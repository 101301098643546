import { Optional, useLocalization } from "@infrastructure";
import _ from "lodash";
import { useMemo } from "react";
import { Contract, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { useGcpSqlInstanceDatabaseVersionTranslator, useGcpSqlInstanceStatusTranslator } from "../../../../../../../../../Entities/hooks";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetNetworkAccessResourceRiskContext } from "../../../useGetNetworkAccessResourceRiskContext";
import { useGetGcpEntityRiskContext } from "./useGetGcpEntityRiskContext";

export function useGetGcpSqlInstanceRiskContext() {
    return useMemo(
        () => useGcpSqlInstanceRiskContext,
        []);
}

function useGcpSqlInstanceRiskContext(instanceModel: Contract.GcpSqlInstanceModel) {
    const entityRiskContext = useGetGcpEntityRiskContext()(instanceModel);
    const networkAccessResourceRiskContext = useGetNetworkAccessResourceRiskContext()(instanceModel);
    const instance = instanceModel.entity as Contract.GcpSqlInstance;
    const instanceNetwork = instanceModel.entityNetwork as Optional<Contract.GcpSqlInstanceStateNetwork>;

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const sqlInstanceDatabaseVersionTranslator = useGcpSqlInstanceDatabaseVersionTranslator();
    const sqlInstanceStatusTranslator = useGcpSqlInstanceStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.contexts.useGetGcpSqlInstanceRiskContext",
            () => ({
                databaseVersion: "The {{translatedInstanceTypeName}} database version is **{{databaseVersion}}**",
                flag: "The {{translatedInstanceTypeName}} **{{name}}** flag is set to **{{value}}**",
                status: "The {{translatedInstanceTypeName}} status is **{{status}}**"
            }));
    const translatedInstanceTypeName =
        entityTypeNameTranslator(
            instance.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });

    return {
        ...entityRiskContext,
        ...networkAccessResourceRiskContext,
        databaseVersion:
            new RiskDefinitionContextItem(
                localization.databaseVersion(
                    {
                        databaseVersion: sqlInstanceDatabaseVersionTranslator(instance.databaseVersion),
                        translatedInstanceTypeName
                    }
                )
            ),
        getFlagContextItem:
            (name: string, value: string) =>
                new RiskDefinitionContextItem(
                    localization.flag(
                        {
                            name,
                            translatedInstanceTypeName,
                            value
                        })),
        status:
            _.isNil(instance.status)
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.status({
                        status: sqlInstanceStatusTranslator(instance.status),
                        translatedInstanceTypeName
                    })),
        wideRangeInboundAuthorizedSubnets:
            _.isNil(instanceNetwork)
                ? undefined
                : entityRiskContext.getWideRangeInboundSubnetsContextItem(instanceNetwork.wideRangeInboundAuthorizedSubnets)
    };
}