﻿import { Dialog, Message, useLocalization } from "@infrastructure";
import { Box, Button, CircularProgress, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { Contract, RiskController, useTheme } from "../../../../../common";

type SendRiskWebhookProps = {
    endpointConfiguration: Contract.WebhookEndpointConfiguration;
    onClose: (canceled?: boolean) => void;
    riskIds: string[];
};

export function SendRisksWebhookDialog({ endpointConfiguration, onClose, riskIds }: SendRiskWebhookProps) {
    const [sendRisksWebhookExecuting, setSendRisksWebhookExecuting] = useState(false);
    const [sendRisksWebhookError, setSendRisksWebhookError] = useState(false);
    const sendRisksWebhook =
        async () => {
            setSendRisksWebhookExecuting(true);
            setSendRisksWebhookError(false);

            try {
                await RiskController.sendRisksWebhook(
                    new Contract.RiskControllerSendRisksWebhookRequest(
                        endpointConfiguration.id,
                        riskIds));
                onClose();
            } catch {
                setSendRisksWebhookError(true);
            }

            setSendRisksWebhookExecuting(false);
        };

    const localization =
        useLocalization(
            "views.customer.risks.sendRisksWebhookDialog",
            () => (
                {
                    actions: {
                        cancel: "No",
                        send: "Yes"
                    },
                    error: "Failed sending {{findings}}",
                    findings: [
                        "this finding",
                        "{{count | NumberFormatter.humanize}} findings"
                    ],
                    message: "Are you sure you want to send {{findings}} to webhook **{{endpointName}}**?"
                }));

    const theme = useTheme();
    return (
        <Dialog
            variant="editor"
            onClose={() => onClose(true)}>
            <Stack
                alignItems="center"
                justifyContent="space-between"
                spacing={3}
                sx={{
                    height: "100%",
                    minHeight: theme.spacing(30),
                    padding: theme.spacing(4.75, 5.75)
                }}>
                <Typography
                    align="center"
                    sx={{ wordBreak: "break-word" }}
                    variant="h1">
                    {localization.message({
                        endpointName: endpointConfiguration.name,
                        findings: localization.findings(riskIds.length)
                    })}
                </Typography>
                {sendRisksWebhookError && (
                    <Message
                        level="error"
                        title={
                            localization.error(
                                {
                                    findings: localization.findings(riskIds.length)
                                })}/>)}
                {sendRisksWebhookExecuting && (
                    <Box>
                        <CircularProgress
                            size={theme.spacing(2)}
                            variant="indeterminate"/>
                    </Box>)}
                <Stack
                    alignItems="center"
                    direction="row"
                    justifyContent="center"
                    spacing={2}>
                    <Button
                        disabled={sendRisksWebhookExecuting}
                        sx={{ width: theme.spacing(12.5) }}
                        variant="outlined"
                        onClick={() => onClose(true)}>
                        {localization.actions.cancel()}
                    </Button>
                    <Button
                        disabled={sendRisksWebhookExecuting}
                        sx={{ width: theme.spacing(12.5) }}
                        onClick={sendRisksWebhook}>
                        {localization.actions.send()}
                    </Button>
                </Stack>
            </Stack>
        </Dialog>);
}