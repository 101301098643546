import { ApiError, PasswordTextField, useInputValidation, useLocalization, useOrderedWizardContext, useSetOrderedWizardContext } from "@infrastructure";
import { FormControl, FormHelperText, Stack, TextField } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useAddOrEditContext, useSetAddOrEditContext } from "..";
import { ConfigurationController, Contract, RadioGroup, ScopeHelper, scopeSystemEntityModelStore, UrlHelper, useScopeNavigationViewContext, useTheme } from "../../../../../../../../../../../../../../../common";

export function OrganizationItem() {
    const { scopeNodeModel } = useScopeNavigationViewContext();
    const instanceModels =
        ScopeHelper.getParentScopeSystemEntityModelsIntersection(
            [scopeNodeModel.configuration.id],
            scopeSystemEntityModelStore.useGetJira());
    const { executing, setLoaded, setValid, useNextEffect } = useOrderedWizardContext();
    const setOrderedWizardContext = useSetOrderedWizardContext();
    useEffect(
        () => {
            setOrderedWizardContext(
                wizardContext => ({
                    ...wizardContext,
                    sideElement: undefined
                }));
            setLoaded();
        },
        []);

    const { instanceAuthenticationType, instanceModel, instanceUrl, instanceUserName, instanceUserToken } = useAddOrEditContext();
    const setAddOrEditContext = useSetAddOrEditContext();
    const [authenticationType, setAuthenticationType] = useState(instanceAuthenticationType);
    const [url, setUrl] = useState(instanceUrl);
    const [userName, setUserName] = useState(instanceUserName);
    const [userToken, setUserToken] = useState(instanceUserToken);

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useTicketingItems.jira.addOrEdit.organizationItem",
            () => ({
                actions: {
                    save: {
                        error: {
                            add: "Failed to add",
                            edit: "Failed to save",
                            [Contract.TypeNames.ConfigurationControllerUpsertJiraInstanceError]: {
                                [Contract.ConfigurationControllerUpsertJiraInstanceError.InvalidUrl]: "Could not connect to Jira instance",
                                [Contract.ConfigurationControllerUpsertJiraInstanceError.UserNotAuthenticated]: "Could not authenticate, please check your username and token/password"
                            }
                        }
                    }
                },
                fields: {
                    authenticationType: {
                        [Contract.JiraInstanceConfigurationConnectionInfoAuthenticationType.Basic]: "Password or API token",
                        [Contract.JiraInstanceConfigurationConnectionInfoAuthenticationType.BearerToken]: "Personal access token (PAT)"
                    },
                    url: {
                        error: {
                            exists: "Instance already exists",
                            invalidFormat: "Invalid instance URL",
                            required: "Instance URL cannot be empty"
                        },
                        title: "Instance URL"
                    },
                    userName: {
                        error: {
                            required: "User name cannot be empty"
                        },
                        title: "User name"
                    },
                    userToken: {
                        error: {
                            required: {
                                [Contract.JiraInstanceConfigurationConnectionInfoAuthenticationType.Basic]: "User Password or API token cannot be empty",
                                [Contract.JiraInstanceConfigurationConnectionInfoAuthenticationType.BearerToken]: "User Personal access token (PAT) cannot be empty"
                            }
                        },
                        title: {
                            [Contract.JiraInstanceConfigurationConnectionInfoAuthenticationType.Basic]: "User Password or API token",
                            [Contract.JiraInstanceConfigurationConnectionInfoAuthenticationType.BearerToken]: "User Personal access token (PAT)"
                        }
                    }
                }
            }));

    const existingInstanceHostNames =
        useMemo(
            () =>
                _(instanceModels).
                    filter(
                        otherInstanceModel =>
                            otherInstanceModel.configuration.id !== instanceModel?.configuration.id &&
                            otherInstanceModel.configuration.scopeId == scopeNodeModel.configuration.id).
                    map(instanceModel => UrlHelper.getUrlData((instanceModel.configuration as Contract.JiraInstanceConfiguration).connectionInfo.url)!.hostname).
                    value(),
            [instanceModels]);

    const [instanceUrlValidationController, instanceUrlValidationMessage] =
        useInputValidation(
            () => {
                const validationInstanceUrl = url?.trim();
                if (_.isEmpty(validationInstanceUrl)) {
                    return localization.fields.url.error.required();
                }

                const validationInstanceUrlData = UrlHelper.getUrlData(validationInstanceUrl);
                if (_.isNil(validationInstanceUrlData) ||
                    validationInstanceUrlData.pathname !== "/") {
                    return localization.fields.url.error.invalidFormat();
                }

                if (!_(existingInstanceHostNames).
                    filter(existingInstanceHostName => existingInstanceHostName === validationInstanceUrlData.hostname).
                    isEmpty()) {
                    return localization.fields.url.error.exists();
                }

                return undefined;
            },
            [url]);

    const [userNameValidationController, userNameValidationMessage] =
        useInputValidation(
            () => {
                const validationUserName = userName?.trim();
                if (_.isEmpty(validationUserName)) {
                    return localization.fields.userName.error.required();
                }

                return undefined;
            },
            [userName]);

    const [userTokenValidationController, userTokenValidationMessage] =
        useInputValidation(
            () => {
                if (_.isNil(userToken)) {
                    return undefined;
                }

                const validationUserToken = userToken.trim();
                if (_.isEmpty(validationUserToken)) {
                    return localization.fields.userToken.error.required[authenticationType]();
                }

                return undefined;
            },
            [userToken]);

    useNextEffect(
        async () => {
            try {
                const { scopeSystemEntityModel } =
                    await ConfigurationController.upsertJiraInstance(
                        new Contract.ConfigurationControllerUpsertJiraInstanceRequest(
                            authenticationType,
                            instanceModel?.configuration.id,
                            scopeNodeModel.configuration.id,
                            url.trim(),
                            userName,
                            userToken));
                await scopeSystemEntityModelStore.notify(scopeSystemEntityModel);
            } catch (error) {
                return error instanceof ApiError && error.statusCode === 400
                    ? localization.actions.save.error[Contract.TypeNames.ConfigurationControllerUpsertJiraInstanceError][error.error as Contract.ConfigurationControllerUpsertJiraInstanceError]()
                    : _.isNil(instanceModel)
                        ? localization.actions.save.error.add()
                        : localization.actions.save.error.edit();
            }
        },
        [authenticationType, url, userName, userToken]);

    useEffect(
        () => {
            setAddOrEditContext(
                addOrEditContext => ({
                    ...addOrEditContext,
                    instanceUrl: url.trim(),
                    instanceUserName: userName.trim(),
                    instanceUserToken: userToken?.trim()
                }));

            setValid(
                instanceUrlValidationController.isValid() &&
                userNameValidationController.isValid() &&
                userTokenValidationController.isValid());
        },
        [url, userName, userToken]);

    const theme = useTheme();
    return (
        <Stack
            spacing={4}
            sx={{ maxWidth: theme.spacing(60) }}>
            <FormControl
                variant="standard">
                <TextField
                    disabled={executing || !_.isNil(instanceModel)}
                    label={localization.fields.url.title()}
                    value={url}
                    variant="outlined"
                    onChange={event => setUrl(event.target.value)}/>
                {!_.isNil(instanceUrlValidationMessage) &&
                    <FormHelperText error={true}>{instanceUrlValidationMessage}</FormHelperText>}
            </FormControl>
            <FormControl
                variant="standard">
                <TextField
                    disabled={executing}
                    label={localization.fields.userName.title()}
                    value={userName}
                    variant="outlined"
                    onChange={event => setUserName(event.target.value)}/>
                {!_.isNil(userNameValidationMessage) &&
                    <FormHelperText error={true}>{userNameValidationMessage}</FormHelperText>}
            </FormControl>
            <FormControl variant="standard">
                <RadioGroup
                    items={[
                        {
                            label: localization.fields.authenticationType[Contract.JiraInstanceConfigurationConnectionInfoAuthenticationType.Basic](),
                            value: Contract.JiraInstanceConfigurationConnectionInfoAuthenticationType.Basic
                        },
                        {
                            label: localization.fields.authenticationType[Contract.JiraInstanceConfigurationConnectionInfoAuthenticationType.BearerToken](),
                            value: Contract.JiraInstanceConfigurationConnectionInfoAuthenticationType.BearerToken
                        }
                    ]}
                    selectedValue={authenticationType}
                    onChange={event => setAuthenticationType(event)}/>
            </FormControl>
            <FormControl
                variant="standard">
                <PasswordTextField
                    disabled={executing}
                    label={localization.fields.userToken.title[authenticationType]()}
                    password={userToken}
                    variant="outlined"
                    onPasswordChanged={userToken => setUserToken(userToken!)}/>
                {!_.isNil(userTokenValidationMessage) &&
                    <FormHelperText error={true}>{userTokenValidationMessage}</FormHelperText>}
            </FormControl>
        </Stack>);
}