﻿import { DataTableColumn, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, ElasticsearchItemPageHelper, PagedEntityFilter, useEntitiesExportOptions } from "../../../../../../../../../common";
import { DefinitionData } from "../../../Table";

export function useCommonKubernetesNamespaceResourceDefinition(definitionData: DefinitionData) {
    const entitiesExportOptions = useEntitiesExportOptions();

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.useCommonKubernetesNamespaceResourceDefinition",
            () => ({
                columns: {
                    namespace: "Namespace"
                }
            }));

    return {
        columns: {
            namespaceColumn:
                <DataTableColumn
                    exportOptions={
                        entitiesExportOptions<Contract.IKubernetesNamespaceResourceModel>(
                            Contract.TypeNames.IKubernetesNamespace,
                            item => [(_.as<Contract.IKubernetesResource>(item.entity).data as Contract.KubernetesNamespaceResourceData).namespaceId],
                            localization.columns.namespace())
                    }
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedEntityFilter
                                    getEntityIdPage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.KubernetesNamespaceResourceNamespace)}
                                    placeholder={localization.columns.namespace()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.KubernetesNamespaceResourceNamespace}
                    key={Contract.EntityModelProperty.KubernetesNamespaceResourceNamespace}
                    title={localization.columns.namespace()}/>
        }
    };
}