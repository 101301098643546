import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function IgnoredIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <rect
                height="2"
                rx="1"
                width="9.75"
                x="7"
                y="11"/>
            <path
                clipRule="evenodd"
                d="M1 12C1 5.92472 5.92472 1 12 1C18.0753 1 23 5.92472 23 12C23 18.0753 18.0753 23 12 23C5.92472 23 1 18.0753 1 12ZM12 3C7.02928 3 3 7.02928 3 12C3 16.9707 7.02928 21 12 21C16.9707 21 21 16.9707 21 12C21 7.02928 16.9707 3 12 3Z"
                fillRule="evenodd"/>
        </SvgIcon>);
}