import { AnalyticsContext, AnalyticsEventActionType, useTrackAnalyticsEvent } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { CustomerConsoleAppUrlHelper, useApplicationViewRoute } from "../../../../common";
import { CustomerView } from "../../hooks";
import { AwsFederation, Dashboard, Permissions, Principals } from "./components";
import { AccessView } from "./hooks";

export function Access() {
    const [{ view }] =
        useApplicationViewRoute(
            CustomerConsoleAppUrlHelper.getTemplatePath(CustomerView.Access),
            _.values(AccessView),
            AccessView.Dashboard);

    return (
        <AnalyticsContext context={view}>
            <View view={view}/>
        </AnalyticsContext>);
}

type ViewProps = {
    view: AccessView;
};

function View({ view }: ViewProps) {
    useTrackAnalyticsEvent(AnalyticsEventActionType.PageView);

    switch (view) {
        case AccessView.AwsFederation:
            return <AwsFederation variant="view"/>;
        case AccessView.Dashboard:
            return <Dashboard/>;
        case AccessView.Permissions:
            return <Permissions/>;
        default:
            return (
                <Principals
                    key={view}
                    view={view}/>);
    }
}