//@ts-nocheck
import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";
import { Contract, EntitiesIcon, useTheme } from "../../../../common";

type CiEntityIconProps =
    SvgIconProps & {
        blurred: boolean;
        entityTypeName: string;
        variant?: string;
    };

export function CiEntityIcon({ blurred, entityTypeName, variant, ...props }: CiEntityIconProps) {
    switch (entityTypeName) {
        case Contract.TypeNames.CiContainerImage:
            return (
                <SvgIcon {...props} filter={blurred ? "url(#greyscale)" : undefined}>
                    <path d="M12.9051 7.1817L12.9233 7.238H12.9825L12.9347 7.2728L12.9529 7.3291L12.9051 7.2943L12.8572 7.3291L12.8755 7.2728L12.8276 7.238H12.8868L12.9051 7.1817Z" />
                    <path d="M11.5397 1.1253C11.8245 0.958235 12.1755 0.958235 12.4603 1.1253L21.0397 6.15769C21.3245 6.32475 21.5 6.63349 21.5 6.96761V17.0324C21.5 17.3665 21.3245 17.6753 21.0397 17.8423L12.4603 22.8747C12.1755 23.0418 11.8245 23.0418 11.5397 22.8747L2.96027 17.8423C2.67545 17.6753 2.5 17.3665 2.5 17.0324V6.96761C2.5 6.63349 2.67545 6.32475 2.96027 6.15769L11.5397 1.1253ZM11.3096 3.06011L4.03422 7.32757V15.721L7.39734 13.671V9.50608L11.5397 12V16.9878L8.12145 14.8655L4.60655 17.0081L12 21.3449L19.5106 16.9394L16.0698 14.8421L12.6137 16.9878V12L16.7561 9.50608V13.6705L16.7761 13.6366L19.9658 15.581V7.32757L12.6904 3.06011V6.76161L16.2191 8.57087L12.1151 11.0648L8.01103 8.57087L11.3096 6.87961V3.06011ZM4.07049 16.6937L4.03422 16.6323V16.6724L4.07049 16.6937Z" fillRule="evenodd" clipRule="evenodd" />
                </SvgIcon>);
        case Contract.TypeNames.CiContainerImageRepository:
            return (
                <SvgIcon {...props} filter={blurred ? "url(#greyscale)" : undefined}>
                    <path d="M3.97297 3.5C3.43561 3.5 3 3.93561 3 4.47297C3 5.01033 3.43561 5.44595 3.97297 5.44595H20.027C20.5644 5.44595 21 5.01033 21 4.47297C21 3.93561 20.5644 3.5 20.027 3.5H3.97297Z" fillRule="evenodd" clipRule="evenodd" />
                    <path d="M20.5455 20.5C20.7965 20.5 21 20.2973 21 20.0472V8.8447C21 8.59462 20.7965 8.39189 20.5455 8.39189H16.3434C16.0924 8.39189 15.8889 8.59462 15.8889 8.8447V10.3175C15.8889 10.5675 15.6854 10.7703 15.4343 10.7703H8.56566C8.31462 10.7703 8.11111 10.5675 8.11111 10.3175V8.8447C8.11111 8.59462 7.9076 8.39189 7.65657 8.39189H3.45455C3.20351 8.39189 3 8.59462 3 8.8447V20.0472C3 20.2973 3.20351 20.5 3.45455 20.5H20.5455ZM6.09158 20.4661C6.09513 20.3544 6.11664 20.2384 6.16101 20.1249C6.10663 20.2683 6.09423 20.3977 6.09158 20.4661ZM4.81818 10.3378V18.5541H19.1818V10.3378H17.707C17.6964 11.7802 16.5592 12.7162 15.4343 12.7162H8.56566C7.4408 12.7162 6.30362 11.7802 6.293 10.3378H4.81818Z" fillRule="evenodd" clipRule="evenodd" />
                </SvgIcon>);
        case Contract.TypeNames.CiTenantEntity:
            return (
                <SvgIcon {...props} filter={blurred ? "url(#greyscale)" : undefined}>
                    <path d="M11.9515 2.07284C11.7855 1.97609 11.5817 1.9757 11.4153 2.07181L3.27435 6.77495C3.10478 6.87291 3 7.05613 3 7.25468V16.7547C3 16.9502 3.10164 17.1311 3.26715 17.2302L4.34127 17.8731C4.70229 18.0892 5.15722 17.8241 5.15722 17.3976V8.41186C5.15722 8.21508 5.26018 8.03319 5.42738 7.93457L12.8548 3.5539C13.2161 3.34082 13.2148 2.80911 12.8524 2.59792L11.9515 2.07284Z" />
                    <path d="M15.4095 4.2691C15.2373 4.17649 15.0256 4.177 14.8539 4.27044L6.65277 8.73301C6.48085 8.82657 6.375 8.99911 6.375 9.18578V18.3379C6.375 18.5234 6.4795 18.695 6.64968 18.789L7.98629 19.5271C8.3575 19.7321 8.82528 19.4806 8.82528 19.076V10.5468C8.82528 10.3601 8.93113 10.1876 9.10305 10.094L16.7847 5.9141C17.1562 5.71196 17.1548 5.20755 16.7822 5.00721L15.4095 4.2691Z" />
                    <path d="M11.9308 21.7243C11.75 21.8256 11.5263 21.8252 11.3459 21.7232L10.0357 20.9829C9.85867 20.8829 9.75 20.7003 9.75 20.503V19.57C9.75 19.1425 10.2303 18.8746 10.6164 19.0867L11.1945 19.4042C11.3741 19.5029 11.5948 19.502 11.7735 19.4017L20.1114 14.7266C20.494 14.5121 20.9754 14.7732 20.9822 15.199L20.9999 16.3162C21.0031 16.5173 20.8932 16.7045 20.7124 16.8057L11.9308 21.7243Z" />
                    <path d="M20.9999 12.3937C21.0032 12.5951 20.8927 12.7826 20.7109 12.8842L11.777 17.874C11.5972 17.9744 11.3751 17.9754 11.1944 17.8765L10.0436 17.2467C9.86203 17.1474 9.75 16.9625 9.75 16.7623V15.7542C9.75 15.3253 10.2343 15.0568 10.6227 15.2704L11.092 15.5284C11.2719 15.6273 11.4931 15.6272 11.6729 15.528L20.1099 10.8752C20.4949 10.6629 20.9765 10.9248 20.9833 11.3502L20.9999 12.3937Z" />
                    <path d="M10.0278 10.6055C9.85586 10.7031 9.75 10.8831 9.75 11.0779V12.4401C9.75 12.6357 9.85663 12.8163 10.0296 12.9136L11.0314 13.4775C11.2033 13.5743 11.4147 13.5741 11.5865 13.4772L20.1585 8.63734C20.3338 8.5384 20.4405 8.35412 20.4374 8.15587L20.4213 7.12342C20.4183 6.93063 20.3118 6.75368 20.1411 6.65791L18.8296 5.9221C18.6574 5.82546 18.4457 5.826 18.274 5.9235L10.0278 10.6055Z" />
                </SvgIcon>);
    }

    return <EntitiesIcon {...props} filter={blurred ? "url(#greyscale)" : undefined}/>;
}