import { Link, map, NoneIcon } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract, CustomerConsoleAppUrlHelper, EntitiesCell, Entity, InlineRisks, ObjectTypeMetadataModelHelper, RiskHelper, riskModelStore, RisksIcon, useRiskPolicyTitleTranslator } from "../../../../..";
import { useUdmObjectTableContext } from "../../..";
import { UdmObjectPropertyItemProps } from "../..";

export function CommonId(props: UdmObjectPropertyItemProps) {
    const objectType = ObjectTypeMetadataModelHelper.get(props.objectTypeName).udmType;
    return map(
        props.objectProperty.relation?.objectType ?? objectType,
        {
            [Contract.UdmObjectType.Entity]: () => <EntityId {...props}/>,
            [Contract.UdmObjectType.Risk]: () => <RiskId {...props}/>
        });
}

function EntityId({ filter, item, objectProperty, objectTypeName }: UdmObjectPropertyItemProps) {
    const { compact } = useUdmObjectTableContext();
    return _.isNil(item)
        ? <NoneIcon sx={{ fontSize: "18px" }}/>
        : objectProperty.collection
            ? <EntitiesCell
                entityIdsOrModels={item}
                entityTypeName={objectTypeName}
                entityVariant={
                    compact || filter
                        ? "iconText"
                        : "iconTextTypeTenant"}/>
            : <Entity
                entityIdOrModel={item}
                variant={
                    compact || filter
                        ? "iconText"
                        : "iconTextTypeTenant"}
                {...(filter && {
                    glanceOptions: { disabled: true },
                    linkOptions: { disabled: true }
                })}/>;
}

function RiskId({ item, objectProperty }: UdmObjectPropertyItemProps) {
    return objectProperty.collection
        ? <InlineRisks
            riskIdsOrModels={item}
            variant="itemCount"/>
        : <Risk riskId={item}/>;
}

type RiskProps = {
    riskId: string;
};

function Risk({ riskId }: RiskProps) {
    const riskPolicyTitleTranslator = useRiskPolicyTitleTranslator();
    const riskModel = riskModelStore.useGet(riskId);
    const riskUrl = CustomerConsoleAppUrlHelper.getRiskProfileHashUrl(riskId);
    return (
        <Link
            sx={{ width: "100%" }}
            urlOrGetUrl={riskUrl}
            variant="text">
            <Stack
                alignItems="center"
                direction="row"
                spacing={0.75}
                sx={{
                    flex: 1,
                    overflow: "hidden"
                }}>
                <RisksIcon sx={{ fontSize: "16px" }}/>
                <Typography noWrap={true}>
                    {riskPolicyTitleTranslator(RiskHelper.getBuiltInRiskPolicyConfigurationTypeNameOrCustomRiskPolicyId(riskModel.risk))}
                </Typography>
            </Stack>
        </Link>);
}