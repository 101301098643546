import React from "react";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { Contract } from "../../../../../../../../../../common";
import { Info } from "../../../../components";
import { useCommonContainerImageInfoItemElements } from "../useCommonContainerImageInfoItemElements";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";
import { useGcpCommonResourceInfoItemElements } from "./useGcpCommonResourceInfoItemElements";

export function useGcpContainerImageDefinition(resourceModel: Contract.GcpEntityModel, options?: DefinitionOptions) {
    const commonContainerImageInfoItemElements = useCommonContainerImageInfoItemElements(resourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const commonResourcesInfoElements = useGcpCommonResourceInfoItemElements(resourceModel as Contract.GcpResourceModel);

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={[
                    commonResourcesInfoElements.typeNameInfoElement,
                    commonResourcesInfoElements.gciTenantInfoItemElement,
                    commonResourcesInfoElements.tenantInfoItemElement,
                    commonResourcesInfoElements.syncTimeInfoItemElement
                ]}
                entityPropertyInfoItemElements={[
                    commonContainerImageInfoItemElements.repositoryInfoItemElement,
                    commonContainerImageInfoItemElements.tagsInfoItemElement,
                    commonContainerImageInfoItemElements.digestInfoItemElement,
                    commonContainerImageInfoItemElements.creationTimeInfoItemElement,
                    commonContainerImageInfoItemElements.operatingSystemTypeInfoItemElement,
                    commonContainerImageInfoItemElements.operatingSystemInfoItemElement,
                    commonContainerImageInfoItemElements.vulnerabilitiesInfoItemElement,
                    commonContainerImageInfoItemElements.scanTimeInfoItemElement,
                    commonContainerImageInfoItemElements.workloadClusterResourceIdsInfoItemElement,
                    commonContainerImageInfoItemElements.virtualMachineIdsInfoItemElement,
                    commonContainerImageInfoItemElements.kubernetesWorkloadResourceIdsInfoItemElement
                ]}
                options={options?.infoOptions}>
            </Info>
    });
}