﻿import { useLocalization } from "@infrastructure";
import { Contract } from "../../../common";

export function useGcpComputeInstanceGroupManagerStatusTranslator() {
    const localization =
        useLocalization(
            "tenants.gcp.hooks.useGcpComputeInstanceGroupManagerStatusTranslator",
            () => ({
                [Contract.TypeNames.GcpComputeInstanceGroupManagerStatus]: {
                    [Contract.GcpComputeInstanceGroupManagerStatus.NotReady]: "Not Ready",
                    [Contract.GcpComputeInstanceGroupManagerStatus.Ready]: "Ready"
                }
            }));
    return (instanceGroupManagerStatus: Contract.GcpComputeInstanceGroupManagerStatus) =>
        localization[Contract.TypeNames.GcpComputeInstanceGroupManagerStatus][instanceGroupManagerStatus]();
}