import { Box, Typography, useTheme } from "@mui/material";
import React from "react";
import { Shadows } from "../../../assets";
import { DirectedGraphLayoutColumn } from "../utilities";

type ColumnProps = {
    layoutColumn: DirectedGraphLayoutColumn;
};

export function Column({ layoutColumn }: ColumnProps) {
    const theme = useTheme();
    return (
        <foreignObject
            height="60px"
            style={{ overflow: "visible" }}
            transform={`translate(${layoutColumn.x}, 0)`}
            width={layoutColumn.width}>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center"
                }}>
                <Typography
                    align="center"
                    noWrap={true}
                    sx={{
                        backgroundColor: theme.palette.background.paper,
                        boxShadow: theme.shadows[Shadows.Tooltip],
                        fontWeight: 500,
                        maxWidth: "100%",
                        padding: theme.spacing(1.5, 2)
                    }}
                    variant="h2">
                    {layoutColumn.modelColumn.title}
                </Typography>
            </Box>
        </foreignObject>);
}