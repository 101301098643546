import { TimeFormatter, useLocalization } from "@infrastructure";
import React from "react";
import { Contract, entityModelStore, InfoItem, useTenantNameTranslator } from "../../../../../../../../../../common";
import { EntitiesInfoItem } from "../../../../components";
import { useCommonEntityInfoItemElements } from "../useCommonEntityInfoItemElements";

export function useAzureCommonEntityInfoItemElements(resourceModel: Contract.AzureEntityModel) {
    const commonEntityInfoItemElements = useCommonEntityInfoItemElements(resourceModel);
    const resource = resourceModel.entity as Contract.AzureEntity;
    const tenantEntityModel = entityModelStore.useGet(resourceModel.tenantId);

    const tenantNameTranslator = useTenantNameTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.azure.useAzureCommonEntityInfoItemElements",
            () => ({
                syncTime: "Sync Time",
                tenantId: "Subscription"
            }));
    return {
        ...commonEntityInfoItemElements,
        syncTimeInfoItemElement:
            <InfoItem
                key="syncTime"
                title={localization.syncTime()}
                value={TimeFormatter.profileFormatDateTime(resource.syncTime)}/>,
        tenantInfoItemElement:
            tenantEntityModel.unknown
                ? <InfoItem
                    key="tenant"
                    title={localization.tenantId()}
                    value={tenantNameTranslator(resource.tenantId, { includeRawId: true })}/>
                : <EntitiesInfoItem
                    entityIdsOrModels={tenantEntityModel}
                    entityTypeName={tenantEntityModel.entity.typeName}
                    key="tenant"
                    title={localization.tenantId()}/>
    };
}