import { InlineItems, StringHelper, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { AwsActionHelper } from "../../../../../../../../../../../tenants";

type InlineActionsProps = {
    actions: string[];
};

export function InlineActions({ actions }: InlineActionsProps) {
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.inlineActions",
            () => ({
                permissions: [
                    "1 permission",
                    "{{count | NumberFormatter.humanize}} permissions"
                ]
            }));
    return (
        <InlineItems
            items={
                _(actions).
                    map(action => AwsActionHelper.getName(action)).
                    orderBy(item => StringHelper.getSortValue(item)).
                    value()}
            namePluralizer={localization.permissions}
            variant="itemCountAndType"/>);
}