import { NumberFormatter, TimeSpanFormatter, useLocalization } from "@infrastructure";
import React from "react";
import { useAwsCommonEncryptedResourceInfoElements, useAwsDefaultResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { useCustomEntityPropertyInfoItemElements } from "..";
import { Contract, InfoItem } from "../../../../../../../../../../common";
import { useAwsDocDbClusterModelTypeTranslator } from "../../../../../../hooks";
import { EntitiesInfoItem, Info } from "../../../../components";

export function useAwsDocDbElasticClusterDefinition(resourceModel: Contract.AwsResourceModel, options?: DefinitionOptions) {
    const clusterModel = resourceModel as Contract.AwsDocDbElasticClusterModel;
    const cluster = clusterModel.entity as Contract.AwsDocDbElasticCluster;
    const commonEncryptedResourceInfoElements = useAwsCommonEncryptedResourceInfoElements(resourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useAwsDefaultResourceInfoItemElements(resourceModel);

    const docDbClusterModelTypeTranslator = useAwsDocDbClusterModelTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsDocDbElasticClusterDefinition",
            () => ({
                info: {
                    items: {
                        backupRetentionTimeFrame: "Automatic Backup Retention Period",
                        clusterType: "Cluster Type",
                        shardCapacity: "Shard Capacity",
                        shardCount: "Shard Count",
                        shardInstanceCount: "Shard Instance Count",
                        status: "Status",
                        storageSize: "Size"
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="clusterType"
                        title={localization.info.items.clusterType()}
                        value={docDbClusterModelTypeTranslator(clusterModel.type)}/>,
                    <InfoItem
                        key="status"
                        title={localization.info.items.status()}
                        value={cluster.status}/>,
                    <InfoItem
                        key="storageSize"
                        title={localization.info.items.storageSize()}
                        value={NumberFormatter.storage(cluster.storageSize)}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={clusterModel.snapshotIds}
                        entityTypeName={Contract.TypeNames.AwsDocDbElasticClusterSnapshot}
                        key="snapshots"/>,
                    ...commonEncryptedResourceInfoElements,
                    <InfoItem
                        key="backupRetentionTimeFrame"
                        title={localization.info.items.backupRetentionTimeFrame()}
                        value={TimeSpanFormatter.day(cluster.backupRetentionTimeFrame)}/>,
                    <InfoItem
                        key="shardCount"
                        title={localization.info.items.shardCount()}
                        value={cluster.shardCount}/>,
                    <InfoItem
                        key="shardInstanceCount"
                        title={localization.info.items.shardInstanceCount()}
                        value={cluster.shardInstanceCount}/>,
                    <InfoItem
                        key="shardCapacity"
                        title={localization.info.items.shardCapacity()}
                        value={cluster.shardCapacity}/>
                ]}
                options={options?.infoOptions}>
            </Info>
    });
}