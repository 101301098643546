import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function RiskPoliciesIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                clipRule="evenodd"
                d="M19.2454 17.849L19.3925 17.6083C20.3436 16.0523 21 13.1353 21 11.0014V6.85547C21 6.4767 20.786 6.13043 20.4472 5.96104L13.4472 2.46104C13.1657 2.32028 12.8343 2.32028 12.5528 2.46104L5.55279 5.96104C5.214 6.13043 5 6.4767 5 6.85547V11.0014C5 13.2216 5.99826 15.7371 7.66424 17.884C9.20604 19.8709 11.1526 21.3278 13.0161 21.9278C13.9038 21.6743 14.9007 21.2998 15.8331 20.8295C16.9664 20.2577 17.8615 19.6158 18.3854 18.998L18.57 18.7803C18.6415 18.6869 18.7492 18.5428 18.9061 18.3267C19.0701 18.1007 19.1772 17.9483 19.2454 17.849ZM19.9109 20.2914C18.3762 22.1014 15.2772 23.4414 13 24.0014C7.89109 22.7414 3 16.5514 3 11.0014V6.85547C3 5.71915 3.64201 4.68036 4.65836 4.17219L11.6584 0.672187C12.5029 0.249896 13.4971 0.249896 14.3416 0.672187L21.3416 4.17219C22.358 4.68036 23 5.71915 23 6.85547V11.0014C23 13.3814 22.297 16.6914 21.099 18.6514C21.099 18.6514 21.1207 18.6802 20.5248 19.5014C19.9514 20.2914 19.9109 20.2914 19.9109 20.2914ZM18.4542 18.9267C18.4543 18.9265 18.4558 18.9248 18.4584 18.9218C18.4554 18.9254 18.4541 18.9269 18.4542 18.9267ZM19.3444 17.7003C19.3472 17.6956 19.3488 17.6931 19.3488 17.6931C19.3489 17.693 19.3475 17.6953 19.3444 17.7003Z"
                fillRule="evenodd"/>
        </SvgIcon>);
}