import { DataTableActions, DataTableFetchItemsResult, DataTableSort, Optional } from "@infrastructure";
import _, { Dictionary, Function3 } from "lodash";
import { useCallback, useState } from "react";
import { Contract, WorkloadController } from "../../../../../common";
import { ElasticsearchIndexSearchCursor } from "../../../../../common/controllers/types.generated";
import { useGetWorkloadResourceModelsRequestSort } from "./useGetWorkloadResourceModelsRequestSort";

export function useFetchWorkloadResourceModelPage<T extends new (...args: ConstructorParameters<typeof Contract.WorkloadControllerGetWorkloadResourceModelPageRequest>) => Contract.WorkloadControllerGetWorkloadResourceModelPageRequest>(
    dataTableActionsRef: React.MutableRefObject<DataTableActions | undefined>,
    getWorkloadResourceModelFilters: Function3<Dictionary<any>, React.MutableRefObject<DataTableActions | undefined>, Optional<string>, Contract.WorkloadControllerWorkloadResourceModelFilters>,
    workloadControllerGetWorkloadResourceModelPageRequestType: T,
    vulnerabilityRawId?: string) {
    const getWorkloadResourceModelsRequestSort = useGetWorkloadResourceModelsRequestSort();

    const [fetchWorkloadResourceModelPageNextPageSearchCursor, setFetchWorkloadResourceModelPageNextPageSearchCursor] = useState(undefined as Optional<ElasticsearchIndexSearchCursor>);
    const [workloadResourceModelCount, setWorkloadResourceModelCount] = useState(0);
    return useCallback(
        async (filterMap: Dictionary<any>, sort: Optional<DataTableSort>, skip: number, limit: number, exportData = false) => {
            const nextPageSearchCursor =
                skip === 0
                    ? undefined
                    : fetchWorkloadResourceModelPageNextPageSearchCursor;
            const { workloadResourceModelPage } =
                await WorkloadController.getWorkloadResourceModelPage(
                    new workloadControllerGetWorkloadResourceModelPageRequestType(
                        exportData,
                        getWorkloadResourceModelFilters(
                            filterMap,
                            dataTableActionsRef,
                            vulnerabilityRawId),
                        limit,
                        nextPageSearchCursor,
                        getWorkloadResourceModelsRequestSort(sort)
                    ));
            return new DataTableFetchItemsResult(
                { count: workloadResourceModelPage.count ?? workloadResourceModelCount },
                workloadResourceModelPage.items,
                _.isNil(workloadResourceModelPage.itemNextPageSearchCursor),
                {
                    onAppendData:
                        () => {
                            if (!_.isNil(workloadResourceModelPage.count)) {
                                setWorkloadResourceModelCount(workloadResourceModelPage.count);
                            }
                            setFetchWorkloadResourceModelPageNextPageSearchCursor(workloadResourceModelPage.itemNextPageSearchCursor);

                        }
                });
        },
        [dataTableActionsRef, fetchWorkloadResourceModelPageNextPageSearchCursor, getWorkloadResourceModelFilters, getWorkloadResourceModelsRequestSort, workloadControllerGetWorkloadResourceModelPageRequestType, workloadResourceModelCount]);
}