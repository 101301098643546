﻿import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function BrazilIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <g clipPath="url(#clip0_60203_5275)">
                <rect
                    fill="white"
                    height="15.9638"
                    rx="2"
                    width="24"
                    y="4"/>
                <path
                    d="M24 4.0011H0V19.9649H24V4.0011Z"
                    fill="#009B3A"/>
                <path
                    d="M2.59 11.983L12.0543 18.0264L21.5185 11.983L12.0543 5.93956L2.59 11.983Z"
                    fill="#FEDF00"/>
                <path
                    d="M12.0543 15.9739C14.2584 15.9739 16.0453 14.1871 16.0453 11.983C16.0453 9.77886 14.2584 7.99205 12.0543 7.99205C9.85016 7.99205 8.06335 9.77886 8.06335 11.983C8.06335 14.1871 9.85016 15.9739 12.0543 15.9739Z"
                    fill="#002776"/>
                <path
                    d="M8.19029 10.9812C8.70964 10.8896 9.23934 10.8427 9.77387 10.8427C12.0277 10.8427 14.1955 11.6768 15.8647 13.174C15.9284 12.97 15.9762 12.7589 16.0065 12.5423C14.2676 11.082 12.0624 10.2726 9.77387 10.2726C9.31244 10.2726 8.8544 10.3055 8.4029 10.37C8.31621 10.566 8.24484 10.7702 8.19029 10.9812Z"
                    fill="white"/>
                <path
                    d="M9.92449 10.7474L10.2664 10.7593L10.2688 10.6888L9.99208 10.6791L9.99549 10.5814L10.2126 10.589L10.2148 10.5239L9.99776 10.5163L10.0004 10.4403L10.2609 10.4494L10.2634 10.3789L9.93776 10.3675L9.92449 10.7474Z"
                    fill="#009B3A"/>
                <path
                    clipRule="evenodd"
                    d="M9.62108 10.749L9.44192 10.7521L9.43528 10.3721L9.61444 10.3689C9.63583 10.3686 9.65708 10.3724 9.67699 10.3803C9.69689 10.3881 9.71506 10.3998 9.73044 10.4146C9.74583 10.4295 9.75814 10.4472 9.76667 10.4669C9.7752 10.4865 9.77978 10.5076 9.78016 10.529L9.78111 10.5833C9.78148 10.6046 9.77764 10.6259 9.7698 10.6458C9.76196 10.6657 9.75027 10.6839 9.73541 10.6993C9.72055 10.7146 9.70281 10.727 9.68319 10.7355C9.66357 10.744 9.64246 10.7486 9.62108 10.749ZM9.61442 10.6785L9.51126 10.6803L9.50709 10.4414L9.61025 10.4396C9.6376 10.4391 9.66403 10.4495 9.68371 10.4686C9.70339 10.4876 9.71472 10.5136 9.7152 10.541L9.71577 10.5735C9.71624 10.6009 9.70584 10.6273 9.68683 10.647C9.66782 10.6667 9.64177 10.678 9.61442 10.6785Z"
                    fill="#009B3A"
                    fillRule="evenodd"/>
                <path
                    clipRule="evenodd"
                    d="M9.0317 10.7774L8.96155 10.7821L8.93916 10.4015L9.15502 10.387C9.18651 10.3849 9.21762 10.3955 9.24153 10.4164C9.26543 10.4373 9.28016 10.4669 9.28247 10.4986C9.28479 10.5303 9.2745 10.5615 9.25388 10.5854C9.24763 10.5927 9.24059 10.599 9.23294 10.6045C9.2735 10.6362 9.27947 10.7179 9.28255 10.7601L9.21776 10.7645C9.21512 10.7283 9.21036 10.6632 9.17241 10.626L9.02138 10.6362L9.0317 10.7774ZM9.16193 10.5558L9.01622 10.5655L9.00908 10.4677L9.15478 10.458C9.16766 10.4571 9.1804 10.4615 9.19017 10.47C9.19995 10.4786 9.20598 10.4907 9.20692 10.5036C9.20787 10.5166 9.20366 10.5294 9.19522 10.5392C9.18679 10.5489 9.17481 10.5549 9.16193 10.5558Z"
                    fill="#009B3A"
                    fillRule="evenodd"/>
                <path
                    clipRule="evenodd"
                    d="M8.76371 10.7464C8.73622 10.7857 8.69548 10.8109 8.65045 10.8164C8.60543 10.8219 8.55981 10.8074 8.52363 10.7759C8.48745 10.7444 8.46367 10.6986 8.45753 10.6486C8.45138 10.5986 8.46338 10.5484 8.49087 10.5091C8.51837 10.4698 8.55911 10.4447 8.60413 10.4391C8.64916 10.4336 8.69478 10.4482 8.73096 10.4796C8.76714 10.5111 8.79092 10.5569 8.79706 10.6069C8.8032 10.657 8.79121 10.7071 8.76371 10.7464ZM8.70809 10.703C8.69212 10.7275 8.66829 10.7431 8.64185 10.7463C8.61541 10.7496 8.58851 10.7402 8.56709 10.7203C8.54566 10.7003 8.53145 10.6715 8.52759 10.64C8.52373 10.6086 8.53053 10.5771 8.5465 10.5526C8.56246 10.5281 8.58629 10.5124 8.61273 10.5092C8.63918 10.506 8.66607 10.5153 8.6875 10.5353C8.70893 10.5552 8.72313 10.5841 8.727 10.6155C8.73086 10.647 8.72406 10.6784 8.70809 10.703Z"
                    fill="#009B3A"
                    fillRule="evenodd"/>
                <path
                    d="M10.4716 10.7736L10.4067 10.7679L10.4398 10.3892L10.5345 10.3975L10.5875 10.6638L10.6859 10.4108L10.7806 10.4191L10.7475 10.7977L10.6826 10.792L10.7053 10.5324L10.6068 10.7854L10.5473 10.7802L10.4943 10.5139L10.4716 10.7736Z"
                    fill="#009B3A"/>
                <path
                    d="M11.1942 10.8567L11.4751 10.905L11.4862 10.8407L11.2694 10.8035L11.2841 10.7179L11.4607 10.7482L11.4718 10.684L11.2952 10.6537L11.3053 10.5948L11.5153 10.6309L11.5264 10.5667L11.2521 10.5195L11.1942 10.8567Z"
                    fill="#009B3A"/>
                <path
                    clipRule="evenodd"
                    d="M11.9843 11.0161L11.916 10.9984L12.0164 10.6318L12.2267 10.6862C12.2573 10.6941 12.2836 10.7139 12.2997 10.7412C12.3158 10.7685 12.3203 10.8011 12.3124 10.8318C12.3045 10.8624 12.2847 10.8887 12.2574 10.9048C12.2301 10.9209 12.1975 10.9254 12.1668 10.9175L12.0197 10.8794L11.9843 11.0161ZM12.1793 10.8478L12.0373 10.8111L12.0618 10.7165L12.2037 10.7532C12.2163 10.7564 12.227 10.7645 12.2336 10.7757C12.2402 10.7869 12.2421 10.8002 12.2388 10.8127C12.2356 10.8253 12.2275 10.836 12.2163 10.8426C12.2051 10.8492 12.1918 10.8511 12.1793 10.8478Z"
                    fill="#009B3A"
                    fillRule="evenodd"/>
                <path
                    clipRule="evenodd"
                    d="M12.3824 11.1262L12.449 11.1467L12.4927 11.0109L12.6361 11.0551L12.6378 11.0556C12.6586 11.1038 12.6385 11.1663 12.6273 11.201L12.6888 11.22C12.702 11.1792 12.7274 11.1001 12.7014 11.0561C12.7101 11.0537 12.7186 11.0504 12.7267 11.046C12.7548 11.031 12.776 11.0054 12.7859 10.9749C12.7957 10.9444 12.7932 10.9115 12.7791 10.8834C12.7649 10.8553 12.7401 10.8344 12.7102 10.8252L12.5054 10.762L12.3824 11.1262ZM12.5146 10.9429L12.6529 10.9856C12.6651 10.9894 12.6785 10.988 12.6899 10.9819C12.7014 10.9757 12.7101 10.9653 12.7142 10.9528C12.7182 10.9403 12.7172 10.9268 12.7114 10.9154C12.7056 10.9039 12.6954 10.8953 12.6832 10.8915L12.5449 10.8489L12.5146 10.9429Z"
                    fill="#009B3A"
                    fillRule="evenodd"/>
                <path
                    clipRule="evenodd"
                    d="M13.1345 11.3216C13.092 11.3438 13.0442 11.3473 13.0017 11.3314C12.9592 11.3155 12.9255 11.2815 12.9079 11.2369C12.8904 11.1923 12.8904 11.1407 12.9081 11.0935C12.9257 11.0463 12.9595 11.0073 13.002 10.9852C13.0446 10.963 13.0923 10.9595 13.1348 10.9754C13.1773 10.9913 13.2111 11.0253 13.2286 11.0699C13.2462 11.1145 13.2461 11.1661 13.2285 11.2133C13.2108 11.2605 13.177 11.2995 13.1345 11.3216ZM13.1052 11.2574C13.0797 11.2718 13.0514 11.2746 13.0264 11.2653C13.0015 11.256 12.982 11.2352 12.9722 11.2076C12.9624 11.1801 12.9631 11.1479 12.9742 11.1182C12.9853 11.0885 13.0058 11.0638 13.0313 11.0494C13.0568 11.035 13.0851 11.0322 13.1101 11.0415C13.1351 11.0508 13.1546 11.0716 13.1644 11.0991C13.1742 11.1267 13.1735 11.1589 13.1624 11.1886C13.1513 11.2182 13.1307 11.243 13.1052 11.2574Z"
                    fill="#009B3A"
                    fillRule="evenodd"/>
                <path
                    d="M14.1637 11.8584L14.4614 12.0269L14.4962 11.9654L14.2552 11.8291L14.3033 11.744L14.4923 11.851L14.5244 11.7942L14.3354 11.6873L14.3728 11.6211L14.5996 11.7495L14.6344 11.688L14.3508 11.5276L14.1637 11.8584Z"
                    fill="#009B3A"/>
                <path
                    d="M14.7643 12.1414C14.7185 12.1123 14.6978 12.0765 14.7182 12.0445L14.6461 11.9985C14.5948 12.0785 14.6735 12.1576 14.7262 12.1912C14.788 12.2306 14.8806 12.2671 14.9339 12.1835C14.9766 12.1164 14.9116 12.054 14.8587 12.0032C14.8222 11.9681 14.7915 11.9386 14.8059 11.9169C14.8271 11.8837 14.8559 11.894 14.8914 11.9166C14.928 11.94 14.9521 11.973 14.9302 12.0073L15 12.0518C15.0515 11.9735 14.9853 11.9056 14.9441 11.8794L14.9388 11.876C14.8942 11.8473 14.7911 11.781 14.7297 11.8748C14.6875 11.9396 14.7475 11.9985 14.7991 12.0491C14.8381 12.0873 14.8723 12.1209 14.8544 12.149C14.8413 12.1696 14.8101 12.1706 14.7643 12.1414Z"
                    fill="#009B3A"/>
                <path
                    d="M15.1259 12.3141C15.1039 12.3451 15.1227 12.3818 15.1669 12.4134C15.2111 12.4449 15.2423 12.4455 15.2565 12.4256C15.2758 12.3985 15.2434 12.3632 15.2065 12.323C15.1576 12.2697 15.1008 12.2078 15.1463 12.1453C15.2125 12.0548 15.312 12.1264 15.3551 12.1574L15.3602 12.1611C15.4 12.1894 15.4625 12.2607 15.407 12.3362L15.3396 12.2881C15.3632 12.255 15.341 12.2207 15.3056 12.1955C15.2713 12.1711 15.2431 12.1592 15.2202 12.1913C15.2046 12.2122 15.2338 12.2433 15.2684 12.2802C15.3186 12.3337 15.3802 12.3995 15.334 12.4642C15.2765 12.5449 15.1859 12.5036 15.1262 12.461C15.0754 12.4248 15.0009 12.3417 15.0563 12.2645L15.1259 12.3141Z"
                    fill="#009B3A"/>
                <path
                    clipRule="evenodd"
                    d="M15.6409 12.7832C15.5936 12.7911 15.5471 12.7797 15.5116 12.7514C15.4761 12.7232 15.4545 12.6804 15.4516 12.6326C15.4487 12.5847 15.4647 12.5357 15.496 12.4962C15.5274 12.4568 15.5716 12.4302 15.6189 12.4222C15.6662 12.4143 15.7127 12.4257 15.7482 12.454C15.7837 12.4822 15.8053 12.525 15.8082 12.5728C15.8111 12.6207 15.7951 12.6697 15.7638 12.7092C15.7324 12.7486 15.6882 12.7752 15.6409 12.7832ZM15.6329 12.713C15.6042 12.7188 15.5764 12.7128 15.5555 12.6962C15.5347 12.6796 15.5225 12.6539 15.5217 12.6246C15.5209 12.5953 15.5316 12.565 15.5513 12.5402C15.571 12.5154 15.5982 12.4982 15.6269 12.4924C15.6556 12.4866 15.6834 12.4926 15.7043 12.5092C15.7251 12.5258 15.7373 12.5516 15.7381 12.5808C15.7389 12.6101 15.7282 12.6404 15.7085 12.6652C15.6888 12.69 15.6616 12.7072 15.6329 12.713Z"
                    fill="#009B3A"
                    fillRule="evenodd"/>
                <path
                    d="M13.5778 11.5098C13.5345 11.5301 13.4874 11.5317 13.4468 11.5143C13.4058 11.4968 13.3747 11.4614 13.3603 11.4159C13.346 11.3704 13.3495 11.3186 13.3702 11.2718C13.3909 11.225 13.4271 11.1871 13.4707 11.1664C13.5143 11.1457 13.5619 11.1439 13.6029 11.1614C13.6439 11.179 13.6749 11.2144 13.6893 11.2598C13.6972 11.2849 13.6997 11.312 13.6968 11.3391L13.6276 11.3093C13.627 11.3016 13.6257 11.2941 13.6237 11.2869C13.6159 11.2588 13.5979 11.2373 13.5739 11.227C13.5498 11.2167 13.5215 11.2185 13.4953 11.232C13.4691 11.2455 13.447 11.2697 13.434 11.2991C13.421 11.3285 13.4181 11.3608 13.4259 11.3889C13.4337 11.4169 13.4517 11.4385 13.4757 11.4488C13.4998 11.4591 13.5281 11.4572 13.5543 11.4437C13.5647 11.4384 13.5744 11.4314 13.5831 11.4231L13.5281 11.3994L13.5504 11.3487L13.6156 11.3767L13.6158 11.3762L13.6795 11.4036L13.6793 11.4041L13.6817 11.4051L13.6816 11.4054L13.6817 11.4054L13.6048 11.5804L13.5561 11.5594L13.5778 11.5098Z"
                    fill="#009B3A"/>
                <path
                    clipRule="evenodd"
                    d="M13.796 11.6739L13.7335 11.643L13.9113 11.302L14.1035 11.3969C14.1316 11.4107 14.1527 11.4354 14.1624 11.4654C14.172 11.4954 14.1693 11.5284 14.1549 11.557C14.1405 11.5856 14.1155 11.6076 14.0855 11.618C14.0768 11.621 14.0679 11.623 14.059 11.624C14.0776 11.6717 14.0403 11.7457 14.021 11.784L13.9633 11.7555C13.9797 11.7229 14.0092 11.6644 13.9963 11.6134L13.9948 11.6127L13.8602 11.5463L13.796 11.6739ZM14.0221 11.5466L13.8924 11.4826L13.9368 11.3943L14.0666 11.4583C14.0781 11.464 14.0867 11.474 14.0907 11.4863C14.0946 11.4986 14.0935 11.5121 14.0876 11.5238C14.0817 11.5355 14.0715 11.5445 14.0592 11.5488C14.0469 11.553 14.0336 11.5523 14.0221 11.5466Z"
                    fill="#009B3A"
                    fillRule="evenodd"/>
                <path
                    d="M13.192 10.8827L13.1916 10.8839L13.1948 10.8806L13.2921 10.8098L13.3892 10.8804L13.3926 10.8839L13.3922 10.8826L13.3932 10.8833L13.3911 10.8794L13.3539 10.7662L13.4529 10.6945L13.4537 10.6941L13.4535 10.6941L13.4548 10.6932L13.449 10.6941L13.3307 10.6927L13.2921 10.5739L13.2535 10.6927L13.1352 10.6941L13.1294 10.6932L13.1307 10.6941L13.1305 10.6941L13.1312 10.6945L13.2303 10.7662L13.193 10.8796L13.191 10.8834L13.192 10.8827Z"
                    fill="white"/>
                <path
                    d="M8.69599 11.4039L8.69558 11.4051L8.69881 11.4018L8.79614 11.3309L8.8933 11.4017L8.89671 11.4052L8.89627 11.4038L8.89729 11.4046L8.89522 11.4007L8.85799 11.2874L8.95702 11.2157L8.95779 11.2153L8.95754 11.2153L8.95885 11.2144L8.95306 11.2153L8.83479 11.2139L8.79622 11.0952L8.75765 11.2139L8.63922 11.2153L8.63343 11.2144L8.63475 11.2153L8.63449 11.2153L8.63526 11.2157L8.73428 11.2874L8.69701 11.4007L8.69499 11.4046L8.69599 11.4039Z"
                    fill="white"/>
                <path
                    d="M9.04884 13.083L9.04925 13.0817L9.04825 13.0824L9.05027 13.0786L9.08753 12.9653L8.98847 12.8936L8.9877 12.8932L8.98795 12.8932L8.98664 12.8922L8.99243 12.8931L9.11084 12.8917L9.14943 12.773L9.18802 12.8917L9.30627 12.8931L9.31206 12.8922L9.31074 12.8932L9.311 12.8932L9.31023 12.8936L9.21121 12.9653L9.24843 13.0785L9.25049 13.0824L9.24948 13.0816L9.24991 13.083L9.2465 13.0795L9.14941 13.0088L9.05206 13.0797L9.04884 13.083Z"
                    fill="white"/>
                <path
                    d="M8.57696 13.4182L8.57731 13.4171L8.57648 13.4177L8.57817 13.4145L8.60922 13.3201L8.5268 13.2604L8.52605 13.26L8.5263 13.26L8.5252 13.2592L8.53003 13.26L8.62844 13.2588L8.66058 13.1599L8.69272 13.2588L8.79145 13.26L8.79627 13.2592L8.79518 13.26L8.79543 13.26L8.79468 13.2604L8.71209 13.3202L8.74309 13.4145L8.74479 13.4177L8.74395 13.4171L8.74431 13.4182L8.74152 13.4153L8.66063 13.3565L8.57975 13.4153L8.57696 13.4182Z"
                    fill="white"/>
                <path
                    d="M9.49235 12.7659L9.49256 12.7653L9.49203 12.7657L9.49311 12.7636L9.51075 12.71L9.4626 12.6751L9.46517 12.6755L9.52186 12.6748L9.54023 12.6183L9.5586 12.6748L9.61507 12.6755L9.61765 12.6751L9.61717 12.6755L9.61684 12.6757L9.56967 12.7098L9.58744 12.7639L9.58838 12.7657L9.58791 12.7653L9.58811 12.7659L9.58656 12.7643L9.5402 12.7306L9.49403 12.7642L9.49235 12.7659Z"
                    fill="white"/>
                <path
                    d="M9.79364 13.8295L9.79393 13.8286L9.79322 13.8291L9.79467 13.8263L9.81946 13.7509L9.75359 13.7032L9.75302 13.703L9.75321 13.7029L9.75233 13.7023L9.75619 13.7029L9.83478 13.702L9.86051 13.6228L9.88623 13.702L9.96524 13.7029L9.9691 13.7023L9.96823 13.7029L9.96836 13.703L9.96795 13.7031L9.90179 13.7511L9.92655 13.8264L9.92801 13.8292L9.92729 13.8286L9.92758 13.8295L9.92529 13.8272L9.86058 13.7801L9.79594 13.8271L9.79364 13.8295Z"
                    fill="white"/>
                <path
                    d="M13.9804 15.0665L13.9801 15.0674L13.9824 15.0651L14.0471 15.018L14.1118 15.0651L14.1141 15.0674L14.1138 15.0665L14.1145 15.067L14.1131 15.0643L14.0883 14.9889L14.1544 14.941L14.1548 14.9408L14.1547 14.9408L14.1556 14.9402L14.1517 14.9408L14.0727 14.9399L14.047 14.8607L14.0213 14.9399L13.9425 14.9408L13.9386 14.9402L13.9395 14.9408L13.9393 14.9408L13.9399 14.9411L14.0059 14.9889L13.9811 15.0643L13.9797 15.067L13.9804 15.0665Z"
                    fill="white"/>
                <path
                    d="M14.7506 13.5216L14.7502 13.5228L14.7534 13.5195L14.8508 13.4486L14.9479 13.5194L14.9513 13.5229L14.9509 13.5215L14.9519 13.5223L14.9498 13.5184L14.9126 13.4051L15.0116 13.3334L15.0124 13.333L15.0121 13.333L15.0134 13.3321L15.0076 13.333L14.8894 13.3316L14.8508 13.2128L14.8122 13.3316L14.6938 13.333L14.688 13.3321L14.6893 13.333L14.6891 13.333L14.6898 13.3334L14.7889 13.4051L14.7516 13.5184L14.7496 13.5223L14.7506 13.5216Z"
                    fill="white"/>
                <path
                    d="M14.9303 13.8516L14.93 13.8526L14.9327 13.8498L15.0137 13.7909L15.0946 13.8498L15.0974 13.8526L15.097 13.8516L15.0978 13.8522L15.0961 13.849L15.0651 13.7546L15.1477 13.6948L15.1485 13.6945L15.1482 13.6945L15.1493 13.6937L15.1445 13.6944L15.0457 13.6933L15.0136 13.5944L14.9814 13.6933L14.8831 13.6944L14.8783 13.6937L14.8793 13.6945L14.8791 13.6945L14.8798 13.6948L14.9622 13.7545L14.9312 13.849L14.9295 13.8522L14.9303 13.8516Z"
                    fill="white"/>
                <path
                    d="M13.9685 14.6889L13.9689 14.6878L13.9681 14.6884L13.9698 14.6852L14.0008 14.5907L13.9184 14.531L13.9177 14.5306L13.9179 14.5306L13.9168 14.5298L13.9217 14.5306L14.0202 14.5294L14.0524 14.4305L14.0845 14.5294L14.1831 14.5306L14.1879 14.5298L14.1868 14.5306L14.1871 14.5306L14.1863 14.531L14.1039 14.5907L14.1349 14.6851L14.1366 14.6883L14.1358 14.6877L14.1362 14.6888L14.1334 14.686L14.0524 14.627L13.9713 14.686L13.9685 14.6889Z"
                    fill="white"/>
                <path
                    d="M15.3001 13.3879L15.3789 13.387L15.4046 13.3079L15.4303 13.387L15.5093 13.3879L15.5131 13.3873L15.5123 13.388L15.5124 13.388L15.512 13.3882L15.4457 13.4362L15.4704 13.5115L15.4719 13.5142L15.4712 13.5137L15.4715 13.5146L15.4692 13.5122L15.4045 13.4652L15.3399 13.5122L15.3376 13.5145L15.3379 13.5137L15.3371 13.5142L15.3386 13.5114L15.3634 13.436L15.2975 13.3883L15.2969 13.388L15.2971 13.388L15.2962 13.3873L15.3001 13.3879Z"
                    fill="white"/>
                <path
                    d="M14.3442 14.2242L14.423 14.2232L14.4487 14.1441L14.4744 14.2232L14.5534 14.2241L14.5572 14.2235L14.5564 14.2241L14.5565 14.2241L14.5561 14.2243L14.4899 14.2723L14.5147 14.3476L14.5161 14.3504L14.5154 14.3499L14.5157 14.3508L14.5134 14.3484L14.4487 14.3013L14.3841 14.3484L14.3818 14.3507L14.3821 14.3498L14.3814 14.3503L14.3829 14.3476L14.4076 14.2723L14.3416 14.2245L14.341 14.2242L14.3412 14.2242L14.3404 14.2236L14.3442 14.2242Z"
                    fill="white"/>
                <path
                    d="M14.6386 14.0148L14.7371 14.0136L14.7693 13.9146L14.8014 14.0136L14.9 14.0148L14.9048 14.014L14.9037 14.0148L14.904 14.0148L14.9032 14.0152L14.8208 14.0749L14.8518 14.1693L14.8535 14.1725L14.8527 14.1719L14.853 14.173L14.8502 14.1701L14.7692 14.1111L14.6882 14.1701L14.6854 14.173L14.6858 14.1719L14.685 14.1725L14.6867 14.1693L14.7177 14.0748L14.6353 14.0152L14.6346 14.0148L14.6348 14.0148L14.6337 14.014L14.6386 14.0148Z"
                    fill="white"/>
                <path
                    d="M13.9318 14.1481L14.0106 14.1472L14.0363 14.0681L14.062 14.1472L14.141 14.1481L14.1448 14.1475L14.144 14.1482L14.1441 14.1482L14.1437 14.1484L14.0773 14.1964L14.1021 14.2716L14.1035 14.2744L14.1028 14.2739L14.1031 14.2748L14.1008 14.2724L14.0362 14.2254L13.9716 14.2724L13.9693 14.2747L13.9696 14.2738L13.9689 14.2744L13.9703 14.2716L13.9951 14.1962L13.9292 14.1484L13.9286 14.1482L13.9288 14.1482L13.9279 14.1475L13.9318 14.1481Z"
                    fill="white"/>
                <path
                    d="M10.4505 12.2606L10.4502 12.2617L10.4529 12.2588L10.5339 12.1998L10.6149 12.2587L10.6177 12.2616L10.6173 12.2605L10.6181 12.2611L10.6165 12.2579L10.5854 12.1635L10.6678 12.1038L10.6686 12.1035L10.6683 12.1035L10.6694 12.1027L10.6646 12.1034L10.5661 12.1023L10.5339 12.0033L10.5018 12.1022L10.4032 12.1034L10.3984 12.1026L10.3995 12.1034L10.3993 12.1034L10.4 12.1038L10.4824 12.1635L10.4514 12.258L10.4497 12.2612L10.4505 12.2606Z"
                    fill="white"/>
                <path
                    d="M13.0736 11.8738L13.0733 11.8747L13.0756 11.8723L13.1402 11.8253L13.2049 11.8724L13.2072 11.8747L13.2069 11.8738L13.2076 11.8743L13.2062 11.8716L13.1814 11.7963L13.2476 11.7483L13.248 11.7481L13.2478 11.7481L13.2487 11.7475L13.2448 11.7481L13.1659 11.7472L13.1402 11.668L13.1144 11.7472L13.0356 11.7481L13.0318 11.7475L13.0327 11.7481L13.0325 11.7481L13.033 11.7484L13.0991 11.7962L13.0743 11.8715L13.0729 11.8743L13.0736 11.8738Z"
                    fill="white"/>
                <path
                    d="M11.9541 13.9125L11.9537 13.9137L11.9569 13.9104L12.0543 13.8396L12.1514 13.9103L12.1548 13.9138L12.1544 13.9125L12.1554 13.9132L12.1534 13.9093L12.1161 13.796L12.215 13.7243L12.2158 13.7239L12.2156 13.7239L12.2169 13.723L12.2111 13.7239L12.0928 13.7225L12.0542 13.6037L12.0157 13.7225L11.8973 13.7239L11.8915 13.723L11.8928 13.7239L11.8926 13.7239L11.8933 13.7243L11.9924 13.796L11.9551 13.9094L11.9531 13.9132L11.9541 13.9125Z"
                    fill="white"/>
                <path
                    d="M12.4325 13.0968L12.4321 13.0979L12.4349 13.0951L12.5158 13.0361L12.5967 13.095L12.5995 13.0979L12.5992 13.0968L12.6 13.0974L12.5983 13.0942L12.5672 12.9997L12.6497 12.9401L12.6504 12.9397L12.6502 12.9397L12.6513 12.9389L12.6464 12.9396L12.5479 12.9385L12.5157 12.8396L12.4836 12.9385L12.385 12.9397L12.3802 12.939L12.3813 12.9397L12.381 12.9397L12.3818 12.9401L12.4643 12.9999L12.4333 13.0942L12.4316 13.0974L12.4325 13.0968Z"
                    fill="white"/>
                <path
                    d="M11.971 12.7384L11.9706 12.7395L11.9734 12.7367L12.0544 12.6777L12.1352 12.7366L12.138 12.7395L12.1377 12.7384L12.1385 12.739L12.1368 12.7358L12.1058 12.6415L12.1884 12.5817L12.1891 12.5813L12.1889 12.5813L12.19 12.5805L12.1852 12.5812L12.0864 12.5801L12.0543 12.4812L12.0221 12.5801L11.9238 12.5813L11.919 12.5805L11.9201 12.5813L11.9198 12.5813L11.9206 12.5817L12.0029 12.6413L11.9718 12.7358L11.9701 12.739L11.971 12.7384Z"
                    fill="white"/>
                <path
                    d="M11.5858 13.0738L11.5855 13.0747L11.5878 13.0723L11.6525 13.0252L11.7173 13.0724L11.7196 13.0748L11.7193 13.0739L11.72 13.0744L11.7186 13.0716L11.6938 12.9963L11.7599 12.9484L11.7603 12.9482L11.7602 12.9482L11.7611 12.9475L11.7572 12.9481L11.6783 12.9472L11.6526 12.8681L11.6269 12.9472L11.548 12.9481L11.5442 12.9475L11.5451 12.9482L11.5449 12.9482L11.5454 12.9485L11.6113 12.9961L11.5865 13.0716L11.5851 13.0743L11.5858 13.0738Z"
                    fill="white"/>
                <path
                    d="M11.8057 13.3245L11.8055 13.3251L11.8072 13.3234L11.8534 13.2898L11.8997 13.3235L11.9012 13.3251L11.901 13.3245L11.9015 13.3248L11.9005 13.323L11.8828 13.269L11.9299 13.2349L11.9303 13.2347L11.9307 13.2343L11.9282 13.2347L11.8717 13.234L11.8533 13.1775L11.835 13.234L11.7783 13.2347L11.7757 13.2343L11.8239 13.2692L11.8063 13.3228L11.8052 13.3249L11.8057 13.3245Z"
                    fill="white"/>
                <path
                    d="M12.0209 15.1512L12.0207 15.1515L12.0217 15.1506L12.0544 15.1267L12.0869 15.1504L12.088 15.1515L12.0878 15.1511L12.0881 15.1513L12.0875 15.1501L12.0751 15.1122L12.108 15.0884L12.1083 15.0882L12.1082 15.0882L12.1087 15.0879L12.1067 15.0882L12.0671 15.0877L12.0542 15.0482L12.0414 15.0878L12.0021 15.0882L12.0002 15.0879L12.0337 15.1122L12.0211 15.1503L12.0206 15.1514L12.0209 15.1512Z"
                    fill="white"/>
                <path
                    d="M13.1654 14.7855L13.1651 14.7865L13.1679 14.7837L13.2488 14.7247L13.3297 14.7836L13.3325 14.7865L13.3321 14.7854L13.333 14.786L13.3313 14.7828L13.3002 14.6883L13.3826 14.6287L13.3834 14.6283L13.3832 14.6283L13.3842 14.6275L13.3794 14.6283L13.2808 14.6271L13.2487 14.5282L13.2165 14.6271L13.118 14.6283L13.1132 14.6275L13.1143 14.6283L13.114 14.6283L13.1148 14.6287L13.1973 14.6884L13.1663 14.7828L13.1646 14.7861L13.1654 14.7855Z"
                    fill="white"/>
                <path
                    d="M10.3523 14.2383L10.3519 14.2395L10.3551 14.2362L10.4525 14.1653L10.5496 14.236L10.553 14.2395L10.5525 14.2382L10.5536 14.2389L10.5515 14.235L10.5143 14.1218L10.6133 14.0501L10.6141 14.0497L10.6138 14.0497L10.6151 14.0488L10.6093 14.0497L10.4911 14.0483L10.4525 13.9295L10.4139 14.0482L10.2955 14.0496L10.2898 14.0487L10.2911 14.0497L10.2908 14.0497L10.2916 14.0501L10.3906 14.1218L10.3533 14.2351L10.3513 14.239L10.3523 14.2383Z"
                    fill="white"/>
                <path
                    d="M9.89671 13.4553L9.89635 13.4564L9.89914 13.4535L9.98016 13.3945L10.0612 13.4535L10.064 13.4564L10.0636 13.4553L10.0644 13.4559L10.0628 13.4527L10.0317 13.3582L10.1141 13.2985L10.1149 13.2982L10.1146 13.2981L10.1157 13.2974L10.1109 13.2981L10.0123 13.2969L9.98019 13.198L9.94803 13.297L9.84942 13.2982L9.8446 13.2974L9.84569 13.2982L9.84545 13.2982L9.8462 13.2986L9.92861 13.3582L9.89757 13.4527L9.89587 13.4559L9.89671 13.4553Z"
                    fill="white"/>
                <path
                    d="M13.4863 14.284L13.5651 14.2831L13.5908 14.2039L13.6165 14.283L13.6955 14.284L13.6994 14.2834L13.6985 14.284L13.6986 14.284L13.6982 14.2842L13.632 14.3321L13.6568 14.4075L13.6583 14.4103L13.6576 14.4097L13.6579 14.4106L13.6556 14.4083L13.5909 14.3612L13.5263 14.4082L13.524 14.4106L13.5243 14.4097L13.5235 14.4102L13.525 14.4074L13.5498 14.3322L13.4837 14.2843L13.4831 14.2841L13.4833 14.2841L13.4824 14.2834L13.4863 14.284Z"
                    fill="white"/>
                <path
                    d="M12.9081 14.1853L12.8294 14.1862L12.8255 14.1856L12.8264 14.1863L12.8262 14.1863L12.8268 14.1866L12.8926 14.2343L12.8678 14.3097L12.8664 14.3124L12.8671 14.3119L12.8668 14.3128L12.8691 14.3104L12.9338 14.2633L12.9986 14.3105L13.0009 14.3129L13.0006 14.312L13.0013 14.3125L12.9999 14.3097L12.9751 14.2344L13.0412 14.1865L13.0416 14.1863L13.0415 14.1863L13.0424 14.1856L13.0385 14.1862L12.9596 14.1853L12.9338 14.1062L12.9081 14.1853Z"
                    fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0_60203_5275">
                    <rect
                        fill="white"
                        height="15.9638"
                        rx="2"
                        width="24"
                        y="4"/>
                </clipPath>
            </defs>
        </SvgIcon>);
}