import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";
import { Contract } from "../../../../../controllers";
import { useTheme } from "../../../../../themes";

export function PiiIcon(props: SvgIconProps) {
    const theme = useTheme();
    return (
        <SvgIcon fill="none" height="24" viewBox="0 0 24 24" width="24" {...props}>
            <path
                clipRule="evenodd"
                d="M8.25 8C8.94036 8 9.5 8.48842 9.5 9.09091V10.1818C9.5 10.7843 8.94036 11.2727 8.25 11.2727C7.55964 11.2727 7 10.7843 7 10.1818V9.09091C7 8.48842 7.55964 8 8.25 8Z"
                fill={theme.palette.data.category(Contract.DataCategory.Pii)}
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M15.75 8C16.4404 8 17 8.48842 17 9.09091V10.1818C17 10.7843 16.4404 11.2727 15.75 11.2727C15.0596 11.2727 14.5 10.7843 14.5 10.1818V9.09091C14.5 8.48842 15.0596 8 15.75 8Z"
                fill={theme.palette.data.category(Contract.DataCategory.Pii)}
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M8.54781 14.3277C8.90043 14.0373 9.42605 14.0825 9.72182 14.4287L10.0705 14.8369C10.4646 15.1026 11.0786 15.3636 12 15.3636C12.9215 15.3636 13.5355 15.1026 13.9295 14.8369L14.2782 14.4287C14.574 14.0825 15.0996 14.0373 15.4522 14.3277C15.8048 14.6181 15.8509 15.1341 15.5552 15.4804L15.1385 15.9681C15.0963 16.0175 15.0482 16.0619 14.9954 16.1002C14.3342 16.58 13.3573 17 12 17C10.6427 17 9.66582 16.58 9.00466 16.1002C8.95182 16.0619 8.90378 16.0175 8.86155 15.9681L8.44488 15.4804C8.14911 15.1341 8.1952 14.6181 8.54781 14.3277Z"
                fill={theme.palette.data.category(Contract.DataCategory.Pii)}
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M6.64638 3.17411C5.39567 3.34224 4.68663 3.656 4.17132 4.17132C3.65605 4.68659 3.34225 5.39584 3.17411 6.64676C3.00212 7.92638 3 9.6149 3 12C3 14.3856 3.00212 16.0741 3.17411 17.3536C3.34224 18.6043 3.656 19.3134 4.17132 19.8287C4.68659 20.344 5.39584 20.6578 6.64677 20.8259C7.92638 20.9979 9.6149 21 12 21C14.3856 21 16.0741 20.9979 17.3536 20.8259C18.6043 20.6578 19.3134 20.344 19.8287 19.8287C20.344 19.3134 20.6578 18.6042 20.8259 17.3532C20.9979 16.0736 21 14.3851 21 12C21 9.61436 20.9979 7.92585 20.8259 6.64638C20.6578 5.39567 20.344 4.68663 19.8287 4.17132C19.3134 3.65605 18.6042 3.34225 17.3532 3.17411C16.0736 3.00212 14.3851 3 12 3C9.61436 3 7.92585 3.00212 6.64638 3.17411ZM11.9252 1H12.0748C14.3682 0.999978 16.1913 0.99996 17.6197 1.19194C19.0922 1.38986 20.294 1.80816 21.2429 2.7571C22.1918 3.706 22.6101 4.90749 22.8081 6.37993C23 7.80813 23 9.63131 23 11.9252V12.0748C23 14.3682 23 16.1913 22.8081 17.6197C22.6101 19.0922 22.1918 20.294 21.2429 21.2429C20.294 22.1918 19.0925 22.6101 17.6201 22.8081C16.1919 23 14.3687 23 12.0748 23H11.9252C9.63183 23 7.80866 23 6.38034 22.8081C4.90785 22.6101 3.70604 22.1918 2.7571 21.2429C1.80821 20.294 1.38987 19.0925 1.19194 17.6201C0.99996 16.1919 0.999978 14.3687 1 12.0748V11.9252C0.999978 9.63183 0.99996 7.80866 1.19194 6.38034C1.38986 4.90785 1.80816 3.70605 2.7571 2.7571C3.706 1.80821 4.90749 1.38987 6.37994 1.19194C7.80813 0.99996 9.63131 0.999978 11.9252 1Z"
                fill={theme.palette.data.category(Contract.DataCategory.Pii)}
                fillRule="evenodd"/>
        </SvgIcon>);
}