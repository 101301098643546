import { Action1, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { ExcessivePermissionActionCount } from ".";
import { EntityList, useEntityTypeNameTranslator } from "../../../../..";
import { EntitiesPermissionActionsEntity } from "../../../utilities";

type EntitiesProps = {
    entities: EntitiesPermissionActionsEntity[];
    entityTypeName: string;
    excessivePermissionsEnabled: boolean;
    onSelectedEntityChanged: Action1<EntitiesPermissionActionsEntity>;
    selectedEntityId?: string;
};

export function Entities({ entities, entityTypeName, excessivePermissionsEnabled, onSelectedEntityChanged, selectedEntityId }: EntitiesProps) {
    const [entityIdToEntityMap, entityIds] =
        useMemo(
            () => {
                const entityIdToEntityMap =
                    _.keyBy(
                        entities,
                        entity => entity.entityId);
                const entityIds = _.keys(entityIdToEntityMap);
                return [entityIdToEntityMap, entityIds];
            },
            [entities]);

    const localization =
        useLocalization(
            "common.access.entitiesPermissionActions.entities",
            () => ({
                title: "Accessible {{translatedEntityTypeName}} ({{entityCount | NumberFormatter.humanize}})"
            }));
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    return (
        <EntityList
            entityIdOrModels={entityIds}
            entityTypeName={entityTypeName}
            selectedEntityId={selectedEntityId}
            title={
                localization.title({
                    entityCount: entities.length,
                    translatedEntityTypeName:
                        entityTypeNameTranslator(
                            entityTypeName,
                            { count: 0 })
                })}
            onSelectionChanged={entityId => onSelectedEntityChanged(entityIdToEntityMap[entityId])}>
            {entityId =>
                excessivePermissionsEnabled
                    ? <ExcessivePermissionActionCount
                        count={entityIdToEntityMap[entityId].excessivePermissionActionCount + entityIdToEntityMap[entityId].nonexcessivePermissionActionCount}
                        excessiveCount={entityIdToEntityMap[entityId].excessivePermissionActionCount}
                        selected={entityId === selectedEntityId}/>
                    : undefined}
        </EntityList>);
}