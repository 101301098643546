import { ActionButton, CloseIcon, ItemWithId, Optional, useLocalization } from "@infrastructure";
import { Box, Stack } from "@mui/material";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import { Contract, ObjectTypeMetadataModelHelper, TypeHelper, useTheme, useUdmObjectTableContext } from "../../../../../..";
import { UdmQueryHelper } from "../../../../utilities";
import { Badge } from "../Badge";
import { UdmObject } from "../UdmObject";
import { UdmObjectFilterSelector } from "../UdmObjectFilterSelector";
import { Actions, Rules } from "./components";

type RuleGroupProps = {
    enableRelationAdd?: boolean;
    objectTypeName: Optional<string>;
    onJoinOperatorSelected?: () => void;
    onRelationRuleAdd: (property: Contract.UdmObjectProperty) => void;
    onRuleGroupChanged: (ruleGroup: Contract.UdmQueryRuleGroup) => void;
    onRuleGroupCleared: () => void;
    parentObjectTypeName?: string;
    root?: boolean;
    ruleGroup: Contract.UdmQueryRuleGroup;
};

export function RuleGroup({ enableRelationAdd, objectTypeName, onJoinOperatorSelected, onRelationRuleAdd, onRuleGroupChanged, onRuleGroupCleared, parentObjectTypeName, root = false, ruleGroup }: RuleGroupProps) {
    function createParentOperatorRuleGroup(ruleGroup: ItemWithId<Contract.UdmQueryRuleGroup>, operator: Contract.UdmQueryRuleGroupOperator) {
        const updatedRuleGroup = UdmQueryHelper.createEmptyRuleGroup(ruleGroup.typeName);
        if (ruleGroup.typeName === Contract.TypeNames.UdmQueryRelationRuleGroup) {
            (updatedRuleGroup as Contract.UdmQueryRelationRuleGroup).relationPropertyId = (ruleGroup as Contract.UdmQueryRelationRuleGroup).relationPropertyId;
        }

        updatedRuleGroup.operator = operator;
        updatedRuleGroup.id = (ruleGroup as ItemWithId<Contract.UdmQueryRuleGroup>).id;
        updatedRuleGroup.rules = [
            {
                ...UdmQueryHelper.createEmptyRuleGroup(Contract.TypeNames.UdmQueryRuleGroup),
                name: ruleGroup.name,
                not: ruleGroup.not,
                operator: ruleGroup.operator,
                rules: ruleGroup.rules
            },
            UdmQueryHelper.createEmptyRuleGroup(Contract.TypeNames.UdmQueryRuleGroup)
        ];

        return updatedRuleGroup;
    }

    const localization =
        useLocalization(
            "common.udmObjectTable.udmQueryBuilder.ruleGroup",
            () => ({
                edit: {
                    save: "Save",
                    title: "Edit group name"
                },
                groupName: "Group {{groupId}}",
                relation: "Link"
            }));

    const { closedGroupIds } = useUdmObjectTableContext();
    const [groupClosed, setGroupClosed] = useState(() => _.includes(closedGroupIds, ruleGroup.name));
    const variant =
        useMemo(
            () => UdmQueryHelper.getRuleGroupVariant(ruleGroup),
            [ruleGroup]);

    const childRuleGroupCount =
        useMemo(
            () =>
                _(ruleGroup.rules).
                    filter(rule => TypeHelper.extendOrImplement(rule.typeName, Contract.TypeNames.UdmQueryRuleGroup)).
                    size(),
            [ruleGroup]);

    const childRelationRuleGroupCount =
        useMemo(
            () =>
                _(ruleGroup.rules).
                    filter(rule => TypeHelper.extendOrImplement(rule.typeName, Contract.TypeNames.UdmQueryRelationRuleGroup)).
                    size(),
            [ruleGroup]);

    const relationObjectTypeName =
        ruleGroup.typeName === Contract.TypeNames.UdmQueryRelationRuleGroup
            ? ObjectTypeMetadataModelHelper.getProperty((ruleGroup as Contract.UdmQueryRelationRuleGroup).relationPropertyId)?.relation?.objectTypeName
            : undefined;

    const theme = useTheme();
    return (
        <Stack
            direction={
                variant === "multiline"
                    ? "column"
                    : "row"}
            spacing={1}>
            {!_.isNil(relationObjectTypeName) &&
                <Stack
                    alignItems="flex-start"
                    direction="row"
                    spacing={1}>
                    <Badge text={localization.relation()}/>
                    <Stack
                        alignItems="center"
                        direction="row"
                        spacing={0.5}
                        sx={{
                            border: theme.border.primary,
                            borderRadius: theme.spacing(0.75),
                            minHeight: "28px",
                            padding: theme.spacing(0, 0.5, 0, 1),
                            width: "fit-content"
                        }}>
                        <UdmObject objectTypeName={relationObjectTypeName}/>
                        <ActionButton
                            size="medium"
                            sx={{
                                height: "20px",
                                width: "20px"
                            }}
                            variant="icon"
                            onClick={
                                event => {
                                    onRuleGroupCleared();
                                    event?.stopPropagation();
                                }}>
                            <CloseIcon sx={{ fontSize: "14px" }}/>
                        </ActionButton>
                    </Stack>
                    {(_.isEmpty(ruleGroup.rules) ||
                        (variant === "multiline" && childRuleGroupCount === childRelationRuleGroupCount)) &&
                        <Box sx={{ paddingTop: theme.spacing(0.5) }}>
                            <UdmObjectFilterSelector
                                enableJoinOperator={enableRelationAdd}
                                objectTypeName={relationObjectTypeName}
                                parentObjectTypeName={parentObjectTypeName ?? relationObjectTypeName}
                                onOperatorAdded={
                                    operator =>
                                        onRuleGroupChanged(
                                            createParentOperatorRuleGroup(
                                                ruleGroup,
                                                operator))}
                                onRuleAdd={
                                    rule =>
                                        onRuleGroupChanged(
                                            UdmQueryHelper.addRuleToRuleGroup(
                                                ruleGroup,
                                                rule))}/>
                        </Box>}
                </Stack>}
            <Stack direction="row">
                {variant === "multiline" &&
                    <Stack
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                            marginLeft:
                                root
                                    ? theme.spacing(5.5)
                                    : _.isNil(relationObjectTypeName)
                                        ? 0
                                        : theme.spacing(6),
                            position: "relative"
                        }}>
                        {childRuleGroupCount > 1 &&
                            <Box
                                sx={{
                                    height: "100%",
                                    marginRight: theme.spacing(1),
                                    position: "relative",
                                    width: theme.spacing(5)
                                }}>
                                <Actions
                                    closed={groupClosed}
                                    id={(ruleGroup as ItemWithId<Contract.UdmQueryRuleGroup>).id!}
                                    name={ruleGroup.name}
                                    operator={ruleGroup.operator}
                                    onAddGroup={
                                        () =>
                                            onRuleGroupChanged(
                                                UdmQueryHelper.addRuleToRuleGroup(
                                                    ruleGroup,
                                                    UdmQueryHelper.createEmptyRuleGroup(Contract.TypeNames.UdmQueryRuleGroup)))}
                                    onChange={
                                        operator =>
                                            onRuleGroupChanged({
                                                ...ruleGroup,
                                                operator
                                            })}
                                    onClosedChanged={closed => setGroupClosed(closed)}
                                    onNameChanged={
                                        name =>
                                            onRuleGroupChanged({
                                                ...ruleGroup,
                                                name
                                            })}
                                    onRuleGroupCleared={onRuleGroupCleared}/>
                            </Box>}
                    </Stack>}
                {!groupClosed &&
                    <Stack gap={1}>
                        {_.map(
                            ruleGroup.rules,
                            rule =>
                                <Box key={(rule as ItemWithId<Contract.UdmQueryRuleBase>).id}>
                                    {rule.typeName === Contract.TypeNames.UdmQueryRuleGroup &&
                                        _.every(
                                            (rule as Contract.UdmQueryRuleGroup).rules,
                                            rule => !TypeHelper.extendOrImplement(rule.typeName, Contract.TypeNames.UdmQueryRuleGroup))
                                        ? <Rules
                                            enableRelationAdd={enableRelationAdd}
                                            objectTypeName={relationObjectTypeName ?? objectTypeName}
                                            parentObjectTypeName={parentObjectTypeName}
                                            ruleGroup={rule as Contract.UdmQueryRuleGroup}
                                            onJoinAdded={onJoinOperatorSelected}
                                            onOperatorAdded={
                                                operator => {
                                                    if (variant === "line") {
                                                        onRuleGroupChanged(
                                                            createParentOperatorRuleGroup(
                                                                ruleGroup,
                                                                operator));
                                                    } else {
                                                        onRuleGroupChanged(
                                                            UdmQueryHelper.replaceRuleInRuleGroup(
                                                                ruleGroup,
                                                                createParentOperatorRuleGroup(
                                                                    rule as ItemWithId<Contract.UdmQueryRuleGroup>,
                                                                    operator)));
                                                    }
                                                }}
                                            onRuleAdded={
                                                newRule => {
                                                    if (newRule.typeName === Contract.TypeNames.UdmQueryRelationRuleGroup) {
                                                        onRuleGroupChanged(
                                                            UdmQueryHelper.addRuleToRuleGroup(
                                                                ruleGroup,
                                                                newRule));
                                                    } else {
                                                        onRuleGroupChanged(
                                                            UdmQueryHelper.replaceRuleInRuleGroup(
                                                                ruleGroup,
                                                                UdmQueryHelper.addRuleToRuleGroup(
                                                                    rule as Contract.UdmQueryRuleGroup,
                                                                    newRule)));
                                                    }
                                                }}
                                            onRuleChanged={
                                                updatedRule => {
                                                    if (_.isEmpty(updatedRule.rules)) {
                                                        onRuleGroupChanged(
                                                            UdmQueryHelper.removeRuleFromRuleGroup(
                                                                ruleGroup,
                                                                updatedRule));
                                                    } else {
                                                        onRuleGroupChanged(
                                                            UdmQueryHelper.replaceRuleInRuleGroup(
                                                                ruleGroup,
                                                                updatedRule));
                                                    }
                                                }}
                                            onRuleCleared={
                                                () =>
                                                    onRuleGroupChanged(
                                                        UdmQueryHelper.removeRuleFromRuleGroup(
                                                            ruleGroup,
                                                            rule))}/>
                                        : <RuleGroup
                                            objectTypeName={relationObjectTypeName ?? objectTypeName}
                                            parentObjectTypeName={parentObjectTypeName}
                                            ruleGroup={rule as Contract.UdmQueryRuleGroup}
                                            onRelationRuleAdd={onRelationRuleAdd}
                                            onRuleGroupChanged={
                                                updatedChildRuleGroup =>
                                                    onRuleGroupChanged(
                                                        UdmQueryHelper.replaceRuleInRuleGroup(
                                                            ruleGroup,
                                                            updatedChildRuleGroup))}
                                            onRuleGroupCleared={
                                                () =>
                                                    onRuleGroupChanged(
                                                        UdmQueryHelper.removeRuleFromRuleGroup(
                                                            ruleGroup,
                                                            rule))}/>}
                                </Box>)}
                    </Stack>}
            </Stack>
        </Stack>);
}