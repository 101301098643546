import { useLocalization } from "@infrastructure";
import { Contract } from "../controllers";

export function useRiskCombinationTranslator() {
    const localization =
        useLocalization(
            "common.hooks.useRiskCombinationTranslator",
            () => ({
                vendor: "3*superscript*rd** party",
                [Contract.TypeNames.RiskCombination]: {
                    [Contract.RiskCombination.AwsContainerImageVulnerabilitySeverityCriticalInboundExternalEcsService]: [
                        "{{count | NumberFormatter.humanize}} *bold*public** ECS service with *bold*critical vulnerabilities**",
                        "{{count | NumberFormatter.humanize}} *bold*public** ECS services with *bold*critical vulnerabilities**"
                    ],
                    [Contract.RiskCombination.AwsContainerImageVulnerabilitySeverityCriticalInboundExternalSeverePermissionActionEcsService]: [
                        "{{count | NumberFormatter.humanize}} *bold*public** ECS service with *bold*critical vulnerabilities** and *bold*high privileges**",
                        "{{count | NumberFormatter.humanize}} *bold*public** ECS services with *bold*critical vulnerabilities** and *bold*high privileges**"
                    ],
                    [Contract.RiskCombination.AwsContainerImageVulnerabilitySeverityCriticalSeverePermissionActionEcsService]: [
                        "{{count | NumberFormatter.humanize}} ECS service with *bold*critical vulnerabilities** and *bold*high privileges**",
                        "{{count | NumberFormatter.humanize}} ECS services with *bold*critical vulnerabilities** and *bold*high privileges**"
                    ],
                    [Contract.RiskCombination.AwsInboundExternalSeverePermissionActionEcsService]: [
                        "{{count | NumberFormatter.humanize}} *bold*public** ECS service with *bold*high privileges**",
                        "{{count | NumberFormatter.humanize}} *bold*public** ECS services with *bold*high privileges**"
                    ],
                    [Contract.RiskCombination.AwsMfaDisabledSeverePermissionActionIamUser]: [
                        "{{count | NumberFormatter.humanize}} IAM user with *bold*no MFA** and *bold*high privileges**",
                        "{{count | NumberFormatter.humanize}} IAM users with *bold*no MFA** and *bold*high privileges**"
                    ],
                    [Contract.RiskCombination.AwsPublicAccessEncryptionDisabledDataResource]: [
                        "{{count | NumberFormatter.humanize}} *bold*public** data resource without *bold*encryption at rest**",
                        "{{count | NumberFormatter.humanize}} *bold*public** data resources without *bold*encryption at rest**"
                    ],
                    [Contract.RiskCombination.AzureInboundExternalSeverePermissionActionWebApplication]: [
                        "{{count | NumberFormatter.humanize}} *bold*public** App service with *bold*high privileges**",
                        "{{count | NumberFormatter.humanize}} *bold*public** App services with *bold*high privileges**"
                    ],
                    [Contract.RiskCombination.AzureInboundExternalSharedKeyAccessStorageStorageAccount]: [
                        "{{count | NumberFormatter.humanize}} *bold*public** storage account with *bold*shared key access**",
                        "{{count | NumberFormatter.humanize}} *bold*public** storage accounts with *bold*shared key access**"
                    ],
                    [Contract.RiskCombination.GcpContainerImageVulnerabilitySeverityCriticalPublicAccessCloudRunService]: [
                        "{{count | NumberFormatter.humanize}} *bold*public** Cloud Run service with *bold*critical vulnerabilities**",
                        "{{count | NumberFormatter.humanize}} *bold*public** Cloud Run services with *bold*critical vulnerabilities**"
                    ],
                    [Contract.RiskCombination.GcpContainerImageVulnerabilitySeverityCriticalPublicAccessSeverePermissionActionCloudRunService]: [
                        "{{count | NumberFormatter.humanize}} *bold*public** Cloud Run service with *bold*critical vulnerabilities** and *bold*high privileges**",
                        "{{count | NumberFormatter.humanize}} *bold*public** Cloud Run services with *bold*critical vulnerabilities** and *bold*high privileges**"
                    ],
                    [Contract.RiskCombination.GcpContainerImageVulnerabilitySeverityCriticalSeverePermissionActionAppEngineApplicationService]: [
                        "{{count | NumberFormatter.humanize}} App Engine service with *bold*critical vulnerabilities** and *bold*high privileges**",
                        "{{count | NumberFormatter.humanize}} App Engine services with *bold*critical vulnerabilities** and *bold*high privileges**"
                    ],
                    [Contract.RiskCombination.GcpContainerImageVulnerabilitySeverityCriticalSeverePermissionActionCloudRunService]: [
                        "{{count | NumberFormatter.humanize}} Cloud Run service with *bold*critical vulnerabilities** and *bold*high privileges**",
                        "{{count | NumberFormatter.humanize}} Cloud Run services with *bold*critical vulnerabilities** and *bold*high privileges**"
                    ],
                    [Contract.RiskCombination.InboundExternalOperatingSystemEndOfLifeWorkloadResource]: [
                        "{{count | NumberFormatter.humanize}} *bold*public** workload with an *bold*OS that is at or nearing EOL**",
                        "{{count | NumberFormatter.humanize}} *bold*public** workloads with an *bold*OS that is at or nearing EOL**"
                    ],
                    [Contract.RiskCombination.InboundExternalOperatingSystemUnpatchedWorkloadResource]: [
                        "{{count | NumberFormatter.humanize}} *bold*public** workload with an *bold*unpatched OS**",
                        "{{count | NumberFormatter.humanize}} *bold*public** workloads with an *bold*unpatched OS**"
                    ],
                    [Contract.RiskCombination.InboundExternalSensitiveVirtualMachine]: [
                        "{{count | NumberFormatter.humanize}} *bold*public** virtual machine marked as *bold*sensitive**",
                        "{{count | NumberFormatter.humanize}} *bold*public** virtual machines marked as *bold*sensitive**"
                    ],
                    [Contract.RiskCombination.InboundExternalSeverePermissionActionVirtualMachine]: [
                        "{{count | NumberFormatter.humanize}} *bold*public** virtual machine with *bold*high privileges**",
                        "{{count | NumberFormatter.humanize}} *bold*public** virtual machines with *bold*high privileges**"
                    ],
                    [Contract.RiskCombination.InboundExternalSevereVulnerabilitySeverePermissionActionWorkloadResource]: [
                        "{{count | NumberFormatter.humanize}} *bold*public** workload with *bold*critical vulnerabilities** and *bold*high privileges**",
                        "{{count | NumberFormatter.humanize}} *bold*public** workloads with *bold*critical vulnerabilities** and *bold*high privileges**"
                    ],
                    [Contract.RiskCombination.InboundExternalSevereVulnerabilityWorkloadResource]: [
                        "{{count | NumberFormatter.humanize}} *bold*public** workload with *bold*critical vulnerabilities**",
                        "{{count | NumberFormatter.humanize}} *bold*public** workloads with *bold*critical vulnerabilities**"
                    ],
                    [Contract.RiskCombination.PublicAccessSensitiveDataResource]: [
                        "{{count | NumberFormatter.humanize}} *bold*public** data resource with *bold*sensitive data**",
                        "{{count | NumberFormatter.humanize}} *bold*public** data resources with *bold*sensitive data**"
                    ],
                    [Contract.RiskCombination.PublicAccessSeverePermissionActionComputeFunction]: [
                        "{{count | NumberFormatter.humanize}} *bold*public** function with *bold*high privileges**",
                        "{{count | NumberFormatter.humanize}} *bold*public** functions with *bold*high privileges**"
                    ],
                    [Contract.RiskCombination.SensitiveResourcePermissionActionExternalPrincipal]: [
                        "{{count | NumberFormatter.humanize}} *bold*external** principal with access to *bold*sensitive data**",
                        "{{count | NumberFormatter.humanize}} *bold*external** principals with access to *bold*sensitive data**"
                    ],
                    [Contract.RiskCombination.SensitiveResourcePermissionActionGuestUser]: [
                        "{{count | NumberFormatter.humanize}} *bold*guest** user with access to *bold*sensitive data**",
                        "{{count | NumberFormatter.humanize}} *bold*guest** users with access to *bold*sensitive data**"
                    ],
                    [Contract.RiskCombination.SensitiveResourcePermissionActionVendorServiceIdentity]: [
                        "{{count | NumberFormatter.humanize}} *bold*{{vendor}}** identity with access to *bold*sensitive data**",
                        "{{count | NumberFormatter.humanize}} *bold*{{vendor}}** identities with access to *bold*sensitive data**"
                    ],
                    [Contract.RiskCombination.SeverePermissionActionExternalPrincipal]: [
                        "{{count | NumberFormatter.humanize}} *bold*external** principal with *bold*high privileges**",
                        "{{count | NumberFormatter.humanize}} *bold*external** principals with *bold*high privileges**"
                    ],
                    [Contract.RiskCombination.SeverePermissionActionGuestUser]: [
                        "{{count | NumberFormatter.humanize}} *bold*guest** user with *bold*high privileges**",
                        "{{count | NumberFormatter.humanize}} *bold*guest** users with *bold*high privileges**"
                    ],
                    [Contract.RiskCombination.SevereVulnerabilitySeverePermissionActionWorkloadResource]: [
                        "{{count | NumberFormatter.humanize}} workload with *bold*critical vulnerabilities** and *bold*high privileges**",
                        "{{count | NumberFormatter.humanize}} workloads with *bold*critical vulnerabilities** and *bold*high privileges**"
                    ]
                }
            }));
    return (riskCombination: Contract.RiskCombination, count: number) =>
        localization[Contract.TypeNames.RiskCombination][riskCombination](
            count,
            {
                vendor: localization.vendor()
            });
}