import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, Entity, entityModelStore, InlineEntities, useEntityTypeNameTranslator, useTheme } from "../../../../../../../../../../../../common";
import { AzureNetworkNetworkSecurityGroupRulesTable } from "../../../../../../../../../../../../tenants";
import { RiskDefinitionContextItem, RiskDefinitionSection } from "../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { EntityExternalConsoleLink } from "../../components";
import { useGetAzureNetworkNetworkSecurityGroupRiskContext } from "../contexts";

export function useAzureNetworkNetworkSecurityGroupInboundRuleInsecureServiceTagRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AzureNetworkNetworkSecurityGroupInboundRuleInsecureServiceTagRisk;
    const networkSecurityGroupModel = entityModelStore.useGet(risk.entityId) as Contract.AzureNetworkNetworkSecurityGroupModel;
    const networkSecurityGroup = networkSecurityGroupModel.entity as Contract.AzureNetworkNetworkSecurityGroup;

    const getAzureNetworkNetworkSecurityGroupRiskContext = useGetAzureNetworkNetworkSecurityGroupRiskContext();

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.compliance.useAzureNetworkNetworkSecurityGroupInboundRuleInsecureServiceTagRiskDefinition",
            () => ({
                contextItems: {
                    sensitivePermissionActionSeverityNetworkedResourceIds: {
                        empty: "The {{translatedSecurityGroupTypeName}} is not attached to resources with critical or high severity permissions",
                        text: "The {{translatedSecurityGroupTypeName}} is attached to {{sensitivePermissionActionSeverityNetworkedResourceIds}} with critical or high severity permissions"
                    }
                },
                description: "{{networkSecurityGroup}} allows insecure inbound traffic via service tag",
                sections: {
                    inboundRules: "Inbound Rules",
                    resolution: {
                        step1: "Service tags alone aren't sufficient to secure traffic without considering the nature of the service and the traffic it sends. If the service tags inbound rules are not necessary, consider deleting them. Otherwise, it is recommended to enforce an additional layer of authentication on the application level."
                    }
                }
            }));
    const translatedSecurityGroupTypeName =
        entityTypeNameTranslator(
            networkSecurityGroup.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });

    const theme = useTheme();
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            networkSecurityGroup:
                <Entity
                    entityIdOrModel={networkSecurityGroupModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () =>
            [
                <EntityExternalConsoleLink
                    entityId={networkSecurityGroupModel.id}
                    key={networkSecurityGroupModel.id}
                    page={Contract.AzureConsoleEntityPage.InboundRules}/>,
                localization.sections.resolution.step1()
            ],
        riskModel,
        () => {
            const networkSecurityGroupRiskContext = getAzureNetworkNetworkSecurityGroupRiskContext(networkSecurityGroupModel);
            return [
                networkSecurityGroupRiskContext.generalInformation,
                networkSecurityGroupRiskContext.sensitive,
                networkSecurityGroupRiskContext.networkedResources,
                networkSecurityGroupRiskContext.sensitiveNetworkedResources,
                networkSecurityGroupRiskContext.subnets,
                _.isEmpty(networkSecurityGroupModel.networkedResourceTypeNameToIdsMap)
                    ? undefined
                    : new RiskDefinitionContextItem(
                        (_.isEmpty(risk.sensitivePermissionActionSeverityNetworkedResourceIds)
                            ? localization.contextItems.sensitivePermissionActionSeverityNetworkedResourceIds.empty
                            : localization.contextItems.sensitivePermissionActionSeverityNetworkedResourceIds.text)({
                            sensitivePermissionActionSeverityNetworkedResourceIds:
                                <InlineEntities
                                    entityIdsOrModels={risk.sensitivePermissionActionSeverityNetworkedResourceIds}
                                    entityTypeName={Contract.TypeNames.AzureResourceGroupResource}
                                    variant="itemCountAndType"/>,
                            translatedSecurityGroupTypeName
                        })),
                networkSecurityGroupRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        },
        {
            sections: [
                new RiskDefinitionSection(
                    <AzureNetworkNetworkSecurityGroupRulesTable
                        getHighlightColor={
                            (rulePath, opacity) =>
                                _.includes(risk.insecureNetworkSecurityGroupRulePaths, rulePath)
                                    ? opacity
                                        ? theme.palette.opacity(theme.palette.severity(riskModel.risk.severity), opacity)
                                        : theme.palette.severity(riskModel.risk.severity)
                                    : undefined}
                        rules={networkSecurityGroup.inboundRules}/>,
                    localization.sections.inboundRules(),
                    {
                        expandable: true
                    })
            ]
        });
}