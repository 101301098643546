﻿import { useMemo } from "react";
import { RiskDefinitionContextItem } from "../../../utilities";
import { useRiskPolicyConfigurationRetentionTimeFrameStep } from "./useRiskPolicyConfigurationRetentionTimeFrameStep";

export function useGetRiskPolicyConfigurationRetentionTimeFrameContextItem() {
    return useMemo(
        () => useRiskPolicyConfigurationRetentionTimeFrameContextItem,
        []);
}

function useRiskPolicyConfigurationRetentionTimeFrameContextItem(retentionTimeFrame: string) {
    return new RiskDefinitionContextItem(useRiskPolicyConfigurationRetentionTimeFrameStep(retentionTimeFrame));
}