import React from "react";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { Contract } from "../../../../../../../../../../common";
import { useCommonContainerImageInfoItemElements, useCustomEntityPropertyInfoItemElements } from "../../../../../Profile/hooks/useDefinition/hooks";
import { Info } from "../../../../components";
import { useCiDefaultEntityInfoItemElements } from "./useCiDefaultEntityInfoItemElements";

export function useCiContainerImageDefinition(entityModel: Contract.CiEntityModel, options?: DefinitionOptions) {
    const commonContainerImageInfoItemElements = useCommonContainerImageInfoItemElements(entityModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(entityModel);
    const defaultTenantInfoItemElements = useCiDefaultEntityInfoItemElements(entityModel);

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultTenantInfoItemElements}
                entityPropertyInfoItemElements={[
                    commonContainerImageInfoItemElements.repositoryInfoItemElement,
                    commonContainerImageInfoItemElements.tagsInfoItemElement,
                    commonContainerImageInfoItemElements.digestInfoItemElement,
                    commonContainerImageInfoItemElements.creationTimeInfoItemElement,
                    commonContainerImageInfoItemElements.operatingSystemTypeInfoItemElement,
                    commonContainerImageInfoItemElements.operatingSystemInfoItemElement,
                    commonContainerImageInfoItemElements.vulnerabilitiesInfoItemElement,
                    commonContainerImageInfoItemElements.scanTimeInfoItemElement,
                    commonContainerImageInfoItemElements.workloadClusterResourceIdsInfoItemElement,
                    commonContainerImageInfoItemElements.virtualMachineIdsInfoItemElement,
                    commonContainerImageInfoItemElements.kubernetesWorkloadResourceIdsInfoItemElement
                ]}
                options={options?.infoOptions}>
            </Info>
    });
}