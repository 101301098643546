﻿import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useAwsApiGatewayApiEndpointTypeTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useAwsApiGatewayApiEndpointTypeTranslator",
            () => ({
                [Contract.TypeNames.AwsApiGatewayApiEndpointType]: {
                    [Contract.AwsApiGatewayApiEndpointType.EdgeOptimized]: "Edge Optimized",
                    [Contract.AwsApiGatewayApiEndpointType.Private]: "Private",
                    [Contract.AwsApiGatewayApiEndpointType.Regional]: "Regional"
                }
            }));
    return (endpointType: Contract.AwsApiGatewayApiEndpointType) =>
        localization[Contract.TypeNames.AwsApiGatewayApiEndpointType][endpointType]();
}