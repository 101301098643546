export class KubernetesResourceHelper {
    public static capabilities =
        [
            "AUDIT_CONTROL",
            "AUDIT_READ",
            "AUDIT_WRITE",
            "BLOCK_SUSPEND",
            "BPF",
            "CHECKPOINT_RESTORE",
            "CHOWN",
            "DAC_OVERRIDE",
            "DAC_READ_SEARCH",
            "FOWNER",
            "FSETID",
            "IPC_LOCK",
            "IPC_OWNER",
            "KILL",
            "LEASE",
            "LINUX_IMMUTABLE",
            "MAC_ADMIN",
            "MAC_OVERRIDE",
            "MKNOD",
            "NET_ADMIN",
            "NET_BIND_SERVICE",
            "NET_BROADCAST",
            "NET_RAW",
            "PERFMON",
            "SETGID",
            "SETFCAP",
            "SETPCAP",
            "SETUID",
            "SYS_ADMIN"
        ];
    public static rawResourceTypeNames =
        [
            "CronJob",
            "DaemonSet",
            "Deployment",
            "Job",
            "Namespace",
            "Pod",
            "ReplicaSet",
            "StatefulSet"
        ];
}