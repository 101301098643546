import { UnexpectedError } from "@infrastructure";
import React from "react";
import { Contract, EntityTypeMetadataModelHelper } from "../../../../../../../../../../../../../common";
import { GenerateAwsResourceNonexcessivePolicyDialog, GenerateAzureIdentityNonexcessiveRoleDefinitionDialog, GenerateGcpIdentityNonexcessiveRoleDialog } from "../../../../../../../../../../../../../tenants";

type GenerateEntityNonexcessivePermitterDialogProps = {
    onClose: () => void;
    principalModel: Contract.IPrincipalModel;
};

export function GenerateEntityNonexcessivePermitterDialog({ onClose, principalModel }: GenerateEntityNonexcessivePermitterDialogProps) {
    const tenantType = EntityTypeMetadataModelHelper.get(principalModel.entity.typeName).tenantType;
    switch (tenantType) {
        case Contract.TenantType.Aad:
            return <GenerateAzureIdentityNonexcessiveRoleDefinitionDialog
                identityModel={principalModel}
                onClose={onClose}/>;
        case Contract.TenantType.Aws:
            return <GenerateAwsResourceNonexcessivePolicyDialog
                entityModel={principalModel}
                onClose={onClose}/>;
        case Contract.TenantType.Gci:
        case Contract.TenantType.Gcp:
            return <GenerateGcpIdentityNonexcessiveRoleDialog
                identityModel={principalModel}
                onClose={onClose}/>;
        default:
            throw new UnexpectedError("tenantType", tenantType);
    }
}