import { Link, Loading, useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { CustomerConsoleAppUrlHelper, InfoItem, InlineDeliveries, RiskPoliciesType, RiskPolicyItem, RiskPolicyTypeMetadataHelper, tenantModelStore } from "../../../../../../../../../../../common";
import { RisksView } from "../../../../../../../../../../../common/controllers/types.generated";
import { useConfigurationDefinition, useEntityCreationTimeAnalysisDelayTimeFrame, useRiskPoliciesItemConfiguration } from "../../../../../../../hooks";
import { RiskPolicyHelper } from "../../../../../../../utilities";
import { AnalyzedEntityCount } from "../../../../../../AnalyzedEntityCount";
import { FailedEntities } from "../../../../../../FailedEntities";
import { EntityExclusions, ExcludedEntities } from "../../../../../components";
import { useCommonRiskPolicyInfoItems } from "../../useCommonRiskPolicyInfoItems";

export function useCommonCloudRiskPolicyInfoItems(item: RiskPolicyItem, scopeId: string) {
    const { riskPolicyConfiguration } = useRiskPoliciesItemConfiguration(RiskPoliciesType.Cloud, item, scopeId);
    const { generalAdditionalItems } = useConfigurationDefinition(riskPolicyConfiguration, RiskPoliciesType.Cloud);
    const { translatedDelayTimeFrameValue } = useEntityCreationTimeAnalysisDelayTimeFrame(item, riskPolicyConfiguration);
    const commonRiskPolicyInfoItems = useCommonRiskPolicyInfoItems(item, scopeId);
    const tenantModels = tenantModelStore.useGetAll();

    const localization =
        useLocalization(
            "views.customer.riskPolicies.profile.hooks.useRiskPolicyInfoItems.hooks.cloud.hooks.useCommonCloudRiskPolicyInfoItems",
            () => ({
                analyzedEntityCount: {
                    count: "{{entityCount | NumberFormatter.humanize}}",
                    title: "Scanned Resources"
                },
                configuration: {
                    delay: {
                        title: "Delay"
                    }
                },
                excludedResources: "Excluded Resources",
                integrations: "Actions",
                resources: {
                    failed: "Failed Resources",
                    ignored: "Ignored Resources"
                },
                risks: {
                    open: [
                        "1 Finding",
                        "{{count | NumberFormatter.humanize}} Findings"
                    ],
                    title: "Findings"
                }
            }));

    return useMemo(
        () => ({
            ...commonRiskPolicyInfoItems,
            analyzedEntityCount:
                <InfoItem
                    title={localization.analyzedEntityCount.title()}
                    value={
                        <Loading container="cell">
                            <AnalyzedEntityCount
                                item={item}
                                scopeId={scopeId}/>
                        </Loading>}/>,
            configurationExcludedResources:
                RiskPolicyHelper.isEntityExclusionsExists(item, tenantModels)
                    ? <InfoItem
                        title={localization.excludedResources()}
                        value={
                            <EntityExclusions
                                item={item}
                                scopeId={scopeId}/>}/>
                    : undefined,
            excludedEntities:
                <InfoItem
                    title={localization.resources.ignored()}
                    value={
                        <Loading container="cell">
                            <ExcludedEntities
                                item={item}
                                scopeId={scopeId}/>
                        </Loading>}/>,
            failedEntities:
                <InfoItem
                    title={localization.resources.failed()}
                    value={
                        <Loading container="cell">
                            <FailedEntities
                                item={item}
                                scopeId={scopeId}/>
                        </Loading>}/>,
            getAdditionalInfoItems:
                () =>
                    _.map(
                        generalAdditionalItems,
                        ({ viewItem }) =>
                            <InfoItem
                                key={viewItem!.title}
                                title={viewItem!.title}
                                value={viewItem!.getValue(riskPolicyConfiguration, item, scopeId)}/>),
            getConfigurationDelay:
                () =>
                    RiskPolicyTypeMetadataHelper.get(riskPolicyConfiguration.typeName).riskPolicyEntityCreationTimeAnalysisDelayTimeFrameChangeable
                        ? <InfoItem
                            title={localization.configuration.delay.title()}
                            value={translatedDelayTimeFrameValue}/>
                        : undefined,
            getRisks:
                (tenantIds?: string[]) =>
                    <InfoItem
                        title={localization.risks.title()}>
                        {item.result.riskCount === 0 || !item.enabled
                            ? undefined
                            : <Link
                                urlOrGetUrl={
                                    CustomerConsoleAppUrlHelper.getRisksRelativeUrl(
                                        RisksView.Open,
                                        {
                                            policyConfigurationTypeNameOrIds: [item.riskPolicyConfigurationTypeNameOrId],
                                            tenantIds
                                        })}>
                                <Typography
                                    sx={{
                                        fontWeight: 400,
                                        textDecoration: "underline"
                                    }}>
                                    {localization.risks.open(
                                        item.result.riskCount,
                                        { count: item.result.riskCount })}
                                </Typography>
                            </Link>}
                    </InfoItem>,
            integration:
                <InfoItem
                    title={localization.integrations()}
                    vertical={_.size(item.deliveries) > 1}>
                    {_.isEmpty(item.deliveries)
                        ? undefined
                        : <InlineDeliveries
                            deliveries={
                                _.map(
                                    item.deliveries,
                                    ({ delivery }) => delivery)}
                            variant="groupedList"/>}
                </InfoItem>
        }),
        [commonRiskPolicyInfoItems, item, riskPolicyConfiguration, scopeId, tenantModels, translatedDelayTimeFrameValue]);
}