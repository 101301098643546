import { TextViewer, useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { useTheme } from "../../../../../../../../../../../../../../../../../../../common";
import { useAddOrEditContext } from "../../../../../AddOrEdit";

export function EnvironmentVariablesItem() {
    const { apiRootUrl } = useAddOrEditContext();
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCodePipelineItems.codePipeline.addOrEdit.hooks.useDefinition.hooks.useGeneralCodePipeline.environmentVariablesItem",
            () => ({
                description: "Create a file named **environment.variables** with the code below (adjust as needed):",
                environmentVariables: {
                    apiToken: {
                        description: "**TENABLE_API_TOKEN**: Replace with the value displayed in the add-pipeline wizard",
                        placeholder: "<api-token here>"
                    },
                    pipelineRunId: "**TENABLE_PIPELINE_RUN_ID**: Replace with the run ID of the pipeline",
                    pipelineRunTrigger: "**TENABLE_PIPELINE_RUN_TRIGGER**: Replace with the name of the event that triggered the workflow run",
                    pipelineRunUrl: "**TENABLE_PIPELINE_RUN_URL**: Replace with the URL where the results of this run can be found"
                }
            }));

    const environmentVariablesText =
        _.join(
            [
                `TENABLE_API_TOKEN=${localization.environmentVariables.apiToken.placeholder()}`,
                `TENABLE_API_URL=${apiRootUrl}`,
                `TENABLE_PIPELINE_RUN_ID=134235`,
                `TENABLE_PIPELINE_RUN_TRIGGER=pull_request`,
                `TENABLE_PIPELINE_RUN_URL=https://github.com/acme/repo1/actions/runs/134235`],
            "\n");

    const theme = useTheme();
    return (
        <Stack spacing={2}>
            <Typography sx={{ maxWidth: theme.spacing(60) }}>
                {localization.description()}
            </Typography>
            <TextViewer
                copyToClipboard={true}
                format="ini"
                text={environmentVariablesText}/>
            <Stack sx={{ maxWidth: theme.spacing(80) }}>
                <Typography>
                    {localization.environmentVariables.apiToken.description()}
                </Typography>
                <Typography>
                    {localization.environmentVariables.pipelineRunId()}
                </Typography>
                <Typography>
                    {localization.environmentVariables.pipelineRunTrigger()}
                </Typography>
                <Typography>
                    {localization.environmentVariables.pipelineRunUrl()}
                </Typography>
            </Stack>
        </Stack>);
}