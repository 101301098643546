import { useLocalization } from "@infrastructure";
import { ToolbarFilterId } from "../../../../../components";

export function useGcpNetworkToolbarFilterIdTranslator() {
    const localization =
        useLocalization(
            "common.network.hooks.useDefinition.hooks.useGcpDefinition.hooks.useGcpNetworkToolbarFilterIdTranslator",
            () => ({
                filters: {
                    [ToolbarFilterId.DestinationScopeFilterIds]: "Ports",
                    [ToolbarFilterId.FirewallPolicyAndVpcIds]: "Security Perimeters",
                    [ToolbarFilterId.SourceSubnets]: "IP Address Ranges"
                }
            }));
    return (toolbarFilterId: ToolbarFilterId) =>
        localization.filters.translate(toolbarFilterId);
}