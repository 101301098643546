import React from "react";

export function WelcomeImage() {
    return (
        <svg
            fill="none"
            height="268"
            viewBox="0 0 452 268"
            width="452"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M59.6589 267.253C84.0088 267.253 413.192 266.546 413.192 266.497C413.323 266.185 454.974 215.135 402.528 161.753C376.535 135.3 426.385 101.043 402.528 71.6325C361.649 21.2404 321.198 49.6157 287.499 35.4527C281.551 32.9553 275.571 28.9299 269.59 22.752C266.222 19.2852 262.541 16.2456 258.582 13.6168C257.974 13.206 257.366 12.8117 256.741 12.4338C254.375 10.9715 251.927 9.6406 249.397 8.44118C247.705 7.63609 245.98 6.89672 244.205 6.22307C241.412 5.13867 238.537 4.21857 235.612 3.42991C231.455 2.31264 227.167 1.47469 222.796 0.916058C220.89 0.669602 218.968 0.472439 217.029 0.324566C179.371 -2.50146 137.013 13.4196 115.144 36.685C110.741 41.3677 90.7288 65.6189 106.502 98.9398C116.459 119.987 74.7256 140.081 59.889 164.103C22.1977 225.174 59.3139 266.448 59.6589 267.253Z"
                fill="#C3D9FF"
                fillOpacity="0.2"/>
            <path
                d="M292.273 255.85C305.77 253.026 314.715 243.296 318.168 231.996C320.679 223.679 320.051 214.419 316.127 206.573C315.343 205.003 314.401 203.591 313.303 202.178C310.635 198.726 307.025 195.43 302.631 192.919C289.762 185.543 272.029 186.799 260.572 198.569C261.671 189.937 257.12 180.835 249.43 176.127C241.583 171.576 231.068 171.576 221.966 177.069C219.926 164.514 211.451 153.215 200.152 146.309C189.01 139.404 175.356 136.579 162.017 137.05C153.699 137.364 145.225 139.09 138.162 143.014C126.392 149.605 119.173 162.16 117.133 176.284C108.972 169.85 97.3591 167.81 87.3153 170.948C77.2714 174.087 69.2677 182.405 66.9136 192.292C66.4428 194.018 66.2859 196.058 66.9136 198.098C62.6764 195.744 57.0267 196.215 53.1033 198.883C51.6909 199.981 50.4354 201.394 49.4938 202.963C48.0813 205.474 47.2967 208.77 47.4536 212.065C40.5484 211.752 33.6433 211.595 27.052 213.321C22.5008 214.576 18.2636 216.93 15.2818 220.226C14.6541 220.697 14.1833 221.482 13.7124 222.109C12.9278 223.051 12.457 224.149 11.9861 225.405C10.2599 230.27 11.3584 235.763 14.0263 240.314C10.5737 240.785 7.43502 242.197 5.86567 245.336C2.41308 252.398 9.94599 259.774 16.8512 263.54C18.8773 264.61 20.9276 265.618 23 266.57L287.879 267.719C291.018 264.267 293.686 260.402 292.273 255.85Z"
                fill="white"/>
            <path
                d="M292.273 255.846C305.77 253.021 314.715 243.291 318.168 231.992C320.679 223.674 320.051 214.415 316.127 206.882C315.814 215.199 313.146 223.36 307.182 229.01C296.51 238.897 280.032 237.17 265.751 234.973C246.448 268.087 162.33 271.069 138.005 237.641C127.805 247.842 112.739 253.021 98.4577 251.295C84.1766 249.411 70.837 240.78 63.618 228.225C53.731 234.032 41.4901 235.915 30.1907 233.404C25.9534 232.305 21.7161 230.736 18.7344 227.597C16.8511 225.557 15.5957 222.889 15.2818 220.221C14.6541 220.692 14.1833 221.477 13.7124 222.105C12.9278 223.046 12.457 224.145 11.9861 225.4C10.2599 230.265 11.3584 235.758 14.0263 240.309C10.5737 240.78 7.43502 242.192 5.86567 245.331C2.41308 252.393 9.94599 259.769 16.8512 263.536C19.5268 264.948 22.2447 266.254 25 267.464L287.879 267.715C291.018 264.262 293.686 260.397 292.273 255.846Z"
                fill="#CDDDF8"/>
            <path
                d="M102.852 215.671C109.129 221.007 119.801 219.909 124.823 213.317C129.844 219.595 138.476 222.89 146.48 221.792C154.483 220.536 161.702 214.887 164.684 207.354C158.878 212.69 150.874 215.515 143.027 214.573C135.18 213.788 127.804 209.394 123.41 202.803C119.801 210.65 110.698 215.514 102.852 215.671Z"
                fill="#CDDDF8"/>
            <path
                d="M287.879 266.719C291.018 263.267 293.686 260.402 292.273 255.85C305.77 253.026 314.715 243.296 318.168 231.996C320.679 223.679 320.051 214.419 316.127 206.573C315.343 205.003 314.401 203.591 313.303 202.178C310.635 198.726 307.025 195.43 302.631 192.919C289.762 185.543 272.028 186.799 260.572 198.569C261.671 189.937 257.12 180.835 249.43 176.127C241.583 171.576 231.068 171.576 221.966 177.069C219.926 164.514 211.451 153.215 200.152 146.309C189.01 139.404 175.356 136.579 162.017 137.05C153.699 137.364 145.225 139.09 138.162 143.014C126.392 149.605 119.173 162.16 117.133 176.284C108.972 169.85 97.3591 167.81 87.3153 170.948C83.2483 172.219 79.5159 174.339 76.3576 177.069L74.5 179M71.4071 182.5C69.3028 185.457 67.7509 188.775 66.9136 192.292C66.4428 194.018 66.2859 196.058 66.9136 198.098C62.6764 195.744 57.0267 196.215 53.1033 198.883C51.6909 199.981 50.4354 201.394 49.4938 202.963C48.0813 205.474 47.2967 208.77 47.4536 212.065C40.5484 211.752 33.6433 211.595 27.052 213.321C22.5008 214.576 18.2636 216.93 15.2818 220.226C14.6541 220.697 14.1833 221.482 13.7124 222.109C12.9278 223.051 12.457 224.149 11.9861 225.405C10.2599 230.27 11.3584 235.763 14.0263 240.314C10.5737 240.785 7.43502 242.197 5.86567 245.336C2.41308 252.398 9.94599 259.774 16.8512 263.54C19.1847 264.772 21.5504 265.923 23.9451 267"
                stroke="#37474F"
                strokeLinecap="round"/>
            <path
                d="M243.424 62C243.424 59.2386 245.662 57 248.424 57C251.185 57 253.424 59.2386 253.424 62V261H243.424V62Z"
                fill="#EEF4FF"/>
            <path
                d="M243 212.569V58C243 55.2386 245.239 53 248 53V53C250.761 53 253 55.2386 253 58V261H243V218.176"
                stroke="#37474F"
                strokeLinecap="round"/>
            <rect
                fill="#CDDDF8"
                height="54"
                rx="7.5"
                stroke="#37474F"
                width="143"
                x="176.5"
                y="63.5"/>
            <rect
                fill="#9FB9DE"
                height="44"
                rx="5.5"
                stroke="#37474F"
                width="133"
                x="181.5"
                y="68.5"/>
            <path
                d="M199.277 98.6992L194.699 82.6992H198.395L201.043 93.8164H201.176L204.098 82.6992H207.262L210.176 93.8398H210.316L212.965 82.6992H216.66L212.082 98.6992H208.785L205.738 88.2383H205.613L202.574 98.6992H199.277Z"
                fill="#37474F"/>
            <path
                d="M222.588 98.9336C221.354 98.9336 220.291 98.6836 219.4 98.1836C218.515 97.6784 217.833 96.9648 217.354 96.043C216.874 95.1159 216.635 94.0195 216.635 92.7539C216.635 91.5195 216.874 90.4362 217.354 89.5039C217.833 88.5716 218.507 87.8451 219.377 87.3242C220.252 86.8034 221.278 86.543 222.455 86.543C223.247 86.543 223.984 86.6706 224.666 86.9258C225.354 87.1758 225.952 87.5534 226.463 88.0586C226.979 88.5638 227.38 89.1992 227.666 89.9648C227.952 90.7253 228.096 91.6159 228.096 92.6367V93.5508H217.963V91.4883H224.963C224.963 91.0091 224.859 90.5846 224.65 90.2148C224.442 89.8451 224.153 89.556 223.783 89.3477C223.419 89.1341 222.994 89.0273 222.51 89.0273C222.005 89.0273 221.557 89.1445 221.166 89.3789C220.781 89.6081 220.479 89.918 220.26 90.3086C220.041 90.694 219.929 91.1237 219.924 91.5977V93.5586C219.924 94.1523 220.033 94.6654 220.252 95.0977C220.476 95.5299 220.791 95.8633 221.197 96.0977C221.604 96.332 222.085 96.4492 222.643 96.4492C223.012 96.4492 223.351 96.3971 223.658 96.293C223.965 96.1888 224.229 96.0326 224.447 95.8242C224.666 95.6159 224.833 95.3607 224.947 95.0586L228.025 95.2617C227.869 96.0013 227.549 96.6471 227.064 97.1992C226.585 97.7461 225.965 98.1732 225.205 98.4805C224.45 98.7826 223.577 98.9336 222.588 98.9336Z"
                fill="#37474F"/>
            <path
                d="M233.596 82.6992V98.6992H230.268V82.6992H233.596Z"
                fill="#37474F"/>
            <path
                d="M241.684 98.9336C240.454 98.9336 239.397 98.6732 238.512 98.1523C237.632 97.6263 236.954 96.8971 236.48 95.9648C236.012 95.0326 235.777 93.9596 235.777 92.7461C235.777 91.5169 236.014 90.4388 236.488 89.5117C236.967 88.5794 237.647 87.8529 238.527 87.332C239.408 86.806 240.454 86.543 241.668 86.543C242.715 86.543 243.632 86.7331 244.418 87.1133C245.204 87.4935 245.827 88.0273 246.285 88.7148C246.743 89.4023 246.996 90.2096 247.043 91.1367H243.902C243.814 90.5378 243.579 90.056 243.199 89.6914C242.824 89.3216 242.332 89.1367 241.723 89.1367C241.207 89.1367 240.757 89.2773 240.371 89.5586C239.991 89.8346 239.694 90.2383 239.48 90.7695C239.267 91.3008 239.16 91.944 239.16 92.6992C239.16 93.4648 239.264 94.1159 239.473 94.6523C239.686 95.1888 239.986 95.5977 240.371 95.8789C240.757 96.1602 241.207 96.3008 241.723 96.3008C242.103 96.3008 242.444 96.2227 242.746 96.0664C243.053 95.9102 243.306 95.6836 243.504 95.3867C243.707 95.0846 243.84 94.7227 243.902 94.3008H247.043C246.991 95.2174 246.741 96.0247 246.293 96.7227C245.85 97.4154 245.238 97.957 244.457 98.3477C243.676 98.7383 242.751 98.9336 241.684 98.9336Z"
                fill="#37474F"/>
            <path
                d="M254.596 98.9336C253.382 98.9336 252.333 98.6758 251.447 98.1602C250.567 97.6393 249.887 96.9154 249.408 95.9883C248.929 95.056 248.689 93.9753 248.689 92.7461C248.689 91.5065 248.929 90.4232 249.408 89.4961C249.887 88.5638 250.567 87.8398 251.447 87.3242C252.333 86.8034 253.382 86.543 254.596 86.543C255.809 86.543 256.856 86.8034 257.736 87.3242C258.622 87.8398 259.304 88.5638 259.783 89.4961C260.262 90.4232 260.502 91.5065 260.502 92.7461C260.502 93.9753 260.262 95.056 259.783 95.9883C259.304 96.9154 258.622 97.6393 257.736 98.1602C256.856 98.6758 255.809 98.9336 254.596 98.9336ZM254.611 96.3555C255.163 96.3555 255.624 96.1992 255.994 95.8867C256.364 95.569 256.643 95.1367 256.83 94.5898C257.023 94.043 257.119 93.4206 257.119 92.7227C257.119 92.0247 257.023 91.4023 256.83 90.8555C256.643 90.3086 256.364 89.8763 255.994 89.5586C255.624 89.2409 255.163 89.082 254.611 89.082C254.054 89.082 253.585 89.2409 253.205 89.5586C252.83 89.8763 252.546 90.3086 252.354 90.8555C252.166 91.4023 252.072 92.0247 252.072 92.7227C252.072 93.4206 252.166 94.043 252.354 94.5898C252.546 95.1367 252.83 95.569 253.205 95.8867C253.585 96.1992 254.054 96.3555 254.611 96.3555Z"
                fill="#37474F"/>
            <path
                d="M262.666 98.6992V86.6992H265.838V88.8164H265.979C266.229 88.1133 266.645 87.5586 267.229 87.1523C267.812 86.7461 268.51 86.543 269.322 86.543C270.145 86.543 270.846 86.7487 271.424 87.1602C272.002 87.5664 272.387 88.1185 272.58 88.8164H272.705C272.95 88.1289 273.393 87.5794 274.033 87.168C274.679 86.7513 275.442 86.543 276.322 86.543C277.442 86.543 278.351 86.8997 279.049 87.6133C279.752 88.3216 280.104 89.3268 280.104 90.6289V98.6992H276.783V91.2852C276.783 90.6185 276.606 90.1185 276.252 89.7852C275.898 89.4518 275.455 89.2852 274.924 89.2852C274.32 89.2852 273.848 89.4779 273.51 89.8633C273.171 90.2435 273.002 90.7461 273.002 91.3711V98.6992H269.775V91.2148C269.775 90.6263 269.606 90.1576 269.268 89.8086C268.934 89.4596 268.494 89.2852 267.947 89.2852C267.577 89.2852 267.244 89.3789 266.947 89.5664C266.656 89.7487 266.424 90.0065 266.252 90.3398C266.08 90.668 265.994 91.0534 265.994 91.4961V98.6992H262.666Z"
                fill="#37474F"/>
            <path
                d="M288.201 98.9336C286.967 98.9336 285.904 98.6836 285.014 98.1836C284.128 97.6784 283.446 96.9648 282.967 96.043C282.488 95.1159 282.248 94.0195 282.248 92.7539C282.248 91.5195 282.488 90.4362 282.967 89.5039C283.446 88.5716 284.12 87.8451 284.99 87.3242C285.865 86.8034 286.891 86.543 288.068 86.543C288.86 86.543 289.597 86.6706 290.279 86.9258C290.967 87.1758 291.566 87.5534 292.076 88.0586C292.592 88.5638 292.993 89.1992 293.279 89.9648C293.566 90.7253 293.709 91.6159 293.709 92.6367V93.5508H283.576V91.4883H290.576C290.576 91.0091 290.472 90.5846 290.264 90.2148C290.055 89.8451 289.766 89.556 289.396 89.3477C289.032 89.1341 288.607 89.0273 288.123 89.0273C287.618 89.0273 287.17 89.1445 286.779 89.3789C286.394 89.6081 286.092 89.918 285.873 90.3086C285.654 90.694 285.542 91.1237 285.537 91.5977V93.5586C285.537 94.1523 285.646 94.6654 285.865 95.0977C286.089 95.5299 286.404 95.8633 286.811 96.0977C287.217 96.332 287.699 96.4492 288.256 96.4492C288.626 96.4492 288.964 96.3971 289.271 96.293C289.579 96.1888 289.842 96.0326 290.061 95.8242C290.279 95.6159 290.446 95.3607 290.561 95.0586L293.639 95.2617C293.482 96.0013 293.162 96.6471 292.678 97.1992C292.199 97.7461 291.579 98.1732 290.818 98.4805C290.063 98.7826 289.191 98.9336 288.201 98.9336Z"
                fill="#37474F"/>
            <path
                d="M299.857 82.6992L299.553 93.9023H296.693L296.381 82.6992H299.857ZM298.123 98.9023C297.607 98.9023 297.165 98.7201 296.795 98.3555C296.425 97.9857 296.243 97.543 296.248 97.0273C296.243 96.5169 296.425 96.0794 296.795 95.7148C297.165 95.3503 297.607 95.168 298.123 95.168C298.618 95.168 299.053 95.3503 299.428 95.7148C299.803 96.0794 299.993 96.5169 299.998 97.0273C299.993 97.3711 299.902 97.6862 299.725 97.9727C299.553 98.2539 299.326 98.4805 299.045 98.6523C298.764 98.819 298.456 98.9023 298.123 98.9023Z"
                fill="#37474F"/>
            <mask
                fill="black"
                height="19"
                id="path-11-outside-1_51221_242129"
                maskUnits="userSpaceOnUse"
                width="109"
                x="193"
                y="80">
                <rect
                    fill="white"
                    height="19"
                    width="109"
                    x="193"
                    y="80"/>
                <path d="M199.578 97L195 81H198.695L201.344 92.1172H201.477L204.398 81H207.562L210.477 92.1406H210.617L213.266 81H216.961L212.383 97H209.086L206.039 86.5391H205.914L202.875 97H199.578Z"/>
                <path d="M222.889 97.2344C221.654 97.2344 220.592 96.9844 219.701 96.4844C218.816 95.9792 218.133 95.2656 217.654 94.3438C217.175 93.4167 216.936 92.3203 216.936 91.0547C216.936 89.8203 217.175 88.737 217.654 87.8047C218.133 86.8724 218.808 86.1458 219.678 85.625C220.553 85.1042 221.579 84.8438 222.756 84.8438C223.548 84.8438 224.285 84.9714 224.967 85.2266C225.654 85.4766 226.253 85.8542 226.764 86.3594C227.279 86.8646 227.68 87.5 227.967 88.2656C228.253 89.026 228.396 89.9167 228.396 90.9375V91.8516H218.264V89.7891H225.264C225.264 89.3099 225.16 88.8854 224.951 88.5156C224.743 88.1458 224.454 87.8568 224.084 87.6484C223.719 87.4349 223.295 87.3281 222.811 87.3281C222.305 87.3281 221.857 87.4453 221.467 87.6797C221.081 87.9089 220.779 88.2188 220.561 88.6094C220.342 88.9948 220.23 89.4245 220.225 89.8984V91.8594C220.225 92.4531 220.334 92.9661 220.553 93.3984C220.777 93.8307 221.092 94.1641 221.498 94.3984C221.904 94.6328 222.386 94.75 222.943 94.75C223.313 94.75 223.652 94.6979 223.959 94.5938C224.266 94.4896 224.529 94.3333 224.748 94.125C224.967 93.9167 225.133 93.6615 225.248 93.3594L228.326 93.5625C228.17 94.3021 227.85 94.9479 227.365 95.5C226.886 96.0469 226.266 96.474 225.506 96.7812C224.751 97.0833 223.878 97.2344 222.889 97.2344Z"/>
                <path d="M233.896 81V97H230.568V81H233.896Z"/>
                <path d="M241.984 97.2344C240.755 97.2344 239.698 96.974 238.812 96.4531C237.932 95.9271 237.255 95.1979 236.781 94.2656C236.312 93.3333 236.078 92.2604 236.078 91.0469C236.078 89.8177 236.315 88.7396 236.789 87.8125C237.268 86.8802 237.948 86.1536 238.828 85.6328C239.708 85.1068 240.755 84.8438 241.969 84.8438C243.016 84.8438 243.932 85.0339 244.719 85.4141C245.505 85.7943 246.128 86.3281 246.586 87.0156C247.044 87.7031 247.297 88.5104 247.344 89.4375H244.203C244.115 88.8385 243.88 88.3568 243.5 87.9922C243.125 87.6224 242.633 87.4375 242.023 87.4375C241.508 87.4375 241.057 87.5781 240.672 87.8594C240.292 88.1354 239.995 88.5391 239.781 89.0703C239.568 89.6016 239.461 90.2448 239.461 91C239.461 91.7656 239.565 92.4167 239.773 92.9531C239.987 93.4896 240.286 93.8984 240.672 94.1797C241.057 94.4609 241.508 94.6016 242.023 94.6016C242.404 94.6016 242.745 94.5234 243.047 94.3672C243.354 94.2109 243.607 93.9844 243.805 93.6875C244.008 93.3854 244.141 93.0234 244.203 92.6016H247.344C247.292 93.5182 247.042 94.3255 246.594 95.0234C246.151 95.7161 245.539 96.2578 244.758 96.6484C243.977 97.0391 243.052 97.2344 241.984 97.2344Z"/>
                <path d="M254.896 97.2344C253.683 97.2344 252.633 96.9766 251.748 96.4609C250.868 95.9401 250.188 95.2161 249.709 94.2891C249.23 93.3568 248.99 92.276 248.99 91.0469C248.99 89.8073 249.23 88.724 249.709 87.7969C250.188 86.8646 250.868 86.1406 251.748 85.625C252.633 85.1042 253.683 84.8438 254.896 84.8438C256.11 84.8438 257.157 85.1042 258.037 85.625C258.923 86.1406 259.605 86.8646 260.084 87.7969C260.563 88.724 260.803 89.8073 260.803 91.0469C260.803 92.276 260.563 93.3568 260.084 94.2891C259.605 95.2161 258.923 95.9401 258.037 96.4609C257.157 96.9766 256.11 97.2344 254.896 97.2344ZM254.912 94.6562C255.464 94.6562 255.925 94.5 256.295 94.1875C256.665 93.8698 256.943 93.4375 257.131 92.8906C257.324 92.3438 257.42 91.7214 257.42 91.0234C257.42 90.3255 257.324 89.7031 257.131 89.1562C256.943 88.6094 256.665 88.1771 256.295 87.8594C255.925 87.5417 255.464 87.3828 254.912 87.3828C254.355 87.3828 253.886 87.5417 253.506 87.8594C253.131 88.1771 252.847 88.6094 252.654 89.1562C252.467 89.7031 252.373 90.3255 252.373 91.0234C252.373 91.7214 252.467 92.3438 252.654 92.8906C252.847 93.4375 253.131 93.8698 253.506 94.1875C253.886 94.5 254.355 94.6562 254.912 94.6562Z"/>
                <path d="M262.967 97V85H266.139V87.1172H266.279C266.529 86.4141 266.946 85.8594 267.529 85.4531C268.113 85.0469 268.811 84.8438 269.623 84.8438C270.446 84.8438 271.146 85.0495 271.725 85.4609C272.303 85.8672 272.688 86.4193 272.881 87.1172H273.006C273.251 86.4297 273.693 85.8802 274.334 85.4688C274.98 85.0521 275.743 84.8438 276.623 84.8438C277.743 84.8438 278.652 85.2005 279.35 85.9141C280.053 86.6224 280.404 87.6276 280.404 88.9297V97H277.084V89.5859C277.084 88.9193 276.907 88.4193 276.553 88.0859C276.199 87.7526 275.756 87.5859 275.225 87.5859C274.62 87.5859 274.149 87.7786 273.811 88.1641C273.472 88.5443 273.303 89.0469 273.303 89.6719V97H270.076V89.5156C270.076 88.9271 269.907 88.4583 269.568 88.1094C269.235 87.7604 268.795 87.5859 268.248 87.5859C267.878 87.5859 267.545 87.6797 267.248 87.8672C266.956 88.0495 266.725 88.3073 266.553 88.6406C266.381 88.9688 266.295 89.3542 266.295 89.7969V97H262.967Z"/>
                <path d="M288.502 97.2344C287.268 97.2344 286.205 96.9844 285.314 96.4844C284.429 95.9792 283.747 95.2656 283.268 94.3438C282.788 93.4167 282.549 92.3203 282.549 91.0547C282.549 89.8203 282.788 88.737 283.268 87.8047C283.747 86.8724 284.421 86.1458 285.291 85.625C286.166 85.1042 287.192 84.8438 288.369 84.8438C289.161 84.8438 289.898 84.9714 290.58 85.2266C291.268 85.4766 291.867 85.8542 292.377 86.3594C292.893 86.8646 293.294 87.5 293.58 88.2656C293.867 89.026 294.01 89.9167 294.01 90.9375V91.8516H283.877V89.7891H290.877C290.877 89.3099 290.773 88.8854 290.564 88.5156C290.356 88.1458 290.067 87.8568 289.697 87.6484C289.333 87.4349 288.908 87.3281 288.424 87.3281C287.919 87.3281 287.471 87.4453 287.08 87.6797C286.695 87.9089 286.393 88.2188 286.174 88.6094C285.955 88.9948 285.843 89.4245 285.838 89.8984V91.8594C285.838 92.4531 285.947 92.9661 286.166 93.3984C286.39 93.8307 286.705 94.1641 287.111 94.3984C287.518 94.6328 287.999 94.75 288.557 94.75C288.926 94.75 289.265 94.6979 289.572 94.5938C289.88 94.4896 290.143 94.3333 290.361 94.125C290.58 93.9167 290.747 93.6615 290.861 93.3594L293.939 93.5625C293.783 94.3021 293.463 94.9479 292.979 95.5C292.499 96.0469 291.88 96.474 291.119 96.7812C290.364 97.0833 289.492 97.2344 288.502 97.2344Z"/>
                <path d="M300.158 81L299.854 92.2031H296.994L296.682 81H300.158ZM298.424 97.2031C297.908 97.2031 297.465 97.0208 297.096 96.6562C296.726 96.2865 296.544 95.8438 296.549 95.3281C296.544 94.8177 296.726 94.3802 297.096 94.0156C297.465 93.651 297.908 93.4688 298.424 93.4688C298.919 93.4688 299.354 93.651 299.729 94.0156C300.104 94.3802 300.294 94.8177 300.299 95.3281C300.294 95.6719 300.202 95.987 300.025 96.2734C299.854 96.5547 299.627 96.7812 299.346 96.9531C299.064 97.1198 298.757 97.2031 298.424 97.2031Z"/>
            </mask>
            <path
                d="M199.578 97L195 81H198.695L201.344 92.1172H201.477L204.398 81H207.562L210.477 92.1406H210.617L213.266 81H216.961L212.383 97H209.086L206.039 86.5391H205.914L202.875 97H199.578Z"
                fill="white"/>
            <path
                d="M222.889 97.2344C221.654 97.2344 220.592 96.9844 219.701 96.4844C218.816 95.9792 218.133 95.2656 217.654 94.3438C217.175 93.4167 216.936 92.3203 216.936 91.0547C216.936 89.8203 217.175 88.737 217.654 87.8047C218.133 86.8724 218.808 86.1458 219.678 85.625C220.553 85.1042 221.579 84.8438 222.756 84.8438C223.548 84.8438 224.285 84.9714 224.967 85.2266C225.654 85.4766 226.253 85.8542 226.764 86.3594C227.279 86.8646 227.68 87.5 227.967 88.2656C228.253 89.026 228.396 89.9167 228.396 90.9375V91.8516H218.264V89.7891H225.264C225.264 89.3099 225.16 88.8854 224.951 88.5156C224.743 88.1458 224.454 87.8568 224.084 87.6484C223.719 87.4349 223.295 87.3281 222.811 87.3281C222.305 87.3281 221.857 87.4453 221.467 87.6797C221.081 87.9089 220.779 88.2188 220.561 88.6094C220.342 88.9948 220.23 89.4245 220.225 89.8984V91.8594C220.225 92.4531 220.334 92.9661 220.553 93.3984C220.777 93.8307 221.092 94.1641 221.498 94.3984C221.904 94.6328 222.386 94.75 222.943 94.75C223.313 94.75 223.652 94.6979 223.959 94.5938C224.266 94.4896 224.529 94.3333 224.748 94.125C224.967 93.9167 225.133 93.6615 225.248 93.3594L228.326 93.5625C228.17 94.3021 227.85 94.9479 227.365 95.5C226.886 96.0469 226.266 96.474 225.506 96.7812C224.751 97.0833 223.878 97.2344 222.889 97.2344Z"
                fill="white"/>
            <path
                d="M233.896 81V97H230.568V81H233.896Z"
                fill="white"/>
            <path
                d="M241.984 97.2344C240.755 97.2344 239.698 96.974 238.812 96.4531C237.932 95.9271 237.255 95.1979 236.781 94.2656C236.312 93.3333 236.078 92.2604 236.078 91.0469C236.078 89.8177 236.315 88.7396 236.789 87.8125C237.268 86.8802 237.948 86.1536 238.828 85.6328C239.708 85.1068 240.755 84.8438 241.969 84.8438C243.016 84.8438 243.932 85.0339 244.719 85.4141C245.505 85.7943 246.128 86.3281 246.586 87.0156C247.044 87.7031 247.297 88.5104 247.344 89.4375H244.203C244.115 88.8385 243.88 88.3568 243.5 87.9922C243.125 87.6224 242.633 87.4375 242.023 87.4375C241.508 87.4375 241.057 87.5781 240.672 87.8594C240.292 88.1354 239.995 88.5391 239.781 89.0703C239.568 89.6016 239.461 90.2448 239.461 91C239.461 91.7656 239.565 92.4167 239.773 92.9531C239.987 93.4896 240.286 93.8984 240.672 94.1797C241.057 94.4609 241.508 94.6016 242.023 94.6016C242.404 94.6016 242.745 94.5234 243.047 94.3672C243.354 94.2109 243.607 93.9844 243.805 93.6875C244.008 93.3854 244.141 93.0234 244.203 92.6016H247.344C247.292 93.5182 247.042 94.3255 246.594 95.0234C246.151 95.7161 245.539 96.2578 244.758 96.6484C243.977 97.0391 243.052 97.2344 241.984 97.2344Z"
                fill="white"/>
            <path
                d="M254.896 97.2344C253.683 97.2344 252.633 96.9766 251.748 96.4609C250.868 95.9401 250.188 95.2161 249.709 94.2891C249.23 93.3568 248.99 92.276 248.99 91.0469C248.99 89.8073 249.23 88.724 249.709 87.7969C250.188 86.8646 250.868 86.1406 251.748 85.625C252.633 85.1042 253.683 84.8438 254.896 84.8438C256.11 84.8438 257.157 85.1042 258.037 85.625C258.923 86.1406 259.605 86.8646 260.084 87.7969C260.563 88.724 260.803 89.8073 260.803 91.0469C260.803 92.276 260.563 93.3568 260.084 94.2891C259.605 95.2161 258.923 95.9401 258.037 96.4609C257.157 96.9766 256.11 97.2344 254.896 97.2344ZM254.912 94.6562C255.464 94.6562 255.925 94.5 256.295 94.1875C256.665 93.8698 256.943 93.4375 257.131 92.8906C257.324 92.3438 257.42 91.7214 257.42 91.0234C257.42 90.3255 257.324 89.7031 257.131 89.1562C256.943 88.6094 256.665 88.1771 256.295 87.8594C255.925 87.5417 255.464 87.3828 254.912 87.3828C254.355 87.3828 253.886 87.5417 253.506 87.8594C253.131 88.1771 252.847 88.6094 252.654 89.1562C252.467 89.7031 252.373 90.3255 252.373 91.0234C252.373 91.7214 252.467 92.3438 252.654 92.8906C252.847 93.4375 253.131 93.8698 253.506 94.1875C253.886 94.5 254.355 94.6562 254.912 94.6562Z"
                fill="white"/>
            <path
                d="M262.967 97V85H266.139V87.1172H266.279C266.529 86.4141 266.946 85.8594 267.529 85.4531C268.113 85.0469 268.811 84.8438 269.623 84.8438C270.446 84.8438 271.146 85.0495 271.725 85.4609C272.303 85.8672 272.688 86.4193 272.881 87.1172H273.006C273.251 86.4297 273.693 85.8802 274.334 85.4688C274.98 85.0521 275.743 84.8438 276.623 84.8438C277.743 84.8438 278.652 85.2005 279.35 85.9141C280.053 86.6224 280.404 87.6276 280.404 88.9297V97H277.084V89.5859C277.084 88.9193 276.907 88.4193 276.553 88.0859C276.199 87.7526 275.756 87.5859 275.225 87.5859C274.62 87.5859 274.149 87.7786 273.811 88.1641C273.472 88.5443 273.303 89.0469 273.303 89.6719V97H270.076V89.5156C270.076 88.9271 269.907 88.4583 269.568 88.1094C269.235 87.7604 268.795 87.5859 268.248 87.5859C267.878 87.5859 267.545 87.6797 267.248 87.8672C266.956 88.0495 266.725 88.3073 266.553 88.6406C266.381 88.9688 266.295 89.3542 266.295 89.7969V97H262.967Z"
                fill="white"/>
            <path
                d="M288.502 97.2344C287.268 97.2344 286.205 96.9844 285.314 96.4844C284.429 95.9792 283.747 95.2656 283.268 94.3438C282.788 93.4167 282.549 92.3203 282.549 91.0547C282.549 89.8203 282.788 88.737 283.268 87.8047C283.747 86.8724 284.421 86.1458 285.291 85.625C286.166 85.1042 287.192 84.8438 288.369 84.8438C289.161 84.8438 289.898 84.9714 290.58 85.2266C291.268 85.4766 291.867 85.8542 292.377 86.3594C292.893 86.8646 293.294 87.5 293.58 88.2656C293.867 89.026 294.01 89.9167 294.01 90.9375V91.8516H283.877V89.7891H290.877C290.877 89.3099 290.773 88.8854 290.564 88.5156C290.356 88.1458 290.067 87.8568 289.697 87.6484C289.333 87.4349 288.908 87.3281 288.424 87.3281C287.919 87.3281 287.471 87.4453 287.08 87.6797C286.695 87.9089 286.393 88.2188 286.174 88.6094C285.955 88.9948 285.843 89.4245 285.838 89.8984V91.8594C285.838 92.4531 285.947 92.9661 286.166 93.3984C286.39 93.8307 286.705 94.1641 287.111 94.3984C287.518 94.6328 287.999 94.75 288.557 94.75C288.926 94.75 289.265 94.6979 289.572 94.5938C289.88 94.4896 290.143 94.3333 290.361 94.125C290.58 93.9167 290.747 93.6615 290.861 93.3594L293.939 93.5625C293.783 94.3021 293.463 94.9479 292.979 95.5C292.499 96.0469 291.88 96.474 291.119 96.7812C290.364 97.0833 289.492 97.2344 288.502 97.2344Z"
                fill="white"/>
            <path
                d="M300.158 81L299.854 92.2031H296.994L296.682 81H300.158ZM298.424 97.2031C297.908 97.2031 297.465 97.0208 297.096 96.6562C296.726 96.2865 296.544 95.8438 296.549 95.3281C296.544 94.8177 296.726 94.3802 297.096 94.0156C297.465 93.651 297.908 93.4688 298.424 93.4688C298.919 93.4688 299.354 93.651 299.729 94.0156C300.104 94.3802 300.294 94.8177 300.299 95.3281C300.294 95.6719 300.202 95.987 300.025 96.2734C299.854 96.5547 299.627 96.7812 299.346 96.9531C299.064 97.1198 298.757 97.2031 298.424 97.2031Z"
                fill="white"/>
            <path
                d="M199.578 97L195 81H198.695L201.344 92.1172H201.477L204.398 81H207.562L210.477 92.1406H210.617L213.266 81H216.961L212.383 97H209.086L206.039 86.5391H205.914L202.875 97H199.578Z"
                mask="url(#path-11-outside-1_51221_242129)"
                stroke="#37474F"
                strokeWidth="2"/>
            <path
                d="M222.889 97.2344C221.654 97.2344 220.592 96.9844 219.701 96.4844C218.816 95.9792 218.133 95.2656 217.654 94.3438C217.175 93.4167 216.936 92.3203 216.936 91.0547C216.936 89.8203 217.175 88.737 217.654 87.8047C218.133 86.8724 218.808 86.1458 219.678 85.625C220.553 85.1042 221.579 84.8438 222.756 84.8438C223.548 84.8438 224.285 84.9714 224.967 85.2266C225.654 85.4766 226.253 85.8542 226.764 86.3594C227.279 86.8646 227.68 87.5 227.967 88.2656C228.253 89.026 228.396 89.9167 228.396 90.9375V91.8516H218.264V89.7891H225.264C225.264 89.3099 225.16 88.8854 224.951 88.5156C224.743 88.1458 224.454 87.8568 224.084 87.6484C223.719 87.4349 223.295 87.3281 222.811 87.3281C222.305 87.3281 221.857 87.4453 221.467 87.6797C221.081 87.9089 220.779 88.2188 220.561 88.6094C220.342 88.9948 220.23 89.4245 220.225 89.8984V91.8594C220.225 92.4531 220.334 92.9661 220.553 93.3984C220.777 93.8307 221.092 94.1641 221.498 94.3984C221.904 94.6328 222.386 94.75 222.943 94.75C223.313 94.75 223.652 94.6979 223.959 94.5938C224.266 94.4896 224.529 94.3333 224.748 94.125C224.967 93.9167 225.133 93.6615 225.248 93.3594L228.326 93.5625C228.17 94.3021 227.85 94.9479 227.365 95.5C226.886 96.0469 226.266 96.474 225.506 96.7812C224.751 97.0833 223.878 97.2344 222.889 97.2344Z"
                mask="url(#path-11-outside-1_51221_242129)"
                stroke="#37474F"
                strokeWidth="2"/>
            <path
                d="M233.896 81V97H230.568V81H233.896Z"
                mask="url(#path-11-outside-1_51221_242129)"
                stroke="#37474F"
                strokeWidth="2"/>
            <path
                d="M241.984 97.2344C240.755 97.2344 239.698 96.974 238.812 96.4531C237.932 95.9271 237.255 95.1979 236.781 94.2656C236.312 93.3333 236.078 92.2604 236.078 91.0469C236.078 89.8177 236.315 88.7396 236.789 87.8125C237.268 86.8802 237.948 86.1536 238.828 85.6328C239.708 85.1068 240.755 84.8438 241.969 84.8438C243.016 84.8438 243.932 85.0339 244.719 85.4141C245.505 85.7943 246.128 86.3281 246.586 87.0156C247.044 87.7031 247.297 88.5104 247.344 89.4375H244.203C244.115 88.8385 243.88 88.3568 243.5 87.9922C243.125 87.6224 242.633 87.4375 242.023 87.4375C241.508 87.4375 241.057 87.5781 240.672 87.8594C240.292 88.1354 239.995 88.5391 239.781 89.0703C239.568 89.6016 239.461 90.2448 239.461 91C239.461 91.7656 239.565 92.4167 239.773 92.9531C239.987 93.4896 240.286 93.8984 240.672 94.1797C241.057 94.4609 241.508 94.6016 242.023 94.6016C242.404 94.6016 242.745 94.5234 243.047 94.3672C243.354 94.2109 243.607 93.9844 243.805 93.6875C244.008 93.3854 244.141 93.0234 244.203 92.6016H247.344C247.292 93.5182 247.042 94.3255 246.594 95.0234C246.151 95.7161 245.539 96.2578 244.758 96.6484C243.977 97.0391 243.052 97.2344 241.984 97.2344Z"
                mask="url(#path-11-outside-1_51221_242129)"
                stroke="#37474F"
                strokeWidth="2"/>
            <path
                d="M254.896 97.2344C253.683 97.2344 252.633 96.9766 251.748 96.4609C250.868 95.9401 250.188 95.2161 249.709 94.2891C249.23 93.3568 248.99 92.276 248.99 91.0469C248.99 89.8073 249.23 88.724 249.709 87.7969C250.188 86.8646 250.868 86.1406 251.748 85.625C252.633 85.1042 253.683 84.8438 254.896 84.8438C256.11 84.8438 257.157 85.1042 258.037 85.625C258.923 86.1406 259.605 86.8646 260.084 87.7969C260.563 88.724 260.803 89.8073 260.803 91.0469C260.803 92.276 260.563 93.3568 260.084 94.2891C259.605 95.2161 258.923 95.9401 258.037 96.4609C257.157 96.9766 256.11 97.2344 254.896 97.2344ZM254.912 94.6562C255.464 94.6562 255.925 94.5 256.295 94.1875C256.665 93.8698 256.943 93.4375 257.131 92.8906C257.324 92.3438 257.42 91.7214 257.42 91.0234C257.42 90.3255 257.324 89.7031 257.131 89.1562C256.943 88.6094 256.665 88.1771 256.295 87.8594C255.925 87.5417 255.464 87.3828 254.912 87.3828C254.355 87.3828 253.886 87.5417 253.506 87.8594C253.131 88.1771 252.847 88.6094 252.654 89.1562C252.467 89.7031 252.373 90.3255 252.373 91.0234C252.373 91.7214 252.467 92.3438 252.654 92.8906C252.847 93.4375 253.131 93.8698 253.506 94.1875C253.886 94.5 254.355 94.6562 254.912 94.6562Z"
                mask="url(#path-11-outside-1_51221_242129)"
                stroke="#37474F"
                strokeWidth="2"/>
            <path
                d="M262.967 97V85H266.139V87.1172H266.279C266.529 86.4141 266.946 85.8594 267.529 85.4531C268.113 85.0469 268.811 84.8438 269.623 84.8438C270.446 84.8438 271.146 85.0495 271.725 85.4609C272.303 85.8672 272.688 86.4193 272.881 87.1172H273.006C273.251 86.4297 273.693 85.8802 274.334 85.4688C274.98 85.0521 275.743 84.8438 276.623 84.8438C277.743 84.8438 278.652 85.2005 279.35 85.9141C280.053 86.6224 280.404 87.6276 280.404 88.9297V97H277.084V89.5859C277.084 88.9193 276.907 88.4193 276.553 88.0859C276.199 87.7526 275.756 87.5859 275.225 87.5859C274.62 87.5859 274.149 87.7786 273.811 88.1641C273.472 88.5443 273.303 89.0469 273.303 89.6719V97H270.076V89.5156C270.076 88.9271 269.907 88.4583 269.568 88.1094C269.235 87.7604 268.795 87.5859 268.248 87.5859C267.878 87.5859 267.545 87.6797 267.248 87.8672C266.956 88.0495 266.725 88.3073 266.553 88.6406C266.381 88.9688 266.295 89.3542 266.295 89.7969V97H262.967Z"
                mask="url(#path-11-outside-1_51221_242129)"
                stroke="#37474F"
                strokeWidth="2"/>
            <path
                d="M288.502 97.2344C287.268 97.2344 286.205 96.9844 285.314 96.4844C284.429 95.9792 283.747 95.2656 283.268 94.3438C282.788 93.4167 282.549 92.3203 282.549 91.0547C282.549 89.8203 282.788 88.737 283.268 87.8047C283.747 86.8724 284.421 86.1458 285.291 85.625C286.166 85.1042 287.192 84.8438 288.369 84.8438C289.161 84.8438 289.898 84.9714 290.58 85.2266C291.268 85.4766 291.867 85.8542 292.377 86.3594C292.893 86.8646 293.294 87.5 293.58 88.2656C293.867 89.026 294.01 89.9167 294.01 90.9375V91.8516H283.877V89.7891H290.877C290.877 89.3099 290.773 88.8854 290.564 88.5156C290.356 88.1458 290.067 87.8568 289.697 87.6484C289.333 87.4349 288.908 87.3281 288.424 87.3281C287.919 87.3281 287.471 87.4453 287.08 87.6797C286.695 87.9089 286.393 88.2188 286.174 88.6094C285.955 88.9948 285.843 89.4245 285.838 89.8984V91.8594C285.838 92.4531 285.947 92.9661 286.166 93.3984C286.39 93.8307 286.705 94.1641 287.111 94.3984C287.518 94.6328 287.999 94.75 288.557 94.75C288.926 94.75 289.265 94.6979 289.572 94.5938C289.88 94.4896 290.143 94.3333 290.361 94.125C290.58 93.9167 290.747 93.6615 290.861 93.3594L293.939 93.5625C293.783 94.3021 293.463 94.9479 292.979 95.5C292.499 96.0469 291.88 96.474 291.119 96.7812C290.364 97.0833 289.492 97.2344 288.502 97.2344Z"
                mask="url(#path-11-outside-1_51221_242129)"
                stroke="#37474F"
                strokeWidth="2"/>
            <path
                d="M300.158 81L299.854 92.2031H296.994L296.682 81H300.158ZM298.424 97.2031C297.908 97.2031 297.465 97.0208 297.096 96.6562C296.726 96.2865 296.544 95.8438 296.549 95.3281C296.544 94.8177 296.726 94.3802 297.096 94.0156C297.465 93.651 297.908 93.4688 298.424 93.4688C298.919 93.4688 299.354 93.651 299.729 94.0156C300.104 94.3802 300.294 94.8177 300.299 95.3281C300.294 95.6719 300.202 95.987 300.025 96.2734C299.854 96.5547 299.627 96.7812 299.346 96.9531C299.064 97.1198 298.757 97.2031 298.424 97.2031Z"
                mask="url(#path-11-outside-1_51221_242129)"
                stroke="#37474F"
                strokeWidth="2"/>
            <path
                d="M308.966 40.1187C308.83 40.1483 308.695 40.1777 308.561 40.2074C302.448 41.1964 295.932 40.8464 290.915 37.5294C289.929 36.8719 289.05 36.1143 288.691 35.1089L288.69 35.1062C288.406 34.325 288.466 33.3589 288.7 32.4971C289.32 30.4912 290.957 28.8373 292.864 27.8839L292.872 27.8799L292.88 27.8756C293.724 27.4156 294.658 27.177 295.632 27.014C296.922 26.8536 298.128 26.8541 299.418 27.0153L300.298 27.1253L299.937 26.3153C297.912 21.7792 297.993 16.2636 300.178 11.811C300.504 11.1602 300.911 10.4277 301.317 9.77736C304.799 4.68273 311.281 1.76947 317.425 2.65867L317.425 2.65871L317.431 2.65956C323.578 3.46832 329.012 8.00672 331.037 13.8382L332.007 13.7195C332.197 11.6323 333.504 10.025 335.373 8.91159C337.246 7.79554 339.639 7.20821 341.861 7.16782L341.862 7.16779C345.87 7.08599 350.049 8.15273 352.837 11.0201L352.842 11.0251C353.861 12.0444 354.577 13.3081 355.058 14.6706C355.851 17.0548 355.679 19.6347 354.286 21.6471L353.723 22.4606L354.711 22.4315C357.458 22.3507 360.252 23.0801 362.306 24.7395L362.313 24.7452L362.321 24.7505C362.616 24.9717 362.919 25.2722 363.171 25.5246C363.672 26.1097 364.119 26.6378 364.416 27.3061L364.439 27.3578L364.473 27.403C365.381 28.614 365.691 30.1337 365.384 31.6726C365.117 32.929 364.367 34.1134 363.404 35.0168C362.437 35.9245 361.303 36.5051 360.313 36.6151L360.061 36.6431L359.935 36.863C357.767 40.6365 353.66 43.2192 349.308 43.7044C344.957 44.1058 340.448 42.4101 337.557 39.1176L336.945 38.4214L336.699 39.3145C336.077 41.5691 334.438 43.2896 332.305 44.4965C330.172 45.7043 327.578 46.3761 325.137 46.5388L325.137 46.5387L325.129 46.5394C322.174 46.7856 319.105 46.5341 316.464 45.2533L316.464 45.2532L316.456 45.2497C314.232 44.2171 312.332 42.2304 311.547 39.9518L311.39 39.4979L310.93 39.6359C310.266 39.8353 309.604 39.9796 308.966 40.1187Z"
                fill="#FEFFFE"
                stroke="#37474F"/>
            <path
                d="M365.874 31.7739C365.29 34.5264 362.621 36.8618 360.369 37.1121C358.117 41.0323 353.863 43.7014 349.359 44.2018C344.855 44.6189 340.184 42.8673 337.181 39.4475C335.846 44.2852 330.175 46.7041 325.17 47.0377C322.167 47.2879 318.998 47.0377 316.245 45.7032C313.91 44.6189 311.908 42.5336 311.074 40.1148C310.24 40.365 309.406 40.5318 308.655 40.6986C302.483 41.6995 295.81 41.3659 290.639 37.9462C289.638 37.2789 288.637 36.4448 288.22 35.2771C287.886 34.3596 287.97 33.2753 288.22 32.3578C288.887 30.1891 290.639 28.4376 292.641 27.4367C293.558 26.9362 294.559 26.686 295.56 26.5192C296.895 26.3524 298.146 26.3524 299.48 26.5192C297.395 21.8483 297.478 16.1765 299.73 11.589C300.064 10.9217 300.481 10.1711 300.898 9.50379C304.485 4.24904 311.157 1.24633 317.497 2.16383C323.836 2.99792 329.424 7.6688 331.509 13.6742C331.926 9.08675 337.264 6.75131 341.852 6.6679C345.939 6.58449 350.276 7.6688 353.195 10.6715C354.28 11.7558 355.03 13.0904 355.531 14.5083C356.365 17.0106 356.198 19.7631 354.697 21.9317C357.533 21.8483 360.452 22.599 362.621 24.3505C362.954 24.6008 363.288 24.9344 363.538 25.1846C364.039 25.7685 364.539 26.3524 364.873 27.103C365.874 28.4376 366.207 30.1057 365.874 31.7739Z"
                fill="#FEFFFE"/>
            <path
                d="M365.874 31.7735C365.29 34.526 362.621 36.8614 360.369 37.1117C358.117 41.0319 353.863 43.701 349.359 44.2014C344.855 44.6184 340.184 42.8669 337.181 39.4471C335.846 44.2848 330.175 46.7037 325.17 47.0373C322.167 47.2875 318.998 47.0373 316.245 45.7028C313.91 44.6184 311.908 42.5332 311.074 40.1144C310.24 40.3646 309.406 40.5314 308.655 40.6982C302.483 41.6991 295.81 41.3655 290.639 37.9458C289.638 37.2785 288.637 36.4444 288.22 35.2767C287.886 34.3592 287.97 33.2749 288.22 32.3574C288.887 30.1887 290.639 28.4372 292.641 27.4363C295.143 29.7717 298.563 31.1062 302.066 31.1896C305.903 31.1896 309.656 29.6049 312.325 26.8524C315.662 29.7717 320.249 31.2731 324.67 30.9394C329.09 30.6058 333.344 28.3538 336.18 24.8506C337.598 27.3528 340.267 29.1878 343.103 29.5215C345.939 29.8551 349.025 28.8542 350.943 26.769C352.612 29.021 355.698 30.1887 358.45 29.5215C360.786 29.021 362.704 27.2694 363.622 25.1008C364.122 25.6847 364.622 26.2685 364.956 27.0192C365.874 28.4372 366.207 30.1053 365.874 31.7735Z"
                fill="#CDDDF8"/>
            <path
                d="M341.852 6.6679C337.264 6.75131 331.926 9.08675 331.509 13.6742C329.424 7.6688 323.836 2.99792 317.497 2.16383C311.157 1.24633 304.485 4.24904 300.898 9.50379C300.481 10.1711 300.064 10.9217 299.73 11.589C297.478 16.1765 297.395 21.8483 299.48 26.5192C298.146 26.3524 296.895 26.3524 295.56 26.5192C294.559 26.686 293.558 26.9362 292.641 27.4367C290.639 28.4376 288.887 30.1891 288.22 32.3578C287.97 33.2753 287.886 34.3596 288.22 35.2771C288.637 36.4448 289.638 37.2789 290.639 37.9462C295.81 41.3659 302.483 41.6995 308.655 40.6986C309.406 40.5318 310.24 40.365 311.074 40.1148C311.908 42.5336 313.91 44.6189 316.245 45.7032C318.998 47.0377 322.167 47.2879 325.17 47.0377C330.175 46.7041 335.846 44.2852 337.181 39.4475C340.184 42.8673 344.855 44.6189 349.359 44.2018C353.863 43.7014 358.117 41.0323 360.369 37.1121C362.621 36.8618 365.29 34.5264 365.874 31.7739C366.207 30.1057 365.874 28.4376 364.873 27.103C364.539 26.3524 364.039 25.7685 363.538 25.1846C363.288 24.9344 362.954 24.6008 362.621 24.3505C360.452 22.599 357.533 21.8483 354.697 21.9317C356.198 19.7631 356.365 17.0106 355.531 14.5083C355.03 13.0904 354.28 11.7558 353.195 10.6715C351.379 8.80291 349.013 7.67723 346.5 7.11357"
                stroke="#37474F"
                strokeLinecap="round"/>
            <path
                d="M325.755 40.2789C323.753 40.3624 321.834 40.9462 319.916 41.2799C322.919 42.1973 326.589 41.9471 329.091 39.9453C328.007 40.1955 326.922 40.1955 325.755 40.2789Z"
                fill="#FEFFFE"/>
            <path
                d="M329.842 35.1078C327.674 35.5248 326.089 36.1921 324.254 37.1096C326.589 37.193 329.008 36.5257 330.843 35.0244C331.594 34.6073 330.176 35.1078 329.842 35.1078Z"
                fill="#FEFFFE"/>
            <path
                d="M184.876 264.477C183.214 266.416 181.829 264.77 181.137 267.263L437.606 267.294C437.052 266.047 436.36 267.94 435.667 266.832C434.836 265.447 433.728 264.201 432.482 263.093C427.358 258.661 419.88 257.138 413.51 259.492C416.28 254.507 416.557 250.491 416.003 246.752C414.895 237.196 409.494 228.472 401.739 223.487C394.122 218.501 384.152 217.393 375.566 220.44C377.505 213.793 376.674 208.392 374.181 204.238C371.827 200.083 367.949 196.898 363.656 194.821C354.378 190.389 343.161 190.528 334.021 195.513C325.02 200.499 318.234 210.054 316.434 220.579C309.925 216.978 300.647 221.548 298.154 229.303C290.399 220.025 278.213 218.224 268.242 222.656C258.271 227.087 251.07 237.473 250.239 248.829C244.008 241.766 235.422 240.105 228.498 243.151C221.574 246.198 216.727 253.814 216.727 261.708C210.772 258.938 205.371 257.692 199.832 257.969C194.431 258.246 189.03 260.323 185.153 264.201L184.876 264.477Z"
                fill="white"/>
            <path
                d="M182.502 267C182.64 266.862 185.015 264.339 185.153 264.201C189.031 260.323 194.432 258.246 199.833 257.969C205.372 257.692 210.773 258.938 216.727 261.708C216.727 253.814 221.574 246.198 228.498 243.151C235.422 240.105 244.008 241.766 250.24 248.829C251.071 237.473 258.272 227.087 268.243 222.656C278.214 218.224 290.4 220.025 298.155 229.303C300.648 221.548 309.926 216.978 316.435 220.579C318.235 210.054 325.021 200.499 334.022 195.513C341.368 191.506 350.057 190.63 358.002 192.742M363.657 194.821C367.95 196.898 371.828 200.083 374.182 204.238C376.674 208.392 377.505 213.793 375.567 220.44C384.152 217.393 394.123 218.501 401.74 223.487C409.495 228.472 414.895 237.196 416.003 246.752C416.557 250.491 416.28 254.507 413.511 259.492C419.881 257.138 427.359 258.661 432.483 263.093C433.729 264.201 434.837 265.447 435.668 266.832"
                stroke="#37474F"
                strokeLinecap="round"/>
            <path
                d="M451.314 268H0.563826C0.252749 268 0 267.827 0 267.613V267.387C0 267.173 0.252749 267 0.563826 267H451.295C451.606 267 451.859 267.173 451.859 267.387V267.613C451.878 267.827 451.625 268 451.314 268Z"
                fill="#9E9E9E"/>
        </svg>);
}