import { useLocalization } from "@infrastructure";
import { Box, Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, RiskTypeMetadataModelHelper, useRiskPolicyTranslator } from "../../../../../../../../common";
import { Snippet } from "../../../../../../../../tenants";
import { RiskDefinition, RiskDefinitionSection } from "../../utilities";

export function useCodeDefinition(riskModel: Contract.RiskModel): RiskDefinition {
    const codeRiskModel = riskModel as Contract.CodeRiskModel;

    const riskPolicyTranslator = useRiskPolicyTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCodeDefinition",
            () => ({
                sections: {
                    code: "Code",
                    policyDescription: "Policy",
                    subtitle: "IaC Finding"
                }
            }));

    return useMemo(
        () => ({
            description: localization.sections.subtitle(),
            sections: [
                new RiskDefinitionSection(
                    <Typography sx={{ whiteSpace: "pre-wrap" }}>
                        {riskPolicyTranslator(RiskTypeMetadataModelHelper.get(codeRiskModel.risk.typeName).policyConfigurationTypeName).description}
                    </Typography>,
                    localization.sections.policyDescription(),
                    { resolvedRiskVisible: true }),
                new RiskDefinitionSection(
                    <Box>
                        {_.map(
                            codeRiskModel.risk.snippets,
                            snippet =>
                                <Snippet
                                    codeType={codeRiskModel.risk.codeType}
                                    snippet={snippet}
                                    tenantId={codeRiskModel.risk.tenantId}/>)}
                    </Box>,
                    localization.sections.code())
            ]
        }),
        [codeRiskModel]);
}