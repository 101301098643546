import _, { Dictionary } from "lodash";
import { Contract, FeatureHelper, LicensingHelper, RiskController, RiskTypeMetadataModelHelper } from "..";
import { TypeNames } from "../controllers/typeNames.generated";
import { TenantType } from "../controllers/types.generated";

export class RiskPolicyTypeMetadataHelper {
    private static kubernetesAdmissionControllerRiskPolicyConfigurationTypeNameToAnalysisGroupTypeToTenantTypesMap: Dictionary<Dictionary<Contract.TenantType[]>>;
    private static kubernetesAdmissionControllerRiskPolicyConfigurationTypeNameToMetadataMap: Dictionary<Contract.RiskPolicyTypeMetadata>;
    private static kubernetesAdmissionControllerRiskPolicyConfigurationTypeNameToTenantTypesMap: Dictionary<Contract.TenantType[]>;
    private static riskPolicyConfigurationTypeNameToAnalysisGroupTypeToTenantTypesMap: Dictionary<Dictionary<Contract.TenantType[]>>;
    private static riskPolicyConfigurationTypeNameToCodeRiskCloudTenantTypesMap: Dictionary<TenantType[]>;
    private static riskPolicyConfigurationTypeNameToExclusionAnyScopeEntityTypeNamesMap: Dictionary<string[]>;
    private static riskPolicyConfigurationTypeNameToExclusionEntityTypeNameToPropertyPatternTypeNamesMap: Dictionary<Dictionary<string[]>>;
    private static riskPolicyConfigurationTypeNameToExclusionEntityTypeNamesMap: Dictionary<string[]>;
    private static riskPolicyConfigurationTypeNameToMetadataMap: Dictionary<Contract.RiskPolicyTypeMetadata>;
    private static riskPolicyConfigurationTypeNameToRiskTypeNamesMap: Dictionary<string[]>;
    private static riskPolicyConfigurationTypeNameToTenantTypeToComplianceRiskedEntityTypeNameMap: Dictionary<Dictionary<string>>;
    private static riskPolicyConfigurationTypeNameToTenantTypeToRiskedEntityTypeNameMap: Dictionary<Dictionary<string>>;
    private static riskPolicyConfigurationTypeNameToTenantTypesMap: Dictionary<Contract.TenantType[]>;

    public static async initialize() {
        const {
            kubernetesAdmissionControllerRiskPolicyConfigurationTypeNameToAnalysisGroupTypeToTenantTypesMap,
            kubernetesAdmissionControllerRiskPolicyConfigurationTypeNameToMetadataMap,
            kubernetesAdmissionControllerRiskPolicyConfigurationTypeNameToTenantTypesMap,
            riskPolicyConfigurationTypeNameToAnalysisGroupTypeToTenantTypesMap,
            riskPolicyConfigurationTypeNameToCodeRiskCloudTenantTypesMap,
            riskPolicyConfigurationTypeNameToExclusionAnyScopeEntityTypeNamesMap,
            riskPolicyConfigurationTypeNameToExclusionEntityTypeNamesMap,
            riskPolicyConfigurationTypeNameToExclusionEntityTypeNameToPropertyPatternTypeNamesMap,
            riskPolicyConfigurationTypeNameToMetadataMap,
            riskPolicyConfigurationTypeNameToRiskTypeNamesMap,
            riskPolicyConfigurationTypeNameToTenantTypesMap,
            riskPolicyConfigurationTypeNameToTenantTypeToComplianceRiskedEntityTypeNameMap,
            riskPolicyConfigurationTypeNameToTenantTypeToRiskedEntityTypeNameMap
        } = await RiskController.getRiskPolicyTypeMetadatas();
        RiskPolicyTypeMetadataHelper.kubernetesAdmissionControllerRiskPolicyConfigurationTypeNameToAnalysisGroupTypeToTenantTypesMap = kubernetesAdmissionControllerRiskPolicyConfigurationTypeNameToAnalysisGroupTypeToTenantTypesMap;
        RiskPolicyTypeMetadataHelper.kubernetesAdmissionControllerRiskPolicyConfigurationTypeNameToMetadataMap = kubernetesAdmissionControllerRiskPolicyConfigurationTypeNameToMetadataMap;
        RiskPolicyTypeMetadataHelper.kubernetesAdmissionControllerRiskPolicyConfigurationTypeNameToTenantTypesMap = kubernetesAdmissionControllerRiskPolicyConfigurationTypeNameToTenantTypesMap;
        RiskPolicyTypeMetadataHelper.riskPolicyConfigurationTypeNameToAnalysisGroupTypeToTenantTypesMap = riskPolicyConfigurationTypeNameToAnalysisGroupTypeToTenantTypesMap;
        RiskPolicyTypeMetadataHelper.riskPolicyConfigurationTypeNameToCodeRiskCloudTenantTypesMap = riskPolicyConfigurationTypeNameToCodeRiskCloudTenantTypesMap;
        RiskPolicyTypeMetadataHelper.riskPolicyConfigurationTypeNameToExclusionAnyScopeEntityTypeNamesMap = riskPolicyConfigurationTypeNameToExclusionAnyScopeEntityTypeNamesMap;
        RiskPolicyTypeMetadataHelper.riskPolicyConfigurationTypeNameToExclusionEntityTypeNamesMap = riskPolicyConfigurationTypeNameToExclusionEntityTypeNamesMap;
        RiskPolicyTypeMetadataHelper.riskPolicyConfigurationTypeNameToExclusionEntityTypeNameToPropertyPatternTypeNamesMap = riskPolicyConfigurationTypeNameToExclusionEntityTypeNameToPropertyPatternTypeNamesMap;
        RiskPolicyTypeMetadataHelper.riskPolicyConfigurationTypeNameToMetadataMap = riskPolicyConfigurationTypeNameToMetadataMap;
        RiskPolicyTypeMetadataHelper.riskPolicyConfigurationTypeNameToRiskTypeNamesMap = riskPolicyConfigurationTypeNameToRiskTypeNamesMap;
        RiskPolicyTypeMetadataHelper.riskPolicyConfigurationTypeNameToTenantTypeToComplianceRiskedEntityTypeNameMap = riskPolicyConfigurationTypeNameToTenantTypeToComplianceRiskedEntityTypeNameMap;
        RiskPolicyTypeMetadataHelper.riskPolicyConfigurationTypeNameToTenantTypeToRiskedEntityTypeNameMap = riskPolicyConfigurationTypeNameToTenantTypeToRiskedEntityTypeNameMap;
        RiskPolicyTypeMetadataHelper.riskPolicyConfigurationTypeNameToTenantTypesMap = riskPolicyConfigurationTypeNameToTenantTypesMap;
    }

    public static get(riskPolicyConfigurationTypeName: string) {
        return _.has(RiskPolicyTypeMetadataHelper.kubernetesAdmissionControllerRiskPolicyConfigurationTypeNameToMetadataMap, riskPolicyConfigurationTypeName)
            ? RiskPolicyTypeMetadataHelper.kubernetesAdmissionControllerRiskPolicyConfigurationTypeNameToMetadataMap[riskPolicyConfigurationTypeName]
            : RiskPolicyTypeMetadataHelper.riskPolicyConfigurationTypeNameToMetadataMap[riskPolicyConfigurationTypeName];
    }

    public static getCodeRiskCloudTenantTypes(riskPolicyConfigurationTypeName: string) {
        return RiskPolicyTypeMetadataHelper.riskPolicyConfigurationTypeNameToCodeRiskCloudTenantTypesMap[riskPolicyConfigurationTypeName];
    }

    public static getComplianceRiskedEntityTypeName(riskPolicyConfigurationTypeName: string) {
        const riskTypeNames = RiskPolicyTypeMetadataHelper.getRiskTypeNames(riskPolicyConfigurationTypeName);
        if (riskTypeNames.length == 1) {
            return RiskTypeMetadataModelHelper.get(riskTypeNames[0]).complianceRiskedEntityTypeName;
        }

        const riskPolicyConfigurationComplianceRiskedEntityTypeName = RiskPolicyTypeMetadataHelper.get(riskPolicyConfigurationTypeName).complianceRiskedEntityTypeName;
        if (!_.isNil(riskPolicyConfigurationComplianceRiskedEntityTypeName)) {
            return riskPolicyConfigurationComplianceRiskedEntityTypeName;
        }

        const riskPolicyConfigurationTenantTypes = RiskPolicyTypeMetadataHelper.getTenantTypes(riskPolicyConfigurationTypeName);
        return riskPolicyConfigurationTenantTypes.length > 1
            ? TypeNames.Entity
            : RiskPolicyTypeMetadataHelper.riskPolicyConfigurationTypeNameToTenantTypeToComplianceRiskedEntityTypeNameMap[riskPolicyConfigurationTypeName][riskPolicyConfigurationTenantTypes[0]];
    }

    public static getExclusionAnyScopeEntityTypeNames(riskPolicyConfigurationTypeName: string) {
        return RiskPolicyTypeMetadataHelper.riskPolicyConfigurationTypeNameToExclusionAnyScopeEntityTypeNamesMap[riskPolicyConfigurationTypeName];
    }

    public static getExclusionEntityTypeNames(riskPolicyConfigurationTypeName: string) {
        return RiskPolicyTypeMetadataHelper.riskPolicyConfigurationTypeNameToExclusionEntityTypeNamesMap[riskPolicyConfigurationTypeName];
    }

    public static getExclusionEntityTypeNameToPropertyPatternTypeNamesMap(riskPolicyConfigurationTypeName: string) {
        return RiskPolicyTypeMetadataHelper.riskPolicyConfigurationTypeNameToExclusionEntityTypeNameToPropertyPatternTypeNamesMap[riskPolicyConfigurationTypeName];
    }

    public static getLicensedRiskPolicyConfigurationTypeNameToMetadataMap() {
        return _.pickBy(
            RiskPolicyTypeMetadataHelper.riskPolicyConfigurationTypeNameToMetadataMap,
            (_riskPolicyTypeMetadata, riskPolicyConfigurationTypeName) =>
                RiskPolicyTypeMetadataHelper.isLicensed(riskPolicyConfigurationTypeName));
    }

    public static getRiskedEntityTypeName(riskPolicyConfigurationTypeName: string) {
        const riskTypeNames = RiskPolicyTypeMetadataHelper.getRiskTypeNames(riskPolicyConfigurationTypeName);
        if (riskTypeNames.length == 1) {
            return RiskTypeMetadataModelHelper.get(riskTypeNames[0]).riskedEntityTypeName;
        }

        const riskPolicyConfigurationRiskedEntityTypeName = RiskPolicyTypeMetadataHelper.get(riskPolicyConfigurationTypeName).riskedEntityTypeName;
        if (!_.isNil(riskPolicyConfigurationRiskedEntityTypeName)) {
            return riskPolicyConfigurationRiskedEntityTypeName;
        }

        const riskPolicyConfigurationTenantTypes = RiskPolicyTypeMetadataHelper.getTenantTypes(riskPolicyConfigurationTypeName);
        return riskPolicyConfigurationTenantTypes.length > 1
            ? TypeNames.Entity
            : RiskPolicyTypeMetadataHelper.riskPolicyConfigurationTypeNameToTenantTypeToRiskedEntityTypeNameMap[riskPolicyConfigurationTypeName][riskPolicyConfigurationTenantTypes[0]];
    }

    public static getRiskTypeNames(riskPolicyConfigurationTypeName: string) {
        return RiskPolicyTypeMetadataHelper.riskPolicyConfigurationTypeNameToRiskTypeNamesMap[riskPolicyConfigurationTypeName];
    }

    public static getTenantTypes(
        riskPolicyConfigurationTypeName: string,
        analysisGroupType?: string) {
        if (_.isNil(analysisGroupType)) {
            return RiskPolicyTypeMetadataHelper.kubernetesAdmissionControllerRiskPolicyConfigurationTypeNameToTenantTypesMap[riskPolicyConfigurationTypeName] ??
            RiskPolicyTypeMetadataHelper.riskPolicyConfigurationTypeNameToTenantTypesMap[riskPolicyConfigurationTypeName];
        }

        const analysisGroupTypeToTenantTypesMap =
            RiskPolicyTypeMetadataHelper.kubernetesAdmissionControllerRiskPolicyConfigurationTypeNameToAnalysisGroupTypeToTenantTypesMap[riskPolicyConfigurationTypeName] ??
            RiskPolicyTypeMetadataHelper.riskPolicyConfigurationTypeNameToAnalysisGroupTypeToTenantTypesMap[riskPolicyConfigurationTypeName];
        return analysisGroupTypeToTenantTypesMap[analysisGroupType];
    }

    public static isLicensed(riskPolicyConfigurationTypeName: string) {
        if ([Contract.TypeNames.VirtualMachineImageOperatingSystemEndOfLifeRiskPolicyConfiguration,
            Contract.TypeNames.VirtualMachineImageOperatingSystemUnpatchedRiskPolicyConfiguration,
            Contract.TypeNames.VirtualMachineImageVulnerabilityRiskPolicyConfiguration].includes(riskPolicyConfigurationTypeName as Contract.TypeNames) &&
            !FeatureHelper.enabled(Contract.FeatureName.AwsEc2MachineImageAnalysisEnabled)) {
            return false;
        }

        return LicensingHelper.isActiveLicenseType(RiskPolicyTypeMetadataHelper.get(riskPolicyConfigurationTypeName).licenseType);
    }
}