import { useEffect, useMemo, useState } from "react";
import { ApiController } from "../controllers";

export function useSelectedScopeId() {
    const [selectedScopeId, setSelectedScopeId] = useState(ApiController.scopeId);
    useEffect(
        () => {
            const handler =
                () => {
                    setSelectedScopeId(ApiController.scopeId);
                };
            ApiController.eventEmitter.on(ApiController.scopeIdChangedEventName, handler);
            return () => {
                ApiController.eventEmitter.off(ApiController.scopeIdChangedEventName, handler);
            };
        },
        []);

    return useMemo(
        () => ({
            selectedScopeId
        }),
        [selectedScopeId]);
}