﻿import { InlineItems, map, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, Entity, InlineEntities, InlinePermissionActions, InlineResourceTags, NetworkScopeFormatter } from "../../../../../../../../../../../../../../common";
import { useResourceSecretExistsSteps } from "../../../../../useResourceSecretExistsSteps";


export function useInformationSteps(riskModel: Contract.AwsEc2InstanceUserDataSecretExistsRiskModel, resourceDisplayName: string) {
    const resourceSecretExistsSteps = useResourceSecretExistsSteps();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsEc2InstanceUserDataSecretExistsRiskDefinition.hooks.useInformationSteps",
            () => ({
                [Contract.TypeNames.AwsEc2InstanceUserDataSecretExistsRiskInfoType]: {
                    [Contract.AwsEc2InstanceUserDataSecretExistsRiskInfoType.AutoScalingGroups]: "The {{resourceDisplayName}} is part of {{autoScalingGroups}}",
                    [Contract.AwsEc2InstanceUserDataSecretExistsRiskInfoType.GroupTags]: [
                        "{{instances}} is grouped by {{tags}}",
                        "{{instances}} are grouped by {{tags}}"
                    ],
                    [Contract.AwsEc2InstanceUserDataSecretExistsRiskInfoType.InstancePublicAccess]: [
                        "{{instances}} is publicly accessible via {{securityGroups}} through {{networkScopes}}",
                        "{{instances}} are publicly accessible via {{securityGroups}} through {{networkScopes}}"
                    ],
                    [Contract.AwsEc2InstanceUserDataSecretExistsRiskInfoType.Instances]: [
                        "The {{resourceDisplayName}} is associated with {{instances}}, which is exposing secrets in clear text via user data",
                        "The {{resourceDisplayName}} is associated with {{instances}}, which are exposing secrets in clear text via user data"
                    ],
                    [Contract.AwsEc2InstanceUserDataSecretExistsRiskInfoType.InstanceNotExists]: "The {{resourceDisplayName}} is not associated with any instance that is exposing a secret",
                    [Contract.AwsEc2InstanceUserDataSecretExistsRiskInfoType.Karpenter]: "The {{resourceDisplayName}} runs **Karpenter**",
                    [Contract.AwsEc2InstanceUserDataSecretExistsRiskInfoType.NotSensitive]: "The {{resourceDisplayName}} is not marked as sensitive",
                    [Contract.AwsEc2InstanceUserDataSecretExistsRiskInfoType.Permissions]: [
                        "{{identities}}{{thirdPartyRolesPart}} is granted with {{permissionActions}} allowing it to read your secrets",
                        "{{identities}}{{thirdPartyRolesPart}} are granted with {{permissionActions}} allowing them to read your secrets"
                    ],
                    [Contract.AwsEc2InstanceUserDataSecretExistsRiskInfoType.RunningInstances]: [
                        "{{instances}} has state of **running**",
                        "{{instances}} have state of **running**"
                    ],
                    [Contract.AwsEc2InstanceUserDataSecretExistsRiskInfoType.RunningInstanceNotExists]: "All EC2 instances are not running",
                    [Contract.AwsEc2InstanceUserDataSecretExistsRiskInfoType.Sensitive]: "The {{resourceDisplayName}} is marked as sensitive",
                    [Contract.AwsEc2InstanceUserDataSecretExistsRiskInfoType.SensitiveAutoScalingGroups]: [
                        "{{autoScalingGroups}} is marked as sensitive",
                        "{{autoScalingGroups}} are marked as sensitive"
                    ],
                    [Contract.AwsEc2InstanceUserDataSecretExistsRiskInfoType.SensitiveInstances]: [
                        "{{instances}} is marked as sensitive",
                        "{{instances}} are marked as sensitive"
                    ]
                },
                identities: {
                    identities: [
                        "1 identity",
                        "{{count | NumberFormatter.humanize}} identities"
                    ],
                    thirdPartyRolesPart: {
                        text: " including {{thirdPartyRoles}}",
                        thirdPartyRoles: [
                            "1 third party role",
                            "{{count | NumberFormatter.humanize}} third party roles"
                        ]
                    }
                },
                ports: [
                    "1 port",
                    "{{count | NumberFormatter.humanize}} ports"
                ]
            }));

    return _.map(
        riskModel.risk.infos,
        info => {
            const infoLocalization = localization[Contract.TypeNames.AwsEc2InstanceUserDataSecretExistsRiskInfoType];
            return map(
                info.type,
                {
                    [Contract.AwsEc2InstanceUserDataSecretExistsRiskInfoType.AutoScalingGroups]: () =>
                        [infoLocalization[Contract.AwsEc2InstanceUserDataSecretExistsRiskInfoType.AutoScalingGroups](
                            {
                                autoScalingGroups:
                                        <InlineEntities
                                            entityIdsOrModels={(info as Contract.AwsEc2InstanceUserDataSecretExistsRiskAutoScalingGroupsInfo).autoScalingGroupIds}
                                            entityTypeName={Contract.TypeNames.AwsAutoScalingAutoScalingGroup}
                                            variant="itemCountAndType"/>,
                                resourceDisplayName
                            })],
                    [Contract.AwsEc2InstanceUserDataSecretExistsRiskInfoType.GroupTags]: () =>
                        [infoLocalization[Contract.AwsEc2InstanceUserDataSecretExistsRiskInfoType.GroupTags](
                            riskModel.risk.aggregatedEntityIds.length,
                            {
                                instances:
                                        <InlineEntities
                                            entityIdsOrModels={riskModel.risk.aggregatedEntityIds}
                                            entityTypeName={Contract.TypeNames.AwsEc2Instance}
                                            variant="itemCountAndType"/>,
                                tags:
                                        <InlineResourceTags
                                            tags={(info as Contract.AwsEc2InstanceUserDataSecretExistsRiskGroupTagsInfo).groupTags}
                                            textVariant="text"
                                            variant="itemCountAndType"/>
                            })],
                    [Contract.AwsEc2InstanceUserDataSecretExistsRiskInfoType.Instances]: () =>
                        _.filter(
                            [infoLocalization[Contract.AwsEc2InstanceUserDataSecretExistsRiskInfoType.Instances](
                                riskModel.risk.aggregatedEntityIds.length,
                                {
                                    instances:
                                            <InlineEntities
                                                entityIdsOrModels={riskModel.risk.aggregatedEntityIds}
                                                entityTypeName={Contract.TypeNames.AwsEc2Instance}
                                                variant="itemCountAndType"/>,
                                    resourceDisplayName
                                }),
                            _.includes([Contract.TypeNames.AwsAutoScalingLaunchConfiguration, Contract.TypeNames.AwsEc2LaunchTemplate], riskModel.risk.aggregatingEntityTypeName)
                                ? resourceSecretExistsSteps.getSecretExistsAggregatingEntityAndEntitiesStep(
                                    riskModel.risk.aggregatedEntityIds,
                                    Contract.TypeNames.AwsEc2Instance,
                                    resourceDisplayName,
                                    riskModel.risk.userDataSecrets)
                                : resourceSecretExistsSteps.getSecretExistsStep(
                                    riskModel.risk.aggregatedEntityIds,
                                    Contract.TypeNames.AwsEc2Instance,
                                    riskModel.risk.userDataSecrets),
                            resourceSecretExistsSteps.getExcludedSecretsStep(
                                riskModel.risk.userDataExcludedSecrets,
                                riskModel.risk.typeName,
                                riskModel.risk.tenantId)]),
                    [Contract.AwsEc2InstanceUserDataSecretExistsRiskInfoType.InstanceNotExists]: () =>
                        [infoLocalization[Contract.AwsEc2InstanceUserDataSecretExistsRiskInfoType.InstanceNotExists](
                            { resourceDisplayName }),
                        resourceSecretExistsSteps.getSecretExistsAggregatingEntityStep(
                            resourceDisplayName,
                            riskModel.risk.userDataSecrets)],
                    [Contract.AwsEc2InstanceUserDataSecretExistsRiskInfoType.InstancePublicAccess]: () =>
                        [infoLocalization[Contract.AwsEc2InstanceUserDataSecretExistsRiskInfoType.InstancePublicAccess](
                            (info as Contract.AwsEc2InstanceUserDataSecretExistsRiskPublicAccessInfo).instanceIds.length,
                            {
                                instances:
                                        <InlineEntities
                                            entityIdsOrModels={(info as Contract.AwsEc2InstanceUserDataSecretExistsRiskPublicAccessInfo).instanceIds}
                                            entityTypeName={Contract.TypeNames.AwsEc2Instance}
                                            variant="itemCountAndType"/>,
                                networkScopes:
                                        <InlineItems
                                            items={
                                                _.map(
                                                    (info as Contract.AwsEc2InstanceUserDataSecretExistsRiskPublicAccessInfo).destinationNetworkScope,
                                                    networkScope => NetworkScopeFormatter.networkScopeFromDestinationNetworkScope(networkScope))}
                                            namePluralizer={localization.ports}
                                            variant="itemCountAndType"/>,
                                resourceDisplayName,
                                securityGroups:
                                        <InlineEntities
                                            entityIdsOrModels={(info as Contract.AwsEc2InstanceUserDataSecretExistsRiskPublicAccessInfo).securityGroupIds}
                                            entityTypeName={Contract.TypeNames.AwsEc2SecurityGroup}
                                            variant="itemCountAndType"/>
                            })],
                    [Contract.AwsEc2InstanceUserDataSecretExistsRiskInfoType.Karpenter]:
                            () => [infoLocalization[Contract.AwsEc2InstanceUserDataSecretExistsRiskInfoType.Karpenter]({ resourceDisplayName })],
                    [Contract.AwsEc2InstanceUserDataSecretExistsRiskInfoType.NotSensitive]:
                            () => [infoLocalization[Contract.AwsEc2InstanceUserDataSecretExistsRiskInfoType.NotSensitive]({ resourceDisplayName })],
                    [Contract.AwsEc2InstanceUserDataSecretExistsRiskInfoType.Permissions]: () =>
                        [infoLocalization[Contract.AwsEc2InstanceUserDataSecretExistsRiskInfoType.Permissions](
                            (info as Contract.AwsEc2InstanceUserDataSecretExistsRiskPermissionsInfo).identityIds.length,
                            {
                                identities:
                                        <InlineEntities
                                            entityIdsOrModels={(info as Contract.AwsEc2InstanceUserDataSecretExistsRiskPermissionsInfo).identityIds}
                                            entityTypeName={Contract.TypeNames.AwsIamIdentity}
                                            variant="itemCountAndType"/>,
                                permissionActions:
                                        <InlinePermissionActions
                                            permissionActions={(info as Contract.AwsEc2InstanceUserDataSecretExistsRiskPermissionsInfo).actions}
                                            variant="itemCountAndType"/>,
                                thirdPartyRolesPart:
                                    (info as Contract.AwsEc2InstanceUserDataSecretExistsRiskPermissionsInfo).vendorIds.length == 0
                                        ? ""
                                        : localization.identities.thirdPartyRolesPart.text({
                                            thirdPartyRoles:
                                                    <InlineItems
                                                        items={
                                                            _.map(
                                                                (info as Contract.AwsEc2InstanceUserDataSecretExistsRiskPermissionsInfo).vendorIds,
                                                                vendorId =>
                                                                    <Entity
                                                                        entityIdOrModel={vendorId}
                                                                        key={vendorId}
                                                                        linkOptions={{ disabledHighlight: false }}
                                                                        variant="iconTextTypeTenant"/>)}
                                                        namePluralizer={localization.identities.thirdPartyRolesPart.thirdPartyRoles}
                                                        variant="itemCountAndType"/>
                                        })
                            }
                        )],
                    [Contract.AwsEc2InstanceUserDataSecretExistsRiskInfoType.RunningInstances]:
                            () =>
                                [infoLocalization[Contract.AwsEc2InstanceUserDataSecretExistsRiskInfoType.RunningInstances](
                                    riskModel.risk.aggregatedEntityIds.length,
                                    {
                                        instances:
                                            <InlineEntities
                                                entityIdsOrModels={riskModel.risk.aggregatedEntityIds}
                                                entityTypeName={Contract.TypeNames.AwsEc2Instance}
                                                variant="itemCountAndType"/>,
                                        resourceDisplayName
                                    })],
                    [Contract.AwsEc2InstanceUserDataSecretExistsRiskInfoType.RunningInstanceNotExists]:
                            () => [infoLocalization[Contract.AwsEc2InstanceUserDataSecretExistsRiskInfoType.RunningInstanceNotExists]()],
                    [Contract.AwsEc2InstanceUserDataSecretExistsRiskInfoType.Sensitive]:
                            () => [infoLocalization[Contract.AwsEc2InstanceUserDataSecretExistsRiskInfoType.Sensitive]({ resourceDisplayName })],
                    [Contract.AwsEc2InstanceUserDataSecretExistsRiskInfoType.SensitiveAutoScalingGroups]:
                            () =>
                                [infoLocalization[Contract.AwsEc2InstanceUserDataSecretExistsRiskInfoType.SensitiveAutoScalingGroups](
                                    (info as Contract.AwsEc2InstanceUserDataSecretExistsRiskSensitiveEntitiesInfo).entityIds.length,
                                    {
                                        autoScalingGroups:
                                            <InlineEntities
                                                entityIdsOrModels={(info as Contract.AwsEc2InstanceUserDataSecretExistsRiskSensitiveEntitiesInfo).entityIds}
                                                entityTypeName={Contract.TypeNames.AwsAutoScalingAutoScalingGroup}
                                                variant="itemCountAndType"/>
                                    })],
                    [Contract.AwsEc2InstanceUserDataSecretExistsRiskInfoType.SensitiveInstances]:
                            () =>
                                [infoLocalization[Contract.AwsEc2InstanceUserDataSecretExistsRiskInfoType.SensitiveInstances](
                                    (info as Contract.AwsEc2InstanceUserDataSecretExistsRiskSensitiveEntitiesInfo).entityIds.length,
                                    {
                                        instances:
                                            <InlineEntities
                                                entityIdsOrModels={(info as Contract.AwsEc2InstanceUserDataSecretExistsRiskSensitiveEntitiesInfo).entityIds}
                                                entityTypeName={Contract.TypeNames.AwsEc2Instance}
                                                variant="itemCountAndType"/>
                                    })]
                });
        }).
        flat();
}