﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, Entity, entityModelStore, InlineEntities } from "../../../../../../../../../../../../../../common";
import { useCommonSectionsAndDescriptionDefinition } from "../../../../../useCommonSectionsAndDescriptionDefinition";
import { EntityExternalConsoleLink, ResourcesExternalConsoleLink } from "../../../../components";
import { useGetGcpComputeInstancesRiskContext, useGetGcpTenantEntityRiskContext } from "../../../contexts";

export function useGcpComputeInstanceSshSerialPortEnabledRiskTenantEntityDefinition(riskModel: Contract.GcpComputeInstanceSshSerialPortEnabledRiskModel) {
    const risk = riskModel.risk as Contract.GcpComputeInstanceSshSerialPortEnabledRisk;
    const tenantEntityModel = entityModelStore.useGet(risk.entityId) as Contract.GcpTenantEntityModel;

    const getGcpComputeInstancesRiskContext = useGetGcpComputeInstancesRiskContext();
    const getGcpTenantEntityRiskContext = useGetGcpTenantEntityRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.useGcpComputeInstanceSshSerialPortEnabledRiskDefinition.hooks.useGcpComputeInstanceSshSerialPortEnabledRiskTenantEntityDefinition",
            () => ({
                description: "{{tenantEntity}} has serial port access enabled",
                sections: {
                    resolution: {
                        step1: "Click on **Edit**",
                        step2: "Under **metadata** remove any entry with key **serial-port-enable**",
                        step3: "Click **Save** to confirm",
                        step4: "Click the name of {{instanceIds}} to remove the metadata value that overrides the project setting",
                        step5: "Click on **Edit**",
                        step6: "Under **metadata** remove any entry with key **serial-port-enable**",
                        step7: "Click **Save** to confirm"
                    }
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            tenantEntity:
                <Entity
                    entityIdOrModel={tenantEntityModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={risk.entityId}
                key={risk.entityId}
                page={Contract.GcpConsolePage.Metadata}/>,
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3(),
            ...(!_.isEmpty(risk.aggregatedEntityIds)
                ? ([
                    <ResourcesExternalConsoleLink
                        includeSignIn={false}
                        key={risk.tenantId}
                        tenantId={risk.tenantId}
                        typeName={Contract.TypeNames.GcpComputeInstance}/>,
                    localization.sections.resolution.step4({
                        instanceIds:
                            <InlineEntities
                                entityIdsOrModels={risk.aggregatedEntityIds}
                                entityTypeName={Contract.TypeNames.GcpComputeInstance}
                                variant="itemCountAndType"/>
                    }),
                    localization.sections.resolution.step5(),
                    localization.sections.resolution.step6(),
                    localization.sections.resolution.step7()
                ])
                : [])
        ],
        riskModel,
        () => {
            const instancesContextItems = getGcpComputeInstancesRiskContext(risk.aggregatedEntityIds);
            const tenantEntityContextItems = getGcpTenantEntityRiskContext(tenantEntityModel);
            return [
                tenantEntityContextItems.generalInformation,
                tenantEntityContextItems.sensitive,
                instancesContextItems.instances,
                instancesContextItems.sensitive,
                instancesContextItems.runningInstances,
                tenantEntityContextItems.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}