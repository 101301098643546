import { Steps, useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { ChangeStep, RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetAwsIamUserRiskContext } from "../contexts";

export function useAwsIamUserInactiveRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsIamUserInactiveRisk;
    const userModel = entityModelStore.useGet(risk.entityId) as Contract.AwsIamUserModel;
    const user = userModel.entity as Contract.AwsIamUser;

    const getAwsIamUserRiskContext = useGetAwsIamUserRiskContext();
    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsIamUserInactiveRiskDefinition",
            () => ({
                description: "{{user}} is inactive and should be deleted",
                sections: {
                    context: {
                        [Contract.TypeNames.AwsIamIdentityInactiveRiskModelInfoType]: {
                            [Contract.AwsIamIdentityInactiveRiskModelInfoType.ActiveNever]: "The user was not active since it was created",
                            [Contract.AwsIamIdentityInactiveRiskModelInfoType.ActivityTime]: "The user was last active {{activityTime | TimeFormatter.humanizePastDuration}}"
                        }
                    },
                    resolution: "Click **Delete** and confirm the deletion"
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            user:
                <Entity
                    entityIdOrModel={userModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => {
            const deleteUserChange = risk.resolutionChanges![0] as Contract.AwsDeleteUserChange;
            return [
                new ChangeStep(
                    deleteUserChange,
                    {
                        contentElement:
                            <Steps variant="plainNumbers">
                                {[
                                    consoleSignInStepTranslator(
                                        Contract.AwsConsoleView.Iam,
                                        AwsConsoleUrlBuilder.getIamUserUrl(user)),
                                    localization.sections.resolution()
                                ]}
                            </Steps>
                    }
                )];
        },
        riskModel,
        () => {
            const userRiskContext = getAwsIamUserRiskContext(userModel);
            return [
                userRiskContext.generalInformation,
                new RiskDefinitionContextItem(localization.sections.context[Contract.TypeNames.AwsIamIdentityInactiveRiskModelInfoType][(riskModel as Contract.AwsIamUserInactiveRiskModel).infoType]({ activityTime: risk.activityTime })),
                userRiskContext.passwordEnabled,
                userRiskContext.mfaEnabled,
                userRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}