import { DirectedGraphModelColumn, UnexpectedError, useLocalization } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { NetworkDefinition } from "../..";
import { AzureNetworkApplicationGatewayRoutingRulesTable, AzureNetworkLoadBalancerRulesTable } from "../../../../../../../tenants";
import { Contract, EntityController } from "../../../../../../controllers";
import { useTheme } from "../../../../../../themes";
import { ItemSelectionHelper } from "../../../../../../utilities";
import { GraphColumnId, ToolbarFilterId, ToolbarToggleFilterId } from "../../../../components";
import { AzureServiceSubnetIcon, AzureServiceSubnetsIcon } from "./components";
import { useAzureNetworkToolbarFilterIdTranslator, useAzureNetworkToolbarToggleFilterIdTranslator } from "./hooks";

export function useAzureDefinition() {
    const localization =
        useLocalization(
            "common.network.hooks.useDefinition.hooks.useAzureDefinition",
            () => ({
                columns: {
                    [GraphColumnId.DestinationGroups]: "Targets",
                    [GraphColumnId.Gateways]: "Via",
                    [GraphColumnId.InterfaceSecurityGroups]: "Interface Network Security Groups",
                    [GraphColumnId.Sources]: "Sources",
                    [GraphColumnId.SubnetSecurityGroups]: "Subnet Network Security Groups"
                },
                rules: {
                    [Contract.TypeNames.AzureNetworkApplicationGateway]: "Request Routing Rules",
                    [Contract.TypeNames.AzureNetworkLoadBalancer]: "Rules"
                },
                specialSources: {
                    [Contract.NetworkGraphSpecialSource.AnyAzureVirtualMachine]: "Any Azure VM"
                }
            }));

    const theme = useTheme();

    return new NetworkDefinition(
        _.map(
            [
                GraphColumnId.Sources,
                GraphColumnId.Gateways,
                GraphColumnId.SubnetSecurityGroups,
                GraphColumnId.InterfaceSecurityGroups,
                GraphColumnId.DestinationGroups
            ],
            (columnId: Exclude<GraphColumnId, GraphColumnId.FirewallPolicyAndVpcs>) =>
                new DirectedGraphModelColumn(
                    columnId,
                    localization.columns[columnId]())),

        (entityId: string, filterMap: Dictionary<any>) =>
            EntityController.getNetworkGraph(
                new Contract.EntityControllerGetAzureNetworkGraphRequest(
                    entityId,
                    {
                        destinationScopeFilterIdSelection: ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[ToolbarFilterId.DestinationScopeFilterIds]),
                        interfaceNetworkSecurityGroupIdSelection: ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[ToolbarFilterId.InterfaceSecurityGroupIds]),
                        sourceEntityIdSelection: ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[ToolbarFilterId.SourceEntityIds]),
                        sourceSubnetSelection: ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[ToolbarFilterId.SourceSubnets]),
                        wideRangeSourceSubnet: filterMap[ToolbarToggleFilterId.WideRangeSourceSubnet] as boolean
                    })
            ),
        (specialSource: Contract.NetworkGraphSpecialSource) => {
            if (specialSource != Contract.NetworkGraphSpecialSource.AnyAzureVirtualMachine) {
                throw new UnexpectedError("specialSource", specialSource);
            }
            return {
                highlightColor: theme.palette.error.main,
                icon: <AzureServiceSubnetsIcon/>,
                normalColor: theme.palette.error.light,
                subtitle: "",
                title: localization.specialSources[Contract.NetworkGraphSpecialSource.AnyAzureVirtualMachine]()
            };
        },
        <AzureServiceSubnetIcon/>,
        <AzureServiceSubnetsIcon/>,
        useAzureNetworkToolbarFilterIdTranslator(),
        useAzureNetworkToolbarToggleFilterIdTranslator(),
        (gatewayResourceModel: Contract.EntityModel) => ({
            rules:
                gatewayResourceModel.entity.typeName === Contract.TypeNames.AzureNetworkLoadBalancer
                    ? <AzureNetworkLoadBalancerRulesTable loadBalancer={gatewayResourceModel.entity as Contract.AzureNetworkLoadBalancer}/>
                    : <AzureNetworkApplicationGatewayRoutingRulesTable applicationGateway={gatewayResourceModel.entity as Contract.AzureNetworkApplicationGateway}/>,
            title: localization.rules.translate(gatewayResourceModel.entity.typeName)
        }));
}