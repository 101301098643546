import { DataTable, DataTableColumn, DataTableColumnRenderProps, EmptyMessageText, InlineItems, Optional, optionalTableCell, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, Entity, entityModelStore } from "../../../common";


type AzureWebApplicationNetworkRulesTableProps = {
    getHighlightColor?: (networkRule: Contract.AzureWebApplicationNetworkRule, opacity?: number) => Optional<string>;
    networkRules: Contract.AzureWebApplicationNetworkRule[];
};

export function AzureWebApplicationNetworkRulesTable({ getHighlightColor, networkRules }: AzureWebApplicationNetworkRulesTableProps) {
    const subnetModels =
        entityModelStore.useGet(
            _(networkRules).
                map(networkRule => networkRule.sourceSubnetId!).
                filter().
                value());

    const items =
        useMemo(
            () => {
                const subnetModelMap =
                    _(subnetModels).
                        filter().
                        keyBy(subnetModel => subnetModel.id).
                        value();

                return _(networkRules).
                    orderBy(networkRule => networkRule.priority).
                    map(
                        networkRule =>
                            new AzureWebApplicationNetworkRulesTableItem(
                                networkRule,
                                _.isNil(networkRule.sourceSubnetId)
                                    ? undefined
                                    : subnetModelMap[networkRule.sourceSubnetId])).
                    value();
            },
            [networkRules]);

    const localization =
        useLocalization(
            "tenants.azure.azureWebApplicationNetworkRulesTable",
            () => ({
                columns: {
                    action: {
                        allow: "Allow",
                        deny: "Deny",
                        title: "Action"
                    },
                    name: "Name",
                    priority: "Priority",
                    sourceSubnets: "Source"
                },
                empty: "No Restrictions"
            }));

    return (
        <DataTable
            emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty()) }}
            fetchItems={() => items}
            getItemId={(item: AzureWebApplicationNetworkRulesTableItem) => _.indexOf(items, item).toString()}
            rowOptions={{
                getHighlightColor: (item: AzureWebApplicationNetworkRulesTableItem) => getHighlightColor?.(item.networkRule),
                getSx:
                    (item: AzureWebApplicationNetworkRulesTableItem) => ({
                        backgroundColor: getHighlightColor?.(item.networkRule, 0.1)
                    })
            }}
            sortOptions={{ enabled: false }}
            variant="card">
            <DataTableColumn
                id={AzureWebApplicationNetworkRuleColumnId.Priority}
                render={optionalTableCell<AzureWebApplicationNetworkRulesTableItem>(item => item.networkRule.priority)}
                title={localization.columns.priority()}/>
            <DataTableColumn
                id={AzureWebApplicationNetworkRuleColumnId.Name}
                itemProperty={(item: AzureWebApplicationNetworkRulesTableItem) => item.networkRule.name}
                title={localization.columns.name()}/>
            <DataTableColumn
                id={AzureWebApplicationNetworkRuleColumnId.SourceSubnets}
                render={
                    ({ item }: DataTableColumnRenderProps<AzureWebApplicationNetworkRulesTableItem>) =>
                        _.isNil(item.subnetModel)
                            ? <InlineItems
                                items={
                                    _.isEmpty(item.networkRule.serviceTags)
                                        ? item.networkRule.sourceSubnets
                                        : item.networkRule.serviceTags}
                                variant="itemPlusItemCount"/>
                            : <Entity
                                entityIdOrModel={item.subnetModel}
                                variant="iconTextTenant"/>}
                title={localization.columns.sourceSubnets()}/>
            <DataTableColumn
                id={AzureWebApplicationNetworkRuleColumnId.Action}
                itemProperty={
                    (item: AzureWebApplicationNetworkRulesTableItem) =>
                        item.networkRule.action === Contract.AzureWebApplicationNetworkRuleAction.Allow
                            ? localization.columns.action.allow()
                            : localization.columns.action.deny()}
                title={localization.columns.action.title()}/>
        </DataTable>);
}

class AzureWebApplicationNetworkRulesTableItem {
    constructor(
        public networkRule: Contract.AzureWebApplicationNetworkRule,
        public subnetModel?: Contract.EntityModel) {
    }
}

enum AzureWebApplicationNetworkRuleColumnId {
    Action = "action",
    Name = "name",
    Priority = "priority",
    SourceSubnets = "sourceSubnets"
}