import { IconText } from "@infrastructure";
import React, { ReactNode } from "react";
import { Severity } from "../controllers/types.generated";
import { TenantModelStatusSeverityIcon } from "../icons";

type StatusCellProps = {
    statusSeverity?: Severity | undefined;
    title: string;
    tooltip?: React.ReactNode | (() => Promise<ReactNode>);
};

export function StatusCell({ statusSeverity, title, tooltip }: StatusCellProps) {
    return (
        <IconText
            icon={<TenantModelStatusSeverityIcon statusSeverity={statusSeverity}/>}
            iconSx={{ fontSize: "18px" }}
            text={title}
            titleOrGetTitle={tooltip}
            tooltipVariant="iconText"/>);
}