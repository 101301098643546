import { useLocalization } from "@infrastructure";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../..";
import { Contract } from "../../../../../../../../../../common";
import { EntitiesInfoItem, Info } from "../../../../components";
import { KubernetesIngressRulesInfoCard, KubernetesYamlInfoCard } from "../../components";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";
import { useAzureCommonKubernetesNamespaceResourceInfoItemElements } from "./useAzureCommonKubernetesNamespaceResourceInfoItemElements";
import { useAzureDefaultResourceInfoItemElements } from "./useAzureDefaultResourceInfoItemElements";

export function useAzureKubernetesIngressDefinition(resourceModel: Contract.AzureResourceModel, options?: DefinitionOptions) {
    const commonKubernetesNamespaceResourceInfoItemElements = useAzureCommonKubernetesNamespaceResourceInfoItemElements(resourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useAzureDefaultResourceInfoItemElements(resourceModel);
    const ingress = resourceModel.entity as Contract.AzureKubernetesIngress;
    const ingressModel = resourceModel as Contract.AzureKubernetesIngressModel;

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.azure.useAzureKubernetesIngressDefinition",
            () => ({
                info: {
                    loadBalancer: "Load Balancer",
                    loadBalancerForwardingRuleIpAddresses: "Address"
                },
                tabs: {
                    yaml: "YAML"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Overview,
                <KubernetesYamlInfoCard rawYaml={ingress.data.rawYaml!}/>,
                localization.tabs.yaml(),
                "yaml")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    commonKubernetesNamespaceResourceInfoItemElements.annotationsInfoItemElement,
                    commonKubernetesNamespaceResourceInfoItemElements.clusterInfoItemElement,
                    commonKubernetesNamespaceResourceInfoItemElements.namespaceInfoItemElement,
                    <EntitiesInfoItem
                        entityIdsOrModels={ingressModel.loadBalancerSearchableReference?.id}
                        entityTypeName={Contract.TypeNames.AzureNetworkLoadBalancer}
                        key="loadBalancerSearchableReference"
                        location="all"
                        title={localization.info.loadBalancer()}/>
                ]}
                options={options?.infoOptions}>
                <KubernetesIngressRulesInfoCard rules={ingress.data.rules}/>
            </Info>
    });
}