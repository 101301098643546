﻿import { TimeFormatter, TimeHelper, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, Entity, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetAwsResourceRiskContext } from "./useGetAwsResourceRiskContext";


export function useGetAwsSecretsManagerSecretRiskContext() {
    return useMemo(
        () => useAwsSecretsManagerSecretRiskContext,
        []);
}

function useAwsSecretsManagerSecretRiskContext(secretModel: Contract.AwsSecretsManagerSecretModel) {
    const secret = secretModel.entity as Contract.AwsSecretsManagerSecret;
    const resourceRiskContext = useGetAwsResourceRiskContext()(secretModel);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.contexts.useGetAwsSecretsManagerSecretRiskContext",
            () => ({
                encryption: {
                    text: "The {{translatedSecretTypeName}} is {{encryption}}",
                    type: {
                        kms: "encrypted using {{kmsKey}}",
                        none: "not encrypted"
                    }
                },
                permissions: "The {{translatedSecretTypeName}}'s resource policy grants {{publicAccessPermissionActions}} to any principal",
                rotationTime: {
                    lastRotated: "last rotated at {{date}}",
                    months: "The {{translatedSecretTypeName}} hasn't been rotated in the last 90 days",
                    neverRotated: "never rotated",
                    text: "The {{translatedSecretTypeName}} was {{rotated}}"
                },
                usage: {
                    lastUsed: "last used at {{date}}",
                    neverUsed: "never used",
                    text: "The {{translatedSecretTypeName}} was {{used}} and was last updated at {{updateDate}}"
                }
            }));
    const translatedSecretTypeName =
        entityTypeNameTranslator(
            secret.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });

    return {
        ...resourceRiskContext,
        encryption:
            new RiskDefinitionContextItem(
                localization.encryption.text({
                    encryption:
                        _.isEmpty(secret.kmsEncryptionKeyOrAliasArns)
                            ? localization.encryption.type.none()
                            : localization.encryption.type.kms(
                                {
                                    kmsKey:
                                        <Entity
                                            entityIdOrModel={secretModel.kmsEncryptionKeyIdReferences[0]}
                                            variant="text"/>
                                }),
                    translatedSecretTypeName
                })),
        publicAccessPermissionActions:
            resourceRiskContext.getPublicAccessPermissionActions(
                secret.publicAccessHighSeveritySensitivePermissionActions,
                secret.publicAccessPermissionActions),
        rotated:
            TimeHelper.elapsed(secretModel.creationTime, 90, "days") &&
            !_.isNil(secret.rotationTime) &&
            TimeHelper.elapsed(secret.rotationTime, 90, "days")
                ? new RiskDefinitionContextItem(localization.rotationTime.months({ translatedSecretTypeName }))
                : undefined,
        rotationTime:
            new RiskDefinitionContextItem(
                localization.rotationTime.text({
                    rotated:
                        _.isNil(secret.rotationTime)
                            ? localization.rotationTime.neverRotated()
                            : localization.rotationTime.lastRotated({
                                date: TimeFormatter.shortDate(secret.rotationTime)
                            }),
                    translatedSecretTypeName
                })
            ),
        usage:
            new RiskDefinitionContextItem(
                localization.usage.text({
                    translatedSecretTypeName,
                    updateDate: TimeFormatter.shortDate(secret.updateTime),
                    used:
                        _.isNil(secret.usageTime)
                            ? localization.usage.neverUsed()
                            : localization.usage.lastUsed({
                                date: TimeFormatter.shortDate(secret.usageTime)
                            })
                })
            )
    };
}