import _ from "lodash";
import { StringHelper } from "@infrastructure";
import { Contract, TreeItem } from "../../../../../../../../../common";

export class OrganizationMemberSelectionHelper {
    public static createTreeItem(scopeNode: Contract.ConfigurationControllerGetOrganizationMemberDatasResponseNode): TreeItem<Contract.ConfigurationControllerGetOrganizationMemberDatasResponseNode> {
        const items =
            _(scopeNode.childrenNodes).
                orderBy([
                    scopeNode => scopeNode.isTenant,
                    scopeNode => StringHelper.getSortValue(scopeNode.rawId)
                ]).
                map(scopeNode => OrganizationMemberSelectionHelper.createTreeItem(scopeNode)).
                as<TreeItem<Contract.ConfigurationControllerGetOrganizationMemberDatasResponseNode>>().
                value();

        return new TreeItem(
            scopeNode,
            items);
    }

    public static getSelectedScopeIds(scopeNode: Contract.ConfigurationControllerGetOrganizationMemberDatasResponseNode, tenantIds: string[]): string[] {
        if (scopeNode.isTenant) {
            return _.includes(tenantIds, scopeNode.rawId)
                ? [scopeNode.rawId]
                : [];
        }

        const selectedTenantIds =
            _.flatMap(
                scopeNode.childrenNodes,
                scopeNode => OrganizationMemberSelectionHelper.getSelectedScopeIds(scopeNode, tenantIds));
        return _(scopeNode.childrenNodes).
            map(scopeNode => scopeNode.rawId).
            xor(selectedTenantIds).
            isEmpty()
            ? [scopeNode.rawId]
            : selectedTenantIds;
    }

    public static getSelectedTenantIds(rootScopeNode: Contract.ConfigurationControllerGetOrganizationMemberDatasResponseNode, scopeIds: string[] = []): string[] {
        return _.flatMap(
            scopeIds,
            id => {
                const scopeNode = OrganizationMemberSelectionHelper.getScopeNode(rootScopeNode, id);
                return scopeNode
                    ? OrganizationMemberSelectionHelper.getTenantIds(scopeNode)
                    : [];
            });
    }

    private static getScopeNode(rootScopeNode: Contract.ConfigurationControllerGetOrganizationMemberDatasResponseNode, id: string): Contract.ConfigurationControllerGetOrganizationMemberDatasResponseNode | undefined {
        if (rootScopeNode.rawId === id) {
            return rootScopeNode;
        }

        for (const childrenNode of rootScopeNode.childrenNodes) {
            const scopeNode =
                OrganizationMemberSelectionHelper.getScopeNode(
                    childrenNode,
                    id);
            if (!_.isNil(scopeNode)) {
                return scopeNode;
            }
        }
    }

    private static getTenantIds(scopeNode: Contract.ConfigurationControllerGetOrganizationMemberDatasResponseNode): string[] {
        if (scopeNode.isTenant) {
            return [scopeNode.rawId];
        };

        return _.flatMap(
            scopeNode.childrenNodes,
            OrganizationMemberSelectionHelper.getTenantIds);
    }
}