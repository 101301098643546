import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function ContactUsIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                clipRule="evenodd"
                d="M19.3505 15.1964L19.5072 14.7723C19.8253 13.9112 20 12.9783 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C13.5709 20 15.0305 19.5492 16.2635 18.7708L16.8369 18.4087L19.6659 18.6659L19.3505 15.1964ZM21.875 20.875L17.3312 20.4619C15.7881 21.4362 13.9599 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 13.2183 21.7821 14.3858 21.3832 15.4655L21.875 20.875Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M7.39811 9.59847C7.39811 9.04619 7.84583 8.59847 8.39811 8.59847H15.6004C16.1527 8.59847 16.6004 9.04619 16.6004 9.59847C16.6004 10.1508 16.1527 10.5985 15.6004 10.5985H8.39811C7.84583 10.5985 7.39811 10.1508 7.39811 9.59847Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M7.39811 13.1995C7.39811 12.6473 7.84583 12.1995 8.39811 12.1995H13.9993C14.5516 12.1995 14.9993 12.6473 14.9993 13.1995C14.9993 13.7518 14.5516 14.1995 13.9993 14.1995H8.39811C7.84583 14.1995 7.39811 13.7518 7.39811 13.1995Z"
                fillRule="evenodd"/>
        </SvgIcon>);
}