import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function DockerIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                d="M13.5819 10.5701H16.0679H13.5819ZM10.6224 10.5701H13.1084H10.6224ZM7.72207 10.5701H10.2081H7.72207ZM4.82174 10.5701H7.24855H4.82174ZM1.86222 10.5701H4.34822H1.86222ZM4.82174 7.84738H7.24855H4.82174ZM7.72207 7.84738H10.2081H7.72207ZM10.6224 7.84738H13.1084H10.6224ZM10.6224 5.12462H13.1084H10.6224Z"
                fill="black"/>
            <path
                clipRule="evenodd"
                d="M13.1084 6.24923H10.6224V4H13.1084V6.24923ZM7.24855 8.97199H4.82174V6.72276H7.24855V8.97199ZM10.2081 8.97199H7.72207V6.72276H10.2081V8.97199ZM13.1084 8.97199H10.6224V6.72276H13.1084V8.97199ZM4.34822 11.6948H1.86222V9.44552H4.34822V11.6948ZM7.24855 11.6948H4.82174V9.44552H7.24855V11.6948ZM10.2081 11.6948H7.72207V9.44552H10.2081V11.6948ZM13.1084 11.6948H10.6224V9.44552H13.1084V11.6948ZM16.0679 11.6948H13.5819V9.44552H16.0679V11.6948Z"
                fill="#1D63ED"
                fillRule="evenodd"/>
            <path
                d="M23.9995 10.6885C23.9995 10.6885 22.9341 9.68228 20.744 10.0374C20.5073 8.3209 18.6724 7.31466 18.6724 7.31466C18.6724 7.31466 16.9559 9.38633 18.1989 11.6948C17.8437 11.8723 17.2518 12.1091 16.364 12.1091H0.0865945C-0.209357 13.2337 -0.209357 20.6917 7.95892 20.6917C13.8188 20.6917 18.1989 17.9689 20.2705 12.9969C23.3484 13.2337 23.9995 10.6885 23.9995 10.6885Z"
                fill="#1D63ED"/>
        </SvgIcon>);
}