import { useLocalization } from "@infrastructure";
import _ from "lodash";
import { useMemo } from "react";
import { Contract, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetGcpEntityRiskContext } from "./useGetGcpEntityRiskContext";

export function useGetGcpSecretManagerSecretRiskContext() {
    return useMemo(
        () => useSecretManagerSecretRiskContext,
        []);
}

function useSecretManagerSecretRiskContext(secretModel: Contract.GcpSecretManagerSecretModel) {
    const entityRiskContext = useGetGcpEntityRiskContext()(secretModel);
    const secret = secretModel.entity as Contract.GcpSecretManagerSecret;

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.contexts.useGetGcpSecretManagerSecretRiskContext",
            () => ({
                enabledVersion: {
                    false: "The {{translatedSecretTypeName}} contains enabled secret version",
                    true: "The {{translatedSecretTypeName}} does not contain enabled secret version"
                },
                rotationTimeFrame: {
                    empty: "The {{translatedSecretTypeName}} rotation period is not set",
                    text: "The {{translatedSecretTypeName}} rotation period is currently set to {{rotationTimeFrame | TimeSpanFormatter.humanizeDays}}"
                }
            }));
    const translatedSecretTypeName =
        entityTypeNameTranslator(
            secret.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });

    return {
        ...entityRiskContext,
        enabledVersion:
            new RiskDefinitionContextItem(
                (secretModel.enabledVersionExists
                    ? localization.enabledVersion.true
                    : localization.enabledVersion.false)({ translatedSecretTypeName })),
        rotationTimeFrame:
            new RiskDefinitionContextItem(
                (_.isNil(secret.rotationTimeFrame)
                    ? localization.rotationTimeFrame.empty
                    : localization.rotationTimeFrame.text)(
                    {
                        rotationTimeFrame: secret.rotationTimeFrame,
                        translatedSecretTypeName
                    }))
    };
}