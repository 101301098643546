﻿import { Optional } from "@infrastructure";
import { Contract } from "../../../../../../../../../../../common";
import { PermissionEligibilityData } from "../../../utilities";

export class OneLoginDirectoryRoleAssignmentEligibilityData extends PermissionEligibilityData {
    public roleIds: string[];

    public constructor(
        permissionEligibilityModel?: Optional<Contract.PermissionEligibilityModel>,
        scopeId?: string) {
        super(
            permissionEligibilityModel,
            scopeId);
        this.roleIds = (permissionEligibilityModel as Contract.OneLoginDirectoryRoleAssignmentEligibilityModel)?.existingRoleIds ?? [];
    }

    protected UpdatePrincipalTenantRelatedData() {
        super.UpdatePrincipalTenantRelatedData();
        this.roleIds = [];
    }
}