import { useExecuteOperation } from "@infrastructure";
import { Contract, RiskController, RiskPolicyItem } from "../../../../../common";

export function useRiskPoliciesItemExcludedEntities(riskPolicyItem: RiskPolicyItem, scopeId: string) {
    const [riskPolicyExcludedEntityIds] =
        useExecuteOperation(
            [useRiskPoliciesItemExcludedEntities, `${scopeId}/${riskPolicyItem.riskPolicyConfigurationTypeNameOrId}`],
            async () => {
                const { builtInRiskPolicyConfigurationTypeNameToExcludedEntityIdsMap, customRiskPolicyIdToExcludedEntityIdsMap } =
                    await RiskController.getRiskPolicyExcludedEntityIds(
                        riskPolicyItem.custom
                            ? new Contract.RiskControllerGetRiskPolicyExcludedEntityIdsRequest(
                                [],
                                [riskPolicyItem.riskPolicyConfigurationTypeNameOrId],
                                scopeId)
                            : new Contract.RiskControllerGetRiskPolicyExcludedEntityIdsRequest(
                                [riskPolicyItem.riskPolicyConfigurationTypeNameOrId],
                                [],
                                scopeId));

                return riskPolicyItem.custom
                    ? customRiskPolicyIdToExcludedEntityIdsMap[riskPolicyItem.riskPolicyConfigurationTypeNameOrId]
                    : builtInRiskPolicyConfigurationTypeNameToExcludedEntityIdsMap[riskPolicyItem.riskPolicyConfigurationTypeNameOrId];
            },
            {
                persistenceStrategy: "updateOnMount"
            });

    return riskPolicyExcludedEntityIds;
}