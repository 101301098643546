import { useMemo } from "react";
import { useGcpComputeVpcPeeringConnectionDefinition, useGcpDefaultResourceDefinition, useGcpIamRoleDefinition, useGcpKubernetesResourceDefinition, useGcpScopeResourceDefinition } from ".";
import { DefinitionOptions } from "../../..";
import { Contract, TypeHelper } from "../../../../../../../../../../common";

export function useGcpResourceDefinition(entityModel: Contract.EntityModel, options?: DefinitionOptions) {
    const useDefinition =
        useMemo(
            () => {
                switch (entityModel.entity.typeName) {
                    case Contract.TypeNames.GcpComputeVpcPeeringConnection:
                        return useGcpComputeVpcPeeringConnectionDefinition;
                    case Contract.TypeNames.GcpIamRole:
                        return useGcpIamRoleDefinition;
                    default:
                        if (TypeHelper.extendOrImplement(entityModel.entity.typeName, Contract.TypeNames.GcpKubernetesResource)) {
                            return useGcpKubernetesResourceDefinition;
                        }
                        if (TypeHelper.extendOrImplement(entityModel.entity.typeName, Contract.TypeNames.GcpScopeResource)) {
                            return useGcpScopeResourceDefinition;
                        }
                        return useGcpDefaultResourceDefinition;
                }
            },
            []);

    return useDefinition(entityModel as Contract.GcpResourceModel, options);
}