import { SuccessIcon, useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import React from "react";
import { useTheme } from "../../../../../../../../../../../../../../../common";

export function FinishItem() {

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useEndpointConnectorItems.endpointConnectors.addOrView.finishItem",
            () => ({
                title: {
                    next: "Next step: Connect the integration to Tenable Cloud Security",
                    success: "Your Endpoint Connector was added successfully!"
                }
            }));

    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            justifyContent="center"
            spacing={2}
            sx={{ height: "100%" }}>
            <SuccessIcon
                sx={{
                    color: theme.palette.success.main,
                    fontSize: "60px"
                }}/>
            <Typography
                align="center"
                sx={{ fontWeight: 400 }}
                variant="h2">
                {localization.title.success()}
            </Typography>
            <Typography
                align="center"
                sx={{ fontWeight: 400 }}>
                {localization.title.next()}
            </Typography>
        </Stack>);
}