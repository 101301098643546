﻿import { UnexpectedError } from "@infrastructure";
import { Contract } from "..";

export class PrincipalRiskCategoryHelper {
    public static getPrincipalType(principalRiskCategory: Contract.PrincipalRiskCategory) {
        switch (principalRiskCategory) {
            case Contract.PrincipalRiskCategory.ExcessivePermissionAwsPermissionSet:
                return Contract.PrincipalType.AwsPermissionSet;
            case Contract.PrincipalRiskCategory.ExcessivePermissionAwsRoleGroup:
                return Contract.PrincipalType.AwsRoleGroup;
            case Contract.PrincipalRiskCategory.ExcessivePermissionGroup:
                return Contract.PrincipalType.Group;
            case Contract.PrincipalRiskCategory.ExcessivePermissionServiceIdentity:
                return Contract.PrincipalType.ServiceIdentity;
            case Contract.PrincipalRiskCategory.ExcessivePermissionUser:
                return Contract.PrincipalType.User;
            case Contract.PrincipalRiskCategory.InactiveServiceIdentity:
                return Contract.PrincipalType.ServiceIdentity;
            case Contract.PrincipalRiskCategory.InactiveUser:
                return Contract.PrincipalType.User;
            case Contract.PrincipalRiskCategory.UnusedAwsPermissionSet:
                return Contract.PrincipalType.AwsPermissionSet;
            default:
                throw new UnexpectedError("principalRiskCategory", principalRiskCategory);
        }
    }
}