import { Optional, useLocalization } from "@infrastructure";
import React from "react";
import { CommonSectionAndDetailsDefinitionOptions, useCommonSectionsAndDescriptionDefinition } from ".";
import { Contract, CustomRiskPolicy } from "../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../utilities";

export function useCommonCustomEntityRiskDefinition(
    description: string,
    riskModel: Contract.RiskModel,
    riskPolicyConfiguration: Contract.CustomRiskPolicyConfiguration,
    getContextItems: () => Optional<RiskDefinitionContextItem>[],
    options?: CommonSectionAndDetailsDefinitionOptions) {

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.useCommonCustomEntityRiskDefinition",
            () => ({
                template: "**Policy template:** {{policyTemplate}}"
            }));

    return useCommonSectionsAndDescriptionDefinition(
        description,
        () => [],
        riskModel,
        () => [
            new RiskDefinitionContextItem(
                localization.template({
                    policyTemplate:
                        <CustomRiskPolicy
                            riskPolicyConfiguration={riskPolicyConfiguration}
                            riskPolicyConfigurationTypeName={riskPolicyConfiguration.typeName}
                            scopeId={riskPolicyConfiguration.scopeId}/>
                })),
            ...(getContextItems())
        ],
        options);
}