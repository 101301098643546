import { Link, useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import React from "react";
import { CustomerConsoleAppUrlHelper, SupportButton, useTheme } from "../../../../../../../../../../common";

export function Empty() {
    const localization =
        useLocalization(
            "views.customer.workloadAnalysis.code.scans.empty",
            () => ({
                or: "or",
                subtitle: {
                    link: "documentation",
                    text: "Please initiate a CI/CD scan. If you still fail to see scan results, check the **{{documentationLink}}**"
                },
                title: "No scan results"
            }));

    const theme = useTheme();
    return (
        <Stack
            spacing={2}
            sx={{
                alignItems: "center",
                height: "100%",
                justifyContent: "center",
                textAlign: "center",
                width: "100%"
            }}>
            <Typography variant="h2">
                {localization.title()}
            </Typography>
            <Typography
                sx={{
                    fontSize: "14px",
                    maxWidth: theme.spacing(40)
                }}>
                {localization.subtitle.text({
                    documentationLink:
                        <Link
                            urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsContainerImageScanningCICDScanningRelativeUrl()}
                            variant="external">
                            {localization.subtitle.link()}
                        </Link>
                })}
            </Typography>
            <Typography sx={{ fontSize: "14px" }}>
                {localization.or()}
            </Typography>
            <SupportButton/>
        </Stack>);
}