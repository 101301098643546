﻿import { Message, Steps, useLocalization } from "@infrastructure";
import { Stack } from "@mui/material";
import React from "react";
import { useTheme } from "../../../../../../../../../../../../../common";
import { useAddOrEditContext } from "../../../AddOrEdit";

export function TerraformStateBucketMessage() {
    const { tenantModel } = useAddOrEditContext();
    const localization =
        useLocalization(
            "views.customer.scopes.hooks.useDefinition.hooks.useGcpDefinition.addOrEdit.permissionsItem.terraformStateBucketMessage",
            () => ({
                title: "We have identified the following buckets that contain Terraform state data associated with your onboarded projects"
            }));

    const theme = useTheme();
    return (
        <Stack
            spacing={2}
            sx={{ paddingLeft: theme.spacing(5) }}>
            <Stack
                spacing={1}
                sx={{
                    border: theme.border.primary,
                    borderRadius: theme.spacing(0.75),
                    padding: theme.spacing(1)
                }}>
                <Message
                    level="info"
                    title={localization.title()}/>
                <Steps sx={{ paddingLeft: theme.spacing(2) }}>
                    {tenantModel!.state.codeAnalysis.terraformStateBucketNames}
                </Steps>
            </Stack>
        </Stack>);
}