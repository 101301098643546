import { CheckboxField, Dropdown, DropdownActions, DropdownIcon, SelectionView, useLocalization } from "@infrastructure";
import { List, ListItem, Stack } from "@mui/material";
import _ from "lodash";
import React, { useRef, useState } from "react";
import { Contract, Severity, useTheme } from "..";

type SeveritySelectorProps = {
    onSelectedSeveritiesChanged: (selectedSeverities: Contract.Severity[]) => void;
    selectedSeverities: Contract.Severity[];
    title?: string;
};

export function SeverityMultiSelect({ onSelectedSeveritiesChanged, selectedSeverities, title }: SeveritySelectorProps) {
    const actionsRef = useRef<DropdownActions>();
    const [open, setOpen] = useState(false);
    const localization =
        useLocalization(
            "common.severityMultiSelect",
            () => ({
                placeholder: "Severity",
                selection: "{{selectionCount | NumberFormatter.humanize}} severities"
            }));

    const theme = useTheme();
    return (
        <Dropdown
            actionsRef={actionsRef}
            justify="right"
            open={open}
            popoverElement={
                <List
                    dense={true}
                    sx={{ padding: theme.spacing(0.25) }}>
                    {_.map(
                        defaultSeverities,
                        severity =>
                            <ListItem
                                dense={true}
                                key={severity}>
                                <CheckboxField
                                    checked={_.includes(selectedSeverities, severity)}
                                    onChange={
                                        (event, checked) =>
                                            onSelectedSeveritiesChanged(
                                                checked
                                                    ? _.concat(selectedSeverities, severity)
                                                    : _.without(selectedSeverities, severity))}>
                                    <Severity severity={severity}/>
                                </CheckboxField>
                            </ListItem>)}
                </List>}
            popoverElementContainerSx={{ padding: 0 }}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}>
            <Stack
                direction="row"
                justifyContent="center"
                spacing={1}
                sx={{
                    "&:hover": {
                        border: theme.border.hoverFocus
                    },
                    border:
                        open
                            ? theme.border.hoverFocus
                            : theme.border.primary,
                    borderRadius: theme.spacing(0.75),
                    padding: theme.spacing(0.5, 0.5, 0.5, 1.5)
                }}>
                <SelectionView
                    empty={selectedSeverities.length === 0}
                    label={title ?? localization.placeholder()}
                    selectedValues={selectedSeverities}
                    sx={{ color: undefined }}
                    totalCount={defaultSeverities.length}/>
                <DropdownIcon sx={{ fontSize: "18px" }}/>
            </Stack>
        </Dropdown>);
}

export const defaultSeverities = [
    Contract.Severity.Critical,
    Contract.Severity.High,
    Contract.Severity.Medium,
    Contract.Severity.Low
];