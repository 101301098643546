import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { EntityExternalConsoleLink } from "../../components";
import { useGetAzureStorageStorageAccountRiskContext } from "../contexts";

export function useAzureStorageStorageAccountServiceDiagnosticSettingsLogCategoryNotEnabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AzureStorageStorageAccountServiceDiagnosticSettingsLogCategoryNotEnabledRisk;
    const storageAccountModel = entityModelStore.useGet(risk.entityId) as Contract.AzureStorageStorageAccountModel;

    const getAzureStorageStorageAccountRiskContext = useGetAzureStorageStorageAccountRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.compliance.useAzureStorageStorageAccountServiceDiagnosticSettingsLogCategoryNotEnabledRiskDefinition",
            () => ({
                description: "{{storageAccount}} {{serviceTypeName}} service logging is not enabled",
                sections: {
                    resolution: {
                        step1: "Select the {{serviceTypeName}} diagnostic settings",
                        step2: "Click **Add diagnostic setting**",
                        step3: "Fill in the **Diagnostic setting name**",
                        step4: "Under the **Categories** section, select at least one of the following log types: **Storage Read**, **Storage Write** or **Storage Delete**",
                        step5: "Select the relevant **Destination details**",
                        step6: "Click **Save**"
                    }
                },
                serviceTypeName: {
                    [Contract.TypeNames.AzureStorageStorageAccountServiceDiagnosticSettingsLogCategoryNotEnabledRisk]: {
                        [Contract.TypeNames.AzureStorageStorageAccountBlobServiceDiagnosticSettingsLogCategoryNotEnabledRisk]: "blob",
                        [Contract.TypeNames.AzureStorageStorageAccountQueueServiceDiagnosticSettingsLogCategoryNotEnabledRisk]: "queue",
                        [Contract.TypeNames.AzureStorageStorageAccountTableServiceDiagnosticSettingsLogCategoryNotEnabledRisk]: "table"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            serviceTypeName: localization.serviceTypeName[Contract.TypeNames.AzureStorageStorageAccountServiceDiagnosticSettingsLogCategoryNotEnabledRisk].translate(risk.typeName),
            storageAccount:
                <Entity
                    entityIdOrModel={storageAccountModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={risk.entityId}
                key={risk.entityId}
                page={Contract.AzureConsoleEntityPage.Diagnostics}/>,
            localization.sections.resolution.step1({
                serviceTypeName: localization.serviceTypeName[Contract.TypeNames.AzureStorageStorageAccountServiceDiagnosticSettingsLogCategoryNotEnabledRisk].translate(risk.typeName)
            }),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4(),
            localization.sections.resolution.step5(),
            localization.sections.resolution.step6()
        ],
        riskModel,
        () => {
            const storageAccountRiskContext = getAzureStorageStorageAccountRiskContext(storageAccountModel);
            return [
                storageAccountRiskContext.generalInformation,
                storageAccountRiskContext.sensitive,
                storageAccountRiskContext.inboundExternalAccessScope,
                storageAccountRiskContext.childResources,
                storageAccountRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}