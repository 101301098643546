import { Action0, CheckboxField, useLocalization } from "@infrastructure";
import { ListItem, Stack, Typography, useTheme } from "@mui/material";
import _ from "lodash";
import React, { ReactNode, useState } from "react";

type PagedValueListItemProps = {
    checked: boolean;
    children: ReactNode;
    onCheckboxClick: Action0;
    onOnlyClick: Action0;
};

export function PagedValueListItem({ checked, children, onCheckboxClick, onOnlyClick }: PagedValueListItemProps) {
    const [hover, setHover] = useState(false);
    const onClick =
        _.debounce(
            (event: React.MouseEvent<HTMLLIElement> | React.ChangeEvent<HTMLInputElement>) => {
                event.stopPropagation();
                onCheckboxClick();
            },
            50);

    const localization =
        useLocalization(
            "infrastructure.filters.pagedValuesFilter.pagedValueListItem",
            () => ({
                only: "Only"
            }));

    const theme = useTheme();
    return (
        <ListItem
            disableGutters={true}
            sx={{
                background:
                    hover
                        ? theme.palette.action.hover
                        : undefined,
                cursor: "pointer",
                padding: theme.spacing(1, 3, 1, 1)
            }}
            onClick={onClick}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}>
            <Stack
                direction="row"
                spacing={2}
                sx={{
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%"
                }}>
                <CheckboxField
                    checked={checked}
                    sx={{
                        maxWidth: theme.spacing(35),
                        minWidth: theme.spacing(20)
                    }}
                    onChange={onClick}>
                    {children}
                </CheckboxField>
                <Typography
                    sx={{
                        cursor: "pointer",
                        textDecoration: "underline",
                        visibility:
                            hover
                                ? "visible"
                                : "hidden"
                    }}
                    onClick={
                        event => {
                            event.preventDefault();
                            event.stopPropagation();
                            onOnlyClick();
                        }}>
                    {localization.only()}
                </Typography>
            </Stack>
        </ListItem>);
}