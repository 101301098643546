import { Dictionary } from "lodash";
import React from "react";
import { entityModelStore, useEntityTypeNameTranslator } from "../../../../../../../../../common";
import { GcpRoleBindingTable } from "../../../../../../../../../tenants";

type GciDirectoryPrincipalGcpRoleBindingsProps = {
    csvExportFilePrefix: string;
    gcpRoleBindingIds: string[];
    initialFilterMap?: Dictionary<any>;
    principalId: string;
};

export function GciDirectoryPrincipalGcpRoleBindings({ csvExportFilePrefix, gcpRoleBindingIds, initialFilterMap, principalId }: GciDirectoryPrincipalGcpRoleBindingsProps) {
    const principalModel = entityModelStore.useGet(principalId);
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    return (
        <GcpRoleBindingTable
            csvExportFilePrefixes={[
                entityTypeNameTranslator(principalModel.entity.typeName, { includeServiceName: false }),
                principalModel.entity.displayName,
                csvExportFilePrefix
            ]}
            initialFilterMap={initialFilterMap}
            principalOrResourceId={principalId}
            roleBindingIds={gcpRoleBindingIds}
            variant="roleAndScopeAndPrincipal"/>);
}