﻿import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function CommentIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                clipRule="evenodd"
                d="M22.3215 15.812C22.7603 14.6243 23 13.3402 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C14.1559 23 16.1669 22.3798 17.8643 21.3081L22.8625 21.7625L22.3215 15.812ZM20.6534 19.5534L20.2888 15.5429L20.4455 15.1189C20.8035 14.1498 21 13.1001 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C13.7669 21 15.4093 20.4928 16.7966 19.617L17.37 19.2549L20.6534 19.5534Z"
                fillRule="evenodd"/>
            <rect
                height="2"
                rx="1"
                width="10"
                x="7"
                y="9"/>
            <rect
                height="2"
                rx="1"
                width="7"
                x="7"
                y="13"/>
        </SvgIcon>);
}