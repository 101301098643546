import { InfoIcon, StringHelper, Tooltip, useInputValidation, useLocalization, useOrderedWizardContext } from "@infrastructure";
import { FormControl, FormHelperText, InputAdornment, Stack, TextField } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { addOrViewContext, useSetAddOrViewContext } from "..";
import { ConfigurationController, Contract, scopeSystemEntityModelStore, useScopeNavigationViewContext, useTheme } from "../../../../../../../../../../../../../../../common";

export function ConfigurationItem() {
    const endpointConnectorModels = scopeSystemEntityModelStore.useGetEndpointConnector();

    const { scopeNodeModel } = useScopeNavigationViewContext();
    const { executing, setLoaded, setValid, useNextEffect } = useOrderedWizardContext();
    const { endpointConnectorHostName, endpointConnectorModel, endpointConnectorName, endpointConnectorPort } = addOrViewContext();
    const setAddOrViewContext = useSetAddOrViewContext();

    const [hostName, setHostName] = useState(endpointConnectorHostName);
    const [name, setName] = useState(endpointConnectorName ?? "");
    const [port, setPort] = useState(endpointConnectorPort);

    useEffect(setLoaded, []);

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useEndpointConnectorItems.endpointConnectors.addOrView.configurationItem",
            () => ({
                actions: {
                    save: {
                        error: "Failed to save",
                        title: "Save"
                    }
                },
                fields: {
                    endpoint: {
                        hostName: {
                            errorRequired: "Service hostname cannot be empty",
                            title: "Endpoint",
                            tooltip: "The hostname or IP address of the integration. e.g. github.tenable.com or 192.82.156.1"
                        },
                        port: {
                            errorRequired: "Service port cannot be empty",
                            title: "Port",
                            tooltip: "The port number used by the integration"
                        }
                    },
                    name: {
                        error: {
                            exists: "Name already exists",
                            required: "Name cannot be empty"
                        },
                        title: "Name",
                        tooltip: "The connector name, as it will appear in the Tenable Cloud Security Console"
                    }
                }
            }));

    const [existingEndpointConnectorModels] =
        useMemo(
            () => {
                const existingEndpointConnectorModels =
                    _.filter(
                        endpointConnectorModels,
                        otherEndpointConnectorModel =>
                            otherEndpointConnectorModel.configuration.id !== endpointConnectorModel?.configuration.id &&
                            otherEndpointConnectorModel.configuration.scopeId == scopeNodeModel.configuration.id);
                return [existingEndpointConnectorModels];
            },
            [endpointConnectorModels]);

    const [hostNameValidationController, hostNameValidationMessage] =
        useInputValidation(
            () => {
                const validationHostName = hostName.trim();
                if (_.isEmpty(validationHostName)) {
                    return localization.fields.endpoint.hostName.errorRequired();
                }

                return undefined;
            },
            [hostName]);

    const [nameValidationController, nameValidationMessage] =
        useInputValidation(
            () => {
                const normalizedName = StringHelper.normalize(name);

                if (_.isEmpty(normalizedName)) {
                    return localization.fields.name.error.required();
                }

                if (_.some(
                    existingEndpointConnectorModels,
                    existingEndpointConnectorModel => StringHelper.compare((existingEndpointConnectorModel.configuration as Contract.EndpointConnectorConfiguration).name, normalizedName))) {
                    return localization.fields.name.error.exists();
                }

                return undefined;
            },
            [name]);

    const [portValidationController, portValidationMessage] =
        useInputValidation(
            () => {
                if (_.isNaN(port)) {
                    return localization.fields.endpoint.port.errorRequired();
                }

                return undefined;
            },
            [port]);

    useNextEffect(
        async () => {
            try {
                if (!_.isNil(endpointConnectorModel)) {
                    return undefined;
                }
                const { scopeSystemEntityModel } =
                    await ConfigurationController.insertEndpointConnector(
                        new Contract.ConfigurationControllerInsertEndpointConnectorRequest(
                            `${hostName}:${port}`,
                            name,
                            scopeNodeModel.configuration.id));

                setAddOrViewContext(
                    context => ({
                        ...context,
                        endpointConnectorModel: scopeSystemEntityModel
                    }));

                return undefined;
            } catch (error) {
                return localization.actions.save.error();
            }
        },
        [hostName, port, name]);

    useEffect(
        () => {
            setValid(
                hostNameValidationController.isValid() &&
                nameValidationController.isValid() &&
                portValidationController.isValid());
        },
        [hostName, name, port]);

    const theme = useTheme();
    return (
        <Stack
            spacing={3}
            sx={{ width: theme.spacing(61) }}>
            <FormControl
                fullWidth={true}
                variant="standard">
                <TextField
                    disabled={executing || !_.isNil(endpointConnectorModel)}
                    label={localization.fields.name.title()}
                    slotProps={{
                        input: {
                            endAdornment:
                                <InputAdornment position="end">
                                    <Tooltip titleOrGetTitle={localization.fields.name.tooltip()}>
                                        <InfoIcon
                                            sx={{
                                                color: theme.palette.text.secondary,
                                                fontSize: "24px"
                                            }}/>
                                    </Tooltip>
                                </InputAdornment>
                        }
                    }}
                    value={name}
                    variant="outlined"
                    onChange={event => setName(event.target.value)}/>
                {!_.isNil(nameValidationMessage) && (
                    <FormHelperText error={true}>{nameValidationMessage}</FormHelperText>)}
            </FormControl>
            <FormControl
                fullWidth={true}
                variant="standard">
                <TextField
                    disabled={executing || !_.isNil(endpointConnectorModel)}
                    label={localization.fields.endpoint.hostName.title()}
                    slotProps={{
                        input: {
                            endAdornment:
                                <InputAdornment position="end">
                                    <Tooltip titleOrGetTitle={localization.fields.endpoint.hostName.tooltip()}>
                                        <InfoIcon
                                            sx={{
                                                color: theme.palette.text.secondary,
                                                fontSize: "24px"
                                            }}/>
                                    </Tooltip>
                                </InputAdornment>
                        }
                    }}
                    value={hostName}
                    variant="outlined"
                    onChange={event => setHostName(event.target.value)}/>
                {!_.isNil(hostNameValidationMessage) && (
                    <FormHelperText error={true}>{hostNameValidationMessage}</FormHelperText>)}
            </FormControl>
            <FormControl
                fullWidth={true}
                variant="standard">
                <TextField
                    disabled={executing || !_.isNil(endpointConnectorModel)}
                    label={localization.fields.endpoint.port.title()}
                    slotProps={{
                        input: {
                            endAdornment:
                                <InputAdornment position="end">
                                    <Tooltip titleOrGetTitle={localization.fields.endpoint.port.tooltip()}>
                                        <InfoIcon
                                            sx={{
                                                color: theme.palette.text.secondary,
                                                fontSize: "24px"
                                            }}/>
                                    </Tooltip>
                                </InputAdornment>
                        }
                    }}
                    type="number"
                    value={port}
                    variant="outlined"
                    onChange={event => setPort(_.parseInt(event.target.value))}/>
                {!_.isNil(portValidationMessage) && (
                    <FormHelperText error={true}>{portValidationMessage}</FormHelperText>)}
            </FormControl>
        </Stack>);
}