import { useLocalization } from "@infrastructure";
import React, { ReactNode } from "react";
import { InfoCard } from "../../../../../../../../../common";

type AwsNetworkingInfoCardProps = {
    children: ReactNode;
};

export function NetworkingInfoCard({ children }: AwsNetworkingInfoCardProps) {
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.networkingInfoCard",
            () => ({
                title: "Network"
            }));

    return (
        <InfoCard
            columns={true}
            title={localization.title()}>
            {children}
        </InfoCard>);
}