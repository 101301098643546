import { Box, Divider, Stack, SxProps, Typography, useTheme } from "@mui/material";
import useResizeObserver from "@react-hook/resize-observer";
import _ from "lodash";
import React, { ReactNode, useCallback, useRef, useState } from "react";
import { Loading, Message } from ".";

type FormLayoutProps = {
    children: ReactNode;
    disableContentPadding?: boolean;
    footerOptions?: FormLayoutFooterOptions;
    titleOptions?: FormLayoutTitleOptions;
};

export type FormLayoutTitleOptions = {
    description?: string;
    infoElement?: ReactNode;
    subtitle?: ReactNode;
    subtitleSx?: SxProps;
    text: string;
};

type FormLayoutFooterOptions = {
    border?: boolean;
    contentElement: ReactNode;
    errorMessage?: string;
};

export function FormLayout({ children, disableContentPadding, footerOptions, titleOptions }: FormLayoutProps) {
    const contentElementRef = useRef<HTMLDivElement>(null);
    const [footerBorder, setFooterBorder] = useState(false);
    const updateFooterBorder =
        useCallback(
            () => {
                if (!_.isNil(contentElementRef.current)) {
                    const { clientHeight, scrollHeight, scrollTop } = contentElementRef.current;
                    setFooterBorder(scrollTop + clientHeight + 16 < scrollHeight);
                }
            },
            []);

    useResizeObserver(contentElementRef, updateFooterBorder);
    const theme = useTheme();
    return (
        <Stack
            sx={{
                flex: 1,
                height: "100%",
                overflow: "auto"
            }}>
            {titleOptions &&
                <Stack sx={{ padding: theme.spacing(2, 5, 0, 3) }}>
                    <Stack
                        alignItems="center"
                        direction="row"
                        spacing={1}>
                        <Typography variant="h2">
                            {titleOptions.text}
                            {!_.isNil(titleOptions.description) &&
                                <Box
                                    component="span"
                                    sx={{ fontWeight: "normal" }}>
                                    &nbsp;- {titleOptions.description}
                                </Box>}
                        </Typography>
                        {!_.isNil(titleOptions.infoElement) &&
                            <Message
                                level="info"
                                title={
                                    <Typography
                                        sx={{
                                            padding: theme.spacing(1),
                                            whiteSpace: "pre-wrap"
                                        }}>
                                        {titleOptions.infoElement}
                                    </Typography>}
                                variant="minimal"/>}
                    </Stack>
                    {!_.isNil(titleOptions.subtitle) &&
                        <Typography
                            sx={{
                                fontSize: "13px",
                                lineHeight: "18px",
                                marginTop: theme.spacing(1),
                                width: "85%",
                                ...titleOptions.subtitleSx
                            }}
                            variant="subtitle1">
                            {titleOptions.subtitle}
                        </Typography>}
                    <Divider sx={{ margin: theme.spacing(2, -5, 0, -3) }}/>
                </Stack>}
            <Stack
                ref={contentElementRef}
                sx={{
                    flex: 1,
                    overflowY: "auto",
                    padding:
                        !disableContentPadding
                            ? theme.spacing(3)
                            : 0

                }}
                onScroll={updateFooterBorder}>
                <Loading>
                    {children}
                </Loading>
            </Stack>
            {!_.isNil(footerOptions) &&
                <Stack
                    alignItems="center"
                    direction="row"
                    spacing={2}
                    sx={{
                        borderTop: theme.border.primary,
                        borderTopColor:
                            footerBorder || footerOptions.border
                                ? undefined
                                : "transparent",
                        justifyContent: "space-between",
                        padding: theme.spacing(3)
                    }}>
                    {!_.isNil(footerOptions.errorMessage) && (
                        <Box sx={{ width: "100%" }}>
                            <Message
                                level="error"
                                title={footerOptions.errorMessage}/>
                        </Box>)}
                    <Box sx={{ width: "100%" }}>
                        {footerOptions.contentElement}
                    </Box>
                </Stack>}
        </Stack>);
}