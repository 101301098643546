import { DataTableColumn, DataTableSortType, EnumValuesFilter, optionalTableCell, PagedValuesFilter, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { useAzureCommonResourceGroupResourceDefinition } from ".";
import { EntityTableDefinition } from "../..";
import { Contract, ElasticsearchItemPageHelper, ItemSelectionHelper, TimeRangeHelper } from "../../../../../../../../../../common";
import { useAzureWebApplicationAuthenticationTypeTranslator, useAzureWebApplicationTypeTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";

export function useAzureWebApplicationDefinition(definitionData: DefinitionData) {
    const commonResourceGroupResourceDefinition = useAzureCommonResourceGroupResourceDefinition(definitionData);

    const webApplicationAuthenticationTypeTranslator = useAzureWebApplicationAuthenticationTypeTranslator();
    const webApplicationTypeTranslator = useAzureWebApplicationTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.azure.useAzureWebApplicationDefinition",
            () => ({
                columns: {
                    authenticationType: "Authentication Type",
                    httpsOnly: {
                        false: "Disabled",
                        title: "HTTPS only",
                        true: "Enabled"
                    },
                    status: {
                        title: "Status",
                        [Contract.TypeNames.AzureWebApplicationStatus]: {
                            [Contract.AzureWebApplicationStatus.AdminDisabled]: "AdminDisabled",
                            [Contract.AzureWebApplicationStatus.Disabled]: "Disabled",
                            [Contract.AzureWebApplicationStatus.Running]: "Running",
                            [Contract.AzureWebApplicationStatus.Stopped]: "Stopped"
                        }
                    },
                    tlsMinVersion: "Minimum TLS version",
                    type: "App Type",
                    url: "App URL"
                }
            }));

    return new EntityTableDefinition(
        _.filter([
            commonResourceGroupResourceDefinition.columns.tenantColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AzureWebApplicationModel) => ({
                            [localization.columns.type()]: webApplicationTypeTranslator((item.entity as Contract.AzureWebApplication).type)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.AzureWebApplicationType}
                                enumTypeTranslator={webApplicationTypeTranslator}
                                placeholder={localization.columns.type()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AzureWebApplicationType}
                itemProperty={(item: Contract.AzureWebApplicationModel) => webApplicationTypeTranslator((item.entity as Contract.AzureWebApplication).type)}
                key={Contract.EntityModelProperty.AzureWebApplicationType}
                title={localization.columns.type()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AzureWebApplicationModel) => {
                            const status = (item.entity as Contract.AzureWebApplication).status;
                            return ({
                                [localization.columns.status.title()]:
                                    _.isNil(status)
                                        ? ""
                                        : localization.columns.status[Contract.TypeNames.AzureWebApplicationStatus][status]()
                            });
                        }
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                emptyValueOptions={{ enabled: true }}
                                enumType={Contract.AzureWebApplicationStatus}
                                enumTypeTranslator={(status: Contract.AzureWebApplicationStatus) => localization.columns.status[Contract.TypeNames.AzureWebApplicationStatus][status]()}
                                placeholder={localization.columns.status.title()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AzureWebApplicationStatus}
                key={Contract.EntityModelProperty.AzureWebApplicationStatus}
                render={
                    optionalTableCell(
                        (item: Contract.AzureWebApplicationModel) => {
                            const status = (item.entity as Contract.AzureWebApplication).status;
                            return _.isNil(status)
                                ? undefined
                                : localization.columns.status[Contract.TypeNames.AzureWebApplicationStatus][status]();
                        })}
                title={localization.columns.status.title()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AzureWebApplicationModel) => ({
                            [localization.columns.url()]: (item.entity as Contract.AzureWebApplication).url
                        })
                }}
                id="url"
                key="url"/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AzureWebApplicationModel) => ({
                            [localization.columns.authenticationType()]:
                                _.isNil((item.entity as Contract.AzureWebApplication).authenticationType)
                                    ? ""
                                    : webApplicationAuthenticationTypeTranslator((item.entity as Contract.AzureWebApplication).authenticationType!)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                emptyValueOptions={{ enabled: true }}
                                enumType={Contract.AzureWebApplicationAuthenticationType}
                                enumTypeTranslator={webApplicationAuthenticationTypeTranslator}
                                placeholder={localization.columns.authenticationType()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AzureWebApplicationAuthenticationType}
                key={Contract.EntityModelProperty.AzureWebApplicationAuthenticationType}
                render={
                    optionalTableCell<Contract.AzureWebApplicationModel>(
                        item =>
                            _.isNil((item.entity as Contract.AzureWebApplication).authenticationType)
                                ? undefined
                                : webApplicationAuthenticationTypeTranslator((item.entity as Contract.AzureWebApplication).authenticationType!))}
                title={localization.columns.authenticationType()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AzureWebApplicationModel) => ({
                            [localization.columns.httpsOnly.title()]:
                                (item.entity as Contract.AzureWebApplication).httpsOnly
                                    ? localization.columns.httpsOnly.true()
                                    : localization.columns.httpsOnly.false()
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <ValuesFilter
                                placeholder={localization.columns.httpsOnly.title()}
                                sorted={false}>
                                <ValuesFilterItem
                                    title={localization.columns.httpsOnly.true()}
                                    value={true}/>
                                <ValuesFilterItem
                                    title={localization.columns.httpsOnly.false()}
                                    value={false}/>
                            </ValuesFilter>
                    }
                }}
                id={Contract.EntityModelProperty.AzureWebApplicationHttpsOnly}
                itemProperty={
                    (item: Contract.AzureWebApplicationModel) =>
                        (item.entity as Contract.AzureWebApplication).httpsOnly
                            ? localization.columns.httpsOnly.true()
                            : localization.columns.httpsOnly.false()}
                key={Contract.EntityModelProperty.AzureWebApplicationHttpsOnly}
                selectorOptions={{ default: false }}
                title={localization.columns.httpsOnly.title()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AzureWebApplicationModel) => ({
                            [localization.columns.tlsMinVersion()]: (item.entity as Contract.AzureWebApplication).tlsMinVersion
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedValuesFilter
                                getValuePage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                        Contract.TypeNames.AzureWebApplication,
                                        Contract.EntityModelProperty.AzureWebApplicationTlsMinVersion)}
                                placeholder={localization.columns.tlsMinVersion()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AzureWebApplicationTlsMinVersion}
                key={Contract.EntityModelProperty.AzureWebApplicationTlsMinVersion}
                render={optionalTableCell<Contract.AzureWebApplicationModel>(item => (item.entity as Contract.AzureWebApplication).tlsMinVersion)}
                selectorOptions={{ default: false }}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.tlsMinVersion()}/>,
            commonResourceGroupResourceDefinition.columns.creationTimeColumn,
            commonResourceGroupResourceDefinition.columns.creatorIdentityCsvColumn,
            commonResourceGroupResourceDefinition.columns.regionColumn,
            commonResourceGroupResourceDefinition.columns.regionLocationColumn,
            commonResourceGroupResourceDefinition.columns.resourceGroupColumn,
            commonResourceGroupResourceDefinition.columns.tagsColumn,
            commonResourceGroupResourceDefinition.columns.attributesColumn,
            commonResourceGroupResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonResourceGroupResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonResourceGroupResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ]),
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAzureWebApplicationRequest(
                new Contract.EntityControllerGetEntityModelPageAzureWebApplicationRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureResourceGroupResourceResourceGroup]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureWebApplicationAuthenticationType]),
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureWebApplicationHttpsOnly]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureWebApplicationStatus]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureWebApplicationTlsMinVersion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureWebApplicationType])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags]))));
}