import { useLocalization, WizardFinishItem } from "@infrastructure";
import React from "react";
import { useCodePipelineTypeTranslator } from "../../../../../../../../../../../../../../../tenants";
import { useAddOrEditContext } from "../AddOrEdit";

export function FinishItem() {
    const { pipelineType } = useAddOrEditContext();

    const pipelineTypeTranslator = useCodePipelineTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCodePipelineItems.codePipeline.addOrEdit.finishItem",
            () => ({
                title: "{{translatedPipelineType}} configured successfully"
            }));

    return <WizardFinishItem title={localization.title({ translatedPipelineType: pipelineTypeTranslator(pipelineType, "configuration") })}/>;
}