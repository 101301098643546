import { Action1, DirectedGraphModelEdge, NodeContentContextMenu, NodeContentContextMenuItem, Optional, useDirectedGraphNodeContext, useLocalization, useSetDirectedGraphContext, useSetRoute } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { Fragment, MutableRefObject } from "react";
import { Contract, CustomerConsoleAppUrlHelper, EntityList, entityModelStore, EntityNodeContentIcon, getEntityNodeContentSize, InlineEntities, useEntityNodeContentSubtitleTranslator, useEntityTypeNameTranslator, useTheme } from "../../../../../..";
import { useNetworkContext } from "../../../..";
import { ToolbarActions } from "../../..";
import { Scope } from "./components";

type DestinationGroupNodeContentProps = {
    destinationGroup: Contract.NetworkGraphDestinationGroup;
    onScopeClick: Action1<string>;
    toolbarActionsRef: MutableRefObject<Optional<ToolbarActions>>;
};

export function DestinationGroupNodeContent({ destinationGroup, onScopeClick, toolbarActionsRef }: DestinationGroupNodeContentProps) {
    const { modelNode } = useDirectedGraphNodeContext();
    const setDirectedGraphContext = useSetDirectedGraphContext();
    const entityModels = entityModelStore.useGet(destinationGroup.entityIds);
    const parentEntityModel = entityModelStore.useGet(destinationGroup.parentEntityId);

    const entityNodeSubtitleTranslator = useEntityNodeContentSubtitleTranslator();
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "common.network.graph.destinationGroupNodeContent",
            () => ({
                menu: {
                    entities: "View {{translatedEntityTypeName}}",
                    profile: "Go to {{translatedEntityTypeName}} profile page"
                }
            }));

    const setRoute = useSetRoute();
    const theme = useTheme();
    const iconSize = headerHeight / 2;
    const iconMarginY = (headerHeight - iconSize) / 2;
    const iconMarginX = 10;
    const { width } = getDestinationGroupNodeContentSize(destinationGroup);
    const { entityId } = useNetworkContext();
    const url = CustomerConsoleAppUrlHelper.getEntityProfileRelativeUrl(entityModels[0]);
    return (
        <g>
            <NodeContentContextMenu
                items={
                    !_.isNil(parentEntityModel)
                        ? undefined
                        : _<NodeContentContextMenuItem>([]).
                            concatIf(
                                entityModels.length === 1 &&
                                entityId !== entityModels[0].id &&
                                !_.isNil(url),
                                () =>
                                    new NodeContentContextMenuItem(
                                        () => setRoute(url!),
                                        localization.menu.profile({
                                            translatedEntityTypeName:
                                                entityTypeNameTranslator(
                                                    entityModels[0].entity.typeName,
                                                    {
                                                        includeServiceName: false,
                                                        variant: "text"
                                                    })
                                        }))).
                            concatIf(
                                entityModels.length > 1,
                                () =>
                                    new NodeContentContextMenuItem(
                                        () =>
                                            setDirectedGraphContext(
                                                context => ({
                                                    ...context,
                                                    dialogContentElement:
                                                        <EntityList
                                                            entityIdOrModels={entityModels}
                                                            entityTypeName={destinationGroup.entityTypeName}
                                                            variant="dialog"/>
                                                })),
                                        localization.menu.entities({
                                            translatedEntityTypeName:
                                                entityTypeNameTranslator(
                                                    entityModels[0].entity.typeName,
                                                    {
                                                        count: entityModels.length,
                                                        includeCount: true,
                                                        includeServiceName: false,
                                                        variant: "text"
                                                    })
                                        }))).
                            value()}>
                <rect
                    fill={theme.palette.background.paper}
                    height={headerHeight}
                    rx="4px"
                    stroke={
                        modelNode.options.appearance === "highlighted"
                            ? theme.palette.text.primary
                            : theme.palette.text.secondary}
                    strokeDasharray={8}
                    width={width}
                    x={0}
                    y={0}/>
                <svg
                    height={iconSize}
                    width={iconSize}
                    x={iconMarginX}
                    y={iconMarginY}>
                    <EntityNodeContentIcon
                        entityIds={destinationGroup.entityIds}
                        entityTypeName={destinationGroup.entityTypeName}/>
                </svg>
                <foreignObject
                    height={headerHeight}
                    transform={`translate(${iconSize + iconMarginX * 2},0)`}
                    width={width - iconSize - iconMarginX * 2}>
                    <Stack
                        justifyContent="center"
                        sx={{ height: "100%" }}>
                        {(!_.isNil(parentEntityModel) || entityModels.length === 1) &&
                            <Fragment>
                                <Typography
                                    noWrap={true}
                                    sx={{
                                        fontSize: "14px",
                                        width: "100%"
                                    }}>
                                    {(parentEntityModel ?? entityModels[0]).entity.displayName}
                                </Typography>
                                <Typography
                                    noWrap={true}
                                    sx={{
                                        color: theme.palette.text.secondary,
                                        fontSize: "14px",
                                        width: "100%"
                                    }}>
                                    {entityNodeSubtitleTranslator(parentEntityModel ?? entityModels[0])}
                                </Typography>
                            </Fragment>}
                        {(!_.isNil(parentEntityModel)) &&
                            <InlineEntities
                                entityIdsOrModels={entityModels}
                                entityTypeName={entityModels[0].entity.typeName}
                                variant="itemCountAndType"/>}
                        {_.isNil(parentEntityModel) && entityModels.length > 1 &&
                            <Typography
                                noWrap={true}
                                sx={{
                                    fontSize: "14px",
                                    width: "100%"
                                }}>
                                {entityTypeNameTranslator(
                                    entityModels[0].entity.typeName,
                                    {
                                        count: entityModels.length,
                                        includeCount: true
                                    })}
                            </Typography>}
                    </Stack>
                </foreignObject>
            </NodeContentContextMenu>
            <g transform={`translate(${width * (1 - scopeWidthRatio) / 2}, ${headerHeight})`}>
                <line
                    stroke={theme.palette.text.secondary}
                    x1={width * scopeWidthRatio / 2}
                    x2={width * scopeWidthRatio / 2}
                    y1={0}
                    y2={(scopeHeight + scopeSpacing) * destinationGroup.scopes.length}/>
                {_.map(
                    destinationGroup.scopes,
                    (scope, scopeIndex) =>
                        <g
                            key={scope.id}
                            transform={`translate(0, ${(scopeHeight + scopeSpacing) * scopeIndex + scopeSpacing})`}
                            onClick={
                                event => {
                                    onScopeClick(scope.id);
                                    event.stopPropagation();
                                }}>
                            <Scope
                                scope={scope}
                                size={{
                                    height: scopeHeight,
                                    width: scopeWidthRatio * width
                                }}
                                toolbarActionsRef={toolbarActionsRef}/>
                        </g>)}
            </g>
        </g>);
}

const headerHeight = 100;
const scopeHeight = 36;
const scopeSpacing = 24;
const scopeWidthRatio = 0.7;

export const getDestinationGroupNodeContentAnchorPoint =
    (destinationGroup: Contract.NetworkGraphDestinationGroup, edge: DirectedGraphModelEdge) => {
        const anchorMargin = 16;
        const { width } = getDestinationGroupNodeContentSize(destinationGroup);
        if (_.isNil(edge.options.destinationAnchorId)) {
            return ({
                x:
                    edge.sourceNodeId === destinationGroup.id
                        ? width + anchorMargin
                        : -anchorMargin,
                y: headerHeight / 2
            });
        } else {
            const scopeIndex =
                _.findIndex(
                    destinationGroup.scopes,
                    scope => scope.id === edge.options.destinationAnchorId);
            return ({
                x:
                    edge.sourceNodeId === destinationGroup.id
                        ? width * (1 + scopeWidthRatio) / 2 + anchorMargin
                        : width * (1 - scopeWidthRatio) / 2 - anchorMargin,
                y: headerHeight + scopeSpacing + (scopeHeight + scopeSpacing) * scopeIndex + scopeHeight / 2
            });
        }
    };

export const getDestinationGroupNodeContentSize =
    (destinationGroup: Contract.NetworkGraphDestinationGroup) => ({
        height: headerHeight * 2 + scopeSpacing + destinationGroup.scopes.length * (scopeHeight + scopeSpacing),
        width: getEntityNodeContentSize("bottom", "large").width
    });