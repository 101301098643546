import { Link, useLocalization } from "@infrastructure";
import React from "react";
import { Contract, CustomerConsoleAppUrlHelper, TenantIcon, useTenantTypeTranslator } from "../../../../../../../common";
import { IdentityProviderTenantTable, IntegrationInformation } from "../components";

export function OneLoginDirectoryRoleAssignment() {
    const localization =
        useLocalization(
            "views.customer.configuration.permissionManagement.oneLoginDirectoryRoleAssignment",
            () => ({
                description: {
                    items: {
                        item1: "Administrators can create eligibilities that allow requesters to request temporary role assignments.",
                        item2: {
                            link: "Learn how to grant permissions",
                            text: "Requires permission to assign roles to users. {{link}}"
                        }
                    }
                },
                title: "{{translatedTenantType}} role assignments"
            }));

    const tenantTypeTranslator = useTenantTypeTranslator();
    return (
        <IntegrationInformation
            icon={
                <TenantIcon
                    sx={{ fontSize: "30px" }}
                    tenantType={Contract.TenantType.OneLogin}/>}
            items={[
                localization.description.items.item1(),
                localization.description.items.item2.text({
                    link:
                        <Link
                            urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsGrantTenableJitPermissionsRelativeUrl(Contract.TenantType.OneLogin)}
                            variant="external">
                            {localization.description.items.item2.link()}
                        </Link>
                })]}
            title={localization.title({ translatedTenantType: tenantTypeTranslator(Contract.TenantType.OneLogin) })}>
            <IdentityProviderTenantTable tenantType={Contract.TenantType.OneLogin}/>
        </IntegrationInformation>);
}