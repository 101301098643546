import { Stack, Typography } from "@mui/material";
import React from "react";
import { useUdmObjectTypeNameTranslator } from "../../../hooks";
import { ObjectIcon } from "../../../icons";

type UdmObjectProps = {
    objectTypeName: string;
};

export function UdmObject({ objectTypeName }: UdmObjectProps) {
    const udmObjectTypeNameTranslator = useUdmObjectTypeNameTranslator();
    return (
        <Stack
            alignItems="center"
            direction="row"
            spacing={0.75}
            sx={{ overflow: "hidden" }}>
            <ObjectIcon objectTypeName={objectTypeName}/>
            <Typography noWrap={true}>
                {udmObjectTypeNameTranslator(objectTypeName)}
            </Typography>
        </Stack>);
}