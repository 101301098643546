import { DataTableColumn, optionalTableCell, PagedValuesFilter, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { useAwsCommonEncryptedResourceDefinition, useAwsCommonResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, InlineEntities, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";

export function useAwsAthenaWorkgroupDefinition(definitionData: DefinitionData) {
    const commonResourceDefinition = useAwsCommonResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();
    const commonEncryptedResourceDefinition = useAwsCommonEncryptedResourceDefinition(definitionData);

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.aws.useAwsAthenaWorkgroupDefinition",
            () => ({
                columns: {
                    enforceWorkgroupConfiguration: {
                        false: "Disabled",
                        title: "Override Client-Side Settings",
                        true: "Enabled"
                    },
                    engineVersionName: "Analytics Engine",
                    loggingEnabled: {
                        false: "Disabled",
                        title: "Query Metrics Logging",
                        true: "Enabled"
                    },
                    outputBucket: "Query Result S3 Bucket",
                    state: {
                        false: "Disabled",
                        title: "State",
                        true: "Enabled"
                    }
                }
            }));

    function getEnforceWorkgroupConfigurationTranslation(workgroupModel: Contract.EntityModel) {
        return (workgroupModel.entity as Contract.AwsAthenaWorkgroup).enforceWorkgroupConfiguration
            ? localization.columns.enforceWorkgroupConfiguration.true()
            : localization.columns.enforceWorkgroupConfiguration.false();
    }

    function getLoggingEnabledTranslation(workgroupModel: Contract.EntityModel) {
        return (workgroupModel.entity as Contract.AwsAthenaWorkgroup).loggingEnabled
            ? localization.columns.loggingEnabled.true()
            : localization.columns.loggingEnabled.false();
    }

    function getStateTranslation(workgroupModel: Contract.EntityModel) {
        return (workgroupModel.entity as Contract.AwsAthenaWorkgroup).enabled
            ? localization.columns.state.true()
            : localization.columns.state.false();
    }

    return new EntityTableDefinition(
        [
            commonResourceDefinition.columns.tenantColumn,
            commonResourceDefinition.columns.creationTimeColumn,
            commonResourceDefinition.columns.creatorIdentityCsvColumn,
            commonResourceDefinition.columns.creatorOriginatorEntityCsvColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsAthenaWorkgroupModel) => ({
                            [localization.columns.state.title()]: getStateTranslation(item)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <ValuesFilter
                                placeholder={localization.columns.state.title()}
                                sorted={false}>
                                <ValuesFilterItem
                                    title={localization.columns.state.true()}
                                    value={true}/>
                                <ValuesFilterItem
                                    title={localization.columns.state.false()}
                                    value={false}/>
                            </ValuesFilter>
                    }
                }}
                id={Contract.EntityModelProperty.AwsAthenaWorkgroupEnabled}
                itemProperty={getStateTranslation}
                key={Contract.EntityModelProperty.AwsAthenaWorkgroupEnabled}
                title={localization.columns.state.title()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsAthenaWorkgroupModel) => ({
                            [localization.columns.engineVersionName()]: (item.entity as Contract.AwsAthenaWorkgroup).engineVersionName
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedValuesFilter
                                getValuePage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AwsAthenaWorkgroupEngineVersionName)}
                                placeholder={localization.columns.engineVersionName()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AwsAthenaWorkgroupEngineVersionName}
                key={Contract.EntityModelProperty.AwsAthenaWorkgroupEngineVersionName}
                render={optionalTableCell<Contract.AwsAthenaWorkgroupModel>(item => (item.entity as Contract.AwsAthenaWorkgroup).engineVersionName)}
                title={localization.columns.engineVersionName()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsAthenaWorkgroupModel) => ({
                            [localization.columns.enforceWorkgroupConfiguration.title()]: getEnforceWorkgroupConfigurationTranslation(item)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <ValuesFilter
                                placeholder={localization.columns.enforceWorkgroupConfiguration.title()}
                                sorted={false}>
                                <ValuesFilterItem
                                    title={localization.columns.enforceWorkgroupConfiguration.true()}
                                    value={true}/>
                                <ValuesFilterItem
                                    title={localization.columns.enforceWorkgroupConfiguration.false()}
                                    value={false}/>
                            </ValuesFilter>
                    }
                }}
                id={Contract.EntityModelProperty.AwsAthenaWorkgroupEnforceWorkgroupConfiguration}
                itemProperty={getEnforceWorkgroupConfigurationTranslation}
                key={Contract.EntityModelProperty.AwsAthenaWorkgroupEnforceWorkgroupConfiguration}
                title={localization.columns.enforceWorkgroupConfiguration.title()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsAthenaWorkgroupModel) => ({
                            [localization.columns.loggingEnabled.title()]: getLoggingEnabledTranslation(item)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <ValuesFilter
                                placeholder={localization.columns.loggingEnabled.title()}
                                sorted={false}>
                                <ValuesFilterItem
                                    title={localization.columns.loggingEnabled.true()}
                                    value={true}/>
                                <ValuesFilterItem
                                    title={localization.columns.loggingEnabled.false()}
                                    value={false}/>
                            </ValuesFilter>
                    }
                }}
                id={Contract.EntityModelProperty.AwsAthenaWorkgroupLoggingEnabled}
                itemProperty={getLoggingEnabledTranslation}
                key={Contract.EntityModelProperty.AwsAthenaWorkgroupLoggingEnabled}
                title={localization.columns.loggingEnabled.title()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.AwsAthenaWorkgroupModel>(
                        Contract.TypeNames.AwsS3Bucket,
                        item => [(item as Contract.AwsAthenaWorkgroupModel).outputBucketIdReference],
                        localization.columns.outputBucket())
                }
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AwsAthenaWorkgroupOutputBucket)}
                                placeholder={localization.columns.outputBucket()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AwsAthenaWorkgroupOutputBucket}
                key={Contract.EntityModelProperty.AwsAthenaWorkgroupOutputBucket}
                render={
                    optionalTableCell<Contract.AwsAthenaWorkgroupModel>(
                        item =>
                            _.isNil(item.outputBucketIdReference)
                                ? undefined
                                : <InlineEntities
                                    entityIdsOrModels={item.outputBucketIdReference}
                                    entityTypeName={Contract.TypeNames.AwsS3Bucket}
                                    entityVariant="iconText"
                                    variant="itemPlusItemCount"/>)}
                title={localization.columns.outputBucket()}/>,
            commonEncryptedResourceDefinition.columns.encryptionExistsColumn,
            commonEncryptedResourceDefinition.columns.kmsEncryptionKeyColumn,
            commonResourceDefinition.columns.regionColumn,
            commonResourceDefinition.columns.regionLocationColumn,
            commonResourceDefinition.columns.tagsColumn,
            commonResourceDefinition.columns.attributesColumn,
            commonResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAwsAthenaWorkgroupRequest(
                new Contract.EntityControllerGetEntityModelPageAwsAthenaWorkgroupRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsAthenaWorkgroupEnabled]),
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsAthenaWorkgroupEnforceWorkgroupConfiguration]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsAthenaWorkgroupEngineVersionName]),
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsAthenaWorkgroupLoggingEnabled]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsAthenaWorkgroupOutputBucket])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags])),
                new Contract.EntityControllerGetEntityModelPageAwsResourceRequestEncrypted(
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEncryptedResourceEncryptionExists]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEncryptedResourceKmsEncryptionKeys]))
            ));
}