import { InlineItems, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract } from "../../../../../../../../../../../common";
import { AuditEventDefinitionContext, AuditEventDefinitionDetailItem } from "../../../useDefinition";
import { usePermissionEligibilityAuditEventCommonDetailItems } from "./usePermissionEligibilityAuditEventCommonDetailItems";

export function usePermissionAssignmentEligibilityAuditEventCommonDetailItems(context: AuditEventDefinitionContext) {
    const permissionEligibilityAuditEventCommonDetailItems = usePermissionEligibilityAuditEventCommonDetailItems(context);
    const localization =
        useLocalization(
            "views.customer.auditEvents.profile.hooks.useDefinition.hooks.usePermissionEligibilityAuditEventDefinition.hooks.usePermissionAssignmentEligibilityAuditEventCommonDetailItems",
            () => ({
                permissionRequestScopePaths: {
                    pluralizer: [
                        "1 Scope",
                        "{{count|NumberFormatter.humanize}} Scopes"
                    ],
                    title: "Request Scopes"
                }
            }));

    const permissionEligibilityAuditEventModel = context.auditEventModel as Contract.PermissionEligibilityAuditEventModel;
    const permissionEligibilityAuditEventPermissionAssignmentEligibilityData = permissionEligibilityAuditEventModel.auditEvent.permissionEligibilityData as Contract.PermissionEligibilityAuditEventPermissionAssignmentEligibilityData;
    return _.merge(
        permissionEligibilityAuditEventCommonDetailItems,
        {
            permissionRequestScopePaths:
                new AuditEventDefinitionDetailItem(
                    localization.permissionRequestScopePaths.title(),
                    localization.permissionRequestScopePaths.pluralizer(permissionEligibilityAuditEventPermissionAssignmentEligibilityData.permissionRequestScopePaths.length),
                    <InlineItems
                        items={permissionEligibilityAuditEventPermissionAssignmentEligibilityData.permissionRequestScopePaths}
                        namePluralizer={localization.permissionRequestScopePaths.pluralizer}
                        variant="itemPlusItemCount"/>)
        });
}