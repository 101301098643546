﻿import { Action0, DataTableFilterExternalOptions, DataTableSort, DataTableSortDirection, DataTableSortType, InfiniteScroll, InfiniteScrollActions, InfiniteScrollFetchDataResult, Optional, Sx, useActions } from "@infrastructure";
import { Box, Stack, SxProps } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { ReactNode, Ref, useCallback, useMemo, useRef, useState } from "react";
import { useTheme } from "../..";
import { Item, RowHeader } from "./components";

export type GroupedTableActions = {
    reloadGroups: () => Promise<void>;
    reset: Action0;
    setItemExpanded: (itemId: string, expanded: boolean) => void;
};

type GroupedTableProps<TItem> = {
    actionsRef?: Ref<Optional<GroupedTableActions>>;
    children: (item: TItem) => ReactNode;
    columns: GroupedTableColumn[];
    fetchItems: () => Promise<TItem[]>;
    filterExternalOptions?: DataTableFilterExternalOptions;
    getItemId: (item: TItem) => string;
    infiniteScrollOptions: GroupedTableInfiniteScrollOptions;
    sort?: DataTableSort;
    sx?: SxProps;
};

type GroupedTableInfiniteScrollOptions = {
    emptyText: string;
    marginBottom?: string;
};

export function GroupedTable<TItem>({ actionsRef, children, columns, fetchItems, filterExternalOptions, getItemId, infiniteScrollOptions, sort: initialSort, sx }: GroupedTableProps<TItem>) {
    const itemIdToSetExpandedRef = useRef<Dictionary<(expanded: boolean) => void>>({});
    const [loadedItemCount, setLoadedItemCount] = useState(0);
    const [sort, setSort] = useState<DataTableSort | undefined>(initialSort);
    const [items, setItems] = useState<TItem[]>();

    const columnIdToColumnMap =
        useMemo(
            () =>
                _.keyBy(
                    columns,
                    column => column.id),
            [columns]);

    const orderedItems =
        useMemo(
            () => {
                if (_.isNil(sort)) {
                    return items;
                }

                const iterates = columnIdToColumnMap[sort.columnId].sortOptions!.getIterates();
                return _.orderBy(
                    items,
                    iterates,
                    Array(iterates.length).
                        fill(
                            sort!.direction === DataTableSortDirection.Ascending
                                ? "asc"
                                : "desc"));
            },
            [sort, items]);


    const itemElements =
        useMemo(
            () =>
                _(orderedItems).
                    take(loadedItemCount).
                    map(
                        item =>
                            <Item
                                columns={columns}
                                item={item}
                                key={getItemId(item)}
                                onLoaded={setExpanded => itemIdToSetExpandedRef.current[getItemId(item)] = setExpanded}>
                                {children}
                            </Item>).
                    value(),
            [children, columns, getItemId, loadedItemCount, orderedItems]);

    useActions<GroupedTableActions>(
        actionsRef,
        {
            async reloadGroups() {
                setItems(await fetchItems());
            },
            reset() {
                setItems(undefined);
                setLoadedItemCount(0);
                infiniteScrollActionsRef.current!.reset();
            },
            setItemExpanded(itemId, expanded) {
                itemIdToSetExpandedRef.current[itemId](expanded);
            }
        });

    const fetchData =
        useCallback(
            async () => {
                let currentItems = items;
                if (_.isNil(currentItems)) {
                    currentItems = await fetchItems();
                    setItems(currentItems);
                }
                const newLoadedItemCount =
                    Math.min(
                        loadedItemCount + 30,
                        currentItems.length);

                return new InfiniteScrollFetchDataResult(
                    currentItems.length > 0,
                    currentItems.length === newLoadedItemCount,
                    () => setLoadedItemCount(newLoadedItemCount)
                );
            },
            [fetchItems, items, loadedItemCount]);

    const infiniteScrollActionsRef = useRef<InfiniteScrollActions>();

    const theme = useTheme();
    return (
        <InfiniteScroll
            actionsRef={infiniteScrollActionsRef}
            emptyTextOptions={{ text: infiniteScrollOptions.emptyText }}
            fetchData={fetchData}
            marginBottom={infiniteScrollOptions.marginBottom}
            waitForReset={true}>
            <Box
                sx={
                    Sx.combine(
                        { minWidth: "fit-content" },
                        sx)}>
                <Box
                    sx={{
                        background: theme.palette.background.paper,
                        borderBottom: theme.border.primary,
                        padding: theme.spacing(2, 2, 2, 5.25),
                        position: "sticky",
                        top: 0,
                        zIndex: 5
                    }}>
                    <RowHeader
                        columns={columns}
                        filterExternalOptions={filterExternalOptions}
                        sortOptions={{
                            onClick: setSort,
                            sort
                        }}/>
                </Box>
                <Stack
                    sx={{
                        marginTop: theme.spacing(1),
                        padding: theme.spacing(0, 1)
                    }}>
                    {itemElements}
                </Stack>
            </Box>
        </InfiniteScroll>
    );
}

export interface GroupedTableColumn<TItem = any> {
    element: React.FunctionComponent<GroupedTableColumnRenderProps<TItem>>;
    filterExternalId?: string;
    id: string;
    minWidth: number;
    sortOptions?: GroupedTableColumnSortOptions<TItem>;
    title: string;
    width: number;
}

type GroupedTableColumnSortOptions<TItem = any, TValue = any> = {
    getIterates: () => ((item: TItem) => TValue)[];
    type: DataTableSortType;
};

export type GroupedTableColumnRenderProps<TItem> = {
    item: TItem;
};