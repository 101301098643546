﻿import { StringHelper, useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import React from "react";
import { RadioGroup } from "../../../../../../../../../common";
import { useAddOrEditContext, useSetAddOrEditContext } from "../../../AddOrEdit";

export function Reason() {
    const { permissionEligibilityData: { reasonRequired }, upsertPermissionEligibilityExecuting } = useAddOrEditContext();
    const setAddOrEditContext = useSetAddOrEditContext();

    const localization =
        useLocalization(
            "views.user.permissionEligibilities.addOrEdit.hooks.useDefinition.reason",
            () => ({
                notRequired: "Not required",
                required: "Required",
                title: "Justification"
            }));

    return (
        <Stack spacing={1.5}>
            <Typography variant="h4">
                {localization.title()}
            </Typography>
            <RadioGroup
                items={[
                    {
                        disabled: upsertPermissionEligibilityExecuting,
                        label: localization.required(),
                        value: true
                    },
                    {
                        disabled: upsertPermissionEligibilityExecuting,
                        label: localization.notRequired(),
                        value: false
                    }
                ]}
                selectedValue={reasonRequired}
                sx={{ marginBottom: 0 }}
                onChange={
                    value =>
                        setAddOrEditContext(
                            addOrEditContext => {
                                addOrEditContext.permissionEligibilityData.reasonRequired = StringHelper.isTrue(value);
                                return { ...addOrEditContext };
                            })}/>
        </Stack>);
}