import { useLocalization } from "@infrastructure";
import React from "react";
import { useAwsCommonEc2ResourceInfoElements, useAwsDefaultResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { useCustomEntityPropertyInfoItemElements } from "..";
import { Contract } from "../../../../../../../../../../common";
import { EntitiesInfoItem, Info } from "../../../../components";

export function useAwsEc2InternetGatewayDefinition(resourceModel: Contract.AwsResourceModel, options?: DefinitionOptions) {
    const commonEc2ResourceInfoItemElements = useAwsCommonEc2ResourceInfoElements(resourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useAwsDefaultResourceInfoItemElements(resourceModel);
    const internetGateway = resourceModel.entity as Contract.AwsEc2InternetGateway;

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsEc2InternetGatewayDefinition",
            () => ({
                info: {
                    items: {
                        vpcId: "VPC"
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    ...commonEc2ResourceInfoItemElements,
                    <EntitiesInfoItem
                        entityIdsOrModels={internetGateway.vpcId}
                        entityTypeName={Contract.TypeNames.AwsEc2Vpc}
                        key="vpcId"
                        location="all"
                        title={localization.info.items.vpcId()}/>
                ]}
                options={options?.infoOptions}/>
    });
}