import { UnexpectedError } from "@infrastructure";
import { ReactNode, useMemo } from "react";
import { Contract, TypeHelper } from "../../../..";
import { RiskResolutionAutomationContext } from "../../utilities";
import { useAwsDefinition, useAzureDefinition, useGcpDefinition } from "./hooks";

export function useDefinition(
    changeModels: Contract.ChangeModel[],
    riskedEntityModel: Contract.EntityModel): Definition {
    const useDefinition =
        useMemo(
            () => {
                if (TypeHelper.extendOrImplement(riskedEntityModel.entity.typeName, Contract.TypeNames.AwsEntity)) {
                    return useAwsDefinition;
                } else if (TypeHelper.extendOrImplement(riskedEntityModel.entity.typeName, Contract.TypeNames.AadEntity) ||
                    TypeHelper.extendOrImplement(riskedEntityModel.entity.typeName, Contract.TypeNames.AzureEntity)) {
                    return useAzureDefinition;
                } else if (TypeHelper.extendOrImplement(riskedEntityModel.entity.typeName, Contract.TypeNames.IGciPrincipal)) {
                    return useGcpDefinition;
                } else {
                    throw new UnexpectedError("riskedEntityTypeName", riskedEntityModel.entity.typeName);
                }
            },
            []);

    return useDefinition(changeModels, riskedEntityModel);
}

export type Definition = {
    createContext: () => RiskResolutionAutomationContext;
    wizardItems: ReactNode[];
};