import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, DeferredFilter, Optional, optionalTableCell, PagedValuesFilter, TextValuesFilter, TimeFormatter, TimeRangeFilter, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { useAwsIamIdentityDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";

export function useAwsIamUserDefinition(definitionData: DefinitionData) {
    const entitiesExportOptions = useEntitiesExportOptions();
    const userModelFiltersPromise = definitionData.entityModelFiltersPromise as Promise<Contract.AwsIamUserModelFilters>;
    const identityDefinition = useAwsIamIdentityDefinition(definitionData);

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.aws.useAwsIamUserDefinition",
            () => ({
                columns: {
                    accessKey: {
                        creationTime: {
                            filter: "Access Key Creation Time",
                            title: "Access Key {{index}} Creation Time"
                        },
                        enabled: {
                            false: "Disabled",
                            filter: "Access Key Status",
                            title: "Access Key {{index}} Status",
                            true: "Enabled"
                        },
                        neverUsed: "Never Used",
                        uniqueId: {
                            filter: "Access Key ID",
                            title: "Access Key {{index}} ID"
                        },
                        usageRegionSystemName: {
                            filter: "Access Key Last Used Region",
                            title: "Access Key {{index}} Last Used Region"
                        },
                        usageService: {
                            filter: "Access Key Last Used Service",
                            title: "Access Key {{index}} Last Used Service"
                        },
                        usageTime: {
                            filter: "Access Key Last Used",
                            title: "Access Key {{index}} Last Used"
                        }
                    },
                    activityTime: {
                        empty: "Never",
                        title: "Activity Time"
                    },
                    groupIds: "Group Memberships",
                    groupPolicies: "Group Policies",
                    mfaEnabled: {
                        disabled: "Not enabled",
                        enabled: "Enabled",
                        title: "MFA"
                    },
                    passwordEnabled: {
                        disabled: "Not enabled",
                        enabled: "Enabled",
                        title: "Console Password"
                    }
                }
            }));

    function getAccessKeyCreationTime(accessKey: Optional<Contract.AwsIamUserAccessKey>) {
        return _.isNil(accessKey)
            ? undefined
            : TimeFormatter.shortDate(accessKey.creationTime);
    }

    function getAccessKeyCreationTimeIso8601(accessKey: Optional<Contract.AwsIamUserAccessKey>) {
        return _.isNil(accessKey)
            ? undefined
            : TimeFormatter.shortDate(accessKey.creationTime);
    }

    function getAccessKeyEnabled(accessKey: Optional<Contract.AwsIamUserAccessKey>) {
        if (_.isNil(accessKey)) {
            return undefined;
        }

        return accessKey.enabled
            ? localization.columns.accessKey.enabled.true()
            : localization.columns.accessKey.enabled.false();
    }

    function getAccessKeyUsageTime(accessKey: Optional<Contract.AwsIamUserAccessKey>) {
        if (_.isNil(accessKey)) {
            return undefined;
        }

        return _.isNil(accessKey.usageTime)
            ? localization.columns.accessKey.neverUsed()
            : TimeFormatter.shortDate(accessKey.usageTime);
    }

    function getAccessKeyUsageTimeIso8601(accessKey: Optional<Contract.AwsIamUserAccessKey>) {
        if (_.isNil(accessKey)) {
            return undefined;
        }

        return _.isNil(accessKey.usageTime)
            ? localization.columns.accessKey.neverUsed()
            : TimeFormatter.iso8601String(accessKey.usageTime);
    }

    function getActivityTimeTranslation(entityModel: Contract.EntityModel) {
        const activityTime = (entityModel.entity as Contract.AwsIamUser).activityTime;
        return _.isNil(activityTime)
            ? localization.columns.activityTime.empty()
            : TimeFormatter.humanizePastDuration(activityTime);
    }

    function getMfaEnabledTranslation(entityModel: Contract.EntityModel) {
        return (entityModel.entity as Contract.AwsIamUser).mfaEnabled
            ? localization.columns.mfaEnabled.enabled()
            : localization.columns.mfaEnabled.disabled();
    }

    function getPasswordEnabledTranslation(entityModel: Contract.EntityModel) {
        return (entityModel.entity as Contract.AwsIamUser).password?.enabled
            ? localization.columns.passwordEnabled.enabled()
            : localization.columns.passwordEnabled.disabled();
    }

    return new EntityTableDefinition(
        [
            identityDefinition.columns.tenantColumn,
            identityDefinition.columns.creationTimeColumn,
            identityDefinition.columns.creatorIdentityCsvColumn,
            identityDefinition.columns.creatorOriginatorEntityCsvColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsIamUserModel) => ({
                            [localization.columns.passwordEnabled.title()]: getPasswordEnabledTranslation(item)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <ValuesFilter
                                placeholder={localization.columns.passwordEnabled.title()}
                                sorted={false}>
                                <ValuesFilterItem
                                    title={localization.columns.passwordEnabled.enabled()}
                                    value={true}/>
                                <ValuesFilterItem
                                    title={localization.columns.passwordEnabled.disabled()}
                                    value={false}/>
                            </ValuesFilter>
                    }
                }}
                id={Contract.EntityModelProperty.AwsIamUserPasswordEnabled}
                itemProperty={getPasswordEnabledTranslation}
                key={Contract.EntityModelProperty.AwsIamUserPasswordEnabled}
                title={localization.columns.passwordEnabled.title()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsIamUserModel) => ({
                            [localization.columns.mfaEnabled.title()]: getMfaEnabledTranslation(item)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <ValuesFilter
                                placeholder={localization.columns.mfaEnabled.title()}
                                sorted={false}>
                                <ValuesFilterItem
                                    title={localization.columns.mfaEnabled.enabled()}
                                    value={true}/>
                                <ValuesFilterItem
                                    title={localization.columns.mfaEnabled.disabled()}
                                    value={false}/>
                            </ValuesFilter>
                    }
                }}
                id={Contract.EntityModelProperty.AwsIamUserMfaEnabled}
                itemProperty={getMfaEnabledTranslation}
                key={Contract.EntityModelProperty.AwsIamUserMfaEnabled}
                title={localization.columns.mfaEnabled.title()}/>,
            identityDefinition.columns.policies,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.AwsIamUserModel>(
                        Contract.TypeNames.AwsIamPrincipalPolicy,
                        item => item.groupPolicyIds,
                        localization.columns.groupPolicies())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AwsIamUserGroupPolicies)}
                                placeholder={localization.columns.groupPolicies()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AwsIamUserGroupPolicies}
                key={Contract.EntityModelProperty.AwsIamUserGroupPolicies}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AwsIamUserModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.groupPolicyIds}
                            entityTypeName={Contract.TypeNames.AwsIamPrincipalPolicy}
                            entityVariant="iconText"
                            inlineEntitiesVariant="itemPlusItemCount"/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.groupPolicies()}/>,
            identityDefinition.columns.boundary,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.AwsIamUserModel>(
                        Contract.TypeNames.AwsIamGroup,
                        item => item.groupIds,
                        localization.columns.groupIds())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AwsIamUserGroups)}
                                placeholder={localization.columns.groupIds()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AwsIamUserGroups}
                key={Contract.EntityModelProperty.AwsIamUserGroups}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AwsIamUserModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.groupIds}
                            entityTypeName={Contract.TypeNames.AwsIamGroup}
                            entityVariant="iconText"/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.groupIds()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsIamUserModel) => ({
                            [localization.columns.activityTime.title()]: getActivityTimeTranslation(item)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <DeferredFilter
                                promiseOrGetPromise={userModelFiltersPromise}
                                title={localization.columns.activityTime.title()}>
                                {filters =>
                                    <TimeRangeFilter
                                        emptyValue={filters.activityTimeRange.emptyValue}
                                        placeholder={localization.columns.activityTime.title()}
                                        timeRange={TimeRangeHelper.getTimeRangeFilterRange(filters.activityTimeRange.timeRange)}/>}
                            </DeferredFilter>
                    }
                }}
                id={Contract.EntityModelProperty.AwsIamIdentityActivityTime}
                itemProperty={getActivityTimeTranslation}
                key={Contract.EntityModelProperty.AwsIamIdentityActivityTime}
                sortOptions={{ type: DataTableSortType.Date }}
                title={localization.columns.activityTime.title()}/>,
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedValuesFilter
                                getValuePage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AwsIamUserAccessKeyUniqueId)}
                                placeholder={localization.columns.accessKey.uniqueId.filter()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AwsIamUserAccessKeyUniqueId}
                key={Contract.EntityModelProperty.AwsIamUserAccessKeyUniqueId}
                title={localization.columns.accessKey.uniqueId.filter()}/>,
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        element:
                            <ValuesFilter
                                placeholder={localization.columns.accessKey.enabled.filter()}
                                sorted={false}>
                                <ValuesFilterItem
                                    title={localization.columns.accessKey.enabled.true()}
                                    value={true}/>
                                <ValuesFilterItem
                                    title={localization.columns.accessKey.enabled.false()}
                                    value={false}/>
                            </ValuesFilter>
                    }
                }}
                id={Contract.EntityModelProperty.AwsIamUserAccessKeyEnabled}
                key={Contract.EntityModelProperty.AwsIamUserAccessKeyEnabled}
                title={localization.columns.accessKey.enabled.filter()}/>,
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        element:
                            <DeferredFilter
                                promiseOrGetPromise={userModelFiltersPromise}
                                title={localization.columns.accessKey.creationTime.filter()}>
                                {filters =>
                                    <TimeRangeFilter
                                        emptyValue={filters.accessKeyCreationTimeRange.emptyValue}
                                        placeholder={localization.columns.accessKey.creationTime.filter()}
                                        timeRange={TimeRangeHelper.getTimeRangeFilterRange(filters.accessKeyCreationTimeRange.timeRange)}/>}
                            </DeferredFilter>
                    }
                }}
                id={Contract.EntityModelProperty.AwsIamUserAccessKeyCreationTime}
                key={Contract.EntityModelProperty.AwsIamUserAccessKeyCreationTime}
                title={localization.columns.accessKey.creationTime.filter()}/>,
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        element:
                            <DeferredFilter
                                promiseOrGetPromise={userModelFiltersPromise}
                                title={localization.columns.accessKey.usageTime.filter()}>
                                {filters =>
                                    <TimeRangeFilter
                                        emptyValue={filters.accessKeyUsageTimeRange.emptyValue}
                                        placeholder={localization.columns.accessKey.usageTime.filter()}
                                        timeRange={TimeRangeHelper.getTimeRangeFilterRange(filters.accessKeyUsageTimeRange.timeRange)}/>}
                            </DeferredFilter>
                    }
                }}
                id={Contract.EntityModelProperty.AwsIamUserAccessKeyUsageTime}
                key={Contract.EntityModelProperty.AwsIamUserAccessKeyUsageTime}
                title={localization.columns.accessKey.usageTime.filter()}/>,
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AwsIamUserAccessKeyUsageService)}
                                placeholder={localization.columns.accessKey.usageService.filter()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AwsIamUserAccessKeyUsageService}
                key={Contract.EntityModelProperty.AwsIamUserAccessKeyUsageService}
                title={localization.columns.accessKey.usageService.filter()}/>,
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        element:
                            <DeferredFilter
                                promiseOrGetPromise={userModelFiltersPromise}
                                title={localization.columns.accessKey.usageRegionSystemName.filter()}>
                                {filters =>
                                    <TextValuesFilter
                                        emptyValue={filters.accessKeyUsageRegionSystemNameItems.emptyValue}
                                        placeholder={localization.columns.accessKey.usageRegionSystemName.filter()}
                                        values={filters.accessKeyUsageRegionSystemNameItems.items}/>}
                            </DeferredFilter>
                    }
                }}
                id={Contract.EntityModelProperty.AwsIamUserAccessKeyUsageRegionSystemName}
                key={Contract.EntityModelProperty.AwsIamUserAccessKeyUsageRegionSystemName}
                title={localization.columns.accessKey.usageRegionSystemName.filter()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsIamUserModel) => ({
                            [localization.columns.accessKey.uniqueId.title({ index: 1 })]: (item.entity as Contract.AwsIamUser).accessKey1?.uniqueId ?? ""
                        })
                }}
                id={Contract.EntityModelProperty.AwsIamUserAccessKey1UniqueId}
                key={Contract.EntityModelProperty.AwsIamUserAccessKey1UniqueId}
                render={optionalTableCell<Contract.AwsIamUserModel>(item => (item.entity as Contract.AwsIamUser).accessKey1?.uniqueId)}
                selectorOptions={{ default: false }}
                title={localization.columns.accessKey.uniqueId.title({ index: 1 })}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsIamUserModel) => ({
                            [localization.columns.accessKey.enabled.title({ index: 1 })]: getAccessKeyEnabled((item.entity as Contract.AwsIamUser).accessKey1) ?? ""
                        })
                }}
                id={Contract.EntityModelProperty.AwsIamUserAccessKey1Enabled}
                key={Contract.EntityModelProperty.AwsIamUserAccessKey1Enabled}
                render={optionalTableCell<Contract.AwsIamUserModel>(item => getAccessKeyEnabled((item.entity as Contract.AwsIamUser).accessKey1))}
                selectorOptions={{ default: false }}
                title={localization.columns.accessKey.enabled.title({ index: 1 })}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsIamUserModel) => ({
                            [localization.columns.accessKey.creationTime.title({ index: 1 })]: getAccessKeyCreationTimeIso8601((item.entity as Contract.AwsIamUser).accessKey1) ?? ""
                        })
                }}
                id={Contract.EntityModelProperty.AwsIamUserAccessKey1CreationTime}
                key={Contract.EntityModelProperty.AwsIamUserAccessKey1CreationTime}
                render={optionalTableCell<Contract.AwsIamUserModel>(item => getAccessKeyCreationTime((item.entity as Contract.AwsIamUser).accessKey1))}
                selectorOptions={{ default: false }}
                title={localization.columns.accessKey.creationTime.title({ index: 1 })}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsIamUserModel) => ({
                            [localization.columns.accessKey.usageTime.title({ index: 1 })]: getAccessKeyUsageTimeIso8601((item.entity as Contract.AwsIamUser).accessKey1) ?? ""
                        })
                }}
                id={Contract.EntityModelProperty.AwsIamUserAccessKey1UsageTime}
                key={Contract.EntityModelProperty.AwsIamUserAccessKey1UsageTime}
                render={optionalTableCell<Contract.AwsIamUserModel>(item => getAccessKeyUsageTime((item.entity as Contract.AwsIamUser).accessKey1))}
                selectorOptions={{ default: false }}
                title={localization.columns.accessKey.usageTime.title({ index: 1 })}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.AwsIamUserModel>(
                        Contract.TypeNames.AwsService,
                        item => [(item.entity as Contract.AwsIamUser).accessKey1?.usageServiceIdReference],
                        localization.columns.accessKey.usageService.title({ index: 1 }))}
                id={Contract.EntityModelProperty.AwsIamUserAccessKey1UsageService}
                key={Contract.EntityModelProperty.AwsIamUserAccessKey1UsageService}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AwsIamUserModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={(item.entity as Contract.AwsIamUser).accessKey1?.usageServiceIdReference}
                            entityTypeName={Contract.TypeNames.AwsService}/>}
                selectorOptions={{ default: false }}
                title={localization.columns.accessKey.usageService.title({ index: 1 })}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsIamUserModel) => ({
                            [localization.columns.accessKey.usageRegionSystemName.title({ index: 1 })]: (item.entity as Contract.AwsIamUser).accessKey1?.usageRegionSystemName ?? ""
                        })
                }}
                id={Contract.EntityModelProperty.AwsIamUserAccessKey1UsageRegionSystemName}
                key={Contract.EntityModelProperty.AwsIamUserAccessKey1UsageRegionSystemName}
                render={optionalTableCell<Contract.AwsIamUserModel>(item => (item.entity as Contract.AwsIamUser).accessKey1?.usageRegionSystemName)}
                selectorOptions={{ default: false }}
                title={localization.columns.accessKey.usageRegionSystemName.title({ index: 1 })}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsIamUserModel) => ({
                            [localization.columns.accessKey.uniqueId.title({ index: 2 })]: (item.entity as Contract.AwsIamUser).accessKey2?.uniqueId ?? ""
                        })
                }}
                id={Contract.EntityModelProperty.AwsIamUserAccessKey2UniqueId}
                key={Contract.EntityModelProperty.AwsIamUserAccessKey2UniqueId}
                render={optionalTableCell<Contract.AwsIamUserModel>(item => (item.entity as Contract.AwsIamUser).accessKey2?.uniqueId)}
                selectorOptions={{ default: false }}
                title={localization.columns.accessKey.uniqueId.title({ index: 2 })}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsIamUserModel) => ({
                            [localization.columns.accessKey.enabled.title({ index: 2 })]: getAccessKeyEnabled((item.entity as Contract.AwsIamUser).accessKey2) ?? ""
                        })
                }}
                id={Contract.EntityModelProperty.AwsIamUserAccessKey2Enabled}
                key={Contract.EntityModelProperty.AwsIamUserAccessKey2Enabled}
                render={optionalTableCell<Contract.AwsIamUserModel>(item => getAccessKeyEnabled((item.entity as Contract.AwsIamUser).accessKey2))}
                selectorOptions={{ default: false }}
                title={localization.columns.accessKey.enabled.title({ index: 2 })}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsIamUserModel) => ({
                            [localization.columns.accessKey.creationTime.title({ index: 2 })]: getAccessKeyCreationTimeIso8601((item.entity as Contract.AwsIamUser).accessKey2) ?? ""
                        })
                }}
                id={Contract.EntityModelProperty.AwsIamUserAccessKey2CreationTime}
                key={Contract.EntityModelProperty.AwsIamUserAccessKey2CreationTime}
                render={optionalTableCell<Contract.AwsIamUserModel>(item => getAccessKeyCreationTime((item.entity as Contract.AwsIamUser).accessKey2))}
                selectorOptions={{ default: false }}
                title={localization.columns.accessKey.creationTime.title({ index: 2 })}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsIamUserModel) => ({
                            [localization.columns.accessKey.usageTime.title({ index: 2 })]: getAccessKeyUsageTimeIso8601((item.entity as Contract.AwsIamUser).accessKey2) ?? ""
                        })
                }}
                id={Contract.EntityModelProperty.AwsIamUserAccessKey2UsageTime}
                key={Contract.EntityModelProperty.AwsIamUserAccessKey2UsageTime}
                render={optionalTableCell<Contract.AwsIamUserModel>(item => getAccessKeyUsageTime((item.entity as Contract.AwsIamUser).accessKey2))}
                selectorOptions={{ default: false }}
                title={localization.columns.accessKey.usageTime.title({ index: 2 })}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.AwsIamUserModel>(
                        Contract.TypeNames.AwsService,
                        item => [(item.entity as Contract.AwsIamUser).accessKey2?.usageServiceIdReference],
                        localization.columns.accessKey.usageService.title({ index: 2 }))}
                id={Contract.EntityModelProperty.AwsIamUserAccessKey2UsageService}
                key={Contract.EntityModelProperty.AwsIamUserAccessKey2UsageService}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AwsIamUserModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={(item.entity as Contract.AwsIamUser).accessKey2?.usageServiceIdReference}
                            entityTypeName={Contract.TypeNames.AwsService}/>}
                selectorOptions={{ default: false }}
                title={localization.columns.accessKey.usageService.title({ index: 2 })}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsIamUserModel) => ({
                            [localization.columns.accessKey.usageRegionSystemName.title({ index: 2 })]: (item.entity as Contract.AwsIamUser).accessKey2?.usageRegionSystemName ?? ""
                        })
                }}
                id={Contract.EntityModelProperty.AwsIamUserAccessKey2UsageRegionSystemName}
                key={Contract.EntityModelProperty.AwsIamUserAccessKey2UsageRegionSystemName}
                render={optionalTableCell<Contract.AwsIamUserModel>(item => (item.entity as Contract.AwsIamUser).accessKey2?.usageRegionSystemName)}
                selectorOptions={{ default: false }}
                title={localization.columns.accessKey.usageRegionSystemName.title({ index: 2 })}/>,
            identityDefinition.columns.tagsColumn,
            identityDefinition.columns.attributesColumn,
            identityDefinition.columns.openRiskedEntityRisksCsvColumn,
            identityDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            identityDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAwsIamUserRequest(
                new Contract.EntityControllerGetEntityModelPageAwsIamUserRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsIamPrincipalPolicies]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.AwsIamIdentityActivityTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsIamIdentityPermissionBoundary]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.AwsIamUserAccessKeyCreationTime]),
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsIamUserAccessKeyEnabled]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsIamUserAccessKeyUniqueId]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsIamUserAccessKeyUsageRegionSystemName]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsIamUserAccessKeyUsageService]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.AwsIamUserAccessKeyUsageTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsIamUserGroups]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsIamUserGroupPolicies]),
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsIamUserMfaEnabled]),
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsIamUserPasswordEnabled])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags]))));
}