import { TextViewer, useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../../..";
import { Contract, Entity, entityModelStore, InlineKubernetesContainerNames, useEntityTypeNameTranslator } from "../../../../../../../../../../../../../common";
import { RiskDefinitionContextItem, RiskDefinitionSection } from "../../../../../../../utilities";
import { useGetKubernetesResourceRiskContext } from "../contexts";

export function useKubernetesWorkloadResourceEnvironmentVariableSecretReferenceRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.KubernetesWorkloadResourceEnvironmentVariableSecretReferenceRisk;
    const workloadResourceModel = entityModelStore.useGet(risk.entityId);

    const getKubernetesResourceRiskContext = useGetKubernetesResourceRiskContext();

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.kubernetes.hooks.resources.workloadResources.useKubernetesWorkloadResourceEnvironmentVariableSecretReferenceRiskDefinition",
            () => ({
                contextItems: {
                    containerNames: "The {{translatedEntityTypeName}} has {{containerNames}} with environment variables that were created from a secret"
                },
                description: "{{workloadResource}} has an environment variable that was created from a secret",
                resolutionSection: {
                    step1: "Consider rewriting application code to read secrets from mounted secret files, rather than from environment variables",
                    step2: "Recreate the {{workloadResource}} without using environment variables that were created from secrets"
                },
                yaml: "YAML"
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            workloadResource:
                <Entity
                    entityIdOrModel={workloadResourceModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            localization.resolutionSection.step1(),
            localization.resolutionSection.step2({
                workloadResource:
                    <Entity
                        entityIdOrModel={workloadResourceModel}
                        variant="typeText"/>
            })
        ],
        riskModel,
        () => {
            const workloadResourceRiskContext = getKubernetesResourceRiskContext(workloadResourceModel);
            return [
                workloadResourceRiskContext.generalInformation,
                workloadResourceRiskContext.relatedResources,
                new RiskDefinitionContextItem(
                    localization.contextItems.containerNames({
                        containerNames: <InlineKubernetesContainerNames containerNames={risk.containerNames}/>,
                        translatedEntityTypeName:
                            entityTypeNameTranslator(
                                workloadResourceModel.entity.typeName,
                                {
                                    includeServiceName: false,
                                    variant: "text"
                                })
                    })),
                workloadResourceRiskContext.sensitive,
                workloadResourceRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        },
        {
            sections: [
                new RiskDefinitionSection(
                    <TextViewer
                        format="yaml"
                        highlightedLines={risk.yaml.highlightedLines}
                        startLine={risk.yaml.startLine}
                        text={risk.yaml.text}/>,
                    localization.yaml())
            ]
        });
}