import { Optional, PagedDropdownPage } from "@infrastructure";
import { Typography } from "@mui/material";
import React from "react";
import { PagedSelector } from ".";
import { Contract, EntityController } from "..";

type PagedResourceTagKeysMultiSelectProps = {
    disabled?: boolean;
    multiSelect: boolean;
    onSelectedResourceTagKeyChanged: (tagKeys: string[]) => void;
    placeholder: string;
    readOnly?: boolean;
    scopeId: string;
    selectedResourceTagKeys: string[];
    tenantTypes?: Contract.TenantType[];
};

export function PagedResourceTagKeySelector({ disabled = false, multiSelect, onSelectedResourceTagKeyChanged, placeholder, readOnly = false, scopeId, selectedResourceTagKeys, tenantTypes }: PagedResourceTagKeysMultiSelectProps) {
    return (
        <PagedSelector
            disabled={disabled}
            fieldOptions={
                {
                    fieldVariant: "underline",
                    ...(multiSelect
                        ? { dense: true }
                        : undefined)
                }}
            getItemPage={
                async (searchText: Optional<string>, skip: number, data?: number) => {
                    const limit = 50;
                    const { entityModelFilterItemPage } =
                        await EntityController.getEntityModelFilterItemPage(
                            new Contract.EntityControllerGetEntityModelFilterItemPageRequest(
                                undefined,
                                limit,
                                Contract.EntityModelProperty.ResourceTagKeys,
                                scopeId,
                                searchText,
                                skip,
                                tenantTypes)) as Contract.EntityControllerGetEntityModelFilterItemPageResponse<string>;
                    const count = entityModelFilterItemPage?.count ?? data!;
                    return new PagedDropdownPage(
                        skip + limit < count,
                        entityModelFilterItemPage.items,
                        () => count);
                }}
            multiSelect={multiSelect}
            placeholder={placeholder}
            readOnly={readOnly}
            selectedItems={selectedResourceTagKeys}
            onSelectedItemsChanged={onSelectedResourceTagKeyChanged}>
            {(resourceTagKey: string) =>
                <Typography noWrap={true}>
                    {resourceTagKey}
                </Typography>}
        </PagedSelector>);
}