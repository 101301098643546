import { getHorizontalLinkedNodeContentAnchorPoint, getHorizontalLinkedNodeContentSize, HorizontalLinkedNodeContent, Optional } from "@infrastructure";
import _ from "lodash";
import React, { MutableRefObject } from "react";
import { Contract, getEntityNodeContentAnchorPoint, getEntityNodeContentSize } from "../../../../../../..";
import { AccessDefinitionToolbarActions } from "../../..";
import { AwsIamPrincipalPolicyIcon } from "../../../../../../../../tenants";
import { PermitterDefinitionNodeContent } from "../../../components";
import { ResourcePermitterNodeContent } from "./ResourcePermitterNodeContent";

type PermissionPathNodeContentProps = {
    permissionPath: Contract.AwsAccessGraphPermissionPath;
    toolbarActionsRef: MutableRefObject<Optional<AccessDefinitionToolbarActions>>;
};

export function PermissionPathNodeContent({ permissionPath, toolbarActionsRef }: PermissionPathNodeContentProps) {
    if (!_.isNil(permissionPath.identifier.principalPolicyId) && !_.isNil(permissionPath.identifier.resourcePermitterType)) {
        return (
            <HorizontalLinkedNodeContent
                childrenLeftAnchorPoint={getEntityNodeContentAnchorPoint("left", "bottom", "small")}
                childrenRightAnchorPoint={getEntityNodeContentAnchorPoint("right", "bottom", "small")}
                childrenSize={getEntityNodeContentSize("bottom", "small")}>
                <PermitterDefinitionNodeContent
                    icon={<AwsIamPrincipalPolicyIcon/>}
                    permitterEntityId={permissionPath.identifier.principalPolicyId}
                    toolbarActionsRef={toolbarActionsRef}
                    variant="small"
                    onViewText={entityModel => (entityModel.entity as Contract.AwsIamPrincipalPolicy).document.raw}/>
                <ResourcePermitterNodeContent
                    entityTypeName={permissionPath.entityTypeName}
                    resourcePermitterType={permissionPath.identifier.resourcePermitterType}
                    variant="small"/>
            </HorizontalLinkedNodeContent>);
    } else if (!_.isNil(permissionPath.identifier.principalPolicyId)) {
        return (
            <PermitterDefinitionNodeContent
                icon={<AwsIamPrincipalPolicyIcon/>}
                permitterEntityId={permissionPath.identifier.principalPolicyId}
                toolbarActionsRef={toolbarActionsRef}
                variant="large"
                onViewText={entityModel => (entityModel.entity as Contract.AwsIamPrincipalPolicy).document.raw}/>);
    } else {
        return (
            <ResourcePermitterNodeContent
                entityTypeName={permissionPath.entityTypeName}
                resourcePermitterType={permissionPath.identifier.resourcePermitterType!}
                variant="large"/>);
    }
}

export const getPermissionPathNodeContentAnchorPoint =
    (anchorPlacement: "left" | "right", permissionPath: Contract.AwsAccessGraphPermissionPath) =>
        _.isNil(permissionPath.identifier.principalPolicyId) || _.isNil(permissionPath.identifier.resourcePermitterType)
            ? getEntityNodeContentAnchorPoint(
                anchorPlacement,
                "bottom",
                "large")
            : getHorizontalLinkedNodeContentAnchorPoint(
                anchorPlacement,
                2,
                getEntityNodeContentAnchorPoint("left", "bottom", "small"),
                getEntityNodeContentAnchorPoint("right", "bottom", "small"),
                getEntityNodeContentSize("bottom", "small"));

export const getPermissionPathNodeContentSize =
    (permissionPath: Contract.AwsAccessGraphPermissionPath) =>
        _.isNil(permissionPath.identifier.principalPolicyId) || _.isNil(permissionPath.identifier.resourcePermitterType)
            ? getEntityNodeContentSize("bottom", "large")
            : getHorizontalLinkedNodeContentSize(2, getEntityNodeContentSize("bottom", "small"));