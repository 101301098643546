import { useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import _ from "lodash";
import React, { memo } from "react";
import { Contract, useNetworkAccessScopeTranslator } from "..";

const NetworkAccessScopeFilterMemo = memo(NetworkAccessScopeFilter);
export { NetworkAccessScopeFilterMemo as NetworkAccessScopeFilter };

type NetworkAccessScopeFilterFilterProps = {
    placeholder: string;
};

function NetworkAccessScopeFilter({ placeholder }: NetworkAccessScopeFilterFilterProps) {
    const networkAccessScopeTranslator = useNetworkAccessScopeTranslator();
    const localization =
        useLocalization(
            "common.networkAccessScopeFilter",
            () => ({
                infoMessage: "**All IPs**: IPv4 or IPv6 addresses [0.0.0.0/0, :/0]\n**Wide Range of IPs**: larger than 65,535 addresses\n**Specific IPs**: smaller than 65,536 addresses"
            }));
    return (
        <ValuesFilter
            emptyValueOptions={{ enabled: true }}
            infoMessage={localization.infoMessage()}
            placeholder={placeholder}
            sorted={false}>
            {_(Contract.NetworkAccessScope).
                values().
                filter(accessScope => accessScope !== Contract.NetworkAccessScope.None).
                reverse().
                map(
                    accessScope =>
                        <ValuesFilterItem
                            key={accessScope}
                            title={networkAccessScopeTranslator(accessScope)}
                            value={accessScope}/>).
                value()}
        </ValuesFilter>);
}