import { TimeFormatter, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { Contract, InfoItem } from "../../../../../../../../../../common";
import { EntitiesInfoCard, EntitiesInfoItem, Info } from "../../../../components";
import { usePingIdentityCommonDirectoryEntityInfoItemElements } from "./usePingIdentityCommonDirectoryEntityInfoItemElements";

export function usePingIdentityDirectoryGroupDefinition(directoryEntityModel: Contract.PingIdentityDirectoryEntityModel, options?: DefinitionOptions) {
    const defaultResourceInfoItemElements = usePingIdentityCommonDirectoryEntityInfoItemElements(directoryEntityModel);
    const groupModel = directoryEntityModel as Contract.PingIdentityDirectoryGroupModel;
    const group = groupModel.entity as Contract.PingIdentityDirectoryGroup;

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.pingIdentity.usePingIdentityDirectoryGroupDefinition",
            () => ({
                info: {
                    items: {
                        Description: "Description",
                        updatedTime: {
                            empty: "Never",
                            title: "Updated Time"
                        }
                    },
                    members: "Members"
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={[]}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="updatedTime"
                        title={localization.info.items.updatedTime.title()}
                        value={
                            _.isNil(group?.updateTime)
                                ? localization.info.items.updatedTime.empty()
                                : TimeFormatter.profileFormatDateTime(group.updateTime)}/>,
                    <InfoItem
                        key="description"
                        location="all"
                        title={localization.info.items.Description()}
                        value={group.description}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={groupModel.memberPrincipalIds}
                        entityTypeName={Contract.TypeNames.PingIdentityDirectoryPrincipal}
                        key="members"
                        location="miniGlance"
                        title={localization.info.members()}/>
                ]}
                options={options?.infoOptions}>
                <EntitiesInfoCard
                    entityIdsOrModels={groupModel.memberPrincipalIds}
                    entityTypeName={Contract.TypeNames.PingIdentityDirectoryPrincipal}
                    title={localization.info.members()}/>
            </Info>,
        sensitive: false
    });
}