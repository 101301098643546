import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function QuboleIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <g clipPath="url(#clip0_58343_2220)">
                <path
                    d="M0 12C0 5.37258 5.37258 0 12 0V0C18.6274 0 24 5.37258 24 12V12C24 18.6274 18.6274 24 12 24V24C5.37258 24 0 18.6274 0 12V12Z"
                    fill="#1474A2"/>
                <path
                    clipRule="evenodd"
                    d="M10.233 17.0557L11.4975 18.4498L12.5755 17.4286L11.5785 16.31L12.0973 15.7264V7.33739L10.9301 6.02432V6H5.16717V6.02432L4 7.33739V15.7183L5.16717 17.0314V17.0557H10.233ZM5.83154 7.96924H10.249V15.1749H5.83154V7.96924Z"
                    fill="white"
                    fillRule="evenodd"/>
                <path
                    d="M18.8734 16.999L20 15.7994V8.75583H18.1682V15.1023H14.7315V8.75583H12.8997V15.7913L14.0182 16.999L14.0993 17.08H18.7923L18.8734 16.999Z"
                    fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0_58343_2220">
                    <rect
                        fill="white"
                        height="24"
                        width="24"/>
                </clipPath>
            </defs>
        </SvgIcon>);
}