import { CopyToClipboardTextField, InlineCopyToClipboardText, Step, Steps, useExecuteOperation, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { ConfigurationController, Contract, GeographyHelper } from "../../../../../../../../../../../../../../../../../common";
import { AwsCloudFormationHelper } from "../../../../../../../../../../../../../../../../../tenants";
import { AddOrEdit, useAddOrEditContext } from "../../../AddOrEdit";

export function CloudFormationConsole() {
    const { masterTenantModel, organizationConfiguration, permissionTypes, roleName, rootRawId } = useAddOrEditContext();

    const [onboardingInfo] =
        useExecuteOperation(
            AddOrEdit,
            () =>
                ConfigurationController.getAwsOrganizationOnboardingInfo(
                    new Contract.ConfigurationControllerGetAwsOrganizationOnboardingInfoRequest(
                        masterTenantModel?.configuration.partitionType ??
                        GeographyHelper.deploymentAwsPartitionType)));
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCloudProviderTenantOrganizationItems.aws.addOrEdit.roleItem.cloudFormationConsole",
            () => ({
                steps: {
                    add: {
                        step1: "Login into your **AWS Management account**",
                        step2: "Open the **AWS CloudFormation** console and navigate to StackSets",
                        step3: "Click **Create StackSet**",
                        step4: {
                            cloudFormationTemplateUrl: "Template URL",
                            title: "In Step 1 (Choose a template), keep the default values, and, in the Specify template section, enter the following Amazon S3 URL"
                        },
                        step5: "Click **Next**",
                        step6: "In Step 2 (Specify StackSet details), enter a **StackSet** name, and, optionally, a description",
                        step7: {
                            parameter: "**{{parameterName}}** is set to **{{parameterValue}}**",
                            title: "Enter the following parameters values"
                        },
                        step8: "Click **Next**",
                        step9: "In Step 3 (Configure StackSet options), click **Next**",
                        step10: "In Step 4 (Set deployment options), choose either **Deploy to organization** or **Deploy to organizational units (OUs)**, as relevant",
                        step11: "In **Regions**, specify a single region (IAM roles aren't regional) and then Click **Next**",
                        step12: "In Step 5 (Review), review the StackSet configurations Select *italic*I acknowledge that AWS CloudFormation might create IAM resources with custom names** and then click **Submit**",
                        step13: "Wait until the Stack creation is complete, click on the refresh button in the Events tab to track progress. If it takes longer than expected, you may continue without it"
                    },
                    edit: {
                        step1: "Login into your AWS Management account",
                        step2: "Open the AWS CloudFormation console and navigate to StackSets and locate the Tenable StackSet",
                        step3: "Click on **Actions** and then **Edit StackSet details**",
                        step4: "In Step 1 (Choose a template), keep the default values, and click **Next**",
                        step5: {
                            parameter: "**{{parameterName}}** is set to **{{parameterValue}}**",
                            title: "In Step 2 (Specify StackSet details), **Verify** the following values are configured"
                        },
                        step6: "Click Next",
                        step7: "In Step 3 (Configure StackSet options), click **Next**",
                        step8: "In Step 4 (Set deployment options), choose  Deploy to organizational units (OUs) and enter the AWS OU ID: {{rootRawId}}",
                        step9: "In Regions, specify a single region (IAM roles aren't regional) and then click **Next**",
                        step10: "In Step 5 (Review), review the StackSet configurations *italic*Select I acknowledge that AWS CloudFormation might create IAM resources with custom names** and then click Submit",
                        step11: "Wait until the Stack creation is complete, click on the refresh button in the Events tab to track progress. If it takes longer than expected, you may continue without it"
                    }
                }
            }));

    const { policyParameterNameToValueMap, roleParameterNameToValueMap } =
        AwsCloudFormationHelper.getOrganizationSteps(
            onboardingInfo,
            masterTenantModel!.configuration.partitionType,
            permissionTypes!,
            roleName!,
            rootRawId!);

    return (
        <Steps variant="letters">
            {_.isNil(organizationConfiguration)
                ? [
                    localization.steps.add.step1(),
                    localization.steps.add.step2(),
                    localization.steps.add.step3(),
                    new Step(
                        localization.steps.add.step4.title(),
                        {
                            contentElement:
                                <CopyToClipboardTextField
                                    title={localization.steps.add.step4.cloudFormationTemplateUrl()}
                                    value={onboardingInfo.cloudFormationTemplateUrl}/>
                        }),
                    localization.steps.add.step5(),
                    localization.steps.add.step6(),
                    new Step(
                        localization.steps.add.step7.title(),
                        {
                            contentElement:
                                <Steps variant="bullets">
                                    {_<string>([]).
                                        concat(
                                            _.map(
                                                roleParameterNameToValueMap,
                                                (parameterValue, parameterName) =>
                                                    localization.steps.add.step7.parameter({
                                                        parameterName,
                                                        parameterValue:
                                                            <InlineCopyToClipboardText text={parameterValue}/>
                                                    }))).
                                        concat(
                                            _.map(
                                                policyParameterNameToValueMap,
                                                (parameterValue, parameterName) =>
                                                    localization.steps.add.step7.parameter({
                                                        parameterName,
                                                        parameterValue
                                                    }))).
                                        value()}
                                </Steps>
                        }),
                    localization.steps.add.step8(),
                    localization.steps.add.step9(),
                    localization.steps.add.step10(),
                    localization.steps.add.step11(),
                    localization.steps.add.step12(),
                    localization.steps.add.step13()
                ]
                : [
                    localization.steps.edit.step1(),
                    localization.steps.edit.step2(),
                    localization.steps.edit.step3(),
                    localization.steps.edit.step4(),
                    new Step(
                        localization.steps.edit.step5.title(),
                        {
                            contentElement:
                                <Steps variant="bullets">
                                    {_<string>([]).
                                        concat(
                                            _.map(
                                                roleParameterNameToValueMap,
                                                (parameterValue, parameterName) =>
                                                    localization.steps.edit.step5.parameter({
                                                        parameterName,
                                                        parameterValue:
                                                            <InlineCopyToClipboardText text={parameterValue}/>
                                                    }))).
                                        concat(
                                            _.map(
                                                policyParameterNameToValueMap,
                                                (parameterValue, parameterName) =>
                                                    localization.steps.edit.step5.parameter({
                                                        parameterName,
                                                        parameterValue
                                                    }))).
                                        value()}
                                </Steps>
                        }),
                    localization.steps.edit.step6(),
                    localization.steps.edit.step7(),
                    localization.steps.edit.step8({ rootRawId: <InlineCopyToClipboardText text={rootRawId!}/> }),
                    localization.steps.edit.step9(),
                    localization.steps.edit.step10(),
                    localization.steps.edit.step11()
                ]}
        </Steps>);
}