import { TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import _ from "lodash";
import moment from "moment";
import React from "react";

type DateFieldProps = {
    date?: string;
    disabled?: boolean;
    disableOpenPicker?: boolean;
    onChange?: (date?: string) => void;
    title: string;
};

export function DateField({ date, disabled, disableOpenPicker, onChange, title }: DateFieldProps) {
    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
                disabled={disabled}
                disableOpenPicker={disableOpenPicker}
                slots={{
                    textField:
                        params => (
                            <TextField
                                {...params}
                                fullWidth={true}
                                label={title}
                                variant="outlined"/>)
                }}
                value={
                    _.isNil(date)
                        ? null
                        : moment(date)}
                onChange={dateMoment => onChange?.(dateMoment?.toISOString())}/>
        </LocalizationProvider>);
}