﻿import { useLocalization } from "@infrastructure";
import { Contract } from "../../../common";

export function useSystemKubernetesClusterAdmissionControllerStatusTranslator() {
    const localization =
        useLocalization(
            "tenants.kubernetes.hooks.useSystemKubernetesClusterAdmissionControllerStatusTranslator",
            () => ({
                [Contract.TypeNames.SystemKubernetesClusterModelHelmComponentStatus]: {
                    [Contract.SystemKubernetesClusterModelHelmComponentStatus.NotInstalled]: "Not installed",
                    [Contract.SystemKubernetesClusterModelHelmComponentStatus.NotRunning]: "Not running",
                    [Contract.SystemKubernetesClusterModelHelmComponentStatus.Running]: "Running"
                }
            }));
    return (status: Contract.SystemKubernetesClusterModelHelmComponentStatus) =>
        localization[Contract.TypeNames.SystemKubernetesClusterModelHelmComponentStatus][status]();
}