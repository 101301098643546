﻿import moment from "moment";
import { useCallback } from "react";
import { useLocalization } from "../hooks";
import { TimeFormatter } from "../utilities";

const hourMilliseconds = 1000 * 60 * 60;
const dayMilliseconds = 24 * hourMilliseconds;

export function useFormatRelativeTime() {
    const localization =
        useLocalization(
            "infrastructure.hooks.useFormatRelativeTime",
            () => ({
                hours: [
                    "1 hour ago",
                    "{{count | NumberFormatter.humanize}} hours ago"
                ],
                lessThanHour: "< 1 hour ago"
            }));

    return useCallback(
        (timeInput: moment.MomentInput, formatFunction?: (timeInput: moment.MomentInput) => string) => {
            const relativeDurationMilliseconds =
                Date.
                    now().
                    valueOf() - moment(timeInput).
                    valueOf();
            return relativeDurationMilliseconds < hourMilliseconds
                ? localization.lessThanHour()
                : relativeDurationMilliseconds <= dayMilliseconds
                    ? localization.hours(Math.floor(relativeDurationMilliseconds / hourMilliseconds))
                    : formatFunction
                        ? formatFunction(timeInput)
                        : TimeFormatter.workloadResourceDateTime(timeInput);
        },
        [localization]);
}