import { TimeFormatter, useLocalization } from "@infrastructure";
import React from "react";
import { useAwsCommonEncryptedResourceInfoElements, useAwsDefaultResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { useCustomEntityPropertyInfoItemElements } from "..";
import { Contract, InfoItem } from "../../../../../../../../../../common";
import { EntitiesInfoItem, Info } from "../../../../components";

export function useAwsSsmParameterDefinition(resourceModel: Contract.AwsResourceModel, options?: DefinitionOptions) {
    const commonEncryptedResourceInfoElements = useAwsCommonEncryptedResourceInfoElements(resourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useAwsDefaultResourceInfoItemElements(resourceModel);
    const parameter = resourceModel.entity as Contract.AwsSsmParameter;

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsSsmParameterDefinition",
            () => ({
                info: {
                    items: {
                        updateIdentity: "Updated By",
                        updateTime: "Update Time",
                        [Contract.TypeNames.AwsSsmParameterType]: {
                            title: "Parameter Type",
                            [Contract.AwsSsmParameterType.SecureString]: "Secure String",
                            [Contract.AwsSsmParameterType.String]: "String",
                            [Contract.AwsSsmParameterType.StringList]: "String List"
                        }
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    ...commonEncryptedResourceInfoElements,
                    <InfoItem
                        key="updateTime"
                        location="all"
                        title={localization.info.items.updateTime()}
                        value={TimeFormatter.profileFormatDateTime(parameter.updateTime)}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={parameter.updateIdentityReference.idReference}
                        entityTypeName={Contract.TypeNames.AwsIamIdentity}
                        key="updateIdentity"
                        location="all"
                        title={localization.info.items.updateIdentity()}/>,
                    <InfoItem
                        key="type"
                        title={localization.info.items[Contract.TypeNames.AwsSsmParameterType].title()}
                        value={localization.info.items[Contract.TypeNames.AwsSsmParameterType][parameter.type]()}/>
                ]}
                options={options?.infoOptions}>
            </Info>
    });
}