import { useMemo } from "react";
import { Contract } from "../../../../../../../../../../../../common";
import { useGetGciDirectoryEntityRiskContext } from "./useGetGciDirectoryEntityRiskContext";

export function useGetGciDirectoryUserRiskContext() {
    return useMemo(
        () => useGciDirectoryUserRiskContext,
        []);
}

function useGciDirectoryUserRiskContext(userModel: Contract.GciDirectoryUserModel) {
    return useGetGciDirectoryEntityRiskContext()(userModel);
}