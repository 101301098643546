﻿import { NoneIcon, Optional, Sx, Tooltip } from "@infrastructure";
import { Stack, SxProps, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { RegionModelHelper, TenantIcon, useTheme } from "..";

type RegionProps = {
    regionId: Optional<string>;
    sx?: SxProps;
    variant?: RegionVariant;
};

export type RegionVariant = "iconText" | "text";

export function Region({ regionId, sx, variant }: RegionProps) {
    const regionModel = RegionModelHelper.tryGet(regionId);

    const theme = useTheme();
    return (
        _.isNil(regionModel)
            ? <NoneIcon/>
            : <Tooltip titleOrGetTitle={regionModel.name}>
                <Stack
                    alignItems="center"
                    direction="row"
                    sx={
                        Sx.combine(
                            { overflow: "hidden" },
                            sx)}>
                    {variant === "iconText" &&
                        <TenantIcon
                            sx={{
                                display: "inline-flex",
                                fontSize: "18px",
                                marginRight: theme.spacing(0.75),
                                verticalAlign: "middle"
                            }}
                            tenantType={regionModel.tenantType}/>}
                    <Typography
                        component="span"
                        noWrap={true}>
                        {regionModel.systemName}
                    </Typography>
                </Stack>
            </Tooltip>);
}