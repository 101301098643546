import _, { Dictionary } from "lodash";
import React, { ReactNode, useRef } from "react";
import { Optional, SelectionActionsAction, useLocalization, useRoute } from "@infrastructure";
import { PrincipalsDefinition } from "../..";
import { AccessController, Contract, CreateJiraIssuesSelectionAction, CreateServiceNowIncidentsSelectionAction, CustomerConsoleAppUrlHelper, Entity, ItemSelectionHelper, RiskType, scopeSystemEntityModelStore, StorageHelper, TimeRangeHelper, UpdateJiraIssuesSelectionAction, UpdateServiceNowIncidentsSelectionAction, useEntityTypeNameTranslator, UserHelper } from "../../../../../../../../../../common";
import { ExcessivePermissionSummary, Table as PrincipalsTable, TableActions, TableContext } from "../../components";

export function useExcessivePermissionDefinition() {
    return new PrincipalsDefinition(
        <Table/>,
        <ExcessivePermissionSummary/>);
}

function Table() {
    const url = CustomerConsoleAppUrlHelper.getPrincipalExcessivePermissionsRelativeUrl();
    const { selectedPrincipalRiskCategory } = useRoute(`${url}/{selectedPrincipalRiskCategory}`);
    const serviceNowModels = scopeSystemEntityModelStore.useGetServiceNow();
    const jiraModels = scopeSystemEntityModelStore.useGetJira();

    function getExcessivePermissionFilters(filterMap: Dictionary<any>, selectedPrincipalRiskCategory: Contract.PrincipalRiskCategory) {
        return new Contract.AccessControllerGetPrincipalExcessivePermissionModelRequestFilters(
            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.AccessControllerGetPrincipalModelPageRequestProperty.ActionRiskCategories]),
            TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(tableActionsRef.current?.getFiltersTime(), filterMap[Contract.AccessControllerGetPrincipalModelPageRequestProperty.ActivityTime]),
            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.AccessControllerGetPrincipalModelPageRequestProperty.EntityAttributes]),
            ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.AccessControllerGetPrincipalModelPageRequestProperty.AwsPermissionSetAccounts]),
            ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.AccessControllerGetPrincipalModelPageRequestProperty.AwsPermissionSetPrincipals]),
            ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.AccessControllerGetPrincipalModelPageRequestProperty.Id]),
            ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.AccessControllerGetPrincipalModelPageRequestProperty.ServiceIdentityOriginatorEntities]),
            ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.AccessControllerGetPrincipalModelPageRequestProperty.ServiceIdentityOriginatorEntityTenantId]),
            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.AccessControllerGetPrincipalModelPageRequestProperty.ServiceIdentityOriginatorEntityTypeNames]),
            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.AccessControllerGetPrincipalModelPageRequestProperty.Severity]),
            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.AccessControllerGetPrincipalModelPageRequestProperty.TenantId]),
            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.AccessControllerGetPrincipalModelPageRequestProperty.TypeNames]),
            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.AccessControllerGetPrincipalModelPageRequestProperty.Vendors]),
            ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.AccessControllerGetPrincipalModelPageRequestProperty.Inactive]),
            selectedPrincipalRiskCategory as Contract.PrincipalRiskCategory,
            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.TypeNames.TicketingServiceType]));
    }

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.access.principals.hooks.useDefinition.hooks.useExcessivePermissionDefinition",
            () => ({
                prompt: {
                    description: {
                        many: {
                            [Contract.TypeNames.TicketingServiceType]: {
                                [Contract.TicketingServiceType.Jira]: "{{multiRiskPrincipalCount | NumberFormatter.humanize}} out of the {{principalCount | NumberFormatter.humanize}} identities are overprivileged/inactive in several accounts. Each Jira issue we create relates to a single account and provides detailed information on those permissions with a link to the finding that details how to remediate it.\nWe are about to create **{{ticketCount | NumberFormatter.humanize}} Jira issues**, 1 for each identity and account the identity is overprivileged/inactive in and you have permissions on.\nClick **Continue** if you want to proceed and select issue details or **Cancel** if you do not wish to create Jira issues.",
                                [Contract.TicketingServiceType.ServiceNow]: "{{multiRiskPrincipalCount | NumberFormatter.humanize}} out of the {{principalCount | NumberFormatter.humanize}} identities are overprivileged/inactive in several accounts. Each ServiceNow incident we create relates to a single account and provides detailed information on those permissions with a link to the finding that details how to remediate it.\nWe are about to create **{{ticketCount | NumberFormatter.humanize}} ServiceNow incidents**, 1 for each identity and account the identity is overprivileged/inactive in and you have permissions on.\nClick **Continue** if you want to proceed and select incident details or **Cancel** if you do not wish to create ServiceNow incidents."
                            }
                        },
                        single: {
                            [Contract.TypeNames.TicketingServiceType]: {
                                [Contract.TicketingServiceType.Jira]: "This {{translatedPrincipalTypeName}} is overprivileged/inactive in {{tenantCount | NumberFormatter.humanize}} different accounts on which you have permissions to create Jira issues. Each Jira issue we create relates to a single account and provides detailed information on those permissions with a link to the finding that details how to remediate it.\nWe are about to create **{{ticketCount | NumberFormatter.humanize}} Jira issues**, 1 for each account this {{translatedPrincipalTypeName}} is overprivileged/inactive in and you have permissions on.\nClick **Continue** if you want to proceed and select issue details or **Cancel** if you do not wish to create Jira issues.",
                                [Contract.TicketingServiceType.ServiceNow]: "This {{translatedPrincipalTypeName}} is overprivileged/inactive in {{tenantCount | NumberFormatter.humanize}} different accounts on which you have permissions to create ServiceNow incidents. Each ServiceNow incident we create relates to a single account and provides detailed information on those permissions with a link to the finding that details how to remediate it.\nWe are about to create **{{ticketCount | NumberFormatter.humanize}} ServiceNow incidents**, 1 for each account this {{translatedPrincipalTypeName}} is overprivileged/inactive in and you have permissions on.\nClick **Continue** if you want to proceed and select incident details or **Cancel** if you do not wish to create ServiceNow incidents."
                            }
                        }
                    },
                    title: {
                        many: {
                            [Contract.TypeNames.TicketingServiceType]: {
                                [Contract.TicketingServiceType.Jira]: "Create/Update {{ticketCount | NumberFormatter.humanize}} Jira Issues for **{{principalCount | NumberFormatter.humanize}} identities**",
                                [Contract.TicketingServiceType.ServiceNow]: "Create/Update {{ticketCount | NumberFormatter.humanize}} ServiceNow Incidents for **{{principalCount | NumberFormatter.humanize}} identities**"
                            }
                        },
                        single: {
                            [Contract.TypeNames.TicketingServiceType]: {
                                [Contract.TicketingServiceType.Jira]: "Create/Update Jira Issue for {{translatedPrincipalTypeName}} **{{principal}}**",
                                [Contract.TicketingServiceType.ServiceNow]: "Create/Update ServiceNow Incident for {{translatedPrincipalTypeName}} **{{principal}}**"
                            }
                        }
                    }
                }
            }));

    const principalIdToOpenAccessRiskIdToScopeIdToIdentityPermissionsMapRef = useRef<Dictionary<Dictionary<Dictionary<Contract.IdentityPermission[]>>>>({});
    const tableActionsRef = useRef<TableActions>();
    const getSelectedPrincipalPermittedRiskIdToScopeIdsMap =
        () =>
            _(tableActionsRef.current!.getSelectedPrincipalIds()).
                flatMap(
                    selectedPrincipalId =>
                        _.map(
                            principalIdToOpenAccessRiskIdToScopeIdToIdentityPermissionsMapRef.current[selectedPrincipalId],
                            (scopeIdToIdentityPermissionsMap, principalOpenAccessRiskId) => ({
                                principalOpenAccessRiskId,
                                scopeIdToIdentityPermissionsMap
                            }))).
                filter(
                    ({ scopeIdToIdentityPermissionsMap }) =>
                        _.includes(
                            _.flatMap(scopeIdToIdentityPermissionsMap),
                            Contract.IdentityPermission.SecurityWrite)).
                keyBy(({ principalOpenAccessRiskId }) => principalOpenAccessRiskId).
                mapValues(({ scopeIdToIdentityPermissionsMap }) => _.keys(scopeIdToIdentityPermissionsMap)).
                value();

    const getTicketingServiceGetPromptMessage =
        (ticketingServiceType: Contract.TicketingServiceType) =>
            (selectedItemIds: string[]) => {
                const principalModel = tableActionsRef.current!.getSelectedPrincipalModel();
                const selectedPrincipalPermittedRiskIdToScopeIds = getSelectedPrincipalPermittedRiskIdToScopeIdsMap();
                if (!_.isNil(principalModel)) {
                    return {
                        description:
                            localization.prompt.description.single[Contract.TypeNames.TicketingServiceType][ticketingServiceType]({
                                tenantCount: _.keys(selectedPrincipalPermittedRiskIdToScopeIds).length,
                                ticketCount: _.keys(selectedPrincipalPermittedRiskIdToScopeIds).length,
                                translatedPrincipalTypeName:
                                    entityTypeNameTranslator(
                                        principalModel.entity.typeName,
                                        {
                                            includeServiceName: false,
                                            variant: "text"
                                        })
                            }),
                        title:
                            localization.prompt.title.single[Contract.TypeNames.TicketingServiceType][ticketingServiceType]({
                                principal:
                                    <Entity
                                        entityIdOrModel={principalModel.id}
                                        linkOptions={{ disabled: true }}
                                        variant="text"/>,
                                translatedPrincipalTypeName:
                                    entityTypeNameTranslator(
                                        principalModel.entity.typeName,
                                        {
                                            variant: "text"
                                        })
                            })
                    };
                } else {
                    return {
                        description:
                            localization.prompt.description.many[Contract.TypeNames.TicketingServiceType][ticketingServiceType]({
                                multiRiskPrincipalCount:
                                    _(tableActionsRef.current!.getSelectedPrincipalIds()).
                                        filter(
                                            selectedItemId =>
                                                _(principalIdToOpenAccessRiskIdToScopeIdToIdentityPermissionsMapRef.current[selectedItemId]).
                                                    pickBy(
                                                        scopeIdToIdentityPermissionsMap =>
                                                            _.includes(
                                                                _.flatMap(scopeIdToIdentityPermissionsMap),
                                                                Contract.IdentityPermission.SecurityWrite)).
                                                    size() > 1).
                                        size(),
                                principalCount: selectedItemIds.length,
                                ticketCount: _.keys(selectedPrincipalPermittedRiskIdToScopeIds).length
                            }),
                        title:
                            localization.prompt.title.many[Contract.TypeNames.TicketingServiceType][ticketingServiceType]({
                                principalCount: selectedItemIds.length,
                                ticketCount: _.keys(selectedPrincipalPermittedRiskIdToScopeIds).length
                            })
                    };
                }
            };

    function getPrincipalIdToIdentityPermissionsMap(principalIdToOpenAccessRiskIdToScopeIdToIdentityPermissionsMap: Dictionary<Dictionary<Dictionary<Contract.IdentityPermission[]>>>) {
        return _.mapValues(
            principalIdToOpenAccessRiskIdToScopeIdToIdentityPermissionsMap,
            openAccessRiskIdToScopeIdToIdentityPermissionsMap =>
                _(openAccessRiskIdToScopeIdToIdentityPermissionsMap).
                    values().
                    flatMap(
                        scopeIdToIdentityPermissionsMap =>
                            _.flatMap(
                                scopeIdToIdentityPermissionsMap,
                                identityPermissions => identityPermissions)).
                    uniq().
                    value());
    }

    return (
        <PrincipalsTable
            actionsRef={tableActionsRef}
            filtersVisibilityStorageItem={StorageHelper.customerExcessivePermissionsTableFilters(selectedPrincipalRiskCategory)}
            getPrincipalIdToIdentityPermissionsMap={
                async filterMap => {
                    const { principalIdToOpenAccessRiskIdToScopeIdToIdentityPermissionsMap } =
                        await AccessController.getPrincipalIdToOpenAccessRiskIdToScopeIdToIdentityPermissionsMap(
                            new Contract.AccessControllerGetPrincipalIdToOpenAccessRiskIdToScopeIdToIdentityPermissionsMapRequest(
                                getExcessivePermissionFilters(
                                    filterMap,
                                    selectedPrincipalRiskCategory as Contract.PrincipalRiskCategory)));
                    principalIdToOpenAccessRiskIdToScopeIdToIdentityPermissionsMapRef.current = principalIdToOpenAccessRiskIdToScopeIdToIdentityPermissionsMap;

                    return getPrincipalIdToIdentityPermissionsMap(principalIdToOpenAccessRiskIdToScopeIdToIdentityPermissionsMap);
                }}
            getPrincipalModelPage={
                async (filterMap, limit, skip, sort) => {
                    const filters =
                        getExcessivePermissionFilters(
                            filterMap,
                            selectedPrincipalRiskCategory as Contract.PrincipalRiskCategory);
                    const { principalIdToOpenAccessRiskIdToScopeIdToIdentityPermissionsMap, principalModelPage } =
                        await AccessController.getPrincipalModelPage(
                            new Contract.AccessControllerGetPrincipalExcessivePermissionsModelPageRequest(
                                filters,
                                limit,
                                skip,
                                sort));

                    let currentPrincipalRiskTenantPermission: Optional<boolean> = undefined;
                    if (skip === 0) {
                        const { principalRiskTenantPermission } =
                            await AccessController.getPrincipalExcessivePermissionPrincipalRiskTenantPermission(
                                new Contract.AccessControllerGetPrincipalExcessivePermissionPrincipalRiskTenantPermissionRequest(
                                    filters));
                        currentPrincipalRiskTenantPermission = principalRiskTenantPermission;
                    }
                    return {
                        onApplyData:
                            () => {
                                principalIdToOpenAccessRiskIdToScopeIdToIdentityPermissionsMapRef.current =
                                    skip === 0
                                        ? principalIdToOpenAccessRiskIdToScopeIdToIdentityPermissionsMap!
                                        : _.merge(
                                            principalIdToOpenAccessRiskIdToScopeIdToIdentityPermissionsMapRef.current,
                                            principalIdToOpenAccessRiskIdToScopeIdToIdentityPermissionsMap);
                            },
                        principalIdToIdentityPermissionsMap: getPrincipalIdToIdentityPermissionsMap(principalIdToOpenAccessRiskIdToScopeIdToIdentityPermissionsMap!),
                        principalModelPage,
                        principalRiskTenantPermission: currentPrincipalRiskTenantPermission
                    };
                }}
            key={selectedPrincipalRiskCategory}
            principalRiskCategory={selectedPrincipalRiskCategory as Contract.PrincipalRiskCategory}>
            {(context: TableContext) =>
                _<ReactNode>([]).
                    concatIf(
                        !_.isEmpty(jiraModels) || !_.isEmpty(serviceNowModels),
                        <SelectionActionsAction
                            id="CreateJiraIssuesButton"
                            key="CreateJiraIssuesButton"
                            permissions={[Contract.IdentityPermission.SecurityWrite]}>
                            <CreateJiraIssuesSelectionAction
                                getItemIssueCreationCount={
                                    principalModelItem =>
                                        _(principalModelItem.access!.scopesRisks).
                                            filter(
                                                scopesRisk =>
                                                    _.some(
                                                        scopesRisk.scopeIds,
                                                        scopeId =>
                                                            UserHelper.hasScopePermissions(
                                                                scopeId,
                                                                Contract.IdentityPermission.SecurityWrite))).
                                            size()}
                                getPromptMessage={getTicketingServiceGetPromptMessage(Contract.TicketingServiceType.Jira)}
                                getSelectedRiskIdToScopeIdsMap={getSelectedPrincipalPermittedRiskIdToScopeIdsMap}
                                reloadItems={context.reloadPrincipalModels}
                                riskType={RiskType.Cloud}/>
                        </SelectionActionsAction>,
                        <SelectionActionsAction
                            id="UpdateJiraIssueButton"
                            key="UpdateJiraIssueButton"
                            permissions={[Contract.IdentityPermission.SecurityWrite]}>
                            <UpdateJiraIssuesSelectionAction
                                getItemIssueUpdateCount={
                                    (principalModelItem: Contract.IPrincipalModel) =>
                                        _(principalModelItem.access!.scopesRisks).
                                            filter(
                                                scopesRisk =>
                                                    _.some(
                                                        scopesRisk.scopeIds,
                                                        scopeId =>
                                                            UserHelper.hasScopePermissions(
                                                                scopeId,
                                                                Contract.IdentityPermission.SecurityWrite))).
                                            map(scopesRisk => scopesRisk.jiraIssueIds.length).
                                            sum()}
                                getSelectedRiskIdToScopeIdsMap={getSelectedPrincipalPermittedRiskIdToScopeIdsMap}
                                getSelectionRiskIdToJiraIssueIdMap={
                                    (principalModelItems: Contract.IPrincipalModel[]) =>
                                        _(principalModelItems).
                                            flatMap(
                                                principalModelItem =>
                                                    _.filter(
                                                        principalModelItem.access!.scopesRisks,
                                                        scopesRisk =>
                                                            _.some(
                                                                scopesRisk.scopeIds,
                                                                scopeId =>
                                                                    UserHelper.hasScopePermissions(
                                                                        scopeId,
                                                                        Contract.IdentityPermission.SecurityWrite)))).
                                            filter(scopesRisk => !_.isEmpty(scopesRisk.jiraIssueIds)).
                                            keyBy(scopesRisk => scopesRisk.openAccessPrincipalRiskId).
                                            mapValues(scopesRisk => scopesRisk.jiraIssueIds).
                                            value()}
                                reloadItems={context.reloadPrincipalModels}
                                riskType={RiskType.Cloud}/>
                        </SelectionActionsAction>,
                        <SelectionActionsAction
                            id="CreateServiceNowIncidentsButton"
                            key="CreateServiceNowIncidentsButton"
                            permissions={[Contract.IdentityPermission.SecurityWrite]}>
                            <CreateServiceNowIncidentsSelectionAction
                                getItemIncidentCreationCount={
                                    principalModelItem =>
                                        _(principalModelItem.access!.scopesRisks).
                                            filter(
                                                scopesRisk =>
                                                    _.some(
                                                        scopesRisk.scopeIds,
                                                        scopeId =>
                                                            UserHelper.hasScopePermissions(
                                                                scopeId,
                                                                Contract.IdentityPermission.SecurityWrite))).
                                            size()}
                                getPromptMessage={getTicketingServiceGetPromptMessage(Contract.TicketingServiceType.ServiceNow)}
                                getSelectedRiskIdToScopeIdsMap={getSelectedPrincipalPermittedRiskIdToScopeIdsMap}
                                reloadItems={context.reloadPrincipalModels}
                                riskType={RiskType.Cloud}/>
                        </SelectionActionsAction>,
                        <SelectionActionsAction
                            id="UpdateServiceNowIncidentButton"
                            key="UpdateServiceNowIncidentButton"
                            permissions={[Contract.IdentityPermission.SecurityWrite]}>
                            <UpdateServiceNowIncidentsSelectionAction
                                getItemIncidentUpdateCount={
                                    (principalModelItem: Contract.IPrincipalModel) =>
                                        _(principalModelItem.access!.scopesRisks).
                                            filter(
                                                scopesRisk =>
                                                    _.some(
                                                        scopesRisk.scopeIds,
                                                        scopeId =>
                                                            UserHelper.hasScopePermissions(
                                                                scopeId,
                                                                Contract.IdentityPermission.SecurityWrite))).
                                            map(scopesRisk => scopesRisk.serviceNowIncidentIds).
                                            sum()}
                                getRiskIdToServiceNowIncidentIdsMap={
                                    (principalModelItems: Contract.IPrincipalModel[]) =>
                                        _(principalModelItems).
                                            flatMap(
                                                principalModelItem =>
                                                    _.filter(
                                                        principalModelItem.access!.scopesRisks,
                                                        scopesRisk =>
                                                            _.some(
                                                                scopesRisk.scopeIds,
                                                                scopeId =>
                                                                    UserHelper.hasScopePermissions(
                                                                        scopeId,
                                                                        Contract.IdentityPermission.SecurityWrite)))).
                                            filter(scopesRisk => !_.isEmpty(scopesRisk.serviceNowIncidentIds)).
                                            keyBy(scopesRisk => scopesRisk.openAccessPrincipalRiskId).
                                            mapValues(scopesRisk => scopesRisk.serviceNowIncidentIds).
                                            value()}
                                getSelectedRiskIdToScopeIdsMap={getSelectedPrincipalPermittedRiskIdToScopeIdsMap}
                                reloadItems={context.reloadPrincipalModels}
                                riskType={RiskType.Cloud}/>
                        </SelectionActionsAction>).
                    value()}
        </PrincipalsTable>);
}