﻿import { DataTableColumn, DataTableColumnRenderProps, EmptyMessageText, map, StringHelper, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, EntitiesCell, Entity, EntityFilter, entityModelStore, ItemTable, TypeHelper, useEntityTypeNameTranslator } from "../../../../../../../../../common";

type KubernetesRoleBindingsProps = {
    principalOrRoleModel: Contract.IKubernetesPrincipalModel | Contract.IKubernetesRoleModel;
};

export function KubernetesRoleBindings({ principalOrRoleModel }: KubernetesRoleBindingsProps) {
    const roleBindingModels = entityModelStore.useGet(principalOrRoleModel.roleBindingIds);
    const [items, relatedEntityIds] =
        useMemo(
            () => {
                const items =
                    _.map(
                        roleBindingModels,
                        roleBindingModel => new KubernetesRoleBindingsTableItem(roleBindingModel));

                const relatedEntityIds =
                    _.flatMap(
                        items,
                        item => [
                            ...item.principalIdReferences,
                            item.roleBindingData.roleReference.idReference,
                            item.scopeResourceId
                        ]);

                return [items, relatedEntityIds];
            },
            [roleBindingModels]);

    const relatedEntityModels = entityModelStore.useGet(relatedEntityIds);
    const relatedEntityModelMap =
        _.keyBy(
            relatedEntityModels,
            roleBindingRelatedEntityModel => roleBindingRelatedEntityModel.id);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.kubernetesRoleBindings",
            () => ({
                columns: {
                    principalIdReferences: "Principals",
                    roleBindingModel: "Role Binding",
                    roleId: "Role",
                    scopeResourceId: "Scope"
                },
                empty: {
                    withFilter: "No Matching Bindings",
                    withoutFilter: "No Bindings"
                },
                title: "Role Bindings"
            }));

    return (
        <ItemTable
            columnIdToGetItemValueMap={{
                [KubernetesRoleBindingTableColumnId.PrincipalIdReferences]: {
                    getFilterValue: item => item.principalIdReferences,
                    getSortValue:
                        item =>
                            StringHelper.getCombineSortValue(
                                _.size(item.principalIdReferences),
                                ..._.map(
                                    item.principalIdReferences,
                                    principalIdReference => relatedEntityModelMap[principalIdReference].entity.displayName))
                },
                [KubernetesRoleBindingTableColumnId.RoleBindingModel]: {
                    getFilterValue: item => item.roleBindingModel.id,
                    getSortValue: item => item.roleBindingModel.entity.displayName
                },
                [KubernetesRoleBindingTableColumnId.RoleId]: {
                    getFilterValue: item => item.roleBindingData.roleReference.idReference,
                    getSortValue: item => relatedEntityModelMap[item.roleBindingData.roleReference.idReference].entity.displayName
                },
                [KubernetesRoleBindingTableColumnId.ScopeResourceReference]: {
                    getFilterValue: item => item.scopeResourceId,
                    getSortValue:
                        item =>
                            StringHelper.getCombineSortValue(
                                item.roleBindingData.typeName !== Contract.TypeNames.KubernetesClusterRoleBindingData,
                                relatedEntityModelMap[item.scopeResourceId].entity.displayName)
                }
            }}
            csvExportFilePrefixes={[
                entityTypeNameTranslator(
                    principalOrRoleModel.entity.typeName,
                    { includeServiceName: false }),
                principalOrRoleModel.entity.displayName
            ]}
            defaultSortColumnIdOrIds={[
                KubernetesRoleBindingTableColumnId.ScopeResourceReference,
                KubernetesRoleBindingTableColumnId.RoleId
            ]}
            emptyMessageOptions={{
                emptyMessageText:
                    new EmptyMessageText(
                        localization.empty.withoutFilter(),
                        localization.empty.withFilter())
            }}
            getCsvItem={
                item =>
                    TypeHelper.extendOrImplement(principalOrRoleModel.typeName, Contract.TypeNames.IKubernetesPrincipalModel)
                        ? {
                            /* eslint-disable sort-keys-fix/sort-keys-fix */
                            "Scope": relatedEntityModelMap[item.scopeResourceId].entity.displayReference,
                            "Role": relatedEntityModelMap[item.roleBindingData.roleReference.idReference].entity.displayReference,
                            "Role Binding": item.roleBindingModel.entity.displayReference
                            /* eslint-enable sort-keys-fix/sort-keys-fix */
                        }
                        : {
                            /* eslint-disable sort-keys-fix/sort-keys-fix */
                            "Scope": relatedEntityModelMap[item.scopeResourceId].entity.displayReference,
                            "Principals":
                                _(item.principalIdReferences).
                                    map(principalIdReference => relatedEntityModelMap[principalIdReference].entity.displayReference).
                                    join("\n"),
                            "Role Binding": item.roleBindingModel.entity.displayReference
                            /* eslint-enable sort-keys-fix/sort-keys-fix */
                        }}
            getItemId={item => item.roleBindingModel.id}
            items={items}>
            {columnIdToItemValuesMap => [
                <DataTableColumn
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <EntityFilter
                                    entityIdsOrSearchableReferences={columnIdToItemValuesMap[KubernetesRoleBindingTableColumnId.ScopeResourceReference]}
                                    placeholder={localization.columns.scopeResourceId()}/>
                        }
                    }}
                    id={KubernetesRoleBindingTableColumnId.ScopeResourceReference}
                    key={KubernetesRoleBindingTableColumnId.ScopeResourceReference}
                    render={
                        ({ item }: DataTableColumnRenderProps<KubernetesRoleBindingsTableItem>) =>
                            <Entity
                                entityIdOrModel={relatedEntityModelMap[item.scopeResourceId]}
                                variant="iconTextTypeTenant"/>}
                    title={localization.columns.scopeResourceId()}/>,
                TypeHelper.extendOrImplement(principalOrRoleModel.typeName, Contract.TypeNames.IKubernetesPrincipalModel)
                    ? <DataTableColumn
                        filterOptions={{
                            itemOrItems: {
                                element:
                                    <EntityFilter
                                        entityIdsOrSearchableReferences={columnIdToItemValuesMap[KubernetesRoleBindingTableColumnId.RoleId]}
                                        placeholder={localization.columns.roleId()}/>
                            }
                        }}
                        id={KubernetesRoleBindingTableColumnId.RoleId}
                        key={KubernetesRoleBindingTableColumnId.RoleId}
                        render={
                            ({ item }: DataTableColumnRenderProps<KubernetesRoleBindingsTableItem>) =>
                                <Entity
                                    entityIdOrModel={relatedEntityModelMap[item.roleBindingData.roleReference.idReference]}
                                    variant="iconTextTypeTenant"/>}
                        title={localization.columns.roleId()}/>
                    : <DataTableColumn
                        filterOptions={{
                            itemOrItems: {
                                element:
                                    <EntityFilter
                                        entityIdsOrSearchableReferences={columnIdToItemValuesMap[KubernetesRoleBindingTableColumnId.PrincipalIdReferences]}
                                        placeholder={localization.columns.principalIdReferences()}/>
                            }
                        }}
                        id={KubernetesRoleBindingTableColumnId.PrincipalIdReferences}
                        key={KubernetesRoleBindingTableColumnId.PrincipalIdReferences}
                        render={
                            ({ item }: DataTableColumnRenderProps<KubernetesRoleBindingsTableItem>) =>
                                <EntitiesCell
                                    entityIdsOrModels={item.principalIdReferences}
                                    entityTypeName={Contract.TypeNames.IKubernetesPrincipal}
                                    entityVariant="iconTextTypeTenant"/>}
                        title={localization.columns.principalIdReferences()}/>,
                <DataTableColumn
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <EntityFilter
                                    entityIdsOrSearchableReferences={columnIdToItemValuesMap[KubernetesRoleBindingTableColumnId.RoleBindingModel]}
                                    placeholder={localization.columns.roleBindingModel()}/>
                        }
                    }}
                    id={KubernetesRoleBindingTableColumnId.RoleBindingModel}
                    key={KubernetesRoleBindingTableColumnId.RoleBindingModel}
                    render={
                        ({ item }: DataTableColumnRenderProps<KubernetesRoleBindingsTableItem>) =>
                            <Entity
                                entityIdOrModel={item.roleBindingModel}
                                variant="iconTextTypeTenant"/>}
                    title={localization.columns.roleBindingModel()}/>
            ]}
        </ItemTable>);
}

export enum KubernetesRoleBindingTableColumnId {
    PrincipalIdReferences = "principalIdReferences",
    RoleBindingModel = "roleBindingModel",
    RoleId = "roleId",
    ScopeResourceReference = "scopeResourceReference"
}

class KubernetesRoleBindingsTableItem {
    public principalIdReferences: string[];
    public roleBindingData: Contract.IKubernetesRoleBindingData;
    public scopeResourceId: string;

    constructor(public roleBindingModel: Contract.EntityModel) {
        const roleBinding = _.as<Contract.IKubernetesRoleBinding>(roleBindingModel.entity);
        this.roleBindingData = _.as<Contract.IKubernetesRoleBindingData>(roleBinding.data);
        this.principalIdReferences =
            _.map(
                this.roleBindingData.principalReferences,
                principalReference => principalReference.idReference);
        this.scopeResourceId =
            map(
                this.roleBindingData.typeName,
                {
                    [Contract.TypeNames.KubernetesClusterRoleBindingData]: () => _.as<Contract.IKubernetesClusterRoleBinding>(roleBindingModel.entity).clusterId,
                    [Contract.TypeNames.KubernetesNamespaceRoleBindingData]: () => _.as<Contract.IKubernetesNamespaceRoleBinding>(roleBindingModel.entity).data.namespaceId
                });
    }
}