import { useActions, useInputValidation, useLocalization } from "@infrastructure";
import { FormHelperText } from "@mui/material";
import _ from "lodash";
import React, { Fragment, Ref, useEffect, useState } from "react";
import { ConfigurationController, Contract, customEntityAttributeDefinitionModelStore, TextInput, TypeHelper } from "../../../../../../../../../../common";
import { useCustomEntityAttributesContext } from "../../../CustomEntityAttributeDefinitions";
import { AddCustomEntityAttributeActions, ConfigurationValidation } from "../AddOrEdit";

type ManualCustomEntityAttributeDefinitionProps = {
    actionsRef: Ref<AddCustomEntityAttributeActions>;
    disabled: boolean;
    onValidChange: (validation: ConfigurationValidation) => void;
    readOnly: boolean;
};

export function ManualCustomEntityAttributeDefinition({ actionsRef, disabled, onValidChange, readOnly }: ManualCustomEntityAttributeDefinitionProps) {
    const configuration = useCustomEntityAttributesContext().item?.configuration as Contract.ManualCustomEntityAttributeDefinitionConfiguration;
    const [name, setName] =
        useState(
            !_.isNil(configuration) &&
            TypeHelper.extendOrImplement(configuration.typeName, Contract.TypeNames.ManualCustomEntityAttributeDefinitionConfiguration)
                ? configuration.name
                : "");

    useActions<AddCustomEntityAttributeActions>(
        actionsRef,
        {
            reset() {
                setName("");
                nameValidationController.clear();
            },
            async upsertCustomEntityAttribute(colorIndex, scopeId) {
                const { customEntityAttributeDefinitionModel } =
                    await ConfigurationController.upsertManualCustomEntityAttributeDefinition(
                        new Contract.ConfigurationControllerUpsertManualCustomEntityAttributeDefinitionRequest(
                            colorIndex,
                            configuration?.id,
                            name,
                            scopeId));

                await customEntityAttributeDefinitionModelStore.notify(customEntityAttributeDefinitionModel);
            }
        });
    const localization =
        useLocalization(
            "views.customer.configuration.entities.customEntityAttributeDefinitions.addOrEdit.manualCustomEntityAttributeDefinition",
            () => ({
                name: {
                    error: {
                        required: "Name cannot be empty"
                    },
                    title: "Name"
                }
            }));

    const [nameValidationController, nameValidationMessage] =
        useInputValidation(
            () => {
                if (_.isEmpty(name.trim())) {
                    return localization.name.error.required();
                }

                return undefined;
            },
            [name]);

    useEffect(
        () =>
            onValidChange(
                {
                    changed:
                        !_.isNil(configuration) &&
                        name != configuration.name,
                    valid: nameValidationController.isValid()
                }),
        [name]);

    return (
        <Fragment>
            <TextInput
                disabled={disabled}
                placeholder={localization.name.title()}
                readOnly={readOnly}
                text={name}
                onChange={setName}/>
            {!_.isNil(nameValidationMessage) && (
                <FormHelperText error={true}>{nameValidationMessage}</FormHelperText>)}
        </Fragment>);
}