import { useLocalization } from "@infrastructure";
import { VulnerabilityEventType } from "../../../../../../../../common";

export function useVulnerabilityEventTypeTranslator() {
    const localization =
        useLocalization(
            "views.customer.workloadAnalysis.vulnerabilities.profile.hooks.useVulnerabilityEventTypeTranslator",
            () => ({
                [VulnerabilityEventType.CISADueDate]: "CISA due date",
                [VulnerabilityEventType.CISAKnownExploit]: "CISA known exploit",
                [VulnerabilityEventType.DiscoveryDate]: "Discovery date",
                [VulnerabilityEventType.EmergingThreat]: "Emerging threat",
                [VulnerabilityEventType.ExploitedInTheWild]: "Exploited in the wild",
                [VulnerabilityEventType.FirstProofOfConcept]: "First proof of concept",
                [VulnerabilityEventType.FirstTenableCoverage]: "First Tenable coverage",
                [VulnerabilityEventType.FunctionalExploit]: "First functional exploit",
                [VulnerabilityEventType.Malware]: "Malware",
                [VulnerabilityEventType.Mention]: "Mention",
                [VulnerabilityEventType.NVDPublished]: "NVD published",
                [VulnerabilityEventType.Ransomware]: "Ransomware"
            }));

    return (type: VulnerabilityEventType) => localization[type]();
}