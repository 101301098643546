﻿import { EmptyMessageText, Steps, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { RiskContentProps } from "../../../../../../../..";
import { Contract, Entity, entityModelStore, InlineEntities, usePrincipalModelAccess, useRiskSeverityReasonTranslator, useSeverityTranslator } from "../../../../../../../../../../../../../../../../common";
import { RiskDefinitionSection } from "../../../../../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../../../../../useCommonSectionsAndDescriptionDefinition";
import { useOpenRiskedEntityRisksStep } from "../../../../../../../useOpenRiskedEntityRisksStep";
import { EntityExternalConsoleLink } from "../../../../../../components";
import { RoleBindingTable } from "../../../components";
import { getSeverityValue, useGetServiceAccountSeverity } from "../../useGetServiceAccountSeverity";

export function useGcpPrincipalServiceAccountWideScopeImpersonateServiceAccountActionExistsRiskPartialServiceAccountDefinition(riskModel: Contract.GcpPrincipalServiceAccountWideScopeImpersonateServiceAccountActionExistsRiskModel) {
    const partialServiceAccountModel = entityModelStore.useGet(riskModel.risk.entityId) as Contract.GcpIamPartialServiceAccountModel;

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.useGcpPrincipalWideScopeImpersonateServiceAccountActionExistsRiskDefinition.hooks.useGcpPrincipalServiceAccountWideScopeImpersonateServiceAccountActionExistsRiskDefinition.hooks.useGcpPrincipalServiceAccountWideScopeImpersonateServiceAccountActionExistsRiskPartialServiceAccountDefinition",
            () => ({
                description: "Service account {{partialServiceAccountId}} has {{roleIds}} assigned at the project level or above",
                sections: {
                    resolution: {
                        step1: "Before deleting the role bindings, assign the service account role bindings to specific service accounts",
                        step2: "{{entityExternalConsoleLink}} and filter on **Principal:{{partialServiceAccountMail}}**",
                        step3: "Under **Permissions** delete the role bindings to {{roleIds}}"
                    },
                    roleBindingIds: {
                        title: "Role Bindings",
                        unknown: "Learning"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            partialServiceAccountId:
                <Entity
                    entityIdOrModel={partialServiceAccountModel}
                    variant="text"/>,
            roleIds:
                <InlineEntities
                    entityIdsOrModels={riskModel.roleIds}
                    entityTypeName={Contract.TypeNames.GcpIamRole}
                    variant="itemAndTypeOrItemCountAndType"/>
        }),
        () =>
            _.concat(
                localization.sections.resolution.step1(),
                _(riskModel.risk.orderedResourceManagerResourceIds).
                    flatMap(
                        resourceManagerResourceId => [
                            localization.sections.resolution.step2({
                                entityExternalConsoleLink:
                                    <EntityExternalConsoleLink
                                        entityId={resourceManagerResourceId}
                                        page={Contract.GcpConsolePage.Permissions}/>,
                                partialServiceAccountMail: riskModel.risk.principalMail
                            }),
                            localization.sections.resolution.step3({
                                roleIds:
                                    <InlineEntities
                                        entityIdsOrModels={riskModel.risk.resourceManagerResourceIdToRoleIdsMap[resourceManagerResourceId]}
                                        entityTypeName={Contract.TypeNames.GcpIamRole}
                                        variant="itemAndTypeOrItemCountAndType"/>
                            })
                        ]).
                    value()),
        riskModel,
        undefined,
        {
            contextSectionElement: <ContextSection riskModel={riskModel}/>,
            sections: [
                new RiskDefinitionSection(
                    <RoleBindingTable
                        csvExportFilePrefixes={[localization.sections.roleBindingIds.title()]}
                        risk={riskModel.risk}
                        unknownMessageText={new EmptyMessageText(localization.sections.roleBindingIds.unknown())}/>,
                    localization.sections.roleBindingIds.title())
            ]
        });
}

function ContextSection({ riskModel }: RiskContentProps) {
    const risk = riskModel.risk as Contract.GcpPrincipalServiceAccountWideScopeImpersonateServiceAccountActionExistsRisk;
    const partialServiceAccountModel = entityModelStore.useGet(risk.entityId) as Contract.GcpIamPartialServiceAccountModel;
    const partialServiceAccountModelAccess = usePrincipalModelAccess<Contract.ServiceIdentityModelAccess>(partialServiceAccountModel.id);
    const openRiskedEntityRisksStep = useOpenRiskedEntityRisksStep(partialServiceAccountModel, risk.id);
    const severityReasonTranslator = useRiskSeverityReasonTranslator();
    const severityTranslator = useSeverityTranslator();
    const [severity, severityServiceAccountIds] = useGetServiceAccountSeverity(risk);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.useGcpPrincipalWideScopeImpersonateServiceAccountActionExistsRiskDefinition.hooks.useGcpPrincipalServiceAccountWideScopeImpersonateServiceAccountActionExistsRiskDefinition.hooks.useGcpPrincipalServiceAccountWideScopeImpersonateServiceAccountActionExistsRiskPartialServiceAccountDefinition.contextSection",
            () => ({
                originatorResourceIds: "The service account is assigned to {{originatorResourceIds}}",
                partialServiceAccount: {
                    withGroupIds: "Service account {{partialServiceAccountId}} is an external service account and part of {{groupIds}}",
                    withoutGroupIds: "Service account {{partialServiceAccountId}} is an external service account"
                },
                serviceAccountIds: {
                    withHighSeverityServiceAccountIds: "The service account can impersonate {{serviceAccountIds}} in project {{tenantId}}, {{highSeverityServiceAccountIds}} with {{severity}} severity permissions",
                    withoutHighSeverityServiceAccountIds: "The service account can impersonate {{serviceAccountIds}} in project {{tenantId}}, none with high severity permissions"
                }
            }));


    return (
        <Steps>
            {_.filter([
                (_.isEmpty(partialServiceAccountModel.groupIds)
                    ? localization.partialServiceAccount.withoutGroupIds
                    : localization.partialServiceAccount.withGroupIds)({
                    groupIds:
                        <InlineEntities
                            entityIdsOrModels={partialServiceAccountModel.groupIds}
                            entityTypeName={Contract.TypeNames.GciDirectoryGroup}
                            entityTypeNameTranslatorOptions={{
                                includeServiceName: false,
                                variant: "text"
                            }}
                            variant="itemCountAndType"/>,
                    partialServiceAccountId:
                        <Entity
                            entityIdOrModel={partialServiceAccountModel}
                            variant="text"/>
                }),
                _.isEmpty(partialServiceAccountModelAccess.originatorEntityIds)
                    ? undefined
                    : localization.originatorResourceIds({
                        originatorResourceIds:
                            <InlineEntities
                                entityIdsOrModels={partialServiceAccountModelAccess.originatorEntityIds}
                                entityTypeName={Contract.TypeNames.GcpScopeResource}
                                variant="itemAndTypeOrItemCountAndType"/>
                    }),
                (getSeverityValue(severity) >= getSeverityValue(Contract.Severity.High)
                    ? localization.serviceAccountIds.withHighSeverityServiceAccountIds
                    : localization.serviceAccountIds.withoutHighSeverityServiceAccountIds)({
                    highSeverityServiceAccountIds:
                        <InlineEntities
                            entityIdsOrModels={severityServiceAccountIds}
                            entityTypeName={Contract.TypeNames.GcpIamServiceAccount}
                            entityTypeNameTranslatorOptions={{ variant: "text" }}
                            variant="itemCountAndType"/>,
                    serviceAccountIds:
                        <InlineEntities
                            entityIdsOrModels={_.keys(risk.serviceAccountIdToSeverityMap)}
                            entityTypeName={Contract.TypeNames.GcpIamServiceAccount}
                            entityTypeNameTranslatorOptions={{ variant: "text" }}
                            variant="itemCountAndType"/>,
                    severity:
                        _.isNil(severity)
                            ? undefined
                            : severityTranslator(severity!, "text"),
                    tenantId:
                        <Entity
                            entityIdOrModel={risk.tenantId}
                            variant="text"/>
                }),
                openRiskedEntityRisksStep,
                severityReasonTranslator(
                    risk.severity,
                    risk.severityReason!)
            ])}
        </Steps>);
}