import { DataTableColumnRenderProps } from "@infrastructure";
import { Typography } from "@mui/material";
import React from "react";
import { Contract, scopeSystemEntityModelStore } from "../../../common";

export function PermissionEligibilityCell({ item }: DataTableColumnRenderProps<Contract.PermissionRequestModel>) {
    const permissionEligibilityModel = scopeSystemEntityModelStore.useGet(item.permissionRequest.permissionEligibilityId);
    return (
        <Typography noWrap={true}>
            {(permissionEligibilityModel.configuration as Contract.PermissionEligibilityConfiguration).name}
        </Typography>);
}