import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useAwsElastiCacheReplicationGroupTransitEncryptionModeTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useAwsElastiCacheReplicationGroupTransitEncryptionModeTranslator",
            () => ({
                [Contract.TypeNames.AwsElastiCacheReplicationGroupTransitEncryptionMode]: {
                    [Contract.AwsElastiCacheReplicationGroupTransitEncryptionMode.Preferred]: "Preferred",
                    [Contract.AwsElastiCacheReplicationGroupTransitEncryptionMode.Required]: "Required"
                }
            }));
    return (transitEncryptionMode: Contract.AwsElastiCacheReplicationGroupTransitEncryptionMode) =>
        localization[Contract.TypeNames.AwsElastiCacheReplicationGroupTransitEncryptionMode][transitEncryptionMode]();
}