import { TextViewer, useLocalization } from "@infrastructure";
import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { Contract, entityModelStore, useTheme } from "../../../../..";
import { EntityProfileInfoLayout } from "../../../../../../views/Customer/components/Entities/components/Profile/components";

type InfoProps = {
    entityId: string;
};

export function AzureAuthorizationRoleAssignmentResourceChangeInfo({ entityId }: InfoProps) {
    const entityModel = entityModelStore.useGet(entityId);

    const localization =
        useLocalization(
            "common.riskResolutionAutomation.changeInfo.azureAuthorizationRoleAssignmentResourceChangeInfo",
            () => ({
                title: "Definition"
            }));

    const theme = useTheme();
    return (
        <EntityProfileInfoLayout entityId={entityId}>
            <Stack
                spacing={2}
                sx={{
                    height: "100%",
                    padding: theme.spacing(2)
                }}>
                <Typography variant="h3">
                    {localization.title()}
                </Typography>
                <Box sx={{ flex: 1 }}>
                    <TextViewer
                        format="json"
                        height="100%"
                        text={(entityModel.entity as Contract.AzureAuthorizationRoleDefinition).json}/>
                </Box>
            </Stack>
        </EntityProfileInfoLayout>);
}