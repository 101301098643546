﻿import { useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract } from "../../../../../../../controllers";
import { useEntityTypeNameTranslator } from "../../../../../../../hooks";
import { customEntityAttributeDefinitionModelStore } from "../../../../../../../stores";
import { ScopeHelper, TypeHelper } from "../../../../../../../utilities";
import { EntityAttributeDefinitionMultiSelect } from "../../../../../../EntityAttributeDefinitionMultiSelect";
import { EntityAttributeDefinitions } from "../../../../../../EntityAttributeDefinitions";
import { OperatorSelector } from "../../../../../../OperatorSelector";
import { useCustomRiskPolicyContext } from "../../../../../CustomRiskPolicy";
import { EntityMultiSelectProperty, useMultiSelectorContext, useSetMultiSelectorContext } from "../../../EntityMultiSelect";
import { useEntityMatchConditionCollectionOperatorTranslator } from "../hooks";

type AttributesProps = {
    builtInEntityAttributeTypeNames: string[];
    directoryEntityType: boolean;
    disabled?: boolean;
    disableManualCustomEntityAttributes: boolean;
    entityTypeName: string;
    includeServiceName: boolean;
};

export function Attributes({ builtInEntityAttributeTypeNames, directoryEntityType, disabled, disableManualCustomEntityAttributes, entityTypeName, includeServiceName }: AttributesProps) {
    const { scopeId } = useCustomRiskPolicyContext();
    const { propertyIdToConditionMap } = useMultiSelectorContext();
    const condition = propertyIdToConditionMap[EntityMultiSelectProperty.Attributes] as Contract.EntityAttributeMatchCondition;
    const setMultiSelectorContext = useSetMultiSelectorContext();
    const customEntityAttributeDefinitionModels = customEntityAttributeDefinitionModelStore.useGetAll();
    const [scopeCustomEntityAttributeDefinitionIds] =
        useMemo(
            () => {
                if (directoryEntityType) {
                    return [];
                }
                const scopeCustomEntityAttributeDefinitionIds =
                    _(customEntityAttributeDefinitionModels).
                        filter(
                            customEntityAttributeDefinitionModel =>
                                (customEntityAttributeDefinitionModel.configuration.scopeId === ScopeHelper.customerId ||
                                    customEntityAttributeDefinitionModel.configuration.scopeId === scopeId) &&
                                (!disableManualCustomEntityAttributes || !TypeHelper.extendOrImplement(customEntityAttributeDefinitionModel.configuration.typeName, Contract.TypeNames.ManualCustomEntityAttributeDefinitionConfiguration))).
                        map(customEntityAttributeDefinitionModel => customEntityAttributeDefinitionModel.configuration.id).
                        value();
                return [scopeCustomEntityAttributeDefinitionIds];
            },
            [customEntityAttributeDefinitionModels, scopeId]);
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const operatorTranslator = useEntityMatchConditionCollectionOperatorTranslator();
    const localization =
        useLocalization(
            "common.customRiskPolicy.entityMultiSelect.condition.attributes",
            () => ({
                text: {
                    part1: "*capitalize*{{translatedEntityTypeName}}** with",
                    part2: "of these labels"
                }
            }));

    return (
        <Stack
            alignItems="center"
            direction="row"
            spacing={1}>
            <Typography>
                {localization.text.part1({
                    translatedEntityTypeName:
                        entityTypeNameTranslator(
                            entityTypeName,
                            {
                                count: 0,
                                includeServiceName,
                                variant: "text"
                            })
                })}
            </Typography>
            <OperatorSelector
                operators={_.values(Contract.EntityMatchConditionCollectionOperator)}
                operatorTranslator={operatorTranslator}
                selectedOperator={condition.operator}
                onSelectedOperatorChanged={
                    (operator: Contract.EntityMatchConditionCollectionOperator) =>
                        setMultiSelectorContext(
                            context => ({
                                propertyIdToConditionMap: {
                                    ...context.propertyIdToConditionMap,
                                    [EntityMultiSelectProperty.Attributes]:
                                        new Contract.EntityAttributeMatchCondition(
                                            condition.builtInAttributeTypeNames,
                                            condition.customAttributeDefinitionIds,
                                            operator)
                                }
                            }))}/>
            <Typography>
                {localization.text.part2()}
            </Typography>
            <EntityAttributeDefinitionMultiSelect
                builtInEntityAttributeTypeNames={builtInEntityAttributeTypeNames}
                customEntityAttributeDefinitionIds={scopeCustomEntityAttributeDefinitionIds}
                disabled={disabled}
                selectedBuiltInEntityAttributeTypeNames={condition.builtInAttributeTypeNames}
                selectedCustomEntityAttributeDefinitionIds={condition.customAttributeDefinitionIds}
                variant={
                    _.isEmpty(condition.builtInAttributeTypeNames) &&
                    _.isEmpty(condition.customAttributeDefinitionIds)
                        ? "button"
                        : "icon"}
                onToggleBuiltInEntityAttributeItem={
                    async builtInEntityAttributeTypeNames =>
                        setMultiSelectorContext(
                            context => ({
                                propertyIdToConditionMap: {
                                    ...context.propertyIdToConditionMap,
                                    [EntityMultiSelectProperty.Attributes]:
                                        new Contract.EntityAttributeMatchCondition(
                                            _(condition.builtInAttributeTypeNames).
                                                intersection(builtInEntityAttributeTypeNames).
                                                isEmpty()
                                                ? _.concat(condition.builtInAttributeTypeNames, builtInEntityAttributeTypeNames)
                                                : _.without(condition.builtInAttributeTypeNames, ...builtInEntityAttributeTypeNames),
                                            condition.customAttributeDefinitionIds,
                                            condition.operator)
                                }
                            }))}
                onToggleCustomEntityAttributeItem={
                    async customEntityAttributeDefinitionId =>
                        setMultiSelectorContext(
                            context => ({
                                propertyIdToConditionMap: {
                                    ...context.propertyIdToConditionMap,
                                    [EntityMultiSelectProperty.Attributes]:
                                        new Contract.EntityAttributeMatchCondition(
                                            condition.builtInAttributeTypeNames,
                                            _.includes(condition.customAttributeDefinitionIds, customEntityAttributeDefinitionId)
                                                ? _.without(condition.customAttributeDefinitionIds, customEntityAttributeDefinitionId)
                                                : _.concat(condition.customAttributeDefinitionIds, customEntityAttributeDefinitionId),
                                            condition.operator)
                                }
                            }))}/>
            <EntityAttributeDefinitions
                builtInEntityAttributeTypeNames={condition.builtInAttributeTypeNames}
                customEntityAttributeDefinitionIds={condition.customAttributeDefinitionIds}/>
        </Stack>);
}