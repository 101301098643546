import { NumberFormatter } from "@infrastructure";
import { Typography } from "@mui/material";
import React from "react";
import { UdmObjectPropertyItemProps } from "../..";

export function InfraInteger({ item }: UdmObjectPropertyItemProps) {
    return (
        <Typography noWrap={true}>
            {NumberFormatter.humanize(item)}
        </Typography>);
}