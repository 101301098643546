//@ts-nocheck
import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";
import { Contract, EntitiesIcon, useTheme } from "../../../../common";

type GciEntityIconProps =
    SvgIconProps & {
        blurred: boolean;
        entityTypeName: string;
        variant?: string;
    };

export function GciEntityIcon({ blurred, entityTypeName, variant, ...props }: GciEntityIconProps) {
    switch (entityTypeName) {
        case Contract.TypeNames.GciPartialTenantEntity:
            return (
                <SvgIcon {...props} filter={blurred ? "url(#greyscale)" : undefined}>
                    <path d="M13.5 21.1C13.3 20.9 13.1 20.7 12.9 20.5C12.4 19.8 12 19.1 11.8 18.3C11.6 18.7 11.3 19.1 11 19.5C10.2 18.3 9.50001 17 9.10001 15.5H11.6C11.7 14.8 11.9 14.1 12.3 13.5H8.80001C8.70001 12.8 8.60001 12.2 8.60001 11.5C8.60001 10.8 8.70001 10.2 8.80001 9.50001H13.5C13.6 10.2 13.7 10.8 13.7 11.5C13.7 11.6 13.7 11.7 13.7 11.8C14.3 11.2 15 10.8 15.7 10.5C15.7 10.2 15.6 9.80001 15.6 9.50001H19C19 9.70001 19.1 9.90001 19.1 10.1C19.8 10.2 20.6 10.4 21.2 10.8C20.8 5.60001 16.5 1.60001 11.2 1.60001C5.70001 1.60001 1.20001 6.10001 1.20001 11.6C1.20001 17.1 5.70001 21.6 11.2 21.6C12 21.5 12.8 21.4 13.5 21.1C13.6 21.2 13.6 21.1 13.5 21.1ZM18 7.50001H15C14.7 6.30001 14.2 5.00001 13.6 3.90001C15.6 4.60001 17.1 5.90001 18 7.50001ZM11.1 3.50001C11.9 4.70001 12.6 6.00001 13 7.50001H9.20001C9.60001 6.10001 10.3 4.70001 11.1 3.50001ZM3.40001 13.5C3.20001 12.9 3.10001 12.2 3.10001 11.5C3.10001 10.8 3.20001 10.1 3.40001 9.50001H6.80001C6.70001 10.2 6.70001 10.8 6.70001 11.5C6.70001 12.2 6.80001 12.8 6.80001 13.5H3.40001ZM4.20001 15.5H7.20001C7.50001 16.7 8.00001 18 8.60001 19.1C6.70001 18.4 5.20001 17.2 4.20001 15.5ZM7.20001 7.50001H4.20001C5.20001 5.80001 6.70001 4.60001 8.50001 3.90001C7.90001 5.10001 7.50001 6.30001 7.20001 7.50001Z"  />
                    <path d="M15.5435 16.5002C15.5435 16.0047 15.68 15.5406 15.9171 15.1432V13.4575H14.2314C13.5624 14.3264 13.1997 15.3854 13.1997 16.5002C13.1997 17.615 13.5624 18.674 14.2314 19.5429H15.9171V17.8572C15.68 17.4598 15.5435 16.9956 15.5435 16.5002Z" fill="#FBBD00" />
                    <path d="M18.1997 19.156L17.0278 20.3279L18.1997 21.4998C19.3145 21.4998 20.3735 21.1371 21.2424 20.4681V18.7842H19.5585C19.1576 19.0222 18.6915 19.156 18.1997 19.156Z" fill="#0F9D58" />
                    <path d="M15.9167 17.8569L14.231 19.5426C14.3634 19.7147 14.5077 19.8794 14.6637 20.0355C15.6081 20.9799 16.8637 21.5 18.1993 21.5V19.1562C17.23 19.1562 16.3806 18.6343 15.9167 17.8569Z" fill="#31AA52" />
                    <path d="M23.1997 16.4995C23.1997 16.1953 23.1722 15.8906 23.1178 15.5937L23.0739 15.3535H18.1997V17.6973H20.5718C20.3415 18.1555 19.9873 18.5293 19.5585 18.7839L21.2424 20.4679C21.4144 20.3354 21.5791 20.1912 21.7352 20.0351C22.6796 19.0907 23.1997 17.8351 23.1997 16.4995Z" fill="#3C79E6" />
                    <path d="M20.078 14.6217L20.2851 14.8289L21.9424 13.1716L21.7353 12.9645C20.7909 12.0201 19.5353 11.5 18.1997 11.5L17.0278 12.6719L18.1997 13.8438C18.9092 13.8438 19.5763 14.12 20.078 14.6217Z" fill="#CF2D48" />
                    <path d="M18.1998 13.8438V11.5C16.8642 11.5 15.6086 12.0201 14.6642 12.9645C14.5081 13.1205 14.3639 13.2853 14.2314 13.4573L15.9171 15.143C16.3811 14.3656 17.2305 13.8438 18.1998 13.8438Z" fill="#EB4132" />
                </SvgIcon>);
        case Contract.TypeNames.GciDirectoryUser:
            return (
                <SvgIcon {...props} filter={blurred ? "url(#greyscale)" : undefined}>
                    <path d="M7 10C8.93298 10 10.5 8.43298 10.5 6.5C10.5 4.56702 8.93298 3 7 3C5.06702 3 3.5 4.56702 3.5 6.5C3.5 8.43298 5.06702 10 7 10Z"  />
                    <path d="M6 11C2.68629 11 0 13.6863 0 17V18.4C0 18.7314 0.26863 19 0.6 19H10.5759C10.526 18.6748 10.5 18.3408 10.5 18C10.5 16.0522 11.3488 14.3225 12.6653 13.2267C11.5653 11.8684 9.88406 11 8 11H6Z"  />
                    <path d="M14.8438 18.0001C14.8438 17.5047 14.9803 17.0406 15.2174 16.6432V14.9575H13.5317C12.8627 15.8264 12.5 16.8853 12.5 18.0001C12.5 19.115 12.8627 20.1739 13.5317 21.0428H15.2174V19.3571C14.9803 18.9597 14.8438 18.4956 14.8438 18.0001Z" fill="#FBBD00" />
                    <path d="M17.5 20.6563L16.3281 21.8282L17.5 23C18.6148 23 19.6738 22.6374 20.5427 21.9683V20.2844H18.8588C18.4579 20.5224 17.9918 20.6563 17.5 20.6563Z" fill="#0F9D58" />
                    <path d="M15.2174 19.3571L13.5317 21.0428C13.6642 21.2149 13.8082 21.3793 13.9643 21.5354C14.9086 22.4798 16.1645 23 17.5 23V20.6563C16.5308 20.6563 15.6813 20.1345 15.2174 19.3571Z" fill="#31AA52" />
                    <path d="M22.5 17.9998C22.5 17.6956 22.4725 17.3908 22.4181 17.094L22.3742 16.8538H17.5V19.1975H19.8721C19.6418 19.6557 19.2876 20.0298 18.8588 20.2844L20.5427 21.9683C20.7147 21.8359 20.8794 21.6914 21.0355 21.5353C21.9799 20.5909 22.5 19.3353 22.5 17.9998Z" fill="#3C79E6" />
                    <path d="M19.3783 16.1217L19.5854 16.3289L21.2427 14.6716L21.0355 14.4645C20.0912 13.5201 18.8356 13 17.5 13L16.3281 14.1719L17.5 15.3438C18.2095 15.3438 18.8765 15.62 19.3783 16.1217Z" fill="#CF2D48" />
                    <path d="M17.5 15.3438V13C16.1645 13 14.9091 13.5201 13.9647 14.4645C13.8086 14.6205 13.6642 14.7854 13.5317 14.9575L15.2174 16.6432C15.6813 15.8658 16.5308 15.3438 17.5 15.3438Z" fill="#EB4132" />
                </SvgIcon>);
        case Contract.TypeNames.GciDirectoryPartialUser:
            return (
                <SvgIcon {...props} filter={blurred ? "url(#greyscale)" : undefined}>
                    <path d="M7 10C8.93298 10 10.5 8.43298 10.5 6.5C10.5 4.56702 8.93298 3 7 3C5.06702 3 3.5 4.56702 3.5 6.5C3.5 8.43298 5.06702 10 7 10Z"  />
                    <path d="M6 11C2.68629 11 0 13.6863 0 17V18.4C0 18.7314 0.26863 19 0.6 19H10.5759C10.526 18.6748 10.5 18.3408 10.5 18C10.5 16.0522 11.3488 14.3225 12.6653 13.2267C11.5653 11.8684 9.88406 11 8 11H6Z"  />
                    <path d="M14.8438 18.0001C14.8438 17.5047 14.9803 17.0406 15.2174 16.6432V14.9575H13.5317C12.8627 15.8264 12.5 16.8853 12.5 18.0001C12.5 19.115 12.8627 20.1739 13.5317 21.0428H15.2174V19.3571C14.9803 18.9597 14.8438 18.4956 14.8438 18.0001Z" fill="#FBBD00" />
                    <path d="M17.5 20.6563L16.3281 21.8282L17.5 23C18.6148 23 19.6738 22.6374 20.5427 21.9683V20.2844H18.8588C18.4579 20.5224 17.9918 20.6563 17.5 20.6563Z" fill="#0F9D58" />
                    <path d="M15.2174 19.3571L13.5317 21.0428C13.6642 21.2149 13.8082 21.3793 13.9643 21.5354C14.9086 22.4798 16.1645 23 17.5 23V20.6563C16.5308 20.6563 15.6813 20.1345 15.2174 19.3571Z" fill="#31AA52" />
                    <path d="M22.5 17.9998C22.5 17.6956 22.4725 17.3908 22.4181 17.094L22.3742 16.8538H17.5V19.1975H19.8721C19.6418 19.6557 19.2876 20.0298 18.8588 20.2844L20.5427 21.9683C20.7147 21.8359 20.8794 21.6914 21.0355 21.5353C21.9799 20.5909 22.5 19.3353 22.5 17.9998Z" fill="#3C79E6" />
                    <path d="M19.3783 16.1217L19.5854 16.3289L21.2427 14.6716L21.0355 14.4645C20.0912 13.5201 18.8356 13 17.5 13L16.3281 14.1719L17.5 15.3438C18.2095 15.3438 18.8765 15.62 19.3783 16.1217Z" fill="#CF2D48" />
                    <path d="M17.5 15.3438V13C16.1645 13 14.9091 13.5201 13.9647 14.4645C13.8086 14.6205 13.6642 14.7854 13.5317 14.9575L15.2174 16.6432C15.6813 15.8658 16.5308 15.3438 17.5 15.3438Z" fill="#EB4132" />
                </SvgIcon>);
        case Contract.TypeNames.GciDirectorySpecialGroup:
            return (
                <SvgIcon {...props} filter={blurred ? "url(#greyscale)" : undefined}>
                    <path d="M4.63476 9.22423C6.35361 9.22423 7.74696 7.83087 7.74696 6.11202C7.74696 4.39335 6.35361 3 4.63476 3C2.91609 3 1.52273 4.39335 1.52273 6.11202C1.52273 7.83087 2.91609 9.22423 4.63476 9.22423Z" fill="#EF5350" />
                    <path d="M0.52725 16.6168L6.06041 16.6169C6.23056 15.3372 6.74966 14.1328 7.56115 13.1623C7.86283 12.8015 8.19851 12.4807 8.56097 12.2039C8.23144 11.7186 7.80043 11.3081 7.29554 11.0022C6.65462 10.6136 5.91923 10.4085 5.16977 10.4092H4.10344C3.01517 10.4092 1.97132 10.8414 1.20185 11.6111C0.432209 12.3806 -4.57764e-05 13.4242 -4.57764e-05 14.5127V16.0935C0.00206757 16.3832 0.237617 16.6168 0.52725 16.6168Z" fill="#EF5350" />
                    <path d="M15.3878 7.51654C15.3878 9.39609 13.8641 10.9198 11.9846 10.9198C10.105 10.9198 8.58133 9.39609 8.58133 7.51654C8.58133 5.63699 10.105 4.11328 11.9846 4.11328C13.8641 4.11328 15.3878 5.63699 15.3878 7.51654Z" fill="#EF5350" />
                    <path d="M14.8117 12.8615C14.457 13.0848 14.1276 13.348 13.8306 13.6471C13.2449 14.237 12.8025 14.9494 12.5361 15.731C11.935 16.2274 11.4801 16.8792 11.2263 17.6186C11.085 18.0301 11.0097 18.4579 11.0007 18.8871H7.56186C7.38973 18.8871 7.22445 18.8186 7.10273 18.6968C6.98101 18.5751 6.91268 18.41 6.91268 18.2379V17.074C6.91198 15.8185 7.37707 14.6075 8.21786 13.6751C9.05847 12.7429 10.2151 12.1555 11.4639 12.0268C12.6452 11.905 13.8295 12.2017 14.8117 12.8615Z" fill="#EF5350" />
                    <path d="M19.3942 15.5361H19.7501L20.7642 14.5413L20.814 14.119C20.2331 13.616 19.531 13.2666 18.7741 13.1038C18.0172 12.941 17.2305 12.9701 16.4883 13.1885C15.7461 13.4068 15.0729 13.8072 14.5322 14.3517C13.9916 14.8962 13.6014 15.5669 13.3985 16.3005C13.5115 16.2551 13.6367 16.2477 13.7544 16.2796L15.7826 15.9515C15.7826 15.9515 15.8858 15.7839 15.9392 15.7944C16.3737 15.3262 16.9743 15.0387 17.618 14.9907C18.2616 14.9426 18.8996 15.1378 19.4014 15.5361H19.3942Z" fill="#EF5350" />
                    <path d="M22.2092 16.2336C21.9761 15.3916 21.4975 14.6346 20.8321 14.0556L19.4088 15.4517C19.7054 15.6895 19.9431 15.9902 20.104 16.3312C20.2649 16.6722 20.3447 17.0446 20.3375 17.4203V17.6681C20.5039 17.6681 20.6686 17.7003 20.8223 17.7627C20.976 17.8252 21.1156 17.9167 21.2332 18.0321C21.3509 18.1475 21.4442 18.2844 21.5078 18.4352C21.5715 18.586 21.6043 18.7475 21.6043 18.9107C21.6043 19.0739 21.5715 19.2355 21.5078 19.3862C21.4442 19.537 21.3509 19.674 21.2332 19.7894C21.1156 19.9048 20.976 19.9963 20.8223 20.0587C20.6686 20.1212 20.5039 20.1533 20.3375 20.1533H17.804L17.5514 20.4046V21.895L17.804 22.1428H20.3375C21.045 22.1482 21.7354 21.9301 22.3067 21.5208C22.878 21.1114 23.2998 20.5325 23.5098 19.8698C23.7197 19.207 23.7065 18.4956 23.4723 17.8408C23.2381 17.1859 22.7952 16.6224 22.2092 16.2336Z" fill="#EF5350" />
                    <path d="M15.2666 22.1293H17.8001V20.1398H15.2666C15.0861 20.1398 14.9077 20.1017 14.7436 20.0281L14.3877 20.1363L13.3665 21.1311L13.2775 21.4801C13.8502 21.9043 14.549 22.1324 15.2666 22.1293Z" fill="#EF5350" />
                    <path d="M15.267 15.6757C14.5805 15.6797 13.9125 15.894 13.3562 16.2885C12.7999 16.683 12.3831 17.2381 12.164 17.8763C11.9449 18.5144 11.9345 19.2038 12.1342 19.848C12.3339 20.4923 12.7338 21.0593 13.2779 21.4698L14.7475 20.0282C14.5607 19.9455 14.3972 19.8195 14.271 19.6611C14.1448 19.5028 14.0597 19.3167 14.0229 19.119C13.9861 18.9212 13.9987 18.7177 14.0598 18.5258C14.1208 18.3339 14.2284 18.1594 14.3733 18.0172C14.5182 17.8751 14.6961 17.7696 14.8917 17.7097C15.0874 17.6498 15.2949 17.6374 15.4965 17.6735C15.6981 17.7096 15.8877 17.7931 16.0492 17.9169C16.2106 18.0407 16.3391 18.2011 16.4234 18.3843L17.893 16.9427C17.5846 16.5472 17.187 16.227 16.7314 16.0072C16.2759 15.7874 15.7747 15.6739 15.267 15.6757Z" fill="#EF5350" />
                </SvgIcon>);
        case Contract.TypeNames.GciDirectoryPartialGroup:
            return (
                <SvgIcon {...props} filter={blurred ? "url(#greyscale)" : undefined}>
                    <path d="M7.747 6.11202C7.747 7.83087 6.35365 9.22423 4.6348 9.22423C2.91613 9.22423 1.52278 7.83087 1.52278 6.11202C1.52278 4.39335 2.91613 3 4.6348 3C6.35365 3 7.747 4.39335 7.747 6.11202Z"  />
                    <path d="M6.06045 16.6169L0.527296 16.6168C0.237663 16.6168 0.00211334 16.3832 0 16.0935V14.5127C0 13.4242 0.432255 12.3806 1.20189 11.6111C1.97137 10.8414 3.01521 10.4092 4.10349 10.4092H5.16981C5.91928 10.4085 6.65467 10.6136 7.29559 11.0022C7.80047 11.3081 8.23148 11.7186 8.56102 12.2039C8.19855 12.4807 7.86287 12.8015 7.5612 13.1623C6.7497 14.1328 6.2306 15.3372 6.06045 16.6169Z"  />
                    <path d="M15.3879 7.51654C15.3879 9.39609 13.8642 10.9198 11.9846 10.9198C10.1051 10.9198 8.58137 9.39609 8.58137 7.51654C8.58137 5.63699 10.1051 4.11328 11.9846 4.11328C13.8642 4.11328 15.3879 5.63699 15.3879 7.51654Z"  />
                    <path d="M14.7353 12.8111C13.0991 13.8501 12.0003 15.7839 12.0003 18C12.0003 18.3014 12.0206 18.5976 12.0599 18.8871H7.5621C7.38997 18.8871 7.22469 18.8186 7.10297 18.6968C6.98125 18.5751 6.91292 18.41 6.91292 18.2379V17.074C6.91222 15.8185 7.37731 14.6075 8.2181 13.6751C9.0587 12.7429 10.2153 12.1555 11.4641 12.0268C12.6148 11.9081 13.7684 12.1866 14.7353 12.8111Z"  />
                    <path d="M16.3441 18.0001C16.3441 17.5047 16.4806 17.0406 16.7177 16.6432V14.9575H15.032C14.363 15.8264 14.0003 16.8853 14.0003 18.0001C14.0003 19.115 14.363 20.1739 15.032 21.0428H16.7177V19.3571C16.4806 18.9597 16.3441 18.4956 16.3441 18.0001Z" fill="#FBBD00" />
                    <path d="M19.0003 20.6563L17.8284 21.8282L19.0003 23C20.1151 23 21.1741 22.6374 22.043 21.9683V20.2844H20.3591C19.9582 20.5224 19.4921 20.6563 19.0003 20.6563Z" fill="#0F9D58" />
                    <path d="M16.7177 19.3571L15.032 21.0428C15.1645 21.2149 15.3085 21.3793 15.4646 21.5354C16.409 22.4798 17.6648 23 19.0003 23V20.6563C18.0311 20.6563 17.1816 20.1345 16.7177 19.3571Z" fill="#31AA52" />
                    <path d="M24.0003 17.9998C24.0003 17.6956 23.9728 17.3908 23.9184 17.094L23.8745 16.8538H19.0003V19.1975H21.3724C21.1421 19.6557 20.7879 20.0298 20.3591 20.2844L22.043 21.9683C22.215 21.8359 22.3798 21.6914 22.5359 21.5353C23.4802 20.5909 24.0003 19.3353 24.0003 17.9998Z" fill="#3C79E6" />
                    <path d="M20.8786 16.1217L21.0857 16.3289L22.743 14.6716L22.5359 14.4645C21.5915 13.5201 20.3359 13 19.0003 13L17.8284 14.1719L19.0003 15.3438C19.7098 15.3438 20.3769 15.62 20.8786 16.1217Z" fill="#CF2D48" />
                    <path d="M19.0003 15.3438V13C17.6648 13 16.4094 13.5201 15.465 14.4645C15.3089 14.6205 15.1645 14.7854 15.032 14.9575L16.7177 16.6432C17.1817 15.8658 18.0311 15.3438 19.0003 15.3438Z" fill="#EB4132" />
                </SvgIcon>);
        case Contract.TypeNames.GciDirectoryGroup:
            return (
                <SvgIcon {...props} filter={blurred ? "url(#greyscale)" : undefined}>
                    <path d="M7.747 6.11202C7.747 7.83087 6.35365 9.22423 4.6348 9.22423C2.91613 9.22423 1.52278 7.83087 1.52278 6.11202C1.52278 4.39335 2.91613 3 4.6348 3C6.35365 3 7.747 4.39335 7.747 6.11202Z"  />
                    <path d="M6.06045 16.6169L0.527296 16.6168C0.237663 16.6168 0.00211334 16.3832 0 16.0935V14.5127C0 13.4242 0.432255 12.3806 1.20189 11.6111C1.97137 10.8414 3.01521 10.4092 4.10349 10.4092H5.16981C5.91928 10.4085 6.65467 10.6136 7.29559 11.0022C7.80047 11.3081 8.23148 11.7186 8.56102 12.2039C8.19855 12.4807 7.86287 12.8015 7.5612 13.1623C6.7497 14.1328 6.2306 15.3372 6.06045 16.6169Z"  />
                    <path d="M15.3879 7.51654C15.3879 9.39609 13.8642 10.9198 11.9846 10.9198C10.1051 10.9198 8.58137 9.39609 8.58137 7.51654C8.58137 5.63699 10.1051 4.11328 11.9846 4.11328C13.8642 4.11328 15.3879 5.63699 15.3879 7.51654Z"  />
                    <path d="M14.7353 12.8111C13.0991 13.8501 12.0003 15.7839 12.0003 18C12.0003 18.3014 12.0206 18.5976 12.0599 18.8871H7.5621C7.38997 18.8871 7.22469 18.8186 7.10297 18.6968C6.98125 18.5751 6.91292 18.41 6.91292 18.2379V17.074C6.91222 15.8185 7.37731 14.6075 8.2181 13.6751C9.0587 12.7429 10.2153 12.1555 11.4641 12.0268C12.6148 11.9081 13.7684 12.1866 14.7353 12.8111Z"  />
                    <path d="M16.3441 18.0001C16.3441 17.5047 16.4806 17.0406 16.7177 16.6432V14.9575H15.032C14.363 15.8264 14.0003 16.8853 14.0003 18.0001C14.0003 19.115 14.363 20.1739 15.032 21.0428H16.7177V19.3571C16.4806 18.9597 16.3441 18.4956 16.3441 18.0001Z" fill="#FBBD00" />
                    <path d="M19.0003 20.6563L17.8284 21.8282L19.0003 23C20.1151 23 21.1741 22.6374 22.043 21.9683V20.2844H20.3591C19.9582 20.5224 19.4921 20.6563 19.0003 20.6563Z" fill="#0F9D58" />
                    <path d="M16.7177 19.3571L15.032 21.0428C15.1645 21.2149 15.3085 21.3793 15.4646 21.5354C16.409 22.4798 17.6648 23 19.0003 23V20.6563C18.0311 20.6563 17.1816 20.1345 16.7177 19.3571Z" fill="#31AA52" />
                    <path d="M24.0003 17.9998C24.0003 17.6956 23.9728 17.3908 23.9184 17.094L23.8745 16.8538H19.0003V19.1975H21.3724C21.1421 19.6557 20.7879 20.0298 20.3591 20.2844L22.043 21.9683C22.215 21.8359 22.3798 21.6914 22.5359 21.5353C23.4802 20.5909 24.0003 19.3353 24.0003 17.9998Z" fill="#3C79E6" />
                    <path d="M20.8786 16.1217L21.0857 16.3289L22.743 14.6716L22.5359 14.4645C21.5915 13.5201 20.3359 13 19.0003 13L17.8284 14.1719L19.0003 15.3438C19.7098 15.3438 20.3769 15.62 20.8786 16.1217Z" fill="#CF2D48" />
                    <path d="M19.0003 15.3438V13C17.6648 13 16.4094 13.5201 15.465 14.4645C15.3089 14.6205 15.1645 14.7854 15.032 14.9575L16.7177 16.6432C17.1817 15.8658 18.0311 15.3438 19.0003 15.3438Z" fill="#EB4132" />
                </SvgIcon>);
        case Contract.TypeNames.GciTenantEntity:
            return (
                <SvgIcon {...props} filter={blurred ? "url(#greyscale)" : undefined}>
                    <path d="M13.5 21.1C13.3 20.9 13.1 20.7 12.9 20.5C12.4 19.8 12 19.1 11.8 18.3C11.6 18.7 11.3 19.1 11 19.5C10.2 18.3 9.50001 17 9.10001 15.5H11.6C11.7 14.8 11.9 14.1 12.3 13.5H8.80001C8.70001 12.8 8.60001 12.2 8.60001 11.5C8.60001 10.8 8.70001 10.2 8.80001 9.50001H13.5C13.6 10.2 13.7 10.8 13.7 11.5C13.7 11.6 13.7 11.7 13.7 11.8C14.3 11.2 15 10.8 15.7 10.5C15.7 10.2 15.6 9.80001 15.6 9.50001H19C19 9.70001 19.1 9.90001 19.1 10.1C19.8 10.2 20.6 10.4 21.2 10.8C20.8 5.60001 16.5 1.60001 11.2 1.60001C5.70001 1.60001 1.20001 6.10001 1.20001 11.6C1.20001 17.1 5.70001 21.6 11.2 21.6C12 21.5 12.8 21.4 13.5 21.1C13.6 21.2 13.6 21.1 13.5 21.1ZM18 7.50001H15C14.7 6.30001 14.2 5.00001 13.6 3.90001C15.6 4.60001 17.1 5.90001 18 7.50001ZM11.1 3.50001C11.9 4.70001 12.6 6.00001 13 7.50001H9.20001C9.60001 6.10001 10.3 4.70001 11.1 3.50001ZM3.40001 13.5C3.20001 12.9 3.10001 12.2 3.10001 11.5C3.10001 10.8 3.20001 10.1 3.40001 9.50001H6.80001C6.70001 10.2 6.70001 10.8 6.70001 11.5C6.70001 12.2 6.80001 12.8 6.80001 13.5H3.40001ZM4.20001 15.5H7.20001C7.50001 16.7 8.00001 18 8.60001 19.1C6.70001 18.4 5.20001 17.2 4.20001 15.5ZM7.20001 7.50001H4.20001C5.20001 5.80001 6.70001 4.60001 8.50001 3.90001C7.90001 5.10001 7.50001 6.30001 7.20001 7.50001Z"  />
                    <path d="M15.5435 16.5002C15.5435 16.0047 15.68 15.5406 15.9171 15.1432V13.4575H14.2314C13.5624 14.3264 13.1997 15.3854 13.1997 16.5002C13.1997 17.615 13.5624 18.674 14.2314 19.5429H15.9171V17.8572C15.68 17.4598 15.5435 16.9956 15.5435 16.5002Z" fill="#FBBD00" />
                    <path d="M18.1997 19.156L17.0278 20.3279L18.1997 21.4998C19.3145 21.4998 20.3735 21.1371 21.2424 20.4681V18.7842H19.5585C19.1576 19.0222 18.6915 19.156 18.1997 19.156Z" fill="#0F9D58" />
                    <path d="M15.9167 17.8569L14.231 19.5426C14.3634 19.7147 14.5077 19.8794 14.6637 20.0355C15.6081 20.9799 16.8637 21.5 18.1993 21.5V19.1562C17.23 19.1562 16.3806 18.6343 15.9167 17.8569Z" fill="#31AA52" />
                    <path d="M23.1997 16.4995C23.1997 16.1953 23.1722 15.8906 23.1178 15.5937L23.0739 15.3535H18.1997V17.6973H20.5718C20.3415 18.1555 19.9873 18.5293 19.5585 18.7839L21.2424 20.4679C21.4144 20.3354 21.5791 20.1912 21.7352 20.0351C22.6796 19.0907 23.1997 17.8351 23.1997 16.4995Z" fill="#3C79E6" />
                    <path d="M20.078 14.6217L20.2851 14.8289L21.9424 13.1716L21.7353 12.9645C20.7909 12.0201 19.5353 11.5 18.1997 11.5L17.0278 12.6719L18.1997 13.8438C18.9092 13.8438 19.5763 14.12 20.078 14.6217Z" fill="#CF2D48" />
                    <path d="M18.1998 13.8438V11.5C16.8642 11.5 15.6086 12.0201 14.6642 12.9645C14.5081 13.1205 14.3639 13.2853 14.2314 13.4573L15.9171 15.143C16.3811 14.3656 17.2305 13.8438 18.1998 13.8438Z" fill="#EB4132" />
                </SvgIcon>);
    }

    return <EntitiesIcon {...props} filter={blurred ? "url(#greyscale)" : undefined}/>;
}