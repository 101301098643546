import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition, useGetAzureKeyVaultVaultRiskContext } from "../../..";
import { Contract, Entity, entityModelStore, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { EntityExternalConsoleLink } from "../../components";

export function useAzureKeyVaultVaultSoftDeleteDisabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AzureKeyVaultVaultSoftDeleteDisabledRisk;
    const vaultModel = entityModelStore.useGet(risk.entityId) as Contract.AzureKeyVaultVaultModel;
    const vault = vaultModel.entity as Contract.AzureKeyVaultVault;

    const getAzureKeyVaultVaultRiskContext = useGetAzureKeyVaultVaultRiskContext();

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.compliance.useAzureKeyVaultVaultSoftDeleteDisabledRiskDefinition",
            () => ({
                contextItems:
                    {
                        purgeProtectionDisabled: "The {{translatedVaultTypeName}} has **Purge Protection** disabled"
                    },
                description: "{{vault}} delete protection is not enabled",
                sections: {
                    resolution: {
                        step1: "Enable **Soft delete**",
                        step2: "Enable **Purge protection**",
                        step3: "Click the **Save** button on top to confirm"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            vault:
                <Entity
                    entityIdOrModel={vaultModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={risk.entityId}
                key={risk.entityId}
                page={Contract.AzureConsoleEntityPage.Properties}/>,
            _.isNil(vault.softDelete)
                ? localization.sections.resolution.step1()
                : undefined,
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3()
        ],
        riskModel,
        () => {
            const vaultRiskContext = getAzureKeyVaultVaultRiskContext(vaultModel);
            return [
                vaultRiskContext.generalInformation,
                vaultRiskContext.sensitive,
                vaultRiskContext.vaultObjectsExist,
                new RiskDefinitionContextItem(
                    localization.contextItems.purgeProtectionDisabled({
                        translatedVaultTypeName:
                            entityTypeNameTranslator(
                                vault.typeName,
                                {
                                    includeServiceName: false,
                                    variant: "text"
                                })
                    })),
                vaultRiskContext.softDeleteEnabled,
                vaultRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        });
}