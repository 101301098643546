﻿import { PagedValuesFilter } from "@infrastructure";
import { Typography } from "@mui/material";
import React from "react";
import { Contract } from "../controllers";
import { ElasticsearchItemPageHelper, ResourceTagData, ResourceTagHelper } from "../utilities";

type PagedEntityResourceTagFilterProps = {
    emptyValue?: boolean;
    entityTypeName?: string;
    placeholder: string;
};

export function PagedEntityResourceTagFilter({ emptyValue = true, entityTypeName, placeholder }: PagedEntityResourceTagFilterProps) {
    return (
        <PagedValuesFilter
            emptyValueOptions={{ enabled: emptyValue }}
            getValuePage={
                ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                    entityTypeName,
                    Contract.EntityModelProperty.ResourceTags,
                    ResourceTagData.createId)}
            placeholder={placeholder}>
            {(inline, resourceTag: Contract.ResourceTag) =>
                <Typography>
                    {ResourceTagHelper.getDisplayName(resourceTag)}
                </Typography>}
        </PagedValuesFilter>);
}