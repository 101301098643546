import { SxProps, Typography } from "@mui/material";
import { TOptions } from "i18next";
import React, { useMemo } from "react";
import { Contract } from "../../../../..";
import { InlineEntityMultiSelection } from "../../";
import { InlineActionsSelection } from "../../InlineActionsSelection";

type ViewProps = {
    riskPolicyConfiguration?: Contract.AwsPrincipalNotAllowedResourcePermissionRiskPolicyConfiguration;
    sx?: SxProps;
    templateTranslator: (options?: TOptions) => string;
};

export function View({ riskPolicyConfiguration, sx, templateTranslator }: ViewProps) {
    const [principalExcludeMatchConditions, principalMatchConditions, resourceExcludeMatchConditions, resourceMatchConditions] =
        useMemo(
            () => [
                riskPolicyConfiguration?.excludePrincipalMatchConditions ?? [],
                riskPolicyConfiguration?.principalMatchConditions ?? [],
                riskPolicyConfiguration?.excludeResourceMatchConditions ?? [],
                riskPolicyConfiguration?.resourceMatchConditions ?? []
            ],
            [riskPolicyConfiguration]);
    return (
        <Typography
            component="span"
            sx={sx}>
            {templateTranslator({
                actions:
                    <InlineActionsSelection
                        selection={riskPolicyConfiguration}
                        sx={sx}/>,
                principals:
                    <InlineEntityMultiSelection
                        conditions={principalMatchConditions}
                        entityLinkOptions={{ disabled: true }}
                        entityTypeName={Contract.TypeNames.AwsIamPrincipal}
                        excludeConditions={principalExcludeMatchConditions}
                        sx={sx}/>,
                resources:
                    <InlineEntityMultiSelection
                        conditions={resourceMatchConditions}
                        entityLinkOptions={{ disabled: true }}
                        entityTypeName={Contract.TypeNames.AwsResource}
                        excludeConditions={resourceExcludeMatchConditions}
                        sx={sx}/>
            })}
        </Typography>);
}