import { NotValidIcon, UnexpectedError } from "@infrastructure";
import React from "react";
import { Contract } from "../../..";
import { CancelledIcon, ExpiredIcon, RejectedIcon } from "./icons";

type PermissionRequestCloseReasonIconProps = {
    closeReason: Contract.PermissionRequestCloseReason;
};

export function PermissionRequestCloseReasonIcon({ closeReason }: PermissionRequestCloseReasonIconProps) {
    switch (closeReason) {
        case Contract.PermissionRequestCloseReason.ActivationFailed:
            return <NotValidIcon/>;
        case Contract.PermissionRequestCloseReason.Cancelled:
            return <CancelledIcon/>;
        case Contract.PermissionRequestCloseReason.Expired:
        case Contract.PermissionRequestCloseReason.RequestExpired:
        case Contract.PermissionRequestCloseReason.Revoked:
            return <ExpiredIcon/>;
        case Contract.PermissionRequestCloseReason.Rejected:
            return <RejectedIcon/>;
        default:
            throw new UnexpectedError("closeReason", closeReason);
    }
}