﻿import { StorageHelper } from "./storageHelper";

export class AccessHelper {
    public static resetDashboardStorageItems() {
        const resetItems = [
            StorageHelper.customerAccessDashboardExcessivePermissionTrendsWidgetInactivePrincipalRiskCategoryChartRiskCategories,
            StorageHelper.customerAccessDashboardExcessivePermissionTrendsWidgetExcessivePermissionPrincipalRiskCategoryChartRiskCategories
        ];

        for (const item of resetItems) {
            item.removeValue();
        }
    }
}