import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition, useGetAwsEcrRepositoryRiskContext } from "../../..";
import { Contract, Entity, entityModelStore, tenantModelStore } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { RiskDefinitionSection } from "../../../../../../utilities";
import { PolicySection } from "../../components";

export function useAwsEcrRepositoryPublicAccessExistsRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsEcrRepositoryPublicAccessExistsRisk;
    const repositoryModel = entityModelStore.useGet(risk.entityId) as Contract.AwsEcrRepositoryModel;
    const repository = repositoryModel.entity as Contract.AwsEcrRepository;
    const tenantModel = tenantModelStore.useGet(risk.tenantId);

    const getAwsEcrRepositoryRiskContext = useGetAwsEcrRepositoryRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsEcrRepositoryPublicAccessExistsRiskDefinition",
            () => ({
                description: "{{repository}} is publicly accessible via resource policy",
                sections: {
                    policy: "Policy",
                    resolution: {
                        step1: "Disabling public access might break identities using the repository. Before changing the repository’s policy, verify that identities using the key can access it without public access",
                        step2: "Click **Edit policy JSON**  and change the policy statements that allows access to any AWS principal",
                        step3: "Click **Save** to confirm"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            repository:
                <Entity
                    entityIdOrModel={repositoryModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.Ecr,
                AwsConsoleUrlBuilder.getEcrRepositoryEditPermissionsUrl(
                    repository,
                    (tenantModel.configuration as Contract.AwsTenantConfiguration).rawId)),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3()
        ],
        riskModel,
        () => {
            const repositoryRiskContext = getAwsEcrRepositoryRiskContext(repositoryModel);
            return [
                repositoryRiskContext.generalInformation,
                repositoryRiskContext.sensitive,
                repositoryRiskContext.encryption,
                repositoryRiskContext.containerImages,
                repositoryRiskContext.criticalVulnerabilityContainerImages,
                repositoryRiskContext.publicAccessPermissionActions,
                repositoryRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        },
        {
            sections: [
                new RiskDefinitionSection(
                    <PolicySection policyDocument={repository.policyDocument}/>,
                    localization.sections.policy(),
                    {
                        expandable: true
                    })
            ]
        });
}