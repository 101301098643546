import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, EnumValuesFilter, InlineItems, Optional, optionalTableCell, useLocalization } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { useGciDirectoryGroupTypeTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";
import { useGciCommonDirectoryPrincipalDefinition } from "./useGciCommonDirectoryPrincipalDefinition";

export function useGciDirectoryGroupDefinition(definitionData: DefinitionData) {
    const commonDirectoryPrincipalDefinition = useGciCommonDirectoryPrincipalDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const directoryGroupTypeTranslator = useGciDirectoryGroupTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.gci.useGciDirectoryGroupDefinition",
            () => ({
                columns: {
                    memberPrincipalIds: "Members",
                    type: "Group Types"
                }
            }));
    return new EntityTableDefinition(
        [
            commonDirectoryPrincipalDefinition.columns.tenantColumn,
            commonDirectoryPrincipalDefinition.columns.emailColumn,
            commonDirectoryPrincipalDefinition.columns.creationTime,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.GciDirectoryGroupModel) => ({
                            [localization.columns.type()]:
                                _((item.entity as Contract.GciDirectoryGroup).types).
                                    map(type => directoryGroupTypeTranslator(type)).
                                    join("\n")
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.GciDirectoryGroupType}
                                enumTypeTranslator={directoryGroupTypeTranslator}
                                placeholder={localization.columns.type()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GciDirectoryGroupTypes}
                key={Contract.EntityModelProperty.GciDirectoryGroupTypes}
                render={
                    optionalTableCell<Contract.GciDirectoryGroupModel>(
                        (item: Contract.GciDirectoryGroupModel) =>
                            <InlineItems
                                items={
                                    _.map(
                                        (item.entity as Contract.GciDirectoryGroup).types,
                                        type => directoryGroupTypeTranslator(type))}
                                variant="itemPlusItemCount"/>)}
                title={localization.columns.type()}/>,
            commonDirectoryPrincipalDefinition.columns.gcpRoleBindingIdsColumn,
            commonDirectoryPrincipalDefinition.columns.gcpRoleIdsFilterColumn,
            commonDirectoryPrincipalDefinition.columns.containedGcpRoleBindingIdsColumn,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.GciDirectoryGroupModel>(
                        Contract.TypeNames.GciDirectoryPrincipal,
                        item => item.memberPrincipalIds,
                        localization.columns.memberPrincipalIds())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityTypeFilter(
                                        Contract.TypeNames.IGciDirectoryGroupMemberPrincipal,
                                        true)}
                                placeholder={localization.columns.memberPrincipalIds()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GciDirectoryGroupMemberPrincipals}
                key={Contract.EntityModelProperty.GciDirectoryGroupMemberPrincipals}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.GciDirectoryGroupModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.memberPrincipalIds}
                            entityTypeName={Contract.TypeNames.IGciPrincipal}
                            entityVariant="iconText"/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.memberPrincipalIds()}/>,
            commonDirectoryPrincipalDefinition.columns.attributesColumn,
            commonDirectoryPrincipalDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonDirectoryPrincipalDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonDirectoryPrincipalDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageGciDirectoryGroupRequest(
                new Contract.EntityControllerGetEntityModelPageGciDirectoryGroupRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    filterMap[Contract.EntityModelProperty.GciPrincipalContainedGcpRoleBindings] as Optional<boolean>,
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.GciDirectoryPrincipalCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GciDirectoryPrincipalGcpRoles]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GciDirectoryPrincipalMail]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GciDirectoryGroupMemberPrincipals]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.GciDirectoryGroupTypes])),
                limit,
                skip,
                requestSort));
}