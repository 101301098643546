﻿import { useLocalization } from "@infrastructure";
import { Contract } from "../../common";

export function useKubernetesServiceTypeTranslator() {
    const localization =
        useLocalization(
            "common.hooks.useKubernetesServiceTypeTranslator",
            () => ({
                [Contract.TypeNames.KubernetesServiceDataType]: {
                    [Contract.KubernetesServiceDataType.ClusterIpAddress]: "Cluster IP",
                    [Contract.KubernetesServiceDataType.ExternalName]: "External Name",
                    [Contract.KubernetesServiceDataType.LoadBalancer]: "Load Balancer",
                    [Contract.KubernetesServiceDataType.NodePort]: "Node Port"
                }
            }));
    return (type: Contract.KubernetesServiceDataType) => localization[Contract.TypeNames.KubernetesServiceDataType][type]();
}