import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function GcpServiceSubnetIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                d="M4.50014 9.99982L5.00014 7.49982L7.00014 5.99982H9.50014L11.3126 6.99982L13.5001 5.49982H16.5001L19.5001 6.99982L24.0001 13.9998L21.5001 18.4998H3.50014L0.000140905 15.9998L1.00014 12.4998L2.00014 10.4998L4.50014 9.99982Z"
                fill="white"/>
            <path d="M19.3303 10.6974C19.2712 8.90397 17.8 7.46809 15.9927 7.46606C15.8184 7.46606 15.6771 7.60736 15.6771 7.78167C15.6771 7.95599 15.8184 8.09733 15.9927 8.09733C17.4544 8.09897 18.6451 9.2573 18.699 10.7063L18.7009 10.8055L18.7038 10.8483C18.7247 11.0024 18.8567 11.1211 19.0165 11.1211L19.0593 11.1182C19.2134 11.0973 19.3321 10.9653 19.3317 10.8049L19.3303 10.6974Z"/>
            <path
                clipRule="evenodd"
                d="M17.3642 5.40111C15.4046 4.66719 13.2287 4.95441 11.5233 6.14795L11.3513 6.27267L11.3406 6.26453C10.0181 5.2834 8.268 5.10857 6.776 5.82821L6.656 5.88832C5.2696 6.60907 4.3525 8.00731 4.2175 9.5762L4.209 9.6917L4.132 9.6971C1.8179 9.8889 0 11.867 0 14.2767C0 16.8133 2.0142 18.8716 4.5013 18.8716L19.4987 18.8714C21.6391 18.8725 23.4828 17.3341 23.9092 15.1947L23.9325 15.0689C24.2773 13.0571 23.2766 11.0574 21.4892 10.1594L21.4194 10.1259L21.3885 9.9895C20.8796 7.87906 19.3757 6.15448 17.3642 5.40111ZM11.559 6.94878C13.1318 5.66384 15.2444 5.31256 17.1325 6.0197C19.0213 6.7271 20.4159 8.39317 20.8058 10.4184L20.8387 10.5895L20.9986 10.6589C22.6767 11.3876 23.6277 13.2283 23.2614 15.0656C22.8954 16.9017 21.3184 18.2118 19.4987 18.2108L4.5013 18.211C2.387 18.211 0.6606 16.4565 0.6606 14.2767C0.6606 12.097 2.387 10.3424 4.5013 10.3424H4.8608V9.9825C4.8595 8.45605 5.7203 7.07086 7.063 6.42317C8.4046 5.77602 9.9912 5.97848 11.1371 6.94543L11.3467 7.12231L11.559 6.94878Z"
                fillRule="evenodd"/>
            <path d="M13.9854 10.9326L14.7677 10.1757L14.8197 9.8571C13.3942 8.60269 11.1282 8.74509 9.8428 10.1538C9.4857 10.5449 9.2211 11.0328 9.0799 11.5368L9.36 11.4984L10.9246 11.2489L11.0452 11.1295C11.7411 10.3899 12.9182 10.2904 13.7215 10.9196L13.9854 10.9326Z"/>
            <path d="M15.8823 11.514C15.7026 10.8733 15.333 10.2977 14.8197 9.8571L13.7215 10.9196C14.1853 11.2861 14.4494 11.8375 14.4383 12.4168V12.6052C14.9778 12.6052 15.4154 13.0286 15.4154 13.5506C15.4154 14.0726 14.9778 14.4857 14.4383 14.4857H12.4812L12.2892 14.6876V15.8218L12.4812 15.9999H14.4383C15.8419 16.0107 16.9888 14.9286 16.9999 13.5706C17.0067 12.7472 16.5869 11.9753 15.8823 11.514Z"/>
            <path d="M10.5269 16L12.4812 15.9999V14.4857L10.5269 14.4861C10.3884 14.4861 10.252 14.4569 10.1262 14.4013L9.8489 14.4834L9.061 15.2404L8.9924 15.4979C9.4343 15.8207 9.9735 16.0023 10.5269 16Z"/>
            <path d="M10.5267 11.0892C9.1228 11.0973 7.9917 12.205 8 13.5635C8.0048 14.3219 8.3711 15.0354 8.9924 15.4979L10.1262 14.4013C9.6343 14.1863 9.4155 13.6264 9.6377 13.1504C9.8599 12.6745 10.4387 12.463 10.9306 12.6779C11.1472 12.7727 11.321 12.9405 11.419 13.1504L12.5528 12.0534C12.0704 11.4431 11.3206 11.0865 10.5267 11.0892Z"/>
        </SvgIcon>);
}