import { Message, useChangeEffect, useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { useEffect, useState } from "react";
import { RadioGroup } from "../../../../../../../../../../../../common";
import { useAddOrEditContext, useSetAddOrEditContext } from "../../../../../../AddOrEdit";
import { AwsSsoPermissionSetAssignmentEligibilityData, AwsSsoPermissionSetAssignmentEligibilityDataPermissions, AwsSsoPermissionSetAssignmentEligibilityDataPolicyPermissions } from "../../utilities";
import { PermissionSetPermissions, PolicyPermissions } from "./components";

export function Permissions() {
    const { permissionEligibilityData, upsertPermissionEligibilityExecuting } = useAddOrEditContext();
    const setAddOrEditContext = useSetAddOrEditContext();
    const { principalTenantId } = permissionEligibilityData;

    const [type, setType] =
        useState(
            _.isNil(permissionEligibilityData) ||
                (permissionEligibilityData as AwsSsoPermissionSetAssignmentEligibilityData).permissions instanceof AwsSsoPermissionSetAssignmentEligibilityDataPolicyPermissions
                ? PermissionsType.Policies
                : PermissionsType.ExistingPermissionSet);

    const localization =
        useLocalization(
            "views.user.permissionEligibilities.addOrEdit.hooks.useDefinition.hooks.useAwsDefinition.permissions",
            () => ({
                title: "Permissions",
                tooltip: "Doesn't include customer managed policies or permissions boundaries",
                type: {
                    [PermissionsType.ExistingPermissionSet]: "Permission set",
                    [PermissionsType.Policies]: "Managed policies"
                }
            }));


    const [typeToPermissionsMap, setTypeToPermissions] = useState<Dictionary<AwsSsoPermissionSetAssignmentEligibilityDataPermissions>>({});
    useChangeEffect(
        () => {
            setAddOrEditContext(
                addOrEditContext => {
                    (addOrEditContext.permissionEligibilityData as AwsSsoPermissionSetAssignmentEligibilityData).permissions = typeToPermissionsMap[type];
                    return { ...addOrEditContext };
                });
        },
        [type, typeToPermissionsMap]);

    const [typeToValidMap, setTypeToValidMap] = useState<Dictionary<boolean>>({});
    useEffect(
        () => {
            setAddOrEditContext(
                addOrEditContext => ({
                    ...addOrEditContext,
                    fieldNameToValidMap: {
                        ...addOrEditContext.fieldNameToValidMap,
                        awsPermissions: typeToValidMap[type]
                    }
                }));
        },
        [type, typeToValidMap]);

    const disabled = _.isNil(principalTenantId) || upsertPermissionEligibilityExecuting;
    return (
        <Stack spacing={1.5}>
            <Typography variant="h4">
                {localization.title()}
            </Typography>
            <RadioGroup
                items={[
                    {
                        children:
                            <PolicyPermissions
                                disabled={disabled}
                                onChange={
                                    permissions =>
                                        setTypeToPermissions(
                                            typeToPermissionsMap => ({
                                                ...typeToPermissionsMap,
                                                [PermissionsType.Policies]: permissions
                                            }))}
                                onValidChange={
                                    valid =>
                                        setTypeToValidMap(
                                            typeToValidMap => ({
                                                ...typeToValidMap,
                                                [PermissionsType.Policies]: valid
                                            }))}/>,
                        childrenVariant: "hidden",
                        disabled,
                        label: localization.type[PermissionsType.Policies](),
                        value: PermissionsType.Policies
                    },
                    {
                        children:
                            <PermissionSetPermissions
                                disabled={disabled}
                                onChange={
                                    permissions =>
                                        setTypeToPermissions(
                                            typeToPermissionsMap => ({
                                                ...typeToPermissionsMap,
                                                [PermissionsType.ExistingPermissionSet]: permissions
                                            }))}
                                onValidChange={
                                    valid =>
                                        setTypeToValidMap(
                                            typeToValidMap => ({
                                                ...typeToValidMap,
                                                [PermissionsType.ExistingPermissionSet]: valid
                                            }))}/>,
                        childrenVariant: "hidden",
                        disabled,
                        label:
                            <Stack
                                alignItems="center"
                                direction="row"
                                spacing={1}>
                                <Typography>
                                    {localization.type[PermissionsType.ExistingPermissionSet]()}
                                </Typography>
                                <Message
                                    disabled={disabled}
                                    level="info"
                                    title={localization.tooltip()}
                                    variant="minimal"/>
                            </Stack>,
                        value: PermissionsType.ExistingPermissionSet
                    }
                ]}
                selectedValue={type}
                onChange={value => setType(value)}/>
        </Stack>);
}

enum PermissionsType {
    ExistingPermissionSet = "existingPermissionSet",
    Policies = "policies"
}

export type PermissionsProps = {
    disabled: boolean;
    onChange: (permissions: AwsSsoPermissionSetAssignmentEligibilityDataPermissions) => void;
    onValidChange: (valid: boolean) => void;
};