import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { EntityExternalConsoleLink } from "../../components";
import { useGetAzureStorageStorageAccountRiskContext } from "../contexts";

export function useAzureStorageStorageAccountAllowAnyAzureTrustedServicesNetworkAccessDisabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AzureStorageStorageAccountAllowAnyAzureTrustedServicesNetworkAccessDisabledRisk;
    const storageAccountModel = entityModelStore.useGet(risk.entityId) as Contract.AzureStorageStorageAccountModel;

    const getAzureStorageStorageAccountRiskContext = useGetAzureStorageStorageAccountRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.compliance.useAzureStorageStorageAccountAllowAnyAzureTrustedServicesNetworkAccessDisabledRiskDefinition",
            () => ({
                description: "{{storageAccount}} Azure trusted services access is not enabled",
                sections: {
                    resolution: {
                        step1: "Run the following command from Azure CLI:  az storage account update --name {{storageAccountName}} --bypass AzureServices"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            storageAccount:
                <Entity
                    entityIdOrModel={storageAccountModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={risk.entityId}
                key={risk.entityId}
                page={Contract.AzureConsoleEntityPage.Networking}/>,
            localization.sections.resolution.step1({
                storageAccountName: (storageAccountModel.entity as Contract.AzureStorageStorageAccount).name
            })
        ],
        riskModel,
        () => {
            const storageAccountRiskContext = getAzureStorageStorageAccountRiskContext(storageAccountModel);
            return [
                storageAccountRiskContext.generalInformation,
                storageAccountRiskContext.inboundExternalAccessScope,
                storageAccountRiskContext.childResources,
                storageAccountRiskContext.sensitive,
                storageAccountRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}