﻿import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../../../../../../../common";

export function useCodeServerModelStatusTranslator() {
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCodeServerItems.hooks.useCodeServerModelStatusTranslator",
            () => ({
                [Contract.TypeNames.CodeServerModelStatus]: {
                    [Contract.CodeServerModelStatus.Deleting]: "Deleting...",
                    [Contract.CodeServerModelStatus.EndpointInvalid]: "Unreachable Server Endpoint",
                    [Contract.CodeServerModelStatus.EndpointNotExist]: "Endpoint Connector integration is not connected",
                    [Contract.CodeServerModelStatus.GitHubApplicationClientIdInvalid]: "Invalid Client ID",
                    [Contract.CodeServerModelStatus.GitHubApplicationClientSecretInvalid]: "Invalid Client Secret",
                    [Contract.CodeServerModelStatus.GitHubApplicationIdInvalid]: "Invalid App ID",
                    [Contract.CodeServerModelStatus.GitHubApplicationPrivateKeyInvalid]: "Invalid Private Key",
                    [Contract.CodeServerModelStatus.GitLabServerResponseInvalid]: "Server side error",
                    [Contract.CodeServerModelStatus.ServerCertificateValidationFailed]: "SSL certificate validation failed",
                    [Contract.CodeServerModelStatus.Valid]: "Connected"
                }
            }));
    return (status: Contract.CodeServerModelStatus) => localization[Contract.TypeNames.CodeServerModelStatus][status]();
}