import { DataTableColumnRenderProps } from "@infrastructure";
import { Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract, IntRangeHelper, NetworkScopeHelper } from "../../../common";

export function AwsEc2NetworkRulePortRangeCell({ item: rule }: DataTableColumnRenderProps<Contract.AwsEc2NetworkRule>) {
    return (
        <Typography noWrap={true}>
            {!_.isNil(rule.destinationPortRange)
                ? NetworkScopeHelper.isAll(rule.destinationPortRange)
                    ? "ALL"
                    : IntRangeHelper.format(rule.destinationPortRange)
                : (_.isNil(rule.icmp) || rule.icmp.type === -1
                    ? "ALL"
                    : rule.icmp.code === -1
                        ? `ICMP [type=${rule.icmp.type}]`
                        : `ICMP [type=${rule.icmp.type}|code=${rule.icmp.code}]`)}
        </Typography>);
}