import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, DeferredFilter, optionalTableCell, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import { Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract, CustomerConsoleAppUrlHelper, EntityAttributeFilter, Region, RegionDeferredFilter, RegionLocation, RegionModelHelper, RiskTypeMetadataModelHelper, ScopeTenantFilter, Severity, SeverityFilter, Tenant, useGetEntityAttributesCsvItem, useRegionLocationTranslator, useRiskPolicyTitleTranslator, useTheme } from "../../../../../../../../../common";
import { EntityEntityAttributesCell } from "../../../components";
import { DefinitionData } from "../../../Table";

export function useCommonEntityDefinition(definitionData: DefinitionData) {
    const getAttributesCsvItem = useGetEntityAttributesCsvItem();
    const riskPolicyTitleTranslator = useRiskPolicyTitleTranslator();
    const regionLocationTranslator = useRegionLocationTranslator();

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.useCommonEntityDefinition",
            () => ({
                columns: {
                    attributes: "Labels",
                    openRelatedEntityRiskHighestSeverity: {
                        title: "Finding Severity",
                        tooltip: "Highest finding severity in resource"
                    },
                    openRelatedEntityRiskTypeIdentifiers: "Findings",
                    openRiskedEntityRiskIds: "Findings",
                    region: {
                        text: "{{systemName}} | {{name}}",
                        title: "Region"
                    },
                    regionLocation: "Location",
                    tenant: "Account"
                }
            }));

    const getRegionColumn =
        (isDefault: boolean) =>
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.EntityModel) => {
                            const regionModel = RegionModelHelper.tryGet(item.entity.regionId);
                            return ({
                                [localization.columns.region.title()]:
                                    _.isNil(regionModel)
                                        ? undefined
                                        : _.isNil(regionModel.name)
                                            ? regionModel.systemName
                                            : localization.columns.region.text({
                                                name: regionModel.name,
                                                systemName: regionModel.systemName
                                            })
                            });
                        }
                }}
                filterOptions={{
                    itemOrItems: {
                        default: isDefault,
                        element:
                            <RegionDeferredFilter
                                getRegionIds={(filters => filters.regionIdItems)}
                                promiseOrGetPromise={definitionData.entityModelFiltersPromise}
                                title={localization.columns.region.title}/>
                    }
                }}
                id={Contract.EntityModelProperty.EntityRegion}
                key={Contract.EntityModelProperty.EntityRegion}
                render={
                    optionalTableCell<Contract.EntityModel>(
                        entityModel =>
                            <Region
                                regionId={entityModel.entity.regionId}
                                variant="iconText"/>)}
                selectorOptions={{ default: isDefault }}
                title={localization.columns.region.title()}/>;
    const getRegionLocationColumn =
        (isDefault: boolean) =>
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.EntityModel) => ({
                            [localization.columns.regionLocation()]:
                                _.isNil(item.entity.regionId)
                                    ? undefined
                                    : regionLocationTranslator(RegionModelHelper.get(item.entity.regionId).location)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        default: isDefault,
                        element:
                            <DeferredFilter
                                promiseOrGetPromise={definitionData.entityModelFiltersPromise}
                                title={localization.columns.regionLocation()}>
                                {filters =>
                                    <ValuesFilter
                                        emptyValueOptions={{ enabled: false }}
                                        placeholder={localization.columns.regionLocation()}>
                                        {_.map(
                                            _(filters.regionIdItems.items).
                                                map(regionId => RegionModelHelper.get(regionId).location).
                                                uniq().
                                                value(),
                                            regionLocation =>
                                                <ValuesFilterItem
                                                    key={regionLocation}
                                                    title={regionLocationTranslator(regionLocation)}
                                                    value={regionLocation}>
                                                    {() => <RegionLocation regionLocation={regionLocation}/>}
                                                </ValuesFilterItem>)}
                                    </ValuesFilter>}
                            </DeferredFilter>
                    }
                }}
                id={Contract.EntityModelProperty.EntityRegionLocation}
                key={Contract.EntityModelProperty.EntityRegionLocation}
                render={
                    optionalTableCell<Contract.EntityModel>(
                        entityModel =>
                            _.isNil(entityModel.entity.regionId)
                                ? undefined
                                : <RegionLocation
                                    regionLocation={RegionModelHelper.get(entityModel.entity.regionId).location}/>)}
                selectorOptions={{ default: isDefault }}
                title={localization.columns.regionLocation()}/>;

    return {
        columns: {
            attributesColumn:
                <DataTableColumn
                    disableAction={true}
                    exportOptions={{
                        getItem:
                            (item: Contract.EntityModel) =>
                                getAttributesCsvItem(item.attributes.attributes)
                    }}
                    filterOptions={{
                        itemOrItems: {
                            default: true,
                            element:
                                <DeferredFilter
                                    promiseOrGetPromise={definitionData.entityModelFiltersPromise}
                                    title={localization.columns.attributes()}>
                                    {filters =>
                                        <EntityAttributeFilter
                                            emptyValue={filters.attributeValueItems.emptyValue}
                                            entityAttributeValues={filters.attributeValueItems.items}
                                            placeholder={localization.columns.attributes()}/>}
                                </DeferredFilter>
                        }
                    }}
                    id={Contract.EntityModelProperty.EntityAttributes}
                    key={Contract.EntityModelProperty.EntityAttributes}
                    render={({ item }: DataTableColumnRenderProps<Contract.EntityModel>) =>
                        <EntityEntityAttributesCell entityId={item.id}/>}
                    sortOptions={{ enabled: false }}
                    title={localization.columns.attributes()}/>,
            openRelatedEntityRiskHighestSeverityColumn:
                <DataTableColumn
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <SeverityFilter placeholder={localization.columns.openRelatedEntityRiskHighestSeverity.title()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity}
                    key={Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity}
                    message={localization.columns.openRelatedEntityRiskHighestSeverity.tooltip()}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.EntityModel>) =>
                            <Severity severity={item.risks.risks.openRelatedEntityRiskHighestSeverity}/>}
                    sortOptions={{ type: DataTableSortType.Numeric }}
                    title={localization.columns.openRelatedEntityRiskHighestSeverity.title()}/>,
            openRelatedEntityRiskTypeIdentifiersColumn:
                <DataTableColumn
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <DeferredFilter
                                    promiseOrGetPromise={definitionData.entityModelFiltersPromise}
                                    title={localization.columns.openRelatedEntityRiskTypeIdentifiers()}>
                                    {filters =>
                                        <ValuesFilter
                                            emptyValueOptions={{ enabled: filters.openRelatedEntityRiskTypeIdentifierItems.emptyValue }}
                                            groupItemTitle={true}
                                            placeholder={localization.columns.openRelatedEntityRiskTypeIdentifiers()}>
                                            {_(filters.openRelatedEntityRiskTypeIdentifierItems.items).
                                                map(
                                                    openRelatedEntityRiskTypeIdentifier =>
                                                        <ValuesFilterItem
                                                            key={openRelatedEntityRiskTypeIdentifier}
                                                            title={riskPolicyTitleTranslator(RiskTypeMetadataModelHelper.get(openRelatedEntityRiskTypeIdentifier)?.policyConfigurationTypeName ?? openRelatedEntityRiskTypeIdentifier)}
                                                            value={openRelatedEntityRiskTypeIdentifier}/>).
                                                value()}
                                        </ValuesFilter>}
                                </DeferredFilter>
                        }
                    }}
                    id={Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers}
                    key={Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers}
                    title={localization.columns.openRelatedEntityRiskTypeIdentifiers()}/>,
            openRiskedEntityRisksCsvColumn:
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.EntityModel) => ({
                                [localization.columns.openRiskedEntityRiskIds()]:
                                    _(item.risks.risks.openRiskedEntityRiskIds).
                                        map(CustomerConsoleAppUrlHelper.getRiskProfileUrl).
                                        join("\n")
                            })
                    }}
                    id="openRiskedEntityRiskIds"
                    key="openRiskedEntityRiskIds"/>,
            regionColumn: getRegionColumn(true),
            regionLocationColumn: getRegionLocationColumn(false),
            tenantColumn:
                <DataTableColumn
                    filterOptions={{
                        itemOrItems: {
                            default: true,
                            element:
                                <DeferredFilter
                                    promiseOrGetPromise={definitionData.entityModelFiltersPromise}
                                    title={localization.columns.tenant()}>
                                    {filters =>
                                        <ScopeTenantFilter
                                            placeholder={localization.columns.tenant()}
                                            tenantIds={filters.tenantIdItems.items}/>}
                                </DeferredFilter>
                        }
                    }}
                    id={Contract.EntityModelProperty.TenantId}
                    key={Contract.EntityModelProperty.TenantId}
                    render={TenantCell}
                    sortOptions={{ enabled: false }}
                    title={localization.columns.tenant()}/>
        },
        getRegionColumn,
        getRegionLocationColumn
    };
}

export function TenantCell({ item }: DataTableColumnRenderProps<Contract.EntityModel>) {
    const theme = useTheme();
    return (
        <Typography noWrap={true}>
            <Tenant
                sx={{ color: theme.palette.text.primary }}
                tenantId={item.tenantId}
                variant="iconText"/>
        </Typography>);
}