﻿import { DataTableColumn, DataTableColumnRenderProps, optionalTableCell, TimeCell, TimeFormatter, TimeRangeFilter, TimeSpanHelper, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { PermissionEligibilityCell, PermissionEligibilityFilter, useApproverUserPermissionRequestsContext } from "../../../../../..";
import { Contract, Entity, EntityFilter, InlineTenants, ScopeTenantFilter, TimeRangeHelper, TypeHelper } from "../../../../../../../../../common";
import { TableColumnId } from "../../../../Table";

export function useCommonColumns() {
    const { getFiltersResponse } = useApproverUserPermissionRequestsContext();

    const localization =
        useLocalization(
            "views.user.approverUserPermissionRequests.table.hooks.useDefinition.hooks.useCommonColumns",
            () => ({
                columns: {
                    expirationTimeFrame: {
                        text: [
                            "1 hour",
                            "{{count | NumberFormatter.humanize}} hours"
                        ],
                        title: "Duration"
                    },
                    granteeUserIdReference: "Requester",
                    permissionAssignmentRequestTenantIds: "Accounts",
                    permissionEligibility: "Eligibility",
                    reason: "Justification",
                    startTime: "Scheduled Time",
                    systemCreationTime: "Creation Time"
                }
            }));
    return useMemo(
        () => ({
            expirationTimeFrame:
            <DataTableColumn
                id={TableColumnId.ExpirationTimeFrame}
                itemProperty={(item: Contract.PermissionRequestModel) => localization.columns.expirationTimeFrame.text(TimeSpanHelper.getHours(item.permissionRequest.expirationTimeFrame))}
                key={TableColumnId.ExpirationTimeFrame}
                sortOptions={{ enabled: false }}
                title={localization.columns.expirationTimeFrame.title()}/>,
            granteeUserIdReference:
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        default: true,
                        element:
                            <EntityFilter
                                emptyValue={getFiltersResponse.granteeUserSearchableReferenceItems.emptyValue}
                                entityIdsOrSearchableReferences={getFiltersResponse.granteeUserSearchableReferenceItems.items}
                                placeholder={localization.columns.granteeUserIdReference()}/>
                    }
                }}
                id={TableColumnId.GranteeUserIdReference}
                key={TableColumnId.GranteeUserIdReference}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.PermissionRequestModel>) =>
                        <Entity
                            entityIdOrModel={item.granteeUserIdReference}
                            linkOptions={{ disabled: true }}
                            variant="iconText"/>}
                selectorOptions={{ disabled: true }}
                sortOptions={{ enabled: false }}
                title={localization.columns.granteeUserIdReference()}/>,
            permissionAssignmentRequestTenantIds:
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        default: true,
                        element:
                            <ScopeTenantFilter
                                placeholder={localization.columns.permissionAssignmentRequestTenantIds()}
                                tenantIds={getFiltersResponse.tenantIdItems.items}/>
                    }
                }}
                id={TableColumnId.PermissionAssignmentRequestTenantIds}
                key={TableColumnId.PermissionAssignmentRequestTenantIds}
                render={
                    optionalTableCell<Contract.PermissionRequestModel>(
                        item =>
                            TypeHelper.extendOrImplement(item.permissionRequest.typeName, Contract.TypeNames.PermissionAssignmentRequest)
                                ? <InlineTenants
                                    sx={{ color: "unset" }}
                                    tenantIds={(item.permissionRequest as Contract.PermissionAssignmentRequest).tenantIds}
                                    tenantVariant="iconText"
                                    variant="itemPlusItemCount"/>
                                : undefined)}
                sortOptions={{ enabled: false }}
                title={localization.columns.permissionAssignmentRequestTenantIds()}/>,
            permissionEligibility:
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        default: true,
                        element:
                            <PermissionEligibilityFilter
                                emptyValue={getFiltersResponse.permissionEligibilityIdItems.emptyValue}
                                permissionEligibilityIds={getFiltersResponse.permissionEligibilityIdItems.items}
                                placeholder={localization.columns.permissionEligibility()}/>
                    }
                }}
                id={TableColumnId.PermissionEligibility}
                key={TableColumnId.PermissionEligibility}
                render={PermissionEligibilityCell}
                selectorOptions={{ disabled: true }}
                sortOptions={{ enabled: false }}
                title={localization.columns.permissionEligibility()}/>,
            reason:
            <DataTableColumn
                id={TableColumnId.Reason}
                key={TableColumnId.Reason}
                render={
                    optionalTableCell<Contract.PermissionRequestModel>(
                        item =>
                            _.isEmpty(item.permissionRequest.reason)
                                ? undefined
                                : item.permissionRequest.reason)}
                sortOptions={{ enabled: false }}
                title={localization.columns.reason()}/>,
            startTime:
            <DataTableColumn
                id={TableColumnId.StartTime}
                key={TableColumnId.StartTime}
                render={
                    optionalTableCell<Contract.PermissionRequestModel>(
                        item =>
                            _.isNil(item.permissionRequest.startTime)
                                ? undefined
                                : TimeFormatter.longDateTime(item.permissionRequest.startTime))}
                sortOptions={{ enabled: false }}
                title={localization.columns.startTime()}/>,
            systemCreationTime:
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        default: true,
                        element:
                            <TimeRangeFilter
                                emptyValue={getFiltersResponse.systemCreationTimeRange.emptyValue}
                                placeholder={localization.columns.systemCreationTime()}
                                timeRange={TimeRangeHelper.getTimeRangeFilterRange(getFiltersResponse.systemCreationTimeRange.timeRange)}/>
                    }
                }}
                id={TableColumnId.SystemCreationTime}
                key={TableColumnId.SystemCreationTime}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.PermissionRequestModel>) =>
                        <TimeCell time={item.permissionRequest.systemCreationTime}/>}
                selectorOptions={{ disabled: true }}
                sortOptions={{ enabled: false }}
                title={localization.columns.systemCreationTime()}/>
        }),
        [getFiltersResponse, localization]);
}