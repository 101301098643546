import { Optional } from "@infrastructure";
import _ from "lodash";

export class Dom {
    public static isChildrenOf(element: Optional<Element | null>, parentElementSelector: (parentElement: Element) => boolean) {
        element = element?.parentElement;
        while (!_.isNil(element)) {
            if (parentElementSelector(element)) {
                return true;
            }

            element = element.parentElement;
        }

        return false;
    }
}