import { ApiError, FormLayout, ItemSelector, map, Message, useChangeEffect, useInputValidation, useLocalization } from "@infrastructure";
import { Button, CircularProgress, FormControl, FormHelperText, Stack, TextField, Typography } from "@mui/material";
import _ from "lodash";
import React, { Fragment, useMemo, useState } from "react";
import { useApiKeysContext, useSetApiKeysContext } from "../..";
import { ConfigurationController, Contract, InlineScopes, InlineTenants, LicensingHelper, ScopeHelper, scopeNodeModelStore, TenantHelper, useIdentityRoleTranslator, useScopeNavigationViewContext, useTheme } from "../../../../../../../../common";
import { Success } from "./components";

export function Add() {
    const scopeNodeMap =
        scopeNodeModelStore.useGetActiveScopeNodeMap(
            undefined,
            true);
    const { scopeNodeModel } = useScopeNavigationViewContext();

    const { apiKeyDatas, executeGetApiKeys } = useApiKeysContext();
    const setApiKeysContext = useSetApiKeysContext();
    const [name, setName] = useState("");
    const [role, setRole] = useState<Contract.IdentityRole>();

    const [addApiKeyToken, setAddApiKeyToken] = useState<string>();
    const [addApiKeyExecuting, setAddApiKeyExecuting] = useState(false);
    const [addApiKeyError, setAddApiKeyError] = useState<Contract.ConfigurationControllerInsertApiKeyError | true>();

    const permissionManagementEnabled =
        useMemo(
            () => {
                if (!LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.PermissionManagement)) {
                    return false;
                }
                const scopeTenantType = ScopeHelper.getTenantType(scopeNodeModel);
                return scopeNodeModel.type === Contract.ScopeType.Customer ||
                    !_.isNil(scopeTenantType) &&
                    TenantHelper.isPermissionManagementTenantType(scopeTenantType);
            },
            [scopeNodeModel]);

    async function addApiKey() {
        setAddApiKeyExecuting(true);
        setAddApiKeyError(undefined);
        setAddApiKeyToken(undefined);

        try {
            const { token } =
                await ConfigurationController.insertApiKey(
                    new Contract.ConfigurationControllerInsertApiKeyRequest(
                        name,
                        role!,
                        scopeNodeModel.configuration.id));
            await executeGetApiKeys();

            setAddApiKeyToken(token);
        } catch (error) {
            setAddApiKeyError(
                error instanceof ApiError && error.statusCode === 400
                    ? error.error as Contract.ConfigurationControllerInsertApiKeyError
                    : true);
        }

        setAddApiKeyExecuting(false);
    }

    const localization =
        useLocalization(
            "views.customer.configuration.apiKeys.add",
            () => ({
                actions: {
                    add: {
                        error: {
                            common: "Failed to add the API token",
                            [Contract.TypeNames.ConfigurationControllerInsertApiKeyError]: {
                                [Contract.ConfigurationControllerInsertApiKeyError.ApiKeyMaxCount]: "API token limit was reached"
                            }
                        },
                        title: "Add Token"
                    },
                    close: "Close"
                },
                fields: {
                    name: {
                        error: {
                            exists: "Name already exists",
                            required: "Name cannot be empty"
                        },
                        title: "Token Name"
                    },
                    role: "Role"
                },
                subtitle: {
                    codeTenant: "This token will allow access to Tenable Cloud Security API for code repository **{{tenantName}} ({{tenantId}})**",
                    customer: "This token will allow access to Tenable Cloud Security API for **all accounts**",
                    folder: "This token will allow access to Tenable Cloud Security API for **{{tenants}}**",
                    project: "This token will allow access to Tenable Cloud Security API for all accounts under **{{projectName}}**",
                    registry: "This token will allow access to Tenable Cloud Security API for registry **{{tenantName}} ({{tenantId}})**",
                    tenant: "This token will allow access to Tenable Cloud Security API for account **{{tenantName}} ({{tenantId}})**"
                },
                title: "Add Token"
            }));

    const [nameValidationController, nameValidationMessage] =
        useInputValidation(
            () => {
                const validationName = name.trim();
                if (_.isEmpty(validationName)) {
                    return localization.fields.name.error.required();
                }
                if (_.find(apiKeyDatas, apiKeyData => apiKeyData.apiKey.name === validationName)) {
                    return localization.fields.name.error.exists();
                }

                return undefined;
            },
            [name]);

    const [valid, setValid] = useState(false);
    useChangeEffect(
        () => {
            setValid(
                nameValidationController.isValid() &&
                !_.isNil(role));
        },
        [name, role]);

    const identityRoleTranslator = useIdentityRoleTranslator();
    const theme = useTheme();
    return (
        <FormLayout
            footerOptions={{
                contentElement:
                    <Stack
                        alignItems="center"
                        direction="row"
                        justifyContent="flex-end"
                        spacing={1}>
                        {_.isNil(addApiKeyToken)
                            ? <Fragment>
                                {addApiKeyExecuting && (
                                    <CircularProgress
                                        size={theme.spacing(2)}
                                        variant="indeterminate"/>)}
                                <Button
                                    disabled={!valid || addApiKeyExecuting}
                                    onClick={() => addApiKey()}>
                                    {localization.actions.add.title()}
                                </Button>
                            </Fragment>
                            : <Button
                                onClick={
                                    () =>
                                        setApiKeysContext(
                                            usersContext => ({
                                                ...usersContext,
                                                addOpen: false
                                            }))}>
                                {localization.actions.close()}
                            </Button>}
                    </Stack>
            }}
            titleOptions={{ text: localization.title() }}>
            {!_.isNil(addApiKeyToken)
                ? <Success token={addApiKeyToken}/>
                : <Stack spacing={1}>
                    <Typography
                        sx={{
                            fontSize: "13px",
                            wordBreak: "break-all"
                        }}>
                        {map(
                            scopeNodeModel.type,
                            {
                                [Contract.ScopeType.CiTenant]: () =>
                                    localization.subtitle.registry({
                                        tenantId: scopeNodeModel.configuration.id,
                                        tenantName: scopeNodeModel.configuration.name
                                    }),
                                [Contract.ScopeType.CloudProviderTenant]: () =>
                                    localization.subtitle.tenant({
                                        tenantId: scopeNodeModel.configuration.id,
                                        tenantName: scopeNodeModel.configuration.name
                                    }),
                                [Contract.ScopeType.CodeTenant]: () =>
                                    localization.subtitle.codeTenant({
                                        tenantId: scopeNodeModel.configuration.id,
                                        tenantName: scopeNodeModel.configuration.name
                                    }),
                                [Contract.ScopeType.Customer]: () => localization.subtitle.customer(),
                                [Contract.ScopeType.Folder]: () =>
                                    localization.subtitle.folder({
                                        tenants:
                                            <InlineTenants
                                                tenantIds={scopeNodeMap[scopeNodeModel.configuration.id].tenantIds}
                                                tenantNameTranslatorOptions={{ includeRawId: true }}
                                                tenantType={_.as<Contract.FolderConfiguration>(scopeNodeModel.configuration).tenantType}/>
                                    }),
                                [Contract.ScopeType.ProjectFolder]:
                                    () =>
                                        localization.subtitle.folder({
                                            tenants:
                                                <InlineScopes
                                                    scopeIds={scopeNodeMap[scopeNodeModel.configuration.id].scopeIds}
                                                    variant="itemCountAndType"/>
                                        }),
                                [Contract.ScopeType.Project]:
                                    () =>
                                        localization.subtitle.project({
                                            projectName:
                                                <Typography sx={{ fontWeight: 600 }}>
                                                    {scopeNodeModel.configuration.name}
                                                </Typography>
                                        }),
                                [Contract.ScopeType.IdentityProviderTenant]: () =>
                                    localization.subtitle.tenant({
                                        tenantId: scopeNodeModel.configuration.id,
                                        tenantName: scopeNodeModel.configuration.name
                                    })
                            })}
                    </Typography>
                    <FormControl
                        fullWidth={true}
                        variant="standard">
                        <TextField
                            disabled={addApiKeyExecuting}
                            label={localization.fields.name.title()}
                            value={name}
                            variant="outlined"
                            onChange={event => setName(event.target.value)}/>
                        {!_.isNil(nameValidationMessage) && (
                            <FormHelperText error={true}>{nameValidationMessage}</FormHelperText>)}
                    </FormControl>
                    <ItemSelector
                        fullWidth={true}
                        items={
                            _<Contract.IdentityRole>([]).
                                concat([
                                    Contract.IdentityRole.Administrator,
                                    Contract.IdentityRole.Collaborator,
                                    Contract.IdentityRole.Viewer]).
                                concatIf(
                                    permissionManagementEnabled,
                                    Contract.IdentityRole.PermissionManagementAdministrator).
                                value()}
                        placeholder={localization.fields.role()}
                        selectedItem={role}
                        sorted={false}
                        onSelectedItemChanged={setRole}>
                        {identityRoleTranslator}
                    </ItemSelector>
                    {!_.isNil(addApiKeyError) && (
                        <Message
                            level="error"
                            title={
                                addApiKeyError === true
                                    ? localization.actions.add.error.common()
                                    : localization.actions.add.error[Contract.TypeNames.ConfigurationControllerInsertApiKeyError][addApiKeyError]()}/>)}
                </Stack>}
        </FormLayout>);
}