import { Action0, Sx, useLocalizeAttributes } from "@infrastructure";
import { Stack, SxProps, Typography } from "@mui/material";
import _ from "lodash";
import React, { ReactNode, RefObject, useMemo } from "react";
import { Contract, EntityLinkOptions, InlineResourceTags, TenantNameTranslatorOptions, useEntityTypeNameTranslator, useTenantNameTranslator, useTheme } from "../../..";
import { EntityDefinition } from "../hooks";
import { Link, LinkProps } from "./Link";

export type TextProps = Pick<LinkProps, "urlOrGetUrl"> & {
    definition: EntityDefinition;
    linkOptions?: EntityLinkOptions;
    onClick?: Action0;
    onTitleMouseEnter: Action0;
    onTitleMouseLeave: Action0;
    showTags: boolean;
    showTenant: boolean;
    showType: boolean;
    subtitleSx?: SxProps;
    tenantNameTranslatorOptions?: TenantNameTranslatorOptions;
    titleRef: RefObject<HTMLElement>;
    titleSx?: SxProps;
};

export function Text({ definition, linkOptions, onClick, onTitleMouseEnter, onTitleMouseLeave, showTags, showTenant, showType, subtitleSx, tenantNameTranslatorOptions, titleRef, titleSx, urlOrGetUrl }: TextProps) {
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localizeAttributes = useLocalizeAttributes();
    const tenantNameTranslator = useTenantNameTranslator();
    const theme = useTheme();
    const subtitle =
        useMemo(
            () => {
                showTags = showTags && !_.isEmpty(definition.tags);
                showTenant = (definition.showTenant ?? showTenant) && definition.tenantId !== "unknown";
                showType = showType && definition.typeName !== Contract.TypeNames.UnknownEntity;

                const translatedTenantName =
                    showTenant && !_.isNil(definition.tenantId)
                        ? tenantNameTranslator(definition.tenantId!, tenantNameTranslatorOptions)
                        : undefined;

                const subtitleAttributes =
                    _.filter([
                        showTenant
                            ? translatedTenantName
                            : undefined,
                        showTags
                            ? <InlineResourceTags
                                sx={{
                                    color: theme.palette.text.primary,
                                    textDecoration: "underline"
                                }}
                                tags={definition.tags!}
                                variant="itemCountAndType"/>
                            : undefined
                    ]);

                return localizeAttributes(
                    ..._<ReactNode>([]).
                        concatIf(
                            showType,
                            () =>
                                definition.translatedEntityTypeName ??
                                entityTypeNameTranslator(definition.typeName)).
                        concatIf(
                            !_.isEmpty(subtitleAttributes),
                            subtitleAttributes!).
                        value());
            },
            [definition]);

    return (
        <Stack>
            <Typography
                noWrap={true}
                ref={titleRef}
                sx={
                    Sx.combine(
                        {
                            lineHeight: "unset",
                            width: "100%"
                        },
                        titleSx)}>
                <Link
                    linkOptions={linkOptions}
                    sx={Sx.combine({ color: theme.palette.text.primary }, titleSx)}
                    urlOrGetUrl={urlOrGetUrl}
                    variant="text"
                    onClick={onClick}
                    onMouseEnter={onTitleMouseEnter}
                    onMouseLeave={onTitleMouseLeave}>
                    {definition.displayName}
                </Link>
            </Typography>
            {!_.isNil(subtitle) &&
                <Typography
                    noWrap={true}
                    sx={
                        Sx.combine(
                            {
                                lineHeight: "unset",
                                width: "100%"
                            },
                            subtitleSx)}
                    variant="subtitle1">
                    {subtitle}
                </Typography>}
        </Stack>);
}