import _ from "lodash";
import { useMemo } from "react";
import { Contract, RiskPoliciesType, RiskPolicyItem, scopeNodeModelStore } from "../../../../../common";
import { useRiskPoliciesItemConfiguration } from "./useRiskPoliciesItemConfiguration";

export function useGetInheritedOverridableValue<TValue>(
    getValue: (riskPolicyConfiguration: Contract.RiskPolicyConfiguration) => TValue,
    riskPoliciesType: RiskPoliciesType,
    riskPolicyConfiguration: Contract.RiskPolicyConfiguration,
    riskPolicyItem: RiskPolicyItem) {
    const scopeNodeMap = scopeNodeModelStore.useGetActiveScopeNodeMap();
    const { inheritDisabled, scopeIdToRiskPolicyModelMap } =
        useRiskPoliciesItemConfiguration(
            riskPoliciesType,
            riskPolicyItem,
            riskPolicyConfiguration.scopeId);

    return useMemo(
        () => {
            let inherited = false;
            let inheritedScopeId = undefined;
            let inheritedValue = undefined;
            const value = getValue(riskPolicyConfiguration);

            if (!inheritDisabled) {
                const parentScopeIds = scopeNodeMap[riskPolicyConfiguration.scopeId].parentScopeIds;
                for (const parentScopeId of parentScopeIds) {
                    const parentValue = getValue(scopeIdToRiskPolicyModelMap[parentScopeId].riskPolicyConfiguration);
                    if (!_.isNil(parentValue)) {
                        inherited = _.isNil(value);
                        inheritedScopeId = parentScopeId;
                        inheritedValue = parentValue;
                        break;
                    }
                }
            }

            return {
                inheritDisabled,
                inherited,
                inheritedScopeId,
                inheritedValue,
                value
            };
        },
        [getValue, inheritDisabled, riskPolicyConfiguration, scopeIdToRiskPolicyModelMap, scopeNodeMap]);
}