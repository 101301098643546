﻿import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useAzureComputeDiskSnapshotTypeTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useAzureComputeDiskSnapshotTypeTranslator",
            () => ({
                [Contract.TypeNames.AzureComputeDiskSnapshotType]: {
                    [Contract.AzureComputeDiskSnapshotType.Full]: "Full",
                    [Contract.AzureComputeDiskSnapshotType.Incremental]: "Incremental"
                }

            }));
    return (type: Contract.AzureComputeDiskSnapshotType) =>
        localization[Contract.TypeNames.AzureComputeDiskSnapshotType][type]();
}