import { useLocalization } from "@infrastructure";
import { AuditEventDefinition, AuditEventDefinitionContext, AuditEventDefinitionDetailItem } from "..";
import { Contract } from "../../../../../../../../../common";
import { useReportScheduleCadenceTranslator } from "../../../../../../Reports/components/Schedule/hooks";
import { useReportDefinitionTranslator } from "../../../../../../Reports/hooks";

export function useReportScheduleAuditEventDefinition(context: AuditEventDefinitionContext) {
    const reportDefinitionTranslator = useReportDefinitionTranslator();
    const reportScheduleCadenceTranslator = useReportScheduleCadenceTranslator();
    const localization =
        useLocalization(
            "views.customer.auditEvents.profile.hooks.useDefinition.hooks.useReportScheduleAuditEventDefinition",
            () => ({
                reportScheduleCadence: "Schedule",
                reportType: "Type"
            }));

    const reportScheduleAuditEvent = context.auditEventModel.auditEvent as Contract.ReportScheduleAuditEvent;
    return new AuditEventDefinition([
        new AuditEventDefinitionDetailItem(
            localization.reportType(),
            reportDefinitionTranslator(reportScheduleAuditEvent.reportDefinitionId)),
        new AuditEventDefinitionDetailItem(
            localization.reportScheduleCadence(),
            reportScheduleCadenceTranslator(reportScheduleAuditEvent.reportScheduleCadence))
    ]);
}