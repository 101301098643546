﻿import { Optional, useLocalization } from "@infrastructure";
import { Box, Radio, Stack } from "@mui/material";
import _ from "lodash";
import React from "react";
import { EntityTypeNameSelector as EntityTypeNameSelectorCommon } from "../../EntityTypeNameSelector";
import { InlineEntityTypeNameSelectionData as InlineEntityTypeNameSelectionData } from "./InlineEntityTypeNameSelection";

type EntityTypeNameSelectorProps = {
    entityTypeNames: string[];
    onSelectionChanged: (selection?: EntityTypeNameSelectorSelection) => void;
    selection?: EntityTypeNameSelectorSelection;
    translatedEntityTypeName: string;
};

export function EntityTypeNameSelector({ entityTypeNames, onSelectionChanged, selection, translatedEntityTypeName }: EntityTypeNameSelectorProps) {
    const localization =
        useLocalization(
            "common.customRiskPolicy.entityTypeNameSelector",
            () => ({
                title: "{{translatedEntityTypeName}} from these types"
            }));
    return (
        <Stack
            alignItems="center"
            direction="row"
            spacing={1}>
            <Radio
                checked={true}
                size="small"/>
            <Box>
                {localization.title({ translatedEntityTypeName })}
            </Box>
            <EntityTypeNameSelectorCommon
                entityTypeNames={entityTypeNames}
                multiSelect={true}
                selectedEntityTypeNames={selection?.typeNames ?? []}
                onSelectedItemsChanged={selectedTypeNames => onSelectionChanged(new EntityTypeNameSelectorSelection(selectedTypeNames))}/>
        </Stack>);
}

export class EntityTypeNameSelectorSelection {
    constructor(public typeNames: string[]) {
    }

    public static getSelectorSelection(typeNames?: string[])
        : Optional<EntityTypeNameSelectorSelection> {
        if (!_.isNil(typeNames)) {
            return new EntityTypeNameSelectorSelection(typeNames);
        } else {
            return undefined;
        }
    }

    public getInlineSelectionData(): InlineEntityTypeNameSelectionData {
        return { typeNames: this.typeNames };
    }

    public valid() {
        return !_.isEmpty(this.typeNames);
    }
}