import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function SignOutIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                clipRule="evenodd"
                d="M17.783 8.80288C18.1681 8.40692 18.8012 8.39804 19.1971 8.78305C19.2767 8.86043 19.446 8.99668 19.7218 9.21338C19.7418 9.22905 19.7621 9.24501 19.7828 9.26124C20.0243 9.4508 20.3116 9.67638 20.5862 9.90907C20.88 10.158 21.194 10.4438 21.4418 10.7348C21.5661 10.8807 21.6941 11.0513 21.795 11.2399C21.8917 11.4205 22 11.6848 22 12C22 12.3152 21.8917 12.5795 21.795 12.7601C21.6941 12.9487 21.5661 13.1193 21.4418 13.2652C21.194 13.5562 20.88 13.842 20.5862 14.0909C20.3116 14.3236 20.0243 14.5492 19.7828 14.7388C19.7621 14.755 19.7418 14.771 19.7218 14.7866C19.446 15.0033 19.2767 15.1396 19.1971 15.217C18.8012 15.602 18.1681 15.5931 17.783 15.1971C17.398 14.8012 17.4069 14.1681 17.8029 13.783C17.9756 13.6151 18.2439 13.4043 18.4836 13.2161L18.4863 13.2139C18.5053 13.199 18.5243 13.184 18.5434 13.169C18.6142 13.1135 18.686 13.0571 18.7583 13H7C6.44772 13 6 12.5523 6 12C6 11.4477 6.44772 11 7 11H18.7583C18.686 10.9429 18.6142 10.8865 18.5435 10.831C18.5243 10.816 18.5053 10.801 18.4863 10.7861L18.4836 10.784C18.2439 10.5957 17.9756 10.3849 17.8029 10.217C17.4069 9.83195 17.398 9.19885 17.783 8.80288Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M11.0567 3C11.609 3 12.0567 3.44772 12.0567 4C12.0567 4.55229 11.609 5 11.0567 5H10.9433C9.25991 4.99999 8.01257 5.00186 7.05434 5.12481C6.08666 5.24897 5.45009 5.49304 4.97345 5.94801C4.50338 6.3967 4.25654 6.98607 4.1295 7.88828C4.00223 8.79212 3.99999 9.9722 4.00001 11.5822V11.6905C3.99999 13.3143 4.00263 15.211 4.1295 16.112C4.25654 17.0141 4.50337 17.6033 4.97345 18.052C5.45008 18.507 6.08683 18.751 7.05463 18.8752C8.01295 18.9981 9.26028 19 10.9433 19H11.0567C11.609 19 12.0567 19.4477 12.0567 20C12.0567 20.5523 11.609 21 11.0567 21L10.8806 21C9.27497 21 7.90024 21.0001 6.80011 20.8589C5.62786 20.7085 4.50609 20.3708 3.5925 19.4987C2.67241 18.6204 2.30967 17.5315 2.14904 16.3909C1.99986 15.3315 1.99994 13.3017 2 11.7685L2 11.5146C1.99995 9.98845 1.99991 8.6685 2.14904 7.60941C2.30964 6.46886 2.67233 5.37963 3.5925 4.5013C4.50602 3.6293 5.6275 3.29149 6.7998 3.14107C7.89983 2.99993 9.27452 2.99996 10.8804 3L11.0567 3Z"
                fillRule="evenodd"/>
        </SvgIcon>);
}