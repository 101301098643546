import { useActions, useLocalization } from "@infrastructure";
import { Stack } from "@mui/material";
import _ from "lodash";
import React, { Ref, useEffect, useMemo, useState } from "react";
import { automaticCustomEntityAttributeDefinitionTemplateModelStore, ConfigurationController, Contract, PagedResourceTagSelector, TextInput, useScopeNavigationViewContext } from "../../../../../../../../../../common";
import { AutomaticCustomEntityAttributeDefinitionTemplateConfigurationType } from "../../../../../../../../../../common/controllers/types.generated";
import { useCustomEntityAttributesContext } from "../../../CustomEntityAttributeDefinitions";
import { AddCustomEntityAttributeActions, ConfigurationValidation } from "../AddOrEdit";

type AutomaticCustomEntityAttributeResourceTagDefinitionTemplateProps = {
    actionsRef: Ref<AddCustomEntityAttributeActions>;
    disabled: boolean;
    onValidChange: (validation: ConfigurationValidation) => void;
    readOnly: boolean;
};

export function AutomaticCustomEntityAttributeResourceTagDefinitionTemplate({ actionsRef, disabled, onValidChange, readOnly }: AutomaticCustomEntityAttributeResourceTagDefinitionTemplateProps) {
    const { scopeNodeModel } = useScopeNavigationViewContext();
    const configuration = useCustomEntityAttributesContext().item?.configuration as Contract.AutomaticCustomEntityAttributeDefinitionTemplateConfiguration | undefined;
    const [name, setName] =
        useState(
            !_.isNil(configuration) &&
            configuration.type == AutomaticCustomEntityAttributeDefinitionTemplateConfigurationType.ResourceTag
                ? configuration.name ?? ""
                : "");
    const [resourceTag, setResourceTag] =
        useState<Contract.ResourceTag | undefined>(
            () => {
                if (!_.isNil(configuration) &&
                    configuration.type == AutomaticCustomEntityAttributeDefinitionTemplateConfigurationType.ResourceTag) {
                    const resourceTagPattern = _.head(configuration?.resourceTagPatterns)!;
                    return new Contract.ResourceTag(resourceTagPattern.keyPattern, resourceTagPattern.valuePattern);
                }

                return undefined;
            });

    useActions<AddCustomEntityAttributeActions>(
        actionsRef,
        {
            reset() {
                setName("");
                setResourceTag(undefined);
            },
            async upsertCustomEntityAttribute(colorIndex, scopeId) {
                const { automaticCustomEntityAttributeDefinitionTemplateModel } =
                    await ConfigurationController.upsertAutomaticCustomEntityAttributeDefinitionTemplate(
                        new Contract.ConfigurationControllerUpsertAutomaticCustomEntityAttributeResourceTagDefinitionTemplateRequest(
                            colorIndex,
                            configuration?.id,
                            scopeId,
                            _.isEmpty(name)
                                ? undefined
                                : name,
                            resourceTag!));
                await automaticCustomEntityAttributeDefinitionTemplateModelStore.notify(automaticCustomEntityAttributeDefinitionTemplateModel);
            }
        });
    const localization =
        useLocalization(
            "views.customer.configuration.entities.customEntityAttributeDefinitions.addOrEdit.automaticCustomEntityAttributeResourceTagDefinitionTemplate",
            () => ({
                name: "Name (optional)",
                resourceTag: "Select a Tag"
            }));

    useEffect(
        () =>
            onValidChange(
                {
                    changed:
                        !_.isNil(configuration) && (
                            name != (configuration.name ?? "") ||
                            resourceTag?.key != _.head(configuration.resourceTagPatterns)?.keyPattern ||
                            resourceTag?.value != _.head(configuration.resourceTagPatterns)?.valuePattern),
                    valid: !_.isNil(resourceTag)
                }),
        [configuration, name, resourceTag]);

    const selectedTags =
        useMemo(
            () =>
                _.isNil(resourceTag)
                    ? []
                    : [resourceTag],
            [resourceTag]);
    return (
        <Stack spacing={2}>
            <TextInput
                disabled={disabled}
                placeholder={
                    readOnly && _.isEmpty(name)
                        ? undefined
                        : localization.name()}
                readOnly={readOnly}
                text={name}
                onChange={setName}/>
            <PagedResourceTagSelector
                disabled={disabled}
                multiSelect={false}
                placeholder={localization.resourceTag()}
                readOnly={readOnly}
                scopeId={scopeNodeModel.configuration.id}
                selectedTags={selectedTags}
                onSelectedTagsChanged={resourceTags => setResourceTag(_.head(resourceTags))}/>
        </Stack>);
}