﻿import { EmptyMessageText, SearchList, useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import _ from "lodash";
import React, { ReactNode, useMemo, useRef } from "react";
import { Contract, InlineVulnerability, SeverityChart, SeverityChartActions, useTheme } from "..";

export type VulnerabilityChartProps = {
    message?: ReactNode;
    variant?: "count" | "initialAndCount";
    vulnerabilities: Contract.VulnerabilityData[];
};

export function VulnerabilityChart({ message, variant = "count", vulnerabilities }: VulnerabilityChartProps) {
    const localization =
        useLocalization(
            "common.vulnerabilityChart",
            () => ({
                empty: "No Matching Vulnerabilities",
                none: "No vulnerabilities"
            }));

    const vulnerabilitySeverityMap =
        useMemo(
            () =>
                _.groupBy(
                    vulnerabilities,
                    vulnerability => vulnerability.severity),
            [vulnerabilities]);
    const actionsRef= useRef<SeverityChartActions>();
    const theme = useTheme();
    return (
        _.isEmpty(vulnerabilities)
            ? <Typography>
                {localization.none()}
            </Typography>
            : <SeverityChart
                actionsRef={actionsRef}
                getDropdownElement={
                    (vulnerabilityDatas: Contract.VulnerabilityData[]) =>
                        <SearchList
                            disablePadding={true}
                            emptyMessageText={new EmptyMessageText(localization.empty())}
                            itemOptions={{
                                getSortValue: (item: Contract.VulnerabilityData) => item.rawId,
                                render:
                                    (item: Contract.VulnerabilityData) =>
                                        <InlineVulnerability
                                            rawId={item.rawId}
                                            onClick={actionsRef.current?.close}/>,
                                spacing: 1
                            }}
                            items={vulnerabilityDatas}
                            searchOptions={{ enabled: true }}
                            sx={{
                                maxHeight: theme.spacing(50),
                                minWidth: theme.spacing(20),
                                overflow: "hidden auto"
                            }}
                            variant="dropdown"/>}
                message={message}
                severityToDatasMap={vulnerabilitySeverityMap}
                variant={variant}/>);
}