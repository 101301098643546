import { IconText } from "@infrastructure";
import { SxProps } from "@mui/material";
import React from "react";
import { Icon, LinkProps, Text, TextProps } from ".";
import { EntityLinkOptions } from "../../..";
import { EntityDefinition } from "../hooks";

type IconTextTenantProps = TextProps & Pick<LinkProps, "urlOrGetUrl"> & {
    definition: EntityDefinition;
    iconSx?: SxProps;
    linkOptions?: EntityLinkOptions;
};

export function IconTextTypeTenant({ definition, iconSx, linkOptions, urlOrGetUrl, ...props }: IconTextTenantProps) {
    return (
        <IconText
            containerSx={props?.titleSx}
            icon={
                <Icon
                    blurred={false}
                    definition={definition}
                    sx={iconSx}/>}
            text={
                <Text
                    definition={definition}
                    linkOptions={linkOptions}
                    urlOrGetUrl={urlOrGetUrl}
                    {...props}/>}/>);
}