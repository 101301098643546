﻿import { Link, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract } from "../../../../../../../../../../../../../../../common";

export function useGitHubOrganizationManagerErrorTranslator() {
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCodeOrganizationItems.gitHub.addOrEditGitHub.hooks.useGitHubOrganizationManagerErrorTranslator",
            () => ({
                [Contract.TypeNames.GitHubOrganizationManagerError]: {
                    [Contract.GitHubOrganizationManagerError.CommonErrorServerInvalid]: {
                        title: "The GitHub server integration is not connected"
                    },
                    [Contract.GitHubOrganizationManagerError.CommonErrorInstallationNetworkAccessDenied]: {
                        linkText: "Tenable IP addresses",
                        linkUrl: "https://docs.ermetic.com/docs/architecture-and-data-handling#tenable-ip-addresses-to-allow",
                        title: "The Tenable Cloud Security GitHub App is denied access to your organization by its IP allowlist. To allow access, add the {{link}} associated with your region to the allowlist."
                    },
                    [Contract.GitHubOrganizationManagerError.CommonErrorInstallationMissingBasicPermissions]: {
                        title: "The Tenable Cloud Security GitHub App is missing mandatory permissions for your organization. Reauthorize the App and try again"
                    },
                    [Contract.GitHubOrganizationManagerError.CommonErrorInstallationNotFound]: {
                        title: "No Tenable Cloud Security GitHub App installation can be found on the requested organization"
                    },
                    [Contract.GitHubOrganizationManagerError.InsertOrganizationErrorOrganizationExists]: {
                        title: "GitHub organization already exists"
                    }
                }
            }));

    return (error: Contract.GitHubOrganizationManagerError) =>
        _.has(localization[Contract.TypeNames.GitHubOrganizationManagerError][error], "linkText")
            ? localization[Contract.TypeNames.GitHubOrganizationManagerError][error].title({
                link:
                    <Link
                        urlOrGetUrl={localization[Contract.TypeNames.GitHubOrganizationManagerError][error].translate("linkUrl")}
                        variant="external">
                        {localization[Contract.TypeNames.GitHubOrganizationManagerError][error].translate("linkText")}
                    </Link>
            })
            : localization[Contract.TypeNames.GitHubOrganizationManagerError][error].title();
}