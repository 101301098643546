import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, VerticalTopItemsInfoItem } from "../../../../../../../../../common";

type KubernetesLabelSelectorInfoItemProps = {
    labelSelector: Contract.KubernetesLabelSelector;
};

export function KubernetesLabelSelectorInfoItem({ labelSelector }: KubernetesLabelSelectorInfoItemProps) {
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.kubernetesLabelSelectorInfoItem",
            () => ({
                tag: "{{key}}:{{value}}",
                title: "Label Selectors",
                [Contract.TypeNames.KubernetesLabelSelectorOperator]: {
                    [Contract.KubernetesLabelSelectorOperator.DoesNotExist]: "!{{key}}",
                    [Contract.KubernetesLabelSelectorOperator.Exists]: "{{key}}",
                    [Contract.KubernetesLabelSelectorOperator.In]: "{{key}} in ({{values}})",
                    [Contract.KubernetesLabelSelectorOperator.NotIn]: "{{key}} notin ({{values}})"
                }
            }));

    const labelSelectors =
        useMemo(
            () =>
                _(labelSelector.keyToValueMap).
                    map(
                        (labelValue, labelKey) =>
                            localization.tag({
                                key: labelKey,
                                value: labelValue
                            })).
                    concat(
                        _.map(
                            labelSelector.expressions,
                            expression =>
                                localization[Contract.TypeNames.KubernetesLabelSelectorOperator][expression.operator]({
                                    key: expression.key,
                                    values: _.join(expression.values, ", ")
                                }))).
                    value(),
            [labelSelector]);

    return (
        <VerticalTopItemsInfoItem
            items={labelSelectors}
            title={localization.title()}/>);
}