import { ValuesFilter, ValuesFilterItem } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { memo } from "react";
import { EntityIcon, useEntityTypeNameTranslator } from "../../common";

const EntityTypeFilterMemo = memo(EntityTypeFilter);
export { EntityTypeFilterMemo as EntityTypeFilter };

type EntityTypeFilterProps = {
    emptyValue?: boolean;
    entityTypeNames: string[];
    groupItemTitle?: boolean;
    placeholder: string;
};

function EntityTypeFilter({ emptyValue, entityTypeNames, groupItemTitle, placeholder }: EntityTypeFilterProps) {
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    return (
        <ValuesFilter
            emptyValueOptions={{
                enabled: emptyValue,
                variant: "iconText"
            }}
            groupItemTitle={groupItemTitle ?? true}
            placeholder={placeholder}>
            {_.map(
                entityTypeNames,
                entityTypeName =>
                    <ValuesFilterItem
                        key={entityTypeName}
                        title={entityTypeNameTranslator(entityTypeName)}
                        value={entityTypeName}>
                        {text =>
                            text
                                ? entityTypeName
                                : <Stack
                                    alignItems="center"
                                    direction="row"
                                    spacing={1}>
                                    <EntityIcon
                                        entityTypeName={entityTypeName}
                                        sx={{ fontSize: "24px" }}/>
                                    <Typography noWrap={true}>
                                        {entityTypeNameTranslator(entityTypeName)}
                                    </Typography>
                                </Stack>}
                    </ValuesFilterItem>)}
        </ValuesFilter>);
}