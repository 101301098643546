import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function AuthenticationIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                d="M2.01 12.172c-.59.007-1.067-.467-1.004-1.055a11.298 11.298 0 011.856-5.096 11.376 11.376 0 014.99-4.13 11.072 11.072 0 016.377-.703 10.739 10.739 0 015.617 2.961 10.784 10.784 0 012.963 5.626c.404 2.14.164 4.366-.69 6.396a11.393 11.393 0 01-4.11 5.009 11.233 11.233 0 01-5.081 1.87c-.587.064-1.06-.415-1.054-1.005.005-.59.489-1.067 1.074-1.145a9.067 9.067 0 003.893-1.484 9.19 9.19 0 003.316-4.04 8.998 8.998 0 00.557-5.16 8.7 8.7 0 00-2.39-4.538 8.663 8.663 0 00-4.531-2.389 8.932 8.932 0 00-5.145.567 9.177 9.177 0 00-5.499 7.237c-.076.587-.551 1.073-1.14 1.08z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M7.748 18.794c.818-.923.748-2.32-.156-3.119-.903-.8-2.298-.7-3.115.223-.817.924-.747 2.32.156 3.12.903.8 2.298.7 3.115-.224zm1.636 1.448c1.634-1.846 1.495-4.639-.312-6.239-1.807-1.599-4.597-1.399-6.231.447-1.634 1.847-1.494 4.64.312 6.24 1.807 1.599 4.597 1.399 6.231-.448z"
                fillRule="evenodd"/>
            <path
                d="M6.774 14.951l7.398-8.357a1.104 1.104 0 011.557-.112c.452.4.487 1.098.079 1.56L8.41 16.399l-1.636-1.448z"
                fillRule="evenodd"/>
            <path
                d="M13.588 10.549a1.105 1.105 0 01-.078-1.56 1.104 1.104 0 011.558-.111l1.636 1.448c.451.4.486 1.098.078 1.56a1.104 1.104 0 01-1.558.111l-1.636-1.448zM11.369 13.056a1.104 1.104 0 01-.078-1.56 1.104 1.104 0 011.557-.111l.818.724c.452.4.487 1.098.079 1.56a1.105 1.105 0 01-1.558.111l-.818-.724z"
                fillRule="evenodd"/>
        </SvgIcon>);
}