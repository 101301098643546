import { IconNodeContent, IconOutline, NodeContentContextMenuItem, useLocalization, useSetDirectedGraphContext } from "@infrastructure";
import _ from "lodash";
import React, { Fragment } from "react";
import { Icon } from ".";
import { EntityList, entityModelStore, EntityNodeContentProps, getEntityNodeContentSize, useEntityTypeNameTranslator, useNodeContentFilterContextMenuItems } from "../../..";

export function Many({ contextMenuItems = [], entityIds, entityTypeName, getTooltip, onFilter, textOptions, titlePosition, variant }: EntityNodeContentProps) {
    const setDirectedGraphContext = useSetDirectedGraphContext();
    const entityTypeNameTranslator = useEntityTypeNameTranslator();

    const entityModel =
        entityModelStore.useGet(
            _.isNil(entityTypeName)
                ? entityIds[0]
                : undefined);

    entityTypeName = entityModel?.entity.typeName ?? entityTypeName;

    const translatedEntityTypeName =
        entityTypeNameTranslator(
            entityTypeName!,
            {
                count: entityIds.length,
                includeCount: true,
                includeServiceName: false,
                variant: "text"
            });
    const filterContextMenuItems =
        useNodeContentFilterContextMenuItems(
            entityIds.length,
            translatedEntityTypeName,
            onFilter);

    const localization =
        useLocalization(
            "common.entityNodeContent.many",
            () => ({
                menu: {
                    entities: "View {{translatedEntityTypeName}}"
                }
            }));
    return (
        <Fragment>
            <IconNodeContent
                contextMenuItems={[
                    ...contextMenuItems,
                    new NodeContentContextMenuItem(
                        () =>
                            setDirectedGraphContext(
                                context => ({
                                    ...context,
                                    dialogContentElement:
                                        <EntityList
                                            entityIdOrModels={entityIds}
                                            entityTypeName={entityTypeName!}
                                            variant="dialog"/>
                                })),
                        localization.menu.entities({ translatedEntityTypeName })),
                    ...filterContextMenuItems
                ]}
                iconOptions={{
                    icon:
                        <IconOutline>
                            <Icon
                                entityIds={entityIds}
                                entityTypeName={entityTypeName!}/>
                        </IconOutline>,
                    shape: "circleGroup",
                    size: "large"
                }}
                size={getEntityNodeContentSize(titlePosition ?? "bottom", variant)}
                titleElementOrOptions={{
                    ...textOptions,
                    title:
                        entityTypeNameTranslator(
                            entityTypeName!,
                            {
                                count: entityIds.length,
                                includeCount: true
                            })
                }}
                titlePosition={titlePosition}
                tooltip={getTooltip?.(entityModel!)}/>
        </Fragment>);
}