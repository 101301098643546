import { UnexpectedError } from "@infrastructure";
import { useMemo } from "react";
import { Contract } from "../../../../../../../../../../../../../common";
import { RiskDefinition } from "../../../../../../../utilities";
import { useOciComputeInstanceVolumeEncryptionInTransitDisabledRiskClusterNodePoolDefinition, useOciComputeInstanceVolumeEncryptionInTransitDisabledRiskInstanceConfigurationDefinition, useOciComputeInstanceVolumeEncryptionInTransitDisabledRiskInstanceDefinition } from "./hooks";

export function useOciComputeInstanceVolumeEncryptionInTransitDisabledRiskDefinition(riskModel: Contract.RiskModel): RiskDefinition {
    const risk = riskModel.risk as Contract.OciComputeInstanceVolumeEncryptionInTransitDisabledRisk;
    const useDefinition =
        useMemo(
            () => {
                switch (risk.aggregatingEntityTypeName) {
                    case Contract.TypeNames.OciComputeInstance:
                        return useOciComputeInstanceVolumeEncryptionInTransitDisabledRiskInstanceDefinition;
                    case Contract.TypeNames.OciComputeInstanceConfiguration:
                        return useOciComputeInstanceVolumeEncryptionInTransitDisabledRiskInstanceConfigurationDefinition;
                    case Contract.TypeNames.OciContainerEngineClusterNodePool:
                        return useOciComputeInstanceVolumeEncryptionInTransitDisabledRiskClusterNodePoolDefinition;
                    default:
                        throw new UnexpectedError("risk.aggregatingEntityTypeName", risk.aggregatingEntityTypeName);
                }
            },
            []);

    return useDefinition(riskModel as Contract.OciComputeInstanceVolumeEncryptionInTransitDisabledRiskModel);
}