import { getItemWithValidation, ItemWithValidation } from "@infrastructure";
import _ from "lodash";
import { useCallback, useMemo, useState } from "react";
import { Contract } from "../controllers";

export function useDeliveries(deliveriesInitial: (Contract.DeliveryWrapper | Contract.Delivery)[] = []) {
    const [deliveries, setDeliveries] =
        useState<ItemWithValidation<Contract.DeliveryRequest>[]>(
            () =>
                _.map(
                    deliveriesInitial,
                    deliveryOrDeliveryWrapper =>
                        _.has(deliveryOrDeliveryWrapper, "id")
                            ? ({
                                delivery: getItemWithValidation((deliveryOrDeliveryWrapper as Contract.DeliveryWrapper).delivery, true),
                                id: (deliveryOrDeliveryWrapper as Contract.DeliveryWrapper).id
                            })
                            : ({
                                delivery: getItemWithValidation(deliveryOrDeliveryWrapper as Contract.Delivery, true),
                                id: undefined
                            })));

    const valid =
        useMemo(
            () =>
                !_.some(
                    deliveries,
                    ({ delivery }) => !(delivery as ItemWithValidation<Contract.Delivery>).valid),
            [deliveries]);

    const onChange =
        useCallback(
            (deliveries: Contract.DeliveryRequest[]) => setDeliveries(deliveries),
            [setDeliveries]);

    const createItem =
        useCallback(
            (typeName: string) => {
                const delivery: Contract.DeliveryRequest = ({
                    delivery:
                        getItemWithValidation<Contract.Delivery>({
                            destinations: [],
                            message: undefined,
                            typeName
                        },
                        _.includes(initialValidDeliveryTypeNames, typeName)),
                    id: undefined
                });
                onChange([...deliveries, delivery]);
            },
            [deliveries, onChange]);

    return useMemo(
        () => ({
            createItem,
            deliveries,
            onChange,
            valid
        }),
        [createItem, deliveries, onChange, valid]);
}

export const initialValidDeliveryTypeNames = [
    Contract.TypeNames.ResourceOwnerMailDelivery
];