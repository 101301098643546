import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function SystemDefaultIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                clipRule="evenodd"
                d="M11.8869 4C10.2715 4 8.95633 4.01816 7.05158 4.03624C5.71354 4.04894 4.62473 5.11765 4.58561 6.45836L4.50036 8.37989L4.58569 11.3042C4.6248 12.6444 5.71192 13.7121 7.0483 13.725C8.88075 13.7427 10.1264 13.76 11.8869 13.76C13.7229 13.76 15.1105 13.7411 17.0062 13.7227C18.2941 13.7102 19.3354 12.7259 19.4044 11.4737C19.4622 10.4247 19.5059 9.30117 19.4993 8.38716C19.4925 7.45908 19.4307 7.31564 19.3561 6.25363C19.2692 5.01542 18.2328 4.05081 16.9577 4.0385C15.0115 4.0197 13.5695 4 11.8869 4ZM7.03259 2.03633C8.9375 2.01824 10.2611 2 11.8869 2C13.5802 2 15.0309 2.01979 16.977 2.03859C19.2608 2.06065 21.1889 3.80148 21.3512 6.11355C21.4267 7.18927 21.492 7.38169 21.4992 8.37261C21.5063 9.34875 21.4599 10.5207 21.4014 11.5837C21.2725 13.9223 19.3338 15.7001 17.0257 15.7226C15.129 15.7411 13.7331 15.76 11.8869 15.76C10.1163 15.76 8.86272 15.7426 7.02899 15.7249C4.61397 15.7016 2.65686 13.7724 2.58654 11.3626L2.49951 8.37989L2.58646 6.40003C2.65682 3.98847 4.61693 2.05927 7.03259 2.03633Z"
                fillRule="evenodd"/>
            <rect
                height="2"
                rx="1"
                width="22"
                x="1"
                y="20"/>
        </SvgIcon>);
}