import { Loading, useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useState } from "react";
import { Contract, RadioGroup, ReportItem } from "../../../../../../../../../../common";
import { ComplianceReportItemProps, ComplianceSelector } from "./components";

export function useGetComplianceReportItem(contentType?: Contract.ReportContentType) {
    const [complianceReportContentType, setComplianceReportContentType] = useState<Contract.ReportContentType>(contentType ?? Contract.ReportContentType.Pdf);

    const localization =
        useLocalization(
            "views.customer.reports.addOrEdit.hooks.useItems.hooks.useGetComplianceReportItem",
            () => ({
                reportContentType: {
                    [Contract.TypeNames.ReportContentType]: {
                        [Contract.ReportContentType.Csv]: "CSV",
                        [Contract.ReportContentType.Pdf]: "PDF"
                    },
                    title: "File Format"
                }

            }));

    return ({ allComplianceIdentifiers, complianceIdentifier, customComplianceConfigurationMap, setComplianceIdentifier }: ComplianceReportItemProps): ReportItem =>
        ({
            configuration:
                <Stack spacing={3}>
                    <Loading>
                        <ComplianceSelector
                            allComplianceIdentifiers={allComplianceIdentifiers}
                            complianceIdentifier={complianceIdentifier}
                            customComplianceConfigurationMap={customComplianceConfigurationMap}
                            setComplianceIdentifier={setComplianceIdentifier}/>
                        <Stack spacing={1}>
                            <Typography variant="h4">
                                {localization.reportContentType.title()}
                            </Typography>
                            <RadioGroup
                                items={[
                                    {
                                        label: localization.reportContentType[Contract.TypeNames.ReportContentType][Contract.ReportContentType.Pdf](),
                                        value: Contract.ReportContentType.Pdf
                                    },
                                    {
                                        label: localization.reportContentType[Contract.TypeNames.ReportContentType][Contract.ReportContentType.Csv](),
                                        value: Contract.ReportContentType.Csv
                                    }
                                ]}
                                selectedValue={complianceReportContentType}
                                onChange={value => setComplianceReportContentType(value)}/>
                        </Stack>
                    </Loading>
                </Stack>,
            generate:
                name =>
                    _.has(customComplianceConfigurationMap, complianceIdentifier!)
                        ? new Contract.ReportControllerCustomComplianceReportRequestDefinition(
                            name,
                            undefined,
                            Contract.TypeNames.ReportControllerCustomComplianceReportRequestDefinition,
                            complianceReportContentType,
                            complianceIdentifier!)
                        : new Contract.ReportControllerBuiltInComplianceReportRequestDefinition(
                            name,
                            undefined,
                            Contract.TypeNames.ReportControllerBuiltInComplianceReportRequestDefinition,
                            complianceReportContentType,
                            complianceIdentifier as Contract.BuiltInComplianceSectionType),
            notValid: () => _.isNil(complianceIdentifier!)
        });
}