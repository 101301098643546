import { StorageItem } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract } from "../../../../../../../controllers";
import { TypeHelper } from "../../../../../../../utilities";
import { SeverityMultiSelect } from "../../../../../../SeverityMultiSelect";

type RisksWidgetSeverityMultiSelectProps = {
    selectedSeverities: Contract.Severity[];
    setSelectedSeverities: React.Dispatch<React.SetStateAction<Contract.Severity[]>>;
    storageItem?: StorageItem;
};

export function RisksWidgetSeverityMultiSelect({ selectedSeverities, setSelectedSeverities, storageItem }: RisksWidgetSeverityMultiSelectProps) {
    return (
        <SeverityMultiSelect
            selectedSeverities={selectedSeverities}
            onSelectedSeveritiesChanged={
                severities => {
                    const orderedSeverities =
                        _.orderBy(
                            severities,
                            severity => TypeHelper.getEnumValue(Contract.TypeNames.Severity, severity),
                            "desc");

                    setSelectedSeverities(orderedSeverities);
                    storageItem?.setValue(JSON.stringify(orderedSeverities));
                }}/>);
}