import { useMemo } from "react";
import { useGcpDefaultKubernetesNamespaceResourceDefinition, useGcpDefaultKubernetesResourceDefinition, useGcpKubernetesClusterRoleBindingDefinition, useGcpKubernetesClusterRoleDefinition, useGcpKubernetesGroupDefinition, useGcpKubernetesIngressDefinition, useGcpKubernetesNamespaceDefinition, useGcpKubernetesNamespaceRoleBindingDefinition, useGcpKubernetesNamespaceRoleDefinition, useGcpKubernetesServiceAccountDefinition, useGcpKubernetesServiceDefinition, useGcpKubernetesUserDefinition, useGcpKubernetesWorkloadResourceDefinition } from ".";
import { Contract, TypeHelper } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";

export function useGcpKubernetesResourceDefinition(definitionData: DefinitionData) {
    const useDefinition =
        useMemo(
            () => {
                switch (definitionData.entityTypeEntitiesViewName) {
                    case Contract.TypeNames.GcpKubernetesClusterRole:
                        return useGcpKubernetesClusterRoleDefinition;
                    case Contract.TypeNames.GcpKubernetesClusterRoleBinding:
                        return useGcpKubernetesClusterRoleBindingDefinition;
                    case Contract.TypeNames.GcpKubernetesGroup:
                        return useGcpKubernetesGroupDefinition;
                    case Contract.TypeNames.GcpKubernetesIngress:
                        return useGcpKubernetesIngressDefinition;
                    case Contract.TypeNames.GcpKubernetesNamespace:
                        return useGcpKubernetesNamespaceDefinition;
                    case Contract.TypeNames.GcpKubernetesNamespaceRole:
                        return useGcpKubernetesNamespaceRoleDefinition;
                    case Contract.TypeNames.GcpKubernetesNamespaceRoleBinding:
                        return useGcpKubernetesNamespaceRoleBindingDefinition;
                    case Contract.TypeNames.GcpKubernetesService:
                        return useGcpKubernetesServiceDefinition;
                    case Contract.TypeNames.GcpKubernetesServiceAccount:
                        return useGcpKubernetesServiceAccountDefinition;
                    case Contract.TypeNames.GcpKubernetesUser:
                        return useGcpKubernetesUserDefinition;
                    case Contract.TypeNames.GcpKubernetesWorkloadResourceBase:
                        return useGcpKubernetesWorkloadResourceDefinition;
                    default:
                        return TypeHelper.extendOrImplement(definitionData.entityTypeEntitiesViewName, Contract.TypeNames.GcpKubernetesNamespaceResourceBase)
                            ? useGcpDefaultKubernetesNamespaceResourceDefinition
                            : useGcpDefaultKubernetesResourceDefinition;
                }
            },
            []);

    return useDefinition(definitionData);
}