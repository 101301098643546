import { NumberFormatter, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { Contract, InfoItem } from "../../../../../../../../../../common";
import { EntitiesInfoCard, EntitiesInfoItem, Info } from "../../../../components";
import { NetworkingInfoCard } from "../../components";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";
import { useAwsCommonNetworkingInfoItemElements } from "./useAwsCommonNetworkingInfoItemElements";
import { useAwsDefaultResourceInfoItemElements } from "./useAwsDefaultResourceInfoItemElements";

export function useAwsAutoScalingAutoScalingGroupDefinition(resourceModel: Contract.AwsResourceModel, options?: DefinitionOptions) {
    const autoScalingGroup = resourceModel.entity as Contract.AwsAutoScalingAutoScalingGroup;
    const autoScalingGroupModel = resourceModel as Contract.AwsAutoScalingAutoScalingGroupModel;
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useAwsDefaultResourceInfoItemElements(resourceModel);
    const commonNetworkCardInfoItems =
        useAwsCommonNetworkingInfoItemElements({
            loadBalancerIdReferences: autoScalingGroupModel.loadBalancerIdReferences,
            resourceModel
        });

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsAutoScalingAutoScalingGroupDefinition",
            () => ({
                info: {
                    cards: {
                        instances: {
                            emptyMessage: "No instances",
                            title: "Instances"
                        }
                    },
                    items: {
                        image: "Image",
                        keyName: "Key Pair",
                        launchConfiguration: "Launch Configuration",
                        launchTemplate: "Launch Template",
                        maxCapacity: "Maximum Capacity",
                        nodeGroupId: "Cluster Node Group",
                        vpcId: "VPC"
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="keyName"
                        title={localization.info.items.keyName()}
                        value={autoScalingGroup.keyName}/>,
                    <InfoItem
                        key="maxCapacity"
                        title={localization.info.items.maxCapacity()}
                        value={NumberFormatter.humanize(autoScalingGroup.maxCapacity)}/>,
                    <EntitiesInfoItem
                        emptyValue={autoScalingGroup.imageRawId}
                        entityIdsOrModels={autoScalingGroupModel.imageId}
                        entityTypeName={Contract.TypeNames.AwsEc2Image}
                        key="image"
                        title={localization.info.items.image()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={autoScalingGroup.launchConfigurationId}
                        entityTypeName={Contract.TypeNames.AwsAutoScalingLaunchConfiguration}
                        key="launchConfiguration"
                        title={localization.info.items.launchConfiguration()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={autoScalingGroup?.launchTemplateRevisionReference?.launchTemplateIdReference}
                        entityTypeName={Contract.TypeNames.AwsEc2LaunchTemplate}
                        key="launchTemplate"
                        title={localization.info.items.launchTemplate()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={autoScalingGroupModel.nodeGroupId}
                        entityTypeName={Contract.TypeNames.AwsEksClusterNodeGroup}
                        key="nodeGroupId"
                        title={localization.info.items.nodeGroupId()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={autoScalingGroup.vpcIds}
                        entityTypeName={Contract.TypeNames.AwsEc2Vpc}
                        key="vpc"
                        location="miniGlance"
                        title={localization.info.items.vpcId()}/>
                ]}
                options={options?.infoOptions}>
                <NetworkingInfoCard>
                    {commonNetworkCardInfoItems.inboundAccessType}
                    {commonNetworkCardInfoItems.inboundExternalAccessScope}
                    {commonNetworkCardInfoItems.getVpcs()}
                    {commonNetworkCardInfoItems.getSubnets()}
                    {commonNetworkCardInfoItems.getSecurityGroups()}
                    {commonNetworkCardInfoItems.getLoadBalancers()}
                </NetworkingInfoCard>
                <EntitiesInfoCard
                    entityIdsOrModels={
                        _.map(
                            autoScalingGroup.instanceReferences,
                            instanceReferences => instanceReferences.idReference)}
                    entityTypeName={Contract.TypeNames.AwsEc2Instance}
                    title={localization.info.cards.instances.title()}/>
            </Info>
    });
}