import { DataTableColumn, DataTableColumnRenderProps, EmptyMessageText, StringHelper, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import { Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, EntitiesCell, Entity, EntityFilter, entityModelStore, ItemTable, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";

type DirectoryRoleAssignmentsProps = {
    directoryRoleModel: Contract.GciDirectoryDirectoryRoleModel;
};

export function DirectoryRoleAssignments({ directoryRoleModel }: DirectoryRoleAssignmentsProps) {
    const roleAssignmentModels = entityModelStore.useGet(directoryRoleModel.directoryRoleAssignmentIds);
    const roleAssignmentModelRelatedEntityModels =
        entityModelStore.useGet(
            _(roleAssignmentModels).
                map(roleAssignmentModels => roleAssignmentModels.entity as Contract.GciDirectoryDirectoryRoleAssignment).
                flatMap(
                    roleAssignment => [
                        roleAssignment.identityIdReference,
                        roleAssignment.organizationalUnitId
                    ]).
                filter().
                value() as string[]);

    const [roleAssignmentModelRelatedEntityModelMap, roleAssignments] =
        useMemo(
            () => {
                const roleAssignmentModelRelatedEntityModelMap =
                    _.keyBy(
                        roleAssignmentModelRelatedEntityModels,
                        roleAssignmentModelRelatedEntityModel => roleAssignmentModelRelatedEntityModel.entity.id);
                const roleAssignments =
                    _.map(
                        roleAssignmentModels,
                        roleAssignmentModel => roleAssignmentModel.entity as Contract.GciDirectoryDirectoryRoleAssignment);
                return [roleAssignmentModelRelatedEntityModelMap, roleAssignments];
            },
            [roleAssignmentModelRelatedEntityModels, roleAssignmentModels]);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gci.useGciDirectoryDirectoryRoleDefinition.directoryRoleAssignments",
            () => ({
                columns: {
                    assignedIdentityId: "Identity",
                    scope: {
                        customer: "Customer",
                        title: "Scope"
                    }
                },
                empty: "No Assignments",
                title: "Assignments"
            }));

    return (
        <ItemTable
            columnIdToGetItemValueMap={{
                [DirectoryRoleAssignmentsColumnId.AssignedIdentityIdReference]: {
                    getFilterValue: item => item.identityIdReference,
                    getSortValue: item => item.displayName
                },
                [DirectoryRoleAssignmentsColumnId.Scope]: {
                    getFilterValue:
                        item =>
                            item.scopeType === Contract.GciDirectoryDirectoryRoleAssignmentScopeType.Tenant
                                ? Contract.GciDirectoryDirectoryRoleAssignmentScopeType.Tenant
                                : item.organizationalUnitId!,
                    getSortValue:
                        item =>
                            StringHelper.getSortValue(
                                item.scopeType === Contract.GciDirectoryDirectoryRoleAssignmentScopeType.Tenant
                                    ? localization.columns.scope.customer()
                                    : roleAssignmentModelRelatedEntityModelMap[item.organizationalUnitId!].entity.displayName)
                }
            }}
            csvExportFilePrefixes={[entityTypeNameTranslator(directoryRoleModel.entity.typeName, { includeServiceName: false }), directoryRoleModel.entity.displayName, localization.title()]}
            defaultSortColumnIdOrIds={DirectoryRoleAssignmentsColumnId.AssignedIdentityIdReference}
            emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty()) }}
            getCsvItem={
                item => ({
                    "Identity": roleAssignmentModelRelatedEntityModelMap[item.identityIdReference].entity.displayReference,
                    "Scope":
                        item.scopeType === Contract.GciDirectoryDirectoryRoleAssignmentScopeType.Tenant
                            ? localization.columns.scope.customer()
                            : roleAssignmentModelRelatedEntityModelMap[item.organizationalUnitId!].entity.displayReference
                })}
            getItemId={roleAssignment => roleAssignment.identityIdReference}
            items={roleAssignments}>
            {columnIdToItemValuesMap => [
                <DataTableColumn
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <EntityFilter
                                    entityIdsOrSearchableReferences={columnIdToItemValuesMap[DirectoryRoleAssignmentsColumnId.AssignedIdentityIdReference]}
                                    placeholder={localization.columns.assignedIdentityId()}/>
                        }
                    }}
                    id={DirectoryRoleAssignmentsColumnId.AssignedIdentityIdReference}
                    key={DirectoryRoleAssignmentsColumnId.AssignedIdentityIdReference}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.GciDirectoryDirectoryRoleAssignment>) =>
                            <EntitiesCell
                                entityIdsOrModels={item.identityIdReference}
                                entityTypeName={Contract.TypeNames.IGciIdentity}
                                entityVariant="iconTextTypeTenant"/>}
                    title={localization.columns.assignedIdentityId()}/>,
                <DataTableColumn
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <ValuesFilter placeholder={localization.columns.scope.title()}>
                                    {_.map(
                                        columnIdToItemValuesMap[DirectoryRoleAssignmentsColumnId.Scope],
                                        scopeValue =>
                                            <ValuesFilterItem
                                                key={scopeValue}
                                                title={
                                                    scopeValue === Contract.GciDirectoryDirectoryRoleAssignmentScopeType.Tenant
                                                        ? localization.columns.scope.customer()
                                                        : roleAssignmentModelRelatedEntityModelMap[scopeValue].entity.displayName}
                                                value={scopeValue}>
                                                {() =>
                                                    scopeValue === Contract.GciDirectoryDirectoryRoleAssignmentScopeType.Tenant
                                                        ? <Typography noWrap={true}>{localization.columns.scope.customer()}</Typography>
                                                        : <Entity
                                                            entityIdOrModel={scopeValue}
                                                            linkOptions={{ disabled: true }}
                                                            variant="iconTextTypeTenant"/>}
                                            </ValuesFilterItem>)}
                                </ValuesFilter>
                        }
                    }}
                    id={DirectoryRoleAssignmentsColumnId.Scope}
                    key={DirectoryRoleAssignmentsColumnId.Scope}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.GciDirectoryDirectoryRoleAssignment>) =>
                            item.scopeType === Contract.GciDirectoryDirectoryRoleAssignmentScopeType.Tenant
                                ? <Typography noWrap={true}>{localization.columns.scope.customer()}</Typography>
                                : <EntitiesCell
                                    entityIdsOrModels={item.organizationalUnitId}
                                    entityTypeName={Contract.TypeNames.GciDirectoryOrganizationalUnit}
                                    entityVariant="iconTextTypeTenant"/>}
                    title={localization.columns.scope.title()}/>
            ]}
        </ItemTable>);
}

enum DirectoryRoleAssignmentsColumnId {
    AssignedIdentityIdReference = "assignedIdentityIdReference",
    Scope = "scope"
}