import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function VendorIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path d="M6.24006 20.254C5.04356 20.254 3.98001 20.0517 3.0494 19.6472C2.12433 19.2426 1.39037 18.6795 0.847518 17.9578C0.310203 17.2362 0.0276966 16.4025 0 15.4567H3.12418C3.16296 16.14 3.47593 16.6868 4.0631 17.0968C4.65581 17.5013 5.37869 17.7036 6.23175 17.7036C7.1402 17.7036 7.88801 17.474 8.47517 17.0148C9.06234 16.5501 9.35316 15.946 9.34762 15.2024C9.35316 14.4425 9.05126 13.822 8.44194 13.3409C7.83815 12.8598 6.99894 12.6193 5.92431 12.6193H4.42039V10.2739H5.92431C6.80507 10.2739 7.52241 10.0498 8.07634 9.60146C8.63582 9.15316 8.91278 8.56272 8.90724 7.83013C8.91278 7.12489 8.67182 6.55631 8.18436 6.12442C7.70244 5.68705 7.06541 5.46837 6.27329 5.46837C5.48671 5.46837 4.80537 5.67339 4.22928 6.08341C3.65319 6.49344 3.3513 7.05108 3.3236 7.75633H0.357287C0.379444 6.816 0.653641 5.99048 1.17988 5.27976C1.70611 4.56358 2.41238 4.00594 3.29867 3.60684C4.1905 3.20228 5.18758 3 6.28991 3C7.42547 3 8.41147 3.21048 9.24791 3.63144C10.0899 4.04694 10.7408 4.60731 11.2005 5.31256C11.6658 6.01781 11.8957 6.79686 11.8902 7.64972C11.8957 8.59005 11.616 9.38278 11.051 10.0279C10.486 10.6675 9.74091 11.0858 8.81584 11.2826V11.4138C10.0068 11.5833 10.9236 12.0206 11.5661 12.7259C12.2087 13.4311 12.5272 14.3086 12.5217 15.3583C12.5272 16.3041 12.2585 17.146 11.7157 17.884C11.1728 18.6221 10.4278 19.2016 9.48056 19.6226C8.53334 20.0435 7.45317 20.254 6.24006 20.254Z"/>
            <path d="M13.5654 11.2391V5.05373H15.1955V6.1108H15.2568C15.3671 5.74033 15.5612 5.45844 15.839 5.26515C16.1168 5.06783 16.4293 4.96917 16.7766 4.96917C16.9523 4.96917 17.1075 4.99132 17.2423 5.03561C17.3813 5.07991 17.5079 5.13629 17.6223 5.20474L17.1198 6.55779C17.034 6.52155 16.9421 6.49135 16.844 6.46719C16.746 6.439 16.6377 6.4249 16.5192 6.4249C16.1638 6.4249 15.8615 6.54571 15.6122 6.78733C15.363 7.02894 15.2384 7.33499 15.2384 7.70547V11.2391H13.5654Z"/>
            <path d="M24.0002 11.2391H22.3579V10.2666H22.2782C22.2087 10.4075 22.1046 10.5626 21.9657 10.7317C21.8267 10.9008 21.6388 11.0458 21.4019 11.1666C21.1649 11.2874 20.8646 11.3478 20.501 11.3478C19.7861 11.3478 19.1794 11.074 18.6809 10.5263C18.1866 9.97867 17.9394 9.18939 17.9394 8.1585C17.9394 7.1115 18.1907 6.3202 18.6932 5.78462C19.1957 5.24904 19.7983 4.98125 20.501 4.98125C21.0362 4.98125 21.4386 5.1081 21.7083 5.36179C21.982 5.61146 22.172 5.85107 22.2782 6.0806H22.3333V3H24.0002V11.2391ZM22.3701 8.14642C22.3701 7.59473 22.2516 7.14975 22.0147 6.81149C21.7818 6.4692 21.4468 6.29806 21.0097 6.29806C20.5603 6.29806 20.2191 6.47323 19.9862 6.82357C19.7575 7.17391 19.6431 7.61486 19.6431 8.14642C19.6431 8.69408 19.7615 9.14308 19.9985 9.49342C20.2355 9.84377 20.5725 10.0189 21.0097 10.0189C21.4386 10.0189 21.7716 9.84779 22.0086 9.5055C22.2496 9.15919 22.3701 8.70616 22.3701 8.14642Z"/>
        </SvgIcon>);
}