import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, TypeHelper } from "../../../../../../..";
import { EditItem } from "../../../../../components";
import { useRiskResolutionAutomationContext } from "../../../../../utilities";
import { AzureRiskResolutionAutomationContext } from "../useAzureDefinition";

export function AzureEditItem() {
    const {
        changeModels,
        isChangeResolvableChangeable
    } = useRiskResolutionAutomationContext() as AzureRiskResolutionAutomationContext;

    const localization =
        useLocalization(
            "common.riskResolutionAutomation.hooks.useDefinition.hooks.useAzureDefinition.azureEditItem",
            () => ({
                changeNotResolvable: {
                    aadChange: "This step must be performed manually by an Microsoft Entra ID admin as Tenable Cloud Security does not have permissions to manage Microsoft Entra ID groups.",
                    azureChange: "This step must be performed manually as Tenable Cloud Security does not have permissions at the management group level."
                },
                tenantsNotChangeable: "There are currently no permissions to perform the remediation."
            }));

    return (
        <EditItem
            getChangeNotResolvableMessage={
                (changeModel: Contract.ChangeModel) =>
                    changeModel.changeable
                        ? TypeHelper.extendOrImplement(changeModel.change.typeName, Contract.TypeNames.AadChange)
                            ? localization.changeNotResolvable.aadChange()
                            : localization.changeNotResolvable.azureChange()
                        : localization.tenantsNotChangeable()}
            isChangeResolvable={isChangeResolvableChangeable}
            nextDisabled={
                _.every(
                    changeModels,
                    changeModel => !isChangeResolvableChangeable(changeModel))}
            notChangeableMessage={
                _.every(changeModels, changeModel => !changeModel.changeable)
                    ? localization.tenantsNotChangeable()
                    : undefined}/>);
}