﻿import { OrderedWizardItem, useLocalization } from "@infrastructure";
import React, { useMemo } from "react";
import { ScannerCommand, ScannerItem } from "../../components";
import { ScanContainerImageStageItem, ScanIacStageItem, VariableItem } from "./components";

export function useGitLabPipeline() {
    const commands =
        useMemo(
            () =>
                [
                    {
                        contentElement: <ScanContainerImageStageItem/>,
                        type: ScannerCommand.ContainerImageScan
                    },
                    {
                        contentElement: <ScanIacStageItem/>,
                        type: ScannerCommand.IacScan
                    }
                ],
            []);

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCodePipelineItems.codePipeline.addOrEdit.hooks.useDefinition.hooks.useGitLabPipeline",
            () => ({
                steps: {
                    scanner: "Add Stages",
                    variable: "Add a Variable (Recommended)"
                },
                title: "Add the relevant stages to your GitLab Pipeline:"
            }));
    return {
        apiKeyItem: true,
        orderedWizardItems: [
            <OrderedWizardItem
                key="variable"
                title={localization.steps.variable()}>
                <VariableItem/>
            </OrderedWizardItem>,
            <OrderedWizardItem
                key="scannerItem"
                title={localization.steps.scanner()}>
                <ScannerItem
                    commands={commands}
                    title={localization.title()}/>
            </OrderedWizardItem>
        ]
    };
}