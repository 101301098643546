import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { ChangeStep, RiskDefinitionSection } from "../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { PolicySection, ResourceRiskPolicyDiff, UpsertResourcePolicyChangeActions } from "../../components";
import { useGetAwsEc2VpcEndpointRiskContext } from "../contexts";

export function useAwsEc2VpcEndpointUnrestrictedServiceAccessRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsEc2VpcEndpointUnrestrictedServiceAccessRisk;
    const unrestrictedServiceAccessRiskModel = riskModel as Contract.AwsEc2VpcEndpointUnrestrictedServiceAccessRiskModel;
    const vpcEndpointModel = entityModelStore.useGet(risk.entityId) as Contract.AwsEc2VpcEndpointModel;
    const vpcEndpoint = vpcEndpointModel.entity as Contract.AwsEc2VpcEndpoint;

    const getAwsEc2VpcEndpointRiskContext = useGetAwsEc2VpcEndpointRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsEc2VpcEndpointUnrestrictedServiceAccessRiskDefinition",
            () => ({
                description: {
                    type: {
                        principals: "{{vpcEndpoint}} has a policy that does not limit access to principals",
                        principalsAndResources: "{{vpcEndpoint}} has a policy that does not limit access to resources and principals",
                        resources: "{{vpcEndpoint}} has a policy that does not limit access to resources"
                    }
                },
                sections: {
                    policy: "Policy"
                }
            }));

    const upsertVpcEndpointServicePolicyChange = risk.resolutionChanges![0] as Contract.AwsUpsertVpcEndpointServicePolicyChange;
    return useCommonSectionsAndDescriptionDefinition(
        (vpcEndpoint.unrestrictedPrincipalsAccess && vpcEndpoint.unrestrictedResourcesAccess
            ? localization.description.type.principalsAndResources
            : vpcEndpoint.unrestrictedPrincipalsAccess
                ? localization.description.type.principals
                : localization.description.type.resources)({
            vpcEndpoint:
                <Entity
                    entityIdOrModel={vpcEndpointModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            new ChangeStep(
                upsertVpcEndpointServicePolicyChange,
                {
                    actionElements: [
                        <UpsertResourcePolicyChangeActions
                            key="upsertResourcePolicyChangeActions"
                            newPolicyFileTypeToFileMap={unrestrictedServiceAccessRiskModel.newPolicyFileTypeToFileMap}/>
                    ],
                    contentElement: <ResourceRiskPolicyDiff
                        existingRawPolicyDocument={vpcEndpoint.servicePolicyDocument!.raw}
                        newRawPolicyDocument={upsertVpcEndpointServicePolicyChange.rawDocument}
                        policyFileName="resource_policy"/>
                })
        ],
        riskModel,
        () => {
            const vpcEndpointRiskContext = getAwsEc2VpcEndpointRiskContext(vpcEndpointModel);
            return [
                vpcEndpointRiskContext.generalInformation,
                vpcEndpointRiskContext.sensitive,
                vpcEndpointRiskContext.vpc,
                vpcEndpointRiskContext.sensitiveVpc,
                vpcEndpointRiskContext.vpcEndpointTypeAndServiceName,
                vpcEndpointRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        },
        {
            sections: [
                new RiskDefinitionSection(
                    <PolicySection policyDocument={vpcEndpoint.servicePolicyDocument}/>,
                    localization.sections.policy(),
                    {
                        expandable: true
                    })
            ]
        });
}