﻿import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function ScansIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <g clipPath="url(#clip0_59326_861)">
                <path
                    clipRule="evenodd"
                    d="M11.1375 0.813284C12.3184 0.222804 13.7147 0.253143 14.8689 0.894363L20.9426 4.26863C22.2124 4.97411 23 6.31259 23 7.76526V11C23 11.5523 22.5523 12 22 12C21.4477 12 21 11.5523 21 11V7.76526C21 7.03893 20.6062 6.36968 19.9713 6.01694L13.8976 2.64268C13.3205 2.32207 12.6224 2.3069 12.0319 2.60214L11.5847 1.70771L12.0319 2.60214L5.10557 6.0653C4.428 6.40408 4 7.09661 4 7.85415V11C4 13.4815 5.09751 15.9425 6.84 17.954C8.52273 19.8966 10.7489 21.3506 12.9968 21.966C13.69 21.7647 14.1324 21.4859 14.8173 21.0544C15.1401 20.851 15.5168 20.6137 15.999 20.3345C16.4769 20.0578 17.0887 20.2209 17.3654 20.6989C17.6421 21.1769 17.479 21.7887 17.001 22.0654C16.6811 22.2506 16.3837 22.4381 16.0934 22.6213C15.22 23.1721 14.4098 23.6832 13.2388 23.9711L12.9997 24.0299L12.7605 23.971C9.98135 23.2855 7.30783 21.5487 5.32832 19.2636C3.34803 16.9776 2 14.0686 2 11V7.85415C2 6.33906 2.85601 4.95401 4.21115 4.27644L4.65836 5.17087L4.21115 4.27644L11.1375 0.813284Z"
                    fillRule="evenodd"/>
                <path
                    clipRule="evenodd"
                    d="M8.62825 7.62825C9.67081 6.5857 11.0848 6 12.5592 6C14.0336 6 15.4476 6.5857 16.4902 7.62825C17.5327 8.67081 18.1184 10.0848 18.1184 11.5592C18.1184 12.6959 17.7703 13.7966 17.1332 14.719L20.7071 18.2929C21.0976 18.6834 21.0976 19.3166 20.7071 19.7071C20.3166 20.0976 19.6834 20.0976 19.2929 19.7071L15.719 16.1332C14.7966 16.7703 13.6959 17.1184 12.5592 17.1184C11.0848 17.1184 9.67081 16.5327 8.62825 15.4902C7.5857 14.4476 7 13.0336 7 11.5592C7 10.0848 7.5857 8.67081 8.62825 7.62825ZM12.5592 8C11.6152 8 10.7099 8.37499 10.0425 9.04247C9.37499 9.70995 9 10.6152 9 11.5592C9 12.5032 9.37499 13.4085 10.0425 14.076C10.71 14.7434 11.6152 15.1184 12.5592 15.1184C13.5032 15.1184 14.4085 14.7434 15.076 14.076C15.7434 13.4085 16.1184 12.5032 16.1184 11.5592C16.1184 10.6152 15.7434 9.70995 15.076 9.04247C14.4085 8.37499 13.5032 8 12.5592 8Z"
                    fillRule="evenodd"/>
            </g>
            <defs>
                <clipPath id="clip0_59326_861">
                    <rect
                        height="24"
                        width="24"/>
                </clipPath>
            </defs>
        </SvgIcon>);
}