﻿import _, { Dictionary } from "lodash";
import { EntityTableDefinition } from "../../..";
import { Contract, CustomerConsoleAppUrlHelper, ItemSelectionHelper, TimeRangeHelper } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";
import { useCommonEntityDefinition } from "../useCommonEntityDefinition";
import { useCommonUnmanagedKubernetesClusterDefinition } from "../useCommonUnmanagedKubernetesClusterDefinition";

export function useAzureUnmanagedKubernetesClusterDefinition(definitionData: DefinitionData) {
    const commonUnmanagedKubernetesClusterDefinition = useCommonUnmanagedKubernetesClusterDefinition(definitionData);
    const commonResourceDefinition = useCommonEntityDefinition(definitionData);

    return new EntityTableDefinition(
        _.filter([
            commonUnmanagedKubernetesClusterDefinition.columns.platformColumn,
            commonResourceDefinition.columns.tenantColumn,
            commonUnmanagedKubernetesClusterDefinition.columns.creationTimeColumn,
            commonUnmanagedKubernetesClusterDefinition.columns.versionColumn,
            commonUnmanagedKubernetesClusterDefinition.columns.createApiConnectivityStatusColumn(CustomerConsoleAppUrlHelper.getDocsOnboardClusterViaTenableConnectorRelativeUrl()),
            commonResourceDefinition.columns.attributesColumn,
            commonResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ]),
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAzureUnmanagedKubernetesClusterRequest(
                new Contract.EntityControllerGetEntityModelPageAzureUnmanagedKubernetesClusterRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.KubernetesClusterApiConnectivityStatus]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.UnmanagedKubernetesClusterCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.UnmanagedKubernetesClusterPlatform]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.KubernetesClusterVersion])),
                limit,
                skip,
                requestSort));
}