import { IconText } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, EntitiesCell, EntityIcon } from "../../../common";

type GciPrincipalReferenceCellProps = {
    principalReference: Contract.GciPrincipalReference;
};

export function GciPrincipalReferenceCell({ principalReference }: GciPrincipalReferenceCellProps) {
    return (
        _.isNil(principalReference.id)
            ? <IconText
                icon={
                    <EntityIcon
                        entityTypeName={
                            principalReference.type === Contract.GciPrincipalType.GkeServiceAccount
                                ? Contract.TypeNames.GcpIamServiceAccount
                                : Contract.TypeNames.UnknownEntity}/>}
                text={principalReference.rawId}/>
            : <EntitiesCell
                entityIdsOrModels={principalReference.id}
                entityTypeName={Contract.TypeNames.IGciPrincipal}
                entityVariant="iconTextTypeTenant"/>);
}