import { useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Compliances, Contract, InfoItem, RiskPoliciesType, RiskPolicyCategory, RiskPolicyItem, Severity, TenantsIcon } from "../../../../../../../../../common";
import { useEnabledTranslator } from "../../../../../hooks";
import { ActionsCell } from "../../../../ActionsCell";

export function useCommonRiskPolicyInfoItems(item: RiskPolicyItem, scopeId: string) {
    const enabledTranslator = useEnabledTranslator();
    const localization =
        useLocalization(
            "views.customer.riskPolicies.profile.hooks.useRiskPolicyInfoItems.hooks.useCommonRiskPolicyInfoItems",
            () => ({
                category: "Category",
                compliance: "Compliance",
                configuration: {
                    status: {
                        title: "Status"
                    }
                },
                severity: "Severity",
                tenantId: "Platform"
            }));

    return {
        category:
            <InfoItem
                title={localization.category()}
                value={
                    <RiskPolicyCategory
                        riskPolicyCategory={item.riskPolicyTypeMetadata.category}
                        variant="riskPolicy"/>}/>,
        getActions:
            (riskPoliciesType: RiskPoliciesType) =>
                <ActionsCell
                    item={item}
                    riskPoliciesType={riskPoliciesType}
                    scopeId={scopeId}/>,
        getCompliances:
            (builtInComplianceTypes: Contract.BuiltInComplianceSectionType[], compliancesScopeId?: string) =>
                <InfoItem
                    title={localization.compliance()}
                    vertical={_.size(item.customComplianceIds) + _.size(builtInComplianceTypes) > 1}>
                    {_.isEmpty(builtInComplianceTypes) && _.isEmpty(item.customComplianceIds)
                        ? undefined
                        : <Compliances
                            builtInComplianceTypes={builtInComplianceTypes}
                            customComplianceIds={item.customComplianceIds}
                            scopeId={compliancesScopeId}
                            variant="list"/>}
                </InfoItem>,
        getDescription:
            (description?: string) =>
                _.isNil(description)
                    ? undefined
                    : <InfoItem
                        containerSx={{ maxWidth: "700px" }}
                        value={
                            <Typography sx={{ whiteSpace: "pre-line" }}>
                                {description}
                            </Typography>}
                        vertical={true}/>,
        getSeverity:
            (severity?: Contract.Severity) =>
                <InfoItem
                    title={localization.severity()}
                    value={
                        <Severity
                            dynamicRiskPolicyConfigurationTypeName={item.riskPolicyConfigurationTypeName}
                            severity={severity}/>}/>,
        getTenantId:
            (tenantTypes: Contract.TenantType[]) =>
                <InfoItem
                    title={localization.tenantId()}
                    value={
                        <TenantsIcon
                            sx={{ fontSize: "24px" }}
                            tenantTypes={tenantTypes}
                            variant="iconText"/>}
                    vertical={_.size(tenantTypes) > 1}/>,
        status:
            <InfoItem
                title={localization.configuration.status.title()}
                value={enabledTranslator(item.enabled)}/>
    };
}