import { DataTableColumn, optionalTableCell, PagedValuesFilter, useLocalization } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { useOciCommonIamResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, ItemSelectionHelper, TenantEntityParentPathCell, TimeRangeHelper } from "../../../../../../../../../../common";
import { DefinitionData, ExportData } from "../../../../Table";

export function useOciTenantEntityDefinition(definitionData: DefinitionData) {
    const commonIamResourceDefinition = useOciCommonIamResourceDefinition(definitionData);

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.oci.useOciTenantEntityDefinition",
            () => ({
                columns: {
                    parentTenantEntityPath: "Compartment Path"
                }
            }));

    return new EntityTableDefinition(
        [
            commonIamResourceDefinition.columns.tenantColumn,
            commonIamResourceDefinition.columns.creationTimeColumn,
            commonIamResourceDefinition.columns.creatorCsvColumn,
            commonIamResourceDefinition.columns.enabledColumn,
            <DataTableColumn
                exportOptions={{
                    getData: (item: Contract.OciTenantEntityModel) => [(item.entity as Contract.OciTenantEntity).parentTenantEntityId],
                    getItem:
                        (item: Contract.OciTenantEntityModel, exportData: ExportData) => ({
                            [localization.columns.parentTenantEntityPath()]:
                                _.isNil((item.entity as Contract.OciTenantEntity).parentTenantEntityId) ||
                                _.isNil(exportData.relatedEntityModelMap[(item.entity as Contract.OciTenantEntity).parentTenantEntityId!]?.consoleUrl)
                                    ? item.parentEntityPath ?? ""
                                    : `${item.parentEntityPath} (${exportData.relatedEntityModelMap[(item.entity as Contract.OciTenantEntity).parentTenantEntityId!].consoleUrl})`
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedValuesFilter
                                getValuePage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.OciTenantEntityParentPath)}
                                placeholder={localization.columns.parentTenantEntityPath()}/>
                    }
                }}
                id={Contract.EntityModelProperty.OciTenantEntityParentPath}
                key={Contract.EntityModelProperty.OciTenantEntityParentPath}
                render={
                    optionalTableCell<Contract.OciTenantEntityModel>(
                        item =>
                            _.isNil(item.parentEntityPath)
                                ? undefined
                                : <TenantEntityParentPathCell
                                    parentEntityId={(item.entity as Contract.OciTenantEntity).parentTenantEntityId}
                                    parentEntityPath={item.parentEntityPath}/>)}
                title={localization.columns.parentTenantEntityPath()}/>,
            commonIamResourceDefinition.columns.tagsColumn,
            commonIamResourceDefinition.columns.attributesColumn,
            commonIamResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonIamResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonIamResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageOciTenantEntityRequest(
                new Contract.EntityControllerGetEntityModelPageOciTenantEntityRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    undefined,
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciIamResourceEnabled]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciTenantEntityParentPath])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags]))));
}