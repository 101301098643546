import { DialogMenuItem, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useAzureCommonComputeVirtualMachineResourceInfoItemElements, useAzureCommonManagedIdentityOriginatorResourceInfoElements, useAzureCommonNetworkingInfoItemElements, useAzureDefaultResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../..";
import { useCommonVirtualMachineDiskResourceInfoItemElements, useCustomEntityPropertyInfoItemElements } from "..";
import { Contract, InfoItem } from "../../../../../../../../../../common";
import { useAzureComputeVirtualMachineStatusTranslator } from "../../../../../../../../../../tenants";
import { useGetVirtualMachinePriorityScanRequestDialogMenuItem } from "../../../../../../../WorkloadAnalysis";
import { EntitiesInfoItem, Info } from "../../../../components";
import { AzureResourceRoleAssignmentResources, NetworkingInfoCard } from "../../components";

export function useAzureComputeVirtualMachineScaleSetVirtualMachineDefinition(resourceGroupResourceModel: Contract.AzureResourceGroupResourceModel, options?: DefinitionOptions) {
    const commonComputeVirtualMachineResourceInfoItemElements = useAzureCommonComputeVirtualMachineResourceInfoItemElements(resourceGroupResourceModel as Contract.AzureComputeVirtualMachineResourceModel);
    const commonManagedIdentityOriginatorResourceInfoElements = useAzureCommonManagedIdentityOriginatorResourceInfoElements(resourceGroupResourceModel);
    const commonVirtualMachineDiskResourceInfoItemElements = useCommonVirtualMachineDiskResourceInfoItemElements(resourceGroupResourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceGroupResourceModel);
    const defaultResourceInfoItemElements = useAzureDefaultResourceInfoItemElements(resourceGroupResourceModel);
    const virtualMachineScaleSetVirtualMachineModel = resourceGroupResourceModel as Contract.AzureComputeVirtualMachineScaleSetVirtualMachineModel;
    const getVirtualMachinePriorityScanRequestDialogMenuItem = useGetVirtualMachinePriorityScanRequestDialogMenuItem(virtualMachineScaleSetVirtualMachineModel);
    const virtualMachineScaleSetVirtualMachine = resourceGroupResourceModel.entity as Contract.AzureComputeVirtualMachineScaleSetVirtualMachine;
    const networkInfoItems =
        useAzureCommonNetworkingInfoItemElements({
            networkInterfaceIds: virtualMachineScaleSetVirtualMachine.networkInterfaceIds,
            resourceModel: resourceGroupResourceModel
        });

    const computeVirtualMachineStatusTranslator = useAzureComputeVirtualMachineStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.azure.useAzureComputeVirtualMachineScaleSetVirtualMachineDefinition",
            () => ({
                info: {
                    items: {
                        availabilityZone: {
                            empty: "N/A",
                            title: "Availability Zone"
                        },
                        dnsZoneIds: "DNS Zones",
                        status: "Status",
                        virtualMachineScaleSetId: "Virtual Machine Scale Set"
                    }
                },
                tabs: {
                    resourceRoleAssignments: "Resource Role Assignments"
                },
                toolbar: {
                    menu: {
                        generate: "Least Privilege Policy"
                    }
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <AzureResourceRoleAssignmentResources
                    csvExportFilePrefix={localization.tabs.resourceRoleAssignments()}
                    scopeResourceModel={resourceGroupResourceModel}/>,
                localization.tabs.resourceRoleAssignments(),
                "resourceRoleAssignments")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    commonComputeVirtualMachineResourceInfoItemElements.rawIdInfoItemElement,
                    commonComputeVirtualMachineResourceInfoItemElements.rawTypeInfoItemElement,
                    commonComputeVirtualMachineResourceInfoItemElements.imageInfoInfoItemElement,
                    ...commonManagedIdentityOriginatorResourceInfoElements,
                    <InfoItem
                        emptyValue={localization.info.items.availabilityZone.empty()}
                        key="availabilityZone"
                        title={localization.info.items.availabilityZone.title()}
                        value={virtualMachineScaleSetVirtualMachine.availabilityZoneName}/>,
                    <InfoItem
                        key="status"
                        location="all"
                        title={localization.info.items.status()}
                        value={computeVirtualMachineStatusTranslator(virtualMachineScaleSetVirtualMachine.status)}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={virtualMachineScaleSetVirtualMachineModel.dnsZoneIds}
                        entityTypeName={Contract.TypeNames.AzureNetworkDnsZone}
                        key="dnsZoneIds"
                        title={localization.info.items.dnsZoneIds()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={virtualMachineScaleSetVirtualMachine.virtualMachineScaleSetId}
                        entityTypeName={Contract.TypeNames.AzureComputeVirtualMachineScaleSet}
                        key="virtualMachineScaleSet"
                        title={localization.info.items.virtualMachineScaleSetId()}/>,
                    commonVirtualMachineDiskResourceInfoItemElements.operatingSystemDisplayNameInfoElement,
                    commonVirtualMachineDiskResourceInfoItemElements.operatingSystemTypeInfoElement,
                    commonVirtualMachineDiskResourceInfoItemElements.vulnerabilitiesInfoElement,
                    commonVirtualMachineDiskResourceInfoItemElements.workloadAnalysisStatusInfoElement,
                    commonVirtualMachineDiskResourceInfoItemElements.scanTimeInfoElement
                ]}
                options={options?.infoOptions}>
                <NetworkingInfoCard>
                    {networkInfoItems.inboundAccessType}
                    {networkInfoItems.inboundExternalAccessScope}
                    {networkInfoItems.getPublicIpAddresses()}
                    {networkInfoItems.getPrivateIpAddresses()}
                    {networkInfoItems.getVirtualNetwork()}
                    {networkInfoItems.getSubnets()}
                    {networkInfoItems.getNetworkInterfaces()}
                    {networkInfoItems.getSubnetNetworkSecurityGroupIds()}
                    {networkInfoItems.getInterfaceNetworkSecurityGroups()}
                    {networkInfoItems.getApplicationSecurityGroups()}
                    {commonComputeVirtualMachineResourceInfoItemElements.loadBalancersInfoItemElement}
                    {commonComputeVirtualMachineResourceInfoItemElements.applicationGatewaysInfoItemElement}
                </NetworkingInfoCard>
            </Info>,
        topbarMenuItems: _.filter([getVirtualMachinePriorityScanRequestDialogMenuItem()]) as DialogMenuItem[]
    });
}