import { DataTableColumn, DataTableColumnRenderProps, EnumValuesFilter, useLocalization } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { useAzureCommonEncryptedResourceDefinition, useAzureCommonResourceGroupResourceDefinition } from ".";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { useAzureEventHubNamespaceSkuTranslator } from "../../../../../../hooks/useAzureEventHubNamespaceSkuTranslator";
import { useAzureEventHubNamespaceStatusTranslator } from "../../../../../../hooks/useAzureEventHubNamespaceStatusTranslator";
import { DefinitionData } from "../../../../Table";
import { EntityTableDefinition } from "../../useDefinition";
import { useCommonNetworkAccessResourceDefinition } from "../useCommonNetworkAccessResourceDefinition";

export function useAzureEventHubNamespaceDefinition(definitionData: DefinitionData) {
    const commonEncryptedResourceDefinition = useAzureCommonEncryptedResourceDefinition(definitionData);
    const commonResourceGroupResourceDefinition = useAzureCommonResourceGroupResourceDefinition(definitionData);
    const commonNetworkAccessResourceDefinition = useCommonNetworkAccessResourceDefinition();
    const entitiesExportOptions = useEntitiesExportOptions();

    const eventHubNamespaceSkuTranslator = useAzureEventHubNamespaceSkuTranslator();
    const eventHubNamespaceStatusTranslator = useAzureEventHubNamespaceStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.azure.useAzureEventHubNamespaceDefinition",
            () => ({
                columns: {
                    eventHubs: "Event Hubs",
                    sku: "Tier",
                    status: "Status"
                }
            }));

    return new EntityTableDefinition(
        _.filter([
            commonResourceGroupResourceDefinition.columns.tenantColumn,
            commonResourceGroupResourceDefinition.columns.creationTimeColumn,
            commonResourceGroupResourceDefinition.columns.creatorIdentityCsvColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AzureEventHubNamespaceModel) => ({
                            [localization.columns.status()]: eventHubNamespaceStatusTranslator((item.entity as Contract.AzureEventHubNamespace).status)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.AzureEventHubNamespaceStatus}
                                enumTypeTranslator={eventHubNamespaceStatusTranslator}
                                placeholder={localization.columns.status()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AzureEventHubNamespaceStatus}
                itemProperty={(item: Contract.AzureEventHubNamespaceModel) => eventHubNamespaceStatusTranslator((item.entity as Contract.AzureEventHubNamespace).status)}
                key={Contract.EntityModelProperty.AzureEventHubNamespaceStatus}
                title={localization.columns.status()}/>,
            commonNetworkAccessResourceDefinition.columns.accessLevelColumn,
            commonNetworkAccessResourceDefinition.columns.inboundExternalAccessScopeColumn,
            commonEncryptedResourceDefinition.columns.encryptionExistsColumn,
            commonEncryptedResourceDefinition.columns.vaultKeyColumn,
            commonResourceGroupResourceDefinition.columns.regionColumn,
            commonResourceGroupResourceDefinition.columns.regionLocationColumn,
            commonResourceGroupResourceDefinition.columns.resourceGroupColumn,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.AzureEventHubNamespaceModel>(
                        Contract.TypeNames.AzureEventHubNamespaceEventHub,
                        item => item.eventHubIds,
                        localization.columns.eventHubs())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AzureEventHubNamespaceEventHubs)}
                                placeholder={localization.columns.eventHubs()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AzureEventHubNamespaceEventHubs}
                key={Contract.EntityModelProperty.AzureEventHubNamespaceEventHubs}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AzureEventHubNamespaceModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.eventHubIds}
                            entityTypeName={Contract.TypeNames.AzureEventHubNamespaceEventHub}
                            entityVariant="iconText"/>}
                title={localization.columns.eventHubs()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AzureEventHubNamespaceModel) => ({
                            [localization.columns.sku()]: eventHubNamespaceSkuTranslator((item.entity as Contract.AzureEventHubNamespace).sku)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.AzureEventHubNamespaceSku}
                                enumTypeTranslator={eventHubNamespaceSkuTranslator}
                                placeholder={localization.columns.sku()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AzureEventHubNamespaceSku}
                itemProperty={(item: Contract.AzureEventHubNamespaceModel) => eventHubNamespaceSkuTranslator((item.entity as Contract.AzureEventHubNamespace).sku)}
                key={Contract.EntityModelProperty.AzureEventHubNamespaceSku}
                title={localization.columns.sku()}/>,
            commonResourceGroupResourceDefinition.columns.tagsColumn,
            commonResourceGroupResourceDefinition.columns.attributesColumn,
            commonResourceGroupResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonResourceGroupResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonResourceGroupResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ]),
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAzureEventHubNamespaceRequest(
                new Contract.EntityControllerGetEntityModelPageAzureEventHubNamespaceRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureResourceGroupResourceResourceGroup]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureEventHubNamespaceEventHubs]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureEventHubNamespaceSku]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureEventHubNamespaceStatus])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags])),
                new Contract.EntityControllerGetEntityModelPageAzureResourceRequestEncrypted(
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureEncryptedResourceEncryptionExists]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureEncryptedResourceVaultKeys])),
                new Contract.EntityControllerGetEntityModelPageEntityRequestNetworkAccess(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.NetworkInboundAccessType]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.NetworkInboundExternalAccessScope]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.NetworkInboundExternalDestinationNetworkScopes]))));
}