import { Stack } from "@mui/material";
import _, { Function0 } from "lodash";
import React, { useCallback } from "react";
import { EmptyMessage, Loading, useLocalization } from "@infrastructure";
import { WidgetDefinition } from "../../../../..";
import { Contract } from "../../../../../../../../controllers";
import { useGetTopCriticalVulnerabilityModels } from "../../../../../../../../hooks";
import { CustomerConsoleAppUrlHelper, ScopeHelper } from "../../../../../../../../utilities";
import { SummaryDashboardContext, useDashboardContext } from "../../../../../../Dashboard";
import { Analyzing } from "../useGetComplianceDefinition/components";
import { Vulnerability } from "./components";

export function useGetWorkloadAnalysisCriticalVulnerabilitiesDefinition(): () => WidgetDefinition {
    const localization =
        useLocalization(
            "common.dashboard.widget.hooks.useDefinition.hooks.useGetWorkloadAnalysisCriticalVulnerabilitiesDefinition",
            () => ({
                details: "Show more",
                helpText: "View critical CVEs found on your machines, prioritized by those with known exploits and those that were recently seen.",
                title: "Critical Vulnerabilities Overview"
            }));

    return useCallback<Function0<WidgetDefinition>>(
        () => ({
            element: <WorkloadAnalysisCriticalVulnerabilities/>,
            options: {
                details: {
                    detailsUrl: CustomerConsoleAppUrlHelper.getWorkloadAnalysisVulnerabilitiesRelativeUrl(Contract.Severity.Critical),
                    title: localization.details()
                },
                helpText: localization.helpText(),
                title: localization.title(),
                variant: "scrollable"
            }
        }),
        [localization]);
}

function WorkloadAnalysisCriticalVulnerabilities() {
    const { analyzing } = useDashboardContext<SummaryDashboardContext>();

    return (
        <Loading>
            {analyzing
                ? <Analyzing/>
                : <Core/>}
        </Loading>);
}

function Core() {
    const topCriticalVulnerabilityModels = useGetTopCriticalVulnerabilityModels(ScopeHelper.customerId);
    const localization =
        useLocalization(
            "common.dashboard.widget.hooks.useDefinition.hooks.useGetWorkloadAnalysisCriticalVulnerabilitiesDefinition.core",
            () => ({
                empty: "No Critical Vulnerabilities"
            }));

    return _.isEmpty(topCriticalVulnerabilityModels)
        ? <EmptyMessage
            message={localization.empty()}
            verticalCenter={true}/>
        : <Stack
            className="workloadAnalysisCriticalVulnerabilities"
            spacing={1}>
            {_.map(
                topCriticalVulnerabilityModels,
                vulnerability =>
                    <Vulnerability
                        key={vulnerability.rawId}
                        vulnerability={vulnerability}/>)}
        </Stack>;
}