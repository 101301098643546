import { useMemo } from "react";
import { Contract, usePrincipalModelAccess } from "../../../../../../../../../../../../common";
import { useGetAadDirectoryEntityRiskContext } from "./useGetAadDirectoryEntityRiskContext";

export function useGetAadDirectoryGroupRiskContext() {
    return useMemo(
        () => useAadDirectoryGroupRiskContext,
        []);
}

function useAadDirectoryGroupRiskContext(groupModel: Contract.AadDirectoryGroupModel) {
    const aadDirectoryEntityRiskContext = useGetAadDirectoryEntityRiskContext()(groupModel);
    const groupModelAccess = usePrincipalModelAccess<Contract.GroupModelAccess>(groupModel.id);

    return {
        ...aadDirectoryEntityRiskContext,
        activityTime: groupModelAccess.activity.time
    };
}