﻿import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, useLocalization } from "@infrastructure";
import { Dictionary } from "lodash";
import React from "react";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";
import { useGcpCommonComputeLoadBalancerResourceDefinition } from "./useGcpCommonComputeLoadBalancerResourceDefinition";

export function useGcpComputeUrlMapDefinition(definitionData: DefinitionData) {
    const commonComputeLoadBalancerResourceDefinition = useGcpCommonComputeLoadBalancerResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.gcp.useGcpComputeUrlMapDefinition",
            () => ({
                columns: {
                    backendResourceIdReferences: "Backends"
                }
            }));

    return new EntityTableDefinition(
        [
            commonComputeLoadBalancerResourceDefinition.columns.tenantColumn,
            commonComputeLoadBalancerResourceDefinition.columns.creationTimeColumn,
            commonComputeLoadBalancerResourceDefinition.columns.creatorIdentityCsvColumn,
            commonComputeLoadBalancerResourceDefinition.columns.creatorOriginatorEntityCsvColumn,
            commonComputeLoadBalancerResourceDefinition.columns.accessLevelColumn,
            commonComputeLoadBalancerResourceDefinition.columns.loadBalancerIdsColumn,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.GcpComputeUrlMapModel>(
                        Contract.TypeNames.GcpScopeResource,
                        item => item.backendResourceIdReferences,
                        localization.columns.backendResourceIdReferences())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.GcpComputeUrlMapBackendResources)}
                                placeholder={localization.columns.backendResourceIdReferences()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpComputeUrlMapBackendResources}
                key={Contract.EntityModelProperty.GcpComputeUrlMapBackendResources}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.GcpComputeUrlMapModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.backendResourceIdReferences}
                            entityTypeName={Contract.TypeNames.GcpScopeResource}
                            entityVariant="iconText"/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.backendResourceIdReferences()}/>,
            commonComputeLoadBalancerResourceDefinition.columns.regionColumn,
            commonComputeLoadBalancerResourceDefinition.columns.regionLocationColumn,
            commonComputeLoadBalancerResourceDefinition.columns.attributesColumn,
            commonComputeLoadBalancerResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonComputeLoadBalancerResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonComputeLoadBalancerResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageGcpComputeUrlMapRequest(
                new Contract.EntityControllerGetEntityModelPageGcpComputeUrlMapRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.GcpResourceUpdateTime]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpScopeResourceAccessLevel]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpScopeResourceRawId]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpComputeLoadBalancerResourceLoadBalancers]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpComputeUrlMapBackendResources])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags]))));
}