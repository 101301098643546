import { Action0, Action1, ConfirmDialog, Message, TimeFormatter, useLocalization } from "@infrastructure";
import { CircularProgress, Stack, Typography } from "@mui/material";
import React, { Fragment, useState } from "react";
import { AuditEventController, Contract, UserHelper, useTheme } from "../../../../../../../../../../../../../../../common";

type ViewProps = {
    onDelete: Action1<string>;
    onEdit: Action0;
    riskNoteCreationAuditEvent: Contract.RiskNoteCreationAuditEvent;
};

export function View({ onDelete, onEdit, riskNoteCreationAuditEvent }: ViewProps) {
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [error, setError] = useState(false);
    const [executing, setExecuting] = useState(false);

    async function deleteNote() {
        setExecuting(true);

        try {
            await AuditEventController.deleteRiskNoteCreationAuditEvent(
                new Contract.AuditEventControllerDeleteRiskNoteCreationAuditEventRequest(
                    riskNoteCreationAuditEvent.id));

            onDelete(riskNoteCreationAuditEvent.id);
        } catch {
            setError(true);
            setExecuting(false);
            return;
        }

        setError(false);
        setExecuting(false);
    }

    const localization =
        useLocalization(
            "views.customer.risks.profile.core.auditEventsSection.auditEvent.riskNoteCreationAuditEvent.view",
            () => ({
                actions: {
                    delete: {
                        confirm: "Are you sure you want to delete this note?",
                        error: "failed to delete note",
                        title: "Delete"
                    },
                    update: {
                        title: "Edit"
                    }
                }
            }));

    const theme = useTheme();
    return (
        <Stack>
            <Typography
                sx={{
                    border: theme.border.primary,
                    borderRadius: theme.spacing(0.75),
                    overflowX: "auto",
                    padding: theme.spacing(1),
                    whiteSpace: "pre-wrap"
                }}>
                {riskNoteCreationAuditEvent.message!}
            </Typography>
            <Stack
                alignItems="center"
                direction="row"
                spacing={1}
                sx={{ marginTop: theme.spacing(0.5) }}>
                <Typography
                    sx={{
                        color: theme.palette.text.secondary,
                        fontSize: "11px",
                        paddingTop: theme.spacing(0.25)
                    }}>
                    {TimeFormatter.longDateTime(riskNoteCreationAuditEvent.systemCreationTime)}
                </Typography>
                {UserHelper.hasScopePermissions(riskNoteCreationAuditEvent.scopeId, Contract.IdentityPermission.SecurityWrite) &&
                    riskNoteCreationAuditEvent.identityReference.id === UserHelper.mail &&
                    <Fragment>
                        <Typography
                            sx={{
                                "&:hover": {
                                    color: theme.palette.text.primary
                                },
                                color: theme.palette.text.secondary,
                                cursor: "pointer",
                                textDecoration: "underline"
                            }}
                            onClick={() => onEdit()}>
                            {localization.actions.update.title()}
                        </Typography>
                        {confirmDelete &&
                            <ConfirmDialog
                                message={localization.actions.delete.confirm()}
                                onClose={
                                    async result => {
                                        if (result !== false) {
                                            await deleteNote();
                                        }

                                        setConfirmDelete(false);
                                    }}/>}
                        <Typography
                            sx={{
                                "&:hover": {
                                    color: theme.palette.text.primary
                                },
                                color: theme.palette.text.secondary,
                                cursor: "pointer",
                                textDecoration: "underline"
                            }}
                            onClick={() => setConfirmDelete(true)}>
                            {localization.actions.delete.title()}
                        </Typography>
                        {executing && (
                            <CircularProgress
                                size={theme.spacing(2)}
                                variant="indeterminate"/>)}
                        {error && (
                            <Message
                                level="error"
                                title={localization.actions.delete.error()}/>)}
                    </Fragment>}
            </Stack>
        </Stack>);
}