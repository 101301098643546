import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { useGetAwsCloudFrontDistributionRiskContext } from "../contexts";

export function useAwsCloudFrontDistributionDefaultSslCertificateRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsCloudFrontDistributionDefaultSslCertificateRisk;
    const distributionModel = entityModelStore.useGet(risk.entityId) as Contract.AwsCloudFrontDistributionModel;
    const distribution = distributionModel.entity as Contract.AwsCloudFrontDistribution;

    const getAwsCloudFrontDistributionRiskContext = useGetAwsCloudFrontDistributionRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsCloudFrontDistributionDefaultSslCertificateRiskDefinition",
            () => ({
                description: "{{distribution}} is using the default SSL certificate",
                sections: {
                    resolution: {
                        step1: "In the **Settings** section, click **Edit**",
                        step2: "Under **Alternate domain name (CNAME)**, click **Add item** to add a custom domain name. You can add more than one name.",
                        step3: "Add your custom domain name to the **Alternate domain name (CNAME)** list",
                        step4: "Under **Custom SSL certificate**, select a SSL certificate",
                        step5: "Click **Save changes**"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            distribution:
                <Entity
                    entityIdOrModel={distributionModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.CloudFront,
                AwsConsoleUrlBuilder.getCloudFrontDistributionUrl(distribution)),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4(),
            localization.sections.resolution.step5()
        ],
        riskModel,
        () => {
            const distributionRiskContext = getAwsCloudFrontDistributionRiskContext(distributionModel);
            return [
                distributionRiskContext.generalInformation,
                distributionRiskContext.status,
                distributionRiskContext.originResources,
                distributionRiskContext.sensitiveOrigin,
                distributionRiskContext.sensitive,
                distributionRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}