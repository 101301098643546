﻿import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function CheckIcon(props: SvgIconProps) {
    return (
        <SvgIcon
            fill="none"
            height="80"
            viewBox="0 0 74 80"
            width="74"
            {...props}>
            <path
                d="M36.9638 10.6287C36.4972 10.6287 36.1187 10.2502 36.1187 9.78358V0.845135C36.1187 0.378509 36.4971 0 36.9638 0C37.4304 0 37.8089 0.378493 37.8089 0.845135V9.78358C37.8089 10.2502 37.4304 10.6287 36.9638 10.6287Z"
                fill="#64B5B5"/>
            <path
                d="M12.0009 39.3367C12.1098 25.9434 23.0527 15 36.5 15C50.1651 15.1089 61.1081 26.1611 60.9992 39.6633C60.8903 53.0566 49.9474 64 36.5 64H36.3367C29.8036 63.9456 23.6516 61.3867 19.0784 56.7044C14.4508 52.0767 11.9464 45.87 12.0009 39.3367Z"
                fill="#64B5B5"/>
            <path
                d="M36.1187 78.7836C36.1187 79.2502 36.4972 79.6287 36.9638 79.6287C37.4304 79.6287 37.8089 79.2502 37.8089 78.7836V69.8451C37.8089 69.3785 37.4304 69 36.9638 69C36.4971 69 36.1187 69.3785 36.1187 69.8451V78.7836Z"
                fill="#64B5B5"/>
            <path
                d="M63.5054 25.5266C63.2042 25.5266 62.912 25.3648 62.7599 25.0799C62.5395 24.6682 62.6947 24.1562 63.1064 23.9359L71.391 19.5026C71.8027 19.2822 72.3146 19.4374 72.5349 19.8491C72.7553 20.2608 72.6001 20.7727 72.1884 20.993L63.9038 25.4264C63.777 25.494 63.64 25.5266 63.5054 25.5266Z"
                fill="#64B5B5"/>
            <path
                d="M9.02282 25.4264C9.14959 25.4946 9.28601 25.5266 9.42063 25.5266C9.72246 25.5266 10.0146 25.3642 10.1668 25.0799C10.3871 24.6682 10.232 24.1563 9.82025 23.9359L1.53565 19.5026C1.12396 19.2822 0.612054 19.4374 0.391714 19.8491C0.171374 20.2607 0.326514 20.7727 0.738217 20.993L9.02282 25.4264Z"
                fill="#64B5B5"/>
            <path
                d="M52.1501 15.7893C51.9985 15.7893 51.8458 15.7489 51.7082 15.6637C51.3104 15.4193 51.1866 14.8983 51.4311 14.5011L54.3378 9.77751C54.5823 9.3797 55.1032 9.25594 55.5004 9.50042C55.8982 9.7449 56.022 10.2659 55.7775 10.6631L52.8708 15.3867C52.7108 15.6468 52.4332 15.7893 52.1501 15.7893Z"
                fill="#64B5B5"/>
            <path
                d="M67.3398 40.495H73.1536C73.6203 40.495 73.9988 40.1165 73.9988 39.6498C73.9988 39.1832 73.6202 38.8047 73.1536 38.8047H67.3398C66.8731 38.8047 66.4946 39.1832 66.4946 39.6498C66.4946 40.1165 66.8731 40.495 67.3398 40.495Z"
                fill="#64B5B5"/>
            <path
                d="M6.65899 40.495H0.845135C0.378509 40.495 0 40.1165 0 39.6498C0 39.1832 0.378494 38.8047 0.845135 38.8047H6.65899C7.12562 38.8047 7.50412 39.1832 7.50412 39.6498C7.50412 40.1165 7.12563 40.495 6.65899 40.495Z"
                fill="#64B5B5"/>
            <path
                d="M21.0551 15.3867C21.215 15.6456 21.4921 15.7887 21.7758 15.7887C21.9268 15.7887 22.0795 15.7483 22.2177 15.6637C22.6155 15.4193 22.7393 14.8989 22.4948 14.5011L19.5881 9.77751C19.3436 9.37969 18.8233 9.25595 18.4255 9.50042C18.0276 9.7449 17.9039 10.2653 18.1484 10.6631L21.0551 15.3867Z"
                fill="#64B5B5"/>
            <path
                d="M71.7888 59.8993C71.6542 59.8993 71.5177 59.8674 71.391 59.7991L63.1064 55.3658C62.6947 55.1454 62.5395 54.6335 62.7599 54.2218C62.9802 53.8101 63.4921 53.6556 63.9038 53.8753L72.1884 58.3087C72.6001 58.529 72.7552 59.0409 72.5349 59.4526C72.3822 59.7376 72.0906 59.8993 71.7888 59.8993Z"
                fill="#64B5B5"/>
            <path
                d="M0.391225 59.4529C0.543954 59.7372 0.835521 59.8996 1.13735 59.8996C1.27197 59.8996 1.40839 59.867 1.53516 59.7994L9.81976 55.366C10.2315 55.1457 10.3866 54.6338 10.1663 54.2221C9.94594 53.8104 9.43403 53.6553 9.02233 53.8756L0.737729 58.309C0.326029 58.5293 0.170885 59.0412 0.391225 59.4529Z"
                fill="#64B5B5"/>
            <path
                d="M55.0586 71.9274C54.7748 71.9274 54.4978 71.7843 54.3378 71.5253L51.4311 66.8017C51.1866 66.4039 51.3104 65.8836 51.7082 65.6391C52.1054 65.3946 52.6257 65.5184 52.8708 65.9162L55.7775 70.6392C56.022 71.037 55.8982 71.5573 55.5004 71.8018C55.3628 71.8869 55.2095 71.9274 55.0586 71.9274Z"
                fill="#64B5B5"/>
            <path
                d="M18.4255 71.8024C18.5637 71.8875 18.7164 71.928 18.8673 71.928C19.1511 71.928 19.4281 71.7849 19.5881 71.5253L22.4948 66.8017C22.7393 66.4039 22.6155 65.8836 22.2177 65.6391C21.8205 65.3946 21.2995 65.5184 21.0551 65.9162L18.1484 70.6398C17.9039 71.0376 18.0276 71.5579 18.4255 71.8024Z"
                fill="#64B5B5"/>
            <path
                d="M32.5505 46.8383L27.1905 41.2064C26.5723 40.5567 25.5363 40.5567 24.918 41.2063C24.3416 41.812 24.3416 42.7634 24.918 43.3691L31.8261 50.6277C32.2203 51.0419 32.8808 51.0419 33.2749 50.6277L50.082 32.9678C50.6584 32.3621 50.6584 31.4107 50.082 30.805C49.4637 30.1554 48.4277 30.1554 47.8095 30.805L32.5505 46.8383Z"
                fill="white"/>
        </SvgIcon>);
}