import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition, useGetGcpComputeInstancesRiskContext, useGetGcpComputeInstanceTemplateRiskContext } from "../../../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../../../common";
import { EntityExternalConsoleLink } from "../../../../components";

export function useGcpComputeInstanceMemoryEncryptionNotEnabledRiskInstanceTemplateDefinition(riskModel: Contract.GcpComputeInstanceMemoryEncryptionNotEnabledRiskModel) {
    const instanceTemplateModel = entityModelStore.useGet(riskModel.risk.entityId) as Contract.GcpComputeInstanceTemplateModel;

    const getGcpComputeInstanceTemplateRiskContext = useGetGcpComputeInstanceTemplateRiskContext();
    const getGcpComputeInstancesRiskContext = useGetGcpComputeInstancesRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.useGcpComputeInstanceMemoryEncryptionNotEnabledRiskDefinition.hooks.useGcpComputeInstanceMemoryEncryptionNotEnabledRiskInstanceTemplateDefinition",
            () => ({
                description: "{{instanceTemplate}} Confidential Computing is not enabled",
                sections: {
                    resolution: {
                        step1: "Click the **CREATE SIMILAR** button",
                        step2: "Under the **Confidential VM service** section, click **Enable**, and then confirm your selection in the dialog box",
                        step3: "Click **Create**",
                        step4: "For each instance group and cluster using the instance template, update the configuration to use the newly created instance template",
                        step5: "Delete the old, unsecured instance template. Note that you cannot delete an instance template if any managed instance groups still reference it"
                    }
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            instanceTemplate:
                <Entity
                    entityIdOrModel={instanceTemplateModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={riskModel.risk.entityId}
                key={riskModel.risk.entityId}
                page={Contract.GcpConsolePage.Resource}/>,
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4(),
            localization.sections.resolution.step5()
        ],
        riskModel,
        () => {
            const instanceTemplateContextItems = getGcpComputeInstanceTemplateRiskContext(instanceTemplateModel);
            const instancesContextItems = getGcpComputeInstancesRiskContext(riskModel.risk.aggregatedEntityIds);
            return [
                instanceTemplateContextItems.generalInformation,
                instanceTemplateContextItems.sensitive,
                instancesContextItems.instances,
                instancesContextItems.sensitive,
                instanceTemplateContextItems.instanceGroups,
                instanceTemplateContextItems.vpcs,
                instanceTemplateContextItems.getOpenRiskedEntityRisksContextItem(riskModel.risk.id)
            ];
        });
}