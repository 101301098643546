import { Link, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useGetGcpIamServiceAccountRiskContext } from "../../..";
import { Contract, Entity, entityModelStore, InlineEntities } from "../../../../../../../../../../../../common";
import { GcpIamServiceAccountKeysTable } from "../../../../../../../../../../../../tenants";
import { RiskDefinitionSection } from "../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { EntityExternalConsoleLink } from "../../components";

export function useGcpIamServiceAccountUserManagedKeyExistsRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.GcpIamServiceAccountUserManagedKeyExistsRisk;
    const serviceAccountUserManagedKeyExistsRiskModel = riskModel as Contract.GcpIamServiceAccountUserManagedKeyExistsRiskModel;
    const serviceAccountModel = entityModelStore.useGet(risk.entityId) as Contract.GcpIamServiceAccountModel;
    const serviceAccount = serviceAccountModel.entity as Contract.GcpIamServiceAccount;
    const userManagedKeyModels = entityModelStore.useGet(serviceAccount.userManagedKeyIds);

    const getGcpIamServiceAccountRiskContext = useGetGcpIamServiceAccountRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.useGcpIamServiceAccountUserManagedKeyExistsRiskDefinition",
            () => ({
                description: {
                    keys: [
                        "1 user managed key",
                        "{{count | NumberFormatter.humanize}} user managed keys"
                    ],
                    text: "{{serviceAccount}} has {{keys}}"
                },
                sections: {
                    keyIds: "User Managed Keys",
                    resolution: {
                        step1: {
                            link: "alternative authentication methods",
                            text: "Before deleting the user managed keys, migrate any usage to {{documentationLink}}"
                        },
                        step2: "For each user managed key, press the delete button on the right"
                    }
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description.text({
            keys:
                <InlineEntities
                    entityIdsOrModels={
                        _.filter(
                            userManagedKeyModels,
                            userManagedKeyModel => (userManagedKeyModel.entity as Contract.GcpIamServiceAccountKey).active)}
                    entityTypeName={Contract.TypeNames.GcpIamServiceAccountKey}
                    namePluralizer={localization.description.keys}
                    variant="itemCountAndType"/>,
            serviceAccount:
                <Entity
                    entityIdOrModel={serviceAccountModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={risk.entityId}
                key={risk.entityId}
                page={Contract.GcpConsolePage.Keys}/>,
            localization.sections.resolution.step1.text({
                documentationLink:
                    <Link
                        urlOrGetUrl={serviceAccountUserManagedKeyExistsRiskModel.managedKeysDocumentationUrl}
                        variant="external">
                        {localization.sections.resolution.step1.link()}
                    </Link>
            }),
            localization.sections.resolution.step2()
        ],
        riskModel,
        () => {
            const serviceAccountRiskContext = getGcpIamServiceAccountRiskContext(serviceAccountModel);
            return [
                serviceAccountRiskContext.generalInformation,
                serviceAccountRiskContext.permissionActionSeverity,
                serviceAccountRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        },
        {
            sections: [
                new RiskDefinitionSection(
                    <GcpIamServiceAccountKeysTable
                        csvExportFilePrefixes={[localization.sections.keyIds()]}
                        serviceAccountKeyIds={serviceAccount.userManagedKeyIds}/>,
                    localization.sections.keyIds())
            ]
        });
}