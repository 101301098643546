
import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function ProjectFolderIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                d="M8.62775 0.690138C8.24787 0.251813 7.6964 0 7.11637 0H3.11852C1.95333 0 1.01059 1.1 1.01059 2.44444L1.00752 5.82532C1.00254 5.88288 1 5.94114 1 6V16C1 17.1046 1.89543 18 3 18H7.5V8.34211C7.5 7.60088 8.10088 7 8.84211 7H23V4.88889C23 3.54444 22.0467 2.44444 20.8815 2.44444H11.0614C10.4814 2.44444 9.92991 2.19263 9.55003 1.75431L8.62775 0.690138Z"
                fill="#BAC5D3"/>
            <path
                d="M10 9.5H12.9268V12.5H10V9.5Z"
                fill="#5BB5FF"/>
            <path
                d="M14.3902 9.5H17.3171V12.5H14.3902V9.5Z"
                fill="#5BB5FF"/>
            <path
                d="M10 14H12.9268V17H10V14Z"
                fill="#5BB5FF"/>
            <path
                d="M14.3902 14H17.3171V17H14.3902V14Z"
                fill="#5BB5FF"/>
            <path
                d="M10 18.5H12.9268V21.5H10V18.5Z"
                fill="#5BB5FF"/>
            <path
                d="M14.3902 18.5H17.3171V21.5H14.3902V18.5Z"
                fill="#5BB5FF"/>
            <path
                d="M19.0732 18.5H22V21.5H19.0732V18.5Z"
                fill="#5BB5FF"/>
            <path
                d="M19.0732 14H22V17H19.0732V14Z"
                fill="#5BB5FF"/>
            <path
                d="M19.0732 9.5H22V12.5H19.0732V9.5Z"
                fill="#5BB5FF"/>
        </SvgIcon>);
}