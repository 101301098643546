import { TimeFormatter, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useGcpDefaultResourceInfoItemElements, useGcpDefaultScopeResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../..";
import { Contract, InfoItem } from "../../../../../../../../../../common";
import { EntitiesInfoItem, Info } from "../../../../components";
import { GcpScopeResourceRoleBindings } from "../../components";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";

export function useGcpKmsKeyRingKeyDefinition(scopeResourceModel: Contract.GcpScopeResourceModel, options?: DefinitionOptions) {
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(scopeResourceModel);
    const defaultPropertyInfoElements = useGcpDefaultResourceInfoItemElements(scopeResourceModel);
    const defaultScopeResourceInfoItemElements = useGcpDefaultScopeResourceInfoItemElements(scopeResourceModel);
    const key = scopeResourceModel.entity as Contract.GcpKmsKeyRingKey;

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpKmsKeyRingKeyDefinition",
            () => ({
                info: {
                    items: {
                        encryptedResourceIds: "Encrypted Resources",
                        keyRingId: "Key Ring",
                        nextRotationTime: {
                            empty: "Never",
                            title: "Next rotation time"
                        },
                        rotationTimeFrame: {
                            empty: "Never",
                            text: "Every {{rotationTimeFrame | TimeSpanFormatter.humanizeDays}}",
                            title: "Rotation period"
                        },
                        usageType: {
                            title: "Purpose",
                            [Contract.TypeNames.GcpKmsKeyRingKeyUsageType]: {
                                [Contract.GcpKmsKeyRingKeyUsageType.AsymmetricDecrypt]: "Asymmetric Decrypt",
                                [Contract.GcpKmsKeyRingKeyUsageType.AsymmetricSign]: "Asymmetric Sign",
                                [Contract.GcpKmsKeyRingKeyUsageType.EncryptDecrypt]: "Symmetric Encrypt/Decrypt",
                                [Contract.GcpKmsKeyRingKeyUsageType.Mac]: "MAC Signing/Verification",
                                [Contract.GcpKmsKeyRingKeyUsageType.RawEncryptDecrypt]: "Raw Symmetric Encrypt/Decrypt",
                                [Contract.GcpKmsKeyRingKeyUsageType.Unspecified]: "Not Specified"
                            }
                        }
                    }
                },
                tabs: {
                    resourceRoleBindings: "Resource Role Bindings"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <GcpScopeResourceRoleBindings
                    csvExportFilePrefix={localization.tabs.resourceRoleBindings()}
                    scopeResourceModel={scopeResourceModel}/>,
                localization.tabs.resourceRoleBindings(),
                "resourceRoleBindings")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultPropertyInfoElements}
                entityPropertyInfoItemElements={[
                    ...defaultScopeResourceInfoItemElements,
                    <EntitiesInfoItem
                        entityIdsOrModels={key.keyRingId}
                        entityTypeName={Contract.TypeNames.GcpKmsKeyRing}
                        key="keyRingId"
                        title={localization.info.items.keyRingId()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={(scopeResourceModel as Contract.GcpKmsKeyRingKeyModel).encryptedResourceIds}
                        entityTypeName={Contract.TypeNames.GcpResource}
                        key="encryptedResourceIds"
                        location="all"
                        title={localization.info.items.encryptedResourceIds()}/>,
                    <InfoItem
                        key="usageType"
                        title={localization.info.items.usageType.title()}
                        value={localization.info.items.usageType[Contract.TypeNames.GcpKmsKeyRingKeyUsageType][key.usageType]()}/>,
                    <InfoItem
                        key="rotationTimeFrame"
                        location="all"
                        title={localization.info.items.rotationTimeFrame.title()}
                        value={
                            _.isNil(key.rotationTimeFrame)
                                ? localization.info.items.rotationTimeFrame.empty()
                                : localization.info.items.rotationTimeFrame.text({ rotationTimeFrame: key.rotationTimeFrame })}/>,
                    <InfoItem
                        key="nextRotationTime"
                        title={localization.info.items.nextRotationTime.title()}
                        value={
                            _.isNil(key.nextRotationTime)
                                ? localization.info.items.nextRotationTime.empty()
                                : TimeFormatter.profileFormatDateTime(key.nextRotationTime)}/>
                ]}
                options={options?.infoOptions}>
            </Info>
    });
}