import { useLocalization } from "@infrastructure";
import _ from "lodash";
import { AuditEventDefinition, AuditEventDefinitionContext, AuditEventDefinitionDetailItem } from "..";
import { Contract } from "../../../../../../../../../common";

export function useRiskStarredUpdateAuditEventDefinition(context: AuditEventDefinitionContext) {
    const localization =
        useLocalization(
            "views.customer.auditEvents.profile.hooks.useDefinition.hooks.useRiskStarredUpdateAuditEventDefinition",
            () => ({
                risks: {
                    starred: {
                        false: "Unstar",
                        true: "Starred"
                    },
                    text: [
                        "1 finding",
                        "{{count | NumberFormatter.humanize}} findings"
                    ]
                }
            }));

    const riskStarredUpdateAuditEvent = context.auditEventModel.auditEvent as Contract.RiskStarredUpdateAuditEvent;
    return new AuditEventDefinition(
        _.filter([
            new AuditEventDefinitionDetailItem(
                riskStarredUpdateAuditEvent.starred
                    ? localization.risks.starred.true()
                    : localization.risks.starred.false(),
                localization.risks.text(riskStarredUpdateAuditEvent.riskCount))
        ]));
}