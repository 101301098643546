import { DataTableColumn, DataTableColumnRenderProps, EnumValuesFilter, useLocalization } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { useAwsCommonNetworkedResourceDefinition, useAwsCommonResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { useAwsEksClusterNodeGroupStatusTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";

export function useAwsEksClusterNodeGroupDefinition(definitionData: DefinitionData) {
    const commonNetworkedResourceDefinition = useAwsCommonNetworkedResourceDefinition(definitionData);
    const commonResourceDefinition = useAwsCommonResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const eksClusterNodeGroupStatusTranslator = useAwsEksClusterNodeGroupStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.aws.useAwsEksClusterNodeGroupDefinition",
            () => ({
                columns: {
                    autoScalingGroup: "Auto Scaling Groups",
                    clusterId: "Cluster",
                    name: "Name",
                    status: "Status"
                }
            }));

    return new EntityTableDefinition(
        _.filter(
            [
                <DataTableColumn
                    exportOptions={
                        entitiesExportOptions<Contract.AwsEksClusterNodeGroupModel>(
                            Contract.TypeNames.AwsEksCluster,
                            item => [(item.entity as Contract.AwsEksClusterNodeGroup).clusterId],
                            localization.columns.clusterId())}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedEntityFilter
                                    getEntityIdPage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.AwsEksClusterNodeGroupClusters)}
                                    placeholder={localization.columns.clusterId()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsEksClusterNodeGroupClusters}
                    key={Contract.EntityModelProperty.AwsEksClusterNodeGroupClusters}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.AwsEksClusterNodeGroupModel>) =>
                            <EntitiesCell
                                entityIdsOrModels={(item.entity as Contract.AwsEksClusterNodeGroup).clusterId}
                                entityTypeName={Contract.TypeNames.AwsEksCluster}
                                entityVariant="iconText"/>}
                    title={localization.columns.clusterId()}/>,
                commonResourceDefinition.columns.tenantColumn,
                commonResourceDefinition.columns.creationTimeColumn,
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsEksClusterNodeGroupModel) => ({
                                [localization.columns.status()]: eksClusterNodeGroupStatusTranslator((item.entity as Contract.AwsEksClusterNodeGroup).status)
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <EnumValuesFilter
                                    enumType={Contract.AwsEksClusterNodeGroupStatus}
                                    enumTypeTranslator={eksClusterNodeGroupStatusTranslator}
                                    placeholder={localization.columns.status()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsEksClusterNodeGroupStatus}
                    itemProperty={(item: Contract.EntityModel) => eksClusterNodeGroupStatusTranslator((item.entity as Contract.AwsEksClusterNodeGroup).status)}
                    key={Contract.EntityModelProperty.AwsEksClusterNodeGroupStatus}
                    title={localization.columns.status()}/>,
                <DataTableColumn
                    exportOptions={
                        entitiesExportOptions<Contract.AwsEksClusterNodeGroupModel>(
                            Contract.TypeNames.AwsAutoScalingAutoScalingGroup,
                            item => (item.entity as Contract.AwsEksClusterNodeGroup).autoScalingGroupIds,
                            localization.columns.autoScalingGroup())}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedEntityFilter
                                    getEntityIdPage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.AwsEksClusterNodeGroupAutoScalingGroups)}
                                    placeholder={localization.columns.autoScalingGroup()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsEksClusterNodeGroupAutoScalingGroups}
                    key={Contract.EntityModelProperty.AwsEksClusterNodeGroupAutoScalingGroups}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.AwsEksClusterNodeGroupModel>) =>
                            <EntitiesCell
                                entityIdsOrModels={(item.entity as Contract.AwsEksClusterNodeGroup).autoScalingGroupIds}
                                entityTypeName={Contract.TypeNames.AwsAutoScalingAutoScalingGroup}
                                entityVariant="iconText"/>}
                    title={localization.columns.autoScalingGroup()}/>,
                commonNetworkedResourceDefinition.columns.getVpcsColumn(),
                commonNetworkedResourceDefinition.columns.securityGroupsColumn,
                commonResourceDefinition.columns.regionColumn,
                commonResourceDefinition.columns.regionLocationColumn,
                commonResourceDefinition.columns.tagsColumn,
                commonResourceDefinition.columns.attributesColumn,
                commonResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
                commonResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
                commonResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
            ]),
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAwsEksClusterNodeGroupRequest(
                new Contract.EntityControllerGetEntityModelPageAwsEksClusterNodeGroupRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEksClusterNodeGroupAutoScalingGroups]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEksClusterNodeGroupClusters]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEksClusterNodeGroupStatus])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags])),
                new Contract.EntityControllerGetEntityModelPageAwsResourceRequestNetworked(
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsNetworkedResourceSecurityGroups]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsNetworkedResourceVpcs]))));
}