import { Link, useLocalization } from "@infrastructure";
import React from "react";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { AzureKeyVaultVaultAccessPoliciesTable } from "../../../../../../../../../../../../tenants";
import { RiskDefinitionSection } from "../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { EntityExternalConsoleLink } from "../../components";
import { useGetAzureKeyVaultVaultRiskContext } from "../contexts";

export function useAzureKeyVaultVaultRbacDisabledRiskDefinition(riskModel: Contract.RiskModel) {
    const vaultRbacDisabledRiskModel = riskModel as Contract.AzureKeyVaultVaultRbacDisabledRiskModel;
    const vaultModel = entityModelStore.useGet(vaultRbacDisabledRiskModel.risk.entityId) as Contract.AzureKeyVaultVaultModel;
    const vault = vaultModel.entity as Contract.AzureKeyVaultVault;

    const getAzureKeyVaultVaultRiskContext = useGetAzureKeyVaultVaultRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.compliance.useAzureKeyVaultVaultRbacDisabledRiskDefinition",
            () => ({
                description: "{{vault}} role-based access control is not enabled",
                sections: {
                    accessPolicies: "Access Policies",
                    resolution: {
                        step1:
                            {
                                link: "Migrate to Azure role-based access control",
                                text: "Before migrating to RBAC, verify that it will not break existing usage: {{rbacLink}}"
                            },
                        step2: "Under **Permissions model** switch to **Azure role-based access control**",
                        step3: "Click the **Save** button on top to confirm"
                    }
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            vault:
                <Entity
                    entityIdOrModel={vaultModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={vaultRbacDisabledRiskModel.risk.entityId}
                key={vaultRbacDisabledRiskModel.risk.entityId}
                page={Contract.AzureConsoleEntityPage.AccessPolicies}/>,
            localization.sections.resolution.step1.text({
                rbacLink:
                    <Link
                        urlOrGetUrl={vaultRbacDisabledRiskModel.rbacDocumentationUrl}
                        variant="external">
                        {localization.sections.resolution.step1.link()}
                    </Link>
            }),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3()
        ],
        vaultRbacDisabledRiskModel,
        () => {
            const vaultRiskContext = getAzureKeyVaultVaultRiskContext(vaultModel);
            return [
                vaultRiskContext.generalInformation,
                vaultRiskContext.sensitive,
                ...vaultRiskContext.accessPolicyPermissions,
                vaultRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.risk.id)
            ];
        },
        {
            sections: [
                new RiskDefinitionSection(
                    <AzureKeyVaultVaultAccessPoliciesTable accessPolicies={vault.accessPolicies}/>,
                    localization.sections.accessPolicies())
            ]
        });
}