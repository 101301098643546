import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useAwsCommonAccessResourceInfoElements, useAwsCommonEc2ResourceInfoElements, useAwsDefaultResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition } from "../..";
import { useCommonVirtualMachineDiskResourceInfoItemElements, useCustomEntityPropertyInfoItemElements } from "..";
import { Contract, FeatureHelper, InfoCard, InfoItem, useTenantNameTranslator, VerticalTopItemsInfoItem } from "../../../../../../../../../../common";
import { AwsEc2DeviceTable, useAwsResourceProductCodeTypeTranslator } from "../../../../../../../../../../tenants";
import { EntitiesInfoItem, Info } from "../../../../components";

export function useAwsEc2ImageDefinition(resourceModel: Contract.AwsResourceModel, options?: DefinitionOptions) {
    const commonAccessResourceInfoElements = useAwsCommonAccessResourceInfoElements(resourceModel);
    const commonEc2ResourceInfoItemElements = useAwsCommonEc2ResourceInfoElements(resourceModel);
    const commonVirtualMachineDiskResourceInfoItemElements = useCommonVirtualMachineDiskResourceInfoItemElements(resourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useAwsDefaultResourceInfoItemElements(resourceModel);
    const image = resourceModel.entity as Contract.AwsEc2Image;
    const imageModel = resourceModel as Contract.AwsEc2ImageModel;

    const resourceProductCodeTypeTranslator = useAwsResourceProductCodeTypeTranslator();
    const tenantNameTranslator = useTenantNameTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsEc2ImageDefinition",
            () => ({
                info: {
                    items: {
                        architecture: "Architecture",
                        autoScalingGroups: "Auto Scaling Groups",
                        awsManaged: {
                            false: "Customer Managed",
                            title: "Management",
                            true: "AWS Managed"
                        },
                        description: "Description",
                        devices: "Block Device Mappings",
                        instanceIds: "Instances",
                        operatingSystemDescription: "Platform",
                        productCodeId: "Product Code Id",
                        productCodeType: "Product Code Type",
                        rootDeviceType: {
                            title: "Root Device Type",
                            [Contract.TypeNames.AwsEc2DeviceType]: {
                                [Contract.AwsEc2DeviceType.Ebs]: "EBS",
                                [Contract.AwsEc2DeviceType.InstanceStorage]: "Instance store"
                            }
                        },
                        sharedTenantRawIds: "Permissions for other AWS accounts"
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={_.filter([
                    ...commonEc2ResourceInfoItemElements,
                    ...commonAccessResourceInfoElements,
                    <InfoItem
                        key="awsManaged"
                        title={localization.info.items.awsManaged.title()}
                        value={
                            image.awsManaged
                                ? localization.info.items.awsManaged.true()
                                : localization.info.items.awsManaged.false()}/>,
                    <InfoItem
                        key="description"
                        title={localization.info.items.description()}
                        value={image.description}/>,
                    <VerticalTopItemsInfoItem
                        items={
                            _.map(
                                image.sharedTenantRawIds,
                                sharedTenantRawId => tenantNameTranslator(sharedTenantRawId, { includeRawId: true }))}
                        key="sharedTenants"
                        title={localization.info.items.sharedTenantRawIds()}/>,
                    FeatureHelper.enabled(Contract.FeatureName.AwsEc2MachineImageAnalysisEnabled)
                        ? commonVirtualMachineDiskResourceInfoItemElements.operatingSystemDisplayNameInfoElement
                        : undefined,
                    FeatureHelper.enabled(Contract.FeatureName.AwsEc2MachineImageAnalysisEnabled)
                        ? commonVirtualMachineDiskResourceInfoItemElements.operatingSystemTypeInfoElement
                        : undefined,
                    <InfoItem
                        key="operatingSystemDescription"
                        title={localization.info.items.operatingSystemDescription()}
                        value={image.operatingSystemDescription}/>,
                    <InfoItem
                        key="architecture"
                        title={localization.info.items.architecture()}
                        value={image.architecture}/>,
                    FeatureHelper.enabled(Contract.FeatureName.AwsEc2MachineImageAnalysisEnabled)
                        ? commonVirtualMachineDiskResourceInfoItemElements.vulnerabilitiesInfoElement
                        : undefined,
                    FeatureHelper.enabled(Contract.FeatureName.AwsEc2MachineImageAnalysisEnabled)
                        ? commonVirtualMachineDiskResourceInfoItemElements.workloadAnalysisStatusInfoElement
                        : undefined,
                    FeatureHelper.enabled(Contract.FeatureName.AwsEc2MachineImageAnalysisEnabled)
                        ? commonVirtualMachineDiskResourceInfoItemElements.scanTimeInfoElement
                        : undefined,
                    <InfoItem
                        key="rootDeviceType"
                        title={localization.info.items.rootDeviceType.title()}
                        value={localization.info.items.rootDeviceType[Contract.TypeNames.AwsEc2DeviceType][image.rootDeviceType]()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={imageModel.instanceIds}
                        entityTypeName={Contract.TypeNames.AwsEc2Instance}
                        key="instanceIds"
                        title={localization.info.items.instanceIds()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={imageModel.autoScalingGroupIds}
                        entityTypeName={Contract.TypeNames.AwsAutoScalingAutoScalingGroup}
                        key="autoScalingGroups"
                        title={localization.info.items.autoScalingGroups()}/>,
                    <InfoItem
                        key="productCodeType"
                        title={localization.info.items.productCodeType()}
                        value={
                            _.isNil(image.productCode)
                                ? undefined
                                : resourceProductCodeTypeTranslator((image.productCode.type))}/>,
                    <InfoItem
                        key="productCodeId"
                        title={localization.info.items.productCodeId()}
                        value={image?.productCode?.id}/>
                ])}
                options={options?.infoOptions}>
                <InfoCard title={localization.info.items.devices()}>
                    <AwsEc2DeviceTable
                        devices={image.devices}
                        snapshotRawIdToIdMap={imageModel.snapshotRawIdToIdMap}/>
                </InfoCard>
            </Info>
    });
}