import { EmptyMessage, Loading, makeContextProvider, useChangeEffect, useExecuteOperation, useLocalization, useRoute, useSyncContext, VerticalFillGrid } from "@infrastructure";
import { Box, Divider } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { useMemo, useRef, useState } from "react";
import { Contract, entityModelStore, useTheme } from "../..";
import { Graph, Toolbar, ToolbarActions } from "./components";
import { NetworkDefinition, useDefinition } from "./hooks";

type NetworkProps = {
    baseUrl: string;
    entityId: string;
    initialFilterMap?: Dictionary<any>;
    tenantType: Contract.TenantType;
    variant: "entity" | "risk";
};

export class NetworkContext {
    constructor(
        public definition: NetworkDefinition,
        public entityId: string,
        public tenantType: Contract.TenantType) {
    }
}

export const [useNetworkContext, useSetNetworkContext, useNetworkContextProvider] = makeContextProvider<NetworkContext>();

export function Network({ baseUrl, entityId, initialFilterMap = {}, tenantType, variant }: NetworkProps) {
    const definition = useDefinition(tenantType);
    let { view } = useRoute(`${baseUrl}/{view}`);
    view = view ?? NetworkView.Graph;

    const [filterMap, setFilterMap] = useState<Dictionary<any>>(initialFilterMap ?? {});
    const [{ networkGraph, result }, executeGetNetworkGraph] =
        useExecuteOperation(
            [Network, entityId],
            async () => {
                const { networkGraph, result } =
                    await definition.getNetworkGraph(
                        entityId,
                        filterMap);

                if (!_.isNil(networkGraph)) {
                    await entityModelStore.get(networkGraph.relatedEntityIds);
                }

                return {
                    networkGraph,
                    result
                };
            });

    const [, , ContextProvider] =
        useNetworkContextProvider(
            () =>
                new NetworkContext(
                    definition,
                    entityId,
                    tenantType));

    const [loading, setLoading] = useState(false);
    const executeGetNetworkGraphSyncContext = useSyncContext();
    useChangeEffect(
        async () => {
            setLoading(true);
            const syncContext = executeGetNetworkGraphSyncContext.create();
            await executeGetNetworkGraph();
            if (executeGetNetworkGraphSyncContext.isActive(syncContext)) {
                setLoading(false);
            }
        },
        [filterMap]);

    const empty =
        useMemo(
            () => _.isNil(networkGraph),
            []);

    const toolbarActionsRef = useRef<ToolbarActions>();
    const localization =
        useLocalization(
            "common.network",
            () => ({
                empty: "No Matching Results",
                [Contract.TypeNames.EntityControllerGetNetworkGraphResponseResult]: {
                    [Contract.EntityControllerGetNetworkGraphResponseResult.None]: "No Network Data Available",
                    [Contract.EntityControllerGetNetworkGraphResponseResult.NoneDeletedEntity]: "No network data available since this resource is deleted"
                }
            }));
    const theme = useTheme();
    return (
        empty
            ? <EmptyMessage message={localization[Contract.TypeNames.EntityControllerGetNetworkGraphResponseResult][result as Exclude<Contract.EntityControllerGetNetworkGraphResponseResult, Contract.EntityControllerGetNetworkGraphResponseResult.Success>]()}/>
            : <Box
                sx={{
                    backgroundColor: theme.palette.background.paper,
                    height:
                        variant === "risk"
                            ? theme.spacing(60)
                            : "100%"
                }}>
                <ContextProvider>
                    <VerticalFillGrid>
                        <Toolbar
                            actionsRef={toolbarActionsRef}
                            baseUrl={baseUrl}
                            initialFilterMap={initialFilterMap}
                            networkGraph={networkGraph!}
                            view={view as NetworkView}
                            onFiltersChanged={toolbarFilters => setFilterMap(toolbarFilters)}/>
                        <Divider/>
                        <Loading loading={loading}>
                            {result !== Contract.EntityControllerGetNetworkGraphResponseResult.Success
                                ? <EmptyMessage message={localization.empty()}/>
                                : <Box sx={{ height: "100%" }}>
                                    {view === NetworkView.Graph &&
                                        <Graph
                                            entityId={entityId}
                                            networkGraph={networkGraph!}
                                            toolbarActionsRef={toolbarActionsRef}/>}
                                </Box>}
                        </Loading>
                    </VerticalFillGrid>
                </ContextProvider>
            </Box>);
}

export enum NetworkView {
    Graph = "graph",
    List = "list"
}