import { UnexpectedError } from "@infrastructure";
import { useMemo } from "react";
import { Contract } from "../../../../../..";
import { useAwsDeleteBucketPolicyChangeDefinition, useAwsDeleteGroupChangeDefinition, useAwsDeleteInlinePolicyChangeDefinition, useAwsDeletePermissionSetAccountAssignmentsChangeDefinition, useAwsDeletePermissionSetChangeDefinition, useAwsDeleteRepositoryPolicyChangeDefinition, useAwsDeleteRoleChangeDefinition, useAwsDeleteSecretPolicyChangeDefinition, useAwsDeleteSecurityGroupChangeDefinition, useAwsDeleteSecurityGroupReferencingSecurityGroupRulesChangeDefinition, useAwsDeleteUserChangeDefinition, useAwsDeleteUserGroupMembershipChangeDefinition, useAwsDeleteUserPasswordChangeDefinition, useAwsDetachPermissionSetManagedPolicyChangeDefinition, useAwsDetachPrincipalManagedPolicyChangeDefinition, useAwsDisableUserAccessKeyChangeDefinition, useAwsProvisionPermissionSetChangeDefinition, useAwsSetInstanceMetadataServiceV2VersionChangeDefinition, useAwsUpsertBucketPolicyChangeDefinition, useAwsUpsertPermissionSetInlinePolicyChangeDefinition, useAwsUpsertPrincipalManagedPolicyChangeDefinition, useAwsUpsertRepositoryPolicyChangeDefinition, useAwsUpsertSecretPolicyChangeDefinition, useAwsUpsertVpcEndpointServicePolicyChangeDefinition } from "./hooks";
import { useAwsSetInstanceMetadataServiceTokenHopMaxCountChangeDefinition } from "./hooks/useAwsSetInstanceMetadataServiceTokenHopMaxCountChangeDefinition";

export function useAwsDefinition(change: Contract.AwsChange, entityLinkDisabled?: boolean) {
    const useDefinition =
        useMemo(
            () => {
                switch (change.typeName) {
                    case Contract.TypeNames.AwsDeleteBucketPolicyChange:
                        return useAwsDeleteBucketPolicyChangeDefinition;
                    case Contract.TypeNames.AwsDeleteGroupChange:
                        return useAwsDeleteGroupChangeDefinition;
                    case Contract.TypeNames.AwsDeleteInlinePolicyChange:
                        return useAwsDeleteInlinePolicyChangeDefinition;
                    case Contract.TypeNames.AwsDeletePermissionSetAccountAssignmentsChange:
                        return useAwsDeletePermissionSetAccountAssignmentsChangeDefinition;
                    case Contract.TypeNames.AwsDeletePermissionSetChange:
                    case Contract.TypeNames.AwsDeletePermissionSetInlinePolicyChange:
                        return useAwsDeletePermissionSetChangeDefinition;
                    case Contract.TypeNames.AwsDeleteRepositoryPolicyChange:
                        return useAwsDeleteRepositoryPolicyChangeDefinition;
                    case Contract.TypeNames.AwsDeleteRoleChange:
                        return useAwsDeleteRoleChangeDefinition;
                    case Contract.TypeNames.AwsDeleteSecretPolicyChange:
                        return useAwsDeleteSecretPolicyChangeDefinition;
                    case Contract.TypeNames.AwsDeleteSecurityGroupChange:
                        return useAwsDeleteSecurityGroupChangeDefinition;
                    case Contract.TypeNames.AwsDeleteSecurityGroupReferencingSecurityGroupRulesChange:
                        return useAwsDeleteSecurityGroupReferencingSecurityGroupRulesChangeDefinition;
                    case Contract.TypeNames.AwsDeleteUserChange:
                        return useAwsDeleteUserChangeDefinition;
                    case Contract.TypeNames.AwsDeleteUserGroupMembershipChange:
                        return useAwsDeleteUserGroupMembershipChangeDefinition;
                    case Contract.TypeNames.AwsDeleteUserPasswordChange:
                        return useAwsDeleteUserPasswordChangeDefinition;
                    case Contract.TypeNames.AwsDetachPermissionSetManagedPolicyChange:
                        return useAwsDetachPermissionSetManagedPolicyChangeDefinition;
                    case Contract.TypeNames.AwsDetachPrincipalManagedPolicyChange:
                        return useAwsDetachPrincipalManagedPolicyChangeDefinition;
                    case Contract.TypeNames.AwsDisableUserAccessKeyChange:
                        return useAwsDisableUserAccessKeyChangeDefinition;
                    case Contract.TypeNames.AwsProvisionPermissionSetChange:
                        return useAwsProvisionPermissionSetChangeDefinition;
                    case Contract.TypeNames.AwsSetInstanceMetadataServiceTokenHopMaxCountChange:
                        return useAwsSetInstanceMetadataServiceTokenHopMaxCountChangeDefinition;
                    case Contract.TypeNames.AwsSetInstanceMetadataServiceV2VersionChange:
                        return useAwsSetInstanceMetadataServiceV2VersionChangeDefinition;
                    case Contract.TypeNames.AwsUpsertBucketPolicyChange:
                        return useAwsUpsertBucketPolicyChangeDefinition;
                    case Contract.TypeNames.AwsUpsertPermissionSetInlinePolicyChange:
                        return useAwsUpsertPermissionSetInlinePolicyChangeDefinition;
                    case Contract.TypeNames.AwsUpsertPrincipalManagedPolicyChange:
                        return useAwsUpsertPrincipalManagedPolicyChangeDefinition;
                    case Contract.TypeNames.AwsUpsertRepositoryPolicyChange:
                        return useAwsUpsertRepositoryPolicyChangeDefinition;
                    case Contract.TypeNames.AwsUpsertSecretPolicyChange:
                        return useAwsUpsertSecretPolicyChangeDefinition;
                    case Contract.TypeNames.AwsUpsertVpcEndpointServicePolicyChange:
                        return useAwsUpsertVpcEndpointServicePolicyChangeDefinition;
                    default:
                        throw new UnexpectedError("change.typeName", change.typeName);
                }
            },
            []);

    return useDefinition(change, entityLinkDisabled);
}