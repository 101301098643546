import { Dialog } from "@infrastructure";
import React from "react";
import { TicketingServicePrompt, TicketingServicePromptMessage } from "../..";
import { UpsertJiraIssue, UpsertJiraIssueProps } from "./components";

export type UpsertJiraIssueDialogProps =
    UpsertJiraIssueProps & {
        promptMessage?: TicketingServicePromptMessage;
    };

export function UpsertJiraIssueDialog({ instanceModels, onClose, projectReference, promptMessage, riskIdToIssueIdsMap, riskType }: UpsertJiraIssueDialogProps) {
    return (
        <TicketingServicePrompt
            message={promptMessage}
            onCancel={() => onClose()}>
            <Dialog
                variant="editor"
                onClose={onClose}>
                <UpsertJiraIssue
                    instanceModels={instanceModels}
                    projectReference={projectReference}
                    riskIdToIssueIdsMap={riskIdToIssueIdsMap}
                    riskType={riskType}
                    onClose={onClose}/>
            </Dialog>
        </TicketingServicePrompt>);
}