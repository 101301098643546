import { ItemSelector, Loading, Optional } from "@infrastructure";
import { Box, Stack } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { useMemo, useState } from "react";
import { Contract, Entity, entityModelStore, useTheme } from "../../../../../../../../../../../../../../common";
import { AwsApiGatewayV1ApiRoutesTable, AwsApiGatewayV2ApiRoutesTable } from "../../../../../../../../../../../../../../tenants";

export type ApiGatewaySectionSectionProps = {
    publicAccessTriggerApiIdToNotExcludedRouteIdentifiersMap: Optional<Dictionary<string[]>>;
    riskSeverity: Contract.Severity;
};

export function ApiGatewaySection({
    publicAccessTriggerApiIdToNotExcludedRouteIdentifiersMap,
    riskSeverity
}: ApiGatewaySectionSectionProps) {
    const apiIds = _.keys(publicAccessTriggerApiIdToNotExcludedRouteIdentifiersMap);
    const apiModels = entityModelStore.useGet(apiIds);

    const [selectedApiId, setSelectedApiId] = useState(apiIds[0]);
    const apiModel =
        useMemo(
            () =>
                _.find(
                    apiModels,
                    apiModel => apiModel.id === selectedApiId)!,
            [selectedApiId]);

    const theme = useTheme();

    const getRouteHighlightColor =
        (apiRoute: Contract.AwsApiGatewayApiRoute, opacity: number | undefined) =>
            _.includes(
                publicAccessTriggerApiIdToNotExcludedRouteIdentifiersMap![apiModel.id],
                apiRoute.identifier)
                ? opacity
                    ? theme.palette.opacity(theme.palette.severity(riskSeverity), opacity)
                    : theme.palette.severity(riskSeverity)
                : undefined;

    return (
        <Stack
            spacing={4}
            sx={{ height: "100%" }}>
            <ItemSelector
                dense={true}
                fullWidth={true}
                items={apiIds}
                selectedItem={selectedApiId}
                onSelectedItemChanged={apiId => setSelectedApiId(apiId)}>
                {entityId =>
                    <Entity
                        entityIdOrModel={entityId}
                        linkOptions={{ disabled: true }}
                        variant="text"/>}
            </ItemSelector>
            <Box
                sx={{
                    flex: 1,
                    width: "100%"
                }}>
                <Loading key={selectedApiId}>
                    {apiModel.entity.typeName === Contract.TypeNames.AwsApiGatewayV1Api
                        ? <AwsApiGatewayV1ApiRoutesTable
                            apiModel={apiModel as Contract.AwsApiGatewayV1ApiModel}
                            getHighlightColor={getRouteHighlightColor}/>
                        : <AwsApiGatewayV2ApiRoutesTable
                            apiModel={apiModel as Contract.AwsApiGatewayV2ApiModel}
                            getHighlightColor={getRouteHighlightColor}/>}
                </Loading>
            </Box>
        </Stack>);
}