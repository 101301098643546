﻿import { Step, Steps, useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { EntityExternalConsoleLink } from "../../components";
import { useGetGcpTenantEntityRiskContext } from "../contexts";

export function useGcpMonitoringAlertPolicyNotExistRiskDefinition(riskModel: Contract.RiskModel) {
    const alertPolicyNotExistRisk = riskModel.risk as Contract.GcpMonitoringAlertPolicyNotExistRisk;
    const tenantEntityModel = entityModelStore.useGet(alertPolicyNotExistRisk.entityId) as Contract.GcpTenantEntityModel;

    const getGcpTenantEntityRiskContext = useGetGcpTenantEntityRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.useGcpMonitoringAlertPolicyNotExistRiskDefinition",
            () => ({
                description: {
                    [Contract.TypeNames.GcpMonitoringAlertPolicyNotExistRisk]: {
                        [Contract.TypeNames.GcpMonitoringAlertPolicyAuditConfigurationChangesNotExistRisk]: "{{tenantEntity}} is not configured with audit configuration changes alert",
                        [Contract.TypeNames.GcpMonitoringAlertPolicyCloudSqlInstanceConfigurationChangesNotExistRisk]: "{{tenantEntity}} is not configured with cloud SQL instance configuration changes alert",
                        [Contract.TypeNames.GcpMonitoringAlertPolicyComputeFirewallRuleChangesNotExistRisk]: "{{tenantEntity}} is not configured with VPC network firewall rule changes alert",
                        [Contract.TypeNames.GcpMonitoringAlertPolicyComputeRouteChangesNotExistRisk]: "{{tenantEntity}} is not configured with VPC network route changes alert",
                        [Contract.TypeNames.GcpMonitoringAlertPolicyComputeVpcChangesNotExistRisk]: "{{tenantEntity}} is not configured with VPC network changes alert",
                        [Contract.TypeNames.GcpMonitoringAlertPolicyIamPolicyChangesNotExistRisk]: "{{tenantEntity}} is not configured with cloud storage IAM permissions changes alert",
                        [Contract.TypeNames.GcpMonitoringAlertPolicyIamRoleChangesNotExistRisk]: "{{tenantEntity}} is not configured with custom role changes alert",
                        [Contract.TypeNames.GcpMonitoringAlertPolicyProjectOwnershipChangesNotExistRisk]: "{{tenantEntity}} is not configured with ownership assignments/changes alert"
                    }
                },
                sections: {
                    resolution: {
                        step1: {
                            step1: "Click **Create metric**",
                            step2: "Under **Metric Type** select **Counter**",
                            step3: "Under **Details** enter a **Log-based metric name**, and for **Units** enter **1**",
                            step4: "Under **Filter selection**, add the query: {{loggingLogMetricFilterPattern}}",
                            step5: "Click **Create metric**",
                            title: "Create the prescribed log metric:"
                        },
                        step2: {
                            step1: "In the Log-based Metrics page, in the **User-defined metrics** table, identify the newly created metric",
                            step2: "Click on the 3-dot menu and select **Create alert from metric**",
                            step3: "In the **ALERT CONDITIONS** section, fill out the alert policy configuration and click **NEXT**. Choose the alerting threshold and configuration that makes sense for the organization. For example, a threshold of zero(0) for the most recent value will ensure that a notification is triggered for every log",
                            step4: "In the **ALERT DETAILS** section, configure the desired **Notification Channels**",
                            step5: "Click **CREATE POLICY**",
                            title: "Create a prescribed alert policy:"
                        }
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description[Contract.TypeNames.GcpMonitoringAlertPolicyNotExistRisk].translate(
            alertPolicyNotExistRisk.typeName,
            {
                tenantEntity:
                    <Entity
                        entityIdOrModel={tenantEntityModel}
                        entityTypeNameTranslatorOptions={{ variant: "title" }}
                        variant="typeText"/>
            }),
        () => [
            <EntityExternalConsoleLink
                entityId={alertPolicyNotExistRisk.entityId}
                key={alertPolicyNotExistRisk.entityId}
                page={Contract.GcpConsolePage.LogMetrics}/>,
            new Step(
                localization.sections.resolution.step1.title(),
                {
                    contentElement:
                        <Steps variant="plainNumbers">
                            {localization.sections.resolution.step1.step1()}
                            {localization.sections.resolution.step1.step2()}
                            {localization.sections.resolution.step1.step3()}
                            {localization.sections.resolution.step1.step4({ loggingLogMetricFilterPattern: alertPolicyNotExistRisk.loggingLogMetricFilterPattern })}
                            {localization.sections.resolution.step1.step5()}
                        </Steps>
                }),
            new Step(
                localization.sections.resolution.step2.title(),
                {
                    contentElement:
                        <Steps variant="plainNumbers">
                            {localization.sections.resolution.step2.step1()}
                            {localization.sections.resolution.step2.step2()}
                            {localization.sections.resolution.step2.step3()}
                            {localization.sections.resolution.step2.step4()}
                            {localization.sections.resolution.step2.step5()}
                        </Steps>
                })
        ],
        riskModel,
        () => {
            const tenantEntityRiskContext = getGcpTenantEntityRiskContext(tenantEntityModel);
            return [
                tenantEntityRiskContext.generalInformation,
                tenantEntityRiskContext.sensitive,
                tenantEntityRiskContext.getOpenRiskedEntityRisksContextItem(alertPolicyNotExistRisk.id)
            ];
        });
}