import { InlineItems, Link, useLocalization, useLocalizeList } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React, { useCallback } from "react";
import { Contract, CustomerConsoleAppUrlHelper, InlineVulnerability, InlineWorkloadAnalysisPackages, VulnerabilityResolutionVersion } from "../../../../../../../../../../../../../common";

export function useGetPackageVulnerabilityResolutionSteps(windowsUpdateDocumentationUrl?: string) {
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.workloadAnalysis.hooks.useWorkloadAnalysisDefinition.hooks.useGetPackageVulnerabilityResolutionSteps",
            () => ({
                binaryPackage: "{{packagePath}} contains {{vulnerablePackages}} with {{vulnerabilitiesRawIds}} and should be upgraded to the latest version available",
                empty: "There are currently no package versions on which any of the vulnerabilities have been fixed. Tenable recommends reaching out directly to the vendors of relevant packages to inquire about when a fix might be available.\nPlease be aware that you can configure the platform to *bold*only** create findings for vulnerabilities *bold*with fixes**. {{configurationPage}} to access the 'Workload Protection' settings page (requires 'Administrator' permissions)'.",
                emptyLinkText: "Click here",
                many: {
                    kbs: "Run {{windowsUpdateLink}} to get the latest version of the operating system or install the following KBs: {{vulnerabilityPackageResolutionVersions}}",
                    versions: "Upgrade package {{vulnerabilityPackageName}} to versions {{vulnerabilityPackageResolutionVersions}}, or later"
                },
                single: {
                    kb: "Run {{windowsUpdateLink}} to get the latest version of the operating system or install the following KBs: {{vulnerabilityPackageResolutionVersion}}",
                    version: "Upgrade package {{vulnerabilityPackageName}} to version {{vulnerabilityPackageResolutionVersion}} or later"
                },
                windowsUpdateLink: "Windows Update"
            }));
    const localizeList = useLocalizeList();

    return useCallback(
        (filePathToPackageNamesMap: Dictionary<string[]>, filePathToVulnerabilityRawIdsMap : Dictionary<string[]>, vulnerabilityPackageNameToResolutionVersionsMap: Dictionary<string[]>) => {
            const vulnerabilityPackagesResolutionSteps =
                    _.map(
                        vulnerabilityPackageNameToResolutionVersionsMap,
                        (vulnerabilityPackageResolutionVersions, vulnerabilityPackageName) => {
                            const kb = /^KB\d{6,}$/.test(vulnerabilityPackageResolutionVersions[0]);
                            const vulnerabilityResolutionVersions =
                                _.map(
                                    vulnerabilityPackageResolutionVersions,
                                    resolutionPatch => <VulnerabilityResolutionVersion resolutionVersion={resolutionPatch}/>);
                            const windowsUpdateLink =
                                <Link
                                    urlOrGetUrl={windowsUpdateDocumentationUrl}
                                    variant="external">
                                    {localization.windowsUpdateLink()}
                                </Link>;
                            return vulnerabilityResolutionVersions.length === 1
                                ? kb
                                    ? localization.single.kb({
                                        vulnerabilityPackageResolutionVersion: vulnerabilityResolutionVersions[0],
                                        windowsUpdateLink
                                    })
                                    : localization.single.version({
                                        vulnerabilityPackageName,
                                        vulnerabilityPackageResolutionVersion: vulnerabilityResolutionVersions[0]
                                    })
                                : kb
                                    ? localization.many.kbs({
                                        vulnerabilityPackageResolutionVersions: localizeList(vulnerabilityResolutionVersions),
                                        windowsUpdateLink
                                    })
                                    : localization.many.versions({
                                        vulnerabilityPackageName,
                                        vulnerabilityPackageResolutionVersions: localizeList(vulnerabilityResolutionVersions)
                                    });
                        }).
                        concat(
                            _.map(
                                filePathToPackageNamesMap,
                                (packageNames, filePath) =>
                                    localization.binaryPackage({ packagePath: filePath,
                                        vulnerabilitiesRawIds:
                                            <InlineItems
                                                items={
                                                    _.map(
                                                        filePathToVulnerabilityRawIdsMap[filePath],
                                                        vulnerabilityRawId => <InlineVulnerability rawId={vulnerabilityRawId}/>)}
                                                namePluralizer={count =>
                                                    count === 1
                                                        ? "1 vulnerability"
                                                        : `${count} vulnerabilities`}
                                                variant={"itemCountAndType"}/>,
                                        vulnerablePackages:
                                            <InlineWorkloadAnalysisPackages
                                                packageNames={packageNames}
                                                packageScope={Contract.WorkloadResourceOperatingSystemRiskPackageScope.All}/> })));

            return _.isEmpty(vulnerabilityPackagesResolutionSteps)
                ? [
                    localization.empty({
                        configurationPage:
                            <Link
                                urlOrGetUrl={CustomerConsoleAppUrlHelper.getConfigurationWorkloadAnalysisVulnerabilitiesRelativeUrl()}>
                                {localization.emptyLinkText()}
                            </Link>
                    })]
                : vulnerabilityPackagesResolutionSteps;
        },
        [windowsUpdateDocumentationUrl]);
}