﻿import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useAwsEmrClusterStatusTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useAwsEmrClusterStatusTranslator",
            () => ({
                [Contract.TypeNames.AwsEmrClusterStatus]: {
                    [Contract.AwsEmrClusterStatus.Bootstrapping]: "Bootstrapping",
                    [Contract.AwsEmrClusterStatus.Running]: "Running",
                    [Contract.AwsEmrClusterStatus.Starting]: "Starting",
                    [Contract.AwsEmrClusterStatus.Terminated]: "Terminated",
                    [Contract.AwsEmrClusterStatus.TerminatedWithErrors]: "Terminated With Errors",
                    [Contract.AwsEmrClusterStatus.Terminating]: "Terminating",
                    [Contract.AwsEmrClusterStatus.Waiting]: "Waiting"
                }
            }));
    return (status: Contract.AwsEmrClusterStatus) =>
        localization[Contract.TypeNames.AwsEmrClusterStatus][status]();
}