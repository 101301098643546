import { Box, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { ComplianceHelper, Contract, useTheme } from "../../../../../../../../common";
import { useItemContext, useSetItemContext } from "../../Profile";
import { Sections, Summary, TableHeader } from "./components";

type ItemProps = {
    description?: string;
    sectionData: Contract.ComplianceSectionData;
};

export function Item({ description, sectionData }: ItemProps) {
    const { disabled } = useItemContext();
    const setItemContext = useSetItemContext();

    const theme = useTheme();
    return (
        <Stack
            spacing={2}
            sx={{ padding: theme.spacing(2) }}>
            {!_.isEmpty(description) &&
                <Typography
                    sx={{
                        color: theme.palette.text.secondary,
                        maxWidth: theme.spacing(120)
                    }}>
                    {description}
                </Typography>}
            {!disabled && sectionData.hasResults &&
                <Summary complianceData={sectionData}/>}
            <Box>
                {!_.isEmpty(sectionData.sectionDatas) &&
                    <TableHeader
                        onCollapseClick={
                            () =>
                                setItemContext(
                                    context => ({
                                        ...context,
                                        expandedSectionIds:
                                            _.size(context.expandedSectionIds) > 0
                                                ? []
                                                : ComplianceHelper.getSectionIds(sectionData)
                                    }))}/>}
                <Sections sectionData={sectionData}/>
            </Box>
        </Stack>);
}