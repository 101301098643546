import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function AboutIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                clipRule="evenodd"
                d="M12.2814 8.04041C12.1323 7.99668 11.975 7.98831 11.8221 8.01595C11.6692 8.04359 11.5248 8.10648 11.4005 8.19964C11.2761 8.2928 11.1752 8.41366 11.1056 8.55264C11.0361 8.69161 11 8.84487 11 9.00026C11.0001 9.55254 10.5525 10.0004 10.0003 10.0005C9.44797 10.0007 9.00014 9.55306 9 9.00077C8.99988 8.5346 9.1084 8.07482 9.31695 7.65791C9.52549 7.24099 9.82833 6.87841 10.2014 6.59893C10.5745 6.31945 11.0076 6.13077 11.4664 6.04784C11.9251 5.96492 12.3968 5.99005 12.8442 6.12122C13.2915 6.2524 13.7021 6.48601 14.0434 6.80354C14.3847 7.12106 14.6473 7.51376 14.8104 7.95047C14.9735 8.38718 15.0326 8.85588 14.9829 9.3194C14.9333 9.78292 14.7764 10.2285 14.5245 10.6208C14.3437 10.9019 14.1337 11.1683 13.9471 11.4005C13.912 11.4441 13.8778 11.4865 13.8444 11.5278C13.6901 11.7189 13.553 11.8888 13.426 12.0634C13.1098 12.4981 13 12.7778 13 13.0005V13.5005C13 14.0528 12.5523 14.5005 12 14.5005C11.4477 14.5005 11 14.0528 11 13.5005V13.0005C11 12.1192 11.4327 11.4039 11.8085 10.8871C11.9682 10.6676 12.1434 10.4506 12.2985 10.2588C12.3293 10.2205 12.3594 10.1833 12.3884 10.1472C12.5734 9.91721 12.7237 9.72349 12.8419 9.53967C12.9258 9.40891 12.9778 9.26097 12.9943 9.10647C13.0109 8.95196 12.9912 8.79572 12.9368 8.65016C12.8824 8.50459 12.7949 8.37369 12.6811 8.26785C12.5674 8.162 12.4305 8.08413 12.2814 8.04041ZM10.992 17.0005C10.992 16.4482 11.4397 16.0005 11.992 16.0005H12.001C12.5533 16.0005 13.001 16.4482 13.001 17.0005C13.001 17.5528 12.5533 18.0005 12.001 18.0005H11.992C11.4397 18.0005 10.992 17.5528 10.992 17.0005Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M6.92072 3.66496C5.73809 3.82393 5.07682 4.1194 4.59811 4.59811C4.11944 5.07677 3.82395 5.73824 3.66496 6.92109C3.50212 8.13261 3.5 9.73274 3.5 12C3.5 14.2678 3.50212 15.8679 3.66496 17.0793C3.82393 18.2619 4.1194 18.9232 4.59811 19.4019C5.07677 19.8806 5.73824 20.1761 6.92109 20.335C8.13261 20.4979 9.73274 20.5 12 20.5C14.2678 20.5 15.8679 20.4979 17.0793 20.335C18.2619 20.1761 18.9232 19.8806 19.4019 19.4019C19.8806 18.9232 20.1761 18.2618 20.335 17.0789C20.4979 15.8674 20.5 14.2673 20.5 12C20.5 9.73223 20.4979 8.13212 20.335 6.92072C20.1761 5.73809 19.8806 5.07682 19.4019 4.59811C18.9232 4.11944 18.2618 3.82395 17.0789 3.66496C15.8674 3.50212 14.2673 3.5 12 3.5C9.73223 3.5 8.13212 3.50212 6.92072 3.66496ZM11.9255 1.5H12.0744C14.2503 1.49998 15.9851 1.49996 17.3453 1.68279C18.7497 1.87155 19.9038 2.27156 20.8161 3.18389C21.7284 4.09619 22.1284 5.24991 22.3172 6.65428C22.5 8.01441 22.5 9.74925 22.5 11.9256V12.0744C22.5 14.2503 22.5 15.9851 22.3172 17.3453C22.1284 18.7497 21.7284 19.9038 20.8161 20.8161C19.9038 21.7284 18.7501 22.1284 17.3457 22.3172C15.9856 22.5 14.2508 22.5 12.0745 22.5H11.9256C9.74974 22.5 8.01491 22.5 6.65466 22.3172C5.25026 22.1284 4.09623 21.7284 3.18389 20.8161C2.2716 19.9038 1.87157 18.7501 1.68279 17.3457C1.49996 15.9856 1.49998 14.2508 1.5 12.0745V11.9256C1.49998 9.74975 1.49996 8.01492 1.68279 6.65466C1.87155 5.25026 2.27156 4.09623 3.18389 3.18389C4.09619 2.2716 5.24991 1.87157 6.65428 1.68279C8.01441 1.49996 9.74924 1.49998 11.9255 1.5Z"
                fillRule="evenodd"/>
        </SvgIcon>);
}