import { useLocalization } from "@infrastructure";
import React from "react";
import { AuditEventDefinition, AuditEventDefinitionContext, AuditEventDefinitionDetailItem } from "..";
import { Contract, Entity } from "../../../../../../../../../common";

export function useAzureWorkloadAnalysisUpdateAuditEventDefinition(context: AuditEventDefinitionContext) {
    const localization =
        useLocalization(
            "views.customer.auditEvents.profile.hooks.useDefinition.hooks.useAzureWorkloadAnalysisUpdateAuditEventDefinition",
            () => ({
                defaultResourceGroup: "Default",
                title: "Resource Group"
            }));

    const auditEvent = context.auditEventModel.auditEvent as Contract.AzureWorkloadAnalysisUpdateAuditEvent;
    return new AuditEventDefinition([
        new AuditEventDefinitionDetailItem(
            localization.title(),
            localization.defaultResourceGroup(),
            auditEvent.diskSnapshotResourceGroupId &&
                <Entity
                    entityIdOrModel={auditEvent.diskSnapshotResourceGroupId}
                    variant="iconText"/>
        )]);
}