import { Dialog, DirectedGraph, DirectedGraphModel, DirectedGraphModelEdge, DirectedGraphModelNode, EmptyMessage, Link, Optional, useLocalization } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React, { Fragment, MutableRefObject, useEffect, useMemo, useState } from "react";
import { AccessView, Contract, entityModelStore, EntityNodeContent, getEntityNodeContentAnchorPoint, getEntityNodeContentSize, useAccessContext, useTheme } from "../../../..";
import { AccessDefinitionToolbarActions, ToolbarFilterId } from "../../hooks";
import { AccessGraphHelper, AccessGraphPermissionEdgeData, ExcessivePermissionHelper } from "../../utilities";
import { EntityGroupNodeContent, getEntityGroupNodeContentAnchorPoint, getEntityGroupNodeContentSize, GraphEntitiesPermissionActions, Legend } from "./components";

type GraphProps = {
    accessGraph: Contract.AccessGraph;
    accessGraphId: string;
    baseUrl?: string;
    excessivePermissionsEnabled: boolean;
    permissionEdgeIdToDataMap: Dictionary<AccessGraphPermissionEdgeData>;
    resourceServiceModelMap: Dictionary<Contract.EntityModel>;
    tenantType: Contract.TenantType;
    toolbarActionsRef: MutableRefObject<Optional<AccessDefinitionToolbarActions>>;
};

export function Graph({ accessGraph, accessGraphId, baseUrl, excessivePermissionsEnabled, permissionEdgeIdToDataMap, resourceServiceModelMap, tenantType, toolbarActionsRef }: GraphProps) {
    const localization =
        useLocalization(
            "common.access.graph",
            () => ({
                actions: {
                    navigateListView: {
                        links: {
                            listView: "go to list view"
                        },
                        title: {
                            withBaseUrl: "Graph is too large. Please use filters or {{listViewLink}}",
                            withoutBaseUrl: "Graph is too large. Please use filters"
                        }
                    }
                }
            }));
    return accessGraph.edges.length > 400
        ? <EmptyMessage
            message={
                _.isNil(baseUrl)
                    ? localization.actions.navigateListView.title.withoutBaseUrl()
                    : localization.actions.navigateListView.title.withBaseUrl({
                        listViewLink:
                            <Link
                                urlOrGetUrl={`${baseUrl}/${AccessView.List}`}>
                                {localization.actions.navigateListView.links.listView()}
                            </Link>
                    })}
            verticalCenter={true}/>
        : <Core
            accessGraph={accessGraph}
            accessGraphId={accessGraphId}
            excessivePermissionsEnabled={excessivePermissionsEnabled}
            permissionEdgeIdToDataMap={permissionEdgeIdToDataMap}
            resourceServiceModelMap={resourceServiceModelMap}
            tenantType={tenantType}
            toolbarActionsRef={toolbarActionsRef}/>;
}

type CoreProps = {
    accessGraph: Contract.AccessGraph;
    accessGraphId: string;
    excessivePermissionsEnabled: boolean;
    permissionEdgeIdToDataMap: Dictionary<AccessGraphPermissionEdgeData>;
    resourceServiceModelMap: Dictionary<Contract.EntityModel>;
    tenantType: Contract.TenantType;
    toolbarActionsRef: MutableRefObject<Optional<AccessDefinitionToolbarActions>>;
};

function Core({ accessGraph, accessGraphId, excessivePermissionsEnabled, permissionEdgeIdToDataMap, resourceServiceModelMap, tenantType, toolbarActionsRef }: CoreProps) {
    const { definition, entityId, variant } = useAccessContext();
    entityModelStore.useGet(accessGraph.relatedEntityIds);

    const [destinationEntityGroupMap, groupEntityIdToNodeMap, identityMap, originatorEntityGranteeEntityIdToNodeMap, permissionPathMap] =
        useMemo(
            () => {
                const destinationEntityGroupMap =
                    _.keyBy(
                        accessGraph.destinationEntityGroups,
                        destinationEntityGroup => destinationEntityGroup.id);
                const groupEntityIdToNodeMap =
                    _.keyBy(
                        accessGraph.groups,
                        group => group.groupId);
                const identityMap =
                    _.keyBy(
                        accessGraph.identityGroups,
                        identity => identity.id);
                const originatorEntityGranteeEntityIdToNodeMap =
                    _.keyBy(
                        accessGraph.originatorEntityGranteeEntities,
                        originatorEntityGranteeEntity => originatorEntityGranteeEntity.granteeEntityId);
                const permissionPathMap =
                    _.keyBy(
                        accessGraph.permissionPaths,
                        permissionPath => permissionPath.id);
                return [destinationEntityGroupMap, groupEntityIdToNodeMap, identityMap, originatorEntityGranteeEntityIdToNodeMap, permissionPathMap];
            },
            [accessGraph]);

    const localization =
        useLocalization(
            "common.access.graph.core",
            () => ({
                edges: {
                    originatorResourceToIdentity: "Assume"
                }
            }));

    const entityNodeId =
        useMemo(
            () => {
                const originatorEntityGroup =
                    _.find(
                        accessGraph.originatorEntityGroups,
                        originatorEntityGroup => _.includes(originatorEntityGroup.originatorEntityIds, entityId));
                if (!_.isNil(originatorEntityGroup)) {
                    return originatorEntityGroup.id;
                }

                const originatorEntityGranteeEntity = originatorEntityGranteeEntityIdToNodeMap[entityId];
                if (!_.isNil(originatorEntityGranteeEntity)) {
                    return originatorEntityGranteeEntity.id;
                }

                const identityGroup =
                    _.find(
                        accessGraph.identityGroups,
                        identityGroup => _.includes(identityGroup.identityIds, entityId));
                if (!_.isNil(identityGroup)) {
                    return identityGroup.id;
                }

                const group = groupEntityIdToNodeMap[entityId];
                if (!_.isNil(group)) {
                    return group.id;
                }

                const permissionPath =
                    definition.graphPermissionPath.getEntityPermissionPath(
                        entityId,
                        accessGraph.permissionPaths);
                if (!_.isNil(permissionPath)) {
                    return permissionPath.id;
                }

                const destinationEntityGroup =
                    _.find(
                        accessGraph.destinationEntityGroups,
                        destinationEntityGroup => _.includes(destinationEntityGroup.entityIds, entityId));
                return _.isNil(destinationEntityGroup)
                    ? accessGraph.destinationEntityGroups[0].id
                    : destinationEntityGroup.id;
            },
            [accessGraph, entityId]);

    const [entitiesPermissionActionsDestinationEntityGroup, setEntitiesPermissionActionsDestinationEntityGroup] = useState<Contract.AccessGraphDestinationEntityGroup>();
    const [selectedNodeId, setSelectedNodeId] = useState(entityNodeId);
    const [directedGraphModel, permissionPathIdToGroupIdentityIdMapMap] =
        useMemo(
            () => {
                const directedGraphModelEdges =
                    _.map(
                        accessGraph.edges,
                        edge =>
                            new DirectedGraphModelEdge(
                                edge.sourceNodeId,
                                edge.destinationNodeId,
                                {
                                    title:
                                        _.isNil(identityMap[edge.destinationNodeId]) || tenantType !== Contract.TenantType.Aws
                                            ? undefined
                                            : localization.edges.originatorResourceToIdentity()
                                }));

                const originatorEntityGroupNodes =
                    _.map(
                        accessGraph.originatorEntityGroups,
                        originatorEntityGroup =>
                            new DirectedGraphModelNode(
                                originatorEntityGroup.id,
                                GraphColumnId.OriginatorEntityGroups,
                                <EntityNodeContent
                                    entityIds={originatorEntityGroup.originatorEntityIds}
                                    entityProfileMenuItem={
                                        originatorEntityGroup.originatorEntityIds[0] !== entityId ||
                                        variant !== "entity"}
                                    entityTypeName={originatorEntityGroup.entityTypeEntitiesViewName}
                                    variant="large"
                                    onFilter={
                                        type =>
                                            toolbarActionsRef.current!.filter(
                                                ToolbarFilterId.OriginatorEntityIds,
                                                type,
                                                originatorEntityGroup.originatorEntityIds)}/>,
                                getEntityNodeContentSize("bottom", "large"),
                                edge =>
                                    getEntityNodeContentAnchorPoint(
                                        edge.getAnchorPlacement(originatorEntityGroup.id),
                                        "bottom",
                                        "large"),
                                {
                                    onClick: () => setSelectedNodeId(originatorEntityGroup.id)
                                }));

                const originatorEntityGranteeEntityNodes =
                    _.map(
                        accessGraph.originatorEntityGranteeEntities,
                        originatorEntityGranteeEntity =>
                            new DirectedGraphModelNode(
                                originatorEntityGranteeEntity.id,
                                GraphColumnId.OriginatorEntityGranteeEntities,
                                <EntityNodeContent
                                    entityIds={[originatorEntityGranteeEntity.granteeEntityId]}
                                    entityProfileMenuItem={
                                        originatorEntityGranteeEntity.granteeEntityId !== entityId ||
                                        variant !== "entity"}
                                    variant="large"/>,
                                getEntityNodeContentSize("bottom", "large"),
                                edge =>
                                    getEntityNodeContentAnchorPoint(
                                        edge.getAnchorPlacement(originatorEntityGranteeEntity.id),
                                        "bottom",
                                        "large"),
                                {
                                    onClick: () => setSelectedNodeId(originatorEntityGranteeEntity.id)
                                }));

                const identityGroupNodes =
                    _.map(
                        accessGraph.identityGroups,
                        identityGroup =>
                            new DirectedGraphModelNode(
                                identityGroup.id,
                                GraphColumnId.IdentityGroups,
                                <EntityNodeContent
                                    entityIds={identityGroup.identityIds}
                                    entityProfileMenuItem={
                                        identityGroup.identityIds[0] !== entityId ||
                                        variant !== "entity"}
                                    entityTypeName={identityGroup.entityTypeEntitiesViewName}
                                    variant="large"
                                    onFilter={
                                        type =>
                                            toolbarActionsRef.current!.filter(
                                                ToolbarFilterId.IdentityIds,
                                                type,
                                                identityGroup.identityIds)}/>,
                                getEntityNodeContentSize("bottom", "large"),
                                edge =>
                                    getEntityNodeContentAnchorPoint(
                                        edge.getAnchorPlacement(identityGroup.id),
                                        "bottom",
                                        "large"),
                                {
                                    onClick: () => setSelectedNodeId(identityGroup.id)
                                }));

                const groupNodes =
                    _.map(
                        accessGraph.groups,
                        group =>
                            new DirectedGraphModelNode(
                                group.id,
                                GraphColumnId.Groups,
                                <EntityNodeContent
                                    entityIds={[group.groupId]}
                                    entityProfileMenuItem={
                                        group.groupId !== entityId ||
                                        variant !== "entity"}
                                    getTooltip={definition.getDirectedGraphGroupNodeTooltip}
                                    variant="large"
                                    onFilter={
                                        type =>
                                            toolbarActionsRef.current!.filter(
                                                ToolbarFilterId.GroupIds,
                                                type,
                                                [group.groupId])}/>,
                                getEntityNodeContentSize("bottom", "large"),
                                edge =>
                                    getEntityNodeContentAnchorPoint(
                                        edge.getAnchorPlacement(group.id),
                                        "bottom",
                                        "large"),
                                {
                                    onClick: () => setSelectedNodeId(group.id)
                                }));

                const permissionPathNodes =
                    _.map(
                        accessGraph.permissionPaths,
                        permissionPath =>
                            definition.graphPermissionPath.getDirectedGraphModelNode(
                                permissionPath,
                                toolbarActionsRef,
                                () => setSelectedNodeId(permissionPath.id)));

                const destinationEntityGroupNodes =
                    _.map(
                        accessGraph.destinationEntityGroups,
                        destinationEntityGroup =>
                            new DirectedGraphModelNode(
                                destinationEntityGroup.id,
                                GraphColumnId.DestinationEntityGroups,
                                <EntityGroupNodeContent
                                    destinationEntityGroup={destinationEntityGroup}
                                    toolbarActionsRef={toolbarActionsRef}
                                    onViewPermissions={() => setEntitiesPermissionActionsDestinationEntityGroup(destinationEntityGroup)}/>,
                                getEntityGroupNodeContentSize(),
                                () => getEntityGroupNodeContentAnchorPoint(),
                                {
                                    onClick: () => setSelectedNodeId(destinationEntityGroup.id)
                                }));

                const directedGraphModel =
                    new DirectedGraphModel(
                        directedGraphModelEdges,
                        [
                            ...originatorEntityGroupNodes,
                            ...originatorEntityGranteeEntityNodes,
                            ...identityGroupNodes,
                            ...groupNodes,
                            ...permissionPathNodes,
                            ...destinationEntityGroupNodes
                        ]);

                const permissionPathIdToGroupIdentityIdMapMap =
                    _(accessGraph.groups).
                        flatMap(group => directedGraphModel.nodeIdToDirectionEdgesMap[group.id].sourceEdges).
                        groupBy(groupToPermissionPathDirectedGraphModelEdge => groupToPermissionPathDirectedGraphModelEdge.destinationNodeId).
                        mapValues(
                            groupToPermissionPathDirectedGraphModelEdges =>
                                _(groupToPermissionPathDirectedGraphModelEdges).
                                    flatMap(groupToPermissionPathDirectedGraphModelEdge => directedGraphModel.nodeIdToDirectionEdgesMap[groupToPermissionPathDirectedGraphModelEdge.sourceNodeId].destinationEdges).
                                    map(identityGroupToGroupDirectedGraphModelEdge => identityGroupToGroupDirectedGraphModelEdge.sourceNodeId).
                                    keyBy().
                                    value()).
                        value();

                return [directedGraphModel, permissionPathIdToGroupIdentityIdMapMap];
            },
            [accessGraph]);

    const [selectedIdentityIds, setSelectedIdentityIds] = useState<string[]>([]);
    const [selectedPermissionPaths, setSelectedPermissionPaths] = useState<Contract.AccessGraphPermissionPath[]>([]);
    const theme = useTheme();
    useEffect(
        () => {
            directedGraphModel.highlight(selectedNodeId);

            const selectedIdentityGroups =
                _<Contract.AccessGraphIdentityGroup | Contract.AccessGraphGroup>(accessGraph.identityGroups).
                    concat(
                        _.filter(
                            accessGraph.groups,
                            group => group.sinister)).
                    filter(identityGroup => directedGraphModel.nodeMap[identityGroup.id].options.appearance === "highlighted").
                    value();
            _(accessGraph.destinationEntityGroups).
                flatMap(destinationEntityGroup => directedGraphModel.nodeIdToDirectionEdgesMap[destinationEntityGroup.id].destinationEdges).
                each(
                    permissionPathToEntityGroupDirectedGraphModelEdge => {
                        if (permissionPathToEntityGroupDirectedGraphModelEdge.options.appearance === "highlighted") {
                            const destinationEntityGroup = destinationEntityGroupMap[permissionPathToEntityGroupDirectedGraphModelEdge.destinationNodeId];
                            const permissionPath = permissionPathMap[permissionPathToEntityGroupDirectedGraphModelEdge.sourceNodeId];
                            const permissionPathGroupIdentityIdMap = permissionPathIdToGroupIdentityIdMapMap[permissionPath.id];
                            const permissionPathSelectedIdentityIds =
                                _(selectedIdentityGroups).
                                    filter(
                                        selectedIdentityGroup =>
                                            !_.isNil(directedGraphModel.edgeMap[DirectedGraphModelEdge.createId(selectedIdentityGroup.id, permissionPath.id)]) ||
                                            !_.isNil(permissionPathGroupIdentityIdMap?.[selectedIdentityGroup.id])).
                                    flatMap(AccessGraphHelper.getGroupIdentityIds).
                                    value();

                            permissionPathToEntityGroupDirectedGraphModelEdge.options.color =
                                excessivePermissionsEnabled
                                    ? theme.palette.excessivePermission(
                                        ExcessivePermissionHelper.getDestinationEntityGroupActionsExcessiveness(
                                            destinationEntityGroup,
                                            permissionPathSelectedIdentityIds,
                                            permissionEdgeIdToDataMap,
                                            permissionPath))
                                    : theme.palette.excessivePermission(Contract.AccessGraphPermissionPathActionsExcessiveness.None);
                        } else {
                            permissionPathToEntityGroupDirectedGraphModelEdge.options.color =
                                theme.palette.excessivePermission(Contract.AccessGraphPermissionPathActionsExcessiveness.None);
                        }
                    });

            setSelectedIdentityIds(
                _.flatMap(
                    selectedIdentityGroups,
                    AccessGraphHelper.getGroupIdentityIds));
            setSelectedPermissionPaths(
                _.filter(
                    accessGraph.permissionPaths,
                    permissionPath => directedGraphModel.nodeMap[permissionPath.id].options.appearance === "highlighted"));
        },
        [directedGraphModel, selectedNodeId]);

    return (
        <Fragment>
            {!_.isNil(entitiesPermissionActionsDestinationEntityGroup) && (
                <Dialog
                    size="large"
                    variant="viewer"
                    onClose={() => setEntitiesPermissionActionsDestinationEntityGroup(undefined)}>
                    <GraphEntitiesPermissionActions
                        accessGraphId={accessGraphId}
                        accessVariant={accessGraph.variant}
                        entitiesPermissionActionsDestinationEntityGroup={entitiesPermissionActionsDestinationEntityGroup}
                        excessivePermissionsEnabled={excessivePermissionsEnabled}
                        permissionEdgeIdToDataMap={permissionEdgeIdToDataMap!}
                        resourceServiceModelMap={resourceServiceModelMap}
                        selectedIdentityIds={selectedIdentityIds}
                        selectedPermissionPaths={selectedPermissionPaths}/>
                </Dialog>)}
            <DirectedGraph
                dense={true}
                legendElement={
                    excessivePermissionsEnabled
                        ? <Legend/>
                        : undefined}
                model={directedGraphModel}/>
        </Fragment>);
}

export enum GraphColumnId {
    DestinationEntityGroups = "destinationEntityGroups",
    Groups = "groups",
    IdentityGroups = "identityGroups",
    OriginatorEntityGranteeEntities = "originatorEntityGranteeEntities",
    OriginatorEntityGroups = "originatorEntityGroups",
    PermissionPaths = "permissionPaths"
}