﻿import { DataTableColumnRenderProps, NoneIcon } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, EntityAttributes } from "../../../../../../../../../../../common";

export function EntityAttributesCell({ item }: DataTableColumnRenderProps<Contract.EntityModel>) {
    return _.isEmpty(item.attributes.attributes)
        ? <NoneIcon sx={{ fontSize: "18px" }}/>
        : <EntityAttributes
            entityAttributes={item.attributes.attributes}
            entityTypeName={item.entity.typeName}
            orderVariant="leftToRight"
            variant="dynamic"/>;
}