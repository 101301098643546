import { FormLayout, useLocalization } from "@infrastructure";
import { Box, Button } from "@mui/material";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import { RiskPolicyTable } from "./RiskPolicyTable";

type AddRiskPoliciesProps = {
    onSave: (policies: string[]) => void;
    riskPolicyIds?: string[];
};

export function AddRiskPolicies({ onSave, riskPolicyIds: initialRiskPolicyIds }: AddRiskPoliciesProps) {
    const [riskPolicyIds, setRiskPolicyIds] = useState<string[]>(initialRiskPolicyIds ?? []);
    const isValid =
        useMemo(
            () => !_.isEqual(initialRiskPolicyIds, riskPolicyIds),
            [initialRiskPolicyIds, riskPolicyIds]);

    const localization =
        useLocalization(
            "views.customer.compliance.addOrEdit.section.addRiskPolicies",
            () => ({
                action: {
                    add: "Add",
                    edit: "Save"
                },
                title: {
                    add: "Add Policies",
                    edit: "Edit Policies"
                }
            }));
    return (
        <FormLayout
            disableContentPadding={true}
            footerOptions={{
                border: true,
                contentElement:
                    <Box sx={{ direction: "rtl" }}>
                        <Button
                            disabled={!isValid}
                            onClick={() => onSave(riskPolicyIds)}>
                            {_.isEmpty(initialRiskPolicyIds)
                                ? localization.action.add()
                                : localization.action.edit()}
                        </Button>
                    </Box>
            }}
            titleOptions={{
                text:
                    _.isEmpty(initialRiskPolicyIds)
                        ? localization.title.add()
                        : localization.title.edit()
            }}>
            <RiskPolicyTable
                selectedRiskPoliciesIds={riskPolicyIds}
                variant="edit"
                onSelectChange={setRiskPolicyIds}/>
        </FormLayout>
    );
}