import _ from "lodash";
import { useLocalization } from "@infrastructure";
import { Contract } from "..";

// TODO: make all PermissionsItem.tsx use this instead of re-declaring titles
export function useCloudProviderTenantPermissionTypesTranslator() {
    const localization =
        useLocalization(
            "common.hooks.useCloudProviderTenantPermissionTypesTranslator",
            () => ({
                longName: {
                    [Contract.CloudProviderTenantPermissionType.ContainerImageRepositoryAnalysis]: "Container Registry Scanning",
                    [Contract.CloudProviderTenantPermissionType.DataAnalysis]: "Data Protection",
                    [Contract.CloudProviderTenantPermissionType.PermissionManagement]: "Just-In-Time Access (User Access Administrator)",
                    [Contract.CloudProviderTenantPermissionType.Read]: "Monitoring (read-only)",
                    [Contract.CloudProviderTenantPermissionType.WorkloadAnalysis]: "Virtual Machine Scanning",
                    [Contract.CloudProviderTenantPermissionType.Write]: "Remediation (read-write)"
                },
                shortName: {
                    [Contract.CloudProviderTenantPermissionType.ContainerImageRepositoryAnalysis]: "Container Registry Scanning",
                    [Contract.CloudProviderTenantPermissionType.DataAnalysis]: "Data Protection",
                    [Contract.CloudProviderTenantPermissionType.PermissionManagement]: "JIT",
                    [Contract.CloudProviderTenantPermissionType.Read]: "Read",
                    [Contract.CloudProviderTenantPermissionType.WorkloadAnalysis]: "Virtual Machine Scanning",
                    [Contract.CloudProviderTenantPermissionType.Write]: "Manage"
                }
            }));

    return {
        multiple:
            (permissionTypes: Contract.CloudProviderTenantPermissionType[], variant: "longName" | "shortName") =>
                _(
                    [
                        Contract.CloudProviderTenantPermissionType.Read,
                        Contract.CloudProviderTenantPermissionType.Write,
                        Contract.CloudProviderTenantPermissionType.DataAnalysis,
                        Contract.CloudProviderTenantPermissionType.PermissionManagement,
                        Contract.CloudProviderTenantPermissionType.ContainerImageRepositoryAnalysis,
                        Contract.CloudProviderTenantPermissionType.WorkloadAnalysis
                    ]).
                    intersection(permissionTypes).
                    map(permissionType => localization[variant][permissionType]()).
                    value(),
        single:
            (permissionType: Contract.CloudProviderTenantPermissionType, variant: "longName" | "shortName") =>
                localization[variant][permissionType]()
    };
}