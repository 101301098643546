import { Link, useLocalization } from "@infrastructure";
import React, { Fragment } from "react";
import { Contract, Entity, entityModelStore, tenantModelStore, TypeHelper, useEntityTypeNameTranslator } from "../../../../../../../../../../../common";
import { AzureConsoleUrlBuilder } from "../../../../../../../../../../../tenants";

type EntityRoleSchedulesExternalConsoleLinkProps = {
    entityId: string;
    roleAssignmentResourceTypeName: Contract.TypeNames.AzureAuthorizationRoleAssignmentSchedule | Contract.TypeNames.AzureAuthorizationRoleEligibilitySchedule;
};

export function EntityRoleSchedulesExternalConsoleLink({ entityId, roleAssignmentResourceTypeName }: EntityRoleSchedulesExternalConsoleLinkProps) {
    const entityModel = entityModelStore.useGet(entityId);
    const tenantConfiguration = tenantModelStore.useGet(entityModel.tenantId).configuration as Contract.AzureTenantConfiguration;
    const aadTenantConfiguration = tenantModelStore.useGet(tenantConfiguration.aadTenantId).configuration as Contract.AadTenantConfiguration;
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.entityRoleSchedulesExternalConsoleLink",
            () => ({
                link: "PIM Assignments page",
                roleScheduleTabName: {
                    [Contract.TypeNames.AzureAuthorizationRoleAssignmentSchedule]: "Active assignments",
                    [Contract.TypeNames.AzureAuthorizationRoleEligibilitySchedule]: "Eligible assignments"
                },
                title: "In Azure Portal, open the {{scopeEntityRoleSchedulesLink}} of {{scopeEntityTranslatedTypeName}} **{{scopeEntityId}}** and navigate to the **{{roleScheduleTabName}}** tab"
            }));
    return (
        <Fragment>
            {entityModel.entity.typeName !== Contract.TypeNames.AzureRoot &&
                !TypeHelper.extendOrImplement(entityModel.entity.typeName, Contract.TypeNames.AzureStorageStorageAccountResource) &&
                localization.title({
                    roleScheduleTabName: localization.roleScheduleTabName[roleAssignmentResourceTypeName](),
                    scopeEntityId:
                        <Entity
                            entityIdOrModel={entityModel.entity.id}
                            linkOptions={{ disabled: true }}
                            variant="text"/>,
                    scopeEntityRoleSchedulesLink:
                        <Link
                            urlOrGetUrl={AzureConsoleUrlBuilder.GetScopeResourceRoleSchedulesUrl(
                                aadTenantConfiguration.partitionType,
                                encodeURIComponent((entityModel.entity as Contract.AzureScopeResource).rawPath!))}
                            variant="external">
                            {localization.link()}
                        </Link>,
                    scopeEntityTranslatedTypeName:
                        entityTypeNameTranslator(
                            entityModel.entity.typeName,
                            {
                                variant: "text"
                            })
                })}
        </Fragment>);
}