import React, { useRef } from "react";
import { PrincipalsDefinition } from "../..";
import { AccessController, Contract, ItemSelectionHelper, StorageHelper, TimeRangeHelper } from "../../../../../../../../../../common";
import { Table, TableActions } from "../../components";

export function usePermissionDefinition() {
    const tableActionsRef = useRef<TableActions>();
    return new PrincipalsDefinition(
        <Table
            actionsRef={tableActionsRef}
            filtersVisibilityStorageItem={StorageHelper.customerPermissionsTableFilters}
            getPrincipalModelPage={
                async (filterMap, limit, skip, sort) => {
                    const { principalModelPage } =
                        await AccessController.getPrincipalModelPage(
                            new Contract.AccessControllerGetPrincipalPermissionsModelPageRequest(
                                new Contract.AccessControllerGetPrincipalPermissionModelRequestFilters(
                                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.AccessControllerGetPrincipalModelPageRequestProperty.ActionRiskCategories]),
                                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(tableActionsRef.current?.getFiltersTime(), filterMap[Contract.AccessControllerGetPrincipalModelPageRequestProperty.ActivityTime]),
                                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.AccessControllerGetPrincipalModelPageRequestProperty.EntityAttributes]),
                                    undefined,
                                    undefined,
                                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.AccessControllerGetPrincipalModelPageRequestProperty.Id]),
                                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.AccessControllerGetPrincipalModelPageRequestProperty.ServiceIdentityOriginatorEntities]),
                                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.AccessControllerGetPrincipalModelPageRequestProperty.ServiceIdentityOriginatorEntityTenantId]),
                                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.AccessControllerGetPrincipalModelPageRequestProperty.ServiceIdentityOriginatorEntityTypeNames]),
                                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.AccessControllerGetPrincipalModelPageRequestProperty.Severity]),
                                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.AccessControllerGetPrincipalModelPageRequestProperty.TenantId]),
                                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.AccessControllerGetPrincipalModelPageRequestProperty.TypeNames]),
                                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.AccessControllerGetPrincipalModelPageRequestProperty.Vendors])),
                                limit,
                                skip,
                                sort));
                    return { principalModelPage };
                }}/>);
}