import { InlineItems, Step, Steps, useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../..";
import { Contract, Entity, entityModelStore, tenantModelStore } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetAwsTenantEntityRiskContext } from "../contexts";

export function useAwsConfigRegionConfigurationRecorderNotExistRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsConfigRegionConfigurationRecorderNotExistRisk;
    const tenantConfiguration = tenantModelStore.useGet(riskModel.tenantId).configuration as Contract.AwsTenantConfiguration;
    const tenantEntityModel = entityModelStore.useGet(risk.entityId) as Contract.AwsTenantEntityModel;

    const getAwsTenantEntityRiskContext = useGetAwsTenantEntityRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsConfigRegionConfigurationRecorderNotExistRiskDefinition",
            () => ({
                contextItems: {
                    regionSystemNames: "The AWS config is not enabled on **{{regionSystemNames}}**"
                },
                description: "{{tenantEntity}} config service is not enabled for all regions",
                regionSystemNames: [
                    "1 active region",
                    "{{count | NumberFormatter.humanize}} active regions"
                ],
                sections: {
                    resolution: {
                        step1: {
                            step1: "Select the region you want to focus on in the top right of the console",
                            step2: "If a Config recorder exists in this region, navigate to the **Settings** page and click **Edit**. If a Config recorder does not exist in this region then you should click **Get Started**",
                            step3: "For **Recording strategy**, select **All resource types with customizable overrides**",
                            step4: "Under **Delivery method**, specify an S3 bucket and SNS topic in the same account or in another managed AWS account",
                            title: "Repeat for each of the **{{regionSystemNames}}**"
                        },
                        step2: "Recording global resources is required in one region. To enable it, make sure that the Config recorder is including the global resources in one of your active regions."
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            tenantEntity:
                <Entity
                    entityIdOrModel={tenantEntityModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () =>
            [
                consoleSignInStepTranslator(
                    Contract.AwsConsoleView.Config,
                    AwsConsoleUrlBuilder.getConfigUrl(tenantConfiguration.partitionType)),
                new Step(
                    localization.sections.resolution.step1.title({
                        regionSystemNames:
                            <InlineItems
                                items={risk.regionSystemNames}
                                namePluralizer={localization.regionSystemNames}
                                variant="itemCountAndType"/>
                    }),
                    {
                        contentElement:
                            <Steps variant="plainNumbers">
                                {localization.sections.resolution.step1.step1()}
                                {localization.sections.resolution.step1.step2()}
                                {localization.sections.resolution.step1.step3()}
                                {localization.sections.resolution.step1.step4()}
                            </Steps>
                    }),
                risk.globalResourceEnabled
                    ? undefined
                    : localization.sections.resolution.step2()
            ],
        riskModel,
        () => {
            const tenantEntityRiskContext = getAwsTenantEntityRiskContext(tenantEntityModel);
            return [
                tenantEntityRiskContext.generalInformation,
                tenantEntityRiskContext.sensitive,
                new RiskDefinitionContextItem(
                    localization.contextItems.regionSystemNames({
                        regionSystemNames:
                            <InlineItems
                                items={risk.regionSystemNames}
                                namePluralizer={localization.regionSystemNames}
                                variant="itemCountAndType"/>
                    })),
                tenantEntityRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}