import { DataTableColumnRenderProps } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import React from "react";
import { Contract } from "../../../../../../../common";

export function NameCell({ item }: DataTableColumnRenderProps<Contract.AutomationRuleConfiguration>) {
    return (
        <Stack>
            <Typography
                noWrap={true}
                variant="h5">
                {item.name}
            </Typography>
            <Typography noWrap={true}>
                {item.description}
            </Typography>
        </Stack>);
}