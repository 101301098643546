import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function BrightcoveIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <g clipPath="url(#clip0_58343_2153)">
                <path
                    clipRule="evenodd"
                    d="M18.66 4.54773C17.7006 3.94889 16.4781 3.70905 15.0375 3.70905H3.69362V20.0185L0 16.3249V0.0154309H11.3439C13.2147 0.0154309 14.5346 0.326456 15.6132 1.40653L18.7079 4.49976L18.66 4.54773ZM19.6673 5.45914L22.906 8.69783C23.576 9.3694 23.9366 10.3288 23.9366 11.576V11.9597C23.9366 13.7593 23.3857 14.5733 21.634 14.8858V15.1009L23.1691 16.6359C23.7926 17.2595 23.9845 18.027 23.9845 19.0344V19.8266C23.9845 22.7048 22.0658 24 18.7807 24H7.67505L3.98143 20.3063H15.0855C18.3721 20.3063 20.2909 19.0112 20.2909 16.133V15.3408C20.2909 13.111 19.2836 12.1268 16.3807 12.0309V11.576C19.2836 11.48 20.2429 10.4959 20.2429 8.26611V7.88236C20.2429 6.97094 20.0511 6.17868 19.6193 5.50711L19.6673 5.45914ZM9.25803 7.09009H12.9037C14.2948 7.09009 14.7265 7.45063 14.7265 8.60189V8.72104C14.7265 9.8723 14.2948 10.2328 12.9037 10.2328H9.25803V7.09009ZM9.25803 16.9237V13.6139H12.9037C14.2948 13.6139 14.7265 13.9729 14.7265 15.1241V15.4119C14.7265 16.5632 14.2948 16.9237 12.9037 16.9237H9.25803Z"
                    fill="#0B2040"
                    fillRule="evenodd"/>
                <path
                    d="M15.9133 0.207307V0.00305176H16.9331V0.207307H16.5493V1.20228H16.3095V0.207307H15.9133ZM17.4716 0.00305176L17.7718 0.854116L18.0843 0.00305176H18.4201V1.20228H18.2035V0.242897L17.8677 1.20228H17.6758L17.34 0.242897V1.20228H17.125V0.00305176H17.4716Z"
                    fill="#0B2040"/>
            </g>
            <defs>
                <clipPath id="clip0_58343_2153">
                    <rect
                        fill="white"
                        height="24"
                        width="24"/>
                </clipPath>
            </defs>
        </SvgIcon>);
}