import { ActionMenuItem, DeleteIcon, EditIcon, Menu, Message, Optional, useLocalization } from "@infrastructure";
import { CircularProgress, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { Fragment, useState } from "react";
import { automaticCustomEntityAttributeDefinitionTemplateModelStore, ConfigurationController, Contract, customEntityAttributeDefinitionModelStore, scopeSystemEntityModelStore, useScopeNavigationViewContext, useTheme } from "../../../../../../../../../common";
import { CustomEntityAttributeIcon } from "../../../../../icons/ViewIcon/icons";
import { UsageDialog } from "../../../components";
import { CustomEntityAttributeItem, CustomEntityAttributeItemType, useSetCustomEntityAttributesContext } from "../../CustomEntityAttributeDefinitions";

export type ActionsCellProps = {
    item: CustomEntityAttributeItem;
};

export function ActionsCell({ item }: ActionsCellProps): any {
    const [customEntityAttributeDefinitionUsage, setCustomEntityAttributeDefinitionUsage] = useState<Optional<Contract.ConfigurationControllerGetAutomaticCustomEntityAttributeDefinitionTemplateUsageResponse>>(undefined);

    const { scopeNodeModel } = useScopeNavigationViewContext();
    const setCustomEntityAttributeContext = useSetCustomEntityAttributesContext();
    const [deleteEntityAttributeExecuting, setDeleteEntityAttributeExecuting] = useState(false);
    const [deleteEntityAttributeError, setDeleteEntityAttributeError] = useState(false);

    const localization =
        useLocalization(
            "views.customer.configuration.entities.customEntityAttributeDefinitions.actionsCell",
            () => ({
                actions: {
                    delete: {
                        error: "Failed to delete",
                        prompt: {
                            message: "Doing so will remove the label from all findings and resources where it was applied.",
                            title: "Are you sure you want to delete the label {{name}}?"
                        },
                        title: "Delete"
                    },
                    edit: "Edit",
                    view: "View"
                }
            }));

    async function deleteEntityAttribute() {
        setDeleteEntityAttributeExecuting(true);
        setDeleteEntityAttributeError(false);
        try {
            const customEntityAttributeDefinitionUsage =
                item.type == CustomEntityAttributeItemType.Manual
                    ? await ConfigurationController.getCustomEntityAttributeDefinitionUsage(new Contract.ConfigurationControllerGetCustomEntityAttributeDefinitionUsageRequest(item.configuration.id))
                    : await ConfigurationController.getAutomaticCustomEntityAttributeDefinitionTemplateUsage(new Contract.ConfigurationControllerGetAutomaticCustomEntityAttributeDefinitionTemplateUsageRequest(item.configuration.id));

            if (!_.isEmpty(customEntityAttributeDefinitionUsage.automationRuleConfigurationIds) ||
                !_.isEmpty(customEntityAttributeDefinitionUsage.customRiskPolicyConfigurationIds) ||
                !_.isEmpty(customEntityAttributeDefinitionUsage.reportScheduleConfigurationIds)) {
                setDeleteEntityAttributeExecuting(false);
                setCustomEntityAttributeDefinitionUsage(customEntityAttributeDefinitionUsage);
                return;
            }

            if (item.type == CustomEntityAttributeItemType.Manual) {
                await ConfigurationController.deleteManualCustomEntityAttributeDefinition(new Contract.ConfigurationControllerDeleteManualCustomEntityAttributeDefinitionRequest(item.configuration.id));
                await customEntityAttributeDefinitionModelStore.notifyDeleted(item.configuration.id);
            } else {
                await ConfigurationController.deleteAutomaticCustomEntityAttributeDefinitionTemplate(new Contract.ConfigurationControllerDeleteAutomaticCustomEntityAttributeDefinitionTemplateRequest(item.configuration.id));
                await automaticCustomEntityAttributeDefinitionTemplateModelStore.notifyDeleted(item.configuration.id);
            }

            await scopeSystemEntityModelStore.notify();
        } catch {
            setDeleteEntityAttributeError(true);
        }
        setDeleteEntityAttributeExecuting(false);
    }

    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            direction="row"
            justifyContent="flex-end"
            spacing={1.5}>
            {!_.isNil(customEntityAttributeDefinitionUsage) &&
                <UsageDialog
                    automationRuleConfigurationIds={customEntityAttributeDefinitionUsage.automationRuleConfigurationIds}
                    customRiskPolicyConfigurationIds={customEntityAttributeDefinitionUsage.customRiskPolicyConfigurationIds}
                    reportScheduleConfigurationIds={customEntityAttributeDefinitionUsage.reportScheduleConfigurationIds}
                    variant="entityAttribute"
                    onClose={() => setCustomEntityAttributeDefinitionUsage(undefined)}/>}
            {deleteEntityAttributeExecuting && (
                <CircularProgress
                    size={theme.spacing(2)}
                    variant="indeterminate"/>)}
            {deleteEntityAttributeError && (
                <Message
                    level="error"
                    title={localization.actions.delete.error()}
                    variant="minimal"/>)}
            <Fragment>
                <Menu
                    itemsOrGetItems={
                        item.configuration.scopeId != scopeNodeModel.configuration.id
                            ? [
                                new ActionMenuItem(
                                    () =>
                                        setCustomEntityAttributeContext(
                                            customEntityAttributeContext => ({
                                                ...customEntityAttributeContext,
                                                addOrEditOpen: true,
                                                addOrEditReadonly: true,
                                                item
                                            })),
                                    localization.actions.view(),
                                    {
                                        disabled: deleteEntityAttributeExecuting,
                                        icon: <CustomEntityAttributeIcon/>
                                    })]
                            : [
                                new ActionMenuItem(
                                    () =>
                                        setCustomEntityAttributeContext(
                                            customEntityAttributeContext => ({
                                                ...customEntityAttributeContext,
                                                addOrEditOpen: true,
                                                addOrEditReadonly: false,
                                                item
                                            })),
                                    localization.actions.edit(),
                                    {
                                        disabled: deleteEntityAttributeExecuting,
                                        icon: <EditIcon/>
                                    }),
                                new ActionMenuItem(
                                    () => deleteEntityAttribute(),
                                    localization.actions.delete.title(),
                                    {
                                        confirmOptions: {
                                            descriptionElement:
                                                <Typography
                                                    align="center"
                                                    color="textSecondary"
                                                    variant="h3">
                                                    {localization.actions.delete.prompt.message()}
                                                </Typography>,
                                            message: localization.actions.delete.prompt.title({ name: item.configuration.name ?? item.displayName })
                                        },
                                        disabled: deleteEntityAttributeExecuting,
                                        icon: <DeleteIcon/>
                                    })
                            ]}/>
            </Fragment>
        </Stack>);
}