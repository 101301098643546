import { Stack } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useMemo } from "react";
import { Loading, useExecuteOperation, useLocalization, useRoute, useSetRoute, VerticalTabView, VerticalTabViewItem } from "@infrastructure";
import { Events, MaliciousFiles, Packages, Risks, Vulnerabilities } from "../../..";
import { Access, CodeController, CodeResource, Contract, CustomerConsoleAppUrlHelper, EntityTypeMetadataModelHelper, FeatureHelper, LicensingHelper, Network, ProfileLayout, StorageHelper, tenantModelStore, TypeHelper, useEntityTypeNameTranslator, useGetActivePermissionsTenantTypes, UserHelper } from "../../../../../../common";
import { EntityModel } from "../../../../../../common/controllers/types.generated";
import { AwsFederation } from "../../../Access/components";
import { Category, ContainerImages, DataAnalysisResourceDataSegments, DataAnalysisResourceOverview, Snapshots, Topbar } from "./components";
import { EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "./hooks";
import { CategoryIcon } from "./icons";

type ProfileProps = {
    definition: EntityProfileDefinition;
    entityModel: EntityModel;
};

export enum ProfileView {
    AwsAccessGraph = "awsAccessGraph",
    AwsFederation = "awsFederation",
    AzureAccessGraph = "azureAccessGraph",
    CodeResource = "codeResource",
    ContainerImages = "containerImages",
    DataAnalysisResourceDataSegments = "dataAnalysisResourceDataSegments",
    DataAnalysisResourceOverview = "dataAnalysisResourceOverview",
    Events = "events",
    GcpAccessGraph = "gcpAccessGraph",
    Info = "info",
    MaliciousFiles = "maliciousFiles",
    NetworkGraph = "networkGraph",
    Packages = "packages",
    Risks = "open",
    Snapshots = "snapshots",
    Vulnerabilities = "vulnerabilities"
}

export function Profile({ definition, entityModel }: ProfileProps) {
    const entityTypeMetadataModel = EntityTypeMetadataModelHelper.get(entityModel.entity.typeName);
    const activeCloudProviderTenantTypes = tenantModelStore.useGetActiveCloudProviderTenantTypes();
    const activePermissionsTenantTypes = useGetActivePermissionsTenantTypes(entityModel.entity.typeName);
    const [{ datas: codeResourceDatas }] =
        useExecuteOperation(
            [Profile, entityModel.id],
            () => CodeController.getResources(new Contract.CodeControllerGetResourcesRequest([entityModel.id])));

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile",
            () => ({
                tabs: {
                    categoryView: {
                        [ProfileCategory.Code]: "IaC",
                        [ProfileCategory.DataAnalysis]: "Data",
                        [ProfileCategory.Events]: "Activity Log",
                        [ProfileCategory.Iam]: "IAM",
                        [ProfileCategory.Network]: "Network",
                        [ProfileCategory.Overview]: "Overview",
                        [ProfileCategory.Risks]: "Findings ({{openRelatedEntityRiskCount | NumberFormatter.humanize}})",
                        [ProfileCategory.WorkloadAnalysis]: "Workload"
                    },
                    profileView: {
                        withServiceName: {
                            [ProfileView.AwsAccessGraph]: "AWS Graph",
                            [ProfileView.AzureAccessGraph]: "Azure Graph",
                            [ProfileView.GcpAccessGraph]: "GCP Graph"
                        },
                        [ProfileView.AwsAccessGraph]: "Graph",
                        [ProfileView.AwsFederation]: "AWS Federated Permissions",
                        [ProfileView.AzureAccessGraph]: "Graph",
                        [ProfileView.CodeResource]: "IaC",
                        [ProfileView.ContainerImages]: "Container Images",
                        [ProfileView.Events]: "Activity Log",
                        [ProfileView.GcpAccessGraph]: "Graph",
                        [ProfileView.Info]: "General",
                        [ProfileView.MaliciousFiles]: "Malicious Files",
                        [ProfileView.NetworkGraph]: "Graph",
                        [ProfileView.DataAnalysisResourceDataSegments]: "Classified Data",
                        [ProfileView.DataAnalysisResourceOverview]: "Overview",
                        [ProfileView.Packages]: "Software",
                        [ProfileView.Risks]: "Findings ({{openRelatedEntityRiskCount | NumberFormatter.humanize}})",
                        [ProfileView.Snapshots]: "Policy Snapshots",
                        [ProfileView.Vulnerabilities]: "Vulnerabilities"
                    }
                }
            }));

    const { category, view } = useRoute(`${CustomerConsoleAppUrlHelper.getEntityProfileRelativeUrl(entityModel)}/{category}/{view}`);
    const setRoute = useSetRoute();
    useEffect(
        () => {
            if (_.isNil(category)) {
                setRoute(
                    CustomerConsoleAppUrlHelper.getEntityProfileRelativeUrl(entityModel, { category: ProfileCategory.Overview })!,
                    undefined,
                    { appendBrowserHistory: false });
            }
        },
        []);

    function getBaseUrl(viewFallback: ProfileView) {
        return CustomerConsoleAppUrlHelper.getEntityProfileRelativeUrl(entityModel, { category: category as ProfileCategory, view: (view as ProfileView ?? viewFallback) })!;
    }

    const getProfileViewTitle =
        (profileView: ProfileView) =>
            (activePermissionsTenantTypes.length > 1 ||
                TypeHelper.extendOrImplement(entityModel.typeName, Contract.TypeNames.IKubernetesResourceModel)) &&
                profileView in localization.tabs.profileView.withServiceName
                ? localization.tabs.profileView.withServiceName.translate(profileView)
                : localization.tabs.profileView[profileView]();

    const [categoryViewToTabMap, entityProfileTabs] =
        useMemo(
            () => {
                const entityProfileTabs =
                    _<EntityProfileDefinitionTab>([]).
                        concatIf(
                            !_.isNil(definition.options.infoElement),
                            new EntityProfileDefinitionTab(
                                ProfileCategory.Overview,
                                definition.options.infoElement,
                                localization.tabs.profileView[ProfileView.Info](),
                                ProfileView.Info)).
                        concatIf(
                            UserHelper.hasAnyScopePermissions(entityModel.entity.scopeIds, Contract.IdentityPermission.SecurityRead),
                            _<EntityProfileDefinitionTab>([]).
                                concat(
                                    new EntityProfileDefinitionTab(
                                        ProfileCategory.Risks,
                                        <Risks relatedEntityId={entityModel.id}/>,
                                        localization.tabs.profileView[ProfileView.Risks]({ openRelatedEntityRiskCount: entityModel.risks.risks.openRelatedEntityRiskCount }),
                                        ProfileView.Risks)).
                                concatIf(
                                    _.includes(activePermissionsTenantTypes, Contract.TenantType.Aws),
                                    new EntityProfileDefinitionTab(
                                        ProfileCategory.Iam,
                                        <Access
                                            baseUrl={getBaseUrl(ProfileView.AwsAccessGraph)}
                                            entityId={entityModel.id}
                                            key={Contract.TenantType.Aws}
                                            scope={Contract.EntityAccessScope.Full}
                                            tenantType={Contract.TenantType.Aws}
                                            variant="entity"/>,
                                        getProfileViewTitle(ProfileView.AwsAccessGraph),
                                        ProfileView.AwsAccessGraph)).
                                concatIf(
                                    entityTypeMetadataModel.awsFederation &&
                                    _.includes(activeCloudProviderTenantTypes, Contract.TenantType.Aws),
                                    new EntityProfileDefinitionTab(
                                        ProfileCategory.Iam,
                                        <AwsFederation
                                            sourceEntityModel={entityModel}
                                            variant="card"/>,
                                        localization.tabs.profileView[ProfileView.AwsFederation](),
                                        ProfileView.AwsFederation)).
                                concatIf(
                                    _.includes(activePermissionsTenantTypes, Contract.TenantType.Azure),
                                    new EntityProfileDefinitionTab(
                                        ProfileCategory.Iam,
                                        <Access
                                            baseUrl={getBaseUrl(ProfileView.AzureAccessGraph)}
                                            entityId={entityModel.id}
                                            key={Contract.TenantType.Azure}
                                            scope={Contract.EntityAccessScope.Full}
                                            tenantType={Contract.TenantType.Azure}
                                            variant="entity"/>,
                                        getProfileViewTitle(ProfileView.AzureAccessGraph),
                                        ProfileView.AzureAccessGraph)).
                                concatIf(
                                    _.includes(activePermissionsTenantTypes, Contract.TenantType.Gcp),
                                    new EntityProfileDefinitionTab(
                                        ProfileCategory.Iam,
                                        <Access
                                            baseUrl={getBaseUrl(ProfileView.GcpAccessGraph)}
                                            entityId={entityModel.id}
                                            key={Contract.TenantType.Gcp}
                                            scope={Contract.EntityAccessScope.Full}
                                            tenantType={Contract.TenantType.Gcp}
                                            variant="entity"/>,
                                        getProfileViewTitle(ProfileView.GcpAccessGraph),
                                        ProfileView.GcpAccessGraph)).
                                concatIf(
                                    TypeHelper.extendOrImplement(entityModel.entity.typeName, Contract.TypeNames.IContainerImageRepository),
                                    new EntityProfileDefinitionTab(
                                        ProfileCategory.Overview,
                                        <ContainerImages entityModel={entityModel}/>,
                                        localization.tabs.profileView[ProfileView.ContainerImages](),
                                        ProfileView.ContainerImages)).
                                concatIf(
                                    entityTypeMetadataModel.networkGraph &&
                                    (entityModel.entityNetwork as Contract.NetworkAccessResourceStateNetwork)?.inboundExternal,
                                    new EntityProfileDefinitionTab(
                                        ProfileCategory.Network,
                                        <Network
                                            baseUrl={getBaseUrl(ProfileView.NetworkGraph)}
                                            entityId={entityModel.id}
                                            tenantType={entityTypeMetadataModel.tenantType}
                                            variant="entity"/>,
                                        localization.tabs.profileView[ProfileView.NetworkGraph](),
                                        ProfileView.NetworkGraph)).
                                concatIf(
                                    LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.Cnapp) &&
                                    (!TypeHelper.extendOrImplement(
                                        entityModel.entity.typeName,
                                        Contract.TypeNames.IContainerImage) ||
                                        _.as<Contract.IContainerImage>(entityModel.entity).data.analyzed),
                                    _<EntityProfileDefinitionTab>([]).
                                        concatIf(
                                            entityTypeMetadataModel.vulnerabilities &&
                                            entityModel.typeName !== Contract.TypeNames.AwsEc2ImageModel ||
                                            FeatureHelper.enabled(Contract.FeatureName.AwsEc2MachineImageAnalysisEnabled),
                                            new EntityProfileDefinitionTab(
                                                ProfileCategory.WorkloadAnalysis,
                                                <Vulnerabilities
                                                    entityIds={[entityModel.id]}
                                                    entityModel={entityModel}
                                                    variant="entity"/>,
                                                localization.tabs.profileView[ProfileView.Vulnerabilities](),
                                                ProfileView.Vulnerabilities)).
                                        concatIf(
                                            entityTypeMetadataModel.packages &&
                                            entityModel.typeName !== Contract.TypeNames.AwsEc2ImageModel ||
                                            FeatureHelper.enabled(Contract.FeatureName.AwsEc2MachineImageAnalysisEnabled),
                                            new EntityProfileDefinitionTab(
                                                ProfileCategory.WorkloadAnalysis,
                                                <Packages entityId={entityModel.id}/>,
                                                localization.tabs.profileView[ProfileView.Packages](),
                                                ProfileView.Packages)).
                                        concatIf(
                                            entityTypeMetadataModel.maliciousFiles &&
                                            entityModel.typeName !== Contract.TypeNames.AwsEc2ImageModel ||
                                            FeatureHelper.enabled(Contract.FeatureName.AwsEc2MachineImageAnalysisEnabled),
                                            new EntityProfileDefinitionTab(
                                                ProfileCategory.WorkloadAnalysis,
                                                <MaliciousFiles
                                                    entityIds={[entityModel.id]}
                                                    tenantId={entityModel.tenantId}
                                                    variant="entity"/>,
                                                localization.tabs.profileView[ProfileView.MaliciousFiles](),
                                                ProfileView.MaliciousFiles)).
                                        value()).
                                concatIf(
                                    !_.isEmpty(codeResourceDatas),
                                    new EntityProfileDefinitionTab(
                                        ProfileCategory.Code,
                                        <CodeResource resourceData={_.first(codeResourceDatas)!}/>,
                                        localization.tabs.profileView[ProfileView.CodeResource](),
                                        ProfileView.CodeResource)).
                                concatIf(
                                    TypeHelper.extendOrImplement(entityModel.typeName, Contract.TypeNames.IDataAnalysisResourceModel) &&
                                    (entityModel.typeName !== Contract.TypeNames.AzureSqlServerModel ||
                                        FeatureHelper.enabled(Contract.FeatureName.AzureSqlServerAnalysisEnabled)),
                                    () => {
                                        const dataAnalysisResourceModel = _.as<Contract.IDataAnalysisResourceModel>(entityModel);
                                        return _<EntityProfileDefinitionTab>([]).
                                            concat(
                                                new EntityProfileDefinitionTab(
                                                    ProfileCategory.DataAnalysis,
                                                    <DataAnalysisResourceOverview dataAnalysisResourceModel={dataAnalysisResourceModel}/>,
                                                    localization.tabs.profileView[ProfileView.DataAnalysisResourceOverview](),
                                                    ProfileView.DataAnalysisResourceOverview)).
                                            concatIf(
                                                ((entityModel.typeName === Contract.TypeNames.AzureSqlServerModel &&
                                                        FeatureHelper.enabled(Contract.FeatureName.AzureSqlServerAnalysisEnabled)) ||
                                                    entityModel.typeName === Contract.TypeNames.AwsRdsClusterModel ||
                                                    entityModel.typeName === Contract.TypeNames.AwsRdsDatabaseInstanceModel ||
                                                    entityModel.typeName === Contract.TypeNames.GcpBigQueryDatasetModel ||
                                                    entityModel.typeName === Contract.TypeNames.GcpSqlInstanceModel ||
                                                    TypeHelper.extendOrImplement(entityModel.entity.typeName, Contract.TypeNames.IObjectStore)) &&
                                                !_.isEmpty(dataAnalysisResourceModel.dataClassifierIdToDataSegmentCountMap) &&
                                                !_.isNil(dataAnalysisResourceModel.scannedDataSegmentCount),
                                                new EntityProfileDefinitionTab(
                                                    ProfileCategory.DataAnalysis,
                                                    <DataAnalysisResourceDataSegments dataAnalysisResourceModel={dataAnalysisResourceModel}/>,
                                                    localization.tabs.profileView[ProfileView.DataAnalysisResourceDataSegments](),
                                                    ProfileView.DataAnalysisResourceDataSegments)).
                                            value();
                                    }).
                                value()).
                        concatIf(
                            !_.isEmpty(definition.options.additionalTabs),
                            definition.options.additionalTabs!).
                        concatIf(
                            UserHelper.hasAnyScopePermissions(entityModel.entity.scopeIds, Contract.IdentityPermission.SecurityRead) &&
                            entityTypeMetadataModel.snapshots,
                            new EntityProfileDefinitionTab(
                                ProfileCategory.Iam,
                                <Snapshots
                                    entityId={entityModel.id}
                                    variant="entity"/>,
                                localization.tabs.profileView[ProfileView.Snapshots](),
                                ProfileView.Snapshots)).
                        concatIf(
                            UserHelper.hasAnyScopePermissions(entityModel.entity.scopeIds, Contract.IdentityPermission.SecurityRead) &&
                            entityTypeMetadataModel.events &&
                            _.some(activeCloudProviderTenantTypes) &&
                            (_.includes(activeCloudProviderTenantTypes, Contract.TenantType.Azure) ||
                                !TypeHelper.extendOrImplement(entityModel.entity.typeName, Contract.TypeNames.AadDirectoryServicePrincipal)) &&
                            (_.includes(activeCloudProviderTenantTypes, Contract.TenantType.Gcp) ||
                                !TypeHelper.extendOrImplement(entityModel.entity.typeName, Contract.TypeNames.IGciPrincipal)),
                            new EntityProfileDefinitionTab(
                                ProfileCategory.Events,
                                <Events
                                    entityId={entityModel.id}
                                    variant="tab"/>,
                                localization.tabs.profileView[ProfileView.Events](),
                                ProfileView.Events)).
                        value();

                const categoryViewToTabMap =
                    _.groupBy(
                        entityProfileTabs,
                        entityProfileTab => entityProfileTab.category);

                return [categoryViewToTabMap, entityProfileTabs];
            },
            [definition]);

    const orderedCategories =
        useMemo(
            () => {
                const categories =
                    _(entityProfileTabs).
                        map(entityProfileTab => entityProfileTab.category).
                        uniq().
                        value();

                return _.intersection(
                    [
                        ProfileCategory.Overview,
                        ProfileCategory.Risks,
                        ProfileCategory.Code,
                        ProfileCategory.DataAnalysis,
                        ProfileCategory.Iam,
                        ProfileCategory.Network,
                        ProfileCategory.WorkloadAnalysis,
                        ProfileCategory.Events
                    ],
                    categories);
            },
            []);

    const currentCategory =
        useMemo(
            () =>
                _.includes(orderedCategories, category)
                    ? category as ProfileCategory
                    : _.first(orderedCategories)!,
            [category, orderedCategories]);

    const orderedCategoriesTabs =
        useMemo(
            () =>
                _.map(
                    orderedCategories,
                    category => ({
                        additionalPropertyNameToValueMap: {
                            "Entity Type":
                                entityTypeNameTranslator(
                                    entityModel.entity.typeName,
                                    {
                                        count: 0,
                                        includeServiceName: true
                                    })
                        },
                        icon: <CategoryIcon category={category}/>,
                        title: localization.tabs.categoryView[category]({ openRelatedEntityRiskCount: entityModel.risks.risks.openRelatedEntityRiskCount }),
                        view: category
                    } as VerticalTabViewItem<ProfileCategory>)),
            [orderedCategories, entityModel]);

    return (
        <ProfileLayout
            topBarElement={
                <Topbar
                    definition={definition}
                    entityModel={entityModel}/>}>
            <VerticalTabView
                items={orderedCategoriesTabs}
                selectedView={currentCategory}
                storageItem={StorageHelper.customerEntitiesProfileTabsOpen}
                onSelectedViewChanged={
                    view => {
                        const entityViewName = EntityTypeMetadataModelHelper.get(entityModel.entity.typeName).entitiesViewName;
                        if (StorageHelper.customerEntitiesSelectedTableView.getValue() === entityViewName) {
                            StorageHelper.
                                customerEntitiesProfileSelectedTab(entityViewName).
                                setValue(view ?? ProfileCategory.Overview);
                        }
                        setRoute(CustomerConsoleAppUrlHelper.getEntityProfileRelativeUrl(entityModel, { category: view as ProfileCategory ?? ProfileCategory.Overview })!);
                    }}>
                <Stack
                    sx={{
                        height: "100%",
                        width: "100%"
                    }}>
                    {!_.isNil(categoryViewToTabMap[currentCategory]) &&
                        <Loading>
                            <Category
                                category={currentCategory}
                                entityId={entityModel.id}
                                initView={currentCategory === ProfileCategory.Iam}
                                tabs={categoryViewToTabMap[currentCategory]}
                                title={localization.tabs.categoryView[currentCategory]({ openRelatedEntityRiskCount: entityModel.risks.risks.openRelatedEntityRiskCount })}/>
                        </Loading>}
                </Stack>
            </VerticalTabView>
        </ProfileLayout>);
}