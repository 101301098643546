import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, InfoItem } from "../../../../../../../../../../common";

export function useAwsCommonEc2ResourceInfoElements(resourceModel: Contract.AwsResourceModel) {
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsCommonEc2ResourceInfoElements",
            () => ({
                rawId: "ID"
            }));

    return [
        <InfoItem
            key="rawId"
            title={localization.rawId()}
            value={(resourceModel.entity as Contract.AwsEc2Resource).rawId}/>
    ];
}