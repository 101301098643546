import { Store } from "@infrastructure";
import { Contract, DataController } from "..";

export const dataAnalysisResourceScanStore =
    new Store<Contract.DataAnalysisResourceScan, never, never>(
        dataAnalysisResourceScan => dataAnalysisResourceScan.resourceId,
        {
            get:
                async ids => {
                    const { dataAnalysisResourceScans } = await DataController.getDataAnalysisResourceScans(new Contract.DataControllerGetDataAnalysisResourceScansRequest(ids));
                    return dataAnalysisResourceScans;
                }
        });