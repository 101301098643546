import { DataTable, DataTableActions, DataTableColumn, DataTableColumnRenderProps, NumberFormatter, optionalTableCell, useChangeEffect, useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { ReactNode, useRef } from "react";
import { Contract, EntitiesCell } from "../../../common";

type AwsEc2DeviceTableProps = {
    additionalColumns?: ReactNode[];
    devices: Contract.AwsEc2Device[];
    snapshotRawIdToIdMap?: Dictionary<string>;
};

export function AwsEc2DeviceTable({ additionalColumns, devices, snapshotRawIdToIdMap }: AwsEc2DeviceTableProps) {
    const dataTableActionsRef = useRef<DataTableActions>();
    useChangeEffect(
        () => {
            dataTableActionsRef.current!.reset();
        },
        [devices]);

    const localization =
        useLocalization(
            "tenants.aws.awsEc2DeviceTable",
            () => ({
                columns: {
                    encryptionEnabled: {
                        false: "No",
                        title: "Encrypted",
                        true: "Yes"
                    },
                    instanceTerminationDeletion: {
                        false: "No",
                        title: "Delete On Termination",
                        true: "Yes"
                    },
                    name: "Device Name",
                    size: "Size",
                    snapshot: "Snapshot"
                }
            }));

    return (
        <DataTable
            actionsRef={dataTableActionsRef}
            fetchItems={() => devices}
            getItemId={(item: Contract.AwsEc2Device) => item.name}
            sortOptions={{ enabled: false }}
            variant="card">
            {_<ReactNode>([]).
                concat([
                    <DataTableColumn
                        id={DeviceColumnId.Name}
                        itemProperty={(item: Contract.AwsEc2Device) => item.name}
                        key={DeviceColumnId.Name}
                        title={localization.columns.name()}/>,
                    <DataTableColumn
                        id={DeviceColumnId.Size}
                        key={DeviceColumnId.Size}
                        render={
                            optionalTableCell<Contract.AwsEc2Device>(
                                item =>
                                    _.isNil(item.size) || item.size === 0
                                        ? undefined
                                        : NumberFormatter.storage(item.size))}
                        title={localization.columns.size()}/>,
                    <DataTableColumn
                        id={DeviceColumnId.InstanceTerminationDeletion}
                        key={DeviceColumnId.InstanceTerminationDeletion}
                        render={
                            optionalTableCell<Contract.AwsEc2Device>(
                                item =>
                                    _.isNil(item.instanceTerminationDeletion)
                                        ? undefined
                                        : item.instanceTerminationDeletion
                                            ? localization.columns.instanceTerminationDeletion.true()
                                            : localization.columns.instanceTerminationDeletion.false())}
                        title={localization.columns.instanceTerminationDeletion.title()}/>,
                    <DataTableColumn
                        id={DeviceColumnId.EncryptionEnabled}
                        key={DeviceColumnId.EncryptionEnabled}
                        render={
                            optionalTableCell<Contract.AwsEc2Device>(
                                item =>
                                    _.isNil(item.encryptionEnabled)
                                        ? undefined
                                        : item.encryptionEnabled
                                            ? localization.columns.encryptionEnabled.true()
                                            : localization.columns.encryptionEnabled.false())}
                        title={localization.columns.encryptionEnabled.title()}/>
                ]).
                concatIf(
                    !_.isEmpty(snapshotRawIdToIdMap),
                    () => (
                        <DataTableColumn
                            id={DeviceColumnId.Snapshot}
                            key={DeviceColumnId.Snapshot}
                            render={
                                ({ item }: DataTableColumnRenderProps<Contract.AwsEc2Device>) =>
                                    !_.isNil(item.snapshotRawId) &&
                                    !_.has(snapshotRawIdToIdMap, item.snapshotRawId)
                                        ? <Typography>
                                            {item.snapshotRawId}
                                        </Typography>
                                        : <EntitiesCell
                                            entityIdsOrModels={
                                                _.isNil(item.snapshotRawId)
                                                    ? undefined
                                                    : snapshotRawIdToIdMap![item.snapshotRawId]}
                                            entityTypeName={Contract.TypeNames.AwsEc2Snapshot}
                                            inlineEntitiesVariant="itemOrItemCountAndType"/>}
                            title={localization.columns.snapshot()}/>)).
                concatIf(
                    !_.isEmpty(additionalColumns),
                    () => additionalColumns).
                value()}
        </DataTable>);
}

enum DeviceColumnId {
    EncryptionEnabled = "encryptionEnabled",
    InstanceTerminationDeletion = "instanceTerminationDeletion",
    Name = "name",
    Size = "size",
    Snapshot = "snapshot"
}