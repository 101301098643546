import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function DataTableSummaryToggleIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                clipRule="evenodd"
                d="M2 16.3999C2 15.8476 2.44772 15.3999 3 15.3999H21C21.5523 15.3999 22 15.8476 22 16.3999C22 16.9522 21.5523 17.3999 21 17.3999H3C2.44772 17.3999 2 16.9522 2 16.3999Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M2 21.3999C2 20.8476 2.44772 20.3999 3 20.3999H21C21.5523 20.3999 22 20.8476 22 21.3999C22 21.9522 21.5523 22.3999 21 22.3999H3C2.44772 22.3999 2 21.9522 2 21.3999Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M11.8981 3.69995C10.3298 3.69995 7.84121 3.80619 5.90426 3.90296C4.87059 3.9546 4.05266 4.80168 4.03456 5.84848L4.00027 7.8317L4.05138 9.00114C4.09486 9.99608 4.8576 10.7928 5.82934 10.8732C7.70412 11.0283 10.1827 11.2 11.9396 11.2C13.7617 11.2 16.3587 11.0154 18.2525 10.8563C19.1764 10.7786 19.8789 10.0694 19.9367 9.19144C19.977 8.57833 20.0043 7.93301 19.9993 7.35353C19.9944 6.78887 19.9569 6.16131 19.9066 5.5616C19.8322 4.67358 19.0949 3.96726 18.1452 3.91832C16.1972 3.81793 13.551 3.69995 11.8981 3.69995ZM5.80446 1.90545C7.73707 1.8089 10.273 1.69995 11.8981 1.69995C13.61 1.69995 16.3055 1.82086 18.2482 1.92097C20.1367 2.01829 21.7367 3.45057 21.8996 5.39454C21.9522 6.02171 21.9938 6.70331 21.9992 7.33628C22.0048 7.9837 21.9744 8.68274 21.9323 9.32266C21.8066 11.2349 20.2824 12.6928 18.4199 12.8492C16.526 13.0084 13.8542 13.2 11.9396 13.2C10.0921 13.2 7.54071 13.0216 5.66441 12.8664C3.6682 12.7012 2.13983 11.0688 2.05328 9.08847L1.99951 7.8581L2.03486 5.81391C2.07094 3.72685 3.70771 2.0102 5.80446 1.90545Z"
                fillRule="evenodd"/>
        </SvgIcon>);
}