import { useLocalization, useLocalizeList } from "@infrastructure";
import _ from "lodash";
import { AuditEventDefinition, AuditEventDefinitionContext, AuditEventDefinitionDetailItem } from "..";
import { Contract, useCloudProviderTenantPermissionTypesTranslator } from "../../../../../../../../../common";

export function useOciOrganizationAuditEventDefinition(context: AuditEventDefinitionContext) {
    const localizeList = useLocalizeList();
    const cloudProviderTenantPermissionTypesTranslator = useCloudProviderTenantPermissionTypesTranslator();
    const localization =
        useLocalization(
            "views.customer.auditEvents.profile.hooks.useDefinition.hooks.useOciOrganizationAuditEventDefinition",
            () => ({
                enabled: {
                    false: "Off",
                    title: "Onboard new compartments",
                    true: "On"
                },
                folderEnabled: {
                    false: "Off",
                    title: "Folder structure sync",
                    true: "On"
                },
                organizationName: "Tenancy name",
                organizationOcid: "Tenancy OCID",
                permissionTypes: "Permissions",
                propertyChanges: {
                    title: "Updated properties",
                    user: "User"
                },
                userOcid: "User OCID"
            }));

    const organizationAuditEvent = context.auditEventModel.auditEvent as Contract.OciOrganizationAuditEvent;
    return new AuditEventDefinition(
        _<AuditEventDefinitionDetailItem>([]).
            concat(
                new AuditEventDefinitionDetailItem(
                    localization.organizationName(),
                    organizationAuditEvent.organizationName),
                new AuditEventDefinitionDetailItem(
                    localization.organizationOcid(),
                    organizationAuditEvent.organizationOcid)).
            concatIf(
                organizationAuditEvent.typeName === Contract.TypeNames.OciOrganizationCreationAuditEvent ||
                organizationAuditEvent.typeName === Contract.TypeNames.OciOrganizationUpdateAuditEvent,
                () =>
                    [
                        new AuditEventDefinitionDetailItem(
                            localization.permissionTypes(),
                            _.join(
                                cloudProviderTenantPermissionTypesTranslator.multiple(
                                    (organizationAuditEvent as Contract.OciOrganizationCreationAuditEvent | Contract.OciOrganizationUpdateAuditEvent).permissionTypes,
                                    "shortName"),
                                ",")),
                        new AuditEventDefinitionDetailItem(
                            localization.userOcid(),
                            (organizationAuditEvent as Contract.OciOrganizationCreationAuditEvent | Contract.OciOrganizationUpdateAuditEvent).organizationUserOcid)
                    ]).
            concatIf(
                organizationAuditEvent.typeName === Contract.TypeNames.OciOrganizationUpdateAuditEvent,
                () => {
                    const organizationUpdateAuditEvent = organizationAuditEvent as Contract.OciOrganizationUpdateAuditEvent;
                    const properties =
                        _.filter([
                            organizationUpdateAuditEvent.enabledChanged
                                ? localization.enabled.title()
                                : undefined,
                            organizationUpdateAuditEvent.folderEnabledChanged
                                ? localization.folderEnabled.title()
                                : undefined,
                            organizationUpdateAuditEvent.organizationUserChanged
                                ? localization.propertyChanges.user()
                                : undefined,
                            organizationUpdateAuditEvent.permissionTypesChanged
                                ? localization.permissionTypes()
                                : undefined
                        ]);
                    return _<AuditEventDefinitionDetailItem>([]).
                        concat(
                            new AuditEventDefinitionDetailItem(
                                localization.enabled.title(),
                                organizationUpdateAuditEvent.enabled
                                    ? localization.enabled.true()
                                    : localization.enabled.false()),
                            new AuditEventDefinitionDetailItem(
                                localization.folderEnabled.title(),
                                organizationUpdateAuditEvent.folderEnabled
                                    ? localization.folderEnabled.true()
                                    : localization.folderEnabled.false())).
                        concatIf(
                            !_.isEmpty(properties),
                            new AuditEventDefinitionDetailItem(
                                localization.propertyChanges.title(),
                                localizeList(properties)!)).
                        value();
                }).
            value());
}