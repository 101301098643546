import _ from "lodash";
import React from "react";
import { FormLayout, makeContextProvider, Optional, OrderedWizard, OrderedWizardItem, useLocalization } from "@infrastructure";
import { useAzureContext, useSetAzureContext } from "../..";
import { Contract, LicensingHelper, tenantModelStore } from "../../../../../../../../../../../../../../common";
import { AadTenantItem, FinishItem, OrganizationDetailsItem, OrganizationMemberSelectionItem, PermissionsItem, RoleDefinitionsItem } from "./components";

export class AddOrEditContext {
    public enabled?: boolean;
    public folderEnabled?: boolean;
    public memberSelection?: Contract.OrganizationMemberSelection;
    public name?: string;
    public organizationMemberTenantNames?: string[];
    public permissionTypes?: Contract.CloudProviderTenantPermissionType[];
    public servicePrincipalAssignedRoleDefinitionNames?: string[];
    public servicePrincipalMissingAssignedRoleDefinitionNames?: string[];

    constructor(
        public aadTenantModel: Optional<Contract.AadTenantModel>,
        public organizationConfiguration: Optional<Contract.AzureOrganizationConfiguration>) {
        this.enabled = this.organizationConfiguration?.enabled;
        this.folderEnabled = this.organizationConfiguration?.folderEnabled;
        this.memberSelection = this.organizationConfiguration?.memberSelection;
        this.name = this.organizationConfiguration?.name;
        this.permissionTypes =
            this.organizationConfiguration?.permissionTypes ??
            _<Contract.CloudProviderTenantPermissionType>([]).
                concat(Contract.CloudProviderTenantPermissionType.Read).
                concatIf(
                    LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.PermissionManagement),
                    Contract.CloudProviderTenantPermissionType.PermissionManagement).
                concatIf(
                    LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.Cnapp),
                    Contract.CloudProviderTenantPermissionType.WorkloadAnalysis).
                value();
    }
}

export const [useAddOrEditContext, useSetAddOrEditContext, useAddOrEditContextProvider] = makeContextProvider<AddOrEditContext>();

export function AddOrEdit() {
    const { addOrEditOpen } = useAzureContext();
    const setAzureContext = useSetAzureContext();

    const aadTenantModel =
        tenantModelStore.useGet(
            _.isBoolean(addOrEditOpen)
                ? undefined
                : addOrEditOpen.aadTenantId);

    const [context, , ContextProvider] =
        useAddOrEditContextProvider(
            () =>
                new AddOrEditContext(
                    aadTenantModel as Contract.AadTenantModel,
                    _.isBoolean(addOrEditOpen)
                        ? undefined
                        : addOrEditOpen));

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCloudProviderTenantOrganizationItems.azure.addOrEdit",
            () => ({
                steps: {
                    aadTenantItem: "Select Microsoft Entra ID Tenant",
                    configureScopes: "Configure Scopes",
                    organizationDetailsItem: "Organization Details",
                    permissionsItem: "Permissions",
                    roleDefinitionsItem: "Assign Roles"
                },
                title: {
                    add: "Add Azure Organization",
                    edit: "Edit Azure Organization - {{name}}"
                }
            }));

    return (
        <ContextProvider>
            <FormLayout
                disableContentPadding={true}
                titleOptions={{
                    text:
                        _.isNil(context.organizationConfiguration)
                            ? localization.title.add()
                            : localization.title.edit({ name: context.name })
                }}>
                <OrderedWizard
                    finishItemElement={<FinishItem/>}
                    startItemIndex={
                        _.isNil(context.organizationConfiguration)
                            ? undefined
                            : 1}
                    onClose={
                        () =>
                            setAzureContext(
                                azureContext => ({
                                    ...azureContext,
                                    addOrEditOpen: false
                                }))}>
                    <OrderedWizardItem
                        deferredLoading={true}
                        title={localization.steps.aadTenantItem()}>
                        <AadTenantItem/>
                    </OrderedWizardItem>
                    <OrderedWizardItem title={localization.steps.organizationDetailsItem()}>
                        <OrganizationDetailsItem/>
                    </OrderedWizardItem>
                    <OrderedWizardItem title={localization.steps.permissionsItem()}>
                        <PermissionsItem/>
                    </OrderedWizardItem>
                    <OrderedWizardItem title={localization.steps.roleDefinitionsItem()}>
                        <RoleDefinitionsItem/>
                    </OrderedWizardItem>
                    <OrderedWizardItem
                        deferredLoading={true}
                        title={localization.steps.configureScopes()}>
                        <OrganizationMemberSelectionItem/>
                    </OrderedWizardItem>
                </OrderedWizard>
            </FormLayout>
        </ContextProvider>);
}