﻿import { Optional, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, Entity, entityModelStore, riskPolicyModelStore } from "../../../../../../../../../common";
import { InlineTagsSelection } from "../../../../../../../../../common/components/CustomRiskPolicy/components";
import { RiskDefinitionContextItem } from "../../../utilities";
import { useCommonCustomEntityRiskDefinition } from "./useCommonCustomEntityRiskDefinition";

type ResourceTagNotExistsRiskViolationTableProps = {
    getContextItems: (resourceModel: Contract.EntityModel) => Optional<RiskDefinitionContextItem>[];
    riskModel: Contract.RiskModel;
};

export function useResourceTagNotExistsRiskDefinition({ getContextItems, riskModel }: ResourceTagNotExistsRiskViolationTableProps) {
    const risk = riskModel.risk as Contract.EntityRisk;
    const resourceModel = entityModelStore.useGet(risk.entityId);
    const riskPolicyModel = riskPolicyModelStore.useGet(riskModel.risk.policyId);
    const riskPolicyConfiguration = riskPolicyModel.riskPolicyConfiguration as Contract.ResourceTagNotExistsRiskPolicyConfiguration;
    const resourceTags = _.as<Contract.ITagEntity>(resourceModel.entity).tags;

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.useResourceTagNotExistsRiskDefinition",
            () => ({
                description: "{{resource}} is missing {{tags}}"
            }));

    return useCommonCustomEntityRiskDefinition(
        localization.description({
            resource:
                <Entity
                    entityIdOrModel={resourceModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>,
            tags:
                <InlineTagsSelection
                    selection={{
                        existsResourceTagKeys:
                            _.differenceWith(
                                riskPolicyConfiguration?.existsResourceTagKeys,
                                _.map(resourceTags, tag => tag.key),
                                (riskTagKey, resourceTagKey) => riskTagKey.toUpperCase() == resourceTagKey.toUpperCase()),
                        existsResourceTagPatternKey: riskPolicyConfiguration?.existsResourceTagPattern?.keyPattern,
                        existsResourceTagPatternValue: riskPolicyConfiguration?.existsResourceTagPattern?.valuePattern,
                        existsResourceTags:
                            _.differenceWith(
                                riskPolicyConfiguration?.existsResourceTags,
                                resourceTags,
                                (riskTag, resourceTag) => riskTag.key.toUpperCase() == resourceTag.key.toUpperCase() &&
                                    riskTag.value == resourceTag.value)
                    }}/>
        }),
        riskModel,
        riskPolicyConfiguration,
        () => getContextItems(resourceModel));
}