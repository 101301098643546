﻿import { TimeFormatter, useLocalization } from "@infrastructure";
import { Box, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract, useTheme } from "../../../../../../../../../../../../../../common";

export type ConsoleAccessSectionProps = {
    password: Contract.AwsIamUserPassword;
};

export function ConsoleAccessSection({ password }: ConsoleAccessSectionProps) {
    const theme = useTheme();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsIamUserMfaDisabledRiskDefinition.consoleAccessSection",
            () => ({
                properties: {
                    disabled: "Disabled",
                    enabled: "Enabled",
                    neverUsed: "Never",
                    usageTime: "Usage Time: **{{date}}**"
                },
                titles: {
                    mfa: "**MFA**",
                    password: "**Password**"
                }
            }));
    return (
        <Stack spacing={3}>
            <Box>
                <Typography variant="h5">
                    {localization.titles.password()}
                </Typography>
                <Typography>
                    {localization.properties.enabled()}
                </Typography>
                <Typography>
                    {localization.properties.usageTime({
                        date:
                            _.isNil(password.usageTime)
                                ? localization.properties.neverUsed()
                                : TimeFormatter.mediumDate(password.usageTime)
                    })}
                </Typography>
            </Box>
            <Box>
                <Typography variant="h5">
                    {localization.titles.mfa()}
                </Typography>
                <Typography
                    sx={{
                        color: theme.palette.error.main,
                        fontWeight: 600
                    }}>
                    {localization.properties.disabled()}
                </Typography>
            </Box>
        </Stack>);
}