import { ActionButton, DiffDialog, useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { DiffViewerIcon } from "../../../../../../../../../common";

type PolicyDocumentDiffProps = {
    existingRawInlinePolicyDocument?: string;
    rawInlinePolicyDocument?: string;
};

export function AwsInlinePolicyDocumentDiff({ existingRawInlinePolicyDocument, rawInlinePolicyDocument }: PolicyDocumentDiffProps) {
    const localization =
        useLocalization(
            "views.user.auditEvents.permissionEligibilityAuditEvents.detailsCell.awsInlinePolicyDocumentDiff",
            () => ({
                dialog: {
                    existingPolicyDocument: "Old policy",
                    policyDocument: "New policy",
                    title: "Policy difference"
                },
                title: "Inline policy diff: {{policyDiffElement}}"
            }));

    const [open, setOpen] = useState(false);
    return (
        <Stack>
            {open && (
                <DiffDialog
                    format="json"
                    modified={rawInlinePolicyDocument}
                    modifiedTitle={localization.dialog.policyDocument()}
                    original={existingRawInlinePolicyDocument}
                    originalTitle={localization.dialog.existingPolicyDocument()}
                    title={localization.dialog.title()}
                    onClose={() => setOpen(false)}/>)}
            <Typography noWrap={true}>
                {localization.title({
                    policyDiffElement:
                        <ActionButton onClick={() => setOpen(true)}>
                            <DiffViewerIcon/>
                        </ActionButton>
                })}
            </Typography>
        </Stack>);
}