import { Stack, SxProps } from "@mui/material";
import _ from "lodash";
import React, { useState } from "react";
import { InlineCopyToClipboardText, Step, Steps, useLocalization } from "@infrastructure";
import { Contract, FeatureHelper, ScopeHelper, useTheme } from "../../../../../../../../../../../../../common";
import { useAddOrEditContext } from "../../../AddOrEdit";
import { PolicyDocumentAccordion } from "./index";

export type ManualProps = {
    roleArnStep: Step;
    tenantOnboardingPoliciesInfo: Contract.TenantControllerGetAwsTenantOnboardingPoliciesInfoResponse;
};

export function Manual({ roleArnStep, tenantOnboardingPoliciesInfo }: ManualProps) {
    const { tenantModel, tenantOnboardingInfo, tenantPartitionType, tenantPermissionTypes } = useAddOrEditContext();
    const localization =
        useLocalization(
            "views.customer.scopes.hooks.useDefinition.hooks.useAwsDefinition.addOrEdit.roleItem.manual",
            () => ({
                steps: {
                    add: {
                        common: {
                            end: {
                                step1: "In the list of policies, search for and select the **SecurityAudit** policy and then finish creating the role",
                                step2: "Open the newly created role",
                                step3: {
                                    steps: {
                                        step1: "Click **Add permissions > Create inline policy**",
                                        step2: "Copy the policy below and paste it in the JSON editor",
                                        step3: "Click **Next** and then finish creating the policy"
                                    },
                                    title: "For each of the policies below, perform the following to create an inline policy"
                                }
                            },
                            start: {
                                step1: "In the AWS Management Console, navigate to **IAM > Roles** and click **Create role**"
                            }
                        },
                        partitionTypeChina: {
                            step1: "For the trusted entity type, select **Custom trust policy**",
                            step2: "For the custom trust policy, click **Add** next to **Add a principal**",
                            step3: "For the principal type, search for and select IAM users, enter the following ARN {{chinaAuthenticationUserArn}} and then click **Add principal**",
                            step5: {
                                parameters: {
                                    conditionKey: "Condition key: **sts:ExternalId**",
                                    operator: "Operator: **StringEquals**",
                                    qualifier: "Qualifier: **Default**",
                                    value: "Value: {{roleExternalId}}"
                                },
                                title: "Click **Add** next to **Add a condition** and enter the following parameters"
                            },
                            step6: "Click **Add Condition** and then click **Next**"
                        },
                        partitionTypeOther: {
                            step1: "Select the type of trusted entity **Another AWS account**",
                            step2: "Enter Account ID: {{applicationRoleTenantRawId}}",
                            step3: "Check **Require external ID**, enter External ID: {{roleExternalId}} and click **Next**"
                        }
                    },
                    edit: {
                        step1: "Login into your AWS Account",
                        step2: "Verify the following policies are attached to role **{{roleName}}**"
                    }
                }
            }));

    const theme = useTheme();
    return (
        <Steps variant="letters">
            {_.isNil(tenantModel)
                ? _<string | Step>([]).
                    concat(localization.steps.add.common.start.step1()).
                    concatIf(
                        tenantPartitionType !== Contract.AwsPartitionType.China,
                        [
                            localization.steps.add.partitionTypeOther.step1(),
                            localization.steps.add.partitionTypeOther.step2({
                                applicationRoleTenantRawId:
                                    <InlineCopyToClipboardText text={tenantOnboardingPoliciesInfo.applicationRoleTenantRawId!}/>
                            }),
                            localization.steps.add.partitionTypeOther.step3({
                                roleExternalId: <InlineCopyToClipboardText text={ScopeHelper.customerId}/>
                            })
                        ]).
                    concatIf(
                        tenantPartitionType === Contract.AwsPartitionType.China,
                        [
                            localization.steps.add.partitionTypeChina.step1(),
                            localization.steps.add.partitionTypeChina.step2(),
                            localization.steps.add.partitionTypeChina.step3({
                                chinaAuthenticationUserArn:
                                    <InlineCopyToClipboardText text={tenantOnboardingInfo.chinaAuthenticationUserArn!}/>
                            }),
                            new Step(
                                localization.steps.add.partitionTypeChina.step5.title(),
                                {
                                    contentElement:
                                        <Steps>
                                            {localization.steps.add.partitionTypeChina.step5.parameters.conditionKey()}
                                            {localization.steps.add.partitionTypeChina.step5.parameters.qualifier()}
                                            {localization.steps.add.partitionTypeChina.step5.parameters.operator()}
                                            {localization.steps.add.partitionTypeChina.step5.parameters.value({
                                                roleExternalId:
                                                    <InlineCopyToClipboardText text={ScopeHelper.customerId}/>
                                            })}
                                        </Steps>
                                }),
                            localization.steps.add.partitionTypeChina.step6()]).
                    concatIf(
                        _.includes(tenantPermissionTypes, Contract.CloudProviderTenantPermissionType.Read),
                        localization.steps.add.common.end.step1()).
                    concat([
                        localization.steps.add.common.end.step2(),
                        new Step(
                            localization.steps.add.common.end.step3.title(),
                            {
                                contentElement:
                                    <Stack
                                        spacing={2}
                                        sx={{ marginTop: theme.spacing(1) }}>
                                        <Steps>
                                            {localization.steps.add.common.end.step3.steps.step1()}
                                            {localization.steps.add.common.end.step3.steps.step2()}
                                            {localization.steps.add.common.end.step3.steps.step3()}
                                        </Steps>
                                        <Policies tenantOnboardingPoliciesInfo={tenantOnboardingPoliciesInfo}/>
                                    </Stack>
                            }),
                        roleArnStep
                    ]).
                    value()
                : [
                    localization.steps.edit.step1(),
                    new Step(
                        localization.steps.edit.step2({ roleName: tenantModel.roleName }),
                        {
                            contentElement:
                                <Policies
                                    sx={{ marginTop: theme.spacing(3) }}
                                    tenantOnboardingPoliciesInfo={tenantOnboardingPoliciesInfo}/>
                        }),
                    roleArnStep
                ]}
        </Steps>);
}

type PoliciesProps = {
    sx?: SxProps;
    tenantOnboardingPoliciesInfo: Contract.TenantControllerGetAwsTenantOnboardingPoliciesInfoResponse;
};

function Policies({ sx, tenantOnboardingPoliciesInfo }: PoliciesProps) {
    const { tenantPermissionTypes } = useAddOrEditContext();
    const localization =
        useLocalization(
            "views.customer.scopes.hooks.useDefinition.hooks.useAwsDefinition.addOrEdit.roleItem.manual.policies",
            () => ({
                policy: {
                    containerImageRepositoryAnalysis: "ECR Scanning Permissions Policy (Workload Protection)",
                    dataAnalysis: "Data Resources Scanning Permission Policy (Data Protection)",
                    management: "Remediation Permissions Policy",
                    permissionManagement: "JIT Access Policy",
                    readOnly: "Monitoring Permissions Policy",
                    terraformStateBucket: "Terraform Permissions Policy",
                    trailBucket: "CloudTrail Permissions Policy",
                    virtualMachineAnalysis: "EC2 Instance Scanning Permissions Policy (Workload Protection)",
                    virtualMachineAndImageAnalysis: "EC2 Instance and AMI Scanning Permissions Policy (Workload Protection)"
                }
            }));

    const [selectedPolicyType, setSelectedPolicyType] = useState<PolicyType | undefined>();
    return (
        <Stack sx={sx}>
            {_.includes(tenantPermissionTypes, Contract.CloudProviderTenantPermissionType.Read) &&
                <PolicyDocumentAccordion
                    policyDocument={tenantOnboardingPoliciesInfo.roleReadOnlyPolicyDocument}
                    policyType={PolicyType.Read}
                    selectedPolicyType={selectedPolicyType}
                    setSelectedPolicyType={setSelectedPolicyType}
                    title={localization.policy.readOnly()}/>}
            {_.includes(tenantPermissionTypes, Contract.CloudProviderTenantPermissionType.Write) &&
                <PolicyDocumentAccordion
                    policyDocument={tenantOnboardingPoliciesInfo.roleManagementPolicyDocument}
                    policyType={PolicyType.Write}
                    selectedPolicyType={selectedPolicyType}
                    setSelectedPolicyType={setSelectedPolicyType}
                    title={localization.policy.management()}/>}
            {_.includes(tenantPermissionTypes, Contract.CloudProviderTenantPermissionType.DataAnalysis) &&
                <PolicyDocumentAccordion
                    policyDocument={tenantOnboardingPoliciesInfo.roleDataAnalysisPolicyDocument}
                    policyType={PolicyType.DataAnalysis}
                    selectedPolicyType={selectedPolicyType}
                    setSelectedPolicyType={setSelectedPolicyType}
                    title={localization.policy.dataAnalysis()}/>}
            {_.some(
                [
                    Contract.CloudProviderTenantPermissionType.WorkloadAnalysis
                ],
                tenantPermissionType => _.includes(tenantPermissionTypes, tenantPermissionType)) &&
                <PolicyDocumentAccordion
                    policyDocument={tenantOnboardingPoliciesInfo.roleWorkloadAnalysisPolicyDocument}
                    policyType={PolicyType.VirtualMachineAnalysis}
                    selectedPolicyType={selectedPolicyType}
                    setSelectedPolicyType={setSelectedPolicyType}
                    title={FeatureHelper.enabled(Contract.FeatureName.AwsEc2MachineImageAnalysisEnabled)
                        ? localization.policy.virtualMachineAndImageAnalysis()
                        : localization.policy.virtualMachineAnalysis()}/>}
            {_.includes(tenantPermissionTypes, Contract.CloudProviderTenantPermissionType.ContainerImageRepositoryAnalysis) &&
                <PolicyDocumentAccordion
                    policyDocument={tenantOnboardingPoliciesInfo.roleContainerImageRepositoryAnalysisPolicyDocument}
                    policyType={PolicyType.ContainerImageRepositoryAnalysis}
                    selectedPolicyType={selectedPolicyType}
                    setSelectedPolicyType={setSelectedPolicyType}
                    title={localization.policy.containerImageRepositoryAnalysis()}/>}
            {_.includes(tenantPermissionTypes, Contract.CloudProviderTenantPermissionType.PermissionManagement) &&
                <PolicyDocumentAccordion
                    policyDocument={tenantOnboardingPoliciesInfo.rolePermissionManagementPolicyDocument}
                    policyType={PolicyType.PermissionManagement}
                    selectedPolicyType={selectedPolicyType}
                    setSelectedPolicyType={setSelectedPolicyType}
                    title={localization.policy.permissionManagement()}/>}
            {!_.isNil(tenantOnboardingPoliciesInfo.roleTerraformStateBucketPolicyDocument) &&
                <PolicyDocumentAccordion
                    policyDocument={tenantOnboardingPoliciesInfo.roleTerraformStateBucketPolicyDocument}
                    policyType={PolicyType.TerraformStateBucket}
                    selectedPolicyType={selectedPolicyType}
                    setSelectedPolicyType={setSelectedPolicyType}
                    title={localization.policy.terraformStateBucket()}/>}
            {!_.isNil(tenantOnboardingPoliciesInfo.roleTrailBucketPolicyDocument) &&
                <PolicyDocumentAccordion
                    policyDocument={tenantOnboardingPoliciesInfo.roleTrailBucketPolicyDocument}
                    policyType={PolicyType.TrailBucket}
                    selectedPolicyType={selectedPolicyType}
                    setSelectedPolicyType={setSelectedPolicyType}
                    title={localization.policy.trailBucket()}/>}
        </Stack>);
}

export enum PolicyType {
    ContainerImageRepositoryAnalysis = "containerImageRepositoryAnalysis",
    DataAnalysis = "dataAnalysis",
    PermissionManagement = "permissionManagement",
    Read = "read",
    TerraformStateBucket = "terraformStateBucket",
    TrailBucket = "trailBucket",
    VirtualMachineAnalysis = "virtualMachineAnalysis",
    Write = "write"
}