import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, InlineEntities } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetAzureResourceRiskContext } from "./useGetAzureResourceRiskContext";

export function useGetAzureManagedIdentityManagedIdentityRiskContext() {
    return useMemo(
        () => useAzureManagedIdentityManagedIdentityRiskContext,
        []);
}

function useAzureManagedIdentityManagedIdentityRiskContext(managedIdentityModel: Contract.AzureManagedIdentityManagedIdentityModel) {
    const resourceRiskContext = useGetAzureResourceRiskContext()(managedIdentityModel);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.contexts.useGetAzureManagedIdentityManagedIdentityRiskContext",
            () => ({
                originatorResources: "The managed identity is linked to {{originatorResourceIds}}"
            }));

    return {
        ...resourceRiskContext,
        originatorResources:
            _.isEmpty(managedIdentityModel.originatorResourceIds)
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.originatorResources({
                        originatorResourceIds:
                            <InlineEntities
                                entityIdsOrModels={managedIdentityModel.originatorResourceIds}
                                entityTypeName={Contract.TypeNames.AzureResource}
                                entityVariant="iconTextTypeTenant"
                                variant="itemAndTypeOrItemCountAndType"/>
                    }))
    };
}