import { Box } from "@mui/material";
import React from "react";
import { UdmObjectPropertyItemProps } from "../..";
import { Severity } from "../../../../Severity";

export function CommonSeverity({ item }: UdmObjectPropertyItemProps) {
    return (
        <Box sx={{ fontSize: "20px" }}>
            <Severity severity={item}/>
        </Box>);
}