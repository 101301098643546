import { Box } from "@mui/material";
import _ from "lodash";
import React from "react";
import { useLocalization } from "@infrastructure";
import { AccessDashboardContext, Contract, defaultSeverities, SeverityMultiSelect, StorageHelper, TypeHelper, useDashboardContext, useSetDashboardContext } from "../../../../../../../common";

export function TopbarFilter() {
    const setDashboardContext = useSetDashboardContext<AccessDashboardContext>();
    const { filters } = useDashboardContext<AccessDashboardContext>();

    const localization =
        useLocalization(
            "views.customer.access.dashboard.topbarFilter",
            () => ({
                severityFilterTitle: "Finding Severity"
            }));

    return (
        <Box>
            <SeverityMultiSelect
                selectedSeverities={filters.severities}
                title={localization.severityFilterTitle()}
                onSelectedSeveritiesChanged={
                    severities => {
                        const orderedSeverities =
                            _.orderBy(
                                severities,
                                severity => TypeHelper.getEnumValue(Contract.TypeNames.Severity, severity),
                                "desc");
                        setDashboardContext(
                            (context: AccessDashboardContext) => ({
                                ...context,
                                filters: {
                                    ...context.filters,
                                    severities: orderedSeverities
                                }
                            }));
                        StorageHelper.
                            customerAccessDashboardPrincipalsWidgetSeverities.
                            setValue(
                                JSON.stringify(
                                    _.isEmpty(orderedSeverities)
                                        ? defaultSeverities
                                        : orderedSeverities));
                    }}/>
        </Box>);
}