import { Link, Step, Steps, useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract } from "../../../../../../../../../../../../../../../../../common";
import { useAddOrEditContext } from "../../../AddOrEdit";

export function Manual() {
    const { aadTenantModel, organizationConfiguration, permissionTypes } = useAddOrEditContext();

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCloudProviderTenantOrganizationItems.azure.addOrEdit.roleDefinitionsItem.manual",
            () => ({
                dataAnalysisRoleDefinitionName: "Tenable Data Scanning",
                defaultAadApplicationName: "Tenable Cloud Security Connector",
                steps: {
                    step1: {
                        link: "**Access control (IAM)**",
                        title: "Log in to the Azure portal, and navigate to the {{link}} tab within the root management group."
                    },
                    step2: {
                        add: "Create a custom role called **{{dataAnalysisRoleDefinitionRoleName}}** and add the following permissions to it. For more information about required permissions, see the documentation:",
                        edit: "Verify a custom role called **{{dataAnalysisRoleDefinitionRoleName}}** exists and contains the following permissions:"
                    },
                    step3: {
                        add: "Assign roles to the **{{aadApplicationName}}** app by repeating the steps below for each of the following roles:",
                        edit: "Verify the following roles are assigned to **{{aadApplicationName}}** by following the steps below:"
                    },
                    step4: "Click on **Add > Add role assignment**",
                    step5: "Search for and select a role from the list above and click **Next**",
                    step6: "Click **+ Select members**",
                    step7: "Search for and select **{{aadApplicationName}}** and then click **Select**",
                    step8: "Click **Next** and then click **Review + assign** to finish. Repeat these steps for all additional roles."
                }
            }));

    const aadApplicationName = aadTenantModel!.configuration.application?.name ?? localization.defaultAadApplicationName();
    const dataAnalysisRoleDefinitionRoleName = localization.dataAnalysisRoleDefinitionName();
    const localizationVariant =
        _.isNil(organizationConfiguration)
            ? "add"
            : "edit";
    return (
        <Steps variant="letters">
            {_<any>([]).
                concat(
                    localization.steps.step1.title({
                        link:
                            <Link
                                urlOrGetUrl={`https://portal.azure.com/#view/Microsoft_Azure_ManagementGroups/ManagmentGroupDrilldownMenuBlade/~/iam/tenantId/${aadTenantModel!.configuration.applicationServicePrincipalRawId}/mgId/${aadTenantModel!.configuration.applicationServicePrincipalRawId}/mgDisplayName/Tenant%20Root%20Group/mgCanAddOrMoveSubscription~/true/mgParentAccessLevel/Not%20Authorized/defaultMenuItemId/overview/drillDownMode~/true`}
                                variant="external">
                                {localization.steps.step1.link()}
                            </Link>
                    })).
                concatIf(
                    _.includes(permissionTypes, Contract.CloudProviderTenantPermissionType.DataAnalysis),
                    new Step(
                        localization.steps.step2[localizationVariant]({ dataAnalysisRoleDefinitionRoleName }),
                        {
                            contentElement:
                                <Steps variant="plainNumbers">
                                    {_<string>([
                                        "Microsoft.Sql/servers/databases/write",
                                        "Microsoft.Storage/storageAccounts/privateEndpointConnections/write"
                                    ]).
                                        map(
                                            action =>
                                                new Step(
                                                    <Typography sx={{ fontWeight: 600 }}>
                                                        {action}
                                                    </Typography>)).
                                        value()}
                                </Steps>
                        })).
                concat(
                    new Step(
                        localization.steps.step3[localizationVariant]({ aadApplicationName }),
                        {
                            contentElement:
                                <Steps variant="plainNumbers">
                                    {_<string>([]).
                                        concat([
                                            "Reader",
                                            "Key Vault Reader",
                                            "Azure Kubernetes Service Cluster User Role",
                                            "Azure Kubernetes Service RBAC Reader"
                                        ]).
                                        concatIf(
                                            _.includes(permissionTypes, Contract.CloudProviderTenantPermissionType.WorkloadAnalysis),
                                            "Disk Snapshot Contributor (Virtual Machine Scanning)").
                                        concatIf(
                                            _.includes(permissionTypes, Contract.CloudProviderTenantPermissionType.PermissionManagement),
                                            "User Access Administrator (JIT)").
                                        concatIf(
                                            _.includes(permissionTypes, Contract.CloudProviderTenantPermissionType.Write),
                                            "Owner (Remediation)").
                                        concatIf(
                                            _.includes(permissionTypes, Contract.CloudProviderTenantPermissionType.DataAnalysis),
                                            "Storage Blob Data Reader",
                                            dataAnalysisRoleDefinitionRoleName).
                                        map(
                                            roleName =>
                                                new Step(
                                                    <Typography sx={{ fontWeight: 600 }}>
                                                        {roleName}
                                                    </Typography>)).
                                        value()}
                                </Steps>
                        }),
                    localization.steps.step4(),
                    localization.steps.step5(),
                    localization.steps.step6(),
                    localization.steps.step7({ aadApplicationName }),
                    localization.steps.step8()).
                value()}
        </Steps>);
}