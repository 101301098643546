import CheckIcon from "@mui/icons-material/Check";
import { Stack, SxProps, Typography, useTheme } from "@mui/material";
import _ from "lodash";
import React, { Fragment, ReactNode } from "react";
import { Loading } from ".";
import { useLocalization } from "../hooks";

const selectionLimit = 3;
export type SelectionViewType = "include" | "exclude";
type SelectionViewProps = {
    empty?: boolean;
    emptyValueTitle?: string;
    enableAll?: boolean;
    label: string;
    loaded?: boolean;
    renderValue?: (inline: boolean, value: any) => ReactNode;
    selectedValues?: any[];
    singleSelection?: boolean;
    sx?: SxProps;
    totalCount: number;
    type?: SelectionViewType;
};

export function SelectionView({ empty, emptyValueTitle, enableAll = true, label, loaded = true, renderValue, selectedValues, singleSelection = false, sx, totalCount, type = "include" }: SelectionViewProps) {
    const localization =
        useLocalization(
            "infrastructure.selectionView",
            () => ({
                all: "all",
                and: "and",
                empty: "Blanks",
                exclude: "is not",
                include: "is",
                options: " {{ count | NumberFormatter.humanize }} options",
                or: "or"
            }));

    const excludeNoValues = type === "exclude" && _.isEmpty(selectedValues);
    const includeAllValues = type === "include" && selectedValues?.length === totalCount && loaded;

    const theme = useTheme();
    return (
        <Stack
            direction="row"
            spacing={0.75}
            sx={{
                alignItems: "center",
                color:
                    empty
                        ? theme.palette.text.secondary
                        : undefined,
                overflow: "hidden",
                ...sx
            }}>
            {singleSelection && selectedValues && <CheckIcon/>}
            <Typography
                sx={{
                    fontWeight:
                        empty
                            ? 400
                            : 700
                }}>
                {label}
            </Typography>
            {!empty && !singleSelection && !_.isNil(selectedValues) &&
                <Loading
                    container="cell"
                    loading={!loaded}>
                    <Typography sx={{ color: theme.palette.text.secondary }}>
                        {type === "include" || selectedValues?.length == 0
                            ? localization.include()
                            : localization.exclude()}
                    </Typography>
                    {enableAll &&
                    (excludeNoValues || includeAllValues)
                        ? <Typography noWrap={true}>
                            {localization.all()}
                        </Typography>
                        : selectedValues.length === 0 && type === "exclude"
                            ? <Typography noWrap={true}>
                                {localization.options({ count: totalCount })}
                            </Typography>
                            : selectedValues.length > selectionLimit
                                ? <Typography>
                                    {localization.options({ count: selectedValues.length })}
                                </Typography>
                                : _.map(
                                    selectedValues,
                                    (selectedValue, selectedValueIndex) => (
                                        <Fragment key={selectedValueIndex}>
                                            <Typography noWrap={true}>
                                                {_.isNil(selectedValue)
                                                    ? emptyValueTitle ?? localization.empty()
                                                    : renderValue
                                                        ? renderValue(true, selectedValue)
                                                        : _.truncate(selectedValue, { length: 40 })}
                                            </Typography>
                                            {selectedValueIndex < selectedValues.length - 1 &&
                                                <Typography sx={{ color: theme.palette.text.secondary }}>
                                                    {type === "include"
                                                        ? localization.or()
                                                        : localization.and()}
                                                </Typography>}
                                        </Fragment>))}
                </Loading>}
        </Stack>);
}