import { Box, Stack, Typography } from "@mui/material";
import _, { Function0 } from "lodash";
import React, { Fragment, useCallback, useMemo, useState } from "react";
import { BreakpointResizer, EmptyMessage, NumberFormatter, useLocalization } from "@infrastructure";
import { WidgetDefinition } from "../../../..";
import { Contract } from "../../../../../../../controllers";
import { useTheme } from "../../../../../../../themes";
import { CustomerConsoleAppUrlHelper } from "../../../../../../../utilities";
import { DistributionChart, DistributionChartItem } from "../../../../../../DistributionChart";
import { SummaryDashboardContext, useDashboardContext } from "../../../../../Dashboard";
import { WorkloadAnalysisItem, WorkloadAnalysisWidgetItem } from "../components";

export function useGetWorkloadAnalysisVulnerabilityStatusDefinition(): () => WidgetDefinition {
    const localization =
        useLocalization(
            "common.dashboard.widget.hooks.useDefinition.hooks.useGetWorkloadAnalysisVulnerabilityStatusDefinition",
            () => ({
                helpText: "View a breakdown of machines by the highest severity vulnerability that was found on them.",
                title: "Virtual Machine Vulnerability Status"
            }));

    return useCallback<Function0<WidgetDefinition>>(
        () => ({
            element: <WorkloadAnalysisVulnerabilityStatus/>,
            options: {
                details: { detailsUrl: CustomerConsoleAppUrlHelper.getWorkloadAnalysisVirtualMachinesRelativeUrl(true) },
                helpText: localization.helpText(),
                title: localization.title()
            }
        }),
        [localization]);
}

function WorkloadAnalysisVulnerabilityStatus() {
    const { summary } = useDashboardContext<SummaryDashboardContext>();
    const localization =
        useLocalization(
            "common.dashboard.widget.hooks.useDefinition.hooks.useGetWorkloadAnalysisVulnerabilityStatusDefinition.workloadAnalysisVulnerabilityStatus",
            () => ({
                chart: {
                    subtitle: "Virtual Machines"
                },
                empty: "No Virtual Machines Scanned",
                severity: {
                    [Contract.Severity.Critical]: "*bold*Critical**",
                    [Contract.Severity.High]: "*bold*High**",
                    [Contract.Severity.Information]: "*bold*Information**",
                    [Contract.Severity.Low]: "*bold*Low**",
                    [Contract.Severity.Medium]: "*bold*Medium**",
                    none: "*bold*None**"
                }
            }));

    const virtualMachineVulnerabilityStats = summary.workloadAnalysisData!.virtualMachineVulnerabilityStats;
    const [vulnerabilitySeverityStatusCardItems, virtualMachineCount] =
        useMemo(
            () => {
                const virtualMachineCount =
                    _(virtualMachineVulnerabilityStats.unsecuredSeverityToCountMap).
                        values().
                        concat(virtualMachineVulnerabilityStats.securedCount).
                        sum();

                const virtualMachineVulnerabilitySeverityToCountMap =
                    _(virtualMachineVulnerabilityStats.unsecuredSeverityToCountMap).
                        pickBy(count => count > 0).
                        mapKeys((_, key) => key as Contract.Severity).
                        value();

                return [
                    _(Contract.Severity).
                        values().
                        filter(severity => severity !== Contract.Severity.Information).
                        reverse().
                        filter(severity => _(virtualMachineVulnerabilitySeverityToCountMap).
                            has(severity)).
                        map(
                            severity =>
                                new WorkloadAnalysisWidgetItem(
                                    virtualMachineVulnerabilitySeverityToCountMap[severity],
                                    NumberFormatter.percentage(virtualMachineVulnerabilitySeverityToCountMap[severity] / virtualMachineCount),
                                    severity,
                                    localization.severity[severity](),
                                    CustomerConsoleAppUrlHelper.getWorkloadAnalysisVirtualMachinesRelativeUrl(true, severity))).
                        concatIf(
                            virtualMachineVulnerabilityStats.securedCount > 0,
                            new WorkloadAnalysisWidgetItem(
                                virtualMachineVulnerabilityStats.securedCount,
                                NumberFormatter.percentage(virtualMachineVulnerabilityStats.securedCount / virtualMachineCount),
                                "None",
                                localization.severity.none(),
                                undefined)).
                        value(),
                    virtualMachineCount];
            },
            [virtualMachineVulnerabilityStats]);

    const [breakpoint, setBreakpoint] = useState(0);
    const theme = useTheme();
    const [activeVulnerabilitySeverity, setActiveVulnerabilitySeverity] = useState<string>();
    return (
        virtualMachineCount === 0
            ? <EmptyMessage
                message={localization.empty()}
                verticalCenter={true}/>
            : <Fragment>
                <BreakpointResizer
                    breakpointXs={[breakpointX]}
                    onSize={setBreakpoint}/>
                <Stack
                    alignItems="center"
                    direction="row"
                    flexWrap="nowrap">
                    <Stack
                        alignItems="flex-start"
                        sx={{
                            flex: 1,
                            height: "100%",
                            overflow: "hidden",
                            paddingTop: theme.spacing(2)
                        }}>
                        {_(vulnerabilitySeverityStatusCardItems).
                            map(
                                vulnerabilitySeverityStatusCardItem =>
                                    <Box
                                        key={vulnerabilitySeverityStatusCardItem.severity}
                                        sx={{
                                            flex: 1,
                                            minWidth: theme.px(60),
                                            padding: theme.spacing(0, 1.5, 1.5, 0),
                                            width: "100%"
                                        }}
                                        onMouseEnter={() => setActiveVulnerabilitySeverity(vulnerabilitySeverityStatusCardItem.severity)}
                                        onMouseLeave={() => setActiveVulnerabilitySeverity(undefined)}>
                                        <WorkloadAnalysisItem
                                            highlight={vulnerabilitySeverityStatusCardItem.severity === activeVulnerabilitySeverity}
                                            item={vulnerabilitySeverityStatusCardItem}/>
                                    </Box>).
                            value()}
                    </Stack>
                    <Stack
                        alignItems="flex-end"
                        sx={{ paddingTop: theme.spacing(5) }}>
                        <DistributionChart
                            activeItemId={activeVulnerabilitySeverity}
                            items={
                                _(vulnerabilitySeverityStatusCardItems).
                                    map(
                                        item =>
                                            new DistributionChartItem(
                                                item.severity === "None"
                                                    ? "#40B99E"
                                                    : theme.palette.severity(item.severity),
                                                item.severity,
                                                <VulnerabilityDistributionItemTooltip item={item}/>,
                                                item.count,
                                                item.url)).
                                    value()}
                            size={
                                breakpoint === 0
                                    ? "medium"
                                    : "xl"}
                            subtitle={localization.chart.subtitle()}
                            title={NumberFormatter.humanize(virtualMachineCount)}
                            titleUrl={CustomerConsoleAppUrlHelper.getWorkloadAnalysisVirtualMachinesRelativeUrl(true)}
                            variant="donut"
                            onActiveItemIdChanged={severity => setActiveVulnerabilitySeverity(severity as Contract.Severity | "None")}/>
                    </Stack>
                </Stack>
            </Fragment>);
}

type ItemTooltipProps = {
    item: WorkloadAnalysisWidgetItem;
};

function VulnerabilityDistributionItemTooltip({ item }: ItemTooltipProps) {
    const localization =
        useLocalization(
            "common.dashboard.widget.hooks.useDefinition.hooks.useGetWorkloadAnalysisVulnerabilityStatusDefinition.vulnerabilityDistributionItemTooltip",
            () => ({
                tooltipSummary: "*bold*{{workloadCount}}** Machines ({{percentage}})"
            }));

    return (
        <Stack>
            <Typography>
                {localization.tooltipSummary({ percentage: item.percentage, workloadCount: item.count })}
            </Typography>
        </Stack>);
}

const breakpointX = 338;