﻿import { Optional, TimeSpanFormatter, TimeSpanHelper, useLocalization } from "@infrastructure";
import React from "react";
import { Numeric } from "..";
import { Contract, RiskPoliciesType } from "../../../../../../../../common";

type TimeFrameProps = {
    description: string;
    documentationUrl?: string;
    getTimeFrame: (riskPolicyConfiguration: Contract.RiskPolicyConfiguration) => Optional<string>;
    maxValue?: number;
    minValue?: number;
    riskPoliciesType: RiskPoliciesType;
    setTimeFrame: (riskPolicyConfiguration: Contract.RiskPolicyConfiguration, timeFrame: Optional<string>) => Optional<string>;
    title: string;
};

export function TimeFrame({ description, documentationUrl, getTimeFrame, maxValue = 365, minValue = 1, riskPoliciesType, setTimeFrame, title }: TimeFrameProps) {
    const localization =
        useLocalization(
            "views.customer.riskPolicies.hooks.useConfigurationDefinition.timeFrame",
            () => ({
                invalidTimeFrame: "The value should be between {{minValue}} and {{maxValue}} days",
                label: "Days"
            }));

    return (
        <Numeric
            description={description}
            documentationUrl={documentationUrl}
            fromNumber={TimeSpanHelper.fromDays}
            getValue={getTimeFrame}
            label={localization.label()}
            maxValue={maxValue}
            minValue={minValue}
            riskPoliciesType={riskPoliciesType}
            setValue={setTimeFrame}
            title={title}
            toNumber={TimeSpanHelper.getDays}
            translateValue={TimeSpanFormatter.day}
            valueOutOfRangeErrorMessage={localization.invalidTimeFrame({ maxValue, minValue })}/>);
}