import { map, useLocalizeList } from "@infrastructure";
import { Contract } from "../..";
import { useGetAwsSsoPermissionSetAssignmentEligibilityUpdateAuditEventChanges, useGetAzureRoleAssignmentEligibilityUpdateAuditEventChanges, useGetGcpRoleBindingEligibilityUpdateAuditEventChanges, useGetGroupMembershipEligibilityUpdateAuditEventChanges, useGetOneLoginDirectoryRoleAssignmentEligibilityUpdateAuditEventChanges } from "./hooks";

export function usePermissionEligibilityUpdateAuditEventChangesTranslator() {
    const localizeList = useLocalizeList();
    const getAwsSsoPermissionSetAssignmentEligibilityUpdateAuditEventChanges = useGetAwsSsoPermissionSetAssignmentEligibilityUpdateAuditEventChanges();
    const getAzureRoleAssignmentEligibilityUpdateAuditEventChanges = useGetAzureRoleAssignmentEligibilityUpdateAuditEventChanges();
    const getGcpRoleBindingEligibilityUpdateAuditEventChanges = useGetGcpRoleBindingEligibilityUpdateAuditEventChanges();
    const getGroupMembershipEligibilityUpdateAuditEventChanges = useGetGroupMembershipEligibilityUpdateAuditEventChanges();
    const getOneLoginDirectoryRoleAssignmentEligibilityUpdateAuditEventChanges = useGetOneLoginDirectoryRoleAssignmentEligibilityUpdateAuditEventChanges();

    return (permissionEligibilityUpdateAuditEvent: Contract.PermissionEligibilityUpdateAuditEvent) =>
        localizeList(
            map(
                permissionEligibilityUpdateAuditEvent.permissionEligibilityChanges.typeName,
                {
                    [Contract.TypeNames.PermissionEligibilityUpdateAuditEventGroupMembershipEligibilityChanges]: () => getGroupMembershipEligibilityUpdateAuditEventChanges(permissionEligibilityUpdateAuditEvent.permissionEligibilityChanges as Contract.PermissionEligibilityUpdateAuditEventGroupMembershipEligibilityChanges),
                    [Contract.TypeNames.PermissionEligibilityUpdateAuditEventAwsSsoPermissionSetAssignmentEligibilityChanges]: () => getAwsSsoPermissionSetAssignmentEligibilityUpdateAuditEventChanges(permissionEligibilityUpdateAuditEvent.permissionEligibilityChanges as Contract.PermissionEligibilityUpdateAuditEventAwsSsoPermissionSetAssignmentEligibilityChanges),
                    [Contract.TypeNames.PermissionEligibilityUpdateAuditEventAzureRoleAssignmentEligibilityChanges]: () => getAzureRoleAssignmentEligibilityUpdateAuditEventChanges(permissionEligibilityUpdateAuditEvent.permissionEligibilityChanges as Contract.PermissionEligibilityUpdateAuditEventAzureRoleAssignmentEligibilityChanges),
                    [Contract.TypeNames.PermissionEligibilityUpdateAuditEventGcpRoleBindingEligibilityChanges]: () => getGcpRoleBindingEligibilityUpdateAuditEventChanges(permissionEligibilityUpdateAuditEvent.permissionEligibilityChanges as Contract.PermissionEligibilityUpdateAuditEventGcpRoleBindingEligibilityChanges),
                    [Contract.TypeNames.PermissionEligibilityUpdateAuditEventOneLoginDirectoryRoleAssignmentEligibilityChanges]: () => getOneLoginDirectoryRoleAssignmentEligibilityUpdateAuditEventChanges(permissionEligibilityUpdateAuditEvent.permissionEligibilityChanges as Contract.PermissionEligibilityUpdateAuditEventOneLoginDirectoryRoleAssignmentEligibilityChanges)
                }));
}