﻿import { Dropdown, NumberFormatter, Shadows, StringHelper, useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { useMemo, useState } from "react";
import { Contract, CustomerConsoleAppUrlHelper, CustomerConsoleAppUrlHelperGetEntitiesRelativeUrlFiltersSelection, EntityTypeMetadataModelHelper, TenantsEntitiesView, useEntityTypeNameTranslator, useTheme } from "../../../../../../../../..";

type EntityCategoryProps = {
    category: Contract.ObjectTypeCategory;
    disabled?: boolean;
    entityRelativeUrlFilters?: Partial<CustomerConsoleAppUrlHelperGetEntitiesRelativeUrlFiltersSelection>;
    entityTypeEntitiesViewNameToCountMap: Dictionary<number>;
    resourceCount: number;
    title?: string;
};

export function EntityCategory({ category, disabled = false, entityRelativeUrlFilters, entityTypeEntitiesViewNameToCountMap, resourceCount, title }: EntityCategoryProps) {
    const [hover, setHover] = useState(false);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "common.dashboard.widget.hooks.useDefinition.entities.entityCategory",
            () => ({
                [Contract.TypeNames.ObjectTypeCategory]: {
                    [Contract.ObjectTypeCategory.Entity]: [
                        "Resource",
                        "Resources"
                    ],
                    [Contract.ObjectTypeCategory.EntityAi]: [
                        "AI resource",
                        "AI resources"
                    ],
                    [Contract.ObjectTypeCategory.EntityCompute]: [
                        "Compute resource",
                        "Compute resources"
                    ],
                    [Contract.ObjectTypeCategory.EntityContainers]: [
                        "Container resource",
                        "Container resources"
                    ],
                    [Contract.ObjectTypeCategory.EntityData]: [
                        "Data resource",
                        "Data resources"
                    ],
                    [Contract.ObjectTypeCategory.EntityIam]: [
                        "IAM resource",
                        "IAM resources"
                    ],
                    [Contract.ObjectTypeCategory.EntityIot]: [
                        "IoT resource",
                        "IoT resources"
                    ],
                    [Contract.ObjectTypeCategory.EntityKubernetes]: [
                        "Kubernetes resource",
                        "Kubernetes resources"
                    ],
                    [Contract.ObjectTypeCategory.EntityManagement]: [
                        "Management resource",
                        "Management resources"
                    ],
                    [Contract.ObjectTypeCategory.EntityNetwork]: [
                        "Network resource",
                        "Network resources"
                    ],
                    [Contract.ObjectTypeCategory.EntitySecurity]: [
                        "Security resource",
                        "Security resources"
                    ],
                    [Contract.ObjectTypeCategory.Risk]: [
                        "Finding",
                        "Findings"
                    ]
                }
            }));

    const tenantTypeToEntityDataMap =
        useMemo(
            () =>
                _(entityTypeEntitiesViewNameToCountMap).
                    map(
                        (entityCount, entityTypeEntitiesViewName) => ({
                            count: entityCount,
                            entitiesViewName: entityTypeEntitiesViewName,
                            link:
                                CustomerConsoleAppUrlHelper.getEntitiesRelativeUrl(
                                    entityTypeEntitiesViewName,
                                    entityRelativeUrlFilters),
                            title:
                                entityTypeNameTranslator(
                                    entityTypeEntitiesViewName,
                                    { count: 0 })
                        })).
                    groupBy(({ entitiesViewName }) => EntityTypeMetadataModelHelper.get(entitiesViewName).tenantType).
                    mapValues(
                        entityData =>
                            _(entityData).
                                orderBy(({ title }) => StringHelper.getSortValue(title)).
                                value()).
                    value(),
            [entityTypeEntitiesViewNameToCountMap]);

    const theme = useTheme();
    return (
        <Dropdown
            disabled={disabled}
            justify="center"
            popoverElement={<TenantsEntitiesView tenantTypeToEntityDatasMap={tenantTypeToEntityDataMap}/>}
            popoverElementContainerSx={{
                maxHeight: "fit-content",
                padding: 0
            }}
            sx={{ height: "100%" }}>
            <Stack
                alignItems="center"
                justifyContent="center"
                sx={{
                    background: theme.palette.background.alternate,
                    border: theme.border.alternate,
                    borderRadius: theme.spacing(0.75),
                    boxShadow:
                        hover
                            ? theme.shadows[Shadows.Highlight]
                            : undefined,
                    height: "100%",
                    padding: theme.spacing(2, 1.75)
                }}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}>
                <Typography
                    noWrap={true}
                    sx={{
                        fontSize: "28px",
                        fontWeight: 700,
                        lineHeight: "32px",
                        maxWidth: "100%"
                    }}>
                    {title ?? NumberFormatter.unit(resourceCount)}
                </Typography>
                <Typography
                    noWrap={true}
                    sx={{
                        fontSize: "13px",
                        lineHeight: "24px",
                        maxWidth: "100%"
                    }}>
                    {localization[Contract.TypeNames.ObjectTypeCategory][category](resourceCount)}
                </Typography>
            </Stack>
        </Dropdown>);
}