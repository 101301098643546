import { TimeFormatter, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, InfoCard, InfoItem, VerticalTopItemsInfoItem } from "../../../../../../../../../../common";
import { useAwsEmrClusterStatusTranslator } from "../../../../../../hooks";
import { EntitiesInfoItem, Info } from "../../../../components";
import { NetworkingInfoCard } from "../../components";
import { DefinitionOptions, EntityProfileDefinition } from "../../useDefinition";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";
import { useAwsCommonNetworkingInfoItemElements } from "./useAwsCommonNetworkingInfoItemElements";
import { useAwsDefaultResourceInfoItemElements } from "./useAwsDefaultResourceInfoItemElements";

export function useAwsEmrClusterDefinition(resourceModel: Contract.AwsResourceModel, options?: DefinitionOptions) {
    const cluster = resourceModel.entity as Contract.AwsEmrCluster;
    const clusterModel = resourceModel as Contract.AwsEmrClusterModel;
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useAwsDefaultResourceInfoItemElements(resourceModel);
    const commonNetworkCardInfoItems = useAwsCommonNetworkingInfoItemElements({ resourceModel });

    const emrClusterStatusTranslator = useAwsEmrClusterStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsEmrClusterDefinition",
            () => ({
                info: {
                    cards: {
                        instancesCard: {
                            title: "Nodes",
                            [Contract.TypeNames.AwsEmrClusterInstanceCollectionType]: {
                                [Contract.AwsEmrClusterInstanceCollectionType.Core]: "Core",
                                [Contract.AwsEmrClusterInstanceCollectionType.Master]: "Master",
                                [Contract.AwsEmrClusterInstanceCollectionType.Task]: "Task"
                            }
                        }
                    },
                    items: {
                        applications: "Applications",
                        autoScalingRole: "Auto Scaling Role",
                        autoTermination: {
                            false: "No",
                            title: "Automatic Termination",
                            true: "Yes"
                        },
                        dnsName: "DNS Name",
                        instanceRole: "EC2 Instance Profile",
                        keyName: "Key Pair",
                        logBucket: "Logs Bucket",
                        rawId: "ID",
                        releaseLabel: "Release Label",
                        securityConfigurationName: "Security Configuration",
                        serviceRole: "EMR Role",
                        status: "State",
                        terminationTime: "End Time",
                        vpc: "VPC"
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="rawId"
                        title={localization.info.items.rawId()}
                        value={cluster.rawId}/>,
                    <InfoItem
                        key="terminationTime"
                        title={localization.info.items.terminationTime()}
                        value={
                            _.isNil(cluster.terminationTime)
                                ? undefined
                                : TimeFormatter.profileFormatDateTime(cluster.terminationTime)}/>,
                    <InfoItem
                        key="status"
                        location="all"
                        title={localization.info.items.status()}
                        value={emrClusterStatusTranslator(cluster.status)}/>,
                    <InfoItem
                        key="releaseLabel"
                        title={localization.info.items.releaseLabel()}
                        value={cluster.releaseLabel}/>,
                    <VerticalTopItemsInfoItem
                        items={
                            _.map(
                                cluster.applications,
                                application => application.name)}
                        key="applications"
                        title={localization.info.items.applications()}/>,
                    <InfoItem
                        key="autoTermination"
                        title={localization.info.items.autoTermination.title()}
                        value={
                            cluster.autoTermination
                                ? localization.info.items.autoTermination.true()
                                : localization.info.items.autoTermination.false()}/>,
                    <InfoItem
                        key="dnsName"
                        title={localization.info.items.dnsName()}
                        value={cluster.dnsName}/>,
                    <InfoItem
                        key="securityConfigurationName"
                        title={localization.info.items.securityConfigurationName()}
                        value={cluster.securityConfigurationName}/>,
                    <InfoItem
                        key="keyName"
                        title={localization.info.items.keyName()}
                        value={cluster.keyName}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={cluster?.instanceRoleReference?.idReference}
                        entityTypeName={Contract.TypeNames.AwsIamRole}
                        key="instanceRoleReference"
                        title={localization.info.items.instanceRole()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={clusterModel.serviceRoleReference}
                        entityTypeName={Contract.TypeNames.AwsIamRole}
                        key="serviceRoleReference"
                        title={localization.info.items.serviceRole()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={clusterModel.autoScalingRoleReference}
                        entityTypeName={Contract.TypeNames.AwsIamRole}
                        key="autoScalingRoleReference"
                        title={localization.info.items.autoScalingRole()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={clusterModel.logsBucketReference}
                        entityTypeName={Contract.TypeNames.AwsIamRole}
                        key="logsBucketReference"
                        title={localization.info.items.logBucket()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={cluster.vpcIds}
                        entityTypeName={Contract.TypeNames.AwsIamRole}
                        key="vpc"
                        location="miniGlance"
                        title={localization.info.items.vpc()}/>
                ]}
                options={options?.infoOptions}>
                <NetworkingInfoCard>
                    {commonNetworkCardInfoItems.inboundAccessType}
                    {commonNetworkCardInfoItems.inboundExternalAccessScope}
                    {commonNetworkCardInfoItems.getVpcs()}
                    {commonNetworkCardInfoItems.getSubnets()}
                    {commonNetworkCardInfoItems.getSecurityGroups()}
                </NetworkingInfoCard>
                <InfoCard title={localization.info.cards.instancesCard.title()}>
                    {_(
                        [
                            Contract.AwsEmrClusterInstanceCollectionType.Master,
                            Contract.AwsEmrClusterInstanceCollectionType.Core,
                            Contract.AwsEmrClusterInstanceCollectionType.Task
                        ]).
                        map(
                            instanceCollectionType =>
                                _.has(cluster.instanceCollectionTypeToInstanceReferencesMap, instanceCollectionType)
                                    ? <EntitiesInfoItem
                                        entityIdsOrModels={
                                            _.map(
                                                cluster.instanceCollectionTypeToInstanceReferencesMap[instanceCollectionType],
                                                instanceReferences => instanceReferences.idReference)}
                                        entityTypeName={Contract.TypeNames.AwsEc2Instance}
                                        key={instanceCollectionType}
                                        title={localization.info.cards.instancesCard[Contract.TypeNames.AwsEmrClusterInstanceCollectionType][instanceCollectionType as Exclude<Contract.AwsEmrClusterInstanceCollectionType, Contract.AwsEmrClusterInstanceCollectionType.Unknown>]()}/>
                                    : undefined).
                        concat(
                            _.has(cluster.instanceCollectionTypeToInstanceReferencesMap, Contract.AwsEmrClusterInstanceCollectionType.Unknown)
                                ? <EntitiesInfoItem
                                    entityIdsOrModels={
                                        _.map(
                                            cluster.instanceCollectionTypeToInstanceReferencesMap[Contract.AwsEmrClusterInstanceCollectionType.Unknown],
                                            instanceReferences => instanceReferences.idReference)}
                                    entityTypeName={Contract.TypeNames.AwsEc2Instance}/>
                                : undefined).
                        filter().
                        value()}
                </InfoCard>
            </Info>
    });
}