export function createAdoptedStyleSheet() {
    const styleSheet = new CSSStyleSheet();
    document.adoptedStyleSheets.push(styleSheet);

    const index = document.adoptedStyleSheets.length - 1;
    function removeStyleSheet() {
        document.adoptedStyleSheets.splice(index, 1);
    }

    return [styleSheet, removeStyleSheet] as const;
}