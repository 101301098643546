import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition, useGetAwsSecretsManagerSecretRiskContext } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { RiskDefinitionSection } from "../../../../../../utilities";
import { PolicySection } from "../../components";

export function useAwsSecretsManagerSecretPublicAccessExistsRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsSecretsManagerSecretPublicAccessExistsRisk;
    const secretModel = entityModelStore.useGet(risk.entityId) as Contract.AwsSecretsManagerSecretModel;
    const secret = secretModel.entity as Contract.AwsSecretsManagerSecret;

    const getAwsSecretsManagerSecretRiskContext = useGetAwsSecretsManagerSecretRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsSecretsManagerSecretPublicAccessExistsRiskDefinition",
            () => ({
                description: "{{secret}} is publicly accessible via resource policy",
                sections: {
                    policy: "Policy",
                    resolution: {
                        step1: "Disabling public access might break identities using the secret. Before changing the policy, verify that identities using the secret can access it without public access",
                        step2: "Click **Edit permissions** and change the policy statements that allows access to any AWS principal",
                        step3: "Click **Save** to confirm"
                    }
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            secret:
                <Entity
                    entityIdOrModel={secretModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.SecretsManager,
                AwsConsoleUrlBuilder.getSecretsManagerSecretUrl(secret)),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3()
        ],
        riskModel,
        () => {
            const secretRiskContext = getAwsSecretsManagerSecretRiskContext(secretModel);

            return [
                secretRiskContext.generalInformation,
                secretRiskContext.sensitive,
                secretRiskContext.encryption,
                secretRiskContext.rotationTime,
                secretRiskContext.usage,
                secretRiskContext.publicAccessPermissionActions,
                secretRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        },
        {
            sections: [
                new RiskDefinitionSection(
                    <PolicySection policyDocument={secret.policyDocument}/>,
                    localization.sections.policy(),
                    {
                        expandable: true
                    })
            ]
        });
}