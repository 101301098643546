import { TimeFormatter, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../..";
import { useGcpCommonEncryptedResourceInfoElements, useGcpDefaultResourceInfoItemElements, useGcpDefaultScopeResourceInfoItemElements } from "..";
import { Contract, InfoItem } from "../../../../../../../../../../../common";
import { EntitiesInfoItem, Info } from "../../../../../components";
import { GcpScopeResourceRoleBindings } from "../../../components";
import { useCustomEntityPropertyInfoItemElements } from "../../useCustomEntityPropertyInfoItemElements";
import { Versions } from "./components";

export function useGcpSecretManagerSecretDefinition(scopeResourceModel: Contract.GcpScopeResourceModel, options?: DefinitionOptions) {
    const commonEncryptedResourceInfoElements = useGcpCommonEncryptedResourceInfoElements(scopeResourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(scopeResourceModel);
    const defaultResourceInfoItemElements = useGcpDefaultResourceInfoItemElements(scopeResourceModel);
    const defaultScopeResourceInfoItemElements = useGcpDefaultScopeResourceInfoItemElements(scopeResourceModel);
    const secretModel = scopeResourceModel as Contract.GcpSecretManagerSecretModel;
    const secret = secretModel.entity as Contract.GcpSecretManagerSecret;

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpSecretManagerSecretDefinition",
            () => ({
                info: {
                    items: {
                        expirationTime: {
                            empty: "Never",
                            title: "Expiration Time"
                        },
                        nextRotationTime: "Next rotation time",
                        notificationTopicEntityIdReferences: "Notifications Pub/Sub topic",
                        replicationType: {
                            title: "Replication type",
                            [Contract.TypeNames.GcpSecretManagerSecretReplicationType]: {
                                [Contract.GcpSecretManagerSecretReplicationType.Automatic]: "Automatic",
                                [Contract.GcpSecretManagerSecretReplicationType.UserManaged]: "User Managed"
                            }
                        },
                        rotationTimeFrame: {
                            empty: "Disabled",
                            text: "Every {{rotationTimeFrame | TimeSpanFormatter.humanizeDays}}",
                            title: "Rotation period"
                        },
                        versionIds: "Versions"
                    }
                },
                tabs: {
                    resourceRoleBindings: "Resource Role Bindings",
                    versions: "Versions"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <GcpScopeResourceRoleBindings
                    csvExportFilePrefix={localization.tabs.resourceRoleBindings()}
                    scopeResourceModel={scopeResourceModel}/>,
                localization.tabs.resourceRoleBindings(),
                "resourceRoleBindings"),
            new EntityProfileDefinitionTab(
                ProfileCategory.Overview,
                <Versions versionIds={secretModel.versionIds}/>,
                localization.tabs.versions(),
                "versions")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    ...defaultScopeResourceInfoItemElements,
                    commonEncryptedResourceInfoElements.encryptionExists,
                    commonEncryptedResourceInfoElements.kmsEncryptionKeys,
                    <InfoItem
                        key="rotationTimeFrame"
                        location="all"
                        title={localization.info.items.rotationTimeFrame.title()}
                        value={
                            _.isNil(secret.rotationTimeFrame)
                                ? localization.info.items.rotationTimeFrame.empty()
                                : localization.info.items.rotationTimeFrame.text({ rotationTimeFrame: secret.rotationTimeFrame })}/>,
                    <InfoItem
                        key="nextRotationTime"
                        title={localization.info.items.nextRotationTime()}
                        value={
                            _.isNil(secret.rotationTimeFrame) || _.isNil(secret.nextRotationTime)
                                ? undefined
                                : TimeFormatter.profileFormatDateTime(secret.nextRotationTime)}/>,
                    <InfoItem
                        key="expirationTime"
                        location="all"
                        title={localization.info.items.expirationTime.title()}
                        value={
                            _.isNil(secret.expirationTime)
                                ? localization.info.items.expirationTime.empty()
                                : TimeFormatter.profileFormatDateTime(secret.expirationTime)}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={secretModel.notificationTopicIdReferences}
                        entityTypeName={Contract.TypeNames.GcpPubSubTopicsGenericResource}
                        key="notificationTopicEntityIdReferences"
                        title={localization.info.items.notificationTopicEntityIdReferences()}/>,
                    <InfoItem
                        key="replicationType"
                        title={localization.info.items.replicationType.title()}
                        value={localization.info.items.replicationType[Contract.TypeNames.GcpSecretManagerSecretReplicationType][secret.replicationType]()}/>
                ]}
                options={options?.infoOptions}>
            </Info>
    });
}