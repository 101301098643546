import _ from "lodash";
import { Contract } from "../../../../../common";

export class KubernetesResourceDataHelper {
    public static entitySensitiveToggle(data: Contract.KubernetesResourceData) {
        return !_.includes(
            [
                Contract.TypeNames.KubernetesClusterRoleBindingData,
                Contract.TypeNames.KubernetesClusterRoleData,
                Contract.TypeNames.KubernetesGroupData,
                Contract.TypeNames.KubernetesNamespaceRoleBindingData,
                Contract.TypeNames.KubernetesNamespaceRoleData,
                Contract.TypeNames.KubernetesServiceAccountData,
                Contract.TypeNames.KubernetesUserData
            ],
            data.typeName);
    }
}