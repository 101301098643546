import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, EnumValuesFilter, useLocalization } from "@infrastructure";
import { Dictionary } from "lodash";
import React from "react";
import { useOciCommonResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { useOciContainerEngineClusterStatusTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";

export function useOciContainerEngineClusterDefinition(definitionData: DefinitionData) {
    const commonResourceDefinition = useOciCommonResourceDefinition(definitionData);

    const entitiesExportOptions = useEntitiesExportOptions();

    const clusterStatusTranslator = useOciContainerEngineClusterStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.oci.useOciContainerEngineClusterDefinition",
            () => ({
                columns: {
                    nodePools: "Node Pools",
                    status: "Status"
                }
            }));

    return new EntityTableDefinition(
        [
            commonResourceDefinition.columns.tenantColumn,
            commonResourceDefinition.columns.creationTimeColumn,
            commonResourceDefinition.columns.creatorCsvColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.OciContainerEngineClusterModel) => ({
                            [localization.columns.status()]: clusterStatusTranslator((item.entity as Contract.OciContainerEngineCluster).status)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.OciContainerEngineClusterStatus}
                                enumTypeTranslator={clusterStatusTranslator}
                                placeholder={localization.columns.status()}/>
                    }
                }}
                id={Contract.EntityModelProperty.OciContainerEngineClusterStatus}
                itemProperty={(item: Contract.OciContainerEngineClusterModel) => clusterStatusTranslator((item.entity as Contract.OciContainerEngineCluster).status)}
                key={Contract.EntityModelProperty.OciContainerEngineClusterStatus}
                title={localization.columns.status()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.OciContainerEngineClusterModel>(
                        Contract.TypeNames.OciContainerEngineClusterNodePool,
                        item => item.nodePoolIds,
                        localization.columns.nodePools())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityTypeFilter(
                                        Contract.TypeNames.OciContainerEngineClusterNodePool,
                                        true)}
                                placeholder={localization.columns.nodePools()}/>
                    }
                }}
                id={Contract.EntityModelProperty.OciContainerEngineClusterNodePools}
                key={Contract.EntityModelProperty.OciContainerEngineClusterNodePools}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.OciContainerEngineClusterModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.nodePoolIds}
                            entityTypeName={Contract.TypeNames.OciContainerEngineClusterNodePool}
                            entityVariant="iconText"/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.nodePools()}/>,
            commonResourceDefinition.columns.regionColumn,
            commonResourceDefinition.columns.regionLocationColumn,
            commonResourceDefinition.columns.tagsColumn,
            commonResourceDefinition.columns.attributesColumn,
            commonResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageOciContainerEngineClusterRequest(
                new Contract.EntityControllerGetEntityModelPageOciContainerEngineClusterRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciContainerEngineClusterNodePools]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciContainerEngineClusterStatus])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags]))));
}