import { DataTable, DataTableActions, DataTableColumn, DataTableColumnRenderProps, DataTableFetchItemsResult, DataTableSort, DataTableSortDirection, DataTableSortType, DeferredFilter, EmptyMessageText, InlineItems, Optional, optionalTableCell, TimeCell, TimeFormatter, TimeRangeFilter, TimeSpanHelper, useLocalization, useOperation, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import { Stack } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { useMemo, useRef, useState } from "react";
import { AuditEventController, Contract, ElasticsearchItemPageHelper, EntitiesCell, Entity, entityModelStore, ItemSelectionHelper, PagedEntityFilter, ScopeHelper, scopeNodeModelStore, StorageHelper, TenantFilter, TenantIcon, TimeRangeHelper } from "../../../../../../common";
import { ActionsCell, TypeNameCell } from "./components";
import { usePermissionRequestAuditEventTypeNameTranslator } from "./hooks";

export function PermissionRequestAuditEvents() {
    const [filtersPromise] =
        useOperation(
            PermissionRequestAuditEvents,
            async () => {
                const { filters } = await AuditEventController.getAuditEventFilters(new Contract.AuditEventControllerGetPermissionRequestAuditEventFiltersRequest());
                return filters as Contract.PermissionRequestAuditEventFilters;
            });

    const dataTableActionsRef = useRef<DataTableActions>();

    const localization =
        useLocalization(
            "views.user.auditEvents.permissionRequestAuditEvents",
            () => ({
                actions: {
                    csvExport: {
                        fileNamePrefix: "AccessRequestAuditLog"
                    }
                },
                columns: {
                    approvalRequired: {
                        false: "No",
                        title: "Requires Approval",
                        true: "Yes"
                    },
                    approverUserIdReferences: "Approvers",
                    expirationTimeFrame: {
                        text: [
                            "1 hour",
                            "{{count | NumberFormatter.humanize}} hours"
                        ],
                        title: "Duration"
                    },
                    granteeUserIdReference: "Requester",
                    granteeUserTenantId: "Organization",
                    permissionEligibilityName: "Eligibility",
                    reason: "Justification",
                    startTime: "Scheduled Time",
                    systemCreationTime: "Time",
                    tenants: {
                        pluralizer: [
                            "1 Account",
                            "{{count | NumberFormatter.humanize}} Accounts"
                        ],
                        text: "{{tenantName}} ({{tenantDisplayReference}})",
                        title: "Accounts"
                    },
                    typeName: "Action"
                },
                empty: {
                    withFilter: "No Matching Audit Events",
                    withoutFilter: "No Audit Events"
                }
            }));

    const scopeNodeModels = scopeNodeModelStore.useGetAll();
    const scopeNodeModelMap =
        useMemo(
            () =>
                _.keyBy(
                    scopeNodeModels,
                    scopeNodeModel => scopeNodeModel.configuration.id),
            [scopeNodeModels]);

    const fetchAuditEventModelNextPageSearchCursorRef = useRef<Contract.ElasticsearchIndexSearchCursor>();
    const [auditEventModelCount, setAuditEventModelCount] = useState(0);
    const fetchAuditEventModels =
        async (filterMap: Dictionary<any>, sort: Optional<DataTableSort>, skip: number, limit: number) => {
            const { auditEventModelPage } =
                await AuditEventController.getAuditEventModelPage(
                    new Contract.AuditEventControllerGetPermissionRequestAuditEventModelPageRequest(
                        limit,
                        skip === 0
                            ? undefined
                            : fetchAuditEventModelNextPageSearchCursorRef.current,
                        new Contract.AuditEventControllerGetPermissionRequestAuditEventModelPageRequestFilters(
                            ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[TableColumnId.ApprovalRequired]),
                            ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[TableColumnId.ApproverUserIdReferences]),
                            ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[TableColumnId.GranteeUserIdReference]),
                            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[TableColumnId.GranteeUserTenantId]),
                            filterMap[TableColumnId.Ids],
                            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[TableColumnId.PermissionAssignmentRequestTenants]),
                            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[TableColumnId.PermissionEligibilityName]),
                            TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(dataTableActionsRef.current?.getFiltersTime(), filterMap[TableColumnId.SystemCreationTime]),
                            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[TableColumnId.TypeName])),
                        new Contract.AuditEventControllerGetPermissionRequestAuditEventModelPageRequestSort(
                            _.isNil(sort) || sort.direction === DataTableSortDirection.Descending
                                ? Contract.SortDirection.Descending
                                : Contract.SortDirection.Ascending,
                            Contract.AuditEventControllerGetAuditEventModelPageRequestProperty.SystemCreationTime)));

            return new DataTableFetchItemsResult(
                { count: auditEventModelPage.count ?? auditEventModelCount },
                auditEventModelPage.items,
                _.isNil(auditEventModelPage.itemNextPageSearchCursor),
                {
                    onAppendData:
                        () => {
                            if (!_.isNil(auditEventModelPage.count)) {
                                setAuditEventModelCount(auditEventModelPage.count);
                            }
                            fetchAuditEventModelNextPageSearchCursorRef.current = auditEventModelPage.itemNextPageSearchCursor;
                        }
                });
        };

    const sortRef = useRef<DataTableSort>();

    async function getExportData(itemIdToExportColumnElementIdToExportDataMap: Dictionary<Dictionary<ColumnExportData>>): Promise<ExportData> {
        const columnExportDatas =
            _(itemIdToExportColumnElementIdToExportDataMap).
                values().
                flatMap(exportColumnElementIdToExportDataMap => _.values(exportColumnElementIdToExportDataMap)).
                value();

        const relatedEntityModels =
            await entityModelStore.get(
                _(columnExportDatas).
                    flatMap(data => data.entityIds!).
                    filter().
                    uniq().
                    value());
        const relatedEntityModelMap =
            _.keyBy(
                relatedEntityModels,
                relatedEntityModel => relatedEntityModel.id);

        return { relatedEntityModelMap };
    }

    const permissionRequestAuditEventTypeNameTranslator = usePermissionRequestAuditEventTypeNameTranslator();
    return (
        <DataTable
            actionsRef={dataTableActionsRef}
            columnOptions={{ resizable: true }}
            emptyMessageOptions={{
                emptyMessageText:
                    new EmptyMessageText(
                        localization.empty.withoutFilter(),
                        localization.empty.withFilter())
            }}
            exportOptions={{
                fileNamePrefix: localization.actions.csvExport.fileNamePrefix(),
                getData: getExportData
            }}
            fetchItems={fetchAuditEventModels}
            filtersOptions={{
                persist: {
                    visibilityStorageItem: StorageHelper.userAuditEventsPermissionRequestTableFilters
                }
            }}
            getItemId={(item: Contract.PermissionRequestAuditEventModel) => item.auditEvent.id}
            pageSize={30}
            onSortChanged={sort => sortRef.current = sort}>
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.PermissionRequestAuditEventModel) => ({
                            [localization.columns.systemCreationTime()]: TimeFormatter.sortableDateTime(item.auditEvent.systemCreationTime)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <DeferredFilter
                                promiseOrGetPromise={filtersPromise}
                                title={localization.columns.systemCreationTime()}>
                                {filters =>
                                    <TimeRangeFilter
                                        emptyValue={filters.systemCreationTimeRange.emptyValue}
                                        placeholder={localization.columns.systemCreationTime()}
                                        timeRange={TimeRangeHelper.getTimeRangeFilterRange(filters.systemCreationTimeRange.timeRange)}/>}
                            </DeferredFilter>
                    }
                }}
                id={TableColumnId.SystemCreationTime}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.PermissionRequestAuditEventModel>) =>
                        <TimeCell
                            short={false}
                            time={item.auditEvent.systemCreationTime}/>}
                sortOptions={{ type: DataTableSortType.Date }}
                title={localization.columns.systemCreationTime()}/>
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.PermissionRequestAuditEventModel) => ({
                            [localization.columns.typeName()]: permissionRequestAuditEventTypeNameTranslator(item.auditEvent.typeName)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <DeferredFilter
                                promiseOrGetPromise={filtersPromise}
                                title={localization.columns.typeName()}>
                                {filters =>
                                    <ValuesFilter
                                        emptyValueOptions={{ enabled: filters.typeNameItems.emptyValue }}
                                        placeholder={localization.columns.typeName()}>
                                        {_.map(
                                            filters.typeNameItems.items,
                                            typeName =>
                                                <ValuesFilterItem
                                                    key={typeName}
                                                    title={permissionRequestAuditEventTypeNameTranslator(typeName)}
                                                    value={typeName}/>)}
                                    </ValuesFilter>}
                            </DeferredFilter>
                    }
                }}
                id={TableColumnId.TypeName}
                key={TableColumnId.TypeName}
                render={TypeNameCell}
                sortOptions={{ enabled: false }}
                title={localization.columns.typeName()}/>
            <DataTableColumn
                exportOptions={{
                    getData: (item: Contract.PermissionRequestAuditEventModel) => ({ entityIds: [item.permissionRequestData.granteeUserIdReference] }),
                    getItem:
                        (item: Contract.PermissionRequestAuditEventModel, exportData: ExportData) => ({
                            [localization.columns.granteeUserIdReference()]: exportData.relatedEntityModelMap[item.permissionRequestData.granteeUserIdReference].entity.displayReference
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        default: true,
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedPermissionAuditEventFilterEntityItem(
                                        Contract.TypeNames.PermissionRequestAuditEvent,
                                        Contract.AuditEventControllerGetAuditEventModelPageRequestProperty.PermissionRequestAuditEventGranteeUser)}
                                placeholder={localization.columns.granteeUserIdReference()}/>
                    }
                }}
                id={TableColumnId.GranteeUserIdReference}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.PermissionRequestAuditEventModel>) =>
                        <Entity
                            entityIdOrModel={item.permissionRequestData.granteeUserIdReference}
                            linkOptions={{ disabled: true }}
                            variant="iconText"/>}
                sortOptions={{ enabled: false }}
                title={localization.columns.granteeUserIdReference()}/>
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.PermissionRequestAuditEventModel) => ({
                            [localization.columns.permissionEligibilityName()]: item.auditEvent.permissionRequestData.permissionEligibilityName
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <DeferredFilter
                                promiseOrGetPromise={filtersPromise}
                                title={localization.columns.permissionEligibilityName()}>
                                {filters =>
                                    <ValuesFilter
                                        emptyValueOptions={{ enabled: filters.permissionEligibilityNameItems.emptyValue }}
                                        placeholder={localization.columns.permissionEligibilityName()}>
                                        {_.map(
                                            filters.permissionEligibilityNameItems.items,
                                            permissionEligibilityName =>
                                                <ValuesFilterItem
                                                    key={permissionEligibilityName}
                                                    title={permissionEligibilityName}
                                                    value={permissionEligibilityName}/>)}
                                    </ValuesFilter>}
                            </DeferredFilter>
                    }
                }}
                id={TableColumnId.PermissionEligibilityName}
                itemProperty={(item: Contract.PermissionRequestAuditEventModel) => item.auditEvent.permissionRequestData.permissionEligibilityName}
                sortOptions={{ enabled: false }}
                title={localization.columns.permissionEligibilityName()}/>
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.PermissionRequestAuditEventModel) => ({
                            [localization.columns.tenants.title()]:
                                item.auditEvent.permissionRequestData.typeName === Contract.TypeNames.PermissionRequestAuditEventPermissionAssignmentRequestData
                                    ? localization.columns.tenants.pluralizer(_.size((item.auditEvent.permissionRequestData as Contract.PermissionRequestAuditEventPermissionAssignmentRequestData).tenantDisplayReferenceToNameMap))
                                    : ""
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        default: true,
                        element:
                            <DeferredFilter
                                promiseOrGetPromise={filtersPromise}
                                title={localization.columns.tenants.title()}>
                                {filters =>
                                    <ValuesFilter
                                        emptyValueOptions={{ enabled: filters.tenantDataItems.emptyValue }}
                                        placeholder={localization.columns.tenants.title()}>
                                        {_.map(
                                            filters.tenantDataItems.items,
                                            ({ tenantDisplayReference, tenantName }) =>
                                                <ValuesFilterItem
                                                    key={tenantDisplayReference}
                                                    title={localization.columns.tenants.text({ tenantDisplayReference, tenantName })}
                                                    value={tenantDisplayReference}/>)}
                                    </ValuesFilter>}
                            </DeferredFilter>
                    }
                }}
                id={TableColumnId.PermissionAssignmentRequestTenants}
                render={
                    optionalTableCell<Contract.PermissionRequestAuditEventModel>(
                        item =>
                            item.auditEvent.permissionRequestData.typeName === Contract.TypeNames.PermissionRequestAuditEventPermissionAssignmentRequestData
                                ? <Stack
                                    direction="row"
                                    spacing={1}>
                                    <TenantIcon
                                        sx={{ fontSize: "24px" }}
                                        tenantType={ScopeHelper.getTenantType(scopeNodeModelMap[item.auditEvent.scopeId])!}/>
                                    <InlineItems
                                        items={_.toPairs((item.auditEvent.permissionRequestData as Contract.PermissionRequestAuditEventPermissionAssignmentRequestData).tenantDisplayReferenceToNameMap)}
                                        namePluralizer={localization.columns.tenants.pluralizer}
                                        variant="itemCountAndType">
                                        {([tenantDisplayReference, tenantName]) => localization.columns.tenants.text({ tenantDisplayReference, tenantName })}
                                    </InlineItems>
                                </Stack>
                                : undefined)}
                sortOptions={{ enabled: false }}
                title={localization.columns.tenants.title()}/>
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.PermissionRequestAuditEventModel) => ({
                            [localization.columns.expirationTimeFrame.title()]: localization.columns.expirationTimeFrame.text(TimeSpanHelper.getHours(item.auditEvent.permissionRequestData.expirationTimeFrame))
                        })
                }}
                id={TableColumnId.ExpirationTimeFrame}
                itemProperty={(item: Contract.PermissionRequestAuditEventModel) => localization.columns.expirationTimeFrame.text(TimeSpanHelper.getHours(item.auditEvent.permissionRequestData.expirationTimeFrame))}
                sortOptions={{ enabled: false }}
                title={localization.columns.expirationTimeFrame.title()}/>
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.PermissionRequestAuditEventModel) => ({
                            [localization.columns.reason()]: item.auditEvent.permissionRequestData.reason
                        })
                }}
                id={TableColumnId.Reason}
                key={TableColumnId.Reason}
                render={
                    optionalTableCell<Contract.PermissionRequestAuditEventModel>(
                        item =>
                            _.isEmpty(item.auditEvent.permissionRequestData.reason)
                                ? undefined
                                : item.auditEvent.permissionRequestData.reason)}
                sortOptions={{ enabled: false }}
                title={localization.columns.reason()}/>
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.PermissionRequestAuditEventModel) => ({
                            [localization.columns.approvalRequired.title()]:
                                item.auditEvent.permissionRequestData.approvalRequired
                                    ? localization.columns.approvalRequired.true()
                                    : localization.columns.approvalRequired.false()
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        default: true,
                        element:
                            <ValuesFilter placeholder={localization.columns.approvalRequired.title()}>
                                <ValuesFilterItem
                                    key="false"
                                    title={localization.columns.approvalRequired.false()}
                                    value={false}/>
                                <ValuesFilterItem
                                    key="true"
                                    title={localization.columns.approvalRequired.true()}
                                    value={true}/>
                            </ValuesFilter>
                    }
                }}
                id={TableColumnId.ApprovalRequired}
                itemProperty={
                    (item: Contract.PermissionRequestAuditEventModel) =>
                        item.auditEvent.permissionRequestData.approvalRequired
                            ? localization.columns.approvalRequired.true()
                            : localization.columns.approvalRequired.false()}
                sortOptions={{ enabled: false }}
                title={localization.columns.approvalRequired.title()}/>
            <DataTableColumn
                exportOptions={{
                    getData: (item: Contract.PermissionRequestAuditEventModel) => ({ entityIds: item.approverUserIdReferences }),
                    getItem:
                        (item: Contract.PermissionRequestAuditEventModel, exportData: ExportData) => ({
                            [localization.columns.approverUserIdReferences()]:
                                _(item.approverUserIdReferences).
                                    map(approverUserIdReference => exportData.relatedEntityModelMap[approverUserIdReference].entity.displayReference).
                                    join("\n")
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedPermissionAuditEventFilterEntityItem(
                                        Contract.TypeNames.PermissionRequestAuditEvent,
                                        Contract.AuditEventControllerGetAuditEventModelPageRequestProperty.PermissionRequestAuditEventApproverUsers)}
                                placeholder={localization.columns.approverUserIdReferences()}/>
                    }
                }}
                id={TableColumnId.ApproverUserIdReferences}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.PermissionRequestAuditEventModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.approverUserIdReferences}
                            entityLinkOptions={{ disabled: true }}
                            entityTypeName={Contract.TypeNames.IPermissionManagementUser}
                            entityVariant="iconText"/>}
                sortOptions={{ enabled: false }}
                title={localization.columns.approverUserIdReferences()}/>
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        element:
                            <DeferredFilter
                                promiseOrGetPromise={filtersPromise}
                                title={localization.columns.granteeUserTenantId()}>
                                {filters =>
                                    <TenantFilter
                                        placeholder={localization.columns.granteeUserTenantId()}
                                        tenantIds={filters.granteeUserTenantIdItems.items}
                                        variant="iconText"/>}
                            </DeferredFilter>
                    }
                }}
                id={TableColumnId.GranteeUserTenantId}
                title={localization.columns.granteeUserTenantId()}/>
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.PermissionRequestAuditEventModel) => {
                            const startTime = item.auditEvent.permissionRequestData.startTime;
                            return ({
                                [localization.columns.startTime()]:
                                    _.isNil(startTime)
                                        ? ""
                                        : TimeFormatter.longDateTime(startTime)
                            });
                        }
                }}
                id={TableColumnId.StartTime}
                render={
                    optionalTableCell<Contract.PermissionRequestAuditEventModel>(
                        item => {
                            const startTime = item.auditEvent.permissionRequestData.startTime;
                            return _.isNil(startTime)
                                ? undefined
                                : TimeFormatter.longDateTime(startTime);
                        })}
                sortOptions={{ enabled: false }}
                title={localization.columns.startTime()}/>
            <DataTableColumn
                id={TableColumnId.Actions}
                render={ActionsCell}/>
        </DataTable>);
}

type ColumnExportData = {
    entityIds?: string[];
};

enum TableColumnId {
    Actions = "actions",
    ApprovalRequired = "approvalRequired",
    ApproverUserIdReferences = "approverUserIdReferences",
    ExpirationTimeFrame = "expirationTimeFrame",
    GranteeUserIdReference = "granteeUserIdReference",
    GranteeUserTenantId = "granteeUserTenantId",
    Ids = "ids",
    PermissionAssignmentRequestTenants = "permissionAssignmentRequestTenants",
    PermissionEligibilityName = "permissionEligibilityName",
    Reason = "reason",
    StartTime = "startTime",
    SystemCreationTime = "systemCreationTime",
    TypeName = "typeName"
}

type ExportData = {
    relatedEntityModelMap: Dictionary<Contract.EntityModel>;
};