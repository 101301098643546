﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, Entity, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetAwsResourceRiskContext } from "./useGetAwsResourceRiskContext";

export function useGetAwsCloudTrailTrailRiskContext() {
    return useMemo(
        () => useAwsCloudTrailTrailRiskContext,
        []);
}

function useAwsCloudTrailTrailRiskContext(trailModel: Contract.AwsCloudTrailTrailModel) {
    const trail = trailModel.entity as Contract.AwsCloudTrailTrail;
    const resourceRiskContext = useGetAwsResourceRiskContext()(trailModel);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.contexts.useGetAwsCloudTrailTrailRiskContext",
            () => ({
                bucketName: "The {{translatedTrailTypeName}} logs are stored in S3 bucket {{bucket}}",
                enabled: {
                    false: "The {{translatedTrailTypeName}} logging is **Off**",
                    true: "The {{translatedTrailTypeName}} logging is **On**"
                },
                encryption: {
                    kms: "encrypted using KMS key {{key}}",
                    none: "not encrypted",
                    text: "The {{translatedTrailTypeName}} logs are {{encryption}}"
                },
                logGroupDeliveryTime: "The {{translatedTrailTypeName}} last log file was delivered to CloudWatch at {{logGroupDeliveryTime | TimeFormatter.shortDate}}",
                multiRegion: {
                    multiRegional: "multi regional",
                    notMultiRegional: "not multi regional",
                    text: "The {{translatedTrailTypeName}} is {{multiRegional}}"
                },
                organizationTrail: {
                    false: "The {{translatedTrailTypeName}} is not used as an organization trail",
                    true: "The {{translatedTrailTypeName}} is used as an organization trail"
                }
            }));

    const translatedTrailTypeName =
        entityTypeNameTranslator(
            trail.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });

    return {
        ...resourceRiskContext,
        bucketName:
            _.isNil(trailModel.bucketId)
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.bucketName({
                        bucket:
                            <Entity
                                entityIdOrModel={trailModel.bucketId}
                                variant="text"/>,
                        translatedTrailTypeName
                    })),
        enabled:
            new RiskDefinitionContextItem(
                (trail.enabled
                    ? localization.enabled.true
                    : localization.enabled.false)({ translatedTrailTypeName })),
        encryption:
            new RiskDefinitionContextItem(
                localization.encryption.text({
                    encryption:
                        _.isNil(trail.bucketKmsEncryptionKeyOrAliasArn)
                            ? localization.encryption.none()
                            : localization.encryption.kms({
                                key:
                                    <Entity
                                        entityIdOrModel={trailModel.kmsEncryptionKeyIdReferences[0]}
                                        variant="text"/>
                            }),
                    translatedTrailTypeName
                })),
        logGroupDeliveryTime:
            _.isNil(trail.logGroupDeliveryTime)
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.logGroupDeliveryTime({
                        logGroupDeliveryTime: trail.logGroupDeliveryTime,
                        translatedTrailTypeName
                    })),
        multiRegion:
            new RiskDefinitionContextItem(
                localization.multiRegion.text({
                    multiRegional:
                        trail.multiRegion
                            ? localization.multiRegion.multiRegional()
                            : localization.multiRegion.notMultiRegional(),
                    translatedTrailTypeName
                })),
        organizationTrail:
            new RiskDefinitionContextItem(
                (trail.organizationTrail
                    ? localization.organizationTrail.true
                    : localization.organizationTrail.false)({ translatedTrailTypeName }))
    };
}