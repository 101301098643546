import { Stack } from "@mui/material";
import { findNodeAtLocation, parseTree } from "jsonc-parser";
import _ from "lodash";
import React, { useMemo } from "react";
import { TextViewer, TimeFormatter, useLocalization, useRoute, useSetRoute, VerticalTabView } from "@infrastructure";
import { RiskPolicyDataChart } from "..";
import { Contract, CustomerConsoleAppUrlHelper, EventsIcon, InfoCard, InfoItem, ProfileLayout, ProfileTopbar, StorageHelper, Tenant, useEntityTypeNameTranslator, useRiskPolicyTranslator, useTheme, VerticalTopItemsInfoItem } from "../../../../../../../../common";
import { kubernetesClusterAdmissionControllerEventModelStore } from "../../../../../../../../common/stores/kubernetesClusterAdmissionControllerEventModelStore";
import { EntitiesInfoItem } from "../../../../../Entities/components/Profile/components";
import { useKubernetesAdmissionControllerResourceOperationTranslator, useKubernetesAdmissionControllerRiskPolicyResultTranslator } from "../../../../hooks";
import { CategoryIcon } from "./icons";

export type ProfileProps = {
    eventId: string;
};

export function Profile({ eventId }: ProfileProps) {
    const eventModel = kubernetesClusterAdmissionControllerEventModelStore.useGet(eventId);
    const event = eventModel.event as Contract.KubernetesClusterAdmissionControllerEvent;
    const [eventJson, eventJsonHighlightedLines] =
        useMemo(
            () => {
                if (_.isNil(event.raw)) {
                    return [];
                }

                const eventJson =
                    JSON.stringify(
                        JSON.parse(event.raw),
                        undefined,
                        2);
                const eventJsonHighlightedLines = new Set<number>();
                const eventJsonNode = parseTree(eventJson);
                const violationPropertyPathRegex = /(?<path>[^.\[\]]+)(?:\[(?<index>\d+)])?/g;
                const violationPropertyPaths =
                    _.flatMap(
                        event.riskPolicyDatas,
                        eventRiskPolicyData => eventRiskPolicyData.violationPropertyPaths);
                for (const violationPropertyPath of violationPropertyPaths) {
                    const violationPropertyPathMatches = Array.from(violationPropertyPath.matchAll(violationPropertyPathRegex));
                    const violationPropertyPathJsonPath =
                        _.flatMap(
                            violationPropertyPathMatches,
                            violationPropertyPathMatch =>
                                _<number | string>([]).
                                    concat(violationPropertyPathMatch.groups!.path).
                                    concatIf(
                                        !_.isNil(violationPropertyPathMatch.groups!.index),
                                        () => _.parseInt(violationPropertyPathMatch[2])).
                                    value());
                    const propertyJsonNode =
                        findNodeAtLocation(
                            eventJsonNode!,
                            violationPropertyPathJsonPath);
                    if (_.isNil(propertyJsonNode)) {
                        continue;
                    }

                    let eventJsonIndex = 0;
                    let lineNumber = 1;
                    while (eventJsonIndex < propertyJsonNode.offset) {
                        if (eventJson[eventJsonIndex++] === "\n") {
                            lineNumber++;
                        }
                    }
                    eventJsonHighlightedLines.add(lineNumber);

                    while (eventJsonIndex < propertyJsonNode.offset + propertyJsonNode.length) {
                        if (eventJson[eventJsonIndex++] === "\n") {
                            eventJsonHighlightedLines.add(++lineNumber);
                        }
                    }
                }

                return [
                    eventJson,
                    Array.from(eventJsonHighlightedLines.values())
                ];
            },
            [event]);


    const { category } = useRoute(`${CustomerConsoleAppUrlHelper.getKubernetesClusterAdmissionControllerEventProfileHashUrl(eventId)}/{category}`);
    const setRoute = useSetRoute();
    const orderedCategories =
        useMemo(
            () =>
                _<ProfileCategory>([]).
                    concat(ProfileCategory.Overview).
                    concatIf(
                        !_.isNil(event.raw),
                        ProfileCategory.RequestJson).
                    value(),
            [eventId]);

    const currentCategory =
        useMemo(
            () =>
                _.includes(orderedCategories, category)
                    ? category as ProfileCategory
                    : _.first(orderedCategories)!,
            [category, orderedCategories]);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const kubernetesAdmissionControllerResourceOperationTranslator = useKubernetesAdmissionControllerResourceOperationTranslator();
    const kubernetesAdmissionControllerRiskPolicyResultTranslator = useKubernetesAdmissionControllerRiskPolicyResultTranslator();
    const riskPolicyTranslator = useRiskPolicyTranslator();
    const localization =
        useLocalization(
            "views.customer.kubernetes.kubernetesClusterAdmissionControllerEvents.profile",
            () => ({
                clusterReference: "Cluster",
                identityReference: "Identity",
                ownerResourceReference: "Owner Resource",
                resourceOperation: "Action",
                resourceReference: "Resource",
                resourceTypeName: "Resource Type",
                riskPolicyDatas: "Policies Evaluated",
                riskPolicyResult: "Result",
                tabs: {
                    [ProfileCategory.RequestJson]: "Request JSON",
                    [ProfileCategory.Overview]: "Overview"
                },
                tenantId: "Account",
                time: "Time",
                title: "Admission Controller Log",
                violationRiskPolicyDatas: "Policy Violations"
            }));

    const theme = useTheme();
    return (
        <ProfileLayout
            topBarElement={
                <ProfileTopbar
                    icon={<EventsIcon/>}
                    title={localization.title()}/>}>
            <VerticalTabView
                items={
                    _.map(
                        orderedCategories,
                        category => ({
                            icon: <CategoryIcon category={category}/>,
                            title: localization.tabs[category](),
                            view: category
                        }))}
                selectedView={currentCategory}
                storageItem={StorageHelper.customerKubernetesClusterAdmissionControllerEventsProfileTabsOpen}
                onSelectedViewChanged={
                    view => {
                        StorageHelper.customerKubernetesClusterAdmissionControllerEventsProfileSelectedTab.setValue(view);
                        setRoute(CustomerConsoleAppUrlHelper.getKubernetesClusterAdmissionControllerEventProfileHashUrl(eventId, view)!);
                    }}>
                <Stack
                    sx={{
                        height: "100%",
                        overflow: "hidden auto",
                        padding: theme.spacing(2, 2, 0, 2)
                    }}>
                    {currentCategory === ProfileCategory.Overview && (
                        <InfoCard columns={true}>
                            <InfoItem
                                title={localization.time()}
                                value={`${TimeFormatter.mediumDate(event.time)} ${TimeFormatter.longTime(event.time)}`}/>
                            <InfoItem
                                title={localization.resourceOperation()}
                                value={kubernetesAdmissionControllerResourceOperationTranslator(event.resourceOperation)}/>
                            <InfoItem
                                title={localization.resourceTypeName()}
                                value={entityTypeNameTranslator(event.resourceTypeName)}/>
                            <EntitiesInfoItem
                                entityIdsOrModels={eventModel.resourceReference}
                                entityTypeName={Contract.TypeNames.IKubernetesResource}
                                title={localization.resourceReference()}/>
                            <EntitiesInfoItem
                                entityIdsOrModels={eventModel.clusterReference}
                                entityTypeName={Contract.TypeNames.IKubernetesCluster}
                                title={localization.clusterReference()}/>
                            <InfoItem
                                title={localization.tenantId()}
                                value={<Tenant tenantId={event.tenantId}/>}/>
                            <InfoItem
                                title={localization.riskPolicyResult()}
                                value={kubernetesAdmissionControllerRiskPolicyResultTranslator(event.riskPolicyResult)}/>
                            <InfoItem
                                title={localization.violationRiskPolicyDatas()}
                                value={
                                    _.isEmpty(eventModel.violationRiskPolicyDatas)
                                        ? undefined
                                        : <RiskPolicyDataChart riskPolicyDatas={eventModel.violationRiskPolicyDatas}/>}
                                valueSx={{ maxWidth: "100%" }}/>
                            <VerticalTopItemsInfoItem
                                items={
                                    _(event.riskPolicyDatas).
                                        map((riskPolicyData: Contract.KubernetesClusterAdmissionControllerEventRiskPolicyData) => riskPolicyTranslator(riskPolicyData.configurationTypeName).title).
                                        orderBy().
                                        value()}
                                title={localization.riskPolicyDatas()}/>
                            <EntitiesInfoItem
                                entityIdsOrModels={eventModel.ownerResourceReference}
                                entityTypeName={Contract.TypeNames.IKubernetesResource}
                                title={localization.ownerResourceReference()}/>
                            <EntitiesInfoItem
                                entityIdsOrModels={eventModel.identityReference}
                                entityTypeName={Contract.TypeNames.IKubernetesPrincipal}
                                title={localization.identityReference()}/>
                        </InfoCard>)}
                    {currentCategory === ProfileCategory.RequestJson && (
                        <TextViewer
                            format="json"
                            height="100%"
                            highlightedLines={eventJsonHighlightedLines}
                            startLine={1}
                            text={eventJson!}/>)}
                </Stack>
            </VerticalTabView>
        </ProfileLayout>);
}

export enum ProfileCategory {
    Overview = "overview",
    RequestJson = "requestJson"
}