import { InfiniteScroll, InfiniteScrollActions, InfiniteScrollFetchDataResult, MemoItemRenderer, SearchListItemsProps, Sx, useChangeEffect, useDeepDependency, useSearchListContext } from "@infrastructure";
import { BoxProps, List, ListItem, SxProps, useTheme } from "@mui/material";
import _ from "lodash";
import React, { useCallback, useMemo, useRef, useState } from "react";

type InfiniteItemsProps =
    Omit<SearchListItemsProps, "listProps"> & {
        container?: "card" | "popup";
        listSx?: SxProps;
        onItemPageLoading?: (itemPage: any[]) => Promise<void>;
        onScroll?: BoxProps["onScroll"];
        pageSize?: number;
        sx: SxProps;
    };

export function InfiniteItems({ container, dependencies, emptyMessageText, itemOptions, listSx, onItemPageLoading, onScroll, pageSize = infiniteItemsPageSize, sx }: InfiniteItemsProps) {
    const { filtered, filteredItems } = useSearchListContext();
    const infiniteScrollActionsRef = useRef<InfiniteScrollActions>(null);
    const [visibleItems, setVisibleItems] = useState<any[]>([]);
    const visibleItemCountRef = useRef(0);
    const filteredItemsDeepDependency = useDeepDependency(filteredItems);
    useChangeEffect(
        () => {
            setVisibleItems([]);
            visibleItemCountRef.current = 0;
            infiniteScrollActionsRef.current!.reset();
        },
        [filteredItemsDeepDependency]);

    const fetchItems =
        useCallback(
            async function() {
                const filteredItemPage =
                        _.slice(
                            filteredItems,
                            visibleItemCountRef.current,
                            visibleItemCountRef.current + pageSize);

                if (!_.isEmpty(filteredItemPage) &&
                        !_.isNil(onItemPageLoading)) {
                    await onItemPageLoading(filteredItemPage);
                }

                return new InfiniteScrollFetchDataResult(
                    !_.isEmpty(filteredItems),
                    filteredItems.length <= visibleItemCountRef.current,
                    () => {
                        visibleItemCountRef.current = visibleItemCountRef.current + filteredItemPage.length;
                        setVisibleItems(
                            visibleItems =>
                                [
                                    ...visibleItems,
                                    ...filteredItemPage
                                ]);
                    });
            },
            [filteredItems, onItemPageLoading]);

    const theme = useTheme();
    const listItems =
        useMemo(
            () =>
                _.map(
                    visibleItems,
                    visibleItem =>
                        <ListItem
                            disableGutters={true}
                            key={JSON.stringify(visibleItem)}
                            sx={{
                                paddingBottom: theme.spacing(itemOptions?.spacing ?? 0),
                                paddingTop: theme.spacing(itemOptions?.spacing ?? 0)
                            }}>
                            {_.isNil(itemOptions?.render)
                                ? visibleItem
                                : <MemoItemRenderer
                                    getDependencies={itemOptions?.getDependencies}
                                    render={itemOptions!.render}
                                    renderArguments={[visibleItem]}/>}
                        </ListItem>),
            [filteredItems, visibleItems, ...dependencies]);
    return (
        <InfiniteScroll
            actionsRef={infiniteScrollActionsRef}
            container={container}
            emptyTextOptions={{ text: emptyMessageText?.getText(filtered) }}
            fetchData={fetchItems}
            sx={sx}
            onScroll={onScroll}>
            <List
                dense={true}
                disablePadding={true}
                sx={
                    Sx.combine(
                        { padding: theme.spacing(0.5) },
                        listSx)}>
                {listItems}
            </List>
        </InfiniteScroll>);
}

export const infiniteItemsPageSize = 20;