﻿import { ItemSelector, MultiSelect, useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract } from "../controllers";
import { useEntityTypeNameTranslator } from "../hooks";
import { TenantIcon } from "../icons";
import { EntityTypeMetadataModelHelper, TenantHelper, TypeHelper } from "../utilities";
import { EntityIcon } from "./EntityIcon";


type EntityTypeNameSelectorProps = {
    disabled?: boolean;
    entityTypeNames?: string[];
    entityTypeNameVariant?: "name" | "viewName";
    multiSelect: boolean;
    onSelectedItemsChanged: (selectedTypeNames: string[]) => void;
    permissionEvaluationEntities?: boolean;
    placeholder?: string;
    selectedEntityTypeNames: string[];
    tenantTypes?: Contract.TenantType[];
};

export function EntityTypeNameSelector({ disabled, entityTypeNames, entityTypeNameVariant = "viewName", multiSelect, onSelectedItemsChanged, permissionEvaluationEntities, placeholder, selectedEntityTypeNames, tenantTypes }: EntityTypeNameSelectorProps) {
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    tenantTypes =
        _.isNil(tenantTypes)
            ? TenantHelper.EntitiesTenantTypes
            : tenantTypes;

    const entityTypeNameToTenantTypeMap =
        useMemo(
            () => {
                const entityTypeMetadataModels =
                    _.isNil(entityTypeNames)
                        ? EntityTypeMetadataModelHelper.getAll()
                        : _(entityTypeNames).
                            flatMap(entityTypeName => TypeHelper.getSealedAssignableTypeNames(entityTypeName)).
                            map(entityTypeName => EntityTypeMetadataModelHelper.get(entityTypeName)).
                            value();

                return _(entityTypeMetadataModels).
                    filter(
                        entityTypeMetadataModel =>
                            entityTypeMetadataModel.entitiesView &&
                            _.includes(tenantTypes, entityTypeMetadataModel.tenantType) &&
                            (!permissionEvaluationEntities || entityTypeMetadataModel.permissionEvaluation)).
                    orderBy([
                        entityTypeMetadataModel => entityTypeMetadataModel.tenantType,
                        entityTypeMetadataModel =>
                            entityTypeNameTranslator(
                                entityTypeNameVariant === "viewName"
                                    ? entityTypeMetadataModel.entitiesViewName
                                    : entityTypeMetadataModel.name)
                    ]).
                    keyBy(
                        entityTypeMetadataModel =>
                            entityTypeNameVariant === "viewName"
                                ? entityTypeMetadataModel.entitiesViewName
                                : entityTypeMetadataModel.name).
                    mapValues(entityTypeMetadataModel => entityTypeMetadataModel.tenantType).
                    value();
            },
            [entityTypeNames, tenantTypes]);

    const localization =
        useLocalization(
            "common.entityTypeNameSelector",
            () => ({
                multi: {
                    placeholder: "Types",
                    selected: "{{selectedTypeNameCount}} selected"
                },
                single: {
                    placeholder: "Type"
                }
            }));
    return (
        multiSelect
            ? <MultiSelect
                disabled={disabled}
                fieldOptions={{
                    dense: true,
                    variant: "itemSelector"
                }}
                getItemText={typeName => entityTypeNameTranslator(typeName)}
                items={_.keys(entityTypeNameToTenantTypeMap)}
                placeholder={placeholder ?? localization.multi.placeholder()}
                selectedItems={selectedEntityTypeNames}
                selectedItemsTranslator={selectedEntitiesViewEntityTypeNames => localization.multi.selected({ selectedTypeNameCount: selectedEntitiesViewEntityTypeNames.length })}
                onSelectedItemsChanged={selectedEntityTypeNames => onSelectedItemsChanged(selectedEntityTypeNames)}>
                {item => (
                    <Stack
                        alignItems="center"
                        direction="row"
                        spacing={1}>
                        <EntityIcon
                            entityTypeName={item}
                            sx={{ fontSize: "24px" }}/>
                        <Typography>
                            {entityTypeNameTranslator(item)}
                        </Typography>
                    </Stack>)}
            </MultiSelect>
            : <ItemSelector
                disabled={disabled}
                fullWidth={true}
                getItemText={
                    item =>
                        entityTypeNameTranslator(
                            item,
                            { count: 0 })}
                items={_.keys(entityTypeNameToTenantTypeMap)}
                placeholder={placeholder ?? localization.single.placeholder()}
                selectedItem={_.head(selectedEntityTypeNames)}
                sorted={false}
                onSelectedItemChanged={selectedItem => onSelectedItemsChanged([selectedItem])}
                onSelectionCleared={() => onSelectedItemsChanged([])}>
                {item => (
                    <Stack
                        alignItems="center"
                        direction="row"
                        spacing={1}>
                        <TenantIcon
                            sx={{ fontSize: "24px" }}
                            tenantType={entityTypeNameToTenantTypeMap[item]}/>
                        <Typography>
                            {entityTypeNameTranslator(
                                item,
                                { count: 0 })}
                        </Typography>
                    </Stack>)}
            </ItemSelector>);
}