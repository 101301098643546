import { useLocalization } from "@infrastructure";
import { Divider } from "@mui/material";
import _ from "lodash";
import React, { Fragment } from "react";
import { UserView, UserViewType, useUserContext } from "../..";
import { AuditEventsIcon, Contract, SetViewRoute, Sidebar as CommonSidebar, sidebarItemSizeClose, SidebarNavigationItem, TenantHelper, useTheme, ViewRoute } from "../../../../common";
import { ApproverUserPermissionRequestsIcon, GranteeUserPermissionRequestsIcon, PermissionEligibilitiesIcon } from "./icons";

type SidebarProps = {
    rootRelativeUrl: string;
    setViewRoute: SetViewRoute<string>;
    viewRoute: ViewRoute<string>;
    views: UserViewType[];
};

export function Sidebar({ rootRelativeUrl, setViewRoute, viewRoute, views }: SidebarProps) {
    const { reloadView } = useUserContext();

    const localization =
        useLocalization(
            "views.user.sidebar",
            () => ({
                approverUserPermissionRequests: "Access Reviews",
                auditEvents: "Audit",
                granteeUserPermissionRequests: "Access Requests",
                permissionEligibilities: "Eligibilities"
            }));

    function isViewVisible(view: Contract.UserConsoleAppView) {
        return _.includes(views, UserView[view]);
    }

    const theme = useTheme();
    return (
        <CommonSidebar
            items={
                <Fragment>
                    {isViewVisible(Contract.UserConsoleAppView.GranteeUserPermissionRequests) &&
                        <SidebarNavigationItem
                            icon={GranteeUserPermissionRequestsIcon}
                            key={UserView.GranteeUserPermissionRequests}
                            tenantTypes={TenantHelper.PermissionManagementTenantTypes}
                            title={localization.granteeUserPermissionRequests()}
                            view={UserView.GranteeUserPermissionRequests}/>}
                    {isViewVisible(Contract.UserConsoleAppView.ApproverUserPermissionRequests) &&
                        <SidebarNavigationItem
                            icon={ApproverUserPermissionRequestsIcon}
                            key={UserView.ApproverUserPermissionRequests}
                            tenantTypes={TenantHelper.PermissionManagementTenantTypes}
                            title={localization.approverUserPermissionRequests()}
                            view={UserView.ApproverUserPermissionRequests}/>}
                    {(isViewVisible(Contract.UserConsoleAppView.GranteeUserPermissionRequests) || isViewVisible(Contract.UserConsoleAppView.ApproverUserPermissionRequests)) &&
                        (isViewVisible(Contract.UserConsoleAppView.PermissionEligibilities) || isViewVisible(Contract.UserConsoleAppView.AuditEvents)) &&
                        <Divider
                            sx={{
                                margin: theme.spacing(1),
                                marginLeft: theme.px(sidebarItemSizeClose * (1 - 0.5) / 2),
                                marginRight: theme.px(sidebarItemSizeClose * (1 - 0.5) / 2)
                            }}/>}
                    {isViewVisible(Contract.UserConsoleAppView.PermissionEligibilities) &&
                        <SidebarNavigationItem
                            icon={PermissionEligibilitiesIcon}
                            key={UserView.PermissionEligibilities}
                            tenantTypes={TenantHelper.PermissionManagementTenantTypes}
                            title={localization.permissionEligibilities()}
                            view={UserView.PermissionEligibilities}/>}
                    {isViewVisible(Contract.UserConsoleAppView.AuditEvents) &&
                        <SidebarNavigationItem
                            icon={AuditEventsIcon}
                            key={UserView.AuditEvents}
                            tenantTypes={TenantHelper.PermissionManagementTenantTypes}
                            title={localization.auditEvents()}
                            view={UserView.AuditEvents}/>}
                </Fragment>}
            reloadView={reloadView}
            rootRelativeUrl={rootRelativeUrl}
            setViewRoute={setViewRoute}
            viewRoute={viewRoute}/>);
}