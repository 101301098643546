import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";
import { useTheme } from "../../../themes";

export function VaronisSystemsIcon(props: SvgIconProps) {
    const theme = useTheme();
    return (
        <SvgIcon {...props}>
            <path
                clipRule="evenodd"
                d="M12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24ZM5 6H7.49993L12.8614 13.9383L11.6148 15.7948L5 6ZM12.1584 16.5973L13.405 14.743L14.0317 15.6792C14.1169 15.7883 14.1779 15.9146 14.2106 16.0496C14.2433 16.1845 14.2468 16.325 14.2211 16.4615C14.1953 16.5979 14.1408 16.7272 14.0612 16.8406C13.9817 16.954 13.8789 17.0488 13.7599 17.1186C13.6526 17.2062 13.5278 17.2692 13.3941 17.3035C13.2604 17.3379 13.1209 17.3426 12.9852 17.3174C12.8495 17.2922 12.7208 17.2377 12.608 17.1576C12.4951 17.0775 12.4008 16.9738 12.3313 16.8534L12.1584 16.5973ZM12.2842 6H9.78424L14.0002 12.245L15.249 10.3885L12.2842 6ZM15.7814 11.1932L18.8204 15.6792C18.9747 15.9074 19.0328 16.188 18.9821 16.4595C18.9314 16.7309 18.7758 16.9708 18.5497 17.1266C18.3237 17.2823 18.0456 17.3409 17.7766 17.2897C17.5077 17.2385 17.2699 17.0816 17.1156 16.8534L14.5348 13.0475L15.7814 11.1932Z"
                fill={theme.palette.text.primary}
                fillRule="evenodd"/>
        </SvgIcon>);
}