﻿import { useLocalization } from "@infrastructure";
import React, { useCallback } from "react";
import { useEnabledTranslator } from "../..";
import { Contract, CustomerConsoleAppUrlHelper, RiskPoliciesType } from "../../../../../../../common";
import { EnabledRadioGroup } from "../components";
import { RiskPolicyConfigurationDefinitionData } from "../useConfigurationDefinition";
import { useAadCommonDirectoryIdentityInactiveRiskPolicyConfigurationDefinition } from "./useAadCommonDirectoryIdentityInactiveRiskPolicyConfigurationDefinition";

export function useAadDirectoryUserInactiveRiskPolicyConfigurationDefinition(riskPoliciesType: RiskPoliciesType): RiskPolicyConfigurationDefinitionData {
    const commonDirectoryIdentityInactiveRiskPolicyConfigurationDefinition = useAadCommonDirectoryIdentityInactiveRiskPolicyConfigurationDefinition(Contract.TypeNames.AadDirectoryUser, riskPoliciesType);
    const enabledTranslator = useEnabledTranslator();
    const localization =
        useLocalization(
            "views.customer.riskPolicies.hooks.useConfigurationDefinition.hooks.useAadDirectoryUserInactiveRiskPolicyConfigurationDefinition",
            () => ({
                description: "Choose whether or not to create findings when the user is a Guest, and their invitation status to a tenant is Pending.",
                title: "Pending Guest Users"
            }));

    const getEnabled =
        useCallback(
            (riskPolicyConfiguration: Contract.RiskPolicyConfiguration) =>
                (riskPolicyConfiguration as Contract.AadDirectoryUserInactiveRiskPolicyConfiguration).includeInvitationPendingAcceptanceGuestUser,
            []);

    return {
        generalAdditionalItems: [
            ...commonDirectoryIdentityInactiveRiskPolicyConfigurationDefinition.generalAdditionalItems!,
            {
                configurationElement:
                    <EnabledRadioGroup
                        description={localization.description()}
                        documentationUrl={CustomerConsoleAppUrlHelper.getDocsViewAndManagePoliciesRelativeUrl()}
                        getEnabled={getEnabled}
                        key={`${Contract.AadDirectoryIdentityInactiveRiskPolicyConfiguration}-invitationPendingAcceptanceGuestUserEnabled`}
                        riskPoliciesType={riskPoliciesType}
                        setEnabled={
                            (riskPolicyConfiguration, includeInvitationPendingAcceptanceGuestUser) => {
                                (riskPolicyConfiguration as Contract.AadDirectoryUserInactiveRiskPolicyConfiguration).includeInvitationPendingAcceptanceGuestUser = includeInvitationPendingAcceptanceGuestUser;
                            }}
                        title={localization.title()}/>,
                viewItem: {
                    getValue: riskPolicyConfiguration => enabledTranslator(getEnabled(riskPolicyConfiguration)),
                    title: localization.title()
                }
            }
        ]
    };
}