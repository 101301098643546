import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useGciDirectoryDirectoryRoleTypeTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useGciDirectoryDirectoryRoleTypeTranslator",
            () => ({
                [Contract.TypeNames.GciDirectoryDirectoryRoleType]: {
                    [Contract.GciDirectoryDirectoryRoleType.Custom]: "Custom",
                    [Contract.GciDirectoryDirectoryRoleType.System]: "System"
                }
            }));
    return (type: Contract.GciDirectoryDirectoryRoleType) =>
        localization[Contract.TypeNames.GciDirectoryDirectoryRoleType][type]();
}