﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useAwsCommonEc2ResourceInfoElements, useAwsDefaultResourceInfoItemElements } from ".";
import { useCustomEntityPropertyInfoItemElements } from "..";
import { Contract, InfoItem, VerticalTopItemsInfoItem } from "../../../../../../../../../../common";
import { AwsEc2VpcEndpointType } from "../../../../../../../../../../common/controllers/types.generated";
import { useAwsEc2VpcEndpointStatusTranslator, useAwsEc2VpcEndpointTypeTranslator } from "../../../../../../hooks";
import { EntitiesInfoItem, Info } from "../../../../components";
import { AwsIamPolicyDocument } from "../../components";
import { AwsEc2VpcEndpointSubnetsInfoCard } from "../../components/AwsEc2VpcEndpointSubnetsInfoCard";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../useDefinition";

export function useAwsEc2VpcEndpointDefinition(resourceModel: Contract.AwsResourceModel, options?: DefinitionOptions) {
    const commonEc2ResourceInfoItemElements = useAwsCommonEc2ResourceInfoElements(resourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useAwsDefaultResourceInfoItemElements(resourceModel);
    const vpcEndpoint = resourceModel.entity as Contract.AwsEc2VpcEndpoint;
    const vpcEndpointModel = resourceModel as Contract.AwsEc2VpcEndpointModel;

    const ec2VpcEndpointStatusTranslator = useAwsEc2VpcEndpointStatusTranslator();
    const ec2VpcEndpointTypeTranslator = useAwsEc2VpcEndpointTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsEc2VpcEndpointDefinition",
            () => ({
                info: {
                    items: {
                        dnsNames: "DNS Names",
                        routeTables: "Route Tables",
                        securityGroup: "Security Groups",
                        service: "Service",
                        status: "Status",
                        subnets: "Subnets",
                        type: "VPC Endpoint Type",
                        vpc: "VPC"
                    }
                },
                tabs: {
                    policyDocument: "Policy"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs:
            _<EntityProfileDefinitionTab>([]).
                concatIf(
                    _.isNil(vpcEndpoint.servicePolicyDocument),
                    new EntityProfileDefinitionTab(
                        ProfileCategory.Iam,
                        <AwsIamPolicyDocument policyDocument={vpcEndpoint.servicePolicyDocument}/>,
                        localization.tabs.policyDocument(),
                        "policyDocument")).
                value(),
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    ...commonEc2ResourceInfoItemElements,
                    <InfoItem
                        key="type"
                        title={localization.info.items.type()}
                        value={ec2VpcEndpointTypeTranslator(vpcEndpoint.type)}/>,
                    <InfoItem
                        key="status"
                        title={localization.info.items.status()}
                        value={ec2VpcEndpointStatusTranslator(vpcEndpoint.status)}/>,
                    <InfoItem
                        key="service"
                        title={localization.info.items.service()}
                        value={vpcEndpoint.serviceName}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={vpcEndpointModel.routeTableIds}
                        entityTypeName={Contract.TypeNames.AwsEc2RouteTable}
                        key="routeTables"
                        title={localization.info.items.routeTables()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={vpcEndpointModel.subnetIds}
                        entityTypeName={Contract.TypeNames.AwsEc2Subnet}
                        key="subnets"
                        title={localization.info.items.subnets()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={vpcEndpoint.vpcIds}
                        entityTypeName={Contract.TypeNames.AwsEc2VpcEndpoint}
                        key="vpc"
                        location="all"
                        title={localization.info.items.vpc()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={vpcEndpoint.securityGroupIds}
                        entityTypeName={Contract.TypeNames.AwsEc2SecurityGroup}
                        key="securityGroup"
                        title={localization.info.items.securityGroup()}/>,
                    <VerticalTopItemsInfoItem
                        items={vpcEndpoint.dnsNames}
                        key="dnsNames"
                        title={localization.info.items.dnsNames()}/>
                ]}
                options={options?.infoOptions}>
                {vpcEndpoint.type === AwsEc2VpcEndpointType.Interface
                    ? <AwsEc2VpcEndpointSubnetsInfoCard
                        subnetIdToNetworkInterfaceId={vpcEndpointModel.subnetIdToNetworkInterfaceIdMap}/>
                    : undefined}
            </Info>
    });
}