import { Stack, Typography } from "@mui/material";
import React from "react";
import { useGcpScopeResourceAccessLevelTranslator } from "../../";
import { Contract } from "../../../../common";
import { AccessLevelIcon } from "./icons";

type GcpScopeResourceAccessLevelProps = {
    accessLevel: Contract.GcpScopeResourceAccessLevel;
};

export function GcpScopeResourceAccessLevel({ accessLevel }: GcpScopeResourceAccessLevelProps) {
    const scopeResourceAccessLevelTranslator = useGcpScopeResourceAccessLevelTranslator();
    return (
        <Stack
            alignItems="center"
            direction="row"
            spacing={1}>
            <AccessLevelIcon
                accessLevel={accessLevel}
                sx={{ fontSize: "18px" }}/>
            <Typography noWrap={true}>
                {scopeResourceAccessLevelTranslator(accessLevel)}
            </Typography>
        </Stack>);
}