import { Optional, TimeHelper } from "@infrastructure";
import { Dictionary } from "lodash";
import { ReactNode, useMemo } from "react";
import { Contract } from "../../../../../../../../../../../../../../../../../../../common";
import { TableDefinition } from "../../../../../useDefinition";

export function useCommonCloudRiskPolicyTableDefinition(riskPolicyTypeTableDefinition: RiskPolicyTypeTableDefinition): TableDefinition {
    return useMemo(
        () => ({
            columns: riskPolicyTypeTableDefinition.columns,
            getRiskIdToScopeIdentityPermissionsMapRequest: riskPolicyTypeTableDefinition.getRiskIdToScopesIdentityPermissionsMapRequest,
            getRiskModelPageRequest:
                (limit, requestFilters, skip, sort, filterMap, filtersTime) =>
                    new Contract.RiskControllerGetRiskPolicyTypeGroupCloudRiskModelPageRequest(
                        requestFilters,
                        limit,
                        skip,
                        sort,
                        riskPolicyTypeTableDefinition.getGroupFilters(filterMap, filtersTime)),
            getRisksReportRequestDefinition:
                (filterMap, filtersTime, riskModelFilters, riskStatus, sort) =>
                    new Contract.ReportControllerCloudRisksReportRequestDefinition(
                        undefined,
                        TimeHelper.timeZoneId(),
                        Contract.TypeNames.ReportControllerCloudRisksReportRequestDefinition,
                        undefined,
                        true,
                        riskModelFilters,
                        riskPolicyTypeTableDefinition.getGroupFilters(filterMap, filtersTime),
                        sort,
                        riskStatus)
        }),
        [riskPolicyTypeTableDefinition]);
}

interface RiskPolicyTypeTableDefinition {
    columns: ReactNode[];
    entityTypeName?: string;
    getGroupFilters: (filterMap: Dictionary<any>, filtersTime: Optional<string>) => Contract.RiskControllerCloudRiskPolicyTypeFilters;
    getRiskIdToScopesIdentityPermissionsMapRequest: (requestFilters: Contract.RiskControllerRiskModelFilters) => Contract.RiskControllerGetCloudRiskIdToScopeIdentityPermissionsMapRequest;
}