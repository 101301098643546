import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, EnumValuesFilter, NumberFormatter, optionalTableCell, useLocalization } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { useAzureCommonComputeDiskResourceDefinition } from ".";
import { EntityTableDefinition } from "../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";

export function useAzureComputeDiskDefinition(definitionData: DefinitionData) {
    const commonComputeDiskResourceDefinition = useAzureCommonComputeDiskResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.azure.useAzureComputeDiskDefinition",
            () => ({
                columns: {
                    managedIdentity: "Managed Identity",
                    role: {
                        title: "Disk Role",
                        [Contract.TypeNames.AzureComputeResourceDiskRole]: {
                            [Contract.AzureComputeResourceDiskRole.Data]: "Data",
                            [Contract.AzureComputeResourceDiskRole.OperatingSystem]: "OS"
                        }
                    },
                    size: "Size",
                    virtualMachineId: "Virtual Machine"
                }
            }));

    function formatSize(computeDiskModel: Contract.AzureComputeDiskModel) {
        const size = (computeDiskModel.entity as Contract.AzureComputeDisk).size;
        return _.isNil(size)
            ? undefined
            : NumberFormatter.storage(size);
    }

    return new EntityTableDefinition(
        _.filter([
            commonComputeDiskResourceDefinition.columns.tenantColumn,
            commonComputeDiskResourceDefinition.columns.creationTimeColumn,
            commonComputeDiskResourceDefinition.columns.creatorIdentityCsvColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AzureComputeDiskModel) => ({
                            [localization.columns.size()]: formatSize(item) ?? ""
                        })
                }}
                id={Contract.EntityModelProperty.AzureComputeDiskSize}
                key={Contract.EntityModelProperty.AzureComputeDiskSize}
                render={optionalTableCell<Contract.AzureComputeDiskModel>(formatSize)}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.size()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AzureComputeDiskModel) => ({
                            [localization.columns.role.title()]: localization.columns.role[Contract.TypeNames.AzureComputeResourceDiskRole][item.role]()
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.AzureComputeResourceDiskRole}
                                enumTypeTranslator={(role: Contract.AzureComputeResourceDiskRole) => localization.columns.role[Contract.TypeNames.AzureComputeResourceDiskRole][role]()}
                                placeholder={localization.columns.role.title()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AzureComputeDiskRole}
                itemProperty={(item: Contract.AzureComputeDiskModel) => localization.columns.role[Contract.TypeNames.AzureComputeResourceDiskRole][item.role]()}
                key={Contract.EntityModelProperty.AzureComputeDiskRole}
                title={localization.columns.role.title()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.AzureComputeDiskModel>(
                        Contract.TypeNames.AzureManagedIdentityManagedIdentity,
                        item => [item.systemManagedIdentityId],
                        localization.columns.managedIdentity())}
                id="managedIdentity"
                key="managedIdentity"/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.AzureComputeDiskModel>(
                        Contract.TypeNames.AzureComputeVirtualMachine,
                        item => [item.virtualMachineId],
                        localization.columns.virtualMachineId())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AzureComputeDiskVirtualMachine)}
                                placeholder={localization.columns.virtualMachineId()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AzureComputeDiskVirtualMachine}
                key={Contract.EntityModelProperty.AzureComputeDiskVirtualMachine}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AzureComputeDiskModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.virtualMachineId}
                            entityTypeName={Contract.TypeNames.AzureComputeVirtualMachine}/>}
                title={localization.columns.virtualMachineId()}/>,
            commonComputeDiskResourceDefinition.columns.networkAccessPolicyType,
            commonComputeDiskResourceDefinition.columns.diskResourceSourceColumn,
            commonComputeDiskResourceDefinition.columns.regionColumn,
            commonComputeDiskResourceDefinition.columns.regionLocationColumn,
            commonComputeDiskResourceDefinition.columns.resourceGroupColumn,
            commonComputeDiskResourceDefinition.columns.tagsColumn,
            commonComputeDiskResourceDefinition.columns.attributesColumn,
            commonComputeDiskResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonComputeDiskResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonComputeDiskResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ]),
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAzureComputeDiskRequest(
                new Contract.EntityControllerGetEntityModelPageAzureComputeDiskRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureResourceGroupResourceResourceGroup]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureComputeDiskResourceNetworkAccessPolicyType]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureComputeDiskResourceSource]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureComputeDiskRole]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureComputeDiskVirtualMachine])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags]))));
}