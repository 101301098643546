import { ConfirmButton, DeleteIcon, Message, useLocalization } from "@infrastructure";
import { Box, CircularProgress, FormControl, Stack, TextField, useTheme } from "@mui/material";
import _ from "lodash";
import React, { ReactNode, useState } from "react";
import { Add, Field } from "./components";

type ValuesFieldProps = {
    deleteConfirmMessage?: ((item: any) => string) | undefined;
    disableConfirm?: boolean;
    onValidate: (item: any) => string | undefined;
    onValuesChanged: (items: any[]) => Promise<void>;
    placeholder: string;
    values: any[];
};

export function ValuesField({ deleteConfirmMessage, disableConfirm = false, onValidate, onValuesChanged, placeholder, values }: ValuesFieldProps) {
    const [addValueExecuting, setAddValueExecuting] = useState(false);
    const [addValueError, setAddValueError] = useState(false);
    const [deleteValueExecuting, setDeleteValueExecuting] = useState<false | string>(false);
    const [deleteValueError, setDeleteValueError] = useState<false | string>(false);

    async function addItem(item: any) {
        setAddValueExecuting(true);
        setAddValueError(false);
        setDeleteValueExecuting(false);
        setDeleteValueError(false);

        try {
            await onValuesChanged(
                _.concat(
                    values,
                    item));
        } catch {
            setAddValueError(true);
        }

        setAddValueExecuting(false);
    }

    async function deleteValue(item: any) {
        setAddValueExecuting(false);
        setAddValueError(false);
        setDeleteValueExecuting(item);
        setDeleteValueError(false);

        try {
            await onValuesChanged(
                _.without(
                    values,
                    item));
        } catch {
            setDeleteValueError(item);
        }

        setDeleteValueExecuting(false);
    }

    const localization =
        useLocalization(
            "infrastructure.inputs.valuesField",
            () => ({
                actions: {
                    add: {
                        error: "Failed to add"
                    },
                    delete: {
                        error: "Failed to delete"
                    }
                }
            }));

    const theme = useTheme();
    return (
        <Field
            addElement={
                <Stack
                    alignItems="center"
                    direction="row"
                    spacing={1}>
                    <Box sx={{ flex: 1 }}>
                        <Add
                            disabled={!!deleteValueExecuting}
                            executing={addValueExecuting}
                            placeholder={placeholder}
                            onSave={newValue => addItem(newValue)}
                            onValidate={onValidate}/>
                    </Box>
                    {addValueError &&
                        <Message
                            level="error"
                            title={localization.actions.add.error()}
                            variant="minimal"/>}
                </Stack>}
            listItemElements={
                _.map(
                    values,
                    value =>
                        <Box
                            key={value}
                            sx={{
                                backgroundColor: theme.palette.background.paper,
                                marginTop: theme.spacing(0.5)
                            }}>
                            <FormControl
                                fullWidth={true}
                                variant="standard">
                                <Stack
                                    alignItems="center"
                                    direction="row"
                                    sx={{
                                        paddingRight: theme.spacing(1),
                                        width: "100%"
                                    }}>
                                    <Box sx={{ flex: 1 }}>
                                        <TextField
                                            fullWidth={true}
                                            hiddenLabel={true}
                                            size="small"
                                            slotProps={{
                                                htmlInput: {
                                                    sx: {
                                                        color: theme.important(theme.palette.text.primary)
                                                    }
                                                },
                                                input: {
                                                    disableUnderline: true,
                                                    readOnly: true,
                                                    sx: { borderRadius: theme.spacing(0.75, 0, 0, 0.75) }
                                                }
                                            }}
                                            value={value}
                                            variant="filled"/>
                                    </Box>
                                    {deleteValueExecuting === value &&
                                        <CircularProgress
                                            size={theme.spacing(3)}
                                            variant="indeterminate"/>}
                                    {deleteValueError === value &&
                                        <Message
                                            level="error"
                                            title={localization.actions.delete.error()}
                                            variant="minimal"/>}
                                    <ConfirmButton
                                        disableConfirm={disableConfirm}
                                        disabled={addValueExecuting || !!deleteValueExecuting}
                                        message={
                                            _.isNil(deleteConfirmMessage)
                                                ? undefined
                                                : deleteConfirmMessage(value)}
                                        variant="action"
                                        onClick={() => deleteValue(value)}>
                                        <DeleteIcon/>
                                    </ConfirmButton>
                                </Stack>
                            </FormControl>
                        </Box>)}/>);
}

export type VariantProps = {
    addElement: ReactNode;
    listItemElements: ReactNode[];
};