import { Step, Steps, useLocalization, useSetOrderedWizardContext } from "@infrastructure";
import _ from "lodash";
import React, { useEffect } from "react";
import { Contract, LicensingHelper, useTenantTypeTranslator } from "../../../../../../../../../../../../../../../../common";
import { InstructionsWithoutPermissionManagementImageUrl, InstructionsWithPermissionManagementImageUrl } from "./images";

export function ClientCredentialsItem() {
    const setOrderedWizardContext = useSetOrderedWizardContext();
    useEffect(
        () => {
            setOrderedWizardContext(
                wizardContext => ({
                    ...wizardContext,
                    sideElement:
                        <img src={
                            LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.PermissionManagement)
                                ? InstructionsWithPermissionManagementImageUrl
                                : InstructionsWithoutPermissionManagementImageUrl}/>
                }));
        },
        []);
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useIdentityProviderTenantItems.pingIdentityTenants.addOrEdit.clientCredentialsItem",
            () => ({
                steps: {
                    step1: "In Ping Identity console, navigate to **Applications -> Applications**.",
                    step2: "Click the **+** sign to add a new application. Name the application, choose application type **Worker** and click **Save**.",
                    step3: "Navigate to the **Roles** tab and click **Grant Roles**.",
                    step4: {
                        permissionManagementStep: "Optional - If you would like to leverage Just-in-Time (JIT) to temporarily add users to {{translatedTenantType}} groups of this organization, grant the **Identity Data Admin** role",
                        step1: "Configuration Read Only",
                        step2: "Identity Data Read Only",
                        title: "Grant the following roles:"
                    },
                    step5: "Click the toggle button to enable the application."
                }
            }));
    const tenantTypeTranslator = useTenantTypeTranslator();
    return (
        <Steps variant="letters">
            {localization.steps.step1()}
            {localization.steps.step2()}
            {localization.steps.step3()}
            {new Step(
                localization.steps.step4.title(),
                {
                    contentElement:
                        <Steps variant="plainNumbers">
                            {_.filter([
                                localization.steps.step4.step1(),
                                localization.steps.step4.step2(),
                                LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.PermissionManagement)
                                    ? localization.steps.step4.permissionManagementStep({ translatedTenantType: tenantTypeTranslator(Contract.TenantType.PingIdentity) })
                                    : undefined])}
                        </Steps>
                })}
            {localization.steps.step5()}
        </Steps>);
}