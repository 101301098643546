import { useMemo } from "react";
import { useOktaDefaultDirectoryEntityDefinition, useOktaDirectoryGroupDefinition, useOktaDirectoryUserDefinition } from ".";
import { Contract } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";

export function useOktaDirectoryEntityDefinition(definitionData: DefinitionData) {
    const useDefinition =
        useMemo(
            () => {
                switch (definitionData.entityTypeEntitiesViewName) {
                    case Contract.TypeNames.OktaDirectoryGroup:
                        return useOktaDirectoryGroupDefinition;
                    case Contract.TypeNames.OktaDirectoryUser:
                        return useOktaDirectoryUserDefinition;
                    default:
                        return useOktaDefaultDirectoryEntityDefinition;
                }
            },
            []);

    return useDefinition(definitionData);
}