import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../../../controllers";

export function useEntityMatchConditionGroupMembershipTypeOperatorTranslator() {
    const localization =
        useLocalization(
            "common.customRiskPolicy.entityMultiSelect.condition.hooks.useEntityMatchConditionGroupMembershipTypeOperatorTranslator",
            () => ({
                [Contract.EntityMatchConditionGroupMembershipTypeOperator.Direct]: "direct",
                [Contract.EntityMatchConditionGroupMembershipTypeOperator.Transitive]: "direct and indirect"
            }));
    return (type: Contract.EntityMatchConditionGroupMembershipTypeOperator) => localization[type]();
}