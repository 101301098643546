﻿import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useAzureLogicWorkflowsStatusTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useAzureLogicWorkflowsStatusTranslator",
            () => ({
                [Contract.TypeNames.AzureLogicWorkflowStatus]: {
                    [Contract.AzureLogicWorkflowStatus.Completed]: "Completed",
                    [Contract.AzureLogicWorkflowStatus.Deleted]: "Deleted",
                    [Contract.AzureLogicWorkflowStatus.Disabled]: "Disabled",
                    [Contract.AzureLogicWorkflowStatus.Enabled]: "Enabled",
                    [Contract.AzureLogicWorkflowStatus.NotSpecified]: "NotSpecified",
                    [Contract.AzureLogicWorkflowStatus.Suspended]: "Suspended"
                }
            }));
    return (status: Contract.AzureLogicWorkflowStatus) =>
        localization[Contract.TypeNames.AzureLogicWorkflowStatus][status]();
}