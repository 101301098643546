import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useWorkloadAnalysisScanStatusTranslator() {
    const localization =
        useLocalization(
            "views.customer.workloadAnalysis.hooks.useWorkloadAnalysisScanStatusTranslator",
            () => ({
                [Contract.TypeNames.WorkloadAnalysisStatus]: {
                    [Contract.WorkloadAnalysisStatus.Disabled]: "Disabled",
                    [Contract.WorkloadAnalysisStatus.NotSupported]: "Not supported",
                    [Contract.WorkloadAnalysisStatus.Scanned]: "Scanned",
                    [Contract.WorkloadAnalysisStatus.Pending]: "Pending"
                }
            }));

    return (workloadAnalysisStatus: Contract.WorkloadAnalysisStatus) =>
        localization[Contract.TypeNames.WorkloadAnalysisStatus][workloadAnalysisStatus]();
}