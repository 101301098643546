import { map } from "@infrastructure";
import { Contract, tenantModelStore } from "../../../../../../../../../../../../../../../common";
import { GcpConsoleUrlBuilder } from "../../../../../../../../../../../../../../../tenants";
import { EntityExternalConsoleLinkDefinition } from "../useDefinition";

export function useGcpSecretManagerSecretDefinition(entityModel: Contract.EntityModel, page: Contract.GcpConsolePage) {
    const secret = entityModel.entity as Contract.GcpSecretManagerSecret;
    const tenantModel = tenantModelStore.useGet(entityModel.entity.tenantId);

    const getUrl =
        map(
            page,
            {
                [Contract.GcpConsolePage.Edit]: () => GcpConsoleUrlBuilder.GetSecretManagerSecretEditUrl,
                [Contract.GcpConsolePage.Permissions]: () => undefined
            });

    return new EntityExternalConsoleLinkDefinition(
        getUrl?.(
            secret.name,
            (tenantModel.configuration as Contract.GcpTenantConfiguration).rawShortNameId));
}