import { useLocalization, useSelectionActionsContext } from "@infrastructure";
import React from "react";
import { StarAction, StarActionVariant } from "../..";
import { Contract } from "../../../../../../../common";

type UpdateStarredSelectionActionProps = {
    reloadRiskModels: (status?: Contract.RiskStatus) => Promise<void>;
};

export function UpdateStarredSelectionAction({ reloadRiskModels }: UpdateStarredSelectionActionProps) {
    const { actionEnded, actionStarted, loadedItems } = useSelectionActionsContext();

    const localization =
        useLocalization(
            "views.customer.risks.items.updateStarredSelectionAction",
            () => ({
                error: "Failed to update starred"
            }));

    return (
        <StarAction
            riskModels={loadedItems}
            variant={StarActionVariant.SelectionAction}
            onError={() => actionEnded(localization.error())}
            onStart={() => actionStarted(false)}
            onToggle={
                async () => {
                    await reloadRiskModels();
                    actionEnded();
                }}/>);
}