import { DataTableSummary, DataTableSummaryProps, Optional, UnexpectedError } from "@infrastructure";
import { Dictionary } from "lodash";
import { ReactNode, useMemo } from "react";
import { Contract, EntityTypeMetadataModelHelper } from "../../../../../../../../common";
import { DefinitionData } from "../../Table";
import { useAadDirectoryEntityDefinition, useAwsEntityDefinition, useAzureEntityDefinition, useCiEntityDefinition, useDataAnalysisResourceDefinition, useGciEntityDefinition, useGcpEntityDefinition, useOciEntityDefinition, useOktaDirectoryEntityDefinition, useOneLoginDirectoryEntityDefinition, useOpEntityDefinition, usePingIdentityDirectoryEntityDefinition } from "./hooks";

export function useDefinition(definitionData: DefinitionData) {
    const useDefinitionCore =
        useMemo(
            () => {
                switch (definitionData.entityTypeEntitiesViewName) {
                    case Contract.TypeNames.IDataAnalysisResource:
                        return useDataAnalysisResourceDefinition;
                }

                switch (EntityTypeMetadataModelHelper.get(definitionData.entityTypeEntitiesViewName).tenantType) {
                    case Contract.TenantType.Aad:
                        return useAadDirectoryEntityDefinition;
                    case Contract.TenantType.Aws:
                        return useAwsEntityDefinition;
                    case Contract.TenantType.Azure:
                        return useAzureEntityDefinition;
                    case Contract.TenantType.Ci:
                        return useCiEntityDefinition;
                    case Contract.TenantType.Gci:
                        return useGciEntityDefinition;
                    case Contract.TenantType.Gcp:
                        return useGcpEntityDefinition;
                    case Contract.TenantType.Oci:
                        return useOciEntityDefinition;
                    case Contract.TenantType.Okta:
                        return useOktaDirectoryEntityDefinition;
                    case Contract.TenantType.OneLogin:
                        return useOneLoginDirectoryEntityDefinition;
                    case Contract.TenantType.Op:
                        return useOpEntityDefinition;
                    case Contract.TenantType.PingIdentity:
                        return usePingIdentityDirectoryEntityDefinition;
                    default:
                        throw new UnexpectedError("entityTypeEntitiesViewName", definitionData.entityTypeEntitiesViewName);
                }
            },
            []);

    return useDefinitionCore(definitionData) as EntityTableDefinition<any>;
}

export type EntityTableDefinitionGetEntityModelPageRequest = (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) => Contract.EntityControllerGetEntityModelPageRequest;

export class EntityTableDefinition<EntityDataTableSummary extends DataTableSummary> {
    constructor(
        public additionalColumnElements: ReactNode[],
        public entityModelFiltersPromise: Promise<Contract.EntityModelFilters>,
        public getEntityModelRequest: EntityTableDefinitionGetEntityModelPageRequest,
        public options?: EntityTableDefinitionOptions<EntityDataTableSummary>) {
    }
}

type EntityTableDefinitionOptions<EntityDataTableSummary extends DataTableSummary>= {
    defaultSortColumnId?: Contract.EntityModelProperty;
    getRowItemUrl?: (item: Contract.EntityModel) => Optional<string>;
    initialFilterMap?: Dictionary<any>;
    summarySection?: (props: DataTableSummaryProps<EntityDataTableSummary>) => JSX.Element;
};