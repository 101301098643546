import { Contract } from "../../../../../controllers";
import { useTenantNameTranslator } from "../../../../../hooks";
import { EntityDefinition } from "../useDefinition";

export function useAzureTenantEntityDefinition(entityModel: Contract.EntityModel) {
    const tenantNameTranslator = useTenantNameTranslator();

    return new EntityDefinition(
        entityModel,
        {
            textDisplayName: tenantNameTranslator(entityModel.tenantId, { includeRawId: true })
        });
}