import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function PropertiesIcon(props: SvgIconProps) {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <path 
                clipRule="evenodd"
                d="M7 2.00049C8.86393 2.00049 10.4301 3.27538 10.874 5.00073L20 5.00049C20.5523 5.00049 21 5.4482 21 6.00049C21 6.51332 20.614 6.936 20.1166 6.99376L20 7.00049L10.8738 7.00125C10.4295 8.72609 8.86356 10.0005 7 10.0005C4.79086 10.0005 3 8.20963 3 6.00049C3 3.79135 4.79086 2.00049 7 2.00049ZM7 4.00049C5.89543 4.00049 5 4.89592 5 6.00049C5 7.10506 5.89543 8.00049 7 8.00049C8.10457 8.00049 9 7.10506 9 6.00049C9 4.89592 8.10457 4.00049 7 4.00049Z"
                fillRule="evenodd"/>
            <path 
                clipRule="evenodd"
                d="M17 14C19.2091 14 21 15.7909 21 18C21 20.2091 19.2091 22 17 22C15.1364 22 13.5705 20.7256 13.1262 19.0008L4 19C3.44772 19 3 18.5523 3 18C3 17.4872 3.38604 17.0645 3.88338 17.0067L4 17L13.126 17.0002C13.5699 15.2749 15.1361 14 17 14ZM17 16C15.8954 16 15 16.8954 15 18C15 19.1046 15.8954 20 17 20C18.1046 20 19 19.1046 19 18C19 16.8954 18.1046 16 17 16Z"
                fillRule="evenodd"/>
        </SvgIcon>);
}