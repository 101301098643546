import { Grid2, Stack } from "@mui/material";
import React, { Fragment } from "react";
import { Contract, FeatureHelper, LicensingHelper, ScopeHelper, useScopeNavigationViewContext, useTheme } from "../../../../../../../../common";
import { ExcludeUnresolvableVulnerabilities, SevereVulnerabilityMinSeverity, VulnerabilityScoreType } from "./components";

export function Vulnerabilities() {
    const { scopeNodeModel } = useScopeNavigationViewContext();
    const theme = useTheme();
    return (
        <Grid2
            size={{ xs: 8 }}
            sx={{
                height: "100%",
                maxWidth: theme.spacing(125),
                overflowY: "auto",
                padding: theme.spacing(3, 4)
            }}>
            <Stack spacing={2}>
                {FeatureHelper.enabled(Contract.FeatureName.TvdlEnabled) &&
                    scopeNodeModel.configuration.id === ScopeHelper.customerId &&
                    <VulnerabilityScoreType/>}
                {LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.Cnapp) &&
                    <Fragment>
                        <SevereVulnerabilityMinSeverity/>
                        <ExcludeUnresolvableVulnerabilities/>
                    </Fragment>}
            </Stack>
        </Grid2>);
}