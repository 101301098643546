import { DataTableColumn, DataTableColumnRenderProps, DeferredFilter, InlineItems, NoneIcon, optionalTableCell, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import { Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract, NetworkAccessScopeCell, NetworkAccessScopeFilter, NetworkInboundAccessTypeFilter, NetworkScopeFormatter, useNetworkAccessScopeTranslator } from "../../../../../../../../../common";
import { useNetworkInboundAccessTypeTranslator } from "../../../../../hooks";

export function useCommonNetworkAccessResourceDefinition() {
    const networkAccessScopeTranslator = useNetworkAccessScopeTranslator();
    const networkInboundAccessTypeTranslator = useNetworkInboundAccessTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.useCommonNetworkAccessResourceDefinition",
            () => ({
                columns: {
                    networkInboundAccessType: "Network Exposure",
                    networkInboundExternalAccessScope: "Network Exposure Scope",
                    networkInboundExternalDestinationNetworkScopes: "Exposed Ports"
                }
            }));

    function translateInboundAccessType(entityModel: Contract.EntityModel) {
        const inboundAccessType = (entityModel.entityNetwork as Contract.NetworkAccessResourceStateNetwork)?.inboundAccessType;
        return _.isNil(inboundAccessType) || inboundAccessType === Contract.NetworkInboundAccessType.Internal
            ? undefined
            : networkInboundAccessTypeTranslator(inboundAccessType);
    }

    return {
        columns: {
            accessLevelColumn:
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.EntityModel) => ({
                                [localization.columns.networkInboundAccessType()]: translateInboundAccessType(item) ?? ""
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            default: true,
                            element:
                                <NetworkInboundAccessTypeFilter placeholder={localization.columns.networkInboundAccessType()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.NetworkInboundAccessType}
                    key={Contract.EntityModelProperty.NetworkInboundAccessType}
                    render={optionalTableCell<Contract.EntityModel>(translateInboundAccessType)}
                    title={localization.columns.networkInboundAccessType()}/>,
            destinationNetworkScopesColumn:
                (entityNetworkFiltersPromise: Promise<Contract.IEntityNetworkFilters>) =>
                    <DataTableColumn
                        exportOptions={{
                            getItem:
                                (item: Contract.EntityModel) => ({
                                    [localization.columns.networkInboundExternalDestinationNetworkScopes()]:
                                    _((item.entityNetwork as Contract.NetworkAccessResourceStateNetwork)?.inboundExternalDestinationNetworkScopes ?? []).
                                        map(destinationNetworkScope => NetworkScopeFormatter.networkScopeFromDestinationNetworkScope(destinationNetworkScope)).
                                        sort().
                                        join("\n") ?? ""
                                })
                        }}
                        filterOptions={{
                            itemOrItems: {
                                element:
                                    <DeferredFilter
                                        promiseOrGetPromise={entityNetworkFiltersPromise}
                                        title={localization.columns.networkInboundExternalDestinationNetworkScopes()}>
                                        {
                                            entityNetworkFilters =>
                                                <ValuesFilter
                                                    emptyValueOptions={{ enabled: entityNetworkFilters.networkAccess.destinationNetworkScopeDataItems.emptyValue }}
                                                    placeholder={localization.columns.networkInboundExternalDestinationNetworkScopes()}>
                                                    {_.map(
                                                        entityNetworkFilters.networkAccess.destinationNetworkScopeDataItems.items,
                                                        destinationNetworkScopeData =>
                                                            <ValuesFilterItem
                                                                key={destinationNetworkScopeData.filterId}
                                                                title={NetworkScopeFormatter.networkScopeFromDestinationNetworkScope(destinationNetworkScopeData.destinationNetworkScope)}
                                                                value={destinationNetworkScopeData.filterId}/>)}
                                                </ValuesFilter>
                                        }
                                    </DeferredFilter>
                            }
                        }}
                        id={Contract.EntityModelProperty.NetworkInboundExternalDestinationNetworkScopes}
                        key={Contract.EntityModelProperty.NetworkInboundExternalDestinationNetworkScopes}
                        render={
                            ({ item }: DataTableColumnRenderProps<Contract.EntityModel>) =>
                                _.isEmpty((item.entityNetwork as Contract.NetworkAccessResourceStateNetwork)?.inboundExternalDestinationNetworkScopes)
                                    ? <NoneIcon sx={{ fontSize: "18px" }}/>
                                    : <Typography noWrap={true}>
                                        <InlineItems
                                            items={
                                                _((item.entityNetwork as Contract.NetworkAccessResourceStateNetwork)?.inboundExternalDestinationNetworkScopes).
                                                    map(destinationNetworkScope => NetworkScopeFormatter.networkScopeFromDestinationNetworkScope(destinationNetworkScope)).
                                                    sort().
                                                    value()}
                                            variant="itemPlusItemCount"/>
                                    </Typography>}
                        sortOptions={{ enabled: false }}
                        title={localization.columns.networkInboundExternalDestinationNetworkScopes()}/>,
            inboundExternalAccessScopeColumn:
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.EntityModel) => {
                                const entityNetworkInboundExternalAccessScope = (item.entityNetwork as Contract.NetworkAccessResourceStateNetwork)?.inboundExternalAccessScope;
                                return {
                                    [localization.columns.networkInboundExternalAccessScope()]:
                                        _.isNil(entityNetworkInboundExternalAccessScope) ||
                                        entityNetworkInboundExternalAccessScope === Contract.NetworkAccessScope.None
                                            ? ""
                                            : networkAccessScopeTranslator(entityNetworkInboundExternalAccessScope)
                                };
                            }
                    }}
                    filterOptions={{
                        itemOrItems: {
                            default: true,
                            element:
                                <NetworkAccessScopeFilter placeholder={localization.columns.networkInboundExternalAccessScope()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.NetworkInboundExternalAccessScope}
                    key={Contract.EntityModelProperty.NetworkInboundExternalAccessScope}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.EntityModel>) =>
                            <NetworkAccessScopeCell networkAccessScope={(item.entityNetwork as Contract.NetworkAccessResourceStateNetwork)?.inboundExternalAccessScope}/>}
                    title={localization.columns.networkInboundExternalAccessScope()}/>
        }
    };
}