import { DataTableColumnRenderProps, map, Steps, useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract, StatusCell as BaseStatusCell } from "../../../../../../../../../../../../../../../common";
import { useGciTenantModelStatusTranslator } from "../../../hooks";

export function StatusCell({ item }: DataTableColumnRenderProps<Contract.GciTenantModel>) {
    const tenantModelStatusTranslator = useGciTenantModelStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useIdentityProviderTenantItems.gciTenants.table.statusCell",
            () => ({
                serviceAccountUserDelegationMissingAuthorizationScopes: "The following OAuth scopes are missing:"
            }));

    function getServiceAccountUserDelegationMissingAuthorizationScopes() {
        return (
            _.isEmpty(item.serviceAccountUserDelegationMissingAuthorizationScopes)
                ? undefined
                : <Stack>
                    <Typography variant="h5">
                        {localization.serviceAccountUserDelegationMissingAuthorizationScopes()}
                    </Typography>
                    <Steps>
                        {_.map(
                            item.serviceAccountUserDelegationMissingAuthorizationScopes,
                            serviceAccountUserDelegationMissingAuthorizationScope =>
                                <Typography>
                                    {serviceAccountUserDelegationMissingAuthorizationScope}
                                </Typography>)}
                    </Steps>
                </Stack>);
    }

    return (
        <BaseStatusCell
            statusSeverity={item.statusSeverity}
            title={tenantModelStatusTranslator(item.status)}
            tooltip={
                map(
                    item.status,
                    {
                        [Contract.GciTenantModelStatus.AccessDenied]: () =>
                            <Typography
                                key={item.state.monitoring.analysisAccessDeniedErrorMessage}
                                sx={{ whiteSpace: "pre-wrap" }}>
                                {item.state.monitoring.analysisAccessDeniedErrorMessage}
                            </Typography>,
                        [Contract.GciTenantModelStatus.ServiceAccountUserDelegationMandatoryAuthorizationScopesMismatch]: () => getServiceAccountUserDelegationMissingAuthorizationScopes(),
                        [Contract.GciTenantModelStatus.ServiceAccountUserDelegationOptionalAuthorizationScopesMismatch]: () => getServiceAccountUserDelegationMissingAuthorizationScopes()
                    },
                    () => undefined)}/>);
}