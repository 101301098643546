﻿import { DataTable, DataTableColumn, optionalTableCell, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, InfoCard, useKubernetesTaintEffectTranslator, useTheme } from "../../../../../../../../../common";

type KubernetesTaintsInfoCardProps = {
    kubernetesTaints: Contract.KubernetesTaint[];
};

export function KubernetesTaintsInfoCard({ kubernetesTaints }: KubernetesTaintsInfoCardProps) {
    const kubernetesTaintEffectTranslator = useKubernetesTaintEffectTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.kubernetesTaintsInfoCard",
            () => ({
                columns: {
                    effect: "Effect",
                    key: "Key",
                    value: "Value"
                },
                title: "Kubernetes Taints"
            }));

    const theme = useTheme();
    return (
        <InfoCard title={localization.title()}>
            <DataTable
                fetchItems={() => kubernetesTaints}
                getItemId={(kubernetesTaint: Contract.KubernetesTaint) => _.indexOf(kubernetesTaints, kubernetesTaint).toString()}
                sortOptions={{ enabled: false }}
                sx={{ maxHeight: theme.spacing(60) }}
                variant="card">
                <DataTableColumn
                    id="key"
                    itemProperty={(kubernetesTaint: Contract.KubernetesTaint) => kubernetesTaint.key}
                    title={localization.columns.key()}/>
                <DataTableColumn
                    id="value"
                    render={
                        optionalTableCell<Contract.KubernetesTaint>(
                            kubernetesTaint =>
                                _.isEmpty(kubernetesTaint.value)
                                    ? undefined
                                    : kubernetesTaint.value)}
                    title={localization.columns.value()}/>
                <DataTableColumn
                    id="effect"
                    itemProperty={(kubernetesTaint: Contract.KubernetesTaint) => kubernetesTaintEffectTranslator(kubernetesTaint.effect)}
                    title={localization.columns.effect()}/>
            </DataTable>
        </InfoCard>);
}