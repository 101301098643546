import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, InlineEntities, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetAwsResourceRiskContext } from "./useGetAwsResourceRiskContext";

export function useGetAwsDocDbClusterRiskContext() {
    return useMemo(
        () => useAwsDocDbClusterRiskContext,
        []);
}

function useAwsDocDbClusterRiskContext(resourceModel: Contract.AwsResourceModel) {
    const docDbClusterResource = _.as<Contract.IAwsDocDbCluster>(resourceModel.entity);
    const resourceRiskContext = useGetAwsResourceRiskContext()(resourceModel);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.contexts.useGetAwsDocDbClusterRiskContext",
            () => ({
                backupRetentionTimeFrame: "The retention period is currently set to **{{backupRetentionTimeFrame | TimeSpanFormatter.day}}**",
                instances: "The DocumentDB Cluster has {{instances}}",
                size: "The {{translatedClusterTypeName}}'s size is {{storageSize | NumberFormatter.storage}}"
            }));
    const translatedClusterTypeName =
        entityTypeNameTranslator(
            docDbClusterResource.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });

    return {
        ...resourceRiskContext,
        backupRetentionTimeFrame:
            new RiskDefinitionContextItem(localization.backupRetentionTimeFrame({ backupRetentionTimeFrame: docDbClusterResource.backupRetentionTimeFrame })),
        instances:
            resourceModel.entity.typeName === Contract.TypeNames.AwsRdsDocDbCluster
                ? _.isEmpty((resourceModel as Contract.AwsRdsDocDbClusterModel).instanceIds)
                    ? undefined
                    : new RiskDefinitionContextItem(
                        localization.instances({
                            instances:
                                <InlineEntities
                                    entityIdsOrModels={(resourceModel as Contract.AwsRdsDocDbClusterModel).instanceIds}
                                    entityTypeName={Contract.TypeNames.AwsRdsDocDbDatabaseInstance}
                                    variant="itemCountAndType"/>
                        }))
                : undefined,
        size:
            new RiskDefinitionContextItem(
                localization.size({
                    storageSize: docDbClusterResource.storageSize,
                    translatedClusterTypeName
                }))
    };
}