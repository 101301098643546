import { Stack } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract, useEpssScoreTranslator, useTheme, useVulnerabilityScoreSourceTypeTranslator, VulnerabilityScoreSourceType, workloadAnalysisVulnerabilityScoreTypeStore } from "../../../..";
import { ScoreCard, ScoreCardSubtitle } from "./components";

type ScoreCardsProps = {
    variant?: "minimal" | "full";
    vulnerability: Contract.Vulnerability;
};

export function ScoreCards({ variant, vulnerability }: ScoreCardsProps) {
    const epssScoreTranslator = useEpssScoreTranslator();
    const vulnerabilityScoreSourceTypeTranslator = useVulnerabilityScoreSourceTypeTranslator();

    const vulnerabilityScoreType = workloadAnalysisVulnerabilityScoreTypeStore.useGet();
    const theme = useTheme();
    return (
        <Stack
            direction="row"
            gap={
                variant === "minimal"
                    ? theme.spacing(0.5)
                    : theme.spacing(1.5)}>
            {!_.isNil(vulnerability.cvssScore) &&
                <ScoreCard
                    color={theme.palette.severity(vulnerability.cvssSeverity)}
                    score={vulnerability.cvssScore}
                    subtitle={
                        <ScoreCardSubtitle
                            total={10}
                            value={trimNumber(vulnerability.cvssScore)}
                            variant={variant}/>}
                    sx={{
                        order:
                            vulnerabilityScoreType === Contract.VulnerabilityScoreType.Cvss
                                ? 1
                                : 2
                    }}
                    title={vulnerabilityScoreSourceTypeTranslator(VulnerabilityScoreSourceType.Cvss)}
                    totalValue={10}
                    variant={variant}/>}
            {!_.isNil(vulnerability.vprScore) &&
                <ScoreCard
                    color={theme.palette.severity(vulnerability.vprSeverity)}
                    score={vulnerability.vprScore}
                    subtitle={
                        <ScoreCardSubtitle
                            total={10}
                            value={trimNumber(vulnerability.vprScore)}
                            variant={variant}/>}
                    sx={{
                        order:
                            vulnerabilityScoreType === Contract.VulnerabilityScoreType.Cvss
                                ? 2
                                : 1
                    }}
                    title={vulnerabilityScoreSourceTypeTranslator(VulnerabilityScoreSourceType.Vpr)}
                    totalValue={10}
                    variant={variant}/>}
            {!_.isNil(vulnerability.epssScore) &&
                <ScoreCard
                    color={theme.palette.workload.epss}
                    score={trimNumber(vulnerability.epssScore)}
                    subtitle={epssScoreTranslator(vulnerability.epssScore)}
                    sx={{ order: 3 }}
                    title={vulnerabilityScoreSourceTypeTranslator(VulnerabilityScoreSourceType.Epss)}
                    totalValue={1}
                    variant={variant}/>}
        </Stack>);
}

function trimNumber(number: number, to = 2) {
    return parseFloat(number.toFixed(to));
}