import { DataTableColumn, useLocalization } from "@infrastructure";
import React from "react";
import { Contract } from "../../../../../../../../../../../common";
import { TableColumnId } from "../../../IdentityProviderTenantTable";
import { TenantDefinition } from "../useDefinition";

export function useOktaDefinition(): TenantDefinition {
    const localization =
        useLocalization(
            "views.customer.configuration.permissionManagement.identityProviderTenantTable.hooks.useDefinition.hooks.useOktaDefinition",
            () => ({
                columns: {
                    url: "Organization URL"
                }
            }));
    return new TenantDefinition([
        <DataTableColumn
            id={TableColumnId.OktaUrl}
            itemProperty={(item: Contract.OktaTenantModel) => item.configuration.url}
            key={TableColumnId.OktaUrl}
            title={localization.columns.url()}/>
    ]);
}