import _, { Dictionary } from "lodash";
import { Contract, UdmController } from "..";

export class ObjectTypeMetadataModelHelper {
    private static objectTypeNameToMetadataModelMap: Dictionary<Contract.ObjectTypeMetadataModel>;
    private static objectPropertyMap: Dictionary<Contract.UdmObjectProperty>;
    private static initialized = false;

    public static async initialize() {
        if (ObjectTypeMetadataModelHelper.initialized) {
            return;
        }

        const { objectTypeMetadataModels } = await UdmController.getObjectTypeMetadataModels();

        ObjectTypeMetadataModelHelper.objectTypeNameToMetadataModelMap =
            _.keyBy(
                objectTypeMetadataModels,
                objectTypeMetadataModel => objectTypeMetadataModel.typeName);

        ObjectTypeMetadataModelHelper.objectPropertyMap =
            _(objectTypeMetadataModels).
                map(objectTypeMetadataModel => objectTypeMetadataModel.udmProperties).
                flatMap().
                keyBy(udmProperties => udmProperties.id).
                value();

        ObjectTypeMetadataModelHelper.initialized = true;
    }

    public static get(typeName: string) {
        return ObjectTypeMetadataModelHelper.objectTypeNameToMetadataModelMap[typeName];
    }

    public static getAll() {
        return _.values(ObjectTypeMetadataModelHelper.objectTypeNameToMetadataModelMap);
    }

    public static getProperty(objectPropertyId: Contract.UdmObjectPropertyId) {
        return ObjectTypeMetadataModelHelper.objectPropertyMap[objectPropertyId];
    }
}