import { useLocalization } from "@infrastructure";
import _ from "lodash";
import { AuditEventDefinitionContext, AuditEventDefinitionDetailItem } from "..";

export function useCommonAuditEventDetailsItems(context: AuditEventDefinitionContext) {
    const localization =
        useLocalization(
            "views.customer.auditEvents.profile.hooks.useDefinition.hooks.useCommonAuditEventDetailsItems",
            () => ({
                errorMessage: "Error"
            }));
    return {
        errorMessageItem:
            _.isNil(context.auditEventModel.auditEvent.errorMessage)
                ? undefined!
                : new AuditEventDefinitionDetailItem(
                    localization.errorMessage(),
                    context.auditEventModel.auditEvent.errorMessage)
    };
}