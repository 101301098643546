import { Contract } from "../../../../../../../../../../common";
import { useGcpCommonResourceInfoItemElements } from "./useGcpCommonResourceInfoItemElements";

export function useGcpDefaultResourceInfoItemElements(resourceModel: Contract.GcpResourceModel) {
    const commonResourcesInfoElements = useGcpCommonResourceInfoItemElements(resourceModel);
    return [
        commonResourcesInfoElements.typeNameInfoElement,
        commonResourcesInfoElements.gciTenantInfoItemElement,
        resourceModel.typeName === Contract.TypeNames.GcpTenantEntityModel
            ? commonResourcesInfoElements.tenantIdInfoItemElement
            : commonResourcesInfoElements.tenantInfoItemElement,
        commonResourcesInfoElements.regionInfoItemElement,
        commonResourcesInfoElements.regionLocationInfoItemElement,
        commonResourcesInfoElements.tagsInfoItemElement,
        commonResourcesInfoElements.creationTimeInfoItemElement,
        commonResourcesInfoElements.creatorEntityInfoItemElement,
        commonResourcesInfoElements.updateTimeInfoItemElement,
        commonResourcesInfoElements.syncTimeInfoItemElement
    ];
}