import { useLocalization, useLocalizeList } from "@infrastructure";
import _ from "lodash";
import { AuditEventDefinition, AuditEventDefinitionContext, AuditEventDefinitionDetailItem } from "..";
import { Contract } from "../../../../../../../../../common";

export function useAwsPermissionPresetAuditEventDefinition(context: AuditEventDefinitionContext) {
    const localizeList = useLocalizeList();
    const localization =
        useLocalization(
            "views.customer.auditEvents.profile.hooks.useDefinition.hooks.useAwsPermissionPresetAuditEventDefinition",
            () => ({
                description: "Description",
                inlinePolicyDocument: "Policy",
                name: "Name",
                propertyChanges: "Updated properties"
            }));

    const permissionPresetAuditEvent = context.auditEventModel.auditEvent as Contract.AwsPermissionPresetAuditEvent;
    return new AuditEventDefinition(
        _<AuditEventDefinitionDetailItem>([]).
            concat(
                new AuditEventDefinitionDetailItem(
                    localization.name(),
                    permissionPresetAuditEvent.name)).
            concatIf(
                !_.isNil(permissionPresetAuditEvent.description),
                new AuditEventDefinitionDetailItem(
                    localization.description(),
                    permissionPresetAuditEvent.description!)).
            concatIf(
                permissionPresetAuditEvent.typeName === Contract.TypeNames.AwsPermissionPresetUpdateAuditEvent,
                () => {
                    const properties =
                        _.filter([
                            (permissionPresetAuditEvent as Contract.AwsPermissionPresetUpdateAuditEvent).descriptionChanged
                                ? localization.description()
                                : undefined,
                            (permissionPresetAuditEvent as Contract.AwsPermissionPresetUpdateAuditEvent).inlinePolicyDocumentChanged
                                ? localization.inlinePolicyDocument()
                                : undefined,
                            (permissionPresetAuditEvent as Contract.AwsPermissionPresetUpdateAuditEvent).nameChanged
                                ? localization.name()
                                : undefined
                        ]);
                    return (
                        _<AuditEventDefinitionDetailItem>([]).
                            concatIf(
                                !_.isEmpty(properties),
                                new AuditEventDefinitionDetailItem(
                                    localization.propertyChanges(),
                                    localizeList(properties)!)).
                            value());
                }).
            value());
}