﻿import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";
import { useTheme } from "../../../themes";

export function EuropeIcon(props: SvgIconProps) {
    const theme = useTheme();
    return (
        <SvgIcon {...props}>
            <path
                d="M5 16.3658V7.5H10.5294V8.84632H6.5401V11.2532H10.243V12.5996H6.5401V15.0195H10.5626V16.3658H5Z"
                fill={theme.palette.text.primary}/>
            <path
                d="M17.4599 7.5H19V13.2922C19 13.9271 18.8561 14.4856 18.5683 14.9675C18.2832 15.4495 17.8819 15.8261 17.3644 16.0974C16.8469 16.3658 16.2422 16.5 15.5503 16.5C14.8557 16.5 14.2496 16.3658 13.7321 16.0974C13.2146 15.8261 12.8133 15.4495 12.5282 14.9675C12.2432 14.4856 12.1007 13.9271 12.1007 13.2922V7.5H13.6408V13.158C13.6408 13.5274 13.7183 13.8564 13.8732 14.145C14.031 14.4336 14.2524 14.6602 14.5374 14.8247C14.8225 14.9863 15.1601 15.0671 15.5503 15.0671C15.9405 15.0671 16.2782 14.9863 16.5632 14.8247C16.8511 14.6602 17.0724 14.4336 17.2274 14.145C17.3824 13.8564 17.4599 13.5274 17.4599 13.158V7.5Z"
                fill={theme.palette.text.primary}/>
            <path
                clipRule="evenodd"
                d="M22 5.00227H2C1.44772 5.00227 1 5.451 1 6.00453V17.9955C1 18.549 1.44772 18.9977 2 18.9977H22C22.5523 18.9977 23 18.549 23 17.9955V6.00454C23 5.451 22.5523 5.00227 22 5.00227ZM2 4H22C23.1046 4 24 4.89746 24 6.00454V17.9955C24 19.1025 23.1046 20 22 20H2C0.89543 20 0 19.1025 0 17.9955V6.00453C0 4.89746 0.895431 4 2 4Z"
                fill={theme.palette.text.secondary}
                fillRule="evenodd"/>
        </SvgIcon>);
}