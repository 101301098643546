﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useOciDefaultResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { Contract, InfoItem, useEntityTypeNameTranslator } from "../../../../../../../../../../common";
import { EntitiesInfoCard, EntitiesInfoItem, Info } from "../../../../components";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";

export function useOciComputeInstanceConfigurationDefinition(resourceModel: Contract.OciResourceModel, options?: DefinitionOptions) {
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useOciDefaultResourceInfoItemElements(resourceModel);
    const instanceConfigurationModel = resourceModel as Contract.OciComputeInstanceConfigurationModel;
    const instanceConfiguration = instanceConfigurationModel.entity as Contract.OciComputeInstanceConfiguration;

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.oci.useOciComputeInstanceConfigurationDefinition",
            () => ({
                info: {
                    items: {
                        availabilityDomain: "Availability Domain",
                        bootSource: "Boot Source",
                        faultDomain: {
                            empty: "Automatically assigned",
                            title: "Fault Domain"
                        },
                        instanceTenant: "Instances Compartment",
                        launchMode: {
                            title: "Launch Mode",
                            [Contract.TypeNames.OciComputeInstanceConfigurationLaunchMode]: {
                                [Contract.OciComputeInstanceConfigurationLaunchMode.Custom]: "Custom",
                                [Contract.OciComputeInstanceConfigurationLaunchMode.Emulated]: "Emulated",
                                [Contract.OciComputeInstanceConfigurationLaunchMode.Native]: "Native",
                                [Contract.OciComputeInstanceConfigurationLaunchMode.ParaVirtualized]: "PARAVIRTUALIZED"
                            }
                        },
                        metadataServiceVersion1Enabled: {
                            false: "Version 2 only",
                            title: "Instance metadata service",
                            true: "Versions 1 and 2"
                        },
                        primaryPrivateIpAddress: {
                            empty: "Automatically assigned",
                            title: "Private IP Address"
                        },
                        security: {
                            enabled: {
                                false: "Disabled",
                                true: "Enabled"
                            },
                            measuredBootEnabled: "Measured Boot",
                            memoryEncryptionEnabled: "Confidential Computing",
                            secureBootEnabled: "Secure Boot",
                            tpmEnabled: "Trusted Platform Module"
                        },
                        shape: "Shape",
                        virtualNetworkInterfaceCards: "VNICs",
                        volumeEncryptionInTransitEnabled: {
                            false: "Disabled",
                            title: "In-Transit Encryption",
                            true: "Enabled"
                        }
                    }
                }
            }));

    function translateSecurityEnabled(enabled: boolean) {
        return enabled
            ? localization.info.items.security.enabled.true()
            : localization.info.items.security.enabled.false();
    }

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="shape"
                        location="all"
                        title={localization.info.items.shape()}
                        value={instanceConfiguration.shape}/>,
                    <InfoItem
                        key="availabilityDomain"
                        location="all"
                        title={localization.info.items.availabilityDomain()}
                        value={instanceConfiguration.availabilityDomain}/>,
                    <InfoItem
                        key="faultDomain"
                        title={localization.info.items.faultDomain.title()}
                        value={instanceConfiguration.faultDomain ?? localization.info.items.faultDomain.empty()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={instanceConfigurationModel.instanceTenantIdReference}
                        entityTypeName={Contract.TypeNames.OciTenantEntity}
                        key="instanceTenant"
                        title={localization.info.items.instanceTenant()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={instanceConfigurationModel.bootSourceResourceIdReference}
                        entityTypeName={Contract.TypeNames.OciResource}
                        key="bootSource"
                        title={localization.info.items.bootSource()}/>,
                    <InfoItem
                        key="launchMode"
                        title={localization.info.items.launchMode.title()}
                        value={
                            _.isNil(instanceConfiguration.launchMode)
                                ? undefined
                                : localization.info.items.launchMode[Contract.TypeNames.OciComputeInstanceConfigurationLaunchMode][instanceConfiguration.launchMode]()}/>,
                    <InfoItem
                        key="metadataServiceVersion1Enabled"
                        title={localization.info.items.metadataServiceVersion1Enabled.title()}
                        value={
                            instanceConfiguration.metadataServiceVersion1Enabled
                                ? localization.info.items.metadataServiceVersion1Enabled.true()
                                : localization.info.items.metadataServiceVersion1Enabled.false()}/>,
                    <InfoItem
                        key="volumeEncryptionInTransitEnabled"
                        title={localization.info.items.volumeEncryptionInTransitEnabled.title()}
                        value={
                            instanceConfiguration.volumeEncryptionInTransitEnabled
                                ? localization.info.items.volumeEncryptionInTransitEnabled.true()
                                : localization.info.items.volumeEncryptionInTransitEnabled.false()}/>,
                    <InfoItem
                        key="securityMemoryEncryptionEnabled"
                        title={localization.info.items.security.memoryEncryptionEnabled()}
                        value={translateSecurityEnabled(instanceConfiguration.security.memoryEncryptionEnabled)}/>,
                    <InfoItem
                        key="securityMeasuredBootEnabled"
                        title={localization.info.items.security.measuredBootEnabled()}
                        value={translateSecurityEnabled(instanceConfiguration.security.measuredBootEnabled)}/>,
                    <InfoItem
                        key="securitySecureBootEnabled"
                        title={localization.info.items.security.secureBootEnabled()}
                        value={translateSecurityEnabled(instanceConfiguration.security.secureBootEnabled)}/>,
                    <InfoItem
                        key="securityTpmEnabled"
                        title={localization.info.items.security.tpmEnabled()}
                        value={translateSecurityEnabled(instanceConfiguration.security.tpmEnabled)}/>,
                    <InfoItem
                        emptyValue={localization.info.items.primaryPrivateIpAddress.empty()}
                        key="primaryPrivateIpAddress"
                        location="all"
                        title={localization.info.items.primaryPrivateIpAddress.title()}
                        value={instanceConfiguration.primaryVirtualNetworkInterfaceCardParameters.privateIpAddress}/>
                ]}
                options={options?.infoOptions}>
                <EntitiesInfoCard
                    entityIdsOrModels={instanceConfigurationModel.instancePoolIds}
                    entityTypeName={Contract.TypeNames.OciComputeInstancePool}
                    title={
                        entityTypeNameTranslator(
                            Contract.TypeNames.OciComputeInstancePool,
                            { count: 0 })}/>
            </Info>
    });
}