﻿import { map } from "@infrastructure";
import { Contract, tenantModelStore } from "../../../../../../../../../../../../../../../common";
import { GcpConsoleUrlBuilder } from "../../../../../../../../../../../../../../../tenants";
import { EntityExternalConsoleLinkDefinition } from "../useDefinition";

export function useGcpComputeSslPolicyDefinition(entityModel: Contract.EntityModel, page: Contract.GcpConsolePage) {
    const sslPolicy = entityModel.entity as Contract.GcpComputeSslPolicy;
    const tenantModel = tenantModelStore.useGet(sslPolicy.tenantId);

    const getUrl =
        map(
            page,
            {
                [Contract.GcpConsolePage.Edit]: () => GcpConsoleUrlBuilder.GetComputeSslPolicyEditUrl,
                [Contract.GcpConsolePage.Permissions]: () => undefined
            });

    return new EntityExternalConsoleLinkDefinition(
        getUrl?.(
            sslPolicy.name,
            (tenantModel.configuration as Contract.GcpTenantConfiguration).rawShortNameId));
}