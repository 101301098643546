import { DataTableColumn, DataTableSortType, optionalTableCell, PagedValuesFilter, useLocalization } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { useAwsCommonNetworkedResourceDefinition, useAwsCommonResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, InlineEntities, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";

export function useAwsAutoScalingAutoScalingGroupDefinition(definitionData: DefinitionData) {
    const autoScalingGroupModelFiltersPromise = definitionData.entityModelFiltersPromise as Promise<Contract.AwsAutoScalingAutoScalingGroupModelFilters>;
    const commonNetworkedResourceDefinition = useAwsCommonNetworkedResourceDefinition(definitionData);
    const commonResourceDefinition = useAwsCommonResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.aws.useAwsAutoScalingAutoScalingGroupDefinition",
            () => ({
                columns: {
                    instanceIds: "Instances",
                    keyName: "Key Pair",
                    launchConfiguration: "Launch Configuration",
                    launchTemplate: "Launch Template"
                }
            }));
    return new EntityTableDefinition(
        [
            commonResourceDefinition.columns.tenantColumn,
            commonResourceDefinition.columns.creationTimeColumn,
            commonResourceDefinition.columns.creatorIdentityCsvColumn,
            commonResourceDefinition.columns.creatorOriginatorEntityCsvColumn,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.AwsAutoScalingAutoScalingGroupModel>(
                        Contract.TypeNames.AwsEc2Instance,
                        item =>
                            _.map(
                                (item.entity as Contract.AwsAutoScalingAutoScalingGroup).instanceReferences,
                                instanceReference => instanceReference.idReference),
                        localization.columns.instanceIds())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AwsAutoScalingAutoScalingGroupInstances)}
                                placeholder={localization.columns.instanceIds()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AwsAutoScalingAutoScalingGroupInstances}
                key={Contract.EntityModelProperty.AwsAutoScalingAutoScalingGroupInstances}
                render={
                    optionalTableCell<Contract.AwsAutoScalingAutoScalingGroupModel>(
                        item =>
                            _.isEmpty((item.entity as Contract.AwsAutoScalingAutoScalingGroup).instanceReferences)
                                ? undefined
                                : <InlineEntities
                                    entityIdsOrModels={
                                        _.map(
                                            (item.entity as Contract.AwsAutoScalingAutoScalingGroup).instanceReferences,
                                            instanceReference => instanceReference.idReference)}
                                    entityTypeName={Contract.TypeNames.AwsEc2Instance}
                                    entityVariant="iconText"
                                    variant="itemPlusItemCount"/>)}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.instanceIds()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.AwsAutoScalingAutoScalingGroupModel>(
                        Contract.TypeNames.AwsEc2LaunchTemplate,
                        item => [(item.entity as Contract.AwsAutoScalingAutoScalingGroup).launchTemplateRevisionReference?.launchTemplateIdReference],
                        localization.columns.launchTemplate())
                }
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AwsAutoScalingAutoScalingGroupLaunchTemplate)}
                                placeholder={localization.columns.launchTemplate()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AwsAutoScalingAutoScalingGroupLaunchTemplate}
                key={Contract.EntityModelProperty.AwsAutoScalingAutoScalingGroupLaunchTemplate}
                render={
                    optionalTableCell<Contract.AwsAutoScalingAutoScalingGroupModel>(
                        item =>
                            _.isNil((item.entity as Contract.AwsAutoScalingAutoScalingGroup).launchTemplateRevisionReference)
                                ? undefined
                                : <InlineEntities
                                    entityIdsOrModels={(item.entity as Contract.AwsAutoScalingAutoScalingGroup).launchTemplateRevisionReference!.launchTemplateIdReference}
                                    entityTypeName={Contract.TypeNames.AwsEc2LaunchTemplate}
                                    entityVariant="iconText"
                                    variant="itemPlusItemCount"/>)}
                title={localization.columns.launchTemplate()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.AwsAutoScalingAutoScalingGroupModel>(
                        Contract.TypeNames.AwsAutoScalingLaunchConfiguration,
                        item => [(item.entity as Contract.AwsAutoScalingAutoScalingGroup).launchConfigurationId],
                        localization.columns.launchConfiguration())
                }
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AwsAutoScalingAutoScalingGroupLaunchConfiguration)}
                                placeholder={localization.columns.launchConfiguration()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AwsAutoScalingAutoScalingGroupLaunchConfiguration}
                key={Contract.EntityModelProperty.AwsAutoScalingAutoScalingGroupLaunchConfiguration}
                render={
                    optionalTableCell<Contract.AwsAutoScalingAutoScalingGroupModel>(
                        item =>
                            _.isNil((item.entity as Contract.AwsAutoScalingAutoScalingGroup).launchConfigurationId)
                                ? undefined
                                : <InlineEntities
                                    entityIdsOrModels={(item.entity as Contract.AwsAutoScalingAutoScalingGroup).launchConfigurationId!}
                                    entityTypeName={Contract.TypeNames.AwsAutoScalingLaunchConfiguration}
                                    entityVariant="iconText"
                                    variant="itemPlusItemCount"/>)}
                title={localization.columns.launchConfiguration()}/>,
            commonNetworkedResourceDefinition.columns.accessLevelColumn,
            commonNetworkedResourceDefinition.columns.inboundExternalAccessScopeColumn,
            commonNetworkedResourceDefinition.columns.destinationNetworkScopesColumn(autoScalingGroupModelFiltersPromise),
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsAutoScalingAutoScalingGroupModel) => ({
                            [localization.columns.keyName()]: (item.entity as Contract.AwsAutoScalingAutoScalingGroup).keyName ?? ""
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedValuesFilter
                                getValuePage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AwsAutoScalingAutoScalingGroupKeyName)}
                                placeholder={localization.columns.keyName()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AwsAutoScalingAutoScalingGroupKeyName}
                key={Contract.EntityModelProperty.AwsAutoScalingAutoScalingGroupKeyName}
                render={optionalTableCell<Contract.AwsAutoScalingAutoScalingGroupModel>(item => (item.entity as Contract.AwsAutoScalingAutoScalingGroup).keyName)}
                title={localization.columns.keyName()}/>,
            commonNetworkedResourceDefinition.columns.getVpcsColumn(),
            commonNetworkedResourceDefinition.columns.securityGroupsColumn,
            commonNetworkedResourceDefinition.columns.getLoadBalancersColumn(
                item => (item as Contract.AwsAutoScalingAutoScalingGroupModel).loadBalancerIdReferences,
                Contract.EntityModelProperty.AwsAutoScalingAutoScalingGroupLoadBalancers),
            commonResourceDefinition.columns.regionColumn,
            commonResourceDefinition.columns.regionLocationColumn,
            commonResourceDefinition.columns.tagsColumn,
            commonResourceDefinition.columns.attributesColumn,
            commonResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAwsAutoScalingAutoScalingGroupRequest(
                new Contract.EntityControllerGetEntityModelPageAwsAutoScalingAutoScalingGroupRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsAutoScalingAutoScalingGroupInstances]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsAutoScalingAutoScalingGroupKeyName]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsAutoScalingAutoScalingGroupLaunchConfiguration]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsAutoScalingAutoScalingGroupLaunchTemplate]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsAutoScalingAutoScalingGroupLoadBalancers])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags])),
                new Contract.EntityControllerGetEntityModelPageEntityRequestNetworkAccess(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.NetworkInboundAccessType]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.NetworkInboundExternalAccessScope]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.NetworkInboundExternalDestinationNetworkScopes])),
                new Contract.EntityControllerGetEntityModelPageAwsResourceRequestNetworked(
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsNetworkedResourceSecurityGroups]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsNetworkedResourceVpcs]))));
}