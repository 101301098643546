﻿import { TimeFormatter, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, InfoItem } from "../../../../../../../../../common";
import { IUnmanagedKubernetesCluster, IUnmanagedKubernetesClusterModel } from "../../../../../../../../../common/controllers/types.generated";
import { useCommonKubernetesClusterInfoElements } from "./useCommonKubernetesClusterInfoElements";

export function useCommonUnmanagedKubernetesClusterInfoElements(clusterModel: IUnmanagedKubernetesClusterModel) {
    const cluster = _.as<IUnmanagedKubernetesCluster>(clusterModel.entity);
    const commonKubernetesClusterInfoElements = useCommonKubernetesClusterInfoElements(clusterModel);

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.useCommonUnmanagedKubernetesClusterInfoElements",
            () => ({
                info: {
                    items: {
                        creationTime: "Creation Time",
                        platformVersion: "OpenShift Version",
                        version: "Kubernetes Version"
                    }
                }
            }));

    return {
        ...commonKubernetesClusterInfoElements,
        creationTime:
            <InfoItem
                key="creationTime"
                location="all"
                title={localization.info.items.creationTime()}
                value={
                    _.isNil(cluster.data.creationTime)
                        ? undefined
                        : TimeFormatter.profileFormatDateTime(cluster.data.creationTime)}/>,
        platformVersion:
            cluster.platform === Contract.KubernetesClusterPlatform.OpenShift
                ? <InfoItem
                    key="platformVersion"
                    location="profile"
                    title={localization.info.items.platformVersion()}
                    value={cluster.data.platformVersion}/>
                : undefined,
        version:
            <InfoItem
                key="version"
                location="all"
                title={localization.info.items.version()}
                value={cluster.data.version}/>
    };
}