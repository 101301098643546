import { StringHelper, useLocalization, useQueryParameters } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Mail, MailImg, MailTable, MailTd } from ".";
import { Contract, CustomerConsoleAppUrlHelper, useTheme } from "../../../common";
import { useReportDefinitionTranslator } from "../../Customer/components/Reports/hooks";

type ReportMailQueryParameters = {
    messageExists: boolean;
    reportDefinitionId: string;
    reportId: string;
    reportScheduleCadenceInterval?: Contract.ReportScheduleCadenceInterval;
    scopeId: string;
};

export function ExportReportMail() {
    const { messageExists, reportDefinitionId, reportId, reportScheduleCadenceInterval, scopeId } = useQueryParameters<ReportMailQueryParameters>();
    const localization =
        useLocalization(
            "views.system.exportReportMail",
            () => ({
                [Contract.TypeNames.ReportScheduleCadenceInterval]: {
                    [Contract.ReportScheduleCadenceInterval.Daily]: "daily",
                    [Contract.ReportScheduleCadenceInterval.Monthly]: "monthly",
                    [Contract.ReportScheduleCadenceInterval.Weekly]: "weekly"
                },
                body: {
                    now: "Tenable Cloud Security **{{reportName}}** report is ready",
                    scheduled: "Tenable Cloud Security **{{reportName}}** {{translatedCadence}} report is ready"
                },
                link: "Download Report",
                title: "Tenable Cloud Security {{reportName}} Report"
            }));
    const reportDefinitionTranslator = useReportDefinitionTranslator();
    const theme = useTheme();
    return (
        <Mail
            backgroundColor="#EBEDF0"
            title={localization.title({ reportName: reportDefinitionTranslator(reportDefinitionId) })}>
            <tr>
                <MailTd
                    align="center"
                    style={{ padding: "70px 10px" }}>
                    <MailTable
                        bgcolor="#ffffff"
                        border="0"
                        cellPadding="0"
                        cellSpacing="0"
                        width="580">
                        <tr>
                            <MailTd align="center">
                                <MailTable
                                    align="center"
                                    border="0"
                                    cellPadding="0"
                                    cellSpacing="0"
                                    style={{ background: "linear-gradient(247.48deg, #B3D6F3 9.77%, #E9FCFF  105.63%)" }}
                                    width="580">
                                    <tr>
                                        <MailTd
                                            height="93"
                                            style={{ paddingLeft: 28 }}
                                            width="580">
                                            <MailImg
                                                alt="Company Logo"
                                                height="25"
                                                src="cid:Logo"
                                                width="271"/>
                                        </MailTd>
                                    </tr>
                                </MailTable>
                            </MailTd>
                        </tr>
                        <tr>
                            <MailTd
                                align="center"
                                style={{
                                    paddingLeft: 40,
                                    paddingRight: 40,
                                    paddingTop: 58
                                }}>
                                <MailTable
                                    align="center"
                                    border="0"
                                    cellPadding="0"
                                    cellSpacing="0"
                                    width="100%">
                                    <tr>
                                        <MailTd style={{ paddingBottom: 38 }}>
                                            <span
                                                style={{
                                                    fontSize: 16,
                                                    fontWeight: 400,
                                                    lineHeight: "20px"
                                                }}>
                                                {_.isNil(reportScheduleCadenceInterval)
                                                    ? localization.body.now({
                                                        reportName: reportDefinitionTranslator(reportDefinitionId)
                                                    })
                                                    : localization.body.scheduled({
                                                        reportName: reportDefinitionTranslator(reportDefinitionId),
                                                        translatedCadence: localization[Contract.TypeNames.ReportScheduleCadenceInterval][reportScheduleCadenceInterval]()
                                                    })}
                                            </span>
                                        </MailTd>
                                    </tr>
                                    {StringHelper.isTrue(messageExists) &&
                                        <tr>
                                            <MailTd
                                                style={{
                                                    fontSize: 14,
                                                    fontWeight: "bold",
                                                    lineHeight: "15px",
                                                    paddingBottom: 20
                                                }}>
                                                <pre
                                                    id="message"
                                                    style={{ whiteSpace: "pre-wrap" }}></pre>
                                            </MailTd>
                                        </tr>}
                                    <tr>
                                        <MailTd style={{ paddingBottom: 58 }}>
                                            <MailTable
                                                border="0"
                                                cellPadding="0"
                                                cellSpacing="0"
                                                width="100%">
                                                <tr>
                                                    <MailTd>
                                                        <MailTable
                                                            border="0"
                                                            cellPadding="0"
                                                            cellSpacing="0">
                                                            <tr>
                                                                <MailTd
                                                                    align="center"
                                                                    bgcolor={theme.palette.primary.main}
                                                                    style={{ borderRadius: "100px" }}>
                                                                    <a
                                                                        href={CustomerConsoleAppUrlHelper.getReportHistoryRelativeUrl(scopeId, reportId)}
                                                                        style={{
                                                                            border: `1px solid ${theme.palette.primary.main}`,
                                                                            borderRadius: "100px",
                                                                            color: "#ffffff",
                                                                            display: "inline-block",
                                                                            fontSize: "16px",
                                                                            fontWeight: 500,
                                                                            padding: "11px 24px",
                                                                            textDecoration: "none"
                                                                        }}>
                                                                        {localization.link()}
                                                                    </a>
                                                                </MailTd>
                                                            </tr>
                                                        </MailTable>
                                                    </MailTd>
                                                </tr>
                                            </MailTable>
                                        </MailTd>
                                    </tr>
                                </MailTable>
                            </MailTd>
                        </tr>
                    </MailTable>
                </MailTd>
            </tr>
        </Mail>);
}