import { alpha, IconButton, IconButtonProps, SxProps, useTheme } from "@mui/material";
import _ from "lodash";
import React, { ReactNode, useCallback, useMemo } from "react";
import { map, Sx } from "@infrastructure";
import { Tooltip } from "./Tooltip";

export type ActionButtonProps = {
    children: ReactNode;
    disabled?: boolean;
    onClick?: (event?: React.MouseEvent) => void | Promise<void>;
    size?: IconButtonProps["size"];
    sx?: SxProps;
    tooltip?: string;
    variant?: ActionButtonVariant;
};

export type ActionButtonVariant = "contained" | "icon" | "outlined";

export function ActionButton({ children, disabled, onClick, size = "small", sx, tooltip, variant = "icon" }: ActionButtonProps) {
    const theme = useTheme();
    const variantSx =
        useMemo(
            () =>
                map<ActionButtonVariant, SxProps>(
                    variant,
                    {
                        "contained": () => ({
                            "&:hover": {
                                backgroundColor: theme.palette.secondary.dark
                            },
                            backgroundColor: theme.palette.secondary.main,
                            color: "white",
                            fill: "white"
                        }),
                        "icon": () => ({
                            "&:hover": {
                                fill: theme.palette.text.primary
                            },
                            borderRadius: theme.spacing(0.75),
                            fill: alpha(theme.palette.text.primary, 0.7)
                        }),
                        "outlined": () => ({
                            "&:hover": {
                                background: theme.palette.action.selected,
                                fill: theme.palette.text.primary
                            },
                            background: theme.palette.action.alternate,
                            border: theme.border.primary,
                            borderRadius: theme.spacing(0.75),
                            fill: alpha(theme.palette.text.primary, 0.7)
                        })
                    }),
            [variant, theme]);

    const handleClick =
        useCallback(
            async (event: React.MouseEvent) => {
                if (!_.isNil(onClick)) {
                    await onClick(event);
                    event.stopPropagation();
                }
            },
            [onClick]);

    return (
        <Tooltip titleOrGetTitle={tooltip}>
            <IconButton
                disabled={disabled}
                size={size}
                sx={Sx.combine(variantSx, sx)}
                onClick={handleClick}>
                {children}
            </IconButton>
        </Tooltip>);
}