import { IconText } from "@infrastructure";
import { Box, CircularProgress, Drawer, Slide, Stack, Typography, useTheme } from "@mui/material";
import _ from "lodash";
import React, { Children, Fragment, ReactNode, useMemo, useState } from "react";
import { Loading, Message } from ".";
import { CloseIcon, Shadows } from "../assets";
import { useChangeEffect, useLocalization } from "../hooks";
import { defined, getChildElements, makeContextProvider } from "../utilities";

type SelectionActionsProps = {
    children: ReactNode;
    itemIds: string[];
    itemPermissions?: SelectionActionsItemPermissions;
    loadedItems: any[];
    onClear: () => void;
    permissionsTranslator?: (permissions: string[]) => string;
    visible?: boolean;
};

export class SelectionActionsItemPermissions {
    constructor(
        public all: string[],
        public common: string[]) {
    }
}

export class SelectionActionsContext {
    constructor(
        public actionEnded: (error?: string) => void,
        public actionExecuting: boolean,
        public actionStarted: (interactive?: boolean) => void,
        public itemIds: string[],
        public loadedItems: any[]) {
    }
}

export const [useSelectionActionsContext, , useSelectionActionsContextProvider] = makeContextProvider<SelectionActionsContext>();

export const selectionActionsHeight = 48;

export function SelectionActions({ children, itemIds, itemPermissions, loadedItems, onClear, permissionsTranslator, visible = true }: SelectionActionsProps) {
    const actionElements = getChildElements(Children.toArray(children), SelectionActionsAction);
    const [hasNotPermittedActions, permittedActionElements] =
        useMemo(
            () => {
                const permittedActionElements =
                    _.filter(
                        actionElements,
                        actionElement =>
                            _.isNil(actionElement.props.permissions) ||
                            !_.isNil(itemPermissions) &&
                            !_(actionElement.props.permissions).
                                intersection(itemPermissions.common).
                                isEmpty());

                const hasNotPermittedActions =
                    !_.isNil(itemPermissions) &&
                    _(actionElements).
                        difference(permittedActionElements).
                        some(
                            actionElement =>
                                !_(actionElement.props.permissions!).
                                    intersection(itemPermissions.all).
                                    isEmpty());
                return [hasNotPermittedActions, permittedActionElements];
            },
            [children, itemPermissions]);

    const [actionExecuting, setActionExecuting] = useState(false);
    const [actionError, setActionError] = useState<string>();
    const [, , ContextProvider] =
        useSelectionActionsContextProvider(
            () =>
                new SelectionActionsContext(
                    error => {
                        setActionError(error);
                        setActionExecuting(false);
                    },
                    actionExecuting,
                    interactive => {
                        setActionError(undefined);
                        if (!interactive) {
                            setActionExecuting(true);
                        }
                    },
                    itemIds,
                    loadedItems),
            [actionExecuting, itemIds, loadedItems]);

    useChangeEffect(
        () => {
            setActionExecuting(false);
            setActionError(undefined);
        },
        [itemIds]);

    const localization =
        useLocalization(
            "infrastructure.selectionActions",
            () => ({
                selection: {
                    hasNotPermittedActions: "You can only perform a limited number of actions since you are a {{translatedPermissions}} on some of the accounts.",
                    itemCount: "{{itemCount | NumberFormatter.humanize}} selected"
                }
            }));

    const theme = useTheme();
    return (
        <ContextProvider>
            <Slide
                direction="up"
                in={visible && !_.isEmpty(itemIds)}
                mountOnEnter={true}
                unmountOnExit={true}>
                <Drawer
                    anchor="bottom"
                    open={visible && !_.isEmpty(itemIds)}
                    PaperProps={{
                        sx: {
                            alignItems: "center",
                            backgroundColor: theme.palette.background.alternate,
                            borderRadius: theme.spacing(0.75),
                            borderTop: "unset",
                            bottom: theme.spacing(3),
                            boxShadow: theme.shadows[Shadows.SelectionActions],
                            display: "flex",
                            left: "50%",
                            maxWidth: "95%",
                            position: "fixed",
                            transform: "translateX(-50%) !important",
                            width: "fit-content",
                            zIndex: theme.zIndex.snackbar
                        }
                    }}
                    transitionDuration={100}
                    variant="persistent">
                    <Stack
                        alignItems="center"
                        direction="row"
                        justifyContent="space-between"
                        sx={{
                            border: theme.border.primary,
                            borderRadius: theme.spacing(0.75),
                            height: theme.px(selectionActionsHeight),
                            overflow: "auto",
                            padding: theme.spacing(0, 1),
                            width: "100%"
                        }}>
                        <IconText
                            containerSx={{
                                "&:hover": {
                                    backgroundColor: theme.palette.action.alternateHover
                                },
                                borderRadius: theme.spacing(0.75),
                                cursor: "pointer",
                                flexDirection: "row-reverse",
                                justifyContent: "flex-end",
                                marginRight: theme.spacing(12.5),
                                minWidth: "100px",
                                padding: theme.spacing(0.75, 1)
                            }}
                            icon={<CloseIcon/>}
                            iconSx={{ fontSize: "20px" }}
                            spacing={0}
                            text={
                                <Typography
                                    noWrap={true}
                                    sx={{
                                        flex: "unset",
                                        fontSize: "14px",
                                        fontWeight: 500,
                                        lineHeight: 1
                                    }}>
                                    {localization.selection.itemCount({ itemCount: itemIds.length })}
                                </Typography>}
                            onClick={onClear}/>
                        <Stack
                            alignItems="center"
                            flexDirection="row"
                            sx={{ height: "100%" }}>
                            {hasNotPermittedActions &&
                                !_.isNil(permissionsTranslator) && (
                                <Box
                                    sx={{
                                        flex: 1,
                                        overflow: "hidden",
                                        paddingLeft: theme.spacing(3.5)
                                    }}>
                                    <Typography
                                        noWrap={true}
                                        sx={{ fontSize: "15px" }}>
                                        {localization.selection.hasNotPermittedActions({ translatedPermissions: permissionsTranslator(itemPermissions!.common) })}
                                    </Typography>
                                </Box>)}
                            {actionExecuting && (
                                <CircularProgress
                                    size={theme.spacing(2)}
                                    variant="indeterminate"/>)}
                            {!_.isNil(actionError) && (
                                <Message
                                    level="error"
                                    title={actionError}
                                    variant="minimal"/>)}
                            {_(permittedActionElements).
                                map(
                                    permittedActionElement =>
                                        <Loading
                                            container="cell-medium"
                                            key={permittedActionElement.props.id}>
                                            {permittedActionElement.props.children}
                                        </Loading>).
                                value()}
                        </Stack>
                    </Stack>
                </Drawer>
            </Slide>
        </ContextProvider>);
}

export type SelectionActionsActionProps = {
    children: ReactNode;
    id: string;
    permissions?: string[];
};

export function SelectionActionsAction(props: SelectionActionsActionProps) {
    defined(props);
    return <Fragment/>;
}