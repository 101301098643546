import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract } from "../../../../../../..";
import { Entity } from "../../../../../..";

export function useAwsDeletePermissionSetChangeDefinition(change: Contract.AwsChange, entityLinkDisabled?: boolean) {
    const permissionSetChange = change as Contract.AwsResourceChange;
    const localization =
        useLocalization(
            "common.change.hooks.useDefinition.hooks.useAwsDefinition.hooks.useAwsDeletePermissionSetChangeDefinition",
            () => ({
                [Contract.TypeNames.AwsDeletePermissionSetChange]: "Delete {{permissionSet}}",
                [Contract.TypeNames.AwsDeletePermissionSetInlinePolicyChange]: "Delete {{permissionSet}} custom policy"
            }));
    return {
        title:
            localization.translate(
                permissionSetChange.typeName,
                {
                    permissionSet:
                        <Entity
                            entityIdOrModel={permissionSetChange.resourceId}
                            glanceOptions={{ disabled: true }}
                            linkOptions={{ disabled: entityLinkDisabled }}
                            variant="typeText"/>
                })
    };
}