import { CopyToClipboardText, formatFilterQueryParameters, IconText, Link, setUrlRoute, useLocalization, ValuesFilterSelection } from "@infrastructure";
import { Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract, CustomerConsoleAppUrlHelper, InfoCard, InfoItem, RiskCountsChart, ScanResult, scopeSystemEntityModelStore, VerticalTopItemsInfoItem } from "../../../../../../../../../common";
import { CodeIntegrationIcon, CodeTypeIcon, useCodeIntegrationTranslator, useCodeTypeTranslator } from "../../../../../../../../../tenants";
import { ProfileCategory } from "../profileCategory";

type InfoProps = {
    scan: Contract.CodeIacScan;
};

export function Info({ scan }: InfoProps) {
    const codeIntegrationConfiguration = scopeSystemEntityModelStore.useGet(scan.integrationId).configuration as Contract.CodeIntegrationConfiguration;

    const codeTypeTranslator = useCodeTypeTranslator();
    const codeIntegrationTranslator = useCodeIntegrationTranslator();

    const localization =
        useLocalization(
            "views.customer.code.scans.scan.info",
            () => ({
                codeTypes: {
                    all: "All",
                    title: "Framework"
                },
                commitIdentityName: "User",
                integration: "Integration",
                path: "Path",
                pipelineExecutionId: "Pipeline Run",
                riskSeverityToCountMap: "Findings",
                success: {
                    false: "Failed",
                    title: "Result",
                    true: "Passed"
                }
            }));

    return (
        <InfoCard columns={true}>
            <InfoItem
                title={localization.path()}
                value={
                    <Typography
                        noWrap={true}
                        sx={{ direction: "rtl" }}>
                        {scan.relativePath}
                    </Typography>}/>
            <InfoItem
                title={localization.integration()}
                value={
                    <IconText
                        icon={
                            <CodeIntegrationIcon
                                configuration={codeIntegrationConfiguration}
                                sx={{ fontSize: "18px" }}/>}
                        text={<CopyToClipboardText text={codeIntegrationConfiguration.name}/>}
                        titleOrGetTitle={codeIntegrationTranslator(codeIntegrationConfiguration)}/>}/>
            <InfoItem
                title={localization.commitIdentityName()}
                value={scan.commit.identityName}/>
            <InfoItem
                title={localization.riskSeverityToCountMap()}
                value={
                    <RiskCountsChart
                        itemMap={scan.stats.riskSeverityToCountMap}
                        severityVariant="initialAndCount"
                        onClick={
                            severity => {
                                const queryParameters =
                                    formatFilterQueryParameters("filterMap", {
                                        [Contract.CodeControllerGetIacScanPageRequestProperty.Severity]:
                                            new ValuesFilterSelection(
                                                false,
                                                [severity])
                                    });
                                setUrlRoute(`${(CustomerConsoleAppUrlHelper.getCodeScanProfileHashUrl(scan.id, ProfileCategory.Findings))}?${queryParameters}`);
                            }}/>}/>
            {_(Contract.CodeType).
                values().
                difference(scan.codeTypes).
                isEmpty()
                ? <InfoItem
                    title={localization.codeTypes.title()}
                    value={localization.codeTypes.all()}/>
                : <VerticalTopItemsInfoItem
                    items={
                        _.map(
                            scan.codeTypes,
                            codeType =>
                                <IconText
                                    icon={
                                        <CodeTypeIcon
                                            codeType={codeType}
                                            sx={{ fontSize: "18px" }}/>}
                                    text={
                                        <Typography noWrap={true}>
                                            {codeTypeTranslator(codeType)}
                                        </Typography>}/>)}
                    title={localization.codeTypes.title()}/>}
            <InfoItem
                title={localization.pipelineExecutionId()}
                value={
                    _.isNil(scan.pipeline)
                        ? undefined
                        : <Link
                            urlOrGetUrl={scan.pipeline.executionUrl}
                            variant="external">
                            {scan.pipeline.executionId}
                        </Link>}/>
            <InfoItem
                title={localization.success.title()}
                value={
                    <ScanResult
                        failed={localization.success.false()}
                        passed={localization.success.true()}
                        success={scan.success}/>}/>
        </InfoCard>);
}