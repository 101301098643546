import { EnumValuesFilter } from "@infrastructure";
import React from "react";
import { Contract, useActionRiskCategoryTranslator } from "..";

type ActionRiskCategoryFilterProps = {
    placeholder: string;
};

export function ActionRiskCategoryFilter({ placeholder }: ActionRiskCategoryFilterProps) {
    const actionRiskCategoryTranslator = useActionRiskCategoryTranslator();
    return (
        <EnumValuesFilter
            enumType={Contract.ActionRiskCategory}
            enumTypeTranslator={(actionRiskCategory: Contract.ActionRiskCategory) => actionRiskCategoryTranslator(actionRiskCategory).title}
            placeholder={placeholder}/>);
}