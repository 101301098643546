import { Link, Steps, useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import React from "react";
import { CustomerConsoleAppUrlHelper } from "../../../../../../../../../../../../../../../../../../../common";
import { useAddOrEditContext } from "../../../../../AddOrEdit";

export function ScanContainerImageTaskItem() {
    const { apiRootUrl } = useAddOrEditContext();

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCodePipelineItems.codePipeline.addOrEdit.hooks.useDefinition.hooks.useAzurePipelines.scanContainerImageTaskItem",
            () => ({
                description: {
                    link: "Azure Pipeline",
                    text: "To scan your container images, add the following task to your {{azurePipelinesLink}}:"
                },
                note: {
                    link: "product documentation",
                    text: "Once the action is triggered, you should start seeing scan results under **Workload Protection -> CI/CD Scans**. Please refer to the {{docsLink}} for more details"
                },
                steps: {
                    step1: "Log into Azure DevOps and click **Pipelines**",
                    step2: "Select the relevant pipeline, and then click on **Edit**",
                    step3: "Select the stage where you would like to add the task",
                    step4: "In the task assistant, search for and click the **Tenable Cloud Security Container Image Scan** task",
                    step5: {
                        step1: "**API Token: $(TENABLE_API_TOKEN)**",
                        step2: "**API URL: {{apiUrl}}**",
                        step3: "**Name: <place your image name here>**",
                        step4: {
                            link: "README file",
                            title: "Use any Advanced settings as needed (refer to the {{readmeFileLink}} for more details)"
                        },
                        title: "To add the task, type these values and then click **Add**:"
                    },
                    step6: "**Save** the updated pipeline YAML"
                }
            }));

    return (
        <Stack spacing={2}>
            <Typography sx={{ whiteSpace: "pre-wrap" }}>
                {localization.description.text({
                    azurePipelinesLink:
                        <Link
                            sx={{ textDecorationLine: "underline" }}
                            urlOrGetUrl="https://learn.microsoft.com/en-us/azure/devops/pipelines/get-started/what-is-azure-pipelines?view=azure-devops"
                            variant="external">
                            {localization.description.link()}
                        </Link>
                })}
            </Typography>
            <Steps variant="letters">
                {localization.steps.step1()}
                {localization.steps.step2()}
                {localization.steps.step3()}
                {localization.steps.step4()}
                <Stack>
                    <Typography>
                        {localization.steps.step5.title()}
                    </Typography>
                    <Steps variant="plainNumbers">
                        {localization.steps.step5.step1()}
                        {localization.steps.step5.step2({ apiUrl: apiRootUrl })}
                        {localization.steps.step5.step3()}
                        {localization.steps.step5.step4.title({
                            readmeFileLink:
                                <Link
                                    sx={{ textDecorationLine: "underline" }}
                                    urlOrGetUrl="https://marketplace.visualstudio.com/items?itemName=Ermetic.ermetic"
                                    variant="external">
                                    {localization.steps.step5.step4.link()}
                                </Link>
                        })}
                    </Steps>
                </Stack>
                {localization.steps.step6()}
            </Steps>
            <Typography>
                {localization.note.text({
                    docsLink:
                        <Link
                            sx={{ textDecorationLine: "underline" }}
                            urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsContainerImageCICDScanningAddAzurePipelinesRelativeUrl()}
                            variant="external">
                            {localization.note.link()}
                        </Link>
                })}
            </Typography>
        </Stack>);
}