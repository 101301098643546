import { UnexpectedError, useChangeEffect, useLocalization } from "@infrastructure";
import { Box, List, ListItem, Stack, Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { useState } from "react";
import { Contract, EntityPropertyPatternHelper, useEntityTypeNameTranslator } from "../../../../../../../../../../common";
import { EntityPropertyPattern } from "./components";

type EntityTypeNamePropertyPatternProps = {
    entityPropertyPatternTypeNames: string[];
    entityTypeName: string;
    onPropertyPatternItemsChanged: (items: EntityTypeNamePropertyPatternItem[]) => void;
    onValidChanged: (valid: boolean) => void;
    propertyPatternItems: EntityTypeNamePropertyPatternItem[];
    propertyPatternKeyToScopeIdsMap: Dictionary<string[]>;
    readOnly: boolean;
};

export function EntityTypeNamePropertyPattern({ entityPropertyPatternTypeNames, entityTypeName, onPropertyPatternItemsChanged, onValidChanged, propertyPatternItems, propertyPatternKeyToScopeIdsMap, readOnly }: EntityTypeNamePropertyPatternProps) {
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.riskPolicies.configuration.entityExclusion.entityTypeNamePropertyPattern",
            () => ({
                patternInfo: "Supported pattern operators:\n* indicates zero or more characters.\n? indicates a single character.",
                subtitle: "Exclude {{translatedEntityTypeName}} by their name, tag or other properties. Choose the property and enter a pattern you would like to exclude the {{translatedEntityTypeName}} by. If the pattern matches the property value, Tenable Cloud Security will exclude the {{translatedEntityTypeName}}."
            }));

    function isItemDuplicate(items: EntityTypeNamePropertyPatternItem[], key: string): boolean {
        return _(items).
            filter(item => item.key === key).
            size() > 1;
    }

    function getPatternItemKeyToDuplicateMap() {
        return _(propertyPatternItems).
            keyBy(propertyPatternItem => propertyPatternItem.key).
            mapValues(propertyPatternItem => isItemDuplicate(propertyPatternItems, propertyPatternItem.key)).
            value();
    }

    const [patternItemKeyToDuplicateMap, setPatternItemKeyToDuplicateMap] = useState(getPatternItemKeyToDuplicateMap);
    useChangeEffect(
        () => setPatternItemKeyToDuplicateMap(getPatternItemKeyToDuplicateMap),
        [propertyPatternItems],
        500);

    return (
        <Stack
            spacing={2}
            sx={{ height: "100%" }}>
            <Stack spacing={1}>
                <Typography sx={{ maxWidth: "55%" }}>
                    {localization.subtitle({
                        translatedEntityTypeName:
                            entityTypeNameTranslator(
                                entityTypeName,
                                {
                                    count: 0,
                                    includeServiceName: false,
                                    variant: "text"
                                })
                    })}
                </Typography>
            </Stack>
            <Typography sx={{ whiteSpace: "pre-wrap" }}>
                {localization.patternInfo()}
            </Typography>
            <Box
                sx={{
                    flex: 1,
                    overflowY: "auto"
                }}>
                <List disablePadding={true}>
                    {_(propertyPatternItems).
                        map(
                            propertyPatternItem =>
                                <ListItem
                                    key={propertyPatternItem.id}
                                    sx={{ paddingLeft: 0 }}>
                                    <EntityPropertyPattern
                                        duplicate={patternItemKeyToDuplicateMap[propertyPatternItem.key]}
                                        propertyPatternItem={propertyPatternItem}
                                        propertyPatternTypeNames={entityPropertyPatternTypeNames}
                                        readOnly={readOnly}
                                        scopeIds={
                                            propertyPatternItem.dirty
                                                ? undefined
                                                : propertyPatternKeyToScopeIdsMap[propertyPatternItem.key]}
                                        onDelete={
                                            () => {
                                                const items =
                                                    _.without(
                                                        propertyPatternItems,
                                                        propertyPatternItem);

                                                onValidChanged(
                                                    _.every(
                                                        items,
                                                        item => item.isValid()) &&
                                                    _(items).
                                                        uniqBy(item => item.key).
                                                        size() === _.size(items));

                                                onPropertyPatternItemsChanged(items);
                                            }}
                                        onPropertyPatternItemChanged={
                                            item => {
                                                const itemIndex =
                                                    _.findIndex(
                                                        propertyPatternItems,
                                                        currentPropertyPatternItems => currentPropertyPatternItems.id == propertyPatternItem.id);
                                                propertyPatternItems.splice(itemIndex, 1, item);
                                                const newItems = [...propertyPatternItems];
                                                onValidChanged(
                                                    _.every(
                                                        propertyPatternItems,
                                                        item => item.isValid()) &&
                                                    _(propertyPatternItems).
                                                        uniqBy(item => item.key).
                                                        size() === _.size(propertyPatternItems));

                                                onPropertyPatternItemsChanged(newItems);
                                            }}/>
                                </ListItem>).
                        concatIf(
                            !readOnly,
                            <ListItem
                                key="empty"
                                sx={{ paddingLeft: 0 }}>
                                <EntityPropertyPattern
                                    duplicate={false}
                                    propertyPatternItem={undefined}
                                    propertyPatternTypeNames={entityPropertyPatternTypeNames}
                                    readOnly={false}
                                    scopeIds={undefined}
                                    onPropertyPatternItemChanged={
                                        item => {
                                            const newItems = [...propertyPatternItems, item];
                                            onPropertyPatternItemsChanged(newItems);

                                            onValidChanged(
                                                _.every(
                                                    newItems,
                                                    item => item.isValid()) &&
                                                _(newItems).
                                                    uniqBy(item => item.key).
                                                    size() === _.size(newItems));
                                        }}/>
                            </ListItem>).
                        value()}
                </List>
            </Box>
        </Stack>);
}

export class EntityTypeNamePropertyPatternItem {
    private static _idCounter = 0;
    public dirty: boolean;
    public id: number;
    public key: string;

    constructor(
        public propertyPattern: Contract.RiskPolicyConfigurationEntityExclusionEntityPropertyPattern,
        dirty?: boolean,
        id?: number) {
        this.dirty = dirty ?? false;
        this.key = EntityPropertyPatternHelper.getExclusionEntityPropertyPatternKey(propertyPattern);
        this.id = id ?? EntityTypeNamePropertyPatternItem._idCounter++;
    }

    public isValid(): boolean {
        switch (this.propertyPattern.typeName) {
            case Contract.TypeNames.RiskPolicyConfigurationEntityExclusionAwsResourceArnPattern:
                return !_.isEmpty((this.propertyPattern as Contract.RiskPolicyConfigurationEntityExclusionAwsResourceArnPattern).arnPattern);
            case Contract.TypeNames.RiskPolicyConfigurationEntityExclusionEntityNamePattern:
                return !_.isEmpty((this.propertyPattern as Contract.RiskPolicyConfigurationEntityExclusionEntityNamePattern).namePattern);
            case Contract.TypeNames.RiskPolicyConfigurationEntityExclusionResourceTagPattern:
                return (
                    !_.isEmpty((this.propertyPattern as Contract.RiskPolicyConfigurationEntityExclusionResourceTagPattern).resourceTagPattern.keyPattern) &&
                    !_.isEmpty((this.propertyPattern as Contract.RiskPolicyConfigurationEntityExclusionResourceTagPattern).resourceTagPattern.valuePattern));
            default:
                throw new UnexpectedError("ExcludedEntityPropertyPattern.typeName", this.propertyPattern.typeName);
        }
    }
}