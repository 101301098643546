import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../../../../../../../../../common";

export function useOneLoginTenantModelStatusTranslator() {
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useIdentityProviderTenantItems.oneLoginTenants.hooks.useOneLoginTenantModelStatusTranslator",
            () => ({
                [Contract.TypeNames.OneLoginTenantModelStatus]: {
                    [Contract.OneLoginTenantModelStatus.ClientAuthenticationFailure]: "Disconnected",
                    [Contract.OneLoginTenantModelStatus.Deleting]: "Deleting, this may take a while...",
                    [Contract.OneLoginTenantModelStatus.Valid]: "Connected"
                }
            }));
    return (status: Contract.OneLoginTenantModelStatus) =>
        localization[Contract.TypeNames.OneLoginTenantModelStatus][status]();
}