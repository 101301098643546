﻿import { DataTableColumnExportOptions, Optional, UnexpectedError } from "@infrastructure";
import { Dictionary, Function1, Function2 } from "lodash";
import { ReactNode, useMemo } from "react";
import { Contract } from "../../../../../../../../common";
import { useAadDefinition, useAwsDefinition, useAzureDefinition, useGciDefinition, useGcpDefinition, useOktaDefinition, useOneLoginDefinition, usePingIdentityDefinition } from "./hooks";

export function useDefinition(filters: Contract.PermissionManagementControllerPermissionEligibilityFilters, tenantType: Contract.TenantType): TenantDefinition {
    const useDefinition =
        useMemo(
            () => {
                switch (tenantType) {
                    case Contract.TenantType.Aad:
                        return useAadDefinition;
                    case Contract.TenantType.Aws:
                        return useAwsDefinition;
                    case Contract.TenantType.Azure:
                        return useAzureDefinition;
                    case Contract.TenantType.Gci:
                        return useGciDefinition;
                    case Contract.TenantType.Gcp:
                        return useGcpDefinition;
                    case Contract.TenantType.Okta:
                        return useOktaDefinition;
                    case Contract.TenantType.OneLogin:
                        return useOneLoginDefinition;
                    case Contract.TenantType.PingIdentity:
                        return usePingIdentityDefinition;
                    default:
                        throw new UnexpectedError("tenantType", tenantType);
                }
            },
            []);

    return useDefinition(filters);
}

export class TenantDefinition {
    constructor(
        public createGetPermissionEligibilityModelPageRequest: (
            commonFilters: Contract.PermissionManagementControllerGetPermissionEligibilityModelPageRequestFilters,
            filterMap: Dictionary<any>,
            limit: number,
            scope: Contract.PermissionManagementControllerPermissionEligibilityRequestScope,
            skip: number,
            sort: Optional<Contract.PermissionManagementControllerGetPermissionEligibilityModelPageRequestSort>) => Contract.PermissionManagementControllerGetPermissionEligibilityModelPageRequest,
        public getColumns: (getEntitiesExportOptions: Function2<Function1<Contract.PermissionEligibilityModel, Optional<string>[]>, string, DataTableColumnExportOptions>) => ReactNode[]) {
    }
}