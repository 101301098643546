﻿import { useLocalization } from "@infrastructure";
import { Contract } from "../../../common";

export function useCiTenantTypeTranslator() {
    const localization =
        useLocalization(
            "tenants.ci.hooks.useCiTenantTypeTranslator",
            () => ({
                [Contract.TypeNames.CiTenantType]: {
                    [Contract.CiTenantType.Cloudsmith]: "Cloudsmith",
                    [Contract.CiTenantType.General]: "Registry",
                    [Contract.CiTenantType.Jfrog]: "Jfrog"
                }
            }));
    return (ciTenantType: Contract.CiTenantType) =>
        localization[Contract.TypeNames.CiTenantType][ciTenantType]();
}