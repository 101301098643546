﻿import { useLocalization } from "@infrastructure";
import { Contract } from "../../../common";

export function useAwsApiGatewayApiRouteMethodTranslator() {
    const localization =
        useLocalization(
            "tenants.aws.hooks.useAwsApiGatewayApiRouteMethodTranslator",
            () => ({
                [Contract.TypeNames.AwsApiGatewayApiRouteMethod]: {
                    [Contract.AwsApiGatewayApiRouteMethod.Any]: "Any",
                    [Contract.AwsApiGatewayApiRouteMethod.Delete]: "Delete",
                    [Contract.AwsApiGatewayApiRouteMethod.Get]: "Get",
                    [Contract.AwsApiGatewayApiRouteMethod.Head]: "Head",
                    [Contract.AwsApiGatewayApiRouteMethod.Options]: "Options",
                    [Contract.AwsApiGatewayApiRouteMethod.Patch]: "Patch",
                    [Contract.AwsApiGatewayApiRouteMethod.Post]: "Post",
                    [Contract.AwsApiGatewayApiRouteMethod.Put]: "Put"
                }
            }));
    return (method: Contract.AwsApiGatewayApiRouteMethod) =>
        localization[Contract.TypeNames.AwsApiGatewayApiRouteMethod][method]();
}