﻿import React from "react";

export function CaIcon() {
    return (
        <svg
            fill="none"
            height={50}
            viewBox="0 0 50 50"
            width={50}>
            <path
                d="M25.1029 49.5417C38.7834 49.5417 49.8737 38.4514 49.8737 24.7708C49.8737 11.0903 38.7834 0 25.1029 0C11.4223 0 0.332031 11.0903 0.332031 24.7708C0.332031 38.4514 11.4223 49.5417 25.1029 49.5417Z"
                fill="#F0F0F0"/>
            <path
                d="M49.872 24.7709C49.872 14.9502 44.1568 6.46469 35.8711 2.45801V47.0837C44.1568 43.0771 49.872 34.5916 49.872 24.7709Z"
                fill="#D80027"/>
            <path
                d="M0.332031 24.7709C0.332031 34.5916 6.04722 43.0771 14.333 47.0838V2.45801C6.04722 6.46469 0.332031 14.9502 0.332031 24.7709Z"
                fill="#D80027"/>
            <path
                d="M29.4122 28.0018L33.7201 25.8478L31.5662 24.7709V22.6169L27.2582 24.7709L29.4122 20.4629H27.2582L25.1042 17.2319L22.9502 20.4629H20.7962L22.9502 24.7709L18.6422 22.6169V24.7709L16.4883 25.8478L20.7962 28.0018L19.7192 30.1558H24.0272V33.3868H26.1811V30.1558H30.4891L29.4122 28.0018Z"
                fill="#D80027"/>
        </svg>);
}