import { TimeHelper, useLocalization } from "@infrastructure";
import React, { useMemo } from "react";
import { Contract, Entity, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetOciResourceRiskContext } from "./useGetOciResourceRiskContext";

export function useGetOciIamCredentialRiskContext() {
    return useMemo(
        () => useOciIamCredentialRiskContext,
        []);
}

function useOciIamCredentialRiskContext(credentialModel: Contract.OciIamUserCredentialModel) {
    const credential = credentialModel.entity as Contract.OciIamUserCredential;
    const resourceRiskContext = useGetOciResourceRiskContext()(credentialModel);
    const entityTypeNameTranslator = useEntityTypeNameTranslator();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.oci.hooks.contexts.useGetOciIamCredentialRiskContext",
            () => ({
                belongsTo: "The {{translatedKeyTypeName}} belongs to the {{userLink}}",
                creationTime: "The {{translatedKeyTypeName}} was created **{{creationTime | TimeSpanFormatter.humanizeDays}} ago**"
            }));

    const translatedKeyTypeName =
        entityTypeNameTranslator(
            credential.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });

    return {
        ...resourceRiskContext,
        belongsTo:
            new RiskDefinitionContextItem(
                localization.belongsTo(
                    {
                        translatedKeyTypeName,
                        userLink:
                            <Entity
                                entityIdOrModel={credential.userId}
                                entityTypeNameTranslatorOptions={{ variant: "text" }}
                                variant="typeText"/>
                    })),
        creationTime:
            new RiskDefinitionContextItem(
                localization.creationTime(
                    {
                        creationTime: TimeHelper.getInterval(credentialModel.creationTime!),
                        translatedKeyTypeName
                    }))
    };
}