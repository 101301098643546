import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function UnignoreIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                clipRule="evenodd"
                d="M21.4837 3.34098C21.8541 2.93127 21.8222 2.29891 21.4124 1.92856C21.0027 1.55822 20.3704 1.59014 20 1.99986L18.1266 4.09593C16.4341 2.78217 14.3084 2.00001 12 2.00001C6.47715 2.00001 2 6.47716 2 12C2 14.7112 3.07893 17.1704 4.83084 18.9716L3.00992 21.0089C2.63958 21.4186 2.67149 22.051 3.08121 22.4213C3.49092 22.7917 4.12328 22.7598 4.49362 22.35L6.36165 20.26C7.96687 21.3579 9.90845 22 12 22C17.5228 22 22 17.5229 22 12C22 9.50614 21.0871 7.22549 19.5773 5.47401L21.4837 3.34098ZM16.7897 5.59168C15.4544 4.59206 13.7963 4.00001 12 4.00001C7.58172 4.00001 4 7.58173 4 12C4 14.1185 4.82344 16.0446 6.16773 17.4759L11.0022 12.0669C11.0007 12.0448 11 12.0225 11 12V7.00001C11 6.44772 11.4477 6.00001 12 6.00001C12.5523 6.00001 13 6.44772 13 7.00001V9.8317L16.7897 5.59168ZM13.6311 12.1267L18.2302 6.98114C19.3372 8.35362 20 10.0994 20 12C20 16.4183 16.4183 20 12 20C10.421 20 8.94876 19.5425 7.70873 18.7529L12.2961 13.6204L14.6421 15.9745C15.032 16.3657 15.6651 16.3667 16.0563 15.9769C16.4475 15.587 16.4486 14.9539 16.0588 14.5627L13.6311 12.1267Z"
                fillRule="evenodd"/>
        </SvgIcon>);
}