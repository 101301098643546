import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, InfoItem, VerticalTopItemsInfoItem } from "../../../../../../../../../../common";
import { EntitiesInfoItem } from "../../../../components";
import { useCommonEntityNetworkInfoItemElements } from "../useCommonEntityNetworkInfoItemElements";

interface AzureCommonNetworkingInfoItemElementsProps {
    macAddress?: string;
    networkInterfaceIds?: string[];
    resourceModel: Contract.AzureResourceGroupResourceModel;
}

export function useAzureCommonNetworkingInfoItemElements({ macAddress, networkInterfaceIds, resourceModel }: AzureCommonNetworkingInfoItemElementsProps) {
    const commonEntityNetworkInfoItemElements = useCommonEntityNetworkInfoItemElements(resourceModel);
    const networkedResource = _.as<Contract.IAzureNetworkedResource>(resourceModel.entity);

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.azure.useAzureCommonNetworkingInfoItemElements",
            () => ({
                interfaceNetworkSecurityGroup: "Interface Network Security Groups",
                ipAddresses: {
                    private: "Private IP Address",
                    public: "Public IP Address"
                },
                macAddress: "MAC Address",
                subnetNetworkSecurityGroup: "Subnet Network Security Groups"
            }));

    return {
        ...commonEntityNetworkInfoItemElements,
        getApplicationSecurityGroups:
            () =>
                <EntitiesInfoItem
                    entityIdsOrModels={networkedResource.applicationSecurityGroupIds}
                    entityTypeName={Contract.TypeNames.AzureNetworkApplicationSecurityGroup}
                    key={Contract.TypeNames.AzureNetworkApplicationSecurityGroup}/>,
        getInterfaceNetworkSecurityGroups:
            () =>
                <EntitiesInfoItem
                    entityIdsOrModels={networkedResource.interfaceNetworkSecurityGroupIds}
                    entityTypeName={Contract.TypeNames.AzureNetworkNetworkSecurityGroup}
                    key={Contract.TypeNames.AzureNetworkNetworkSecurityGroup}
                    title={localization.interfaceNetworkSecurityGroup()}/>,
        getMacAddress:
            () =>
                <InfoItem
                    key="macAddress"
                    title={localization.macAddress()}
                    value={macAddress}/>,
        getNetworkInterfaces:
            () =>
                <EntitiesInfoItem
                    entityIdsOrModels={networkInterfaceIds}
                    entityTypeName={Contract.TypeNames.AzureNetworkNetworkInterface}
                    key={Contract.TypeNames.AzureNetworkNetworkInterface}/>,
        getPrivateIpAddresses:
            () =>
                <VerticalTopItemsInfoItem
                    items={networkedResource.privateIpAddresses}
                    key="privateIpAddress"
                    title={localization.ipAddresses.private()}/>,
        getPublicIpAddresses:
            () =>
                <EntitiesInfoItem
                    entityIdsOrModels={networkedResource.publicIpAddressIds}
                    entityTypeName={Contract.TypeNames.AzureNetworkPublicIpAddress}
                    key="publicIpAddress"
                    title={localization.ipAddresses.public()}/>,
        getSubnetNetworkSecurityGroupIds:
            () =>
                <EntitiesInfoItem
                    entityIdsOrModels={networkedResource.subnetNetworkSecurityGroupIds}
                    entityTypeName={Contract.TypeNames.AzureNetworkNetworkSecurityGroup}
                    key="subnetNetworkSecurityGroup"
                    title={localization.subnetNetworkSecurityGroup()}/>,
        getSubnets:
            () =>
                <EntitiesInfoItem
                    entityIdsOrModels={networkedResource.subnetIds}
                    entityTypeName={Contract.TypeNames.AzureNetworkVirtualNetworkSubnet}
                    key={Contract.TypeNames.AzureNetworkVirtualNetworkSubnet}/>,
        getVirtualNetwork:
            () =>
                <EntitiesInfoItem
                    entityIdsOrModels={networkedResource?.virtualNetworkId}
                    entityTypeName={Contract.TypeNames.AzureNetworkVirtualNetwork}
                    key={Contract.TypeNames.AzureNetworkVirtualNetwork}/>
    };
}