﻿import { Link, Steps, useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import React from "react";
import { useTheme } from "../../../../../../../../../../../../../../../../../../../common";

export function InstallItem() {
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCodePipelineItems.codePipeline.addOrEdit.hooks.useDefinition.hooks.useAzurePipelines.installItem",
            () => ({
                steps: {
                    step1: {
                        link: "Azure DevOps extension",
                        title: "Go to the {{extensionLink}}"
                    },
                    step2: "Click **Get it free**",
                    step3: "Select the Azure DevOps organization, and then click **Install**",
                    step4: "Click **Proceed to organization** to go to your Azure DevOps site"
                },
                title: "Install Tenable Cloud Security’s Azure DevOps Extension:"
            }));

    const theme = useTheme();
    return (
        <Stack
            spacing={2}
            sx={{ maxWidth: theme.spacing(58) }}>
            <Typography>
                {localization.title()}
            </Typography>
            <Steps variant="letters">
                {localization.steps.step1.title({
                    extensionLink:
                        <Link
                            sx={{ textDecorationLine: "underline" }}
                            urlOrGetUrl="https://marketplace.visualstudio.com/items?itemName=Ermetic.ermetic"
                            variant="external">
                            {localization.steps.step1.link()}
                        </Link>
                })}
                {localization.steps.step2()}
                {localization.steps.step3()}
                {localization.steps.step4()}
            </Steps>
        </Stack>);
}