﻿import { useLocalization } from "@infrastructure";

export function useGcpIamServiceAccountDisabledTranslator() {
    const localization =
        useLocalization(
            "tenants.gcp.hooks.useGcpIamServiceAccountDisabledTranslator",
            () => ({
                false: "Enabled",
                true: "Disabled"
            }));
    return (disabled: boolean) =>
        disabled
            ? localization.true()
            : localization.false();
}