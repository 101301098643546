import { InlineItems, Message, Steps, useLocalization } from "@infrastructure";
import { Stack } from "@mui/material";
import _ from "lodash";
import React from "react";
import { useTheme } from "../../../../../../../../../../../../../common";
import { useAddOrEditContext } from "../../../AddOrEdit";


export function TerraformStateBucketMessage() {
    const { tenantModel } = useAddOrEditContext();

    const localization =
        useLocalization(
            "views.customer.scopes.hooks.useDefinition.hooks.useAwsDefinition.addOrEdit.permissionsItem.terraformStateBucketMessage",
            () => ({
                terraformStateBucketNameToEncryptionKeyArnsMap: {
                    bucketWithKeys: "{{terraformStateBucketName}}: {{terraformStateBucketEncryptionKeyArns}}",
                    encryptionKeyNamePluralizer: [
                        "1 encryption key",
                        "{{count | NumberFormatter.humanize}} encryption keys"
                    ],
                    title: "We have identified the following buckets that contain Terraform state data associated with your onboarded accounts:"
                }
            }));

    const theme = useTheme();
    return (
        <Stack
            spacing={2}
            sx={{ paddingLeft: theme.spacing(5) }}>
            <Stack
                spacing={1}
                sx={{
                    border: theme.border.primary,
                    borderRadius: theme.spacing(0.75),
                    padding: theme.spacing(1)
                }}>
                <Message
                    level="info"
                    title={localization.terraformStateBucketNameToEncryptionKeyArnsMap.title()}/>
                <Steps sx={{ paddingLeft: theme.spacing(2) }}>
                    {_.map(
                        tenantModel!.state.codeAnalysis.terraformStateBucketNameToEncryptionKeyArnsMap,
                        (terraformStateBucketEncryptionKeyArns, terraformStateBucketName) =>
                            _.isEmpty(terraformStateBucketEncryptionKeyArns)
                                ? terraformStateBucketName
                                : localization.terraformStateBucketNameToEncryptionKeyArnsMap.bucketWithKeys({
                                    terraformStateBucketEncryptionKeyArns:
                                        <InlineItems
                                            items={terraformStateBucketEncryptionKeyArns}
                                            namePluralizer={localization.terraformStateBucketNameToEncryptionKeyArnsMap.encryptionKeyNamePluralizer}
                                            variant="itemCountAndType"/>,
                                    terraformStateBucketName
                                }))}
                </Steps>
            </Stack>
        </Stack>);
}