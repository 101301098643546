import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useAzureEventHubNamespaceEventHubStatusTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useAzureEventHubNamespaceEventHubStatusTranslator",
            () => ({
                [Contract.TypeNames.AzureEventHubNamespaceEventHubStatus]: {
                    [Contract.AzureEventHubNamespaceEventHubStatus.Active]: "Active",
                    [Contract.AzureEventHubNamespaceEventHubStatus.Creating]: "Creating",
                    [Contract.AzureEventHubNamespaceEventHubStatus.Deleting]: "Deleting",
                    [Contract.AzureEventHubNamespaceEventHubStatus.Disabled]: "Disabled",
                    [Contract.AzureEventHubNamespaceEventHubStatus.ReceiveDisabled]: "ReceiveDisabled",
                    [Contract.AzureEventHubNamespaceEventHubStatus.Renaming]: "Renaming",
                    [Contract.AzureEventHubNamespaceEventHubStatus.Restoring]: "Restoring",
                    [Contract.AzureEventHubNamespaceEventHubStatus.SendDisabled]: "SendDisabled",
                    [Contract.AzureEventHubNamespaceEventHubStatus.Unknown]: "Unknown"
                }
            }));

    return (status: Contract.AzureEventHubNamespaceEventHubStatus) =>
        localization[Contract.TypeNames.AzureEventHubNamespaceEventHubStatus][status]();
}