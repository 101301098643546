import { DataTableColumn, DataTableColumnRenderProps, EnumValuesFilter, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import { Dictionary } from "lodash";
import React from "react";
import { useOciCommonResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions, useEntityTypeNameTranslator } from "../../../../../../../../../../common";
import { useOciNetworkingNatGatewayStatusTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";

export function useOciNetworkingNatGatewayDefinition(definitionData: DefinitionData) {
    const commonResourceDefinition = useOciCommonResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const natGatewayStatusTranslator = useOciNetworkingNatGatewayStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.oci.useOciNetworkingNatGatewayDefinition",
            () => ({
                columns: {
                    blockTraffic: {
                        false: "Disabled",
                        title: "Block Traffic",
                        true: "Enabled"
                    },
                    status: "State"
                }
            }));

    return new EntityTableDefinition(
        [
            commonResourceDefinition.columns.tenantColumn,
            commonResourceDefinition.columns.creationTimeColumn,
            commonResourceDefinition.columns.creatorCsvColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.OciNetworkingNatGatewayModel) => ({
                            [localization.columns.status()]: natGatewayStatusTranslator((item.entity as Contract.OciNetworkingNatGateway).status)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.OciNetworkingNatGatewayStatus}
                                enumTypeTranslator={natGatewayStatusTranslator}
                                placeholder={localization.columns.status()}/>
                    }
                }}
                id={Contract.EntityModelProperty.OciNetworkingNatGatewayStatus}
                itemProperty={(item: Contract.OciNetworkingNatGatewayModel) => natGatewayStatusTranslator((item.entity as Contract.OciNetworkingNatGateway).status)}
                key={Contract.EntityModelProperty.OciNetworkingNatGatewayStatus}
                title={localization.columns.status()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.OciNetworkingNatGatewayModel) => ({
                            [localization.columns.blockTraffic.title()]:
                                (item.entity as Contract.OciNetworkingNatGateway).blockTraffic
                                    ? localization.columns.blockTraffic.true()
                                    : localization.columns.blockTraffic.false()
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        default: true,
                        element:
                            <ValuesFilter placeholder={localization.columns.blockTraffic.title()}>
                                <ValuesFilterItem
                                    title={localization.columns.blockTraffic.false()}
                                    value={false}/>
                                <ValuesFilterItem
                                    title={localization.columns.blockTraffic.true()}
                                    value={true}/>
                            </ValuesFilter>
                    }
                }}
                id={Contract.EntityModelProperty.OciNetworkingNatGatewayBlockTraffic}
                itemProperty={
                    (item: Contract.EntityModel) =>
                        (item.entity as Contract.OciNetworkingNatGateway).blockTraffic
                            ? localization.columns.blockTraffic.true()
                            : localization.columns.blockTraffic.false()}
                key={Contract.EntityModelProperty.OciNetworkingNatGatewayBlockTraffic}
                title={localization.columns.blockTraffic.title()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.OciNetworkingNatGatewayModel>(
                        Contract.TypeNames.OciNetworkingVcn,
                        item => [item.vcnIdReference],
                        entityTypeNameTranslator(Contract.TypeNames.OciNetworkingVcn))}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.OciNetworkingNatGatewayVcn)}
                                placeholder={entityTypeNameTranslator(Contract.TypeNames.OciNetworkingVcn)}/>
                    }
                }}
                id={Contract.EntityModelProperty.OciNetworkingNatGatewayVcn}
                key={Contract.EntityModelProperty.OciNetworkingNatGatewayVcn}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.OciNetworkingNatGatewayModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.vcnIdReference}
                            entityTypeName={Contract.TypeNames.OciNetworkingVcn}/>}
                title={entityTypeNameTranslator(Contract.TypeNames.OciNetworkingVcn)}/>,
            commonResourceDefinition.columns.regionColumn,
            commonResourceDefinition.columns.regionLocationColumn,
            commonResourceDefinition.columns.tagsColumn,
            commonResourceDefinition.columns.attributesColumn,
            commonResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageOciNetworkingNatGatewayRequest(
                new Contract.EntityControllerGetEntityModelPageOciNetworkingNatGatewayRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciNetworkingNatGatewayBlockTraffic]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciNetworkingNatGatewayStatus]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciNetworkingNatGatewayVcn])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags]))));
}