import _ from "lodash";
import { useMemo } from "react";
import { Contract } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useEntityRiskContext } from "../../../useEntityRiskContext";
import { useResourceGeneralInformationStep } from "../../../useResourceGeneralInformationStep";

export function useGetAzureResourceRiskContext() {
    return useMemo(
        () => useAzureResourceModelRiskContext,
        []);
}

function useAzureResourceModelRiskContext(resourceModel: Contract.AzureResourceModel) {
    const entityRiskContext = useEntityRiskContext(resourceModel);
    const resourceGeneralInformationStep = useResourceGeneralInformationStep(resourceModel);

    return {
        ...entityRiskContext,
        generalInformation:
            _.isNil(resourceGeneralInformationStep)
                ? undefined
                : new RiskDefinitionContextItem(resourceGeneralInformationStep)
    };
}