﻿import { NotValidIcon, SuccessIcon, Tooltip } from "@infrastructure";
import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { Contract, useTheme } from "../../../../../common";
import { useSystemKubernetesClusterAdmissionControllerStatusTooltipTranslator, useSystemKubernetesClusterAdmissionControllerStatusTranslator } from "../../../../../tenants";

type KubernetesClusterAdmissionControllerStatusProps = {
    status: Contract.SystemKubernetesClusterModelHelmComponentStatus;
};

export function KubernetesClusterAdmissionControllerStatus({ status }: KubernetesClusterAdmissionControllerStatusProps) {
    const kubernetesClusterAdmissionControllerStatusTooltipTranslator = useSystemKubernetesClusterAdmissionControllerStatusTooltipTranslator();
    const kubernetesClusterAdmissionControllerStatusTranslator = useSystemKubernetesClusterAdmissionControllerStatusTranslator();
    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            direction="row"
            flexWrap="nowrap"
            spacing={2}
            sx={{ width: "inherit" }}>
            <Tooltip titleOrGetTitle={kubernetesClusterAdmissionControllerStatusTooltipTranslator(status)}>
                <Stack
                    alignItems="center"
                    direction="row"
                    spacing={1}
                    sx={{
                        overflow: "hidden",
                        width: "fit-content"
                    }}>
                    <Box sx={{ fontSize: "18px" }}>
                        {(() => {
                            switch (status) {
                                case Contract.SystemKubernetesClusterModelHelmComponentStatus.NotInstalled:
                                    return <NotValidIcon sx={{ color: theme.palette.severity(Contract.Severity.Information) }}/>;
                                case Contract.SystemKubernetesClusterModelHelmComponentStatus.NotRunning:
                                    return <NotValidIcon sx={{ color: theme.palette.severity(Contract.Severity.Medium) }}/>;
                                case Contract.SystemKubernetesClusterModelHelmComponentStatus.Running:
                                    return <SuccessIcon sx={{ color: theme.palette.success.main }}/>;
                            }
                        })()}
                    </Box>
                    <Typography noWrap={true}>
                        {kubernetesClusterAdmissionControllerStatusTranslator(status)}
                    </Typography>
                </Stack>
            </Tooltip>
        </Stack>);
}