import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition, useGetAzureStorageStorageAccountRiskContext } from "../../..";
import { Contract, Entity, entityModelStore, tenantModelStore } from "../../../../../../../../../../../../common";
import { EntityExternalConsoleLink, ResourcesExternalConsoleLink } from "../../components";
import { useGetAzureStorageStorageAccountBlobContainerRiskContext } from "../contexts";

export function useAzureInsightsDiagnosticSettingsStorageAccountBlobContainerPublicAccessEnabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AzureInsightsDiagnosticSettingsStorageAccountBlobContainerPublicAccessEnabledRisk;
    const blobContainerModel = entityModelStore.useGet(risk.entityId) as Contract.AzureStorageStorageAccountBlobContainerModel;
    const storageAccountModel =
        blobContainerModel.unknown
            ? undefined
            : entityModelStore.useGet((blobContainerModel.entity as Contract.AzureStorageStorageAccountBlobContainer).storageAccountId) as Contract.AzureStorageStorageAccountModel;
    const tenantConfiguration = tenantModelStore.useGet(riskModel.tenantId).configuration as Contract.AzureTenantConfiguration;
    const aadTenantConfiguration = tenantModelStore.useGet(tenantConfiguration.aadTenantId).configuration as Contract.AadTenantConfiguration;

    const getAzureStorageStorageAccountBlobContainerRiskContext = useGetAzureStorageStorageAccountBlobContainerRiskContext();
    const getAzureStorageStorageAccountRiskContext = useGetAzureStorageStorageAccountRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.compliance.useAzureInsightsDiagnosticSettingsStorageAccountBlobContainerPublicAccessEnabledRiskDefinition",
            () => ({
                description: "{{blobContainer}} allows anonymous read access",
                sections: {
                    resolution: {
                        step1: "Before changing the access level, verify that applications can access the blob container without public access",
                        step2: "Click the **Change access level** button on the top",
                        step3: "Select **Private**",
                        step4: "Click **OK** to confirm"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            blobContainer:
                <Entity
                    entityIdOrModel={risk.entityId}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            blobContainerModel.unknown
                ? <ResourcesExternalConsoleLink
                    partitionType={aadTenantConfiguration.partitionType}
                    typeName={Contract.TypeNames.AzureStorageStorageAccount}/>
                : <EntityExternalConsoleLink
                    entityId={risk.entityId}
                    key={risk.entityId}/>,
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4()],
        riskModel,
        () => {
            const blobContainerRiskContext = getAzureStorageStorageAccountBlobContainerRiskContext(blobContainerModel);
            const storageAccountRiskContext = getAzureStorageStorageAccountRiskContext(storageAccountModel!);
            return [
                blobContainerRiskContext.generalInformation,
                blobContainerRiskContext.storageAccount,
                blobContainerRiskContext.effectivePublicAccess,
                blobContainerRiskContext.sensitive,
                storageAccountRiskContext.sensitive,
                blobContainerRiskContext.highestDataSensitivity,
                blobContainerRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)];
        });
}