import { StorageHelper as InfrastructureStorageHelper } from "@infrastructure";
import { CodeView, ComplianceView, KubernetesView, ScopesViewType, WorkloadAnalysisView } from "../../views/Customer/components";
import { AutomationsView, EntitiesView } from "../../views/Customer/components/Configuration/components";
import { ConfigurationView } from "../../views/Customer/components/Configuration/utilities";
import { ProfileCategory as CodeProfileCategory } from "../../views/Customer/components/WorkloadAnalysis/components/Code/components/Scans/components/Profile";
import { Contract } from "../controllers";
import { RiskPoliciesView } from "./riskPoliciesHelper";

export class StorageHelper {
    public static apiControllerScopeId = (consoleAppType: Contract.ConsoleAppType) => InfrastructureStorageHelper.getItem(`${consoleAppType}:apiControllerScopeId`);

    public static customerAccessAwsFederationColumnOrder = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.Access}:AwsFederation:ColumnOrder`);

    public static customerAccessAwsFederationColumnSelector = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.Access}:AwsFederation:ColumnSelector`);

    public static customerAccessAwsFederationFilters = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.Access}:awsFederationFilters`);

    public static customerAccessDashboardExcessivePermissionTrendsWidgetExcessivePermissionPrincipalRiskCategoryChartRiskCategories = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.Access}:Dashboard:excessivePermissionTrendsCardExcessivePermissionPrincipalRiskCategoryChartRiskCategories`);

    public static customerAccessDashboardExcessivePermissionTrendsWidgetInactivePrincipalRiskCategoryChartRiskCategories = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.Access}:Dashboard:excessivePermissionTrendsCardInactivePrincipalRiskCategoryChartRiskCategories`);

    public static customerAccessDashboardExcessivePermissionTrendsWidgetSeverities = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.Access}:Dashboard:excessivePermissionTrendsCardSeverities`);

    public static customerAccessDashboardExcessivePermissionTrendsWidgetTimeFrame = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.Access}:Dashboard:excessivePermissionTrendsCardTimeFrame`);

    public static customerAccessDashboardPrincipalsWidgetSeverities = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.Access}:Dashboard:principalsCardSeverities`);

    public static customerAccessPermissionQueryColumnOrder = (permissionsModelEntityType: string) => InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.Access}:${permissionsModelEntityType}:ColumnOrder`);

    public static customerAccessPermissionQueryColumnSelector = (permissionsModelEntityType: string) => InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.Access}:${permissionsModelEntityType}:ColumnSelector`);

    public static customerAccessPermissionsEntitiesFilters = (entityType: Contract.AccessControllerGetPermissionsModelEntityType) => InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.Access}:${entityType}:filters`);

    public static customerAccessPrincipalsColumnOrder = (principalsView: string) => InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.Access}:${principalsView}:ColumnOrder`);

    public static customerAccessPrincipalsColumnSelector = (principalsView: string) => InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.Access}:${principalsView}:ColumnSelector`);

    public static customerAccessToolbarFilters = (tenantType: Contract.TenantType, graphDirection?: Contract.EntityAccessDirection) => InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.Access}:Toolbar:${tenantType}:${graphDirection}:Filters`);

    public static customerAuditEventsColumnOrder = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.AuditEvents}:columnOrder`);

    public static customerAuditEventsFilters = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.AuditEvents}:filters`);

    public static customerCodeDashboardRisksWidgetSeverities = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.Code}:${Contract.CustomerConsoleAppView.Dashboard}:risksWidgetSeverities`);

    public static customerCodeRiskPoliciesTableColumnOrder = (view: RiskPoliciesView) => InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.Code}:${Contract.CustomerConsoleAppView.RiskPolicies}:${view}:columnOrder`);

    public static customerCodeRiskPoliciesTableColumnSelector = (view: RiskPoliciesView) => InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.Code}:${Contract.CustomerConsoleAppView.RiskPolicies}:${view}:columnSelector`);

    public static customerCodeRiskPoliciesTableFilters = (view: RiskPoliciesView) => InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.Code}:${Contract.CustomerConsoleAppView.RiskPolicies}:${view}:filters`);

    public static customerCodeRisksColumnOrder = (view: Contract.RisksView) => InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.Code}:${CodeView.Risks}:${view}:columnOrder`);

    public static customerCodeRisksColumnSelector = (view: Contract.RisksView) => InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.Code}:${CodeView.Risks}:${view}:columnSelector`);

    public static customerCodeRisksGroupColumnOrder = (view: Contract.RisksView, groupId: string) => InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.Code}:${CodeView.Risks}:${view}:${groupId}:columnOrder`);

    public static customerCodeRisksGroupColumnSelector = (view: Contract.RisksView, groupId: string) => InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.Code}:${CodeView.Risks}:${view}:${groupId}:columnSelector`);

    public static customerCodeRisksItemsGroupingType = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.Code}:${CodeView.Risks}:itemsGroupingType`);

    public static customerCodeRisksProfileTabsOpen = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.Code}:${CodeView.Risks}:profileTabsOpen`);

    public static customerCodeScansColumnOrder = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.Code}:columnOrder`);

    public static customerCodeScansColumnSelector = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.Code}:columnSelector`);

    public static customerCodeScansFilters = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.Code}:filters`);

    public static customerCodeScansProfileSelectedTab = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.Code}:profileSelectedTab`);

    public static customerConfigurationApiKeysFilters = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.Configuration}:apiKeysFilters`);

    public static customerConfigurationApiKeysFlatView = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.Configuration}:${ConfigurationView.ApiKeys}:flatView`);

    public static customerConfigurationAutomationsAuditEventsFlatView = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.Configuration}:${ConfigurationView.Automations}:${AutomationsView.AuditEvents}:flatView`);

    public static customerConfigurationAutomationsRiskFlatView = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.Configuration}:${ConfigurationView.Automations}:${AutomationsView.RiskPolicies}:flatView`);

    public static customerConfigurationEntitiesCustomEntityAttributeDefinitionsFlatView = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.Configuration}:${ConfigurationView.Entities}:${EntitiesView.CustomEntityAttributeDefinitions}:flatView`);

    public static customerConfigurationEntitiesEntityPropertiesFlatView = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.Configuration}:${ConfigurationView.Entities}:${EntitiesView.EntityProperties}:flatView`);

    public static customerConfigurationEntityPropertiesFilters = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.Configuration}:entityPropertiesFilters`);

    public static customerConfigurationIntegrationsFlatView = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.Configuration}:${ConfigurationView.Integrations}:flatView`);

    public static customerConfigurationPrincipalRoleAssignmentsFlatView = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.Configuration}:principalRoleAssignmentsFlatView`);

    public static customerConfigurationPrincipalsFilters = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.Configuration}:principalsFilters`);

    public static customerConfigurationSettingTableFlatView = (typeName: string) => InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.Configuration}${typeName}:settingTableFlatView`);

    public static customerConfigurationTabsOpen = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.Configuration}:tabsOpen`);

    public static customerComplianceCustomTableFlatView = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.Compliance}:${ComplianceView.Custom}:flatView`);

    public static customerExploreFilterDetailsVisible = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.UdmObjects}:filterDetailsVisible`);

    public static customerCustomersIdToViewTimeMap = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.Customers}:viewTime`);

    public static customerDashboardRisksWidgetSeverities = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.Dashboard}:risksWidgetSeverities`);

    public static customerEntitiesColumnOrder = (entityType: string) => InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.Entities}:${entityType}:columnOrder`);

    public static customerEntitiesColumnSelector = (entityType: string) => InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.Entities}:${entityType}:columnSelector`);

    public static customerEntitiesProfileSelectedTab = (entityType: string) => InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.Entities}:${entityType}:profileSelectedTab`);

    public static customerEntitiesProfileTabsOpen = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.Entities}:profileTabsOpen`);

    public static customerEntitiesSelectedTableView = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.Entities}:selectedEntityType`);

    public static customerEntitiesViewFilters = (entityTypeEntitiesViewName: string, variant: string) => InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.Entities}:${entityTypeEntitiesViewName}:${variant}:filters`);

    public static customerEntitiesViewSelectedTypeName = (tenantType: string) => InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.Entities}:${tenantType}:selectedTypeName`);

    public static customerEventsColumnOrder = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.Events}:columnOrder`);

    public static customerEventsColumnSelector = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.Events}:columnSelector`);

    public static customerEventsFilters = (variant: string) => InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.Events}:${variant}:filters`);

    public static customerEventsProfileSelectedTab = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.Events}:profileSelectedTab`);

    public static customerEventsProfileTabsOpen = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.Events}:profileTabsOpen`);

    public static customerExcessivePermissionsTableFilters = (selectedPrincipalRiskCategory: string) => InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.Access}:${selectedPrincipalRiskCategory}:excessivePermissionsTableFilters`);

    public static customerGraphApiExplorerTheme = InfrastructureStorageHelper.getItem("graphiql:theme");

    public static customerKubernetesClusterAdmissionControllerEventsColumnOrder = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.Kubernetes}:${KubernetesView.KubernetesClusterAdmissionControllerEvents}:columnOrder`);

    public static customerKubernetesClusterAdmissionControllerEventsColumnSelector = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.Kubernetes}:${KubernetesView.KubernetesClusterAdmissionControllerEvents}:columnSelector`);

    public static customerKubernetesClusterAdmissionControllerEventsFilters = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.Kubernetes}:${KubernetesView.KubernetesClusterAdmissionControllerEvents}:filters`);

    public static customerKubernetesClusterAdmissionControllerEventsProfileSelectedTab = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.Kubernetes}:${KubernetesView.KubernetesClusterAdmissionControllerEvents}:profileSelectedTab`);

    public static customerKubernetesClusterAdmissionControllerEventsProfileTabsOpen = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.Kubernetes}:${KubernetesView.KubernetesClusterAdmissionControllerEvents}:profileTabsOpen`);

    public static customerKubernetesSystemKubernetesClustersColumnOrder = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.Kubernetes}:${KubernetesView.SystemKubernetesClusters}:columnOrder`);

    public static customerKubernetesSystemKubernetesClustersColumnSelector = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.Kubernetes}:${KubernetesView.SystemKubernetesClusters}`);

    public static customerNetworkToolbarFilters = (tenantType: Contract.TenantType) => InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:networkToolbar:${tenantType}:filters`);

    public static customerPermissionsTableFilters = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.Access}:permissionsTableFilters`);

    public static customerReportsHistoryColumnOrder = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.Reports}:historyColumnOrder`);

    public static customerReportsHistoryFlatView = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.Reports}:historyFlatView`);

    public static customerReportsHistoryTableFilters = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.Reports}:historyFilters`);

    public static customerReportsScheduleColumnOrder = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.Reports}:scheduleColumnOrder`);

    public static customerReportsScheduleFlatView = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.Reports}:scheduleFlatView`);

    public static customerReportsScheduleTableFilters = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.Reports}:scheduleFilters`);

    public static customerRisksColumnOrder = (view: Contract.RisksView) => InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.Risks}:${view}:columnOrder`);

    public static customerRisksColumnSelector = (view: Contract.RisksView) => InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.Risks}:${view}:columnSelector`);

    public static customerRisksEntityProfileFilters = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.Risks}:entityProfileFilters`);

    public static customerRisksGroupColumnOrder = (view: Contract.RisksView, groupId: string) => InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.Risks}:${view}:${groupId}:columnOrder`);

    public static customerRisksGroupColumnSelector = (view: Contract.RisksView, groupId: string) => InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.Risks}:${view}:${groupId}:columnSelector`);

    public static customerRisksItemsGroupingType = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.Risks}:itemsGroupingType`);

    public static customerKubernetesAdmissionControllerRiskPoliciesTableColumnOrder = (view: RiskPoliciesView) => InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.Kubernetes}:${KubernetesView.KubernetesAdmissionControllerRiskPolicies}:${view}:columnOrder`);

    public static customerKubernetesAdmissionControllerRiskPoliciesTableColumnSelector = (view: RiskPoliciesView) => InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.Kubernetes}:${KubernetesView.KubernetesAdmissionControllerRiskPolicies}:${view}:columnSelector`);

    public static customerKubernetesAdmissionControllerRiskPoliciesTableFilters = (view: RiskPoliciesView) => InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.Kubernetes}:${KubernetesView.KubernetesAdmissionControllerRiskPolicies}:${view}:filters`);

    public static customerRiskPoliciesTableColumnOrder = (view: RiskPoliciesView) => InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.RiskPolicies}:${view}:columnOrder`);

    public static customerRiskPoliciesTableColumnSelector = (view: RiskPoliciesView) => InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.RiskPolicies}:${view}:columnSelector`);

    public static customerRiskPoliciesTableFilters = (view: RiskPoliciesView) => InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.RiskPolicies}:${view}:filters`);

    public static customerRiskPoliciesTableFlatView = (view: RiskPoliciesView) => InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.RiskPolicies}:${view}:flatView`);

    public static customerRisksProfileTabsOpen = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.Risks}:profileTabsOpen`);

    public static customerRisksViewFilters = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.Risks}:viewFilters`);

    public static customerScopesColumnOrder = (view: ScopesViewType) => InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.Scopes}:${view}:columnOrder`);

    public static customerScopesColumnSelector = (view: ScopesViewType) => InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.Scopes}:${view}:columnSelector`);

    public static customerScopesTableFilters = (view: ScopesViewType) => InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.Scopes}:${view}:filters`);

    public static customerScopesTableFlatView = (view: ScopesViewType) => InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.Scopes}:${view}:flatView`);

    public static customerSidebarOpen = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:sidebarOpen`);

    public static customerSideViewFullWidth = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:sideViewFullWidth`);

    public static customerVulnerabilityReportContainerImageFilters = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.WorkloadAnalysis}:${Contract.CustomerConsoleAppView.Reports}:${Contract.VulnerabilityReportType.ContainerImages}:filters`);

    public static customerVulnerabilityReportVirtualMachineFilters = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.WorkloadAnalysis}:${Contract.CustomerConsoleAppView.Reports}:${Contract.VulnerabilityReportType.VirtualMachines}:filters`);

    public static customerWorkloadAnalysisCodeScanProfileSelectedTab = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.WorkloadAnalysis}:${WorkloadAnalysisView.CodeScans}:profileSelectedTab`);

    public static customerWorkloadAnalysisCodeScanProfileTabsOpen = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.WorkloadAnalysis}:${WorkloadAnalysisView.CodeScans}:profileTabsOpen`);

    public static customerWorkloadAnalysisCodeScansColumnOrder = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.WorkloadAnalysis}:${WorkloadAnalysisView.CodeScans}:columnOrder`);

    public static customerWorkloadAnalysisCodeScansColumnSelector = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.WorkloadAnalysis}:${WorkloadAnalysisView.CodeScans}:columnSelector`);

    public static customerWorkloadAnalysisCodeScansPackagesProfileColumnOrder = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.WorkloadAnalysis}:${WorkloadAnalysisView.CodeScans}:${CodeProfileCategory.Packages}:columnOrder`);

    public static customerWorkloadAnalysisCodeScansPackagesProfileColumnSelector = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.WorkloadAnalysis}:${WorkloadAnalysisView.CodeScans}:${CodeProfileCategory.Packages}:columnSelector`);

    public static customerWorkloadAnalysisCodeScansProfileColumnOrder = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.WorkloadAnalysis}:${WorkloadAnalysisView.CodeScans}:${CodeProfileCategory.Vulnerabilities}:columnOrder`);

    public static customerWorkloadAnalysisCodeScansProfileColumnSelector = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.WorkloadAnalysis}:${WorkloadAnalysisView.CodeScans}:${CodeProfileCategory.Vulnerabilities}:columnSelector`);

    public static customerWorkloadAnalysisCodeScansFilters = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.WorkloadAnalysis}:${WorkloadAnalysisView.CodeScans}:filters`);

    public static customerWorkloadAnalysisContainerImagesProfileTableColumnOrder = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.WorkloadAnalysis}:profileContainerImagesColumnOrder`);

    public static customerWorkloadAnalysisContainerImagesProfileTableColumnSelector = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.WorkloadAnalysis}:profileContainerImagesColumnSelector`);

    public static customerWorkloadAnalysisContainerImagesProfileTableFilters = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.WorkloadAnalysis}:profileContainerImagesFilters`);

    public static customerWorkloadAnalysisContainerImagesTableColumnOrder = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.WorkloadAnalysis}:containerImagesColumnOrder`);

    public static customerWorkloadAnalysisContainerImagesTableColumnSelector = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.WorkloadAnalysis}:containerImagesColumnSelector`);

    public static customerWorkloadAnalysisContainerImagesTableFilters = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.WorkloadAnalysis}:containerImagesFilters`);

    public static customerWorkloadAnalysisMaliciousFilesTableColumnOrder = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.WorkloadAnalysis}:maliciousFilesColumnOrder`);

    public static customerWorkloadAnalysisMaliciousFilesTableColumnSelector = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.WorkloadAnalysis}:maliciousFilesColumnSelector`);

    public static customerWorkloadAnalysisMaliciousFilesTableFilters = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.WorkloadAnalysis}:maliciousFilesFilters`);

    public static customerWorkloadAnalysisPackagesProfileTableColumnOrder = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.WorkloadAnalysis}:profilePackagesColumnOrder`);

    public static customerWorkloadAnalysisPackagesProfileTableColumnSelector = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.WorkloadAnalysis}:profilePackagesColumnSelector`);

    public static customerWorkloadAnalysisPackagesProfileTableFilters = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.WorkloadAnalysis}:profilePackagesFilters`);

    public static customerWorkloadAnalysisPackagesTableColumnOrder = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.WorkloadAnalysis}:packagesColumnOrder`);

    public static customerWorkloadAnalysisPackagesTableColumnSelector = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.WorkloadAnalysis}:packagesColumnSelector`);

    public static customerWorkloadAnalysisPackagesTableFilters = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.WorkloadAnalysis}:packagesFilters`);

    public static customerWorkloadAnalysisVirtualMachinesProfileTableColumnOrder = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.WorkloadAnalysis}:profileVirtualMachinesColumnOrder`);

    public static customerWorkloadAnalysisVirtualMachinesProfileTableColumnSelector = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.WorkloadAnalysis}:profileVirtualMachinesColumnSelector`);

    public static customerWorkloadAnalysisVirtualMachinesProfileTableColumnTableFilters = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.WorkloadAnalysis}:profileVirtualMachinesColumnTableFilters`);

    public static customerWorkloadAnalysisVirtualMachinesTableColumnOrder = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.WorkloadAnalysis}:virtualMachinesColumnOrder`);

    public static customerWorkloadAnalysisVirtualMachinesTableColumnSelector = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.WorkloadAnalysis}:virtualMachinesColumnSelector`);

    public static customerWorkloadAnalysisVirtualMachinesTableColumnTableFilters = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.WorkloadAnalysis}:virtualMachinesColumnTableFilters`);

    public static customerWorkloadAnalysisVirtualMachinesImagesTableColumnOrder = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.WorkloadAnalysis}:virtualMachinesImagesColumnOrder`);

    public static customerWorkloadAnalysisVirtualMachinesImagesTableColumnSelector = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.WorkloadAnalysis}:virtualMachinesImagesColumnSelector`);

    public static customerWorkloadAnalysisVirtualMachinesImagesTableColumnTableFiltersSelector = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.WorkloadAnalysis}:virtualMachinesImagesColumnTableFilters`);

    public static customerWorkloadAnalysisVulnerabilitiesTableColumnOrder = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.WorkloadAnalysis}:vulnerabilitiesColumnOrder`);

    public static customerWorkloadAnalysisVulnerabilitiesTableColumnSelector = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.WorkloadAnalysis}:vulnerabilitiesColumnSelector`);

    public static customerWorkloadAnalysisVulnerabilitiesTableFilters = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.Customer}:${Contract.CustomerConsoleAppView.WorkloadAnalysis}:vulnerabilitiesFilters`);

    public static geographySelectedConsoleAppUrl = InfrastructureStorageHelper.getItem("geographySelectedConsoleAppUrl");

    public static pendoEnabled = InfrastructureStorageHelper.getItem("pendoEnabled");

    public static userApproverUserPermissionRequestsFilters = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.User}:${Contract.UserConsoleAppView.ApproverUserPermissionRequests}:filters`);

    public static userApproverUserPermissionRequestsActiveColumnSelector = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.User}:${Contract.UserConsoleAppView.ApproverUserPermissionRequests}:activeColumnSelector`);

    public static userApproverUserPermissionRequestsHistoryColumnSelector = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.User}:${Contract.UserConsoleAppView.ApproverUserPermissionRequests}:historyColumnSelector`);

    public static userAuditEventsPermissionEligibilityTableFilters = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.User}:${Contract.UserConsoleAppView.AuditEvents}:permissionEligibilityAuditEventFilters`);

    public static userAuditEventsPermissionRequestTableFilters = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.User}:${Contract.UserConsoleAppView.AuditEvents}:permissionRequestAuditEventFilters`);

    public static userGranteeUserPermissionRequestsFilters = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.User}:${Contract.UserConsoleAppView.GranteeUserPermissionRequests}:filters`);

    public static userGranteeUserPermissionRequestsActiveColumnSelector = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.User}:${Contract.UserConsoleAppView.GranteeUserPermissionRequests}:activeColumnSelector`);

    public static userGranteeUserPermissionRequestsHistoryColumnSelector = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.User}:${Contract.UserConsoleAppView.GranteeUserPermissionRequests}:historyColumnSelector`);

    public static userPermissionEligibilitiesColumnOrder = (tenantType: Contract.TenantType) => InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.User}:${Contract.UserConsoleAppView.PermissionEligibilities}:${tenantType}:columnOrder`);

    public static userPermissionEligibilitiesColumnSelector = (tenantType: Contract.TenantType) => InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.User}:${Contract.UserConsoleAppView.PermissionEligibilities}:${tenantType}:columnSelector`);

    public static userPermissionEligibilityTableFilters = InfrastructureStorageHelper.getItem(`${Contract.ConsoleAppType.User}:${Contract.UserConsoleAppView.PermissionEligibilities}:filters`);
}