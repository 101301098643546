import { FormLayout, ItemSelector, Loading, Message, Optional, useLocalization } from "@infrastructure";
import { Box, Button, CircularProgress, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo, useRef, useState } from "react";
import { ConfigurationController, Contract, InfoItem, SettingConfigurationHelper, settingConfigurationOperationStore, settingConfigurationTypeNamesOperationStore, useScopeNameTranslator, useScopeNavigationViewContext, useTheme } from "../../../../../../../common";
import { useScopeSettingConfigurationDerivedTypeNameTranslator } from "../hooks";
import { useConfigurationContextContext, useSetConfigurationContextContext } from "../SettingConfigurationTable";
import { Value } from "./Value";

type AddOrEditProps = {
    derivedTypeNames: Contract.ScopeSettingConfigurationDerivedTypeNames[];
};

export function AddOrEdit({ derivedTypeNames }: AddOrEditProps) {
    const setConfigurationContextContext = useSetConfigurationContextContext();
    const { scopeNodeModel } = useScopeNavigationViewContext();
    const { addOrEditOpen } = useConfigurationContextContext();
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const [executing, setExecuting] = useState(false);
    const [valid, setValid] = useState(false);
    const scopeSettingConfiguration =
        useMemo(
            () =>
                _.isBoolean(addOrEditOpen)
                    ? undefined
                    : (addOrEditOpen as Contract.ScopeSettingConfiguration),
            [addOrEditOpen]);
    const [selectedDerivedTypeName, setSelectedDerivedTypeName] =
        useState(
            _.isNil(scopeSettingConfiguration)
                ? !_.isEmpty(derivedTypeNames)
                    ? derivedTypeNames[0]
                    : undefined
                : scopeSettingConfiguration.typeName as Contract.ScopeSettingConfigurationDerivedTypeNames);
    const initialValue =
        useMemo(
            () =>
                _.isNil(scopeSettingConfiguration)
                    ? undefined
                    : SettingConfigurationHelper.getValue(scopeSettingConfiguration),
            []);
    const value = useRef<any>(initialValue);

    function setValidation(valid: boolean) {
        setValid(valid && !_.isEqual(initialValue, value.current));
    }

    const scopeNameTranslator = useScopeNameTranslator();
    const scopeSettingConfigurationDerivedTypeNameTranslator = useScopeSettingConfigurationDerivedTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.configuration.settingConfigurationTable.addOrEdit",
            () => ({
                add: {
                    done: "Add",
                    title: "Add Setting"
                },
                edit: {
                    done: "Save",
                    title: "Edit Setting"
                },
                errors: {
                    insert: "Failed to insert setting",
                    update: "Failed to update setting"
                },
                scope: "Scope",
                settingSelector: "Setting"
            }));

    async function onFinished() {
        if (_.isNil(value.current) || _.isNil(selectedDerivedTypeName)) {
            return;
        }
        try {
            setExecuting(true);
            await ConfigurationController.upsertScopeSettingConfiguration(
                getRequest(
                    scopeSettingConfiguration?.id,
                    selectedDerivedTypeName,
                    scopeSettingConfiguration?.scopeId ?? scopeNodeModel.configuration.id,
                    value.current));

            if (_.isNil(scopeSettingConfiguration?.id)) {
                await Promise.all([
                    settingConfigurationOperationStore.notifyAll(),
                    settingConfigurationTypeNamesOperationStore.notifyAll()
                ]);
            } else {
                await settingConfigurationOperationStore.notifyAll();
            }
            setConfigurationContextContext(({ addOrEditOpen: false }));
        } catch {
            setErrorMessage(
                _.isNil(scopeSettingConfiguration?.id)
                    ? localization.errors.insert()
                    : localization.errors.update());
        }

        setExecuting(false);
    }

    const theme = useTheme();
    return (
        <FormLayout
            footerOptions={{
                contentElement:
                    <Stack
                        alignItems="center"
                        direction="row"
                        justifyContent="space-between"
                        spacing={1}>
                        <Box>
                            {!_.isNil(errorMessage) && (
                                <Message
                                    level="error"
                                    title={errorMessage}/>)}
                        </Box>
                        <Stack
                            alignItems="center"
                            direction="row"
                            spacing={1}>
                            {executing && (
                                <CircularProgress
                                    size={theme.spacing(2)}
                                    variant="indeterminate"/>)}
                            <Button
                                disabled={!valid || executing}
                                onClick={onFinished}>
                                <Typography>
                                    {_.isBoolean(addOrEditOpen)
                                        ? localization.add.done()
                                        : localization.edit.done()}
                                </Typography>
                            </Button>
                        </Stack>
                    </Stack>
            }}
            titleOptions={{
                text:
                    _.isBoolean(addOrEditOpen)
                        ? localization.add.title()
                        : localization.edit.title()
            }}>
            <Stack spacing={2}>
                <ItemSelector
                    disabled={executing || !_.isBoolean(addOrEditOpen)}
                    items={derivedTypeNames}
                    placeholder={localization.settingSelector()}
                    selectedItem={selectedDerivedTypeName}
                    onSelectedItemChanged={setSelectedDerivedTypeName}>
                    {type => scopeSettingConfigurationDerivedTypeNameTranslator(type).title}
                </ItemSelector>
                <InfoItem
                    title={localization.scope()}
                    value={
                        <Typography noWrap={true}>
                            {scopeNameTranslator(
                                _.isBoolean(addOrEditOpen)
                                    ? scopeNodeModel.configuration.id
                                    : addOrEditOpen.scopeId,
                                { path: true })}
                        </Typography>}/>
                {!_.isNil(selectedDerivedTypeName) &&
                    <Loading>
                        <Typography>
                            {scopeSettingConfigurationDerivedTypeNameTranslator(selectedDerivedTypeName).description}
                        </Typography>
                        <Value
                            derivedTypeName={selectedDerivedTypeName}
                            disabled={executing}
                            setValidation={setValidation}
                            valueRef={value}
                            variant="edit"/>
                    </Loading>}
            </Stack>
        </FormLayout>);
}

function getRequest(id: Optional<string>, typeName: Contract.ScopeSettingConfigurationDerivedTypeNames, scopeId: string, value: string | unknown[]) {
    switch (typeName) {
        case Contract.ScopeSettingConfigurationDerivedTypeNames.ServiceIdentityExcessivePermissionEvaluationTimeFrameConfiguration:
            return new Contract.ConfigurationControllerUpsertServiceIdentityExcessivePermissionEvaluationTimeFrameConfigurationRequest(
                id,
                scopeId,
                value as string);
        case Contract.ScopeSettingConfigurationDerivedTypeNames.UserExcessivePermissionEvaluationTimeFrameConfiguration:
            return new Contract.ConfigurationControllerUpsertUserExcessivePermissionEvaluationTimeFrameConfigurationRequest(
                id,
                scopeId,
                value as string);
        case Contract.ScopeSettingConfigurationDerivedTypeNames.AadSeverePermissionDirectoryRoleDefinitionsConfiguration:
            return new Contract.ConfigurationControllerUpsertAadSeverePermissionDirectoryRoleDefinitionsConfigurationRequest(
                id,
                scopeId,
                value as Contract.AadSeverePermissionDirectoryRoleDefinitionData[]);
    }
}