import { useWizardContext } from "@infrastructure";
import React, { useEffect, useRef, useState } from "react";
import { useAddContext } from "..";
import { Contract, CustomRiskPolicyActions, RiskController, riskPolicyModelStore } from "../../../../../../../../../../../../../common";
import { CustomRiskPolicyTemplate } from "../../../../../../../../../components/CustomRiskPolicyTemplate";

export function RiskPolicyItem() {
    const { setValid: setWizardValid, useNextEffect } = useWizardContext();
    const { riskPolicyConfigurationTypeName, scopeId } = useAddContext();

    const [description, setDescription] = useState<string | undefined>();
    const [name, setName] = useState("");
    const [severity, setSeverity] = useState(Contract.Severity.Information);
    const [valid, setValid] = useState(false);

    useEffect(
        () => {
            setWizardValid(valid);
        },
        [valid]);

    const customRiskPolicyActionsRef = useRef<CustomRiskPolicyActions>();

    useNextEffect(
        async () => {
            try {
                await RiskController.insertCustomRiskPolicy(
                    customRiskPolicyActionsRef.current!.createRequest(
                        description?.trim(),
                        name.trim(),
                        scopeId,
                        severity));
                await riskPolicyModelStore.notify();
                return undefined;
            } catch (error) {
                console.error({ error });
                return false;
            }
        },
        [description, name, severity]);

    return (
        <CustomRiskPolicyTemplate
            customRiskPolicyActionsRef={customRiskPolicyActionsRef}
            inputOptions={{
                description: { onChange: setDescription },
                name: { onChange: setName },
                severity: { onChange: setSeverity }
            }}
            riskPolicyConfigurationTypeName={riskPolicyConfigurationTypeName!}
            scopeId={scopeId}
            onValidChange={setValid}/>);
}