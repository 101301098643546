import { Store } from "@infrastructure";
import _, { Dictionary } from "lodash";
import { Contract, TicketingServiceController } from "..";

export class TicketingServiceTicketStore extends Store<Contract.TicketingServiceTicket, never, never> {

    public useGetJiraIssueMap =
        (ids: string[]) =>
            _(this.useGet(ids)).
                keyBy(ticketingServiceTicket => ticketingServiceTicket.id).
                value() as Dictionary<Contract.JiraIssue>;

    public useGetServiceNowIncidentMap =
        (ids: string[]) =>
            _(this.useGet(ids)).
                keyBy(ticketingServiceTicket => ticketingServiceTicket.id).
                value() as Dictionary<Contract.ServiceNowIncident>;
}

export const ticketingServiceTicketStore =
    new TicketingServiceTicketStore(
        ticketingServiceTicket => ticketingServiceTicket.id,
        {
            get:
                async ids => {
                    const { tickets } = await TicketingServiceController.getTickets(new Contract.TicketingServiceControllerGetTicketsRequest(ids));
                    return tickets;
                }
        });