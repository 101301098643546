import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function ApiKeyIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <path d="M3.4694 7.46688C4.74276 8.52694 6.63437 8.35402 7.69442 7.08066C8.75448 5.8073 8.58156 3.91569 7.3082 2.85564C6.03484 1.79558 4.14323 1.9685 3.08318 3.24186C2.02312 4.51522 2.19604 6.40683 3.4694 7.46688ZM2.1898 9.00397C4.31206 10.7707 7.46474 10.4825 9.2315 8.36026C10.9983 6.238 10.7101 3.08532 8.5878 1.31855C6.46553 -0.448206 3.31285 -0.160011 1.54609 1.96226C-0.220667 4.08452 0.0675281 7.2372 2.1898 9.00397Z"/>
        <path d="M8.58816 7.69818C8.94151 7.27372 9.57205 7.21608 9.9965 7.56944L18.5785 14.7139C19.003 15.0672 19.0606 15.6978 18.7073 16.1222C18.3539 16.5467 17.7234 16.6043 17.2989 16.251L8.7169 9.10652C8.29245 8.75316 8.23481 8.12263 8.58816 7.69818Z"/>
        <path d="M16.7314 14.2424C17.1558 14.5957 17.2134 15.2263 16.8601 15.6507L15.1184 17.7428C14.7651 18.1673 14.1345 18.2249 13.7101 17.8716C13.2856 17.5182 13.228 16.8877 13.5813 16.4632L15.323 14.3711C15.6764 13.9467 16.3069 13.889 16.7314 14.2424Z"/>
        <path d="M14.0085 11.9756C14.4329 12.329 14.4906 12.9595 14.1372 13.384L13.1509 14.5688C12.7975 14.9932 12.167 15.0509 11.7425 14.6975C11.3181 14.3442 11.2604 13.7136 11.6138 13.2892L12.6002 12.1044C12.9535 11.6799 13.584 11.6223 14.0085 11.9756Z"/>
    </SvgIcon>;
}