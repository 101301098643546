import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useAzureNetworkServiceEndpointStatusTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useAzureNetworkServiceEndpointStatusTranslator",
            () => ({
                [Contract.TypeNames.AzureNetworkServiceEndpointState]: {
                    [Contract.AzureNetworkServiceEndpointState.Deleting]: "Deleting",
                    [Contract.AzureNetworkServiceEndpointState.Deprovisioning]: "Deprovisioning",
                    [Contract.AzureNetworkServiceEndpointState.Failed]: "Failed",
                    [Contract.AzureNetworkServiceEndpointState.Initializing]: "Initializing",
                    [Contract.AzureNetworkServiceEndpointState.InProgress]: "In progress",
                    [Contract.AzureNetworkServiceEndpointState.NetworkSourceDeleted]: "Network source deleted",
                    [Contract.AzureNetworkServiceEndpointState.Provisioning]: "Provisioning",
                    [Contract.AzureNetworkServiceEndpointState.Ready]: "Ready",
                    [Contract.AzureNetworkServiceEndpointState.Succeeded]: "Succeeded",
                    [Contract.AzureNetworkServiceEndpointState.Unknown]: "Unknown"
                }
            }));
    return (serviceEndpointStatus: Contract.AzureNetworkServiceEndpointState) =>
        localization[Contract.TypeNames.AzureNetworkServiceEndpointState][serviceEndpointStatus]();
}