import SaveIcon from "@mui/icons-material/Save";
import { Stack, TextField, useTheme } from "@mui/material";
import React, { Fragment, KeyboardEvent, Ref, useEffect, useRef, useState } from "react";
import { ClearIcon, EditIcon } from "../assets";
import { useActions } from "../hooks";
import { Action1, KeyCodes, Optional } from "../types";
import { CopyToClipboardText } from "./CopyToClipboard";

type EditableTextFieldProps = {
    actionsRef?: Ref<Optional<EditableTextFieldActions>>;
    onSave: Action1<string>;
    title: string;
    value: string;
};

export type EditableTextFieldActions = {
    setValue: (value: string) => void;
};

export function EditableTextField({ actionsRef, onSave, title, value: initialValue }: EditableTextFieldProps) {
    const [editMode, setEditMode] = useState(false);
    const [value, setValue] = useState(initialValue);

    useActions<EditableTextFieldActions>(
        actionsRef,
        {
            setValue(value) {
                setValue(value);
            }
        });

    const inputElementRef = useRef<HTMLInputElement>(null);
    useEffect(
        () => {
            if (editMode) {
                inputElementRef.current?.focus();
                inputElementRef.current?.setSelectionRange(0, 0);
            }
        },
        [editMode]);

    function handleCancelClick() {
        setValue(initialValue);
        setEditMode(false);
    }

    function handleEditClick() {
        setEditMode(!editMode);
    }

    function handleSaveClick() {
        onSave(value);
        setEditMode(false);
    }

    function onKeyDown(e: KeyboardEvent<HTMLInputElement>) {
        const { keyCode } = e;
        if (keyCode === KeyCodes.Enter) {
            handleSaveClick();
        } else if (keyCode === KeyCodes.Escape) {
            handleCancelClick();
        }
    }

    const theme = useTheme();
    return (
        <Stack
            direction="row"
            spacing={0.5}
            sx={{
                alignItems: "center",
                flex: 1
            }}>
            {editMode
                ? <Fragment>
                    <TextField
                        defaultValue={initialValue}
                        inputRef={inputElementRef}
                        sx={{
                            ".MuiInputBase-root:before": {
                                borderBottomColor: theme.palette.text.secondary
                            },
                            flex: 1
                        }}
                        value={value}
                        variant="standard"
                        onChange={e => setValue(e.target.value)}
                        onKeyDown={onKeyDown}/>
                    <Stack
                        direction="row"
                        spacing={0.5}
                        sx={{ flex: 1 }}>
                        <SaveIcon
                            sx={{
                                "&:hover": {
                                    cursor: "pointer"
                                }
                            }}
                            onClick={handleSaveClick}/>
                        <ClearIcon
                            sx={{
                                "&:hover": {
                                    cursor: "pointer"
                                }
                            }}
                            onClick={handleCancelClick}/>
                    </Stack>
                </Fragment>
                : <Fragment>
                    <CopyToClipboardText
                        text={value}
                        title={title}/>
                    <EditIcon
                        sx={{
                            "&:hover": {
                                cursor: "pointer"
                            }
                        }}
                        onClick={handleEditClick}/>
                </Fragment>}
        </Stack>);
}