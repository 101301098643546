import { ArrowForward } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { ReactNode } from "react";

type SourceToTargetMapProps = {
    children?: (value: any) => ReactNode;
    source: any;
    target: any;
};

export function SourceToTargetMap({ children: renderItem, source, target }: SourceToTargetMapProps) {
    return (
        _.isEqual(_.concat(source), _.concat(target))
            ? <Typography noWrap={true}>
                {renderItem?.(source) ?? source}
            </Typography>
            : <Stack
                alignItems="center"
                direction="row"
                spacing={1}>
                <Typography noWrap={true}>{renderItem?.(source) ?? source}</Typography>
                <ArrowForward/>
                <Typography noWrap={true}>{renderItem?.(target) ?? target}</Typography>
            </Stack>);
}