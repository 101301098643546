import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function ColumnsIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path d="M6 6C6 5.44772 6.44772 5 7 5C7.55228 5 8 5.44772 8 6V18C8 18.5523 7.55228 19 7 19C6.44772 19 6 18.5523 6 18V6Z"/>
            <path d="M11 6C11 5.44772 11.4477 5 12 5C12.5523 5 13 5.44772 13 6V18C13 18.5523 12.5523 19 12 19C11.4477 19 11 18.5523 11 18V6Z"/>
            <path d="M16 6C16 5.44772 16.4477 5 17 5C17.5523 5 18 5.44772 18 6V18C18 18.5523 17.5523 19 17 19C16.4477 19 16 18.5523 16 18V6Z"/>
        </SvgIcon>);
}