import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, EmptyMessageText, TextValuesFilter, UnexpectedError, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import _ from "lodash";
import React, { useCallback, useMemo } from "react";
import { DataAnalysisResourceDataClassifierSamples } from ".";
import { InlineDataClassifierNames } from "../..";
import { Contract, dataAnalysisResourceScanStore, DataCategories, dataClassifierModelStore, DataSensitivity, ItemTable, TypeHelper, useDataCategoryTranslator, useDataSensitivityTranslator, useTheme } from "../../../../../../../common";

type DataAnalysisResourceDataSegmentsProps = {
    dataAnalysisResourceModel: Contract.IDataAnalysisResourceModel;
};

export function DataAnalysisResourceDataSegments({ dataAnalysisResourceModel }: DataAnalysisResourceDataSegmentsProps) {
    const dataClassifierModels = dataClassifierModelStore.useGetAll();
    const dataClassifierModelMap =
        useMemo(
            () =>
                _.keyBy(
                    dataClassifierModels,
                    dataClassifierModel => dataClassifierModel.id),
            [dataClassifierModels]);
    const dataAnalysisResourceScan = dataAnalysisResourceScanStore.useGet(dataAnalysisResourceModel.id);
    const items =
        useMemo(
            () =>
                _.map(
                    dataAnalysisResourceScan.classificationExistsDataSegmentNameToDataMap,
                    (dataSegmentData, dataSegmentName) => ({
                        dataCategories:
                            _(dataSegmentData.dataClassifierIdToSamplesMap).
                                keys().
                                map(dataClassifierId => dataClassifierModelMap[dataClassifierId].category).
                                uniq().
                                value(),
                        dataClassifierIds:
                            _(dataSegmentData.dataClassifierIdToSamplesMap).
                                keys().
                                value(),
                        dataClassifierIdToSamplesMap: dataSegmentData.dataClassifierIdToSamplesMap,
                        dataSensitivity:
                            _(dataSegmentData.dataClassifierIdToSamplesMap).
                                keys().
                                map(dataClassifierId => dataClassifierModelMap[dataClassifierId].sensitivity).
                                maxBy(dataSensitivity => TypeHelper.getEnumValue(Contract.TypeNames.DataSensitivity, dataSensitivity))!,
                        name: dataSegmentName
                    } as DataSegmentTableItem)),
            [dataAnalysisResourceScan]);

    const dataCategoryTranslator = useDataCategoryTranslator();
    const dataSensitivityTranslator = useDataSensitivityTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.dataAnalysisResourceDataSegments",
            () => ({
                columns: {
                    dataCategories: "Data Categories",
                    dataClassifierIds: "Data Types",
                    dataSamples: "Samples",
                    dataSensitivity: {
                        title: "Data Sensitivity",
                        tooltip: "Highest sensitivity"
                    },
                    path: "Path",
                    table: "Table"
                },
                csv: {
                    title: "Classified Data"
                },
                empty: "No details"
            }));

    const getPathOrTableColumnName =
        useCallback(
            (dataAnalysisResourceModel: Contract.IDataAnalysisResourceModel) => {
                if (TypeHelper.extendOrImplement(dataAnalysisResourceModel.entity.typeName, Contract.TypeNames.IObjectStore)) {
                    return localization.columns.path();
                }

                switch (dataAnalysisResourceModel.typeName) {
                    case Contract.TypeNames.AwsRdsClusterModel:
                    case Contract.TypeNames.AwsRdsDatabaseInstanceModel:
                    case Contract.TypeNames.AzureSqlServerModel:
                    case Contract.TypeNames.GcpBigQueryDatasetModel:
                    case Contract.TypeNames.GcpSqlInstanceModel:
                        return localization.columns.table();
                    default:
                        throw new UnexpectedError("getPathOrTableColumnName", dataAnalysisResourceModel.typeName);
                }
            },
            [localization]);

    const theme = useTheme();
    return (
        <ItemTable
            columnIdToDefaultSortDirectionMap={{
                [TableColumnId.DataSensitivity]: "desc"
            }}
            columnIdToGetItemValueMap={{
                [TableColumnId.Name]: item => item.name,
                [TableColumnId.DataCategories]: {
                    getFilterValue: item => item.dataCategories,
                    getSortValue: item => item.dataCategories.length
                },
                [TableColumnId.DataClassifierIds]: {
                    getFilterValue: item => item.dataClassifierIds,
                    getSortValue: item => item.dataClassifierIds.length
                },
                [TableColumnId.DataSensitivity]: {
                    getFilterValue: item => item.dataSensitivity,
                    getSortValue: item => TypeHelper.getEnumValue(Contract.TypeNames.DataSensitivity, item.dataSensitivity)
                },
                [TableColumnId.DataSamples]:
                    item =>
                        _.reduce(
                            item.dataClassifierIdToSamplesMap,
                            (sum, value) => sum + _.size(value),
                            0)
            }}
            columnOptions={{
                orderOptions: {
                    enabled: true
                },
                resizable: true,
                selectorOptions: {
                    enabled: true
                },
                stickyColumnId: TableColumnId.Name
            }}
            csvExportFilePrefixes={[dataAnalysisResourceModel.entity.displayName, localization.csv.title()]}
            defaultSortColumnIdOrIds={TableColumnId.DataSensitivity}
            emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty()) }}
            filterOptions={{
                persist: true,
                sx: { padding: theme.spacing(0, 0, 1) }
            }}
            getCsvItem={
                item => ({
                    /* eslint-disable sort-keys-fix/sort-keys-fix */
                    "Name": item.name,
                    "Data Types":
                        _(item.dataClassifierIds).
                            map(dataClassifierId => dataClassifierModelMap[dataClassifierId]?.name).
                            join("\n"),
                    "Data Categories":
                        _(item.dataCategories).
                            map(dataCategory => dataCategoryTranslator(dataCategory).title).
                            join("\n"),
                    "Data Sensitivity": dataSensitivityTranslator(item.dataSensitivity)
                    /* eslint-enable sort-keys-fix/sort-keys-fix */
                })}
            getItemId={item => item.name}
            items={items}
            variant="view">
            {columnIdToItemValuesMap =>
                [
                    <DataTableColumn
                        cellMaxWidth="medium"
                        filterOptions={{
                            itemOrItems: {
                                default: true,
                                element:
                                    <TextValuesFilter
                                        placeholder={getPathOrTableColumnName(dataAnalysisResourceModel)}
                                        values={columnIdToItemValuesMap[TableColumnId.Name]}/>
                            }
                        }}
                        id={TableColumnId.Name}
                        itemProperty={(item: DataSegmentTableItem) => item.name}
                        key={TableColumnId.Name}
                        selectorOptions={{ disabled: true }}
                        title={getPathOrTableColumnName(dataAnalysisResourceModel)}/>,
                    <DataTableColumn
                        filterOptions={{
                            itemOrItems: {
                                default: true,
                                element:
                                    <ValuesFilter placeholder={localization.columns.dataClassifierIds()}>
                                        {_.map(
                                            columnIdToItemValuesMap[TableColumnId.DataClassifierIds],
                                            dataClassifierId =>
                                                <ValuesFilterItem
                                                    key={dataClassifierId}
                                                    title={dataClassifierModelMap[dataClassifierId]?.name}
                                                    value={dataClassifierId}/>)}
                                    </ValuesFilter>
                            }
                        }}
                        id={TableColumnId.DataClassifierIds}
                        key={TableColumnId.DataClassifierIds}
                        render={({ item }: DataTableColumnRenderProps<DataSegmentTableItem>) => <InlineDataClassifierNames ids={item.dataClassifierIds}/>}
                        selectorOptions={{ disabled: true }}
                        sortOptions={{ type: DataTableSortType.Numeric }}
                        title={localization.columns.dataClassifierIds()}/>,
                    <DataTableColumn
                        filterOptions={{
                            itemOrItems: {
                                default: true,
                                element:
                                    <ValuesFilter placeholder={localization.columns.dataCategories()}>
                                        {_.map(
                                            columnIdToItemValuesMap[TableColumnId.DataCategories],
                                            dataCategory =>
                                                <ValuesFilterItem
                                                    key={dataCategory}
                                                    title={dataCategoryTranslator(dataCategory)?.initials}
                                                    value={dataCategory}/>)}
                                    </ValuesFilter>
                            }
                        }}
                        id={TableColumnId.DataCategories}
                        key={TableColumnId.DataCategories}
                        render={
                            ({ item }: DataTableColumnRenderProps<DataSegmentTableItem>) =>
                                <DataCategories
                                    categories={item.dataCategories}
                                    variant="dynamic"/>}
                        sortOptions={{ type: DataTableSortType.Numeric }}
                        title={localization.columns.dataCategories()}/>,
                    <DataTableColumn
                        filterOptions={{
                            itemOrItems: {
                                default: true,
                                element:
                                    <ValuesFilter placeholder={localization.columns.dataSensitivity.title()}>
                                        {_.map(
                                            columnIdToItemValuesMap[TableColumnId.DataSensitivity],
                                            dataSensitivity =>
                                                <ValuesFilterItem
                                                    key={dataSensitivity}
                                                    title={dataSensitivityTranslator(dataSensitivity)}
                                                    value={dataSensitivity}/>)}
                                    </ValuesFilter>
                            }
                        }}
                        id={TableColumnId.DataSensitivity}
                        key={TableColumnId.DataSensitivity}
                        message={localization.columns.dataSensitivity.tooltip()}
                        render={
                            ({ item }: DataTableColumnRenderProps<DataSegmentTableItem>) =>
                                <DataSensitivity
                                    sensitivity={
                                        _(item.dataClassifierIdToSamplesMap).
                                            keys().
                                            map(dataClassifierId => dataClassifierModelMap[dataClassifierId].sensitivity).
                                            maxBy(dataSensitivity => TypeHelper.getEnumValue(Contract.TypeNames.DataSensitivity, dataSensitivity))!}/>}
                        sortOptions={{ type: DataTableSortType.Numeric }}
                        title={localization.columns.dataSensitivity.title()}/>,
                    <DataTableColumn
                        id={TableColumnId.DataSamples}
                        key={TableColumnId.DataSamples}
                        render={({ item }: DataTableColumnRenderProps<DataSegmentTableItem>) =>
                            <DataAnalysisResourceDataClassifierSamples
                                dataClassifierIdToSamplesMap={item.dataClassifierIdToSamplesMap}
                                dataClassifierModelMap={dataClassifierModelMap}/>}
                        sortOptions={{ type: DataTableSortType.Numeric }}
                        title={localization.columns.dataSamples()}/>
                ]}
        </ItemTable>);
}

type DataSegmentTableItem = {
    dataCategories: Contract.DataCategory[];
    dataClassifierIds: string[];
    dataClassifierIdToSamplesMap: _.Dictionary<Contract.DataClassifierSample[]>;
    dataSensitivity: Contract.DataSensitivity;
    name: string;
};

enum TableColumnId {
    Database = "database",
    DataCategories = "dataCategories",
    DataClassifierIds = "dataClassifierIds",
    DataSamples = "dataSamples",
    DataSensitivity = "dataSensitivity",
    Name = "name"
}