import _, { Function0 } from "lodash";
import React, { Fragment, useCallback, useMemo } from "react";
import { EmptyMessage, useLocalization } from "@infrastructure";
import { WidgetDefinition } from "../../../../..";
import { Contract } from "../../../../../../../../controllers";
import { TypeHelper } from "../../../../../../../../utilities";
import { defaultSeverities } from "../../../../../../../SeverityMultiSelect";
import { AccessDashboardContext, useDashboardContext } from "../../../../../../Dashboard";
import { AccessRisk } from "./components";

export function useGetAccessRisksDefinition(): () => WidgetDefinition {
    const localization =
        useLocalization(
            "common.dashboard.widget.hooks.useDefinition.hooks.useGetAccessRisksDefinition",
            () => ({
                title: "Findings"
            }));

    return useCallback<Function0<WidgetDefinition>>(
        () => ({
            element: <AccessRisks/>,
            options: {
                title: localization.title(),
                variant: "scrollable"
            }
        }),
        [localization]);
}

function AccessRisks() {
    const { filters, summary } = useDashboardContext<AccessDashboardContext>();
    const localization =
        useLocalization(
            "common.dashboard.widget.hooks.useDefinition.hooks.useGetAccessRisksDefinition.accessRisksCardContent",
            () => ({
                empty: "No Findings"
            }));

    const severities =
        useMemo(
            () =>
                _.isEmpty(filters.severities)
                    ? defaultSeverities
                    : filters.severities,
            [filters.severities]);

    const riskDatas =
        useMemo(
            () =>
                _.map(
                    summary.riskDatas,
                    riskData => ({
                        ...riskData,
                        severitiesCount:
                            _.map(
                                defaultSeverities,
                                severity => riskData.severityToCountMap[severity] ?? 0)
                    })),
            [summary.riskDatas]);

    const filteredSeverityIndexes =
        useMemo(
            () =>
                _.map(
                    severities,
                    severity =>
                        _.indexOf(
                            defaultSeverities,
                            severity)),
            [severities]);

    const filteredRiskDatas =
        useMemo(
            (): AccessRiskDataItem[] =>
                _(riskDatas).
                    map(
                        filteredRiskData => {
                            const filteredRiskDataSeverityIndexes =
                                _.filter(
                                    filteredSeverityIndexes,
                                    index => filteredRiskData.severitiesCount[index] > 0);

                            return {
                                ...filteredRiskData,
                                count:
                                    _(filteredRiskDataSeverityIndexes)
                                        .map(index => filteredRiskData.severitiesCount[index])
                                        .sum(),
                                maxSeverity: defaultSeverities?.[filteredRiskDataSeverityIndexes[0]]
                            };
                        }).
                    filter(({ count }) => count > 0).
                    orderBy(
                        [
                            ({ maxSeverity }) =>
                                TypeHelper.getEnumValue(
                                    Contract.TypeNames.Severity,
                                    maxSeverity),
                            ({ maxSeverity, severityToCountMap }) => severityToCountMap[maxSeverity]
                        ],
                        ["desc", "desc"]).
                    value(),
            [riskDatas, filteredSeverityIndexes]);

    return _.isEmpty(filteredRiskDatas)
        ? <EmptyMessage
            message={localization.empty()}
            verticalCenter={true}/>
        : <Fragment>
            {_.map(
                filteredRiskDatas,
                filteredRiskData =>
                    <AccessRisk
                        filteredSeverities={severities}
                        item={filteredRiskData}
                        key={filteredRiskData.policyConfigurationTypeName}/>)}
        </Fragment>;
}

interface AccessRiskDataItem {
    count: number;
    maxSeverity: Contract.Severity;
    policyConfigurationTypeName: string;
    severitiesCount: number[];
    severityToCountMap: _.Dictionary<number>;
    tenantTypes: Contract.TenantType[];
}