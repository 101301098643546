import { ActionMenuItem, ConfirmDialog, DataTableColumnRenderProps, Menu, Message, useLocalization } from "@infrastructure";
import { CircularProgress, Stack } from "@mui/material";
import React, { Fragment, useState } from "react";
import { Contract, PermissionManagementController, useTheme } from "../../../../../../../../../../../common";
import { useGranteeUserPermissionRequestsContext } from "../../../../../../../GranteeUserPermissionRequests";

export function PendingActionsCell({ item: permissionRequestModel }: DataTableColumnRenderProps<Contract.PermissionRequestModel>) {
    const { triggerPermissionRequestModelChange } = useGranteeUserPermissionRequestsContext();

    const [cancelPermissionRequestError, setCancelPermissionRequestError] = useState(false);
    const [cancelPermissionRequestExecuting, setCancelPermissionRequestExecuting] = useState(false);

    async function cancelPermissionRequest() {
        setCancelPermissionRequestExecuting(true);
        setCancelPermissionRequestError(false);

        try {
            await PermissionManagementController.cancelPermissionRequest(
                new Contract.PermissionManagementControllerCancelPermissionRequestRequest(permissionRequestModel.permissionRequest.id));

            await triggerPermissionRequestModelChange();

        } catch {
            setCancelPermissionRequestError(true);
        }

        setCancelPermissionRequestExecuting(false);
    }

    const localization =
        useLocalization(
            "views.user.granteeUserPermissionRequests.table.hooks.useDefinition.hooks.useActiveDefinition.pendingActionsCell",
            () => ({
                actions: {
                    cancelPermissionRequest: {
                        confirm: "Are you sure you want to cancel this request?",
                        error: "Failed to cancel request",
                        title: "Cancel"
                    }
                }
            }));

    const [confirmCancel, setConfirmCancel] = useState(false);
    const theme = useTheme();
    return (
        <Fragment>
            {confirmCancel &&
                <ConfirmDialog
                    message={localization.actions.cancelPermissionRequest.confirm()}
                    onClose={
                        confirmed => {
                            if (confirmed) {
                                cancelPermissionRequest();
                            }

                            setConfirmCancel(false);
                        }}/>}
            <Stack
                alignItems="center"
                direction="row"
                justifyContent="flex-end"
                spacing={1.5}>
                {cancelPermissionRequestExecuting && (
                    <CircularProgress
                        size={theme.spacing(3)}
                        variant="indeterminate"/>)}
                {cancelPermissionRequestError && (
                    <Message
                        level="error"
                        title={localization.actions.cancelPermissionRequest.error()}
                        variant="minimal"/>)}
                <Menu
                    itemsOrGetItems={[
                        new ActionMenuItem(
                            () => setConfirmCancel(true),
                            localization.actions.cancelPermissionRequest.title())
                    ]}/>
            </Stack>
        </Fragment>);
}