import { ActionButton, Step, Steps, UnexpectedError, useLocalization } from "@infrastructure";
import { Stack } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { useMemo, useState } from "react";
import utf8 from "utf8";
import { Contract, DiffViewerIcon, DocumentViewerDialog, Entity, entityModelStore, InlineEntities, InlinePermissionActions, PolicyDocumentIcon, useEntityTypeNameTranslator, useSeverityTranslator, useTheme } from "../../../../../../../../../../../../common";
import { AwsIamRoleGroupModel } from "../../../../../../../../../../../../common/controllers/types.generated";
import { CodeTypeIcon } from "../../../../../../../../../../../../tenants";
import { ChangeStep, makeAccessResolutionSectionDefinitionComponent, RiskDefinitionSection } from "../../../../../../utilities";
import { RiskContentProps } from "../../../../useCloudDefinition";
import { PrincipalRiskPolicyDiffDialog, RiskPolicyDiffPolicyItem } from "../../components";
import { useAwsCommonAccessPrincipalRiskDefinition } from "./useAwsCommonAccessPrincipalRiskDefinition";

export function useAwsExcessivePermissionRoleGroupRiskDefinition(riskModel: Contract.RiskModel) {
    const excessivePermissionRoleGroupRiskModel = riskModel as Contract.AwsExcessivePermissionRoleGroupRiskModel;
    const roleGroupModel = entityModelStore.useGet(excessivePermissionRoleGroupRiskModel.risk.entityId) as Contract.AwsIamRoleGroupModel;

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.access.useAwsExcessivePermissionRoleGroupRiskDefinition",
            () => ({
                description: "Role {{roleGroup}} is deployed identically across {{accountIds}} in your organization, and has {{policies}} attached. In total, these policies grant some unused permissions, which can be removed.",
                sections: {
                    description: {
                        text: "This finding relates to a role that is identically deployed across multiple accounts and grants excessive permissions.",
                        title: "Description"
                    }
                }
            }));

    const descriptionProps = {
        accountIds:
            <InlineEntities
                entityIdsOrModels={roleGroupModel.accountIds}
                entityTypeName={Contract.TypeNames.AwsOrganizationsAccount}
                entityTypeNameTranslatorOptions={{
                    includeServiceName: false,
                    variant: "text"
                }}
                variant="itemAndTypeOrItemCountAndType"/>,
        policies:
            <InlineEntities
                entityIdsOrModels={excessivePermissionRoleGroupRiskModel.policyIdReferences}
                entityTypeName={Contract.TypeNames.AwsIamPrincipalPolicy}
                variant="itemAndTypeOrItemCountAndType"/>,
        roleGroup:
            <Entity
                entityIdOrModel={roleGroupModel}
                variant="text"/>
    };
    return useAwsCommonAccessPrincipalRiskDefinition(
        Contract.EntityAccessScope.GranteePermitterInvolved,
        <ContextSection riskModel={riskModel}/>,
        localization.description(descriptionProps),
        riskModel,
        {
            descriptionSection:
                new RiskDefinitionSection(
                    localization.sections.description.text(),
                    localization.sections.description.title()),
            resolutionSectionDefinitionComponent
        });
}

function ContextSection({ riskModel }: RiskContentProps) {
    const excessivePermissionRoleGroupRiskModel = riskModel as Contract.AwsExcessivePermissionRoleGroupRiskModel;
    const risk = excessivePermissionRoleGroupRiskModel.risk as Contract.AwsExcessivePermissionRoleGroupRisk;
    const roleGroupModel = entityModelStore.useGet(risk.entityId) as Contract.AwsIamRoleGroupModel;
    const roleGroup = roleGroupModel.entity as Contract.AwsIamRoleGroup;
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const severityTranslator = useSeverityTranslator();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.access.useAwsExcessivePermissionRoleGroupRiskDefinition.contextSection",
            () => ({
                sensitiveExcessivePermissionActions: [
                    "1 sensitive excessive permission",
                    "{{count | NumberFormatter.humanize}} sensitive excessive permissions"
                ],
                sensitiveResources: [
                    "1 sensitive resource",
                    "{{count | NumberFormatter.humanize}} sensitive resources"
                ],
                unknownTenants: [
                    "1 unknown account",
                    "{{count | NumberFormatter.humanize}} unknown accounts"
                ],
                userActiveAccessKeys: [
                    "1 active access key",
                    "{{count | NumberFormatter.humanize}} active access keys"
                ],
                [Contract.TypeNames.AwsExcessivePermissionRoleGroupRiskModelInfoType]: {
                    [Contract.AwsExcessivePermissionRoleGroupRiskModelInfoType.Accounts]: "The {{roleGroup}} is deployed identically across {{accountIds}} in your organization",
                    [Contract.AwsExcessivePermissionRoleGroupRiskModelInfoType.ActivityTime]: "This role was last active on {{activityTime | TimeFormatter.shortDate}}",
                    [Contract.AwsExcessivePermissionRoleGroupRiskModelInfoType.ExcessivePermissionActionSeverity]: "One or more instances of this role has **{{excessivePermissionActionSeverity}}** severity excessive permissions",
                    [Contract.AwsExcessivePermissionRoleGroupRiskModelInfoType.OldestRoleCreationTime]: "This role was first deployed on {{oldestRoleCreationTime | TimeFormatter.shortDate}}",
                    [Contract.AwsExcessivePermissionRoleGroupRiskModelInfoType.Policies]: "This role has {{policies}} attached granting it {{permissionActions}} on {{services}}",
                    [Contract.AwsExcessivePermissionRoleGroupRiskModelInfoType.PublicOriginatorResources]: "One or more instances of this role  is linked to internet-facing compute instances",
                    [Contract.AwsExcessivePermissionRoleGroupRiskModelInfoType.Public]: "One or more instances of this role is public (trusts any identity)",
                    [Contract.AwsExcessivePermissionRoleGroupRiskModelInfoType.SensitiveResourcesSensitiveExcessivePermissionActionsAny]: "This role has {{sensitiveExcessivePermissionActions}} on {{sensitiveResources}}",
                    [Contract.AwsExcessivePermissionRoleGroupRiskModelInfoType.ServiceLevelManyExcessivePermissionServicesAll]: "This role has access to {{services}}, but has not used any of them for more than {{servicesServiceUsageTime | TimeFormatter.humanizeDuration}}",
                    [Contract.AwsExcessivePermissionRoleGroupRiskModelInfoType.ServiceLevelManyExcessivePermissionServicesSome]: [
                        "{{excessivePermissionServices}} out of the {{services}} was unused for more than {{servicesExcessivePermissionServiceUsageTime | TimeFormatter.humanizeDuration}}",
                        "{{excessivePermissionServices}} out of the {{services}} were unused for more than {{servicesExcessivePermissionServiceUsageTime | TimeFormatter.humanizeDuration}}"
                    ],
                    [Contract.AwsExcessivePermissionRoleGroupRiskModelInfoType.ServiceLevelSingle]: "This role has access to {{services}}, but has not used it for more than {{servicesServiceUsageTime | TimeFormatter.humanizeDuration}}",
                    [Contract.AwsExcessivePermissionRoleGroupRiskModelInfoType.ServiceResourceLevel]: "This role is only using some of the permissions of {{serviceResourceLevelService}}",
                    [Contract.AwsExcessivePermissionRoleGroupRiskModelInfoType.ServiceResourceLevelNotUsedPermissionActions]: "This role is only using {{serviceResourceLevelUsedPermissionActions}} out of the {{serviceResourceLevelPermissionActions}} on {{serviceResourceLevelService}}",
                    [Contract.AwsExcessivePermissionRoleGroupRiskModelInfoType.ServiceResourceLevelNotUsedResources]: "This role has access to {{serviceResourceLevelResources}} but is not using any of them on {{serviceResourceLevelService}}",
                    [Contract.AwsExcessivePermissionRoleGroupRiskModelInfoType.ServiceResourceLevelNotUsedResourcesAndNotUsedPermissionActions]: "This role has access to {{serviceResourceLevelResources}} but is not using any of them and is only using {{serviceResourceLevelUsedPermissionActions}} out of the {{serviceResourceLevelPermissionActions}} on {{serviceResourceLevelService}}",
                    [Contract.AwsExcessivePermissionRoleGroupRiskModelInfoType.ServiceResourceLevelNotUsedResourcesAndUsedResources]: "This role has access to {{serviceResourceLevelResources}} but is only using {{serviceResourceLevelUsedResources}} on {{serviceResourceLevelService}}",
                    [Contract.AwsExcessivePermissionRoleGroupRiskModelInfoType.ServiceResourceLevelNotUsedResourcesAndUsedResourcesAndNotUsedPermissionActions]: "This role has access to {{serviceResourceLevelResources}} but is only using {{serviceResourceLevelUsedResources}} and only {{serviceResourceLevelUsedPermissionActions}} out of the {{serviceResourceLevelPermissionActions}} on {{serviceResourceLevelService}}",
                    [Contract.AwsExcessivePermissionRoleGroupRiskModelInfoType.ServiceResourceLevelNotUsedPermissionActionResourcesAndUsedResources]: "This role has access to {{serviceResourceLevelResources}} but is only using some of the permissions on {{serviceResourceLevelNotUsedPermissionActionResources}} on {{serviceResourceLevelService}}",
                    [Contract.AwsExcessivePermissionRoleGroupRiskModelInfoType.UnknownAccounts]: "One or more instances of this role trust unknown accounts",
                    [Contract.AwsExcessivePermissionRoleGroupRiskModelInfoType.Vendor]: "One or more instances of this role is used by a 3rd party application",
                    [Contract.AwsExcessivePermissionRoleGroupRiskModelInfoType.VulnerableOriginatorWorkloadResources]: "One or more instances of this role is linked to compute instances with critical vulnerabilities"
                }
            }));
    const createInfoProps =
        (additionalProps?: Dictionary<any>) => ({
            ...additionalProps,
            accountIds:
                <InlineEntities
                    entityIdsOrModels={roleGroupModel.accountIds}
                    entityTypeName={Contract.TypeNames.AwsOrganizationsAccount}
                    entityTypeNameTranslatorOptions={{
                        includeServiceName: false,
                        variant: "text"
                    }}
                    variant="itemAndTypeOrItemCountAndType"/>,
            activityTime: roleGroupModel.activityTime,
            excessivePermissionActionSeverity: severityTranslator(risk.excessivePermissionActionSeverity!, "text"),
            excessivePermissionServices:
                risk.serviceLevel!.hasExcessivePermissions
                    ? <InlineEntities
                        entityIdsOrModels={risk.serviceLevel!.excessivePermissionServiceIds}
                        entityTypeName={Contract.TypeNames.AwsService}
                        variant="itemCountAndType"/>
                    : undefined,
            oldestRoleCreationTime: roleGroupModel.oldestRoleCreationTime,
            permissionActions:
                <InlinePermissionActions
                    permissionActions={risk.permissionActions}
                    variant="itemOrItemCountAndType"/>,
            policies:
                <InlineEntities
                    entityIdsOrModels={excessivePermissionRoleGroupRiskModel.policyIdReferences}
                    entityTypeName={Contract.TypeNames.AwsIamPrincipalPolicy}
                    variant="itemAndTypeOrItemCountAndType"/>,
            roleGroup:
                <Entity
                    entityIdOrModel={roleGroupModel}
                    variant="typeText"/>,
            sensitiveExcessivePermissionActions:
                <InlinePermissionActions
                    namePluralizer={localization.sensitiveExcessivePermissionActions}
                    permissionActions={risk.sensitiveResources.sensitiveExcessivePermissionActions}
                    variant="itemOrItemCountAndType"/>,
            sensitiveResources:
                <InlineEntities
                    entityIdsOrModels={risk.sensitiveResources.ids}
                    entityTypeName={Contract.TypeNames.AwsResource}
                    namePluralizer={localization.sensitiveResources}
                    variant="itemAndTypeOrItemCountAndType"/>,
            services:
                <InlineEntities
                    entityIdsOrModels={excessivePermissionRoleGroupRiskModel.serviceIds}
                    entityTypeName={Contract.TypeNames.AwsService}
                    variant="itemAndTypeOrItemCountAndType"/>,
            servicesExcessivePermissionServiceUsageTime:
                _.isNil(excessivePermissionRoleGroupRiskModel.lastUsedExcessivePermissionServiceInfo)
                    ? roleGroupModel.oldestRoleCreationTime
                    : excessivePermissionRoleGroupRiskModel.lastUsedExcessivePermissionServiceInfo?.usageTime,
            servicesServiceUsageTime:
                _.isNil(excessivePermissionRoleGroupRiskModel.lastUsedServiceInfo)
                    ? roleGroupModel.oldestRoleCreationTime
                    : excessivePermissionRoleGroupRiskModel.lastUsedServiceInfo.usageTime,
            translatedRoleGroupTypeName:
                entityTypeNameTranslator(
                    roleGroup.typeName,
                    {
                        includeServiceName: false,
                        variant: "title"
                    })
        });

    const serviceIdToServiceResourceLevelMap =
        _.keyBy(
            risk.serviceResourceLevels,
            serviceResourceLevel => serviceResourceLevel.serviceId);

    return (
        <Steps>
            {_<string>([]).
                concat(
                    _.map(
                        excessivePermissionRoleGroupRiskModel.infos,
                        info => {
                            switch (info.type) {
                                case Contract.AwsExcessivePermissionRoleGroupRiskModelInfoType.Accounts:
                                case Contract.AwsExcessivePermissionRoleGroupRiskModelInfoType.ActivityTime:
                                case Contract.AwsExcessivePermissionRoleGroupRiskModelInfoType.OldestRoleCreationTime:
                                case Contract.AwsExcessivePermissionRoleGroupRiskModelInfoType.ExcessivePermissionActionSeverity:
                                case Contract.AwsExcessivePermissionRoleGroupRiskModelInfoType.PublicOriginatorResources:
                                case Contract.AwsExcessivePermissionRoleGroupRiskModelInfoType.Public:
                                case Contract.AwsExcessivePermissionRoleGroupRiskModelInfoType.UnknownAccounts:
                                case Contract.AwsExcessivePermissionRoleGroupRiskModelInfoType.Vendor:
                                case Contract.AwsExcessivePermissionRoleGroupRiskModelInfoType.VulnerableOriginatorWorkloadResources:
                                case Contract.AwsExcessivePermissionRoleGroupRiskModelInfoType.SensitiveResourcesSensitiveExcessivePermissionActionsAny:
                                case Contract.AwsExcessivePermissionRoleGroupRiskModelInfoType.ServiceLevelManyExcessivePermissionServicesAll:
                                case Contract.AwsExcessivePermissionRoleGroupRiskModelInfoType.ServiceLevelSingle:
                                case Contract.AwsExcessivePermissionRoleGroupRiskModelInfoType.Policies:
                                    return localization[Contract.TypeNames.AwsExcessivePermissionRoleGroupRiskModelInfoType][info.type](createInfoProps());
                                case Contract.AwsExcessivePermissionRoleGroupRiskModelInfoType.ServiceLevelManyExcessivePermissionServicesSome:
                                    return localization[Contract.TypeNames.AwsExcessivePermissionRoleGroupRiskModelInfoType][info.type](
                                        risk.serviceLevel!.excessivePermissionServiceIds.length,
                                        createInfoProps());
                                case Contract.AwsExcessivePermissionRoleGroupRiskModelInfoType.ServiceResourceLevel:
                                case Contract.AwsExcessivePermissionRoleGroupRiskModelInfoType.ServiceResourceLevelNotUsedPermissionActionResourcesAndUsedResources:
                                case Contract.AwsExcessivePermissionRoleGroupRiskModelInfoType.ServiceResourceLevelNotUsedPermissionActions:
                                case Contract.AwsExcessivePermissionRoleGroupRiskModelInfoType.ServiceResourceLevelNotUsedResources:
                                case Contract.AwsExcessivePermissionRoleGroupRiskModelInfoType.ServiceResourceLevelNotUsedResourcesAndNotUsedPermissionActions:
                                case Contract.AwsExcessivePermissionRoleGroupRiskModelInfoType.ServiceResourceLevelNotUsedResourcesAndUsedResources:
                                case Contract.AwsExcessivePermissionRoleGroupRiskModelInfoType.ServiceResourceLevelNotUsedResourcesAndUsedResourcesAndNotUsedPermissionActions: {
                                    const serviceExcessivenessInfo = info as Contract.AwsExcessivePermissionRoleGroupRiskModelServiceExcessivenessInfo;
                                    const serviceResourceLevel = serviceIdToServiceResourceLevelMap[serviceExcessivenessInfo.serviceId];
                                    const serviceResourceLevelProps =
                                        createInfoProps({
                                            serviceResourceLevelNotUsedPermissionActionResources:
                                                <InlineEntities
                                                    entityIdsOrModels={serviceResourceLevel.notUsedPermissionActionResourceIds}
                                                    entityTypeName={Contract.TypeNames.AwsResource}
                                                    variant="itemCountAndType"/>,
                                            serviceResourceLevelPermissionActions:
                                                <InlinePermissionActions
                                                    permissionActions={serviceResourceLevel.permissionActions}
                                                    variant="itemOrItemCountAndType"/>,
                                            serviceResourceLevelResources:
                                                <InlineEntities
                                                    entityIdsOrModels={serviceResourceLevel.resourceIds}
                                                    entityTypeName={Contract.TypeNames.AwsResource}
                                                    variant="itemAndTypeOrItemCountAndType"/>,
                                            serviceResourceLevelService:
                                                <Entity
                                                    entityIdOrModel={serviceResourceLevel.serviceId}
                                                    variant="text"/>,
                                            serviceResourceLevelUsedPermissionActions:
                                                <InlinePermissionActions
                                                    permissionActions={serviceResourceLevel.usedPermissionActions}
                                                    variant="itemCountAndType"/>,
                                            serviceResourceLevelUsedResources:
                                                <InlineEntities
                                                    entityIdsOrModels={serviceResourceLevel.usedResourceIds}
                                                    entityTypeName={Contract.TypeNames.AwsResource}
                                                    variant="itemCountAndType"/>
                                        });
                                    return localization[Contract.TypeNames.AwsExcessivePermissionRoleGroupRiskModelInfoType][info.type](serviceResourceLevelProps);
                                }
                                default:
                                    throw new UnexpectedError("info.type", info.type);
                            }
                        })).
                value()}
        </Steps>);
}


const resolutionSectionDefinitionComponent =
    makeAccessResolutionSectionDefinitionComponent(
        riskModel => {
            const excessivePermissionRoleGroupRiskModel = riskModel as Contract.AwsExcessivePermissionRoleGroupRiskModel;
            const policies =
                entityModelStore.useGet(
                    _.flatMap(
                        excessivePermissionRoleGroupRiskModel.risk.resolutionChanges,
                        resolutionChange => resolutionChange.entityIds)) as Contract.AwsIamPrincipalPolicyModel[];
            const policyMap =
                _.keyBy(
                    policies,
                    policy => policy.id);
            const roleGroupModel = entityModelStore.useGet(excessivePermissionRoleGroupRiskModel.risk.entityId) as AwsIamRoleGroupModel;
            const localization =
                useLocalization(
                    "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.access.useAwsExcessivePermissionRoleGroupRiskDefinition.useResolutionSectionOptions",
                    () => ({
                        steps: {
                            deleteInlinePolicy: "Delete inline policy **{{policyName}}**",
                            deleteManagedPolicy: "Detach managed policy **{{policyName}}**",
                            deletePolicies: "Remove all existing policies of {{roleGroup}} across {{accountIds}}"
                        }
                    }));

            const getPolicyDisplayName =
                (policyId: string) =>
                    excessivePermissionRoleGroupRiskModel.nonAwsManagedManagedPolicyIdToDisplayNameMap[policyId] ??
                    excessivePermissionRoleGroupRiskModel.nonAwsManagedInlinePolicyIdToDisplayNameMap[policyId] ??
                    policyMap[policyId].entity.displayName;

            return _.map(
                excessivePermissionRoleGroupRiskModel.risk.resolutionChanges,
                resolutionChange => {
                    if (resolutionChange.typeName === Contract.TypeNames.AwsUpsertPrincipalManagedPolicyChange) {
                        const upsertPrincipalManagedPolicyChange = resolutionChange as Contract.AwsUpsertPrincipalManagedPolicyChange;
                        return new ChangeStep(
                            upsertPrincipalManagedPolicyChange,
                            {
                                actionElements: [
                                    <UpsertPrincipalManagedPolicyChangeActions
                                        key={upsertPrincipalManagedPolicyChange.id}
                                        policyInfo={excessivePermissionRoleGroupRiskModel.risk.resolutionChangeIdToPolicyInfoMap[upsertPrincipalManagedPolicyChange.id]}
                                        riskModel={excessivePermissionRoleGroupRiskModel}
                                        upsertPrincipalManagedPolicyChange={upsertPrincipalManagedPolicyChange}/>
                                ]
                            });
                    }

                    return new Step(
                        localization.steps.deletePolicies({
                            accountIds:
                                <InlineEntities
                                    entityIdsOrModels={roleGroupModel.accountIds}
                                    entityTypeName={Contract.TypeNames.AwsOrganizationsAccount}
                                    entityTypeNameTranslatorOptions={{
                                        includeServiceName: false,
                                        variant: "text"
                                    }}
                                    variant="itemAndTypeOrItemCountAndType"/>,
                            roleGroup:
                                <Entity
                                    entityIdOrModel={roleGroupModel}
                                    glanceOptions={{ disabled: true }}
                                    linkOptions={{ disabled: true }}
                                    variant="typeText"/>
                        }),
                        {
                            contentElement:
                                <Steps variant="plainNumbers">
                                    {_.map(
                                        (resolutionChange as Contract.ChangeSet).changes,
                                        deletePrincipalPolicyChange => {
                                            const policyId = (deletePrincipalPolicyChange as Contract.AwsResourceChange).resourceId;
                                            return new ChangeStep(
                                                deletePrincipalPolicyChange,
                                                {
                                                    actionElements: [
                                                        <DeletePrincipalPolicyChangeActions
                                                            key={deletePrincipalPolicyChange.id}
                                                            policyInfo={excessivePermissionRoleGroupRiskModel.risk.resolutionChangeIdToPolicyInfoMap[deletePrincipalPolicyChange.id]}
                                                            policyName={getPolicyDisplayName(policyId)}/>
                                                    ],
                                                    title:
                                                        _.has(excessivePermissionRoleGroupRiskModel.nonAwsManagedInlinePolicyIdToDisplayNameMap, policyId)
                                                            ? localization.steps.deleteInlinePolicy({ policyName: getPolicyDisplayName(policyId) })
                                                            : _.has(excessivePermissionRoleGroupRiskModel.nonAwsManagedManagedPolicyIdToDisplayNameMap, policyId)
                                                                ? localization.steps.deleteManagedPolicy({ policyName: getPolicyDisplayName(policyId) })
                                                                : undefined
                                                });
                                        })}
                                </Steps>
                        });
                });
        });

type DeletePrincipalPolicyChangeActionsProps = {
    policyInfo: Contract.AwsExcessivePermissionPrincipalRiskResolutionPolicyInfo;
    policyName: string;
};

const modifyDocument = (document: string) => document.replaceAll("000000000000", "{ACCOUNT_ID}");

function DeletePrincipalPolicyChangeActions({ policyInfo, policyName }: DeletePrincipalPolicyChangeActionsProps) {
    const [policyDocument, setPolicyDocument] = useState(false);
    const [policyDocumentDiff, setPolicyDocumentDiff] = useState(false);
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.access.useAwsExcessivePermissionRoleGroupRiskDefinition.deletePrincipalPolicyChangeActions",
            () => ({
                actions: {
                    diff: "Diff",
                    json: "JSON"
                }
            }));
    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            direction="row">
            {policyDocument && (
                <DocumentViewerDialog
                    document={modifyDocument(policyInfo.existingDocument!.raw)}
                    documentFileName={`${policyName}.json`}
                    title={policyName}
                    onClose={() => setPolicyDocument(false)}/>)}
            {policyDocumentDiff && (
                <PrincipalRiskPolicyDiffDialog
                    existingPolicyItems={[
                        new RiskPolicyDiffPolicyItem(
                            policyName,
                            modifyDocument(policyInfo.existingDocument!.raw))]}
                    newPolicyItem={
                        _.isNil(policyInfo.newDocument)
                            ? undefined
                            : new RiskPolicyDiffPolicyItem(
                                policyName,
                                modifyDocument(policyInfo.newDocument.raw))}
                    policyFileName={policyName}
                    onClose={() => setPolicyDocumentDiff(false)}/>)}
            <ActionButton
                tooltip={localization.actions.json()}
                onClick={() => setPolicyDocument(true)}>
                <PolicyDocumentIcon
                    sx={{
                        color: theme.palette.text.primary,
                        fontSize: "18px"
                    }}/>
            </ActionButton>
            <ActionButton
                tooltip={localization.actions.diff()}
                onClick={() => setPolicyDocumentDiff(true)}>
                <DiffViewerIcon
                    sx={{
                        color: theme.palette.text.primary,
                        fontSize: "18px"
                    }}/>
            </ActionButton>
        </Stack>);
}

type UpsertPrincipalManagedPolicyChangeActionsProps = {
    policyInfo: Contract.AwsExcessivePermissionPrincipalRiskResolutionPolicyInfo;
    riskModel: Contract.AwsExcessivePermissionRoleGroupRiskModel;
    upsertPrincipalManagedPolicyChange: Contract.AwsUpsertPrincipalManagedPolicyChange;
};

function UpsertPrincipalManagedPolicyChangeActions({ policyInfo, riskModel, upsertPrincipalManagedPolicyChange }: UpsertPrincipalManagedPolicyChangeActionsProps) {
    const [policyDocumentDiff, setPolicyDocumentDiff] = useState(false);
    const [riskFile, setRiskFile] = useState<Contract.RiskFile>();
    const roleGroupModel = entityModelStore.useGet(riskModel.risk.entityId) as Contract.AwsIamRoleGroupModel;
    const existingManagedPolicyModels = entityModelStore.useGet((roleGroupModel.entity as Contract.AwsIamRoleGroup).awsManagedPolicyIds);
    const existingManagedPolicies =
        useMemo(
            () =>
                _.map(
                    existingManagedPolicyModels,
                    existingPolicyModel => existingPolicyModel.entity as Contract.AwsIamPrincipalPolicy),
            [existingManagedPolicyModels]);
    const existingRoleGroupPolicies = (roleGroupModel.entity as Contract.AwsIamRoleGroup).nonAwsManagedPolicies;

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.access.useAwsExcessivePermissionRoleGroupRiskDefinition.upsertPrincipalManagedPolicyChangeActions",
            () => ({
                actions: {
                    cloudFormation: "CloudFormation",
                    diff: "Diff",
                    json: "JSON",
                    terraform: "Terraform"
                }
            }));
    const theme = useTheme();

    return (
        <Stack
            alignItems="center"
            direction="row">
            {!_.isNil(riskFile) && (
                <DocumentViewerDialog
                    document={modifyDocument(utf8.decode(atob(riskFile.contentBytes)))}
                    documentFileName={riskFile.name}
                    title={riskFile.name}
                    onClose={() => setRiskFile(undefined)}/>)}
            {policyDocumentDiff && (
                <PrincipalRiskPolicyDiffDialog
                    existingPolicyItems={
                        _<RiskPolicyDiffPolicyItem>([]).
                            concat(
                                existingRoleGroupPolicies.map(
                                    existingRoleGroupPolicy =>
                                        new RiskPolicyDiffPolicyItem(
                                            existingRoleGroupPolicy.displayName,
                                            modifyDocument(existingRoleGroupPolicy.document.raw)))).
                            concat(
                                existingManagedPolicies.map(
                                    existingPolicy =>
                                        new RiskPolicyDiffPolicyItem(
                                            existingPolicy.displayName,
                                            modifyDocument(existingPolicy.document.raw)))).
                            value()}
                    newPolicyItem={
                        new RiskPolicyDiffPolicyItem(
                            upsertPrincipalManagedPolicyChange.name,
                            modifyDocument(policyInfo.newDocument!.raw))}
                    policyFileName={upsertPrincipalManagedPolicyChange.name}
                    onClose={() => setPolicyDocumentDiff(false)}/>)}
            <ActionButton
                tooltip={localization.actions.json()}
                onClick={() => setRiskFile(riskModel.upsertPrincipalManagedPolicyChangeIdToFileTypeToFileMap[upsertPrincipalManagedPolicyChange.id][Contract.RiskFileType.AwsPolicyDocumentRaw])}>
                <PolicyDocumentIcon
                    sx={{
                        color: theme.palette.text.primary,
                        fontSize: "18px"
                    }}/>
            </ActionButton>
            <ActionButton
                tooltip={localization.actions.diff()}
                onClick={() => setPolicyDocumentDiff(true)}>
                <DiffViewerIcon
                    sx={{
                        color: theme.palette.text.primary,
                        fontSize: "18px"
                    }}/>
            </ActionButton>
            <ActionButton
                tooltip={localization.actions.terraform()}
                onClick={() => setRiskFile(riskModel.upsertPrincipalManagedPolicyChangeIdToFileTypeToFileMap[upsertPrincipalManagedPolicyChange.id][Contract.RiskFileType.Terraform])}>
                <CodeTypeIcon
                    codeType={Contract.CodeType.Terraform}
                    sx={{ fontSize: "18px" }}/>
            </ActionButton>
            <ActionButton
                tooltip={localization.actions.cloudFormation()}
                onClick={() => setRiskFile(riskModel.upsertPrincipalManagedPolicyChangeIdToFileTypeToFileMap[upsertPrincipalManagedPolicyChange.id][Contract.RiskFileType.AwsCloudFormation])}>
                <CodeTypeIcon
                    codeType={Contract.CodeType.CloudFormation}
                    sx={{ fontSize: "18px" }}/>
            </ActionButton>
        </Stack>);
}