import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useGetAwsTenantEntityRiskContext } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";

export function useAwsTenantEntityCreationRiskDefinition(riskModel: Contract.RiskModel) {
    const tenantEntityCreationRisk = riskModel.risk as Contract.AwsTenantEntityCreationRisk;
    const tenantEntityModel = entityModelStore.useGet(tenantEntityCreationRisk.entityId) as Contract.AwsTenantEntityModel;

    const getTenantEntityRiskContext = useGetAwsTenantEntityRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsTenantEntityCreationRiskDefinition",
            () => ({
                description: {
                    knownCreator: "{{creatorIdentity}} created a new {{tenantEntity}}",
                    unknownCreator: "New {{tenantEntity}} was created"
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        (_.isNil(tenantEntityModel.creatorEntityIdReference)
            ? localization.description.unknownCreator
            : localization.description.knownCreator)({
            creatorIdentity:
                <Entity
                    entityIdOrModel={(tenantEntityModel as Contract.AwsTenantEntityModel).creatorEntityIdReference!}
                    variant="text"/>,
            tenantEntity:
                <Entity
                    entityIdOrModel={tenantEntityModel}
                    variant="typeText"/>
        }),
        () => [],
        riskModel,
        () => {
            const tenantEntityRiskContext = getTenantEntityRiskContext(tenantEntityModel);
            return [
                tenantEntityRiskContext.generalInformation,
                tenantEntityRiskContext.accountStatus,
                tenantEntityRiskContext.sensitive,
                tenantEntityRiskContext.path,
                tenantEntityRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        });
}