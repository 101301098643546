import { DataTable, DataTableColumn, DataTableColumnRenderProps, EmptyMessageText, Optional, useLocalization } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { Contract, EntitiesCell } from "../../../common";

type AwsCloudFrontDistributionBehaviorsInfoCardProps = {
    behaviors: Contract.AwsCloudFrontDistributionBehavior[];
    getHighlightColor?: (behavior: Contract.AwsCloudFrontDistributionBehavior, opacity?: number) => Optional<string>;
    originIdToResourceIdReferenceMap: Dictionary<string>;
};

export function AwsCloudFrontDistributionBehaviorsTable({ behaviors, getHighlightColor, originIdToResourceIdReferenceMap }: AwsCloudFrontDistributionBehaviorsInfoCardProps) {
    const localization =
        useLocalization(
            "tenants.aws.awsCloudFrontDistributionBehaviorsTable",
            () => ({
                columns: {
                    origins: "Origins",
                    pathPattern: "Path Pattern",
                    precedence: "Precedence",
                    viewerProtocolPolicy: {
                        title: "Viewer Protocol Policy",
                        [Contract.TypeNames.AwsCloudFrontDistributionBehaviorViewerProtocolPolicy]: {
                            [Contract.AwsCloudFrontDistributionBehaviorViewerProtocolPolicy.AllowAll]: "HTTP and HTTPS",
                            [Contract.AwsCloudFrontDistributionBehaviorViewerProtocolPolicy.HttpsOnly]: "HTTPS only",
                            [Contract.AwsCloudFrontDistributionBehaviorViewerProtocolPolicy.RedirectToHttps]: "Redirect HTTP to HTTPS"
                        }
                    }
                },
                empty: "No Behaviors"
            }));

    return (
        <DataTable
            emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty()) }}
            fetchItems={() => behaviors}
            getItemId={(behavior: Contract.AwsCloudFrontDistributionBehavior) => behavior.precedence.toString()}
            rowOptions={{
                getHighlightColor: (behavior: Contract.AwsCloudFrontDistributionBehavior) => getHighlightColor?.(behavior),
                getSx:
                    (behavior: Contract.AwsCloudFrontDistributionBehavior) => ({
                        backgroundColor: getHighlightColor?.(behavior, 0.1)
                    })
            }}
            sortOptions={{ enabled: false }}
            variant="card">
            <DataTableColumn
                id={CloudFrontDistributionBehaviorsColumnId.Precedence}
                itemProperty={(behavior: Contract.AwsCloudFrontDistributionBehavior) => behavior.precedence}
                title={localization.columns.precedence()}/>
            <DataTableColumn
                id={CloudFrontDistributionBehaviorsColumnId.ViewerProtocolPolicy}
                itemProperty={(behavior: Contract.AwsCloudFrontDistributionBehavior) => localization.columns.viewerProtocolPolicy[Contract.TypeNames.AwsCloudFrontDistributionBehaviorViewerProtocolPolicy][behavior.viewerProtocolPolicy]()}
                title={localization.columns.viewerProtocolPolicy.title()}/>
            <DataTableColumn
                id={CloudFrontDistributionBehaviorsColumnId.PathPattern}
                itemProperty={(behavior: Contract.AwsCloudFrontDistributionBehavior) => behavior.pathPattern}
                title={localization.columns.pathPattern()}/>
            <DataTableColumn
                id={CloudFrontDistributionBehaviorsColumnId.Origins}
                render={
                    ({ item: behavior }: DataTableColumnRenderProps<Contract.AwsCloudFrontDistributionBehavior>) =>
                        <EntitiesCell
                            entityIdsOrModels={
                                _.map(
                                    behavior.originIds,
                                    originId => originIdToResourceIdReferenceMap[originId])}
                            entityTypeName={Contract.TypeNames.AwsResource}
                            entityVariant="iconTextTypeTenant"/>}
                title={localization.columns.origins()}/>
        </DataTable>);
}

enum CloudFrontDistributionBehaviorsColumnId {
    Origins = "origins",
    PathPattern = "pathPattern",
    Precedence = "precedence",
    ViewerProtocolPolicy = "viewerProtocolPolicy"
}