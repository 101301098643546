import { AzureAuthorizationRoleAssignmentResourceHelper } from "../../../../../../tenants";
import { Contract } from "../../../../../controllers";
import { useEntityTypeNameTranslator } from "../../../../../hooks";
import { EntityDefinition } from "../useDefinition";

export function useAzureAuthorizationRoleAssignmentResourceDefinition(entityModel: Contract.EntityModel) {
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const roleAssignmentResourceModel = entityModel as Contract.AzureAuthorizationRoleAssignmentResourceModel;

    return new EntityDefinition(
        entityModel,
        {
            displayName: AzureAuthorizationRoleAssignmentResourceHelper.getDisplayName(entityTypeNameTranslator, roleAssignmentResourceModel),
            iconVariant:
                roleAssignmentResourceModel.unknown
                    ? Contract.AzureAuthorizationRoleDefinitionType.BuiltIn
                    : roleAssignmentResourceModel.roleDefinitionType
        });
}