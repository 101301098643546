import { Optional, PagedDropdownPage } from "@infrastructure";
import { Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { PagedSelector, ResourceTag } from ".";
import { Contract, EntityController, ResourceTagHelper } from "..";

type PagedResourceTagSelectorProps = {
    disabled?: boolean;
    entityTypeName?: string;
    multiSelect?: boolean;
    onSelectedTagsChanged: (tags: Contract.ResourceTag[]) => void;
    placeholder: string;
    readOnly?: boolean;
    scopeId: string;
    selectedTags: Contract.ResourceTag[];
    tenantTypes?: Contract.TenantType[];
};

export function PagedResourceTagSelector({ disabled = false, entityTypeName, multiSelect = true, onSelectedTagsChanged, placeholder, readOnly = false, scopeId, selectedTags, tenantTypes }: PagedResourceTagSelectorProps) {
    return (
        <PagedSelector
            disabled={disabled}
            fieldOptions={
                multiSelect
                    ? { dense: true }
                    : {
                        selection:
                            _.isNil(selectedTags[0])
                                ? undefined
                                : ResourceTagHelper.getDisplayName(selectedTags[0])
                    }}
            getItemPage={
                async (searchText: Optional<string>, skip: number, data?: number) => {
                    const limit = 50;
                    const { entityModelFilterItemPage } =
                        await EntityController.getEntityModelFilterItemPage(
                            new Contract.EntityControllerGetEntityModelFilterItemPageRequest(
                                entityTypeName,
                                limit,
                                Contract.EntityModelProperty.ResourceTags,
                                scopeId,
                                searchText,
                                skip,
                                tenantTypes)) as Contract.EntityControllerGetEntityModelFilterItemPageResponse<Contract.ResourceTag>;
                    const count = entityModelFilterItemPage?.count ?? data!;
                    return new PagedDropdownPage(
                        skip + limit < count,
                        _.map(
                            ResourceTagHelper.getDatas(entityModelFilterItemPage.items),
                            resourceTagData => resourceTagData.id),
                        () => count);
                }}
            multiSelect={multiSelect}
            placeholder={placeholder}
            readOnly={readOnly}
            selectedItems={
                _.map(
                    ResourceTagHelper.getDatas(selectedTags),
                    resourceTagData => resourceTagData.id)}
            onSelectedItemsChanged={tagIds => onSelectedTagsChanged(ResourceTagHelper.parse(tagIds)!)}>
            {(tagId: string) =>
                <Typography noWrap={true}>
                    <ResourceTag resourceTag={_.first(ResourceTagHelper.parse([tagId]))!}/>
                </Typography>}
        </PagedSelector>);
}