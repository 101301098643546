﻿import { UnexpectedError } from "@infrastructure";
import { useMemo } from "react";
import { useOpContainerImageDefinition, useOpKubernetesResourceDefinition, useOpVirtualMachineDefinition } from ".";
import { Contract, TypeHelper } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";

export function useOpResourceDefinition(definitionData: DefinitionData) {
    const useDefinition =
        useMemo(
            () => {
                switch (definitionData.entityTypeEntitiesViewName) {
                    case Contract.TypeNames.OpContainerImage:
                        return useOpContainerImageDefinition;
                    case Contract.TypeNames.OpVirtualMachine:
                        return useOpVirtualMachineDefinition;
                    default:
                        if (TypeHelper.extendOrImplement(definitionData.entityTypeEntitiesViewName, Contract.TypeNames.OpKubernetesResource)) {
                            return useOpKubernetesResourceDefinition;
                        }
                        throw new UnexpectedError("definitionData.entityTypeEntitiesViewName", definitionData.entityTypeEntitiesViewName);
                }
            },
            []);

    return useDefinition(definitionData);
}