import { Tooltip } from "@infrastructure";
import { Box, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { ComplianceSecuredBar, Contract, useComplianceTranslator, useTheme } from "../../../../../../../../../../../../../common";
import { useItemContext } from "../../../../../../../Profile";

export type SectionItemDetailsProps = {
    sectionData: Contract.ComplianceSectionData;
};

export function SectionItemDetails({ sectionData }: SectionItemDetailsProps) {
    const { disabled } = useItemContext();
    const complianceTranslator = useComplianceTranslator();
    const { description, title } = complianceTranslator(sectionData.identifier);
    const theme = useTheme();
    return (
        <Tooltip
            disableInteractive={true}
            placement="bottom-start"
            sx={{ maxWidth: "500px" }}
            titleOrGetTitle={
                _.isEmpty(description)
                    ? undefined
                    : description}>
            <Stack
                direction="row"
                spacing={1}
                sx={{
                    alignItems: "center",
                    justifyContent: "space-between",
                    overflow: "hidden",
                    width: "100%"
                }}>
                <Typography
                    noWrap={true}
                    sx={{ fontWeight: 500 }}
                    variant="h5">
                    {title}
                </Typography>
                <Box sx={{ minWidth: theme.spacing(20) }}>
                    <ComplianceSecuredBar
                        data={sectionData}
                        disabled={disabled}/>
                </Box>
            </Stack>
        </Tooltip>);
}