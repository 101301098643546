import { IconNodeContent, NodeContentContextMenuItem, Optional, TextViewer, useDirectedGraphNodeContext, useLocalization, useSetDirectedGraphContext, useSetRoute } from "@infrastructure";
import { Box, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { MutableRefObject, ReactNode } from "react";
import { Contract, CustomerConsoleAppUrlHelper, Entity, entityModelStore, getEntityNodeContentSize, useEntityTypeNameTranslator, useNodeContentFilterContextMenuItems, useTheme } from "../../../../..";
import { AccessDefinitionToolbarActions, ToolbarFilterId } from "..";

type PermitterDefinitionNodeContentProps = {
    icon: ReactNode;
    onViewText: (entityModel: Contract.EntityModel) => string;
    options?: PermitterDefinitionNodeContent;
    permitterEntityId: string;
    toolbarActionsRef: MutableRefObject<Optional<AccessDefinitionToolbarActions>>;
    variant: "large" | "small";
};

type PermitterDefinitionNodeContent = {
    subtitle?: ReactNode;
    title?: ReactNode;
};

export function PermitterDefinitionNodeContent({ icon, onViewText, options, permitterEntityId, toolbarActionsRef, variant }: PermitterDefinitionNodeContentProps) {
    const { modelNode } = useDirectedGraphNodeContext();
    const setDirectedGraphContext = useSetDirectedGraphContext();
    const entityModel = entityModelStore.useGet(permitterEntityId);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "common.access.hooks.useDefinition.permitterDefinitionNodeContent",
            () => ({
                menu: {
                    profile: "Go to {{translatedEntityTypeName}} profile page",
                    viewText: "View JSON"
                }
            }));
    const filterContextMenuItems =
        useNodeContentFilterContextMenuItems(
            1,
            <Entity
                entityIdOrModel={permitterEntityId}
                linkOptions={{ disabled: true }}
                variant="text"/>,
            type =>
                toolbarActionsRef.current!.filter(
                    ToolbarFilterId.PermitterIds,
                    type,
                    [permitterEntityId]));

    const setRoute = useSetRoute();
    const theme = useTheme();
    return (
        <IconNodeContent
            contextMenuItems={[
                new NodeContentContextMenuItem(
                    () => setRoute(CustomerConsoleAppUrlHelper.getEntityProfileRelativeUrl(entityModel)!),
                    localization.menu.profile({
                        translatedEntityTypeName:
                            entityTypeNameTranslator(
                                entityModel.entity.typeName,
                                {
                                    includeServiceName: false,
                                    variant: "text"
                                })
                    })),
                new NodeContentContextMenuItem(
                    () =>
                        setDirectedGraphContext(
                            context => ({
                                ...context,
                                dialogContentElement:
                                    <Stack
                                        spacing={1}
                                        sx={{
                                            height: "100%",
                                            padding: theme.spacing(2),
                                            width: "100%"
                                        }}>
                                        <Box>
                                            <Entity
                                                entityIdOrModel={entityModel}
                                                variant="iconTextTenant"/>
                                        </Box>
                                        <Box sx={{ flex: 1 }}>
                                            <TextViewer
                                                format="json"
                                                height="100%"
                                                text={onViewText(entityModel)}/>
                                        </Box>
                                    </Stack>
                            })),
                    localization.menu.viewText()),
                ...filterContextMenuItems
            ]}
            iconOptions={{
                icon,
                shape: "rectangle"
            }}
            size={getEntityNodeContentSize("bottom", variant)}
            titleElementOrOptions={
                _.isNil(options)
                    ? <Typography align="center">
                        <Entity
                            entityIdOrModel={entityModel}
                            glanceOptions={{ miniGlancePlacement: "center" }}
                            linkOptions={{
                                disabled: true,
                                disabledHighlight: false
                            }}
                            sxOptions={{
                                title: {
                                    color:
                                        modelNode.options.appearance === "highlighted"
                                            ? theme.palette.text.primary
                                            : theme.palette.text.secondary,
                                    fontSize: "14px"
                                }
                            }}
                            tenantNameTranslatorOptions={{ includeRawId: false }}
                            variant="text"/>
                    </Typography>
                    : {
                        subtitle: options.subtitle,
                        title: options.title
                    }}/>);
}