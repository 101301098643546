import { CollapsedIcon, Optional, useActions, useLocalization } from "@infrastructure";
import { Check } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Box, Stack, Typography } from "@mui/material";
import { TOptions } from "i18next";
import React, { Ref, useEffect, useState } from "react";
import { Contract } from "../../../../../controllers";
import { useEntityTypeNameTranslator } from "../../../../../hooks";
import { useTheme } from "../../../../../themes";
import { CustomRiskPolicyActions, useCustomRiskPolicyContext } from "../../../CustomRiskPolicy";
import { EntitySelector, EntitySelectorSelection } from "../../EntitySelector";
import { InlineEntitySelection } from "../../InlineEntitySelection";

type EditProps = {
    actionsRef: Ref<Optional<CustomRiskPolicyActions>>;
    onValidChange: (valid: boolean) => void;
    riskPolicyConfiguration?: Contract.AwsEncryptedResourceKmsEncryptionNotExistsRiskPolicyConfiguration;
    templateTranslator: (options?: TOptions) => string;
};

export function Edit({ actionsRef, onValidChange, riskPolicyConfiguration, templateTranslator }: EditProps) {
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const { scopeId } = useCustomRiskPolicyContext();
    const [encryptedResourcesValid, setEncryptedResourcesValid] = useState(false);
    const [kmsKeysValid, setKmsKeysValid] = useState(false);
    const [view, setView] = useState<EditView | undefined>(EditView.Resources);
    const [encryptedResourceSelectorSelection, setEncryptedResourceSelectorSelection] =
        useState<Optional<EntitySelectorSelection>>(
            EntitySelectorSelection.getSelectorSelection(
                riskPolicyConfiguration?.allEncryptedResources,
                undefined,
                undefined,
                riskPolicyConfiguration?.encryptedResourceBuiltInAttributeTypeNames,
                undefined,
                riskPolicyConfiguration?.encryptedResourceCustomAttributeDefinitionIds,
                undefined,
                riskPolicyConfiguration?.encryptedResourceIds,
                undefined,
                undefined,
                riskPolicyConfiguration?.encryptedResourceTags,
                undefined,
                riskPolicyConfiguration?.encryptedResourceTypeNames));
    const [kmsKeySelectorSelection, setKmsKeySelectorSelection] =
        useState<Optional<EntitySelectorSelection>>(
            EntitySelectorSelection.getSelectorSelection(
                undefined,
                riskPolicyConfiguration?.anyKmsKey,
                riskPolicyConfiguration?.anyCustomerManagedKmsKey,
                riskPolicyConfiguration?.kmsKeyBuiltInAttributeTypeNames,
                undefined,
                riskPolicyConfiguration?.kmsKeyCustomAttributeDefinitionIds,
                undefined,
                riskPolicyConfiguration?.kmsKeyIds,
                undefined,
                undefined,
                riskPolicyConfiguration?.kmsKeyTags));
    useActions(
        actionsRef,
        {
            createRequest:
                (description: Optional<string>, name: string, scopeId: string, severity: Contract.Severity) => {
                    const { allEntities, entityBuiltInAttributeTypeNames, entityCustomAttributeDefinitionIds, entityIds, entityTags, entityTypeNames } = encryptedResourceSelectorSelection!.getInlineSelectionData();
                    const { anyCustomerManagedKmsKey, anyEntity: anyKmsKey, entityBuiltInAttributeTypeNames: keyBuiltInAttributeTypeNames, entityCustomAttributeDefinitionIds: keyCustomAttributeDefinitionIds, entityIds: keyIds, entityTags: keyTags } = kmsKeySelectorSelection!.getInlineSelectionData();
                    return new Contract.RiskControllerInsertAwsEncryptedResourceKmsEncryptionNotExistsRiskPolicyRequest(
                        description,
                        name,
                        scopeId,
                        severity,
                        allEntities ?? false,
                        anyCustomerManagedKmsKey ?? false,
                        anyKmsKey ?? false,
                        entityBuiltInAttributeTypeNames,
                        entityCustomAttributeDefinitionIds,
                        entityIds,
                        entityTags,
                        entityTypeNames,
                        keyBuiltInAttributeTypeNames,
                        keyCustomAttributeDefinitionIds,
                        keyIds,
                        keyTags);
                }
        });
    useEffect(
        () => {
            const resourcesValid = encryptedResourceSelectorSelection?.valid() ?? false;
            const keysValid = kmsKeySelectorSelection?.valid() ?? false;
            setEncryptedResourcesValid(resourcesValid);
            setKmsKeysValid(keysValid);

            onValidChange(
                keysValid &&
                resourcesValid);
        },
        [encryptedResourceSelectorSelection, kmsKeySelectorSelection]);

    const localization =
        useLocalization(
            "common.customRiskPolicy.awsEncryptedResourceKmsEncryptionNotExistsRiskPolicy.components.edit",
            () => ({
                prompt: "Select the required resources and keys"
            }));

    const theme = useTheme();
    return (
        <Stack spacing={2}>
            <Typography variant="h5">
                {localization.prompt()}
            </Typography>
            <Stack spacing={1}>
                <Typography
                    sx={{
                        backgroundColor: theme.palette.background.alternate,
                        borderRadius: theme.spacing(0.75),
                        padding: theme.spacing(2)
                    }}>
                    {templateTranslator({
                        keys:
                            <InlineEntitySelection
                                entityTypeName={Contract.TypeNames.AwsKmsKey}
                                selection={kmsKeySelectorSelection}/>,
                        resources:
                            <InlineEntitySelection
                                entityTypeName={Contract.TypeNames.IAwsEncryptedResource}
                                selection={encryptedResourceSelectorSelection}/>
                    })}
                </Typography>
                <Box>
                    <Accordion
                        expanded={view === EditView.Resources}
                        onChange={
                            (_event, expanded) =>
                                setView(
                                    expanded
                                        ? EditView.Resources
                                        : undefined)}>
                        <AccordionSummary expandIcon={<CollapsedIcon/>}>
                            <Stack
                                alignItems="center"
                                direction="row"
                                spacing={2}
                                sx={{ width: "100%" }}>
                                <Typography
                                    sx={{ flex: 1 }}
                                    variant="h5">
                                    {entityTypeNameTranslator(
                                        Contract.TypeNames.IAwsEncryptedResource,
                                        {
                                            count: 0,
                                            includeServiceName: false
                                        })}
                                </Typography>
                                {encryptedResourcesValid &&
                                    <Check
                                        sx={{
                                            color: theme.palette.success.main,
                                            fontSize: "18px"
                                        }}/>}
                            </Stack>
                        </AccordionSummary>
                        <AccordionDetails>
                            <EntitySelector
                                builtInEntityAttributeTypeNames={[
                                    Contract.TypeNames.PublicEntityAttribute,
                                    Contract.TypeNames.SensitiveResourceAttribute,
                                    Contract.TypeNames.CodeResourceAttribute
                                ]}
                                entityTypeName={Contract.TypeNames.IAwsEncryptedResource}
                                properties={["all", "typeNames", "tags", "attributes"]}
                                scopeId={scopeId}
                                selection={encryptedResourceSelectorSelection}
                                onSelectionChanged={setEncryptedResourceSelectorSelection}/>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        expanded={view === EditView.Keys}
                        onChange={
                            (_event, expanded) =>
                                setView(
                                    expanded
                                        ? EditView.Keys
                                        : undefined)}>
                        <AccordionSummary expandIcon={<CollapsedIcon/>}>
                            <Stack
                                alignItems="center"
                                direction="row"
                                spacing={2}
                                sx={{ width: "100%" }}>
                                <Typography
                                    sx={{ flex: 1 }}
                                    variant="h5">
                                    {entityTypeNameTranslator(
                                        Contract.TypeNames.AwsKmsKey,
                                        {
                                            count: 0,
                                            includeServiceName: false
                                        })}
                                </Typography>
                                {kmsKeysValid &&
                                    <Check
                                        sx={{
                                            color: theme.palette.success.main,
                                            fontSize: "18px"
                                        }}/>}
                            </Stack>
                        </AccordionSummary>
                        <AccordionDetails>
                            <EntitySelector
                                builtInEntityAttributeTypeNames={[
                                    Contract.TypeNames.PublicEntityAttribute,
                                    Contract.TypeNames.SensitiveResourceAttribute,
                                    Contract.TypeNames.CodeResourceAttribute
                                ]}
                                entityTypeName={Contract.TypeNames.AwsKmsKey}
                                properties={["any", "anyCustomerManagedKmsKey", "tags", "attributes"]}
                                scopeId={scopeId}
                                selection={kmsKeySelectorSelection}
                                onSelectionChanged={setKmsKeySelectorSelection}/>
                        </AccordionDetails>
                    </Accordion>
                </Box>
            </Stack>
        </Stack>);
}

enum EditView {
    Keys = "keys",
    Resources = "resources"
}