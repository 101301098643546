import { ItemWithValidation, useChangeEffect } from "@infrastructure";
import _ from "lodash";
import React, { Fragment, useState } from "react";
import { TeamsChannelReferenceMultiSelect } from "../..";
import { Contract } from "../../../controllers";
import { DeliveryProps } from "../Delivery";
import { Message } from "./Message";

export function Teams({ delivery, disabled, iconVisible, messageOptions, onChange, scopeId }: DeliveryProps) {
    const [teamsChannelReferences, setTeamsChannelReferences] =
        useState(
            _.map(
                (delivery.destinations ?? []) as Contract.TeamsDeliveryChannel[],
                ({ teamsChannelReference }) => teamsChannelReference));
    const [message, setMessage] = useState(delivery?.message);

    useChangeEffect(
        () =>
            onChange({
                ...(delivery as Contract.TeamsDelivery),
                destinations:
                    _.map(
                        teamsChannelReferences,
                        teamsChannelReference => ({
                            teamsChannelReference,
                            typeName: Contract.TypeNames.TeamsDeliveryChannel
                        })) as Contract.TeamsDeliveryChannel[],
                message,
                valid: !_.isEmpty(teamsChannelReferences)
            } as ItemWithValidation<Contract.TeamsDelivery>),
        [teamsChannelReferences, message],
        500);

    return (
        <Fragment>
            <TeamsChannelReferenceMultiSelect
                disabled={disabled}
                iconVisible={iconVisible}
                scopeId={scopeId}
                selectedChannelReferences={teamsChannelReferences}
                onSelectedChannelReferencesChanged={setTeamsChannelReferences}/>
            <Message
                disabled={disabled}
                message={message}
                options={messageOptions}
                setMessage={setMessage}/>
        </Fragment>);
}