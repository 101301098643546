import _, { Dictionary } from "lodash";
import { useMemo } from "react";
import { Optional, Store } from "@infrastructure";
import { Contract, ScopeController, ScopeHelper, scopeSystemEntityModelStore, TypeHelper, UserHelper } from "..";

export class ScopeNode {
    private _childScopeIds?: string[];
    private _folderScopeIds?: string[];
    private _parentScopeIds?: string[];
    private _path?: string;
    private _rootFolderId?: string;
    private _scopeIds?: string[];
    private _tenantIds?: string[];
    private _tenantTypes?: Contract.TenantType[];

    public scopeNodes: ScopeNode[] = [];

    constructor(
        public parentScopeNode: Optional<ScopeNode>,
        public scopeNodeModel: Contract.ScopeNodeModel) {
    }

    public get childScopeIds(): string[] {
        if (_.isNil(this._childScopeIds)) {
            this._childScopeIds =
                _(this.scopeNodes).
                    flatMap(scopeNode => scopeNode.scopeIds).
                    value();
        }

        return this._childScopeIds;
    }

    public get folderScopeIds(): string[] {
        if (_.isNil(this._folderScopeIds)) {
            this._folderScopeIds =
                _(this.scopeNodes).
                    flatMap(scopeNode => scopeNode.folderScopeIds).
                    concatIf(
                        ScopeHelper.isFolder(this.scopeNodeModel),
                        this.scopeNodeModel.configuration.id).
                    value();
        }

        return this._folderScopeIds;
    }

    public get parentScopeIds(): string[] {
        if (_.isNil(this._parentScopeIds)) {
            this._parentScopeIds =
                _.isNil(this.parentScopeNode)
                    ? []
                    : [
                        this.parentScopeNode.scopeNodeModel.configuration.id,
                        ...this.parentScopeNode.parentScopeIds
                    ];
        }

        return this._parentScopeIds;
    }

    public get path(): string {
        if (_.isNil(this._path)) {
            this._path =
                _.isNil(this.parentScopeNode)
                    ? ScopeHelper.customerDisplayName
                    : `${this.parentScopeNode.path}/${this.scopeNodeModel.configuration.name}`;
        }

        return this._path;
    }

    public get rootFolderId(): Optional<string> {
        if (_.isNil(this.parentScopeNode)) {
            return undefined;
        } else if (ScopeHelper.isRootFolder(this.scopeNodeModel)) {
            return this.scopeNodeModel.configuration.id;
        } else if (_.isNil(this._rootFolderId)) {
            this._rootFolderId = this.parentScopeNode.rootFolderId;
        }

        return this._rootFolderId;
    }

    public get scopeIds(): string[] {
        if (_.isNil(this._scopeIds)) {
            this._scopeIds =
                _(this.childScopeIds).
                    concat(this.scopeNodeModel.configuration.id).
                    value();
        }

        return this._scopeIds;
    }

    public get tenantIds(): string[] {
        if (_.isNil(this._tenantIds)) {
            this._tenantIds =
                _(this.scopeNodes).
                    flatMap(scopeNode => scopeNode.tenantIds).
                    concatIf(
                        ScopeHelper.isTenant(this.scopeNodeModel),
                        this.scopeNodeModel.configuration.id).
                    value();
        }

        return this._tenantIds;
    }

    public getTenantTypes(filterTenantTypes?: Contract.TenantType[]): Contract.TenantType[] {
        if (_.isNil(this._tenantTypes)) {
            this._tenantTypes =
                _(this.scopeNodes).
                    flatMap(scopeNode => scopeNode.getTenantTypes()).
                    concatIf(
                        TypeHelper.extendOrImplement(this.scopeNodeModel.configuration.typeName, Contract.TypeNames.TenantConfiguration),
                        (this.scopeNodeModel.configuration as Contract.TenantConfiguration).type).
                    uniq().
                    value();
        }

        return _.isNil(filterTenantTypes)
            ? this._tenantTypes
            : _.intersection(
                this._tenantTypes,
                filterTenantTypes);
    }
}

export class ScopeNodeModelStore extends Store<Contract.ScopeNodeModel, never, never> {
    private activeScopeNodeMap?: Dictionary<ScopeNode>;
    private scopeNodeMap?: Dictionary<ScopeNode>;
    private scopeNodeModels?: Contract.ScopeNodeModel[];

    public getScopeNodeMap =
        (active: boolean, allScopeNodeModels: Contract.ScopeNodeModel[], tenantTypes?: Contract.TenantType[], includeProjects = false) => {
            function getScopeNodeMap(scopeNodeModels: Contract.ScopeNodeModel[]) {
                const scopeNodeModelMap =
                    _.keyBy(
                        scopeNodeModels,
                        scopeNodeModel => scopeNodeModel.configuration.id);

                const scopeNodeMap: Dictionary<ScopeNode> = {};

                function getOrAddScopeNode(scopeNodeModel: Contract.ScopeNodeModel) {
                    let scopeNode = scopeNodeMap[scopeNodeModel.configuration.id];
                    if (_.isNil(scopeNode)) {
                        const parentScopeId = ScopeHelper.tryGetParentScopeId(scopeNodeModel);
                        scopeNode =
                            new ScopeNode(
                                _.isNil(parentScopeId)
                                    ? undefined
                                    : getOrAddScopeNode(scopeNodeModelMap[parentScopeId]),
                                scopeNodeModel);
                        scopeNodeMap[scopeNodeModel.configuration.id] = scopeNode;
                        scopeNode.parentScopeNode?.scopeNodes.push(scopeNode);
                    }

                    return scopeNode;
                }

                _.each(
                    scopeNodeModelMap,
                    scopeNodeModel => getOrAddScopeNode(scopeNodeModel));

                return scopeNodeMap;
            }

            const scopeNodeModels =
                includeProjects
                    ? allScopeNodeModels
                    : _.filter(
                        allScopeNodeModels,
                        scopeNodeModel => scopeNodeModel.type !== Contract.ScopeType.Project);

            if (_.isNil(this.scopeNodeModels) ||
                this.scopeNodeModels.length !== scopeNodeModels.length ||
                !_(scopeNodeModels).
                    xor(this.scopeNodeModels).
                    isEmpty()) {
                this.scopeNodeModels = scopeNodeModels;
                this.activeScopeNodeMap = undefined;
                this.scopeNodeMap = undefined;
            }

            let scopeNodeMap: Dictionary<ScopeNode>;
            if (active) {
                if (_.isNil(this.activeScopeNodeMap)) {
                    this.activeScopeNodeMap =
                        getScopeNodeMap(
                            _.filter(
                                scopeNodeModels,
                                scopeNodeModel =>
                                    scopeNodeModel.type === Contract.ScopeType.Customer ||
                                    ScopeHelper.isFolder(scopeNodeModel) ||
                                    scopeNodeModel.type === Contract.ScopeType.Project ||
                                    (scopeNodeModel.configuration as Contract.TenantConfiguration).active));
                }

                scopeNodeMap = this.activeScopeNodeMap!;
            } else {
                if (_.isNil(this.scopeNodeMap)) {
                    this.scopeNodeMap = getScopeNodeMap(scopeNodeModels);
                }

                scopeNodeMap = this.scopeNodeMap!;
            }

            if (_.isNil(tenantTypes)) {
                return scopeNodeMap;
            } else {
                const tenantTypeScopeNodeMap =
                    _.pickBy(
                        scopeNodeMap,
                        scopeNode =>
                            _.includes(
                                tenantTypes,
                                ScopeHelper.getTenantType(scopeNode.scopeNodeModel)));
                tenantTypeScopeNodeMap[ScopeHelper.customerId] =
                    new ScopeNode(
                        undefined,
                        scopeNodeMap[ScopeHelper.customerId].scopeNodeModel);
                tenantTypeScopeNodeMap[ScopeHelper.customerId].scopeNodes =
                    _.filter(
                        tenantTypeScopeNodeMap,
                        tenantTypeScopeNode => ScopeHelper.isRootFolder(tenantTypeScopeNode.scopeNodeModel));
                return tenantTypeScopeNodeMap;
            }
        };

    public useGetScopeNodeMap =
        (tenantTypes?: Contract.TenantType[], includeProjects = false) =>
            this.useGetScopeNodeMapCore(
                false,
                tenantTypes,
                includeProjects);

    public useGetActiveScopeNodeMap =
        (tenantTypes?: Contract.TenantType[], includeProjects = false) =>
            this.useGetScopeNodeMapCore(
                true,
                tenantTypes,
                includeProjects);

    private useGetScopeNodeMapCore =
        (active: boolean, tenantTypes?: Contract.TenantType[], includeProjects = false) => {
            const allScopeNodeModels = this.useGetAll();
            return useMemo(
                () => this.getScopeNodeMap(active, allScopeNodeModels, tenantTypes, includeProjects),
                [active, allScopeNodeModels, tenantTypes, includeProjects]);
        };
}

export const scopeNodeModelStore =
    new ScopeNodeModelStore(
        scopeNodeModel => scopeNodeModel.configuration.id,
        {
            get:
                async ids => {
                    const { scopeNodeModels } = await ScopeController.getScopeNodeModels(new Contract.ScopeControllerGetScopeNodeModelsRequest(ids));
                    return scopeNodeModels;
                },
            getAll:
                async () => {
                    const { scopeNodeModels } = await ScopeController.getScopeNodeModels(new Contract.ScopeControllerGetScopeNodeModelsRequest(undefined));
                    return scopeNodeModels;
                },
            onNotify:
                async () => {
                    await UserHelper.initialize();
                },
            onNotifyDeleted:
                async () => {
                    await scopeSystemEntityModelStore.notify();
                }
        });