import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, EnumValuesFilter, Optional, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, tenantModelStore, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { useAadDirectoryGroupTypeTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";
import { useAadCommonDirectoryPrincipalDefinition } from "./useAadCommonDirectoryPrincipalDefinition";

export function useAadDirectoryGroupDefinition(definitionData: DefinitionData) {
    const activeCloudProviderTenantTypes = tenantModelStore.useGetActiveCloudProviderTenantTypes();
    const commonDirectoryPrincipalDefinition = useAadCommonDirectoryPrincipalDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const directoryGroupTypeTranslator = useAadDirectoryGroupTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.aad.useAadDirectoryGroupDefinition",
            () => ({
                columns: {
                    aadRoleAssignmentResources: "Microsoft Entra ID Administrative Role Assignments",
                    adOrigin: {
                        false: "No",
                        title: "On-premises sync",
                        true: "Yes"
                    },
                    awsRoles: "AWS Roles",
                    memberPrincipals: "Members",
                    membershipType: {
                        title: "Membership Type",
                        [Contract.TypeNames.AadDirectoryGroupMembershipType]: {
                            [Contract.AadDirectoryGroupMembershipType.Assigned]: "Assigned",
                            [Contract.AadDirectoryGroupMembershipType.Dynamic]: "Dynamic"
                        }
                    },
                    ownerPrincipalIds: "Owners",
                    type: "Group Type"
                }
            }));
    return new EntityTableDefinition(
        _.filter([
            commonDirectoryPrincipalDefinition.columns.tenantColumn,
            commonDirectoryPrincipalDefinition.columns.creationTimeColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AadDirectoryGroupModel) => ({
                            [localization.columns.type()]: directoryGroupTypeTranslator((item.entity as Contract.AadDirectoryGroup).type)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.AadDirectoryGroupType}
                                enumTypeTranslator={directoryGroupTypeTranslator}
                                exclude={[Contract.AadDirectoryGroupType.Unknown]}
                                placeholder={localization.columns.type()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AadDirectoryGroupType}
                itemProperty={(item: Contract.EntityModel) => directoryGroupTypeTranslator((item.entity as Contract.AadDirectoryGroup).type)}
                key={Contract.EntityModelProperty.AadDirectoryGroupType}
                title={localization.columns.type()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AadDirectoryGroupModel) => ({
                            [localization.columns.adOrigin.title()]:
                                (item.entity as Contract.AadDirectoryGroup).adOrigin
                                    ? localization.columns.adOrigin.true()
                                    : localization.columns.adOrigin.false()
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <ValuesFilter
                                placeholder={localization.columns.adOrigin.title()}
                                sorted={false}>
                                <ValuesFilterItem
                                    title={localization.columns.adOrigin.true()}
                                    value={true}/>
                                <ValuesFilterItem
                                    title={localization.columns.adOrigin.false()}
                                    value={false}/>
                            </ValuesFilter>
                    }
                }}
                id={Contract.EntityModelProperty.AadDirectoryGroupAdOrigin}
                itemProperty={
                    (item: Contract.AadDirectoryGroupModel) =>
                        (item.entity as Contract.AadDirectoryGroup).adOrigin
                            ? localization.columns.adOrigin.true()
                            : localization.columns.adOrigin.false()}
                key={Contract.EntityModelProperty.AadDirectoryGroupAdOrigin}
                title={localization.columns.adOrigin.title()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AadDirectoryGroupModel) => ({
                            [localization.columns.membershipType.title()]: localization.columns.membershipType[Contract.TypeNames.AadDirectoryGroupMembershipType][(item.entity as Contract.AadDirectoryGroup).membershipType]()
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.AadDirectoryGroupMembershipType}
                                enumTypeTranslator={(membershipType: Contract.AadDirectoryGroupMembershipType) => localization.columns.membershipType[Contract.TypeNames.AadDirectoryGroupMembershipType][membershipType]()}
                                placeholder={localization.columns.membershipType.title()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AadDirectoryGroupMembershipType}
                itemProperty={(item: Contract.EntityModel) => localization.columns.membershipType[Contract.TypeNames.AadDirectoryGroupMembershipType][(item.entity as Contract.AadDirectoryGroup).membershipType]()}
                key={Contract.EntityModelProperty.AadDirectoryGroupMembershipType}
                title={localization.columns.membershipType.title()}/>,
            _.includes(activeCloudProviderTenantTypes, Contract.TenantType.Aws)
                ? <DataTableColumn
                    exportOptions={
                        entitiesExportOptions<Contract.AadDirectoryGroupModel>(
                            Contract.TypeNames.AwsIamRole,
                            item => item.awsRoleIds,
                            localization.columns.awsRoles())}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedEntityFilter
                                    getEntityIdPage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.AadDirectoryGroupAwsRoles)}
                                    placeholder={localization.columns.awsRoles()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AadDirectoryGroupAwsRoles}
                    key={Contract.EntityModelProperty.AadDirectoryGroupAwsRoles}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.AadDirectoryGroupModel>) =>
                            <EntitiesCell
                                entityIdsOrModels={item.awsRoleIds}
                                entityTypeName={Contract.TypeNames.AwsIamRole}
                                entityVariant="iconText"/>}
                    sortOptions={{ type: DataTableSortType.Numeric }}
                    title={localization.columns.awsRoles()}/>
                : undefined!,
            commonDirectoryPrincipalDefinition.columns.azureRoleAssignmentResourcesColumn,
            commonDirectoryPrincipalDefinition.columns.azureRoleDefinitionsColumn,
            commonDirectoryPrincipalDefinition.columns.containedAzureRoleAssignmentResourcesColumn,
            commonDirectoryPrincipalDefinition.columns.directoryRoleAssignmentResourcesColumn,
            commonDirectoryPrincipalDefinition.columns.directoryRoleDefinitionsColumn,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.AadDirectoryGroupModel>(
                        Contract.TypeNames.AadDirectoryDirectoryRoleAssignment,
                        item => item.directoryRoleAssignmentResourceIds,
                        localization.columns.aadRoleAssignmentResources())}
                id="aadRoleAssignmentResources"
                key="aadRoleAssignmentResources"/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.AadDirectoryGroupModel>(
                        Contract.TypeNames.AadDirectoryPrincipal,
                        item => (item.entity as Contract.AadDirectoryGroup).ownerPrincipalIds,
                        localization.columns.ownerPrincipalIds())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AadDirectoryGroupOwnerPrincipals)}
                                placeholder={localization.columns.ownerPrincipalIds()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AadDirectoryGroupOwnerPrincipals}
                key={Contract.EntityModelProperty.AadDirectoryGroupOwnerPrincipals}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AadDirectoryGroupModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={(item.entity as Contract.AadDirectoryGroup).ownerPrincipalIds}
                            entityTypeName={Contract.TypeNames.AadDirectoryIdentity}
                            entityVariant="iconText"/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.ownerPrincipalIds()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.AadDirectoryGroupModel>(
                        Contract.TypeNames.AadDirectoryPrincipal,
                        item => item.memberPrincipalIds,
                        localization.columns.memberPrincipals())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityTypeFilter(
                                        Contract.TypeNames.AadDirectoryPrincipal,
                                        true)}
                                placeholder={localization.columns.memberPrincipals()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AadDirectoryGroupMemberPrincipals}
                key={Contract.EntityModelProperty.AadDirectoryGroupMemberPrincipals}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AadDirectoryGroupModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.memberPrincipalIds}
                            entityTypeName={Contract.TypeNames.AadDirectoryPrincipal}
                            entityVariant="iconText"/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.memberPrincipals()}/>,
            commonDirectoryPrincipalDefinition.columns.attributesColumn,
            commonDirectoryPrincipalDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonDirectoryPrincipalDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonDirectoryPrincipalDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ]),
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAadDirectoryGroupRequest(
                new Contract.EntityControllerGetEntityModelPageAadDirectoryGroupRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.AadDirectoryEntityCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AadDirectoryPrincipalAzureRoleDefinitions]),
                    filterMap[Contract.EntityModelProperty.AadDirectoryPrincipalContainedAzureRoleAssignmentResources] as Optional<boolean>,
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AadDirectoryPrincipalDirectoryRoleDefinitions]),
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AadDirectoryGroupAdOrigin]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AadDirectoryGroupAwsRoles]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AadDirectoryGroupMemberPrincipals]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AadDirectoryGroupMembershipType]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AadDirectoryGroupOwnerPrincipals]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AadDirectoryGroupType])),
                limit,
                skip,
                requestSort));
}