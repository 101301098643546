import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { RiskDefinitionSection } from "../../../../../../utilities";
import { PolicySection } from "../../components";
import { useGetAwsSnsTopicRiskContext } from "../contexts";

export function useAwsSnsTopicPublicAccessExistsRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsSnsTopicPublicAccessExistsRisk;
    const topicModel = entityModelStore.useGet(risk.entityId) as Contract.AwsSnsTopicModel;
    const topic = topicModel.entity as Contract.AwsSnsTopic;

    const getAwsSnsTopicRiskContext = useGetAwsSnsTopicRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsSnsTopicPublicAccessExistsRiskDefinition",
            () => ({
                description: "{{topic}} is publicly accessible via resource policy",
                sections: {
                    policy: "Policy",
                    resolution: {
                        step1: "Disabling public access might break identities using the topic. Before changing the policy, verify that identities using the topic can access it without public access",
                        step2: "Change the policy statements that allows access to any AWS principal",
                        step3: "Click **Save changes** to confirm"
                    }
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            topic:
                <Entity
                    entityIdOrModel={topicModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.Sns,
                AwsConsoleUrlBuilder.getSnsTopicEditUrl(topic)),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3()
        ],
        riskModel,
        () => {
            const topicRiskContext = getAwsSnsTopicRiskContext(topicModel);

            return [
                topicRiskContext.generalInformation,
                topicRiskContext.subscriptions,
                topicRiskContext.encryption,
                topicRiskContext.getPublicAccessPermissionActions(
                    topic.publicAccessHighSeveritySensitivePermissionActions,
                    topic.publicAccessNonKmsEncryptedDataPermissionActions),
                topicRiskContext.sensitive,
                topicRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        },
        {
            sections: [
                new RiskDefinitionSection(
                    <PolicySection policyDocument={topic.policyDocument}/>,
                    localization.sections.policy(),
                    {
                        expandable: true
                    })
            ]
        });
}