import { useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { InlinePermitterConditions, InlinePermitterPolicies } from ".";
import { Contract } from "../../../../../../..";
import { AccessDefinitionEntitiesPermissionActionsPermittersProps } from "../../..";

export function Permitters({ actionData, entityTypeName }: AccessDefinitionEntitiesPermissionActionsPermittersProps) {
    const localization =
        useLocalization(
            "common.access.hooks.useDefinition.hooks.useAwsDefinition.permitters",
            () => ({
                conditionedPermitters: "Granted through {{permitterEntities}} | under {{conditions}}",
                permitters: "Granted through {{permitterEntities}}"
            }));
    const { conditionTypeToConditionsConsistentHashesMap, permissionPathIdentifiers } = actionData as Contract.AwsAccessListPermissionActionData;
    return (
        <Typography>
            {_.isEmpty(conditionTypeToConditionsConsistentHashesMap)
                ? localization.permitters({
                    permitterEntities:
                        <InlinePermitterPolicies
                            entityTypeName={entityTypeName}
                            permissionPathIdentifiers={permissionPathIdentifiers}/>
                })
                : localization.conditionedPermitters({
                    conditions:
                        <InlinePermitterConditions
                            conditionTypeToConditionsConsistentHashesMap={conditionTypeToConditionsConsistentHashesMap}/>,
                    permitterEntities:
                        <InlinePermitterPolicies
                            entityTypeName={entityTypeName}
                            permissionPathIdentifiers={permissionPathIdentifiers}/>
                })}
        </Typography>);
}