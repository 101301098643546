import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { useGetAwsResourceRiskContext } from "../contexts";

export function useAwsRdsNeptuneDatabaseInstanceMinorVersionUpgradeDisabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsRdsNeptuneDatabaseInstanceMinorVersionUpgradeDisabledRisk;
    const databaseInstanceModel = entityModelStore.useGet(risk.entityId) as Contract.AwsRdsNeptuneDatabaseInstanceModel;
    const databaseInstance = databaseInstanceModel.entity as Contract.AwsRdsNeptuneDatabaseInstance;

    const getAwsResourceRiskContext = useGetAwsResourceRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsRdsNeptuneDatabaseInstanceMinorVersionUpgradeDisabledRiskDefinition",
            () => ({
                description: "{{databaseInstance}} 'auto minor version upgrade' flag is not enabled",
                sections: {
                    resolution: {
                        step1: "Select the instance {{databaseInstance}}",
                        step2: "Click **Modify**",
                        step3: "In the **Additional settings** section, click **Show more**",
                        step4: "Select **Turn on auto minor version upgrade**",
                        step5: "Click **Next**",
                        step6: "Select **Apply Immediately** to apply the changes immediately, or **Apply during the next scheduled maintenance window**. Note that the upgrade causes downtime",
                        step7: "Click **Submit**"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            databaseInstance:
                <Entity
                    entityIdOrModel={databaseInstanceModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.Neptune,
                AwsConsoleUrlBuilder.getRdsNeptuneDatabaseInstanceUrl(databaseInstance)),
            localization.sections.resolution.step1({
                databaseInstance:
                    <Entity
                        entityIdOrModel={databaseInstanceModel}
                        entityTypeNameTranslatorOptions={{ variant: "text" }}
                        variant="text"/>
            }),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4(),
            localization.sections.resolution.step5(),
            localization.sections.resolution.step6(),
            localization.sections.resolution.step7()
        ],
        riskModel,
        () => {
            const resourceContextItems = getAwsResourceRiskContext(databaseInstanceModel);
            return [
                resourceContextItems.generalInformation,
                resourceContextItems.sensitive,
                resourceContextItems.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        });
}