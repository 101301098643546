import _ from "lodash";

export class AwsActionHelper {
    public static getName =
        (action: string, serviceShortName?: string) => {
            const [actionServiceShortName, actionName] = action.split(":");
            return _.isNil(serviceShortName) || actionServiceShortName === serviceShortName
                ? actionName
                : action;
        };
}