import { ActionMenuItem, DeleteIcon, EditIcon, Menu, Message, setUrlRoute, Tooltip, useLocalization } from "@infrastructure";
import { Button, CircularProgress, Stack } from "@mui/material";
import _ from "lodash";
import React, { Fragment, useState } from "react";
import { ConfigurationDialog } from "../..";
import { Contract, CustomerConsoleAppUrlHelper, CustomRiskPolicyItem, RiskPoliciesType, RiskPoliciesView, RisksIcon, Scope, UserHelper, useRiskPolicyConfigurationControllerDefinition, useTheme } from "../../../../../../../common";

type CustomActionsCellProps = {
    item: CustomRiskPolicyItem;
    riskPoliciesType: RiskPoliciesType;
    scopeId: string;
};

export function CustomActionsCell({ item, riskPoliciesType, scopeId }: CustomActionsCellProps) {
    const { deleteCustomRiskPolicyConfiguration } = useRiskPolicyConfigurationControllerDefinition(riskPoliciesType, item.riskPolicyConfigurationTypeNameOrId, scopeId, RiskPoliciesView.Custom);

    const [deleteRiskPolicyError, setDeleteRiskPolicyError] = useState(false);
    const [deleteRiskPolicyExecuting, setDeleteRiskPolicyExecuting] = useState(false);
    const [configurationOpen, setConfigurationOpen] = useState(false);

    async function onDeleteRiskPolicy() {
        setDeleteRiskPolicyExecuting(true);
        setDeleteRiskPolicyError(false);

        try {
            await deleteCustomRiskPolicyConfiguration?.();
        } catch {
            setDeleteRiskPolicyError(true);
        }

        setDeleteRiskPolicyExecuting(false);
    }

    const localization =
        useLocalization(
            "views.customer.riskPolicies.actionsCell.customActionsCell",
            () => ({
                actions: {
                    configuration: "Edit",
                    delete: {
                        error: "Failed to delete policy",
                        prompt: "Are you sure you want to delete this policy?",
                        title: "Delete"
                    },
                    risks: "View findings"
                },
                readOnly: "Inherited from ({{scope}})"
            }));

    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            direction="row"
            justifyContent="flex-end"
            spacing={1.5}>
            {deleteRiskPolicyExecuting && (
                <Stack>
                    <CircularProgress
                        size={theme.spacing(2)}
                        variant="indeterminate"/>
                </Stack>)}
            {deleteRiskPolicyError && (
                <Message
                    level="error"
                    title={localization.actions.delete.error()}
                    variant="minimal"/>)}
            {UserHelper.hasScopePermissions(item.riskPolicyConfiguration.scopeId, Contract.IdentityPermission.SecurityWrite) &&
                <Fragment>
                    {configurationOpen &&
                        <ConfigurationDialog
                            item={item}
                            riskPoliciesType={riskPoliciesType}
                            scopeId={scopeId}
                            onClose={() => setConfigurationOpen(false)}/>}
                    <Tooltip
                        titleOrGetTitle={
                            item.readOnly
                                ? localization.readOnly({
                                    scope:
                                        <Scope
                                            scopeId={item.riskPolicyConfiguration.scopeId}
                                            sx={{ color: theme.palette.text.primary }}
                                            variant="text"/>
                                })
                                : undefined}>
                        <Button
                            sx={{
                                "&:hover": {
                                    textDecoration: "none"
                                },
                                height: "20px",
                                minHeight: "unset",
                                padding: 0,
                                width: "20px"
                            }}
                            variant="text">
                            <Menu
                                disabled={item.readOnly}
                                itemsOrGetItems={
                                    _<ActionMenuItem>([]).
                                        concatIf(
                                            item.result.riskCount > 0,
                                            new ActionMenuItem(
                                                () =>
                                                    setUrlRoute(
                                                        CustomerConsoleAppUrlHelper.getRisksRelativeUrl(
                                                            Contract.RisksView.Open,
                                                            {
                                                                policyConfigurationTypeNameOrIds: [item.riskPolicyConfigurationTypeNameOrId]
                                                            })),
                                                localization.actions.risks(),
                                                {
                                                    icon: <RisksIcon/>
                                                })).
                                        concat([
                                            new ActionMenuItem(
                                                () => setConfigurationOpen(true),
                                                localization.actions.configuration(),
                                                {
                                                    disabled: deleteRiskPolicyExecuting,
                                                    icon: <EditIcon/>
                                                }),
                                            new ActionMenuItem(
                                                () => onDeleteRiskPolicy(),
                                                localization.actions.delete.title(),
                                                {
                                                    confirmOptions: {
                                                        message: localization.actions.delete.prompt()
                                                    },
                                                    disabled: deleteRiskPolicyExecuting,
                                                    icon: <DeleteIcon/>
                                                })]).
                                        value()}
                                sx={{ color: theme.palette.text.primary }}
                                variant="bottomCenter"/>
                        </Button>
                    </Tooltip>
                </Fragment>}
        </Stack>);
}