import { useLocalization } from "@infrastructure";
import { Contract } from "..";

export function usePrincipalRiskCategoryTranslator() {
    const localization =
        useLocalization(
            "common.hooks.usePrincipalRiskCategoryTranslator",
            () => ({
                [Contract.TypeNames.PrincipalRiskCategory]: {
                    [Contract.PrincipalRiskCategory.ExcessivePermissionAwsPermissionSet]: {
                        shortTitle: "Overprivileged",
                        text: "overprivileged permission sets",
                        title: "Overprivileged Permission Sets"
                    },
                    [Contract.PrincipalRiskCategory.ExcessivePermissionAwsRoleGroup]: {
                        shortTitle: "Overprivileged",
                        text: "overprivileged organization roles",
                        title: "Overprivileged Organization Roles"
                    },
                    [Contract.PrincipalRiskCategory.ExcessivePermissionGroup]: {
                        shortTitle: "Overprivileged",
                        text: "overprivileged groups",
                        title: "Overprivileged Groups"
                    },
                    [Contract.PrincipalRiskCategory.ExcessivePermissionServiceIdentity]: {
                        shortTitle: "Overprivileged",
                        text: "overprivileged service identities",
                        title: "Overprivileged Service Identities"
                    },
                    [Contract.PrincipalRiskCategory.ExcessivePermissionUser]: {
                        shortTitle: "Overprivileged",
                        text: "overprivileged users",
                        title: "Overprivileged Users"
                    },
                    [Contract.PrincipalRiskCategory.InactiveServiceIdentity]: {
                        shortTitle: "Inactive",
                        text: "inactive service identities",
                        title: "Inactive Service Identities"
                    },
                    [Contract.PrincipalRiskCategory.InactiveUser]: {
                        shortTitle: "Inactive",
                        text: "inactive users",
                        title: "Inactive Users"
                    },
                    [Contract.PrincipalRiskCategory.UnusedAwsPermissionSet]: {
                        shortTitle: "Unused",
                        text: "unused permission sets",
                        title: "Unused Permission Sets"
                    }
                }
            }));
    return (
        principalRiskCategory: Contract.PrincipalRiskCategory,
        variant: principalRiskCategoryTranslatorVariant = "title") =>
        localization[Contract.TypeNames.PrincipalRiskCategory][principalRiskCategory][variant]();
}

type principalRiskCategoryTranslatorVariant = "shortTitle" | "text" | "title";