﻿import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, EnumValuesFilter, useLocalization } from "@infrastructure";
import { Dictionary } from "lodash";
import React from "react";
import { useGcpCommonScopeResourceDefinition, useGcpCommonServiceAccountOriginatorScopeResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions, useEntityTypeNameTranslator } from "../../../../../../../../../../common";
import { useGcpContainerClusterNodePoolStatusTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";

export function useGcpContainerClusterNodePoolDefinition(definitionData: DefinitionData) {
    const commonScopeResourceDefinition = useGcpCommonScopeResourceDefinition(definitionData);
    const commonServiceAccountOriginatorScopeResourceDefinition = useGcpCommonServiceAccountOriginatorScopeResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const containerClusterNodePoolStatusTranslator = useGcpContainerClusterNodePoolStatusTranslator();
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.gcp.useGcpContainerClusterNodePoolDefinition",
            () => ({
                columns: {
                    clusterId: "Cluster",
                    computeInstanceGroupIds: "Instance Groups",
                    status: "Status"
                }
            }));

    return new EntityTableDefinition(
        [
            commonScopeResourceDefinition.columns.tenantColumn,
            commonScopeResourceDefinition.columns.creationTimeColumn,
            commonScopeResourceDefinition.columns.accessLevelColumn,
            commonScopeResourceDefinition.columns.updateTimeColumn,
            commonServiceAccountOriginatorScopeResourceDefinition.columns.serviceAccountColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.GcpContainerClusterNodePoolModel) => ({
                            [localization.columns.status()]: containerClusterNodePoolStatusTranslator((item.entity as Contract.GcpContainerClusterNodePool).status)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.GcpContainerClusterNodePoolStatus}
                                enumTypeTranslator={containerClusterNodePoolStatusTranslator}
                                placeholder={localization.columns.status()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpContainerClusterNodePoolStatus}
                itemProperty={(item: Contract.EntityModel) => containerClusterNodePoolStatusTranslator((item.entity as Contract.GcpContainerClusterNodePool).status)}
                key={Contract.EntityModelProperty.GcpContainerClusterNodePoolStatus}
                title={localization.columns.status()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.GcpContainerClusterNodePoolModel) => ({
                            [localization.columns.computeInstanceGroupIds()]:
                                entityTypeNameTranslator(
                                    Contract.TypeNames.GcpComputeInstanceGroup,
                                    {
                                        count: item.computeInstanceGroupIds.length,
                                        includeCount: true,
                                        includeServiceName: false
                                    })
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.GcpContainerClusterNodePoolInstanceGroups)}
                                placeholder={localization.columns.computeInstanceGroupIds()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpContainerClusterNodePoolInstanceGroups}
                key={Contract.EntityModelProperty.GcpContainerClusterNodePoolInstanceGroups}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.GcpContainerClusterNodePoolModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.computeInstanceGroupIds}
                            entityTypeName={Contract.TypeNames.GcpComputeInstanceGroup}
                            entityVariant="iconText"/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.computeInstanceGroupIds()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.GcpContainerClusterNodePoolModel>(
                        Contract.TypeNames.GcpContainerCluster,
                        item => [(item.entity as Contract.GcpContainerClusterNodePool).clusterId],
                        localization.columns.clusterId())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.GcpContainerClusterNodePoolCluster)}
                                placeholder={localization.columns.clusterId()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpContainerClusterNodePoolCluster}
                key={Contract.EntityModelProperty.GcpContainerClusterNodePoolCluster}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.GcpContainerClusterNodePoolModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={(item.entity as Contract.GcpContainerClusterNodePool).clusterId}
                            entityTypeName={Contract.TypeNames.GcpContainerCluster}
                            entityVariant="iconText"/>}
                title={localization.columns.clusterId()}/>,
            commonScopeResourceDefinition.columns.regionColumn,
            commonScopeResourceDefinition.columns.regionLocationColumn,
            commonScopeResourceDefinition.columns.tagsColumn,
            commonScopeResourceDefinition.columns.attributesColumn,
            commonScopeResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonScopeResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonScopeResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageGcpContainerClusterNodePoolRequest(
                new Contract.EntityControllerGetEntityModelPageGcpContainerClusterNodePoolRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.GcpResourceUpdateTime]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpScopeResourceAccessLevel]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpScopeResourceRawId]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpContainerClusterNodePoolCluster]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpContainerClusterNodePoolInstanceGroups]),
                    filterMap[Contract.EntityModelProperty.GcpContainerClusterNodePoolStatus]),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags])),
                new Contract.EntityControllerGetEntityModelPageGcpScopeResourceRequestServiceAccount(ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpServiceAccountOriginatorScopeResourceServiceAccount]))));
}