import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition, useGetAwsAutoScalingAutoScalingGroupsRiskContext, useGetAwsEc2InstancesRiskContext } from "../../../../..";
import { Contract, Entity, entityModelStore, InlineEntities } from "../../../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../../../tenants";
import { useGetAwsAutoScalingLaunchConfigurationRiskContext } from "../../../contexts";

export function useAwsEc2InstanceInstanceProfileNotExistRiskLaunchConfigurationDefinition(riskModel: Contract.AwsEc2InstanceInstanceProfileNotExistRiskModel) {
    const risk = riskModel.risk as Contract.AwsEc2InstanceInstanceProfileNotExistRisk;
    const launchConfigurationModel = entityModelStore.useGet(risk.entityId) as Contract.AwsAutoScalingLaunchConfigurationModel;

    const getAwsAutoScalingAutoScalingGroupsRiskContext = useGetAwsAutoScalingAutoScalingGroupsRiskContext();
    const getAwsAutoScalingLaunchConfigurationRiskContext = useGetAwsAutoScalingLaunchConfigurationRiskContext();
    const getAwsEc2InstancesRiskContext = useGetAwsEc2InstancesRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsEc2InstanceInstanceProfileNotExistRiskDefinition.hooks.useAwsEc2InstanceInstanceProfileNotExistRiskLaunchConfigurationDefinition",
            () => ({
                description: "{{launchConfiguration}} is not configured with IAM instance profile",
                sections: {
                    resolution: {
                        step1: "Select the launch configuration **{{launchConfigurationName}}** and choose **Actions, Copy launch configuration**",
                        step2: "In the **Additional Configuration** section, under **IAM instance profile**, select an existing one or create a new IAM role",
                        step3: "Click on the **Create launch configuration** button",
                        step4: "Set the new version as the default by clicking on **Actions > Set default version**",
                        step5: "Make sure the {{autoScalingGroups}} are using the latest version",
                        step6: "Wait until all instances are relaunched with the updated configuration",
                        step7: "On the Launch Configuration page, select the old configuration and click **Actions, delete launch configuration**"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            launchConfiguration:
                <Entity
                    entityIdOrModel={launchConfigurationModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.Ec2,
                AwsConsoleUrlBuilder.getAutoScalingGroupLaunchConfigurationUrl(launchConfigurationModel.entity as Contract.AwsAutoScalingLaunchConfiguration)),
            localization.sections.resolution.step1({ launchConfigurationName: launchConfigurationModel.entity.displayName }),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4(),
            !_.isEmpty(riskModel.risk.autoScalingGroupIds)
                ? localization.sections.resolution.step5({
                    autoScalingGroups:
                        <InlineEntities
                            entityIdsOrModels={riskModel.risk.autoScalingGroupIds}
                            entityTypeName={Contract.TypeNames.AwsAutoScalingAutoScalingGroup}
                            variant="itemCountAndType"/>
                })
                : undefined,
            localization.sections.resolution.step6(),
            localization.sections.resolution.step7()
        ],
        riskModel,
        () => {
            const autoScalingGroupsContextItems = getAwsAutoScalingAutoScalingGroupsRiskContext(riskModel.risk.autoScalingGroupIds!);
            const instancesContextItems = getAwsEc2InstancesRiskContext(riskModel.risk.aggregatedEntityIds);
            const launchConfigurationContextItems = getAwsAutoScalingLaunchConfigurationRiskContext(launchConfigurationModel);
            return [
                launchConfigurationContextItems.generalInformation,
                launchConfigurationContextItems.sensitive,
                launchConfigurationContextItems.getAutoScalingGroupsContextItem(riskModel.risk.autoScalingGroupIds),
                autoScalingGroupsContextItems.sensitive,
                instancesContextItems.runningInstances,
                instancesContextItems.sensitive,
                launchConfigurationContextItems.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        });
}