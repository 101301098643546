﻿import { useLocalization } from "@infrastructure";
import { Contract } from "../../../common";

export function useCodePipelineTypeTranslator() {
    const localization =
        useLocalization(
            "tenants.code.hooks.useCodePipelineTypeTranslator",
            () => ({
                [Contract.TypeNames.CodePipelineType]: {
                    [Contract.CodePipelineType.AzurePipeline]: {
                        configuration: "Azure Pipelines",
                        name: "Azure Pipelines",
                        run: "Azure Pipelines task"
                    },
                    [Contract.CodePipelineType.General]: {
                        configuration: "Generic (Docker)",
                        name: "Generic (Docker)",
                        run: "Docker image"
                    },
                    [Contract.CodePipelineType.GitHubWorkflow]: {
                        configuration: "GitHub Workflow",
                        name: "GitHub Actions",
                        run: "GitHub action"
                    },
                    [Contract.CodePipelineType.GitLabPipeline]: {
                        configuration: "GitLab Pipeline",
                        name: "GitLab CI/CD",
                        run: "Docker image"
                    },
                    [Contract.CodePipelineType.JenkinsPipeline]: {
                        configuration: "Jenkins Pipeline",
                        name: "Jenkins Pipeline",
                        run: "Docker image"
                    },
                    [Contract.CodePipelineType.TerraformCloudRunTask]: {
                        configuration: "Run Task",
                        name: "Terraform Cloud",
                        run: "Run Task"
                    }
                }
            }));
    return (codePipelineType: Contract.CodePipelineType, variant?: "configuration" | "name" | "run") =>
        localization[Contract.TypeNames.CodePipelineType][codePipelineType][variant ?? "name"]();
}