import { Dictionary } from "lodash";
import { EntityTableDefinition } from "../../..";
import { Contract, ItemSelectionHelper } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";
import { useAadCommonDirectoryEntityDefinition } from "./useAadCommonDirectoryEntityDefinition";

export function useAadDefaultDirectoryEntityDefinition(definitionData: DefinitionData) {
    const commonDirectoryEntityDefinition = useAadCommonDirectoryEntityDefinition(definitionData);

    return new EntityTableDefinition(
        [
            commonDirectoryEntityDefinition.columns.tenantColumn,
            commonDirectoryEntityDefinition.columns.attributesColumn,
            commonDirectoryEntityDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonDirectoryEntityDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonDirectoryEntityDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAadDefaultDirectoryEntityRequest(
                new Contract.EntityControllerGetEntityModelPageAadDefaultDirectoryEntityRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    undefined,
                    definitionData.entityTypeEntitiesViewName),
                limit,
                skip,
                requestSort));
}