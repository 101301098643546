import { Dialog, getUrlQueryParameters, makeContextProvider, StringHelper, useClearQueryParameters } from "@infrastructure";
import React from "react";
import { Contract } from "../../../../../../../../../../../../common";
import { AddOrEdit, Table } from "./components";

export class GcpContext {
    constructor(public addOrEditOpen: boolean | Contract.GcpOrganizationConfiguration) {
    }
}

export const [useGcpContext, useSetGcpContext, useGcpContextProvider] = makeContextProvider<GcpContext>();

export function Gcp() {
    const clearQueryParameters = useClearQueryParameters();
    const [context, setContext, ContextProvider] =
        useGcpContextProvider(
            () => {
                const queryParameters = getUrlQueryParameters<CloudProviderTenantOrganizationGcpQueryParameters>();
                clearQueryParameters();
                return new GcpContext(StringHelper.isTrue(queryParameters.addOrEditOpen));
            });

    return (
        <ContextProvider>
            {context.addOrEditOpen !== false &&
                <Dialog
                    size="large"
                    variant="editor"
                    onClose={
                        () =>
                            setContext(
                                context => ({
                                    ...context,
                                    addOrEditOpen: false
                                }))}>
                    <AddOrEdit/>
                </Dialog>}
            <Table/>
        </ContextProvider>);
}

export type CloudProviderTenantOrganizationGcpQueryParameters = {
    addOrEditOpen?: string;
};