import { StringHelper, useInputValidation, useLocalization, useOrderedWizardContext } from "@infrastructure";
import { FormControl, FormHelperText, Stack, TextField, Typography } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useAddOrEditContext, useSetAddOrEditContext } from "..";
import { ConfigurationController, Contract, scopeSystemEntityModelStore, useTheme } from "../../../../../../../../../../../../../../../common";

export function PipelineItem() {
    const { executing, setLoaded, setValid, useNextEffect } = useOrderedWizardContext();
    const { pipelineConfiguration, pipelineName, pipelineType } = useAddOrEditContext();
    const setAddOrEditContext = useSetAddOrEditContext();

    const existingPipelineConfigurations =
        _.map(
            scopeSystemEntityModelStore.useGetCodePipeline(pipelineType),
            scopeEntityModel => scopeEntityModel.configuration as Contract.CodePipelineConfiguration);

    useEffect(
        () => {
            setLoaded();
        },
        []);

    const [name, setName] = useState(pipelineName);

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCodePipelineItems.codePipeline.addOrEdit.pipelineItem",
            () => ({
                actions: {
                    save: {
                        error: {
                            create: "Failed to create",
                            edit: "Failed to save"
                        }
                    }
                },
                description: "Enter a name for the API token, and then click **Next** to create the token.",
                fields: {
                    name: {
                        error: {
                            exists: "Name already exists",
                            required: "Name cannot be empty"
                        },
                        label: "API Token Name"
                    }
                }
            }));

    const [nameValidationController, nameValidationMessage] =
        useInputValidation(
            () => {
                const normalizedName = StringHelper.normalize(name);
                if (_.isEmpty(normalizedName)) {
                    return localization.fields.name.error.required();
                }

                if (_.some(
                    existingPipelineConfigurations,
                    existingPipelineConfiguration =>
                        existingPipelineConfiguration.id != pipelineConfiguration?.id &&
                        StringHelper.compare(existingPipelineConfiguration.name, normalizedName))) {
                    return localization.fields.name.error.exists();
                }

                return undefined;
            },
            [name]);

    useNextEffect(
        async () => {
            try {
                const { apiKeyToken, scopeSystemEntityModel } =
                    _.isNil(pipelineConfiguration)
                        ? await ConfigurationController.insertCodePipeline(
                            new Contract.ConfigurationControllerInsertCodePipelineRequest(
                                name,
                                pipelineType))
                        : await ConfigurationController.updateCodePipeline(
                            new Contract.ConfigurationControllerUpdateCodePipelineRequest(
                                pipelineConfiguration.id,
                                name));
                await scopeSystemEntityModelStore.notify(scopeSystemEntityModel);

                setAddOrEditContext(
                    addOrEditContext => ({
                        ...addOrEditContext,
                        apiKeyToken,
                        pipelineConfiguration: scopeSystemEntityModel.configuration as Contract.CodePipelineConfiguration
                    }));
            } catch (error) {
                return _.isNil(pipelineConfiguration)
                    ? localization.actions.save.error.create()
                    : localization.actions.save.error.edit();
            }
        },
        [name]);

    useEffect(
        () => {
            setAddOrEditContext(
                addOrEditContext => ({
                    ...addOrEditContext,
                    pipelineName: name.trim()
                }));

            setValid(nameValidationController.isValid());
        },
        [name]);

    const theme = useTheme();
    return (
        <Stack
            spacing={4}
            sx={{ maxWidth: theme.spacing(65) }}>
            <Typography>
                {localization.description()}
            </Typography>
            <Stack spacing={1}>
                <FormControl
                    fullWidth={true}
                    variant="standard">
                    <TextField
                        disabled={executing}
                        label={localization.fields.name.label()}
                        value={name}
                        variant="outlined"
                        onChange={event => setName(event.target.value)}/>
                    {!_.isNil(nameValidationMessage) && (
                        <FormHelperText error={true}>{nameValidationMessage}</FormHelperText>)}
                </FormControl>
            </Stack>
        </Stack>);
}