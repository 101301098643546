import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition, useGetCommonKubernetesClusterRiskContext } from "../../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../../common";

export function useKubernetesClusterControllerManagerServiceAccountTokenKeyNotExistRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.KubernetesClusterControllerManagerServiceAccountTokenKeyNotExistRisk;
    const clusterModel = entityModelStore.useGet(risk.entityId);

    const getCommonKubernetesClusterRiskContext = useGetCommonKubernetesClusterRiskContext(clusterModel);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.kubernetes.hooks.clusters.controllerManagers.useKubernetesClusterControllerManagerServiceAccountTokenKeyNotExistRiskDefinition",
            () => ({
                description: "{{cluster}} Controller Manager private key file for service accounts is not set",
                resolutionSection: {
                    step1: "Edit the Controller Manager configuration file",
                    step2: "Ensure the **--service-account-private-key-file** argument points to a valid private key file used to sign service account tokens",
                    step3: "Restart the Controller Manager to apply the changes"
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            cluster:
                <Entity
                    entityIdOrModel={clusterModel}
                    entityTypeNameTranslatorOptions={{
                        includeServiceName: true,
                        variant: "title"
                    }}
                    variant="typeText"/>
        }),
        () => [
            localization.resolutionSection.step1(),
            localization.resolutionSection.step2(),
            localization.resolutionSection.step3()
        ],
        riskModel,
        () => {
            const clusterRiskContext = getCommonKubernetesClusterRiskContext(clusterModel);

            return [
                clusterRiskContext.generalInformation,
                clusterRiskContext.virtualNetwork,
                clusterRiskContext.sensitive,
                clusterRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        });
}