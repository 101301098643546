import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";
import { useTheme } from "../../../themes";

export function CodeFolderIcon(props: SvgIconProps) {
    const theme = useTheme();
    return (
        <SvgIcon {...props}>
            <path
                clipRule="evenodd"
                d="M8.62775 0.690138C8.24787 0.251813 7.6964 0 7.11637 0H3.11852C1.95333 0 1.01059 1.1 1.01059 2.44444L1.00752 5.82532C1.00254 5.88288 1 5.94114 1 6V16C1 17.1046 1.89543 18 3 18H6.07252C5.48397 17.2686 5 16.3603 5 15.1C5 13.2 6.09999 12.1 6.99999 11.2L8.60001 9.60001C9.30001 9.00001 10.1 8.60001 11.1 8.60001C12.1 8.60001 12.9 8.90001 13.6 9.60001L14.3 7.5C14.6946 6.02027 16.0623 5.02712 17.5392 5.00055C17.8342 5.0086 18.2129 5.10431 18.5 5.20001C19.4 5.50001 20.2 6.09999 20.6 6.89999C21 7.49999 21 8.19997 21 8.79996C21.5 8.99996 21.9 9.30001 22.2 9.60001L23 10.4V4.88889C23 3.54444 22.0467 2.44444 20.8815 2.44444H11.0614C10.4814 2.44444 9.92991 2.19263 9.55003 1.75431L8.62775 0.690138ZM17.5392 5.00055C17.5595 5.00018 17.5797 5 17.6 5H17.5C17.5129 5 17.526 5.00019 17.5392 5.00055Z"
                fill="#BAC5D3"
                fillRule="evenodd"/>
            <path
                d="M13.1999 23.5001C13.1999 23.5001 12.9999 23.5001 12.8999 23.5001C12.3999 23.3001 12.0999 22.8001 12.1999 22.2001L16.5999 8.20013C16.7999 7.70013 17.2999 7.40012 17.8999 7.50012C18.3999 7.70012 18.6999 8.2001 18.5999 8.8001L14.1999 22.8001C14.0999 23.2001 13.6999 23.5001 13.1999 23.5001ZM10.9999 19.1001C10.6999 19.1001 10.4999 19.1001 10.2999 18.8001L8.69989 17.2001C7.89989 16.4001 7.3999 15.9001 7.3999 15.1001C7.3999 14.3001 7.89989 13.7001 8.69989 13.0001L10.2999 11.4001C10.6999 11.0001 11.2999 11.0001 11.6999 11.4001C12.0999 11.8001 12.0999 12.4001 11.6999 12.8001L10.0999 14.4001C9.79991 14.7001 9.4999 15.0001 9.3999 15.1001C9.4999 15.3001 9.79991 15.6001 10.0999 15.8001L11.6999 17.4001C12.0999 17.8001 12.0999 18.4001 11.6999 18.8001C11.4999 19.0001 11.1999 19.1001 10.9999 19.1001ZM19.7999 19.1001C19.4999 19.1001 19.2999 19.1001 19.0999 18.8001C18.6999 18.4001 18.6999 17.8001 19.0999 17.4001L20.6999 15.8001C20.9999 15.5001 21.2999 15.2001 21.3999 15.1001C21.2999 14.9001 20.9999 14.6001 20.6999 14.4001L19.0999 12.8001C18.6999 12.4001 18.6999 11.8001 19.0999 11.4001C19.4999 11.0001 20.0999 11.0001 20.4999 11.4001L22.0999 13.0001C22.8999 13.8001 23.3999 14.3001 23.3999 15.1001C23.3999 15.9001 22.8999 16.5001 22.0999 17.2001L20.4999 18.8001C20.2999 19.0001 19.9999 19.1001 19.7999 19.1001Z"
                fill={theme.palette.text.primary}/>
        </SvgIcon>);
}