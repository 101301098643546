import _ from "lodash";
import { useEffect, useState } from "react";
import { ApiError, Optional, useExecuteOperation, useLocalization, useOrderedWizardContext } from "@infrastructure";
import { ConfigurationController, Contract } from "../../../../../../../../../../../../../../../common";
import { OrganizationMemberSelection } from "../../../../../../../components";
import { OrganizationMemberSelectionHelper } from "../../../../../../../utilities";
import { useAddOrEditContext, useSetAddOrEditContext } from "../AddOrEditGitHub";
import { useGitHubOrganizationManagerErrorTranslator } from "../hooks";

export function OrganizationMemberSelectionItem() {
    const addOrEditContext = useAddOrEditContext();
    const setAddOrEditContext = useSetAddOrEditContext();

    const { setError, setLoaded, setValid, useNextEffect } = useOrderedWizardContext();

    const [scopeNodeRootTreeItem] =
        useExecuteOperation(
            OrganizationMemberSelectionItem,
            async () => {
                const { node } =
                    await ConfigurationController.getOrganizationMemberDatas(
                        new Contract.ConfigurationControllerGetGitHubOrganizationMemberDatasRequest(
                            addOrEditContext.oAuthAccessToken,
                            addOrEditContext.organizationModel!.configuration.name,
                            addOrEditContext.updatedServerId));
                return OrganizationMemberSelectionHelper.createTreeItem(node);
            });

    const [selectionType, setScopeSelection] = useState(addOrEditContext.memberSelection?.type);
    const [selectedTenantIds, setSelectedTenantIds] =
        useState(
            () =>
                OrganizationMemberSelectionHelper.getSelectedTenantIds(
                    scopeNodeRootTreeItem.value,
                    addOrEditContext.memberSelection?.rawIds));

    useEffect(setLoaded, []);

    const gitHubOrganizationManagerErrorTranslator = useGitHubOrganizationManagerErrorTranslator();

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCodeOrganizationItems.gitHub.addOrEditGitHub.organizationMemberSelectionItem",
            () => ({
                actions: {
                    save: {
                        error: {
                            add: "Failed to add organization {{organizationName}}",
                            edit: "Failed to save organization {{organizationName}}"
                        }
                    }
                },
                errors: {
                    empty: "Empty selection is not allowed",
                    excludeAll: "Excluding all {{name}} is not allowed"
                }
            }));

    useNextEffect(
        async () => {
            if (!_.isNil(selectionType) && _.isEmpty(selectedTenantIds)) {
                return localization.errors.empty();
            }

            const newMemberSelection: Optional<Contract.OrganizationMemberSelection> =
                _.isNil(selectionType)
                    ? undefined
                    : {
                        rawIds:
                            OrganizationMemberSelectionHelper.getSelectedScopeIds(
                                scopeNodeRootTreeItem.value,
                                selectedTenantIds),
                        type: selectionType
                    };

            if (_.isNil(addOrEditContext.organizationModel?.configuration)) {
                try {
                    const { folderScopeNodeModel, organizationModel } =
                        await ConfigurationController.insertGitHubOrganization(
                            new Contract.ConfigurationControllerInsertGitHubOrganizationRequest(
                                addOrEditContext.oAuthAccessToken!,
                                addOrEditContext.updatedFolderEnabled!,
                                newMemberSelection,
                                addOrEditContext.updatedOrganizationName!,
                                addOrEditContext.updatedServerId,
                                addOrEditContext.updatedSyncEnabled!));
                    setAddOrEditContext(
                        context => ({
                            ...context,
                            organizationModel,
                            updatedFolderScopeNodeModel: folderScopeNodeModel
                        }));
                } catch (error) {
                    return error instanceof ApiError && error.statusCode === 400
                        ? gitHubOrganizationManagerErrorTranslator(error.error as Contract.GitHubOrganizationManagerError)
                        : localization.actions.save.error.edit({ organizationName: addOrEditContext.updatedOrganizationName });
                }
            } else {
                const { organizationModel } =
                    await ConfigurationController.updateGitHubOrganization(
                        new Contract.ConfigurationControllerUpdateGitHubOrganizationRequest(
                            addOrEditContext.updatedFolderEnabled!,
                            newMemberSelection,
                            addOrEditContext.organizationModel!.configuration.id,
                            addOrEditContext.updatedSyncEnabled!));
                setAddOrEditContext(
                    context => ({
                        ...context,
                        organizationModel
                    }));
            }
        },
        [selectedTenantIds, selectionType]);

    return (
        <OrganizationMemberSelection
            codeTenantType={Contract.CodeTenantType.GitHub}
            scopeNodeRootTreeItem={scopeNodeRootTreeItem}
            selectedTenantIds={selectedTenantIds}
            selectionType={selectionType}
            tenantType={Contract.TenantType.Code}
            onError={
                error => {
                    setValid(_.isNil(error));
                    setError(error);
                }}
            onSelectedTenantIdsChanged={setSelectedTenantIds}
            onSelectionTypeChanged={setScopeSelection}/>);
}