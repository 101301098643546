﻿import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, DeferredFilter, EnumValuesFilter, TimeFormatter, TimeRangeFilter, useLocalization } from "@infrastructure";
import React from "react";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { AadDirectoryApplicationExpirationDateCell, useAadDirectoryApplicationObjectExpirationStatusTranslator } from "../../../../../../../../../../tenants";
import { DefinitionData } from "../../../../Table";
import { useAadCommonDirectoryEntityDefinition } from "./useAadCommonDirectoryEntityDefinition";

export function useAadDirectoryApplicationObjectDefinition(definitionData: DefinitionData) {
    const commonDirectoryEntityDefinition = useAadCommonDirectoryEntityDefinition(definitionData);
    const directoryApplicationObjectModelFiltersPromise = definitionData.entityModelFiltersPromise as Promise<Contract.AadDirectoryApplicationObjectModelFilters>;
    const entitiesExportOptions = useEntitiesExportOptions();

    const directoryApplicationObjectExpirationStatusTranslator = useAadDirectoryApplicationObjectExpirationStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.aad.useAadDirectoryApplicationObjectDefinition",
            () => ({
                columns: {
                    application: "App Registration",
                    applicationOwnerPrincipal: "App Registration Owners",
                    expirationStatus: "Expiry status",
                    expirationTime: "Expiration Time"
                }
            }));

    return {
        columns: {
            ...commonDirectoryEntityDefinition.columns,
            application:
                <DataTableColumn
                    exportOptions={
                        entitiesExportOptions<Contract.AadDirectoryApplicationObjectModel>(
                            Contract.TypeNames.AadDirectoryApplication,
                            item => [(item.entity as Contract.AadDirectoryApplicationObject).applicationId],
                            localization.columns.application(),
                            { getValue: entityModel => entityModel.entity.displayName })}
                    filterOptions={{
                        itemOrItems: {
                            default: true,
                            element:
                                <PagedEntityFilter
                                    getEntityIdPage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.AadDirectoryApplicationObjectApplication)}
                                    placeholder={localization.columns.application()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AadDirectoryApplicationObjectApplication}
                    key={Contract.EntityModelProperty.AadDirectoryApplicationObjectApplication}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.AadDirectoryApplicationObjectModel>) =>
                            <EntitiesCell
                                entityIdsOrModels={(item.entity as Contract.AadDirectoryApplicationObject).applicationId}
                                entityTypeName={Contract.TypeNames.AadDirectoryApplication}/>}
                    title={localization.columns.application()}/>,
            applicationOwnerPrincipal:
                <DataTableColumn
                    exportOptions={
                        entitiesExportOptions<Contract.AadDirectoryApplicationObjectModel>(
                            Contract.TypeNames.AadDirectoryIdentity,
                            item => item.applicationOwnerPrincipalIds,
                            localization.columns.applicationOwnerPrincipal())}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedEntityFilter
                                    getEntityIdPage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.AadDirectoryApplicationObjectApplicationOwnerPrincipals)}
                                    placeholder={localization.columns.applicationOwnerPrincipal()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AadDirectoryApplicationObjectApplicationOwnerPrincipals}
                    key={Contract.EntityModelProperty.AadDirectoryApplicationObjectApplicationOwnerPrincipals}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.AadDirectoryApplicationObjectModel>) =>
                            <EntitiesCell
                                entityIdsOrModels={item.applicationOwnerPrincipalIds}
                                entityTypeName={Contract.TypeNames.AadDirectoryIdentity}
                                entityVariant="iconText"/>}
                    selectorOptions={{ default: false }}
                    title={localization.columns.applicationOwnerPrincipal()}/>,
            expirationStatus:
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AadDirectoryApplicationObjectModel) => ({
                                [localization.columns.expirationStatus()]: directoryApplicationObjectExpirationStatusTranslator((item.entity as Contract.AadDirectoryApplicationObject).expirationStatus)
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            default: true,
                            element:
                                <EnumValuesFilter
                                    enumType={Contract.AadDirectoryApplicationObjectExpirationStatus}
                                    enumTypeTranslator={directoryApplicationObjectExpirationStatusTranslator}
                                    placeholder={localization.columns.expirationStatus()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AadDirectoryApplicationObjectExpirationStatus}
                    key={Contract.EntityModelProperty.AadDirectoryApplicationObjectExpirationStatus}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.AadDirectoryApplicationObjectModel>) =>
                            <AadDirectoryApplicationExpirationDateCell
                                expirationDate={(item.entity as Contract.AadDirectoryApplicationObject).expirationTime}
                                expirationStatus={(item.entity as Contract.AadDirectoryApplicationObject).expirationStatus}
                                variant="statusAndIcon"/>}
                    title={localization.columns.expirationStatus()}/>,
            expirationTime:
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AadDirectoryApplicationObjectModel) => ({
                                [localization.columns.expirationTime()]: TimeFormatter.iso8601String((item.entity as Contract.AadDirectoryApplicationObject).expirationTime)
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <DeferredFilter
                                    promiseOrGetPromise={directoryApplicationObjectModelFiltersPromise}
                                    title={localization.columns.expirationTime()}>
                                    {filters =>
                                        <TimeRangeFilter
                                            emptyValue={filters.expirationTimeRange.emptyValue}
                                            placeholder={localization.columns.expirationTime()}
                                            timeRange={TimeRangeHelper.getTimeRangeFilterRange(filters.expirationTimeRange.timeRange)}/>}
                                </DeferredFilter>
                        }
                    }}
                    id={Contract.EntityModelProperty.AadDirectoryApplicationObjectExpirationTime}
                    itemProperty={(item: Contract.AadDirectoryServicePrincipalModel) => TimeFormatter.monthDayAndYear((item.entity as Contract.AadDirectoryApplicationObject).expirationTime)}
                    key={Contract.EntityModelProperty.AadDirectoryApplicationObjectExpirationTime}
                    sortOptions={{ type: DataTableSortType.Date }}
                    title={localization.columns.expirationTime()}/>
        }
    };
}