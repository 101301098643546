import { useLocalization } from "@infrastructure";
import { Contract } from "../../../common";

export function usePingIdentityClientRegionTranslator() {
    const localization =
        useLocalization(
            "tenants.pingIdentity.hooks.usePingIdentityClientRegionTranslator",
            () => ({
                [Contract.TypeNames.PingIdentityClientRegion]: {
                    [Contract.PingIdentityClientRegion.Ap]: "Asia Pacific (AP)",
                    [Contract.PingIdentityClientRegion.Ca]: "Canada (CA)",
                    [Contract.PingIdentityClientRegion.Eu]: "European Union (EU)",
                    [Contract.PingIdentityClientRegion.Us]: "North America (NA)"
                }
            }));
    return (region: Contract.PingIdentityClientRegion) =>
        localization[Contract.TypeNames.PingIdentityClientRegion][region]();
}