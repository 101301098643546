import _ from "lodash";
import React from "react";
import { Contract, EntitiesCell } from "../../../common";
import { AadDirectoryUnresolvedPrincipal } from "./AadDirectoryUnresolvedPrincipal";

type AadDirectoryPrincipalReferenceCellProps = {
    principalReference: Contract.AadDirectoryPrincipalReference;
};

export function AadDirectoryPrincipalReferenceCell({ principalReference }: AadDirectoryPrincipalReferenceCellProps) {
    return (
        _.isNil(principalReference.id)
            ? <AadDirectoryUnresolvedPrincipal
                rawId={principalReference.rawId}
                type={principalReference.type}/>
            : <EntitiesCell
                entityIdsOrModels={principalReference.id}
                entityTypeName={Contract.TypeNames.AadDirectoryPrincipal}
                entityVariant="iconTextTypeTenant"/>);
}