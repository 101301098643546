import _ from "lodash";
import { useCallback, useMemo } from "react";
import { Contract, scopeSystemEntityModelStore, tenantModelStore } from "../../../../../../../../common";

export function useGcpCommonDefinitionData() {
    const permittedGcpTenantModels = tenantModelStore.useGetPermittedGcpTenants();
    const gciTenantModels = tenantModelStore.useGetGciTenants();
    const gcpOrganizationModels = scopeSystemEntityModelStore.useGetGcpOrganization();

    const gcpTenantRawShortNameIdToModelMap =
        useMemo(
            () =>
                _.keyBy(
                    permittedGcpTenantModels,
                    permittedGcpTenantModel => permittedGcpTenantModel.configuration.rawShortNameId),
            [permittedGcpTenantModels]);

    const [gciTenantIdToServiceAccountTenantRawShortNameIdOrModel, organizationConfigurationMap] =
        useMemo(
            () => {
                const gciTenantIdToServiceAccountTenantRawShortNameIdOrModel =
                    _(gciTenantModels).
                        keyBy(gciTenantModel => gciTenantModel.configuration.id).
                        mapValues(
                            gciTenantModel => {
                                const serviceAccountTenantRawShortNameId = gciTenantModel.serviceAccountTenantRawShortNameId;
                                return gcpTenantRawShortNameIdToModelMap[serviceAccountTenantRawShortNameId] ?? serviceAccountTenantRawShortNameId;
                            }).
                        value();

                const organizationConfigurationMap =
                    _(gcpOrganizationModels).
                        keyBy(organizationModel => organizationModel.configuration.id).
                        mapValues(organizationModel => organizationModel.configuration as Contract.GcpOrganizationConfiguration).
                        value();

                return [gciTenantIdToServiceAccountTenantRawShortNameIdOrModel, organizationConfigurationMap];
            },
            [gciTenantModels, gcpTenantRawShortNameIdToModelMap]);

    const getSinkPubSubSubscriptionRawId =
        useCallback(
            (tenantModel: Contract.GcpTenantModel) =>
                tenantModel.configuration.sink?.pubSubSubscriptionRawId ??
                (_.isNil(tenantModel.configuration.organizationId)
                    ? undefined
                    : organizationConfigurationMap[tenantModel.configuration.organizationId].sink?.pubSubSubscriptionRawId),
            [organizationConfigurationMap]);

    return useMemo(
        () => ({
            gciTenantIdToServiceAccountTenantRawShortNameIdOrModel,
            getSinkPubSubSubscriptionRawId
        }),
        [gciTenantIdToServiceAccountTenantRawShortNameIdOrModel, getSinkPubSubSubscriptionRawId]);
}