import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, EntityDefinition, InfoItem, Region, RegionLocation, RegionModelHelper, useEntityTypeNameTranslator, useTheme } from "../../../../../../../../../common";

export function useCommonEntityInfoItemElements(entityModel: Contract.EntityModel) {
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.useCommonEntityInfoItemElements",
            () => ({
                region: "Region",
                regionLocation: "Location",
                tenantId: "ID",
                typeName: "Type"
            }));

    const theme = useTheme();
    return {
        regionInfoItemElement:
            <InfoItem
                key="region"
                location="all"
                title={localization.region()}>
                <Region
                    regionId={entityModel.entity.regionId}
                    sx={{ color: theme.palette.text.primary }}
                    variant="iconText"/>
            </InfoItem>,
        regionLocationInfoItemElement:
            <InfoItem
                key="regionLocation"
                location="all"
                title={localization.regionLocation()}>
                <RegionLocation
                    regionLocation={RegionModelHelper.tryGet(entityModel.entity.regionId)?.location}/>
            </InfoItem>,
        tenantIdInfoItemElement:
            <InfoItem
                key="tenantId"
                location="all"
                title={localization.tenantId()}
                value={entityModel.tenantId}/>,
        typeNameInfoElement:
            <EntityDefinition
                entityModel={entityModel}
                key="typeName">
                {definition =>
                    <InfoItem
                        title={localization.typeName()}
                        value={definition.translatedEntityTypeName ?? entityTypeNameTranslator(entityModel.entity.typeName)}/>}
            </EntityDefinition>
    };
}