import { useLocalization, useSetWizardContext } from "@infrastructure";
import { Radio, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useTheme } from "../../../../..";
import { useSetUpsertServiceNowIncidentContext, useUpsertServiceNowIncidentContext } from "../UpsertServiceNowIncident";

export function MultipleRisksItem() {
    const { multipleRisksSelection } = useUpsertServiceNowIncidentContext();
    const setUpsertServiceNowIncidentContext = useSetUpsertServiceNowIncidentContext();
    const setWizardContext = useSetWizardContext();

    useEffect(
        () => {
            setWizardContext(
                wizardContext => ({
                    ...wizardContext,
                    showBack: false
                }));
        },
        []);

    const localization =
        useLocalization(
            "common.upsertServiceNowIncidentDialog.upsertServiceNowIncident.multipleRisksItem",
            () => ({
                multipleRisks: {
                    false: "Create separate ServiceNow incidents, one per finding",
                    true: "Create a unified ServiceNow incident which includes all findings"
                }
            }));
    const theme = useTheme();
    return (

        <Stack spacing={2}>
            <Stack
                alignItems="center"
                direction="row"
                key="true"
                sx={{
                    backgroundColor: theme.palette.background.alternate,
                    border: theme.border.alternate,
                    borderRadius: theme.spacing(0.75),
                    padding: theme.spacing(1)
                }}>
                <Radio
                    checked={multipleRisksSelection === true}
                    size="small"
                    onChange={() =>
                        setUpsertServiceNowIncidentContext(
                            upsertServiceNowIncidentContext => ({
                                ...upsertServiceNowIncidentContext,
                                multipleRisksSelection: true
                            }))}/>
                <Typography
                    sx={{
                        cursor: "default",
                        flex: 1
                    }}>
                    {localization.multipleRisks.true()}
                </Typography>
            </Stack>
            <Stack
                alignItems="center"
                direction="row"
                key="false"
                sx={{
                    backgroundColor: theme.palette.background.alternate,
                    border: theme.border.alternate,
                    borderRadius: theme.spacing(0.75),
                    padding: theme.spacing(1)
                }}>
                <Radio
                    checked={multipleRisksSelection === false}
                    size="small"
                    onChange={() =>
                        setUpsertServiceNowIncidentContext(
                            upsertServiceNowIncidentContext => ({
                                ...upsertServiceNowIncidentContext,
                                multipleRisksSelection: false
                            }))}/>
                <Typography
                    sx={{
                        cursor: "default",
                        flex: 1
                    }}>
                    {localization.multipleRisks.false()}
                </Typography>
            </Stack>
        </Stack>);
}