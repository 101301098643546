import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useOciObjectStorageBucketStorageTierTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useOciObjectStorageBucketStorageTierTranslator",
            () => ({
                [Contract.TypeNames.OciObjectStorageBucketStorageTier]: {
                    [Contract.OciObjectStorageBucketStorageTier.Archive]: "Archive",
                    [Contract.OciObjectStorageBucketStorageTier.Standard]: "Standard"
                }
            }));
    return (storageTier: Contract.OciObjectStorageBucketStorageTier) =>
        localization[Contract.TypeNames.OciObjectStorageBucketStorageTier][storageTier]();
}