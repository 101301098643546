import { Link, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../..";
import { Contract, Entity, entityModelStore, InlineEntities, InlinePermissionActions, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { EntityExternalConsoleLink } from "../../components";
import { useGetGcpFunctionsFunctionRiskContext } from "../contexts";

export function useGcpFunctionsFunctionBuildDefaultServiceAccountRiskDefinition(riskModel: Contract.RiskModel) {
    const functionBuildDefaultServiceAccountRiskModel = riskModel as Contract.GcpFunctionsFunctionBuildDefaultServiceAccountRiskModel;
    const risk = functionBuildDefaultServiceAccountRiskModel.risk;
    const functionModel = entityModelStore.useGet(risk.entityId) as Contract.GcpFunctionsFunctionModel;

    const getGcpFunctionsFunctionRiskContext = useGetGcpFunctionsFunctionRiskContext();

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.useGcpFunctionsFunctionBuildDefaultServiceAccountRiskDefinition",
            () => ({
                contextItems: {
                    updatePermissionActions: "{{principalIds}} were granted {{updatePermissionAction}}, allowing them to update the {{translatedFunctionTypeName}}",
                    updatePermissionActionsExternalPrincipals: "{{principalIds}} including {{partialPrincipalIds}} were granted {{updatePermissionAction}}, allowing them to update the {{translatedFunctionTypeName}}"
                },
                description: "{{function}} is using the build default service account",
                sections: {
                    resolution: {
                        step1: {
                            link: "Follow GCP documentation on how to create a custom service account for Cloud Build",
                            text: "Create a custom service account for the Cloud Build instance deployed as part of the function deployment process. This service account should be tailored to the specific requirements of the Cloud Functions deployment. This form of deployment is more secure. {{createCustomServiceAccountLink}}."
                        },
                        step2: "Click **Edit**",
                        step3: "Expand the section **Runtime, build, connections and security settings**",
                        step4: "Under **Build service account**, select **Custom service account** and select the newly created service account",
                        step5: "Click **NEXT** and **DEPLOY**"
                    }
                }
            }));
    const partialPrincipalIds =
        _(risk.principalIdToExternalMap).
            pickBy(external => external).
            keys().
            value();

    const createPrincipalIdsProps =
        () => ({
            partialPrincipalIds:
                <InlineEntities
                    entityIdsOrModels={partialPrincipalIds}
                    entityTypeName={Contract.TypeNames.IGciPartialPrincipal}
                    variant="itemCountAndType"/>,
            principalIds:
                <InlineEntities
                    entityIdsOrModels={_.keys(risk.principalIdToExternalMap)}
                    entityTypeName={Contract.TypeNames.IGciPrincipal}
                    variant="itemCountAndType"/>,
            translatedFunctionTypeName:
                entityTypeNameTranslator(
                    functionModel.entity.typeName,
                    {
                        includeServiceName: false,
                        variant: "text"
                    }),
            updatePermissionAction:
                <InlinePermissionActions
                    permissionActions={[risk.updatePermissionAction]}
                    variant="itemCountAndType"/>
        });

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            function:
                <Entity
                    entityIdOrModel={functionModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={risk.entityId}
                key={risk.entityId}
                page={Contract.GcpConsolePage.Resource}/>,
            localization.sections.resolution.step1.text({
                createCustomServiceAccountLink:
                    <Link
                        urlOrGetUrl={functionBuildDefaultServiceAccountRiskModel.createCustomServiceAccountUrl}
                        variant="external">
                        {localization.sections.resolution.step1.link()}
                    </Link>
            }),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4(),
            localization.sections.resolution.step5()
        ],
        riskModel,
        () => {
            const functionsFunctionRiskContext = getGcpFunctionsFunctionRiskContext(functionModel);
            return [
                functionsFunctionRiskContext.generalInformation,
                functionsFunctionRiskContext.sensitive,
                _.isEmpty(risk.principalIdToExternalMap)
                    ? undefined
                    : new RiskDefinitionContextItem(
                        _.isEmpty(partialPrincipalIds)
                            ? localization.contextItems.updatePermissionActions({ ...createPrincipalIdsProps() })
                            : localization.contextItems.updatePermissionActionsExternalPrincipals({ ...createPrincipalIdsProps() })),
                functionsFunctionRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}