import { useLocalization } from "@infrastructure";
import { Typography, useTheme } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { getScopeConfigurationValueType, getScopeConfigurationValueTypeValue, RadioGroup, RadioGroupItem, ScopeConfigurationValueType, ScopeHelper, ScopeLabel, scopeNodeModelStore, useScopeNavigationViewContext } from "../../../../../../../../../../common";
import { useInheritedScopeId, useUpdateConfiguration } from "../../../../hooks";

export function ExcludeUnresolvableVulnerabilities() {
    const { scopeNodeModel } = useScopeNavigationViewContext();
    const scopeNodeMap = scopeNodeModelStore.useGetActiveScopeNodeMap();
    const inheritDisabled = scopeNodeModel.configuration.id === ScopeHelper.customerId;

    const inheritedScopeId = useInheritedScopeId(workloadAnalysis => workloadAnalysis.excludeUnresolvableVulnerabilities);

    const inheritedExcludeUnresolvableVulnerabilities =
        useMemo(
            () =>
                _.isNil(inheritedScopeId)
                    ? undefined
                    : scopeNodeMap[inheritedScopeId!].scopeNodeModel.configuration.scopeSections.workloadAnalysis.excludeUnresolvableVulnerabilities,
            [inheritedScopeId, scopeNodeMap]);

    const localization =
        useLocalization(
            "views.customer.configuration.workloadAnalysis.vulnerabilities.excludeUnresolvableVulnerabilities",
            () => ({
                description: "Configure how findings are created for vulnerabilities.",
                options: {
                    false: {
                        full: "Disabled - create findings for *bold*all** vulnerabilities (including those *bold*without fixes**)",
                        keyword: "Disabled"
                    },
                    inherit: "Inherit",
                    true: {
                        full: "Enabled - *bold*only** create findings for vulnerabilities *bold*with fixes**",
                        keyword: "Enabled"
                    }
                },
                title: "Findings for Vulnerabilities Without Fixes"
            }));

    const items =
        useMemo(
            () =>
                _<RadioGroupItem<ScopeConfigurationValueType>>([]).
                    concatIf(
                        !inheritDisabled,
                        {
                            label:
                                <ScopeLabel
                                    inheritedScopeId={inheritedScopeId}
                                    text={localization.options.inherit()}
                                    translatedInheritedValue={
                                        inheritedExcludeUnresolvableVulnerabilities
                                            ? localization.options.true.keyword()
                                            : localization.options.false.keyword()}/>,
                            value: ScopeConfigurationValueType.Inherit
                        }).
                    concat(
                        {
                            label: <ScopeLabel text={localization.options.true.full()}/>,
                            value: ScopeConfigurationValueType.Enabled
                        },
                        {
                            label: <ScopeLabel text={localization.options.false.full()}/>,
                            value: ScopeConfigurationValueType.Disabled
                        }).
                    value(),
            [inheritDisabled, inheritedScopeId, inheritedExcludeUnresolvableVulnerabilities]);


    const { workloadAnalysis } = scopeNodeModel.configuration.scopeSections;
    const updateConfiguration = useUpdateConfiguration();
    const theme = useTheme();
    return (
        <RadioGroup
            description={
                <Typography
                    sx={{
                        paddingTop: theme.spacing(1),
                        whiteSpace: "pre-wrap"
                    }}>
                    {localization.description()}
                </Typography>}
            items={items}
            selectedValue={getScopeConfigurationValueType(workloadAnalysis.excludeUnresolvableVulnerabilities)}
            title={localization.title()}
            onChange={
                async (value: ScopeConfigurationValueType) => {
                    const excludeUnresolvableVulnerabilities = getScopeConfigurationValueTypeValue(value);
                    await updateConfiguration({ excludeUnresolvableVulnerabilities });
                }}/>);
}