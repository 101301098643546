import { TimeSpanFormatter, useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition, useGetAwsElastiCacheReplicationGroupRiskContext } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { useGetRiskPolicyConfigurationRetentionTimeFrameContextItem } from "../../../useGetRiskPolicyConfigurationRetentionTimeFrameContextItem";

export function useAwsElastiCacheReplicationGroupAutomaticBackupsDisabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsElastiCacheReplicationGroupAutomaticBackupsDisabledRisk;
    const automaticBackupsRiskModel = riskModel as Contract.AwsElastiCacheReplicationGroupAutomaticBackupsDisabledRiskModel;
    const replicationGroupModel = entityModelStore.useGet(risk.entityId) as Contract.AwsElastiCacheReplicationGroupModel;
    const replicationGroup = replicationGroupModel.entity as Contract.AwsElastiCacheReplicationGroup;

    const getAwsElastiCacheReplicationGroupRiskContext = useGetAwsElastiCacheReplicationGroupRiskContext();
    const getRiskPolicyConfigurationRetentionTimeFrameContextItem = useGetRiskPolicyConfigurationRetentionTimeFrameContextItem();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsElastiCacheReplicationGroupAutomaticBackupsDisabledRiskDefinition",
            () => ({
                description: {
                    automaticBackupsDisabled: "{{replicationGroup}} automatic backup retention period is not enabled",
                    automaticBackupsEnabled: "{{replicationGroup}} automatic backup retention period is less than {{backupRetentionTimeFrame}}"
                },
                sections: {
                    resolution: {
                        step1: "Click on the **Maintenance and backups** tab",
                        step2: "In the **Backup** section, and click **Modify**",
                        step3: "Check the **Enable automatic backups** option and set the **Backup retention period** to at least **{{backupRetentionTimeFrame}}**",
                        step4: "In the **Backup** section, click **Modify**",
                        step5: "Click **Preview changes**",
                        step6: "Click **Modify**"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        (replicationGroup.automaticBackups
            ? localization.description.automaticBackupsEnabled
            : localization.description.automaticBackupsDisabled)
        ({
            backupRetentionTimeFrame: TimeSpanFormatter.day(automaticBackupsRiskModel.risk.backupRetentionTimeFrame),
            replicationGroup:
                <Entity
                    entityIdOrModel={replicationGroupModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.ElastiCache,
                AwsConsoleUrlBuilder.getElastiCacheReplicationGroupUrl(replicationGroupModel.entity as Contract.AwsElastiCacheReplicationGroup)),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3({ backupRetentionTimeFrame: TimeSpanFormatter.day(automaticBackupsRiskModel.risk.backupRetentionTimeFrame) }),
            localization.sections.resolution.step4(),
            localization.sections.resolution.step5(),
            localization.sections.resolution.step6()
        ],
        riskModel,
        () => {
            const replicationGroupContextItems = getAwsElastiCacheReplicationGroupRiskContext(replicationGroupModel);
            return [
                replicationGroupContextItems.generalInformation,
                replicationGroupContextItems.sensitive,
                getRiskPolicyConfigurationRetentionTimeFrameContextItem(risk.backupRetentionTimeFrame),
                replicationGroupContextItems.automaticBackups,
                replicationGroupContextItems.backupRetentionTimeFrame,
                replicationGroupContextItems.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}