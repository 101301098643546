import { Loading, Tooltip, useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { ReactNode } from "react";
import { Attribute, useTheme } from "../../../../..";

type ContainerProps = {
    color: string;
    iconOptions?: ContainerIconOptions;
    name: string;
    tooltip?: string | ReactNode;
    variant?: "automatic" | "manual";
};

type ContainerIconOptions = {
    icon: ReactNode;
    orientation: "left" | "right";
};

export function Container({ color, iconOptions, name, tooltip, ...props }: ContainerProps) {
    const localization =
        useLocalization(
            "common.entityAttributes.entityAttribute.container",
            () => ({
                title: {
                    automatic: "Automatic Label",
                    manual: "Manual Label"
                }
            }));

    const theme = useTheme();
    return (
        <Attribute
            backgroundColor={theme.palette.opacity(color, 0.2)}
            borderColor={color}
            item={
                <Tooltip
                    titleOrGetTitle={
                        _.isNil(tooltip) ||
                        _.isNil(props.variant)
                            ? undefined
                            : <Loading container="popup">
                                <Stack>
                                    <Typography variant="h5">
                                        {localization.title[props.variant]()}
                                    </Typography>
                                    {tooltip}
                                </Stack>
                            </Loading>}>
                    <Stack
                        alignItems="center"
                        direction="row"
                        spacing={0.5}
                        sx={{ height: "100%" }}>
                        {iconOptions &&
                            iconOptions.orientation == "left" &&
                            iconOptions.icon}
                        <Typography noWrap={true}>
                            {name}
                        </Typography>
                        {iconOptions &&
                            iconOptions.orientation == "right" &&
                            iconOptions.icon}
                    </Stack>
                </Tooltip>}/>);
}