import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { useGcpCommonKubernetesResourceInfoItemElements, useGcpDefaultResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../..";
import { Contract, entityModelStore } from "../../../../../../../../../../common";
import { EntityTypeNameGroupsInfoCard, Info } from "../../../../components";
import { KubernetesYamlInfoCard } from "../../components";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";

export function useGcpKubernetesNamespaceDefinition(resourceModel: Contract.GcpResourceModel, options?: DefinitionOptions) {
    const commonKubernetesResourceInfoItemElements = useGcpCommonKubernetesResourceInfoItemElements(resourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultPropertyInfoElements = useGcpDefaultResourceInfoItemElements(resourceModel);
    const namespaceModel = resourceModel as Contract.GcpKubernetesNamespaceModel;
    const resourceModels = entityModelStore.useGet(namespaceModel.resourceIds);

    const resourceTypeNameToIdsMap =
        useMemo(
            () =>
                _(resourceModels).
                    groupBy(resourceModel => resourceModel.entity.typeName).
                    mapValues(
                        resourceModels =>
                            _.map(
                                resourceModels,
                                resourceModel => resourceModel.entity.id)).
                    value(),
            [resourceModels]);

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpKubernetesNamespaceDefinition",
            () => ({
                info: {
                    cards: {
                        resources: {
                            emptyMessage: "No resources",
                            title: "Resources"
                        }
                    }
                },
                tabs: {
                    yaml: "YAML"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Overview,
                <KubernetesYamlInfoCard rawYaml={(namespaceModel.entity as Contract.GcpKubernetesNamespace).data.rawYaml!}/>,
                localization.tabs.yaml(),
                "yaml")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultPropertyInfoElements}
                entityPropertyInfoItemElements={[
                    commonKubernetesResourceInfoItemElements.annotationsInfoItemElement,
                    commonKubernetesResourceInfoItemElements.clusterInfoItemElement
                ]}
                options={options?.infoOptions}>
                <EntityTypeNameGroupsInfoCard
                    emptyMessage={localization.info.cards.resources.emptyMessage()}
                    entityTypeNameToIdsMap={resourceTypeNameToIdsMap}
                    title={localization.info.cards.resources.title()}/>
            </Info>
    });
}