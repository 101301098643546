import { UnexpectedError } from "@infrastructure";
import { useMemo } from "react";
import { Contract, ScopeHelper, scopeNodeModelStore, TenantNameTranslatorOptions, useTenantNameTranslator } from "..";

export type ScopeNameTranslatorOptions = {
    path?: boolean;
    tenantNameTranslatorOptions?: TenantNameTranslatorOptions;
};

export type ScopeNameTranslator = (scopeId: string, options?: ScopeNameTranslatorOptions) => string;

export function useScopeNameTranslator(): ScopeNameTranslator {
    const scopeNodeMap = scopeNodeModelStore.useGetScopeNodeMap(undefined, true);
    const tenantNameTranslator = useTenantNameTranslator();
    return useMemo(
        () =>
            (scopeId: string, options?: ScopeNameTranslatorOptions) => {
                const scopeNode = scopeNodeMap[scopeId];
                if (options?.path) {
                    return scopeNode.path;
                } else {
                    switch (scopeNode.scopeNodeModel.type) {
                        case Contract.ScopeType.CiTenant:
                            return (scopeNode.scopeNodeModel.configuration as Contract.CiTenantConfiguration).displayReference;
                        case Contract.ScopeType.CloudProviderTenant:
                            return tenantNameTranslator(scopeId, options?.tenantNameTranslatorOptions);
                        case Contract.ScopeType.CodeTenant:
                            return (scopeNode.scopeNodeModel.configuration as Contract.CodeTenantConfiguration).displayReference;
                        case Contract.ScopeType.Customer:
                            return ScopeHelper.customerDisplayName;
                        case Contract.ScopeType.Folder:
                            return scopeNode.scopeNodeModel.configuration.name;
                        case Contract.ScopeType.IdentityProviderTenant:
                            return scopeNode.scopeNodeModel.configuration.name;
                        case Contract.ScopeType.Project:
                            return (scopeNode.scopeNodeModel.configuration as Contract.ProjectConfiguration).name;
                        case Contract.ScopeType.ProjectFolder:
                            return (scopeNode.scopeNodeModel.configuration as Contract.ProjectFolderConfiguration).name;
                        default:
                            throw new UnexpectedError("scopeType", scopeNode.scopeNodeModel.type);
                    }
                }
            },
        [scopeNodeMap, tenantNameTranslator]);
}