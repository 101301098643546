import { useLocalization } from "@infrastructure";
import { AuditEventDefinition, AuditEventDefinitionContext, AuditEventDefinitionDetailItem } from "..";
import { Contract } from "../../../../../../../../../common";

export function useSlackWorkspaceAuditEventDefinition(context: AuditEventDefinitionContext) {
    const localization =
        useLocalization(
            "views.customer.auditEvents.profile.hooks.useDefinition.hooks.useSlackWorkspaceAuditEventDefinition",
            () => ({
                slackWorkspaceName: "Workspace"
            }));
    return new AuditEventDefinition([
        new AuditEventDefinitionDetailItem(
            localization.slackWorkspaceName(),
            (context.auditEventModel.auditEvent as Contract.SlackWorkspaceAuditEvent).slackWorkspaceName)
    ]);
}