import _, { Dictionary } from "lodash";
import { useCallback, useMemo } from "react";
import { AppError, setUrlRoute, useRoute } from "@infrastructure";
import { useSelectedScopeId } from "./useSelectedScopeId";

export type SetViewRoute<TView> = (view: TView, parameters?: Dictionary<string>) => void;
export type ViewRoute<TView> = {
    parameters: Dictionary<string>;
    view: TView;
};

export function useApplicationViewRoute<TView extends string>(
    baseRoute: string,
    views: TView[],
    defaultView: TView,
    validateTemplateRouteResultParameters?: (parameters: Dictionary<string>) => boolean,
    onError?: (error: AppError) => void):
    [ViewRoute<TView>, SetViewRoute<TView>] {
    const routeTemplate = `${baseRoute}/{view}`;
    const { match, view, ...parameters } = useRoute(routeTemplate);
    const { selectedScopeId } = useSelectedScopeId();

    const viewInvalid = !_.includes(views, (view as TView));

    function handleError(message: string) {
        const error = new AppError(message);
        if (_.isNil(onError)) {
            throw error;
        }

        onError(error);
        return {
            parameters: {},
            view:
                !match || viewInvalid
                    ? defaultView
                    : view as TView
        };
    }

    const viewRoute =
        useMemo(
            (): ViewRoute<TView> => {
                if (!match) {
                    return handleError(`Route mismatch: [template=${routeTemplate} path=${window.location.pathname}]`);
                }

                if (!_.isNil(validateTemplateRouteResultParameters) && !validateTemplateRouteResultParameters(parameters)) {
                    return handleError(`Invalid route result parameters: ${parameters}`);
                }

                if (_.isNil(view)) {
                    setUrlRoute(
                        routeTemplate,
                        {
                            scopeId: encodeURIComponent(selectedScopeId),
                            view: defaultView
                        },
                        { appendBrowserHistory: false }
                    );
                    return { parameters: {}, view: defaultView };
                }

                if (viewInvalid) {
                    return handleError(`Invalid view: [view=${view} availableViews=${views.join(", ")}]`);
                }

                return {
                    parameters,
                    view: view as TView
                };
            },
            [match, view, parameters, selectedScopeId]);

    const setViewRoute =
        useCallback(
            (view: TView, parameters: Dictionary<string> = {}) => {
                setUrlRoute(
                    routeTemplate,
                    {
                        scopeId: encodeURIComponent(selectedScopeId),
                        view,
                        ...parameters
                    });
            },
            [routeTemplate, selectedScopeId]);

    return [viewRoute, setViewRoute];
}