import { Cache } from "./cache";

export class StorageItem {
    constructor(private key: string) {
    }

    public getValue() {
        return localStorage.getItem(this.key);
    }

    public removeValue() {
        localStorage.removeItem(this.key);
    }

    public setValue(value: any) {
        localStorage.setItem(this.key, value);
    }
}

export class StorageHelper {
    private static _storageItemCache = new Cache<StorageItem>();

    public static amplitudeEnabled = StorageHelper.getItem("observabilityAmplitudeEnabled");

    public static theme = StorageHelper.getItem("theme");

    public static getItem(key: string) {
        if (!StorageHelper._storageItemCache.has(key)) {
            StorageHelper._storageItemCache.set(
                key,
                new StorageItem(key));
        }

        return StorageHelper._storageItemCache.get(key)!;
    }
}