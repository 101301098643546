import { Link, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useState } from "react";
import { ConfigurationController, Contract, CustomerConsoleAppUrlHelper, PermissionManagementHelper, ScopeHelper, scopeSystemEntityModelStore, TeamsIcon, useScopeNavigationViewContext } from "../../../../../../../common";
import { IntegrationInformation } from "../components";

export function Teams() {
    const { scopeNodeModel } = useScopeNavigationViewContext();
    const teamsOrganizationModels =
        ScopeHelper.getParentScopeSystemEntityModelsIntersection(
            [scopeNodeModel.configuration.id],
            scopeSystemEntityModelStore.useGetTeams());

    const [teamsEnabled, setTeamsEnabled] =
        useState(
            PermissionManagementHelper.teamsEnabled &&
            !_.isEmpty(teamsOrganizationModels));

    const [updateTeamsEnabledError, setUpdateTeamsEnabledError] = useState(false);
    const [updateTeamsEnabledExecuting, setUpdateTeamsEnabledExecuting] = useState(false);

    const localization =
        useLocalization(
            "views.customer.configuration.permissionManagement.teams",
            () => ({
                description: {
                    items: {
                        item1: "Users can request access in Teams, via an embedded version of the Tenable Cloud Security JIT Portal",
                        item2: "Approval requests are sent via email and Teams to all approvers",
                        item3: "Requests can be approved natively within Teams, via the embedded portal"
                    }
                },
                teamsEnabled: {
                    empty: {
                        link: "connect your Teams organization",
                        title: "To enable Teams for JIT, first {{link}} with Tenable Cloud Security."
                    },
                    error: "Failed to change teams status",
                    unlicensed: "JIT feature is not enabled"
                },
                title: "Teams integration"
            }));

    async function updateTeamsEnabled() {
        setUpdateTeamsEnabledExecuting(true);
        setUpdateTeamsEnabledError(false);

        const toggleTeamsEnabled = !teamsEnabled;
        try {
            await ConfigurationController.updatePermissionManagementTeamsEnabled(new Contract.ConfigurationControllerUpdatePermissionManagementTeamsEnabledRequest(toggleTeamsEnabled));
            setTeamsEnabled(toggleTeamsEnabled);
            await PermissionManagementHelper.initialize();
        } catch (error) {
            setUpdateTeamsEnabledError(true);
        }

        setUpdateTeamsEnabledExecuting(false);
    }

    return (
        <IntegrationInformation
            emptyTitle={
                _.isEmpty(teamsOrganizationModels)
                    ? localization.teamsEnabled.empty.title({
                        link:
                            <Link
                                urlOrGetUrl={CustomerConsoleAppUrlHelper.GetConfigurationIntegrationsTeamsRelativeUrl()}
                                variant="external">
                                {localization.teamsEnabled.empty.link()}
                            </Link>
                    })
                    : undefined}
            icon={<TeamsIcon sx={{ fontSize: "36px" }}/>}
            items={[
                localization.description.items.item1(),
                localization.description.items.item2(),
                localization.description.items.item3()]}
            title={localization.title()}
            toggleOptions={{
                checked: teamsEnabled,
                disabled:
                    updateTeamsEnabledExecuting ||
                    !teamsEnabled &&
                    _.isEmpty(teamsOrganizationModels),
                errorMessage:
                    updateTeamsEnabledError
                        ? localization.teamsEnabled.error()
                        : undefined,
                executing: updateTeamsEnabledExecuting,
                onClick: updateTeamsEnabled
            }}/>);
}