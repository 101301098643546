import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, DeferredFilter, EnumValuesFilter, Optional, optionalTableCell, TimeFormatter, TimeRangeFilter, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useActivityTranslator, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { useGciDirectoryUserStatusTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";
import { useGciCommonDirectoryPrincipalDefinition } from "./useGciCommonDirectoryPrincipalDefinition";

export function useGciDirectoryUserDefinition(definitionData: DefinitionData) {
    const userModelFiltersPromise = definitionData.entityModelFiltersPromise as Promise<Contract.GciDirectoryUserModelFilters>;
    const commonDirectoryPrincipalDefinition = useGciCommonDirectoryPrincipalDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();
    const activityTranslator = useActivityTranslator();
    const directoryUserStatusTranslator = useGciDirectoryUserStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.gci.useGciDirectoryUserDefinition",
            () => ({
                columns: {
                    activityTime: {
                        helpText: "The last time an activity was seen in GCP for this identity. This is highly dependent on the logging configuration. Unlogged activity will not be taken into account.",
                        title: "Activity Time"
                    },
                    groupIds: "Group Memberships",
                    mfaEnforced: {
                        enforced: "Enforced",
                        notEnforced: "Not enforced",
                        title: "MFA"
                    },
                    signInTime: {
                        empty: "Not seen",
                        title: "Sign-in Time"
                    },
                    status: "Status"
                }
            }));

    function getMfaEnabledTranslation(entityModel: Contract.EntityModel) {
        return (entityModel.entity as Contract.GciDirectoryUser).mfaEnforced
            ? localization.columns.mfaEnforced.enforced()
            : localization.columns.mfaEnforced.notEnforced();
    }

    function translateSignInTime(userModel: Contract.EntityModel) {
        const user = userModel.entity as Contract.GciDirectoryUser;

        return _.isNil(user.signInTime)
            ? localization.columns.signInTime.empty()
            : TimeFormatter.monthDayAndYear(user.signInTime);
    }

    return new EntityTableDefinition(
        [
            commonDirectoryPrincipalDefinition.columns.tenantColumn,
            commonDirectoryPrincipalDefinition.columns.emailColumn,
            commonDirectoryPrincipalDefinition.columns.creationTime,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.GciDirectoryUserModel) => ({
                            [localization.columns.mfaEnforced.title()]: getMfaEnabledTranslation(item)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <ValuesFilter
                                placeholder={localization.columns.mfaEnforced.title()}
                                sorted={false}>
                                <ValuesFilterItem
                                    title={localization.columns.mfaEnforced.enforced()}
                                    value={true}/>
                                <ValuesFilterItem
                                    title={localization.columns.mfaEnforced.notEnforced()}
                                    value={false}/>
                            </ValuesFilter>
                    }
                }}
                id={Contract.EntityModelProperty.GciDirectoryUserMfaEnforced}
                itemProperty={getMfaEnabledTranslation}
                key={Contract.EntityModelProperty.GciDirectoryUserMfaEnforced}
                title={localization.columns.mfaEnforced.title()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.GciDirectoryUserModel) => ({
                            [localization.columns.status()]: directoryUserStatusTranslator((item.entity as Contract.GciDirectoryUser).status)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.GciDirectoryUserStatus}
                                enumTypeTranslator={directoryUserStatusTranslator}
                                placeholder={localization.columns.status()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GciDirectoryUserStatus}
                itemProperty={(item: Contract.EntityModel) => directoryUserStatusTranslator((item.entity as Contract.GciDirectoryUser).status)}
                key={Contract.EntityModelProperty.GciDirectoryUserStatus}
                title={localization.columns.status()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.GciDirectoryUserModel) => ({
                            [localization.columns.signInTime.title()]:
                                _.isNil((item.entity as Contract.GciDirectoryUser).signInTime)
                                    ? localization.columns.signInTime.empty()
                                    : TimeFormatter.iso8601String((item.entity as Contract.GciDirectoryUser).signInTime)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <DeferredFilter
                                promiseOrGetPromise={userModelFiltersPromise}
                                title={localization.columns.signInTime.title()}>
                                {filters =>
                                    <TimeRangeFilter
                                        emptyValue={filters.signInTimeRange.emptyValue}
                                        placeholder={localization.columns.signInTime.title()}
                                        timeRange={TimeRangeHelper.getTimeRangeFilterRange(filters.signInTimeRange.timeRange)}/>}
                            </DeferredFilter>
                    }
                }}
                id={Contract.EntityModelProperty.GciDirectoryUserSignInTime}
                itemProperty={translateSignInTime}
                key={Contract.EntityModelProperty.GciDirectoryUserSignInTime}
                sortOptions={{ type: DataTableSortType.Date }}
                title={localization.columns.signInTime.title()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.GciDirectoryUserModel) => ({
                            [localization.columns.activityTime.title()]: activityTranslator(item.access?.activity) ?? ""
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <DeferredFilter
                                promiseOrGetPromise={userModelFiltersPromise}
                                title={localization.columns.activityTime.title()}>
                                {filters =>
                                    <TimeRangeFilter
                                        emptyValue={filters.activityTimeRange.emptyValue}
                                        placeholder={localization.columns.activityTime.title()}
                                        timeRange={TimeRangeHelper.getTimeRangeFilterRange(filters.activityTimeRange.timeRange)}/>}
                            </DeferredFilter>
                    }
                }}
                id={Contract.EntityModelProperty.GciDirectoryUserActivityTime}
                key={Contract.EntityModelProperty.GciDirectoryUserActivityTime}
                message={localization.columns.activityTime.helpText()}
                messageLevel="info"
                render={
                    optionalTableCell<Contract.GciDirectoryUserModel>(
                        userModel =>
                            activityTranslator(userModel.access!.activity))}
                sortOptions={{ type: DataTableSortType.Date }}
                title={localization.columns.activityTime.title()}/>,
            commonDirectoryPrincipalDefinition.columns.gcpRoleBindingIdsColumn,
            commonDirectoryPrincipalDefinition.columns.gcpRoleIdsFilterColumn,
            commonDirectoryPrincipalDefinition.columns.containedGcpRoleBindingIdsColumn,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.GciDirectoryUserModel>(
                        Contract.TypeNames.GciDirectoryGroup,
                        item => item.groupIds,
                        localization.columns.groupIds())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.GciDirectoryPrincipalGroups)}
                                placeholder={localization.columns.groupIds()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GciPrincipalGroups}
                key={Contract.EntityModelProperty.GciPrincipalGroups}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.GciDirectoryUserModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.groupIds}
                            entityTypeName={Contract.TypeNames.GciDirectoryGroup}
                            entityVariant="iconText"/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.groupIds()}/>,
            commonDirectoryPrincipalDefinition.columns.attributesColumn,
            commonDirectoryPrincipalDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonDirectoryPrincipalDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonDirectoryPrincipalDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageGciDirectoryUserRequest(
                new Contract.EntityControllerGetEntityModelPageGciDirectoryUserRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    filterMap[Contract.EntityModelProperty.GciPrincipalContainedGcpRoleBindings] as Optional<boolean>,
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.GciDirectoryPrincipalCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GciDirectoryPrincipalGcpRoles]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GciDirectoryPrincipalMail]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.GciDirectoryUserActivityTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GciPrincipalGroups]),
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.GciDirectoryUserMfaEnforced]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.GciDirectoryUserSignInTime]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.GciDirectoryUserStatus])),
                limit,
                skip,
                requestSort));
}