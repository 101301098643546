import { UnexpectedError, useLocalization } from "@infrastructure";
import _, { Dictionary } from "lodash";
import { Contract, TypeHelper } from "../../../../common";
import { useGroupMembershipRequestActivationFailureDataTranslator, usePermissionAssignmentRequestActivationFailureDataTranslator } from "./hooks";

export const displayedItemCount = 3;
export function usePermissionRequestActivationFailureDataTranslator() {
    const localization =
        useLocalization(
            "views.user.hooks.usePermissionRequestActivationFailureDataTranslator",
            () => ({
                text: "Error: {{errorMessage}}"
            }));

    const groupMembershipRequestActivationFailureDataTranslator = useGroupMembershipRequestActivationFailureDataTranslator();
    const permissionAssignmentRequestActivationFailureDataTranslator = usePermissionAssignmentRequestActivationFailureDataTranslator();
    return (permissionRequestModelActivationFailureData: Contract.PermissionRequestModelActivationFailureData, entityModelMap: Dictionary<Contract.EntityModel>) => {
        if (!_.isNil(permissionRequestModelActivationFailureData.activationFailureData.errorMessage)) {
            return localization.text({ errorMessage: permissionRequestModelActivationFailureData.activationFailureData.errorMessage });
        }

        if (TypeHelper.extendOrImplement(permissionRequestModelActivationFailureData.activationFailureData.typeName, Contract.TypeNames.PermissionAssignmentRequestActivationFailureData)) {
            return permissionAssignmentRequestActivationFailureDataTranslator(permissionRequestModelActivationFailureData);
        }

        if (TypeHelper.extendOrImplement(permissionRequestModelActivationFailureData.activationFailureData.typeName, Contract.TypeNames.GroupMembershipRequestActivationFailureData)) {
            return groupMembershipRequestActivationFailureDataTranslator(
                permissionRequestModelActivationFailureData as Contract.GroupMembershipRequestModelActivationFailureData,
                entityModelMap);
        }

        throw new UnexpectedError("permissionRequestModelActivationFailureData.activationFailureData.typeName", permissionRequestModelActivationFailureData.activationFailureData.typeName);
    };
}