import { Box, Portal } from "@mui/material";
import { GridStack, GridStackWidget } from "gridstack";
import _, { Dictionary } from "lodash";
import React, { useLayoutEffect, useMemo, useRef } from "react";
import { Action1, useUniqueKey } from "@infrastructure";
import { useTheme } from "../../..";
import { DashboardWidgetType, Widget } from "./Widget";
import "gridstack/dist/gridstack.css";
import "gridstack/dist/gridstack-extra.css";

export interface DashboardWidget extends GridStackWidget {
    smallScreenSize?: Pick<GridStackWidget, "maxH" | "minH">;
    type: DashboardWidgetType;
};

type GridStackWrapperProps = {
    editMode?: boolean;
    initialWidgets: DashboardWidget[];
    onWidgetsChanged?: Action1<DashboardWidget[]>;
};

export function GridStackWrapper({ editMode = false, initialWidgets, onWidgetsChanged }: GridStackWrapperProps) {
    const breakpoint = useRef<number>();
    const gridStackRef = useRef<GridStack | undefined>();
    const gridStackContainerRef = useRef<HTMLElement | undefined>();
    const gridStackWidgetsRef = useRef<Dictionary<{ config: GridStackWidget; element: HTMLElement }>>({});
    const gridStackWideWidgetsConfigRef = useRef(initialWidgets);
    const [gridStackWidgetsKey, updateGridStackWidgetsKey] = useUniqueKey();

    useLayoutEffect(
        () => {
            if (_.isNil(gridStackRef.current)) {
                gridStackRef.current =
                    GridStack.init(
                        gridStackOptions,
                        gridStackContainerRef.current);
            }

            gridStackRef.current.
                on(
                    "added",
                    (_event, gridStackNodes) => {
                        _.forEach(
                            gridStackNodes,
                            gridStackNode => {
                                if (gridStackNode.id) {
                                    const element = gridStackNode.el!.querySelector<HTMLElement>(".grid-stack-item-content")!;
                                    gridStackWidgetsRef.current[gridStackNode.id] = {
                                        config: gridStackNode,
                                        element
                                    };
                                }
                            });
                        const widgets = gridStackRef.current!.save(false) as DashboardWidget[];
                        if (breakpoint.current! > 0) {
                            gridStackWideWidgetsConfigRef.current = widgets;
                            onWidgetsChanged?.(widgets);
                        }
                        updateGridStackWidgetsKey();
                    }).
                on(
                    "removed",
                    (_event, gridStackNodes) => {
                        _.forEach(
                            gridStackNodes,
                            gridStackNode => {
                                if (gridStackNode.id) {
                                    delete gridStackWidgetsRef.current[gridStackNode.id];
                                }
                            });
                        const widgets = gridStackRef.current!.save(false) as DashboardWidget[];
                        if (breakpoint.current! > 0) {
                            gridStackWideWidgetsConfigRef.current = widgets;
                            onWidgetsChanged?.(widgets);
                        }
                    }).
                on(
                    "change",
                    () => {
                        const widgets = gridStackRef.current!.save(false) as DashboardWidget[];

                        if (breakpoint.current! > 0) {
                            gridStackWideWidgetsConfigRef.current = widgets;
                            onWidgetsChanged?.(widgets);
                        }

                        const nextBreakpoint = getGridStackColumnOptionsBreakpoint(gridStackContainerRef?.current);
                        if (nextBreakpoint !== breakpoint.current) {
                            breakpoint.current = nextBreakpoint;
                            gridStackRef.current?.load(
                                getWidgetsByGridStackColumnOptionsBreakpoint(
                                    gridStackWideWidgetsConfigRef.current,
                                    nextBreakpoint),
                                false);
                        }
                    }).
                on(
                    "dropped",
                    (_event, _gridStackNodes, gridStackNode) => {
                        const draggedElement = (gridStackNode.el as HTMLElement).querySelector(".grid-stack-drag-indicator")! as HTMLElement;
                        draggedElement.classList.remove("grid-stack-drag-indicator");
                        draggedElement.innerHTML = "";
                    });

            gridStackRef.current!.load(
                getWidgetsByGridStackColumnOptionsBreakpoint(
                    initialWidgets,
                    getGridStackColumnOptionsBreakpoint(gridStackContainerRef?.current)));
        },
        []);

    useLayoutEffect(
        () => {
            gridStackRef.current?.enableMove(editMode);
            gridStackRef.current?.enableResize(editMode);
        },
        [editMode]);

    const widgetRenders =
        useMemo(
            () =>
                _.map(
                    Object.values(gridStackWidgetsRef.current),
                    ({ config, element }) =>
                        _.isNil(element)
                            ? undefined
                            : <Portal
                                container={element}
                                key={config.id}>
                                <Widget
                                    editMode={editMode}
                                    type={(config as DashboardWidget).type}
                                    onRemoveWidget={() => gridStackRef.current?.removeWidget(element.parentElement!)}/>
                            </Portal>),
            [breakpoint.current, editMode, gridStackWidgetsKey]);

    const theme = useTheme();
    const marginLeft = gridStackOptions.margin ?? "10px";
    return (
        <Box
            sx={{
                overflow: "hidden",
                width: "100%"
            }}>
            <Box
                sx={{
                    height: "100%",
                    left: `calc(-1 * ${theme.px(marginLeft)})`,
                    position: "relative",
                    top: `calc(-1 * ${theme.px(gridStackOptions.margin)})`,
                    width: `calc(100% + ${theme.px(marginLeft)} + ${theme.px(gridStackOptions.margin)})`
                }}>
                <Box
                    className="grid-stack"
                    ref={gridStackContainerRef}>
                    {widgetRenders}
                </Box>
            </Box>
        </Box>);
}

function getGridStackColumnOptionsBreakpoint(containerElement?: HTMLElement) {
    const containerWidth = containerElement?.getBoundingClientRect().width ?? 0;
    return containerWidth >= gridStackColumnOptsBreakpoint.w
        ? gridStackColumnOptsBreakpoint.w
        : 0;
}

function getWidgetsByGridStackColumnOptionsBreakpoint(widgets: DashboardWidget[], breakpoint: number) {
    if (breakpoint > 0) {
        return widgets;
    }

    return _.map(
        widgets,
        widget => {
            const widgetData =
                _.isNil(widget.smallScreenSize)
                    ? widget
                    : {
                        ...widget,
                        ...widget.smallScreenSize
                    };

            return _.omit(
                widgetData,
                ["x", "y"]);
        });
}

const gridStackColumnOptsBreakpoint = {
    c: 1,
    w: 1024
};

const gridStackOptions = {
    acceptWidgets: true,
    cellHeight: 55,
    column: 12,
    columnOpts: { breakpoints: [gridStackColumnOptsBreakpoint] },
    margin: 4,
    minRow: 1,
    resizable: { handles: "s,se,e" },
    staticGrid: false
};