﻿import { DataTable, DataTableColumn, Optional, StringHelper, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, useTheme } from "../../../../../../../../../../../../../../common";

type AuthorizedNetworksSectionProps = {
    authorizedSubnetDatas: Contract.GcpContainerClusterAuthorizedSubnetData[];
    getHighlightColor: (authorizedSubnetData: Contract.GcpContainerClusterAuthorizedSubnetData, opacity?: number) => Optional<string>;
};

export function AuthorizedNetworksSection({ authorizedSubnetDatas, getHighlightColor }: AuthorizedNetworksSectionProps) {
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.useGcpContainerClusterInboundExternalWideRangeRiskDefinition.authorizedNetworksSection",
            () => ({
                columns: {
                    name: "Name",
                    subnet: "Subnet"
                }
            }));

    const theme = useTheme();
    return (
        <DataTable
            fetchItems={
                () =>
                    _.orderBy(
                        authorizedSubnetDatas,
                        [
                            authorizedSubnetData => !_.isNil(getHighlightColor(authorizedSubnetData)),
                            authorizedSubnetData => StringHelper.getSortValue(authorizedSubnetData.name)
                        ],
                        [
                            "desc",
                            "asc"
                        ])}
            getItemId={
                authorizedSubnetData =>
                    _(authorizedSubnetDatas).
                        indexOf(authorizedSubnetData).
                        toString()}
            rowOptions={{
                getHighlightColor: authorizedSubnetData => getHighlightColor?.(authorizedSubnetData),
                getSx:
                    authorizedSubnetData => ({
                        backgroundColor: getHighlightColor(authorizedSubnetData, 0.1)
                    })
            }}
            sortOptions={{ enabled: false }}
            variant="card">
            <DataTableColumn
                cellSx={{ width: theme.spacing(44) }}
                id="name"
                itemProperty={authorizedSubnetData => authorizedSubnetData.name}
                title={localization.columns.name()}/>
            <DataTableColumn
                id="subnet"
                itemProperty={authorizedSubnetData => authorizedSubnetData.subnet}
                title={localization.columns.subnet()}/>
        </DataTable>);
}