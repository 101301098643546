import { useLocalization, WelcomeView } from "@infrastructure";
import { Button } from "@mui/material";
import React from "react";
import { Contract, TenantIcon, useTheme } from "../../../../../../../../../common";
import { useSetScopesContext } from "../../../../../Scopes";

export function Welcome() {
    const setScopesContext = useSetScopesContext();
    const localization =
        useLocalization(
            "views.customer.scopes.hooks.useDefinition.hooks.useOpDefinition.welcome",
            () => ({
                action: {
                    add: "Add On-Prem Tenancy"
                },
                title: "Connect your On-Prem Environment"
            }));

    const theme = useTheme();
    return (
        <WelcomeView title={localization.title()}>
            <Button
                startIcon={<TenantIcon tenantType={Contract.TenantType.Op}/>}
                sx={{ padding: theme.spacing(1, 3) }}
                variant="outlined"
                onClick={
                    () =>
                        setScopesContext(
                            context => ({
                                ...context,
                                addOrEditOpen: "scope"
                            }))}>
                {localization.action.add()}
            </Button>
        </WelcomeView>);
}