import { Link, useLocalization } from "@infrastructure";

export function useGetWorkloadResourceOperatingSystemReleaseHistory() {
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.workloadAnalysis.hooks.useWorkloadAnalysisDefinition.hooks.useGetWorkloadResourceOperatingSystemReleaseHistory",
            () => ({
                linkText: "here",
                text: "See history of the releases {{releasesLink}}"
            }));

    return (operatingSystemReleaseNotesUrl: string) => localization.text({
        releasesLink: <Link
            urlOrGetUrl={operatingSystemReleaseNotesUrl}
            variant="external">
            {localization.linkText()}
        </Link>
    });
}