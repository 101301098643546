﻿import { DataTableColumn, ItemSelector, optionalTableCell, useLocalization } from "@infrastructure";
import { Box, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import { Contract, entityModelStore, InlineEntities } from "../../../../../../../../../../../../../../../../common";
import { AwsEc2DeviceTable } from "../../../../../../../../../../../../../../../../tenants";

type RevisionsDeviceTableProps = {
    launchTemplateModel: Contract.AwsEc2LaunchTemplateModel;
    risk: Contract.AwsEc2VolumeEncryptionDisabledRisk;
};

export function RevisionsDeviceTable({ launchTemplateModel, risk }: RevisionsDeviceTableProps) {
    const launchTemplate = launchTemplateModel.entity as Contract.AwsEc2LaunchTemplate;
    const riskData = risk.data as Contract.AwsEc2VolumeEncryptionDisabledRiskEc2LaunchTemplateData;
    const instanceModels = entityModelStore.useGet(riskData.instanceIds);
    const volumeModels = entityModelStore.useGet(risk.aggregatedEntityIds);

    const [selectedRevisionId, setSelectedRevisionId] =
        useState(
            () =>
                _.isEmpty(risk.aggregatedEntityIds)
                    ? _(launchTemplateModel.revisionIdToDataMap).
                        keys().
                        maxBy(Number)!
                    : _.maxBy(riskData.revisionIds, Number)!
        )
    ;
    const instanceRawIds =
        useMemo(
            () =>
                _.map(
                    instanceModels,
                    instanceModel => (instanceModel.entity as Contract.AwsEc2Instance).rawId),
            [risk]);
    const deviceNameToVolumeModelsMap =
        useMemo(
            () =>
                _(launchTemplate.revisionMap[selectedRevisionId].devices).
                    keyBy(device => device.name).
                    mapValues(
                        device =>
                            _.filter(
                                volumeModels,
                                volumeModel =>
                                    !_.isEmpty(
                                        _(instanceRawIds).
                                            intersection(_.keys((volumeModel.entity as Contract.AwsEc2Volume).instanceRawIdToDeviceNameMap)).
                                            filter(instanceRawId => (volumeModel.entity as Contract.AwsEc2Volume).instanceRawIdToDeviceNameMap[instanceRawId] === device.name).
                                            value()))).
                    value(),
            [selectedRevisionId]);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsEc2VolumeEncryptionDisabledRiskDefinition.hooks.useAwsEc2VolumeEncryptionDisabledRiskLaunchTemplateDefinition.revisionsDeviceTable",
            () => ({
                deviceTable: {
                    title: "Block Device Mappings",
                    volumes: "Volumes"
                },
                selectedRevision: {
                    default: "{{revisionId}} (Default)",
                    latest: "Latest : {{revisionId}}"
                },
                title: "Version ({{revisionCount | NumberFormatter.humanize}})"
            }));
    return (
        <Stack
            spacing={4}
            sx={{ overflow: "hidden auto" }}>
            <Box
                sx={{
                    flex: 1,
                    width: "100%"
                }}>
                <ItemSelector
                    dense={true}
                    fullWidth={true}
                    items={
                        _(_.isEmpty(risk.aggregatedEntityIds)
                            ? _.keys(launchTemplateModel.revisionIdToDataMap)
                            : riskData.revisionIds).
                            orderBy(
                                revisionId => Number(revisionId),
                                "desc").
                            value()}
                    selectedItem={selectedRevisionId}
                    sorted={false}
                    onSelectedItemChanged={revisionId => setSelectedRevisionId(revisionId)}>
                    {revisionId =>
                        Number(revisionId) === launchTemplate.defaultRevisionId
                            ? localization.selectedRevision.default({ revisionId })
                            : Number(revisionId) === launchTemplate.latestRevisionId
                                ? localization.selectedRevision.latest({ revisionId })
                                : revisionId}
                </ItemSelector>
            </Box>
            <Stack
                spacing={1}
                sx={{
                    flex: 1,
                    width: "100%"
                }}>
                <Typography
                    sx={{ width: "100%" }}
                    variant="h5">
                    {localization.deviceTable.title()}
                </Typography>
                <Box sx={{ width: "100%" }}>
                    {_.isEmpty(risk.aggregatedEntityIds)
                        ? <AwsEc2DeviceTable
                            devices={
                                _.filter(
                                    launchTemplate.revisionMap[selectedRevisionId].devices,
                                    device => device.encryptionEnabled === false)}
                            snapshotRawIdToIdMap={launchTemplateModel.snapshotRawIdToIdMap}/>
                        : <AwsEc2DeviceTable
                            additionalColumns={[
                                <DataTableColumn
                                    id={localization.deviceTable.volumes()}
                                    key={localization.deviceTable.volumes()}
                                    render={
                                        optionalTableCell<Contract.AwsEc2Device>(
                                            item =>
                                                _.isEmpty(deviceNameToVolumeModelsMap[item.name])
                                                    ? undefined
                                                    : <InlineEntities
                                                        entityIdsOrModels={deviceNameToVolumeModelsMap[item.name]}
                                                        entityTypeName={Contract.TypeNames.AwsEc2Volume}
                                                        variant="itemOrItemCountAndType"/>)}
                                    title={localization.deviceTable.volumes()}/>
                            ]}
                            devices={launchTemplate.revisionMap[selectedRevisionId].devices}
                            snapshotRawIdToIdMap={launchTemplateModel.snapshotRawIdToIdMap}/>}
                </Box>
            </Stack>
        </Stack>);
}