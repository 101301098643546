import _ from "lodash";
import { Contract, RiskPolicyTypeMetadataHelper, RiskType, TenantHelper } from "../../../../../../../../../../../../../../../../../../../common";
import { useTableCommonCloudColumns } from "../../../../../../../../../../../../../hooks";
import { useCommonColumns } from "../../../../useCommonColumns";
import { useCommonCloudRiskPolicyTableDefinition } from "./useCommonCloudRiskPolicyTableDefinition";

export function useDefaultDefinition(riskPolicyConfigurationTypeName: string) {
    const commonColumns = useCommonColumns();
    const commonCloudColumns = useTableCommonCloudColumns();
    return useCommonCloudRiskPolicyTableDefinition({
        columns:
            _.filter([
                commonColumns.statusUpdateTimeColumn,
                commonColumns.severityColumn,
                commonColumns.riskPolicyIdColumn,
                commonCloudColumns.descriptionColumn,
                commonColumns.tenantIdColumn,
                commonColumns.riskedEntityIdColumn(
                    riskPolicyConfigurationTypeName,
                    undefined),
                _(RiskPolicyTypeMetadataHelper.getTenantTypes(riskPolicyConfigurationTypeName)).
                    intersection(TenantHelper.PropertyTenantTypes).
                    some()
                    ? commonColumns.resourceOwnerColumn
                    : undefined,
                _(RiskPolicyTypeMetadataHelper.getTenantTypes(riskPolicyConfigurationTypeName)).
                    intersection(TenantHelper.PropertyTenantTypes).
                    some()
                    ? commonColumns.resourceEnvironmentColumn
                    : undefined,
                commonColumns.statusColumn(true),
                commonColumns.subStatusColumn(true),
                commonColumns.ignoreExpirationDateColumn,
                commonColumns.openStatusUpdateTimeColumn,
                commonColumns.systemCreationTimeColumn,
                commonColumns.riskedEntityAttributesColumn,
                commonColumns.actionsCell(RiskType.Cloud)
            ]),
        getGroupFilters:
            () =>
                new Contract.RiskControllerCloudCommonRiskPolicyTypeFilters(
                    riskPolicyConfigurationTypeName,
                    undefined),
        getRiskIdToScopesIdentityPermissionsMapRequest:
            requestFilters =>
                new Contract.RiskControllerGetRiskPolicyTypeGroupCloudRiskIdToScopeIdentityPermissionsMapRequest(
                    requestFilters,
                    riskPolicyConfigurationTypeName)
    });
}