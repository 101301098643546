import _, { Dictionary } from "lodash";
import React, { useMemo, useState } from "react";
import { Action0, Dialog, Link, StringHelper, useLocalization } from "@infrastructure";
import { Contract, CustomerConsoleAppUrlHelper, Scope, ScopeController, ScopeHelper, ScopeNode, scopeNodeModelStore, tenantModelStore, TreeItem, TreeItemMoveForm, UserHelper, useTheme } from "../../../../../common";

type MoveDialogProps = {
    onClose: Action0;
    onExecute?: Action0;
    parentScopeNodeModel: Contract.ScopeNodeModel;
    scopeIds: string[];
    scopeNodeMap: Dictionary<ScopeNode>;
};

export function MoveDialog({ onClose, onExecute, parentScopeNodeModel, scopeIds, scopeNodeMap }: MoveDialogProps) {
    const [error, setError] = useState(false);
    const [executing, setExecuting] = useState(false);
    const [folderNameExists, setFolderNameExists] = useState(false);
    const [hasScopePermissions, setHasScopePermissions] = useState(true);

    const normalizedFolderNames =
        useMemo(
            () =>
                _(scopeIds).
                    filter(scopeId => ScopeHelper.isFolder(scopeNodeMap[scopeId].scopeNodeModel)).
                    map(scopeId => StringHelper.normalize(scopeNodeMap[scopeId].scopeNodeModel.configuration.name)).
                    value(),
            [scopeIds, scopeNodeMap]);

    const rootItem =
        useMemo(
            () => {
                function createItem(scopeId: string): TreeItem<Contract.ScopeNodeModel> {
                    const scopeNode = scopeNodeMap[scopeId];
                    return new TreeItem(
                        scopeNode.scopeNodeModel,
                        _(scopeNode.scopeNodes).
                            map(scopeNode => scopeNode.scopeNodeModel).
                            filter(
                                scopeNodeModel =>
                                    ScopeHelper.isFolder(scopeNodeModel) &&
                                    !scopeNodeModel.configuration.systemDeleted &&
                                    !_.includes(scopeIds, scopeNodeModel.configuration.id) &&
                                    _([scopeNodeModel.configuration.id]).
                                        concat(scopeNodeMap[scopeNodeModel.configuration.id].folderScopeIds).
                                        some(scopeId => UserHelper.hasScopePermissions(scopeId, Contract.IdentityPermission.SecurityAdministrationRead))).
                            orderBy(scopeNodeModel => StringHelper.getSortValue(scopeNodeModel.configuration.name)).
                            map(scopeNodeModel => createItem(scopeNodeModel.configuration.id)).
                            value());
                }

                return createItem(scopeNodeMap[parentScopeNodeModel.configuration.id].rootFolderId!);
            },
            [parentScopeNodeModel, scopeNodeMap]);

    async function move(parentFolderId: string) {
        setError(false);
        setExecuting(true);

        try {
            for (const scopeId of scopeIds) {
                await ScopeController.moveScope(
                    new Contract.ScopeControllerMoveScopeRequest(
                        scopeId,
                        parentFolderId));
            }

            const [folderOrProjectIds, tenantIds] =
                _.partition(
                    scopeIds,
                    scopeId =>
                        ScopeHelper.isFolder(scopeNodeMap[scopeId].scopeNodeModel) ||
                        scopeNodeMap[scopeId].scopeNodeModel.type === Contract.ScopeType.Project);

            if (!_.isEmpty(folderOrProjectIds)) {
                await scopeNodeModelStore.notify(folderOrProjectIds);
            }

            if (!_.isEmpty(tenantIds)) {
                await tenantModelStore.notify(tenantIds);
            }

            onExecute?.();
            onClose();
        } catch {
            setError(true);
        }

        setExecuting(false);
    }

    const localization =
        useLocalization(
            "views.customer.scopes.moveDialog",
            () => ({
                error: "You can't move the selected account/s because the target folder does not have the same configured integrations as the source folder. {{accountManagementLink}}",
                folderNameExists: "A folder with the same name already exists in the target location",
                insufficientPermissions: "Your permissions do not permit moving folders or accounts to this location",
                learnMore: "Learn more"
            }));

    const errorMessage =
        useMemo(
            () => {
                if (error) {
                    return localization.error({
                        accountManagementLink:
                            <Link
                                urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsCloudOnboardingAccountManagementOptionsRelativeUrl()}
                                variant="external">
                                {localization.learnMore()}
                            </Link>
                    });
                }

                if (hasScopePermissions && !folderNameExists) {
                    return undefined;
                }

                return hasScopePermissions
                    ? localization.folderNameExists()
                    : localization.insufficientPermissions();
            },
            [folderNameExists, hasScopePermissions, error]);

    const theme = useTheme();
    return (
        <Dialog
            variant="editor"
            onClose={onClose}>
            <TreeItemMoveForm
                errorMessage={errorMessage}
                getItemDisabled={
                    item =>
                        (scopeNodeMap[item.configuration.id].scopeNodeModel.configuration as Contract.FolderConfiguration).managed ||
                        !hasScopePermissions ||
                        folderNameExists ||
                        executing}
                loading={executing}
                rootItem={rootItem}
                selected={parentScopeNodeModel}
                onSave={item => move(item.configuration.id)}
                onTreeItemClick={
                    item => {
                        setHasScopePermissions(
                            UserHelper.hasScopePermissions(
                                item.value.configuration.id,
                                Contract.IdentityPermission.SecurityAdministrationRead));
                        setFolderNameExists(
                            !_(item.items).
                                map(item => item.value.configuration.name).
                                intersection(normalizedFolderNames).
                                isEmpty());
                    }}>
                {item =>
                    <Scope
                        scopeId={item.value.configuration.id}
                        sx={{
                            color: theme.palette.text.primary,
                            padding: theme.spacing(0.75)
                        }}/>}
            </TreeItemMoveForm>
        </Dialog>);
}