import { Stack } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract, useTheme } from "../../../../../../../../../../common";
import { RiskPoliciesTable, SectionItem } from "./components";

type SectionsProps = {
    sectionData: Contract.ComplianceSectionData;
};

export function Sections({ sectionData }: SectionsProps) {
    const theme = useTheme();
    return (
        <Stack spacing={1}>
            {!_.isEmpty(sectionData.sectionDatas) &&
                <Stack
                    sx={{
                        overflowX: "hidden",
                        overflowY: "auto"
                    }}>
                    {_.map(
                        sectionData.sectionDatas,
                        complianceSectionData =>
                            <SectionItem
                                key={complianceSectionData.identifier}
                                sectionData={complianceSectionData}/>)}
                </Stack>}
            {(!_.isEmpty(sectionData.riskPolicyDatas) || _.isEmpty(sectionData.sectionDatas)) &&
                <Stack
                    sx={{
                        height:
                            _.size(sectionData.riskPolicyDatas) > 10
                                ? theme.spacing(61)
                                : undefined,
                        overflow: "hidden",
                        paddingLeft: theme.spacing(1.5)
                    }}>
                    <RiskPoliciesTable riskPolicyDatas={sectionData.riskPolicyDatas}/>
                </Stack>}
        </Stack>);
}