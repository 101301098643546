import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function WorkloadAnalysisIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                clipRule="evenodd"
                d="M17.2557 5.13758C16.2486 5.00213 14.9138 5 13 5C11.0864 5 9.75174 5.00237 8.7448 5.13793C7.76661 5.26962 7.24823 5.5103 6.87881 5.87941C6.50982 6.24808 6.26916 6.76595 6.13758 7.7443C6.00213 8.7514 6 10.0862 6 12C6 13.9136 6.00237 15.2483 6.13793 16.2552C6.26962 17.2334 6.5103 17.7518 6.87941 18.1212C7.24808 18.4902 7.76595 18.7308 8.7443 18.8624C9.7514 18.9979 11.0862 19 13 19C14.9136 19 16.2483 18.9976 17.2552 18.8621C18.2334 18.7304 18.7518 18.4897 19.1212 18.1206C19.4902 17.7519 19.7308 17.2341 19.8624 16.2557C19.9979 15.2486 20 13.9138 20 12C20 10.0864 19.9976 8.75174 19.8621 7.7448C19.7304 6.76661 19.4897 6.24823 19.1206 5.87881C18.7519 5.50982 18.2341 5.26916 17.2557 5.13758ZM17.5223 3.15542C18.7225 3.31684 19.7331 3.66218 20.5354 4.4652C21.3373 5.26778 21.6826 6.27789 21.8442 7.47795C22 8.63564 22 10.1079 22 11.9354V12.0732C22 13.8965 22 15.3663 21.8446 16.5223C21.6832 17.7225 21.3378 18.7331 20.5348 19.5354C19.7322 20.3373 18.7221 20.6826 17.522 20.8442C16.3644 21 14.8921 21 13.0646 21H12.9268C11.1035 21 9.63368 21 8.4777 20.8446C7.27755 20.6832 6.26692 20.3378 5.46459 19.5348C4.6627 18.7322 4.31738 17.7221 4.15582 16.522C3.99996 15.3644 3.99998 13.8921 4 12.0647L4 11.9268C3.99997 10.1035 3.99995 8.63368 4.15542 7.4777C4.31684 6.27755 4.66218 5.26692 5.4652 4.46459C6.26778 3.6627 7.27789 3.31738 8.47795 3.15582C9.63564 2.99996 11.1079 2.99998 12.9353 3L13.0732 3C14.8965 2.99997 16.3663 2.99995 17.5223 3.15542Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M8.6 0C9.15228 0 9.6 0.447715 9.6 1V3.2C9.6 3.75228 9.15228 4.2 8.6 4.2C8.04772 4.2 7.6 3.75228 7.6 3.2V1C7.6 0.447715 8.04772 0 8.6 0ZM13 0C13.5523 0 14 0.447715 14 1V3.2C14 3.75228 13.5523 4.2 13 4.2C12.4477 4.2 12 3.75228 12 3.2V1C12 0.447715 12.4477 0 13 0ZM17.4 0C17.9523 0 18.4 0.447715 18.4 1V3.2C18.4 3.75228 17.9523 4.2 17.4 4.2C16.8477 4.2 16.4 3.75228 16.4 3.2V1C16.4 0.447715 16.8477 0 17.4 0ZM1 7.6C1 7.04772 1.44772 6.6 2 6.6H4.2C4.75228 6.6 5.2 7.04772 5.2 7.6C5.2 8.15228 4.75228 8.6 4.2 8.6H2C1.44772 8.6 1 8.15228 1 7.6ZM20.8 7.6C20.8 7.04772 21.2477 6.6 21.8 6.6H24C24.5523 6.6 25 7.04772 25 7.6C25 8.15228 24.5523 8.6 24 8.6H21.8C21.2477 8.6 20.8 8.15228 20.8 7.6ZM1 12C1 11.4477 1.44772 11 2 11H4.2C4.75228 11 5.2 11.4477 5.2 12C5.2 12.5523 4.75228 13 4.2 13H2C1.44772 13 1 12.5523 1 12ZM20.8 12C20.8 11.4477 21.2477 11 21.8 11H24C24.5523 11 25 11.4477 25 12C25 12.5523 24.5523 13 24 13H21.8C21.2477 13 20.8 12.5523 20.8 12ZM1 16.4C1 15.8477 1.44772 15.4 2 15.4H4.2C4.75228 15.4 5.2 15.8477 5.2 16.4C5.2 16.9523 4.75228 17.4 4.2 17.4H2C1.44772 17.4 1 16.9523 1 16.4ZM20.8 16.4C20.8 15.8477 21.2477 15.4 21.8 15.4H24C24.5523 15.4 25 15.8477 25 16.4C25 16.9523 24.5523 17.4 24 17.4H21.8C21.2477 17.4 20.8 16.9523 20.8 16.4ZM8.6 19.8C9.15228 19.8 9.6 20.2477 9.6 20.8V23C9.6 23.5523 9.15228 24 8.6 24C8.04772 24 7.6 23.5523 7.6 23V20.8C7.6 20.2477 8.04772 19.8 8.6 19.8ZM13 19.8C13.5523 19.8 14 20.2477 14 20.8V23C14 23.5523 13.5523 24 13 24C12.4477 24 12 23.5523 12 23V20.8C12 20.2477 12.4477 19.8 13 19.8ZM17.4 19.8C17.9523 19.8 18.4 20.2477 18.4 20.8V23C18.4 23.5523 17.9523 24 17.4 24C16.8477 24 16.4 23.5523 16.4 23V20.8C16.4 20.2477 16.8477 19.8 17.4 19.8Z"
                fillRule="evenodd"/>
        </SvgIcon>);
}