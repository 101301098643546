import { DeferredFilter, EnumValuesFilter, Filter, TimeRangeFilter, useLocalization, ValueFilter, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import _ from "lodash";
import React, { useEffect, useMemo, useRef } from "react";
import { ComplianceFilter, Contract, EntityAttributeFilter, LicensingHelper, riskPolicyModelStore, SeverityFilter, TimeRangeHelper, useRiskPolicyCategoryTranslator, useRiskPolicyTitleTranslator } from "../../../../../../../../../common";
import { RisksItemsFilterId } from "../../../Filters";

export function useCommonFilters(risksView: Contract.RisksView, viewFiltersPromise: Promise<Contract.RiskFilters>) {
    const customRiskPolicyModels = riskPolicyModelStore.useGetLicensedCustom();
    const customRiskPolicyIds =
        useRef(
            _.map(
                customRiskPolicyModels,
                customRiskPolicyModel => customRiskPolicyModel.riskPolicyConfiguration.id));

    useEffect(
        () => {
            customRiskPolicyIds.current =
                _.map(
                    customRiskPolicyModels,
                    customRiskPolicyModel => customRiskPolicyModel.riskPolicyConfiguration.id);
        },
        [customRiskPolicyModels]);

    const riskPolicyTitleTranslator = useRiskPolicyTitleTranslator();
    const riskPolicyCategoryTranslator = useRiskPolicyCategoryTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.filters.hooks.useItems.hooks.useCommonFilters",
            () => ({
                filters: {
                    complianceIdOrSectionTypes: "Compliances",
                    entityAttributes: "Label",
                    ignoreExpirationDate: "Ignored Until",
                    riskPolicyCategory: "Category",
                    riskPolicyType: "Policy",
                    severity: "Severity",
                    starred: "Starred",
                    statusUpdateTime: {
                        [Contract.TypeNames.RisksView]: {
                            [Contract.RisksView.Ignored]: "Ignore Time",
                            [Contract.RisksView.Closed]: "Close Time",
                            [Contract.RisksView.Open]: "Open Time"
                        }
                    },
                    systemCreationTime: "Creation Time",
                    tenantIds: "Accounts",
                    ticketingServiceType: {
                        placeholder: "Ticket",
                        [Contract.TypeNames.TicketingServiceType]: {
                            [Contract.TicketingServiceType.Jira]: "Jira issue",
                            [Contract.TicketingServiceType.ServiceNow]: "ServiceNow incident"
                        }
                    }
                }
            }));

    return useMemo(
        () => ({
            category:
                <Filter
                    default={true}
                    id={RisksItemsFilterId[Contract.RiskFilterId.RiskPolicyCategory]}
                    key={RisksItemsFilterId[Contract.RiskFilterId.RiskPolicyCategory]}
                    title={localization.filters.riskPolicyCategory()}>
                    <DeferredFilter
                        promiseOrGetPromise={viewFiltersPromise}
                        title={localization.filters.riskPolicyCategory()}>
                        {filters =>
                            <ValuesFilter
                                emptyValueOptions={{ enabled: filters.categoryItems.emptyValue }}
                                placeholder={localization.filters.riskPolicyCategory()}>
                                {_.map(
                                    filters.categoryItems.items,
                                    category =>
                                        <ValuesFilterItem
                                            key={category}
                                            title={riskPolicyCategoryTranslator(category)}
                                            value={category}/>)}
                            </ValuesFilter>}
                    </DeferredFilter>
                </Filter>,
            compliance:
                LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.Cspm)
                    ? <Filter
                        id={RisksItemsFilterId[Contract.RiskFilterId.ComplianceIdOrSectionType]}
                        key={RisksItemsFilterId[Contract.RiskFilterId.ComplianceIdOrSectionType]}
                        title={localization.filters.complianceIdOrSectionTypes()}>
                        <DeferredFilter
                            promiseOrGetPromise={viewFiltersPromise}
                            title={localization.filters.complianceIdOrSectionTypes()}>
                            {filters =>
                                <ComplianceFilter
                                    complianceIdentifiers={filters.complianceIdentifierItems.items}
                                    emptyValue={filters.complianceIdentifierItems.emptyValue}
                                    placeholder={localization.filters.complianceIdOrSectionTypes()}/>}
                        </DeferredFilter>
                    </Filter>
                    : undefined,
            labels:
                <Filter
                    default={true}
                    id={RisksItemsFilterId[Contract.RiskFilterId.RiskedEntityAttribute]}
                    key={RisksItemsFilterId[Contract.RiskFilterId.RiskedEntityAttribute]}
                    title={localization.filters.entityAttributes()}>
                    <DeferredFilter
                        promiseOrGetPromise={viewFiltersPromise}
                        title={localization.filters.entityAttributes()}>
                        {filters =>
                            <EntityAttributeFilter
                                emptyValue={filters.attributeValueItems.emptyValue}
                                entityAttributeValues={filters.attributeValueItems.items}
                                placeholder={localization.filters.entityAttributes()}/>}
                    </DeferredFilter>
                </Filter>,
            openStatusUpdateTime:
                risksView === Contract.RisksView.Open
                    ? undefined
                    : <Filter
                        default={true}
                        id={RisksItemsFilterId[Contract.RiskFilterId.OpenStatusUpdateTime]}
                        key={RisksItemsFilterId[Contract.RiskFilterId.OpenStatusUpdateTime]}
                        title={localization.filters.statusUpdateTime[Contract.TypeNames.RisksView][Contract.RisksView.Open]()}>
                        <DeferredFilter
                            promiseOrGetPromise={viewFiltersPromise}
                            title={localization.filters.statusUpdateTime[Contract.TypeNames.RisksView][Contract.RisksView.Open]()}>
                            {filters =>
                                <TimeRangeFilter
                                    emptyValue={filters.openStatusUpdateTimeRange.emptyValue}
                                    placeholder={localization.filters.statusUpdateTime[Contract.TypeNames.RisksView][Contract.RisksView.Open]()}
                                    timeRange={TimeRangeHelper.getTimeRangeFilterRange(filters.openStatusUpdateTimeRange.timeRange)}/>}
                        </DeferredFilter>
                    </Filter>,
            policy:
                <Filter
                    default={true}
                    id={RisksItemsFilterId[Contract.RiskFilterId.RiskPolicyConfigurationTypeNameOrId]}
                    key={RisksItemsFilterId[Contract.RiskFilterId.RiskPolicyConfigurationTypeNameOrId]}
                    title={localization.filters.riskPolicyType()}>
                    <DeferredFilter
                        promiseOrGetPromise={viewFiltersPromise}
                        title={localization.filters.riskPolicyType()}>
                        {filters =>
                            <ValuesFilter
                                emptyValueOptions={{ enabled: filters.riskPolicyIdentifierItems.emptyValue }}
                                groupItemTitle={true}
                                placeholder={localization.filters.riskPolicyType()}>
                                {_(filters.riskPolicyIdentifierItems.items).
                                    filter(
                                        riskPolicyIdentifier =>
                                            riskPolicyIdentifier in Contract.TypeNames ||
                                            _.includes(customRiskPolicyIds.current, riskPolicyIdentifier)).
                                    map(
                                        riskPolicyConfigurationTypeNameOrId =>
                                            <ValuesFilterItem
                                                key={riskPolicyConfigurationTypeNameOrId}
                                                title={riskPolicyTitleTranslator(riskPolicyConfigurationTypeNameOrId)}
                                                value={riskPolicyConfigurationTypeNameOrId}/>).
                                    value()}
                            </ValuesFilter>}
                    </DeferredFilter>
                </Filter>,
            severity:
                <Filter
                    default={true}
                    id={RisksItemsFilterId[Contract.RiskFilterId.RiskSeverity]}
                    key={RisksItemsFilterId[Contract.RiskFilterId.RiskSeverity]}
                    title={localization.filters.severity()}>
                    <SeverityFilter
                        emptyValueOptions={{ enabled: false }}
                        placeholder={localization.filters.severity()}/>
                </Filter>,
            starred:
                <Filter
                    default={true}
                    id={RisksItemsFilterId[Contract.RiskFilterId.RiskStarred]}
                    key={RisksItemsFilterId[Contract.RiskFilterId.RiskStarred]}
                    title={localization.filters.starred()}>
                    <ValueFilter
                        items={[{
                            title: localization.filters.starred(),
                            value: true
                        }]}
                        placeholder={localization.filters.starred()}/>
                </Filter>,
            statusUpdateTime:
                <Filter
                    default={true}
                    id={RisksItemsFilterId[Contract.RiskFilterId.StatusUpdateTime]}
                    key={RisksItemsFilterId[Contract.RiskFilterId.StatusUpdateTime]}
                    title={localization.filters.statusUpdateTime[Contract.TypeNames.RisksView][risksView]()}>
                    <DeferredFilter
                        promiseOrGetPromise={viewFiltersPromise}
                        title={localization.filters.statusUpdateTime[Contract.TypeNames.RisksView][risksView]()}>
                        {filters =>
                            <TimeRangeFilter
                                emptyValue={filters.statusUpdateTimeRange.emptyValue}
                                placeholder={localization.filters.statusUpdateTime[Contract.TypeNames.RisksView][risksView]()}
                                timeRange={TimeRangeHelper.getTimeRangeFilterRange(filters.statusUpdateTimeRange.timeRange)}/>}
                    </DeferredFilter>
                </Filter>,
            systemCreationTime:
                <Filter
                    id={RisksItemsFilterId[Contract.RiskFilterId.SystemCreationTime]}
                    key={RisksItemsFilterId[Contract.RiskFilterId.SystemCreationTime]}
                    title={localization.filters.systemCreationTime()}>
                    <DeferredFilter
                        promiseOrGetPromise={viewFiltersPromise}
                        title={localization.filters.systemCreationTime()}>
                        {filters =>
                            <TimeRangeFilter
                                emptyValue={filters.systemCreationTimeRange.emptyValue}
                                placeholder={localization.filters.systemCreationTime()}
                                timeRange={TimeRangeHelper.getTimeRangeFilterRange(filters.systemCreationTimeRange.timeRange)}/>}
                    </DeferredFilter>
                </Filter>,
            ticketingServiceType:
                <Filter
                    id={RisksItemsFilterId[Contract.RiskFilterId.TicketingServiceType]}
                    key={RisksItemsFilterId[Contract.RiskFilterId.TicketingServiceType]}
                    title={localization.filters.ticketingServiceType.placeholder()}>
                    <EnumValuesFilter
                        emptyValueOptions={{ enabled: true }}
                        enumType={Contract.TicketingServiceType}
                        enumTypeTranslator={(ticketingServiceType: Contract.TicketingServiceType) => localization.filters.ticketingServiceType[Contract.TypeNames.TicketingServiceType][ticketingServiceType]()}
                        placeholder={localization.filters.ticketingServiceType.placeholder()}/>
                </Filter>
        }),
        [viewFiltersPromise]);
}