import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, Entity, entityModelStore, InlineEntities } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { useGetAwsS3BucketRiskContext } from "../contexts";

export function useAwsS3BucketObjectMfaDeleteDisabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsS3BucketObjectMfaDeleteDisabledRisk;
    const bucketModel = entityModelStore.useGet(risk.entityId) as Contract.AwsS3BucketModel;
    const bucket = bucketModel.entity as Contract.AwsS3Bucket;

    const getAwsS3BucketRiskContext = useGetAwsS3BucketRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsS3BucketObjectMfaDeleteDisabledRiskDefinition",
            () => ({
                contextItems: {
                    bucketTrails: "The bucket is storing CloudTrail logs for {{bucketTrails}}",
                    objectLifecycleConfiguration: "The bucket's lifecycle configuration is **unknown** since {{tenantRole}} is missing the required permissions. If the bucket has lifecycle configuration you can't enable MFA delete."
                },
                description: "{{bucket}} MFA delete is not enabled",
                sections: {
                    resolution: {
                        step1: "Run the following command with the **root** user using AWS CLI:\naws s3api put-bucket-versioning --bucket {{bucketName}} --versioning-configuration Status=Enabled,MFADelete=Enabled --mfa \"SERIAL 123456\""
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description(
            {
                bucket:
                    <Entity
                        entityIdOrModel={bucketModel}
                        entityTypeNameTranslatorOptions={{ variant: "title" }}
                        variant="typeText"/>
            }),
        () => [localization.sections.resolution.step1({ bucketName: bucket.name })],
        riskModel,
        () => {
            const bucketRiskContext = getAwsS3BucketRiskContext(bucketModel);
            return [
                bucketRiskContext.generalInformation,
                bucketRiskContext.sensitive,
                _.isNil(bucket.objectLifecycleConfigurationEnabled)
                    ? new RiskDefinitionContextItem(
                        localization.contextItems.objectLifecycleConfiguration({
                            tenantRole:
                                <Entity
                                    entityIdOrModel={risk.tenantRoleId}
                                    variant="text"/>
                        }))
                    : undefined,
                bucketRiskContext.storageSize,
                bucketRiskContext.encryption,
                bucketRiskContext.accessLevel,
                bucketRiskContext.objectVersioningEnabled,
                _.isEmpty(risk.bucketTrailIds)
                    ? undefined
                    : new RiskDefinitionContextItem(
                        localization.contextItems.bucketTrails({
                            bucketTrails:
                                <InlineEntities
                                    entityIdsOrModels={risk.bucketTrailIds}
                                    entityTypeName={Contract.TypeNames.AwsCloudTrailTrail}
                                    entityTypeNameTranslatorOptions={{ variant: "text" }}
                                    variant="itemCountAndType"/>
                        })),
                bucketRiskContext.highestDataSensitivity,
                bucketRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        });
}