﻿import { EmptyMessage, TextViewer, useLocalization } from "@infrastructure";
import { SxProps } from "@mui/material";
import _ from "lodash";
import React, { ReactNode } from "react";

type AwsEc2InstanceUserDataProps = {
    actionsElement?: ReactNode;
    actionsElementSx?: SxProps;
    highlightedLines?: number[];
    startLine?: number;
    userData?: string;
};

export function AwsEc2InstanceUserDataTextViewer({ actionsElement, actionsElementSx, highlightedLines, startLine, userData }: AwsEc2InstanceUserDataProps) {
    const localization =
        useLocalization(
            "tenants.aws.awsEc2InstanceUserDataTextViewer",
            () => ({
                empty: "No User Data"
            }));
    return (
        _.isNil(userData)
            ? <EmptyMessage
                message={localization.empty()}
                verticalCenter={true}/>
            : <TextViewer
                actionsElement={actionsElement}
                actionsElementSx={actionsElementSx}
                format="json"
                highlightedLines={highlightedLines}
                startLine={startLine}
                text={userData}/>);
}