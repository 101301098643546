import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function CentOsIcon(props: SvgIconProps) {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <g clipPath="url(#clip0_52885_254722)">
                <path
                    d="M10.1115 11.1226L10.9766 11.9829L10.1115 12.8431H4.02153V15.7091L0.307617 11.9829L4.02153 8.31923V11.1226H10.1115ZM12.7884 3.80371H20.188V11.2034H12.7884V3.80371Z"
                    fill="#932279"/>
                <path
                    d="M12.8697 10.112L12.0096 10.9771L11.1494 10.112V4.02202H8.2832L12.0096 0.308105L15.6731 4.02202H12.8697V10.112ZM12.7891 12.7889H20.1886V20.1886H12.7891V12.7889Z"
                    fill="#EFA724"/>
                <path
                    d="M13.8804 12.8697L13.0151 12.0096L13.8804 11.1494H19.9701V8.2832L23.6842 12.0096L19.9701 15.6731V12.8697H13.8804ZM3.80371 12.7891H11.2033V20.1888H3.80371V12.7891Z"
                    fill="#262577"/>
                <path
                    d="M11.1226 13.8804L11.9827 13.0151L12.8431 13.8804V19.9703H15.7091L11.9827 23.6842L8.31939 19.9703H11.1226V13.8804ZM3.80371 3.80371H11.2033V11.2034H3.80371V3.80371Z"
                    fill="#9CCD2A"/>
                <path
                    d="M3.53906 3.53955H11.4672V11.4677H3.53906V3.53955ZM4.06753 10.9393H10.9386V4.06818H4.06753V10.9393ZM12.5243 3.53955H20.4525V11.4677H12.5243V3.53955ZM13.0528 10.9393H19.9239V4.06818H13.0528V10.9393ZM12.5243 12.5248H20.4525V20.4532H12.5243V12.5248ZM13.0528 19.9244H19.9239V13.0533H13.0528V19.9244ZM3.53906 12.5248H11.4672V20.4532H3.53906V12.5248ZM4.06753 19.9244H10.9386V13.0533H4.06753V19.9244Z"
                    fill="white"/>
                <path
                    d="M5.64235 17.6025L0.0361328 11.9963L5.64218 6.39021L11.2484 11.9963L5.64235 17.6025ZM0.783673 11.9963L5.64235 16.8549L10.5009 11.9963L5.64218 7.13775L0.783673 11.9963ZM11.9958 11.2489L6.38972 5.64284L11.9958 0.0366211L17.6018 5.64284L11.9958 11.2489ZM7.13726 5.64284L11.9958 10.5013L16.8545 5.64284L11.9958 0.784161L7.13726 5.64284ZM18.3494 17.6025L12.7433 11.9963L18.3494 6.39021L23.9554 11.9963L18.3494 17.6025ZM13.4907 11.9963L18.3494 16.8549L23.2079 11.9963L18.3494 7.13775L13.4907 11.9963ZM11.9958 23.9559L6.38972 18.3499L11.9958 12.7438L17.6018 18.3499L11.9958 23.9559ZM7.13726 18.3499L11.9958 23.2084L16.8545 18.3499L11.9958 13.4913L7.13726 18.3499Z"
                    fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0_52885_254722">
                    <rect
                        fill="white"
                        height="24"
                        width="24"/>
                </clipPath>
            </defs>
        </SvgIcon>);
}