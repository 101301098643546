import { CollapsedIcon } from "@infrastructure";
import { Check } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Box, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { ReactElement } from "react";
import { useTheme } from "../../..";

export type AwsResourceUsageRiskPolicyEditProps = {
    accordionContents: AccordionContent[];
    prompt: string;
    template: string;
};

export type AccordionContent = {
    selector: ReactElement;
    title: string;
    valid: boolean;
};

export function AwsResourceUsageRiskPolicyEdit({ accordionContents, prompt, template }: AwsResourceUsageRiskPolicyEditProps) {
    const theme = useTheme();
    return (
        <Stack spacing={2}>
            <Typography variant="h5">
                {prompt}
            </Typography>
            <Stack spacing={1}>
                <Typography
                    sx={{
                        backgroundColor: theme.palette.background.alternate,
                        borderRadius: theme.spacing(0.75),
                        padding: theme.spacing(2)
                    }}>
                    {template}
                </Typography>
                <Box>
                    {_.map(
                        accordionContents,
                        ({ selector, title, valid }) =>
                            <Accordion key={title}>
                                <AccordionSummary expandIcon={<CollapsedIcon/>}>
                                    <Stack
                                        alignItems="center"
                                        direction="row"
                                        spacing={2}
                                        sx={{ width: "100%" }}>
                                        <Typography
                                            sx={{ flex: 1 }}
                                            variant="h5">
                                            {title}
                                        </Typography>
                                        {valid &&
                                            <Check
                                                sx={{
                                                    color: theme.palette.success.main,
                                                    fontSize: "18px"
                                                }}/>}
                                    </Stack>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {selector}
                                </AccordionDetails>
                            </Accordion>)}
                </Box>
            </Stack>
        </Stack>);
}