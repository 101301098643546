import { Optional, useLocalization } from "@infrastructure";
import React, { useCallback, useState } from "react";
import { Contract } from "../../../../../../../../../../common";
import { useUpdateConfiguration } from "../../../../hooks";
import { AddExclusionTags, ExclusionTagsTableAddComponentProps } from "../../../ExclusionTags";

export function VirtualMachineImageAddExclusionTags({ onAdd, resourceTags }: ExclusionTagsTableAddComponentProps) {
    const [addExclusionTagExecuting, setAddExclusionTagExecuting] = useState(false);
    const [addExclusionTagError, setAddExclusionTagError] = useState(false);

    const updateConfiguration = useUpdateConfiguration();

    const addVirtualMachineImageExclusionTag =
        useCallback(
            async (
                exclusionTagKey: string,
                exclusionTagValue: Optional<string>) => {
                setAddExclusionTagExecuting(true);
                setAddExclusionTagError(false);
                try {
                    const updateWorkloadAnalysisResponse =
                        await updateConfiguration({
                            virtualMachineImageExclusionTags: [
                                new Contract.ResourceTag(
                                    exclusionTagKey,
                                    exclusionTagValue),
                                ...resourceTags]
                        });

                    onAdd(updateWorkloadAnalysisResponse.virtualMachineImageExclusionTags);
                } catch {
                    setAddExclusionTagError(true);
                }

                setAddExclusionTagExecuting(false);
            },
            []);

    const localization =
        useLocalization(
            "views.customer.configuration.workloadAnalysis.virtualMachineImages.virtualMachineImageExclusionTags.virtualMachineImageAddExclusionTags",
            () => ({
                resourceTypeCaps: "Virtual Machine Image",
                resourceTypeNoCaps: "virtual machine image"
            }));

    return <AddExclusionTags
        addExclusionTag={addVirtualMachineImageExclusionTag}
        addExclusionTagError={addExclusionTagError}
        addExclusionTagExecuting={addExclusionTagExecuting}
        resourceTypeLocalization={{
            resourceTypeCaps: localization.resourceTypeCaps(),
            resourceTypeNoCaps: localization.resourceTypeNoCaps()
        }}/>;
}