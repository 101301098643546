﻿import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, InfoCard, InfoItem, VerticalTopItemsInfoItem } from "../../../../../../../../../../../../common";
import { EntitiesInfoItem } from "../../../../../../components";

type ClusterNetworkingInfoCardProps = {
    clusterNetwork: Contract.AwsEksClusterNetwork;
};

export function ClusterNetworkingInfoCard({ clusterNetwork }: ClusterNetworkingInfoCardProps) {
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsEksClusterDefinition.clusterNetworkingInfoCard",
            () => ({
                sections: {
                    authorizedSubnets: "Public Access IP Address Ranges",
                    clusterAndNodeSecurityGroupIdReference: "Cluster Security Group",
                    clusterSecurityGroupIdReferences: "Additional Security Groups",
                    inboundExternalAccess: {
                        false: "Disabled",
                        title: "Endpoint Public Access",
                        true: "Enabled"
                    },
                    nodeInboundExternalAccess: {
                        false: "Enabled",
                        title: "Endpoint Private Access",
                        true: "Disabled"
                    },
                    serviceSubnet: "Services IP Address Range"
                },
                title: "Network"
            }));
    return (
        <InfoCard title={localization.title()}>
            <InfoCard columns={true}>
                <EntitiesInfoItem
                    entityIdsOrModels={clusterNetwork.vpcId}
                    entityTypeName={Contract.TypeNames.AwsEc2Vpc}/>
                <EntitiesInfoItem
                    entityIdsOrModels={clusterNetwork.subnetIds}
                    entityTypeName={Contract.TypeNames.AwsEc2Subnet}/>
                <EntitiesInfoItem
                    entityIdsOrModels={clusterNetwork.clusterAndNodeSecurityGroupIdReference}
                    entityTypeName={Contract.TypeNames.AwsEc2SecurityGroup}
                    title={localization.sections.clusterAndNodeSecurityGroupIdReference()}/>
                <EntitiesInfoItem
                    entityIdsOrModels={clusterNetwork.clusterSecurityGroupIdReferences}
                    entityTypeName={Contract.TypeNames.AwsEc2SecurityGroup}
                    title={localization.sections.clusterSecurityGroupIdReferences()}/>
            </InfoCard>
            <InfoCard columns={true}>
                <InfoItem
                    title={localization.sections.inboundExternalAccess.title()}
                    value={
                        clusterNetwork.inboundExternal
                            ? localization.sections.inboundExternalAccess.true()
                            : localization.sections.inboundExternalAccess.false()}/>
                <InfoItem
                    title={localization.sections.nodeInboundExternalAccess.title()}
                    value={
                        clusterNetwork.nodeInboundExternal
                            ? localization.sections.nodeInboundExternalAccess.true()
                            : localization.sections.nodeInboundExternalAccess.false()}/>
                <VerticalTopItemsInfoItem
                    items={clusterNetwork.authorizedSubnets}
                    title={localization.sections.authorizedSubnets()}/>
                <InfoItem
                    title={localization.sections.serviceSubnet()}
                    value={clusterNetwork.serviceSubnet}/>
            </InfoCard>
        </InfoCard>);
}