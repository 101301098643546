import { Action0, Loading, map, NumberFormatter, Optional, Resizer, Shadows, Sx, useActions, useEntityMiniGlance, useExecuteOperation, useLocalization } from "@infrastructure";
import { Box, ClickAwayListener, List, ListItem, Popover, PopoverActions, PopoverOrigin, Popper, Stack, SxProps, Typography, useTheme } from "@mui/material";
import _ from "lodash";
import React, { Fragment, MouseEvent, ReactNode, RefObject, useCallback, useMemo, useRef, useState } from "react";

type InlineItemsProps = {
    anchorOrigin?: PopoverOrigin;
    children?: (item: any, inline: boolean) => ReactNode;
    itemsPopover?: (items: any[]) => ReactNode;
    itemsPopoverActionsRef?: RefObject<Optional<ItemsPopoverActions>>;
    itemsPopoverTransition?: boolean;
    namePluralizer?: (count: number) => string;
    popoverElementSx?: SxProps;
    sx?: SxProps;
    title?: string;
    variant: InlineItemsVariant;
} & ({
    deferredItems?: never;
    items: any[] | any;
} | {
    deferredItems: DeferredItems;
    items?: never;
});

export type ItemsPopoverActions = {
    close: Action0;
};

export type DeferredItems = {
    count: number;
    firstItem?: any;
    getItems: () => Promise<any[]>;
};

export type InlineItemsVariant = "itemCount" | "itemCountAndType" | "itemOrItemCountAndType" | "itemPlusItemCount" | "text" | "title";

export function InlineItems({ anchorOrigin, children, deferredItems, items, itemsPopover, itemsPopoverActionsRef, itemsPopoverTransition = true, namePluralizer, popoverElementSx, sx, title, variant }: InlineItemsProps) {
    if (
        (_.isArray(items) || !_.isNil(deferredItems)) && (
            (deferredItems?.count ?? items.length) !== 1 ||
            variant === "itemCount" ||
            variant === "itemCountAndType")) {
        return (
            <Items
                anchorOrigin={anchorOrigin}
                deferredItems={deferredItems}
                items={items}
                itemsPopover={itemsPopover}
                itemsPopoverActionsRef={itemsPopoverActionsRef}
                itemsPopoverTransition={itemsPopoverTransition}
                namePluralizer={namePluralizer}
                popoverElementSx={popoverElementSx}
                sx={sx}
                title={title}
                variant={
                    variant === "itemCountAndType"
                        ? "itemOrItemCountAndType"
                        : variant}>
                {children}
            </Items>);
    } else {
        const item =
            deferredItems?.firstItem ??
            (_.isArray(items)
                ? items[0]
                : items);
        return _.isNil(children)
            ? item
            : children(item, true);
    }
}

type ItemsProps =
    Pick<InlineItemsProps, "anchorOrigin" | "children" | "itemsPopover" | "itemsPopoverActionsRef" | "itemsPopoverTransition" | "namePluralizer" | "popoverElementSx" | "sx" | "title" | "variant"> &
    ({
        deferredItems?: never;
        items: any[] | any;
    } | {
        deferredItems: DeferredItems;
        items?: never;
    });

function Items({ anchorOrigin, children, deferredItems, items, itemsPopover, itemsPopoverActionsRef, itemsPopoverTransition, namePluralizer, popoverElementSx, sx, title, variant }: ItemsProps) {
    const [open, setOpen] = useState(false);
    const handleClick =
        useCallback(
            (event: MouseEvent) => {
                if (!_.isNil(deferredItems) ||
                    !_.isEmpty(items)) {
                    setOpen(true);
                }

                event.preventDefault();
                event.stopPropagation();
            },
            [deferredItems, items]);

    const localization =
        useLocalization(
            "infrastructure.inlineItems.items",
            () => ({
                additionalItemCount: "+{{additionalItemCount | NumberFormatter.humanize}}"
            }));

    const itemCount =
        useMemo(
            () => deferredItems?.count ?? items.length,
            [deferredItems, items]);
    const popoverActionsRef = useRef<PopoverActions | null>(null);
    const textElementRef = useRef<any>(null);
    const theme = useTheme();
    const variantElementSx =
        Sx.combine(
            {
                color: theme.palette.text.primary,
                whiteSpace: "nowrap"
            },
            sx,
            itemCount === 0
                ? undefined
                : {
                    cursor: "pointer",
                    textDecoration: "underline"
                });

    useActions<ItemsPopoverActions>(
        itemsPopoverActionsRef,
        {
            close() {
                setOpen(false);
            }
        });

    const popoverElement =
        <Stack
            sx={{
                minWidth: theme.spacing(20),
                ...popoverElementSx
            }}>
            <Loading container="popup">
                <PopoverElement
                    deferredItems={deferredItems}
                    items={items}
                    itemsPopover={itemsPopover}
                    variant={variant}>
                    {children}
                </PopoverElement>
            </Loading>
        </Stack>;
    const entityMiniGlance = useEntityMiniGlance();
    return (
        <Fragment>
            {entityMiniGlance
                ? <ClickAwayListener
                    mouseEvent="onMouseUp"
                    onClickAway={() => setOpen(false)}>
                    <Popper
                        anchorEl={textElementRef.current}
                        disablePortal={true}
                        open={open}
                        placement="bottom-start"
                        sx={{
                            backgroundColor: theme.palette.background.paper,
                            boxShadow: theme.shadows[Shadows.Tooltip],
                            zIndex: theme.zIndex.modal
                        }}>
                        {popoverElement}
                    </Popper>
                </ClickAwayListener>
                : <Popover
                    action={popoverActionsRef}
                    anchorEl={textElementRef.current}
                    anchorOrigin={
                        anchorOrigin ??
                        {
                            horizontal: "center",
                            vertical: "bottom"
                        }}
                    open={open}
                    sx={{ zIndex: theme.zIndex.tooltip }}
                    transformOrigin={{
                        horizontal:
                            variant === "itemPlusItemCount"
                                ? "left"
                                : "center",
                        vertical: "top"
                    }}
                    TransitionProps={{
                        timeout:
                            itemsPopoverTransition
                                ? undefined
                                : 0
                    }}
                    onClick={
                        (event: MouseEvent) => {
                            event.stopPropagation();
                        }}
                    onClose={
                        (event: Event) => {
                            setOpen(false);
                            event.stopPropagation();
                        }}
                    onContextMenu={event => event.stopPropagation()}>
                    <Resizer onSize={() => popoverActionsRef.current?.updatePosition()}/>
                    {popoverElement}
                </Popover>}
            {map(
                variant,
                {
                    "itemCount": () =>
                        <Typography
                            component="span"
                            ref={textElementRef}
                            sx={Sx.combine({ fontSize: "unset" }, variantElementSx)}
                            onClick={handleClick}>
                            {NumberFormatter.unit(itemCount)}
                        </Typography>,
                    "itemOrItemCountAndType": () =>
                        <Typography
                            component="span"
                            noWrap={true}
                            ref={textElementRef}
                            sx={Sx.combine({ fontSize: "unset" }, variantElementSx)}
                            onClick={handleClick}>
                            {namePluralizer
                                ? namePluralizer(itemCount)
                                : itemCount}
                        </Typography>,
                    "itemPlusItemCount": () =>
                        itemCount > 0 && (
                            <Stack
                                direction="row"
                                spacing={1}
                                sx={{ overflow: "hidden" }}>
                                <Typography
                                    noWrap={true}
                                    sx={Sx.combine({ overflow: "hidden" }, sx)}>
                                    {children?.(deferredItems?.firstItem ?? items[0], false) ?? (deferredItems?.firstItem ?? items[0])}
                                </Typography>
                                {itemCount > 1 && (
                                    <Typography
                                        noWrap={true}
                                        ref={textElementRef}
                                        sx={
                                            Sx.combine(
                                                {
                                                    fontSize: "unset",
                                                    minWidth: "min-content"
                                                },
                                                variantElementSx)}
                                        onClick={handleClick}>
                                        {localization.additionalItemCount({ additionalItemCount: itemCount - 1 })}
                                    </Typography>)}
                            </Stack>),
                    "text": () =>
                        <Typography
                            component="span"
                            sx={Sx.combine({ fontSize: "unset" }, sx)}>
                            {namePluralizer
                                ? namePluralizer(itemCount)
                                : itemCount}
                        </Typography>,
                    "title": () =>
                        <Typography
                            component="span"
                            ref={textElementRef}
                            sx={Sx.combine({ fontSize: "unset" }, variantElementSx)}
                            onClick={handleClick}>
                            {title
                                ? title
                                : itemCount}
                        </Typography>
                })}
        </Fragment>);
}

type PopoverElementProps =
    Pick<ItemsProps, "children" | "itemsPopover" | "variant"> &
    ({
        deferredItems?: never;
        items: any[] | any;
    } | {
        deferredItems: DeferredItems;
        items?: never;
    });

function PopoverElement({ children, deferredItems, items, itemsPopover, variant }: PopoverElementProps) {
    const theme = useTheme();
    const [resolvedItems] =
        useExecuteOperation(
            [PopoverElement],
            async () => items ?? await deferredItems?.getItems());

    const popoverItems =
        useMemo(
            () =>
                variant === "itemPlusItemCount"
                    ? (!_.isNil(deferredItems)
                        ? _.filter(
                            resolvedItems,
                            resolvedItem => !_.isEqual(resolvedItem, deferredItems.firstItem))
                        : _.drop(resolvedItems, 1))
                    : resolvedItems,
            [resolvedItems, variant]);

    return (
        _.isNil(itemsPopover)
            ? <List
                sx={{
                    maxHeight: theme.spacing(50),
                    maxWidth: "450px",
                    overflow: "hidden auto",
                    padding: 0
                }}>
                {_.map(
                    popoverItems,
                    (popoverItem, popoverItemIndex) =>
                        <ListItem
                            key={popoverItemIndex}
                            sx={{
                                color: theme.palette.text.primary,
                                padding: theme.spacing(1, 1.5)
                            }}>
                            {children
                                ? children(popoverItem, false)
                                : popoverItem}
                        </ListItem>)}
            </List>
            : <Box>
                {itemsPopover(popoverItems)}
            </Box>);
}