import { Box, CircularProgress, useTheme } from "@mui/material";
import _, { Function0 } from "lodash";
import React, { Fragment, ReactNode, useEffect, useState } from "react";
import { Dropdown } from "../../Dropdown";
import { SelectionView } from "../../SelectionView";
import { useFilterConnectorContext, useSetFilterConnectorContext } from "./FilterConnector";
import { FilterField } from "./FilterField";

type DeferredFilterProps<TFilters> = {
    children: (filters: TFilters) => ReactNode;
    promiseOrGetPromise: Promise<TFilters> | Function0<Promise<TFilters>>;
    title: string;
};

export function DeferredFilter<TFilters>({ children, promiseOrGetPromise, title }: DeferredFilterProps<TFilters>) {
    const { filter, open } = useFilterConnectorContext();
    const [filters, setFilters] = useState<TFilters>();
    const setFilterConnectorContext = useSetFilterConnectorContext();
    useEffect(
        () => {
            if (_.isNil(filters) && (open || !_.isNil(filter))) {
                (_.isFunction(promiseOrGetPromise)
                    ? promiseOrGetPromise()
                    : promiseOrGetPromise).
                    then(setFilters);
            }
        },
        [filter, open]);

    const theme = useTheme();
    return _.isNil(filters)
        ? <Dropdown
            open={open}
            popoverElement={
                <Box
                    sx={{
                        height: theme.spacing(40),
                        textAlign: "center",
                        width: theme.spacing(31)
                    }}>
                    <CircularProgress
                        size="18px"
                        sx={{ margin: theme.spacing(2) }}
                        variant="indeterminate"/>
                </Box>}
            onOpen={
                () =>
                    setFilterConnectorContext(
                        context =>
                            ({
                                ...context,
                                open: true
                            }))}>
            <FilterField
                emptyValue={_.isNil(filter) || filter.length === 0}
                focused={open}
                placeholder={title}
                selection={
                    <SelectionView
                        empty={_.isNil(filter) || filter.length === 0}
                        label={title}
                        loaded={!_.isNil(filters)}
                        selectedValues={filter}
                        totalCount={0}/>}/>
        </Dropdown>
        : <Fragment>
            {children(filters)}
        </Fragment>;
}