﻿import { TimeFormatter, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, entityModelStore, InfoItem } from "../../../../../../../../../../common";
import { AadDirectoryApplicationExpirationDateCell } from "../../../../../../../../../../tenants";
import { EntitiesInfoItem, Info } from "../../../../components";
import { DefinitionOptions, EntityProfileDefinition } from "../../useDefinition";
import { useAadDefaultDirectoryEntityInfoItemElements } from "./useAadDefaultDirectoryEntityInfoItemElements";

export function useAadDirectoryApplicationCertificateDefinition(directoryEntityModel: Contract.AadDirectoryEntityModel, options?: DefinitionOptions) {
    const certificateModel = directoryEntityModel as Contract.AadDirectoryApplicationCertificateModel;
    const certificate = certificateModel.entity as Contract.AadDirectoryApplicationCertificate;
    const applicationModel = entityModelStore.useGet(certificate.applicationId) as Contract.AadDirectoryApplicationModel;
    const defaultDirectoryEntityInfoItemElements = useAadDefaultDirectoryEntityInfoItemElements(directoryEntityModel as Contract.AadDirectoryPrincipalModel);

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aad.useAadDirectoryApplicationCertificateDefinition",
            () => ({
                info: {
                    items: {
                        application: "App Registration",
                        applicationOwnerPrincipal: "App Registration Owners",
                        description: "Description",
                        expirationStatus: "Expiry status",
                        expirationTime: "Expiration Time",
                        keyVaultCertificate: "Key Vault Certificate",
                        thumbprint: "Thumbprint"
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={[]}
                defaultTenantInfoItemElements={defaultDirectoryEntityInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="thumbprint"
                        title={localization.info.items.thumbprint()}
                        value={certificate.thumbprint}/>,
                    <InfoItem
                        key="description"
                        title={localization.info.items.description()}
                        value={certificate.description}/>,
                    <InfoItem
                        key="expirationTime"
                        title={localization.info.items.expirationTime()}
                        value={TimeFormatter.profileFormatDateTime(certificate.expirationTime)}/>,
                    <InfoItem
                        key="expirationStatus"
                        title={localization.info.items.expirationStatus()}
                        value={
                            <AadDirectoryApplicationExpirationDateCell
                                expirationDate={certificate.expirationTime}
                                expirationStatus={certificate.expirationStatus}
                                variant="status"/>}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={certificate.applicationId}
                        entityTypeName={Contract.TypeNames.AadDirectoryApplication}
                        key="application"
                        title={localization.info.items.application()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={certificateModel.applicationOwnerPrincipalIds}
                        entityTypeName={Contract.TypeNames.AadDirectoryIdentity}
                        key="applicationOwnerPrincipal"
                        title={localization.info.items.applicationOwnerPrincipal()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={
                            _.isNil(certificate.thumbprint)
                                ? undefined
                                : applicationModel.certificateThumbprintToCertificateIdsMap[certificate.thumbprint]}
                        entityTypeName={Contract.TypeNames.AzureKeyVaultVaultCertificate}
                        key="keyVaultCertificate"
                        title={localization.info.items.keyVaultCertificate()}/>
                ]}
                options={options?.infoOptions}>
            </Info>,
        sensitive: false
    });
}