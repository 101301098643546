import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function PermissionEligibilitiesIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path d="M7 7C6.44772 7 6 7.44772 6 8C6 8.55228 6.44772 9 7 9C7.55229 9 8 8.55228 8 8C8 7.44772 7.55229 7 7 7Z"/>
            <path d="M7 11C6.44772 11 6 11.4477 6 12C6 12.5523 6.44772 13 7 13C7.55229 13 8 12.5523 8 12C8 11.4477 7.55229 11 7 11Z"/>
            <path d="M6 16C6 15.4477 6.44772 15 7 15C7.55228 15 8 15.4477 8 16C8 16.5523 7.55228 17 7 17C6.44772 17 6 16.5523 6 16Z"/>
            <path d="M10 7C9.44771 7 9 7.44772 9 8C9 8.55229 9.44771 9 10 9H16C16.5523 9 17 8.55229 17 8C17 7.44772 16.5523 7 16 7H10Z"/>
            <path d="M9 12C9 11.4477 9.44771 11 10 11H16C16.5523 11 17 11.4477 17 12C17 12.5523 16.5523 13 16 13H10C9.44771 13 9 12.5523 9 12Z"/>
            <path d="M10 15C9.44771 15 9 15.4477 9 16C9 16.5523 9.44771 17 10 17H16C16.5523 17 17 16.5523 17 16C17 15.4477 16.5523 15 16 15H10Z"/>
            <path
                clipRule="evenodd"
                d="M2 4C2 2.89543 2.89543 2 4 2H19C20.1046 2 21 2.89543 21 4V20C21 21.1046 20.1046 22 19 22H4C2.89543 22 2 21.1046 2 20V4ZM4 4H19V20H4V4Z"
                fillRule="evenodd"/>
        </SvgIcon>);
}