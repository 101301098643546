import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function MonitoringIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                clipRule="evenodd"
                d="M12.3672 2.00007C12.8433 1.99431 13.2574 2.325 13.3571 2.79056L16.1483 15.8216L17.7108 8.33591C17.8043 7.88751 18.1906 7.56003 18.6483 7.54109C19.106 7.52214 19.518 7.81657 19.6483 8.2557L21.459 14.3563H23C23.5523 14.3563 24 14.804 24 15.3563C24 15.9086 23.5523 16.3563 23 16.3563H20.7126C20.2699 16.3563 19.8799 16.0653 19.754 15.6409L18.8587 12.6244L17.1398 20.8595C17.0433 21.3221 16.6361 21.6539 16.1635 21.6552C15.691 21.6564 15.2821 21.3267 15.1831 20.8646L12.4406 8.06053L10.8332 16.565C10.7619 16.9422 10.481 17.2453 10.1103 17.345C9.73967 17.4447 9.34464 17.3234 9.09375 17.0329L6.64255 14.1947L5.37239 15.9439C5.18425 16.203 4.88342 16.3563 4.56322 16.3563H1C0.447715 16.3563 0 15.9086 0 15.3563C0 14.804 0.447715 14.3563 1 14.3563H4.05353L5.76554 11.9986C5.94497 11.7515 6.22747 11.6 6.53257 11.5871C6.83768 11.5742 7.13194 11.7015 7.33154 11.9326L9.25284 14.1573L11.3967 2.81429C11.4851 2.34645 11.8911 2.00584 12.3672 2.00007Z"
                fillRule="evenodd"/>
        </SvgIcon>);
}