import { DropdownIcon, makeContextProvider } from "@infrastructure";
import { MenuItem, Select as MuiSelect, SelectProps as MuiSelectProps, SvgIconProps, useTheme } from "@mui/material";
import _ from "lodash";
import React from "react";

export class SelectItem {
    constructor(
        public title: string,
        public value: any) {
    }
}

type SelectProps = MuiSelectProps & {
    emptyTitle?: string;
    items: SelectItem[];
};

export class SelectContext {
    constructor(public disabled: boolean) {
    }
}

export const [useSelectContext, , useSelectContextProvider] = makeContextProvider<SelectContext>();

export function Select({ disabled = false, emptyTitle, items, ...props }: SelectProps) {
    const [, , ContextProvider] =
        useSelectContextProvider(
            () => new SelectContext(disabled),
            [disabled]);

    return (
        <ContextProvider>
            <MuiSelect
                disabled={disabled}
                displayEmpty={!_.isNil(emptyTitle)}
                IconComponent={Icon}
                MenuProps={{
                    anchorOrigin: {
                        horizontal: "left",
                        vertical: "bottom"
                    },
                    transformOrigin: {
                        horizontal: "left",
                        vertical: "top"
                    }
                }}
                renderValue={
                    !_.isNil(emptyTitle)
                        ? value =>
                            _.isEmpty(value)
                                ? emptyTitle
                                : _.find(items, item => item.value === value)!.title
                        : undefined}
                variant="standard"
                {...props}>
                {_.map(
                    items,
                    item =>
                        <MenuItem
                            key={item.value}
                            value={item.value}>
                            {item.title}
                        </MenuItem>)}
            </MuiSelect>
        </ContextProvider>);
}

function Icon({ ...props }: SvgIconProps) {
    const { disabled } = useSelectContext();
    const theme = useTheme();
    return (
        <DropdownIcon
            {...props}
            sx={{
                color:
                    disabled
                        ? theme.palette.text.disabled
                        : theme.palette.text.secondary,
                marginRight: theme.spacing(0.5),
                top: "auto"
            }}/>);
}