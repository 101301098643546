import { EmptyMessageText, InlineItems, NoneIcon, SearchList, useLocalization } from "@infrastructure";
import { Box, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { UdmObjectPropertyItemProps } from "../..";
import { useTheme } from "../../../../../themes";

export function InfraString({ item, objectProperty }: UdmObjectPropertyItemProps) {
    const localization =
        useLocalization(
            "common.udmObjectTable.udmObjectPropertyItem.infraString",
            () => ({
                empty: {
                    withFilter: "No Matching Items",
                    withoutFilter: "No Items"
                }
            }));

    const theme = useTheme();
    return _.isEmpty(item)
        ? <NoneIcon sx={{ fontSize: "18px" }}/>
        : objectProperty.collection
            ? <InlineItems
                items={item}
                itemsPopover={
                    items =>
                        <SearchList
                            disablePadding={true}
                            emptyMessageText={
                                new EmptyMessageText(
                                    localization.empty.withoutFilter(),
                                    localization.empty.withFilter())}
                            itemOptions={{
                                render: item => <Item item={item}/>,
                                spacing: 1
                            }}
                            items={items}
                            searchOptions={{ enabled: true }}
                            sx={{
                                maxHeight: theme.spacing(50),
                                minWidth: theme.spacing(20),
                                overflow: "hidden auto",
                                padding: theme.spacing(0, 1.5)
                            }}
                            variant="dropdown"/>}
                variant="itemPlusItemCount">
                {item => <Item item={item}/>}
            </InlineItems>
            : <Item item={item}/>;
}

type ItemProps = {
    item: any;
};

function Item({ item }: ItemProps) {
    return (
        <Box sx={{ overflow: "hidden" }}>
            <Typography noWrap={true}>
                {item}
            </Typography>
        </Box>);
}