import _ from "lodash";
import { Contract, LicensingHelper, ScopeHelper, useScopeNavigationViewContext } from "../../../../../../../../common";
import { IntegrationItem } from "../../utilities";
import { useCiIntegrationItems, useCloudProviderTenantOrganizationItems, useCodeOrganizationItems, useCodePipelineItems, useCodeServerItems, useCollaborationItems, useEndpointConnectorItems, useIdentityProviderTenantItems, useKubernetesItems, useObservabilityItems, useTicketingItems, useWebhooksItems } from "./hooks";

export function useItems(childScopeEnabled: boolean): IntegrationItem[] {
    const { scopeNodeModel } = useScopeNavigationViewContext();
    const isCustomerScope = scopeNodeModel.configuration.typeName === Contract.TypeNames.CustomerConfiguration;

    const ciIntegrationItems = useCiIntegrationItems();
    const cloudProviderTenantOrganizationItems = useCloudProviderTenantOrganizationItems();
    const codeOrganizationItems = useCodeOrganizationItems();
    const codePipelineItems = useCodePipelineItems();
    const codeServerItems = useCodeServerItems();
    const collaborationItems = useCollaborationItems(childScopeEnabled);
    const endpointConnectorItems = useEndpointConnectorItems(childScopeEnabled);
    const identityProviderTenantItems = useIdentityProviderTenantItems();
    const kubernetesItems = useKubernetesItems(childScopeEnabled);
    const observabilityItems = useObservabilityItems(childScopeEnabled);
    const ticketingItems = useTicketingItems(childScopeEnabled);
    const webhooksItems = useWebhooksItems(childScopeEnabled);
    return _<IntegrationItem>([]).
        concatIf(
            isCustomerScope,
            cloudProviderTenantOrganizationItems,
            identityProviderTenantItems).
        concatIf(
            isCustomerScope &&
            LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.Cspm),
            codeServerItems,
            codeOrganizationItems,
            codePipelineItems).
        concatIf(
            isCustomerScope ||
            ScopeHelper.isCloudProviderTenantsScope(scopeNodeModel),
            kubernetesItems).
        concat(
            ticketingItems,
            collaborationItems,
            observabilityItems,
            webhooksItems).
        concat(endpointConnectorItems).
        concatIf(
            isCustomerScope &&
            LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.Cnapp),
            ciIntegrationItems).
        value();
}