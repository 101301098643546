﻿import { useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useEffect } from "react";
import { Contract, ElasticsearchItemPageHelper, EntityController, PagedEntityMultiSelect } from "../../../../../../../../../common";
import { useAddOrEditContext, useSetAddOrEditContext } from "../../../AddOrEdit";


export function GranteePrincipals() {
    const { permissionEligibilityData: { granteePrincipalIds, principalTenantId }, upsertPermissionEligibilityExecuting } = useAddOrEditContext();
    const setAddOrEditContext = useSetAddOrEditContext();

    const localization =
        useLocalization(
            "views.user.permissionEligibilities.addOrEdit.hooks.useDefinition.granteePrincipals",
            () => ({
                usersAndGroups: "Users and groups"
            }));

    useEffect(
        () => {
            setAddOrEditContext(
                addOrEditContext => ({
                    ...addOrEditContext,
                    fieldNameToValidMap: {
                        ...addOrEditContext.fieldNameToValidMap,
                        granteePrincipals: !_.isEmpty(granteePrincipalIds)
                    }
                }));
        },
        [granteePrincipalIds]);

    return (
        <Stack spacing={1.5}>
            <Typography variant="h4">
                {localization.usersAndGroups()}
            </Typography>
            <PagedEntityMultiSelect
                disabled={
                    _.isNil(principalTenantId) ||
                    upsertPermissionEligibilityExecuting}
                fullWidth={true}
                getEntityModelPage={
                    ElasticsearchItemPageHelper.makePagedEntityMultiSelect(
                        async (itemNextPageSearchCursor, searchText) => {
                            const { entityModelPage } =
                                await EntityController.searchEntityModels(
                                    new Contract.EntityControllerSearchEntityModelsPermissionManagementPrincipalRequest(
                                        false,
                                        15,
                                        itemNextPageSearchCursor,
                                        undefined,
                                        searchText,
                                        principalTenantId!));
                            return entityModelPage;
                        })}
                placeholder={localization.usersAndGroups()}
                selectedEntityIds={granteePrincipalIds}
                onSelectedEntityIdsChanged={
                    granteePrincipalIds =>
                        setAddOrEditContext(
                            addOrEditContext => {
                                addOrEditContext.permissionEligibilityData.granteePrincipalIds = granteePrincipalIds;
                                return { ...addOrEditContext };
                            })}/>
        </Stack>);
}