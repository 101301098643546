import { Loading, useChangeEffect, useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { Fragment, useState } from "react";
import { Contract, EntityTypeMetadataModelHelper, EntityTypeNameSelector, ReportItem } from "../../../../../../../../../common";
import { Table as EntitiesTable } from "../../../../Table";

export type ItemOptions = {
    entityTypeName?: string;
    filterMap?: Dictionary<any>;
};

export function useGetItem(itemOptions: ItemOptions, tenantTypes: Contract.TenantType[]) {
    const [entityTypeName, setEntityTypeName] = useState(itemOptions?.entityTypeName);
    const [filterMap, setFilterMap] = useState(itemOptions?.filterMap);

    const localization =
        useLocalization(
            "views.customer.entities.report.hooks.useItems.hooks.useGetItem",
            () => ({
                entityTypeName: {
                    placeHolder: "Resource Type (optional)",
                    title: "Resource Type"
                }
            }));

    useChangeEffect(
        () => {
            if (entityTypeName &&
                !_.includes(
                    tenantTypes,
                    EntityTypeMetadataModelHelper.get(entityTypeName).tenantType)) {
                setEntityTypeName(undefined);
            }
        },
        [tenantTypes]);

    return (): ReportItem => ({
        configuration:
            <Fragment>
                <Stack spacing={1}>
                    <Typography variant="h4">
                        {localization.entityTypeName.title()}
                    </Typography>
                    <EntityTypeNameSelector
                        multiSelect={false}
                        placeholder={localization.entityTypeName.placeHolder()}
                        selectedEntityTypeNames={_.filter([entityTypeName]) as string[]}
                        tenantTypes={tenantTypes}
                        onSelectedItemsChanged={
                            selectedTypeNames => {
                                setEntityTypeName(_.head(selectedTypeNames));
                                setFilterMap({});
                            }}/>
                </Stack>
                {!_.isNil(entityTypeName) &&
                    <Loading>
                        <EntitiesTable
                            entityTypeEntitiesViewName={entityTypeName}
                            filtersOptions={{
                                initialMap: filterMap,
                                onFiltersChanged: setFilterMap
                            }}
                            key={entityTypeName}
                            variant="filtersOnly"/>
                    </Loading>}
            </Fragment>,
        generate:
            name =>
                new Contract.ReportControllerInventoryReportRequestDefinition(
                    name,
                    undefined,
                    Contract.TypeNames.ReportControllerInventoryReportRequestDefinition,
                    false,
                    entityTypeName,
                    _.isEmpty(filterMap)
                        ? undefined
                        : JSON.stringify(filterMap))
    });
}