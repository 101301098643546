import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useAadTenantEntityExternalUserInvitationAllowedRolesTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useAadTenantEntityExternalUserInvitationAllowedRolesTranslator",
            () => ({
                [Contract.TypeNames.AadTenantEntityExternalUserInvitationAllowedRoles]: {
                    [Contract.AadTenantEntityExternalUserInvitationAllowedRoles.AdminsAndGuestInviters]: "Only users assigned to specific admin roles can invite guest users",
                    [Contract.AadTenantEntityExternalUserInvitationAllowedRoles.AdminsGuestInvitersAndAllMembers]: "Member users and users assigned to specific admin roles can invite guest users including guests with member permissions",
                    [Contract.AadTenantEntityExternalUserInvitationAllowedRoles.Everyone]: "Anyone in the organization can invite guest users including guests and non-admins (most inclusive)",
                    [Contract.AadTenantEntityExternalUserInvitationAllowedRoles.None]: "No one in the organization can invite guest users including admins (most restrictive)"
                }
            }));

    return (type: Contract.AadTenantEntityExternalUserInvitationAllowedRoles) =>
        localization[Contract.TypeNames.AadTenantEntityExternalUserInvitationAllowedRoles][type]();
}