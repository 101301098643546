import { CheckButton, StorageItem, useSetToggleFilterConnectorContext, useToggleFilterConnectorContext } from "@infrastructure";
import { Box, useTheme } from "@mui/material";
import React from "react";

type ToggleFilterProps = {
    disabled?: boolean;
    persistStorageItem?: StorageItem;
    title: string;
};

export function ToggleFilter({ disabled, persistStorageItem, title }: ToggleFilterProps) {
    const { filter: toggled } = useToggleFilterConnectorContext() as { filter?: boolean };
    const setToggleFilterConnectorContext = useSetToggleFilterConnectorContext();

    const theme = useTheme();
    return (
        <Box sx={{ backgroundColor: theme.palette.background.paper }}>
            <CheckButton
                checked={toggled ?? false}
                disabled={disabled ?? false}
                title={title}
                onCheckedChanged={
                    checked => {
                        setToggleFilterConnectorContext({ filter: checked || undefined });
                        persistStorageItem?.setValue(checked);
                    }}/>
        </Box>);
}