import { MailIcon, useLocalization, useSelectionActionsContext } from "@infrastructure";
import React, { Fragment, useState } from "react";
import { SendRisksMailDialog } from "../../../../../..";
import { Contract, SelectionActionButton } from "../../../../../../../../../../../common";

type SendMailSelectionActionProps = {
    clearSelection: () => void;
};

export function SendMailSelectionAction({ clearSelection }: SendMailSelectionActionProps) {
    const { actionEnded, actionExecuting, actionStarted, itemIds } = useSelectionActionsContext();
    const [open, setOpen] = useState(false);

    const disabled = itemIds.length > 10;

    const localization =
        useLocalization(
            "views.customer.risks.items.hooks.useRiskTypeDefinition.hooks.useCommonSelectionActions.sendMailSelectionAction",
            () => ({
                disabledTooltip: "Sending via mail is limited to 10 findings",
                share: "Send via mail"
            }));

    return (
        <Fragment>
            {open &&
                <SendRisksMailDialog
                    riskIds={itemIds}
                    typeName={Contract.TypeNames.MailDelivery}
                    onClose={
                        canceled => {
                            if (!canceled) {
                                clearSelection();
                            }
                            actionEnded();
                            setOpen(false);
                        }}/>}
            <SelectionActionButton
                disabled={disabled}
                startIcon={
                    <MailIcon
                        sx={{
                            opacity:
                                actionExecuting
                                    ? 0.4
                                    : 1
                        }}/>}
                tooltip={
                    disabled
                        ? localization.disabledTooltip()
                        : undefined}
                onClick={
                    () => {
                        setOpen(true);
                        actionStarted(true);
                    }}>
                {localization.share()}
            </SelectionActionButton>
        </Fragment>);
}