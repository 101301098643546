﻿import _ from "lodash";
import { DataTableColumn, EnumValuesFilter, useLocalization } from "@infrastructure";
import { Contract, useKubernetesServiceTypeTranslator } from "../../../../../../../../../common";

export function useCommonKubernetesServiceDefinition() {
    const kubernetesServiceTypeTranslator = useKubernetesServiceTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.useCommonKubernetesServiceDefinition",
            () => ({
                columns: {
                    type: "Service Type"
                }
            }));

    return {
        columns: {
            typeColumn:
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.EntityModel) => ({
                                [localization.columns.type()]: kubernetesServiceTypeTranslator(_.as<Contract.IKubernetesService>(item.entity).data.type)
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <EnumValuesFilter
                                    enumType={Contract.KubernetesServiceDataType}
                                    enumTypeTranslator={kubernetesServiceTypeTranslator}
                                    placeholder={localization.columns.type()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.KubernetesServiceType}
                    itemProperty={(item: Contract.EntityModel) => kubernetesServiceTypeTranslator(_.as<Contract.IKubernetesService>(item.entity).data.type)}
                    key={Contract.EntityModelProperty.KubernetesServiceType}
                    title={localization.columns.type()}/>
        }
    };
}