import { AnalyticsEventActionType, Dropdown, DropdownActions, SelectionView, useFiltersContext, useSetFiltersContext, useTrackAnalytics } from "@infrastructure";
import CheckIcon from "@mui/icons-material/Check";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import _ from "lodash";
import React, { ReactNode, useCallback, useEffect, useMemo, useRef } from "react";
import { FilterField, useFilterConnectorContext, useSetFilterConnectorContext } from ".";

type ValueFilterProps<T> = {
    items: ValueFilterItem<T>[];
    placeholder: string;
};

type ValueFilterItem<T = unknown> = {
    title?: string;
    value: T;
};

export function ValueFilter<T>({ items, placeholder }: ValueFilterProps<T>) {
    const { filter, id, open } = useFilterConnectorContext();
    const { activeFilter } = useFiltersContext();
    const setFilterConnectorContext = useSetFilterConnectorContext();
    const setFiltersContext = useSetFiltersContext();

    const onClose =
        useCallback(
            () => {
                setFiltersContext(
                    context => ({
                        ...context,
                        activeFilter: undefined
                    }));
                setFilterConnectorContext(
                    context => ({
                        ...context,
                        open: false
                    }));
            },
            []);

    const actionsRef = useRef<DropdownActions>();
    const trackAnalytics = useTrackAnalytics();

    function setCurrentFilter(item: ValueFilterItem) {
        setFilterConnectorContext(
            context => ({
                ...context,
                filter:
                    context.filter === item.value
                        ? undefined
                        : item.value
            }));
    }

    const onValueSelected =
        useCallback(
            (item: ValueFilterItem) =>
                () => {
                    trackAnalytics(
                        AnalyticsEventActionType.FilterValueSet,
                        {
                            "Filter Name": placeholder,
                            "Filter Selected Value": item.title ?? item.value as string,
                            "Filter Type": "ValueFilter"
                        });
                    setCurrentFilter(item);
                    actionsRef.current?.close?.();
                },
            [actionsRef]);

    const selectedItem =
        useMemo(
            () =>
                _.find(
                    items,
                    item => item.value === filter),
            [filter]);
    const singleSelection = useMemo(() => items.length === 1, [items]);
    const Container =
        useMemo(
            () =>
                singleSelection
                    ? SingleValueContainer
                    : MultipleValuesContainer,
            [singleSelection]);

    useEffect(
        () => {
            if(activeFilter === id && singleSelection) {
                setCurrentFilter(items[0]);
                onClose();
            }
        },
        []);

    return (
        <Container
            actionsRef={actionsRef}
            items={items}
            onClose={onClose}
            onValueSelected={onValueSelected}>
            <FilterField
                emptyValue={_.isNil(selectedItem?.value)}
                focused={open}
                placeholder={placeholder}
                selection={
                    <SelectionView
                        empty={_.isNil(selectedItem?.value)}
                        label={placeholder}
                        selectedValues={
                            _.isNil(selectedItem)
                                ? undefined
                                : [selectedItem.title ?? selectedItem.value]}
                        singleSelection={singleSelection}
                        totalCount={items.length}
                        type="include"/>}/>
        </Container>);
}

type ContainerProps<T> = {
    actionsRef: React.MutableRefObject<DropdownActions | undefined>;
    children: ReactNode;
    items: ValueFilterItem<T>[];
    onClose: () => void;
    onValueSelected: (item: ValueFilterItem) => () => void;
};

function SingleValueContainer<T>({ children, items, onValueSelected }: ContainerProps<T>) {
    return (
        <Box
            sx={{ cursor: "pointer" }}
            onClick={onValueSelected(items[0])}>
            {children}
        </Box>);
}

function MultipleValuesContainer<T>({ actionsRef, children, items, onClose, onValueSelected }: ContainerProps<T>) {
    const { filter, open } = useFilterConnectorContext();
    const theme = useTheme();

    return (
        <Dropdown
            actionsRef={actionsRef}
            open={open}
            popoverElement={
                <Stack>
                    {_.map(
                        items,
                        item =>
                            <Stack
                                direction="row"
                                spacing={1}
                                sx={{
                                    "&:hover": {
                                        background: theme.palette.action.hover
                                    },
                                    alignItems: "center",
                                    cursor: "pointer",
                                    margin: 0,
                                    padding: theme.spacing(1.5, 3, 1.5, 1)
                                }}
                                onClick={onValueSelected(item)}>
                                <CheckIcon
                                    sx={{
                                        visibility:
                                            item.value === filter
                                                ? "visible"
                                                : "hidden"
                                    }}/>
                                <Typography
                                    sx={{
                                        fontWeight:
                                            item.value === filter
                                                ? 700
                                                : 400
                                    }}>
                                    {item.title}
                                </Typography>
                            </Stack>)}
                </Stack>}
            popoverElementContainerSx={{ padding: 0 }}
            onClose={onClose}>
            {children}
        </Dropdown>);
}