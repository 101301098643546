﻿export class ActionHelper {
    public static getRawValue =
        (action: string) => {
            const actionParts = action.split("\n");

            if (actionParts.length === 2) {
                return actionParts[1];
            }

            return action;
        };
}