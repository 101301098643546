import { InlineItems, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, Entity, entityModelStore, InlineEntities, InlineVulnerability, InlineWorkloadAnalysisPackages } from "../../../../../../../../../../../../../common";
import { Vulnerabilities } from "../../../../../../../../../../WorkloadAnalysis";
import { RiskDefinition, RiskDefinitionContextItemImpact, RiskDefinitionSection } from "../../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../../useCommonSectionsAndDescriptionDefinition";
import { useGetVirtualMachineOperatingSystemUnpatchedRiskContext, useGetVirtualMachinesWorkloadAnalysisRiskContext, useGetVirtualMachineWorkloadAnalysisRiskContext } from "../../contexts";
import { useGetVirtualMachineOperatingSystemResolutionSteps } from "./useGetVirtualMachineOperatingSystemResolutionSteps";
import { useGetWorkloadAnalysisRiskResolutionPatches } from "./useWorkloadAnalysisRiskResolutionPatches";

export function useVirtualMachineOperatingSystemUnpatchedRiskDefinition(riskModel: Contract.RiskModel): RiskDefinition {
    const virtualMachineOperatingSystemUnpatchedRisk = riskModel.risk as Contract.VirtualMachineOperatingSystemUnpatchedRisk;
    const virtualMachineModels = _.as<Contract.IVirtualMachineModel[]>(entityModelStore.useGet(virtualMachineOperatingSystemUnpatchedRisk.virtualMachineIds));
    const operatingSystemReleaseNotesUrl =
        _(virtualMachineModels).
            map(virtualMachineModel => virtualMachineModel.operatingSystemReleaseNotesUrl).
            filter().
            first();
    const getWorkloadAnalysisRiskResolutionPatches = useGetWorkloadAnalysisRiskResolutionPatches();
    const getVirtualMachineOperatingSystemResolutionSteps =
        useGetVirtualMachineOperatingSystemResolutionSteps(
            virtualMachineOperatingSystemUnpatchedRisk,
            operatingSystemReleaseNotesUrl,
            () => getWorkloadAnalysisRiskResolutionPatches(virtualMachineOperatingSystemUnpatchedRisk));

    const { operatingSystemDisplayName, operatingSystemType } = virtualMachineOperatingSystemUnpatchedRisk;

    const getVirtualMachineImageWorkloadAnalysisRiskContext = useGetVirtualMachinesWorkloadAnalysisRiskContext(virtualMachineOperatingSystemUnpatchedRisk);
    const getVirtualMachineOperatingSystemUnpatchedRiskContext = useGetVirtualMachineOperatingSystemUnpatchedRiskContext();
    const getVirtualMachineWorkloadAnalysisRiskContext = useGetVirtualMachineWorkloadAnalysisRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.workloadAnalysis.hooks.useWorkloadAnalysisDefinition.hooks.useVirtualMachineOperatingSystemUnpatchedRiskDefinition",
            () => ({
                description: {
                    many: {
                        [Contract.TypeNames.OperatingSystemType]: {
                            [Contract.OperatingSystemType.Linux]: "{{virtualMachines}} in {{riskedEntity}} are running operating system {{operatingSystem}} and contain {{packages}} with critical or high vulnerabilities with known fixes",
                            [Contract.OperatingSystemType.Windows]: "{{virtualMachines}} in {{riskedEntity}} are running operating system {{operatingSystem}} and contain {{vulnerabilities}} related to the operating system with critical or high severity with known fixes"
                        }
                    },
                    single: {
                        [Contract.TypeNames.OperatingSystemType]: {
                            [Contract.OperatingSystemType.Linux]: "{{riskedEntity}} is running operating system {{operatingSystem}} and contains {{packages}} with critical or high vulnerabilities with known fixes",
                            [Contract.OperatingSystemType.Windows]: "{{riskedEntity}} is running operating system {{operatingSystem}} and contains {{vulnerabilities}} related to the operating system with critical or high severity with known fixes"
                        }
                    }
                },
                sections: {
                    vulnerabilities: "Vulnerabilities"
                },
                vulnerabilities: [
                    " 1 vulnerability",
                    " {{count | NumberFormatter.humanize}} vulnerabilities"
                ]
            }));


    return useCommonSectionsAndDescriptionDefinition(
        (virtualMachineOperatingSystemUnpatchedRisk.singleVirtualMachine
            ? localization.description.single[Contract.TypeNames.OperatingSystemType][operatingSystemType]
            : localization.description.many[Contract.TypeNames.OperatingSystemType][operatingSystemType])({
            operatingSystem: operatingSystemDisplayName,
            packages:
                <InlineWorkloadAnalysisPackages
                    packageNames={virtualMachineOperatingSystemUnpatchedRisk.vulnerabilityPackageNames}
                    packageScope={virtualMachineOperatingSystemUnpatchedRisk.packageScope}/>,
            riskedEntity:
                <Entity
                    entityIdOrModel={virtualMachineOperatingSystemUnpatchedRisk.entityId}
                    variant="text"/>,
            virtualMachines:
                <InlineEntities
                    entityIdsOrModels={virtualMachineOperatingSystemUnpatchedRisk.virtualMachineIds}
                    entityTypeName={Contract.TypeNames.IVirtualMachine}
                    variant="itemCountAndType"/>,
            vulnerabilities:
                <InlineItems
                    items={
                        _(virtualMachineOperatingSystemUnpatchedRisk.criticalVulnerabilities).
                            concat(virtualMachineOperatingSystemUnpatchedRisk.highVulnerabilities).
                            map(vulnerability => vulnerability.rawId).
                            uniq().
                            map(
                                vulnerabilityRawId => (
                                    <InlineVulnerability
                                        key={vulnerabilityRawId}
                                        rawId={vulnerabilityRawId}/>)).
                            value()}
                    namePluralizer={localization.vulnerabilities}
                    variant="itemCountAndType"/>
        }),
        getVirtualMachineOperatingSystemResolutionSteps,
        riskModel,
        () => {
            const virtualMachineImageWorkloadAnalysisRiskContext = getVirtualMachineImageWorkloadAnalysisRiskContext();
            const virtualMachineWorkloadAnalysisRiskContext = getVirtualMachineWorkloadAnalysisRiskContext(virtualMachineOperatingSystemUnpatchedRisk);
            const virtualMachineOperatingSystemUnpatchedRiskContext =
                operatingSystemType === Contract.OperatingSystemType.Linux
                    ? getVirtualMachineOperatingSystemUnpatchedRiskContext(virtualMachineOperatingSystemUnpatchedRisk)
                    : null;
            return [
                virtualMachineWorkloadAnalysisRiskContext.generalInformation,
                virtualMachineWorkloadAnalysisRiskContext.virtualMachines,
                virtualMachineWorkloadAnalysisRiskContext.operatingSystem,
                virtualMachineOperatingSystemUnpatchedRiskContext?.unpatchedPackages,
                virtualMachineOperatingSystemUnpatchedRiskContext?.criticalExploitableVulnerabilityPackageNames,
                virtualMachineOperatingSystemUnpatchedRiskContext?.criticalNonExploitableVulnerabilityPackageNames,
                virtualMachineOperatingSystemUnpatchedRiskContext?.highExploitableVulnerabilityPackageNames,
                virtualMachineOperatingSystemUnpatchedRiskContext?.highNonExploitableVulnerabilityPackageNames,
                virtualMachineWorkloadAnalysisRiskContext.getVulnerabilities(
                    virtualMachineOperatingSystemUnpatchedRisk.packageScope === Contract.WorkloadResourceOperatingSystemRiskPackageScope.OperatingSystem,
                    true),
                virtualMachineWorkloadAnalysisRiskContext.getSeverityVulnerabilities(Contract.Severity.Critical),
                virtualMachineWorkloadAnalysisRiskContext.getSeverityVulnerabilities(Contract.Severity.High),
                virtualMachineImageWorkloadAnalysisRiskContext.sensitive?.withImpact(RiskDefinitionContextItemImpact.SeverityIncreased),
                virtualMachineImageWorkloadAnalysisRiskContext.inboundExternalAccessScope?.withImpact(RiskDefinitionContextItemImpact.SeverityIncreased),
                virtualMachineImageWorkloadAnalysisRiskContext.identityPermissionActionSeverity?.withImpact(RiskDefinitionContextItemImpact.SeverityIncreased),
                virtualMachineImageWorkloadAnalysisRiskContext.stopped?.withImpact(RiskDefinitionContextItemImpact.SeverityDecreased),
                virtualMachineWorkloadAnalysisRiskContext.getOpenRiskedEntityRisksContextItem(virtualMachineOperatingSystemUnpatchedRisk.id)
            ];
        },
        {
            sections:
                [
                    new RiskDefinitionSection(
                        <Vulnerabilities
                            entityIds={virtualMachineOperatingSystemUnpatchedRisk.virtualMachineIds}
                            severityFilterValues={[
                                Contract.Severity.Critical,
                                Contract.Severity.High]}
                            variant="risk"/>,
                        localization.sections.vulnerabilities(),
                        {
                            expandable: true
                        })
                ]
        });
}