import { map, Optional } from "@infrastructure";
import _ from "lodash";
import { CodeConsoleUrlBuilder } from ".";
import { Contract, DnsEndpointHelper } from "../../../common";

export class CodeConsoleUrlHelper {
    public static getCodeOrganizationUrl =
        (codeTenantType: Contract.CodeTenantType, organizationName: Optional<string>, serverEndpoint: Optional<string>): Optional<string> =>
            _.isNil(organizationName)
                ? undefined
                : map(
                    codeTenantType,
                    {
                        [Contract.CodeTenantType.AzureDevOps]: () => CodeConsoleUrlBuilder.GetAzureDevOpsOrganizationUrl(organizationName),
                        [Contract.CodeTenantType.Bitbucket]: () => CodeConsoleUrlBuilder.GetBitbucketOrganizationUrl(organizationName),
                        [Contract.CodeTenantType.GitHub]: () => CodeConsoleUrlBuilder.GetGitHubOrganizationUrl(organizationName, DnsEndpointHelper.getHttpsEndpointString(serverEndpoint!)),
                        [Contract.CodeTenantType.GitLab]: () => CodeConsoleUrlBuilder.GetGitLabOrganizationUrl(organizationName, DnsEndpointHelper.getHttpsEndpointString(serverEndpoint!))
                    },
                    () => undefined);

    public static getCodeBranchUrl =
        (branchName: string, tenantType: Contract.CodeTenantType, tenantUrl: string): Optional<string> =>
            map(
                tenantType,
                {
                    [Contract.CodeTenantType.AzureDevOps]: () => CodeConsoleUrlBuilder.GetAzureDevOpsBranchUrl(branchName, tenantUrl),
                    [Contract.CodeTenantType.Bitbucket]: () => CodeConsoleUrlBuilder.GetBitbucketBranchUrl(branchName, tenantUrl),
                    [Contract.CodeTenantType.GitHub]: () => CodeConsoleUrlBuilder.GetGitHubBranchUrl(branchName, tenantUrl),
                    [Contract.CodeTenantType.GitLab]: () => CodeConsoleUrlBuilder.GetGitLabBranchUrl(branchName, tenantUrl)
                },
                () => undefined);

    public static getCodeCommitUrl =
        (commitHash: string, tenantType: Contract.CodeTenantType, tenantUrl: string): Optional<string> =>
            map(
                tenantType,
                {
                    [Contract.CodeTenantType.AzureDevOps]: () => CodeConsoleUrlBuilder.GetAzureDevOpsCommitUrl(commitHash, tenantUrl),
                    [Contract.CodeTenantType.Bitbucket]: () => CodeConsoleUrlBuilder.GetBitbucketCommitUrl(commitHash, tenantUrl),
                    [Contract.CodeTenantType.GitHub]: () => CodeConsoleUrlBuilder.GetGitHubCommitUrl(commitHash, tenantUrl),
                    [Contract.CodeTenantType.GitLab]: () => CodeConsoleUrlBuilder.GetGitLabCommitUrl(commitHash, tenantUrl)
                },
                () => undefined);

    public static getCodeFileLineUrl =
        (codeType: Contract.CodeType, commitHash: string, fileRelativePath: Optional<string>, line: number, tenantType: Contract.CodeTenantType, tenantUrl: string): Optional<string> =>
            _.isNil(fileRelativePath) ||
            codeType == Contract.CodeType.TerraformPlan
                ? undefined
                : map(
                    tenantType,
                    {
                        [Contract.CodeTenantType.AzureDevOps]: () => CodeConsoleUrlBuilder.GetAzureDevOpsFileLineUrl(commitHash, fileRelativePath, String(line), tenantUrl),
                        [Contract.CodeTenantType.Bitbucket]: () => CodeConsoleUrlBuilder.GetBitbucketFileLineUrl(commitHash, fileRelativePath, String(line), tenantUrl),
                        [Contract.CodeTenantType.GitHub]: () => CodeConsoleUrlBuilder.GetGitHubFileLineUrl(commitHash, fileRelativePath, String(line), tenantUrl),
                        [Contract.CodeTenantType.GitLab]: () => CodeConsoleUrlBuilder.GetGitLabFileLineUrl(commitHash, fileRelativePath, String(line), tenantUrl)
                    },
                    () => undefined);

    public static getCodeFileUrl =
        (codeType: Contract.CodeType, commitHash: string, fileRelativePath: Optional<string>, tenantType: Contract.CodeTenantType, tenantUrl: string): Optional<string> =>
            _.isNil(fileRelativePath) ||
            codeType == Contract.CodeType.TerraformPlan
                ? undefined
                : map(
                    tenantType,
                    {
                        [Contract.CodeTenantType.AzureDevOps]: () => CodeConsoleUrlBuilder.GetAzureDevOpsFileUrl(commitHash, fileRelativePath, tenantUrl),
                        [Contract.CodeTenantType.Bitbucket]: () => CodeConsoleUrlBuilder.GetBitbucketFileUrl(commitHash, fileRelativePath, tenantUrl),
                        [Contract.CodeTenantType.GitHub]: () => CodeConsoleUrlBuilder.GetGitHubFileUrl(commitHash, fileRelativePath, tenantUrl),
                        [Contract.CodeTenantType.GitLab]: () => CodeConsoleUrlBuilder.GetGitLabFileUrl(commitHash, fileRelativePath, tenantUrl)
                    },
                    () => undefined);
}