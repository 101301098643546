import { AnalyticsEventActionType, CheckboxField, Dialog, FormLayout, Message, TimeHelper, useAsyncEffect, useChangeEffect, useExecuteOperation, useLocalization, useTrackAnalytics } from "@infrastructure";
import { Button, CircularProgress, FormControl, FormHelperText, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { Fragment, useState } from "react";
import { AccessController, Contract, DocumentsViewer, ElasticsearchItemPageHelper, EntityController, NonexcessivePermitterDateSelectorField, PagedEntityMultiSelect, PagedEntitySelector, RadioGroup, TenantMultiSelect, useEntityTypeNameTranslator, useTheme } from "../../../common";

export type GenerateAzureIdentityNonexcessiveRoleDefinitionSelectionOptions = {
    containerResourceIds: string[];
    containerResourceTypeName: string;
    excessivePermissionEvaluationStartDate?: string;
};

type GenerateAzureIdentityNonexcessiveRoleDefinitionDialogProps = {
    identityModel: Contract.IPrincipalModel;
    onClose: () => void;
    selectionOptions?: GenerateAzureIdentityNonexcessiveRoleDefinitionSelectionOptions;
};

export function GenerateAzureIdentityNonexcessiveRoleDefinitionDialog({ identityModel, onClose, selectionOptions }: GenerateAzureIdentityNonexcessiveRoleDefinitionDialogProps) {
    return (
        <Dialog
            size="medium"
            variant="editor"
            onClose={onClose}>
            <Core
                identityModel={identityModel}
                selectionOptions={selectionOptions}
                onClose={onClose}/>
        </Dialog>);
}

function Core({ identityModel, onClose, selectionOptions }: GenerateAzureIdentityNonexcessiveRoleDefinitionDialogProps) {
    const [{ destinationTenantIds }] =
        useExecuteOperation(
            [GenerateAzureIdentityNonexcessiveRoleDefinitionDialog, identityModel.id],
            async () => await AccessController.getIdentityDestinationTenantIds(new Contract.AccessControllerGetAzureIdentityDestinationTenantIdsRequest(identityModel.id)));

    const [containerResourceIds, setContainerResourceIds] = useState<string[]>(selectionOptions?.containerResourceIds ?? []);
    const [containerResourceTypeName, setContainerResourceTypeName] = useState(selectionOptions?.containerResourceTypeName ?? Contract.TypeNames.AzureTenantEntity);
    const [entityExcessivePermissionCustomEvaluationData, setEntityExcessivePermissionCustomEvaluationData] = useState<Contract.EntityControllerGetEntityExcessivePermissionCustomEvaluationDataResponse | undefined>(undefined);
    const [excessivePermissionEvaluationStartDate, setExcessivePermissionEvaluationStartDate] = useState<string>();
    const [includeGroupRoleAssignments, setIncludeGroupRoleAssignments] = useState(false);
    const [generateError, setGenerateError] = useState<Contract.EntityControllerGenerateAzureIdentityNonexcessiveRoleDefinitionError | undefined>(undefined);
    const [generateRoleDefinitionExecuting, setGenerateRoleDefinitionExecuting] = useState(false);
    const [rawRoleDefinitions, setRawRoleDefinitions] = useState<string[] | undefined>();

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const translatedIdentityTypeName =
        entityTypeNameTranslator(
            identityModel.entity.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });
    const minTime = _.last(entityExcessivePermissionCustomEvaluationData?.dateInfos);

    const localization =
        useLocalization(
            "tenants.azure.generateAzureIdentityNonexcessiveRoleDefinitionDialog",
            () => ({
                actions: {
                    close: "Done",
                    generate: {
                        [Contract.TypeNames.EntityControllerGenerateAzureIdentityNonexcessiveRoleDefinitionError]: {
                            [Contract.EntityControllerGenerateAzureIdentityNonexcessiveRoleDefinitionError.AllExcessive]: "Unable to generate a custom role for this {{translatedIdentityTypeName}}, because there is no activity in the chosen time frame",
                            [Contract.EntityControllerGenerateAzureIdentityNonexcessiveRoleDefinitionError.AllNonexcessive]: "Permissions are not excessive based on the chosen scope and time frame, so the existing roles should be kept",
                            [Contract.EntityControllerGenerateAzureIdentityNonexcessiveRoleDefinitionError.CommonErrorGeneration]: "Unable to generate a custom role for this {{translatedIdentityTypeName}}. For more information contact support via the 'Contact us' link under the '?' menu (top right)",
                            [Contract.EntityControllerGenerateAzureIdentityNonexcessiveRoleDefinitionError.NoPermissions]: "Unable to generate a custom role for this {{translatedIdentityTypeName}}, because it has no permissions in the chosen scope"
                        },
                        title: "Generate"
                    }
                },
                fields: {
                    containerResourceIds: {
                        footer: {
                            resourceGroup: "The custom role will include permissions inherited from higher level scopes"
                        },
                        placeholder: {
                            [Contract.TypeNames.AzureManagementManagementGroup]: "Select one management group",
                            [Contract.TypeNames.AzureResourcesResourceGroup]: "Select one or more resource groups",
                            [Contract.TypeNames.AzureTenantEntity]: "Select one or more subscriptions"
                        }
                    },
                    containerResourceType: {
                        subtitle: "Choose a scope level to determine which resources are included in the analysis, and at what level to assign the role to the {{translatedIdentityTypeName}}.",
                        title: "Scope Level"
                    },
                    excessivePermissionEvaluationStartDate: {
                        disabled: "Unable to generate a custom role - no activity in the selected scopes",
                        footer: {
                            [Contract.EntityControllerGetEntityExcessivePermissionCustomEvaluationDataResponseDateType.CreationDate]: "Activity is available from {{minDate | TimeFormatter.monthDayAndYear}} ({{translatedIdentityTypeName}} creation) until {{eventsTime | TimeFormatter.monthDayAndYear}} ({{dateSelectionRange | TimeSpanFormatter.humanizeDays}} in total)",
                            [Contract.EntityControllerGetEntityExcessivePermissionCustomEvaluationDataResponseDateType.EventStartDate]: "Activity is available from {{minDate | TimeFormatter.monthDayAndYear}} (max activity time) until {{eventsTime | TimeFormatter.monthDayAndYear}} ({{dateSelectionRange | TimeSpanFormatter.humanizeDays}} in total)"
                        }
                    },
                    includeGroupRoleAssignments: "Include permissions granted via group membership"
                },
                footer: "The custom role will be based on activity from {{excessivePermissionEvaluationStartDate | TimeFormatter.monthDayAndYear}} to today ({{excessivePermissionEvaluationTimeRange | TimeSpanFormatter.humanizeDays}} in total)",
                nonexcessivePermissionsRawRoleDefinition:{
                    many: "LeastPrivilegeRoleDefinition({{rawRoleDefinitionIndex}} of {{rawRoleDefinitionCount}})",
                    single: "LeastPrivilegeRoleDefinition"
                },
                subtitle: "Generate a custom role which you can then use in Azure to grant the minimum privileges needed.",
                title: "Least Privilege Custom Role"
            }));

    const [valid, setValid] = useState(false);
    useChangeEffect(
        () => {
            setValid(
                entityExcessivePermissionCustomEvaluationData?.nonexcessivePermitterCustomGenerationEnabled === true &&
                !_.isNil(excessivePermissionEvaluationStartDate));
        },
        [containerResourceIds, entityExcessivePermissionCustomEvaluationData, excessivePermissionEvaluationStartDate]);

    const trackAnalytics = useTrackAnalytics();
    async function generateNonexcessiveRoleDefinition() {
        setGenerateRoleDefinitionExecuting(true);
        setGenerateError(undefined);
        setRawRoleDefinitions(undefined);

        try {
            const response =
                await EntityController.generateAzureIdentityNonexcessiveRoleDefinition(
                    new Contract.EntityControllerGenerateAzureIdentityNonexcessiveRoleDefinitionRequest(
                        excessivePermissionEvaluationStartDate!,
                        identityModel.id,
                        includeGroupRoleAssignments,
                        containerResourceIds));

            trackAnalytics(
                AnalyticsEventActionType.AccessGenerateLeastPrivilege,
                { "Type": localization.title() });
            
            if (!_.isNil(response.error)) {
                setGenerateError(response.error);
            } else {
                setRawRoleDefinitions(response.rawRoleDefinitions);
            }
        } catch {
            setGenerateError(Contract.EntityControllerGenerateAzureIdentityNonexcessiveRoleDefinitionError.CommonErrorGeneration);
        }

        setGenerateRoleDefinitionExecuting(false);
    }

    async function getEntityExcessivePermissionCustomEvaluationData(
        containerResourceIds: string[],
        principalModel: Contract.IPrincipalModel) {
        setExcessivePermissionEvaluationStartDate(undefined);
        setGenerateError(undefined);

        try {
            setEntityExcessivePermissionCustomEvaluationData(
                _.isEmpty(containerResourceIds)
                    ? undefined
                    : await EntityController.getEntityExcessivePermissionCustomEvaluationData(
                        new Contract.EntityControllerGetAzureEntityExcessivePermissionCustomEvaluationDataRequest(
                            principalModel.entity.id,
                            containerResourceIds)));
        } catch (error) {
            setEntityExcessivePermissionCustomEvaluationData(undefined);
        }
    }

    useAsyncEffect(
        async () => {
            if (!_.isNil(selectionOptions)) {
                await getEntityExcessivePermissionCustomEvaluationData(
                    selectionOptions.containerResourceIds,
                    identityModel);
                if (!_.isNil(selectionOptions.excessivePermissionEvaluationStartDate)) {
                    setExcessivePermissionEvaluationStartDate(selectionOptions.excessivePermissionEvaluationStartDate);
                }
            }
        },
        []);

    const theme = useTheme();
    return (
        <FormLayout
            footerOptions={{
                contentElement:
                    <Stack
                        alignItems="center"
                        direction="row-reverse"
                        justifyContent="space-between"
                        spacing={1}>
                        {_.isNil(rawRoleDefinitions)
                            ? <Fragment>
                                <Stack
                                    alignItems="center"
                                    direction="row-reverse"
                                    justifyContent="flex-start"
                                    spacing={1}>
                                    <Button
                                        disabled={!valid || generateRoleDefinitionExecuting}
                                        onClick={() => generateNonexcessiveRoleDefinition()}>
                                        {localization.actions.generate.title()}
                                    </Button>
                                    {generateRoleDefinitionExecuting &&
                                        <CircularProgress
                                            size={theme.spacing(2)}
                                            variant="indeterminate"/>}
                                </Stack>
                                {valid && _.isNil(generateError) &&
                                    <Message
                                        level="info"
                                        title={localization.footer({
                                            excessivePermissionEvaluationStartDate,
                                            excessivePermissionEvaluationTimeRange: TimeHelper.getInterval(excessivePermissionEvaluationStartDate!)
                                        })}/>}
                                {!_.isNil(generateError) &&
                                    <Message
                                        level={
                                            generateError === Contract.EntityControllerGenerateAzureIdentityNonexcessiveRoleDefinitionError.AllNonexcessive
                                                ? "info"
                                                : "error"}
                                        title={localization.actions.generate[Contract.TypeNames.EntityControllerGenerateAzureIdentityNonexcessiveRoleDefinitionError][generateError]({ translatedIdentityTypeName })}/>}
                            </Fragment>
                            : <Button onClick={onClose}>
                                {localization.actions.close()}
                            </Button>}
                    </Stack>
            }}
            titleOptions={{
                subtitle: localization.subtitle(),
                text: localization.title()
            }}>
            {!_.isNil(rawRoleDefinitions)
                ? <DocumentsViewer
                    documents={
                        _.map(
                            rawRoleDefinitions,
                            (rawRoleDefinition, rawRoleDefinitionIndex) => ({
                                document: rawRoleDefinition,
                                name:
                                    rawRoleDefinitions.length === 1
                                        ? localization.nonexcessivePermissionsRawRoleDefinition.single()
                                        : localization.nonexcessivePermissionsRawRoleDefinition.many({
                                            rawRoleDefinitionCount: rawRoleDefinitions.length,
                                            rawRoleDefinitionIndex: rawRoleDefinitionIndex + 1
                                        })
                            }))
                    }/>
                : <Stack spacing={3}>
                    <Stack spacing={1.5}>
                        <Stack spacing={1}>
                            <Typography variant="h4">
                                {localization.fields.containerResourceType.title()}
                            </Typography>
                            <Typography sx={{ fontSize: "13px" }}>
                                {localization.fields.containerResourceType.subtitle({ translatedIdentityTypeName })}
                            </Typography>
                        </Stack>
                        <RadioGroup
                            disabled={generateRoleDefinitionExecuting}
                            items={
                                [
                                    {
                                        disabled: !_.isNil(selectionOptions) && selectionOptions.containerResourceTypeName !== Contract.TypeNames.AzureManagementManagementGroup,
                                        label:
                                            entityTypeNameTranslator(
                                                Contract.TypeNames.AzureManagementManagementGroup,
                                                { includeServiceName: false }),
                                        value: Contract.TypeNames.AzureManagementManagementGroup
                                    },
                                    {
                                        disabled: !_.isNil(selectionOptions) && selectionOptions.containerResourceTypeName !== Contract.TypeNames.AzureTenantEntity,
                                        label:
                                            entityTypeNameTranslator(
                                                Contract.TypeNames.AzureTenantEntity,
                                                { count: 0 }),
                                        value: Contract.TypeNames.AzureTenantEntity
                                    },
                                    {
                                        disabled: !_.isNil(selectionOptions) && selectionOptions.containerResourceTypeName !== Contract.TypeNames.AzureResourcesResourceGroup,
                                        label:
                                            entityTypeNameTranslator(
                                                Contract.TypeNames.AzureResourcesResourceGroup,
                                                { count: 0 }),
                                        value: Contract.TypeNames.AzureResourcesResourceGroup
                                    }
                                ]
                            }
                            selectedValue={containerResourceTypeName}
                            onChange={
                                event => {
                                    setExcessivePermissionEvaluationStartDate(undefined);
                                    setEntityExcessivePermissionCustomEvaluationData(undefined);
                                    setContainerResourceIds([]);
                                    setContainerResourceTypeName(event);
                                }}/>
                    </Stack>
                    <FormControl
                        fullWidth={true}
                        variant="standard">
                        <Stack spacing={1}>
                            {containerResourceTypeName === Contract.TypeNames.AzureManagementManagementGroup &&
                                <PagedEntitySelector
                                    disabled={generateRoleDefinitionExecuting}
                                    fullWidth={true}
                                    getEntityModelPage={
                                        ElasticsearchItemPageHelper.makePagedEntityMultiSelect(
                                            async (itemNextPageSearchCursor, searchText) => {
                                                const { entityModelPage } =
                                                    await EntityController.searchEntityModels(
                                                        new Contract.EntityControllerSearchEntityModelsTypeRequest(
                                                            false,
                                                            15,
                                                            itemNextPageSearchCursor,
                                                            undefined,
                                                            searchText,
                                                            false,
                                                            containerResourceTypeName));
                                                return entityModelPage;
                                            })}
                                    placeholder={localization.fields.containerResourceIds.placeholder.translate(containerResourceTypeName)}
                                    selectedEntityId={containerResourceIds[0]}
                                    onSelectedEntityIdChanged={
                                        async selectedManagementGroupId => {
                                            setContainerResourceIds([selectedManagementGroupId]);
                                            await getEntityExcessivePermissionCustomEvaluationData(
                                                [selectedManagementGroupId],
                                                identityModel);
                                        }}/>}
                            {containerResourceTypeName === Contract.TypeNames.AzureResourcesResourceGroup &&
                                <PagedEntityMultiSelect
                                    disabled={generateRoleDefinitionExecuting}
                                    fullWidth={true}
                                    getEntityModelPage={
                                        ElasticsearchItemPageHelper.makePagedEntityMultiSelect(
                                            async (itemNextPageSearchCursor, searchText) => {
                                                const { entityModelPage } =
                                                    await EntityController.searchEntityModels(
                                                        new Contract.EntityControllerSearchEntityModelsPrincipalPermissionActionResourceRequest(
                                                            false,
                                                            15,
                                                            itemNextPageSearchCursor,
                                                            undefined,
                                                            searchText,
                                                            identityModel.id,
                                                            Contract.TypeNames.AzureResourcesResourceGroup));
                                                return entityModelPage;
                                            })}
                                    placeholder={localization.fields.containerResourceIds.placeholder.translate(containerResourceTypeName)}
                                    selectedEntityIds={containerResourceIds}
                                    onSelectedEntityIdsChanged={
                                        async selectedResourceGroupIds => {
                                            setContainerResourceIds(selectedResourceGroupIds);
                                            await getEntityExcessivePermissionCustomEvaluationData(
                                                selectedResourceGroupIds,
                                                identityModel);
                                        }}/>}
                            {containerResourceTypeName === Contract.TypeNames.AzureTenantEntity &&
                                <TenantMultiSelect
                                    disabled={generateRoleDefinitionExecuting}
                                    displayAllAsEmpty={false}
                                    fullWidth={true}
                                    placeholder={localization.fields.containerResourceIds.placeholder.translate(containerResourceTypeName)}
                                    selectedTenantIds={containerResourceIds}
                                    tenantIds={destinationTenantIds}
                                    variant="itemSelector"
                                    onSelectedTenantIdsChanged={
                                        async selectedTenantIds => {
                                            setContainerResourceIds(selectedTenantIds);
                                            await getEntityExcessivePermissionCustomEvaluationData(
                                                selectedTenantIds,
                                                identityModel);
                                        }}/>}
                            {containerResourceTypeName === Contract.TypeNames.AzureResourcesResourceGroup &&
                                <Message
                                    level="info"
                                    title={
                                        <Typography sx={{ fontSize: "12px" }}>
                                            {localization.fields.containerResourceIds.footer.resourceGroup()}
                                        </Typography>}/>}
                        </Stack>
                    </FormControl>
                    <FormControl
                        fullWidth={true}
                        variant="standard">
                        <Stack spacing={1}>
                            <NonexcessivePermitterDateSelectorField
                                disabled={
                                    _.isEmpty(containerResourceIds) ||
                                    entityExcessivePermissionCustomEvaluationData?.nonexcessivePermitterCustomGenerationEnabled === false ||
                                    generateRoleDefinitionExecuting}
                                entityExcessivePermissionCustomEvaluationData={entityExcessivePermissionCustomEvaluationData}
                                selectedDate={excessivePermissionEvaluationStartDate}
                                onSelectedDateChanged={selectedDate => {
                                    setExcessivePermissionEvaluationStartDate(selectedDate);
                                    setGenerateError(undefined);
                                }}/>
                            {entityExcessivePermissionCustomEvaluationData?.nonexcessivePermitterCustomGenerationEnabled &&
                                <Typography sx={{ fontSize: "12px" }}>
                                    {localization.fields.excessivePermissionEvaluationStartDate.footer.translate(
                                        minTime!.type,
                                        {
                                            dateSelectionRange: TimeHelper.getDuration(
                                                minTime!.date,
                                                entityExcessivePermissionCustomEvaluationData.eventsTime!),
                                            eventsTime: entityExcessivePermissionCustomEvaluationData.eventsTime,
                                            minDate: minTime!.date,
                                            translatedIdentityTypeName
                                        })}
                                </Typography>}
                            {entityExcessivePermissionCustomEvaluationData?.nonexcessivePermitterCustomGenerationEnabled === false &&
                                <FormHelperText error={true}>
                                    {localization.fields.excessivePermissionEvaluationStartDate.disabled()}
                                </FormHelperText>}
                        </Stack>
                    </FormControl>
                    <CheckboxField
                        checked={includeGroupRoleAssignments}
                        disabled={generateRoleDefinitionExecuting}
                        onChange={() => setIncludeGroupRoleAssignments(!includeGroupRoleAssignments)}>
                        <Typography
                            sx={{
                                color:
                                    generateRoleDefinitionExecuting
                                        ? theme.palette.text.disabled
                                        : theme.palette.text.primary,
                                fontSize: "12px"
                            }}>
                            {localization.fields.includeGroupRoleAssignments()}
                        </Typography>
                    </CheckboxField>
                </Stack>}
        </FormLayout>);
}