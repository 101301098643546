import { useMemo } from "react";
import { Contract } from "../../../../../../../../../../../../common";
import { useGetNetworkAccessResourceRiskContext } from "../../../useGetNetworkAccessResourceRiskContext";
import { useGetAzureResourceRiskContext } from "./useGetAzureResourceRiskContext";

export function useGetAzureBotServiceBotServiceRiskContext() {
    return useMemo(
        () => useAzureBotServiceBotServiceRiskContext,
        []);
}

function useAzureBotServiceBotServiceRiskContext(botServiceModel: Contract.AzureBotServiceBotServiceModel) {
    const networkAccessResourceRiskContext = useGetNetworkAccessResourceRiskContext()(botServiceModel);
    const resourceRiskContext = useGetAzureResourceRiskContext()(botServiceModel);

    return {
        ...resourceRiskContext,
        ...networkAccessResourceRiskContext
    };
}