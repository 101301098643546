import { DataTableColumn, EnumValuesFilter, useLocalization } from "@infrastructure";
import { Dictionary } from "lodash";
import React from "react";
import { Contract, ItemSelectionHelper, TimeRangeHelper } from "../../../../../../../../../../common";
import { useAzureAuthorizationRoleDefinitionTypeTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";
import { EntityTableDefinition } from "../../useDefinition";
import { useCommonEntityDefinition } from "../useCommonEntityDefinition";

export function useAzureAuthorizationRoleDefinitionDefinition(definitionData: DefinitionData) {
    const commonEntityDefinition = useCommonEntityDefinition(definitionData);

    const authorizationRoleDefinitionTypeTranslator = useAzureAuthorizationRoleDefinitionTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.azure.useAzureAuthorizationRoleDefinitionDefinition",
            () => ({
                columns: {
                    type: "Role Type"
                }
            }));

    return new EntityTableDefinition(
        [
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AzureAuthorizationRoleDefinitionModel) => ({
                            [localization.columns.type()]: authorizationRoleDefinitionTypeTranslator((item.entity as Contract.AzureAuthorizationRoleDefinition).type)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.AzureAuthorizationRoleDefinitionType}
                                enumTypeTranslator={authorizationRoleDefinitionTypeTranslator}
                                placeholder={localization.columns.type()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AzureAuthorizationRoleDefinitionType}
                itemProperty={(item: Contract.EntityModel) => authorizationRoleDefinitionTypeTranslator((item.entity as Contract.AzureAuthorizationRoleDefinition).type)}
                key={Contract.EntityModelProperty.AzureAuthorizationRoleDefinitionType}
                title={localization.columns.type()}/>,
            commonEntityDefinition.columns.attributesColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAzureAuthorizationRoleDefinitionRequest(
                new Contract.EntityControllerGetEntityModelPageAzureAuthorizationRoleDefinitionRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureAuthorizationRoleDefinitionType])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(undefined)));
}