import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, EmptyMessageText, InlineTexts, StringHelper, TextValuesFilter, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, entityModelStore, InfoCard, ItemTable, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";

type PrivateRecordSetInfoCardProps = {
    privateDnsZoneModel: Contract.AzureNetworkPrivateDnsZoneModel;
};

export function RecordSetsInfoCard({ privateDnsZoneModel: privateDnsZoneModel }: PrivateRecordSetInfoCardProps) {
    const entityTypeNameTranslator = useEntityTypeNameTranslator();

    const recordSets =
        _(entityModelStore.useGet(privateDnsZoneModel.recordSetIds)).
            map(recordSetModel => recordSetModel.entity as Contract.AzureNetworkPrivateDnsZoneRecordSetResource).
            value();

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.azure.useAzureNetworkPrivateDnsZoneDefinition.recordSetsInfoCard",
            () => ({
                columns: {
                    name: "Name",
                    type: {
                        title: "Type",
                        [Contract.TypeNames.AzureNetworkPrivateDnsZoneRecordSetResource]: {
                            [Contract.TypeNames.AzureNetworkPrivateDnsZoneRecordSetA]: "A",
                            [Contract.TypeNames.AzureNetworkPrivateDnsZoneRecordSetAaaa]: "AAAA",
                            [Contract.TypeNames.AzureNetworkPrivateDnsZoneRecordSetCname]: "CNAME",
                            [Contract.TypeNames.AzureNetworkPrivateDnsZoneRecordSetMx]: "MX",
                            [Contract.TypeNames.AzureNetworkPrivateDnsZoneRecordSetPtr]: "PTR",
                            [Contract.TypeNames.AzureNetworkPrivateDnsZoneRecordSetSoa]: "SOA",
                            [Contract.TypeNames.AzureNetworkPrivateDnsZoneRecordSetSrv]: "SRV",
                            [Contract.TypeNames.AzureNetworkPrivateDnsZoneRecordSetTxt]: "TXT"
                        }
                    },
                    values: "Value"
                },
                empty: "No Record Sets",
                title: "Record Sets"
            }));

    return (
        <InfoCard title={localization.title()}>
            <ItemTable
                columnIdToGetItemValueMap={{
                    [PrivateRecordSetInfoCardTableColumnId.Name]: item => item.name,
                    [PrivateRecordSetInfoCardTableColumnId.Type]: item => localization.columns.type[Contract.TypeNames.AzureNetworkPrivateDnsZoneRecordSetResource].translate(item.typeName),
                    [PrivateRecordSetInfoCardTableColumnId.Values]: {
                        getFilterValue: item => item.values,
                        getSortValue: item => StringHelper.getCombineSortValue(_.size(item.values), ...item.values)
                    }
                }}
                csvExportFilePrefixes={
                    [
                        entityTypeNameTranslator((privateDnsZoneModel.entity as Contract.AzureNetworkPrivateDnsZone).typeName, { includeServiceName: false }),
                        (privateDnsZoneModel.entity as Contract.AzureNetworkPrivateDnsZone).displayName,
                        localization.title()
                    ]}
                defaultSortColumnIdOrIds={PrivateRecordSetInfoCardTableColumnId.Name}
                emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty()) }}
                getCsvItem={
                    item => ({

                        "Name": item.name,
                        "Type": localization.columns.type[Contract.TypeNames.AzureNetworkPrivateDnsZoneRecordSetResource].translate(item.typeName),
                        "Value": _.join(item.values, "\n")

                    })}
                getItemId={item => item.id}
                items={recordSets}>
                {columnIdToItemValuesMap => [
                    <DataTableColumn
                        cellMaxWidth="medium"
                        filterOptions={{
                            itemOrItems: {
                                element:
                                    <TextValuesFilter
                                        placeholder={localization.columns.name()}
                                        values={columnIdToItemValuesMap[PrivateRecordSetInfoCardTableColumnId.Name]}/>
                            }
                        }}
                        id={PrivateRecordSetInfoCardTableColumnId.Name}
                        itemProperty={(recordSet: Contract.AzureNetworkPrivateDnsZoneRecordSetResource) => recordSet.name}
                        key={PrivateRecordSetInfoCardTableColumnId.Name}
                        title={localization.columns.name()}/>,
                    <DataTableColumn
                        filterOptions={{
                            itemOrItems: {
                                element:
                                    <ValuesFilter placeholder={localization.columns.type.title()}>
                                        {_.map(
                                            columnIdToItemValuesMap[PrivateRecordSetInfoCardTableColumnId.Type],
                                            type =>
                                                <ValuesFilterItem
                                                    key={type}
                                                    title={type}
                                                    value={type}/>)}
                                    </ValuesFilter>
                            }
                        }}
                        id={PrivateRecordSetInfoCardTableColumnId.Type}
                        itemProperty={(recordSet: Contract.AzureNetworkPrivateDnsZoneRecordSetResource) => localization.columns.type[Contract.TypeNames.AzureNetworkPrivateDnsZoneRecordSetResource].translate(recordSet.typeName)}
                        key={PrivateRecordSetInfoCardTableColumnId.Type}
                        title={localization.columns.type.title()}/>,
                    <DataTableColumn
                        cellMaxWidth="medium"
                        filterOptions={{
                            itemOrItems: {
                                element:
                                    <TextValuesFilter
                                        placeholder={localization.columns.values()}
                                        values={columnIdToItemValuesMap[PrivateRecordSetInfoCardTableColumnId.Values]}/>
                            }
                        }}
                        id={PrivateRecordSetInfoCardTableColumnId.Values}
                        key={PrivateRecordSetInfoCardTableColumnId.Values}
                        render={
                            ({ item }: DataTableColumnRenderProps<Contract.AzureNetworkPrivateDnsZoneRecordSetResource>) =>
                                <InlineTexts
                                    texts={item.values}
                                    variant="itemPlusItemCount"/>}
                        sortOptions={{ type: DataTableSortType.Numeric }}
                        title={localization.columns.values()}/>
                ]}
            </ItemTable>
        </InfoCard>);
}

enum PrivateRecordSetInfoCardTableColumnId {
    Name = "name",
    Type = "type",
    Values = "value"
}