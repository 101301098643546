import { Box } from "@mui/material";
import _, { Function0 } from "lodash";
import React, { useCallback, useMemo } from "react";
import { EmptyMessage, useLocalization } from "@infrastructure";
import { Contract, CustomerConsoleAppUrlHelper, EntityTypeMetadataModelHelper, SecretItem, useTenantTypeTranslator, useTheme, WidgetDefinition } from "../../../../../../..";
import { SummaryDashboardContext, useDashboardContext } from "../../../../../Dashboard";

export function useGetSecretsDefinition(): () => WidgetDefinition {
    const { summary } = useDashboardContext<SummaryDashboardContext>();

    const tenantTypeTranslator = useTenantTypeTranslator();
    const localization =
        useLocalization(
            "common.dashboard.widget.hooks.useDefinition.hooks.useGetSecretsDefinition",
            () => ({
                helpText: "Assess and remediate vulnerable resources that expose important secrets (such as passwords, credentials, and keys). Such resources are often overlooked, and can be leveraged by attackers. Click on a resource type to see all associated findings.",
                title: "Resources Exposing Secrets"
            }));

    const itemDatas =
        useMemo(
            () =>
                _(summary.secretExistsRiskPolicyConfigurationTypeNameToEntityTypeNameToDataMap).
                    flatMap(
                        (entityTypeNameToDataMap, riskPolicyConfigurationTypeName) =>
                            _.map(
                                entityTypeNameToDataMap,
                                ({ entityIds, secretCount }, entityTypeName) =>
                                    ({
                                        entityIds,
                                        entityTypeName,
                                        riskPolicyConfigurationTypeName,
                                        secretCount
                                    }))).
                    orderBy(
                        [
                            itemData => tenantTypeTranslator(EntityTypeMetadataModelHelper.get(itemData.entityTypeName).tenantType),
                            itemData => itemData.secretCount
                        ],
                        [
                            "asc",
                            "desc"
                        ]).
                    value(),
            [summary]);

    const empty =
        useMemo(
            () =>
                _(itemDatas).
                    map(itemData => itemData.secretCount).
                    sum() === 0,
            [itemDatas]);

    return useCallback<Function0<WidgetDefinition>>(
        () => ({
            element:
                <Secrets
                    empty={empty}
                    itemDatas={itemDatas}/>,
            options: {
                details: {
                    detailsUrl:
                        empty
                            ? undefined
                            : CustomerConsoleAppUrlHelper.getRisksUrl(
                                Contract.RisksView.Open,
                                {
                                    policyConfigurationTypeNameOrIds:
                                        _.map(
                                            itemDatas,
                                            itemData => itemData.riskPolicyConfigurationTypeName)
                                })
                },
                helpText: localization.helpText(),
                title: localization.title(),
                variant: "scrollable"
            }
        }),
        [itemDatas, localization]);
}

type SecretsProps = {
    empty: boolean;
    itemDatas: SecretsItemData[];
};

type SecretsItemData = {
    entityIds: string[];
    entityTypeName: string;
    riskPolicyConfigurationTypeName: string;
    secretCount: number;
};

function Secrets({ empty, itemDatas }: SecretsProps) {
    const localization =
        useLocalization(
            "common.dashboard.widget.hooks.useDefinition.hooks.useGetSecretsDefinition.secrets",
            () => ({
                empty: "No Exposed Secrets"
            }));

    const theme = useTheme();

    return empty
        ? <EmptyMessage
            message={localization.empty()}
            verticalCenter={true}/>
        : <Box>
            {_.map(
                itemDatas,
                itemData =>
                    <SecretItem
                        containerSx={{
                            height: "unset",
                            padding: theme.spacing(1.5, 1)
                        }}
                        entityIds={itemData.entityIds}
                        entityTypeName={itemData.entityTypeName}
                        key={`${itemData.riskPolicyConfigurationTypeName}_${itemData.entityTypeName}`}
                        riskPolicyConfigurationTypeName={itemData.riskPolicyConfigurationTypeName}
                        secretCount={itemData.secretCount}/>)}
        </Box>;
}