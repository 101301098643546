import { TimeFormatter, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, InfoItem, VerticalTopItemsInfoItem } from "../../../../../../../../../../common";

export function useGciCommonDirectoryPrincipalInfoItemElements(directoryPrincipalModel: Contract.GciDirectoryPrincipalModel) {
    const principal = directoryPrincipalModel.entity as Contract.GciDirectoryPrincipal;

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gci.useGciCommonDirectoryPrincipalInfoItemElements",
            () => ({
                info: {
                    creationTime: "Creation Time",
                    mail: "Mail",
                    mailAliases: "Email Aliases"
                }
            }));
    return [
        <InfoItem
            key="creationTime"
            location="all"
            title={localization.info.creationTime()}
            value={
                _.isNil(principal?.creationTime)
                    ? undefined
                    : TimeFormatter.profileFormatDateTime(principal.creationTime)}/>,
        <InfoItem
            key="mail"
            location="all"
            title={localization.info.mail()}
            value={principal.mail}/>,
        <VerticalTopItemsInfoItem
            items={principal.mailAliases}
            key="mailAliases"
            title={localization.info.mailAliases()}/>
    ];
}