﻿import { Optional, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, InlineEntities, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetAwsResourceRiskContext } from "./useGetAwsResourceRiskContext";

export function useGetAwsEc2LaunchTemplateRiskContext() {
    return useMemo(
        () => useAwsEc2LaunchTemplateRiskContext,
        []);
}

function useAwsEc2LaunchTemplateRiskContext(launchTemplateModel: Contract.AwsEc2LaunchTemplateModel) {
    const getResourceRiskContext = useGetAwsResourceRiskContext();

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.contexts.useGetAwsEc2LaunchTemplateRiskContext",
            () => ({
                autoScalingGroups: "The {{translatedLaunchTemplateTypeName}} is part of {{autoScalingGroups}}"
            }));

    return {
        ...getResourceRiskContext(launchTemplateModel),
        getAutoScalingGroupsContextItem:
            (autoScalingGroupIds: Optional<string[]>) =>
                _.isEmpty(autoScalingGroupIds)
                    ? undefined
                    : new RiskDefinitionContextItem(
                        localization.autoScalingGroups(
                            {
                                autoScalingGroups:
                                    <InlineEntities
                                        entityIdsOrModels={autoScalingGroupIds!}
                                        entityTypeName={Contract.TypeNames.AwsAutoScalingAutoScalingGroup}
                                        variant="itemCountAndType"/>,
                                translatedLaunchTemplateTypeName:
                                    entityTypeNameTranslator(
                                        launchTemplateModel.entity.typeName,
                                        {
                                            includeServiceName: false,
                                            variant: "text"
                                        })
                            }))
    };
}