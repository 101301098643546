import { useLocalization } from "@infrastructure";
import { Contract } from "../../../common";

export function useAwsResourceProductCodeTypeTranslator() {
    const localization =
        useLocalization(
            "tenants.aws.hooks.useAwsResourceProductCodeTypeTranslator",
            () => ({
                [Contract.TypeNames.AwsResourceProductCodeType]: {
                    [Contract.AwsResourceProductCodeType.DevPay]: "DevPay",
                    [Contract.AwsResourceProductCodeType.Marketplace]: "Marketplace"
                }
            }));
    return (productCodeType: Contract.AwsResourceProductCodeType) =>
        localization[Contract.TypeNames.AwsResourceProductCodeType][productCodeType]();
}