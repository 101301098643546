import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useOktaDirectoryUserStatusTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useOktaDirectoryUserStatusTranslator",
            () => ({
                [Contract.TypeNames.OktaDirectoryUserStatus]: {
                    [Contract.OktaDirectoryUserStatus.Active]: "Active",
                    [Contract.OktaDirectoryUserStatus.Deprovisioned]: "Deactivated",
                    [Contract.OktaDirectoryUserStatus.LockedOut]: "Locked out",
                    [Contract.OktaDirectoryUserStatus.PasswordExpired]: "Password expired",
                    [Contract.OktaDirectoryUserStatus.PasswordReset]: "Password reset",
                    [Contract.OktaDirectoryUserStatus.Provisioned]: "Provisioned",
                    [Contract.OktaDirectoryUserStatus.Staged]: "Staged",
                    [Contract.OktaDirectoryUserStatus.Suspended]: "Suspended"
                }
            }));
    return (status: Contract.OktaDirectoryUserStatus) =>
        localization[Contract.TypeNames.OktaDirectoryUserStatus][status]();
}