import { SxProps, Typography } from "@mui/material";
import { TOptions } from "i18next";
import React from "react";
import { Contract } from "../../../../..";
import { InlineActionsSelection, InlineEntitySelection } from "../..";

type ViewProps = {
    riskPolicyConfiguration?: Contract.AzurePrincipalAllowedResourcePermissionRiskPolicyConfiguration;
    sx?: SxProps;
    templateTranslator: (options?: TOptions) => string;
};

export function View({ riskPolicyConfiguration, sx, templateTranslator }: ViewProps) {
    return (
        <Typography
            component="span"
            sx={sx}>
            {templateTranslator({
                actions:
                    <InlineActionsSelection
                        selection={riskPolicyConfiguration}
                        sx={sx}/>,
                principals:
                    <InlineEntitySelection
                        entityTypeName={Contract.TypeNames.AadDirectoryPrincipal}
                        includeEntityServiceName={true}
                        selection={{
                            entityBuiltInAttributeTypeNames: riskPolicyConfiguration?.principalBuiltInAttributeTypeNames,
                            entityIds: riskPolicyConfiguration?.principalIds
                        }}
                        sx={sx}/>,
                resources:
                    <InlineEntitySelection
                        entityTypeName={Contract.TypeNames.AzureResource}
                        selection={{
                            entityBuiltInAttributeTypeNames: riskPolicyConfiguration?.resourceBuiltInAttributeTypeNames,
                            entityCustomAttributeDefinitionIds: riskPolicyConfiguration?.resourceCustomAttributeDefinitionIds,
                            entityIds: riskPolicyConfiguration?.resourceIds,
                            entityTags: riskPolicyConfiguration?.resourceTags
                        }}
                        sx={sx}/>
            })}
        </Typography>);
}