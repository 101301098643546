import { ApiError, AutocompleteItems, FormLayout, InfoIcon, InlineItems, ItemSelector, MailIcon, MailParser, Message, Tooltip, useChangeEffect, useInputValidation, useLocalization } from "@infrastructure";
import { Person as UserIcon } from "@mui/icons-material";
import { Button, CircularProgress, FormControl, FormHelperText, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import _ from "lodash";
import React, { useState } from "react";
import { usePrincipalsContext, useSetPrincipalsContext } from "../../..";
import { ConfigurationController, Contract, LicensingHelper, ScopeHelper, ScopePath, useIdentityRoleTranslator, UserHelper, useScopeNavigationViewContext, useTheme } from "../../../../../../../../../common";

export type UserProps = {
    roleAssignment?: Contract.ConfigurationControllerGetPrincipalRoleAssignmentsResponsePrincipalRoleAssignment;
};

export function User({ roleAssignment }: UserProps) {
    const { scopeNodeModel } = useScopeNavigationViewContext();
    const { authorizationDomainNames, executeGetPrincipals, principalRoleAssignments, users } = usePrincipalsContext();
    const setPrincipalsContext = useSetPrincipalsContext();

    const [mail, setMail] = useState(roleAssignment?.principalIdentifier ?? "");
    const [role, setRole] = useState(roleAssignment?.role);

    const [upsertUserRoleAssignmentError, setUpsertUserRoleAssignmentError] = useState<Contract.ConfigurationControllerInsertUserRoleAssignmentError | true>();
    const [upsertUserRoleAssignmentExecuting, setUpsertUserRoleAssignmentExecuting] = useState(false);

    async function upsertUserRoleAssignment() {
        setUpsertUserRoleAssignmentExecuting(true);
        setUpsertUserRoleAssignmentError(undefined);

        try {
            if (_.isNil(roleAssignment)) {
                await ConfigurationController.insertUserRoleAssignment(
                    new Contract.ConfigurationControllerInsertUserRoleAssignmentRequest(
                        mail.trim(),
                        role!,
                        scopeNodeModel.configuration.id));
            } else {
                await ConfigurationController.updateUserRoleAssignment(
                    new Contract.ConfigurationControllerUpdateUserRoleAssignmentRequest(
                        roleAssignment.role,
                        role!,
                        roleAssignment.scopeId,
                        _.find(users, user => user.mail === mail)!.id));
            }

            await executeGetPrincipals();
            setPrincipalsContext(
                principalsContext => ({
                    ...principalsContext,
                    dialogContentElement: undefined
                }));
        } catch (error) {
            setUpsertUserRoleAssignmentError(
                error instanceof ApiError && error.statusCode === 400
                    ? error.error as Contract.ConfigurationControllerInsertUserRoleAssignmentError
                    : true);
        }

        setUpsertUserRoleAssignmentExecuting(false);
    }

    const identityRoleTranslator = useIdentityRoleTranslator();
    const localization =
        useLocalization(
            "views.customer.configuration.principals.addOrEditPrincipal.user",
            () => ({
                actions: {
                    add: {
                        error:
                            {
                                authorizedDomains: [
                                    "1 authorized domain",
                                    "{{count | NumberFormatter.humanize}} authorized domains"
                                ],
                                general: "Failed to add user assignment",
                                [Contract.TypeNames.ConfigurationControllerInsertUserRoleAssignmentError]: {
                                    [Contract.ConfigurationControllerInsertUserRoleAssignmentError.UserMailDomainUnauthorized]: "The domain does not exist in the list of {{authorizedDomains}}",
                                    [Contract.ConfigurationControllerInsertUserRoleAssignmentError.UserMaxCount]: "User limit was reached"
                                }
                            },
                        title: "Add"
                    },
                    edit: {
                        error: {
                            general: "Failed to save"
                        },
                        title: "Save"
                    }
                },
                fields: {
                    mail: {
                        error: {
                            format: "Must be a valid mail address",
                            required: "Mail address cannot be empty"
                        },
                        tenableMessage: "Please use a valid Tenable One user email address",
                        title: "User email address"
                    },
                    role: "Role",
                    userRoleAssignment: {
                        error: {
                            exists: "User role assignment exists in this scope"
                        }
                    }
                },
                title: {
                    add: "Add a User Role Assignment",
                    edit: "Edit a User Role Assignment"
                }
            }));

    const [mailValidationController, mailValidationMessage] =
        useInputValidation(
            () => {
                const validationMail = mail.trim();
                if (_.isEmpty(validationMail)) {
                    return localization.fields.mail.error.required();
                }

                if (!MailParser.validate(validationMail)) {
                    return localization.fields.mail.error.format();
                }

                return undefined;
            },
            [mail]);

    const userRoleAssignmentExists =
        !_.isEmpty(mail) &&
        role != roleAssignment?.role &&
        _.some(
            principalRoleAssignments,
            principalRoleAssignment =>
                principalRoleAssignment.principalIdentifier === mail &&
                principalRoleAssignment.role === role &&
                principalRoleAssignment.scopeId === scopeNodeModel.configuration.id);

    const errorMessage =
        _.isNil(upsertUserRoleAssignmentError) &&
        !userRoleAssignmentExists
            ? undefined
            : userRoleAssignmentExists
                ? localization.fields.userRoleAssignment.error.exists()
                : upsertUserRoleAssignmentError === true
                    ? _.isNil(roleAssignment)
                        ? localization.actions.add.error.general()
                        : localization.actions.edit.error.general()
                    : localization.actions.add.error[Contract.TypeNames.ConfigurationControllerInsertUserRoleAssignmentError][upsertUserRoleAssignmentError!]({
                        authorizedDomains:
                            <InlineItems
                                items={authorizationDomainNames}
                                namePluralizer={localization.actions.add.error.authorizedDomains}
                                variant="itemOrItemCountAndType"/>
                    });

    const [valid, setValid] = useState(false);
    useChangeEffect(
        () => {
            setValid(
                mailValidationController.isValid() &&
                role != roleAssignment?.role &&
                !userRoleAssignmentExists);

            setUpsertUserRoleAssignmentError(undefined);
        },
        [mail, role, userRoleAssignmentExists]);

    const theme = useTheme();

    return (
        <FormLayout
            footerOptions={{
                contentElement:
                    <Stack
                        alignItems="center"
                        direction="row"
                        justifyContent="flex-end"
                        spacing={1}>
                        {upsertUserRoleAssignmentExecuting && (
                            <CircularProgress
                                size={theme.spacing(2)}
                                variant="indeterminate"/>)}
                        <Button
                            disabled={!valid || upsertUserRoleAssignmentExecuting}
                            onClick={() => upsertUserRoleAssignment()}>
                            {_.isNil(roleAssignment)
                                ? localization.actions.add.title()
                                : localization.actions.edit.title()}
                        </Button>
                    </Stack>
            }}
            titleOptions={{
                text:
                    _.isNil(roleAssignment)
                        ? localization.title.add()
                        : localization.title.edit()
            }}>
            <Stack
                spacing={5}
                sx={{ width: "100%" }}>
                <Stack spacing={2}>
                    <ScopePath
                        scopeId={scopeNodeModel.configuration.id}
                        variant="customer"/>
                    <FormControl
                        fullWidth={true}
                        variant="standard">
                        <AutocompleteItems
                            autoSelect={true}
                            disableClearable={true}
                            disabled={upsertUserRoleAssignmentExecuting || !_.isNil(roleAssignment)}
                            freeSolo={true}
                            fullWidth={true}
                            options={
                                _(users).
                                    map(user => user.mail).
                                    sort().
                                    value()}
                            renderInput={
                                params => (
                                    <TextField
                                        {...params}
                                        placeholder={localization.fields.mail.title()}
                                        slotProps={{
                                            input: {
                                                ...params.InputProps,
                                                endAdornment:
                                                    UserHelper.tenable &&
                                                    <InputAdornment position="end">
                                                        <Tooltip
                                                            titleOrGetTitle={
                                                                <Typography
                                                                    sx={{
                                                                        fontWeight: "initial",
                                                                        padding: theme.spacing(1),
                                                                        whiteSpace: "pre-wrap"
                                                                    }}>
                                                                    {localization.fields.mail.tenableMessage()}
                                                                </Typography>}>
                                                            <InfoIcon
                                                                sx={{
                                                                    color: theme.palette.text.secondary,
                                                                    fontSize: "24px"
                                                                }}/>
                                                        </Tooltip>
                                                    </InputAdornment>,
                                                startAdornment:
                                                    <InputAdornment
                                                        position="start"
                                                        sx={{ fontSize: "18px" }}>
                                                        <MailIcon/>
                                                    </InputAdornment>
                                            }
                                        }}
                                        variant="outlined"/>)}
                            value={mail}
                            onChange={(_event, mail) => setMail(mail)}>
                            {mail =>
                                <Stack
                                    alignItems="center"
                                    direction="row"
                                    spacing={1}>
                                    <UserIcon
                                        sx={{
                                            color: theme.palette.text.primary,
                                            fontSize: "18px"
                                        }}/>
                                    {mail}
                                </Stack>}
                        </AutocompleteItems>
                        {!_.isNil(mailValidationMessage) && (
                            <FormHelperText error={true}>{mailValidationMessage}</FormHelperText>)}
                    </FormControl>
                    <FormControl
                        fullWidth={true}
                        variant="standard">
                        <ItemSelector
                            disabled={upsertUserRoleAssignmentExecuting}
                            fullWidth={true}
                            items={
                                _(
                                    [
                                        Contract.IdentityRole.Administrator,
                                        Contract.IdentityRole.Collaborator,
                                        Contract.IdentityRole.Viewer
                                    ]).
                                    concatIf(
                                        LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.PermissionManagement) &&
                                        ScopeHelper.isPermissionManagementTenantsScope(scopeNodeModel),
                                        Contract.IdentityRole.PermissionManagementAdministrator).
                                    value()}
                            placeholder={localization.fields.role()}
                            selectedItem={role}
                            sorted={false}
                            onSelectedItemChanged={setRole}>
                            {identityRoleTranslator}
                        </ItemSelector>
                    </FormControl>
                </Stack>
                {!_.isNil(errorMessage) && (
                    <Message
                        level="error"
                        title={errorMessage}/>)}
            </Stack>
        </FormLayout>);
}