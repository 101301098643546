﻿import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useAwsWafWebAclDefaultActionTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useAwsWafWebAclDefaultActionTranslator",
            () => ({
                [Contract.TypeNames.AwsWafWebAclDefaultAction]: {
                    [Contract.AwsWafWebAclDefaultAction.Allow]: "Allow",
                    [Contract.AwsWafWebAclDefaultAction.Block]: "Block"
                }
            }));
    return (defaultAction: Contract.AwsWafWebAclDefaultAction) =>
        localization[Contract.TypeNames.AwsWafWebAclDefaultAction][defaultAction]();
}