import { Loading, map, useLocalization, VerticalFillGrid } from "@infrastructure";
import { Stack, Tab, Tabs } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract, ScopeHelper, TenantHelper, useScopeNavigationViewContext, useTheme } from "../../../../../../common";
import { ConfigurationView } from "../../utilities";
import { CustomEntityAttributeDefinitions } from "./CustomEntityAttributeDefinitions";
import { EntitiesView } from "./EntitiesView";
import { EntityProperties } from "./EntityProperties";

export function Entities() {
    const { scopeNodeModel, useViewRoute } = useScopeNavigationViewContext();
    const entitiesViews =
        _<EntitiesView>([]).
            concatIf(
                scopeNodeModel.configuration.typeName === Contract.TypeNames.CustomerConfiguration ||
                    _.includes(
                        TenantHelper.PropertyTenantTypes,
                        ScopeHelper.getTenantType(scopeNodeModel)),
                EntitiesView.EntityProperties).
            concat(EntitiesView.CustomEntityAttributeDefinitions).
            value();
    const [view, setView] = useViewRoute(`${ConfigurationView.Entities}/{view}`, entitiesViews);

    const localization =
        useLocalization(
            "views.customer.configuration.entities",
            () => ({
                tabs: {
                    entitiesView: {
                        [EntitiesView.CustomEntityAttributeDefinitions]: "Labels",
                        [EntitiesView.EntityProperties]: "Custom Properties"
                    }
                }
            }));
    const theme = useTheme();
    return (
        <VerticalFillGrid>
            <Tabs
                sx={{
                    border: "unset",
                    padding: theme.spacing(0, 2)
                }}
                value={view}
                variant="scrollable"
                onChange={(event, view) => setView(view as EntitiesView)}>
                {_.map(
                    entitiesViews,
                    entitiesView =>
                        <Tab
                            id={entitiesView}
                            key={entitiesView}
                            label={localization.tabs.entitiesView[entitiesView]()}
                            sx={{
                                marginRight: theme.spacing(3),
                                padding: 0
                            }}
                            value={entitiesView}/>)}
            </Tabs>
            <Stack
                sx={{
                    height: "100%",
                    width: "100%"
                }}>
                <Loading>
                    {map(
                        view,
                        {
                            [EntitiesView.CustomEntityAttributeDefinitions]: () => <CustomEntityAttributeDefinitions/>,
                            [EntitiesView.EntityProperties]: () => <EntityProperties/>
                        })}
                </Loading>
            </Stack>
        </VerticalFillGrid>);
}