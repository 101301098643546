import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { useGetAwsRdsNeptuneClusterRiskContext } from "../contexts";

export function useAwsRdsNeptuneClusterCloudWatchLoggingDisabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsRdsNeptuneClusterCloudWatchLoggingDisabledRisk;
    const neptuneClusterModel = entityModelStore.useGet(risk.entityId) as Contract.AwsRdsNeptuneClusterModel;
    const neptuneCluster = neptuneClusterModel.entity as Contract.AwsRdsNeptuneCluster;

    const getAwsRdsNeptuneClusterRiskContext = useGetAwsRdsNeptuneClusterRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsRdsNeptuneClusterCloudWatchLoggingDisabledRiskDefinition",
            () => ({
                description: "{{neptuneCluster}} does not publish logs to CloudWatch Logs",
                sections: {
                    resolution: {
                        step1: "Click **Modify**",
                        step2: "In the **Additional settings** section, click **Show more** ",
                        step3: "Under **Log exports**, select audit",
                        step4: "Continue and save changes"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            neptuneCluster:
                <Entity
                    entityIdOrModel={neptuneClusterModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.Neptune,
                AwsConsoleUrlBuilder.getRdsNeptuneClusterUrl(neptuneCluster)),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4()
        ],
        riskModel,
        () => {
            const neptuneClusterRiskContext = getAwsRdsNeptuneClusterRiskContext(neptuneClusterModel);
            return [
                neptuneClusterRiskContext.generalInformation,
                neptuneClusterRiskContext.size,
                neptuneClusterRiskContext.instances,
                neptuneClusterRiskContext.sensitive,
                neptuneClusterRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}