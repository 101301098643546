import { AnalyticsEventActionType, CheckboxField, Dialog, FormLayout, Link, Message, TimeHelper, useExecuteOperation, useLocalization, useTrackAnalytics } from "@infrastructure";
import { Button, CircularProgress, FormControl, FormHelperText, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { Fragment, useState } from "react";
import { Contract, CustomerConsoleAppUrlHelper, DocumentsViewer, EntityController, NonexcessivePermitterDateSelectorField, useEntityTypeNameTranslator, useTheme } from "../../../common";

type GenerateAwsResourceNonexcessivePolicyDialogProps = {
    entityExcessivePermissionCustomEvaluationData?: Contract.EntityControllerGetEntityExcessivePermissionCustomEvaluationDataResponse;
    entityModel: Contract.IEntityModel;
    onClose: () => void;
};

export function GenerateAwsResourceNonexcessivePolicyDialog({ entityExcessivePermissionCustomEvaluationData, entityModel, onClose }: GenerateAwsResourceNonexcessivePolicyDialogProps) {
    return (
        <Dialog
            size="medium"
            variant="editor"
            onClose={onClose}>
            <Core
                entityExcessivePermissionCustomEvaluationData={entityExcessivePermissionCustomEvaluationData}
                entityModel={entityModel}
                onClose={onClose}/>
        </Dialog>);
}

function Core({ entityExcessivePermissionCustomEvaluationData: customEvaluationData, entityModel, onClose }: GenerateAwsResourceNonexcessivePolicyDialogProps) {
    const [entityExcessivePermissionCustomEvaluationData] =
        useExecuteOperation(
            [GenerateAwsResourceNonexcessivePolicyDialog, entityModel.id],
            async () => customEvaluationData ?? await EntityController.getEntityExcessivePermissionCustomEvaluationData(new Contract.EntityControllerGetAwsEntityExcessivePermissionCustomEvaluationDataRequest(entityModel.id)));
    const { dateInfos, eventsTime, nonexcessivePermitterCustomGenerationEnabled } = entityExcessivePermissionCustomEvaluationData;
    const minTime = _.last(dateInfos);
    const [modifiedPolicyDocuments, setModifiedPolicyDocuments] = useState<Contract.AwsIamPolicyDocument[] | undefined>();
    const [generatePolicyExecuting, setGeneratePolicyExecuting] = useState(false);
    const [includeGroupPolicies, setIncludeGroupPolicies] = useState(false);
    const [excessivePermissionEvaluationStartDate, setExcessivePermissionEvaluationStartDate] = useState<string>();
    const [statementResourceArnPatternPreservationDisabled, setStatementResourceArnPatternPreservationDisabled] = useState(false);
    const [generateResult, setGenerateResult] = useState<Contract.EntityControllerGenerateAwsResourceNonexcessivePolicyResult | undefined>(undefined);
    const entityTypeNameTranslator = useEntityTypeNameTranslator();

    const trackAnalytics = useTrackAnalytics();
    async function generatePolicy() {
        setGeneratePolicyExecuting(true);
        setGenerateResult(undefined);
        setModifiedPolicyDocuments(undefined);

        try {
            const response =
                await EntityController.generateAwsResourceNonexcessivePolicy(
                    new Contract.EntityControllerGenerateAwsResourceNonexcessivePolicyRequest(
                        excessivePermissionEvaluationStartDate!,
                        includeGroupPolicies,
                        entityModel.id,
                        statementResourceArnPatternPreservationDisabled));

            trackAnalytics(
                AnalyticsEventActionType.AccessGenerateLeastPrivilege,
                { "Type": localization.title() });

            setGenerateResult(response.result);
            if (response.result == Contract.EntityControllerGenerateAwsResourceNonexcessivePolicyResult.Modified ||
                response.result == Contract.EntityControllerGenerateAwsResourceNonexcessivePolicyResult.ModifiedDocumentCharacterMaxCountExceeded) {
                setModifiedPolicyDocuments(response.documents);
            }
        } catch (error) {
            setGenerateResult(Contract.EntityControllerGenerateAwsResourceNonexcessivePolicyResult.Failure);
        }

        setGeneratePolicyExecuting(false);
    }

    const localization =
        useLocalization(
            "tenants.aws.generateAwsResourceNonexcessivePolicyDialog",
            () => ({
                actions: {
                    close: "Done",
                    generate: {
                        [Contract.TypeNames.EntityControllerGenerateAwsResourceNonexcessivePolicyResult]: {
                            [Contract.EntityControllerGenerateAwsResourceNonexcessivePolicyResult.AllExcessive]: "Unable to generate a policy for this {{translatedEntityTypeName}}, because there is no activity in the chosen time frame",
                            [Contract.EntityControllerGenerateAwsResourceNonexcessivePolicyResult.AllNonexcessive]: "Permissions are not excessive based on the chosen time frame, so the existing policies should be kept",
                            [Contract.EntityControllerGenerateAwsResourceNonexcessivePolicyResult.Failure]: "Unable to generate a policy for this {{translatedEntityTypeName}}. For more information contact support via the 'Contact us' link under the '?' menu (top right)",
                            [Contract.EntityControllerGenerateAwsResourceNonexcessivePolicyResult.ModifiedDocumentCharacterMaxCountExceeded]: "The policy exceeds the character limit and cannot be applied in AWS",
                            [Contract.EntityControllerGenerateAwsResourceNonexcessivePolicyResult.NoPermissions]: "Unable to generate a policy for this {{translatedEntityTypeName}}, because it has no permissions"
                        },
                        title: "Generate"
                    }
                },
                fields: {
                    excessivePermissionEvaluationStartDate: {
                        disabled: "Unable to generate a policy",
                        footer: {
                            [Contract.EntityControllerGetEntityExcessivePermissionCustomEvaluationDataResponseDateType.AwsEc2InstanceLaunchDate]: "Activity is available from {{minDate | TimeFormatter.monthDayAndYear}} (instance launch time) until {{eventsTime | TimeFormatter.monthDayAndYear}} ({{dateSelectionRange | TimeSpanFormatter.humanizeDays}} in total)",
                            [Contract.EntityControllerGetEntityExcessivePermissionCustomEvaluationDataResponseDateType.CreationDate]: "Activity is available from {{minDate | TimeFormatter.monthDayAndYear}} ({{translatedEntityTypeName}} creation) until {{eventsTime | TimeFormatter.monthDayAndYear}} ({{dateSelectionRange | TimeSpanFormatter.humanizeDays}} in total)",
                            [Contract.EntityControllerGetEntityExcessivePermissionCustomEvaluationDataResponseDateType.EventStartDate]: "Activity is available from {{minDate | TimeFormatter.monthDayAndYear}} (max activity time) until {{eventsTime | TimeFormatter.monthDayAndYear}} ({{dateSelectionRange | TimeSpanFormatter.humanizeDays}} in total)"
                        }
                    },
                    includeGroupPolicies: "Include permissions granted via group membership",
                    statementResourceArnPatternPreservationDisabled: {
                        documentation: "Learn more",
                        text: "Use specific resources instead of wildcards (only in supported AWS services). {{documentationLink}}"
                    }
                },
                footer: "The policy will be based on activity from {{excessivePermissionEvaluationStartDate | TimeFormatter.monthDayAndYear}} to today ({{excessivePermissionEvaluationTimeRange | TimeSpanFormatter.humanizeDays}} in total)",
                policyDocuments: "LeastPrivilegePolicy {{documentIndex}}",
                subtitle: "Generate a policy which you can then use in AWS to grant the minimum privileges needed.",
                title: "Least Privilege Policy"
            }));

    const theme = useTheme();
    return (
        <Dialog
            size="medium"
            variant="editor"
            onClose={onClose}>
            <FormLayout
                footerOptions={{
                    contentElement:
                        <Stack
                            alignItems="center"
                            direction="row-reverse"
                            justifyContent="space-between"
                            spacing={1}>
                            {_.isNil(modifiedPolicyDocuments)
                                ? <Fragment>
                                    <Stack
                                        alignItems="center"
                                        direction="row-reverse"
                                        justifyContent="flex-start"
                                        spacing={1}>
                                        <Button
                                            disabled={_.isNil(excessivePermissionEvaluationStartDate) || generatePolicyExecuting}
                                            onClick={generatePolicy}>
                                            {localization.actions.generate.title()}
                                        </Button>
                                        {generatePolicyExecuting &&
                                            <CircularProgress
                                                size={theme.spacing(2)}
                                                variant="indeterminate"/>}
                                    </Stack>
                                    {!_.isNil(excessivePermissionEvaluationStartDate) &&
                                        _.isNil(generateResult) &&
                                        <Message
                                            level="info"
                                            title={localization.footer({
                                                excessivePermissionEvaluationStartDate,
                                                excessivePermissionEvaluationTimeRange: TimeHelper.getInterval(excessivePermissionEvaluationStartDate!)
                                            })}/>}
                                    {!_.isNil(generateResult) &&
                                        <Message
                                            level="error"
                                            title={localization.actions.generate[Contract.TypeNames.EntityControllerGenerateAwsResourceNonexcessivePolicyResult].translate(
                                                generateResult,
                                                {
                                                    translatedEntityTypeName: entityTypeNameTranslator(
                                                        entityModel.entity.typeName,
                                                        {
                                                            includeServiceName: false,
                                                            variant: "text"
                                                        })
                                                })}/>}
                                </Fragment>
                                : <Fragment>
                                    <Button onClick={onClose}>
                                        {localization.actions.close()}
                                    </Button>
                                    {generateResult === Contract.EntityControllerGenerateAwsResourceNonexcessivePolicyResult.ModifiedDocumentCharacterMaxCountExceeded &&
                                        <Message
                                            level="warning"
                                            title={localization.actions.generate[Contract.TypeNames.EntityControllerGenerateAwsResourceNonexcessivePolicyResult].translate(generateResult)}/>}
                                </Fragment>}
                        </Stack>
                }}
                titleOptions={{
                    subtitle: localization.subtitle(),
                    text: localization.title()
                }}>
                {!_.isNil(modifiedPolicyDocuments)
                    ? <DocumentsViewer
                        documents={
                            _.map(
                                modifiedPolicyDocuments,
                                (policyDocument, policyDocumentIndex) => ({
                                    document: policyDocument.raw,
                                    name: localization.policyDocuments({ documentIndex: policyDocumentIndex + 1 })
                                }))
                        }/>
                    : <Stack spacing={3}>
                        <FormControl
                            fullWidth={true}
                            variant="standard">
                            <Stack spacing={1}>
                                <NonexcessivePermitterDateSelectorField
                                    disabled={
                                        !nonexcessivePermitterCustomGenerationEnabled ||
                                        generatePolicyExecuting}
                                    entityExcessivePermissionCustomEvaluationData={entityExcessivePermissionCustomEvaluationData}
                                    selectedDate={excessivePermissionEvaluationStartDate}
                                    onSelectedDateChanged={selectedDate => {
                                        setExcessivePermissionEvaluationStartDate(selectedDate);
                                        setGenerateResult(undefined);
                                    }}/>
                                {entityExcessivePermissionCustomEvaluationData?.nonexcessivePermitterCustomGenerationEnabled &&
                                    <Typography sx={{ fontSize: "12px" }}>
                                        {localization.fields.excessivePermissionEvaluationStartDate.footer.translate(
                                            minTime!.type,
                                            {
                                                dateSelectionRange: TimeHelper.getDuration(
                                                    minTime!.date,
                                                    eventsTime!),
                                                eventsTime,
                                                minDate: minTime!.date,
                                                translatedEntityTypeName: entityTypeNameTranslator(
                                                    entityModel.entity.typeName,
                                                    {
                                                        includeServiceName: false,
                                                        variant: "text"
                                                    })
                                            })}
                                    </Typography>}
                                {!nonexcessivePermitterCustomGenerationEnabled &&
                                    <FormHelperText error={true}>{localization.fields.excessivePermissionEvaluationStartDate.disabled()}</FormHelperText>}
                            </Stack>
                        </FormControl>
                        <Stack>
                            {entityModel.entity.typeName === Contract.TypeNames.AwsIamUser &&
                                <CheckboxField
                                    checked={includeGroupPolicies}
                                    disabled={generatePolicyExecuting}
                                    onChange={() => setIncludeGroupPolicies(!includeGroupPolicies)}>
                                    <Typography
                                        sx={{
                                            color:
                                                generatePolicyExecuting
                                                    ? theme.palette.text.disabled
                                                    : theme.palette.text.primary,
                                            fontSize: "12px"
                                        }}>
                                        {localization.fields.includeGroupPolicies()}
                                    </Typography>
                                </CheckboxField>}
                            <CheckboxField
                                checked={statementResourceArnPatternPreservationDisabled}
                                disabled={generatePolicyExecuting}
                                onChange={() => setStatementResourceArnPatternPreservationDisabled(!statementResourceArnPatternPreservationDisabled)}>
                                <Typography
                                    sx={{
                                        color:
                                            generatePolicyExecuting
                                                ? theme.palette.text.disabled
                                                : theme.palette.text.primary,
                                        fontSize: "12px"
                                    }}>
                                    {localization.fields.statementResourceArnPatternPreservationDisabled.text({
                                        documentationLink:
                                            <Link
                                                disabled={generatePolicyExecuting}
                                                urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsGenerateAwsResourceNonexcessivePolicyRelativeUrl()}
                                                variant="external">
                                                {localization.fields.statementResourceArnPatternPreservationDisabled.documentation()}
                                            </Link>
                                    })}
                                </Typography>
                            </CheckboxField>
                        </Stack>
                    </Stack>}
            </FormLayout>
        </Dialog>);
}