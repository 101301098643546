import { DataTableColumn, DataTableSortType, DeferredFilter, optionalTableCell, TimeFormatter, TimeRangeFilter, useLocalization } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { DefinitionData } from "../../../../..";
import { EntityTableDefinition } from "../../..";
import { Contract, CustomerConsoleAppUrlHelper, EntityTypeFilter, InlineResourceTags, ItemSelectionHelper, PagedEntityResourceTagFilter, ResourceTagHelper, TimeRangeHelper, useEntityTypeNameTranslator } from "../../../../../../../../../../common";
import { ProfileCategory } from "../../../../../Profile/hooks";
import { useCommonDataAnalysisDefinition } from "../useCommonDataAnalysisDefinition";
import { useCommonEntityDefinition } from "../useCommonEntityDefinition";
import { DataAnalysisTableSummarySection } from "./components";

export function useDataAnalysisResourceDefinition(definitionData: DefinitionData) {
    const commonEntityDefinition = useCommonEntityDefinition(definitionData);
    const commonDataAnalysisResourceDefinition = useCommonDataAnalysisDefinition(definitionData);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.useDataAnalysisResourceDefinition",
            () => ({
                columns: {
                    creationTime: "Creation Time",
                    tags: "Tags",
                    typeName: "Type"
                }
            }));

    return new EntityTableDefinition(
        _.filter([
            commonEntityDefinition.columns.tenantColumn,
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        default: true,
                        element:
                            <DeferredFilter
                                promiseOrGetPromise={definitionData.entityModelFiltersPromise}
                                title={localization.columns.typeName()}>
                                {filters =>
                                    <EntityTypeFilter
                                        emptyValue={filters.typeNameItems.emptyValue}
                                        entityTypeNames={filters.typeNameItems.items}
                                        groupItemTitle={false}
                                        placeholder={localization.columns.typeName()}/>}
                            </DeferredFilter>
                    }
                }}
                id={Contract.EntityModelProperty.DataAnalysisResourceTypeName}
                itemProperty={(item: Contract.IDataAnalysisResourceModel) => entityTypeNameTranslator(item.entity.typeName)}
                key={Contract.EntityModelProperty.DataAnalysisResourceTypeName}
                sortOptions={{ enabled: false }}
                title={localization.columns.typeName()}/>,
            commonDataAnalysisResourceDefinition.columns.dataAnalysisResourceStorageSizeColumn,
            commonEntityDefinition.getRegionLocationColumn(true),
            commonEntityDefinition.getRegionColumn(false),
            commonDataAnalysisResourceDefinition.columns.dataClassifiersColumn?.(true),
            commonDataAnalysisResourceDefinition.columns.dataCategoriesColumn,
            commonDataAnalysisResourceDefinition.columns.dataAnalysisResourceSensitivityColumn,
            commonEntityDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.IDataAnalysisResourceModel) => ({
                            [localization.columns.creationTime()]:
                                _.isNil(item.creationTime)
                                    ? ""
                                    : TimeFormatter.iso8601String(item.creationTime)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <DeferredFilter
                                promiseOrGetPromise={definitionData.entityModelFiltersPromise as Promise<Contract.DataAnalysisResourceModelFilters>}
                                title={localization.columns.creationTime()}>
                                {filters =>
                                    <TimeRangeFilter
                                        emptyValue={filters.creationTimeRange.emptyValue}
                                        placeholder={localization.columns.creationTime()}
                                        timeRange={TimeRangeHelper.getTimeRangeFilterRange(filters.creationTimeRange.timeRange)}/>}
                            </DeferredFilter>
                    }
                }}
                id={Contract.EntityModelProperty.ResourceCreationTime}
                key={Contract.EntityModelProperty.ResourceCreationTime}
                render={
                    optionalTableCell<Contract.IDataAnalysisResourceModel>(
                        item =>
                            !_.isNil(item.creationTime) && TimeFormatter.monthDayAndYear((item as Contract.IDataAnalysisResourceModel).creationTime))}
                selectorOptions={{ default: false }}
                sortOptions={{ type: DataTableSortType.Date }}
                title={localization.columns.creationTime()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.IDataAnalysisResourceModel) => ({
                            [localization.columns.tags()]:
                                _.as<Contract.IDataAnalysisResource>(item.entity).
                                    tags.
                                    map(ResourceTagHelper.getDisplayName).
                                    join("\n")
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityResourceTagFilter
                                entityTypeName={Contract.TypeNames.IDataAnalysisResource}
                                placeholder={localization.columns.tags()}/>
                    }
                }}
                id={Contract.EntityModelProperty.ResourceTags}
                key={Contract.EntityModelProperty.ResourceTags}
                render={
                    optionalTableCell<Contract.IDataAnalysisResourceModel>(
                        (item: Contract.IDataAnalysisResourceModel) =>
                            _.isEmpty(_.as<Contract.IDataAnalysisResource>(item.entity).tags)
                                ? undefined
                                : <InlineResourceTags tags={_.as<Contract.IDataAnalysisResource>(item.entity).tags}/>)}
                selectorOptions={{ default: false }}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.tags()}/>,
            commonDataAnalysisResourceDefinition.columns.scanTimeColumn,
            commonEntityDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn,
            commonEntityDefinition.columns.attributesColumn,
            commonDataAnalysisResourceDefinition.columns.dataAnalysisStatusColumn
        ]),
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageDataAnalysisResourceRequest(
                new Contract.EntityControllerGetEntityModelPageDataAnalysisResourceRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.DataAnalysisResourceTypeName])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestDataAnalysis(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.DataAnalysisResourceScanStatus]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.DataAnalysisResourceDataCategories]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.DataAnalysisResourceDataClassifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.DataAnalysisResourceDataSensitivities]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.DataAnalysisResourceScanTime])),
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(
                    ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(
                        filterMap[Contract.EntityModelProperty.ResourceTags]))),
        {
            defaultSortColumnId: Contract.EntityModelProperty.DataAnalysisResourceDataSensitivities,
            getRowItemUrl:
                (item: Contract.EntityModel) =>
                    CustomerConsoleAppUrlHelper.getEntityProfileRelativeUrl(
                        item,
                        {
                            category: ProfileCategory.DataAnalysis
                        }),
            summarySection: DataAnalysisTableSummarySection
        });
}