import { AppError, UnexpectedError } from "@infrastructure";
import { Typography } from "@mui/material";
import { TFunction, TOptions } from "i18next";
import _, { Dictionary, Function0 } from "lodash";
import React, { Fragment, isValidElement, ReactNode, useMemo } from "react";
import { useTranslation } from "react-i18next";

export type TranslateOptions = TOptions & {
    settings?: TranslateOptionsSettings;
};

type TranslateOptionsSettings = {
    decorators?: boolean;
};

export type LocalizationTranslation<TResourceValue> =
    TResourceValue extends string
        ? (options?: TranslateOptions) => string
        : TResourceValue extends string[]
            ? (count: number, options?: TranslateOptions) => string
            : Localization<TResourceValue>;
export type Localization<TResource> =
    { [TResourceKey in keyof TResource]: LocalizationTranslation<TResource[TResourceKey]> } &
    { translate: (key: string, options?: TranslateOptions) => string };

const resourcePathToLocalizationMap: Dictionary<Localization<any>> = {};

export function useLocalization<TResource extends object>(resourcePath: string, createResource: Function0<TResource>): Localization<TResource> {
    const { t } = useTranslation();
    return useMemo(
        () => {
            if (!_.has(resourcePathToLocalizationMap, resourcePath)) {
                resourcePathToLocalizationMap[resourcePath] = createLocalization(resourcePath, createResource(), t);
            }

            return resourcePathToLocalizationMap[resourcePath] as Localization<TResource>;
        },
        []);
}

function createLocalization<TResource extends object>(resourcePath: string, resource: TResource, t: TFunction): Localization<TResource> {
    const localization =
        _.mapValues(
            resource,
            (resourceValue, resourceKey) =>
                _.isString(resourceValue)
                    ? (options?: TranslateOptions) =>
                        translate(
                            t,
                            resourcePath,
                            resourceKey,
                            resourceValue,
                            options)
                    : Array.isArray(resourceValue)
                        ? (count: number, options?: TranslateOptions) =>
                            translate(
                                t,
                                resourcePath,
                                resourceKey,
                                resourceValue[0],
                                {
                                    ...options,
                                    count
                                })
                        : createLocalization(
                            `${resourcePath}.${resourceKey}`,
                            resourceValue as any,
                            t)) as any;
    return ({
        ...localization,
        translate:
            (key: string, options?: TranslateOptions) => {
                const resourceTranslator = localization[key];
                if (!_.isFunction(resourceTranslator)) {
                    throw new AppError(`Cannot find resource translator [resourcePath=${resourcePath} key=${key}]`);
                }
                return _.isArray(_.get(resource, key))
                    ? resourceTranslator(options?.count ?? 1, options)
                    : resourceTranslator(options);
            }
    }) as any;
}

function translate(
    t: TFunction,
    resourcePath: string,
    resourceKey: string,
    resourceValue: string,
    options?: TranslateOptions) {
    const elements: ReactNode[] = [];

    function createElementReference(element: ReactNode) {
        elements.push(element);
        return `\0${elements.length - 1}\0`;
    }

    function translateTextElementReferences(translatedResourceParameterValueText: string) {
        if (translatedResourceParameterValueText.includes("\0")) {
            return (
                <Fragment key={`${resourcePath}.${resourceKey}.${translatedResourceParameterValueText}`}>
                    {_.map(
                        translatedResourceParameterValueText.split("\0"),
                        (translatedResourceParameterValueTextPart, translatedResourceParameterValueTextPartIndex) =>
                            <Fragment key={translatedResourceParameterValueTextPartIndex}>
                                {translatedResourceParameterValueTextPartIndex % 2 === 1
                                    ? elements[Number(translatedResourceParameterValueTextPart)]
                                    : translatedResourceParameterValueTextPart}
                            </Fragment>)}
                </Fragment>);
        } else {
            return translatedResourceParameterValueText;
        }
    }

    const resourceValueParametersRegex = /{{\W*(\w+)\W*}}/g;
    let resourceValueParameter = resourceValueParametersRegex.exec(resourceValue);
    if (!_.isNil(resourceValueParameter)) {
        if (_.isNil(options)) {
            throw new AppError(`Missing resource parameter arguments [resourcePath=${resourcePath} resourceKey=${resourceKey}]`);
        }

        while (!_.isNil(resourceValueParameter)) {
            const resourceParameterName = resourceValueParameter[1];
            if (_.isNil(options[resourceParameterName])) {
                throw new AppError(`Missing resource parameter argument [resourcePath=${resourcePath} resourceKey=${resourceKey} resourceParameterName=${resourceParameterName}]`);
            }

            if (isValidElement(options[resourceParameterName])) {
                const { [resourceParameterName]: resourceParameterElement } = options;
                options[resourceParameterName] = createElementReference(resourceParameterElement);
            }

            resourceValueParameter = resourceValueParametersRegex.exec(resourceValue);
        }
    }

    const translatedResourceParameterValue =
        t(`${resourcePath}.${resourceKey}`, options ?? {}).
            replace(
                /\*(\w+)?\*([^\*]+)\*\*/g,
                (_match, variant, text) =>
                    options?.settings?.decorators === false
                        ? text
                        : createElementReference(
                            <Decorator variant={variant}>
                                {translateTextElementReferences(text)}
                            </Decorator>));
    return translateTextElementReferences(translatedResourceParameterValue);
}

type DecoratorProps = {
    children: ReactNode;
    variant?: "bold" | "capitalize" | "italic" | "semibold" | "superscript" | "underline";
};

function Decorator({ children, variant = "semibold" }: DecoratorProps) {
    switch (variant) {
        case "bold":
        case "semibold":
            return (
                <Typography
                    component="span"
                    sx={{
                        color: "unset",
                        fontSize: "unset",
                        fontWeight:
                            variant === "bold"
                                ? "bold"
                                : 600
                    }}>
                    {children}
                </Typography>);
        case "capitalize":
            return (
                <Typography
                    component="span"
                    sx={{
                        "&:first-letter": {
                            textTransform: "capitalize"
                        },
                        display: "inline-block",
                        fontSize: "unset"
                    }}>
                    {children}
                </Typography>);
        case "italic":
            return (
                <Typography
                    component="span"
                    sx={{ fontStyle: "italic" }}>
                    {children}
                </Typography>);
        case "superscript":
            return (
                <sup
                    style={{
                        lineHeight: 0,
                        textTransform: "lowercase"
                    }}>
                    {children}
                </sup>);
        case "underline":
            return (
                <Typography
                    component="span"
                    sx={{ textDecoration: "underline" }}>
                    {children}
                </Typography>);
        default:
            throw new UnexpectedError("variant", variant);
    }
}