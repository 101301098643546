import { useLocalization } from "@infrastructure";
import _ from "lodash";
import { AuditEventDefinition, AuditEventDefinitionContext, AuditEventDefinitionDetailItem } from "..";
import { Contract } from "../../../../../../../../../common";
import { useCodePipelineTypeTranslator } from "../../../../../../../../../tenants";

export function useCodePipelineAuditEventDefinition(context: AuditEventDefinitionContext) {
    const localization =
        useLocalization(
            "views.customer.auditEvents.profile.hooks.useDefinition.hooks.useCodePipelineAuditEventDefinition",
            () => ({
                pipelineName: "Name",
                pipelinePreviousName: "Previous name",
                pipelineType: "Pipeline type"
            }));

    const pipelineTypeTranslator = useCodePipelineTypeTranslator();
    const codePipelineAuditEvent = context.auditEventModel.auditEvent as Contract.CodePipelineAuditEvent;
    return new AuditEventDefinition(
        _.filter([
            new AuditEventDefinitionDetailItem(
                localization.pipelineType(),
                pipelineTypeTranslator(codePipelineAuditEvent.pipelineType)),
            new AuditEventDefinitionDetailItem(
                localization.pipelineName(),
                codePipelineAuditEvent.pipelineName),
            codePipelineAuditEvent.typeName === Contract.TypeNames.CodePipelineUpdateAuditEvent
                ? new AuditEventDefinitionDetailItem(
                    localization.pipelinePreviousName(),
                    (codePipelineAuditEvent as Contract.CodePipelineUpdateAuditEvent).pipelinePreviousName!)
                : undefined!
        ]));
}