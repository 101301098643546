import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, EnumValuesFilter, useLocalization } from "@infrastructure";
import { Dictionary } from "lodash";
import React from "react";
import { useOciCommonResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { useOciKmsVaultStatusTranslator, useOciKmsVaultTypeTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";

export function useOciKmsVaultDefinition(definitionData: DefinitionData) {
    const commonResourceDefinition = useOciCommonResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const vaultStatusTranslator = useOciKmsVaultStatusTranslator();
    const vaultTypeTranslator = useOciKmsVaultTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.oci.useOciKmsVaultDefinition",
            () => ({
                columns: {
                    keyIds: "Master Encryption Keys",
                    secretIds: "Secrets",
                    status: "State",
                    type: "Vault Type"
                }
            }));

    return new EntityTableDefinition(
        [
            commonResourceDefinition.columns.tenantColumn,
            commonResourceDefinition.columns.creationTimeColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.OciKmsVaultModel) => ({
                            [localization.columns.status()]: vaultStatusTranslator((item.entity as Contract.OciKmsVault).status)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        default: true,
                        element:
                            <EnumValuesFilter
                                enumType={Contract.OciKmsVaultStatus}
                                enumTypeTranslator={vaultStatusTranslator}
                                placeholder={localization.columns.status()}/>
                    }
                }}
                id={Contract.EntityModelProperty.OciKmsVaultStatus}
                itemProperty={(item: Contract.OciKmsVaultModel) => vaultStatusTranslator((item.entity as Contract.OciKmsVault).status)}
                key={Contract.EntityModelProperty.OciKmsVaultStatus}
                title={localization.columns.status()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.OciKmsVaultModel) => ({
                            [localization.columns.type()]: vaultTypeTranslator((item.entity as Contract.OciKmsVault).type)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        default: true,
                        element:
                            <EnumValuesFilter
                                enumType={Contract.OciKmsVaultType}
                                enumTypeTranslator={vaultTypeTranslator}
                                placeholder={localization.columns.type()}/>
                    }
                }}
                id={Contract.EntityModelProperty.OciKmsVaultType}
                itemProperty={(item: Contract.OciKmsVaultModel) => vaultTypeTranslator((item.entity as Contract.OciKmsVault).type)}
                key={Contract.EntityModelProperty.OciKmsVaultType}
                title={localization.columns.type()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.OciKmsVaultModel>(
                        Contract.TypeNames.OciKmsVaultKey,
                        item => item.keyIds,
                        localization.columns.keyIds())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.OciKmsVaultKeys)}
                                placeholder={localization.columns.keyIds()}/>
                    }
                }}
                id={Contract.EntityModelProperty.OciKmsVaultKeys}
                key={Contract.EntityModelProperty.OciKmsVaultKeys}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.OciKmsVaultModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.keyIds}
                            entityTypeName={Contract.TypeNames.OciKmsVaultKey}
                            entityVariant="iconText"/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.keyIds()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.OciKmsVaultModel>(
                        Contract.TypeNames.OciKmsVaultSecret,
                        item => item.secretIds,
                        localization.columns.secretIds())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.OciKmsVaultSecrets)}
                                placeholder={localization.columns.secretIds()}/>
                    }
                }}
                id={Contract.EntityModelProperty.OciKmsVaultSecrets}
                key={Contract.EntityModelProperty.OciKmsVaultSecrets}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.OciKmsVaultModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.secretIds}
                            entityTypeName={Contract.TypeNames.OciKmsVaultSecret}
                            entityVariant="iconText"/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.secretIds()}/>,
            commonResourceDefinition.columns.creatorCsvColumn,
            commonResourceDefinition.columns.regionColumn,
            commonResourceDefinition.columns.regionLocationColumn,
            commonResourceDefinition.columns.tagsColumn,
            commonResourceDefinition.columns.attributesColumn,
            commonResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageOciKmsVaultRequest(
                new Contract.EntityControllerGetEntityModelPageOciKmsVaultRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciKmsVaultKeys]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciKmsVaultSecrets]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciKmsVaultStatus]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciKmsVaultType])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags]))));
}