import { Link, Message, useExecuteOperation, useInputValidation, useLocalization, useOrderedWizardContext } from "@infrastructure";
import { FormControl, FormHelperText, Stack, TextField, Typography } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { ConfigurationController, Contract, GeographyHelper, RadioGroup, useAwsPartitionTypeTranslator, useTheme } from "../../../../../../../../../../../../common";
import { AwsRegionSystemNamesSelector } from "../../../../../../../../../../../../tenants";
import { useAddOrEditContext, useSetAddOrEditContext } from "../../index";

export function TenantDetailsItem() {
    const [{ partitionTypeToRegionSystemNamesMap }] =
        useExecuteOperation(
            TenantDetailsItem,
            ConfigurationController.getAwsRegions);

    const { executing, setLoaded, setValid } = useOrderedWizardContext();
    const setAddOrEditContext = useSetAddOrEditContext();

    useEffect(
        () => {
            setLoaded();
        },
        []);

    const {
        tenantName,
        tenantOnboardingInfo,
        tenantOrganizationEnabled,
        tenantPartitionType,
        tenantRegionSystemNames
    } = useAddOrEditContext();

    const [name, setName] = useState(tenantName ?? "");
    const [partitionType, setPartitionType] = useState(tenantPartitionType ?? GeographyHelper.deploymentAwsPartitionType);
    const [regionSystemNames, setRegionSystemNames] = useState(tenantRegionSystemNames);

    const awsPartitionTypeTranslator = useAwsPartitionTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.scopes.hooks.useDefinition.hooks.useAwsDefinition.addOrEdit.tenantDetailsItem",
            () => ({
                fields: {
                    name: {
                        error: {
                            required: "Name cannot be empty"
                        },
                        title: "Name"
                    },
                    partitionType: {
                        info: {
                            awsDocsLink: "AWS Documentation",
                            title: "The partition that your AWS account belongs to. A partition is a group of AWS Regions. For more information, refer to {{awsDocsLink}}"
                        },
                        title: "Partition",
                        tooltip: "Please contact support to enable the AWS {{partitionType}} partition"
                    },
                    regionSystemNames: {
                        error: {
                            empty: "Active Regions cannot be empty"
                        },
                        some: [
                            "1 Region",
                            "{{count | NumberFormatter.humanize}} Regions"
                        ],
                        subtitle: "Select the specific regions used by your organization, as unselected regions will be ignored. Enable all regions not disabled or blocked by SCPs for scanning. If you expand to new regions later, edit your account to add them",
                        title: "Active Regions"
                    }
                }
            }));

    const [nameValidationController, nameValidationMessage] =
        useInputValidation(
            () => {
                const validationName = name?.trim();
                if (_.isEmpty(validationName)) {
                    return localization.fields.name.error.required();
                }

                return undefined;
            },
            [name]);

    const [regionSystemNamesValidationController, regionSystemNamesValidationMessage] =
        useInputValidation(
            () => {
                if (!_.isNil(regionSystemNames) && _.isEmpty(regionSystemNames)) {
                    return localization.fields.regionSystemNames.error.empty();
                }

                return undefined;
            },
            [regionSystemNames]);

    useEffect(
        () => {
            setAddOrEditContext(
                addOrEditContext => ({
                    ...addOrEditContext,
                    tenantName: name?.trim(),
                    tenantPartitionType: partitionType,
                    tenantRegionSystemNames: regionSystemNames
                }));
            setValid(
                nameValidationController.isValid() &&
                regionSystemNamesValidationController.isValid());
        },
        [name, partitionType, regionSystemNames]);
    const theme = useTheme();
    const partitionRegionSystemNames = partitionTypeToRegionSystemNamesMap[partitionType!];
    return (
        <Stack
            spacing={5}
            sx={{ maxWidth: theme.spacing(80) }}>
            <Stack spacing={1}>
                <Typography variant="h4">
                    {localization.fields.name.title()}
                </Typography>
                <FormControl
                    fullWidth={true}
                    variant="standard">
                    <TextField
                        autoFocus={true}
                        disabled={executing || tenantOrganizationEnabled}
                        value={name}
                        variant="outlined"
                        onChange={event => setName(event.target.value)}/>
                    {!_.isNil(nameValidationMessage) &&
                        <FormHelperText error={true}>{nameValidationMessage}</FormHelperText>}
                </FormControl>
            </Stack>
            <Stack spacing={1}>
                <Stack
                    direction="row"
                    spacing={1}>
                    <Typography variant="h4">
                        {localization.fields.partitionType.title()}
                    </Typography>
                    <Message
                        level="info"
                        title={
                            localization.fields.partitionType.info.title({
                                awsDocsLink:
                                    <Link
                                        urlOrGetUrl="https://docs.aws.amazon.com/whitepapers/latest/aws-fault-isolation-boundaries/partitions.html"
                                        variant="external">
                                        {localization.fields.partitionType.info.awsDocsLink()}
                                    </Link>
                            })}
                        variant="minimal"/>
                </Stack>
                <RadioGroup
                    items={
                        _.concat(
                            {
                                disabled: false,
                                label:
                                    <Stack>
                                        <Typography>
                                            {awsPartitionTypeTranslator(GeographyHelper.deploymentAwsPartitionType)}
                                        </Typography>
                                    </Stack>,
                                value: GeographyHelper.deploymentAwsPartitionType
                            },
                            _(tenantOnboardingInfo.partitionTypes).
                                filter(partitionType => partitionType !== GeographyHelper.deploymentAwsPartitionType).
                                map(
                                    partitionType => {
                                        const disabled =
                                            partitionType == Contract.AwsPartitionType.China &&
                                            _.isNil(tenantOnboardingInfo.chinaAuthenticationUserArn);
                                        return ({
                                            disabled,
                                            label:
                                                <Stack
                                                    direction="row"
                                                    spacing={theme.spacing(1)}>
                                                    <Typography>{awsPartitionTypeTranslator(partitionType)}</Typography>
                                                    {disabled &&
                                                        <Message
                                                            level="info"
                                                            sx={{ fontSize: "15px" }}
                                                            title={localization.fields.partitionType.tooltip({ partitionType: awsPartitionTypeTranslator(partitionType) })}
                                                            variant="minimal"/>}
                                                </Stack>,
                                            value: partitionType
                                        });
                                    }).
                                value())}
                    selectedValue={partitionType}
                    onChange={
                        event => {
                            setPartitionType(event);
                            setRegionSystemNames(undefined);
                        }}/>
            </Stack>
            <Stack spacing={1}>
                <Typography variant="h4">
                    {localization.fields.regionSystemNames.title()}
                </Typography>
                <Typography>
                    {localization.fields.regionSystemNames.subtitle()}
                </Typography>
                <AwsRegionSystemNamesSelector
                    disabled={executing}
                    label={localization.fields.regionSystemNames.title()}
                    regionSystemNames={partitionRegionSystemNames}
                    selectedRegionSystemNames={
                        _.isNil(regionSystemNames)
                            ? partitionRegionSystemNames
                            : regionSystemNames}
                    onSelectedRegionSystemNamesChanged={
                        selectedRegions =>
                            setRegionSystemNames(
                                selectedRegions.length === partitionRegionSystemNames.length
                                    ? undefined
                                    : selectedRegions)}/>
                {!_.isNil(regionSystemNamesValidationMessage) && (
                    <FormHelperText error={true}>{regionSystemNamesValidationMessage}</FormHelperText>)}
            </Stack>
        </Stack>);
}