import { useLocalization } from "@infrastructure";
import { ArrowRightAlt as EdgeIcon } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract, useTheme } from "../../../../..";

export function Legend() {
    const localization =
        useLocalization(
            "common.access.graph.legend",
            () => ({
                ExcessivePermissionType: {
                    [Contract.AccessGraphPermissionPathActionsExcessiveness.All]: "All permissions are excessive",
                    [Contract.AccessGraphPermissionPathActionsExcessiveness.None]: "No permissions are excessive",
                    [Contract.AccessGraphPermissionPathActionsExcessiveness.Some]: "Some permissions are excessive"
                }
            }));
    const theme = useTheme();
    return (
        <Stack>
            {_.map(
                [
                    Contract.AccessGraphPermissionPathActionsExcessiveness.All,
                    Contract.AccessGraphPermissionPathActionsExcessiveness.Some,
                    Contract.AccessGraphPermissionPathActionsExcessiveness.None
                ],
                excessivePermissionType =>
                    <Stack
                        alignItems="center"
                        direction="row"
                        key={excessivePermissionType}
                        spacing={1}>
                        <EdgeIcon
                            sx={{
                                color: theme.palette.excessivePermission(excessivePermissionType),
                                fontSize: "24px"
                            }}/>
                        <Typography>{localization.ExcessivePermissionType[excessivePermissionType]()}</Typography>
                    </Stack>)}
        </Stack>);
}