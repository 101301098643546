import { useLocalization } from "@infrastructure";
import _ from "lodash";
import { useGetPermissionAssignmentEligibilityUpdateAuditEventCommonChanges } from ".";
import { Contract } from "../../../..";

export function useGetGcpRoleBindingEligibilityUpdateAuditEventChanges() {
    const getPermissionAssignmentEligibilityUpdateAuditEventCommonChanges = useGetPermissionAssignmentEligibilityUpdateAuditEventCommonChanges();

    const localization =
        useLocalization(
            "common.hooks.usePermissionEligibilityUpdateAuditEventChangesTranslator.hooks.useGetPermissionAssignmentEligibilityUpdateAuditEventChanges.useGetGcpRoleBindingEligibilityUpdateAuditEventChanges",
            () => ({
                roles: "Permissions"
            }));
    return (permissionEligibilityUpdateAuditEventPermissionEligibilityChanges: Contract.PermissionEligibilityUpdateAuditEventGcpRoleBindingEligibilityChanges) => {
        const permissionAssignmentEligibilityUpdateAuditEventCommonChanges = getPermissionAssignmentEligibilityUpdateAuditEventCommonChanges(permissionEligibilityUpdateAuditEventPermissionEligibilityChanges);
        return _.filter([
            permissionAssignmentEligibilityUpdateAuditEventCommonChanges.name,
            permissionAssignmentEligibilityUpdateAuditEventCommonChanges.principalTenantId,
            permissionAssignmentEligibilityUpdateAuditEventCommonChanges.granteePrincipals,
            permissionEligibilityUpdateAuditEventPermissionEligibilityChanges.rolesChanged
                ? localization.roles()
                : undefined!,
            permissionAssignmentEligibilityUpdateAuditEventCommonChanges.permissionRequestScopes,
            permissionAssignmentEligibilityUpdateAuditEventCommonChanges.expirationTimeFrame,
            permissionAssignmentEligibilityUpdateAuditEventCommonChanges.approval,
            permissionAssignmentEligibilityUpdateAuditEventCommonChanges.reasonRequired
        ]);
    };
}