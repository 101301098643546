import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, useLocalization } from "@infrastructure";
import { Dictionary } from "lodash";
import React from "react";
import { useGcpCommonScopeResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";

export function useGcpKmsKeyRingDefinition(definitionData: DefinitionData) {
    const commonScopeResourceDefinition = useGcpCommonScopeResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.gcp.useGcpKmsKeyRingDefinition",
            () => ({
                columns: {
                    keyIds: "Keys"
                }
            }));
    return new EntityTableDefinition(
        [
            commonScopeResourceDefinition.columns.tenantColumn,
            commonScopeResourceDefinition.columns.creationTimeColumn,
            commonScopeResourceDefinition.columns.creatorIdentityCsvColumn,
            commonScopeResourceDefinition.columns.creatorOriginatorEntityCsvColumn,
            commonScopeResourceDefinition.columns.accessLevelColumn,
            commonScopeResourceDefinition.columns.updateTimeColumn,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.GcpKmsKeyRingModel>(
                        Contract.TypeNames.GcpKmsKeyRingKey,
                        item => item.keyIds,
                        localization.columns.keyIds())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.GcpKmsKeyRingKeys)}
                                placeholder={localization.columns.keyIds()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpKmsKeyRingKeys}
                key={Contract.EntityModelProperty.GcpKmsKeyRingKeys}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.GcpKmsKeyRingModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.keyIds}
                            entityTypeName={Contract.TypeNames.GcpKmsKeyRingKey}
                            entityVariant="iconText"/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.keyIds()}/>,
            commonScopeResourceDefinition.columns.regionColumn,
            commonScopeResourceDefinition.columns.regionLocationColumn,
            commonScopeResourceDefinition.columns.tagsColumn,
            commonScopeResourceDefinition.columns.attributesColumn,
            commonScopeResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonScopeResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonScopeResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageGcpKmsKeyRingRequest(
                new Contract.EntityControllerGetEntityModelPageGcpKmsKeyRingRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.GcpResourceUpdateTime]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpScopeResourceAccessLevel]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpScopeResourceRawId]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpKmsKeyRingKeys])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags]))));
}