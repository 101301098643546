import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, entityModelStore, InlineEntities, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetWorkloadAnalysisRiskContext } from "./useGetWorkloadAnalysisRiskContext";
import { useGetWorkloadResourceOperatingSystemContextItem } from "./useGetWorkloadResourceOperatingSystemContextItem";

export function useGetContainerImageWorkloadAnalysisRiskContext() {
    return useMemo(
        () => useContainerImageWorkloadAnalysisRiskContext,
        []);
}

function useContainerImageWorkloadAnalysisRiskContext(risk: Contract.ContainerImageWorkloadAnalysisRisk) {
    const entityModel = entityModelStore.useGet(risk.entityId);
    const containerImageModels = _.as<Contract.IContainerImageModel[]>(entityModelStore.useGet(risk.containerImageIds));
    const operatingSystemDisplayName =
        _(containerImageModels).
            filter(containerImageModel => !_.isNil(containerImageModel.workloadAnalysisOperatingSystem?.displayName)).
            first()?.
            workloadAnalysisOperatingSystem?.
            displayName;
    const operatingSystemEndOfLifeDate =
        _(containerImageModels).
            filter(containerImageModel => !_.isNil(containerImageModel.workloadAnalysisOperatingSystemEndOfLifeDate)).
            first()?.
            workloadAnalysisOperatingSystemEndOfLifeDate;
    const operatingSystemType =
        _(containerImageModels).
            filter(containerImageModel => !_.isNil(containerImageModel.operatingSystemType)).
            first()?.
            operatingSystemType;

    const getWorkloadResourceOperatingSystemContextItem = useGetWorkloadResourceOperatingSystemContextItem();
    const workloadAnalysisRiskContext = useGetWorkloadAnalysisRiskContext()(risk);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.workloadAnalysis.hooks.contexts.useGetContainerImageWorkloadAnalysisRiskContext",
            () => ({
                containerImage: "container image",
                virtualMachines: "The container image was found on {{virtualMachines}}",
                vulnerabilities: "The container image on this {{translatedEntityTypeName}} contains {{vulnerabilities}}{{resolvableVulnerabilitiesSuffix}}"
            }));
    const translatedEntityTypeName =
        entityTypeNameTranslator(
            entityModel.entity.typeName,
            { includeServiceName: false });
    return {
        ...workloadAnalysisRiskContext,
        getSeverityVulnerabilities:
            (severity: Contract.Severity) =>
                workloadAnalysisRiskContext.getSeverityVulnerabilities(
                    severity,
                    localization.containerImage()),
        getVulnerabilities: (
            displayResolvableOperatingSystemPackageVulnerabilities?: boolean,
            displayResolvableVulnerabilities?: boolean) => {
            const vulnerabilitiesProps =
                workloadAnalysisRiskContext.getVulnerabilitiesProps(
                    operatingSystemType,
                    displayResolvableOperatingSystemPackageVulnerabilities,
                    displayResolvableVulnerabilities);

            return _.isNil(vulnerabilitiesProps)
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.vulnerabilities({
                        ...vulnerabilitiesProps,
                        translatedEntityTypeName
                    }));
        },
        operatingSystem:
            getWorkloadResourceOperatingSystemContextItem(
                operatingSystemDisplayName,
                operatingSystemEndOfLifeDate,
                entityTypeNameTranslator(
                    Contract.TypeNames.IContainerImage,
                    {
                        variant: "text"
                    })),
        virtualMachines:
            _.isEmpty(risk.virtualMachineIds)
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.virtualMachines({
                        virtualMachines:
                            <InlineEntities
                                entityIdsOrModels={risk.virtualMachineIds}
                                entityTypeName={Contract.TypeNames.IVirtualMachine}
                                variant="itemCountAndType"/>
                    }))
    };
}