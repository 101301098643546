import { useLocalization } from "@infrastructure";
import React from "react";
import { useGetAzureMySqlServerRiskContext } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { EntityExternalConsoleLink } from "../../components";

export function useAzureMySqlServerHttpsOnlyDisabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AzureMySqlServerHttpsOnlyDisabledRisk;
    const serverModel = entityModelStore.useGet(risk.entityId) as Contract.AzureMySqlServerModel;

    const getAzureMySqlServerRiskContext = useGetAzureMySqlServerRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.compliance.useAzureMySqlServerHttpsOnlyDisabledRiskDefinition",
            () => ({
                description: "{{server}} is not enforcing SSL connections",
                sections: {
                    resolution: {
                        step1: "Before enforcing transport encryption, verify that applications use only HTTPS to access the database",
                        step2: {
                            [Contract.TypeNames.AzureMySqlSingleServer]: "Switch **Enforce SSL connection** to enabled and click **Save** to confirm",
                            [Contract.TypeNames.AzureMySqlFlexibleServer]: "Set the **require_secure_transport** server to **ON** and click **Save** to confirm"
                        }
                    }
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            server:
                <Entity
                    entityIdOrModel={serverModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={risk.entityId}
                key={risk.entityId}
                page={
                    serverModel.entity.typeName === Contract.TypeNames.AzureMySqlSingleServer
                        ? Contract.AzureConsoleEntityPage.ConnectionSecurity
                        : Contract.AzureConsoleEntityPage.ServerConfigurations}/>,
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2.translate(serverModel.entity.typeName)
        ],
        riskModel,
        () => {
            const serverRiskContext = getAzureMySqlServerRiskContext(serverModel);
            return [
                serverRiskContext.generalInformation,
                serverRiskContext.sensitive,
                serverRiskContext.inboundExternalAccessScope,
                serverRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        });
}