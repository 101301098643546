import { AddIcon, Dropdown, Loading } from "@infrastructure";
import _ from "lodash";
import React, { useState } from "react";
import { Contract, useTheme } from "../../../../../..";
import { useSetUdmObjectTableContext } from "../../../..";
import { UdmQueryHelper } from "../../../../utilities";
import { Popup } from "./components";

type UdmObjectFilterSelectorProps = {
    enableJoinOperator?: boolean;
    objectTypeName: string;
    onJoinAdded?: () => void;
    onOperatorAdded?: (operator: Contract.UdmQueryRuleGroupOperator) => void;
    onRuleAdd?: (rule: Contract.UdmQueryRuleBase) => void;
    parentObjectTypeName?: string;
};

export function UdmObjectFilterSelector({ enableJoinOperator = false, objectTypeName, onJoinAdded, onOperatorAdded, onRuleAdd, parentObjectTypeName }: UdmObjectFilterSelectorProps) {
    const [open, setOpen] = useState(false);
    const setUdmObjectTableContext = useSetUdmObjectTableContext();

    const theme = useTheme();
    return (
        <Dropdown
            justify="left"
            open={open}
            popoverElement={
                <Loading>
                    <Popup
                        enableJoinOperator={enableJoinOperator}
                        objectTypeName={objectTypeName}
                        parentObjectTypeName={parentObjectTypeName}
                        onGroupOperatorSelected={onOperatorAdded}
                        onJoinOperatorSelected={
                            () => {
                                onJoinAdded?.();
                                setOpen(false);
                            }}
                        onPropertySelected={
                            property => {
                                const rule = UdmQueryHelper.createRule(property);
                                setUdmObjectTableContext(
                                    context => ({
                                        ...context,
                                        newFiltersIds: _.concat(context.newFiltersIds, rule.id!)
                                    }));
                                onRuleAdd?.(rule);
                                setOpen(false);
                            }}/>
                </Loading>}
            popoverElementContainerSx={{
                minWidth: "500px",
                overflow: "hidden",
                padding: 0
            }}
            sx={{
                "&:hover": {
                    background: theme.palette.action.hover
                },
                background:
                    open
                        ? theme.palette.action.hover
                        : undefined,
                borderRadius: theme.spacing(0.75),
                padding: theme.spacing(0.5)
            }}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}>
            <AddIcon sx={{ fontSize: "14px" }}/>
        </Dropdown>);
}