import _ from "lodash";
import { useCallback, useMemo } from "react";
import { Contract, scopeNodeModelStore } from "../../../../../../../../common";

export function useProjectsCommonDefinitionData() {
    const scopeNodeMap = scopeNodeModelStore.useGetScopeNodeMap();
    const getScopeIds =
        useCallback(
            (projectModel: Contract.ProjectModel) =>
                _(projectModel.configuration.rules).
                    flatMap(rule => rule.scopeIds).
                    uniq().
                    value(),
            [scopeNodeMap]);

    return useMemo(
        () => ({
            getScopeIds
        }),
        [getScopeIds]);
}