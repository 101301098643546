import { useLocalization } from "@infrastructure";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import { ConfigurationController, Contract, ElasticsearchItemPageHelper, Entity, EntityController, PagedEntitySelector, RadioGroup, RadioGroupItem, ScopeHelper, ScopeLabel, scopeNodeModelStore, useScopeNavigationViewContext } from "../../../../../../../../../../common";

enum ResourceGroupValueType {
    Default = "Default",
    Inherit = "Inherit",
    Scope = "Scope"
}

export function AzureDiskSnapshotResourceGroup() {
    const { scopeNodeModel } = useScopeNavigationViewContext();
    const scopeNodeMap = scopeNodeModelStore.useGetActiveScopeNodeMap();

    function getAzureScopeConfigurationWorkloadAnalysis(scopeNodeModel: Contract.ScopeNodeModel) {
        if (ScopeHelper.isFolder(scopeNodeModel)) {
            return (scopeNodeModel.configuration as Contract.AzureFolderConfiguration).azureScopeSections.workloadAnalysis;
        }

        if (ScopeHelper.isTenant(scopeNodeModel)) {
            return (scopeNodeModel.configuration as Contract.AzureTenantConfiguration).azureScopeSections.workloadAnalysis;
        }
        return undefined;
    }

    function getResourceGroupValueType(defaultDiskSnapshotResourceGroup?: boolean) {
        return _.isNil(defaultDiskSnapshotResourceGroup)
            ? ResourceGroupValueType.Inherit
            : defaultDiskSnapshotResourceGroup
                ? ResourceGroupValueType.Default
                : ResourceGroupValueType.Scope;
    }

    const inheritEnable =
        useMemo(
            () => !ScopeHelper.isRootFolder(scopeNodeModel),
            [scopeNodeModel]);

    const [inheritedResourceGroupId, inheritedScopeId] =
        useMemo(
            () => {
                if (!inheritEnable) {
                    return [undefined, undefined];
                }

                const parentScopeIds = scopeNodeMap[scopeNodeModel.configuration.id].parentScopeIds;
                for (const scopeId of [...parentScopeIds]) {
                    const workloadAnalysis = (getAzureScopeConfigurationWorkloadAnalysis(scopeNodeMap[scopeId].scopeNodeModel));
                    if (!_.isNil(workloadAnalysis) &&
                        !_.isNil(workloadAnalysis.defaultDiskSnapshotResourceGroup)) {
                        return [workloadAnalysis.diskSnapshotResourceGroupId, scopeId];
                    }
                }

                return [undefined, undefined];
            },
            [inheritEnable, scopeNodeModel, scopeNodeMap]);


    const [resourceGroupId, setResourceGroupId] = useState(getAzureScopeConfigurationWorkloadAnalysis(scopeNodeModel)?.diskSnapshotResourceGroupId);
    const [loading, setLoading] = useState(false);
    async function updateDiskSnapshotResourceGroup(resourceGroupValueType: ResourceGroupValueType, entityId?: string) {
        if (resourceGroupValueType === ResourceGroupValueType.Scope && _.isNil(entityId)) {
            return;
        }

        setLoading(true);
        const defaultDiskSnapshotResourceGroup =
            resourceGroupValueType === ResourceGroupValueType.Inherit
                ? undefined
                : resourceGroupValueType === ResourceGroupValueType.Default;

        await ConfigurationController.updateAzureWorkloadAnalysis(
            new Contract.ConfigurationControllerUpdateAzureWorkloadAnalysisRequest(
                defaultDiskSnapshotResourceGroup,
                entityId,
                scopeNodeModel.configuration.id));

        await scopeNodeModelStore.notify(scopeNodeModel.configuration.id);
        setResourceGroupId(entityId);
        setLoading(false);
    }

    const localization =
        useLocalization(
            "views.customer.configuration.workloadAnalysis.virtualMachines.azureDiskSnapshotResourceGroup",
            () => ({
                description: "By default, snapshots are created in the same resource group as the one the disk belongs to.\nIn case you have a resource lock which prevents snapshot deletion, you can specify a dedicated resource group (without resource lock) which will be used for snapshot creation.",
                options: {
                    disk: "Disk Resource Group",
                    inherit: {
                        entity: "({{entity}})",
                        title: "Inherit"
                    },
                    resourceGroup: {
                        placeholder: "Choose Resource Group",
                        title: "Resource Group"
                    }
                },
                title: "Snapshots Resource Group"
            }));

    const theme = useTheme();
    const items =
        useMemo(
            () =>
                _<RadioGroupItem<ResourceGroupValueType>>([]).
                    concatIf(
                        inheritEnable,
                        {
                            label:
                                <Stack
                                    alignItems="center"
                                    direction="row"
                                    spacing={1}>
                                    <ScopeLabel
                                        inheritedScopeId={inheritedScopeId}
                                        text={localization.options.inherit.title()}
                                        translatedInheritedValue={
                                            _.isNil(inheritedResourceGroupId)
                                                ? localization.options.disk()
                                                : undefined}/>
                                    {!_.isNil(inheritedResourceGroupId) &&
                                        <Typography>
                                            {localization.options.inherit.entity({
                                                entity:
                                                    <Entity
                                                        entityIdOrModel={inheritedResourceGroupId!}
                                                        linkOptions={{ color: "inherit" }}
                                                        variant="text"/>
                                            })}
                                        </Typography>}
                                </Stack>,
                            value: ResourceGroupValueType.Inherit
                        }).
                    concat([
                        {
                            label: <ScopeLabel text={localization.options.disk()}/>,
                            value: ResourceGroupValueType.Default
                        },
                        {
                            children:
                                <Stack
                                    alignItems="center"
                                    direction="row"
                                    spacing={1}
                                    sx={{ width: theme.spacing(40) }}>
                                    <Box sx={{ flex: 1 }}>
                                        <PagedEntitySelector
                                            fullWidth={true}
                                            getEntityModelPage={
                                                ElasticsearchItemPageHelper.makePagedEntityMultiSelect(
                                                    async (itemNextPageSearchCursor, searchText) => {
                                                        const { entityModelPage } =
                                                            await EntityController.searchEntityModels(
                                                                new Contract.EntityControllerSearchEntityModelsTypeRequest(
                                                                    false,
                                                                    15,
                                                                    itemNextPageSearchCursor,
                                                                    undefined,
                                                                    searchText,
                                                                    false,
                                                                    Contract.TypeNames.AzureResourcesResourceGroup));
                                                        return entityModelPage;
                                                    })}
                                            placeholder={localization.options.resourceGroup.placeholder()}
                                            selectedEntityId={resourceGroupId}
                                            onSelectedEntityIdChanged={entityId => updateDiskSnapshotResourceGroup(ResourceGroupValueType.Scope, entityId)}/>
                                    </Box>
                                </Stack>,
                            label: localization.options.resourceGroup.title(),
                            value: ResourceGroupValueType.Scope
                        }]).
                    value(),
            [inheritEnable, inheritedResourceGroupId, inheritedScopeId, resourceGroupId]);

    return (
        <RadioGroup
            description={
                <Typography
                    sx={{
                        paddingTop: theme.spacing(1),
                        whiteSpace: "pre-wrap" 
                    }}>
                    {localization.description()}
                </Typography>}
            items={items}
            loading={loading}
            selectedValue={getResourceGroupValueType(getAzureScopeConfigurationWorkloadAnalysis(scopeNodeModel)!.defaultDiskSnapshotResourceGroup)}
            title={localization.title()}
            onChange={updateDiskSnapshotResourceGroup}/>);
}