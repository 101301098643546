import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function ExpandIcon(props: SvgIconProps) {
    return (
        <SvgIcon fill="none" height="24" viewBox="0 0 24 24" width="24" {...props}>
            <path
                clipRule="evenodd"
                d="M3 12C3 11.4477 3.36631 11 3.81818 11H20.1818C20.6337 11 21 11.4477 21 12C21 12.5523 20.6337 13 20.1818 13H3.81818C3.36631 13 3 12.5523 3 12Z"
                fillRule="evenodd"/>
            <path d="M8.288 19.0702C7.90374 19.4239 7.90374 20.0122 8.288 20.3659L11.7448 23.5479C12.11 23.8841 12.6896 23.8841 13.0548 23.5479L16.5116 20.3659C16.8959 20.0122 16.8959 19.4239 16.5116 19.0702C16.1464 18.734 15.5669 18.734 15.2016 19.0702L13.343 20.7811L13.343 15.9C13.343 15.3724 12.8889 15 12.3998 15C11.9107 15 11.4566 15.3724 11.4566 15.9L11.4566 20.7811L9.59798 19.0702C9.23275 18.734 8.65323 18.734 8.288 19.0702Z"/>
            <path d="M8.288 4.72959C7.90374 4.37588 7.90374 3.78759 8.288 3.43388L11.7448 0.251902C12.11 -0.0842936 12.6896 -0.0842935 13.0548 0.251903L16.5116 3.43388C16.8959 3.78759 16.8959 4.37588 16.5116 4.72959C16.1464 5.06579 15.5669 5.06579 15.2016 4.72959L13.343 3.01872L13.343 7.89976C13.343 8.42737 12.8889 8.79976 12.3998 8.79976C11.9107 8.79976 11.4566 8.42736 11.4566 7.89976L11.4566 3.01872L9.59798 4.72959C9.23275 5.06579 8.65323 5.06579 8.288 4.72959Z"/>
        </SvgIcon>);
}