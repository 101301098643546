﻿import { DataTableColumn, DataTableColumnRenderProps, Loading, optionalTableCell, useLocalization } from "@infrastructure";
import { Box, Button } from "@mui/material";
import _ from "lodash";
import React, { Fragment, useState } from "react";
import { Contract, Entity, entityModelStore, InlineTextViewer, Region, riskPolicyModelStore, SecretExclusionAction, UserHelper, useTheme } from "../../../../../../../../../../../../../../../../common";
import { useAwsEc2InstanceStatusTranslator } from "../../../../../../../../../../../../../Entities/hooks";
import { EntityExclusionMessage, Table } from "../../../../../../../../components";
import { UserData } from "../../../components";

type DetailsSectionProps = {
    riskModel: Contract.AwsEc2InstanceUserDataSecretExistsRiskModel;
};

export function DetailsSection({ riskModel }: DetailsSectionProps) {
    const instanceModels = entityModelStore.useGet(riskModel.risk.aggregatedEntityIds);
    const { riskPolicyConfiguration } = riskPolicyModelStore.useGet(riskModel.risk.policyId);
    const [exclude, setExclude] = useState<boolean>(false);

    const ec2InstanceStatusTranslator = useAwsEc2InstanceStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsEc2InstanceUserDataSecretExistsRiskDefinition.hooks.useAwsEc2InstanceUserDataSecretExistsRiskLaunchConfiguration.detailsSection",
            () => ({
                columns: {
                    displayName: "Instance",
                    regionSystemName: "Region",
                    status: "State",
                    userData: {
                        excludeAction: "Exclude Secret",
                        title: "User Data"
                    }
                },
                excludeAction: "Exclude Secret"
            }));

    const theme = useTheme();
    return {
        instances:
            <Fragment>
                <Box
                    sx={{
                        flex: 1,
                        width: "100%"
                    }}>
                    {exclude &&
                        <SecretExclusionAction
                            riskPolicyConfiguration={riskPolicyConfiguration}
                            onExcludeChanged={setExclude}/>}
                    <EntityExclusionMessage/>
                    <Table
                        fetchItems={() => instanceModels}
                        getItemId={(instanceModel: Contract.AwsEc2InstanceModel) => instanceModel.id}>
                        <DataTableColumn
                            id={DetailsSectionColumnId.DisplayName}
                            render={
                                ({ item }: DataTableColumnRenderProps<Contract.AwsEc2InstanceModel>) =>
                                    <Entity
                                        entityIdOrModel={item}
                                        variant="iconTextTenant"/>}
                            title={localization.columns.displayName()}/>
                        <DataTableColumn
                            id={DetailsSectionColumnId.UserData}
                            render={
                                optionalTableCell<Contract.AwsEc2InstanceModel>(
                                    instanceModel =>
                                        instanceModel.unknown &&
                                        !_.isEmpty(riskModel.risk.instanceIdToUserDataSecretExistsTextBlockMap)
                                            ? undefined
                                            : <InlineTextViewer
                                                actionsElement={
                                                    UserHelper.hasAnyScopePermissions(riskModel.risk.scopeIds, Contract.IdentityPermission.SecurityWrite)
                                                        ? <Button
                                                            sx={{
                                                                "&:hover": {
                                                                    backgroundColor: theme.palette.textEditor.background
                                                                },
                                                                backgroundColor: theme.palette.textEditor.background,
                                                                margin: theme.spacing(1),
                                                                padding: theme.spacing(1, 3)
                                                            }}
                                                            variant="outlined"
                                                            onClick={() => setExclude(true)}>
                                                            {localization.columns.userData.excludeAction()}
                                                        </Button>
                                                        : undefined}
                                                actionsElementSx={{ backgroundColor: "unset" }}
                                                dialogTitle={localization.columns.userData.title()}
                                                highlightedLines={riskModel.risk.instanceIdToUserDataSecretExistsTextBlockMap![instanceModel.id].highlightedLines}
                                                startLine={riskModel.risk.instanceIdToUserDataSecretExistsTextBlockMap![instanceModel.id].startLine}
                                                text={riskModel.risk.instanceIdToUserDataSecretExistsTextBlockMap![instanceModel.id].text}
                                                title={localization.columns.userData.title()}/>)}
                            title={localization.columns.userData.title()}/>
                        <DataTableColumn
                            id={DetailsSectionColumnId.Status}
                            render={
                                optionalTableCell<Contract.AwsEc2InstanceModel>(
                                    instanceModel =>
                                        instanceModel.unknown
                                            ? undefined
                                            : ec2InstanceStatusTranslator((instanceModel.entity as Contract.AwsEc2Instance).status))}
                            title={localization.columns.status()}/>
                        <DataTableColumn
                            id={DetailsSectionColumnId.RegionSystemName}
                            render={
                                optionalTableCell<Contract.AwsEc2InstanceModel>(
                                    instanceModel =>
                                        <Region
                                            regionId={instanceModel.entity.regionId}
                                            variant="iconText"/>)}
                            title={localization.columns.regionSystemName()}/>
                    </Table>
                </Box>
            </Fragment>,
        userData:
            <Fragment>
                <Box
                    sx={{
                        flex: 1,
                        width: "100%"
                    }}>
                    <Loading>
                        <UserData
                            riskModel={riskModel}
                            textBlock={riskModel.risk.launchConfigurationUserDataSecretExistsTextBlock!}/>
                    </Loading>
                </Box>
            </Fragment>
    };
}

enum DetailsSectionColumnId {
    Actions = "actions",
    DisplayName = "displayName",
    RegionSystemName = "regionSystemName",
    Status = "status",
    UserData = "userData"
}