﻿import { Dropdown, EmptyMessageText, SearchList, useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, TopItems, TypeHelper, useRiskPolicyTranslator, useTheme } from "../../../../../../../../common";
import { RiskPolicyResultCount } from "./components";

export type RiskPolicyDataChartProps = {
    riskPolicyDatas: Contract.KubernetesClusterAdmissionControllerEventRiskPolicyData[];
};

export function RiskPolicyDataChart({ riskPolicyDatas }: RiskPolicyDataChartProps) {
    const [orderedRiskPolicyResults, riskPolicyResultToRiskPolicyDatasMap] =
        useMemo(
            () => {
                const riskPolicyResultToRiskPolicyDatasMap =
                    _.groupBy(
                        riskPolicyDatas,
                        riskPolicyData => riskPolicyData.result);
                const orderedRiskPolicyResults =
                    _(riskPolicyResultToRiskPolicyDatasMap).
                        keys().
                        orderBy(
                            riskPolicyResult =>
                                TypeHelper.getEnumValue(
                                    Contract.TypeNames.KubernetesAdmissionControllerRiskPolicyResult,
                                    riskPolicyResult),
                            "desc").
                        value();

                return [orderedRiskPolicyResults, riskPolicyResultToRiskPolicyDatasMap];
            },
            [riskPolicyDatas]);

    const riskPolicyTranslator = useRiskPolicyTranslator();
    const localization =
        useLocalization(
            "views.customer.kubernetes.kubernetesClusterAdmissionControllerEvents.riskPolicyDataChart",
            () => ({
                empty: "No Matching Policies"
            }));

    const theme = useTheme();
    return (
        <TopItems
            items={orderedRiskPolicyResults}
            itemSizeForLimitCalculations={40}
            orderVariant="leftToRight"
            variant="dynamic">
            {riskPolicyResult =>
                <Dropdown
                    key={riskPolicyResult}
                    popoverElement={
                        () =>
                            <SearchList
                                disablePadding={true}
                                emptyMessageText={new EmptyMessageText(localization.empty())}
                                itemOptions={{
                                    getSortValue: (item: Contract.KubernetesClusterAdmissionControllerEventRiskPolicyData) => riskPolicyTranslator(item.configurationTypeName).title,
                                    render:
                                        (item: Contract.KubernetesClusterAdmissionControllerEventRiskPolicyData) =>
                                            <Typography noWrap={true}>
                                                {riskPolicyTranslator(item.configurationTypeName).title}
                                            </Typography>,
                                    spacing: 1
                                }}
                                items={riskPolicyResultToRiskPolicyDatasMap[riskPolicyResult]}
                                searchOptions={{ enabled: true }}
                                sx={{
                                    maxHeight: theme.spacing(50),
                                    minWidth: theme.spacing(20),
                                    overflow: "hidden auto",
                                    padding: theme.spacing(0, 1)
                                }}
                                variant="dropdown"/>}
                    popoverElementContainerSx={{ padding: 0 }}>
                    {_open =>
                        <RiskPolicyResultCount
                            count={_.size(riskPolicyResultToRiskPolicyDatasMap[riskPolicyResult])}
                            key={riskPolicyResult}
                            riskPolicyResult={riskPolicyResult}/>}
                </Dropdown>}
        </TopItems>);
}