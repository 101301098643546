import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, Entity, entityModelStore, InlineEntities } from "../../../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../../../tenants";
import { useCommonSectionsAndDescriptionDefinition } from "../../../../../useCommonSectionsAndDescriptionDefinition";
import { useGetAwsRdsNeptuneClusterRiskContext } from "../../../contexts";
import { useGetAwsRdsNeptuneClusterSnapshotEncryptionDisabledRiskClusterContext } from "./useGetAwsRdsNeptuneClusterSnapshotEncryptionDisabledRiskClusterContext";

export function useAwsRdsNeptuneClusterSnapshotEncryptionDisabledRiskClusterDefinition(riskModel: Contract.AwsRdsNeptuneClusterSnapshotEncryptionDisabledRiskModel) {
    const risk = riskModel.risk;
    const clusterModel = entityModelStore.useGet(riskModel.risk.entityId) as Contract.AwsRdsNeptuneClusterModel;
    const cluster = clusterModel.entity as Contract.AwsRdsNeptuneCluster;

    const getAwsRdsNeptuneClusterRiskContext = useGetAwsRdsNeptuneClusterRiskContext();
    const getAwsRdsNeptuneSnapshotEncryptionDisabledRiskClusterContext = useGetAwsRdsNeptuneClusterSnapshotEncryptionDisabledRiskClusterContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsRdsNeptuneClusterSnapshotEncryptionDisabledRiskDefinition.hooks.useAwsRdsNeptuneClusterSnapshotEncryptionDisabledRiskClusterDefinition",
            () => ({
                description: {
                    many: "{{cluster}} has {{snapshots}} that are not configured with KMS encryption",
                    none: "{{cluster}} is not encrypted using KMS",
                    single: "{{cluster}} has {{snapshots}} that is not configured with KMS encryption"
                },
                sections: {
                    resolution: {
                        step1: "Neptune cluster snapshots can only be encrypted at the time of creation. It is not possible to enable encryption for a snapshot after it has been created. To address this, you must restore an unencrypted snapshot to a new cluster with encryption enabled",
                        step2: "On the **Snapshots** page, select the unencrypted cluster snapshot and click **Actions**, and then **Restore snapshot**",
                        step3: "At the bottom of the page, choose **Show advanced settings**",
                        step4: "When restoring a snapshot your data is encrypted by default with a key that AWS owns and manages for you. To choose a different key, customize your encryption settings",
                        step5: "Click **Restore snapshot**",
                        step6: "On the **Snapshots** page, click **Create snapshot**",
                        step7: "Select the newly restored cluster and click **Create DB Snapshot**",
                        step8: "Before deleting any cluster or snapshot, ensure that your cluster is properly configured to avoid downtime",
                        step9: "Delete the old, unencrypted Neptune cluster snapshots"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        (_.isEmpty(risk.aggregatedEntityIds)
            ? localization.description.none
            : risk.aggregatedEntityIds.length === 1
                ? localization.description.single
                : localization.description.many)({
            cluster:
                <Entity
                    entityIdOrModel={clusterModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>,
            snapshots:
                <InlineEntities
                    entityIdsOrModels={risk.aggregatedEntityIds}
                    entityTypeName={Contract.TypeNames.AwsRdsNeptuneClusterSnapshot}
                    variant="itemCountAndType"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.Neptune,
                AwsConsoleUrlBuilder.getRdsNeptuneClusterUrl(cluster)),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4(),
            localization.sections.resolution.step5(),
            localization.sections.resolution.step6(),
            localization.sections.resolution.step7(),
            localization.sections.resolution.step8(),
            localization.sections.resolution.step9()
        ],
        riskModel,
        () => {
            const clusterContextItems = getAwsRdsNeptuneClusterRiskContext(clusterModel);
            const snapshotEncryptionDisabledRiskClusterContextItems = getAwsRdsNeptuneSnapshotEncryptionDisabledRiskClusterContext(riskModel);
            return [
                clusterContextItems.generalInformation,
                snapshotEncryptionDisabledRiskClusterContextItems.awsBackup,
                snapshotEncryptionDisabledRiskClusterContextItems.manual,
                snapshotEncryptionDisabledRiskClusterContextItems.automatic,
                clusterContextItems.size,
                snapshotEncryptionDisabledRiskClusterContextItems.internal,
                snapshotEncryptionDisabledRiskClusterContextItems.snapshotIdsEmpty,
                clusterContextItems.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        });
}