import { useLocalization } from "@infrastructure";
import React, { useCallback } from "react";
import { RiskPolicyConfigurationDefinitionData } from "..";
import { Contract, CustomerConsoleAppUrlHelper, RiskPoliciesType } from "../../../../../../../common";
import { TimeFrame, ViewOnlyTimeFrame } from "../components";

export function useAwsIamUserUnusedAccessKeysEnabledRiskPolicyConfigurationDefinition(riskPoliciesType: RiskPoliciesType): RiskPolicyConfigurationDefinitionData {
    const localization =
        useLocalization(
            "views.customer.riskPolicies.hooks.useConfigurationDefinition.hooks.useAwsIamUserUnusedAccessKeysEnabledRiskPolicyConfigurationDefinition",
            () => ({
                description: "By default, Tenable Cloud Security uses a 45 days time frame to analyze this policy before opening findings.",
                title: "Inactivity Period"
            }));

    const getTimeFrame =
        useCallback(
            (riskPolicyConfiguration: Contract.RiskPolicyConfiguration) =>
                (riskPolicyConfiguration as Contract.AwsIamUserUnusedAccessKeysEnabledRiskPolicyConfiguration).accessKeyUsageTimeFrame,
            []);

    return {
        generalAdditionalItems: [{
            configurationElement:
                <TimeFrame
                    description={localization.description()}
                    documentationUrl={CustomerConsoleAppUrlHelper.getDocsViewAndManagePoliciesRelativeUrl()}
                    getTimeFrame={getTimeFrame}
                    key={`${Contract.TypeNames.AwsIamUserUnusedAccessKeysEnabledRiskPolicyConfiguration}-accessKeyUsageTimeFrame`}
                    riskPoliciesType={riskPoliciesType}
                    setTimeFrame={
                        (riskPolicyConfiguration, accessKeyUsageTimeFrame) => {
                            (riskPolicyConfiguration as Contract.AwsIamUserUnusedAccessKeysEnabledRiskPolicyConfiguration).accessKeyUsageTimeFrame = accessKeyUsageTimeFrame;
                            return undefined;
                        }}
                    title={localization.title()}/>,
            viewItem:
                {
                    getValue:
                        (_, item, scopeId) =>
                            <ViewOnlyTimeFrame
                                getTimeFrame={getTimeFrame}
                                item={item}
                                riskPoliciesType={riskPoliciesType}
                                scopeId={scopeId}/>,
                    title: localization.title()
                }
        }]
    };
}