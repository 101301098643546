import { NoneIcon } from "@infrastructure";
import { Stack } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, entityModelStore, InlineEntities, RiskPolicyItem } from "../../../../../../../common";
import { useCloudRiskPolicyItemEntityExclusion } from "../../../hooks";

type EntityExclusionsProps = {
    item: RiskPolicyItem;
    scopeId: string;
};

export function EntityExclusions({ item, scopeId }: EntityExclusionsProps) {
    const { defaultExcludedEntityTypeNameToIdToExclusionMap } = useCloudRiskPolicyItemEntityExclusion(item, scopeId);
    const allExcludedEntitiesIds =
        useMemo(
            () =>
                _.flatMap(
                    defaultExcludedEntityTypeNameToIdToExclusionMap,
                    entities => _.keys(entities)),
            [defaultExcludedEntityTypeNameToIdToExclusionMap]);
    const entityModels = entityModelStore.useGet(allExcludedEntitiesIds);

    return (
        <Stack spacing={1}>
            {entityModels.length === 0
                ? <NoneIcon sx={{ fontSize: "18px" }}/>
                : <InlineEntities
                    entityIdsOrModels={entityModels}
                    entityTypeName={Contract.TypeNames.Entity}
                    entityVariant="iconTextTypeTenant"
                    variant="itemCountAndType"/>}
        </Stack>);
}