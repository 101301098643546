import _, { Dictionary } from "lodash";
import { useAwsCommonEc2ResourceDefinition, useAwsCommonNetworkedResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, ItemSelectionHelper, TimeRangeHelper } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";

export function useAwsEc2NetworkAclDefinition(definitionData: DefinitionData) {
    const commonEc2ResourceDefinition = useAwsCommonEc2ResourceDefinition(definitionData);
    const commonNetworkedResourceDefinition = useAwsCommonNetworkedResourceDefinition(definitionData);

    return new EntityTableDefinition(
        _.filter(
            [
                commonEc2ResourceDefinition.columns.rawIdColumn,
                commonEc2ResourceDefinition.columns.tenantColumn,
                commonEc2ResourceDefinition.columns.creationTimeColumn,
                commonEc2ResourceDefinition.columns.creatorIdentityCsvColumn,
                commonEc2ResourceDefinition.columns.creatorOriginatorEntityCsvColumn,
                commonNetworkedResourceDefinition.columns.getVpcsColumn(Contract.EntityModelProperty.AwsEc2NetworkAclVpc),
                commonEc2ResourceDefinition.columns.regionColumn,
                commonEc2ResourceDefinition.columns.regionLocationColumn,
                commonEc2ResourceDefinition.columns.tagsColumn,
                commonEc2ResourceDefinition.columns.attributesColumn,
                commonEc2ResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
                commonEc2ResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
                commonEc2ResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
            ]),
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAwsEc2NetworkAclRequest(
                new Contract.EntityControllerGetEntityModelPageAwsEc2NetworkAclRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEc2ResourceRawId]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEc2NetworkAclVpc])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags]))));
}