﻿import { EmptyMessageText, ItemSelector, Loading, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useState } from "react";
import { VulnerabilitiesCell } from "../../../../../../..";
import { useGcpCommonEncryptedResourceInfoElements, useGcpCommonServiceAccountOriginatorScopeResourceInfoElements } from "../..";
import { Contract, entityModelStore, InfoCard, InfoItem, KeyValueTable, KeyValueTableItem } from "../../../../../../../../../../../../common";
import { EntitiesInfoItem } from "../../../../../../components";

type RevisionsProps = {
    revisionIds: string[];
};

export function Revisions({ revisionIds }: RevisionsProps) {
    const revisionModels = entityModelStore.useGet(revisionIds) as Contract.GcpCloudRunServiceRevisionModel[];
    const revisionModelMap =
        _.keyBy(
            revisionModels,
            revisionModel => revisionModel.id);
    const latestRevisionId = revisionIds[0];
    const [selectedRevisionModel, setSelectedRevisionModel] = useState(revisionModels[0]);

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpCloudRunServiceDefinition.revisions",
            () => ({
                items: {
                    cloudSqlInstanceIdReference: "Cloud SQL Instance",
                    containerImage: "Container Image",
                    containerImageVulnerabilities: "Vulnerabilities",
                    containerPort: "Container Port",
                    creationTime: "Creation Time",
                    deployer: "Deployed By",
                    environmentVariableNameToValueMap: {
                        empty: "No Environment Variables",
                        title: "Environment Variables"
                    },
                    maxInstances: "Max Instances",
                    secretIdReferences: "Secrets",
                    serviceAccountIdReference: "Service Account",
                    trafficPercent: "Traffic(%)"
                },
                latest: "Latest"
            }));

    const selectedRevision = selectedRevisionModel.entity as Contract.GcpCloudRunServiceRevision;
    const commonEncryptedResourceInfoElements = useGcpCommonEncryptedResourceInfoElements(selectedRevisionModel);
    const commonServiceAccountOriginatorScopeResourceInfoElements = useGcpCommonServiceAccountOriginatorScopeResourceInfoElements(selectedRevisionModel);
    return (
        <InfoCard>
            <ItemSelector
                dense={true}
                fullWidth={true}
                items={_.keys(revisionModelMap)}
                selectedItem={selectedRevision.id}
                sorted={false}
                onSelectedItemChanged={revisionModelId => setSelectedRevisionModel(revisionModelMap[revisionModelId])}>
                {revisionModelId =>
                    revisionModelId === latestRevisionId
                        ? localization.latest()
                        : revisionModelMap[revisionModelId].entity.displayName}
            </ItemSelector>
            <Loading>
                <InfoCard columns={true}>
                    {!_.isNil(selectedRevisionModel.deployerIdentityIdReference)
                        ? <EntitiesInfoItem
                            entityIdsOrModels={selectedRevisionModel.deployerIdentityIdReference}
                            entityTypeName={Contract.TypeNames.IGciIdentity}
                            title={localization.items.deployer()}/>
                        : <InfoItem
                            title={localization.items.deployer()}
                            value={selectedRevision.deployerRawIdentifier}/>}
                    <EntitiesInfoItem
                        entityIdsOrModels={selectedRevisionModel.containerImageIdReferences}
                        entityTypeName={Contract.TypeNames.IContainerImage}
                        title={localization.items.containerImage()}/>
                    <InfoItem
                        title={localization.items.containerImageVulnerabilities()}
                        value={
                            _.isEmpty(selectedRevisionModel.containerImageVulnerabilities)
                                ? undefined
                                : <VulnerabilitiesCell
                                    containerImageScanStatus={selectedRevisionModel.containerImageScanStatus}
                                    variant="initialAndCount"
                                    vulnerabilities={selectedRevisionModel.containerImageVulnerabilities}/>}/>
                    <InfoItem
                        title={localization.items.containerPort()}
                        value={
                            _.isNil(selectedRevision.containerPort)
                                ? undefined
                                : selectedRevision.containerPort}/>
                    <EntitiesInfoItem
                        entityIdsOrModels={selectedRevisionModel.serviceAccountIdReference}
                        entityTypeName={Contract.TypeNames.GcpIamServiceAccount}
                        title={localization.items.serviceAccountIdReference()}/>
                    {commonEncryptedResourceInfoElements.encryptionExists}
                    {commonEncryptedResourceInfoElements.kmsEncryptionKeys}
                    {commonServiceAccountOriginatorScopeResourceInfoElements.serviceAccount}
                    <InfoItem
                        title={localization.items.trafficPercent()}
                        value={selectedRevision.trafficPercent}/>
                    <InfoItem
                        title={localization.items.maxInstances()}
                        value={
                            _.isNil(selectedRevision.maxInstances)
                                ? undefined
                                : selectedRevision.maxInstances}/>
                    <EntitiesInfoItem
                        entityIdsOrModels={selectedRevisionModel.secretIdReferences}
                        entityTypeName={Contract.TypeNames.GcpSecretManagerSecret}
                        title={localization.items.secretIdReferences()}/>
                    <EntitiesInfoItem
                        entityIdsOrModels={selectedRevisionModel?.cloudSqlInstanceIdReference}
                        entityTypeName={Contract.TypeNames.GcpSqlInstance}
                        title={localization.items.cloudSqlInstanceIdReference()}/>
                </InfoCard>
                <InfoCard title={localization.items.environmentVariableNameToValueMap.title()}>
                    <KeyValueTable
                        emptyMessageText={new EmptyMessageText(localization.items.environmentVariableNameToValueMap.empty())}
                        items={
                            _.map(
                                selectedRevision.environmentVariableNameToValueMap,
                                (value, key) =>
                                    new KeyValueTableItem(
                                        key,
                                        value))}/>
                </InfoCard>
            </Loading>
        </InfoCard>);
}