import { ActionMenuItem, DataTableColumnRenderProps, Menu, NoneIcon, useLocalization } from "@infrastructure";
import { Box, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { Fragment, useMemo, useState } from "react";
import { useRiskContext } from "../../../..";
import { Contract, entityModelStore, riskPolicyModelStore, RiskType, RiskTypeMetadataModelHelper, TypeHelper, useEntityTypeNameTranslator, UserHelper, useTheme } from "../../../../../../../../../common";
import { ExclusionHelper } from "../../../../../utilities";
import { RiskDetailsSectionItem } from "../../../utilities";
import { ExcludeEntityDialog } from "./ExcludeEntityDialog";

export function EntityExclusionActionCell({ item }: DataTableColumnRenderProps<RiskDetailsSectionItem>) {
    const { excludedEntityIds, excludedRiskItemEntityIds, reloadRiskModel, riskModel } = useRiskContext();
    const { riskPolicyConfiguration } = riskPolicyModelStore.useGet(riskModel.risk.policyId);

    const riskTypeMetadataModel = RiskTypeMetadataModelHelper.get(riskModel.risk.typeName);

    const exclusionEntityIds =
        useMemo(
            () => {
                const exclusionEntityTypeNameToIdsMap =
                    ExclusionHelper.getExclusionEntityTypeNameToIdsMap(
                        [item.riskItem],
                        riskTypeMetadataModel);
                return _(exclusionEntityTypeNameToIdsMap).
                    flatMap(exclusionEntityIds => exclusionEntityIds!).
                    value();
            },
            [item]);
    const exclusionEntityModels = entityModelStore.useGet(exclusionEntityIds);
    const [exclusionEntityModel, setExclusionEntityModel] = useState<Contract.EntityModel | undefined>();

    const items =
        useMemo(
            () =>
                _(exclusionEntityModels).
                    filter(
                        exclusionEntityModel =>
                            !exclusionEntityModel.unknown &&
                            !exclusionEntityModel.systemDeleted).
                    map(
                        exclusionEntityModel =>
                            new EntityExclusionActionCellItem(
                                _.includes(
                                    excludedEntityIds,
                                    exclusionEntityModel.entity.id),
                                exclusionEntityModel,
                                _.find(
                                    riskTypeMetadataModel.exclusionEntityTypeNames,
                                    entityTypeName => TypeHelper.extendOrImplement(exclusionEntityModel.entity.typeName, entityTypeName))!)).
                    value(),
            [excludedEntityIds, item, riskPolicyConfiguration]);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.entityExclusionActionCell",
            () => ({
                actions: {
                    exclude: {
                        badge: "Excluded",
                        error: "Failed to exclude",
                        title: "Exclude {{translatedEntityTypeName}} **{{entity}}**"
                    }
                }
            }));

    const theme = useTheme();
    return riskModel.risk.status !== Contract.RiskStatus.Open
        ? <Fragment/>
        : _.isEmpty(items)
            ? <NoneIcon sx={{ fontSize: "18px" }}/>
            : <Fragment>
                {exclusionEntityModel &&
                    <ExcludeEntityDialog
                        entityModel={exclusionEntityModel}
                        reloadRiskModel={reloadRiskModel}
                        riskId={riskModel.id}
                        riskPolicyConfiguration={riskPolicyConfiguration}
                        riskType={RiskType.Cloud}
                        onClose={() => setExclusionEntityModel(undefined)}/>}
                <Stack
                    alignItems="center"
                    direction="row"
                    justifyContent="flex-end"
                    spacing={1}>
                    {_.includes(
                        excludedRiskItemEntityIds,
                        item.riskItem.entityId) && (
                        <Typography
                            sx={{
                                border: theme.border.primary,
                                borderRadius: theme.spacing(0.75),
                                color: theme.palette.text.secondary,
                                padding: theme.spacing(0.5, 1)
                            }}>
                            {localization.actions.exclude.badge()}
                        </Typography>)}
                    <Box
                        sx={{
                            visibility:
                                UserHelper.hasAnyScopePermissions(riskModel.risk.scopeIds, Contract.IdentityPermission.SecurityWrite)
                                    ? undefined
                                    : "hidden"
                        }}>
                        <Menu
                            disabled={item.riskItem.excluded}
                            itemsOrGetItems={
                                _.map(
                                    items,
                                    item =>
                                        new ActionMenuItem(
                                            () => setExclusionEntityModel(item.exclusionEntityModel),
                                            localization.actions.exclude.title({
                                                entity: item.exclusionEntityModel.entity.displayName,
                                                translatedEntityTypeName:
                                                    entityTypeNameTranslator(
                                                        item.exclusionEntityTypeName,
                                                        {
                                                            includeServiceName: false,
                                                            variant: "text"
                                                        })
                                            }),
                                            { disabled: item.excluded }
                                        ))}/>
                    </Box>
                </Stack>
            </Fragment>;
}

class EntityExclusionActionCellItem {
    constructor(
        public excluded: boolean,
        public exclusionEntityModel: Contract.EntityModel,
        public exclusionEntityTypeName: string) {
    }
}