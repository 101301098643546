import { useLocalization } from "@infrastructure";

export function useEnabledTranslator() {
    const localization =
        useLocalization(
            "views.customer.riskPolicies.hooks.useEnabledTranslator",
            () => ({
                disabled: "Disabled",
                enabled: "Enabled"
            }));

    return (enabled?: boolean) =>
        enabled
            ? localization.enabled()
            : localization.disabled();
}