﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, Entity, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useEntityRiskContext } from "../../../useEntityRiskContext";

export function useGetGciDirectoryEntityRiskContext() {
    return useMemo(
        () => useGciDirectoryEntityRiskContext,
        []);
}

function useGciDirectoryEntityRiskContext(entityModel: Contract.GciDirectoryEntityModel) {
    const entityRiskContext = useEntityRiskContext(entityModel);
    const entityTypeNameTranslator = useEntityTypeNameTranslator();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gci.hooks.contexts.useGetGciDirectoryEntityRiskContext",
            () => ({
                creationTime: "*capitalize*{{entityTypeName}}** {{entity}} was created on {{creationTime | TimeFormatter.shortDate}}"
            }));

    return {
        ...entityRiskContext,
        generalInformation:
            new RiskDefinitionContextItem(
                _.isNil((entityModel.entity as Contract.GciDirectoryEntity).creationTime)
                    ? undefined
                    : localization.creationTime({
                        creationTime: (entityModel.entity as Contract.GciDirectoryEntity).creationTime,
                        entity:
                            <Entity
                                entityIdOrModel={entityModel}
                                variant="text"/>,
                        entityTypeName:
                            entityTypeNameTranslator(
                                entityModel.entity.typeName,
                                {
                                    includeServiceName: false,
                                    variant: "text"
                                })
                    }))
    };
}