import { Checkbox as MuiCheckbox, CheckboxProps } from "@mui/material";
import React, { memo } from "react";
import { CheckedIcon, DisabledCheckedIcon, DisabledIndeterminateIcon, DisabledUncheckedIcon, IndeterminateIcon, UncheckedIcon } from "./icons";

const CheckboxMemo = memo(Checkbox);
export { CheckboxMemo as Checkbox };

function Checkbox({ disabled, ...props }: CheckboxProps) {
    return (
        <MuiCheckbox
            checkedIcon={
                disabled
                    ? <DisabledCheckedIcon/>
                    : <CheckedIcon/>}
            disabled={disabled}
            disableRipple={true}
            icon={
                disabled
                    ? <DisabledUncheckedIcon/>
                    : <UncheckedIcon/>}
            indeterminateIcon={
                disabled
                    ? <DisabledIndeterminateIcon/>
                    : <IndeterminateIcon/>}
            {...props}/>);
}