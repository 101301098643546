import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useOciNetworkingVcnStatusTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useOciNetworkingVcnStatusTranslator",
            () => ({
                [Contract.TypeNames.OciNetworkingVcnStatus]: {
                    [Contract.OciNetworkingVcnStatus.Available]: "Available",
                    [Contract.OciNetworkingVcnStatus.Provisioning]: "Provisioning",
                    [Contract.OciNetworkingVcnStatus.Terminated]: "Terminated",
                    [Contract.OciNetworkingVcnStatus.Terminating]: "Terminating",
                    [Contract.OciNetworkingVcnStatus.Updating]: "Updating"
                }
            }));
    return (status: Contract.OciNetworkingVcnStatus) =>
        localization[Contract.TypeNames.OciNetworkingVcnStatus][status]();
}