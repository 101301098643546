import { Link, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { AuditEventDefinitionContext, AuditEventDefinitionDetailItem } from "..";
import { Contract, Entity, EntityModelHelper, InlineEntities, RiskHelper, RiskTypeMetadataModelHelper, TypeHelper, useEntityTypeNameTranslator, useRiskPolicyTitleTranslator } from "../../../../../../../../../common";
import { useCommonAuditEventDetailsItems } from "./useCommonAuditEventDetailsItems";

export function useRiskAuditEventDetailsItems(context: AuditEventDefinitionContext) {
    const commonAuditEventDetailsItems = useCommonAuditEventDetailsItems(context);
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const riskPolicyTitleTranslator = useRiskPolicyTitleTranslator();
    const localization =
        useLocalization(
            "views.customer.auditEvents.profile.hooks.useDefinition.hooks.useRiskAuditEventDetailsItems",
            () => ({
                riskDeletedItem: "Finding has been deleted",
                riskedEntitiesItem: "Resources",
                riskId: "ID",
                riskLink: {
                    title: "Finding"
                },
                riskPolicyType: "Title"
            }));

    const riskId =
        TypeHelper.extendOrImplement(context.auditEventModel.auditEvent.typeName, Contract.TypeNames.RiskAuditEvent)
            ? (context.auditEventModel.auditEvent as Contract.RiskAuditEvent).riskId
            : (context.auditEventModel.auditEvent as Contract.RisksAuditEvent).riskIds[0];
    const riskModel = context.riskModelMap[riskId];
    if (_.isNil(riskModel)) {
        return {
            ...commonAuditEventDetailsItems,
            riskDeletedItem:
                new AuditEventDefinitionDetailItem(
                    localization.riskPolicyType(),
                    localization.riskDeletedItem()),
            riskedEntitiesItem: undefined!,
            riskIdItem:
                new AuditEventDefinitionDetailItem(
                    localization.riskId(),
                    riskId),
            riskLinkItem: undefined,
            riskPolicyTypeItem: undefined
        };
    }

    const risk = riskModel.risk;
    const firstRiskedEntityModel = context.entityModelMap[risk.riskedEntityIds[0]];
    return {
        ...commonAuditEventDetailsItems,
        riskDeletedItem: undefined,
        riskedEntitiesItem:
            risk.riskedEntityIds.length === 1
                ? new AuditEventDefinitionDetailItem(
                    entityTypeNameTranslator(firstRiskedEntityModel.entity.typeName),
                    firstRiskedEntityModel.entity.displayName,
                    <Entity
                        entityIdOrModel={firstRiskedEntityModel}
                        variant="iconText"/>)
                : new AuditEventDefinitionDetailItem(
                    localization.riskedEntitiesItem(),
                    entityTypeNameTranslator(
                        EntityModelHelper.getCommonTypeName(
                            _.map(
                                risk.riskedEntityIds,
                                entityId => context.entityModelMap[entityId])) ??
                        Contract.TypeNames.Entity,
                        {
                            count: risk.riskedEntityIds.length,
                            includeCount: true
                        }),
                    <InlineEntities
                        entityIdsOrModels={risk.riskedEntityIds}
                        entityTypeName={RiskTypeMetadataModelHelper.get(riskModel.risk.typeName).riskedEntityTypeName}
                        variant="itemPlusItemCount"/>),
        riskIdItem: undefined,
        riskLinkItem:
            new AuditEventDefinitionDetailItem(
                localization.riskLink.title(),
                RiskHelper.getRiskProfileUrlByRiskType(risk),
                <Link urlOrGetUrl={RiskHelper.getRiskProfileRelativeUrlByRiskType(risk)}>
                    {riskPolicyTitleTranslator(RiskHelper.getBuiltInRiskPolicyConfigurationTypeNameOrCustomRiskPolicyId(risk))}
                </Link>),
        riskPolicyTypeItem:
            new AuditEventDefinitionDetailItem(
                localization.riskPolicyType(),
                riskPolicyTitleTranslator(RiskHelper.getBuiltInRiskPolicyConfigurationTypeNameOrCustomRiskPolicyId(risk)))
    };
}