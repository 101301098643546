import { useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import React from "react";
import { Contract } from "../../../../../../..";
import { AccessDefinitionEntitiesPermissionActionsPermittersProps } from "../../..";
import { InlinePermitterPolicies } from "./InlinePermitterPolicies";

export function Permitters({ actionData, entityTypeName }: AccessDefinitionEntitiesPermissionActionsPermittersProps) {
    const localization =
        useLocalization(
            "common.access.hooks.useDefinition.hooks.useAzureDefinition.permitters",
            () => ({
                permitters: "Granted through {{permitterEntities}}"
            }));
    const { permissionPathIdentifiers } = actionData as Contract.AzureAccessListPermissionActionData;
    return (
        <Typography>
            {localization.permitters({
                permitterEntities:
                    <InlinePermitterPolicies
                        entityTypeName={entityTypeName}
                        permissionPathIdentifiers={permissionPathIdentifiers}/>
            })}
        </Typography>);
}