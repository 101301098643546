import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../../../controllers";

export function useOperatorTranslator() {
    const localization =
        useLocalization(
            "common.udmObjectTable.udmQueryBuilder.udmObjectPropertyFilter.hooks.useOperatorTranslator",
            () => ({
                [Contract.TypeNames.UdmQueryRuleOperator]: {
                    [Contract.UdmQueryRuleOperator.Between]: {
                        text: "between",
                        title: "Between"
                    },
                    [Contract.UdmQueryRuleOperator.StringContains]: {
                        text: "contains",
                        title: "Contains"
                    },
                    [Contract.UdmQueryRuleOperator.StringEndsWith]: {
                        text: "ends with",
                        title: "Ends with"
                    },
                    [Contract.UdmQueryRuleOperator.Equals]: {
                        text: "equals",
                        title: "Equals"
                    },
                    [Contract.UdmQueryRuleOperator.Gt]: {
                        text: "greater than",
                        title: "Greater than"
                    },
                    [Contract.UdmQueryRuleOperator.Gte]: {
                        text: "greater than or equal to",
                        title: "Greater than or equal to"
                    },
                    [Contract.UdmQueryRuleOperator.In]: {
                        text: "is",
                        title: "Is"
                    },
                    [Contract.UdmQueryRuleOperator.Like]: {
                        text: "like",
                        title: "Like"
                    },
                    [Contract.UdmQueryRuleOperator.Lt]: {
                        text: "lower than",
                        title: "Lower than"
                    },
                    [Contract.UdmQueryRuleOperator.Lte]: {
                        text: "lower than or equal to",
                        title: "Lower than or equal to"
                    },
                    [Contract.UdmQueryRuleOperator.StringStartsWith]: {
                        text: "starts with",
                        title: "Starts with"
                    }
                }
            }));

    return (queryRuleOperator: Contract.UdmQueryRuleOperator, variant: "text" | "title" = "text") =>
        localization[Contract.TypeNames.UdmQueryRuleOperator][queryRuleOperator][variant]();
}