import { DataTableSort, DataTableSortDirection, Optional } from "@infrastructure";
import { useCallback } from "react";
import { Contract } from "../../../../../common";

export function useGetWorkloadResourceModelsRequestSort(){
    return useCallback((sort: Optional<DataTableSort>) =>
        new Contract.WorkloadControllerWorkloadResourceModelSort(
            Contract.TypeNames.WorkloadControllerWorkloadResourceModelSort,
            !sort || sort.direction !== DataTableSortDirection.Ascending
                ? Contract.SortDirection.Descending
                : Contract.SortDirection.Ascending,
            !sort
                ? Contract.WorkloadControllerRequestProperty.Risks
                : sort.columnId === Contract.WorkloadControllerRequestProperty.VulnerabilityRawId
                    ? Contract.WorkloadControllerRequestProperty.VulnerabilitySeverity
                    : sort.columnId as Contract.WorkloadControllerRequestProperty),
    []);
}