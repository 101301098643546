import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useAadTenantEntityExternalUserDefaultRoleTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useAadTenantEntityExternalUserDefaultRoleTranslator",
            () => ({
                [Contract.TypeNames.AadTenantEntityExternalUserDefaultRole]: {
                    [Contract.AadTenantEntityExternalUserDefaultRole.GuestUser]: "Guest users have limited access to properties and memberships of directory objects",
                    [Contract.AadTenantEntityExternalUserDefaultRole.RestrictedGuestUser]: "Guest user access is restricted to properties and memberships of their own directory objects (most restrictive)",
                    [Contract.AadTenantEntityExternalUserDefaultRole.User]: "Guest users have the same access as members (most inclusive)"
                }
            }));

    return (type: Contract.AadTenantEntityExternalUserDefaultRole) =>
        localization[Contract.TypeNames.AadTenantEntityExternalUserDefaultRole][type]();
}