import { NoneIcon, useExecuteOperation } from "@infrastructure";
import { Typography } from "@mui/material";
import React from "react";
import { Contract, RiskPolicyTypeMetadataHelper, useEntityTypeNameTranslator, useGetAnalysisGroupTypeToRiskPolicyIdentifierToCountMap } from "../../../../../../../../../../../../../common";
import { useItemContext } from "../../../../../../../Profile";

type CountCellProps = {
    item: Contract.ComplianceRiskPolicyData;
};

export function CountCell({ item }: CountCellProps) {
    const { scopeId } = useItemContext();
    const getAnalysisGroupTypeToRiskPolicyIdentifierToCountMap =
        useGetAnalysisGroupTypeToRiskPolicyIdentifierToCountMap(
            () => item,
            scopeId);
    const [complianceRiskPolicyCount] =
        useExecuteOperation(
            [CountCell, `${item.riskPolicyIdentifier}/${scopeId}`],
            async () => {
                const analysisGroupTypeToRiskPolicyIdentifierToCountMap = await getAnalysisGroupTypeToRiskPolicyIdentifierToCountMap();
                return analysisGroupTypeToRiskPolicyIdentifierToCountMap[item.analysisGroupType][item.riskPolicyIdentifier];
            });
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    return (
        item.hasResults
            ? <Typography>
                {entityTypeNameTranslator(
                    RiskPolicyTypeMetadataHelper.getComplianceRiskedEntityTypeName(item.riskPolicyConfigurationTypeName),
                    {
                        count: complianceRiskPolicyCount,
                        includeCount: true,
                        includeServiceName: false,
                        variant: "text"
                    })}
            </Typography>
            : <NoneIcon sx={{ fontSize: "18px" }}/>);
}