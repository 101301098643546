﻿import { Message, useLocalization } from "@infrastructure";
import React from "react";
import { Contract, InfoItem } from "../../../../../../../../../../../common";
import { useGcpIamRoleBindingExpiredTranslator } from "../../../../../../../../../../../tenants";
import { EntitiesInfoItem, Info } from "../../../../../components";
import { DefinitionOptions, EntityProfileDefinition } from "../../../useDefinition";
import { useCustomEntityPropertyInfoItemElements } from "../../useCustomEntityPropertyInfoItemElements";
import { useGcpDefaultResourceInfoItemElements } from "../useGcpDefaultResourceInfoItemElements";
import { PermissionUsageInfoCard } from "./components";

export function useGcpIamRoleBindingDefinition(entityModel: Contract.GcpEntityModel, options?: DefinitionOptions) {
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(entityModel);
    const defaultPropertyInfoElements = useGcpDefaultResourceInfoItemElements(entityModel as Contract.GcpResourceModel);
    const roleBindingModel = entityModel as Contract.GcpIamRoleBindingModel;
    const roleBinding = roleBindingModel.entity as Contract.GcpIamRoleBinding;

    const roleBindingExpiredTranslator = useGcpIamRoleBindingExpiredTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpIamRoleBindingDefinition",
            () => ({
                info: {
                    containingRoleBindingIds: {
                        helpText: "The role binding is \"covered\" by these role bindings, which may come from a higher scope level or group membership.",
                        title: "Covered By"
                    },
                    expired: "Status",
                    principalIdReference: "Principal",
                    roleId: "Role",
                    scopeResourceIdReference: "Scope"
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultPropertyInfoElements}
                entityPropertyInfoItemElements={[
                    <EntitiesInfoItem
                        entityIdsOrModels={roleBinding.roleId}
                        entityTypeName={Contract.TypeNames.GcpIamRole}
                        key="roleId"
                        location="all"
                        title={localization.info.roleId()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={roleBindingModel.scopeResourceIdReference}
                        entityTypeName={Contract.TypeNames.GcpScopeResource}
                        key="scopeResourceIdReference"
                        location="all"
                        title={localization.info.scopeResourceIdReference()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={roleBindingModel.principalIdReference}
                        entityTypeName={Contract.TypeNames.IGciPrincipal}
                        key="principalIdReference"
                        location="all"
                        title={localization.info.principalIdReference()}/>,
                    <InfoItem
                        key="expired"
                        location="all"
                        title={localization.info.expired()}
                        value={roleBindingExpiredTranslator(roleBinding.expired)}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={roleBindingModel.containingRoleBindingIds}
                        entityTypeName={Contract.TypeNames.GcpIamRoleBinding}
                        key="containingRoleBindingIds"
                        location="all"
                        title={localization.info.containingRoleBindingIds.title()}
                        titleInfoElement={
                            <Message
                                level="info"
                                title={localization.info.containingRoleBindingIds.helpText()}
                                variant="minimal"/>}/>
                ]}
                options={options?.infoOptions}>
                <PermissionUsageInfoCard roleBindingModel={roleBindingModel}/>
            </Info>
    });
}