﻿import _ from "lodash";
import { ReactNode } from "react";
import { useLocalization } from ".";

export function useLocalizeAttributes() {
    const localization =
        useLocalization(
            "infrastructure.hooks.useLocalizeAttributes",
            () => ({
                attribute: "{{firstAttribute}} | {{otherAttributes}}"
            }));

    function buildAttributes(attributes: ReactNode[]): ReactNode {
        const [firstAttribute, ...otherAttributes] = attributes;
        if (_.isEmpty(otherAttributes)) {
            return firstAttribute;
        } else {
            return localization.attribute({
                firstAttribute,
                otherAttributes: buildAttributes(otherAttributes)
            });
        }
    }

    return (...attributes: ReactNode[]): string => buildAttributes(_.filter(attributes)) as string;
}