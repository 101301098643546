import { useLocalization } from "@infrastructure";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { Contract, InfoItem, useEntityTypeNameTranslator } from "../../../../../../../../../../../common";
import { useOciNetworkingRouteTableStatusTranslator } from "../../../../../../../hooks";
import { EntitiesInfoItem, Info } from "../../../../../components";
import { OciNetworkingSubnetsInfoCard } from "../../../components";
import { useCustomEntityPropertyInfoItemElements } from "../../useCustomEntityPropertyInfoItemElements";
import { useOciDefaultResourceInfoItemElements } from "../.";
import { RouteRulesInfoCard } from "./components";

export function useOciNetworkingRouteTableDefinition(resourceModel: Contract.OciResourceModel, options?: DefinitionOptions) {
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useOciDefaultResourceInfoItemElements(resourceModel);
    const routeTableModel = resourceModel as Contract.OciNetworkingRouteTableModel;
    const routeTable = routeTableModel.entity as Contract.OciNetworkingRouteTable;

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const routeTableStatusTranslator = useOciNetworkingRouteTableStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.oci.useOciNetworkingRouteTableDefinition",
            () => ({
                info: {
                    items: {
                        status: "State"
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="status"
                        title={localization.info.items.status()}
                        value={routeTableStatusTranslator(routeTable.status)}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={routeTableModel.vcnIdReference}
                        entityTypeName={Contract.TypeNames.OciNetworkingVcn}
                        key="vcn"
                        title={entityTypeNameTranslator(Contract.TypeNames.OciNetworkingVcn)}/>
                ]}
                options={options?.infoOptions}>
                <OciNetworkingSubnetsInfoCard subnetIds={routeTableModel.subnetIds}/>
                <RouteRulesInfoCard
                    routeRules={routeTable.routeRules}
                    targetResourceOcidToEntityIdReferenceMap={routeTableModel.targetResourceOcidToEntityIdReferenceMap}/>
            </Info>
    });
}