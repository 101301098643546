import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function GoogleIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                d="M6.6875 12.0004C6.6875 11.0095 6.96051 10.0813 7.4348 9.28645V5.91504H4.0634C2.72531 7.65285 2 9.77078 2 12.0004C2 14.23 2.72531 16.3479 4.0634 18.0857H7.4348V14.7143C6.96051 13.9195 6.6875 12.9913 6.6875 12.0004Z"
                fill="#FBBD00"/>
            <path
                d="M12 17.3121L9.65625 19.6558L12 21.9996C14.2296 21.9996 16.3475 21.2743 18.0854 19.9362V16.5684H14.7175C13.9158 17.0444 12.9836 17.3121 12 17.3121Z"
                fill="#0F9D58"/>
            <path
                d="M7.4348 14.7143L4.0634 18.0857C4.32832 18.4298 4.6159 18.7588 4.92805 19.071C6.8168 20.9597 9.32891 21.9996 12 21.9996V17.3121C10.0616 17.3121 8.36262 16.2691 7.4348 14.7143Z"
                fill="#31AA52"/>
            <path
                d="M22 11.9991C22 11.3907 21.9449 10.7811 21.8363 10.1875L21.7483 9.70703H12V14.3945H16.7442C16.2835 15.311 15.5752 16.0591 14.7175 16.5684L18.0854 19.9362C18.4294 19.6712 18.7589 19.3823 19.0711 19.0702C20.9598 17.1814 22 14.6702 22 11.9991Z"
                fill="#3C79E6"/>
            <path
                d="M15.7565 8.24348L16.1708 8.65777L19.4854 5.34324L19.0711 4.92895C17.1823 3.0402 14.6711 2 12 2L9.65625 4.34375L12 6.6875C13.419 6.6875 14.7531 7.24008 15.7565 8.24348Z"
                fill="#CF2D48"/>
            <path
                d="M12 6.6875V2C9.32891 2 6.81781 3.0402 4.92902 4.92891C4.61688 5.24106 4.32832 5.57094 4.0634 5.91504L7.4348 9.28645C8.36266 7.73164 10.0616 6.6875 12 6.6875Z"
                fill="#EB4132"/>
        </SvgIcon>);
}