import { useExecuteOperation } from "@infrastructure";
import _ from "lodash";
import { Contract, TypeHelper, WorkloadController } from "../../../../../../../../../../../common";

export function useGetWorkloadResourceRiskVulnerabilityRawIds(risk: Contract.WorkloadAnalysisRisk) {

    const maxVulnerabilitySeverity = getWorkloadResourceVulnerabilityRiskMaxVulnerabilitySeverity(risk);

    const [vulnerabilitySeverityToRawIdsMap] =
        useExecuteOperation(
            [risk.id],
            async () => {
                const { vulnerabilitySeverityToRawIdsMap } =
                    await WorkloadController.getWorkloadResourceRiskVulnerabilityRawIds(
                        new Contract.WorkloadControllerGetWorkloadResourceRiskVulnerabilityRawIdsRequest(
                            risk.id,
                            maxVulnerabilitySeverity));
                return vulnerabilitySeverityToRawIdsMap;
            });
    return vulnerabilitySeverityToRawIdsMap;
}

export function getWorkloadResourceVulnerabilityRiskMaxVulnerabilitySeverity(risk: Contract.WorkloadAnalysisRisk) : Contract.Severity {
    return _(risk.vulnerabilitySeverityToCountMap).
        toPairs().
        orderBy(
            ([severity]) => TypeHelper.getEnumValue(Contract.TypeNames.Severity, severity),
            "desc").
        filter(([, severityVulnerabilityCount]) => severityVulnerabilityCount > 0).
        map(([severity]) => severity).
        first()! as Contract.Severity;
}

export function getWorkloadResourceVulnerabilityRiskSeverityValues(minSeverity: Contract.Severity) {
    return _(Contract.Severity).filter(severity => TypeHelper.getEnumValue(Contract.TypeNames.Severity, severity) >= TypeHelper.getEnumValue(Contract.TypeNames.Severity, minSeverity)).value();
}