import { DropdownActions } from "@infrastructure";
import { Box } from "@mui/material";
import _ from "lodash";
import React, { useCallback, useMemo, useRef } from "react";
import { Contract, Entities, Entity, InfoItemProps, useEntityTypeNameTranslator, useTheme, VerticalTopItemsInfoItem } from "../../../../../../../common";
import { EntityModel } from "../../../../../../../common/controllers/types.generated";

export type EntitiesInfoItemProps = InfoItemProps & {
    disabledTitle?: boolean;
    entityIdsOrModels?: EntityModel | EntityModel[] | string | string[];
    entityTypeName: string;
    title?: string;
};

export function EntitiesInfoItem({ disabledTitle = false, entityIdsOrModels = [], entityTypeName, title, ...props }: EntitiesInfoItemProps) {
    const dropdownActionsRef = useRef<DropdownActions>();
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const idsOrModels =
        useMemo(
            () =>
                _.isArray(entityIdsOrModels)
                    ? entityIdsOrModels
                    : _.isNil(entityIdsOrModels)
                        ? []
                        : [entityIdsOrModels] as EntityModel[] | string[],
            [entityIdsOrModels]);
    const entityTitle =
        useMemo(
            () =>
                title
                    ? title
                    : entityTypeName
                        ? entityTypeNameTranslator(
                            entityTypeName,
                            {
                                count: _.size(idsOrModels),
                                includeServiceName: false
                            })
                        : undefined,
            [title, entityTypeName, idsOrModels]);

    const getSortValue =
        useCallback(
            (entityModel: Contract.EntityModel | Contract.EntitySearchableReference) =>
                (entityModel as Contract.EntitySearchableReference)?.displayName
                ?? (entityModel as Contract.EntityModel)?.entity?.displayName,
            [idsOrModels]);

    const theme = useTheme();
    return (
        <VerticalTopItemsInfoItem
            {...props}
            disabledTitle={disabledTitle}
            dropdownActionsRef={dropdownActionsRef}
            getSortValue={getSortValue}
            items={idsOrModels}
            itemsPopover={
                (entityIdsOrModels: string[] | Contract.EntityModel[]) =>
                    <Box sx={{ maxWidth: theme.spacing(60) }}>
                        <Entities
                            entityIdsOrModels={entityIdsOrModels}
                            entityTypeName={entityTypeName}
                            searchEnabled={true}
                            variant="dropdown"
                            onEntityClick={() => dropdownActionsRef.current?.close()}/>
                    </Box>}
            title={entityTitle}>
            {(entityModel: string | Contract.EntityModel) =>
                <Entity
                    entityIdOrModel={entityModel}
                    key={
                        _.isNil(entityModel)
                            ? (entityModel as Contract.EntityModel)?.id
                            : (entityModel as string)}
                    variant="iconText"/>}
        </VerticalTopItemsInfoItem>);
}