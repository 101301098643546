import { UnexpectedError } from "@infrastructure";
import { Contract } from "../controllers";

export class ReportHelper {
    public static getTenantTypes(reportType: Contract.ReportType) {
        switch (reportType) {
            case Contract.ReportType.AwsIamPolicies:
            case Contract.ReportType.AwsIamUserAccessKeys:
            case Contract.ReportType.AwsSsoUserTenantAssignments:
                return [Contract.TenantType.Aws];
            case Contract.ReportType.AzureAuthorizationRoleAssignments:
                return [Contract.TenantType.Azure];
            case Contract.ReportType.CodeRisks:
                return [Contract.TenantType.Code];
            case Contract.ReportType.GcpIamRoleBindings:
            case Contract.ReportType.GcpIamServiceAccountUserManagedKeys:
                return [Contract.TenantType.Gcp];
            case Contract.ReportType.Events:
                return [
                    Contract.TenantType.Aws,
                    Contract.TenantType.Azure,
                    Contract.TenantType.Gcp
                ];
            case Contract.ReportType.Compliance:
            case Contract.ReportType.ComplianceScopes:
            case Contract.ReportType.RiskPolicy:
            case Contract.ReportType.Risks:
                return [
                    Contract.TenantType.Aad,
                    Contract.TenantType.Aws,
                    Contract.TenantType.Azure,
                    Contract.TenantType.Ci,
                    Contract.TenantType.Gcp,
                    Contract.TenantType.Oci,
                    Contract.TenantType.Op
                ];
            case Contract.ReportType.Vulnerabilities:
                return [
                    Contract.TenantType.Aws,
                    Contract.TenantType.Azure,
                    Contract.TenantType.Ci,
                    Contract.TenantType.Gcp,
                    Contract.TenantType.Op
                ];
            case Contract.ReportType.Dashboard:
            case Contract.ReportType.Inventory:
                return [
                    Contract.TenantType.Aad,
                    Contract.TenantType.Aws,
                    Contract.TenantType.Azure,
                    Contract.TenantType.Ci,
                    Contract.TenantType.Gci,
                    Contract.TenantType.Gcp,
                    Contract.TenantType.Oci,
                    Contract.TenantType.Okta,
                    Contract.TenantType.OneLogin,
                    Contract.TenantType.Op,
                    Contract.TenantType.PingIdentity
                ];
            default:
                throw new UnexpectedError("Contract.ReportType", reportType);
        }
    }
}