﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, Entity, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useEntityRiskContext } from "../../../useEntityRiskContext";

export function useGetAadDirectoryEntityRiskContext() {
    return useMemo(
        () => useAadDirectoryEntityRiskContext,
        []);
}

function useAadDirectoryEntityRiskContext(directoryEntityModel: Contract.AadDirectoryEntityModel) {
    const entityRiskContext = useEntityRiskContext(directoryEntityModel);
    const entityTypeNameTranslator = useEntityTypeNameTranslator();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aad.hooks.contexts.useGetAadDirectoryEntityRiskContext",
            () => ({
                creationTime: "*capitalize*{{directoryEntityTypeName}}** {{directoryEntity}} was created on {{creationTime | TimeFormatter.shortDate}}"
            }));

    return {
        ...entityRiskContext,
        generalInformation:
            new RiskDefinitionContextItem(
                _.isNil((directoryEntityModel.entity as Contract.AadDirectoryEntity).creationTime)
                    ? undefined
                    : localization.creationTime({
                        creationTime: (directoryEntityModel.entity as Contract.AadDirectoryEntity).creationTime,
                        directoryEntity:
                            <Entity
                                entityIdOrModel={directoryEntityModel}
                                variant="text"/>,
                        directoryEntityTypeName:
                            entityTypeNameTranslator(
                                directoryEntityModel.entity.typeName,
                                {
                                    includeServiceName: false,
                                    variant: "text"
                                })
                    }))
    };
}