export enum TypeNames {
    AadChange = "AadChange",
    AadDeletePrincipalGroupMembershipChange = "AadDeletePrincipalGroupMembershipChange",
    AadDirectoryApplication = "AadDirectoryApplication",
    AadDirectoryApplicationCertificate = "AadDirectoryApplicationCertificate",
    AadDirectoryApplicationCertificateModel = "AadDirectoryApplicationCertificateModel",
    AadDirectoryApplicationCertificateNotRotatedRisk = "AadDirectoryApplicationCertificateNotRotatedRisk",
    AadDirectoryApplicationCertificateNotRotatedRiskModel = "AadDirectoryApplicationCertificateNotRotatedRiskModel",
    AadDirectoryApplicationCertificateNotRotatedRiskPolicyConfiguration = "AadDirectoryApplicationCertificateNotRotatedRiskPolicyConfiguration",
    AadDirectoryApplicationCertificateNotRotatedRiskPolicyUpdateAuditEventCertificateRotationTimeFrameChange = "AadDirectoryApplicationCertificateNotRotatedRiskPolicyUpdateAuditEventCertificateRotationTimeFrameChange",
    AadDirectoryApplicationModel = "AadDirectoryApplicationModel",
    AadDirectoryApplicationObject = "AadDirectoryApplicationObject",
    AadDirectoryApplicationObjectExpirationStatus = "AadDirectoryApplicationObjectExpirationStatus",
    AadDirectoryApplicationObjectModel = "AadDirectoryApplicationObjectModel",
    AadDirectoryApplicationObjectModelFilters = "AadDirectoryApplicationObjectModelFilters",
    AadDirectoryApplicationRoleDefinition = "AadDirectoryApplicationRoleDefinition",
    AadDirectoryApplicationSecret = "AadDirectoryApplicationSecret",
    AadDirectoryApplicationSecretModel = "AadDirectoryApplicationSecretModel",
    AadDirectoryApplicationSecretNotRotatedRisk = "AadDirectoryApplicationSecretNotRotatedRisk",
    AadDirectoryApplicationSecretNotRotatedRiskModel = "AadDirectoryApplicationSecretNotRotatedRiskModel",
    AadDirectoryApplicationSecretNotRotatedRiskPolicyConfiguration = "AadDirectoryApplicationSecretNotRotatedRiskPolicyConfiguration",
    AadDirectoryApplicationSecretNotRotatedRiskPolicyUpdateAuditEventSecretRotationTimeFrameChange = "AadDirectoryApplicationSecretNotRotatedRiskPolicyUpdateAuditEventSecretRotationTimeFrameChange",
    AadDirectoryApplicationServicePrincipal = "AadDirectoryApplicationServicePrincipal",
    AadDirectoryApplicationServicePrincipalAws = "AadDirectoryApplicationServicePrincipalAws",
    AadDirectoryApplicationServicePrincipalAwsType = "AadDirectoryApplicationServicePrincipalAwsType",
    AadDirectoryApplicationServicePrincipalInactiveRisk = "AadDirectoryApplicationServicePrincipalInactiveRisk",
    AadDirectoryApplicationServicePrincipalInactiveRiskModel = "AadDirectoryApplicationServicePrincipalInactiveRiskModel",
    AadDirectoryApplicationServicePrincipalInactiveRiskPolicyConfiguration = "AadDirectoryApplicationServicePrincipalInactiveRiskPolicyConfiguration",
    AadDirectoryApplicationServicePrincipalModel = "AadDirectoryApplicationServicePrincipalModel",
    AadDirectoryApplicationType = "AadDirectoryApplicationType",
    AadDirectoryDirectoryRoleAssignment = "AadDirectoryDirectoryRoleAssignment",
    AadDirectoryDirectoryRoleAssignmentModel = "AadDirectoryDirectoryRoleAssignmentModel",
    AadDirectoryDirectoryRoleAssignmentResource = "AadDirectoryDirectoryRoleAssignmentResource",
    AadDirectoryDirectoryRoleAssignmentResourceModel = "AadDirectoryDirectoryRoleAssignmentResourceModel",
    AadDirectoryDirectoryRoleAssignmentResourceScopeInfo = "AadDirectoryDirectoryRoleAssignmentResourceScopeInfo",
    AadDirectoryDirectoryRoleAssignmentResourceScopeType = "AadDirectoryDirectoryRoleAssignmentResourceScopeType",
    AadDirectoryDirectoryRoleAssignmentResourceStatus = "AadDirectoryDirectoryRoleAssignmentResourceStatus",
    AadDirectoryDirectoryRoleDefinition = "AadDirectoryDirectoryRoleDefinition",
    AadDirectoryDirectoryRoleDefinitionModel = "AadDirectoryDirectoryRoleDefinitionModel",
    AadDirectoryDirectoryRoleDefinitionPermission = "AadDirectoryDirectoryRoleDefinitionPermission",
    AadDirectoryDirectoryRoleDefinitionType = "AadDirectoryDirectoryRoleDefinitionType",
    AadDirectoryDirectoryRoleEligibilitySchedule = "AadDirectoryDirectoryRoleEligibilitySchedule",
    AadDirectoryDirectoryRoleEligibilityScheduleModel = "AadDirectoryDirectoryRoleEligibilityScheduleModel",
    AadDirectoryDirectoryRoleEntity = "AadDirectoryDirectoryRoleEntity",
    AadDirectoryDirectoryRoleManagementPolicyAssignment = "AadDirectoryDirectoryRoleManagementPolicyAssignment",
    AadDirectoryDirectoryRoleManagementPolicyAssignmentModel = "AadDirectoryDirectoryRoleManagementPolicyAssignmentModel",
    AadDirectoryEntity = "AadDirectoryEntity",
    AadDirectoryEntityModel = "AadDirectoryEntityModel",
    AadDirectoryEntityModelFilters = "AadDirectoryEntityModelFilters",
    AadDirectoryEntityTypeMetadata = "AadDirectoryEntityTypeMetadata",
    AadDirectoryGroup = "AadDirectoryGroup",
    AadDirectoryGroupInactiveRisk = "AadDirectoryGroupInactiveRisk",
    AadDirectoryGroupInactiveRiskModel = "AadDirectoryGroupInactiveRiskModel",
    AadDirectoryGroupInactiveRiskPolicyConfiguration = "AadDirectoryGroupInactiveRiskPolicyConfiguration",
    AadDirectoryGroupMembershipEligibilityConfiguration = "AadDirectoryGroupMembershipEligibilityConfiguration",
    AadDirectoryGroupMembershipRequest = "AadDirectoryGroupMembershipRequest",
    AadDirectoryGroupMembershipType = "AadDirectoryGroupMembershipType",
    AadDirectoryGroupModel = "AadDirectoryGroupModel",
    AadDirectoryGroupProfile = "AadDirectoryGroupProfile",
    AadDirectoryGroupProfileIdentitiesInactive = "AadDirectoryGroupProfileIdentitiesInactive",
    AadDirectoryGroupType = "AadDirectoryGroupType",
    AadDirectoryIdentity = "AadDirectoryIdentity",
    AadDirectoryIdentityInactiveRisk = "AadDirectoryIdentityInactiveRisk",
    AadDirectoryIdentityInactiveRiskModel = "AadDirectoryIdentityInactiveRiskModel",
    AadDirectoryIdentityInactiveRiskModelInfoType = "AadDirectoryIdentityInactiveRiskModelInfoType",
    AadDirectoryIdentityInactiveRiskPolicyConfiguration = "AadDirectoryIdentityInactiveRiskPolicyConfiguration",
    AadDirectoryIdentityInactiveRiskPolicyUpdateAuditEventIncludeDisabledIdentityChange = "AadDirectoryIdentityInactiveRiskPolicyUpdateAuditEventIncludeDisabledIdentityChange",
    AadDirectoryIdentityInactiveRiskResolutionType = "AadDirectoryIdentityInactiveRiskResolutionType",
    AadDirectoryIdentityModel = "AadDirectoryIdentityModel",
    AadDirectoryIdentityModelFilters = "AadDirectoryIdentityModelFilters",
    AadDirectoryIdentityProfile = "AadDirectoryIdentityProfile",
    AadDirectoryManagedIdentityServicePrincipal = "AadDirectoryManagedIdentityServicePrincipal",
    AadDirectoryManagedIdentityServicePrincipalModel = "AadDirectoryManagedIdentityServicePrincipalModel",
    AadDirectoryPrincipal = "AadDirectoryPrincipal",
    AadDirectoryPrincipalGroupIdMatchCondition = "AadDirectoryPrincipalGroupIdMatchCondition",
    AadDirectoryPrincipalModel = "AadDirectoryPrincipalModel",
    AadDirectoryPrincipalModelAnalyzing = "AadDirectoryPrincipalModelAnalyzing",
    AadDirectoryPrincipalReference = "AadDirectoryPrincipalReference",
    AadDirectoryPrincipalType = "AadDirectoryPrincipalType",
    AadDirectoryRoleManagementPolicyAssignmentActivation = "AadDirectoryRoleManagementPolicyAssignmentActivation",
    AadDirectoryRoleManagementPolicyAssignmentActivationAuthenticationType = "AadDirectoryRoleManagementPolicyAssignmentActivationAuthenticationType",
    AadDirectoryServicePrincipal = "AadDirectoryServicePrincipal",
    AadDirectoryServicePrincipalFederatedCredential = "AadDirectoryServicePrincipalFederatedCredential",
    AadDirectoryServicePrincipalModel = "AadDirectoryServicePrincipalModel",
    AadDirectoryServicePrincipalProfile = "AadDirectoryServicePrincipalProfile",
    AadDirectoryServicePrincipalType = "AadDirectoryServicePrincipalType",
    AadDirectoryUser = "AadDirectoryUser",
    AadDirectoryUserAuthenticationType = "AadDirectoryUserAuthenticationType",
    AadDirectoryUserExternalAttribute = "AadDirectoryUserExternalAttribute",
    AadDirectoryUserGuestAttribute = "AadDirectoryUserGuestAttribute",
    AadDirectoryUserGuestUserInvitationStatus = "AadDirectoryUserGuestUserInvitationStatus",
    AadDirectoryUserInactiveRisk = "AadDirectoryUserInactiveRisk",
    AadDirectoryUserInactiveRiskModel = "AadDirectoryUserInactiveRiskModel",
    AadDirectoryUserInactiveRiskPolicyConfiguration = "AadDirectoryUserInactiveRiskPolicyConfiguration",
    AadDirectoryUserInactiveRiskPolicyUpdateAuditEventIncludeInvitationPendingAcceptanceGuestUserChange = "AadDirectoryUserInactiveRiskPolicyUpdateAuditEventIncludeInvitationPendingAcceptanceGuestUserChange",
    AadDirectoryUserJobInfo = "AadDirectoryUserJobInfo",
    AadDirectoryUserModel = "AadDirectoryUserModel",
    AadDirectoryUserOnPremisesExtensionAttribute = "AadDirectoryUserOnPremisesExtensionAttribute",
    AadDirectoryUserProfile = "AadDirectoryUserProfile",
    AadDirectoryUserType = "AadDirectoryUserType",
    AadEntity = "AadEntity",
    AadEntityModel = "AadEntityModel",
    AadEntityProfile = "AadEntityProfile",
    AadEventAnalysisServiceConfiguration = "AadEventAnalysisServiceConfiguration",
    AadFolderConfiguration = "AadFolderConfiguration",
    AadPartitionType = "AadPartitionType",
    AadSeverePermissionDirectoryRoleDefinitionData = "AadSeverePermissionDirectoryRoleDefinitionData",
    AadSeverePermissionDirectoryRoleDefinitionsConfiguration = "AadSeverePermissionDirectoryRoleDefinitionsConfiguration",
    AadSeverePermissionDirectoryRoleDefinitionsDeletionAuditEventData = "AadSeverePermissionDirectoryRoleDefinitionsDeletionAuditEventData",
    AadSeverePermissionDirectoryRoleDefinitionsUpdateAuditEventData = "AadSeverePermissionDirectoryRoleDefinitionsUpdateAuditEventData",
    AadSeverePermissionPrincipalRisk = "AadSeverePermissionPrincipalRisk",
    AadSeverePermissionPrincipalRiskModel = "AadSeverePermissionPrincipalRiskModel",
    AadSeverePermissionPrincipalRiskPolicyConfiguration = "AadSeverePermissionPrincipalRiskPolicyConfiguration",
    AadTenantAuditEvent = "AadTenantAuditEvent",
    AadTenantAuthenticationProviderConfigurationSection = "AadTenantAuthenticationProviderConfigurationSection",
    AadTenantAuthenticationProviderConfigurationSectionPartitionData = "AadTenantAuthenticationProviderConfigurationSectionPartitionData",
    AadTenantConfiguration = "AadTenantConfiguration",
    AadTenantConfigurationAnalysis = "AadTenantConfigurationAnalysis",
    AadTenantConfigurationAnalysisSyncType = "AadTenantConfigurationAnalysisSyncType",
    AadTenantConfigurationApplication = "AadTenantConfigurationApplication",
    AadTenantConfigurationEvents = "AadTenantConfigurationEvents",
    AadTenantConfigurationFederation = "AadTenantConfigurationFederation",
    AadTenantConfigurationFederationAwsRoleSessionNameUserProperty = "AadTenantConfigurationFederationAwsRoleSessionNameUserProperty",
    AadTenantConfigurationFederationRoleData = "AadTenantConfigurationFederationRoleData",
    AadTenantCreationAuditEvent = "AadTenantCreationAuditEvent",
    AadTenantDeletionAuditEvent = "AadTenantDeletionAuditEvent",
    AadTenantEntity = "AadTenantEntity",
    AadTenantEntityDefaultUserRoleApplicationCreationEnabledRisk = "AadTenantEntityDefaultUserRoleApplicationCreationEnabledRisk",
    AadTenantEntityDefaultUserRoleApplicationCreationEnabledRiskModel = "AadTenantEntityDefaultUserRoleApplicationCreationEnabledRiskModel",
    AadTenantEntityDefaultUserRoleApplicationCreationEnabledRiskPolicyConfiguration = "AadTenantEntityDefaultUserRoleApplicationCreationEnabledRiskPolicyConfiguration",
    AadTenantEntityDefaultUserRoleTenantCreationEnabledRisk = "AadTenantEntityDefaultUserRoleTenantCreationEnabledRisk",
    AadTenantEntityDefaultUserRoleTenantCreationEnabledRiskModel = "AadTenantEntityDefaultUserRoleTenantCreationEnabledRiskModel",
    AadTenantEntityDefaultUserRoleTenantCreationEnabledRiskPolicyConfiguration = "AadTenantEntityDefaultUserRoleTenantCreationEnabledRiskPolicyConfiguration",
    AadTenantEntityExternalUserDefaultRole = "AadTenantEntityExternalUserDefaultRole",
    AadTenantEntityExternalUserInvitationAllowedRoles = "AadTenantEntityExternalUserInvitationAllowedRoles",
    AadTenantEntityModel = "AadTenantEntityModel",
    AadTenantEntityProfile = "AadTenantEntityProfile",
    AadTenantEntityUnrestrictedExternalUserDefaultRoleRisk = "AadTenantEntityUnrestrictedExternalUserDefaultRoleRisk",
    AadTenantEntityUnrestrictedExternalUserDefaultRoleRiskModel = "AadTenantEntityUnrestrictedExternalUserDefaultRoleRiskModel",
    AadTenantEntityUnrestrictedExternalUserDefaultRoleRiskPolicyConfiguration = "AadTenantEntityUnrestrictedExternalUserDefaultRoleRiskPolicyConfiguration",
    AadTenantEntityUnrestrictedExternalUserInvitationAllowedRolesRisk = "AadTenantEntityUnrestrictedExternalUserInvitationAllowedRolesRisk",
    AadTenantEntityUnrestrictedExternalUserInvitationAllowedRolesRiskModel = "AadTenantEntityUnrestrictedExternalUserInvitationAllowedRolesRiskModel",
    AadTenantEntityUnrestrictedExternalUserInvitationAllowedRolesRiskPolicyConfiguration = "AadTenantEntityUnrestrictedExternalUserInvitationAllowedRolesRiskPolicyConfiguration",
    AadTenantModel = "AadTenantModel",
    AadTenantModelStatus = "AadTenantModelStatus",
    AadTenantState = "AadTenantState",
    AadTenantStateEvents = "AadTenantStateEvents",
    AadTenantStateMonitoring = "AadTenantStateMonitoring",
    AadTenantStateMonitoringIssue = "AadTenantStateMonitoringIssue",
    AadTenantUpdateAuditEvent = "AadTenantUpdateAuditEvent",
    AccessControllerGetAwsFederationEdgeFilterItemPageRequest = "AccessControllerGetAwsFederationEdgeFilterItemPageRequest",
    AccessControllerGetAwsFederationEdgeFilterItemPageResponse = "AccessControllerGetAwsFederationEdgeFilterItemPageResponse",
    AccessControllerGetAwsFederationEdgeFiltersRequest = "AccessControllerGetAwsFederationEdgeFiltersRequest",
    AccessControllerGetAwsFederationEdgeFiltersResponse = "AccessControllerGetAwsFederationEdgeFiltersResponse",
    AccessControllerGetAwsFederationEdgeModelPageRequest = "AccessControllerGetAwsFederationEdgeModelPageRequest",
    AccessControllerGetAwsFederationEdgeModelPageRequestProperty = "AccessControllerGetAwsFederationEdgeModelPageRequestProperty",
    AccessControllerGetAwsFederationEdgeModelPageRequestSort = "AccessControllerGetAwsFederationEdgeModelPageRequestSort",
    AccessControllerGetAwsFederationEdgeModelPageResponse = "AccessControllerGetAwsFederationEdgeModelPageResponse",
    AccessControllerGetAwsFederationEdgeModelRequestFilters = "AccessControllerGetAwsFederationEdgeModelRequestFilters",
    AccessControllerGetAzureIdentityDestinationTenantIdsRequest = "AccessControllerGetAzureIdentityDestinationTenantIdsRequest",
    AccessControllerGetDashboardSummaryResponse = "AccessControllerGetDashboardSummaryResponse",
    AccessControllerGetDashboardSummaryResponseAccessSummary = "AccessControllerGetDashboardSummaryResponseAccessSummary",
    AccessControllerGetDashboardSummaryResponseAccessSummaryPrincipalSummary = "AccessControllerGetDashboardSummaryResponseAccessSummaryPrincipalSummary",
    AccessControllerGetDashboardSummaryResponseAccessSummaryRiskData = "AccessControllerGetDashboardSummaryResponseAccessSummaryRiskData",
    AccessControllerGetDashboardSummaryResponseAccessSummaryVendorData = "AccessControllerGetDashboardSummaryResponseAccessSummaryVendorData",
    AccessControllerGetDashboardSummaryResponseCloudProviderTenantAccessExcessivePermissionPrincipalTrend = "AccessControllerGetDashboardSummaryResponseCloudProviderTenantAccessExcessivePermissionPrincipalTrend",
    AccessControllerGetDashboardSummaryResponseCloudProviderTenantAccessPrincipalTimeFrameSummary = "AccessControllerGetDashboardSummaryResponseCloudProviderTenantAccessPrincipalTimeFrameSummary",
    AccessControllerGetGcpIdentityDestinationTenantIdsRequest = "AccessControllerGetGcpIdentityDestinationTenantIdsRequest",
    AccessControllerGetIdentityDestinationTenantIdsRequest = "AccessControllerGetIdentityDestinationTenantIdsRequest",
    AccessControllerGetIdentityDestinationTenantIdsResponse = "AccessControllerGetIdentityDestinationTenantIdsResponse",
    AccessControllerGetPermissionFiltersResponse = "AccessControllerGetPermissionFiltersResponse",
    AccessControllerGetPermissionsModelActionsRequest = "AccessControllerGetPermissionsModelActionsRequest",
    AccessControllerGetPermissionsModelActionsResponse = "AccessControllerGetPermissionsModelActionsResponse",
    AccessControllerGetPermissionsModelCountRequest = "AccessControllerGetPermissionsModelCountRequest",
    AccessControllerGetPermissionsModelCountResponse = "AccessControllerGetPermissionsModelCountResponse",
    AccessControllerGetPermissionsModelDestinationEntityIdsRequest = "AccessControllerGetPermissionsModelDestinationEntityIdsRequest",
    AccessControllerGetPermissionsModelDestinationEntityIdsResponse = "AccessControllerGetPermissionsModelDestinationEntityIdsResponse",
    AccessControllerGetPermissionsModelEntityType = "AccessControllerGetPermissionsModelEntityType",
    AccessControllerGetPermissionsModelExportRequest = "AccessControllerGetPermissionsModelExportRequest",
    AccessControllerGetPermissionsModelExportResponse = "AccessControllerGetPermissionsModelExportResponse",
    AccessControllerGetPermissionsModelOriginatorEntityIdsRequest = "AccessControllerGetPermissionsModelOriginatorEntityIdsRequest",
    AccessControllerGetPermissionsModelOriginatorEntityIdsResponse = "AccessControllerGetPermissionsModelOriginatorEntityIdsResponse",
    AccessControllerGetPermissionsModelPageRequest = "AccessControllerGetPermissionsModelPageRequest",
    AccessControllerGetPermissionsModelPageResponse = "AccessControllerGetPermissionsModelPageResponse",
    AccessControllerGetPermissionsModelSourceEntityIdsRequest = "AccessControllerGetPermissionsModelSourceEntityIdsRequest",
    AccessControllerGetPermissionsModelSourceEntityIdsResponse = "AccessControllerGetPermissionsModelSourceEntityIdsResponse",
    AccessControllerGetPrincipalExcessivePermissionFilterItemPageRequest = "AccessControllerGetPrincipalExcessivePermissionFilterItemPageRequest",
    AccessControllerGetPrincipalExcessivePermissionModelRequestFilters = "AccessControllerGetPrincipalExcessivePermissionModelRequestFilters",
    AccessControllerGetPrincipalExcessivePermissionPrincipalRiskTenantPermissionRequest = "AccessControllerGetPrincipalExcessivePermissionPrincipalRiskTenantPermissionRequest",
    AccessControllerGetPrincipalExcessivePermissionPrincipalRiskTenantPermissionResponse = "AccessControllerGetPrincipalExcessivePermissionPrincipalRiskTenantPermissionResponse",
    AccessControllerGetPrincipalExcessivePermissionPropertyItemsRequest = "AccessControllerGetPrincipalExcessivePermissionPropertyItemsRequest",
    AccessControllerGetPrincipalExcessivePermissionRiskResolutionEnabledRequest = "AccessControllerGetPrincipalExcessivePermissionRiskResolutionEnabledRequest",
    AccessControllerGetPrincipalExcessivePermissionRiskResolutionEnabledResponse = "AccessControllerGetPrincipalExcessivePermissionRiskResolutionEnabledResponse",
    AccessControllerGetPrincipalExcessivePermissionsModelPageRequest = "AccessControllerGetPrincipalExcessivePermissionsModelPageRequest",
    AccessControllerGetPrincipalExcessivePermissionSummaryResponse = "AccessControllerGetPrincipalExcessivePermissionSummaryResponse",
    AccessControllerGetPrincipalFilterItemPageRequest = "AccessControllerGetPrincipalFilterItemPageRequest",
    AccessControllerGetPrincipalFilterItemPageResponse = "AccessControllerGetPrincipalFilterItemPageResponse",
    AccessControllerGetPrincipalFilterItemPageResponseBase = "AccessControllerGetPrincipalFilterItemPageResponseBase",
    AccessControllerGetPrincipalIdToOpenAccessRiskIdToScopeIdToIdentityPermissionsMapRequest = "AccessControllerGetPrincipalIdToOpenAccessRiskIdToScopeIdToIdentityPermissionsMapRequest",
    AccessControllerGetPrincipalIdToOpenAccessRiskIdToScopeIdToIdentityPermissionsMapResponse = "AccessControllerGetPrincipalIdToOpenAccessRiskIdToScopeIdToIdentityPermissionsMapResponse",
    AccessControllerGetPrincipalModelExcessivePermissionFiltersRequest = "AccessControllerGetPrincipalModelExcessivePermissionFiltersRequest",
    AccessControllerGetPrincipalModelFiltersRequest = "AccessControllerGetPrincipalModelFiltersRequest",
    AccessControllerGetPrincipalModelFiltersResponse = "AccessControllerGetPrincipalModelFiltersResponse",
    AccessControllerGetPrincipalModelPageRequest = "AccessControllerGetPrincipalModelPageRequest",
    AccessControllerGetPrincipalModelPageRequestProperty = "AccessControllerGetPrincipalModelPageRequestProperty",
    AccessControllerGetPrincipalModelPageRequestSort = "AccessControllerGetPrincipalModelPageRequestSort",
    AccessControllerGetPrincipalModelPageResponse = "AccessControllerGetPrincipalModelPageResponse",
    AccessControllerGetPrincipalModelPermissionFiltersRequest = "AccessControllerGetPrincipalModelPermissionFiltersRequest",
    AccessControllerGetPrincipalModelRequestFilters = "AccessControllerGetPrincipalModelRequestFilters",
    AccessControllerGetPrincipalPermissionFilterItemPageRequest = "AccessControllerGetPrincipalPermissionFilterItemPageRequest",
    AccessControllerGetPrincipalPermissionModelRequestFilters = "AccessControllerGetPrincipalPermissionModelRequestFilters",
    AccessControllerGetPrincipalPermissionPropertyItemsRequest = "AccessControllerGetPrincipalPermissionPropertyItemsRequest",
    AccessControllerGetPrincipalPermissionsModelPageRequest = "AccessControllerGetPrincipalPermissionsModelPageRequest",
    AccessControllerGetPrincipalPermissionSummaryResponse = "AccessControllerGetPrincipalPermissionSummaryResponse",
    AccessControllerGetPrincipalPropertyEntitySearchableReferencesResponse = "AccessControllerGetPrincipalPropertyEntitySearchableReferencesResponse",
    AccessControllerGetPrincipalPropertyItemsRequest = "AccessControllerGetPrincipalPropertyItemsRequest",
    AccessControllerGetPrincipalPropertyItemsResponse = "AccessControllerGetPrincipalPropertyItemsResponse",
    AccessControllerGetPrincipalSummaryRequest = "AccessControllerGetPrincipalSummaryRequest",
    AccessControllerGetPrincipalSummaryRequestType = "AccessControllerGetPrincipalSummaryRequestType",
    AccessControllerGetPrincipalSummaryResponse = "AccessControllerGetPrincipalSummaryResponse",
    AccessControllerGetPrincipalSummaryResponseResult = "AccessControllerGetPrincipalSummaryResponseResult",
    AccessGraph = "AccessGraph",
    AccessGraphDestinationEntityGroup = "AccessGraphDestinationEntityGroup",
    AccessGraphEntitiesNode = "AccessGraphEntitiesNode",
    AccessGraphGroup = "AccessGraphGroup",
    AccessGraphIdentityGroup = "AccessGraphIdentityGroup",
    AccessGraphOriginatorEntityGranteeEntity = "AccessGraphOriginatorEntityGranteeEntity",
    AccessGraphOriginatorEntityGroup = "AccessGraphOriginatorEntityGroup",
    AccessGraphPermissionEdgeFragmentedData = "AccessGraphPermissionEdgeFragmentedData",
    AccessGraphPermissionPath = "AccessGraphPermissionPath",
    AccessGraphPermissionPathActionsExcessiveness = "AccessGraphPermissionPathActionsExcessiveness",
    AccessGraphPermissionPathIdentifier = "AccessGraphPermissionPathIdentifier",
    AccessLevel = "AccessLevel",
    AccessListPermissionActionData = "AccessListPermissionActionData",
    AccessSettingConfiguration = "AccessSettingConfiguration",
    ActionRiskCategory = "ActionRiskCategory",
    Activity = "Activity",
    ActivityTimeType = "ActivityTimeType",
    AdministrationControllerConfigureTenantRequest = "AdministrationControllerConfigureTenantRequest",
    AdministrationControllerConfigureTenantResponse = "AdministrationControllerConfigureTenantResponse",
    AdministrationControllerDeleteSupportPrincipalRequest = "AdministrationControllerDeleteSupportPrincipalRequest",
    AdministrationControllerGetAuthenticationConfigurationResponse = "AdministrationControllerGetAuthenticationConfigurationResponse",
    AdministrationControllerGetAuthenticationUsersResponse = "AdministrationControllerGetAuthenticationUsersResponse",
    AdministrationControllerGetDataAnalysisRequest = "AdministrationControllerGetDataAnalysisRequest",
    AdministrationControllerGetDataAnalysisResponse = "AdministrationControllerGetDataAnalysisResponse",
    AdministrationControllerGetFeaturesResponse = "AdministrationControllerGetFeaturesResponse",
    AdministrationControllerGetInformationResponse = "AdministrationControllerGetInformationResponse",
    AdministrationControllerGetLicensingConfigurationResponse = "AdministrationControllerGetLicensingConfigurationResponse",
    AdministrationControllerGetLicensingObjectCountAveragePreviewRequest = "AdministrationControllerGetLicensingObjectCountAveragePreviewRequest",
    AdministrationControllerGetLicensingObjectCountAveragePreviewResponse = "AdministrationControllerGetLicensingObjectCountAveragePreviewResponse",
    AdministrationControllerGetOrchestrationStageDatasResponse = "AdministrationControllerGetOrchestrationStageDatasResponse",
    AdministrationControllerGetOrchestrationStageDatasResponseCustomerOrchestrationStageData = "AdministrationControllerGetOrchestrationStageDatasResponseCustomerOrchestrationStageData",
    AdministrationControllerGetOrchestrationStageDatasResponseOrchestrationStageData = "AdministrationControllerGetOrchestrationStageDatasResponseOrchestrationStageData",
    AdministrationControllerGetOrchestrationStageDatasResponseRootStagesOrchestrationStageData = "AdministrationControllerGetOrchestrationStageDatasResponseRootStagesOrchestrationStageData",
    AdministrationControllerGetOrchestrationStageDatasResponseStagesOrchestrationStageData = "AdministrationControllerGetOrchestrationStageDatasResponseStagesOrchestrationStageData",
    AdministrationControllerGetOrchestrationStageDatasResponseTenantOrchestrationStageData = "AdministrationControllerGetOrchestrationStageDatasResponseTenantOrchestrationStageData",
    AdministrationControllerGetSupportPrincipalsResponse = "AdministrationControllerGetSupportPrincipalsResponse",
    AdministrationControllerGetSupportPrincipalsResponseSupportPrincipalData = "AdministrationControllerGetSupportPrincipalsResponseSupportPrincipalData",
    AdministrationControllerGetWorkloadAnalysisRequest = "AdministrationControllerGetWorkloadAnalysisRequest",
    AdministrationControllerGetWorkloadAnalysisResponse = "AdministrationControllerGetWorkloadAnalysisResponse",
    AdministrationControllerOrchestrationStageStatus = "AdministrationControllerOrchestrationStageStatus",
    AdministrationControllerOrchestrationStageType = "AdministrationControllerOrchestrationStageType",
    AdministrationControllerResetCognitoUserPasswordError = "AdministrationControllerResetCognitoUserPasswordError",
    AdministrationControllerResetCognitoUserPasswordRequest = "AdministrationControllerResetCognitoUserPasswordRequest",
    AdministrationControllerResetCognitoUserPasswordResponse = "AdministrationControllerResetCognitoUserPasswordResponse",
    AdministrationControllerUpdateAuthenticationConfigurationError = "AdministrationControllerUpdateAuthenticationConfigurationError",
    AdministrationControllerUpdateAuthenticationConfigurationRequest = "AdministrationControllerUpdateAuthenticationConfigurationRequest",
    AdministrationControllerUpdateCustomerRequest = "AdministrationControllerUpdateCustomerRequest",
    AdministrationControllerUpdateDataAnalysisRequest = "AdministrationControllerUpdateDataAnalysisRequest",
    AdministrationControllerUpdateFeatureRequest = "AdministrationControllerUpdateFeatureRequest",
    AdministrationControllerUpdateLicensingRequest = "AdministrationControllerUpdateLicensingRequest",
    AdministrationControllerUpdateLogoRequest = "AdministrationControllerUpdateLogoRequest",
    AdministrationControllerUpdateWorkloadAnalysisRequest = "AdministrationControllerUpdateWorkloadAnalysisRequest",
    AdministrationControllerUpsertSupportPrincipalError = "AdministrationControllerUpsertSupportPrincipalError",
    AdministrationControllerUpsertSupportPrincipalRequest = "AdministrationControllerUpsertSupportPrincipalRequest",
    AdministratorPrincipalAttribute = "AdministratorPrincipalAttribute",
    AggregatedEntityRisk = "AggregatedEntityRisk",
    AggregatedEntityRiskModel = "AggregatedEntityRiskModel",
    AllEntityMatchCondition = "AllEntityMatchCondition",
    AmplitudeConfigurationSection = "AmplitudeConfigurationSection",
    AnalysisServiceConfiguration = "AnalysisServiceConfiguration",
    ApiKey = "ApiKey",
    ApiKeyAuditEvent = "ApiKeyAuditEvent",
    ApiKeyCreationAuditEvent = "ApiKeyCreationAuditEvent",
    ApiKeyDeletionAuditEvent = "ApiKeyDeletionAuditEvent",
    ApiKeyState = "ApiKeyState",
    ApiServiceConfiguration = "ApiServiceConfiguration",
    ApiUrlBuilderConfigurationSection = "ApiUrlBuilderConfigurationSection",
    ApplicationConfiguration = "ApplicationConfiguration",
    ApplicationCustomerConfiguration = "ApplicationCustomerConfiguration",
    ApplicationCustomerConfigurationAuthentication = "ApplicationCustomerConfigurationAuthentication",
    ApplicationCustomerConfigurationAuthenticationSamlIdentityProvider = "ApplicationCustomerConfigurationAuthenticationSamlIdentityProvider",
    ApplicationCustomerConfigurationAuthenticationSamlIdentityProviderType = "ApplicationCustomerConfigurationAuthenticationSamlIdentityProviderType",
    ApplicationCustomerConfigurationLicensing = "ApplicationCustomerConfigurationLicensing",
    ApplicationCustomerConfigurationLicensingEvaluationLicense = "ApplicationCustomerConfigurationLicensingEvaluationLicense",
    ApplicationCustomerConfigurationLicensingLicense = "ApplicationCustomerConfigurationLicensingLicense",
    ApplicationCustomerConfigurationLicensingLicenseType = "ApplicationCustomerConfigurationLicensingLicenseType",
    ApplicationCustomerConfigurationLicensingLicenseVersion = "ApplicationCustomerConfigurationLicensingLicenseVersion",
    ApplicationCustomerConfigurationLicensingSubscriptionLicense = "ApplicationCustomerConfigurationLicensingSubscriptionLicense",
    ApplicationDataResourceAttribute = "ApplicationDataResourceAttribute",
    ApplicationDataResourcePermissionActionPrincipalAttribute = "ApplicationDataResourcePermissionActionPrincipalAttribute",
    ApplicationPciDataResourceAttribute = "ApplicationPciDataResourceAttribute",
    ApplicationPciDataResourcePermissionActionPrincipalAttribute = "ApplicationPciDataResourcePermissionActionPrincipalAttribute",
    ApplicationPhiDataResourceAttribute = "ApplicationPhiDataResourceAttribute",
    ApplicationPhiDataResourcePermissionActionPrincipalAttribute = "ApplicationPhiDataResourcePermissionActionPrincipalAttribute",
    ApplicationPiiDataResourceAttribute = "ApplicationPiiDataResourceAttribute",
    ApplicationPiiDataResourcePermissionActionPrincipalAttribute = "ApplicationPiiDataResourcePermissionActionPrincipalAttribute",
    ApplicationSecretsDataResourceAttribute = "ApplicationSecretsDataResourceAttribute",
    ApplicationSecretsDataResourcePermissionActionPrincipalAttribute = "ApplicationSecretsDataResourcePermissionActionPrincipalAttribute",
    AuditEvent = "AuditEvent",
    AuditEventAutomationRuleConfiguration = "AuditEventAutomationRuleConfiguration",
    AuditEventAutomationRuleCreationAuditEvent = "AuditEventAutomationRuleCreationAuditEvent",
    AuditEventAutomationRuleDeletionAuditEvent = "AuditEventAutomationRuleDeletionAuditEvent",
    AuditEventAutomationRuleUpdateAuditEvent = "AuditEventAutomationRuleUpdateAuditEvent",
    AuditEventControllerDeleteRiskNoteCreationAuditEventRequest = "AuditEventControllerDeleteRiskNoteCreationAuditEventRequest",
    AuditEventControllerDeleteRiskStatusNoteAuditEventRequest = "AuditEventControllerDeleteRiskStatusNoteAuditEventRequest",
    AuditEventControllerDeleteRiskStatusNoteAuditEventResponse = "AuditEventControllerDeleteRiskStatusNoteAuditEventResponse",
    AuditEventControllerGetAuditEventFiltersRequest = "AuditEventControllerGetAuditEventFiltersRequest",
    AuditEventControllerGetAuditEventFiltersRequestBase = "AuditEventControllerGetAuditEventFiltersRequestBase",
    AuditEventControllerGetAuditEventFiltersResponse = "AuditEventControllerGetAuditEventFiltersResponse",
    AuditEventControllerGetAuditEventModelPageRequest = "AuditEventControllerGetAuditEventModelPageRequest",
    AuditEventControllerGetAuditEventModelPageRequestBase = "AuditEventControllerGetAuditEventModelPageRequestBase",
    AuditEventControllerGetAuditEventModelPageRequestFilters = "AuditEventControllerGetAuditEventModelPageRequestFilters",
    AuditEventControllerGetAuditEventModelPageRequestProperty = "AuditEventControllerGetAuditEventModelPageRequestProperty",
    AuditEventControllerGetAuditEventModelPageRequestSort = "AuditEventControllerGetAuditEventModelPageRequestSort",
    AuditEventControllerGetAuditEventModelPageResponse = "AuditEventControllerGetAuditEventModelPageResponse",
    AuditEventControllerGetAuditEventModelsRequest = "AuditEventControllerGetAuditEventModelsRequest",
    AuditEventControllerGetAuditEventModelsResponse = "AuditEventControllerGetAuditEventModelsResponse",
    AuditEventControllerGetAuditEventTypeNamesResponse = "AuditEventControllerGetAuditEventTypeNamesResponse",
    AuditEventControllerGetPermissionAuditEventFilterItemPageRequest = "AuditEventControllerGetPermissionAuditEventFilterItemPageRequest",
    AuditEventControllerGetPermissionAuditEventFilterItemPageResponse = "AuditEventControllerGetPermissionAuditEventFilterItemPageResponse",
    AuditEventControllerGetPermissionAuditEventFilterItemPageResponseBase = "AuditEventControllerGetPermissionAuditEventFilterItemPageResponseBase",
    AuditEventControllerGetPermissionAuditEventFiltersRequest = "AuditEventControllerGetPermissionAuditEventFiltersRequest",
    AuditEventControllerGetPermissionEligibilityAuditEventFiltersRequest = "AuditEventControllerGetPermissionEligibilityAuditEventFiltersRequest",
    AuditEventControllerGetPermissionEligibilityAuditEventModelPageRequest = "AuditEventControllerGetPermissionEligibilityAuditEventModelPageRequest",
    AuditEventControllerGetPermissionEligibilityAuditEventModelPageRequestFilters = "AuditEventControllerGetPermissionEligibilityAuditEventModelPageRequestFilters",
    AuditEventControllerGetPermissionEligibilityAuditEventModelPageRequestSort = "AuditEventControllerGetPermissionEligibilityAuditEventModelPageRequestSort",
    AuditEventControllerGetPermissionRequestAuditEventFiltersRequest = "AuditEventControllerGetPermissionRequestAuditEventFiltersRequest",
    AuditEventControllerGetPermissionRequestAuditEventModelPageRequest = "AuditEventControllerGetPermissionRequestAuditEventModelPageRequest",
    AuditEventControllerGetPermissionRequestAuditEventModelPageRequestFilters = "AuditEventControllerGetPermissionRequestAuditEventModelPageRequestFilters",
    AuditEventControllerGetPermissionRequestAuditEventModelPageRequestSort = "AuditEventControllerGetPermissionRequestAuditEventModelPageRequestSort",
    AuditEventControllerGetRiskAuditEventModelPageRequest = "AuditEventControllerGetRiskAuditEventModelPageRequest",
    AuditEventControllerInsertRiskNoteCreationAuditEventRequest = "AuditEventControllerInsertRiskNoteCreationAuditEventRequest",
    AuditEventControllerInsertRiskNoteCreationAuditEventResponse = "AuditEventControllerInsertRiskNoteCreationAuditEventResponse",
    AuditEventControllerUpdateRiskNoteCreationAuditEventRequest = "AuditEventControllerUpdateRiskNoteCreationAuditEventRequest",
    AuditEventControllerUpdateRiskNoteCreationAuditEventResponse = "AuditEventControllerUpdateRiskNoteCreationAuditEventResponse",
    AuditEventControllerUpdateRiskStatusUpdateAuditEventRequest = "AuditEventControllerUpdateRiskStatusUpdateAuditEventRequest",
    AuditEventControllerUpdateRiskStatusUpdateAuditEventResponse = "AuditEventControllerUpdateRiskStatusUpdateAuditEventResponse",
    AuditEventFilters = "AuditEventFilters",
    AuditEventModel = "AuditEventModel",
    AuthenticationConfigurationSection = "AuthenticationConfigurationSection",
    AuthenticationConfigurationSectionCognito = "AuthenticationConfigurationSectionCognito",
    AuthenticationConfigurationSectionOidc = "AuthenticationConfigurationSectionOidc",
    AuthenticationConfigurationSectionSaml = "AuthenticationConfigurationSectionSaml",
    AuthenticationControllerChangeCognitoUserPasswordRequest = "AuthenticationControllerChangeCognitoUserPasswordRequest",
    AuthenticationControllerChangeCognitoUserPasswordResponse = "AuthenticationControllerChangeCognitoUserPasswordResponse",
    AuthenticationControllerGetTeamsSignInDeploymentRedirectRequest = "AuthenticationControllerGetTeamsSignInDeploymentRedirectRequest",
    AuthenticationControllerGetTeamsSignInDeploymentRedirectResponse = "AuthenticationControllerGetTeamsSignInDeploymentRedirectResponse",
    AuthenticationControllerGetUserCustomerDataResponse = "AuthenticationControllerGetUserCustomerDataResponse",
    AuthenticationControllerGetUserCustomerDataResponseCustomer = "AuthenticationControllerGetUserCustomerDataResponseCustomer",
    AuthenticationControllerGetUserCustomerDatasResponse = "AuthenticationControllerGetUserCustomerDatasResponse",
    AuthenticationControllerGetUserIdentityProvidersRequest = "AuthenticationControllerGetUserIdentityProvidersRequest",
    AuthenticationControllerGetUserIdentityProvidersResponse = "AuthenticationControllerGetUserIdentityProvidersResponse",
    AuthenticationControllerGetUserResponse = "AuthenticationControllerGetUserResponse",
    AuthenticationControllerRecoverCognitoUserPasswordRequest = "AuthenticationControllerRecoverCognitoUserPasswordRequest",
    AuthenticationControllerRecoverCognitoUserPasswordResponse = "AuthenticationControllerRecoverCognitoUserPasswordResponse",
    AuthenticationControllerSetUserCustomerRequest = "AuthenticationControllerSetUserCustomerRequest",
    AuthenticationControllerSignInCognitoUserRequest = "AuthenticationControllerSignInCognitoUserRequest",
    AuthenticationControllerSignInCognitoUserResponse = "AuthenticationControllerSignInCognitoUserResponse",
    AuthenticationControllerSignInCognitoUserResponseResult = "AuthenticationControllerSignInCognitoUserResponseResult",
    AuthenticationControllerSignInTeamsRequest = "AuthenticationControllerSignInTeamsRequest",
    AuthenticationControllerSignInTeamsResponse = "AuthenticationControllerSignInTeamsResponse",
    AuthenticationControllerStartRecoverCognitoUserPasswordRequest = "AuthenticationControllerStartRecoverCognitoUserPasswordRequest",
    AuthenticationControllerStartRecoverCognitoUserPasswordResponse = "AuthenticationControllerStartRecoverCognitoUserPasswordResponse",
    AuthenticationControllerTeamsSignInError = "AuthenticationControllerTeamsSignInError",
    AuthorizationConfigurationSection = "AuthorizationConfigurationSection",
    AutomaticCustomEntityAttribute = "AutomaticCustomEntityAttribute",
    AutomaticCustomEntityAttributeDefinitionConfiguration = "AutomaticCustomEntityAttributeDefinitionConfiguration",
    AutomaticCustomEntityAttributeDefinitionTemplateAuditEvent = "AutomaticCustomEntityAttributeDefinitionTemplateAuditEvent",
    AutomaticCustomEntityAttributeDefinitionTemplateConfiguration = "AutomaticCustomEntityAttributeDefinitionTemplateConfiguration",
    AutomaticCustomEntityAttributeDefinitionTemplateConfigurationType = "AutomaticCustomEntityAttributeDefinitionTemplateConfigurationType",
    AutomaticCustomEntityAttributeDefinitionTemplateCreationAuditEvent = "AutomaticCustomEntityAttributeDefinitionTemplateCreationAuditEvent",
    AutomaticCustomEntityAttributeDefinitionTemplateDeletionAuditEvent = "AutomaticCustomEntityAttributeDefinitionTemplateDeletionAuditEvent",
    AutomaticCustomEntityAttributeDefinitionTemplateModel = "AutomaticCustomEntityAttributeDefinitionTemplateModel",
    AutomaticCustomEntityAttributeDefinitionTemplateUpdateAuditEvent = "AutomaticCustomEntityAttributeDefinitionTemplateUpdateAuditEvent",
    AutomationRuleAuditEvent = "AutomationRuleAuditEvent",
    AutomationRuleConfiguration = "AutomationRuleConfiguration",
    AutomationRuleDeliveryDestinationReference = "AutomationRuleDeliveryDestinationReference",
    AutomationRuleDeliveryDestinationState = "AutomationRuleDeliveryDestinationState",
    AutomationRuleState = "AutomationRuleState",
    AwsAccessAnalyzerAnalyzer = "AwsAccessAnalyzerAnalyzer",
    AwsAccessAnalyzerAnalyzerModel = "AwsAccessAnalyzerAnalyzerModel",
    AwsAccessAnalyzerAnalyzerStatus = "AwsAccessAnalyzerAnalyzerStatus",
    AwsAccessAnalyzerAnalyzerType = "AwsAccessAnalyzerAnalyzerType",
    AwsAccessAnalyzerRegionAnalyzerNotExistRisk = "AwsAccessAnalyzerRegionAnalyzerNotExistRisk",
    AwsAccessAnalyzerRegionAnalyzerNotExistRiskModel = "AwsAccessAnalyzerRegionAnalyzerNotExistRiskModel",
    AwsAccessAnalyzerRegionAnalyzerNotExistRiskPolicyConfiguration = "AwsAccessAnalyzerRegionAnalyzerNotExistRiskPolicyConfiguration",
    AwsAccessGraphPermissionPath = "AwsAccessGraphPermissionPath",
    AwsAccessGraphPermissionPathIdentifier = "AwsAccessGraphPermissionPathIdentifier",
    AwsAccessKeyEnabledUserAttribute = "AwsAccessKeyEnabledUserAttribute",
    AwsAccessListPermissionActionData = "AwsAccessListPermissionActionData",
    AwsAccessPrincipalRisk = "AwsAccessPrincipalRisk",
    AwsAccessPrincipalRiskModel = "AwsAccessPrincipalRiskModel",
    AwsAccessPrincipalRiskSensitiveResources = "AwsAccessPrincipalRiskSensitiveResources",
    AwsAcmCertificate = "AwsAcmCertificate",
    AwsAcmCertificateKeyMinLengthRisk = "AwsAcmCertificateKeyMinLengthRisk",
    AwsAcmCertificateKeyMinLengthRiskModel = "AwsAcmCertificateKeyMinLengthRiskModel",
    AwsAcmCertificateKeyMinLengthRiskPolicyConfiguration = "AwsAcmCertificateKeyMinLengthRiskPolicyConfiguration",
    AwsAcmCertificateKeyType = "AwsAcmCertificateKeyType",
    AwsAcmCertificateModel = "AwsAcmCertificateModel",
    AwsAcmCertificateModelFilters = "AwsAcmCertificateModelFilters",
    AwsAcmCertificateProfile = "AwsAcmCertificateProfile",
    AwsAcmCertificateRenewalEligibility = "AwsAcmCertificateRenewalEligibility",
    AwsAcmCertificateStatus = "AwsAcmCertificateStatus",
    AwsAcmCertificateType = "AwsAcmCertificateType",
    AwsActivityRisk = "AwsActivityRisk",
    AwsActivityRiskModel = "AwsActivityRiskModel",
    AwsAossCollection = "AwsAossCollection",
    AwsAossCollectionModel = "AwsAossCollectionModel",
    AwsAossCollectionProfile = "AwsAossCollectionProfile",
    AwsApiGatewayApi = "AwsApiGatewayApi",
    AwsApiGatewayApiEndpointType = "AwsApiGatewayApiEndpointType",
    AwsApiGatewayApiModel = "AwsApiGatewayApiModel",
    AwsApiGatewayApiProfile = "AwsApiGatewayApiProfile",
    AwsApiGatewayApiRoute = "AwsApiGatewayApiRoute",
    AwsApiGatewayApiRouteMethod = "AwsApiGatewayApiRouteMethod",
    AwsApiGatewayApiType = "AwsApiGatewayApiType",
    AwsApiGatewayApiWebAclNotExistsCodeRisk = "AwsApiGatewayApiWebAclNotExistsCodeRisk",
    AwsApiGatewayApiWebAclNotExistsCodeRiskModel = "AwsApiGatewayApiWebAclNotExistsCodeRiskModel",
    AwsApiGatewayApiWebAclNotExistsRisk = "AwsApiGatewayApiWebAclNotExistsRisk",
    AwsApiGatewayApiWebAclNotExistsRiskModel = "AwsApiGatewayApiWebAclNotExistsRiskModel",
    AwsApiGatewayApiWebAclNotExistsRiskPolicyConfiguration = "AwsApiGatewayApiWebAclNotExistsRiskPolicyConfiguration",
    AwsApiGatewayV1Api = "AwsApiGatewayV1Api",
    AwsApiGatewayV1ApiAuthorizationType = "AwsApiGatewayV1ApiAuthorizationType",
    AwsApiGatewayV1ApiAuthorizer = "AwsApiGatewayV1ApiAuthorizer",
    AwsApiGatewayV1ApiModel = "AwsApiGatewayV1ApiModel",
    AwsApiGatewayV1ApiProfile = "AwsApiGatewayV1ApiProfile",
    AwsApiGatewayV1ApiRoute = "AwsApiGatewayV1ApiRoute",
    AwsApiGatewayV1ApiRouteIntegration = "AwsApiGatewayV1ApiRouteIntegration",
    AwsApiGatewayV1ApiRouteIntegrationType = "AwsApiGatewayV1ApiRouteIntegrationType",
    AwsApiGatewayV2Api = "AwsApiGatewayV2Api",
    AwsApiGatewayV2ApiAuthorizationType = "AwsApiGatewayV2ApiAuthorizationType",
    AwsApiGatewayV2ApiAuthorizer = "AwsApiGatewayV2ApiAuthorizer",
    AwsApiGatewayV2ApiIntegration = "AwsApiGatewayV2ApiIntegration",
    AwsApiGatewayV2ApiModel = "AwsApiGatewayV2ApiModel",
    AwsApiGatewayV2ApiProfile = "AwsApiGatewayV2ApiProfile",
    AwsApiGatewayV2ApiRoute = "AwsApiGatewayV2ApiRoute",
    AwsApiGatewayV2ApiRouteIntegrationType = "AwsApiGatewayV2ApiRouteIntegrationType",
    AwsAppConfigApplication = "AwsAppConfigApplication",
    AwsAppSyncGraphQlApi = "AwsAppSyncGraphQlApi",
    AwsAssumeRolePolicyDocumentNonComputeServiceGranteeOnlyRoleAttribute = "AwsAssumeRolePolicyDocumentNonComputeServiceGranteeOnlyRoleAttribute",
    AwsAthenaWorkgroup = "AwsAthenaWorkgroup",
    AwsAthenaWorkgroupEncryptionDisabledCodeRisk = "AwsAthenaWorkgroupEncryptionDisabledCodeRisk",
    AwsAthenaWorkgroupEncryptionDisabledCodeRiskModel = "AwsAthenaWorkgroupEncryptionDisabledCodeRiskModel",
    AwsAthenaWorkgroupEncryptionDisabledRisk = "AwsAthenaWorkgroupEncryptionDisabledRisk",
    AwsAthenaWorkgroupEncryptionDisabledRiskModel = "AwsAthenaWorkgroupEncryptionDisabledRiskModel",
    AwsAthenaWorkgroupEncryptionDisabledRiskPolicyConfiguration = "AwsAthenaWorkgroupEncryptionDisabledRiskPolicyConfiguration",
    AwsAthenaWorkgroupLoggingDisabledCodeRisk = "AwsAthenaWorkgroupLoggingDisabledCodeRisk",
    AwsAthenaWorkgroupLoggingDisabledCodeRiskModel = "AwsAthenaWorkgroupLoggingDisabledCodeRiskModel",
    AwsAthenaWorkgroupLoggingDisabledRisk = "AwsAthenaWorkgroupLoggingDisabledRisk",
    AwsAthenaWorkgroupLoggingDisabledRiskModel = "AwsAthenaWorkgroupLoggingDisabledRiskModel",
    AwsAthenaWorkgroupLoggingDisabledRiskPolicyConfiguration = "AwsAthenaWorkgroupLoggingDisabledRiskPolicyConfiguration",
    AwsAthenaWorkgroupModel = "AwsAthenaWorkgroupModel",
    AwsAthenaWorkgroupProfile = "AwsAthenaWorkgroupProfile",
    AwsAuthenticationUser = "AwsAuthenticationUser",
    AwsAutoScalingAutoScalingGroup = "AwsAutoScalingAutoScalingGroup",
    AwsAutoScalingAutoScalingGroupModel = "AwsAutoScalingAutoScalingGroupModel",
    AwsAutoScalingAutoScalingGroupModelFilters = "AwsAutoScalingAutoScalingGroupModelFilters",
    AwsAutoScalingAutoScalingGroupProfile = "AwsAutoScalingAutoScalingGroupProfile",
    AwsAutoScalingLaunchConfiguration = "AwsAutoScalingLaunchConfiguration",
    AwsAutoScalingLaunchConfigurationModel = "AwsAutoScalingLaunchConfigurationModel",
    AwsAutoScalingLaunchConfigurationProfile = "AwsAutoScalingLaunchConfigurationProfile",
    AwsAutoScalingResource = "AwsAutoScalingResource",
    AwsAvailabilityZone = "AwsAvailabilityZone",
    AwsBatchComputeEnvironment = "AwsBatchComputeEnvironment",
    AwsBatchComputeEnvironmentModel = "AwsBatchComputeEnvironmentModel",
    AwsBatchComputeEnvironmentType = "AwsBatchComputeEnvironmentType",
    AwsBatchComputeResource = "AwsBatchComputeResource",
    AwsBatchJobDefinition = "AwsBatchJobDefinition",
    AwsBatchJobDefinitionModel = "AwsBatchJobDefinitionModel",
    AwsBatchJobDefinitionProfile = "AwsBatchJobDefinitionProfile",
    AwsBatchResource = "AwsBatchResource",
    AwsBedrockAgent = "AwsBedrockAgent",
    AwsBedrockAgentAlias = "AwsBedrockAgentAlias",
    AwsBedrockAgentModel = "AwsBedrockAgentModel",
    AwsBedrockAgentModelFilters = "AwsBedrockAgentModelFilters",
    AwsBedrockAgentProfile = "AwsBedrockAgentProfile",
    AwsBedrockAgentRevision = "AwsBedrockAgentRevision",
    AwsBedrockAgentStatus = "AwsBedrockAgentStatus",
    AwsBedrockCustomModel = "AwsBedrockCustomModel",
    AwsBedrockCustomModelCustomizationType = "AwsBedrockCustomModelCustomizationType",
    AwsBedrockCustomModelModel = "AwsBedrockCustomModelModel",
    AwsBedrockCustomModelProfile = "AwsBedrockCustomModelProfile",
    AwsBehaviorIdentityRisk = "AwsBehaviorIdentityRisk",
    AwsBehaviorIdentityRiskModel = "AwsBehaviorIdentityRiskModel",
    AwsBehaviorIdentityRiskModelInfo = "AwsBehaviorIdentityRiskModelInfo",
    AwsBehaviorIdentityRiskPolicyGroupFilters = "AwsBehaviorIdentityRiskPolicyGroupFilters",
    AwsChange = "AwsChange",
    AwsCloudFormationChangeSet = "AwsCloudFormationChangeSet",
    AwsCloudFormationChangeSetModel = "AwsCloudFormationChangeSetModel",
    AwsCloudFormationStack = "AwsCloudFormationStack",
    AwsCloudFormationStackDriftStatus = "AwsCloudFormationStackDriftStatus",
    AwsCloudFormationStackModel = "AwsCloudFormationStackModel",
    AwsCloudFormationStackOutput = "AwsCloudFormationStackOutput",
    AwsCloudFormationStackParameter = "AwsCloudFormationStackParameter",
    AwsCloudFormationStackProfile = "AwsCloudFormationStackProfile",
    AwsCloudFormationStackSecretExistsCodeRisk = "AwsCloudFormationStackSecretExistsCodeRisk",
    AwsCloudFormationStackSecretExistsCodeRiskModel = "AwsCloudFormationStackSecretExistsCodeRiskModel",
    AwsCloudFormationStackSecretExistsRisk = "AwsCloudFormationStackSecretExistsRisk",
    AwsCloudFormationStackSecretExistsRiskModel = "AwsCloudFormationStackSecretExistsRiskModel",
    AwsCloudFormationStackSecretExistsRiskPolicyConfiguration = "AwsCloudFormationStackSecretExistsRiskPolicyConfiguration",
    AwsCloudFormationStackStatus = "AwsCloudFormationStackStatus",
    AwsCloudFrontDistribution = "AwsCloudFrontDistribution",
    AwsCloudFrontDistributionBehavior = "AwsCloudFrontDistributionBehavior",
    AwsCloudFrontDistributionBehaviorViewerProtocolPolicy = "AwsCloudFrontDistributionBehaviorViewerProtocolPolicy",
    AwsCloudFrontDistributionDefaultSslCertificateCodeRisk = "AwsCloudFrontDistributionDefaultSslCertificateCodeRisk",
    AwsCloudFrontDistributionDefaultSslCertificateCodeRiskModel = "AwsCloudFrontDistributionDefaultSslCertificateCodeRiskModel",
    AwsCloudFrontDistributionDefaultSslCertificateRisk = "AwsCloudFrontDistributionDefaultSslCertificateRisk",
    AwsCloudFrontDistributionDefaultSslCertificateRiskModel = "AwsCloudFrontDistributionDefaultSslCertificateRiskModel",
    AwsCloudFrontDistributionDefaultSslCertificateRiskPolicyConfiguration = "AwsCloudFrontDistributionDefaultSslCertificateRiskPolicyConfiguration",
    AwsCloudFrontDistributionHttpsOnlyDisabledCodeRisk = "AwsCloudFrontDistributionHttpsOnlyDisabledCodeRisk",
    AwsCloudFrontDistributionHttpsOnlyDisabledCodeRiskModel = "AwsCloudFrontDistributionHttpsOnlyDisabledCodeRiskModel",
    AwsCloudFrontDistributionHttpsOnlyDisabledRisk = "AwsCloudFrontDistributionHttpsOnlyDisabledRisk",
    AwsCloudFrontDistributionHttpsOnlyDisabledRiskModel = "AwsCloudFrontDistributionHttpsOnlyDisabledRiskModel",
    AwsCloudFrontDistributionHttpsOnlyDisabledRiskPolicyConfiguration = "AwsCloudFrontDistributionHttpsOnlyDisabledRiskPolicyConfiguration",
    AwsCloudFrontDistributionInsecureTlsProtocolCodeRisk = "AwsCloudFrontDistributionInsecureTlsProtocolCodeRisk",
    AwsCloudFrontDistributionInsecureTlsProtocolCodeRiskModel = "AwsCloudFrontDistributionInsecureTlsProtocolCodeRiskModel",
    AwsCloudFrontDistributionInsecureTlsProtocolRisk = "AwsCloudFrontDistributionInsecureTlsProtocolRisk",
    AwsCloudFrontDistributionInsecureTlsProtocolRiskModel = "AwsCloudFrontDistributionInsecureTlsProtocolRiskModel",
    AwsCloudFrontDistributionInsecureTlsProtocolRiskPolicyConfiguration = "AwsCloudFrontDistributionInsecureTlsProtocolRiskPolicyConfiguration",
    AwsCloudFrontDistributionLoggingDisabledCodeRisk = "AwsCloudFrontDistributionLoggingDisabledCodeRisk",
    AwsCloudFrontDistributionLoggingDisabledCodeRiskModel = "AwsCloudFrontDistributionLoggingDisabledCodeRiskModel",
    AwsCloudFrontDistributionLoggingDisabledRisk = "AwsCloudFrontDistributionLoggingDisabledRisk",
    AwsCloudFrontDistributionLoggingDisabledRiskModel = "AwsCloudFrontDistributionLoggingDisabledRiskModel",
    AwsCloudFrontDistributionLoggingDisabledRiskPolicyConfiguration = "AwsCloudFrontDistributionLoggingDisabledRiskPolicyConfiguration",
    AwsCloudFrontDistributionMisconfigurationRisk = "AwsCloudFrontDistributionMisconfigurationRisk",
    AwsCloudFrontDistributionModel = "AwsCloudFrontDistributionModel",
    AwsCloudFrontDistributionOrigin = "AwsCloudFrontDistributionOrigin",
    AwsCloudFrontDistributionOriginAccess = "AwsCloudFrontDistributionOriginAccess",
    AwsCloudFrontDistributionOriginHttpsOnlyDisabledCodeRisk = "AwsCloudFrontDistributionOriginHttpsOnlyDisabledCodeRisk",
    AwsCloudFrontDistributionOriginHttpsOnlyDisabledCodeRiskModel = "AwsCloudFrontDistributionOriginHttpsOnlyDisabledCodeRiskModel",
    AwsCloudFrontDistributionOriginHttpsOnlyDisabledRisk = "AwsCloudFrontDistributionOriginHttpsOnlyDisabledRisk",
    AwsCloudFrontDistributionOriginHttpsOnlyDisabledRiskModel = "AwsCloudFrontDistributionOriginHttpsOnlyDisabledRiskModel",
    AwsCloudFrontDistributionOriginHttpsOnlyDisabledRiskPolicyConfiguration = "AwsCloudFrontDistributionOriginHttpsOnlyDisabledRiskPolicyConfiguration",
    AwsCloudFrontDistributionOriginProtocolPolicy = "AwsCloudFrontDistributionOriginProtocolPolicy",
    AwsCloudFrontDistributionOriginSslProtocol = "AwsCloudFrontDistributionOriginSslProtocol",
    AwsCloudFrontDistributionOriginType = "AwsCloudFrontDistributionOriginType",
    AwsCloudFrontDistributionProfile = "AwsCloudFrontDistributionProfile",
    AwsCloudFrontDistributionRootObjectNotExistsCodeRisk = "AwsCloudFrontDistributionRootObjectNotExistsCodeRisk",
    AwsCloudFrontDistributionRootObjectNotExistsCodeRiskModel = "AwsCloudFrontDistributionRootObjectNotExistsCodeRiskModel",
    AwsCloudFrontDistributionRootObjectNotExistsRisk = "AwsCloudFrontDistributionRootObjectNotExistsRisk",
    AwsCloudFrontDistributionRootObjectNotExistsRiskModel = "AwsCloudFrontDistributionRootObjectNotExistsRiskModel",
    AwsCloudFrontDistributionRootObjectNotExistsRiskPolicyConfiguration = "AwsCloudFrontDistributionRootObjectNotExistsRiskPolicyConfiguration",
    AwsCloudFrontDistributionS3BucketOriginAccessControlDisabledCodeRisk = "AwsCloudFrontDistributionS3BucketOriginAccessControlDisabledCodeRisk",
    AwsCloudFrontDistributionS3BucketOriginAccessControlDisabledCodeRiskModel = "AwsCloudFrontDistributionS3BucketOriginAccessControlDisabledCodeRiskModel",
    AwsCloudFrontDistributionS3BucketOriginAccessControlDisabledRisk = "AwsCloudFrontDistributionS3BucketOriginAccessControlDisabledRisk",
    AwsCloudFrontDistributionS3BucketOriginAccessControlDisabledRiskModel = "AwsCloudFrontDistributionS3BucketOriginAccessControlDisabledRiskModel",
    AwsCloudFrontDistributionS3BucketOriginAccessControlDisabledRiskPolicyConfiguration = "AwsCloudFrontDistributionS3BucketOriginAccessControlDisabledRiskPolicyConfiguration",
    AwsCloudFrontDistributionStatus = "AwsCloudFrontDistributionStatus",
    AwsCloudFrontDistributionWebAclNotExistsCodeRisk = "AwsCloudFrontDistributionWebAclNotExistsCodeRisk",
    AwsCloudFrontDistributionWebAclNotExistsCodeRiskModel = "AwsCloudFrontDistributionWebAclNotExistsCodeRiskModel",
    AwsCloudFrontDistributionWebAclNotExistsRisk = "AwsCloudFrontDistributionWebAclNotExistsRisk",
    AwsCloudFrontDistributionWebAclNotExistsRiskModel = "AwsCloudFrontDistributionWebAclNotExistsRiskModel",
    AwsCloudFrontDistributionWebAclNotExistsRiskPolicyConfiguration = "AwsCloudFrontDistributionWebAclNotExistsRiskPolicyConfiguration",
    AwsCloudTrailTrail = "AwsCloudTrailTrail",
    AwsCloudTrailTrailAdvancedEventSelector = "AwsCloudTrailTrailAdvancedEventSelector",
    AwsCloudTrailTrailAdvancedEventSelectorFieldSelector = "AwsCloudTrailTrailAdvancedEventSelectorFieldSelector",
    AwsCloudTrailTrailAdvancedEventSelectorFieldSelectorOperator = "AwsCloudTrailTrailAdvancedEventSelectorFieldSelectorOperator",
    AwsCloudTrailTrailAdvancedEventSelectorFieldSelectorType = "AwsCloudTrailTrailAdvancedEventSelectorFieldSelectorType",
    AwsCloudTrailTrailBasicEventSelector = "AwsCloudTrailTrailBasicEventSelector",
    AwsCloudTrailTrailBucketComplianceEntityRisk = "AwsCloudTrailTrailBucketComplianceEntityRisk",
    AwsCloudTrailTrailBucketEventDisabledCodeRisk = "AwsCloudTrailTrailBucketEventDisabledCodeRisk",
    AwsCloudTrailTrailBucketEventDisabledCodeRiskModel = "AwsCloudTrailTrailBucketEventDisabledCodeRiskModel",
    AwsCloudTrailTrailBucketEventDisabledRisk = "AwsCloudTrailTrailBucketEventDisabledRisk",
    AwsCloudTrailTrailBucketEventDisabledRiskModel = "AwsCloudTrailTrailBucketEventDisabledRiskModel",
    AwsCloudTrailTrailBucketEventDisabledRiskPolicyConfiguration = "AwsCloudTrailTrailBucketEventDisabledRiskPolicyConfiguration",
    AwsCloudTrailTrailBucketPublicAccessEnabledCodeRisk = "AwsCloudTrailTrailBucketPublicAccessEnabledCodeRisk",
    AwsCloudTrailTrailBucketPublicAccessEnabledCodeRiskModel = "AwsCloudTrailTrailBucketPublicAccessEnabledCodeRiskModel",
    AwsCloudTrailTrailBucketPublicAccessEnabledRisk = "AwsCloudTrailTrailBucketPublicAccessEnabledRisk",
    AwsCloudTrailTrailBucketPublicAccessEnabledRiskModel = "AwsCloudTrailTrailBucketPublicAccessEnabledRiskModel",
    AwsCloudTrailTrailBucketPublicAccessEnabledRiskPolicyConfiguration = "AwsCloudTrailTrailBucketPublicAccessEnabledRiskPolicyConfiguration",
    AwsCloudTrailTrailDigestDisabledCodeRisk = "AwsCloudTrailTrailDigestDisabledCodeRisk",
    AwsCloudTrailTrailDigestDisabledCodeRiskModel = "AwsCloudTrailTrailDigestDisabledCodeRiskModel",
    AwsCloudTrailTrailDigestDisabledRisk = "AwsCloudTrailTrailDigestDisabledRisk",
    AwsCloudTrailTrailDigestDisabledRiskModel = "AwsCloudTrailTrailDigestDisabledRiskModel",
    AwsCloudTrailTrailDigestDisabledRiskPolicyConfiguration = "AwsCloudTrailTrailDigestDisabledRiskPolicyConfiguration",
    AwsCloudTrailTrailEncryptionDisabledCodeRisk = "AwsCloudTrailTrailEncryptionDisabledCodeRisk",
    AwsCloudTrailTrailEncryptionDisabledCodeRiskModel = "AwsCloudTrailTrailEncryptionDisabledCodeRiskModel",
    AwsCloudTrailTrailEncryptionDisabledRisk = "AwsCloudTrailTrailEncryptionDisabledRisk",
    AwsCloudTrailTrailEncryptionDisabledRiskModel = "AwsCloudTrailTrailEncryptionDisabledRiskModel",
    AwsCloudTrailTrailEncryptionDisabledRiskPolicyConfiguration = "AwsCloudTrailTrailEncryptionDisabledRiskPolicyConfiguration",
    AwsCloudTrailTrailEventCategory = "AwsCloudTrailTrailEventCategory",
    AwsCloudTrailTrailEventSelectorType = "AwsCloudTrailTrailEventSelectorType",
    AwsCloudTrailTrailLogGroupDisabledCodeRisk = "AwsCloudTrailTrailLogGroupDisabledCodeRisk",
    AwsCloudTrailTrailLogGroupDisabledCodeRiskModel = "AwsCloudTrailTrailLogGroupDisabledCodeRiskModel",
    AwsCloudTrailTrailLogGroupDisabledRisk = "AwsCloudTrailTrailLogGroupDisabledRisk",
    AwsCloudTrailTrailLogGroupDisabledRiskModel = "AwsCloudTrailTrailLogGroupDisabledRiskModel",
    AwsCloudTrailTrailLogGroupDisabledRiskPolicyConfiguration = "AwsCloudTrailTrailLogGroupDisabledRiskPolicyConfiguration",
    AwsCloudTrailTrailModel = "AwsCloudTrailTrailModel",
    AwsCloudTrailTrailMultiRegionNotExistRisk = "AwsCloudTrailTrailMultiRegionNotExistRisk",
    AwsCloudTrailTrailMultiRegionNotExistRiskModel = "AwsCloudTrailTrailMultiRegionNotExistRiskModel",
    AwsCloudTrailTrailMultiRegionNotExistRiskPolicyConfiguration = "AwsCloudTrailTrailMultiRegionNotExistRiskPolicyConfiguration",
    AwsCloudTrailTrailProfile = "AwsCloudTrailTrailProfile",
    AwsCloudTrailTrailS3ObjectReadEventSelectorNotExistRisk = "AwsCloudTrailTrailS3ObjectReadEventSelectorNotExistRisk",
    AwsCloudTrailTrailS3ObjectReadEventSelectorNotExistRiskModel = "AwsCloudTrailTrailS3ObjectReadEventSelectorNotExistRiskModel",
    AwsCloudTrailTrailS3ObjectReadEventSelectorNotExistRiskPolicyConfiguration = "AwsCloudTrailTrailS3ObjectReadEventSelectorNotExistRiskPolicyConfiguration",
    AwsCloudTrailTrailS3ObjectWriteEventSelectorNotExistRisk = "AwsCloudTrailTrailS3ObjectWriteEventSelectorNotExistRisk",
    AwsCloudTrailTrailS3ObjectWriteEventSelectorNotExistRiskModel = "AwsCloudTrailTrailS3ObjectWriteEventSelectorNotExistRiskModel",
    AwsCloudTrailTrailS3ObjectWriteEventSelectorNotExistRiskPolicyConfiguration = "AwsCloudTrailTrailS3ObjectWriteEventSelectorNotExistRiskPolicyConfiguration",
    AwsCloudWatchAlarm = "AwsCloudWatchAlarm",
    AwsCloudWatchAlarmCloudTrailChangesNotExistRisk = "AwsCloudWatchAlarmCloudTrailChangesNotExistRisk",
    AwsCloudWatchAlarmCloudTrailChangesNotExistRiskModel = "AwsCloudWatchAlarmCloudTrailChangesNotExistRiskModel",
    AwsCloudWatchAlarmCloudTrailChangesNotExistRiskPolicyConfiguration = "AwsCloudWatchAlarmCloudTrailChangesNotExistRiskPolicyConfiguration",
    AwsCloudWatchAlarmConfigChangesNotExistRisk = "AwsCloudWatchAlarmConfigChangesNotExistRisk",
    AwsCloudWatchAlarmConfigChangesNotExistRiskModel = "AwsCloudWatchAlarmConfigChangesNotExistRiskModel",
    AwsCloudWatchAlarmConfigChangesNotExistRiskPolicyConfiguration = "AwsCloudWatchAlarmConfigChangesNotExistRiskPolicyConfiguration",
    AwsCloudWatchAlarmEc2InternetGatewayChangesNotExistRisk = "AwsCloudWatchAlarmEc2InternetGatewayChangesNotExistRisk",
    AwsCloudWatchAlarmEc2InternetGatewayChangesNotExistRiskModel = "AwsCloudWatchAlarmEc2InternetGatewayChangesNotExistRiskModel",
    AwsCloudWatchAlarmEc2InternetGatewayChangesNotExistRiskPolicyConfiguration = "AwsCloudWatchAlarmEc2InternetGatewayChangesNotExistRiskPolicyConfiguration",
    AwsCloudWatchAlarmEc2NetworkAclChangesNotExistRisk = "AwsCloudWatchAlarmEc2NetworkAclChangesNotExistRisk",
    AwsCloudWatchAlarmEc2NetworkAclChangesNotExistRiskModel = "AwsCloudWatchAlarmEc2NetworkAclChangesNotExistRiskModel",
    AwsCloudWatchAlarmEc2NetworkAclChangesNotExistRiskPolicyConfiguration = "AwsCloudWatchAlarmEc2NetworkAclChangesNotExistRiskPolicyConfiguration",
    AwsCloudWatchAlarmEc2RouteTableChangesNotExistRisk = "AwsCloudWatchAlarmEc2RouteTableChangesNotExistRisk",
    AwsCloudWatchAlarmEc2RouteTableChangesNotExistRiskModel = "AwsCloudWatchAlarmEc2RouteTableChangesNotExistRiskModel",
    AwsCloudWatchAlarmEc2RouteTableChangesNotExistRiskPolicyConfiguration = "AwsCloudWatchAlarmEc2RouteTableChangesNotExistRiskPolicyConfiguration",
    AwsCloudWatchAlarmEc2SecurityGroupChangesNotExistRisk = "AwsCloudWatchAlarmEc2SecurityGroupChangesNotExistRisk",
    AwsCloudWatchAlarmEc2SecurityGroupChangesNotExistRiskModel = "AwsCloudWatchAlarmEc2SecurityGroupChangesNotExistRiskModel",
    AwsCloudWatchAlarmEc2SecurityGroupChangesNotExistRiskPolicyConfiguration = "AwsCloudWatchAlarmEc2SecurityGroupChangesNotExistRiskPolicyConfiguration",
    AwsCloudWatchAlarmEc2VpcChangesNotExistRisk = "AwsCloudWatchAlarmEc2VpcChangesNotExistRisk",
    AwsCloudWatchAlarmEc2VpcChangesNotExistRiskModel = "AwsCloudWatchAlarmEc2VpcChangesNotExistRiskModel",
    AwsCloudWatchAlarmEc2VpcChangesNotExistRiskPolicyConfiguration = "AwsCloudWatchAlarmEc2VpcChangesNotExistRiskPolicyConfiguration",
    AwsCloudWatchAlarmIamPolicyChangesNotExistRisk = "AwsCloudWatchAlarmIamPolicyChangesNotExistRisk",
    AwsCloudWatchAlarmIamPolicyChangesNotExistRiskModel = "AwsCloudWatchAlarmIamPolicyChangesNotExistRiskModel",
    AwsCloudWatchAlarmIamPolicyChangesNotExistRiskPolicyConfiguration = "AwsCloudWatchAlarmIamPolicyChangesNotExistRiskPolicyConfiguration",
    AwsCloudWatchAlarmKmsCustomerMasterKeyDisabledNotExistRisk = "AwsCloudWatchAlarmKmsCustomerMasterKeyDisabledNotExistRisk",
    AwsCloudWatchAlarmKmsCustomerMasterKeyDisabledNotExistRiskModel = "AwsCloudWatchAlarmKmsCustomerMasterKeyDisabledNotExistRiskModel",
    AwsCloudWatchAlarmKmsCustomerMasterKeyDisabledNotExistRiskPolicyConfiguration = "AwsCloudWatchAlarmKmsCustomerMasterKeyDisabledNotExistRiskPolicyConfiguration",
    AwsCloudWatchAlarmManagementConsoleAuthenticationFailuresNotExistRisk = "AwsCloudWatchAlarmManagementConsoleAuthenticationFailuresNotExistRisk",
    AwsCloudWatchAlarmManagementConsoleAuthenticationFailuresNotExistRiskModel = "AwsCloudWatchAlarmManagementConsoleAuthenticationFailuresNotExistRiskModel",
    AwsCloudWatchAlarmManagementConsoleAuthenticationFailuresNotExistRiskPolicyConfiguration = "AwsCloudWatchAlarmManagementConsoleAuthenticationFailuresNotExistRiskPolicyConfiguration",
    AwsCloudWatchAlarmManagementConsoleMfaDisabledNotExistRisk = "AwsCloudWatchAlarmManagementConsoleMfaDisabledNotExistRisk",
    AwsCloudWatchAlarmManagementConsoleMfaDisabledNotExistRiskModel = "AwsCloudWatchAlarmManagementConsoleMfaDisabledNotExistRiskModel",
    AwsCloudWatchAlarmManagementConsoleMfaDisabledNotExistRiskPolicyConfiguration = "AwsCloudWatchAlarmManagementConsoleMfaDisabledNotExistRiskPolicyConfiguration",
    AwsCloudWatchAlarmModel = "AwsCloudWatchAlarmModel",
    AwsCloudWatchAlarmNotExistRisk = "AwsCloudWatchAlarmNotExistRisk",
    AwsCloudWatchAlarmNotExistRiskModel = "AwsCloudWatchAlarmNotExistRiskModel",
    AwsCloudWatchAlarmNotExistRiskRequirement = "AwsCloudWatchAlarmNotExistRiskRequirement",
    AwsCloudWatchAlarmNotExistRiskTypeMetadata = "AwsCloudWatchAlarmNotExistRiskTypeMetadata",
    AwsCloudWatchAlarmOrganizationsOrganizationChangesNotExistRisk = "AwsCloudWatchAlarmOrganizationsOrganizationChangesNotExistRisk",
    AwsCloudWatchAlarmOrganizationsOrganizationChangesNotExistRiskModel = "AwsCloudWatchAlarmOrganizationsOrganizationChangesNotExistRiskModel",
    AwsCloudWatchAlarmOrganizationsOrganizationChangesNotExistRiskPolicyConfiguration = "AwsCloudWatchAlarmOrganizationsOrganizationChangesNotExistRiskPolicyConfiguration",
    AwsCloudWatchAlarmRootUsageNotExistRisk = "AwsCloudWatchAlarmRootUsageNotExistRisk",
    AwsCloudWatchAlarmRootUsageNotExistRiskModel = "AwsCloudWatchAlarmRootUsageNotExistRiskModel",
    AwsCloudWatchAlarmRootUsageNotExistRiskPolicyConfiguration = "AwsCloudWatchAlarmRootUsageNotExistRiskPolicyConfiguration",
    AwsCloudWatchAlarmS3BucketPolicyChangesNotExistRisk = "AwsCloudWatchAlarmS3BucketPolicyChangesNotExistRisk",
    AwsCloudWatchAlarmS3BucketPolicyChangesNotExistRiskModel = "AwsCloudWatchAlarmS3BucketPolicyChangesNotExistRiskModel",
    AwsCloudWatchAlarmS3BucketPolicyChangesNotExistRiskPolicyConfiguration = "AwsCloudWatchAlarmS3BucketPolicyChangesNotExistRiskPolicyConfiguration",
    AwsCloudWatchAlarmUnauthorizedApiCallsNotExistRisk = "AwsCloudWatchAlarmUnauthorizedApiCallsNotExistRisk",
    AwsCloudWatchAlarmUnauthorizedApiCallsNotExistRiskModel = "AwsCloudWatchAlarmUnauthorizedApiCallsNotExistRiskModel",
    AwsCloudWatchAlarmUnauthorizedApiCallsNotExistRiskPolicyConfiguration = "AwsCloudWatchAlarmUnauthorizedApiCallsNotExistRiskPolicyConfiguration",
    AwsCognitoIdpUserPool = "AwsCognitoIdpUserPool",
    AwsConfigConfigurationRecorder = "AwsConfigConfigurationRecorder",
    AwsConfigConfigurationRecorderModel = "AwsConfigConfigurationRecorderModel",
    AwsConfigConfigurationRecorderStatus = "AwsConfigConfigurationRecorderStatus",
    AwsConfigRegionConfigurationRecorderNotExistRisk = "AwsConfigRegionConfigurationRecorderNotExistRisk",
    AwsConfigRegionConfigurationRecorderNotExistRiskModel = "AwsConfigRegionConfigurationRecorderNotExistRiskModel",
    AwsConfigRegionConfigurationRecorderNotExistRiskPolicyConfiguration = "AwsConfigRegionConfigurationRecorderNotExistRiskPolicyConfiguration",
    AwsConsoleView = "AwsConsoleView",
    AwsContainerImage = "AwsContainerImage",
    AwsContainerImageModel = "AwsContainerImageModel",
    AwsContainerImageModelFilters = "AwsContainerImageModelFilters",
    AwsCustomActivityRiskModel = "AwsCustomActivityRiskModel",
    AwsCustomRiskModel = "AwsCustomRiskModel",
    AwsDataLeakageRisk = "AwsDataLeakageRisk",
    AwsDataLeakageRiskModel = "AwsDataLeakageRiskModel",
    AwsDataLeakageRiskModelDescriptionType = "AwsDataLeakageRiskModelDescriptionType",
    AwsDataLeakageRiskPolicyConfiguration = "AwsDataLeakageRiskPolicyConfiguration",
    AwsDeleteBucketPolicyChange = "AwsDeleteBucketPolicyChange",
    AwsDeleteGroupChange = "AwsDeleteGroupChange",
    AwsDeleteInlinePolicyChange = "AwsDeleteInlinePolicyChange",
    AwsDeletePermissionSetAccountAssignmentsChange = "AwsDeletePermissionSetAccountAssignmentsChange",
    AwsDeletePermissionSetChange = "AwsDeletePermissionSetChange",
    AwsDeletePermissionSetInlinePolicyChange = "AwsDeletePermissionSetInlinePolicyChange",
    AwsDeletePrincipalChange = "AwsDeletePrincipalChange",
    AwsDeleteRepositoryPolicyChange = "AwsDeleteRepositoryPolicyChange",
    AwsDeleteResourcePolicyChange = "AwsDeleteResourcePolicyChange",
    AwsDeleteRoleChange = "AwsDeleteRoleChange",
    AwsDeleteSecretPolicyChange = "AwsDeleteSecretPolicyChange",
    AwsDeleteSecurityGroupChange = "AwsDeleteSecurityGroupChange",
    AwsDeleteSecurityGroupReferencingSecurityGroupRulesChange = "AwsDeleteSecurityGroupReferencingSecurityGroupRulesChange",
    AwsDeleteUserChange = "AwsDeleteUserChange",
    AwsDeleteUserGroupMembershipChange = "AwsDeleteUserGroupMembershipChange",
    AwsDeleteUserPasswordChange = "AwsDeleteUserPasswordChange",
    AwsDetachPermissionSetManagedPolicyChange = "AwsDetachPermissionSetManagedPolicyChange",
    AwsDetachPrincipalManagedPolicyChange = "AwsDetachPrincipalManagedPolicyChange",
    AwsDisableUserAccessKeyChange = "AwsDisableUserAccessKeyChange",
    AwsDocDbClusterBackupRetentionTimeFrameCodeRisk = "AwsDocDbClusterBackupRetentionTimeFrameCodeRisk",
    AwsDocDbClusterBackupRetentionTimeFrameCodeRiskModel = "AwsDocDbClusterBackupRetentionTimeFrameCodeRiskModel",
    AwsDocDbClusterBackupRetentionTimeFrameRisk = "AwsDocDbClusterBackupRetentionTimeFrameRisk",
    AwsDocDbClusterBackupRetentionTimeFrameRiskModel = "AwsDocDbClusterBackupRetentionTimeFrameRiskModel",
    AwsDocDbClusterBackupRetentionTimeFrameRiskPolicyConfiguration = "AwsDocDbClusterBackupRetentionTimeFrameRiskPolicyConfiguration",
    AwsDocDbClusterBackupRetentionTimeFrameRiskPolicyUpdateAuditEventBackupRetentionTimeFrameChange = "AwsDocDbClusterBackupRetentionTimeFrameRiskPolicyUpdateAuditEventBackupRetentionTimeFrameChange",
    AwsDocDbClusterModelType = "AwsDocDbClusterModelType",
    AwsDocDbElasticCluster = "AwsDocDbElasticCluster",
    AwsDocDbElasticClusterModel = "AwsDocDbElasticClusterModel",
    AwsDocDbElasticClusterProfile = "AwsDocDbElasticClusterProfile",
    AwsDocDbElasticClusterSnapshot = "AwsDocDbElasticClusterSnapshot",
    AwsDocDbElasticClusterSnapshotModel = "AwsDocDbElasticClusterSnapshotModel",
    AwsDocDbElasticClusterSnapshotProfile = "AwsDocDbElasticClusterSnapshotProfile",
    AwsDynamoDbTable = "AwsDynamoDbTable",
    AwsDynamoDbTableDeleteProtectionDisabledCodeRisk = "AwsDynamoDbTableDeleteProtectionDisabledCodeRisk",
    AwsDynamoDbTableDeleteProtectionDisabledCodeRiskModel = "AwsDynamoDbTableDeleteProtectionDisabledCodeRiskModel",
    AwsDynamoDbTableDeleteProtectionDisabledRisk = "AwsDynamoDbTableDeleteProtectionDisabledRisk",
    AwsDynamoDbTableDeleteProtectionDisabledRiskModel = "AwsDynamoDbTableDeleteProtectionDisabledRiskModel",
    AwsDynamoDbTableDeleteProtectionDisabledRiskPolicyConfiguration = "AwsDynamoDbTableDeleteProtectionDisabledRiskPolicyConfiguration",
    AwsDynamoDbTableEncryptionDisabledCodeRisk = "AwsDynamoDbTableEncryptionDisabledCodeRisk",
    AwsDynamoDbTableEncryptionDisabledCodeRiskModel = "AwsDynamoDbTableEncryptionDisabledCodeRiskModel",
    AwsDynamoDbTableEncryptionDisabledRisk = "AwsDynamoDbTableEncryptionDisabledRisk",
    AwsDynamoDbTableEncryptionDisabledRiskModel = "AwsDynamoDbTableEncryptionDisabledRiskModel",
    AwsDynamoDbTableEncryptionDisabledRiskPolicyConfiguration = "AwsDynamoDbTableEncryptionDisabledRiskPolicyConfiguration",
    AwsDynamoDbTableModel = "AwsDynamoDbTableModel",
    AwsDynamoDbTableModelFilters = "AwsDynamoDbTableModelFilters",
    AwsDynamoDbTableProfile = "AwsDynamoDbTableProfile",
    AwsEc2AnalysisServiceConfiguration = "AwsEc2AnalysisServiceConfiguration",
    AwsEc2CoipPool = "AwsEc2CoipPool",
    AwsEc2CoipPoolModel = "AwsEc2CoipPoolModel",
    AwsEc2DatabricksInstanceGroup = "AwsEc2DatabricksInstanceGroup",
    AwsEc2DatabricksInstanceGroupDefinitionConfiguration = "AwsEc2DatabricksInstanceGroupDefinitionConfiguration",
    AwsEc2DatabricksInstanceGroupModel = "AwsEc2DatabricksInstanceGroupModel",
    AwsEc2Device = "AwsEc2Device",
    AwsEc2DeviceType = "AwsEc2DeviceType",
    AwsEc2ElasticIp = "AwsEc2ElasticIp",
    AwsEc2ElasticIpModel = "AwsEc2ElasticIpModel",
    AwsEc2ElasticIpProfile = "AwsEc2ElasticIpProfile",
    AwsEc2FlowLog = "AwsEc2FlowLog",
    AwsEc2FlowLogFilter = "AwsEc2FlowLogFilter",
    AwsEc2FlowLogModel = "AwsEc2FlowLogModel",
    AwsEc2Image = "AwsEc2Image",
    AwsEc2ImageModel = "AwsEc2ImageModel",
    AwsEc2ImageModelFilters = "AwsEc2ImageModelFilters",
    AwsEc2ImageProfile = "AwsEc2ImageProfile",
    AwsEc2ImagePublicAccessExistsCodeRisk = "AwsEc2ImagePublicAccessExistsCodeRisk",
    AwsEc2ImagePublicAccessExistsCodeRiskModel = "AwsEc2ImagePublicAccessExistsCodeRiskModel",
    AwsEc2ImagePublicAccessExistsRisk = "AwsEc2ImagePublicAccessExistsRisk",
    AwsEc2ImagePublicAccessExistsRiskModel = "AwsEc2ImagePublicAccessExistsRiskModel",
    AwsEc2ImagePublicAccessExistsRiskPolicyConfiguration = "AwsEc2ImagePublicAccessExistsRiskPolicyConfiguration",
    AwsEc2Instance = "AwsEc2Instance",
    AwsEc2InstanceHypervisor = "AwsEc2InstanceHypervisor",
    AwsEc2InstanceInstanceProfileNotExistCodeRisk = "AwsEc2InstanceInstanceProfileNotExistCodeRisk",
    AwsEc2InstanceInstanceProfileNotExistCodeRiskModel = "AwsEc2InstanceInstanceProfileNotExistCodeRiskModel",
    AwsEc2InstanceInstanceProfileNotExistRisk = "AwsEc2InstanceInstanceProfileNotExistRisk",
    AwsEc2InstanceInstanceProfileNotExistRiskModel = "AwsEc2InstanceInstanceProfileNotExistRiskModel",
    AwsEc2InstanceInstanceProfileNotExistRiskPolicyConfiguration = "AwsEc2InstanceInstanceProfileNotExistRiskPolicyConfiguration",
    AwsEc2InstanceMetadataService = "AwsEc2InstanceMetadataService",
    AwsEc2InstanceMetadataServiceTokenHopMaxCountRisk = "AwsEc2InstanceMetadataServiceTokenHopMaxCountRisk",
    AwsEc2InstanceMetadataServiceTokenHopMaxCountRiskData = "AwsEc2InstanceMetadataServiceTokenHopMaxCountRiskData",
    AwsEc2InstanceMetadataServiceTokenHopMaxCountRiskModel = "AwsEc2InstanceMetadataServiceTokenHopMaxCountRiskModel",
    AwsEc2InstanceMetadataServiceTokenHopMaxCountRiskPolicyConfiguration = "AwsEc2InstanceMetadataServiceTokenHopMaxCountRiskPolicyConfiguration",
    AwsEc2InstanceMetadataServiceVersion = "AwsEc2InstanceMetadataServiceVersion",
    AwsEc2InstanceMetadataServiceVersionCodeRisk = "AwsEc2InstanceMetadataServiceVersionCodeRisk",
    AwsEc2InstanceMetadataServiceVersionCodeRiskModel = "AwsEc2InstanceMetadataServiceVersionCodeRiskModel",
    AwsEc2InstanceMetadataServiceVersionRisk = "AwsEc2InstanceMetadataServiceVersionRisk",
    AwsEc2InstanceMetadataServiceVersionRiskData = "AwsEc2InstanceMetadataServiceVersionRiskData",
    AwsEc2InstanceMetadataServiceVersionRiskModel = "AwsEc2InstanceMetadataServiceVersionRiskModel",
    AwsEc2InstanceMetadataServiceVersionRiskPolicyConfiguration = "AwsEc2InstanceMetadataServiceVersionRiskPolicyConfiguration",
    AwsEc2InstanceMetadataServiceVersionRiskPolicyGroupFilters = "AwsEc2InstanceMetadataServiceVersionRiskPolicyGroupFilters",
    AwsEc2InstanceModel = "AwsEc2InstanceModel",
    AwsEc2InstanceModelFilters = "AwsEc2InstanceModelFilters",
    AwsEc2InstanceProfile = "AwsEc2InstanceProfile",
    AwsEc2InstanceStatus = "AwsEc2InstanceStatus",
    AwsEc2InstanceTypeData = "AwsEc2InstanceTypeData",
    AwsEc2InstanceUserDataSecretExistsCodeRisk = "AwsEc2InstanceUserDataSecretExistsCodeRisk",
    AwsEc2InstanceUserDataSecretExistsCodeRiskModel = "AwsEc2InstanceUserDataSecretExistsCodeRiskModel",
    AwsEc2InstanceUserDataSecretExistsRisk = "AwsEc2InstanceUserDataSecretExistsRisk",
    AwsEc2InstanceUserDataSecretExistsRiskAutoScalingGroupsInfo = "AwsEc2InstanceUserDataSecretExistsRiskAutoScalingGroupsInfo",
    AwsEc2InstanceUserDataSecretExistsRiskGroupTagsInfo = "AwsEc2InstanceUserDataSecretExistsRiskGroupTagsInfo",
    AwsEc2InstanceUserDataSecretExistsRiskInfo = "AwsEc2InstanceUserDataSecretExistsRiskInfo",
    AwsEc2InstanceUserDataSecretExistsRiskInfoType = "AwsEc2InstanceUserDataSecretExistsRiskInfoType",
    AwsEc2InstanceUserDataSecretExistsRiskModel = "AwsEc2InstanceUserDataSecretExistsRiskModel",
    AwsEc2InstanceUserDataSecretExistsRiskPermissionsInfo = "AwsEc2InstanceUserDataSecretExistsRiskPermissionsInfo",
    AwsEc2InstanceUserDataSecretExistsRiskPolicyConfiguration = "AwsEc2InstanceUserDataSecretExistsRiskPolicyConfiguration",
    AwsEc2InstanceUserDataSecretExistsRiskPublicAccessInfo = "AwsEc2InstanceUserDataSecretExistsRiskPublicAccessInfo",
    AwsEc2InstanceUserDataSecretExistsRiskRunningInstancesInfo = "AwsEc2InstanceUserDataSecretExistsRiskRunningInstancesInfo",
    AwsEc2InstanceUserDataSecretExistsRiskSensitiveEntitiesInfo = "AwsEc2InstanceUserDataSecretExistsRiskSensitiveEntitiesInfo",
    AwsEc2InternetGateway = "AwsEc2InternetGateway",
    AwsEc2InternetGatewayModel = "AwsEc2InternetGatewayModel",
    AwsEc2InternetGatewayProfile = "AwsEc2InternetGatewayProfile",
    AwsEc2IpamPool = "AwsEc2IpamPool",
    AwsEc2IpamPoolModel = "AwsEc2IpamPoolModel",
    AwsEc2LaunchTemplate = "AwsEc2LaunchTemplate",
    AwsEc2LaunchTemplateModel = "AwsEc2LaunchTemplateModel",
    AwsEc2LaunchTemplateModelRevisionData = "AwsEc2LaunchTemplateModelRevisionData",
    AwsEc2LaunchTemplateProfile = "AwsEc2LaunchTemplateProfile",
    AwsEc2LaunchTemplateRevision = "AwsEc2LaunchTemplateRevision",
    AwsEc2LaunchTemplateRevisionIdType = "AwsEc2LaunchTemplateRevisionIdType",
    AwsEc2LaunchTemplateRevisionReference = "AwsEc2LaunchTemplateRevisionReference",
    AwsEc2LoadBalancerListenerInsecureSslProtocolExistsCodeRisk = "AwsEc2LoadBalancerListenerInsecureSslProtocolExistsCodeRisk",
    AwsEc2LoadBalancerListenerInsecureSslProtocolExistsCodeRiskModel = "AwsEc2LoadBalancerListenerInsecureSslProtocolExistsCodeRiskModel",
    AwsEc2LoadBalancerListenerInsecureSslProtocolExistsRisk = "AwsEc2LoadBalancerListenerInsecureSslProtocolExistsRisk",
    AwsEc2LoadBalancerListenerInsecureSslProtocolExistsRiskModel = "AwsEc2LoadBalancerListenerInsecureSslProtocolExistsRiskModel",
    AwsEc2LoadBalancerListenerInsecureSslProtocolExistsRiskPolicyConfiguration = "AwsEc2LoadBalancerListenerInsecureSslProtocolExistsRiskPolicyConfiguration",
    AwsEc2LocalGatewayRouteTable = "AwsEc2LocalGatewayRouteTable",
    AwsEc2LocalGatewayRouteTableModel = "AwsEc2LocalGatewayRouteTableModel",
    AwsEc2NatGateway = "AwsEc2NatGateway",
    AwsEc2NatGatewayModel = "AwsEc2NatGatewayModel",
    AwsEc2NatGatewayProfile = "AwsEc2NatGatewayProfile",
    AwsEc2NetworkAcl = "AwsEc2NetworkAcl",
    AwsEc2NetworkAclInboundRuleSubnetAnyExistsCodeRisk = "AwsEc2NetworkAclInboundRuleSubnetAnyExistsCodeRisk",
    AwsEc2NetworkAclInboundRuleSubnetAnyExistsCodeRiskModel = "AwsEc2NetworkAclInboundRuleSubnetAnyExistsCodeRiskModel",
    AwsEc2NetworkAclInboundRuleSubnetAnyExistsRisk = "AwsEc2NetworkAclInboundRuleSubnetAnyExistsRisk",
    AwsEc2NetworkAclInboundRuleSubnetAnyExistsRiskModel = "AwsEc2NetworkAclInboundRuleSubnetAnyExistsRiskModel",
    AwsEc2NetworkAclInboundRuleSubnetAnyExistsRiskPolicyConfiguration = "AwsEc2NetworkAclInboundRuleSubnetAnyExistsRiskPolicyConfiguration",
    AwsEc2NetworkAclModel = "AwsEc2NetworkAclModel",
    AwsEc2NetworkAclProfile = "AwsEc2NetworkAclProfile",
    AwsEc2NetworkAclRule = "AwsEc2NetworkAclRule",
    AwsEc2NetworkAclRuleAction = "AwsEc2NetworkAclRuleAction",
    AwsEc2NetworkInterface = "AwsEc2NetworkInterface",
    AwsEc2NetworkInterfaceModel = "AwsEc2NetworkInterfaceModel",
    AwsEc2NetworkInterfaceProfile = "AwsEc2NetworkInterfaceProfile",
    AwsEc2NetworkResourceInboundRuleSubnetAnyExistsRisk = "AwsEc2NetworkResourceInboundRuleSubnetAnyExistsRisk",
    AwsEc2NetworkResourceInboundRuleSubnetAnyExistsRiskModel = "AwsEc2NetworkResourceInboundRuleSubnetAnyExistsRiskModel",
    AwsEc2NetworkResourceInboundRuleSubnetAnyExistsRiskPolicyGroupFilters = "AwsEc2NetworkResourceInboundRuleSubnetAnyExistsRiskPolicyGroupFilters",
    AwsEc2NetworkRule = "AwsEc2NetworkRule",
    AwsEc2NetworkRuleIcmp = "AwsEc2NetworkRuleIcmp",
    AwsEc2Resource = "AwsEc2Resource",
    AwsEc2ResourceModel = "AwsEc2ResourceModel",
    AwsEc2RouteTable = "AwsEc2RouteTable",
    AwsEc2RouteTableModel = "AwsEc2RouteTableModel",
    AwsEc2RouteTableProfile = "AwsEc2RouteTableProfile",
    AwsEc2RouteTableRoute = "AwsEc2RouteTableRoute",
    AwsEc2SecurityGroup = "AwsEc2SecurityGroup",
    AwsEc2SecurityGroupAllowedInboundRuleRisk = "AwsEc2SecurityGroupAllowedInboundRuleRisk",
    AwsEc2SecurityGroupAllowedInboundRuleRiskModel = "AwsEc2SecurityGroupAllowedInboundRuleRiskModel",
    AwsEc2SecurityGroupAllowedInboundRuleRiskPolicyConfiguration = "AwsEc2SecurityGroupAllowedInboundRuleRiskPolicyConfiguration",
    AwsEc2SecurityGroupInboundRuleRisk = "AwsEc2SecurityGroupInboundRuleRisk",
    AwsEc2SecurityGroupInboundRuleRiskItem = "AwsEc2SecurityGroupInboundRuleRiskItem",
    AwsEc2SecurityGroupInboundRuleRiskModel = "AwsEc2SecurityGroupInboundRuleRiskModel",
    AwsEc2SecurityGroupInboundRuleRiskPolicyConfiguration = "AwsEc2SecurityGroupInboundRuleRiskPolicyConfiguration",
    AwsEc2SecurityGroupInboundRuleSubnetAnyExistsCodeRisk = "AwsEc2SecurityGroupInboundRuleSubnetAnyExistsCodeRisk",
    AwsEc2SecurityGroupInboundRuleSubnetAnyExistsCodeRiskModel = "AwsEc2SecurityGroupInboundRuleSubnetAnyExistsCodeRiskModel",
    AwsEc2SecurityGroupInboundRuleSubnetAnyExistsRisk = "AwsEc2SecurityGroupInboundRuleSubnetAnyExistsRisk",
    AwsEc2SecurityGroupInboundRuleSubnetAnyExistsRiskModel = "AwsEc2SecurityGroupInboundRuleSubnetAnyExistsRiskModel",
    AwsEc2SecurityGroupInboundRuleSubnetAnyExistsRiskPolicyConfiguration = "AwsEc2SecurityGroupInboundRuleSubnetAnyExistsRiskPolicyConfiguration",
    AwsEc2SecurityGroupManagementRisk = "AwsEc2SecurityGroupManagementRisk",
    AwsEc2SecurityGroupManagementRiskModel = "AwsEc2SecurityGroupManagementRiskModel",
    AwsEc2SecurityGroupManagementRiskModelDescriptionType = "AwsEc2SecurityGroupManagementRiskModelDescriptionType",
    AwsEc2SecurityGroupManagementRiskPolicyConfiguration = "AwsEc2SecurityGroupManagementRiskPolicyConfiguration",
    AwsEc2SecurityGroupModel = "AwsEc2SecurityGroupModel",
    AwsEc2SecurityGroupNotAllowedInboundRuleRisk = "AwsEc2SecurityGroupNotAllowedInboundRuleRisk",
    AwsEc2SecurityGroupNotAllowedInboundRuleRiskModel = "AwsEc2SecurityGroupNotAllowedInboundRuleRiskModel",
    AwsEc2SecurityGroupNotAllowedInboundRuleRiskPolicyConfiguration = "AwsEc2SecurityGroupNotAllowedInboundRuleRiskPolicyConfiguration",
    AwsEc2SecurityGroupProfile = "AwsEc2SecurityGroupProfile",
    AwsEc2SecurityGroupRule = "AwsEc2SecurityGroupRule",
    AwsEc2SecurityGroupUnusedRisk = "AwsEc2SecurityGroupUnusedRisk",
    AwsEc2SecurityGroupUnusedRiskModel = "AwsEc2SecurityGroupUnusedRiskModel",
    AwsEc2SecurityGroupUnusedRiskPolicyConfiguration = "AwsEc2SecurityGroupUnusedRiskPolicyConfiguration",
    AwsEc2SecurityGroupUnusedRiskPolicyUpdateAuditEventUsageTimeFrameChange = "AwsEc2SecurityGroupUnusedRiskPolicyUpdateAuditEventUsageTimeFrameChange",
    AwsEc2SecurityGroupUnusedRiskResourceAttachInfo = "AwsEc2SecurityGroupUnusedRiskResourceAttachInfo",
    AwsEc2Snapshot = "AwsEc2Snapshot",
    AwsEc2SnapshotEncryptionDisabledCodeRisk = "AwsEc2SnapshotEncryptionDisabledCodeRisk",
    AwsEc2SnapshotEncryptionDisabledCodeRiskModel = "AwsEc2SnapshotEncryptionDisabledCodeRiskModel",
    AwsEc2SnapshotEncryptionDisabledRisk = "AwsEc2SnapshotEncryptionDisabledRisk",
    AwsEc2SnapshotEncryptionDisabledRiskAutoScalingLaunchConfigurationData = "AwsEc2SnapshotEncryptionDisabledRiskAutoScalingLaunchConfigurationData",
    AwsEc2SnapshotEncryptionDisabledRiskData = "AwsEc2SnapshotEncryptionDisabledRiskData",
    AwsEc2SnapshotEncryptionDisabledRiskLaunchTemplateData = "AwsEc2SnapshotEncryptionDisabledRiskLaunchTemplateData",
    AwsEc2SnapshotEncryptionDisabledRiskModel = "AwsEc2SnapshotEncryptionDisabledRiskModel",
    AwsEc2SnapshotEncryptionDisabledRiskPolicyConfiguration = "AwsEc2SnapshotEncryptionDisabledRiskPolicyConfiguration",
    AwsEc2SnapshotEncryptionDisabledRiskSnapshotData = "AwsEc2SnapshotEncryptionDisabledRiskSnapshotData",
    AwsEc2SnapshotEncryptionDisabledRiskVolumeData = "AwsEc2SnapshotEncryptionDisabledRiskVolumeData",
    AwsEc2SnapshotModel = "AwsEc2SnapshotModel",
    AwsEc2SnapshotProfile = "AwsEc2SnapshotProfile",
    AwsEc2SnapshotPublicAccessExistsRisk = "AwsEc2SnapshotPublicAccessExistsRisk",
    AwsEc2SnapshotPublicAccessExistsRiskModel = "AwsEc2SnapshotPublicAccessExistsRiskModel",
    AwsEc2SnapshotPublicAccessExistsRiskPolicyConfiguration = "AwsEc2SnapshotPublicAccessExistsRiskPolicyConfiguration",
    AwsEc2SpotInstanceGroup = "AwsEc2SpotInstanceGroup",
    AwsEc2SpotInstanceGroupDefinitionConfiguration = "AwsEc2SpotInstanceGroupDefinitionConfiguration",
    AwsEc2SpotInstanceGroupModel = "AwsEc2SpotInstanceGroupModel",
    AwsEc2Subnet = "AwsEc2Subnet",
    AwsEc2SubnetModel = "AwsEc2SubnetModel",
    AwsEc2SubnetProfile = "AwsEc2SubnetProfile",
    AwsEc2SubnetStateNetwork = "AwsEc2SubnetStateNetwork",
    AwsEc2Volume = "AwsEc2Volume",
    AwsEc2VolumeEncryptionDisabledCodeRisk = "AwsEc2VolumeEncryptionDisabledCodeRisk",
    AwsEc2VolumeEncryptionDisabledCodeRiskModel = "AwsEc2VolumeEncryptionDisabledCodeRiskModel",
    AwsEc2VolumeEncryptionDisabledRisk = "AwsEc2VolumeEncryptionDisabledRisk",
    AwsEc2VolumeEncryptionDisabledRiskAutoScalingLaunchConfigurationData = "AwsEc2VolumeEncryptionDisabledRiskAutoScalingLaunchConfigurationData",
    AwsEc2VolumeEncryptionDisabledRiskData = "AwsEc2VolumeEncryptionDisabledRiskData",
    AwsEc2VolumeEncryptionDisabledRiskEc2LaunchTemplateData = "AwsEc2VolumeEncryptionDisabledRiskEc2LaunchTemplateData",
    AwsEc2VolumeEncryptionDisabledRiskEc2VolumeData = "AwsEc2VolumeEncryptionDisabledRiskEc2VolumeData",
    AwsEc2VolumeEncryptionDisabledRiskEmrClustersData = "AwsEc2VolumeEncryptionDisabledRiskEmrClustersData",
    AwsEc2VolumeEncryptionDisabledRiskEmrSecurityConfigurationData = "AwsEc2VolumeEncryptionDisabledRiskEmrSecurityConfigurationData",
    AwsEc2VolumeEncryptionDisabledRiskModel = "AwsEc2VolumeEncryptionDisabledRiskModel",
    AwsEc2VolumeEncryptionDisabledRiskPolicyConfiguration = "AwsEc2VolumeEncryptionDisabledRiskPolicyConfiguration",
    AwsEc2VolumeModel = "AwsEc2VolumeModel",
    AwsEc2VolumeProfile = "AwsEc2VolumeProfile",
    AwsEc2Vpc = "AwsEc2Vpc",
    AwsEc2VpcBlockPublicAccessPolicy = "AwsEc2VpcBlockPublicAccessPolicy",
    AwsEc2VpcBlockPublicAccessPolicyExclusion = "AwsEc2VpcBlockPublicAccessPolicyExclusion",
    AwsEc2VpcBlockPublicAccessPolicyModel = "AwsEc2VpcBlockPublicAccessPolicyModel",
    AwsEc2VpcBlockPublicAccessPolicyNetworkDirection = "AwsEc2VpcBlockPublicAccessPolicyNetworkDirection",
    AwsEc2VpcBlockPublicAccessPolicyProfile = "AwsEc2VpcBlockPublicAccessPolicyProfile",
    AwsEc2VpcDefaultSecurityGroupRuleExistsRisk = "AwsEc2VpcDefaultSecurityGroupRuleExistsRisk",
    AwsEc2VpcDefaultSecurityGroupRuleExistsRiskModel = "AwsEc2VpcDefaultSecurityGroupRuleExistsRiskModel",
    AwsEc2VpcDefaultSecurityGroupRuleExistsRiskPolicyConfiguration = "AwsEc2VpcDefaultSecurityGroupRuleExistsRiskPolicyConfiguration",
    AwsEc2VpcEndpoint = "AwsEc2VpcEndpoint",
    AwsEc2VpcEndpointModel = "AwsEc2VpcEndpointModel",
    AwsEc2VpcEndpointProfile = "AwsEc2VpcEndpointProfile",
    AwsEc2VpcEndpointStatus = "AwsEc2VpcEndpointStatus",
    AwsEc2VpcEndpointType = "AwsEc2VpcEndpointType",
    AwsEc2VpcEndpointUnrestrictedServiceAccessRisk = "AwsEc2VpcEndpointUnrestrictedServiceAccessRisk",
    AwsEc2VpcEndpointUnrestrictedServiceAccessRiskModel = "AwsEc2VpcEndpointUnrestrictedServiceAccessRiskModel",
    AwsEc2VpcEndpointUnrestrictedServiceAccessRiskPolicyConfiguration = "AwsEc2VpcEndpointUnrestrictedServiceAccessRiskPolicyConfiguration",
    AwsEc2VpcModel = "AwsEc2VpcModel",
    AwsEc2VpcPeeringConnection = "AwsEc2VpcPeeringConnection",
    AwsEc2VpcPeeringConnectionModel = "AwsEc2VpcPeeringConnectionModel",
    AwsEc2VpcPeeringConnectionProfile = "AwsEc2VpcPeeringConnectionProfile",
    AwsEc2VpcPeeringConnectionStatus = "AwsEc2VpcPeeringConnectionStatus",
    AwsEc2VpcProfile = "AwsEc2VpcProfile",
    AwsEc2VpcRejectFlowLogNotExistCodeRisk = "AwsEc2VpcRejectFlowLogNotExistCodeRisk",
    AwsEc2VpcRejectFlowLogNotExistCodeRiskModel = "AwsEc2VpcRejectFlowLogNotExistCodeRiskModel",
    AwsEc2VpcRejectFlowLogNotExistRisk = "AwsEc2VpcRejectFlowLogNotExistRisk",
    AwsEc2VpcRejectFlowLogNotExistRiskModel = "AwsEc2VpcRejectFlowLogNotExistRiskModel",
    AwsEc2VpcRejectFlowLogNotExistRiskPolicyConfiguration = "AwsEc2VpcRejectFlowLogNotExistRiskPolicyConfiguration",
    AwsEc2VpcStateNetwork = "AwsEc2VpcStateNetwork",
    AwsEcrRepository = "AwsEcrRepository",
    AwsEcrRepositoryEncryptionType = "AwsEcrRepositoryEncryptionType",
    AwsEcrRepositoryModel = "AwsEcrRepositoryModel",
    AwsEcrRepositoryProfile = "AwsEcrRepositoryProfile",
    AwsEcrRepositoryPublicAccessExistsCodeRisk = "AwsEcrRepositoryPublicAccessExistsCodeRisk",
    AwsEcrRepositoryPublicAccessExistsCodeRiskModel = "AwsEcrRepositoryPublicAccessExistsCodeRiskModel",
    AwsEcrRepositoryPublicAccessExistsRisk = "AwsEcrRepositoryPublicAccessExistsRisk",
    AwsEcrRepositoryPublicAccessExistsRiskModel = "AwsEcrRepositoryPublicAccessExistsRiskModel",
    AwsEcrRepositoryPublicAccessExistsRiskPolicyConfiguration = "AwsEcrRepositoryPublicAccessExistsRiskPolicyConfiguration",
    AwsEcrRepositoryScanningConfiguration = "AwsEcrRepositoryScanningConfiguration",
    AwsEcrRepositorySnapshot = "AwsEcrRepositorySnapshot",
    AwsEcrResource = "AwsEcrResource",
    AwsEcsCluster = "AwsEcsCluster",
    AwsEcsClusterModel = "AwsEcsClusterModel",
    AwsEcsClusterProfile = "AwsEcsClusterProfile",
    AwsEcsResource = "AwsEcsResource",
    AwsEcsService = "AwsEcsService",
    AwsEcsServiceLaunchType = "AwsEcsServiceLaunchType",
    AwsEcsServiceModel = "AwsEcsServiceModel",
    AwsEcsServiceProfile = "AwsEcsServiceProfile",
    AwsEcsTask = "AwsEcsTask",
    AwsEcsTaskDefinition = "AwsEcsTaskDefinition",
    AwsEcsTaskDefinitionEnvironmentVariableSecretExistsCodeRisk = "AwsEcsTaskDefinitionEnvironmentVariableSecretExistsCodeRisk",
    AwsEcsTaskDefinitionEnvironmentVariableSecretExistsCodeRiskModel = "AwsEcsTaskDefinitionEnvironmentVariableSecretExistsCodeRiskModel",
    AwsEcsTaskDefinitionEnvironmentVariableSecretExistsRisk = "AwsEcsTaskDefinitionEnvironmentVariableSecretExistsRisk",
    AwsEcsTaskDefinitionEnvironmentVariableSecretExistsRiskModel = "AwsEcsTaskDefinitionEnvironmentVariableSecretExistsRiskModel",
    AwsEcsTaskDefinitionEnvironmentVariableSecretExistsRiskPolicyConfiguration = "AwsEcsTaskDefinitionEnvironmentVariableSecretExistsRiskPolicyConfiguration",
    AwsEcsTaskDefinitionEnvironmentVariableSecretExistsRiskPolicyGroupFilters = "AwsEcsTaskDefinitionEnvironmentVariableSecretExistsRiskPolicyGroupFilters",
    AwsEcsTaskDefinitionModel = "AwsEcsTaskDefinitionModel",
    AwsEcsTaskDefinitionProfile = "AwsEcsTaskDefinitionProfile",
    AwsEcsTaskDefinitionRevision = "AwsEcsTaskDefinitionRevision",
    AwsEcsTaskDefinitionRevisionContainerDefinition = "AwsEcsTaskDefinitionRevisionContainerDefinition",
    AwsEcsTaskDefinitionRevisionContainerDefinitionPortMapping = "AwsEcsTaskDefinitionRevisionContainerDefinitionPortMapping",
    AwsEcsTaskDefinitionRevisionNetworkMode = "AwsEcsTaskDefinitionRevisionNetworkMode",
    AwsEcsTaskDefinitionStatus = "AwsEcsTaskDefinitionStatus",
    AwsEcsTaskModel = "AwsEcsTaskModel",
    AwsEcsTaskProfile = "AwsEcsTaskProfile",
    AwsEksCluster = "AwsEksCluster",
    AwsEksClusterInboundExternalWideRangeRisk = "AwsEksClusterInboundExternalWideRangeRisk",
    AwsEksClusterInboundExternalWideRangeRiskModel = "AwsEksClusterInboundExternalWideRangeRiskModel",
    AwsEksClusterInboundExternalWideRangeRiskPolicyConfiguration = "AwsEksClusterInboundExternalWideRangeRiskPolicyConfiguration",
    AwsEksClusterInstanceInboundExternalRisk = "AwsEksClusterInstanceInboundExternalRisk",
    AwsEksClusterInstanceInboundExternalRiskInstanceData = "AwsEksClusterInstanceInboundExternalRiskInstanceData",
    AwsEksClusterInstanceInboundExternalRiskModel = "AwsEksClusterInstanceInboundExternalRiskModel",
    AwsEksClusterInstanceInboundExternalRiskPolicyConfiguration = "AwsEksClusterInstanceInboundExternalRiskPolicyConfiguration",
    AwsEksClusterKubernetesSecretEncryptionDisabledCodeRisk = "AwsEksClusterKubernetesSecretEncryptionDisabledCodeRisk",
    AwsEksClusterKubernetesSecretEncryptionDisabledCodeRiskModel = "AwsEksClusterKubernetesSecretEncryptionDisabledCodeRiskModel",
    AwsEksClusterKubernetesSecretEncryptionDisabledRisk = "AwsEksClusterKubernetesSecretEncryptionDisabledRisk",
    AwsEksClusterKubernetesSecretEncryptionDisabledRiskModel = "AwsEksClusterKubernetesSecretEncryptionDisabledRiskModel",
    AwsEksClusterKubernetesSecretEncryptionDisabledRiskPolicyConfiguration = "AwsEksClusterKubernetesSecretEncryptionDisabledRiskPolicyConfiguration",
    AwsEksClusterModel = "AwsEksClusterModel",
    AwsEksClusterModelFilters = "AwsEksClusterModelFilters",
    AwsEksClusterNetwork = "AwsEksClusterNetwork",
    AwsEksClusterNodeGroup = "AwsEksClusterNodeGroup",
    AwsEksClusterNodeGroupModel = "AwsEksClusterNodeGroupModel",
    AwsEksClusterNodeGroupNode = "AwsEksClusterNodeGroupNode",
    AwsEksClusterNodeGroupNodeAutoscaling = "AwsEksClusterNodeGroupNodeAutoscaling",
    AwsEksClusterNodeGroupProfile = "AwsEksClusterNodeGroupProfile",
    AwsEksClusterNodeGroupStatus = "AwsEksClusterNodeGroupStatus",
    AwsEksClusterProfile = "AwsEksClusterProfile",
    AwsEksClusterStatus = "AwsEksClusterStatus",
    AwsEksFargateProfile = "AwsEksFargateProfile",
    AwsEksFargateProfileModel = "AwsEksFargateProfileModel",
    AwsEksFargateProfilePodSelector = "AwsEksFargateProfilePodSelector",
    AwsEksFargateProfileStatus = "AwsEksFargateProfileStatus",
    AwsEksResource = "AwsEksResource",
    AwsElastiCacheCache = "AwsElastiCacheCache",
    AwsElastiCacheCacheAutoFailover = "AwsElastiCacheCacheAutoFailover",
    AwsElastiCacheCacheDeploymentType = "AwsElastiCacheCacheDeploymentType",
    AwsElastiCacheCacheModel = "AwsElastiCacheCacheModel",
    AwsElastiCacheCacheStatus = "AwsElastiCacheCacheStatus",
    AwsElastiCacheReplicationGroup = "AwsElastiCacheReplicationGroup",
    AwsElastiCacheReplicationGroupAutoFailoverDisabledCodeRisk = "AwsElastiCacheReplicationGroupAutoFailoverDisabledCodeRisk",
    AwsElastiCacheReplicationGroupAutoFailoverDisabledCodeRiskModel = "AwsElastiCacheReplicationGroupAutoFailoverDisabledCodeRiskModel",
    AwsElastiCacheReplicationGroupAutoFailoverDisabledRisk = "AwsElastiCacheReplicationGroupAutoFailoverDisabledRisk",
    AwsElastiCacheReplicationGroupAutoFailoverDisabledRiskModel = "AwsElastiCacheReplicationGroupAutoFailoverDisabledRiskModel",
    AwsElastiCacheReplicationGroupAutoFailoverDisabledRiskPolicyConfiguration = "AwsElastiCacheReplicationGroupAutoFailoverDisabledRiskPolicyConfiguration",
    AwsElastiCacheReplicationGroupAutomaticBackupsDisabledCodeRisk = "AwsElastiCacheReplicationGroupAutomaticBackupsDisabledCodeRisk",
    AwsElastiCacheReplicationGroupAutomaticBackupsDisabledCodeRiskModel = "AwsElastiCacheReplicationGroupAutomaticBackupsDisabledCodeRiskModel",
    AwsElastiCacheReplicationGroupAutomaticBackupsDisabledRisk = "AwsElastiCacheReplicationGroupAutomaticBackupsDisabledRisk",
    AwsElastiCacheReplicationGroupAutomaticBackupsDisabledRiskModel = "AwsElastiCacheReplicationGroupAutomaticBackupsDisabledRiskModel",
    AwsElastiCacheReplicationGroupAutomaticBackupsDisabledRiskPolicyConfiguration = "AwsElastiCacheReplicationGroupAutomaticBackupsDisabledRiskPolicyConfiguration",
    AwsElastiCacheReplicationGroupAutomaticBackupsDisabledRiskPolicyUpdateAuditEventBackupRetentionTimeFrameChange = "AwsElastiCacheReplicationGroupAutomaticBackupsDisabledRiskPolicyUpdateAuditEventBackupRetentionTimeFrameChange",
    AwsElastiCacheReplicationGroupAutoUpgradeMinorVersionsDisabledCodeRisk = "AwsElastiCacheReplicationGroupAutoUpgradeMinorVersionsDisabledCodeRisk",
    AwsElastiCacheReplicationGroupAutoUpgradeMinorVersionsDisabledCodeRiskModel = "AwsElastiCacheReplicationGroupAutoUpgradeMinorVersionsDisabledCodeRiskModel",
    AwsElastiCacheReplicationGroupAutoUpgradeMinorVersionsDisabledRisk = "AwsElastiCacheReplicationGroupAutoUpgradeMinorVersionsDisabledRisk",
    AwsElastiCacheReplicationGroupAutoUpgradeMinorVersionsDisabledRiskModel = "AwsElastiCacheReplicationGroupAutoUpgradeMinorVersionsDisabledRiskModel",
    AwsElastiCacheReplicationGroupAutoUpgradeMinorVersionsDisabledRiskPolicyConfiguration = "AwsElastiCacheReplicationGroupAutoUpgradeMinorVersionsDisabledRiskPolicyConfiguration",
    AwsElastiCacheReplicationGroupClusterMode = "AwsElastiCacheReplicationGroupClusterMode",
    AwsElastiCacheReplicationGroupDefaultSubnetGroupCodeRisk = "AwsElastiCacheReplicationGroupDefaultSubnetGroupCodeRisk",
    AwsElastiCacheReplicationGroupDefaultSubnetGroupCodeRiskModel = "AwsElastiCacheReplicationGroupDefaultSubnetGroupCodeRiskModel",
    AwsElastiCacheReplicationGroupDefaultSubnetGroupRisk = "AwsElastiCacheReplicationGroupDefaultSubnetGroupRisk",
    AwsElastiCacheReplicationGroupDefaultSubnetGroupRiskModel = "AwsElastiCacheReplicationGroupDefaultSubnetGroupRiskModel",
    AwsElastiCacheReplicationGroupDefaultSubnetGroupRiskPolicyConfiguration = "AwsElastiCacheReplicationGroupDefaultSubnetGroupRiskPolicyConfiguration",
    AwsElastiCacheReplicationGroupEncryptionDisabledRisk = "AwsElastiCacheReplicationGroupEncryptionDisabledRisk",
    AwsElastiCacheReplicationGroupEncryptionDisabledRiskModel = "AwsElastiCacheReplicationGroupEncryptionDisabledRiskModel",
    AwsElastiCacheReplicationGroupEncryptionDisabledRiskPolicyConfiguration = "AwsElastiCacheReplicationGroupEncryptionDisabledRiskPolicyConfiguration",
    AwsElastiCacheReplicationGroupModel = "AwsElastiCacheReplicationGroupModel",
    AwsElastiCacheReplicationGroupNetworkType = "AwsElastiCacheReplicationGroupNetworkType",
    AwsElastiCacheReplicationGroupProfile = "AwsElastiCacheReplicationGroupProfile",
    AwsElastiCacheReplicationGroupTransitEncryptionMode = "AwsElastiCacheReplicationGroupTransitEncryptionMode",
    AwsElastiCacheReplicationGroupTransitEncryptionModeNotRequiredCodeRisk = "AwsElastiCacheReplicationGroupTransitEncryptionModeNotRequiredCodeRisk",
    AwsElastiCacheReplicationGroupTransitEncryptionModeNotRequiredCodeRiskModel = "AwsElastiCacheReplicationGroupTransitEncryptionModeNotRequiredCodeRiskModel",
    AwsElastiCacheReplicationGroupTransitEncryptionModeNotRequiredRisk = "AwsElastiCacheReplicationGroupTransitEncryptionModeNotRequiredRisk",
    AwsElastiCacheReplicationGroupTransitEncryptionModeNotRequiredRiskModel = "AwsElastiCacheReplicationGroupTransitEncryptionModeNotRequiredRiskModel",
    AwsElastiCacheReplicationGroupTransitEncryptionModeNotRequiredRiskPolicyConfiguration = "AwsElastiCacheReplicationGroupTransitEncryptionModeNotRequiredRiskPolicyConfiguration",
    AwsElastiCacheServerlessCache = "AwsElastiCacheServerlessCache",
    AwsElastiCacheServerlessCacheModel = "AwsElastiCacheServerlessCacheModel",
    AwsElastiCacheServerlessCacheProfile = "AwsElastiCacheServerlessCacheProfile",
    AwsElasticBeanstalkApplication = "AwsElasticBeanstalkApplication",
    AwsElasticBeanstalkApplicationModel = "AwsElasticBeanstalkApplicationModel",
    AwsElasticBeanstalkApplicationProfile = "AwsElasticBeanstalkApplicationProfile",
    AwsElasticBeanstalkEnvironment = "AwsElasticBeanstalkEnvironment",
    AwsElasticBeanstalkEnvironmentCloudWatchLoggingDisabledRisk = "AwsElasticBeanstalkEnvironmentCloudWatchLoggingDisabledRisk",
    AwsElasticBeanstalkEnvironmentCloudWatchLoggingDisabledRiskModel = "AwsElasticBeanstalkEnvironmentCloudWatchLoggingDisabledRiskModel",
    AwsElasticBeanstalkEnvironmentCloudWatchLoggingDisabledRiskPolicyConfiguration = "AwsElasticBeanstalkEnvironmentCloudWatchLoggingDisabledRiskPolicyConfiguration",
    AwsElasticBeanstalkEnvironmentCloudWatchLoggingDisabledRiskPolicyUpdateAuditEventCloudWatchLogRetentionTimeFrameChange = "AwsElasticBeanstalkEnvironmentCloudWatchLoggingDisabledRiskPolicyUpdateAuditEventCloudWatchLogRetentionTimeFrameChange",
    AwsElasticBeanstalkEnvironmentModel = "AwsElasticBeanstalkEnvironmentModel",
    AwsElasticBeanstalkEnvironmentProfile = "AwsElasticBeanstalkEnvironmentProfile",
    AwsElasticBeanstalkEnvironmentTier = "AwsElasticBeanstalkEnvironmentTier",
    AwsElasticBeanstalkResource = "AwsElasticBeanstalkResource",
    AwsElasticFileSystemFileSystem = "AwsElasticFileSystemFileSystem",
    AwsElasticFileSystemFileSystemEncryptionDisabledCodeRisk = "AwsElasticFileSystemFileSystemEncryptionDisabledCodeRisk",
    AwsElasticFileSystemFileSystemEncryptionDisabledCodeRiskModel = "AwsElasticFileSystemFileSystemEncryptionDisabledCodeRiskModel",
    AwsElasticFileSystemFileSystemEncryptionDisabledRisk = "AwsElasticFileSystemFileSystemEncryptionDisabledRisk",
    AwsElasticFileSystemFileSystemEncryptionDisabledRiskModel = "AwsElasticFileSystemFileSystemEncryptionDisabledRiskModel",
    AwsElasticFileSystemFileSystemEncryptionDisabledRiskPolicyConfiguration = "AwsElasticFileSystemFileSystemEncryptionDisabledRiskPolicyConfiguration",
    AwsElasticFileSystemFileSystemModel = "AwsElasticFileSystemFileSystemModel",
    AwsElasticFileSystemFileSystemProfile = "AwsElasticFileSystemFileSystemProfile",
    AwsElbApplicationLoadBalancer = "AwsElbApplicationLoadBalancer",
    AwsElbApplicationLoadBalancerListener = "AwsElbApplicationLoadBalancerListener",
    AwsElbApplicationLoadBalancerListenerModel = "AwsElbApplicationLoadBalancerListenerModel",
    AwsElbApplicationLoadBalancerModel = "AwsElbApplicationLoadBalancerModel",
    AwsElbApplicationLoadBalancerProfile = "AwsElbApplicationLoadBalancerProfile",
    AwsElbClassicLoadBalancer = "AwsElbClassicLoadBalancer",
    AwsElbClassicLoadBalancerListener = "AwsElbClassicLoadBalancerListener",
    AwsElbClassicLoadBalancerListenerModel = "AwsElbClassicLoadBalancerListenerModel",
    AwsElbClassicLoadBalancerModel = "AwsElbClassicLoadBalancerModel",
    AwsElbClassicLoadBalancerProfile = "AwsElbClassicLoadBalancerProfile",
    AwsElbLoadBalancer = "AwsElbLoadBalancer",
    AwsElbLoadBalancerListener = "AwsElbLoadBalancerListener",
    AwsElbLoadBalancerListenerModel = "AwsElbLoadBalancerListenerModel",
    AwsElbLoadBalancerListenerSecurityPolicy = "AwsElbLoadBalancerListenerSecurityPolicy",
    AwsElbLoadBalancerModel = "AwsElbLoadBalancerModel",
    AwsElbLoadBalancerModelFilters = "AwsElbLoadBalancerModelFilters",
    AwsElbLoadBalancerModelType = "AwsElbLoadBalancerModelType",
    AwsElbModernLoadBalancerListener = "AwsElbModernLoadBalancerListener",
    AwsElbNetworkLoadBalancer = "AwsElbNetworkLoadBalancer",
    AwsElbNetworkLoadBalancerListener = "AwsElbNetworkLoadBalancerListener",
    AwsElbNetworkLoadBalancerListenerModel = "AwsElbNetworkLoadBalancerListenerModel",
    AwsElbNetworkLoadBalancerModel = "AwsElbNetworkLoadBalancerModel",
    AwsElbNetworkLoadBalancerProfile = "AwsElbNetworkLoadBalancerProfile",
    AwsElbProtocol = "AwsElbProtocol",
    AwsElbResource = "AwsElbResource",
    AwsElbTargetGroup = "AwsElbTargetGroup",
    AwsElbTargetGroupModel = "AwsElbTargetGroupModel",
    AwsElbTargetGroupTarget = "AwsElbTargetGroupTarget",
    AwsElbTargetGroupType = "AwsElbTargetGroupType",
    AwsEmrCluster = "AwsEmrCluster",
    AwsEmrClusterApplication = "AwsEmrClusterApplication",
    AwsEmrClusterInstanceCollectionType = "AwsEmrClusterInstanceCollectionType",
    AwsEmrClusterModel = "AwsEmrClusterModel",
    AwsEmrClusterProfile = "AwsEmrClusterProfile",
    AwsEmrClusterStatus = "AwsEmrClusterStatus",
    AwsEmrSecurityConfiguration = "AwsEmrSecurityConfiguration",
    AwsEmrSecurityConfigurationModel = "AwsEmrSecurityConfigurationModel",
    AwsEncryptedResourceKmsEncryptionNotExistsRisk = "AwsEncryptedResourceKmsEncryptionNotExistsRisk",
    AwsEncryptedResourceKmsEncryptionNotExistsRiskModel = "AwsEncryptedResourceKmsEncryptionNotExistsRiskModel",
    AwsEncryptedResourceKmsEncryptionNotExistsRiskPolicyConfiguration = "AwsEncryptedResourceKmsEncryptionNotExistsRiskPolicyConfiguration",
    AwsEntity = "AwsEntity",
    AwsEntityModel = "AwsEntityModel",
    AwsEntitySnapshot = "AwsEntitySnapshot",
    AwsEsDomain = "AwsEsDomain",
    AwsEsDomainCloudWatchLoggingDisabledRisk = "AwsEsDomainCloudWatchLoggingDisabledRisk",
    AwsEsDomainCloudWatchLoggingDisabledRiskModel = "AwsEsDomainCloudWatchLoggingDisabledRiskModel",
    AwsEsDomainCloudWatchLoggingDisabledRiskPolicyConfiguration = "AwsEsDomainCloudWatchLoggingDisabledRiskPolicyConfiguration",
    AwsEsDomainEncryptionDisabledCodeRisk = "AwsEsDomainEncryptionDisabledCodeRisk",
    AwsEsDomainEncryptionDisabledCodeRiskModel = "AwsEsDomainEncryptionDisabledCodeRiskModel",
    AwsEsDomainEncryptionDisabledRisk = "AwsEsDomainEncryptionDisabledRisk",
    AwsEsDomainEncryptionDisabledRiskModel = "AwsEsDomainEncryptionDisabledRiskModel",
    AwsEsDomainEncryptionDisabledRiskPolicyConfiguration = "AwsEsDomainEncryptionDisabledRiskPolicyConfiguration",
    AwsEsDomainEngineType = "AwsEsDomainEngineType",
    AwsEsDomainModel = "AwsEsDomainModel",
    AwsEsDomainNodeCommunicationEncryptionDisabledCodeRisk = "AwsEsDomainNodeCommunicationEncryptionDisabledCodeRisk",
    AwsEsDomainNodeCommunicationEncryptionDisabledCodeRiskModel = "AwsEsDomainNodeCommunicationEncryptionDisabledCodeRiskModel",
    AwsEsDomainNodeCommunicationEncryptionDisabledRisk = "AwsEsDomainNodeCommunicationEncryptionDisabledRisk",
    AwsEsDomainNodeCommunicationEncryptionDisabledRiskModel = "AwsEsDomainNodeCommunicationEncryptionDisabledRiskModel",
    AwsEsDomainNodeCommunicationEncryptionDisabledRiskPolicyConfiguration = "AwsEsDomainNodeCommunicationEncryptionDisabledRiskPolicyConfiguration",
    AwsEsDomainProfile = "AwsEsDomainProfile",
    AwsEsDomainPublicAccessExistsCodeRisk = "AwsEsDomainPublicAccessExistsCodeRisk",
    AwsEsDomainPublicAccessExistsCodeRiskModel = "AwsEsDomainPublicAccessExistsCodeRiskModel",
    AwsEsDomainPublicAccessExistsRisk = "AwsEsDomainPublicAccessExistsRisk",
    AwsEsDomainPublicAccessExistsRiskModel = "AwsEsDomainPublicAccessExistsRiskModel",
    AwsEsDomainPublicAccessExistsRiskPolicyConfiguration = "AwsEsDomainPublicAccessExistsRiskPolicyConfiguration",
    AwsEvent = "AwsEvent",
    AwsEventAnalysisServiceConfiguration = "AwsEventAnalysisServiceConfiguration",
    AwsEventAssumeRole = "AwsEventAssumeRole",
    AwsEventProcessResult = "AwsEventProcessResult",
    AwsEventsRuleOnCustomEventBus = "AwsEventsRuleOnCustomEventBus",
    AwsEventsRuleOnDefaultEventBus = "AwsEventsRuleOnDefaultEventBus",
    AwsEventSyncerConfigurationSection = "AwsEventSyncerConfigurationSection",
    AwsExcessivePermissionBucketRisk = "AwsExcessivePermissionBucketRisk",
    AwsExcessivePermissionBucketRiskModel = "AwsExcessivePermissionBucketRiskModel",
    AwsExcessivePermissionBucketRiskPolicyConfiguration = "AwsExcessivePermissionBucketRiskPolicyConfiguration",
    AwsExcessivePermissionGroupRisk = "AwsExcessivePermissionGroupRisk",
    AwsExcessivePermissionGroupRiskModel = "AwsExcessivePermissionGroupRiskModel",
    AwsExcessivePermissionGroupRiskPolicyConfiguration = "AwsExcessivePermissionGroupRiskPolicyConfiguration",
    AwsExcessivePermissionGroupRiskPolicyGroupFilters = "AwsExcessivePermissionGroupRiskPolicyGroupFilters",
    AwsExcessivePermissionIamPrincipalRisk = "AwsExcessivePermissionIamPrincipalRisk",
    AwsExcessivePermissionIamPrincipalRiskModel = "AwsExcessivePermissionIamPrincipalRiskModel",
    AwsExcessivePermissionIamPrincipalRiskModelInfo = "AwsExcessivePermissionIamPrincipalRiskModelInfo",
    AwsExcessivePermissionIamPrincipalRiskModelInfoType = "AwsExcessivePermissionIamPrincipalRiskModelInfoType",
    AwsExcessivePermissionIamPrincipalRiskModelServiceExcessivenessInfo = "AwsExcessivePermissionIamPrincipalRiskModelServiceExcessivenessInfo",
    AwsExcessivePermissionPermissionSetRisk = "AwsExcessivePermissionPermissionSetRisk",
    AwsExcessivePermissionPermissionSetRiskModel = "AwsExcessivePermissionPermissionSetRiskModel",
    AwsExcessivePermissionPermissionSetRiskModelDescriptionType = "AwsExcessivePermissionPermissionSetRiskModelDescriptionType",
    AwsExcessivePermissionPermissionSetRiskModelInfo = "AwsExcessivePermissionPermissionSetRiskModelInfo",
    AwsExcessivePermissionPermissionSetRiskPolicyConfiguration = "AwsExcessivePermissionPermissionSetRiskPolicyConfiguration",
    AwsExcessivePermissionPrincipalRisk = "AwsExcessivePermissionPrincipalRisk",
    AwsExcessivePermissionPrincipalRiskModel = "AwsExcessivePermissionPrincipalRiskModel",
    AwsExcessivePermissionPrincipalRiskModelServiceInfo = "AwsExcessivePermissionPrincipalRiskModelServiceInfo",
    AwsExcessivePermissionPrincipalRiskPolicyAssociationAndInfo = "AwsExcessivePermissionPrincipalRiskPolicyAssociationAndInfo",
    AwsExcessivePermissionPrincipalRiskResolutionPolicyInfo = "AwsExcessivePermissionPrincipalRiskResolutionPolicyInfo",
    AwsExcessivePermissionRepositoryRisk = "AwsExcessivePermissionRepositoryRisk",
    AwsExcessivePermissionRepositoryRiskModel = "AwsExcessivePermissionRepositoryRiskModel",
    AwsExcessivePermissionRepositoryRiskPolicyConfiguration = "AwsExcessivePermissionRepositoryRiskPolicyConfiguration",
    AwsExcessivePermissionResourceRisk = "AwsExcessivePermissionResourceRisk",
    AwsExcessivePermissionResourceRiskAccessType = "AwsExcessivePermissionResourceRiskAccessType",
    AwsExcessivePermissionResourceRiskModel = "AwsExcessivePermissionResourceRiskModel",
    AwsExcessivePermissionResourceRiskModelAccessTypeInfo = "AwsExcessivePermissionResourceRiskModelAccessTypeInfo",
    AwsExcessivePermissionResourceRiskModelDescriptionType = "AwsExcessivePermissionResourceRiskModelDescriptionType",
    AwsExcessivePermissionResourceRiskModelInfo = "AwsExcessivePermissionResourceRiskModelInfo",
    AwsExcessivePermissionResourceRiskModelInfoType = "AwsExcessivePermissionResourceRiskModelInfoType",
    AwsExcessivePermissionResourceRiskModelUnusedPermissionActionInfo = "AwsExcessivePermissionResourceRiskModelUnusedPermissionActionInfo",
    AwsExcessivePermissionResourceRiskSeverityInfo = "AwsExcessivePermissionResourceRiskSeverityInfo",
    AwsExcessivePermissionRoleGroupRisk = "AwsExcessivePermissionRoleGroupRisk",
    AwsExcessivePermissionRoleGroupRiskModel = "AwsExcessivePermissionRoleGroupRiskModel",
    AwsExcessivePermissionRoleGroupRiskModelInfo = "AwsExcessivePermissionRoleGroupRiskModelInfo",
    AwsExcessivePermissionRoleGroupRiskModelInfoType = "AwsExcessivePermissionRoleGroupRiskModelInfoType",
    AwsExcessivePermissionRoleGroupRiskModelServiceExcessivenessInfo = "AwsExcessivePermissionRoleGroupRiskModelServiceExcessivenessInfo",
    AwsExcessivePermissionRoleGroupRiskPolicyConfiguration = "AwsExcessivePermissionRoleGroupRiskPolicyConfiguration",
    AwsExcessivePermissionRoleRisk = "AwsExcessivePermissionRoleRisk",
    AwsExcessivePermissionRoleRiskModel = "AwsExcessivePermissionRoleRiskModel",
    AwsExcessivePermissionRoleRiskPolicyConfiguration = "AwsExcessivePermissionRoleRiskPolicyConfiguration",
    AwsExcessivePermissionSecretRisk = "AwsExcessivePermissionSecretRisk",
    AwsExcessivePermissionSecretRiskModel = "AwsExcessivePermissionSecretRiskModel",
    AwsExcessivePermissionSecretRiskPolicyConfiguration = "AwsExcessivePermissionSecretRiskPolicyConfiguration",
    AwsExcessivePermissionUserRisk = "AwsExcessivePermissionUserRisk",
    AwsExcessivePermissionUserRiskModel = "AwsExcessivePermissionUserRiskModel",
    AwsExcessivePermissionUserRiskPolicyConfiguration = "AwsExcessivePermissionUserRiskPolicyConfiguration",
    AwsFederationEdgeFilters = "AwsFederationEdgeFilters",
    AwsFederationEdgeModel = "AwsFederationEdgeModel",
    AwsFirehoseDeliveryStream = "AwsFirehoseDeliveryStream",
    AwsFirehoseDeliveryStreamEncryptionDisabledCodeRisk = "AwsFirehoseDeliveryStreamEncryptionDisabledCodeRisk",
    AwsFirehoseDeliveryStreamEncryptionDisabledCodeRiskModel = "AwsFirehoseDeliveryStreamEncryptionDisabledCodeRiskModel",
    AwsFirehoseDeliveryStreamEncryptionDisabledRisk = "AwsFirehoseDeliveryStreamEncryptionDisabledRisk",
    AwsFirehoseDeliveryStreamEncryptionDisabledRiskModel = "AwsFirehoseDeliveryStreamEncryptionDisabledRiskModel",
    AwsFirehoseDeliveryStreamEncryptionDisabledRiskPolicyConfiguration = "AwsFirehoseDeliveryStreamEncryptionDisabledRiskPolicyConfiguration",
    AwsFirehoseDeliveryStreamEncryptionKeyType = "AwsFirehoseDeliveryStreamEncryptionKeyType",
    AwsFirehoseDeliveryStreamModel = "AwsFirehoseDeliveryStreamModel",
    AwsFirehoseDeliveryStreamProfile = "AwsFirehoseDeliveryStreamProfile",
    AwsFirehoseDeliveryStreamStatus = "AwsFirehoseDeliveryStreamStatus",
    AwsFirehoseDeliveryStreamType = "AwsFirehoseDeliveryStreamType",
    AwsFolderConfiguration = "AwsFolderConfiguration",
    AwsFolderConfigurationAnalysis = "AwsFolderConfigurationAnalysis",
    AwsFolderConfigurationAuthentication = "AwsFolderConfigurationAuthentication",
    AwsFolderConfigurationSections = "AwsFolderConfigurationSections",
    AwsIamAccessKey = "AwsIamAccessKey",
    AwsIamGroup = "AwsIamGroup",
    AwsIamGroupInactiveRisk = "AwsIamGroupInactiveRisk",
    AwsIamGroupInactiveRiskModel = "AwsIamGroupInactiveRiskModel",
    AwsIamGroupInactiveRiskPolicyConfiguration = "AwsIamGroupInactiveRiskPolicyConfiguration",
    AwsIamGroupModel = "AwsIamGroupModel",
    AwsIamGroupProfile = "AwsIamGroupProfile",
    AwsIamGroupProfileUsersInactive = "AwsIamGroupProfileUsersInactive",
    AwsIamGroupSnapshot = "AwsIamGroupSnapshot",
    AwsIamIdentity = "AwsIamIdentity",
    AwsIamIdentityAttachPermissionBoundaryPolicySnapshotChange = "AwsIamIdentityAttachPermissionBoundaryPolicySnapshotChange",
    AwsIamIdentityDetachPermissionBoundaryPolicySnapshotChange = "AwsIamIdentityDetachPermissionBoundaryPolicySnapshotChange",
    AwsIamIdentityEditPermissionBoundaryPolicySnapshotChange = "AwsIamIdentityEditPermissionBoundaryPolicySnapshotChange",
    AwsIamIdentityInactiveRisk = "AwsIamIdentityInactiveRisk",
    AwsIamIdentityInactiveRiskModel = "AwsIamIdentityInactiveRiskModel",
    AwsIamIdentityInactiveRiskModelInfoType = "AwsIamIdentityInactiveRiskModelInfoType",
    AwsIamIdentityModel = "AwsIamIdentityModel",
    AwsIamIdentityModelFilters = "AwsIamIdentityModelFilters",
    AwsIamIdentityProfile = "AwsIamIdentityProfile",
    AwsIamIdentityProvider = "AwsIamIdentityProvider",
    AwsIamIdentitySnapshot = "AwsIamIdentitySnapshot",
    AwsIamInlinePolicy = "AwsIamInlinePolicy",
    AwsIamInlinePolicyModel = "AwsIamInlinePolicyModel",
    AwsIamInlinePolicySnapshot = "AwsIamInlinePolicySnapshot",
    AwsIamInstanceProfile = "AwsIamInstanceProfile",
    AwsIamInstanceProfileModel = "AwsIamInstanceProfileModel",
    AwsIamManagedPolicy = "AwsIamManagedPolicy",
    AwsIamManagedPolicyModel = "AwsIamManagedPolicyModel",
    AwsIamManagedPolicyProfile = "AwsIamManagedPolicyProfile",
    AwsIamManagedPolicyRevision = "AwsIamManagedPolicyRevision",
    AwsIamManagedPolicySnapshot = "AwsIamManagedPolicySnapshot",
    AwsIamOidcIdentityProvider = "AwsIamOidcIdentityProvider",
    AwsIamOidcIdentityProviderModel = "AwsIamOidcIdentityProviderModel",
    AwsIamPasswordLengthPolicyCodeRisk = "AwsIamPasswordLengthPolicyCodeRisk",
    AwsIamPasswordLengthPolicyCodeRiskModel = "AwsIamPasswordLengthPolicyCodeRiskModel",
    AwsIamPasswordLengthPolicyRisk = "AwsIamPasswordLengthPolicyRisk",
    AwsIamPasswordLengthPolicyRiskModel = "AwsIamPasswordLengthPolicyRiskModel",
    AwsIamPasswordLengthPolicyRiskPolicyConfiguration = "AwsIamPasswordLengthPolicyRiskPolicyConfiguration",
    AwsIamPasswordLengthPolicyRiskPolicyUpdateAuditEventPasswordPolicyMinLengthChange = "AwsIamPasswordLengthPolicyRiskPolicyUpdateAuditEventPasswordPolicyMinLengthChange",
    AwsIamPasswordReusePolicyRisk = "AwsIamPasswordReusePolicyRisk",
    AwsIamPasswordReusePolicyRiskModel = "AwsIamPasswordReusePolicyRiskModel",
    AwsIamPasswordReusePolicyRiskPolicyConfiguration = "AwsIamPasswordReusePolicyRiskPolicyConfiguration",
    AwsIamPasswordReusePolicyRiskPolicyUpdateAuditEventPasswordPolicyPreventReusePreviousPasswordCountChange = "AwsIamPasswordReusePolicyRiskPolicyUpdateAuditEventPasswordPolicyPreventReusePreviousPasswordCountChange",
    AwsIamPolicy = "AwsIamPolicy",
    AwsIamPolicyDocument = "AwsIamPolicyDocument",
    AwsIamPolicyDocumentStatement = "AwsIamPolicyDocumentStatement",
    AwsIamPolicyDocumentStatementCondition = "AwsIamPolicyDocumentStatementCondition",
    AwsIamPolicyDocumentStatementConditionOperator = "AwsIamPolicyDocumentStatementConditionOperator",
    AwsIamPolicyDocumentStatementConditionOperatorPostfix = "AwsIamPolicyDocumentStatementConditionOperatorPostfix",
    AwsIamPolicyDocumentStatementConditionOperatorPrefix = "AwsIamPolicyDocumentStatementConditionOperatorPrefix",
    AwsIamPolicyDocumentStatementConditions = "AwsIamPolicyDocumentStatementConditions",
    AwsIamPolicyDocumentStatementEffect = "AwsIamPolicyDocumentStatementEffect",
    AwsIamPolicyDocumentStatementGrantees = "AwsIamPolicyDocumentStatementGrantees",
    AwsIamPrincipal = "AwsIamPrincipal",
    AwsIamPrincipalAddInlinePolicySnapshotChange = "AwsIamPrincipalAddInlinePolicySnapshotChange",
    AwsIamPrincipalAttachedPolicyPermissionScopeAnyCodeRisk = "AwsIamPrincipalAttachedPolicyPermissionScopeAnyCodeRisk",
    AwsIamPrincipalAttachedPolicyPermissionScopeAnyCodeRiskModel = "AwsIamPrincipalAttachedPolicyPermissionScopeAnyCodeRiskModel",
    AwsIamPrincipalAttachedPolicyPermissionScopeAnyRisk = "AwsIamPrincipalAttachedPolicyPermissionScopeAnyRisk",
    AwsIamPrincipalAttachedPolicyPermissionScopeAnyRiskModel = "AwsIamPrincipalAttachedPolicyPermissionScopeAnyRiskModel",
    AwsIamPrincipalAttachedPolicyPermissionScopeAnyRiskPolicyConfiguration = "AwsIamPrincipalAttachedPolicyPermissionScopeAnyRiskPolicyConfiguration",
    AwsIamPrincipalAttachManagedPolicySnapshotChange = "AwsIamPrincipalAttachManagedPolicySnapshotChange",
    AwsIamPrincipalCreationRisk = "AwsIamPrincipalCreationRisk",
    AwsIamPrincipalCreationRiskModel = "AwsIamPrincipalCreationRiskModel",
    AwsIamPrincipalCreationRiskPolicyConfiguration = "AwsIamPrincipalCreationRiskPolicyConfiguration",
    AwsIamPrincipalCriticalActionSeverityPermissionMatchCondition = "AwsIamPrincipalCriticalActionSeverityPermissionMatchCondition",
    AwsIamPrincipalDetachManagedPolicySnapshotChange = "AwsIamPrincipalDetachManagedPolicySnapshotChange",
    AwsIamPrincipalModel = "AwsIamPrincipalModel",
    AwsIamPrincipalPolicy = "AwsIamPrincipalPolicy",
    AwsIamPrincipalPolicyAssociation = "AwsIamPrincipalPolicyAssociation",
    AwsIamPrincipalPolicyEditSnapshotChange = "AwsIamPrincipalPolicyEditSnapshotChange",
    AwsIamPrincipalPolicyInfo = "AwsIamPrincipalPolicyInfo",
    AwsIamPrincipalPolicyModel = "AwsIamPrincipalPolicyModel",
    AwsIamPrincipalPolicyModelManagement = "AwsIamPrincipalPolicyModelManagement",
    AwsIamPrincipalPolicySnapshot = "AwsIamPrincipalPolicySnapshot",
    AwsIamPrincipalProfile = "AwsIamPrincipalProfile",
    AwsIamPrincipalRemoveInlinePolicySnapshotChange = "AwsIamPrincipalRemoveInlinePolicySnapshotChange",
    AwsIamPrincipalSnapshot = "AwsIamPrincipalSnapshot",
    AwsIamResource = "AwsIamResource",
    AwsIamRole = "AwsIamRole",
    AwsIamRoleGroup = "AwsIamRoleGroup",
    AwsIamRoleGroupModel = "AwsIamRoleGroupModel",
    AwsIamRoleGroupModelAccess = "AwsIamRoleGroupModelAccess",
    AwsIamRoleGroupPolicy = "AwsIamRoleGroupPolicy",
    AwsIamRoleGroupProfile = "AwsIamRoleGroupProfile",
    AwsIamRoleInactiveRisk = "AwsIamRoleInactiveRisk",
    AwsIamRoleInactiveRiskModel = "AwsIamRoleInactiveRiskModel",
    AwsIamRoleInactiveRiskPolicyConfiguration = "AwsIamRoleInactiveRiskPolicyConfiguration",
    AwsIamRoleManagementRisk = "AwsIamRoleManagementRisk",
    AwsIamRoleManagementRiskModel = "AwsIamRoleManagementRiskModel",
    AwsIamRoleManagementRiskPolicyConfiguration = "AwsIamRoleManagementRiskPolicyConfiguration",
    AwsIamRoleModel = "AwsIamRoleModel",
    AwsIamRoleModelAssumeRoleGranteeType = "AwsIamRoleModelAssumeRoleGranteeType",
    AwsIamRoleModelFilters = "AwsIamRoleModelFilters",
    AwsIamRoleProfile = "AwsIamRoleProfile",
    AwsIamRolePublicAccessExistsCodeRisk = "AwsIamRolePublicAccessExistsCodeRisk",
    AwsIamRolePublicAccessExistsCodeRiskModel = "AwsIamRolePublicAccessExistsCodeRiskModel",
    AwsIamRolePublicAccessExistsRisk = "AwsIamRolePublicAccessExistsRisk",
    AwsIamRolePublicAccessExistsRiskModel = "AwsIamRolePublicAccessExistsRiskModel",
    AwsIamRolePublicAccessExistsRiskPolicyConfiguration = "AwsIamRolePublicAccessExistsRiskPolicyConfiguration",
    AwsIamRoleSnapshot = "AwsIamRoleSnapshot",
    AwsIamRoleTemplateMismatchRiskItem = "AwsIamRoleTemplateMismatchRiskItem",
    AwsIamRoleVendorAssumeRolePolicyDocumentExternalIdConditionNotExistCodeRisk = "AwsIamRoleVendorAssumeRolePolicyDocumentExternalIdConditionNotExistCodeRisk",
    AwsIamRoleVendorAssumeRolePolicyDocumentExternalIdConditionNotExistCodeRiskModel = "AwsIamRoleVendorAssumeRolePolicyDocumentExternalIdConditionNotExistCodeRiskModel",
    AwsIamRoleVendorAssumeRolePolicyDocumentExternalIdConditionNotExistRisk = "AwsIamRoleVendorAssumeRolePolicyDocumentExternalIdConditionNotExistRisk",
    AwsIamRoleVendorAssumeRolePolicyDocumentExternalIdConditionNotExistRiskModel = "AwsIamRoleVendorAssumeRolePolicyDocumentExternalIdConditionNotExistRiskModel",
    AwsIamRoleVendorAssumeRolePolicyDocumentExternalIdConditionNotExistRiskPolicyConfiguration = "AwsIamRoleVendorAssumeRolePolicyDocumentExternalIdConditionNotExistRiskPolicyConfiguration",
    AwsIamRootUser = "AwsIamRootUser",
    AwsIamRootUserAccessKeyEnabledRisk = "AwsIamRootUserAccessKeyEnabledRisk",
    AwsIamRootUserAccessKeyEnabledRiskModel = "AwsIamRootUserAccessKeyEnabledRiskModel",
    AwsIamRootUserAccessKeyEnabledRiskPolicyConfiguration = "AwsIamRootUserAccessKeyEnabledRiskPolicyConfiguration",
    AwsIamRootUserActivityRisk = "AwsIamRootUserActivityRisk",
    AwsIamRootUserActivityRiskModel = "AwsIamRootUserActivityRiskModel",
    AwsIamRootUserActivityRiskPolicyConfiguration = "AwsIamRootUserActivityRiskPolicyConfiguration",
    AwsIamRootUserMfaDisabledRisk = "AwsIamRootUserMfaDisabledRisk",
    AwsIamRootUserMfaDisabledRiskModel = "AwsIamRootUserMfaDisabledRiskModel",
    AwsIamRootUserMfaDisabledRiskPolicyConfiguration = "AwsIamRootUserMfaDisabledRiskPolicyConfiguration",
    AwsIamRootUserMfaDisabledRiskPolicyUpdateAuditEventIncludeUnusedRootUserChange = "AwsIamRootUserMfaDisabledRiskPolicyUpdateAuditEventIncludeUnusedRootUserChange",
    AwsIamRootUserModel = "AwsIamRootUserModel",
    AwsIamRootUserProfile = "AwsIamRootUserProfile",
    AwsIamSamlIdentityProvider = "AwsIamSamlIdentityProvider",
    AwsIamSamlIdentityProviderModel = "AwsIamSamlIdentityProviderModel",
    AwsIamServerCertificate = "AwsIamServerCertificate",
    AwsIamServerCertificateExpiredRisk = "AwsIamServerCertificateExpiredRisk",
    AwsIamServerCertificateExpiredRiskModel = "AwsIamServerCertificateExpiredRiskModel",
    AwsIamServerCertificateExpiredRiskPolicyConfiguration = "AwsIamServerCertificateExpiredRiskPolicyConfiguration",
    AwsIamServerCertificateModel = "AwsIamServerCertificateModel",
    AwsIamServerCertificateModelFilters = "AwsIamServerCertificateModelFilters",
    AwsIamSupportRoleNotExistRisk = "AwsIamSupportRoleNotExistRisk",
    AwsIamSupportRoleNotExistRiskModel = "AwsIamSupportRoleNotExistRiskModel",
    AwsIamSupportRoleNotExistRiskPolicyConfiguration = "AwsIamSupportRoleNotExistRiskPolicyConfiguration",
    AwsIamUser = "AwsIamUser",
    AwsIamUserAccessKey = "AwsIamUserAccessKey",
    AwsIamUserAddGroupSnapshotChange = "AwsIamUserAddGroupSnapshotChange",
    AwsIamUserAttachedPolicyCodeRisk = "AwsIamUserAttachedPolicyCodeRisk",
    AwsIamUserAttachedPolicyCodeRiskModel = "AwsIamUserAttachedPolicyCodeRiskModel",
    AwsIamUserAttachedPolicyRisk = "AwsIamUserAttachedPolicyRisk",
    AwsIamUserAttachedPolicyRiskModel = "AwsIamUserAttachedPolicyRiskModel",
    AwsIamUserAttachedPolicyRiskPolicyConfiguration = "AwsIamUserAttachedPolicyRiskPolicyConfiguration",
    AwsIamUserCredentials = "AwsIamUserCredentials",
    AwsIamUserGroupIdMatchCondition = "AwsIamUserGroupIdMatchCondition",
    AwsIamUserInactiveRisk = "AwsIamUserInactiveRisk",
    AwsIamUserInactiveRiskModel = "AwsIamUserInactiveRiskModel",
    AwsIamUserInactiveRiskPolicyConfiguration = "AwsIamUserInactiveRiskPolicyConfiguration",
    AwsIamUserMfaDisabledRisk = "AwsIamUserMfaDisabledRisk",
    AwsIamUserMfaDisabledRiskModel = "AwsIamUserMfaDisabledRiskModel",
    AwsIamUserMfaDisabledRiskPolicyConfiguration = "AwsIamUserMfaDisabledRiskPolicyConfiguration",
    AwsIamUserModel = "AwsIamUserModel",
    AwsIamUserModelFilters = "AwsIamUserModelFilters",
    AwsIamUserMultipleEnabledAccessKeyRisk = "AwsIamUserMultipleEnabledAccessKeyRisk",
    AwsIamUserMultipleEnabledAccessKeyRiskModel = "AwsIamUserMultipleEnabledAccessKeyRiskModel",
    AwsIamUserMultipleEnabledAccessKeyRiskPolicyConfiguration = "AwsIamUserMultipleEnabledAccessKeyRiskPolicyConfiguration",
    AwsIamUserPassword = "AwsIamUserPassword",
    AwsIamUserProfile = "AwsIamUserProfile",
    AwsIamUserRemoveGroupSnapshotChange = "AwsIamUserRemoveGroupSnapshotChange",
    AwsIamUserSnapshot = "AwsIamUserSnapshot",
    AwsIamUserUnrotatedAccessKeyRisk = "AwsIamUserUnrotatedAccessKeyRisk",
    AwsIamUserUnrotatedAccessKeyRiskModel = "AwsIamUserUnrotatedAccessKeyRiskModel",
    AwsIamUserUnrotatedAccessKeyRiskPolicyConfiguration = "AwsIamUserUnrotatedAccessKeyRiskPolicyConfiguration",
    AwsIamUserUnrotatedAccessKeyRiskPolicyUpdateAuditEventAccessKeyRotationTimeFrameChange = "AwsIamUserUnrotatedAccessKeyRiskPolicyUpdateAuditEventAccessKeyRotationTimeFrameChange",
    AwsIamUserUnusedAccessKeysEnabledRisk = "AwsIamUserUnusedAccessKeysEnabledRisk",
    AwsIamUserUnusedAccessKeysEnabledRiskModel = "AwsIamUserUnusedAccessKeysEnabledRiskModel",
    AwsIamUserUnusedAccessKeysEnabledRiskPolicyConfiguration = "AwsIamUserUnusedAccessKeysEnabledRiskPolicyConfiguration",
    AwsIamUserUnusedAccessKeysEnabledRiskPolicyUpdateAuditEventAccessKeyUsageTimeFrameChange = "AwsIamUserUnusedAccessKeysEnabledRiskPolicyUpdateAuditEventAccessKeyUsageTimeFrameChange",
    AwsIamUserUnusedPasswordEnabledRisk = "AwsIamUserUnusedPasswordEnabledRisk",
    AwsIamUserUnusedPasswordEnabledRiskModel = "AwsIamUserUnusedPasswordEnabledRiskModel",
    AwsIamUserUnusedPasswordEnabledRiskPolicyConfiguration = "AwsIamUserUnusedPasswordEnabledRiskPolicyConfiguration",
    AwsIamUserUnusedPasswordEnabledRiskPolicyUpdateAuditEventPasswordUsageTimeFrameChange = "AwsIamUserUnusedPasswordEnabledRiskPolicyUpdateAuditEventPasswordUsageTimeFrameChange",
    AwsIamVirtualMfaDevice = "AwsIamVirtualMfaDevice",
    AwsIamVirtualMfaDeviceModel = "AwsIamVirtualMfaDeviceModel",
    AwsIdentityActivityRisk = "AwsIdentityActivityRisk",
    AwsIdentityActivityRiskModel = "AwsIdentityActivityRiskModel",
    AwsIdentityActivityRiskPolicyConfiguration = "AwsIdentityActivityRiskPolicyConfiguration",
    AwsInboundExternalEc2InstanceCodeRisk = "AwsInboundExternalEc2InstanceCodeRisk",
    AwsInboundExternalEc2InstanceCodeRiskModel = "AwsInboundExternalEc2InstanceCodeRiskModel",
    AwsInboundExternalEc2InstanceRisk = "AwsInboundExternalEc2InstanceRisk",
    AwsInboundExternalEc2InstanceRiskAutoScalingGroupsInfo = "AwsInboundExternalEc2InstanceRiskAutoScalingGroupsInfo",
    AwsInboundExternalEc2InstanceRiskGroupTagsInfo = "AwsInboundExternalEc2InstanceRiskGroupTagsInfo",
    AwsInboundExternalEc2InstanceRiskHighSeverityInstanceRolePermissionsInfo = "AwsInboundExternalEc2InstanceRiskHighSeverityInstanceRolePermissionsInfo",
    AwsInboundExternalEc2InstanceRiskInfo = "AwsInboundExternalEc2InstanceRiskInfo",
    AwsInboundExternalEc2InstanceRiskInfoType = "AwsInboundExternalEc2InstanceRiskInfoType",
    AwsInboundExternalEc2InstanceRiskInstanceData = "AwsInboundExternalEc2InstanceRiskInstanceData",
    AwsInboundExternalEc2InstanceRiskInstanceDataPublicAccess = "AwsInboundExternalEc2InstanceRiskInstanceDataPublicAccess",
    AwsInboundExternalEc2InstanceRiskInstanceDataVulnerabilities = "AwsInboundExternalEc2InstanceRiskInstanceDataVulnerabilities",
    AwsInboundExternalEc2InstanceRiskModel = "AwsInboundExternalEc2InstanceRiskModel",
    AwsInboundExternalEc2InstanceRiskPolicyConfiguration = "AwsInboundExternalEc2InstanceRiskPolicyConfiguration",
    AwsInboundExternalEc2InstanceRiskRunningInstancesInfo = "AwsInboundExternalEc2InstanceRiskRunningInstancesInfo",
    AwsInboundExternalEc2InstanceRiskSensitiveEntitiesInfo = "AwsInboundExternalEc2InstanceRiskSensitiveEntitiesInfo",
    AwsInboundExternalElbLoadBalancerCodeRisk = "AwsInboundExternalElbLoadBalancerCodeRisk",
    AwsInboundExternalElbLoadBalancerCodeRiskModel = "AwsInboundExternalElbLoadBalancerCodeRiskModel",
    AwsInboundExternalElbLoadBalancerRisk = "AwsInboundExternalElbLoadBalancerRisk",
    AwsInboundExternalElbLoadBalancerRiskModel = "AwsInboundExternalElbLoadBalancerRiskModel",
    AwsInboundExternalElbLoadBalancerRiskPolicyConfiguration = "AwsInboundExternalElbLoadBalancerRiskPolicyConfiguration",
    AwsInboundExternalRdsDatabaseInstanceCodeRisk = "AwsInboundExternalRdsDatabaseInstanceCodeRisk",
    AwsInboundExternalRdsDatabaseInstanceCodeRiskModel = "AwsInboundExternalRdsDatabaseInstanceCodeRiskModel",
    AwsInboundExternalRdsDatabaseInstanceRisk = "AwsInboundExternalRdsDatabaseInstanceRisk",
    AwsInboundExternalRdsDatabaseInstanceRiskModel = "AwsInboundExternalRdsDatabaseInstanceRiskModel",
    AwsInboundExternalRdsDatabaseInstanceRiskPolicyConfiguration = "AwsInboundExternalRdsDatabaseInstanceRiskPolicyConfiguration",
    AwsInboundExternalRedshiftClusterCodeRisk = "AwsInboundExternalRedshiftClusterCodeRisk",
    AwsInboundExternalRedshiftClusterCodeRiskModel = "AwsInboundExternalRedshiftClusterCodeRiskModel",
    AwsInboundExternalRedshiftClusterRisk = "AwsInboundExternalRedshiftClusterRisk",
    AwsInboundExternalRedshiftClusterRiskModel = "AwsInboundExternalRedshiftClusterRiskModel",
    AwsInboundExternalRedshiftClusterRiskPolicyConfiguration = "AwsInboundExternalRedshiftClusterRiskPolicyConfiguration",
    AwsInboundExternalResourceRisk = "AwsInboundExternalResourceRisk",
    AwsInboundExternalResourceRiskModel = "AwsInboundExternalResourceRiskModel",
    AwsInboundExternalSubnetRisk = "AwsInboundExternalSubnetRisk",
    AwsInboundExternalSubnetRiskItem = "AwsInboundExternalSubnetRiskItem",
    AwsInboundExternalSubnetRiskModel = "AwsInboundExternalSubnetRiskModel",
    AwsInboundExternalSubnetRiskPolicyConfiguration = "AwsInboundExternalSubnetRiskPolicyConfiguration",
    AwsInboundExternalVpcRisk = "AwsInboundExternalVpcRisk",
    AwsInboundExternalVpcRiskItem = "AwsInboundExternalVpcRiskItem",
    AwsInboundExternalVpcRiskModel = "AwsInboundExternalVpcRiskModel",
    AwsInboundExternalVpcRiskModelInternetGatewayAttachInfo = "AwsInboundExternalVpcRiskModelInternetGatewayAttachInfo",
    AwsInboundExternalVpcRiskPolicyConfiguration = "AwsInboundExternalVpcRiskPolicyConfiguration",
    AwsIotDomainConfiguration = "AwsIotDomainConfiguration",
    AwsKafkaCluster = "AwsKafkaCluster",
    AwsKinesisStream = "AwsKinesisStream",
    AwsKinesisStreamEncryptionDisabledCodeRisk = "AwsKinesisStreamEncryptionDisabledCodeRisk",
    AwsKinesisStreamEncryptionDisabledCodeRiskModel = "AwsKinesisStreamEncryptionDisabledCodeRiskModel",
    AwsKinesisStreamEncryptionDisabledRisk = "AwsKinesisStreamEncryptionDisabledRisk",
    AwsKinesisStreamEncryptionDisabledRiskModel = "AwsKinesisStreamEncryptionDisabledRiskModel",
    AwsKinesisStreamEncryptionDisabledRiskPolicyConfiguration = "AwsKinesisStreamEncryptionDisabledRiskPolicyConfiguration",
    AwsKinesisStreamMode = "AwsKinesisStreamMode",
    AwsKinesisStreamModel = "AwsKinesisStreamModel",
    AwsKinesisStreamProfile = "AwsKinesisStreamProfile",
    AwsKmsAlias = "AwsKmsAlias",
    AwsKmsAliasModel = "AwsKmsAliasModel",
    AwsKmsKey = "AwsKmsKey",
    AwsKmsKeyCustomerManagedMatchCondition = "AwsKmsKeyCustomerManagedMatchCondition",
    AwsKmsKeyGrant = "AwsKmsKeyGrant",
    AwsKmsKeyGrantListPart = "AwsKmsKeyGrantListPart",
    AwsKmsKeyGrantListPartModel = "AwsKmsKeyGrantListPartModel",
    AwsKmsKeyManagementRisk = "AwsKmsKeyManagementRisk",
    AwsKmsKeyManagementRiskModel = "AwsKmsKeyManagementRiskModel",
    AwsKmsKeyManagementRiskModelDescriptionType = "AwsKmsKeyManagementRiskModelDescriptionType",
    AwsKmsKeyManagementRiskPolicyConfiguration = "AwsKmsKeyManagementRiskPolicyConfiguration",
    AwsKmsKeyModel = "AwsKmsKeyModel",
    AwsKmsKeyProfile = "AwsKmsKeyProfile",
    AwsKmsKeyPublicAccessExistsCodeRisk = "AwsKmsKeyPublicAccessExistsCodeRisk",
    AwsKmsKeyPublicAccessExistsCodeRiskModel = "AwsKmsKeyPublicAccessExistsCodeRiskModel",
    AwsKmsKeyPublicAccessExistsRisk = "AwsKmsKeyPublicAccessExistsRisk",
    AwsKmsKeyPublicAccessExistsRiskModel = "AwsKmsKeyPublicAccessExistsRiskModel",
    AwsKmsKeyPublicAccessExistsRiskPolicyConfiguration = "AwsKmsKeyPublicAccessExistsRiskPolicyConfiguration",
    AwsKmsKeyRotationDisabledCodeRisk = "AwsKmsKeyRotationDisabledCodeRisk",
    AwsKmsKeyRotationDisabledCodeRiskModel = "AwsKmsKeyRotationDisabledCodeRiskModel",
    AwsKmsKeyRotationDisabledRisk = "AwsKmsKeyRotationDisabledRisk",
    AwsKmsKeyRotationDisabledRiskModel = "AwsKmsKeyRotationDisabledRiskModel",
    AwsKmsKeyRotationDisabledRiskPolicyConfiguration = "AwsKmsKeyRotationDisabledRiskPolicyConfiguration",
    AwsKmsKeyRotationDisabledRiskPolicyUpdateAuditEventKeyRotationTimeFrameChange = "AwsKmsKeyRotationDisabledRiskPolicyUpdateAuditEventKeyRotationTimeFrameChange",
    AwsKmsResource = "AwsKmsResource",
    AwsKubernetesClusterRole = "AwsKubernetesClusterRole",
    AwsKubernetesClusterRoleBinding = "AwsKubernetesClusterRoleBinding",
    AwsKubernetesClusterRoleBindingModel = "AwsKubernetesClusterRoleBindingModel",
    AwsKubernetesClusterRoleModel = "AwsKubernetesClusterRoleModel",
    AwsKubernetesConfigMap = "AwsKubernetesConfigMap",
    AwsKubernetesConfigMapModel = "AwsKubernetesConfigMapModel",
    AwsKubernetesCronJob = "AwsKubernetesCronJob",
    AwsKubernetesCronJobModel = "AwsKubernetesCronJobModel",
    AwsKubernetesDaemonSet = "AwsKubernetesDaemonSet",
    AwsKubernetesDaemonSetModel = "AwsKubernetesDaemonSetModel",
    AwsKubernetesDeployment = "AwsKubernetesDeployment",
    AwsKubernetesDeploymentModel = "AwsKubernetesDeploymentModel",
    AwsKubernetesGroup = "AwsKubernetesGroup",
    AwsKubernetesGroupModel = "AwsKubernetesGroupModel",
    AwsKubernetesIngress = "AwsKubernetesIngress",
    AwsKubernetesIngressClass = "AwsKubernetesIngressClass",
    AwsKubernetesIngressClassModel = "AwsKubernetesIngressClassModel",
    AwsKubernetesIngressModel = "AwsKubernetesIngressModel",
    AwsKubernetesJob = "AwsKubernetesJob",
    AwsKubernetesJobModel = "AwsKubernetesJobModel",
    AwsKubernetesNamespace = "AwsKubernetesNamespace",
    AwsKubernetesNamespaceModel = "AwsKubernetesNamespaceModel",
    AwsKubernetesNamespaceResource = "AwsKubernetesNamespaceResource",
    AwsKubernetesNamespaceResourceBase = "AwsKubernetesNamespaceResourceBase",
    AwsKubernetesNamespaceResourceModel = "AwsKubernetesNamespaceResourceModel",
    AwsKubernetesNamespaceRole = "AwsKubernetesNamespaceRole",
    AwsKubernetesNamespaceRoleBinding = "AwsKubernetesNamespaceRoleBinding",
    AwsKubernetesNamespaceRoleBindingModel = "AwsKubernetesNamespaceRoleBindingModel",
    AwsKubernetesNamespaceRoleModel = "AwsKubernetesNamespaceRoleModel",
    AwsKubernetesNetworkPolicy = "AwsKubernetesNetworkPolicy",
    AwsKubernetesNetworkPolicyModel = "AwsKubernetesNetworkPolicyModel",
    AwsKubernetesNode = "AwsKubernetesNode",
    AwsKubernetesNodeInstance = "AwsKubernetesNodeInstance",
    AwsKubernetesNodeModel = "AwsKubernetesNodeModel",
    AwsKubernetesPersistentVolume = "AwsKubernetesPersistentVolume",
    AwsKubernetesPersistentVolumeModel = "AwsKubernetesPersistentVolumeModel",
    AwsKubernetesPod = "AwsKubernetesPod",
    AwsKubernetesPodModel = "AwsKubernetesPodModel",
    AwsKubernetesPodSecurityPolicy = "AwsKubernetesPodSecurityPolicy",
    AwsKubernetesPodSecurityPolicyModel = "AwsKubernetesPodSecurityPolicyModel",
    AwsKubernetesPodTemplate = "AwsKubernetesPodTemplate",
    AwsKubernetesPodTemplateModel = "AwsKubernetesPodTemplateModel",
    AwsKubernetesReplicaSet = "AwsKubernetesReplicaSet",
    AwsKubernetesReplicaSetModel = "AwsKubernetesReplicaSetModel",
    AwsKubernetesResource = "AwsKubernetesResource",
    AwsKubernetesResourceBase = "AwsKubernetesResourceBase",
    AwsKubernetesResourceModel = "AwsKubernetesResourceModel",
    AwsKubernetesResourceTypeMetadata = "AwsKubernetesResourceTypeMetadata",
    AwsKubernetesService = "AwsKubernetesService",
    AwsKubernetesServiceAccount = "AwsKubernetesServiceAccount",
    AwsKubernetesServiceAccountModel = "AwsKubernetesServiceAccountModel",
    AwsKubernetesServiceModel = "AwsKubernetesServiceModel",
    AwsKubernetesStatefulSet = "AwsKubernetesStatefulSet",
    AwsKubernetesStatefulSetModel = "AwsKubernetesStatefulSetModel",
    AwsKubernetesUser = "AwsKubernetesUser",
    AwsKubernetesUserModel = "AwsKubernetesUserModel",
    AwsKubernetesWorkloadResource = "AwsKubernetesWorkloadResource",
    AwsKubernetesWorkloadResourceBase = "AwsKubernetesWorkloadResourceBase",
    AwsKubernetesWorkloadResourceModel = "AwsKubernetesWorkloadResourceModel",
    AwsLambdaFunctionConfiguration = "AwsLambdaFunctionConfiguration",
    AwsLambdaFunctionConfigurationAlias = "AwsLambdaFunctionConfigurationAlias",
    AwsLambdaFunctionConfigurationDeprecatedRuntimeCodeRisk = "AwsLambdaFunctionConfigurationDeprecatedRuntimeCodeRisk",
    AwsLambdaFunctionConfigurationDeprecatedRuntimeCodeRiskModel = "AwsLambdaFunctionConfigurationDeprecatedRuntimeCodeRiskModel",
    AwsLambdaFunctionConfigurationDeprecatedRuntimeRisk = "AwsLambdaFunctionConfigurationDeprecatedRuntimeRisk",
    AwsLambdaFunctionConfigurationDeprecatedRuntimeRiskModel = "AwsLambdaFunctionConfigurationDeprecatedRuntimeRiskModel",
    AwsLambdaFunctionConfigurationDeprecatedRuntimeRiskPolicyConfiguration = "AwsLambdaFunctionConfigurationDeprecatedRuntimeRiskPolicyConfiguration",
    AwsLambdaFunctionConfigurationEnvironmentVariableSecretExistsCodeRisk = "AwsLambdaFunctionConfigurationEnvironmentVariableSecretExistsCodeRisk",
    AwsLambdaFunctionConfigurationEnvironmentVariableSecretExistsCodeRiskModel = "AwsLambdaFunctionConfigurationEnvironmentVariableSecretExistsCodeRiskModel",
    AwsLambdaFunctionConfigurationEnvironmentVariableSecretExistsRisk = "AwsLambdaFunctionConfigurationEnvironmentVariableSecretExistsRisk",
    AwsLambdaFunctionConfigurationEnvironmentVariableSecretExistsRiskModel = "AwsLambdaFunctionConfigurationEnvironmentVariableSecretExistsRiskModel",
    AwsLambdaFunctionConfigurationEnvironmentVariableSecretExistsRiskPolicyConfiguration = "AwsLambdaFunctionConfigurationEnvironmentVariableSecretExistsRiskPolicyConfiguration",
    AwsLambdaFunctionConfigurationModel = "AwsLambdaFunctionConfigurationModel",
    AwsLambdaFunctionConfigurationModelAccessType = "AwsLambdaFunctionConfigurationModelAccessType",
    AwsLambdaFunctionConfigurationMultiAvailabilityZoneNotExistCodeRisk = "AwsLambdaFunctionConfigurationMultiAvailabilityZoneNotExistCodeRisk",
    AwsLambdaFunctionConfigurationMultiAvailabilityZoneNotExistCodeRiskModel = "AwsLambdaFunctionConfigurationMultiAvailabilityZoneNotExistCodeRiskModel",
    AwsLambdaFunctionConfigurationMultiAvailabilityZoneNotExistRisk = "AwsLambdaFunctionConfigurationMultiAvailabilityZoneNotExistRisk",
    AwsLambdaFunctionConfigurationMultiAvailabilityZoneNotExistRiskModel = "AwsLambdaFunctionConfigurationMultiAvailabilityZoneNotExistRiskModel",
    AwsLambdaFunctionConfigurationMultiAvailabilityZoneNotExistRiskPolicyConfiguration = "AwsLambdaFunctionConfigurationMultiAvailabilityZoneNotExistRiskPolicyConfiguration",
    AwsLambdaFunctionConfigurationPackageType = "AwsLambdaFunctionConfigurationPackageType",
    AwsLambdaFunctionConfigurationProfile = "AwsLambdaFunctionConfigurationProfile",
    AwsLambdaFunctionConfigurationPublicAccessExistsApiGatewayRouteExclusionRoutePattern = "AwsLambdaFunctionConfigurationPublicAccessExistsApiGatewayRouteExclusionRoutePattern",
    AwsLambdaFunctionConfigurationPublicAccessExistsCodeRisk = "AwsLambdaFunctionConfigurationPublicAccessExistsCodeRisk",
    AwsLambdaFunctionConfigurationPublicAccessExistsCodeRiskModel = "AwsLambdaFunctionConfigurationPublicAccessExistsCodeRiskModel",
    AwsLambdaFunctionConfigurationPublicAccessExistsRisk = "AwsLambdaFunctionConfigurationPublicAccessExistsRisk",
    AwsLambdaFunctionConfigurationPublicAccessExistsRiskModel = "AwsLambdaFunctionConfigurationPublicAccessExistsRiskModel",
    AwsLambdaFunctionConfigurationPublicAccessExistsRiskPolicyConfiguration = "AwsLambdaFunctionConfigurationPublicAccessExistsRiskPolicyConfiguration",
    AwsLambdaFunctionConfigurationPublicAccessExistsRiskPolicyUpdateAuditEventApiGatewayRouteExclusionsChange = "AwsLambdaFunctionConfigurationPublicAccessExistsRiskPolicyUpdateAuditEventApiGatewayRouteExclusionsChange",
    AwsLambdaFunctionConfigurationRevision = "AwsLambdaFunctionConfigurationRevision",
    AwsLambdaFunctionConfigurationTriggers = "AwsLambdaFunctionConfigurationTriggers",
    AwsLambdaFunctionConfigurationTriggersExternalTrigger = "AwsLambdaFunctionConfigurationTriggersExternalTrigger",
    AwsLambdaFunctionConfigurationTriggersExternalTriggerType = "AwsLambdaFunctionConfigurationTriggersExternalTriggerType",
    AwsLambdaFunctionConfigurationTriggersTrigger = "AwsLambdaFunctionConfigurationTriggersTrigger",
    AwsLambdaFunctionConfigurationUrl = "AwsLambdaFunctionConfigurationUrl",
    AwsLambdaFunctionConfigurationVpcNotExistsCodeRisk = "AwsLambdaFunctionConfigurationVpcNotExistsCodeRisk",
    AwsLambdaFunctionConfigurationVpcNotExistsCodeRiskModel = "AwsLambdaFunctionConfigurationVpcNotExistsCodeRiskModel",
    AwsLambdaFunctionConfigurationVpcNotExistsRisk = "AwsLambdaFunctionConfigurationVpcNotExistsRisk",
    AwsLambdaFunctionConfigurationVpcNotExistsRiskModel = "AwsLambdaFunctionConfigurationVpcNotExistsRiskModel",
    AwsLambdaFunctionConfigurationVpcNotExistsRiskPolicyConfiguration = "AwsLambdaFunctionConfigurationVpcNotExistsRiskPolicyConfiguration",
    AwsLogsLogGroup = "AwsLogsLogGroup",
    AwsLogsLogGroupMetricFilter = "AwsLogsLogGroupMetricFilter",
    AwsLogsLogGroupMetricFilterMetric = "AwsLogsLogGroupMetricFilterMetric",
    AwsLogsLogGroupModel = "AwsLogsLogGroupModel",
    AwsNetworkAccessManagementRisk = "AwsNetworkAccessManagementRisk",
    AwsNetworkAccessManagementRiskModel = "AwsNetworkAccessManagementRiskModel",
    AwsNetworkAccessManagementRiskPolicyConfiguration = "AwsNetworkAccessManagementRiskPolicyConfiguration",
    AwsNetworkAccessResourceStateNetwork = "AwsNetworkAccessResourceStateNetwork",
    AwsNetworkedResourceInboundExternalPortRisk = "AwsNetworkedResourceInboundExternalPortRisk",
    AwsNetworkedResourceInboundExternalPortRiskItem = "AwsNetworkedResourceInboundExternalPortRiskItem",
    AwsNetworkedResourceInboundExternalPortRiskModel = "AwsNetworkedResourceInboundExternalPortRiskModel",
    AwsNetworkedResourceInboundExternalPortRiskPolicyConfiguration = "AwsNetworkedResourceInboundExternalPortRiskPolicyConfiguration",
    AwsNetworkedResourceStateNetwork = "AwsNetworkedResourceStateNetwork",
    AwsNetworkScope = "AwsNetworkScope",
    AwsNotSyncedResource = "AwsNotSyncedResource",
    AwsOpenShiftBuildConfig = "AwsOpenShiftBuildConfig",
    AwsOpenShiftBuildConfigModel = "AwsOpenShiftBuildConfigModel",
    AwsOpenShiftCatalogSource = "AwsOpenShiftCatalogSource",
    AwsOpenShiftCatalogSourceModel = "AwsOpenShiftCatalogSourceModel",
    AwsOpenShiftDeploymentConfig = "AwsOpenShiftDeploymentConfig",
    AwsOpenShiftDeploymentConfigModel = "AwsOpenShiftDeploymentConfigModel",
    AwsOpenShiftImageStream = "AwsOpenShiftImageStream",
    AwsOpenShiftImageStreamModel = "AwsOpenShiftImageStreamModel",
    AwsOpenShiftProject = "AwsOpenShiftProject",
    AwsOpenShiftProjectModel = "AwsOpenShiftProjectModel",
    AwsOpenShiftRoute = "AwsOpenShiftRoute",
    AwsOpenShiftRouteModel = "AwsOpenShiftRouteModel",
    AwsOpenShiftSecurityContextConstraint = "AwsOpenShiftSecurityContextConstraint",
    AwsOpenShiftSecurityContextConstraintModel = "AwsOpenShiftSecurityContextConstraintModel",
    AwsOpenShiftTemplate = "AwsOpenShiftTemplate",
    AwsOpenShiftTemplateModel = "AwsOpenShiftTemplateModel",
    AwsOrganizationAuditEvent = "AwsOrganizationAuditEvent",
    AwsOrganizationAuditEventData = "AwsOrganizationAuditEventData",
    AwsOrganizationConfiguration = "AwsOrganizationConfiguration",
    AwsOrganizationCreationAuditEvent = "AwsOrganizationCreationAuditEvent",
    AwsOrganizationDeletionAuditEvent = "AwsOrganizationDeletionAuditEvent",
    AwsOrganizationsAccount = "AwsOrganizationsAccount",
    AwsOrganizationsAccountModel = "AwsOrganizationsAccountModel",
    AwsOrganizationsAccountOrganizationJoinMethod = "AwsOrganizationsAccountOrganizationJoinMethod",
    AwsOrganizationsAccountStatus = "AwsOrganizationsAccountStatus",
    AwsOrganizationsOrganization = "AwsOrganizationsOrganization",
    AwsOrganizationsOrganizationalUnit = "AwsOrganizationsOrganizationalUnit",
    AwsOrganizationsOrganizationalUnitModel = "AwsOrganizationsOrganizationalUnitModel",
    AwsOrganizationsOrganizationModel = "AwsOrganizationsOrganizationModel",
    AwsOrganizationsPolicy = "AwsOrganizationsPolicy",
    AwsOrganizationsPolicyModel = "AwsOrganizationsPolicyModel",
    AwsOrganizationsPolicyProfile = "AwsOrganizationsPolicyProfile",
    AwsOrganizationsPolicyType = "AwsOrganizationsPolicyType",
    AwsOrganizationsResource = "AwsOrganizationsResource",
    AwsOrganizationsRoot = "AwsOrganizationsRoot",
    AwsOrganizationsRootModel = "AwsOrganizationsRootModel",
    AwsOrganizationsScopeResource = "AwsOrganizationsScopeResource",
    AwsOrganizationState = "AwsOrganizationState",
    AwsOrganizationUpdateAuditEvent = "AwsOrganizationUpdateAuditEvent",
    AwsPartialEntity = "AwsPartialEntity",
    AwsPartialEntityModel = "AwsPartialEntityModel",
    AwsPartialTenantEntity = "AwsPartialTenantEntity",
    AwsPartialTenantEntityModel = "AwsPartialTenantEntityModel",
    AwsPartialTenantEntityTypeMetadata = "AwsPartialTenantEntityTypeMetadata",
    AwsPartitionType = "AwsPartitionType",
    AwsPermissionEdgeActionConditionType = "AwsPermissionEdgeActionConditionType",
    AwsPermissionManagementRisk = "AwsPermissionManagementRisk",
    AwsPermissionManagementRiskModel = "AwsPermissionManagementRiskModel",
    AwsPermissionManagementRiskModelDescriptionType = "AwsPermissionManagementRiskModelDescriptionType",
    AwsPermissionManagementRiskPolicyConfiguration = "AwsPermissionManagementRiskPolicyConfiguration",
    AwsPermissionPresetAuditEvent = "AwsPermissionPresetAuditEvent",
    AwsPermissionPresetCreationAuditEvent = "AwsPermissionPresetCreationAuditEvent",
    AwsPermissionPresetDeletionAuditEvent = "AwsPermissionPresetDeletionAuditEvent",
    AwsPermissionPresetUpdateAuditEvent = "AwsPermissionPresetUpdateAuditEvent",
    AwsPermitter = "AwsPermitter",
    AwsPolicyResourceAddPolicySnapshotChange = "AwsPolicyResourceAddPolicySnapshotChange",
    AwsPolicyResourceEditPolicySnapshotChange = "AwsPolicyResourceEditPolicySnapshotChange",
    AwsPolicyResourceRemovePolicySnapshotChange = "AwsPolicyResourceRemovePolicySnapshotChange",
    AwsPrincipalAllowedPermissionRisk = "AwsPrincipalAllowedPermissionRisk",
    AwsPrincipalAllowedPermissionRiskItem = "AwsPrincipalAllowedPermissionRiskItem",
    AwsPrincipalAllowedPermissionRiskModel = "AwsPrincipalAllowedPermissionRiskModel",
    AwsPrincipalAllowedPermissionRiskPolicyConfiguration = "AwsPrincipalAllowedPermissionRiskPolicyConfiguration",
    AwsPrincipalAllowedResourcePermissionRisk = "AwsPrincipalAllowedResourcePermissionRisk",
    AwsPrincipalAllowedResourcePermissionRiskModel = "AwsPrincipalAllowedResourcePermissionRiskModel",
    AwsPrincipalAllowedResourcePermissionRiskPolicyConfiguration = "AwsPrincipalAllowedResourcePermissionRiskPolicyConfiguration",
    AwsPrincipalNonexcessivePolicyGeneratorServiceLevel = "AwsPrincipalNonexcessivePolicyGeneratorServiceLevel",
    AwsPrincipalNonexcessivePolicyGeneratorServiceResourceLevel = "AwsPrincipalNonexcessivePolicyGeneratorServiceResourceLevel",
    AwsPrincipalNotAllowedResourcePermissionRisk = "AwsPrincipalNotAllowedResourcePermissionRisk",
    AwsPrincipalNotAllowedResourcePermissionRiskModel = "AwsPrincipalNotAllowedResourcePermissionRiskModel",
    AwsPrincipalNotAllowedResourcePermissionRiskPolicyConfiguration = "AwsPrincipalNotAllowedResourcePermissionRiskPolicyConfiguration",
    AwsPrincipalPolicyChange = "AwsPrincipalPolicyChange",
    AwsPrincipalResourcePermissionRisk = "AwsPrincipalResourcePermissionRisk",
    AwsPrincipalResourcePermissionRiskItem = "AwsPrincipalResourcePermissionRiskItem",
    AwsPrincipalResourcePermissionRiskModel = "AwsPrincipalResourcePermissionRiskModel",
    AwsPrincipalResourcePermissionRiskPolicyConfiguration = "AwsPrincipalResourcePermissionRiskPolicyConfiguration",
    AwsPrivilegeEscalationRisk = "AwsPrivilegeEscalationRisk",
    AwsPrivilegeEscalationRiskModel = "AwsPrivilegeEscalationRiskModel",
    AwsPrivilegeEscalationRiskModelDescriptionType = "AwsPrivilegeEscalationRiskModelDescriptionType",
    AwsPrivilegeEscalationRiskPolicyConfiguration = "AwsPrivilegeEscalationRiskPolicyConfiguration",
    AwsProvisionPermissionSetChange = "AwsProvisionPermissionSetChange",
    AwsRdsAuroraGlobalCluster = "AwsRdsAuroraGlobalCluster",
    AwsRdsAuroraGlobalClusterModel = "AwsRdsAuroraGlobalClusterModel",
    AwsRdsAuroraGlobalClusterProfile = "AwsRdsAuroraGlobalClusterProfile",
    AwsRdsCluster = "AwsRdsCluster",
    AwsRdsClusterBackupRetentionTimeFrameCodeRisk = "AwsRdsClusterBackupRetentionTimeFrameCodeRisk",
    AwsRdsClusterBackupRetentionTimeFrameCodeRiskModel = "AwsRdsClusterBackupRetentionTimeFrameCodeRiskModel",
    AwsRdsClusterBackupRetentionTimeFrameRisk = "AwsRdsClusterBackupRetentionTimeFrameRisk",
    AwsRdsClusterBackupRetentionTimeFrameRiskModel = "AwsRdsClusterBackupRetentionTimeFrameRiskModel",
    AwsRdsClusterBackupRetentionTimeFrameRiskPolicyConfiguration = "AwsRdsClusterBackupRetentionTimeFrameRiskPolicyConfiguration",
    AwsRdsClusterBackupRetentionTimeFrameRiskPolicyUpdateAuditEventBackupRetentionTimeFrameChange = "AwsRdsClusterBackupRetentionTimeFrameRiskPolicyUpdateAuditEventBackupRetentionTimeFrameChange",
    AwsRdsClusterCloudWatchLoggingDisabledRisk = "AwsRdsClusterCloudWatchLoggingDisabledRisk",
    AwsRdsClusterCloudWatchLoggingDisabledRiskModel = "AwsRdsClusterCloudWatchLoggingDisabledRiskModel",
    AwsRdsClusterCloudWatchLoggingDisabledRiskPolicyConfiguration = "AwsRdsClusterCloudWatchLoggingDisabledRiskPolicyConfiguration",
    AwsRdsClusterDeleteProtectionDisabledCodeRisk = "AwsRdsClusterDeleteProtectionDisabledCodeRisk",
    AwsRdsClusterDeleteProtectionDisabledCodeRiskModel = "AwsRdsClusterDeleteProtectionDisabledCodeRiskModel",
    AwsRdsClusterDeleteProtectionDisabledRisk = "AwsRdsClusterDeleteProtectionDisabledRisk",
    AwsRdsClusterDeleteProtectionDisabledRiskModel = "AwsRdsClusterDeleteProtectionDisabledRiskModel",
    AwsRdsClusterDeleteProtectionDisabledRiskPolicyConfiguration = "AwsRdsClusterDeleteProtectionDisabledRiskPolicyConfiguration",
    AwsRdsClusterEngineType = "AwsRdsClusterEngineType",
    AwsRdsClusterMinorVersionUpgradeDisabledRisk = "AwsRdsClusterMinorVersionUpgradeDisabledRisk",
    AwsRdsClusterMinorVersionUpgradeDisabledRiskModel = "AwsRdsClusterMinorVersionUpgradeDisabledRiskModel",
    AwsRdsClusterMinorVersionUpgradeDisabledRiskPolicyConfiguration = "AwsRdsClusterMinorVersionUpgradeDisabledRiskPolicyConfiguration",
    AwsRdsClusterModel = "AwsRdsClusterModel",
    AwsRdsClusterModelFilters = "AwsRdsClusterModelFilters",
    AwsRdsClusterProfile = "AwsRdsClusterProfile",
    AwsRdsClusterResource = "AwsRdsClusterResource",
    AwsRdsClusterResourceModel = "AwsRdsClusterResourceModel",
    AwsRdsClusterResourceProfile = "AwsRdsClusterResourceProfile",
    AwsRdsClusterResourceTypeMetadata = "AwsRdsClusterResourceTypeMetadata",
    AwsRdsClusterSnapshot = "AwsRdsClusterSnapshot",
    AwsRdsClusterSnapshotModel = "AwsRdsClusterSnapshotModel",
    AwsRdsClusterSnapshotProfile = "AwsRdsClusterSnapshotProfile",
    AwsRdsClusterSnapshotTypeMetadata = "AwsRdsClusterSnapshotTypeMetadata",
    AwsRdsClusterStorageEncryptionDisabledCodeRisk = "AwsRdsClusterStorageEncryptionDisabledCodeRisk",
    AwsRdsClusterStorageEncryptionDisabledCodeRiskModel = "AwsRdsClusterStorageEncryptionDisabledCodeRiskModel",
    AwsRdsClusterStorageEncryptionDisabledRiskPolicyConfiguration = "AwsRdsClusterStorageEncryptionDisabledRiskPolicyConfiguration",
    AwsRdsDatabaseInstance = "AwsRdsDatabaseInstance",
    AwsRdsDatabaseInstanceBackupRetentionTimeFrameCodeRisk = "AwsRdsDatabaseInstanceBackupRetentionTimeFrameCodeRisk",
    AwsRdsDatabaseInstanceBackupRetentionTimeFrameCodeRiskModel = "AwsRdsDatabaseInstanceBackupRetentionTimeFrameCodeRiskModel",
    AwsRdsDatabaseInstanceBackupRetentionTimeFrameRisk = "AwsRdsDatabaseInstanceBackupRetentionTimeFrameRisk",
    AwsRdsDatabaseInstanceBackupRetentionTimeFrameRiskModel = "AwsRdsDatabaseInstanceBackupRetentionTimeFrameRiskModel",
    AwsRdsDatabaseInstanceBackupRetentionTimeFrameRiskPolicyConfiguration = "AwsRdsDatabaseInstanceBackupRetentionTimeFrameRiskPolicyConfiguration",
    AwsRdsDatabaseInstanceBackupRetentionTimeFrameRiskPolicyUpdateAuditEventBackupRetentionTimeFrameChange = "AwsRdsDatabaseInstanceBackupRetentionTimeFrameRiskPolicyUpdateAuditEventBackupRetentionTimeFrameChange",
    AwsRdsDatabaseInstanceCloudWatchLoggingDisabledRisk = "AwsRdsDatabaseInstanceCloudWatchLoggingDisabledRisk",
    AwsRdsDatabaseInstanceCloudWatchLoggingDisabledRiskModel = "AwsRdsDatabaseInstanceCloudWatchLoggingDisabledRiskModel",
    AwsRdsDatabaseInstanceCloudWatchLoggingDisabledRiskPolicyConfiguration = "AwsRdsDatabaseInstanceCloudWatchLoggingDisabledRiskPolicyConfiguration",
    AwsRdsDatabaseInstanceDeleteProtectionDisabledCodeRisk = "AwsRdsDatabaseInstanceDeleteProtectionDisabledCodeRisk",
    AwsRdsDatabaseInstanceDeleteProtectionDisabledCodeRiskModel = "AwsRdsDatabaseInstanceDeleteProtectionDisabledCodeRiskModel",
    AwsRdsDatabaseInstanceDeleteProtectionDisabledRisk = "AwsRdsDatabaseInstanceDeleteProtectionDisabledRisk",
    AwsRdsDatabaseInstanceDeleteProtectionDisabledRiskModel = "AwsRdsDatabaseInstanceDeleteProtectionDisabledRiskModel",
    AwsRdsDatabaseInstanceDeleteProtectionDisabledRiskPolicyConfiguration = "AwsRdsDatabaseInstanceDeleteProtectionDisabledRiskPolicyConfiguration",
    AwsRdsDatabaseInstanceEngineType = "AwsRdsDatabaseInstanceEngineType",
    AwsRdsDatabaseInstanceMinorVersionUpgradeDisabledCodeRisk = "AwsRdsDatabaseInstanceMinorVersionUpgradeDisabledCodeRisk",
    AwsRdsDatabaseInstanceMinorVersionUpgradeDisabledCodeRiskModel = "AwsRdsDatabaseInstanceMinorVersionUpgradeDisabledCodeRiskModel",
    AwsRdsDatabaseInstanceMinorVersionUpgradeDisabledRisk = "AwsRdsDatabaseInstanceMinorVersionUpgradeDisabledRisk",
    AwsRdsDatabaseInstanceMinorVersionUpgradeDisabledRiskModel = "AwsRdsDatabaseInstanceMinorVersionUpgradeDisabledRiskModel",
    AwsRdsDatabaseInstanceMinorVersionUpgradeDisabledRiskPolicyConfiguration = "AwsRdsDatabaseInstanceMinorVersionUpgradeDisabledRiskPolicyConfiguration",
    AwsRdsDatabaseInstanceModel = "AwsRdsDatabaseInstanceModel",
    AwsRdsDatabaseInstanceModelFilters = "AwsRdsDatabaseInstanceModelFilters",
    AwsRdsDatabaseInstanceProfile = "AwsRdsDatabaseInstanceProfile",
    AwsRdsDatabaseInstanceResource = "AwsRdsDatabaseInstanceResource",
    AwsRdsDatabaseInstanceResourceModel = "AwsRdsDatabaseInstanceResourceModel",
    AwsRdsDatabaseInstanceResourceProfile = "AwsRdsDatabaseInstanceResourceProfile",
    AwsRdsDatabaseInstanceResourceTypeMetadata = "AwsRdsDatabaseInstanceResourceTypeMetadata",
    AwsRdsDatabaseInstanceSnapshot = "AwsRdsDatabaseInstanceSnapshot",
    AwsRdsDatabaseInstanceSnapshotModel = "AwsRdsDatabaseInstanceSnapshotModel",
    AwsRdsDatabaseInstanceSnapshotProfile = "AwsRdsDatabaseInstanceSnapshotProfile",
    AwsRdsDatabaseInstanceStorageEncryptionDisabledCodeRisk = "AwsRdsDatabaseInstanceStorageEncryptionDisabledCodeRisk",
    AwsRdsDatabaseInstanceStorageEncryptionDisabledCodeRiskModel = "AwsRdsDatabaseInstanceStorageEncryptionDisabledCodeRiskModel",
    AwsRdsDatabaseInstanceStorageEncryptionDisabledRisk = "AwsRdsDatabaseInstanceStorageEncryptionDisabledRisk",
    AwsRdsDatabaseInstanceStorageEncryptionDisabledRiskModel = "AwsRdsDatabaseInstanceStorageEncryptionDisabledRiskModel",
    AwsRdsDatabaseInstanceStorageEncryptionDisabledRiskPolicyConfiguration = "AwsRdsDatabaseInstanceStorageEncryptionDisabledRiskPolicyConfiguration",
    AwsRdsDatabaseSecurityGroup = "AwsRdsDatabaseSecurityGroup",
    AwsRdsDatabaseSecurityGroupModel = "AwsRdsDatabaseSecurityGroupModel",
    AwsRdsDocDbCluster = "AwsRdsDocDbCluster",
    AwsRdsDocDbClusterCloudWatchLoggingDisabledRisk = "AwsRdsDocDbClusterCloudWatchLoggingDisabledRisk",
    AwsRdsDocDbClusterCloudWatchLoggingDisabledRiskModel = "AwsRdsDocDbClusterCloudWatchLoggingDisabledRiskModel",
    AwsRdsDocDbClusterCloudWatchLoggingDisabledRiskPolicyConfiguration = "AwsRdsDocDbClusterCloudWatchLoggingDisabledRiskPolicyConfiguration",
    AwsRdsDocDbClusterDeleteProtectionDisabledCodeRisk = "AwsRdsDocDbClusterDeleteProtectionDisabledCodeRisk",
    AwsRdsDocDbClusterDeleteProtectionDisabledCodeRiskModel = "AwsRdsDocDbClusterDeleteProtectionDisabledCodeRiskModel",
    AwsRdsDocDbClusterDeleteProtectionDisabledRisk = "AwsRdsDocDbClusterDeleteProtectionDisabledRisk",
    AwsRdsDocDbClusterDeleteProtectionDisabledRiskModel = "AwsRdsDocDbClusterDeleteProtectionDisabledRiskModel",
    AwsRdsDocDbClusterDeleteProtectionDisabledRiskPolicyConfiguration = "AwsRdsDocDbClusterDeleteProtectionDisabledRiskPolicyConfiguration",
    AwsRdsDocDbClusterModel = "AwsRdsDocDbClusterModel",
    AwsRdsDocDbClusterProfile = "AwsRdsDocDbClusterProfile",
    AwsRdsDocDbClusterSnapshot = "AwsRdsDocDbClusterSnapshot",
    AwsRdsDocDbClusterSnapshotEncryptionDisabledRisk = "AwsRdsDocDbClusterSnapshotEncryptionDisabledRisk",
    AwsRdsDocDbClusterSnapshotEncryptionDisabledRiskModel = "AwsRdsDocDbClusterSnapshotEncryptionDisabledRiskModel",
    AwsRdsDocDbClusterSnapshotEncryptionDisabledRiskPolicyConfiguration = "AwsRdsDocDbClusterSnapshotEncryptionDisabledRiskPolicyConfiguration",
    AwsRdsDocDbClusterSnapshotModel = "AwsRdsDocDbClusterSnapshotModel",
    AwsRdsDocDbClusterSnapshotProfile = "AwsRdsDocDbClusterSnapshotProfile",
    AwsRdsDocDbClusterSnapshotPublicAccessExistsRisk = "AwsRdsDocDbClusterSnapshotPublicAccessExistsRisk",
    AwsRdsDocDbClusterSnapshotPublicAccessExistsRiskModel = "AwsRdsDocDbClusterSnapshotPublicAccessExistsRiskModel",
    AwsRdsDocDbClusterSnapshotPublicAccessExistsRiskPolicyConfiguration = "AwsRdsDocDbClusterSnapshotPublicAccessExistsRiskPolicyConfiguration",
    AwsRdsDocDbDatabaseInstance = "AwsRdsDocDbDatabaseInstance",
    AwsRdsDocDbDatabaseInstanceModel = "AwsRdsDocDbDatabaseInstanceModel",
    AwsRdsDocDbDatabaseInstanceProfile = "AwsRdsDocDbDatabaseInstanceProfile",
    AwsRdsDocDbGlobalCluster = "AwsRdsDocDbGlobalCluster",
    AwsRdsDocDbGlobalClusterModel = "AwsRdsDocDbGlobalClusterModel",
    AwsRdsDocDbGlobalClusterProfile = "AwsRdsDocDbGlobalClusterProfile",
    AwsRdsGlobalClusterResource = "AwsRdsGlobalClusterResource",
    AwsRdsGlobalClusterResourceModel = "AwsRdsGlobalClusterResourceModel",
    AwsRdsGlobalClusterResourceProfile = "AwsRdsGlobalClusterResourceProfile",
    AwsRdsGlobalClusterResourceTypeMetadata = "AwsRdsGlobalClusterResourceTypeMetadata",
    AwsRdsNeptuneCluster = "AwsRdsNeptuneCluster",
    AwsRdsNeptuneClusterBackupRetentionTimeFrameCodeRisk = "AwsRdsNeptuneClusterBackupRetentionTimeFrameCodeRisk",
    AwsRdsNeptuneClusterBackupRetentionTimeFrameCodeRiskModel = "AwsRdsNeptuneClusterBackupRetentionTimeFrameCodeRiskModel",
    AwsRdsNeptuneClusterBackupRetentionTimeFrameRisk = "AwsRdsNeptuneClusterBackupRetentionTimeFrameRisk",
    AwsRdsNeptuneClusterBackupRetentionTimeFrameRiskModel = "AwsRdsNeptuneClusterBackupRetentionTimeFrameRiskModel",
    AwsRdsNeptuneClusterBackupRetentionTimeFrameRiskPolicyConfiguration = "AwsRdsNeptuneClusterBackupRetentionTimeFrameRiskPolicyConfiguration",
    AwsRdsNeptuneClusterBackupRetentionTimeFrameRiskPolicyUpdateAuditEventBackupRetentionTimeFrameChange = "AwsRdsNeptuneClusterBackupRetentionTimeFrameRiskPolicyUpdateAuditEventBackupRetentionTimeFrameChange",
    AwsRdsNeptuneClusterCloudWatchLoggingDisabledRisk = "AwsRdsNeptuneClusterCloudWatchLoggingDisabledRisk",
    AwsRdsNeptuneClusterCloudWatchLoggingDisabledRiskModel = "AwsRdsNeptuneClusterCloudWatchLoggingDisabledRiskModel",
    AwsRdsNeptuneClusterCloudWatchLoggingDisabledRiskPolicyConfiguration = "AwsRdsNeptuneClusterCloudWatchLoggingDisabledRiskPolicyConfiguration",
    AwsRdsNeptuneClusterDeleteProtectionDisabledCodeRisk = "AwsRdsNeptuneClusterDeleteProtectionDisabledCodeRisk",
    AwsRdsNeptuneClusterDeleteProtectionDisabledCodeRiskModel = "AwsRdsNeptuneClusterDeleteProtectionDisabledCodeRiskModel",
    AwsRdsNeptuneClusterDeleteProtectionDisabledRisk = "AwsRdsNeptuneClusterDeleteProtectionDisabledRisk",
    AwsRdsNeptuneClusterDeleteProtectionDisabledRiskModel = "AwsRdsNeptuneClusterDeleteProtectionDisabledRiskModel",
    AwsRdsNeptuneClusterDeleteProtectionDisabledRiskPolicyConfiguration = "AwsRdsNeptuneClusterDeleteProtectionDisabledRiskPolicyConfiguration",
    AwsRdsNeptuneClusterModel = "AwsRdsNeptuneClusterModel",
    AwsRdsNeptuneClusterProfile = "AwsRdsNeptuneClusterProfile",
    AwsRdsNeptuneClusterSnapshot = "AwsRdsNeptuneClusterSnapshot",
    AwsRdsNeptuneClusterSnapshotEncryptionDisabledRisk = "AwsRdsNeptuneClusterSnapshotEncryptionDisabledRisk",
    AwsRdsNeptuneClusterSnapshotEncryptionDisabledRiskModel = "AwsRdsNeptuneClusterSnapshotEncryptionDisabledRiskModel",
    AwsRdsNeptuneClusterSnapshotEncryptionDisabledRiskPolicyConfiguration = "AwsRdsNeptuneClusterSnapshotEncryptionDisabledRiskPolicyConfiguration",
    AwsRdsNeptuneClusterSnapshotModel = "AwsRdsNeptuneClusterSnapshotModel",
    AwsRdsNeptuneClusterSnapshotProfile = "AwsRdsNeptuneClusterSnapshotProfile",
    AwsRdsNeptuneClusterSnapshotPublicAccessExistsRisk = "AwsRdsNeptuneClusterSnapshotPublicAccessExistsRisk",
    AwsRdsNeptuneClusterSnapshotPublicAccessExistsRiskModel = "AwsRdsNeptuneClusterSnapshotPublicAccessExistsRiskModel",
    AwsRdsNeptuneClusterSnapshotPublicAccessExistsRiskPolicyConfiguration = "AwsRdsNeptuneClusterSnapshotPublicAccessExistsRiskPolicyConfiguration",
    AwsRdsNeptuneDatabaseInstance = "AwsRdsNeptuneDatabaseInstance",
    AwsRdsNeptuneDatabaseInstanceMinorVersionUpgradeDisabledRisk = "AwsRdsNeptuneDatabaseInstanceMinorVersionUpgradeDisabledRisk",
    AwsRdsNeptuneDatabaseInstanceMinorVersionUpgradeDisabledRiskModel = "AwsRdsNeptuneDatabaseInstanceMinorVersionUpgradeDisabledRiskModel",
    AwsRdsNeptuneDatabaseInstanceMinorVersionUpgradeDisabledRiskPolicyConfiguration = "AwsRdsNeptuneDatabaseInstanceMinorVersionUpgradeDisabledRiskPolicyConfiguration",
    AwsRdsNeptuneDatabaseInstanceModel = "AwsRdsNeptuneDatabaseInstanceModel",
    AwsRdsNeptuneDatabaseInstanceProfile = "AwsRdsNeptuneDatabaseInstanceProfile",
    AwsRdsNeptuneGlobalCluster = "AwsRdsNeptuneGlobalCluster",
    AwsRdsNeptuneGlobalClusterModel = "AwsRdsNeptuneGlobalClusterModel",
    AwsRdsNeptuneGlobalClusterProfile = "AwsRdsNeptuneGlobalClusterProfile",
    AwsRdsResource = "AwsRdsResource",
    AwsRdsSnapshot = "AwsRdsSnapshot",
    AwsRdsSnapshotEncryptionDisabledRisk = "AwsRdsSnapshotEncryptionDisabledRisk",
    AwsRdsSnapshotEncryptionDisabledRiskModel = "AwsRdsSnapshotEncryptionDisabledRiskModel",
    AwsRdsSnapshotEncryptionDisabledRiskPolicyConfiguration = "AwsRdsSnapshotEncryptionDisabledRiskPolicyConfiguration",
    AwsRdsSnapshotModel = "AwsRdsSnapshotModel",
    AwsRdsSnapshotPublicAccessExistsCodeRisk = "AwsRdsSnapshotPublicAccessExistsCodeRisk",
    AwsRdsSnapshotPublicAccessExistsCodeRiskModel = "AwsRdsSnapshotPublicAccessExistsCodeRiskModel",
    AwsRdsSnapshotPublicAccessExistsRisk = "AwsRdsSnapshotPublicAccessExistsRisk",
    AwsRdsSnapshotPublicAccessExistsRiskModel = "AwsRdsSnapshotPublicAccessExistsRiskModel",
    AwsRdsSnapshotPublicAccessExistsRiskPolicyConfiguration = "AwsRdsSnapshotPublicAccessExistsRiskPolicyConfiguration",
    AwsRdsSnapshotResource = "AwsRdsSnapshotResource",
    AwsRdsSnapshotResourceModel = "AwsRdsSnapshotResourceModel",
    AwsReconnaissanceRisk = "AwsReconnaissanceRisk",
    AwsReconnaissanceRiskModel = "AwsReconnaissanceRiskModel",
    AwsReconnaissanceRiskPolicyConfiguration = "AwsReconnaissanceRiskPolicyConfiguration",
    AwsRedshiftCluster = "AwsRedshiftCluster",
    AwsRedshiftClusterBackupRetentionTimeFrameCodeRisk = "AwsRedshiftClusterBackupRetentionTimeFrameCodeRisk",
    AwsRedshiftClusterBackupRetentionTimeFrameCodeRiskModel = "AwsRedshiftClusterBackupRetentionTimeFrameCodeRiskModel",
    AwsRedshiftClusterBackupRetentionTimeFrameRisk = "AwsRedshiftClusterBackupRetentionTimeFrameRisk",
    AwsRedshiftClusterBackupRetentionTimeFrameRiskModel = "AwsRedshiftClusterBackupRetentionTimeFrameRiskModel",
    AwsRedshiftClusterBackupRetentionTimeFrameRiskPolicyConfiguration = "AwsRedshiftClusterBackupRetentionTimeFrameRiskPolicyConfiguration",
    AwsRedshiftClusterBackupRetentionTimeFrameRiskPolicyUpdateAuditEventBackupRetentionTimeFrameChange = "AwsRedshiftClusterBackupRetentionTimeFrameRiskPolicyUpdateAuditEventBackupRetentionTimeFrameChange",
    AwsRedshiftClusterEncryptionDisabledCodeRisk = "AwsRedshiftClusterEncryptionDisabledCodeRisk",
    AwsRedshiftClusterEncryptionDisabledCodeRiskModel = "AwsRedshiftClusterEncryptionDisabledCodeRiskModel",
    AwsRedshiftClusterEncryptionDisabledRisk = "AwsRedshiftClusterEncryptionDisabledRisk",
    AwsRedshiftClusterEncryptionDisabledRiskModel = "AwsRedshiftClusterEncryptionDisabledRiskModel",
    AwsRedshiftClusterEncryptionDisabledRiskPolicyConfiguration = "AwsRedshiftClusterEncryptionDisabledRiskPolicyConfiguration",
    AwsRedshiftClusterModel = "AwsRedshiftClusterModel",
    AwsRedshiftClusterProfile = "AwsRedshiftClusterProfile",
    AwsRedshiftClusterSecurityGroup = "AwsRedshiftClusterSecurityGroup",
    AwsRedshiftClusterSecurityGroupModel = "AwsRedshiftClusterSecurityGroupModel",
    AwsRedshiftClusterStorageEncryptionType = "AwsRedshiftClusterStorageEncryptionType",
    AwsRedshiftNamespace = "AwsRedshiftNamespace",
    AwsRedshiftNamespaceModel = "AwsRedshiftNamespaceModel",
    AwsRedshiftNamespaceProfile = "AwsRedshiftNamespaceProfile",
    AwsRedshiftResource = "AwsRedshiftResource",
    AwsRedshiftWorkgroup = "AwsRedshiftWorkgroup",
    AwsRedshiftWorkgroupModel = "AwsRedshiftWorkgroupModel",
    AwsRedshiftWorkgroupProfile = "AwsRedshiftWorkgroupProfile",
    AwsRegionType = "AwsRegionType",
    AwsResource = "AwsResource",
    AwsResourceAccessLevel = "AwsResourceAccessLevel",
    AwsResourceChange = "AwsResourceChange",
    AwsResourceCodeResourceNotExistsRisk = "AwsResourceCodeResourceNotExistsRisk",
    AwsResourceCodeResourceNotExistsRiskModel = "AwsResourceCodeResourceNotExistsRiskModel",
    AwsResourceCodeResourceNotExistsRiskPolicyConfiguration = "AwsResourceCodeResourceNotExistsRiskPolicyConfiguration",
    AwsResourceGroup = "AwsResourceGroup",
    AwsResourceGroupModel = "AwsResourceGroupModel",
    AwsResourceModel = "AwsResourceModel",
    AwsResourceModelFilters = "AwsResourceModelFilters",
    AwsResourcePermitterType = "AwsResourcePermitterType",
    AwsResourceProductCode = "AwsResourceProductCode",
    AwsResourceProductCodeType = "AwsResourceProductCodeType",
    AwsResourceProfile = "AwsResourceProfile",
    AwsResourceProfileCreation = "AwsResourceProfileCreation",
    AwsResourceReference = "AwsResourceReference",
    AwsResourceSnapshotType = "AwsResourceSnapshotType",
    AwsResourceTagNotExistsRisk = "AwsResourceTagNotExistsRisk",
    AwsResourceTagNotExistsRiskModel = "AwsResourceTagNotExistsRiskModel",
    AwsResourceTagNotExistsRiskPolicyConfiguration = "AwsResourceTagNotExistsRiskPolicyConfiguration",
    AwsResourceTagSecretExistsRisk = "AwsResourceTagSecretExistsRisk",
    AwsResourceTagSecretExistsRiskModel = "AwsResourceTagSecretExistsRiskModel",
    AwsResourceTagSecretExistsRiskPolicyConfiguration = "AwsResourceTagSecretExistsRiskPolicyConfiguration",
    AwsResourceTypeMetadata = "AwsResourceTypeMetadata",
    AwsResourceUsageRisk = "AwsResourceUsageRisk",
    AwsResourceUsageRiskPolicyConfiguration = "AwsResourceUsageRiskPolicyConfiguration",
    AwsRoleTemplateMismatchRisk = "AwsRoleTemplateMismatchRisk",
    AwsRoleTemplateMismatchRiskModel = "AwsRoleTemplateMismatchRiskModel",
    AwsRoleTemplateMismatchRiskPolicyConfiguration = "AwsRoleTemplateMismatchRiskPolicyConfiguration",
    AwsRoute53HostedZone = "AwsRoute53HostedZone",
    AwsRoute53HostedZoneModel = "AwsRoute53HostedZoneModel",
    AwsRoute53HostedZoneProfile = "AwsRoute53HostedZoneProfile",
    AwsRoute53HostedZoneRecord = "AwsRoute53HostedZoneRecord",
    AwsRoute53HostedZoneRecordRoutingPolicy = "AwsRoute53HostedZoneRecordRoutingPolicy",
    AwsRoute53HostedZoneRecordType = "AwsRoute53HostedZoneRecordType",
    AwsRoute53HostedZoneSigningDisabledCodeRisk = "AwsRoute53HostedZoneSigningDisabledCodeRisk",
    AwsRoute53HostedZoneSigningDisabledCodeRiskModel = "AwsRoute53HostedZoneSigningDisabledCodeRiskModel",
    AwsRoute53HostedZoneSigningDisabledRisk = "AwsRoute53HostedZoneSigningDisabledRisk",
    AwsRoute53HostedZoneSigningDisabledRiskModel = "AwsRoute53HostedZoneSigningDisabledRiskModel",
    AwsRoute53HostedZoneSigningDisabledRiskPolicyConfiguration = "AwsRoute53HostedZoneSigningDisabledRiskPolicyConfiguration",
    AwsRoute53HostedZoneSigningStatus = "AwsRoute53HostedZoneSigningStatus",
    AwsRoute53Resource = "AwsRoute53Resource",
    AwsS3AccessControlEntry = "AwsS3AccessControlEntry",
    AwsS3AccessControlEntryGrantee = "AwsS3AccessControlEntryGrantee",
    AwsS3AccessControlEntryGranteeType = "AwsS3AccessControlEntryGranteeType",
    AwsS3AccessControlEntryPermission = "AwsS3AccessControlEntryPermission",
    AwsS3AccessControlList = "AwsS3AccessControlList",
    AwsS3Bucket = "AwsS3Bucket",
    AwsS3BucketAccessPoint = "AwsS3BucketAccessPoint",
    AwsS3BucketAccessPointModel = "AwsS3BucketAccessPointModel",
    AwsS3BucketBlockPublicAccessDisabledCodeRisk = "AwsS3BucketBlockPublicAccessDisabledCodeRisk",
    AwsS3BucketBlockPublicAccessDisabledCodeRiskModel = "AwsS3BucketBlockPublicAccessDisabledCodeRiskModel",
    AwsS3BucketBlockPublicAccessDisabledRisk = "AwsS3BucketBlockPublicAccessDisabledRisk",
    AwsS3BucketBlockPublicAccessDisabledRiskModel = "AwsS3BucketBlockPublicAccessDisabledRiskModel",
    AwsS3BucketBlockPublicAccessDisabledRiskPolicyConfiguration = "AwsS3BucketBlockPublicAccessDisabledRiskPolicyConfiguration",
    AwsS3BucketEditAccessControlSnapshotChange = "AwsS3BucketEditAccessControlSnapshotChange",
    AwsS3BucketEditPublicAccessPolicySnapshotChange = "AwsS3BucketEditPublicAccessPolicySnapshotChange",
    AwsS3BucketEncryptionDisabledCodeRisk = "AwsS3BucketEncryptionDisabledCodeRisk",
    AwsS3BucketEncryptionDisabledCodeRiskModel = "AwsS3BucketEncryptionDisabledCodeRiskModel",
    AwsS3BucketEncryptionDisabledRisk = "AwsS3BucketEncryptionDisabledRisk",
    AwsS3BucketEncryptionDisabledRiskModel = "AwsS3BucketEncryptionDisabledRiskModel",
    AwsS3BucketEncryptionDisabledRiskPolicyConfiguration = "AwsS3BucketEncryptionDisabledRiskPolicyConfiguration",
    AwsS3BucketHttpsOnlyDisabledCodeRisk = "AwsS3BucketHttpsOnlyDisabledCodeRisk",
    AwsS3BucketHttpsOnlyDisabledCodeRiskModel = "AwsS3BucketHttpsOnlyDisabledCodeRiskModel",
    AwsS3BucketHttpsOnlyDisabledRisk = "AwsS3BucketHttpsOnlyDisabledRisk",
    AwsS3BucketHttpsOnlyDisabledRiskModel = "AwsS3BucketHttpsOnlyDisabledRiskModel",
    AwsS3BucketHttpsOnlyDisabledRiskPolicyConfiguration = "AwsS3BucketHttpsOnlyDisabledRiskPolicyConfiguration",
    AwsS3BucketManagementRisk = "AwsS3BucketManagementRisk",
    AwsS3BucketManagementRiskModel = "AwsS3BucketManagementRiskModel",
    AwsS3BucketManagementRiskModelDescriptionType = "AwsS3BucketManagementRiskModelDescriptionType",
    AwsS3BucketManagementRiskPolicyConfiguration = "AwsS3BucketManagementRiskPolicyConfiguration",
    AwsS3BucketModel = "AwsS3BucketModel",
    AwsS3BucketModelFilters = "AwsS3BucketModelFilters",
    AwsS3BucketObject = "AwsS3BucketObject",
    AwsS3BucketObjectMfaDeleteDisabledCodeRisk = "AwsS3BucketObjectMfaDeleteDisabledCodeRisk",
    AwsS3BucketObjectMfaDeleteDisabledCodeRiskModel = "AwsS3BucketObjectMfaDeleteDisabledCodeRiskModel",
    AwsS3BucketObjectMfaDeleteDisabledRisk = "AwsS3BucketObjectMfaDeleteDisabledRisk",
    AwsS3BucketObjectMfaDeleteDisabledRiskModel = "AwsS3BucketObjectMfaDeleteDisabledRiskModel",
    AwsS3BucketObjectMfaDeleteDisabledRiskPolicyConfiguration = "AwsS3BucketObjectMfaDeleteDisabledRiskPolicyConfiguration",
    AwsS3BucketObjectModel = "AwsS3BucketObjectModel",
    AwsS3BucketObjectOwnership = "AwsS3BucketObjectOwnership",
    AwsS3BucketProfile = "AwsS3BucketProfile",
    AwsS3BucketPublicAccessExistsCodeRisk = "AwsS3BucketPublicAccessExistsCodeRisk",
    AwsS3BucketPublicAccessExistsCodeRiskModel = "AwsS3BucketPublicAccessExistsCodeRiskModel",
    AwsS3BucketPublicAccessExistsRisk = "AwsS3BucketPublicAccessExistsRisk",
    AwsS3BucketPublicAccessExistsRiskModel = "AwsS3BucketPublicAccessExistsRiskModel",
    AwsS3BucketPublicAccessExistsRiskPolicyConfiguration = "AwsS3BucketPublicAccessExistsRiskPolicyConfiguration",
    AwsS3BucketServerSideEncryptionType = "AwsS3BucketServerSideEncryptionType",
    AwsS3BucketSnapshot = "AwsS3BucketSnapshot",
    AwsS3PublicAccessPolicy = "AwsS3PublicAccessPolicy",
    AwsS3Resource = "AwsS3Resource",
    AwsSageMakerNotebookInstance = "AwsSageMakerNotebookInstance",
    AwsSageMakerNotebookInstanceDefaultEncryptionRisk = "AwsSageMakerNotebookInstanceDefaultEncryptionRisk",
    AwsSageMakerNotebookInstanceDefaultEncryptionRiskModel = "AwsSageMakerNotebookInstanceDefaultEncryptionRiskModel",
    AwsSageMakerNotebookInstanceDefaultEncryptionRiskPolicyConfiguration = "AwsSageMakerNotebookInstanceDefaultEncryptionRiskPolicyConfiguration",
    AwsSageMakerNotebookInstanceMetadataServiceVersion = "AwsSageMakerNotebookInstanceMetadataServiceVersion",
    AwsSageMakerNotebookInstanceModel = "AwsSageMakerNotebookInstanceModel",
    AwsSageMakerNotebookInstanceProfile = "AwsSageMakerNotebookInstanceProfile",
    AwsSageMakerNotebookInstanceRootAccessEnabledRisk = "AwsSageMakerNotebookInstanceRootAccessEnabledRisk",
    AwsSageMakerNotebookInstanceRootAccessEnabledRiskModel = "AwsSageMakerNotebookInstanceRootAccessEnabledRiskModel",
    AwsSageMakerNotebookInstanceRootAccessEnabledRiskPolicyConfiguration = "AwsSageMakerNotebookInstanceRootAccessEnabledRiskPolicyConfiguration",
    AwsSageMakerNotebookInstanceStatus = "AwsSageMakerNotebookInstanceStatus",
    AwsSageMakerNotebookInstanceUnrestrictedOutboundAccessRisk = "AwsSageMakerNotebookInstanceUnrestrictedOutboundAccessRisk",
    AwsSageMakerNotebookInstanceUnrestrictedOutboundAccessRiskModel = "AwsSageMakerNotebookInstanceUnrestrictedOutboundAccessRiskModel",
    AwsSageMakerNotebookInstanceUnrestrictedOutboundAccessRiskPolicyConfiguration = "AwsSageMakerNotebookInstanceUnrestrictedOutboundAccessRiskPolicyConfiguration",
    AwsScopeConfigurationAnalysis = "AwsScopeConfigurationAnalysis",
    AwsScopeConfigurationAnalysisEntities = "AwsScopeConfigurationAnalysisEntities",
    AwsScopeConfigurationDataAnalysis = "AwsScopeConfigurationDataAnalysis",
    AwsScopeConfigurationOutpost = "AwsScopeConfigurationOutpost",
    AwsScopeConfigurationOutpostRegionData = "AwsScopeConfigurationOutpostRegionData",
    AwsScopeConfigurationSections = "AwsScopeConfigurationSections",
    AwsScopeConfigurationWorkloadAnalysis = "AwsScopeConfigurationWorkloadAnalysis",
    AwsSecretsManagerSecret = "AwsSecretsManagerSecret",
    AwsSecretsManagerSecretManagementRisk = "AwsSecretsManagerSecretManagementRisk",
    AwsSecretsManagerSecretManagementRiskModel = "AwsSecretsManagerSecretManagementRiskModel",
    AwsSecretsManagerSecretManagementRiskPolicyConfiguration = "AwsSecretsManagerSecretManagementRiskPolicyConfiguration",
    AwsSecretsManagerSecretModel = "AwsSecretsManagerSecretModel",
    AwsSecretsManagerSecretModelFilters = "AwsSecretsManagerSecretModelFilters",
    AwsSecretsManagerSecretProfile = "AwsSecretsManagerSecretProfile",
    AwsSecretsManagerSecretPublicAccessExistsCodeRisk = "AwsSecretsManagerSecretPublicAccessExistsCodeRisk",
    AwsSecretsManagerSecretPublicAccessExistsCodeRiskModel = "AwsSecretsManagerSecretPublicAccessExistsCodeRiskModel",
    AwsSecretsManagerSecretPublicAccessExistsRisk = "AwsSecretsManagerSecretPublicAccessExistsRisk",
    AwsSecretsManagerSecretPublicAccessExistsRiskModel = "AwsSecretsManagerSecretPublicAccessExistsRiskModel",
    AwsSecretsManagerSecretPublicAccessExistsRiskPolicyConfiguration = "AwsSecretsManagerSecretPublicAccessExistsRiskPolicyConfiguration",
    AwsSecretsManagerSecretRotationDisabledCodeRisk = "AwsSecretsManagerSecretRotationDisabledCodeRisk",
    AwsSecretsManagerSecretRotationDisabledCodeRiskModel = "AwsSecretsManagerSecretRotationDisabledCodeRiskModel",
    AwsSecretsManagerSecretRotationDisabledRisk = "AwsSecretsManagerSecretRotationDisabledRisk",
    AwsSecretsManagerSecretRotationDisabledRiskModel = "AwsSecretsManagerSecretRotationDisabledRiskModel",
    AwsSecretsManagerSecretRotationDisabledRiskPolicyConfiguration = "AwsSecretsManagerSecretRotationDisabledRiskPolicyConfiguration",
    AwsSecretsManagerSecretRotationDisabledRiskPolicyGroupFilters = "AwsSecretsManagerSecretRotationDisabledRiskPolicyGroupFilters",
    AwsSecretsManagerSecretSnapshot = "AwsSecretsManagerSecretSnapshot",
    AwsSecurityHubHub = "AwsSecurityHubHub",
    AwsSecurityHubHubModel = "AwsSecurityHubHubModel",
    AwsSecurityHubRegionHubDisabledRisk = "AwsSecurityHubRegionHubDisabledRisk",
    AwsSecurityHubRegionHubDisabledRiskModel = "AwsSecurityHubRegionHubDisabledRiskModel",
    AwsSecurityHubRegionHubDisabledRiskPolicyConfiguration = "AwsSecurityHubRegionHubDisabledRiskPolicyConfiguration",
    AwsService = "AwsService",
    AwsServiceDiscoveryService = "AwsServiceDiscoveryService",
    AwsServiceModel = "AwsServiceModel",
    AwsSesIdentity = "AwsSesIdentity",
    AwsSesIdentityModel = "AwsSesIdentityModel",
    AwsSesResource = "AwsSesResource",
    AwsSetInstanceMetadataServiceTokenHopMaxCountChange = "AwsSetInstanceMetadataServiceTokenHopMaxCountChange",
    AwsSetInstanceMetadataServiceV2VersionChange = "AwsSetInstanceMetadataServiceV2VersionChange",
    AwsSeverePermissionPrincipalRisk = "AwsSeverePermissionPrincipalRisk",
    AwsSeverePermissionPrincipalRiskModel = "AwsSeverePermissionPrincipalRiskModel",
    AwsSeverePermissionPrincipalRiskModelInfo = "AwsSeverePermissionPrincipalRiskModelInfo",
    AwsSeverePermissionPrincipalRiskPolicyConfiguration = "AwsSeverePermissionPrincipalRiskPolicyConfiguration",
    AwsSnsDeliveryProtocol = "AwsSnsDeliveryProtocol",
    AwsSnsTopic = "AwsSnsTopic",
    AwsSnsTopicEncryptionDisabledCodeRisk = "AwsSnsTopicEncryptionDisabledCodeRisk",
    AwsSnsTopicEncryptionDisabledCodeRiskModel = "AwsSnsTopicEncryptionDisabledCodeRiskModel",
    AwsSnsTopicEncryptionDisabledRisk = "AwsSnsTopicEncryptionDisabledRisk",
    AwsSnsTopicEncryptionDisabledRiskModel = "AwsSnsTopicEncryptionDisabledRiskModel",
    AwsSnsTopicEncryptionDisabledRiskPolicyConfiguration = "AwsSnsTopicEncryptionDisabledRiskPolicyConfiguration",
    AwsSnsTopicHttpsOnlyDisabledRisk = "AwsSnsTopicHttpsOnlyDisabledRisk",
    AwsSnsTopicHttpsOnlyDisabledRiskModel = "AwsSnsTopicHttpsOnlyDisabledRiskModel",
    AwsSnsTopicHttpsOnlyDisabledRiskPolicyConfiguration = "AwsSnsTopicHttpsOnlyDisabledRiskPolicyConfiguration",
    AwsSnsTopicModel = "AwsSnsTopicModel",
    AwsSnsTopicProfile = "AwsSnsTopicProfile",
    AwsSnsTopicPublicAccessExistsCodeRisk = "AwsSnsTopicPublicAccessExistsCodeRisk",
    AwsSnsTopicPublicAccessExistsCodeRiskModel = "AwsSnsTopicPublicAccessExistsCodeRiskModel",
    AwsSnsTopicPublicAccessExistsRisk = "AwsSnsTopicPublicAccessExistsRisk",
    AwsSnsTopicPublicAccessExistsRiskModel = "AwsSnsTopicPublicAccessExistsRiskModel",
    AwsSnsTopicPublicAccessExistsRiskPolicyConfiguration = "AwsSnsTopicPublicAccessExistsRiskPolicyConfiguration",
    AwsSnsTopicSubscription = "AwsSnsTopicSubscription",
    AwsSnsTopicType = "AwsSnsTopicType",
    AwsSqsQueue = "AwsSqsQueue",
    AwsSqsQueueEncryptionDisabledCodeRisk = "AwsSqsQueueEncryptionDisabledCodeRisk",
    AwsSqsQueueEncryptionDisabledCodeRiskModel = "AwsSqsQueueEncryptionDisabledCodeRiskModel",
    AwsSqsQueueEncryptionDisabledRisk = "AwsSqsQueueEncryptionDisabledRisk",
    AwsSqsQueueEncryptionDisabledRiskModel = "AwsSqsQueueEncryptionDisabledRiskModel",
    AwsSqsQueueEncryptionDisabledRiskPolicyConfiguration = "AwsSqsQueueEncryptionDisabledRiskPolicyConfiguration",
    AwsSqsQueueModel = "AwsSqsQueueModel",
    AwsSqsQueueProfile = "AwsSqsQueueProfile",
    AwsSqsQueuePublicAccessExistsCodeRisk = "AwsSqsQueuePublicAccessExistsCodeRisk",
    AwsSqsQueuePublicAccessExistsCodeRiskModel = "AwsSqsQueuePublicAccessExistsCodeRiskModel",
    AwsSqsQueuePublicAccessExistsRisk = "AwsSqsQueuePublicAccessExistsRisk",
    AwsSqsQueuePublicAccessExistsRiskModel = "AwsSqsQueuePublicAccessExistsRiskModel",
    AwsSqsQueuePublicAccessExistsRiskPolicyConfiguration = "AwsSqsQueuePublicAccessExistsRiskPolicyConfiguration",
    AwsSqsQueueServerSideEncryptionType = "AwsSqsQueueServerSideEncryptionType",
    AwsSqsQueueType = "AwsSqsQueueType",
    AwsSsmParameter = "AwsSsmParameter",
    AwsSsmParameterModel = "AwsSsmParameterModel",
    AwsSsmParameterModelFilters = "AwsSsmParameterModelFilters",
    AwsSsmParameterProfile = "AwsSsmParameterProfile",
    AwsSsmParameterSecretExistsCodeRisk = "AwsSsmParameterSecretExistsCodeRisk",
    AwsSsmParameterSecretExistsCodeRiskModel = "AwsSsmParameterSecretExistsCodeRiskModel",
    AwsSsmParameterSecretExistsRisk = "AwsSsmParameterSecretExistsRisk",
    AwsSsmParameterSecretExistsRiskModel = "AwsSsmParameterSecretExistsRiskModel",
    AwsSsmParameterSecretExistsRiskPolicyConfiguration = "AwsSsmParameterSecretExistsRiskPolicyConfiguration",
    AwsSsmParameterType = "AwsSsmParameterType",
    AwsSsmResource = "AwsSsmResource",
    AwsSsmServiceSetting = "AwsSsmServiceSetting",
    AwsSsoGroup = "AwsSsoGroup",
    AwsSsoGroupModel = "AwsSsoGroupModel",
    AwsSsoInstance = "AwsSsoInstance",
    AwsSsoInstanceModel = "AwsSsoInstanceModel",
    AwsSsoPermissionSet = "AwsSsoPermissionSet",
    AwsSsoPermissionSetAssignmentEligibilityConfiguration = "AwsSsoPermissionSetAssignmentEligibilityConfiguration",
    AwsSsoPermissionSetAssignmentEligibilityConfigurationPermissions = "AwsSsoPermissionSetAssignmentEligibilityConfigurationPermissions",
    AwsSsoPermissionSetAssignmentEligibilityConfigurationPermissionSetPermissions = "AwsSsoPermissionSetAssignmentEligibilityConfigurationPermissionSetPermissions",
    AwsSsoPermissionSetAssignmentEligibilityConfigurationPolicyPermissions = "AwsSsoPermissionSetAssignmentEligibilityConfigurationPolicyPermissions",
    AwsSsoPermissionSetAssignmentEligibilityModel = "AwsSsoPermissionSetAssignmentEligibilityModel",
    AwsSsoPermissionSetAssignmentEligibilityModelPermissions = "AwsSsoPermissionSetAssignmentEligibilityModelPermissions",
    AwsSsoPermissionSetAssignmentEligibilityModelPermissionSetPermissions = "AwsSsoPermissionSetAssignmentEligibilityModelPermissionSetPermissions",
    AwsSsoPermissionSetAssignmentEligibilityModelPolicyPermissions = "AwsSsoPermissionSetAssignmentEligibilityModelPolicyPermissions",
    AwsSsoPermissionSetAssignmentEligibilityState = "AwsSsoPermissionSetAssignmentEligibilityState",
    AwsSsoPermissionSetAssignmentEligibilityStatePermissionSet = "AwsSsoPermissionSetAssignmentEligibilityStatePermissionSet",
    AwsSsoPermissionSetAssignmentRequest = "AwsSsoPermissionSetAssignmentRequest",
    AwsSsoPermissionSetAssignmentRequestActivationFailureData = "AwsSsoPermissionSetAssignmentRequestActivationFailureData",
    AwsSsoPermissionSetAssignmentRequestPermissions = "AwsSsoPermissionSetAssignmentRequestPermissions",
    AwsSsoPermissionSetAssignmentRisk = "AwsSsoPermissionSetAssignmentRisk",
    AwsSsoPermissionSetAssignmentRiskModel = "AwsSsoPermissionSetAssignmentRiskModel",
    AwsSsoPermissionSetAssignmentRiskPolicyConfiguration = "AwsSsoPermissionSetAssignmentRiskPolicyConfiguration",
    AwsSsoPermissionSetManagementRisk = "AwsSsoPermissionSetManagementRisk",
    AwsSsoPermissionSetManagementRiskModel = "AwsSsoPermissionSetManagementRiskModel",
    AwsSsoPermissionSetManagementRiskPolicyConfiguration = "AwsSsoPermissionSetManagementRiskPolicyConfiguration",
    AwsSsoPermissionSetModel = "AwsSsoPermissionSetModel",
    AwsSsoPermissionSetModelAccess = "AwsSsoPermissionSetModelAccess",
    AwsSsoPermissionSetProfile = "AwsSsoPermissionSetProfile",
    AwsSsoPermissionSetProvisionedMatchCondition = "AwsSsoPermissionSetProvisionedMatchCondition",
    AwsSsoPermissionSetUnusedRisk = "AwsSsoPermissionSetUnusedRisk",
    AwsSsoPermissionSetUnusedRiskModel = "AwsSsoPermissionSetUnusedRiskModel",
    AwsSsoPermissionSetUnusedRiskModelInfo = "AwsSsoPermissionSetUnusedRiskModelInfo",
    AwsSsoPermissionSetUnusedRiskPolicyConfiguration = "AwsSsoPermissionSetUnusedRiskPolicyConfiguration",
    AwsSsoPermissionsSetCustomerManagedPolicyReference = "AwsSsoPermissionsSetCustomerManagedPolicyReference",
    AwsSsoPermissionsSetPermissionsBoundary = "AwsSsoPermissionsSetPermissionsBoundary",
    AwsSsoPrincipal = "AwsSsoPrincipal",
    AwsSsoPrincipalCreationRisk = "AwsSsoPrincipalCreationRisk",
    AwsSsoPrincipalCreationRiskModel = "AwsSsoPrincipalCreationRiskModel",
    AwsSsoPrincipalCreationRiskPolicyConfiguration = "AwsSsoPrincipalCreationRiskPolicyConfiguration",
    AwsSsoPrincipalModel = "AwsSsoPrincipalModel",
    AwsSsoPrincipalModelFilters = "AwsSsoPrincipalModelFilters",
    AwsSsoResource = "AwsSsoResource",
    AwsSsoUser = "AwsSsoUser",
    AwsSsoUserModel = "AwsSsoUserModel",
    AwsSsoUserProfile = "AwsSsoUserProfile",
    AwsStepsFunctionStateMachine = "AwsStepsFunctionStateMachine",
    AwsStsFederatedUser = "AwsStsFederatedUser",
    AwsTagResourceGroup = "AwsTagResourceGroup",
    AwsTagResourceGroupModel = "AwsTagResourceGroupModel",
    AwsTenantAuditEvent = "AwsTenantAuditEvent",
    AwsTenantAuthenticationProviderConfigurationSection = "AwsTenantAuthenticationProviderConfigurationSection",
    AwsTenantAuthenticationProviderConfigurationSectionPartitionData = "AwsTenantAuthenticationProviderConfigurationSectionPartitionData",
    AwsTenantConfiguration = "AwsTenantConfiguration",
    AwsTenantConfigurationEvents = "AwsTenantConfigurationEvents",
    AwsTenantConfigurationFederation = "AwsTenantConfigurationFederation",
    AwsTenantConfigurationPermissionManagement = "AwsTenantConfigurationPermissionManagement",
    AwsTenantConfigurationSections = "AwsTenantConfigurationSections",
    AwsTenantConfigurationTrail = "AwsTenantConfigurationTrail",
    AwsTenantConfigurationTrailBucket = "AwsTenantConfigurationTrailBucket",
    AwsTenantCreationAuditEvent = "AwsTenantCreationAuditEvent",
    AwsTenantDeletionAuditEvent = "AwsTenantDeletionAuditEvent",
    AwsTenantEntity = "AwsTenantEntity",
    AwsTenantEntityCreationRisk = "AwsTenantEntityCreationRisk",
    AwsTenantEntityCreationRiskModel = "AwsTenantEntityCreationRiskModel",
    AwsTenantEntityCreationRiskPolicyConfiguration = "AwsTenantEntityCreationRiskPolicyConfiguration",
    AwsTenantEntityEditS3PublicAccessPolicySnapshotChange = "AwsTenantEntityEditS3PublicAccessPolicySnapshotChange",
    AwsTenantEntityIam = "AwsTenantEntityIam",
    AwsTenantEntityIamPasswordPolicy = "AwsTenantEntityIamPasswordPolicy",
    AwsTenantEntityModel = "AwsTenantEntityModel",
    AwsTenantEntityModelOrganizationsAccount = "AwsTenantEntityModelOrganizationsAccount",
    AwsTenantEntityProfile = "AwsTenantEntityProfile",
    AwsTenantEntityS3 = "AwsTenantEntityS3",
    AwsTenantEntitySnapshot = "AwsTenantEntitySnapshot",
    AwsTenantEventCategory = "AwsTenantEventCategory",
    AwsTenantManagerValidateTenantResult = "AwsTenantManagerValidateTenantResult",
    AwsTenantManagerValidateTenantTrailBucketResult = "AwsTenantManagerValidateTenantTrailBucketResult",
    AwsTenantModel = "AwsTenantModel",
    AwsTenantModelIssue = "AwsTenantModelIssue",
    AwsTenantModelStatus = "AwsTenantModelStatus",
    AwsTenantModelTrailStatus = "AwsTenantModelTrailStatus",
    AwsTenantState = "AwsTenantState",
    AwsTenantStateCodeAnalysis = "AwsTenantStateCodeAnalysis",
    AwsTenantStateDataAnalysis = "AwsTenantStateDataAnalysis",
    AwsTenantStateEventAnalysis = "AwsTenantStateEventAnalysis",
    AwsTenantStateEventAnalysisTrail = "AwsTenantStateEventAnalysisTrail",
    AwsTenantStateMonitoring = "AwsTenantStateMonitoring",
    AwsTenantStateMonitoringDataAnalysisIssue = "AwsTenantStateMonitoringDataAnalysisIssue",
    AwsTenantStateMonitoringIssue = "AwsTenantStateMonitoringIssue",
    AwsTenantStateMonitoringPermissionManagementIssue = "AwsTenantStateMonitoringPermissionManagementIssue",
    AwsTenantStateMonitoringTrailIssue = "AwsTenantStateMonitoringTrailIssue",
    AwsTenantStateMonitoringTrailIssueType = "AwsTenantStateMonitoringTrailIssueType",
    AwsTenantStateMonitoringWorkloadAnalysisIssue = "AwsTenantStateMonitoringWorkloadAnalysisIssue",
    AwsTenantStateOrganization = "AwsTenantStateOrganization",
    AwsTenantStateWorkloadAnalysis = "AwsTenantStateWorkloadAnalysis",
    AwsTenantUpdateAuditEvent = "AwsTenantUpdateAuditEvent",
    AwsTimestreamDatabase = "AwsTimestreamDatabase",
    AwsTimestreamDatabaseModel = "AwsTimestreamDatabaseModel",
    AwsTimestreamDatabaseProfile = "AwsTimestreamDatabaseProfile",
    AwsTimestreamDatabaseTable = "AwsTimestreamDatabaseTable",
    AwsTimestreamDatabaseTableModel = "AwsTimestreamDatabaseTableModel",
    AwsTimestreamDatabaseTableProfile = "AwsTimestreamDatabaseTableProfile",
    AwsUnmanagedKubernetesCluster = "AwsUnmanagedKubernetesCluster",
    AwsUnmanagedKubernetesClusterModel = "AwsUnmanagedKubernetesClusterModel",
    AwsUnmanagedKubernetesClusterModelFilters = "AwsUnmanagedKubernetesClusterModelFilters",
    AwsUnmanagedKubernetesClusterTypeMetadata = "AwsUnmanagedKubernetesClusterTypeMetadata",
    AwsUpsertBucketPolicyChange = "AwsUpsertBucketPolicyChange",
    AwsUpsertPermissionSetInlinePolicyChange = "AwsUpsertPermissionSetInlinePolicyChange",
    AwsUpsertPolicyChange = "AwsUpsertPolicyChange",
    AwsUpsertPrincipalManagedPolicyChange = "AwsUpsertPrincipalManagedPolicyChange",
    AwsUpsertRepositoryPolicyChange = "AwsUpsertRepositoryPolicyChange",
    AwsUpsertResourcePolicyChange = "AwsUpsertResourcePolicyChange",
    AwsUpsertSecretPolicyChange = "AwsUpsertSecretPolicyChange",
    AwsUpsertVpcEndpointServicePolicyChange = "AwsUpsertVpcEndpointServicePolicyChange",
    AwsWafGlobalWebAcl = "AwsWafGlobalWebAcl",
    AwsWafGlobalWebAclModel = "AwsWafGlobalWebAclModel",
    AwsWafGlobalWebAclProfile = "AwsWafGlobalWebAclProfile",
    AwsWafRegionalWebAcl = "AwsWafRegionalWebAcl",
    AwsWafRegionalWebAclModel = "AwsWafRegionalWebAclModel",
    AwsWafRegionalWebAclProfile = "AwsWafRegionalWebAclProfile",
    AwsWafWebAcl = "AwsWafWebAcl",
    AwsWafWebAclDefaultAction = "AwsWafWebAclDefaultAction",
    AwsWafWebAclModel = "AwsWafWebAclModel",
    AwsWafWebAclRule = "AwsWafWebAclRule",
    AwsWafWebAclRuleBase = "AwsWafWebAclRuleBase",
    AwsWafWebAclRuleGroup = "AwsWafWebAclRuleGroup",
    AzureAadDomainServiceGenericResource = "AzureAadDomainServiceGenericResource",
    AzureAadiamAzureAdMetricGenericResource = "AzureAadiamAzureAdMetricGenericResource",
    AzureAadiamPrivateLinkForAzureAdGenericResource = "AzureAadiamPrivateLinkForAzureAdGenericResource",
    AzureAadiamTenantGenericResource = "AzureAadiamTenantGenericResource",
    AzureAccessGraphPermissionPath = "AzureAccessGraphPermissionPath",
    AzureAccessGraphPermissionPathIdentifier = "AzureAccessGraphPermissionPathIdentifier",
    AzureAccessListPermissionActionData = "AzureAccessListPermissionActionData",
    AzureAccessPrincipalRisk = "AzureAccessPrincipalRisk",
    AzureAccessPrincipalRiskModel = "AzureAccessPrincipalRiskModel",
    AzureAccessPrincipalRiskPermitters = "AzureAccessPrincipalRiskPermitters",
    AzureActiveDirectoryB2cDirectoryGenericResource = "AzureActiveDirectoryB2cDirectoryGenericResource",
    AzureActiveDirectoryCiamDirectoryGenericResource = "AzureActiveDirectoryCiamDirectoryGenericResource",
    AzureActiveDirectoryGuestUsageGenericResource = "AzureActiveDirectoryGuestUsageGenericResource",
    AzureAgFoodPlatformFarmBeatGenericResource = "AzureAgFoodPlatformFarmBeatGenericResource",
    AzureAgriculturePlatformAgriServiceGenericResource = "AzureAgriculturePlatformAgriServiceGenericResource",
    AzureAlertsManagementActionRuleGenericResource = "AzureAlertsManagementActionRuleGenericResource",
    AzureAlertsManagementPrometheusRuleGroupGenericResource = "AzureAlertsManagementPrometheusRuleGroupGenericResource",
    AzureAlertsManagementSmartDetectorAlertRuleGenericResource = "AzureAlertsManagementSmartDetectorAlertRuleGenericResource",
    AzureAnalysisServicesServerGenericResource = "AzureAnalysisServicesServerGenericResource",
    AzureAnyBuildClusterGenericResource = "AzureAnyBuildClusterGenericResource",
    AzureApiCenterServiceGenericResource = "AzureApiCenterServiceGenericResource",
    AzureApiManagementGatewayGenericResource = "AzureApiManagementGatewayGenericResource",
    AzureApiManagementServiceResourceGenericResource = "AzureApiManagementServiceResourceGenericResource",
    AzureApiManagementServiceResourceGenericResourceModel = "AzureApiManagementServiceResourceGenericResourceModel",
    AzureAppAssessmentMigrateProjectGenericResource = "AzureAppAssessmentMigrateProjectGenericResource",
    AzureAppBuilderGenericResource = "AzureAppBuilderGenericResource",
    AzureAppConfigurationConfigurationStoreGenericResource = "AzureAppConfigurationConfigurationStoreGenericResource",
    AzureAppConfigurationConfigurationStoreGenericResourceModel = "AzureAppConfigurationConfigurationStoreGenericResourceModel",
    AzureAppConnectedEnvironmentGenericResource = "AzureAppConnectedEnvironmentGenericResource",
    AzureAppConnectedenvironmentsCertificateGenericResource = "AzureAppConnectedenvironmentsCertificateGenericResource",
    AzureAppContainerAppGenericResource = "AzureAppContainerAppGenericResource",
    AzureAppJobGenericResource = "AzureAppJobGenericResource",
    AzureApplicationAppSettingsSecretExistsCodeRisk = "AzureApplicationAppSettingsSecretExistsCodeRisk",
    AzureApplicationAppSettingsSecretExistsCodeRiskModel = "AzureApplicationAppSettingsSecretExistsCodeRiskModel",
    AzureApplicationAppSettingsSecretExistsRiskPolicyConfiguration = "AzureApplicationAppSettingsSecretExistsRiskPolicyConfiguration",
    AzureApplicationAuthenticationProviderConfigurationSection = "AzureApplicationAuthenticationProviderConfigurationSection",
    AzureAppManagedEnvironmentGenericResource = "AzureAppManagedEnvironmentGenericResource",
    AzureAppManagedEnvironmentsCertificateGenericResource = "AzureAppManagedEnvironmentsCertificateGenericResource",
    AzureAppManagedenvironmentsManagedcertificateGenericResource = "AzureAppManagedenvironmentsManagedcertificateGenericResource",
    AzureAppPlatformSpringGenericResource = "AzureAppPlatformSpringGenericResource",
    AzureAppSecurityPolicyGenericResource = "AzureAppSecurityPolicyGenericResource",
    AzureAppSessionPoolGenericResource = "AzureAppSessionPoolGenericResource",
    AzureAppSpaceGenericResource = "AzureAppSpaceGenericResource",
    AzureArcDataDataControllerGenericResource = "AzureArcDataDataControllerGenericResource",
    AzureArcDataPostgresInstanceGenericResource = "AzureArcDataPostgresInstanceGenericResource",
    AzureArcDataSqlManagedInstanceGenericResource = "AzureArcDataSqlManagedInstanceGenericResource",
    AzureArcDataSqlServerEsuLicenseGenericResource = "AzureArcDataSqlServerEsuLicenseGenericResource",
    AzureArcDataSqlServerInstanceGenericResource = "AzureArcDataSqlServerInstanceGenericResource",
    AzureArcDataSqlServerInstancesAvailabilityGroupGenericResource = "AzureArcDataSqlServerInstancesAvailabilityGroupGenericResource",
    AzureArcDataSqlServerInstancesDatabaseGenericResource = "AzureArcDataSqlServerInstancesDatabaseGenericResource",
    AzureArcDataSqlServerLicenseGenericResource = "AzureArcDataSqlServerLicenseGenericResource",
    AzureAstronomerAstroOrganizationGenericResource = "AzureAstronomerAstroOrganizationGenericResource",
    AzureAttestationAttestationProviderGenericResource = "AzureAttestationAttestationProviderGenericResource",
    AzureAuthorizationDenyAssignment = "AzureAuthorizationDenyAssignment",
    AzureAuthorizationDenyAssignmentModel = "AzureAuthorizationDenyAssignmentModel",
    AzureAuthorizationDenyAssignmentProfile = "AzureAuthorizationDenyAssignmentProfile",
    AzureAuthorizationLock = "AzureAuthorizationLock",
    AzureAuthorizationLockModel = "AzureAuthorizationLockModel",
    AzureAuthorizationLockProfile = "AzureAuthorizationLockProfile",
    AzureAuthorizationLockType = "AzureAuthorizationLockType",
    AzureAuthorizationPermission = "AzureAuthorizationPermission",
    AzureAuthorizationResourceManagementPrivateLinkGenericResource = "AzureAuthorizationResourceManagementPrivateLinkGenericResource",
    AzureAuthorizationRoleAssignment = "AzureAuthorizationRoleAssignment",
    AzureAuthorizationRoleAssignmentClassicAdministrator = "AzureAuthorizationRoleAssignmentClassicAdministrator",
    AzureAuthorizationRoleAssignmentClassicAdministratorModel = "AzureAuthorizationRoleAssignmentClassicAdministratorModel",
    AzureAuthorizationRoleAssignmentClassicAdministratorProfile = "AzureAuthorizationRoleAssignmentClassicAdministratorProfile",
    AzureAuthorizationRoleAssignmentModel = "AzureAuthorizationRoleAssignmentModel",
    AzureAuthorizationRoleAssignmentProfile = "AzureAuthorizationRoleAssignmentProfile",
    AzureAuthorizationRoleAssignmentResource = "AzureAuthorizationRoleAssignmentResource",
    AzureAuthorizationRoleAssignmentResourceModel = "AzureAuthorizationRoleAssignmentResourceModel",
    AzureAuthorizationRoleAssignmentResourceModelFilters = "AzureAuthorizationRoleAssignmentResourceModelFilters",
    AzureAuthorizationRoleAssignmentResourceStatus = "AzureAuthorizationRoleAssignmentResourceStatus",
    AzureAuthorizationRoleAssignmentSchedule = "AzureAuthorizationRoleAssignmentSchedule",
    AzureAuthorizationRoleAssignmentScheduleModel = "AzureAuthorizationRoleAssignmentScheduleModel",
    AzureAuthorizationRoleAssignmentScheduleProfile = "AzureAuthorizationRoleAssignmentScheduleProfile",
    AzureAuthorizationRoleDefinition = "AzureAuthorizationRoleDefinition",
    AzureAuthorizationRoleDefinitionModel = "AzureAuthorizationRoleDefinitionModel",
    AzureAuthorizationRoleDefinitionProfile = "AzureAuthorizationRoleDefinitionProfile",
    AzureAuthorizationRoleDefinitionType = "AzureAuthorizationRoleDefinitionType",
    AzureAuthorizationRoleEligibilitySchedule = "AzureAuthorizationRoleEligibilitySchedule",
    AzureAuthorizationRoleEligibilityScheduleModel = "AzureAuthorizationRoleEligibilityScheduleModel",
    AzureAuthorizationRoleEligibilityScheduleProfile = "AzureAuthorizationRoleEligibilityScheduleProfile",
    AzureAuthorizationRoleManagementPolicyAssignment = "AzureAuthorizationRoleManagementPolicyAssignment",
    AzureAuthorizationRoleManagementPolicyAssignmentActivation = "AzureAuthorizationRoleManagementPolicyAssignmentActivation",
    AzureAuthorizationRoleManagementPolicyAssignmentActivationAuthenticationType = "AzureAuthorizationRoleManagementPolicyAssignmentActivationAuthenticationType",
    AzureAuthorizationRoleManagementPolicyAssignmentModel = "AzureAuthorizationRoleManagementPolicyAssignmentModel",
    AzureAuthorizationRoleManagementPolicyAssignmentProfile = "AzureAuthorizationRoleManagementPolicyAssignmentProfile",
    AzureAutomanageConfigurationProfileGenericResource = "AzureAutomanageConfigurationProfileGenericResource",
    AzureAutomanageConfigurationProfilesVersionGenericResource = "AzureAutomanageConfigurationProfilesVersionGenericResource",
    AzureAutomanagePatchJobConfigurationGenericResource = "AzureAutomanagePatchJobConfigurationGenericResource",
    AzureAutomanagePatchTierGenericResource = "AzureAutomanagePatchTierGenericResource",
    AzureAutomationAutomationAccountGenericResource = "AzureAutomationAutomationAccountGenericResource",
    AzureAutomationAutomationAccountsConfigurationGenericResource = "AzureAutomationAutomationAccountsConfigurationGenericResource",
    AzureAutomationAutomationAccountsRunbookGenericResource = "AzureAutomationAutomationAccountsRunbookGenericResource",
    AzureAvsPrivateCloudGenericResource = "AzureAvsPrivateCloudGenericResource",
    AzureAwsConnectorAccessAnalyzerAnalyzerGenericResource = "AzureAwsConnectorAccessAnalyzerAnalyzerGenericResource",
    AzureAwsConnectorAcmCertificateSummaryGenericResource = "AzureAwsConnectorAcmCertificateSummaryGenericResource",
    AzureAwsConnectorApiGatewayRestApiGenericResource = "AzureAwsConnectorApiGatewayRestApiGenericResource",
    AzureAwsConnectorApiGatewayStageGenericResource = "AzureAwsConnectorApiGatewayStageGenericResource",
    AzureAwsConnectorAppSyncGraphqlApiGenericResource = "AzureAwsConnectorAppSyncGraphqlApiGenericResource",
    AzureAwsConnectorAutoScalingAutoScalingGroupGenericResource = "AzureAwsConnectorAutoScalingAutoScalingGroupGenericResource",
    AzureAwsConnectorCloudFormationStackGenericResource = "AzureAwsConnectorCloudFormationStackGenericResource",
    AzureAwsConnectorCloudFormationStackSetGenericResource = "AzureAwsConnectorCloudFormationStackSetGenericResource",
    AzureAwsConnectorCloudFrontDistributionGenericResource = "AzureAwsConnectorCloudFrontDistributionGenericResource",
    AzureAwsConnectorCloudTrailTrailGenericResource = "AzureAwsConnectorCloudTrailTrailGenericResource",
    AzureAwsConnectorCloudWatchAlarmGenericResource = "AzureAwsConnectorCloudWatchAlarmGenericResource",
    AzureAwsConnectorCodeBuildProjectGenericResource = "AzureAwsConnectorCodeBuildProjectGenericResource",
    AzureAwsConnectorCodeBuildSourceCredentialsInfoGenericResource = "AzureAwsConnectorCodeBuildSourceCredentialsInfoGenericResource",
    AzureAwsConnectorConfigServiceConfigurationRecorderGenericResource = "AzureAwsConnectorConfigServiceConfigurationRecorderGenericResource",
    AzureAwsConnectorConfigServiceConfigurationRecorderStatusGenericResource = "AzureAwsConnectorConfigServiceConfigurationRecorderStatusGenericResource",
    AzureAwsConnectorConfigServiceDeliveryChannelGenericResource = "AzureAwsConnectorConfigServiceDeliveryChannelGenericResource",
    AzureAwsConnectorDatabaseMigrationServiceReplicationInstanceGenericResource = "AzureAwsConnectorDatabaseMigrationServiceReplicationInstanceGenericResource",
    AzureAwsConnectorDaxClusterGenericResource = "AzureAwsConnectorDaxClusterGenericResource",
    AzureAwsConnectorDynamoDbContinuousBackupsDescriptionGenericResource = "AzureAwsConnectorDynamoDbContinuousBackupsDescriptionGenericResource",
    AzureAwsConnectorDynamoDbTableGenericResource = "AzureAwsConnectorDynamoDbTableGenericResource",
    AzureAwsConnectorEc2AccountAttributeGenericResource = "AzureAwsConnectorEc2AccountAttributeGenericResource",
    AzureAwsConnectorEc2AddressGenericResource = "AzureAwsConnectorEc2AddressGenericResource",
    AzureAwsConnectorEc2FlowLogGenericResource = "AzureAwsConnectorEc2FlowLogGenericResource",
    AzureAwsConnectorEc2ImageGenericResource = "AzureAwsConnectorEc2ImageGenericResource",
    AzureAwsConnectorEc2InstanceStatusGenericResource = "AzureAwsConnectorEc2InstanceStatusGenericResource",
    AzureAwsConnectorEc2IpamGenericResource = "AzureAwsConnectorEc2IpamGenericResource",
    AzureAwsConnectorEc2KeyPairGenericResource = "AzureAwsConnectorEc2KeyPairGenericResource",
    AzureAwsConnectorEc2NetworkAclGenericResource = "AzureAwsConnectorEc2NetworkAclGenericResource",
    AzureAwsConnectorEc2NetworkInterfaceGenericResource = "AzureAwsConnectorEc2NetworkInterfaceGenericResource",
    AzureAwsConnectorEc2RouteTableGenericResource = "AzureAwsConnectorEc2RouteTableGenericResource",
    AzureAwsConnectorEc2SecurityGroupGenericResource = "AzureAwsConnectorEc2SecurityGroupGenericResource",
    AzureAwsConnectorEc2SnapshotGenericResource = "AzureAwsConnectorEc2SnapshotGenericResource",
    AzureAwsConnectorEc2SubnetGenericResource = "AzureAwsConnectorEc2SubnetGenericResource",
    AzureAwsConnectorEc2VolumeGenericResource = "AzureAwsConnectorEc2VolumeGenericResource",
    AzureAwsConnectorEc2VpcEndpointGenericResource = "AzureAwsConnectorEc2VpcEndpointGenericResource",
    AzureAwsConnectorEc2VpcGenericResource = "AzureAwsConnectorEc2VpcGenericResource",
    AzureAwsConnectorEc2VpcPeeringConnectionGenericResource = "AzureAwsConnectorEc2VpcPeeringConnectionGenericResource",
    AzureAwsConnectorEcrImageDetailGenericResource = "AzureAwsConnectorEcrImageDetailGenericResource",
    AzureAwsConnectorEcrRepositoryGenericResource = "AzureAwsConnectorEcrRepositoryGenericResource",
    AzureAwsConnectorEcsClusterGenericResource = "AzureAwsConnectorEcsClusterGenericResource",
    AzureAwsConnectorEcsServiceGenericResource = "AzureAwsConnectorEcsServiceGenericResource",
    AzureAwsConnectorEcsTaskDefinitionGenericResource = "AzureAwsConnectorEcsTaskDefinitionGenericResource",
    AzureAwsConnectorEfsFileSystemGenericResource = "AzureAwsConnectorEfsFileSystemGenericResource",
    AzureAwsConnectorEfsMountTargetGenericResource = "AzureAwsConnectorEfsMountTargetGenericResource",
    AzureAwsConnectorEksNodegroupGenericResource = "AzureAwsConnectorEksNodegroupGenericResource",
    AzureAwsConnectorElasticBeanstalkApplicationGenericResource = "AzureAwsConnectorElasticBeanstalkApplicationGenericResource",
    AzureAwsConnectorElasticBeanstalkConfigurationTemplateGenericResource = "AzureAwsConnectorElasticBeanstalkConfigurationTemplateGenericResource",
    AzureAwsConnectorElasticBeanstalkEnvironmentGenericResource = "AzureAwsConnectorElasticBeanstalkEnvironmentGenericResource",
    AzureAwsConnectorElasticLoadBalancingV2ListenerGenericResource = "AzureAwsConnectorElasticLoadBalancingV2ListenerGenericResource",
    AzureAwsConnectorElasticLoadBalancingV2LoadBalancerGenericResource = "AzureAwsConnectorElasticLoadBalancingV2LoadBalancerGenericResource",
    AzureAwsConnectorElasticLoadBalancingV2TargetGroupGenericResource = "AzureAwsConnectorElasticLoadBalancingV2TargetGroupGenericResource",
    AzureAwsConnectorElasticLoadBalancingV2TargetHealthDescriptionGenericResource = "AzureAwsConnectorElasticLoadBalancingV2TargetHealthDescriptionGenericResource",
    AzureAwsConnectorEmrClusterGenericResource = "AzureAwsConnectorEmrClusterGenericResource",
    AzureAwsConnectorGuardDutyDetectorGenericResource = "AzureAwsConnectorGuardDutyDetectorGenericResource",
    AzureAwsConnectorIamAccessKeyLastUsedGenericResource = "AzureAwsConnectorIamAccessKeyLastUsedGenericResource",
    AzureAwsConnectorIamAccessKeyMetadatumGenericResource = "AzureAwsConnectorIamAccessKeyMetadatumGenericResource",
    AzureAwsConnectorIamGroupGenericResource = "AzureAwsConnectorIamGroupGenericResource",
    AzureAwsConnectorIamInstanceProfileGenericResource = "AzureAwsConnectorIamInstanceProfileGenericResource",
    AzureAwsConnectorIamMfaDeviceGenericResource = "AzureAwsConnectorIamMfaDeviceGenericResource",
    AzureAwsConnectorIamPasswordPolicyGenericResource = "AzureAwsConnectorIamPasswordPolicyGenericResource",
    AzureAwsConnectorIamPolicyVersionGenericResource = "AzureAwsConnectorIamPolicyVersionGenericResource",
    AzureAwsConnectorIamRoleGenericResource = "AzureAwsConnectorIamRoleGenericResource",
    AzureAwsConnectorIamServerCertificateGenericResource = "AzureAwsConnectorIamServerCertificateGenericResource",
    AzureAwsConnectorIamVirtualMfaDeviceGenericResource = "AzureAwsConnectorIamVirtualMfaDeviceGenericResource",
    AzureAwsConnectorKmsAliasGenericResource = "AzureAwsConnectorKmsAliasGenericResource",
    AzureAwsConnectorKmsKeyGenericResource = "AzureAwsConnectorKmsKeyGenericResource",
    AzureAwsConnectorLambdaFunctionCodeLocationGenericResource = "AzureAwsConnectorLambdaFunctionCodeLocationGenericResource",
    AzureAwsConnectorLambdaFunctionConfigurationGenericResource = "AzureAwsConnectorLambdaFunctionConfigurationGenericResource",
    AzureAwsConnectorLambdaFunctionGenericResource = "AzureAwsConnectorLambdaFunctionGenericResource",
    AzureAwsConnectorLightsailBucketGenericResource = "AzureAwsConnectorLightsailBucketGenericResource",
    AzureAwsConnectorLightsailInstanceGenericResource = "AzureAwsConnectorLightsailInstanceGenericResource",
    AzureAwsConnectorLogsLogGroupGenericResource = "AzureAwsConnectorLogsLogGroupGenericResource",
    AzureAwsConnectorLogsLogStreamGenericResource = "AzureAwsConnectorLogsLogStreamGenericResource",
    AzureAwsConnectorLogsMetricFilterGenericResource = "AzureAwsConnectorLogsMetricFilterGenericResource",
    AzureAwsConnectorLogsSubscriptionFilterGenericResource = "AzureAwsConnectorLogsSubscriptionFilterGenericResource",
    AzureAwsConnectorMacie2JobSummaryGenericResource = "AzureAwsConnectorMacie2JobSummaryGenericResource",
    AzureAwsConnectorMacieAllowListGenericResource = "AzureAwsConnectorMacieAllowListGenericResource",
    AzureAwsConnectorNetworkFirewallFirewallGenericResource = "AzureAwsConnectorNetworkFirewallFirewallGenericResource",
    AzureAwsConnectorNetworkFirewallFirewallPolicyGenericResource = "AzureAwsConnectorNetworkFirewallFirewallPolicyGenericResource",
    AzureAwsConnectorNetworkFirewallRuleGroupGenericResource = "AzureAwsConnectorNetworkFirewallRuleGroupGenericResource",
    AzureAwsConnectorOpenSearchDomainStatusGenericResource = "AzureAwsConnectorOpenSearchDomainStatusGenericResource",
    AzureAwsConnectorOrganizationsAccountGenericResource = "AzureAwsConnectorOrganizationsAccountGenericResource",
    AzureAwsConnectorOrganizationsOrganizationGenericResource = "AzureAwsConnectorOrganizationsOrganizationGenericResource",
    AzureAwsConnectorRdsDbClusterGenericResource = "AzureAwsConnectorRdsDbClusterGenericResource",
    AzureAwsConnectorRdsDbInstanceGenericResource = "AzureAwsConnectorRdsDbInstanceGenericResource",
    AzureAwsConnectorRdsDbSnapshotAttributesResultGenericResource = "AzureAwsConnectorRdsDbSnapshotAttributesResultGenericResource",
    AzureAwsConnectorRdsDbSnapshotGenericResource = "AzureAwsConnectorRdsDbSnapshotGenericResource",
    AzureAwsConnectorRdsEventSubscriptionGenericResource = "AzureAwsConnectorRdsEventSubscriptionGenericResource",
    AzureAwsConnectorRdsExportTaskGenericResource = "AzureAwsConnectorRdsExportTaskGenericResource",
    AzureAwsConnectorRedshiftClusterGenericResource = "AzureAwsConnectorRedshiftClusterGenericResource",
    AzureAwsConnectorRedshiftClusterParameterGroupGenericResource = "AzureAwsConnectorRedshiftClusterParameterGroupGenericResource",
    AzureAwsConnectorRoute53DomainsDomainSummaryGenericResource = "AzureAwsConnectorRoute53DomainsDomainSummaryGenericResource",
    AzureAwsConnectorRoute53HostedZoneGenericResource = "AzureAwsConnectorRoute53HostedZoneGenericResource",
    AzureAwsConnectorRoute53ResourceRecordSetGenericResource = "AzureAwsConnectorRoute53ResourceRecordSetGenericResource",
    AzureAwsConnectorS3AccessControlPolicyGenericResource = "AzureAwsConnectorS3AccessControlPolicyGenericResource",
    AzureAwsConnectorS3AccessPointGenericResource = "AzureAwsConnectorS3AccessPointGenericResource",
    AzureAwsConnectorS3BucketGenericResource = "AzureAwsConnectorS3BucketGenericResource",
    AzureAwsConnectorS3BucketPolicyGenericResource = "AzureAwsConnectorS3BucketPolicyGenericResource",
    AzureAwsConnectorSageMakerAppGenericResource = "AzureAwsConnectorSageMakerAppGenericResource",
    AzureAwsConnectorSageMakerNotebookInstanceSummaryGenericResource = "AzureAwsConnectorSageMakerNotebookInstanceSummaryGenericResource",
    AzureAwsConnectorSecretsManagerResourcePolicyGenericResource = "AzureAwsConnectorSecretsManagerResourcePolicyGenericResource",
    AzureAwsConnectorSecretsManagerSecretGenericResource = "AzureAwsConnectorSecretsManagerSecretGenericResource",
    AzureAwsConnectorSnsSubscriptionGenericResource = "AzureAwsConnectorSnsSubscriptionGenericResource",
    AzureAwsConnectorSnsTopicGenericResource = "AzureAwsConnectorSnsTopicGenericResource",
    AzureAwsConnectorSqsQueueGenericResource = "AzureAwsConnectorSqsQueueGenericResource",
    AzureAwsConnectorSsmInstanceInformationGenericResource = "AzureAwsConnectorSsmInstanceInformationGenericResource",
    AzureAwsConnectorSsmParameterGenericResource = "AzureAwsConnectorSsmParameterGenericResource",
    AzureAwsConnectorSsmResourceComplianceSummaryItemGenericResource = "AzureAwsConnectorSsmResourceComplianceSummaryItemGenericResource",
    AzureAwsConnectorWafv2LoggingConfigurationGenericResource = "AzureAwsConnectorWafv2LoggingConfigurationGenericResource",
    AzureBackupSolutionsVMwareApplicationGenericResource = "AzureBackupSolutionsVMwareApplicationGenericResource",
    AzureBareMetalBareMetalConnectionGenericResource = "AzureBareMetalBareMetalConnectionGenericResource",
    AzureBareMetalConsoleConnectionGenericResource = "AzureBareMetalConsoleConnectionGenericResource",
    AzureBareMetalCrayServerGenericResource = "AzureBareMetalCrayServerGenericResource",
    AzureBareMetalInfrastructureBareMetalInstanceGenericResource = "AzureBareMetalInfrastructureBareMetalInstanceGenericResource",
    AzureBareMetalInfrastructureBareMetalStorageInstanceGenericResource = "AzureBareMetalInfrastructureBareMetalStorageInstanceGenericResource",
    AzureBareMetalMonitoringServerGenericResource = "AzureBareMetalMonitoringServerGenericResource",
    AzureBatchBatchAccountGenericResource = "AzureBatchBatchAccountGenericResource",
    AzureBatchBatchAccountGenericResourceModel = "AzureBatchBatchAccountGenericResourceModel",
    AzureBehaviorIdentityRisk = "AzureBehaviorIdentityRisk",
    AzureBehaviorIdentityRiskModel = "AzureBehaviorIdentityRiskModel",
    AzureBehaviorIdentityRiskModelInfo = "AzureBehaviorIdentityRiskModelInfo",
    AzureBehaviorIdentityRiskPolicyGroupFilters = "AzureBehaviorIdentityRiskPolicyGroupFilters",
    AzureBillingBenefitsCreditGenericResource = "AzureBillingBenefitsCreditGenericResource",
    AzureBillingBenefitsIncentiveScheduleGenericResource = "AzureBillingBenefitsIncentiveScheduleGenericResource",
    AzureBillingBenefitsMaccGenericResource = "AzureBillingBenefitsMaccGenericResource",
    AzureBingAccountGenericResource = "AzureBingAccountGenericResource",
    AzureBluefinInstanceGenericResource = "AzureBluefinInstanceGenericResource",
    AzureBluefinInstancesDatasetGenericResource = "AzureBluefinInstancesDatasetGenericResource",
    AzureBluefinInstancesPipelineGenericResource = "AzureBluefinInstancesPipelineGenericResource",
    AzureBotServiceBotService = "AzureBotServiceBotService",
    AzureBotServiceBotServiceGenericResource = "AzureBotServiceBotServiceGenericResource",
    AzureBotServiceBotServiceInboundExternalWideRangeRisk = "AzureBotServiceBotServiceInboundExternalWideRangeRisk",
    AzureBotServiceBotServiceInboundExternalWideRangeRiskModel = "AzureBotServiceBotServiceInboundExternalWideRangeRiskModel",
    AzureBotServiceBotServiceInboundExternalWideRangeRiskPolicyConfiguration = "AzureBotServiceBotServiceInboundExternalWideRangeRiskPolicyConfiguration",
    AzureBotServiceBotServiceModel = "AzureBotServiceBotServiceModel",
    AzureBotServiceBotServiceProfile = "AzureBotServiceBotServiceProfile",
    AzureBotServiceBotServiceSharedKeyAccessEnabledRisk = "AzureBotServiceBotServiceSharedKeyAccessEnabledRisk",
    AzureBotServiceBotServiceSharedKeyAccessEnabledRiskModel = "AzureBotServiceBotServiceSharedKeyAccessEnabledRiskModel",
    AzureBotServiceBotServiceSharedKeyAccessEnabledRiskPolicyConfiguration = "AzureBotServiceBotServiceSharedKeyAccessEnabledRiskPolicyConfiguration",
    AzureBotServiceBotServiceStateNetwork = "AzureBotServiceBotServiceStateNetwork",
    AzureCacheRedisEnterpriseGenericResource = "AzureCacheRedisEnterpriseGenericResource",
    AzureCacheRedisGenericResource = "AzureCacheRedisGenericResource",
    AzureCacheRedisGenericResourceModel = "AzureCacheRedisGenericResourceModel",
    AzureCdnCdnwebapplicationfirewallpolicyGenericResource = "AzureCdnCdnwebapplicationfirewallpolicyGenericResource",
    AzureCdnEdgeActionGenericResource = "AzureCdnEdgeActionGenericResource",
    AzureCdnEdgeActionsAttachmentGenericResource = "AzureCdnEdgeActionsAttachmentGenericResource",
    AzureCdnEdgeActionsExecutionFilterGenericResource = "AzureCdnEdgeActionsExecutionFilterGenericResource",
    AzureCdnEdgeActionsVersionGenericResource = "AzureCdnEdgeActionsVersionGenericResource",
    AzureCdnProfileGenericResource = "AzureCdnProfileGenericResource",
    AzureCdnProfileGenericResourceModel = "AzureCdnProfileGenericResourceModel",
    AzureCdnProfilesAfdendpointGenericResource = "AzureCdnProfilesAfdendpointGenericResource",
    AzureCdnProfilesEndpointGenericResource = "AzureCdnProfilesEndpointGenericResource",
    AzureCertificateRegistrationCertificateOrderGenericResource = "AzureCertificateRegistrationCertificateOrderGenericResource",
    AzureChange = "AzureChange",
    AzureChaosApplicationGenericResource = "AzureChaosApplicationGenericResource",
    AzureChaosExperimentGenericResource = "AzureChaosExperimentGenericResource",
    AzureChaosPrivateAccessGenericResource = "AzureChaosPrivateAccessGenericResource",
    AzureChaosResilienceProfileGenericResource = "AzureChaosResilienceProfileGenericResource",
    AzureClassicComputeDomainNameGenericResource = "AzureClassicComputeDomainNameGenericResource",
    AzureClassicComputeVirtualMachineGenericResource = "AzureClassicComputeVirtualMachineGenericResource",
    AzureClassicComputeVirtualMachineGenericResourceModel = "AzureClassicComputeVirtualMachineGenericResourceModel",
    AzureClassicNetworkNetworkSecurityGroupGenericResource = "AzureClassicNetworkNetworkSecurityGroupGenericResource",
    AzureClassicNetworkNetworkSecurityGroupGenericResourceModel = "AzureClassicNetworkNetworkSecurityGroupGenericResourceModel",
    AzureClassicNetworkReservedIpGenericResource = "AzureClassicNetworkReservedIpGenericResource",
    AzureClassicNetworkReservedIpGenericResourceModel = "AzureClassicNetworkReservedIpGenericResourceModel",
    AzureClassicNetworkVirtualNetworkGenericResource = "AzureClassicNetworkVirtualNetworkGenericResource",
    AzureClassicNetworkVirtualNetworkGenericResourceModel = "AzureClassicNetworkVirtualNetworkGenericResourceModel",
    AzureClassicStorageStorageAccountGenericResource = "AzureClassicStorageStorageAccountGenericResource",
    AzureClassicStorageStorageAccountGenericResourceModel = "AzureClassicStorageStorageAccountGenericResourceModel",
    AzureCleanRoomCleanroomGenericResource = "AzureCleanRoomCleanroomGenericResource",
    AzureCleanRoomMicroserviceGenericResource = "AzureCleanRoomMicroserviceGenericResource",
    AzureCloudDevicePlatformDelegatedIdentityGenericResource = "AzureCloudDevicePlatformDelegatedIdentityGenericResource",
    AzureCloudesAccountGenericResource = "AzureCloudesAccountGenericResource",
    AzureCloudHealthHealthmodelGenericResource = "AzureCloudHealthHealthmodelGenericResource",
    AzureCloudTestAccountGenericResource = "AzureCloudTestAccountGenericResource",
    AzureCloudTestBuildcacheGenericResource = "AzureCloudTestBuildcacheGenericResource",
    AzureCloudTestHostedpoolGenericResource = "AzureCloudTestHostedpoolGenericResource",
    AzureCloudTestImageGenericResource = "AzureCloudTestImageGenericResource",
    AzureCloudTestPoolGenericResource = "AzureCloudTestPoolGenericResource",
    AzureCodeSigningCodeSigningAccountGenericResource = "AzureCodeSigningCodeSigningAccountGenericResource",
    AzureCognitiveServicesAccount = "AzureCognitiveServicesAccount",
    AzureCognitiveServicesAccountGenericResource = "AzureCognitiveServicesAccountGenericResource",
    AzureCognitiveServicesAccountInboundExternalWideRangeRisk = "AzureCognitiveServicesAccountInboundExternalWideRangeRisk",
    AzureCognitiveServicesAccountInboundExternalWideRangeRiskModel = "AzureCognitiveServicesAccountInboundExternalWideRangeRiskModel",
    AzureCognitiveServicesAccountInboundExternalWideRangeRiskPolicyConfiguration = "AzureCognitiveServicesAccountInboundExternalWideRangeRiskPolicyConfiguration",
    AzureCognitiveServicesAccountModel = "AzureCognitiveServicesAccountModel",
    AzureCognitiveServicesAccountProfile = "AzureCognitiveServicesAccountProfile",
    AzureCognitiveServicesAccountSharedKeyAccessEnabledRisk = "AzureCognitiveServicesAccountSharedKeyAccessEnabledRisk",
    AzureCognitiveServicesAccountSharedKeyAccessEnabledRiskModel = "AzureCognitiveServicesAccountSharedKeyAccessEnabledRiskModel",
    AzureCognitiveServicesAccountSharedKeyAccessEnabledRiskPolicyConfiguration = "AzureCognitiveServicesAccountSharedKeyAccessEnabledRiskPolicyConfiguration",
    AzureCognitiveServicesAccountStateNetwork = "AzureCognitiveServicesAccountStateNetwork",
    AzureCognitiveServicesAccountStateNetworkFirewallRuleData = "AzureCognitiveServicesAccountStateNetworkFirewallRuleData",
    AzureCognitiveServicesAccountType = "AzureCognitiveServicesAccountType",
    AzureCognitiveServicesAccountUnrestrictedOutboundAccessRisk = "AzureCognitiveServicesAccountUnrestrictedOutboundAccessRisk",
    AzureCognitiveServicesAccountUnrestrictedOutboundAccessRiskModel = "AzureCognitiveServicesAccountUnrestrictedOutboundAccessRiskModel",
    AzureCognitiveServicesAccountUnrestrictedOutboundAccessRiskPolicyConfiguration = "AzureCognitiveServicesAccountUnrestrictedOutboundAccessRiskPolicyConfiguration",
    AzureCognitiveServicesCommitmentPlanGenericResource = "AzureCognitiveServicesCommitmentPlanGenericResource",
    AzureCommunityCommunityTrainingGenericResource = "AzureCommunityCommunityTrainingGenericResource",
    AzureComputeAvailabilitySetGenericResource = "AzureComputeAvailabilitySetGenericResource",
    AzureComputeCapacityReservationGroupGenericResource = "AzureComputeCapacityReservationGroupGenericResource",
    AzureComputeCapacityReservationGroupsCapacityReservationGenericResource = "AzureComputeCapacityReservationGroupsCapacityReservationGenericResource",
    AzureComputeCloudServiceGenericResource = "AzureComputeCloudServiceGenericResource",
    AzureComputeCloudServicesRoleInstancesNetworkInterfaceGenericResource = "AzureComputeCloudServicesRoleInstancesNetworkInterfaceGenericResource",
    AzureComputeDisk = "AzureComputeDisk",
    AzureComputeDiskAccessGenericResource = "AzureComputeDiskAccessGenericResource",
    AzureComputeDiskEncryptionSet = "AzureComputeDiskEncryptionSet",
    AzureComputeDiskEncryptionSetGenericResource = "AzureComputeDiskEncryptionSetGenericResource",
    AzureComputeDiskEncryptionSetModel = "AzureComputeDiskEncryptionSetModel",
    AzureComputeDiskEncryptionSetProfile = "AzureComputeDiskEncryptionSetProfile",
    AzureComputeDiskGenericResource = "AzureComputeDiskGenericResource",
    AzureComputeDiskModel = "AzureComputeDiskModel",
    AzureComputeDiskProfile = "AzureComputeDiskProfile",
    AzureComputeDiskResource = "AzureComputeDiskResource",
    AzureComputeDiskResourceEncryptionType = "AzureComputeDiskResourceEncryptionType",
    AzureComputeDiskResourceModel = "AzureComputeDiskResourceModel",
    AzureComputeDiskResourceNetworkAccessPolicyType = "AzureComputeDiskResourceNetworkAccessPolicyType",
    AzureComputeDiskResourceSource = "AzureComputeDiskResourceSource",
    AzureComputeDiskResourceSourceType = "AzureComputeDiskResourceSourceType",
    AzureComputeDiskSnapshot = "AzureComputeDiskSnapshot",
    AzureComputeDiskSnapshotModel = "AzureComputeDiskSnapshotModel",
    AzureComputeDiskSnapshotProfile = "AzureComputeDiskSnapshotProfile",
    AzureComputeDiskSnapshotType = "AzureComputeDiskSnapshotType",
    AzureComputeDiskStorageAccountSku = "AzureComputeDiskStorageAccountSku",
    AzureComputeGalleriesApplicationGenericResource = "AzureComputeGalleriesApplicationGenericResource",
    AzureComputeGalleriesApplicationsVersionGenericResource = "AzureComputeGalleriesApplicationsVersionGenericResource",
    AzureComputeGalleriesImageGenericResource = "AzureComputeGalleriesImageGenericResource",
    AzureComputeGalleriesImagesVersionGenericResource = "AzureComputeGalleriesImagesVersionGenericResource",
    AzureComputeGalleriesInVmAccessControlProfileGenericResource = "AzureComputeGalleriesInVmAccessControlProfileGenericResource",
    AzureComputeGalleriesInVmAccessControlProfilesVersionGenericResource = "AzureComputeGalleriesInVmAccessControlProfilesVersionGenericResource",
    AzureComputeGalleriesRemoteContainerImageGenericResource = "AzureComputeGalleriesRemoteContainerImageGenericResource",
    AzureComputeGalleriesServiceArtifactGenericResource = "AzureComputeGalleriesServiceArtifactGenericResource",
    AzureComputeGallery = "AzureComputeGallery",
    AzureComputeGalleryGenericResource = "AzureComputeGalleryGenericResource",
    AzureComputeGalleryImage = "AzureComputeGalleryImage",
    AzureComputeGalleryImageModel = "AzureComputeGalleryImageModel",
    AzureComputeGalleryImageProfile = "AzureComputeGalleryImageProfile",
    AzureComputeGalleryModel = "AzureComputeGalleryModel",
    AzureComputeGalleryProfile = "AzureComputeGalleryProfile",
    AzureComputeHostGroupGenericResource = "AzureComputeHostGroupGenericResource",
    AzureComputeHostGroupsHostGenericResource = "AzureComputeHostGroupsHostGenericResource",
    AzureComputeImage = "AzureComputeImage",
    AzureComputeImageGenericResource = "AzureComputeImageGenericResource",
    AzureComputeImageInfo = "AzureComputeImageInfo",
    AzureComputeManagedImage = "AzureComputeManagedImage",
    AzureComputeManagedImageModel = "AzureComputeManagedImageModel",
    AzureComputeManagedImageProfile = "AzureComputeManagedImageProfile",
    AzureComputeProximityPlacementGroupGenericResource = "AzureComputeProximityPlacementGroupGenericResource",
    AzureComputeResourceDiskRole = "AzureComputeResourceDiskRole",
    AzureComputeResourceStateNetwork = "AzureComputeResourceStateNetwork",
    AzureComputeRestorePointCollectionGenericResource = "AzureComputeRestorePointCollectionGenericResource",
    AzureComputeRestorePointCollectionGenericResourceModel = "AzureComputeRestorePointCollectionGenericResourceModel",
    AzureComputeScheduleAutoActionGenericResource = "AzureComputeScheduleAutoActionGenericResource",
    AzureComputeSharedVmExtensionGenericResource = "AzureComputeSharedVmExtensionGenericResource",
    AzureComputeSharedVmExtensionsVersionGenericResource = "AzureComputeSharedVmExtensionsVersionGenericResource",
    AzureComputeSharedVmImageGenericResource = "AzureComputeSharedVmImageGenericResource",
    AzureComputeSharedVmImagesVersionGenericResource = "AzureComputeSharedVmImagesVersionGenericResource",
    AzureComputeSnapshotGenericResource = "AzureComputeSnapshotGenericResource",
    AzureComputeSshPublicKeyGenericResource = "AzureComputeSshPublicKeyGenericResource",
    AzureComputeSshPublicKeyGenericResourceModel = "AzureComputeSshPublicKeyGenericResourceModel",
    AzureComputeVirtualMachine = "AzureComputeVirtualMachine",
    AzureComputeVirtualMachineAnalysisServiceConfiguration = "AzureComputeVirtualMachineAnalysisServiceConfiguration",
    AzureComputeVirtualMachineGenericResource = "AzureComputeVirtualMachineGenericResource",
    AzureComputeVirtualMachineModel = "AzureComputeVirtualMachineModel",
    AzureComputeVirtualMachineModelFilters = "AzureComputeVirtualMachineModelFilters",
    AzureComputeVirtualMachineProfile = "AzureComputeVirtualMachineProfile",
    AzureComputeVirtualMachineResource = "AzureComputeVirtualMachineResource",
    AzureComputeVirtualMachineResourceModel = "AzureComputeVirtualMachineResourceModel",
    AzureComputeVirtualMachineResourceSpecification = "AzureComputeVirtualMachineResourceSpecification",
    AzureComputeVirtualMachineScaleSet = "AzureComputeVirtualMachineScaleSet",
    AzureComputeVirtualMachineScaleSetGenericResource = "AzureComputeVirtualMachineScaleSetGenericResource",
    AzureComputeVirtualMachineScaleSetModel = "AzureComputeVirtualMachineScaleSetModel",
    AzureComputeVirtualMachineScaleSetModelFilters = "AzureComputeVirtualMachineScaleSetModelFilters",
    AzureComputeVirtualMachineScaleSetProfile = "AzureComputeVirtualMachineScaleSetProfile",
    AzureComputeVirtualMachineScaleSetsVirtualMachinesNetworkInterfacesIpConfigurationsPublicIpAddressGenericResource = "AzureComputeVirtualMachineScaleSetsVirtualMachinesNetworkInterfacesIpConfigurationsPublicIpAddressGenericResource",
    AzureComputeVirtualMachineScaleSetType = "AzureComputeVirtualMachineScaleSetType",
    AzureComputeVirtualMachineScaleSetVirtualMachine = "AzureComputeVirtualMachineScaleSetVirtualMachine",
    AzureComputeVirtualMachineScaleSetVirtualMachineDisk = "AzureComputeVirtualMachineScaleSetVirtualMachineDisk",
    AzureComputeVirtualMachineScaleSetVirtualMachineModel = "AzureComputeVirtualMachineScaleSetVirtualMachineModel",
    AzureComputeVirtualMachineScaleSetVirtualMachineModelFilters = "AzureComputeVirtualMachineScaleSetVirtualMachineModelFilters",
    AzureComputeVirtualMachineScaleSetVirtualMachineNetworkInterface = "AzureComputeVirtualMachineScaleSetVirtualMachineNetworkInterface",
    AzureComputeVirtualMachineScaleSetVirtualMachineNetworkInterfaceIpConfigurationPublicIpAddress = "AzureComputeVirtualMachineScaleSetVirtualMachineNetworkInterfaceIpConfigurationPublicIpAddress",
    AzureComputeVirtualMachineScaleSetVirtualMachineNetworkInterfaceIpConfigurationPublicIpAddressModel = "AzureComputeVirtualMachineScaleSetVirtualMachineNetworkInterfaceIpConfigurationPublicIpAddressModel",
    AzureComputeVirtualMachineScaleSetVirtualMachineNetworkInterfaceIpConfigurationPublicIpAddressProfile = "AzureComputeVirtualMachineScaleSetVirtualMachineNetworkInterfaceIpConfigurationPublicIpAddressProfile",
    AzureComputeVirtualMachineScaleSetVirtualMachineNetworkInterfaceModel = "AzureComputeVirtualMachineScaleSetVirtualMachineNetworkInterfaceModel",
    AzureComputeVirtualMachineScaleSetVirtualMachineNetworkInterfaceProfile = "AzureComputeVirtualMachineScaleSetVirtualMachineNetworkInterfaceProfile",
    AzureComputeVirtualMachineScaleSetVirtualMachineProfile = "AzureComputeVirtualMachineScaleSetVirtualMachineProfile",
    AzureComputeVirtualMachinesExtensionGenericResource = "AzureComputeVirtualMachinesExtensionGenericResource",
    AzureComputeVirtualMachinesRunCommandGenericResource = "AzureComputeVirtualMachinesRunCommandGenericResource",
    AzureComputeVirtualMachineStatus = "AzureComputeVirtualMachineStatus",
    AzureComputeVirtualMachinesVmApplicationGenericResource = "AzureComputeVirtualMachinesVmApplicationGenericResource",
    AzureComputeVirtualMachineUnmanagedDisk = "AzureComputeVirtualMachineUnmanagedDisk",
    AzureComputeVirtualMachineUnmanagedDiskExistsCodeRisk = "AzureComputeVirtualMachineUnmanagedDiskExistsCodeRisk",
    AzureComputeVirtualMachineUnmanagedDiskExistsCodeRiskModel = "AzureComputeVirtualMachineUnmanagedDiskExistsCodeRiskModel",
    AzureComputeVirtualMachineUnmanagedDiskExistsRisk = "AzureComputeVirtualMachineUnmanagedDiskExistsRisk",
    AzureComputeVirtualMachineUnmanagedDiskExistsRiskModel = "AzureComputeVirtualMachineUnmanagedDiskExistsRiskModel",
    AzureComputeVirtualMachineUnmanagedDiskExistsRiskPolicyConfiguration = "AzureComputeVirtualMachineUnmanagedDiskExistsRiskPolicyConfiguration",
    AzureComputeVirtualMachineUserDataSecretExistsCodeRisk = "AzureComputeVirtualMachineUserDataSecretExistsCodeRisk",
    AzureComputeVirtualMachineUserDataSecretExistsCodeRiskModel = "AzureComputeVirtualMachineUserDataSecretExistsCodeRiskModel",
    AzureComputeVirtualMachineUserDataSecretExistsRiskPolicyConfiguration = "AzureComputeVirtualMachineUserDataSecretExistsRiskPolicyConfiguration",
    AzureConfidentialLedgerLedgerGenericResource = "AzureConfidentialLedgerLedgerGenericResource",
    AzureConfidentialLedgerManagedCcFGenericResource = "AzureConfidentialLedgerManagedCcFGenericResource",
    AzureConfluentOrganizationGenericResource = "AzureConfluentOrganizationGenericResource",
    AzureConnectedCacheCacheNodeGenericResource = "AzureConnectedCacheCacheNodeGenericResource",
    AzureConnectedCacheEnterpriseCustomerGenericResource = "AzureConnectedCacheEnterpriseCustomerGenericResource",
    AzureConnectedCacheEnterpriseMccCustomerGenericResource = "AzureConnectedCacheEnterpriseMccCustomerGenericResource",
    AzureConnectedCacheEnterpriseMccCustomersEnterpriseMccCacheNodeGenericResource = "AzureConnectedCacheEnterpriseMccCustomersEnterpriseMccCacheNodeGenericResource",
    AzureConnectedCacheIspCustomerGenericResource = "AzureConnectedCacheIspCustomerGenericResource",
    AzureConnectedCacheIspCustomersIspCacheNodeGenericResource = "AzureConnectedCacheIspCustomersIspCacheNodeGenericResource",
    AzureConnectedCredentialsCredentialGenericResource = "AzureConnectedCredentialsCredentialGenericResource",
    AzureConnectedopenstackHeatStackGenericResource = "AzureConnectedopenstackHeatStackGenericResource",
    AzureConnectedopenstackOpenStackIdentityGenericResource = "AzureConnectedopenstackOpenStackIdentityGenericResource",
    AzureConnectedVehiclePlatformAccountGenericResource = "AzureConnectedVehiclePlatformAccountGenericResource",
    AzureConnectedVMwareVSphereClusterGenericResource = "AzureConnectedVMwareVSphereClusterGenericResource",
    AzureConnectedVMwareVSphereDatastoreGenericResource = "AzureConnectedVMwareVSphereDatastoreGenericResource",
    AzureConnectedVMwareVSphereHostGenericResource = "AzureConnectedVMwareVSphereHostGenericResource",
    AzureConnectedVMwareVSphereResourcepoolGenericResource = "AzureConnectedVMwareVSphereResourcepoolGenericResource",
    AzureConnectedVMwareVSphereVcenterGenericResource = "AzureConnectedVMwareVSphereVcenterGenericResource",
    AzureConnectedVMwareVSphereVirtualmachineGenericResource = "AzureConnectedVMwareVSphereVirtualmachineGenericResource",
    AzureConnectedVMwareVSphereVirtualmachinesExtensionGenericResource = "AzureConnectedVMwareVSphereVirtualmachinesExtensionGenericResource",
    AzureConnectedVMwareVSphereVirtualmachinetemplateGenericResource = "AzureConnectedVMwareVSphereVirtualmachinetemplateGenericResource",
    AzureConnectedVMwareVSphereVirtualnetworkGenericResource = "AzureConnectedVMwareVSphereVirtualnetworkGenericResource",
    AzureConsoleEntityPage = "AzureConsoleEntityPage",
    AzureContainerImage = "AzureContainerImage",
    AzureContainerImageModel = "AzureContainerImageModel",
    AzureContainerImageModelFilters = "AzureContainerImageModelFilters",
    AzureContainerImageProfile = "AzureContainerImageProfile",
    AzureContainerInstanceContainerGroupGenericResource = "AzureContainerInstanceContainerGroupGenericResource",
    AzureContainerInstanceContainerGroupGenericResourceModel = "AzureContainerInstanceContainerGroupGenericResourceModel",
    AzureContainerInstanceContainerGroupProfileGenericResource = "AzureContainerInstanceContainerGroupProfileGenericResource",
    AzureContainerInstanceContainerScaleSetGenericResource = "AzureContainerInstanceContainerScaleSetGenericResource",
    AzureContainerInstanceNGroupGenericResource = "AzureContainerInstanceNGroupGenericResource",
    AzureContainerRegistryRegistriesAgentpoolGenericResource = "AzureContainerRegistryRegistriesAgentpoolGenericResource",
    AzureContainerRegistryRegistriesBuildTaskGenericResource = "AzureContainerRegistryRegistriesBuildTaskGenericResource",
    AzureContainerRegistryRegistriesReplicationGenericResource = "AzureContainerRegistryRegistriesReplicationGenericResource",
    AzureContainerRegistryRegistriesTaskGenericResource = "AzureContainerRegistryRegistriesTaskGenericResource",
    AzureContainerRegistryRegistriesTaskrunGenericResource = "AzureContainerRegistryRegistriesTaskrunGenericResource",
    AzureContainerRegistryRegistriesWebhookGenericResource = "AzureContainerRegistryRegistriesWebhookGenericResource",
    AzureContainerRegistryRegistry = "AzureContainerRegistryRegistry",
    AzureContainerRegistryRegistryAccessKeyEnabledCodeRisk = "AzureContainerRegistryRegistryAccessKeyEnabledCodeRisk",
    AzureContainerRegistryRegistryAccessKeyEnabledCodeRiskModel = "AzureContainerRegistryRegistryAccessKeyEnabledCodeRiskModel",
    AzureContainerRegistryRegistryAccessKeyEnabledRisk = "AzureContainerRegistryRegistryAccessKeyEnabledRisk",
    AzureContainerRegistryRegistryAccessKeyEnabledRiskModel = "AzureContainerRegistryRegistryAccessKeyEnabledRiskModel",
    AzureContainerRegistryRegistryAccessKeyEnabledRiskPolicyConfiguration = "AzureContainerRegistryRegistryAccessKeyEnabledRiskPolicyConfiguration",
    AzureContainerRegistryRegistryGenericResource = "AzureContainerRegistryRegistryGenericResource",
    AzureContainerRegistryRegistryInboundExternalWideRangeCodeRisk = "AzureContainerRegistryRegistryInboundExternalWideRangeCodeRisk",
    AzureContainerRegistryRegistryInboundExternalWideRangeCodeRiskModel = "AzureContainerRegistryRegistryInboundExternalWideRangeCodeRiskModel",
    AzureContainerRegistryRegistryInboundExternalWideRangeRisk = "AzureContainerRegistryRegistryInboundExternalWideRangeRisk",
    AzureContainerRegistryRegistryInboundExternalWideRangeRiskModel = "AzureContainerRegistryRegistryInboundExternalWideRangeRiskModel",
    AzureContainerRegistryRegistryInboundExternalWideRangeRiskPolicyConfiguration = "AzureContainerRegistryRegistryInboundExternalWideRangeRiskPolicyConfiguration",
    AzureContainerRegistryRegistryModel = "AzureContainerRegistryRegistryModel",
    AzureContainerRegistryRegistryNetworkAccessType = "AzureContainerRegistryRegistryNetworkAccessType",
    AzureContainerRegistryRegistryProfile = "AzureContainerRegistryRegistryProfile",
    AzureContainerRegistryRegistrySku = "AzureContainerRegistryRegistrySku",
    AzureContainerRegistryRegistryStateNetwork = "AzureContainerRegistryRegistryStateNetwork",
    AzureContainerRegistryRegistryStateNetworkFirewallRuleData = "AzureContainerRegistryRegistryStateNetworkFirewallRuleData",
    AzureContainerRegistryRepository = "AzureContainerRegistryRepository",
    AzureContainerRegistryRepositoryModel = "AzureContainerRegistryRepositoryModel",
    AzureContainerRegistryRepositoryProfile = "AzureContainerRegistryRepositoryProfile",
    AzureContainerResource = "AzureContainerResource",
    AzureContainerResourceTypeMetadata = "AzureContainerResourceTypeMetadata",
    AzureContainerServiceContainerServiceGenericResource = "AzureContainerServiceContainerServiceGenericResource",
    AzureContainerServiceFleetGenericResource = "AzureContainerServiceFleetGenericResource",
    AzureContainerServiceManagedCluster = "AzureContainerServiceManagedCluster",
    AzureContainerServiceManagedClusterAgentPool = "AzureContainerServiceManagedClusterAgentPool",
    AzureContainerServiceManagedClusterAgentPoolAutoscaling = "AzureContainerServiceManagedClusterAgentPoolAutoscaling",
    AzureContainerServiceManagedClusterAgentPoolMode = "AzureContainerServiceManagedClusterAgentPoolMode",
    AzureContainerServiceManagedClusterAgentPoolModel = "AzureContainerServiceManagedClusterAgentPoolModel",
    AzureContainerServiceManagedClusterAgentPoolNode = "AzureContainerServiceManagedClusterAgentPoolNode",
    AzureContainerServiceManagedClusterAgentPoolProfile = "AzureContainerServiceManagedClusterAgentPoolProfile",
    AzureContainerServiceManagedClusterAgentPoolStatus = "AzureContainerServiceManagedClusterAgentPoolStatus",
    AzureContainerServiceManagedClusterAuthentication = "AzureContainerServiceManagedClusterAuthentication",
    AzureContainerServiceManagedClusterAuthenticationAndAuthorizationMode = "AzureContainerServiceManagedClusterAuthenticationAndAuthorizationMode",
    AzureContainerServiceManagedClusterAuthorization = "AzureContainerServiceManagedClusterAuthorization",
    AzureContainerServiceManagedClusterGenericResource = "AzureContainerServiceManagedClusterGenericResource",
    AzureContainerServiceManagedClusterIdentityAuthenticationDisabledRisk = "AzureContainerServiceManagedClusterIdentityAuthenticationDisabledRisk",
    AzureContainerServiceManagedClusterIdentityAuthenticationDisabledRiskModel = "AzureContainerServiceManagedClusterIdentityAuthenticationDisabledRiskModel",
    AzureContainerServiceManagedClusterIdentityAuthenticationDisabledRiskPolicyConfiguration = "AzureContainerServiceManagedClusterIdentityAuthenticationDisabledRiskPolicyConfiguration",
    AzureContainerServiceManagedClusterInboundExternalWideRangeRisk = "AzureContainerServiceManagedClusterInboundExternalWideRangeRisk",
    AzureContainerServiceManagedClusterInboundExternalWideRangeRiskModel = "AzureContainerServiceManagedClusterInboundExternalWideRangeRiskModel",
    AzureContainerServiceManagedClusterInboundExternalWideRangeRiskPolicyConfiguration = "AzureContainerServiceManagedClusterInboundExternalWideRangeRiskPolicyConfiguration",
    AzureContainerServiceManagedClusterKubernetesSecretEncryptionDisabledCodeRisk = "AzureContainerServiceManagedClusterKubernetesSecretEncryptionDisabledCodeRisk",
    AzureContainerServiceManagedClusterKubernetesSecretEncryptionDisabledCodeRiskModel = "AzureContainerServiceManagedClusterKubernetesSecretEncryptionDisabledCodeRiskModel",
    AzureContainerServiceManagedClusterKubernetesSecretEncryptionDisabledRisk = "AzureContainerServiceManagedClusterKubernetesSecretEncryptionDisabledRisk",
    AzureContainerServiceManagedClusterKubernetesSecretEncryptionDisabledRiskModel = "AzureContainerServiceManagedClusterKubernetesSecretEncryptionDisabledRiskModel",
    AzureContainerServiceManagedClusterKubernetesSecretEncryptionDisabledRiskPolicyConfiguration = "AzureContainerServiceManagedClusterKubernetesSecretEncryptionDisabledRiskPolicyConfiguration",
    AzureContainerServiceManagedClusterModel = "AzureContainerServiceManagedClusterModel",
    AzureContainerServiceManagedClusterModelFilters = "AzureContainerServiceManagedClusterModelFilters",
    AzureContainerServiceManagedClusterNetwork = "AzureContainerServiceManagedClusterNetwork",
    AzureContainerServiceManagedClusterNetworkType = "AzureContainerServiceManagedClusterNetworkType",
    AzureContainerServiceManagedClusterProfile = "AzureContainerServiceManagedClusterProfile",
    AzureContainerServiceManagedclustersnapshotGenericResource = "AzureContainerServiceManagedclustersnapshotGenericResource",
    AzureContainerServiceManagedClusterStatus = "AzureContainerServiceManagedClusterStatus",
    AzureContainerServiceManagedClusterVirtualMachineInboundExternalRisk = "AzureContainerServiceManagedClusterVirtualMachineInboundExternalRisk",
    AzureContainerServiceManagedClusterVirtualMachineInboundExternalRiskModel = "AzureContainerServiceManagedClusterVirtualMachineInboundExternalRiskModel",
    AzureContainerServiceManagedClusterVirtualMachineInboundExternalRiskPolicyConfiguration = "AzureContainerServiceManagedClusterVirtualMachineInboundExternalRiskPolicyConfiguration",
    AzureContainerServiceManagedClusterVirtualMachineInboundExternalRiskVirtualMachineData = "AzureContainerServiceManagedClusterVirtualMachineInboundExternalRiskVirtualMachineData",
    AzureContainerServiceSnapshotGenericResource = "AzureContainerServiceSnapshotGenericResource",
    AzureCostManagementConnectorGenericResource = "AzureCostManagementConnectorGenericResource",
    AzureCreateRoleAssignmentChange = "AzureCreateRoleAssignmentChange",
    AzureCreateRoleAssignmentResourceChange = "AzureCreateRoleAssignmentResourceChange",
    AzureCreateRoleAssignmentScheduleChange = "AzureCreateRoleAssignmentScheduleChange",
    AzureCreateRoleEligibilityScheduleChange = "AzureCreateRoleEligibilityScheduleChange",
    AzureCustomProvidersResourceproviderGenericResource = "AzureCustomProvidersResourceproviderGenericResource",
    AzureCustomRiskModel = "AzureCustomRiskModel",
    AzureD365CustomerInsightsInstanceGenericResource = "AzureD365CustomerInsightsInstanceGenericResource",
    AzureDashboardGrafanaGenericResource = "AzureDashboardGrafanaGenericResource",
    AzureDashboardGrafanaManagedPrivateEndpointGenericResource = "AzureDashboardGrafanaManagedPrivateEndpointGenericResource",
    AzureDatabaseFleetManagerFleetGenericResource = "AzureDatabaseFleetManagerFleetGenericResource",
    AzureDatabaseResourceStateNetwork = "AzureDatabaseResourceStateNetwork",
    AzureDatabaseWatcherWatcherGenericResource = "AzureDatabaseWatcherWatcherGenericResource",
    AzureDataBoxEdgeDataBoxEdgeDeviceGenericResource = "AzureDataBoxEdgeDataBoxEdgeDeviceGenericResource",
    AzureDataBoxJobGenericResource = "AzureDataBoxJobGenericResource",
    AzureDatabricksAccessConnectorGenericResource = "AzureDatabricksAccessConnectorGenericResource",
    AzureDatabricksWorkspaceGenericResource = "AzureDatabricksWorkspaceGenericResource",
    AzureDatabricksWorkspaceGenericResourceModel = "AzureDatabricksWorkspaceGenericResourceModel",
    AzureDatadogMonitorGenericResource = "AzureDatadogMonitorGenericResource",
    AzureDataFactoryFactoryGenericResource = "AzureDataFactoryFactoryGenericResource",
    AzureDataFactoryFactoryGenericResourceModel = "AzureDataFactoryFactoryGenericResourceModel",
    AzureDataLakeAnalyticsAccountGenericResource = "AzureDataLakeAnalyticsAccountGenericResource",
    AzureDataLakeStoreAccountGenericResource = "AzureDataLakeStoreAccountGenericResource",
    AzureDataLakeStoreAccountGenericResourceModel = "AzureDataLakeStoreAccountGenericResourceModel",
    AzureDataMigrationMigrationServiceGenericResource = "AzureDataMigrationMigrationServiceGenericResource",
    AzureDataMigrationServiceGenericResource = "AzureDataMigrationServiceGenericResource",
    AzureDataMigrationServiceGenericResourceModel = "AzureDataMigrationServiceGenericResourceModel",
    AzureDataMigrationServicesProjectGenericResource = "AzureDataMigrationServicesProjectGenericResource",
    AzureDataMigrationServicesProjectGenericResourceModel = "AzureDataMigrationServicesProjectGenericResourceModel",
    AzureDataMigrationSlotGenericResource = "AzureDataMigrationSlotGenericResource",
    AzureDataMigrationSqlMigrationServiceGenericResource = "AzureDataMigrationSqlMigrationServiceGenericResource",
    AzureDataProtectionBackupVaultGenericResource = "AzureDataProtectionBackupVaultGenericResource",
    AzureDataProtectionBackupVaultGenericResourceModel = "AzureDataProtectionBackupVaultGenericResourceModel",
    AzureDataProtectionResourceGuardGenericResource = "AzureDataProtectionResourceGuardGenericResource",
    AzureDataReplicationReplicationFabricGenericResource = "AzureDataReplicationReplicationFabricGenericResource",
    AzureDataReplicationReplicationVaultGenericResource = "AzureDataReplicationReplicationVaultGenericResource",
    AzureDataShareAccountGenericResource = "AzureDataShareAccountGenericResource",
    AzureDataShareAccountGenericResourceModel = "AzureDataShareAccountGenericResourceModel",
    AzureDataTransferConnectionGenericResource = "AzureDataTransferConnectionGenericResource",
    AzureDataTransferConnectionsFlowGenericResource = "AzureDataTransferConnectionsFlowGenericResource",
    AzureDataTransferPipelineGenericResource = "AzureDataTransferPipelineGenericResource",
    AzureDbForMariaDbServerGenericResource = "AzureDbForMariaDbServerGenericResource",
    AzureDbForMariaDbServerGenericResourceModel = "AzureDbForMariaDbServerGenericResourceModel",
    AzureDbForMySqlFlexibleServerGenericResource = "AzureDbForMySqlFlexibleServerGenericResource",
    AzureDbForMySqlServerGenericResource = "AzureDbForMySqlServerGenericResource",
    AzureDbForPostgreSqlFlexibleServerGenericResource = "AzureDbForPostgreSqlFlexibleServerGenericResource",
    AzureDbForPostgreSqlServerGenericResource = "AzureDbForPostgreSqlServerGenericResource",
    AzureDbForPostgreSqlServerGroupsv2GenericResource = "AzureDbForPostgreSqlServerGroupsv2GenericResource",
    AzureDelegatedNetworkControllerGenericResource = "AzureDelegatedNetworkControllerGenericResource",
    AzureDelegatedNetworkDelegatedSubnetGenericResource = "AzureDelegatedNetworkDelegatedSubnetGenericResource",
    AzureDelegatedNetworkOrchestratorGenericResource = "AzureDelegatedNetworkOrchestratorGenericResource",
    AzureDeleteManagedIdentityChange = "AzureDeleteManagedIdentityChange",
    AzureDeleteRoleAssignmentChange = "AzureDeleteRoleAssignmentChange",
    AzureDeleteRoleAssignmentClassicAdministratorChange = "AzureDeleteRoleAssignmentClassicAdministratorChange",
    AzureDeleteRoleAssignmentResourceChange = "AzureDeleteRoleAssignmentResourceChange",
    AzureDeleteRoleAssignmentScheduleChange = "AzureDeleteRoleAssignmentScheduleChange",
    AzureDeleteRoleEligibilityScheduleChange = "AzureDeleteRoleEligibilityScheduleChange",
    AzureDeleteSystemManagedIdentityChange = "AzureDeleteSystemManagedIdentityChange",
    AzureDeleteUserManagedIdentityChange = "AzureDeleteUserManagedIdentityChange",
    AzureDesktopVirtualizationAppattachpackageGenericResource = "AzureDesktopVirtualizationAppattachpackageGenericResource",
    AzureDesktopVirtualizationApplicationgroupGenericResource = "AzureDesktopVirtualizationApplicationgroupGenericResource",
    AzureDesktopVirtualizationApplicationgroupGenericResourceModel = "AzureDesktopVirtualizationApplicationgroupGenericResourceModel",
    AzureDesktopVirtualizationConnectionPolicyGenericResource = "AzureDesktopVirtualizationConnectionPolicyGenericResource",
    AzureDesktopVirtualizationHostpoolGenericResource = "AzureDesktopVirtualizationHostpoolGenericResource",
    AzureDesktopVirtualizationHostpoolGenericResourceModel = "AzureDesktopVirtualizationHostpoolGenericResourceModel",
    AzureDesktopVirtualizationScalingPlanGenericResource = "AzureDesktopVirtualizationScalingPlanGenericResource",
    AzureDesktopVirtualizationScalingPlanGenericResourceModel = "AzureDesktopVirtualizationScalingPlanGenericResourceModel",
    AzureDesktopVirtualizationWorkspaceGenericResource = "AzureDesktopVirtualizationWorkspaceGenericResource",
    AzureDesktopVirtualizationWorkspaceGenericResourceModel = "AzureDesktopVirtualizationWorkspaceGenericResourceModel",
    AzureDevCenterDevcenterGenericResource = "AzureDevCenterDevcenterGenericResource",
    AzureDevCenterDevcentersDevboxdefinitionGenericResource = "AzureDevCenterDevcentersDevboxdefinitionGenericResource",
    AzureDevCenterNetworkConnectionGenericResource = "AzureDevCenterNetworkConnectionGenericResource",
    AzureDevCenterPlanGenericResource = "AzureDevCenterPlanGenericResource",
    AzureDevCenterProjectGenericResource = "AzureDevCenterProjectGenericResource",
    AzureDevCenterProjectsPoolGenericResource = "AzureDevCenterProjectsPoolGenericResource",
    AzureDevHubIacProfileGenericResource = "AzureDevHubIacProfileGenericResource",
    AzureDevHubWorkflowGenericResource = "AzureDevHubWorkflowGenericResource",
    AzureDeviceRegistryAssetEndpointProfileGenericResource = "AzureDeviceRegistryAssetEndpointProfileGenericResource",
    AzureDeviceRegistryAssetGenericResource = "AzureDeviceRegistryAssetGenericResource",
    AzureDeviceRegistryDeviceGenericResource = "AzureDeviceRegistryDeviceGenericResource",
    AzureDeviceRegistryDiscoveredAssetEndpointProfileGenericResource = "AzureDeviceRegistryDiscoveredAssetEndpointProfileGenericResource",
    AzureDeviceRegistryDiscoveredAssetGenericResource = "AzureDeviceRegistryDiscoveredAssetGenericResource",
    AzureDeviceRegistrySchemaRegistryGenericResource = "AzureDeviceRegistrySchemaRegistryGenericResource",
    AzureDevicesIotHubGenericResource = "AzureDevicesIotHubGenericResource",
    AzureDevicesIotHubGenericResourceModel = "AzureDevicesIotHubGenericResourceModel",
    AzureDevicesProvisioningServiceGenericResource = "AzureDevicesProvisioningServiceGenericResource",
    AzureDevicesProvisioningServiceGenericResourceModel = "AzureDevicesProvisioningServiceGenericResourceModel",
    AzureDeviceUpdateAccountGenericResource = "AzureDeviceUpdateAccountGenericResource",
    AzureDeviceUpdateAccountGenericResourceModel = "AzureDeviceUpdateAccountGenericResourceModel",
    AzureDeviceUpdateAccountsAgentGenericResource = "AzureDeviceUpdateAccountsAgentGenericResource",
    AzureDeviceUpdateAccountsInstanceGenericResource = "AzureDeviceUpdateAccountsInstanceGenericResource",
    AzureDeviceUpdateAccountsInstanceGenericResourceModel = "AzureDeviceUpdateAccountsInstanceGenericResourceModel",
    AzureDeviceUpdateUpdateAccountGenericResource = "AzureDeviceUpdateUpdateAccountGenericResource",
    AzureDeviceUpdateUpdateAccountsActiveDeploymentGenericResource = "AzureDeviceUpdateUpdateAccountsActiveDeploymentGenericResource",
    AzureDeviceUpdateUpdateAccountsAgentGenericResource = "AzureDeviceUpdateUpdateAccountsAgentGenericResource",
    AzureDeviceUpdateUpdateAccountsDeploymentGenericResource = "AzureDeviceUpdateUpdateAccountsDeploymentGenericResource",
    AzureDeviceUpdateUpdateAccountsDeviceClassGenericResource = "AzureDeviceUpdateUpdateAccountsDeviceClassGenericResource",
    AzureDeviceUpdateUpdateAccountsUpdateGenericResource = "AzureDeviceUpdateUpdateAccountsUpdateGenericResource",
    AzureDevOpsAuthenticationProviderConfigurationSection = "AzureDevOpsAuthenticationProviderConfigurationSection",
    AzureDevOpsInfrastructurePoolGenericResource = "AzureDevOpsInfrastructurePoolGenericResource",
    AzureDevOpsOrganizationConfiguration = "AzureDevOpsOrganizationConfiguration",
    AzureDevOpsOrganizationManagerError = "AzureDevOpsOrganizationManagerError",
    AzureDevOpsTenantConfiguration = "AzureDevOpsTenantConfiguration",
    AzureDevTestLabLabGenericResource = "AzureDevTestLabLabGenericResource",
    AzureDevTestLabLabsServiceRunnerGenericResource = "AzureDevTestLabLabsServiceRunnerGenericResource",
    AzureDevTestLabLabsVirtualMachineGenericResource = "AzureDevTestLabLabsVirtualMachineGenericResource",
    AzureDevTestLabScheduleGenericResource = "AzureDevTestLabScheduleGenericResource",
    AzureDigitalTwinsDigitalTwinsInstanceGenericResource = "AzureDigitalTwinsDigitalTwinsInstanceGenericResource",
    AzureDocumentDbCassandraClusterGenericResource = "AzureDocumentDbCassandraClusterGenericResource",
    AzureDocumentDbCassandraClusterGenericResourceModel = "AzureDocumentDbCassandraClusterGenericResourceModel",
    AzureDocumentDbDatabaseAccount = "AzureDocumentDbDatabaseAccount",
    AzureDocumentDbDatabaseAccountBackupType = "AzureDocumentDbDatabaseAccountBackupType",
    AzureDocumentDbDatabaseAccountCassandraKeyspace = "AzureDocumentDbDatabaseAccountCassandraKeyspace",
    AzureDocumentDbDatabaseAccountCassandraKeyspaceModel = "AzureDocumentDbDatabaseAccountCassandraKeyspaceModel",
    AzureDocumentDbDatabaseAccountCassandraKeyspaceProfile = "AzureDocumentDbDatabaseAccountCassandraKeyspaceProfile",
    AzureDocumentDbDatabaseAccountGenericResource = "AzureDocumentDbDatabaseAccountGenericResource",
    AzureDocumentDbDatabaseAccountGremlinDatabase = "AzureDocumentDbDatabaseAccountGremlinDatabase",
    AzureDocumentDbDatabaseAccountGremlinDatabaseModel = "AzureDocumentDbDatabaseAccountGremlinDatabaseModel",
    AzureDocumentDbDatabaseAccountGremlinDatabaseProfile = "AzureDocumentDbDatabaseAccountGremlinDatabaseProfile",
    AzureDocumentDbDatabaseAccountManagementKeyAccessEnabledCodeRisk = "AzureDocumentDbDatabaseAccountManagementKeyAccessEnabledCodeRisk",
    AzureDocumentDbDatabaseAccountManagementKeyAccessEnabledCodeRiskModel = "AzureDocumentDbDatabaseAccountManagementKeyAccessEnabledCodeRiskModel",
    AzureDocumentDbDatabaseAccountManagementKeyAccessEnabledRisk = "AzureDocumentDbDatabaseAccountManagementKeyAccessEnabledRisk",
    AzureDocumentDbDatabaseAccountManagementKeyAccessEnabledRiskModel = "AzureDocumentDbDatabaseAccountManagementKeyAccessEnabledRiskModel",
    AzureDocumentDbDatabaseAccountManagementKeyAccessEnabledRiskPolicyConfiguration = "AzureDocumentDbDatabaseAccountManagementKeyAccessEnabledRiskPolicyConfiguration",
    AzureDocumentDbDatabaseAccountModel = "AzureDocumentDbDatabaseAccountModel",
    AzureDocumentDbDatabaseAccountMongoDbDatabase = "AzureDocumentDbDatabaseAccountMongoDbDatabase",
    AzureDocumentDbDatabaseAccountMongoDbDatabaseModel = "AzureDocumentDbDatabaseAccountMongoDbDatabaseModel",
    AzureDocumentDbDatabaseAccountMongoDbDatabaseProfile = "AzureDocumentDbDatabaseAccountMongoDbDatabaseProfile",
    AzureDocumentDbDatabaseAccountPrivateEndpointNotExistsCodeRisk = "AzureDocumentDbDatabaseAccountPrivateEndpointNotExistsCodeRisk",
    AzureDocumentDbDatabaseAccountPrivateEndpointNotExistsCodeRiskModel = "AzureDocumentDbDatabaseAccountPrivateEndpointNotExistsCodeRiskModel",
    AzureDocumentDbDatabaseAccountPrivateEndpointNotExistsRisk = "AzureDocumentDbDatabaseAccountPrivateEndpointNotExistsRisk",
    AzureDocumentDbDatabaseAccountPrivateEndpointNotExistsRiskModel = "AzureDocumentDbDatabaseAccountPrivateEndpointNotExistsRiskModel",
    AzureDocumentDbDatabaseAccountPrivateEndpointNotExistsRiskPolicyConfiguration = "AzureDocumentDbDatabaseAccountPrivateEndpointNotExistsRiskPolicyConfiguration",
    AzureDocumentDbDatabaseAccountProfile = "AzureDocumentDbDatabaseAccountProfile",
    AzureDocumentDbDatabaseAccountResource = "AzureDocumentDbDatabaseAccountResource",
    AzureDocumentDbDatabaseAccountSqlDatabase = "AzureDocumentDbDatabaseAccountSqlDatabase",
    AzureDocumentDbDatabaseAccountSqlDatabaseModel = "AzureDocumentDbDatabaseAccountSqlDatabaseModel",
    AzureDocumentDbDatabaseAccountSqlDatabaseProfile = "AzureDocumentDbDatabaseAccountSqlDatabaseProfile",
    AzureDocumentDbDatabaseAccountStateNetwork = "AzureDocumentDbDatabaseAccountStateNetwork",
    AzureDocumentDbDatabaseAccountStateNetworkFirewallRuleData = "AzureDocumentDbDatabaseAccountStateNetworkFirewallRuleData",
    AzureDocumentDbDatabaseAccountTable = "AzureDocumentDbDatabaseAccountTable",
    AzureDocumentDbDatabaseAccountTableModel = "AzureDocumentDbDatabaseAccountTableModel",
    AzureDocumentDbDatabaseAccountTableProfile = "AzureDocumentDbDatabaseAccountTableProfile",
    AzureDocumentDbDatabaseAccountType = "AzureDocumentDbDatabaseAccountType",
    AzureDocumentDbDatabaseAccountWideRangeInboundCodeRisk = "AzureDocumentDbDatabaseAccountWideRangeInboundCodeRisk",
    AzureDocumentDbDatabaseAccountWideRangeInboundCodeRiskModel = "AzureDocumentDbDatabaseAccountWideRangeInboundCodeRiskModel",
    AzureDocumentDbDatabaseAccountWideRangeInboundRisk = "AzureDocumentDbDatabaseAccountWideRangeInboundRisk",
    AzureDocumentDbDatabaseAccountWideRangeInboundRiskModel = "AzureDocumentDbDatabaseAccountWideRangeInboundRiskModel",
    AzureDocumentDbDatabaseAccountWideRangeInboundRiskPolicyConfiguration = "AzureDocumentDbDatabaseAccountWideRangeInboundRiskPolicyConfiguration",
    AzureDocumentDbGarnetClusterGenericResource = "AzureDocumentDbGarnetClusterGenericResource",
    AzureDocumentDbMongoClusterGenericResource = "AzureDocumentDbMongoClusterGenericResource",
    AzureDocumentDbThroughputPoolGenericResource = "AzureDocumentDbThroughputPoolGenericResource",
    AzureDomainRegistrationDomainGenericResource = "AzureDomainRegistrationDomainGenericResource",
    AzureDurableTaskNamespaceGenericResource = "AzureDurableTaskNamespaceGenericResource",
    AzureDurableTaskSchedulerGenericResource = "AzureDurableTaskSchedulerGenericResource",
    AzureDynatraceObservabilityMonitorGenericResource = "AzureDynatraceObservabilityMonitorGenericResource",
    AzureEasmWorkspaceGenericResource = "AzureEasmWorkspaceGenericResource",
    AzureEdgeCapabilityListGenericResource = "AzureEdgeCapabilityListGenericResource",
    AzureEdgeConfigurationGenericResource = "AzureEdgeConfigurationGenericResource",
    AzureEdgeDeploymentTargetGenericResource = "AzureEdgeDeploymentTargetGenericResource",
    AzureEdgeHierarchyListGenericResource = "AzureEdgeHierarchyListGenericResource",
    AzureEdgeOrderAddressGenericResource = "AzureEdgeOrderAddressGenericResource",
    AzureEdgeOrderBootstrapConfigurationGenericResource = "AzureEdgeOrderBootstrapConfigurationGenericResource",
    AzureEdgeOrderOrderItemGenericResource = "AzureEdgeOrderOrderItemGenericResource",
    AzureEdgeSchemaGenericResource = "AzureEdgeSchemaGenericResource",
    AzureEdgeSolutionBindingGenericResource = "AzureEdgeSolutionBindingGenericResource",
    AzureEdgeSolutionGenericResource = "AzureEdgeSolutionGenericResource",
    AzureEdgeWinfieldGenericResource = "AzureEdgeWinfieldGenericResource",
    AzureElasticMonitorGenericResource = "AzureElasticMonitorGenericResource",
    AzureElasticSanElasticSanGenericResource = "AzureElasticSanElasticSanGenericResource",
    AzureElasticSanElasticSansVolumeGroupGenericResource = "AzureElasticSanElasticSansVolumeGroupGenericResource",
    AzureEnterpriseSupportEnterpriseSupportGenericResource = "AzureEnterpriseSupportEnterpriseSupportGenericResource",
    AzureEntity = "AzureEntity",
    AzureEntityModel = "AzureEntityModel",
    AzureEntityPathType = "AzureEntityPathType",
    AzureEvent = "AzureEvent",
    AzureEventAnalysisServiceConfiguration = "AzureEventAnalysisServiceConfiguration",
    AzureEventGridDomainGenericResource = "AzureEventGridDomainGenericResource",
    AzureEventGridNamespaceGenericResource = "AzureEventGridNamespaceGenericResource",
    AzureEventGridPartnerConfigurationGenericResource = "AzureEventGridPartnerConfigurationGenericResource",
    AzureEventGridPartnerDestinationGenericResource = "AzureEventGridPartnerDestinationGenericResource",
    AzureEventGridPartnerNamespaceGenericResource = "AzureEventGridPartnerNamespaceGenericResource",
    AzureEventGridPartnerRegistrationGenericResource = "AzureEventGridPartnerRegistrationGenericResource",
    AzureEventGridPartnerTopicGenericResource = "AzureEventGridPartnerTopicGenericResource",
    AzureEventGridSystemTopicGenericResource = "AzureEventGridSystemTopicGenericResource",
    AzureEventGridTopicGenericResource = "AzureEventGridTopicGenericResource",
    AzureEventHubClusterGenericResource = "AzureEventHubClusterGenericResource",
    AzureEventHubNamespace = "AzureEventHubNamespace",
    AzureEventHubNamespaceEventHub = "AzureEventHubNamespaceEventHub",
    AzureEventHubNamespaceEventHubModel = "AzureEventHubNamespaceEventHubModel",
    AzureEventHubNamespaceEventHubProfile = "AzureEventHubNamespaceEventHubProfile",
    AzureEventHubNamespaceEventHubStatus = "AzureEventHubNamespaceEventHubStatus",
    AzureEventHubNamespaceGenericResource = "AzureEventHubNamespaceGenericResource",
    AzureEventHubNamespaceInboundExternalWideRangeCodeRisk = "AzureEventHubNamespaceInboundExternalWideRangeCodeRisk",
    AzureEventHubNamespaceInboundExternalWideRangeCodeRiskModel = "AzureEventHubNamespaceInboundExternalWideRangeCodeRiskModel",
    AzureEventHubNamespaceInboundExternalWideRangeRisk = "AzureEventHubNamespaceInboundExternalWideRangeRisk",
    AzureEventHubNamespaceInboundExternalWideRangeRiskModel = "AzureEventHubNamespaceInboundExternalWideRangeRiskModel",
    AzureEventHubNamespaceInboundExternalWideRangeRiskPolicyConfiguration = "AzureEventHubNamespaceInboundExternalWideRangeRiskPolicyConfiguration",
    AzureEventHubNamespaceModel = "AzureEventHubNamespaceModel",
    AzureEventHubNamespaceNetworkAccessType = "AzureEventHubNamespaceNetworkAccessType",
    AzureEventHubNamespaceProfile = "AzureEventHubNamespaceProfile",
    AzureEventHubNamespaceSku = "AzureEventHubNamespaceSku",
    AzureEventHubNamespaceStateNetwork = "AzureEventHubNamespaceStateNetwork",
    AzureEventHubNamespaceStateNetworkFirewallRuleData = "AzureEventHubNamespaceStateNetworkFirewallRuleData",
    AzureEventHubNamespaceStatus = "AzureEventHubNamespaceStatus",
    AzureEventSyncerConfigurationSection = "AzureEventSyncerConfigurationSection",
    AzureExcessivePermissionApplicationServicePrincipalRisk = "AzureExcessivePermissionApplicationServicePrincipalRisk",
    AzureExcessivePermissionApplicationServicePrincipalRiskModel = "AzureExcessivePermissionApplicationServicePrincipalRiskModel",
    AzureExcessivePermissionApplicationServicePrincipalRiskPolicyConfiguration = "AzureExcessivePermissionApplicationServicePrincipalRiskPolicyConfiguration",
    AzureExcessivePermissionGroupRisk = "AzureExcessivePermissionGroupRisk",
    AzureExcessivePermissionGroupRiskModel = "AzureExcessivePermissionGroupRiskModel",
    AzureExcessivePermissionGroupRiskPolicyConfiguration = "AzureExcessivePermissionGroupRiskPolicyConfiguration",
    AzureExcessivePermissionManagedIdentityServicePrincipalRisk = "AzureExcessivePermissionManagedIdentityServicePrincipalRisk",
    AzureExcessivePermissionManagedIdentityServicePrincipalRiskModel = "AzureExcessivePermissionManagedIdentityServicePrincipalRiskModel",
    AzureExcessivePermissionManagedIdentityServicePrincipalRiskPolicyConfiguration = "AzureExcessivePermissionManagedIdentityServicePrincipalRiskPolicyConfiguration",
    AzureExcessivePermissionPrincipalRisk = "AzureExcessivePermissionPrincipalRisk",
    AzureExcessivePermissionPrincipalRiskModel = "AzureExcessivePermissionPrincipalRiskModel",
    AzureExcessivePermissionPrincipalRiskModelInfo = "AzureExcessivePermissionPrincipalRiskModelInfo",
    AzureExcessivePermissionPrincipalRiskModelInfoType = "AzureExcessivePermissionPrincipalRiskModelInfoType",
    AzureExcessivePermissionPrincipalRiskModelPermittersType = "AzureExcessivePermissionPrincipalRiskModelPermittersType",
    AzureExcessivePermissionPrincipalRiskModelResolutionPermittersType = "AzureExcessivePermissionPrincipalRiskModelResolutionPermittersType",
    AzureExcessivePermissionPrincipalRiskModelResolutionType = "AzureExcessivePermissionPrincipalRiskModelResolutionType",
    AzureExcessivePermissionPrincipalRiskModelRoleAssignmentResourcesInfo = "AzureExcessivePermissionPrincipalRiskModelRoleAssignmentResourcesInfo",
    AzureExcessivePermissionPrincipalRiskPolicyConfiguration = "AzureExcessivePermissionPrincipalRiskPolicyConfiguration",
    AzureExcessivePermissionPrincipalRiskPolicyUpdateAuditEventInactivePrincipalDeleteGroupMembershipEnabledChange = "AzureExcessivePermissionPrincipalRiskPolicyUpdateAuditEventInactivePrincipalDeleteGroupMembershipEnabledChange",
    AzureExcessivePermissionPrincipalRiskRoleAssignmentResourceResolution = "AzureExcessivePermissionPrincipalRiskRoleAssignmentResourceResolution",
    AzureExcessivePermissionPrincipalRiskScopeRoleAssignmentResourceData = "AzureExcessivePermissionPrincipalRiskScopeRoleAssignmentResourceData",
    AzureExcessivePermissionPrincipalRiskSensitiveResources = "AzureExcessivePermissionPrincipalRiskSensitiveResources",
    AzureExcessivePermissionUserRisk = "AzureExcessivePermissionUserRisk",
    AzureExcessivePermissionUserRiskModel = "AzureExcessivePermissionUserRiskModel",
    AzureExcessivePermissionUserRiskPolicyConfiguration = "AzureExcessivePermissionUserRiskPolicyConfiguration",
    AzureExperimentationExperimentWorkspaceGenericResource = "AzureExperimentationExperimentWorkspaceGenericResource",
    AzureExtendedLocationCustomLocationGenericResource = "AzureExtendedLocationCustomLocationGenericResource",
    AzureExtendedLocationCustomLocationsResourceSyncRuleGenericResource = "AzureExtendedLocationCustomLocationsResourceSyncRuleGenericResource",
    AzureExtensionResource = "AzureExtensionResource",
    AzureExtensionResourceTypeMetadata = "AzureExtensionResourceTypeMetadata",
    AzureFabricCapacityGenericResource = "AzureFabricCapacityGenericResource",
    AzureFabricPrivateLinkServicesForFabricGenericResource = "AzureFabricPrivateLinkServicesForFabricGenericResource",
    AzureFairfieldGardensProvisioningResourceGenericResource = "AzureFairfieldGardensProvisioningResourceGenericResource",
    AzureFairfieldGardensProvisioningResourcesProvisioningPolicyGenericResource = "AzureFairfieldGardensProvisioningResourcesProvisioningPolicyGenericResource",
    AzureFalconNamespaceGenericResource = "AzureFalconNamespaceGenericResource",
    AzureFileSharesFileShareGenericResource = "AzureFileSharesFileShareGenericResource",
    AzureFleetFleetGenericResource = "AzureFleetFleetGenericResource",
    AzureFluidRelayFluidRelayServerGenericResource = "AzureFluidRelayFluidRelayServerGenericResource",
    AzureFolderConfiguration = "AzureFolderConfiguration",
    AzureFolderConfigurationAnalysis = "AzureFolderConfigurationAnalysis",
    AzureFolderConfigurationSections = "AzureFolderConfigurationSections",
    AzureFootprintMonitoringProfileGenericResource = "AzureFootprintMonitoringProfileGenericResource",
    AzureGitHubNetworkNetworkSettingGenericResource = "AzureGitHubNetworkNetworkSettingGenericResource",
    AzureGraphServicesAccountGenericResource = "AzureGraphServicesAccountGenericResource",
    AzureGuestUserAdministratorCodeRisk = "AzureGuestUserAdministratorCodeRisk",
    AzureGuestUserAdministratorCodeRiskModel = "AzureGuestUserAdministratorCodeRiskModel",
    AzureGuestUserAdministratorRisk = "AzureGuestUserAdministratorRisk",
    AzureGuestUserAdministratorRiskModel = "AzureGuestUserAdministratorRiskModel",
    AzureGuestUserAdministratorRiskPolicyConfiguration = "AzureGuestUserAdministratorRiskPolicyConfiguration",
    AzureHanaOnHanaInstanceGenericResource = "AzureHanaOnHanaInstanceGenericResource",
    AzureHardwareOrderGenericResource = "AzureHardwareOrderGenericResource",
    AzureHardwareSecurityModulesCloudHsmClusterGenericResource = "AzureHardwareSecurityModulesCloudHsmClusterGenericResource",
    AzureHardwareSecurityModulesDedicatedhsmGenericResource = "AzureHardwareSecurityModulesDedicatedhsmGenericResource",
    AzureHardwareSecurityModulesDedicatedhsmGenericResourceModel = "AzureHardwareSecurityModulesDedicatedhsmGenericResourceModel",
    AzureHdInsightClusterGenericResource = "AzureHdInsightClusterGenericResource",
    AzureHdInsightClusterPoolGenericResource = "AzureHdInsightClusterPoolGenericResource",
    AzureHdInsightClusterPoolsClusterGenericResource = "AzureHdInsightClusterPoolsClusterGenericResource",
    AzureHealthBotHealthBotGenericResource = "AzureHealthBotHealthBotGenericResource",
    AzureHealthcareApisServiceGenericResource = "AzureHealthcareApisServiceGenericResource",
    AzureHealthcareApisServicesPrivateEndpointConnectionGenericResource = "AzureHealthcareApisServicesPrivateEndpointConnectionGenericResource",
    AzureHealthcareApisWorkspaceGenericResource = "AzureHealthcareApisWorkspaceGenericResource",
    AzureHealthcareApisWorkspacesAnalyticsconnectorGenericResource = "AzureHealthcareApisWorkspacesAnalyticsconnectorGenericResource",
    AzureHealthcareApisWorkspacesDicomserviceGenericResource = "AzureHealthcareApisWorkspacesDicomserviceGenericResource",
    AzureHealthcareApisWorkspacesFhirserviceGenericResource = "AzureHealthcareApisWorkspacesFhirserviceGenericResource",
    AzureHealthcareApisWorkspacesIotconnectorGenericResource = "AzureHealthcareApisWorkspacesIotconnectorGenericResource",
    AzureHealthDataAiServicesDeidServiceGenericResource = "AzureHealthDataAiServicesDeidServiceGenericResource",
    AzureHealthModelHealthmodelGenericResource = "AzureHealthModelHealthmodelGenericResource",
    AzureHybridCloudCloudConnectionGenericResource = "AzureHybridCloudCloudConnectionGenericResource",
    AzureHybridCloudCloudConnectorGenericResource = "AzureHybridCloudCloudConnectorGenericResource",
    AzureHybridComputeGatewayGenericResource = "AzureHybridComputeGatewayGenericResource",
    AzureHybridComputeLicenseGenericResource = "AzureHybridComputeLicenseGenericResource",
    AzureHybridComputeMachineGenericResource = "AzureHybridComputeMachineGenericResource",
    AzureHybridComputeMachinesExtensionGenericResource = "AzureHybridComputeMachinesExtensionGenericResource",
    AzureHybridComputeMachinesLicenseProfileGenericResource = "AzureHybridComputeMachinesLicenseProfileGenericResource",
    AzureHybridComputeMachinesRuncommandGenericResource = "AzureHybridComputeMachinesRuncommandGenericResource",
    AzureHybridComputePrivateLinkScopeGenericResource = "AzureHybridComputePrivateLinkScopeGenericResource",
    AzureHybridConnectivityPublicCloudConnectorGenericResource = "AzureHybridConnectivityPublicCloudConnectorGenericResource",
    AzureHybridContainerServiceProvisionedClusterGenericResource = "AzureHybridContainerServiceProvisionedClusterGenericResource",
    AzureHybridContainerServiceProvisionedClustersAgentPoolGenericResource = "AzureHybridContainerServiceProvisionedClustersAgentPoolGenericResource",
    AzureHybridContainerServiceStorageSpaceGenericResource = "AzureHybridContainerServiceStorageSpaceGenericResource",
    AzureHybridContainerServiceVirtualNetworkGenericResource = "AzureHybridContainerServiceVirtualNetworkGenericResource",
    AzureHybridNetworkConfigurationGroupValueGenericResource = "AzureHybridNetworkConfigurationGroupValueGenericResource",
    AzureHybridNetworkDeviceGenericResource = "AzureHybridNetworkDeviceGenericResource",
    AzureHybridNetworkNetworkFunctionGenericResource = "AzureHybridNetworkNetworkFunctionGenericResource",
    AzureHybridNetworkPublisherGenericResource = "AzureHybridNetworkPublisherGenericResource",
    AzureHybridNetworkPublishersArtifactStoreGenericResource = "AzureHybridNetworkPublishersArtifactStoreGenericResource",
    AzureHybridNetworkPublishersArtifactStoresArtifactManifestGenericResource = "AzureHybridNetworkPublishersArtifactStoresArtifactManifestGenericResource",
    AzureHybridNetworkPublishersConfigurationGroupSchemaGenericResource = "AzureHybridNetworkPublishersConfigurationGroupSchemaGenericResource",
    AzureHybridNetworkPublishersNetworkFunctionDefinitionGroupGenericResource = "AzureHybridNetworkPublishersNetworkFunctionDefinitionGroupGenericResource",
    AzureHybridNetworkPublishersNetworkFunctionDefinitionGroupsNetworkFunctionDefinitionVersionGenericResource = "AzureHybridNetworkPublishersNetworkFunctionDefinitionGroupsNetworkFunctionDefinitionVersionGenericResource",
    AzureHybridNetworkPublishersNetworkFunctionDefinitionGroupsPreviewSubscriptionGenericResource = "AzureHybridNetworkPublishersNetworkFunctionDefinitionGroupsPreviewSubscriptionGenericResource",
    AzureHybridNetworkPublishersNetworkServiceDesignGroupGenericResource = "AzureHybridNetworkPublishersNetworkServiceDesignGroupGenericResource",
    AzureHybridNetworkPublishersNetworkServiceDesignGroupsNetworkServiceDesignVersionGenericResource = "AzureHybridNetworkPublishersNetworkServiceDesignGroupsNetworkServiceDesignVersionGenericResource",
    AzureHybridNetworkServiceManagementContainerGenericResource = "AzureHybridNetworkServiceManagementContainerGenericResource",
    AzureHybridNetworkServiceManagementContainersRolloutSequenceGenericResource = "AzureHybridNetworkServiceManagementContainersRolloutSequenceGenericResource",
    AzureHybridNetworkServiceManagementContainersRolloutTierGenericResource = "AzureHybridNetworkServiceManagementContainersRolloutTierGenericResource",
    AzureHybridNetworkServiceManagementContainersUpdateSpecificationGenericResource = "AzureHybridNetworkServiceManagementContainersUpdateSpecificationGenericResource",
    AzureHybridNetworkServiceManagementContainersUpdateSpecificationsRolloutGenericResource = "AzureHybridNetworkServiceManagementContainersUpdateSpecificationsRolloutGenericResource",
    AzureHybridNetworkSiteGenericResource = "AzureHybridNetworkSiteGenericResource",
    AzureHybridNetworkSiteNetworkServiceGenericResource = "AzureHybridNetworkSiteNetworkServiceGenericResource",
    AzureImageTestingForLinuxJobGenericResource = "AzureImageTestingForLinuxJobGenericResource",
    AzureImageTestingForLinuxJobTemplateGenericResource = "AzureImageTestingForLinuxJobTemplateGenericResource",
    AzureInboundExternalComputeVirtualMachineRisk = "AzureInboundExternalComputeVirtualMachineRisk",
    AzureInboundExternalComputeVirtualMachineRiskModel = "AzureInboundExternalComputeVirtualMachineRiskModel",
    AzureInboundExternalComputeVirtualMachineRiskPolicyConfiguration = "AzureInboundExternalComputeVirtualMachineRiskPolicyConfiguration",
    AzureInboundExternalComputeVirtualMachineScaleSetRisk = "AzureInboundExternalComputeVirtualMachineScaleSetRisk",
    AzureInboundExternalComputeVirtualMachineScaleSetRiskModel = "AzureInboundExternalComputeVirtualMachineScaleSetRiskModel",
    AzureInboundExternalComputeVirtualMachineScaleSetRiskPolicyConfiguration = "AzureInboundExternalComputeVirtualMachineScaleSetRiskPolicyConfiguration",
    AzureInboundExternalResourceRisk = "AzureInboundExternalResourceRisk",
    AzureInformaticaDataManagementOrganizationGenericResource = "AzureInformaticaDataManagementOrganizationGenericResource",
    AzureInsightsActionGroupGenericResource = "AzureInsightsActionGroupGenericResource",
    AzureInsightsActivityLogAlertGenericResource = "AzureInsightsActivityLogAlertGenericResource",
    AzureInsightsAutoscaleSettingGenericResource = "AzureInsightsAutoscaleSettingGenericResource",
    AzureInsightsComponentGenericResource = "AzureInsightsComponentGenericResource",
    AzureInsightsDataCollectionEndpointGenericResource = "AzureInsightsDataCollectionEndpointGenericResource",
    AzureInsightsDataCollectionRuleGenericResource = "AzureInsightsDataCollectionRuleGenericResource",
    AzureInsightsDiagnosticSettings = "AzureInsightsDiagnosticSettings",
    AzureInsightsDiagnosticSettingsModel = "AzureInsightsDiagnosticSettingsModel",
    AzureInsightsDiagnosticSettingsProfile = "AzureInsightsDiagnosticSettingsProfile",
    AzureInsightsDiagnosticSettingsStorageAccountBlobContainerDefaultEncryptionCodeRisk = "AzureInsightsDiagnosticSettingsStorageAccountBlobContainerDefaultEncryptionCodeRisk",
    AzureInsightsDiagnosticSettingsStorageAccountBlobContainerDefaultEncryptionCodeRiskModel = "AzureInsightsDiagnosticSettingsStorageAccountBlobContainerDefaultEncryptionCodeRiskModel",
    AzureInsightsDiagnosticSettingsStorageAccountBlobContainerDefaultEncryptionRisk = "AzureInsightsDiagnosticSettingsStorageAccountBlobContainerDefaultEncryptionRisk",
    AzureInsightsDiagnosticSettingsStorageAccountBlobContainerDefaultEncryptionRiskModel = "AzureInsightsDiagnosticSettingsStorageAccountBlobContainerDefaultEncryptionRiskModel",
    AzureInsightsDiagnosticSettingsStorageAccountBlobContainerDefaultEncryptionRiskPolicyConfiguration = "AzureInsightsDiagnosticSettingsStorageAccountBlobContainerDefaultEncryptionRiskPolicyConfiguration",
    AzureInsightsDiagnosticSettingsStorageAccountBlobContainerPublicAccessEnabledCodeRisk = "AzureInsightsDiagnosticSettingsStorageAccountBlobContainerPublicAccessEnabledCodeRisk",
    AzureInsightsDiagnosticSettingsStorageAccountBlobContainerPublicAccessEnabledCodeRiskModel = "AzureInsightsDiagnosticSettingsStorageAccountBlobContainerPublicAccessEnabledCodeRiskModel",
    AzureInsightsDiagnosticSettingsStorageAccountBlobContainerPublicAccessEnabledRisk = "AzureInsightsDiagnosticSettingsStorageAccountBlobContainerPublicAccessEnabledRisk",
    AzureInsightsDiagnosticSettingsStorageAccountBlobContainerPublicAccessEnabledRiskModel = "AzureInsightsDiagnosticSettingsStorageAccountBlobContainerPublicAccessEnabledRiskModel",
    AzureInsightsDiagnosticSettingsStorageAccountBlobContainerPublicAccessEnabledRiskPolicyConfiguration = "AzureInsightsDiagnosticSettingsStorageAccountBlobContainerPublicAccessEnabledRiskPolicyConfiguration",
    AzureInsightsDiagnosticSettingsTenantLogCategoryAdministrativeNotExistsRisk = "AzureInsightsDiagnosticSettingsTenantLogCategoryAdministrativeNotExistsRisk",
    AzureInsightsDiagnosticSettingsTenantLogCategoryAdministrativeNotExistsRiskModel = "AzureInsightsDiagnosticSettingsTenantLogCategoryAdministrativeNotExistsRiskModel",
    AzureInsightsDiagnosticSettingsTenantLogCategoryAdministrativeNotExistsRiskPolicyConfiguration = "AzureInsightsDiagnosticSettingsTenantLogCategoryAdministrativeNotExistsRiskPolicyConfiguration",
    AzureInsightsGuestDiagnosticSettingGenericResource = "AzureInsightsGuestDiagnosticSettingGenericResource",
    AzureInsightsMetricAlertGenericResource = "AzureInsightsMetricAlertGenericResource",
    AzureInsightsPrivateLinkScopeGenericResource = "AzureInsightsPrivateLinkScopeGenericResource",
    AzureInsightsScheduledQueryRuleGenericResource = "AzureInsightsScheduledQueryRuleGenericResource",
    AzureInsightsWebtestGenericResource = "AzureInsightsWebtestGenericResource",
    AzureInsightsWorkbookGenericResource = "AzureInsightsWorkbookGenericResource",
    AzureInsightsWorkbookGenericResourceModel = "AzureInsightsWorkbookGenericResourceModel",
    AzureInsightsWorkbookTemplateGenericResource = "AzureInsightsWorkbookTemplateGenericResource",
    AzureIntegrationSpacesSpaceGenericResource = "AzureIntegrationSpacesSpaceGenericResource",
    AzureIntegrationSpacesSpacesApplicationGenericResource = "AzureIntegrationSpacesSpacesApplicationGenericResource",
    AzureIotCentralIoTAppGenericResource = "AzureIotCentralIoTAppGenericResource",
    AzureIoTFirmwareDefenseWorkspaceGenericResource = "AzureIoTFirmwareDefenseWorkspaceGenericResource",
    AzureIoTOperationsDataProcessorInstanceGenericResource = "AzureIoTOperationsDataProcessorInstanceGenericResource",
    AzureIoTOperationsDataProcessorInstancesDatasetGenericResource = "AzureIoTOperationsDataProcessorInstancesDatasetGenericResource",
    AzureIoTOperationsDataProcessorInstancesPipelineGenericResource = "AzureIoTOperationsDataProcessorInstancesPipelineGenericResource",
    AzureIoTOperationsInstanceGenericResource = "AzureIoTOperationsInstanceGenericResource",
    AzureIoTOperationsMqMqBrokerAuthenticationGenericResource = "AzureIoTOperationsMqMqBrokerAuthenticationGenericResource",
    AzureIoTOperationsMqMqBrokerAuthorizationGenericResource = "AzureIoTOperationsMqMqBrokerAuthorizationGenericResource",
    AzureIoTOperationsMqMqBrokerGenericResource = "AzureIoTOperationsMqMqBrokerGenericResource",
    AzureIoTOperationsMqMqBrokerListenerGenericResource = "AzureIoTOperationsMqMqBrokerListenerGenericResource",
    AzureIoTOperationsMqMqDataLakeConnectorGenericResource = "AzureIoTOperationsMqMqDataLakeConnectorGenericResource",
    AzureIoTOperationsMqMqDataLakeConnectorTopicMapGenericResource = "AzureIoTOperationsMqMqDataLakeConnectorTopicMapGenericResource",
    AzureIoTOperationsMqMqDiagnosticServiceGenericResource = "AzureIoTOperationsMqMqDiagnosticServiceGenericResource",
    AzureIoTOperationsMqMqGenericResource = "AzureIoTOperationsMqMqGenericResource",
    AzureIoTOperationsMqMqKafkaConnectorGenericResource = "AzureIoTOperationsMqMqKafkaConnectorGenericResource",
    AzureIoTOperationsMqMqKafkaConnectorTopicMapGenericResource = "AzureIoTOperationsMqMqKafkaConnectorTopicMapGenericResource",
    AzureIoTOperationsMqMqMqttBridgeConnectorGenericResource = "AzureIoTOperationsMqMqMqttBridgeConnectorGenericResource",
    AzureIoTOperationsMqMqMqttBridgeConnectorTopicMapGenericResource = "AzureIoTOperationsMqMqMqttBridgeConnectorTopicMapGenericResource",
    AzureIoTOperationsOrchestratorInstanceGenericResource = "AzureIoTOperationsOrchestratorInstanceGenericResource",
    AzureIoTOperationsOrchestratorSolutionGenericResource = "AzureIoTOperationsOrchestratorSolutionGenericResource",
    AzureIoTOperationsOrchestratorTargetGenericResource = "AzureIoTOperationsOrchestratorTargetGenericResource",
    AzureKeyVaultHsmPoolGenericResource = "AzureKeyVaultHsmPoolGenericResource",
    AzureKeyVaultManagedHsMGenericResource = "AzureKeyVaultManagedHsMGenericResource",
    AzureKeyVaultVault = "AzureKeyVaultVault",
    AzureKeyVaultVaultAccessPolicy = "AzureKeyVaultVaultAccessPolicy",
    AzureKeyVaultVaultCertificate = "AzureKeyVaultVaultCertificate",
    AzureKeyVaultVaultCertificateModel = "AzureKeyVaultVaultCertificateModel",
    AzureKeyVaultVaultCertificateModelFilters = "AzureKeyVaultVaultCertificateModelFilters",
    AzureKeyVaultVaultCertificateNotRotatedRisk = "AzureKeyVaultVaultCertificateNotRotatedRisk",
    AzureKeyVaultVaultCertificateNotRotatedRiskModel = "AzureKeyVaultVaultCertificateNotRotatedRiskModel",
    AzureKeyVaultVaultCertificateNotRotatedRiskPolicyConfiguration = "AzureKeyVaultVaultCertificateNotRotatedRiskPolicyConfiguration",
    AzureKeyVaultVaultCertificateNotRotatedRiskPolicyUpdateAuditEventCertificateRotationTimeFrameChange = "AzureKeyVaultVaultCertificateNotRotatedRiskPolicyUpdateAuditEventCertificateRotationTimeFrameChange",
    AzureKeyVaultVaultCertificatePermission = "AzureKeyVaultVaultCertificatePermission",
    AzureKeyVaultVaultCertificateProfile = "AzureKeyVaultVaultCertificateProfile",
    AzureKeyVaultVaultCertificateVersion = "AzureKeyVaultVaultCertificateVersion",
    AzureKeyVaultVaultEventDisabledCodeRisk = "AzureKeyVaultVaultEventDisabledCodeRisk",
    AzureKeyVaultVaultEventDisabledCodeRiskModel = "AzureKeyVaultVaultEventDisabledCodeRiskModel",
    AzureKeyVaultVaultEventDisabledRisk = "AzureKeyVaultVaultEventDisabledRisk",
    AzureKeyVaultVaultEventDisabledRiskModel = "AzureKeyVaultVaultEventDisabledRiskModel",
    AzureKeyVaultVaultEventDisabledRiskPolicyConfiguration = "AzureKeyVaultVaultEventDisabledRiskPolicyConfiguration",
    AzureKeyVaultVaultGenericResource = "AzureKeyVaultVaultGenericResource",
    AzureKeyVaultVaultKey = "AzureKeyVaultVaultKey",
    AzureKeyVaultVaultKeyEllipticCurveType = "AzureKeyVaultVaultKeyEllipticCurveType",
    AzureKeyVaultVaultKeyExpirationDisabledCodeRisk = "AzureKeyVaultVaultKeyExpirationDisabledCodeRisk",
    AzureKeyVaultVaultKeyExpirationDisabledCodeRiskModel = "AzureKeyVaultVaultKeyExpirationDisabledCodeRiskModel",
    AzureKeyVaultVaultKeyExpirationDisabledRisk = "AzureKeyVaultVaultKeyExpirationDisabledRisk",
    AzureKeyVaultVaultKeyExpirationDisabledRiskModel = "AzureKeyVaultVaultKeyExpirationDisabledRiskModel",
    AzureKeyVaultVaultKeyExpirationDisabledRiskPolicyConfiguration = "AzureKeyVaultVaultKeyExpirationDisabledRiskPolicyConfiguration",
    AzureKeyVaultVaultKeyInfo = "AzureKeyVaultVaultKeyInfo",
    AzureKeyVaultVaultKeyModel = "AzureKeyVaultVaultKeyModel",
    AzureKeyVaultVaultKeyNotRotatedCodeRisk = "AzureKeyVaultVaultKeyNotRotatedCodeRisk",
    AzureKeyVaultVaultKeyNotRotatedCodeRiskModel = "AzureKeyVaultVaultKeyNotRotatedCodeRiskModel",
    AzureKeyVaultVaultKeyNotRotatedRisk = "AzureKeyVaultVaultKeyNotRotatedRisk",
    AzureKeyVaultVaultKeyNotRotatedRiskModel = "AzureKeyVaultVaultKeyNotRotatedRiskModel",
    AzureKeyVaultVaultKeyNotRotatedRiskPolicyConfiguration = "AzureKeyVaultVaultKeyNotRotatedRiskPolicyConfiguration",
    AzureKeyVaultVaultKeyNotRotatedRiskPolicyUpdateAuditEventKeyRotationTimeFrameChange = "AzureKeyVaultVaultKeyNotRotatedRiskPolicyUpdateAuditEventKeyRotationTimeFrameChange",
    AzureKeyVaultVaultKeyOperation = "AzureKeyVaultVaultKeyOperation",
    AzureKeyVaultVaultKeyPermission = "AzureKeyVaultVaultKeyPermission",
    AzureKeyVaultVaultKeyProfile = "AzureKeyVaultVaultKeyProfile",
    AzureKeyVaultVaultKeyRotationDisabledCodeRisk = "AzureKeyVaultVaultKeyRotationDisabledCodeRisk",
    AzureKeyVaultVaultKeyRotationDisabledCodeRiskModel = "AzureKeyVaultVaultKeyRotationDisabledCodeRiskModel",
    AzureKeyVaultVaultKeyRotationDisabledRisk = "AzureKeyVaultVaultKeyRotationDisabledRisk",
    AzureKeyVaultVaultKeyRotationDisabledRiskModel = "AzureKeyVaultVaultKeyRotationDisabledRiskModel",
    AzureKeyVaultVaultKeyRotationDisabledRiskPolicyConfiguration = "AzureKeyVaultVaultKeyRotationDisabledRiskPolicyConfiguration",
    AzureKeyVaultVaultKeyRotationDisabledRiskPolicyUpdateAuditEventKeyRotationTimeFrameChange = "AzureKeyVaultVaultKeyRotationDisabledRiskPolicyUpdateAuditEventKeyRotationTimeFrameChange",
    AzureKeyVaultVaultKeyRotationPolicy = "AzureKeyVaultVaultKeyRotationPolicy",
    AzureKeyVaultVaultKeyRotationPolicyType = "AzureKeyVaultVaultKeyRotationPolicyType",
    AzureKeyVaultVaultKeyType = "AzureKeyVaultVaultKeyType",
    AzureKeyVaultVaultKeyVersion = "AzureKeyVaultVaultKeyVersion",
    AzureKeyVaultVaultModel = "AzureKeyVaultVaultModel",
    AzureKeyVaultVaultObject = "AzureKeyVaultVaultObject",
    AzureKeyVaultVaultObjectModel = "AzureKeyVaultVaultObjectModel",
    AzureKeyVaultVaultObjectModelFilters = "AzureKeyVaultVaultObjectModelFilters",
    AzureKeyVaultVaultPrivateEndpointNotExistsCodeRisk = "AzureKeyVaultVaultPrivateEndpointNotExistsCodeRisk",
    AzureKeyVaultVaultPrivateEndpointNotExistsCodeRiskModel = "AzureKeyVaultVaultPrivateEndpointNotExistsCodeRiskModel",
    AzureKeyVaultVaultPrivateEndpointNotExistsRisk = "AzureKeyVaultVaultPrivateEndpointNotExistsRisk",
    AzureKeyVaultVaultPrivateEndpointNotExistsRiskModel = "AzureKeyVaultVaultPrivateEndpointNotExistsRiskModel",
    AzureKeyVaultVaultPrivateEndpointNotExistsRiskPolicyConfiguration = "AzureKeyVaultVaultPrivateEndpointNotExistsRiskPolicyConfiguration",
    AzureKeyVaultVaultProfile = "AzureKeyVaultVaultProfile",
    AzureKeyVaultVaultRbacDisabledCodeRisk = "AzureKeyVaultVaultRbacDisabledCodeRisk",
    AzureKeyVaultVaultRbacDisabledCodeRiskModel = "AzureKeyVaultVaultRbacDisabledCodeRiskModel",
    AzureKeyVaultVaultRbacDisabledRisk = "AzureKeyVaultVaultRbacDisabledRisk",
    AzureKeyVaultVaultRbacDisabledRiskModel = "AzureKeyVaultVaultRbacDisabledRiskModel",
    AzureKeyVaultVaultRbacDisabledRiskPolicyConfiguration = "AzureKeyVaultVaultRbacDisabledRiskPolicyConfiguration",
    AzureKeyVaultVaultSecret = "AzureKeyVaultVaultSecret",
    AzureKeyVaultVaultSecretExpirationDisabledCodeRisk = "AzureKeyVaultVaultSecretExpirationDisabledCodeRisk",
    AzureKeyVaultVaultSecretExpirationDisabledCodeRiskModel = "AzureKeyVaultVaultSecretExpirationDisabledCodeRiskModel",
    AzureKeyVaultVaultSecretExpirationDisabledRisk = "AzureKeyVaultVaultSecretExpirationDisabledRisk",
    AzureKeyVaultVaultSecretExpirationDisabledRiskModel = "AzureKeyVaultVaultSecretExpirationDisabledRiskModel",
    AzureKeyVaultVaultSecretExpirationDisabledRiskPolicyConfiguration = "AzureKeyVaultVaultSecretExpirationDisabledRiskPolicyConfiguration",
    AzureKeyVaultVaultSecretModel = "AzureKeyVaultVaultSecretModel",
    AzureKeyVaultVaultSecretPermission = "AzureKeyVaultVaultSecretPermission",
    AzureKeyVaultVaultSecretProfile = "AzureKeyVaultVaultSecretProfile",
    AzureKeyVaultVaultSecretVersion = "AzureKeyVaultVaultSecretVersion",
    AzureKeyVaultVaultSoftDelete = "AzureKeyVaultVaultSoftDelete",
    AzureKeyVaultVaultSoftDeleteDisabledCodeRisk = "AzureKeyVaultVaultSoftDeleteDisabledCodeRisk",
    AzureKeyVaultVaultSoftDeleteDisabledCodeRiskModel = "AzureKeyVaultVaultSoftDeleteDisabledCodeRiskModel",
    AzureKeyVaultVaultSoftDeleteDisabledRisk = "AzureKeyVaultVaultSoftDeleteDisabledRisk",
    AzureKeyVaultVaultSoftDeleteDisabledRiskModel = "AzureKeyVaultVaultSoftDeleteDisabledRiskModel",
    AzureKeyVaultVaultSoftDeleteDisabledRiskPolicyConfiguration = "AzureKeyVaultVaultSoftDeleteDisabledRiskPolicyConfiguration",
    AzureKeyVaultVaultStorageAccountPermission = "AzureKeyVaultVaultStorageAccountPermission",
    AzureKeyVaultVaultWideRangeInboundCodeRisk = "AzureKeyVaultVaultWideRangeInboundCodeRisk",
    AzureKeyVaultVaultWideRangeInboundCodeRiskModel = "AzureKeyVaultVaultWideRangeInboundCodeRiskModel",
    AzureKeyVaultVaultWideRangeInboundRisk = "AzureKeyVaultVaultWideRangeInboundRisk",
    AzureKeyVaultVaultWideRangeInboundRiskModel = "AzureKeyVaultVaultWideRangeInboundRiskModel",
    AzureKeyVaultVaultWideRangeInboundRiskPolicyConfiguration = "AzureKeyVaultVaultWideRangeInboundRiskPolicyConfiguration",
    AzureKubernetesClusterRole = "AzureKubernetesClusterRole",
    AzureKubernetesClusterRoleBinding = "AzureKubernetesClusterRoleBinding",
    AzureKubernetesClusterRoleBindingModel = "AzureKubernetesClusterRoleBindingModel",
    AzureKubernetesClusterRoleModel = "AzureKubernetesClusterRoleModel",
    AzureKubernetesConfigMap = "AzureKubernetesConfigMap",
    AzureKubernetesConfigMapModel = "AzureKubernetesConfigMapModel",
    AzureKubernetesConfigurationPrivateLinkScopeGenericResource = "AzureKubernetesConfigurationPrivateLinkScopeGenericResource",
    AzureKubernetesConnectedClusterGenericResource = "AzureKubernetesConnectedClusterGenericResource",
    AzureKubernetesCronJob = "AzureKubernetesCronJob",
    AzureKubernetesCronJobModel = "AzureKubernetesCronJobModel",
    AzureKubernetesDaemonSet = "AzureKubernetesDaemonSet",
    AzureKubernetesDaemonSetModel = "AzureKubernetesDaemonSetModel",
    AzureKubernetesDeployment = "AzureKubernetesDeployment",
    AzureKubernetesDeploymentModel = "AzureKubernetesDeploymentModel",
    AzureKubernetesGroup = "AzureKubernetesGroup",
    AzureKubernetesGroupModel = "AzureKubernetesGroupModel",
    AzureKubernetesIngress = "AzureKubernetesIngress",
    AzureKubernetesIngressClass = "AzureKubernetesIngressClass",
    AzureKubernetesIngressClassModel = "AzureKubernetesIngressClassModel",
    AzureKubernetesIngressModel = "AzureKubernetesIngressModel",
    AzureKubernetesJob = "AzureKubernetesJob",
    AzureKubernetesJobModel = "AzureKubernetesJobModel",
    AzureKubernetesNamespace = "AzureKubernetesNamespace",
    AzureKubernetesNamespaceModel = "AzureKubernetesNamespaceModel",
    AzureKubernetesNamespaceResource = "AzureKubernetesNamespaceResource",
    AzureKubernetesNamespaceResourceBase = "AzureKubernetesNamespaceResourceBase",
    AzureKubernetesNamespaceResourceModel = "AzureKubernetesNamespaceResourceModel",
    AzureKubernetesNamespaceRole = "AzureKubernetesNamespaceRole",
    AzureKubernetesNamespaceRoleBinding = "AzureKubernetesNamespaceRoleBinding",
    AzureKubernetesNamespaceRoleBindingModel = "AzureKubernetesNamespaceRoleBindingModel",
    AzureKubernetesNamespaceRoleModel = "AzureKubernetesNamespaceRoleModel",
    AzureKubernetesNetworkPolicy = "AzureKubernetesNetworkPolicy",
    AzureKubernetesNetworkPolicyModel = "AzureKubernetesNetworkPolicyModel",
    AzureKubernetesNode = "AzureKubernetesNode",
    AzureKubernetesNodeModel = "AzureKubernetesNodeModel",
    AzureKubernetesPersistentVolume = "AzureKubernetesPersistentVolume",
    AzureKubernetesPersistentVolumeModel = "AzureKubernetesPersistentVolumeModel",
    AzureKubernetesPod = "AzureKubernetesPod",
    AzureKubernetesPodModel = "AzureKubernetesPodModel",
    AzureKubernetesPodSecurityPolicy = "AzureKubernetesPodSecurityPolicy",
    AzureKubernetesPodSecurityPolicyModel = "AzureKubernetesPodSecurityPolicyModel",
    AzureKubernetesPodTemplate = "AzureKubernetesPodTemplate",
    AzureKubernetesPodTemplateModel = "AzureKubernetesPodTemplateModel",
    AzureKubernetesReplicaSet = "AzureKubernetesReplicaSet",
    AzureKubernetesReplicaSetModel = "AzureKubernetesReplicaSetModel",
    AzureKubernetesResource = "AzureKubernetesResource",
    AzureKubernetesResourceBase = "AzureKubernetesResourceBase",
    AzureKubernetesResourceModel = "AzureKubernetesResourceModel",
    AzureKubernetesResourceTypeMetadata = "AzureKubernetesResourceTypeMetadata",
    AzureKubernetesService = "AzureKubernetesService",
    AzureKubernetesServiceAccount = "AzureKubernetesServiceAccount",
    AzureKubernetesServiceAccountModel = "AzureKubernetesServiceAccountModel",
    AzureKubernetesServiceModel = "AzureKubernetesServiceModel",
    AzureKubernetesStatefulSet = "AzureKubernetesStatefulSet",
    AzureKubernetesStatefulSetModel = "AzureKubernetesStatefulSetModel",
    AzureKubernetesUser = "AzureKubernetesUser",
    AzureKubernetesUserModel = "AzureKubernetesUserModel",
    AzureKubernetesWorkloadResource = "AzureKubernetesWorkloadResource",
    AzureKubernetesWorkloadResourceBase = "AzureKubernetesWorkloadResourceBase",
    AzureKubernetesWorkloadResourceModel = "AzureKubernetesWorkloadResourceModel",
    AzureKustoClusterGenericResource = "AzureKustoClusterGenericResource",
    AzureKustoClusterGenericResourceModel = "AzureKustoClusterGenericResourceModel",
    AzureLabServicesLabAccountGenericResource = "AzureLabServicesLabAccountGenericResource",
    AzureLabServicesLabGenericResource = "AzureLabServicesLabGenericResource",
    AzureLabServicesLabPlanGenericResource = "AzureLabServicesLabPlanGenericResource",
    AzureLargeInstanceAzureLargeInstanceGenericResource = "AzureLargeInstanceAzureLargeInstanceGenericResource",
    AzureLargeInstanceAzureLargeStorageInstanceGenericResource = "AzureLargeInstanceAzureLargeStorageInstanceGenericResource",
    AzureLoadTestServiceLoadTestGenericResource = "AzureLoadTestServiceLoadTestGenericResource",
    AzureLogicBusinessprocessGenericResource = "AzureLogicBusinessprocessGenericResource",
    AzureLogicIntegrationAccountGenericResource = "AzureLogicIntegrationAccountGenericResource",
    AzureLogicIntegrationServiceEnvironmentGenericResource = "AzureLogicIntegrationServiceEnvironmentGenericResource",
    AzureLogicIntegrationServiceEnvironmentsManagedApiGenericResource = "AzureLogicIntegrationServiceEnvironmentsManagedApiGenericResource",
    AzureLogicWorkflow = "AzureLogicWorkflow",
    AzureLogicWorkflowGenericResource = "AzureLogicWorkflowGenericResource",
    AzureLogicWorkflowInboundExternalWideRangeCodeRisk = "AzureLogicWorkflowInboundExternalWideRangeCodeRisk",
    AzureLogicWorkflowInboundExternalWideRangeCodeRiskModel = "AzureLogicWorkflowInboundExternalWideRangeCodeRiskModel",
    AzureLogicWorkflowInboundExternalWideRangeRisk = "AzureLogicWorkflowInboundExternalWideRangeRisk",
    AzureLogicWorkflowInboundExternalWideRangeRiskModel = "AzureLogicWorkflowInboundExternalWideRangeRiskModel",
    AzureLogicWorkflowInboundExternalWideRangeRiskPolicyConfiguration = "AzureLogicWorkflowInboundExternalWideRangeRiskPolicyConfiguration",
    AzureLogicWorkflowModel = "AzureLogicWorkflowModel",
    AzureLogicWorkflowProfile = "AzureLogicWorkflowProfile",
    AzureLogicWorkflowSecretExistsCodeRisk = "AzureLogicWorkflowSecretExistsCodeRisk",
    AzureLogicWorkflowSecretExistsCodeRiskModel = "AzureLogicWorkflowSecretExistsCodeRiskModel",
    AzureLogicWorkflowSecretExistsRisk = "AzureLogicWorkflowSecretExistsRisk",
    AzureLogicWorkflowSecretExistsRiskModel = "AzureLogicWorkflowSecretExistsRiskModel",
    AzureLogicWorkflowSecretExistsRiskPolicyConfiguration = "AzureLogicWorkflowSecretExistsRiskPolicyConfiguration",
    AzureLogicWorkflowStateNetwork = "AzureLogicWorkflowStateNetwork",
    AzureLogicWorkflowStateNetworkTriggerRuleData = "AzureLogicWorkflowStateNetworkTriggerRuleData",
    AzureLogicWorkflowStatus = "AzureLogicWorkflowStatus",
    AzureLogicWorkflowTriggerNetworkAccessType = "AzureLogicWorkflowTriggerNetworkAccessType",
    AzureLogzMonitorGenericResource = "AzureLogzMonitorGenericResource",
    AzureLogzMonitorsAccountGenericResource = "AzureLogzMonitorsAccountGenericResource",
    AzureLogzMonitorsMetricsSourceGenericResource = "AzureLogzMonitorsMetricsSourceGenericResource",
    AzureMachineLearningServicesRegistryGenericResource = "AzureMachineLearningServicesRegistryGenericResource",
    AzureMachineLearningServicesVirtualclusterGenericResource = "AzureMachineLearningServicesVirtualclusterGenericResource",
    AzureMachineLearningServicesWorkspaceGenericResource = "AzureMachineLearningServicesWorkspaceGenericResource",
    AzureMachineLearningServicesWorkspacesBatchEndpointGenericResource = "AzureMachineLearningServicesWorkspacesBatchEndpointGenericResource",
    AzureMachineLearningServicesWorkspacesBatchEndpointsDeploymentGenericResource = "AzureMachineLearningServicesWorkspacesBatchEndpointsDeploymentGenericResource",
    AzureMachineLearningServicesWorkspacesInferencePoolGenericResource = "AzureMachineLearningServicesWorkspacesInferencePoolGenericResource",
    AzureMachineLearningServicesWorkspacesInferencePoolsEndpointGenericResource = "AzureMachineLearningServicesWorkspacesInferencePoolsEndpointGenericResource",
    AzureMachineLearningServicesWorkspacesInferencePoolsGroupGenericResource = "AzureMachineLearningServicesWorkspacesInferencePoolsGroupGenericResource",
    AzureMachineLearningServicesWorkspacesOnlineEndpointGenericResource = "AzureMachineLearningServicesWorkspacesOnlineEndpointGenericResource",
    AzureMachineLearningServicesWorkspacesOnlineEndpointsDeploymentGenericResource = "AzureMachineLearningServicesWorkspacesOnlineEndpointsDeploymentGenericResource",
    AzureMachineLearningServicesWorkspacesServerlessEndpointGenericResource = "AzureMachineLearningServicesWorkspacesServerlessEndpointGenericResource",
    AzureMachineLearningWorkspace = "AzureMachineLearningWorkspace",
    AzureMachineLearningWorkspaceDiagnosticSettingsLogCategoryNotEnabledRisk = "AzureMachineLearningWorkspaceDiagnosticSettingsLogCategoryNotEnabledRisk",
    AzureMachineLearningWorkspaceDiagnosticSettingsLogCategoryNotEnabledRiskModel = "AzureMachineLearningWorkspaceDiagnosticSettingsLogCategoryNotEnabledRiskModel",
    AzureMachineLearningWorkspaceDiagnosticSettingsLogCategoryNotEnabledRiskPolicyConfiguration = "AzureMachineLearningWorkspaceDiagnosticSettingsLogCategoryNotEnabledRiskPolicyConfiguration",
    AzureMachineLearningWorkspaceInboundExternalWideRangeRisk = "AzureMachineLearningWorkspaceInboundExternalWideRangeRisk",
    AzureMachineLearningWorkspaceInboundExternalWideRangeRiskModel = "AzureMachineLearningWorkspaceInboundExternalWideRangeRiskModel",
    AzureMachineLearningWorkspaceInboundExternalWideRangeRiskPolicyConfiguration = "AzureMachineLearningWorkspaceInboundExternalWideRangeRiskPolicyConfiguration",
    AzureMachineLearningWorkspaceModel = "AzureMachineLearningWorkspaceModel",
    AzureMachineLearningWorkspaceProfile = "AzureMachineLearningWorkspaceProfile",
    AzureMachineLearningWorkspaceStateNetwork = "AzureMachineLearningWorkspaceStateNetwork",
    AzureMaintenanceMaintenanceConfigurationGenericResource = "AzureMaintenanceMaintenanceConfigurationGenericResource",
    AzureManagedIdentityManagedIdentity = "AzureManagedIdentityManagedIdentity",
    AzureManagedIdentityManagedIdentityInactiveRisk = "AzureManagedIdentityManagedIdentityInactiveRisk",
    AzureManagedIdentityManagedIdentityInactiveRiskModel = "AzureManagedIdentityManagedIdentityInactiveRiskModel",
    AzureManagedIdentityManagedIdentityInactiveRiskModelInfoType = "AzureManagedIdentityManagedIdentityInactiveRiskModelInfoType",
    AzureManagedIdentityManagedIdentityInactiveRiskPolicyConfiguration = "AzureManagedIdentityManagedIdentityInactiveRiskPolicyConfiguration",
    AzureManagedIdentityManagedIdentityModel = "AzureManagedIdentityManagedIdentityModel",
    AzureManagedIdentityManagedIdentityModelFilters = "AzureManagedIdentityManagedIdentityModelFilters",
    AzureManagedIdentityManagedIdentityModelType = "AzureManagedIdentityManagedIdentityModelType",
    AzureManagedIdentityNotAllowedResourcePermissionRisk = "AzureManagedIdentityNotAllowedResourcePermissionRisk",
    AzureManagedIdentityNotAllowedResourcePermissionRiskItem = "AzureManagedIdentityNotAllowedResourcePermissionRiskItem",
    AzureManagedIdentityNotAllowedResourcePermissionRiskModel = "AzureManagedIdentityNotAllowedResourcePermissionRiskModel",
    AzureManagedIdentityNotAllowedResourcePermissionRiskPolicyConfiguration = "AzureManagedIdentityNotAllowedResourcePermissionRiskPolicyConfiguration",
    AzureManagedIdentitySystemManagedIdentity = "AzureManagedIdentitySystemManagedIdentity",
    AzureManagedIdentitySystemManagedIdentityModel = "AzureManagedIdentitySystemManagedIdentityModel",
    AzureManagedIdentitySystemManagedIdentityProfile = "AzureManagedIdentitySystemManagedIdentityProfile",
    AzureManagedIdentityUserAssignedIdentityGenericResource = "AzureManagedIdentityUserAssignedIdentityGenericResource",
    AzureManagedIdentityUserManagedIdentity = "AzureManagedIdentityUserManagedIdentity",
    AzureManagedIdentityUserManagedIdentityModel = "AzureManagedIdentityUserManagedIdentityModel",
    AzureManagedIdentityUserManagedIdentityProfile = "AzureManagedIdentityUserManagedIdentityProfile",
    AzureManagedNetworkFabricAccessControlListGenericResource = "AzureManagedNetworkFabricAccessControlListGenericResource",
    AzureManagedNetworkFabricInternetGatewayGenericResource = "AzureManagedNetworkFabricInternetGatewayGenericResource",
    AzureManagedNetworkFabricInternetGatewayRuleGenericResource = "AzureManagedNetworkFabricInternetGatewayRuleGenericResource",
    AzureManagedNetworkFabricIpCommunityGenericResource = "AzureManagedNetworkFabricIpCommunityGenericResource",
    AzureManagedNetworkFabricIpCommunityListGenericResource = "AzureManagedNetworkFabricIpCommunityListGenericResource",
    AzureManagedNetworkFabricIpExtendedCommunityGenericResource = "AzureManagedNetworkFabricIpExtendedCommunityGenericResource",
    AzureManagedNetworkFabricIpPrefixGenericResource = "AzureManagedNetworkFabricIpPrefixGenericResource",
    AzureManagedNetworkFabricIpPrefixListGenericResource = "AzureManagedNetworkFabricIpPrefixListGenericResource",
    AzureManagedNetworkFabricL2IsolationDomainGenericResource = "AzureManagedNetworkFabricL2IsolationDomainGenericResource",
    AzureManagedNetworkFabricL3IsolationDomainGenericResource = "AzureManagedNetworkFabricL3IsolationDomainGenericResource",
    AzureManagedNetworkFabricNeighborGroupGenericResource = "AzureManagedNetworkFabricNeighborGroupGenericResource",
    AzureManagedNetworkFabricNetworkDeviceGenericResource = "AzureManagedNetworkFabricNetworkDeviceGenericResource",
    AzureManagedNetworkFabricNetworkFabricControllerGenericResource = "AzureManagedNetworkFabricNetworkFabricControllerGenericResource",
    AzureManagedNetworkFabricNetworkFabricGenericResource = "AzureManagedNetworkFabricNetworkFabricGenericResource",
    AzureManagedNetworkFabricNetworkPacketBrokerGenericResource = "AzureManagedNetworkFabricNetworkPacketBrokerGenericResource",
    AzureManagedNetworkFabricNetworkRackGenericResource = "AzureManagedNetworkFabricNetworkRackGenericResource",
    AzureManagedNetworkFabricNetworkTapGenericResource = "AzureManagedNetworkFabricNetworkTapGenericResource",
    AzureManagedNetworkFabricNetworkTapRuleGenericResource = "AzureManagedNetworkFabricNetworkTapRuleGenericResource",
    AzureManagedNetworkFabricRoutePolicyGenericResource = "AzureManagedNetworkFabricRoutePolicyGenericResource",
    AzureManagementManagementGroup = "AzureManagementManagementGroup",
    AzureManagementManagementGroupModel = "AzureManagementManagementGroupModel",
    AzureManagementManagementGroupProfile = "AzureManagementManagementGroupProfile",
    AzureManufacturingPlatformManufacturingDataServiceGenericResource = "AzureManufacturingPlatformManufacturingDataServiceGenericResource",
    AzureMapsAccountGenericResource = "AzureMapsAccountGenericResource",
    AzureMapsAccountsCreatorGenericResource = "AzureMapsAccountsCreatorGenericResource",
    AzureMediaMediaserviceGenericResource = "AzureMediaMediaserviceGenericResource",
    AzureMediaMediaserviceGenericResourceModel = "AzureMediaMediaserviceGenericResourceModel",
    AzureMediaMediaservicesLiveEventGenericResource = "AzureMediaMediaservicesLiveEventGenericResource",
    AzureMediaMediaservicesStreamingEndpointGenericResource = "AzureMediaMediaservicesStreamingEndpointGenericResource",
    AzureMediaVideoAnalyzerGenericResource = "AzureMediaVideoAnalyzerGenericResource",
    AzureMessagingCatalogCatalogGenericResource = "AzureMessagingCatalogCatalogGenericResource",
    AzureMessagingConnectorsConnectorGenericResource = "AzureMessagingConnectorsConnectorGenericResource",
    AzureMigrateAssessmentProjectGenericResource = "AzureMigrateAssessmentProjectGenericResource",
    AzureMigrateMigrateProjectGenericResource = "AzureMigrateMigrateProjectGenericResource",
    AzureMigrateModernizeProjectGenericResource = "AzureMigrateModernizeProjectGenericResource",
    AzureMigrateMoveCollectionGenericResource = "AzureMigrateMoveCollectionGenericResource",
    AzureMissionCatalogGenericResource = "AzureMissionCatalogGenericResource",
    AzureMissionCommunitiesCommunityEndpointGenericResource = "AzureMissionCommunitiesCommunityEndpointGenericResource",
    AzureMissionCommunitiesTransitHubGenericResource = "AzureMissionCommunitiesTransitHubGenericResource",
    AzureMissionCommunityGenericResource = "AzureMissionCommunityGenericResource",
    AzureMissionEnclaveConnectionGenericResource = "AzureMissionEnclaveConnectionGenericResource",
    AzureMissionExternalConnectionGenericResource = "AzureMissionExternalConnectionGenericResource",
    AzureMissionInternalConnectionGenericResource = "AzureMissionInternalConnectionGenericResource",
    AzureMissionVirtualEnclafeGenericResource = "AzureMissionVirtualEnclafeGenericResource",
    AzureMissionVirtualEnclavesEnclaveEndpointGenericResource = "AzureMissionVirtualEnclavesEnclaveEndpointGenericResource",
    AzureMissionVirtualEnclavesEndpointGenericResource = "AzureMissionVirtualEnclavesEndpointGenericResource",
    AzureMissionVirtualEnclavesWorkloadGenericResource = "AzureMissionVirtualEnclavesWorkloadGenericResource",
    AzureMixedRealityObjectAnchorsAccountGenericResource = "AzureMixedRealityObjectAnchorsAccountGenericResource",
    AzureMixedRealityObjectUnderstandingAccountGenericResource = "AzureMixedRealityObjectUnderstandingAccountGenericResource",
    AzureMixedRealityRemoteRenderingAccountGenericResource = "AzureMixedRealityRemoteRenderingAccountGenericResource",
    AzureMixedRealitySpatialAnchorsAccountGenericResource = "AzureMixedRealitySpatialAnchorsAccountGenericResource",
    AzureMixedRealitySpatialMapsAccountGenericResource = "AzureMixedRealitySpatialMapsAccountGenericResource",
    AzureMobileNetworkMobileNetworkGenericResource = "AzureMobileNetworkMobileNetworkGenericResource",
    AzureMobileNetworkMobileNetworksDataNetworkGenericResource = "AzureMobileNetworkMobileNetworksDataNetworkGenericResource",
    AzureMobileNetworkMobileNetworksEdgeNetworkSecurityGroupGenericResource = "AzureMobileNetworkMobileNetworksEdgeNetworkSecurityGroupGenericResource",
    AzureMobileNetworkMobileNetworksServiceGenericResource = "AzureMobileNetworkMobileNetworksServiceGenericResource",
    AzureMobileNetworkMobileNetworksSimPolicyGenericResource = "AzureMobileNetworkMobileNetworksSimPolicyGenericResource",
    AzureMobileNetworkMobileNetworksSiteGenericResource = "AzureMobileNetworkMobileNetworksSiteGenericResource",
    AzureMobileNetworkMobileNetworksSliceGenericResource = "AzureMobileNetworkMobileNetworksSliceGenericResource",
    AzureMobileNetworkMobileNetworksWifiSsidGenericResource = "AzureMobileNetworkMobileNetworksWifiSsidGenericResource",
    AzureMobileNetworkPacketCoreControlPlaneGenericResource = "AzureMobileNetworkPacketCoreControlPlaneGenericResource",
    AzureMobileNetworkPacketCoreControlPlanesPacketCoreDataPlaneGenericResource = "AzureMobileNetworkPacketCoreControlPlanesPacketCoreDataPlaneGenericResource",
    AzureMobileNetworkPacketCoreControlPlanesPacketCoreDataPlanesAttachedDataNetworkGenericResource = "AzureMobileNetworkPacketCoreControlPlanesPacketCoreDataPlanesAttachedDataNetworkGenericResource",
    AzureMobileNetworkPacketCoreControlPlanesPacketCoreDataPlanesAttachedWifiSsidGenericResource = "AzureMobileNetworkPacketCoreControlPlanesPacketCoreDataPlanesAttachedWifiSsidGenericResource",
    AzureMobileNetworkPacketCoreControlPlanesPacketCoreDataPlanesEdgeVirtualNetworkGenericResource = "AzureMobileNetworkPacketCoreControlPlanesPacketCoreDataPlanesEdgeVirtualNetworkGenericResource",
    AzureMobileNetworkRadioAccessNetworkGenericResource = "AzureMobileNetworkRadioAccessNetworkGenericResource",
    AzureMobileNetworkSimGenericResource = "AzureMobileNetworkSimGenericResource",
    AzureMobileNetworkSimGroupGenericResource = "AzureMobileNetworkSimGroupGenericResource",
    AzureModSimWorkbenchInstanceGenericResource = "AzureModSimWorkbenchInstanceGenericResource",
    AzureModSimWorkbenchInstancesChamberGenericResource = "AzureModSimWorkbenchInstancesChamberGenericResource",
    AzureModSimWorkbenchInstancesChambersConnectorGenericResource = "AzureModSimWorkbenchInstancesChambersConnectorGenericResource",
    AzureModSimWorkbenchInstancesChambersWorkloadGenericResource = "AzureModSimWorkbenchInstancesChambersWorkloadGenericResource",
    AzureModSimWorkbenchWorkbenchesChamberGenericResource = "AzureModSimWorkbenchWorkbenchesChamberGenericResource",
    AzureModSimWorkbenchWorkbenchesChambersConnectorGenericResource = "AzureModSimWorkbenchWorkbenchesChambersConnectorGenericResource",
    AzureModSimWorkbenchWorkbenchesChambersStorageGenericResource = "AzureModSimWorkbenchWorkbenchesChambersStorageGenericResource",
    AzureModSimWorkbenchWorkbenchesChambersWorkloadGenericResource = "AzureModSimWorkbenchWorkbenchesChambersWorkloadGenericResource",
    AzureModSimWorkbenchWorkbenchesSharedStorageGenericResource = "AzureModSimWorkbenchWorkbenchesSharedStorageGenericResource",
    AzureModSimWorkbenchWorkbenchGenericResource = "AzureModSimWorkbenchWorkbenchGenericResource",
    AzureMonitorAccountGenericResource = "AzureMonitorAccountGenericResource",
    AzureMonitorPipelineGroupGenericResource = "AzureMonitorPipelineGroupGenericResource",
    AzureMySqlDiscoveryMySqlSiteGenericResource = "AzureMySqlDiscoveryMySqlSiteGenericResource",
    AzureMySqlFlexibleServer = "AzureMySqlFlexibleServer",
    AzureMySqlFlexibleServerAadOnlyAuthenticationDisabledCodeRisk = "AzureMySqlFlexibleServerAadOnlyAuthenticationDisabledCodeRisk",
    AzureMySqlFlexibleServerAadOnlyAuthenticationDisabledCodeRiskModel = "AzureMySqlFlexibleServerAadOnlyAuthenticationDisabledCodeRiskModel",
    AzureMySqlFlexibleServerAadOnlyAuthenticationDisabledRisk = "AzureMySqlFlexibleServerAadOnlyAuthenticationDisabledRisk",
    AzureMySqlFlexibleServerAadOnlyAuthenticationDisabledRiskModel = "AzureMySqlFlexibleServerAadOnlyAuthenticationDisabledRiskModel",
    AzureMySqlFlexibleServerAadOnlyAuthenticationDisabledRiskPolicyConfiguration = "AzureMySqlFlexibleServerAadOnlyAuthenticationDisabledRiskPolicyConfiguration",
    AzureMySqlFlexibleServerAuditLogConnectionEventDisabledCodeRisk = "AzureMySqlFlexibleServerAuditLogConnectionEventDisabledCodeRisk",
    AzureMySqlFlexibleServerAuditLogConnectionEventDisabledCodeRiskModel = "AzureMySqlFlexibleServerAuditLogConnectionEventDisabledCodeRiskModel",
    AzureMySqlFlexibleServerAuditLogConnectionEventDisabledRisk = "AzureMySqlFlexibleServerAuditLogConnectionEventDisabledRisk",
    AzureMySqlFlexibleServerAuditLogConnectionEventDisabledRiskModel = "AzureMySqlFlexibleServerAuditLogConnectionEventDisabledRiskModel",
    AzureMySqlFlexibleServerAuditLogConnectionEventDisabledRiskPolicyConfiguration = "AzureMySqlFlexibleServerAuditLogConnectionEventDisabledRiskPolicyConfiguration",
    AzureMySqlFlexibleServerAuditLogDisabledCodeRisk = "AzureMySqlFlexibleServerAuditLogDisabledCodeRisk",
    AzureMySqlFlexibleServerAuditLogDisabledCodeRiskModel = "AzureMySqlFlexibleServerAuditLogDisabledCodeRiskModel",
    AzureMySqlFlexibleServerAuditLogDisabledRisk = "AzureMySqlFlexibleServerAuditLogDisabledRisk",
    AzureMySqlFlexibleServerAuditLogDisabledRiskModel = "AzureMySqlFlexibleServerAuditLogDisabledRiskModel",
    AzureMySqlFlexibleServerAuditLogDisabledRiskPolicyConfiguration = "AzureMySqlFlexibleServerAuditLogDisabledRiskPolicyConfiguration",
    AzureMySqlFlexibleServerInsecureTlsProtocolCodeRisk = "AzureMySqlFlexibleServerInsecureTlsProtocolCodeRisk",
    AzureMySqlFlexibleServerInsecureTlsProtocolCodeRiskModel = "AzureMySqlFlexibleServerInsecureTlsProtocolCodeRiskModel",
    AzureMySqlFlexibleServerInsecureTlsProtocolRisk = "AzureMySqlFlexibleServerInsecureTlsProtocolRisk",
    AzureMySqlFlexibleServerInsecureTlsProtocolRiskModel = "AzureMySqlFlexibleServerInsecureTlsProtocolRiskModel",
    AzureMySqlFlexibleServerInsecureTlsProtocolRiskPolicyConfiguration = "AzureMySqlFlexibleServerInsecureTlsProtocolRiskPolicyConfiguration",
    AzureMySqlFlexibleServerModel = "AzureMySqlFlexibleServerModel",
    AzureMySqlFlexibleServerProfile = "AzureMySqlFlexibleServerProfile",
    AzureMySqlServer = "AzureMySqlServer",
    AzureMySqlServerAuthenticationType = "AzureMySqlServerAuthenticationType",
    AzureMySqlServerConfiguration = "AzureMySqlServerConfiguration",
    AzureMySqlServerHttpsOnlyDisabledCodeRisk = "AzureMySqlServerHttpsOnlyDisabledCodeRisk",
    AzureMySqlServerHttpsOnlyDisabledCodeRiskModel = "AzureMySqlServerHttpsOnlyDisabledCodeRiskModel",
    AzureMySqlServerHttpsOnlyDisabledRisk = "AzureMySqlServerHttpsOnlyDisabledRisk",
    AzureMySqlServerHttpsOnlyDisabledRiskModel = "AzureMySqlServerHttpsOnlyDisabledRiskModel",
    AzureMySqlServerHttpsOnlyDisabledRiskPolicyConfiguration = "AzureMySqlServerHttpsOnlyDisabledRiskPolicyConfiguration",
    AzureMySqlServerModel = "AzureMySqlServerModel",
    AzureMySqlServerSkuTier = "AzureMySqlServerSkuTier",
    AzureMySqlServerStatus = "AzureMySqlServerStatus",
    AzureMySqlServerWideRangeInboundCodeRisk = "AzureMySqlServerWideRangeInboundCodeRisk",
    AzureMySqlServerWideRangeInboundCodeRiskModel = "AzureMySqlServerWideRangeInboundCodeRiskModel",
    AzureMySqlServerWideRangeInboundRisk = "AzureMySqlServerWideRangeInboundRisk",
    AzureMySqlServerWideRangeInboundRiskModel = "AzureMySqlServerWideRangeInboundRiskModel",
    AzureMySqlServerWideRangeInboundRiskPolicyConfiguration = "AzureMySqlServerWideRangeInboundRiskPolicyConfiguration",
    AzureMySqlSingleServer = "AzureMySqlSingleServer",
    AzureMySqlSingleServerModel = "AzureMySqlSingleServerModel",
    AzureMySqlSingleServerProfile = "AzureMySqlSingleServerProfile",
    AzureNeonPostgresOrganizationGenericResource = "AzureNeonPostgresOrganizationGenericResource",
    AzureNetAppNetAppAccountGenericResource = "AzureNetAppNetAppAccountGenericResource",
    AzureNetAppNetAppAccountsBackupPolicyGenericResource = "AzureNetAppNetAppAccountsBackupPolicyGenericResource",
    AzureNetAppNetAppAccountsBackupVaultGenericResource = "AzureNetAppNetAppAccountsBackupVaultGenericResource",
    AzureNetAppNetAppAccountsCapacityPoolGenericResource = "AzureNetAppNetAppAccountsCapacityPoolGenericResource",
    AzureNetAppNetAppAccountsCapacityPoolsVolumeGenericResource = "AzureNetAppNetAppAccountsCapacityPoolsVolumeGenericResource",
    AzureNetAppNetAppAccountsSnapshotPolicyGenericResource = "AzureNetAppNetAppAccountsSnapshotPolicyGenericResource",
    AzureNetworkAccessManagementRisk = "AzureNetworkAccessManagementRisk",
    AzureNetworkAccessManagementRiskModel = "AzureNetworkAccessManagementRiskModel",
    AzureNetworkAccessManagementRiskPolicyConfiguration = "AzureNetworkAccessManagementRiskPolicyConfiguration",
    AzureNetworkAccessResourceStateNetwork = "AzureNetworkAccessResourceStateNetwork",
    AzureNetworkApplicationGateway = "AzureNetworkApplicationGateway",
    AzureNetworkApplicationGatewayBackendAddressPool = "AzureNetworkApplicationGatewayBackendAddressPool",
    AzureNetworkApplicationGatewayBackendRule = "AzureNetworkApplicationGatewayBackendRule",
    AzureNetworkApplicationGatewayGenericResource = "AzureNetworkApplicationGatewayGenericResource",
    AzureNetworkApplicationGatewayModel = "AzureNetworkApplicationGatewayModel",
    AzureNetworkApplicationGatewayProfile = "AzureNetworkApplicationGatewayProfile",
    AzureNetworkApplicationGatewayProtocol = "AzureNetworkApplicationGatewayProtocol",
    AzureNetworkApplicationGatewayRedirectRule = "AzureNetworkApplicationGatewayRedirectRule",
    AzureNetworkApplicationGatewayRule = "AzureNetworkApplicationGatewayRule",
    AzureNetworkApplicationGatewayStateNetwork = "AzureNetworkApplicationGatewayStateNetwork",
    AzureNetworkApplicationGatewayWebApplicationFirewallPolicyGenericResource = "AzureNetworkApplicationGatewayWebApplicationFirewallPolicyGenericResource",
    AzureNetworkApplicationSecurityGroup = "AzureNetworkApplicationSecurityGroup",
    AzureNetworkApplicationSecurityGroupGenericResource = "AzureNetworkApplicationSecurityGroupGenericResource",
    AzureNetworkApplicationSecurityGroupModel = "AzureNetworkApplicationSecurityGroupModel",
    AzureNetworkApplicationSecurityGroupProfile = "AzureNetworkApplicationSecurityGroupProfile",
    AzureNetworkAzurefirewallGenericResource = "AzureNetworkAzurefirewallGenericResource",
    AzureNetworkAzurefirewallGenericResourceModel = "AzureNetworkAzurefirewallGenericResourceModel",
    AzureNetworkBastionHostGenericResource = "AzureNetworkBastionHostGenericResource",
    AzureNetworkBastionHostGenericResourceModel = "AzureNetworkBastionHostGenericResourceModel",
    AzureNetworkCloudBareMetalMachineGenericResource = "AzureNetworkCloudBareMetalMachineGenericResource",
    AzureNetworkCloudCloudServicesNetworkGenericResource = "AzureNetworkCloudCloudServicesNetworkGenericResource",
    AzureNetworkCloudClusterGenericResource = "AzureNetworkCloudClusterGenericResource",
    AzureNetworkCloudClusterManagerGenericResource = "AzureNetworkCloudClusterManagerGenericResource",
    AzureNetworkCloudClustersBareMetalMachineKeySetGenericResource = "AzureNetworkCloudClustersBareMetalMachineKeySetGenericResource",
    AzureNetworkCloudClustersBmcKeySetGenericResource = "AzureNetworkCloudClustersBmcKeySetGenericResource",
    AzureNetworkCloudClustersMetricsConfigurationGenericResource = "AzureNetworkCloudClustersMetricsConfigurationGenericResource",
    AzureNetworkCloudEdgeClusterGenericResource = "AzureNetworkCloudEdgeClusterGenericResource",
    AzureNetworkCloudEdgeClustersNodeGenericResource = "AzureNetworkCloudEdgeClustersNodeGenericResource",
    AzureNetworkCloudKubernetesClusterGenericResource = "AzureNetworkCloudKubernetesClusterGenericResource",
    AzureNetworkCloudKubernetesClustersAgentPoolGenericResource = "AzureNetworkCloudKubernetesClustersAgentPoolGenericResource",
    AzureNetworkCloudKubernetesClustersFeatureGenericResource = "AzureNetworkCloudKubernetesClustersFeatureGenericResource",
    AzureNetworkCloudL2NetworkGenericResource = "AzureNetworkCloudL2NetworkGenericResource",
    AzureNetworkCloudL3NetworkGenericResource = "AzureNetworkCloudL3NetworkGenericResource",
    AzureNetworkCloudRackGenericResource = "AzureNetworkCloudRackGenericResource",
    AzureNetworkCloudRegistrationHubGenericResource = "AzureNetworkCloudRegistrationHubGenericResource",
    AzureNetworkCloudRegistrationHubsImageGenericResource = "AzureNetworkCloudRegistrationHubsImageGenericResource",
    AzureNetworkCloudRegistrationHubsMachineGenericResource = "AzureNetworkCloudRegistrationHubsMachineGenericResource",
    AzureNetworkCloudStorageApplianceGenericResource = "AzureNetworkCloudStorageApplianceGenericResource",
    AzureNetworkCloudTrunkedNetworkGenericResource = "AzureNetworkCloudTrunkedNetworkGenericResource",
    AzureNetworkCloudVirtualMachineGenericResource = "AzureNetworkCloudVirtualMachineGenericResource",
    AzureNetworkCloudVirtualMachinesConsoleGenericResource = "AzureNetworkCloudVirtualMachinesConsoleGenericResource",
    AzureNetworkCloudVolumeGenericResource = "AzureNetworkCloudVolumeGenericResource",
    AzureNetworkConnectionGenericResource = "AzureNetworkConnectionGenericResource",
    AzureNetworkCustomIpPrefixGenericResource = "AzureNetworkCustomIpPrefixGenericResource",
    AzureNetworkDdosCustomPolicyGenericResource = "AzureNetworkDdosCustomPolicyGenericResource",
    AzureNetworkDdosProtectionPlanGenericResource = "AzureNetworkDdosProtectionPlanGenericResource",
    AzureNetworkDnsForwardingRulesetGenericResource = "AzureNetworkDnsForwardingRulesetGenericResource",
    AzureNetworkDnsResolverDomainListGenericResource = "AzureNetworkDnsResolverDomainListGenericResource",
    AzureNetworkDnsResolverGenericResource = "AzureNetworkDnsResolverGenericResource",
    AzureNetworkDnsResolverPoliciesDnsSecurityRuleGenericResource = "AzureNetworkDnsResolverPoliciesDnsSecurityRuleGenericResource",
    AzureNetworkDnsResolverPoliciesVirtualNetworkLinkGenericResource = "AzureNetworkDnsResolverPoliciesVirtualNetworkLinkGenericResource",
    AzureNetworkDnsResolverPolicyGenericResource = "AzureNetworkDnsResolverPolicyGenericResource",
    AzureNetworkDnsResolversInboundEndpointGenericResource = "AzureNetworkDnsResolversInboundEndpointGenericResource",
    AzureNetworkDnsResolversOutboundEndpointGenericResource = "AzureNetworkDnsResolversOutboundEndpointGenericResource",
    AzureNetworkDnsZone = "AzureNetworkDnsZone",
    AzureNetworkDnszoneGenericResource = "AzureNetworkDnszoneGenericResource",
    AzureNetworkDnsZoneModel = "AzureNetworkDnsZoneModel",
    AzureNetworkDnsZoneProfile = "AzureNetworkDnsZoneProfile",
    AzureNetworkDnsZoneRecordSetA = "AzureNetworkDnsZoneRecordSetA",
    AzureNetworkDnsZoneRecordSetAaaa = "AzureNetworkDnsZoneRecordSetAaaa",
    AzureNetworkDnsZoneRecordSetAaaaModel = "AzureNetworkDnsZoneRecordSetAaaaModel",
    AzureNetworkDnsZoneRecordSetAaaaProfile = "AzureNetworkDnsZoneRecordSetAaaaProfile",
    AzureNetworkDnsZoneRecordSetAModel = "AzureNetworkDnsZoneRecordSetAModel",
    AzureNetworkDnsZoneRecordSetAProfile = "AzureNetworkDnsZoneRecordSetAProfile",
    AzureNetworkDnsZoneRecordSetCaa = "AzureNetworkDnsZoneRecordSetCaa",
    AzureNetworkDnsZoneRecordSetCaaModel = "AzureNetworkDnsZoneRecordSetCaaModel",
    AzureNetworkDnsZoneRecordSetCaaProfile = "AzureNetworkDnsZoneRecordSetCaaProfile",
    AzureNetworkDnsZoneRecordSetCname = "AzureNetworkDnsZoneRecordSetCname",
    AzureNetworkDnsZoneRecordSetCnameModel = "AzureNetworkDnsZoneRecordSetCnameModel",
    AzureNetworkDnsZoneRecordSetCnameProfile = "AzureNetworkDnsZoneRecordSetCnameProfile",
    AzureNetworkDnsZoneRecordSetMx = "AzureNetworkDnsZoneRecordSetMx",
    AzureNetworkDnsZoneRecordSetMxModel = "AzureNetworkDnsZoneRecordSetMxModel",
    AzureNetworkDnsZoneRecordSetMxProfile = "AzureNetworkDnsZoneRecordSetMxProfile",
    AzureNetworkDnsZoneRecordSetNs = "AzureNetworkDnsZoneRecordSetNs",
    AzureNetworkDnsZoneRecordSetNsModel = "AzureNetworkDnsZoneRecordSetNsModel",
    AzureNetworkDnsZoneRecordSetNsProfile = "AzureNetworkDnsZoneRecordSetNsProfile",
    AzureNetworkDnsZoneRecordSetPtr = "AzureNetworkDnsZoneRecordSetPtr",
    AzureNetworkDnsZoneRecordSetPtrModel = "AzureNetworkDnsZoneRecordSetPtrModel",
    AzureNetworkDnsZoneRecordSetPtrProfile = "AzureNetworkDnsZoneRecordSetPtrProfile",
    AzureNetworkDnsZoneRecordSetResource = "AzureNetworkDnsZoneRecordSetResource",
    AzureNetworkDnsZoneRecordSetResourceModel = "AzureNetworkDnsZoneRecordSetResourceModel",
    AzureNetworkDnsZoneRecordSetSoa = "AzureNetworkDnsZoneRecordSetSoa",
    AzureNetworkDnsZoneRecordSetSoaModel = "AzureNetworkDnsZoneRecordSetSoaModel",
    AzureNetworkDnsZoneRecordSetSoaProfile = "AzureNetworkDnsZoneRecordSetSoaProfile",
    AzureNetworkDnsZoneRecordSetSrv = "AzureNetworkDnsZoneRecordSetSrv",
    AzureNetworkDnsZoneRecordSetSrvModel = "AzureNetworkDnsZoneRecordSetSrvModel",
    AzureNetworkDnsZoneRecordSetSrvProfile = "AzureNetworkDnsZoneRecordSetSrvProfile",
    AzureNetworkDnsZoneRecordSetTxt = "AzureNetworkDnsZoneRecordSetTxt",
    AzureNetworkDnsZoneRecordSetTxtModel = "AzureNetworkDnsZoneRecordSetTxtModel",
    AzureNetworkDnsZoneRecordSetTxtProfile = "AzureNetworkDnsZoneRecordSetTxtProfile",
    AzureNetworkDnszonesAGenericResource = "AzureNetworkDnszonesAGenericResource",
    AzureNetworkDnszonesCnameGenericResource = "AzureNetworkDnszonesCnameGenericResource",
    AzureNetworkDscpConfigurationGenericResource = "AzureNetworkDscpConfigurationGenericResource",
    AzureNetworkExpressRouteCircuitGenericResource = "AzureNetworkExpressRouteCircuitGenericResource",
    AzureNetworkExpressRouteCrossConnectionGenericResource = "AzureNetworkExpressRouteCrossConnectionGenericResource",
    AzureNetworkExpressRouteGatewayGenericResource = "AzureNetworkExpressRouteGatewayGenericResource",
    AzureNetworkExpressRoutePortGenericResource = "AzureNetworkExpressRoutePortGenericResource",
    AzureNetworkFirewallPoliciesRuleGroupGenericResource = "AzureNetworkFirewallPoliciesRuleGroupGenericResource",
    AzureNetworkFirewallPolicyGenericResource = "AzureNetworkFirewallPolicyGenericResource",
    AzureNetworkFirewallPolicyGenericResourceModel = "AzureNetworkFirewallPolicyGenericResourceModel",
    AzureNetworkFrontDoorGenericResource = "AzureNetworkFrontDoorGenericResource",
    AzureNetworkFrontDoorGenericResourceModel = "AzureNetworkFrontDoorGenericResourceModel",
    AzureNetworkFrontDoorWebApplicationFirewallPolicyGenericResource = "AzureNetworkFrontDoorWebApplicationFirewallPolicyGenericResource",
    AzureNetworkFrontDoorWebApplicationFirewallPolicyGenericResourceModel = "AzureNetworkFrontDoorWebApplicationFirewallPolicyGenericResourceModel",
    AzureNetworkFunctionAzureTrafficCollectorGenericResource = "AzureNetworkFunctionAzureTrafficCollectorGenericResource",
    AzureNetworkFunctionAzureTrafficCollectorsCollectorPolicyGenericResource = "AzureNetworkFunctionAzureTrafficCollectorsCollectorPolicyGenericResource",
    AzureNetworkFunctionMeshVpnGenericResource = "AzureNetworkFunctionMeshVpnGenericResource",
    AzureNetworkFunctionVpnBranchGenericResource = "AzureNetworkFunctionVpnBranchGenericResource",
    AzureNetworkGatewayLoadBalancerAliasGenericResource = "AzureNetworkGatewayLoadBalancerAliasGenericResource",
    AzureNetworkIpAllocationGenericResource = "AzureNetworkIpAllocationGenericResource",
    AzureNetworkIpGroupGenericResource = "AzureNetworkIpGroupGenericResource",
    AzureNetworkIpGroupGenericResourceModel = "AzureNetworkIpGroupGenericResourceModel",
    AzureNetworkLoadBalancer = "AzureNetworkLoadBalancer",
    AzureNetworkLoadBalancerBackendAddressPool = "AzureNetworkLoadBalancerBackendAddressPool",
    AzureNetworkLoadBalancerFrontendIpConfiguration = "AzureNetworkLoadBalancerFrontendIpConfiguration",
    AzureNetworkLoadBalancerGenericResource = "AzureNetworkLoadBalancerGenericResource",
    AzureNetworkLoadBalancerInboundNatRule = "AzureNetworkLoadBalancerInboundNatRule",
    AzureNetworkLoadBalancerLoadBalancingRule = "AzureNetworkLoadBalancerLoadBalancingRule",
    AzureNetworkLoadBalancerModel = "AzureNetworkLoadBalancerModel",
    AzureNetworkLoadBalancerModelFilters = "AzureNetworkLoadBalancerModelFilters",
    AzureNetworkLoadBalancerProfile = "AzureNetworkLoadBalancerProfile",
    AzureNetworkLoadBalancerRule = "AzureNetworkLoadBalancerRule",
    AzureNetworkLoadBalancerRuleProtocol = "AzureNetworkLoadBalancerRuleProtocol",
    AzureNetworkLoadBalancerSku = "AzureNetworkLoadBalancerSku",
    AzureNetworkLoadBalancerStateNetwork = "AzureNetworkLoadBalancerStateNetwork",
    AzureNetworkLoadBalancerType = "AzureNetworkLoadBalancerType",
    AzureNetworkLocalnetworkgatewayGenericResource = "AzureNetworkLocalnetworkgatewayGenericResource",
    AzureNetworkLocalnetworkgatewayGenericResourceModel = "AzureNetworkLocalnetworkgatewayGenericResourceModel",
    AzureNetworkNatGatewayGenericResource = "AzureNetworkNatGatewayGenericResource",
    AzureNetworkNatGatewayGenericResourceModel = "AzureNetworkNatGatewayGenericResourceModel",
    AzureNetworkNetworkExperimentProfileGenericResource = "AzureNetworkNetworkExperimentProfileGenericResource",
    AzureNetworkNetworkIntentPolicyGenericResource = "AzureNetworkNetworkIntentPolicyGenericResource",
    AzureNetworkNetworkInterface = "AzureNetworkNetworkInterface",
    AzureNetworkNetworkInterfaceGenericResource = "AzureNetworkNetworkInterfaceGenericResource",
    AzureNetworkNetworkInterfaceIpConfiguration = "AzureNetworkNetworkInterfaceIpConfiguration",
    AzureNetworkNetworkInterfaceModel = "AzureNetworkNetworkInterfaceModel",
    AzureNetworkNetworkInterfaceProfile = "AzureNetworkNetworkInterfaceProfile",
    AzureNetworkNetworkManagerGenericResource = "AzureNetworkNetworkManagerGenericResource",
    AzureNetworkNetworkManagerGenericResourceModel = "AzureNetworkNetworkManagerGenericResourceModel",
    AzureNetworkNetworkManagersIpamPoolGenericResource = "AzureNetworkNetworkManagersIpamPoolGenericResource",
    AzureNetworkNetworkManagersVerifierWorkspaceGenericResource = "AzureNetworkNetworkManagersVerifierWorkspaceGenericResource",
    AzureNetworkNetworkProfileGenericResource = "AzureNetworkNetworkProfileGenericResource",
    AzureNetworkNetworkSecurityGroup = "AzureNetworkNetworkSecurityGroup",
    AzureNetworkNetworkSecurityGroupGenericResource = "AzureNetworkNetworkSecurityGroupGenericResource",
    AzureNetworkNetworkSecurityGroupInboundRuleInsecureServiceTagCodeRisk = "AzureNetworkNetworkSecurityGroupInboundRuleInsecureServiceTagCodeRisk",
    AzureNetworkNetworkSecurityGroupInboundRuleInsecureServiceTagCodeRiskModel = "AzureNetworkNetworkSecurityGroupInboundRuleInsecureServiceTagCodeRiskModel",
    AzureNetworkNetworkSecurityGroupInboundRuleInsecureServiceTagRisk = "AzureNetworkNetworkSecurityGroupInboundRuleInsecureServiceTagRisk",
    AzureNetworkNetworkSecurityGroupInboundRuleInsecureServiceTagRiskModel = "AzureNetworkNetworkSecurityGroupInboundRuleInsecureServiceTagRiskModel",
    AzureNetworkNetworkSecurityGroupInboundRuleInsecureServiceTagRiskPolicyConfiguration = "AzureNetworkNetworkSecurityGroupInboundRuleInsecureServiceTagRiskPolicyConfiguration",
    AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnyExistsRisk = "AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnyExistsRisk",
    AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnyExistsRiskModel = "AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnyExistsRiskModel",
    AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnyRdpExistsCodeRisk = "AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnyRdpExistsCodeRisk",
    AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnyRdpExistsCodeRiskModel = "AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnyRdpExistsCodeRiskModel",
    AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnyRdpExistsRisk = "AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnyRdpExistsRisk",
    AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnyRdpExistsRiskModel = "AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnyRdpExistsRiskModel",
    AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnyRdpExistsRiskPolicyConfiguration = "AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnyRdpExistsRiskPolicyConfiguration",
    AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnySshExistsCodeRisk = "AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnySshExistsCodeRisk",
    AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnySshExistsCodeRiskModel = "AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnySshExistsCodeRiskModel",
    AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnySshExistsRisk = "AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnySshExistsRisk",
    AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnySshExistsRiskModel = "AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnySshExistsRiskModel",
    AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnySshExistsRiskPolicyConfiguration = "AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnySshExistsRiskPolicyConfiguration",
    AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnyUdpExistsCodeRisk = "AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnyUdpExistsCodeRisk",
    AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnyUdpExistsCodeRiskModel = "AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnyUdpExistsCodeRiskModel",
    AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnyUdpExistsRisk = "AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnyUdpExistsRisk",
    AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnyUdpExistsRiskModel = "AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnyUdpExistsRiskModel",
    AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnyUdpExistsRiskPolicyConfiguration = "AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnyUdpExistsRiskPolicyConfiguration",
    AzureNetworkNetworkSecurityGroupInsecureInboundRuleExistsRisk = "AzureNetworkNetworkSecurityGroupInsecureInboundRuleExistsRisk",
    AzureNetworkNetworkSecurityGroupModel = "AzureNetworkNetworkSecurityGroupModel",
    AzureNetworkNetworkSecurityGroupProfile = "AzureNetworkNetworkSecurityGroupProfile",
    AzureNetworkNetworkSecurityGroupRule = "AzureNetworkNetworkSecurityGroupRule",
    AzureNetworkNetworkSecurityGroupRuleAction = "AzureNetworkNetworkSecurityGroupRuleAction",
    AzureNetworkNetworkSecurityGroupRuleEntity = "AzureNetworkNetworkSecurityGroupRuleEntity",
    AzureNetworkNetworkSecurityPerimeterGenericResource = "AzureNetworkNetworkSecurityPerimeterGenericResource",
    AzureNetworkNetworkVerifierGenericResource = "AzureNetworkNetworkVerifierGenericResource",
    AzureNetworkNetworkVirtualApplianceGenericResource = "AzureNetworkNetworkVirtualApplianceGenericResource",
    AzureNetworkNetworkWatcherGenericResource = "AzureNetworkNetworkWatcherGenericResource",
    AzureNetworkNetworkWatchersConnectionMonitorGenericResource = "AzureNetworkNetworkWatchersConnectionMonitorGenericResource",
    AzureNetworkNetworkWatchersFlowLogGenericResource = "AzureNetworkNetworkWatchersFlowLogGenericResource",
    AzureNetworkNetworkWatchersLensGenericResource = "AzureNetworkNetworkWatchersLensGenericResource",
    AzureNetworkNetworkWatchersPingMeshGenericResource = "AzureNetworkNetworkWatchersPingMeshGenericResource",
    AzureNetworkP2sVpnGatewayGenericResource = "AzureNetworkP2sVpnGatewayGenericResource",
    AzureNetworkPrivateDnsZone = "AzureNetworkPrivateDnsZone",
    AzureNetworkPrivateDnsZoneGenericResource = "AzureNetworkPrivateDnsZoneGenericResource",
    AzureNetworkPrivateDnsZoneModel = "AzureNetworkPrivateDnsZoneModel",
    AzureNetworkPrivateDnsZoneProfile = "AzureNetworkPrivateDnsZoneProfile",
    AzureNetworkPrivateDnsZoneRecordSetA = "AzureNetworkPrivateDnsZoneRecordSetA",
    AzureNetworkPrivateDnsZoneRecordSetAaaa = "AzureNetworkPrivateDnsZoneRecordSetAaaa",
    AzureNetworkPrivateDnsZoneRecordSetAaaaModel = "AzureNetworkPrivateDnsZoneRecordSetAaaaModel",
    AzureNetworkPrivateDnsZoneRecordSetAaaaProfile = "AzureNetworkPrivateDnsZoneRecordSetAaaaProfile",
    AzureNetworkPrivateDnsZoneRecordSetAModel = "AzureNetworkPrivateDnsZoneRecordSetAModel",
    AzureNetworkPrivateDnsZoneRecordSetAProfile = "AzureNetworkPrivateDnsZoneRecordSetAProfile",
    AzureNetworkPrivateDnsZoneRecordSetCname = "AzureNetworkPrivateDnsZoneRecordSetCname",
    AzureNetworkPrivateDnsZoneRecordSetCnameModel = "AzureNetworkPrivateDnsZoneRecordSetCnameModel",
    AzureNetworkPrivateDnsZoneRecordSetCnameProfile = "AzureNetworkPrivateDnsZoneRecordSetCnameProfile",
    AzureNetworkPrivateDnsZoneRecordSetMx = "AzureNetworkPrivateDnsZoneRecordSetMx",
    AzureNetworkPrivateDnsZoneRecordSetMxModel = "AzureNetworkPrivateDnsZoneRecordSetMxModel",
    AzureNetworkPrivateDnsZoneRecordSetMxProfile = "AzureNetworkPrivateDnsZoneRecordSetMxProfile",
    AzureNetworkPrivateDnsZoneRecordSetPtr = "AzureNetworkPrivateDnsZoneRecordSetPtr",
    AzureNetworkPrivateDnsZoneRecordSetPtrModel = "AzureNetworkPrivateDnsZoneRecordSetPtrModel",
    AzureNetworkPrivateDnsZoneRecordSetPtrProfile = "AzureNetworkPrivateDnsZoneRecordSetPtrProfile",
    AzureNetworkPrivateDnsZoneRecordSetResource = "AzureNetworkPrivateDnsZoneRecordSetResource",
    AzureNetworkPrivateDnsZoneRecordSetResourceModel = "AzureNetworkPrivateDnsZoneRecordSetResourceModel",
    AzureNetworkPrivateDnsZoneRecordSetSoa = "AzureNetworkPrivateDnsZoneRecordSetSoa",
    AzureNetworkPrivateDnsZoneRecordSetSoaModel = "AzureNetworkPrivateDnsZoneRecordSetSoaModel",
    AzureNetworkPrivateDnsZoneRecordSetSoaProfile = "AzureNetworkPrivateDnsZoneRecordSetSoaProfile",
    AzureNetworkPrivateDnsZoneRecordSetSrv = "AzureNetworkPrivateDnsZoneRecordSetSrv",
    AzureNetworkPrivateDnsZoneRecordSetSrvModel = "AzureNetworkPrivateDnsZoneRecordSetSrvModel",
    AzureNetworkPrivateDnsZoneRecordSetSrvProfile = "AzureNetworkPrivateDnsZoneRecordSetSrvProfile",
    AzureNetworkPrivateDnsZoneRecordSetTxt = "AzureNetworkPrivateDnsZoneRecordSetTxt",
    AzureNetworkPrivateDnsZoneRecordSetTxtModel = "AzureNetworkPrivateDnsZoneRecordSetTxtModel",
    AzureNetworkPrivateDnsZoneRecordSetTxtProfile = "AzureNetworkPrivateDnsZoneRecordSetTxtProfile",
    AzureNetworkPrivateDnsZonesVirtualNetworkLinkGenericResource = "AzureNetworkPrivateDnsZonesVirtualNetworkLinkGenericResource",
    AzureNetworkPrivateEndpoint = "AzureNetworkPrivateEndpoint",
    AzureNetworkPrivateEndpointConnectionStatus = "AzureNetworkPrivateEndpointConnectionStatus",
    AzureNetworkPrivateEndpointGenericResource = "AzureNetworkPrivateEndpointGenericResource",
    AzureNetworkPrivateEndpointModel = "AzureNetworkPrivateEndpointModel",
    AzureNetworkPrivateEndpointProfile = "AzureNetworkPrivateEndpointProfile",
    AzureNetworkPrivateEndpointRedirectMapGenericResource = "AzureNetworkPrivateEndpointRedirectMapGenericResource",
    AzureNetworkPrivateLinkServiceGenericResource = "AzureNetworkPrivateLinkServiceGenericResource",
    AzureNetworkPrivateLinkServiceGenericResourceModel = "AzureNetworkPrivateLinkServiceGenericResourceModel",
    AzureNetworkPublicIpAddress = "AzureNetworkPublicIpAddress",
    AzureNetworkPublicIpAddressGenericResource = "AzureNetworkPublicIpAddressGenericResource",
    AzureNetworkPublicIpAddressModel = "AzureNetworkPublicIpAddressModel",
    AzureNetworkPublicIpAddressProfile = "AzureNetworkPublicIpAddressProfile",
    AzureNetworkPublicIpAddressSku = "AzureNetworkPublicIpAddressSku",
    AzureNetworkPublicIpAddressType = "AzureNetworkPublicIpAddressType",
    AzureNetworkPublicIpPrefix = "AzureNetworkPublicIpPrefix",
    AzureNetworkPublicIpPrefixGenericResource = "AzureNetworkPublicIpPrefixGenericResource",
    AzureNetworkPublicIpPrefixModel = "AzureNetworkPublicIpPrefixModel",
    AzureNetworkPublicIpPrefixProfile = "AzureNetworkPublicIpPrefixProfile",
    AzureNetworkRouteFilterGenericResource = "AzureNetworkRouteFilterGenericResource",
    AzureNetworkRouteTable = "AzureNetworkRouteTable",
    AzureNetworkRouteTableGenericResource = "AzureNetworkRouteTableGenericResource",
    AzureNetworkRouteTableModel = "AzureNetworkRouteTableModel",
    AzureNetworkRouteTableProfile = "AzureNetworkRouteTableProfile",
    AzureNetworkRouteTableRoute = "AzureNetworkRouteTableRoute",
    AzureNetworkRouteTableRouteType = "AzureNetworkRouteTableRouteType",
    AzureNetworkScope = "AzureNetworkScope",
    AzureNetworkSecurityPartnerProviderGenericResource = "AzureNetworkSecurityPartnerProviderGenericResource",
    AzureNetworkServiceEndpointPolicyGenericResource = "AzureNetworkServiceEndpointPolicyGenericResource",
    AzureNetworkServiceEndpointState = "AzureNetworkServiceEndpointState",
    AzureNetworkTrafficManagerProfileGenericResource = "AzureNetworkTrafficManagerProfileGenericResource",
    AzureNetworkTrafficManagerProfileGenericResourceModel = "AzureNetworkTrafficManagerProfileGenericResourceModel",
    AzureNetworkVirtualHubGenericResource = "AzureNetworkVirtualHubGenericResource",
    AzureNetworkVirtualHubsBgpConnectionGenericResource = "AzureNetworkVirtualHubsBgpConnectionGenericResource",
    AzureNetworkVirtualHubsIpConfigurationGenericResource = "AzureNetworkVirtualHubsIpConfigurationGenericResource",
    AzureNetworkVirtualNetwork = "AzureNetworkVirtualNetwork",
    AzureNetworkVirtualnetworkgatewayGenericResource = "AzureNetworkVirtualnetworkgatewayGenericResource",
    AzureNetworkVirtualnetworkgatewayGenericResourceModel = "AzureNetworkVirtualnetworkgatewayGenericResourceModel",
    AzureNetworkVirtualNetworkGenericResource = "AzureNetworkVirtualNetworkGenericResource",
    AzureNetworkVirtualNetworkModel = "AzureNetworkVirtualNetworkModel",
    AzureNetworkVirtualNetworkProfile = "AzureNetworkVirtualNetworkProfile",
    AzureNetworkVirtualNetworkSubnet = "AzureNetworkVirtualNetworkSubnet",
    AzureNetworkVirtualNetworkSubnetModel = "AzureNetworkVirtualNetworkSubnetModel",
    AzureNetworkVirtualNetworkSubnetProfile = "AzureNetworkVirtualNetworkSubnetProfile",
    AzureNetworkVirtualNetworkSubnetStateNetwork = "AzureNetworkVirtualNetworkSubnetStateNetwork",
    AzureNetworkVirtualNetworkSubnetUnrestrictedInboundAccessCodeRisk = "AzureNetworkVirtualNetworkSubnetUnrestrictedInboundAccessCodeRisk",
    AzureNetworkVirtualNetworkSubnetUnrestrictedInboundAccessCodeRiskModel = "AzureNetworkVirtualNetworkSubnetUnrestrictedInboundAccessCodeRiskModel",
    AzureNetworkVirtualNetworkSubnetUnrestrictedInboundAccessRisk = "AzureNetworkVirtualNetworkSubnetUnrestrictedInboundAccessRisk",
    AzureNetworkVirtualNetworkSubnetUnrestrictedInboundAccessRiskModel = "AzureNetworkVirtualNetworkSubnetUnrestrictedInboundAccessRiskModel",
    AzureNetworkVirtualNetworkSubnetUnrestrictedInboundAccessRiskPolicyConfiguration = "AzureNetworkVirtualNetworkSubnetUnrestrictedInboundAccessRiskPolicyConfiguration",
    AzureNetworkVirtualNetworkTapGenericResource = "AzureNetworkVirtualNetworkTapGenericResource",
    AzureNetworkVirtualRouterGenericResource = "AzureNetworkVirtualRouterGenericResource",
    AzureNetworkVirtualWanGenericResource = "AzureNetworkVirtualWanGenericResource",
    AzureNetworkVirtualWanGenericResourceModel = "AzureNetworkVirtualWanGenericResourceModel",
    AzureNetworkVpnGatewayGenericResource = "AzureNetworkVpnGatewayGenericResource",
    AzureNetworkVpnGatewayGenericResourceModel = "AzureNetworkVpnGatewayGenericResourceModel",
    AzureNetworkVpnServerConfigurationGenericResource = "AzureNetworkVpnServerConfigurationGenericResource",
    AzureNetworkVpnsiteGenericResource = "AzureNetworkVpnsiteGenericResource",
    AzureNewRelicObservabilityMonitorGenericResource = "AzureNewRelicObservabilityMonitorGenericResource",
    AzureNexusIdentityIdentityControllerGenericResource = "AzureNexusIdentityIdentityControllerGenericResource",
    AzureNexusIdentityIdentitySetGenericResource = "AzureNexusIdentityIdentitySetGenericResource",
    AzureNginxNginxplusNginxDeploymentGenericResource = "AzureNginxNginxplusNginxDeploymentGenericResource",
    AzureNotificationHubsNamespaceGenericResource = "AzureNotificationHubsNamespaceGenericResource",
    AzureNotificationHubsNamespaceGenericResourceModel = "AzureNotificationHubsNamespaceGenericResourceModel",
    AzureNotificationHubsNamespacesNotificationHubGenericResource = "AzureNotificationHubsNamespacesNotificationHubGenericResource",
    AzureNutanixInterfaceGenericResource = "AzureNutanixInterfaceGenericResource",
    AzureNutanixNodeGenericResource = "AzureNutanixNodeGenericResource",
    AzureObjectStoreOsNamespaceGenericResource = "AzureObjectStoreOsNamespaceGenericResource",
    AzureOffApplianceGenericResource = "AzureOffApplianceGenericResource",
    AzureOffHypervSiteGenericResource = "AzureOffHypervSiteGenericResource",
    AzureOffImportSiteGenericResource = "AzureOffImportSiteGenericResource",
    AzureOffMasterSiteGenericResource = "AzureOffMasterSiteGenericResource",
    AzureOffServerSiteGenericResource = "AzureOffServerSiteGenericResource",
    AzureOffSpringBootSpringbootsiteGenericResource = "AzureOffSpringBootSpringbootsiteGenericResource",
    AzureOffVmwareSiteGenericResource = "AzureOffVmwareSiteGenericResource",
    AzureOpenEnergyPlatformEnergyServiceGenericResource = "AzureOpenEnergyPlatformEnergyServiceGenericResource",
    AzureOpenShiftBuildConfig = "AzureOpenShiftBuildConfig",
    AzureOpenShiftBuildConfigModel = "AzureOpenShiftBuildConfigModel",
    AzureOpenShiftCatalogSource = "AzureOpenShiftCatalogSource",
    AzureOpenShiftCatalogSourceModel = "AzureOpenShiftCatalogSourceModel",
    AzureOpenShiftDeploymentConfig = "AzureOpenShiftDeploymentConfig",
    AzureOpenShiftDeploymentConfigModel = "AzureOpenShiftDeploymentConfigModel",
    AzureOpenShiftImageStream = "AzureOpenShiftImageStream",
    AzureOpenShiftImageStreamModel = "AzureOpenShiftImageStreamModel",
    AzureOpenShiftProject = "AzureOpenShiftProject",
    AzureOpenShiftProjectModel = "AzureOpenShiftProjectModel",
    AzureOpenShiftRoute = "AzureOpenShiftRoute",
    AzureOpenShiftRouteModel = "AzureOpenShiftRouteModel",
    AzureOpenShiftSecurityContextConstraint = "AzureOpenShiftSecurityContextConstraint",
    AzureOpenShiftSecurityContextConstraintModel = "AzureOpenShiftSecurityContextConstraintModel",
    AzureOpenShiftTemplate = "AzureOpenShiftTemplate",
    AzureOpenShiftTemplateModel = "AzureOpenShiftTemplateModel",
    AzureOperationalInsightsClusterGenericResource = "AzureOperationalInsightsClusterGenericResource",
    AzureOperationalInsightsQuerypackGenericResource = "AzureOperationalInsightsQuerypackGenericResource",
    AzureOperationalInsightsWorkspaceGenericResource = "AzureOperationalInsightsWorkspaceGenericResource",
    AzureOperationsManagementSolutionGenericResource = "AzureOperationsManagementSolutionGenericResource",
    AzureOperationsManagementViewGenericResource = "AzureOperationsManagementViewGenericResource",
    AzureOracleDatabaseAutonomousDatabaseGenericResource = "AzureOracleDatabaseAutonomousDatabaseGenericResource",
    AzureOracleDatabaseCloudExadataInfrastructureGenericResource = "AzureOracleDatabaseCloudExadataInfrastructureGenericResource",
    AzureOracleDatabaseCloudVmClusterGenericResource = "AzureOracleDatabaseCloudVmClusterGenericResource",
    AzureOracleDiscoveryOracleSiteGenericResource = "AzureOracleDiscoveryOracleSiteGenericResource",
    AzureOrbitalCloudAccessRouterGenericResource = "AzureOrbitalCloudAccessRouterGenericResource",
    AzureOrbitalContactProfileGenericResource = "AzureOrbitalContactProfileGenericResource",
    AzureOrbitalEdgeSiteGenericResource = "AzureOrbitalEdgeSiteGenericResource",
    AzureOrbitalGeoCatalogGenericResource = "AzureOrbitalGeoCatalogGenericResource",
    AzureOrbitalGroundStationGenericResource = "AzureOrbitalGroundStationGenericResource",
    AzureOrbitalL2ConnectionGenericResource = "AzureOrbitalL2ConnectionGenericResource",
    AzureOrbitalSdwanControllerGenericResource = "AzureOrbitalSdwanControllerGenericResource",
    AzureOrbitalSpacecraftGenericResource = "AzureOrbitalSpacecraftGenericResource",
    AzureOrbitalTerminalGenericResource = "AzureOrbitalTerminalGenericResource",
    AzureOrganizationAuditEvent = "AzureOrganizationAuditEvent",
    AzureOrganizationAuditEventData = "AzureOrganizationAuditEventData",
    AzureOrganizationConfiguration = "AzureOrganizationConfiguration",
    AzureOrganizationCreationAuditEvent = "AzureOrganizationCreationAuditEvent",
    AzureOrganizationDeletionAuditEvent = "AzureOrganizationDeletionAuditEvent",
    AzureOrganizationState = "AzureOrganizationState",
    AzureOrganizationUpdateAuditEvent = "AzureOrganizationUpdateAuditEvent",
    AzurePaloAltoNetworksCloudngfwFirewallGenericResource = "AzurePaloAltoNetworksCloudngfwFirewallGenericResource",
    AzurePaloAltoNetworksCloudngfwLocalRulestackGenericResource = "AzurePaloAltoNetworksCloudngfwLocalRulestackGenericResource",
    AzurePeeringPeeringGenericResource = "AzurePeeringPeeringGenericResource",
    AzurePeeringPeeringGenericResourceModel = "AzurePeeringPeeringGenericResourceModel",
    AzurePeeringPeeringServiceGenericResource = "AzurePeeringPeeringServiceGenericResource",
    AzurePermissionManagementRisk = "AzurePermissionManagementRisk",
    AzurePermissionManagementRiskModel = "AzurePermissionManagementRiskModel",
    AzurePermissionManagementRiskPolicyConfiguration = "AzurePermissionManagementRiskPolicyConfiguration",
    AzurePermitterType = "AzurePermitterType",
    AzurePkiPkiGenericResource = "AzurePkiPkiGenericResource",
    AzurePlaywrightServiceAccountGenericResource = "AzurePlaywrightServiceAccountGenericResource",
    AzurePortalDashboardGenericResource = "AzurePortalDashboardGenericResource",
    AzurePostgreSqlFlexibleServer = "AzurePostgreSqlFlexibleServer",
    AzurePostgreSqlFlexibleServerAadOnlyAuthenticationDisabledCodeRisk = "AzurePostgreSqlFlexibleServerAadOnlyAuthenticationDisabledCodeRisk",
    AzurePostgreSqlFlexibleServerAadOnlyAuthenticationDisabledCodeRiskModel = "AzurePostgreSqlFlexibleServerAadOnlyAuthenticationDisabledCodeRiskModel",
    AzurePostgreSqlFlexibleServerAadOnlyAuthenticationDisabledRisk = "AzurePostgreSqlFlexibleServerAadOnlyAuthenticationDisabledRisk",
    AzurePostgreSqlFlexibleServerAadOnlyAuthenticationDisabledRiskModel = "AzurePostgreSqlFlexibleServerAadOnlyAuthenticationDisabledRiskModel",
    AzurePostgreSqlFlexibleServerAadOnlyAuthenticationDisabledRiskPolicyConfiguration = "AzurePostgreSqlFlexibleServerAadOnlyAuthenticationDisabledRiskPolicyConfiguration",
    AzurePostgreSqlFlexibleServerModel = "AzurePostgreSqlFlexibleServerModel",
    AzurePostgreSqlFlexibleServerProfile = "AzurePostgreSqlFlexibleServerProfile",
    AzurePostgreSqlServer = "AzurePostgreSqlServer",
    AzurePostgreSqlServerAuthenticationType = "AzurePostgreSqlServerAuthenticationType",
    AzurePostgreSqlServerConfiguration = "AzurePostgreSqlServerConfiguration",
    AzurePostgreSqlServerConnectionThrottlingDisabledCodeRisk = "AzurePostgreSqlServerConnectionThrottlingDisabledCodeRisk",
    AzurePostgreSqlServerConnectionThrottlingDisabledCodeRiskModel = "AzurePostgreSqlServerConnectionThrottlingDisabledCodeRiskModel",
    AzurePostgreSqlServerConnectionThrottlingDisabledRisk = "AzurePostgreSqlServerConnectionThrottlingDisabledRisk",
    AzurePostgreSqlServerConnectionThrottlingDisabledRiskModel = "AzurePostgreSqlServerConnectionThrottlingDisabledRiskModel",
    AzurePostgreSqlServerConnectionThrottlingDisabledRiskPolicyConfiguration = "AzurePostgreSqlServerConnectionThrottlingDisabledRiskPolicyConfiguration",
    AzurePostgreSqlServerHttpsOnlyDisabledCodeRisk = "AzurePostgreSqlServerHttpsOnlyDisabledCodeRisk",
    AzurePostgreSqlServerHttpsOnlyDisabledCodeRiskModel = "AzurePostgreSqlServerHttpsOnlyDisabledCodeRiskModel",
    AzurePostgreSqlServerHttpsOnlyDisabledRisk = "AzurePostgreSqlServerHttpsOnlyDisabledRisk",
    AzurePostgreSqlServerHttpsOnlyDisabledRiskModel = "AzurePostgreSqlServerHttpsOnlyDisabledRiskModel",
    AzurePostgreSqlServerHttpsOnlyDisabledRiskPolicyConfiguration = "AzurePostgreSqlServerHttpsOnlyDisabledRiskPolicyConfiguration",
    AzurePostgreSqlServerLogCheckpointsDisabledCodeRisk = "AzurePostgreSqlServerLogCheckpointsDisabledCodeRisk",
    AzurePostgreSqlServerLogCheckpointsDisabledCodeRiskModel = "AzurePostgreSqlServerLogCheckpointsDisabledCodeRiskModel",
    AzurePostgreSqlServerLogCheckpointsDisabledRisk = "AzurePostgreSqlServerLogCheckpointsDisabledRisk",
    AzurePostgreSqlServerLogCheckpointsDisabledRiskModel = "AzurePostgreSqlServerLogCheckpointsDisabledRiskModel",
    AzurePostgreSqlServerLogCheckpointsDisabledRiskPolicyConfiguration = "AzurePostgreSqlServerLogCheckpointsDisabledRiskPolicyConfiguration",
    AzurePostgreSqlServerLogConnectionsDisabledCodeRisk = "AzurePostgreSqlServerLogConnectionsDisabledCodeRisk",
    AzurePostgreSqlServerLogConnectionsDisabledCodeRiskModel = "AzurePostgreSqlServerLogConnectionsDisabledCodeRiskModel",
    AzurePostgreSqlServerLogConnectionsDisabledRisk = "AzurePostgreSqlServerLogConnectionsDisabledRisk",
    AzurePostgreSqlServerLogConnectionsDisabledRiskModel = "AzurePostgreSqlServerLogConnectionsDisabledRiskModel",
    AzurePostgreSqlServerLogConnectionsDisabledRiskPolicyConfiguration = "AzurePostgreSqlServerLogConnectionsDisabledRiskPolicyConfiguration",
    AzurePostgreSqlServerLogDisconnectionsDisabledCodeRisk = "AzurePostgreSqlServerLogDisconnectionsDisabledCodeRisk",
    AzurePostgreSqlServerLogDisconnectionsDisabledCodeRiskModel = "AzurePostgreSqlServerLogDisconnectionsDisabledCodeRiskModel",
    AzurePostgreSqlServerLogDisconnectionsDisabledRisk = "AzurePostgreSqlServerLogDisconnectionsDisabledRisk",
    AzurePostgreSqlServerLogDisconnectionsDisabledRiskModel = "AzurePostgreSqlServerLogDisconnectionsDisabledRiskModel",
    AzurePostgreSqlServerLogDisconnectionsDisabledRiskPolicyConfiguration = "AzurePostgreSqlServerLogDisconnectionsDisabledRiskPolicyConfiguration",
    AzurePostgreSqlServerLogRetentionCodeRisk = "AzurePostgreSqlServerLogRetentionCodeRisk",
    AzurePostgreSqlServerLogRetentionCodeRiskModel = "AzurePostgreSqlServerLogRetentionCodeRiskModel",
    AzurePostgreSqlServerLogRetentionRisk = "AzurePostgreSqlServerLogRetentionRisk",
    AzurePostgreSqlServerLogRetentionRiskModel = "AzurePostgreSqlServerLogRetentionRiskModel",
    AzurePostgreSqlServerLogRetentionRiskPolicyConfiguration = "AzurePostgreSqlServerLogRetentionRiskPolicyConfiguration",
    AzurePostgreSqlServerModel = "AzurePostgreSqlServerModel",
    AzurePostgreSqlServerSkuTier = "AzurePostgreSqlServerSkuTier",
    AzurePostgreSqlServerStatus = "AzurePostgreSqlServerStatus",
    AzurePostgreSqlServerWideRangeInboundCodeRisk = "AzurePostgreSqlServerWideRangeInboundCodeRisk",
    AzurePostgreSqlServerWideRangeInboundCodeRiskModel = "AzurePostgreSqlServerWideRangeInboundCodeRiskModel",
    AzurePostgreSqlServerWideRangeInboundRisk = "AzurePostgreSqlServerWideRangeInboundRisk",
    AzurePostgreSqlServerWideRangeInboundRiskModel = "AzurePostgreSqlServerWideRangeInboundRiskModel",
    AzurePostgreSqlServerWideRangeInboundRiskPolicyConfiguration = "AzurePostgreSqlServerWideRangeInboundRiskPolicyConfiguration",
    AzurePostgreSqlSingleServer = "AzurePostgreSqlSingleServer",
    AzurePostgreSqlSingleServerModel = "AzurePostgreSqlSingleServerModel",
    AzurePostgreSqlSingleServerProfile = "AzurePostgreSqlSingleServerProfile",
    AzurePowerBiDedicatedAutoScaleVCoreGenericResource = "AzurePowerBiDedicatedAutoScaleVCoreGenericResource",
    AzurePowerBiDedicatedCapacityGenericResource = "AzurePowerBiDedicatedCapacityGenericResource",
    AzurePowerBiPrivateLinkServicesForPowerBiGenericResource = "AzurePowerBiPrivateLinkServicesForPowerBiGenericResource",
    AzurePowerBiTenantGenericResource = "AzurePowerBiTenantGenericResource",
    AzurePowerBiWorkspaceCollectionGenericResource = "AzurePowerBiWorkspaceCollectionGenericResource",
    AzurePowerBiWorkspaceCollectionGenericResourceModel = "AzurePowerBiWorkspaceCollectionGenericResourceModel",
    AzurePowerPlatformAccountGenericResource = "AzurePowerPlatformAccountGenericResource",
    AzurePowerPlatformEnterprisePolicyGenericResource = "AzurePowerPlatformEnterprisePolicyGenericResource",
    AzurePrincipalAllowedResourcePermissionRisk = "AzurePrincipalAllowedResourcePermissionRisk",
    AzurePrincipalAllowedResourcePermissionRiskModel = "AzurePrincipalAllowedResourcePermissionRiskModel",
    AzurePrincipalAllowedResourcePermissionRiskPolicyConfiguration = "AzurePrincipalAllowedResourcePermissionRiskPolicyConfiguration",
    AzurePrincipalNotAllowedResourcePermissionRisk = "AzurePrincipalNotAllowedResourcePermissionRisk",
    AzurePrincipalNotAllowedResourcePermissionRiskModel = "AzurePrincipalNotAllowedResourcePermissionRiskModel",
    AzurePrincipalNotAllowedResourcePermissionRiskPolicyConfiguration = "AzurePrincipalNotAllowedResourcePermissionRiskPolicyConfiguration",
    AzurePrincipalNotAllowedResourceRoleDefinitionRisk = "AzurePrincipalNotAllowedResourceRoleDefinitionRisk",
    AzurePrincipalNotAllowedResourceRoleDefinitionRiskItem = "AzurePrincipalNotAllowedResourceRoleDefinitionRiskItem",
    AzurePrincipalNotAllowedResourceRoleDefinitionRiskModel = "AzurePrincipalNotAllowedResourceRoleDefinitionRiskModel",
    AzurePrincipalNotAllowedResourceRoleDefinitionRiskPolicyConfiguration = "AzurePrincipalNotAllowedResourceRoleDefinitionRiskPolicyConfiguration",
    AzurePrincipalNotAllowedResourceRoleDefinitionRiskPolicyConfigurationRoleDefinitions = "AzurePrincipalNotAllowedResourceRoleDefinitionRiskPolicyConfigurationRoleDefinitions",
    AzurePrincipalNotAllowedResourceRoleDefinitionRiskPolicyConfigurationRoleDefinitionsOperator = "AzurePrincipalNotAllowedResourceRoleDefinitionRiskPolicyConfigurationRoleDefinitionsOperator",
    AzurePrincipalResourcePermissionRisk = "AzurePrincipalResourcePermissionRisk",
    AzurePrincipalResourcePermissionRiskItem = "AzurePrincipalResourcePermissionRiskItem",
    AzurePrincipalResourcePermissionRiskModel = "AzurePrincipalResourcePermissionRiskModel",
    AzurePrincipalResourcePermissionRiskPolicyConfiguration = "AzurePrincipalResourcePermissionRiskPolicyConfiguration",
    AzureProfessionalServiceResourceGenericResource = "AzureProfessionalServiceResourceGenericResource",
    AzureProgrammableConnectivityGatewayGenericResource = "AzureProgrammableConnectivityGatewayGenericResource",
    AzureProgrammableConnectivityOperatorApiConnectionGenericResource = "AzureProgrammableConnectivityOperatorApiConnectionGenericResource",
    AzureProgrammableConnectivityOperatorConnectionGenericResource = "AzureProgrammableConnectivityOperatorConnectionGenericResource",
    AzureProviderHubProviderMonitorSettingGenericResource = "AzureProviderHubProviderMonitorSettingGenericResource",
    AzurePurviewAccountGenericResource = "AzurePurviewAccountGenericResource",
    AzureQuantumWorkspaceGenericResource = "AzureQuantumWorkspaceGenericResource",
    AzureQuantumWorkspaceGenericResourceModel = "AzureQuantumWorkspaceGenericResourceModel",
    AzureQumuloStorageFileSystemGenericResource = "AzureQumuloStorageFileSystemGenericResource",
    AzureRecommendationsServiceAccountGenericResource = "AzureRecommendationsServiceAccountGenericResource",
    AzureRecommendationsServiceAccountGenericResourceModel = "AzureRecommendationsServiceAccountGenericResourceModel",
    AzureRecommendationsServiceAccountsModelingGenericResource = "AzureRecommendationsServiceAccountsModelingGenericResource",
    AzureRecommendationsServiceAccountsServiceEndpointGenericResource = "AzureRecommendationsServiceAccountsServiceEndpointGenericResource",
    AzureRecoveryServicesVaultGenericResource = "AzureRecoveryServicesVaultGenericResource",
    AzureRecoveryServicesVaultGenericResourceModel = "AzureRecoveryServicesVaultGenericResourceModel",
    AzureRedHatOpenShiftOpenShiftClusterGenericResource = "AzureRedHatOpenShiftOpenShiftClusterGenericResource",
    AzureRedHatOpenShiftOpenShiftClusterGenericResourceModel = "AzureRedHatOpenShiftOpenShiftClusterGenericResourceModel",
    AzureRelayNamespaceGenericResource = "AzureRelayNamespaceGenericResource",
    AzureResource = "AzureResource",
    AzureResourceChange = "AzureResourceChange",
    AzureResourceCodeResourceNotExistsRisk = "AzureResourceCodeResourceNotExistsRisk",
    AzureResourceCodeResourceNotExistsRiskModel = "AzureResourceCodeResourceNotExistsRiskModel",
    AzureResourceCodeResourceNotExistsRiskPolicyConfiguration = "AzureResourceCodeResourceNotExistsRiskPolicyConfiguration",
    AzureResourceConnectorApplianceGenericResource = "AzureResourceConnectorApplianceGenericResource",
    AzureResourceFirewallIpAddressRangeRule = "AzureResourceFirewallIpAddressRangeRule",
    AzureResourceFirewallVirtualNetworkSubnetRule = "AzureResourceFirewallVirtualNetworkSubnetRule",
    AzureResourceGraphQueryGenericResource = "AzureResourceGraphQueryGenericResource",
    AzureResourceGroupResource = "AzureResourceGroupResource",
    AzureResourceGroupResourceModel = "AzureResourceGroupResourceModel",
    AzureResourceGroupResourceTypeMetadata = "AzureResourceGroupResourceTypeMetadata",
    AzureResourceInboundExternalPortRisk = "AzureResourceInboundExternalPortRisk",
    AzureResourceInboundExternalPortRiskItem = "AzureResourceInboundExternalPortRiskItem",
    AzureResourceInboundExternalPortRiskModel = "AzureResourceInboundExternalPortRiskModel",
    AzureResourceInboundExternalPortRiskPolicyConfiguration = "AzureResourceInboundExternalPortRiskPolicyConfiguration",
    AzureResourceManagerDeploymentScriptGenericResource = "AzureResourceManagerDeploymentScriptGenericResource",
    AzureResourceManagerMoboBrokerGenericResource = "AzureResourceManagerMoboBrokerGenericResource",
    AzureResourceManagerTemplateSpecGenericResource = "AzureResourceManagerTemplateSpecGenericResource",
    AzureResourceManagerTemplateSpecsVersionGenericResource = "AzureResourceManagerTemplateSpecsVersionGenericResource",
    AzureResourceModel = "AzureResourceModel",
    AzureResourceModelFilters = "AzureResourceModelFilters",
    AzureResourceProfile = "AzureResourceProfile",
    AzureResourceProfileCreation = "AzureResourceProfileCreation",
    AzureResourcesGenericResource = "AzureResourcesGenericResource",
    AzureResourcesGenericResourceModel = "AzureResourcesGenericResourceModel",
    AzureResourcesGenericResourceProfile = "AzureResourcesGenericResourceProfile",
    AzureResourcesGenericResourceTypeMetadata = "AzureResourcesGenericResourceTypeMetadata",
    AzureResourcesResourceGroup = "AzureResourcesResourceGroup",
    AzureResourcesResourceGroupModel = "AzureResourcesResourceGroupModel",
    AzureResourcesResourceGroupProfile = "AzureResourcesResourceGroupProfile",
    AzureResourceTagNotExistsRisk = "AzureResourceTagNotExistsRisk",
    AzureResourceTagNotExistsRiskModel = "AzureResourceTagNotExistsRiskModel",
    AzureResourceTagNotExistsRiskPolicyConfiguration = "AzureResourceTagNotExistsRiskPolicyConfiguration",
    AzureResourceTagSecretExistsRisk = "AzureResourceTagSecretExistsRisk",
    AzureResourceTagSecretExistsRiskModel = "AzureResourceTagSecretExistsRiskModel",
    AzureResourceTagSecretExistsRiskPolicyConfiguration = "AzureResourceTagSecretExistsRiskPolicyConfiguration",
    AzureResourceTypeMetadata = "AzureResourceTypeMetadata",
    AzureRoleAssignmentEligibilityConfiguration = "AzureRoleAssignmentEligibilityConfiguration",
    AzureRoleAssignmentEligibilityModel = "AzureRoleAssignmentEligibilityModel",
    AzureRoleAssignmentRequest = "AzureRoleAssignmentRequest",
    AzureRoleAssignmentRequestActivationFailureData = "AzureRoleAssignmentRequestActivationFailureData",
    AzureRoot = "AzureRoot",
    AzureRootModel = "AzureRootModel",
    AzureRootTypeMetadata = "AzureRootTypeMetadata",
    AzureSaaSApplicationGenericResource = "AzureSaaSApplicationGenericResource",
    AzureSaaSHubCloudServiceGenericResource = "AzureSaaSHubCloudServiceGenericResource",
    AzureSaaSResourceGenericResource = "AzureSaaSResourceGenericResource",
    AzureScanScanningAccountGenericResource = "AzureScanScanningAccountGenericResource",
    AzureScomManagedInstanceGenericResource = "AzureScomManagedInstanceGenericResource",
    AzureScopeConfigurationDataAnalysis = "AzureScopeConfigurationDataAnalysis",
    AzureScopeConfigurationOutpost = "AzureScopeConfigurationOutpost",
    AzureScopeConfigurationOutpostData = "AzureScopeConfigurationOutpostData",
    AzureScopeConfigurationOutpostDataStorageAccount = "AzureScopeConfigurationOutpostDataStorageAccount",
    AzureScopeConfigurationOutpostDataVirtualNetwork = "AzureScopeConfigurationOutpostDataVirtualNetwork",
    AzureScopeConfigurationSections = "AzureScopeConfigurationSections",
    AzureScopeConfigurationWorkloadAnalysis = "AzureScopeConfigurationWorkloadAnalysis",
    AzureScopeEntityReference = "AzureScopeEntityReference",
    AzureScopeResource = "AzureScopeResource",
    AzureScopeResourceModel = "AzureScopeResourceModel",
    AzureScopeResourceParentEntityIdMatchCondition = "AzureScopeResourceParentEntityIdMatchCondition",
    AzureScopeResourceTypeMetadata = "AzureScopeResourceTypeMetadata",
    AzureScvmmAvailabilitySetGenericResource = "AzureScvmmAvailabilitySetGenericResource",
    AzureScvmmCloudGenericResource = "AzureScvmmCloudGenericResource",
    AzureScvmmVirtualmachineGenericResource = "AzureScvmmVirtualmachineGenericResource",
    AzureScvmmVirtualMachinesExtensionGenericResource = "AzureScvmmVirtualMachinesExtensionGenericResource",
    AzureScvmmVirtualmachinetemplateGenericResource = "AzureScvmmVirtualmachinetemplateGenericResource",
    AzureScvmmVirtualnetworkGenericResource = "AzureScvmmVirtualnetworkGenericResource",
    AzureScvmmVmmserverGenericResource = "AzureScvmmVmmserverGenericResource",
    AzureSearchSearchServiceGenericResource = "AzureSearchSearchServiceGenericResource",
    AzureSecretManagementSampleProviderForecastGenericResource = "AzureSecretManagementSampleProviderForecastGenericResource",
    AzureSecretSyncControllerAzureKeyVaultSecretProviderClassGenericResource = "AzureSecretSyncControllerAzureKeyVaultSecretProviderClassGenericResource",
    AzureSecretSyncControllerSecretSyncGenericResource = "AzureSecretSyncControllerSecretSyncGenericResource",
    AzureSecurityAssignmentGenericResource = "AzureSecurityAssignmentGenericResource",
    AzureSecurityAutomationGenericResource = "AzureSecurityAutomationGenericResource",
    AzureSecurityCopilotCapacityGenericResource = "AzureSecurityCopilotCapacityGenericResource",
    AzureSecurityCustomAssessmentAutomationGenericResource = "AzureSecurityCustomAssessmentAutomationGenericResource",
    AzureSecurityDataScannerGenericResource = "AzureSecurityDataScannerGenericResource",
    AzureSecurityDetonationChamberGenericResource = "AzureSecurityDetonationChamberGenericResource",
    AzureSecurityIotSecuritySolutionGenericResource = "AzureSecurityIotSecuritySolutionGenericResource",
    AzureSecurityPlatformAccountLinkGenericResource = "AzureSecurityPlatformAccountLinkGenericResource",
    AzureSecuritySecurityConnectorGenericResource = "AzureSecuritySecurityConnectorGenericResource",
    AzureSecurityStandardGenericResource = "AzureSecurityStandardGenericResource",
    AzureService = "AzureService",
    AzureServiceBusNamespaceGenericResource = "AzureServiceBusNamespaceGenericResource",
    AzureServiceBusNamespaceGenericResourceModel = "AzureServiceBusNamespaceGenericResourceModel",
    AzureServiceFabricClusterGenericResource = "AzureServiceFabricClusterGenericResource",
    AzureServiceFabricClusterGenericResourceModel = "AzureServiceFabricClusterGenericResourceModel",
    AzureServiceFabricManagedclusterGenericResource = "AzureServiceFabricManagedclusterGenericResource",
    AzureServiceFabricManagedclusterGenericResourceModel = "AzureServiceFabricManagedclusterGenericResourceModel",
    AzureServiceFabricMeshApplicationGenericResource = "AzureServiceFabricMeshApplicationGenericResource",
    AzureServiceFabricMeshGatewayGenericResource = "AzureServiceFabricMeshGatewayGenericResource",
    AzureServiceFabricMeshNetworkGenericResource = "AzureServiceFabricMeshNetworkGenericResource",
    AzureServiceFabricMeshSecretGenericResource = "AzureServiceFabricMeshSecretGenericResource",
    AzureServiceFabricMeshVolumeGenericResource = "AzureServiceFabricMeshVolumeGenericResource",
    AzureServiceModel = "AzureServiceModel",
    AzureServiceNetworkingTrafficControllerGenericResource = "AzureServiceNetworkingTrafficControllerGenericResource",
    AzureServiceNetworkingTrafficControllersAssociationGenericResource = "AzureServiceNetworkingTrafficControllersAssociationGenericResource",
    AzureServiceNetworkingTrafficControllersFrontendGenericResource = "AzureServiceNetworkingTrafficControllersFrontendGenericResource",
    AzureServiceNetworkingTrafficControllersSecurityPolicyGenericResource = "AzureServiceNetworkingTrafficControllersSecurityPolicyGenericResource",
    AzureServicesHubConnectorGenericResource = "AzureServicesHubConnectorGenericResource",
    AzureServicesHubConnectorGenericResourceModel = "AzureServicesHubConnectorGenericResourceModel",
    AzureSeverePermissionPrincipalRisk = "AzureSeverePermissionPrincipalRisk",
    AzureSeverePermissionPrincipalRiskModel = "AzureSeverePermissionPrincipalRiskModel",
    AzureSeverePermissionPrincipalRiskModelInfo = "AzureSeverePermissionPrincipalRiskModelInfo",
    AzureSeverePermissionPrincipalRiskPolicyConfiguration = "AzureSeverePermissionPrincipalRiskPolicyConfiguration",
    AzureSignalRServiceSignalRGenericResource = "AzureSignalRServiceSignalRGenericResource",
    AzureSignalRServiceSignalRGenericResourceModel = "AzureSignalRServiceSignalRGenericResourceModel",
    AzureSignalRServiceSignalRReplicaGenericResource = "AzureSignalRServiceSignalRReplicaGenericResource",
    AzureSignalRServiceWebPubSubGenericResource = "AzureSignalRServiceWebPubSubGenericResource",
    AzureSignalRServiceWebPubSubReplicaGenericResource = "AzureSignalRServiceWebPubSubReplicaGenericResource",
    AzureSingularityAccountGenericResource = "AzureSingularityAccountGenericResource",
    AzureSoftwarePlanSoftwareSubscriptionGenericResource = "AzureSoftwarePlanSoftwareSubscriptionGenericResource",
    AzureSolarWindsObservabilityOrganizationGenericResource = "AzureSolarWindsObservabilityOrganizationGenericResource",
    AzureSolutionsApplicationDefinitionGenericResource = "AzureSolutionsApplicationDefinitionGenericResource",
    AzureSolutionsApplicationGenericResource = "AzureSolutionsApplicationGenericResource",
    AzureSolutionsJitRequestGenericResource = "AzureSolutionsJitRequestGenericResource",
    AzureSovereignLandingZoneAccountGenericResource = "AzureSovereignLandingZoneAccountGenericResource",
    AzureSphereCatalogGenericResource = "AzureSphereCatalogGenericResource",
    AzureSphereCatalogsProductGenericResource = "AzureSphereCatalogsProductGenericResource",
    AzureSqlInstancePoolGenericResource = "AzureSqlInstancePoolGenericResource",
    AzureSqlManagedInstanceGenericResource = "AzureSqlManagedInstanceGenericResource",
    AzureSqlManagedInstancesDatabaseGenericResource = "AzureSqlManagedInstancesDatabaseGenericResource",
    AzureSqlServer = "AzureSqlServer",
    AzureSqlServerAadAuthenticationAdministratorPrincipalNotExistsCodeRisk = "AzureSqlServerAadAuthenticationAdministratorPrincipalNotExistsCodeRisk",
    AzureSqlServerAadAuthenticationAdministratorPrincipalNotExistsCodeRiskModel = "AzureSqlServerAadAuthenticationAdministratorPrincipalNotExistsCodeRiskModel",
    AzureSqlServerAadAuthenticationAdministratorPrincipalNotExistsRisk = "AzureSqlServerAadAuthenticationAdministratorPrincipalNotExistsRisk",
    AzureSqlServerAadAuthenticationAdministratorPrincipalNotExistsRiskModel = "AzureSqlServerAadAuthenticationAdministratorPrincipalNotExistsRiskModel",
    AzureSqlServerAadAuthenticationAdministratorPrincipalNotExistsRiskPolicyConfiguration = "AzureSqlServerAadAuthenticationAdministratorPrincipalNotExistsRiskPolicyConfiguration",
    AzureSqlServerAadOnlyAuthenticationDisabledCodeRisk = "AzureSqlServerAadOnlyAuthenticationDisabledCodeRisk",
    AzureSqlServerAadOnlyAuthenticationDisabledCodeRiskModel = "AzureSqlServerAadOnlyAuthenticationDisabledCodeRiskModel",
    AzureSqlServerAadOnlyAuthenticationDisabledRisk = "AzureSqlServerAadOnlyAuthenticationDisabledRisk",
    AzureSqlServerAadOnlyAuthenticationDisabledRiskModel = "AzureSqlServerAadOnlyAuthenticationDisabledRiskModel",
    AzureSqlServerAadOnlyAuthenticationDisabledRiskPolicyConfiguration = "AzureSqlServerAadOnlyAuthenticationDisabledRiskPolicyConfiguration",
    AzureSqlServerAuthenticationType = "AzureSqlServerAuthenticationType",
    AzureSqlServerDatabase = "AzureSqlServerDatabase",
    AzureSqlServerDatabaseEncryptionDisabledCodeRisk = "AzureSqlServerDatabaseEncryptionDisabledCodeRisk",
    AzureSqlServerDatabaseEncryptionDisabledCodeRiskModel = "AzureSqlServerDatabaseEncryptionDisabledCodeRiskModel",
    AzureSqlServerDatabaseEncryptionDisabledRisk = "AzureSqlServerDatabaseEncryptionDisabledRisk",
    AzureSqlServerDatabaseEncryptionDisabledRiskModel = "AzureSqlServerDatabaseEncryptionDisabledRiskModel",
    AzureSqlServerDatabaseEncryptionDisabledRiskPolicyConfiguration = "AzureSqlServerDatabaseEncryptionDisabledRiskPolicyConfiguration",
    AzureSqlServerDatabaseModel = "AzureSqlServerDatabaseModel",
    AzureSqlServerDatabaseProfile = "AzureSqlServerDatabaseProfile",
    AzureSqlServerDatabaseStatus = "AzureSqlServerDatabaseStatus",
    AzureSqlServerDefaultEncryptionCodeRisk = "AzureSqlServerDefaultEncryptionCodeRisk",
    AzureSqlServerDefaultEncryptionCodeRiskModel = "AzureSqlServerDefaultEncryptionCodeRiskModel",
    AzureSqlServerDefaultEncryptionRisk = "AzureSqlServerDefaultEncryptionRisk",
    AzureSqlServerDefaultEncryptionRiskModel = "AzureSqlServerDefaultEncryptionRiskModel",
    AzureSqlServerDefaultEncryptionRiskPolicyConfiguration = "AzureSqlServerDefaultEncryptionRiskPolicyConfiguration",
    AzureSqlServerEventDisabledCodeRisk = "AzureSqlServerEventDisabledCodeRisk",
    AzureSqlServerEventDisabledCodeRiskModel = "AzureSqlServerEventDisabledCodeRiskModel",
    AzureSqlServerEventDisabledRisk = "AzureSqlServerEventDisabledRisk",
    AzureSqlServerEventDisabledRiskModel = "AzureSqlServerEventDisabledRiskModel",
    AzureSqlServerEventDisabledRiskPolicyConfiguration = "AzureSqlServerEventDisabledRiskPolicyConfiguration",
    AzureSqlServerEventRetentionTimeFrameCodeRisk = "AzureSqlServerEventRetentionTimeFrameCodeRisk",
    AzureSqlServerEventRetentionTimeFrameCodeRiskModel = "AzureSqlServerEventRetentionTimeFrameCodeRiskModel",
    AzureSqlServerEventRetentionTimeFrameRisk = "AzureSqlServerEventRetentionTimeFrameRisk",
    AzureSqlServerEventRetentionTimeFrameRiskModel = "AzureSqlServerEventRetentionTimeFrameRiskModel",
    AzureSqlServerEventRetentionTimeFrameRiskPolicyConfiguration = "AzureSqlServerEventRetentionTimeFrameRiskPolicyConfiguration",
    AzureSqlServerEventRetentionTimeFrameRiskPolicyUpdateAuditEventEventRetentionTimeFrameChange = "AzureSqlServerEventRetentionTimeFrameRiskPolicyUpdateAuditEventEventRetentionTimeFrameChange",
    AzureSqlServerGenericResource = "AzureSqlServerGenericResource",
    AzureSqlServerModel = "AzureSqlServerModel",
    AzureSqlServerModelFilters = "AzureSqlServerModelFilters",
    AzureSqlServerProfile = "AzureSqlServerProfile",
    AzureSqlServersDatabaseGenericResource = "AzureSqlServersDatabaseGenericResource",
    AzureSqlServerSecurityAlertPolicyDisabledCodeRisk = "AzureSqlServerSecurityAlertPolicyDisabledCodeRisk",
    AzureSqlServerSecurityAlertPolicyDisabledCodeRiskModel = "AzureSqlServerSecurityAlertPolicyDisabledCodeRiskModel",
    AzureSqlServerSecurityAlertPolicyDisabledRisk = "AzureSqlServerSecurityAlertPolicyDisabledRisk",
    AzureSqlServerSecurityAlertPolicyDisabledRiskModel = "AzureSqlServerSecurityAlertPolicyDisabledRiskModel",
    AzureSqlServerSecurityAlertPolicyDisabledRiskPolicyConfiguration = "AzureSqlServerSecurityAlertPolicyDisabledRiskPolicyConfiguration",
    AzureSqlServersElasticPoolGenericResource = "AzureSqlServersElasticPoolGenericResource",
    AzureSqlServersJobAccountGenericResource = "AzureSqlServersJobAccountGenericResource",
    AzureSqlServersJobAgentGenericResource = "AzureSqlServersJobAgentGenericResource",
    AzureSqlServerStatus = "AzureSqlServerStatus",
    AzureSqlServerWideRangeInboundCodeRisk = "AzureSqlServerWideRangeInboundCodeRisk",
    AzureSqlServerWideRangeInboundCodeRiskModel = "AzureSqlServerWideRangeInboundCodeRiskModel",
    AzureSqlServerWideRangeInboundRisk = "AzureSqlServerWideRangeInboundRisk",
    AzureSqlServerWideRangeInboundRiskModel = "AzureSqlServerWideRangeInboundRiskModel",
    AzureSqlServerWideRangeInboundRiskPolicyConfiguration = "AzureSqlServerWideRangeInboundRiskPolicyConfiguration",
    AzureSqlVirtualClusterGenericResource = "AzureSqlVirtualClusterGenericResource",
    AzureSqlVirtualMachineSqlVirtualMachineGenericResource = "AzureSqlVirtualMachineSqlVirtualMachineGenericResource",
    AzureSqlVirtualMachineSqlVirtualMachineGroupGenericResource = "AzureSqlVirtualMachineSqlVirtualMachineGroupGenericResource",
    AzureStackHciClusterGenericResource = "AzureStackHciClusterGenericResource",
    AzureStackHciEdgeNodePoolGenericResource = "AzureStackHciEdgeNodePoolGenericResource",
    AzureStackHciGalleryImageGenericResource = "AzureStackHciGalleryImageGenericResource",
    AzureStackHciLogicalNetworkGenericResource = "AzureStackHciLogicalNetworkGenericResource",
    AzureStackHciMarketplaceGalleryImageGenericResource = "AzureStackHciMarketplaceGalleryImageGenericResource",
    AzureStackHciNetworkInterfaceGenericResource = "AzureStackHciNetworkInterfaceGenericResource",
    AzureStackHciNetworkSecurityGroupGenericResource = "AzureStackHciNetworkSecurityGroupGenericResource",
    AzureStackHciStorageContainerGenericResource = "AzureStackHciStorageContainerGenericResource",
    AzureStackHciVirtualHardDiskGenericResource = "AzureStackHciVirtualHardDiskGenericResource",
    AzureStackHciVirtualMachineGenericResource = "AzureStackHciVirtualMachineGenericResource",
    AzureStackHciVirtualMachinesExtensionGenericResource = "AzureStackHciVirtualMachinesExtensionGenericResource",
    AzureStackHciVirtualNetworkGenericResource = "AzureStackHciVirtualNetworkGenericResource",
    AzureStackLinkedSubscriptionGenericResource = "AzureStackLinkedSubscriptionGenericResource",
    AzureStackRegistrationGenericResource = "AzureStackRegistrationGenericResource",
    AzureStandbyPoolStandbyContainerGroupPoolGenericResource = "AzureStandbyPoolStandbyContainerGroupPoolGenericResource",
    AzureStandbyPoolStandbyVirtualMachinePoolGenericResource = "AzureStandbyPoolStandbyVirtualMachinePoolGenericResource",
    AzureStorageActionsStorageTaskGenericResource = "AzureStorageActionsStorageTaskGenericResource",
    AzureStorageCacheAmlFilesystemGenericResource = "AzureStorageCacheAmlFilesystemGenericResource",
    AzureStorageCacheCacheGenericResource = "AzureStorageCacheCacheGenericResource",
    AzureStorageMoverStorageMoverGenericResource = "AzureStorageMoverStorageMoverGenericResource",
    AzureStorageResourceStateNetworkFirewallRuleData = "AzureStorageResourceStateNetworkFirewallRuleData",
    AzureStorageStorageAccount = "AzureStorageStorageAccount",
    AzureStorageStorageAccountAllowAnyAzureTrustedServicesNetworkAccessDisabledCodeRisk = "AzureStorageStorageAccountAllowAnyAzureTrustedServicesNetworkAccessDisabledCodeRisk",
    AzureStorageStorageAccountAllowAnyAzureTrustedServicesNetworkAccessDisabledCodeRiskModel = "AzureStorageStorageAccountAllowAnyAzureTrustedServicesNetworkAccessDisabledCodeRiskModel",
    AzureStorageStorageAccountAllowAnyAzureTrustedServicesNetworkAccessDisabledRisk = "AzureStorageStorageAccountAllowAnyAzureTrustedServicesNetworkAccessDisabledRisk",
    AzureStorageStorageAccountAllowAnyAzureTrustedServicesNetworkAccessDisabledRiskModel = "AzureStorageStorageAccountAllowAnyAzureTrustedServicesNetworkAccessDisabledRiskModel",
    AzureStorageStorageAccountAllowAnyAzureTrustedServicesNetworkAccessDisabledRiskPolicyConfiguration = "AzureStorageStorageAccountAllowAnyAzureTrustedServicesNetworkAccessDisabledRiskPolicyConfiguration",
    AzureStorageStorageAccountBlobContainer = "AzureStorageStorageAccountBlobContainer",
    AzureStorageStorageAccountBlobContainerLeaseState = "AzureStorageStorageAccountBlobContainerLeaseState",
    AzureStorageStorageAccountBlobContainerModel = "AzureStorageStorageAccountBlobContainerModel",
    AzureStorageStorageAccountBlobContainerModelFilters = "AzureStorageStorageAccountBlobContainerModelFilters",
    AzureStorageStorageAccountBlobContainerProfile = "AzureStorageStorageAccountBlobContainerProfile",
    AzureStorageStorageAccountBlobContainerPublicAccess = "AzureStorageStorageAccountBlobContainerPublicAccess",
    AzureStorageStorageAccountBlobContainerPublicAccessExistsCodeRisk = "AzureStorageStorageAccountBlobContainerPublicAccessExistsCodeRisk",
    AzureStorageStorageAccountBlobContainerPublicAccessExistsCodeRiskModel = "AzureStorageStorageAccountBlobContainerPublicAccessExistsCodeRiskModel",
    AzureStorageStorageAccountBlobContainerPublicAccessExistsRisk = "AzureStorageStorageAccountBlobContainerPublicAccessExistsRisk",
    AzureStorageStorageAccountBlobContainerPublicAccessExistsRiskModel = "AzureStorageStorageAccountBlobContainerPublicAccessExistsRiskModel",
    AzureStorageStorageAccountBlobContainerPublicAccessExistsRiskPolicyConfiguration = "AzureStorageStorageAccountBlobContainerPublicAccessExistsRiskPolicyConfiguration",
    AzureStorageStorageAccountBlobServiceDiagnosticSettingsLogCategoryNotEnabledCodeRisk = "AzureStorageStorageAccountBlobServiceDiagnosticSettingsLogCategoryNotEnabledCodeRisk",
    AzureStorageStorageAccountBlobServiceDiagnosticSettingsLogCategoryNotEnabledCodeRiskModel = "AzureStorageStorageAccountBlobServiceDiagnosticSettingsLogCategoryNotEnabledCodeRiskModel",
    AzureStorageStorageAccountBlobServiceDiagnosticSettingsLogCategoryNotEnabledRisk = "AzureStorageStorageAccountBlobServiceDiagnosticSettingsLogCategoryNotEnabledRisk",
    AzureStorageStorageAccountBlobServiceDiagnosticSettingsLogCategoryNotEnabledRiskModel = "AzureStorageStorageAccountBlobServiceDiagnosticSettingsLogCategoryNotEnabledRiskModel",
    AzureStorageStorageAccountBlobServiceDiagnosticSettingsLogCategoryNotEnabledRiskPolicyConfiguration = "AzureStorageStorageAccountBlobServiceDiagnosticSettingsLogCategoryNotEnabledRiskPolicyConfiguration",
    AzureStorageStorageAccountDefaultEncryptionCodeRisk = "AzureStorageStorageAccountDefaultEncryptionCodeRisk",
    AzureStorageStorageAccountDefaultEncryptionCodeRiskModel = "AzureStorageStorageAccountDefaultEncryptionCodeRiskModel",
    AzureStorageStorageAccountDefaultEncryptionRisk = "AzureStorageStorageAccountDefaultEncryptionRisk",
    AzureStorageStorageAccountDefaultEncryptionRiskModel = "AzureStorageStorageAccountDefaultEncryptionRiskModel",
    AzureStorageStorageAccountDefaultEncryptionRiskPolicyConfiguration = "AzureStorageStorageAccountDefaultEncryptionRiskPolicyConfiguration",
    AzureStorageStorageAccountEncryptionKeySource = "AzureStorageStorageAccountEncryptionKeySource",
    AzureStorageStorageAccountFileShare = "AzureStorageStorageAccountFileShare",
    AzureStorageStorageAccountFileShareModel = "AzureStorageStorageAccountFileShareModel",
    AzureStorageStorageAccountFileShareProfile = "AzureStorageStorageAccountFileShareProfile",
    AzureStorageStorageAccountGenericResource = "AzureStorageStorageAccountGenericResource",
    AzureStorageStorageAccountHttpsOnlyDisabledCodeRisk = "AzureStorageStorageAccountHttpsOnlyDisabledCodeRisk",
    AzureStorageStorageAccountHttpsOnlyDisabledCodeRiskModel = "AzureStorageStorageAccountHttpsOnlyDisabledCodeRiskModel",
    AzureStorageStorageAccountHttpsOnlyDisabledRisk = "AzureStorageStorageAccountHttpsOnlyDisabledRisk",
    AzureStorageStorageAccountHttpsOnlyDisabledRiskModel = "AzureStorageStorageAccountHttpsOnlyDisabledRiskModel",
    AzureStorageStorageAccountHttpsOnlyDisabledRiskPolicyConfiguration = "AzureStorageStorageAccountHttpsOnlyDisabledRiskPolicyConfiguration",
    AzureStorageStorageAccountInfrastructureEncryptionDisabledCodeRisk = "AzureStorageStorageAccountInfrastructureEncryptionDisabledCodeRisk",
    AzureStorageStorageAccountInfrastructureEncryptionDisabledCodeRiskModel = "AzureStorageStorageAccountInfrastructureEncryptionDisabledCodeRiskModel",
    AzureStorageStorageAccountInfrastructureEncryptionDisabledRisk = "AzureStorageStorageAccountInfrastructureEncryptionDisabledRisk",
    AzureStorageStorageAccountInfrastructureEncryptionDisabledRiskModel = "AzureStorageStorageAccountInfrastructureEncryptionDisabledRiskModel",
    AzureStorageStorageAccountInfrastructureEncryptionDisabledRiskPolicyConfiguration = "AzureStorageStorageAccountInfrastructureEncryptionDisabledRiskPolicyConfiguration",
    AzureStorageStorageAccountInsecureTlsProtocolCodeRisk = "AzureStorageStorageAccountInsecureTlsProtocolCodeRisk",
    AzureStorageStorageAccountInsecureTlsProtocolCodeRiskModel = "AzureStorageStorageAccountInsecureTlsProtocolCodeRiskModel",
    AzureStorageStorageAccountInsecureTlsProtocolRisk = "AzureStorageStorageAccountInsecureTlsProtocolRisk",
    AzureStorageStorageAccountInsecureTlsProtocolRiskModel = "AzureStorageStorageAccountInsecureTlsProtocolRiskModel",
    AzureStorageStorageAccountInsecureTlsProtocolRiskPolicyConfiguration = "AzureStorageStorageAccountInsecureTlsProtocolRiskPolicyConfiguration",
    AzureStorageStorageAccountModel = "AzureStorageStorageAccountModel",
    AzureStorageStorageAccountPrivateEndpointNotExistsCodeRisk = "AzureStorageStorageAccountPrivateEndpointNotExistsCodeRisk",
    AzureStorageStorageAccountPrivateEndpointNotExistsCodeRiskModel = "AzureStorageStorageAccountPrivateEndpointNotExistsCodeRiskModel",
    AzureStorageStorageAccountPrivateEndpointNotExistsRisk = "AzureStorageStorageAccountPrivateEndpointNotExistsRisk",
    AzureStorageStorageAccountPrivateEndpointNotExistsRiskModel = "AzureStorageStorageAccountPrivateEndpointNotExistsRiskModel",
    AzureStorageStorageAccountPrivateEndpointNotExistsRiskPolicyConfiguration = "AzureStorageStorageAccountPrivateEndpointNotExistsRiskPolicyConfiguration",
    AzureStorageStorageAccountProfile = "AzureStorageStorageAccountProfile",
    AzureStorageStorageAccountQueue = "AzureStorageStorageAccountQueue",
    AzureStorageStorageAccountQueueModel = "AzureStorageStorageAccountQueueModel",
    AzureStorageStorageAccountQueueProfile = "AzureStorageStorageAccountQueueProfile",
    AzureStorageStorageAccountQueueServiceDiagnosticSettingsLogCategoryNotEnabledCodeRisk = "AzureStorageStorageAccountQueueServiceDiagnosticSettingsLogCategoryNotEnabledCodeRisk",
    AzureStorageStorageAccountQueueServiceDiagnosticSettingsLogCategoryNotEnabledCodeRiskModel = "AzureStorageStorageAccountQueueServiceDiagnosticSettingsLogCategoryNotEnabledCodeRiskModel",
    AzureStorageStorageAccountQueueServiceDiagnosticSettingsLogCategoryNotEnabledRisk = "AzureStorageStorageAccountQueueServiceDiagnosticSettingsLogCategoryNotEnabledRisk",
    AzureStorageStorageAccountQueueServiceDiagnosticSettingsLogCategoryNotEnabledRiskModel = "AzureStorageStorageAccountQueueServiceDiagnosticSettingsLogCategoryNotEnabledRiskModel",
    AzureStorageStorageAccountQueueServiceDiagnosticSettingsLogCategoryNotEnabledRiskPolicyConfiguration = "AzureStorageStorageAccountQueueServiceDiagnosticSettingsLogCategoryNotEnabledRiskPolicyConfiguration",
    AzureStorageStorageAccountResource = "AzureStorageStorageAccountResource",
    AzureStorageStorageAccountResourceModel = "AzureStorageStorageAccountResourceModel",
    AzureStorageStorageAccountServiceDiagnosticSettingsLogCategoryNotEnabledRisk = "AzureStorageStorageAccountServiceDiagnosticSettingsLogCategoryNotEnabledRisk",
    AzureStorageStorageAccountServiceDiagnosticSettingsLogCategoryNotEnabledRiskModel = "AzureStorageStorageAccountServiceDiagnosticSettingsLogCategoryNotEnabledRiskModel",
    AzureStorageStorageAccountSharedKeyAccessEnabledCodeRisk = "AzureStorageStorageAccountSharedKeyAccessEnabledCodeRisk",
    AzureStorageStorageAccountSharedKeyAccessEnabledCodeRiskModel = "AzureStorageStorageAccountSharedKeyAccessEnabledCodeRiskModel",
    AzureStorageStorageAccountSharedKeyAccessEnabledRisk = "AzureStorageStorageAccountSharedKeyAccessEnabledRisk",
    AzureStorageStorageAccountSharedKeyAccessEnabledRiskModel = "AzureStorageStorageAccountSharedKeyAccessEnabledRiskModel",
    AzureStorageStorageAccountSharedKeyAccessEnabledRiskPolicyConfiguration = "AzureStorageStorageAccountSharedKeyAccessEnabledRiskPolicyConfiguration",
    AzureStorageStorageAccountSoftDeleteDisabledCodeRisk = "AzureStorageStorageAccountSoftDeleteDisabledCodeRisk",
    AzureStorageStorageAccountSoftDeleteDisabledCodeRiskModel = "AzureStorageStorageAccountSoftDeleteDisabledCodeRiskModel",
    AzureStorageStorageAccountSoftDeleteDisabledRisk = "AzureStorageStorageAccountSoftDeleteDisabledRisk",
    AzureStorageStorageAccountSoftDeleteDisabledRiskModel = "AzureStorageStorageAccountSoftDeleteDisabledRiskModel",
    AzureStorageStorageAccountSoftDeleteDisabledRiskPolicyConfiguration = "AzureStorageStorageAccountSoftDeleteDisabledRiskPolicyConfiguration",
    AzureStorageStorageAccountStateNetwork = "AzureStorageStorageAccountStateNetwork",
    AzureStorageStorageAccountStateNetworkFirewallRuleData = "AzureStorageStorageAccountStateNetworkFirewallRuleData",
    AzureStorageStorageAccountStatus = "AzureStorageStorageAccountStatus",
    AzureStorageStorageAccountTable = "AzureStorageStorageAccountTable",
    AzureStorageStorageAccountTableModel = "AzureStorageStorageAccountTableModel",
    AzureStorageStorageAccountTableProfile = "AzureStorageStorageAccountTableProfile",
    AzureStorageStorageAccountTableServiceDiagnosticSettingsLogCategoryNotEnabledCodeRisk = "AzureStorageStorageAccountTableServiceDiagnosticSettingsLogCategoryNotEnabledCodeRisk",
    AzureStorageStorageAccountTableServiceDiagnosticSettingsLogCategoryNotEnabledCodeRiskModel = "AzureStorageStorageAccountTableServiceDiagnosticSettingsLogCategoryNotEnabledCodeRiskModel",
    AzureStorageStorageAccountTableServiceDiagnosticSettingsLogCategoryNotEnabledRisk = "AzureStorageStorageAccountTableServiceDiagnosticSettingsLogCategoryNotEnabledRisk",
    AzureStorageStorageAccountTableServiceDiagnosticSettingsLogCategoryNotEnabledRiskModel = "AzureStorageStorageAccountTableServiceDiagnosticSettingsLogCategoryNotEnabledRiskModel",
    AzureStorageStorageAccountTableServiceDiagnosticSettingsLogCategoryNotEnabledRiskPolicyConfiguration = "AzureStorageStorageAccountTableServiceDiagnosticSettingsLogCategoryNotEnabledRiskPolicyConfiguration",
    AzureStorageStorageAccountType = "AzureStorageStorageAccountType",
    AzureStorageStorageAccountWideRangeInboundCodeRisk = "AzureStorageStorageAccountWideRangeInboundCodeRisk",
    AzureStorageStorageAccountWideRangeInboundCodeRiskModel = "AzureStorageStorageAccountWideRangeInboundCodeRiskModel",
    AzureStorageStorageAccountWideRangeInboundRisk = "AzureStorageStorageAccountWideRangeInboundRisk",
    AzureStorageStorageAccountWideRangeInboundRiskModel = "AzureStorageStorageAccountWideRangeInboundRiskModel",
    AzureStorageStorageAccountWideRangeInboundRiskPolicyConfiguration = "AzureStorageStorageAccountWideRangeInboundRiskPolicyConfiguration",
    AzureStorageStorageTaskGenericResource = "AzureStorageStorageTaskGenericResource",
    AzureStorageSyncStorageSyncServiceGenericResource = "AzureStorageSyncStorageSyncServiceGenericResource",
    AzureStorageTasksStorageTaskGenericResource = "AzureStorageTasksStorageTaskGenericResource",
    AzureStreamAnalyticsClusterGenericResource = "AzureStreamAnalyticsClusterGenericResource",
    AzureStreamAnalyticsStreamingJobGenericResource = "AzureStreamAnalyticsStreamingJobGenericResource",
    AzureSustainabilityServicesCalculationGenericResource = "AzureSustainabilityServicesCalculationGenericResource",
    AzureSynapsePrivateLinkHubGenericResource = "AzureSynapsePrivateLinkHubGenericResource",
    AzureSynapseWorkspaceGenericResource = "AzureSynapseWorkspaceGenericResource",
    AzureSynapseWorkspaceGenericResourceModel = "AzureSynapseWorkspaceGenericResourceModel",
    AzureSynapseWorkspacesBigDataPoolGenericResource = "AzureSynapseWorkspacesBigDataPoolGenericResource",
    AzureSynapseWorkspacesKustoPoolGenericResource = "AzureSynapseWorkspacesKustoPoolGenericResource",
    AzureSynapseWorkspacesSqlPoolGenericResource = "AzureSynapseWorkspacesSqlPoolGenericResource",
    AzureSyntexAccountGenericResource = "AzureSyntexAccountGenericResource",
    AzureSyntexDocumentProcessorGenericResource = "AzureSyntexDocumentProcessorGenericResource",
    AzureTenantAuditEvent = "AzureTenantAuditEvent",
    AzureTenantConfiguration = "AzureTenantConfiguration",
    AzureTenantConfigurationEvents = "AzureTenantConfigurationEvents",
    AzureTenantConfigurationSections = "AzureTenantConfigurationSections",
    AzureTenantCreationAuditEvent = "AzureTenantCreationAuditEvent",
    AzureTenantDeletionAuditEvent = "AzureTenantDeletionAuditEvent",
    AzureTenantEntity = "AzureTenantEntity",
    AzureTenantEntityCreationRisk = "AzureTenantEntityCreationRisk",
    AzureTenantEntityCreationRiskModel = "AzureTenantEntityCreationRiskModel",
    AzureTenantEntityCreationRiskPolicyConfiguration = "AzureTenantEntityCreationRiskPolicyConfiguration",
    AzureTenantEntityModel = "AzureTenantEntityModel",
    AzureTenantEntityProfile = "AzureTenantEntityProfile",
    AzureTenantEntityStatus = "AzureTenantEntityStatus",
    AzureTenantManagerError = "AzureTenantManagerError",
    AzureTenantModel = "AzureTenantModel",
    AzureTenantModelIssue = "AzureTenantModelIssue",
    AzureTenantModelStatus = "AzureTenantModelStatus",
    AzureTenantState = "AzureTenantState",
    AzureTenantStateCodeAnalysis = "AzureTenantStateCodeAnalysis",
    AzureTenantStateEvents = "AzureTenantStateEvents",
    AzureTenantStateMonitoring = "AzureTenantStateMonitoring",
    AzureTenantStateMonitoringDataAnalysisIssue = "AzureTenantStateMonitoringDataAnalysisIssue",
    AzureTenantStateMonitoringIssue = "AzureTenantStateMonitoringIssue",
    AzureTenantStateMonitoringPermissionManagementIssue = "AzureTenantStateMonitoringPermissionManagementIssue",
    AzureTenantStateMonitoringWorkloadAnalysisIssue = "AzureTenantStateMonitoringWorkloadAnalysisIssue",
    AzureTenantUpdateAuditEvent = "AzureTenantUpdateAuditEvent",
    AzureToolchainOrchestratorActivationGenericResource = "AzureToolchainOrchestratorActivationGenericResource",
    AzureToolchainOrchestratorCampaignGenericResource = "AzureToolchainOrchestratorCampaignGenericResource",
    AzureToolchainOrchestratorCampaignsVersionGenericResource = "AzureToolchainOrchestratorCampaignsVersionGenericResource",
    AzureToolchainOrchestratorCatalogGenericResource = "AzureToolchainOrchestratorCatalogGenericResource",
    AzureToolchainOrchestratorCatalogsVersionGenericResource = "AzureToolchainOrchestratorCatalogsVersionGenericResource",
    AzureToolchainOrchestratorDiagnosticGenericResource = "AzureToolchainOrchestratorDiagnosticGenericResource",
    AzureToolchainOrchestratorInstanceGenericResource = "AzureToolchainOrchestratorInstanceGenericResource",
    AzureToolchainOrchestratorInstancesVersionGenericResource = "AzureToolchainOrchestratorInstancesVersionGenericResource",
    AzureToolchainOrchestratorSolutionGenericResource = "AzureToolchainOrchestratorSolutionGenericResource",
    AzureToolchainOrchestratorSolutionsVersionGenericResource = "AzureToolchainOrchestratorSolutionsVersionGenericResource",
    AzureToolchainOrchestratorTargetGenericResource = "AzureToolchainOrchestratorTargetGenericResource",
    AzureToolchainOrchestratorTargetsVersionGenericResource = "AzureToolchainOrchestratorTargetsVersionGenericResource",
    AzureUnmanagedKubernetesCluster = "AzureUnmanagedKubernetesCluster",
    AzureUnmanagedKubernetesClusterModel = "AzureUnmanagedKubernetesClusterModel",
    AzureUnmanagedKubernetesClusterModelFilters = "AzureUnmanagedKubernetesClusterModelFilters",
    AzureUnmanagedKubernetesClusterTypeMetadata = "AzureUnmanagedKubernetesClusterTypeMetadata",
    AzureUsageBillingAccountGenericResource = "AzureUsageBillingAccountGenericResource",
    AzureVideoIndexerAccountGenericResource = "AzureVideoIndexerAccountGenericResource",
    AzureVirtualMachineImagesImageTemplateGenericResource = "AzureVirtualMachineImagesImageTemplateGenericResource",
    AzureVisualStudioAccountExtensionGenericResource = "AzureVisualStudioAccountExtensionGenericResource",
    AzureVisualStudioAccountGenericResource = "AzureVisualStudioAccountGenericResource",
    AzureVisualStudioAccountProjectGenericResource = "AzureVisualStudioAccountProjectGenericResource",
    AzureVMwareArczoneGenericResource = "AzureVMwareArczoneGenericResource",
    AzureVMwareResourcepoolGenericResource = "AzureVMwareResourcepoolGenericResource",
    AzureVMwareVcenterGenericResource = "AzureVMwareVcenterGenericResource",
    AzureVMwareVirtualmachineGenericResource = "AzureVMwareVirtualmachineGenericResource",
    AzureVMwareVirtualmachineGenericResourceModel = "AzureVMwareVirtualmachineGenericResourceModel",
    AzureVMwareVirtualmachinetemplateGenericResource = "AzureVMwareVirtualmachinetemplateGenericResource",
    AzureVMwareVirtualnetworkGenericResource = "AzureVMwareVirtualnetworkGenericResource",
    AzureVMwareVirtualnetworkGenericResourceModel = "AzureVMwareVirtualnetworkGenericResourceModel",
    AzureVoiceServicesCommunicationsGatewayGenericResource = "AzureVoiceServicesCommunicationsGatewayGenericResource",
    AzureVoiceServicesCommunicationsGatewaysContactGenericResource = "AzureVoiceServicesCommunicationsGatewaysContactGenericResource",
    AzureVoiceServicesCommunicationsGatewaysTestLineGenericResource = "AzureVoiceServicesCommunicationsGatewaysTestLineGenericResource",
    AzureVoiceServicesOperatorVoicemailInstanceGenericResource = "AzureVoiceServicesOperatorVoicemailInstanceGenericResource",
    AzureWandiscoFusionFusionGroupGenericResource = "AzureWandiscoFusionFusionGroupGenericResource",
    AzureWandiscoFusionFusionGroupsAzureZoneGenericResource = "AzureWandiscoFusionFusionGroupsAzureZoneGenericResource",
    AzureWandiscoFusionFusionGroupsAzureZonesPluginGenericResource = "AzureWandiscoFusionFusionGroupsAzureZonesPluginGenericResource",
    AzureWandiscoFusionFusionGroupsManagedOnPremZoneGenericResource = "AzureWandiscoFusionFusionGroupsManagedOnPremZoneGenericResource",
    AzureWandiscoFusionFusionGroupsReplicationRuleGenericResource = "AzureWandiscoFusionFusionGroupsReplicationRuleGenericResource",
    AzureWandiscoFusionMigratorGenericResource = "AzureWandiscoFusionMigratorGenericResource",
    AzureWandiscoFusionMigratorsDataTransferAgentGenericResource = "AzureWandiscoFusionMigratorsDataTransferAgentGenericResource",
    AzureWandiscoFusionMigratorsExclusionTemplateGenericResource = "AzureWandiscoFusionMigratorsExclusionTemplateGenericResource",
    AzureWandiscoFusionMigratorsLiveDataMigrationGenericResource = "AzureWandiscoFusionMigratorsLiveDataMigrationGenericResource",
    AzureWandiscoFusionMigratorsMetadataMigrationGenericResource = "AzureWandiscoFusionMigratorsMetadataMigrationGenericResource",
    AzureWandiscoFusionMigratorsMetadataTargetGenericResource = "AzureWandiscoFusionMigratorsMetadataTargetGenericResource",
    AzureWandiscoFusionMigratorsPathMappingGenericResource = "AzureWandiscoFusionMigratorsPathMappingGenericResource",
    AzureWandiscoFusionMigratorsTargetGenericResource = "AzureWandiscoFusionMigratorsTargetGenericResource",
    AzureWandiscoFusionMigratorsVerificationGenericResource = "AzureWandiscoFusionMigratorsVerificationGenericResource",
    AzureWebApiManagementAccountGenericResource = "AzureWebApiManagementAccountGenericResource",
    AzureWebApimanagementaccountsApiGenericResource = "AzureWebApimanagementaccountsApiGenericResource",
    AzureWebApplication = "AzureWebApplication",
    AzureWebApplicationAuthenticationType = "AzureWebApplicationAuthenticationType",
    AzureWebApplicationHttpsOnlyDisabledCodeRisk = "AzureWebApplicationHttpsOnlyDisabledCodeRisk",
    AzureWebApplicationHttpsOnlyDisabledCodeRiskModel = "AzureWebApplicationHttpsOnlyDisabledCodeRiskModel",
    AzureWebApplicationHttpsOnlyDisabledRisk = "AzureWebApplicationHttpsOnlyDisabledRisk",
    AzureWebApplicationHttpsOnlyDisabledRiskModel = "AzureWebApplicationHttpsOnlyDisabledRiskModel",
    AzureWebApplicationHttpsOnlyDisabledRiskPolicyConfiguration = "AzureWebApplicationHttpsOnlyDisabledRiskPolicyConfiguration",
    AzureWebApplicationInboundExternalWideRangeCodeRisk = "AzureWebApplicationInboundExternalWideRangeCodeRisk",
    AzureWebApplicationInboundExternalWideRangeCodeRiskModel = "AzureWebApplicationInboundExternalWideRangeCodeRiskModel",
    AzureWebApplicationInboundExternalWideRangeRisk = "AzureWebApplicationInboundExternalWideRangeRisk",
    AzureWebApplicationInboundExternalWideRangeRiskModel = "AzureWebApplicationInboundExternalWideRangeRiskModel",
    AzureWebApplicationInboundExternalWideRangeRiskPolicyConfiguration = "AzureWebApplicationInboundExternalWideRangeRiskPolicyConfiguration",
    AzureWebApplicationInsecureTlsProtocolCodeRisk = "AzureWebApplicationInsecureTlsProtocolCodeRisk",
    AzureWebApplicationInsecureTlsProtocolCodeRiskModel = "AzureWebApplicationInsecureTlsProtocolCodeRiskModel",
    AzureWebApplicationInsecureTlsProtocolRisk = "AzureWebApplicationInsecureTlsProtocolRisk",
    AzureWebApplicationInsecureTlsProtocolRiskModel = "AzureWebApplicationInsecureTlsProtocolRiskModel",
    AzureWebApplicationInsecureTlsProtocolRiskPolicyConfiguration = "AzureWebApplicationInsecureTlsProtocolRiskPolicyConfiguration",
    AzureWebApplicationModel = "AzureWebApplicationModel",
    AzureWebApplicationNetworkRule = "AzureWebApplicationNetworkRule",
    AzureWebApplicationNetworkRuleAction = "AzureWebApplicationNetworkRuleAction",
    AzureWebApplicationProfile = "AzureWebApplicationProfile",
    AzureWebApplicationScmInboundExternalWideRangeCodeRisk = "AzureWebApplicationScmInboundExternalWideRangeCodeRisk",
    AzureWebApplicationScmInboundExternalWideRangeCodeRiskModel = "AzureWebApplicationScmInboundExternalWideRangeCodeRiskModel",
    AzureWebApplicationScmInboundExternalWideRangeRisk = "AzureWebApplicationScmInboundExternalWideRangeRisk",
    AzureWebApplicationScmInboundExternalWideRangeRiskModel = "AzureWebApplicationScmInboundExternalWideRangeRiskModel",
    AzureWebApplicationScmInboundExternalWideRangeRiskPolicyConfiguration = "AzureWebApplicationScmInboundExternalWideRangeRiskPolicyConfiguration",
    AzureWebApplicationStateNetwork = "AzureWebApplicationStateNetwork",
    AzureWebApplicationStatus = "AzureWebApplicationStatus",
    AzureWebApplicationType = "AzureWebApplicationType",
    AzureWebCertificateGenericResource = "AzureWebCertificateGenericResource",
    AzureWebConnectionGatewayGenericResource = "AzureWebConnectionGatewayGenericResource",
    AzureWebConnectionGenericResource = "AzureWebConnectionGenericResource",
    AzureWebContainerAppGenericResource = "AzureWebContainerAppGenericResource",
    AzureWebCustomApiGenericResource = "AzureWebCustomApiGenericResource",
    AzureWebHostingEnvironmentGenericResource = "AzureWebHostingEnvironmentGenericResource",
    AzureWebKubeEnvironmentGenericResource = "AzureWebKubeEnvironmentGenericResource",
    AzureWebServerfarmGenericResource = "AzureWebServerfarmGenericResource",
    AzureWebSiteGenericResource = "AzureWebSiteGenericResource",
    AzureWebSitesSlotGenericResource = "AzureWebSitesSlotGenericResource",
    AzureWebSitesWorkflowGenericResource = "AzureWebSitesWorkflowGenericResource",
    AzureWebStaticSiteGenericResource = "AzureWebStaticSiteGenericResource",
    AzureWebWorkerAppGenericResource = "AzureWebWorkerAppGenericResource",
    AzureWindows365CloudPcDelegatedMsiGenericResource = "AzureWindows365CloudPcDelegatedMsiGenericResource",
    AzureWindowsPushNotificationServicesRegistrationGenericResource = "AzureWindowsPushNotificationServicesRegistrationGenericResource",
    AzureWorkloadAnalysisUpdateAuditEvent = "AzureWorkloadAnalysisUpdateAuditEvent",
    AzureWorkloadBuilderMigrationAgentGenericResource = "AzureWorkloadBuilderMigrationAgentGenericResource",
    AzureWorkloadBuilderWorkloadGenericResource = "AzureWorkloadBuilderWorkloadGenericResource",
    AzureWorkloadsConnectorGenericResource = "AzureWorkloadsConnectorGenericResource",
    AzureWorkloadsConnectorsAcssBackupGenericResource = "AzureWorkloadsConnectorsAcssBackupGenericResource",
    AzureWorkloadsConnectorsAmsInsightGenericResource = "AzureWorkloadsConnectorsAmsInsightGenericResource",
    AzureWorkloadsConnectorsSapVirtualInstanceMonitorGenericResource = "AzureWorkloadsConnectorsSapVirtualInstanceMonitorGenericResource",
    AzureWorkloadsEpicVirtualInstanceGenericResource = "AzureWorkloadsEpicVirtualInstanceGenericResource",
    AzureWorkloadsEpicVirtualInstancesDatabaseInstanceGenericResource = "AzureWorkloadsEpicVirtualInstancesDatabaseInstanceGenericResource",
    AzureWorkloadsEpicVirtualInstancesHyperspaceWebInstanceGenericResource = "AzureWorkloadsEpicVirtualInstancesHyperspaceWebInstanceGenericResource",
    AzureWorkloadsEpicVirtualInstancesPresentationInstanceGenericResource = "AzureWorkloadsEpicVirtualInstancesPresentationInstanceGenericResource",
    AzureWorkloadsEpicVirtualInstancesSharedInstanceGenericResource = "AzureWorkloadsEpicVirtualInstancesSharedInstanceGenericResource",
    AzureWorkloadsEpicVirtualInstancesWssInstanceGenericResource = "AzureWorkloadsEpicVirtualInstancesWssInstanceGenericResource",
    AzureWorkloadsInsightGenericResource = "AzureWorkloadsInsightGenericResource",
    AzureWorkloadsMonitorGenericResource = "AzureWorkloadsMonitorGenericResource",
    AzureWorkloadsOracleVirtualInstanceGenericResource = "AzureWorkloadsOracleVirtualInstanceGenericResource",
    AzureWorkloadsOracleVirtualInstancesDatabaseInstanceGenericResource = "AzureWorkloadsOracleVirtualInstancesDatabaseInstanceGenericResource",
    AzureWorkloadsPhpWorkloadGenericResource = "AzureWorkloadsPhpWorkloadGenericResource",
    AzureWorkloadsSapDiscoverySiteGenericResource = "AzureWorkloadsSapDiscoverySiteGenericResource",
    AzureWorkloadsSapDiscoverySitesSapInstanceGenericResource = "AzureWorkloadsSapDiscoverySitesSapInstanceGenericResource",
    AzureWorkloadsSapVirtualInstanceGenericResource = "AzureWorkloadsSapVirtualInstanceGenericResource",
    AzureWorkloadsSapVirtualInstancesApplicationInstanceGenericResource = "AzureWorkloadsSapVirtualInstancesApplicationInstanceGenericResource",
    AzureWorkloadsSapVirtualInstancesCentralInstanceGenericResource = "AzureWorkloadsSapVirtualInstancesCentralInstanceGenericResource",
    AzureWorkloadsSapVirtualInstancesDatabaseInstanceGenericResource = "AzureWorkloadsSapVirtualInstancesDatabaseInstanceGenericResource",
    BehaviorRiskIdentityAttribute = "BehaviorRiskIdentityAttribute",
    BitbucketOrganizationConfiguration = "BitbucketOrganizationConfiguration",
    BitbucketOrganizationManagerError = "BitbucketOrganizationManagerError",
    BitbucketTenantConfiguration = "BitbucketTenantConfiguration",
    BiUpdaterConfigurationSection = "BiUpdaterConfigurationSection",
    BuiltInComplianceAuditEvent = "BuiltInComplianceAuditEvent",
    BuiltInComplianceEnabledUpdateAuditEvent = "BuiltInComplianceEnabledUpdateAuditEvent",
    BuiltInComplianceReportDefinitionConfiguration = "BuiltInComplianceReportDefinitionConfiguration",
    BuiltInComplianceSection = "BuiltInComplianceSection",
    BuiltInComplianceSectionType = "BuiltInComplianceSectionType",
    BuiltInEntityAttribute = "BuiltInEntityAttribute",
    BuiltInKubernetesAdmissionControllerRiskPolicyConfiguration = "BuiltInKubernetesAdmissionControllerRiskPolicyConfiguration",
    BuiltInRiskPolicyConfiguration = "BuiltInRiskPolicyConfiguration",
    Change = "Change",
    ChangeBase = "ChangeBase",
    ChangeModel = "ChangeModel",
    ChangeModelChangeData = "ChangeModelChangeData",
    ChangeSet = "ChangeSet",
    CiCloudsmithIntegrationConfiguration = "CiCloudsmithIntegrationConfiguration",
    CiCloudsmithTenantConfiguration = "CiCloudsmithTenantConfiguration",
    CiCloudsmithTenantModel = "CiCloudsmithTenantModel",
    CiContainerImage = "CiContainerImage",
    CiContainerImageModel = "CiContainerImageModel",
    CiContainerImageModelFilters = "CiContainerImageModelFilters",
    CiContainerImageRepository = "CiContainerImageRepository",
    CiContainerImageRepositoryModel = "CiContainerImageRepositoryModel",
    CiEntity = "CiEntity",
    CiEntityModel = "CiEntityModel",
    CiEntityTypeMetadata = "CiEntityTypeMetadata",
    CiFolderConfiguration = "CiFolderConfiguration",
    CiGeneralIntegrationConfiguration = "CiGeneralIntegrationConfiguration",
    CiGeneralTenantConfiguration = "CiGeneralTenantConfiguration",
    CiGeneralTenantModel = "CiGeneralTenantModel",
    CiIntegrationAuditEvent = "CiIntegrationAuditEvent",
    CiIntegrationConfiguration = "CiIntegrationConfiguration",
    CiIntegrationCreationAuditEvent = "CiIntegrationCreationAuditEvent",
    CiIntegrationDeletionAuditEvent = "CiIntegrationDeletionAuditEvent",
    CiIntegrationManagerError = "CiIntegrationManagerError",
    CiIntegrationModel = "CiIntegrationModel",
    CiIntegrationModelStatus = "CiIntegrationModelStatus",
    CiIntegrationState = "CiIntegrationState",
    CiIntegrationStateIssue = "CiIntegrationStateIssue",
    CiIntegrationUpdateAuditEvent = "CiIntegrationUpdateAuditEvent",
    CiJfrogOrganizationConfiguration = "CiJfrogOrganizationConfiguration",
    CiJfrogTenantConfiguration = "CiJfrogTenantConfiguration",
    CiJfrogTenantModel = "CiJfrogTenantModel",
    CiOrganizationCreationAuditEvent = "CiOrganizationCreationAuditEvent",
    CiOrganizationDeletionAuditEvent = "CiOrganizationDeletionAuditEvent",
    CiOrganizationUpdateAuditEvent = "CiOrganizationUpdateAuditEvent",
    CiRegistryIntegrationCreationAuditEvent = "CiRegistryIntegrationCreationAuditEvent",
    CiRegistryIntegrationDeletionAuditEvent = "CiRegistryIntegrationDeletionAuditEvent",
    CiRegistryIntegrationUpdateAuditEvent = "CiRegistryIntegrationUpdateAuditEvent",
    CiScopeConfigurationOutpost = "CiScopeConfigurationOutpost",
    CiScopeConfigurationSections = "CiScopeConfigurationSections",
    CiScopeConfigurationWorkloadAnalysis = "CiScopeConfigurationWorkloadAnalysis",
    CiTenantAuditEvent = "CiTenantAuditEvent",
    CiTenantConfiguration = "CiTenantConfiguration",
    CiTenantCreationAuditEvent = "CiTenantCreationAuditEvent",
    CiTenantDeletionAuditEvent = "CiTenantDeletionAuditEvent",
    CiTenantEntity = "CiTenantEntity",
    CiTenantEntityModel = "CiTenantEntityModel",
    CiTenantModel = "CiTenantModel",
    CiTenantModelStatus = "CiTenantModelStatus",
    CiTenantState = "CiTenantState",
    CiTenantStateMonitoring = "CiTenantStateMonitoring",
    CiTenantStateMonitoringIssue = "CiTenantStateMonitoringIssue",
    CiTenantType = "CiTenantType",
    CiTenantUpdateAuditEvent = "CiTenantUpdateAuditEvent",
    CloudProviderTenantConfiguration = "CloudProviderTenantConfiguration",
    CloudProviderTenantFolderConfiguration = "CloudProviderTenantFolderConfiguration",
    CloudProviderTenantModel = "CloudProviderTenantModel",
    CloudProviderTenantOrganizationConfiguration = "CloudProviderTenantOrganizationConfiguration",
    CloudProviderTenantOrganizationState = "CloudProviderTenantOrganizationState",
    CloudProviderTenantPermissionType = "CloudProviderTenantPermissionType",
    CloudProviderTenantState = "CloudProviderTenantState",
    CloudRisk = "CloudRisk",
    CloudRiskFilterItemType = "CloudRiskFilterItemType",
    CloudRiskFilters = "CloudRiskFilters",
    CloudRiskModel = "CloudRiskModel",
    CloudRisksReportDefinitionConfiguration = "CloudRisksReportDefinitionConfiguration",
    CodeAnalysisServiceConfiguration = "CodeAnalysisServiceConfiguration",
    CodeApiServiceConfiguration = "CodeApiServiceConfiguration",
    CodeBlock = "CodeBlock",
    CodeCommit = "CodeCommit",
    CodeContainerImagePackage = "CodeContainerImagePackage",
    CodeContainerImageScan = "CodeContainerImageScan",
    CodeContainerImageScanFilters = "CodeContainerImageScanFilters",
    CodeContainerImageVulnerability = "CodeContainerImageVulnerability",
    CodeControllerGetContainerImagePackagePageResponse = "CodeControllerGetContainerImagePackagePageResponse",
    CodeControllerGetContainerImageScanExistsResponse = "CodeControllerGetContainerImageScanExistsResponse",
    CodeControllerGetContainerImageScanFilterItemPageRequest = "CodeControllerGetContainerImageScanFilterItemPageRequest",
    CodeControllerGetContainerImageScanFilterItemPageResponse = "CodeControllerGetContainerImageScanFilterItemPageResponse",
    CodeControllerGetContainerImageScanFiltersResponse = "CodeControllerGetContainerImageScanFiltersResponse",
    CodeControllerGetContainerImageScanPackageFilterItemPageRequest = "CodeControllerGetContainerImageScanPackageFilterItemPageRequest",
    CodeControllerGetContainerImageScanPackageFilterItemPageResponse = "CodeControllerGetContainerImageScanPackageFilterItemPageResponse",
    CodeControllerGetContainerImageScanPackagePageRequest = "CodeControllerGetContainerImageScanPackagePageRequest",
    CodeControllerGetContainerImageScanPackagePageRequestFilters = "CodeControllerGetContainerImageScanPackagePageRequestFilters",
    CodeControllerGetContainerImageScanPackagePageRequestProperty = "CodeControllerGetContainerImageScanPackagePageRequestProperty",
    CodeControllerGetContainerImageScanPackagePageRequestSort = "CodeControllerGetContainerImageScanPackagePageRequestSort",
    CodeControllerGetContainerImageScanPackageRequest = "CodeControllerGetContainerImageScanPackageRequest",
    CodeControllerGetContainerImageScanPackageResponse = "CodeControllerGetContainerImageScanPackageResponse",
    CodeControllerGetContainerImageScanPageRequest = "CodeControllerGetContainerImageScanPageRequest",
    CodeControllerGetContainerImageScanPageRequestProperty = "CodeControllerGetContainerImageScanPageRequestProperty",
    CodeControllerGetContainerImageScanPageRequestSort = "CodeControllerGetContainerImageScanPageRequestSort",
    CodeControllerGetContainerImageScanPageResponse = "CodeControllerGetContainerImageScanPageResponse",
    CodeControllerGetContainerImageScansRequest = "CodeControllerGetContainerImageScansRequest",
    CodeControllerGetContainerImageScansResponse = "CodeControllerGetContainerImageScansResponse",
    CodeControllerGetContainerImageScanVulnerabilityFilterItemPageRequest = "CodeControllerGetContainerImageScanVulnerabilityFilterItemPageRequest",
    CodeControllerGetContainerImageScanVulnerabilityFilterItemPageResponse = "CodeControllerGetContainerImageScanVulnerabilityFilterItemPageResponse",
    CodeControllerGetContainerImageScanVulnerabilityPageRequest = "CodeControllerGetContainerImageScanVulnerabilityPageRequest",
    CodeControllerGetContainerImageScanVulnerabilityPageRequestFilters = "CodeControllerGetContainerImageScanVulnerabilityPageRequestFilters",
    CodeControllerGetContainerImageScanVulnerabilityPageRequestProperty = "CodeControllerGetContainerImageScanVulnerabilityPageRequestProperty",
    CodeControllerGetContainerImageScanVulnerabilityPageRequestSort = "CodeControllerGetContainerImageScanVulnerabilityPageRequestSort",
    CodeControllerGetContainerImageScanVulnerabilityRequest = "CodeControllerGetContainerImageScanVulnerabilityRequest",
    CodeControllerGetContainerImageScanVulnerabilityResponse = "CodeControllerGetContainerImageScanVulnerabilityResponse",
    CodeControllerGetContainerImageVulnerabilityPageResponse = "CodeControllerGetContainerImageVulnerabilityPageResponse",
    CodeControllerGetDashboardSummaryResponse = "CodeControllerGetDashboardSummaryResponse",
    CodeControllerGetDashboardSummaryResponseSummary = "CodeControllerGetDashboardSummaryResponseSummary",
    CodeControllerGetIacRiskFilterItemPageRequest = "CodeControllerGetIacRiskFilterItemPageRequest",
    CodeControllerGetIacRiskFilterItemPageResponse = "CodeControllerGetIacRiskFilterItemPageResponse",
    CodeControllerGetIacRiskFiltersRequest = "CodeControllerGetIacRiskFiltersRequest",
    CodeControllerGetIacRiskFiltersResponse = "CodeControllerGetIacRiskFiltersResponse",
    CodeControllerGetIacRiskPageRequest = "CodeControllerGetIacRiskPageRequest",
    CodeControllerGetIacRiskPageRequestFilters = "CodeControllerGetIacRiskPageRequestFilters",
    CodeControllerGetIacRiskPageRequestProperty = "CodeControllerGetIacRiskPageRequestProperty",
    CodeControllerGetIacRiskPageRequestSort = "CodeControllerGetIacRiskPageRequestSort",
    CodeControllerGetIacRiskPageResponse = "CodeControllerGetIacRiskPageResponse",
    CodeControllerGetIacRiskRequest = "CodeControllerGetIacRiskRequest",
    CodeControllerGetIacRiskResponse = "CodeControllerGetIacRiskResponse",
    CodeControllerGetIacScanExistsResponse = "CodeControllerGetIacScanExistsResponse",
    CodeControllerGetIacScanFilterItemPageRequest = "CodeControllerGetIacScanFilterItemPageRequest",
    CodeControllerGetIacScanFilterItemPageResponse = "CodeControllerGetIacScanFilterItemPageResponse",
    CodeControllerGetIacScanFiltersResponse = "CodeControllerGetIacScanFiltersResponse",
    CodeControllerGetIacScanPageRequest = "CodeControllerGetIacScanPageRequest",
    CodeControllerGetIacScanPageRequestFilters = "CodeControllerGetIacScanPageRequestFilters",
    CodeControllerGetIacScanPageRequestProperty = "CodeControllerGetIacScanPageRequestProperty",
    CodeControllerGetIacScanPageRequestSort = "CodeControllerGetIacScanPageRequestSort",
    CodeControllerGetIacScanPageResponse = "CodeControllerGetIacScanPageResponse",
    CodeControllerGetIacScanRequest = "CodeControllerGetIacScanRequest",
    CodeControllerGetIacScanResponse = "CodeControllerGetIacScanResponse",
    CodeControllerGetResourcesRequest = "CodeControllerGetResourcesRequest",
    CodeControllerGetResourcesResponse = "CodeControllerGetResourcesResponse",
    CodeEntity = "CodeEntity",
    CodeFileDiff = "CodeFileDiff",
    CodeFolderConfiguration = "CodeFolderConfiguration",
    CodeIacRisk = "CodeIacRisk",
    CodeIacRiskFilters = "CodeIacRiskFilters",
    CodeIacScan = "CodeIacScan",
    CodeIacScanStats = "CodeIacScanStats",
    CodeIntegrationConfiguration = "CodeIntegrationConfiguration",
    CodeOrganizationAuditEvent = "CodeOrganizationAuditEvent",
    CodeOrganizationConfiguration = "CodeOrganizationConfiguration",
    CodeOrganizationCreationAuditEvent = "CodeOrganizationCreationAuditEvent",
    CodeOrganizationDeletionAuditEvent = "CodeOrganizationDeletionAuditEvent",
    CodeOrganizationModel = "CodeOrganizationModel",
    CodeOrganizationModelStatus = "CodeOrganizationModelStatus",
    CodeOrganizationState = "CodeOrganizationState",
    CodeOrganizationStateIssue = "CodeOrganizationStateIssue",
    CodeOrganizationUpdateAuditEvent = "CodeOrganizationUpdateAuditEvent",
    CodePipelineAuditEvent = "CodePipelineAuditEvent",
    CodePipelineConfiguration = "CodePipelineConfiguration",
    CodePipelineCreationAuditEvent = "CodePipelineCreationAuditEvent",
    CodePipelineDeletionAuditEvent = "CodePipelineDeletionAuditEvent",
    CodePipelineModel = "CodePipelineModel",
    CodePipelineType = "CodePipelineType",
    CodePipelineUpdateAuditEvent = "CodePipelineUpdateAuditEvent",
    CodeResourceAttribute = "CodeResourceAttribute",
    CodeResourceData = "CodeResourceData",
    CodeResourceDataBlockData = "CodeResourceDataBlockData",
    CodeRisk = "CodeRisk",
    CodeRiskFilterItemType = "CodeRiskFilterItemType",
    CodeRiskFilters = "CodeRiskFilters",
    CodeRiskModel = "CodeRiskModel",
    CodeRiskSnippet = "CodeRiskSnippet",
    CodeRisksReportDefinitionConfiguration = "CodeRisksReportDefinitionConfiguration",
    CodeRiskTypeMetadata = "CodeRiskTypeMetadata",
    CodeScan = "CodeScan",
    CodeScanFilters = "CodeScanFilters",
    CodeScanPipeline = "CodeScanPipeline",
    CodeScanResource = "CodeScanResource",
    CodeServerAuditEvent = "CodeServerAuditEvent",
    CodeServerConfiguration = "CodeServerConfiguration",
    CodeServerCreationAuditEvent = "CodeServerCreationAuditEvent",
    CodeServerDeletionAuditEvent = "CodeServerDeletionAuditEvent",
    CodeServerModel = "CodeServerModel",
    CodeServerModelStatus = "CodeServerModelStatus",
    CodeServerState = "CodeServerState",
    CodeServerStateIssue = "CodeServerStateIssue",
    CodeServerUpdateAuditEvent = "CodeServerUpdateAuditEvent",
    CodeTenantAuditEvent = "CodeTenantAuditEvent",
    CodeTenantConfiguration = "CodeTenantConfiguration",
    CodeTenantEntityData = "CodeTenantEntityData",
    CodeTenantModel = "CodeTenantModel",
    CodeTenantModelStatus = "CodeTenantModelStatus",
    CodeTenantType = "CodeTenantType",
    CodeTerraformDnsRecordSetExistsCodeRisk = "CodeTerraformDnsRecordSetExistsCodeRisk",
    CodeTerraformDnsRecordSetExistsCodeRiskModel = "CodeTerraformDnsRecordSetExistsCodeRiskModel",
    CodeTerraformDnsRecordSetExistsRiskPolicyConfiguration = "CodeTerraformDnsRecordSetExistsRiskPolicyConfiguration",
    CodeTerraformExternalExistsCodeRisk = "CodeTerraformExternalExistsCodeRisk",
    CodeTerraformExternalExistsCodeRiskModel = "CodeTerraformExternalExistsCodeRiskModel",
    CodeTerraformExternalExistsRiskPolicyConfiguration = "CodeTerraformExternalExistsRiskPolicyConfiguration",
    CodeType = "CodeType",
    CodeUpdateAuditEvent = "CodeUpdateAuditEvent",
    CognitoAuthenticationManagerChangeUserPasswordResult = "CognitoAuthenticationManagerChangeUserPasswordResult",
    CommonReportDefinitionConfiguration = "CommonReportDefinitionConfiguration",
    ComplianceData = "ComplianceData",
    ComplianceDataStats = "ComplianceDataStats",
    ComplianceReportDefinitionConfiguration = "ComplianceReportDefinitionConfiguration",
    ComplianceRiskPolicyData = "ComplianceRiskPolicyData",
    ComplianceScopesReportDefinitionConfiguration = "ComplianceScopesReportDefinitionConfiguration",
    ComplianceSectionData = "ComplianceSectionData",
    ConfigurationControllerDeleteAnalysisNetworkRequest = "ConfigurationControllerDeleteAnalysisNetworkRequest",
    ConfigurationControllerDeleteApiKeyRequest = "ConfigurationControllerDeleteApiKeyRequest",
    ConfigurationControllerDeleteAutomaticCustomEntityAttributeDefinitionTemplateRequest = "ConfigurationControllerDeleteAutomaticCustomEntityAttributeDefinitionTemplateRequest",
    ConfigurationControllerDeleteAutomationRuleRequest = "ConfigurationControllerDeleteAutomationRuleRequest",
    ConfigurationControllerDeleteAzureDevOpsOrganizationRequest = "ConfigurationControllerDeleteAzureDevOpsOrganizationRequest",
    ConfigurationControllerDeleteAzureDevOpsOrganizationResponse = "ConfigurationControllerDeleteAzureDevOpsOrganizationResponse",
    ConfigurationControllerDeleteBitbucketOrganizationRequest = "ConfigurationControllerDeleteBitbucketOrganizationRequest",
    ConfigurationControllerDeleteBitbucketOrganizationResponse = "ConfigurationControllerDeleteBitbucketOrganizationResponse",
    ConfigurationControllerDeleteCiCloudsmithIntegrationRequest = "ConfigurationControllerDeleteCiCloudsmithIntegrationRequest",
    ConfigurationControllerDeleteCiCloudsmithIntegrationResponse = "ConfigurationControllerDeleteCiCloudsmithIntegrationResponse",
    ConfigurationControllerDeleteCiGeneralIntegrationRequest = "ConfigurationControllerDeleteCiGeneralIntegrationRequest",
    ConfigurationControllerDeleteCiGeneralIntegrationResponse = "ConfigurationControllerDeleteCiGeneralIntegrationResponse",
    ConfigurationControllerDeleteCiJfrogIntegrationRequest = "ConfigurationControllerDeleteCiJfrogIntegrationRequest",
    ConfigurationControllerDeleteCiJfrogIntegrationResponse = "ConfigurationControllerDeleteCiJfrogIntegrationResponse",
    ConfigurationControllerDeleteCodePipelineRequest = "ConfigurationControllerDeleteCodePipelineRequest",
    ConfigurationControllerDeleteCustomComplianceRequest = "ConfigurationControllerDeleteCustomComplianceRequest",
    ConfigurationControllerDeleteDatadogOrganizationRequest = "ConfigurationControllerDeleteDatadogOrganizationRequest",
    ConfigurationControllerDeleteEndpointConnectorRequest = "ConfigurationControllerDeleteEndpointConnectorRequest",
    ConfigurationControllerDeleteEntityPropertyDefinitionRequest = "ConfigurationControllerDeleteEntityPropertyDefinitionRequest",
    ConfigurationControllerDeleteGitHubOrganizationRequest = "ConfigurationControllerDeleteGitHubOrganizationRequest",
    ConfigurationControllerDeleteGitHubOrganizationResponse = "ConfigurationControllerDeleteGitHubOrganizationResponse",
    ConfigurationControllerDeleteGitHubServerRequest = "ConfigurationControllerDeleteGitHubServerRequest",
    ConfigurationControllerDeleteGitHubServerResponse = "ConfigurationControllerDeleteGitHubServerResponse",
    ConfigurationControllerDeleteGitLabOrganizationRequest = "ConfigurationControllerDeleteGitLabOrganizationRequest",
    ConfigurationControllerDeleteGitLabOrganizationResponse = "ConfigurationControllerDeleteGitLabOrganizationResponse",
    ConfigurationControllerDeleteGitLabServerRequest = "ConfigurationControllerDeleteGitLabServerRequest",
    ConfigurationControllerDeleteGitLabServerResponse = "ConfigurationControllerDeleteGitLabServerResponse",
    ConfigurationControllerDeleteGroupRoleAssignmentRequest = "ConfigurationControllerDeleteGroupRoleAssignmentRequest",
    ConfigurationControllerDeleteJiraInstanceRequest = "ConfigurationControllerDeleteJiraInstanceRequest",
    ConfigurationControllerDeleteJiraProjectRequest = "ConfigurationControllerDeleteJiraProjectRequest",
    ConfigurationControllerDeleteKubernetesClusterConnectorRequest = "ConfigurationControllerDeleteKubernetesClusterConnectorRequest",
    ConfigurationControllerDeleteManualCustomEntityAttributeDefinitionRequest = "ConfigurationControllerDeleteManualCustomEntityAttributeDefinitionRequest",
    ConfigurationControllerDeletePermissionEligibilityRequest = "ConfigurationControllerDeletePermissionEligibilityRequest",
    ConfigurationControllerDeleteProjectRequest = "ConfigurationControllerDeleteProjectRequest",
    ConfigurationControllerDeleteQRadarServerRequest = "ConfigurationControllerDeleteQRadarServerRequest",
    ConfigurationControllerDeleteReportScheduleRequest = "ConfigurationControllerDeleteReportScheduleRequest",
    ConfigurationControllerDeleteScopeSettingConfigurationRequest = "ConfigurationControllerDeleteScopeSettingConfigurationRequest",
    ConfigurationControllerDeleteServiceNowInstanceRequest = "ConfigurationControllerDeleteServiceNowInstanceRequest",
    ConfigurationControllerDeleteSlackWorkspaceRequest = "ConfigurationControllerDeleteSlackWorkspaceRequest",
    ConfigurationControllerDeleteSplunkEventCollectorRequest = "ConfigurationControllerDeleteSplunkEventCollectorRequest",
    ConfigurationControllerDeleteSqsQueueRequest = "ConfigurationControllerDeleteSqsQueueRequest",
    ConfigurationControllerDeleteTeamsOrganizationRequest = "ConfigurationControllerDeleteTeamsOrganizationRequest",
    ConfigurationControllerDeleteUserRoleAssignmentRequest = "ConfigurationControllerDeleteUserRoleAssignmentRequest",
    ConfigurationControllerDeleteWebhookEndpointRequest = "ConfigurationControllerDeleteWebhookEndpointRequest",
    ConfigurationControllerDeleteWorkloadAnalysisTrustedFileRequest = "ConfigurationControllerDeleteWorkloadAnalysisTrustedFileRequest",
    ConfigurationControllerDeleteWorkloadAnalysisTrustedFileResponse = "ConfigurationControllerDeleteWorkloadAnalysisTrustedFileResponse",
    ConfigurationControllerGetAddableScopeSettingConfigurationTypeNamesRequest = "ConfigurationControllerGetAddableScopeSettingConfigurationTypeNamesRequest",
    ConfigurationControllerGetAddableScopeSettingConfigurationTypeNamesResponse = "ConfigurationControllerGetAddableScopeSettingConfigurationTypeNamesResponse",
    ConfigurationControllerGetAnalysisNetworkResponse = "ConfigurationControllerGetAnalysisNetworkResponse",
    ConfigurationControllerGetApiKeysRequest = "ConfigurationControllerGetApiKeysRequest",
    ConfigurationControllerGetApiKeysResponse = "ConfigurationControllerGetApiKeysResponse",
    ConfigurationControllerGetApiKeysResponseApiKeyData = "ConfigurationControllerGetApiKeysResponseApiKeyData",
    ConfigurationControllerGetAuthenticationConfigurationResponse = "ConfigurationControllerGetAuthenticationConfigurationResponse",
    ConfigurationControllerGetAutomaticCustomEntityAttributeDefinitionTemplateModelsResponse = "ConfigurationControllerGetAutomaticCustomEntityAttributeDefinitionTemplateModelsResponse",
    ConfigurationControllerGetAutomaticCustomEntityAttributeDefinitionTemplateUsageRequest = "ConfigurationControllerGetAutomaticCustomEntityAttributeDefinitionTemplateUsageRequest",
    ConfigurationControllerGetAutomaticCustomEntityAttributeDefinitionTemplateUsageResponse = "ConfigurationControllerGetAutomaticCustomEntityAttributeDefinitionTemplateUsageResponse",
    ConfigurationControllerGetAwsOrganizationMemberDatasRequest = "ConfigurationControllerGetAwsOrganizationMemberDatasRequest",
    ConfigurationControllerGetAwsOrganizationOnboardingInfoRequest = "ConfigurationControllerGetAwsOrganizationOnboardingInfoRequest",
    ConfigurationControllerGetAwsOrganizationOnboardingInfoResponse = "ConfigurationControllerGetAwsOrganizationOnboardingInfoResponse",
    ConfigurationControllerGetAwsOrganizationOnboardingInfoRootRequest = "ConfigurationControllerGetAwsOrganizationOnboardingInfoRootRequest",
    ConfigurationControllerGetAwsOrganizationOnboardingInfoRootResponse = "ConfigurationControllerGetAwsOrganizationOnboardingInfoRootResponse",
    ConfigurationControllerGetAwsRegionsResponse = "ConfigurationControllerGetAwsRegionsResponse",
    ConfigurationControllerGetAzureDevOpsOrganizationMemberDatasRequest = "ConfigurationControllerGetAzureDevOpsOrganizationMemberDatasRequest",
    ConfigurationControllerGetAzureDevOpsOrganizationOnboardingApplicationInfoResponse = "ConfigurationControllerGetAzureDevOpsOrganizationOnboardingApplicationInfoResponse",
    ConfigurationControllerGetAzureDevOpsOrganizationOnboardingOrganizationsInfoRequest = "ConfigurationControllerGetAzureDevOpsOrganizationOnboardingOrganizationsInfoRequest",
    ConfigurationControllerGetAzureDevOpsOrganizationOnboardingOrganizationsInfoResponse = "ConfigurationControllerGetAzureDevOpsOrganizationOnboardingOrganizationsInfoResponse",
    ConfigurationControllerGetAzureDevOpsOrganizationOnboardingTenantsInfoRequest = "ConfigurationControllerGetAzureDevOpsOrganizationOnboardingTenantsInfoRequest",
    ConfigurationControllerGetAzureDevOpsOrganizationOnboardingTenantsInfoResponse = "ConfigurationControllerGetAzureDevOpsOrganizationOnboardingTenantsInfoResponse",
    ConfigurationControllerGetAzureOrganizationMemberDatasRequest = "ConfigurationControllerGetAzureOrganizationMemberDatasRequest",
    ConfigurationControllerGetBitbucketOrganizationMemberDatasRequest = "ConfigurationControllerGetBitbucketOrganizationMemberDatasRequest",
    ConfigurationControllerGetBitbucketOrganizationOnboardingTenantsInfoRequest = "ConfigurationControllerGetBitbucketOrganizationOnboardingTenantsInfoRequest",
    ConfigurationControllerGetBitbucketOrganizationOnboardingTenantsInfoResponse = "ConfigurationControllerGetBitbucketOrganizationOnboardingTenantsInfoResponse",
    ConfigurationControllerGetCodePipelineApiRootUrlResponse = "ConfigurationControllerGetCodePipelineApiRootUrlResponse",
    ConfigurationControllerGetCustomEntityAttributeDefinitionModelsResponse = "ConfigurationControllerGetCustomEntityAttributeDefinitionModelsResponse",
    ConfigurationControllerGetCustomEntityAttributeDefinitionUsageRequest = "ConfigurationControllerGetCustomEntityAttributeDefinitionUsageRequest",
    ConfigurationControllerGetCustomEntityAttributeDefinitionUsageResponse = "ConfigurationControllerGetCustomEntityAttributeDefinitionUsageResponse",
    ConfigurationControllerGetEndpointConnectorApiKeyTokenRequest = "ConfigurationControllerGetEndpointConnectorApiKeyTokenRequest",
    ConfigurationControllerGetEndpointConnectorApiKeyTokenResponse = "ConfigurationControllerGetEndpointConnectorApiKeyTokenResponse",
    ConfigurationControllerGetEndpointConnectorIdToIntegrationNameMapResponse = "ConfigurationControllerGetEndpointConnectorIdToIntegrationNameMapResponse",
    ConfigurationControllerGetEndpointConnectorTunnelSessionClientIdsResponse = "ConfigurationControllerGetEndpointConnectorTunnelSessionClientIdsResponse",
    ConfigurationControllerGetEntityPropertyIdentifierUsageRequest = "ConfigurationControllerGetEntityPropertyIdentifierUsageRequest",
    ConfigurationControllerGetEntityPropertyIdentifierUsageResponse = "ConfigurationControllerGetEntityPropertyIdentifierUsageResponse",
    ConfigurationControllerGetFeaturesResponse = "ConfigurationControllerGetFeaturesResponse",
    ConfigurationControllerGetGcpOrganizationMemberDatasRequest = "ConfigurationControllerGetGcpOrganizationMemberDatasRequest",
    ConfigurationControllerGetGcpOrganizationOnboardingInfoResponse = "ConfigurationControllerGetGcpOrganizationOnboardingInfoResponse",
    ConfigurationControllerGetGitHubOrganizationMemberDatasRequest = "ConfigurationControllerGetGitHubOrganizationMemberDatasRequest",
    ConfigurationControllerGetGitHubOrganizationOnboardingApplicationInfoResponse = "ConfigurationControllerGetGitHubOrganizationOnboardingApplicationInfoResponse",
    ConfigurationControllerGetGitHubOrganizationOnboardingOrganizationsInfoRequest = "ConfigurationControllerGetGitHubOrganizationOnboardingOrganizationsInfoRequest",
    ConfigurationControllerGetGitHubOrganizationOnboardingOrganizationsInfoResponse = "ConfigurationControllerGetGitHubOrganizationOnboardingOrganizationsInfoResponse",
    ConfigurationControllerGetGitHubOrganizationOnboardingTenantsInfoRequest = "ConfigurationControllerGetGitHubOrganizationOnboardingTenantsInfoRequest",
    ConfigurationControllerGetGitHubOrganizationOnboardingTenantsInfoResponse = "ConfigurationControllerGetGitHubOrganizationOnboardingTenantsInfoResponse",
    ConfigurationControllerGetGitHubServerOnboardingApplicationInfoResponse = "ConfigurationControllerGetGitHubServerOnboardingApplicationInfoResponse",
    ConfigurationControllerGetGitLabOrganizationMemberDatasRequest = "ConfigurationControllerGetGitLabOrganizationMemberDatasRequest",
    ConfigurationControllerGetGitLabOrganizationOnboardingTenantsInfoRequest = "ConfigurationControllerGetGitLabOrganizationOnboardingTenantsInfoRequest",
    ConfigurationControllerGetGitLabOrganizationOnboardingTenantsInfoResponse = "ConfigurationControllerGetGitLabOrganizationOnboardingTenantsInfoResponse",
    ConfigurationControllerGetJiraRawProjectDataError = "ConfigurationControllerGetJiraRawProjectDataError",
    ConfigurationControllerGetJiraRawProjectDataRequest = "ConfigurationControllerGetJiraRawProjectDataRequest",
    ConfigurationControllerGetJiraRawProjectDataResponse = "ConfigurationControllerGetJiraRawProjectDataResponse",
    ConfigurationControllerGetKubernetesClusterConnectorIdToClusterIdsMapResponse = "ConfigurationControllerGetKubernetesClusterConnectorIdToClusterIdsMapResponse",
    ConfigurationControllerGetLicensingDataRequest = "ConfigurationControllerGetLicensingDataRequest",
    ConfigurationControllerGetLicensingDataResponse = "ConfigurationControllerGetLicensingDataResponse",
    ConfigurationControllerGetLicensingExportDataRequest = "ConfigurationControllerGetLicensingExportDataRequest",
    ConfigurationControllerGetLicensingExportDataResponse = "ConfigurationControllerGetLicensingExportDataResponse",
    ConfigurationControllerGetLicensingExportDataResponseTenantData = "ConfigurationControllerGetLicensingExportDataResponseTenantData",
    ConfigurationControllerGetLicensingResponse = "ConfigurationControllerGetLicensingResponse",
    ConfigurationControllerGetOciOrganizationMemberDatasRequest = "ConfigurationControllerGetOciOrganizationMemberDatasRequest",
    ConfigurationControllerGetOrganizationMemberDatasRequest = "ConfigurationControllerGetOrganizationMemberDatasRequest",
    ConfigurationControllerGetOrganizationMemberDatasResponse = "ConfigurationControllerGetOrganizationMemberDatasResponse",
    ConfigurationControllerGetOrganizationMemberDatasResponseNode = "ConfigurationControllerGetOrganizationMemberDatasResponseNode",
    ConfigurationControllerGetPrincipalRoleAssignmentsRequest = "ConfigurationControllerGetPrincipalRoleAssignmentsRequest",
    ConfigurationControllerGetPrincipalRoleAssignmentsResponse = "ConfigurationControllerGetPrincipalRoleAssignmentsResponse",
    ConfigurationControllerGetPrincipalRoleAssignmentsResponsePrincipalRoleAssignment = "ConfigurationControllerGetPrincipalRoleAssignmentsResponsePrincipalRoleAssignment",
    ConfigurationControllerGetPrincipalRoleAssignmentsResponseUser = "ConfigurationControllerGetPrincipalRoleAssignmentsResponseUser",
    ConfigurationControllerGetProjectConfigurationRulesMatchingTenantIdsRequest = "ConfigurationControllerGetProjectConfigurationRulesMatchingTenantIdsRequest",
    ConfigurationControllerGetProjectConfigurationRulesMatchingTenantIdsResponse = "ConfigurationControllerGetProjectConfigurationRulesMatchingTenantIdsResponse",
    ConfigurationControllerGetReportDefinitionsResponse = "ConfigurationControllerGetReportDefinitionsResponse",
    ConfigurationControllerGetScopePathCustomComplianceIdsRequest = "ConfigurationControllerGetScopePathCustomComplianceIdsRequest",
    ConfigurationControllerGetScopePathCustomComplianceIdsResponse = "ConfigurationControllerGetScopePathCustomComplianceIdsResponse",
    ConfigurationControllerGetScopeSettingConfigurationDatasRequest = "ConfigurationControllerGetScopeSettingConfigurationDatasRequest",
    ConfigurationControllerGetScopeSettingConfigurationDatasResponse = "ConfigurationControllerGetScopeSettingConfigurationDatasResponse",
    ConfigurationControllerGetScopeSystemEntityModelsRequest = "ConfigurationControllerGetScopeSystemEntityModelsRequest",
    ConfigurationControllerGetScopeSystemEntityModelsResponse = "ConfigurationControllerGetScopeSystemEntityModelsResponse",
    ConfigurationControllerGetSlackWorkspaceIdToOAuthAuthorizationUrlMapRequest = "ConfigurationControllerGetSlackWorkspaceIdToOAuthAuthorizationUrlMapRequest",
    ConfigurationControllerGetSlackWorkspaceIdToOAuthAuthorizationUrlMapResponse = "ConfigurationControllerGetSlackWorkspaceIdToOAuthAuthorizationUrlMapResponse",
    ConfigurationControllerGetSlackWorkspaceOnboardingInfoRequest = "ConfigurationControllerGetSlackWorkspaceOnboardingInfoRequest",
    ConfigurationControllerGetSlackWorkspaceOnboardingInfoResponse = "ConfigurationControllerGetSlackWorkspaceOnboardingInfoResponse",
    ConfigurationControllerGetTeamsOrganizationOnboardingInfoRequest = "ConfigurationControllerGetTeamsOrganizationOnboardingInfoRequest",
    ConfigurationControllerGetTeamsOrganizationOnboardingInfoResponse = "ConfigurationControllerGetTeamsOrganizationOnboardingInfoResponse",
    ConfigurationControllerGetTerraformCloudScanApiUrlResponse = "ConfigurationControllerGetTerraformCloudScanApiUrlResponse",
    ConfigurationControllerGetWorkloadAnalysisOperatingSystemEndOfLifeTimeFrameResponse = "ConfigurationControllerGetWorkloadAnalysisOperatingSystemEndOfLifeTimeFrameResponse",
    ConfigurationControllerGetWorkloadAnalysisVulnerabilityScoreTypeResponse = "ConfigurationControllerGetWorkloadAnalysisVulnerabilityScoreTypeResponse",
    ConfigurationControllerGetWorkspaceIdToChannelRawIdToNameMapResponse = "ConfigurationControllerGetWorkspaceIdToChannelRawIdToNameMapResponse",
    ConfigurationControllerInsertAadDirectoryGroupMembershipEligibilityRequest = "ConfigurationControllerInsertAadDirectoryGroupMembershipEligibilityRequest",
    ConfigurationControllerInsertApiKeyError = "ConfigurationControllerInsertApiKeyError",
    ConfigurationControllerInsertApiKeyRequest = "ConfigurationControllerInsertApiKeyRequest",
    ConfigurationControllerInsertApiKeyResponse = "ConfigurationControllerInsertApiKeyResponse",
    ConfigurationControllerInsertAwsPolicySsoPermissionSetAssignmentEligibilityRequest = "ConfigurationControllerInsertAwsPolicySsoPermissionSetAssignmentEligibilityRequest",
    ConfigurationControllerInsertAwsSsoPermissionSetAssignmentEligibilityRequest = "ConfigurationControllerInsertAwsSsoPermissionSetAssignmentEligibilityRequest",
    ConfigurationControllerInsertAwsSsoPermissionSetPermissionSetAssignmentEligibilityRequest = "ConfigurationControllerInsertAwsSsoPermissionSetPermissionSetAssignmentEligibilityRequest",
    ConfigurationControllerInsertAzureDevOpsOrganizationRequest = "ConfigurationControllerInsertAzureDevOpsOrganizationRequest",
    ConfigurationControllerInsertAzureDevOpsOrganizationResponse = "ConfigurationControllerInsertAzureDevOpsOrganizationResponse",
    ConfigurationControllerInsertAzureRoleAssignmentEligibilityRequest = "ConfigurationControllerInsertAzureRoleAssignmentEligibilityRequest",
    ConfigurationControllerInsertBitbucketOrganizationRequest = "ConfigurationControllerInsertBitbucketOrganizationRequest",
    ConfigurationControllerInsertBitbucketOrganizationResponse = "ConfigurationControllerInsertBitbucketOrganizationResponse",
    ConfigurationControllerInsertCiCloudsmithIntegrationRequest = "ConfigurationControllerInsertCiCloudsmithIntegrationRequest",
    ConfigurationControllerInsertCiCloudsmithIntegrationResponse = "ConfigurationControllerInsertCiCloudsmithIntegrationResponse",
    ConfigurationControllerInsertCiGeneralIntegrationRequest = "ConfigurationControllerInsertCiGeneralIntegrationRequest",
    ConfigurationControllerInsertCiGeneralIntegrationResponse = "ConfigurationControllerInsertCiGeneralIntegrationResponse",
    ConfigurationControllerInsertCiJfrogIntegrationRequest = "ConfigurationControllerInsertCiJfrogIntegrationRequest",
    ConfigurationControllerInsertCiJfrogIntegrationResponse = "ConfigurationControllerInsertCiJfrogIntegrationResponse",
    ConfigurationControllerInsertCodePipelineRequest = "ConfigurationControllerInsertCodePipelineRequest",
    ConfigurationControllerInsertCodePipelineResponse = "ConfigurationControllerInsertCodePipelineResponse",
    ConfigurationControllerInsertDatadogOrganizationError = "ConfigurationControllerInsertDatadogOrganizationError",
    ConfigurationControllerInsertDatadogOrganizationRequest = "ConfigurationControllerInsertDatadogOrganizationRequest",
    ConfigurationControllerInsertEndpointConnectorRequest = "ConfigurationControllerInsertEndpointConnectorRequest",
    ConfigurationControllerInsertGciDirectoryGroupMembershipEligibilityRequest = "ConfigurationControllerInsertGciDirectoryGroupMembershipEligibilityRequest",
    ConfigurationControllerInsertGcpRoleBindingEligibilityRequest = "ConfigurationControllerInsertGcpRoleBindingEligibilityRequest",
    ConfigurationControllerInsertGitHubOrganizationRequest = "ConfigurationControllerInsertGitHubOrganizationRequest",
    ConfigurationControllerInsertGitHubOrganizationResponse = "ConfigurationControllerInsertGitHubOrganizationResponse",
    ConfigurationControllerInsertGitHubServerRequest = "ConfigurationControllerInsertGitHubServerRequest",
    ConfigurationControllerInsertGitHubServerResponse = "ConfigurationControllerInsertGitHubServerResponse",
    ConfigurationControllerInsertGitLabOrganizationRequest = "ConfigurationControllerInsertGitLabOrganizationRequest",
    ConfigurationControllerInsertGitLabOrganizationResponse = "ConfigurationControllerInsertGitLabOrganizationResponse",
    ConfigurationControllerInsertGitLabServerRequest = "ConfigurationControllerInsertGitLabServerRequest",
    ConfigurationControllerInsertGitLabServerResponse = "ConfigurationControllerInsertGitLabServerResponse",
    ConfigurationControllerInsertGroupMembershipEligibilityRequest = "ConfigurationControllerInsertGroupMembershipEligibilityRequest",
    ConfigurationControllerInsertGroupRoleAssignmentError = "ConfigurationControllerInsertGroupRoleAssignmentError",
    ConfigurationControllerInsertGroupRoleAssignmentRequest = "ConfigurationControllerInsertGroupRoleAssignmentRequest",
    ConfigurationControllerInsertOciOrganizationRequest = "ConfigurationControllerInsertOciOrganizationRequest",
    ConfigurationControllerInsertOciOrganizationResponse = "ConfigurationControllerInsertOciOrganizationResponse",
    ConfigurationControllerInsertOktaDirectoryGroupMembershipEligibilityRequest = "ConfigurationControllerInsertOktaDirectoryGroupMembershipEligibilityRequest",
    ConfigurationControllerInsertOneLoginDirectoryRoleAssignmentEligibilityRequest = "ConfigurationControllerInsertOneLoginDirectoryRoleAssignmentEligibilityRequest",
    ConfigurationControllerInsertPermissionAssignmentEligibilityRequest = "ConfigurationControllerInsertPermissionAssignmentEligibilityRequest",
    ConfigurationControllerInsertPermissionEligibilityRequest = "ConfigurationControllerInsertPermissionEligibilityRequest",
    ConfigurationControllerInsertPingIdentityDirectoryGroupMembershipEligibilityRequest = "ConfigurationControllerInsertPingIdentityDirectoryGroupMembershipEligibilityRequest",
    ConfigurationControllerInsertProjectRequest = "ConfigurationControllerInsertProjectRequest",
    ConfigurationControllerInsertProjectResponse = "ConfigurationControllerInsertProjectResponse",
    ConfigurationControllerInsertQRadarServerRequest = "ConfigurationControllerInsertQRadarServerRequest",
    ConfigurationControllerInsertUserRoleAssignmentError = "ConfigurationControllerInsertUserRoleAssignmentError",
    ConfigurationControllerInsertUserRoleAssignmentRequest = "ConfigurationControllerInsertUserRoleAssignmentRequest",
    ConfigurationControllerInsertWebhookEndpointRequest = "ConfigurationControllerInsertWebhookEndpointRequest",
    ConfigurationControllerInsertWorkloadAnalysisTrustedFilesRequest = "ConfigurationControllerInsertWorkloadAnalysisTrustedFilesRequest",
    ConfigurationControllerInsertWorkloadAnalysisTrustedFilesResponse = "ConfigurationControllerInsertWorkloadAnalysisTrustedFilesResponse",
    ConfigurationControllerPermissionEligibilityApproval = "ConfigurationControllerPermissionEligibilityApproval",
    ConfigurationControllerSetOwnerRequest = "ConfigurationControllerSetOwnerRequest",
    ConfigurationControllerUpdateAadDirectoryGroupMembershipEligibilityRequest = "ConfigurationControllerUpdateAadDirectoryGroupMembershipEligibilityRequest",
    ConfigurationControllerUpdateAuthenticationConfigurationRequest = "ConfigurationControllerUpdateAuthenticationConfigurationRequest",
    ConfigurationControllerUpdateAwsPolicySsoPermissionSetAssignmentEligibilityRequest = "ConfigurationControllerUpdateAwsPolicySsoPermissionSetAssignmentEligibilityRequest",
    ConfigurationControllerUpdateAwsSsoPermissionSetAssignmentEligibilityRequest = "ConfigurationControllerUpdateAwsSsoPermissionSetAssignmentEligibilityRequest",
    ConfigurationControllerUpdateAwsSsoPermissionSetPermissionSetAssignmentEligibilityRequest = "ConfigurationControllerUpdateAwsSsoPermissionSetPermissionSetAssignmentEligibilityRequest",
    ConfigurationControllerUpdateAzureDevOpsOrganizationRequest = "ConfigurationControllerUpdateAzureDevOpsOrganizationRequest",
    ConfigurationControllerUpdateAzureDevOpsOrganizationResponse = "ConfigurationControllerUpdateAzureDevOpsOrganizationResponse",
    ConfigurationControllerUpdateAzureRoleAssignmentEligibilityRequest = "ConfigurationControllerUpdateAzureRoleAssignmentEligibilityRequest",
    ConfigurationControllerUpdateAzureWorkloadAnalysisRequest = "ConfigurationControllerUpdateAzureWorkloadAnalysisRequest",
    ConfigurationControllerUpdateAzureWorkloadAnalysisResponse = "ConfigurationControllerUpdateAzureWorkloadAnalysisResponse",
    ConfigurationControllerUpdateBitbucketOrganizationRequest = "ConfigurationControllerUpdateBitbucketOrganizationRequest",
    ConfigurationControllerUpdateBitbucketOrganizationResponse = "ConfigurationControllerUpdateBitbucketOrganizationResponse",
    ConfigurationControllerUpdateCiCloudsmithIntegrationRequest = "ConfigurationControllerUpdateCiCloudsmithIntegrationRequest",
    ConfigurationControllerUpdateCiCloudsmithIntegrationResponse = "ConfigurationControllerUpdateCiCloudsmithIntegrationResponse",
    ConfigurationControllerUpdateCiGeneralIntegrationRequest = "ConfigurationControllerUpdateCiGeneralIntegrationRequest",
    ConfigurationControllerUpdateCiGeneralIntegrationResponse = "ConfigurationControllerUpdateCiGeneralIntegrationResponse",
    ConfigurationControllerUpdateCiJfrogIntegrationRequest = "ConfigurationControllerUpdateCiJfrogIntegrationRequest",
    ConfigurationControllerUpdateCiJfrogIntegrationResponse = "ConfigurationControllerUpdateCiJfrogIntegrationResponse",
    ConfigurationControllerUpdateCodePipelineRequest = "ConfigurationControllerUpdateCodePipelineRequest",
    ConfigurationControllerUpdateCodePipelineResponse = "ConfigurationControllerUpdateCodePipelineResponse",
    ConfigurationControllerUpdateCodeRequest = "ConfigurationControllerUpdateCodeRequest",
    ConfigurationControllerUpdateDataAnalysisRequest = "ConfigurationControllerUpdateDataAnalysisRequest",
    ConfigurationControllerUpdateDatadogOrganizationError = "ConfigurationControllerUpdateDatadogOrganizationError",
    ConfigurationControllerUpdateDatadogOrganizationRequest = "ConfigurationControllerUpdateDatadogOrganizationRequest",
    ConfigurationControllerUpdateGciDirectoryGroupMembershipEligibilityRequest = "ConfigurationControllerUpdateGciDirectoryGroupMembershipEligibilityRequest",
    ConfigurationControllerUpdateGcpRoleBindingEligibilityRequest = "ConfigurationControllerUpdateGcpRoleBindingEligibilityRequest",
    ConfigurationControllerUpdateGitHubOrganizationRequest = "ConfigurationControllerUpdateGitHubOrganizationRequest",
    ConfigurationControllerUpdateGitHubOrganizationResponse = "ConfigurationControllerUpdateGitHubOrganizationResponse",
    ConfigurationControllerUpdateGitHubServerRequest = "ConfigurationControllerUpdateGitHubServerRequest",
    ConfigurationControllerUpdateGitHubServerResponse = "ConfigurationControllerUpdateGitHubServerResponse",
    ConfigurationControllerUpdateGitLabOrganizationRequest = "ConfigurationControllerUpdateGitLabOrganizationRequest",
    ConfigurationControllerUpdateGitLabOrganizationResponse = "ConfigurationControllerUpdateGitLabOrganizationResponse",
    ConfigurationControllerUpdateGitLabServerRequest = "ConfigurationControllerUpdateGitLabServerRequest",
    ConfigurationControllerUpdateGitLabServerResponse = "ConfigurationControllerUpdateGitLabServerResponse",
    ConfigurationControllerUpdateGroupMembershipEligibilityRequest = "ConfigurationControllerUpdateGroupMembershipEligibilityRequest",
    ConfigurationControllerUpdateGroupRoleAssignmentRequest = "ConfigurationControllerUpdateGroupRoleAssignmentRequest",
    ConfigurationControllerUpdateOciOrganizationRequest = "ConfigurationControllerUpdateOciOrganizationRequest",
    ConfigurationControllerUpdateOciOrganizationRequestUser = "ConfigurationControllerUpdateOciOrganizationRequestUser",
    ConfigurationControllerUpdateOciOrganizationResponse = "ConfigurationControllerUpdateOciOrganizationResponse",
    ConfigurationControllerUpdateOktaDirectoryGroupMembershipEligibilityRequest = "ConfigurationControllerUpdateOktaDirectoryGroupMembershipEligibilityRequest",
    ConfigurationControllerUpdateOneLoginDirectoryRoleAssignmentEligibilityRequest = "ConfigurationControllerUpdateOneLoginDirectoryRoleAssignmentEligibilityRequest",
    ConfigurationControllerUpdatePermissionAssignmentEligibilityRequest = "ConfigurationControllerUpdatePermissionAssignmentEligibilityRequest",
    ConfigurationControllerUpdatePermissionEligibilityRequest = "ConfigurationControllerUpdatePermissionEligibilityRequest",
    ConfigurationControllerUpdatePermissionManagementSlackEnabledRequest = "ConfigurationControllerUpdatePermissionManagementSlackEnabledRequest",
    ConfigurationControllerUpdatePermissionManagementTeamsEnabledRequest = "ConfigurationControllerUpdatePermissionManagementTeamsEnabledRequest",
    ConfigurationControllerUpdatePingIdentityDirectoryGroupMembershipEligibilityRequest = "ConfigurationControllerUpdatePingIdentityDirectoryGroupMembershipEligibilityRequest",
    ConfigurationControllerUpdateProjectRequest = "ConfigurationControllerUpdateProjectRequest",
    ConfigurationControllerUpdateProjectResponse = "ConfigurationControllerUpdateProjectResponse",
    ConfigurationControllerUpdateQRadarServerRequest = "ConfigurationControllerUpdateQRadarServerRequest",
    ConfigurationControllerUpdateSlackWorkspacesPermissionManagementEnabledError = "ConfigurationControllerUpdateSlackWorkspacesPermissionManagementEnabledError",
    ConfigurationControllerUpdateSlackWorkspacesPermissionManagementEnabledRequest = "ConfigurationControllerUpdateSlackWorkspacesPermissionManagementEnabledRequest",
    ConfigurationControllerUpdateUserRoleAssignmentRequest = "ConfigurationControllerUpdateUserRoleAssignmentRequest",
    ConfigurationControllerUpdateWebhookEndpointRequest = "ConfigurationControllerUpdateWebhookEndpointRequest",
    ConfigurationControllerUpdateWorkloadAnalysisOperatingSystemEndOfLifeTimeFrameRequest = "ConfigurationControllerUpdateWorkloadAnalysisOperatingSystemEndOfLifeTimeFrameRequest",
    ConfigurationControllerUpdateWorkloadAnalysisRequest = "ConfigurationControllerUpdateWorkloadAnalysisRequest",
    ConfigurationControllerUpdateWorkloadAnalysisResponse = "ConfigurationControllerUpdateWorkloadAnalysisResponse",
    ConfigurationControllerUpdateWorkloadAnalysisVulnerabilityScoreTypeRequest = "ConfigurationControllerUpdateWorkloadAnalysisVulnerabilityScoreTypeRequest",
    ConfigurationControllerUpsertAadSeverePermissionDirectoryRoleDefinitionsConfigurationRequest = "ConfigurationControllerUpsertAadSeverePermissionDirectoryRoleDefinitionsConfigurationRequest",
    ConfigurationControllerUpsertAnalysisNetworkRequest = "ConfigurationControllerUpsertAnalysisNetworkRequest",
    ConfigurationControllerUpsertAnalysisNetworkResponse = "ConfigurationControllerUpsertAnalysisNetworkResponse",
    ConfigurationControllerUpsertAuditEventAutomationRuleRequest = "ConfigurationControllerUpsertAuditEventAutomationRuleRequest",
    ConfigurationControllerUpsertAutomaticCustomEntityAttributeDefinitionTemplateRequest = "ConfigurationControllerUpsertAutomaticCustomEntityAttributeDefinitionTemplateRequest",
    ConfigurationControllerUpsertAutomaticCustomEntityAttributeDefinitionTemplateResponse = "ConfigurationControllerUpsertAutomaticCustomEntityAttributeDefinitionTemplateResponse",
    ConfigurationControllerUpsertAutomaticCustomEntityAttributeResourceTagDefinitionTemplateRequest = "ConfigurationControllerUpsertAutomaticCustomEntityAttributeResourceTagDefinitionTemplateRequest",
    ConfigurationControllerUpsertAutomaticCustomEntityAttributeResourceTagKeyDefinitionTemplateRequest = "ConfigurationControllerUpsertAutomaticCustomEntityAttributeResourceTagKeyDefinitionTemplateRequest",
    ConfigurationControllerUpsertAutomaticCustomEntityAttributeResourceTagPatternsDefinitionTemplateRequest = "ConfigurationControllerUpsertAutomaticCustomEntityAttributeResourceTagPatternsDefinitionTemplateRequest",
    ConfigurationControllerUpsertAwsOrganizationRequest = "ConfigurationControllerUpsertAwsOrganizationRequest",
    ConfigurationControllerUpsertAwsOrganizationResponse = "ConfigurationControllerUpsertAwsOrganizationResponse",
    ConfigurationControllerUpsertAzureOrganizationRequest = "ConfigurationControllerUpsertAzureOrganizationRequest",
    ConfigurationControllerUpsertAzureOrganizationResponse = "ConfigurationControllerUpsertAzureOrganizationResponse",
    ConfigurationControllerUpsertCustomComplianceRequest = "ConfigurationControllerUpsertCustomComplianceRequest",
    ConfigurationControllerUpsertCustomComplianceRequestRiskPolicy = "ConfigurationControllerUpsertCustomComplianceRequestRiskPolicy",
    ConfigurationControllerUpsertCustomComplianceRequestSection = "ConfigurationControllerUpsertCustomComplianceRequestSection",
    ConfigurationControllerUpsertEntityPropertyDefinitionRequest = "ConfigurationControllerUpsertEntityPropertyDefinitionRequest",
    ConfigurationControllerUpsertGcpOrganizationRequest = "ConfigurationControllerUpsertGcpOrganizationRequest",
    ConfigurationControllerUpsertGcpOrganizationResponse = "ConfigurationControllerUpsertGcpOrganizationResponse",
    ConfigurationControllerUpsertJiraInstanceError = "ConfigurationControllerUpsertJiraInstanceError",
    ConfigurationControllerUpsertJiraInstanceRequest = "ConfigurationControllerUpsertJiraInstanceRequest",
    ConfigurationControllerUpsertJiraProjectError = "ConfigurationControllerUpsertJiraProjectError",
    ConfigurationControllerUpsertJiraProjectRequest = "ConfigurationControllerUpsertJiraProjectRequest",
    ConfigurationControllerUpsertKubernetesClusterConnectorRequest = "ConfigurationControllerUpsertKubernetesClusterConnectorRequest",
    ConfigurationControllerUpsertManualCustomEntityAttributeDefinitionRequest = "ConfigurationControllerUpsertManualCustomEntityAttributeDefinitionRequest",
    ConfigurationControllerUpsertManualCustomEntityAttributeDefinitionResponse = "ConfigurationControllerUpsertManualCustomEntityAttributeDefinitionResponse",
    ConfigurationControllerUpsertPermissionEligibilityError = "ConfigurationControllerUpsertPermissionEligibilityError",
    ConfigurationControllerUpsertReportScheduleRequest = "ConfigurationControllerUpsertReportScheduleRequest",
    ConfigurationControllerUpsertRiskAutomationRuleRequest = "ConfigurationControllerUpsertRiskAutomationRuleRequest",
    ConfigurationControllerUpsertScopeSettingConfigurationRequest = "ConfigurationControllerUpsertScopeSettingConfigurationRequest",
    ConfigurationControllerUpsertScopeSystemEntityResponse = "ConfigurationControllerUpsertScopeSystemEntityResponse",
    ConfigurationControllerUpsertServiceIdentityExcessivePermissionEvaluationTimeFrameConfigurationRequest = "ConfigurationControllerUpsertServiceIdentityExcessivePermissionEvaluationTimeFrameConfigurationRequest",
    ConfigurationControllerUpsertServiceNowInstanceError = "ConfigurationControllerUpsertServiceNowInstanceError",
    ConfigurationControllerUpsertServiceNowInstanceRequest = "ConfigurationControllerUpsertServiceNowInstanceRequest",
    ConfigurationControllerUpsertSplunkEventCollectorError = "ConfigurationControllerUpsertSplunkEventCollectorError",
    ConfigurationControllerUpsertSplunkEventCollectorRequest = "ConfigurationControllerUpsertSplunkEventCollectorRequest",
    ConfigurationControllerUpsertSqsQueueError = "ConfigurationControllerUpsertSqsQueueError",
    ConfigurationControllerUpsertSqsQueueRequest = "ConfigurationControllerUpsertSqsQueueRequest",
    ConfigurationControllerUpsertUserExcessivePermissionEvaluationTimeFrameConfigurationRequest = "ConfigurationControllerUpsertUserExcessivePermissionEvaluationTimeFrameConfigurationRequest",
    ConfigurationControllerValidateAuthenticationSamlIdentityProviderError = "ConfigurationControllerValidateAuthenticationSamlIdentityProviderError",
    ConfigurationControllerValidateAuthenticationSamlIdentityProviderRequest = "ConfigurationControllerValidateAuthenticationSamlIdentityProviderRequest",
    ConfigurationControllerValidateAuthenticationSamlIdentityProviderResponse = "ConfigurationControllerValidateAuthenticationSamlIdentityProviderResponse",
    ConfigurationControllerValidateGitHubServerOnboardingServerInfoRequest = "ConfigurationControllerValidateGitHubServerOnboardingServerInfoRequest",
    ConfigurationControllerValidateGitHubServerOnboardingServerInfoResponse = "ConfigurationControllerValidateGitHubServerOnboardingServerInfoResponse",
    ConfigurationSection = "ConfigurationSection",
    ConsoleApiServiceConfiguration = "ConsoleApiServiceConfiguration",
    ConsoleAppType = "ConsoleAppType",
    ConsoleAppUrlBuilderConfigurationSection = "ConsoleAppUrlBuilderConfigurationSection",
    ContainerImageData = "ContainerImageData",
    ContainerImageDataScanStatus = "ContainerImageDataScanStatus",
    ContainerImageMaliciousFileRisk = "ContainerImageMaliciousFileRisk",
    ContainerImageMaliciousFileRiskModel = "ContainerImageMaliciousFileRiskModel",
    ContainerImageMaliciousFileRiskPolicyConfiguration = "ContainerImageMaliciousFileRiskPolicyConfiguration",
    ContainerImageOperatingSystemEndOfLifeRisk = "ContainerImageOperatingSystemEndOfLifeRisk",
    ContainerImageOperatingSystemEndOfLifeRiskModel = "ContainerImageOperatingSystemEndOfLifeRiskModel",
    ContainerImageOperatingSystemEndOfLifeRiskPolicyConfiguration = "ContainerImageOperatingSystemEndOfLifeRiskPolicyConfiguration",
    ContainerImageOperatingSystemUnpatchedRisk = "ContainerImageOperatingSystemUnpatchedRisk",
    ContainerImageOperatingSystemUnpatchedRiskModel = "ContainerImageOperatingSystemUnpatchedRiskModel",
    ContainerImageOperatingSystemUnpatchedRiskPolicyConfiguration = "ContainerImageOperatingSystemUnpatchedRiskPolicyConfiguration",
    ContainerImagePackageVulnerabilitiesReportDefinitionConfiguration = "ContainerImagePackageVulnerabilitiesReportDefinitionConfiguration",
    ContainerImageRegistryManagerConfigurationSection = "ContainerImageRegistryManagerConfigurationSection",
    ContainerImageRegistryManagerConfigurationSectionAzureContainerRegistry = "ContainerImageRegistryManagerConfigurationSectionAzureContainerRegistry",
    ContainerImageRepositoryAnalysisServiceConfiguration = "ContainerImageRepositoryAnalysisServiceConfiguration",
    ContainerImageRepositoryVulnerabilityRisk = "ContainerImageRepositoryVulnerabilityRisk",
    ContainerImageRepositoryVulnerabilityRiskMetadata = "ContainerImageRepositoryVulnerabilityRiskMetadata",
    ContainerImageRepositoryVulnerabilityRiskModel = "ContainerImageRepositoryVulnerabilityRiskModel",
    ContainerImageRepositoryVulnerabilityRiskPolicyConfiguration = "ContainerImageRepositoryVulnerabilityRiskPolicyConfiguration",
    ContainerImageRepositoryWorkloadAnalysisRisk = "ContainerImageRepositoryWorkloadAnalysisRisk",
    ContainerImageResourceStateContainerImageData = "ContainerImageResourceStateContainerImageData",
    ContainerImageScanFilters = "ContainerImageScanFilters",
    ContainerImagesReportDefinitionConfiguration = "ContainerImagesReportDefinitionConfiguration",
    ContainerImageVulnerabilityRisk = "ContainerImageVulnerabilityRisk",
    ContainerImageVulnerabilityRiskModel = "ContainerImageVulnerabilityRiskModel",
    ContainerImageVulnerabilityRiskPolicyConfiguration = "ContainerImageVulnerabilityRiskPolicyConfiguration",
    ContainerImageWorkloadAnalysisRisk = "ContainerImageWorkloadAnalysisRisk",
    CredentialsDisabledUserAttribute = "CredentialsDisabledUserAttribute",
    CustomActivityRiskPolicyConfiguration = "CustomActivityRiskPolicyConfiguration",
    CustomActivityRiskPolicyTypeMetadata = "CustomActivityRiskPolicyTypeMetadata",
    CustomCodeBuilderKubernetesAdmissionControllerRiskPolicyConfiguration = "CustomCodeBuilderKubernetesAdmissionControllerRiskPolicyConfiguration",
    CustomCodeBuilderKubernetesAdmissionControllerRiskPolicyConfigurationCondition = "CustomCodeBuilderKubernetesAdmissionControllerRiskPolicyConfigurationCondition",
    CustomCodeBuilderKubernetesAdmissionControllerRiskPolicyConfigurationConditionOperator = "CustomCodeBuilderKubernetesAdmissionControllerRiskPolicyConfigurationConditionOperator",
    CustomCodeKubernetesAdmissionControllerRiskPolicyConfiguration = "CustomCodeKubernetesAdmissionControllerRiskPolicyConfiguration",
    CustomComplianceAuditEvent = "CustomComplianceAuditEvent",
    CustomComplianceConfiguration = "CustomComplianceConfiguration",
    CustomComplianceCreationAuditEvent = "CustomComplianceCreationAuditEvent",
    CustomComplianceDeletionAuditEvent = "CustomComplianceDeletionAuditEvent",
    CustomComplianceReportDefinitionConfiguration = "CustomComplianceReportDefinitionConfiguration",
    CustomComplianceRiskPolicy = "CustomComplianceRiskPolicy",
    CustomComplianceSection = "CustomComplianceSection",
    CustomComplianceUpdateAuditEvent = "CustomComplianceUpdateAuditEvent",
    CustomEntityAttribute = "CustomEntityAttribute",
    CustomEntityAttributeDefinitionConfiguration = "CustomEntityAttributeDefinitionConfiguration",
    CustomEntityAttributeDefinitionModel = "CustomEntityAttributeDefinitionModel",
    CustomerConfiguration = "CustomerConfiguration",
    CustomerConfigurationAnalysis = "CustomerConfigurationAnalysis",
    CustomerConfigurationAnalysisDraining = "CustomerConfigurationAnalysisDraining",
    CustomerConfigurationAnalysisIam = "CustomerConfigurationAnalysisIam",
    CustomerConfigurationAnalysisNetwork = "CustomerConfigurationAnalysisNetwork",
    CustomerConfigurationAnalysisSchedule = "CustomerConfigurationAnalysisSchedule",
    CustomerConfigurationAwsEc2Analysis = "CustomerConfigurationAwsEc2Analysis",
    CustomerConfigurationAzureComputeVirtualMachineAnalysis = "CustomerConfigurationAzureComputeVirtualMachineAnalysis",
    CustomerConfigurationCodeAnalysis = "CustomerConfigurationCodeAnalysis",
    CustomerConfigurationContainerImageRepositoryAnalysis = "CustomerConfigurationContainerImageRepositoryAnalysis",
    CustomerConfigurationEventAnalysis = "CustomerConfigurationEventAnalysis",
    CustomerConfigurationEventAnalysisAad = "CustomerConfigurationEventAnalysisAad",
    CustomerConfigurationEventAnalysisAws = "CustomerConfigurationEventAnalysisAws",
    CustomerConfigurationEventAnalysisAzure = "CustomerConfigurationEventAnalysisAzure",
    CustomerConfigurationEventAnalysisGci = "CustomerConfigurationEventAnalysisGci",
    CustomerConfigurationEventAnalysisGcp = "CustomerConfigurationEventAnalysisGcp",
    CustomerConfigurationGcpComputeInstanceAnalysis = "CustomerConfigurationGcpComputeInstanceAnalysis",
    CustomerConfigurationManagedDatabaseAnalysis = "CustomerConfigurationManagedDatabaseAnalysis",
    CustomerConfigurationObjectStoreAnalysis = "CustomerConfigurationObjectStoreAnalysis",
    CustomerConfigurationOutpost = "CustomerConfigurationOutpost",
    CustomerConfigurationPermissionManagement = "CustomerConfigurationPermissionManagement",
    CustomerConfigurationProvisionedManagedDatabaseAnalysis = "CustomerConfigurationProvisionedManagedDatabaseAnalysis",
    CustomerConfigurationSections = "CustomerConfigurationSections",
    CustomerConfigurationWorkloadAnalysis = "CustomerConfigurationWorkloadAnalysis",
    CustomerConsoleAppSideViewType = "CustomerConsoleAppSideViewType",
    CustomerConsoleAppUrlBuilderGetConfigurationIntegrationsAadTenantsRelativeUrlError = "CustomerConsoleAppUrlBuilderGetConfigurationIntegrationsAadTenantsRelativeUrlError",
    CustomerConsoleAppUrlBuilderGetConfigurationIntegrationsAzureDevOpsOrganizationsRelativeUrlError = "CustomerConsoleAppUrlBuilderGetConfigurationIntegrationsAzureDevOpsOrganizationsRelativeUrlError",
    CustomerConsoleAppUrlBuilderGetConfigurationIntegrationsGitHubOrganizationsRelativeUrlError = "CustomerConsoleAppUrlBuilderGetConfigurationIntegrationsGitHubOrganizationsRelativeUrlError",
    CustomerConsoleAppUrlBuilderGetConfigurationIntegrationsSlackRelativeUrlError = "CustomerConsoleAppUrlBuilderGetConfigurationIntegrationsSlackRelativeUrlError",
    CustomerConsoleAppUrlBuilderGetConfigurationIntegrationsTeamsRelativeUrlError = "CustomerConsoleAppUrlBuilderGetConfigurationIntegrationsTeamsRelativeUrlError",
    CustomerConsoleAppView = "CustomerConsoleAppView",
    CustomerConsoleSignInAuditEvent = "CustomerConsoleSignInAuditEvent",
    CustomerConsoleSignOutAuditEvent = "CustomerConsoleSignOutAuditEvent",
    CustomerLicenseObjectType = "CustomerLicenseObjectType",
    CustomerLogoConfiguration = "CustomerLogoConfiguration",
    CustomerOrchestrationStageState = "CustomerOrchestrationStageState",
    CustomerState = "CustomerState",
    CustomerStateAnalysis = "CustomerStateAnalysis",
    CustomerStateAutomations = "CustomerStateAutomations",
    CustomerStateEvents = "CustomerStateEvents",
    CustomerStateOrchestration = "CustomerStateOrchestration",
    CustomerStateOrchestrationData = "CustomerStateOrchestrationData",
    CustomerStateOrchestrationDataPrevious = "CustomerStateOrchestrationDataPrevious",
    CustomKubernetesAdmissionControllerRiskPolicyConfiguration = "CustomKubernetesAdmissionControllerRiskPolicyConfiguration",
    CustomRiskPolicyConfiguration = "CustomRiskPolicyConfiguration",
    CustomRiskPolicyGroupFilters = "CustomRiskPolicyGroupFilters",
    CustomRiskPolicyTypeMetadata = "CustomRiskPolicyTypeMetadata",
    CvssVersion = "CvssVersion",
    Dag = "Dag",
    DagContainer = "DagContainer",
    DagContainerType = "DagContainerType",
    DagNode = "DagNode",
    DashboardControllerGetPublicEntityIdsRequest = "DashboardControllerGetPublicEntityIdsRequest",
    DashboardControllerGetPublicEntityIdsResponse = "DashboardControllerGetPublicEntityIdsResponse",
    DashboardControllerGetSummaryTopCriticalVulnerabilityModelsResponse = "DashboardControllerGetSummaryTopCriticalVulnerabilityModelsResponse",
    DashboardControllerGetSummaryVulnerabilityWorkloadResourceIdsRequest = "DashboardControllerGetSummaryVulnerabilityWorkloadResourceIdsRequest",
    DashboardControllerGetSummaryVulnerabilityWorkloadResourceIdsResponse = "DashboardControllerGetSummaryVulnerabilityWorkloadResourceIdsResponse",
    DashboardGetSummaryResponse = "DashboardGetSummaryResponse",
    DashboardSummary = "DashboardSummary",
    DashboardSummaryRiskData = "DashboardSummaryRiskData",
    DashboardSummaryRiskTrendsData = "DashboardSummaryRiskTrendsData",
    DashboardSummarySecretExistsRiskEntityTypeData = "DashboardSummarySecretExistsRiskEntityTypeData",
    DashboardSummaryWorkloadAnalysisData = "DashboardSummaryWorkloadAnalysisData",
    DataAnalysisResourceModelDataAnalysisError = "DataAnalysisResourceModelDataAnalysisError",
    DataAnalysisResourceModelDataAnalysisStatus = "DataAnalysisResourceModelDataAnalysisStatus",
    DataAnalysisResourceModelFilters = "DataAnalysisResourceModelFilters",
    DataAnalysisResourceModelFiltersSection = "DataAnalysisResourceModelFiltersSection",
    DataAnalysisResourceModelSummary = "DataAnalysisResourceModelSummary",
    DataAnalysisResourcePriorityScanRequestUpdateAuditEvent = "DataAnalysisResourcePriorityScanRequestUpdateAuditEvent",
    DataAnalysisResourceScan = "DataAnalysisResourceScan",
    DataAnalysisResourceScanDataSegmentData = "DataAnalysisResourceScanDataSegmentData",
    DataAnalysisSensitivity = "DataAnalysisSensitivity",
    DataAnalysisUpdateAuditEvent = "DataAnalysisUpdateAuditEvent",
    DataCategory = "DataCategory",
    DataClassifier = "DataClassifier",
    DataClassifierConfiguration = "DataClassifierConfiguration",
    DataClassifierModel = "DataClassifierModel",
    DataClassifierSample = "DataClassifierSample",
    DataClassifierSampleLocation = "DataClassifierSampleLocation",
    DataControllerGetDataAnalysisResourceScansRequest = "DataControllerGetDataAnalysisResourceScansRequest",
    DataControllerGetDataAnalysisResourceScansResponse = "DataControllerGetDataAnalysisResourceScansResponse",
    DataControllerGetDataClassifierModelsResponse = "DataControllerGetDataClassifierModelsResponse",
    DataControllerUpdateDataAnalysisResourcePriorityScanRequestRequest = "DataControllerUpdateDataAnalysisResourcePriorityScanRequestRequest",
    DatadogConfigurationSection = "DatadogConfigurationSection",
    DatadogConfigurationSectionRumApplication = "DatadogConfigurationSectionRumApplication",
    DatadogDelivery = "DatadogDelivery",
    DatadogDeliveryOrganization = "DatadogDeliveryOrganization",
    DatadogOrganizationAuditEvent = "DatadogOrganizationAuditEvent",
    DatadogOrganizationConfiguration = "DatadogOrganizationConfiguration",
    DatadogOrganizationConfigurationSite = "DatadogOrganizationConfigurationSite",
    DatadogOrganizationCreationAuditEvent = "DatadogOrganizationCreationAuditEvent",
    DatadogOrganizationDeletionAuditEvent = "DatadogOrganizationDeletionAuditEvent",
    DatadogOrganizationState = "DatadogOrganizationState",
    DatadogOrganizationStateIssue = "DatadogOrganizationStateIssue",
    DatadogOrganizationUpdateAuditEvent = "DatadogOrganizationUpdateAuditEvent",
    DataResourceAttribute = "DataResourceAttribute",
    DataResourcePermissionActionPrincipalAttribute = "DataResourcePermissionActionPrincipalAttribute",
    DataSegmentMetadata = "DataSegmentMetadata",
    DataSensitivity = "DataSensitivity",
    DataStoresExporterConfigurationSection = "DataStoresExporterConfigurationSection",
    DateTimeSearchableData = "DateTimeSearchableData",
    DayOfWeek = "DayOfWeek",
    DeletedRisk = "DeletedRisk",
    Delivery = "Delivery",
    DeliveryDestination = "DeliveryDestination",
    DeliveryIntegration = "DeliveryIntegration",
    DeliveryRequest = "DeliveryRequest",
    DeliveryWrapper = "DeliveryWrapper",
    DeploymentConfigurationSection = "DeploymentConfigurationSection",
    DeploymentDataProviderConfigurationSection = "DeploymentDataProviderConfigurationSection",
    DeploymentDataProviderConfigurationSectionData = "DeploymentDataProviderConfigurationSectionData",
    DestinationNetworkScope = "DestinationNetworkScope",
    DestinationNetworkScopeData = "DestinationNetworkScopeData",
    DirectoryIdentityProfileAzure = "DirectoryIdentityProfileAzure",
    DirectoryUserProfileAws = "DirectoryUserProfileAws",
    DisabledIdentityAttribute = "DisabledIdentityAttribute",
    Document = "Document",
    Document360AuthenticationProviderConfigurationSection = "Document360AuthenticationProviderConfigurationSection",
    DocumentModelExporterConfigurationSection = "DocumentModelExporterConfigurationSection",
    DocumentModelExporterConfigurationSectionBucketData = "DocumentModelExporterConfigurationSectionBucketData",
    DocumentSharedFragmentData = "DocumentSharedFragmentData",
    ElasticsearchClientProviderConfigurationSection = "ElasticsearchClientProviderConfigurationSection",
    ElasticsearchDomainConfiguration = "ElasticsearchDomainConfiguration",
    ElasticsearchFilterItemPage = "ElasticsearchFilterItemPage",
    ElasticsearchIndexAggregationCursor = "ElasticsearchIndexAggregationCursor",
    ElasticsearchIndexSearchCursor = "ElasticsearchIndexSearchCursor",
    ElasticsearchItemPage = "ElasticsearchItemPage",
    EncryptedData = "EncryptedData",
    EndpointConnectorAuditEvent = "EndpointConnectorAuditEvent",
    EndpointConnectorConfiguration = "EndpointConnectorConfiguration",
    EndpointConnectorCreationAuditEvent = "EndpointConnectorCreationAuditEvent",
    EndpointConnectorDeletionAuditEvent = "EndpointConnectorDeletionAuditEvent",
    EngineeringConfigurationSection = "EngineeringConfigurationSection",
    EngineeringConfigurationSectionAws = "EngineeringConfigurationSectionAws",
    Entity = "Entity",
    EntityAccessDirection = "EntityAccessDirection",
    EntityAccessEvaluatorEvaluateResult = "EntityAccessEvaluatorEvaluateResult",
    EntityAccessReportDefinitionConfiguration = "EntityAccessReportDefinitionConfiguration",
    EntityAccessScope = "EntityAccessScope",
    EntityAccessVariant = "EntityAccessVariant",
    EntityAnalyzingReason = "EntityAnalyzingReason",
    EntityAttribute = "EntityAttribute",
    EntityAttributeMatchCondition = "EntityAttributeMatchCondition",
    EntityAwsAccessReportDefinitionConfiguration = "EntityAwsAccessReportDefinitionConfiguration",
    EntityAzureAccessReportDefinitionConfiguration = "EntityAzureAccessReportDefinitionConfiguration",
    EntityConfiguration = "EntityConfiguration",
    EntityControllerGenerateAwsResourceNonexcessivePolicyRequest = "EntityControllerGenerateAwsResourceNonexcessivePolicyRequest",
    EntityControllerGenerateAwsResourceNonexcessivePolicyResponse = "EntityControllerGenerateAwsResourceNonexcessivePolicyResponse",
    EntityControllerGenerateAwsResourceNonexcessivePolicyResult = "EntityControllerGenerateAwsResourceNonexcessivePolicyResult",
    EntityControllerGenerateAzureIdentityNonexcessiveRoleDefinitionError = "EntityControllerGenerateAzureIdentityNonexcessiveRoleDefinitionError",
    EntityControllerGenerateAzureIdentityNonexcessiveRoleDefinitionRequest = "EntityControllerGenerateAzureIdentityNonexcessiveRoleDefinitionRequest",
    EntityControllerGenerateAzureIdentityNonexcessiveRoleDefinitionResponse = "EntityControllerGenerateAzureIdentityNonexcessiveRoleDefinitionResponse",
    EntityControllerGenerateGcpIdentityNonexcessiveRoleError = "EntityControllerGenerateGcpIdentityNonexcessiveRoleError",
    EntityControllerGenerateGcpIdentityNonexcessiveRoleRequest = "EntityControllerGenerateGcpIdentityNonexcessiveRoleRequest",
    EntityControllerGenerateGcpIdentityNonexcessiveRoleResponse = "EntityControllerGenerateGcpIdentityNonexcessiveRoleResponse",
    EntityControllerGetAccessDataRequest = "EntityControllerGetAccessDataRequest",
    EntityControllerGetAccessDataRequestFilters = "EntityControllerGetAccessDataRequestFilters",
    EntityControllerGetAccessGraphRequest = "EntityControllerGetAccessGraphRequest",
    EntityControllerGetAccessGraphResponse = "EntityControllerGetAccessGraphResponse",
    EntityControllerGetAccessListPermissionActionsRequest = "EntityControllerGetAccessListPermissionActionsRequest",
    EntityControllerGetAccessListPermissionActionsRequestSelection = "EntityControllerGetAccessListPermissionActionsRequestSelection",
    EntityControllerGetAccessListPermissionActionsResponse = "EntityControllerGetAccessListPermissionActionsResponse",
    EntityControllerGetActionsRequest = "EntityControllerGetActionsRequest",
    EntityControllerGetActionsResponse = "EntityControllerGetActionsResponse",
    EntityControllerGetAwsAccessDataRequestFilters = "EntityControllerGetAwsAccessDataRequestFilters",
    EntityControllerGetAwsAccessGraphRequest = "EntityControllerGetAwsAccessGraphRequest",
    EntityControllerGetAwsAccessListPermissionActionConditionJsonsRequest = "EntityControllerGetAwsAccessListPermissionActionConditionJsonsRequest",
    EntityControllerGetAwsAccessListPermissionActionConditionJsonsResponse = "EntityControllerGetAwsAccessListPermissionActionConditionJsonsResponse",
    EntityControllerGetAwsAccessListPermissionActionsRequest = "EntityControllerGetAwsAccessListPermissionActionsRequest",
    EntityControllerGetAwsDistributionIdsRequest = "EntityControllerGetAwsDistributionIdsRequest",
    EntityControllerGetAwsDistributionIdsResponse = "EntityControllerGetAwsDistributionIdsResponse",
    EntityControllerGetAwsEntityExcessivePermissionCustomEvaluationDataRequest = "EntityControllerGetAwsEntityExcessivePermissionCustomEvaluationDataRequest",
    EntityControllerGetAwsKmsKeyGrantsRequest = "EntityControllerGetAwsKmsKeyGrantsRequest",
    EntityControllerGetAwsKmsKeyGrantsResponse = "EntityControllerGetAwsKmsKeyGrantsResponse",
    EntityControllerGetAwsLoggingBucketDistributionIdsAsyncRequest = "EntityControllerGetAwsLoggingBucketDistributionIdsAsyncRequest",
    EntityControllerGetAwsLoggingBucketDistributionIdsAsyncResponse = "EntityControllerGetAwsLoggingBucketDistributionIdsAsyncResponse",
    EntityControllerGetAwsNetworkGraphRequest = "EntityControllerGetAwsNetworkGraphRequest",
    EntityControllerGetAwsNetworkGraphRequestFilters = "EntityControllerGetAwsNetworkGraphRequestFilters",
    EntityControllerGetAwsTimestreamDatabaseTableIdsRequest = "EntityControllerGetAwsTimestreamDatabaseTableIdsRequest",
    EntityControllerGetAwsTimestreamDatabaseTableIdsResponse = "EntityControllerGetAwsTimestreamDatabaseTableIdsResponse",
    EntityControllerGetAzureAccessDataRequestFilters = "EntityControllerGetAzureAccessDataRequestFilters",
    EntityControllerGetAzureAccessGraphRequest = "EntityControllerGetAzureAccessGraphRequest",
    EntityControllerGetAzureAccessListPermissionActionsRequest = "EntityControllerGetAzureAccessListPermissionActionsRequest",
    EntityControllerGetAzureEntityExcessivePermissionCustomEvaluationDataRequest = "EntityControllerGetAzureEntityExcessivePermissionCustomEvaluationDataRequest",
    EntityControllerGetAzureKeyVaultVaultObjectIdsRequest = "EntityControllerGetAzureKeyVaultVaultObjectIdsRequest",
    EntityControllerGetAzureKeyVaultVaultObjectIdsResponse = "EntityControllerGetAzureKeyVaultVaultObjectIdsResponse",
    EntityControllerGetAzureNetworkGraphRequest = "EntityControllerGetAzureNetworkGraphRequest",
    EntityControllerGetAzureNetworkGraphRequestFilters = "EntityControllerGetAzureNetworkGraphRequestFilters",
    EntityControllerGetAzureResourceGroupResourceDatasRequest = "EntityControllerGetAzureResourceGroupResourceDatasRequest",
    EntityControllerGetAzureResourceGroupResourceDatasResponse = "EntityControllerGetAzureResourceGroupResourceDatasResponse",
    EntityControllerGetAzureStorageAccountChildResourceDatasRequest = "EntityControllerGetAzureStorageAccountChildResourceDatasRequest",
    EntityControllerGetAzureStorageAccountChildResourceDatasResponse = "EntityControllerGetAzureStorageAccountChildResourceDatasResponse",
    EntityControllerGetClusterKubernetesResourceModelsRequest = "EntityControllerGetClusterKubernetesResourceModelsRequest",
    EntityControllerGetClusterKubernetesResourceModelsResponse = "EntityControllerGetClusterKubernetesResourceModelsResponse",
    EntityControllerGetEntityEntitiesViewTypeNamesRequest = "EntityControllerGetEntityEntitiesViewTypeNamesRequest",
    EntityControllerGetEntityEntitiesViewTypeNamesResponse = "EntityControllerGetEntityEntitiesViewTypeNamesResponse",
    EntityControllerGetEntityExcessivePermissionCustomEvaluationDataRequest = "EntityControllerGetEntityExcessivePermissionCustomEvaluationDataRequest",
    EntityControllerGetEntityExcessivePermissionCustomEvaluationDataResponse = "EntityControllerGetEntityExcessivePermissionCustomEvaluationDataResponse",
    EntityControllerGetEntityExcessivePermissionCustomEvaluationDataResponseDateInfo = "EntityControllerGetEntityExcessivePermissionCustomEvaluationDataResponseDateInfo",
    EntityControllerGetEntityExcessivePermissionCustomEvaluationDataResponseDateType = "EntityControllerGetEntityExcessivePermissionCustomEvaluationDataResponseDateType",
    EntityControllerGetEntityModelFilterItemPageRequest = "EntityControllerGetEntityModelFilterItemPageRequest",
    EntityControllerGetEntityModelFilterItemPageResponse = "EntityControllerGetEntityModelFilterItemPageResponse",
    EntityControllerGetEntityModelFilterItemPageResponseBase = "EntityControllerGetEntityModelFilterItemPageResponseBase",
    EntityControllerGetEntityModelFiltersRequest = "EntityControllerGetEntityModelFiltersRequest",
    EntityControllerGetEntityModelFiltersResponse = "EntityControllerGetEntityModelFiltersResponse",
    EntityControllerGetEntityModelPageAadDefaultDirectoryEntityRequest = "EntityControllerGetEntityModelPageAadDefaultDirectoryEntityRequest",
    EntityControllerGetEntityModelPageAadDefaultDirectoryEntityRequestFilters = "EntityControllerGetEntityModelPageAadDefaultDirectoryEntityRequestFilters",
    EntityControllerGetEntityModelPageAadDirectoryApplicationCertificateRequest = "EntityControllerGetEntityModelPageAadDirectoryApplicationCertificateRequest",
    EntityControllerGetEntityModelPageAadDirectoryApplicationCertificateRequestFilters = "EntityControllerGetEntityModelPageAadDirectoryApplicationCertificateRequestFilters",
    EntityControllerGetEntityModelPageAadDirectoryApplicationObjectRequest = "EntityControllerGetEntityModelPageAadDirectoryApplicationObjectRequest",
    EntityControllerGetEntityModelPageAadDirectoryApplicationObjectRequestFilters = "EntityControllerGetEntityModelPageAadDirectoryApplicationObjectRequestFilters",
    EntityControllerGetEntityModelPageAadDirectoryApplicationRequest = "EntityControllerGetEntityModelPageAadDirectoryApplicationRequest",
    EntityControllerGetEntityModelPageAadDirectoryApplicationRequestFilters = "EntityControllerGetEntityModelPageAadDirectoryApplicationRequestFilters",
    EntityControllerGetEntityModelPageAadDirectoryApplicationSecretRequest = "EntityControllerGetEntityModelPageAadDirectoryApplicationSecretRequest",
    EntityControllerGetEntityModelPageAadDirectoryApplicationSecretRequestFilters = "EntityControllerGetEntityModelPageAadDirectoryApplicationSecretRequestFilters",
    EntityControllerGetEntityModelPageAadDirectoryDirectoryRoleDefinitionRequest = "EntityControllerGetEntityModelPageAadDirectoryDirectoryRoleDefinitionRequest",
    EntityControllerGetEntityModelPageAadDirectoryDirectoryRoleDefinitionRequestFilters = "EntityControllerGetEntityModelPageAadDirectoryDirectoryRoleDefinitionRequestFilters",
    EntityControllerGetEntityModelPageAadDirectoryDirectoryRoleManagementPolicyAssignmentRequest = "EntityControllerGetEntityModelPageAadDirectoryDirectoryRoleManagementPolicyAssignmentRequest",
    EntityControllerGetEntityModelPageAadDirectoryDirectoryRoleManagementPolicyAssignmentRequestFilters = "EntityControllerGetEntityModelPageAadDirectoryDirectoryRoleManagementPolicyAssignmentRequestFilters",
    EntityControllerGetEntityModelPageAadDirectoryEntityRequest = "EntityControllerGetEntityModelPageAadDirectoryEntityRequest",
    EntityControllerGetEntityModelPageAadDirectoryEntityRequestFilters = "EntityControllerGetEntityModelPageAadDirectoryEntityRequestFilters",
    EntityControllerGetEntityModelPageAadDirectoryGroupRequest = "EntityControllerGetEntityModelPageAadDirectoryGroupRequest",
    EntityControllerGetEntityModelPageAadDirectoryGroupRequestFilters = "EntityControllerGetEntityModelPageAadDirectoryGroupRequestFilters",
    EntityControllerGetEntityModelPageAadDirectoryIdentityRequest = "EntityControllerGetEntityModelPageAadDirectoryIdentityRequest",
    EntityControllerGetEntityModelPageAadDirectoryIdentityRequestFilters = "EntityControllerGetEntityModelPageAadDirectoryIdentityRequestFilters",
    EntityControllerGetEntityModelPageAadDirectoryPrincipalRequest = "EntityControllerGetEntityModelPageAadDirectoryPrincipalRequest",
    EntityControllerGetEntityModelPageAadDirectoryPrincipalRequestFilters = "EntityControllerGetEntityModelPageAadDirectoryPrincipalRequestFilters",
    EntityControllerGetEntityModelPageAadDirectoryServicePrincipalRequest = "EntityControllerGetEntityModelPageAadDirectoryServicePrincipalRequest",
    EntityControllerGetEntityModelPageAadDirectoryServicePrincipalRequestFilters = "EntityControllerGetEntityModelPageAadDirectoryServicePrincipalRequestFilters",
    EntityControllerGetEntityModelPageAadDirectoryUserRequest = "EntityControllerGetEntityModelPageAadDirectoryUserRequest",
    EntityControllerGetEntityModelPageAadDirectoryUserRequestFilters = "EntityControllerGetEntityModelPageAadDirectoryUserRequestFilters",
    EntityControllerGetEntityModelPageAadTenantEntityRequest = "EntityControllerGetEntityModelPageAadTenantEntityRequest",
    EntityControllerGetEntityModelPageAadTenantEntityRequestFilters = "EntityControllerGetEntityModelPageAadTenantEntityRequestFilters",
    EntityControllerGetEntityModelPageAwsAcmCertificateRequest = "EntityControllerGetEntityModelPageAwsAcmCertificateRequest",
    EntityControllerGetEntityModelPageAwsAcmCertificateRequestFilters = "EntityControllerGetEntityModelPageAwsAcmCertificateRequestFilters",
    EntityControllerGetEntityModelPageAwsAossCollectionRequest = "EntityControllerGetEntityModelPageAwsAossCollectionRequest",
    EntityControllerGetEntityModelPageAwsAossCollectionRequestFilters = "EntityControllerGetEntityModelPageAwsAossCollectionRequestFilters",
    EntityControllerGetEntityModelPageAwsApiGatewayApiRequest = "EntityControllerGetEntityModelPageAwsApiGatewayApiRequest",
    EntityControllerGetEntityModelPageAwsApiGatewayApiRequestFilters = "EntityControllerGetEntityModelPageAwsApiGatewayApiRequestFilters",
    EntityControllerGetEntityModelPageAwsAthenaWorkgroupRequest = "EntityControllerGetEntityModelPageAwsAthenaWorkgroupRequest",
    EntityControllerGetEntityModelPageAwsAthenaWorkgroupRequestFilters = "EntityControllerGetEntityModelPageAwsAthenaWorkgroupRequestFilters",
    EntityControllerGetEntityModelPageAwsAutoScalingAutoScalingGroupRequest = "EntityControllerGetEntityModelPageAwsAutoScalingAutoScalingGroupRequest",
    EntityControllerGetEntityModelPageAwsAutoScalingAutoScalingGroupRequestFilters = "EntityControllerGetEntityModelPageAwsAutoScalingAutoScalingGroupRequestFilters",
    EntityControllerGetEntityModelPageAwsAutoScalingLaunchConfigurationRequest = "EntityControllerGetEntityModelPageAwsAutoScalingLaunchConfigurationRequest",
    EntityControllerGetEntityModelPageAwsAutoScalingLaunchConfigurationRequestFilters = "EntityControllerGetEntityModelPageAwsAutoScalingLaunchConfigurationRequestFilters",
    EntityControllerGetEntityModelPageAwsBedrockAgentRequest = "EntityControllerGetEntityModelPageAwsBedrockAgentRequest",
    EntityControllerGetEntityModelPageAwsBedrockAgentRequestFilters = "EntityControllerGetEntityModelPageAwsBedrockAgentRequestFilters",
    EntityControllerGetEntityModelPageAwsBedrockCustomModelRequest = "EntityControllerGetEntityModelPageAwsBedrockCustomModelRequest",
    EntityControllerGetEntityModelPageAwsBedrockCustomModelRequestFilters = "EntityControllerGetEntityModelPageAwsBedrockCustomModelRequestFilters",
    EntityControllerGetEntityModelPageAwsCloudFormationStackRequest = "EntityControllerGetEntityModelPageAwsCloudFormationStackRequest",
    EntityControllerGetEntityModelPageAwsCloudFormationStackRequestFilters = "EntityControllerGetEntityModelPageAwsCloudFormationStackRequestFilters",
    EntityControllerGetEntityModelPageAwsCloudFrontDistributionRequest = "EntityControllerGetEntityModelPageAwsCloudFrontDistributionRequest",
    EntityControllerGetEntityModelPageAwsCloudFrontDistributionRequestFilters = "EntityControllerGetEntityModelPageAwsCloudFrontDistributionRequestFilters",
    EntityControllerGetEntityModelPageAwsCloudTrailTrailRequest = "EntityControllerGetEntityModelPageAwsCloudTrailTrailRequest",
    EntityControllerGetEntityModelPageAwsCloudTrailTrailRequestFilters = "EntityControllerGetEntityModelPageAwsCloudTrailTrailRequestFilters",
    EntityControllerGetEntityModelPageAwsContainerImageRequest = "EntityControllerGetEntityModelPageAwsContainerImageRequest",
    EntityControllerGetEntityModelPageAwsContainerImageRequestFilters = "EntityControllerGetEntityModelPageAwsContainerImageRequestFilters",
    EntityControllerGetEntityModelPageAwsDefaultKubernetesNamespaceResourceRequest = "EntityControllerGetEntityModelPageAwsDefaultKubernetesNamespaceResourceRequest",
    EntityControllerGetEntityModelPageAwsDefaultKubernetesNamespaceResourceRequestFilters = "EntityControllerGetEntityModelPageAwsDefaultKubernetesNamespaceResourceRequestFilters",
    EntityControllerGetEntityModelPageAwsDefaultKubernetesResourceRequest = "EntityControllerGetEntityModelPageAwsDefaultKubernetesResourceRequest",
    EntityControllerGetEntityModelPageAwsDefaultKubernetesResourceRequestFilters = "EntityControllerGetEntityModelPageAwsDefaultKubernetesResourceRequestFilters",
    EntityControllerGetEntityModelPageAwsDefaultResourceRequest = "EntityControllerGetEntityModelPageAwsDefaultResourceRequest",
    EntityControllerGetEntityModelPageAwsDefaultResourceRequestFilters = "EntityControllerGetEntityModelPageAwsDefaultResourceRequestFilters",
    EntityControllerGetEntityModelPageAwsDocDbClusterRequest = "EntityControllerGetEntityModelPageAwsDocDbClusterRequest",
    EntityControllerGetEntityModelPageAwsDocDbClusterRequestFilters = "EntityControllerGetEntityModelPageAwsDocDbClusterRequestFilters",
    EntityControllerGetEntityModelPageAwsDocDbClusterSnapshotRequest = "EntityControllerGetEntityModelPageAwsDocDbClusterSnapshotRequest",
    EntityControllerGetEntityModelPageAwsDocDbClusterSnapshotRequestFilters = "EntityControllerGetEntityModelPageAwsDocDbClusterSnapshotRequestFilters",
    EntityControllerGetEntityModelPageAwsDynamoDbTableRequest = "EntityControllerGetEntityModelPageAwsDynamoDbTableRequest",
    EntityControllerGetEntityModelPageAwsDynamoDbTableRequestFilters = "EntityControllerGetEntityModelPageAwsDynamoDbTableRequestFilters",
    EntityControllerGetEntityModelPageAwsEc2ElasticIpRequest = "EntityControllerGetEntityModelPageAwsEc2ElasticIpRequest",
    EntityControllerGetEntityModelPageAwsEc2ElasticIpRequestFilters = "EntityControllerGetEntityModelPageAwsEc2ElasticIpRequestFilters",
    EntityControllerGetEntityModelPageAwsEc2ImageRequest = "EntityControllerGetEntityModelPageAwsEc2ImageRequest",
    EntityControllerGetEntityModelPageAwsEc2ImageRequestFilters = "EntityControllerGetEntityModelPageAwsEc2ImageRequestFilters",
    EntityControllerGetEntityModelPageAwsEc2InstanceRequest = "EntityControllerGetEntityModelPageAwsEc2InstanceRequest",
    EntityControllerGetEntityModelPageAwsEc2InstanceRequestFilters = "EntityControllerGetEntityModelPageAwsEc2InstanceRequestFilters",
    EntityControllerGetEntityModelPageAwsEc2InternetGatewayRequest = "EntityControllerGetEntityModelPageAwsEc2InternetGatewayRequest",
    EntityControllerGetEntityModelPageAwsEc2InternetGatewayRequestFilters = "EntityControllerGetEntityModelPageAwsEc2InternetGatewayRequestFilters",
    EntityControllerGetEntityModelPageAwsEc2LaunchTemplateRequest = "EntityControllerGetEntityModelPageAwsEc2LaunchTemplateRequest",
    EntityControllerGetEntityModelPageAwsEc2LaunchTemplateRequestFilters = "EntityControllerGetEntityModelPageAwsEc2LaunchTemplateRequestFilters",
    EntityControllerGetEntityModelPageAwsEc2NatGatewayRequest = "EntityControllerGetEntityModelPageAwsEc2NatGatewayRequest",
    EntityControllerGetEntityModelPageAwsEc2NatGatewayRequestFilters = "EntityControllerGetEntityModelPageAwsEc2NatGatewayRequestFilters",
    EntityControllerGetEntityModelPageAwsEc2NetworkAclRequest = "EntityControllerGetEntityModelPageAwsEc2NetworkAclRequest",
    EntityControllerGetEntityModelPageAwsEc2NetworkAclRequestFilters = "EntityControllerGetEntityModelPageAwsEc2NetworkAclRequestFilters",
    EntityControllerGetEntityModelPageAwsEc2NetworkInterfaceRequest = "EntityControllerGetEntityModelPageAwsEc2NetworkInterfaceRequest",
    EntityControllerGetEntityModelPageAwsEc2NetworkInterfaceRequestFilters = "EntityControllerGetEntityModelPageAwsEc2NetworkInterfaceRequestFilters",
    EntityControllerGetEntityModelPageAwsEc2ResourceRequest = "EntityControllerGetEntityModelPageAwsEc2ResourceRequest",
    EntityControllerGetEntityModelPageAwsEc2ResourceRequestFilters = "EntityControllerGetEntityModelPageAwsEc2ResourceRequestFilters",
    EntityControllerGetEntityModelPageAwsEc2RouteTableRequest = "EntityControllerGetEntityModelPageAwsEc2RouteTableRequest",
    EntityControllerGetEntityModelPageAwsEc2RouteTableRequestFilters = "EntityControllerGetEntityModelPageAwsEc2RouteTableRequestFilters",
    EntityControllerGetEntityModelPageAwsEc2SecurityGroupRequest = "EntityControllerGetEntityModelPageAwsEc2SecurityGroupRequest",
    EntityControllerGetEntityModelPageAwsEc2SecurityGroupRequestFilters = "EntityControllerGetEntityModelPageAwsEc2SecurityGroupRequestFilters",
    EntityControllerGetEntityModelPageAwsEc2SnapshotRequest = "EntityControllerGetEntityModelPageAwsEc2SnapshotRequest",
    EntityControllerGetEntityModelPageAwsEc2SnapshotRequestFilters = "EntityControllerGetEntityModelPageAwsEc2SnapshotRequestFilters",
    EntityControllerGetEntityModelPageAwsEc2SubnetRequest = "EntityControllerGetEntityModelPageAwsEc2SubnetRequest",
    EntityControllerGetEntityModelPageAwsEc2SubnetRequestFilters = "EntityControllerGetEntityModelPageAwsEc2SubnetRequestFilters",
    EntityControllerGetEntityModelPageAwsEc2VolumeRequest = "EntityControllerGetEntityModelPageAwsEc2VolumeRequest",
    EntityControllerGetEntityModelPageAwsEc2VolumeRequestFilters = "EntityControllerGetEntityModelPageAwsEc2VolumeRequestFilters",
    EntityControllerGetEntityModelPageAwsEc2VpcBlockPublicAccessPolicyRequest = "EntityControllerGetEntityModelPageAwsEc2VpcBlockPublicAccessPolicyRequest",
    EntityControllerGetEntityModelPageAwsEc2VpcBlockPublicAccessPolicyRequestFilters = "EntityControllerGetEntityModelPageAwsEc2VpcBlockPublicAccessPolicyRequestFilters",
    EntityControllerGetEntityModelPageAwsEc2VpcEndpointRequest = "EntityControllerGetEntityModelPageAwsEc2VpcEndpointRequest",
    EntityControllerGetEntityModelPageAwsEc2VpcEndpointRequestFilters = "EntityControllerGetEntityModelPageAwsEc2VpcEndpointRequestFilters",
    EntityControllerGetEntityModelPageAwsEc2VpcPeeringConnectionRequest = "EntityControllerGetEntityModelPageAwsEc2VpcPeeringConnectionRequest",
    EntityControllerGetEntityModelPageAwsEc2VpcPeeringConnectionRequestFilters = "EntityControllerGetEntityModelPageAwsEc2VpcPeeringConnectionRequestFilters",
    EntityControllerGetEntityModelPageAwsEc2VpcRequest = "EntityControllerGetEntityModelPageAwsEc2VpcRequest",
    EntityControllerGetEntityModelPageAwsEc2VpcRequestFilters = "EntityControllerGetEntityModelPageAwsEc2VpcRequestFilters",
    EntityControllerGetEntityModelPageAwsEcrRepositoryRequest = "EntityControllerGetEntityModelPageAwsEcrRepositoryRequest",
    EntityControllerGetEntityModelPageAwsEcrRepositoryRequestFilters = "EntityControllerGetEntityModelPageAwsEcrRepositoryRequestFilters",
    EntityControllerGetEntityModelPageAwsEcsServiceRequest = "EntityControllerGetEntityModelPageAwsEcsServiceRequest",
    EntityControllerGetEntityModelPageAwsEcsServiceRequestFilters = "EntityControllerGetEntityModelPageAwsEcsServiceRequestFilters",
    EntityControllerGetEntityModelPageAwsEcsTaskDefinitionRequest = "EntityControllerGetEntityModelPageAwsEcsTaskDefinitionRequest",
    EntityControllerGetEntityModelPageAwsEcsTaskDefinitionRequestFilters = "EntityControllerGetEntityModelPageAwsEcsTaskDefinitionRequestFilters",
    EntityControllerGetEntityModelPageAwsEksClusterNodeGroupRequest = "EntityControllerGetEntityModelPageAwsEksClusterNodeGroupRequest",
    EntityControllerGetEntityModelPageAwsEksClusterNodeGroupRequestFilters = "EntityControllerGetEntityModelPageAwsEksClusterNodeGroupRequestFilters",
    EntityControllerGetEntityModelPageAwsEksClusterRequest = "EntityControllerGetEntityModelPageAwsEksClusterRequest",
    EntityControllerGetEntityModelPageAwsEksClusterRequestFilters = "EntityControllerGetEntityModelPageAwsEksClusterRequestFilters",
    EntityControllerGetEntityModelPageAwsElastiCacheCacheRequest = "EntityControllerGetEntityModelPageAwsElastiCacheCacheRequest",
    EntityControllerGetEntityModelPageAwsElastiCacheCacheRequestFilters = "EntityControllerGetEntityModelPageAwsElastiCacheCacheRequestFilters",
    EntityControllerGetEntityModelPageAwsElasticBeanstalkEnvironmentRequest = "EntityControllerGetEntityModelPageAwsElasticBeanstalkEnvironmentRequest",
    EntityControllerGetEntityModelPageAwsElasticBeanstalkEnvironmentRequestFilters = "EntityControllerGetEntityModelPageAwsElasticBeanstalkEnvironmentRequestFilters",
    EntityControllerGetEntityModelPageAwsElasticFileSystemFileSystemRequest = "EntityControllerGetEntityModelPageAwsElasticFileSystemFileSystemRequest",
    EntityControllerGetEntityModelPageAwsElasticFileSystemFileSystemRequestFilters = "EntityControllerGetEntityModelPageAwsElasticFileSystemFileSystemRequestFilters",
    EntityControllerGetEntityModelPageAwsElbLoadBalancerRequest = "EntityControllerGetEntityModelPageAwsElbLoadBalancerRequest",
    EntityControllerGetEntityModelPageAwsElbLoadBalancerRequestFilters = "EntityControllerGetEntityModelPageAwsElbLoadBalancerRequestFilters",
    EntityControllerGetEntityModelPageAwsEmrClusterRequest = "EntityControllerGetEntityModelPageAwsEmrClusterRequest",
    EntityControllerGetEntityModelPageAwsEmrClusterRequestFilters = "EntityControllerGetEntityModelPageAwsEmrClusterRequestFilters",
    EntityControllerGetEntityModelPageAwsEsDomainRequest = "EntityControllerGetEntityModelPageAwsEsDomainRequest",
    EntityControllerGetEntityModelPageAwsEsDomainRequestFilters = "EntityControllerGetEntityModelPageAwsEsDomainRequestFilters",
    EntityControllerGetEntityModelPageAwsFirehoseDeliveryStreamRequest = "EntityControllerGetEntityModelPageAwsFirehoseDeliveryStreamRequest",
    EntityControllerGetEntityModelPageAwsFirehoseDeliveryStreamRequestFilters = "EntityControllerGetEntityModelPageAwsFirehoseDeliveryStreamRequestFilters",
    EntityControllerGetEntityModelPageAwsIamGroupRequest = "EntityControllerGetEntityModelPageAwsIamGroupRequest",
    EntityControllerGetEntityModelPageAwsIamGroupRequestFilters = "EntityControllerGetEntityModelPageAwsIamGroupRequestFilters",
    EntityControllerGetEntityModelPageAwsIamIdentityRequest = "EntityControllerGetEntityModelPageAwsIamIdentityRequest",
    EntityControllerGetEntityModelPageAwsIamIdentityRequestFilters = "EntityControllerGetEntityModelPageAwsIamIdentityRequestFilters",
    EntityControllerGetEntityModelPageAwsIamPrincipalPolicyRequest = "EntityControllerGetEntityModelPageAwsIamPrincipalPolicyRequest",
    EntityControllerGetEntityModelPageAwsIamPrincipalPolicyRequestFilters = "EntityControllerGetEntityModelPageAwsIamPrincipalPolicyRequestFilters",
    EntityControllerGetEntityModelPageAwsIamPrincipalRequest = "EntityControllerGetEntityModelPageAwsIamPrincipalRequest",
    EntityControllerGetEntityModelPageAwsIamPrincipalRequestFilters = "EntityControllerGetEntityModelPageAwsIamPrincipalRequestFilters",
    EntityControllerGetEntityModelPageAwsIamRoleRequest = "EntityControllerGetEntityModelPageAwsIamRoleRequest",
    EntityControllerGetEntityModelPageAwsIamRoleRequestFilters = "EntityControllerGetEntityModelPageAwsIamRoleRequestFilters",
    EntityControllerGetEntityModelPageAwsIamRootUserRequest = "EntityControllerGetEntityModelPageAwsIamRootUserRequest",
    EntityControllerGetEntityModelPageAwsIamRootUserRequestFilters = "EntityControllerGetEntityModelPageAwsIamRootUserRequestFilters",
    EntityControllerGetEntityModelPageAwsIamServerCertificateRequest = "EntityControllerGetEntityModelPageAwsIamServerCertificateRequest",
    EntityControllerGetEntityModelPageAwsIamServerCertificateRequestFilters = "EntityControllerGetEntityModelPageAwsIamServerCertificateRequestFilters",
    EntityControllerGetEntityModelPageAwsIamUserRequest = "EntityControllerGetEntityModelPageAwsIamUserRequest",
    EntityControllerGetEntityModelPageAwsIamUserRequestFilters = "EntityControllerGetEntityModelPageAwsIamUserRequestFilters",
    EntityControllerGetEntityModelPageAwsKinesisStreamRequest = "EntityControllerGetEntityModelPageAwsKinesisStreamRequest",
    EntityControllerGetEntityModelPageAwsKinesisStreamRequestFilters = "EntityControllerGetEntityModelPageAwsKinesisStreamRequestFilters",
    EntityControllerGetEntityModelPageAwsKmsKeyRequest = "EntityControllerGetEntityModelPageAwsKmsKeyRequest",
    EntityControllerGetEntityModelPageAwsKmsKeyRequestFilters = "EntityControllerGetEntityModelPageAwsKmsKeyRequestFilters",
    EntityControllerGetEntityModelPageAwsKubernetesClusterRoleBindingRequest = "EntityControllerGetEntityModelPageAwsKubernetesClusterRoleBindingRequest",
    EntityControllerGetEntityModelPageAwsKubernetesClusterRoleBindingRequestFilters = "EntityControllerGetEntityModelPageAwsKubernetesClusterRoleBindingRequestFilters",
    EntityControllerGetEntityModelPageAwsKubernetesClusterRoleRequest = "EntityControllerGetEntityModelPageAwsKubernetesClusterRoleRequest",
    EntityControllerGetEntityModelPageAwsKubernetesClusterRoleRequestFilters = "EntityControllerGetEntityModelPageAwsKubernetesClusterRoleRequestFilters",
    EntityControllerGetEntityModelPageAwsKubernetesGroupRequest = "EntityControllerGetEntityModelPageAwsKubernetesGroupRequest",
    EntityControllerGetEntityModelPageAwsKubernetesGroupRequestFilters = "EntityControllerGetEntityModelPageAwsKubernetesGroupRequestFilters",
    EntityControllerGetEntityModelPageAwsKubernetesIngressRequest = "EntityControllerGetEntityModelPageAwsKubernetesIngressRequest",
    EntityControllerGetEntityModelPageAwsKubernetesIngressRequestFilters = "EntityControllerGetEntityModelPageAwsKubernetesIngressRequestFilters",
    EntityControllerGetEntityModelPageAwsKubernetesNamespaceRequest = "EntityControllerGetEntityModelPageAwsKubernetesNamespaceRequest",
    EntityControllerGetEntityModelPageAwsKubernetesNamespaceRequestFilters = "EntityControllerGetEntityModelPageAwsKubernetesNamespaceRequestFilters",
    EntityControllerGetEntityModelPageAwsKubernetesNamespaceResourceRequest = "EntityControllerGetEntityModelPageAwsKubernetesNamespaceResourceRequest",
    EntityControllerGetEntityModelPageAwsKubernetesNamespaceResourceRequestFilters = "EntityControllerGetEntityModelPageAwsKubernetesNamespaceResourceRequestFilters",
    EntityControllerGetEntityModelPageAwsKubernetesNamespaceRoleBindingRequest = "EntityControllerGetEntityModelPageAwsKubernetesNamespaceRoleBindingRequest",
    EntityControllerGetEntityModelPageAwsKubernetesNamespaceRoleBindingRequestFilters = "EntityControllerGetEntityModelPageAwsKubernetesNamespaceRoleBindingRequestFilters",
    EntityControllerGetEntityModelPageAwsKubernetesNamespaceRoleRequest = "EntityControllerGetEntityModelPageAwsKubernetesNamespaceRoleRequest",
    EntityControllerGetEntityModelPageAwsKubernetesNamespaceRoleRequestFilters = "EntityControllerGetEntityModelPageAwsKubernetesNamespaceRoleRequestFilters",
    EntityControllerGetEntityModelPageAwsKubernetesResourceRequest = "EntityControllerGetEntityModelPageAwsKubernetesResourceRequest",
    EntityControllerGetEntityModelPageAwsKubernetesResourceRequestFilters = "EntityControllerGetEntityModelPageAwsKubernetesResourceRequestFilters",
    EntityControllerGetEntityModelPageAwsKubernetesServiceAccountRequest = "EntityControllerGetEntityModelPageAwsKubernetesServiceAccountRequest",
    EntityControllerGetEntityModelPageAwsKubernetesServiceAccountRequestFilters = "EntityControllerGetEntityModelPageAwsKubernetesServiceAccountRequestFilters",
    EntityControllerGetEntityModelPageAwsKubernetesServiceRequest = "EntityControllerGetEntityModelPageAwsKubernetesServiceRequest",
    EntityControllerGetEntityModelPageAwsKubernetesServiceRequestFilters = "EntityControllerGetEntityModelPageAwsKubernetesServiceRequestFilters",
    EntityControllerGetEntityModelPageAwsKubernetesUserRequest = "EntityControllerGetEntityModelPageAwsKubernetesUserRequest",
    EntityControllerGetEntityModelPageAwsKubernetesUserRequestFilters = "EntityControllerGetEntityModelPageAwsKubernetesUserRequestFilters",
    EntityControllerGetEntityModelPageAwsKubernetesWorkloadResourceRequest = "EntityControllerGetEntityModelPageAwsKubernetesWorkloadResourceRequest",
    EntityControllerGetEntityModelPageAwsKubernetesWorkloadResourceRequestFilters = "EntityControllerGetEntityModelPageAwsKubernetesWorkloadResourceRequestFilters",
    EntityControllerGetEntityModelPageAwsLambdaFunctionConfigurationRequest = "EntityControllerGetEntityModelPageAwsLambdaFunctionConfigurationRequest",
    EntityControllerGetEntityModelPageAwsLambdaFunctionConfigurationRequestFilters = "EntityControllerGetEntityModelPageAwsLambdaFunctionConfigurationRequestFilters",
    EntityControllerGetEntityModelPageAwsOrganizationsPolicyRequest = "EntityControllerGetEntityModelPageAwsOrganizationsPolicyRequest",
    EntityControllerGetEntityModelPageAwsOrganizationsPolicyRequestFilters = "EntityControllerGetEntityModelPageAwsOrganizationsPolicyRequestFilters",
    EntityControllerGetEntityModelPageAwsRdsAuroraGlobalClusterRequest = "EntityControllerGetEntityModelPageAwsRdsAuroraGlobalClusterRequest",
    EntityControllerGetEntityModelPageAwsRdsAuroraGlobalClusterRequestFilters = "EntityControllerGetEntityModelPageAwsRdsAuroraGlobalClusterRequestFilters",
    EntityControllerGetEntityModelPageAwsRdsClusterRequest = "EntityControllerGetEntityModelPageAwsRdsClusterRequest",
    EntityControllerGetEntityModelPageAwsRdsClusterRequestFilters = "EntityControllerGetEntityModelPageAwsRdsClusterRequestFilters",
    EntityControllerGetEntityModelPageAwsRdsClusterResourceRequest = "EntityControllerGetEntityModelPageAwsRdsClusterResourceRequest",
    EntityControllerGetEntityModelPageAwsRdsClusterResourceRequestFilters = "EntityControllerGetEntityModelPageAwsRdsClusterResourceRequestFilters",
    EntityControllerGetEntityModelPageAwsRdsDatabaseInstanceRequest = "EntityControllerGetEntityModelPageAwsRdsDatabaseInstanceRequest",
    EntityControllerGetEntityModelPageAwsRdsDatabaseInstanceRequestFilters = "EntityControllerGetEntityModelPageAwsRdsDatabaseInstanceRequestFilters",
    EntityControllerGetEntityModelPageAwsRdsDatabaseInstanceResourceRequest = "EntityControllerGetEntityModelPageAwsRdsDatabaseInstanceResourceRequest",
    EntityControllerGetEntityModelPageAwsRdsDatabaseInstanceResourceRequestFilters = "EntityControllerGetEntityModelPageAwsRdsDatabaseInstanceResourceRequestFilters",
    EntityControllerGetEntityModelPageAwsRdsDocDbDatabaseInstanceRequest = "EntityControllerGetEntityModelPageAwsRdsDocDbDatabaseInstanceRequest",
    EntityControllerGetEntityModelPageAwsRdsDocDbDatabaseInstanceRequestFilters = "EntityControllerGetEntityModelPageAwsRdsDocDbDatabaseInstanceRequestFilters",
    EntityControllerGetEntityModelPageAwsRdsDocDbGlobalClusterRequest = "EntityControllerGetEntityModelPageAwsRdsDocDbGlobalClusterRequest",
    EntityControllerGetEntityModelPageAwsRdsDocDbGlobalClusterRequestFilters = "EntityControllerGetEntityModelPageAwsRdsDocDbGlobalClusterRequestFilters",
    EntityControllerGetEntityModelPageAwsRdsGlobalClusterResourceRequest = "EntityControllerGetEntityModelPageAwsRdsGlobalClusterResourceRequest",
    EntityControllerGetEntityModelPageAwsRdsGlobalClusterResourceRequestFilters = "EntityControllerGetEntityModelPageAwsRdsGlobalClusterResourceRequestFilters",
    EntityControllerGetEntityModelPageAwsRdsNeptuneClusterRequest = "EntityControllerGetEntityModelPageAwsRdsNeptuneClusterRequest",
    EntityControllerGetEntityModelPageAwsRdsNeptuneClusterRequestFilters = "EntityControllerGetEntityModelPageAwsRdsNeptuneClusterRequestFilters",
    EntityControllerGetEntityModelPageAwsRdsNeptuneClusterSnapshotRequest = "EntityControllerGetEntityModelPageAwsRdsNeptuneClusterSnapshotRequest",
    EntityControllerGetEntityModelPageAwsRdsNeptuneClusterSnapshotRequestFilters = "EntityControllerGetEntityModelPageAwsRdsNeptuneClusterSnapshotRequestFilters",
    EntityControllerGetEntityModelPageAwsRdsNeptuneDatabaseInstanceRequest = "EntityControllerGetEntityModelPageAwsRdsNeptuneDatabaseInstanceRequest",
    EntityControllerGetEntityModelPageAwsRdsNeptuneDatabaseInstanceRequestFilters = "EntityControllerGetEntityModelPageAwsRdsNeptuneDatabaseInstanceRequestFilters",
    EntityControllerGetEntityModelPageAwsRdsNeptuneGlobalClusterRequest = "EntityControllerGetEntityModelPageAwsRdsNeptuneGlobalClusterRequest",
    EntityControllerGetEntityModelPageAwsRdsNeptuneGlobalClusterRequestFilters = "EntityControllerGetEntityModelPageAwsRdsNeptuneGlobalClusterRequestFilters",
    EntityControllerGetEntityModelPageAwsRdsSnapshotRequest = "EntityControllerGetEntityModelPageAwsRdsSnapshotRequest",
    EntityControllerGetEntityModelPageAwsRdsSnapshotRequestFilters = "EntityControllerGetEntityModelPageAwsRdsSnapshotRequestFilters",
    EntityControllerGetEntityModelPageAwsRedshiftClusterRequest = "EntityControllerGetEntityModelPageAwsRedshiftClusterRequest",
    EntityControllerGetEntityModelPageAwsRedshiftClusterRequestFilters = "EntityControllerGetEntityModelPageAwsRedshiftClusterRequestFilters",
    EntityControllerGetEntityModelPageAwsRedshiftNamespaceRequest = "EntityControllerGetEntityModelPageAwsRedshiftNamespaceRequest",
    EntityControllerGetEntityModelPageAwsRedshiftNamespaceRequestFilters = "EntityControllerGetEntityModelPageAwsRedshiftNamespaceRequestFilters",
    EntityControllerGetEntityModelPageAwsResourceRequest = "EntityControllerGetEntityModelPageAwsResourceRequest",
    EntityControllerGetEntityModelPageAwsResourceRequestAccess = "EntityControllerGetEntityModelPageAwsResourceRequestAccess",
    EntityControllerGetEntityModelPageAwsResourceRequestCloudWatchLogging = "EntityControllerGetEntityModelPageAwsResourceRequestCloudWatchLogging",
    EntityControllerGetEntityModelPageAwsResourceRequestEncrypted = "EntityControllerGetEntityModelPageAwsResourceRequestEncrypted",
    EntityControllerGetEntityModelPageAwsResourceRequestFilters = "EntityControllerGetEntityModelPageAwsResourceRequestFilters",
    EntityControllerGetEntityModelPageAwsResourceRequestNetworked = "EntityControllerGetEntityModelPageAwsResourceRequestNetworked",
    EntityControllerGetEntityModelPageAwsRoute53HostedZoneRequest = "EntityControllerGetEntityModelPageAwsRoute53HostedZoneRequest",
    EntityControllerGetEntityModelPageAwsRoute53HostedZoneRequestFilters = "EntityControllerGetEntityModelPageAwsRoute53HostedZoneRequestFilters",
    EntityControllerGetEntityModelPageAwsS3BucketRequest = "EntityControllerGetEntityModelPageAwsS3BucketRequest",
    EntityControllerGetEntityModelPageAwsS3BucketRequestFilters = "EntityControllerGetEntityModelPageAwsS3BucketRequestFilters",
    EntityControllerGetEntityModelPageAwsSageMakerNotebookInstanceRequest = "EntityControllerGetEntityModelPageAwsSageMakerNotebookInstanceRequest",
    EntityControllerGetEntityModelPageAwsSageMakerNotebookInstanceRequestFilters = "EntityControllerGetEntityModelPageAwsSageMakerNotebookInstanceRequestFilters",
    EntityControllerGetEntityModelPageAwsSecretsManagerSecretRequest = "EntityControllerGetEntityModelPageAwsSecretsManagerSecretRequest",
    EntityControllerGetEntityModelPageAwsSecretsManagerSecretRequestFilters = "EntityControllerGetEntityModelPageAwsSecretsManagerSecretRequestFilters",
    EntityControllerGetEntityModelPageAwsServiceRequest = "EntityControllerGetEntityModelPageAwsServiceRequest",
    EntityControllerGetEntityModelPageAwsServiceRequestFilters = "EntityControllerGetEntityModelPageAwsServiceRequestFilters",
    EntityControllerGetEntityModelPageAwsSnsTopicRequest = "EntityControllerGetEntityModelPageAwsSnsTopicRequest",
    EntityControllerGetEntityModelPageAwsSnsTopicRequestFilters = "EntityControllerGetEntityModelPageAwsSnsTopicRequestFilters",
    EntityControllerGetEntityModelPageAwsSqsQueueRequest = "EntityControllerGetEntityModelPageAwsSqsQueueRequest",
    EntityControllerGetEntityModelPageAwsSqsQueueRequestFilters = "EntityControllerGetEntityModelPageAwsSqsQueueRequestFilters",
    EntityControllerGetEntityModelPageAwsSsmParameterRequest = "EntityControllerGetEntityModelPageAwsSsmParameterRequest",
    EntityControllerGetEntityModelPageAwsSsmParameterRequestFilters = "EntityControllerGetEntityModelPageAwsSsmParameterRequestFilters",
    EntityControllerGetEntityModelPageAwsSsoGroupRequest = "EntityControllerGetEntityModelPageAwsSsoGroupRequest",
    EntityControllerGetEntityModelPageAwsSsoGroupRequestFilters = "EntityControllerGetEntityModelPageAwsSsoGroupRequestFilters",
    EntityControllerGetEntityModelPageAwsSsoPermissionSetRequest = "EntityControllerGetEntityModelPageAwsSsoPermissionSetRequest",
    EntityControllerGetEntityModelPageAwsSsoPermissionSetRequestFilters = "EntityControllerGetEntityModelPageAwsSsoPermissionSetRequestFilters",
    EntityControllerGetEntityModelPageAwsSsoPrincipalRequest = "EntityControllerGetEntityModelPageAwsSsoPrincipalRequest",
    EntityControllerGetEntityModelPageAwsSsoPrincipalRequestFilters = "EntityControllerGetEntityModelPageAwsSsoPrincipalRequestFilters",
    EntityControllerGetEntityModelPageAwsSsoUserRequest = "EntityControllerGetEntityModelPageAwsSsoUserRequest",
    EntityControllerGetEntityModelPageAwsSsoUserRequestFilters = "EntityControllerGetEntityModelPageAwsSsoUserRequestFilters",
    EntityControllerGetEntityModelPageAwsTenantEntityRequest = "EntityControllerGetEntityModelPageAwsTenantEntityRequest",
    EntityControllerGetEntityModelPageAwsTenantEntityRequestFilters = "EntityControllerGetEntityModelPageAwsTenantEntityRequestFilters",
    EntityControllerGetEntityModelPageAwsTimestreamDatabaseRequest = "EntityControllerGetEntityModelPageAwsTimestreamDatabaseRequest",
    EntityControllerGetEntityModelPageAwsTimestreamDatabaseRequestFilters = "EntityControllerGetEntityModelPageAwsTimestreamDatabaseRequestFilters",
    EntityControllerGetEntityModelPageAwsTimestreamDatabaseTableRequest = "EntityControllerGetEntityModelPageAwsTimestreamDatabaseTableRequest",
    EntityControllerGetEntityModelPageAwsTimestreamDatabaseTableRequestFilters = "EntityControllerGetEntityModelPageAwsTimestreamDatabaseTableRequestFilters",
    EntityControllerGetEntityModelPageAwsUnmanagedKubernetesClusterRequest = "EntityControllerGetEntityModelPageAwsUnmanagedKubernetesClusterRequest",
    EntityControllerGetEntityModelPageAwsUnmanagedKubernetesClusterRequestFilters = "EntityControllerGetEntityModelPageAwsUnmanagedKubernetesClusterRequestFilters",
    EntityControllerGetEntityModelPageAwsWafWebAclRequest = "EntityControllerGetEntityModelPageAwsWafWebAclRequest",
    EntityControllerGetEntityModelPageAwsWafWebAclRequestFilters = "EntityControllerGetEntityModelPageAwsWafWebAclRequestFilters",
    EntityControllerGetEntityModelPageAzureAuthorizationRoleAssignmentResourceRequest = "EntityControllerGetEntityModelPageAzureAuthorizationRoleAssignmentResourceRequest",
    EntityControllerGetEntityModelPageAzureAuthorizationRoleAssignmentResourceRequestFilters = "EntityControllerGetEntityModelPageAzureAuthorizationRoleAssignmentResourceRequestFilters",
    EntityControllerGetEntityModelPageAzureAuthorizationRoleDefinitionRequest = "EntityControllerGetEntityModelPageAzureAuthorizationRoleDefinitionRequest",
    EntityControllerGetEntityModelPageAzureAuthorizationRoleDefinitionRequestFilters = "EntityControllerGetEntityModelPageAzureAuthorizationRoleDefinitionRequestFilters",
    EntityControllerGetEntityModelPageAzureAuthorizationRoleManagementPolicyAssignmentRequest = "EntityControllerGetEntityModelPageAzureAuthorizationRoleManagementPolicyAssignmentRequest",
    EntityControllerGetEntityModelPageAzureAuthorizationRoleManagementPolicyAssignmentRequestFilters = "EntityControllerGetEntityModelPageAzureAuthorizationRoleManagementPolicyAssignmentRequestFilters",
    EntityControllerGetEntityModelPageAzureBotServiceBotServiceRequest = "EntityControllerGetEntityModelPageAzureBotServiceBotServiceRequest",
    EntityControllerGetEntityModelPageAzureBotServiceBotServiceRequestFilters = "EntityControllerGetEntityModelPageAzureBotServiceBotServiceRequestFilters",
    EntityControllerGetEntityModelPageAzureCognitiveServicesAccountRequest = "EntityControllerGetEntityModelPageAzureCognitiveServicesAccountRequest",
    EntityControllerGetEntityModelPageAzureCognitiveServicesAccountRequestFilters = "EntityControllerGetEntityModelPageAzureCognitiveServicesAccountRequestFilters",
    EntityControllerGetEntityModelPageAzureComputeDiskRequest = "EntityControllerGetEntityModelPageAzureComputeDiskRequest",
    EntityControllerGetEntityModelPageAzureComputeDiskRequestFilters = "EntityControllerGetEntityModelPageAzureComputeDiskRequestFilters",
    EntityControllerGetEntityModelPageAzureComputeDiskResourceRequest = "EntityControllerGetEntityModelPageAzureComputeDiskResourceRequest",
    EntityControllerGetEntityModelPageAzureComputeDiskResourceRequestFilters = "EntityControllerGetEntityModelPageAzureComputeDiskResourceRequestFilters",
    EntityControllerGetEntityModelPageAzureComputeDiskSnapshotRequest = "EntityControllerGetEntityModelPageAzureComputeDiskSnapshotRequest",
    EntityControllerGetEntityModelPageAzureComputeDiskSnapshotRequestFilters = "EntityControllerGetEntityModelPageAzureComputeDiskSnapshotRequestFilters",
    EntityControllerGetEntityModelPageAzureComputeVirtualMachineRequest = "EntityControllerGetEntityModelPageAzureComputeVirtualMachineRequest",
    EntityControllerGetEntityModelPageAzureComputeVirtualMachineRequestFilters = "EntityControllerGetEntityModelPageAzureComputeVirtualMachineRequestFilters",
    EntityControllerGetEntityModelPageAzureComputeVirtualMachineResourceRequest = "EntityControllerGetEntityModelPageAzureComputeVirtualMachineResourceRequest",
    EntityControllerGetEntityModelPageAzureComputeVirtualMachineResourceRequestFilters = "EntityControllerGetEntityModelPageAzureComputeVirtualMachineResourceRequestFilters",
    EntityControllerGetEntityModelPageAzureComputeVirtualMachineScaleSetRequest = "EntityControllerGetEntityModelPageAzureComputeVirtualMachineScaleSetRequest",
    EntityControllerGetEntityModelPageAzureComputeVirtualMachineScaleSetRequestFilters = "EntityControllerGetEntityModelPageAzureComputeVirtualMachineScaleSetRequestFilters",
    EntityControllerGetEntityModelPageAzureComputeVirtualMachineScaleSetVirtualMachineRequest = "EntityControllerGetEntityModelPageAzureComputeVirtualMachineScaleSetVirtualMachineRequest",
    EntityControllerGetEntityModelPageAzureComputeVirtualMachineScaleSetVirtualMachineRequestFilters = "EntityControllerGetEntityModelPageAzureComputeVirtualMachineScaleSetVirtualMachineRequestFilters",
    EntityControllerGetEntityModelPageAzureContainerImageRequest = "EntityControllerGetEntityModelPageAzureContainerImageRequest",
    EntityControllerGetEntityModelPageAzureContainerImageRequestFilters = "EntityControllerGetEntityModelPageAzureContainerImageRequestFilters",
    EntityControllerGetEntityModelPageAzureContainerRegistryRegistryRequest = "EntityControllerGetEntityModelPageAzureContainerRegistryRegistryRequest",
    EntityControllerGetEntityModelPageAzureContainerRegistryRegistryRequestFilters = "EntityControllerGetEntityModelPageAzureContainerRegistryRegistryRequestFilters",
    EntityControllerGetEntityModelPageAzureContainerRegistryRepositoryRequest = "EntityControllerGetEntityModelPageAzureContainerRegistryRepositoryRequest",
    EntityControllerGetEntityModelPageAzureContainerRegistryRepositoryRequestFilters = "EntityControllerGetEntityModelPageAzureContainerRegistryRepositoryRequestFilters",
    EntityControllerGetEntityModelPageAzureContainerServiceManagedClusterAgentPoolRequest = "EntityControllerGetEntityModelPageAzureContainerServiceManagedClusterAgentPoolRequest",
    EntityControllerGetEntityModelPageAzureContainerServiceManagedClusterAgentPoolRequestFilters = "EntityControllerGetEntityModelPageAzureContainerServiceManagedClusterAgentPoolRequestFilters",
    EntityControllerGetEntityModelPageAzureContainerServiceManagedClusterRequest = "EntityControllerGetEntityModelPageAzureContainerServiceManagedClusterRequest",
    EntityControllerGetEntityModelPageAzureContainerServiceManagedClusterRequestFilters = "EntityControllerGetEntityModelPageAzureContainerServiceManagedClusterRequestFilters",
    EntityControllerGetEntityModelPageAzureDefaultKubernetesNamespaceResourceRequest = "EntityControllerGetEntityModelPageAzureDefaultKubernetesNamespaceResourceRequest",
    EntityControllerGetEntityModelPageAzureDefaultKubernetesNamespaceResourceRequestFilters = "EntityControllerGetEntityModelPageAzureDefaultKubernetesNamespaceResourceRequestFilters",
    EntityControllerGetEntityModelPageAzureDefaultKubernetesResourceRequest = "EntityControllerGetEntityModelPageAzureDefaultKubernetesResourceRequest",
    EntityControllerGetEntityModelPageAzureDefaultKubernetesResourceRequestFilters = "EntityControllerGetEntityModelPageAzureDefaultKubernetesResourceRequestFilters",
    EntityControllerGetEntityModelPageAzureDefaultResourceGroupResourceRequest = "EntityControllerGetEntityModelPageAzureDefaultResourceGroupResourceRequest",
    EntityControllerGetEntityModelPageAzureDefaultResourceGroupResourceRequestFilters = "EntityControllerGetEntityModelPageAzureDefaultResourceGroupResourceRequestFilters",
    EntityControllerGetEntityModelPageAzureDefaultResourceRequest = "EntityControllerGetEntityModelPageAzureDefaultResourceRequest",
    EntityControllerGetEntityModelPageAzureDefaultResourceRequestFilters = "EntityControllerGetEntityModelPageAzureDefaultResourceRequestFilters",
    EntityControllerGetEntityModelPageAzureDocumentDbDatabaseAccountRequest = "EntityControllerGetEntityModelPageAzureDocumentDbDatabaseAccountRequest",
    EntityControllerGetEntityModelPageAzureDocumentDbDatabaseAccountRequestFilters = "EntityControllerGetEntityModelPageAzureDocumentDbDatabaseAccountRequestFilters",
    EntityControllerGetEntityModelPageAzureEventHubNamespaceRequest = "EntityControllerGetEntityModelPageAzureEventHubNamespaceRequest",
    EntityControllerGetEntityModelPageAzureEventHubNamespaceRequestFilters = "EntityControllerGetEntityModelPageAzureEventHubNamespaceRequestFilters",
    EntityControllerGetEntityModelPageAzureKeyVaultVaultCertificateRequest = "EntityControllerGetEntityModelPageAzureKeyVaultVaultCertificateRequest",
    EntityControllerGetEntityModelPageAzureKeyVaultVaultCertificateRequestFilters = "EntityControllerGetEntityModelPageAzureKeyVaultVaultCertificateRequestFilters",
    EntityControllerGetEntityModelPageAzureKeyVaultVaultKeyRequest = "EntityControllerGetEntityModelPageAzureKeyVaultVaultKeyRequest",
    EntityControllerGetEntityModelPageAzureKeyVaultVaultKeyRequestFilters = "EntityControllerGetEntityModelPageAzureKeyVaultVaultKeyRequestFilters",
    EntityControllerGetEntityModelPageAzureKeyVaultVaultObjectRequest = "EntityControllerGetEntityModelPageAzureKeyVaultVaultObjectRequest",
    EntityControllerGetEntityModelPageAzureKeyVaultVaultObjectRequestFilters = "EntityControllerGetEntityModelPageAzureKeyVaultVaultObjectRequestFilters",
    EntityControllerGetEntityModelPageAzureKeyVaultVaultRequest = "EntityControllerGetEntityModelPageAzureKeyVaultVaultRequest",
    EntityControllerGetEntityModelPageAzureKeyVaultVaultRequestFilters = "EntityControllerGetEntityModelPageAzureKeyVaultVaultRequestFilters",
    EntityControllerGetEntityModelPageAzureKeyVaultVaultSecretRequest = "EntityControllerGetEntityModelPageAzureKeyVaultVaultSecretRequest",
    EntityControllerGetEntityModelPageAzureKeyVaultVaultSecretRequestFilters = "EntityControllerGetEntityModelPageAzureKeyVaultVaultSecretRequestFilters",
    EntityControllerGetEntityModelPageAzureKubernetesClusterRoleBindingRequest = "EntityControllerGetEntityModelPageAzureKubernetesClusterRoleBindingRequest",
    EntityControllerGetEntityModelPageAzureKubernetesClusterRoleBindingRequestFilters = "EntityControllerGetEntityModelPageAzureKubernetesClusterRoleBindingRequestFilters",
    EntityControllerGetEntityModelPageAzureKubernetesClusterRoleRequest = "EntityControllerGetEntityModelPageAzureKubernetesClusterRoleRequest",
    EntityControllerGetEntityModelPageAzureKubernetesClusterRoleRequestFilters = "EntityControllerGetEntityModelPageAzureKubernetesClusterRoleRequestFilters",
    EntityControllerGetEntityModelPageAzureKubernetesGroupRequest = "EntityControllerGetEntityModelPageAzureKubernetesGroupRequest",
    EntityControllerGetEntityModelPageAzureKubernetesGroupRequestFilters = "EntityControllerGetEntityModelPageAzureKubernetesGroupRequestFilters",
    EntityControllerGetEntityModelPageAzureKubernetesIngressRequest = "EntityControllerGetEntityModelPageAzureKubernetesIngressRequest",
    EntityControllerGetEntityModelPageAzureKubernetesIngressRequestFilters = "EntityControllerGetEntityModelPageAzureKubernetesIngressRequestFilters",
    EntityControllerGetEntityModelPageAzureKubernetesNamespaceRequest = "EntityControllerGetEntityModelPageAzureKubernetesNamespaceRequest",
    EntityControllerGetEntityModelPageAzureKubernetesNamespaceRequestFilters = "EntityControllerGetEntityModelPageAzureKubernetesNamespaceRequestFilters",
    EntityControllerGetEntityModelPageAzureKubernetesNamespaceResourceRequest = "EntityControllerGetEntityModelPageAzureKubernetesNamespaceResourceRequest",
    EntityControllerGetEntityModelPageAzureKubernetesNamespaceResourceRequestFilters = "EntityControllerGetEntityModelPageAzureKubernetesNamespaceResourceRequestFilters",
    EntityControllerGetEntityModelPageAzureKubernetesNamespaceRoleBindingRequest = "EntityControllerGetEntityModelPageAzureKubernetesNamespaceRoleBindingRequest",
    EntityControllerGetEntityModelPageAzureKubernetesNamespaceRoleBindingRequestFilters = "EntityControllerGetEntityModelPageAzureKubernetesNamespaceRoleBindingRequestFilters",
    EntityControllerGetEntityModelPageAzureKubernetesNamespaceRoleRequest = "EntityControllerGetEntityModelPageAzureKubernetesNamespaceRoleRequest",
    EntityControllerGetEntityModelPageAzureKubernetesNamespaceRoleRequestFilters = "EntityControllerGetEntityModelPageAzureKubernetesNamespaceRoleRequestFilters",
    EntityControllerGetEntityModelPageAzureKubernetesResourceRequest = "EntityControllerGetEntityModelPageAzureKubernetesResourceRequest",
    EntityControllerGetEntityModelPageAzureKubernetesResourceRequestFilters = "EntityControllerGetEntityModelPageAzureKubernetesResourceRequestFilters",
    EntityControllerGetEntityModelPageAzureKubernetesServiceAccountRequest = "EntityControllerGetEntityModelPageAzureKubernetesServiceAccountRequest",
    EntityControllerGetEntityModelPageAzureKubernetesServiceAccountRequestFilters = "EntityControllerGetEntityModelPageAzureKubernetesServiceAccountRequestFilters",
    EntityControllerGetEntityModelPageAzureKubernetesServiceRequest = "EntityControllerGetEntityModelPageAzureKubernetesServiceRequest",
    EntityControllerGetEntityModelPageAzureKubernetesServiceRequestFilters = "EntityControllerGetEntityModelPageAzureKubernetesServiceRequestFilters",
    EntityControllerGetEntityModelPageAzureKubernetesUserRequest = "EntityControllerGetEntityModelPageAzureKubernetesUserRequest",
    EntityControllerGetEntityModelPageAzureKubernetesUserRequestFilters = "EntityControllerGetEntityModelPageAzureKubernetesUserRequestFilters",
    EntityControllerGetEntityModelPageAzureKubernetesWorkloadResourceRequest = "EntityControllerGetEntityModelPageAzureKubernetesWorkloadResourceRequest",
    EntityControllerGetEntityModelPageAzureKubernetesWorkloadResourceRequestFilters = "EntityControllerGetEntityModelPageAzureKubernetesWorkloadResourceRequestFilters",
    EntityControllerGetEntityModelPageAzureLogicWorkflowRequest = "EntityControllerGetEntityModelPageAzureLogicWorkflowRequest",
    EntityControllerGetEntityModelPageAzureLogicWorkflowRequestFilters = "EntityControllerGetEntityModelPageAzureLogicWorkflowRequestFilters",
    EntityControllerGetEntityModelPageAzureMachineLearningWorkspaceRequest = "EntityControllerGetEntityModelPageAzureMachineLearningWorkspaceRequest",
    EntityControllerGetEntityModelPageAzureMachineLearningWorkspaceRequestFilters = "EntityControllerGetEntityModelPageAzureMachineLearningWorkspaceRequestFilters",
    EntityControllerGetEntityModelPageAzureManagedIdentityManagedIdentityRequest = "EntityControllerGetEntityModelPageAzureManagedIdentityManagedIdentityRequest",
    EntityControllerGetEntityModelPageAzureManagedIdentityManagedIdentityRequestFilters = "EntityControllerGetEntityModelPageAzureManagedIdentityManagedIdentityRequestFilters",
    EntityControllerGetEntityModelPageAzureMySqlServerRequest = "EntityControllerGetEntityModelPageAzureMySqlServerRequest",
    EntityControllerGetEntityModelPageAzureMySqlServerRequestFilters = "EntityControllerGetEntityModelPageAzureMySqlServerRequestFilters",
    EntityControllerGetEntityModelPageAzureNetworkApplicationGatewayRequest = "EntityControllerGetEntityModelPageAzureNetworkApplicationGatewayRequest",
    EntityControllerGetEntityModelPageAzureNetworkApplicationGatewayRequestFilters = "EntityControllerGetEntityModelPageAzureNetworkApplicationGatewayRequestFilters",
    EntityControllerGetEntityModelPageAzureNetworkApplicationSecurityGroupRequest = "EntityControllerGetEntityModelPageAzureNetworkApplicationSecurityGroupRequest",
    EntityControllerGetEntityModelPageAzureNetworkApplicationSecurityGroupRequestFilters = "EntityControllerGetEntityModelPageAzureNetworkApplicationSecurityGroupRequestFilters",
    EntityControllerGetEntityModelPageAzureNetworkDnsZoneRequest = "EntityControllerGetEntityModelPageAzureNetworkDnsZoneRequest",
    EntityControllerGetEntityModelPageAzureNetworkDnsZoneRequestFilters = "EntityControllerGetEntityModelPageAzureNetworkDnsZoneRequestFilters",
    EntityControllerGetEntityModelPageAzureNetworkLoadBalancerRequest = "EntityControllerGetEntityModelPageAzureNetworkLoadBalancerRequest",
    EntityControllerGetEntityModelPageAzureNetworkLoadBalancerRequestFilters = "EntityControllerGetEntityModelPageAzureNetworkLoadBalancerRequestFilters",
    EntityControllerGetEntityModelPageAzureNetworkNetworkInterfaceRequest = "EntityControllerGetEntityModelPageAzureNetworkNetworkInterfaceRequest",
    EntityControllerGetEntityModelPageAzureNetworkNetworkInterfaceRequestFilters = "EntityControllerGetEntityModelPageAzureNetworkNetworkInterfaceRequestFilters",
    EntityControllerGetEntityModelPageAzureNetworkNetworkSecurityGroupRequest = "EntityControllerGetEntityModelPageAzureNetworkNetworkSecurityGroupRequest",
    EntityControllerGetEntityModelPageAzureNetworkNetworkSecurityGroupRequestFilters = "EntityControllerGetEntityModelPageAzureNetworkNetworkSecurityGroupRequestFilters",
    EntityControllerGetEntityModelPageAzureNetworkPrivateDnsZoneRequest = "EntityControllerGetEntityModelPageAzureNetworkPrivateDnsZoneRequest",
    EntityControllerGetEntityModelPageAzureNetworkPrivateDnsZoneRequestFilters = "EntityControllerGetEntityModelPageAzureNetworkPrivateDnsZoneRequestFilters",
    EntityControllerGetEntityModelPageAzureNetworkPrivateEndpointRequest = "EntityControllerGetEntityModelPageAzureNetworkPrivateEndpointRequest",
    EntityControllerGetEntityModelPageAzureNetworkPrivateEndpointRequestFilters = "EntityControllerGetEntityModelPageAzureNetworkPrivateEndpointRequestFilters",
    EntityControllerGetEntityModelPageAzureNetworkPublicIpAddressRequest = "EntityControllerGetEntityModelPageAzureNetworkPublicIpAddressRequest",
    EntityControllerGetEntityModelPageAzureNetworkPublicIpAddressRequestFilters = "EntityControllerGetEntityModelPageAzureNetworkPublicIpAddressRequestFilters",
    EntityControllerGetEntityModelPageAzureNetworkPublicIpPrefixRequest = "EntityControllerGetEntityModelPageAzureNetworkPublicIpPrefixRequest",
    EntityControllerGetEntityModelPageAzureNetworkPublicIpPrefixRequestFilters = "EntityControllerGetEntityModelPageAzureNetworkPublicIpPrefixRequestFilters",
    EntityControllerGetEntityModelPageAzureNetworkRouteTableRequest = "EntityControllerGetEntityModelPageAzureNetworkRouteTableRequest",
    EntityControllerGetEntityModelPageAzureNetworkRouteTableRequestFilters = "EntityControllerGetEntityModelPageAzureNetworkRouteTableRequestFilters",
    EntityControllerGetEntityModelPageAzureNetworkVirtualNetworkRequest = "EntityControllerGetEntityModelPageAzureNetworkVirtualNetworkRequest",
    EntityControllerGetEntityModelPageAzureNetworkVirtualNetworkRequestFilters = "EntityControllerGetEntityModelPageAzureNetworkVirtualNetworkRequestFilters",
    EntityControllerGetEntityModelPageAzureNetworkVirtualNetworkSubnetRequest = "EntityControllerGetEntityModelPageAzureNetworkVirtualNetworkSubnetRequest",
    EntityControllerGetEntityModelPageAzureNetworkVirtualNetworkSubnetRequestFilters = "EntityControllerGetEntityModelPageAzureNetworkVirtualNetworkSubnetRequestFilters",
    EntityControllerGetEntityModelPageAzurePostgreSqlServerRequest = "EntityControllerGetEntityModelPageAzurePostgreSqlServerRequest",
    EntityControllerGetEntityModelPageAzurePostgreSqlServerRequestFilters = "EntityControllerGetEntityModelPageAzurePostgreSqlServerRequestFilters",
    EntityControllerGetEntityModelPageAzureResourceGroupResourceRequest = "EntityControllerGetEntityModelPageAzureResourceGroupResourceRequest",
    EntityControllerGetEntityModelPageAzureResourceGroupResourceRequestFilters = "EntityControllerGetEntityModelPageAzureResourceGroupResourceRequestFilters",
    EntityControllerGetEntityModelPageAzureResourceRequest = "EntityControllerGetEntityModelPageAzureResourceRequest",
    EntityControllerGetEntityModelPageAzureResourceRequestEncrypted = "EntityControllerGetEntityModelPageAzureResourceRequestEncrypted",
    EntityControllerGetEntityModelPageAzureResourceRequestFilters = "EntityControllerGetEntityModelPageAzureResourceRequestFilters",
    EntityControllerGetEntityModelPageAzureResourcesResourceGroupRequest = "EntityControllerGetEntityModelPageAzureResourcesResourceGroupRequest",
    EntityControllerGetEntityModelPageAzureResourcesResourceGroupRequestFilters = "EntityControllerGetEntityModelPageAzureResourcesResourceGroupRequestFilters",
    EntityControllerGetEntityModelPageAzureSqlServerDatabaseRequest = "EntityControllerGetEntityModelPageAzureSqlServerDatabaseRequest",
    EntityControllerGetEntityModelPageAzureSqlServerDatabaseRequestFilters = "EntityControllerGetEntityModelPageAzureSqlServerDatabaseRequestFilters",
    EntityControllerGetEntityModelPageAzureSqlServerRequest = "EntityControllerGetEntityModelPageAzureSqlServerRequest",
    EntityControllerGetEntityModelPageAzureSqlServerRequestFilters = "EntityControllerGetEntityModelPageAzureSqlServerRequestFilters",
    EntityControllerGetEntityModelPageAzureStorageStorageAccountBlobContainerRequest = "EntityControllerGetEntityModelPageAzureStorageStorageAccountBlobContainerRequest",
    EntityControllerGetEntityModelPageAzureStorageStorageAccountBlobContainerRequestFilters = "EntityControllerGetEntityModelPageAzureStorageStorageAccountBlobContainerRequestFilters",
    EntityControllerGetEntityModelPageAzureStorageStorageAccountRequest = "EntityControllerGetEntityModelPageAzureStorageStorageAccountRequest",
    EntityControllerGetEntityModelPageAzureStorageStorageAccountRequestFilters = "EntityControllerGetEntityModelPageAzureStorageStorageAccountRequestFilters",
    EntityControllerGetEntityModelPageAzureTenantEntityRequest = "EntityControllerGetEntityModelPageAzureTenantEntityRequest",
    EntityControllerGetEntityModelPageAzureTenantEntityRequestFilters = "EntityControllerGetEntityModelPageAzureTenantEntityRequestFilters",
    EntityControllerGetEntityModelPageAzureUnmanagedKubernetesClusterRequest = "EntityControllerGetEntityModelPageAzureUnmanagedKubernetesClusterRequest",
    EntityControllerGetEntityModelPageAzureUnmanagedKubernetesClusterRequestFilters = "EntityControllerGetEntityModelPageAzureUnmanagedKubernetesClusterRequestFilters",
    EntityControllerGetEntityModelPageAzureWebApplicationRequest = "EntityControllerGetEntityModelPageAzureWebApplicationRequest",
    EntityControllerGetEntityModelPageAzureWebApplicationRequestFilters = "EntityControllerGetEntityModelPageAzureWebApplicationRequestFilters",
    EntityControllerGetEntityModelPageCiContainerImageRepositoryRequest = "EntityControllerGetEntityModelPageCiContainerImageRepositoryRequest",
    EntityControllerGetEntityModelPageCiContainerImageRepositoryRequestFilters = "EntityControllerGetEntityModelPageCiContainerImageRepositoryRequestFilters",
    EntityControllerGetEntityModelPageCiContainerImageRequest = "EntityControllerGetEntityModelPageCiContainerImageRequest",
    EntityControllerGetEntityModelPageCiContainerImageRequestFilters = "EntityControllerGetEntityModelPageCiContainerImageRequestFilters",
    EntityControllerGetEntityModelPageCiDefaultResourceRequest = "EntityControllerGetEntityModelPageCiDefaultResourceRequest",
    EntityControllerGetEntityModelPageCiDefaultResourceRequestFilters = "EntityControllerGetEntityModelPageCiDefaultResourceRequestFilters",
    EntityControllerGetEntityModelPageDataAnalysisResourceRequest = "EntityControllerGetEntityModelPageDataAnalysisResourceRequest",
    EntityControllerGetEntityModelPageDataAnalysisResourceRequestFilters = "EntityControllerGetEntityModelPageDataAnalysisResourceRequestFilters",
    EntityControllerGetEntityModelPageEntityRequestDataAnalysis = "EntityControllerGetEntityModelPageEntityRequestDataAnalysis",
    EntityControllerGetEntityModelPageEntityRequestKubernetesIngress = "EntityControllerGetEntityModelPageEntityRequestKubernetesIngress",
    EntityControllerGetEntityModelPageEntityRequestKubernetesNamespace = "EntityControllerGetEntityModelPageEntityRequestKubernetesNamespace",
    EntityControllerGetEntityModelPageEntityRequestKubernetesNamespaceResource = "EntityControllerGetEntityModelPageEntityRequestKubernetesNamespaceResource",
    EntityControllerGetEntityModelPageEntityRequestKubernetesPrincipal = "EntityControllerGetEntityModelPageEntityRequestKubernetesPrincipal",
    EntityControllerGetEntityModelPageEntityRequestKubernetesResource = "EntityControllerGetEntityModelPageEntityRequestKubernetesResource",
    EntityControllerGetEntityModelPageEntityRequestKubernetesRole = "EntityControllerGetEntityModelPageEntityRequestKubernetesRole",
    EntityControllerGetEntityModelPageEntityRequestKubernetesRoleBinding = "EntityControllerGetEntityModelPageEntityRequestKubernetesRoleBinding",
    EntityControllerGetEntityModelPageEntityRequestKubernetesService = "EntityControllerGetEntityModelPageEntityRequestKubernetesService",
    EntityControllerGetEntityModelPageEntityRequestNetworkAccess = "EntityControllerGetEntityModelPageEntityRequestNetworkAccess",
    EntityControllerGetEntityModelPageEntityRequestSection = "EntityControllerGetEntityModelPageEntityRequestSection",
    EntityControllerGetEntityModelPageEntityRequestTag = "EntityControllerGetEntityModelPageEntityRequestTag",
    EntityControllerGetEntityModelPageEntityRequestVirtualMachineDiskResource = "EntityControllerGetEntityModelPageEntityRequestVirtualMachineDiskResource",
    EntityControllerGetEntityModelPageEntityRequestVirtualMachineResource = "EntityControllerGetEntityModelPageEntityRequestVirtualMachineResource",
    EntityControllerGetEntityModelPageGciDefaultDirectoryEntityRequest = "EntityControllerGetEntityModelPageGciDefaultDirectoryEntityRequest",
    EntityControllerGetEntityModelPageGciDefaultDirectoryEntityRequestFilters = "EntityControllerGetEntityModelPageGciDefaultDirectoryEntityRequestFilters",
    EntityControllerGetEntityModelPageGciDirectoryDirectoryRoleRequest = "EntityControllerGetEntityModelPageGciDirectoryDirectoryRoleRequest",
    EntityControllerGetEntityModelPageGciDirectoryDirectoryRoleRequestFilters = "EntityControllerGetEntityModelPageGciDirectoryDirectoryRoleRequestFilters",
    EntityControllerGetEntityModelPageGciDirectoryGroupRequest = "EntityControllerGetEntityModelPageGciDirectoryGroupRequest",
    EntityControllerGetEntityModelPageGciDirectoryGroupRequestFilters = "EntityControllerGetEntityModelPageGciDirectoryGroupRequestFilters",
    EntityControllerGetEntityModelPageGciDirectoryPartialEntityRequest = "EntityControllerGetEntityModelPageGciDirectoryPartialEntityRequest",
    EntityControllerGetEntityModelPageGciDirectoryPartialEntityRequestFilters = "EntityControllerGetEntityModelPageGciDirectoryPartialEntityRequestFilters",
    EntityControllerGetEntityModelPageGciDirectoryPartialGroupRequest = "EntityControllerGetEntityModelPageGciDirectoryPartialGroupRequest",
    EntityControllerGetEntityModelPageGciDirectoryPartialGroupRequestFilters = "EntityControllerGetEntityModelPageGciDirectoryPartialGroupRequestFilters",
    EntityControllerGetEntityModelPageGciDirectoryPartialPrincipalRequest = "EntityControllerGetEntityModelPageGciDirectoryPartialPrincipalRequest",
    EntityControllerGetEntityModelPageGciDirectoryPartialPrincipalRequestFilters = "EntityControllerGetEntityModelPageGciDirectoryPartialPrincipalRequestFilters",
    EntityControllerGetEntityModelPageGciDirectoryPartialUserRequest = "EntityControllerGetEntityModelPageGciDirectoryPartialUserRequest",
    EntityControllerGetEntityModelPageGciDirectoryPartialUserRequestFilters = "EntityControllerGetEntityModelPageGciDirectoryPartialUserRequestFilters",
    EntityControllerGetEntityModelPageGciDirectoryPrincipalRequest = "EntityControllerGetEntityModelPageGciDirectoryPrincipalRequest",
    EntityControllerGetEntityModelPageGciDirectoryPrincipalRequestFilters = "EntityControllerGetEntityModelPageGciDirectoryPrincipalRequestFilters",
    EntityControllerGetEntityModelPageGciDirectoryUserRequest = "EntityControllerGetEntityModelPageGciDirectoryUserRequest",
    EntityControllerGetEntityModelPageGciDirectoryUserRequestFilters = "EntityControllerGetEntityModelPageGciDirectoryUserRequestFilters",
    EntityControllerGetEntityModelPageGciTenantEntityRequest = "EntityControllerGetEntityModelPageGciTenantEntityRequest",
    EntityControllerGetEntityModelPageGciTenantEntityRequestFilters = "EntityControllerGetEntityModelPageGciTenantEntityRequestFilters",
    EntityControllerGetEntityModelPageGcpApiKeysKeyRequest = "EntityControllerGetEntityModelPageGcpApiKeysKeyRequest",
    EntityControllerGetEntityModelPageGcpApiKeysKeyRequestFilters = "EntityControllerGetEntityModelPageGcpApiKeysKeyRequestFilters",
    EntityControllerGetEntityModelPageGcpAppEngineApplicationRequest = "EntityControllerGetEntityModelPageGcpAppEngineApplicationRequest",
    EntityControllerGetEntityModelPageGcpAppEngineApplicationRequestFilters = "EntityControllerGetEntityModelPageGcpAppEngineApplicationRequestFilters",
    EntityControllerGetEntityModelPageGcpAppEngineApplicationServiceRequest = "EntityControllerGetEntityModelPageGcpAppEngineApplicationServiceRequest",
    EntityControllerGetEntityModelPageGcpAppEngineApplicationServiceRequestFilters = "EntityControllerGetEntityModelPageGcpAppEngineApplicationServiceRequestFilters",
    EntityControllerGetEntityModelPageGcpArtifactContainerImageRepositoryRequest = "EntityControllerGetEntityModelPageGcpArtifactContainerImageRepositoryRequest",
    EntityControllerGetEntityModelPageGcpArtifactContainerImageRepositoryRequestFilters = "EntityControllerGetEntityModelPageGcpArtifactContainerImageRepositoryRequestFilters",
    EntityControllerGetEntityModelPageGcpArtifactPackageRepositoryRequest = "EntityControllerGetEntityModelPageGcpArtifactPackageRepositoryRequest",
    EntityControllerGetEntityModelPageGcpArtifactPackageRepositoryRequestFilters = "EntityControllerGetEntityModelPageGcpArtifactPackageRepositoryRequestFilters",
    EntityControllerGetEntityModelPageGcpArtifactRegistryRequest = "EntityControllerGetEntityModelPageGcpArtifactRegistryRequest",
    EntityControllerGetEntityModelPageGcpArtifactRegistryRequestFilters = "EntityControllerGetEntityModelPageGcpArtifactRegistryRequestFilters",
    EntityControllerGetEntityModelPageGcpBigQueryDatasetRequest = "EntityControllerGetEntityModelPageGcpBigQueryDatasetRequest",
    EntityControllerGetEntityModelPageGcpBigQueryDatasetRequestFilters = "EntityControllerGetEntityModelPageGcpBigQueryDatasetRequestFilters",
    EntityControllerGetEntityModelPageGcpBigQueryDatasetTableRequest = "EntityControllerGetEntityModelPageGcpBigQueryDatasetTableRequest",
    EntityControllerGetEntityModelPageGcpBigQueryDatasetTableRequestFilters = "EntityControllerGetEntityModelPageGcpBigQueryDatasetTableRequestFilters",
    EntityControllerGetEntityModelPageGcpCloudBuildBuildTriggerRequest = "EntityControllerGetEntityModelPageGcpCloudBuildBuildTriggerRequest",
    EntityControllerGetEntityModelPageGcpCloudBuildBuildTriggerRequestFilters = "EntityControllerGetEntityModelPageGcpCloudBuildBuildTriggerRequestFilters",
    EntityControllerGetEntityModelPageGcpCloudRunServiceRequest = "EntityControllerGetEntityModelPageGcpCloudRunServiceRequest",
    EntityControllerGetEntityModelPageGcpCloudRunServiceRequestFilters = "EntityControllerGetEntityModelPageGcpCloudRunServiceRequestFilters",
    EntityControllerGetEntityModelPageGcpComputeBackendServiceRequest = "EntityControllerGetEntityModelPageGcpComputeBackendServiceRequest",
    EntityControllerGetEntityModelPageGcpComputeBackendServiceRequestFilters = "EntityControllerGetEntityModelPageGcpComputeBackendServiceRequestFilters",
    EntityControllerGetEntityModelPageGcpComputeDiskRequest = "EntityControllerGetEntityModelPageGcpComputeDiskRequest",
    EntityControllerGetEntityModelPageGcpComputeDiskRequestFilters = "EntityControllerGetEntityModelPageGcpComputeDiskRequestFilters",
    EntityControllerGetEntityModelPageGcpComputeFirewallPolicyRequest = "EntityControllerGetEntityModelPageGcpComputeFirewallPolicyRequest",
    EntityControllerGetEntityModelPageGcpComputeFirewallPolicyRequestFilters = "EntityControllerGetEntityModelPageGcpComputeFirewallPolicyRequestFilters",
    EntityControllerGetEntityModelPageGcpComputeForwardingRuleRequest = "EntityControllerGetEntityModelPageGcpComputeForwardingRuleRequest",
    EntityControllerGetEntityModelPageGcpComputeForwardingRuleRequestFilters = "EntityControllerGetEntityModelPageGcpComputeForwardingRuleRequestFilters",
    EntityControllerGetEntityModelPageGcpComputeImageRequest = "EntityControllerGetEntityModelPageGcpComputeImageRequest",
    EntityControllerGetEntityModelPageGcpComputeImageRequestFilters = "EntityControllerGetEntityModelPageGcpComputeImageRequestFilters",
    EntityControllerGetEntityModelPageGcpComputeInstanceGroupRequest = "EntityControllerGetEntityModelPageGcpComputeInstanceGroupRequest",
    EntityControllerGetEntityModelPageGcpComputeInstanceGroupRequestFilters = "EntityControllerGetEntityModelPageGcpComputeInstanceGroupRequestFilters",
    EntityControllerGetEntityModelPageGcpComputeInstanceRequest = "EntityControllerGetEntityModelPageGcpComputeInstanceRequest",
    EntityControllerGetEntityModelPageGcpComputeInstanceRequestFilters = "EntityControllerGetEntityModelPageGcpComputeInstanceRequestFilters",
    EntityControllerGetEntityModelPageGcpComputeInstanceTemplateRequest = "EntityControllerGetEntityModelPageGcpComputeInstanceTemplateRequest",
    EntityControllerGetEntityModelPageGcpComputeInstanceTemplateRequestFilters = "EntityControllerGetEntityModelPageGcpComputeInstanceTemplateRequestFilters",
    EntityControllerGetEntityModelPageGcpComputeLoadBalancerRequest = "EntityControllerGetEntityModelPageGcpComputeLoadBalancerRequest",
    EntityControllerGetEntityModelPageGcpComputeLoadBalancerRequestFilters = "EntityControllerGetEntityModelPageGcpComputeLoadBalancerRequestFilters",
    EntityControllerGetEntityModelPageGcpComputeLoadBalancerResourceRequest = "EntityControllerGetEntityModelPageGcpComputeLoadBalancerResourceRequest",
    EntityControllerGetEntityModelPageGcpComputeLoadBalancerResourceRequestFilters = "EntityControllerGetEntityModelPageGcpComputeLoadBalancerResourceRequestFilters",
    EntityControllerGetEntityModelPageGcpComputeSnapshotRequest = "EntityControllerGetEntityModelPageGcpComputeSnapshotRequest",
    EntityControllerGetEntityModelPageGcpComputeSnapshotRequestFilters = "EntityControllerGetEntityModelPageGcpComputeSnapshotRequestFilters",
    EntityControllerGetEntityModelPageGcpComputeSslPolicyRequest = "EntityControllerGetEntityModelPageGcpComputeSslPolicyRequest",
    EntityControllerGetEntityModelPageGcpComputeSslPolicyRequestFilters = "EntityControllerGetEntityModelPageGcpComputeSslPolicyRequestFilters",
    EntityControllerGetEntityModelPageGcpComputeSubnetRequest = "EntityControllerGetEntityModelPageGcpComputeSubnetRequest",
    EntityControllerGetEntityModelPageGcpComputeSubnetRequestFilters = "EntityControllerGetEntityModelPageGcpComputeSubnetRequestFilters",
    EntityControllerGetEntityModelPageGcpComputeTargetPoolRequest = "EntityControllerGetEntityModelPageGcpComputeTargetPoolRequest",
    EntityControllerGetEntityModelPageGcpComputeTargetPoolRequestFilters = "EntityControllerGetEntityModelPageGcpComputeTargetPoolRequestFilters",
    EntityControllerGetEntityModelPageGcpComputeTargetProxyRequest = "EntityControllerGetEntityModelPageGcpComputeTargetProxyRequest",
    EntityControllerGetEntityModelPageGcpComputeTargetProxyRequestFilters = "EntityControllerGetEntityModelPageGcpComputeTargetProxyRequestFilters",
    EntityControllerGetEntityModelPageGcpComputeUrlMapRequest = "EntityControllerGetEntityModelPageGcpComputeUrlMapRequest",
    EntityControllerGetEntityModelPageGcpComputeUrlMapRequestFilters = "EntityControllerGetEntityModelPageGcpComputeUrlMapRequestFilters",
    EntityControllerGetEntityModelPageGcpComputeVpcPeeringConnectionRequest = "EntityControllerGetEntityModelPageGcpComputeVpcPeeringConnectionRequest",
    EntityControllerGetEntityModelPageGcpComputeVpcPeeringConnectionRequestFilters = "EntityControllerGetEntityModelPageGcpComputeVpcPeeringConnectionRequestFilters",
    EntityControllerGetEntityModelPageGcpComputeVpcRequest = "EntityControllerGetEntityModelPageGcpComputeVpcRequest",
    EntityControllerGetEntityModelPageGcpComputeVpcRequestFilters = "EntityControllerGetEntityModelPageGcpComputeVpcRequestFilters",
    EntityControllerGetEntityModelPageGcpContainerClusterNodePoolRequest = "EntityControllerGetEntityModelPageGcpContainerClusterNodePoolRequest",
    EntityControllerGetEntityModelPageGcpContainerClusterNodePoolRequestFilters = "EntityControllerGetEntityModelPageGcpContainerClusterNodePoolRequestFilters",
    EntityControllerGetEntityModelPageGcpContainerClusterRequest = "EntityControllerGetEntityModelPageGcpContainerClusterRequest",
    EntityControllerGetEntityModelPageGcpContainerClusterRequestFilters = "EntityControllerGetEntityModelPageGcpContainerClusterRequestFilters",
    EntityControllerGetEntityModelPageGcpContainerImageRequest = "EntityControllerGetEntityModelPageGcpContainerImageRequest",
    EntityControllerGetEntityModelPageGcpContainerImageRequestFilters = "EntityControllerGetEntityModelPageGcpContainerImageRequestFilters",
    EntityControllerGetEntityModelPageGcpDefaultKubernetesNamespaceResourceRequest = "EntityControllerGetEntityModelPageGcpDefaultKubernetesNamespaceResourceRequest",
    EntityControllerGetEntityModelPageGcpDefaultKubernetesNamespaceResourceRequestFilters = "EntityControllerGetEntityModelPageGcpDefaultKubernetesNamespaceResourceRequestFilters",
    EntityControllerGetEntityModelPageGcpDefaultKubernetesResourceRequest = "EntityControllerGetEntityModelPageGcpDefaultKubernetesResourceRequest",
    EntityControllerGetEntityModelPageGcpDefaultKubernetesResourceRequestFilters = "EntityControllerGetEntityModelPageGcpDefaultKubernetesResourceRequestFilters",
    EntityControllerGetEntityModelPageGcpDefaultResourceRequest = "EntityControllerGetEntityModelPageGcpDefaultResourceRequest",
    EntityControllerGetEntityModelPageGcpDefaultResourceRequestFilters = "EntityControllerGetEntityModelPageGcpDefaultResourceRequestFilters",
    EntityControllerGetEntityModelPageGcpDefaultScopeResourceRequest = "EntityControllerGetEntityModelPageGcpDefaultScopeResourceRequest",
    EntityControllerGetEntityModelPageGcpDefaultScopeResourceRequestFilters = "EntityControllerGetEntityModelPageGcpDefaultScopeResourceRequestFilters",
    EntityControllerGetEntityModelPageGcpDnsManagedZoneRequest = "EntityControllerGetEntityModelPageGcpDnsManagedZoneRequest",
    EntityControllerGetEntityModelPageGcpDnsManagedZoneRequestFilters = "EntityControllerGetEntityModelPageGcpDnsManagedZoneRequestFilters",
    EntityControllerGetEntityModelPageGcpEntityRequest = "EntityControllerGetEntityModelPageGcpEntityRequest",
    EntityControllerGetEntityModelPageGcpEntityRequestFilters = "EntityControllerGetEntityModelPageGcpEntityRequestFilters",
    EntityControllerGetEntityModelPageGcpFunctionsFunctionRequest = "EntityControllerGetEntityModelPageGcpFunctionsFunctionRequest",
    EntityControllerGetEntityModelPageGcpFunctionsFunctionRequestFilters = "EntityControllerGetEntityModelPageGcpFunctionsFunctionRequestFilters",
    EntityControllerGetEntityModelPageGcpIamPartialServiceAccountRequest = "EntityControllerGetEntityModelPageGcpIamPartialServiceAccountRequest",
    EntityControllerGetEntityModelPageGcpIamPartialServiceAccountRequestFilters = "EntityControllerGetEntityModelPageGcpIamPartialServiceAccountRequestFilters",
    EntityControllerGetEntityModelPageGcpIamRoleBindingRequest = "EntityControllerGetEntityModelPageGcpIamRoleBindingRequest",
    EntityControllerGetEntityModelPageGcpIamRoleBindingRequestFilters = "EntityControllerGetEntityModelPageGcpIamRoleBindingRequestFilters",
    EntityControllerGetEntityModelPageGcpIamRoleRequest = "EntityControllerGetEntityModelPageGcpIamRoleRequest",
    EntityControllerGetEntityModelPageGcpIamRoleRequestFilters = "EntityControllerGetEntityModelPageGcpIamRoleRequestFilters",
    EntityControllerGetEntityModelPageGcpIamServiceAccountRequest = "EntityControllerGetEntityModelPageGcpIamServiceAccountRequest",
    EntityControllerGetEntityModelPageGcpIamServiceAccountRequestFilters = "EntityControllerGetEntityModelPageGcpIamServiceAccountRequestFilters",
    EntityControllerGetEntityModelPageGcpIamWorkforcePoolRequest = "EntityControllerGetEntityModelPageGcpIamWorkforcePoolRequest",
    EntityControllerGetEntityModelPageGcpIamWorkforcePoolRequestFilters = "EntityControllerGetEntityModelPageGcpIamWorkforcePoolRequestFilters",
    EntityControllerGetEntityModelPageGcpIamWorkloadIdentityPoolRequest = "EntityControllerGetEntityModelPageGcpIamWorkloadIdentityPoolRequest",
    EntityControllerGetEntityModelPageGcpIamWorkloadIdentityPoolRequestFilters = "EntityControllerGetEntityModelPageGcpIamWorkloadIdentityPoolRequestFilters",
    EntityControllerGetEntityModelPageGcpKmsKeyRingKeyRequest = "EntityControllerGetEntityModelPageGcpKmsKeyRingKeyRequest",
    EntityControllerGetEntityModelPageGcpKmsKeyRingKeyRequestFilters = "EntityControllerGetEntityModelPageGcpKmsKeyRingKeyRequestFilters",
    EntityControllerGetEntityModelPageGcpKmsKeyRingRequest = "EntityControllerGetEntityModelPageGcpKmsKeyRingRequest",
    EntityControllerGetEntityModelPageGcpKmsKeyRingRequestFilters = "EntityControllerGetEntityModelPageGcpKmsKeyRingRequestFilters",
    EntityControllerGetEntityModelPageGcpKubernetesClusterRoleBindingRequest = "EntityControllerGetEntityModelPageGcpKubernetesClusterRoleBindingRequest",
    EntityControllerGetEntityModelPageGcpKubernetesClusterRoleBindingRequestFilters = "EntityControllerGetEntityModelPageGcpKubernetesClusterRoleBindingRequestFilters",
    EntityControllerGetEntityModelPageGcpKubernetesClusterRoleRequest = "EntityControllerGetEntityModelPageGcpKubernetesClusterRoleRequest",
    EntityControllerGetEntityModelPageGcpKubernetesClusterRoleRequestFilters = "EntityControllerGetEntityModelPageGcpKubernetesClusterRoleRequestFilters",
    EntityControllerGetEntityModelPageGcpKubernetesGroupRequest = "EntityControllerGetEntityModelPageGcpKubernetesGroupRequest",
    EntityControllerGetEntityModelPageGcpKubernetesGroupRequestFilters = "EntityControllerGetEntityModelPageGcpKubernetesGroupRequestFilters",
    EntityControllerGetEntityModelPageGcpKubernetesIngressRequest = "EntityControllerGetEntityModelPageGcpKubernetesIngressRequest",
    EntityControllerGetEntityModelPageGcpKubernetesIngressRequestFilters = "EntityControllerGetEntityModelPageGcpKubernetesIngressRequestFilters",
    EntityControllerGetEntityModelPageGcpKubernetesNamespaceRequest = "EntityControllerGetEntityModelPageGcpKubernetesNamespaceRequest",
    EntityControllerGetEntityModelPageGcpKubernetesNamespaceRequestFilters = "EntityControllerGetEntityModelPageGcpKubernetesNamespaceRequestFilters",
    EntityControllerGetEntityModelPageGcpKubernetesNamespaceResourceRequest = "EntityControllerGetEntityModelPageGcpKubernetesNamespaceResourceRequest",
    EntityControllerGetEntityModelPageGcpKubernetesNamespaceResourceRequestFilters = "EntityControllerGetEntityModelPageGcpKubernetesNamespaceResourceRequestFilters",
    EntityControllerGetEntityModelPageGcpKubernetesNamespaceRoleBindingRequest = "EntityControllerGetEntityModelPageGcpKubernetesNamespaceRoleBindingRequest",
    EntityControllerGetEntityModelPageGcpKubernetesNamespaceRoleBindingRequestFilters = "EntityControllerGetEntityModelPageGcpKubernetesNamespaceRoleBindingRequestFilters",
    EntityControllerGetEntityModelPageGcpKubernetesNamespaceRoleRequest = "EntityControllerGetEntityModelPageGcpKubernetesNamespaceRoleRequest",
    EntityControllerGetEntityModelPageGcpKubernetesNamespaceRoleRequestFilters = "EntityControllerGetEntityModelPageGcpKubernetesNamespaceRoleRequestFilters",
    EntityControllerGetEntityModelPageGcpKubernetesResourceRequest = "EntityControllerGetEntityModelPageGcpKubernetesResourceRequest",
    EntityControllerGetEntityModelPageGcpKubernetesResourceRequestFilters = "EntityControllerGetEntityModelPageGcpKubernetesResourceRequestFilters",
    EntityControllerGetEntityModelPageGcpKubernetesServiceAccountRequest = "EntityControllerGetEntityModelPageGcpKubernetesServiceAccountRequest",
    EntityControllerGetEntityModelPageGcpKubernetesServiceAccountRequestFilters = "EntityControllerGetEntityModelPageGcpKubernetesServiceAccountRequestFilters",
    EntityControllerGetEntityModelPageGcpKubernetesServiceRequest = "EntityControllerGetEntityModelPageGcpKubernetesServiceRequest",
    EntityControllerGetEntityModelPageGcpKubernetesServiceRequestFilters = "EntityControllerGetEntityModelPageGcpKubernetesServiceRequestFilters",
    EntityControllerGetEntityModelPageGcpKubernetesUserRequest = "EntityControllerGetEntityModelPageGcpKubernetesUserRequest",
    EntityControllerGetEntityModelPageGcpKubernetesUserRequestFilters = "EntityControllerGetEntityModelPageGcpKubernetesUserRequestFilters",
    EntityControllerGetEntityModelPageGcpKubernetesWorkloadResourceRequest = "EntityControllerGetEntityModelPageGcpKubernetesWorkloadResourceRequest",
    EntityControllerGetEntityModelPageGcpKubernetesWorkloadResourceRequestFilters = "EntityControllerGetEntityModelPageGcpKubernetesWorkloadResourceRequestFilters",
    EntityControllerGetEntityModelPageGcpLoggingLogSinkRequest = "EntityControllerGetEntityModelPageGcpLoggingLogSinkRequest",
    EntityControllerGetEntityModelPageGcpLoggingLogSinkRequestFilters = "EntityControllerGetEntityModelPageGcpLoggingLogSinkRequestFilters",
    EntityControllerGetEntityModelPageGcpNotebooksInstanceRequest = "EntityControllerGetEntityModelPageGcpNotebooksInstanceRequest",
    EntityControllerGetEntityModelPageGcpNotebooksInstanceRequestFilters = "EntityControllerGetEntityModelPageGcpNotebooksInstanceRequestFilters",
    EntityControllerGetEntityModelPageGcpPubSubSubscriptionRequest = "EntityControllerGetEntityModelPageGcpPubSubSubscriptionRequest",
    EntityControllerGetEntityModelPageGcpPubSubSubscriptionRequestFilters = "EntityControllerGetEntityModelPageGcpPubSubSubscriptionRequestFilters",
    EntityControllerGetEntityModelPageGcpPubSubTopicRequest = "EntityControllerGetEntityModelPageGcpPubSubTopicRequest",
    EntityControllerGetEntityModelPageGcpPubSubTopicRequestFilters = "EntityControllerGetEntityModelPageGcpPubSubTopicRequestFilters",
    EntityControllerGetEntityModelPageGcpResourceRequest = "EntityControllerGetEntityModelPageGcpResourceRequest",
    EntityControllerGetEntityModelPageGcpResourceRequestFilters = "EntityControllerGetEntityModelPageGcpResourceRequestFilters",
    EntityControllerGetEntityModelPageGcpScopeResourceRequest = "EntityControllerGetEntityModelPageGcpScopeResourceRequest",
    EntityControllerGetEntityModelPageGcpScopeResourceRequestEncrypted = "EntityControllerGetEntityModelPageGcpScopeResourceRequestEncrypted",
    EntityControllerGetEntityModelPageGcpScopeResourceRequestFilters = "EntityControllerGetEntityModelPageGcpScopeResourceRequestFilters",
    EntityControllerGetEntityModelPageGcpScopeResourceRequestServiceAccount = "EntityControllerGetEntityModelPageGcpScopeResourceRequestServiceAccount",
    EntityControllerGetEntityModelPageGcpSecretManagerSecretRequest = "EntityControllerGetEntityModelPageGcpSecretManagerSecretRequest",
    EntityControllerGetEntityModelPageGcpSecretManagerSecretRequestFilters = "EntityControllerGetEntityModelPageGcpSecretManagerSecretRequestFilters",
    EntityControllerGetEntityModelPageGcpSpannerInstanceDatabaseRequest = "EntityControllerGetEntityModelPageGcpSpannerInstanceDatabaseRequest",
    EntityControllerGetEntityModelPageGcpSpannerInstanceDatabaseRequestFilters = "EntityControllerGetEntityModelPageGcpSpannerInstanceDatabaseRequestFilters",
    EntityControllerGetEntityModelPageGcpSpannerInstanceRequest = "EntityControllerGetEntityModelPageGcpSpannerInstanceRequest",
    EntityControllerGetEntityModelPageGcpSpannerInstanceRequestFilters = "EntityControllerGetEntityModelPageGcpSpannerInstanceRequestFilters",
    EntityControllerGetEntityModelPageGcpSqlInstanceRequest = "EntityControllerGetEntityModelPageGcpSqlInstanceRequest",
    EntityControllerGetEntityModelPageGcpSqlInstanceRequestFilters = "EntityControllerGetEntityModelPageGcpSqlInstanceRequestFilters",
    EntityControllerGetEntityModelPageGcpStorageBucketRequest = "EntityControllerGetEntityModelPageGcpStorageBucketRequest",
    EntityControllerGetEntityModelPageGcpStorageBucketRequestFilters = "EntityControllerGetEntityModelPageGcpStorageBucketRequestFilters",
    EntityControllerGetEntityModelPageGcpTenantEntityRequest = "EntityControllerGetEntityModelPageGcpTenantEntityRequest",
    EntityControllerGetEntityModelPageGcpTenantEntityRequestFilters = "EntityControllerGetEntityModelPageGcpTenantEntityRequestFilters",
    EntityControllerGetEntityModelPageGcpUnmanagedKubernetesClusterRequest = "EntityControllerGetEntityModelPageGcpUnmanagedKubernetesClusterRequest",
    EntityControllerGetEntityModelPageGcpUnmanagedKubernetesClusterRequestFilters = "EntityControllerGetEntityModelPageGcpUnmanagedKubernetesClusterRequestFilters",
    EntityControllerGetEntityModelPageOciAutoscalingAutoscalingConfigurationRequest = "EntityControllerGetEntityModelPageOciAutoscalingAutoscalingConfigurationRequest",
    EntityControllerGetEntityModelPageOciAutoscalingAutoscalingConfigurationRequestFilters = "EntityControllerGetEntityModelPageOciAutoscalingAutoscalingConfigurationRequestFilters",
    EntityControllerGetEntityModelPageOciBlockStorageBlockVolumeRequest = "EntityControllerGetEntityModelPageOciBlockStorageBlockVolumeRequest",
    EntityControllerGetEntityModelPageOciBlockStorageBlockVolumeRequestFilters = "EntityControllerGetEntityModelPageOciBlockStorageBlockVolumeRequestFilters",
    EntityControllerGetEntityModelPageOciBlockStorageBootVolumeRequest = "EntityControllerGetEntityModelPageOciBlockStorageBootVolumeRequest",
    EntityControllerGetEntityModelPageOciBlockStorageBootVolumeRequestFilters = "EntityControllerGetEntityModelPageOciBlockStorageBootVolumeRequestFilters",
    EntityControllerGetEntityModelPageOciComputeImageRequest = "EntityControllerGetEntityModelPageOciComputeImageRequest",
    EntityControllerGetEntityModelPageOciComputeImageRequestFilters = "EntityControllerGetEntityModelPageOciComputeImageRequestFilters",
    EntityControllerGetEntityModelPageOciComputeInstanceConfigurationRequest = "EntityControllerGetEntityModelPageOciComputeInstanceConfigurationRequest",
    EntityControllerGetEntityModelPageOciComputeInstanceConfigurationRequestFilters = "EntityControllerGetEntityModelPageOciComputeInstanceConfigurationRequestFilters",
    EntityControllerGetEntityModelPageOciComputeInstancePoolRequest = "EntityControllerGetEntityModelPageOciComputeInstancePoolRequest",
    EntityControllerGetEntityModelPageOciComputeInstancePoolRequestFilters = "EntityControllerGetEntityModelPageOciComputeInstancePoolRequestFilters",
    EntityControllerGetEntityModelPageOciComputeInstanceRequest = "EntityControllerGetEntityModelPageOciComputeInstanceRequest",
    EntityControllerGetEntityModelPageOciComputeInstanceRequestFilters = "EntityControllerGetEntityModelPageOciComputeInstanceRequestFilters",
    EntityControllerGetEntityModelPageOciContainerEngineClusterNodePoolRequest = "EntityControllerGetEntityModelPageOciContainerEngineClusterNodePoolRequest",
    EntityControllerGetEntityModelPageOciContainerEngineClusterNodePoolRequestFilters = "EntityControllerGetEntityModelPageOciContainerEngineClusterNodePoolRequestFilters",
    EntityControllerGetEntityModelPageOciContainerEngineClusterRequest = "EntityControllerGetEntityModelPageOciContainerEngineClusterRequest",
    EntityControllerGetEntityModelPageOciContainerEngineClusterRequestFilters = "EntityControllerGetEntityModelPageOciContainerEngineClusterRequestFilters",
    EntityControllerGetEntityModelPageOciDefaultResourceRequest = "EntityControllerGetEntityModelPageOciDefaultResourceRequest",
    EntityControllerGetEntityModelPageOciDefaultResourceRequestFilters = "EntityControllerGetEntityModelPageOciDefaultResourceRequestFilters",
    EntityControllerGetEntityModelPageOciFssFileSystemRequest = "EntityControllerGetEntityModelPageOciFssFileSystemRequest",
    EntityControllerGetEntityModelPageOciFssFileSystemRequestFilters = "EntityControllerGetEntityModelPageOciFssFileSystemRequestFilters",
    EntityControllerGetEntityModelPageOciFssMountTargetExportRequest = "EntityControllerGetEntityModelPageOciFssMountTargetExportRequest",
    EntityControllerGetEntityModelPageOciFssMountTargetExportRequestFilters = "EntityControllerGetEntityModelPageOciFssMountTargetExportRequestFilters",
    EntityControllerGetEntityModelPageOciFssMountTargetRequest = "EntityControllerGetEntityModelPageOciFssMountTargetRequest",
    EntityControllerGetEntityModelPageOciFssMountTargetRequestFilters = "EntityControllerGetEntityModelPageOciFssMountTargetRequestFilters",
    EntityControllerGetEntityModelPageOciIamCredentialRequest = "EntityControllerGetEntityModelPageOciIamCredentialRequest",
    EntityControllerGetEntityModelPageOciIamCredentialRequestFilters = "EntityControllerGetEntityModelPageOciIamCredentialRequestFilters",
    EntityControllerGetEntityModelPageOciIamDomainRequest = "EntityControllerGetEntityModelPageOciIamDomainRequest",
    EntityControllerGetEntityModelPageOciIamDomainRequestFilters = "EntityControllerGetEntityModelPageOciIamDomainRequestFilters",
    EntityControllerGetEntityModelPageOciIamDynamicResourceGroupRequest = "EntityControllerGetEntityModelPageOciIamDynamicResourceGroupRequest",
    EntityControllerGetEntityModelPageOciIamDynamicResourceGroupRequestFilters = "EntityControllerGetEntityModelPageOciIamDynamicResourceGroupRequestFilters",
    EntityControllerGetEntityModelPageOciIamGroupRequest = "EntityControllerGetEntityModelPageOciIamGroupRequest",
    EntityControllerGetEntityModelPageOciIamGroupRequestFilters = "EntityControllerGetEntityModelPageOciIamGroupRequestFilters",
    EntityControllerGetEntityModelPageOciIamPolicyRequest = "EntityControllerGetEntityModelPageOciIamPolicyRequest",
    EntityControllerGetEntityModelPageOciIamPolicyRequestFilters = "EntityControllerGetEntityModelPageOciIamPolicyRequestFilters",
    EntityControllerGetEntityModelPageOciIamPrincipalRequest = "EntityControllerGetEntityModelPageOciIamPrincipalRequest",
    EntityControllerGetEntityModelPageOciIamPrincipalRequestFilters = "EntityControllerGetEntityModelPageOciIamPrincipalRequestFilters",
    EntityControllerGetEntityModelPageOciIamResourceRequest = "EntityControllerGetEntityModelPageOciIamResourceRequest",
    EntityControllerGetEntityModelPageOciIamResourceRequestFilters = "EntityControllerGetEntityModelPageOciIamResourceRequestFilters",
    EntityControllerGetEntityModelPageOciIamUserApiKeyRequest = "EntityControllerGetEntityModelPageOciIamUserApiKeyRequest",
    EntityControllerGetEntityModelPageOciIamUserApiKeyRequestFilters = "EntityControllerGetEntityModelPageOciIamUserApiKeyRequestFilters",
    EntityControllerGetEntityModelPageOciIamUserAuthTokenRequest = "EntityControllerGetEntityModelPageOciIamUserAuthTokenRequest",
    EntityControllerGetEntityModelPageOciIamUserAuthTokenRequestFilters = "EntityControllerGetEntityModelPageOciIamUserAuthTokenRequestFilters",
    EntityControllerGetEntityModelPageOciIamUserDatabasePasswordRequest = "EntityControllerGetEntityModelPageOciIamUserDatabasePasswordRequest",
    EntityControllerGetEntityModelPageOciIamUserDatabasePasswordRequestFilters = "EntityControllerGetEntityModelPageOciIamUserDatabasePasswordRequestFilters",
    EntityControllerGetEntityModelPageOciIamUserRequest = "EntityControllerGetEntityModelPageOciIamUserRequest",
    EntityControllerGetEntityModelPageOciIamUserRequestFilters = "EntityControllerGetEntityModelPageOciIamUserRequestFilters",
    EntityControllerGetEntityModelPageOciIamUserSecretKeyRequest = "EntityControllerGetEntityModelPageOciIamUserSecretKeyRequest",
    EntityControllerGetEntityModelPageOciIamUserSecretKeyRequestFilters = "EntityControllerGetEntityModelPageOciIamUserSecretKeyRequestFilters",
    EntityControllerGetEntityModelPageOciKmsVaultKeyRequest = "EntityControllerGetEntityModelPageOciKmsVaultKeyRequest",
    EntityControllerGetEntityModelPageOciKmsVaultKeyRequestFilters = "EntityControllerGetEntityModelPageOciKmsVaultKeyRequestFilters",
    EntityControllerGetEntityModelPageOciKmsVaultObjectRequest = "EntityControllerGetEntityModelPageOciKmsVaultObjectRequest",
    EntityControllerGetEntityModelPageOciKmsVaultObjectRequestFilters = "EntityControllerGetEntityModelPageOciKmsVaultObjectRequestFilters",
    EntityControllerGetEntityModelPageOciKmsVaultRequest = "EntityControllerGetEntityModelPageOciKmsVaultRequest",
    EntityControllerGetEntityModelPageOciKmsVaultRequestFilters = "EntityControllerGetEntityModelPageOciKmsVaultRequestFilters",
    EntityControllerGetEntityModelPageOciKmsVaultSecretRequest = "EntityControllerGetEntityModelPageOciKmsVaultSecretRequest",
    EntityControllerGetEntityModelPageOciKmsVaultSecretRequestFilters = "EntityControllerGetEntityModelPageOciKmsVaultSecretRequestFilters",
    EntityControllerGetEntityModelPageOciLoadBalancingLoadBalancerRequest = "EntityControllerGetEntityModelPageOciLoadBalancingLoadBalancerRequest",
    EntityControllerGetEntityModelPageOciLoadBalancingLoadBalancerRequestFilters = "EntityControllerGetEntityModelPageOciLoadBalancingLoadBalancerRequestFilters",
    EntityControllerGetEntityModelPageOciLoggingLogGroupRequest = "EntityControllerGetEntityModelPageOciLoggingLogGroupRequest",
    EntityControllerGetEntityModelPageOciLoggingLogGroupRequestFilters = "EntityControllerGetEntityModelPageOciLoggingLogGroupRequestFilters",
    EntityControllerGetEntityModelPageOciNetworkingInternetGatewayRequest = "EntityControllerGetEntityModelPageOciNetworkingInternetGatewayRequest",
    EntityControllerGetEntityModelPageOciNetworkingInternetGatewayRequestFilters = "EntityControllerGetEntityModelPageOciNetworkingInternetGatewayRequestFilters",
    EntityControllerGetEntityModelPageOciNetworkingLocalPeeringGatewayRequest = "EntityControllerGetEntityModelPageOciNetworkingLocalPeeringGatewayRequest",
    EntityControllerGetEntityModelPageOciNetworkingLocalPeeringGatewayRequestFilters = "EntityControllerGetEntityModelPageOciNetworkingLocalPeeringGatewayRequestFilters",
    EntityControllerGetEntityModelPageOciNetworkingNatGatewayRequest = "EntityControllerGetEntityModelPageOciNetworkingNatGatewayRequest",
    EntityControllerGetEntityModelPageOciNetworkingNatGatewayRequestFilters = "EntityControllerGetEntityModelPageOciNetworkingNatGatewayRequestFilters",
    EntityControllerGetEntityModelPageOciNetworkingNetworkLoadBalancerRequest = "EntityControllerGetEntityModelPageOciNetworkingNetworkLoadBalancerRequest",
    EntityControllerGetEntityModelPageOciNetworkingNetworkLoadBalancerRequestFilters = "EntityControllerGetEntityModelPageOciNetworkingNetworkLoadBalancerRequestFilters",
    EntityControllerGetEntityModelPageOciNetworkingNetworkSecurityGroupRequest = "EntityControllerGetEntityModelPageOciNetworkingNetworkSecurityGroupRequest",
    EntityControllerGetEntityModelPageOciNetworkingNetworkSecurityGroupRequestFilters = "EntityControllerGetEntityModelPageOciNetworkingNetworkSecurityGroupRequestFilters",
    EntityControllerGetEntityModelPageOciNetworkingRouteTableRequest = "EntityControllerGetEntityModelPageOciNetworkingRouteTableRequest",
    EntityControllerGetEntityModelPageOciNetworkingRouteTableRequestFilters = "EntityControllerGetEntityModelPageOciNetworkingRouteTableRequestFilters",
    EntityControllerGetEntityModelPageOciNetworkingSecurityListRequest = "EntityControllerGetEntityModelPageOciNetworkingSecurityListRequest",
    EntityControllerGetEntityModelPageOciNetworkingSecurityListRequestFilters = "EntityControllerGetEntityModelPageOciNetworkingSecurityListRequestFilters",
    EntityControllerGetEntityModelPageOciNetworkingServiceGatewayRequest = "EntityControllerGetEntityModelPageOciNetworkingServiceGatewayRequest",
    EntityControllerGetEntityModelPageOciNetworkingServiceGatewayRequestFilters = "EntityControllerGetEntityModelPageOciNetworkingServiceGatewayRequestFilters",
    EntityControllerGetEntityModelPageOciNetworkingSubnetRequest = "EntityControllerGetEntityModelPageOciNetworkingSubnetRequest",
    EntityControllerGetEntityModelPageOciNetworkingSubnetRequestFilters = "EntityControllerGetEntityModelPageOciNetworkingSubnetRequestFilters",
    EntityControllerGetEntityModelPageOciNetworkingVcnRequest = "EntityControllerGetEntityModelPageOciNetworkingVcnRequest",
    EntityControllerGetEntityModelPageOciNetworkingVcnRequestFilters = "EntityControllerGetEntityModelPageOciNetworkingVcnRequestFilters",
    EntityControllerGetEntityModelPageOciNetworkingVirtualNetworkInterfaceCardRequest = "EntityControllerGetEntityModelPageOciNetworkingVirtualNetworkInterfaceCardRequest",
    EntityControllerGetEntityModelPageOciNetworkingVirtualNetworkInterfaceCardRequestFilters = "EntityControllerGetEntityModelPageOciNetworkingVirtualNetworkInterfaceCardRequestFilters",
    EntityControllerGetEntityModelPageOciObjectStorageBucketRequest = "EntityControllerGetEntityModelPageOciObjectStorageBucketRequest",
    EntityControllerGetEntityModelPageOciObjectStorageBucketRequestFilters = "EntityControllerGetEntityModelPageOciObjectStorageBucketRequestFilters",
    EntityControllerGetEntityModelPageOciResourceRequest = "EntityControllerGetEntityModelPageOciResourceRequest",
    EntityControllerGetEntityModelPageOciResourceRequestEncrypted = "EntityControllerGetEntityModelPageOciResourceRequestEncrypted",
    EntityControllerGetEntityModelPageOciResourceRequestFilters = "EntityControllerGetEntityModelPageOciResourceRequestFilters",
    EntityControllerGetEntityModelPageOciTenantEntityRequest = "EntityControllerGetEntityModelPageOciTenantEntityRequest",
    EntityControllerGetEntityModelPageOciTenantEntityRequestFilters = "EntityControllerGetEntityModelPageOciTenantEntityRequestFilters",
    EntityControllerGetEntityModelPageOktaDefaultDirectoryEntityRequest = "EntityControllerGetEntityModelPageOktaDefaultDirectoryEntityRequest",
    EntityControllerGetEntityModelPageOktaDefaultDirectoryEntityRequestFilters = "EntityControllerGetEntityModelPageOktaDefaultDirectoryEntityRequestFilters",
    EntityControllerGetEntityModelPageOktaDirectoryEntityRequest = "EntityControllerGetEntityModelPageOktaDirectoryEntityRequest",
    EntityControllerGetEntityModelPageOktaDirectoryEntityRequestFilters = "EntityControllerGetEntityModelPageOktaDirectoryEntityRequestFilters",
    EntityControllerGetEntityModelPageOktaDirectoryGroupRequest = "EntityControllerGetEntityModelPageOktaDirectoryGroupRequest",
    EntityControllerGetEntityModelPageOktaDirectoryGroupRequestFilters = "EntityControllerGetEntityModelPageOktaDirectoryGroupRequestFilters",
    EntityControllerGetEntityModelPageOktaDirectoryPrincipalRequest = "EntityControllerGetEntityModelPageOktaDirectoryPrincipalRequest",
    EntityControllerGetEntityModelPageOktaDirectoryPrincipalRequestFilters = "EntityControllerGetEntityModelPageOktaDirectoryPrincipalRequestFilters",
    EntityControllerGetEntityModelPageOktaDirectoryUserRequest = "EntityControllerGetEntityModelPageOktaDirectoryUserRequest",
    EntityControllerGetEntityModelPageOktaDirectoryUserRequestFilters = "EntityControllerGetEntityModelPageOktaDirectoryUserRequestFilters",
    EntityControllerGetEntityModelPageOneLoginDefaultDirectoryEntityRequest = "EntityControllerGetEntityModelPageOneLoginDefaultDirectoryEntityRequest",
    EntityControllerGetEntityModelPageOneLoginDefaultDirectoryEntityRequestFilters = "EntityControllerGetEntityModelPageOneLoginDefaultDirectoryEntityRequestFilters",
    EntityControllerGetEntityModelPageOneLoginDirectoryGroupRequest = "EntityControllerGetEntityModelPageOneLoginDirectoryGroupRequest",
    EntityControllerGetEntityModelPageOneLoginDirectoryGroupRequestFilters = "EntityControllerGetEntityModelPageOneLoginDirectoryGroupRequestFilters",
    EntityControllerGetEntityModelPageOneLoginDirectoryRoleRequest = "EntityControllerGetEntityModelPageOneLoginDirectoryRoleRequest",
    EntityControllerGetEntityModelPageOneLoginDirectoryRoleRequestFilters = "EntityControllerGetEntityModelPageOneLoginDirectoryRoleRequestFilters",
    EntityControllerGetEntityModelPageOneLoginDirectoryUserRequest = "EntityControllerGetEntityModelPageOneLoginDirectoryUserRequest",
    EntityControllerGetEntityModelPageOneLoginDirectoryUserRequestFilters = "EntityControllerGetEntityModelPageOneLoginDirectoryUserRequestFilters",
    EntityControllerGetEntityModelPageOpContainerImageRequest = "EntityControllerGetEntityModelPageOpContainerImageRequest",
    EntityControllerGetEntityModelPageOpContainerImageRequestFilters = "EntityControllerGetEntityModelPageOpContainerImageRequestFilters",
    EntityControllerGetEntityModelPageOpDefaultKubernetesNamespaceResourceRequest = "EntityControllerGetEntityModelPageOpDefaultKubernetesNamespaceResourceRequest",
    EntityControllerGetEntityModelPageOpDefaultKubernetesNamespaceResourceRequestFilters = "EntityControllerGetEntityModelPageOpDefaultKubernetesNamespaceResourceRequestFilters",
    EntityControllerGetEntityModelPageOpDefaultKubernetesResourceRequest = "EntityControllerGetEntityModelPageOpDefaultKubernetesResourceRequest",
    EntityControllerGetEntityModelPageOpDefaultKubernetesResourceRequestFilters = "EntityControllerGetEntityModelPageOpDefaultKubernetesResourceRequestFilters",
    EntityControllerGetEntityModelPageOpKubernetesClusterRoleBindingRequest = "EntityControllerGetEntityModelPageOpKubernetesClusterRoleBindingRequest",
    EntityControllerGetEntityModelPageOpKubernetesClusterRoleBindingRequestFilters = "EntityControllerGetEntityModelPageOpKubernetesClusterRoleBindingRequestFilters",
    EntityControllerGetEntityModelPageOpKubernetesClusterRoleRequest = "EntityControllerGetEntityModelPageOpKubernetesClusterRoleRequest",
    EntityControllerGetEntityModelPageOpKubernetesClusterRoleRequestFilters = "EntityControllerGetEntityModelPageOpKubernetesClusterRoleRequestFilters",
    EntityControllerGetEntityModelPageOpKubernetesGroupRequest = "EntityControllerGetEntityModelPageOpKubernetesGroupRequest",
    EntityControllerGetEntityModelPageOpKubernetesGroupRequestFilters = "EntityControllerGetEntityModelPageOpKubernetesGroupRequestFilters",
    EntityControllerGetEntityModelPageOpKubernetesIngressRequest = "EntityControllerGetEntityModelPageOpKubernetesIngressRequest",
    EntityControllerGetEntityModelPageOpKubernetesIngressRequestFilters = "EntityControllerGetEntityModelPageOpKubernetesIngressRequestFilters",
    EntityControllerGetEntityModelPageOpKubernetesNamespaceRequest = "EntityControllerGetEntityModelPageOpKubernetesNamespaceRequest",
    EntityControllerGetEntityModelPageOpKubernetesNamespaceRequestFilters = "EntityControllerGetEntityModelPageOpKubernetesNamespaceRequestFilters",
    EntityControllerGetEntityModelPageOpKubernetesNamespaceResourceRequest = "EntityControllerGetEntityModelPageOpKubernetesNamespaceResourceRequest",
    EntityControllerGetEntityModelPageOpKubernetesNamespaceResourceRequestFilters = "EntityControllerGetEntityModelPageOpKubernetesNamespaceResourceRequestFilters",
    EntityControllerGetEntityModelPageOpKubernetesNamespaceRoleBindingRequest = "EntityControllerGetEntityModelPageOpKubernetesNamespaceRoleBindingRequest",
    EntityControllerGetEntityModelPageOpKubernetesNamespaceRoleBindingRequestFilters = "EntityControllerGetEntityModelPageOpKubernetesNamespaceRoleBindingRequestFilters",
    EntityControllerGetEntityModelPageOpKubernetesNamespaceRoleRequest = "EntityControllerGetEntityModelPageOpKubernetesNamespaceRoleRequest",
    EntityControllerGetEntityModelPageOpKubernetesNamespaceRoleRequestFilters = "EntityControllerGetEntityModelPageOpKubernetesNamespaceRoleRequestFilters",
    EntityControllerGetEntityModelPageOpKubernetesResourceRequest = "EntityControllerGetEntityModelPageOpKubernetesResourceRequest",
    EntityControllerGetEntityModelPageOpKubernetesResourceRequestFilters = "EntityControllerGetEntityModelPageOpKubernetesResourceRequestFilters",
    EntityControllerGetEntityModelPageOpKubernetesServiceAccountRequest = "EntityControllerGetEntityModelPageOpKubernetesServiceAccountRequest",
    EntityControllerGetEntityModelPageOpKubernetesServiceAccountRequestFilters = "EntityControllerGetEntityModelPageOpKubernetesServiceAccountRequestFilters",
    EntityControllerGetEntityModelPageOpKubernetesServiceRequest = "EntityControllerGetEntityModelPageOpKubernetesServiceRequest",
    EntityControllerGetEntityModelPageOpKubernetesServiceRequestFilters = "EntityControllerGetEntityModelPageOpKubernetesServiceRequestFilters",
    EntityControllerGetEntityModelPageOpKubernetesUserRequest = "EntityControllerGetEntityModelPageOpKubernetesUserRequest",
    EntityControllerGetEntityModelPageOpKubernetesUserRequestFilters = "EntityControllerGetEntityModelPageOpKubernetesUserRequestFilters",
    EntityControllerGetEntityModelPageOpKubernetesWorkloadResourceRequest = "EntityControllerGetEntityModelPageOpKubernetesWorkloadResourceRequest",
    EntityControllerGetEntityModelPageOpKubernetesWorkloadResourceRequestFilters = "EntityControllerGetEntityModelPageOpKubernetesWorkloadResourceRequestFilters",
    EntityControllerGetEntityModelPageOpResourceRequest = "EntityControllerGetEntityModelPageOpResourceRequest",
    EntityControllerGetEntityModelPageOpResourceRequestFilters = "EntityControllerGetEntityModelPageOpResourceRequestFilters",
    EntityControllerGetEntityModelPageOpUnmanagedKubernetesClusterRequest = "EntityControllerGetEntityModelPageOpUnmanagedKubernetesClusterRequest",
    EntityControllerGetEntityModelPageOpUnmanagedKubernetesClusterRequestFilters = "EntityControllerGetEntityModelPageOpUnmanagedKubernetesClusterRequestFilters",
    EntityControllerGetEntityModelPageOpVirtualMachineRequest = "EntityControllerGetEntityModelPageOpVirtualMachineRequest",
    EntityControllerGetEntityModelPageOpVirtualMachineRequestFilters = "EntityControllerGetEntityModelPageOpVirtualMachineRequestFilters",
    EntityControllerGetEntityModelPagePingIdentityDefaultDirectoryEntityRequest = "EntityControllerGetEntityModelPagePingIdentityDefaultDirectoryEntityRequest",
    EntityControllerGetEntityModelPagePingIdentityDefaultDirectoryEntityRequestFilters = "EntityControllerGetEntityModelPagePingIdentityDefaultDirectoryEntityRequestFilters",
    EntityControllerGetEntityModelPagePingIdentityDirectoryEntityRequest = "EntityControllerGetEntityModelPagePingIdentityDirectoryEntityRequest",
    EntityControllerGetEntityModelPagePingIdentityDirectoryEntityRequestFilters = "EntityControllerGetEntityModelPagePingIdentityDirectoryEntityRequestFilters",
    EntityControllerGetEntityModelPagePingIdentityDirectoryGroupRequest = "EntityControllerGetEntityModelPagePingIdentityDirectoryGroupRequest",
    EntityControllerGetEntityModelPagePingIdentityDirectoryGroupRequestFilters = "EntityControllerGetEntityModelPagePingIdentityDirectoryGroupRequestFilters",
    EntityControllerGetEntityModelPagePingIdentityDirectoryUserRequest = "EntityControllerGetEntityModelPagePingIdentityDirectoryUserRequest",
    EntityControllerGetEntityModelPagePingIdentityDirectoryUserRequestFilters = "EntityControllerGetEntityModelPagePingIdentityDirectoryUserRequestFilters",
    EntityControllerGetEntityModelPageRequest = "EntityControllerGetEntityModelPageRequest",
    EntityControllerGetEntityModelPageRequestFilters = "EntityControllerGetEntityModelPageRequestFilters",
    EntityControllerGetEntityModelPageRequestSort = "EntityControllerGetEntityModelPageRequestSort",
    EntityControllerGetEntityModelPageResponse = "EntityControllerGetEntityModelPageResponse",
    EntityControllerGetEntityModelPropertyFilterItemPageRequest = "EntityControllerGetEntityModelPropertyFilterItemPageRequest",
    EntityControllerGetEntityModelsRequest = "EntityControllerGetEntityModelsRequest",
    EntityControllerGetEntityModelsResponse = "EntityControllerGetEntityModelsResponse",
    EntityControllerGetEntityModelSummaryResponse = "EntityControllerGetEntityModelSummaryResponse",
    EntityControllerGetEntitySearchableReferencesRequest = "EntityControllerGetEntitySearchableReferencesRequest",
    EntityControllerGetEntitySearchableReferencesResponse = "EntityControllerGetEntitySearchableReferencesResponse",
    EntityControllerGetEntitySnapshotsRequest = "EntityControllerGetEntitySnapshotsRequest",
    EntityControllerGetEntitySnapshotsResponse = "EntityControllerGetEntitySnapshotsResponse",
    EntityControllerGetEntityTypeEntitySearchableReferencesRequest = "EntityControllerGetEntityTypeEntitySearchableReferencesRequest",
    EntityControllerGetEntityTypeEntitySearchableReferencesResponse = "EntityControllerGetEntityTypeEntitySearchableReferencesResponse",
    EntityControllerGetEntityTypeMetadataModelsResponse = "EntityControllerGetEntityTypeMetadataModelsResponse",
    EntityControllerGetExcessivePermissionMultiRiskPrincipalIdsRequest = "EntityControllerGetExcessivePermissionMultiRiskPrincipalIdsRequest",
    EntityControllerGetExcessivePermissionMultiRiskPrincipalIdsResponse = "EntityControllerGetExcessivePermissionMultiRiskPrincipalIdsResponse",
    EntityControllerGetGcpAccessDataRequestFilters = "EntityControllerGetGcpAccessDataRequestFilters",
    EntityControllerGetGcpAccessGraphRequest = "EntityControllerGetGcpAccessGraphRequest",
    EntityControllerGetGcpAccessListPermissionActionsRequest = "EntityControllerGetGcpAccessListPermissionActionsRequest",
    EntityControllerGetGcpBigQueryDatasetTableIdsRequest = "EntityControllerGetGcpBigQueryDatasetTableIdsRequest",
    EntityControllerGetGcpBigQueryDatasetTableIdsResponse = "EntityControllerGetGcpBigQueryDatasetTableIdsResponse",
    EntityControllerGetGcpEntityExcessivePermissionCustomEvaluationDataRequest = "EntityControllerGetGcpEntityExcessivePermissionCustomEvaluationDataRequest",
    EntityControllerGetGcpNetworkGraphRequest = "EntityControllerGetGcpNetworkGraphRequest",
    EntityControllerGetGcpNetworkGraphRequestFilters = "EntityControllerGetGcpNetworkGraphRequestFilters",
    EntityControllerGetGcpSpannerInstanceDatabaseIdsRequest = "EntityControllerGetGcpSpannerInstanceDatabaseIdsRequest",
    EntityControllerGetGcpSpannerInstanceDatabaseIdsResponse = "EntityControllerGetGcpSpannerInstanceDatabaseIdsResponse",
    EntityControllerGetKubernetesSystemNamespaceIdsRequest = "EntityControllerGetKubernetesSystemNamespaceIdsRequest",
    EntityControllerGetKubernetesSystemNamespaceIdsResponse = "EntityControllerGetKubernetesSystemNamespaceIdsResponse",
    EntityControllerGetNetworkGraphRequest = "EntityControllerGetNetworkGraphRequest",
    EntityControllerGetNetworkGraphRequestFilters = "EntityControllerGetNetworkGraphRequestFilters",
    EntityControllerGetNetworkGraphResponse = "EntityControllerGetNetworkGraphResponse",
    EntityControllerGetNetworkGraphResponseResult = "EntityControllerGetNetworkGraphResponseResult",
    EntityControllerGetPrincipalIdToAccessMapRequest = "EntityControllerGetPrincipalIdToAccessMapRequest",
    EntityControllerGetPrincipalIdToModelAccessMapResponse = "EntityControllerGetPrincipalIdToModelAccessMapResponse",
    EntityControllerGetRegionModelsResponse = "EntityControllerGetRegionModelsResponse",
    EntityControllerGetSummaryRequest = "EntityControllerGetSummaryRequest",
    EntityControllerGetSummaryResponse = "EntityControllerGetSummaryResponse",
    EntityControllerSearchAllEntityModelsRequest = "EntityControllerSearchAllEntityModelsRequest",
    EntityControllerSearchAllEntityModelsResponse = "EntityControllerSearchAllEntityModelsResponse",
    EntityControllerSearchEntityIdsPermissionRequest = "EntityControllerSearchEntityIdsPermissionRequest",
    EntityControllerSearchEntityIdsPermissionRequestType = "EntityControllerSearchEntityIdsPermissionRequestType",
    EntityControllerSearchEntityIdsPrincipalAccessRequest = "EntityControllerSearchEntityIdsPrincipalAccessRequest",
    EntityControllerSearchEntityIdsPrincipalExcessivePermissionRequest = "EntityControllerSearchEntityIdsPrincipalExcessivePermissionRequest",
    EntityControllerSearchEntityIdsPrincipalPermissionRequest = "EntityControllerSearchEntityIdsPrincipalPermissionRequest",
    EntityControllerSearchEntityIdsRequest = "EntityControllerSearchEntityIdsRequest",
    EntityControllerSearchEntityIdsResponse = "EntityControllerSearchEntityIdsResponse",
    EntityControllerSearchEntityIdsTypeRequest = "EntityControllerSearchEntityIdsTypeRequest",
    EntityControllerSearchEntityModelsAwsIamManagedPolicyRequest = "EntityControllerSearchEntityModelsAwsIamManagedPolicyRequest",
    EntityControllerSearchEntityModelsAzurePermissionEvaluationRequest = "EntityControllerSearchEntityModelsAzurePermissionEvaluationRequest",
    EntityControllerSearchEntityModelsDirectoryEntityTypeRequest = "EntityControllerSearchEntityModelsDirectoryEntityTypeRequest",
    EntityControllerSearchEntityModelsGcpPermissionEvaluationRequest = "EntityControllerSearchEntityModelsGcpPermissionEvaluationRequest",
    EntityControllerSearchEntityModelsPermissionManagementAadDirectoryGroupRequest = "EntityControllerSearchEntityModelsPermissionManagementAadDirectoryGroupRequest",
    EntityControllerSearchEntityModelsPermissionManagementAwsSsoPermissionSetRequest = "EntityControllerSearchEntityModelsPermissionManagementAwsSsoPermissionSetRequest",
    EntityControllerSearchEntityModelsPermissionManagementAzureAuthorizationRoleDefinitionRequest = "EntityControllerSearchEntityModelsPermissionManagementAzureAuthorizationRoleDefinitionRequest",
    EntityControllerSearchEntityModelsPermissionManagementGcpIamRoleRequest = "EntityControllerSearchEntityModelsPermissionManagementGcpIamRoleRequest",
    EntityControllerSearchEntityModelsPermissionManagementOktaDirectoryGroupRequest = "EntityControllerSearchEntityModelsPermissionManagementOktaDirectoryGroupRequest",
    EntityControllerSearchEntityModelsPermissionManagementPrincipalRequest = "EntityControllerSearchEntityModelsPermissionManagementPrincipalRequest",
    EntityControllerSearchEntityModelsPrincipalPermissionActionResourceRequest = "EntityControllerSearchEntityModelsPrincipalPermissionActionResourceRequest",
    EntityControllerSearchEntityModelsRequest = "EntityControllerSearchEntityModelsRequest",
    EntityControllerSearchEntityModelsResponse = "EntityControllerSearchEntityModelsResponse",
    EntityControllerSearchEntityModelsTypeRequest = "EntityControllerSearchEntityModelsTypeRequest",
    EntityControllerUpdateConfigurationRequest = "EntityControllerUpdateConfigurationRequest",
    EntityDagNode = "EntityDagNode",
    EntityGcpAccessReportDefinitionConfiguration = "EntityGcpAccessReportDefinitionConfiguration",
    EntityGroupDefinitionConfiguration = "EntityGroupDefinitionConfiguration",
    EntityIdMatchCondition = "EntityIdMatchCondition",
    EntityManualCustomAttributeDefinitionUpdateAuditEvent = "EntityManualCustomAttributeDefinitionUpdateAuditEvent",
    EntityManualCustomAttributeDefinitionUpdateAuditEventModel = "EntityManualCustomAttributeDefinitionUpdateAuditEventModel",
    EntityMatchCondition = "EntityMatchCondition",
    EntityMatchConditionCollectionOperator = "EntityMatchConditionCollectionOperator",
    EntityMatchConditionGroupMembershipTypeOperator = "EntityMatchConditionGroupMembershipTypeOperator",
    EntityModel = "EntityModel",
    EntityModelAttributes = "EntityModelAttributes",
    EntityModelFilters = "EntityModelFilters",
    EntityModelProperty = "EntityModelProperty",
    EntityModelRisks = "EntityModelRisks",
    EntityModelRisksRisks = "EntityModelRisksRisks",
    EntityModelSummary = "EntityModelSummary",
    EntityNamePatternMatchCondition = "EntityNamePatternMatchCondition",
    EntityNetworkAccessFiltersSection = "EntityNetworkAccessFiltersSection",
    EntityPrincipalReferenceProperty = "EntityPrincipalReferenceProperty",
    EntityPrincipalReferencePropertyMatchCondition = "EntityPrincipalReferencePropertyMatchCondition",
    EntityProfile = "EntityProfile",
    EntityProperty = "EntityProperty",
    EntityPropertyDefinitionAuditEvent = "EntityPropertyDefinitionAuditEvent",
    EntityPropertyDefinitionConfiguration = "EntityPropertyDefinitionConfiguration",
    EntityPropertyDefinitionCreationAuditEvent = "EntityPropertyDefinitionCreationAuditEvent",
    EntityPropertyDefinitionDeletionAuditEvent = "EntityPropertyDefinitionDeletionAuditEvent",
    EntityPropertyDefinitionUpdateAuditEvent = "EntityPropertyDefinitionUpdateAuditEvent",
    EntityPropertyIdentifier = "EntityPropertyIdentifier",
    EntityPropertyMatchCondition = "EntityPropertyMatchCondition",
    EntityPropertyMatchConditionBase = "EntityPropertyMatchConditionBase",
    EntityPropertyPrincipalReferenceValue = "EntityPropertyPrincipalReferenceValue",
    EntityPropertyStringValue = "EntityPropertyStringValue",
    EntityPropertyType = "EntityPropertyType",
    EntityPropertyValue = "EntityPropertyValue",
    EntityPropertyValueAutomaticSource = "EntityPropertyValueAutomaticSource",
    EntityPropertyValueManualSource = "EntityPropertyValueManualSource",
    EntityPropertyValueSource = "EntityPropertyValueSource",
    EntityPropertyValueType = "EntityPropertyValueType",
    EntityReference = "EntityReference",
    EntityReferenceData = "EntityReferenceData",
    EntityRisk = "EntityRisk",
    EntitySearchableEventReference = "EntitySearchableEventReference",
    EntitySearchableIdReference = "EntitySearchableIdReference",
    EntitySearchableReference = "EntitySearchableReference",
    EntitySearchableReferenceBase = "EntitySearchableReferenceBase",
    EntitySensitiveMatchCondition = "EntitySensitiveMatchCondition",
    EntitySensitivityUpdateAuditEvent = "EntitySensitivityUpdateAuditEvent",
    EntitySensitivityUpdateAuditEventModel = "EntitySensitivityUpdateAuditEventModel",
    EntitySnapshot = "EntitySnapshot",
    EntitySnapshotChange = "EntitySnapshotChange",
    EntitySnapshotControllerGetSnapshotsRequest = "EntitySnapshotControllerGetSnapshotsRequest",
    EntitySnapshotControllerGetSnapshotsResponse = "EntitySnapshotControllerGetSnapshotsResponse",
    EntityStateFragment = "EntityStateFragment",
    EntityStateNetwork = "EntityStateNetwork",
    EntityStringProperty = "EntityStringProperty",
    EntityStringPropertyMatchCondition = "EntityStringPropertyMatchCondition",
    EntityTagMatchCondition = "EntityTagMatchCondition",
    EntityTenantIdMatchCondition = "EntityTenantIdMatchCondition",
    EntityTypeMetadata = "EntityTypeMetadata",
    EntityTypeMetadataModel = "EntityTypeMetadataModel",
    EntityTypeNameMatchCondition = "EntityTypeNameMatchCondition",
    EnvironmentType = "EnvironmentType",
    Event = "Event",
    EventAnalysisServiceConfiguration = "EventAnalysisServiceConfiguration",
    EventControllerGetEntityEventFiltersRequest = "EventControllerGetEntityEventFiltersRequest",
    EventControllerGetEntityEventModelPageRequest = "EventControllerGetEntityEventModelPageRequest",
    EventControllerGetEventFilterItemPageRequest = "EventControllerGetEventFilterItemPageRequest",
    EventControllerGetEventFilterItemPageResponse = "EventControllerGetEventFilterItemPageResponse",
    EventControllerGetEventFilterItemPageResponseBase = "EventControllerGetEventFilterItemPageResponseBase",
    EventControllerGetEventFiltersRequest = "EventControllerGetEventFiltersRequest",
    EventControllerGetEventFiltersResponse = "EventControllerGetEventFiltersResponse",
    EventControllerGetEventModelCountResponse = "EventControllerGetEventModelCountResponse",
    EventControllerGetEventModelPageRequest = "EventControllerGetEventModelPageRequest",
    EventControllerGetEventModelPageRequestFilters = "EventControllerGetEventModelPageRequestFilters",
    EventControllerGetEventModelPageResponse = "EventControllerGetEventModelPageResponse",
    EventControllerGetEventModelsRequest = "EventControllerGetEventModelsRequest",
    EventControllerGetEventModelsResponse = "EventControllerGetEventModelsResponse",
    EventControllerGetRiskEventFiltersRequest = "EventControllerGetRiskEventFiltersRequest",
    EventControllerGetRiskEventModelPageRequest = "EventControllerGetRiskEventModelPageRequest",
    EventControllerGetTenantEventFiltersRequest = "EventControllerGetTenantEventFiltersRequest",
    EventControllerGetTenantEventModelPageRequest = "EventControllerGetTenantEventModelPageRequest",
    EventModel = "EventModel",
    EventModelProperty = "EventModelProperty",
    EventsReportDefinitionConfiguration = "EventsReportDefinitionConfiguration",
    ExportRelatedEntityModel = "ExportRelatedEntityModel",
    FeatureConfigurationSection = "FeatureConfigurationSection",
    FeatureName = "FeatureName",
    FederationEdgeModel = "FederationEdgeModel",
    FederationEdgeModelDestinationEntity = "FederationEdgeModelDestinationEntity",
    FederationEdgeModelEntity = "FederationEdgeModelEntity",
    FederationEdgeModelSourceEntity = "FederationEdgeModelSourceEntity",
    FileControllerGetFileModelsRequest = "FileControllerGetFileModelsRequest",
    FileControllerGetFileModelsResponse = "FileControllerGetFileModelsResponse",
    FilterItems = "FilterItems",
    FilterTimeRange = "FilterTimeRange",
    FolderAuditEvent = "FolderAuditEvent",
    FolderConfiguration = "FolderConfiguration",
    FolderConfigurationOrganization = "FolderConfigurationOrganization",
    FolderControllerDeleteFolderRequest = "FolderControllerDeleteFolderRequest",
    FolderControllerInsertFolderRequest = "FolderControllerInsertFolderRequest",
    FolderControllerInsertFolderResponse = "FolderControllerInsertFolderResponse",
    FolderControllerUpdateFolderRequest = "FolderControllerUpdateFolderRequest",
    FolderControllerUpdateFolderResponse = "FolderControllerUpdateFolderResponse",
    FolderCreationAuditEvent = "FolderCreationAuditEvent",
    FolderDeletionAuditEvent = "FolderDeletionAuditEvent",
    FolderUpdateAuditEvent = "FolderUpdateAuditEvent",
    GciChange = "GciChange",
    GciDeletePrincipalGroupMembershipChange = "GciDeletePrincipalGroupMembershipChange",
    GciDirectoryDirectoryRole = "GciDirectoryDirectoryRole",
    GciDirectoryDirectoryRoleAssignment = "GciDirectoryDirectoryRoleAssignment",
    GciDirectoryDirectoryRoleAssignmentModel = "GciDirectoryDirectoryRoleAssignmentModel",
    GciDirectoryDirectoryRoleAssignmentScopeType = "GciDirectoryDirectoryRoleAssignmentScopeType",
    GciDirectoryDirectoryRoleEntity = "GciDirectoryDirectoryRoleEntity",
    GciDirectoryDirectoryRoleModel = "GciDirectoryDirectoryRoleModel",
    GciDirectoryDirectoryRoleType = "GciDirectoryDirectoryRoleType",
    GciDirectoryEntity = "GciDirectoryEntity",
    GciDirectoryEntityModel = "GciDirectoryEntityModel",
    GciDirectoryGroup = "GciDirectoryGroup",
    GciDirectoryGroupInactiveRisk = "GciDirectoryGroupInactiveRisk",
    GciDirectoryGroupInactiveRiskModel = "GciDirectoryGroupInactiveRiskModel",
    GciDirectoryGroupInactiveRiskPolicyConfiguration = "GciDirectoryGroupInactiveRiskPolicyConfiguration",
    GciDirectoryGroupMemberPrincipalRole = "GciDirectoryGroupMemberPrincipalRole",
    GciDirectoryGroupMembershipEligibilityConfiguration = "GciDirectoryGroupMembershipEligibilityConfiguration",
    GciDirectoryGroupMembershipRequest = "GciDirectoryGroupMembershipRequest",
    GciDirectoryGroupModel = "GciDirectoryGroupModel",
    GciDirectoryGroupProfile = "GciDirectoryGroupProfile",
    GciDirectoryGroupProfileIdentitiesInactive = "GciDirectoryGroupProfileIdentitiesInactive",
    GciDirectoryGroupType = "GciDirectoryGroupType",
    GciDirectoryOrganizationalUnit = "GciDirectoryOrganizationalUnit",
    GciDirectoryOrganizationalUnitModel = "GciDirectoryOrganizationalUnitModel",
    GciDirectoryPartialEntity = "GciDirectoryPartialEntity",
    GciDirectoryPartialEntityModel = "GciDirectoryPartialEntityModel",
    GciDirectoryPartialGroup = "GciDirectoryPartialGroup",
    GciDirectoryPartialGroupModel = "GciDirectoryPartialGroupModel",
    GciDirectoryPartialPrincipal = "GciDirectoryPartialPrincipal",
    GciDirectoryPartialPrincipalModel = "GciDirectoryPartialPrincipalModel",
    GciDirectoryPartialUser = "GciDirectoryPartialUser",
    GciDirectoryPartialUserModel = "GciDirectoryPartialUserModel",
    GciDirectoryPartialUserModelFilters = "GciDirectoryPartialUserModelFilters",
    GciDirectoryPartialUserProfile = "GciDirectoryPartialUserProfile",
    GciDirectoryPrincipal = "GciDirectoryPrincipal",
    GciDirectoryPrincipalModel = "GciDirectoryPrincipalModel",
    GciDirectoryPrincipalModelFilters = "GciDirectoryPrincipalModelFilters",
    GciDirectorySamlSsoProfile = "GciDirectorySamlSsoProfile",
    GciDirectorySamlSsoProfileModel = "GciDirectorySamlSsoProfileModel",
    GciDirectorySpecialGroup = "GciDirectorySpecialGroup",
    GciDirectorySpecialGroupModel = "GciDirectorySpecialGroupModel",
    GciDirectorySpecialGroupType = "GciDirectorySpecialGroupType",
    GciDirectorySsoProfileAssignment = "GciDirectorySsoProfileAssignment",
    GciDirectorySsoProfileAssignmentModel = "GciDirectorySsoProfileAssignmentModel",
    GciDirectorySsoProfileAssignmentStatus = "GciDirectorySsoProfileAssignmentStatus",
    GciDirectoryUser = "GciDirectoryUser",
    GciDirectoryUserEventSyncerConfigurationSection = "GciDirectoryUserEventSyncerConfigurationSection",
    GciDirectoryUserInactiveRisk = "GciDirectoryUserInactiveRisk",
    GciDirectoryUserInactiveRiskModel = "GciDirectoryUserInactiveRiskModel",
    GciDirectoryUserInactiveRiskModelInfoType = "GciDirectoryUserInactiveRiskModelInfoType",
    GciDirectoryUserInactiveRiskPolicyConfiguration = "GciDirectoryUserInactiveRiskPolicyConfiguration",
    GciDirectoryUserInactiveRiskPolicyUpdateAuditEventIncludeDisabledUserChange = "GciDirectoryUserInactiveRiskPolicyUpdateAuditEventIncludeDisabledUserChange",
    GciDirectoryUserInactiveRiskResolutionType = "GciDirectoryUserInactiveRiskResolutionType",
    GciDirectoryUserModel = "GciDirectoryUserModel",
    GciDirectoryUserModelFilters = "GciDirectoryUserModelFilters",
    GciDirectoryUserProfile = "GciDirectoryUserProfile",
    GciDirectoryUserStatus = "GciDirectoryUserStatus",
    GciEntity = "GciEntity",
    GciEntityModel = "GciEntityModel",
    GciEntityProfile = "GciEntityProfile",
    GciEntityTypeMetadata = "GciEntityTypeMetadata",
    GciEventAnalysisServiceConfiguration = "GciEventAnalysisServiceConfiguration",
    GciFolderConfiguration = "GciFolderConfiguration",
    GciIdentityProfileGcp = "GciIdentityProfileGcp",
    GciParentResourceData = "GciParentResourceData",
    GciPartialEntity = "GciPartialEntity",
    GciPartialTenantEntity = "GciPartialTenantEntity",
    GciPartialTenantEntityModel = "GciPartialTenantEntityModel",
    GciPrincipalGroupIdMatchCondition = "GciPrincipalGroupIdMatchCondition",
    GciPrincipalModelAnalyzing = "GciPrincipalModelAnalyzing",
    GciPrincipalReference = "GciPrincipalReference",
    GciPrincipalType = "GciPrincipalType",
    GciTenantAuditEvent = "GciTenantAuditEvent",
    GciTenantConfiguration = "GciTenantConfiguration",
    GciTenantConfigurationEvents = "GciTenantConfigurationEvents",
    GciTenantCreationAuditEvent = "GciTenantCreationAuditEvent",
    GciTenantDeletionAuditEvent = "GciTenantDeletionAuditEvent",
    GciTenantEntity = "GciTenantEntity",
    GciTenantEntityDomain = "GciTenantEntityDomain",
    GciTenantEntityDomainAlias = "GciTenantEntityDomainAlias",
    GciTenantEntityModel = "GciTenantEntityModel",
    GciTenantEntityProfile = "GciTenantEntityProfile",
    GciTenantModel = "GciTenantModel",
    GciTenantModelStatus = "GciTenantModelStatus",
    GciTenantState = "GciTenantState",
    GciTenantStateEvents = "GciTenantStateEvents",
    GciTenantStateGcp = "GciTenantStateGcp",
    GciTenantStateMonitoring = "GciTenantStateMonitoring",
    GciTenantStateMonitoringIssue = "GciTenantStateMonitoringIssue",
    GciTenantStateMonitoringPermissionManagementIssue = "GciTenantStateMonitoringPermissionManagementIssue",
    GciTenantUpdateAuditEvent = "GciTenantUpdateAuditEvent",
    GcpAccessApprovalSettingsGenericResource = "GcpAccessApprovalSettingsGenericResource",
    GcpAccessContextManagerAccessLevelsGenericResource = "GcpAccessContextManagerAccessLevelsGenericResource",
    GcpAccessContextManagerAccessPoliciesGenericResource = "GcpAccessContextManagerAccessPoliciesGenericResource",
    GcpAccessContextManagerAuthorizedOrgsDescsGenericResource = "GcpAccessContextManagerAuthorizedOrgsDescsGenericResource",
    GcpAccessContextManagerServicePerimetersGenericResource = "GcpAccessContextManagerServicePerimetersGenericResource",
    GcpAccessGraphPermissionPath = "GcpAccessGraphPermissionPath",
    GcpAccessGraphPermissionPathIdentifier = "GcpAccessGraphPermissionPathIdentifier",
    GcpAccessListPermissionActionData = "GcpAccessListPermissionActionData",
    GcpAccessPrincipalRisk = "GcpAccessPrincipalRisk",
    GcpAccessPrincipalRiskModel = "GcpAccessPrincipalRiskModel",
    GcpActivityRisk = "GcpActivityRisk",
    GcpActivityRiskModel = "GcpActivityRiskModel",
    GcpAiPlatformBatchPredictionJobsGenericResource = "GcpAiPlatformBatchPredictionJobsGenericResource",
    GcpAiPlatformCustomJobsGenericResource = "GcpAiPlatformCustomJobsGenericResource",
    GcpAiPlatformDataLabelingJobsGenericResource = "GcpAiPlatformDataLabelingJobsGenericResource",
    GcpAiPlatformDatasetsGenericResource = "GcpAiPlatformDatasetsGenericResource",
    GcpAiPlatformEndpointsGenericResource = "GcpAiPlatformEndpointsGenericResource",
    GcpAiPlatformFeatureGroupsGenericResource = "GcpAiPlatformFeatureGroupsGenericResource",
    GcpAiPlatformFeatureOnlineStoresGenericResource = "GcpAiPlatformFeatureOnlineStoresGenericResource",
    GcpAiPlatformFeaturestoresGenericResource = "GcpAiPlatformFeaturestoresGenericResource",
    GcpAiPlatformHyperparameterTuningJobsGenericResource = "GcpAiPlatformHyperparameterTuningJobsGenericResource",
    GcpAiPlatformIndexEndpointsGenericResource = "GcpAiPlatformIndexEndpointsGenericResource",
    GcpAiPlatformIndicesGenericResource = "GcpAiPlatformIndicesGenericResource",
    GcpAiPlatformMetadataStoresGenericResource = "GcpAiPlatformMetadataStoresGenericResource",
    GcpAiPlatformModelDeploymentMonitoringJobsGenericResource = "GcpAiPlatformModelDeploymentMonitoringJobsGenericResource",
    GcpAiPlatformModelsGenericResource = "GcpAiPlatformModelsGenericResource",
    GcpAiPlatformNasJobsGenericResource = "GcpAiPlatformNasJobsGenericResource",
    GcpAiPlatformNotebookExecutionJobsGenericResource = "GcpAiPlatformNotebookExecutionJobsGenericResource",
    GcpAiPlatformNotebookRuntimesGenericResource = "GcpAiPlatformNotebookRuntimesGenericResource",
    GcpAiPlatformNotebookRuntimeTemplatesGenericResource = "GcpAiPlatformNotebookRuntimeTemplatesGenericResource",
    GcpAiPlatformPipelineJobsGenericResource = "GcpAiPlatformPipelineJobsGenericResource",
    GcpAiPlatformSpecialistPoolsGenericResource = "GcpAiPlatformSpecialistPoolsGenericResource",
    GcpAiPlatformTensorboardsGenericResource = "GcpAiPlatformTensorboardsGenericResource",
    GcpAiPlatformTrainingPipelinesGenericResource = "GcpAiPlatformTrainingPipelinesGenericResource",
    GcpAiPlatformTuningJobsGenericResource = "GcpAiPlatformTuningJobsGenericResource",
    GcpAlloydbBackupsGenericResource = "GcpAlloydbBackupsGenericResource",
    GcpAlloydbClustersGenericResource = "GcpAlloydbClustersGenericResource",
    GcpAlloydbInstancesGenericResource = "GcpAlloydbInstancesGenericResource",
    GcpApiGatewayApiconfigsGenericResource = "GcpApiGatewayApiconfigsGenericResource",
    GcpApiGatewayApisGenericResource = "GcpApiGatewayApisGenericResource",
    GcpApiGatewayGatewaysGenericResource = "GcpApiGatewayGatewaysGenericResource",
    GcpApiGatewayGatewaysGenericResourceModel = "GcpApiGatewayGatewaysGenericResourceModel",
    GcpApigeeInstancesGenericResource = "GcpApigeeInstancesGenericResource",
    GcpApigeeOrganizationsGenericResource = "GcpApigeeOrganizationsGenericResource",
    GcpApihubApiHubInstancesGenericResource = "GcpApihubApiHubInstancesGenericResource",
    GcpApiKeysKey = "GcpApiKeysKey",
    GcpApiKeysKeyClientRestrictionNotExistsCodeRisk = "GcpApiKeysKeyClientRestrictionNotExistsCodeRisk",
    GcpApiKeysKeyClientRestrictionNotExistsCodeRiskModel = "GcpApiKeysKeyClientRestrictionNotExistsCodeRiskModel",
    GcpApiKeysKeyClientRestrictionNotExistsRisk = "GcpApiKeysKeyClientRestrictionNotExistsRisk",
    GcpApiKeysKeyClientRestrictionNotExistsRiskModel = "GcpApiKeysKeyClientRestrictionNotExistsRiskModel",
    GcpApiKeysKeyClientRestrictionNotExistsRiskPolicyConfiguration = "GcpApiKeysKeyClientRestrictionNotExistsRiskPolicyConfiguration",
    GcpApiKeysKeyExistsRisk = "GcpApiKeysKeyExistsRisk",
    GcpApiKeysKeyExistsRiskModel = "GcpApiKeysKeyExistsRiskModel",
    GcpApiKeysKeyExistsRiskPolicyConfiguration = "GcpApiKeysKeyExistsRiskPolicyConfiguration",
    GcpApiKeysKeyModel = "GcpApiKeysKeyModel",
    GcpApiKeysKeyNotRotatedRisk = "GcpApiKeysKeyNotRotatedRisk",
    GcpApiKeysKeyNotRotatedRiskModel = "GcpApiKeysKeyNotRotatedRiskModel",
    GcpApiKeysKeyNotRotatedRiskPolicyConfiguration = "GcpApiKeysKeyNotRotatedRiskPolicyConfiguration",
    GcpApiKeysKeyNotRotatedRiskPolicyUpdateAuditEventKeyRotationTimeFrameChange = "GcpApiKeysKeyNotRotatedRiskPolicyUpdateAuditEventKeyRotationTimeFrameChange",
    GcpApiKeysKeyProfile = "GcpApiKeysKeyProfile",
    GcpApiKeysKeyRestrictions = "GcpApiKeysKeyRestrictions",
    GcpApiKeysKeyRestrictionsClientType = "GcpApiKeysKeyRestrictionsClientType",
    GcpApiKeysKeyServiceRestrictionNotExistsCodeRisk = "GcpApiKeysKeyServiceRestrictionNotExistsCodeRisk",
    GcpApiKeysKeyServiceRestrictionNotExistsCodeRiskModel = "GcpApiKeysKeyServiceRestrictionNotExistsCodeRiskModel",
    GcpApiKeysKeyServiceRestrictionNotExistsRisk = "GcpApiKeysKeyServiceRestrictionNotExistsRisk",
    GcpApiKeysKeyServiceRestrictionNotExistsRiskModel = "GcpApiKeysKeyServiceRestrictionNotExistsRiskModel",
    GcpApiKeysKeyServiceRestrictionNotExistsRiskPolicyConfiguration = "GcpApiKeysKeyServiceRestrictionNotExistsRiskPolicyConfiguration",
    GcpApikeysKeysGenericResource = "GcpApikeysKeysGenericResource",
    GcpAppEngineApplication = "GcpAppEngineApplication",
    GcpAppEngineApplicationFirewallRule = "GcpAppEngineApplicationFirewallRule",
    GcpAppEngineApplicationModel = "GcpAppEngineApplicationModel",
    GcpAppEngineApplicationProfile = "GcpAppEngineApplicationProfile",
    GcpAppEngineApplicationResourceStatus = "GcpAppEngineApplicationResourceStatus",
    GcpAppEngineApplicationService = "GcpAppEngineApplicationService",
    GcpAppEngineApplicationServiceEnvironmentVariableSecretExistsCodeRisk = "GcpAppEngineApplicationServiceEnvironmentVariableSecretExistsCodeRisk",
    GcpAppEngineApplicationServiceEnvironmentVariableSecretExistsCodeRiskModel = "GcpAppEngineApplicationServiceEnvironmentVariableSecretExistsCodeRiskModel",
    GcpAppEngineApplicationServiceEnvironmentVariableSecretExistsRisk = "GcpAppEngineApplicationServiceEnvironmentVariableSecretExistsRisk",
    GcpAppEngineApplicationServiceEnvironmentVariableSecretExistsRiskModel = "GcpAppEngineApplicationServiceEnvironmentVariableSecretExistsRiskModel",
    GcpAppEngineApplicationServiceEnvironmentVariableSecretExistsRiskPolicyConfiguration = "GcpAppEngineApplicationServiceEnvironmentVariableSecretExistsRiskPolicyConfiguration",
    GcpAppEngineApplicationServiceModel = "GcpAppEngineApplicationServiceModel",
    GcpAppEngineApplicationServiceModelFilters = "GcpAppEngineApplicationServiceModelFilters",
    GcpAppEngineApplicationServiceProfile = "GcpAppEngineApplicationServiceProfile",
    GcpAppEngineApplicationServiceRevision = "GcpAppEngineApplicationServiceRevision",
    GcpAppEngineApplicationServiceRevisionEnvironment = "GcpAppEngineApplicationServiceRevisionEnvironment",
    GcpAppEngineApplicationServiceRevisionModel = "GcpAppEngineApplicationServiceRevisionModel",
    GcpAppEngineApplicationServiceRevisionProfile = "GcpAppEngineApplicationServiceRevisionProfile",
    GcpAppEngineApplicationServiceRevisionScaling = "GcpAppEngineApplicationServiceRevisionScaling",
    GcpAppEngineApplicationServiceRevisionSecurityLevel = "GcpAppEngineApplicationServiceRevisionSecurityLevel",
    GcpAppEngineApplicationServiceUnencryptedTransportExistsCodeRisk = "GcpAppEngineApplicationServiceUnencryptedTransportExistsCodeRisk",
    GcpAppEngineApplicationServiceUnencryptedTransportExistsCodeRiskModel = "GcpAppEngineApplicationServiceUnencryptedTransportExistsCodeRiskModel",
    GcpAppEngineApplicationServiceUnencryptedTransportExistsRisk = "GcpAppEngineApplicationServiceUnencryptedTransportExistsRisk",
    GcpAppEngineApplicationServiceUnencryptedTransportExistsRiskModel = "GcpAppEngineApplicationServiceUnencryptedTransportExistsRiskModel",
    GcpAppEngineApplicationServiceUnencryptedTransportExistsRiskPolicyConfiguration = "GcpAppEngineApplicationServiceUnencryptedTransportExistsRiskPolicyConfiguration",
    GcpAppEngineApplicationsGenericResource = "GcpAppEngineApplicationsGenericResource",
    GcpAppEngineServicesGenericResource = "GcpAppEngineServicesGenericResource",
    GcpAppEngineVersionsGenericResource = "GcpAppEngineVersionsGenericResource",
    GcpApphubApplicationsGenericResource = "GcpApphubApplicationsGenericResource",
    GcpApphubServiceProjectAttachmentsGenericResource = "GcpApphubServiceProjectAttachmentsGenericResource",
    GcpApphubServicesGenericResource = "GcpApphubServicesGenericResource",
    GcpApphubWorkloadGenericResource = "GcpApphubWorkloadGenericResource",
    GcpApplicationAuthenticationProviderConfigurationSection = "GcpApplicationAuthenticationProviderConfigurationSection",
    GcpApplicationAuthenticationProviderConfigurationServiceAccount = "GcpApplicationAuthenticationProviderConfigurationServiceAccount",
    GcpArtifactContainerImageRepository = "GcpArtifactContainerImageRepository",
    GcpArtifactContainerImageRepositoryModel = "GcpArtifactContainerImageRepositoryModel",
    GcpArtifactContainerImageRepositoryProfile = "GcpArtifactContainerImageRepositoryProfile",
    GcpArtifactDockerimagesGenericResource = "GcpArtifactDockerimagesGenericResource",
    GcpArtifactMavenartifactsGenericResource = "GcpArtifactMavenartifactsGenericResource",
    GcpArtifactNpmpackagesGenericResource = "GcpArtifactNpmpackagesGenericResource",
    GcpArtifactPackageRepository = "GcpArtifactPackageRepository",
    GcpArtifactPackageRepositoryModel = "GcpArtifactPackageRepositoryModel",
    GcpArtifactPackageRepositoryNpmPackage = "GcpArtifactPackageRepositoryNpmPackage",
    GcpArtifactPackageRepositoryPackage = "GcpArtifactPackageRepositoryPackage",
    GcpArtifactPackageRepositoryProfile = "GcpArtifactPackageRepositoryProfile",
    GcpArtifactPythonpackagesGenericResource = "GcpArtifactPythonpackagesGenericResource",
    GcpArtifactRegistry = "GcpArtifactRegistry",
    GcpArtifactRegistryModel = "GcpArtifactRegistryModel",
    GcpArtifactRegistryProfile = "GcpArtifactRegistryProfile",
    GcpArtifactRegistryPublicAccessExistsCodeRisk = "GcpArtifactRegistryPublicAccessExistsCodeRisk",
    GcpArtifactRegistryPublicAccessExistsCodeRiskModel = "GcpArtifactRegistryPublicAccessExistsCodeRiskModel",
    GcpArtifactRegistryPublicAccessExistsRisk = "GcpArtifactRegistryPublicAccessExistsRisk",
    GcpArtifactRegistryPublicAccessExistsRiskModel = "GcpArtifactRegistryPublicAccessExistsRiskModel",
    GcpArtifactRegistryPublicAccessExistsRiskPolicyConfiguration = "GcpArtifactRegistryPublicAccessExistsRiskPolicyConfiguration",
    GcpArtifactRegistryType = "GcpArtifactRegistryType",
    GcpArtifactRepositoriesGenericResource = "GcpArtifactRepositoriesGenericResource",
    GcpArtifactRepositoryTypeMetadata = "GcpArtifactRepositoryTypeMetadata",
    GcpArtifactRulesGenericResource = "GcpArtifactRulesGenericResource",
    GcpAssetGenericResource = "GcpAssetGenericResource",
    GcpAssetGenericResourceModel = "GcpAssetGenericResourceModel",
    GcpAssetGenericResourceProfile = "GcpAssetGenericResourceProfile",
    GcpAssetGenericResourceTypeMetadata = "GcpAssetGenericResourceTypeMetadata",
    GcpAssuredWorkloadsWorkloadGenericResource = "GcpAssuredWorkloadsWorkloadGenericResource",
    GcpAuditLogType = "GcpAuditLogType",
    GcpBackupdrBackupPlanAssociationsGenericResource = "GcpBackupdrBackupPlanAssociationsGenericResource",
    GcpBackupdrBackupPlansGenericResource = "GcpBackupdrBackupPlansGenericResource",
    GcpBackupdrManagementServersGenericResource = "GcpBackupdrManagementServersGenericResource",
    GcpBatchJobsGenericResource = "GcpBatchJobsGenericResource",
    GcpBehaviorIdentityRisk = "GcpBehaviorIdentityRisk",
    GcpBehaviorIdentityRiskModel = "GcpBehaviorIdentityRiskModel",
    GcpBehaviorIdentityRiskModelInfo = "GcpBehaviorIdentityRiskModelInfo",
    GcpBehaviorIdentityRiskPolicyGroupFilters = "GcpBehaviorIdentityRiskPolicyGroupFilters",
    GcpBehaviorIdentityRiskSeverityInfo = "GcpBehaviorIdentityRiskSeverityInfo",
    GcpBeyondcorpAppConnectionsGenericResource = "GcpBeyondcorpAppConnectionsGenericResource",
    GcpBeyondcorpAppConnectorsGenericResource = "GcpBeyondcorpAppConnectorsGenericResource",
    GcpBeyondcorpAppGatewaysGenericResource = "GcpBeyondcorpAppGatewaysGenericResource",
    GcpBeyondcorpClientConnectorServicesGenericResource = "GcpBeyondcorpClientConnectorServicesGenericResource",
    GcpBeyondcorpClientGatewaysGenericResource = "GcpBeyondcorpClientGatewaysGenericResource",
    GcpBigQueryDataset = "GcpBigQueryDataset",
    GcpBigQueryDatasetEncryptionDisabledCodeRisk = "GcpBigQueryDatasetEncryptionDisabledCodeRisk",
    GcpBigQueryDatasetEncryptionDisabledCodeRiskModel = "GcpBigQueryDatasetEncryptionDisabledCodeRiskModel",
    GcpBigQueryDatasetEncryptionDisabledRisk = "GcpBigQueryDatasetEncryptionDisabledRisk",
    GcpBigQueryDatasetEncryptionDisabledRiskModel = "GcpBigQueryDatasetEncryptionDisabledRiskModel",
    GcpBigQueryDatasetEncryptionDisabledRiskPolicyConfiguration = "GcpBigQueryDatasetEncryptionDisabledRiskPolicyConfiguration",
    GcpBigQueryDatasetModel = "GcpBigQueryDatasetModel",
    GcpBigQueryDatasetModelFilters = "GcpBigQueryDatasetModelFilters",
    GcpBigQueryDatasetProfile = "GcpBigQueryDatasetProfile",
    GcpBigQueryDatasetPublicAccessExistsCodeRisk = "GcpBigQueryDatasetPublicAccessExistsCodeRisk",
    GcpBigQueryDatasetPublicAccessExistsCodeRiskModel = "GcpBigQueryDatasetPublicAccessExistsCodeRiskModel",
    GcpBigQueryDatasetPublicAccessExistsRisk = "GcpBigQueryDatasetPublicAccessExistsRisk",
    GcpBigQueryDatasetPublicAccessExistsRiskModel = "GcpBigQueryDatasetPublicAccessExistsRiskModel",
    GcpBigQueryDatasetPublicAccessExistsRiskPolicyConfiguration = "GcpBigQueryDatasetPublicAccessExistsRiskPolicyConfiguration",
    GcpBigQueryDatasetsGenericResource = "GcpBigQueryDatasetsGenericResource",
    GcpBigQueryDatasetTable = "GcpBigQueryDatasetTable",
    GcpBigQueryDatasetTableEncryptionDisabledCodeRisk = "GcpBigQueryDatasetTableEncryptionDisabledCodeRisk",
    GcpBigQueryDatasetTableEncryptionDisabledCodeRiskModel = "GcpBigQueryDatasetTableEncryptionDisabledCodeRiskModel",
    GcpBigQueryDatasetTableEncryptionDisabledRisk = "GcpBigQueryDatasetTableEncryptionDisabledRisk",
    GcpBigQueryDatasetTableEncryptionDisabledRiskModel = "GcpBigQueryDatasetTableEncryptionDisabledRiskModel",
    GcpBigQueryDatasetTableEncryptionDisabledRiskPolicyConfiguration = "GcpBigQueryDatasetTableEncryptionDisabledRiskPolicyConfiguration",
    GcpBigQueryDatasetTableModel = "GcpBigQueryDatasetTableModel",
    GcpBigQueryDatasetTableProfile = "GcpBigQueryDatasetTableProfile",
    GcpBigquerymigrationMigrationWorkflowsGenericResource = "GcpBigquerymigrationMigrationWorkflowsGenericResource",
    GcpBigQueryModelsGenericResource = "GcpBigQueryModelsGenericResource",
    GcpBigQueryModelsGenericResourceModel = "GcpBigQueryModelsGenericResourceModel",
    GcpBigQueryResource = "GcpBigQueryResource",
    GcpBigQueryTablesGenericResource = "GcpBigQueryTablesGenericResource",
    GcpBigtableAppProfilesGenericResource = "GcpBigtableAppProfilesGenericResource",
    GcpBigtableBackupsGenericResource = "GcpBigtableBackupsGenericResource",
    GcpBigtableClustersGenericResource = "GcpBigtableClustersGenericResource",
    GcpBigtableClustersGenericResourceModel = "GcpBigtableClustersGenericResourceModel",
    GcpBigtableInstancesGenericResource = "GcpBigtableInstancesGenericResource",
    GcpBigtableInstancesGenericResourceModel = "GcpBigtableInstancesGenericResourceModel",
    GcpBigtableTablesGenericResource = "GcpBigtableTablesGenericResource",
    GcpBigtableTablesGenericResourceModel = "GcpBigtableTablesGenericResourceModel",
    GcpBillingAccountsGenericResource = "GcpBillingAccountsGenericResource",
    GcpBillingProjectBillingInfosGenericResource = "GcpBillingProjectBillingInfosGenericResource",
    GcpBlockchainnodeengineBlockchainNodesGenericResource = "GcpBlockchainnodeengineBlockchainNodesGenericResource",
    GcpCertificatemanagerCertificateIssuanceConfigsGenericResource = "GcpCertificatemanagerCertificateIssuanceConfigsGenericResource",
    GcpCertificatemanagerCertificateMapEntriesGenericResource = "GcpCertificatemanagerCertificateMapEntriesGenericResource",
    GcpCertificatemanagerCertificateMapsGenericResource = "GcpCertificatemanagerCertificateMapsGenericResource",
    GcpCertificatemanagerCertificatesGenericResource = "GcpCertificatemanagerCertificatesGenericResource",
    GcpCertificatemanagerDnsauthorizationsGenericResource = "GcpCertificatemanagerDnsauthorizationsGenericResource",
    GcpCertificatemanagerTrustconfigsGenericResource = "GcpCertificatemanagerTrustconfigsGenericResource",
    GcpChange = "GcpChange",
    GcpCloudaicompanionCodeRepositoryIndicesGenericResource = "GcpCloudaicompanionCodeRepositoryIndicesGenericResource",
    GcpCloudaicompanionRepositoryGroupsGenericResource = "GcpCloudaicompanionRepositoryGroupsGenericResource",
    GcpCloudAssetFeedsGenericResource = "GcpCloudAssetFeedsGenericResource",
    GcpCloudBuildBitbucketServerConfigsGenericResource = "GcpCloudBuildBitbucketServerConfigsGenericResource",
    GcpCloudBuildBuildsGenericResource = "GcpCloudBuildBuildsGenericResource",
    GcpCloudBuildBuildTrigger = "GcpCloudBuildBuildTrigger",
    GcpCloudBuildBuildTriggerDefaultServiceAccountRisk = "GcpCloudBuildBuildTriggerDefaultServiceAccountRisk",
    GcpCloudBuildBuildTriggerDefaultServiceAccountRiskModel = "GcpCloudBuildBuildTriggerDefaultServiceAccountRiskModel",
    GcpCloudBuildBuildTriggerDefaultServiceAccountRiskPolicyConfiguration = "GcpCloudBuildBuildTriggerDefaultServiceAccountRiskPolicyConfiguration",
    GcpCloudBuildBuildTriggerModel = "GcpCloudBuildBuildTriggerModel",
    GcpCloudBuildBuildTriggerProfile = "GcpCloudBuildBuildTriggerProfile",
    GcpCloudBuildBuildTriggersGenericResource = "GcpCloudBuildBuildTriggersGenericResource",
    GcpCloudBuildConnectionsGenericResource = "GcpCloudBuildConnectionsGenericResource",
    GcpCloudBuildGithubEnterpriseConfigsGenericResource = "GcpCloudBuildGithubEnterpriseConfigsGenericResource",
    GcpCloudBuildGlobalTriggerSettingsGenericResource = "GcpCloudBuildGlobalTriggerSettingsGenericResource",
    GcpCloudBuildInstallationsGenericResource = "GcpCloudBuildInstallationsGenericResource",
    GcpCloudBuildRepositoriesGenericResource = "GcpCloudBuildRepositoriesGenericResource",
    GcpCloudBuildWorkerpoolsGenericResource = "GcpCloudBuildWorkerpoolsGenericResource",
    GcpCloudcontrolspartnerCustomersGenericResource = "GcpCloudcontrolspartnerCustomersGenericResource",
    GcpCloudcontrolspartnerPartnersGenericResource = "GcpCloudcontrolspartnerPartnersGenericResource",
    GcpCloudcontrolspartnerWorkloadGenericResource = "GcpCloudcontrolspartnerWorkloadGenericResource",
    GcpClouddeployDeliveryPipelinesGenericResource = "GcpClouddeployDeliveryPipelinesGenericResource",
    GcpClouddeployReleasesGenericResource = "GcpClouddeployReleasesGenericResource",
    GcpClouddeployRolloutsGenericResource = "GcpClouddeployRolloutsGenericResource",
    GcpClouddeployTargetsGenericResource = "GcpClouddeployTargetsGenericResource",
    GcpCloudFunctionsFunctionsGenericResource = "GcpCloudFunctionsFunctionsGenericResource",
    GcpCloudKmsAutokeyConfigsGenericResource = "GcpCloudKmsAutokeyConfigsGenericResource",
    GcpCloudKmsCryptoKeysGenericResource = "GcpCloudKmsCryptoKeysGenericResource",
    GcpCloudKmsCryptoKeyVersionsGenericResource = "GcpCloudKmsCryptoKeyVersionsGenericResource",
    GcpCloudKmsEkmConfigsGenericResource = "GcpCloudKmsEkmConfigsGenericResource",
    GcpCloudKmsEkmConnectionsGenericResource = "GcpCloudKmsEkmConnectionsGenericResource",
    GcpCloudKmsImportJobsGenericResource = "GcpCloudKmsImportJobsGenericResource",
    GcpCloudKmsKeyHandlesGenericResource = "GcpCloudKmsKeyHandlesGenericResource",
    GcpCloudKmsKeyRingsGenericResource = "GcpCloudKmsKeyRingsGenericResource",
    GcpCloudquotasQuotaPreferencesGenericResource = "GcpCloudquotasQuotaPreferencesGenericResource",
    GcpCloudRunService = "GcpCloudRunService",
    GcpCloudRunServiceEnvironmentVariableSecretExistsCodeRisk = "GcpCloudRunServiceEnvironmentVariableSecretExistsCodeRisk",
    GcpCloudRunServiceEnvironmentVariableSecretExistsCodeRiskModel = "GcpCloudRunServiceEnvironmentVariableSecretExistsCodeRiskModel",
    GcpCloudRunServiceEnvironmentVariableSecretExistsRisk = "GcpCloudRunServiceEnvironmentVariableSecretExistsRisk",
    GcpCloudRunServiceEnvironmentVariableSecretExistsRiskModel = "GcpCloudRunServiceEnvironmentVariableSecretExistsRiskModel",
    GcpCloudRunServiceEnvironmentVariableSecretExistsRiskPolicyConfiguration = "GcpCloudRunServiceEnvironmentVariableSecretExistsRiskPolicyConfiguration",
    GcpCloudRunServiceModel = "GcpCloudRunServiceModel",
    GcpCloudRunServiceProfile = "GcpCloudRunServiceProfile",
    GcpCloudRunServicePublicAccessExistsCodeRisk = "GcpCloudRunServicePublicAccessExistsCodeRisk",
    GcpCloudRunServicePublicAccessExistsCodeRiskModel = "GcpCloudRunServicePublicAccessExistsCodeRiskModel",
    GcpCloudRunServicePublicAccessExistsRisk = "GcpCloudRunServicePublicAccessExistsRisk",
    GcpCloudRunServicePublicAccessExistsRiskModel = "GcpCloudRunServicePublicAccessExistsRiskModel",
    GcpCloudRunServicePublicAccessExistsRiskPolicyConfiguration = "GcpCloudRunServicePublicAccessExistsRiskPolicyConfiguration",
    GcpCloudRunServiceRevision = "GcpCloudRunServiceRevision",
    GcpCloudRunServiceRevisionModel = "GcpCloudRunServiceRevisionModel",
    GcpCloudRunServiceRevisionProfile = "GcpCloudRunServiceRevisionProfile",
    GcpCloudSqlBackupRunsGenericResource = "GcpCloudSqlBackupRunsGenericResource",
    GcpCloudSqlBackupsGenericResource = "GcpCloudSqlBackupsGenericResource",
    GcpCloudSqlInstancesGenericResource = "GcpCloudSqlInstancesGenericResource",
    GcpCloudTasksQueuesGenericResource = "GcpCloudTasksQueuesGenericResource",
    GcpComposerEnvironmentsGenericResource = "GcpComposerEnvironmentsGenericResource",
    GcpComputeAddressesGenericResource = "GcpComputeAddressesGenericResource",
    GcpComputeAutoscaler = "GcpComputeAutoscaler",
    GcpComputeAutoscalerModel = "GcpComputeAutoscalerModel",
    GcpComputeAutoscalerProfile = "GcpComputeAutoscalerProfile",
    GcpComputeAutoscalersGenericResource = "GcpComputeAutoscalersGenericResource",
    GcpComputeBackendBucket = "GcpComputeBackendBucket",
    GcpComputeBackendBucketModel = "GcpComputeBackendBucketModel",
    GcpComputeBackendBucketProfile = "GcpComputeBackendBucketProfile",
    GcpComputeBackendBucketsGenericResource = "GcpComputeBackendBucketsGenericResource",
    GcpComputeBackendService = "GcpComputeBackendService",
    GcpComputeBackendServiceLoggingNotEnabledCodeRisk = "GcpComputeBackendServiceLoggingNotEnabledCodeRisk",
    GcpComputeBackendServiceLoggingNotEnabledCodeRiskModel = "GcpComputeBackendServiceLoggingNotEnabledCodeRiskModel",
    GcpComputeBackendServiceLoggingNotEnabledRisk = "GcpComputeBackendServiceLoggingNotEnabledRisk",
    GcpComputeBackendServiceLoggingNotEnabledRiskModel = "GcpComputeBackendServiceLoggingNotEnabledRiskModel",
    GcpComputeBackendServiceLoggingNotEnabledRiskPolicyConfiguration = "GcpComputeBackendServiceLoggingNotEnabledRiskPolicyConfiguration",
    GcpComputeBackendServiceModel = "GcpComputeBackendServiceModel",
    GcpComputeBackendServiceProfile = "GcpComputeBackendServiceProfile",
    GcpComputeBackendServiceProtocol = "GcpComputeBackendServiceProtocol",
    GcpComputeBackendServicesGenericResource = "GcpComputeBackendServicesGenericResource",
    GcpComputeCommitmentsGenericResource = "GcpComputeCommitmentsGenericResource",
    GcpComputeDisk = "GcpComputeDisk",
    GcpComputeDiskModel = "GcpComputeDiskModel",
    GcpComputeDiskProfile = "GcpComputeDiskProfile",
    GcpComputeDisksGenericResource = "GcpComputeDisksGenericResource",
    GcpComputeDiskStatus = "GcpComputeDiskStatus",
    GcpComputeDiskType = "GcpComputeDiskType",
    GcpComputeExternalVpnGatewaysGenericResource = "GcpComputeExternalVpnGatewaysGenericResource",
    GcpComputeExternalVpnGatewaysGenericResourceModel = "GcpComputeExternalVpnGatewaysGenericResourceModel",
    GcpComputeFirewallPoliciesGenericResource = "GcpComputeFirewallPoliciesGenericResource",
    GcpComputeFirewallPolicy = "GcpComputeFirewallPolicy",
    GcpComputeFirewallPolicyModel = "GcpComputeFirewallPolicyModel",
    GcpComputeFirewallPolicyProfile = "GcpComputeFirewallPolicyProfile",
    GcpComputeFirewallPolicyRule = "GcpComputeFirewallPolicyRule",
    GcpComputeFirewallPolicyRuleAction = "GcpComputeFirewallPolicyRuleAction",
    GcpComputeFirewallPolicyRuleDirection = "GcpComputeFirewallPolicyRuleDirection",
    GcpComputeFirewallPolicyRuleFilter = "GcpComputeFirewallPolicyRuleFilter",
    GcpComputeFirewallPolicyRuleScope = "GcpComputeFirewallPolicyRuleScope",
    GcpComputeFirewallPolicyRuleSecureTag = "GcpComputeFirewallPolicyRuleSecureTag",
    GcpComputeFirewallPolicyRuleTarget = "GcpComputeFirewallPolicyRuleTarget",
    GcpComputeFirewallPolicyScope = "GcpComputeFirewallPolicyScope",
    GcpComputeFirewallRule = "GcpComputeFirewallRule",
    GcpComputeFirewallRuleAction = "GcpComputeFirewallRuleAction",
    GcpComputeFirewallRuleDirection = "GcpComputeFirewallRuleDirection",
    GcpComputeFirewallRuleFilter = "GcpComputeFirewallRuleFilter",
    GcpComputeFirewallRuleFilterInboundVpc = "GcpComputeFirewallRuleFilterInboundVpc",
    GcpComputeFirewallRuleModel = "GcpComputeFirewallRuleModel",
    GcpComputeFirewallRuleProfile = "GcpComputeFirewallRuleProfile",
    GcpComputeFirewallRuleScope = "GcpComputeFirewallRuleScope",
    GcpComputeFirewallRuleTarget = "GcpComputeFirewallRuleTarget",
    GcpComputeFirewallsGenericResource = "GcpComputeFirewallsGenericResource",
    GcpComputeForwardingRule = "GcpComputeForwardingRule",
    GcpComputeForwardingRuleModel = "GcpComputeForwardingRuleModel",
    GcpComputeForwardingRuleNetworkTier = "GcpComputeForwardingRuleNetworkTier",
    GcpComputeForwardingRuleProfile = "GcpComputeForwardingRuleProfile",
    GcpComputeForwardingRuleProtocol = "GcpComputeForwardingRuleProtocol",
    GcpComputeForwardingRulesGenericResource = "GcpComputeForwardingRulesGenericResource",
    GcpComputeForwardingRuleTarget = "GcpComputeForwardingRuleTarget",
    GcpComputeForwardingRuleTargetServiceType = "GcpComputeForwardingRuleTargetServiceType",
    GcpComputeGlobalAddressesGenericResource = "GcpComputeGlobalAddressesGenericResource",
    GcpComputeGlobalForwardingRulesGenericResource = "GcpComputeGlobalForwardingRulesGenericResource",
    GcpComputeHealthChecksGenericResource = "GcpComputeHealthChecksGenericResource",
    GcpComputeHttpHealthChecksGenericResource = "GcpComputeHttpHealthChecksGenericResource",
    GcpComputeHttpsHealthChecksGenericResource = "GcpComputeHttpsHealthChecksGenericResource",
    GcpComputeImage = "GcpComputeImage",
    GcpComputeImageModel = "GcpComputeImageModel",
    GcpComputeImageProfile = "GcpComputeImageProfile",
    GcpComputeImagePublicAccessExistsCodeRisk = "GcpComputeImagePublicAccessExistsCodeRisk",
    GcpComputeImagePublicAccessExistsCodeRiskModel = "GcpComputeImagePublicAccessExistsCodeRiskModel",
    GcpComputeImagePublicAccessExistsRisk = "GcpComputeImagePublicAccessExistsRisk",
    GcpComputeImagePublicAccessExistsRiskModel = "GcpComputeImagePublicAccessExistsRiskModel",
    GcpComputeImagePublicAccessExistsRiskPolicyConfiguration = "GcpComputeImagePublicAccessExistsRiskPolicyConfiguration",
    GcpComputeImagesGenericResource = "GcpComputeImagesGenericResource",
    GcpComputeImageStatus = "GcpComputeImageStatus",
    GcpComputeImageType = "GcpComputeImageType",
    GcpComputeInstance = "GcpComputeInstance",
    GcpComputeInstanceAnalysisServiceConfiguration = "GcpComputeInstanceAnalysisServiceConfiguration",
    GcpComputeInstanceBlockProjectWideSshKeysNotEnabledCodeRisk = "GcpComputeInstanceBlockProjectWideSshKeysNotEnabledCodeRisk",
    GcpComputeInstanceBlockProjectWideSshKeysNotEnabledCodeRiskModel = "GcpComputeInstanceBlockProjectWideSshKeysNotEnabledCodeRiskModel",
    GcpComputeInstanceBlockProjectWideSshKeysNotEnabledRisk = "GcpComputeInstanceBlockProjectWideSshKeysNotEnabledRisk",
    GcpComputeInstanceBlockProjectWideSshKeysNotEnabledRiskModel = "GcpComputeInstanceBlockProjectWideSshKeysNotEnabledRiskModel",
    GcpComputeInstanceBlockProjectWideSshKeysNotEnabledRiskPolicyConfiguration = "GcpComputeInstanceBlockProjectWideSshKeysNotEnabledRiskPolicyConfiguration",
    GcpComputeInstanceDefaultServiceAccountCodeRisk = "GcpComputeInstanceDefaultServiceAccountCodeRisk",
    GcpComputeInstanceDefaultServiceAccountCodeRiskModel = "GcpComputeInstanceDefaultServiceAccountCodeRiskModel",
    GcpComputeInstanceDefaultServiceAccountRisk = "GcpComputeInstanceDefaultServiceAccountRisk",
    GcpComputeInstanceDefaultServiceAccountRiskModel = "GcpComputeInstanceDefaultServiceAccountRiskModel",
    GcpComputeInstanceDefaultServiceAccountRiskPolicyConfiguration = "GcpComputeInstanceDefaultServiceAccountRiskPolicyConfiguration",
    GcpComputeInstanceGroup = "GcpComputeInstanceGroup",
    GcpComputeInstanceGroupManager = "GcpComputeInstanceGroupManager",
    GcpComputeInstanceGroupManagerModel = "GcpComputeInstanceGroupManagerModel",
    GcpComputeInstanceGroupManagerProfile = "GcpComputeInstanceGroupManagerProfile",
    GcpComputeInstanceGroupManagersGenericResource = "GcpComputeInstanceGroupManagersGenericResource",
    GcpComputeInstanceGroupManagerStatus = "GcpComputeInstanceGroupManagerStatus",
    GcpComputeInstanceGroupModel = "GcpComputeInstanceGroupModel",
    GcpComputeInstanceGroupModelFilters = "GcpComputeInstanceGroupModelFilters",
    GcpComputeInstanceGroupProfile = "GcpComputeInstanceGroupProfile",
    GcpComputeInstanceGroupsGenericResource = "GcpComputeInstanceGroupsGenericResource",
    GcpComputeInstanceIpForwardingEnabledCodeRisk = "GcpComputeInstanceIpForwardingEnabledCodeRisk",
    GcpComputeInstanceIpForwardingEnabledCodeRiskModel = "GcpComputeInstanceIpForwardingEnabledCodeRiskModel",
    GcpComputeInstanceIpForwardingEnabledRisk = "GcpComputeInstanceIpForwardingEnabledRisk",
    GcpComputeInstanceIpForwardingEnabledRiskModel = "GcpComputeInstanceIpForwardingEnabledRiskModel",
    GcpComputeInstanceIpForwardingEnabledRiskPolicyConfiguration = "GcpComputeInstanceIpForwardingEnabledRiskPolicyConfiguration",
    GcpComputeInstanceMemoryEncryptionNotEnabledCodeRisk = "GcpComputeInstanceMemoryEncryptionNotEnabledCodeRisk",
    GcpComputeInstanceMemoryEncryptionNotEnabledCodeRiskModel = "GcpComputeInstanceMemoryEncryptionNotEnabledCodeRiskModel",
    GcpComputeInstanceMemoryEncryptionNotEnabledRisk = "GcpComputeInstanceMemoryEncryptionNotEnabledRisk",
    GcpComputeInstanceMemoryEncryptionNotEnabledRiskModel = "GcpComputeInstanceMemoryEncryptionNotEnabledRiskModel",
    GcpComputeInstanceMemoryEncryptionNotEnabledRiskPolicyConfiguration = "GcpComputeInstanceMemoryEncryptionNotEnabledRiskPolicyConfiguration",
    GcpComputeInstanceModel = "GcpComputeInstanceModel",
    GcpComputeInstanceModelFilters = "GcpComputeInstanceModelFilters",
    GcpComputeInstanceProfile = "GcpComputeInstanceProfile",
    GcpComputeInstanceScopePropertyData = "GcpComputeInstanceScopePropertyData",
    GcpComputeInstanceScopePropertySource = "GcpComputeInstanceScopePropertySource",
    GcpComputeInstancesGenericResource = "GcpComputeInstancesGenericResource",
    GcpComputeInstanceShieldedNotEnabledCodeRisk = "GcpComputeInstanceShieldedNotEnabledCodeRisk",
    GcpComputeInstanceShieldedNotEnabledCodeRiskModel = "GcpComputeInstanceShieldedNotEnabledCodeRiskModel",
    GcpComputeInstanceShieldedNotEnabledRisk = "GcpComputeInstanceShieldedNotEnabledRisk",
    GcpComputeInstanceShieldedNotEnabledRiskModel = "GcpComputeInstanceShieldedNotEnabledRiskModel",
    GcpComputeInstanceShieldedNotEnabledRiskPolicyConfiguration = "GcpComputeInstanceShieldedNotEnabledRiskPolicyConfiguration",
    GcpComputeInstanceSshSerialPortEnabledCodeRisk = "GcpComputeInstanceSshSerialPortEnabledCodeRisk",
    GcpComputeInstanceSshSerialPortEnabledCodeRiskModel = "GcpComputeInstanceSshSerialPortEnabledCodeRiskModel",
    GcpComputeInstanceSshSerialPortEnabledRisk = "GcpComputeInstanceSshSerialPortEnabledRisk",
    GcpComputeInstanceSshSerialPortEnabledRiskModel = "GcpComputeInstanceSshSerialPortEnabledRiskModel",
    GcpComputeInstanceSshSerialPortEnabledRiskPolicyConfiguration = "GcpComputeInstanceSshSerialPortEnabledRiskPolicyConfiguration",
    GcpComputeInstanceStartupScriptSecretExistsCodeRisk = "GcpComputeInstanceStartupScriptSecretExistsCodeRisk",
    GcpComputeInstanceStartupScriptSecretExistsCodeRiskModel = "GcpComputeInstanceStartupScriptSecretExistsCodeRiskModel",
    GcpComputeInstanceStartupScriptSecretExistsRisk = "GcpComputeInstanceStartupScriptSecretExistsRisk",
    GcpComputeInstanceStartupScriptSecretExistsRiskModel = "GcpComputeInstanceStartupScriptSecretExistsRiskModel",
    GcpComputeInstanceStartupScriptSecretExistsRiskPolicyConfiguration = "GcpComputeInstanceStartupScriptSecretExistsRiskPolicyConfiguration",
    GcpComputeInstanceStatus = "GcpComputeInstanceStatus",
    GcpComputeInstanceTemplate = "GcpComputeInstanceTemplate",
    GcpComputeInstanceTemplateModel = "GcpComputeInstanceTemplateModel",
    GcpComputeInstanceTemplateProfile = "GcpComputeInstanceTemplateProfile",
    GcpComputeInstanceTemplatesGenericResource = "GcpComputeInstanceTemplatesGenericResource",
    GcpComputeInstantSnapshotsGenericResource = "GcpComputeInstantSnapshotsGenericResource",
    GcpComputeInterconnectAttachmentsGenericResource = "GcpComputeInterconnectAttachmentsGenericResource",
    GcpComputeInterconnectsGenericResource = "GcpComputeInterconnectsGenericResource",
    GcpComputeLicensesGenericResource = "GcpComputeLicensesGenericResource",
    GcpComputeLoadBalancer = "GcpComputeLoadBalancer",
    GcpComputeLoadBalancerInsecureSslPolicyCodeRisk = "GcpComputeLoadBalancerInsecureSslPolicyCodeRisk",
    GcpComputeLoadBalancerInsecureSslPolicyCodeRiskModel = "GcpComputeLoadBalancerInsecureSslPolicyCodeRiskModel",
    GcpComputeLoadBalancerInsecureSslPolicyRisk = "GcpComputeLoadBalancerInsecureSslPolicyRisk",
    GcpComputeLoadBalancerInsecureSslPolicyRiskModel = "GcpComputeLoadBalancerInsecureSslPolicyRiskModel",
    GcpComputeLoadBalancerInsecureSslPolicyRiskPolicyConfiguration = "GcpComputeLoadBalancerInsecureSslPolicyRiskPolicyConfiguration",
    GcpComputeLoadBalancerLoadBalancingScheme = "GcpComputeLoadBalancerLoadBalancingScheme",
    GcpComputeLoadBalancerModel = "GcpComputeLoadBalancerModel",
    GcpComputeLoadBalancerModelFilters = "GcpComputeLoadBalancerModelFilters",
    GcpComputeLoadBalancerProtocol = "GcpComputeLoadBalancerProtocol",
    GcpComputeLoadBalancerResource = "GcpComputeLoadBalancerResource",
    GcpComputeLoadBalancerResourceModel = "GcpComputeLoadBalancerResourceModel",
    GcpComputeLoadBalancerResources = "GcpComputeLoadBalancerResources",
    GcpComputeLoadBalancerType = "GcpComputeLoadBalancerType",
    GcpComputeLoadBalancerTypeMetadata = "GcpComputeLoadBalancerTypeMetadata",
    GcpComputeMachineImagesGenericResource = "GcpComputeMachineImagesGenericResource",
    GcpComputeNetworkAttachmentsGenericResource = "GcpComputeNetworkAttachmentsGenericResource",
    GcpComputeNetworkEdgeSecurityServicesGenericResource = "GcpComputeNetworkEdgeSecurityServicesGenericResource",
    GcpComputeNetworkEndpointGroup = "GcpComputeNetworkEndpointGroup",
    GcpComputeNetworkEndpointGroupEndpoint = "GcpComputeNetworkEndpointGroupEndpoint",
    GcpComputeNetworkEndpointGroupModel = "GcpComputeNetworkEndpointGroupModel",
    GcpComputeNetworkEndpointGroupProfile = "GcpComputeNetworkEndpointGroupProfile",
    GcpComputeNetworkEndpointGroupsGenericResource = "GcpComputeNetworkEndpointGroupsGenericResource",
    GcpComputeNetworkEndpointGroupType = "GcpComputeNetworkEndpointGroupType",
    GcpComputeNetworkResource = "GcpComputeNetworkResource",
    GcpComputeNetworksGenericResource = "GcpComputeNetworksGenericResource",
    GcpComputeNodeGroupsGenericResource = "GcpComputeNodeGroupsGenericResource",
    GcpComputeNodeGroupsGenericResourceModel = "GcpComputeNodeGroupsGenericResourceModel",
    GcpComputeNodeTemplatesGenericResource = "GcpComputeNodeTemplatesGenericResource",
    GcpComputeNodeTemplatesGenericResourceModel = "GcpComputeNodeTemplatesGenericResourceModel",
    GcpComputePacketMirroringsGenericResource = "GcpComputePacketMirroringsGenericResource",
    GcpComputeProject = "GcpComputeProject",
    GcpComputeProjectModel = "GcpComputeProjectModel",
    GcpComputeProjectProfile = "GcpComputeProjectProfile",
    GcpComputeProjectsGenericResource = "GcpComputeProjectsGenericResource",
    GcpComputeProjectSshIamNotEnabledCodeRisk = "GcpComputeProjectSshIamNotEnabledCodeRisk",
    GcpComputeProjectSshIamNotEnabledCodeRiskModel = "GcpComputeProjectSshIamNotEnabledCodeRiskModel",
    GcpComputeProjectSshIamNotEnabledRisk = "GcpComputeProjectSshIamNotEnabledRisk",
    GcpComputeProjectSshIamNotEnabledRiskModel = "GcpComputeProjectSshIamNotEnabledRiskModel",
    GcpComputeProjectSshIamNotEnabledRiskPolicyConfiguration = "GcpComputeProjectSshIamNotEnabledRiskPolicyConfiguration",
    GcpComputePublicDelegatedPrefixesGenericResource = "GcpComputePublicDelegatedPrefixesGenericResource",
    GcpComputeRegionBackendServicesGenericResource = "GcpComputeRegionBackendServicesGenericResource",
    GcpComputeRegionDisksGenericResource = "GcpComputeRegionDisksGenericResource",
    GcpComputeReservationsGenericResource = "GcpComputeReservationsGenericResource",
    GcpComputeResource = "GcpComputeResource",
    GcpComputeResourcePoliciesGenericResource = "GcpComputeResourcePoliciesGenericResource",
    GcpComputeResourceStateNetwork = "GcpComputeResourceStateNetwork",
    GcpComputeRoute = "GcpComputeRoute",
    GcpComputeRouteModel = "GcpComputeRouteModel",
    GcpComputeRouteNextHop = "GcpComputeRouteNextHop",
    GcpComputeRouteNextHopType = "GcpComputeRouteNextHopType",
    GcpComputeRouteProfile = "GcpComputeRouteProfile",
    GcpComputeRoutersGenericResource = "GcpComputeRoutersGenericResource",
    GcpComputeRoutesGenericResource = "GcpComputeRoutesGenericResource",
    GcpComputeSecurityPoliciesGenericResource = "GcpComputeSecurityPoliciesGenericResource",
    GcpComputeServiceAttachmentsGenericResource = "GcpComputeServiceAttachmentsGenericResource",
    GcpComputeSnapshot = "GcpComputeSnapshot",
    GcpComputeSnapshotCreationType = "GcpComputeSnapshotCreationType",
    GcpComputeSnapshotModel = "GcpComputeSnapshotModel",
    GcpComputeSnapshotProfile = "GcpComputeSnapshotProfile",
    GcpComputeSnapshotsGenericResource = "GcpComputeSnapshotsGenericResource",
    GcpComputeSnapshotStatus = "GcpComputeSnapshotStatus",
    GcpComputeSnapshotType = "GcpComputeSnapshotType",
    GcpComputeSslCertificate = "GcpComputeSslCertificate",
    GcpComputeSslCertificateModel = "GcpComputeSslCertificateModel",
    GcpComputeSslCertificateProfile = "GcpComputeSslCertificateProfile",
    GcpComputeSslCertificatesGenericResource = "GcpComputeSslCertificatesGenericResource",
    GcpComputeSslPoliciesGenericResource = "GcpComputeSslPoliciesGenericResource",
    GcpComputeSslPolicy = "GcpComputeSslPolicy",
    GcpComputeSslPolicyFeatureProfile = "GcpComputeSslPolicyFeatureProfile",
    GcpComputeSslPolicyModel = "GcpComputeSslPolicyModel",
    GcpComputeSslPolicyProfile = "GcpComputeSslPolicyProfile",
    GcpComputeStoragePoolsGenericResource = "GcpComputeStoragePoolsGenericResource",
    GcpComputeSubnet = "GcpComputeSubnet",
    GcpComputeSubnetFlowLogs = "GcpComputeSubnetFlowLogs",
    GcpComputeSubnetModel = "GcpComputeSubnetModel",
    GcpComputeSubnetProfile = "GcpComputeSubnetProfile",
    GcpComputeSubnetType = "GcpComputeSubnetType",
    GcpComputeSubnetworksGenericResource = "GcpComputeSubnetworksGenericResource",
    GcpComputeTargetGrpcProxiesGenericResource = "GcpComputeTargetGrpcProxiesGenericResource",
    GcpComputeTargetHttpProxiesGenericResource = "GcpComputeTargetHttpProxiesGenericResource",
    GcpComputeTargetHttpProxy = "GcpComputeTargetHttpProxy",
    GcpComputeTargetHttpProxyModel = "GcpComputeTargetHttpProxyModel",
    GcpComputeTargetHttpProxyProfile = "GcpComputeTargetHttpProxyProfile",
    GcpComputeTargetHttpsProxiesGenericResource = "GcpComputeTargetHttpsProxiesGenericResource",
    GcpComputeTargetHttpsProxy = "GcpComputeTargetHttpsProxy",
    GcpComputeTargetHttpsProxyModel = "GcpComputeTargetHttpsProxyModel",
    GcpComputeTargetHttpsProxyProfile = "GcpComputeTargetHttpsProxyProfile",
    GcpComputeTargetInstancesGenericResource = "GcpComputeTargetInstancesGenericResource",
    GcpComputeTargetPool = "GcpComputeTargetPool",
    GcpComputeTargetPoolModel = "GcpComputeTargetPoolModel",
    GcpComputeTargetPoolProfile = "GcpComputeTargetPoolProfile",
    GcpComputeTargetPoolsGenericResource = "GcpComputeTargetPoolsGenericResource",
    GcpComputeTargetProxy = "GcpComputeTargetProxy",
    GcpComputeTargetProxyModel = "GcpComputeTargetProxyModel",
    GcpComputeTargetProxyType = "GcpComputeTargetProxyType",
    GcpComputeTargetSslProxiesGenericResource = "GcpComputeTargetSslProxiesGenericResource",
    GcpComputeTargetSslProxy = "GcpComputeTargetSslProxy",
    GcpComputeTargetSslProxyModel = "GcpComputeTargetSslProxyModel",
    GcpComputeTargetSslProxyProfile = "GcpComputeTargetSslProxyProfile",
    GcpComputeTargetTcpProxiesGenericResource = "GcpComputeTargetTcpProxiesGenericResource",
    GcpComputeTargetTcpProxy = "GcpComputeTargetTcpProxy",
    GcpComputeTargetTcpProxyModel = "GcpComputeTargetTcpProxyModel",
    GcpComputeTargetTcpProxyProfile = "GcpComputeTargetTcpProxyProfile",
    GcpComputeTargetVpnGatewaysGenericResource = "GcpComputeTargetVpnGatewaysGenericResource",
    GcpComputeTargetVpnGatewaysGenericResourceModel = "GcpComputeTargetVpnGatewaysGenericResourceModel",
    GcpComputeUrlMap = "GcpComputeUrlMap",
    GcpComputeUrlMapBackend = "GcpComputeUrlMapBackend",
    GcpComputeUrlMapModel = "GcpComputeUrlMapModel",
    GcpComputeUrlMapProfile = "GcpComputeUrlMapProfile",
    GcpComputeUrlMapRule = "GcpComputeUrlMapRule",
    GcpComputeUrlMapsGenericResource = "GcpComputeUrlMapsGenericResource",
    GcpComputeVpc = "GcpComputeVpc",
    GcpComputeVpcFlowLogsNotEnabledCodeRisk = "GcpComputeVpcFlowLogsNotEnabledCodeRisk",
    GcpComputeVpcFlowLogsNotEnabledCodeRiskModel = "GcpComputeVpcFlowLogsNotEnabledCodeRiskModel",
    GcpComputeVpcFlowLogsNotEnabledRisk = "GcpComputeVpcFlowLogsNotEnabledRisk",
    GcpComputeVpcFlowLogsNotEnabledRiskModel = "GcpComputeVpcFlowLogsNotEnabledRiskModel",
    GcpComputeVpcFlowLogsNotEnabledRiskPolicyConfiguration = "GcpComputeVpcFlowLogsNotEnabledRiskPolicyConfiguration",
    GcpComputeVpcLegacyVpcRisk = "GcpComputeVpcLegacyVpcRisk",
    GcpComputeVpcLegacyVpcRiskModel = "GcpComputeVpcLegacyVpcRiskModel",
    GcpComputeVpcLegacyVpcRiskPolicyConfiguration = "GcpComputeVpcLegacyVpcRiskPolicyConfiguration",
    GcpComputeVpcModel = "GcpComputeVpcModel",
    GcpComputeVpcPeeringConnection = "GcpComputeVpcPeeringConnection",
    GcpComputeVpcPeeringConnectionModel = "GcpComputeVpcPeeringConnectionModel",
    GcpComputeVpcPeeringConnectionProfile = "GcpComputeVpcPeeringConnectionProfile",
    GcpComputeVpcPeeringConnectionStatus = "GcpComputeVpcPeeringConnectionStatus",
    GcpComputeVpcPeeringConnectionSupportedIpAddressFamilies = "GcpComputeVpcPeeringConnectionSupportedIpAddressFamilies",
    GcpComputeVpcProfile = "GcpComputeVpcProfile",
    GcpComputeVpcUnrestrictedIcmpInboundAccessCodeRisk = "GcpComputeVpcUnrestrictedIcmpInboundAccessCodeRisk",
    GcpComputeVpcUnrestrictedIcmpInboundAccessCodeRiskModel = "GcpComputeVpcUnrestrictedIcmpInboundAccessCodeRiskModel",
    GcpComputeVpcUnrestrictedIcmpInboundAccessRisk = "GcpComputeVpcUnrestrictedIcmpInboundAccessRisk",
    GcpComputeVpcUnrestrictedIcmpInboundAccessRiskModel = "GcpComputeVpcUnrestrictedIcmpInboundAccessRiskModel",
    GcpComputeVpcUnrestrictedIcmpInboundAccessRiskPolicyConfiguration = "GcpComputeVpcUnrestrictedIcmpInboundAccessRiskPolicyConfiguration",
    GcpComputeVpcUnrestrictedInboundAccessRisk = "GcpComputeVpcUnrestrictedInboundAccessRisk",
    GcpComputeVpcUnrestrictedInboundAccessRiskModel = "GcpComputeVpcUnrestrictedInboundAccessRiskModel",
    GcpComputeVpcUnrestrictedRdpInboundAccessCodeRisk = "GcpComputeVpcUnrestrictedRdpInboundAccessCodeRisk",
    GcpComputeVpcUnrestrictedRdpInboundAccessCodeRiskModel = "GcpComputeVpcUnrestrictedRdpInboundAccessCodeRiskModel",
    GcpComputeVpcUnrestrictedRdpInboundAccessRisk = "GcpComputeVpcUnrestrictedRdpInboundAccessRisk",
    GcpComputeVpcUnrestrictedRdpInboundAccessRiskModel = "GcpComputeVpcUnrestrictedRdpInboundAccessRiskModel",
    GcpComputeVpcUnrestrictedRdpInboundAccessRiskPolicyConfiguration = "GcpComputeVpcUnrestrictedRdpInboundAccessRiskPolicyConfiguration",
    GcpComputeVpcUnrestrictedSshInboundAccessCodeRisk = "GcpComputeVpcUnrestrictedSshInboundAccessCodeRisk",
    GcpComputeVpcUnrestrictedSshInboundAccessCodeRiskModel = "GcpComputeVpcUnrestrictedSshInboundAccessCodeRiskModel",
    GcpComputeVpcUnrestrictedSshInboundAccessRisk = "GcpComputeVpcUnrestrictedSshInboundAccessRisk",
    GcpComputeVpcUnrestrictedSshInboundAccessRiskModel = "GcpComputeVpcUnrestrictedSshInboundAccessRiskModel",
    GcpComputeVpcUnrestrictedSshInboundAccessRiskPolicyConfiguration = "GcpComputeVpcUnrestrictedSshInboundAccessRiskPolicyConfiguration",
    GcpComputeVpnGatewaysGenericResource = "GcpComputeVpnGatewaysGenericResource",
    GcpComputeVpnGatewaysGenericResourceModel = "GcpComputeVpnGatewaysGenericResourceModel",
    GcpComputeVpnTunnelsGenericResource = "GcpComputeVpnTunnelsGenericResource",
    GcpConfigDeploymentsGenericResource = "GcpConfigDeploymentsGenericResource",
    GcpConfigPreviewsGenericResource = "GcpConfigPreviewsGenericResource",
    GcpConnectorsConnectionsGenericResource = "GcpConnectorsConnectionsGenericResource",
    GcpConnectorsEndpointAttachmentsGenericResource = "GcpConnectorsEndpointAttachmentsGenericResource",
    GcpConnectorsEventSubscriptionsGenericResource = "GcpConnectorsEventSubscriptionsGenericResource",
    GcpConnectorsManagedZonesGenericResource = "GcpConnectorsManagedZonesGenericResource",
    GcpConsolePage = "GcpConsolePage",
    GcpContactcenterinsightsIssueModelsGenericResource = "GcpContactcenterinsightsIssueModelsGenericResource",
    GcpContactcenterinsightsPhraseMatchersGenericResource = "GcpContactcenterinsightsPhraseMatchersGenericResource",
    GcpContactcenterinsightsViewsGenericResource = "GcpContactcenterinsightsViewsGenericResource",
    GcpContainerCluster = "GcpContainerCluster",
    GcpContainerClusterAttributeBasedAuthorizationEnabledRisk = "GcpContainerClusterAttributeBasedAuthorizationEnabledRisk",
    GcpContainerClusterAttributeBasedAuthorizationEnabledRiskModel = "GcpContainerClusterAttributeBasedAuthorizationEnabledRiskModel",
    GcpContainerClusterAttributeBasedAuthorizationEnabledRiskPolicyConfiguration = "GcpContainerClusterAttributeBasedAuthorizationEnabledRiskPolicyConfiguration",
    GcpContainerClusterAuthorizedSubnetData = "GcpContainerClusterAuthorizedSubnetData",
    GcpContainerClusterDefaultServiceAccountRisk = "GcpContainerClusterDefaultServiceAccountRisk",
    GcpContainerClusterDefaultServiceAccountRiskModel = "GcpContainerClusterDefaultServiceAccountRiskModel",
    GcpContainerClusterDefaultServiceAccountRiskPolicyConfiguration = "GcpContainerClusterDefaultServiceAccountRiskPolicyConfiguration",
    GcpContainerClusterInboundExternalWideRangeRisk = "GcpContainerClusterInboundExternalWideRangeRisk",
    GcpContainerClusterInboundExternalWideRangeRiskModel = "GcpContainerClusterInboundExternalWideRangeRiskModel",
    GcpContainerClusterInboundExternalWideRangeRiskPolicyConfiguration = "GcpContainerClusterInboundExternalWideRangeRiskPolicyConfiguration",
    GcpContainerClusterInstanceInboundExternalRisk = "GcpContainerClusterInstanceInboundExternalRisk",
    GcpContainerClusterInstanceInboundExternalRiskInstanceData = "GcpContainerClusterInstanceInboundExternalRiskInstanceData",
    GcpContainerClusterInstanceInboundExternalRiskModel = "GcpContainerClusterInstanceInboundExternalRiskModel",
    GcpContainerClusterInstanceInboundExternalRiskPolicyConfiguration = "GcpContainerClusterInstanceInboundExternalRiskPolicyConfiguration",
    GcpContainerClusterKubernetesSecretEncryptionDisabledCodeRisk = "GcpContainerClusterKubernetesSecretEncryptionDisabledCodeRisk",
    GcpContainerClusterKubernetesSecretEncryptionDisabledCodeRiskModel = "GcpContainerClusterKubernetesSecretEncryptionDisabledCodeRiskModel",
    GcpContainerClusterKubernetesSecretEncryptionDisabledRisk = "GcpContainerClusterKubernetesSecretEncryptionDisabledRisk",
    GcpContainerClusterKubernetesSecretEncryptionDisabledRiskModel = "GcpContainerClusterKubernetesSecretEncryptionDisabledRiskModel",
    GcpContainerClusterKubernetesSecretEncryptionDisabledRiskPolicyConfiguration = "GcpContainerClusterKubernetesSecretEncryptionDisabledRiskPolicyConfiguration",
    GcpContainerClusterLegacyComputeMetadataServerEnabledRisk = "GcpContainerClusterLegacyComputeMetadataServerEnabledRisk",
    GcpContainerClusterLegacyComputeMetadataServerEnabledRiskModel = "GcpContainerClusterLegacyComputeMetadataServerEnabledRiskModel",
    GcpContainerClusterLegacyComputeMetadataServerEnabledRiskPolicyConfiguration = "GcpContainerClusterLegacyComputeMetadataServerEnabledRiskPolicyConfiguration",
    GcpContainerClusterMode = "GcpContainerClusterMode",
    GcpContainerClusterModel = "GcpContainerClusterModel",
    GcpContainerClusterModelFilters = "GcpContainerClusterModelFilters",
    GcpContainerClusterNetwork = "GcpContainerClusterNetwork",
    GcpContainerClusterNodePool = "GcpContainerClusterNodePool",
    GcpContainerClusterNodePoolInstanceAutoscalingLimits = "GcpContainerClusterNodePoolInstanceAutoscalingLimits",
    GcpContainerClusterNodePoolModel = "GcpContainerClusterNodePoolModel",
    GcpContainerClusterNodePoolNode = "GcpContainerClusterNodePoolNode",
    GcpContainerClusterNodePoolNodeAutoscaling = "GcpContainerClusterNodePoolNodeAutoscaling",
    GcpContainerClusterNodePoolNodeImageType = "GcpContainerClusterNodePoolNodeImageType",
    GcpContainerClusterNodePoolProfile = "GcpContainerClusterNodePoolProfile",
    GcpContainerClusterNodePoolStatus = "GcpContainerClusterNodePoolStatus",
    GcpContainerClusterProfile = "GcpContainerClusterProfile",
    GcpContainerClusterRoleBindingsGenericResource = "GcpContainerClusterRoleBindingsGenericResource",
    GcpContainerClusterRolesGenericResource = "GcpContainerClusterRolesGenericResource",
    GcpContainerClustersGenericResource = "GcpContainerClustersGenericResource",
    GcpContainerClusterStatus = "GcpContainerClusterStatus",
    GcpContainerClusterWorkloadIdentityDisabledRisk = "GcpContainerClusterWorkloadIdentityDisabledRisk",
    GcpContainerClusterWorkloadIdentityDisabledRiskModel = "GcpContainerClusterWorkloadIdentityDisabledRiskModel",
    GcpContainerClusterWorkloadIdentityDisabledRiskNodePoolData = "GcpContainerClusterWorkloadIdentityDisabledRiskNodePoolData",
    GcpContainerClusterWorkloadIdentityDisabledRiskPolicyConfiguration = "GcpContainerClusterWorkloadIdentityDisabledRiskPolicyConfiguration",
    GcpContainerCronJobsGenericResource = "GcpContainerCronJobsGenericResource",
    GcpContainerDaemonSetsGenericResource = "GcpContainerDaemonSetsGenericResource",
    GcpContainerDeploymentsGenericResource = "GcpContainerDeploymentsGenericResource",
    GcpContainerEndpointsGenericResource = "GcpContainerEndpointsGenericResource",
    GcpContainerExtensionsDaemonSetGenericResource = "GcpContainerExtensionsDaemonSetGenericResource",
    GcpContainerExtensionsIngressGenericResource = "GcpContainerExtensionsIngressGenericResource",
    GcpContainerHorizontalPodAutoscalersGenericResource = "GcpContainerHorizontalPodAutoscalersGenericResource",
    GcpContainerImage = "GcpContainerImage",
    GcpContainerImageModel = "GcpContainerImageModel",
    GcpContainerImageModelFilters = "GcpContainerImageModelFilters",
    GcpContainerJobsGenericResource = "GcpContainerJobsGenericResource",
    GcpContainerNamespacesGenericResource = "GcpContainerNamespacesGenericResource",
    GcpContainerNetworkingIngressGenericResource = "GcpContainerNetworkingIngressGenericResource",
    GcpContainerNetworkPoliciesGenericResource = "GcpContainerNetworkPoliciesGenericResource",
    GcpContainerNodePoolsGenericResource = "GcpContainerNodePoolsGenericResource",
    GcpContainerNodePoolsGenericResourceModel = "GcpContainerNodePoolsGenericResourceModel",
    GcpContainerNodesGenericResource = "GcpContainerNodesGenericResource",
    GcpContainerPersistentVolumeClaimsGenericResource = "GcpContainerPersistentVolumeClaimsGenericResource",
    GcpContainerPersistentVolumesGenericResource = "GcpContainerPersistentVolumesGenericResource",
    GcpContainerPodsGenericResource = "GcpContainerPodsGenericResource",
    GcpContainerPodTemplatesGenericResource = "GcpContainerPodTemplatesGenericResource",
    GcpContainerReplicaSetsGenericResource = "GcpContainerReplicaSetsGenericResource",
    GcpContainerReplicationControllersGenericResource = "GcpContainerReplicationControllersGenericResource",
    GcpContainerResource = "GcpContainerResource",
    GcpContainerResourceQuotaGenericResource = "GcpContainerResourceQuotaGenericResource",
    GcpContainerRoleBindingsGenericResource = "GcpContainerRoleBindingsGenericResource",
    GcpContainerRolesGenericResource = "GcpContainerRolesGenericResource",
    GcpContainerSecretsGenericResource = "GcpContainerSecretsGenericResource",
    GcpContainerServiceAccountsGenericResource = "GcpContainerServiceAccountsGenericResource",
    GcpContainerServicesGenericResource = "GcpContainerServicesGenericResource",
    GcpContainerStatefulSetsGenericResource = "GcpContainerStatefulSetsGenericResource",
    GcpContainerStorageClassesGenericResource = "GcpContainerStorageClassesGenericResource",
    GcpCreateRoleBindingChange = "GcpCreateRoleBindingChange",
    GcpCustomActivityRiskModel = "GcpCustomActivityRiskModel",
    GcpCustomRiskModel = "GcpCustomRiskModel",
    GcpDataflowJobsGenericResource = "GcpDataflowJobsGenericResource",
    GcpDataflowJobsGenericResourceModel = "GcpDataflowJobsGenericResourceModel",
    GcpDataformCompilationResultsGenericResource = "GcpDataformCompilationResultsGenericResource",
    GcpDataformReleaseConfigsGenericResource = "GcpDataformReleaseConfigsGenericResource",
    GcpDataformRepositoriesGenericResource = "GcpDataformRepositoriesGenericResource",
    GcpDataformWorkflowConfigsGenericResource = "GcpDataformWorkflowConfigsGenericResource",
    GcpDataformWorkflowInvocationsGenericResource = "GcpDataformWorkflowInvocationsGenericResource",
    GcpDataformWorkspacesGenericResource = "GcpDataformWorkspacesGenericResource",
    GcpDataFusionDnsPeeringsGenericResource = "GcpDataFusionDnsPeeringsGenericResource",
    GcpDataFusionInstancesGenericResource = "GcpDataFusionInstancesGenericResource",
    GcpDatalineageProcessesGenericResource = "GcpDatalineageProcessesGenericResource",
    GcpDatamigrationConnectionprofilesGenericResource = "GcpDatamigrationConnectionprofilesGenericResource",
    GcpDatamigrationConversionworkspacesGenericResource = "GcpDatamigrationConversionworkspacesGenericResource",
    GcpDatamigrationMigrationjobsGenericResource = "GcpDatamigrationMigrationjobsGenericResource",
    GcpDatamigrationPrivateconnectionsGenericResource = "GcpDatamigrationPrivateconnectionsGenericResource",
    GcpDataplexAspectTypesGenericResource = "GcpDataplexAspectTypesGenericResource",
    GcpDataplexAssetsGenericResource = "GcpDataplexAssetsGenericResource",
    GcpDataplexDatascansGenericResource = "GcpDataplexDatascansGenericResource",
    GcpDataplexEntryGroupsGenericResource = "GcpDataplexEntryGroupsGenericResource",
    GcpDataplexEntryTypesGenericResource = "GcpDataplexEntryTypesGenericResource",
    GcpDataplexEnvironmentsGenericResource = "GcpDataplexEnvironmentsGenericResource",
    GcpDataplexLakesGenericResource = "GcpDataplexLakesGenericResource",
    GcpDataplexTasksGenericResource = "GcpDataplexTasksGenericResource",
    GcpDataplexZonesGenericResource = "GcpDataplexZonesGenericResource",
    GcpDataprocAutoscalingPoliciesGenericResource = "GcpDataprocAutoscalingPoliciesGenericResource",
    GcpDataprocBatchesGenericResource = "GcpDataprocBatchesGenericResource",
    GcpDataprocClustersGenericResource = "GcpDataprocClustersGenericResource",
    GcpDataprocClustersGenericResourceModel = "GcpDataprocClustersGenericResourceModel",
    GcpDataprocJobsGenericResource = "GcpDataprocJobsGenericResource",
    GcpDataprocSessionsGenericResource = "GcpDataprocSessionsGenericResource",
    GcpDataprocWorkflowTemplatesGenericResource = "GcpDataprocWorkflowTemplatesGenericResource",
    GcpDatastoreDatabasesGenericResource = "GcpDatastoreDatabasesGenericResource",
    GcpDatastreamConnectionProfilesGenericResource = "GcpDatastreamConnectionProfilesGenericResource",
    GcpDatastreamPrivateConnectionsGenericResource = "GcpDatastreamPrivateConnectionsGenericResource",
    GcpDatastreamStreamsGenericResource = "GcpDatastreamStreamsGenericResource",
    GcpDeleteRoleBindingChange = "GcpDeleteRoleBindingChange",
    GcpDeleteScopeResourcePrincipalRoleBindingsChange = "GcpDeleteScopeResourcePrincipalRoleBindingsChange",
    GcpDeleteServiceAccountChange = "GcpDeleteServiceAccountChange",
    GcpDeveloperconnectConnectionsGenericResource = "GcpDeveloperconnectConnectionsGenericResource",
    GcpDeveloperconnectGitRepositoryLinksGenericResource = "GcpDeveloperconnectGitRepositoryLinksGenericResource",
    GcpDialogflowAgentsGenericResource = "GcpDialogflowAgentsGenericResource",
    GcpDialogflowConversationProfilesGenericResource = "GcpDialogflowConversationProfilesGenericResource",
    GcpDialogflowKnowledgeBasesGenericResource = "GcpDialogflowKnowledgeBasesGenericResource",
    GcpDialogflowLocationSettingsGenericResource = "GcpDialogflowLocationSettingsGenericResource",
    GcpDisableServiceAccountChange = "GcpDisableServiceAccountChange",
    GcpDiscoveryengineCollectionsGenericResource = "GcpDiscoveryengineCollectionsGenericResource",
    GcpDiscoveryengineDataStoresGenericResource = "GcpDiscoveryengineDataStoresGenericResource",
    GcpDiscoveryengineEnginesGenericResource = "GcpDiscoveryengineEnginesGenericResource",
    GcpDlpDeidentifyTemplatesGenericResource = "GcpDlpDeidentifyTemplatesGenericResource",
    GcpDlpDiscoveryConfigsGenericResource = "GcpDlpDiscoveryConfigsGenericResource",
    GcpDlpInspectTemplatesGenericResource = "GcpDlpInspectTemplatesGenericResource",
    GcpDlpJobsGenericResource = "GcpDlpJobsGenericResource",
    GcpDlpJobTriggersGenericResource = "GcpDlpJobTriggersGenericResource",
    GcpDlpStoredInfoTypesGenericResource = "GcpDlpStoredInfoTypesGenericResource",
    GcpDnsManagedZone = "GcpDnsManagedZone",
    GcpDnsManagedZoneDnsSec = "GcpDnsManagedZoneDnsSec",
    GcpDnsManagedZoneDnsSecNotEnabledRisk = "GcpDnsManagedZoneDnsSecNotEnabledRisk",
    GcpDnsManagedZoneDnsSecNotEnabledRiskModel = "GcpDnsManagedZoneDnsSecNotEnabledRiskModel",
    GcpDnsManagedZoneDnsSecNotEnabledRiskPolicyConfiguration = "GcpDnsManagedZoneDnsSecNotEnabledRiskPolicyConfiguration",
    GcpDnsManagedZoneDnsSecSigningKeyAlgorithm = "GcpDnsManagedZoneDnsSecSigningKeyAlgorithm",
    GcpDnsManagedZoneDnsSecStatus = "GcpDnsManagedZoneDnsSecStatus",
    GcpDnsManagedZoneInsecureDnsSecKeySigningKeyAlgorithmRisk = "GcpDnsManagedZoneInsecureDnsSecKeySigningKeyAlgorithmRisk",
    GcpDnsManagedZoneInsecureDnsSecKeySigningKeyAlgorithmRiskModel = "GcpDnsManagedZoneInsecureDnsSecKeySigningKeyAlgorithmRiskModel",
    GcpDnsManagedZoneInsecureDnsSecKeySigningKeyAlgorithmRiskPolicyConfiguration = "GcpDnsManagedZoneInsecureDnsSecKeySigningKeyAlgorithmRiskPolicyConfiguration",
    GcpDnsManagedZoneInsecureDnsSecZoneSigningKeyAlgorithmRisk = "GcpDnsManagedZoneInsecureDnsSecZoneSigningKeyAlgorithmRisk",
    GcpDnsManagedZoneInsecureDnsSecZoneSigningKeyAlgorithmRiskModel = "GcpDnsManagedZoneInsecureDnsSecZoneSigningKeyAlgorithmRiskModel",
    GcpDnsManagedZoneInsecureDnsSecZoneSigningKeyAlgorithmRiskPolicyConfiguration = "GcpDnsManagedZoneInsecureDnsSecZoneSigningKeyAlgorithmRiskPolicyConfiguration",
    GcpDnsManagedZoneModel = "GcpDnsManagedZoneModel",
    GcpDnsManagedZonePrivateZoneType = "GcpDnsManagedZonePrivateZoneType",
    GcpDnsManagedZoneProfile = "GcpDnsManagedZoneProfile",
    GcpDnsManagedZoneResourceRecordSet = "GcpDnsManagedZoneResourceRecordSet",
    GcpDnsManagedZoneResourceRecordSetModel = "GcpDnsManagedZoneResourceRecordSetModel",
    GcpDnsManagedZoneResourceRecordSetProfile = "GcpDnsManagedZoneResourceRecordSetProfile",
    GcpDnsManagedZoneResourceRecordSetRoutingPolicy = "GcpDnsManagedZoneResourceRecordSetRoutingPolicy",
    GcpDnsManagedZoneResourceRecordSetType = "GcpDnsManagedZoneResourceRecordSetType",
    GcpDnsManagedZonesGenericResource = "GcpDnsManagedZonesGenericResource",
    GcpDnsPoliciesGenericResource = "GcpDnsPoliciesGenericResource",
    GcpDocumentAiHumanReviewConfigsGenericResource = "GcpDocumentAiHumanReviewConfigsGenericResource",
    GcpDocumentAiLabelerPoolsGenericResource = "GcpDocumentAiLabelerPoolsGenericResource",
    GcpDocumentAiProcessorsGenericResource = "GcpDocumentAiProcessorsGenericResource",
    GcpDocumentAiProcessorVersionsGenericResource = "GcpDocumentAiProcessorVersionsGenericResource",
    GcpDomainsRegistrationsGenericResource = "GcpDomainsRegistrationsGenericResource",
    GcpEntity = "GcpEntity",
    GcpEntityInboundExternalPortRisk = "GcpEntityInboundExternalPortRisk",
    GcpEntityInboundExternalPortRiskItem = "GcpEntityInboundExternalPortRiskItem",
    GcpEntityInboundExternalPortRiskModel = "GcpEntityInboundExternalPortRiskModel",
    GcpEntityInboundExternalPortRiskPolicyConfiguration = "GcpEntityInboundExternalPortRiskPolicyConfiguration",
    GcpEntityModel = "GcpEntityModel",
    GcpEntityModelFilters = "GcpEntityModelFilters",
    GcpEntityProfile = "GcpEntityProfile",
    GcpEssentialcontactsContactsGenericResource = "GcpEssentialcontactsContactsGenericResource",
    GcpEvent = "GcpEvent",
    GcpEventAnalysisServiceConfiguration = "GcpEventAnalysisServiceConfiguration",
    GcpEventarcTrigger = "GcpEventarcTrigger",
    GcpEventarcTriggerDestinationResourceType = "GcpEventarcTriggerDestinationResourceType",
    GcpEventarcTriggerModel = "GcpEventarcTriggerModel",
    GcpEventarcTriggerProfile = "GcpEventarcTriggerProfile",
    GcpEventarcTriggersGenericResource = "GcpEventarcTriggersGenericResource",
    GcpEventSyncerConfigurationSection = "GcpEventSyncerConfigurationSection",
    GcpExcessivePermissionGroupRisk = "GcpExcessivePermissionGroupRisk",
    GcpExcessivePermissionGroupRiskModel = "GcpExcessivePermissionGroupRiskModel",
    GcpExcessivePermissionGroupRiskPolicyConfiguration = "GcpExcessivePermissionGroupRiskPolicyConfiguration",
    GcpExcessivePermissionPrincipalRisk = "GcpExcessivePermissionPrincipalRisk",
    GcpExcessivePermissionPrincipalRiskModel = "GcpExcessivePermissionPrincipalRiskModel",
    GcpExcessivePermissionPrincipalRiskModelInfo = "GcpExcessivePermissionPrincipalRiskModelInfo",
    GcpExcessivePermissionPrincipalRiskPolicyConfiguration = "GcpExcessivePermissionPrincipalRiskPolicyConfiguration",
    GcpExcessivePermissionPrincipalRiskPolicyUpdateAuditEventInactivePrincipalDeleteGroupMembershipEnabledChange = "GcpExcessivePermissionPrincipalRiskPolicyUpdateAuditEventInactivePrincipalDeleteGroupMembershipEnabledChange",
    GcpExcessivePermissionPrincipalRiskRoleBindingData = "GcpExcessivePermissionPrincipalRiskRoleBindingData",
    GcpExcessivePermissionPrincipalRiskRoleBindingDataResolutionType = "GcpExcessivePermissionPrincipalRiskRoleBindingDataResolutionType",
    GcpExcessivePermissionPrincipalRiskRoleBindingResolution = "GcpExcessivePermissionPrincipalRiskRoleBindingResolution",
    GcpExcessivePermissionPrincipalRiskScopeRoleBindingData = "GcpExcessivePermissionPrincipalRiskScopeRoleBindingData",
    GcpExcessivePermissionServiceAccountRisk = "GcpExcessivePermissionServiceAccountRisk",
    GcpExcessivePermissionServiceAccountRiskModel = "GcpExcessivePermissionServiceAccountRiskModel",
    GcpExcessivePermissionServiceAccountRiskPolicyConfiguration = "GcpExcessivePermissionServiceAccountRiskPolicyConfiguration",
    GcpExcessivePermissionUserRisk = "GcpExcessivePermissionUserRisk",
    GcpExcessivePermissionUserRiskModel = "GcpExcessivePermissionUserRiskModel",
    GcpExcessivePermissionUserRiskPolicyConfiguration = "GcpExcessivePermissionUserRiskPolicyConfiguration",
    GcpFileBackupsGenericResource = "GcpFileBackupsGenericResource",
    GcpFileInstancesGenericResource = "GcpFileInstancesGenericResource",
    GcpFileInstancesGenericResourceModel = "GcpFileInstancesGenericResourceModel",
    GcpFileSnapshotsGenericResource = "GcpFileSnapshotsGenericResource",
    GcpFinancialservicesBacktestResultsGenericResource = "GcpFinancialservicesBacktestResultsGenericResource",
    GcpFinancialservicesDatasetsGenericResource = "GcpFinancialservicesDatasetsGenericResource",
    GcpFinancialservicesEngineConfigsGenericResource = "GcpFinancialservicesEngineConfigsGenericResource",
    GcpFinancialservicesInstancesGenericResource = "GcpFinancialservicesInstancesGenericResource",
    GcpFinancialservicesModelsGenericResource = "GcpFinancialservicesModelsGenericResource",
    GcpFinancialservicesPredictionResultsGenericResource = "GcpFinancialservicesPredictionResultsGenericResource",
    GcpFirebaseFirebaseAppInfosGenericResource = "GcpFirebaseFirebaseAppInfosGenericResource",
    GcpFirebaseFirebaseProjectsGenericResource = "GcpFirebaseFirebaseProjectsGenericResource",
    GcpFirebaseRulesReleasesGenericResource = "GcpFirebaseRulesReleasesGenericResource",
    GcpFirebaseRulesRulesetsGenericResource = "GcpFirebaseRulesRulesetsGenericResource",
    GcpFolderConfiguration = "GcpFolderConfiguration",
    GcpFolderConfigurationAnalysis = "GcpFolderConfigurationAnalysis",
    GcpFolderConfigurationSections = "GcpFolderConfigurationSections",
    GcpFunctionsFunction = "GcpFunctionsFunction",
    GcpFunctionsFunctionBuildDefaultServiceAccountRisk = "GcpFunctionsFunctionBuildDefaultServiceAccountRisk",
    GcpFunctionsFunctionBuildDefaultServiceAccountRiskModel = "GcpFunctionsFunctionBuildDefaultServiceAccountRiskModel",
    GcpFunctionsFunctionBuildDefaultServiceAccountRiskPolicyConfiguration = "GcpFunctionsFunctionBuildDefaultServiceAccountRiskPolicyConfiguration",
    GcpFunctionsFunctionEnvironmentVariable = "GcpFunctionsFunctionEnvironmentVariable",
    GcpFunctionsFunctionEnvironmentVariableSecretExistsCodeRisk = "GcpFunctionsFunctionEnvironmentVariableSecretExistsCodeRisk",
    GcpFunctionsFunctionEnvironmentVariableSecretExistsCodeRiskModel = "GcpFunctionsFunctionEnvironmentVariableSecretExistsCodeRiskModel",
    GcpFunctionsFunctionEnvironmentVariableSecretExistsRisk = "GcpFunctionsFunctionEnvironmentVariableSecretExistsRisk",
    GcpFunctionsFunctionEnvironmentVariableSecretExistsRiskEnvironmentVariableData = "GcpFunctionsFunctionEnvironmentVariableSecretExistsRiskEnvironmentVariableData",
    GcpFunctionsFunctionEnvironmentVariableSecretExistsRiskModel = "GcpFunctionsFunctionEnvironmentVariableSecretExistsRiskModel",
    GcpFunctionsFunctionEnvironmentVariableSecretExistsRiskPolicyConfiguration = "GcpFunctionsFunctionEnvironmentVariableSecretExistsRiskPolicyConfiguration",
    GcpFunctionsFunctionEnvironmentVariableType = "GcpFunctionsFunctionEnvironmentVariableType",
    GcpFunctionsFunctionModel = "GcpFunctionsFunctionModel",
    GcpFunctionsFunctionProfile = "GcpFunctionsFunctionProfile",
    GcpFunctionsFunctionPublicAccessExistsCodeRisk = "GcpFunctionsFunctionPublicAccessExistsCodeRisk",
    GcpFunctionsFunctionPublicAccessExistsCodeRiskModel = "GcpFunctionsFunctionPublicAccessExistsCodeRiskModel",
    GcpFunctionsFunctionPublicAccessExistsRisk = "GcpFunctionsFunctionPublicAccessExistsRisk",
    GcpFunctionsFunctionPublicAccessExistsRiskModel = "GcpFunctionsFunctionPublicAccessExistsRiskModel",
    GcpFunctionsFunctionPublicAccessExistsRiskPolicyConfiguration = "GcpFunctionsFunctionPublicAccessExistsRiskPolicyConfiguration",
    GcpFunctionsFunctionTriggerType = "GcpFunctionsFunctionTriggerType",
    GcpFunctionsFunctionType = "GcpFunctionsFunctionType",
    GcpGameServicesGameServerClustersGenericResource = "GcpGameServicesGameServerClustersGenericResource",
    GcpGameServicesGameServerConfigsGenericResource = "GcpGameServicesGameServerConfigsGenericResource",
    GcpGameServicesGameServerDeploymentsGenericResource = "GcpGameServicesGameServerDeploymentsGenericResource",
    GcpGameServicesRealmsGenericResource = "GcpGameServicesRealmsGenericResource",
    GcpGkebackupBackupPlansGenericResource = "GcpGkebackupBackupPlansGenericResource",
    GcpGkebackupBackupsGenericResource = "GcpGkebackupBackupsGenericResource",
    GcpGkebackupRestorePlansGenericResource = "GcpGkebackupRestorePlansGenericResource",
    GcpGkebackupRestoresGenericResource = "GcpGkebackupRestoresGenericResource",
    GcpGkebackupVolumeBackupsGenericResource = "GcpGkebackupVolumeBackupsGenericResource",
    GcpGkebackupVolumeRestoresGenericResource = "GcpGkebackupVolumeRestoresGenericResource",
    GcpGkeHubFeaturesGenericResource = "GcpGkeHubFeaturesGenericResource",
    GcpGkeHubFleetsGenericResource = "GcpGkeHubFleetsGenericResource",
    GcpGkeHubMembershipbindingsGenericResource = "GcpGkeHubMembershipbindingsGenericResource",
    GcpGkeHubMembershipsGenericResource = "GcpGkeHubMembershipsGenericResource",
    GcpGkeHubNamespacesGenericResource = "GcpGkeHubNamespacesGenericResource",
    GcpGkeHubRbacrolebindingsGenericResource = "GcpGkeHubRbacrolebindingsGenericResource",
    GcpGkeHubScopesGenericResource = "GcpGkeHubScopesGenericResource",
    GcpGkemulticloudAttachedClustersGenericResource = "GcpGkemulticloudAttachedClustersGenericResource",
    GcpGkemulticloudAwsClustersGenericResource = "GcpGkemulticloudAwsClustersGenericResource",
    GcpGkemulticloudAwsNodePoolsGenericResource = "GcpGkemulticloudAwsNodePoolsGenericResource",
    GcpGkemulticloudAzureClientsGenericResource = "GcpGkemulticloudAzureClientsGenericResource",
    GcpGkemulticloudAzureClustersGenericResource = "GcpGkemulticloudAzureClustersGenericResource",
    GcpGkemulticloudAzureNodePoolsGenericResource = "GcpGkemulticloudAzureNodePoolsGenericResource",
    GcpGkemulticloudConnectedClustersGenericResource = "GcpGkemulticloudConnectedClustersGenericResource",
    GcpGkeonpremBareMetalClustersGenericResource = "GcpGkeonpremBareMetalClustersGenericResource",
    GcpGkeonpremBareMetalNodePoolsGenericResource = "GcpGkeonpremBareMetalNodePoolsGenericResource",
    GcpGkeonpremVmwareClustersGenericResource = "GcpGkeonpremVmwareClustersGenericResource",
    GcpGkeonpremVmwareNodePoolsGenericResource = "GcpGkeonpremVmwareNodePoolsGenericResource",
    GcpHealthcareConsentStoresGenericResource = "GcpHealthcareConsentStoresGenericResource",
    GcpHealthcareDatasetsGenericResource = "GcpHealthcareDatasetsGenericResource",
    GcpHealthcareDicomStoresGenericResource = "GcpHealthcareDicomStoresGenericResource",
    GcpHealthcareFhirStoresGenericResource = "GcpHealthcareFhirStoresGenericResource",
    GcpHealthcareHl7V2StoresGenericResource = "GcpHealthcareHl7V2StoresGenericResource",
    GcpIamGcpManagedPartialServiceAccount = "GcpIamGcpManagedPartialServiceAccount",
    GcpIamGcpManagedPartialServiceAccountModel = "GcpIamGcpManagedPartialServiceAccountModel",
    GcpIamGcpManagedPartialServiceAccountProfile = "GcpIamGcpManagedPartialServiceAccountProfile",
    GcpIamPartialPrincipal = "GcpIamPartialPrincipal",
    GcpIamPartialPrincipalModel = "GcpIamPartialPrincipalModel",
    GcpIamPartialServiceAccount = "GcpIamPartialServiceAccount",
    GcpIamPartialServiceAccountModel = "GcpIamPartialServiceAccountModel",
    GcpIamPartialServiceAccountModelFilters = "GcpIamPartialServiceAccountModelFilters",
    GcpIamPartialServiceAccountProfile = "GcpIamPartialServiceAccountProfile",
    GcpIamPolicyV2sGenericResource = "GcpIamPolicyV2sGenericResource",
    GcpIamPrincipal = "GcpIamPrincipal",
    GcpIamPrincipalModel = "GcpIamPrincipalModel",
    GcpIamRole = "GcpIamRole",
    GcpIamRoleBinding = "GcpIamRoleBinding",
    GcpIamRoleBindingCondition = "GcpIamRoleBindingCondition",
    GcpIamRoleBindingDeltaAction = "GcpIamRoleBindingDeltaAction",
    GcpIamRoleBindingDeltaModel = "GcpIamRoleBindingDeltaModel",
    GcpIamRoleBindingModel = "GcpIamRoleBindingModel",
    GcpIamRoleBindingModelFilters = "GcpIamRoleBindingModelFilters",
    GcpIamRoleBindingModelPermissionUsage = "GcpIamRoleBindingModelPermissionUsage",
    GcpIamRoleBindingModelPermissionUsageType = "GcpIamRoleBindingModelPermissionUsageType",
    GcpIamRoleBindingTypeMetadata = "GcpIamRoleBindingTypeMetadata",
    GcpIamRoleModel = "GcpIamRoleModel",
    GcpIamRoleProfile = "GcpIamRoleProfile",
    GcpIamRolesGenericResource = "GcpIamRolesGenericResource",
    GcpIamRoleStage = "GcpIamRoleStage",
    GcpIamRoleType = "GcpIamRoleType",
    GcpIamServiceAccount = "GcpIamServiceAccount",
    GcpIamServiceAccountInactiveRisk = "GcpIamServiceAccountInactiveRisk",
    GcpIamServiceAccountInactiveRiskModel = "GcpIamServiceAccountInactiveRiskModel",
    GcpIamServiceAccountInactiveRiskModelInfoType = "GcpIamServiceAccountInactiveRiskModelInfoType",
    GcpIamServiceAccountInactiveRiskPolicyConfiguration = "GcpIamServiceAccountInactiveRiskPolicyConfiguration",
    GcpIamServiceAccountInactiveRiskPolicyUpdateAuditEventIncludeDisabledServiceAccountChange = "GcpIamServiceAccountInactiveRiskPolicyUpdateAuditEventIncludeDisabledServiceAccountChange",
    GcpIamServiceAccountKey = "GcpIamServiceAccountKey",
    GcpIamServiceAccountKeyModel = "GcpIamServiceAccountKeyModel",
    GcpIamServiceAccountKeyProfile = "GcpIamServiceAccountKeyProfile",
    GcpIamServiceAccountKeysGenericResource = "GcpIamServiceAccountKeysGenericResource",
    GcpIamServiceAccountKeyType = "GcpIamServiceAccountKeyType",
    GcpIamServiceAccountModel = "GcpIamServiceAccountModel",
    GcpIamServiceAccountModelFilters = "GcpIamServiceAccountModelFilters",
    GcpIamServiceAccountProfile = "GcpIamServiceAccountProfile",
    GcpIamServiceAccountsGenericResource = "GcpIamServiceAccountsGenericResource",
    GcpIamServiceAccountUnrotatedUserManagedKeyCodeRisk = "GcpIamServiceAccountUnrotatedUserManagedKeyCodeRisk",
    GcpIamServiceAccountUnrotatedUserManagedKeyCodeRiskModel = "GcpIamServiceAccountUnrotatedUserManagedKeyCodeRiskModel",
    GcpIamServiceAccountUnrotatedUserManagedKeyRisk = "GcpIamServiceAccountUnrotatedUserManagedKeyRisk",
    GcpIamServiceAccountUnrotatedUserManagedKeyRiskModel = "GcpIamServiceAccountUnrotatedUserManagedKeyRiskModel",
    GcpIamServiceAccountUnrotatedUserManagedKeyRiskPolicyConfiguration = "GcpIamServiceAccountUnrotatedUserManagedKeyRiskPolicyConfiguration",
    GcpIamServiceAccountUnrotatedUserManagedKeyRiskPolicyUpdateAuditEventKeyRotationTimeFrameChange = "GcpIamServiceAccountUnrotatedUserManagedKeyRiskPolicyUpdateAuditEventKeyRotationTimeFrameChange",
    GcpIamServiceAccountUserManagedKeyExistsCodeRisk = "GcpIamServiceAccountUserManagedKeyExistsCodeRisk",
    GcpIamServiceAccountUserManagedKeyExistsCodeRiskModel = "GcpIamServiceAccountUserManagedKeyExistsCodeRiskModel",
    GcpIamServiceAccountUserManagedKeyExistsRisk = "GcpIamServiceAccountUserManagedKeyExistsRisk",
    GcpIamServiceAccountUserManagedKeyExistsRiskModel = "GcpIamServiceAccountUserManagedKeyExistsRiskModel",
    GcpIamServiceAccountUserManagedKeyExistsRiskPolicyConfiguration = "GcpIamServiceAccountUserManagedKeyExistsRiskPolicyConfiguration",
    GcpIamWorkforcePool = "GcpIamWorkforcePool",
    GcpIamWorkforcePoolAccessRestriction = "GcpIamWorkforcePoolAccessRestriction",
    GcpIamWorkforcePoolModel = "GcpIamWorkforcePoolModel",
    GcpIamWorkforcePoolModelAccess = "GcpIamWorkforcePoolModelAccess",
    GcpIamWorkforcePoolProfile = "GcpIamWorkforcePoolProfile",
    GcpIamWorkforcePoolProvider = "GcpIamWorkforcePoolProvider",
    GcpIamWorkforcePoolProviderAttributeData = "GcpIamWorkforcePoolProviderAttributeData",
    GcpIamWorkforcePoolProviderModel = "GcpIamWorkforcePoolProviderModel",
    GcpIamWorkforcePoolProviderProfile = "GcpIamWorkforcePoolProviderProfile",
    GcpIamWorkforcePoolProviderType = "GcpIamWorkforcePoolProviderType",
    GcpIamWorkloadIdentityPool = "GcpIamWorkloadIdentityPool",
    GcpIamWorkloadIdentityPoolModel = "GcpIamWorkloadIdentityPoolModel",
    GcpIamWorkloadIdentityPoolModelAccess = "GcpIamWorkloadIdentityPoolModelAccess",
    GcpIamWorkloadIdentityPoolProfile = "GcpIamWorkloadIdentityPoolProfile",
    GcpIamWorkloadIdentityPoolProvider = "GcpIamWorkloadIdentityPoolProvider",
    GcpIamWorkloadIdentityPoolProviderAttributeData = "GcpIamWorkloadIdentityPoolProviderAttributeData",
    GcpIamWorkloadIdentityPoolProviderModel = "GcpIamWorkloadIdentityPoolProviderModel",
    GcpIamWorkloadIdentityPoolProviderProfile = "GcpIamWorkloadIdentityPoolProviderProfile",
    GcpIamWorkloadIdentityPoolProviderType = "GcpIamWorkloadIdentityPoolProviderType",
    GcpIapTunnelDestGroupsGenericResource = "GcpIapTunnelDestGroupsGenericResource",
    GcpIapTunnelInstancesGenericResource = "GcpIapTunnelInstancesGenericResource",
    GcpIapTunnelsGenericResource = "GcpIapTunnelsGenericResource",
    GcpIapTunnelZonesGenericResource = "GcpIapTunnelZonesGenericResource",
    GcpIapWebServicesGenericResource = "GcpIapWebServicesGenericResource",
    GcpIapWebServiceVersionsGenericResource = "GcpIapWebServiceVersionsGenericResource",
    GcpIapWebsGenericResource = "GcpIapWebsGenericResource",
    GcpIapWebTypesGenericResource = "GcpIapWebTypesGenericResource",
    GcpIdentityActivityRisk = "GcpIdentityActivityRisk",
    GcpIdentityActivityRiskModel = "GcpIdentityActivityRiskModel",
    GcpIdentityActivityRiskPolicyConfiguration = "GcpIdentityActivityRiskPolicyConfiguration",
    GcpIdentitytoolkitConfigsGenericResource = "GcpIdentitytoolkitConfigsGenericResource",
    GcpIdentitytoolkitDefaultSupportedIdpConfigsGenericResource = "GcpIdentitytoolkitDefaultSupportedIdpConfigsGenericResource",
    GcpIdentitytoolkitInboundSamlConfigsGenericResource = "GcpIdentitytoolkitInboundSamlConfigsGenericResource",
    GcpIdentitytoolkitOauthIdpConfigsGenericResource = "GcpIdentitytoolkitOauthIdpConfigsGenericResource",
    GcpIdentitytoolkitTenantsGenericResource = "GcpIdentitytoolkitTenantsGenericResource",
    GcpIdsEndpointsGenericResource = "GcpIdsEndpointsGenericResource",
    GcpInboundExternalComputeInstanceRisk = "GcpInboundExternalComputeInstanceRisk",
    GcpInboundExternalComputeInstanceRiskModel = "GcpInboundExternalComputeInstanceRiskModel",
    GcpInboundExternalComputeInstanceRiskPolicyConfiguration = "GcpInboundExternalComputeInstanceRiskPolicyConfiguration",
    GcpInboundExternalResourceRiskNetworkData = "GcpInboundExternalResourceRiskNetworkData",
    GcpInstanceGroupType = "GcpInstanceGroupType",
    GcpIntegrationsAuthConfigsGenericResource = "GcpIntegrationsAuthConfigsGenericResource",
    GcpIntegrationsCertificatesGenericResource = "GcpIntegrationsCertificatesGenericResource",
    GcpIntegrationsExecutionsGenericResource = "GcpIntegrationsExecutionsGenericResource",
    GcpIntegrationsIntegrationsGenericResource = "GcpIntegrationsIntegrationsGenericResource",
    GcpIntegrationsIntegrationVersionsGenericResource = "GcpIntegrationsIntegrationVersionsGenericResource",
    GcpIntegrationsSfdcChannelsGenericResource = "GcpIntegrationsSfdcChannelsGenericResource",
    GcpIntegrationsSfdcInstancesGenericResource = "GcpIntegrationsSfdcInstancesGenericResource",
    GcpIntegrationsSuspensionsGenericResource = "GcpIntegrationsSuspensionsGenericResource",
    GcpKmsKeyRing = "GcpKmsKeyRing",
    GcpKmsKeyRingKey = "GcpKmsKeyRingKey",
    GcpKmsKeyRingKeyModel = "GcpKmsKeyRingKeyModel",
    GcpKmsKeyRingKeyProfile = "GcpKmsKeyRingKeyProfile",
    GcpKmsKeyRingKeyPublicAccessExistsCodeRisk = "GcpKmsKeyRingKeyPublicAccessExistsCodeRisk",
    GcpKmsKeyRingKeyPublicAccessExistsCodeRiskModel = "GcpKmsKeyRingKeyPublicAccessExistsCodeRiskModel",
    GcpKmsKeyRingKeyPublicAccessExistsRisk = "GcpKmsKeyRingKeyPublicAccessExistsRisk",
    GcpKmsKeyRingKeyPublicAccessExistsRiskModel = "GcpKmsKeyRingKeyPublicAccessExistsRiskModel",
    GcpKmsKeyRingKeyPublicAccessExistsRiskPolicyConfiguration = "GcpKmsKeyRingKeyPublicAccessExistsRiskPolicyConfiguration",
    GcpKmsKeyRingKeyRotationTimeFrameCodeRisk = "GcpKmsKeyRingKeyRotationTimeFrameCodeRisk",
    GcpKmsKeyRingKeyRotationTimeFrameCodeRiskModel = "GcpKmsKeyRingKeyRotationTimeFrameCodeRiskModel",
    GcpKmsKeyRingKeyRotationTimeFrameRisk = "GcpKmsKeyRingKeyRotationTimeFrameRisk",
    GcpKmsKeyRingKeyRotationTimeFrameRiskModel = "GcpKmsKeyRingKeyRotationTimeFrameRiskModel",
    GcpKmsKeyRingKeyRotationTimeFrameRiskPolicyConfiguration = "GcpKmsKeyRingKeyRotationTimeFrameRiskPolicyConfiguration",
    GcpKmsKeyRingKeyRotationTimeFrameRiskPolicyUpdateAuditEventKeyRotationTimeFrameChange = "GcpKmsKeyRingKeyRotationTimeFrameRiskPolicyUpdateAuditEventKeyRotationTimeFrameChange",
    GcpKmsKeyRingKeyUsageType = "GcpKmsKeyRingKeyUsageType",
    GcpKmsKeyRingModel = "GcpKmsKeyRingModel",
    GcpKmsKeyRingProfile = "GcpKmsKeyRingProfile",
    GcpKmsKeyRingPublicAccessExistsCodeRisk = "GcpKmsKeyRingPublicAccessExistsCodeRisk",
    GcpKmsKeyRingPublicAccessExistsCodeRiskModel = "GcpKmsKeyRingPublicAccessExistsCodeRiskModel",
    GcpKmsKeyRingPublicAccessExistsRisk = "GcpKmsKeyRingPublicAccessExistsRisk",
    GcpKmsKeyRingPublicAccessExistsRiskModel = "GcpKmsKeyRingPublicAccessExistsRiskModel",
    GcpKmsKeyRingPublicAccessExistsRiskPolicyConfiguration = "GcpKmsKeyRingPublicAccessExistsRiskPolicyConfiguration",
    GcpKrmapihostingKrmApiHostsGenericResource = "GcpKrmapihostingKrmApiHostsGenericResource",
    GcpKubernetesClusterRole = "GcpKubernetesClusterRole",
    GcpKubernetesClusterRoleBinding = "GcpKubernetesClusterRoleBinding",
    GcpKubernetesClusterRoleBindingModel = "GcpKubernetesClusterRoleBindingModel",
    GcpKubernetesClusterRoleModel = "GcpKubernetesClusterRoleModel",
    GcpKubernetesConfigMap = "GcpKubernetesConfigMap",
    GcpKubernetesConfigMapModel = "GcpKubernetesConfigMapModel",
    GcpKubernetesCronJob = "GcpKubernetesCronJob",
    GcpKubernetesCronJobModel = "GcpKubernetesCronJobModel",
    GcpKubernetesDaemonSet = "GcpKubernetesDaemonSet",
    GcpKubernetesDaemonSetModel = "GcpKubernetesDaemonSetModel",
    GcpKubernetesDeployment = "GcpKubernetesDeployment",
    GcpKubernetesDeploymentModel = "GcpKubernetesDeploymentModel",
    GcpKubernetesGroup = "GcpKubernetesGroup",
    GcpKubernetesGroupModel = "GcpKubernetesGroupModel",
    GcpKubernetesIngress = "GcpKubernetesIngress",
    GcpKubernetesIngressClass = "GcpKubernetesIngressClass",
    GcpKubernetesIngressClassModel = "GcpKubernetesIngressClassModel",
    GcpKubernetesIngressModel = "GcpKubernetesIngressModel",
    GcpKubernetesJob = "GcpKubernetesJob",
    GcpKubernetesJobModel = "GcpKubernetesJobModel",
    GcpKubernetesNamespace = "GcpKubernetesNamespace",
    GcpKubernetesNamespaceModel = "GcpKubernetesNamespaceModel",
    GcpKubernetesNamespaceResource = "GcpKubernetesNamespaceResource",
    GcpKubernetesNamespaceResourceBase = "GcpKubernetesNamespaceResourceBase",
    GcpKubernetesNamespaceResourceModel = "GcpKubernetesNamespaceResourceModel",
    GcpKubernetesNamespaceRole = "GcpKubernetesNamespaceRole",
    GcpKubernetesNamespaceRoleBinding = "GcpKubernetesNamespaceRoleBinding",
    GcpKubernetesNamespaceRoleBindingModel = "GcpKubernetesNamespaceRoleBindingModel",
    GcpKubernetesNamespaceRoleModel = "GcpKubernetesNamespaceRoleModel",
    GcpKubernetesNetworkPolicy = "GcpKubernetesNetworkPolicy",
    GcpKubernetesNetworkPolicyModel = "GcpKubernetesNetworkPolicyModel",
    GcpKubernetesNode = "GcpKubernetesNode",
    GcpKubernetesNodeModel = "GcpKubernetesNodeModel",
    GcpKubernetesPersistentVolume = "GcpKubernetesPersistentVolume",
    GcpKubernetesPersistentVolumeModel = "GcpKubernetesPersistentVolumeModel",
    GcpKubernetesPod = "GcpKubernetesPod",
    GcpKubernetesPodModel = "GcpKubernetesPodModel",
    GcpKubernetesPodSecurityPolicy = "GcpKubernetesPodSecurityPolicy",
    GcpKubernetesPodSecurityPolicyModel = "GcpKubernetesPodSecurityPolicyModel",
    GcpKubernetesPodTemplate = "GcpKubernetesPodTemplate",
    GcpKubernetesPodTemplateModel = "GcpKubernetesPodTemplateModel",
    GcpKubernetesReplicaSet = "GcpKubernetesReplicaSet",
    GcpKubernetesReplicaSetModel = "GcpKubernetesReplicaSetModel",
    GcpKubernetesResource = "GcpKubernetesResource",
    GcpKubernetesResourceBase = "GcpKubernetesResourceBase",
    GcpKubernetesResourceModel = "GcpKubernetesResourceModel",
    GcpKubernetesResourceTypeMetadata = "GcpKubernetesResourceTypeMetadata",
    GcpKubernetesService = "GcpKubernetesService",
    GcpKubernetesServiceAccount = "GcpKubernetesServiceAccount",
    GcpKubernetesServiceAccountModel = "GcpKubernetesServiceAccountModel",
    GcpKubernetesServiceModel = "GcpKubernetesServiceModel",
    GcpKubernetesStatefulSet = "GcpKubernetesStatefulSet",
    GcpKubernetesStatefulSetModel = "GcpKubernetesStatefulSetModel",
    GcpKubernetesUser = "GcpKubernetesUser",
    GcpKubernetesUserModel = "GcpKubernetesUserModel",
    GcpKubernetesWorkloadResource = "GcpKubernetesWorkloadResource",
    GcpKubernetesWorkloadResourceBase = "GcpKubernetesWorkloadResourceBase",
    GcpKubernetesWorkloadResourceModel = "GcpKubernetesWorkloadResourceModel",
    GcpLivestreamAssetsGenericResource = "GcpLivestreamAssetsGenericResource",
    GcpLivestreamChannelsGenericResource = "GcpLivestreamChannelsGenericResource",
    GcpLivestreamInputsGenericResource = "GcpLivestreamInputsGenericResource",
    GcpLivestreamPoolsGenericResource = "GcpLivestreamPoolsGenericResource",
    GcpLoggingAuditLogTenantDefaultNotEnabledCodeRisk = "GcpLoggingAuditLogTenantDefaultNotEnabledCodeRisk",
    GcpLoggingAuditLogTenantDefaultNotEnabledCodeRiskModel = "GcpLoggingAuditLogTenantDefaultNotEnabledCodeRiskModel",
    GcpLoggingAuditLogTenantDefaultNotEnabledRisk = "GcpLoggingAuditLogTenantDefaultNotEnabledRisk",
    GcpLoggingAuditLogTenantDefaultNotEnabledRiskModel = "GcpLoggingAuditLogTenantDefaultNotEnabledRiskModel",
    GcpLoggingAuditLogTenantDefaultNotEnabledRiskPolicyConfiguration = "GcpLoggingAuditLogTenantDefaultNotEnabledRiskPolicyConfiguration",
    GcpLoggingBucketsGenericResource = "GcpLoggingBucketsGenericResource",
    GcpLoggingLinksGenericResource = "GcpLoggingLinksGenericResource",
    GcpLoggingLogBucket = "GcpLoggingLogBucket",
    GcpLoggingLogBucketModel = "GcpLoggingLogBucketModel",
    GcpLoggingLogBucketProfile = "GcpLoggingLogBucketProfile",
    GcpLoggingLogMetric = "GcpLoggingLogMetric",
    GcpLoggingLogMetricModel = "GcpLoggingLogMetricModel",
    GcpLoggingLogMetricProfile = "GcpLoggingLogMetricProfile",
    GcpLoggingLogMetricsGenericResource = "GcpLoggingLogMetricsGenericResource",
    GcpLoggingLogMetricType = "GcpLoggingLogMetricType",
    GcpLoggingLogSink = "GcpLoggingLogSink",
    GcpLoggingLogSinkModel = "GcpLoggingLogSinkModel",
    GcpLoggingLogSinkProfile = "GcpLoggingLogSinkProfile",
    GcpLoggingLogViewsGenericResource = "GcpLoggingLogViewsGenericResource",
    GcpLoggingRecentQueriesGenericResource = "GcpLoggingRecentQueriesGenericResource",
    GcpLoggingResourceModel = "GcpLoggingResourceModel",
    GcpLoggingSavedQueriesGenericResource = "GcpLoggingSavedQueriesGenericResource",
    GcpLoggingSettingsGenericResource = "GcpLoggingSettingsGenericResource",
    GcpLoggingSinksGenericResource = "GcpLoggingSinksGenericResource",
    GcpLookerInstancesGenericResource = "GcpLookerInstancesGenericResource",
    GcpManagedIdentitiesDomainsGenericResource = "GcpManagedIdentitiesDomainsGenericResource",
    GcpManagedkafkaClustersGenericResource = "GcpManagedkafkaClustersGenericResource",
    GcpMemcacheInstancesGenericResource = "GcpMemcacheInstancesGenericResource",
    GcpMemcacheInstancesGenericResourceModel = "GcpMemcacheInstancesGenericResourceModel",
    GcpMetastoreBackupsGenericResource = "GcpMetastoreBackupsGenericResource",
    GcpMetastoreImportsGenericResource = "GcpMetastoreImportsGenericResource",
    GcpMetastoreServicesGenericResource = "GcpMetastoreServicesGenericResource",
    GcpMetastoreServicesGenericResourceModel = "GcpMetastoreServicesGenericResourceModel",
    GcpMonitoringAlertPoliciesGenericResource = "GcpMonitoringAlertPoliciesGenericResource",
    GcpMonitoringAlertPolicy = "GcpMonitoringAlertPolicy",
    GcpMonitoringAlertPolicyAuditConfigurationChangesNotExistRisk = "GcpMonitoringAlertPolicyAuditConfigurationChangesNotExistRisk",
    GcpMonitoringAlertPolicyAuditConfigurationChangesNotExistRiskModel = "GcpMonitoringAlertPolicyAuditConfigurationChangesNotExistRiskModel",
    GcpMonitoringAlertPolicyAuditConfigurationChangesNotExistRiskPolicyConfiguration = "GcpMonitoringAlertPolicyAuditConfigurationChangesNotExistRiskPolicyConfiguration",
    GcpMonitoringAlertPolicyCloudSqlInstanceConfigurationChangesNotExistRisk = "GcpMonitoringAlertPolicyCloudSqlInstanceConfigurationChangesNotExistRisk",
    GcpMonitoringAlertPolicyCloudSqlInstanceConfigurationChangesNotExistRiskModel = "GcpMonitoringAlertPolicyCloudSqlInstanceConfigurationChangesNotExistRiskModel",
    GcpMonitoringAlertPolicyCloudSqlInstanceConfigurationChangesNotExistRiskPolicyConfiguration = "GcpMonitoringAlertPolicyCloudSqlInstanceConfigurationChangesNotExistRiskPolicyConfiguration",
    GcpMonitoringAlertPolicyComputeFirewallRuleChangesNotExistRisk = "GcpMonitoringAlertPolicyComputeFirewallRuleChangesNotExistRisk",
    GcpMonitoringAlertPolicyComputeFirewallRuleChangesNotExistRiskModel = "GcpMonitoringAlertPolicyComputeFirewallRuleChangesNotExistRiskModel",
    GcpMonitoringAlertPolicyComputeFirewallRuleChangesNotExistRiskPolicyConfiguration = "GcpMonitoringAlertPolicyComputeFirewallRuleChangesNotExistRiskPolicyConfiguration",
    GcpMonitoringAlertPolicyComputeRouteChangesNotExistRisk = "GcpMonitoringAlertPolicyComputeRouteChangesNotExistRisk",
    GcpMonitoringAlertPolicyComputeRouteChangesNotExistRiskModel = "GcpMonitoringAlertPolicyComputeRouteChangesNotExistRiskModel",
    GcpMonitoringAlertPolicyComputeRouteChangesNotExistRiskPolicyConfiguration = "GcpMonitoringAlertPolicyComputeRouteChangesNotExistRiskPolicyConfiguration",
    GcpMonitoringAlertPolicyComputeVpcChangesNotExistRisk = "GcpMonitoringAlertPolicyComputeVpcChangesNotExistRisk",
    GcpMonitoringAlertPolicyComputeVpcChangesNotExistRiskModel = "GcpMonitoringAlertPolicyComputeVpcChangesNotExistRiskModel",
    GcpMonitoringAlertPolicyComputeVpcChangesNotExistRiskPolicyConfiguration = "GcpMonitoringAlertPolicyComputeVpcChangesNotExistRiskPolicyConfiguration",
    GcpMonitoringAlertPolicyCondition = "GcpMonitoringAlertPolicyCondition",
    GcpMonitoringAlertPolicyConditionType = "GcpMonitoringAlertPolicyConditionType",
    GcpMonitoringAlertPolicyIamPolicyChangesNotExistRisk = "GcpMonitoringAlertPolicyIamPolicyChangesNotExistRisk",
    GcpMonitoringAlertPolicyIamPolicyChangesNotExistRiskModel = "GcpMonitoringAlertPolicyIamPolicyChangesNotExistRiskModel",
    GcpMonitoringAlertPolicyIamPolicyChangesNotExistRiskPolicyConfiguration = "GcpMonitoringAlertPolicyIamPolicyChangesNotExistRiskPolicyConfiguration",
    GcpMonitoringAlertPolicyIamRoleChangesNotExistRisk = "GcpMonitoringAlertPolicyIamRoleChangesNotExistRisk",
    GcpMonitoringAlertPolicyIamRoleChangesNotExistRiskModel = "GcpMonitoringAlertPolicyIamRoleChangesNotExistRiskModel",
    GcpMonitoringAlertPolicyIamRoleChangesNotExistRiskPolicyConfiguration = "GcpMonitoringAlertPolicyIamRoleChangesNotExistRiskPolicyConfiguration",
    GcpMonitoringAlertPolicyModel = "GcpMonitoringAlertPolicyModel",
    GcpMonitoringAlertPolicyNotExistRisk = "GcpMonitoringAlertPolicyNotExistRisk",
    GcpMonitoringAlertPolicyNotExistRiskModel = "GcpMonitoringAlertPolicyNotExistRiskModel",
    GcpMonitoringAlertPolicyNotExistRiskTypeMetadata = "GcpMonitoringAlertPolicyNotExistRiskTypeMetadata",
    GcpMonitoringAlertPolicyProfile = "GcpMonitoringAlertPolicyProfile",
    GcpMonitoringAlertPolicyProjectOwnershipChangesNotExistRisk = "GcpMonitoringAlertPolicyProjectOwnershipChangesNotExistRisk",
    GcpMonitoringAlertPolicyProjectOwnershipChangesNotExistRiskModel = "GcpMonitoringAlertPolicyProjectOwnershipChangesNotExistRiskModel",
    GcpMonitoringAlertPolicyProjectOwnershipChangesNotExistRiskPolicyConfiguration = "GcpMonitoringAlertPolicyProjectOwnershipChangesNotExistRiskPolicyConfiguration",
    GcpMonitoringDashboardsGenericResource = "GcpMonitoringDashboardsGenericResource",
    GcpMonitoringNotificationChannelsGenericResource = "GcpMonitoringNotificationChannelsGenericResource",
    GcpMonitoringSnoozesGenericResource = "GcpMonitoringSnoozesGenericResource",
    GcpMonitoringUptimeCheckConfigsGenericResource = "GcpMonitoringUptimeCheckConfigsGenericResource",
    GcpNetappActiveDirectoriesGenericResource = "GcpNetappActiveDirectoriesGenericResource",
    GcpNetappBackupPoliciesGenericResource = "GcpNetappBackupPoliciesGenericResource",
    GcpNetappBackupsGenericResource = "GcpNetappBackupsGenericResource",
    GcpNetappBackupVaultsGenericResource = "GcpNetappBackupVaultsGenericResource",
    GcpNetappKmsConfigsGenericResource = "GcpNetappKmsConfigsGenericResource",
    GcpNetappReplicationsGenericResource = "GcpNetappReplicationsGenericResource",
    GcpNetappSnapshotsGenericResource = "GcpNetappSnapshotsGenericResource",
    GcpNetappStoragePoolsGenericResource = "GcpNetappStoragePoolsGenericResource",
    GcpNetappVolumesGenericResource = "GcpNetappVolumesGenericResource",
    GcpNetworkAccessResourceStateNetwork = "GcpNetworkAccessResourceStateNetwork",
    GcpNetworkconnectivityHubRoutesGenericResource = "GcpNetworkconnectivityHubRoutesGenericResource",
    GcpNetworkconnectivityHubsGenericResource = "GcpNetworkconnectivityHubsGenericResource",
    GcpNetworkconnectivityPolicyBasedRoutesGenericResource = "GcpNetworkconnectivityPolicyBasedRoutesGenericResource",
    GcpNetworkconnectivityRouteTablesGenericResource = "GcpNetworkconnectivityRouteTablesGenericResource",
    GcpNetworkconnectivitySpokesGenericResource = "GcpNetworkconnectivitySpokesGenericResource",
    GcpNetworkManagementConnectivitytestsGenericResource = "GcpNetworkManagementConnectivitytestsGenericResource",
    GcpNetworkScope = "GcpNetworkScope",
    GcpNetworkSecurityAddressGroupsGenericResource = "GcpNetworkSecurityAddressGroupsGenericResource",
    GcpNetworkSecurityFirewallEndpointAssociationsGenericResource = "GcpNetworkSecurityFirewallEndpointAssociationsGenericResource",
    GcpNetworkSecurityFirewallEndpointsGenericResource = "GcpNetworkSecurityFirewallEndpointsGenericResource",
    GcpNetworkSecurityGatewaySecurityPoliciesGenericResource = "GcpNetworkSecurityGatewaySecurityPoliciesGenericResource",
    GcpNetworkSecurityGatewaySecurityPolicyRulesGenericResource = "GcpNetworkSecurityGatewaySecurityPolicyRulesGenericResource",
    GcpNetworkSecuritySecurityProfileGroupsGenericResource = "GcpNetworkSecuritySecurityProfileGroupsGenericResource",
    GcpNetworkSecuritySecurityProfilesGenericResource = "GcpNetworkSecuritySecurityProfilesGenericResource",
    GcpNetworkSecurityTlsInspectionPoliciesGenericResource = "GcpNetworkSecurityTlsInspectionPoliciesGenericResource",
    GcpNetworkSecurityUrlListsGenericResource = "GcpNetworkSecurityUrlListsGenericResource",
    GcpNetworkServicesEdgeCacheKeysetsGenericResource = "GcpNetworkServicesEdgeCacheKeysetsGenericResource",
    GcpNetworkServicesEdgeCacheOriginsGenericResource = "GcpNetworkServicesEdgeCacheOriginsGenericResource",
    GcpNetworkServicesEdgeCacheServicesGenericResource = "GcpNetworkServicesEdgeCacheServicesGenericResource",
    GcpNetworkServicesEndpointPoliciesGenericResource = "GcpNetworkServicesEndpointPoliciesGenericResource",
    GcpNetworkServicesGatewaysGenericResource = "GcpNetworkServicesGatewaysGenericResource",
    GcpNetworkServicesGrpcRoutesGenericResource = "GcpNetworkServicesGrpcRoutesGenericResource",
    GcpNetworkServicesHttpRoutesGenericResource = "GcpNetworkServicesHttpRoutesGenericResource",
    GcpNetworkServicesLbRouteExtensionsGenericResource = "GcpNetworkServicesLbRouteExtensionsGenericResource",
    GcpNetworkServicesLbTrafficExtensionsGenericResource = "GcpNetworkServicesLbTrafficExtensionsGenericResource",
    GcpNetworkServicesMeshesGenericResource = "GcpNetworkServicesMeshesGenericResource",
    GcpNetworkServicesServiceBindingsGenericResource = "GcpNetworkServicesServiceBindingsGenericResource",
    GcpNetworkServicesServiceLbPoliciesGenericResource = "GcpNetworkServicesServiceLbPoliciesGenericResource",
    GcpNetworkServicesTcpRoutesGenericResource = "GcpNetworkServicesTcpRoutesGenericResource",
    GcpNetworkServicesTlsRoutesGenericResource = "GcpNetworkServicesTlsRoutesGenericResource",
    GcpNetworkServicesWasmPluginsGenericResource = "GcpNetworkServicesWasmPluginsGenericResource",
    GcpNetworkServicesWasmPluginVersionsGenericResource = "GcpNetworkServicesWasmPluginVersionsGenericResource",
    GcpNotebooksInstance = "GcpNotebooksInstance",
    GcpNotebooksInstanceBootIntegrityValidationNotEnabledRisk = "GcpNotebooksInstanceBootIntegrityValidationNotEnabledRisk",
    GcpNotebooksInstanceBootIntegrityValidationNotEnabledRiskModel = "GcpNotebooksInstanceBootIntegrityValidationNotEnabledRiskModel",
    GcpNotebooksInstanceBootIntegrityValidationNotEnabledRiskPolicyConfiguration = "GcpNotebooksInstanceBootIntegrityValidationNotEnabledRiskPolicyConfiguration",
    GcpNotebooksInstanceDefaultEncryptionRisk = "GcpNotebooksInstanceDefaultEncryptionRisk",
    GcpNotebooksInstanceDefaultEncryptionRiskModel = "GcpNotebooksInstanceDefaultEncryptionRiskModel",
    GcpNotebooksInstanceDefaultEncryptionRiskPolicyConfiguration = "GcpNotebooksInstanceDefaultEncryptionRiskPolicyConfiguration",
    GcpNotebooksInstanceModel = "GcpNotebooksInstanceModel",
    GcpNotebooksInstanceProfile = "GcpNotebooksInstanceProfile",
    GcpNotebooksInstanceRootAccessEnabledRisk = "GcpNotebooksInstanceRootAccessEnabledRisk",
    GcpNotebooksInstanceRootAccessEnabledRiskModel = "GcpNotebooksInstanceRootAccessEnabledRiskModel",
    GcpNotebooksInstanceRootAccessEnabledRiskPolicyConfiguration = "GcpNotebooksInstanceRootAccessEnabledRiskPolicyConfiguration",
    GcpNotebooksInstanceSecureBootNotEnabledRisk = "GcpNotebooksInstanceSecureBootNotEnabledRisk",
    GcpNotebooksInstanceSecureBootNotEnabledRiskModel = "GcpNotebooksInstanceSecureBootNotEnabledRiskModel",
    GcpNotebooksInstanceSecureBootNotEnabledRiskPolicyConfiguration = "GcpNotebooksInstanceSecureBootNotEnabledRiskPolicyConfiguration",
    GcpNotebooksInstancesGenericResource = "GcpNotebooksInstancesGenericResource",
    GcpNotebooksInstanceStatus = "GcpNotebooksInstanceStatus",
    GcpNotebooksInstanceVirtualTpmNotEnabledRisk = "GcpNotebooksInstanceVirtualTpmNotEnabledRisk",
    GcpNotebooksInstanceVirtualTpmNotEnabledRiskModel = "GcpNotebooksInstanceVirtualTpmNotEnabledRiskModel",
    GcpNotebooksInstanceVirtualTpmNotEnabledRiskPolicyConfiguration = "GcpNotebooksInstanceVirtualTpmNotEnabledRiskPolicyConfiguration",
    GcpOpenShiftBuildConfig = "GcpOpenShiftBuildConfig",
    GcpOpenShiftBuildConfigModel = "GcpOpenShiftBuildConfigModel",
    GcpOpenShiftCatalogSource = "GcpOpenShiftCatalogSource",
    GcpOpenShiftCatalogSourceModel = "GcpOpenShiftCatalogSourceModel",
    GcpOpenShiftDeploymentConfig = "GcpOpenShiftDeploymentConfig",
    GcpOpenShiftDeploymentConfigModel = "GcpOpenShiftDeploymentConfigModel",
    GcpOpenShiftImageStream = "GcpOpenShiftImageStream",
    GcpOpenShiftImageStreamModel = "GcpOpenShiftImageStreamModel",
    GcpOpenShiftProject = "GcpOpenShiftProject",
    GcpOpenShiftProjectModel = "GcpOpenShiftProjectModel",
    GcpOpenShiftRoute = "GcpOpenShiftRoute",
    GcpOpenShiftRouteModel = "GcpOpenShiftRouteModel",
    GcpOpenShiftSecurityContextConstraint = "GcpOpenShiftSecurityContextConstraint",
    GcpOpenShiftSecurityContextConstraintModel = "GcpOpenShiftSecurityContextConstraintModel",
    GcpOpenShiftTemplate = "GcpOpenShiftTemplate",
    GcpOpenShiftTemplateModel = "GcpOpenShiftTemplateModel",
    GcpOrganizationAuditEvent = "GcpOrganizationAuditEvent",
    GcpOrganizationAuditEventData = "GcpOrganizationAuditEventData",
    GcpOrganizationConfiguration = "GcpOrganizationConfiguration",
    GcpOrganizationCreationAuditEvent = "GcpOrganizationCreationAuditEvent",
    GcpOrganizationDeletionAuditEvent = "GcpOrganizationDeletionAuditEvent",
    GcpOrganizationState = "GcpOrganizationState",
    GcpOrganizationUpdateAuditEvent = "GcpOrganizationUpdateAuditEvent",
    GcpOrgPolicyCustomConstraintsGenericResource = "GcpOrgPolicyCustomConstraintsGenericResource",
    GcpOrgPolicyPoliciesGenericResource = "GcpOrgPolicyPoliciesGenericResource",
    GcpOsconfigOsPolicyAssignmentReportsGenericResource = "GcpOsconfigOsPolicyAssignmentReportsGenericResource",
    GcpOsconfigOsPolicyAssignmentsGenericResource = "GcpOsconfigOsPolicyAssignmentsGenericResource",
    GcpOsconfigPatchDeploymentsGenericResource = "GcpOsconfigPatchDeploymentsGenericResource",
    GcpOsconfigVulnerabilityReportsGenericResource = "GcpOsconfigVulnerabilityReportsGenericResource",
    GcpPartialEntity = "GcpPartialEntity",
    GcpPartialEntityModel = "GcpPartialEntityModel",
    GcpPartialEntityTypeMetadata = "GcpPartialEntityTypeMetadata",
    GcpPartialSpecialGroup = "GcpPartialSpecialGroup",
    GcpPartialSpecialGroupModel = "GcpPartialSpecialGroupModel",
    GcpPermissionManagementRisk = "GcpPermissionManagementRisk",
    GcpPermissionManagementRiskModel = "GcpPermissionManagementRiskModel",
    GcpPermissionManagementRiskPolicyConfiguration = "GcpPermissionManagementRiskPolicyConfiguration",
    GcpPrincipalAllowedResourcePermissionRisk = "GcpPrincipalAllowedResourcePermissionRisk",
    GcpPrincipalAllowedResourcePermissionRiskModel = "GcpPrincipalAllowedResourcePermissionRiskModel",
    GcpPrincipalAllowedResourcePermissionRiskPolicyConfiguration = "GcpPrincipalAllowedResourcePermissionRiskPolicyConfiguration",
    GcpPrincipalNotAllowedResourcePermissionRisk = "GcpPrincipalNotAllowedResourcePermissionRisk",
    GcpPrincipalNotAllowedResourcePermissionRiskModel = "GcpPrincipalNotAllowedResourcePermissionRiskModel",
    GcpPrincipalNotAllowedResourcePermissionRiskPolicyConfiguration = "GcpPrincipalNotAllowedResourcePermissionRiskPolicyConfiguration",
    GcpPrincipalNotAllowedResourceRoleRisk = "GcpPrincipalNotAllowedResourceRoleRisk",
    GcpPrincipalNotAllowedResourceRoleRiskItem = "GcpPrincipalNotAllowedResourceRoleRiskItem",
    GcpPrincipalNotAllowedResourceRoleRiskModel = "GcpPrincipalNotAllowedResourceRoleRiskModel",
    GcpPrincipalNotAllowedResourceRoleRiskPolicyConfiguration = "GcpPrincipalNotAllowedResourceRoleRiskPolicyConfiguration",
    GcpPrincipalNotAllowedResourceRoleRiskPolicyConfigurationRoles = "GcpPrincipalNotAllowedResourceRoleRiskPolicyConfigurationRoles",
    GcpPrincipalNotAllowedResourceRoleRiskPolicyConfigurationRolesOperator = "GcpPrincipalNotAllowedResourceRoleRiskPolicyConfigurationRolesOperator",
    GcpPrincipalResourcePermissionRisk = "GcpPrincipalResourcePermissionRisk",
    GcpPrincipalResourcePermissionRiskItem = "GcpPrincipalResourcePermissionRiskItem",
    GcpPrincipalResourcePermissionRiskModel = "GcpPrincipalResourcePermissionRiskModel",
    GcpPrincipalResourcePermissionRiskPolicyConfiguration = "GcpPrincipalResourcePermissionRiskPolicyConfiguration",
    GcpPrincipalServiceAccountWideScopeAdministratorRoleCodeRisk = "GcpPrincipalServiceAccountWideScopeAdministratorRoleCodeRisk",
    GcpPrincipalServiceAccountWideScopeAdministratorRoleCodeRiskModel = "GcpPrincipalServiceAccountWideScopeAdministratorRoleCodeRiskModel",
    GcpPrincipalServiceAccountWideScopeAdministratorRoleRisk = "GcpPrincipalServiceAccountWideScopeAdministratorRoleRisk",
    GcpPrincipalServiceAccountWideScopeAdministratorRoleRiskModel = "GcpPrincipalServiceAccountWideScopeAdministratorRoleRiskModel",
    GcpPrincipalServiceAccountWideScopeAdministratorRoleRiskPolicyConfiguration = "GcpPrincipalServiceAccountWideScopeAdministratorRoleRiskPolicyConfiguration",
    GcpPrincipalServiceAccountWideScopeImpersonateServiceAccountActionExistsCodeRisk = "GcpPrincipalServiceAccountWideScopeImpersonateServiceAccountActionExistsCodeRisk",
    GcpPrincipalServiceAccountWideScopeImpersonateServiceAccountActionExistsCodeRiskModel = "GcpPrincipalServiceAccountWideScopeImpersonateServiceAccountActionExistsCodeRiskModel",
    GcpPrincipalServiceAccountWideScopeImpersonateServiceAccountActionExistsRisk = "GcpPrincipalServiceAccountWideScopeImpersonateServiceAccountActionExistsRisk",
    GcpPrincipalServiceAccountWideScopeImpersonateServiceAccountActionExistsRiskModel = "GcpPrincipalServiceAccountWideScopeImpersonateServiceAccountActionExistsRiskModel",
    GcpPrincipalServiceAccountWideScopeImpersonateServiceAccountActionExistsRiskPolicyConfiguration = "GcpPrincipalServiceAccountWideScopeImpersonateServiceAccountActionExistsRiskPolicyConfiguration",
    GcpPrincipalUserWideScopeImpersonateServiceAccountActionExistsCodeRisk = "GcpPrincipalUserWideScopeImpersonateServiceAccountActionExistsCodeRisk",
    GcpPrincipalUserWideScopeImpersonateServiceAccountActionExistsCodeRiskModel = "GcpPrincipalUserWideScopeImpersonateServiceAccountActionExistsCodeRiskModel",
    GcpPrincipalUserWideScopeImpersonateServiceAccountActionExistsRisk = "GcpPrincipalUserWideScopeImpersonateServiceAccountActionExistsRisk",
    GcpPrincipalUserWideScopeImpersonateServiceAccountActionExistsRiskModel = "GcpPrincipalUserWideScopeImpersonateServiceAccountActionExistsRiskModel",
    GcpPrincipalUserWideScopeImpersonateServiceAccountActionExistsRiskPolicyConfiguration = "GcpPrincipalUserWideScopeImpersonateServiceAccountActionExistsRiskPolicyConfiguration",
    GcpPrincipalWideScopeImpersonateServiceAccountActionExistsRisk = "GcpPrincipalWideScopeImpersonateServiceAccountActionExistsRisk",
    GcpPrincipalWideScopeImpersonateServiceAccountActionExistsRiskModel = "GcpPrincipalWideScopeImpersonateServiceAccountActionExistsRiskModel",
    GcpPrivateCaCaPoolsGenericResource = "GcpPrivateCaCaPoolsGenericResource",
    GcpPrivateCaCertificateAuthoritiesGenericResource = "GcpPrivateCaCertificateAuthoritiesGenericResource",
    GcpPrivateCaCertificateRevocationListsGenericResource = "GcpPrivateCaCertificateRevocationListsGenericResource",
    GcpPrivateCaCertificatesGenericResource = "GcpPrivateCaCertificatesGenericResource",
    GcpPrivateCaCertificateTemplatesGenericResource = "GcpPrivateCaCertificateTemplatesGenericResource",
    GcpPrivilegedaccessmanagerGrantsGenericResource = "GcpPrivilegedaccessmanagerGrantsGenericResource",
    GcpPubSubSchemasGenericResource = "GcpPubSubSchemasGenericResource",
    GcpPubSubSnapshotsGenericResource = "GcpPubSubSnapshotsGenericResource",
    GcpPubSubSnapshotsGenericResourceModel = "GcpPubSubSnapshotsGenericResourceModel",
    GcpPubSubSubscription = "GcpPubSubSubscription",
    GcpPubSubSubscriptionMessageDeliveryRetryPolicy = "GcpPubSubSubscriptionMessageDeliveryRetryPolicy",
    GcpPubSubSubscriptionMessageDeliverySettings = "GcpPubSubSubscriptionMessageDeliverySettings",
    GcpPubSubSubscriptionMessageDeliveryType = "GcpPubSubSubscriptionMessageDeliveryType",
    GcpPubSubSubscriptionModel = "GcpPubSubSubscriptionModel",
    GcpPubSubSubscriptionProfile = "GcpPubSubSubscriptionProfile",
    GcpPubSubSubscriptionPublicAccessExistsCodeRisk = "GcpPubSubSubscriptionPublicAccessExistsCodeRisk",
    GcpPubSubSubscriptionPublicAccessExistsCodeRiskModel = "GcpPubSubSubscriptionPublicAccessExistsCodeRiskModel",
    GcpPubSubSubscriptionPublicAccessExistsRisk = "GcpPubSubSubscriptionPublicAccessExistsRisk",
    GcpPubSubSubscriptionPublicAccessExistsRiskModel = "GcpPubSubSubscriptionPublicAccessExistsRiskModel",
    GcpPubSubSubscriptionPublicAccessExistsRiskPolicyConfiguration = "GcpPubSubSubscriptionPublicAccessExistsRiskPolicyConfiguration",
    GcpPubSubSubscriptionsGenericResource = "GcpPubSubSubscriptionsGenericResource",
    GcpPubSubSubscriptionStatus = "GcpPubSubSubscriptionStatus",
    GcpPubSubTopic = "GcpPubSubTopic",
    GcpPubSubTopicModel = "GcpPubSubTopicModel",
    GcpPubSubTopicProfile = "GcpPubSubTopicProfile",
    GcpPubSubTopicPublicAccessExistsCodeRisk = "GcpPubSubTopicPublicAccessExistsCodeRisk",
    GcpPubSubTopicPublicAccessExistsCodeRiskModel = "GcpPubSubTopicPublicAccessExistsCodeRiskModel",
    GcpPubSubTopicPublicAccessExistsRisk = "GcpPubSubTopicPublicAccessExistsRisk",
    GcpPubSubTopicPublicAccessExistsRiskModel = "GcpPubSubTopicPublicAccessExistsRiskModel",
    GcpPubSubTopicPublicAccessExistsRiskPolicyConfiguration = "GcpPubSubTopicPublicAccessExistsRiskPolicyConfiguration",
    GcpPubSubTopicsGenericResource = "GcpPubSubTopicsGenericResource",
    GcpRecaptchaEnterpriseFirewallpoliciesGenericResource = "GcpRecaptchaEnterpriseFirewallpoliciesGenericResource",
    GcpRecaptchaEnterpriseKeysGenericResource = "GcpRecaptchaEnterpriseKeysGenericResource",
    GcpRedisClustersGenericResource = "GcpRedisClustersGenericResource",
    GcpRedisInstancesGenericResource = "GcpRedisInstancesGenericResource",
    GcpRedisInstancesGenericResourceModel = "GcpRedisInstancesGenericResourceModel",
    GcpResource = "GcpResource",
    GcpResourceAssetPathType = "GcpResourceAssetPathType",
    GcpResourceChange = "GcpResourceChange",
    GcpResourceCodeResourceNotExistsRisk = "GcpResourceCodeResourceNotExistsRisk",
    GcpResourceCodeResourceNotExistsRiskModel = "GcpResourceCodeResourceNotExistsRiskModel",
    GcpResourceCodeResourceNotExistsRiskPolicyConfiguration = "GcpResourceCodeResourceNotExistsRiskPolicyConfiguration",
    GcpResourceManagerFolder = "GcpResourceManagerFolder",
    GcpResourceManagerFolderModel = "GcpResourceManagerFolderModel",
    GcpResourceManagerFolderProfile = "GcpResourceManagerFolderProfile",
    GcpResourceManagerFoldersGenericResource = "GcpResourceManagerFoldersGenericResource",
    GcpResourceManagerLiensGenericResource = "GcpResourceManagerLiensGenericResource",
    GcpResourceManagerOrganization = "GcpResourceManagerOrganization",
    GcpResourceManagerOrganizationModel = "GcpResourceManagerOrganizationModel",
    GcpResourceManagerOrganizationProfile = "GcpResourceManagerOrganizationProfile",
    GcpResourceManagerOrganizationsGenericResource = "GcpResourceManagerOrganizationsGenericResource",
    GcpResourceManagerProjectsGenericResource = "GcpResourceManagerProjectsGenericResource",
    GcpResourceManagerResource = "GcpResourceManagerResource",
    GcpResourceManagerResourceProfile = "GcpResourceManagerResourceProfile",
    GcpResourceManagerTagBindingsGenericResource = "GcpResourceManagerTagBindingsGenericResource",
    GcpResourceManagerTagKeysGenericResource = "GcpResourceManagerTagKeysGenericResource",
    GcpResourceManagerTagValuesGenericResource = "GcpResourceManagerTagValuesGenericResource",
    GcpResourceModel = "GcpResourceModel",
    GcpResourceModelFilters = "GcpResourceModelFilters",
    GcpResourceProfile = "GcpResourceProfile",
    GcpResourceProfileCreation = "GcpResourceProfileCreation",
    GcpResourceTagNotExistsRisk = "GcpResourceTagNotExistsRisk",
    GcpResourceTagNotExistsRiskModel = "GcpResourceTagNotExistsRiskModel",
    GcpResourceTagNotExistsRiskPolicyConfiguration = "GcpResourceTagNotExistsRiskPolicyConfiguration",
    GcpResourceTagSecretExistsRisk = "GcpResourceTagSecretExistsRisk",
    GcpResourceTagSecretExistsRiskModel = "GcpResourceTagSecretExistsRiskModel",
    GcpResourceTagSecretExistsRiskPolicyConfiguration = "GcpResourceTagSecretExistsRiskPolicyConfiguration",
    GcpResourceTypeMetadata = "GcpResourceTypeMetadata",
    GcpRoleBindingEligibilityConfiguration = "GcpRoleBindingEligibilityConfiguration",
    GcpRoleBindingEligibilityModel = "GcpRoleBindingEligibilityModel",
    GcpRoleBindingRequest = "GcpRoleBindingRequest",
    GcpRoleBindingRequestActivationFailureData = "GcpRoleBindingRequestActivationFailureData",
    GcpRunDomainMappingsGenericResource = "GcpRunDomainMappingsGenericResource",
    GcpRunExecutionsGenericResource = "GcpRunExecutionsGenericResource",
    GcpRunJobsGenericResource = "GcpRunJobsGenericResource",
    GcpRunRevisionsGenericResource = "GcpRunRevisionsGenericResource",
    GcpRunServicesGenericResource = "GcpRunServicesGenericResource",
    GcpScopeConfigurationDataAnalysis = "GcpScopeConfigurationDataAnalysis",
    GcpScopeConfigurationOutpost = "GcpScopeConfigurationOutpost",
    GcpScopeConfigurationOutpostRegionData = "GcpScopeConfigurationOutpostRegionData",
    GcpScopeConfigurationSections = "GcpScopeConfigurationSections",
    GcpScopeConfigurationWorkloadAnalysis = "GcpScopeConfigurationWorkloadAnalysis",
    GcpScopeResource = "GcpScopeResource",
    GcpScopeResourceAccessLevel = "GcpScopeResourceAccessLevel",
    GcpScopeResourceModel = "GcpScopeResourceModel",
    GcpScopeResourceParentScopeResourceIdMatchCondition = "GcpScopeResourceParentScopeResourceIdMatchCondition",
    GcpScopeResourcePublicAccessExistsRisk = "GcpScopeResourcePublicAccessExistsRisk",
    GcpScopeResourcePublicAccessExistsRiskModel = "GcpScopeResourcePublicAccessExistsRiskModel",
    GcpScopeResourceReference = "GcpScopeResourceReference",
    GcpScopeResourceSearchableReference = "GcpScopeResourceSearchableReference",
    GcpScopeResourceTypeMetadata = "GcpScopeResourceTypeMetadata",
    GcpSecretManagerSecret = "GcpSecretManagerSecret",
    GcpSecretManagerSecretModel = "GcpSecretManagerSecretModel",
    GcpSecretManagerSecretProfile = "GcpSecretManagerSecretProfile",
    GcpSecretManagerSecretReplicationType = "GcpSecretManagerSecretReplicationType",
    GcpSecretManagerSecretRotationTimeFrameCodeRisk = "GcpSecretManagerSecretRotationTimeFrameCodeRisk",
    GcpSecretManagerSecretRotationTimeFrameCodeRiskModel = "GcpSecretManagerSecretRotationTimeFrameCodeRiskModel",
    GcpSecretManagerSecretRotationTimeFrameRisk = "GcpSecretManagerSecretRotationTimeFrameRisk",
    GcpSecretManagerSecretRotationTimeFrameRiskModel = "GcpSecretManagerSecretRotationTimeFrameRiskModel",
    GcpSecretManagerSecretRotationTimeFrameRiskPolicyConfiguration = "GcpSecretManagerSecretRotationTimeFrameRiskPolicyConfiguration",
    GcpSecretManagerSecretRotationTimeFrameRiskPolicyUpdateAuditEventSecretRotationTimeFrameChange = "GcpSecretManagerSecretRotationTimeFrameRiskPolicyUpdateAuditEventSecretRotationTimeFrameChange",
    GcpSecretManagerSecretsGenericResource = "GcpSecretManagerSecretsGenericResource",
    GcpSecretManagerSecretVersion = "GcpSecretManagerSecretVersion",
    GcpSecretManagerSecretVersionModel = "GcpSecretManagerSecretVersionModel",
    GcpSecretManagerSecretVersionProfile = "GcpSecretManagerSecretVersionProfile",
    GcpSecretManagerVersionsGenericResource = "GcpSecretManagerVersionsGenericResource",
    GcpSecretManagerVersionStatus = "GcpSecretManagerVersionStatus",
    GcpSecuresourcemanagerInstancesGenericResource = "GcpSecuresourcemanagerInstancesGenericResource",
    GcpSecurityposturePostureDeploymentsGenericResource = "GcpSecurityposturePostureDeploymentsGenericResource",
    GcpSecurityposturePosturesGenericResource = "GcpSecurityposturePosturesGenericResource",
    GcpServerlessNetworkedResourceNetworkInboundAccessType = "GcpServerlessNetworkedResourceNetworkInboundAccessType",
    GcpService = "GcpService",
    GcpServiceAccountOriginatorAuthorizationScopeType = "GcpServiceAccountOriginatorAuthorizationScopeType",
    GcpServiceAccountOriginatorServiceAccountData = "GcpServiceAccountOriginatorServiceAccountData",
    GcpServicedirectoryEndpointsGenericResource = "GcpServicedirectoryEndpointsGenericResource",
    GcpServicedirectoryNamespacesGenericResource = "GcpServicedirectoryNamespacesGenericResource",
    GcpServicedirectoryServicesGenericResource = "GcpServicedirectoryServicesGenericResource",
    GcpServiceManagementServicesGenericResource = "GcpServiceManagementServicesGenericResource",
    GcpServiceModel = "GcpServiceModel",
    GcpServiceUsageServicesGenericResource = "GcpServiceUsageServicesGenericResource",
    GcpSeverePermissionPrincipalRisk = "GcpSeverePermissionPrincipalRisk",
    GcpSeverePermissionPrincipalRiskModel = "GcpSeverePermissionPrincipalRiskModel",
    GcpSeverePermissionPrincipalRiskModelInfo = "GcpSeverePermissionPrincipalRiskModelInfo",
    GcpSeverePermissionPrincipalRiskPolicyConfiguration = "GcpSeverePermissionPrincipalRiskPolicyConfiguration",
    GcpSpannerBackupsGenericResource = "GcpSpannerBackupsGenericResource",
    GcpSpannerDatabasesGenericResource = "GcpSpannerDatabasesGenericResource",
    GcpSpannerInstance = "GcpSpannerInstance",
    GcpSpannerInstanceConfigsGenericResource = "GcpSpannerInstanceConfigsGenericResource",
    GcpSpannerInstanceDatabase = "GcpSpannerInstanceDatabase",
    GcpSpannerInstanceDatabaseModel = "GcpSpannerInstanceDatabaseModel",
    GcpSpannerInstanceDatabaseProfile = "GcpSpannerInstanceDatabaseProfile",
    GcpSpannerInstanceModel = "GcpSpannerInstanceModel",
    GcpSpannerInstanceProfile = "GcpSpannerInstanceProfile",
    GcpSpannerInstancesGenericResource = "GcpSpannerInstancesGenericResource",
    GcpSpeakeridPhrasesGenericResource = "GcpSpeakeridPhrasesGenericResource",
    GcpSpeakeridSettingsGenericResource = "GcpSpeakeridSettingsGenericResource",
    GcpSpeakeridSpeakersGenericResource = "GcpSpeakeridSpeakersGenericResource",
    GcpSpecialGroup = "GcpSpecialGroup",
    GcpSpecialGroupModel = "GcpSpecialGroupModel",
    GcpSpecialGroupType = "GcpSpecialGroupType",
    GcpSpecialGroupTypeMetadata = "GcpSpecialGroupTypeMetadata",
    GcpSpeechConfigsGenericResource = "GcpSpeechConfigsGenericResource",
    GcpSpeechCustomClassesGenericResource = "GcpSpeechCustomClassesGenericResource",
    GcpSpeechPhraseSetsGenericResource = "GcpSpeechPhraseSetsGenericResource",
    GcpSpeechRecognizersGenericResource = "GcpSpeechRecognizersGenericResource",
    GcpSqlInstance = "GcpSqlInstance",
    GcpSqlInstanceAuthorizedSubnetData = "GcpSqlInstanceAuthorizedSubnetData",
    GcpSqlInstanceBackupDisabledCodeRisk = "GcpSqlInstanceBackupDisabledCodeRisk",
    GcpSqlInstanceBackupDisabledCodeRiskModel = "GcpSqlInstanceBackupDisabledCodeRiskModel",
    GcpSqlInstanceBackupDisabledRisk = "GcpSqlInstanceBackupDisabledRisk",
    GcpSqlInstanceBackupDisabledRiskModel = "GcpSqlInstanceBackupDisabledRiskModel",
    GcpSqlInstanceBackupDisabledRiskPolicyConfiguration = "GcpSqlInstanceBackupDisabledRiskPolicyConfiguration",
    GcpSqlInstanceDatabase = "GcpSqlInstanceDatabase",
    GcpSqlInstanceDatabaseModel = "GcpSqlInstanceDatabaseModel",
    GcpSqlInstanceDatabaseProfile = "GcpSqlInstanceDatabaseProfile",
    GcpSqlInstanceDatabaseType = "GcpSqlInstanceDatabaseType",
    GcpSqlInstanceDatabaseVersion = "GcpSqlInstanceDatabaseVersion",
    GcpSqlInstanceHttpsOnlyDisabledCodeRisk = "GcpSqlInstanceHttpsOnlyDisabledCodeRisk",
    GcpSqlInstanceHttpsOnlyDisabledCodeRiskModel = "GcpSqlInstanceHttpsOnlyDisabledCodeRiskModel",
    GcpSqlInstanceHttpsOnlyDisabledRisk = "GcpSqlInstanceHttpsOnlyDisabledRisk",
    GcpSqlInstanceHttpsOnlyDisabledRiskModel = "GcpSqlInstanceHttpsOnlyDisabledRiskModel",
    GcpSqlInstanceHttpsOnlyDisabledRiskPolicyConfiguration = "GcpSqlInstanceHttpsOnlyDisabledRiskPolicyConfiguration",
    GcpSqlInstanceModel = "GcpSqlInstanceModel",
    GcpSqlInstanceModelFilters = "GcpSqlInstanceModelFilters",
    GcpSqlInstanceMySqlListDatabasesPermissionRequiredDisabledCodeRisk = "GcpSqlInstanceMySqlListDatabasesPermissionRequiredDisabledCodeRisk",
    GcpSqlInstanceMySqlListDatabasesPermissionRequiredDisabledCodeRiskModel = "GcpSqlInstanceMySqlListDatabasesPermissionRequiredDisabledCodeRiskModel",
    GcpSqlInstanceMySqlListDatabasesPermissionRequiredDisabledRisk = "GcpSqlInstanceMySqlListDatabasesPermissionRequiredDisabledRisk",
    GcpSqlInstanceMySqlListDatabasesPermissionRequiredDisabledRiskModel = "GcpSqlInstanceMySqlListDatabasesPermissionRequiredDisabledRiskModel",
    GcpSqlInstanceMySqlListDatabasesPermissionRequiredDisabledRiskPolicyConfiguration = "GcpSqlInstanceMySqlListDatabasesPermissionRequiredDisabledRiskPolicyConfiguration",
    GcpSqlInstanceMySqlLoadClientFileEnabledCodeRisk = "GcpSqlInstanceMySqlLoadClientFileEnabledCodeRisk",
    GcpSqlInstanceMySqlLoadClientFileEnabledCodeRiskModel = "GcpSqlInstanceMySqlLoadClientFileEnabledCodeRiskModel",
    GcpSqlInstanceMySqlLoadClientFileEnabledRisk = "GcpSqlInstanceMySqlLoadClientFileEnabledRisk",
    GcpSqlInstanceMySqlLoadClientFileEnabledRiskModel = "GcpSqlInstanceMySqlLoadClientFileEnabledRiskModel",
    GcpSqlInstanceMySqlLoadClientFileEnabledRiskPolicyConfiguration = "GcpSqlInstanceMySqlLoadClientFileEnabledRiskPolicyConfiguration",
    GcpSqlInstancePostgreSqlAuditDisabledCodeRisk = "GcpSqlInstancePostgreSqlAuditDisabledCodeRisk",
    GcpSqlInstancePostgreSqlAuditDisabledCodeRiskModel = "GcpSqlInstancePostgreSqlAuditDisabledCodeRiskModel",
    GcpSqlInstancePostgreSqlAuditDisabledRisk = "GcpSqlInstancePostgreSqlAuditDisabledRisk",
    GcpSqlInstancePostgreSqlAuditDisabledRiskModel = "GcpSqlInstancePostgreSqlAuditDisabledRiskModel",
    GcpSqlInstancePostgreSqlAuditDisabledRiskPolicyConfiguration = "GcpSqlInstancePostgreSqlAuditDisabledRiskPolicyConfiguration",
    GcpSqlInstancePostgreSqlLogConnectionsDisabledCodeRisk = "GcpSqlInstancePostgreSqlLogConnectionsDisabledCodeRisk",
    GcpSqlInstancePostgreSqlLogConnectionsDisabledCodeRiskModel = "GcpSqlInstancePostgreSqlLogConnectionsDisabledCodeRiskModel",
    GcpSqlInstancePostgreSqlLogConnectionsDisabledRisk = "GcpSqlInstancePostgreSqlLogConnectionsDisabledRisk",
    GcpSqlInstancePostgreSqlLogConnectionsDisabledRiskModel = "GcpSqlInstancePostgreSqlLogConnectionsDisabledRiskModel",
    GcpSqlInstancePostgreSqlLogConnectionsDisabledRiskPolicyConfiguration = "GcpSqlInstancePostgreSqlLogConnectionsDisabledRiskPolicyConfiguration",
    GcpSqlInstancePostgreSqlLogConnectionsHostNameDisabledCodeRisk = "GcpSqlInstancePostgreSqlLogConnectionsHostNameDisabledCodeRisk",
    GcpSqlInstancePostgreSqlLogConnectionsHostNameDisabledCodeRiskModel = "GcpSqlInstancePostgreSqlLogConnectionsHostNameDisabledCodeRiskModel",
    GcpSqlInstancePostgreSqlLogConnectionsHostNameDisabledRisk = "GcpSqlInstancePostgreSqlLogConnectionsHostNameDisabledRisk",
    GcpSqlInstancePostgreSqlLogConnectionsHostNameDisabledRiskModel = "GcpSqlInstancePostgreSqlLogConnectionsHostNameDisabledRiskModel",
    GcpSqlInstancePostgreSqlLogConnectionsHostNameDisabledRiskPolicyConfiguration = "GcpSqlInstancePostgreSqlLogConnectionsHostNameDisabledRiskPolicyConfiguration",
    GcpSqlInstancePostgreSqlLogDisconnectionsDisabledCodeRisk = "GcpSqlInstancePostgreSqlLogDisconnectionsDisabledCodeRisk",
    GcpSqlInstancePostgreSqlLogDisconnectionsDisabledCodeRiskModel = "GcpSqlInstancePostgreSqlLogDisconnectionsDisabledCodeRiskModel",
    GcpSqlInstancePostgreSqlLogDisconnectionsDisabledRisk = "GcpSqlInstancePostgreSqlLogDisconnectionsDisabledRisk",
    GcpSqlInstancePostgreSqlLogDisconnectionsDisabledRiskModel = "GcpSqlInstancePostgreSqlLogDisconnectionsDisabledRiskModel",
    GcpSqlInstancePostgreSqlLogDisconnectionsDisabledRiskPolicyConfiguration = "GcpSqlInstancePostgreSqlLogDisconnectionsDisabledRiskPolicyConfiguration",
    GcpSqlInstancePostgreSqlLogErrorVerbosityCodeRisk = "GcpSqlInstancePostgreSqlLogErrorVerbosityCodeRisk",
    GcpSqlInstancePostgreSqlLogErrorVerbosityCodeRiskModel = "GcpSqlInstancePostgreSqlLogErrorVerbosityCodeRiskModel",
    GcpSqlInstancePostgreSqlLogErrorVerbosityRisk = "GcpSqlInstancePostgreSqlLogErrorVerbosityRisk",
    GcpSqlInstancePostgreSqlLogErrorVerbosityRiskModel = "GcpSqlInstancePostgreSqlLogErrorVerbosityRiskModel",
    GcpSqlInstancePostgreSqlLogErrorVerbosityRiskPolicyConfiguration = "GcpSqlInstancePostgreSqlLogErrorVerbosityRiskPolicyConfiguration",
    GcpSqlInstancePostgreSqlLogLevel = "GcpSqlInstancePostgreSqlLogLevel",
    GcpSqlInstancePostgreSqlLogMinLevelCodeRisk = "GcpSqlInstancePostgreSqlLogMinLevelCodeRisk",
    GcpSqlInstancePostgreSqlLogMinLevelCodeRiskModel = "GcpSqlInstancePostgreSqlLogMinLevelCodeRiskModel",
    GcpSqlInstancePostgreSqlLogMinLevelRisk = "GcpSqlInstancePostgreSqlLogMinLevelRisk",
    GcpSqlInstancePostgreSqlLogMinLevelRiskModel = "GcpSqlInstancePostgreSqlLogMinLevelRiskModel",
    GcpSqlInstancePostgreSqlLogMinLevelRiskPolicyConfiguration = "GcpSqlInstancePostgreSqlLogMinLevelRiskPolicyConfiguration",
    GcpSqlInstancePostgreSqlLogVerbosity = "GcpSqlInstancePostgreSqlLogVerbosity",
    GcpSqlInstancePostgreSqlMinDurationLogQueryEnabledCodeRisk = "GcpSqlInstancePostgreSqlMinDurationLogQueryEnabledCodeRisk",
    GcpSqlInstancePostgreSqlMinDurationLogQueryEnabledCodeRiskModel = "GcpSqlInstancePostgreSqlMinDurationLogQueryEnabledCodeRiskModel",
    GcpSqlInstancePostgreSqlMinDurationLogQueryEnabledRisk = "GcpSqlInstancePostgreSqlMinDurationLogQueryEnabledRisk",
    GcpSqlInstancePostgreSqlMinDurationLogQueryEnabledRiskModel = "GcpSqlInstancePostgreSqlMinDurationLogQueryEnabledRiskModel",
    GcpSqlInstancePostgreSqlMinDurationLogQueryEnabledRiskPolicyConfiguration = "GcpSqlInstancePostgreSqlMinDurationLogQueryEnabledRiskPolicyConfiguration",
    GcpSqlInstancePostgreSqlMinLogLevelLogQueryCodeRisk = "GcpSqlInstancePostgreSqlMinLogLevelLogQueryCodeRisk",
    GcpSqlInstancePostgreSqlMinLogLevelLogQueryCodeRiskModel = "GcpSqlInstancePostgreSqlMinLogLevelLogQueryCodeRiskModel",
    GcpSqlInstancePostgreSqlMinLogLevelLogQueryRisk = "GcpSqlInstancePostgreSqlMinLogLevelLogQueryRisk",
    GcpSqlInstancePostgreSqlMinLogLevelLogQueryRiskModel = "GcpSqlInstancePostgreSqlMinLogLevelLogQueryRiskModel",
    GcpSqlInstancePostgreSqlMinLogLevelLogQueryRiskPolicyConfiguration = "GcpSqlInstancePostgreSqlMinLogLevelLogQueryRiskPolicyConfiguration",
    GcpSqlInstancePostgreSqlStatementLogDisabledCodeRisk = "GcpSqlInstancePostgreSqlStatementLogDisabledCodeRisk",
    GcpSqlInstancePostgreSqlStatementLogDisabledCodeRiskModel = "GcpSqlInstancePostgreSqlStatementLogDisabledCodeRiskModel",
    GcpSqlInstancePostgreSqlStatementLogDisabledRisk = "GcpSqlInstancePostgreSqlStatementLogDisabledRisk",
    GcpSqlInstancePostgreSqlStatementLogDisabledRiskModel = "GcpSqlInstancePostgreSqlStatementLogDisabledRiskModel",
    GcpSqlInstancePostgreSqlStatementLogDisabledRiskPolicyConfiguration = "GcpSqlInstancePostgreSqlStatementLogDisabledRiskPolicyConfiguration",
    GcpSqlInstancePostgreSqlStatementLogType = "GcpSqlInstancePostgreSqlStatementLogType",
    GcpSqlInstanceProfile = "GcpSqlInstanceProfile",
    GcpSqlInstanceSqlServerContainedDatabaseAuthenticationEnabledCodeRisk = "GcpSqlInstanceSqlServerContainedDatabaseAuthenticationEnabledCodeRisk",
    GcpSqlInstanceSqlServerContainedDatabaseAuthenticationEnabledCodeRiskModel = "GcpSqlInstanceSqlServerContainedDatabaseAuthenticationEnabledCodeRiskModel",
    GcpSqlInstanceSqlServerContainedDatabaseAuthenticationEnabledRisk = "GcpSqlInstanceSqlServerContainedDatabaseAuthenticationEnabledRisk",
    GcpSqlInstanceSqlServerContainedDatabaseAuthenticationEnabledRiskModel = "GcpSqlInstanceSqlServerContainedDatabaseAuthenticationEnabledRiskModel",
    GcpSqlInstanceSqlServerContainedDatabaseAuthenticationEnabledRiskPolicyConfiguration = "GcpSqlInstanceSqlServerContainedDatabaseAuthenticationEnabledRiskPolicyConfiguration",
    GcpSqlInstanceSqlServerCrossDatabaseImplicitPermissionsEnabledCodeRisk = "GcpSqlInstanceSqlServerCrossDatabaseImplicitPermissionsEnabledCodeRisk",
    GcpSqlInstanceSqlServerCrossDatabaseImplicitPermissionsEnabledCodeRiskModel = "GcpSqlInstanceSqlServerCrossDatabaseImplicitPermissionsEnabledCodeRiskModel",
    GcpSqlInstanceSqlServerCrossDatabaseImplicitPermissionsEnabledRisk = "GcpSqlInstanceSqlServerCrossDatabaseImplicitPermissionsEnabledRisk",
    GcpSqlInstanceSqlServerCrossDatabaseImplicitPermissionsEnabledRiskModel = "GcpSqlInstanceSqlServerCrossDatabaseImplicitPermissionsEnabledRiskModel",
    GcpSqlInstanceSqlServerCrossDatabaseImplicitPermissionsEnabledRiskPolicyConfiguration = "GcpSqlInstanceSqlServerCrossDatabaseImplicitPermissionsEnabledRiskPolicyConfiguration",
    GcpSqlInstanceSqlServerCrossInstanceProcedureExecutionEnabledCodeRisk = "GcpSqlInstanceSqlServerCrossInstanceProcedureExecutionEnabledCodeRisk",
    GcpSqlInstanceSqlServerCrossInstanceProcedureExecutionEnabledCodeRiskModel = "GcpSqlInstanceSqlServerCrossInstanceProcedureExecutionEnabledCodeRiskModel",
    GcpSqlInstanceSqlServerCrossInstanceProcedureExecutionEnabledRisk = "GcpSqlInstanceSqlServerCrossInstanceProcedureExecutionEnabledRisk",
    GcpSqlInstanceSqlServerCrossInstanceProcedureExecutionEnabledRiskModel = "GcpSqlInstanceSqlServerCrossInstanceProcedureExecutionEnabledRiskModel",
    GcpSqlInstanceSqlServerCrossInstanceProcedureExecutionEnabledRiskPolicyConfiguration = "GcpSqlInstanceSqlServerCrossInstanceProcedureExecutionEnabledRiskPolicyConfiguration",
    GcpSqlInstanceSqlServerLogMaskParametersDisabledCodeRisk = "GcpSqlInstanceSqlServerLogMaskParametersDisabledCodeRisk",
    GcpSqlInstanceSqlServerLogMaskParametersDisabledCodeRiskModel = "GcpSqlInstanceSqlServerLogMaskParametersDisabledCodeRiskModel",
    GcpSqlInstanceSqlServerLogMaskParametersDisabledRisk = "GcpSqlInstanceSqlServerLogMaskParametersDisabledRisk",
    GcpSqlInstanceSqlServerLogMaskParametersDisabledRiskModel = "GcpSqlInstanceSqlServerLogMaskParametersDisabledRiskModel",
    GcpSqlInstanceSqlServerLogMaskParametersDisabledRiskPolicyConfiguration = "GcpSqlInstanceSqlServerLogMaskParametersDisabledRiskPolicyConfiguration",
    GcpSqlInstanceSqlServerScriptExecutionEnabledCodeRisk = "GcpSqlInstanceSqlServerScriptExecutionEnabledCodeRisk",
    GcpSqlInstanceSqlServerScriptExecutionEnabledCodeRiskModel = "GcpSqlInstanceSqlServerScriptExecutionEnabledCodeRiskModel",
    GcpSqlInstanceSqlServerScriptExecutionEnabledRisk = "GcpSqlInstanceSqlServerScriptExecutionEnabledRisk",
    GcpSqlInstanceSqlServerScriptExecutionEnabledRiskModel = "GcpSqlInstanceSqlServerScriptExecutionEnabledRiskModel",
    GcpSqlInstanceSqlServerScriptExecutionEnabledRiskPolicyConfiguration = "GcpSqlInstanceSqlServerScriptExecutionEnabledRiskPolicyConfiguration",
    GcpSqlInstanceSqlServerUserMaxConnectionsExistsCodeRisk = "GcpSqlInstanceSqlServerUserMaxConnectionsExistsCodeRisk",
    GcpSqlInstanceSqlServerUserMaxConnectionsExistsCodeRiskModel = "GcpSqlInstanceSqlServerUserMaxConnectionsExistsCodeRiskModel",
    GcpSqlInstanceSqlServerUserMaxConnectionsExistsRisk = "GcpSqlInstanceSqlServerUserMaxConnectionsExistsRisk",
    GcpSqlInstanceSqlServerUserMaxConnectionsExistsRiskModel = "GcpSqlInstanceSqlServerUserMaxConnectionsExistsRiskModel",
    GcpSqlInstanceSqlServerUserMaxConnectionsExistsRiskPolicyConfiguration = "GcpSqlInstanceSqlServerUserMaxConnectionsExistsRiskPolicyConfiguration",
    GcpSqlInstanceSqlServerUserOptionsEnabledCodeRisk = "GcpSqlInstanceSqlServerUserOptionsEnabledCodeRisk",
    GcpSqlInstanceSqlServerUserOptionsEnabledCodeRiskModel = "GcpSqlInstanceSqlServerUserOptionsEnabledCodeRiskModel",
    GcpSqlInstanceSqlServerUserOptionsEnabledRisk = "GcpSqlInstanceSqlServerUserOptionsEnabledRisk",
    GcpSqlInstanceSqlServerUserOptionsEnabledRiskModel = "GcpSqlInstanceSqlServerUserOptionsEnabledRiskModel",
    GcpSqlInstanceSqlServerUserOptionsEnabledRiskPolicyConfiguration = "GcpSqlInstanceSqlServerUserOptionsEnabledRiskPolicyConfiguration",
    GcpSqlInstanceStateNetwork = "GcpSqlInstanceStateNetwork",
    GcpSqlInstanceStatus = "GcpSqlInstanceStatus",
    GcpSqlInstanceType = "GcpSqlInstanceType",
    GcpSqlInstanceUser = "GcpSqlInstanceUser",
    GcpSqlInstanceUserModel = "GcpSqlInstanceUserModel",
    GcpSqlInstanceUserProfile = "GcpSqlInstanceUserProfile",
    GcpSqlInstanceUserType = "GcpSqlInstanceUserType",
    GcpSqlInstanceWideRangeInboundCodeRisk = "GcpSqlInstanceWideRangeInboundCodeRisk",
    GcpSqlInstanceWideRangeInboundCodeRiskModel = "GcpSqlInstanceWideRangeInboundCodeRiskModel",
    GcpSqlInstanceWideRangeInboundRisk = "GcpSqlInstanceWideRangeInboundRisk",
    GcpSqlInstanceWideRangeInboundRiskModel = "GcpSqlInstanceWideRangeInboundRiskModel",
    GcpSqlInstanceWideRangeInboundRiskPolicyConfiguration = "GcpSqlInstanceWideRangeInboundRiskPolicyConfiguration",
    GcpStorageBucket = "GcpStorageBucket",
    GcpStorageBucketLockedRetentionPolicyNotEnabledCodeRisk = "GcpStorageBucketLockedRetentionPolicyNotEnabledCodeRisk",
    GcpStorageBucketLockedRetentionPolicyNotEnabledCodeRiskModel = "GcpStorageBucketLockedRetentionPolicyNotEnabledCodeRiskModel",
    GcpStorageBucketLockedRetentionPolicyNotEnabledRisk = "GcpStorageBucketLockedRetentionPolicyNotEnabledRisk",
    GcpStorageBucketLockedRetentionPolicyNotEnabledRiskModel = "GcpStorageBucketLockedRetentionPolicyNotEnabledRiskModel",
    GcpStorageBucketLockedRetentionPolicyNotEnabledRiskPolicyConfiguration = "GcpStorageBucketLockedRetentionPolicyNotEnabledRiskPolicyConfiguration",
    GcpStorageBucketModel = "GcpStorageBucketModel",
    GcpStorageBucketModelFilters = "GcpStorageBucketModelFilters",
    GcpStorageBucketProfile = "GcpStorageBucketProfile",
    GcpStorageBucketPublicAccessExistsCodeRisk = "GcpStorageBucketPublicAccessExistsCodeRisk",
    GcpStorageBucketPublicAccessExistsCodeRiskModel = "GcpStorageBucketPublicAccessExistsCodeRiskModel",
    GcpStorageBucketPublicAccessExistsRisk = "GcpStorageBucketPublicAccessExistsRisk",
    GcpStorageBucketPublicAccessExistsRiskModel = "GcpStorageBucketPublicAccessExistsRiskModel",
    GcpStorageBucketPublicAccessExistsRiskModelDescriptionType = "GcpStorageBucketPublicAccessExistsRiskModelDescriptionType",
    GcpStorageBucketPublicAccessExistsRiskPolicyConfiguration = "GcpStorageBucketPublicAccessExistsRiskPolicyConfiguration",
    GcpStorageBucketsGenericResource = "GcpStorageBucketsGenericResource",
    GcpStorageBucketUniformAccessControlDisabledCodeRisk = "GcpStorageBucketUniformAccessControlDisabledCodeRisk",
    GcpStorageBucketUniformAccessControlDisabledCodeRiskModel = "GcpStorageBucketUniformAccessControlDisabledCodeRiskModel",
    GcpStorageBucketUniformAccessControlDisabledRisk = "GcpStorageBucketUniformAccessControlDisabledRisk",
    GcpStorageBucketUniformAccessControlDisabledRiskModel = "GcpStorageBucketUniformAccessControlDisabledRiskModel",
    GcpStorageBucketUniformAccessControlDisabledRiskPolicyConfiguration = "GcpStorageBucketUniformAccessControlDisabledRiskPolicyConfiguration",
    GcpStorageinsightsReportConfigsGenericResource = "GcpStorageinsightsReportConfigsGenericResource",
    GcpStorageinsightsReportDetailsGenericResource = "GcpStorageinsightsReportDetailsGenericResource",
    GcpTenantAuditEvent = "GcpTenantAuditEvent",
    GcpTenantConfiguration = "GcpTenantConfiguration",
    GcpTenantConfigurationSink = "GcpTenantConfigurationSink",
    GcpTenantCreationAuditEvent = "GcpTenantCreationAuditEvent",
    GcpTenantDeletionAuditEvent = "GcpTenantDeletionAuditEvent",
    GcpTenantEntity = "GcpTenantEntity",
    GcpTenantEntityCloudAssetServiceNotEnabledRisk = "GcpTenantEntityCloudAssetServiceNotEnabledRisk",
    GcpTenantEntityCloudAssetServiceNotEnabledRiskModel = "GcpTenantEntityCloudAssetServiceNotEnabledRiskModel",
    GcpTenantEntityCloudAssetServiceNotEnabledRiskPolicyConfiguration = "GcpTenantEntityCloudAssetServiceNotEnabledRiskPolicyConfiguration",
    GcpTenantEntityCreationRisk = "GcpTenantEntityCreationRisk",
    GcpTenantEntityCreationRiskModel = "GcpTenantEntityCreationRiskModel",
    GcpTenantEntityCreationRiskPolicyConfiguration = "GcpTenantEntityCreationRiskPolicyConfiguration",
    GcpTenantEntityModel = "GcpTenantEntityModel",
    GcpTenantEntityProfile = "GcpTenantEntityProfile",
    GcpTenantEntityUnusedRisk = "GcpTenantEntityUnusedRisk",
    GcpTenantEntityUnusedRiskModel = "GcpTenantEntityUnusedRiskModel",
    GcpTenantEntityUnusedRiskPolicyConfiguration = "GcpTenantEntityUnusedRiskPolicyConfiguration",
    GcpTenantEntityUnusedRiskPolicyUpdateAuditEventProjectUsageTimeFrameChange = "GcpTenantEntityUnusedRiskPolicyUpdateAuditEventProjectUsageTimeFrameChange",
    GcpTenantEntityUsage = "GcpTenantEntityUsage",
    GcpTenantEntityUsageAppEngine = "GcpTenantEntityUsageAppEngine",
    GcpTenantEntityUsageBigQuery = "GcpTenantEntityUsageBigQuery",
    GcpTenantEntityUsageCloudSql = "GcpTenantEntityUsageCloudSql",
    GcpTenantEntityUsageCommon = "GcpTenantEntityUsageCommon",
    GcpTenantEntityUsageCompute = "GcpTenantEntityUsageCompute",
    GcpTenantEntityUsageIam = "GcpTenantEntityUsageIam",
    GcpTenantEntityUsageStorage = "GcpTenantEntityUsageStorage",
    GcpTenantManagerError = "GcpTenantManagerError",
    GcpTenantManagerValidateOrganizationResult = "GcpTenantManagerValidateOrganizationResult",
    GcpTenantManagerValidateTenantResult = "GcpTenantManagerValidateTenantResult",
    GcpTenantModel = "GcpTenantModel",
    GcpTenantModelIssue = "GcpTenantModelIssue",
    GcpTenantModelStatus = "GcpTenantModelStatus",
    GcpTenantState = "GcpTenantState",
    GcpTenantStateCodeAnalysis = "GcpTenantStateCodeAnalysis",
    GcpTenantStateEvents = "GcpTenantStateEvents",
    GcpTenantStateEventsServiceAccountSyncTimes = "GcpTenantStateEventsServiceAccountSyncTimes",
    GcpTenantStateMonitoring = "GcpTenantStateMonitoring",
    GcpTenantStateMonitoringIssue = "GcpTenantStateMonitoringIssue",
    GcpTenantStateMonitoringPermissionManagementIssue = "GcpTenantStateMonitoringPermissionManagementIssue",
    GcpTenantStateMonitoringSinkIssue = "GcpTenantStateMonitoringSinkIssue",
    GcpTenantUpdateAuditEvent = "GcpTenantUpdateAuditEvent",
    GcpTpuNodesGenericResource = "GcpTpuNodesGenericResource",
    GcpTranscoderJobsGenericResource = "GcpTranscoderJobsGenericResource",
    GcpTranscoderJobTemplatesGenericResource = "GcpTranscoderJobTemplatesGenericResource",
    GcpUnmanagedKubernetesCluster = "GcpUnmanagedKubernetesCluster",
    GcpUnmanagedKubernetesClusterModel = "GcpUnmanagedKubernetesClusterModel",
    GcpUnmanagedKubernetesClusterModelFilters = "GcpUnmanagedKubernetesClusterModelFilters",
    GcpUnmanagedKubernetesClusterTypeMetadata = "GcpUnmanagedKubernetesClusterTypeMetadata",
    GcpUnusedTenantEntityAttribute = "GcpUnusedTenantEntityAttribute",
    GcpUserManagedKeyExistsServiceAccountAttribute = "GcpUserManagedKeyExistsServiceAccountAttribute",
    GcpVideoStitcherCdnKeysGenericResource = "GcpVideoStitcherCdnKeysGenericResource",
    GcpVideoStitcherLiveConfigsGenericResource = "GcpVideoStitcherLiveConfigsGenericResource",
    GcpVideoStitcherSlatesGenericResource = "GcpVideoStitcherSlatesGenericResource",
    GcpVideoStitcherVodConfigsGenericResource = "GcpVideoStitcherVodConfigsGenericResource",
    GcpVmMigrationCloneJobsGenericResource = "GcpVmMigrationCloneJobsGenericResource",
    GcpVmMigrationCutoverJobsGenericResource = "GcpVmMigrationCutoverJobsGenericResource",
    GcpVmMigrationDatacenterConnectorsGenericResource = "GcpVmMigrationDatacenterConnectorsGenericResource",
    GcpVmMigrationGroupsGenericResource = "GcpVmMigrationGroupsGenericResource",
    GcpVmMigrationMigratingVmsGenericResource = "GcpVmMigrationMigratingVmsGenericResource",
    GcpVmMigrationSourcesGenericResource = "GcpVmMigrationSourcesGenericResource",
    GcpVmMigrationTargetProjectsGenericResource = "GcpVmMigrationTargetProjectsGenericResource",
    GcpVmMigrationUtilizationReportsGenericResource = "GcpVmMigrationUtilizationReportsGenericResource",
    GcpVmwareengineClustersGenericResource = "GcpVmwareengineClustersGenericResource",
    GcpVmwareengineExternalAccessRulesGenericResource = "GcpVmwareengineExternalAccessRulesGenericResource",
    GcpVmwareengineExternalAddressesGenericResource = "GcpVmwareengineExternalAddressesGenericResource",
    GcpVmwareengineNetworkPeeringsGenericResource = "GcpVmwareengineNetworkPeeringsGenericResource",
    GcpVmwareengineNetworkPoliciesGenericResource = "GcpVmwareengineNetworkPoliciesGenericResource",
    GcpVmwareenginePrivateCloudsGenericResource = "GcpVmwareenginePrivateCloudsGenericResource",
    GcpVmwareenginePrivateConnectionsGenericResource = "GcpVmwareenginePrivateConnectionsGenericResource",
    GcpVmwareengineVmwareEngineNetworksGenericResource = "GcpVmwareengineVmwareEngineNetworksGenericResource",
    GcpVpcAccessConnectorsGenericResource = "GcpVpcAccessConnectorsGenericResource",
    GcpWorkflowsWorkflowsGenericResource = "GcpWorkflowsWorkflowsGenericResource",
    GcpWorkstationsWorkstationClustersGenericResource = "GcpWorkstationsWorkstationClustersGenericResource",
    GcpWorkstationsWorkstationConfigsGenericResource = "GcpWorkstationsWorkstationConfigsGenericResource",
    GcpWorkstationsWorkstationsGenericResource = "GcpWorkstationsWorkstationsGenericResource",
    GeneralCodeTenantConfiguration = "GeneralCodeTenantConfiguration",
    GeneralCodeTenantDeletionAuditEvent = "GeneralCodeTenantDeletionAuditEvent",
    GeneralCodeTenantModel = "GeneralCodeTenantModel",
    GeneralCodeTenantState = "GeneralCodeTenantState",
    GeneralCodeTenantStateMonitoring = "GeneralCodeTenantStateMonitoring",
    GeneralCodeTenantUpdateAuditEvent = "GeneralCodeTenantUpdateAuditEvent",
    GenerateReportError = "GenerateReportError",
    GeographyType = "GeographyType",
    GitHubOrganizationConfiguration = "GitHubOrganizationConfiguration",
    GitHubOrganizationManagerError = "GitHubOrganizationManagerError",
    GitHubServerConfiguration = "GitHubServerConfiguration",
    GitHubServerConfigurationProviderConfigurationSection = "GitHubServerConfigurationProviderConfigurationSection",
    GitHubServerManagerError = "GitHubServerManagerError",
    GitHubServerManagerValidateServerOnboardingServerInfoResult = "GitHubServerManagerValidateServerOnboardingServerInfoResult",
    GitHubTenantConfiguration = "GitHubTenantConfiguration",
    GitLabOrganizationConfiguration = "GitLabOrganizationConfiguration",
    GitLabOrganizationManagerError = "GitLabOrganizationManagerError",
    GitLabServerConfiguration = "GitLabServerConfiguration",
    GitLabServerManagerError = "GitLabServerManagerError",
    GitLabTenantConfiguration = "GitLabTenantConfiguration",
    GitTenantConfiguration = "GitTenantConfiguration",
    GitTenantCreationAuditEvent = "GitTenantCreationAuditEvent",
    GitTenantDeletionAuditEvent = "GitTenantDeletionAuditEvent",
    GitTenantModel = "GitTenantModel",
    GitTenantState = "GitTenantState",
    GitTenantStateMonitoring = "GitTenantStateMonitoring",
    GitTenantStateMonitoringIssue = "GitTenantStateMonitoringIssue",
    GitTenantUpdateAuditEvent = "GitTenantUpdateAuditEvent",
    GlobalConsoleAppConfigurationSection = "GlobalConsoleAppConfigurationSection",
    GlobalSlackConfigurationSection = "GlobalSlackConfigurationSection",
    Graph = "Graph",
    GraphEdge = "GraphEdge",
    GraphNode = "GraphNode",
    Group = "Group",
    GroupMembershipEligibilityConfiguration = "GroupMembershipEligibilityConfiguration",
    GroupMembershipEligibilityModel = "GroupMembershipEligibilityModel",
    GroupMembershipRequest = "GroupMembershipRequest",
    GroupMembershipRequestActivationFailureData = "GroupMembershipRequestActivationFailureData",
    GroupMembershipRequestModelActivationFailureData = "GroupMembershipRequestModelActivationFailureData",
    GroupModelAccess = "GroupModelAccess",
    GroupRoleAssignmentAuditEvent = "GroupRoleAssignmentAuditEvent",
    GroupRoleAssignmentCreationAuditEvent = "GroupRoleAssignmentCreationAuditEvent",
    GroupRoleAssignmentDeletionAuditEvent = "GroupRoleAssignmentDeletionAuditEvent",
    GroupRoleAssignmentUpdateAuditEvent = "GroupRoleAssignmentUpdateAuditEvent",
    IAadFolderConfiguration = "IAadFolderConfiguration",
    IAadScopeConfiguration = "IAadScopeConfiguration",
    IAccessPrincipalRisk = "IAccessPrincipalRisk",
    IAction = "IAction",
    IActivityRisk = "IActivityRisk",
    IAwsAccessResource = "IAwsAccessResource",
    IAwsAccessResourceModel = "IAwsAccessResourceModel",
    IAwsActivityResourceProfile = "IAwsActivityResourceProfile",
    IAwsCertificateResource = "IAwsCertificateResource",
    IAwsClassicSecurityGroup = "IAwsClassicSecurityGroup",
    IAwsCloudTrailTrailEventSelector = "IAwsCloudTrailTrailEventSelector",
    IAwsCloudWatchLoggingResource = "IAwsCloudWatchLoggingResource",
    IAwsCloudWatchLoggingResourceModel = "IAwsCloudWatchLoggingResourceModel",
    IAwsDnsRecordResource = "IAwsDnsRecordResource",
    IAwsDocDbCluster = "IAwsDocDbCluster",
    IAwsDocDbClusterModel = "IAwsDocDbClusterModel",
    IAwsDocDbClusterSnapshot = "IAwsDocDbClusterSnapshot",
    IAwsDocDbClusterSnapshotModel = "IAwsDocDbClusterSnapshotModel",
    IAwsEncryptedResource = "IAwsEncryptedResource",
    IAwsEncryptedResourceModel = "IAwsEncryptedResourceModel",
    IAwsExcessivePermissionPrincipalRisk = "IAwsExcessivePermissionPrincipalRisk",
    IAwsFederationUserModel = "IAwsFederationUserModel",
    IAwsFolderConfiguration = "IAwsFolderConfiguration",
    IAwsIdentity = "IAwsIdentity",
    IAwsNetworkedResource = "IAwsNetworkedResource",
    IAwsNetworkedResourceModel = "IAwsNetworkedResourceModel",
    IAwsNetworkEvaluationNetworkedResource = "IAwsNetworkEvaluationNetworkedResource",
    IAwsOriginatorComputeResource = "IAwsOriginatorComputeResource",
    IAwsOriginatorIdentity = "IAwsOriginatorIdentity",
    IAwsOriginatorKubernetesResource = "IAwsOriginatorKubernetesResource",
    IAwsOriginatorWorkloadResource = "IAwsOriginatorWorkloadResource",
    IAwsPolicyResource = "IAwsPolicyResource",
    IAwsPolicyResourceModel = "IAwsPolicyResourceModel",
    IAwsPolicyResourceProfile = "IAwsPolicyResourceProfile",
    IAwsPolicyResourceSnapshot = "IAwsPolicyResourceSnapshot",
    IAwsPrincipal = "IAwsPrincipal",
    IAwsRdsDocDbResource = "IAwsRdsDocDbResource",
    IAwsRdsNeptuneResource = "IAwsRdsNeptuneResource",
    IAwsResource = "IAwsResource",
    IAwsScopeConfiguration = "IAwsScopeConfiguration",
    IAwsTagResource = "IAwsTagResource",
    IAwsTenantEntity = "IAwsTenantEntity",
    IAwsTenantEntityModel = "IAwsTenantEntityModel",
    IAzureComputeVirtualMachine = "IAzureComputeVirtualMachine",
    IAzureComputeVirtualMachineProfile = "IAzureComputeVirtualMachineProfile",
    IAzureDnsRecordResource = "IAzureDnsRecordResource",
    IAzureEncryptedResource = "IAzureEncryptedResource",
    IAzureEncryptedResourceModel = "IAzureEncryptedResourceModel",
    IAzureEntity = "IAzureEntity",
    IAzureExcessivePermissionPrincipalRisk = "IAzureExcessivePermissionPrincipalRisk",
    IAzureExtensionResource = "IAzureExtensionResource",
    IAzureFolderConfiguration = "IAzureFolderConfiguration",
    IAzureManagedIdentityOriginatorResource = "IAzureManagedIdentityOriginatorResource",
    IAzureManagedIdentityOriginatorResourceModel = "IAzureManagedIdentityOriginatorResourceModel",
    IAzureNetworkAliasDnsZoneRecordSetResource = "IAzureNetworkAliasDnsZoneRecordSetResource",
    IAzureNetworkedResource = "IAzureNetworkedResource",
    IAzureNetworkedResourceModel = "IAzureNetworkedResourceModel",
    IAzureNetworkEvaluationResource = "IAzureNetworkEvaluationResource",
    IAzureNetworkInterface = "IAzureNetworkInterface",
    IAzureOriginatorKubernetesResource = "IAzureOriginatorKubernetesResource",
    IAzureOriginatorResource = "IAzureOriginatorResource",
    IAzurePrivateEndpointResource = "IAzurePrivateEndpointResource",
    IAzurePublicIpAddress = "IAzurePublicIpAddress",
    IAzureScopeConfiguration = "IAzureScopeConfiguration",
    IAzureTagResource = "IAzureTagResource",
    IBehaviorIdentityRiskModel = "IBehaviorIdentityRiskModel",
    IChildScopeConfiguration = "IChildScopeConfiguration",
    ICiFolderConfiguration = "ICiFolderConfiguration",
    ICiScopeConfiguration = "ICiScopeConfiguration",
    ICloudProviderResourceChange = "ICloudProviderResourceChange",
    ICodeFolderConfiguration = "ICodeFolderConfiguration",
    ICodeInfrastructureChange = "ICodeInfrastructureChange",
    ICodeScopeConfiguration = "ICodeScopeConfiguration",
    IConsistentHashable = "IConsistentHashable",
    IContainerImage = "IContainerImage",
    IContainerImageFilters = "IContainerImageFilters",
    IContainerImageModel = "IContainerImageModel",
    IContainerImageRepository = "IContainerImageRepository",
    IContainerImageRepositoryModel = "IContainerImageRepositoryModel",
    IContainerImageResource = "IContainerImageResource",
    IContainerImageResourceModel = "IContainerImageResourceModel",
    IDataAnalysisResource = "IDataAnalysisResource",
    IDataAnalysisResourceModel = "IDataAnalysisResourceModel",
    IDataAnalysisResourceModelFilters = "IDataAnalysisResourceModelFilters",
    Identity = "Identity",
    IdentityControllerGetIdentityModelsResponse = "IdentityControllerGetIdentityModelsResponse",
    IdentityModel = "IdentityModel",
    IdentityModelAccess = "IdentityModelAccess",
    IdentityPermission = "IdentityPermission",
    IdentityProviderTenantConfiguration = "IdentityProviderTenantConfiguration",
    IdentityProviderTenantState = "IdentityProviderTenantState",
    IdentityReference = "IdentityReference",
    IdentityRole = "IdentityRole",
    IDirectoryEntity = "IDirectoryEntity",
    IDirectoryGroup = "IDirectoryGroup",
    IDirectoryPrincipal = "IDirectoryPrincipal",
    IDirectoryUser = "IDirectoryUser",
    IDirectoryUserProfile = "IDirectoryUserProfile",
    IDnsRecordEntity = "IDnsRecordEntity",
    IDocument = "IDocument",
    IEndpointIntegrationEntityConfiguration = "IEndpointIntegrationEntityConfiguration",
    IEntity = "IEntity",
    IEntityControllerGetEntityModelPageContainerImageRequest = "IEntityControllerGetEntityModelPageContainerImageRequest",
    IEntityControllerGetEntityModelPageContainerImageRequestFilters = "IEntityControllerGetEntityModelPageContainerImageRequestFilters",
    IEntityGroup = "IEntityGroup",
    IEntityGroupEntity = "IEntityGroupEntity",
    IEntityGroupModel = "IEntityGroupModel",
    IEntityGroupTagEntity = "IEntityGroupTagEntity",
    IEntityModel = "IEntityModel",
    IEntityModelFilters = "IEntityModelFilters",
    IEntityNetworkFilters = "IEntityNetworkFilters",
    IEphemeralKubernetesResource = "IEphemeralKubernetesResource",
    IExcessivePermissionPrincipalRisk = "IExcessivePermissionPrincipalRisk",
    IGciDirectoryGroupMemberPrincipal = "IGciDirectoryGroupMemberPrincipal",
    IGciDirectoryPrincipal = "IGciDirectoryPrincipal",
    IGciFolderConfiguration = "IGciFolderConfiguration",
    IGciGcpResourceModel = "IGciGcpResourceModel",
    IGciGroup = "IGciGroup",
    IGciIdentity = "IGciIdentity",
    IGciIdentityProfile = "IGciIdentityProfile",
    IGciPartialIdentity = "IGciPartialIdentity",
    IGciPartialPrincipal = "IGciPartialPrincipal",
    IGciPrincipal = "IGciPrincipal",
    IGciPrincipalModel = "IGciPrincipalModel",
    IGciScopeConfiguration = "IGciScopeConfiguration",
    IGciTenantEntity = "IGciTenantEntity",
    IGciUser = "IGciUser",
    IGcpArtifactRepository = "IGcpArtifactRepository",
    IGcpDnsRecordEntity = "IGcpDnsRecordEntity",
    IGcpEncryptedResource = "IGcpEncryptedResource",
    IGcpEncryptedResourceModel = "IGcpEncryptedResourceModel",
    IGcpExcessivePermissionPrincipalRisk = "IGcpExcessivePermissionPrincipalRisk",
    IGcpFolderConfiguration = "IGcpFolderConfiguration",
    IGcpIamServiceAccount = "IGcpIamServiceAccount",
    IGcpIamServiceAccountModel = "IGcpIamServiceAccountModel",
    IGcpLoggingLogSinkDestinationResourceModel = "IGcpLoggingLogSinkDestinationResourceModel",
    IGcpNetworkedResource = "IGcpNetworkedResource",
    IGcpNetworkEvaluationEntity = "IGcpNetworkEvaluationEntity",
    IGcpPublicAccessPreventionResource = "IGcpPublicAccessPreventionResource",
    IGcpRoleBindingUsageResource = "IGcpRoleBindingUsageResource",
    IGcpScopeConfiguration = "IGcpScopeConfiguration",
    IGcpServerlessNetworkedResource = "IGcpServerlessNetworkedResource",
    IGcpServiceAccountOriginatorKubernetesResource = "IGcpServiceAccountOriginatorKubernetesResource",
    IGcpServiceAccountOriginatorResource = "IGcpServiceAccountOriginatorResource",
    IGcpServiceAccountOriginatorScopeResource = "IGcpServiceAccountOriginatorScopeResource",
    IGcpServiceAccountOriginatorScopeResourceModel = "IGcpServiceAccountOriginatorScopeResourceModel",
    IGcpSpecialGroup = "IGcpSpecialGroup",
    IGcpTagResource = "IGcpTagResource",
    IGenericResource = "IGenericResource",
    IGroupModel = "IGroupModel",
    IIdentityModel = "IIdentityModel",
    IKubernetesAdmissionControllerRiskPolicyConfiguration = "IKubernetesAdmissionControllerRiskPolicyConfiguration",
    IKubernetesCluster = "IKubernetesCluster",
    IKubernetesClusterModel = "IKubernetesClusterModel",
    IKubernetesClusterModelFilters = "IKubernetesClusterModelFilters",
    IKubernetesClusterModelFiltersSection = "IKubernetesClusterModelFiltersSection",
    IKubernetesClusterRole = "IKubernetesClusterRole",
    IKubernetesClusterRoleBinding = "IKubernetesClusterRoleBinding",
    IKubernetesConfigMap = "IKubernetesConfigMap",
    IKubernetesCronJob = "IKubernetesCronJob",
    IKubernetesDaemonSet = "IKubernetesDaemonSet",
    IKubernetesDeployment = "IKubernetesDeployment",
    IKubernetesDeploymentModel = "IKubernetesDeploymentModel",
    IKubernetesGroup = "IKubernetesGroup",
    IKubernetesIngress = "IKubernetesIngress",
    IKubernetesIngressClass = "IKubernetesIngressClass",
    IKubernetesIngressModel = "IKubernetesIngressModel",
    IKubernetesJob = "IKubernetesJob",
    IKubernetesNamespace = "IKubernetesNamespace",
    IKubernetesNamespaceModel = "IKubernetesNamespaceModel",
    IKubernetesNamespaceResource = "IKubernetesNamespaceResource",
    IKubernetesNamespaceResourceModel = "IKubernetesNamespaceResourceModel",
    IKubernetesNamespaceRole = "IKubernetesNamespaceRole",
    IKubernetesNamespaceRoleBinding = "IKubernetesNamespaceRoleBinding",
    IKubernetesNetworkPolicy = "IKubernetesNetworkPolicy",
    IKubernetesNode = "IKubernetesNode",
    IKubernetesNodeModel = "IKubernetesNodeModel",
    IKubernetesPersistentVolume = "IKubernetesPersistentVolume",
    IKubernetesPod = "IKubernetesPod",
    IKubernetesPodSecurityPolicy = "IKubernetesPodSecurityPolicy",
    IKubernetesPodTemplate = "IKubernetesPodTemplate",
    IKubernetesPrincipal = "IKubernetesPrincipal",
    IKubernetesPrincipalData = "IKubernetesPrincipalData",
    IKubernetesPrincipalModel = "IKubernetesPrincipalModel",
    IKubernetesReplicaSet = "IKubernetesReplicaSet",
    IKubernetesResource = "IKubernetesResource",
    IKubernetesResourceData = "IKubernetesResourceData",
    IKubernetesResourceModel = "IKubernetesResourceModel",
    IKubernetesRisk = "IKubernetesRisk",
    IKubernetesRole = "IKubernetesRole",
    IKubernetesRoleBinding = "IKubernetesRoleBinding",
    IKubernetesRoleBindingData = "IKubernetesRoleBindingData",
    IKubernetesRoleBindingModel = "IKubernetesRoleBindingModel",
    IKubernetesRoleModel = "IKubernetesRoleModel",
    IKubernetesService = "IKubernetesService",
    IKubernetesServiceAccount = "IKubernetesServiceAccount",
    IKubernetesServiceAccountModel = "IKubernetesServiceAccountModel",
    IKubernetesServiceModel = "IKubernetesServiceModel",
    IKubernetesStatefulSet = "IKubernetesStatefulSet",
    IKubernetesUser = "IKubernetesUser",
    IKubernetesWorkloadResource = "IKubernetesWorkloadResource",
    IKubernetesWorkloadResourceModel = "IKubernetesWorkloadResourceModel",
    ILicensingDocument = "ILicensingDocument",
    IManagedKubernetesCluster = "IManagedKubernetesCluster",
    IManagedKubernetesClusterModelFilters = "IManagedKubernetesClusterModelFilters",
    InactiveIdentityAttribute = "InactiveIdentityAttribute",
    INetworkAccessResourceModel = "INetworkAccessResourceModel",
    INetworkedResource = "INetworkedResource",
    INetworkInboundExternalResourceRiskModel = "INetworkInboundExternalResourceRiskModel",
    InfrastructureConfigurationSection = "InfrastructureConfigurationSection",
    InfrastructureConfigurationSectionAws = "InfrastructureConfigurationSectionAws",
    InfrastructureConfigurationSectionAwsOutpostData = "InfrastructureConfigurationSectionAwsOutpostData",
    InfrastructureConfigurationSectionAzure = "InfrastructureConfigurationSectionAzure",
    InfrastructureConfigurationSectionAzureRegionData = "InfrastructureConfigurationSectionAzureRegionData",
    InfrastructureConfigurationSectionAzureRegionDataOutpost = "InfrastructureConfigurationSectionAzureRegionDataOutpost",
    InfrastructureConfigurationSectionAzureRegionDataOutpostStorageAccount = "InfrastructureConfigurationSectionAzureRegionDataOutpostStorageAccount",
    InfrastructureConfigurationSectionAzureRegionDataOutpostVirtualNetwork = "InfrastructureConfigurationSectionAzureRegionDataOutpostVirtualNetwork",
    InfrastructureConfigurationSectionGcp = "InfrastructureConfigurationSectionGcp",
    InfrastructureConfigurationSectionGcpOutpostData = "InfrastructureConfigurationSectionGcpOutpostData",
    InfrastructureConfigurationSectionOci = "InfrastructureConfigurationSectionOci",
    InfrastructureConfigurationSectionOciOutpostData = "InfrastructureConfigurationSectionOciOutpostData",
    InfrastructureConfigurationSectionSegment = "InfrastructureConfigurationSectionSegment",
    IntegrationApiServiceConfiguration = "IntegrationApiServiceConfiguration",
    IntegrationDelivery = "IntegrationDelivery",
    IntegrationEntityConfiguration = "IntegrationEntityConfiguration",
    IntegrationType = "IntegrationType",
    InternalSubnetCreationAuditEvent = "InternalSubnetCreationAuditEvent",
    InternalSubnetDeletionAuditEvent = "InternalSubnetDeletionAuditEvent",
    InternalSubnetUpdateAuditEvent = "InternalSubnetUpdateAuditEvent",
    IntRange = "IntRange",
    INullableConfigurationSection = "INullableConfigurationSection",
    InventoryReportDefinitionConfiguration = "InventoryReportDefinitionConfiguration",
    IObjectStore = "IObjectStore",
    IOciEncryptedResource = "IOciEncryptedResource",
    IOciEncryptedResourceModel = "IOciEncryptedResourceModel",
    IOciFolderConfiguration = "IOciFolderConfiguration",
    IOciNetworkedResource = "IOciNetworkedResource",
    IOciScopeConfiguration = "IOciScopeConfiguration",
    IOktaFolderConfiguration = "IOktaFolderConfiguration",
    IOktaScopeConfiguration = "IOktaScopeConfiguration",
    IOneLoginFolderConfiguration = "IOneLoginFolderConfiguration",
    IOneLoginScopeConfiguration = "IOneLoginScopeConfiguration",
    IOpenShiftBuildConfig = "IOpenShiftBuildConfig",
    IOpenShiftCatalogSource = "IOpenShiftCatalogSource",
    IOpenShiftDeploymentConfig = "IOpenShiftDeploymentConfig",
    IOpenShiftImageStream = "IOpenShiftImageStream",
    IOpenShiftProject = "IOpenShiftProject",
    IOpenShiftRoute = "IOpenShiftRoute",
    IOpenShiftSecurityContextConstraint = "IOpenShiftSecurityContextConstraint",
    IOpenShiftTemplate = "IOpenShiftTemplate",
    IOrganizationFolderConfiguration = "IOrganizationFolderConfiguration",
    IOrganizationTenantConfiguration = "IOrganizationTenantConfiguration",
    IpAddressFamily = "IpAddressFamily",
    IpAddressRange = "IpAddressRange",
    IpAddressSearchableData = "IpAddressSearchableData",
    IPartialEntity = "IPartialEntity",
    IPermissionEligibilityAuditEventPermissionAssignmentEligibilityData = "IPermissionEligibilityAuditEventPermissionAssignmentEligibilityData",
    IPermissionManagementGroup = "IPermissionManagementGroup",
    IPermissionManagementGroupModel = "IPermissionManagementGroupModel",
    IPermissionManagementPrincipal = "IPermissionManagementPrincipal",
    IPermissionManagementPrincipalModel = "IPermissionManagementPrincipalModel",
    IPermissionManagementUser = "IPermissionManagementUser",
    IPermissionManagementUserModel = "IPermissionManagementUserModel",
    IPingIdentityFolderConfiguration = "IPingIdentityFolderConfiguration",
    IPingIdentityScopeConfiguration = "IPingIdentityScopeConfiguration",
    IPrincipalModel = "IPrincipalModel",
    IPrincipalModelAccessScopes = "IPrincipalModelAccessScopes",
    IProjectScopeConfiguration = "IProjectScopeConfiguration",
    IPublicRisk = "IPublicRisk",
    IRelatedTenantsEntityModel = "IRelatedTenantsEntityModel",
    IResource = "IResource",
    IResourceModel = "IResourceModel",
    IRisk = "IRisk",
    IRiskMessageAuditEvent = "IRiskMessageAuditEvent",
    IRiskModel = "IRiskModel",
    IScopeConfiguration = "IScopeConfiguration",
    IScopeConfigurationSectionOutpost = "IScopeConfigurationSectionOutpost",
    ISecretExistsRiskModel = "ISecretExistsRiskModel",
    IService = "IService",
    IServiceIdentity = "IServiceIdentity",
    IServiceIdentityModel = "IServiceIdentityModel",
    ISummarySection = "ISummarySection",
    ITagEntity = "ITagEntity",
    ITagEntityModel = "ITagEntityModel",
    ItemPage = "ItemPage",
    ItemSelection = "ItemSelection",
    ItemSelectionBase = "ItemSelectionBase",
    ITenantDocument = "ITenantDocument",
    ITenantEntity = "ITenantEntity",
    ITenantEntityModel = "ITenantEntityModel",
    ITenantOrganizationConfiguration = "ITenantOrganizationConfiguration",
    ITunnelSessionScopeSystemEntityConfiguration = "ITunnelSessionScopeSystemEntityConfiguration",
    ITypeNameObject = "ITypeNameObject",
    IUdmObjectModel = "IUdmObjectModel",
    IUnmanagedKubernetesCluster = "IUnmanagedKubernetesCluster",
    IUnmanagedKubernetesClusterModel = "IUnmanagedKubernetesClusterModel",
    IUnmanagedKubernetesClusterModelFilters = "IUnmanagedKubernetesClusterModelFilters",
    IUnmanagedKubernetesClusterModelFiltersSection = "IUnmanagedKubernetesClusterModelFiltersSection",
    IUser = "IUser",
    IUserModel = "IUserModel",
    IVendorServiceIdentity = "IVendorServiceIdentity",
    IVendorServiceIdentityModel = "IVendorServiceIdentityModel",
    IVirtualMachine = "IVirtualMachine",
    IVirtualMachineDiskResourceFilters = "IVirtualMachineDiskResourceFilters",
    IVirtualMachineDiskResourceModel = "IVirtualMachineDiskResourceModel",
    IVirtualMachineImage = "IVirtualMachineImage",
    IVirtualMachineImageModel = "IVirtualMachineImageModel",
    IVirtualMachineImageRiskModel = "IVirtualMachineImageRiskModel",
    IVirtualMachineModel = "IVirtualMachineModel",
    IWorkloadResource = "IWorkloadResource",
    IWorkloadResourceMaliciousFileRisk = "IWorkloadResourceMaliciousFileRisk",
    IWorkloadResourceModel = "IWorkloadResourceModel",
    IWorkloadResourceOperatingSystemRisk = "IWorkloadResourceOperatingSystemRisk",
    IWorkloadResourceOperatingSystemUnpatchedRisk = "IWorkloadResourceOperatingSystemUnpatchedRisk",
    IWorkloadResourceOperatingSystemUnpatchedRiskModel = "IWorkloadResourceOperatingSystemUnpatchedRiskModel",
    IWorkloadResourceVulnerabilityRelatedRisk = "IWorkloadResourceVulnerabilityRelatedRisk",
    IWorkloadResourceVulnerabilityRisk = "IWorkloadResourceVulnerabilityRisk",
    IWorkloadResourceVulnerabilityRiskModel = "IWorkloadResourceVulnerabilityRiskModel",
    JiraAutoCompleteSuggestion = "JiraAutoCompleteSuggestion",
    JiraCustomLabelsField = "JiraCustomLabelsField",
    JiraDateField = "JiraDateField",
    JiraDateTimeField = "JiraDateTimeField",
    JiraDelivery = "JiraDelivery",
    JiraDeliveryProject = "JiraDeliveryProject",
    JiraEpic = "JiraEpic",
    JiraField = "JiraField",
    JiraInstanceAuditEvent = "JiraInstanceAuditEvent",
    JiraInstanceConfiguration = "JiraInstanceConfiguration",
    JiraInstanceConfigurationConnectionInfo = "JiraInstanceConfigurationConnectionInfo",
    JiraInstanceConfigurationConnectionInfoAuthenticationType = "JiraInstanceConfigurationConnectionInfoAuthenticationType",
    JiraInstanceConfigurationProject = "JiraInstanceConfigurationProject",
    JiraInstanceCreationAuditEvent = "JiraInstanceCreationAuditEvent",
    JiraInstanceDeletionAuditEvent = "JiraInstanceDeletionAuditEvent",
    JiraInstanceDeploymentType = "JiraInstanceDeploymentType",
    JiraInstanceState = "JiraInstanceState",
    JiraInstanceStateIssue = "JiraInstanceStateIssue",
    JiraInstanceStateProject = "JiraInstanceStateProject",
    JiraInstanceStateProjectIssue = "JiraInstanceStateProjectIssue",
    JiraInstanceUpdateAuditEvent = "JiraInstanceUpdateAuditEvent",
    JiraIssue = "JiraIssue",
    JiraIssueCreationData = "JiraIssueCreationData",
    JiraIssueInfo = "JiraIssueInfo",
    JiraMultiSelectionField = "JiraMultiSelectionField",
    JiraNumberField = "JiraNumberField",
    JiraPriority = "JiraPriority",
    JiraProductType = "JiraProductType",
    JiraProject = "JiraProject",
    JiraProjectAuditEvent = "JiraProjectAuditEvent",
    JiraProjectCreationAuditEvent = "JiraProjectCreationAuditEvent",
    JiraProjectDeletionAuditEvent = "JiraProjectDeletionAuditEvent",
    JiraProjectIssueTypeData = "JiraProjectIssueTypeData",
    JiraProjectType = "JiraProjectType",
    JiraProjectUpdateAuditEvent = "JiraProjectUpdateAuditEvent",
    JiraSelectionField = "JiraSelectionField",
    JiraSingleSelectionField = "JiraSingleSelectionField",
    JiraSprint = "JiraSprint",
    JiraStatus = "JiraStatus",
    JiraStringField = "JiraStringField",
    JiraUnsupportedField = "JiraUnsupportedField",
    JiraUser = "JiraUser",
    JiraUserField = "JiraUserField",
    JobManagerConfigurationSection = "JobManagerConfigurationSection",
    JobManagerConfigurationSectionServiceConfiguration = "JobManagerConfigurationSectionServiceConfiguration",
    KubernetesAdmissionControllerRequiredLabelsRiskPolicyConfiguration = "KubernetesAdmissionControllerRequiredLabelsRiskPolicyConfiguration",
    KubernetesAdmissionControllerResourceOperation = "KubernetesAdmissionControllerResourceOperation",
    KubernetesAdmissionControllerRiskPolicyConfiguration = "KubernetesAdmissionControllerRiskPolicyConfiguration",
    KubernetesAdmissionControllerRiskPolicyEffect = "KubernetesAdmissionControllerRiskPolicyEffect",
    KubernetesAdmissionControllerRiskPolicyResult = "KubernetesAdmissionControllerRiskPolicyResult",
    KubernetesAdmissionControllerWorkloadResourceContainerCapabilityRiskPolicyConfiguration = "KubernetesAdmissionControllerWorkloadResourceContainerCapabilityRiskPolicyConfiguration",
    KubernetesAdmissionControllerWorkloadResourceContainerImageAllowedRepositoriesRiskPolicyConfiguration = "KubernetesAdmissionControllerWorkloadResourceContainerImageAllowedRepositoriesRiskPolicyConfiguration",
    KubernetesAdmissionControllerWorkloadResourceContainerImageDigestNotExistsRiskPolicyConfiguration = "KubernetesAdmissionControllerWorkloadResourceContainerImageDigestNotExistsRiskPolicyConfiguration",
    KubernetesAdmissionControllerWorkloadResourceContainerImageNotAllowedRepositoriesRiskPolicyConfiguration = "KubernetesAdmissionControllerWorkloadResourceContainerImageNotAllowedRepositoriesRiskPolicyConfiguration",
    KubernetesAdmissionControllerWorkloadResourceContainerPrivilegedRiskPolicyConfiguration = "KubernetesAdmissionControllerWorkloadResourceContainerPrivilegedRiskPolicyConfiguration",
    KubernetesAdmissionControllerWorkloadResourceContainerPrivilegeEscalationRiskPolicyConfiguration = "KubernetesAdmissionControllerWorkloadResourceContainerPrivilegeEscalationRiskPolicyConfiguration",
    KubernetesAdmissionControllerWorkloadResourceContainerRiskPolicyConfiguration = "KubernetesAdmissionControllerWorkloadResourceContainerRiskPolicyConfiguration",
    KubernetesAdmissionControllerWorkloadResourceContainerRootUserEnabledRiskPolicyConfiguration = "KubernetesAdmissionControllerWorkloadResourceContainerRootUserEnabledRiskPolicyConfiguration",
    KubernetesAdmissionControllerWorkloadResourceHostNamespaceInterProcessCommunicationEnabledRiskPolicyConfiguration = "KubernetesAdmissionControllerWorkloadResourceHostNamespaceInterProcessCommunicationEnabledRiskPolicyConfiguration",
    KubernetesAdmissionControllerWorkloadResourceHostNamespaceNetworkEnabledRiskPolicyConfiguration = "KubernetesAdmissionControllerWorkloadResourceHostNamespaceNetworkEnabledRiskPolicyConfiguration",
    KubernetesAdmissionControllerWorkloadResourceHostNamespaceProcessIdEnabledRiskPolicyConfiguration = "KubernetesAdmissionControllerWorkloadResourceHostNamespaceProcessIdEnabledRiskPolicyConfiguration",
    KubernetesAdmissionControllerWorkloadResourceRiskPolicyConfiguration = "KubernetesAdmissionControllerWorkloadResourceRiskPolicyConfiguration",
    KubernetesApiServiceConfiguration = "KubernetesApiServiceConfiguration",
    KubernetesClusterAdmissionControllerEvent = "KubernetesClusterAdmissionControllerEvent",
    KubernetesClusterAdmissionControllerEventFilters = "KubernetesClusterAdmissionControllerEventFilters",
    KubernetesClusterAdmissionControllerEventModel = "KubernetesClusterAdmissionControllerEventModel",
    KubernetesClusterAdmissionControllerEventRiskPolicyData = "KubernetesClusterAdmissionControllerEventRiskPolicyData",
    KubernetesClusterApiServerAlwaysAdmitAdmissionControllerPluginEnabledRisk = "KubernetesClusterApiServerAlwaysAdmitAdmissionControllerPluginEnabledRisk",
    KubernetesClusterApiServerAlwaysAdmitAdmissionControllerPluginEnabledRiskModel = "KubernetesClusterApiServerAlwaysAdmitAdmissionControllerPluginEnabledRiskModel",
    KubernetesClusterApiServerAlwaysAdmitAdmissionControllerPluginEnabledRiskPolicyConfiguration = "KubernetesClusterApiServerAlwaysAdmitAdmissionControllerPluginEnabledRiskPolicyConfiguration",
    KubernetesClusterApiServerAuthorizationModeAlwaysAllowRisk = "KubernetesClusterApiServerAuthorizationModeAlwaysAllowRisk",
    KubernetesClusterApiServerAuthorizationModeAlwaysAllowRiskModel = "KubernetesClusterApiServerAuthorizationModeAlwaysAllowRiskModel",
    KubernetesClusterApiServerAuthorizationModeAlwaysAllowRiskPolicyConfiguration = "KubernetesClusterApiServerAuthorizationModeAlwaysAllowRiskPolicyConfiguration",
    KubernetesClusterApiServerBasicAuthenticationEnabledRisk = "KubernetesClusterApiServerBasicAuthenticationEnabledRisk",
    KubernetesClusterApiServerBasicAuthenticationEnabledRiskModel = "KubernetesClusterApiServerBasicAuthenticationEnabledRiskModel",
    KubernetesClusterApiServerBasicAuthenticationEnabledRiskPolicyConfiguration = "KubernetesClusterApiServerBasicAuthenticationEnabledRiskPolicyConfiguration",
    KubernetesClusterApiServerClientCertificateAuthenticationDisabledRisk = "KubernetesClusterApiServerClientCertificateAuthenticationDisabledRisk",
    KubernetesClusterApiServerClientCertificateAuthenticationDisabledRiskModel = "KubernetesClusterApiServerClientCertificateAuthenticationDisabledRiskModel",
    KubernetesClusterApiServerClientCertificateAuthenticationDisabledRiskPolicyConfiguration = "KubernetesClusterApiServerClientCertificateAuthenticationDisabledRiskPolicyConfiguration",
    KubernetesClusterApiServerEtcdCertificateAuthorityCertificateNotExistRisk = "KubernetesClusterApiServerEtcdCertificateAuthorityCertificateNotExistRisk",
    KubernetesClusterApiServerEtcdCertificateAuthorityCertificateNotExistRiskModel = "KubernetesClusterApiServerEtcdCertificateAuthorityCertificateNotExistRiskModel",
    KubernetesClusterApiServerEtcdCertificateAuthorityCertificateNotExistRiskPolicyConfiguration = "KubernetesClusterApiServerEtcdCertificateAuthorityCertificateNotExistRiskPolicyConfiguration",
    KubernetesClusterApiServerEtcdClientCertificateAuthenticationDisabledRisk = "KubernetesClusterApiServerEtcdClientCertificateAuthenticationDisabledRisk",
    KubernetesClusterApiServerEtcdClientCertificateAuthenticationDisabledRiskModel = "KubernetesClusterApiServerEtcdClientCertificateAuthenticationDisabledRiskModel",
    KubernetesClusterApiServerEtcdClientCertificateAuthenticationDisabledRiskPolicyConfiguration = "KubernetesClusterApiServerEtcdClientCertificateAuthenticationDisabledRiskPolicyConfiguration",
    KubernetesClusterApiServerInsecureListenerEnabledRisk = "KubernetesClusterApiServerInsecureListenerEnabledRisk",
    KubernetesClusterApiServerInsecureListenerEnabledRiskModel = "KubernetesClusterApiServerInsecureListenerEnabledRiskModel",
    KubernetesClusterApiServerInsecureListenerEnabledRiskPolicyConfiguration = "KubernetesClusterApiServerInsecureListenerEnabledRiskPolicyConfiguration",
    KubernetesClusterApiServerInsecureTlsProtocolRisk = "KubernetesClusterApiServerInsecureTlsProtocolRisk",
    KubernetesClusterApiServerInsecureTlsProtocolRiskModel = "KubernetesClusterApiServerInsecureTlsProtocolRiskModel",
    KubernetesClusterApiServerInsecureTlsProtocolRiskPolicyConfiguration = "KubernetesClusterApiServerInsecureTlsProtocolRiskPolicyConfiguration",
    KubernetesClusterApiServerKubeletCertificateValidationDisabledRisk = "KubernetesClusterApiServerKubeletCertificateValidationDisabledRisk",
    KubernetesClusterApiServerKubeletCertificateValidationDisabledRiskModel = "KubernetesClusterApiServerKubeletCertificateValidationDisabledRiskModel",
    KubernetesClusterApiServerKubeletCertificateValidationDisabledRiskPolicyConfiguration = "KubernetesClusterApiServerKubeletCertificateValidationDisabledRiskPolicyConfiguration",
    KubernetesClusterApiServerKubeletClientCertificateAuthenticationDisabledRisk = "KubernetesClusterApiServerKubeletClientCertificateAuthenticationDisabledRisk",
    KubernetesClusterApiServerKubeletClientCertificateAuthenticationDisabledRiskModel = "KubernetesClusterApiServerKubeletClientCertificateAuthenticationDisabledRiskModel",
    KubernetesClusterApiServerKubeletClientCertificateAuthenticationDisabledRiskPolicyConfiguration = "KubernetesClusterApiServerKubeletClientCertificateAuthenticationDisabledRiskPolicyConfiguration",
    KubernetesClusterApiServerNamespaceLifecycleAdmissionControllerPluginDisabledRisk = "KubernetesClusterApiServerNamespaceLifecycleAdmissionControllerPluginDisabledRisk",
    KubernetesClusterApiServerNamespaceLifecycleAdmissionControllerPluginDisabledRiskModel = "KubernetesClusterApiServerNamespaceLifecycleAdmissionControllerPluginDisabledRiskModel",
    KubernetesClusterApiServerNamespaceLifecycleAdmissionControllerPluginDisabledRiskPolicyConfiguration = "KubernetesClusterApiServerNamespaceLifecycleAdmissionControllerPluginDisabledRiskPolicyConfiguration",
    KubernetesClusterApiServerNodeRestrictionAdmissionControllerPluginDisabledRisk = "KubernetesClusterApiServerNodeRestrictionAdmissionControllerPluginDisabledRisk",
    KubernetesClusterApiServerNodeRestrictionAdmissionControllerPluginDisabledRiskModel = "KubernetesClusterApiServerNodeRestrictionAdmissionControllerPluginDisabledRiskModel",
    KubernetesClusterApiServerNodeRestrictionAdmissionControllerPluginDisabledRiskPolicyConfiguration = "KubernetesClusterApiServerNodeRestrictionAdmissionControllerPluginDisabledRiskPolicyConfiguration",
    KubernetesClusterApiServerRoleBasedAccessControlAuthorizationModeDisabledRisk = "KubernetesClusterApiServerRoleBasedAccessControlAuthorizationModeDisabledRisk",
    KubernetesClusterApiServerRoleBasedAccessControlAuthorizationModeDisabledRiskModel = "KubernetesClusterApiServerRoleBasedAccessControlAuthorizationModeDisabledRiskModel",
    KubernetesClusterApiServerRoleBasedAccessControlAuthorizationModeDisabledRiskPolicyConfiguration = "KubernetesClusterApiServerRoleBasedAccessControlAuthorizationModeDisabledRiskPolicyConfiguration",
    KubernetesClusterApiServerSecurityContextConstraintAdmissionControllerPluginDisabledRisk = "KubernetesClusterApiServerSecurityContextConstraintAdmissionControllerPluginDisabledRisk",
    KubernetesClusterApiServerSecurityContextConstraintAdmissionControllerPluginDisabledRiskModel = "KubernetesClusterApiServerSecurityContextConstraintAdmissionControllerPluginDisabledRiskModel",
    KubernetesClusterApiServerSecurityContextConstraintAdmissionControllerPluginDisabledRiskPolicyConfiguration = "KubernetesClusterApiServerSecurityContextConstraintAdmissionControllerPluginDisabledRiskPolicyConfiguration",
    KubernetesClusterApiServerServiceAccountAdmissionControllerPluginDisabledRisk = "KubernetesClusterApiServerServiceAccountAdmissionControllerPluginDisabledRisk",
    KubernetesClusterApiServerServiceAccountAdmissionControllerPluginDisabledRiskModel = "KubernetesClusterApiServerServiceAccountAdmissionControllerPluginDisabledRiskModel",
    KubernetesClusterApiServerServiceAccountAdmissionControllerPluginDisabledRiskPolicyConfiguration = "KubernetesClusterApiServerServiceAccountAdmissionControllerPluginDisabledRiskPolicyConfiguration",
    KubernetesClusterApiServerServiceAccountLookupDisabledRisk = "KubernetesClusterApiServerServiceAccountLookupDisabledRisk",
    KubernetesClusterApiServerServiceAccountLookupDisabledRiskModel = "KubernetesClusterApiServerServiceAccountLookupDisabledRiskModel",
    KubernetesClusterApiServerServiceAccountLookupDisabledRiskPolicyConfiguration = "KubernetesClusterApiServerServiceAccountLookupDisabledRiskPolicyConfiguration",
    KubernetesClusterApiServerServiceAccountTokenKeyNotExistRisk = "KubernetesClusterApiServerServiceAccountTokenKeyNotExistRisk",
    KubernetesClusterApiServerServiceAccountTokenKeyNotExistRiskModel = "KubernetesClusterApiServerServiceAccountTokenKeyNotExistRiskModel",
    KubernetesClusterApiServerServiceAccountTokenKeyNotExistRiskPolicyConfiguration = "KubernetesClusterApiServerServiceAccountTokenKeyNotExistRiskPolicyConfiguration",
    KubernetesClusterApiServerStaticTokenAuthenticationEnabledRisk = "KubernetesClusterApiServerStaticTokenAuthenticationEnabledRisk",
    KubernetesClusterApiServerStaticTokenAuthenticationEnabledRiskModel = "KubernetesClusterApiServerStaticTokenAuthenticationEnabledRiskModel",
    KubernetesClusterApiServerStaticTokenAuthenticationEnabledRiskPolicyConfiguration = "KubernetesClusterApiServerStaticTokenAuthenticationEnabledRiskPolicyConfiguration",
    KubernetesClusterApiServerTlsCertificateNotExistRisk = "KubernetesClusterApiServerTlsCertificateNotExistRisk",
    KubernetesClusterApiServerTlsCertificateNotExistRiskModel = "KubernetesClusterApiServerTlsCertificateNotExistRiskModel",
    KubernetesClusterApiServerTlsCertificateNotExistRiskPolicyConfiguration = "KubernetesClusterApiServerTlsCertificateNotExistRiskPolicyConfiguration",
    KubernetesClusterAuthenticationPrincipalRoleBindingExistsRisk = "KubernetesClusterAuthenticationPrincipalRoleBindingExistsRisk",
    KubernetesClusterAuthenticationPrincipalRoleBindingExistsRiskModel = "KubernetesClusterAuthenticationPrincipalRoleBindingExistsRiskModel",
    KubernetesClusterAuthenticationPrincipalRoleBindingExistsRiskPolicyConfiguration = "KubernetesClusterAuthenticationPrincipalRoleBindingExistsRiskPolicyConfiguration",
    KubernetesClusterClusterAdminRoleBindingExistsRisk = "KubernetesClusterClusterAdminRoleBindingExistsRisk",
    KubernetesClusterClusterAdminRoleBindingExistsRiskModel = "KubernetesClusterClusterAdminRoleBindingExistsRiskModel",
    KubernetesClusterClusterAdminRoleBindingExistsRiskPolicyConfiguration = "KubernetesClusterClusterAdminRoleBindingExistsRiskPolicyConfiguration",
    KubernetesClusterConnectorAuditEvent = "KubernetesClusterConnectorAuditEvent",
    KubernetesClusterConnectorConfiguration = "KubernetesClusterConnectorConfiguration",
    KubernetesClusterConnectorCreationAuditEvent = "KubernetesClusterConnectorCreationAuditEvent",
    KubernetesClusterConnectorDeletionAuditEvent = "KubernetesClusterConnectorDeletionAuditEvent",
    KubernetesClusterConnectorUpdateAuditEvent = "KubernetesClusterConnectorUpdateAuditEvent",
    KubernetesClusterControllerManagerInsecureListenerIpAddressRisk = "KubernetesClusterControllerManagerInsecureListenerIpAddressRisk",
    KubernetesClusterControllerManagerInsecureListenerIpAddressRiskModel = "KubernetesClusterControllerManagerInsecureListenerIpAddressRiskModel",
    KubernetesClusterControllerManagerInsecureListenerIpAddressRiskPolicyConfiguration = "KubernetesClusterControllerManagerInsecureListenerIpAddressRiskPolicyConfiguration",
    KubernetesClusterControllerManagerServiceAccountCredentialsDisabledRisk = "KubernetesClusterControllerManagerServiceAccountCredentialsDisabledRisk",
    KubernetesClusterControllerManagerServiceAccountCredentialsDisabledRiskModel = "KubernetesClusterControllerManagerServiceAccountCredentialsDisabledRiskModel",
    KubernetesClusterControllerManagerServiceAccountCredentialsDisabledRiskPolicyConfiguration = "KubernetesClusterControllerManagerServiceAccountCredentialsDisabledRiskPolicyConfiguration",
    KubernetesClusterControllerManagerServiceAccountTokenKeyNotExistRisk = "KubernetesClusterControllerManagerServiceAccountTokenKeyNotExistRisk",
    KubernetesClusterControllerManagerServiceAccountTokenKeyNotExistRiskModel = "KubernetesClusterControllerManagerServiceAccountTokenKeyNotExistRiskModel",
    KubernetesClusterControllerManagerServiceAccountTokenKeyNotExistRiskPolicyConfiguration = "KubernetesClusterControllerManagerServiceAccountTokenKeyNotExistRiskPolicyConfiguration",
    KubernetesClusterControllerManagerServiceAccountTokenSecretCertificateAuthorityCertificateNotExistRisk = "KubernetesClusterControllerManagerServiceAccountTokenSecretCertificateAuthorityCertificateNotExistRisk",
    KubernetesClusterControllerManagerServiceAccountTokenSecretCertificateAuthorityCertificateNotExistRiskModel = "KubernetesClusterControllerManagerServiceAccountTokenSecretCertificateAuthorityCertificateNotExistRiskModel",
    KubernetesClusterControllerManagerServiceAccountTokenSecretCertificateAuthorityCertificateNotExistRiskPolicyConfiguration = "KubernetesClusterControllerManagerServiceAccountTokenSecretCertificateAuthorityCertificateNotExistRiskPolicyConfiguration",
    KubernetesClusterData = "KubernetesClusterData",
    KubernetesClusterDefaultNamespaceUsedRisk = "KubernetesClusterDefaultNamespaceUsedRisk",
    KubernetesClusterDefaultNamespaceUsedRiskModel = "KubernetesClusterDefaultNamespaceUsedRiskModel",
    KubernetesClusterDefaultNamespaceUsedRiskPolicyConfiguration = "KubernetesClusterDefaultNamespaceUsedRiskPolicyConfiguration",
    KubernetesClusterEndOfLifeRisk = "KubernetesClusterEndOfLifeRisk",
    KubernetesClusterEndOfLifeRiskModel = "KubernetesClusterEndOfLifeRiskModel",
    KubernetesClusterEndOfLifeRiskPolicyConfiguration = "KubernetesClusterEndOfLifeRiskPolicyConfiguration",
    KubernetesClusterEtcdClientCertificateAuthenticationDisabledRisk = "KubernetesClusterEtcdClientCertificateAuthenticationDisabledRisk",
    KubernetesClusterEtcdClientCertificateAuthenticationDisabledRiskModel = "KubernetesClusterEtcdClientCertificateAuthenticationDisabledRiskModel",
    KubernetesClusterEtcdClientCertificateAuthenticationDisabledRiskPolicyConfiguration = "KubernetesClusterEtcdClientCertificateAuthenticationDisabledRiskPolicyConfiguration",
    KubernetesClusterEtcdPeerClientCertificateAuthenticationDisabledRisk = "KubernetesClusterEtcdPeerClientCertificateAuthenticationDisabledRisk",
    KubernetesClusterEtcdPeerClientCertificateAuthenticationDisabledRiskModel = "KubernetesClusterEtcdPeerClientCertificateAuthenticationDisabledRiskModel",
    KubernetesClusterEtcdPeerClientCertificateAuthenticationDisabledRiskPolicyConfiguration = "KubernetesClusterEtcdPeerClientCertificateAuthenticationDisabledRiskPolicyConfiguration",
    KubernetesClusterEtcdPeerSelfSignedTlsCertificateGenerationEnabledRisk = "KubernetesClusterEtcdPeerSelfSignedTlsCertificateGenerationEnabledRisk",
    KubernetesClusterEtcdPeerSelfSignedTlsCertificateGenerationEnabledRiskModel = "KubernetesClusterEtcdPeerSelfSignedTlsCertificateGenerationEnabledRiskModel",
    KubernetesClusterEtcdPeerSelfSignedTlsCertificateGenerationEnabledRiskPolicyConfiguration = "KubernetesClusterEtcdPeerSelfSignedTlsCertificateGenerationEnabledRiskPolicyConfiguration",
    KubernetesClusterEtcdPeerTlsCertificateNotExistRisk = "KubernetesClusterEtcdPeerTlsCertificateNotExistRisk",
    KubernetesClusterEtcdPeerTlsCertificateNotExistRiskModel = "KubernetesClusterEtcdPeerTlsCertificateNotExistRiskModel",
    KubernetesClusterEtcdPeerTlsCertificateNotExistRiskPolicyConfiguration = "KubernetesClusterEtcdPeerTlsCertificateNotExistRiskPolicyConfiguration",
    KubernetesClusterEtcdSelfSignedTlsCertificateGenerationEnabledRisk = "KubernetesClusterEtcdSelfSignedTlsCertificateGenerationEnabledRisk",
    KubernetesClusterEtcdSelfSignedTlsCertificateGenerationEnabledRiskModel = "KubernetesClusterEtcdSelfSignedTlsCertificateGenerationEnabledRiskModel",
    KubernetesClusterEtcdSelfSignedTlsCertificateGenerationEnabledRiskPolicyConfiguration = "KubernetesClusterEtcdSelfSignedTlsCertificateGenerationEnabledRiskPolicyConfiguration",
    KubernetesClusterEtcdTlsCertificateNotExistRisk = "KubernetesClusterEtcdTlsCertificateNotExistRisk",
    KubernetesClusterEtcdTlsCertificateNotExistRiskModel = "KubernetesClusterEtcdTlsCertificateNotExistRiskModel",
    KubernetesClusterEtcdTlsCertificateNotExistRiskPolicyConfiguration = "KubernetesClusterEtcdTlsCertificateNotExistRiskPolicyConfiguration",
    KubernetesClusterEvent = "KubernetesClusterEvent",
    KubernetesClusterEventFilters = "KubernetesClusterEventFilters",
    KubernetesClusterEventModel = "KubernetesClusterEventModel",
    KubernetesClusterIdentityProviderAuthenticationDisabledRisk = "KubernetesClusterIdentityProviderAuthenticationDisabledRisk",
    KubernetesClusterIdentityProviderAuthenticationDisabledRiskModel = "KubernetesClusterIdentityProviderAuthenticationDisabledRiskModel",
    KubernetesClusterIdentityProviderAuthenticationDisabledRiskPolicyConfiguration = "KubernetesClusterIdentityProviderAuthenticationDisabledRiskPolicyConfiguration",
    KubernetesClusterKubeletAnonymousAuthenticationEnabledRisk = "KubernetesClusterKubeletAnonymousAuthenticationEnabledRisk",
    KubernetesClusterKubeletAnonymousAuthenticationEnabledRiskModel = "KubernetesClusterKubeletAnonymousAuthenticationEnabledRiskModel",
    KubernetesClusterKubeletAnonymousAuthenticationEnabledRiskPolicyConfiguration = "KubernetesClusterKubeletAnonymousAuthenticationEnabledRiskPolicyConfiguration",
    KubernetesClusterKubeletAuthorizationModeAlwaysAllowRisk = "KubernetesClusterKubeletAuthorizationModeAlwaysAllowRisk",
    KubernetesClusterKubeletAuthorizationModeAlwaysAllowRiskModel = "KubernetesClusterKubeletAuthorizationModeAlwaysAllowRiskModel",
    KubernetesClusterKubeletAuthorizationModeAlwaysAllowRiskPolicyConfiguration = "KubernetesClusterKubeletAuthorizationModeAlwaysAllowRiskPolicyConfiguration",
    KubernetesClusterKubeletClientCertificateAuthenticationDisabledRisk = "KubernetesClusterKubeletClientCertificateAuthenticationDisabledRisk",
    KubernetesClusterKubeletClientCertificateAuthenticationDisabledRiskModel = "KubernetesClusterKubeletClientCertificateAuthenticationDisabledRiskModel",
    KubernetesClusterKubeletClientCertificateAuthenticationDisabledRiskPolicyConfiguration = "KubernetesClusterKubeletClientCertificateAuthenticationDisabledRiskPolicyConfiguration",
    KubernetesClusterKubeletClientCertificateRotationDisabledRisk = "KubernetesClusterKubeletClientCertificateRotationDisabledRisk",
    KubernetesClusterKubeletClientCertificateRotationDisabledRiskModel = "KubernetesClusterKubeletClientCertificateRotationDisabledRiskModel",
    KubernetesClusterKubeletClientCertificateRotationDisabledRiskPolicyConfiguration = "KubernetesClusterKubeletClientCertificateRotationDisabledRiskPolicyConfiguration",
    KubernetesClusterKubeletHostnameOverrideUsedRisk = "KubernetesClusterKubeletHostnameOverrideUsedRisk",
    KubernetesClusterKubeletHostnameOverrideUsedRiskModel = "KubernetesClusterKubeletHostnameOverrideUsedRiskModel",
    KubernetesClusterKubeletHostnameOverrideUsedRiskPolicyConfiguration = "KubernetesClusterKubeletHostnameOverrideUsedRiskPolicyConfiguration",
    KubernetesClusterKubeletInsecureTlsProtocolRisk = "KubernetesClusterKubeletInsecureTlsProtocolRisk",
    KubernetesClusterKubeletInsecureTlsProtocolRiskModel = "KubernetesClusterKubeletInsecureTlsProtocolRiskModel",
    KubernetesClusterKubeletInsecureTlsProtocolRiskPolicyConfiguration = "KubernetesClusterKubeletInsecureTlsProtocolRiskPolicyConfiguration",
    KubernetesClusterKubeletInsufficientEventsPerSecondMaxCountRisk = "KubernetesClusterKubeletInsufficientEventsPerSecondMaxCountRisk",
    KubernetesClusterKubeletInsufficientEventsPerSecondMaxCountRiskModel = "KubernetesClusterKubeletInsufficientEventsPerSecondMaxCountRiskModel",
    KubernetesClusterKubeletInsufficientEventsPerSecondMaxCountRiskPolicyConfiguration = "KubernetesClusterKubeletInsufficientEventsPerSecondMaxCountRiskPolicyConfiguration",
    KubernetesClusterKubeletManageIpTablesDisabledRisk = "KubernetesClusterKubeletManageIpTablesDisabledRisk",
    KubernetesClusterKubeletManageIpTablesDisabledRiskModel = "KubernetesClusterKubeletManageIpTablesDisabledRiskModel",
    KubernetesClusterKubeletManageIpTablesDisabledRiskPolicyConfiguration = "KubernetesClusterKubeletManageIpTablesDisabledRiskPolicyConfiguration",
    KubernetesClusterKubeletReadOnlyPortEnabledRisk = "KubernetesClusterKubeletReadOnlyPortEnabledRisk",
    KubernetesClusterKubeletReadOnlyPortEnabledRiskModel = "KubernetesClusterKubeletReadOnlyPortEnabledRiskModel",
    KubernetesClusterKubeletReadOnlyPortEnabledRiskPolicyConfiguration = "KubernetesClusterKubeletReadOnlyPortEnabledRiskPolicyConfiguration",
    KubernetesClusterKubeletRisk = "KubernetesClusterKubeletRisk",
    KubernetesClusterKubeletRiskNodeData = "KubernetesClusterKubeletRiskNodeData",
    KubernetesClusterKubeletServerCertificateRotationDisabledRisk = "KubernetesClusterKubeletServerCertificateRotationDisabledRisk",
    KubernetesClusterKubeletServerCertificateRotationDisabledRiskModel = "KubernetesClusterKubeletServerCertificateRotationDisabledRiskModel",
    KubernetesClusterKubeletServerCertificateRotationDisabledRiskPolicyConfiguration = "KubernetesClusterKubeletServerCertificateRotationDisabledRiskPolicyConfiguration",
    KubernetesClusterKubeletStreamingConnectionIdleTimeoutDisabledRisk = "KubernetesClusterKubeletStreamingConnectionIdleTimeoutDisabledRisk",
    KubernetesClusterKubeletStreamingConnectionIdleTimeoutDisabledRiskModel = "KubernetesClusterKubeletStreamingConnectionIdleTimeoutDisabledRiskModel",
    KubernetesClusterKubeletStreamingConnectionIdleTimeoutDisabledRiskPolicyConfiguration = "KubernetesClusterKubeletStreamingConnectionIdleTimeoutDisabledRiskPolicyConfiguration",
    KubernetesClusterKubeletTlsCertificateNotExistRisk = "KubernetesClusterKubeletTlsCertificateNotExistRisk",
    KubernetesClusterKubeletTlsCertificateNotExistRiskModel = "KubernetesClusterKubeletTlsCertificateNotExistRiskModel",
    KubernetesClusterKubeletTlsCertificateNotExistRiskPolicyConfiguration = "KubernetesClusterKubeletTlsCertificateNotExistRiskPolicyConfiguration",
    KubernetesClusterModelData = "KubernetesClusterModelData",
    KubernetesClusterModelFiltersSection = "KubernetesClusterModelFiltersSection",
    KubernetesClusterModelHelm = "KubernetesClusterModelHelm",
    KubernetesClusterModelHelmAdmissionController = "KubernetesClusterModelHelmAdmissionController",
    KubernetesClusterModelHelmComponent = "KubernetesClusterModelHelmComponent",
    KubernetesClusterModelHelmConnector = "KubernetesClusterModelHelmConnector",
    KubernetesClusterModelHelmSensor = "KubernetesClusterModelHelmSensor",
    KubernetesClusterNetworkPolicyUnsupportedRisk = "KubernetesClusterNetworkPolicyUnsupportedRisk",
    KubernetesClusterNetworkPolicyUnsupportedRiskModel = "KubernetesClusterNetworkPolicyUnsupportedRiskModel",
    KubernetesClusterNetworkPolicyUnsupportedRiskPolicyConfiguration = "KubernetesClusterNetworkPolicyUnsupportedRiskPolicyConfiguration",
    KubernetesClusterNodeUnrestrictedAccessFileRisk = "KubernetesClusterNodeUnrestrictedAccessFileRisk",
    KubernetesClusterNodeUnrestrictedAccessFileRiskModel = "KubernetesClusterNodeUnrestrictedAccessFileRiskModel",
    KubernetesClusterNodeUnrestrictedAccessFileRiskModelUnrestrictedAccessItem = "KubernetesClusterNodeUnrestrictedAccessFileRiskModelUnrestrictedAccessItem",
    KubernetesClusterNodeUnrestrictedAccessFileRiskNodeDataFileUnrestrictedAccessType = "KubernetesClusterNodeUnrestrictedAccessFileRiskNodeDataFileUnrestrictedAccessType",
    KubernetesClusterNodeUnrestrictedAccessFileRiskPolicyConfiguration = "KubernetesClusterNodeUnrestrictedAccessFileRiskPolicyConfiguration",
    KubernetesClusterPlatform = "KubernetesClusterPlatform",
    KubernetesClusterPlatformEndOfLifeRisk = "KubernetesClusterPlatformEndOfLifeRisk",
    KubernetesClusterPlatformEndOfLifeRiskModel = "KubernetesClusterPlatformEndOfLifeRiskModel",
    KubernetesClusterPlatformEndOfLifeRiskPolicyConfiguration = "KubernetesClusterPlatformEndOfLifeRiskPolicyConfiguration",
    KubernetesClusterResourceEncryptionDisabledRisk = "KubernetesClusterResourceEncryptionDisabledRisk",
    KubernetesClusterResourceEncryptionDisabledRiskModel = "KubernetesClusterResourceEncryptionDisabledRiskModel",
    KubernetesClusterResourceEncryptionDisabledRiskPolicyConfiguration = "KubernetesClusterResourceEncryptionDisabledRiskPolicyConfiguration",
    KubernetesClusterRoleBindingData = "KubernetesClusterRoleBindingData",
    KubernetesClusterRoleData = "KubernetesClusterRoleData",
    KubernetesClusterSchedulerRoleBasedAccessAuthorizationDisabledRisk = "KubernetesClusterSchedulerRoleBasedAccessAuthorizationDisabledRisk",
    KubernetesClusterSchedulerRoleBasedAccessAuthorizationDisabledRiskModel = "KubernetesClusterSchedulerRoleBasedAccessAuthorizationDisabledRiskModel",
    KubernetesClusterSchedulerRoleBasedAccessAuthorizationDisabledRiskPolicyConfiguration = "KubernetesClusterSchedulerRoleBasedAccessAuthorizationDisabledRiskPolicyConfiguration",
    KubernetesConfigMapData = "KubernetesConfigMapData",
    KubernetesContainer = "KubernetesContainer",
    KubernetesContainerPort = "KubernetesContainerPort",
    KubernetesContainerSecurityContext = "KubernetesContainerSecurityContext",
    KubernetesContainerSecurityContextCapabilities = "KubernetesContainerSecurityContextCapabilities",
    KubernetesControllerClusterAdmissionControllerEventProperty = "KubernetesControllerClusterAdmissionControllerEventProperty",
    KubernetesControllerDeleteSystemKubernetesClusterRequest = "KubernetesControllerDeleteSystemKubernetesClusterRequest",
    KubernetesControllerGetKubernetesClusterAdmissionControllerEventFilterItemPageRequest = "KubernetesControllerGetKubernetesClusterAdmissionControllerEventFilterItemPageRequest",
    KubernetesControllerGetKubernetesClusterAdmissionControllerEventFilterItemPageResponse = "KubernetesControllerGetKubernetesClusterAdmissionControllerEventFilterItemPageResponse",
    KubernetesControllerGetKubernetesClusterAdmissionControllerEventFilterItemPageResponseBase = "KubernetesControllerGetKubernetesClusterAdmissionControllerEventFilterItemPageResponseBase",
    KubernetesControllerGetKubernetesClusterAdmissionControllerEventFiltersRequest = "KubernetesControllerGetKubernetesClusterAdmissionControllerEventFiltersRequest",
    KubernetesControllerGetKubernetesClusterAdmissionControllerEventFiltersResponse = "KubernetesControllerGetKubernetesClusterAdmissionControllerEventFiltersResponse",
    KubernetesControllerGetKubernetesClusterAdmissionControllerEventModelPageRequest = "KubernetesControllerGetKubernetesClusterAdmissionControllerEventModelPageRequest",
    KubernetesControllerGetKubernetesClusterAdmissionControllerEventModelPageRequestFilters = "KubernetesControllerGetKubernetesClusterAdmissionControllerEventModelPageRequestFilters",
    KubernetesControllerGetKubernetesClusterAdmissionControllerEventModelPageResponse = "KubernetesControllerGetKubernetesClusterAdmissionControllerEventModelPageResponse",
    KubernetesControllerGetKubernetesClusterAdmissionControllerEventModelRequest = "KubernetesControllerGetKubernetesClusterAdmissionControllerEventModelRequest",
    KubernetesControllerGetKubernetesClusterAdmissionControllerEventModelResponse = "KubernetesControllerGetKubernetesClusterAdmissionControllerEventModelResponse",
    KubernetesControllerGetKubernetesClusterOnboardingInfoRequest = "KubernetesControllerGetKubernetesClusterOnboardingInfoRequest",
    KubernetesControllerGetKubernetesClusterOnboardingInfoResponse = "KubernetesControllerGetKubernetesClusterOnboardingInfoResponse",
    KubernetesControllerGetKubernetesClusterOnboardingInfoResponseContainerImageRegistryToken = "KubernetesControllerGetKubernetesClusterOnboardingInfoResponseContainerImageRegistryToken",
    KubernetesControllerGetSystemKubernetesClusterFilterItemPageRequest = "KubernetesControllerGetSystemKubernetesClusterFilterItemPageRequest",
    KubernetesControllerGetSystemKubernetesClusterFilterItemPageResponse = "KubernetesControllerGetSystemKubernetesClusterFilterItemPageResponse",
    KubernetesControllerGetSystemKubernetesClusterFilterItemPageResponseBase = "KubernetesControllerGetSystemKubernetesClusterFilterItemPageResponseBase",
    KubernetesControllerGetSystemKubernetesClusterFiltersRequest = "KubernetesControllerGetSystemKubernetesClusterFiltersRequest",
    KubernetesControllerGetSystemKubernetesClusterFiltersResponse = "KubernetesControllerGetSystemKubernetesClusterFiltersResponse",
    KubernetesControllerGetSystemKubernetesClusterModelPageRequest = "KubernetesControllerGetSystemKubernetesClusterModelPageRequest",
    KubernetesControllerGetSystemKubernetesClusterModelPageRequestFilters = "KubernetesControllerGetSystemKubernetesClusterModelPageRequestFilters",
    KubernetesControllerGetSystemKubernetesClusterModelPageRequestSort = "KubernetesControllerGetSystemKubernetesClusterModelPageRequestSort",
    KubernetesControllerGetSystemKubernetesClusterModelPageResponse = "KubernetesControllerGetSystemKubernetesClusterModelPageResponse",
    KubernetesControllerSystemKubernetesClusterModelProperty = "KubernetesControllerSystemKubernetesClusterModelProperty",
    KubernetesCronJobData = "KubernetesCronJobData",
    KubernetesDaemonSetData = "KubernetesDaemonSetData",
    KubernetesDeploymentData = "KubernetesDeploymentData",
    KubernetesEnvironmentVariable = "KubernetesEnvironmentVariable",
    KubernetesEnvironmentVariableContainerPropertyReference = "KubernetesEnvironmentVariableContainerPropertyReference",
    KubernetesEnvironmentVariableResourceReference = "KubernetesEnvironmentVariableResourceReference",
    KubernetesEnvironmentVariableValueReference = "KubernetesEnvironmentVariableValueReference",
    KubernetesGroupData = "KubernetesGroupData",
    KubernetesIngressClassData = "KubernetesIngressClassData",
    KubernetesIngressData = "KubernetesIngressData",
    KubernetesIngressDataRule = "KubernetesIngressDataRule",
    KubernetesIngressDataRulePath = "KubernetesIngressDataRulePath",
    KubernetesIngressDataRulePathType = "KubernetesIngressDataRulePathType",
    KubernetesIngressDataRuleResourcePath = "KubernetesIngressDataRuleResourcePath",
    KubernetesIngressDataRuleServicePath = "KubernetesIngressDataRuleServicePath",
    KubernetesJobData = "KubernetesJobData",
    KubernetesLabelSelector = "KubernetesLabelSelector",
    KubernetesLabelSelectorExpression = "KubernetesLabelSelectorExpression",
    KubernetesLabelSelectorOperator = "KubernetesLabelSelectorOperator",
    KubernetesNamespaceData = "KubernetesNamespaceData",
    KubernetesNamespaceDataPodSecurityStandard = "KubernetesNamespaceDataPodSecurityStandard",
    KubernetesNamespaceDefaultServiceAccountRoleBindingExistsRisk = "KubernetesNamespaceDefaultServiceAccountRoleBindingExistsRisk",
    KubernetesNamespaceDefaultServiceAccountRoleBindingExistsRiskModel = "KubernetesNamespaceDefaultServiceAccountRoleBindingExistsRiskModel",
    KubernetesNamespaceDefaultServiceAccountRoleBindingExistsRiskPolicyConfiguration = "KubernetesNamespaceDefaultServiceAccountRoleBindingExistsRiskPolicyConfiguration",
    KubernetesNamespaceResourceData = "KubernetesNamespaceResourceData",
    KubernetesNamespaceRestrictedPodSecurityStandardNotExistRisk = "KubernetesNamespaceRestrictedPodSecurityStandardNotExistRisk",
    KubernetesNamespaceRestrictedPodSecurityStandardNotExistRiskModel = "KubernetesNamespaceRestrictedPodSecurityStandardNotExistRiskModel",
    KubernetesNamespaceRestrictedPodSecurityStandardNotExistRiskPolicyConfiguration = "KubernetesNamespaceRestrictedPodSecurityStandardNotExistRiskPolicyConfiguration",
    KubernetesNamespaceRoleBindingData = "KubernetesNamespaceRoleBindingData",
    KubernetesNamespaceRoleData = "KubernetesNamespaceRoleData",
    KubernetesNamespaceWorkloadResourceContainerCapabilityRisk = "KubernetesNamespaceWorkloadResourceContainerCapabilityRisk",
    KubernetesNamespaceWorkloadResourceContainerCapabilityRiskModel = "KubernetesNamespaceWorkloadResourceContainerCapabilityRiskModel",
    KubernetesNamespaceWorkloadResourceContainerCapabilityRiskModelCapabilitiesType = "KubernetesNamespaceWorkloadResourceContainerCapabilityRiskModelCapabilitiesType",
    KubernetesNamespaceWorkloadResourceContainerCapabilityRiskPolicyConfiguration = "KubernetesNamespaceWorkloadResourceContainerCapabilityRiskPolicyConfiguration",
    KubernetesNamespaceWorkloadResourceContainerPrivilegedRisk = "KubernetesNamespaceWorkloadResourceContainerPrivilegedRisk",
    KubernetesNamespaceWorkloadResourceContainerPrivilegedRiskModel = "KubernetesNamespaceWorkloadResourceContainerPrivilegedRiskModel",
    KubernetesNamespaceWorkloadResourceContainerPrivilegedRiskPolicyConfiguration = "KubernetesNamespaceWorkloadResourceContainerPrivilegedRiskPolicyConfiguration",
    KubernetesNamespaceWorkloadResourceContainerPrivilegeEscalationRisk = "KubernetesNamespaceWorkloadResourceContainerPrivilegeEscalationRisk",
    KubernetesNamespaceWorkloadResourceContainerPrivilegeEscalationRiskModel = "KubernetesNamespaceWorkloadResourceContainerPrivilegeEscalationRiskModel",
    KubernetesNamespaceWorkloadResourceContainerPrivilegeEscalationRiskPolicyConfiguration = "KubernetesNamespaceWorkloadResourceContainerPrivilegeEscalationRiskPolicyConfiguration",
    KubernetesNamespaceWorkloadResourceContainerRootUserEnabledRisk = "KubernetesNamespaceWorkloadResourceContainerRootUserEnabledRisk",
    KubernetesNamespaceWorkloadResourceContainerRootUserEnabledRiskModel = "KubernetesNamespaceWorkloadResourceContainerRootUserEnabledRiskModel",
    KubernetesNamespaceWorkloadResourceContainerRootUserEnabledRiskPolicyConfiguration = "KubernetesNamespaceWorkloadResourceContainerRootUserEnabledRiskPolicyConfiguration",
    KubernetesNamespaceWorkloadResourceContainersRisk = "KubernetesNamespaceWorkloadResourceContainersRisk",
    KubernetesNamespaceWorkloadResourceContainersRiskModel = "KubernetesNamespaceWorkloadResourceContainersRiskModel",
    KubernetesNamespaceWorkloadResourceHostNamespaceInterProcessCommunicationEnabledRisk = "KubernetesNamespaceWorkloadResourceHostNamespaceInterProcessCommunicationEnabledRisk",
    KubernetesNamespaceWorkloadResourceHostNamespaceInterProcessCommunicationEnabledRiskModel = "KubernetesNamespaceWorkloadResourceHostNamespaceInterProcessCommunicationEnabledRiskModel",
    KubernetesNamespaceWorkloadResourceHostNamespaceInterProcessCommunicationEnabledRiskPolicyConfiguration = "KubernetesNamespaceWorkloadResourceHostNamespaceInterProcessCommunicationEnabledRiskPolicyConfiguration",
    KubernetesNamespaceWorkloadResourceHostNamespaceNetworkEnabledRisk = "KubernetesNamespaceWorkloadResourceHostNamespaceNetworkEnabledRisk",
    KubernetesNamespaceWorkloadResourceHostNamespaceNetworkEnabledRiskModel = "KubernetesNamespaceWorkloadResourceHostNamespaceNetworkEnabledRiskModel",
    KubernetesNamespaceWorkloadResourceHostNamespaceNetworkEnabledRiskPolicyConfiguration = "KubernetesNamespaceWorkloadResourceHostNamespaceNetworkEnabledRiskPolicyConfiguration",
    KubernetesNamespaceWorkloadResourceHostNamespaceProcessIdEnabledRisk = "KubernetesNamespaceWorkloadResourceHostNamespaceProcessIdEnabledRisk",
    KubernetesNamespaceWorkloadResourceHostNamespaceProcessIdEnabledRiskModel = "KubernetesNamespaceWorkloadResourceHostNamespaceProcessIdEnabledRiskModel",
    KubernetesNamespaceWorkloadResourceHostNamespaceProcessIdEnabledRiskPolicyConfiguration = "KubernetesNamespaceWorkloadResourceHostNamespaceProcessIdEnabledRiskPolicyConfiguration",
    KubernetesNamespaceWorkloadResourceRisk = "KubernetesNamespaceWorkloadResourceRisk",
    KubernetesNamespaceWorkloadResourceRiskModel = "KubernetesNamespaceWorkloadResourceRiskModel",
    KubernetesNetworkPolicyData = "KubernetesNetworkPolicyData",
    KubernetesNodeData = "KubernetesNodeData",
    KubernetesNodeFileDataType = "KubernetesNodeFileDataType",
    KubernetesNodeKubelet = "KubernetesNodeKubelet",
    KubernetesNodeKubeletAuthorizationMode = "KubernetesNodeKubeletAuthorizationMode",
    KubernetesPersistentVolumeData = "KubernetesPersistentVolumeData",
    KubernetesPodData = "KubernetesPodData",
    KubernetesPodSecurityPolicyData = "KubernetesPodSecurityPolicyData",
    KubernetesPodSpec = "KubernetesPodSpec",
    KubernetesPodSpecHostNamespaceData = "KubernetesPodSpecHostNamespaceData",
    KubernetesPodSpecSecurityContext = "KubernetesPodSpecSecurityContext",
    KubernetesPodTemplateData = "KubernetesPodTemplateData",
    KubernetesPodTemplateSpec = "KubernetesPodTemplateSpec",
    KubernetesPortProtocolType = "KubernetesPortProtocolType",
    KubernetesReplicaSetData = "KubernetesReplicaSetData",
    KubernetesResourceData = "KubernetesResourceData",
    KubernetesResourceReference = "KubernetesResourceReference",
    KubernetesServiceAccountData = "KubernetesServiceAccountData",
    KubernetesServiceData = "KubernetesServiceData",
    KubernetesServiceDataPort = "KubernetesServiceDataPort",
    KubernetesServiceDataType = "KubernetesServiceDataType",
    KubernetesStatefulSetData = "KubernetesStatefulSetData",
    KubernetesTaint = "KubernetesTaint",
    KubernetesTaintEffect = "KubernetesTaintEffect",
    KubernetesToleration = "KubernetesToleration",
    KubernetesTolerationOperator = "KubernetesTolerationOperator",
    KubernetesUserData = "KubernetesUserData",
    KubernetesWorkloadResourceContainerPrivilegedCodeRisk = "KubernetesWorkloadResourceContainerPrivilegedCodeRisk",
    KubernetesWorkloadResourceContainerPrivilegedCodeRiskModel = "KubernetesWorkloadResourceContainerPrivilegedCodeRiskModel",
    KubernetesWorkloadResourceContainerPrivilegedRiskPolicyConfiguration = "KubernetesWorkloadResourceContainerPrivilegedRiskPolicyConfiguration",
    KubernetesWorkloadResourceData = "KubernetesWorkloadResourceData",
    KubernetesWorkloadResourceEnvironmentVariableSecretReferenceRisk = "KubernetesWorkloadResourceEnvironmentVariableSecretReferenceRisk",
    KubernetesWorkloadResourceEnvironmentVariableSecretReferenceRiskModel = "KubernetesWorkloadResourceEnvironmentVariableSecretReferenceRiskModel",
    KubernetesWorkloadResourceEnvironmentVariableSecretReferenceRiskPolicyConfiguration = "KubernetesWorkloadResourceEnvironmentVariableSecretReferenceRiskPolicyConfiguration",
    LocalizationControllerSupportedLanguage = "LocalizationControllerSupportedLanguage",
    MailAddressSearchableData = "MailAddressSearchableData",
    MailDelivery = "MailDelivery",
    MailDeliveryMail = "MailDeliveryMail",
    MailProviderConfigurationSection = "MailProviderConfigurationSection",
    MailUpdateAuditEvent = "MailUpdateAuditEvent",
    MaliciousFilesReportDefinitionConfiguration = "MaliciousFilesReportDefinitionConfiguration",
    ManagedDatabaseAnalysisServiceConfiguration = "ManagedDatabaseAnalysisServiceConfiguration",
    ManualCustomEntityAttribute = "ManualCustomEntityAttribute",
    ManualCustomEntityAttributeDefinitionAuditEvent = "ManualCustomEntityAttributeDefinitionAuditEvent",
    ManualCustomEntityAttributeDefinitionConfiguration = "ManualCustomEntityAttributeDefinitionConfiguration",
    ManualCustomEntityAttributeDefinitionCreationAuditEvent = "ManualCustomEntityAttributeDefinitionCreationAuditEvent",
    ManualCustomEntityAttributeDefinitionDeletionAuditEvent = "ManualCustomEntityAttributeDefinitionDeletionAuditEvent",
    ManualCustomEntityAttributeDefinitionUpdateAuditEvent = "ManualCustomEntityAttributeDefinitionUpdateAuditEvent",
    MetaEntityTypeMetadata = "MetaEntityTypeMetadata",
    MetaObjectTypeMetadata = "MetaObjectTypeMetadata",
    MetaRiskTypeMetadata = "MetaRiskTypeMetadata",
    MfaDisabledUserAttribute = "MfaDisabledUserAttribute",
    MongoDbAtlasProjectConfigurationSection = "MongoDbAtlasProjectConfigurationSection",
    MongoDbConfigurationSection = "MongoDbConfigurationSection",
    NetworkAccessResourceStateNetwork = "NetworkAccessResourceStateNetwork",
    NetworkAccessScope = "NetworkAccessScope",
    NetworkGraph = "NetworkGraph",
    NetworkGraphDestinationGroup = "NetworkGraphDestinationGroup",
    NetworkGraphDestinationGroupScope = "NetworkGraphDestinationGroupScope",
    NetworkGraphGateway = "NetworkGraphGateway",
    NetworkGraphSecurityPerimeter = "NetworkGraphSecurityPerimeter",
    NetworkGraphSecurityPerimeterType = "NetworkGraphSecurityPerimeterType",
    NetworkGraphSourceGroup = "NetworkGraphSourceGroup",
    NetworkGraphSpecialSource = "NetworkGraphSpecialSource",
    NetworkInboundAccessType = "NetworkInboundAccessType",
    NetworkInboundExternalResourceRiskPolicyGroupFilters = "NetworkInboundExternalResourceRiskPolicyGroupFilters",
    NetworkScope = "NetworkScope",
    NvdState = "NvdState",
    ObjectDataSegmentMetadata = "ObjectDataSegmentMetadata",
    ObjectStoreAnalysisServiceConfiguration = "ObjectStoreAnalysisServiceConfiguration",
    ObjectTypeCategory = "ObjectTypeCategory",
    ObjectTypeMetadata = "ObjectTypeMetadata",
    ObjectTypeMetadataModel = "ObjectTypeMetadataModel",
    ObservabilityApiServiceConfiguration = "ObservabilityApiServiceConfiguration",
    OciAccessPolicyGenericResource = "OciAccessPolicyGenericResource",
    OciAdmKnowledgeBaseGenericResource = "OciAdmKnowledgeBaseGenericResource",
    OciAdmRemediationRecipeGenericResource = "OciAdmRemediationRecipeGenericResource",
    OciAdmRemediationRunGenericResource = "OciAdmRemediationRunGenericResource",
    OciAdmVulnerabilityAuditGenericResource = "OciAdmVulnerabilityAuditGenericResource",
    OciAiAnomalyDetectionDataAssetGenericResource = "OciAiAnomalyDetectionDataAssetGenericResource",
    OciAiAnomalyDetectionModelGenericResource = "OciAiAnomalyDetectionModelGenericResource",
    OciAiAnomalyDetectionProjectGenericResource = "OciAiAnomalyDetectionProjectGenericResource",
    OciAiAnomalyDetectionPvtEndpointGenericResource = "OciAiAnomalyDetectionPvtEndpointGenericResource",
    OciAiDocumentModelGenericResource = "OciAiDocumentModelGenericResource",
    OciAiDocumentProjectGenericResource = "OciAiDocumentProjectGenericResource",
    OciAiForecastDataAssetGenericResource = "OciAiForecastDataAssetGenericResource",
    OciAiForecastForecastGenericResource = "OciAiForecastForecastGenericResource",
    OciAiForecastProjectGenericResource = "OciAiForecastProjectGenericResource",
    OciAiLanguageEndpointGenericResource = "OciAiLanguageEndpointGenericResource",
    OciAiLanguageModelGenericResource = "OciAiLanguageModelGenericResource",
    OciAiLanguageProjectGenericResource = "OciAiLanguageProjectGenericResource",
    OciAiModelDeploymentGenericResource = "OciAiModelDeploymentGenericResource",
    OciAiModelGenericResource = "OciAiModelGenericResource",
    OciAiProjectGenericResource = "OciAiProjectGenericResource",
    OciAiVisionModelGenericResource = "OciAiVisionModelGenericResource",
    OciAiVisionProjectGenericResource = "OciAiVisionProjectGenericResource",
    OciAlarmGenericResource = "OciAlarmGenericResource",
    OciAnalyticsInstanceGenericResource = "OciAnalyticsInstanceGenericResource",
    OciApiDeploymentGenericResource = "OciApiDeploymentGenericResource",
    OciApiGatewayApiGenericResource = "OciApiGatewayApiGenericResource",
    OciApiGatewayCertificateGenericResource = "OciApiGatewayCertificateGenericResource",
    OciApiGatewayGenericResource = "OciApiGatewayGenericResource",
    OciApiGatewaySdkGenericResource = "OciApiGatewaySdkGenericResource",
    OciApiGatewaySubscriberGenericResource = "OciApiGatewaySubscriberGenericResource",
    OciApiGatewayUsagePlanGenericResource = "OciApiGatewayUsagePlanGenericResource",
    OciApmDomainGenericResource = "OciApmDomainGenericResource",
    OciAppGenericResource = "OciAppGenericResource",
    OciApplicationAuthenticationProviderConfigurationSection = "OciApplicationAuthenticationProviderConfigurationSection",
    OciApplicationAuthenticationProviderConfigurationSectionPartitionData = "OciApplicationAuthenticationProviderConfigurationSectionPartitionData",
    OciApplicationAuthenticationProviderConfigurationSectionPartitionDataUser = "OciApplicationAuthenticationProviderConfigurationSectionPartitionDataUser",
    OciApplicationGenericResource = "OciApplicationGenericResource",
    OciApplicationVipGenericResource = "OciApplicationVipGenericResource",
    OciArcsSaasEnvironmentGenericResource = "OciArcsSaasEnvironmentGenericResource",
    OciAtatGenericResource = "OciAtatGenericResource",
    OciAutonomousContainerDatabaseGenericResource = "OciAutonomousContainerDatabaseGenericResource",
    OciAutonomousDatabaseBackupGenericResource = "OciAutonomousDatabaseBackupGenericResource",
    OciAutonomousDatabaseGenericResource = "OciAutonomousDatabaseGenericResource",
    OciAutonomousDatabaseSoftwareImageGenericResource = "OciAutonomousDatabaseSoftwareImageGenericResource",
    OciAutonomousDataSecurityInstanceGenericResource = "OciAutonomousDataSecurityInstanceGenericResource",
    OciAutonomousExadataInfrastructureGenericResource = "OciAutonomousExadataInfrastructureGenericResource",
    OciAutonomousVirtualMachineGenericResource = "OciAutonomousVirtualMachineGenericResource",
    OciAutonomousVmClusterGenericResource = "OciAutonomousVmClusterGenericResource",
    OciAutoscalingAutoscalingConfiguration = "OciAutoscalingAutoscalingConfiguration",
    OciAutoscalingAutoscalingConfigurationModel = "OciAutoscalingAutoscalingConfigurationModel",
    OciAutoscalingAutoscalingConfigurationProfile = "OciAutoscalingAutoscalingConfigurationProfile",
    OciAutoScalingConfigurationGenericResource = "OciAutoScalingConfigurationGenericResource",
    OciBackupDestinationGenericResource = "OciBackupDestinationGenericResource",
    OciBastionGenericResource = "OciBastionGenericResource",
    OciBigDataServiceApiKeyGenericResource = "OciBigDataServiceApiKeyGenericResource",
    OciBigDataServiceAutoscaleGenericResource = "OciBigDataServiceAutoscaleGenericResource",
    OciBigDataServiceGenericResource = "OciBigDataServiceGenericResource",
    OciBigDataServiceLakeConfigGenericResource = "OciBigDataServiceLakeConfigGenericResource",
    OciBigDataServiceMetastoreConfigGenericResource = "OciBigDataServiceMetastoreConfigGenericResource",
    OciBlockchainPlatformGenericResource = "OciBlockchainPlatformGenericResource",
    OciBlockStorageBlockVolume = "OciBlockStorageBlockVolume",
    OciBlockStorageBlockVolumeDefaultEncryptionRisk = "OciBlockStorageBlockVolumeDefaultEncryptionRisk",
    OciBlockStorageBlockVolumeDefaultEncryptionRiskModel = "OciBlockStorageBlockVolumeDefaultEncryptionRiskModel",
    OciBlockStorageBlockVolumeDefaultEncryptionRiskPolicyConfiguration = "OciBlockStorageBlockVolumeDefaultEncryptionRiskPolicyConfiguration",
    OciBlockStorageBlockVolumeModel = "OciBlockStorageBlockVolumeModel",
    OciBlockStorageBlockVolumeProfile = "OciBlockStorageBlockVolumeProfile",
    OciBlockStorageBlockVolumeSourceResource = "OciBlockStorageBlockVolumeSourceResource",
    OciBlockStorageBlockVolumeSourceResourceType = "OciBlockStorageBlockVolumeSourceResourceType",
    OciBlockStorageBlockVolumeStatus = "OciBlockStorageBlockVolumeStatus",
    OciBlockStorageBootVolume = "OciBlockStorageBootVolume",
    OciBlockStorageBootVolumeDefaultEncryptionCodeRisk = "OciBlockStorageBootVolumeDefaultEncryptionCodeRisk",
    OciBlockStorageBootVolumeDefaultEncryptionCodeRiskModel = "OciBlockStorageBootVolumeDefaultEncryptionCodeRiskModel",
    OciBlockStorageBootVolumeDefaultEncryptionRisk = "OciBlockStorageBootVolumeDefaultEncryptionRisk",
    OciBlockStorageBootVolumeDefaultEncryptionRiskModel = "OciBlockStorageBootVolumeDefaultEncryptionRiskModel",
    OciBlockStorageBootVolumeDefaultEncryptionRiskPolicyConfiguration = "OciBlockStorageBootVolumeDefaultEncryptionRiskPolicyConfiguration",
    OciBlockStorageBootVolumeModel = "OciBlockStorageBootVolumeModel",
    OciBlockStorageBootVolumeProfile = "OciBlockStorageBootVolumeProfile",
    OciBlockStorageBootVolumeSourceResource = "OciBlockStorageBootVolumeSourceResource",
    OciBlockStorageBootVolumeSourceResourceType = "OciBlockStorageBootVolumeSourceResourceType",
    OciBlockStorageBootVolumeStatus = "OciBlockStorageBootVolumeStatus",
    OciBootVolumeBackupGenericResource = "OciBootVolumeBackupGenericResource",
    OciBootVolumeGenericResource = "OciBootVolumeGenericResource",
    OciBootVolumeReplicaGenericResource = "OciBootVolumeReplicaGenericResource",
    OciBucketGenericResource = "OciBucketGenericResource",
    OciBudgetGenericResource = "OciBudgetGenericResource",
    OciByoipRangeGenericResource = "OciByoipRangeGenericResource",
    OciCaBundleAssociationGenericResource = "OciCaBundleAssociationGenericResource",
    OciCaBundleGenericResource = "OciCaBundleGenericResource",
    OciCccInfrastructureGenericResource = "OciCccInfrastructureGenericResource",
    OciCccUpgradeScheduleGenericResource = "OciCccUpgradeScheduleGenericResource",
    OciCertificateAssociationGenericResource = "OciCertificateAssociationGenericResource",
    OciCertificateAuthorityAssociationGenericResource = "OciCertificateAuthorityAssociationGenericResource",
    OciCertificateAuthorityGenericResource = "OciCertificateAuthorityGenericResource",
    OciCertificateGenericResource = "OciCertificateGenericResource",
    OciCimDemoSaasEnvironmentGenericResource = "OciCimDemoSaasEnvironmentGenericResource",
    OciCloudAutonomousVmClusterGenericResource = "OciCloudAutonomousVmClusterGenericResource",
    OciCloudExadataInfrastructureGenericResource = "OciCloudExadataInfrastructureGenericResource",
    OciCloudGuardAdhocQueryGenericResource = "OciCloudGuardAdhocQueryGenericResource",
    OciCloudGuardDataSourceGenericResource = "OciCloudGuardDataSourceGenericResource",
    OciCloudGuardDetectorRecipeGenericResource = "OciCloudGuardDetectorRecipeGenericResource",
    OciCloudGuardManagedListGenericResource = "OciCloudGuardManagedListGenericResource",
    OciCloudGuardResponderRecipeGenericResource = "OciCloudGuardResponderRecipeGenericResource",
    OciCloudGuardSavedQueryGenericResource = "OciCloudGuardSavedQueryGenericResource",
    OciCloudGuardTargetGenericResource = "OciCloudGuardTargetGenericResource",
    OciCloudVmClusterGenericResource = "OciCloudVmClusterGenericResource",
    OciClusterNetworkGenericResource = "OciClusterNetworkGenericResource",
    OciClusterPlacementGroupDevGenericResource = "OciClusterPlacementGroupDevGenericResource",
    OciClusterPlacementGroupGenericResource = "OciClusterPlacementGroupGenericResource",
    OciClustersClusterGenericResource = "OciClustersClusterGenericResource",
    OciCommerceCloudSaasEnvironmentGenericResource = "OciCommerceCloudSaasEnvironmentGenericResource",
    OciCompartmentGenericResource = "OciCompartmentGenericResource",
    OciComputeCapacityReservationGenericResource = "OciComputeCapacityReservationGenericResource",
    OciComputeImage = "OciComputeImage",
    OciComputeImageModel = "OciComputeImageModel",
    OciComputeImageProfile = "OciComputeImageProfile",
    OciComputeImageStatus = "OciComputeImageStatus",
    OciComputeInstance = "OciComputeInstance",
    OciComputeInstanceBootSource = "OciComputeInstanceBootSource",
    OciComputeInstanceBootSourceType = "OciComputeInstanceBootSourceType",
    OciComputeInstanceConfiguration = "OciComputeInstanceConfiguration",
    OciComputeInstanceConfigurationBootSource = "OciComputeInstanceConfigurationBootSource",
    OciComputeInstanceConfigurationBootSourceType = "OciComputeInstanceConfigurationBootSourceType",
    OciComputeInstanceConfigurationLaunchMode = "OciComputeInstanceConfigurationLaunchMode",
    OciComputeInstanceConfigurationModel = "OciComputeInstanceConfigurationModel",
    OciComputeInstanceConfigurationProfile = "OciComputeInstanceConfigurationProfile",
    OciComputeInstanceConfigurationSecurity = "OciComputeInstanceConfigurationSecurity",
    OciComputeInstanceConfigurationVirtualNetworkInterfaceCardParameters = "OciComputeInstanceConfigurationVirtualNetworkInterfaceCardParameters",
    OciComputeInstanceLaunchMode = "OciComputeInstanceLaunchMode",
    OciComputeInstanceMetadataServiceVersionRisk = "OciComputeInstanceMetadataServiceVersionRisk",
    OciComputeInstanceMetadataServiceVersionRiskModel = "OciComputeInstanceMetadataServiceVersionRiskModel",
    OciComputeInstanceMetadataServiceVersionRiskPolicyConfiguration = "OciComputeInstanceMetadataServiceVersionRiskPolicyConfiguration",
    OciComputeInstanceModel = "OciComputeInstanceModel",
    OciComputeInstancePool = "OciComputeInstancePool",
    OciComputeInstancePoolModel = "OciComputeInstancePoolModel",
    OciComputeInstancePoolPlacementConfiguration = "OciComputeInstancePoolPlacementConfiguration",
    OciComputeInstancePoolProfile = "OciComputeInstancePoolProfile",
    OciComputeInstancePoolStatus = "OciComputeInstancePoolStatus",
    OciComputeInstanceProfile = "OciComputeInstanceProfile",
    OciComputeInstanceSecureBootNotEnabledRisk = "OciComputeInstanceSecureBootNotEnabledRisk",
    OciComputeInstanceSecureBootNotEnabledRiskModel = "OciComputeInstanceSecureBootNotEnabledRiskModel",
    OciComputeInstanceSecureBootNotEnabledRiskPolicyConfiguration = "OciComputeInstanceSecureBootNotEnabledRiskPolicyConfiguration",
    OciComputeInstanceSecurity = "OciComputeInstanceSecurity",
    OciComputeInstanceStatus = "OciComputeInstanceStatus",
    OciComputeInstanceVolumeEncryptionInTransitDisabledRisk = "OciComputeInstanceVolumeEncryptionInTransitDisabledRisk",
    OciComputeInstanceVolumeEncryptionInTransitDisabledRiskModel = "OciComputeInstanceVolumeEncryptionInTransitDisabledRiskModel",
    OciComputeInstanceVolumeEncryptionInTransitDisabledRiskPolicyConfiguration = "OciComputeInstanceVolumeEncryptionInTransitDisabledRiskPolicyConfiguration",
    OciConnectHarnesGenericResource = "OciConnectHarnesGenericResource",
    OciConsoleConnectionGenericResource = "OciConsoleConnectionGenericResource",
    OciConsoleDashboardGenericResource = "OciConsoleDashboardGenericResource",
    OciConsoleDashboardGroupGenericResource = "OciConsoleDashboardGroupGenericResource",
    OciConsoleHistoryGenericResource = "OciConsoleHistoryGenericResource",
    OciConsoleResourceCollectionGenericResource = "OciConsoleResourceCollectionGenericResource",
    OciConsoleView = "OciConsoleView",
    OciContainerEngineCluster = "OciContainerEngineCluster",
    OciContainerEngineClusterModel = "OciContainerEngineClusterModel",
    OciContainerEngineClusterNodePool = "OciContainerEngineClusterNodePool",
    OciContainerEngineClusterNodePoolModel = "OciContainerEngineClusterNodePoolModel",
    OciContainerEngineClusterNodePoolNode = "OciContainerEngineClusterNodePoolNode",
    OciContainerEngineClusterNodePoolProfile = "OciContainerEngineClusterNodePoolProfile",
    OciContainerEngineClusterNodePoolStatus = "OciContainerEngineClusterNodePoolStatus",
    OciContainerEngineClusterProfile = "OciContainerEngineClusterProfile",
    OciContainerEngineClusterStatus = "OciContainerEngineClusterStatus",
    OciContainerEngineClusterType = "OciContainerEngineClusterType",
    OciContainerGenericResource = "OciContainerGenericResource",
    OciContainerImageGenericResource = "OciContainerImageGenericResource",
    OciContainerInstanceGenericResource = "OciContainerInstanceGenericResource",
    OciContainerRepoGenericResource = "OciContainerRepoGenericResource",
    OciContinuousQueryGenericResource = "OciContinuousQueryGenericResource",
    OciCpeGenericResource = "OciCpeGenericResource",
    OciCpqSaasEnvironmentGenericResource = "OciCpqSaasEnvironmentGenericResource",
    OciCrossConnectGenericResource = "OciCrossConnectGenericResource",
    OciCrossConnectGroupGenericResource = "OciCrossConnectGroupGenericResource",
    OciCustomerDnsZoneGenericResource = "OciCustomerDnsZoneGenericResource",
    OciCxOpaSaasEnvironmentGenericResource = "OciCxOpaSaasEnvironmentGenericResource",
    OciCxUnitySaasEnvironmentGenericResource = "OciCxUnitySaasEnvironmentGenericResource",
    OciDatabaseGenericResource = "OciDatabaseGenericResource",
    OciDatabaseSoftwareImageGenericResource = "OciDatabaseSoftwareImageGenericResource",
    OciDatabaseToolsConnectionGenericResource = "OciDatabaseToolsConnectionGenericResource",
    OciDatabaseToolsPrivateEndpointGenericResource = "OciDatabaseToolsPrivateEndpointGenericResource",
    OciDataCatalogGenericResource = "OciDataCatalogGenericResource",
    OciDataCatalogMetastoreGenericResource = "OciDataCatalogMetastoreGenericResource",
    OciDataCatalogPrivateEndpointGenericResource = "OciDataCatalogPrivateEndpointGenericResource",
    OciDataFlowApplicationGenericResource = "OciDataFlowApplicationGenericResource",
    OciDataFlowClusterGenericResource = "OciDataFlowClusterGenericResource",
    OciDataFlowPoolGenericResource = "OciDataFlowPoolGenericResource",
    OciDataFlowRunGenericResource = "OciDataFlowRunGenericResource",
    OciDataFlowSqlEndpointGenericResource = "OciDataFlowSqlEndpointGenericResource",
    OciDataLabelingDatasetGenericResource = "OciDataLabelingDatasetGenericResource",
    OciDataSaasEnvironmentGenericResource = "OciDataSaasEnvironmentGenericResource",
    OciDataSafeAlertPolicyGenericResource = "OciDataSafeAlertPolicyGenericResource",
    OciDataSafeArchiveRetrievalGenericResource = "OciDataSafeArchiveRetrievalGenericResource",
    OciDataSafeAuditPolicyGenericResource = "OciDataSafeAuditPolicyGenericResource",
    OciDataSafeAuditProfileGenericResource = "OciDataSafeAuditProfileGenericResource",
    OciDataSafeAuditTrailGenericResource = "OciDataSafeAuditTrailGenericResource",
    OciDataSafeDatabaseSecurityConfigGenericResource = "OciDataSafeDatabaseSecurityConfigGenericResource",
    OciDataSafeDiscoveryJobGenericResource = "OciDataSafeDiscoveryJobGenericResource",
    OciDataSafeLibraryMaskingFormatGenericResource = "OciDataSafeLibraryMaskingFormatGenericResource",
    OciDataSafeMaskingPolicyGenericResource = "OciDataSafeMaskingPolicyGenericResource",
    OciDataSafeMaskingReportGenericResource = "OciDataSafeMaskingReportGenericResource",
    OciDataSafeMaskPolicyHealthReportGenericResource = "OciDataSafeMaskPolicyHealthReportGenericResource",
    OciDataSafeOnpremConnectorGenericResource = "OciDataSafeOnpremConnectorGenericResource",
    OciDataSafePrivateEndpointGenericResource = "OciDataSafePrivateEndpointGenericResource",
    OciDataSafeReportDefinitionGenericResource = "OciDataSafeReportDefinitionGenericResource",
    OciDataSafeReportGenericResource = "OciDataSafeReportGenericResource",
    OciDataSafeSdmMaskingPolicyDifferenceGenericResource = "OciDataSafeSdmMaskingPolicyDifferenceGenericResource",
    OciDataSafeSecurityAssessmentGenericResource = "OciDataSafeSecurityAssessmentGenericResource",
    OciDataSafeSecurityPolicyDeploymentGenericResource = "OciDataSafeSecurityPolicyDeploymentGenericResource",
    OciDataSafeSecurityPolicyGenericResource = "OciDataSafeSecurityPolicyGenericResource",
    OciDataSafeSensitiveDataModelGenericResource = "OciDataSafeSensitiveDataModelGenericResource",
    OciDataSafeSensitiveTypeGenericResource = "OciDataSafeSensitiveTypeGenericResource",
    OciDataSafeSensitiveTypesExportGenericResource = "OciDataSafeSensitiveTypesExportGenericResource",
    OciDataSafeSqlCollectionGenericResource = "OciDataSafeSqlCollectionGenericResource",
    OciDataSafeSqlFirewallAllowedSqlGenericResource = "OciDataSafeSqlFirewallAllowedSqlGenericResource",
    OciDataSafeSqlFirewallPolicyGenericResource = "OciDataSafeSqlFirewallPolicyGenericResource",
    OciDataSafeSqlFirewallViolationGenericResource = "OciDataSafeSqlFirewallViolationGenericResource",
    OciDataSafeTargetAlertPolicyAssociationGenericResource = "OciDataSafeTargetAlertPolicyAssociationGenericResource",
    OciDataSafeTargetDatabaseGenericResource = "OciDataSafeTargetDatabaseGenericResource",
    OciDataSafeUserAssessmentGenericResource = "OciDataSafeUserAssessmentGenericResource",
    OciDataScienceJobGenericResource = "OciDataScienceJobGenericResource",
    OciDataScienceJobRunGenericResource = "OciDataScienceJobRunGenericResource",
    OciDataScienceModelDeploymentGenericResource = "OciDataScienceModelDeploymentGenericResource",
    OciDataScienceModelGenericResource = "OciDataScienceModelGenericResource",
    OciDataScienceModelVersionSetGenericResource = "OciDataScienceModelVersionSetGenericResource",
    OciDataScienceNotebookSessionGenericResource = "OciDataScienceNotebookSessionGenericResource",
    OciDataSciencePipelineGenericResource = "OciDataSciencePipelineGenericResource",
    OciDataSciencePipelineRunGenericResource = "OciDataSciencePipelineRunGenericResource",
    OciDataSciencePrivateEndpointGenericResource = "OciDataSciencePrivateEndpointGenericResource",
    OciDataScienceProjectGenericResource = "OciDataScienceProjectGenericResource",
    OciDataScienceScheduleGenericResource = "OciDataScienceScheduleGenericResource",
    OciDataTransferApplianceExportJobGenericResource = "OciDataTransferApplianceExportJobGenericResource",
    OciDataTransferApplianceGenericResource = "OciDataTransferApplianceGenericResource",
    OciDataTransferDeviceGenericResource = "OciDataTransferDeviceGenericResource",
    OciDataTransferJobGenericResource = "OciDataTransferJobGenericResource",
    OciDataTransferPackageGenericResource = "OciDataTransferPackageGenericResource",
    OciDbCloudSchedulingPolicyGenericResource = "OciDbCloudSchedulingPolicyGenericResource",
    OciDbCloudSchedulingWindowGenericResource = "OciDbCloudSchedulingWindowGenericResource",
    OciDbHomeGenericResource = "OciDbHomeGenericResource",
    OciDbKeyStoreGenericResource = "OciDbKeyStoreGenericResource",
    OciDbmgmtExternalAsmGenericResource = "OciDbmgmtExternalAsmGenericResource",
    OciDbmgmtExternalAsmInstanceGenericResource = "OciDbmgmtExternalAsmInstanceGenericResource",
    OciDbmgmtExternalClusterGenericResource = "OciDbmgmtExternalClusterGenericResource",
    OciDbmgmtExternalClusterInstanceGenericResource = "OciDbmgmtExternalClusterInstanceGenericResource",
    OciDbmgmtExternalDbHomeGenericResource = "OciDbmgmtExternalDbHomeGenericResource",
    OciDbmgmtExternalDbNodeGenericResource = "OciDbmgmtExternalDbNodeGenericResource",
    OciDbmgmtExternalDbSystemConnectorGenericResource = "OciDbmgmtExternalDbSystemConnectorGenericResource",
    OciDbmgmtExternalDbSystemGenericResource = "OciDbmgmtExternalDbSystemGenericResource",
    OciDbmgmtExternalExadataInfrastructureGenericResource = "OciDbmgmtExternalExadataInfrastructureGenericResource",
    OciDbmgmtExternalExadataStorageConnectorGenericResource = "OciDbmgmtExternalExadataStorageConnectorGenericResource",
    OciDbmgmtExternalExadataStorageGridGenericResource = "OciDbmgmtExternalExadataStorageGridGenericResource",
    OciDbmgmtExternalExadataStorageServerGenericResource = "OciDbmgmtExternalExadataStorageServerGenericResource",
    OciDbmgmtExternalListenerGenericResource = "OciDbmgmtExternalListenerGenericResource",
    OciDbmgmtJobGenericResource = "OciDbmgmtJobGenericResource",
    OciDbmgmtManagedDatabaseGenericResource = "OciDbmgmtManagedDatabaseGenericResource",
    OciDbmgmtManagedDatabaseGroupGenericResource = "OciDbmgmtManagedDatabaseGroupGenericResource",
    OciDbmgmtNamedCredentialGenericResource = "OciDbmgmtNamedCredentialGenericResource",
    OciDbmgmtPrivateEndpointGenericResource = "OciDbmgmtPrivateEndpointGenericResource",
    OciDbNfsStorageGenericResource = "OciDbNfsStorageGenericResource",
    OciDbNodeConsoleConnectionGenericResource = "OciDbNodeConsoleConnectionGenericResource",
    OciDbNodeConsoleHistoryGenericResource = "OciDbNodeConsoleHistoryGenericResource",
    OciDbNodeGenericResource = "OciDbNodeGenericResource",
    OciDbServerGenericResource = "OciDbServerGenericResource",
    OciDbSystemGenericResource = "OciDbSystemGenericResource",
    OciDcmsEndpointGenericResource = "OciDcmsEndpointGenericResource",
    OciDcmsRegistryGenericResource = "OciDcmsRegistryGenericResource",
    OciDedicatedVmHostGenericResource = "OciDedicatedVmHostGenericResource",
    OciDelegationManagementDelegatedResourceAccessRequestGenericResource = "OciDelegationManagementDelegatedResourceAccessRequestGenericResource",
    OciDelegationManagementDelegationControlGenericResource = "OciDelegationManagementDelegationControlGenericResource",
    OciDelegationManagementDelegationSubscriptionGenericResource = "OciDelegationManagementDelegationSubscriptionGenericResource",
    OciDesktopPoolGenericResource = "OciDesktopPoolGenericResource",
    OciDevOpsBuildPipelineGenericResource = "OciDevOpsBuildPipelineGenericResource",
    OciDevOpsBuildPipelineStageGenericResource = "OciDevOpsBuildPipelineStageGenericResource",
    OciDevOpsBuildRunGenericResource = "OciDevOpsBuildRunGenericResource",
    OciDevOpsConnectionGenericResource = "OciDevOpsConnectionGenericResource",
    OciDevOpsDeployArtifactGenericResource = "OciDevOpsDeployArtifactGenericResource",
    OciDevOpsDeployEnvironmentGenericResource = "OciDevOpsDeployEnvironmentGenericResource",
    OciDevOpsDeploymentGenericResource = "OciDevOpsDeploymentGenericResource",
    OciDevOpsDeployPipelineGenericResource = "OciDevOpsDeployPipelineGenericResource",
    OciDevOpsDeployStageGenericResource = "OciDevOpsDeployStageGenericResource",
    OciDevOpsProjectGenericResource = "OciDevOpsProjectGenericResource",
    OciDevOpsRepositoryGenericResource = "OciDevOpsRepositoryGenericResource",
    OciDevOpsTriggerGenericResource = "OciDevOpsTriggerGenericResource",
    OciDhcpOptionGenericResource = "OciDhcpOptionGenericResource",
    OciDisWorkspaceGenericResource = "OciDisWorkspaceGenericResource",
    OciDnsPolicyAttachmentGenericResource = "OciDnsPolicyAttachmentGenericResource",
    OciDnsPolicyGenericResource = "OciDnsPolicyGenericResource",
    OciDnsResolverGenericResource = "OciDnsResolverGenericResource",
    OciDnsTsigKeyGenericResource = "OciDnsTsigKeyGenericResource",
    OciDnsViewGenericResource = "OciDnsViewGenericResource",
    OciDrgAttachmentGenericResource = "OciDrgAttachmentGenericResource",
    OciDrgGenericResource = "OciDrgGenericResource",
    OciDrgRouteDistributionGenericResource = "OciDrgRouteDistributionGenericResource",
    OciDrgRouteTableGenericResource = "OciDrgRouteTableGenericResource",
    OciDrPlanExecutionGenericResource = "OciDrPlanExecutionGenericResource",
    OciDrPlanGenericResource = "OciDrPlanGenericResource",
    OciDrProtectionGroupGenericResource = "OciDrProtectionGroupGenericResource",
    OciDynamicResourceGroupGenericResource = "OciDynamicResourceGroupGenericResource",
    OciEdmcsSaasEnvironmentGenericResource = "OciEdmcsSaasEnvironmentGenericResource",
    OciEkmsPrivateEndpointGenericResource = "OciEkmsPrivateEndpointGenericResource",
    OciEmailDkimGenericResource = "OciEmailDkimGenericResource",
    OciEmailDomainGenericResource = "OciEmailDomainGenericResource",
    OciEmailReturnPathGenericResource = "OciEmailReturnPathGenericResource",
    OciEmailSenderGenericResource = "OciEmailSenderGenericResource",
    OciEntity = "OciEntity",
    OciEntityModel = "OciEntityModel",
    OciEprcsSaasEnvironmentGenericResource = "OciEprcsSaasEnvironmentGenericResource",
    OciEventRuleGenericResource = "OciEventRuleGenericResource",
    OciExadataInfrastructureGenericResource = "OciExadataInfrastructureGenericResource",
    OciExadbVmClusterGenericResource = "OciExadbVmClusterGenericResource",
    OciExascaleDbStorageVaultGenericResource = "OciExascaleDbStorageVaultGenericResource",
    OciExportGenericResource = "OciExportGenericResource",
    OciExternalContainerDatabaseGenericResource = "OciExternalContainerDatabaseGenericResource",
    OciExternalDatabaseConnectorGenericResource = "OciExternalDatabaseConnectorGenericResource",
    OciExternalNonContainerDatabaseGenericResource = "OciExternalNonContainerDatabaseGenericResource",
    OciExternalPluggableDatabaseGenericResource = "OciExternalPluggableDatabaseGenericResource",
    OciFamscompliancepolicyGenericResource = "OciFamscompliancepolicyGenericResource",
    OciFamsfleetGenericResource = "OciFamsfleetGenericResource",
    OciFamsmaintenancewindowGenericResource = "OciFamsmaintenancewindowGenericResource",
    OciFamspatchGenericResource = "OciFamspatchGenericResource",
    OciFamsplatformconfigurationGenericResource = "OciFamsplatformconfigurationGenericResource",
    OciFamsrunbookGenericResource = "OciFamsrunbookGenericResource",
    OciFamsschedulerdefinitionGenericResource = "OciFamsschedulerdefinitionGenericResource",
    OciFawServiceGenericResource = "OciFawServiceGenericResource",
    OciFileSystemGenericResource = "OciFileSystemGenericResource",
    OciFilesystemSnapshotPolicyGenericResource = "OciFilesystemSnapshotPolicyGenericResource",
    OciFolderConfiguration = "OciFolderConfiguration",
    OciFsgbuascsSaasEnvironmentGenericResource = "OciFsgbuascsSaasEnvironmentGenericResource",
    OciFsgbuccaSaasEnvironmentGenericResource = "OciFsgbuccaSaasEnvironmentGenericResource",
    OciFsgbuerfSaasEnvironmentGenericResource = "OciFsgbuerfSaasEnvironmentGenericResource",
    OciFsGbuFccmamlcsSaasEnvironmentGenericResource = "OciFsGbuFccmamlcsSaasEnvironmentGenericResource",
    OciFsgbuinsSaasEnvironmentGenericResource = "OciFsgbuinsSaasEnvironmentGenericResource",
    OciFsGbuObcsSaasEnvironmentGenericResource = "OciFsGbuObcsSaasEnvironmentGenericResource",
    OciFsgbupbsmSaasEnvironmentGenericResource = "OciFsgbupbsmSaasEnvironmentGenericResource",
    OciFssFileSystem = "OciFssFileSystem",
    OciFssFileSystemDefaultEncryptionCodeRisk = "OciFssFileSystemDefaultEncryptionCodeRisk",
    OciFssFileSystemDefaultEncryptionCodeRiskModel = "OciFssFileSystemDefaultEncryptionCodeRiskModel",
    OciFssFileSystemDefaultEncryptionRisk = "OciFssFileSystemDefaultEncryptionRisk",
    OciFssFileSystemDefaultEncryptionRiskModel = "OciFssFileSystemDefaultEncryptionRiskModel",
    OciFssFileSystemDefaultEncryptionRiskPolicyConfiguration = "OciFssFileSystemDefaultEncryptionRiskPolicyConfiguration",
    OciFssFileSystemModel = "OciFssFileSystemModel",
    OciFssFileSystemMountTargetExportOptionIdentitySquashRootNotEnabledCodeRisk = "OciFssFileSystemMountTargetExportOptionIdentitySquashRootNotEnabledCodeRisk",
    OciFssFileSystemMountTargetExportOptionIdentitySquashRootNotEnabledCodeRiskModel = "OciFssFileSystemMountTargetExportOptionIdentitySquashRootNotEnabledCodeRiskModel",
    OciFssFileSystemMountTargetExportOptionIdentitySquashRootNotEnabledRisk = "OciFssFileSystemMountTargetExportOptionIdentitySquashRootNotEnabledRisk",
    OciFssFileSystemMountTargetExportOptionIdentitySquashRootNotEnabledRiskModel = "OciFssFileSystemMountTargetExportOptionIdentitySquashRootNotEnabledRiskModel",
    OciFssFileSystemMountTargetExportOptionIdentitySquashRootNotEnabledRiskPolicyConfiguration = "OciFssFileSystemMountTargetExportOptionIdentitySquashRootNotEnabledRiskPolicyConfiguration",
    OciFssFileSystemProfile = "OciFssFileSystemProfile",
    OciFssFileSystemStatus = "OciFssFileSystemStatus",
    OciFssMountTarget = "OciFssMountTarget",
    OciFssMountTargetExport = "OciFssMountTargetExport",
    OciFssMountTargetExportModel = "OciFssMountTargetExportModel",
    OciFssMountTargetExportOption = "OciFssMountTargetExportOption",
    OciFssMountTargetExportOptionAccess = "OciFssMountTargetExportOptionAccess",
    OciFssMountTargetExportOptionAuthType = "OciFssMountTargetExportOptionAuthType",
    OciFssMountTargetExportOptionIdentitySquash = "OciFssMountTargetExportOptionIdentitySquash",
    OciFssMountTargetExportProfile = "OciFssMountTargetExportProfile",
    OciFssMountTargetExportStatus = "OciFssMountTargetExportStatus",
    OciFssMountTargetModel = "OciFssMountTargetModel",
    OciFssMountTargetProfile = "OciFssMountTargetProfile",
    OciFssMountTargetStatus = "OciFssMountTargetStatus",
    OciFssReplicationGenericResource = "OciFssReplicationGenericResource",
    OciFssReplicationTargetGenericResource = "OciFssReplicationTargetGenericResource",
    OciFsuActionGenericResource = "OciFsuActionGenericResource",
    OciFsuCollectionGenericResource = "OciFsuCollectionGenericResource",
    OciFsuCycleGenericResource = "OciFsuCycleGenericResource",
    OciFsuDiscoveryGenericResource = "OciFsuDiscoveryGenericResource",
    OciFsuJobGenericResource = "OciFsuJobGenericResource",
    OciFunctionsApplicationGenericResource = "OciFunctionsApplicationGenericResource",
    OciFunctionsFunctionGenericResource = "OciFunctionsFunctionGenericResource",
    OciFunctionsFunctionGenericResourceModel = "OciFunctionsFunctionGenericResourceModel",
    OciFusionEnvironmentFamilyGenericResource = "OciFusionEnvironmentFamilyGenericResource",
    OciFusionEnvironmentGenericResource = "OciFusionEnvironmentGenericResource",
    OciGenAiAgentDevelopmentGenericResource = "OciGenAiAgentDevelopmentGenericResource",
    OciGenerativeAiDedicatedAiClusterGenericResource = "OciGenerativeAiDedicatedAiClusterGenericResource",
    OciGenerativeAiEndpointGenericResource = "OciGenerativeAiEndpointGenericResource",
    OciGenerativeAiModelGenericResource = "OciGenerativeAiModelGenericResource",
    OciGoldenGateConnectionGenericResource = "OciGoldenGateConnectionGenericResource",
    OciGoldenGateDatabaseRegistrationGenericResource = "OciGoldenGateDatabaseRegistrationGenericResource",
    OciGoldenGateDeploymentBackupGenericResource = "OciGoldenGateDeploymentBackupGenericResource",
    OciGoldenGateDeploymentGenericResource = "OciGoldenGateDeploymentGenericResource",
    OciGroupGenericResource = "OciGroupGenericResource",
    OciHttpRedirectGenericResource = "OciHttpRedirectGenericResource",
    OciIamDomain = "OciIamDomain",
    OciIamDomainLicenseType = "OciIamDomainLicenseType",
    OciIamDomainModel = "OciIamDomainModel",
    OciIamDomainPasswordExpirationPolicyCodeRisk = "OciIamDomainPasswordExpirationPolicyCodeRisk",
    OciIamDomainPasswordExpirationPolicyCodeRiskModel = "OciIamDomainPasswordExpirationPolicyCodeRiskModel",
    OciIamDomainPasswordExpirationPolicyRisk = "OciIamDomainPasswordExpirationPolicyRisk",
    OciIamDomainPasswordExpirationPolicyRiskModel = "OciIamDomainPasswordExpirationPolicyRiskModel",
    OciIamDomainPasswordExpirationPolicyRiskPolicyConfiguration = "OciIamDomainPasswordExpirationPolicyRiskPolicyConfiguration",
    OciIamDomainPasswordExpirationPolicyRiskPolicyUpdateAuditEventPasswordPolicyExpirationIntervalDaysChange = "OciIamDomainPasswordExpirationPolicyRiskPolicyUpdateAuditEventPasswordPolicyExpirationIntervalDaysChange",
    OciIamDomainPasswordLengthPolicyCodeRisk = "OciIamDomainPasswordLengthPolicyCodeRisk",
    OciIamDomainPasswordLengthPolicyCodeRiskModel = "OciIamDomainPasswordLengthPolicyCodeRiskModel",
    OciIamDomainPasswordLengthPolicyRisk = "OciIamDomainPasswordLengthPolicyRisk",
    OciIamDomainPasswordLengthPolicyRiskModel = "OciIamDomainPasswordLengthPolicyRiskModel",
    OciIamDomainPasswordLengthPolicyRiskPolicyConfiguration = "OciIamDomainPasswordLengthPolicyRiskPolicyConfiguration",
    OciIamDomainPasswordLengthPolicyRiskPolicyUpdateAuditEventPasswordPolicyMinLengthChange = "OciIamDomainPasswordLengthPolicyRiskPolicyUpdateAuditEventPasswordPolicyMinLengthChange",
    OciIamDomainPasswordPolicy = "OciIamDomainPasswordPolicy",
    OciIamDomainPasswordReusePolicyCodeRisk = "OciIamDomainPasswordReusePolicyCodeRisk",
    OciIamDomainPasswordReusePolicyCodeRiskModel = "OciIamDomainPasswordReusePolicyCodeRiskModel",
    OciIamDomainPasswordReusePolicyRisk = "OciIamDomainPasswordReusePolicyRisk",
    OciIamDomainPasswordReusePolicyRiskModel = "OciIamDomainPasswordReusePolicyRiskModel",
    OciIamDomainPasswordReusePolicyRiskPolicyConfiguration = "OciIamDomainPasswordReusePolicyRiskPolicyConfiguration",
    OciIamDomainPasswordReusePolicyRiskPolicyUpdateAuditEventPasswordPolicyPreventReusePreviousPasswordCountChange = "OciIamDomainPasswordReusePolicyRiskPolicyUpdateAuditEventPasswordPolicyPreventReusePreviousPasswordCountChange",
    OciIamDomainProfile = "OciIamDomainProfile",
    OciIamDynamicResourceGroup = "OciIamDynamicResourceGroup",
    OciIamDynamicResourceGroupModel = "OciIamDynamicResourceGroupModel",
    OciIamDynamicResourceGroupProfile = "OciIamDynamicResourceGroupProfile",
    OciIamGroup = "OciIamGroup",
    OciIamGroupModel = "OciIamGroupModel",
    OciIamGroupProfile = "OciIamGroupProfile",
    OciIamPolicy = "OciIamPolicy",
    OciIamPolicyModel = "OciIamPolicyModel",
    OciIamPolicyProfile = "OciIamPolicyProfile",
    OciIamPrincipal = "OciIamPrincipal",
    OciIamPrincipalModel = "OciIamPrincipalModel",
    OciIamPrincipalProfile = "OciIamPrincipalProfile",
    OciIamResource = "OciIamResource",
    OciIamResourceModel = "OciIamResourceModel",
    OciIamResourceProfile = "OciIamResourceProfile",
    OciIamUser = "OciIamUser",
    OciIamUserApiKey = "OciIamUserApiKey",
    OciIamUserApiKeyModel = "OciIamUserApiKeyModel",
    OciIamUserApiKeyNotRotatedRisk = "OciIamUserApiKeyNotRotatedRisk",
    OciIamUserApiKeyNotRotatedRiskModel = "OciIamUserApiKeyNotRotatedRiskModel",
    OciIamUserApiKeyNotRotatedRiskPolicyConfiguration = "OciIamUserApiKeyNotRotatedRiskPolicyConfiguration",
    OciIamUserApiKeyProfile = "OciIamUserApiKeyProfile",
    OciIamUserAuthToken = "OciIamUserAuthToken",
    OciIamUserAuthTokenModel = "OciIamUserAuthTokenModel",
    OciIamUserAuthTokenNotRotatedRisk = "OciIamUserAuthTokenNotRotatedRisk",
    OciIamUserAuthTokenNotRotatedRiskModel = "OciIamUserAuthTokenNotRotatedRiskModel",
    OciIamUserAuthTokenNotRotatedRiskPolicyConfiguration = "OciIamUserAuthTokenNotRotatedRiskPolicyConfiguration",
    OciIamUserAuthTokenProfile = "OciIamUserAuthTokenProfile",
    OciIamUserCredential = "OciIamUserCredential",
    OciIamUserCredentialModel = "OciIamUserCredentialModel",
    OciIamUserCredentialNotRotatedRisk = "OciIamUserCredentialNotRotatedRisk",
    OciIamUserCredentialNotRotatedRiskPolicyConfiguration = "OciIamUserCredentialNotRotatedRiskPolicyConfiguration",
    OciIamUserCredentialNotRotatedRiskPolicyUpdateAuditEventCredentialRotationTimeFrameChange = "OciIamUserCredentialNotRotatedRiskPolicyUpdateAuditEventCredentialRotationTimeFrameChange",
    OciIamUserCredentialProfile = "OciIamUserCredentialProfile",
    OciIamUserDatabasePassword = "OciIamUserDatabasePassword",
    OciIamUserDatabasePasswordModel = "OciIamUserDatabasePasswordModel",
    OciIamUserDatabasePasswordNotRotatedRisk = "OciIamUserDatabasePasswordNotRotatedRisk",
    OciIamUserDatabasePasswordNotRotatedRiskModel = "OciIamUserDatabasePasswordNotRotatedRiskModel",
    OciIamUserDatabasePasswordNotRotatedRiskPolicyConfiguration = "OciIamUserDatabasePasswordNotRotatedRiskPolicyConfiguration",
    OciIamUserDatabasePasswordProfile = "OciIamUserDatabasePasswordProfile",
    OciIamUserModel = "OciIamUserModel",
    OciIamUserModelFilters = "OciIamUserModelFilters",
    OciIamUserOrganizationAdministratorApiKeyExistsRisk = "OciIamUserOrganizationAdministratorApiKeyExistsRisk",
    OciIamUserOrganizationAdministratorApiKeyExistsRiskModel = "OciIamUserOrganizationAdministratorApiKeyExistsRiskModel",
    OciIamUserOrganizationAdministratorApiKeyExistsRiskPolicyConfiguration = "OciIamUserOrganizationAdministratorApiKeyExistsRiskPolicyConfiguration",
    OciIamUserProfile = "OciIamUserProfile",
    OciIamUserSecretKey = "OciIamUserSecretKey",
    OciIamUserSecretKeyModel = "OciIamUserSecretKeyModel",
    OciIamUserSecretKeyNotRotatedRisk = "OciIamUserSecretKeyNotRotatedRisk",
    OciIamUserSecretKeyNotRotatedRiskModel = "OciIamUserSecretKeyNotRotatedRiskModel",
    OciIamUserSecretKeyNotRotatedRiskPolicyConfiguration = "OciIamUserSecretKeyNotRotatedRiskPolicyConfiguration",
    OciIamUserSecretKeyProfile = "OciIamUserSecretKeyProfile",
    OciIdentityProviderGenericResource = "OciIdentityProviderGenericResource",
    OciImageGenericResource = "OciImageGenericResource",
    OciIngressGatewayGenericResource = "OciIngressGatewayGenericResource",
    OciIngressGatewayRouteTableGenericResource = "OciIngressGatewayRouteTableGenericResource",
    OciInstanceConfigurationGenericResource = "OciInstanceConfigurationGenericResource",
    OciInstanceGenericResource = "OciInstanceGenericResource",
    OciInstancePoolGenericResource = "OciInstancePoolGenericResource",
    OciIntegrationInstanceGenericResource = "OciIntegrationInstanceGenericResource",
    OciInternetGatewayGenericResource = "OciInternetGatewayGenericResource",
    OciInventoryAssetGenericResource = "OciInventoryAssetGenericResource",
    OciIpSecConnectionGenericResource = "OciIpSecConnectionGenericResource",
    OciIpv6GenericResource = "OciIpv6GenericResource",
    OciJmsFleetGenericResource = "OciJmsFleetGenericResource",
    OciKafkaClusterConfigGenericResource = "OciKafkaClusterConfigGenericResource",
    OciKafkaClusterGenericResource = "OciKafkaClusterGenericResource",
    OciKeyGenericResource = "OciKeyGenericResource",
    OciKmsHsmClusterGenericResource = "OciKmsHsmClusterGenericResource",
    OciKmsHsmPartitionGenericResource = "OciKmsHsmPartitionGenericResource",
    OciKmsVault = "OciKmsVault",
    OciKmsVaultKey = "OciKmsVaultKey",
    OciKmsVaultKeyAlgorithm = "OciKmsVaultKeyAlgorithm",
    OciKmsVaultKeyCurveType = "OciKmsVaultKeyCurveType",
    OciKmsVaultKeyModel = "OciKmsVaultKeyModel",
    OciKmsVaultKeyNotRotatedRisk = "OciKmsVaultKeyNotRotatedRisk",
    OciKmsVaultKeyNotRotatedRiskModel = "OciKmsVaultKeyNotRotatedRiskModel",
    OciKmsVaultKeyNotRotatedRiskPolicyConfiguration = "OciKmsVaultKeyNotRotatedRiskPolicyConfiguration",
    OciKmsVaultKeyNotRotatedRiskPolicyUpdateAuditEventKeyRotationTimeFrameChange = "OciKmsVaultKeyNotRotatedRiskPolicyUpdateAuditEventKeyRotationTimeFrameChange",
    OciKmsVaultKeyProfile = "OciKmsVaultKeyProfile",
    OciKmsVaultKeyProtectionMode = "OciKmsVaultKeyProtectionMode",
    OciKmsVaultKeyStatus = "OciKmsVaultKeyStatus",
    OciKmsVaultKeyVersion = "OciKmsVaultKeyVersion",
    OciKmsVaultKeyVersionSource = "OciKmsVaultKeyVersionSource",
    OciKmsVaultKeyVersionStatus = "OciKmsVaultKeyVersionStatus",
    OciKmsVaultModel = "OciKmsVaultModel",
    OciKmsVaultObject = "OciKmsVaultObject",
    OciKmsVaultObjectModel = "OciKmsVaultObjectModel",
    OciKmsVaultProfile = "OciKmsVaultProfile",
    OciKmsVaultSecret = "OciKmsVaultSecret",
    OciKmsVaultSecretModel = "OciKmsVaultSecretModel",
    OciKmsVaultSecretProfile = "OciKmsVaultSecretProfile",
    OciKmsVaultSecretStatus = "OciKmsVaultSecretStatus",
    OciKmsVaultSecretVersion = "OciKmsVaultSecretVersion",
    OciKmsVaultSecretVersionStatus = "OciKmsVaultSecretVersionStatus",
    OciKmsVaultStatus = "OciKmsVaultStatus",
    OciKmsVaultType = "OciKmsVaultType",
    OciLicenseManagerLicenseRecordGenericResource = "OciLicenseManagerLicenseRecordGenericResource",
    OciLicenseManagerProductLicenseGenericResource = "OciLicenseManagerProductLicenseGenericResource",
    OciLoadBalancerGenericResource = "OciLoadBalancerGenericResource",
    OciLoadBalancingLoadBalancer = "OciLoadBalancingLoadBalancer",
    OciLoadBalancingLoadBalancerBackend = "OciLoadBalancingLoadBalancerBackend",
    OciLoadBalancingLoadBalancerBackendSet = "OciLoadBalancingLoadBalancerBackendSet",
    OciLoadBalancingLoadBalancerListener = "OciLoadBalancingLoadBalancerListener",
    OciLoadBalancingLoadBalancerModel = "OciLoadBalancingLoadBalancerModel",
    OciLoadBalancingLoadBalancerProfile = "OciLoadBalancingLoadBalancerProfile",
    OciLoadBalancingLoadBalancerSslConfiguration = "OciLoadBalancingLoadBalancerSslConfiguration",
    OciLoadBalancingLoadBalancerSslProtocol = "OciLoadBalancingLoadBalancerSslProtocol",
    OciLoadBalancingLoadBalancerStatus = "OciLoadBalancingLoadBalancerStatus",
    OciLocalPeeringGatewayGenericResource = "OciLocalPeeringGatewayGenericResource",
    OciLogAnalyticsEntityGenericResource = "OciLogAnalyticsEntityGenericResource",
    OciLogAsyncSearchGenericResource = "OciLogAsyncSearchGenericResource",
    OciLogDataModelGenericResource = "OciLogDataModelGenericResource",
    OciLogFireSaasEnvironmentGenericResource = "OciLogFireSaasEnvironmentGenericResource",
    OciLogGenericResource = "OciLogGenericResource",
    OciLoggingLog = "OciLoggingLog",
    OciLoggingLogGroup = "OciLoggingLogGroup",
    OciLoggingLogGroupModel = "OciLoggingLogGroupModel",
    OciLoggingLogGroupProfile = "OciLoggingLogGroupProfile",
    OciLoggingLogSourceServiceConfiguration = "OciLoggingLogSourceServiceConfiguration",
    OciLoggingLogStatus = "OciLoggingLogStatus",
    OciLoggingLogType = "OciLoggingLogType",
    OciLogGroupGenericResource = "OciLogGroupGenericResource",
    OciLogRuleGenericResource = "OciLogRuleGenericResource",
    OciLogSavedSearchGenericResource = "OciLogSavedSearchGenericResource",
    OciManagementAgentGenericResource = "OciManagementAgentGenericResource",
    OciManagementAgentInstallKeyGenericResource = "OciManagementAgentInstallKeyGenericResource",
    OciMaxymiserSaasEnvironmentGenericResource = "OciMaxymiserSaasEnvironmentGenericResource",
    OciMediaAssetGenericResource = "OciMediaAssetGenericResource",
    OciMediaWorkflowConfigurationGenericResource = "OciMediaWorkflowConfigurationGenericResource",
    OciMediaWorkflowGenericResource = "OciMediaWorkflowGenericResource",
    OciMediaWorkflowJobGenericResource = "OciMediaWorkflowJobGenericResource",
    OciMeshGenericResource = "OciMeshGenericResource",
    OciMigrationGenericResource = "OciMigrationGenericResource",
    OciMigrationPlanGenericResource = "OciMigrationPlanGenericResource",
    OciMktPubArtifactGenericResource = "OciMktPubArtifactGenericResource",
    OciMktPubListingGenericResource = "OciMktPubListingGenericResource",
    OciMktPubListingRevisionGenericResource = "OciMktPubListingRevisionGenericResource",
    OciMktPubTermGenericResource = "OciMktPubTermGenericResource",
    OciMktPubTermVersionGenericResource = "OciMktPubTermVersionGenericResource",
    OciMockCimImSaasEnvironmentGenericResource = "OciMockCimImSaasEnvironmentGenericResource",
    OciMountTargetGenericResource = "OciMountTargetGenericResource",
    OciNatGatewayGenericResource = "OciNatGatewayGenericResource",
    OciNetworkFirewallGenericResource = "OciNetworkFirewallGenericResource",
    OciNetworkFirewallPolicyGenericResource = "OciNetworkFirewallPolicyGenericResource",
    OciNetworkingInternetGateway = "OciNetworkingInternetGateway",
    OciNetworkingInternetGatewayModel = "OciNetworkingInternetGatewayModel",
    OciNetworkingInternetGatewayProfile = "OciNetworkingInternetGatewayProfile",
    OciNetworkingInternetGatewayStatus = "OciNetworkingInternetGatewayStatus",
    OciNetworkingLocalPeeringGateway = "OciNetworkingLocalPeeringGateway",
    OciNetworkingLocalPeeringGatewayModel = "OciNetworkingLocalPeeringGatewayModel",
    OciNetworkingLocalPeeringGatewayPeeringStatus = "OciNetworkingLocalPeeringGatewayPeeringStatus",
    OciNetworkingLocalPeeringGatewayProfile = "OciNetworkingLocalPeeringGatewayProfile",
    OciNetworkingLocalPeeringGatewayStatus = "OciNetworkingLocalPeeringGatewayStatus",
    OciNetworkingNatGateway = "OciNetworkingNatGateway",
    OciNetworkingNatGatewayModel = "OciNetworkingNatGatewayModel",
    OciNetworkingNatGatewayProfile = "OciNetworkingNatGatewayProfile",
    OciNetworkingNatGatewayStatus = "OciNetworkingNatGatewayStatus",
    OciNetworkingNetworkLoadBalancer = "OciNetworkingNetworkLoadBalancer",
    OciNetworkingNetworkLoadBalancerBackend = "OciNetworkingNetworkLoadBalancerBackend",
    OciNetworkingNetworkLoadBalancerListener = "OciNetworkingNetworkLoadBalancerListener",
    OciNetworkingNetworkLoadBalancerListenerProtocol = "OciNetworkingNetworkLoadBalancerListenerProtocol",
    OciNetworkingNetworkLoadBalancerModel = "OciNetworkingNetworkLoadBalancerModel",
    OciNetworkingNetworkLoadBalancerProfile = "OciNetworkingNetworkLoadBalancerProfile",
    OciNetworkingNetworkLoadBalancerStatus = "OciNetworkingNetworkLoadBalancerStatus",
    OciNetworkingNetworkResourceInboundRuleSubnetAnyExistsRisk = "OciNetworkingNetworkResourceInboundRuleSubnetAnyExistsRisk",
    OciNetworkingNetworkSecurityGroup = "OciNetworkingNetworkSecurityGroup",
    OciNetworkingNetworkSecurityGroupInboundRuleSubnetAnyExistsRisk = "OciNetworkingNetworkSecurityGroupInboundRuleSubnetAnyExistsRisk",
    OciNetworkingNetworkSecurityGroupInboundRuleSubnetAnyExistsRiskModel = "OciNetworkingNetworkSecurityGroupInboundRuleSubnetAnyExistsRiskModel",
    OciNetworkingNetworkSecurityGroupInboundRuleSubnetAnyRdpExistsCodeRisk = "OciNetworkingNetworkSecurityGroupInboundRuleSubnetAnyRdpExistsCodeRisk",
    OciNetworkingNetworkSecurityGroupInboundRuleSubnetAnyRdpExistsCodeRiskModel = "OciNetworkingNetworkSecurityGroupInboundRuleSubnetAnyRdpExistsCodeRiskModel",
    OciNetworkingNetworkSecurityGroupInboundRuleSubnetAnyRdpExistsRisk = "OciNetworkingNetworkSecurityGroupInboundRuleSubnetAnyRdpExistsRisk",
    OciNetworkingNetworkSecurityGroupInboundRuleSubnetAnyRdpExistsRiskModel = "OciNetworkingNetworkSecurityGroupInboundRuleSubnetAnyRdpExistsRiskModel",
    OciNetworkingNetworkSecurityGroupInboundRuleSubnetAnyRdpExistsRiskPolicyConfiguration = "OciNetworkingNetworkSecurityGroupInboundRuleSubnetAnyRdpExistsRiskPolicyConfiguration",
    OciNetworkingNetworkSecurityGroupInboundRuleSubnetAnySshExistsCodeRisk = "OciNetworkingNetworkSecurityGroupInboundRuleSubnetAnySshExistsCodeRisk",
    OciNetworkingNetworkSecurityGroupInboundRuleSubnetAnySshExistsCodeRiskModel = "OciNetworkingNetworkSecurityGroupInboundRuleSubnetAnySshExistsCodeRiskModel",
    OciNetworkingNetworkSecurityGroupInboundRuleSubnetAnySshExistsRisk = "OciNetworkingNetworkSecurityGroupInboundRuleSubnetAnySshExistsRisk",
    OciNetworkingNetworkSecurityGroupInboundRuleSubnetAnySshExistsRiskModel = "OciNetworkingNetworkSecurityGroupInboundRuleSubnetAnySshExistsRiskModel",
    OciNetworkingNetworkSecurityGroupInboundRuleSubnetAnySshExistsRiskPolicyConfiguration = "OciNetworkingNetworkSecurityGroupInboundRuleSubnetAnySshExistsRiskPolicyConfiguration",
    OciNetworkingNetworkSecurityGroupModel = "OciNetworkingNetworkSecurityGroupModel",
    OciNetworkingNetworkSecurityGroupProfile = "OciNetworkingNetworkSecurityGroupProfile",
    OciNetworkingNetworkSecurityGroupRule = "OciNetworkingNetworkSecurityGroupRule",
    OciNetworkingNetworkSecurityGroupRuleEndpointType = "OciNetworkingNetworkSecurityGroupRuleEndpointType",
    OciNetworkingNetworkSecurityGroupStatus = "OciNetworkingNetworkSecurityGroupStatus",
    OciNetworkingRouteTable = "OciNetworkingRouteTable",
    OciNetworkingRouteTableModel = "OciNetworkingRouteTableModel",
    OciNetworkingRouteTableProfile = "OciNetworkingRouteTableProfile",
    OciNetworkingRouteTableRouteRule = "OciNetworkingRouteTableRouteRule",
    OciNetworkingRouteTableRouteRuleRouteType = "OciNetworkingRouteTableRouteRuleRouteType",
    OciNetworkingRouteTableStatus = "OciNetworkingRouteTableStatus",
    OciNetworkingSecurityList = "OciNetworkingSecurityList",
    OciNetworkingSecurityListInboundRuleSubnetAnyExistsRisk = "OciNetworkingSecurityListInboundRuleSubnetAnyExistsRisk",
    OciNetworkingSecurityListInboundRuleSubnetAnyExistsRiskModel = "OciNetworkingSecurityListInboundRuleSubnetAnyExistsRiskModel",
    OciNetworkingSecurityListInboundRuleSubnetAnyRdpExistsCodeRisk = "OciNetworkingSecurityListInboundRuleSubnetAnyRdpExistsCodeRisk",
    OciNetworkingSecurityListInboundRuleSubnetAnyRdpExistsCodeRiskModel = "OciNetworkingSecurityListInboundRuleSubnetAnyRdpExistsCodeRiskModel",
    OciNetworkingSecurityListInboundRuleSubnetAnyRdpExistsRisk = "OciNetworkingSecurityListInboundRuleSubnetAnyRdpExistsRisk",
    OciNetworkingSecurityListInboundRuleSubnetAnyRdpExistsRiskModel = "OciNetworkingSecurityListInboundRuleSubnetAnyRdpExistsRiskModel",
    OciNetworkingSecurityListInboundRuleSubnetAnyRdpExistsRiskPolicyConfiguration = "OciNetworkingSecurityListInboundRuleSubnetAnyRdpExistsRiskPolicyConfiguration",
    OciNetworkingSecurityListInboundRuleSubnetAnySshExistsCodeRisk = "OciNetworkingSecurityListInboundRuleSubnetAnySshExistsCodeRisk",
    OciNetworkingSecurityListInboundRuleSubnetAnySshExistsCodeRiskModel = "OciNetworkingSecurityListInboundRuleSubnetAnySshExistsCodeRiskModel",
    OciNetworkingSecurityListInboundRuleSubnetAnySshExistsRisk = "OciNetworkingSecurityListInboundRuleSubnetAnySshExistsRisk",
    OciNetworkingSecurityListInboundRuleSubnetAnySshExistsRiskModel = "OciNetworkingSecurityListInboundRuleSubnetAnySshExistsRiskModel",
    OciNetworkingSecurityListInboundRuleSubnetAnySshExistsRiskPolicyConfiguration = "OciNetworkingSecurityListInboundRuleSubnetAnySshExistsRiskPolicyConfiguration",
    OciNetworkingSecurityListModel = "OciNetworkingSecurityListModel",
    OciNetworkingSecurityListProfile = "OciNetworkingSecurityListProfile",
    OciNetworkingSecurityListRule = "OciNetworkingSecurityListRule",
    OciNetworkingSecurityListStatus = "OciNetworkingSecurityListStatus",
    OciNetworkingSecurityRule = "OciNetworkingSecurityRule",
    OciNetworkingSecurityRuleIcmp = "OciNetworkingSecurityRuleIcmp",
    OciNetworkingServiceGateway = "OciNetworkingServiceGateway",
    OciNetworkingServiceGatewayModel = "OciNetworkingServiceGatewayModel",
    OciNetworkingServiceGatewayProfile = "OciNetworkingServiceGatewayProfile",
    OciNetworkingServiceGatewayStatus = "OciNetworkingServiceGatewayStatus",
    OciNetworkingSubnet = "OciNetworkingSubnet",
    OciNetworkingSubnetModel = "OciNetworkingSubnetModel",
    OciNetworkingSubnetProfile = "OciNetworkingSubnetProfile",
    OciNetworkingSubnetStatus = "OciNetworkingSubnetStatus",
    OciNetworkingVcn = "OciNetworkingVcn",
    OciNetworkingVcnFlowLogsNotEnabledCodeRisk = "OciNetworkingVcnFlowLogsNotEnabledCodeRisk",
    OciNetworkingVcnFlowLogsNotEnabledCodeRiskModel = "OciNetworkingVcnFlowLogsNotEnabledCodeRiskModel",
    OciNetworkingVcnFlowLogsNotEnabledRisk = "OciNetworkingVcnFlowLogsNotEnabledRisk",
    OciNetworkingVcnFlowLogsNotEnabledRiskModel = "OciNetworkingVcnFlowLogsNotEnabledRiskModel",
    OciNetworkingVcnFlowLogsNotEnabledRiskPolicyConfiguration = "OciNetworkingVcnFlowLogsNotEnabledRiskPolicyConfiguration",
    OciNetworkingVcnModel = "OciNetworkingVcnModel",
    OciNetworkingVcnProfile = "OciNetworkingVcnProfile",
    OciNetworkingVcnStatus = "OciNetworkingVcnStatus",
    OciNetworkingVirtualNetworkInterfaceCard = "OciNetworkingVirtualNetworkInterfaceCard",
    OciNetworkingVirtualNetworkInterfaceCardModel = "OciNetworkingVirtualNetworkInterfaceCardModel",
    OciNetworkingVirtualNetworkInterfaceCardProfile = "OciNetworkingVirtualNetworkInterfaceCardProfile",
    OciNetworkSecurityGroupGenericResource = "OciNetworkSecurityGroupGenericResource",
    OciNoSqlTableGenericResource = "OciNoSqlTableGenericResource",
    OciObjectStorageBucket = "OciObjectStorageBucket",
    OciObjectStorageBucketDefaultEncryptionRisk = "OciObjectStorageBucketDefaultEncryptionRisk",
    OciObjectStorageBucketDefaultEncryptionRiskModel = "OciObjectStorageBucketDefaultEncryptionRiskModel",
    OciObjectStorageBucketDefaultEncryptionRiskPolicyConfiguration = "OciObjectStorageBucketDefaultEncryptionRiskPolicyConfiguration",
    OciObjectStorageBucketLogCategory = "OciObjectStorageBucketLogCategory",
    OciObjectStorageBucketLogCategoryWriteNotExistsCodeRisk = "OciObjectStorageBucketLogCategoryWriteNotExistsCodeRisk",
    OciObjectStorageBucketLogCategoryWriteNotExistsCodeRiskModel = "OciObjectStorageBucketLogCategoryWriteNotExistsCodeRiskModel",
    OciObjectStorageBucketLogCategoryWriteNotExistsRisk = "OciObjectStorageBucketLogCategoryWriteNotExistsRisk",
    OciObjectStorageBucketLogCategoryWriteNotExistsRiskModel = "OciObjectStorageBucketLogCategoryWriteNotExistsRiskModel",
    OciObjectStorageBucketLogCategoryWriteNotExistsRiskPolicyConfiguration = "OciObjectStorageBucketLogCategoryWriteNotExistsRiskPolicyConfiguration",
    OciObjectStorageBucketModel = "OciObjectStorageBucketModel",
    OciObjectStorageBucketObjectAccessLevel = "OciObjectStorageBucketObjectAccessLevel",
    OciObjectStorageBucketObjectEventsDisabledRisk = "OciObjectStorageBucketObjectEventsDisabledRisk",
    OciObjectStorageBucketObjectEventsDisabledRiskModel = "OciObjectStorageBucketObjectEventsDisabledRiskModel",
    OciObjectStorageBucketObjectEventsDisabledRiskPolicyConfiguration = "OciObjectStorageBucketObjectEventsDisabledRiskPolicyConfiguration",
    OciObjectStorageBucketObjectVersioning = "OciObjectStorageBucketObjectVersioning",
    OciObjectStorageBucketObjectVersioningDisabledRisk = "OciObjectStorageBucketObjectVersioningDisabledRisk",
    OciObjectStorageBucketObjectVersioningDisabledRiskModel = "OciObjectStorageBucketObjectVersioningDisabledRiskModel",
    OciObjectStorageBucketObjectVersioningDisabledRiskPolicyConfiguration = "OciObjectStorageBucketObjectVersioningDisabledRiskPolicyConfiguration",
    OciObjectStorageBucketProfile = "OciObjectStorageBucketProfile",
    OciObjectStorageBucketPublicAccessExistsRisk = "OciObjectStorageBucketPublicAccessExistsRisk",
    OciObjectStorageBucketPublicAccessExistsRiskModel = "OciObjectStorageBucketPublicAccessExistsRiskModel",
    OciObjectStorageBucketPublicAccessExistsRiskPolicyConfiguration = "OciObjectStorageBucketPublicAccessExistsRiskPolicyConfiguration",
    OciObjectStorageBucketStorageTier = "OciObjectStorageBucketStorageTier",
    OciOcbAgentDependencyGenericResource = "OciOcbAgentDependencyGenericResource",
    OciOcbAgentGenericResource = "OciOcbAgentGenericResource",
    OciOcbAssetSourceGenericResource = "OciOcbAssetSourceGenericResource",
    OciOcbAwsEbsAssetGenericResource = "OciOcbAwsEbsAssetGenericResource",
    OciOcbAwsEcTwoAssetGenericResource = "OciOcbAwsEcTwoAssetGenericResource",
    OciOcbDiscoveryScheduleGenericResource = "OciOcbDiscoveryScheduleGenericResource",
    OciOcbEnvironmentGenericResource = "OciOcbEnvironmentGenericResource",
    OciOcbInventoryGenericResource = "OciOcbInventoryGenericResource",
    OciOcbInventoryRelationGenericResource = "OciOcbInventoryRelationGenericResource",
    OciOcbOracleDbAssetGenericResource = "OciOcbOracleDbAssetGenericResource",
    OciOcbVmAssetGenericResource = "OciOcbVmAssetGenericResource",
    OciOcbVmwareVmAssetGenericResource = "OciOcbVmwareVmAssetGenericResource",
    OciOccAvailabilityCatalogGenericResource = "OciOccAvailabilityCatalogGenericResource",
    OciOccCapacityRequestGenericResource = "OciOccCapacityRequestGenericResource",
    OciOceInstanceGenericResource = "OciOceInstanceGenericResource",
    OciOdaInstanceGenericResource = "OciOdaInstanceGenericResource",
    OciOdaPrivateEndpointGenericResource = "OciOdaPrivateEndpointGenericResource",
    OciOdmsAgentGenericResource = "OciOdmsAgentGenericResource",
    OciOdmsConnectionGenericResource = "OciOdmsConnectionGenericResource",
    OciOdmsJobGenericResource = "OciOdmsJobGenericResource",
    OciOdmsMigrationGenericResource = "OciOdmsMigrationGenericResource",
    OciOneoffPatchGenericResource = "OciOneoffPatchGenericResource",
    OciOnsPhoneApplicationGenericResource = "OciOnsPhoneApplicationGenericResource",
    OciOnsPhoneNumberGenericResource = "OciOnsPhoneNumberGenericResource",
    OciOnsSubscriptionGenericResource = "OciOnsSubscriptionGenericResource",
    OciOnsTopicGenericResource = "OciOnsTopicGenericResource",
    OciOpctlOperatorControlAssignmentGenericResource = "OciOpctlOperatorControlAssignmentGenericResource",
    OciOpctlOperatorControlGenericResource = "OciOpctlOperatorControlGenericResource",
    OciOpsiDatabaseInsightGenericResource = "OciOpsiDatabaseInsightGenericResource",
    OciOracleDbAzureBlobContainerGenericResource = "OciOracleDbAzureBlobContainerGenericResource",
    OciOracleDbAzureBlobMountGenericResource = "OciOracleDbAzureBlobMountGenericResource",
    OciOracleDbAzureConnectorGenericResource = "OciOracleDbAzureConnectorGenericResource",
    OciOracleDbAzureVaultAssociationGenericResource = "OciOracleDbAzureVaultAssociationGenericResource",
    OciOracleDbAzureVaultGenericResource = "OciOracleDbAzureVaultGenericResource",
    OciOrganizationAuditEvent = "OciOrganizationAuditEvent",
    OciOrganizationConfiguration = "OciOrganizationConfiguration",
    OciOrganizationConfigurationUser = "OciOrganizationConfigurationUser",
    OciOrganizationCreationAuditEvent = "OciOrganizationCreationAuditEvent",
    OciOrganizationDeletionAuditEvent = "OciOrganizationDeletionAuditEvent",
    OciOrganizationModel = "OciOrganizationModel",
    OciOrganizationModelStatus = "OciOrganizationModelStatus",
    OciOrganizationsGovernanceRuleGenericResource = "OciOrganizationsGovernanceRuleGenericResource",
    OciOrganizationState = "OciOrganizationState",
    OciOrganizationStateIssue = "OciOrganizationStateIssue",
    OciOrganizationUpdateAuditEvent = "OciOrganizationUpdateAuditEvent",
    OciOrmConfigSourceProviderGenericResource = "OciOrmConfigSourceProviderGenericResource",
    OciOrmJobGenericResource = "OciOrmJobGenericResource",
    OciOrmPrivateEndpointGenericResource = "OciOrmPrivateEndpointGenericResource",
    OciOrmStackGenericResource = "OciOrmStackGenericResource",
    OciOrmTemplateGenericResource = "OciOrmTemplateGenericResource",
    OciOsdPrivateEndpointGenericResource = "OciOsdPrivateEndpointGenericResource",
    OciOsdShardedDatabaseGenericResource = "OciOsdShardedDatabaseGenericResource",
    OciOsfpcsSaasEnvironmentGenericResource = "OciOsfpcsSaasEnvironmentGenericResource",
    OciOsmhLifecycleEnvironmentGenericResource = "OciOsmhLifecycleEnvironmentGenericResource",
    OciOsmhLifecycleStageGenericResource = "OciOsmhLifecycleStageGenericResource",
    OciOsmhManagedInstanceGroupGenericResource = "OciOsmhManagedInstanceGroupGenericResource",
    OciOsmhManagementStationGenericResource = "OciOsmhManagementStationGenericResource",
    OciOsmhProfileGenericResource = "OciOsmhProfileGenericResource",
    OciOsmhScheduledJobGenericResource = "OciOsmhScheduledJobGenericResource",
    OciOsmhSoftwareSourceGenericResource = "OciOsmhSoftwareSourceGenericResource",
    OciOsmsManagedInstanceGroupGenericResource = "OciOsmsManagedInstanceGroupGenericResource",
    OciOsmsScheduledJobGenericResource = "OciOsmsScheduledJobGenericResource",
    OciOsmsSoftwareSourceGenericResource = "OciOsmsSoftwareSourceGenericResource",
    OciOtmgtmSaasEnvironmentGenericResource = "OciOtmgtmSaasEnvironmentGenericResource",
    OciOutboundConnectorGenericResource = "OciOutboundConnectorGenericResource",
    OciPartitionType = "OciPartitionType",
    OciPathAnalyzerTestGenericResource = "OciPathAnalyzerTestGenericResource",
    OciPcmcsSaasEnvironmentGenericResource = "OciPcmcsSaasEnvironmentGenericResource",
    OciPlanningSaasEnvironmentGenericResource = "OciPlanningSaasEnvironmentGenericResource",
    OciPluggableDatabaseGenericResource = "OciPluggableDatabaseGenericResource",
    OciPolicyGenericResource = "OciPolicyGenericResource",
    OciPostgresqlBackupGenericResource = "OciPostgresqlBackupGenericResource",
    OciPostgresqlConfigurationGenericResource = "OciPostgresqlConfigurationGenericResource",
    OciPostgresqlDbSystemGenericResource = "OciPostgresqlDbSystemGenericResource",
    OciPriceListGenericResource = "OciPriceListGenericResource",
    OciPriceListItemGenericResource = "OciPriceListItemGenericResource",
    OciPricingRuleGenericResource = "OciPricingRuleGenericResource",
    OciPrivateIpGenericResource = "OciPrivateIpGenericResource",
    OciProcessAutomationInstanceGenericResource = "OciProcessAutomationInstanceGenericResource",
    OciProductGenericResource = "OciProductGenericResource",
    OciProtectedDatabaseGenericResource = "OciProtectedDatabaseGenericResource",
    OciProtectionPolicyGenericResource = "OciProtectionPolicyGenericResource",
    OciPublicIpGenericResource = "OciPublicIpGenericResource",
    OciPublicIpPoolGenericResource = "OciPublicIpPoolGenericResource",
    OciQueryServiceProjectGenericResource = "OciQueryServiceProjectGenericResource",
    OciQueueConsumerGroupGenericResource = "OciQueueConsumerGroupGenericResource",
    OciQueueGenericResource = "OciQueueGenericResource",
    OciQuotumGenericResource = "OciQuotumGenericResource",
    OciRcmsAppConfigurationGenericResource = "OciRcmsAppConfigurationGenericResource",
    OciRecoveryServiceSubnetGenericResource = "OciRecoveryServiceSubnetGenericResource",
    OciRecoverySystemGenericResource = "OciRecoverySystemGenericResource",
    OciRecoverySystemNetworkInterfaceGenericResource = "OciRecoverySystemNetworkInterfaceGenericResource",
    OciRecoverySystemPolicyGenericResource = "OciRecoverySystemPolicyGenericResource",
    OciRedisClusterGenericResource = "OciRedisClusterGenericResource",
    OciRemotePeeringConnectionGenericResource = "OciRemotePeeringConnectionGenericResource",
    OciReplicationPolicyGenericResource = "OciReplicationPolicyGenericResource",
    OciReplicationScheduleGenericResource = "OciReplicationScheduleGenericResource",
    OciResource = "OciResource",
    OciResourceModel = "OciResourceModel",
    OciResourceModelFilters = "OciResourceModelFilters",
    OciResourceProfile = "OciResourceProfile",
    OciResourceScheduleGenericResource = "OciResourceScheduleGenericResource",
    OciResourceSearchGenericResource = "OciResourceSearchGenericResource",
    OciResourceSearchGenericResourceModel = "OciResourceSearchGenericResourceModel",
    OciResourceSearchGenericResourceProfile = "OciResourceSearchGenericResourceProfile",
    OciResourceSearchGenericResourceTypeMetadata = "OciResourceSearchGenericResourceTypeMetadata",
    OciResourceTypeMetadata = "OciResourceTypeMetadata",
    OciRgbuCecsSaasEnvironmentGenericResource = "OciRgbuCecsSaasEnvironmentGenericResource",
    OciRouteTableGenericResource = "OciRouteTableGenericResource",
    OciRoverClusterGenericResource = "OciRoverClusterGenericResource",
    OciRoverNodeGenericResource = "OciRoverNodeGenericResource",
    OciScopeConfigurationOutpost = "OciScopeConfigurationOutpost",
    OciScopeConfigurationOutpostRegionData = "OciScopeConfigurationOutpostRegionData",
    OciScopeConfigurationSections = "OciScopeConfigurationSections",
    OciSecurityAttributeNamespaceGenericResource = "OciSecurityAttributeNamespaceGenericResource",
    OciSecurityListGenericResource = "OciSecurityListGenericResource",
    OciSecurityZonesSecurityPolicyGenericResource = "OciSecurityZonesSecurityPolicyGenericResource",
    OciSecurityZonesSecurityRecipeGenericResource = "OciSecurityZonesSecurityRecipeGenericResource",
    OciSecurityZonesSecurityZoneGenericResource = "OciSecurityZonesSecurityZoneGenericResource",
    OciServiceConnectorGenericResource = "OciServiceConnectorGenericResource",
    OciServiceGatewayGenericResource = "OciServiceGatewayGenericResource",
    OciShareGenericResource = "OciShareGenericResource",
    OciStackMonitoringResourceGenericResource = "OciStackMonitoringResourceGenericResource",
    OciStatusServiceResourceGenericResource = "OciStatusServiceResourceGenericResource",
    OciStorageGatewayGenericResource = "OciStorageGatewayGenericResource",
    OciStreamCdnConfigGenericResource = "OciStreamCdnConfigGenericResource",
    OciStreamDistributionChannelGenericResource = "OciStreamDistributionChannelGenericResource",
    OciStreamGenericResource = "OciStreamGenericResource",
    OciStreamPackagingConfigGenericResource = "OciStreamPackagingConfigGenericResource",
    OciSubnetGenericResource = "OciSubnetGenericResource",
    OciSubscriptionPricingConfigGenericResource = "OciSubscriptionPricingConfigGenericResource",
    OciTagDefaultGenericResource = "OciTagDefaultGenericResource",
    OciTagNamespaceGenericResource = "OciTagNamespaceGenericResource",
    OciTenantAuditEvent = "OciTenantAuditEvent",
    OciTenantConfiguration = "OciTenantConfiguration",
    OciTenantCreationAuditEvent = "OciTenantCreationAuditEvent",
    OciTenantDeletionAuditEvent = "OciTenantDeletionAuditEvent",
    OciTenantEntity = "OciTenantEntity",
    OciTenantEntityModel = "OciTenantEntityModel",
    OciTenantEntityProfile = "OciTenantEntityProfile",
    OciTenantManagerError = "OciTenantManagerError",
    OciTenantModel = "OciTenantModel",
    OciTenantModelStatus = "OciTenantModelStatus",
    OciTenantState = "OciTenantState",
    OciTenantStateMonitoring = "OciTenantStateMonitoring",
    OciTenantStateMonitoringIssue = "OciTenantStateMonitoringIssue",
    OciTenantUpdateAuditEvent = "OciTenantUpdateAuditEvent",
    OciUGbuwacsSaasEnvironmentGenericResource = "OciUGbuwacsSaasEnvironmentGenericResource",
    OciUnifiedAgentConfigurationGenericResource = "OciUnifiedAgentConfigurationGenericResource",
    OciUserGenericResource = "OciUserGenericResource",
    OciVaultGenericResource = "OciVaultGenericResource",
    OciVaultSecretGenericResource = "OciVaultSecretGenericResource",
    OciVbsInstanceGenericResource = "OciVbsInstanceGenericResource",
    OciVcnGenericResource = "OciVcnGenericResource",
    OciVirtualCircuitGenericResource = "OciVirtualCircuitGenericResource",
    OciVirtualDeploymentGenericResource = "OciVirtualDeploymentGenericResource",
    OciVirtualServiceGenericResource = "OciVirtualServiceGenericResource",
    OciVirtualServiceRouteTableGenericResource = "OciVirtualServiceRouteTableGenericResource",
    OciVisualBuilderInstanceGenericResource = "OciVisualBuilderInstanceGenericResource",
    OciVlanGenericResource = "OciVlanGenericResource",
    OciVmClusterGenericResource = "OciVmClusterGenericResource",
    OciVmClusterNetworkGenericResource = "OciVmClusterNetworkGenericResource",
    OciVmwareBillingLinkGenericResource = "OciVmwareBillingLinkGenericResource",
    OciVmwareClusterGenericResource = "OciVmwareClusterGenericResource",
    OciVmwareDatastoreClusterGenericResource = "OciVmwareDatastoreClusterGenericResource",
    OciVmwareDatastoreGenericResource = "OciVmwareDatastoreGenericResource",
    OciVmwareEsxiHostGenericResource = "OciVmwareEsxiHostGenericResource",
    OciVmwareSddcGenericResource = "OciVmwareSddcGenericResource",
    OciVnicGenericResource = "OciVnicGenericResource",
    OciVolumeBackupGenericResource = "OciVolumeBackupGenericResource",
    OciVolumeBackupPolicyGenericResource = "OciVolumeBackupPolicyGenericResource",
    OciVolumeGenericResource = "OciVolumeGenericResource",
    OciVolumeGroupBackupGenericResource = "OciVolumeGroupBackupGenericResource",
    OciVolumeGroupGenericResource = "OciVolumeGroupGenericResource",
    OciVolumeGroupReplicaGenericResource = "OciVolumeGroupReplicaGenericResource",
    OciVolumeReplicaGenericResource = "OciVolumeReplicaGenericResource",
    OciVssContainerScanRecipeGenericResource = "OciVssContainerScanRecipeGenericResource",
    OciVssContainerScanTargetGenericResource = "OciVssContainerScanTargetGenericResource",
    OciVssHostScanRecipeGenericResource = "OciVssHostScanRecipeGenericResource",
    OciVssHostScanTargetGenericResource = "OciVssHostScanTargetGenericResource",
    OciWaasAddressListGenericResource = "OciWaasAddressListGenericResource",
    OciWaasCertificateGenericResource = "OciWaasCertificateGenericResource",
    OciWaasCustomProtectionRuleGenericResource = "OciWaasCustomProtectionRuleGenericResource",
    OciWaasPolicyGenericResource = "OciWaasPolicyGenericResource",
    OciWebAppAccelerationGenericResource = "OciWebAppAccelerationGenericResource",
    OciWebAppAccelerationPolicyGenericResource = "OciWebAppAccelerationPolicyGenericResource",
    OciWebAppFirewallGenericResource = "OciWebAppFirewallGenericResource",
    OciWebAppFirewallNetworkAddressListGenericResource = "OciWebAppFirewallNetworkAddressListGenericResource",
    OciWebAppFirewallPolicyGenericResource = "OciWebAppFirewallPolicyGenericResource",
    OciWlmsWlsDomainGenericResource = "OciWlmsWlsDomainGenericResource",
    OciZprPolicyGenericResource = "OciZprPolicyGenericResource",
    OktaDirectoryApplication = "OktaDirectoryApplication",
    OktaDirectoryApplicationGroupAssignment = "OktaDirectoryApplicationGroupAssignment",
    OktaDirectoryApplicationSignOnMode = "OktaDirectoryApplicationSignOnMode",
    OktaDirectoryApplicationStatus = "OktaDirectoryApplicationStatus",
    OktaDirectoryApplicationUserAssignment = "OktaDirectoryApplicationUserAssignment",
    OktaDirectoryApplicationUserAssignmentType = "OktaDirectoryApplicationUserAssignmentType",
    OktaDirectoryAwsApplication = "OktaDirectoryAwsApplication",
    OktaDirectoryAwsApplicationAwsTenantType = "OktaDirectoryAwsApplicationAwsTenantType",
    OktaDirectoryAwsApplicationGroupAssignment = "OktaDirectoryAwsApplicationGroupAssignment",
    OktaDirectoryAwsApplicationModel = "OktaDirectoryAwsApplicationModel",
    OktaDirectoryAwsApplicationMultiAwsTenant = "OktaDirectoryAwsApplicationMultiAwsTenant",
    OktaDirectoryAwsApplicationSingleAwsTenant = "OktaDirectoryAwsApplicationSingleAwsTenant",
    OktaDirectoryAwsApplicationUserAssignment = "OktaDirectoryAwsApplicationUserAssignment",
    OktaDirectoryAwsGenericApplication = "OktaDirectoryAwsGenericApplication",
    OktaDirectoryAwsGenericApplicationGroupAssignment = "OktaDirectoryAwsGenericApplicationGroupAssignment",
    OktaDirectoryAwsGenericApplicationModel = "OktaDirectoryAwsGenericApplicationModel",
    OktaDirectoryAwsGenericApplicationUserAssignment = "OktaDirectoryAwsGenericApplicationUserAssignment",
    OktaDirectoryAwsSsoApplication = "OktaDirectoryAwsSsoApplication",
    OktaDirectoryAwsSsoApplicationGroupAssignment = "OktaDirectoryAwsSsoApplicationGroupAssignment",
    OktaDirectoryAwsSsoApplicationModel = "OktaDirectoryAwsSsoApplicationModel",
    OktaDirectoryAwsSsoApplicationUserAssignment = "OktaDirectoryAwsSsoApplicationUserAssignment",
    OktaDirectoryEntity = "OktaDirectoryEntity",
    OktaDirectoryEntityModel = "OktaDirectoryEntityModel",
    OktaDirectoryEntityModelFilters = "OktaDirectoryEntityModelFilters",
    OktaDirectoryEntityTypeMetadata = "OktaDirectoryEntityTypeMetadata",
    OktaDirectoryGroup = "OktaDirectoryGroup",
    OktaDirectoryGroupMembershipEligibilityConfiguration = "OktaDirectoryGroupMembershipEligibilityConfiguration",
    OktaDirectoryGroupMembershipRequest = "OktaDirectoryGroupMembershipRequest",
    OktaDirectoryGroupModel = "OktaDirectoryGroupModel",
    OktaDirectoryGroupType = "OktaDirectoryGroupType",
    OktaDirectoryPrincipal = "OktaDirectoryPrincipal",
    OktaDirectoryPrincipalModel = "OktaDirectoryPrincipalModel",
    OktaDirectoryUser = "OktaDirectoryUser",
    OktaDirectoryUserModel = "OktaDirectoryUserModel",
    OktaDirectoryUserModelFilters = "OktaDirectoryUserModelFilters",
    OktaDirectoryUserProfile = "OktaDirectoryUserProfile",
    OktaDirectoryUserStatus = "OktaDirectoryUserStatus",
    OktaEntity = "OktaEntity",
    OktaEntityModel = "OktaEntityModel",
    OktaEntityProfile = "OktaEntityProfile",
    OktaFolderConfiguration = "OktaFolderConfiguration",
    OktaTenantAuditEvent = "OktaTenantAuditEvent",
    OktaTenantConfiguration = "OktaTenantConfiguration",
    OktaTenantCreationAuditEvent = "OktaTenantCreationAuditEvent",
    OktaTenantDeletionAuditEvent = "OktaTenantDeletionAuditEvent",
    OktaTenantModel = "OktaTenantModel",
    OktaTenantModelStatus = "OktaTenantModelStatus",
    OktaTenantState = "OktaTenantState",
    OktaTenantStateMonitoring = "OktaTenantStateMonitoring",
    OktaTenantStateMonitoringPermissionManagementIssue = "OktaTenantStateMonitoringPermissionManagementIssue",
    OktaTenantUpdateAuditEvent = "OktaTenantUpdateAuditEvent",
    OneLoginClientData = "OneLoginClientData",
    OneLoginDirectoryApplication = "OneLoginDirectoryApplication",
    OneLoginDirectoryApplicationModel = "OneLoginDirectoryApplicationModel",
    OneLoginDirectoryApplicationParameter = "OneLoginDirectoryApplicationParameter",
    OneLoginDirectoryApplicationParameterTransformationType = "OneLoginDirectoryApplicationParameterTransformationType",
    OneLoginDirectoryApplicationParameterType = "OneLoginDirectoryApplicationParameterType",
    OneLoginDirectoryApplicationRule = "OneLoginDirectoryApplicationRule",
    OneLoginDirectoryApplicationRuleAction = "OneLoginDirectoryApplicationRuleAction",
    OneLoginDirectoryApplicationRuleActionType = "OneLoginDirectoryApplicationRuleActionType",
    OneLoginDirectoryApplicationRuleCondition = "OneLoginDirectoryApplicationRuleCondition",
    OneLoginDirectoryApplicationRuleConditionMatchType = "OneLoginDirectoryApplicationRuleConditionMatchType",
    OneLoginDirectoryApplicationRuleConditionOperator = "OneLoginDirectoryApplicationRuleConditionOperator",
    OneLoginDirectoryApplicationRuleConditionSource = "OneLoginDirectoryApplicationRuleConditionSource",
    OneLoginDirectoryApplicationType = "OneLoginDirectoryApplicationType",
    OneLoginDirectoryEntity = "OneLoginDirectoryEntity",
    OneLoginDirectoryEntityModel = "OneLoginDirectoryEntityModel",
    OneLoginDirectoryEntityTypeMetadata = "OneLoginDirectoryEntityTypeMetadata",
    OneLoginDirectoryGroup = "OneLoginDirectoryGroup",
    OneLoginDirectoryGroupModel = "OneLoginDirectoryGroupModel",
    OneLoginDirectoryPrincipal = "OneLoginDirectoryPrincipal",
    OneLoginDirectoryPrincipalModel = "OneLoginDirectoryPrincipalModel",
    OneLoginDirectoryRole = "OneLoginDirectoryRole",
    OneLoginDirectoryRoleAssignmentEligibilityConfiguration = "OneLoginDirectoryRoleAssignmentEligibilityConfiguration",
    OneLoginDirectoryRoleAssignmentEligibilityModel = "OneLoginDirectoryRoleAssignmentEligibilityModel",
    OneLoginDirectoryRoleAssignmentRequest = "OneLoginDirectoryRoleAssignmentRequest",
    OneLoginDirectoryRoleAssignmentRequestActivationFailureData = "OneLoginDirectoryRoleAssignmentRequestActivationFailureData",
    OneLoginDirectoryRoleModel = "OneLoginDirectoryRoleModel",
    OneLoginDirectoryUser = "OneLoginDirectoryUser",
    OneLoginDirectoryUserModel = "OneLoginDirectoryUserModel",
    OneLoginDirectoryUserModelFilters = "OneLoginDirectoryUserModelFilters",
    OneLoginDirectoryUserProfile = "OneLoginDirectoryUserProfile",
    OneLoginDirectoryUserProvisioningState = "OneLoginDirectoryUserProvisioningState",
    OneLoginDirectoryUserStatus = "OneLoginDirectoryUserStatus",
    OneLoginEntity = "OneLoginEntity",
    OneLoginEntityModel = "OneLoginEntityModel",
    OneLoginEntityProfile = "OneLoginEntityProfile",
    OneLoginFolderConfiguration = "OneLoginFolderConfiguration",
    OneLoginTenantAuditEvent = "OneLoginTenantAuditEvent",
    OneLoginTenantConfiguration = "OneLoginTenantConfiguration",
    OneLoginTenantConfigurationClientData = "OneLoginTenantConfigurationClientData",
    OneLoginTenantCreationAuditEvent = "OneLoginTenantCreationAuditEvent",
    OneLoginTenantDeletionAuditEvent = "OneLoginTenantDeletionAuditEvent",
    OneLoginTenantModel = "OneLoginTenantModel",
    OneLoginTenantModelStatus = "OneLoginTenantModelStatus",
    OneLoginTenantState = "OneLoginTenantState",
    OneLoginTenantStateMonitoring = "OneLoginTenantStateMonitoring",
    OneLoginTenantStateMonitoringPermissionManagementIssue = "OneLoginTenantStateMonitoringPermissionManagementIssue",
    OneLoginTenantUpdateAuditEvent = "OneLoginTenantUpdateAuditEvent",
    OpContainerImage = "OpContainerImage",
    OpContainerImageModel = "OpContainerImageModel",
    OpContainerImageModelFilters = "OpContainerImageModelFilters",
    OpenShiftBuildConfigData = "OpenShiftBuildConfigData",
    OpenShiftCatalogSourceData = "OpenShiftCatalogSourceData",
    OpenShiftDeploymentConfigData = "OpenShiftDeploymentConfigData",
    OpenShiftImageStreamData = "OpenShiftImageStreamData",
    OpenShiftProjectData = "OpenShiftProjectData",
    OpenShiftRouteData = "OpenShiftRouteData",
    OpenShiftSecurityContextConstraintData = "OpenShiftSecurityContextConstraintData",
    OpenShiftTemplateData = "OpenShiftTemplateData",
    OpEntity = "OpEntity",
    OpEntityModel = "OpEntityModel",
    OpEntityTypeMetadata = "OpEntityTypeMetadata",
    OperatingSystemArchitecture = "OperatingSystemArchitecture",
    OperatingSystemDistribution = "OperatingSystemDistribution",
    OperatingSystemRiskPolicyType = "OperatingSystemRiskPolicyType",
    OperatingSystemType = "OperatingSystemType",
    OpFolderConfiguration = "OpFolderConfiguration",
    OpKubernetesClusterRole = "OpKubernetesClusterRole",
    OpKubernetesClusterRoleBinding = "OpKubernetesClusterRoleBinding",
    OpKubernetesClusterRoleBindingModel = "OpKubernetesClusterRoleBindingModel",
    OpKubernetesClusterRoleModel = "OpKubernetesClusterRoleModel",
    OpKubernetesConfigMap = "OpKubernetesConfigMap",
    OpKubernetesConfigMapModel = "OpKubernetesConfigMapModel",
    OpKubernetesCronJob = "OpKubernetesCronJob",
    OpKubernetesCronJobModel = "OpKubernetesCronJobModel",
    OpKubernetesDaemonSet = "OpKubernetesDaemonSet",
    OpKubernetesDaemonSetModel = "OpKubernetesDaemonSetModel",
    OpKubernetesDeployment = "OpKubernetesDeployment",
    OpKubernetesDeploymentModel = "OpKubernetesDeploymentModel",
    OpKubernetesGroup = "OpKubernetesGroup",
    OpKubernetesGroupModel = "OpKubernetesGroupModel",
    OpKubernetesIngress = "OpKubernetesIngress",
    OpKubernetesIngressClass = "OpKubernetesIngressClass",
    OpKubernetesIngressClassModel = "OpKubernetesIngressClassModel",
    OpKubernetesIngressModel = "OpKubernetesIngressModel",
    OpKubernetesJob = "OpKubernetesJob",
    OpKubernetesJobModel = "OpKubernetesJobModel",
    OpKubernetesNamespace = "OpKubernetesNamespace",
    OpKubernetesNamespaceModel = "OpKubernetesNamespaceModel",
    OpKubernetesNamespaceResource = "OpKubernetesNamespaceResource",
    OpKubernetesNamespaceResourceBase = "OpKubernetesNamespaceResourceBase",
    OpKubernetesNamespaceResourceModel = "OpKubernetesNamespaceResourceModel",
    OpKubernetesNamespaceRole = "OpKubernetesNamespaceRole",
    OpKubernetesNamespaceRoleBinding = "OpKubernetesNamespaceRoleBinding",
    OpKubernetesNamespaceRoleBindingModel = "OpKubernetesNamespaceRoleBindingModel",
    OpKubernetesNamespaceRoleModel = "OpKubernetesNamespaceRoleModel",
    OpKubernetesNetworkPolicy = "OpKubernetesNetworkPolicy",
    OpKubernetesNetworkPolicyModel = "OpKubernetesNetworkPolicyModel",
    OpKubernetesNode = "OpKubernetesNode",
    OpKubernetesNodeModel = "OpKubernetesNodeModel",
    OpKubernetesPersistentVolume = "OpKubernetesPersistentVolume",
    OpKubernetesPersistentVolumeModel = "OpKubernetesPersistentVolumeModel",
    OpKubernetesPod = "OpKubernetesPod",
    OpKubernetesPodModel = "OpKubernetesPodModel",
    OpKubernetesPodSecurityPolicy = "OpKubernetesPodSecurityPolicy",
    OpKubernetesPodSecurityPolicyModel = "OpKubernetesPodSecurityPolicyModel",
    OpKubernetesPodTemplate = "OpKubernetesPodTemplate",
    OpKubernetesPodTemplateModel = "OpKubernetesPodTemplateModel",
    OpKubernetesReplicaSet = "OpKubernetesReplicaSet",
    OpKubernetesReplicaSetModel = "OpKubernetesReplicaSetModel",
    OpKubernetesResource = "OpKubernetesResource",
    OpKubernetesResourceBase = "OpKubernetesResourceBase",
    OpKubernetesResourceModel = "OpKubernetesResourceModel",
    OpKubernetesResourceTypeMetadata = "OpKubernetesResourceTypeMetadata",
    OpKubernetesService = "OpKubernetesService",
    OpKubernetesServiceAccount = "OpKubernetesServiceAccount",
    OpKubernetesServiceAccountModel = "OpKubernetesServiceAccountModel",
    OpKubernetesServiceModel = "OpKubernetesServiceModel",
    OpKubernetesStatefulSet = "OpKubernetesStatefulSet",
    OpKubernetesStatefulSetModel = "OpKubernetesStatefulSetModel",
    OpKubernetesUser = "OpKubernetesUser",
    OpKubernetesUserModel = "OpKubernetesUserModel",
    OpKubernetesWorkloadResource = "OpKubernetesWorkloadResource",
    OpKubernetesWorkloadResourceBase = "OpKubernetesWorkloadResourceBase",
    OpKubernetesWorkloadResourceModel = "OpKubernetesWorkloadResourceModel",
    OpOpenShiftBuildConfig = "OpOpenShiftBuildConfig",
    OpOpenShiftBuildConfigModel = "OpOpenShiftBuildConfigModel",
    OpOpenShiftCatalogSource = "OpOpenShiftCatalogSource",
    OpOpenShiftCatalogSourceModel = "OpOpenShiftCatalogSourceModel",
    OpOpenShiftDeploymentConfig = "OpOpenShiftDeploymentConfig",
    OpOpenShiftDeploymentConfigModel = "OpOpenShiftDeploymentConfigModel",
    OpOpenShiftImageStream = "OpOpenShiftImageStream",
    OpOpenShiftImageStreamModel = "OpOpenShiftImageStreamModel",
    OpOpenShiftProject = "OpOpenShiftProject",
    OpOpenShiftProjectModel = "OpOpenShiftProjectModel",
    OpOpenShiftRoute = "OpOpenShiftRoute",
    OpOpenShiftRouteModel = "OpOpenShiftRouteModel",
    OpOpenShiftSecurityContextConstraint = "OpOpenShiftSecurityContextConstraint",
    OpOpenShiftSecurityContextConstraintModel = "OpOpenShiftSecurityContextConstraintModel",
    OpOpenShiftTemplate = "OpOpenShiftTemplate",
    OpOpenShiftTemplateModel = "OpOpenShiftTemplateModel",
    OpResource = "OpResource",
    OpResourceModel = "OpResourceModel",
    OpResourceModelFilters = "OpResourceModelFilters",
    OpTenantAuditEvent = "OpTenantAuditEvent",
    OpTenantConfiguration = "OpTenantConfiguration",
    OpTenantCreationAuditEvent = "OpTenantCreationAuditEvent",
    OpTenantDeletionAuditEvent = "OpTenantDeletionAuditEvent",
    OpTenantModel = "OpTenantModel",
    OpTenantModelStatus = "OpTenantModelStatus",
    OpTenantState = "OpTenantState",
    OpTenantStateMonitoring = "OpTenantStateMonitoring",
    OpTenantUpdateAuditEvent = "OpTenantUpdateAuditEvent",
    OpUnmanagedKubernetesCluster = "OpUnmanagedKubernetesCluster",
    OpUnmanagedKubernetesClusterModel = "OpUnmanagedKubernetesClusterModel",
    OpUnmanagedKubernetesClusterModelFilters = "OpUnmanagedKubernetesClusterModelFilters",
    OpVirtualMachine = "OpVirtualMachine",
    OpVirtualMachineModel = "OpVirtualMachineModel",
    OpVirtualMachineModelFilters = "OpVirtualMachineModelFilters",
    OrchestrationServiceConfiguration = "OrchestrationServiceConfiguration",
    OrchestrationStageState = "OrchestrationStageState",
    OrchestrationStageStateData = "OrchestrationStageStateData",
    OrchestrationType = "OrchestrationType",
    OrchestratorConfigurationSection = "OrchestratorConfigurationSection",
    OrganizationMemberSelection = "OrganizationMemberSelection",
    OrganizationMemberSelectionType = "OrganizationMemberSelectionType",
    OutpostApiServiceConfiguration = "OutpostApiServiceConfiguration",
    PackagesReportDefinitionConfiguration = "PackagesReportDefinitionConfiguration",
    PackageVulnerabilitiesReportDefinitionConfiguration = "PackageVulnerabilitiesReportDefinitionConfiguration",
    PagedItemSelection = "PagedItemSelection",
    PagedItemSelectionType = "PagedItemSelectionType",
    PartialAnonymousIdentity = "PartialAnonymousIdentity",
    PartialAnonymousIdentityModel = "PartialAnonymousIdentityModel",
    PartialEntity = "PartialEntity",
    PartialEntityModel = "PartialEntityModel",
    PartialEntityTypeMetadata = "PartialEntityTypeMetadata",
    PartialIdentity = "PartialIdentity",
    PartialIdentityModel = "PartialIdentityModel",
    PartialPrincipal = "PartialPrincipal",
    PartialUser = "PartialUser",
    PartitionType = "PartitionType",
    PendoConfigurationSection = "PendoConfigurationSection",
    PermissionActionExcessiveness = "PermissionActionExcessiveness",
    PermissionAssignmentEligibilityConfiguration = "PermissionAssignmentEligibilityConfiguration",
    PermissionAssignmentEligibilityModel = "PermissionAssignmentEligibilityModel",
    PermissionAssignmentRequest = "PermissionAssignmentRequest",
    PermissionAssignmentRequestActivationFailureData = "PermissionAssignmentRequestActivationFailureData",
    PermissionEligibilityAuditEvent = "PermissionEligibilityAuditEvent",
    PermissionEligibilityAuditEventAwsSsoPermissionSetAssignmentEligibilityData = "PermissionEligibilityAuditEventAwsSsoPermissionSetAssignmentEligibilityData",
    PermissionEligibilityAuditEventAzureRoleAssignmentEligibilityData = "PermissionEligibilityAuditEventAzureRoleAssignmentEligibilityData",
    PermissionEligibilityAuditEventFilters = "PermissionEligibilityAuditEventFilters",
    PermissionEligibilityAuditEventGcpRoleBindingEligibilityData = "PermissionEligibilityAuditEventGcpRoleBindingEligibilityData",
    PermissionEligibilityAuditEventGroupMembershipEligibilityData = "PermissionEligibilityAuditEventGroupMembershipEligibilityData",
    PermissionEligibilityAuditEventModel = "PermissionEligibilityAuditEventModel",
    PermissionEligibilityAuditEventModelAwsSsoPermissionSetAssignmentEligibilityData = "PermissionEligibilityAuditEventModelAwsSsoPermissionSetAssignmentEligibilityData",
    PermissionEligibilityAuditEventModelAzureRoleAssignmentEligibilityData = "PermissionEligibilityAuditEventModelAzureRoleAssignmentEligibilityData",
    PermissionEligibilityAuditEventModelGcpRoleBindingEligibilityData = "PermissionEligibilityAuditEventModelGcpRoleBindingEligibilityData",
    PermissionEligibilityAuditEventModelGroupMembershipEligibilityData = "PermissionEligibilityAuditEventModelGroupMembershipEligibilityData",
    PermissionEligibilityAuditEventModelOneLoginDirectoryRoleAssignmentEligibilityData = "PermissionEligibilityAuditEventModelOneLoginDirectoryRoleAssignmentEligibilityData",
    PermissionEligibilityAuditEventModelPermissionAssignmentEligibilityData = "PermissionEligibilityAuditEventModelPermissionAssignmentEligibilityData",
    PermissionEligibilityAuditEventModelPermissionEligibilityData = "PermissionEligibilityAuditEventModelPermissionEligibilityData",
    PermissionEligibilityAuditEventOneLoginDirectoryRoleAssignmentEligibilityData = "PermissionEligibilityAuditEventOneLoginDirectoryRoleAssignmentEligibilityData",
    PermissionEligibilityAuditEventPermissionAssignmentEligibilityData = "PermissionEligibilityAuditEventPermissionAssignmentEligibilityData",
    PermissionEligibilityAuditEventPermissionEligibilityData = "PermissionEligibilityAuditEventPermissionEligibilityData",
    PermissionEligibilityConfiguration = "PermissionEligibilityConfiguration",
    PermissionEligibilityConfigurationApproval = "PermissionEligibilityConfigurationApproval",
    PermissionEligibilityCreationAuditEvent = "PermissionEligibilityCreationAuditEvent",
    PermissionEligibilityDeletionAuditEvent = "PermissionEligibilityDeletionAuditEvent",
    PermissionEligibilityModel = "PermissionEligibilityModel",
    PermissionEligibilityState = "PermissionEligibilityState",
    PermissionEligibilityUpdateAuditEvent = "PermissionEligibilityUpdateAuditEvent",
    PermissionEligibilityUpdateAuditEventAwsSsoPermissionSetAssignmentEligibilityChanges = "PermissionEligibilityUpdateAuditEventAwsSsoPermissionSetAssignmentEligibilityChanges",
    PermissionEligibilityUpdateAuditEventAwsSsoPermissionSetAssignmentEligibilityChangesPermissionsChange = "PermissionEligibilityUpdateAuditEventAwsSsoPermissionSetAssignmentEligibilityChangesPermissionsChange",
    PermissionEligibilityUpdateAuditEventAwsSsoPermissionSetAssignmentEligibilityChangesPermissionSetPermissionsChange = "PermissionEligibilityUpdateAuditEventAwsSsoPermissionSetAssignmentEligibilityChangesPermissionSetPermissionsChange",
    PermissionEligibilityUpdateAuditEventAwsSsoPermissionSetAssignmentEligibilityChangesPermissionsTypeChange = "PermissionEligibilityUpdateAuditEventAwsSsoPermissionSetAssignmentEligibilityChangesPermissionsTypeChange",
    PermissionEligibilityUpdateAuditEventAwsSsoPermissionSetAssignmentEligibilityChangesPolicyPermissionsChange = "PermissionEligibilityUpdateAuditEventAwsSsoPermissionSetAssignmentEligibilityChangesPolicyPermissionsChange",
    PermissionEligibilityUpdateAuditEventAzureRoleAssignmentEligibilityChanges = "PermissionEligibilityUpdateAuditEventAzureRoleAssignmentEligibilityChanges",
    PermissionEligibilityUpdateAuditEventGcpRoleBindingEligibilityChanges = "PermissionEligibilityUpdateAuditEventGcpRoleBindingEligibilityChanges",
    PermissionEligibilityUpdateAuditEventGroupMembershipEligibilityChanges = "PermissionEligibilityUpdateAuditEventGroupMembershipEligibilityChanges",
    PermissionEligibilityUpdateAuditEventOneLoginDirectoryRoleAssignmentEligibilityChanges = "PermissionEligibilityUpdateAuditEventOneLoginDirectoryRoleAssignmentEligibilityChanges",
    PermissionEligibilityUpdateAuditEventPermissionAssignmentEligibilityChanges = "PermissionEligibilityUpdateAuditEventPermissionAssignmentEligibilityChanges",
    PermissionEligibilityUpdateAuditEventPermissionEligibilityChanges = "PermissionEligibilityUpdateAuditEventPermissionEligibilityChanges",
    PermissionFilters = "PermissionFilters",
    PermissionManagementAadTenantAuthenticationProviderConfigurationSection = "PermissionManagementAadTenantAuthenticationProviderConfigurationSection",
    PermissionManagementAadTenantAuthenticationProviderConfigurationSectionPartitionData = "PermissionManagementAadTenantAuthenticationProviderConfigurationSectionPartitionData",
    PermissionManagementAuditEvent = "PermissionManagementAuditEvent",
    PermissionManagementAuditEventModel = "PermissionManagementAuditEventModel",
    PermissionManagementControllerApprovePermissionRequestRequest = "PermissionManagementControllerApprovePermissionRequestRequest",
    PermissionManagementControllerApproverUserPermissionRequestFilters = "PermissionManagementControllerApproverUserPermissionRequestFilters",
    PermissionManagementControllerAwsPermissionEligibilityFilters = "PermissionManagementControllerAwsPermissionEligibilityFilters",
    PermissionManagementControllerAzurePermissionEligibilityFilters = "PermissionManagementControllerAzurePermissionEligibilityFilters",
    PermissionManagementControllerCancelPermissionRequestRequest = "PermissionManagementControllerCancelPermissionRequestRequest",
    PermissionManagementControllerGcpPermissionEligibilityFilters = "PermissionManagementControllerGcpPermissionEligibilityFilters",
    PermissionManagementControllerGetAadPermissionEligibilityModelPageRequest = "PermissionManagementControllerGetAadPermissionEligibilityModelPageRequest",
    PermissionManagementControllerGetAadTenantOnboardingInfoResponse = "PermissionManagementControllerGetAadTenantOnboardingInfoResponse",
    PermissionManagementControllerGetActivePermissionRequestPrincipalTenantIdToConsoleUrlMapResponse = "PermissionManagementControllerGetActivePermissionRequestPrincipalTenantIdToConsoleUrlMapResponse",
    PermissionManagementControllerGetApproverUserPermissionRequestFiltersResponse = "PermissionManagementControllerGetApproverUserPermissionRequestFiltersResponse",
    PermissionManagementControllerGetApproverUserPermissionRequestModelPageRequest = "PermissionManagementControllerGetApproverUserPermissionRequestModelPageRequest",
    PermissionManagementControllerGetApproverUserPermissionRequestModelPageResponse = "PermissionManagementControllerGetApproverUserPermissionRequestModelPageResponse",
    PermissionManagementControllerGetAwsPermissionEligibilityModelPageRequest = "PermissionManagementControllerGetAwsPermissionEligibilityModelPageRequest",
    PermissionManagementControllerGetAwsPermissionEligibilityModelPageRequestFilters = "PermissionManagementControllerGetAwsPermissionEligibilityModelPageRequestFilters",
    PermissionManagementControllerGetAzurePermissionEligibilityModelPageRequest = "PermissionManagementControllerGetAzurePermissionEligibilityModelPageRequest",
    PermissionManagementControllerGetAzurePermissionEligibilityModelPageRequestFilters = "PermissionManagementControllerGetAzurePermissionEligibilityModelPageRequestFilters",
    PermissionManagementControllerGetGciPermissionEligibilityModelPageRequest = "PermissionManagementControllerGetGciPermissionEligibilityModelPageRequest",
    PermissionManagementControllerGetGcpPermissionEligibilityModelPageRequest = "PermissionManagementControllerGetGcpPermissionEligibilityModelPageRequest",
    PermissionManagementControllerGetGcpPermissionEligibilityModelPageRequestFilters = "PermissionManagementControllerGetGcpPermissionEligibilityModelPageRequestFilters",
    PermissionManagementControllerGetGranteeUserPermissionRequestFiltersResponse = "PermissionManagementControllerGetGranteeUserPermissionRequestFiltersResponse",
    PermissionManagementControllerGetGranteeUserPermissionRequestModelPageRequest = "PermissionManagementControllerGetGranteeUserPermissionRequestModelPageRequest",
    PermissionManagementControllerGetGranteeUserPermissionRequestModelPageResponse = "PermissionManagementControllerGetGranteeUserPermissionRequestModelPageResponse",
    PermissionManagementControllerGetGroupMembershipEligibilityFiltersRequest = "PermissionManagementControllerGetGroupMembershipEligibilityFiltersRequest",
    PermissionManagementControllerGetGroupMembershipEligibilityModelPageRequestFilters = "PermissionManagementControllerGetGroupMembershipEligibilityModelPageRequestFilters",
    PermissionManagementControllerGetGroupMembershipPermissionEligibilityModelPageRequest = "PermissionManagementControllerGetGroupMembershipPermissionEligibilityModelPageRequest",
    PermissionManagementControllerGetInfoResponse = "PermissionManagementControllerGetInfoResponse",
    PermissionManagementControllerGetOktaPermissionEligibilityModelPageRequest = "PermissionManagementControllerGetOktaPermissionEligibilityModelPageRequest",
    PermissionManagementControllerGetOneLoginEligibilityFiltersRequest = "PermissionManagementControllerGetOneLoginEligibilityFiltersRequest",
    PermissionManagementControllerGetOneLoginEligibilityModelPageRequestFilters = "PermissionManagementControllerGetOneLoginEligibilityModelPageRequestFilters",
    PermissionManagementControllerGetOneLoginPermissionEligibilityModelPageRequest = "PermissionManagementControllerGetOneLoginPermissionEligibilityModelPageRequest",
    PermissionManagementControllerGetPermissionAssignmentEligibilityFiltersRequest = "PermissionManagementControllerGetPermissionAssignmentEligibilityFiltersRequest",
    PermissionManagementControllerGetPermissionAssignmentEligibilityModelPageRequest = "PermissionManagementControllerGetPermissionAssignmentEligibilityModelPageRequest",
    PermissionManagementControllerGetPermissionEligibilityFiltersRequest = "PermissionManagementControllerGetPermissionEligibilityFiltersRequest",
    PermissionManagementControllerGetPermissionEligibilityFiltersResponse = "PermissionManagementControllerGetPermissionEligibilityFiltersResponse",
    PermissionManagementControllerGetPermissionEligibilityModelPageRequest = "PermissionManagementControllerGetPermissionEligibilityModelPageRequest",
    PermissionManagementControllerGetPermissionEligibilityModelPageRequestFilters = "PermissionManagementControllerGetPermissionEligibilityModelPageRequestFilters",
    PermissionManagementControllerGetPermissionEligibilityModelPageRequestProperty = "PermissionManagementControllerGetPermissionEligibilityModelPageRequestProperty",
    PermissionManagementControllerGetPermissionEligibilityModelPageRequestSort = "PermissionManagementControllerGetPermissionEligibilityModelPageRequestSort",
    PermissionManagementControllerGetPermissionEligibilityModelPageResponse = "PermissionManagementControllerGetPermissionEligibilityModelPageResponse",
    PermissionManagementControllerGetPermissionEligibilityPrincipalOverlapRequest = "PermissionManagementControllerGetPermissionEligibilityPrincipalOverlapRequest",
    PermissionManagementControllerGetPermissionEligibilityPrincipalOverlapResponse = "PermissionManagementControllerGetPermissionEligibilityPrincipalOverlapResponse",
    PermissionManagementControllerGetPermissionEligibilityTenantIdsRequest = "PermissionManagementControllerGetPermissionEligibilityTenantIdsRequest",
    PermissionManagementControllerGetPermissionEligibilityTenantIdsResponse = "PermissionManagementControllerGetPermissionEligibilityTenantIdsResponse",
    PermissionManagementControllerGetPingIdentityPermissionEligibilityModelPageRequest = "PermissionManagementControllerGetPingIdentityPermissionEligibilityModelPageRequest",
    PermissionManagementControllerGetUserInfoResponse = "PermissionManagementControllerGetUserInfoResponse",
    PermissionManagementControllerGetUserPermissionEligibilityIdsResponse = "PermissionManagementControllerGetUserPermissionEligibilityIdsResponse",
    PermissionManagementControllerGetUserPrincipalsResponse = "PermissionManagementControllerGetUserPrincipalsResponse",
    PermissionManagementControllerGranteeUserPermissionRequestFilters = "PermissionManagementControllerGranteeUserPermissionRequestFilters",
    PermissionManagementControllerGroupMembershipEligibilityFilters = "PermissionManagementControllerGroupMembershipEligibilityFilters",
    PermissionManagementControllerGroupMembershipEligibilityRequestScope = "PermissionManagementControllerGroupMembershipEligibilityRequestScope",
    PermissionManagementControllerInsertGroupMembershipRequestRequest = "PermissionManagementControllerInsertGroupMembershipRequestRequest",
    PermissionManagementControllerInsertOneLoginDirectoryRoleAssignmentRequestRequest = "PermissionManagementControllerInsertOneLoginDirectoryRoleAssignmentRequestRequest",
    PermissionManagementControllerInsertPermissionAssignmentRequestRequest = "PermissionManagementControllerInsertPermissionAssignmentRequestRequest",
    PermissionManagementControllerInsertPermissionRequestRequest = "PermissionManagementControllerInsertPermissionRequestRequest",
    PermissionManagementControllerInsertPermissionRequestResponse = "PermissionManagementControllerInsertPermissionRequestResponse",
    PermissionManagementControllerOneLoginEligibilityFilters = "PermissionManagementControllerOneLoginEligibilityFilters",
    PermissionManagementControllerOneLoginEligibilityRequestScope = "PermissionManagementControllerOneLoginEligibilityRequestScope",
    PermissionManagementControllerPermissionAssignmentEligibilityRequestScope = "PermissionManagementControllerPermissionAssignmentEligibilityRequestScope",
    PermissionManagementControllerPermissionEligibilityFilters = "PermissionManagementControllerPermissionEligibilityFilters",
    PermissionManagementControllerPermissionEligibilityRequestScope = "PermissionManagementControllerPermissionEligibilityRequestScope",
    PermissionManagementControllerRejectPermissionRequestRequest = "PermissionManagementControllerRejectPermissionRequestRequest",
    PermissionManagementControllerRevokePermissionRequestRequest = "PermissionManagementControllerRevokePermissionRequestRequest",
    PermissionManagementControllerValidateAwsIamPolicyDocumentRequest = "PermissionManagementControllerValidateAwsIamPolicyDocumentRequest",
    PermissionManagementControllerValidateAwsIamPolicyDocumentResponse = "PermissionManagementControllerValidateAwsIamPolicyDocumentResponse",
    PermissionManagementEntityAttribute = "PermissionManagementEntityAttribute",
    PermissionManagementEntityAttributeType = "PermissionManagementEntityAttributeType",
    PermissionManagerRequestFilters = "PermissionManagerRequestFilters",
    PermissionRequest = "PermissionRequest",
    PermissionRequestActivationAuditEvent = "PermissionRequestActivationAuditEvent",
    PermissionRequestActivationAuditEventModel = "PermissionRequestActivationAuditEventModel",
    PermissionRequestActivationFailureAuditEvent = "PermissionRequestActivationFailureAuditEvent",
    PermissionRequestActivationFailureAuditEventModel = "PermissionRequestActivationFailureAuditEventModel",
    PermissionRequestActivationFailureData = "PermissionRequestActivationFailureData",
    PermissionRequestApprovalAuditEvent = "PermissionRequestApprovalAuditEvent",
    PermissionRequestAuditEvent = "PermissionRequestAuditEvent",
    PermissionRequestAuditEventFilters = "PermissionRequestAuditEventFilters",
    PermissionRequestAuditEventFiltersTenantData = "PermissionRequestAuditEventFiltersTenantData",
    PermissionRequestAuditEventGroupMembershipRequestActivationFailureData = "PermissionRequestAuditEventGroupMembershipRequestActivationFailureData",
    PermissionRequestAuditEventModel = "PermissionRequestAuditEventModel",
    PermissionRequestAuditEventModelGroupMembershipRequestActivationFailureData = "PermissionRequestAuditEventModelGroupMembershipRequestActivationFailureData",
    PermissionRequestAuditEventModelPermissionRequestActivationFailureData = "PermissionRequestAuditEventModelPermissionRequestActivationFailureData",
    PermissionRequestAuditEventModelPermissionRequestData = "PermissionRequestAuditEventModelPermissionRequestData",
    PermissionRequestAuditEventOneLoginDirectoryRoleAssignmentRequestActivationFailureData = "PermissionRequestAuditEventOneLoginDirectoryRoleAssignmentRequestActivationFailureData",
    PermissionRequestAuditEventPermissionAssignmentRequestActivationFailureData = "PermissionRequestAuditEventPermissionAssignmentRequestActivationFailureData",
    PermissionRequestAuditEventPermissionAssignmentRequestActivationFailureDataTenantData = "PermissionRequestAuditEventPermissionAssignmentRequestActivationFailureDataTenantData",
    PermissionRequestAuditEventPermissionAssignmentRequestData = "PermissionRequestAuditEventPermissionAssignmentRequestData",
    PermissionRequestAuditEventPermissionRequestActivationFailureData = "PermissionRequestAuditEventPermissionRequestActivationFailureData",
    PermissionRequestAuditEventPermissionRequestData = "PermissionRequestAuditEventPermissionRequestData",
    PermissionRequestCancellationAuditEvent = "PermissionRequestCancellationAuditEvent",
    PermissionRequestCloseReason = "PermissionRequestCloseReason",
    PermissionRequestCloseReasonAndStatus = "PermissionRequestCloseReasonAndStatus",
    PermissionRequestCreationAuditEvent = "PermissionRequestCreationAuditEvent",
    PermissionRequestExpirationAuditEvent = "PermissionRequestExpirationAuditEvent",
    PermissionRequestExpirationAuditEventModel = "PermissionRequestExpirationAuditEventModel",
    PermissionRequestMailPermissionRequestType = "PermissionRequestMailPermissionRequestType",
    PermissionRequestModel = "PermissionRequestModel",
    PermissionRequestModelActivationFailureData = "PermissionRequestModelActivationFailureData",
    PermissionRequestRejectionAuditEvent = "PermissionRequestRejectionAuditEvent",
    PermissionRequestRequestExpirationAuditEvent = "PermissionRequestRequestExpirationAuditEvent",
    PermissionRequestRevocationAuditEvent = "PermissionRequestRevocationAuditEvent",
    PermissionRequestRevocationAuditEventModel = "PermissionRequestRevocationAuditEventModel",
    PermissionRequestStatus = "PermissionRequestStatus",
    PermissionsModel = "PermissionsModel",
    PermitterPermissionScope = "PermitterPermissionScope",
    PingIdentityClientRegion = "PingIdentityClientRegion",
    PingIdentityDirectoryApplication = "PingIdentityDirectoryApplication",
    PingIdentityDirectoryApplicationGroups = "PingIdentityDirectoryApplicationGroups",
    PingIdentityDirectoryApplicationGroupsCondition = "PingIdentityDirectoryApplicationGroupsCondition",
    PingIdentityDirectoryApplicationModel = "PingIdentityDirectoryApplicationModel",
    PingIdentityDirectoryApplicationParameter = "PingIdentityDirectoryApplicationParameter",
    PingIdentityDirectoryApplicationParameterType = "PingIdentityDirectoryApplicationParameterType",
    PingIdentityDirectoryApplicationType = "PingIdentityDirectoryApplicationType",
    PingIdentityDirectoryEntity = "PingIdentityDirectoryEntity",
    PingIdentityDirectoryEntityModel = "PingIdentityDirectoryEntityModel",
    PingIdentityDirectoryEntityModelFilters = "PingIdentityDirectoryEntityModelFilters",
    PingIdentityDirectoryEntityTypeMetadata = "PingIdentityDirectoryEntityTypeMetadata",
    PingIdentityDirectoryGroup = "PingIdentityDirectoryGroup",
    PingIdentityDirectoryGroupMembershipEligibilityConfiguration = "PingIdentityDirectoryGroupMembershipEligibilityConfiguration",
    PingIdentityDirectoryGroupMembershipRequest = "PingIdentityDirectoryGroupMembershipRequest",
    PingIdentityDirectoryGroupModel = "PingIdentityDirectoryGroupModel",
    PingIdentityDirectoryPrincipal = "PingIdentityDirectoryPrincipal",
    PingIdentityDirectoryPrincipalModel = "PingIdentityDirectoryPrincipalModel",
    PingIdentityDirectoryUser = "PingIdentityDirectoryUser",
    PingIdentityDirectoryUserGroupMembershipType = "PingIdentityDirectoryUserGroupMembershipType",
    PingIdentityDirectoryUserModel = "PingIdentityDirectoryUserModel",
    PingIdentityDirectoryUserModelFilters = "PingIdentityDirectoryUserModelFilters",
    PingIdentityDirectoryUserProfile = "PingIdentityDirectoryUserProfile",
    PingIdentityDirectoryUserStatus = "PingIdentityDirectoryUserStatus",
    PingIdentityEntity = "PingIdentityEntity",
    PingIdentityEntityModel = "PingIdentityEntityModel",
    PingIdentityEntityProfile = "PingIdentityEntityProfile",
    PingIdentityFolderConfiguration = "PingIdentityFolderConfiguration",
    PingIdentityTenantAuditEvent = "PingIdentityTenantAuditEvent",
    PingIdentityTenantConfiguration = "PingIdentityTenantConfiguration",
    PingIdentityTenantCreationAuditEvent = "PingIdentityTenantCreationAuditEvent",
    PingIdentityTenantDeletionAuditEvent = "PingIdentityTenantDeletionAuditEvent",
    PingIdentityTenantModel = "PingIdentityTenantModel",
    PingIdentityTenantModelStatus = "PingIdentityTenantModelStatus",
    PingIdentityTenantState = "PingIdentityTenantState",
    PingIdentityTenantStateMonitoring = "PingIdentityTenantStateMonitoring",
    PingIdentityTenantStateMonitoringPermissionManagementIssue = "PingIdentityTenantStateMonitoringPermissionManagementIssue",
    PingIdentityTenantUpdateAuditEvent = "PingIdentityTenantUpdateAuditEvent",
    Principal = "Principal",
    PrincipalModelAccess = "PrincipalModelAccess",
    PrincipalModelAccessPermissions = "PrincipalModelAccessPermissions",
    PrincipalModelAccessPermissionsPermissions = "PrincipalModelAccessPermissionsPermissions",
    PrincipalModelAccessRisks = "PrincipalModelAccessRisks",
    PrincipalModelAccessScopesActivity = "PrincipalModelAccessScopesActivity",
    PrincipalModelAccessScopesExcessivePermissions = "PrincipalModelAccessScopesExcessivePermissions",
    PrincipalModelAccessScopesPermissions = "PrincipalModelAccessScopesPermissions",
    PrincipalModelAccessScopesPermissionsAccess = "PrincipalModelAccessScopesPermissionsAccess",
    PrincipalModelAccessScopesPermissionStats = "PrincipalModelAccessScopesPermissionStats",
    PrincipalModelAccessScopesRisk = "PrincipalModelAccessScopesRisk",
    PrincipalModelFilters = "PrincipalModelFilters",
    PrincipalRiskCategory = "PrincipalRiskCategory",
    PrincipalSeverePermissionActionRiskType = "PrincipalSeverePermissionActionRiskType",
    PrincipalType = "PrincipalType",
    ProjectAuditEvent = "ProjectAuditEvent",
    ProjectConfiguration = "ProjectConfiguration",
    ProjectConfigurationRule = "ProjectConfigurationRule",
    ProjectConfigurationRuleAzureResourceGroupNamePatternEntityCondition = "ProjectConfigurationRuleAzureResourceGroupNamePatternEntityCondition",
    ProjectConfigurationRuleCondition = "ProjectConfigurationRuleCondition",
    ProjectConfigurationRuleEntityCondition = "ProjectConfigurationRuleEntityCondition",
    ProjectConfigurationRuleNamePatternTenantCondition = "ProjectConfigurationRuleNamePatternTenantCondition",
    ProjectConfigurationRuleTagPatternEntityCondition = "ProjectConfigurationRuleTagPatternEntityCondition",
    ProjectConfigurationRuleTagPatternTenantCondition = "ProjectConfigurationRuleTagPatternTenantCondition",
    ProjectConfigurationRuleTenantCondition = "ProjectConfigurationRuleTenantCondition",
    ProjectControllerGetProjectModelsRequest = "ProjectControllerGetProjectModelsRequest",
    ProjectControllerGetProjectModelsResponse = "ProjectControllerGetProjectModelsResponse",
    ProjectCreationAuditEvent = "ProjectCreationAuditEvent",
    ProjectDeletionAuditEvent = "ProjectDeletionAuditEvent",
    ProjectFolderConfiguration = "ProjectFolderConfiguration",
    ProjectModel = "ProjectModel",
    ProjectState = "ProjectState",
    ProjectStateStatus = "ProjectStateStatus",
    ProjectUpdateAuditEvent = "ProjectUpdateAuditEvent",
    ProtocolType = "ProtocolType",
    ProvisionedManagedDatabaseAnalysisServiceConfiguration = "ProvisionedManagedDatabaseAnalysisServiceConfiguration",
    PublicAccessPreventionType = "PublicAccessPreventionType",
    PublicEntityAttribute = "PublicEntityAttribute",
    PublicEntityRisk = "PublicEntityRisk",
    QRadarDelivery = "QRadarDelivery",
    QRadarDeliveryServer = "QRadarDeliveryServer",
    QRadarServerAuditEvent = "QRadarServerAuditEvent",
    QRadarServerConfiguration = "QRadarServerConfiguration",
    QRadarServerCreationAuditEvent = "QRadarServerCreationAuditEvent",
    QRadarServerDeletionAuditEvent = "QRadarServerDeletionAuditEvent",
    QRadarServerState = "QRadarServerState",
    QRadarServerUpdateAuditEvent = "QRadarServerUpdateAuditEvent",
    RegionLocation = "RegionLocation",
    RegionModel = "RegionModel",
    RelatedPublicComputeAttribute = "RelatedPublicComputeAttribute",
    RelatedWorkloadResourceVulnerabilityAttribute = "RelatedWorkloadResourceVulnerabilityAttribute",
    Report = "Report",
    ReportBase = "ReportBase",
    ReportContentType = "ReportContentType",
    ReportControllerAccessReportRequestDefinition = "ReportControllerAccessReportRequestDefinition",
    ReportControllerAwsAccessReportRequestDefinition = "ReportControllerAwsAccessReportRequestDefinition",
    ReportControllerAzureAccessReportRequestDefinition = "ReportControllerAzureAccessReportRequestDefinition",
    ReportControllerBuiltInComplianceReportRequestDefinition = "ReportControllerBuiltInComplianceReportRequestDefinition",
    ReportControllerCloudRisksReportRequestDefinition = "ReportControllerCloudRisksReportRequestDefinition",
    ReportControllerCodeRisksReportRequestDefinition = "ReportControllerCodeRisksReportRequestDefinition",
    ReportControllerCommonReportRequestDefinition = "ReportControllerCommonReportRequestDefinition",
    ReportControllerComplianceReportRequestDefinition = "ReportControllerComplianceReportRequestDefinition",
    ReportControllerComplianceScopesReportRequestDefinition = "ReportControllerComplianceScopesReportRequestDefinition",
    ReportControllerContainerImagePackageVulnerabilitiesReportRequestDefinition = "ReportControllerContainerImagePackageVulnerabilitiesReportRequestDefinition",
    ReportControllerContainerImagesReportRequestDefinition = "ReportControllerContainerImagesReportRequestDefinition",
    ReportControllerCustomComplianceReportRequestDefinition = "ReportControllerCustomComplianceReportRequestDefinition",
    ReportControllerDeleteReportRequest = "ReportControllerDeleteReportRequest",
    ReportControllerEventsReportRequestDefinition = "ReportControllerEventsReportRequestDefinition",
    ReportControllerGcpAccessReportRequestDefinition = "ReportControllerGcpAccessReportRequestDefinition",
    ReportControllerGenerateReportRequest = "ReportControllerGenerateReportRequest",
    ReportControllerGenerateReportResponse = "ReportControllerGenerateReportResponse",
    ReportControllerGetDashboardReportRequest = "ReportControllerGetDashboardReportRequest",
    ReportControllerGetDashboardReportResponse = "ReportControllerGetDashboardReportResponse",
    ReportControllerGetDashboardReportResponseCloudProviderTenantIdentitySummary = "ReportControllerGetDashboardReportResponseCloudProviderTenantIdentitySummary",
    ReportControllerGetDashboardReportResponseRiskCategorySummary = "ReportControllerGetDashboardReportResponseRiskCategorySummary",
    ReportControllerGetReportFiltersRequest = "ReportControllerGetReportFiltersRequest",
    ReportControllerGetReportFiltersResponse = "ReportControllerGetReportFiltersResponse",
    ReportControllerGetReportPageRequest = "ReportControllerGetReportPageRequest",
    ReportControllerGetReportPageRequestFilters = "ReportControllerGetReportPageRequestFilters",
    ReportControllerGetReportPageResponse = "ReportControllerGetReportPageResponse",
    ReportControllerInsertExportReportDefinitionRequest = "ReportControllerInsertExportReportDefinitionRequest",
    ReportControllerInsertExportReportDefinitionResponse = "ReportControllerInsertExportReportDefinitionResponse",
    ReportControllerInventoryReportRequestDefinition = "ReportControllerInventoryReportRequestDefinition",
    ReportControllerReportRequestDefinition = "ReportControllerReportRequestDefinition",
    ReportControllerRisksReportRequestDefinition = "ReportControllerRisksReportRequestDefinition",
    ReportControllerVirtualMachinePackageVulnerabilitiesReportRequestDefinition = "ReportControllerVirtualMachinePackageVulnerabilitiesReportRequestDefinition",
    ReportControllerVirtualMachinesReportRequestDefinition = "ReportControllerVirtualMachinesReportRequestDefinition",
    ReportControllerWorkloadAnalysisMaliciousFilesReportRequestDefinition = "ReportControllerWorkloadAnalysisMaliciousFilesReportRequestDefinition",
    ReportControllerWorkloadAnalysisPackagesReportRequestDefinition = "ReportControllerWorkloadAnalysisPackagesReportRequestDefinition",
    ReportControllerWorkloadAnalysisPackageVulnerabilitiesReportRequestDefinition = "ReportControllerWorkloadAnalysisPackageVulnerabilitiesReportRequestDefinition",
    ReportControllerWorkloadAnalysisReportRequestDefinition = "ReportControllerWorkloadAnalysisReportRequestDefinition",
    ReportControllerWorkloadReportRequestDefinition = "ReportControllerWorkloadReportRequestDefinition",
    ReportControllerWorkloadResourcePackageVulnerabilitiesReportRequestDefinition = "ReportControllerWorkloadResourcePackageVulnerabilitiesReportRequestDefinition",
    ReportControllerWorkloadResourcesReportRequestDefinition = "ReportControllerWorkloadResourcesReportRequestDefinition",
    ReportDefinitionConfiguration = "ReportDefinitionConfiguration",
    ReportScheduleAuditEvent = "ReportScheduleAuditEvent",
    ReportScheduleCadence = "ReportScheduleCadence",
    ReportScheduleCadenceInterval = "ReportScheduleCadenceInterval",
    ReportScheduleConfiguration = "ReportScheduleConfiguration",
    ReportScheduleCreationAuditEvent = "ReportScheduleCreationAuditEvent",
    ReportScheduleDeletionAuditEvent = "ReportScheduleDeletionAuditEvent",
    ReportScheduleUpdateAuditEvent = "ReportScheduleUpdateAuditEvent",
    ReportType = "ReportType",
    ResourceAnalysisManagementServiceConfiguration = "ResourceAnalysisManagementServiceConfiguration",
    ResourceAnalysisScanManagerConfigurationSection = "ResourceAnalysisScanManagerConfigurationSection",
    ResourceAnalysisScanningServiceConfiguration = "ResourceAnalysisScanningServiceConfiguration",
    ResourceCodeResourceNotExistsRiskPolicyConfiguration = "ResourceCodeResourceNotExistsRiskPolicyConfiguration",
    ResourceOwnerMailDelivery = "ResourceOwnerMailDelivery",
    ResourceTag = "ResourceTag",
    ResourceTagNotExistsRiskPolicyConfiguration = "ResourceTagNotExistsRiskPolicyConfiguration",
    ResourceTagPattern = "ResourceTagPattern",
    ResourceTagSearchableData = "ResourceTagSearchableData",
    Response = "Response",
    Risk = "Risk",
    RiskAuditEvent = "RiskAuditEvent",
    RiskAutomationRuleConfiguration = "RiskAutomationRuleConfiguration",
    RiskAutomationRuleCreationAuditEvent = "RiskAutomationRuleCreationAuditEvent",
    RiskAutomationRuleDeletionAuditEvent = "RiskAutomationRuleDeletionAuditEvent",
    RiskAutomationRuleUpdateAuditEvent = "RiskAutomationRuleUpdateAuditEvent",
    RiskCodeResolutionAuditEvent = "RiskCodeResolutionAuditEvent",
    RiskCombination = "RiskCombination",
    RiskCombinationData = "RiskCombinationData",
    RiskConfiguration = "RiskConfiguration",
    RiskControllerAadDirectoryGroupInactiveRiskPolicyTypeFilters = "RiskControllerAadDirectoryGroupInactiveRiskPolicyTypeFilters",
    RiskControllerAwsBehaviorIdentityRiskPolicyTypeFilters = "RiskControllerAwsBehaviorIdentityRiskPolicyTypeFilters",
    RiskControllerAwsEc2InstanceMetadataServiceVersionRiskPolicyTypeFilters = "RiskControllerAwsEc2InstanceMetadataServiceVersionRiskPolicyTypeFilters",
    RiskControllerAwsEc2NetworkResourceInboundRuleSubnetAnyExistsRiskPolicyTypeFilters = "RiskControllerAwsEc2NetworkResourceInboundRuleSubnetAnyExistsRiskPolicyTypeFilters",
    RiskControllerAwsExcessivePermissionRiskPolicyTypeFilters = "RiskControllerAwsExcessivePermissionRiskPolicyTypeFilters",
    RiskControllerAwsIamGroupInactiveRiskPolicyTypeFilters = "RiskControllerAwsIamGroupInactiveRiskPolicyTypeFilters",
    RiskControllerAwsIamRoleVendorAssumeRolePolicyDocumentExternalIdConditionNotExistRiskPolicyTypeFilters = "RiskControllerAwsIamRoleVendorAssumeRolePolicyDocumentExternalIdConditionNotExistRiskPolicyTypeFilters",
    RiskControllerAwsLambdaFunctionConfigurationPublicAccessExistsRiskPolicyTypeFilters = "RiskControllerAwsLambdaFunctionConfigurationPublicAccessExistsRiskPolicyTypeFilters",
    RiskControllerAwsSecretsManagerSecretRotationDisabledRiskPolicyTypeFilters = "RiskControllerAwsSecretsManagerSecretRotationDisabledRiskPolicyTypeFilters",
    RiskControllerAzureBehaviorIdentityRiskPolicyTypeFilters = "RiskControllerAzureBehaviorIdentityRiskPolicyTypeFilters",
    RiskControllerCloudCommonRiskPolicyTypeFilters = "RiskControllerCloudCommonRiskPolicyTypeFilters",
    RiskControllerCloudRiskModelFilters = "RiskControllerCloudRiskModelFilters",
    RiskControllerCloudRiskPolicyTypeFilters = "RiskControllerCloudRiskPolicyTypeFilters",
    RiskControllerCodeRiskModelFilters = "RiskControllerCodeRiskModelFilters",
    RiskControllerCodeRiskPolicyTypeFilters = "RiskControllerCodeRiskPolicyTypeFilters",
    RiskControllerCustomRiskPolicyTypeFilters = "RiskControllerCustomRiskPolicyTypeFilters",
    RiskControllerDeleteCustomRiskPolicyRequest = "RiskControllerDeleteCustomRiskPolicyRequest",
    RiskControllerExcludeEntityRequest = "RiskControllerExcludeEntityRequest",
    RiskControllerGciDirectoryGroupInactiveRiskPolicyTypeFilters = "RiskControllerGciDirectoryGroupInactiveRiskPolicyTypeFilters",
    RiskControllerGcpBehaviorIdentityRiskPolicyTypeFilters = "RiskControllerGcpBehaviorIdentityRiskPolicyTypeFilters",
    RiskControllerGetBuiltInCloudRiskPolicyModelsRequest = "RiskControllerGetBuiltInCloudRiskPolicyModelsRequest",
    RiskControllerGetBuiltInCodeRiskPolicyModelsRequest = "RiskControllerGetBuiltInCodeRiskPolicyModelsRequest",
    RiskControllerGetBuiltInComplianceDatasResponse = "RiskControllerGetBuiltInComplianceDatasResponse",
    RiskControllerGetBuiltInComplianceDatasResponseComplianceData = "RiskControllerGetBuiltInComplianceDatasResponseComplianceData",
    RiskControllerGetBuiltInComplianceTypeToAnalysisGroupTypeToRiskPolicyConfigurationTypeNamesMapResponse = "RiskControllerGetBuiltInComplianceTypeToAnalysisGroupTypeToRiskPolicyConfigurationTypeNamesMapResponse",
    RiskControllerGetBuiltInKubernetesAdmissionControllerRiskPolicyModelsRequest = "RiskControllerGetBuiltInKubernetesAdmissionControllerRiskPolicyModelsRequest",
    RiskControllerGetBuiltInRiskPolicyModelsRequest = "RiskControllerGetBuiltInRiskPolicyModelsRequest",
    RiskControllerGetBuiltInRiskPolicyModelsResponse = "RiskControllerGetBuiltInRiskPolicyModelsResponse",
    RiskControllerGetBuiltInRiskPolicyModelsResponseData = "RiskControllerGetBuiltInRiskPolicyModelsResponseData",
    RiskControllerGetCloudRiskFilterItemPageRequest = "RiskControllerGetCloudRiskFilterItemPageRequest",
    RiskControllerGetCloudRiskFiltersRequest = "RiskControllerGetCloudRiskFiltersRequest",
    RiskControllerGetCloudRiskGroupsRequest = "RiskControllerGetCloudRiskGroupsRequest",
    RiskControllerGetCloudRiskIdToDataMapRequest = "RiskControllerGetCloudRiskIdToDataMapRequest",
    RiskControllerGetCloudRiskIdToScopeIdentityPermissionsMapRequest = "RiskControllerGetCloudRiskIdToScopeIdentityPermissionsMapRequest",
    RiskControllerGetCloudRiskModelPageRequest = "RiskControllerGetCloudRiskModelPageRequest",
    RiskControllerGetCloudRiskPoliciesRequest = "RiskControllerGetCloudRiskPoliciesRequest",
    RiskControllerGetCloudRiskPolicyModelsRequest = "RiskControllerGetCloudRiskPolicyModelsRequest",
    RiskControllerGetCloudRiskPolicySummaryRequest = "RiskControllerGetCloudRiskPolicySummaryRequest",
    RiskControllerGetCodeRiskFilterItemPageRequest = "RiskControllerGetCodeRiskFilterItemPageRequest",
    RiskControllerGetCodeRiskFiltersRequest = "RiskControllerGetCodeRiskFiltersRequest",
    RiskControllerGetCodeRiskGroupsRequest = "RiskControllerGetCodeRiskGroupsRequest",
    RiskControllerGetCodeRiskIdToDataMapRequest = "RiskControllerGetCodeRiskIdToDataMapRequest",
    RiskControllerGetCodeRiskIdToScopeIdentityPermissionsMapRequest = "RiskControllerGetCodeRiskIdToScopeIdentityPermissionsMapRequest",
    RiskControllerGetCodeRiskModelPageRequest = "RiskControllerGetCodeRiskModelPageRequest",
    RiskControllerGetCodeRiskPoliciesRequest = "RiskControllerGetCodeRiskPoliciesRequest",
    RiskControllerGetCodeRiskPolicyModelsRequest = "RiskControllerGetCodeRiskPolicyModelsRequest",
    RiskControllerGetCodeRiskPolicySummaryRequest = "RiskControllerGetCodeRiskPolicySummaryRequest",
    RiskControllerGetComplianceSectionDatasRequest = "RiskControllerGetComplianceSectionDatasRequest",
    RiskControllerGetComplianceSectionDatasResponse = "RiskControllerGetComplianceSectionDatasResponse",
    RiskControllerGetComplianceSectionDatasResponseRiskPolicyData = "RiskControllerGetComplianceSectionDatasResponseRiskPolicyData",
    RiskControllerGetCustomRiskEntityMatchConditionsMatchEntityModelCountRequest = "RiskControllerGetCustomRiskEntityMatchConditionsMatchEntityModelCountRequest",
    RiskControllerGetCustomRiskEntityMatchConditionsMatchEntityModelCountResponse = "RiskControllerGetCustomRiskEntityMatchConditionsMatchEntityModelCountResponse",
    RiskControllerGetCustomRiskEntityMatchConditionsMatchEntityModelPageRequest = "RiskControllerGetCustomRiskEntityMatchConditionsMatchEntityModelPageRequest",
    RiskControllerGetCustomRiskEntityMatchConditionsMatchEntityModelPageResponse = "RiskControllerGetCustomRiskEntityMatchConditionsMatchEntityModelPageResponse",
    RiskControllerGetCustomRiskPolicyModelsRequest = "RiskControllerGetCustomRiskPolicyModelsRequest",
    RiskControllerGetDeletedRiskRequest = "RiskControllerGetDeletedRiskRequest",
    RiskControllerGetDeletedRiskResponse = "RiskControllerGetDeletedRiskResponse",
    RiskControllerGetKubernetesAdmissionControllerRiskPoliciesRequest = "RiskControllerGetKubernetesAdmissionControllerRiskPoliciesRequest",
    RiskControllerGetKubernetesAdmissionControllerRiskPolicyConfigurationTypeNameToLastViolationEventTimeMapRequest = "RiskControllerGetKubernetesAdmissionControllerRiskPolicyConfigurationTypeNameToLastViolationEventTimeMapRequest",
    RiskControllerGetKubernetesAdmissionControllerRiskPolicyConfigurationTypeNameToLastViolationEventTimeMapResponse = "RiskControllerGetKubernetesAdmissionControllerRiskPolicyConfigurationTypeNameToLastViolationEventTimeMapResponse",
    RiskControllerGetKubernetesAdmissionControllerRiskPolicySummaryRequest = "RiskControllerGetKubernetesAdmissionControllerRiskPolicySummaryRequest",
    RiskControllerGetRiskChangeModelsRequest = "RiskControllerGetRiskChangeModelsRequest",
    RiskControllerGetRiskChangeModelsResponse = "RiskControllerGetRiskChangeModelsResponse",
    RiskControllerGetRiskCodeFileDiffsRequest = "RiskControllerGetRiskCodeFileDiffsRequest",
    RiskControllerGetRiskCodeFileDiffsResponse = "RiskControllerGetRiskCodeFileDiffsResponse",
    RiskControllerGetRiskCodeFileDiffsResponseData = "RiskControllerGetRiskCodeFileDiffsResponseData",
    RiskControllerGetRiskExcludedEntityIdsRequest = "RiskControllerGetRiskExcludedEntityIdsRequest",
    RiskControllerGetRiskExcludedEntityIdsResponse = "RiskControllerGetRiskExcludedEntityIdsResponse",
    RiskControllerGetRiskFilterItemPageRequest = "RiskControllerGetRiskFilterItemPageRequest",
    RiskControllerGetRiskFilterItemPageResponse = "RiskControllerGetRiskFilterItemPageResponse",
    RiskControllerGetRiskFilterItemPageResponseBase = "RiskControllerGetRiskFilterItemPageResponseBase",
    RiskControllerGetRiskFiltersRequest = "RiskControllerGetRiskFiltersRequest",
    RiskControllerGetRiskFiltersResponse = "RiskControllerGetRiskFiltersResponse",
    RiskControllerGetRiskGroupsRequest = "RiskControllerGetRiskGroupsRequest",
    RiskControllerGetRiskGroupsRequestType = "RiskControllerGetRiskGroupsRequestType",
    RiskControllerGetRiskGroupsResponse = "RiskControllerGetRiskGroupsResponse",
    RiskControllerGetRiskGroupsResponseRiskGroup = "RiskControllerGetRiskGroupsResponseRiskGroup",
    RiskControllerGetRiskGroupsResponseRiskPolicyTypeGroup = "RiskControllerGetRiskGroupsResponseRiskPolicyTypeGroup",
    RiskControllerGetRiskGroupsResponseRiskTenantGroup = "RiskControllerGetRiskGroupsResponseRiskTenantGroup",
    RiskControllerGetRiskGroupsResponseRiskTypeGroup = "RiskControllerGetRiskGroupsResponseRiskTypeGroup",
    RiskControllerGetRiskIdToDataMapRequest = "RiskControllerGetRiskIdToDataMapRequest",
    RiskControllerGetRiskIdToDataMapResponse = "RiskControllerGetRiskIdToDataMapResponse",
    RiskControllerGetRiskIdToDataMapResponseData = "RiskControllerGetRiskIdToDataMapResponseData",
    RiskControllerGetRiskIdToJiraIssuesMapRequest = "RiskControllerGetRiskIdToJiraIssuesMapRequest",
    RiskControllerGetRiskIdToJiraIssuesMapResponse = "RiskControllerGetRiskIdToJiraIssuesMapResponse",
    RiskControllerGetRiskIdToScopeIdentityPermissionsMapRequest = "RiskControllerGetRiskIdToScopeIdentityPermissionsMapRequest",
    RiskControllerGetRiskIdToScopeIdentityPermissionsMapResponse = "RiskControllerGetRiskIdToScopeIdentityPermissionsMapResponse",
    RiskControllerGetRiskIdToServiceNowIncidentsMapRequest = "RiskControllerGetRiskIdToServiceNowIncidentsMapRequest",
    RiskControllerGetRiskIdToServiceNowIncidentsMapResponse = "RiskControllerGetRiskIdToServiceNowIncidentsMapResponse",
    RiskControllerGetRiskModelPageRequest = "RiskControllerGetRiskModelPageRequest",
    RiskControllerGetRiskModelPageResponse = "RiskControllerGetRiskModelPageResponse",
    RiskControllerGetRiskModelRequest = "RiskControllerGetRiskModelRequest",
    RiskControllerGetRiskModelResponse = "RiskControllerGetRiskModelResponse",
    RiskControllerGetRiskModelsRequest = "RiskControllerGetRiskModelsRequest",
    RiskControllerGetRiskModelsResponse = "RiskControllerGetRiskModelsResponse",
    RiskControllerGetRiskPoliciesRequest = "RiskControllerGetRiskPoliciesRequest",
    RiskControllerGetRiskPoliciesResponse = "RiskControllerGetRiskPoliciesResponse",
    RiskControllerGetRiskPoliciesResponseCloudBuiltInPolicy = "RiskControllerGetRiskPoliciesResponseCloudBuiltInPolicy",
    RiskControllerGetRiskPoliciesResponseCloudCustomPolicy = "RiskControllerGetRiskPoliciesResponseCloudCustomPolicy",
    RiskControllerGetRiskPoliciesResponseCloudPolicy = "RiskControllerGetRiskPoliciesResponseCloudPolicy",
    RiskControllerGetRiskPoliciesResponseCodePolicy = "RiskControllerGetRiskPoliciesResponseCodePolicy",
    RiskControllerGetRiskPoliciesResponseKubernetesAdmissionControllerBuiltInPolicy = "RiskControllerGetRiskPoliciesResponseKubernetesAdmissionControllerBuiltInPolicy",
    RiskControllerGetRiskPoliciesResponseKubernetesAdmissionControllerPolicy = "RiskControllerGetRiskPoliciesResponseKubernetesAdmissionControllerPolicy",
    RiskControllerGetRiskPoliciesResponsePolicy = "RiskControllerGetRiskPoliciesResponsePolicy",
    RiskControllerGetRiskPolicyAnalyzedEntityCountRequest = "RiskControllerGetRiskPolicyAnalyzedEntityCountRequest",
    RiskControllerGetRiskPolicyAnalyzedEntityCountRequestRiskPolicyData = "RiskControllerGetRiskPolicyAnalyzedEntityCountRequestRiskPolicyData",
    RiskControllerGetRiskPolicyAnalyzedEntityCountResponse = "RiskControllerGetRiskPolicyAnalyzedEntityCountResponse",
    RiskControllerGetRiskPolicyAnalyzedEntityCountResponseRiskPolicyData = "RiskControllerGetRiskPolicyAnalyzedEntityCountResponseRiskPolicyData",
    RiskControllerGetRiskPolicyExcludedEntityIdsRequest = "RiskControllerGetRiskPolicyExcludedEntityIdsRequest",
    RiskControllerGetRiskPolicyExcludedEntityIdsResponse = "RiskControllerGetRiskPolicyExcludedEntityIdsResponse",
    RiskControllerGetRiskPolicyFailedEntityCountRequest = "RiskControllerGetRiskPolicyFailedEntityCountRequest",
    RiskControllerGetRiskPolicyFailedEntityCountResponse = "RiskControllerGetRiskPolicyFailedEntityCountResponse",
    RiskControllerGetRiskPolicyFailedEntityIdsRequest = "RiskControllerGetRiskPolicyFailedEntityIdsRequest",
    RiskControllerGetRiskPolicyFailedEntityIdsResponse = "RiskControllerGetRiskPolicyFailedEntityIdsResponse",
    RiskControllerGetRiskPolicyModelsRequest = "RiskControllerGetRiskPolicyModelsRequest",
    RiskControllerGetRiskPolicyModelsRequestBase = "RiskControllerGetRiskPolicyModelsRequestBase",
    RiskControllerGetRiskPolicyModelsResponse = "RiskControllerGetRiskPolicyModelsResponse",
    RiskControllerGetRiskPolicySummaryRequest = "RiskControllerGetRiskPolicySummaryRequest",
    RiskControllerGetRiskPolicySummaryResponse = "RiskControllerGetRiskPolicySummaryResponse",
    RiskControllerGetRiskPolicyTypeGroupCloudRiskFiltersRequest = "RiskControllerGetRiskPolicyTypeGroupCloudRiskFiltersRequest",
    RiskControllerGetRiskPolicyTypeGroupCloudRiskIdToScopeIdentityPermissionsMapRequest = "RiskControllerGetRiskPolicyTypeGroupCloudRiskIdToScopeIdentityPermissionsMapRequest",
    RiskControllerGetRiskPolicyTypeGroupCloudRiskModelPageRequest = "RiskControllerGetRiskPolicyTypeGroupCloudRiskModelPageRequest",
    RiskControllerGetRiskPolicyTypeGroupCodeRiskFiltersRequest = "RiskControllerGetRiskPolicyTypeGroupCodeRiskFiltersRequest",
    RiskControllerGetRiskPolicyTypeGroupCodeRiskIdToScopeIdentityPermissionsMapRequest = "RiskControllerGetRiskPolicyTypeGroupCodeRiskIdToScopeIdentityPermissionsMapRequest",
    RiskControllerGetRiskPolicyTypeGroupCodeRiskModelPageRequest = "RiskControllerGetRiskPolicyTypeGroupCodeRiskModelPageRequest",
    RiskControllerGetRiskPolicyTypeGroupFilterItemPageRequest = "RiskControllerGetRiskPolicyTypeGroupFilterItemPageRequest",
    RiskControllerGetRiskPolicyTypeGroupFilterItemPageRequestProperty = "RiskControllerGetRiskPolicyTypeGroupFilterItemPageRequestProperty",
    RiskControllerGetRiskPolicyTypeGroupFilterItemPageResponse = "RiskControllerGetRiskPolicyTypeGroupFilterItemPageResponse",
    RiskControllerGetRiskPolicyTypeGroupFilterItemPageResponseBase = "RiskControllerGetRiskPolicyTypeGroupFilterItemPageResponseBase",
    RiskControllerGetRiskPolicyTypeGroupFiltersRequest = "RiskControllerGetRiskPolicyTypeGroupFiltersRequest",
    RiskControllerGetRiskPolicyTypeGroupFiltersResponse = "RiskControllerGetRiskPolicyTypeGroupFiltersResponse",
    RiskControllerGetRiskPolicyTypeGroupRiskIdToScopeIdentityPermissionsMapRequest = "RiskControllerGetRiskPolicyTypeGroupRiskIdToScopeIdentityPermissionsMapRequest",
    RiskControllerGetRiskPolicyTypeGroupRiskModelPageRequest = "RiskControllerGetRiskPolicyTypeGroupRiskModelPageRequest",
    RiskControllerGetRiskPolicyTypeMetadatasResponse = "RiskControllerGetRiskPolicyTypeMetadatasResponse",
    RiskControllerGetRiskTypeMetadataModelsResponse = "RiskControllerGetRiskTypeMetadataModelsResponse",
    RiskControllerGetScopeGroupCloudRiskIdToScopeIdentityPermissionsMapRequest = "RiskControllerGetScopeGroupCloudRiskIdToScopeIdentityPermissionsMapRequest",
    RiskControllerGetScopeGroupCodeRiskIdToScopeIdentityPermissionsMapRequest = "RiskControllerGetScopeGroupCodeRiskIdToScopeIdentityPermissionsMapRequest",
    RiskControllerGetScopeGroupRiskIdToScopeIdentityPermissionsMapRequest = "RiskControllerGetScopeGroupRiskIdToScopeIdentityPermissionsMapRequest",
    RiskControllerGetTenantGroupCloudRiskModelPageRequest = "RiskControllerGetTenantGroupCloudRiskModelPageRequest",
    RiskControllerGetTenantGroupCodeRiskModelPageRequest = "RiskControllerGetTenantGroupCodeRiskModelPageRequest",
    RiskControllerGetTenantGroupRiskModelPageRequest = "RiskControllerGetTenantGroupRiskModelPageRequest",
    RiskControllerInsertAwsEc2SecurityGroupAllowedInboundRuleRiskPolicyRequest = "RiskControllerInsertAwsEc2SecurityGroupAllowedInboundRuleRiskPolicyRequest",
    RiskControllerInsertAwsEc2SecurityGroupInboundRuleRiskPolicyRequest = "RiskControllerInsertAwsEc2SecurityGroupInboundRuleRiskPolicyRequest",
    RiskControllerInsertAwsEc2SecurityGroupManagementRiskPolicyRequest = "RiskControllerInsertAwsEc2SecurityGroupManagementRiskPolicyRequest",
    RiskControllerInsertAwsEc2SecurityGroupNotAllowedInboundRuleRiskPolicyRequest = "RiskControllerInsertAwsEc2SecurityGroupNotAllowedInboundRuleRiskPolicyRequest",
    RiskControllerInsertAwsEncryptedResourceKmsEncryptionNotExistsRiskPolicyRequest = "RiskControllerInsertAwsEncryptedResourceKmsEncryptionNotExistsRiskPolicyRequest",
    RiskControllerInsertAwsIamPrincipalCreationRiskPolicyRequest = "RiskControllerInsertAwsIamPrincipalCreationRiskPolicyRequest",
    RiskControllerInsertAwsIamRoleManagementRiskPolicyRequest = "RiskControllerInsertAwsIamRoleManagementRiskPolicyRequest",
    RiskControllerInsertAwsIdentityActivityRiskPolicyRequest = "RiskControllerInsertAwsIdentityActivityRiskPolicyRequest",
    RiskControllerInsertAwsInboundExternalSubnetRiskPolicyRequest = "RiskControllerInsertAwsInboundExternalSubnetRiskPolicyRequest",
    RiskControllerInsertAwsInboundExternalVpcRiskPolicyRequest = "RiskControllerInsertAwsInboundExternalVpcRiskPolicyRequest",
    RiskControllerInsertAwsKmsKeyManagementRiskPolicyRequest = "RiskControllerInsertAwsKmsKeyManagementRiskPolicyRequest",
    RiskControllerInsertAwsNetworkedResourceInboundExternalPortRiskPolicyRequest = "RiskControllerInsertAwsNetworkedResourceInboundExternalPortRiskPolicyRequest",
    RiskControllerInsertAwsPrincipalAllowedPermissionRiskPolicyRequest = "RiskControllerInsertAwsPrincipalAllowedPermissionRiskPolicyRequest",
    RiskControllerInsertAwsPrincipalAllowedResourcePermissionRiskPolicyRequest = "RiskControllerInsertAwsPrincipalAllowedResourcePermissionRiskPolicyRequest",
    RiskControllerInsertAwsPrincipalNotAllowedResourcePermissionRiskPolicyRequest = "RiskControllerInsertAwsPrincipalNotAllowedResourcePermissionRiskPolicyRequest",
    RiskControllerInsertAwsPrincipalResourcePermissionRiskPolicyRequest = "RiskControllerInsertAwsPrincipalResourcePermissionRiskPolicyRequest",
    RiskControllerInsertAwsResourceCodeResourceNotExistsRiskPolicyRequest = "RiskControllerInsertAwsResourceCodeResourceNotExistsRiskPolicyRequest",
    RiskControllerInsertAwsResourceTagNotExistsRiskPolicyRequest = "RiskControllerInsertAwsResourceTagNotExistsRiskPolicyRequest",
    RiskControllerInsertAwsResourceUsageRiskPolicyRequest = "RiskControllerInsertAwsResourceUsageRiskPolicyRequest",
    RiskControllerInsertAwsRoleTemplateMismatchRiskPolicyRequest = "RiskControllerInsertAwsRoleTemplateMismatchRiskPolicyRequest",
    RiskControllerInsertAwsS3BucketManagementRiskPolicyRequest = "RiskControllerInsertAwsS3BucketManagementRiskPolicyRequest",
    RiskControllerInsertAwsSecretsManagerSecretManagementRiskPolicyRequest = "RiskControllerInsertAwsSecretsManagerSecretManagementRiskPolicyRequest",
    RiskControllerInsertAwsSsoPermissionSetAssignmentRiskPolicyRequest = "RiskControllerInsertAwsSsoPermissionSetAssignmentRiskPolicyRequest",
    RiskControllerInsertAwsSsoPermissionSetManagementRiskPolicyRequest = "RiskControllerInsertAwsSsoPermissionSetManagementRiskPolicyRequest",
    RiskControllerInsertAwsSsoPrincipalCreationRiskPolicyRequest = "RiskControllerInsertAwsSsoPrincipalCreationRiskPolicyRequest",
    RiskControllerInsertAzureManagedIdentityNotAllowedResourcePermissionRiskPolicyRequest = "RiskControllerInsertAzureManagedIdentityNotAllowedResourcePermissionRiskPolicyRequest",
    RiskControllerInsertAzurePrincipalAllowedResourcePermissionRiskPolicyRequest = "RiskControllerInsertAzurePrincipalAllowedResourcePermissionRiskPolicyRequest",
    RiskControllerInsertAzurePrincipalNotAllowedResourcePermissionRiskPolicyRequest = "RiskControllerInsertAzurePrincipalNotAllowedResourcePermissionRiskPolicyRequest",
    RiskControllerInsertAzurePrincipalNotAllowedResourceRoleDefinitionRiskPolicyRequest = "RiskControllerInsertAzurePrincipalNotAllowedResourceRoleDefinitionRiskPolicyRequest",
    RiskControllerInsertAzurePrincipalResourcePermissionRiskPolicyRequest = "RiskControllerInsertAzurePrincipalResourcePermissionRiskPolicyRequest",
    RiskControllerInsertAzureResourceCodeResourceNotExistsRiskPolicyRequest = "RiskControllerInsertAzureResourceCodeResourceNotExistsRiskPolicyRequest",
    RiskControllerInsertAzureResourceInboundExternalPortRiskPolicyRequest = "RiskControllerInsertAzureResourceInboundExternalPortRiskPolicyRequest",
    RiskControllerInsertAzureResourceTagNotExistsRiskPolicyRequest = "RiskControllerInsertAzureResourceTagNotExistsRiskPolicyRequest",
    RiskControllerInsertCustomRiskPolicyRequest = "RiskControllerInsertCustomRiskPolicyRequest",
    RiskControllerInsertCustomRiskPolicyResponse = "RiskControllerInsertCustomRiskPolicyResponse",
    RiskControllerInsertGcpEntityInboundExternalPortRiskPolicyRequest = "RiskControllerInsertGcpEntityInboundExternalPortRiskPolicyRequest",
    RiskControllerInsertGcpIdentityActivityRiskPolicyRequest = "RiskControllerInsertGcpIdentityActivityRiskPolicyRequest",
    RiskControllerInsertGcpPrincipalAllowedResourcePermissionRiskPolicyRequest = "RiskControllerInsertGcpPrincipalAllowedResourcePermissionRiskPolicyRequest",
    RiskControllerInsertGcpPrincipalNotAllowedResourcePermissionRiskPolicyRequest = "RiskControllerInsertGcpPrincipalNotAllowedResourcePermissionRiskPolicyRequest",
    RiskControllerInsertGcpPrincipalNotAllowedResourceRoleRiskPolicyRequest = "RiskControllerInsertGcpPrincipalNotAllowedResourceRoleRiskPolicyRequest",
    RiskControllerInsertGcpPrincipalResourcePermissionRiskPolicyRequest = "RiskControllerInsertGcpPrincipalResourcePermissionRiskPolicyRequest",
    RiskControllerInsertGcpResourceCodeResourceNotExistsRiskPolicyRequest = "RiskControllerInsertGcpResourceCodeResourceNotExistsRiskPolicyRequest",
    RiskControllerInsertGcpResourceTagNotExistsRiskPolicyRequest = "RiskControllerInsertGcpResourceTagNotExistsRiskPolicyRequest",
    RiskControllerInsertResourceCodeResourceNotExistsRiskPolicyRequest = "RiskControllerInsertResourceCodeResourceNotExistsRiskPolicyRequest",
    RiskControllerInsertResourceTagNotExistsRiskPolicyRequest = "RiskControllerInsertResourceTagNotExistsRiskPolicyRequest",
    RiskControllerNetworkInboundExternalResourceRiskPolicyTypeFilters = "RiskControllerNetworkInboundExternalResourceRiskPolicyTypeFilters",
    RiskControllerRequestAwsEcsTaskDefinitionEnvironmentVariableSecretExistsRiskPolicyGroupFilters = "RiskControllerRequestAwsEcsTaskDefinitionEnvironmentVariableSecretExistsRiskPolicyGroupFilters",
    RiskControllerRequestSortRiskModelProperty = "RiskControllerRequestSortRiskModelProperty",
    RiskControllerResolveAwsRiskAutomationRequest = "RiskControllerResolveAwsRiskAutomationRequest",
    RiskControllerResolveAzureRiskAutomationRequest = "RiskControllerResolveAzureRiskAutomationRequest",
    RiskControllerResolveGcpRiskAutomationRequest = "RiskControllerResolveGcpRiskAutomationRequest",
    RiskControllerResolveRiskAutomationRequest = "RiskControllerResolveRiskAutomationRequest",
    RiskControllerResolveRiskAutomationResponse = "RiskControllerResolveRiskAutomationResponse",
    RiskControllerResolveRiskCodeAutomationError = "RiskControllerResolveRiskCodeAutomationError",
    RiskControllerResolveRiskCodeAutomationRequest = "RiskControllerResolveRiskCodeAutomationRequest",
    RiskControllerResolveRiskCodeAutomationResponse = "RiskControllerResolveRiskCodeAutomationResponse",
    RiskControllerRiskModelFilters = "RiskControllerRiskModelFilters",
    RiskControllerRiskModelSort = "RiskControllerRiskModelSort",
    RiskControllerRiskPolicyTypeFilters = "RiskControllerRiskPolicyTypeFilters",
    RiskControllerSendRisksMailRequest = "RiskControllerSendRisksMailRequest",
    RiskControllerSendRisksWebhookRequest = "RiskControllerSendRisksWebhookRequest",
    RiskControllerUpdateBuiltInComplianceEnabledRequest = "RiskControllerUpdateBuiltInComplianceEnabledRequest",
    RiskControllerUpdateCloudRiskPolicyConfigurationRequest = "RiskControllerUpdateCloudRiskPolicyConfigurationRequest",
    RiskControllerUpdateCodeRiskPolicyConfigurationRequest = "RiskControllerUpdateCodeRiskPolicyConfigurationRequest",
    RiskControllerUpdateCustomRiskPolicySeverityRequest = "RiskControllerUpdateCustomRiskPolicySeverityRequest",
    RiskControllerUpdateKubernetesAdmissionControllerRiskPolicyConfigurationRequest = "RiskControllerUpdateKubernetesAdmissionControllerRiskPolicyConfigurationRequest",
    RiskControllerUpdateRiskChangesRequest = "RiskControllerUpdateRiskChangesRequest",
    RiskControllerUpdateRiskCloseStatusRequest = "RiskControllerUpdateRiskCloseStatusRequest",
    RiskControllerUpdateRiskIgnoreStatusRequest = "RiskControllerUpdateRiskIgnoreStatusRequest",
    RiskControllerUpdateRiskOpenStatusRequest = "RiskControllerUpdateRiskOpenStatusRequest",
    RiskControllerUpdateRiskPolicyConfigurationError = "RiskControllerUpdateRiskPolicyConfigurationError",
    RiskControllerUpdateRiskPolicyConfigurationRequest = "RiskControllerUpdateRiskPolicyConfigurationRequest",
    RiskControllerUpdateRiskStarredRequest = "RiskControllerUpdateRiskStarredRequest",
    RiskControllerUpdateRiskStatusRequest = "RiskControllerUpdateRiskStatusRequest",
    RiskControllerUpsertRiskJiraIssueRequest = "RiskControllerUpsertRiskJiraIssueRequest",
    RiskControllerUpsertRiskJiraIssuesRequest = "RiskControllerUpsertRiskJiraIssuesRequest",
    RiskControllerUpsertRiskJiraIssuesResponse = "RiskControllerUpsertRiskJiraIssuesResponse",
    RiskControllerUpsertRiskServiceNowIncidentRequest = "RiskControllerUpsertRiskServiceNowIncidentRequest",
    RiskControllerUpsertRiskServiceNowIncidentsRequest = "RiskControllerUpsertRiskServiceNowIncidentsRequest",
    RiskControllerUpsertRiskServiceNowIncidentsResponse = "RiskControllerUpsertRiskServiceNowIncidentsResponse",
    RiskCreationAuditEvent = "RiskCreationAuditEvent",
    RiskEngineConfigurationSection = "RiskEngineConfigurationSection",
    RiskFile = "RiskFile",
    RiskFileType = "RiskFileType",
    RiskFilterId = "RiskFilterId",
    RiskFilters = "RiskFilters",
    RiskItem = "RiskItem",
    RiskJiraIssueAuditEvent = "RiskJiraIssueAuditEvent",
    RiskJiraIssueCreationAuditEvent = "RiskJiraIssueCreationAuditEvent",
    RiskJiraIssueUpdateAuditEvent = "RiskJiraIssueUpdateAuditEvent",
    RiskModel = "RiskModel",
    RiskNoteCreationAuditEvent = "RiskNoteCreationAuditEvent",
    RiskNoteDeletionAuditEvent = "RiskNoteDeletionAuditEvent",
    RiskNoteUpdateAuditEvent = "RiskNoteUpdateAuditEvent",
    RiskPolicyAuditEvent = "RiskPolicyAuditEvent",
    RiskPolicyCategory = "RiskPolicyCategory",
    RiskPolicyConfiguration = "RiskPolicyConfiguration",
    RiskPolicyConfigurationCodeExclusion = "RiskPolicyConfigurationCodeExclusion",
    RiskPolicyConfigurationEntityExclusion = "RiskPolicyConfigurationEntityExclusion",
    RiskPolicyConfigurationEntityExclusionAwsResourceArnPattern = "RiskPolicyConfigurationEntityExclusionAwsResourceArnPattern",
    RiskPolicyConfigurationEntityExclusionEntityNamePattern = "RiskPolicyConfigurationEntityExclusionEntityNamePattern",
    RiskPolicyConfigurationEntityExclusionEntityPropertyPattern = "RiskPolicyConfigurationEntityExclusionEntityPropertyPattern",
    RiskPolicyConfigurationEntityExclusionResourceTagPattern = "RiskPolicyConfigurationEntityExclusionResourceTagPattern",
    RiskPolicyConfigurationExclusionData = "RiskPolicyConfigurationExclusionData",
    RiskPolicyConfigurationExclusionDataReason = "RiskPolicyConfigurationExclusionDataReason",
    RiskPolicyCreationAuditEvent = "RiskPolicyCreationAuditEvent",
    RiskPolicyDeletionAuditEvent = "RiskPolicyDeletionAuditEvent",
    RiskPolicyModel = "RiskPolicyModel",
    RiskPolicyTypeGroupFilters = "RiskPolicyTypeGroupFilters",
    RiskPolicyTypeMetadata = "RiskPolicyTypeMetadata",
    RiskPolicyTypeMetadataAnalysisGroupType = "RiskPolicyTypeMetadataAnalysisGroupType",
    RiskPolicyUpdateAuditEvent = "RiskPolicyUpdateAuditEvent",
    RiskPolicyUpdateAuditEventChange = "RiskPolicyUpdateAuditEventChange",
    RiskPolicyUpdateAuditEventCodeEnabledChange = "RiskPolicyUpdateAuditEventCodeEnabledChange",
    RiskPolicyUpdateAuditEventCodeExclusionsChange = "RiskPolicyUpdateAuditEventCodeExclusionsChange",
    RiskPolicyUpdateAuditEventEnabledChange = "RiskPolicyUpdateAuditEventEnabledChange",
    RiskPolicyUpdateAuditEventEntityCreationTimeAnalysisDelayTimeFrameChange = "RiskPolicyUpdateAuditEventEntityCreationTimeAnalysisDelayTimeFrameChange",
    RiskPolicyUpdateAuditEventEntityExclusionsChange = "RiskPolicyUpdateAuditEventEntityExclusionsChange",
    RiskPolicyUpdateAuditEventModel = "RiskPolicyUpdateAuditEventModel",
    RiskResolutionAuditEvent = "RiskResolutionAuditEvent",
    RiskResolutionReason = "RiskResolutionReason",
    RisksAuditEvent = "RisksAuditEvent",
    RiskSenderSendRiskJiraIssueResult = "RiskSenderSendRiskJiraIssueResult",
    RiskSenderSendRiskServiceNowIncidentResult = "RiskSenderSendRiskServiceNowIncidentResult",
    RiskSenderUpsertRiskJiraIssueResult = "RiskSenderUpsertRiskJiraIssueResult",
    RiskSenderUpsertRiskServiceNowIncidentResult = "RiskSenderUpsertRiskServiceNowIncidentResult",
    RiskSendMailAuditEvent = "RiskSendMailAuditEvent",
    RiskSendWebhookAuditEvent = "RiskSendWebhookAuditEvent",
    RiskServiceNowIncidentAuditEvent = "RiskServiceNowIncidentAuditEvent",
    RiskServiceNowIncidentCreationAuditEvent = "RiskServiceNowIncidentCreationAuditEvent",
    RiskServiceNowIncidentUpdateAuditEvent = "RiskServiceNowIncidentUpdateAuditEvent",
    RiskSeverityReason = "RiskSeverityReason",
    RiskSeverityUpdateAuditEvent = "RiskSeverityUpdateAuditEvent",
    RisksReportDefinitionConfiguration = "RisksReportDefinitionConfiguration",
    RiskStarredUpdateAuditEvent = "RiskStarredUpdateAuditEvent",
    RiskState = "RiskState",
    RiskStateIntegration = "RiskStateIntegration",
    RiskStateIntegrationJira = "RiskStateIntegrationJira",
    RiskStateIntegrationServiceNow = "RiskStateIntegrationServiceNow",
    RiskStatus = "RiskStatus",
    RiskStatusUpdateAuditEvent = "RiskStatusUpdateAuditEvent",
    RiskStatusUpdateNoteDeletionAuditEvent = "RiskStatusUpdateNoteDeletionAuditEvent",
    RiskStatusUpdateNoteUpdateAuditEvent = "RiskStatusUpdateNoteUpdateAuditEvent",
    RiskSubStatus = "RiskSubStatus",
    RisksView = "RisksView",
    RiskTypeMetadata = "RiskTypeMetadata",
    RiskTypeMetadataModel = "RiskTypeMetadataModel",
    ScopeConfigurationAuthorization = "ScopeConfigurationAuthorization",
    ScopeConfigurationCode = "ScopeConfigurationCode",
    ScopeConfigurationDataAnalysis = "ScopeConfigurationDataAnalysis",
    ScopeConfigurationSection = "ScopeConfigurationSection",
    ScopeConfigurationSections = "ScopeConfigurationSections",
    ScopeConfigurationWorkloadAnalysis = "ScopeConfigurationWorkloadAnalysis",
    ScopeConfigurationWorkloadAnalysisTrustedFile = "ScopeConfigurationWorkloadAnalysisTrustedFile",
    ScopeControllerGetScopeNodeModelsRequest = "ScopeControllerGetScopeNodeModelsRequest",
    ScopeControllerGetScopeNodeModelsResponse = "ScopeControllerGetScopeNodeModelsResponse",
    ScopeControllerMoveScopeRequest = "ScopeControllerMoveScopeRequest",
    ScopeControllerMoveScopeResponse = "ScopeControllerMoveScopeResponse",
    ScopeDocument = "ScopeDocument",
    ScopeModel = "ScopeModel",
    ScopeNodeModel = "ScopeNodeModel",
    ScopeSettingAction = "ScopeSettingAction",
    ScopeSettingAuditEvent = "ScopeSettingAuditEvent",
    ScopeSettingConfiguration = "ScopeSettingConfiguration",
    ScopeSettingConfigurationData = "ScopeSettingConfigurationData",
    ScopeSettingCreationAuditEvent = "ScopeSettingCreationAuditEvent",
    ScopeSettingDeletionAuditEvent = "ScopeSettingDeletionAuditEvent",
    ScopeSettingDeletionAuditEventData = "ScopeSettingDeletionAuditEventData",
    ScopeSettingUpdateAuditEvent = "ScopeSettingUpdateAuditEvent",
    ScopeSettingUpdateAuditEventData = "ScopeSettingUpdateAuditEventData",
    ScopeSummaryCloudProviderEntityPublicData = "ScopeSummaryCloudProviderEntityPublicData",
    ScopeSummaryGeneratorConfigurationSection = "ScopeSummaryGeneratorConfigurationSection",
    ScopeSystemEntityConfiguration = "ScopeSystemEntityConfiguration",
    ScopeSystemEntityModel = "ScopeSystemEntityModel",
    ScopeType = "ScopeType",
    SearchableData = "SearchableData",
    SearchableDataBase = "SearchableDataBase",
    SecretExistsRiskPolicyConfiguration = "SecretExistsRiskPolicyConfiguration",
    SecretExistsRiskPolicyConfigurationSecretExclusionSecretPattern = "SecretExistsRiskPolicyConfigurationSecretExclusionSecretPattern",
    SecretExistsRiskPolicyUpdateAuditEventSecretExclusionsChange = "SecretExistsRiskPolicyUpdateAuditEventSecretExclusionsChange",
    SecurityStats = "SecurityStats",
    SemiStructuredDataClassifierSampleLocation = "SemiStructuredDataClassifierSampleLocation",
    SensitiveResourceAttribute = "SensitiveResourceAttribute",
    SensitiveResourcePermissionActionPrincipalAttribute = "SensitiveResourcePermissionActionPrincipalAttribute",
    SensorVirtualMachineMetadata = "SensorVirtualMachineMetadata",
    ServiceConfiguration = "ServiceConfiguration",
    ServiceIdentityExcessivePermissionEvaluationTimeFrameConfiguration = "ServiceIdentityExcessivePermissionEvaluationTimeFrameConfiguration",
    ServiceIdentityExcessivePermissionEvaluationTimeFrameDeletionAuditEventData = "ServiceIdentityExcessivePermissionEvaluationTimeFrameDeletionAuditEventData",
    ServiceIdentityExcessivePermissionEvaluationTimeFrameUpdateAuditEventData = "ServiceIdentityExcessivePermissionEvaluationTimeFrameUpdateAuditEventData",
    ServiceIdentityModelAccess = "ServiceIdentityModelAccess",
    ServiceNowDelivery = "ServiceNowDelivery",
    ServiceNowDeliveryInstance = "ServiceNowDeliveryInstance",
    ServiceNowField = "ServiceNowField",
    ServiceNowGroup = "ServiceNowGroup",
    ServiceNowIncident = "ServiceNowIncident",
    ServiceNowIncidentCreationData = "ServiceNowIncidentCreationData",
    ServiceNowIncidentImpact = "ServiceNowIncidentImpact",
    ServiceNowIncidentInfo = "ServiceNowIncidentInfo",
    ServiceNowIncidentUrgency = "ServiceNowIncidentUrgency",
    ServiceNowInstanceAuditEvent = "ServiceNowInstanceAuditEvent",
    ServiceNowInstanceConfiguration = "ServiceNowInstanceConfiguration",
    ServiceNowInstanceCreationAuditEvent = "ServiceNowInstanceCreationAuditEvent",
    ServiceNowInstanceDeletionAuditEvent = "ServiceNowInstanceDeletionAuditEvent",
    ServiceNowInstanceState = "ServiceNowInstanceState",
    ServiceNowInstanceStateIssue = "ServiceNowInstanceStateIssue",
    ServiceNowInstanceUpdateAuditEvent = "ServiceNowInstanceUpdateAuditEvent",
    ServiceNowSelectionField = "ServiceNowSelectionField",
    ServiceNowStringField = "ServiceNowStringField",
    ServiceNowUser = "ServiceNowUser",
    ServiceTypeMetadata = "ServiceTypeMetadata",
    SevereDirectoryPermissionAttribute = "SevereDirectoryPermissionAttribute",
    SevereExcessivePermissionActionPrincipalAttribute = "SevereExcessivePermissionActionPrincipalAttribute",
    SeverePermissionActionPrincipalAttribute = "SeverePermissionActionPrincipalAttribute",
    Severity = "Severity",
    SingleStoreClientProviderConfigurationSection = "SingleStoreClientProviderConfigurationSection",
    SingleStoreWorkspaceGroupConfiguration = "SingleStoreWorkspaceGroupConfiguration",
    SlackConversationReference = "SlackConversationReference",
    SlackDelivery = "SlackDelivery",
    SlackDeliveryChannel = "SlackDeliveryChannel",
    SlackManagerConfigurationSection = "SlackManagerConfigurationSection",
    SlackWorkspaceAuditEvent = "SlackWorkspaceAuditEvent",
    SlackWorkspaceConfiguration = "SlackWorkspaceConfiguration",
    SlackWorkspaceCreationAuditEvent = "SlackWorkspaceCreationAuditEvent",
    SlackWorkspaceDeletionAuditEvent = "SlackWorkspaceDeletionAuditEvent",
    SlackWorkspaceModel = "SlackWorkspaceModel",
    SlackWorkspaceState = "SlackWorkspaceState",
    SlackWorkspaceStateIssue = "SlackWorkspaceStateIssue",
    SlackWorkspaceUpdateAuditEvent = "SlackWorkspaceUpdateAuditEvent",
    SnowflakeAccountConfiguration = "SnowflakeAccountConfiguration",
    SnowflakeClientProviderConfigurationSection = "SnowflakeClientProviderConfigurationSection",
    SortDirection = "SortDirection",
    SplunkDelivery = "SplunkDelivery",
    SplunkDeliveryEventCollector = "SplunkDeliveryEventCollector",
    SplunkEventCollectorAuditEvent = "SplunkEventCollectorAuditEvent",
    SplunkEventCollectorConfiguration = "SplunkEventCollectorConfiguration",
    SplunkEventCollectorCreationAuditEvent = "SplunkEventCollectorCreationAuditEvent",
    SplunkEventCollectorDeletionAuditEvent = "SplunkEventCollectorDeletionAuditEvent",
    SplunkEventCollectorState = "SplunkEventCollectorState",
    SplunkEventCollectorStateIssue = "SplunkEventCollectorStateIssue",
    SplunkEventCollectorUpdateAuditEvent = "SplunkEventCollectorUpdateAuditEvent",
    SqsDelivery = "SqsDelivery",
    SqsDeliveryQueue = "SqsDeliveryQueue",
    SqsQueueAuditEvent = "SqsQueueAuditEvent",
    SqsQueueConfiguration = "SqsQueueConfiguration",
    SqsQueueCreationAuditEvent = "SqsQueueCreationAuditEvent",
    SqsQueueDeletionAuditEvent = "SqsQueueDeletionAuditEvent",
    SqsQueueState = "SqsQueueState",
    SqsQueueStateIssue = "SqsQueueStateIssue",
    SqsQueueUpdateAuditEvent = "SqsQueueUpdateAuditEvent",
    StatusCode = "StatusCode",
    StringSearchableData = "StringSearchableData",
    StructuredDataClassifierSampleLocation = "StructuredDataClassifierSampleLocation",
    Subdocument = "Subdocument",
    SubnetSearchableData = "SubnetSearchableData",
    Summary = "Summary",
    SummarySection = "SummarySection",
    SystemApplicationServiceConfiguration = "SystemApplicationServiceConfiguration",
    SystemConfiguration = "SystemConfiguration",
    SystemConsoleAppView = "SystemConsoleAppView",
    SystemControllerGetGeographyInfoResponse = "SystemControllerGetGeographyInfoResponse",
    SystemControllerGetInfoResponse = "SystemControllerGetInfoResponse",
    SystemControllerGetInfoResponseDatadogRumApplication = "SystemControllerGetInfoResponseDatadogRumApplication",
    SystemEntity = "SystemEntity",
    SystemEntityConfiguration = "SystemEntityConfiguration",
    SystemEntityModel = "SystemEntityModel",
    SystemEntityState = "SystemEntityState",
    SystemIdentity = "SystemIdentity",
    SystemInfrastructureServiceConfiguration = "SystemInfrastructureServiceConfiguration",
    SystemKubernetesClusterApiConnectivityStatus = "SystemKubernetesClusterApiConnectivityStatus",
    SystemKubernetesClusterConfiguration = "SystemKubernetesClusterConfiguration",
    SystemKubernetesClusterFilters = "SystemKubernetesClusterFilters",
    SystemKubernetesClusterModel = "SystemKubernetesClusterModel",
    SystemKubernetesClusterModelHelmComponentStatus = "SystemKubernetesClusterModelHelmComponentStatus",
    SystemKubernetesClusterState = "SystemKubernetesClusterState",
    SystemPermissionManagementServiceConfiguration = "SystemPermissionManagementServiceConfiguration",
    SystemVirtualMachineConfiguration = "SystemVirtualMachineConfiguration",
    SystemVirtualMachineState = "SystemVirtualMachineState",
    TableDataSegmentMetadata = "TableDataSegmentMetadata",
    TagResourceGroupDefinitionConfiguration = "TagResourceGroupDefinitionConfiguration",
    TeamsApplicationAuthenticationProviderConfigurationSection = "TeamsApplicationAuthenticationProviderConfigurationSection",
    TeamsChannelReference = "TeamsChannelReference",
    TeamsConsoleAppView = "TeamsConsoleAppView",
    TeamsConsoleSignInAuditEvent = "TeamsConsoleSignInAuditEvent",
    TeamsConversationReference = "TeamsConversationReference",
    TeamsDelivery = "TeamsDelivery",
    TeamsDeliveryChannel = "TeamsDeliveryChannel",
    TeamsOrganizationAuditEvent = "TeamsOrganizationAuditEvent",
    TeamsOrganizationConfiguration = "TeamsOrganizationConfiguration",
    TeamsOrganizationCreationAuditEvent = "TeamsOrganizationCreationAuditEvent",
    TeamsOrganizationDeletionAuditEvent = "TeamsOrganizationDeletionAuditEvent",
    TeamsOrganizationState = "TeamsOrganizationState",
    TeamsOrganizationStateIssue = "TeamsOrganizationStateIssue",
    TenantConfiguration = "TenantConfiguration",
    TenantControllerDeleteOrganizationRequest = "TenantControllerDeleteOrganizationRequest",
    TenantControllerDeleteOrganizationResponse = "TenantControllerDeleteOrganizationResponse",
    TenantControllerDeleteTenantRequest = "TenantControllerDeleteTenantRequest",
    TenantControllerDeleteTenantResponse = "TenantControllerDeleteTenantResponse",
    TenantControllerGetAadTenantOnboardingInfoResponse = "TenantControllerGetAadTenantOnboardingInfoResponse",
    TenantControllerGetAwsTenantOnboardingInfoRequest = "TenantControllerGetAwsTenantOnboardingInfoRequest",
    TenantControllerGetAwsTenantOnboardingInfoResponse = "TenantControllerGetAwsTenantOnboardingInfoResponse",
    TenantControllerGetAwsTenantOnboardingPoliciesInfoRequest = "TenantControllerGetAwsTenantOnboardingPoliciesInfoRequest",
    TenantControllerGetAwsTenantOnboardingPoliciesInfoResponse = "TenantControllerGetAwsTenantOnboardingPoliciesInfoResponse",
    TenantControllerGetAzureTenantOnboardingInfoResponse = "TenantControllerGetAzureTenantOnboardingInfoResponse",
    TenantControllerGetGciTenantOnboardingInfoResponse = "TenantControllerGetGciTenantOnboardingInfoResponse",
    TenantControllerGetGcpTenantOnboardingInfoResponse = "TenantControllerGetGcpTenantOnboardingInfoResponse",
    TenantControllerGetTenantModelsRequest = "TenantControllerGetTenantModelsRequest",
    TenantControllerGetTenantModelsResponse = "TenantControllerGetTenantModelsResponse",
    TenantControllerInsertAadChinaTenantRequest = "TenantControllerInsertAadChinaTenantRequest",
    TenantControllerInsertAadChinaTenantResponse = "TenantControllerInsertAadChinaTenantResponse",
    TenantControllerInsertAwsTenantRequest = "TenantControllerInsertAwsTenantRequest",
    TenantControllerInsertAwsTenantResponse = "TenantControllerInsertAwsTenantResponse",
    TenantControllerInsertAzureTenantRequest = "TenantControllerInsertAzureTenantRequest",
    TenantControllerInsertAzureTenantResponse = "TenantControllerInsertAzureTenantResponse",
    TenantControllerInsertGciTenantRequest = "TenantControllerInsertGciTenantRequest",
    TenantControllerInsertGciTenantResponse = "TenantControllerInsertGciTenantResponse",
    TenantControllerInsertGcpTenantRequest = "TenantControllerInsertGcpTenantRequest",
    TenantControllerInsertGcpTenantResponse = "TenantControllerInsertGcpTenantResponse",
    TenantControllerInsertOktaTenantRequest = "TenantControllerInsertOktaTenantRequest",
    TenantControllerInsertOktaTenantResponse = "TenantControllerInsertOktaTenantResponse",
    TenantControllerInsertOneLoginTenantError = "TenantControllerInsertOneLoginTenantError",
    TenantControllerInsertOneLoginTenantRequest = "TenantControllerInsertOneLoginTenantRequest",
    TenantControllerInsertOneLoginTenantResponse = "TenantControllerInsertOneLoginTenantResponse",
    TenantControllerInsertOpTenantRequest = "TenantControllerInsertOpTenantRequest",
    TenantControllerInsertOpTenantResponse = "TenantControllerInsertOpTenantResponse",
    TenantControllerInsertPingIdentityTenantError = "TenantControllerInsertPingIdentityTenantError",
    TenantControllerInsertPingIdentityTenantRequest = "TenantControllerInsertPingIdentityTenantRequest",
    TenantControllerInsertPingIdentityTenantResponse = "TenantControllerInsertPingIdentityTenantResponse",
    TenantControllerUpdateAadTenantRequest = "TenantControllerUpdateAadTenantRequest",
    TenantControllerUpdateAadTenantResponse = "TenantControllerUpdateAadTenantResponse",
    TenantControllerUpdateAwsTenantRequest = "TenantControllerUpdateAwsTenantRequest",
    TenantControllerUpdateAwsTenantResponse = "TenantControllerUpdateAwsTenantResponse",
    TenantControllerUpdateAzureTenantRequest = "TenantControllerUpdateAzureTenantRequest",
    TenantControllerUpdateAzureTenantResponse = "TenantControllerUpdateAzureTenantResponse",
    TenantControllerUpdateGciTenantRequest = "TenantControllerUpdateGciTenantRequest",
    TenantControllerUpdateGciTenantResponse = "TenantControllerUpdateGciTenantResponse",
    TenantControllerUpdateGcpTenantRequest = "TenantControllerUpdateGcpTenantRequest",
    TenantControllerUpdateGcpTenantResponse = "TenantControllerUpdateGcpTenantResponse",
    TenantControllerUpdateOktaTenantRequest = "TenantControllerUpdateOktaTenantRequest",
    TenantControllerUpdateOktaTenantResponse = "TenantControllerUpdateOktaTenantResponse",
    TenantControllerUpdateOneLoginTenantError = "TenantControllerUpdateOneLoginTenantError",
    TenantControllerUpdateOneLoginTenantRequest = "TenantControllerUpdateOneLoginTenantRequest",
    TenantControllerUpdateOneLoginTenantRequestClientData = "TenantControllerUpdateOneLoginTenantRequestClientData",
    TenantControllerUpdateOneLoginTenantResponse = "TenantControllerUpdateOneLoginTenantResponse",
    TenantControllerUpdateOpTenantRequest = "TenantControllerUpdateOpTenantRequest",
    TenantControllerUpdateOpTenantResponse = "TenantControllerUpdateOpTenantResponse",
    TenantControllerUpdatePingIdentityTenantError = "TenantControllerUpdatePingIdentityTenantError",
    TenantControllerUpdatePingIdentityTenantRequest = "TenantControllerUpdatePingIdentityTenantRequest",
    TenantControllerUpdatePingIdentityTenantResponse = "TenantControllerUpdatePingIdentityTenantResponse",
    TenantControllerValidateAwsAccessKeyRequest = "TenantControllerValidateAwsAccessKeyRequest",
    TenantControllerValidateAwsAccessKeyResponse = "TenantControllerValidateAwsAccessKeyResponse",
    TenantControllerValidateAwsTenantRequest = "TenantControllerValidateAwsTenantRequest",
    TenantControllerValidateAwsTenantResponse = "TenantControllerValidateAwsTenantResponse",
    TenantControllerValidateAwsTenantTrailBucketRequest = "TenantControllerValidateAwsTenantTrailBucketRequest",
    TenantControllerValidateAwsTenantTrailBucketResponse = "TenantControllerValidateAwsTenantTrailBucketResponse",
    TenantControllerValidateGciTenantRequest = "TenantControllerValidateGciTenantRequest",
    TenantControllerValidateGciTenantResponse = "TenantControllerValidateGciTenantResponse",
    TenantControllerValidateGciTenantResult = "TenantControllerValidateGciTenantResult",
    TenantControllerValidateGcpAccessTokenRequest = "TenantControllerValidateGcpAccessTokenRequest",
    TenantControllerValidateGcpAccessTokenResponse = "TenantControllerValidateGcpAccessTokenResponse",
    TenantControllerValidateGcpOrganizationRequest = "TenantControllerValidateGcpOrganizationRequest",
    TenantControllerValidateGcpOrganizationResponse = "TenantControllerValidateGcpOrganizationResponse",
    TenantControllerValidateGcpTenantRequest = "TenantControllerValidateGcpTenantRequest",
    TenantControllerValidateGcpTenantResponse = "TenantControllerValidateGcpTenantResponse",
    TenantControllerValidateOneLoginTenantRequest = "TenantControllerValidateOneLoginTenantRequest",
    TenantControllerValidateOneLoginTenantResponse = "TenantControllerValidateOneLoginTenantResponse",
    TenantControllerValidateOneLoginTenantResult = "TenantControllerValidateOneLoginTenantResult",
    TenantControllerValidateStoredAwsAccessKeyRequest = "TenantControllerValidateStoredAwsAccessKeyRequest",
    TenantControllerValidateStoredAwsAccessKeyResponse = "TenantControllerValidateStoredAwsAccessKeyResponse",
    TenantControllerValidateStoredGcpAccessTokenRequest = "TenantControllerValidateStoredGcpAccessTokenRequest",
    TenantControllerValidateStoredGcpAccessTokenResponse = "TenantControllerValidateStoredGcpAccessTokenResponse",
    TenantDocument = "TenantDocument",
    TenantModel = "TenantModel",
    TenantOrchestrationStageState = "TenantOrchestrationStageState",
    TenantState = "TenantState",
    TenantStateMonitoring = "TenantStateMonitoring",
    TenantStateOrchestration = "TenantStateOrchestration",
    TenantStateOrchestrationData = "TenantStateOrchestrationData",
    TenantType = "TenantType",
    TerraformBlock = "TerraformBlock",
    TerraformDataBlock = "TerraformDataBlock",
    TerraformResourceBlock = "TerraformResourceBlock",
    TextBlock = "TextBlock",
    TextIssue = "TextIssue",
    TicketingServiceControllerGetJiraCustomLabelsFieldAutocompleteSuggestionsRequest = "TicketingServiceControllerGetJiraCustomLabelsFieldAutocompleteSuggestionsRequest",
    TicketingServiceControllerGetJiraCustomLabelsFieldAutoCompleteSuggestionsResponse = "TicketingServiceControllerGetJiraCustomLabelsFieldAutoCompleteSuggestionsResponse",
    TicketingServiceControllerGetJiraProjectRequest = "TicketingServiceControllerGetJiraProjectRequest",
    TicketingServiceControllerGetJiraProjectResponse = "TicketingServiceControllerGetJiraProjectResponse",
    TicketingServiceControllerGetServiceNowInstanceRequest = "TicketingServiceControllerGetServiceNowInstanceRequest",
    TicketingServiceControllerGetServiceNowInstanceResponse = "TicketingServiceControllerGetServiceNowInstanceResponse",
    TicketingServiceControllerGetServiceNowSeverityImpactAndUrgencyRequest = "TicketingServiceControllerGetServiceNowSeverityImpactAndUrgencyRequest",
    TicketingServiceControllerGetServiceNowSeverityImpactAndUrgencyResponse = "TicketingServiceControllerGetServiceNowSeverityImpactAndUrgencyResponse",
    TicketingServiceControllerGetTicketsRequest = "TicketingServiceControllerGetTicketsRequest",
    TicketingServiceControllerGetTicketsResponse = "TicketingServiceControllerGetTicketsResponse",
    TicketingServiceControllerSearchJiraProjectUsersRequest = "TicketingServiceControllerSearchJiraProjectUsersRequest",
    TicketingServiceControllerSearchJiraProjectUsersResponse = "TicketingServiceControllerSearchJiraProjectUsersResponse",
    TicketingServiceControllerTryGetJiraIssueInfoRequest = "TicketingServiceControllerTryGetJiraIssueInfoRequest",
    TicketingServiceControllerTryGetJiraIssueInfoResponse = "TicketingServiceControllerTryGetJiraIssueInfoResponse",
    TicketingServiceControllerTryGetServiceNowIncidentInfoRequest = "TicketingServiceControllerTryGetServiceNowIncidentInfoRequest",
    TicketingServiceControllerTryGetServiceNowIncidentInfoResponse = "TicketingServiceControllerTryGetServiceNowIncidentInfoResponse",
    TicketingServiceTicket = "TicketingServiceTicket",
    TicketingServiceType = "TicketingServiceType",
    TiFile = "TiFile",
    TiFileAnalysis = "TiFileAnalysis",
    TiFileContentAnalysisResult = "TiFileContentAnalysisResult",
    TiFileContentAnalysisResultCategory = "TiFileContentAnalysisResultCategory",
    TiFileContentKnownDistributors = "TiFileContentKnownDistributors",
    TiFileModel = "TiFileModel",
    TiFileScan = "TiFileScan",
    TiFileVirusTotalData = "TiFileVirusTotalData",
    TimeFrame = "TimeFrame",
    TimeRange = "TimeRange",
    TimeRangeSelection = "TimeRangeSelection",
    TimeRangeSelectionRelative = "TimeRangeSelectionRelative",
    TimeRangeSelectionRelativeUnit = "TimeRangeSelectionRelativeUnit",
    TimeRangeSelectionType = "TimeRangeSelectionType",
    Trace = "Trace",
    TvdbState = "TvdbState",
    UdmControllerGetEntityTagPartValuePageRequest = "UdmControllerGetEntityTagPartValuePageRequest",
    UdmControllerGetObjectItemsPageRequest = "UdmControllerGetObjectItemsPageRequest",
    UdmControllerGetObjectTypeMetadatasModelsResponse = "UdmControllerGetObjectTypeMetadatasModelsResponse",
    UdmControllerGetPropertyValuePageRequest = "UdmControllerGetPropertyValuePageRequest",
    UdmControllerGetPropertyValuePageResponse = "UdmControllerGetPropertyValuePageResponse",
    UdmControllerObjectItemsPageResponse = "UdmControllerObjectItemsPageResponse",
    UdmObjectProperty = "UdmObjectProperty",
    UdmObjectPropertyDataType = "UdmObjectPropertyDataType",
    UdmObjectPropertyId = "UdmObjectPropertyId",
    UdmObjectPropertyOptions = "UdmObjectPropertyOptions",
    UdmObjectPropertyRelation = "UdmObjectPropertyRelation",
    UdmObjectType = "UdmObjectType",
    UdmQuery = "UdmQuery",
    UdmQueryBase = "UdmQueryBase",
    UdmQueryJoin = "UdmQueryJoin",
    UdmQueryOptions = "UdmQueryOptions",
    UdmQueryOptionsSort = "UdmQueryOptionsSort",
    UdmQueryRelationRuleGroup = "UdmQueryRelationRuleGroup",
    UdmQueryRule = "UdmQueryRule",
    UdmQueryRuleBase = "UdmQueryRuleBase",
    UdmQueryRuleGroup = "UdmQueryRuleGroup",
    UdmQueryRuleGroupOperator = "UdmQueryRuleGroupOperator",
    UdmQueryRuleOperator = "UdmQueryRuleOperator",
    UnknownEntity = "UnknownEntity",
    UnknownEntityBase = "UnknownEntityBase",
    UnknownEntityBaseModel = "UnknownEntityBaseModel",
    UnknownEntityModel = "UnknownEntityModel",
    UnknownEntityTypeMetadata = "UnknownEntityTypeMetadata",
    UnknownPrincipal = "UnknownPrincipal",
    UnknownPrincipalModel = "UnknownPrincipalModel",
    UnmanagedKubernetesClusterModelFiltersSection = "UnmanagedKubernetesClusterModelFiltersSection",
    UnstructuredDataClassifierSampleLocation = "UnstructuredDataClassifierSampleLocation",
    UrlBuilderConfigurationSection = "UrlBuilderConfigurationSection",
    User = "User",
    UserConsoleAppView = "UserConsoleAppView",
    UserConsoleSignInAuditEvent = "UserConsoleSignInAuditEvent",
    UserConsoleSignOutAuditEvent = "UserConsoleSignOutAuditEvent",
    UserExcessivePermissionEvaluationTimeFrameConfiguration = "UserExcessivePermissionEvaluationTimeFrameConfiguration",
    UserExcessivePermissionEvaluationTimeFrameDeletionAuditEventData = "UserExcessivePermissionEvaluationTimeFrameDeletionAuditEventData",
    UserExcessivePermissionEvaluationTimeFrameUpdateAuditEventData = "UserExcessivePermissionEvaluationTimeFrameUpdateAuditEventData",
    UserModelAccess = "UserModelAccess",
    UserRoleAssignmentAuditEvent = "UserRoleAssignmentAuditEvent",
    UserRoleAssignmentCreationAuditEvent = "UserRoleAssignmentCreationAuditEvent",
    UserRoleAssignmentDeletionAuditEvent = "UserRoleAssignmentDeletionAuditEvent",
    UserRoleAssignmentUpdateAuditEvent = "UserRoleAssignmentUpdateAuditEvent",
    UserState = "UserState",
    Vendor = "Vendor",
    VendorServiceIdentityAttribute = "VendorServiceIdentityAttribute",
    VersionSearchableData = "VersionSearchableData",
    VirtualMachineImageAccessLevel = "VirtualMachineImageAccessLevel",
    VirtualMachineImageManagementType = "VirtualMachineImageManagementType",
    VirtualMachineImageOperatingSystemEndOfLifeRisk = "VirtualMachineImageOperatingSystemEndOfLifeRisk",
    VirtualMachineImageOperatingSystemEndOfLifeRiskModel = "VirtualMachineImageOperatingSystemEndOfLifeRiskModel",
    VirtualMachineImageOperatingSystemEndOfLifeRiskPolicyConfiguration = "VirtualMachineImageOperatingSystemEndOfLifeRiskPolicyConfiguration",
    VirtualMachineImageOperatingSystemUnpatchedRisk = "VirtualMachineImageOperatingSystemUnpatchedRisk",
    VirtualMachineImageOperatingSystemUnpatchedRiskModel = "VirtualMachineImageOperatingSystemUnpatchedRiskModel",
    VirtualMachineImageOperatingSystemUnpatchedRiskPolicyConfiguration = "VirtualMachineImageOperatingSystemUnpatchedRiskPolicyConfiguration",
    VirtualMachineImageScanFilters = "VirtualMachineImageScanFilters",
    VirtualMachineImageVulnerabilityRisk = "VirtualMachineImageVulnerabilityRisk",
    VirtualMachineImageVulnerabilityRiskModel = "VirtualMachineImageVulnerabilityRiskModel",
    VirtualMachineImageVulnerabilityRiskPolicyConfiguration = "VirtualMachineImageVulnerabilityRiskPolicyConfiguration",
    VirtualMachineImageWorkloadAnalysisRisk = "VirtualMachineImageWorkloadAnalysisRisk",
    VirtualMachineMaliciousFileRisk = "VirtualMachineMaliciousFileRisk",
    VirtualMachineMaliciousFileRiskModel = "VirtualMachineMaliciousFileRiskModel",
    VirtualMachineMaliciousFileRiskPolicyConfiguration = "VirtualMachineMaliciousFileRiskPolicyConfiguration",
    VirtualMachineOperatingSystemEndOfLifeRisk = "VirtualMachineOperatingSystemEndOfLifeRisk",
    VirtualMachineOperatingSystemEndOfLifeRiskModel = "VirtualMachineOperatingSystemEndOfLifeRiskModel",
    VirtualMachineOperatingSystemEndOfLifeRiskPolicyConfiguration = "VirtualMachineOperatingSystemEndOfLifeRiskPolicyConfiguration",
    VirtualMachineOperatingSystemUnpatchedRisk = "VirtualMachineOperatingSystemUnpatchedRisk",
    VirtualMachineOperatingSystemUnpatchedRiskModel = "VirtualMachineOperatingSystemUnpatchedRiskModel",
    VirtualMachineOperatingSystemUnpatchedRiskPolicyConfiguration = "VirtualMachineOperatingSystemUnpatchedRiskPolicyConfiguration",
    VirtualMachinePackageVulnerabilitiesReportDefinitionConfiguration = "VirtualMachinePackageVulnerabilitiesReportDefinitionConfiguration",
    VirtualMachinePriorityScanRequestUpdateAuditEvent = "VirtualMachinePriorityScanRequestUpdateAuditEvent",
    VirtualMachineScanFilters = "VirtualMachineScanFilters",
    VirtualMachineScopeConfiguration = "VirtualMachineScopeConfiguration",
    VirtualMachinesReportDefinitionConfiguration = "VirtualMachinesReportDefinitionConfiguration",
    VirtualMachineVulnerabilityRisk = "VirtualMachineVulnerabilityRisk",
    VirtualMachineVulnerabilityRiskModel = "VirtualMachineVulnerabilityRiskModel",
    VirtualMachineVulnerabilityRiskPolicyConfiguration = "VirtualMachineVulnerabilityRiskPolicyConfiguration",
    VirtualMachineWorkloadAnalysisRisk = "VirtualMachineWorkloadAnalysisRisk",
    VirusTotalManagerConfigurationSection = "VirusTotalManagerConfigurationSection",
    Vulnerability = "Vulnerability",
    VulnerabilityAge = "VulnerabilityAge",
    VulnerabilityAttackComplexity = "VulnerabilityAttackComplexity",
    VulnerabilityAttackVector = "VulnerabilityAttackVector",
    VulnerabilityCisaKevData = "VulnerabilityCisaKevData",
    VulnerabilityControllerGetVulnerabilityModelsRequest = "VulnerabilityControllerGetVulnerabilityModelsRequest",
    VulnerabilityControllerGetVulnerabilityModelsResponse = "VulnerabilityControllerGetVulnerabilityModelsResponse",
    VulnerabilityData = "VulnerabilityData",
    VulnerabilityEpssScoreData = "VulnerabilityEpssScoreData",
    VulnerabilityExploitMaturity = "VulnerabilityExploitMaturity",
    VulnerabilityModel = "VulnerabilityModel",
    VulnerabilityProductCoverage = "VulnerabilityProductCoverage",
    VulnerabilityReference = "VulnerabilityReference",
    VulnerabilityReferenceAuthority = "VulnerabilityReferenceAuthority",
    VulnerabilityReferenceTag = "VulnerabilityReferenceTag",
    VulnerabilityReferenceTagData = "VulnerabilityReferenceTagData",
    VulnerabilityReportType = "VulnerabilityReportType",
    VulnerabilityScoreType = "VulnerabilityScoreType",
    VulnerabilitySecurityWatchType = "VulnerabilitySecurityWatchType",
    VulnerabilitySourceData = "VulnerabilitySourceData",
    VulnerabilitySourceType = "VulnerabilitySourceType",
    VulnerabilityThreatIntensityLast28Days = "VulnerabilityThreatIntensityLast28Days",
    VulnerabilityThreatRecency = "VulnerabilityThreatRecency",
    VulnerabilityThreatSourcesLast28Days = "VulnerabilityThreatSourcesLast28Days",
    VulnerabilityTimeline = "VulnerabilityTimeline",
    VulnerabilityVprMetrics = "VulnerabilityVprMetrics",
    VulnerabilityVprScoreData = "VulnerabilityVprScoreData",
    WebhookDelivery = "WebhookDelivery",
    WebhookDeliveryEndpoint = "WebhookDeliveryEndpoint",
    WebhookEndpointAuditEvent = "WebhookEndpointAuditEvent",
    WebhookEndpointConfiguration = "WebhookEndpointConfiguration",
    WebhookEndpointConfigurationHttpHeader = "WebhookEndpointConfigurationHttpHeader",
    WebhookEndpointCreationAuditEvent = "WebhookEndpointCreationAuditEvent",
    WebhookEndpointDeletionAuditEvent = "WebhookEndpointDeletionAuditEvent",
    WebhookEndpointState = "WebhookEndpointState",
    WebhookEndpointUpdateAuditEvent = "WebhookEndpointUpdateAuditEvent",
    WebhookManagerHttpHeader = "WebhookManagerHttpHeader",
    WorkloadAnalysisAuditEvent = "WorkloadAnalysisAuditEvent",
    WorkloadAnalysisError = "WorkloadAnalysisError",
    WorkloadAnalysisFileModel = "WorkloadAnalysisFileModel",
    WorkloadAnalysisPackageModel = "WorkloadAnalysisPackageModel",
    WorkloadAnalysisPackageVulnerabilityModel = "WorkloadAnalysisPackageVulnerabilityModel",
    WorkloadAnalysisReportDefinitionConfiguration = "WorkloadAnalysisReportDefinitionConfiguration",
    WorkloadAnalysisRisk = "WorkloadAnalysisRisk",
    WorkloadAnalysisRiskModel = "WorkloadAnalysisRiskModel",
    WorkloadAnalysisStatus = "WorkloadAnalysisStatus",
    WorkloadAnalysisUpdateAuditEvent = "WorkloadAnalysisUpdateAuditEvent",
    WorkloadAnalysisVulnerabilityModel = "WorkloadAnalysisVulnerabilityModel",
    WorkloadAnalysisWorkloadResourceType = "WorkloadAnalysisWorkloadResourceType",
    WorkloadControllerContainerImageModelFilters = "WorkloadControllerContainerImageModelFilters",
    WorkloadControllerContainerImageModelSort = "WorkloadControllerContainerImageModelSort",
    WorkloadControllerGetMaliciousFileModelCountResponse = "WorkloadControllerGetMaliciousFileModelCountResponse",
    WorkloadControllerGetMaliciousFileModelPageRequest = "WorkloadControllerGetMaliciousFileModelPageRequest",
    WorkloadControllerGetMaliciousFileModelPageResponse = "WorkloadControllerGetMaliciousFileModelPageResponse",
    WorkloadControllerGetPackageModelCountResponse = "WorkloadControllerGetPackageModelCountResponse",
    WorkloadControllerGetPackageModelPageRequest = "WorkloadControllerGetPackageModelPageRequest",
    WorkloadControllerGetPackageModelPageResponse = "WorkloadControllerGetPackageModelPageResponse",
    WorkloadControllerGetPackageVulnerabilityModelCountResponse = "WorkloadControllerGetPackageVulnerabilityModelCountResponse",
    WorkloadControllerGetPackageVulnerabilityModelPageRequest = "WorkloadControllerGetPackageVulnerabilityModelPageRequest",
    WorkloadControllerGetPackageVulnerabilityModelPageResponse = "WorkloadControllerGetPackageVulnerabilityModelPageResponse",
    WorkloadControllerGetWorkloadFileFilterScanItemPageResponse = "WorkloadControllerGetWorkloadFileFilterScanItemPageResponse",
    WorkloadControllerGetWorkloadFileFilterScanItemPageResponseBase = "WorkloadControllerGetWorkloadFileFilterScanItemPageResponseBase",
    WorkloadControllerGetWorkloadPackageFilterScanItemPageResponse = "WorkloadControllerGetWorkloadPackageFilterScanItemPageResponse",
    WorkloadControllerGetWorkloadPackageFilterScanItemPageResponseBase = "WorkloadControllerGetWorkloadPackageFilterScanItemPageResponseBase",
    WorkloadControllerGetWorkloadPackageScanFiltersRequest = "WorkloadControllerGetWorkloadPackageScanFiltersRequest",
    WorkloadControllerGetWorkloadPackageScanFiltersResponse = "WorkloadControllerGetWorkloadPackageScanFiltersResponse",
    WorkloadControllerGetWorkloadResourceFilterScanItemPageResponse = "WorkloadControllerGetWorkloadResourceFilterScanItemPageResponse",
    WorkloadControllerGetWorkloadResourceFilterScanItemPageResponseBase = "WorkloadControllerGetWorkloadResourceFilterScanItemPageResponseBase",
    WorkloadControllerGetWorkloadResourceModelPageContainerImageRequest = "WorkloadControllerGetWorkloadResourceModelPageContainerImageRequest",
    WorkloadControllerGetWorkloadResourceModelPageRequest = "WorkloadControllerGetWorkloadResourceModelPageRequest",
    WorkloadControllerGetWorkloadResourceModelPageResponse = "WorkloadControllerGetWorkloadResourceModelPageResponse",
    WorkloadControllerGetWorkloadResourceModelPageVirtualMachineImageRequest = "WorkloadControllerGetWorkloadResourceModelPageVirtualMachineImageRequest",
    WorkloadControllerGetWorkloadResourceModelPageVirtualMachineRequest = "WorkloadControllerGetWorkloadResourceModelPageVirtualMachineRequest",
    WorkloadControllerGetWorkloadResourceRiskVulnerabilityRawIdsRequest = "WorkloadControllerGetWorkloadResourceRiskVulnerabilityRawIdsRequest",
    WorkloadControllerGetWorkloadResourceRiskVulnerabilityRawIdsResponse = "WorkloadControllerGetWorkloadResourceRiskVulnerabilityRawIdsResponse",
    WorkloadControllerGetWorkloadResourceScanFileFilterItemPageRequest = "WorkloadControllerGetWorkloadResourceScanFileFilterItemPageRequest",
    WorkloadControllerGetWorkloadResourceScanFileFiltersResponse = "WorkloadControllerGetWorkloadResourceScanFileFiltersResponse",
    WorkloadControllerGetWorkloadResourceScanFilterItemPageRequest = "WorkloadControllerGetWorkloadResourceScanFilterItemPageRequest",
    WorkloadControllerGetWorkloadResourceScanFiltersRequest = "WorkloadControllerGetWorkloadResourceScanFiltersRequest",
    WorkloadControllerGetWorkloadResourceScanFiltersResponse = "WorkloadControllerGetWorkloadResourceScanFiltersResponse",
    WorkloadControllerGetWorkloadResourceScanPackageFilterItemPageRequest = "WorkloadControllerGetWorkloadResourceScanPackageFilterItemPageRequest",
    WorkloadControllerMaliciousFileModelPropertyItemsRequest = "WorkloadControllerMaliciousFileModelPropertyItemsRequest",
    WorkloadControllerMaliciousFileModelPropertyItemsResponse = "WorkloadControllerMaliciousFileModelPropertyItemsResponse",
    WorkloadControllerMaliciousFileModelPropertyItemsResponseBase = "WorkloadControllerMaliciousFileModelPropertyItemsResponseBase",
    WorkloadControllerMaliciousFileModelsFilters = "WorkloadControllerMaliciousFileModelsFilters",
    WorkloadControllerPackageModelPropertyItemsRequest = "WorkloadControllerPackageModelPropertyItemsRequest",
    WorkloadControllerPackageModelPropertyItemsResponse = "WorkloadControllerPackageModelPropertyItemsResponse",
    WorkloadControllerPackageModelPropertyItemsResponseBase = "WorkloadControllerPackageModelPropertyItemsResponseBase",
    WorkloadControllerPackageModelsFilters = "WorkloadControllerPackageModelsFilters",
    WorkloadControllerPackageVulnerabilityModelPropertyItemsRequest = "WorkloadControllerPackageVulnerabilityModelPropertyItemsRequest",
    WorkloadControllerPackageVulnerabilityModelPropertyItemsResponse = "WorkloadControllerPackageVulnerabilityModelPropertyItemsResponse",
    WorkloadControllerPackageVulnerabilityModelPropertyItemsResponseBase = "WorkloadControllerPackageVulnerabilityModelPropertyItemsResponseBase",
    WorkloadControllerPackageVulnerabilityModelsFilters = "WorkloadControllerPackageVulnerabilityModelsFilters",
    WorkloadControllerRequestProperty = "WorkloadControllerRequestProperty",
    WorkloadControllerUpdateVirtualMachinePriorityScanRequestRequest = "WorkloadControllerUpdateVirtualMachinePriorityScanRequestRequest",
    WorkloadControllerVirtualMachineImageModelFilters = "WorkloadControllerVirtualMachineImageModelFilters",
    WorkloadControllerVirtualMachineImageModelSort = "WorkloadControllerVirtualMachineImageModelSort",
    WorkloadControllerVirtualMachineModelFilters = "WorkloadControllerVirtualMachineModelFilters",
    WorkloadControllerVirtualMachineModelSort = "WorkloadControllerVirtualMachineModelSort",
    WorkloadControllerWorkloadAnalysisModelsFilters = "WorkloadControllerWorkloadAnalysisModelsFilters",
    WorkloadControllerWorkloadResourceModelFilters = "WorkloadControllerWorkloadResourceModelFilters",
    WorkloadControllerWorkloadResourceModelPropertyItemsRequest = "WorkloadControllerWorkloadResourceModelPropertyItemsRequest",
    WorkloadControllerWorkloadResourceModelPropertyItemsResponse = "WorkloadControllerWorkloadResourceModelPropertyItemsResponse",
    WorkloadControllerWorkloadResourceModelPropertyItemsResponseBase = "WorkloadControllerWorkloadResourceModelPropertyItemsResponseBase",
    WorkloadControllerWorkloadResourceModelSort = "WorkloadControllerWorkloadResourceModelSort",
    WorkloadResourceContainerImageModel = "WorkloadResourceContainerImageModel",
    WorkloadResourceMaliciousFileAttribute = "WorkloadResourceMaliciousFileAttribute",
    WorkloadResourceMaliciousFileRiskMetadata = "WorkloadResourceMaliciousFileRiskMetadata",
    WorkloadResourceModel = "WorkloadResourceModel",
    WorkloadResourceModelPackage = "WorkloadResourceModelPackage",
    WorkloadResourceOperatingSystemEndOfLifeAttribute = "WorkloadResourceOperatingSystemEndOfLifeAttribute",
    WorkloadResourceOperatingSystemEndOfLifeRiskMetadata = "WorkloadResourceOperatingSystemEndOfLifeRiskMetadata",
    WorkloadResourceOperatingSystemRiskPackageScope = "WorkloadResourceOperatingSystemRiskPackageScope",
    WorkloadResourceOperatingSystemRiskTypeMetadata = "WorkloadResourceOperatingSystemRiskTypeMetadata",
    WorkloadResourceOperatingSystemUnpatchedAttribute = "WorkloadResourceOperatingSystemUnpatchedAttribute",
    WorkloadResourceOperatingSystemUnpatchedMetadata = "WorkloadResourceOperatingSystemUnpatchedMetadata",
    WorkloadResourcePackageVulnerabilitiesReportDefinitionConfiguration = "WorkloadResourcePackageVulnerabilitiesReportDefinitionConfiguration",
    WorkloadResourceRiskTypeMetadata = "WorkloadResourceRiskTypeMetadata",
    WorkloadResourceRiskVulnerability = "WorkloadResourceRiskVulnerability",
    WorkloadResourceScanFileFilters = "WorkloadResourceScanFileFilters",
    WorkloadResourceScanFileModel = "WorkloadResourceScanFileModel",
    WorkloadResourceScanFileType = "WorkloadResourceScanFileType",
    WorkloadResourceScanFilters = "WorkloadResourceScanFilters",
    WorkloadResourceScanModel = "WorkloadResourceScanModel",
    WorkloadResourceScanModelVirtualMachineStatus = "WorkloadResourceScanModelVirtualMachineStatus",
    WorkloadResourceScanOperatingSystem = "WorkloadResourceScanOperatingSystem",
    WorkloadResourceScanPackageFilters = "WorkloadResourceScanPackageFilters",
    WorkloadResourceScanPackageModel = "WorkloadResourceScanPackageModel",
    WorkloadResourceScanPackageModelVulnerability = "WorkloadResourceScanPackageModelVulnerability",
    WorkloadResourceScanPackageType = "WorkloadResourceScanPackageType",
    WorkloadResourcesReportDefinitionConfiguration = "WorkloadResourcesReportDefinitionConfiguration",
    WorkloadResourcesReportDefinitionConfigurationBase = "WorkloadResourcesReportDefinitionConfigurationBase",
    WorkloadResourceVirtualMachineImageModel = "WorkloadResourceVirtualMachineImageModel",
    WorkloadResourceVirtualMachineModel = "WorkloadResourceVirtualMachineModel",
    WorkloadResourceVulnerabilityAttribute = "WorkloadResourceVulnerabilityAttribute",
    WorkloadResourceVulnerabilityRiskMetadata = "WorkloadResourceVulnerabilityRiskMetadata"
}