import { NumberFormatter, TimeSpanFormatter, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, InfoItem } from "../../../../../../../../../../common";
import { EntitiesInfoItem } from "../../../../components";
import { useAwsCommonCloudWatchLoggingResourceInfoElements } from "./useAwsCommonCloudWatchLoggingResourceInfoElements";

export function useAwsCommonRdsDatabaseInstanceResourceInfoElements(
    clusterTypeName: string,
    databaseInstanceResourceModel: Contract.AwsRdsDatabaseInstanceResourceModel) {
    const databaseInstanceResource = databaseInstanceResourceModel.entity as Contract.AwsRdsDatabaseInstance;
    const commonCloudWatchLoggingResourceInfoElements = useAwsCommonCloudWatchLoggingResourceInfoElements(databaseInstanceResourceModel);

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsCommonRdsDatabaseInstanceResourceInfoElements",
            () => ({
                info: {
                    items: {
                        availabilityZone: "Availability Zone",
                        backupRetentionTimeFrame: "Automatic Backup Retention Period",
                        cluster: "Cluster",
                        deleteProtection: {
                            disabled: "Disabled",
                            enabled: "Enabled",
                            title: "Delete Protection"
                        },
                        endpoint: "Endpoint",
                        engineVersion: "Engine Version",
                        instanceRawType: "Instance Class",
                        minorVersionUpgradeEnabled: {
                            false: "Disabled",
                            title: "Auto Minor Version Upgrade",
                            true: "Enabled"
                        },
                        publicIpAddressEnabled: {
                            false: "No",
                            title: "Public Accessibility",
                            true: "Yes"
                        },
                        status: "Status",
                        storageSize: "Size",
                        vpc: "VPC"
                    }
                }
            }));

    return {
        ...commonCloudWatchLoggingResourceInfoElements,
        availabilityZone:
            <InfoItem
                key="availabilityZone"
                title={localization.info.items.availabilityZone()}
                value={databaseInstanceResource.availabilityZone.name}/>,
        backupRetentionTimeFrame:
            <InfoItem
                key="backupRetentionTimeFrame"
                title={localization.info.items.backupRetentionTimeFrame()}
                value={TimeSpanFormatter.day(databaseInstanceResource.backupRetentionTimeFrame)}/>,
        cluster:
            <EntitiesInfoItem
                entityIdsOrModels={databaseInstanceResource.clusterId}
                entityTypeName={clusterTypeName}
                key="cluster"/>,
        deleteProtection:
            <InfoItem
                key="deleteProtection"
                title={localization.info.items.deleteProtection.title()}
                value={
                    databaseInstanceResource.deleteProtection
                        ? localization.info.items.deleteProtection.enabled()
                        : localization.info.items.deleteProtection.disabled()}/>,
        endpoint:
            <InfoItem
                key="endpoint"
                title={localization.info.items.endpoint()}
                value={
                    _.isNil(databaseInstanceResource.endpoint)
                        ? undefined
                        : databaseInstanceResource.endpoint}/>,
        engineVersion:
            <InfoItem
                key="engineVersion"
                title={localization.info.items.engineVersion()}
                value={databaseInstanceResource.engineVersion}/>,
        instanceRawType:
            <InfoItem
                key="instanceRawType"
                title={localization.info.items.instanceRawType()}
                value={databaseInstanceResource.instanceRawType}/>,
        minorVersionUpgradeEnabled:
            <InfoItem
                key="minorVersionUpgradeEnabled"
                title={localization.info.items.minorVersionUpgradeEnabled.title()}
                value={
                    databaseInstanceResource.minorVersionUpgradeEnabled
                        ? localization.info.items.minorVersionUpgradeEnabled.true()
                        : localization.info.items.minorVersionUpgradeEnabled.false()}/>,
        publicIpAddressEnabled:
            <InfoItem
                key="publicIpAddressEnabled"
                title={localization.info.items.publicIpAddressEnabled.title()}
                value={
                    databaseInstanceResource.publicIpAddressEnabled
                        ? localization.info.items.publicIpAddressEnabled.true()
                        : localization.info.items.publicIpAddressEnabled.false()}/>,
        status:
            <InfoItem
                key="status"
                title={localization.info.items.status()}
                value={databaseInstanceResource.status}/>,
        storageSize:
            <InfoItem
                key="storageSize"
                title={localization.info.items.storageSize()}
                value={NumberFormatter.storage(databaseInstanceResource.storageSize)}/>,
        vpc:
            <EntitiesInfoItem
                entityIdsOrModels={databaseInstanceResource.vpcIds}
                entityTypeName={Contract.TypeNames.AwsEc2Vpc}
                key="vpc"
                location="miniGlance"
                title={localization.info.items.vpc()}/>
    };
}