import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function MicrosoftIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <g clipPath="url(#clip0_58343_2026)">
                <path
                    d="M11.4038 11.4038H0V0H11.4038V11.4038Z"
                    fill="#F1511B"/>
                <path
                    d="M23.9962 11.4038H12.5914V0H23.9952L23.9962 11.4038Z"
                    fill="#80CC28"/>
                <path
                    d="M11.4038 24H0V12.5962H11.4038V24Z"
                    fill="#00ADEF"/>
                <path
                    d="M23.9962 24H12.5914V12.5962H23.9952L23.9962 24Z"
                    fill="#FBBC09"/>
            </g>
            <defs>
                <clipPath id="clip0_58343_2026">
                    <rect
                        fill="white"
                        height="24"
                        width="24"/>
                </clipPath>
            </defs>
        </SvgIcon>);
}