import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition, useGetAwsEc2InstanceRiskContext } from "../../../../..";
import { Contract, Entity, entityModelStore, RegionModelHelper } from "../../../../../../../../../../../../../../common";

export function useAwsEc2InstanceMetadataServiceTokenHopMaxCountRiskInstanceDefinition(riskModel: Contract.AwsEc2InstanceMetadataServiceTokenHopMaxCountRiskModel) {
    const risk = riskModel.risk as Contract.AwsEc2InstanceMetadataServiceTokenHopMaxCountRisk;
    const instanceModel = entityModelStore.useGet(risk.entityId) as Contract.AwsEc2InstanceModel;
    const instance = instanceModel.entity as Contract.AwsEc2Instance;

    const getAwsEc2InstanceRiskContext = useGetAwsEc2InstanceRiskContext();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsEc2InstanceMetadataServiceTokenHopMaxCountRiskDefinition.hooks.useAwsEc2InstanceMetadataServiceTokenHopMaxCountRiskInstanceDefinition",
            () => ({
                description: "{{instance}} metadata response hop limit is greater than {{maxTokenHopMaxCount}}",
                sections: {
                    resolution: {
                        step1: "Setting the hop limit to {{maxTokenHopMaxCount}} can restrict network communications beyond the local network, potentially affecting applications that rely on multiple hops. Carefully evaluate your specific networking requirements, and any potential impact before implementing this change.",
                        step2: "Run the following command: aws ec2 modify-instance-metadata-options --instance-id {{instanceRawId}} --http-put-response-hop-limit {{maxTokenHopMaxCount}} --region {{instanceRegion}}"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            instance:
                <Entity
                    entityIdOrModel={instanceModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>,
            maxTokenHopMaxCount: risk.maxTokenHopMaxCount
        }),
        () => [
            localization.sections.resolution.step1({ maxTokenHopMaxCount: risk.maxTokenHopMaxCount }),
            localization.sections.resolution.step2({
                instanceRawId: instance.rawId,
                instanceRegion: RegionModelHelper.get(instance.regionId!).systemName,
                maxTokenHopMaxCount: risk.maxTokenHopMaxCount
            })
        ],
        riskModel,
        () => {
            const instanceContextItems = getAwsEc2InstanceRiskContext(instanceModel);
            return [
                instanceContextItems.generalInformation,
                instanceContextItems.sensitive,
                instanceContextItems.status,
                instanceContextItems.instanceRoleNotExists,
                instanceContextItems.getPermissionActionsContextItem(riskModel.risk.instanceIdToDataMap[instance.id]?.highSeverityRolePermissionActions),
                instanceContextItems.getInboundExternalAccessContextItem(
                    riskModel.risk.instanceIdToDataMap[instance.id]?.destinationNetworkScope,
                    riskModel.risk.instanceIdToDataMap[instance.id]?.securityGroupIds),
                instanceContextItems.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        });
}