import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition, useGetAzureWebApplicationRiskContext } from "../../..";
import { Contract, Entity, entityModelStore, useTheme } from "../../../../../../../../../../../../common";
import { AzureWebApplicationNetworkRulesTable } from "../../../../../../../../../../../../tenants";
import { useAzureWebApplicationTypeTranslator } from "../../../../../../../../../Entities/hooks";
import { RiskDefinitionSection } from "../../../../../../utilities";
import { EntityExternalConsoleLink } from "../../components";

export function useAzureWebApplicationInboundExternalWideRangeRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AzureWebApplicationInboundExternalWideRangeRisk;
    const applicationModel = entityModelStore.useGet(risk.entityId) as Contract.AzureWebApplicationModel;
    const application = applicationModel.entity as Contract.AzureWebApplication;

    const getAzureWebApplicationRiskContext = useGetAzureWebApplicationRiskContext();

    const webApplicationTypeTranslator = useAzureWebApplicationTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.compliance.useAzureWebApplicationInboundExternalWideRangeRiskDefinition",
            () => ({
                description: "{{applicationType}} {{application}} allows public invocation",
                sections: {
                    networkRules: "Access Restrictions",
                    resolution: {
                        step1: "Click on **Add Identity Provider** and complete the setup to set authentication.\nUnder **Restrict access**, make sure to select the **Require authentication** option."
                    }
                }
            }));

    const theme = useTheme();
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            application:
                <Entity
                    entityIdOrModel={applicationModel}
                    variant="text"/>,
            applicationType: webApplicationTypeTranslator(application.type)
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={risk.entityId}
                key={risk.entityId}
                page={Contract.AzureConsoleEntityPage.Authentication}/>,
            localization.sections.resolution.step1()
        ],
        riskModel,
        () => {
            const applicationRiskContext = getAzureWebApplicationRiskContext(applicationModel);
            return [
                applicationRiskContext.generalInformation,
                applicationRiskContext.sensitive,
                applicationRiskContext.authentication,
                applicationRiskContext.managedIdentities,
                applicationRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        },
        {
            sections: [
                new RiskDefinitionSection(
                    <AzureWebApplicationNetworkRulesTable
                        getHighlightColor={
                            (networkRule: Contract.AzureWebApplicationNetworkRule, opacity) =>
                                _.some(
                                    application.wideRangeNetworkRules,
                                    wideRangeNetworkRule =>
                                        !_.isNil(networkRule.sourceSubnets) &&
                                        networkRule.sourceSubnets.length === wideRangeNetworkRule.sourceSubnets!.length &&
                                        _.every(
                                            networkRule.sourceSubnets,
                                            sourceSubnet =>
                                                _.indexOf(
                                                    wideRangeNetworkRule.sourceSubnets,
                                                    sourceSubnet) > -1))
                                    ? opacity
                                        ? theme.palette.opacity(theme.palette.severity(riskModel.risk.severity), opacity)
                                        : theme.palette.severity(riskModel.risk.severity)
                                    : undefined}
                        networkRules={application.networkRules!}/>,
                    localization.sections.networkRules(),
                    { expandable: true })
            ]
        });
}