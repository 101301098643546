import { Link, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../..";
import { useCustomEntityPropertyInfoItemElements } from "../..";
import { useGcpCommonEncryptedResourceInfoElements, useGcpCommonServiceAccountOriginatorScopeResourceInfoElements, useGcpDefaultResourceInfoItemElements, useGcpDefaultScopeResourceInfoItemElements } from "..";
import { Contract, InfoItem } from "../../../../../../../../../../../common";
import { useGcpFunctionsFunctionTriggerTypeTranslator, useGcpFunctionsFunctionTypeTranslator, useGcpServerlessNetworkedResourceNetworkInboundAccessTypeTranslator } from "../../../../../../../hooks";
import { EntitiesInfoItem, Info } from "../../../../../components";
import { GcpScopeResourceRoleBindings } from "../../../components";
import { EnvironmentVariablesInfoCard } from "./components";

export function useGcpFunctionsFunctionDefinition(scopeResourceModel: Contract.GcpScopeResourceModel, options?: DefinitionOptions) {
    const commonEncryptedResourceInfoElements = useGcpCommonEncryptedResourceInfoElements(scopeResourceModel);
    const commonServiceAccountOriginatorScopeResourceInfoElements = useGcpCommonServiceAccountOriginatorScopeResourceInfoElements(scopeResourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(scopeResourceModel);
    const defaultResourceInfoItemElements = useGcpDefaultResourceInfoItemElements(scopeResourceModel);
    const defaultScopeResourceInfoItemElements = useGcpDefaultScopeResourceInfoItemElements(scopeResourceModel);
    const functionModel = scopeResourceModel as Contract.GcpFunctionsFunctionModel;
    const functionEntity = scopeResourceModel.entity as Contract.GcpFunctionsFunction;

    const functionsFunctionTypeTranslator = useGcpFunctionsFunctionTypeTranslator();
    const functionsFunctionTriggerTypeTranslator = useGcpFunctionsFunctionTriggerTypeTranslator();
    const serverlessNetworkedResourceNetworkInboundAccessTypeTranslator = useGcpServerlessNetworkedResourceNetworkInboundAccessTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpFunctionsFunctionDefinition",
            () => ({
                info: {
                    items: {
                        artifactRegistry: "Artifact Registry",
                        buildServiceAccount: "Build service account",
                        cloudRunService: "Cloud Run Service",
                        httpsOnly: {
                            false: "No",
                            title: "Require HTTPs",
                            true: "Yes"
                        },
                        networkInboundAccessType: "Ingress traffic",
                        pubSubTriggerTopic: "Triggering Topic",
                        triggerType: "Trigger Type",
                        triggerUrl: "URL",
                        type: "Environment",
                        version: "Version"
                    }
                },
                tabs: {
                    resourceRoleBindings: "Resource Role Bindings"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <GcpScopeResourceRoleBindings
                    csvExportFilePrefix={localization.tabs.resourceRoleBindings()}
                    scopeResourceModel={scopeResourceModel}/>,
                localization.tabs.resourceRoleBindings(),
                "resourceRoleBindings")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    ...defaultScopeResourceInfoItemElements,
                    commonEncryptedResourceInfoElements.encryptionExists,
                    commonEncryptedResourceInfoElements.kmsEncryptionKeys,
                    commonServiceAccountOriginatorScopeResourceInfoElements.serviceAccount,
                    <EntitiesInfoItem
                        entityIdsOrModels={functionModel.buildServiceAccountIdReference}
                        entityTypeName={Contract.TypeNames.GcpIamServiceAccount}
                        key="buildServiceAccount"
                        title={localization.info.items.buildServiceAccount()}/>,
                    <InfoItem
                        key="type"
                        title={localization.info.items.type()}
                        value={functionsFunctionTypeTranslator(functionEntity.type)}/>,
                    <InfoItem
                        key="triggerType"
                        title={localization.info.items.triggerType()}
                        value={functionsFunctionTriggerTypeTranslator(functionEntity.triggerType)}/>,
                    <InfoItem
                        key="version"
                        title={localization.info.items.version()}
                        value={
                            _.isNil(functionEntity.version)
                                ? undefined
                                : functionEntity.version}/>,
                    <InfoItem
                        key="triggerUrl"
                        title={localization.info.items.triggerUrl()}
                        value={
                            functionEntity.triggerType != Contract.GcpFunctionsFunctionTriggerType.Http
                                ? undefined
                                : <Link
                                    urlOrGetUrl={functionEntity.triggerUrl}
                                    variant="external"/>}/>,
                    <InfoItem
                        key="httpsOnly"
                        title={localization.info.items.httpsOnly.title()}
                        value={
                            functionEntity.triggerType !== Contract.GcpFunctionsFunctionTriggerType.Http
                                ? undefined
                                : functionEntity.httpsOnly
                                    ? localization.info.items.httpsOnly.true()
                                    : localization.info.items.httpsOnly.false()}/>,
                    <InfoItem
                        key="networkInboundAccessType"
                        title={localization.info.items.networkInboundAccessType()}
                        value={serverlessNetworkedResourceNetworkInboundAccessTypeTranslator(functionEntity.networkInboundAccessType!)}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={functionModel?.pubSubTriggerTopicIdReference}
                        entityTypeName={Contract.TypeNames.GcpPubSubTopic}
                        key="pubSubTriggerTopic"
                        title={localization.info.items.pubSubTriggerTopic()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={functionModel?.artifactRegistryIdReference}
                        entityTypeName={Contract.TypeNames.GcpArtifactRegistry}
                        key="artifactRegistry"
                        title={localization.info.items.artifactRegistry()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={functionModel?.cloudRunServiceIdReference}
                        entityTypeName={Contract.TypeNames.GcpCloudRunService}
                        key="cloudRunService"
                        title={localization.info.items.cloudRunService()}/>
                ]}
                options={
                    options?.infoOptions
                }>
                <EnvironmentVariablesInfoCard environmentVariables={functionEntity.environmentVariables}/>
            </Info>
    });
}