﻿import React from "react";
import { Contract, entityModelStore } from "../../../../../../../../../../../../../../common";
import { GcpRoleBindingTable, GcpRoleBindingTableColumnId } from "../../../../../../../../../../../../../../tenants";

type ScopeResourceIamRoleBindingTableSectionProps = {
    csvExportFilePrefixes: string[];
    risk: Contract.GcpScopeResourcePublicAccessExistsRisk;
};

export function ScopeResourceRoleBindingTableSection({ csvExportFilePrefixes, risk }: ScopeResourceIamRoleBindingTableSectionProps) {
    const scopeResourceModel = entityModelStore.useGet(risk.entityId) as Contract.GcpScopeResourceModel;
    return (
        <GcpRoleBindingTable
            csvExportFilePrefixes={csvExportFilePrefixes}
            initialFilterMap={{
                [GcpRoleBindingTableColumnId.PrincipalReference]: risk.principalIds,
                [GcpRoleBindingTableColumnId.RoleId]: risk.roleIds
            }}
            principalOrResourceId={scopeResourceModel.entity.id}
            roleBindingIds={scopeResourceModel.roleBindingIds}
            variant="principalAndRoleAndScope"/>);
}