import { useLocalization } from "@infrastructure";
import React from "react";
import { useAzureCommonNetworkingInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { Contract } from "../../../../../../../../../../common";
import { EntitiesInfoItem, Info } from "../../../../components";
import { NetworkingInfoCard } from "../../components";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";
import { useAzureDefaultResourceInfoItemElements } from "./useAzureDefaultResourceInfoItemElements";

export function useAzureNetworkNetworkInterfaceDefinition(resourceGroupResourceModel: Contract.AzureResourceGroupResourceModel, options?: DefinitionOptions) {
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceGroupResourceModel);
    const defaultResourceInfoItemElements = useAzureDefaultResourceInfoItemElements(resourceGroupResourceModel);
    const networkInterfaceModel = resourceGroupResourceModel as Contract.AzureNetworkNetworkInterfaceModel;
    const networkInterface = resourceGroupResourceModel.entity as Contract.AzureNetworkNetworkInterface;
    const networkInfoItems =
        useAzureCommonNetworkingInfoItemElements({
            macAddress: networkInterface.macAddress,
            resourceModel: resourceGroupResourceModel
        });

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.azure.useAzureNetworkNetworkInterfaceDefinition",
            () => ({
                info: {
                    items: {
                        dnsZoneIds: "DNS Zones",
                        virtualMachineId: "Attached To"
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <EntitiesInfoItem
                        entityIdsOrModels={networkInterfaceModel.virtualMachineId}
                        entityTypeName={Contract.TypeNames.AzureComputeVirtualMachine}
                        key="virtualMachineId"
                        title={localization.info.items.virtualMachineId()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={networkInterfaceModel.dnsZoneIds}
                        entityTypeName={Contract.TypeNames.Entity}
                        key="dnsZoneIds"
                        title={localization.info.items.dnsZoneIds()}/>
                ]}
                options={options?.infoOptions}>
                <NetworkingInfoCard>
                    {networkInfoItems.getPublicIpAddresses()}
                    {networkInfoItems.getPrivateIpAddresses()}
                    {networkInfoItems.getMacAddress()}
                    {networkInfoItems.getVirtualNetwork()}
                    {networkInfoItems.getSubnets()}
                    {networkInfoItems.getInterfaceNetworkSecurityGroups()}
                    {networkInfoItems.getApplicationSecurityGroups()}
                </NetworkingInfoCard>
            </Info>
    });
}