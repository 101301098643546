﻿import _ from "lodash";
import React from "react";
import { FormLayout, makeContextProvider, OrderedWizard, OrderedWizardItem, useLocalization } from "@infrastructure";
import { Contract } from "../../../../../../../../../../../../../../common";
import { useGitLabContext, useSetGitLabContext } from "../../GitLab";
import { ChooseServerItem, ConfigureOrganizationItem, FinishItem, OrganizationMemberSelectionItem } from "./components";

export class AddOrEditContext {
    public memberSelection?: Contract.OrganizationMemberSelection;
    public tenantPaths?: string[];
    public updatedAccessToken?: string;
    public updatedFolderEnabled?: boolean;
    public updatedFolderScopeNodeModel?: Contract.ScopeNodeModel;
    public updatedSyncEnabled?: boolean;

    constructor(
        public organizationModel?: Contract.CodeOrganizationModel,
        public updatedServerId?: string) {
        this.memberSelection = organizationModel?.configuration.memberSelection;
        this.updatedFolderEnabled = organizationModel?.configuration.folderEnabled;
    }
}

export const [useAddOrEditContext, useSetAddOrEditContext, useAddOrEditContextProvider] = makeContextProvider<AddOrEditContext>();

export function AddOrEditGitLab() {
    const { addOrEditOpen } = useGitLabContext();
    const setGitLabContext = useSetGitLabContext();

    const [, , ContextProvider] =
        useAddOrEditContextProvider(
            () =>
                new AddOrEditContext(
                    _.isBoolean(addOrEditOpen)
                        ? undefined
                        : addOrEditOpen,
                    _.isBoolean(addOrEditOpen)
                        ? undefined
                        : addOrEditOpen.configuration.serverId));

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCodeOrganizationItems.gitLab.addOrEditGitLab",
            () => ({
                steps: {
                    chooseServer: "Select GitLab Server",
                    configureOrganization: "Configure Group",
                    tenants: "Select Projects"
                },
                title: {
                    add: "Add GitLab Group",
                    edit: "Edit GitLab Group"
                }
            }));

    return (
        <ContextProvider>
            <FormLayout
                disableContentPadding={true}
                titleOptions={{
                    text:
                        _.isBoolean(addOrEditOpen)
                            ? localization.title.add()
                            : localization.title.edit()
                }}>
                <OrderedWizard
                    finishItemElement={<FinishItem/>}
                    startItemIndex={
                        !_.isBoolean(addOrEditOpen)
                            ? 1
                            : 0}
                    onClose={
                        () =>
                            setGitLabContext(
                                context => ({
                                    ...context,
                                    addOrEditOpen: false
                                }))}>
                    <OrderedWizardItem title={localization.steps.chooseServer()}>
                        <ChooseServerItem/>
                    </OrderedWizardItem>
                    <OrderedWizardItem title={localization.steps.configureOrganization()}>
                        <ConfigureOrganizationItem/>
                    </OrderedWizardItem>
                    <OrderedWizardItem
                        deferredLoading={true}
                        title={localization.steps.tenants()}>
                        <OrganizationMemberSelectionItem/>
                    </OrderedWizardItem>
                </OrderedWizard>
            </FormLayout>
        </ContextProvider>);
}