import { AppError, Optional, useExecuteOperation, useLocalization, WizardItem } from "@infrastructure";
import _ from "lodash";
import React, { ReactNode, useState } from "react";
import { Contract, TenantController } from "../../../../../..";
import { RiskResolutionAutomationContext } from "../../../../utilities";
import { AwsAccessKeyItem, AwsEditItem, AwsSummaryItem } from "./components";

export class AwsRiskResolutionAutomationContext extends RiskResolutionAutomationContext {
    public awsAccessKey?: Contract.AwsIamAccessKey;

    constructor(
        changeModels: Contract.ChangeModel[],
        riskedEntityModel: Contract.EntityModel,
        public storedAwsAccessKey: boolean,
        public storedAwsAccessKeyUniqueId: Optional<string>) {
        super(
            changeModels,
            riskedEntityModel);
    }
}

export function useAwsDefinition(changeModels: Contract.ChangeModel[], riskedEntityModel: Contract.EntityModel) {
    if (_(changeModels).
        flatMap(changeModel => changeModel.changeDatas).
        flatMap(changeData => changeData.riskId).
        uniq().
        size() !== 1) {
        throw new AppError("Expected only one risk");
    }

    const resourceTenantId = changeModels[0].changeDatas[0].riskTenantId;
    const [{ uniqueId: storedAwsAccessKeyUniqueId }] =
        useExecuteOperation(
            [AwsAccessKeyItem, resourceTenantId],
            () =>
                TenantController.validateStoredAwsAccessKey(
                    new Contract.TenantControllerValidateStoredAwsAccessKeyRequest(
                        resourceTenantId)));

    const [showAccessKeyItem, setShowAccessKeyItem] = useState(false);
    const localization =
        useLocalization(
            "common.riskResolutionAutomation.hooks.useDefinition.hooks.useAwsDefinition",
            () => ({
                items: {
                    edit: "The following steps are required in order to perform the remediation. Select a step to view more details and customize it",
                    summary: "The following steps will be applied once you click the **Apply** button"
                }
            }));

    return {
        createContext:
            () =>
                new AwsRiskResolutionAutomationContext(
                    changeModels,
                    riskedEntityModel,
                    !_.isNil(storedAwsAccessKeyUniqueId),
                    storedAwsAccessKeyUniqueId),
        wizardItems:
            _<ReactNode>([]).
                concat(
                    <WizardItem
                        disablePadding={true}
                        subtitle={localization.items.edit()}>
                        <AwsEditItem
                            showAccessKeyItem={showAccessKeyItem}
                            onShowAccessKeyItemChanged={setShowAccessKeyItem}/>
                    </WizardItem>).
                concatIf(
                    showAccessKeyItem ||
                    _.some(
                        changeModels,
                        changeModel => !changeModel.changeable),
                    <WizardItem deferredLoading={true}>
                        <AwsAccessKeyItem/>
                    </WizardItem>).
                concat(
                    <WizardItem
                        disablePadding={true}
                        subtitle={localization.items.summary()}>
                        <AwsSummaryItem/>
                    </WizardItem>).
                value()
    };
}