import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, EnumValuesFilter, InlineItems, optionalTableCell, PagedValuesFilter, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { useOciCommonResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions, useEntityTypeNameTranslator } from "../../../../../../../../../../common";
import { useOciLoadBalancingLoadBalancerStatusTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";

export function useOciLoadBalancingLoadBalancerDefinition(definitionData: DefinitionData) {
    const commonResourceDefinition = useOciCommonResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const loadBalancerStatusTranslator = useOciLoadBalancingLoadBalancerStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.oci.useOciLoadBalancingLoadBalancerDefinition",
            () => ({
                columns: {
                    external: {
                        false: "Private",
                        title: "Visibility Type",
                        true: "Public"
                    },
                    ipAddresses: {
                        private: "Private IP",
                        public: "Public IP"
                    },
                    logsEnabled: {
                        false: "Disabled",
                        title: {
                            access: "Access Logs",
                            error: "Error Logs"
                        },
                        true: "Enabled"
                    },
                    status: "State",
                    webApplicationFirewall: "Web Application Firewall"
                }
            }));

    return new EntityTableDefinition(
        [
            commonResourceDefinition.columns.tenantColumn,
            commonResourceDefinition.columns.creationTimeColumn,
            commonResourceDefinition.columns.creatorCsvColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.OciLoadBalancingLoadBalancerModel) => ({
                            [localization.columns.status()]: loadBalancerStatusTranslator((item.entity as Contract.OciLoadBalancingLoadBalancer).status)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        default: true,
                        element:
                            <EnumValuesFilter
                                enumType={Contract.OciLoadBalancingLoadBalancerStatus}
                                enumTypeTranslator={loadBalancerStatusTranslator}
                                placeholder={localization.columns.status()}/>
                    }
                }}
                id={Contract.EntityModelProperty.OciLoadBalancingLoadBalancerStatus}
                itemProperty={(item: Contract.OciLoadBalancingLoadBalancerModel) => loadBalancerStatusTranslator((item.entity as Contract.OciLoadBalancingLoadBalancer).status)}
                key={Contract.EntityModelProperty.OciLoadBalancingLoadBalancerStatus}
                title={localization.columns.status()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.OciLoadBalancingLoadBalancerModel) => ({
                            [localization.columns.external.title()]:
                                (item.entity as Contract.OciLoadBalancingLoadBalancer).external
                                    ? localization.columns.external.true()
                                    : localization.columns.external.false()
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        default: true,
                        element:
                            <ValuesFilter placeholder={localization.columns.external.title()}>
                                <ValuesFilterItem
                                    title={localization.columns.external.false()}
                                    value={false}/>
                                <ValuesFilterItem
                                    title={localization.columns.external.true()}
                                    value={true}/>
                            </ValuesFilter>
                    }
                }}
                id={Contract.EntityModelProperty.OciLoadBalancingLoadBalancerExternal}
                itemProperty={
                    (item: Contract.EntityModel) =>
                        (item.entity as Contract.OciLoadBalancingLoadBalancer).external
                            ? localization.columns.external.true()
                            : localization.columns.external.false()}
                key={Contract.EntityModelProperty.OciLoadBalancingLoadBalancerExternal}
                title={localization.columns.external.title()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.OciLoadBalancingLoadBalancerModel) => ({
                            [localization.columns.ipAddresses.public()]: (item.entity as Contract.OciLoadBalancingLoadBalancer).publicIpAddresses.join("\n")
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedValuesFilter
                                getValuePage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.OciLoadBalancingLoadBalancerPublicIpAddresses)}
                                placeholder={localization.columns.ipAddresses.public()}/>
                    }
                }}
                id={Contract.EntityModelProperty.OciLoadBalancingLoadBalancerPublicIpAddresses}
                key={Contract.EntityModelProperty.OciLoadBalancingLoadBalancerPublicIpAddresses}
                render={
                    optionalTableCell<Contract.OciLoadBalancingLoadBalancerModel>(
                        item =>
                            _.isEmpty((item.entity as Contract.OciLoadBalancingLoadBalancer).publicIpAddresses)
                                ? undefined
                                : <InlineItems
                                    items={(item.entity as Contract.OciLoadBalancingLoadBalancer).publicIpAddresses}
                                    variant="itemPlusItemCount"/>)}
                title={localization.columns.ipAddresses.public()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.OciLoadBalancingLoadBalancerModel) => ({
                            [localization.columns.ipAddresses.private()]: (item.entity as Contract.OciLoadBalancingLoadBalancer).privateIpAddresses.join("\n")
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedValuesFilter
                                getValuePage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.OciLoadBalancingLoadBalancerPrivateIpAddresses)}
                                placeholder={localization.columns.ipAddresses.private()}/>
                    }
                }}
                id={Contract.EntityModelProperty.OciLoadBalancingLoadBalancerPrivateIpAddresses}
                key={Contract.EntityModelProperty.OciLoadBalancingLoadBalancerPrivateIpAddresses}
                render={
                    optionalTableCell<Contract.OciLoadBalancingLoadBalancerModel>(
                        item =>
                            _.isEmpty((item.entity as Contract.OciLoadBalancingLoadBalancer).privateIpAddresses)
                                ? undefined
                                : <InlineItems
                                    items={(item.entity as Contract.OciLoadBalancingLoadBalancer).privateIpAddresses}
                                    variant="itemPlusItemCount"/>)}
                title={localization.columns.ipAddresses.private()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.OciLoadBalancingLoadBalancerModel>(
                        Contract.TypeNames.OciNetworkingVcn,
                        item => [item.vcnIdReference],
                        entityTypeNameTranslator(Contract.TypeNames.OciNetworkingVcn))}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.OciLoadBalancingLoadBalancerVcn)}
                                placeholder={entityTypeNameTranslator(Contract.TypeNames.OciNetworkingVcn)}/>
                    }
                }}
                id={Contract.EntityModelProperty.OciLoadBalancingLoadBalancerVcn}
                key={Contract.EntityModelProperty.OciLoadBalancingLoadBalancerVcn}
                render={
                    optionalTableCell<Contract.OciLoadBalancingLoadBalancerModel>(
                        item =>
                            _.isNil(item.vcnIdReference)
                                ? undefined
                                : <EntitiesCell
                                    entityIdsOrModels={item.vcnIdReference}
                                    entityTypeName={Contract.TypeNames.OciNetworkingVcn}/>)}
                title={entityTypeNameTranslator(Contract.TypeNames.OciNetworkingVcn)}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.OciLoadBalancingLoadBalancerModel>(
                        Contract.TypeNames.OciNetworkingNetworkSecurityGroup,
                        item => item.securityGroupIdReferences,
                        entityTypeNameTranslator(Contract.TypeNames.OciNetworkingNetworkSecurityGroup))}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.OciLoadBalancingLoadBalancerSecurityGroups)}
                                placeholder={
                                    entityTypeNameTranslator(
                                        Contract.TypeNames.OciNetworkingNetworkSecurityGroup,
                                        { count: 0 })}/>
                    }
                }}
                id={Contract.EntityModelProperty.OciLoadBalancingLoadBalancerSecurityGroups}
                key={Contract.EntityModelProperty.OciLoadBalancingLoadBalancerSecurityGroups}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.OciLoadBalancingLoadBalancerModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.securityGroupIdReferences}
                            entityTypeName={Contract.TypeNames.OciNetworkingNetworkSecurityGroup}/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={
                    entityTypeNameTranslator(
                        Contract.TypeNames.OciNetworkingNetworkSecurityGroup,
                        { count: 0 })}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.OciLoadBalancingLoadBalancerModel) => ({
                            [localization.columns.webApplicationFirewall()]: (item.entity as Contract.OciLoadBalancingLoadBalancer).webApplicationFirewallOcid ?? ""
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.OciLoadBalancingLoadBalancerWebApplicationFirewall)}
                                placeholder={localization.columns.webApplicationFirewall()}/>
                    }
                }}
                id={Contract.EntityModelProperty.OciLoadBalancingLoadBalancerWebApplicationFirewall}
                key={Contract.EntityModelProperty.OciLoadBalancingLoadBalancerWebApplicationFirewall}
                render={
                    optionalTableCell<Contract.OciLoadBalancingLoadBalancerModel>(
                        item =>
                            _.isNil((item.entity as Contract.OciLoadBalancingLoadBalancer).webApplicationFirewallOcid)
                                ? undefined
                                : <EntitiesCell
                                    entityIdsOrModels={item.webApplicationFirewallIdReference}
                                    entityTypeName={Contract.TypeNames.OciWebAppFirewallGenericResource}/>)}
                title={localization.columns.webApplicationFirewall()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.OciLoadBalancingLoadBalancerModel) => ({
                            [localization.columns.logsEnabled.title.access()]:
                                item.accessLogsEnabled
                                    ? localization.columns.logsEnabled.true()
                                    : localization.columns.logsEnabled.false()
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <ValuesFilter placeholder={localization.columns.logsEnabled.title.access()}>
                                <ValuesFilterItem
                                    title={localization.columns.logsEnabled.false()}
                                    value={false}/>
                                <ValuesFilterItem
                                    title={localization.columns.logsEnabled.true()}
                                    value={true}/>
                            </ValuesFilter>
                    }
                }}
                id={Contract.EntityModelProperty.OciLoadBalancingLoadBalancerAccessLogsEnabled}
                itemProperty={
                    (item: Contract.OciLoadBalancingLoadBalancerModel) =>
                        item.accessLogsEnabled
                            ? localization.columns.logsEnabled.true()
                            : localization.columns.logsEnabled.false()}
                key={Contract.EntityModelProperty.OciLoadBalancingLoadBalancerAccessLogsEnabled}
                selectorOptions={{ default: false }}
                title={localization.columns.logsEnabled.title.access()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.OciLoadBalancingLoadBalancerModel) => ({
                            [localization.columns.logsEnabled.title.error()]:
                                item.errorLogsEnabled
                                    ? localization.columns.logsEnabled.true()
                                    : localization.columns.logsEnabled.false()
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <ValuesFilter placeholder={localization.columns.logsEnabled.title.error()}>
                                <ValuesFilterItem
                                    title={localization.columns.logsEnabled.false()}
                                    value={false}/>
                                <ValuesFilterItem
                                    title={localization.columns.logsEnabled.true()}
                                    value={true}/>
                            </ValuesFilter>
                    }
                }}
                id={Contract.EntityModelProperty.OciLoadBalancingLoadBalancerErrorLogsEnabled}
                itemProperty={
                    (item: Contract.OciLoadBalancingLoadBalancerModel) =>
                        item.errorLogsEnabled
                            ? localization.columns.logsEnabled.true()
                            : localization.columns.logsEnabled.false()}
                key={Contract.EntityModelProperty.OciLoadBalancingLoadBalancerErrorLogsEnabled}
                selectorOptions={{ default: false }}
                title={localization.columns.logsEnabled.title.error()}/>,
            commonResourceDefinition.columns.regionColumn,
            commonResourceDefinition.columns.regionLocationColumn,
            commonResourceDefinition.columns.tagsColumn,
            commonResourceDefinition.columns.attributesColumn,
            commonResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageOciLoadBalancingLoadBalancerRequest(
                new Contract.EntityControllerGetEntityModelPageOciLoadBalancingLoadBalancerRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciLoadBalancingLoadBalancerAccessLogsEnabled]),
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciLoadBalancingLoadBalancerErrorLogsEnabled]),
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciLoadBalancingLoadBalancerExternal]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciLoadBalancingLoadBalancerPrivateIpAddresses]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciLoadBalancingLoadBalancerPublicIpAddresses]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciLoadBalancingLoadBalancerSecurityGroups]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciLoadBalancingLoadBalancerStatus]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciLoadBalancingLoadBalancerVcn]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciLoadBalancingLoadBalancerWebApplicationFirewall])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags]))));
}