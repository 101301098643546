import { map } from "@infrastructure";
import { Contract, tenantModelStore } from "../../../../../../../../../../../../../../../common";
import { GcpConsoleUrlBuilder } from "../../../../../../../../../../../../../../../tenants";
import { EntityExternalConsoleLinkDefinition } from "../useDefinition";

export function useGcpIamServiceAccountDefinition(entityModel: Contract.EntityModel, page: Contract.GcpConsolePage) {
    const serviceAccount = entityModel.entity as Contract.GcpIamServiceAccount;
    const tenantModel = tenantModelStore.useGet(entityModel.entity.tenantId);

    const getUrl =
        map(
            page,
            {
                [Contract.GcpConsolePage.Keys]: () => GcpConsoleUrlBuilder.GetIamServiceAccountKeysUrl,
                [Contract.GcpConsolePage.Permissions]: () => GcpConsoleUrlBuilder.GetIamServiceAccountPermissionsUrl
            });

    return new EntityExternalConsoleLinkDefinition(
        getUrl?.(
            (tenantModel.configuration as Contract.GcpTenantConfiguration).rawShortNameId,
            serviceAccount.uniqueId));
}