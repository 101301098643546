﻿import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition, useGetAwsCloudFrontDistributionRiskContext } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";

export function useAwsCloudFrontDistributionLoggingDisabledRiskDefinition(riskModel: Contract.RiskModel) {
    const distributionLoggingEnabledRiskModel = riskModel as Contract.AwsCloudFrontDistributionLoggingDisabledRiskModel;
    const distributionModel = entityModelStore.useGet(distributionLoggingEnabledRiskModel.risk.entityId) as Contract.AwsCloudFrontDistributionModel;
    const distribution = distributionModel.entity as Contract.AwsCloudFrontDistribution;

    const getAwsCloudFrontDistributionRiskContext = useGetAwsCloudFrontDistributionRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsCloudFrontDistributionLoggingDisabledRiskDefinition",
            () => ({
                description: "{{distribution}} logging is not enabled",
                sections: {
                    resolution: {
                        step1: "Under **Settings**, click **Edit**",
                        step2: "Navigate to **Standard logging** and select **On**",
                        step3: "Select the **S3 bucket** in which you want CloudFront to deliver the logs",
                        step4: "Click **Save changes**"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            distribution:
                <Entity
                    entityIdOrModel={distributionModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.CloudFront,
                AwsConsoleUrlBuilder.getCloudFrontDistributionUrl(distribution)),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4()
        ],
        distributionLoggingEnabledRiskModel,
        () => {
            const distributionRiskContext = getAwsCloudFrontDistributionRiskContext(distributionModel);
            return [
                distributionRiskContext.generalInformation,
                distributionRiskContext.status,
                distributionRiskContext.originResources,
                distributionRiskContext.sensitive,
                distributionRiskContext.sensitiveOrigin,
                distributionRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        });
}