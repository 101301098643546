import { useMemo } from "react";
import { Contract } from "../../../../../../../../../../../../../../../../../../../../common";
import { useDefaultDefinition } from "../useDefaultDefinition";
import { useAwsEc2NetworkResourceInboundRuleSubnetAnyExistsRiskDefinition, useNetworkInboundExternalResourceRiskDefinition } from "./hooks";

export function useNetworkDefinition(riskPolicyConfigurationTypeName: string) {
    const useDefinition =
        useMemo(
            () => {
                switch (riskPolicyConfigurationTypeName) {
                    case Contract.TypeNames.AwsEc2NetworkAclInboundRuleSubnetAnyExistsRiskPolicyConfiguration:
                    case Contract.TypeNames.AwsEc2SecurityGroupInboundRuleSubnetAnyExistsRiskPolicyConfiguration:
                        return useAwsEc2NetworkResourceInboundRuleSubnetAnyExistsRiskDefinition;
                    case Contract.TypeNames.AwsInboundExternalRdsDatabaseInstanceRiskPolicyConfiguration:
                    case Contract.TypeNames.AwsInboundExternalRedshiftClusterRiskPolicyConfiguration:
                    case Contract.TypeNames.AzureInboundExternalComputeVirtualMachineRiskPolicyConfiguration:
                    case Contract.TypeNames.AzureInboundExternalComputeVirtualMachineScaleSetRiskPolicyConfiguration:
                    case Contract.TypeNames.GcpInboundExternalComputeInstanceRiskPolicyConfiguration:
                        return useNetworkInboundExternalResourceRiskDefinition;
                    default:
                        return useDefaultDefinition;
                }
            },
            []);

    return useDefinition(riskPolicyConfigurationTypeName);
}