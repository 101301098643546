﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import path from "path";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../..";
import { useGcpDefaultResourceInfoItemElements, useGcpDefaultScopeResourceInfoItemElements } from "..";
import { Contract, InfoItem } from "../../../../../../../../../../../common";
import { EntitiesInfoItem, Info } from "../../../../../components";
import { GcpScopeResourceRoleBindings } from "../../../components";
import { useCustomEntityPropertyInfoItemElements } from "../../useCustomEntityPropertyInfoItemElements";
import { useGcpCommonComputeLoadBalancerResourceInfoItemElements } from "../useGcpCommonComputeLoadBalancerResourceInfoItemElements";
import { RulesInfoCard } from "./components";

export function useGcpComputeUrlMapDefinition(scopeResourceModel: Contract.GcpScopeResourceModel, options?: DefinitionOptions) {
    const urlMapModel = scopeResourceModel as Contract.GcpComputeUrlMapModel;
    const computeLoadBalancerResourceInfoItemElements = useGcpCommonComputeLoadBalancerResourceInfoItemElements(urlMapModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(scopeResourceModel);
    const defaultResourceInfoItemElements = useGcpDefaultResourceInfoItemElements(scopeResourceModel);
    const defaultScopeResourceInfoItemElements = useGcpDefaultScopeResourceInfoItemElements(scopeResourceModel);
    const urlMap = urlMapModel.entity as Contract.GcpComputeUrlMap;

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpComputeUrlMapDefinition",
            () => ({
                info: {
                    items: {
                        backendResourceIdReferences: "Backends",
                        defaultBackend: "Default Backend"
                    }
                },
                tabs: {
                    resourceRoleBindings: "Resource Role Bindings"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <GcpScopeResourceRoleBindings
                    csvExportFilePrefix={localization.tabs.resourceRoleBindings()}
                    scopeResourceModel={scopeResourceModel}/>,
                localization.tabs.resourceRoleBindings(),
                "resourceRoleBindings")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    ...defaultScopeResourceInfoItemElements,
                    computeLoadBalancerResourceInfoItemElements.loadBalancerIdsInfoItemElement,
                    <EntitiesInfoItem
                        entityIdsOrModels={urlMapModel.backendResourceIdReferences}
                        entityTypeName={Contract.TypeNames.GcpScopeResource}
                        key="backendResourceIdReferences"
                        title={localization.info.items.backendResourceIdReferences()}/>,
                    !_.isNil(urlMap.defaultBackend.resourceUrls)
                        ? <EntitiesInfoItem
                            entityIdsOrModels={
                                _.map(
                                    urlMap.defaultBackend.resourceUrls,
                                    backendResourceUrl => urlMapModel.backendResourceUrlToSearchableIdReferenceMap[backendResourceUrl].idReference)}
                            entityTypeName={Contract.TypeNames.GcpScopeResource}
                            key="defaultBackend"
                            title={localization.info.items.defaultBackend()}/>
                        : <InfoItem
                            key="defaultBackend"
                            title={localization.info.items.defaultBackend()}
                            value={
                                path.join(
                                    urlMap.defaultBackend.redirectHost ?? "*",
                                    urlMap.defaultBackend.redirectPath ?? "*")}/>
                ]}
                options={options?.infoOptions}>
                <RulesInfoCard
                    backendResourceUrlToSearchableIdReferenceMap={urlMapModel.backendResourceUrlToSearchableIdReferenceMap}
                    rules={urlMap.rules}/>
            </Info>
    });
}