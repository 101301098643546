import { InlineItems, InlineItemsVariant, useLocalization } from "@infrastructure";
import React from "react";
import { Contract } from "../../../common";


type InlineAzureKeyVaultVaultSecretPermissionsProps = {
    permissions: Contract.AzureKeyVaultVaultSecretPermission[];
    variant: InlineItemsVariant;
};

export function InlineAzureKeyVaultVaultSecretPermissions({ permissions, variant }: InlineAzureKeyVaultVaultSecretPermissionsProps) {
    const localization =
        useLocalization(
            "tenants.azure.inlineAzureKeyVaultVaultSecretPermissions",
            () => ({
                permissions: [
                    "1 secret permission",
                    "{{count | NumberFormatter.humanize}} secret permissions"
                ],
                [Contract.TypeNames.AzureKeyVaultVaultSecretPermission]: {
                    [Contract.AzureKeyVaultVaultSecretPermission.All]: "All",
                    [Contract.AzureKeyVaultVaultSecretPermission.Backup]: "Backup",
                    [Contract.AzureKeyVaultVaultSecretPermission.Delete]: "Delete",
                    [Contract.AzureKeyVaultVaultSecretPermission.Get]: "Get",
                    [Contract.AzureKeyVaultVaultSecretPermission.List]: "List",
                    [Contract.AzureKeyVaultVaultSecretPermission.Purge]: "Purge",
                    [Contract.AzureKeyVaultVaultSecretPermission.Recover]: "Recover",
                    [Contract.AzureKeyVaultVaultSecretPermission.Restore]: "Restore",
                    [Contract.AzureKeyVaultVaultSecretPermission.Set]: "Set"
                }
            }));
    return (
        <InlineItems
            items={permissions}
            namePluralizer={localization.permissions}
            variant={variant}>
            {(permission: Contract.AzureKeyVaultVaultSecretPermission) => localization[Contract.TypeNames.AzureKeyVaultVaultSecretPermission][permission]()}
        </InlineItems>);
}