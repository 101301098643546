import { InlineItems, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { useCommonSectionsAndDescriptionDefinition, useGetGcpComputeInstanceTemplateRiskContext } from "../../../../..";
import { Contract, Entity, entityModelStore, InlineEntities, SeveritySquare, TypeHelper, useSeverityTranslator } from "../../../../../../../../../../../../../../common";
import { GcpComputeInstanceStatus } from "../../../../../../../../../../../../../../common/controllers/types.generated";
import { RiskDefinitionContextItem } from "../../../../../../../../utilities";
import { EntityExternalConsoleLink } from "../../../../components";
import { useGetGcpInboundExternalComputeInstanceRiskSourceResourceRiskContext } from "./useGetGcpInboundExternalComputeInstanceRiskSourceResourceRiskContext";

export function useGcpInboundExternalComputeInstanceRiskInstanceTemplateDefinition(riskModel: Contract.RiskModel) {
    const inboundExternalComputeInstanceRiskModel = riskModel as Contract.GcpInboundExternalComputeInstanceRiskModel;
    const instanceModels = entityModelStore.useGet(inboundExternalComputeInstanceRiskModel.risk.aggregatedEntityIds) as Contract.GcpComputeInstanceModel[];
    const instanceTemplateModel = entityModelStore.useGet(inboundExternalComputeInstanceRiskModel.risk.entityId) as Contract.GcpComputeInstanceTemplateModel;
    const serviceAccountModels =
        entityModelStore.useGet(
            _(instanceModels).
                map(instanceModel => instanceModel.serviceAccountIdReference).
                filter().
                value() as string[]);

    const networkTags =
        _(instanceModels).
            flatMap(instanceModel => (instanceModel.entity as Contract.GcpComputeInstance).networkTags).
            uniq().
            value();
    const permissionActionSeverity =
        _(serviceAccountModels).
            map(serviceAccountModel => _.as<Contract.IGcpIamServiceAccountModel>(serviceAccountModel).permissionActionSeverity).
            filter().
            maxBy(
                severity =>
                    TypeHelper.getEnumValue(
                        Contract.TypeNames.Severity,
                        severity!));
    const runningInstances =
        _.filter(
            instanceModels,
            instanceModel => (instanceModel.entity as Contract.GcpComputeInstance).status === GcpComputeInstanceStatus.Running);
    const subnetIds =
        _(instanceModels).
            flatMap(instanceModel => instanceModel.subnetIdReferences).
            uniq().
            value();
    const vpcIds =
        _(instanceModels).
            flatMap(instanceModel => instanceModel.vpcIdReferences).
            uniq().
            value();

    const sensitiveInstanceModels =
        useMemo(
            () =>
                _.filter(
                    instanceModels,
                    instanceModel => instanceModel.entityConfiguration?.sensitive === true),
            [instanceModels]);

    const getGcpComputeInstanceTemplateRiskContext = useGetGcpComputeInstanceTemplateRiskContext();
    const getGcpInboundExternalComputeInstanceRiskSourceResourceRiskContext = useGetGcpInboundExternalComputeInstanceRiskSourceResourceRiskContext();

    const severityTranslator = useSeverityTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.useGcpInboundExternalComputeInstanceRiskDefinition.hooks.useGcpInboundExternalComputeInstanceRiskInstanceTemplateDefinition",
            () => ({
                contextItems: {
                    networkTags: {
                        pluralizer: [
                            "1 network tag",
                            "{{count | NumberFormatter.humanize}} network tags"
                        ],
                        tags: "The VM instances have {{tags}} attached"
                    },
                    sensitive: [
                        "The {{instances}} is marked as sensitive",
                        "The {{instances}} are marked as sensitive"
                    ],
                    serviceAccounts: {
                        notAttached: "The VM instances are not attached to any service account",
                        withoutPermissions: "The VM instances are attached to {{serviceAccounts}} with no permissions",
                        withPermissions: "The VM instances are attached to {{serviceAccounts}} that has {{severitySquare}}**{{serviceAccountPermissionsRiskSeverity}}** severity permissions"
                    },
                    status: {
                        allRunning: [
                            "The instance template launched {{instanceIds}}, which is in Running state",
                            "The instance template launched {{instanceIds}}, all in Running state"
                        ],
                        nonRunning: [
                            "The instance template launched {{instanceIds}}, which is not in Running state",
                            "The instance template launched {{instanceIds}}, none in Running state"
                        ],
                        someRunning: [
                            "The instance template launched {{instanceIds}}, {{runningInstanceIds}} is in Running state",
                            "The instance template launched {{instanceIds}}, {{runningInstanceIds}} are in Running state"
                        ]
                    },
                    vpcsAndSubnets: "The instance template is part of {{vpcIds}} and {{subnetIds}}"
                },
                description: [
                    "{{instanceTemplate}} launched {{instanceIds}} that is directly accessible from a wide range of public IP addresses",
                    "{{instanceTemplate}} launched {{instanceIds}} that are directly accessible from a wide range of public IP addresses"
                ],
                sections: {
                    resolution: {
                        step1: "Before changing any ingress (inbound) rule, verify that doing so will not break existing communications for the VM instance or any other instance in the network",
                        step2: "For each relevant ingress (inbound) rule, edit the source address range to restrict it to only the IP addresses that require access, or, if access is not required, delete the rule"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description(
            instanceModels.length,
            {
                instanceIds:
                    <InlineEntities
                        entityIdsOrModels={instanceModels}
                        entityTypeName={Contract.TypeNames.GcpComputeInstance}
                        variant="itemCountAndType"/>,
                instanceTemplate:
                    <Entity
                        entityIdOrModel={instanceTemplateModel}
                        entityTypeNameTranslatorOptions={{ variant: "title" }}
                        variant="typeText"/>
            }),
        () =>
            [
                <EntityExternalConsoleLink
                    entityId={riskModel.risk.tenantId}
                    key={riskModel.risk.tenantId}
                    page={Contract.GcpConsolePage.Firewalls}/>,
                localization.sections.resolution.step1(),
                localization.sections.resolution.step2()
            ],
        riskModel,
        () => {
            const instanceRiskSourceResourceContextItems = getGcpInboundExternalComputeInstanceRiskSourceResourceRiskContext(inboundExternalComputeInstanceRiskModel);
            const instanceTemplateContextItems = getGcpComputeInstanceTemplateRiskContext(instanceTemplateModel);
            const localizationContextItemsStatus =
                instanceModels.length === runningInstances.length
                    ? localization.contextItems.status.allRunning
                    : _.some(runningInstances)
                        ? localization.contextItems.status.someRunning
                        : localization.contextItems.status.nonRunning;
            return [
                instanceTemplateContextItems.generalInformation,
                instanceTemplateContextItems.sensitive,
                _.isEmpty(sensitiveInstanceModels)
                    ? undefined
                    : new RiskDefinitionContextItem(
                        localization.contextItems.sensitive(
                            sensitiveInstanceModels.length,
                            {
                                instances:
                                    <InlineEntities
                                        entityIdsOrModels={sensitiveInstanceModels}
                                        entityTypeName={Contract.TypeNames.GcpComputeInstance}
                                        variant="itemCountAndType"/>
                            })),
                new RiskDefinitionContextItem(
                    localization.contextItems.vpcsAndSubnets({
                        subnetIds:
                            <InlineEntities
                                entityIdsOrModels={subnetIds}
                                entityTypeName={Contract.TypeNames.GcpComputeSubnet}
                                variant="itemCountAndType"/>,
                        vpcIds:
                            <InlineEntities
                                entityIdsOrModels={vpcIds}
                                entityTypeName={Contract.TypeNames.GcpComputeVpc}
                                variant="itemCountAndType"/>
                    })),
                new RiskDefinitionContextItem(
                    localizationContextItemsStatus(
                        _.some(runningInstances)
                            ? runningInstances.length
                            : instanceModels.length,
                        {
                            instanceIds:
                                <InlineEntities
                                    entityIdsOrModels={instanceModels}
                                    entityTypeName={Contract.TypeNames.GcpComputeInstance}
                                    variant="itemAndTypeOrItemCountAndType"/>,
                            runningInstanceIds:
                                <InlineEntities
                                    entityIdsOrModels={runningInstances}
                                    entityTypeName={Contract.TypeNames.GcpComputeInstance}
                                    variant="itemCount"/>
                        })),
                new RiskDefinitionContextItem(
                    _.isEmpty(serviceAccountModels)
                        ? localization.contextItems.serviceAccounts.notAttached()
                        : _.isNil(permissionActionSeverity)
                            ? localization.contextItems.serviceAccounts.withoutPermissions({
                                serviceAccounts:
                                    <InlineEntities
                                        entityIdsOrModels={serviceAccountModels}
                                        entityTypeName={Contract.TypeNames.GcpIamServiceAccount}
                                        variant="itemAndTypeOrItemCountAndType"/>
                            })
                            : localization.contextItems.serviceAccounts.withPermissions({
                                serviceAccountPermissionsRiskSeverity: severityTranslator(permissionActionSeverity!, "text"),
                                serviceAccounts:
                                    <InlineEntities
                                        entityIdsOrModels={serviceAccountModels}
                                        entityTypeName={Contract.TypeNames.GcpIamServiceAccount}
                                        variant="itemAndTypeOrItemCountAndType"/>,
                                severitySquare: <SeveritySquare severity={permissionActionSeverity!}/>
                            })),
                _.isEmpty(networkTags)
                    ? undefined
                    : new RiskDefinitionContextItem(
                        localization.contextItems.networkTags.tags({
                            tags:
                                <InlineItems
                                    items={networkTags}
                                    namePluralizer={localization.contextItems.networkTags.pluralizer}
                                    variant="itemCountAndType"/>
                        })),
                instanceRiskSourceResourceContextItems.networkSecurityPerimeterResourceIdsAndExposedNetworkScopes,
                instanceTemplateContextItems.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        });
}