﻿import { useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import React from "react";
import { Contract, EntityController } from "../../../../../../../controllers";
import { useEntityTypeNameTranslator } from "../../../../../../../hooks";
import { ElasticsearchItemPageHelper } from "../../../../../../../utilities";
import { PagedEntityMultiSelect } from "../../../../../../PagedEntityMultiSelect";
import { useCustomRiskPolicyContext } from "../../../../../CustomRiskPolicy";
import { EntityMultiSelectPermissionEvaluationEntities, EntityMultiSelectProperty, useMultiSelectorContext, useSetMultiSelectorContext } from "../../../EntityMultiSelect";

type AzureScopeResourceParentEntityIdsProps = {
    disabled?: boolean;
    entityTypeName: string;
    excludeCommonCloudProviderTenants: boolean;
    includeServiceName: boolean;
    permissionEvaluationEntities?: EntityMultiSelectPermissionEvaluationEntities;
    scopeResourceParentEntityTypeName?: string;
};

export function AzureScopeResourceParentEntityIds({ disabled, entityTypeName, excludeCommonCloudProviderTenants, includeServiceName, permissionEvaluationEntities, scopeResourceParentEntityTypeName }: AzureScopeResourceParentEntityIdsProps) {
    const { scopeId } = useCustomRiskPolicyContext();
    const { propertyIdToConditionMap } = useMultiSelectorContext();
    const condition = propertyIdToConditionMap[EntityMultiSelectProperty.AzureScopeResourceParentEntityIds] as Contract.AzureScopeResourceParentEntityIdMatchCondition;
    const parentEntityTypeName = scopeResourceParentEntityTypeName ?? Contract.TypeNames.AzureResource;
    const setMultiSelectorContext = useSetMultiSelectorContext();
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "common.customRiskPolicy.entityMultiSelect.condition.azureScopeResourceParentEntityIds",
            () => ({
                title: "*capitalize*{{translatedEntityTypeName}}** in selected scopes or lower"
            }));

    return (
        <Stack
            alignItems="center"
            direction="row"
            spacing={1}>
            <Typography noWrap={true}>
                {localization.title({
                    translatedEntityTypeName:
                        entityTypeNameTranslator(
                            entityTypeName,
                            {
                                count: 0,
                                includeServiceName,
                                variant: "text"
                            })
                })}
            </Typography>
            <PagedEntityMultiSelect
                disabled={disabled}
                fieldOptions={{ dense: true }}
                getEntityModelPage={
                    ElasticsearchItemPageHelper.makePagedEntityMultiSelect(
                        async (itemNextPageSearchCursor, searchText) => {
                            const { entityModelPage } =
                                await EntityController.searchEntityModels(
                                    permissionEvaluationEntities === EntityMultiSelectPermissionEvaluationEntities.Azure
                                        ? new Contract.EntityControllerSearchEntityModelsAzurePermissionEvaluationRequest(
                                            false,
                                            15,
                                            itemNextPageSearchCursor,
                                            scopeId,
                                            searchText)
                                        : new Contract.EntityControllerSearchEntityModelsTypeRequest(
                                            false,
                                            15,
                                            itemNextPageSearchCursor,
                                            scopeId,
                                            searchText,
                                            excludeCommonCloudProviderTenants,
                                            parentEntityTypeName));
                            return entityModelPage;
                        })}
                placeholder={
                    entityTypeNameTranslator(
                        parentEntityTypeName,
                        {
                            count: 0,
                            includeServiceName: false
                        })}
                selectedEntityIds={condition.parentEntityIds}
                onSelectedEntityIdsChanged={
                    parentEntityIds =>
                        setMultiSelectorContext(
                            context => ({
                                propertyIdToConditionMap: {
                                    ...context.propertyIdToConditionMap,
                                    [EntityMultiSelectProperty.AzureScopeResourceParentEntityIds]: new Contract.AzureScopeResourceParentEntityIdMatchCondition(parentEntityIds)

                                }
                            }))}/>
        </Stack>);
}