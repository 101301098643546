import { Link, Message, Step, Steps, UnexpectedError, useLocalization } from "@infrastructure";
import { Box, Divider, Stack } from "@mui/material";
import _ from "lodash";
import React, { ReactNode, useMemo } from "react";
import { RiskContentProps } from "../../../../..";
import { Change, Contract, Entity, entityModelStore, FeatureHelper, InlineEntities, InlineGroupIdentities, InlinePermissionActions, MultiLineEllipsis, SeveritySquare, tenantModelStore, TypeHelper, useEntityTypeNameTranslator, useSeverityTranslator } from "../../../../../../../../../../../../../common";
import { AadConsoleUrlBuilder } from "../../../../../../../../../../../../../tenants/aad/utilities/aadConsoleUrlBuilder.generated";
import { RiskView } from "../../../../../../../../../utilities";
import { ChangeStep, makeAccessResolutionSectionDefinitionComponent, RiskDefinitionSection, RiskDefinitionSectionCategory } from "../../../../../../../utilities";
import { EntityExternalConsoleLink, EntityRoleSchedulesExternalConsoleLink } from "../../../components";
import { useDefinition } from "../hooks/useDefinition";
import { useAzureCommonAccessPrincipalRiskDefinition } from "../useAzureCommonAccessPrincipalRiskDefinition";
import { GenerateNonexcessiveRoleDefinition, ReplaceRoleAssignmentResourcesChangeDiff, ResolutionCategoryViewLink } from "./components";

export function useAzureExcessivePermissionPrincipalRiskDefinition(riskModel: Contract.RiskModel) {
    const excessivePermissionPrincipalRiskModel = riskModel as Contract.AzureExcessivePermissionPrincipalRiskModel;
    const excessivePermissionPrincipalRisk = riskModel.risk as Contract.AzureExcessivePermissionPrincipalRisk;
    const principalModel = entityModelStore.useGet(excessivePermissionPrincipalRiskModel.risk.entityId) as Contract.AadDirectoryPrincipalModel;
    const severityTranslator = useSeverityTranslator();

    const accessScope =
        useMemo(
            () =>
                _.isEmpty(excessivePermissionPrincipalRisk.excessiveDirectMembershipGroupIds)
                    ? Contract.EntityAccessScope.GranteePermitterInvolved
                    : Contract.EntityAccessScope.Full,
            [excessivePermissionPrincipalRisk]);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.access.useAzureExcessivePermissionPrincipalRiskDefinition",
            () => ({
                description: "{{principal}} has {{severitySquare}}**{{removedExcessivePermissionActionsSeverity}}** severity permissions that can be removed",
                granteePermitterInvolvedAccessGraphHelpText: "This view displays {{principal}}’s direct permissions on subscription {{tenantId}} only. {{principal}} may also have permissions through groups and permissions on other subscriptions."
            }));

    const descriptionProps = {
        principal:
            <Entity
                entityIdOrModel={principalModel}
                entityTypeNameTranslatorOptions={{ variant: "title" }}
                variant="typeText"/>,
        removedExcessivePermissionActionsSeverity: severityTranslator(excessivePermissionPrincipalRisk.removedExcessivePermissionActionsSeverity!, "text"),
        severitySquare:
            <SeveritySquare severity={excessivePermissionPrincipalRisk.removedExcessivePermissionActionsSeverity!}/>
    };

    return useAzureCommonAccessPrincipalRiskDefinition(
        accessScope,
        <ContextSection riskModel={riskModel}/>,
        localization.description(descriptionProps),
        riskModel,
        {
            accessGraphActionsElement:
                accessScope === Contract.EntityAccessScope.GranteePermitterInvolved
                    ? <Message
                        level="info"
                        title={
                            localization.granteePermitterInvolvedAccessGraphHelpText(
                                {
                                    principal:
                                        <Entity
                                            entityIdOrModel={principalModel}
                                            variant="text"/>,
                                    tenantId:
                                        <Entity
                                            entityIdOrModel={excessivePermissionPrincipalRiskModel.risk.tenantId}
                                            variant="text"/>
                                })}
                        variant="minimal"/>
                    : undefined,
            customResolutionSection:
                _.some(
                    excessivePermissionPrincipalRisk.excessiveScopeRoleAssignmentResourceDatas,
                    excessiveScopeRoleAssignmentResourceData => excessiveScopeRoleAssignmentResourceData.resolution === Contract.AzureExcessivePermissionPrincipalRiskRoleAssignmentResourceResolution.Replace)
                    ? new RiskDefinitionSection(
                        <CustomResolutionSection riskModel={riskModel}/>,
                        "",
                        {
                            categoryView: {
                                category: RiskDefinitionSectionCategory.Resolution,
                                view:
                                    excessivePermissionPrincipalRisk.excessiveScopeRoleAssignmentResourceDatas.length > 1
                                        ? RiskView.ResolutionAzureCustomRoles
                                        : RiskView.ResolutionAzureCustomRole
                            }
                        })
                    : undefined,
            resolutionSectionDefinitionComponent
        });
}

function ContextSection({ riskModel }: RiskContentProps) {
    const excessivePermissionPrincipalRiskModel = riskModel as Contract.AzureExcessivePermissionPrincipalRiskModel;
    const principalModel = entityModelStore.useGet(excessivePermissionPrincipalRiskModel.risk.entityId) as Contract.AadDirectoryPrincipalModel;
    const principal = principalModel.entity as Contract.AadDirectoryPrincipal;
    const definition = useDefinition(excessivePermissionPrincipalRiskModel);
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const severityTranslator = useSeverityTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.access.useAzureExcessivePermissionPrincipalRiskDefinition.contextSection",
            () => ({
                resolution: {
                    [Contract.TypeNames.AzureExcessivePermissionPrincipalRiskModelResolutionPermittersType]: {
                        [Contract.AzureExcessivePermissionPrincipalRiskModelResolutionPermittersType.ClassicAdministrator]: "The classic administrator",
                        [Contract.AzureExcessivePermissionPrincipalRiskModelResolutionPermittersType.ClassicAdministratorAndRoleAssignmentsAllMany]: "These role assignments and the classic administrator",
                        [Contract.AzureExcessivePermissionPrincipalRiskModelResolutionPermittersType.ClassicAdministratorAndRoleAssignmentsAllSingle]: "This role assignment and the classic administrator",
                        [Contract.AzureExcessivePermissionPrincipalRiskModelResolutionPermittersType.ClassicAdministratorAndRoleAssignmentsSome]: "{{roleAssignmentResourceIds}} and the classic administrator",
                        [Contract.AzureExcessivePermissionPrincipalRiskModelResolutionPermittersType.RoleAssignmentsAllMany]: "These role assignments",
                        [Contract.AzureExcessivePermissionPrincipalRiskModelResolutionPermittersType.RoleAssignmentsAllSingle]: "This role assignment",
                        [Contract.AzureExcessivePermissionPrincipalRiskModelResolutionPermittersType.RoleAssignmentsSome]: "{{roleAssignmentResourceIds}}"
                    },
                    [Contract.TypeNames.AzureExcessivePermissionPrincipalRiskModelResolutionType]: {
                        [Contract.AzureExcessivePermissionPrincipalRiskModelResolutionType.ReplaceMany]: "{{replacePermitters}} can be replaced with less privileged roles",
                        [Contract.AzureExcessivePermissionPrincipalRiskModelResolutionType.ReplaceSingle]: "{{replacePermitters}} can be replaced with a less privileged role",
                        [Contract.AzureExcessivePermissionPrincipalRiskModelResolutionType.Delete]: "{{deletePermitters}} can be removed",
                        [Contract.AzureExcessivePermissionPrincipalRiskModelResolutionType.DeleteAndReplaceMany]: "{{deletePermitters}} can be removed and {{replacePermitters}} can be replaced with less privileged roles",
                        [Contract.AzureExcessivePermissionPrincipalRiskModelResolutionType.DeleteAndReplaceSingle]: "{{deletePermitters}} can be removed and {{replacePermitters}} can be replaced with a less privileged role"
                    }
                },
                sensitiveExcessivePermissionActions: [
                    "1 sensitive excessive permission",
                    "{{count | NumberFormatter.humanize}} sensitive excessive permissions"
                ],
                sensitiveResources: [
                    "1 sensitive resource",
                    "{{count | NumberFormatter.humanize}} sensitive resources"
                ],
                severity: {
                    empty: "This finding has {{severitySquare}}**{{severity}}** severity",
                    permissions: {
                        all: {
                            remaining: "After removing those permissions, the {{translatedPrincipalTypeName}} will only have **{{remainingPermissionActionSeverity}}** severity permissions, resulting in a **{{severity}}** impact of change",
                            removed: "All **{{severity}}** severity permissions can be removed"
                        },
                        some: {
                            remaining: "Even after removing those permissions, the {{translatedPrincipalTypeName}} will still have other **{{remainingPermissionActionSeverity}}** severity permissions, resulting in a **{{severity}}** impact of change",
                            removed: "Some **{{severity}}** severity permissions can be removed"
                        }
                    },
                    title: "This finding has {{severitySquare}}**{{severity}}** severity since:"
                },
                [Contract.TypeNames.AzureExcessivePermissionPrincipalRiskModelInfoType]: {
                    [Contract.AzureExcessivePermissionPrincipalRiskModelInfoType.CreationTime]: "*capitalize*{{translatedPrincipalTypeName}}** {{principal}} was created {{creationTime | TimeFormatter.humanizePastDuration}}",
                    [Contract.AzureExcessivePermissionPrincipalRiskModelInfoType.ExcessiveDirectMembershipGroupIds]: "The {{translatedPrincipalTypeName}} is a member of {{excessiveDirectMembershipGroupIds}} granting it {{excessiveDirectMembershipGroupsPermissionActionSeveritySquare}}**{{excessiveDirectMembershipGroupsPermissionActionSeverity}}** severity permissions that based on Azure activity log can be removed.",
                    [Contract.AzureExcessivePermissionPrincipalRiskModelInfoType.GroupMembers]: "The {{translatedPrincipalTypeName}} has {{groupIdentityIds}}",
                    [Contract.AzureExcessivePermissionPrincipalRiskModelInfoType.IdentityDisabled]: "The {{translatedPrincipalTypeName}} sign-in is disabled in Microsoft Entra ID, which reduces the finding severity",
                    [Contract.AzureExcessivePermissionPrincipalRiskModelInfoType.OriginatorResources]: "The {{translatedPrincipalTypeName}} is linked to {{originatorResourceIds}}",
                    [Contract.AzureExcessivePermissionPrincipalRiskModelInfoType.SensitiveResourcesSensitiveExcessivePermissionActionsAny]: {
                        anyRemovedSensitiveExcessivePermissions: "The {{translatedPrincipalTypeName}} has {{sensitiveExcessivePermissionActions}} on {{sensitiveResources}} (of which {{sensitiveRemovedExcessivePermissionActions}} on {{sensitiveResources}} can be removed)",
                        noRemovedSensitiveExcessivePermissions: "The {{translatedPrincipalTypeName}} has {{sensitiveExcessivePermissionActions}} on {{sensitiveResources}}"
                    },
                    [Contract.AzureExcessivePermissionPrincipalRiskModelInfoType.SeverityAndResolution]: {
                        [Contract.TypeNames.AadDirectoryGroup]: "The {{translatedPrincipalTypeName}} {{permitters}} granting its {{groupIdentityIds}} {{permissionActionSeveritySquare}}**{{permissionActionSeverity}}** severity permissions that based on Azure activity log can be removed. {{resolution}}",
                        [Contract.TypeNames.AadDirectoryIdentity]: "The {{translatedPrincipalTypeName}} {{permitters}} granting it {{permissionActionSeveritySquare}}**{{permissionActionSeverity}}** severity permissions that based on Azure activity log can be removed. {{resolution}}"
                    },
                    [Contract.AzureExcessivePermissionPrincipalRiskModelInfoType.SignInNever]: "The {{translatedPrincipalTypeName}} was not active since it was created",
                    [Contract.AzureExcessivePermissionPrincipalRiskModelInfoType.SignInTime]: "The {{translatedPrincipalTypeName}} was last active {{signInTime | TimeFormatter.humanizePastDuration}}",
                    [Contract.AzureExcessivePermissionPrincipalRiskModelInfoType.SignInTimeNotExact]: "The {{translatedPrincipalTypeName}} was last active more than {{activityTime | TimeFormatter.humanizeDuration}}"
                },
                [Contract.TypeNames.AzureExcessivePermissionPrincipalRiskModelPermittersType]: {
                    [Contract.AzureExcessivePermissionPrincipalRiskModelPermittersType.ClassicAdministrator]: "is a classic administrator",
                    [Contract.AzureExcessivePermissionPrincipalRiskModelPermittersType.ClassicAdministratorAndRoleAssignments]: "has {{roleAssignmentResourceIds}} and is a classic administrator",
                    [Contract.AzureExcessivePermissionPrincipalRiskModelPermittersType.RoleAssignments]: "has {{roleAssignmentResourceIds}}"
                }

            }));
    const createInfoProps =
        () => ({
            activityTime: excessivePermissionPrincipalRiskModel.risk.activityTime,
            creationTime: principal.creationTime,
            excessiveDirectMembershipGroupIds:
                <InlineEntities
                    entityIdsOrModels={excessivePermissionPrincipalRiskModel.risk.excessiveDirectMembershipGroupIds}
                    entityTypeName={Contract.TypeNames.AadDirectoryGroup}
                    variant="itemCountAndType"/>,
            excessiveDirectMembershipGroupsPermissionActionSeverity:
                _.isNil(excessivePermissionPrincipalRiskModel.risk.excessiveDirectMembershipGroupsPermissionActionSeverity)
                    ? undefined
                    : severityTranslator(excessivePermissionPrincipalRiskModel.risk.excessiveDirectMembershipGroupsPermissionActionSeverity!, "text"),
            excessiveDirectMembershipGroupsPermissionActionSeveritySquare:
                _.isNil(excessivePermissionPrincipalRiskModel.risk.excessiveDirectMembershipGroupsPermissionActionSeverity)
                    ? undefined
                    : <SeveritySquare severity={excessivePermissionPrincipalRiskModel.risk.excessiveDirectMembershipGroupsPermissionActionSeverity!}/>,
            groupIdentityIds:
                <InlineGroupIdentities
                    identityIds={excessivePermissionPrincipalRiskModel.risk.groupIdentityIds}
                    identityTypeName={Contract.TypeNames.AadDirectoryIdentity}/>,
            originatorResourceIds:
                <InlineEntities
                    entityIdsOrModels={excessivePermissionPrincipalRiskModel.risk.originatorResourceIds}
                    entityTypeName={Contract.TypeNames.AzureResource}
                    variant="itemAndTypeOrItemCountAndType"/>,
            principal:
                <Entity
                    entityIdOrModel={principalModel}
                    variant="text"/>,
            signInTime: excessivePermissionPrincipalRiskModel.risk.signInTime,
            translatedPrincipalTypeName:
                entityTypeNameTranslator(
                    principalModel.entity.typeName,
                    {
                        includeServiceName: false,
                        variant: "text"
                    })
        });
    const createPermittersInfo =
        (info: Contract.AzureExcessivePermissionPrincipalRiskModelRoleAssignmentResourcesInfo, resolution: Contract.AzureExcessivePermissionPrincipalRiskRoleAssignmentResourceResolution) =>
            _.isNil(info.resolutionToPermittersTypeMap[resolution])
                ? undefined
                : localization.resolution[Contract.TypeNames.AzureExcessivePermissionPrincipalRiskModelResolutionPermittersType][info.resolutionToPermittersTypeMap[resolution]]({
                    roleAssignmentResourceIds:
                        <InlineEntities
                            entityIdsOrModels={excessivePermissionPrincipalRiskModel.risk.roleAssignmentResourceResolutionToPermittersMap[resolution].roleAssignmentResourceIds}
                            entityTypeName={Contract.TypeNames.AzureAuthorizationRoleAssignmentResource}
                            variant="itemCountAndType"/>
                });
    const createRoleAssignmentsInfoProps =
        (info: Contract.AzureExcessivePermissionPrincipalRiskModelRoleAssignmentResourcesInfo) => ({
            groupIdentityIds:
                <InlineGroupIdentities
                    identityIds={excessivePermissionPrincipalRiskModel.risk.groupIdentityIds}
                    identityTypeName={Contract.TypeNames.AadDirectoryIdentity}/>,
            permissionActionSeverity:
                severityTranslator(info.permissionActionSeverity, "text"),
            permissionActionSeveritySquare: <SeveritySquare severity={info.permissionActionSeverity}/>,
            permitters:
                localization[Contract.TypeNames.AzureExcessivePermissionPrincipalRiskModelPermittersType][info.permittersType]({
                    roleAssignmentResourceIds:
                        <InlineEntities
                            entityIdsOrModels={info.permitters.roleAssignmentResourceIds}
                            entityTypeName={Contract.TypeNames.AzureAuthorizationRoleAssignmentResource}
                            variant="itemCountAndType"/>
                }),
            resolution:
                localization.resolution[Contract.TypeNames.AzureExcessivePermissionPrincipalRiskModelResolutionType][info.resolutionType]({
                    deletePermitters:
                        createPermittersInfo(info, Contract.AzureExcessivePermissionPrincipalRiskRoleAssignmentResourceResolution.Delete),
                    replacePermitters:
                        createPermittersInfo(info, Contract.AzureExcessivePermissionPrincipalRiskRoleAssignmentResourceResolution.Replace)
                })
        });
    const removedExcessivePermissionActionsSeverityValue =
        _.isNil(excessivePermissionPrincipalRiskModel.risk.removedExcessivePermissionActionsSeverity)
            ? undefined
            : TypeHelper.getEnumValue(Contract.TypeNames.Severity, excessivePermissionPrincipalRiskModel.risk.removedExcessivePermissionActionsSeverity);
    const remainingPermissionActionSeverityValue =
        _.isNil(excessivePermissionPrincipalRiskModel.risk.remainingPermissionActionSeverity)
            ? undefined
            : TypeHelper.getEnumValue(Contract.TypeNames.Severity, excessivePermissionPrincipalRiskModel.risk.remainingPermissionActionSeverity);

    return (
        <Steps>
            {_<(React.ReactNode | Step)>([]).
                concat(
                    _(excessivePermissionPrincipalRiskModel.infos).
                        filter(
                            info =>
                                info.type !== Contract.AzureExcessivePermissionPrincipalRiskModelInfoType.CreationTime ||
                                !_.isNil(principal.creationTime)).
                        map(
                            info => {
                                switch (info.type) {
                                    case Contract.AzureExcessivePermissionPrincipalRiskModelInfoType.CreationTime:
                                    case Contract.AzureExcessivePermissionPrincipalRiskModelInfoType.ExcessiveDirectMembershipGroupIds:
                                    case Contract.AzureExcessivePermissionPrincipalRiskModelInfoType.GroupMembers:
                                    case Contract.AzureExcessivePermissionPrincipalRiskModelInfoType.IdentityDisabled:
                                    case Contract.AzureExcessivePermissionPrincipalRiskModelInfoType.OriginatorResources:
                                    case Contract.AzureExcessivePermissionPrincipalRiskModelInfoType.SignInNever:
                                    case Contract.AzureExcessivePermissionPrincipalRiskModelInfoType.SignInTime:
                                    case Contract.AzureExcessivePermissionPrincipalRiskModelInfoType.SignInTimeNotExact:
                                        return localization[Contract.TypeNames.AzureExcessivePermissionPrincipalRiskModelInfoType][info.type](createInfoProps());
                                    case Contract.AzureExcessivePermissionPrincipalRiskModelInfoType.SensitiveResourcesSensitiveExcessivePermissionActionsAny:
                                        return (_.isEmpty(excessivePermissionPrincipalRiskModel.risk.sensitiveResources.sensitiveRemovedExcessivePermissionActions)
                                            ? localization[Contract.TypeNames.AzureExcessivePermissionPrincipalRiskModelInfoType][info.type].noRemovedSensitiveExcessivePermissions
                                            : localization[Contract.TypeNames.AzureExcessivePermissionPrincipalRiskModelInfoType][info.type].anyRemovedSensitiveExcessivePermissions)({
                                            sensitiveExcessivePermissionActions:
                                                <InlinePermissionActions
                                                    namePluralizer={localization.sensitiveExcessivePermissionActions}
                                                    permissionActions={excessivePermissionPrincipalRiskModel.risk.sensitiveResources.sensitiveExcessivePermissionActions}
                                                    variant="itemOrItemCountAndType"/>,
                                            sensitiveRemovedExcessivePermissionActions:
                                                <InlinePermissionActions
                                                    namePluralizer={localization.sensitiveExcessivePermissionActions}
                                                    permissionActions={excessivePermissionPrincipalRiskModel.risk.sensitiveResources.sensitiveRemovedExcessivePermissionActions}
                                                    variant="itemOrItemCountAndType"/>,
                                            sensitiveResources:
                                                <InlineEntities
                                                    entityIdsOrModels={excessivePermissionPrincipalRiskModel.risk.sensitiveResources.ids}
                                                    entityTypeName={Contract.TypeNames.AzureResource}
                                                    namePluralizer={localization.sensitiveResources}
                                                    variant="itemAndTypeOrItemCountAndType"/>,
                                            translatedPrincipalTypeName:
                                                entityTypeNameTranslator(
                                                    principalModel.entity.typeName,
                                                    {
                                                        includeServiceName: false,
                                                        variant: "text"
                                                    })
                                        });
                                    case Contract.AzureExcessivePermissionPrincipalRiskModelInfoType.SeverityAndResolution: {
                                        const principalType =
                                            principal.typeName === Contract.TypeNames.AadDirectoryGroup
                                                ? Contract.TypeNames.AadDirectoryGroup
                                                : Contract.TypeNames.AadDirectoryIdentity;
                                        return localization[Contract.TypeNames.AzureExcessivePermissionPrincipalRiskModelInfoType][info.type][principalType]({
                                            ...createInfoProps(),
                                            ...createRoleAssignmentsInfoProps(info as Contract.AzureExcessivePermissionPrincipalRiskModelRoleAssignmentResourcesInfo)
                                        });
                                    }
                                }
                            }).
                        value()).
                concat(
                    new Step(
                        (_(definition.additionalSeverityReasons).
                            filter().
                            isEmpty() && (
                            _.isNil(removedExcessivePermissionActionsSeverityValue) ||
                                _.isNil(remainingPermissionActionSeverityValue))
                            ? localization.severity.empty
                            : localization.severity.title)({
                            severity:
                                severityTranslator(riskModel.risk.severity, "text"),
                            severitySquare:
                                <SeveritySquare severity={riskModel.risk.severity}/>
                        }),
                        {
                            contentElement:
                                <Steps variant="bullets">
                                    {_.filter([
                                        ...definition.additionalSeverityReasons,
                                        _.isNil(removedExcessivePermissionActionsSeverityValue) ||
                                        _.isNil(remainingPermissionActionSeverityValue)
                                            ? undefined
                                            : (removedExcessivePermissionActionsSeverityValue <= remainingPermissionActionSeverityValue
                                                ? localization.severity.permissions.some.removed
                                                : localization.severity.permissions.all.removed)({
                                                severity:
                                                    severityTranslator(excessivePermissionPrincipalRiskModel.risk.removedExcessivePermissionActionsSeverity!, "text")
                                            }),
                                        _.isNil(removedExcessivePermissionActionsSeverityValue) ||
                                        _.isNil(remainingPermissionActionSeverityValue) ||
                                        FeatureHelper.enabled(Contract.FeatureName.ExcessivePermissionPrincipalRiskLegacySeverityEnabled)
                                            ? undefined
                                            : (removedExcessivePermissionActionsSeverityValue <= remainingPermissionActionSeverityValue
                                                ? localization.severity.permissions.some.remaining
                                                : localization.severity.permissions.all.remaining)({
                                                remainingPermissionActionSeverity:
                                                    severityTranslator(excessivePermissionPrincipalRiskModel.risk.remainingPermissionActionSeverity!, "text"),
                                                severity:
                                                    severityTranslator(excessivePermissionPrincipalRiskModel.risk.severity, "text"),
                                                translatedPrincipalTypeName:
                                                    entityTypeNameTranslator(
                                                        principalModel.entity.typeName,
                                                        {
                                                            includeServiceName: false,
                                                            variant: "text"
                                                        })
                                            })
                                    ])}
                                </Steps>
                        })).
                value()}
        </Steps>);
}

const resolutionSectionDefinitionComponent =
    makeAccessResolutionSectionDefinitionComponent(
        riskModel => {
            const excessivePermissionPrincipalRisk = riskModel.risk as Contract.AzureExcessivePermissionPrincipalRisk;
            const principalModel = entityModelStore.useGet(excessivePermissionPrincipalRisk.entityId) as Contract.AadDirectoryPrincipalModel;
            const aadTenantModel = tenantModelStore.useGet(principalModel.tenantId);
            const aadTenantConfiguration = aadTenantModel.configuration as Contract.AadTenantConfiguration;

            const entityModels =
                entityModelStore.useGet(
                    _.flatMap(
                        excessivePermissionPrincipalRisk.resolutionChanges,
                        resolutionChange => resolutionChange.entityIds));
            const entityModelMap =
                _.keyBy(
                    entityModels,
                    entityModel => entityModel.id);

            const localization =
                useLocalization(
                    "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.access.useAzureExcessivePermissionPrincipalRiskDefinition.resolutionSection",
                    () => ({
                        steps: {
                            createRoleAssignment: {
                                iamTabName: "Role assignments",
                                text: "Add role assignment with {{principal}} and {{newRoleDefinitionId}}",
                                title: "Assign {{principal}} with {{newRoleDefinitionIds}}"
                            },
                            createRoleAssignmentSchedule: "Add PIM active role assignment with {{principal}} and {{newRoleDefinitionId}}",
                            createRoleEligibilitySchedule: "Add PIM eligible role assignment with {{principal}} and {{newRoleDefinitionId}}",
                            deleteRoleAssignment: {
                                iamTabName: "Role assignments",
                                text: "Remove role assignment with {{principal}} and role {{roleDefinitionId}}",
                                title: "Remove {{principal}}‘s role assignments assigned to {{scopeEntityId}}"
                            },
                            deleteRoleAssignmentClassicAdministrator: {
                                iamTabName: "Classic administrators",
                                text: "Locate and remove {{principal}}",
                                title: "Remove {{principal}} as Classic administrator"
                            },
                            deleteRoleAssignmentSchedule: {
                                text: "Remove PIM active role assignment with {{principal}} and role {{roleDefinitionId}}",
                                title: "Remove {{principal}}‘s PIM role assignments assigned to {{scopeEntityId}}"
                            },
                            deleteRoleEligibilitySchedule: {
                                text: "Remove PIM eligible role assignment with {{principal}} and role {{roleDefinitionId}}",
                                title: "Remove {{principal}}‘s PIM role eligibilities assigned to {{scopeEntityId}}"
                            },
                            helpText: "This operation can only be performed through Azure API. Azure console does not support removing role assignments from this scope.",
                            permissionActionGroups: {
                                many: {
                                    step1: {
                                        link: "Group memberships page",
                                        text: "In Azure Portal, open the {{groupsLink}} of {{principal}}"
                                    },
                                    title: "Remove {{principal}}’s group memberships"
                                },
                                one: {
                                    step1: {
                                        link: "Members page",
                                        text: "In Azure Portal, open the {{groupMembersLink}} of group {{groupId}}"
                                    },
                                    step2: "Remove {{principal}} from the members list"
                                }
                            },
                            replaceRoleAssignmentResource: "Replace {{roleDefinitionIds}} with {{newRoleDefinitionIds}} assigned to {{scopeEntityId}}"
                        }
                    }));

            const createPrincipalResolutionProps =
                () => ({
                    principal:
                        <Entity
                            entityIdOrModel={principalModel}
                            linkOptions={{ disabled: true }}
                            variant="typeText"/>
                });
            const createScopeResolutionProps =
                (scopeEntityId: string) => ({
                    scopeEntityId:
                        <Entity
                            entityIdOrModel={scopeEntityId}
                            linkOptions={{ disabled: true }}
                            variant="typeText"/>
                });
            const createNewRoleAssignmentResourceResolutionProps =
                (newRoleDefinitionId: string) => ({
                    newRoleDefinitionId:
                        <Entity
                            entityIdOrModel={newRoleDefinitionId}
                            linkOptions={{ disabled: true }}
                            variant="typeText"/>
                });
            const createNewRoleAssignmentResourcesResolutionProps =
                (newRoleDefinitionIds: string[]) => ({
                    newRoleDefinitionIds:
                        <InlineEntities
                            entityIdsOrModels={newRoleDefinitionIds}
                            entityLinkOptions={{ disabled: true }}
                            entityTypeName={Contract.TypeNames.AzureAuthorizationRoleDefinition}
                            variant="itemAndTypeOrItemCountAndType"/>
                });
            const createDeleteRoleAssignmentResourceResolutionProps =
                (roleAssignmentResourceId: string) => ({
                    roleDefinitionId:
                        <Entity
                            entityIdOrModel={(entityModelMap[roleAssignmentResourceId].entity as Contract.AzureAuthorizationRoleAssignment).roleDefinitionId}
                            linkOptions={{ disabled: true }}
                            variant="text"/>
                });
            const createDeleteRoleAssignmentResourcesResolutionProps =
                (deleteRoleAssignmentResourceChanges: Contract.AzureDeleteRoleAssignmentResourceChange[]) => ({
                    roleDefinitionIds:
                        <InlineEntities
                            entityGlanceOptions={{ disabled: true }}
                            entityIdsOrModels={
                                _.map(
                                    deleteRoleAssignmentResourceChanges,
                                    deleteRoleAssignmentResourceChange => (entityModelMap[deleteRoleAssignmentResourceChange.resourceId].entity as Contract.AzureAuthorizationRoleAssignmentResource).roleDefinitionId)}
                            entityLinkOptions={{ disabled: true }}
                            entityTypeName={Contract.TypeNames.AzureAuthorizationRoleDefinition}
                            variant="itemAndTypeOrItemCountAndType"/>
                });

            function getAadDeletePrincipalGroupMembershipsChangeStep(changeSet: Contract.ChangeSet) {
                const deletePrincipalGroupMembershipChanges = changeSet.changes as Contract.AadDeletePrincipalGroupMembershipChange[];
                return new Step(
                    localization.steps.permissionActionGroups.many.title({
                        principal:
                            <Entity
                                entityIdOrModel={principalModel}
                                glanceOptions={{ disabled: true }}
                                linkOptions={{ disabled: true }}
                                variant="text"/>
                    }),
                    {
                        contentElement:
                            <Steps variant="plainNumbers">
                                {[
                                    localization.steps.permissionActionGroups.many.step1.text({
                                        groupsLink:
                                            principalModel.unknown
                                                ? localization.steps.permissionActionGroups.many.step1.link()
                                                : <Link
                                                    urlOrGetUrl={getPrincipalGroupMembershipUrl(principalModel.entity)}
                                                    variant="external">
                                                    {localization.steps.permissionActionGroups.many.step1.link()}
                                                </Link>,
                                        principal:
                                            <Entity
                                                entityIdOrModel={principalModel}
                                                entityTypeNameTranslatorOptions={{ variant: "text" }}
                                                linkOptions={{ disabled: true }}
                                                variant="typeText"/>
                                    }),
                                    ..._.map(
                                        deletePrincipalGroupMembershipChanges,
                                        deletePrincipalGroupMembershipChange =>
                                            <Change
                                                change={deletePrincipalGroupMembershipChange}
                                                key={deletePrincipalGroupMembershipChange.id}/>)
                                ]}
                            </Steps>
                    });
            }

            function getAadDeletePrincipalGroupMembershipChangeStep(aadDeletePrincipalGroupMembershipChange: Contract.AadDeletePrincipalGroupMembershipChange) {
                return new ChangeStep(
                    aadDeletePrincipalGroupMembershipChange,
                    {
                        contentElement:
                            <Steps variant="plainNumbers">
                                {localization.steps.permissionActionGroups.one.step1.text({
                                    groupId:
                                        <Entity
                                            entityIdOrModel={aadDeletePrincipalGroupMembershipChange.groupId}
                                            linkOptions={{ disabled: true }}
                                            variant="text"/>,
                                    groupMembersLink:
                                        <Link
                                            urlOrGetUrl={AadConsoleUrlBuilder.GetGroupMembersUrl(
                                                aadTenantConfiguration.partitionType,
                                                (entityModelMap[aadDeletePrincipalGroupMembershipChange.groupId].entity as Contract.AadDirectoryGroup).rawId)}
                                            variant="external">
                                            {localization.steps.permissionActionGroups.one.step1.link()}
                                        </Link>
                                })}
                                {localization.steps.permissionActionGroups.one.step2({
                                    principal:
                                        <Entity
                                            entityIdOrModel={principalModel}
                                            entityTypeNameTranslatorOptions={{ variant: "text" }}
                                            linkOptions={{ disabled: true }}
                                            variant="typeText"/>
                                })}
                            </Steps>
                    });
            }

            function getPrincipalGroupMembershipUrl(principal: Contract.Entity) {
                switch (principal.typeName) {
                    case Contract.TypeNames.AadDirectoryGroup:
                        return AadConsoleUrlBuilder.GetGroupGroupMembershipsUrl(
                            aadTenantConfiguration.partitionType,
                            (principal as Contract.AadDirectoryGroup).rawId);
                    case Contract.TypeNames.AadDirectoryUser:
                        return AadConsoleUrlBuilder.GetUserGroupMembershipsUrl(
                            aadTenantConfiguration.partitionType,
                            (principal as Contract.AadDirectoryUser).rawId);
                    default:
                        throw new UnexpectedError("principal.typeName", principal.typeName);
                }
            }

            function getRoleAssignmentResourceChangeStep(
                azureConsoleExternalLink: ReactNode,
                azureConsoleSupportRemove: boolean,
                title: ReactNode,
                options?: GetRoleAssignmentResourceChangeStepOptions) {
                return new Step(
                    title,
                    {
                        actionsElement:
                            !_.isNil(options?.actionElement) ||
                            !azureConsoleSupportRemove
                                ? <Stack
                                    alignItems="center"
                                    direction="row">
                                    <Box justifyContent="flex-start">
                                        {!azureConsoleSupportRemove
                                            ? <Message
                                                level="info"
                                                title={localization.steps.helpText()}
                                                variant="minimal"/>
                                            : undefined}
                                    </Box>
                                    <Box
                                        sx={{
                                            flex: 1,
                                            justifyContent: "flex-end"
                                        }}>
                                        {_.isNil(options?.actionElement)
                                            ? undefined
                                            : options!.actionElement}
                                    </Box>
                                </Stack>
                                : undefined,
                        contentElement:
                            <Steps variant="plainNumbers">
                                {_<ChangeStep | ReactNode>([]).
                                    concatIf(
                                        azureConsoleSupportRemove,
                                        () => azureConsoleExternalLink).
                                    concatIf(
                                        !_.isNil(options?.createRoleAssignmentResource),
                                        () =>
                                            _.map(
                                                options!.createRoleAssignmentResource!.changes,
                                                createRoleAssignmentResourceChange =>
                                                    new ChangeStep(
                                                        createRoleAssignmentResourceChange,
                                                        {
                                                            title:
                                                                options!.createRoleAssignmentResource!.translator({
                                                                    ...createPrincipalResolutionProps(),
                                                                    ...createNewRoleAssignmentResourceResolutionProps(createRoleAssignmentResourceChange.resourceId)
                                                                })
                                                        }))).
                                    concatIf(
                                        !_.isNil(options?.deleteRoleAssignmentResource),
                                        () =>
                                            _.map(
                                                options!.deleteRoleAssignmentResource!.changes,
                                                deleteRoleAssignmentResourceChange =>
                                                    new ChangeStep(
                                                        deleteRoleAssignmentResourceChange,
                                                        {
                                                            title:
                                                                options!.deleteRoleAssignmentResource!.translator({
                                                                    ...createPrincipalResolutionProps(),
                                                                    ...createDeleteRoleAssignmentResourceResolutionProps(deleteRoleAssignmentResourceChange.resourceId)
                                                                })
                                                        }))).
                                    value()}
                            </Steps>
                    });
            }

            function getCreateRoleAssignmentChangeStep(createRoleAssignmentChanges: Contract.AzureCreateRoleAssignmentChange[]) {
                const scopeEntityId = createRoleAssignmentChanges[0].scopeEntityId;
                const newRoleDefinitionIds =
                    _.map(
                        createRoleAssignmentChanges,
                        createRoleBindingChange => createRoleBindingChange.resourceId);
                return getRoleAssignmentResourceChangeStep(
                    <EntityExternalConsoleLink
                        entityId={scopeEntityId}
                        page={Contract.AzureConsoleEntityPage.RoleAssignments}
                        translatedTabName={localization.steps.createRoleAssignment.iamTabName()}/>,
                    entityModelMap[scopeEntityId].entity.typeName !== Contract.TypeNames.AzureStorageStorageAccountFileShare &&
                    entityModelMap[scopeEntityId].entity.typeName !== Contract.TypeNames.AzureStorageStorageAccountTable,
                    localization.steps.createRoleAssignment.title({
                        ...createPrincipalResolutionProps(),
                        ...(createNewRoleAssignmentResourcesResolutionProps(newRoleDefinitionIds))
                    }),
                    {
                        createRoleAssignmentResource: {
                            changes: createRoleAssignmentChanges,
                            translator: localization.steps.createRoleAssignment.text
                        }
                    });
            }

            function getDeleteRoleAssignmentChangeStep(changeSet: Contract.ChangeSet) {
                const deleteRoleAssignmentChanges = changeSet.changes as Contract.AzureDeleteRoleAssignmentChange[];
                const scopeEntityId = _.head(deleteRoleAssignmentChanges)!.scopeEntityId;
                return getRoleAssignmentResourceChangeStep(
                    <EntityExternalConsoleLink
                        entityId={scopeEntityId}
                        page={Contract.AzureConsoleEntityPage.RoleAssignments}
                        translatedTabName={localization.steps.deleteRoleAssignment.iamTabName()}/>,
                    entityModelMap[scopeEntityId].entity.typeName !== Contract.TypeNames.AzureStorageStorageAccountFileShare &&
                    entityModelMap[scopeEntityId].entity.typeName !== Contract.TypeNames.AzureStorageStorageAccountTable,
                    localization.steps.deleteRoleAssignment.title({
                        ...createPrincipalResolutionProps(),
                        ...createScopeResolutionProps(scopeEntityId)
                    }),
                    {
                        deleteRoleAssignmentResource: {
                            changes: deleteRoleAssignmentChanges,
                            translator: localization.steps.deleteRoleAssignment.text
                        }
                    });
            }

            function getReplaceRoleAssignmentChangeStep(changeSet: Contract.ChangeSet) {
                const createRoleAssignmentChanges =
                    _(changeSet.changes).
                        filter(
                            change =>
                                TypeHelper.extendOrImplement(
                                    change.typeName,
                                    Contract.TypeNames.AzureCreateRoleAssignmentChange)).
                        as<Contract.AzureCreateRoleAssignmentChange>().
                        value();
                const deleteRoleAssignmentChanges =
                    _(changeSet.changes).
                        filter(
                            change =>
                                TypeHelper.extendOrImplement(
                                    change.typeName,
                                    Contract.TypeNames.AzureDeleteRoleAssignmentChange)).
                        as<Contract.AzureDeleteRoleAssignmentChange>().
                        value();
                const scopeEntityId = createRoleAssignmentChanges[0].scopeEntityId;
                const newRoleDefinitionIds =
                    _.map(
                        createRoleAssignmentChanges,
                        createRoleAssignmentChange => createRoleAssignmentChange.resourceId);
                return getRoleAssignmentResourceChangeStep(
                    <EntityExternalConsoleLink
                        entityId={scopeEntityId}
                        page={Contract.AzureConsoleEntityPage.RoleAssignments}
                        translatedTabName={localization.steps.createRoleAssignment.iamTabName()}/>,
                    entityModelMap[scopeEntityId].entity.typeName !== Contract.TypeNames.AzureStorageStorageAccountFileShare &&
                    entityModelMap[scopeEntityId].entity.typeName !== Contract.TypeNames.AzureStorageStorageAccountTable,
                    localization.steps.replaceRoleAssignmentResource({
                        ...(createScopeResolutionProps(scopeEntityId)),
                        ...(createNewRoleAssignmentResourcesResolutionProps(newRoleDefinitionIds)),
                        ...(createDeleteRoleAssignmentResourcesResolutionProps(deleteRoleAssignmentChanges))
                    }),
                    {
                        actionElement:
                            <ReplaceRoleAssignmentResourcesChangeDiff
                                existingRoleDefinitionIds={
                                    _.map(
                                        deleteRoleAssignmentChanges,
                                        deleteRoleAssignmentChange => (entityModelMap[deleteRoleAssignmentChange.resourceId].entity as Contract.AzureAuthorizationRoleAssignment).roleDefinitionId)}
                                newRoleDefinitionIds={newRoleDefinitionIds}/>,
                        createRoleAssignmentResource: {
                            changes: createRoleAssignmentChanges,
                            translator: localization.steps.createRoleAssignment.text
                        },
                        deleteRoleAssignmentResource: {
                            changes: deleteRoleAssignmentChanges,
                            translator: localization.steps.deleteRoleAssignment.text
                        }
                    });
            }

            function getDeleteRoleAssignmentScheduleChangeStep(changeSet: Contract.ChangeSet) {
                const deleteRoleAssignmentScheduleChanges = changeSet.changes as Contract.AzureDeleteRoleAssignmentScheduleChange[];
                const scopeEntityId = _.head(deleteRoleAssignmentScheduleChanges)!.scopeEntityId;
                return getRoleAssignmentResourceChangeStep(
                    <EntityRoleSchedulesExternalConsoleLink
                        entityId={scopeEntityId}
                        roleAssignmentResourceTypeName={Contract.TypeNames.AzureAuthorizationRoleAssignmentSchedule}/>,
                    !TypeHelper.extendOrImplement(
                        entityModelMap[scopeEntityId].entity.typeName,
                        Contract.TypeNames.AzureStorageStorageAccountResource),
                    localization.steps.deleteRoleAssignmentSchedule.title({
                        ...createPrincipalResolutionProps(),
                        ...createScopeResolutionProps(scopeEntityId)
                    }),
                    {
                        deleteRoleAssignmentResource: {
                            changes: deleteRoleAssignmentScheduleChanges,
                            translator: localization.steps.deleteRoleAssignmentSchedule.text
                        }
                    });
            }

            function getReplaceRoleAssignmentScheduleChangeStep(changeSet: Contract.ChangeSet) {
                const createRoleAssignmentScheduleChanges =
                    _(changeSet.changes).
                        filter(
                            change =>
                                TypeHelper.extendOrImplement(
                                    change.typeName,
                                    Contract.TypeNames.AzureCreateRoleAssignmentScheduleChange)).
                        as<Contract.AzureCreateRoleAssignmentScheduleChange>().
                        value();
                const deleteRoleAssignmentScheduleChanges =
                    _(changeSet.changes).
                        filter(
                            change =>
                                TypeHelper.extendOrImplement(
                                    change.typeName,
                                    Contract.TypeNames.AzureDeleteRoleAssignmentScheduleChange)).
                        as<Contract.AzureDeleteRoleAssignmentScheduleChange>().
                        value();
                const scopeEntityId = createRoleAssignmentScheduleChanges[0].scopeEntityId;
                const newRoleDefinitionIds =
                    _.map(
                        createRoleAssignmentScheduleChanges,
                        createRoleAssignmentScheduleChange => createRoleAssignmentScheduleChange.resourceId);
                return getRoleAssignmentResourceChangeStep(
                    <EntityRoleSchedulesExternalConsoleLink
                        entityId={scopeEntityId}
                        roleAssignmentResourceTypeName={Contract.TypeNames.AzureAuthorizationRoleAssignmentSchedule}/>,
                    !TypeHelper.extendOrImplement(
                        entityModelMap[scopeEntityId].entity.typeName,
                        Contract.TypeNames.AzureStorageStorageAccountResource),
                    localization.steps.replaceRoleAssignmentResource({
                        ...createScopeResolutionProps(scopeEntityId),
                        ...createNewRoleAssignmentResourcesResolutionProps(newRoleDefinitionIds),
                        ...createDeleteRoleAssignmentResourcesResolutionProps(deleteRoleAssignmentScheduleChanges)
                    }),
                    {
                        actionElement:
                            <ReplaceRoleAssignmentResourcesChangeDiff
                                existingRoleDefinitionIds={
                                    _.map(
                                        deleteRoleAssignmentScheduleChanges,
                                        deleteRoleAssignmentScheduleChange => (entityModelMap[deleteRoleAssignmentScheduleChange.resourceId].entity as Contract.AzureAuthorizationRoleAssignmentSchedule).roleDefinitionId)}
                                newRoleDefinitionIds={newRoleDefinitionIds}/>,
                        createRoleAssignmentResource: {
                            changes: createRoleAssignmentScheduleChanges,
                            translator: localization.steps.createRoleAssignmentSchedule
                        },
                        deleteRoleAssignmentResource: {
                            changes: deleteRoleAssignmentScheduleChanges,
                            translator: localization.steps.deleteRoleAssignmentSchedule.text
                        }
                    });
            }

            function getDeleteRoleEligibilityScheduleChangeStep(changeSet: Contract.ChangeSet) {
                const deleteRoleEligibilityScheduleChanges = changeSet.changes as Contract.AzureDeleteRoleEligibilityScheduleChange[];
                const scopeEntityId = _.head(deleteRoleEligibilityScheduleChanges)!.scopeEntityId;
                return getRoleAssignmentResourceChangeStep(
                    <EntityRoleSchedulesExternalConsoleLink
                        entityId={scopeEntityId}
                        roleAssignmentResourceTypeName={Contract.TypeNames.AzureAuthorizationRoleEligibilitySchedule}/>,
                    !TypeHelper.extendOrImplement(
                        entityModelMap[scopeEntityId].entity.typeName,
                        Contract.TypeNames.AzureStorageStorageAccountResource),
                    localization.steps.deleteRoleEligibilitySchedule.title({
                        ...createPrincipalResolutionProps(),
                        ...createScopeResolutionProps(scopeEntityId)
                    }),
                    {
                        deleteRoleAssignmentResource: {
                            changes: deleteRoleEligibilityScheduleChanges,
                            translator: localization.steps.deleteRoleEligibilitySchedule.text
                        }
                    });
            }

            function getReplaceRoleEligibilityScheduleChangeStep(changeSet: Contract.ChangeSet) {
                const createRoleEligibilityScheduleChange = changeSet.changes[0] as Contract.AzureCreateRoleEligibilityScheduleChange;
                const createRoleEligibilityScheduleChanges =
                    _(changeSet.changes).
                        filter(
                            change =>
                                TypeHelper.extendOrImplement(
                                    change.typeName,
                                    Contract.TypeNames.AzureCreateRoleEligibilityScheduleChange)).
                        as<Contract.AzureCreateRoleEligibilityScheduleChange>().
                        value();
                const deleteRoleEligibilityScheduleChanges =
                    _(changeSet.changes).
                        filter(
                            change =>
                                TypeHelper.extendOrImplement(
                                    change.typeName,
                                    Contract.TypeNames.AzureDeleteRoleEligibilityScheduleChange)).
                        as<Contract.AzureDeleteRoleEligibilityScheduleChange>().
                        value();
                const scopeEntityId = createRoleEligibilityScheduleChange.scopeEntityId;
                const newRoleDefinitionIds =
                    _.map(
                        createRoleEligibilityScheduleChanges,
                        createRoleEligibilityScheduleChange => createRoleEligibilityScheduleChange.resourceId);
                return getRoleAssignmentResourceChangeStep(
                    <EntityRoleSchedulesExternalConsoleLink
                        entityId={scopeEntityId}
                        roleAssignmentResourceTypeName={Contract.TypeNames.AzureAuthorizationRoleEligibilitySchedule}/>,
                    !TypeHelper.extendOrImplement(
                        entityModelMap[scopeEntityId].entity.typeName,
                        Contract.TypeNames.AzureStorageStorageAccountResource),
                    localization.steps.replaceRoleAssignmentResource({
                        ...createScopeResolutionProps(createRoleEligibilityScheduleChange.scopeEntityId),
                        ...createNewRoleAssignmentResourcesResolutionProps(newRoleDefinitionIds),
                        ...createDeleteRoleAssignmentResourcesResolutionProps(deleteRoleEligibilityScheduleChanges)
                    }),
                    {
                        actionElement:
                            <ReplaceRoleAssignmentResourcesChangeDiff
                                existingRoleDefinitionIds={
                                    _.map(
                                        deleteRoleEligibilityScheduleChanges,
                                        deleteRoleEligibilityScheduleChange => (entityModelMap[deleteRoleEligibilityScheduleChange.resourceId].entity as Contract.AzureAuthorizationRoleEligibilitySchedule).roleDefinitionId)}
                                newRoleDefinitionIds={newRoleDefinitionIds}/>,
                        createRoleAssignmentResource: {
                            changes: createRoleEligibilityScheduleChanges,
                            translator: localization.steps.createRoleEligibilitySchedule
                        },
                        deleteRoleAssignmentResource: {
                            changes: deleteRoleEligibilityScheduleChanges,
                            translator: localization.steps.deleteRoleEligibilitySchedule.text
                        }
                    });
            }

            function getDeleteRoleAssignmentClassicAdministratorChangeStep(deleteRoleAssignmentClassicAdministratorChange: Contract.AzureDeleteRoleAssignmentClassicAdministratorChange) {
                return new Step(
                    localization.steps.deleteRoleAssignmentClassicAdministrator.title(createPrincipalResolutionProps()),
                    {
                        contentElement:
                            <Steps variant="plainNumbers">
                                {_<ChangeStep | ReactNode>([]).
                                    concat(
                                        <EntityExternalConsoleLink
                                            entityId={excessivePermissionPrincipalRisk.tenantId}
                                            page={Contract.AzureConsoleEntityPage.RoleAssignments}
                                            translatedTabName={localization.steps.deleteRoleAssignmentClassicAdministrator.iamTabName()}/>).
                                    concat(
                                        new ChangeStep(
                                            deleteRoleAssignmentClassicAdministratorChange,
                                            {
                                                title: localization.steps.deleteRoleAssignmentClassicAdministrator.text(createPrincipalResolutionProps())
                                            })).
                                    value()}
                            </Steps>
                    });
            }

            return _.map(
                excessivePermissionPrincipalRisk.resolutionChanges,
                resolutionChange => {
                    if (resolutionChange.typeName === Contract.TypeNames.ChangeSet) {
                        const changeSet = resolutionChange as Contract.ChangeSet;
                        const changeSetChangeTypeName = changeSet.changes[0].typeName;
                        switch (changeSetChangeTypeName) {
                            case Contract.TypeNames.AadDeletePrincipalGroupMembershipChange:
                                return getAadDeletePrincipalGroupMembershipsChangeStep(changeSet);
                            case Contract.TypeNames.AzureDeleteRoleAssignmentChange:
                                return getDeleteRoleAssignmentChangeStep(changeSet);
                            case Contract.TypeNames.AzureDeleteRoleAssignmentScheduleChange:
                                return getDeleteRoleAssignmentScheduleChangeStep(changeSet);
                            case Contract.TypeNames.AzureDeleteRoleEligibilityScheduleChange:
                                return getDeleteRoleEligibilityScheduleChangeStep(changeSet);
                            case Contract.TypeNames.AzureCreateRoleAssignmentChange:
                                return _.every(
                                    changeSet.changes,
                                    change =>
                                        TypeHelper.extendOrImplement(
                                            change.typeName,
                                            Contract.TypeNames.AzureCreateRoleAssignmentChange))
                                    ? getCreateRoleAssignmentChangeStep(changeSet.changes as Contract.AzureCreateRoleAssignmentChange[])
                                    : getReplaceRoleAssignmentChangeStep(changeSet);
                            case Contract.TypeNames.AzureCreateRoleAssignmentScheduleChange:
                                return getReplaceRoleAssignmentScheduleChangeStep(changeSet);
                            case Contract.TypeNames.AzureCreateRoleEligibilityScheduleChange:
                                return getReplaceRoleEligibilityScheduleChangeStep(changeSet);
                            default:
                                throw new UnexpectedError("changeSetChangeTypeName", changeSetChangeTypeName);
                        }
                    } else {
                        const change = resolutionChange as Contract.Change;
                        switch (change.typeName) {
                            case Contract.TypeNames.AadDeletePrincipalGroupMembershipChange:
                                return getAadDeletePrincipalGroupMembershipChangeStep(change as Contract.AadDeletePrincipalGroupMembershipChange);
                            case Contract.TypeNames.AzureDeleteRoleAssignmentClassicAdministratorChange:
                                return getDeleteRoleAssignmentClassicAdministratorChangeStep(change as Contract.AzureDeleteRoleAssignmentClassicAdministratorChange);
                            default:
                                throw new UnexpectedError("change.typeName", change.typeName);
                        }
                    }
                });
        });

function CustomResolutionSection({ riskModel }: RiskContentProps) {
    const excessivePermissionPrincipalRisk = riskModel.risk as Contract.AzureExcessivePermissionPrincipalRisk;
    const principalModel = entityModelStore.useGet(excessivePermissionPrincipalRisk.entityId) as Contract.AadDirectoryPrincipalModel;
    entityModelStore.useGet(
        _.flatMap(
            excessivePermissionPrincipalRisk.excessiveScopeRoleAssignmentResourceDatas,
            excessiveScopeRoleAssignmentResourceData => _.concat(excessiveScopeRoleAssignmentResourceData.ids, excessiveScopeRoleAssignmentResourceData.scopeEntityId)));
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.access.useAzureExcessivePermissionPrincipalRiskDefinition.customResolutionSection",
            () => ({
                steps: {
                    [Contract.TypeNames.AzureExcessivePermissionPrincipalRiskRoleAssignmentResourceResolution]: {
                        [Contract.AzureExcessivePermissionPrincipalRiskRoleAssignmentResourceResolution.Delete]: "Remove {{roleAssignmentResourceIds}} at scope of {{scopeEntityId}} (for more detailed instructions, see {{riskResolutionCategoryViewLink}} Remediation tab)",
                        [Contract.AzureExcessivePermissionPrincipalRiskRoleAssignmentResourceResolution.Replace]: "Replace {{roleAssignmentResourceIds}} at scope of {{scopeEntityId}} (has excessive permissions) with a {{generationLink}}"
                    }
                },
                subtitle: "These remediation steps only include assignments at the resource group level or above.\nTo remediate assignments at the resource level, see the {{riskResolutionCategoryViewLink}} Remediation tab."
            }));

    const steps =
        useMemo(
            () =>
                _.map(
                    excessivePermissionPrincipalRisk.excessiveScopeRoleAssignmentResourceDatas,
                    excessiveScopeRoleAssignmentResourceData =>
                        new Step(localization.steps[Contract.TypeNames.AzureExcessivePermissionPrincipalRiskRoleAssignmentResourceResolution][excessiveScopeRoleAssignmentResourceData.resolution]({
                            generationLink:
                                excessiveScopeRoleAssignmentResourceData.resolution === Contract.AzureExcessivePermissionPrincipalRiskRoleAssignmentResourceResolution.Delete
                                    ? undefined
                                    : <GenerateNonexcessiveRoleDefinition
                                        excessivePermissionEvaluationStartDate={excessivePermissionPrincipalRisk.excessivePermissionEvaluationStartDate}
                                        principalModel={principalModel}
                                        scopeEntityId={excessiveScopeRoleAssignmentResourceData.scopeEntityId}/>,
                            riskResolutionCategoryViewLink: <ResolutionCategoryViewLink riskModel={riskModel}/>,
                            roleAssignmentResourceIds:
                                <InlineEntities
                                    entityIdsOrModels={excessiveScopeRoleAssignmentResourceData.ids}
                                    entityTypeName={Contract.TypeNames.AzureAuthorizationRoleAssignmentResource}
                                    variant="itemCountAndType"/>,
                            scopeEntityId:
                                <Entity
                                    entityIdOrModel={excessiveScopeRoleAssignmentResourceData.scopeEntityId}
                                    linkOptions={{ disabled: true }}
                                    variant="typeText"/>
                        }))),
            [excessivePermissionPrincipalRisk, principalModel]);

    return (
        <Stack spacing={1.5}>
            <MultiLineEllipsis maxLines={2}>
                {localization.subtitle({
                    riskResolutionCategoryViewLink: <ResolutionCategoryViewLink riskModel={riskModel}/>
                })}
            </MultiLineEllipsis>
            <Divider variant="fullWidth"/>
            <Steps variant="plainNumbers">
                {steps}
            </Steps>
        </Stack>);
}

type GetRoleAssignmentResourceChangeStepOptions = {
    actionElement?: ReactNode;
    createRoleAssignmentResource?: {
        changes: Contract.AzureCreateRoleAssignmentResourceChange[];
        translator: (props: any) => string;
    };
    deleteRoleAssignmentResource?: {
        changes: Contract.AzureDeleteRoleAssignmentResourceChange[];
        translator: (props: any) => string;
    };
};