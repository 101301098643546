﻿import { useLocalization } from "@infrastructure";
import React, { useMemo } from "react";
import { Contract, Entity, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetAwsResourceRiskContext } from "./useGetAwsResourceRiskContext";

export function useGetAwsSsmParameterRiskContext() {
    return useMemo(
        () => useAwsSsmParameterRiskContext,
        []);
}

function useAwsSsmParameterRiskContext(parameterModel: Contract.AwsSsmParameterModel) {
    const parameter = parameterModel.entity as Contract.AwsSsmParameter;
    const resourceRiskContext = useGetAwsResourceRiskContext()(parameterModel);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.contexts.useGetAwsSsmParameterRiskContext",
            () => ({
                updateIdentity: "The {{translatedParameterTypeName}} was last updated by {{updateIdentity}}"
            }));

    return {
        ...resourceRiskContext,
        updateIdentity:
            new RiskDefinitionContextItem(
                localization.updateIdentity({
                    translatedParameterTypeName:
                        entityTypeNameTranslator(
                            parameter.typeName,
                            {
                                includeServiceName: false,
                                variant: "text"
                            }),
                    updateIdentity:
                        <Entity
                            entityIdOrModel={parameter.updateIdentityReference.idReference}
                            variant="text"/>
                }))
    };
}