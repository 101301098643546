import { ActionButton, map, Message, Step, Steps, UnexpectedError, useLocalization } from "@infrastructure";
import { Stack } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { useMemo, useState } from "react";
import utf8 from "utf8";
import { Contract, DiffViewerIcon, DocumentViewerDialog, Entity, EntityModelHelper, entityModelStore, InlineEntities, InlinePermissionActions, PolicyDocumentIcon, SeveritySquare, useEntityTypeNameTranslator, useRiskSeverityReasonTranslator, useSeverityTranslator, useTheme } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, CodeTypeIcon, InlineAwsIamGroupUsers, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { ChangeStep, makeAccessResolutionSectionDefinitionComponent } from "../../../../../../utilities";
import { RiskContentProps } from "../../../../useCloudDefinition";
import { ChangeHelper } from "../../../utilities";
import { PrincipalRiskPolicyDiffDialog, RiskPolicyDiffPolicyItem } from "../../components";
import { useAwsCommonAccessPrincipalRiskDefinition } from "./useAwsCommonAccessPrincipalRiskDefinition";

export function useAwsExcessivePermissionIamPrincipalRiskDefinition(riskModel: Contract.RiskModel) {
    const excessivePermissionPrincipalRiskModel = riskModel as Contract.AwsExcessivePermissionIamPrincipalRiskModel;
    const risk = excessivePermissionPrincipalRiskModel.risk;
    const principalModel = entityModelStore.useGet(risk.entityId) as Contract.AwsIamPrincipalModel;
    const principal = principalModel.entity as Contract.AwsIamPrincipal;

    const severityTranslator = useSeverityTranslator();

    const accessScope =
        useMemo(
            () =>
                principalModel.entity.typeName === Contract.TypeNames.AwsIamUser &&
                principalModel.inactive
                    ? Contract.EntityAccessScope.Full
                    : Contract.EntityAccessScope.GranteePermitterInvolved,
            [principalModel]);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.access.useAwsExcessivePermissionIamPrincipalRiskDefinition",
            () => ({
                description: "{{principal}} has {{severitySquare}}**{{excessivePermissionActionSeverity}}** severity permissions that can be removed",
                userGranteePermitterInvolvedAccessGraphHelpText: "This view displays {{principal}}’s direct permissions only. {{principal}} may also have permissions through groups."
            }));

    return useAwsCommonAccessPrincipalRiskDefinition(
        accessScope,
        <ContextSection riskModel={riskModel}/>,
        localization.description({
            excessivePermissionActionSeverity: severityTranslator(excessivePermissionPrincipalRiskModel.risk.excessivePermissionActionSeverity, "text"),
            principal:
                <Entity
                    entityIdOrModel={principalModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>,
            severitySquare:
                <SeveritySquare severity={excessivePermissionPrincipalRiskModel.risk.excessivePermissionActionSeverity!}/>
        }),
        riskModel,
        {
            accessGraphActionsElement:
                principal.typeName === Contract.TypeNames.AwsIamUser &&
                accessScope === Contract.EntityAccessScope.GranteePermitterInvolved
                    ? <Message
                        level="info"
                        title={
                            localization.userGranteePermitterInvolvedAccessGraphHelpText(
                                {
                                    principal:
                                        <Entity
                                            entityIdOrModel={principalModel}
                                            variant="text"/>
                                })}
                        variant="minimal"/>
                    : undefined,
            resolutionSectionDefinitionComponent
        });
}

function ContextSection({ riskModel }: RiskContentProps) {
    const excessivePermissionPrincipalRiskModel = riskModel as Contract.AwsExcessivePermissionIamPrincipalRiskModel;
    const risk = excessivePermissionPrincipalRiskModel.risk;
    const principalModel = entityModelStore.useGet(risk.entityId) as Contract.AwsIamPrincipalModel;
    const principal = principalModel.entity as Contract.AwsIamPrincipal;
    const roleAssumingEntityModels = entityModelStore.useGet(risk.roleAssumingEntityIds);
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const severityReasonTranslator = useRiskSeverityReasonTranslator();
    const severityTranslator = useSeverityTranslator();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.access.useAwsExcessivePermissionIamPrincipalRiskDefinition.contextSection",
            () => ({
                sensitiveExcessivePermissionActions: [
                    "1 sensitive excessive permission",
                    "{{count | NumberFormatter.humanize}} sensitive excessive permissions"
                ],
                sensitiveResources: [
                    "1 sensitive resource",
                    "{{count | NumberFormatter.humanize}} sensitive resources"
                ],
                unknownTenants: [
                    "1 unknown account",
                    "{{count | NumberFormatter.humanize}} unknown accounts"
                ],
                userActiveAccessKeys: [
                    "1 active access key",
                    "{{count | NumberFormatter.humanize}} active access keys"
                ],
                [Contract.TypeNames.AwsExcessivePermissionIamPrincipalRiskModelInfoType]: {
                    [Contract.AwsExcessivePermissionIamPrincipalRiskModelInfoType.CreationTime]: "{{translatedPrincipalTypeName}} {{principal}} was created {{creationTime | TimeFormatter.humanizePastDuration}}",
                    [Contract.AwsExcessivePermissionIamPrincipalRiskModelInfoType.CreationTimeAndCreator]: "{{translatedPrincipalTypeName}} {{principal}} was created {{creationTime | TimeFormatter.humanizePastDuration}} by {{creatorEntity}}",
                    [Contract.AwsExcessivePermissionIamPrincipalRiskModelInfoType.CreationTimeAndActivityTime]: "{{translatedPrincipalTypeName}} {{principal}} was created {{creationTime | TimeFormatter.humanizePastDuration}} and was last active {{activityTime | TimeFormatter.humanizePastDuration}}",
                    [Contract.AwsExcessivePermissionIamPrincipalRiskModelInfoType.CreationTimeAndCreatorAndActivityTime]: "{{translatedPrincipalTypeName}} {{principal}} was created {{creationTime | TimeFormatter.humanizePastDuration}} by {{creatorEntity}} and was last active {{activityTime | TimeFormatter.humanizePastDuration}}",
                    [Contract.AwsExcessivePermissionIamPrincipalRiskModelInfoType.GroupUsers]: "{{principal}} has {{groupUsers}}",
                    [Contract.AwsExcessivePermissionIamPrincipalRiskModelInfoType.OriginatorResources]: "{{principal}} is linked to {{originatorResources}}",
                    [Contract.AwsExcessivePermissionIamPrincipalRiskModelInfoType.Policies]: {
                        [Contract.TypeNames.AwsIamGroup]: "{{principal}} has {{policies}} attached granting its users {{permissionActions}} on {{services}}",
                        [Contract.TypeNames.AwsIamIdentity]: "{{principal}} has {{policies}} attached granting it {{permissionActions}} on {{services}}"
                    },
                    [Contract.AwsExcessivePermissionIamPrincipalRiskModelInfoType.RoleAssumingEntitiesResolved]: "{{principal}} is actively being assumed by {{roleAssumingEntities}}",
                    [Contract.AwsExcessivePermissionIamPrincipalRiskModelInfoType.RoleAssumingEntitiesUnresolved]: "{{principal}} is actively being assumed",
                    [Contract.AwsExcessivePermissionIamPrincipalRiskModelInfoType.SensitiveResourcesSensitiveExcessivePermissionActionsAny]: "This {{translatedPrincipalTypeName}} has {{sensitiveExcessivePermissionActions}} on {{sensitiveResources}}",
                    [Contract.AwsExcessivePermissionIamPrincipalRiskModelInfoType.ServiceLevelManyExcessivePermissionServicesAll]: {
                        [Contract.TypeNames.AwsIamGroup]: [
                            "{{principal}} user has access to {{services}}, but has not used any of them for more than {{servicesServiceUsageTime | TimeFormatter.humanizeDuration}}",
                            "{{principal}} users have access to {{services}}, but have not used any of them for more than {{servicesServiceUsageTime | TimeFormatter.humanizeDuration}}"
                        ],
                        [Contract.TypeNames.AwsIamIdentity]: "{{principal}} has access to {{services}}, but has not used any of them for more than {{servicesServiceUsageTime | TimeFormatter.humanizeDuration}}"
                    },
                    [Contract.AwsExcessivePermissionIamPrincipalRiskModelInfoType.ServiceLevelManyExcessivePermissionServicesSome]: [
                        "{{excessivePermissionServices}} out of the {{services}} was unused for more than {{servicesExcessivePermissionServiceUsageTime | TimeFormatter.humanizeDuration}}",
                        "{{excessivePermissionServices}} out of the {{services}} were unused for more than {{servicesExcessivePermissionServiceUsageTime | TimeFormatter.humanizeDuration}}"
                    ],
                    [Contract.AwsExcessivePermissionIamPrincipalRiskModelInfoType.ServiceLevelSingle]: {
                        [Contract.TypeNames.AwsIamGroup]: [
                            "{{principal}} user has access to {{services}}, but has not used it for more than {{servicesServiceUsageTime | TimeFormatter.humanizeDuration}}",
                            "{{principal}} users have access to {{services}}, but have not used it for more than {{servicesServiceUsageTime | TimeFormatter.humanizeDuration}}"
                        ],
                        [Contract.TypeNames.AwsIamIdentity]: "{{principal}} has access to {{services}}, but has not used it for more than {{servicesServiceUsageTime | TimeFormatter.humanizeDuration}}"
                    },
                    [Contract.AwsExcessivePermissionIamPrincipalRiskModelInfoType.ServiceResourceLevel]: {
                        [Contract.TypeNames.AwsIamGroup]: [
                            "{{principal}} user is only using some of the permissions of {{serviceResourceLevelService}}",
                            "{{principal}} users are only using some of the permissions of {{serviceResourceLevelService}}"
                        ],
                        [Contract.TypeNames.AwsIamIdentity]: "{{principal}} is only using some of the permissions of {{serviceResourceLevelService}}"
                    },
                    [Contract.AwsExcessivePermissionIamPrincipalRiskModelInfoType.ServiceResourceLevelNotUsedPermissionActions]: {
                        [Contract.TypeNames.AwsIamGroup]: [
                            "{{principal}} user is only using {{serviceResourceLevelUsedPermissionActions}} out of the {{serviceResourceLevelPermissionActions}} on {{serviceResourceLevelService}}",
                            "{{principal}} users are only using {{serviceResourceLevelUsedPermissionActions}} out of the {{serviceResourceLevelPermissionActions}} on {{serviceResourceLevelService}}"
                        ],
                        [Contract.TypeNames.AwsIamIdentity]: "{{principal}} is only using {{serviceResourceLevelUsedPermissionActions}} out of the {{serviceResourceLevelPermissionActions}} on {{serviceResourceLevelService}}"
                    },
                    [Contract.AwsExcessivePermissionIamPrincipalRiskModelInfoType.ServiceResourceLevelNotUsedResources]: {
                        [Contract.TypeNames.AwsIamGroup]: [
                            "{{principal}} user has access to {{serviceResourceLevelResources}} but is not using any of them on {{serviceResourceLevelService}}",
                            "{{principal}} users have access to {{serviceResourceLevelResources}} but are not using any of them on {{serviceResourceLevelService}}"
                        ],
                        [Contract.TypeNames.AwsIamIdentity]: "{{principal}} has access to {{serviceResourceLevelResources}} but is not using any of them on {{serviceResourceLevelService}}"
                    },
                    [Contract.AwsExcessivePermissionIamPrincipalRiskModelInfoType.ServiceResourceLevelNotUsedResourcesAndNotUsedPermissionActions]: {
                        [Contract.TypeNames.AwsIamGroup]: [
                            "{{principal}} user has access to {{serviceResourceLevelResources}} but is not using any of them and is only using {{serviceResourceLevelUsedPermissionActions}} out of the {{serviceResourceLevelPermissionActions}} on {{serviceResourceLevelService}}",
                            "{{principal}} users have access to {{serviceResourceLevelResources}} but are not using any of them and are only using {{serviceResourceLevelUsedPermissionActions}} out of the {{serviceResourceLevelPermissionActions}} on {{serviceResourceLevelService}}"
                        ],
                        [Contract.TypeNames.AwsIamIdentity]: "{{principal}} has access to {{serviceResourceLevelResources}} but is not using any of them and is only using {{serviceResourceLevelUsedPermissionActions}} out of the {{serviceResourceLevelPermissionActions}} on {{serviceResourceLevelService}}"
                    },
                    [Contract.AwsExcessivePermissionIamPrincipalRiskModelInfoType.ServiceResourceLevelNotUsedResourcesAndUsedResources]:
                        {
                            [Contract.TypeNames.AwsIamGroup]: [
                                "{{principal}} user has access to {{serviceResourceLevelResources}} but is only using {{serviceResourceLevelUsedResources}} on {{serviceResourceLevelService}}",
                                "{{principal}} users have access to {{serviceResourceLevelResources}} but are only using {{serviceResourceLevelUsedResources}} on {{serviceResourceLevelService}}"
                            ],
                            [Contract.TypeNames.AwsIamIdentity]: "{{principal}} has access to {{serviceResourceLevelResources}} but is only using {{serviceResourceLevelUsedResources}} on {{serviceResourceLevelService}}"
                        },
                    [Contract.AwsExcessivePermissionIamPrincipalRiskModelInfoType.ServiceResourceLevelNotUsedResourcesAndUsedResourcesAndNotUsedPermissionActions]: {
                        [Contract.TypeNames.AwsIamGroup]: [
                            "{{principal}} user has access to {{serviceResourceLevelResources}} but is only using {{serviceResourceLevelUsedResources}} and only {{serviceResourceLevelUsedPermissionActions}} out of the {{serviceResourceLevelPermissionActions}} on {{serviceResourceLevelService}}",
                            "{{principal}} users have access to {{serviceResourceLevelResources}} but are only using {{serviceResourceLevelUsedResources}} and only {{serviceResourceLevelUsedPermissionActions}} out of the {{serviceResourceLevelPermissionActions}} on {{serviceResourceLevelService}}"
                        ],
                        [Contract.TypeNames.AwsIamIdentity]: "{{principal}} has access to {{serviceResourceLevelResources}} but is only using {{serviceResourceLevelUsedResources}} and only {{serviceResourceLevelUsedPermissionActions}} out of the {{serviceResourceLevelPermissionActions}} on {{serviceResourceLevelService}}"
                    },
                    [Contract.AwsExcessivePermissionIamPrincipalRiskModelInfoType.ServiceResourceLevelNotUsedPermissionActionResourcesAndUsedResources]: {
                        [Contract.TypeNames.AwsIamGroup]: [
                            "{{principal}} user has access to {{serviceResourceLevelResources}} but is only using some of the permissions on {{serviceResourceLevelNotUsedPermissionActionResources}} on {{serviceResourceLevelService}}",
                            "{{principal}} users have access to {{serviceResourceLevelResources}} but are only using some of the permissions on {{serviceResourceLevelNotUsedPermissionActionResources}} on {{serviceResourceLevelService}}"
                        ],
                        [Contract.TypeNames.AwsIamIdentity]: "{{principal}} has access to {{serviceResourceLevelResources}} but is only using some of the permissions on {{serviceResourceLevelNotUsedPermissionActionResources}} on {{serviceResourceLevelService}}"
                    },
                    [Contract.AwsExcessivePermissionIamPrincipalRiskModelInfoType.UserAccessKeys]: "{{principal}} has {{translatedActiveAccessKeys}}",
                    [Contract.AwsExcessivePermissionIamPrincipalRiskModelInfoType.UserAccessKeysAndPassword]: "{{principal}} has a console password enabled and {{translatedActiveAccessKeys}}",
                    [Contract.AwsExcessivePermissionIamPrincipalRiskModelInfoType.UserExcessiveGroupMembershipGroupIds]: "{{principal}} is a member of {{userExcessiveGroupMembershipGroupIds}} granting it {{userExcessiveGroupMembershipPermissionActionSeveritySquare}}**{{userExcessiveGroupMembershipPermissionActionSeverity}}** severity permissions that can be removed.",
                    [Contract.AwsExcessivePermissionIamPrincipalRiskModelInfoType.UserMfaDisabled]: "{{principal}} has no MFA enabled",
                    [Contract.AwsExcessivePermissionIamPrincipalRiskModelInfoType.UserMfaEnabled]: "{{principal}} has MFA enabled",
                    [Contract.AwsExcessivePermissionIamPrincipalRiskModelInfoType.UserPassword]: "{{principal}} has a console password enabled"
                }
            }));
    const createInfoProps =
        (additionalProps?: Dictionary<any>) => ({
            ...additionalProps,
            activityTime: risk.activityTime,
            creationTime: principalModel.creationTime,
            creatorEntity:
                <Entity
                    entityIdOrModel={principalModel.creatorEntityIdReference!}
                    variant="text"/>,
            excessivePermissionServices:
                risk.serviceLevel!.hasExcessivePermissions
                    ? <InlineEntities
                        entityIdsOrModels={risk.serviceLevel!.excessivePermissionServiceIds}
                        entityTypeName={Contract.TypeNames.AwsService}
                        variant="itemCountAndType"/>
                    : undefined,
            groupUsers: <InlineAwsIamGroupUsers userIds={risk.groupUserIds}/>,
            originatorResources:
                <InlineEntities
                    entityIdsOrModels={risk.roleMostIndirectOriginatorResourceIds}
                    entityTypeName={Contract.TypeNames.IAwsOriginatorWorkloadResource}
                    variant="itemAndTypeOrItemCountAndType"/>,
            permissionActions:
                <InlinePermissionActions
                    permissionActions={risk.permissionActions}
                    variant="itemOrItemCountAndType"/>,
            policies:
                <InlineEntities
                    entityIdsOrModels={
                        _.map(
                            risk.policyAssociationAndInfos,
                            policyAssociationAndInfo => policyAssociationAndInfo.policyAssociation.policyId)}
                    entityTypeName={Contract.TypeNames.AwsIamPrincipalPolicy}
                    variant="itemAndTypeOrItemCountAndType"/>,
            principal:
                <Entity
                    entityIdOrModel={principalModel}
                    variant="text"/>,
            roleAssumingEntities:
                <InlineEntities
                    entityIdsOrModels={roleAssumingEntityModels}
                    entityTypeName={EntityModelHelper.getCommonTypeName(roleAssumingEntityModels) ?? Contract.TypeNames.Entity}
                    variant="itemAndTypeOrItemCountAndType"/>,
            sensitiveExcessivePermissionActions:
                <InlinePermissionActions
                    namePluralizer={localization.sensitiveExcessivePermissionActions}
                    permissionActions={risk.sensitiveResources.sensitiveExcessivePermissionActions}
                    variant="itemOrItemCountAndType"/>,
            sensitiveResources:
                <InlineEntities
                    entityIdsOrModels={risk.sensitiveResources.ids}
                    entityTypeName={Contract.TypeNames.AwsResource}
                    namePluralizer={localization.sensitiveResources}
                    variant="itemAndTypeOrItemCountAndType"/>,
            services:
                <InlineEntities
                    entityIdsOrModels={excessivePermissionPrincipalRiskModel.serviceIds}
                    entityTypeName={Contract.TypeNames.AwsService}
                    variant="itemAndTypeOrItemCountAndType"/>,
            servicesExcessivePermissionServiceUsageTime:
                _.isNil(excessivePermissionPrincipalRiskModel.lastUsedExcessivePermissionServiceInfo)
                    ? principalModel.creationTime
                    : excessivePermissionPrincipalRiskModel.lastUsedExcessivePermissionServiceInfo?.usageTime,
            servicesServiceUsageTime:
                _.isNil(excessivePermissionPrincipalRiskModel.lastUsedServiceInfo)
                    ? principalModel.creationTime
                    : excessivePermissionPrincipalRiskModel.lastUsedServiceInfo.usageTime,
            translatedActiveAccessKeys:
                _.isNil(excessivePermissionPrincipalRiskModel.userActiveAccessKeyCount)
                    ? undefined
                    : localization.userActiveAccessKeys(excessivePermissionPrincipalRiskModel.userActiveAccessKeyCount),
            translatedPrincipalTypeName:
                entityTypeNameTranslator(
                    principal.typeName,
                    {
                        includeServiceName: false,
                        variant: "title"
                    }),
            userExcessiveGroupMembershipGroupIds:
                <InlineEntities
                    entityIdsOrModels={excessivePermissionPrincipalRiskModel.risk.userExcessiveGroupMembershipGroupIds}
                    entityTypeName={Contract.TypeNames.GciDirectoryGroup}
                    variant="itemCountAndType"/>,
            userExcessiveGroupMembershipPermissionActionSeverity:
                _.isNil(excessivePermissionPrincipalRiskModel.risk.userExcessiveGroupMembershipPermissionActionSeverity)
                    ? undefined
                    : severityTranslator(excessivePermissionPrincipalRiskModel.risk.userExcessiveGroupMembershipPermissionActionSeverity!, "text"),
            userExcessiveGroupMembershipPermissionActionSeveritySquare:
                _.isNil(excessivePermissionPrincipalRiskModel.risk.userExcessiveGroupMembershipPermissionActionSeverity)
                    ? undefined
                    : <SeveritySquare severity={excessivePermissionPrincipalRiskModel.risk.userExcessiveGroupMembershipPermissionActionSeverity!}/>
        });

    const serviceIdToServiceResourceLevelMap =
        _.keyBy(
            risk.serviceResourceLevels,
            serviceResourceLevel => serviceResourceLevel.serviceId);

    return (
        <Steps>
            {_<string>([]).
                concat(
                    _.map(
                        excessivePermissionPrincipalRiskModel.infos,
                        info => {
                            switch (info.type) {
                                case Contract.AwsExcessivePermissionIamPrincipalRiskModelInfoType.CreationTime:
                                case Contract.AwsExcessivePermissionIamPrincipalRiskModelInfoType.CreationTimeAndCreator:
                                case Contract.AwsExcessivePermissionIamPrincipalRiskModelInfoType.CreationTimeAndActivityTime:
                                case Contract.AwsExcessivePermissionIamPrincipalRiskModelInfoType.CreationTimeAndCreatorAndActivityTime:
                                case Contract.AwsExcessivePermissionIamPrincipalRiskModelInfoType.GroupUsers:
                                case Contract.AwsExcessivePermissionIamPrincipalRiskModelInfoType.OriginatorResources:
                                case Contract.AwsExcessivePermissionIamPrincipalRiskModelInfoType.RoleAssumingEntitiesResolved:
                                case Contract.AwsExcessivePermissionIamPrincipalRiskModelInfoType.RoleAssumingEntitiesUnresolved:
                                case Contract.AwsExcessivePermissionIamPrincipalRiskModelInfoType.SensitiveResourcesSensitiveExcessivePermissionActionsAny:
                                case Contract.AwsExcessivePermissionIamPrincipalRiskModelInfoType.UserAccessKeys:
                                case Contract.AwsExcessivePermissionIamPrincipalRiskModelInfoType.UserAccessKeysAndPassword:
                                case Contract.AwsExcessivePermissionIamPrincipalRiskModelInfoType.UserExcessiveGroupMembershipGroupIds:
                                case Contract.AwsExcessivePermissionIamPrincipalRiskModelInfoType.UserMfaDisabled:
                                case Contract.AwsExcessivePermissionIamPrincipalRiskModelInfoType.UserMfaEnabled:
                                case Contract.AwsExcessivePermissionIamPrincipalRiskModelInfoType.UserPassword:
                                    return localization[Contract.TypeNames.AwsExcessivePermissionIamPrincipalRiskModelInfoType][info.type](createInfoProps());
                                case Contract.AwsExcessivePermissionIamPrincipalRiskModelInfoType.Policies:
                                    return principal.typeName === Contract.TypeNames.AwsIamGroup
                                        ? localization[Contract.TypeNames.AwsExcessivePermissionIamPrincipalRiskModelInfoType][info.type][Contract.TypeNames.AwsIamGroup](createInfoProps())
                                        : localization[Contract.TypeNames.AwsExcessivePermissionIamPrincipalRiskModelInfoType][info.type][Contract.TypeNames.AwsIamIdentity](createInfoProps());
                                case Contract.AwsExcessivePermissionIamPrincipalRiskModelInfoType.ServiceLevelManyExcessivePermissionServicesAll:
                                case Contract.AwsExcessivePermissionIamPrincipalRiskModelInfoType.ServiceLevelSingle:
                                    return principal.typeName === Contract.TypeNames.AwsIamGroup
                                        ? localization[Contract.TypeNames.AwsExcessivePermissionIamPrincipalRiskModelInfoType][info.type][Contract.TypeNames.AwsIamGroup](
                                            risk.groupUserIds.length,
                                            createInfoProps())
                                        : localization[Contract.TypeNames.AwsExcessivePermissionIamPrincipalRiskModelInfoType][info.type][Contract.TypeNames.AwsIamIdentity](createInfoProps());
                                case Contract.AwsExcessivePermissionIamPrincipalRiskModelInfoType.ServiceLevelManyExcessivePermissionServicesSome:
                                    return localization[Contract.TypeNames.AwsExcessivePermissionIamPrincipalRiskModelInfoType][info.type](
                                        risk.serviceLevel!.excessivePermissionServiceIds.length,
                                        createInfoProps());
                                case Contract.AwsExcessivePermissionIamPrincipalRiskModelInfoType.ServiceResourceLevel:
                                case Contract.AwsExcessivePermissionIamPrincipalRiskModelInfoType.ServiceResourceLevelNotUsedPermissionActionResourcesAndUsedResources:
                                case Contract.AwsExcessivePermissionIamPrincipalRiskModelInfoType.ServiceResourceLevelNotUsedPermissionActions:
                                case Contract.AwsExcessivePermissionIamPrincipalRiskModelInfoType.ServiceResourceLevelNotUsedResources:
                                case Contract.AwsExcessivePermissionIamPrincipalRiskModelInfoType.ServiceResourceLevelNotUsedResourcesAndNotUsedPermissionActions:
                                case Contract.AwsExcessivePermissionIamPrincipalRiskModelInfoType.ServiceResourceLevelNotUsedResourcesAndUsedResources:
                                case Contract.AwsExcessivePermissionIamPrincipalRiskModelInfoType.ServiceResourceLevelNotUsedResourcesAndUsedResourcesAndNotUsedPermissionActions: {
                                    const serviceExcessivenessInfo = info as Contract.AwsExcessivePermissionIamPrincipalRiskModelServiceExcessivenessInfo;
                                    const serviceResourceLevel = serviceIdToServiceResourceLevelMap[serviceExcessivenessInfo.serviceId];
                                    const serviceResourceLevelProps =
                                        createInfoProps({
                                            serviceResourceLevelNotUsedPermissionActionResources:
                                                <InlineEntities
                                                    entityIdsOrModels={serviceResourceLevel.notUsedPermissionActionResourceIds}
                                                    entityTypeName={Contract.TypeNames.AwsResource}
                                                    variant="itemCountAndType"/>,
                                            serviceResourceLevelPermissionActions:
                                                <InlinePermissionActions
                                                    permissionActions={serviceResourceLevel.permissionActions}
                                                    variant="itemOrItemCountAndType"/>,
                                            serviceResourceLevelResources:
                                                <InlineEntities
                                                    entityIdsOrModels={serviceResourceLevel.resourceIds}
                                                    entityTypeName={Contract.TypeNames.AwsResource}
                                                    variant="itemAndTypeOrItemCountAndType"/>,
                                            serviceResourceLevelService:
                                                <Entity
                                                    entityIdOrModel={serviceResourceLevel.serviceId}
                                                    variant="text"/>,
                                            serviceResourceLevelUsedPermissionActions:
                                                <InlinePermissionActions
                                                    permissionActions={serviceResourceLevel.usedPermissionActions}
                                                    variant="itemCountAndType"/>,
                                            serviceResourceLevelUsedResources:
                                                <InlineEntities
                                                    entityIdsOrModels={serviceResourceLevel.usedResourceIds}
                                                    entityTypeName={Contract.TypeNames.AwsResource}
                                                    variant="itemCountAndType"/>
                                        });
                                    return principal.typeName === Contract.TypeNames.AwsIamGroup
                                        ? localization[Contract.TypeNames.AwsExcessivePermissionIamPrincipalRiskModelInfoType][info.type][Contract.TypeNames.AwsIamGroup](
                                            risk.groupUserIds.length,
                                            serviceResourceLevelProps)
                                        : localization[Contract.TypeNames.AwsExcessivePermissionIamPrincipalRiskModelInfoType][info.type][Contract.TypeNames.AwsIamIdentity](serviceResourceLevelProps);
                                }
                                default:
                                    throw new UnexpectedError("info.type", info.type);
                            }
                        })).
                concatIf(
                    !_.isNil(risk.severityReason),
                    () =>
                        severityReasonTranslator(
                            risk.severity,
                            risk.severityReason!,
                            {
                                excessivePermissionActionSeverity:
                                    _.isNil(risk.excessivePermissionActionSeverity)
                                        ? undefined
                                        : severityTranslator(risk.excessivePermissionActionSeverity, "text"),
                                existingPermissionActionUnknownExternalTenants:
                                    principal.typeName === Contract.TypeNames.AwsIamRole
                                        ? <InlineEntities
                                            entityIdsOrModels={(risk as Contract.AwsExcessivePermissionRoleRisk).unknownTenantIds}
                                            entityTypeName={Contract.TypeNames.AwsTenantEntity}
                                            namePluralizer={localization.unknownTenants}
                                            variant="itemCountAndType"/>
                                        : undefined,
                                resourceTypeName: principal.typeName
                            })).
                value()}
        </Steps>);
}

const resolutionSectionDefinitionComponent =
    makeAccessResolutionSectionDefinitionComponent(
        riskModel => {
            const excessivePermissionIamPrincipalRiskModel = riskModel as Contract.AwsExcessivePermissionIamPrincipalRiskModel;
            const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
            entityModelStore.useGet(
                _.flatMap(
                    excessivePermissionIamPrincipalRiskModel.risk.resolutionChanges,
                    resolutionChange => resolutionChange.entityIds));

            const principalModel = entityModelStore.useGet(excessivePermissionIamPrincipalRiskModel.risk.entityId);

            const localization =
                useLocalization(
                    "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.access.useAwsExcessivePermissionIamPrincipalRiskDefinition.useResolutionSectionOptions",
                    () => ({
                        steps: {
                            deletePolicies: "Remove all existing policies of {{principal}}",
                            deleteUserGroupMemberships: "Remove {{principal}}’s group memberships"
                        }
                    }));

            const existingPolicyIds =
                _(ChangeHelper.getChanges(excessivePermissionIamPrincipalRiskModel.risk.resolutionChanges!)).
                    map(
                        resolutionChange =>
                            map(
                                resolutionChange.typeName,
                                {
                                    [Contract.TypeNames.AwsUpsertPrincipalManagedPolicyChange]: () => (resolutionChange as Contract.AwsUpsertPrincipalManagedPolicyChange).policyId
                                },
                                () => (resolutionChange as Contract.AwsResourceChange).resourceId)).
                    filter().
                    as<string>().
                    value();

            function getDeleteUserGroupMembershipsChangeStep(changeSet: Contract.ChangeSet) {
                const deleteUserGroupMembershipChanges = changeSet.changes as Contract.AwsDeleteUserGroupMembershipChange[];
                return new Step(
                    localization.steps.deleteUserGroupMemberships({
                        principal:
                            <Entity
                                entityIdOrModel={principalModel}
                                glanceOptions={{ disabled: true }}
                                linkOptions={{ disabled: true }}
                                variant="text"/>
                    }),
                    {
                        contentElement:
                            <Steps variant="plainNumbers">
                                {[
                                    consoleSignInStepTranslator(
                                        Contract.AwsConsoleView.Iam,
                                        AwsConsoleUrlBuilder.getIamUserGroupMembershipUrl(principalModel.entity as Contract.AwsIamUser)),
                                    ..._.map(
                                        deleteUserGroupMembershipChanges,
                                        deleteUserGroupMembershipChange => new ChangeStep(deleteUserGroupMembershipChange))
                                ]}
                            </Steps>
                    });
            }

            function getPrincipalPolicyChangeStep(changeSet: Contract.ChangeSet) {
                return new Step(
                    localization.steps.deletePolicies({
                        principal:
                            <Entity
                                entityIdOrModel={principalModel}
                                glanceOptions={{ disabled: true }}
                                linkOptions={{ disabled: true }}
                                variant="typeText"/>
                    }),
                    {
                        contentElement:
                            <Steps variant="plainNumbers">
                                {_.map(
                                    changeSet.changes,
                                    deletePrincipalPolicyChange =>
                                        new ChangeStep(
                                            deletePrincipalPolicyChange,
                                            {
                                                actionElements: [
                                                    <DeletePrincipalPolicyChangeActions
                                                        key={deletePrincipalPolicyChange.id}
                                                        policyId={(deletePrincipalPolicyChange as Contract.AwsResourceChange).resourceId}
                                                        policyInfo={excessivePermissionIamPrincipalRiskModel.risk.resolutionChangeIdToPolicyInfoMap[deletePrincipalPolicyChange.id]}/>
                                                ]
                                            }))}
                            </Steps>
                    });
            }

            function getUpsertPrincipalManagedPolicyChangeStep(upsertPrincipalManagedPolicyChange: Contract.AwsUpsertPrincipalManagedPolicyChange) {
                return new ChangeStep(
                    upsertPrincipalManagedPolicyChange,
                    {
                        actionElements: [
                            <UpsertPrincipalManagedPolicyChangeActions
                                existingPolicyIds={existingPolicyIds}
                                key={upsertPrincipalManagedPolicyChange.id}
                                policyInfo={excessivePermissionIamPrincipalRiskModel.risk.resolutionChangeIdToPolicyInfoMap[upsertPrincipalManagedPolicyChange.id]}
                                riskModel={excessivePermissionIamPrincipalRiskModel}
                                upsertPrincipalManagedPolicyChange={upsertPrincipalManagedPolicyChange}/>
                        ]
                    });
            }

            return _.map(
                excessivePermissionIamPrincipalRiskModel.risk.resolutionChanges,
                resolutionChange => {
                    if (resolutionChange.typeName === Contract.TypeNames.ChangeSet) {
                        const changeSet = resolutionChange as Contract.ChangeSet;
                        const changeSetChangeTypeName = changeSet.changes[0].typeName;
                        switch (changeSetChangeTypeName) {
                            case Contract.TypeNames.AwsDeleteInlinePolicyChange:
                            case Contract.TypeNames.AwsDetachPrincipalManagedPolicyChange:
                                return getPrincipalPolicyChangeStep(changeSet);
                            case Contract.TypeNames.AwsDeleteUserGroupMembershipChange:
                                return getDeleteUserGroupMembershipsChangeStep(changeSet);
                            default:
                                throw new UnexpectedError("changeSetChangeTypeName", changeSetChangeTypeName);
                        }
                    } else {
                        const change = resolutionChange as Contract.Change;
                        switch (change.typeName) {
                            case Contract.TypeNames.AwsUpsertPrincipalManagedPolicyChange:
                                return getUpsertPrincipalManagedPolicyChangeStep(change as Contract.AwsUpsertPrincipalManagedPolicyChange);
                            default:
                                throw new UnexpectedError("change.typeName", change.typeName);
                        }
                    }
                });
        });

type DeletePrincipalPolicyChangeActionsProps = {
    policyId: string;
    policyInfo: Contract.AwsExcessivePermissionPrincipalRiskResolutionPolicyInfo;
};

function DeletePrincipalPolicyChangeActions({ policyId, policyInfo }: DeletePrincipalPolicyChangeActionsProps) {
    const policyModel = entityModelStore.useGet(policyId);
    const [policyDocument, setPolicyDocument] = useState(false);
    const [policyDocumentDiff, setPolicyDocumentDiff] = useState(false);
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.access.useAwsExcessivePermissionIamPrincipalRiskDefinition.deletePrincipalPolicyChangeActions",
            () => ({
                actions: {
                    diff: "Diff",
                    json: "JSON"
                }
            }));
    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            direction="row">
            {policyDocument && (
                <DocumentViewerDialog
                    document={policyInfo.existingDocument!.raw}
                    documentFileName={`${policyModel.entity.displayName}.json`}
                    title={policyModel.entity.displayName}
                    onClose={() => setPolicyDocument(false)}/>)}
            {policyDocumentDiff && (
                <PrincipalRiskPolicyDiffDialog
                    existingPolicyItems={[
                        new RiskPolicyDiffPolicyItem(
                            policyModel.entity.displayName,
                            policyInfo.existingDocument!.raw)
                    ]}
                    newPolicyItem={
                        _.isNil(policyInfo.newDocument)
                            ? undefined
                            : new RiskPolicyDiffPolicyItem(
                                policyModel.entity.displayName,
                                policyInfo.newDocument.raw)}
                    policyFileName={policyModel.entity.displayName}
                    onClose={() => setPolicyDocumentDiff(false)}/>)}
            <ActionButton
                tooltip={localization.actions.json()}
                onClick={() => setPolicyDocument(true)}>
                <PolicyDocumentIcon
                    sx={{
                        color: theme.palette.text.primary,
                        fontSize: "18px"
                    }}/>
            </ActionButton>
            <ActionButton
                tooltip={localization.actions.diff()}
                onClick={() => setPolicyDocumentDiff(true)}>
                <DiffViewerIcon
                    sx={{
                        color: theme.palette.text.primary,
                        fontSize: "18px"
                    }}/>
            </ActionButton>
        </Stack>);
}

type UpsertPrincipalManagedPolicyChangeActionsProps = {
    existingPolicyIds: string[];
    policyInfo: Contract.AwsExcessivePermissionPrincipalRiskResolutionPolicyInfo;
    riskModel: Contract.AwsExcessivePermissionIamPrincipalRiskModel;
    upsertPrincipalManagedPolicyChange: Contract.AwsUpsertPrincipalManagedPolicyChange;
};

function UpsertPrincipalManagedPolicyChangeActions({ existingPolicyIds, policyInfo, riskModel, upsertPrincipalManagedPolicyChange }: UpsertPrincipalManagedPolicyChangeActionsProps) {
    const [policyDocumentDiff, setPolicyDocumentDiff] = useState(false);
    const [riskFile, setRiskFile] = useState<Contract.RiskFile>();
    const existingPolicyModels = entityModelStore.useGet(existingPolicyIds);
    const existingPolicies =
        useMemo(
            () =>
                _.map(
                    existingPolicyModels,
                    existingPolicyModel => existingPolicyModel.entity as Contract.AwsIamPrincipalPolicy),
            [existingPolicyModels]);
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.access.useAwsExcessivePermissionIamPrincipalRiskDefinition.upsertPrincipalManagedPolicyChangeActions",
            () => ({
                actions: {
                    cloudFormation: "CloudFormation",
                    diff: "Diff",
                    json: "JSON",
                    terraform: "Terraform"
                }
            }));
    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            direction="row">
            {!_.isNil(riskFile) && (
                <DocumentViewerDialog
                    document={utf8.decode(atob(riskFile.contentBytes))}
                    documentFileName={riskFile.name}
                    title={riskFile.name}
                    onClose={() => setRiskFile(undefined)}/>)}
            {policyDocumentDiff && (
                <PrincipalRiskPolicyDiffDialog
                    existingPolicyItems={
                        existingPolicies.map(
                            existingPolicy =>
                                new RiskPolicyDiffPolicyItem(
                                    existingPolicy.displayName,
                                    existingPolicy.document.raw))}
                    newPolicyItem={
                        new RiskPolicyDiffPolicyItem(
                            upsertPrincipalManagedPolicyChange.name,
                            policyInfo.newDocument!.raw)}
                    policyFileName={upsertPrincipalManagedPolicyChange.name}
                    onClose={() => setPolicyDocumentDiff(false)}/>)}
            <ActionButton
                tooltip={localization.actions.json()}
                onClick={() => setRiskFile(riskModel.upsertPrincipalManagedPolicyChangeIdToFileTypeToFileMap[upsertPrincipalManagedPolicyChange.id][Contract.RiskFileType.AwsPolicyDocumentRaw])}>
                <PolicyDocumentIcon
                    sx={{
                        color: theme.palette.text.primary,
                        fontSize: "24px"
                    }}/>
            </ActionButton>
            <ActionButton
                tooltip={localization.actions.diff()}
                onClick={() => setPolicyDocumentDiff(true)}>
                <DiffViewerIcon
                    sx={{
                        color: theme.palette.text.primary,
                        fontSize: "24px"
                    }}/>
            </ActionButton>
            <ActionButton
                tooltip={localization.actions.terraform()}
                onClick={() => setRiskFile(riskModel.upsertPrincipalManagedPolicyChangeIdToFileTypeToFileMap[upsertPrincipalManagedPolicyChange.id][Contract.RiskFileType.Terraform])}>
                <CodeTypeIcon
                    codeType={Contract.CodeType.Terraform}
                    sx={{ fontSize: "24px" }}/>
            </ActionButton>
            <ActionButton
                tooltip={localization.actions.cloudFormation()}
                onClick={() => setRiskFile(riskModel.upsertPrincipalManagedPolicyChangeIdToFileTypeToFileMap[upsertPrincipalManagedPolicyChange.id][Contract.RiskFileType.AwsCloudFormation])}>
                <CodeTypeIcon
                    codeType={Contract.CodeType.CloudFormation}
                    sx={{ fontSize: "24px" }}/>
            </ActionButton>
        </Stack>);
}