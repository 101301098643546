import { useLocalization } from "@infrastructure";

export function useAwsUpsertSecretPolicyChangeDefinition() {
    const localization =
        useLocalization(
            "common.change.hooks.useDefinition.hooks.useAwsDefinition.hooks.useAwsUpsertSecretPolicyChangeDefinition",
            () => ({
                title: "Update the secret policy"
            }));
    return {
        title: localization.title()
    };
}