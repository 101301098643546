import { EmptyMessageText, Optional, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useGcpDefaultResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../..";
import { Contract, entityModelStore, InfoCard, InfoItem, KeyValueTable, KeyValueTableItem, TenantEntityParentPathCell } from "../../../../../../../../../../common";
import { GcpTenantEntityUsageSection } from "../../../../../../../../../../tenants";
import { EntitiesInfoItem, Info } from "../../../../components";
import { GcpScopeResourceRoleBindings } from "../../components";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";

export function useGcpTenantEntityDefinition(scopeResourceModel: Contract.GcpScopeResourceModel, options?: DefinitionOptions) {
    const tenantEntityModel = scopeResourceModel as Contract.GcpTenantEntityModel;
    const computeProject = entityModelStore.useGet(tenantEntityModel.computeProjectId)?.entity as Optional<Contract.GcpComputeProject>;
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(scopeResourceModel);
    const defaultResourceInfoItemElements = useGcpDefaultResourceInfoItemElements(scopeResourceModel);
    const tenantEntity = tenantEntityModel.entity as Contract.GcpTenantEntity;

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpTenantEntityDefinition",
            () => ({
                info: {
                    card: {
                        computeProjectInstanceDefaultMetadataKeyToValueMap: {
                            empty: "No metadata values",
                            title: "Compute Engine Metadata"
                        },
                        usage: "Utilization"
                    },
                    items: {
                        apiKeysKeys: "API Keys",
                        computeProjectInstanceDefaultSshIamEnabled: {
                            false: "Disabled",
                            title: "OS Login",
                            true: "Enabled"
                        },
                        parentPath: "Path",
                        rawShortName: "Project ID",
                        rawShortNumber: "Project Number"
                    }
                },
                tabs: {
                    resourceRoleBindings: "Resource Role Bindings"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <GcpScopeResourceRoleBindings
                    csvExportFilePrefix={localization.tabs.resourceRoleBindings()}
                    scopeResourceModel={scopeResourceModel}/>,
                localization.tabs.resourceRoleBindings(),
                "resourceRoleBindings")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="rawShortNumberId"
                        title={localization.info.items.rawShortNumber()}
                        value={tenantEntity.rawShortNumberId}/>,
                    <InfoItem
                        key="rawShortNameId"
                        title={localization.info.items.rawShortName()}
                        value={tenantEntity.rawShortNameId}/>,
                    <InfoItem
                        key="parentPath"
                        title={localization.info.items.parentPath()}
                        value={
                            <TenantEntityParentPathCell
                                parentEntityId={(tenantEntityModel.entity as Contract.GcpTenantEntity).parentResourceId}
                                parentEntityPath={tenantEntityModel.parentEntityPath}/>}/>,
                    <InfoItem
                        key="computeProjectSshIamEnabled"
                        title={localization.info.items.computeProjectInstanceDefaultSshIamEnabled.title()}
                        value={
                            _.isNil(computeProject?.instanceDefaultSshIamEnabled)
                                ? undefined
                                : computeProject!.instanceDefaultSshIamEnabled
                                    ? localization.info.items.computeProjectInstanceDefaultSshIamEnabled.true()
                                    : localization.info.items.computeProjectInstanceDefaultSshIamEnabled.false()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={tenantEntityModel.apiKeysKeyIds}
                        entityTypeName={Contract.TypeNames.GcpApiKeysKey}
                        key="apiKeys"
                        title={localization.info.items.apiKeysKeys()}/>
                ]}
                options={options?.infoOptions}>
                {!_.isNil(tenantEntity.usage) &&
                    <InfoCard title={localization.info.card.usage()}>
                        <GcpTenantEntityUsageSection
                            activeOwnerReferences={tenantEntityModel.activeOwnerReferences}
                            inactiveOwnerReferences={tenantEntityModel.inactiveOwnerReferences}
                            usage={tenantEntity.usage}/>
                    </InfoCard>}
                {!_.isNil(computeProject?.instanceDefaultMetadataKeyToValueMap) &&
                    <InfoCard title={localization.info.card.computeProjectInstanceDefaultMetadataKeyToValueMap.title()}>
                        <KeyValueTable
                            emptyMessageText={new EmptyMessageText(localization.info.card.computeProjectInstanceDefaultMetadataKeyToValueMap.empty())}
                            items={
                                _.map(
                                    computeProject!.instanceDefaultMetadataKeyToValueMap,
                                    (value, key) =>
                                        new KeyValueTableItem(
                                            key,
                                            value))}/>
                    </InfoCard>}
            </Info>
    });
}