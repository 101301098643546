﻿import { Link, useLocalization } from "@infrastructure";
import React from "react";
import { Contract, Entity, entityModelStore, useKubernetesClusterPlatformTranslator } from "../../../../../../../../../../../../common";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { useGetCommonKubernetesClusterRiskContext } from "./contexts";

export function useKubernetesClusterPlatformEndOfLifeRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.KubernetesClusterPlatformEndOfLifeRisk;
    const clusterModel = entityModelStore.useGet(risk.entityId);

    const getCommonKubernetesClusterRiskContext = useGetCommonKubernetesClusterRiskContext(clusterModel);

    const platformTranslator = useKubernetesClusterPlatformTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.kubernetes.hooks.clusters.useKubernetesClusterPlatformEndOfLifeRiskDefinition",
            () => ({
                description: "{{cluster}} version has reached End-of-Life",
                resolutionSection: {
                    step1: {
                        links: {
                            updateVersionDocumentationLink: "{{translatedPlatform}} documentation"
                        },
                        title: "Update the version of {{cluster}} by following the {{updateVersionDocumentationLink}}."
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            cluster:
                <Entity
                    entityIdOrModel={clusterModel}
                    entityTypeNameTranslatorOptions={{
                        includeServiceName: true,
                        variant: "title"
                    }}
                    variant="typeText"/>
        }),
        () => [
            localization.resolutionSection.step1.title({
                cluster:
                    <Entity
                        entityIdOrModel={clusterModel}
                        entityTypeNameTranslatorOptions={{ includeServiceName: true }}
                        variant="typeText"/>,
                updateVersionDocumentationLink:
                    <Link
                        urlOrGetUrl={(riskModel as Contract.KubernetesClusterPlatformEndOfLifeRiskModel).updateVersionDocumentationUrl}
                        variant="external">
                        {localization.resolutionSection.step1.links.updateVersionDocumentationLink({ translatedPlatform: platformTranslator(risk.clusterPlatform) })}
                    </Link>
            })
        ],
        riskModel,
        () => {
            const clusterRiskContext = getCommonKubernetesClusterRiskContext(clusterModel);
            return [
                clusterRiskContext.generalInformation,
                clusterRiskContext.sensitive,
                clusterRiskContext.virtualNetwork,
                clusterRiskContext.platformVersion,
                clusterRiskContext.version,
                clusterRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        });
}