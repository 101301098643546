﻿import { OrderedWizardItem, useLocalization } from "@infrastructure";
import React from "react";
import { RunTaskItem, WorkspaceItem } from "./components";

export function useTerraformCloudRunTask() {
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCodePipelineItems.codePipeline.addOrEdit.hooks.useDefinition.hooks.useTerraformCloudRunTask",
            () => ({
                steps: {
                    runTask: "Create a Run Task",
                    workspace: "Associate a Run Task with a Workspace"
                }
            }));
    return {
        apiKeyItem: false,
        orderedWizardItems: [
            <OrderedWizardItem
                key="runTask"
                title={localization.steps.runTask()}>
                <RunTaskItem/>
            </OrderedWizardItem>,
            <OrderedWizardItem
                key="workspace"
                title={localization.steps.workspace()}>
                <WorkspaceItem/>
            </OrderedWizardItem>
        ]
    };
}