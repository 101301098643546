import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, Entity, entityModelStore, InlineEntities } from "../../../../../../../../../../../../common";
import { useAzureCommonBehaviorIdentityRiskDefinition } from "./useAzureCommonBehaviorIdentityRiskDefinition";

export function useAzureNetworkAccessManagementRiskDefinition(riskModel: Contract.RiskModel) {
    const networkAccessManagementRiskModel = riskModel as Contract.AzureNetworkAccessManagementRiskModel;
    const identityModel = entityModelStore.useGet(networkAccessManagementRiskModel.risk.identityId);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.behavior.useAzureNetworkAccessManagementRiskDefinition",
            () => ({
                description: "{{identity}} was observed modifying network access settings of {{resources}}"
            }));
    return useAzureCommonBehaviorIdentityRiskDefinition(
        networkAccessManagementRiskModel,
        localization.description({
            identity:
                <Entity
                    entityIdOrModel={identityModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>,
            resources:
                <InlineEntities
                    entityIdsOrModels={networkAccessManagementRiskModel.resourceIdReferences}
                    entityTypeName={Contract.TypeNames.AzureResource}
                    variant="itemAndTypeOrItemCountAndType"/>
        }));
}