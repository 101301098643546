import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { EntityExternalConsoleLink } from "../../components";
import { useGetAzureEventHubNamespaceRiskContext } from "../contexts";

export function useAzureEventHubNamespaceInboundExternalWideRangeRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AzureEventHubNamespaceInboundExternalWideRangeRisk;
    const namespaceModel = entityModelStore.useGet(risk.entityId) as Contract.AzureEventHubNamespaceModel;

    const getAzureEventHubNamespaceRiskContext = useGetAzureEventHubNamespaceRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.compliance.useAzureEventHubNamespaceInboundExternalWideRangeRiskDefinition",
            () => ({
                description: "{{namespaceModel}} allows inbound network access from a wide range of public IP addresses",
                sections: {
                    resolution: {
                        step1: "Click **Networking**",
                        step2: "In the **Public access** section, select either **Selected networks** or **Disabled**",
                        step3: "If you select **Selected networks**, restrict access to the specific IP ranges by configuring **Virtual networks** or by adding IP ranges in the **Firewall** section",
                        step4: "Click **Save**"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            namespaceModel:
                <Entity
                    entityIdOrModel={namespaceModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={risk.entityId}
                key={risk.entityId}
                page={Contract.AzureConsoleEntityPage.Networking}/>,
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4()
        ],
        riskModel,
        () => {
            const namespaceRiskContext = getAzureEventHubNamespaceRiskContext(namespaceModel);
            return [
                namespaceRiskContext.generalInformation,
                namespaceRiskContext.sensitive,
                namespaceRiskContext.status,
                namespaceRiskContext.eventHubs,
                namespaceRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}