import { NumberFormatter } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, useTheme } from "../../../../../../../../../../../../common";

type CustomYAxisTickProps = {
    payload?: CustomYAxisTickPayload;
    percentage?: boolean;
    x?: number;
    y?: number;
};

type CustomYAxisTickPayload = {
    value: number;
};

export function CustomYAxisTick({ payload, percentage = false, x, y }: CustomYAxisTickProps) {
    const theme = useTheme();
    return (
        _.isNil(payload)
            ? null
            : <text
                dy={4}
                style={{
                    fill:
                        payload.value === 9
                            ? theme.palette.severity(Contract.Severity.Critical)
                            : payload.value === 7
                                ? theme.palette.severity(Contract.Severity.High)
                                : theme.palette.text.secondary
                }}
                textAnchor="end"
                x={x}
                y={y}>
                <tspan
                    dy="0.355em"
                    x={x}>
                    {percentage
                        ? NumberFormatter.percentageFraction(payload.value)
                        :payload.value}
                </tspan>
            </text>);
}