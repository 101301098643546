import { Button, Typography } from "@mui/material";
import _ from "lodash";
import React, { ReactNode, useMemo, useRef, useState } from "react";
import { AddIcon, DataTableAction, DataTableActions, DataTableColumn, DataTableColumnRenderProps, Dialog, EmptyMessageText, makeContextProvider, StringHelper, ToggleFilter, useChangeEffect, useLocalization, ValueFilter, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import { Contract, ItemTable, ItemTableFilterOptions, settingConfigurationOperationStore, settingConfigurationTypeNamesOperationStore, StorageHelper, UserHelper, useScopeNameTranslator, useScopeNavigationViewContext, useTheme } from "../../../../../../common";
import { ActionsCell, AddOrEdit, Value } from "./components";
import { useScopeSettingConfigurationDerivedTypeNameTranslator } from "./hooks";

export class ConfigurationContext {
    constructor(public addOrEditOpen: boolean | Contract.ScopeSettingConfiguration) {
    }
}

export const [useConfigurationContextContext, useSetConfigurationContextContext, useConfigurationContextContextProvider] = makeContextProvider<ConfigurationContext>();

type SettingConfigurationTableProps = {
    typeName: string;
};

export function SettingConfigurationTable({ typeName }: SettingConfigurationTableProps) {
    const [{ addOrEditOpen }, setConfigurationContextContext, ContextProvider] = useConfigurationContextContextProvider(() => new ConfigurationContext(false));
    const [filterMap, setFilterMap] = useState<_.Dictionary<any>>({});
    const { scopeNodeModel } = useScopeNavigationViewContext();
    const [childScopeTenantsEnabled, effective] =
        useMemo(
            () => {
                const childScopeTenantsEnabled =
                    filterMap[SettingTableColumnIds.ChildScopeTenantsEnabled] ??
                    _.isNil(StorageHelper.customerConfigurationSettingTableFlatView(typeName).getValue())
                        ? true
                        : StringHelper.isTrue(StorageHelper.customerConfigurationSettingTableFlatView(typeName).getValue());
                const effective =
                    filterMap[SettingTableColumnIds.Effective] ??
                    false;

                return [childScopeTenantsEnabled, effective];
            },
            [filterMap]);

    const { typeNames } =
        settingConfigurationTypeNamesOperationStore.useGet(
            scopeNodeModel.configuration.id,
            typeName);
    const { scopeSettingConfigurationDatas } =
        settingConfigurationOperationStore.useGet(
            scopeNodeModel.configuration.id,
            typeName,
            effective,
            childScopeTenantsEnabled);

    const dataTableActionsRef = useRef<DataTableActions>();
    useChangeEffect(
        () => {
            dataTableActionsRef.current?.reload({ refreshFilters: true });
        },
        [scopeSettingConfigurationDatas]);

    const scopeNameTranslator = useScopeNameTranslator();
    const scopeSettingConfigurationDerivedTypeNameTranslator = useScopeSettingConfigurationDerivedTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.configuration.settingConfigurationTable",
            () => ({
                actions: {
                    add: "Add Setting",
                    flat: "Flat View"
                },
                columns: {
                    [SettingTableColumnIds.ChildScopeTenantsEnabled]: "Flat View",
                    [SettingTableColumnIds.Name]: "Name",
                    [SettingTableColumnIds.Scope]: "Scope",
                    [SettingTableColumnIds.Value]: "Value",
                    [SettingTableColumnIds.Effective]: "Effective"
                },
                empty: {
                    withFilter: "No Matching Settings",
                    withoutFilter: "No Settings"
                }
            }));

    const initialFiltersOptions =
        useMemo(
            (): ItemTableFilterOptions => ({
                initialState: {
                    [SettingTableColumnIds.ChildScopeTenantsEnabled]: childScopeTenantsEnabled
                },
                onChanged: setFilterMap,
                persist: true
            }),
            [childScopeTenantsEnabled]);

    const theme = useTheme();
    return (
        <ContextProvider>
            {addOrEditOpen &&
                <Dialog
                    variant="editor"
                    onClose={() => setConfigurationContextContext(({ addOrEditOpen: false }))}>
                    <AddOrEdit derivedTypeNames={typeNames as Contract.ScopeSettingConfigurationDerivedTypeNames[]}/>
                </Dialog>}
            <ItemTable
                actionsRef={dataTableActionsRef}
                columnIdToGetItemValueMap={{
                    [SettingTableColumnIds.Name]: item => scopeSettingConfigurationDerivedTypeNameTranslator(item.scopeSettingConfiguration.typeName as Contract.ScopeSettingConfigurationDerivedTypeNames).title,
                    [SettingTableColumnIds.Scope]: item => scopeNameTranslator(item.scopeSettingConfiguration.scopeId, { path: true })
                }}
                dataTableActions={
                    <DataTableAction>
                        <Button
                            disabled={_.isEmpty(typeNames) || !UserHelper.hasScopePermissions(scopeNodeModel.configuration.id, Contract.IdentityPermission.SecurityAdministrationRead)}
                            size="small"
                            startIcon={<AddIcon/>}
                            onClick={() => setConfigurationContextContext(context => ({ addOrEditOpen: !context.addOrEditOpen }))}>
                            {localization.actions.add()}
                        </Button>
                    </DataTableAction>}
                defaultSortColumnIdOrIds={[SettingTableColumnIds.Name, SettingTableColumnIds.Scope]}
                emptyMessageOptions={{
                    emptyMessageText:
                        new EmptyMessageText(
                            localization.empty.withoutFilter(),
                            localization.empty.withFilter())
                }}
                filterOptions={initialFiltersOptions}
                getItemId={(item: Contract.ScopeSettingConfigurationData) => item.scopeSettingConfiguration.id}
                items={scopeSettingConfigurationDatas}
                showEmptyTable={true}
                variant="view">
                {columnIdToItemValuesMap =>
                    _<ReactNode>([]).
                        concat(
                            <DataTableColumn
                                filterOptions={{
                                    itemOrItems: {
                                        default: true,
                                        element:
                                            <ValuesFilter placeholder={localization.columns[SettingTableColumnIds.Name]()}>
                                                {_.map(
                                                    columnIdToItemValuesMap[SettingTableColumnIds.Name],
                                                    name =>
                                                        <ValuesFilterItem
                                                            key={name}
                                                            title={name}
                                                            value={name}/>)}
                                            </ValuesFilter>
                                    }
                                }}
                                id={SettingTableColumnIds.Name}
                                key={SettingTableColumnIds.Name}
                                render={
                                    ({ item }: DataTableColumnRenderProps<Contract.ScopeSettingConfigurationData>) =>
                                        <Typography noWrap={true}>
                                            {scopeSettingConfigurationDerivedTypeNameTranslator(item.scopeSettingConfiguration.typeName as Contract.ScopeSettingConfigurationDerivedTypeNames).title}
                                        </Typography>}
                                title={localization.columns[SettingTableColumnIds.Name]()}/>,
                            <DataTableColumn
                                filterOptions={{
                                    itemOrItems: {
                                        default: true,
                                        element:
                                            <ValuesFilter placeholder={localization.columns[SettingTableColumnIds.Scope]()}>
                                                {_.map(
                                                    columnIdToItemValuesMap[SettingTableColumnIds.Scope],
                                                    scope =>
                                                        <ValuesFilterItem
                                                            key={scope}
                                                            title={scope}
                                                            value={scope}/>)}
                                            </ValuesFilter>
                                    }
                                }}
                                id={SettingTableColumnIds.Scope}
                                key={SettingTableColumnIds.Scope}
                                render={
                                    ({ item }: DataTableColumnRenderProps<Contract.ScopeSettingConfigurationData>) =>
                                        <Typography noWrap={true}>
                                            {scopeNameTranslator(item.scopeSettingConfiguration.scopeId, { path: true })}
                                        </Typography>}
                                title={localization.columns[SettingTableColumnIds.Scope]()}/>,
                            <DataTableColumn
                                id={SettingTableColumnIds.Value}
                                key={SettingTableColumnIds.Value}
                                render={
                                    ({ item }: DataTableColumnRenderProps<Contract.ScopeSettingConfigurationData>) =>
                                        <Value
                                            settingConfiguration={item.scopeSettingConfiguration}
                                            variant="view"/>}
                                sortOptions={{ enabled: false }}
                                title={localization.columns[SettingTableColumnIds.Value]()}/>,
                            <DataTableColumn
                                disableAction={true}
                                headerSx={{ minWidth: theme.spacing(5) }}
                                id={SettingTableColumnIds.Actions}
                                key={SettingTableColumnIds.Actions}
                                orderable={false}
                                render={({ item }: DataTableColumnRenderProps<Contract.ScopeSettingConfigurationData>) => <ActionsCell scopeSettingConfigurationData={item}/>}
                                resizable={false}
                                selectorOptions={{ disabled: true }}
                                sortOptions={{ enabled: false }}/>,
                            <DataTableColumn
                                filterOptions={{
                                    itemOrItems: {
                                        default: true,
                                        element:
                                            <ValueFilter
                                                items={[{ value: true }]}
                                                placeholder={localization.columns[SettingTableColumnIds.Effective]()}/>
                                    }
                                }}
                                id={SettingTableColumnIds.Effective}
                                key={SettingTableColumnIds.Effective}
                                title={localization.columns[SettingTableColumnIds.Effective]()}/>,
                            <DataTableColumn
                                filterOptions={{
                                    toggleItem:
                                        <ToggleFilter
                                            persistStorageItem={StorageHelper.customerConfigurationSettingTableFlatView(typeName)}
                                            title={localization.columns[SettingTableColumnIds.ChildScopeTenantsEnabled]()}/>
                                }}
                                id={SettingTableColumnIds.ChildScopeTenantsEnabled}
                                key={SettingTableColumnIds.ChildScopeTenantsEnabled}/>).
                        value()}
            </ItemTable>
        </ContextProvider>);
}

enum SettingTableColumnIds {
    Actions = "actions",
    ChildScopeTenantsEnabled = "childScopeTenantsEnabled",
    Effective = "effective",
    Name = "name",
    Scope = "scope",
    Value = "value"
}