import { useMemo } from "react";
import { Contract } from "../../../../../../../../../../../../common";
import { useGetEntitiesRiskContext } from "../../../useGetEntitiesRiskContext";

export function useGetOciIamUserApiKeysRiskContext() {
    return useMemo(
        () => useOciIamUserApiKeysRiskContext,
        []);
}

function useOciIamUserApiKeysRiskContext(apiKeyIdsOrModels: string[] | Contract.OciIamUserApiKeyModel[]) {
    return useGetEntitiesRiskContext()(
        apiKeyIdsOrModels,
        Contract.TypeNames.OciIamUserApiKey);
}