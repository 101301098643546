import { NoneIcon, useExecuteOperation } from "@infrastructure";
import { Stack } from "@mui/material";
import React, { memo, useMemo } from "react";
import { Contract, InlineEntities, RiskController, RiskPolicyItem, RiskPolicyTypeMetadataHelper } from "../../../../../common";

const FailedEntitiesMemo = memo(FailedEntities);
export { FailedEntitiesMemo as FailedEntities };

type FailedEntitiesProps = {
    item: RiskPolicyItem;
    scopeId: string;
};

function FailedEntities({ item, scopeId }: FailedEntitiesProps) {
    return item.enabled && !item.riskPolicyTypeMetadata.temporal
        ? <Core
            item={item}
            scopeId={scopeId}/>
        : <NoneIcon sx={{ fontSize: "18px" }}/>;
}

function Core({ item, scopeId }: FailedEntitiesProps) {
    const entityTypeName =
        useMemo(
            () => RiskPolicyTypeMetadataHelper.getRiskedEntityTypeName(item.riskPolicyConfigurationTypeName),
            [item]);

    const [{ failedEntityIds }] =
        useExecuteOperation(
            [Core, `${scopeId}/${item.riskPolicyConfigurationTypeNameOrId}`],
            async () =>
                await RiskController.getRiskPolicyFailedEntityIds(
                    new Contract.RiskControllerGetRiskPolicyFailedEntityIdsRequest(
                        Contract.RiskPolicyTypeMetadataAnalysisGroupType.AllEntities,
                        item.riskPolicyConfigurationTypeNameOrId,
                        scopeId)),
            {
                persistenceStrategy: "always"
            });

    return (
        <Stack spacing={1}>
            {failedEntityIds.length === 0
                ? <NoneIcon sx={{ fontSize: "18px" }}/>
                : <InlineEntities
                    entityIdsOrModels={failedEntityIds}
                    entityTypeName={entityTypeName}
                    entityVariant="iconTextTypeTenant"
                    variant="itemCountAndType"/>}
        </Stack>);
}