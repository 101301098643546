import { CheckboxField, CollapsedIcon, EmptyMessage, SearchTextField, StringHelper, useChangeEffect, useLocalization } from "@infrastructure";
import { Accordion, AccordionDetails, AccordionSummary, Stack, Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { useMemo, useState } from "react";
import { Contract, ObjectIdentifierData, useTheme } from "../../..";
import { useUdmObjectPropertyIdTranslator, useUdmObjectTypeNameTranslator } from "../hooks";

type ColumnSelectorProps = {
    objectIdentifierToDataMap: Dictionary<ObjectIdentifierData>;
    onSelectedPropertyIdsChanged: (objectIdentifier: string, propertyIds: Contract.UdmObjectPropertyId[]) => void;
};

export function ColumnSelector({ objectIdentifierToDataMap, onSelectedPropertyIdsChanged }: ColumnSelectorProps) {
    const [searchText, setSearchText] = useState<string>("");

    const udmObjectPropertyIdTranslator = useUdmObjectPropertyIdTranslator();
    const udmObjectTypeNameTranslator = useUdmObjectTypeNameTranslator();
    const localization =
        useLocalization(
            "common.udmObjectTable.columnSelector",
            () => ({
                search: {
                    noResults: "No Columns",
                    placeholder: "Search"
                }
            }));

    const onSelectedPropertyIdsChangedDebounced = _.debounce(onSelectedPropertyIdsChanged, 500);

    const filteredObjectIdentifierToPropertyIdsMap =
        useMemo(
            () =>
                _.mapValues(
                    objectIdentifierToDataMap,
                    objectData => {
                        if (StringHelper.search(udmObjectTypeNameTranslator(objectData.objectTypeName), searchText)) {
                            return objectData.udmTablePropertyIds;
                        }

                        const filteredTablePropertyIds =
                            _.filter(
                                objectData.udmTablePropertyIds,
                                tablePropertyId => StringHelper.search(udmObjectPropertyIdTranslator(tablePropertyId), searchText));
                        if (!_.isEmpty(filteredTablePropertyIds)) {
                            return filteredTablePropertyIds;
                        }

                        return undefined;
                    }),
            [objectIdentifierToDataMap, searchText]);

    const theme = useTheme();
    return (
        <Stack
            spacing={0.5}
            sx={{
                backgroundColor: theme.palette.background.paper,
                height: "100%",
                padding: theme.spacing(0.5),
                width: "100%"
            }}>
            <SearchTextField
                placeholder={localization.search.placeholder()}
                searchText={searchText}
                onSearchTextChanged={searchText => setSearchText(searchText || "")}/>
            <Stack sx={{ overflow: "hidden auto" }}>
                {_.size(filteredObjectIdentifierToPropertyIdsMap) > 0
                    ? _.map(
                        filteredObjectIdentifierToPropertyIdsMap,
                        (filteredObjectPropertyIds, filteredObjectIdentifier) =>
                            <Item
                                expanded={_.size(filteredObjectIdentifierToPropertyIdsMap) === 1}
                                filteredObjectPropertyIds={filteredObjectPropertyIds!}
                                key={filteredObjectIdentifier}
                                objectIdentifierData={objectIdentifierToDataMap[filteredObjectIdentifier]}
                                searching={!_.isEmpty(searchText)}
                                onSelectedPropertyIdsChanged={
                                    selectedPropertyIds =>
                                        onSelectedPropertyIdsChangedDebounced(
                                            filteredObjectIdentifier,
                                            selectedPropertyIds)}/>)
                    : <EmptyMessage message={localization.search.noResults()}/>}
            </Stack>
        </Stack>);
}

type ItemProps = {
    expanded: boolean;
    filteredObjectPropertyIds: Contract.UdmObjectPropertyId[];
    objectIdentifierData: ObjectIdentifierData;
    onSelectedPropertyIdsChanged: (selectedPropertyIds: Contract.UdmObjectPropertyId[]) => void;
    searching: boolean;
};

function Item({ expanded: initialExpanded, filteredObjectPropertyIds, objectIdentifierData, onSelectedPropertyIdsChanged, searching }: ItemProps) {
    const [expanded, setExpanded] = useState(initialExpanded);
    const [selectedPropertyIds, setSelectedPropertyIds] = useState(() => objectIdentifierData.udmQueryOptions?.propertyIds ?? []);
    useChangeEffect(
        () => {
            onSelectedPropertyIdsChanged(selectedPropertyIds);
        },
        [selectedPropertyIds]);
    const allSelected =
        useMemo(
            () =>
                _(filteredObjectPropertyIds).
                    xor(selectedPropertyIds).
                    isEmpty(),
            [filteredObjectPropertyIds, selectedPropertyIds]);

    const udmObjectPropertyIdTranslator = useUdmObjectPropertyIdTranslator();
    const udmObjectTypeNameTranslator = useUdmObjectTypeNameTranslator();
    const localization =
        useLocalization(
            "common.udmObjectTable.columnSelector.item",
            () => ({
                action: {
                    all: "Select all",
                    none: "Unselect all",
                    search: {
                        all: "Select matches",
                        none: "Unselect matches"
                    }
                }
            }));

    const theme = useTheme();
    return (
        <Accordion
            disableGutters={true}
            expanded={expanded || searching}
            onChange={
                (event, expanded) => {
                    setExpanded(expanded);
                    event.stopPropagation();
                    event.preventDefault();
                }}>
            <AccordionSummary
                expandIcon={<CollapsedIcon/>}
                sx={{ minHeight: 32 }}>
                <Stack
                    alignItems="center"
                    direction="row"
                    justifyContent="space-between"
                    spacing={2}
                    sx={{ width: "100%" }}>
                    <Typography variant="h5">
                        {udmObjectTypeNameTranslator(objectIdentifierData.objectTypeName)}
                    </Typography>
                    <Typography
                        sx={{
                            color: theme.palette.text.secondary,
                            cursor: "pointer",
                            textDecoration: "underline"
                        }}
                        onClick={
                            event => {
                                setSelectedPropertyIds(
                                    searching
                                        ? allSelected
                                            ? _(selectedPropertyIds).
                                                without(...filteredObjectPropertyIds).
                                                concat(objectIdentifierData.udmSelfIdProperty.id).
                                                uniq().
                                                value()
                                            : _(selectedPropertyIds).
                                                concat(
                                                    filteredObjectPropertyIds,
                                                    objectIdentifierData.udmSelfIdProperty.id).
                                                uniq().
                                                value()
                                        : allSelected
                                            ? [objectIdentifierData.udmSelfIdProperty.id]
                                            : objectIdentifierData.udmTablePropertyIds);
                                event.stopPropagation();
                            }}>
                        {searching
                            ? allSelected
                                ? localization.action.search.none()
                                : localization.action.search.all()
                            : allSelected
                                ? localization.action.none()
                                : localization.action.all()}
                    </Typography>
                </Stack>
            </AccordionSummary>
            <AccordionDetails
                sx={{
                    marginTop: 0,
                    padding: 0
                }}>
                {_.map(
                    filteredObjectPropertyIds,
                    filteredObjectPropertyId =>
                        <Stack
                            direction="row"
                            key={filteredObjectPropertyId}
                            sx={{
                                paddingLeft: theme.spacing(1),
                                width: "100%"
                            }}>
                            <CheckboxField
                                checked={_.includes(selectedPropertyIds, filteredObjectPropertyId)}
                                disabled={objectIdentifierData.udmSelfIdProperty.id === filteredObjectPropertyId}
                                key={filteredObjectPropertyId}
                                onChange={
                                    (_event, checked) =>
                                        setSelectedPropertyIds(
                                            checked
                                                ? _.concat(selectedPropertyIds, filteredObjectPropertyId)
                                                : _.without(selectedPropertyIds, filteredObjectPropertyId))}
                                onClick={event => event.stopPropagation()}>
                                <Typography noWrap={true}>
                                    {udmObjectPropertyIdTranslator(filteredObjectPropertyId)}
                                </Typography>
                            </CheckboxField>
                        </Stack>)}
            </AccordionDetails>
        </Accordion>);
}