import { useMemo } from "react";
import { Contract, RiskController } from "../../../../../../../common";
import { RiskController as RiskControllerDefinition } from "../useRiskController";

export function useCodeRiskController() {
    return useMemo(
        (): RiskControllerDefinition => ({
            getRiskGroups:
                (requestFilters, groupRequestType) =>
                    RiskController.getRiskGroups(
                        new Contract.RiskControllerGetCodeRiskGroupsRequest(
                            requestFilters,
                            groupRequestType
                        )),
            getRiskIdToScopeIdentityPermissionsMap:
                requestFilters =>
                    RiskController.getRiskIdToScopeIdentityPermissionsMap(
                        new Contract.RiskControllerGetCodeRiskIdToScopeIdentityPermissionsMapRequest(requestFilters)),
            getRiskModelPage:
                (requestFilters, limit, skip, sort) =>
                    RiskController.getRiskModelPage(
                        new Contract.RiskControllerGetCodeRiskModelPageRequest(
                            requestFilters,
                            limit,
                            skip,
                            sort)),
            updateCloseRiskStatus:
                (riskIds, message) =>
                    RiskController.updateRiskStatus(
                        new Contract.RiskControllerUpdateRiskCloseStatusRequest(
                            message,
                            riskIds)),
            updateOpenRiskStatus:
                (riskIds, message) =>
                    RiskController.updateRiskStatus(
                        new Contract.RiskControllerUpdateRiskOpenStatusRequest(
                            message,
                            riskIds))
        }),
        []);
}