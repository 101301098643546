import { map } from "@infrastructure";
import { Contract, tenantModelStore } from "../../../../../../../../../../../../../../../common";
import { AzureConsoleUrlBuilder } from "../../../../../../../../../../../../../../../tenants";

export function useAzureKeyVaultVaultDefinition(entityModel: Contract.EntityModel, page: Contract.AzureConsoleEntityPage) {
    const tenantModel = tenantModelStore.useGet(entityModel.entity.tenantId);
    const aadTenantModel = tenantModelStore.useGet((tenantModel.configuration as Contract.AzureTenantConfiguration).aadTenantId);
    const aadTenantConfiguration = aadTenantModel.configuration as Contract.AadTenantConfiguration;

    const getUrlFunction =
        map(
            page,
            {
                [Contract.AzureConsoleEntityPage.AccessPolicies]: () => AzureConsoleUrlBuilder.GetKeyVaultVaultAccessPoliciesUrl,
                [Contract.AzureConsoleEntityPage.Certificates]: () => AzureConsoleUrlBuilder.GetEntityCertificatesUrl,
                [Contract.AzureConsoleEntityPage.Diagnostics]: () => AzureConsoleUrlBuilder.GetEntityDiagnosticsUrl,
                [Contract.AzureConsoleEntityPage.Keys]: () => AzureConsoleUrlBuilder.GetEntityKeysUrl,
                [Contract.AzureConsoleEntityPage.Networking]: () => AzureConsoleUrlBuilder.GetEntityNetworkingUrl,
                [Contract.AzureConsoleEntityPage.Overview]: () => AzureConsoleUrlBuilder.GetEntityOverviewUrl,
                [Contract.AzureConsoleEntityPage.Properties]: () => AzureConsoleUrlBuilder.GetEntityPropertiesUrl,
                [Contract.AzureConsoleEntityPage.RoleAssignments]: () => AzureConsoleUrlBuilder.GetEntityRoleAssignmentsUrl,
                [Contract.AzureConsoleEntityPage.Secrets]: () => AzureConsoleUrlBuilder.GetEntitySecretsUrl
            });

    return {
        url:
            getUrlFunction(
                aadTenantConfiguration.primaryDomainName,
                aadTenantConfiguration.partitionType,
                (entityModel.entity as Contract.AzureKeyVaultVault).path!)
    };
}