import { Step, StepOptions } from "@infrastructure";
import { Stack } from "@mui/material";
import _ from "lodash";
import React, { ReactNode } from "react";

type ChangeSetStepOptions = Omit<StepOptions, "actionsElement"> & {
    actionElements?: ReactNode[];
};

export class ChangeSetStep extends Step {
    constructor(
        title: ReactNode,
        options?: ChangeSetStepOptions) {
        super(
            title,
            {
                ...options,
                actionsElement:
                    _.isEmpty(options?.actionElements)
                        ? undefined
                        : <Actions actionElements={options!.actionElements}/>
            });
    }
}

type ActionsProps = {
    actionElements?: ReactNode[];
};

function Actions({ actionElements }: ActionsProps) {
    return (
        <Stack
            alignItems="center"
            direction="row"
            justifyContent="flex-end"
            sx={{ width: "100%" }}>
            {_.map(
                actionElements,
                actionElement =>
                    actionElement)}
        </Stack>);
}