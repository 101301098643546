﻿import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useAadDirectoryUserGuestUserInvitationStatusTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useAadDirectoryUserGuestUserInvitationStatusTranslator",
            () => ({
                [Contract.TypeNames.AadDirectoryUserGuestUserInvitationStatus]: {
                    [Contract.AadDirectoryUserGuestUserInvitationStatus.Accepted]: "Accepted",
                    [Contract.AadDirectoryUserGuestUserInvitationStatus.PendingAcceptance]: "Pending"
                }
            }));
    return (guestUserInvitationStatus: Contract.AadDirectoryUserGuestUserInvitationStatus) =>
        localization[Contract.TypeNames.AadDirectoryUserGuestUserInvitationStatus][guestUserInvitationStatus]();
}