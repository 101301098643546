import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../../";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetAadDirectoryGroupRiskContext } from "../contexts";

export function useAadDirectoryGroupInactiveRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AadDirectoryGroupInactiveRisk;
    const groupModel = entityModelStore.useGet(risk.entityId) as Contract.AadDirectoryGroupModel;
    const getAadDirectoryGroupRiskContext = useGetAadDirectoryGroupRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aad.hooks.compliance.useAadDirectoryGroupInactiveRiskDefinition",
            () => ({
                description: {
                    identityExists: "All identities in group {{group}} are inactive",
                    identityNotExist: "Group {{group}} has no identities (direct or indirect)"
                },
                sections: {
                    context: {
                        activityTimeExists: [
                            "The identity (direct or indirect) in the group has been inactive for more than {{activityTime | TimeFormatter.humanizeDuration}}",
                            "All {{groupIdentityCount}} identities (direct or indirect) in the group have been inactive for more than {{activityTime | TimeFormatter.humanizeDuration}}"
                        ],
                        activityTimeNotExist: [
                            "The identity (direct or indirect) in the group has been inactive since its creation",
                            "All {{groupIdentityCount}} identities (direct or indirect) in the group have been inactive since their creation"
                        ],
                        groupIdentityNotExist: "The group has no identities (direct or indirect)"
                    }
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        (risk.groupIdentityExists
            ? localization.description.identityExists
            : localization.description.identityNotExist)(
            {
                group:
                    <Entity
                        entityIdOrModel={groupModel}
                        variant="text"/>
            }),
        () => [],
        riskModel,
        () => {
            const groupRiskContext = getAadDirectoryGroupRiskContext(groupModel);

            const inactivityContextItem =
                risk.groupIdentityExists
                    ? _.isNil(groupRiskContext.activityTime)
                        ? new RiskDefinitionContextItem(
                            localization.sections.context.activityTimeNotExist(
                                risk.groupIdentityCount,
                                { groupIdentityCount: risk.groupIdentityCount }))
                        : new RiskDefinitionContextItem(
                            localization.sections.context.activityTimeExists(
                                risk.groupIdentityCount,
                                {
                                    activityTime: groupRiskContext.activityTime,
                                    groupIdentityCount: risk.groupIdentityCount
                                }))
                    : new RiskDefinitionContextItem(localization.sections.context.groupIdentityNotExist());

            return [
                groupRiskContext.generalInformation,
                inactivityContextItem,
                groupRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)];
        });
}