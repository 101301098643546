import { DataTableColumn, DataTableColumnRenderProps, optionalTableCell, PagedValuesFilter, useLocalization } from "@infrastructure";
import { Dictionary } from "lodash";
import React from "react";
import { useOciCommonResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions, useEntityTypeNameTranslator } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";

export function useOciNetworkingVirtualNetworkInterfaceCardDefinition(definitionData: DefinitionData) {
    const commonResourceDefinition = useOciCommonResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.oci.useOciNetworkingVirtualNetworkInterfaceCardDefinition",
            () => ({
                columns: {
                    PrivateIpAddress: "Private IP Address",
                    PublicIpAddress: "Public IP Address"
                }
            }));

    return new EntityTableDefinition(
        [
            commonResourceDefinition.columns.tenantColumn,
            commonResourceDefinition.columns.creationTimeColumn,
            commonResourceDefinition.columns.creatorCsvColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.OciNetworkingVirtualNetworkInterfaceCardModel) => ({
                            [localization.columns.PrivateIpAddress()]: (item.entity as Contract.OciNetworkingVirtualNetworkInterfaceCard).privateIpAddress ?? ""
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedValuesFilter
                                getValuePage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.OciNetworkingVirtualNetworkInterfaceCardPrivateIpAddress)}
                                placeholder={localization.columns.PrivateIpAddress()}/>
                    }
                }}
                id={Contract.EntityModelProperty.OciNetworkingVirtualNetworkInterfaceCardPrivateIpAddress}
                key={Contract.EntityModelProperty.OciNetworkingVirtualNetworkInterfaceCardPrivateIpAddress}
                render={optionalTableCell<Contract.OciNetworkingVirtualNetworkInterfaceCardModel>(item => (item.entity as Contract.OciNetworkingVirtualNetworkInterfaceCard).privateIpAddress)}
                title={localization.columns.PrivateIpAddress()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.OciNetworkingVirtualNetworkInterfaceCardModel) => ({
                            [localization.columns.PublicIpAddress()]: (item.entity as Contract.OciNetworkingVirtualNetworkInterfaceCard).publicIpAddress ?? ""
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedValuesFilter
                                getValuePage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.OciNetworkingVirtualNetworkInterfaceCardPublicIpAddress)}
                                placeholder={localization.columns.PublicIpAddress()}/>
                    }
                }}
                id={Contract.EntityModelProperty.OciNetworkingVirtualNetworkInterfaceCardPublicIpAddress}
                key={Contract.EntityModelProperty.OciNetworkingVirtualNetworkInterfaceCardPublicIpAddress}
                render={optionalTableCell<Contract.OciNetworkingVirtualNetworkInterfaceCardModel>(item => (item.entity as Contract.OciNetworkingVirtualNetworkInterfaceCard).publicIpAddress)}
                title={localization.columns.PublicIpAddress()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.OciNetworkingVirtualNetworkInterfaceCardModel>(
                        Contract.TypeNames.OciNetworkingSubnet,
                        item => [item.subnetIdReference],
                        entityTypeNameTranslator(Contract.TypeNames.OciNetworkingSubnet))}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.OciNetworkingVirtualNetworkInterfaceCardSubnet)}
                                placeholder={entityTypeNameTranslator(Contract.TypeNames.OciNetworkingSubnet)}/>
                    }
                }}
                id={Contract.EntityModelProperty.OciNetworkingVirtualNetworkInterfaceCardSubnet}
                key={Contract.EntityModelProperty.OciNetworkingVirtualNetworkInterfaceCardSubnet}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.OciNetworkingVirtualNetworkInterfaceCardModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.subnetIdReference}
                            entityTypeName={Contract.TypeNames.OciNetworkingSubnet}/>}
                title={entityTypeNameTranslator(Contract.TypeNames.OciNetworkingSubnet)}/>,
            commonResourceDefinition.columns.regionColumn,
            commonResourceDefinition.columns.regionLocationColumn,
            commonResourceDefinition.columns.tagsColumn,
            commonResourceDefinition.columns.attributesColumn,
            commonResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageOciNetworkingVirtualNetworkInterfaceCardRequest(
                new Contract.EntityControllerGetEntityModelPageOciNetworkingVirtualNetworkInterfaceCardRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciNetworkingVirtualNetworkInterfaceCardPrivateIpAddress]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciNetworkingVirtualNetworkInterfaceCardPublicIpAddress]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciNetworkingVirtualNetworkInterfaceCardSubnet])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags]))));
}