import { useExecuteOperation, useLocalization } from "@infrastructure";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../..";
import { Contract, EntityController } from "../../../../../../../../../../common";
import { EntityTypeNameGroupsInfoCard, Info } from "../../../../components";
import { AzureResourceRoleAssignmentResources } from "../../components";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";
import { useAzureDefaultResourceInfoItemElements } from "./useAzureDefaultResourceInfoItemElements";

export function useAzureResourcesResourceGroupDefinition(resourceModel: Contract.AzureResourceModel, options?: DefinitionOptions) {
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useAzureDefaultResourceInfoItemElements(resourceModel);
    const resourceGroupModel = resourceModel as Contract.AzureResourcesResourceGroupModel;

    const [{ resourceTypeNameToIdsMap }] =
        useExecuteOperation(
            [useAzureResourcesResourceGroupDefinition, resourceGroupModel.id],
            async () => await EntityController.getAzureResourceGroupResourceDatas(new Contract.EntityControllerGetAzureResourceGroupResourceDatasRequest(resourceGroupModel.id)));

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.azure.useAzureResourcesResourceGroupDefinition",
            () => ({
                info: {
                    cards: {
                        resources: {
                            emptyMessage: "No resources",
                            title: "Resources"
                        }
                    }
                },
                tabs: {
                    resourceRoleAssignments: "Resource Role Assignments"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <AzureResourceRoleAssignmentResources
                    csvExportFilePrefix={localization.tabs.resourceRoleAssignments()}
                    scopeResourceModel={resourceGroupModel}/>,
                localization.tabs.resourceRoleAssignments(),
                "resourceRoleAssignments")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[]}
                options={options?.infoOptions}>
                <EntityTypeNameGroupsInfoCard
                    emptyMessage={localization.info.cards.resources.emptyMessage()}
                    entityTypeNameToIdsMap={resourceTypeNameToIdsMap}
                    title={localization.info.cards.resources.title()}/>
            </Info>
    });
}