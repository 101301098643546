import { Box, Button, CircularProgress, Stack, Typography, useTheme } from "@mui/material";
import _, { Function0 } from "lodash";
import React, { Children, Fragment, MutableRefObject, ReactElement, ReactNode, useEffect, useRef, useState } from "react";
import { Action0, BackIcon, defined, getChildElements, Loading, Message, NextIcon, Optional, useChangeEffect, useLocalization } from "@infrastructure";
import { makeContextProvider } from "../../utilities";
import { Item } from "./components";

type OrderedWizardProps = {
    children: ReactNode;
    finishItemButtonTitle?: string;
    finishItemElement?: ReactNode;
    onClose: Action0;
    startItemIndex?: number;
};

export class OrderedWizardContext {
    public executing = false;
    public sideElement?: ReactElement;

    constructor(
        public nextEffectHandlerRef: MutableRefObject<Optional<Function0<Promise<Optional<string | ReactElement>>>>>,
        public setError: (error?: string) => void,
        public setLoaded: () => void,
        public setValid: (valid: boolean) => void) {
    }

    public useNextEffect =
        (handler: Function0<Promise<Optional<string | ReactElement>>>, dependencies: any[]) => {
            useEffect(
                () => {
                    this.nextEffectHandlerRef.current = handler;
                    return () => {
                        this.nextEffectHandlerRef.current = undefined;
                    };
                },
                dependencies);
        };
}

export const [useOrderedWizardContext, useSetOrderedWizardContext, useOrderedWizardContextProvider] = makeContextProvider<OrderedWizardContext>();

export function OrderedWizard({ children, finishItemButtonTitle, finishItemElement, onClose, startItemIndex = 0 }: OrderedWizardProps) {
    const itemElements = getChildElements(Children.toArray(children), OrderedWizardItem);
    const [itemIndex, setItemIndex] = useState(startItemIndex);
    const [itemLoaded, setItemLoaded] = useState(!itemElements[startItemIndex].props.deferredLoading);
    const [itemValid, setItemValid] = useState(true);
    const [error, setError] = useState<string | undefined>(itemElements[itemIndex].props.errorMessage ?? "");

    const nextEffectHandlerRef = useRef<Function0<Promise<Optional<string>>>>();
    const [context, setContext, ContextProvider] =
        useOrderedWizardContextProvider(
            () =>
                new OrderedWizardContext(
                    nextEffectHandlerRef,
                    setError,
                    () => setItemLoaded(true),
                    setItemValid));

    const [executing, setExecuting] = useState(false);
    const [finished, setFinished] = useState(false);

    useEffect(
        () => {
            const currentItemMessage = itemElements[itemIndex].props.errorMessage;
            if (_.isNil(currentItemMessage)) {
                return;
            }

            setError(currentItemMessage);
        },
        [itemIndex, itemElements]);

    async function next() {
        setError(undefined);

        if (!_.isNil(nextEffectHandlerRef.current)) {
            setExecuting(true);

            try {
                const errorMessage = await nextEffectHandlerRef.current();
                if (!_.isNil(errorMessage)) {
                    setError(errorMessage);
                    return;
                }
            } finally {
                setExecuting(false);
            }
        }

        if (finished ||
            _.isNil(finishItemElement) &&
            itemIndex + 1 === itemElements.length) {
            onClose();
            return;
        }

        nextEffectHandlerRef.current = undefined;
        if (itemIndex + 1 === itemElements.length) {
            setFinished(true);
        } else {
            moveStep(1);
        }
    }

    function moveStep(direction: 1 | -1) {
        nextEffectHandlerRef.current = undefined;
        setItemIndex(itemIndex + direction);
        setItemLoaded(!itemElements[itemIndex + direction].props.deferredLoading);
        setItemValid(true);
        setError(undefined);
    }

    useChangeEffect(
        () => {
            setContext(
                context => ({
                    ...context,
                    executing
                }));
        },
        [executing]);

    const localization =
        useLocalization(
            "infrastructure.orderedWizard",
            () => ({
                actions: {
                    back: "Back",
                    close: "Done",
                    finish: "Finish",
                    next: "Next"
                }
            }));

    const backButtonDisabled = !itemLoaded || executing;
    const itemCount = _.size(itemElements);

    const theme = useTheme();
    return (
        <Stack sx={{ height: "100%" }}>
            <Stack
                direction="row"
                sx={{
                    height: "100%",
                    overflow: "hidden"
                }}>
                {itemCount > 1 &&
                    <Stack
                        spacing={1}
                        sx={{
                            height: "100%",
                            width: theme.spacing(32)
                        }}>
                        {_.map(
                            itemElements,
                            (itemElement, itemElementIndex) =>
                                <Item
                                    error={itemElement.props.error}
                                    index={itemElementIndex + 1}
                                    key={itemElementIndex}
                                    selected={itemIndex === itemElementIndex}
                                    title={itemElement.props.title}/>)}
                    </Stack>}
                <Stack
                    sx={{
                        borderLeft: theme.border.primary,
                        flex: 1,
                        height: "100%"
                    }}>
                    <Stack
                        sx={{
                            flex: 1,
                            overflow: "hidden auto",
                            padding:
                                theme.spacing(
                                    1,
                                    itemCount > 1
                                        ? 10
                                        : 3),
                            ...(finished && {
                                alignItems: "center",
                                justifyContent: "center"
                            })
                        }}>
                        <ContextProvider>
                            <Loading key={itemIndex}>
                                {finished
                                    ? finishItemElement
                                    : <Stack sx={{ height: "calc(100% - 16px)" }}>
                                        {!_.isNil(itemElements[itemIndex].props.subtitle) &&
                                            <Typography sx={{ marginTop: theme.spacing(3) }}>
                                                {itemElements[itemIndex].props.subtitle}
                                            </Typography>}
                                        <Stack
                                            spacing={1}
                                            sx={{
                                                height: "calc(100% - 16px)",
                                                marginTop: theme.spacing(3)
                                            }}>
                                            {itemElements[itemIndex].props.children}
                                        </Stack>
                                    </Stack>}
                            </Loading>
                        </ContextProvider>
                    </Stack>
                </Stack>
                {!_.isNil(context.sideElement) && (
                    <Box
                        sx={{
                            borderLeft: theme.border.primary,
                            height: "100%",
                            overflow: "hidden auto",
                            padding: theme.spacing(2)
                        }}>
                        {context.sideElement}
                    </Box>)}
            </Stack>
            <Stack
                alignItems="center"
                direction="row"
                spacing={2}
                sx={{
                    borderTop: theme.border.primary,
                    justifyContent: "space-between",
                    padding: theme.spacing(3)
                }}>
                <Box>
                    {!_.isEmpty(error) &&
                        <Message
                            level="error"
                            title={error}/>}
                </Box>
                <Stack
                    alignItems="center"
                    direction="row"
                    justifyContent="end"
                    spacing={1.5}>
                    {executing && (
                        <CircularProgress
                            size={theme.spacing(3)}
                            variant="indeterminate"/>)}
                    {!finished && itemIndex !== 0 &&
                        <Button
                            disabled={backButtonDisabled}
                            startIcon={<BackIcon sx={{ fontSize: "14px" }}/>}
                            sx={{
                                "& .MuiButton-startIcon > :nth-of-type(1)": {
                                    fontSize: "14px"
                                }
                            }}
                            variant={
                                backButtonDisabled
                                    ? "contained"
                                    : "outlined"}
                            onClick={() => moveStep(-1)}>
                            {localization.actions.back()}
                        </Button>}
                    {finished
                        ? <Button
                            disabled={!itemLoaded || executing}
                            onClick={() => next()}>
                            {localization.actions.close()}
                        </Button>
                        : <Button
                            disabled={!itemLoaded || executing || !itemValid}
                            endIcon={
                                itemIndex < itemElements.length - 1 &&
                                <NextIcon sx={{ fontSize: "14px" }}/>}
                            sx={{
                                "& .MuiButton-endIcon > :nth-of-type(1)": {
                                    fontSize: "14px"
                                }
                            }}
                            onClick={() => next()}>
                            {itemIndex < itemElements.length - 1
                                ? localization.actions.next()
                                : finishItemButtonTitle ?? localization.actions.finish()}
                        </Button>}
                </Stack>
            </Stack>
        </Stack>);
}

type OrderedWizardItemProps = {
    children: ReactNode;
    deferredLoading?: boolean;
    error?: boolean;
    errorMessage?: string;
    subtitle?: string;
    title: string;
};

export function OrderedWizardItem(props: OrderedWizardItemProps) {
    defined(props);
    return <Fragment/>;
}