import { Loading, map, useLocalization, VerticalFillGrid } from "@infrastructure";
import { Box, Tab, Tabs } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { useScopeNavigationViewContext, useTheme } from "../../../../../../common";
import { ConfigurationView } from "../../utilities";
import { ManagedDatabases, ObjectStores } from "./components";
import { DataAnalysisView } from "./DataAnalysisView";

export function DataAnalysis() {
    const dataAnalysisViews =
        useMemo(
            () =>
                [
                    DataAnalysisView.ObjectStores,
                    DataAnalysisView.ManagedDatabases
                ],
            []);
    const { useViewRoute } = useScopeNavigationViewContext();
    const [view, setView] = useViewRoute(`${ConfigurationView.DataAnalysis}/{view}`, dataAnalysisViews);

    const localization =
        useLocalization(
            "views.customer.configuration.dataAnalysis",
            () => ({
                tabs: {
                    dataAnalysisView: {
                        [DataAnalysisView.ManagedDatabases]: "Managed Database",
                        [DataAnalysisView.ObjectStores]: "Object Storage (Buckets)"
                    }
                }
            }));

    const theme = useTheme();
    return (
        <VerticalFillGrid>
            <Tabs
                sx={{
                    border: "unset",
                    padding: theme.spacing(0, 2)
                }}
                value={view}
                variant="scrollable"
                onChange={(_event, view) => setView(view as DataAnalysisView)}>
                {_.map(
                    dataAnalysisViews,
                    dataAnalysisView =>
                        <Tab
                            id={dataAnalysisView}
                            key={dataAnalysisView}
                            label={localization.tabs.dataAnalysisView[dataAnalysisView]()}
                            sx={{
                                marginRight: theme.spacing(3),
                                padding: 0
                            }}
                            value={dataAnalysisView}/>)}
            </Tabs>
            <Box
                sx={{
                    height: "100%",
                    width: "100%"
                }}>
                <Loading>
                    {map(
                        view,
                        {
                            [DataAnalysisView.ObjectStores]: () => <ObjectStores/>,
                            [DataAnalysisView.ManagedDatabases]: () => <ManagedDatabases/>
                        })}
                </Loading>
            </Box>
        </VerticalFillGrid>);
}