import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, VerticalTopItemsInfoItem } from "../../../../../../../../../../common";
import { EntitiesInfoItem } from "../../../../components";

export function useAwsCommonKubernetesResourceInfoItemElements(resourceModel: Contract.AwsResourceModel) {
    const resource = resourceModel.entity as Contract.AwsKubernetesResourceBase;
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsCommonKubernetesResourceInfoItemElements",
            () => ({
                annotations: "Annotations",
                cluster: "Cluster",
                tags: "Kubernetes Labels"
            }));

    return {
        annotationsInfoItemElement:
            <VerticalTopItemsInfoItem
                items={
                    _(resource.data.annotationKeyToValueMap).
                        map((annotationValue, annotationKey) => `${annotationKey}: ${annotationValue}`).
                        value()}
                key="annotations"
                title={localization.annotations()}/>,
        clusterInfoItemElement:
            <EntitiesInfoItem
                entityIdsOrModels={resource.clusterId}
                entityTypeName={Contract.TypeNames.IKubernetesCluster}
                key="clusterId"
                location="all"
                title={localization.cluster()}/>
    };
}