import { Step, Steps, useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition, useGetAwsElastiCacheReplicationGroupRiskContext } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";

export function useAwsElastiCacheReplicationGroupDefaultSubnetGroupRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsElastiCacheReplicationGroupDefaultSubnetGroupRisk;
    const replicationGroupModel = entityModelStore.useGet(risk.entityId) as Contract.AwsElastiCacheReplicationGroupModel;

    const getAwsElastiCacheReplicationGroupRiskContext = useGetAwsElastiCacheReplicationGroupRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsElastiCacheReplicationGroupDefaultSubnetGroupRiskDefinition",
            () => ({
                description: "{{replicationGroup}} is using the default subnet group",
                sections: {
                    resolution: {
                        step1: "Updating an existing cluster to use a different subnet group is not supported. You must create a new cluster using the desired subnet group. Ensure you have created a custom subnet group with the desired subnets before proceeding",
                        step2: {
                            step1: "Take a snapshot of the existing cluster to preserve your data by clicking **Backup**.",
                            step2: "Fill in the backup details and click **Create backup**",
                            title: "Backup the existing cluster: Take a snapshot of the existing cluster to preserve your data:"
                        },
                        step3: {
                            step1: "Go to the **Backups** page and find the snapshot you just created",
                            step2: "Select the backup and click **Restore**",
                            step3: "Ensure all details match your existing cluster to avoid disruptions or downtime",
                            step4: "Expand the **Default settings** section and select **Customize default settings**",
                            step5: "Choose the desired VPC and configure security settings by selecting **Customize Your Security Settings**",
                            step6: "Verify that the new subnet group and security group configurations allow proper access",
                            step7: "Click **Create**",
                            title: "Create a New Cluster:"
                        },
                        step4: "Before migrating to the new cluster and decommissioning the old insecure cluster, ensure the new subnet group has the appropriate security group configurations to allow access. Additionally, verify that the cluster is properly configured and thoroughly tested to avoid disruptions during the transition.",
                        step5: "Update your application configurations to point to the new cluster's endpoint",
                        step6: "Once the new cluster is operational and the application is pointing to it, delete the old cluster"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            replicationGroup:
                <Entity
                    entityIdOrModel={replicationGroupModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.ElastiCache,
                AwsConsoleUrlBuilder.getElastiCacheReplicationGroupUrl(replicationGroupModel.entity as Contract.AwsElastiCacheReplicationGroup)),
            localization.sections.resolution.step1(),
            new Step(
                localization.sections.resolution.step2.title(),
                {
                    contentElement:
                        <Steps variant="letters">
                            {localization.sections.resolution.step2.step1()}
                            {localization.sections.resolution.step2.step2()}
                        </Steps>
                }),
            new Step(
                localization.sections.resolution.step3.title(),
                {
                    contentElement:
                        <Steps variant="letters">
                            {localization.sections.resolution.step3.step1()}
                            {localization.sections.resolution.step3.step2()}
                            {localization.sections.resolution.step3.step3()}
                            {localization.sections.resolution.step3.step4()}
                            {localization.sections.resolution.step3.step5()}
                            {localization.sections.resolution.step3.step6()}
                            {localization.sections.resolution.step3.step7()}
                        </Steps>
                }),
            localization.sections.resolution.step4(),
            localization.sections.resolution.step5(),
            localization.sections.resolution.step6()
        ],
        riskModel,
        () => {
            const replicationGroupContextItems = getAwsElastiCacheReplicationGroupRiskContext(replicationGroupModel);
            return [
                replicationGroupContextItems.generalInformation,
                replicationGroupContextItems.sensitive,
                replicationGroupContextItems.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}