import { useLocalization } from "@infrastructure";
import { Button, Stack, Typography, useTheme } from "@mui/material";
import _ from "lodash";
import React, { ReactElement, useState } from "react";
import { Dialog } from "./Dialog";

export type ConfirmDialogProps = {
    cancelTitle?: false | string;
    descriptionElement?: ReactElement;
    message?: string;
    messageElement?: ReactElement;
    okTitles?: string[];
    onClose: (result: string | boolean) => void;
};

export function ConfirmDialog({ cancelTitle, descriptionElement, message, messageElement, okTitles, onClose }: ConfirmDialogProps) {
    const [executing, setExecuting] = useState(false);

    const localization =
        useLocalization(
            "infrastructure.confirmDialog",
            () => ({
                cancel: "No",
                ok: "Yes"
            }));

    const theme = useTheme();
    return (
        <Dialog
            variant="viewer"
            onClose={() => onClose(false)}>
            <Stack
                spacing={7}
                sx={{
                    minHeight: theme.spacing(30),
                    padding: theme.spacing(6)
                }}>
                <Stack
                    spacing={2}
                    sx={{ flex: 1 }}>
                    {messageElement ??
                        <Typography
                            align="center"
                            sx={{ wordBreak: "break-word" }}
                            variant="h1">
                            {message}
                        </Typography>}
                    {descriptionElement}
                </Stack>
                <Stack
                    alignItems="center"
                    direction="row"
                    justifyContent="center"
                    spacing={2}>
                    {cancelTitle !== false && (
                        <Button
                            variant="outlined"
                            onClick={
                                event => {
                                    setExecuting(true);
                                    onClose(false);
                                    event.stopPropagation();
                                }}>
                            {cancelTitle ?? localization.cancel()}
                        </Button>)}
                    {_.map(
                        okTitles ?? [undefined],
                        (okTitle, okTitleIndex) =>
                            <Button
                                disabled={executing}
                                key={okTitleIndex}
                                onClick={
                                    event => {
                                        setExecuting(true);
                                        onClose(okTitle ?? true);
                                        event.stopPropagation();
                                    }}>
                                {okTitle ?? localization.ok()}
                            </Button>)}
                </Stack>
            </Stack>
        </Dialog>);
}