import { DialogMenuItem, Link, TimeFormatter, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../..";
import { useGetDataAnalysisResourcePriorityScanRequestDialogMenuItem } from "..";
import { Contract, InfoItem } from "../../../../../../../../../../common";
import { AzureStorageStorageAccountBlobContainerPublicAccess } from "../../../../../../../../../../tenants";
import { EntitiesInfoItem, Info } from "../../../../components";
import { AzureResourceRoleAssignmentResources } from "../../components";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";
import { useDefaultDataAnalysisResourceInfoItemElements } from "../useDefaultDataAnalysisResourceInfoItemElements";
import { useAzureDefaultResourceInfoItemElements } from "./useAzureDefaultResourceInfoItemElements";

export function useAzureStorageStorageAccountBlobContainerDefinition(resourceGroupResourceModel: Contract.AzureResourceGroupResourceModel, options?: DefinitionOptions) {
    const blobContainer = resourceGroupResourceModel.entity as Contract.AzureStorageStorageAccountBlobContainer;
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceGroupResourceModel);
    const defaultDataAnalysisResourceInfoItemElements = useDefaultDataAnalysisResourceInfoItemElements(resourceGroupResourceModel);
    const defaultResourceInfoItemElements = useAzureDefaultResourceInfoItemElements(resourceGroupResourceModel);
    const getDataAnalysisResourcePriorityScanRequestDialogMenuItem =
        useGetDataAnalysisResourcePriorityScanRequestDialogMenuItem(
            resourceGroupResourceModel as Contract.AzureStorageStorageAccountBlobContainerModel,
            dataAnalysis => dataAnalysis.objectStoreScanEnabled);

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.azure.useAzureStorageStorageAccountBlobContainerDefinition",
            () => ({
                info: {
                    items: {
                        defaultBlobEncryptionScope: "Encryption Scope",
                        effectivePublicAccess: "Access Level",
                        leaseState: "Lease State",
                        storageAccountId: "Storage Account",
                        updateTime: "Update Time",
                        url: "URL"
                    }
                },
                tabs: {
                    resourceRoleAssignments: "Resource Role Assignments"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <AzureResourceRoleAssignmentResources
                    csvExportFilePrefix={localization.tabs.resourceRoleAssignments()}
                    scopeResourceModel={resourceGroupResourceModel}/>,
                localization.tabs.resourceRoleAssignments(),
                "resourceRoleAssignments")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <EntitiesInfoItem
                        entityIdsOrModels={blobContainer.storageAccountId}
                        entityTypeName={Contract.TypeNames.AzureStorageStorageAccount}
                        key="storageAccountId"
                        location="all"
                        title={localization.info.items.storageAccountId()}/>,
                    <InfoItem
                        key="effectivePublicAccess"
                        title={localization.info.items.effectivePublicAccess()}
                        value={
                            <AzureStorageStorageAccountBlobContainerPublicAccess publicAccess={blobContainer.effectivePublicAccess}/>}/>,
                    <InfoItem
                        key="url"
                        location="all"
                        title={localization.info.items.url()}
                        value={
                            <Link
                                urlOrGetUrl={`${blobContainer.url}?restype=container&comp=list`}
                                variant="external">
                                {blobContainer.url}
                            </Link>}/>,
                    <InfoItem
                        key="updateTime"
                        title={localization.info.items.updateTime()}
                        value={TimeFormatter.profileFormatDateTime(blobContainer.updateTime)}/>,
                    <InfoItem
                        key="leaseState"
                        title={localization.info.items.leaseState()}
                        value={blobContainer.leaseState}/>,
                    <InfoItem
                        key="defaultBlobEncryptionScope"
                        title={localization.info.items.defaultBlobEncryptionScope()}
                        value={blobContainer.defaultBlobEncryptionScope}/>,
                    ...defaultDataAnalysisResourceInfoItemElements
                ]}
                options={options?.infoOptions}>
            </Info>,
        topbarMenuItems: _.filter([getDataAnalysisResourcePriorityScanRequestDialogMenuItem()]) as DialogMenuItem[]
    });
}