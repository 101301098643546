import { useSortable } from "@dnd-kit/sortable";
import { CSS as DndCSS } from "@dnd-kit/utilities";
import { ActionMenuItem, ColumnHeader, DataTableColumnProps, FilterIcon, GrabIcon, Menu, MenuItem, SeparatorMenuItem, Sx, TableColumnHeaderSortOptions, useChangeEffect, useDataTableActionsContext, useLocalization, useSetDataTableColumnContext, useSortMenuItems } from "@infrastructure";
import { TableCell, useTheme } from "@mui/material";
import { Box, Stack } from "@mui/system";
import _ from "lodash";
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { RemoveColumnIcon } from "./icons";

const DataTableColumnHeaderMemo = memo(DataTableColumnHeader);
export { DataTableColumnHeaderMemo as DataTableColumnHeader };

export type DataTableFilterItem = {
    active?: boolean;
    default?: boolean;
    id: string;
    name?: string;
    visible?: boolean;
};

export type DataTableColumnHeaderFilterOptions = {
    enabled: boolean;
    items?: DataTableFilterItem[];
    onClick?: (filterItem: DataTableFilterItem) => void;
};

export type DataTableColumnHeaderProps = {
    columnProps: DataTableColumnProps;
    filterOptions: DataTableColumnHeaderFilterOptions;
    itemSelectionEnabled: boolean;
    onRemoveVisibleColumn?: (id: string) => void;
    sortOptions: TableColumnHeaderSortOptions;
    sticky?: boolean;
    tableOptions: DataTableColumnHeaderTableOptions;
    visible?: boolean;
};

type DataTableColumnHeaderTableOptions = {
    height?: number;
    orderable: boolean;
    resizable: boolean;
    selectable?: boolean;
    selection?: boolean;
};

export enum FilterMenuTitleType {
    WithName = "WithName",
    WithoutName = "WithoutName"
}

function DataTableColumnHeader({ columnProps, filterOptions, itemSelectionEnabled, onRemoveVisibleColumn, sortOptions, sticky, tableOptions, visible = true }: DataTableColumnHeaderProps) {
    const sortMenuItems = useSortMenuItems(sortOptions);

    const localization =
        useLocalization(
            "infrastructure.dataTable.dataTableColumnHeader",
            () => ({
                filters: {
                    add: {
                        [FilterMenuTitleType.WithName]: "Add {{filterName}} Filter",
                        [FilterMenuTitleType.WithoutName]: "Add Filter"
                    }
                },
                picker: "Remove Column"
            }));

    const selectorEnabled = tableOptions.selectable && !columnProps.selectorOptions?.disabled;
    const sortEnabled =
        !!sortOptions.enabled &&
        !_.isNil(columnProps.title) &&
        (columnProps.sortOptions?.enabled ?? true);
    const menuEnabled = filterOptions.enabled || sortEnabled || selectorEnabled;
    const theme = useTheme();
    const menuItems =
        useMemo(
            (): MenuItem[] =>
                _<MenuItem>([]).
                    concatIf(
                        filterOptions.enabled,
                        _.map(
                            filterOptions.items,
                            filterItem => {
                                const filterMenuTitleType =
                                    filterItem.name
                                        ? FilterMenuTitleType.WithName
                                        : FilterMenuTitleType.WithoutName;
                                return new ActionMenuItem(
                                    () => filterOptions.onClick!(filterItem),
                                    localization.filters.add[filterMenuTitleType]({ filterName: filterItem.name }),
                                    { icon: <FilterIcon/> });
                            })).
                    concatIf(
                        !columnProps.selectorOptions?.default && !columnProps.selectorOptions?.disabled && tableOptions.selectable === true,
                        new ActionMenuItem(
                            () => onRemoveVisibleColumn!(columnProps.id),
                            localization.picker(),
                            { icon: <RemoveColumnIcon/> })).
                    concatIf(
                        sortEnabled,
                        [
                            filterOptions.enabled
                                ? new SeparatorMenuItem()
                                : undefined,
                            ...sortMenuItems
                        ]).
                    value(),
            [filterOptions, localization, sortEnabled, sortMenuItems]);

    const filterActive =
        useMemo(
            () => _.some(filterOptions.items, item => item.active),
            [filterOptions.items]);
    const { actions } = useDataTableActionsContext();
    const setDataTableColumnContextContext = useSetDataTableColumnContext();
    const cellElementRef = useRef<HTMLElement>(null);
    const resizerElementRef = useRef<HTMLElement>(null);
    const resizerElementCursorDiffRef = useRef(0);
    const [hover, setHover] = useState(false);
    const [itemsLoaded, setItemsLoaded] = useState(false);
    const [onResizing, setOnResizing] = useState(false);
    const [resizingHover, setResizingHover] = useState(false);
    const [width, setWidth] = useState<number>();
    const minWidth = columnProps.cellMinWidth ?? COLUMN_MIN_WIDTH;
    useEffect(
        () => {
            if (!tableOptions.resizable || _.isNil(cellElementRef.current)) {
                return;
            }

            const unregister =
                actions.registerItemsLoaded(
                    loaded => {
                        setItemsLoaded(loaded);
                        if (loaded && _.isNil(columnProps.titleElement)) {
                            const width =
                                Math.max(
                                    cellElementRef.current!.getBoundingClientRect()?.width,
                                    minWidth);
                            setWidth(width);

                            if (sticky) {
                                setDataTableColumnContextContext(
                                    context => ({
                                        ...context,
                                        stickyColumnWidth: width
                                    }));
                            }

                        } else {
                            setWidth(undefined);
                        }
                    });

            return () => unregister();
        },
        []);

    const resizable = columnProps.resizable ?? tableOptions.resizable;
    const orderable = tableOptions.orderable && columnProps.orderable !== false && !sticky;

    const { attributes: orderableAttributes, listeners: orderableListeners, setNodeRef: orderableSetNodeRef, transform: orderableTransform, transition: orderableTransition } =
        useSortable({
            disabled: !tableOptions.orderable || columnProps.orderable === false,
            id: columnProps.id
        });
    const cellRefSetter =
        useCallback(
            (ref: HTMLElement) => {
                (cellElementRef as any).current = ref;
                orderableSetNodeRef(ref);
            },
            [orderableSetNodeRef]);
    const columnDragged = orderableAttributes["aria-pressed"];
    useChangeEffect(
        () => {
            if (columnDragged &&
                (hover || resizingHover)) {
                setHover(false);
                setResizingHover(false);
            }
        },
        [columnDragged, hover]);

    return (
        <TableCell
            data-on-resizing={onResizing}
            ref={cellRefSetter}
            style={{ transform: DndCSS.Translate.toString(orderableTransform) }}
            sx={
                Sx.combine(
                    {
                        "&:first-of-type": {
                            paddingLeft:
                                itemSelectionEnabled
                                    ? theme.spacing(1)
                                    : theme.spacing(2)
                        },
                        "&:first-of-type:hover, &[data-on-resizing=true], [data-on-resizing=false] + &:hover": {
                            background:
                                _.isEmpty(menuItems)
                                    ? undefined
                                    : theme.palette.action.hover
                        },
                        "body:not(.col-drag, .col-resize) &": {
                            cursor:
                                _.isEmpty(menuItems)
                                    ? undefined
                                    : "pointer"
                        },
                        boxSizing: "border-box",
                        display:
                            visible
                                ? undefined
                                : "none",
                        padding: 0,
                        transition: orderableTransition,
                        userSelect: "none",
                        ...(sticky && {
                            background: theme.palette.background.paper,
                            left:
                                tableOptions.selection
                                    ? 58
                                    : 0,
                            position: "sticky",
                            zIndex: 9
                        })
                    },
                    columnProps.headerSx)}
            width={width}
            onMouseEnter={() => resizable && setHover(true)}
            onMouseLeave={
                () => {
                    if (!resizable || onResizing) {
                        return;
                    }

                    setHover(false);
                }}
            {...orderable
                ? { ...orderableAttributes }
                : undefined}>
            <Stack
                alignItems="center"
                direction="row"
                sx={{ height: "100%" }}>
                <Box
                    sx={{
                        flex: 1,
                        overflow: "hidden"
                    }}
                    {...orderable
                        ? { ...orderableListeners }
                        : undefined}>
                    {!columnDragged &&
                        <Box>
                            <Menu
                                disabled={!menuEnabled}
                                itemsOrGetItems={menuItems}
                                itemSx={{
                                    minWidth:
                                        resizable
                                            ? undefined
                                            : minWidth
                                }}
                                sx={{
                                    cursor: undefined,
                                    overflow: "hidden"
                                }}
                                variant="bottomLeft"
                                onClick={
                                    () => {
                                        if (!resizable) {
                                            return;
                                        }

                                        setHover(false);
                                        setResizingHover(false);
                                    }}>
                                <ColumnHeader
                                    containerSx={{ height: 40 }}
                                    filterActive={filterActive}
                                    message={columnProps.message}
                                    messageLevel={columnProps.messageLevel}
                                    sortDirection={sortOptions.direction}
                                    title={columnProps.title}
                                    titleElement={columnProps.titleElement}
                                    tooltip={columnProps.tooltip}/>
                            </Menu>
                        </Box>}
                </Box>
                {orderable && hover && !onResizing && itemsLoaded &&
                    <GrabIcon
                        {...orderableListeners}
                        sx={{
                            color: theme.palette.borders.hoverFocus,
                            cursor: "grab",
                            fontSize: "16px",
                            paddingRight: theme.spacing(1)
                        }}/>}
                {resizable && !columnDragged && itemsLoaded &&
                    <Box
                        ref={resizerElementRef}
                        sx={{
                            cursor: "ew-resize",
                            paddingLeft: theme.spacing(1),
                            position: "absolute",
                            right: -1,
                            top: 0,
                            width: theme.spacing(1.25)
                        }}
                        onMouseDown={
                            event => {
                                const resizerElementBoundingClientRect = resizerElementRef.current?.getBoundingClientRect();
                                const handlerToMouseDiff =
                                    !_.isNil(resizerElementBoundingClientRect?.left)
                                        ? event.clientX - resizerElementBoundingClientRect!.left
                                        : 0;
                                resizerElementCursorDiffRef.current = handlerToMouseDiff;

                                const mouseMove =
                                    (event: MouseEvent) => {
                                        const cellElementBoundingClientRect = cellElementRef.current?.getBoundingClientRect();
                                        const cellElementLeftPaddingPropertyValue = window.getComputedStyle(cellElementRef.current!).
                                            getPropertyValue("padding-right");
                                        const cellElementLeftPaddingPropertyValueNumeric = Number(_.replace(cellElementLeftPaddingPropertyValue, /[^0-9.]/g, ""));
                                        const resizerCursorDiff =
                                            !_.isNil(resizerElementBoundingClientRect)
                                                ? resizerElementBoundingClientRect.width - resizerElementCursorDiffRef.current
                                                : 0;
                                        const width = event.clientX - (cellElementBoundingClientRect?.x ?? 0) - cellElementLeftPaddingPropertyValueNumeric + resizerCursorDiff;
                                        if (width >= minWidth) {
                                            setWidth(width);
                                        }

                                        if (sticky) {
                                            setDataTableColumnContextContext(
                                                context => ({
                                                    ...context,
                                                    stickyColumnWidth: width
                                                }));
                                        }

                                        event.preventDefault();
                                    };

                                document.addEventListener("mousemove", mouseMove);
                                document.addEventListener(
                                    "mouseup",
                                    () => {
                                        document.removeEventListener("mousemove", mouseMove);
                                        window.document.body.classList.remove("col-resize");
                                        setHover(false);
                                        setOnResizing(false);
                                        setResizingHover(false);
                                    },
                                    {
                                        once: true
                                    });

                                window.document.body.classList.add("col-resize");
                                setOnResizing(true);
                                event.preventDefault();
                            }}
                        onMouseEnter={() => resizable && setResizingHover(true)}
                        onMouseLeave={() => resizable && setResizingHover(false)}>
                        <Box
                            sx={{
                                background:
                                    onResizing || resizingHover
                                        ? theme.palette.borders.hoverFocus
                                        : undefined,
                                borderRadius: theme.px(1),
                                height:
                                    onResizing || resizingHover
                                        ? tableOptions.height
                                        : theme.spacing(5),
                                width: theme.px(1)
                            }}/>
                    </Box>}
            </Stack>
        </TableCell>);
}

const COLUMN_MIN_WIDTH = 70;