import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useOciKmsVaultKeyStatusTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useOciKmsVaultKeyStatusTranslator",
            () => ({
                [Contract.TypeNames.OciKmsVaultKeyStatus]: {
                    [Contract.OciKmsVaultKeyStatus.BackupInProgress]: "Backup In Progress",
                    [Contract.OciKmsVaultKeyStatus.CancellingDeletion]: "Cancelling Deletion",
                    [Contract.OciKmsVaultKeyStatus.Creating]: "Creating",
                    [Contract.OciKmsVaultKeyStatus.Deleted]: "Deleted",
                    [Contract.OciKmsVaultKeyStatus.Deleting]: "Deleting",
                    [Contract.OciKmsVaultKeyStatus.Disabled]: "Disabled",
                    [Contract.OciKmsVaultKeyStatus.Disabling]: "Disabling",
                    [Contract.OciKmsVaultKeyStatus.Enabled]: "Enabled",
                    [Contract.OciKmsVaultKeyStatus.Enabling]: "Enabling",
                    [Contract.OciKmsVaultKeyStatus.PendingDeletion]: "Pending Deletion",
                    [Contract.OciKmsVaultKeyStatus.Restoring]: "Restoring",
                    [Contract.OciKmsVaultKeyStatus.SchedulingDeletion]: "Scheduling Deletion",
                    [Contract.OciKmsVaultKeyStatus.Updating]: "Updating"
                }
            }));
    return (status: Contract.OciKmsVaultKeyStatus) =>
        localization[Contract.TypeNames.OciKmsVaultKeyStatus][status]();
}