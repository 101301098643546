import { ActionMenuItem, AddIcon, IconText, ItemWithValidation, Menu, useLocalization } from "@infrastructure";
import { Button, Stack } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract } from "../../controllers";
import { useDeliveryTranslator } from "../../hooks";
import { useTheme } from "../../themes";
import { DeliveryIcon } from "../DeliveryIcon";
import { DeliveryListItem } from "./components";

type DeliveriesProps = {
    createItem: (typeName: string) => void;
    deliveries: ItemWithValidation<Contract.DeliveryRequest[]>;
    disabled: boolean;
    onChange: (deliveries: ItemWithValidation<Contract.DeliveryRequest[]>) => void;
    scopeId: string;
    typeNames: Contract.DeliveryDerivedTypeNames[];
};

export function Deliveries({ createItem, deliveries, disabled, onChange, scopeId, typeNames }: DeliveriesProps) {
    const deliveryTranslator = useDeliveryTranslator();
    const menuItems =
        useMemo(
            () =>
                _.map(
                    typeNames,
                    typeName =>
                        new ActionMenuItem(
                            () => createItem(typeName),
                            deliveryTranslator(typeName, "title").text,
                            { icon: <DeliveryIcon typeName={typeName}/> })),
            [typeNames]);

    const localization =
        useLocalization(
            "common.deliveries",
            () => ({
                additional: "Add Delivery Method"
            }));

    const theme = useTheme();
    return (
        <Stack
            spacing={2}
            sx={{ paddingBottom: theme.spacing(2) }}>
            {!_.isEmpty(deliveries) &&
                <Stack>
                    {_.map(
                        deliveries,
                        (deliveryWrapper, index) =>
                            <DeliveryListItem
                                delivery={deliveryWrapper.delivery}
                                disabled={disabled}
                                key={index}
                                scopeId={scopeId}
                                onChange={
                                    updatedDelivery =>
                                        onChange(
                                            _.map(
                                                deliveries,
                                                (delivery, deliveryIndex) =>
                                                    index === deliveryIndex
                                                        ? {
                                                            ...delivery,
                                                            delivery: updatedDelivery
                                                        }
                                                        : delivery))}
                                onDelete={
                                    () =>
                                        onChange(
                                            _.filter(
                                                deliveries,
                                                (_, deliveryIndex) => index !== deliveryIndex))}/>)}
                </Stack>}
            {!_.isEmpty(menuItems) &&
                <Menu
                    disabled={disabled}
                    itemsOrGetItems={menuItems}
                    sx={{ maxWidth: "fit-content" }}
                    variant="bottomCenter">
                    <Button
                        size="small"
                        variant="outlined">
                        <IconText
                            icon={<AddIcon/>}
                            iconSx={{ fontSize: "18px" }}
                            text={localization.additional()}/>
                    </Button>
                </Menu>}
        </Stack>);
}