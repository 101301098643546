import { Tooltip } from "@infrastructure";
import { SxProps } from "@mui/material";
import React, { SyntheticEvent } from "react";
import { Contract } from "../../controllers";
import { useDataCategoryTranslator } from "../../hooks";
import { OutlineLabelButton } from "../OutlineLabelButton";
import { DataCategoryIcon } from "./components";

type DataCategoryProps = {
    active?: boolean;
    category: Contract.DataCategory;
    count?: number;
    countLoading?: boolean;
    disabled?: boolean;
    onClick?: (category: Contract.DataCategory, event: SyntheticEvent<HTMLButtonElement>) => void;
    sx?: SxProps;
};

export function DataCategory({ active, category, count, countLoading, disabled, onClick, sx }: DataCategoryProps) {
    const dataCategoryTranslator = useDataCategoryTranslator();
    const { initials, title } = dataCategoryTranslator(category);
    return (
        <Tooltip titleOrGetTitle={title}>
            <OutlineLabelButton
                active={active}
                className={`dataCategoryButton ${category}`}
                count={count}
                countLoading={countLoading}
                disabled={disabled}
                icon={
                    <DataCategoryIcon
                        category={category}
                        sx={{
                            opacity:
                                disabled && !active
                                    ? 0.3
                                    : 1
                        }}/>}
                label={initials}
                sx={sx}
                onClick={onClick && (event => onClick?.(category, event))}/>
        </Tooltip>);
}