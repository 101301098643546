import { Optional } from "@infrastructure";
import _, { Dictionary } from "lodash";
import { EntityTableDefinition } from "../../..";
import { Contract, ItemSelectionHelper, TimeRangeHelper } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";
import { useCommonContainerImageDefinition } from "../useCommonContainerImageDefinition";
import { useAzureCommonResourceDefinition } from "./useAzureCommonResourceDefinition";

export function useAzureContainerImageDefinition(definitionData: DefinitionData) {
    const commonContainerImageDefinition = useCommonContainerImageDefinition(definitionData);

    const commonResourceDefinition = useAzureCommonResourceDefinition(definitionData);

    return new EntityTableDefinition(
        _.filter(
            [
                commonResourceDefinition.columns.tenantColumn,
                commonContainerImageDefinition.columns.repositoryColumn,
                commonContainerImageDefinition.columns.tagsColumn,
                commonContainerImageDefinition.columns.digestColumn,
                commonContainerImageDefinition.columns.creationTimeColumn,
                commonContainerImageDefinition.columns.vulnerabilitiesColumn,
                commonContainerImageDefinition.columns.scanTimeColumn,
                commonContainerImageDefinition.columns.workloadClusterResourcesColumn,
                commonContainerImageDefinition.columns.virtualMachinesColumn,
                commonContainerImageDefinition.columns.kubernetesWorkloadResourcesColumn,
                commonResourceDefinition.columns.attributesColumn,
                commonResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
                commonResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
                commonResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn,
                commonContainerImageDefinition.columns.usageColumn
            ]),
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAzureContainerImageRequest(
                new Contract.EntityControllerGetEntityModelPageAzureContainerImageRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ContainerImageCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ContainerImageTags]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ContainerImageDigest]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ContainerImageKubernetesWorkloadResources]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ContainerImageRepository]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ContainerImageScanTime]),
                    filterMap[Contract.EntityModelProperty.ContainerImageWorkloadExists] as Optional<boolean>,
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ContainerImageVirtualMachines]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ContainerImageVulnerabilities]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.ContainerImageVulnerabilitySeverities]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ContainerImageWorkloadClusterResources])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags]))),
        {
            defaultSortColumnId: Contract.EntityModelProperty.ContainerImageVulnerabilities,
            initialFilterMap: {
                [Contract.EntityModelProperty.ContainerImageWorkloadExists]: true
            }
        });
}