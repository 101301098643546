import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import { Link, setUrlRoute, Steps, Tooltip, useLocalization } from "@infrastructure";
import { Contract, CustomerConsoleAppUrlHelper, ScopeHelper, UserHelper, useTheme } from "../../../../../common";

export function Onboarding() {
    const localization =
        useLocalization(
            "views.customer.code.onboarding",
            () => ({
                button: {
                    text: "Configure Integration",
                    tooltip: "Missing permissions. Only users with the Administrator role can configure integrations"
                },
                description: {
                    action: "To start, integrate Tenable Cloud Security with your **Code Repositories** and/or **CI/CD Pipelines**. {{documentationLink}}",
                    documentationLink: "Learn more.",
                    helpText: "If you still see this page after integrating your code, initiate a pipeline scan and/or wait a few minutes for data to be populated.",
                    scan: "**Scan** your infrastructure-as-code (IaC) and address configuration issues before your cloud resources are deployed.",
                    sync: "**Sync** the code associated with your cloud resources to trace those resources back to their origins (Cloud to Code).",
                    title: "Use Tenable Cloud Security IaC Security to:"
                },
                title: "Infrastructure-as-Code (IaC) Security"
            }));

    const theme = useTheme();
    return (
        <Stack
            spacing={2}
            sx={{
                height: "100%",
                maxWidth: theme.spacing(100),
                padding: theme.spacing(10),
                position: "relative"
            }}>
            <Typography
                sx={{
                    fontSize: "30px",
                    fontWeight: 700
                }}>
                {localization.title()}
            </Typography>
            <Stack
                spacing={1}>
                <Typography
                    sx={{
                        fontSize: "14px",
                        fontWeight: 600
                    }}>
                    {localization.description.title()}
                </Typography>
                <Steps variant="bullets">
                    <Typography
                        sx={{
                            fontSize: "13px",
                            fontWeight: 400,
                            paddingBottom: theme.spacing(1)
                        }}>
                        {localization.description.scan()}
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: "13px",
                            fontWeight: 400
                        }}>
                        {localization.description.sync()}
                    </Typography>
                </Steps>
            </Stack>
            <Typography
                sx={{
                    fontSize: "13px",
                    fontWeight: 400,
                    paddingBottom: theme.spacing(1.5)
                }}>
                {localization.description.action({
                    documentationLink:
                        <Link
                            urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsIacSecurityRelativeUrl()}
                            variant="external">
                            {localization.description.documentationLink()}
                        </Link>
                })}
            </Typography>
            <Tooltip
                titleOrGetTitle={
                    UserHelper.hasScopePermissions(ScopeHelper.customerId, Contract.IdentityPermission.SecurityAdministrationRead)
                        ? undefined
                        : localization.button.tooltip()}>
                <Box sx={{ width: "fit-content" }}>
                    <Button
                        disabled={!UserHelper.hasScopePermissions(ScopeHelper.customerId, Contract.IdentityPermission.SecurityAdministrationRead)}
                        onClick={() => setUrlRoute(CustomerConsoleAppUrlHelper.getConfigurationIntegrationsRelativeUrl())}>
                        {localization.button.text()}
                    </Button>
                </Box>
            </Tooltip>
            <Typography sx={{ fontSize: "12px" }}>
                {localization.description.helpText()}
            </Typography>
        </Stack>);
}