import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function ThemesIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                clipRule="evenodd"
                d="M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                fillRule="evenodd"/>
            <path d="M12 6C12.7879 6 13.5681 6.15519 14.2961 6.45672C15.0241 6.75825 15.6855 7.20021 16.2426 7.75736C16.7998 8.31451 17.2417 8.97595 17.5433 9.7039C17.8448 10.4319 18 11.2121 18 12C18 12.7879 17.8448 13.5681 17.5433 14.2961C17.2417 15.0241 16.7998 15.6855 16.2426 16.2426C15.6855 16.7998 15.0241 17.2417 14.2961 17.5433C13.5681 17.8448 12.7879 18 12 18L12 12L12 6Z"/>
        </SvgIcon>);
}