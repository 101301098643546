import { Dom, Shadows, Sx, Tooltip, useWindowEventEffect } from "@infrastructure";
import { Avatar, Box, Grid2, SxProps } from "@mui/material";
import _ from "lodash";
import React, { useRef, useState } from "react";
import { Contract } from "../../../../../../../../../../../controllers";
import { useRiskPolicyCategoryTranslator } from "../../../../../../../../../../../hooks";
import { RiskPolicyCategoryIcon } from "../../../../../../../../../../../icons";
import { useTheme } from "../../../../../../../../../../../themes";

type RiskPolicyCategoriesProps = {
    orderedRiskPolicyCategories: Contract.RiskPolicyCategory[];
};

export function RiskPolicyCategories({ orderedRiskPolicyCategories }: RiskPolicyCategoriesProps) {
    const containerElementRef = useRef<HTMLDivElement>(null);
    const popoverElementRef = useRef<HTMLDivElement>(null);
    const [hover, setHover] = useState(false);

    function closePopover(event: MouseEvent) {
        if (
            !Dom.isChildrenOf(
                event.target as Element,
                parentElement =>
                    parentElement === containerElementRef.current ||
                    parentElement === popoverElementRef.current)) {
            setHover(false);
        }
    }

    useWindowEventEffect(
        "mousemove",
        closePopover);

    useWindowEventEffect(
        "wheel",
        closePopover);

    const theme = useTheme();
    return (
        <Box
            ref={containerElementRef}
            sx={{ position: "relative" }}
            onMouseEnter={() => setHover(true)}>
            <Grid2
                container={true}
                spacing={0}
                sx={{ width: theme.spacing(6) }}>
                {_.map(
                    orderedRiskPolicyCategories,
                    (riskPolicyCategory, riskPolicyCategoryIndex) =>
                        <RiskPolicyCategoryAvatar
                            key={`${riskPolicyCategory}-${riskPolicyCategoryIndex}`}
                            riskPolicyCategory={riskPolicyCategory}
                            sx={{
                                left: theme.px(riskPolicyCategoryIndex * 6),
                                position: "absolute",
                                top: theme.spacing(-2),
                                zIndex: 100 - riskPolicyCategoryIndex
                            }}/>)}
            </Grid2>
            {hover &&
                <Grid2
                    container={true}
                    ref={popoverElementRef}
                    spacing={1}
                    sx={{
                        backgroundColor: theme.palette.background.paper,
                        border: theme.border.primary,
                        borderRadius: theme.px(100),
                        boxShadow: theme.shadows[Shadows.Avatar],
                        left: theme.px(-1),
                        position: "absolute",
                        top: theme.px(-17),
                        width: "auto",
                        zIndex: 101
                    }}
                    wrap="nowrap">
                    {_.map(
                        orderedRiskPolicyCategories,
                        (riskPolicyCategory, index) =>
                            <Grid2 key={`${riskPolicyCategory}-${index}`}>
                                <RiskPolicyCategoryAvatar riskPolicyCategory={riskPolicyCategory}/>
                            </Grid2>)}
                </Grid2>}
        </Box>);
}

type RiskPolicyCategoryAvatarProps = {
    riskPolicyCategory: Contract.RiskPolicyCategory;
    sx?: SxProps;
};

function RiskPolicyCategoryAvatar({ riskPolicyCategory, sx }: RiskPolicyCategoryAvatarProps) {
    const riskPolicyCategoryTranslator = useRiskPolicyCategoryTranslator();
    const theme = useTheme();
    return (
        <Tooltip titleOrGetTitle={riskPolicyCategoryTranslator(riskPolicyCategory)}>
            <Avatar
                sx={
                    Sx.combine(
                        {
                            backgroundColor: theme.palette.background.alternate,
                            border: theme.border.alternate,
                            boxShadow: theme.shadows[10],
                            height: "30px",
                            width: "30px"
                        },
                        sx)}>
                <RiskPolicyCategoryIcon
                    riskPolicyCategory={riskPolicyCategory}
                    sx={{
                        color: theme.palette.text.primary,
                        fontSize: "18px"
                    }}/>
            </Avatar>
        </Tooltip>);
}