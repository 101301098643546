import { TimeFormatter, useLocalization } from "@infrastructure";
import React from "react";
import { Contract, InfoItem } from "../../../../../../../../../../common";
import { useCommonEntityInfoItemElements } from "../useCommonEntityInfoItemElements";

export function useOktaCommonDirectoryEntityInfoItemElements(directoryEntityModel: Contract.OktaDirectoryEntityModel) {
    const commonEntityInfoItemElements = useCommonEntityInfoItemElements(directoryEntityModel);
    const directoryEntity = directoryEntityModel.entity as Contract.OktaDirectoryEntity;

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.okta.useOktaCommonDirectoryEntityInfoItemElements",
            () => ({
                creationTime: "Creation Time",
                syncTime: "Sync Time"
            }));
    return [
        commonEntityInfoItemElements.typeNameInfoElement,
        <InfoItem
            key="creationTime"
            location="all"
            title={localization.creationTime()}
            value={TimeFormatter.profileFormatDateTime(directoryEntity.creationTime)}/>,
        <InfoItem
            key="syncTime"
            title={localization.syncTime()}
            value={TimeFormatter.profileFormatDateTime(directoryEntity.syncTime)}/>
    ];
}