import { useLocalization } from "@infrastructure";
import React from "react";
import { useGetAwsRdsClusterRiskContext } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { useGetRiskPolicyConfigurationRetentionTimeFrameContextItem } from "../../../useGetRiskPolicyConfigurationRetentionTimeFrameContextItem";

export function useAwsRdsClusterBackupRetentionTimeFrameRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsRdsClusterBackupRetentionTimeFrameRisk;
    const clusterModel = entityModelStore.useGet(risk.entityId) as Contract.AwsRdsClusterModel;
    const cluster = clusterModel.entity as Contract.AwsRdsCluster;

    const getAwsRdsClusterRiskContext = useGetAwsRdsClusterRiskContext();
    const getRiskPolicyConfigurationRetentionTimeFrameContextItem = useGetRiskPolicyConfigurationRetentionTimeFrameContextItem();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsRdsClusterBackupRetentionTimeFrameRiskDefinition",
            () => ({
                description: "{{cluster}} backup retention period is less than {{configuredBackupRetentionTimeFrame | TimeSpanFormatter.day}}",
                sections: {
                    resolution: {
                        step1: "In the top right corner, click **Modify**",
                        step2: "Scroll down to the **Additional configuration** section and set the **Backup retention period** to at least {{configuredBackupRetentionTimeFrame | TimeSpanFormatter.day}}",
                        step3: "Continue and save changes"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            cluster:
                <Entity
                    entityIdOrModel={clusterModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>,
            configuredBackupRetentionTimeFrame: risk.backupRetentionTimeFrame
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.Rds,
                AwsConsoleUrlBuilder.getRdsClusterUrl(cluster)),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2({ configuredBackupRetentionTimeFrame: risk.backupRetentionTimeFrame }),
            localization.sections.resolution.step3()
        ],
        riskModel,
        () => {
            const clusterRiskContext = getAwsRdsClusterRiskContext(clusterModel);
            return [
                clusterRiskContext.generalInformation,
                getRiskPolicyConfigurationRetentionTimeFrameContextItem(risk.backupRetentionTimeFrame),
                clusterRiskContext.backupRetentionTimeFrame,
                clusterRiskContext.storageSize,
                clusterRiskContext.instances,
                clusterRiskContext.highestDataSensitivity,
                clusterRiskContext.sensitive,
                clusterRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}