import { Link, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, CustomerConsoleAppUrlHelper } from "..";

export function useDataAnalysisErrorTranslator() {
    const localization =
        useLocalization(
            "common.hooks.useDataAnalysisErrorTranslator",
            () => ({
                documentation: "Learn more.",
                text: {
                    [Contract.TypeNames.DataAnalysisResourceModelDataAnalysisError]: {
                        [Contract.DataAnalysisResourceModelDataAnalysisError.DataAnalysisDisabled]: "Data analysis is disabled for this resource",
                        [Contract.DataAnalysisResourceModelDataAnalysisError.DatabaseInstanceMemberOfRdsAuroraCluster]: "Resource is a member of an RDS Aurora cluster",
                        [Contract.DataAnalysisResourceModelDataAnalysisError.Empty]: "The resource contains no data",
                        [Contract.DataAnalysisResourceModelDataAnalysisError.InsufficientPermissions]: "The scan failed due to missing permissions",
                        [Contract.DataAnalysisResourceModelDataAnalysisError.MaxResourceSizeExceeded]: "Data analysis is disabled for very large resources",
                        [Contract.DataAnalysisResourceModelDataAnalysisError.NotSupported]: "Data analysis is not supported for existing items",
                        [Contract.DataAnalysisResourceModelDataAnalysisError.PrivateObjectStoreScanDisabled]: "Data analysis is disabled for private object stores",
                        [Contract.DataAnalysisResourceModelDataAnalysisError.ResourceConfigurationNotSupported]: "Data analysis is not supported for resource configuration",
                        [Contract.DataAnalysisResourceModelDataAnalysisError.ScanFailure]: "Scan failed"
                    }
                },
                withLink: "{{text}}. {{link}}",
                withoutLink: "{{text}}"
            }));

    return (dataAnalysisError: Contract.DataAnalysisResourceModelDataAnalysisError, variant: "tooltip" | "export" = "tooltip") => {
        const url =
            dataAnalysisError == Contract.DataAnalysisResourceModelDataAnalysisError.InsufficientPermissions
                ? CustomerConsoleAppUrlHelper.getDocsConfigureDataProtectionRelativeUrl()
                : dataAnalysisError == Contract.DataAnalysisResourceModelDataAnalysisError.NotSupported
                    ? CustomerConsoleAppUrlHelper.getDocsHowTenableScansYourDataScannedFileTypesRelativeUrl()
                    : undefined;

        return (variant === "export" || _.isNil(url)
            ? localization.withoutLink
            : localization.withLink)(
            {
                link:
                        <Link
                            sx={{ display: "flex" }}
                            urlOrGetUrl={url}
                            variant="external">
                            {localization.documentation()}
                        </Link>,
                text: localization.text[Contract.TypeNames.DataAnalysisResourceModelDataAnalysisError][dataAnalysisError]()
            });
    };
}