import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function PaloAltoNetworksIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                clipRule="evenodd"
                d="M18.872 6.45839L15.4435 3L12.0149 6.45839L13.7143 8.18758L5.12795 16.8335L8.55652 20.2919L11.9851 16.8335L10.2857 15.1043L18.872 6.45839ZM0 11.646L3.42857 15.1043L12.0149 6.45839L8.58634 3L0 11.646ZM11.9851 16.8037L15.4137 20.2621L24 11.6161L20.6012 8.15776L11.9851 16.8037Z"
                fill="#FA582D"
                fillRule="evenodd"/>
        </SvgIcon>);
}