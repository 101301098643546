﻿import { useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract } from "../../../../../../../controllers";
import { useEntityTypeNameTranslator } from "../../../../../../../hooks";
import { scopeNodeModelStore, tenantModelStore } from "../../../../../../../stores";
import { TenantMultiSelect } from "../../../../../../TenantMultiSelect";
import { useCustomRiskPolicyContext } from "../../../../../CustomRiskPolicy";
import { EntityMultiSelectProperty, useMultiSelectorContext, useSetMultiSelectorContext } from "../../../EntityMultiSelect";

type TenantProps = {
    disabled?: boolean;
    entityTypeName: string;
    includeServiceName: boolean;
    tenantTypes?: Contract.TenantType[];
};

export function Tenants({ disabled, entityTypeName, includeServiceName, tenantTypes }: TenantProps) {
    const { scopeId, tenantTypes: riskTenantTypes } = useCustomRiskPolicyContext();
    const scopeNodeModel = scopeNodeModelStore.useGet(scopeId);
    const { propertyIdToConditionMap } = useMultiSelectorContext();
    const condition = propertyIdToConditionMap[EntityMultiSelectProperty.TenantIds] as Contract.EntityTenantIdMatchCondition;
    const setMultiSelectorContext = useSetMultiSelectorContext();
    const scopeNodeMap = scopeNodeModelStore.useGetActiveScopeNodeMap();
    const tenantModels = tenantModelStore.useGet(scopeNodeMap[scopeNodeModel.configuration.id].tenantIds);
    const tenantIds =
        useMemo(
            () =>
                _(tenantModels).
                    filter(
                        tenantModel =>
                            _.includes(
                                tenantTypes ?? riskTenantTypes,
                                tenantModel.tenantType)).
                    map(tenantModel => tenantModel.configuration.id).
                    value(),
            [tenantModels]);
    const entityTypeNameTranslator = useEntityTypeNameTranslator();

    const localization =
        useLocalization(
            "common.customRiskPolicy.entityMultiSelect.condition.tenants",
            () => ({
                selected: "{{selectedTypeNameCount}} selected",
                title: "*capitalize*{{translatedEntityTypeName}}** in these accounts"
            }));

    return (
        <Stack
            alignItems="center"
            direction="row"
            spacing={1}>
            <Typography>
                {localization.title({
                    translatedEntityTypeName:
                        entityTypeNameTranslator(
                            entityTypeName,
                            {
                                count: 0,
                                includeServiceName,
                                variant: "text"
                            })
                })}
            </Typography>
            <Stack spacing={2}>
                <TenantMultiSelect
                    dense={true}
                    disabled={disabled}
                    displayAllAsEmpty={false}
                    fullWidth={false}
                    rootFolderId={scopeNodeModel.configuration.id}
                    selectedTenantIds={condition.tenantIds}
                    tenantIds={tenantIds}
                    variant="itemSelector"
                    onSelectedTenantIdsChanged={
                        tenantIds =>
                            setMultiSelectorContext(
                                context => ({
                                    propertyIdToConditionMap: {
                                        ...context.propertyIdToConditionMap,
                                        [EntityMultiSelectProperty.TenantIds]:
                                            new Contract.EntityTenantIdMatchCondition(tenantIds)
                                    }
                                }))}/>
            </Stack>
        </Stack>);
}