import { DataTableSort, makeContextProvider, Optional } from "@infrastructure";
import _, { Dictionary } from "lodash";
import { MutableRefObject, useRef } from "react";
import { Contract, useTheme } from "../../../../../../../common";

export type RequestData = {
    getFilters: (filtersMap: Dictionary<any>) => Contract.RiskControllerRiskModelFilters;
    getFiltersTime: () => Optional<string>;
    getSort: (sort?: DataTableSort) => Optional<Contract.RiskControllerRiskModelSort>;
};

class ItemsContext {
    constructor(
        public filtered: boolean,
        public requestFilters: Optional<Contract.RiskControllerRiskModelFilters>,
        public requestFiltersTime: Optional<string>,
        public getRequestSort: (sort?: DataTableSort) => Optional<Contract.RiskControllerRiskModelSort>,
        public reloadRiskModel: (status?: Contract.RiskStatus) => Promise<void>,
        public riskIdToScopeIdsMapRef: MutableRefObject<Dictionary<string[]>>,
        public view: Contract.RisksView,
        public entityAttributesTopCount?: number) {
    }
}

const [useItemsContext, , useItemsContextProviderCore] = makeContextProvider<ItemsContext>();

function useItemsContextProvider(
    filtersMap: Dictionary<any>,
    filtersInitialized: boolean,
    reloadRiskModel: (status?: Contract.RiskStatus) => Promise<void>,
    requestData: RequestData,
    view: Contract.RisksView) {
    const riskIdToScopeIdsMapRef = useRef<Dictionary<string[]>>({});
    const theme = useTheme();
    return useItemsContextProviderCore(
        () =>
            new ItemsContext(
                !_.isEmpty(filtersMap),
                filtersInitialized
                    ? requestData.getFilters(filtersMap)
                    : undefined,
                requestData.getFiltersTime(),
                requestData.getSort,
                reloadRiskModel,
                riskIdToScopeIdsMapRef,
                view,
                window.innerWidth < theme.breakpoints.get("xl")
                    ? 1
                    : undefined),
        [filtersInitialized, filtersMap, view]);
}

export { useItemsContext, useItemsContextProvider };