import { UnexpectedError } from "@infrastructure";
import { useMemo } from "react";
import { Contract } from "../../../../../../../../../../../../../../../../common";
import { ConditionType } from "../../utilities";
import { useEntityNamePatternConditionDefinition, useEntityTagPatternConditionDefinition, useTenantNamePatternConditionDefinition, useTenantTagConditionDefinition } from "./hooks";

type ConditionDefinition = {
    conditionType: ConditionType;
    not: boolean;
    value: string | Contract.ResourceTagPattern;
};

export function useDefinition(condition: Contract.ProjectConfigurationRuleCondition): ConditionDefinition {
    const useDefinition =
        useMemo(
            () => {
                switch (condition.typeName) {
                    case ConditionType.EntityNamePattern:
                        return useEntityNamePatternConditionDefinition;
                    case ConditionType.EntityTagPattern:
                        return useEntityTagPatternConditionDefinition;
                    case ConditionType.TenantNamePattern:
                        return useTenantNamePatternConditionDefinition;
                    case ConditionType.TenantTagPattern:
                        return useTenantTagConditionDefinition;
                    default:
                        throw new UnexpectedError("condition.typeName", condition.typeName);
                }
            },
            [condition]);

    return useDefinition(condition);
}