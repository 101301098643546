import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useGcpDnsManagedZonePrivateZoneTypeTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useGcpDnsManagedZonePrivateZoneTypeTranslator",
            () => ({
                [Contract.TypeNames.GcpDnsManagedZonePrivateZoneType]: {
                    [Contract.GcpDnsManagedZonePrivateZoneType.Default]: "Default",
                    [Contract.GcpDnsManagedZonePrivateZoneType.DnsServerForwarding]: "Forwarding",
                    [Contract.GcpDnsManagedZonePrivateZoneType.ManagedZonePeering]: "Peering",
                    [Contract.GcpDnsManagedZonePrivateZoneType.ReverseLookup]: "Reverse Lookup",
                    [Contract.GcpDnsManagedZonePrivateZoneType.ServiceDirectoryNamespace]: "Service Directory"
                }
            }));
    return (privateZoneType: Contract.GcpDnsManagedZonePrivateZoneType) =>
        localization[Contract.TypeNames.GcpDnsManagedZonePrivateZoneType][privateZoneType]();
}