﻿import { UnexpectedError } from "@infrastructure";
import { ReactNode, useMemo } from "react";
import { Contract } from "../../../../../../common";
import { ScopesView, ScopesViewType } from "../../utilities";
import { useAwsProfileDefinition, useAzureProfileDefinition, useCiProfileDefinition, useCodeProfileDefinition, useGcpProfileDefinition, useOciProfileDefinition, useOpProfileDefinition, useProjectsProfileDefinition } from "./hooks";

export function useProfileDefinition(view: ScopesViewType, scopeNodeModel: Contract.ScopeNodeModel): ProfileDefinition {
    const useProfileDefinition =
        useMemo(
            () => {
                switch (view) {
                    case ScopesView.Aws:
                        return useAwsProfileDefinition;
                    case ScopesView.Azure:
                        return useAzureProfileDefinition;
                    case ScopesView.Ci:
                        return useCiProfileDefinition;
                    case ScopesView.Code:
                        return useCodeProfileDefinition;
                    case ScopesView.Gcp:
                        return useGcpProfileDefinition;
                    case ScopesView.Oci:
                        return useOciProfileDefinition;
                    case ScopesView.Op:
                        return useOpProfileDefinition;
                    case ScopesView.Project:
                        return useProjectsProfileDefinition;
                    default:
                        throw new UnexpectedError("view", view);
                }
            },
            [view]);

    return useProfileDefinition(scopeNodeModel);
}

export type ProfileDefinition = {
    sections: ProfileDataSection[];
};

export type ProfileDataSection = {
    items: ProfileDataSectionItem[];
    title?: string;
};

export type ProfileDataSectionItem = {
    title?: string;
    value: string | ReactNode;
};