import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function ConnectIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                clipRule="evenodd"
                d="M18.1882 5.95133L17.6182 5.40922C15.9093 3.7837 13.2327 3.86821 11.6262 5.60371C11.4328 5.81262 11.264 6.03534 11.1194 6.26854L17.7915 12.6323C17.9989 12.4777 18.196 12.3013 18.3801 12.1025C20.0009 10.3515 19.9126 7.59148 18.1882 5.95133ZM17.4518 15.0721L8.72998 6.75337C8.99925 5.8448 9.47465 4.98387 10.1585 4.24509C12.5215 1.69245 16.4784 1.56483 18.9966 3.96005L19.5666 4.50217C22.0848 6.89739 22.2107 10.9084 19.8478 13.4611C19.1594 14.2047 18.3358 14.7425 17.4518 15.0721Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M4.92169 18.6644L5.49165 19.2065C7.20064 20.832 9.87715 20.7475 11.4837 19.012C11.6772 18.8029 11.846 18.5802 11.9905 18.3472L5.31838 11.9834C5.11123 12.1378 4.91405 12.3143 4.72983 12.5133C3.10899 14.2642 3.19732 17.0242 4.92169 18.6644ZM4.11327 20.6557L3.54331 20.1136C1.02509 17.7183 0.899201 13.7073 3.26213 11.1546C3.79888 10.5748 4.41788 10.1201 5.08359 9.79165C5.42699 9.62222 5.83398 9.71137 6.11108 9.97566L13.9377 17.4405C14.2091 17.6995 14.319 18.0913 14.1784 18.4391C13.8973 19.1343 13.4887 19.7902 12.9514 20.3706C10.5884 22.9233 6.63149 23.0509 4.11327 20.6557Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M10.7513 11.6673L9.02958 13.4125L7.625 11.9888L9.34674 10.2435C9.7346 9.85033 10.3635 9.85033 10.7513 10.2435C11.1392 10.6367 11.1392 11.2741 10.7513 11.6673Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M0.590719 23.7116C0.202854 23.3185 0.202854 22.681 0.590719 22.2879L3.71704 19.1188C4.1049 18.7256 4.73376 18.7256 5.12162 19.1188C5.50948 19.512 5.50948 20.1494 5.12162 20.5426L1.9953 23.7116C1.60744 24.1048 0.978583 24.1048 0.590719 23.7116Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M18.4643 5.88059C18.0764 5.48743 18.0764 4.84998 18.4643 4.45681L21.5906 1.28776C21.9784 0.894593 22.6073 0.894593 22.9952 1.28776C23.383 1.68092 23.383 2.31837 22.9952 2.71154L19.8688 5.88059C19.481 6.27376 18.8521 6.27376 18.4643 5.88059Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M13.8778 14.8362L12.156 16.5815L10.7515 15.1577L12.4732 13.4124C12.8611 13.0193 13.4899 13.0193 13.8778 13.4124C14.2656 13.8056 14.2656 14.4431 13.8778 14.8362Z"
                fillRule="evenodd"/>
        </SvgIcon>);
}