﻿import React from "react";
import { useLocalization } from "@infrastructure";
import { useOciDefaultResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { Contract, InfoItem } from "../../../../../../../../../../common";
import { useOciContainerEngineClusterStatusTranslator } from "../../../../../../hooks";
import { EntitiesInfoItem, Info } from "../../../../components";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";

export function useOciContainerEngineClusterDefinition(resourceModel: Contract.OciResourceModel, options?: DefinitionOptions) {
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useOciDefaultResourceInfoItemElements(resourceModel);
    const clusterModel = resourceModel as Contract.OciContainerEngineClusterModel;
    const cluster = clusterModel.entity as Contract.OciContainerEngineCluster;

    const clusterStatusTranslator = useOciContainerEngineClusterStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.oci.useOciContainerEngineClusterDefinition",
            () => ({
                info: {
                    items: {
                        nodePools: "Node Pools",
                        status: "Status",
                        type: {
                            title: "Type",
                            [Contract.TypeNames.OciContainerEngineClusterType]: {
                                [Contract.OciContainerEngineClusterType.Basic]: "Basic",
                                [Contract.OciContainerEngineClusterType.Enhanced]: "Enhanced"
                            }
                        },
                        version: "Kubernetes Version"
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="status"
                        location="all"
                        title={localization.info.items.status()}
                        value={clusterStatusTranslator(cluster.status)}/>,
                    <InfoItem
                        key="type"
                        location="all"
                        title={localization.info.items.type.title()}
                        value={localization.info.items.type[Contract.TypeNames.OciContainerEngineClusterType][cluster.type]()}/>,
                    <InfoItem
                        key="version"
                        location="all"
                        title={localization.info.items.version()}
                        value={cluster.version}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={clusterModel.nodePoolIds}
                        entityTypeName={Contract.TypeNames.OciContainerEngineClusterNodePool}
                        key="nodePools"
                        title={localization.info.items.nodePools()}/>
                ]}
                options={options?.infoOptions}>
            </Info>
    });
}