import { useLocalization } from "@infrastructure";
import { DefinitionData } from "../../../../Table";
import { useCommonKubernetesResourceDefinition } from "../useCommonKubernetesResourceDefinition";
import { useAzureCommonResourceDefinition } from "./useAzureCommonResourceDefinition";

export function useAzureCommonKubernetesResourceDefinition(definitionData: DefinitionData) {
    const commonResourceDefinition = useAzureCommonResourceDefinition(definitionData);
    const commonKubernetesResourceDefinition = useCommonKubernetesResourceDefinition(definitionData);

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.azure.useAzureCommonKubernetesResourceDefinition",
            () => ({
                columns: {
                    tags: "Kubernetes Labels"
                }
            }));

    return {
        columns: {
            ...commonResourceDefinition.columns,
            ...commonKubernetesResourceDefinition.columns,
            tagsColumn:
                commonResourceDefinition.createTagsColumn(localization.columns.tags())
        }
    };
}