import { DataTableColumn, Link, TimeFormatter, useLocalization } from "@infrastructure";
import _ from "lodash";
import moment from "moment";
import React from "react";
import { RiskContentProps } from "../../../../..";
import { Contract, CustomerConsoleAppUrlHelper, Entity, entityModelStore } from "../../../../../../../../../../../../../common";
import { ProfileCategory } from "../../../../../../../../../../Entities/components/Profile/hooks";
import { Table } from "../../../../../components";
import { AwsCommonComplianceRiskDefinitionResolutionSection, useAwsCommonComplianceRiskDefinition } from "../useAwsCommonComplianceRiskDefinition";
import { ContextSection } from "./components";

export function useAwsIamRootUserActivityRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsIamRootUserActivityRisk;
    const rootUserModel = entityModelStore.useGet(risk.entityId);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsIamRootUserActivityRiskDefinition",
            () => ({
                description: {
                    manyDays: "{{rootUser}} was used on consecutive days between {{startActivityTime | TimeFormatter.longDate}} and {{endActivityTime | TimeFormatter.longDate}}",
                    singleDay: "{{rootUser}} was used on {{activityTime | TimeFormatter.longDate}}"
                },
                sections: {
                    resolution: {
                        step1: {
                            link: "Configure an administrative user in AWS IAM Identity Center (successor to AWS Single Sign-On)",
                            text: "{{documentationLink}} to perform daily tasks and access AWS resources. This is based on AWS recommendations."
                        }
                    }
                }
            }));

    return useAwsCommonComplianceRiskDefinition(
        TimeFormatter.longDate(moment(risk.activityTimeRange.startTime)) == TimeFormatter.longDate(moment(risk.activityTimeRange.endTime))
            ? localization.description.singleDay({
                activityTime: risk.activityTimeRange.endTime,
                rootUser:
                    <Entity
                        entityIdOrModel={rootUserModel}
                        entityTypeNameTranslatorOptions={{ variant: "title" }}
                        variant="typeText"/>
            })
            : localization.description.manyDays({
                endActivityTime: risk.activityTimeRange.endTime,
                rootUser:
                    <Entity
                        entityIdOrModel={rootUserModel}
                        variant="text"/>,
                startActivityTime: risk.activityTimeRange.startTime
            }),
        <DetailsSection riskModel={riskModel}/>,
        new AwsCommonComplianceRiskDefinitionResolutionSection(
            [
                localization.sections.resolution.step1.text({
                    documentationLink:
                        <Link
                            urlOrGetUrl={(riskModel as Contract.AwsIamRootUserActivityRiskModel).ssoDocumentationUrl}
                            variant="external">
                            {localization.sections.resolution.step1.link()}
                        </Link>
                })
            ]),
        riskModel,
        risk.reopened
            ? <ContextSection risk={risk}/>
            : undefined);
}

function DetailsSection({ riskModel }: RiskContentProps) {
    const rootUserActivityRiskModel = riskModel as Contract.AwsIamRootUserActivityRiskModel;
    const rootUserModel = entityModelStore.useGet(rootUserActivityRiskModel.risk.entityId);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsIamRootUserActivityRiskDefinition.detailsSection",
            () => ({
                columns: {
                    accessKey: {
                        disabled: "disabled",
                        empty: "Never",
                        title: "Access Key Used"
                    },
                    events: {
                        links: {
                            events: "here"
                        },
                        title: "Activities",
                        view: "To view the activity log, go {{eventsLink}}"
                    },
                    passwordUsageTime: {
                        empty: "Never",
                        title: "Password Used"
                    }
                }
            }));
    return (
        <Table
            fetchItems={() => [rootUserActivityRiskModel.risk as Contract.AwsIamRootUserActivityRisk]}
            getItemId={(item: Contract.AwsIamRootUserActivityRisk) => item.id}>
            <DataTableColumn
                id={DetailsSectionColumnId.PasswordUsageTime}
                itemProperty={
                    (item: Contract.AwsIamRootUserActivityRisk) =>
                        _.isNil(item.passwordUsageTime)
                            ? localization.columns.passwordUsageTime.empty()
                            : TimeFormatter.shortDateTime(item.passwordUsageTime)}
                title={localization.columns.passwordUsageTime.title()}/>
            <DataTableColumn
                id={DetailsSectionColumnId.AccessKey}
                itemProperty={
                    (item: Contract.AwsIamRootUserActivityRisk) =>
                        !_.isNil(item.accessKeyUsageTime)
                            ? TimeFormatter.shortDateTime(item.accessKeyUsageTime)
                            : item.accessKeyEnabled
                                ? localization.columns.accessKey.empty()
                                : localization.columns.accessKey.disabled()
                }
                title={localization.columns.accessKey.title()}/>
            <DataTableColumn
                id={DetailsSectionColumnId.Events}
                itemProperty={
                    () =>
                        localization.columns.events.view({
                            eventsLink:
                                <Link urlOrGetUrl={CustomerConsoleAppUrlHelper.getEntityProfileRelativeUrl(rootUserModel, { category: ProfileCategory.Risks })}>
                                    {localization.columns.events.links.events()}
                                </Link>
                        })}
                title={localization.columns.events.title()}/>
        </Table>);
}

enum DetailsSectionColumnId {
    AccessKey = "accessKey",
    Events = "events",
    PasswordUsageTime = "passwordUsageTime"
}