import { Link, Tooltip, useLocalization, useLocalizeAttributes } from "@infrastructure";
import { Box, Divider, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { ReactNode, useMemo } from "react";
import { SensitiveToggle } from "../../..";
import { Contract, customEntityAttributeDefinitionModelStore, Entity, EntityAttributes, EntityDefinition, EntityTypeMetadataModelHelper, ProfileTopbar, TenantHelper, TypeHelper, useEntityTypeNameTranslator, useTenantNameTranslator, useTenantTypeTranslator, useTheme } from "../../../../../../../../common";
import { EntityProfileDefinition } from "../../hooks";
import { ProfileTopbarDeletedInfoItem } from "./components";

type TopbarProps = {
    definition: EntityProfileDefinition;
    entityModel: Contract.EntityModel;
};

export function Topbar({ definition, entityModel }: TopbarProps) {
    const customEntityAttributeDefinitionModels = customEntityAttributeDefinitionModelStore.useGetAll();
    const hasEntityAttributes =
        useMemo(
            () => {
                const customEntityAttributeDefinitionModelMap =
                    _.keyBy(
                        customEntityAttributeDefinitionModels,
                        customEntityAttributeDefinitionModel => customEntityAttributeDefinitionModel.configuration.id);
                return _.some(
                    entityModel.attributes.attributes,
                    entityAttribute =>
                        !TypeHelper.extendOrImplement(entityAttribute.typeName, Contract.TypeNames.CustomEntityAttribute) ||
                        !_.isNil(customEntityAttributeDefinitionModelMap[(entityAttribute as Contract.CustomEntityAttribute).definitionId]));

            },
            [entityModel]);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localizeAttributes = useLocalizeAttributes();
    const tenantNameTranslator = useTenantNameTranslator();
    const tenantTypeTranslator = useTenantTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.topbar",
            () => ({
                consoleUrlTooltip: "View in {{translatedTenantType}} console",
                deleted: "Deleted"
            }));

    const translatedTenantType = tenantTypeTranslator(EntityTypeMetadataModelHelper.get(entityModel.entity.typeName).tenantType);

    const theme = useTheme();
    const infoChildren =
        useMemo(
            () =>
                _<ReactNode>([]).
                    concatIf(
                        !_.isNil(entityModel.consoleUrl),
                        <Link
                            key="externalLink"
                            sx={{
                                "&:hover": {
                                    textDecoration: "none"
                                },
                                marginRight: theme.spacing(0.5)
                            }}
                            urlOrGetUrl={entityModel.consoleUrl}
                            variant="external">
                            <Tooltip titleOrGetTitle={localization.consoleUrlTooltip({ translatedTenantType })}>
                                <Typography noWrap={true}>
                                    {translatedTenantType}
                                </Typography>
                            </Tooltip>
                        </Link>).
                    concatIf(
                        entityModel.systemDeleted &&
                        !_.isNil(entityModel.systemDeletionTime),
                        <ProfileTopbarDeletedInfoItem
                            deletedTime={entityModel.systemDeletionTime!}
                            key="systemDeleted"/>).
                    concatIf(
                        !entityModel.systemDeleted && !_.isEmpty(definition.options.statElements),
                        <Stack
                            direction="row"
                            key="systemDeletedStats"
                            spacing={1}>
                            {_.map(
                                definition.options.statElements,
                                (statElement, statElementIndex) =>
                                    <Box key={statElementIndex}>
                                        {statElement}
                                    </Box>)}
                        </Stack>).
                    concatIf(
                        !TenantHelper.isCommonTenant(entityModel.tenantId) &&
                        definition.options.sensitive !== false &&
                        !entityModel.systemDeleted,
                        <SensitiveToggle
                            entityId={entityModel.entity.id}
                            key="sensitive"/>).
                    value(),
            [definition.options.statElements, entityModel, translatedTenantType]);

    return (
        <ProfileTopbar
            icon={
                <Entity
                    entityIdOrModel={entityModel}
                    linkOptions={{ disabled: true }}
                    sxOptions={{ icon: { fontSize: "30px" } }}
                    variant="icon"/>}
            infoChildren={infoChildren}
            subtitle={
                <EntityDefinition entityModel={entityModel}>
                    {definition =>
                        <Stack
                            alignItems="center"
                            direction="row"
                            spacing={1}
                            sx={{ marginTop: theme.spacing(0.5) }}>
                            <Typography
                                noWrap={true}
                                sx={{ color: theme.palette.text.secondary }}>
                                {definition.translatedEntityTypeName ?? entityTypeNameTranslator(entityModel.entity.typeName)}
                            </Typography>
                            <Divider
                                orientation="vertical"
                                sx={{
                                    borderColor: theme.palette.borders.hoverFocus,
                                    height: theme.spacing(1.5)
                                }}/>
                            <Typography
                                noWrap={true}
                                sx={{ color: theme.palette.text.secondary }}>
                                {localizeAttributes(
                                    ..._<string>([]).
                                        concatIf(
                                            definition.showTenant != false,
                                            tenantNameTranslator(entityModel.entity.tenantId)).
                                        concatIf(
                                            !_.isEmpty(definition.subtitleAdditionalAttributes),
                                            definition.subtitleAdditionalAttributes!).
                                        value())}
                            </Typography>
                        </Stack>}
                </EntityDefinition>}
            title={
                <Stack
                    alignItems="center"
                    direction="row"
                    spacing={1}>
                    <Typography
                        noWrap={true}
                        sx={{
                            fontWeight: 500,
                            lineHeight: "20px"
                        }}
                        variant="h2">
                        {entityModel.entity.displayName}
                    </Typography>
                    {entityModel.systemDeleted &&
                        <Typography
                            sx={{
                                border: theme.border.primary,
                                borderColor: theme.palette.error.main,
                                borderRadius: theme.spacing(0.75),
                                lineHeight: "18px",
                                padding: theme.spacing(0, 1)
                            }}>
                            {localization.deleted()}
                        </Typography>}
                </Stack>}
            titleInfo={
                hasEntityAttributes
                    ? <EntityAttributes
                        entityAttributes={entityModel.attributes.attributes}
                        entityTypeName={entityModel.entity.typeName}/>
                    : undefined}/>);
}