﻿import React from "react";

export function EuIcon() {
    return (
        <svg
            fill="none"
            height={50}
            viewBox="0 0 50 50"
            width={50}>
            <path
                d="M25.1048 49.5417C38.7854 49.5417 49.8757 38.4514 49.8757 24.7708C49.8757 11.0903 38.7854 0 25.1048 0C11.4243 0 0.333984 11.0903 0.333984 24.7708C0.333984 38.4514 11.4243 49.5417 25.1048 49.5417Z"
                fill="#0052B4"/>
            <path
                d="M25.1043 9.69287L25.9064 12.1611H28.5015L26.4019 13.6866L27.2039 16.1549L25.1043 14.6293L23.0046 16.1549L23.8066 13.6866L21.707 12.1611H24.3023L25.1043 9.69287Z"
                fill="#FFDA44"/>
            <path
                d="M14.4419 14.109L16.7545 15.2871L18.5895 13.4521L18.1834 16.0153L20.4959 17.1936L17.9326 17.5996L17.5266 20.163L16.3484 17.8505L13.7852 18.2566L15.6202 16.4216L14.4419 14.109Z"
                fill="#FFDA44"/>
            <path
                d="M10.0273 24.7708L12.4956 23.9688V21.3735L14.021 23.4733L16.4893 22.6712L14.9637 24.7708L16.4893 26.8704L14.021 26.0685L12.4956 28.1681V25.5729L10.0273 24.7708Z"
                fill="#FFDA44"/>
            <path
                d="M14.4419 35.4324L15.6201 33.1198L13.7852 31.2847L16.3485 31.6909L17.5265 29.3784L17.9326 31.9417L20.4958 32.3477L18.1836 33.526L18.5895 36.0892L16.7545 34.2541L14.4419 35.4324Z"
                fill="#FFDA44"/>
            <path
                d="M25.1043 39.8485L24.3022 37.3802H21.707L23.8067 35.8548L23.0046 33.3867L25.1043 34.9121L27.2039 33.3867L26.4019 35.8548L28.5015 37.3802H25.9063L25.1043 39.8485Z"
                fill="#FFDA44"/>
            <path
                d="M35.7687 35.4324L33.4562 34.2542L31.621 36.0893L32.0271 33.5259L29.7148 32.3477L32.278 31.9417L32.6841 29.3784L33.8622 31.6909L36.4254 31.2847L34.5903 33.12L35.7687 35.4324Z"
                fill="#FFDA44"/>
            <path
                d="M40.1845 24.7708L37.7162 25.5729V28.1681L36.1907 26.0684L33.7227 26.8704L35.2481 24.7708L33.7227 22.6712L36.1908 23.4733L37.7162 21.3735V23.9689L40.1845 24.7708Z"
                fill="#FFDA44"/>
            <path
                d="M35.7687 14.1089L34.5904 16.4215L36.4255 18.2565L33.8621 17.8503L32.6841 20.1628L32.278 17.5995L29.7148 17.1934L32.0271 16.0153L31.621 13.4521L33.4563 15.2871L35.7687 14.1089Z"
                fill="#FFDA44"/>
        </svg>);
}