import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function RockyIcon(props: SvgIconProps) {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <g clipPath="url(#clip0_52885_254809)">
                <path
                    clipRule="evenodd"
                    d="M23.3323 15.9572C23.7649 14.7182 24 13.3866 24 12C24 5.37259 18.6274 0 12 0C5.37259 0 0 5.37259 0 12C0 15.2793 1.31538 18.2514 3.44729 20.4172L15.6198 8.2448L18.625 11.25L23.3323 15.9572ZM21.1402 19.7756L15.6198 14.2552L6.97472 22.9002C8.50336 23.6061 10.2057 24 12 24C15.661 24 18.9392 22.3605 21.1402 19.7756Z"
                    fill="#10B981"
                    fillRule="evenodd"/>
            </g>
            <defs>
                <clipPath id="clip0_52885_254809">
                    <rect
                        fill="white"
                        height="24"
                        width="24"/>
                </clipPath>
            </defs>
        </SvgIcon>);
}