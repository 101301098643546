﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { Fragment, useMemo } from "react";
import { Contract, InlineEntities, InlinePermissionActions, RiskTypeMetadataModelHelper, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { InlineHighSeverityPermissionActions } from "../compliance/publicAccess/components";

export function useGetGcpScopeResourcePublicAccessExistsRiskContext() {
    return useMemo(
        () => useGcpScopeResourcePublicAccessExistsRiskContext,
        []);
}

function useGcpScopeResourcePublicAccessExistsRiskContext(risk: Contract.GcpScopeResourcePublicAccessExistsRisk) {
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.contexts.useGetGcpScopeResourcePublicAccessExistsRiskContext",
            () => ({
                invocationOnly: "The {{translatedResourceTypeName}} can be invoked by anonymous identity",
                permissionActions: {
                    many: {
                        allHighPermissionActions: "{{publicRoleIds}} are assigned to **{{principalIds}}** which expose {{highPermissionActions}} to the public (any principal)",
                        noneHighPermissionActions: "{{publicRoleIds}} are assigned to **{{principalIds}}** which expose {{permissionActions}} to the public (any principal)",
                        someHighPermissionActions: "{{publicRoleIds}} are assigned to **{{principalIds}}** which expose {{permissionActions}} to the public (any principal), {{highPermissionActions}}"
                    },
                    single: {
                        allHighPermissionActions: "Role {{publicRoleIds}} is assigned to **{{principalIds}}** which exposes {{highPermissionActions}} to the public (any principal)",
                        noneHighPermissionActions: "Role {{publicRoleIds}} is assigned to **{{principalIds}}** which exposes {{permissionActions}} to the public (any principal)",
                        someHighPermissionActions: "Role {{publicRoleIds}} is assigned to **{{principalIds}}** which exposes {{permissionActions}} to the public (any principal), {{highPermissionActions}}"
                    }
                }
            }));
    const rolesLocalization =
        localization.permissionActions[
            risk.roleIds.length === 1
                ? "single"
                : "many"];
    const rolesAndPermissionsLocalization =
        _(risk.permissionActionToHighSeverityMap).
            values().
            every(highSeverity => highSeverity)
            ? rolesLocalization.allHighPermissionActions
            : _(risk.permissionActionToHighSeverityMap).
                values().
                some(highSeverity => highSeverity)
                ? rolesLocalization.someHighPermissionActions
                : rolesLocalization.noneHighPermissionActions;

    return {
        permissionActions:
            risk.invocationOnly
                ? new RiskDefinitionContextItem(
                    localization.invocationOnly({
                        translatedResourceTypeName:
                            entityTypeNameTranslator(
                                RiskTypeMetadataModelHelper.get(risk.typeName).riskedEntityTypeName,
                                {
                                    includeServiceName: false,
                                    variant: "text"
                                })
                    }))
                : new RiskDefinitionContextItem(
                    <Fragment>
                        {
                            rolesAndPermissionsLocalization({
                                highPermissionActions: <InlineHighSeverityPermissionActions risk={risk}/>,
                                permissionActions:
                                    <InlinePermissionActions
                                        permissionActions={_.keys(risk.permissionActionToHighSeverityMap)}
                                        variant="itemCountAndType"/>,
                                principalIds:
                                    _.isEmpty(risk.principalIds)
                                        ? "AllUsers/AllAuthenticatedUsers"
                                        : <InlineEntities
                                            entityIdsOrModels={risk.principalIds}
                                            entityTypeName={Contract.TypeNames.GciDirectorySpecialGroup}
                                            variant="itemOrItemCountAndType"/>,
                                publicRoleIds:
                                    <InlineEntities
                                        entityIdsOrModels={risk.roleIds}
                                        entityTypeName={Contract.TypeNames.GcpIamRole}
                                        variant="itemOrItemCountAndType"/>
                            })
                        }
                    </Fragment>)
    };
}