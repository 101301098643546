﻿import { Steps, useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { useOpenRiskedEntityRisksStep } from "../../../../..";
import { Contract, Entity, entityModelStore, InlineEntities, useRiskSeverityReasonTranslator, useTenantNameTranslator } from "../../../../../../../../../../../../../../common";
import { RiskDefinitionSection } from "../../../../../../../../utilities";
import { RiskContentProps } from "../../../../../../useCloudDefinition";
import { useCommonSectionsAndDescriptionDefinition } from "../../../../../useCommonSectionsAndDescriptionDefinition";
import { EntityExternalConsoleLink } from "../../../../components";
import { RoleBindingTable } from "../components";

export function useGcpPrincipalServiceAccountWideScopeAdministratorRoleRiskGciDirectoryGroupDefinition(riskModel: Contract.GcpPrincipalServiceAccountWideScopeAdministratorRoleRiskModel) {
    const risk = riskModel.risk as Contract.GcpPrincipalServiceAccountWideScopeAdministratorRoleRisk;
    const groupModel = entityModelStore.useGet(risk.entityId) as Contract.GciDirectoryGroupModel;

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.useGcpPrincipalServiceAccountWideScopeAdministratorRoleRiskDefinition.hooks.useGcpPrincipalServiceAccountWideScopeAdministratorRoleRiskGciDirectoryGroupDefinition",
            () => ({
                description: {
                    roleIds: [
                        "1 high severity role",
                        "{{count | NumberFormatter.humanize}} high severity role"
                    ],
                    text: "Group {{groupId}} has {{groupServiceAccountIds}} with {{roleIds}} assigned at the project level or above"
                },
                sections: {
                    resolution: {
                        step1: "Before deleting the admin role bindings, assign least privilege role bindings according to the actual used permissions",
                        step2: "{{entityExternalConsoleLink}} and filter on **Principal:{{groupMail}}**",
                        step3: "Under **Permissions** delete the role bindings to {{roleIds}}"
                    },
                    roleBindingIds: "Role Bindings"
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description.text({
            groupId:
                <Entity
                    entityIdOrModel={groupModel}
                    variant="text"/>,
            groupServiceAccountIds:
                <InlineEntities
                    entityIdsOrModels={risk.groupServiceAccountIds}
                    entityTypeName={Contract.TypeNames.IGcpIamServiceAccount}
                    entityVariant="iconTextTypeTenant"
                    variant="itemCountAndType"/>,
            roleIds:
                <InlineEntities
                    entityIdsOrModels={riskModel.roleIds}
                    entityTypeName={Contract.TypeNames.GcpIamRole}
                    namePluralizer={localization.description.roleIds}
                    variant="itemAndTypeOrItemCountAndType"/>
        }),
        () =>
            _.concat(
                localization.sections.resolution.step1(),
                _(risk.orderedResourceManagerResourceIds).
                    flatMap(
                        resourceManagerResourceId => [
                            localization.sections.resolution.step2({
                                entityExternalConsoleLink:
                                    <EntityExternalConsoleLink
                                        entityId={resourceManagerResourceId}
                                        page={Contract.GcpConsolePage.Permissions}/>,
                                groupMail: risk.principalMail
                            }),
                            localization.sections.resolution.step3({
                                roleIds:
                                    <InlineEntities
                                        entityIdsOrModels={risk.resourceManagerResourceIdToRoleIdsMap[resourceManagerResourceId]}
                                        entityTypeName={Contract.TypeNames.GcpIamRole}
                                        entityTypeNameTranslatorOptions={{ variant: "text" }}
                                        variant="itemAndTypeOrItemCountAndType"/>
                            })
                        ]).
                    value()),
        riskModel,
        undefined,
        {
            contextSectionElement: <ContextSection riskModel={riskModel}/>,
            sections: [
                new RiskDefinitionSection(
                    <RoleBindingTable
                        csvExportFilePrefixes={[localization.sections.roleBindingIds()]}
                        risk={risk}/>,
                    localization.sections.roleBindingIds())
            ]
        });
}

function ContextSection({ riskModel }: RiskContentProps) {
    const risk = riskModel.risk as Contract.GcpPrincipalServiceAccountWideScopeAdministratorRoleRisk;
    const groupModel = entityModelStore.useGet(risk.entityId) as Contract.GciDirectoryGroupModel;
    const openRiskedEntityRisksStep = useOpenRiskedEntityRisksStep(groupModel, risk.id);
    const severityReasonTranslator = useRiskSeverityReasonTranslator();
    const tenantNameTranslator = useTenantNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.useGcpPrincipalServiceAccountWideScopeAdministratorRoleRiskDefinition.hooks.useGcpPrincipalServiceAccountWideScopeAdministratorRoleRiskGciDirectoryGroupDefinition.contextSection",
            () => ({
                creationTimeAndWorkspace: "Group {{groupId}} was created on {{creationTime | TimeFormatter.shortDate}} and is part of {{gciTenantId}} workspace"
            }));

    return (
        <Steps>
            {_.filter([
                localization.creationTimeAndWorkspace({
                    creationTime: (groupModel.entity as Contract.GciDirectoryGroup).creationTime,
                    gciTenantId:
                        <Typography
                            component="span"
                            variant="h5">
                            {tenantNameTranslator(groupModel.entity.tenantId)}
                        </Typography>,
                    groupId:
                        <Entity
                            entityIdOrModel={groupModel}
                            variant="text"/>
                }),
                openRiskedEntityRisksStep,
                severityReasonTranslator(
                    risk.severity,
                    risk.severityReason!)
            ])}
        </Steps>);
}