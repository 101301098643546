import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, InlineEntities, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { useAzureWebApplicationTypeTranslator } from "../../../../../../../../../Entities/hooks";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetAzureResourceRiskContext } from "./useGetAzureResourceRiskContext";

export function useGetAzureWebApplicationRiskContext() {
    return useMemo(
        () => useAzureWebApplicationRiskContext,
        []);
}

function useAzureWebApplicationRiskContext(applicationModel: Contract.AzureWebApplicationModel) {
    const application = applicationModel.entity as Contract.AzureWebApplication;
    const resourceRiskContext = useGetAzureResourceRiskContext()(applicationModel);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const webApplicationTypeTranslator = useAzureWebApplicationTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.contexts.useGetAzureWebApplicationRiskContext",
            () => ({
                authentication: {
                    disabled: "Disabled",
                    enabled: "Enabled",
                    title: "The {{translatedApplicationTypeName}} authentication is **{{authenticationMode}}** and allows unauthenticated access"
                },
                generalInformation: "{{applicationType}} {{applicationGeneralInformation}}",
                managedIdentities: "The function is assigned to managed identity {{managedIdentities}}",
                tlsMinVersion: "The {{translatedApplicationTypeName}} is configured with minimum TLS version {{tlsMinVersion}}"
            }));
    const translatedApplicationTypeName =
        entityTypeNameTranslator(
            application.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });
    return {
        ...resourceRiskContext,
        authentication:
            new RiskDefinitionContextItem(
                localization.authentication.title({
                    authenticationMode:
                        application.authenticationType === Contract.AzureWebApplicationAuthenticationType.Disabled
                            ? localization.authentication.disabled()
                            : localization.authentication.enabled(),
                    translatedApplicationTypeName
                })),
        generalInformation:
            new RiskDefinitionContextItem(
                localization.generalInformation({
                    applicationGeneralInformation: resourceRiskContext.generalInformation!.contentElement,
                    applicationType: webApplicationTypeTranslator(application.type)
                })),
        managedIdentities:
            _.isEmpty(applicationModel.userManagedIdentityServicePrincipalIds)
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.managedIdentities({
                        managedIdentities:
                            <InlineEntities
                                entityIdsOrModels={applicationModel.userManagedIdentityServicePrincipalIds}
                                entityTypeName={Contract.TypeNames.AadDirectoryManagedIdentityServicePrincipal}
                                variant="itemOrItemCountAndType"/>
                    })),
        tlsMinVersion:
            !_.isNil(application.tlsMinVersion)
                ? new RiskDefinitionContextItem(
                    localization.tlsMinVersion({
                        tlsMinVersion:
                        application.tlsMinVersion,
                        translatedApplicationTypeName
                    }))
                : undefined
    };
}