﻿import { Dialog, useExecuteOperation, useLocalization } from "@infrastructure";
import { Button, Stack, Typography } from "@mui/material";
import React from "react";
import { Contract, DataController, entityModelStore, useEntityTypeNameTranslator, useTheme } from "../../../../../../../common";

export type GenerateDataAnalysisResourcePriorityScanRequestDialogProps =
    {
        dataAnalysisResourceModel: Contract.IDataAnalysisResourceModel;
        onClose: () => void;
    };

export function GenerateDataAnalysisResourcePriorityScanRequestDialog({ dataAnalysisResourceModel, onClose }: GenerateDataAnalysisResourcePriorityScanRequestDialogProps) {
    return (
        <Dialog
            variant="editor"
            onClose={onClose}>
            <Core
                dataAnalysisResourceModel={dataAnalysisResourceModel}
                onClose={onClose}/>
        </Dialog>);
}

function Core({ dataAnalysisResourceModel, onClose }: GenerateDataAnalysisResourcePriorityScanRequestDialogProps) {
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.generateDataAnalysisResourcePriorityScanRequestDialog",
            () => ({
                dialog: {
                    description: "This {{translatedEntityTypeName}} has been moved to the top of the scanning queue, and results will be ready soon.",
                    failure: "On demand scan request failed",
                    success: "On-demand scan requested"
                },
                ok: "OK"
            }));

    useExecuteOperation(
        [GenerateDataAnalysisResourcePriorityScanRequestDialog, dataAnalysisResourceModel.id],
        async () => {
            await DataController.updateDataAnalysisResourcePriorityScanRequest(new Contract.DataControllerUpdateDataAnalysisResourcePriorityScanRequestRequest(dataAnalysisResourceModel.id));
            await entityModelStore.notify(dataAnalysisResourceModel.id);
        });

    const translatedEntityTypeName =
        entityTypeNameTranslator(
            dataAnalysisResourceModel.entity.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });

    const theme = useTheme();
    return (
        <Stack
            spacing={3.5}
            sx={{
                height: "100%",
                padding: theme.spacing(8.25, 7.5)
            }}>
            <Typography
                sx={{ textAlign: "center" }}
                variant="h1">
                {localization.dialog.success()}
            </Typography>
            <Typography sx={{ textAlign: "center" }}>
                {localization.dialog.description({ translatedEntityTypeName })}
            </Typography>
            <Stack
                alignItems="center"
                direction="row"
                justifyContent="center">
                <Button
                    size="large"
                    variant="contained"
                    onClick={onClose}>
                    {localization.ok()}
                </Button>
            </Stack>
        </Stack>);
}