import { map } from "@infrastructure";
import { Contract } from "../../../../../../../../../../../../../../../common";
import { GcpConsoleUrlBuilder } from "../../../../../../../../../../../../../../../tenants";
import { EntityExternalConsoleLinkDefinition } from "../useDefinition";

export function useGcpTenantEntityDefinition(entityModel: Contract.EntityModel, page: Contract.GcpConsolePage) {
    const getUrl =
        map(
            page,
            {
                [Contract.GcpConsolePage.ApiKeys]: () => GcpConsoleUrlBuilder.GetApiKeysKeysUrl,
                [Contract.GcpConsolePage.ApiLibrary]: () => GcpConsoleUrlBuilder.GetTenantEntityApiLibraryUrl,
                [Contract.GcpConsolePage.AuditLogs]: () => GcpConsoleUrlBuilder.GetTenantEntityAuditLogsUrl,
                [Contract.GcpConsolePage.Firewalls]: () => GcpConsoleUrlBuilder.GetComputeFirewallsUrl,
                [Contract.GcpConsolePage.LogMetrics]: () => GcpConsoleUrlBuilder.GetTenantEntityLogMetricsUrl,
                [Contract.GcpConsolePage.Metadata]: () => GcpConsoleUrlBuilder.GetComputeTenantMetadataUrl,
                [Contract.GcpConsolePage.Permissions]: () => GcpConsoleUrlBuilder.GetTenantEntityPermissionsUrl
            });

    return new EntityExternalConsoleLinkDefinition(getUrl((entityModel.entity as Contract.GcpTenantEntity).rawShortId));
}