import { useExecuteOperation, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { Contract, EntityController, InfoCard, InfoItem, VerticalTopItemsInfoItem } from "../../../../../../../../../../common";
import { AwsElbLoadBalancerListenersTable } from "../../../../../../../../../../tenants";
import { EntitiesInfoItem, Info } from "../../../../components";
import { NetworkingInfoCard } from "../../components";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";
import { useAwsCommonNetworkingInfoItemElements } from "./useAwsCommonNetworkingInfoItemElements";
import { useAwsDefaultResourceInfoItemElements } from "./useAwsDefaultResourceInfoItemElements";

export function useAwsElbApplicationLoadBalancerDefinition(resourceModel: Contract.AwsResourceModel, options?: DefinitionOptions) {
    const applicationLoadBalancer = resourceModel.entity as Contract.AwsElbApplicationLoadBalancer;
    const applicationLoadBalancerModel = resourceModel as Contract.AwsElbApplicationLoadBalancerModel;
    const defaultResourceInfoItemElements = useAwsDefaultResourceInfoItemElements(resourceModel);
    const entityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const [{ distributionIds }] =
        useExecuteOperation(
            [useAwsElbApplicationLoadBalancerDefinition, applicationLoadBalancerModel.id],
            async () => await EntityController.getAwsDistributionIds(new Contract.EntityControllerGetAwsDistributionIdsRequest(applicationLoadBalancerModel.id)));
    const commonNetworkCardInfoItems =
        useAwsCommonNetworkingInfoItemElements({
            networkInterfaceIds: applicationLoadBalancer.networkInterfaceIds,
            privateIpAddresses: applicationLoadBalancer.privateIpAddresses,
            publicIpAddresses: applicationLoadBalancer.publicIpAddresses,
            resourceModel
        });

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsElbApplicationLoadBalancerDefinition",
            () => ({
                info: {
                    cards: {
                        listeners: "Listeners"
                    },
                    items: {
                        accessLogsEnabled: {
                            false: "Disabled",
                            title: "Access Logs",
                            true: "Enabled"
                        },
                        autoScalingGroupIds: "Auto Scaling Groups",
                        availabilityZones: "Availability Zones",
                        cloudFrontDistributions: "CloudFront Distributions",
                        dnsName: "DNS Name",
                        dnsZoneIds: "DNS Zones",
                        external: {
                            false: "Off",
                            title: "Internet Facing",
                            true: "On"
                        },
                        vpc: "VPC",
                        webAcl: "WAF Web ACL"
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={entityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <VerticalTopItemsInfoItem
                        items={
                            _.map(
                                applicationLoadBalancer.availabilityZones,
                                availabilityZone => availabilityZone.name)}
                        key="availabilityZones"
                        title={localization.info.items.availabilityZones()}/>,
                    <InfoItem
                        key="internal"
                        title={localization.info.items.external.title()}
                        value={
                            applicationLoadBalancer.external
                                ? localization.info.items.external.true()
                                : localization.info.items.external.false()}/>,
                    <InfoItem
                        key="dnsName"
                        title={localization.info.items.dnsName()}
                        value={applicationLoadBalancer.dnsName}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={applicationLoadBalancerModel.dnsZoneIds}
                        entityTypeName={Contract.TypeNames.Entity}
                        key="dnsZoneIds"
                        title={localization.info.items.dnsZoneIds()}/>,
                    <InfoItem
                        key="accessLogsEnabled"
                        title={localization.info.items.accessLogsEnabled.title()}
                        value={
                            applicationLoadBalancer.accessLogsEnabled
                                ? localization.info.items.accessLogsEnabled.true()
                                : localization.info.items.accessLogsEnabled.false()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={applicationLoadBalancerModel.autoScalingGroupIds}
                        entityTypeName={Contract.TypeNames.AwsAutoScalingAutoScalingGroup}
                        key="autoScalingGroupIds"
                        title={localization.info.items.autoScalingGroupIds()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={applicationLoadBalancerModel.webAclId}
                        entityTypeName={Contract.TypeNames.AwsWafWebAcl}
                        key="wafWebAcl"
                        title={localization.info.items.webAcl()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={distributionIds}
                        entityTypeName={Contract.TypeNames.AwsCloudFrontDistribution}
                        key="cloudFrontDistributions"
                        title={localization.info.items.cloudFrontDistributions()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={applicationLoadBalancer.vpcIds}
                        entityTypeName={Contract.TypeNames.AwsEc2Vpc}
                        key="vpc"
                        location="miniGlance"
                        title={localization.info.items.vpc()}/>
                ]}
                options={options?.infoOptions}>
                <NetworkingInfoCard>
                    {commonNetworkCardInfoItems.inboundAccessType}
                    {commonNetworkCardInfoItems.inboundExternalAccessScope}
                    {commonNetworkCardInfoItems.getPrivateIpAddresses()}
                    {commonNetworkCardInfoItems.getPublicIpAddresses()}
                    {commonNetworkCardInfoItems.getVpcs()}
                    {commonNetworkCardInfoItems.getSubnets()}
                    {commonNetworkCardInfoItems.getNetworkInterfaces()}
                    {commonNetworkCardInfoItems.getSecurityGroups()}
                </NetworkingInfoCard>
                <InfoCard title={localization.info.cards.listeners()}>
                    <AwsElbLoadBalancerListenersTable
                        loadBalancerListenerIds={applicationLoadBalancerModel.listenerIds}
                        targetGroupIds={applicationLoadBalancerModel.targetGroupIds}/>
                </InfoCard>
            </Info>
    });
}