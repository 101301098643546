﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract } from "../../../../../../../../../../common";
import { EntitiesInfoItem } from "../../../../components";

export function useOpCommonKubernetesWorkloadResourceInfoItemElements(workloadResourceModel: Contract.OpKubernetesWorkloadResourceModel) {
    const workloadResource = workloadResourceModel.entity as Contract.OpKubernetesWorkloadResourceBase;

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.op.useOpCommonKubernetesWorkloadResourceInfoItemElements",
            () => ({
                controllerResourceReference: "Owner",
                serviceAccountIdReference: "Service Account"
            }));
    return {
        controllerResourceInfoItemElement:
            _.isNil(workloadResourceModel.controllerResourceReference)
                ? undefined
                : <EntitiesInfoItem
                    entityIdsOrModels={workloadResourceModel.controllerResourceReference.idReference}
                    entityTypeName={Contract.TypeNames.OpKubernetesResource}
                    key="controllerResourceReference"
                    location="all"
                    title={localization.controllerResourceReference()}/>,
        serviceAccountInfoItemElement:
            <EntitiesInfoItem
                entityIdsOrModels={workloadResource.data.podTemplateSpec.podSpec.serviceAccountReference.idReference}
                entityTypeName={Contract.TypeNames.OpKubernetesServiceAccount}
                key="serviceAccountIdReference"
                title={localization.serviceAccountIdReference()}/>
    };
}