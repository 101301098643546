import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, DatadogIcon, EntityIcon, QRadarIcon, scopeNodeModelStore, scopeSystemEntityModelStore, SplunkIcon, useScopeNavigationViewContext } from "../../../../../../../../../../common";
import { IntegrationItem, IntegrationItemCategory } from "../../../../utilities";
import { Datadog, QRadar, Splunk, Sqs } from "./components";

export function useObservabilityItems(childScopeEnabled: boolean): IntegrationItem[] {
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useObservabilityItems",
            () => ({
                description: {
                    childScope: "{{count | NumberFormatter.humanize}} configured (child scopes)",
                    title: "{{count | NumberFormatter.humanize}} configured"
                },
                [Contract.TypeNames.IntegrationType]: {
                    [Contract.IntegrationType.Datadog]: "Datadog",
                    [Contract.IntegrationType.QRadar]: "QRadar",
                    [Contract.IntegrationType.Splunk]: "Splunk",
                    [Contract.IntegrationType.Sqs]: "SQS"
                }
            }));

    const { scopeNodeModel } = useScopeNavigationViewContext();
    const scopeNodeMap = scopeNodeModelStore.useGetActiveScopeNodeMap();
    const childScopeIds =
        useMemo(
            () => scopeNodeMap[scopeNodeModel.configuration.id].childScopeIds,
            [scopeNodeModel.configuration.id, scopeNodeMap]);
    const parentScopeIds =
        useMemo(
            () => scopeNodeMap[scopeNodeModel.configuration.id].parentScopeIds,
            [scopeNodeModel.configuration.id, scopeNodeMap]);

    const datadogInstanceModels = scopeSystemEntityModelStore.useGetDatadog();
    const qRadarInstanceModels = scopeSystemEntityModelStore.useGetQRadar();
    const splunkInstanceModels = scopeSystemEntityModelStore.useGetSplunk();
    const sqsInstanceModels = scopeSystemEntityModelStore.useGetSqs();
    const [filteredChildScopeDatadogInstanceModels, filteredChildScopeQRadarInstanceModels, filteredChildScopeSplunkInstanceModels, filteredChildScopeSqsInstanceModels] =
        useMemo(
            () =>
                childScopeEnabled
                    ? [
                        _.filter(
                            datadogInstanceModels,
                            datadogInstanceModel =>
                                _.includes(
                                    childScopeIds,
                                    datadogInstanceModel.configuration.scopeId)),
                        _.filter(
                            qRadarInstanceModels,
                            qRadarInstanceModel =>
                                _.includes(
                                    childScopeIds,
                                    qRadarInstanceModel.configuration.scopeId)),
                        _.filter(
                            splunkInstanceModels,
                            splunkInstanceModel =>
                                _.includes(
                                    childScopeIds,
                                    splunkInstanceModel.configuration.scopeId)),
                        _.filter(
                            sqsInstanceModels,
                            sqsInstanceModel =>
                                _.includes(
                                    childScopeIds,
                                    sqsInstanceModel.configuration.scopeId))]
                    : [[], [], [], []],
            [childScopeIds, childScopeEnabled, datadogInstanceModels, qRadarInstanceModels, splunkInstanceModels, sqsInstanceModels]);
    const [filteredDatadogInstanceModels, filteredQRadarInstanceModels, filteredSplunkInstanceModels, filteredSqsInstanceModels] =
        useMemo(
            () =>
                [
                    _.filter(
                        datadogInstanceModels,
                        datadogInstanceModel =>
                            _(parentScopeIds).
                                concat(scopeNodeModel.configuration.id).
                                includes(datadogInstanceModel.configuration.scopeId)),
                    _.filter(
                        qRadarInstanceModels,
                        qRadarInstanceModel =>
                            _(parentScopeIds).
                                concat(scopeNodeModel.configuration.id).
                                includes(qRadarInstanceModel.configuration.scopeId)),
                    _.filter(
                        splunkInstanceModels,
                        splunkInstanceModel =>
                            _(parentScopeIds).
                                concat(scopeNodeModel.configuration.id).
                                includes(splunkInstanceModel.configuration.scopeId)),
                    _.filter(
                        sqsInstanceModels,
                        sqsInstanceModel =>
                            _(parentScopeIds).
                                concat(scopeNodeModel.configuration.id).
                                includes(sqsInstanceModel.configuration.scopeId))
                ],
            [datadogInstanceModels, parentScopeIds, qRadarInstanceModels, splunkInstanceModels, sqsInstanceModels]);

    return [
        new IntegrationItem(
            IntegrationItemCategory.Observability,
            _.concat(filteredChildScopeDatadogInstanceModels, filteredDatadogInstanceModels),
            _.concatIf(
                [localization.description.title({ count: filteredDatadogInstanceModels.length })],
                childScopeEnabled && filteredChildScopeDatadogInstanceModels.length > 0,
                localization.description.childScope({ count: filteredChildScopeDatadogInstanceModels.length })),
            <DatadogIcon/>,
            localization[Contract.TypeNames.IntegrationType][Contract.IntegrationType.Datadog](),
            Contract.IntegrationType.Datadog,
            <Datadog/>),
        new IntegrationItem(
            IntegrationItemCategory.Observability,
            _.concat(filteredChildScopeQRadarInstanceModels, filteredQRadarInstanceModels),
            _.concatIf(
                [localization.description.title({ count: filteredQRadarInstanceModels.length })],
                childScopeEnabled && filteredChildScopeQRadarInstanceModels.length > 0,
                localization.description.childScope({ count: filteredChildScopeQRadarInstanceModels.length })),
            <QRadarIcon/>,
            localization[Contract.TypeNames.IntegrationType][Contract.IntegrationType.QRadar](),
            Contract.IntegrationType.QRadar,
            <QRadar/>),
        new IntegrationItem(
            IntegrationItemCategory.Observability,
            _.concat(filteredChildScopeSplunkInstanceModels, filteredSplunkInstanceModels),
            _.concatIf(
                [localization.description.title({ count: filteredSplunkInstanceModels.length })],
                childScopeEnabled && filteredChildScopeSplunkInstanceModels.length > 0,
                localization.description.childScope({ count: filteredChildScopeSplunkInstanceModels.length })),
            <SplunkIcon/>,
            localization[Contract.TypeNames.IntegrationType][Contract.IntegrationType.Splunk](),
            Contract.IntegrationType.Splunk,
            <Splunk/>),
        new IntegrationItem(
            IntegrationItemCategory.Observability,
            _.concat(filteredChildScopeSqsInstanceModels, filteredSqsInstanceModels),
            _.concatIf(
                [localization.description.title({ count: filteredSqsInstanceModels.length })],
                childScopeEnabled && filteredChildScopeSqsInstanceModels.length > 0,
                localization.description.childScope({ count: filteredChildScopeSqsInstanceModels.length })),
            <EntityIcon entityTypeName={Contract.TypeNames.AwsSqsQueue}/>,
            localization[Contract.TypeNames.IntegrationType][Contract.IntegrationType.Sqs](),
            Contract.IntegrationType.Sqs,
            <Sqs/>)
    ];
}