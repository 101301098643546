import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function ExclusionIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                clipRule="evenodd"
                d="M3.875 8.875V20.125H15.125V8.875H3.875ZM3.60714 7C2.71954 7 2 7.71954 2 8.60714V20.3929C2 21.2805 2.71954 22 3.60714 22H15.3929C16.2805 22 17 21.2805 17 20.3929V8.60714C17 7.71954 16.2805 7 15.3929 7H3.60714Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M8.875 3.875V15.125H20.125V3.875H8.875ZM8.60714 2C7.71954 2 7 2.71954 7 3.60714V15.3929C7 16.2805 7.71954 17 8.60714 17H20.3929C21.2805 17 22 16.2805 22 15.3929V3.60714C22 2.71954 21.2805 2 20.3929 2H8.60714Z"
                fillRule="evenodd"/>
        </SvgIcon>);
}