import { Tooltip, TooltipProps } from "@infrastructure";
import { Box, Stack, SxProps } from "@mui/material";
import React, { ReactNode } from "react";

type IconTextProps = {
    containerSx?: SxProps;
    direction?: "rtl" | "ltr";
    icon: ReactNode;
    iconSx?: SxProps;
    onClick?: () => void;
    spacing?: number;
    text: ReactNode;
    textSx?: SxProps;
    tooltipSx?: SxProps;
    tooltipVariant?: "icon" | "iconText";
} & Pick<TooltipProps, "titleOrGetTitle" | "placement" | "disableInteractive">;

export function IconText({ containerSx, direction, disableInteractive, icon, iconSx, onClick, placement, spacing, text, textSx, titleOrGetTitle, tooltipSx, tooltipVariant = "icon" }: IconTextProps) {
    return (
        <Tooltip
            disableInteractive={disableInteractive}
            placement={placement}
            sx={tooltipSx}
            titleOrGetTitle={
                tooltipVariant === "iconText"
                    ? titleOrGetTitle
                    : undefined}>
            <Stack
                alignItems="center"
                direction={
                    direction === "rtl"
                        ? "row-reverse"
                        : "row"}
                justifyContent={
                    direction === "rtl"
                        ? "flex-end"
                        : undefined}
                spacing={spacing ?? 1}
                sx={{
                    maxWidth: "fit-content",
                    overflow: "hidden",
                    ...containerSx
                }}
                onClick={onClick}>
                <Tooltip
                    titleOrGetTitle={
                        tooltipVariant === "icon"
                            ? titleOrGetTitle
                            : undefined}>
                    <Box
                        sx={{
                            overflow: "hidden",
                            ...iconSx
                        }}>
                        {icon}
                    </Box>
                </Tooltip>
                <Box
                    sx={{
                        flex: 1,
                        overflow: "hidden",
                        ...textSx
                    }}>
                    {text}
                </Box>
            </Stack>
        </Tooltip>);
}