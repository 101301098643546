import { DataTableColumn, EnumValuesFilter, useLocalization } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { useAzureCommonComputeDiskResourceDefinition } from ".";
import { EntityTableDefinition } from "../..";
import { Contract, ItemSelectionHelper, TimeRangeHelper } from "../../../../../../../../../../common";
import { useAzureComputeDiskSnapshotTypeTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";

export function useAzureComputeDiskSnapshotDefinition(definitionData: DefinitionData) {
    const commonComputeDiskResourceDefinition = useAzureCommonComputeDiskResourceDefinition(definitionData);

    const computeDiskSnapshotTypeTranslator = useAzureComputeDiskSnapshotTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.azure.useAzureComputeDiskSnapshotDefinition",
            () => ({
                columns: {
                    type: "Snapshot Type"
                }
            }));

    return new EntityTableDefinition(
        _.filter([
            commonComputeDiskResourceDefinition.columns.tenantColumn,
            commonComputeDiskResourceDefinition.columns.creationTimeColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AzureComputeDiskSnapshotModel) => ({
                            [localization.columns.type()]: computeDiskSnapshotTypeTranslator((item.entity as Contract.AzureComputeDiskSnapshot).type)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.AzureComputeDiskSnapshotType}
                                enumTypeTranslator={computeDiskSnapshotTypeTranslator}
                                placeholder={localization.columns.type()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AzureComputeDiskSnapshotType}
                itemProperty={(item: Contract.AzureComputeDiskSnapshotModel) => computeDiskSnapshotTypeTranslator((item.entity as Contract.AzureComputeDiskSnapshot).type)}
                key={Contract.EntityModelProperty.AzureComputeDiskSnapshotType}
                title={localization.columns.type()}/>,
            commonComputeDiskResourceDefinition.columns.networkAccessPolicyType,
            commonComputeDiskResourceDefinition.columns.diskResourceSourceColumn,
            commonComputeDiskResourceDefinition.columns.regionColumn,
            commonComputeDiskResourceDefinition.columns.regionLocationColumn,
            commonComputeDiskResourceDefinition.columns.resourceGroupColumn,
            commonComputeDiskResourceDefinition.columns.tagsColumn,
            commonComputeDiskResourceDefinition.columns.attributesColumn,
            commonComputeDiskResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonComputeDiskResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonComputeDiskResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ]),
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAzureComputeDiskSnapshotRequest(
                new Contract.EntityControllerGetEntityModelPageAzureComputeDiskSnapshotRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureResourceGroupResourceResourceGroup]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureComputeDiskResourceNetworkAccessPolicyType]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureComputeDiskResourceSource]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureComputeDiskSnapshotType])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags]))));
}