import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useOciFssFileSystemStatusTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useOciFssFileSystemStatusTranslator",
            () => ({
                [Contract.TypeNames.OciFssFileSystemStatus]: {
                    [Contract.OciFssFileSystemStatus.Active]: "Active",
                    [Contract.OciFssFileSystemStatus.Creating]: "Creating",
                    [Contract.OciFssFileSystemStatus.Deleted]: "Deleted",
                    [Contract.OciFssFileSystemStatus.Deleting]: "Deleting",
                    [Contract.OciFssFileSystemStatus.Failed]: "Failed"
                }
            }));
    return (status: Contract.OciFssFileSystemStatus) =>
        localization[Contract.TypeNames.OciFssFileSystemStatus][status]();
}