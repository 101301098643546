import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, scopeNodeModelStore, scopeSystemEntityModelStore, useScopeNavigationViewContext, WebhookIcon } from "../../../../../../../../../../common";
import { IntegrationItem, IntegrationItemCategory } from "../../../../utilities";
import { Webhook } from "./components";

export function useWebhooksItems(childScopeEnabled: boolean): IntegrationItem[] {
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useWebhooksItems",
            () => ({
                description: {
                    childScope: "{{count | NumberFormatter.humanize}} configured (child scopes)",
                    title: "{{count | NumberFormatter.humanize}} configured"
                },
                [Contract.TypeNames.IntegrationType]: {
                    [Contract.IntegrationType.Webhook]: "Webhook"
                }
            }));

    const { scopeNodeModel } = useScopeNavigationViewContext();
    const scopeNodeMap = scopeNodeModelStore.useGetActiveScopeNodeMap();
    const childScopeIds =
        useMemo(
            () => scopeNodeMap[scopeNodeModel.configuration.id].childScopeIds,
            [scopeNodeModel.configuration.id, scopeNodeMap]);
    const parentScopeIds =
        useMemo(
            () => scopeNodeMap[scopeNodeModel.configuration.id].parentScopeIds,
            [scopeNodeModel.configuration.id, scopeNodeMap]);
    const webhookInstanceModels = scopeSystemEntityModelStore.useGetWebhook();
    const filteredChildScopeWebhookInstanceModels =
        useMemo(
            () =>
                childScopeEnabled
                    ? _.filter(
                        webhookInstanceModels,
                        webhookInstanceModel =>
                            _.includes(
                                childScopeIds,
                                webhookInstanceModel.configuration.scopeId))
                    : [],
            [childScopeEnabled, childScopeIds, webhookInstanceModels, parentScopeIds]);
    const filteredWebhookInstanceModels =
        useMemo(
            () =>
                _.filter(
                    webhookInstanceModels,
                    webhookInstanceModel =>
                        _(parentScopeIds).
                            concat(scopeNodeModel.configuration.id).
                            includes(webhookInstanceModel.configuration.scopeId)),
            [childScopeEnabled, childScopeIds, webhookInstanceModels, parentScopeIds]);
    return [
        new IntegrationItem(
            IntegrationItemCategory.Webhooks,
            _.concat(filteredWebhookInstanceModels, filteredChildScopeWebhookInstanceModels),
            _.concatIf(
                [localization.description.title({ count: filteredWebhookInstanceModels.length })],
                childScopeEnabled && filteredChildScopeWebhookInstanceModels.length > 0,
                localization.description.childScope({ count: filteredChildScopeWebhookInstanceModels.length })),
            <WebhookIcon/>,
            localization[Contract.TypeNames.IntegrationType][Contract.IntegrationType.Webhook](),
            Contract.IntegrationType.Webhook,
            <Webhook/>)];
}