import { useLocalization } from "@infrastructure";
import React from "react";
import { useGcpDefaultResourceInfoItemElements, useGcpDefaultScopeResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../..";
import { Contract, InfoItem, TypeHelper } from "../../../../../../../../../../common";
import { useGcpComputeTargetProxyTypeTranslator } from "../../../../../../hooks";
import { EntitiesInfoItem, Info } from "../../../../components";
import { GcpScopeResourceRoleBindings } from "../../components";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";
import { useGcpCommonComputeLoadBalancerResourceInfoItemElements } from "./useGcpCommonComputeLoadBalancerResourceInfoItemElements";

export function useGcpComputeTargetProxyDefinition(scopeResourceModel: Contract.GcpScopeResourceModel, options?: DefinitionOptions) {
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(scopeResourceModel);
    const defaultResourceInfoItemElements = useGcpDefaultResourceInfoItemElements(scopeResourceModel);
    const defaultScopeResourceInfoItemElements = useGcpDefaultScopeResourceInfoItemElements(scopeResourceModel);
    const targetProxyModel = scopeResourceModel as Contract.GcpComputeTargetProxyModel;
    const targetProxy = targetProxyModel.entity as Contract.GcpComputeTargetProxy;
    const commonComputeLoadBalancerResourceInfoItemElements = useGcpCommonComputeLoadBalancerResourceInfoItemElements(targetProxyModel);

    const computeTargetProxyTypeTranslator = useGcpComputeTargetProxyTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpComputeTargetProxyDefinition",
            () => ({
                info: {
                    items: {
                        forwardingRuleIds: "Forwarding Rules",
                        sslCertificateIds: "SSL Certificates",
                        sslPolicyId: {
                            default: "Default",
                            title: "SSL Policy"
                        },
                        targetResourceIdReference: "Target",
                        type: "Proxy Type"
                    }
                },
                tabs: {
                    resourceRoleBindings: "Resource Role Bindings"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <GcpScopeResourceRoleBindings
                    csvExportFilePrefix={localization.tabs.resourceRoleBindings()}
                    scopeResourceModel={scopeResourceModel}/>,
                localization.tabs.resourceRoleBindings(),
                "resourceRoleBindings")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    ...defaultScopeResourceInfoItemElements,
                    <InfoItem
                        key="type"
                        title={localization.info.items.type()}
                        value={computeTargetProxyTypeTranslator(targetProxy.type)}/>,
                    commonComputeLoadBalancerResourceInfoItemElements.loadBalancerIdsInfoItemElement,
                    <EntitiesInfoItem
                        entityIdsOrModels={targetProxyModel.forwardingRuleIds}
                        entityTypeName={Contract.TypeNames.GcpComputeForwardingRule}
                        key="forwardingRuleIds"
                        title={localization.info.items.forwardingRuleIds()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={targetProxyModel.targetResourceIdReference}
                        entityTypeName={Contract.TypeNames.GcpScopeResource}
                        key="targetResourceIdReference"
                        title={localization.info.items.targetResourceIdReference()}/>,
                    TypeHelper.extendOrImplement(
                        targetProxyModel.entity.typeName,
                        Contract.TypeNames.GcpComputeTargetHttpsProxy,
                        Contract.TypeNames.GcpComputeTargetSslProxy)
                        ? <EntitiesInfoItem
                            entityIdsOrModels={(targetProxyModel as Contract.GcpComputeTargetHttpsProxyModel | Contract.GcpComputeTargetSslProxyModel).sslCertificateIds}
                            entityTypeName={Contract.TypeNames.GcpComputeSslCertificate}
                            key="sslCertificateIds"
                            title={localization.info.items.sslCertificateIds()}/>
                        : undefined,
                    TypeHelper.extendOrImplement(
                        targetProxyModel.entity.typeName,
                        Contract.TypeNames.GcpComputeTargetHttpsProxy,
                        Contract.TypeNames.GcpComputeTargetSslProxy)
                        ? <EntitiesInfoItem
                            entityIdsOrModels={
                                (targetProxyModel as Contract.GcpComputeTargetHttpsProxyModel | Contract.GcpComputeTargetSslProxyModel).sslPolicyId ??
                                localization.info.items.sslPolicyId.default()}
                            entityTypeName={Contract.TypeNames.GcpComputeSslPolicy}
                            key="sslPolicyId"
                            title={localization.info.items.sslPolicyId.title()}/>
                        : undefined
                ]}
                options={options?.infoOptions}>
            </Info>
    });
}