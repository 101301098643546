import { DataTableColumn, DataTableColumnRenderProps, InlineItems, PagedValuesFilter, useExecuteOperation, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, ElasticsearchItemPageHelper, ItemSelectionHelper, NetworkScopeFormatter, RiskController, RiskType } from "../../../../../../../../../../../../../../../../../../../../../common";
import { useItemsContext, useTableCommonCloudColumns } from "../../../../../../../../../../../../../../../hooks";
import { TableColumnId } from "../../../../../../../../../../../../../../Table";
import { useCommonColumns } from "../../../../../../useCommonColumns";
import { useCommonCloudRiskPolicyTableDefinition } from "../../useCommonCloudRiskPolicyTableDefinition";

export function useNetworkInboundExternalResourceRiskDefinition(riskPolicyConfigurationTypeName: string) {
    const { requestFilters } = useItemsContext();
    const [{ filters }] =
        useExecuteOperation(
            [useNetworkInboundExternalResourceRiskDefinition, riskPolicyConfigurationTypeName],
            () =>
                RiskController.getRiskPolicyTypeGroupFilters(
                    new Contract.RiskControllerGetRiskPolicyTypeGroupCloudRiskFiltersRequest(
                        requestFilters!,
                        riskPolicyConfigurationTypeName)));

    const localization =
        useLocalization(
            "views.customer.risks.items.grouped.item.table.hooks.useDefinition.hooks.useCloudDefinition.hooks.useRiskPolicyTypeDefinition.hooks.useNetworkDefinition.hooks.useNetworkInboundExternalResourceRiskDefinition",
            () => ({
                columns: {
                    destinationNetworkScopes: "Exposed Ports",
                    sourceSubnets: "Exposed IP Address Ranges"
                }
            }));

    const networkInboundExternalResourceRiskPolicyGroupFilters = filters as Contract.NetworkInboundExternalResourceRiskPolicyGroupFilters;
    const commonColumns = useCommonColumns();
    const commonCloudColumns = useTableCommonCloudColumns();

    return useCommonCloudRiskPolicyTableDefinition({
        columns:
            _.filter([
                commonColumns.statusUpdateTimeColumn,
                commonColumns.severityColumn,
                commonColumns.riskPolicyIdColumn,
                commonCloudColumns.descriptionColumn,
                commonColumns.tenantIdColumn,
                commonColumns.riskedEntityIdColumn(
                    riskPolicyConfigurationTypeName,
                    requestFilters as Contract.RiskControllerCloudRiskModelFilters),
                commonColumns.resourceOwnerColumn,
                commonColumns.resourceEnvironmentColumn,
                <DataTableColumn
                    cellMaxWidth="medium"
                    filterOptions={{
                        itemOrItems: {
                            element:
                            <ValuesFilter
                                groupItemTitle={true}
                                placeholder={localization.columns.destinationNetworkScopes()}>
                                {_.map(
                                    networkInboundExternalResourceRiskPolicyGroupFilters.destinationNetworkScopeFilterIdToDestinationNetworkScopeMap,
                                    (destinationNetworkScope, filterId) =>
                                        <ValuesFilterItem
                                            key={filterId}
                                            title={NetworkScopeFormatter.networkScopeFromDestinationNetworkScope(destinationNetworkScope)}
                                            value={filterId}/>)}
                            </ValuesFilter>
                        }
                    }}
                    id={TableColumnId.NetworkInboundExternalResourceRiskDestinationNetworkScopes}
                    key={TableColumnId.NetworkInboundExternalResourceRiskDestinationNetworkScopes}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.INetworkInboundExternalResourceRiskModel>) =>
                            <InlineItems
                                items={
                                    _(item.destinationNetworkScopes).
                                        map(networkScope => NetworkScopeFormatter.networkScopeFromDestinationNetworkScope(networkScope)).
                                        sort().
                                        value()}
                                variant="itemPlusItemCount"/>}
                    sortOptions={{ enabled: false }}
                    title={localization.columns.destinationNetworkScopes()}/>,
                <DataTableColumn
                    cellMaxWidth="medium"
                    filterOptions={{
                        itemOrItems: {
                            element:
                            <PagedValuesFilter
                                getValuePage={
                                    ElasticsearchItemPageHelper.makePagedRiskFilterValueItem(
                                        requestFilters as Contract.RiskControllerCloudRiskModelFilters,
                                        Contract.RiskControllerGetRiskPolicyTypeGroupFilterItemPageRequestProperty.NetworkInboundExternalResourceRiskSourceSubnets,
                                        riskPolicyConfigurationTypeName)}
                                placeholder={localization.columns.sourceSubnets()}/>
                        }
                    }}
                    id={TableColumnId.NetworkInboundExternalResourceRiskSourceSubnets}
                    key={TableColumnId.NetworkInboundExternalResourceRiskSourceSubnets}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.INetworkInboundExternalResourceRiskModel>) =>
                            <InlineItems
                                items={item.sourceSubnets}
                                variant="itemPlusItemCount"/>}
                    sortOptions={{ enabled: false }}
                    title={localization.columns.sourceSubnets()}/>,
                commonColumns.statusColumn(true),
                commonColumns.subStatusColumn(true),
                commonColumns.ignoreExpirationDateColumn,
                commonColumns.openStatusUpdateTimeColumn,
                commonColumns.systemCreationTimeColumn,
                commonColumns.riskedEntityAttributesColumn,
                commonColumns.actionsCell(RiskType.Cloud)
            ]),
        getGroupFilters:
            filterMap =>
                new Contract.RiskControllerNetworkInboundExternalResourceRiskPolicyTypeFilters(
                    riskPolicyConfigurationTypeName,
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[TableColumnId.RiskedEntityIds]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[TableColumnId.NetworkInboundExternalResourceRiskDestinationNetworkScopes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[TableColumnId.NetworkInboundExternalResourceRiskSourceSubnets])),
        getRiskIdToScopesIdentityPermissionsMapRequest:
            requestFilters =>
                new Contract.RiskControllerGetRiskPolicyTypeGroupCloudRiskIdToScopeIdentityPermissionsMapRequest(
                    requestFilters,
                    riskPolicyConfigurationTypeName)
    });
}