import { map, Optional, Tooltip, useLocalization } from "@infrastructure";
import { Avatar, Box, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { Fragment, ReactNode, useMemo } from "react";
import { Contract, DeliveryIcon, InfoCard, InfoItem, scopeSystemEntityModelStore, SlackConversationReferenceHelper, slackWorkspaceChannelOperationStore, TeamsChannelReferenceHelper, TopItems, useDeliveryTranslator, useTheme, VerticalTopItems } from "..";

export type InlineDeliveriesProps = {
    deliveries: Contract.Delivery[];
    variant?: "groupedList" | "icon";
};

export function InlineDeliveries({ deliveries, variant = "icon" }: InlineDeliveriesProps) {
    const workspaceIdToChannelRawIdToNameMap = slackWorkspaceChannelOperationStore.useGet();
    const scopeSystemEntityModels = scopeSystemEntityModelStore.useGetAll();
    const deliveryTranslator = useDeliveryTranslator();
    const localization =
        useLocalization(
            "common.inlineDeliveries",
            () => ({
                integration: {
                    deleted: "{{ name }} integration has been deleted",
                    icon: {
                        [Contract.DeliveryDerivedTypeNames.TeamsDelivery]: {
                            organization: "{{ teamName }} | {{ name }}"
                        }
                    },
                    owner: "Mail per resource owner"
                }
            }));

    function getDeletedDeliveryElement(typeName: Contract.DeliveryDerivedTypeNames) {
        return (
            <Typography
                noWrap={true}>
                {localization.integration.deleted({ name: deliveryTranslator(typeName, "title").text })}
            </Typography>);
    }

    const theme = useTheme();
    const items =
        useMemo(
            () => {
                const scopeSystemEntityModelMap =
                    _.keyBy(
                        scopeSystemEntityModels,
                        scopeSystemEntityModel => scopeSystemEntityModel.configuration.id);
                const deliveryTypeNameToDeliveryMap =
                    _.groupBy(
                        deliveries,
                        delivery => delivery.typeName);
                const slackWorkspaceModels =
                    _.filter(
                        scopeSystemEntityModels,
                        scopeSystemEntityModel => scopeSystemEntityModel.configuration.typeName === Contract.TypeNames.SlackWorkspaceConfiguration) as Contract.SlackWorkspaceModel[];
                const slackChannelReferenceIdToNameMap =
                    _(slackWorkspaceModels).
                        flatMap(
                            slackWorkspaceModel =>
                                _.flatMap(
                                    workspaceIdToChannelRawIdToNameMap[slackWorkspaceModel.id],
                                    (channelName, channelRawId) => ({
                                        channelName,
                                        channelReferenceId:
                                            SlackConversationReferenceHelper.getId(
                                                new Contract.SlackConversationReference(
                                                    channelRawId,
                                                    slackWorkspaceModel.configuration.id))
                                    }))).
                        keyBy(({ channelReferenceId }) => channelReferenceId).
                        mapValues(({ channelName }) => channelName).
                        value();
                const teamsOrganizationModels =
                    _.filter(
                        scopeSystemEntityModels,
                        scopeSystemEntityModel => scopeSystemEntityModel.configuration.typeName === Contract.TypeNames.TeamsOrganizationConfiguration);
                const teamsChannelReferenceIdToNameMap =
                    _(teamsOrganizationModels).
                        flatMap(
                            teamsOrganizationModel =>
                                _.flatMap(
                                    (teamsOrganizationModel.state as Contract.TeamsOrganizationState).teamRawIdToChannelReferencesMap,
                                    (channelReferences, teamRawId) =>
                                        _.map(
                                            channelReferences,
                                            channelReference => ({
                                                channelName: (teamsOrganizationModel.state as Contract.TeamsOrganizationState).channelRawIdToNameMap[channelReference.rawId],
                                                channelReferenceId: TeamsChannelReferenceHelper.getId(channelReference),
                                                teamName: (teamsOrganizationModel.state as Contract.TeamsOrganizationState).teamRawIdToNameMap[teamRawId]
                                            })))).
                        keyBy(({ channelReferenceId }) => channelReferenceId).
                        value();

                function createItem<TDelivery extends Contract.Delivery>(
                    deliveryTypeNameOrNames: string | string[],
                    getNames: (delivery: TDelivery) => string[],
                    getNameElements?: (delivery: TDelivery) => ReactNode): Optional<IntegrationsItem> {
                    const deliveryTypeNames = _.concat(deliveryTypeNameOrNames);
                    const deliveries =
                        _(deliveryTypeNames).
                            flatMap(deliveryTypeName => deliveryTypeNameToDeliveryMap[deliveryTypeName] as TDelivery[]).
                            filter(item => !_.isNil(item)).
                            value();

                    return _.isEmpty(deliveries)
                        ? undefined
                        : {
                            deliveryTypeName: deliveryTypeNames[0],
                            icon:
                                <DeliveryIcon
                                    sx={{ color: theme.palette.text.primary }}
                                    typeName={deliveryTypeNames[0] as Contract.DeliveryDerivedTypeNames}/>,
                            nameElements:
                                _.isNil(getNameElements)
                                    ? undefined
                                    : _.flatMap(
                                        deliveries,
                                        getNameElements),
                            names:
                                _(deliveries).
                                    flatMap(getNames).
                                    uniq().
                                    value()
                        };
                }

                return _.filter([
                    createItem<Contract.DatadogDelivery>(
                        Contract.DeliveryDerivedTypeNames.DatadogDelivery,
                        datadogDelivery =>
                            _.map(
                                datadogDelivery.destinations,
                                datadogDestination => (scopeSystemEntityModelMap[datadogDestination.organizationId].configuration as Contract.DatadogOrganizationConfiguration).name)),
                    createItem<Contract.JiraDelivery>(
                        Contract.DeliveryDerivedTypeNames.JiraDelivery,
                        jiraDelivery =>
                            _.map(
                                jiraDelivery.destinations,
                                jiraDestination => ((scopeSystemEntityModelMap[jiraDestination.instanceId!]).state as Contract.JiraInstanceState).projectRawIdToProjectMap[jiraDestination.issueCreationData.projectRawId].project.name)),
                    ...(variant === "icon"
                        ? [
                            createItem<Contract.MailDelivery | Contract.ResourceOwnerMailDelivery>(
                                [Contract.DeliveryDerivedTypeNames.ResourceOwnerMailDelivery, Contract.DeliveryDerivedTypeNames.MailDelivery],
                                mailDelivery =>
                                    _<string>([]).
                                        concatIf(
                                            _.isEmpty(mailDelivery.destinations) &&
                                            !_.isNil((mailDelivery as Contract.ResourceOwnerMailDelivery)?.ccMails),
                                            localization.integration.owner()).
                                        concat(
                                            _.flatMap(
                                                mailDelivery.destinations,
                                                mailDestination => mailDestination.mail)).
                                        concat((mailDelivery as Contract.ResourceOwnerMailDelivery)?.ccMails).
                                        filter().
                                        value())
                        ]
                        : [
                            createItem<Contract.ResourceOwnerMailDelivery>(
                                Contract.DeliveryDerivedTypeNames.ResourceOwnerMailDelivery,
                                mailDelivery =>
                                    _.map(
                                        mailDelivery.destinations,
                                        mailDestination => mailDestination.mail)),
                            createItem<Contract.MailDelivery>(
                                Contract.DeliveryDerivedTypeNames.MailDelivery,
                                mailDelivery =>
                                    _.map(
                                        mailDelivery.destinations,
                                        mailDestination => mailDestination.mail))
                        ]),
                    createItem<Contract.QRadarDelivery>(
                        Contract.DeliveryDerivedTypeNames.QRadarDelivery,
                        qRadarDelivery =>
                            _.map(
                                qRadarDelivery.destinations,
                                qRadarDestination => (scopeSystemEntityModelMap[qRadarDestination.serverId].configuration as Contract.QRadarServerConfiguration)!.name)),
                    createItem<Contract.ServiceNowDelivery>(
                        Contract.DeliveryDerivedTypeNames.ServiceNowDelivery,
                        serviceNowDelivery =>
                            _.map(
                                serviceNowDelivery.destinations,
                                serviceNowDestination => (scopeSystemEntityModelMap[serviceNowDestination.instanceId].configuration as Contract.ServiceNowInstanceConfiguration).name)),
                    createItem<Contract.SlackDelivery>(
                        Contract.DeliveryDerivedTypeNames.SlackDelivery,
                        slackDelivery =>
                            _.map(
                                slackDelivery.destinations,
                                slackDestination => slackChannelReferenceIdToNameMap[SlackConversationReferenceHelper.getId(slackDestination.slackChannelReference)] ?? "******"),
                        slackDelivery =>
                            _.isEmpty(slackDelivery.destinations)
                                ? getDeletedDeliveryElement(slackDelivery.typeName as Contract.DeliveryDerivedTypeNames)
                                : _.map(
                                    slackDelivery.destinations,
                                    slackDestination =>
                                        <InfoCard
                                            columnContainerSx={{ paddingRight: 0 }}
                                            itemContainerSx={{ padding: 0 }}
                                            key={slackDestination.integrationId}>
                                            {_.isNil(slackChannelReferenceIdToNameMap[SlackConversationReferenceHelper.getId(slackDestination.slackChannelReference)])
                                                ? getDeletedDeliveryElement(slackDelivery.typeName as Contract.DeliveryDerivedTypeNames)
                                                : <InfoItem
                                                    title={
                                                        _.find(
                                                            slackWorkspaceModels,
                                                            slackWorkspaceModel => slackWorkspaceModel.configuration.id === slackDestination.slackChannelReference.workspaceId)?.configuration?.name ?? "******"}
                                                    value={slackChannelReferenceIdToNameMap[SlackConversationReferenceHelper.getId(slackDestination.slackChannelReference)] ?? "******"}/>}
                                        </InfoCard>)),
                    createItem<Contract.SplunkDelivery>(
                        Contract.DeliveryDerivedTypeNames.SplunkDelivery,
                        splunkDelivery =>
                            _.map(
                                splunkDelivery.destinations,
                                splunkDestination => (scopeSystemEntityModelMap[splunkDestination.eventCollectorId].configuration as Contract.SplunkEventCollectorConfiguration).name)),
                    createItem<Contract.SqsDelivery>(
                        Contract.DeliveryDerivedTypeNames.SqsDelivery,
                        sqsDelivery =>
                            _.map(
                                sqsDelivery.destinations,
                                sqsDestination => (scopeSystemEntityModelMap[sqsDestination.queueId].configuration as Contract.SqsQueueConfiguration).name)),
                    createItem<Contract.TeamsDelivery>(
                        Contract.DeliveryDerivedTypeNames.TeamsDelivery,
                        teamsDelivery =>
                            _.map(
                                teamsDelivery.destinations,
                                teamsDestination => teamsChannelReferenceIdToNameMap[TeamsChannelReferenceHelper.getId(teamsDestination.teamsChannelReference)]?.channelName),
                        teamsDelivery =>
                            _.isEmpty(teamsDelivery.destinations)
                                ? getDeletedDeliveryElement(teamsDelivery.typeName as Contract.DeliveryDerivedTypeNames)
                                : _.map(
                                    teamsDelivery.destinations,
                                    teamsDestination =>
                                        <InfoCard
                                            columnContainerSx={{ paddingRight: 0 }}
                                            itemContainerSx={{ padding: 0 }}
                                            key={teamsDestination.integrationId}>
                                            {_.isNil(teamsChannelReferenceIdToNameMap[TeamsChannelReferenceHelper.getId(teamsDestination.teamsChannelReference)])
                                                ? getDeletedDeliveryElement(teamsDelivery.typeName as Contract.DeliveryDerivedTypeNames)
                                                : <InfoItem
                                                    title={
                                                        (_.find(
                                                            teamsOrganizationModels,
                                                            teamsOrganizationModel => teamsOrganizationModel.configuration.id === teamsDestination.teamsChannelReference.organizationId)?.configuration as Contract.TeamsOrganizationConfiguration)?.name ?? "******"}
                                                    value={
                                                        localization.integration.icon[Contract.DeliveryDerivedTypeNames.TeamsDelivery].organization({
                                                            name: teamsChannelReferenceIdToNameMap[TeamsChannelReferenceHelper.getId(teamsDestination.teamsChannelReference)].channelName,
                                                            teamName: teamsChannelReferenceIdToNameMap[TeamsChannelReferenceHelper.getId(teamsDestination.teamsChannelReference)].teamName
                                                        })}/>}
                                        </InfoCard>)),
                    createItem<Contract.WebhookDelivery>(
                        Contract.DeliveryDerivedTypeNames.WebhookDelivery,
                        webhookDelivery =>
                            _.map(
                                webhookDelivery.destinations,
                                webhookDestination => (scopeSystemEntityModelMap[webhookDestination.endpointId].configuration as Contract.WebhookEndpointConfiguration)!.name))
                ]) as IntegrationsItem[];
            },
            [deliveries]);

    return (
        <Fragment>
            {map(
                variant,
                {
                    "groupedList":
                        () =>
                            <VerticalTopItems
                                getSortValue={(item: IntegrationsItem) => item.names[0]}
                                items={items}>
                                {(item: IntegrationsItem) =>
                                    <Stack
                                        alignItems="center"
                                        direction="row"
                                        key={item.deliveryTypeName}
                                        spacing={1}
                                        sx={{ overflow: "hidden" }}>
                                        <Avatar
                                            sx={{
                                                color: "unset",
                                                fontSize: "17px",
                                                height: "23px",
                                                width: "23px"
                                            }}>
                                            {item.icon}
                                        </Avatar>
                                        <Typography noWrap={true}>
                                            {deliveryTranslator(
                                                item.deliveryTypeName as Contract.DeliveryDerivedTypeNames,
                                                "group",
                                                {
                                                    count: item.names.length,
                                                    item: item.names[0]
                                                }).text}
                                        </Typography>
                                    </Stack>}
                            </VerticalTopItems>,
                    "icon":
                        () =>
                            <TopItems
                                items={items}
                                itemSizeForLimitCalculations={31}
                                orderVariant="leftToRight"
                                variant="dynamic">
                                {item =>
                                    <Box
                                        key={item.deliveryTypeName}
                                        sx={{ padding: theme.spacing(0.5) }}>
                                        <Tooltip
                                            titleOrGetTitle={
                                                <Stack sx={{ padding: theme.spacing(0.5, 1) }}>
                                                    {_.isNil(item.nameElements)
                                                        ? _.isEmpty(item.names)
                                                            ? getDeletedDeliveryElement(item.deliveryTypeName)
                                                            : _.map(
                                                                item.names,
                                                                name =>
                                                                    _.isEmpty(name)
                                                                        ? getDeletedDeliveryElement(item.deliveryTypeName)
                                                                        :<Typography noWrap={true}>
                                                                            {name}
                                                                        </Typography>)
                                                        : _.map(
                                                            item.nameElements,
                                                            nameElement => nameElement)}
                                                </Stack>}>
                                            <Avatar
                                                sx={{
                                                    color: "unset",
                                                    fontSize: "17px",
                                                    height: "23px",
                                                    width: "23px"
                                                }}>
                                                {item.icon}
                                            </Avatar>
                                        </Tooltip>
                                    </Box>}
                            </TopItems>
                })}
        </Fragment>);
}

type IntegrationsItem = {
    deliveryTypeName: string;
    icon: ReactNode;
    nameElements?: ReactNode[];
    names: string[];
};