import { AnalyticsEventActionType, Link, map, useLocalization, useSelectionActionsContext, useTrackAnalytics } from "@infrastructure";
import { Box } from "@mui/material";
import _ from "lodash";
import React, { ReactNode, useMemo, useState } from "react";
import { CloseDialog, IgnoreDialog, OpenDialog } from "../..";
import { Contract, CustomerConsoleAppUrlHelper, RiskHelper, RiskType, RiskTypeMetadataModelHelper, SelectionActionButton } from "../../../../../../../common";

type UpdateStatusSelectionActionProps = {
    icon: ReactNode;
    reloadRiskModels: (status?: Contract.RiskStatus) => Promise<void>;
    riskType: RiskType;
    status: Contract.RiskStatus;
    view: Contract.RisksView;
};

export function UpdateStatusSelectionAction({ icon, reloadRiskModels, riskType, status, view }: UpdateStatusSelectionActionProps) {
    const { actionEnded, actionExecuting, actionStarted, itemIds, loadedItems } = useSelectionActionsContext();
    const [open, setOpen] = useState(false);

    const localization =
        useLocalization(
            "views.customer.risks.items.updateStatusSelectionAction",
            () => ({
                action: {
                    [Contract.RiskStatus.Closed]: {
                        text: "Close"
                    },
                    [Contract.RiskStatus.Ignored]: {
                        disabled: {
                            many: "Some of the policies do not support ignoring findings. Deselect these policies to ignore the findings. For a list of policies, see the {{docsLink}}",
                            single: "This policy does not support ignoring findings"
                        },
                        docsLink: "documentation",
                        text: {
                            add: "Ignore",
                            edit: "Edit Ignore"
                        }
                    },
                    [Contract.RiskStatus.Open]: {
                        disabled: "Some findings can't be reopened since they were automatically closed by Tenable Cloud Security",
                        text: "Open"
                    }
                },
                error: "Failed to update status"
            }));

    const [exclusionNotSupportedRiskSelected, riskPolicyCount] =
        useMemo(
            () => {
                const exclusionNotSupportedLoadedItems =
                    view === Contract.RisksView.Open &&
                    _.some(
                        loadedItems,
                        loadedItem => _.isEmpty(RiskTypeMetadataModelHelper.get(loadedItem.risk.typeName).exclusionEntityTypeNames));
                const riskPolicyCount =
                    _(loadedItems).
                        map(loadedItem => RiskHelper.getBuiltInRiskPolicyConfigurationTypeNameOrCustomRiskPolicyId(loadedItem.risk)).
                        uniq().
                        size();
                return [exclusionNotSupportedLoadedItems, riskPolicyCount];
            },
            [loadedItems]);
    const notManualClosedRiskSelected =
        useMemo(
            () =>
                view === Contract.RisksView.Closed &&
                _.some(
                    loadedItems,
                    loadedItem => loadedItem.risk.resolutionReason !== Contract.RiskResolutionReason.Manual),
            [loadedItems]);

    const trackAnalytics = useTrackAnalytics();
    async function onCloseDialog(canceled?: boolean) {
        setOpen(false);
        actionStarted(false);
        actionEnded();

        if (canceled) {
            return;
        }

        await reloadRiskModels(
            map(
                view,
                {
                    [Contract.RisksView.Open]: () => Contract.RiskStatus.Open,
                    [Contract.RisksView.Ignored]: () => Contract.RiskStatus.Ignored,
                    [Contract.RisksView.Closed]: () => Contract.RiskStatus.Closed
                }));

        trackAnalytics(
            AnalyticsEventActionType.RiskStatusUpdate,
            {
                "Risks Count": _.size(itemIds),
                "Risks New Status": status
            });
    }

    return (
        <Box sx={{ height: "100%" }}>
            {open &&
                map(
                    status,
                    {
                        [Contract.RiskStatus.Closed]:
                            () =>
                                <CloseDialog
                                    riskIds={itemIds}
                                    riskType={riskType}
                                    onClose={onCloseDialog}/>,
                        [Contract.RiskStatus.Ignored]:
                            () =>
                                <IgnoreDialog
                                    edit={view === Contract.RisksView.Ignored}
                                    riskIdsOrModel={itemIds}
                                    riskType={riskType}
                                    onClose={onCloseDialog}/>,
                        [Contract.RiskStatus.Open]:
                            () =>
                                <OpenDialog
                                    riskIds={itemIds}
                                    riskType={riskType}
                                    onClose={onCloseDialog}/>
                    })}
            <SelectionActionButton
                disabled={
                    actionExecuting ||
                    notManualClosedRiskSelected ||
                    (exclusionNotSupportedRiskSelected &&
                    status === Contract.RiskStatus.Ignored)}
                startIcon={icon}
                tooltip={
                    status === Contract.RiskStatus.Ignored &&
                        exclusionNotSupportedRiskSelected
                        ? (riskPolicyCount > 1
                            ? localization.action[Contract.RiskStatus.Ignored].disabled.many
                            : localization.action[Contract.RiskStatus.Ignored].disabled.single)(
                            {
                                docsLink:
                                    <Link
                                        urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsRiskPolicyExclusionRelativeUrl()}
                                        variant="external">
                                        {localization.action[Contract.RiskStatus.Ignored].docsLink()}
                                    </Link>
                            })
                        : status === Contract.RiskStatus.Open && notManualClosedRiskSelected
                            ? localization.action[Contract.RiskStatus.Open].disabled()
                            : undefined}
                onClick={() => setOpen(true)}>
                {status === Contract.RiskStatus.Closed ||
                        status === Contract.RiskStatus.Open
                    ? localization.action[status].text()
                    : view === Contract.RisksView.Ignored
                        ? localization.action[Contract.RiskStatus.Ignored].text.edit()
                        : localization.action[Contract.RiskStatus.Ignored].text.add()}
            </SelectionActionButton>
        </Box>);
}