import { Dialog, getUrlQueryParameters, makeContextProvider } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract } from "../../../../../../../../../../../../common";
import { AddOrEdit, Table } from "./components";

export class GciTenantsContext {
    constructor(
        public addOrEditOpen: boolean | Contract.GciTenantConfiguration) {
    }
}

export const [useGciTenantsContext, useSetGciTenantsContext, useGciTenantsContextProvider] = makeContextProvider<GciTenantsContext>();

export function GciTenants() {
    const [context, setContext, ContextProvider] =
        useGciTenantsContextProvider(
            () => {
                const queryParameters = getUrlQueryParameters<IdentityProviderTenantGciTenantQueryParameters>();
                return new GciTenantsContext(!_.isEmpty(queryParameters));
            });

    return (
        <ContextProvider>
            {context.addOrEditOpen !== false &&
                <Dialog
                    size="large"
                    variant="editor"
                    onClose={() => setContext(new GciTenantsContext(false))}>
                    <AddOrEdit/>
                </Dialog>}
            <Table/>
        </ContextProvider>);
}

export type IdentityProviderTenantGciTenantQueryParameters = {
    addOrEditOpen?: string;
};