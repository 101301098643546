import { DataTableColumn, DataTableColumnRenderProps, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import { Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, PagedEntityFilter } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";

export function useOciCommonEncryptedResourceDefinition(definitionData: DefinitionData) {
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.oci.useOciCommonEncryptedResourceDefinition",
            () => ({
                columns: {
                    encryptionExists: {
                        false: "Disabled",
                        title: "Encryption",
                        true: "Enabled"
                    },
                    encryptionVaultKeyIdReference: {
                        empty: "Oracle managed",
                        title: "Encryption Key"
                    }
                }
            }));

    function getEncryptionExistsTranslation(encryptedResourceModel: Contract.OciResourceModel) {
        return (_.as<Contract.IOciEncryptedResource>(encryptedResourceModel.entity)).encryptionExists
            ? localization.columns.encryptionExists.true()
            : localization.columns.encryptionExists.false();
    }

    return {
        columns: {
            encryptionExistsColumn:
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.OciResourceModel) => ({
                                [localization.columns.encryptionExists.title()]: getEncryptionExistsTranslation(item)
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <ValuesFilter
                                    placeholder={localization.columns.encryptionExists.title()}
                                    sorted={false}>
                                    <ValuesFilterItem
                                        title={localization.columns.encryptionExists.true()}
                                        value={true}/>
                                    <ValuesFilterItem
                                        title={localization.columns.encryptionExists.false()}
                                        value={false}/>
                                </ValuesFilter>
                        }
                    }}
                    id={Contract.EntityModelProperty.OciEncryptedResourceEncryptionExists}
                    itemProperty={getEncryptionExistsTranslation}
                    key={Contract.EntityModelProperty.OciEncryptedResourceEncryptionExists}
                    title={localization.columns.encryptionExists.title()}/>,
            encryptionVaultKeyColumn:
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.OciResourceModel) => ({
                                [localization.columns.encryptionVaultKeyIdReference.title()]:
                                    _.isNil(_.as<Contract.IOciEncryptedResourceModel>(item).encryptionVaultKeyIdReference)
                                        ? localization.columns.encryptionVaultKeyIdReference.empty()
                                        : _.as<Contract.IOciEncryptedResource>(item.entity).encryptionVaultKeyOcid!
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedEntityFilter
                                    emptyValueOptions={{
                                        title: localization.columns.encryptionVaultKeyIdReference.empty(),
                                        variant: "text"
                                    }}
                                    getEntityIdPage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.OciEncryptedResourceEncryptionVaultKey)}
                                    placeholder={localization.columns.encryptionVaultKeyIdReference.title()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.OciEncryptedResourceEncryptionVaultKey}
                    key={Contract.EntityModelProperty.OciEncryptedResourceEncryptionVaultKey}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.IOciEncryptedResourceModel>) =>
                            _.isNil(item.encryptionVaultKeyIdReference)
                                ? <Typography noWrap={true}>
                                    {localization.columns.encryptionVaultKeyIdReference.empty()}
                                </Typography>
                                : <EntitiesCell
                                    entityIdsOrModels={item.encryptionVaultKeyIdReference}
                                    entityTypeName={Contract.TypeNames.OciKmsVaultKey}/>}
                    title={localization.columns.encryptionVaultKeyIdReference.title()}/>
        }
    };
}