import { NotValidIcon, SuccessIcon, useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import React from "react";
import { useTheme } from "../../../../../../../../../../../../../common";

type PermissionsCellProps = {
    permissionManagementEnabled: boolean;
};

export function PermissionsCell({ permissionManagementEnabled }: PermissionsCellProps) {
    const localization =
        useLocalization(
            "views.customer.configuration.permissionManagement.identityProviderTenantTable.hooks.useDefinition.hooks.useCommonColumns.permissionsCell",
            () => ({
                exist: "Granted",
                notExist: "Not Granted"
            }));
    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            direction="row"
            spacing={1}>
            {permissionManagementEnabled
                ? <SuccessIcon
                    sx={{
                        color: theme.palette.success.main,
                        fontSize: "18px"
                    }}/>
                : <NotValidIcon
                    sx={{
                        color: theme.palette.error.main,
                        fontSize: "18px"
                    }}/>}
            <Typography>
                {permissionManagementEnabled
                    ? localization.exist()
                    : localization.notExist()}
            </Typography>
        </Stack>);
}