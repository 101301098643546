import { useLocalization } from "@infrastructure";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../..";
import { Contract, InfoItem } from "../../../../../../../../../../common";
import { EntitiesInfoCard, EntitiesInfoItem, Info } from "../../../../components";
import { AwsSsoPrincipalAccountAssignments } from "../../components";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";
import { useAwsDefaultResourceInfoItemElements } from "./useAwsDefaultResourceInfoItemElements";

export function useAwsSsoUserDefinition(resourceModel: Contract.AwsResourceModel, options?: DefinitionOptions) {
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useAwsDefaultResourceInfoItemElements(resourceModel);
    const user = (resourceModel.entity as Contract.AwsSsoUser);
    const userModel = (resourceModel as Contract.AwsSsoUserModel);
    const userProfile = userModel.entityProfile as Contract.AwsSsoUserProfile;

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsSsoUserDefinition",
            () => ({
                info: {
                    cards: {
                        groupIds: "Group Memberships"
                    },
                    items: {
                        active: {
                            false: "Inactive",
                            title: "Status",
                            true: "Active"
                        },
                        federatedIdentityReference: "Federated Identity",
                        firstName: "First Name",
                        identifier: "Username",
                        lastName: "Last Name",
                        rawId: "User ID",
                        rawMail: "Primary Mail"
                    }
                },
                tabs: {
                    accountAssignments: "Assignments"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <AwsSsoPrincipalAccountAssignments
                    principalModel={userModel}
                    roleIdToActivityTimeMap={userProfile?.aws.roleIdToActivityTimeMap}
                    variant="user"/>,
                localization.tabs.accountAssignments(),
                "accountAssignments")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="rawId"
                        title={localization.info.items.rawId()}
                        value={user.rawId}/>,
                    <InfoItem
                        key="active"
                        location="all"
                        title={localization.info.items.active.title()}
                        value={
                            user.active
                                ? localization.info.items.active.true()
                                : localization.info.items.active.false()}/>,
                    <InfoItem
                        key="firstName"
                        location="all"
                        title={localization.info.items.firstName()}
                        value={user.firstName}/>,
                    <InfoItem
                        key="lastName"
                        location="all"
                        title={localization.info.items.lastName()}
                        value={user.lastName}/>,
                    <InfoItem
                        key="identifier"
                        title={localization.info.items.identifier()}
                        value={user.identifier}/>,
                    <InfoItem
                        key="rawMail"
                        title={localization.info.items.rawMail()}
                        value={user.rawMail}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={userModel?.federatedIdentityReference?.id}
                        entityTypeName={userModel?.federatedIdentityReference?.typeName ?? Contract.TypeNames.AwsSsoPrincipal}
                        key="federatedIdentityReference"
                        title={localization.info.items.federatedIdentityReference()}/>
                ]}
                options={options?.infoOptions}>
                <EntitiesInfoCard
                    entityIdsOrModels={userModel.groupIds}
                    entityTypeName={Contract.TypeNames.AwsSsoGroup}
                    title={localization.info.cards.groupIds()}/>
            </Info>,
        sensitive: false
    });
}