import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function RisksIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                clipRule="evenodd"
                d="M6.92072 3.66496C5.73809 3.82393 5.07682 4.1194 4.59811 4.59811C4.11944 5.07677 3.82395 5.73824 3.66496 6.92109C3.50212 8.13261 3.5 9.73274 3.5 12C3.5 14.2678 3.50212 15.8679 3.66496 17.0793C3.82393 18.2619 4.1194 18.9232 4.59811 19.4019C5.07677 19.8806 5.73824 20.1761 6.92109 20.335C8.13261 20.4979 9.73274 20.5 12 20.5C14.2678 20.5 15.8679 20.4979 17.0793 20.335C18.2619 20.1761 18.9232 19.8806 19.4019 19.4019C19.8806 18.9232 20.1761 18.2618 20.335 17.0789C20.4979 15.8674 20.5 14.2673 20.5 12C20.5 9.73223 20.4979 8.13212 20.335 6.92072C20.1761 5.73809 19.8806 5.07682 19.4019 4.59811C18.9232 4.11944 18.2618 3.82395 17.0789 3.66496C15.8674 3.50212 14.2673 3.5 12 3.5C9.73223 3.5 8.13212 3.50212 6.92072 3.66496ZM11.9255 1.5H12.0744C14.2503 1.49998 15.9851 1.49996 17.3453 1.68279C18.7497 1.87155 19.9038 2.27156 20.8161 3.18389C21.7284 4.09619 22.1284 5.24991 22.3172 6.65428C22.5 8.01441 22.5 9.74925 22.5 11.9256V12.0744C22.5 14.2503 22.5 15.9851 22.3172 17.3453C22.1284 18.7497 21.7284 19.9038 20.8161 20.8161C19.9038 21.7284 18.7501 22.1284 17.3457 22.3172C15.9856 22.5 14.2508 22.5 12.0745 22.5H11.9256C9.74974 22.5 8.01491 22.5 6.65466 22.3172C5.25026 22.1284 4.09623 21.7284 3.18389 20.8161C2.2716 19.9038 1.87157 18.7501 1.68279 17.3457C1.49996 15.9856 1.49998 14.2508 1.5 12.0745V11.9256C1.49998 9.74975 1.49996 8.01492 1.68279 6.65466C1.87155 5.25026 2.27156 4.09623 3.18389 3.18389C4.09619 2.2716 5.24991 1.87157 6.65428 1.68279C8.01441 1.49996 9.74924 1.49998 11.9255 1.5ZM12 6C12.5523 6 13 6.44772 13 7V13C13 13.5523 12.5523 14 12 14C11.4477 14 11 13.5523 11 13V7C11 6.44772 11.4477 6 12 6ZM11 16C11 15.4477 11.4477 15 12 15H12.009C12.5613 15 13.009 15.4477 13.009 16C13.009 16.5523 12.5613 17 12.009 17H12C11.4477 17 11 16.5523 11 16Z"
                fillRule="evenodd"/>
        </SvgIcon>);
}