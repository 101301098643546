import { MailIcon } from "@infrastructure";
import { SvgIconProps } from "@mui/material";
import React, { Fragment } from "react";
import { Contract } from "../controllers";
import { DatadogIcon, JiraIcon, QRadarIcon, ServiceNowIcon, SlackIcon, SplunkIcon, TeamsIcon, WebhookIcon } from "../icons";
import { EntityIcon } from "./EntityIcon";

type DeliveryIconProps =
    SvgIconProps & {
        typeName: Contract.DeliveryDerivedTypeNames;
    };

export function DeliveryIcon({ typeName, ...props }: DeliveryIconProps) {
    switch (typeName) {
        case Contract.DeliveryDerivedTypeNames.MailDelivery:
        case Contract.DeliveryDerivedTypeNames.ResourceOwnerMailDelivery:
            return <MailIcon {...props}/>;
        case Contract.DeliveryDerivedTypeNames.SlackDelivery:
            return <SlackIcon {...props}/>;
        case Contract.DeliveryDerivedTypeNames.TeamsDelivery:
            return <TeamsIcon {...props}/>;
        case Contract.DeliveryDerivedTypeNames.DatadogDelivery:
            return <DatadogIcon {...props}/>;
        case Contract.DeliveryDerivedTypeNames.JiraDelivery:
            return <JiraIcon {...props}/>;
        case Contract.DeliveryDerivedTypeNames.QRadarDelivery:
            return <QRadarIcon {...props}/>;
        case Contract.DeliveryDerivedTypeNames.ServiceNowDelivery:
            return <ServiceNowIcon {...props}/>;
        case Contract.DeliveryDerivedTypeNames.SplunkDelivery:
            return <SplunkIcon {...props}/>;
        case Contract.DeliveryDerivedTypeNames.SqsDelivery:
            return (
                <EntityIcon
                    entityTypeName={Contract.TypeNames.AwsSqsQueue}
                    {...props}/>);
        case Contract.DeliveryDerivedTypeNames.WebhookDelivery:
            return <WebhookIcon {...props}/>;
        default:
            return <Fragment/>;
    }
}