import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../../";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { EntityExternalConsoleLink } from "../../components";
import { useGetAzureSqlServerRiskContext } from "../contexts";

export function useAzureSqlServerSecurityAlertPolicyDisabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AzureSqlServerSecurityAlertPolicyDisabledRisk;
    const serverModel = entityModelStore.useGet(risk.entityId) as Contract.AzureSqlServerModel;

    const getAzureSqlServerRiskContext = useGetAzureSqlServerRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.compliance.useAzureSqlServerSecurityAlertPolicyDisabledRiskDefinition",
            () => ({
                description: "{{server}} Microsoft Defender is not enabled",
                sections: {
                    resolution: {
                        step1: "Click **Enable**"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            server:
                <Entity
                    entityIdOrModel={serverModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={risk.entityId}
                key={risk.entityId}
                page={Contract.AzureConsoleEntityPage.SecurityAlertPolicy}/>,
            localization.sections.resolution.step1()
        ],
        riskModel,
        () => {
            const serverRiskContext = getAzureSqlServerRiskContext(serverModel);
            return [
                serverRiskContext.generalInformation,
                serverRiskContext.status,
                serverRiskContext.storageSize,
                serverRiskContext.inboundExternalAccessScope,
                serverRiskContext.sensitive,
                serverRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}