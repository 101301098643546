import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, Entity, entityModelStore, InlineEntities, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { useAwsCommonBehaviorIdentityRiskDefinition } from "./useAwsCommonBehaviorIdentityRiskDefinition";

export function useAwsPermissionManagementRiskDefinition(riskModel: Contract.RiskModel) {
    const permissionManagementRiskModel = riskModel as Contract.AwsPermissionManagementRiskModel;
    const identityModel = entityModelStore.useGet(permissionManagementRiskModel.risk.identityId);
    const identity = identityModel.entity as Contract.AwsIamIdentity;

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.behavior.useAwsPermissionManagementRiskDefinition",
            () => ({
                description: {
                    [Contract.TypeNames.AwsPermissionManagementRiskModelDescriptionType]: {
                        [Contract.AwsPermissionManagementRiskModelDescriptionType.ResourcesNone]: "{{identity}} was observed modifying permissions",
                        [Contract.AwsPermissionManagementRiskModelDescriptionType.ResourcesSome]: "{{identity}} was observed modifying the permissions of {{resources}}"
                    }
                }
            }));

    return useAwsCommonBehaviorIdentityRiskDefinition(
        riskModel,
        localization.description[Contract.TypeNames.AwsPermissionManagementRiskModelDescriptionType][permissionManagementRiskModel.descriptionType]({
            identity:
                <Entity
                    entityIdOrModel={identityModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>,
            resources:
                <InlineEntities
                    entityIdsOrModels={permissionManagementRiskModel.resourceIdReferences}
                    entityTypeName={Contract.TypeNames.AwsResource}
                    variant="itemAndTypeOrItemCountAndType"/>,
            translatedIdentityTypeName:
                entityTypeNameTranslator(
                    identity.typeName,
                    {
                        includeServiceName: false
                    })
        }));
}