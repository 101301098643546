import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function CommonEntityAttributesIcon(props: SvgIconProps) {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <path 
                clipRule="evenodd"
                d="M5 6C4.44772 6 4 6.44772 4 7V19C4 19.5523 4.44772 20 5 20H19C19.5523 20 20 19.5523 20 19V7C20 6.44772 19.5523 6 19 6H18.75C18.1977 6 17.75 5.55228 17.75 5C17.75 4.44772 18.1977 4 18.75 4H19C20.6569 4 22 5.34315 22 7V19C22 20.6569 20.6569 22 19 22H5C3.34315 22 2 20.6569 2 19V7C2 5.34315 3.34315 4 5 4H5.25C5.80228 4 6.25 4.44772 6.25 5C6.25 5.55228 5.80228 6 5.25 6H5Z"
                fillRule="evenodd"/>
            <path 
                clipRule="evenodd"
                d="M7 11C7 10.4477 7.40701 10 7.90909 10H16.0909C16.593 10 17 10.4477 17 11C17 11.5523 16.593 12 16.0909 12H7.90909C7.40701 12 7 11.5523 7 11Z"
                fillRule="evenodd"/>
            <path 
                clipRule="evenodd"
                d="M7 15C7 14.4477 7.40701 14 7.90909 14H16.0909C16.593 14 17 14.4477 17 15C17 15.5523 16.593 16 16.0909 16H7.90909C7.40701 16 7 15.5523 7 15Z"
                fillRule="evenodd"/>
            <path 
                clipRule="evenodd"
                d="M13 4H11C10.4477 4 10 4.44772 10 5C10 5.55228 10.4477 6 11 6H13C13.5523 6 14 5.55228 14 5C14 4.44772 13.5523 4 13 4ZM11 2C9.34315 2 8 3.34315 8 5C8 6.65685 9.34315 8 11 8H13C14.6569 8 16 6.65685 16 5C16 3.34315 14.6569 2 13 2H11Z"
                fillRule="evenodd"/>
        </SvgIcon>);
}