import { NumberFormatter, useLocalization } from "@infrastructure";
import { Box, Typography } from "@mui/material";
import React from "react";
import { Contract, CustomerConsoleAppUrlHelper, TenantsIcon, useRiskPolicyTitleTranslator, useSeverityTranslator, useTheme } from "../../../../../../../../..";
import { Link } from "../../../../../../../../Entity/components";
import { SeverityItem } from "../../../components";

interface AccessRiskProps {
    filteredSeverities: Contract.Severity[];
    item: AccessRiskDataItem;
}

export function AccessRisk({ filteredSeverities, item }: AccessRiskProps) {
    const severityTranslator = useSeverityTranslator();
    const riskPolicyTitleTranslator = useRiskPolicyTitleTranslator();

    const localization =
        useLocalization(
            "common.dashboard.widget.hooks.useDefinition.hooks.useGetAccessRisksDefinition.accessRisk",
            () => ({
                findings: "{{ count | NumberFormatter.humanize }} Findings"
            }));

    const theme = useTheme();
    return (
        <Link
            className="accessRisk"
            key={item.policyConfigurationTypeName}
            sx={{ color: theme.palette.text.primary }}
            urlOrGetUrl={
                CustomerConsoleAppUrlHelper.getRisksRelativeUrl(
                    Contract.RisksView.Open,
                    {
                        policyConfigurationTypeNameOrIds: [item.policyConfigurationTypeName],
                        riskPolicyCategory: Contract.RiskPolicyCategory.Access,
                        severity: filteredSeverities
                    })}
            variant="text">
            <SeverityItem
                icon={
                    <TenantsIcon
                        sx={{ fontSize: "22px" }}
                        tenantTypes={item.tenantTypes}/>}
                severity={item.maxSeverity as Contract.Severity}
                subTitles={[
                    <Box
                        key="findings"
                        sx={{ overflow: "hidden" }}>
                        <Typography
                            noWrap={true}
                            sx={{ color: theme.palette.text.secondary }}>
                            {localization.findings({ count: item.count })}
                        </Typography>
                    </Box>,
                    <Box
                        key="maxSeverityRiskCount"
                        sx={{ overflow: "hidden" }}>
                        <Typography
                            noWrap={true}
                            sx={{ color: theme.palette.text.secondary }}>
                            {NumberFormatter.humanize(item.severityToCountMap[item.maxSeverity])} {severityTranslator(item.maxSeverity as Contract.Severity)}
                        </Typography>
                    </Box>
                ]}
                title={
                    <Typography noWrap={true}>
                        {riskPolicyTitleTranslator(item.policyConfigurationTypeName)}
                    </Typography>}/>
        </Link>);
}

interface AccessRiskDataItem {
    count: number;
    maxSeverity: Contract.Severity;
    policyConfigurationTypeName: string;
    severitiesCount: number[];
    severityToCountMap: _.Dictionary<number>;
    tenantTypes: Contract.TenantType[];
}