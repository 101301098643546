import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, EntityModelHelper, InlineEntities } from "../../../../../../../../../../../common";

export function useEntitySnapshotChangeTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.profile.snapshots.snapshot.hooks.useEntitySnapshotChangeTranslator",
            () => ({
                changes: {
                    [Contract.TypeNames.EntitySnapshotChange]: {
                        [Contract.TypeNames.AwsIamIdentityAttachPermissionBoundaryPolicySnapshotChange]: "{{entitiesElement}} attached (Permissions Boundary)",
                        [Contract.TypeNames.AwsIamIdentityDetachPermissionBoundaryPolicySnapshotChange]: "{{entitiesElement}} detached (Permissions Boundary)",
                        [Contract.TypeNames.AwsIamIdentityEditPermissionBoundaryPolicySnapshotChange]: "{{entitiesElement}} edited (Permissions Boundary)",
                        [Contract.TypeNames.AwsIamPrincipalAddInlinePolicySnapshotChange]: "{{entitiesElement}} added",
                        [Contract.TypeNames.AwsIamPrincipalAttachManagedPolicySnapshotChange]: "{{entitiesElement}} attached",
                        [Contract.TypeNames.AwsIamPrincipalDetachManagedPolicySnapshotChange]: "{{entitiesElement}} detached",
                        [Contract.TypeNames.AwsIamPrincipalPolicyEditSnapshotChange]: "{{entitiesElement}} edited",
                        [Contract.TypeNames.AwsIamPrincipalRemoveInlinePolicySnapshotChange]: "{{entitiesElement}} removed",
                        [Contract.TypeNames.AwsIamUserAddGroupSnapshotChange]: "User was added to {{entitiesElement}}",
                        [Contract.TypeNames.AwsIamUserRemoveGroupSnapshotChange]: "User was removed from {{entitiesElement}}"
                    }
                }
            }));
    return (typeName: string, entityModels: Contract.EntityModel[]) =>
        localization.changes[Contract.TypeNames.EntitySnapshotChange][typeName as EntitySnapshotChangeTypeName]({
            entitiesElement:
                <InlineEntities
                    entityIdsOrModels={entityModels}
                    entityTypeName={EntityModelHelper.getCommonTypeName(entityModels) ?? Contract.TypeNames.Entity}
                    variant="itemOrItemCountAndType"/>
        });
}

type EntitySnapshotChangeTypeName =
    Contract.TypeNames.AwsIamIdentityAttachPermissionBoundaryPolicySnapshotChange |
    Contract.TypeNames.AwsIamIdentityDetachPermissionBoundaryPolicySnapshotChange |
    Contract.TypeNames.AwsIamIdentityEditPermissionBoundaryPolicySnapshotChange |
    Contract.TypeNames.AwsIamPrincipalAddInlinePolicySnapshotChange |
    Contract.TypeNames.AwsIamPrincipalAttachManagedPolicySnapshotChange |
    Contract.TypeNames.AwsIamPrincipalDetachManagedPolicySnapshotChange |
    Contract.TypeNames.AwsIamPrincipalPolicyEditSnapshotChange |
    Contract.TypeNames.AwsIamPrincipalRemoveInlinePolicySnapshotChange |
    Contract.TypeNames.AwsIamUserAddGroupSnapshotChange |
    Contract.TypeNames.AwsIamUserRemoveGroupSnapshotChange;