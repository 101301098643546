import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { EntityExternalConsoleLink } from "../../components";
import { useGetGcpSqlInstanceRiskContext } from "../contexts";

export function useGcpSqlInstancePostgreSqlAuditDisabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.GcpSqlInstancePostgreSqlAuditDisabledRisk;
    const instanceModel = entityModelStore.useGet(risk.entityId) as Contract.GcpSqlInstanceModel;

    const getGcpSqlInstanceRiskContext = useGetGcpSqlInstanceRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.useGcpSqlInstancePostgreSqlAuditDisabledRiskDefinition",
            () => ({
                description: "{{instance}} ‘cloudsql.enable_pgaudit’ flag is not enabled",
                sections: {
                    resolution: {
                        step1: "Under **Flags**, set the flag **'cloudsql.enable_pgaudit'** to **On** and **'pgaudit.log'** to the scope of operations recommended by your organization and click on **Done**",
                        step2: "Click **Save** at the bottom to confirm and restart the instance"
                    }
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            instance:
                <Entity
                    entityIdOrModel={instanceModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={risk.entityId}
                key={risk.entityId}
                page={Contract.GcpConsolePage.Edit}/>,
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2()
        ],
        riskModel,
        () => {
            const instanceRiskContext = getGcpSqlInstanceRiskContext(instanceModel);
            return [
                instanceRiskContext.generalInformation,
                instanceRiskContext.sensitive,
                instanceRiskContext.inboundExternalAccessScope,
                instanceRiskContext.status,
                instanceRiskContext.databaseVersion,
                instanceRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        });
}