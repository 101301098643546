﻿import { Optional, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, InlineEntities, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetAwsResourceRiskContext } from "./useGetAwsResourceRiskContext";

export function useGetAwsAutoScalingLaunchConfigurationRiskContext() {
    return useMemo(
        () => useAwsAutoScalingLaunchConfigurationRiskContext,
        []);
}

function useAwsAutoScalingLaunchConfigurationRiskContext(launchConfigurationModel: Contract.AwsAutoScalingLaunchConfigurationModel) {
    const resourceRiskContext = useGetAwsResourceRiskContext()(launchConfigurationModel);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.contexts.useGetAwsAutoScalingLaunchConfigurationRiskContext",
            () => ({
                autoScalingGroups: "The {{translatedLaunchConfigurationTypeName}} is part of {{autoScalingGroups}}"
            }));

    return {
        ...resourceRiskContext,
        getAutoScalingGroupsContextItem:
            (autoScalingGroupIds: Optional<string[]>) =>
                _.isEmpty(autoScalingGroupIds)
                    ? undefined
                    : new RiskDefinitionContextItem(
                        localization.autoScalingGroups({
                            autoScalingGroups:
                                <InlineEntities
                                    entityIdsOrModels={autoScalingGroupIds!}
                                    entityTypeName={Contract.TypeNames.AwsAutoScalingAutoScalingGroup}
                                    variant="itemCountAndType"/>,
                            translatedLaunchConfigurationTypeName:
                                entityTypeNameTranslator(
                                    launchConfigurationModel.entity.typeName,
                                    {
                                        includeServiceName: false,
                                        variant: "text"
                                    })
                        }))
    };
}