import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../../..";

export function useAwsDisableUserAccessKeyChangeDefinition(change: Contract.AwsChange) {
    const disableUserAccessKeyChange = change as Contract.AwsDisableUserAccessKeyChange;
    const localization =
        useLocalization(
            "common.change.hooks.useDefinition.hooks.useAwsDefinition.hooks.useAwsDisableUserAccessKeyChangeDefinition",
            () => ({
                title: "Disable/Delete **access key {{accessKeyUniqueId}}**"
            }));
    return {
        title: localization.title({ accessKeyUniqueId: disableUserAccessKeyChange.accessKeyUniqueId })
    };
}