import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function ExpelIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                d="M21.4264 0H2.71206C2.52321 0 2.34209 0.07502 2.20856 0.208557C2.07502 0.342093 2 0.523208 2 0.712058V23.2879C2 23.4768 2.07502 23.6579 2.20856 23.7914C2.34209 23.925 2.52321 24 2.71206 24H21.4264C21.6152 24 21.7964 23.925 21.9299 23.7914C22.0634 23.6579 22.1385 23.4768 22.1385 23.2879V0.712058C22.1385 0.523208 22.0634 0.342093 21.9299 0.208557C21.7964 0.07502 21.6152 0 21.4264 0ZM18.035 20.7364C17.781 20.7271 17.5319 20.6636 17.3044 20.5503C17.0769 20.4369 16.8763 20.2763 16.7159 20.0791L11.9916 14.3233L7.24002 20.0746C7.08504 20.2777 6.88554 20.4426 6.65685 20.5565C6.42816 20.6705 6.17638 20.7304 5.92088 20.7318C5.72965 20.7379 5.53908 20.7063 5.36009 20.6387C5.1811 20.5711 5.01718 20.4688 4.87772 20.3379C4.73825 20.2069 4.62597 20.0497 4.54729 19.8753C4.46861 19.7009 4.42507 19.5127 4.41917 19.3214V19.2666C4.42463 18.8442 4.58737 18.439 4.87562 18.1301L10.0107 11.9407L5.17231 6.11639C4.86646 5.77465 4.70313 5.32874 4.71586 4.87029C4.75594 4.50453 4.9258 4.16526 5.19463 3.91402C5.46345 3.66278 5.81343 3.51623 6.18106 3.50095C6.44432 3.49877 6.7046 3.55687 6.94195 3.6708C7.1793 3.78473 7.38743 3.95146 7.5504 4.15823L12.019 9.54431L16.369 4.13541C16.5262 3.93476 16.7262 3.77166 16.9544 3.65796C17.1826 3.54427 17.4332 3.48284 17.6881 3.47813C18.0856 3.48052 18.4662 3.63951 18.7473 3.92062C19.0284 4.20172 19.1874 4.5823 19.1898 4.97984C19.1765 5.40067 19.0148 5.80324 18.7334 6.11639L14.0046 11.9041L19.1076 18.1347C19.3996 18.4263 19.5638 18.822 19.5641 19.2347C19.5665 19.4295 19.5305 19.6229 19.4582 19.8038C19.3859 19.9847 19.2786 20.1496 19.1425 20.289C19.0065 20.4285 18.8443 20.5398 18.6652 20.6165C18.4861 20.6933 18.2937 20.734 18.0989 20.7364H18.035Z"
                fill="#00A85D"/>
        </SvgIcon>);
}