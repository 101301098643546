import { ApiError, AutocompleteItems, FormLayout, InfoIcon, ItemSelector, Link, Message, Tooltip, useChangeEffect, useInputValidation, useLocalization } from "@infrastructure";
import { Group as GroupIcon } from "@mui/icons-material";
import { Button, CircularProgress, FormControl, FormHelperText, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import _ from "lodash";
import React, { useState } from "react";
import { usePrincipalsContext, useSetPrincipalsContext } from "../../..";
import { ConfigurationController, Contract, CustomerConsoleAppUrlHelper, LicensingHelper, ScopeHelper, ScopePath, useIdentityRoleTranslator, UserHelper, useScopeNavigationViewContext, useTheme } from "../../../../../../../../../common";


export type GroupProps = {
    roleAssignment?: Contract.ConfigurationControllerGetPrincipalRoleAssignmentsResponsePrincipalRoleAssignment;
};

export function Group({ roleAssignment }: GroupProps) {
    const { scopeNodeModel } = useScopeNavigationViewContext();
    const { executeGetPrincipals, groups, principalRoleAssignments } = usePrincipalsContext();
    const setPrincipalsContext = useSetPrincipalsContext();

    const [identifier, setIdentifier] = useState(roleAssignment?.principalIdentifier ?? "");
    const [role, setRole] = useState(roleAssignment?.role);

    const [upsertGroupRoleAssignmentExecuting, setUpsertGroupRoleAssignmentExecuting] = useState(false);
    const [upsertGroupRoleAssignmentError, setUpsertGroupRoleAssignmentError] = useState<Contract.ConfigurationControllerInsertGroupRoleAssignmentError | true>();

    async function upsertGroupRoleAssignment() {
        setUpsertGroupRoleAssignmentExecuting(true);
        setUpsertGroupRoleAssignmentError(undefined);

        try {
            if (_.isNil(roleAssignment)) {
                await ConfigurationController.insertGroupRoleAssignment(
                    new Contract.ConfigurationControllerInsertGroupRoleAssignmentRequest(
                        identifier.trim(),
                        undefined,
                        role!,
                        scopeNodeModel.configuration.id));
            } else {
                await ConfigurationController.updateGroupRoleAssignment(
                    new Contract.ConfigurationControllerUpdateGroupRoleAssignmentRequest(
                        roleAssignment.role,
                        _.find(groups, group => group.identifier === identifier)!.id,
                        undefined,
                        role!,
                        roleAssignment.scopeId));
            }

            await executeGetPrincipals();
            setPrincipalsContext(
                principalsContext => ({
                    ...principalsContext,
                    dialogContentElement: undefined
                }));
        } catch (error) {
            setUpsertGroupRoleAssignmentError(
                error instanceof ApiError && error.statusCode === 400
                    ? error.error as Contract.ConfigurationControllerInsertGroupRoleAssignmentError
                    : true);
        }

        setUpsertGroupRoleAssignmentExecuting(false);
    }

    const identityRoleTranslator = useIdentityRoleTranslator();
    const localization =
        useLocalization(
            "views.customer.configuration.principals.addOrEditPrincipal.group",
            () => ({
                actions: {
                    add: {
                        error: "Failed to add group assignment",
                        roleAssignmentError: {
                            [Contract.ConfigurationControllerInsertGroupRoleAssignmentError.GroupMaxCount]: "Group limit was reached"
                        },
                        title: "Add"
                    },
                    edit: {
                        error: "Failed to save",
                        title: "Save"
                    },
                    upsert: {
                        error: {
                            add: "Failed to add group assignment",
                            edit: "Failed to save",
                            [Contract.TypeNames.ConfigurationControllerInsertGroupRoleAssignmentError]: {
                                [Contract.ConfigurationControllerInsertGroupRoleAssignmentError.GroupMaxCount]: "Group limit was reached"
                            }
                        },
                        title: {
                            add: "Add",
                            edit: "Save"
                        }
                    }
                },
                fields: {
                    groupRoleAssignment: {
                        error: {
                            exists: "Group role assignment exists in this scope"
                        }
                    },
                    identifier: {
                        error: {
                            required: "Name cannot be empty"
                        },
                        title: "Group name"
                    },
                    role: "Role"
                },
                helpText: {
                    identifier: {
                        normal: "The group name, as defined in your IdP. You must first configure the IdP to send group claims to Tenable Cloud Security.",
                        tenable: "The group name, as defined in Tenable One"
                    },
                    title: {
                        link: "Read documentation",
                        tenableText: "Grant all users associated with a Tenable One group identical permissions in Tenable Cloud Security.",
                        text: "Grant all users associated with an IdP group identical permissions in Tenable Cloud Security. You must first configure the IdP to send group claims to Tenable Cloud Security. {{link}}."
                    }
                },
                title: {
                    add: "Add a Group Role Assignment",
                    edit: "Edit a Group Role Assignment"
                }
            }));

    const [identifierValidationController, identifierValidationMessage] =
        useInputValidation(
            () => {
                const validationIdentifier = identifier.trim();
                if (_.isEmpty(validationIdentifier)) {
                    return localization.fields.identifier.error.required();
                }

                return undefined;
            },
            [identifier]);

    const groupRoleAssignmentExists =
        !_.isEmpty(identifier) &&
        role != roleAssignment?.role &&
        _.some(
            principalRoleAssignments,
            principalRoleAssignment =>
                principalRoleAssignment.principalIdentifier === identifier &&
                principalRoleAssignment.role === role &&
                principalRoleAssignment.scopeId === scopeNodeModel.configuration.id);

    const errorMessage =
        _.isNil(upsertGroupRoleAssignmentError) &&
        !groupRoleAssignmentExists
            ? undefined
            : groupRoleAssignmentExists
                ? localization.fields.groupRoleAssignment.error.exists()
                : upsertGroupRoleAssignmentError === true
                    ? _.isNil(roleAssignment)
                        ? localization.actions.add.error()
                        : localization.actions.edit.error()
                    : localization.actions.add.roleAssignmentError[upsertGroupRoleAssignmentError!]();

    const [valid, setValid] = useState(false);
    useChangeEffect(
        () => {
            setValid(
                identifierValidationController.isValid() &&
                role != roleAssignment?.role &&
                !groupRoleAssignmentExists);

            setUpsertGroupRoleAssignmentError(undefined);
        },
        [groupRoleAssignmentExists, identifier, role]);

    const theme = useTheme();
    return (
        <FormLayout
            footerOptions={{
                contentElement:
                    <Stack
                        alignItems="center"
                        direction="row"
                        justifyContent="flex-end"
                        spacing={1}>
                        {upsertGroupRoleAssignmentExecuting && (
                            <CircularProgress
                                size={theme.spacing(2)}
                                variant="indeterminate"/>)}
                        <Button
                            disabled={!valid || upsertGroupRoleAssignmentExecuting}
                            onClick={() => upsertGroupRoleAssignment()}>
                            {_.isNil(roleAssignment)
                                ? localization.actions.add.title()
                                : localization.actions.edit.title()}
                        </Button>
                    </Stack>
            }}
            titleOptions={{
                infoElement:
                    UserHelper.tenable
                        ? localization.helpText.title.tenableText()
                        : _.isNil(roleAssignment)
                            ? localization.helpText.title.text({
                                link:
                                    <Link
                                        urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsEnableConsoleLoginViaSsoRelativeUrl()}
                                        variant="external">
                                        {localization.helpText.title.link()}
                                    </Link>
                            })
                            : undefined,
                text:
                    _.isNil(roleAssignment)
                        ? localization.title.add()
                        : localization.title.edit()
            }}>
            <Stack spacing={5}>
                <Stack spacing={2}>
                    <ScopePath
                        scopeId={scopeNodeModel.configuration.id}
                        variant="customer"/>
                    <FormControl
                        fullWidth={true}
                        variant="standard">
                        <AutocompleteItems
                            autoSelect={true}
                            disableClearable={true}
                            disabled={upsertGroupRoleAssignmentExecuting || !_.isNil(roleAssignment)}
                            freeSolo={true}
                            fullWidth={true}
                            options={
                                _.map(
                                    groups,
                                    group => group.identifier)}
                            renderInput={
                                params => (
                                    <TextField
                                        {...params}
                                        label={localization.fields.identifier.title()}
                                        slotProps={{
                                            input: {
                                                ...params.InputProps,
                                                endAdornment:
                                                    <InputAdornment position="end">
                                                        <Tooltip
                                                            titleOrGetTitle={
                                                                <Typography
                                                                    sx={{
                                                                        fontWeight: "initial",
                                                                        padding: theme.spacing(1),
                                                                        whiteSpace: "pre-wrap"
                                                                    }}>
                                                                    {UserHelper.tenable
                                                                        ? localization.helpText.identifier.tenable()
                                                                        : localization.helpText.identifier.normal()}
                                                                </Typography>}>
                                                            <InfoIcon
                                                                sx={{
                                                                    color: theme.palette.text.secondary,
                                                                    fontSize: "24px"
                                                                }}/>
                                                        </Tooltip>
                                                    </InputAdornment>
                                            }
                                        }}
                                        variant="outlined"/>)}
                            value={identifier}
                            onChange={(_, identifier) => setIdentifier(identifier)}>
                            {identifier =>
                                <Stack
                                    alignItems="center"
                                    direction="row"
                                    spacing={1}>
                                    <GroupIcon
                                        sx={{
                                            color: theme.palette.text.primary,
                                            fontSize: "18px"
                                        }}/>
                                    <Typography>
                                        {identifier}
                                    </Typography>
                                </Stack>}
                        </AutocompleteItems>
                        {!_.isNil(identifierValidationMessage) && (
                            <FormHelperText error={true}>{identifierValidationMessage}</FormHelperText>)}
                    </FormControl>
                    <ItemSelector
                        fullWidth={true}
                        items={
                            _(
                                [
                                    Contract.IdentityRole.Administrator,
                                    Contract.IdentityRole.Collaborator,
                                    Contract.IdentityRole.Viewer
                                ]).
                                concatIf(
                                    LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.PermissionManagement) &&
                                    ScopeHelper.isPermissionManagementTenantsScope(scopeNodeModel),
                                    Contract.IdentityRole.PermissionManagementAdministrator).
                                value()}
                        placeholder={localization.fields.role()}
                        selectedItem={role}
                        sorted={false}
                        onSelectedItemChanged={setRole}>
                        {identityRoleTranslator}
                    </ItemSelector>
                </Stack>
                {!_.isNil(errorMessage) && (
                    <Message
                        level="error"
                        title={errorMessage}/>)}
            </Stack>
        </FormLayout>);
}