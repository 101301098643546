import { Link, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../..";
import { useCommonKubernetesClusterInfoElements, useCustomEntityPropertyInfoItemElements } from "../..";
import { useAwsCommonEncryptedResourceInfoElements, useAwsDefaultResourceInfoItemElements } from "..";
import { Contract, CustomerConsoleAppUrlHelper, InfoItem } from "../../../../../../../../../../../common";
import { useAwsEksClusterStatusTranslator } from "../../../../../../../hooks";
import { EntitiesInfoItem, Info } from "../../../../../components";
import { KubernetesClusterResourcesInfoCard } from "../../../components";
import { ClusterNetworkingInfoCard, FargateProfileInfoCard } from "./components";

export function useAwsEksClusterDefinition(resourceModel: Contract.AwsResourceModel, options?: DefinitionOptions) {
    const cluster = resourceModel.entity as Contract.AwsEksCluster;
    const clusterModel = resourceModel as Contract.AwsEksClusterModel;
    const commonEncryptedResourceInfoElements = useAwsCommonEncryptedResourceInfoElements(resourceModel);
    const commonKubernetesClusterInfoElements = useCommonKubernetesClusterInfoElements(_.as<Contract.IKubernetesClusterModel>(resourceModel));
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useAwsDefaultResourceInfoItemElements(resourceModel);

    const clusterStatusTranslator = useAwsEksClusterStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsEksClusterDefinition",
            () => ({
                info: {
                    autoScalingGroupIds: "Auto Scaling Groups",
                    network: {
                        vpcId: "VPC"
                    },
                    nodeGroupIds: "Node Groups",
                    serviceRoleReference: "Role",
                    status: "Status",
                    url: "Endpoint",
                    version: {
                        title: "Kubernetes Version"
                    }
                },
                tabs: {
                    resources: "Resources"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Overview,
                <KubernetesClusterResourcesInfoCard clusterId={clusterModel.entity.id}/>,
                localization.tabs.resources(),
                "resources")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    ...commonEncryptedResourceInfoElements,
                    <InfoItem
                        key="status"
                        title={localization.info.status()}
                        value={clusterStatusTranslator(cluster.status)}/>,
                    <InfoItem
                        key="version"
                        location="all"
                        title={localization.info.version.title()}
                        value={cluster.version}/>,
                    commonKubernetesClusterInfoElements.createApiConnectivityStatusInfoItemElement(CustomerConsoleAppUrlHelper.getDocsOnboardAwsEksClusterGrantPermissionsRelativeUrl()),
                    <InfoItem
                        key="url"
                        title={localization.info.url()}
                        value={
                            <Link
                                urlOrGetUrl={cluster.url}
                                variant="external"/>}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={cluster.serviceRoleReference?.idReference}
                        entityTypeName={Contract.TypeNames.AwsIamRole}
                        key="roleReference"
                        location="all"
                        title={localization.info.serviceRoleReference()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={clusterModel.nodeGroupIds}
                        entityTypeName={Contract.TypeNames.AwsEksClusterNodeGroup}
                        key="nodeGroupIds"
                        title={localization.info.nodeGroupIds()}/>,
                    _.isEmpty(clusterModel.autoScalingGroupIds)
                        ? undefined
                        : <EntitiesInfoItem
                            entityIdsOrModels={clusterModel.autoScalingGroupIds}
                            entityTypeName={Contract.TypeNames.AwsAutoScalingAutoScalingGroup}
                            key="autoScalingGroupIds"
                            title={localization.info.autoScalingGroupIds()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={cluster.network.vpcId}
                        entityTypeName={Contract.TypeNames.AwsEc2Vpc}
                        key="networkVpcId"
                        location="miniGlance"
                        title={localization.info.network.vpcId()}/>
                ]}
                options={options?.infoOptions}>
                <ClusterNetworkingInfoCard clusterNetwork={cluster.network}/>
                {!_.isEmpty(clusterModel.fargateProfileIds) &&
                    <FargateProfileInfoCard clusterModel={clusterModel}/>}
            </Info>
    });
}