import { useLocalization } from "@infrastructure";
import { Contract, CustomerConsoleAppUrlHelper } from "../../../../../../../../../common";

export function useGetCiTranslator() {
    const localization =
        useLocalization(
            "views.customer.configuration.licensing.hooks.useGetCustomerLicenseObjectInfo.hooks.useGetCiTranslator",
            () => ({
                [Contract.CustomerLicenseObjectType.CiContainerImageRepository]: "Container Image Repositories"
            }));

    return () => ({
        link: CustomerConsoleAppUrlHelper.getEntitiesRelativeUrl(Contract.TypeNames.CiContainerImageRepository),
        title: localization[Contract.CustomerLicenseObjectType.CiContainerImageRepository]()
    });
}