import { useLocalization } from "@infrastructure";
import { Grid, Typography } from "@mui/material";
import React, { Fragment } from "react";

export function Custom() {
    const localization =
        useLocalization(
            "views.system.exportReport.riskPolicyReport.item.title.custom",
            () => ({
                name: "Policy",
                results: "Results",
                severity: "Severity",
                tenantType: "Platform"
            }));
    return (
        <Fragment>
            <Grid
                item={true}
                xs={1}>
                <Typography variant="h5">
                    {localization.tenantType()}
                </Typography>
            </Grid>
            <Grid
                item={true}
                xs={true}>
                <Typography variant="h5">
                    {localization.name()}
                </Typography>
            </Grid>
            <Grid
                item={true}
                xs={3}>
                <Typography variant="h5">
                    {localization.severity()}
                </Typography>
            </Grid>
            <Grid
                item={true}
                xs={3}>
                <Typography variant="h5">
                    {localization.results()}
                </Typography>
            </Grid>
        </Fragment>);
}