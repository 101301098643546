import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../../../common";
import { EntityExternalConsoleLink } from "../../../../components";
import { useGetGcpComputeInstanceRiskContext } from "../../../contexts";

export function useGcpComputeInstanceShieldedNotEnabledRiskInstanceDefinition(riskModel: Contract.GcpComputeInstanceShieldedNotEnabledRiskModel) {
    const risk = riskModel.risk as Contract.GcpComputeInstanceShieldedNotEnabledRisk;
    const instanceModel = entityModelStore.useGet(risk.entityId) as Contract.GcpComputeInstanceModel;

    const getGcpComputeInstanceRiskContext = useGetGcpComputeInstanceRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.useGcpComputeInstanceShieldedNotEnabledRiskDefinition.hooks.useGcpComputeInstanceShieldedNotEnabledRiskInstanceDefinition",
            () => ({
                description: "{{instance}} Shielded VM is not enabled",
                sections: {
                    resolution: {
                        step1: "Click **EDIT**",
                        step2: "In the **Shielded VM** section, select **Turn on vTPM** and **Turn on Integrity Monitoring**",
                        step3: "Click **Save**"
                    }
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            instance:
                <Entity
                    entityIdOrModel={instanceModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={riskModel.risk.entityId}
                key={riskModel.risk.entityId}
                page={Contract.GcpConsolePage.Resource}/>,
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3()
        ],
        riskModel,
        () => {
            const instanceContextItems = getGcpComputeInstanceRiskContext(instanceModel);
            return [
                instanceContextItems.generalInformation,
                instanceContextItems.sensitive,
                instanceContextItems.status,
                instanceContextItems.inboundExternalAccessScope,
                instanceContextItems.vpcsAndSubnets,
                instanceContextItems.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}