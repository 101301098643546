import { ItemWithValidation, Mails, useChangeEffect, useLocalization } from "@infrastructure";
import { FormControl } from "@mui/material";
import _ from "lodash";
import React, { Fragment, useState } from "react";
import { Contract } from "../../../controllers";
import { DeliveryProps } from "../Delivery";
import { Message } from "./Message";

export function SendToOwner({ delivery, disabled, iconVisible, messageOptions, onChange }: DeliveryProps) {
    const [ccMails, setCcMails] =
        useState(
            _.map(
                (delivery as Contract.ResourceOwnerMailDelivery).ccMails ?? [],
                mail => mail));
    const [message, setMessage] = useState(delivery?.message);

    useChangeEffect(
        () =>
            onChange({
                ...(delivery as Contract.ResourceOwnerMailDelivery),
                ccMails,
                message
            } as ItemWithValidation<Contract.ResourceOwnerMailDelivery>),
        [ccMails, message],
        500);

    const localization =
        useLocalization(
            "common.delivery.sendToOwner",
            () => ({
                error: {
                    invalidFormat: "Not a valid mail address",
                    required: "User mail cannot be empty"
                },
                mail: "CC (optional)"
            }));

    return (
        <Fragment>
            <FormControl
                fullWidth={true}
                variant="standard">
                <Mails
                    disabled={disabled}
                    iconVisible={iconVisible}
                    mails={ccMails}
                    placeholder={localization.mail()}
                    onChange={setCcMails}/>
            </FormControl>
            <Message
                disabled={disabled}
                message={message}
                options={messageOptions}
                setMessage={setMessage}/>
        </Fragment>);
}