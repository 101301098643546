﻿import { DataTableColumnExportOptions, Optional, useLocalization } from "@infrastructure";
import _ from "lodash";
import { ExportData } from "../../views/Customer/components/Entities/components";
import { Contract } from "../controllers";
import { useEntityTypeNameTranslator } from "./useEntityTypeNameTranslator";

export function useEntitiesExportOptions() {
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "common.hooks.useEntitiesExportOptions",
            () => ({
                info: {
                    message: "Showing {{limit}} out of {{translatedEntitiesTypeName}}\n{{entities}}"
                }
            }));

    return <TItem extends Contract.IEntityModel>(
        entityTypeName: string,
        getEntityIds: (item: TItem) => Optional<string>[],
        title: string,
        options?: EntitiesExportOptionsOptions): DataTableColumnExportOptions => {
        const limit = 100;
        return {
            getData:
                (item: TItem) => {
                    const entityIds = _.take(getEntityIds(item), limit);
                    return options?.getData?.(entityIds) ?? entityIds;
                },
            getItem:
                (item: TItem, exportData: ExportData) => {
                    const entityIds = _.filter(getEntityIds(item));
                    return ({
                        [title]:
                            entityIds.length <= limit
                                ? _(entityIds).
                                    map(entityId => options?.getValue?.(exportData.relatedEntityModelMap[entityId!]) ?? exportData.relatedEntityModelMap[entityId!].entity.displayReference).
                                    join("\n")
                                : localization.info.message({
                                    entities:
                                        _(entityIds).
                                            take(limit).
                                            map(entityId => options?.getValue?.(exportData.relatedEntityModelMap[entityId!]) ?? exportData.relatedEntityModelMap[entityId!].entity.displayReference).
                                            join("\n"),
                                    limit,
                                    translatedEntitiesTypeName:
                                        entityTypeNameTranslator(
                                            entityTypeName,
                                            {
                                                count: entityIds.length,
                                                includeCount: true,
                                                includeServiceName: false
                                            })
                                })
                    });
                }
        };
    };
}

type EntitiesExportOptionsOptions = {
    getData?: (entityIds: Optional<string>[]) => any;
    getValue?: (entityModel: Contract.EntityModel) => string;
};