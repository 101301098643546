import { InlineItems, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, Entity, entityModelStore, useEntityTypeNameTranslator } from "../../../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../../../tenants";
import { RiskDefinitionContextItem } from "../../../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../../../useCommonSectionsAndDescriptionDefinition";
import { useGetAwsEc2InstancesRiskContext, useGetAwsEksClusterRiskContext } from "../../../contexts";
import { useGetAwsEc2InstanceMetadataServiceTokenHopMaxCountRiskContext } from "./useGetAwsEc2InstanceMetadataServiceTokenHopMaxCountRiskContext";

export function useAwsEc2InstanceMetadataServiceTokenHopMaxCountRiskClusterDefinition(riskModel: Contract.AwsEc2InstanceMetadataServiceTokenHopMaxCountRiskModel) {
    const risk = riskModel.risk as Contract.AwsEc2InstanceMetadataServiceTokenHopMaxCountRisk;
    const clusterModel = entityModelStore.useGet(risk.entityId) as Contract.AwsEksClusterModel;
    const cluster = (clusterModel.entity) as Contract.AwsEksCluster;
    const instanceModels = entityModelStore.useGet(riskModel.risk.aggregatedEntityIds) as Contract.AwsEc2InstanceModel[];
    const karpenterNodeClassNames =
        useMemo(
            () =>
                _(instanceModels).
                    map(instanceModel => (instanceModel.entity as Contract.AwsEc2Instance).karpenterNodeClassName).
                    filter().
                    uniq().
                    value(),
            [riskModel]);

    const getAwsEc2InstanceMetadataServiceTokenHopMaxCountRiskContext = useGetAwsEc2InstanceMetadataServiceTokenHopMaxCountRiskContext();
    const getAwsEc2InstancesRiskContext = useGetAwsEc2InstancesRiskContext();
    const getAwsEksClusterRiskContext = useGetAwsEksClusterRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsEc2InstanceMetadataServiceTokenHopMaxCountRiskDefinition.hooks.useAwsEc2InstanceMetadataServiceTokenHopMaxCountRiskClusterDefinition",
            () => ({
                contextItems: {
                    karpenter: "The {{translatedClusterTypeName}} runs **Karpenter**"
                },
                description: "{{cluster}} runs EC2 instances with metadata response hop limit greater than {{maxTokenHopMaxCount}}",
                sections: {
                    resolution: {
                        step1: "Setting the hop limit to {{maxTokenHopMaxCount}} can restrict network communications beyond the local network, potentially affecting applications that rely on multiple hops. Carefully evaluate your specific networking requirements, and any potential impact before implementing this change.",
                        step2: {
                            karpenter: {
                                false: "The {{translatedClusterTypeName}} is spinning up and down EC2 instances. Please update the cluster’s configuration to ensure the instances are launched with the correct configuration",
                                karpenterNodeClasses: [
                                    "1 Karpenter EC2 Node Class",
                                    "{{count | NumberFormatter.humanize}} Karpenter EC2 Node Classes"
                                ],
                                true: "The {{translatedClusterTypeName}} is using **Karpenter** to spin up and down EC2 instances. Update the template on {{karpenterNodeClasses}} to run the instance with hop limit of {{maxTokenHopMaxCount}}."
                            }
                        }
                    }
                }
            }));

    const translatedClusterTypeName =
        entityTypeNameTranslator(
            cluster.typeName,
            { variant: "text" });

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            cluster:
                <Entity
                    entityIdOrModel={clusterModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>,
            maxTokenHopMaxCount: risk.maxTokenHopMaxCount
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.Eks,
                AwsConsoleUrlBuilder.getEksClusterUrl(cluster)),
            localization.sections.resolution.step1({ maxTokenHopMaxCount: risk.maxTokenHopMaxCount }),
            _.isEmpty(karpenterNodeClassNames)
                ? localization.sections.resolution.step2.karpenter.false({ translatedClusterTypeName })
                : localization.sections.resolution.step2.karpenter.true({
                    karpenterNodeClasses:
                        <InlineItems
                            items={karpenterNodeClassNames}
                            namePluralizer={localization.sections.resolution.step2.karpenter.karpenterNodeClasses}
                            variant="itemCountAndType"/>,
                    maxTokenHopMaxCount: risk.maxTokenHopMaxCount,
                    translatedClusterTypeName
                })
        ],
        riskModel,
        () => {
            const clusterContextItems = getAwsEksClusterRiskContext(clusterModel);
            const instanceMetadataServiceTokenHopMaxCountRiskContextItems = getAwsEc2InstanceMetadataServiceTokenHopMaxCountRiskContext(riskModel);
            const instancesContextItems = getAwsEc2InstancesRiskContext(instanceModels);
            return _.filter(
                [
                    clusterContextItems.generalInformation,
                    clusterContextItems.sensitive,
                    _.isEmpty(karpenterNodeClassNames)
                        ? undefined
                        : new RiskDefinitionContextItem(localization.contextItems.karpenter({ translatedClusterTypeName })),
                    instanceMetadataServiceTokenHopMaxCountRiskContextItems.instances,
                    instanceMetadataServiceTokenHopMaxCountRiskContextItems.permissionActions,
                    instanceMetadataServiceTokenHopMaxCountRiskContextItems.instancePublicAccess,
                    instancesContextItems.runningInstances,
                    instancesContextItems.sensitive,
                    clusterContextItems.getOpenRiskedEntityRisksContextItem(riskModel.id)
                ]);
        });
}