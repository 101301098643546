﻿import { CheckboxField, useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { RiskPoliciesType, ScopeLabel } from "../../../../../../../../../common";
import { useGetInheritedOverridableValue } from "../../../../../hooks";
import { useConfigurationContext, useSetConfigurationContext } from "../../../Configuration";

export function InheritToggle() {
    const { item, riskPolicyConfiguration } = useConfigurationContext();
    const setConfigurationContext = useSetConfigurationContext();
    const { inherited, inheritedScopeId, inheritedValue: inheritedEnabled } =
        useGetInheritedOverridableValue(
            riskPolicyConfiguration => riskPolicyConfiguration.enabled,
            RiskPoliciesType.KubernetesAdmissionController,
            riskPolicyConfiguration,
            item);

    const localization =
        useLocalization(
            "views.customer.riskPolicies.configuration.general.inheritToggle",
            () => ({
                description: "Inherit policy configuration",
                title: "Inheritance"
            }));

    return (
        <Stack>
            <Typography variant="h4">
                {localization.title()}
            </Typography>
            <CheckboxField
                checked={inherited}
                onChange={
                    () =>
                        setConfigurationContext(
                            configurationContext =>
                                ({
                                    ...configurationContext,
                                    riskPolicyConfiguration: {
                                        ...configurationContext.riskPolicyConfiguration,
                                        enabled:
                                            _.isNil(configurationContext.riskPolicyConfiguration.enabled)
                                                ? inheritedEnabled
                                                : undefined
                                    }
                                }))}>
                <ScopeLabel
                    inheritedScopeId={inheritedScopeId}
                    text={localization.description()}/>
            </CheckboxField>
        </Stack>);
}