import { FunctionComponentProps } from "@infrastructure";
import _ from "lodash";
import { FunctionComponent, FunctionComponentElement, ReactNode } from "react";

export function getChildElements<TChildElementComponent extends FunctionComponent<any>>(
    childElements: ReactNode[],
    childElementComponent: TChildElementComponent):
    FunctionComponentElement<FunctionComponentProps<TChildElementComponent>>[] {
    return _.filter(
        childElements as FunctionComponentElement<any>[],
        childElement => isComponentType(childElement, childElementComponent));
}

export function isComponentType<TElementComponent extends FunctionComponent<any>>(
    element: ReactNode,
    elementComponent: TElementComponent): boolean {
    return Object.is((element as FunctionComponentElement<any>)?.type, elementComponent);
}