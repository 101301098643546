import { Divider, Stack, Typography } from "@mui/material";
import _, { Function0 } from "lodash";
import React, { useCallback, useMemo } from "react";
import { EmptyMessage, Link, useLocalization, useSetRoute } from "@infrastructure";
import { Contract, CustomerConsoleAppUrlHelper, DataCategories, dataCategories, DataSensitivities, dataSensitivities, RatioGaugeChart, useTheme, WidgetDefinition } from "../../../../../../..";
import { SummaryDashboardContext, useDashboardContext } from "../../../../../Dashboard";

export function useGetDataAnalysisDefinition(): () => WidgetDefinition {
    const localization =
        useLocalization(
            "common.dashboard.widget.hooks.useDefinition.hooks.useGetDataAnalysisDefinition",
            () => ({
                helpText: "View sensitive resources, classified by category and sensitivity.",
                title: "Data Resources"
            }));

    return useCallback<Function0<WidgetDefinition>>(
        () => ({
            element: <DataAnalysis/>,
            options: {
                helpText: localization.helpText(),
                title: localization.title(),
                variant: "scrollable"
            }
        }),
        [localization]);
}

function DataAnalysis() {
    const { summary } = useDashboardContext<SummaryDashboardContext>();
    const setRoute = useSetRoute();

    const localization =
        useLocalization(
            "common.dashboard.widget.hooks.useDefinition.hooks.useGetDataAnalysisDefinition.dataAnalysis",
            () => ({
                dataCategories: "Data Categories",
                dataSensitivity: "Data Sensitivity",
                empty: "No Sensitive Resources",
                outOfTotalMonitoredResources: "Out of total monitored resources",
                sensitiveResources: "Sensitive Resources",
                sensitiveResourcesRatio: "{{count}}/{{total}}",
                unmonitoredResources: "{{count}} unmonitored resources"
            }));

    const dataCategoryToCountMap =
        useMemo(
            () =>
                _.defaults(
                    {},
                    summary.dataCategoryToScannedDataAnalysisResourceCountMap,
                    _.mapValues(
                        Contract.DataCategory,
                        () => 0)) as NonNullable<typeof summary.dataCategoryToScannedDataAnalysisResourceCountMap>,
            [summary.dataCategoryToScannedDataAnalysisResourceCountMap]);

    const dataSensitivityToCountMap =
        useMemo(
            () =>
                _.defaults(
                    {},
                    summary.dataSensitivityToScannedDataAnalysisResourceCountMap,
                    _.mapValues(
                        Contract.DataSensitivity,
                        () => 0)) as NonNullable<typeof summary.dataSensitivityToScannedDataAnalysisResourceCountMap>,
            [summary.dataSensitivityToScannedDataAnalysisResourceCountMap]);

    const theme = useTheme();
    return (
        summary.sensitiveScannedDataAnalysisResourceCount === 0
            ? <EmptyMessage
                message={localization.empty()}
                verticalCenter={true}/>
            : <Stack
                divider={
                    <Divider
                        flexItem={true}
                        sx={{ margin: theme.spacing(3, 0) }}/>}
                justifyContent="space-around"
                sx={{ height: "calc(100% - 16px)" }}>
                <Stack
                    alignItems="center"
                    className="ratioGaugeChartSection"
                    direction="row"
                    sx={{ padding: theme.spacing(0.25, 3.5) }}>
                    <RatioGaugeChart
                        count={summary.sensitiveScannedDataAnalysisResourceCount}
                        labelFontSize={34}
                        thickness={8}
                        total={summary.scannedDataAnalysisResourceCount}
                        width={164}/>
                    <Stack sx={{ marginLeft: "min(48px, 8%)" }}>
                        <Link
                            urlOrGetUrl={CustomerConsoleAppUrlHelper.getDataAnalysisResourceRelativeUrl({ dataSensitivities: _.values(Contract.DataSensitivity) })}
                            variant="text">
                            <Typography
                                sx={{
                                    fontSize: 18,
                                    fontWeight: 600
                                }}>
                                {localization.sensitiveResourcesRatio({
                                    count: summary.sensitiveScannedDataAnalysisResourceCount,
                                    total: summary.scannedDataAnalysisResourceCount
                                })}
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: 14,
                                    fontWeight: 600
                                }}>
                                {localization.sensitiveResources()}
                            </Typography>
                            <Typography
                                sx={{
                                    color: theme.palette.text.secondary,
                                    fontSize: 12,
                                    fontWeight: 400
                                }}>
                                {localization.outOfTotalMonitoredResources()}
                            </Typography>
                        </Link>
                        <Divider
                            flexItem={true}
                            orientation="horizontal"
                            sx={{ margin: theme.spacing(0.75, 0) }}/>
                        <Link
                            urlOrGetUrl={
                                CustomerConsoleAppUrlHelper.getDataAnalysisResourceRelativeUrl(
                                    {
                                        dataResourceScanStatuses: [
                                            Contract.DataAnalysisResourceModelDataAnalysisStatus.Failed,
                                            Contract.DataAnalysisResourceModelDataAnalysisStatus.Disabled
                                        ]
                                    })}
                            variant="text">
                            <Typography
                                sx={{
                                    fontSize: 12,
                                    fontWeight: 600
                                }}>
                                {localization.unmonitoredResources({ count: summary.notScannedDataAnalysisResourceCount })}
                            </Typography>
                        </Link>
                    </Stack>
                </Stack>
                <Stack
                    className="dataCategoriesSection"
                    sx={{ padding: theme.spacing(0.25, 3.5) }}>
                    <Typography
                        sx={{ marginBottom: theme.spacing(2) }}
                        variant="h4">
                        {localization.dataCategories()}
                    </Typography>
                    <DataCategories
                        categories={dataCategories}
                        categoryToCountMap={dataCategoryToCountMap}
                        onClick={category => setRoute(CustomerConsoleAppUrlHelper.getDataAnalysisResourceRelativeUrl({ dataCategories: [category] }))}/>
                </Stack>
                <Stack
                    className="dataSensitivitiesSection"
                    sx={{ padding: theme.spacing(0.25, 3.5) }}>
                    <Typography
                        sx={{ marginBottom: theme.spacing(2) }}
                        variant="h4">
                        {localization.dataSensitivity()}
                    </Typography>
                    <DataSensitivities
                        sensitivities={dataSensitivities}
                        sensitivityToCountMap={dataSensitivityToCountMap}
                        onClick={sensitivity => setRoute(CustomerConsoleAppUrlHelper.getDataAnalysisResourceRelativeUrl({ dataSensitivities: [sensitivity] }))}/>
                </Stack>
            </Stack>);
}