import { DialogMenuItem, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, tenantModelStore, usePrincipalModelAccess } from "../../../../../../../../../../common";
import { GenerateGcpIdentityNonexcessiveRoleDialog } from "../../../../../../../../../../tenants";
import { EntitiesInfoCard, GciIdentityActivityInfoItem, Info, PrincipalSecurityStatsStat } from "../../../../components";
import { GenerateEntityNonexcessivePermitterIcon } from "../../../../icons";
import { GciDirectoryPrincipalGcpRoleBindings } from "../../components";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../useDefinition";
import { useGciDefaultPartialPrincipalInfoItemElements } from "./useGciDefaultDirectoryPartialPrincipalInfoItemElements";
import { useGciDefaultEntityInfoItemElements } from "./useGciDefaultEntityInfoItemElements";

export function useGciDirectoryPartialUserDefinition(entityModel: Contract.GciEntityModel, options?: DefinitionOptions) {
    const activeCloudProviderTenantTypes = tenantModelStore.useGetActiveCloudProviderTenantTypes();
    const defaultPartialPrincipalInfoItemElements = useGciDefaultPartialPrincipalInfoItemElements(entityModel);
    const defaultResourceInfoItemElements = useGciDefaultEntityInfoItemElements(entityModel);
    const partialUserModel = entityModel as Contract.GciDirectoryPartialUserModel;
    const partialUserModelAccess = usePrincipalModelAccess<Contract.UserModelAccess>(partialUserModel.id);

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gci.useGciDirectoryPartialUserDefinition",
            () => ({
                cards: {
                    groupIds: "Group Memberships"
                },
                tabs: {
                    gcpRoleBindings: "Bindings"
                },
                topbar: {
                    menu: {
                        generate: "Generate Custom Role"
                    }
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs:
            _<EntityProfileDefinitionTab>([]).
                concatIf(
                    _.includes(activeCloudProviderTenantTypes, Contract.TenantType.Gcp),
                    new EntityProfileDefinitionTab(
                        ProfileCategory.Iam,
                        <GciDirectoryPrincipalGcpRoleBindings
                            csvExportFilePrefix={localization.tabs.gcpRoleBindings()}
                            gcpRoleBindingIds={partialUserModel.gcpRoleBindingIds}
                            principalId={partialUserModel.id}/>,
                        localization.tabs.gcpRoleBindings(),
                        "gcpRoleBindings")).
                value(),
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={[]}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    ...defaultPartialPrincipalInfoItemElements,
                    <GciIdentityActivityInfoItem
                        identityModel={partialUserModel}
                        key="activityTime"/>
                ]}
                options={options?.infoOptions}>
                <EntitiesInfoCard
                    entityIdsOrModels={partialUserModel.groupIds}
                    entityTypeName={Contract.TypeNames.GciDirectoryGroup}
                    title={localization.cards.groupIds()}/>
            </Info>,

        sensitive: false,
        statElements:
            _.filter([
                _.isNil(partialUserModelAccess?.permissions)
                    ? undefined
                    : <PrincipalSecurityStatsStat
                        analyzingReason={partialUserModel.analyzing?.reason}
                        analyzingTenants={
                            _.isNil(partialUserModel.analyzing?.tenantIds)
                                ? undefined
                                : {
                                    ids: partialUserModel.analyzing!.tenantIds,
                                    typeName: Contract.TypeNames.GcpTenantEntity
                                }}
                        securityStats={partialUserModelAccess.permissions!.excessivePermissionActionStats}
                        typeName={Contract.TypeNames.GciDirectoryPartialUser}/>
            ]),
        topbarMenuItems:
            _.isNil(partialUserModel.systemDeletionTime)
                ? [
                    new DialogMenuItem(
                        onClose =>
                            <GenerateGcpIdentityNonexcessiveRoleDialog
                                identityModel={partialUserModel}
                                onClose={onClose}/>,
                        localization.topbar.menu.generate(),
                        { icon: <GenerateEntityNonexcessivePermitterIcon/> })
                ]
                : undefined
    });
}