import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract } from "../../../../../../..";
import { Entity } from "../../../../../..";

export function useAwsDeleteUserChangeDefinition(change: Contract.AwsChange, entityLinkDisabled?: boolean) {
    const deleteUserChange = change as Contract.AwsDeleteUserChange;
    const localization =
        useLocalization(
            "common.change.hooks.useDefinition.hooks.useAwsDefinition.hooks.useAwsDeleteUserChangeDefinition",
            () => ({
                title: "Delete {{user}}"
            }));
    return {
        title:
            localization.title({
                user:
                    <Entity
                        entityIdOrModel={deleteUserChange.resourceId}
                        glanceOptions={{ disabled: true }}
                        linkOptions={{ disabled: entityLinkDisabled }}
                        variant="typeText"/>
            })
    };
}