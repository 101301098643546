import { useLocalization } from "@infrastructure";
import { Contract } from "../../../common";

export function useAzureManagedIdentityManagedIdentityModelTypeTranslator() {
    const localization =
        useLocalization(
            "tenants.azure.hooks.useAzureManagedIdentityManagedIdentityModelTypeTranslator",
            () => ({
                [Contract.TypeNames.AzureManagedIdentityManagedIdentityModelType]: {
                    [Contract.AzureManagedIdentityManagedIdentityModelType.System]: "System Assigned Managed Identity",
                    [Contract.AzureManagedIdentityManagedIdentityModelType.User]: "User Assigned Managed Identity"
                }
            }));
    return (azureManagedIdentityManagedIdentityModelType: Contract.AzureManagedIdentityManagedIdentityModelType) =>
        localization[Contract.TypeNames.AzureManagedIdentityManagedIdentityModelType][azureManagedIdentityManagedIdentityModelType]();
}