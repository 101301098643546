import { MenuItem, UnexpectedError } from "@infrastructure";
import { SxProps } from "@mui/material";
import { FunctionComponentElement, ReactNode, useMemo } from "react";
import { Contract, EntityTypeMetadataModelHelper } from "../../../../../../../../common";
import { InfoProps } from "../../components";
import { useAadDirectoryEntityDefinition, useAwsEntityDefinition, useAzureEntityDefinition, useCiEntityDefinition, useGciEntityDefinition, useGcpEntityDefinition, useOciEntityDefinition, useOktaDirectoryEntityDefinition, useOneLoginDirectoryEntityDefinition, useOpEntityDefinition, usePingIdentityDirectoryEntityDefinition } from "./hooks";

export type DefinitionOptions = {
    infoOptions?: DefinitionOptionsInfoOptions;
};

export type DefinitionOptionsInfoOptions = {
    data?: any;
    itemOptions?: DefinitionOptionsInfoOptionsItemOptions;
    variant?: DefinitionOptionsInfoOptionsVariant;
};

export type DefinitionOptionsInfoOptionsVariant = "miniGlance" | "profile";

type DefinitionOptionsInfoOptionsItemOptions = {
    titleSx?: SxProps;
    valueSx?: SxProps;
};

export function useDefinition(entityModel: Contract.EntityModel, options?: DefinitionOptions) {
    const useDefinition =
        useMemo(
            () => {
                switch (EntityTypeMetadataModelHelper.get(entityModel.entity.typeName).tenantType) {
                    case Contract.TenantType.Aad:
                        return useAadDirectoryEntityDefinition;
                    case Contract.TenantType.Aws:
                        return useAwsEntityDefinition;
                    case Contract.TenantType.Azure:
                        return useAzureEntityDefinition;
                    case Contract.TenantType.Ci:
                        return useCiEntityDefinition;
                    case Contract.TenantType.Gci:
                        return useGciEntityDefinition;
                    case Contract.TenantType.Gcp:
                        return useGcpEntityDefinition;
                    case Contract.TenantType.Oci:
                        return useOciEntityDefinition;
                    case Contract.TenantType.Okta:
                        return useOktaDirectoryEntityDefinition;
                    case Contract.TenantType.OneLogin:
                        return useOneLoginDirectoryEntityDefinition;
                    case Contract.TenantType.Op:
                        return useOpEntityDefinition;
                    case Contract.TenantType.PingIdentity:
                        return usePingIdentityDirectoryEntityDefinition;
                    default:
                        throw new UnexpectedError("entityModel.entity.typeName", entityModel.entity.typeName);
                }
            },
            []);

    return useDefinition(entityModel, options);
}

export class EntityProfileDefinition {
    constructor(public options: EntityProfileDefinitionOptions) {
    }
}

export enum ProfileCategory {
    Code = "code",
    DataAnalysis = "dataAnalysis",
    Events = "activityLog",
    Iam = "iam",
    Network = "network",
    Overview = "overview",
    Risks = "risks",
    WorkloadAnalysis = "workloadAnalysis"
}

export class EntityProfileDefinitionTab {
    constructor(
        public category: ProfileCategory,
        public element: ReactNode,
        public title: string,
        public view: string) {
    }
}

type EntityProfileDefinitionOptions = {
    additionalTabs?: EntityProfileDefinitionTab[];
    infoElement?: FunctionComponentElement<InfoProps>;
    sensitive?: boolean;
    statElements?: ReactNode[];
    topbarMenuItems?: MenuItem[];
};