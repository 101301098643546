import React from "react";
import { EntityAttributesCell, entityModelStore } from "../../../../../../../common";

type EntityEntityAttributesCellProps = {
    entityId: string;
};

export function EntityEntityAttributesCell({ entityId }: EntityEntityAttributesCellProps) {
    const entityModel = entityModelStore.useGet(entityId);

    return (
        <EntityAttributesCell
            entityAttributes={entityModel.attributes.attributes}
            entityTypeName={entityModel.entity.typeName}/>);
}