import { ActionMenuItem, MenuItem, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useCallback, useMemo } from "react";
import { IgnoreDialog } from "../../../..";
import { Contract, riskPolicyModelStore, RiskStatusIcon, RiskType, RiskTypeMetadataModelHelper, UserHelper } from "../../../../../../../../../common";
import { ActionDialogType, useActionsContext } from "../../../Actions";
import { ActionsDefinition } from "../useDefinition";
import { useCommonItems } from "./useCommonItems";

export function useCloudDefinition(riskModel: Contract.RiskModel): ActionsDefinition {
    const riskPolicyConfiguration = riskPolicyModelStore.useGet(riskModel.risk.policyId)?.riskPolicyConfiguration;
    const riskTypeMetadataModel = RiskTypeMetadataModelHelper.get(riskModel.risk.typeName);
    const { onCloseDialog, setDialogType } = useActionsContext();
    const { additionalElements, getItemDialog: commonGetItemDialog, menuItems: commonMenuItems } = useCommonItems(riskModel, RiskType.Cloud);

    const localization =
        useLocalization(
            "views.customer.risks.actions.hooks.useDefinition.hooks.useCloudDefinition",
            () => ({
                actions: {
                    updateStatus: {
                        [Contract.RiskStatus.Ignored]: {
                            disabled: "This policy does not support ignoring findings",
                            text: {
                                add: "Ignore",
                                edit: "Edit Ignore"
                            }
                        }
                    }
                }
            }));

    const getItemDialog =
        useCallback(
            (type: ActionDialogType) => {
                switch (type) {
                    case ActionDialogType.Ignore:
                        return (
                            <IgnoreDialog
                                edit={riskModel.risk.status === Contract.RiskStatus.Ignored}
                                riskIdsOrModel={riskModel}
                                riskType={RiskType.Cloud}
                                onClose={
                                    canceled =>
                                        onCloseDialog(
                                            riskModel,
                                            Contract.RiskStatus.Ignored,
                                            canceled)}/>);
                    default:
                        return commonGetItemDialog(type);
                }
            },
            [commonGetItemDialog, riskModel, onCloseDialog]);
    const menuItems =
        useMemo(
            () =>
                _<MenuItem>([]).
                    concatIf(
                        !_.isNil(commonMenuItems.close),
                        commonMenuItems.close!).
                    concatIf(
                        UserHelper.hasScopePermissions(riskPolicyConfiguration?.scopeId, Contract.IdentityPermission.SecurityWrite) &&
                        riskModel.risk.status !== Contract.RiskStatus.Closed,
                        () =>
                            new ActionMenuItem(
                                () => setDialogType(ActionDialogType.Ignore),
                                riskModel.risk.status === Contract.RiskStatus.Ignored
                                    ? localization.actions.updateStatus[Contract.RiskStatus.Ignored].text.edit()
                                    : localization.actions.updateStatus[Contract.RiskStatus.Ignored].text.add(),
                                {
                                    disabled: _.isEmpty(riskTypeMetadataModel.exclusionEntityTypeNames),
                                    icon: <RiskStatusIcon status={Contract.RiskStatus.Ignored}/>,
                                    tooltip:
                                        _.isEmpty(riskTypeMetadataModel.exclusionEntityTypeNames)
                                            ? localization.actions.updateStatus[Contract.RiskStatus.Ignored].disabled()
                                            : undefined
                                })).
                    concatIf(
                        !_.isNil(commonMenuItems.open),
                        commonMenuItems.open!).
                    concatIf(
                        !_.isNil(commonMenuItems.separator),
                        commonMenuItems.separator!).
                    concatIf(
                        !_.isNil(commonMenuItems.comment),
                        commonMenuItems.comment!).
                    concatIf(
                        !_.isNil(commonMenuItems.sendRisksMail),
                        commonMenuItems.sendRisksMail!).
                    concatIf(
                        !_.isNil(commonMenuItems.sendRisksMailPerOwner),
                        commonMenuItems.sendRisksMailPerOwner!).
                    concatIf(
                        !_.isNil(commonMenuItems.sendRiskWebhook),
                        commonMenuItems.sendRiskWebhook!).
                    concatIf(
                        (!_.isNil(commonMenuItems.jiraCreate) || !_.isNil(commonMenuItems.serviceNowCreate)) &&
                        !_.isNil(commonMenuItems.separator),
                        commonMenuItems.separator!).
                    concatIf(
                        !_.isNil(commonMenuItems.jiraCreate),
                        commonMenuItems.jiraCreate!).
                    concatIf(
                        !_.isNil(commonMenuItems.jiraUpdate),
                        commonMenuItems.jiraUpdate!).
                    concatIf(
                        !_.isNil(commonMenuItems.serviceNowCreate),
                        commonMenuItems.serviceNowCreate!).
                    concatIf(
                        !_.isNil(commonMenuItems.serviceNowUpdate),
                        commonMenuItems.serviceNowUpdate!).
                    value(),
            [commonMenuItems, riskModel, riskPolicyConfiguration]);

    return useMemo(
        () => ({
            additionalElements,
            getItemDialog,
            menuItems
        }),
        [additionalElements, menuItems, getItemDialog]);
}