import { NumberFormatter, useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import React from "react";
import { Contract, InfoItem } from "../../../../../../../common";

type SecurityStatsStatProps = {
    securityStats: Contract.SecurityStats;
};

export function SecurityStatsStat({ securityStats }: SecurityStatsStatProps) {
    const localization =
        useLocalization(
            "views.customer.entities.profile.securityStatsStat",
            () => ({
                excessive: "Excessive Permissions"
            }));
    return (
        <InfoItem
            title={localization.excessive()}
            value={
                <Typography noWrap={true}>
                    {securityStats.count === 0
                        ? NumberFormatter.percentage(0)
                        : NumberFormatter.percentage(1 - securityStats.securedCount / securityStats.count)}
                </Typography>}/>);
}