import { Contract } from "../../../../../../../../../../../../../../../../../common";
import { useCommonCodeTableDefinition } from "./useCommonCodeTableDefinition";

export function useRiskTenantDefinition(riskGroup: Contract.RiskControllerGetRiskGroupsResponseRiskGroup) {
    const tenantId = (riskGroup as Contract.RiskControllerGetRiskGroupsResponseRiskTenantGroup).tenantId;

    return useCommonCodeTableDefinition({
        getRiskModelPageRequest:
            (limit, requestFilters, skip, sort) =>
                new Contract.RiskControllerGetTenantGroupCodeRiskModelPageRequest(
                    requestFilters,
                    limit,
                    skip,
                    sort,
                    tenantId)
    });
}