import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useAwsRoute53HostedZoneRecordTypeTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useAwsRoute53HostedZoneRecordTypeTranslator",
            () => ({
                [Contract.TypeNames.AwsRoute53HostedZoneRecordType]: {
                    [Contract.AwsRoute53HostedZoneRecordType.A]: "A",
                    [Contract.AwsRoute53HostedZoneRecordType.Aaaa]: "AAAA",
                    [Contract.AwsRoute53HostedZoneRecordType.Caa]: "CAA",
                    [Contract.AwsRoute53HostedZoneRecordType.Cname]: "CNAME",
                    [Contract.AwsRoute53HostedZoneRecordType.Ds]: "DS",
                    [Contract.AwsRoute53HostedZoneRecordType.Https]: "HTTPS",
                    [Contract.AwsRoute53HostedZoneRecordType.Mx]: "MX",
                    [Contract.AwsRoute53HostedZoneRecordType.Naptr]: "NAPTR",
                    [Contract.AwsRoute53HostedZoneRecordType.Ns]: "NS",
                    [Contract.AwsRoute53HostedZoneRecordType.Ptr]: "PTR",
                    [Contract.AwsRoute53HostedZoneRecordType.Soa]: "SOA",
                    [Contract.AwsRoute53HostedZoneRecordType.Spf]: "SPF",
                    [Contract.AwsRoute53HostedZoneRecordType.Srv]: "SRV",
                    [Contract.AwsRoute53HostedZoneRecordType.Sshfp]: "SSHFP",
                    [Contract.AwsRoute53HostedZoneRecordType.Svcb]: "SVCB",
                    [Contract.AwsRoute53HostedZoneRecordType.Tlsa]: "TLSA",
                    [Contract.AwsRoute53HostedZoneRecordType.Txt]: "TXT"
                }
            }));

    return (type: Contract.AwsRoute53HostedZoneRecordType) =>
        localization[Contract.TypeNames.AwsRoute53HostedZoneRecordType][type]();
}