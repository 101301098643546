import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { useAwsCommonAccessResourceDefinition, useAwsCommonResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, EntitiesCell, ItemSelectionHelper, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";

export function useAwsKmsKeyDefinition(definitionData: DefinitionData) {
    const commonAccessResourceDefinition = useAwsCommonAccessResourceDefinition(definitionData);
    const commonResourceDefinition = useAwsCommonResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.aws.useAwsKmsKeyDefinition",
            () => ({
                columns: {
                    awsManaged: {
                        false: "Customer managed",
                        title: "Management",
                        true: "AWS managed"
                    },
                    encryptedResources: "Encrypted Resources",
                    status: {
                        disabled: "Disabled",
                        enabled: "Enabled",
                        title: "Status"
                    }
                }
            }));

    function getAwsManagedTranslation(kmsKeyModel: Contract.EntityModel) {
        return (kmsKeyModel.entity as Contract.AwsKmsKey).awsManaged
            ? localization.columns.awsManaged.true()
            : localization.columns.awsManaged.false();
    }

    function getEnabledTranslation(kmsKeyModel: Contract.EntityModel) {
        return (kmsKeyModel.entity as Contract.AwsKmsKey).enabled
            ? localization.columns.status.enabled()
            : localization.columns.status.disabled();
    }

    return new EntityTableDefinition(
        _.filter(
            [
                commonResourceDefinition.columns.tenantColumn,
                commonResourceDefinition.columns.creationTimeColumn,
                commonResourceDefinition.columns.creatorIdentityCsvColumn,
                commonResourceDefinition.columns.creatorOriginatorEntityCsvColumn,
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsKmsKeyModel) => ({
                                [localization.columns.awsManaged.title()]: getAwsManagedTranslation(item)
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <ValuesFilter
                                    placeholder={localization.columns.awsManaged.title()}
                                    sorted={false}>
                                    <ValuesFilterItem
                                        title={localization.columns.awsManaged.true()}
                                        value={true}/>
                                    <ValuesFilterItem
                                        title={localization.columns.awsManaged.false()}
                                        value={false}/>
                                </ValuesFilter>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsKmsKeyAwsManaged}
                    itemProperty={getAwsManagedTranslation}
                    key={Contract.EntityModelProperty.AwsKmsKeyAwsManaged}
                    title={localization.columns.awsManaged.title()}/>,
                <DataTableColumn
                    exportOptions={
                        entitiesExportOptions<Contract.AwsKmsKeyModel>(
                            Contract.TypeNames.AwsResource,
                            item => item.encryptedResourceIds,
                            localization.columns.encryptedResources())}
                    id={Contract.EntityModelProperty.AwsKmsKeyEncryptedResources}
                    key={Contract.EntityModelProperty.AwsKmsKeyEncryptedResources}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.AwsKmsKeyModel>) =>
                            <EntitiesCell
                                entityIdsOrModels={item.encryptedResourceIds}
                                entityTypeName={Contract.TypeNames.AwsResource}
                                entityVariant="iconText"/>}
                    sortOptions={{ type: DataTableSortType.Numeric }}
                    title={localization.columns.encryptedResources()}/>,
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsKmsKeyModel) => ({
                                [localization.columns.status.title()]: getEnabledTranslation(item)
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <ValuesFilter
                                    placeholder={localization.columns.status.title()}
                                    sorted={false}>
                                    <ValuesFilterItem
                                        title={localization.columns.status.enabled()}
                                        value={true}/>
                                    <ValuesFilterItem
                                        title={localization.columns.status.disabled()}
                                        value={false}/>
                                </ValuesFilter>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsKmsKeyEnabled}
                    itemProperty={getEnabledTranslation}
                    key={Contract.EntityModelProperty.AwsKmsKeyEnabled}
                    title={localization.columns.status.title()}/>,
                commonAccessResourceDefinition.columns.accessLevelColumn,
                commonResourceDefinition.columns.regionColumn,
                commonResourceDefinition.columns.regionLocationColumn,
                commonResourceDefinition.columns.tagsColumn,
                commonResourceDefinition.columns.attributesColumn,
                commonResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
                commonResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
                commonResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
            ]),
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAwsKmsKeyRequest(
                new Contract.EntityControllerGetEntityModelPageAwsKmsKeyRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsKmsKeyAwsManaged]),
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsKmsKeyEnabled])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags])),
                new Contract.EntityControllerGetEntityModelPageAwsResourceRequestAccess(ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsResourceAccessLevel]))));
}