import { Loading, makeContextProvider, normalizeFilterMap, useFiltersContext, useSetFiltersContext } from "@infrastructure";
import _ from "lodash";
import React, { ReactNode, useCallback, useEffect, useLayoutEffect, useRef } from "react";

type FilterConnectorProps = {
    children: ReactNode;
    filter: any;
    id: string;
    open?: boolean;
    showByDefault?: boolean;
};

type FilterConnectorContext = {
    filter?: any;
    id: string;
    open?: boolean;
    showByDefault?: boolean;
};

export const [useFilterConnectorContext, useSetFilterConnectorContext, useFilterConnectorContextProvider] = makeContextProvider<FilterConnectorContext>();

export function FilterConnector({ children, filter, id, open, showByDefault }: FilterConnectorProps) {
    const [context, setFilterConnectorContext, ContextProvider] = useFilterConnectorContextProvider(() => ({ filter, id, open, showByDefault }));
    const setFiltersContext = useSetFiltersContext();
    const filtersContext = useFiltersContext();

    const onFilterChanged =
        useCallback(
            () => {
                if (!_.isEqual(context.filter, filtersContext.filterMap[id])) {
                    setFiltersContext(
                        filtersContext =>
                            ({
                                ...filtersContext,
                                filterMap: normalizeFilterMap({
                                    ...filtersContext.filterMap,
                                    [id]: context.filter
                                })
                            }));
                }
            },
            [context.filter, filtersContext.filterMap[id]]);

    const contextRef = useRef(context);
    useLayoutEffect(
        () => {
            if (!_.isEqual(contextRef.current, context)) {
                contextRef.current = context;
                onFilterChanged();
            } else if (!_.isEqual(context.filter, filter)) {
                setFilterConnectorContext(
                    context => ({
                        ...context,
                        filter
                    }));
            }
        },
        [onFilterChanged, context, contextRef.current]);
    useEffect(
        () => {
            setFilterConnectorContext(
                context => ({
                    ...context,
                    open
                }));
        },
        [open]);

    return (
        <ContextProvider>
            <Loading container="cell">
                {children}
            </Loading>
        </ContextProvider>);
}