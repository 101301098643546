import { darken } from "@mui/material";
import { useMemo } from "react";
import { ThemeHelper } from "../../utilities";

export interface AttributePalette {
    background: {
        0: string;
        1: string;
        2: string;
        3: string;
        4: string;
        5: string;
        6: string;
        7: string;
        8: string;
        9: string;
        10: string;
        11: string;
        12: string;
        13: string;
        14: string;
        15: string;
        16: string;
        17: string;
    };
    label: string;
}

export interface AvatarPalette {
    customer: {
        background: {
            0: string;
            1: string;
            2: string;
            3: string;
            4: string;
            5: string;
            6: string;
            7: string;
            8: string;
            9: string;
        };
    };
    identity: {
        defaultBackground: string;
        text: string;
    };
}

export interface BorderColorPalette {
    alternate: string;
    error: string;
    hoverFocus: string;
    pageFrame: string;
    primary: string;
}

export interface BorderPalette extends BorderColorPalette {
    action: string;
    transparent: string;
}

export interface ChartPalette {
    grey: string;
    verticalLine: string;
}

export interface DataPalette {
    category: DataCategoryPalette;
    sensitivity: DataSensitivityPalette;
}

export interface DataCategoryPalette {
    pci: string;
    phi: string;
    pii: string;
    secrets: string;
}

export interface DataSensitivityPalette {
    confidential: string;
    private: string;
    proprietary: string;
    public: string;
    restricted: string;
}

export interface InputPalette {
    disabledBackground: string;
    filledBackground: string;
}

export interface RiskPalette {
    access: {
        0: string;
        1: string;
        2: string;
        3: string;
    };
    behavior: {
        0: string;
        1: string;
        2: string;
        3: string;
        4: string;
        5: string;
        6: string;
        7: string;
        8: string;
    };
    category: {
        0: string;
        1: string;
        2: string;
        3: string;
        4: string;
        5: string;
        6: string;
        7: string;
        8: string;
        9: string;
        10: string;
        11: string;
        default: string;
    };
}

export interface SeverityPalette {
    critical: string;
    high: string;
    low: string;
    medium: string;
}

export interface TablePalette {
    disabled: string;
    placeholder: string;
}
export interface TabsPalette {
    selected: string;
}

export interface TextEditorPalette {
    background: string;
    highlightBackground: string;
    insertedLineBackground: string;
    insertedTextBackground: string;
    removedLineBackground: string;
}

export interface ToastPalette {
    error: string;
    info: string;
    success: string;
    warning: string;
}

export interface WorkloadAnalysisPalette {
    epss: string;
    events: {
        0: string;
        1: string;
        2: string;
        3: string;
        4: string;
        5: string;
        6: string;
        7: string;
        8: string;
    };
}

export interface UdmPalette {
    nestingLevel: {
        0: string;
        1: string;
        2: string;
        3: string;
        4: string;
    };
}

type ColorPalette = {
    action: {
        active: string;
        alternate: string;
        alternateHover: string;
        disabled: string;
        hover: string;
        selected: string;
        slider: string;
    };
    attribute: AttributePalette;
    avatar: AvatarPalette;
    background: {
        alternate: string;
        primary: string;
        secondary: string;
    };
    borders: BorderColorPalette;
    chart: ChartPalette;
    data: DataPalette;
    error: {
        dark: string;
        primary: string;
    };
    input: InputPalette;
    link: {
        hover: string;
        primary: string;
        secondary: string;
    };
    logo: string;
    primary: {
        dark: string;
        main: string;
    };
    risk: RiskPalette;
    scrollbar: string;
    severity: SeverityPalette;
    shadows: {
        avatar: string;
        card: string;
        floutItem: string;
        graphDot: string;
        highlight: string;
        selectionActions: string;
        sideNavigation: string;
        tableEdge: string;
        tooltip: string;
    };
    sidebar: {
        hover: string;
        selected: string;
    };
    success: string;
    table: TablePalette;
    tabs: TabsPalette;
    text: {
        disabled: string;
        primary: string;
        secondary: string;
    };
    textEditor: TextEditorPalette;
    toast: {
        error: string;
        info: string;
        success: string;
        warning: string;
    };
    udm: UdmPalette;
    warning: {
        light: string;
        primary: string;
    };
    workload: WorkloadAnalysisPalette;
};

const darkColorPalette: ColorPalette = {
    action: {
        active: "#058EFF",
        alternate: "#35383D",
        alternateHover: "#50555E",
        disabled: "#3D4149",
        hover: "#35383D",
        selected: "#3D4149",
        slider: "#3D4149"
    },
    attribute: {
        background: {
            0: "#2193CB",
            1: "#699EBA",
            2: "#609CA2",
            3: "#4CA591",
            4: "#188A3B",
            5: "#728940",
            6: "#AB5D82",
            7: "#C38486",
            8: "#AF78AD",
            9: "#916F72",
            10: "#B0866E",
            11: "#9A88CE",
            12: "#727EC5",
            13: "#9170A5",
            14: "#8A97CA",
            15: "#9F8A2F",
            16: "#A7A279",
            17: "#9F9E02"
        },
        label: "#282A38"
    },
    avatar: {
        customer: {
            background: {
                0: "#3D57EA",
                1: "#5A3FC8",
                2: "#5B64ED",
                3: "#4BBAF2",
                4: "#AFE4FF",
                5: "#40B99E",
                6: "#75E4CC",
                7: "#F8DF86",
                8: "#FFC700",
                9: "#FF9900"
            }
        },
        identity: {
            defaultBackground: "#0079DD",
            text: "#FFEDED"
        }
    },
    background: {
        alternate: "#35383D",
        primary: "#2B2E33",
        secondary: "#22252A"
    },
    borders: {
        alternate: "#2B2E33",
        error: "#EF5350",
        hoverFocus: "#565B64",
        pageFrame: "#1E2025",
        primary: "#3D4149"
    },
    chart: {
        grey: "#484B53",
        verticalLine: "#C9C9C9"
    },
    data: {
        category: {
            pci: "#E7BE24",
            phi: "#A884EF",
            pii: "#50AAD5",
            secrets: "#49AA7F"
        },
        sensitivity: {
            confidential: "#08BADD",
            private: "#7CBBFF",
            proprietary: "#4F75FF",
            public: "#D8DEE6",
            restricted: "#8D69FF"
        }
    },
    error: {
        dark: "#AA282A",
        primary: "#EF5350"
    },
    input: {
        disabledBackground: "#35383D",
        filledBackground: "#393D43"
    },
    link: {
        hover: "#FFFFFF",
        primary: "#E2E2E3",
        secondary: "#78B6FF"
    },
    logo: "#ffffff",
    primary: {
        dark: darken("#0079DD", 0.1),
        main: "#0079DD"
    },
    risk: {
        access: {
            0: "#FBC503",
            1: "#3D57EA",
            2: "#4BB9F1",
            3: "#70DAC3"
        },
        behavior: {
            0: "#75E4CC",
            1: "#AFE4FF",
            2: "#FBC503",
            3: "#8697FA",
            4: "#4BBAF2",
            5: "#40B99E",
            6: "#3D57EA",
            7: "#F8DF86",
            8: "#5A3FC8"
        },
        category: {
            0: "#3D57EA",
            1: "#8697FA",
            2: "#4BBAF2",
            3: "#AFE4FF",
            4: "#F8DF86",
            5: "#FFC700",
            6: "#75E4CC",
            7: "#40B99E",
            8: "#299F8F",
            9: "#1E5AB2",
            10: "#576BE4",
            11: "#5A3FC8",
            default: "#555E6B"
        }
    },
    scrollbar: "#3D4149",
    severity: {
        critical: "#CE0202",
        high: "#FF6200",
        low: "#757E8D",
        medium: "#E9BA00"
    },
    shadows: {
        avatar: "rgba(0, 0, 0, 0.1)",
        card: "rgba(0, 0, 0, 0.24)",
        floutItem: "rgba(0, 0, 0, 0.4)",
        graphDot: "rgba(0, 0, 0, 0.2)",
        highlight: "rgba(0, 0, 0, 0.1)",
        selectionActions: "rgba(0, 0, 0, 0.40)",
        sideNavigation: "rgba(0, 0, 0, 0.90)",
        tableEdge: "rgba(0, 0, 0, 0.4)",
        tooltip: "rgba(0, 0, 0, 0.4)"
    },
    sidebar: {
        hover: "#3D4149",
        selected: "#50555E"
    },
    success: "#40B99E",
    table: {
        disabled: "#F5F5F5",
        placeholder: "#3D4149"
    },
    tabs: {
        selected: "#A8A7A7"
    },
    text: {
        disabled: "#88888B",
        primary: "#E2E2E3",
        secondary: "#A8A7A7"
    },
    textEditor: {
        background: "#1e1e1e",
        highlightBackground: "#661C18",
        insertedLineBackground: "#373D29",
        insertedTextBackground: "#4B592B",
        removedLineBackground: "#661C18"
    },
    toast: {
        error: "#EF5350",
        info: "#0079DD",
        success: "#64C9BB",
        warning: "#FDD835"
    },
    udm: {
        nestingLevel: {
            0: "rgba(103, 153, 114, 0.10)",
            1: "rgba(69, 151, 193, 0.10)",
            2: "rgba(113, 68, 89, 0.10)",
            3: "rgba(82, 98, 189, 0.10)",
            4: "rgba(139, 125, 53, 0.10)"
        }
    },
    warning: {
        light: "#CCA302",
        primary: "#D5830A"
    },
    workload: {
        epss: "#576BE4",
        events: {
            0: "#40B99E",
            1: "#82CEF3",
            2: "#91A1FF",
            3: "#EACA45",
            4: "#A7D547",
            5: "#FFBCBE",
            6: "#EE7CB4",
            7: "#3D57EA",
            8: "#C8CEE9"
        }
    }
};

const lightColorPalette: ColorPalette = {
    action: {
        active: "#0079DD",
        alternate: "#F1F4F9",
        alternateHover: "#F1F4F9",
        disabled: "#EDF1F7",
        hover: "#F1F4F9",
        selected: "#EAEFF6",
        slider: "#D8DEE6"
    },
    attribute: {
        background: {
            0: "#82CEF3",
            1: "#A9CDDF",
            2: "#7BDFE8",
            3: "#66DCC1",
            4: "#48CB70",
            5: "#A7D547",
            6: "#EE7CB4",
            7: "#FFBCBE",
            8: "#FFAEFC",
            9: "#DB989E",
            10: "#FFC299",
            11: "#C0AEF3",
            12: "#91A1FF",
            13: "#D4B3DF",
            14: "#C8CEE9",
            15: "#EACA45",
            16: "#DCD17B",
            17: "#C3C208"
        },
        label: "#282A38"
    },
    avatar: {
        customer: {
            background: {
                0: "#3D57EA",
                1: "#5A3FC8",
                2: "#5B64ED",
                3: "#4BBAF2",
                4: "#AFE4FF",
                5: "#40B99E",
                6: "#75E4CC",
                7: "#F8DF86",
                8: "#FFC700",
                9: "#FF9900"
            }
        },
        identity: {
            defaultBackground: "#0079DD",
            text: "#FFEDED"
        }
    },
    background: {
        alternate: "#FFFFFF",
        primary: "#FFFFFF",
        secondary: "#F1F4F8"
    },
    borders: {
        alternate: "#D8DEE6",
        error: "#EF5350",
        hoverFocus: "#BAC5D3",
        pageFrame: "#EBF1F8",
        primary: "#D8DEE6"
    },
    chart: {
        grey: "#EDF1F7",
        verticalLine: "#C9C9C9"
    },
    data: {
        category: {
            pci: "#E7BE24",
            phi: "#A884EF",
            pii: "#50AAD5",
            secrets: "#49AA7F"
        },
        sensitivity: {
            confidential: "#08BADD",
            private: "#7CBBFF",
            proprietary: "#4F75FF",
            public: "#D8DEE6",
            restricted: "#8D69FF"
        }
    },
    error: {
        dark: "#E12626",
        primary: "#EF5350"
    },
    input: {
        disabledBackground: "#F8FAFD",
        filledBackground: "#F6FAFE"
    },
    link: {
        hover: "#000000",
        primary: "#1F2E41",
        secondary: "#0079DD"
    },
    logo: "#0B2040",
    primary: {
        dark: darken("#0079DD", 0.1),
        main: "#0079DD"
    },
    risk: {
        access: {
            0: "#FBC503",
            1: "#3D57EA",
            2: "#4BB9F1",
            3: "#70DAC3"
        },
        behavior: {
            0: "#75E4CC",
            1: "#AFE4FF",
            2: "#FBC503",
            3: "#8697FA",
            4: "#4BBAF2",
            5: "#40B99E",
            6: "#3D57EA",
            7: "#F8DF86",
            8: "#5A3FC8"
        },
        category: {
            0: "#3D57EA",
            1: "#8697FA",
            2: "#4BBAF2",
            3: "#AFE4FF",
            4: "#F8DF86",
            5: "#FFC700",
            6: "#75E4CC",
            7: "#40B99E",
            8: "#299F8F",
            9: "#1E5AB2",
            10: "#576BE4",
            11: "#5A3FC8",
            default: "#E0F2FF"
        }
    },
    scrollbar: "#D8DEE6",
    severity: {
        critical: "#CE0202",
        high: "#FF6200",
        low: "#D8DEE6",
        medium: "#E9BA00"
    },
    shadows: {
        avatar: "rgba(0, 0, 0, 0.1)",
        card: "rgba(0, 0, 0, 0.06)",
        floutItem: "rgba(0, 0, 0, 0.10)",
        graphDot: "rgba(0, 0, 0, 0.2)",
        highlight: "rgba(40, 50, 128, 0.08)",
        selectionActions: "rgba(52, 74, 103, 0.12)",
        sideNavigation: "rgba(52, 74, 103, 0.15)",
        tableEdge: "rgba(19, 49, 125, 0.3)",
        tooltip: "rgba(0, 0, 0, 0.1)"
    },
    sidebar: {
        hover: "#FFFFFF",
        selected: "#DAE1EA"
    },
    success: "#40B99E",
    table: {
        disabled: "#F5F5F5",
        placeholder: "#F5F5F5"
    },
    tabs: {
        selected: "#5C728E"
    },
    text: {
        disabled: "#8D9CB0",
        primary: "#1F2E41",
        secondary: "#5C728E"
    },
    textEditor: {
        background: "#fffffe",
        highlightBackground: "#FECFD0",
        insertedLineBackground: "#D9F1E1",
        insertedTextBackground: "#BCE7CB",
        removedLineBackground: "#FFDEDD"
    },
    toast: {
        error: "#EF5350",
        info: "#0079DD",
        success: "#64C9BB",
        warning: "#FDD835"
    },
    udm: {
        nestingLevel: {
            0: "rgba(103, 153, 114, 0.10)",
            1: "rgba(69, 151, 193, 0.10)",
            2: "rgba(113, 68, 89, 0.10)",
            3: "rgba(82, 98, 189, 0.10)",
            4: "rgba(139, 125, 53, 0.10)"
        }
    },
    warning: {
        light: "#E5BB00",
        primary: "#FF9800"
    },
    workload: {
        epss: "#576BE4",
        events: {
            0: "#40B99E",
            1: "#82CEF3",
            2: "#91A1FF",
            3: "#EACA45",
            4: "#A7D547",
            5: "#FFBCBE",
            6: "#EE7CB4",
            7: "#3D57EA",
            8: "#C8CEE9"
        }
    }
};

export enum ThemeType {
    Dark = "Dark",
    Light = "Light",
    System = "System"
}

type ColorTheme = {
    colorPalette: ColorPalette;
    dark: boolean;
    type: ThemeType;
};

const themes: { [key in Exclude<ThemeType, ThemeType.System>]: ColorTheme } = {
    [ThemeType.Dark]: {
        colorPalette: darkColorPalette,
        dark: true,
        type: ThemeType.Dark
    },
    [ThemeType.Light]: {
        colorPalette: lightColorPalette,
        dark: false,
        type: ThemeType.Light
    }
};

export function useColorTheme(type: ThemeType): ColorTheme {
    return useMemo(
        () => themes[ThemeHelper.getThemeType(type)],
        [type]);
}