import _, { Dictionary } from "lodash";
import { AwsResourceArnParser, Contract, TypeHelper } from "../../../common";

export class AwsTenantHelper {
    public static isAccessAdvisorEnabled =
        (entity: Contract.Entity, tenantModelMap: Dictionary<Contract.AwsTenantModel>) => {
            const partitionType = this.tryGetPartitionType(entity, tenantModelMap[entity.tenantId]);

            return _.isNil(partitionType)
                ? _.some(
                    tenantModelMap,
                    tenantModel => tenantModel.configuration.accessAdvisorEnabled)
                : partitionType !== Contract.AwsPartitionType.China;
        };

    public static getTrailEventCategoryToTrailMap =
        (tenantConfigurationTrails?: Contract.AwsTenantConfigurationTrail[]) =>
            _(tenantConfigurationTrails).
                flatMap(
                    trail =>
                        _.map(
                            trail.eventCategories,
                            eventCategory => ({
                                eventCategory,
                                trail
                            }))).
                keyBy(trailData => trailData.eventCategory).
                mapValues(trailData => trailData.trail).
                value();

    public static tryGetPartitionType =
        (entity: Contract.Entity, tenantModel?: Contract.TenantModel) => {
            if (!_.isNil(tenantModel)) {
                return tenantModel.configuration.typeName === Contract.TypeNames.AwsTenantConfiguration
                    ? (tenantModel.configuration as Contract.AwsTenantConfiguration).partitionType
                    : undefined;
            }

            if (TypeHelper.extendOrImplement(entity.typeName, Contract.TypeNames.AwsResource) &&
                !_.isNil((entity as Contract.AwsResource).arn)) {
                return AwsResourceArnParser.getPartitionType((entity as Contract.AwsResource).arn!);
            }

            return undefined;
        };
}