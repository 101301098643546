import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../../common";
import { Vulnerabilities } from "../../../../../../../../../../WorkloadAnalysis";
import { RiskDefinition, RiskDefinitionSection } from "../../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../../useCommonSectionsAndDescriptionDefinition";
import { VulnerabilitiesInlineItems } from "../../../components";
import { getWorkloadResourceVulnerabilityRiskMaxVulnerabilitySeverity, getWorkloadResourceVulnerabilityRiskSeverityValues, useGetWorkloadResourceRiskVulnerabilityRawIds } from "../../../utilities/utilities";
import { useGetContainerImageRepositoryWorkloadAnalysisRiskContext } from "../../contexts";
import { useGetPackageVulnerabilityResolutionSteps } from "./useGetPackageVulnerabilityResolutionSteps";

export function useContainerImageRepositoryVulnerabilityRiskDefinition(riskModel: Contract.RiskModel): RiskDefinition {
    const containerImageRepositoryVulnerabilityRisk = riskModel.risk as Contract.ContainerImageRepositoryVulnerabilityRisk;

    const maxVulnerabilitySeverity = getWorkloadResourceVulnerabilityRiskMaxVulnerabilitySeverity(containerImageRepositoryVulnerabilityRisk);
    const vulnerabilitySeverityToRawIdsMap = useGetWorkloadResourceRiskVulnerabilityRawIds(containerImageRepositoryVulnerabilityRisk);

    const getContainerImageRepositoryWorkloadAnalysisRiskContext = useGetContainerImageRepositoryWorkloadAnalysisRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.workloadAnalysis.hooks.useWorkloadAnalysisDefinition.hooks.useContainerImageRepositoryVulnerabilityRiskDefinition",
            () => ({
                description: "{{riskedEntity}} has {{vulnerabilities}}",
                sections: {
                    resolution: {
                        step1: "Build a new container image version in which all the vulnerabilities have been resolved",
                        step2: "Push the image to the repository"
                    },
                    vulnerabilities: "Vulnerabilities"
                }
            }));

    const containerImageModel = entityModelStore.useGet(containerImageRepositoryVulnerabilityRisk.containerImageId);
    const containerImage =
        containerImageModel.unknown
            ? undefined
            : _.as<Contract.IContainerImage>(containerImageModel.entity);
    const getPackageVulnerabilityResolutionSteps = useGetPackageVulnerabilityResolutionSteps();
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            riskedEntity:
                <Entity
                    entityIdOrModel={containerImageRepositoryVulnerabilityRisk.entityId}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>,
            vulnerabilities: <VulnerabilitiesInlineItems
                severity={maxVulnerabilitySeverity}
                severityVulnerabilityRawIds={vulnerabilitySeverityToRawIdsMap[maxVulnerabilitySeverity]}/>
        }),
        _.isEmpty(containerImageRepositoryVulnerabilityRisk.filePathToPackageNamesMap) &&
        _.isEmpty(containerImageRepositoryVulnerabilityRisk.vulnerabilityPackageNameToResolutionVersionsMap)
            ? () => [
                localization.sections.resolution.step1(),
                localization.sections.resolution.step2()
            ]
            : () =>
                getPackageVulnerabilityResolutionSteps(
                    containerImageRepositoryVulnerabilityRisk.filePathToPackageNamesMap,
                    containerImageRepositoryVulnerabilityRisk.filePathToVulnerabilityRawIdsMap,
                    containerImageRepositoryVulnerabilityRisk.vulnerabilityPackageNameToResolutionVersionsMap),
        riskModel,
        () => {
            const vulnerabilitySeverityToRawIdsMap =
                _.mapValues(
                    _.groupBy(
                        containerImage!.data.vulnerabilities,
                        vulnerability => vulnerability.severity),
                    vulnerabilities => vulnerabilities.map(vulnerability => vulnerability.rawId));

            const containerImageRepositoryWorkloadAnalysisRiskContext =
                getContainerImageRepositoryWorkloadAnalysisRiskContext(
                    containerImageRepositoryVulnerabilityRisk,
                    vulnerabilitySeverityToRawIdsMap);

            return [
                containerImageRepositoryWorkloadAnalysisRiskContext.generalInformation,
                containerImageRepositoryWorkloadAnalysisRiskContext.virtualMachines,
                containerImageRepositoryWorkloadAnalysisRiskContext.getVulnerabilities(),
                ...containerImageRepositoryWorkloadAnalysisRiskContext.severityVulnerabilities,
                containerImageRepositoryWorkloadAnalysisRiskContext.getOpenRiskedEntityRisksContextItem(containerImageRepositoryVulnerabilityRisk.id)
            ];
        },
        {
            sections:
                _.isNil(containerImage) ||
                !containerImage.data.analyzed
                    ? undefined
                    : [
                        new RiskDefinitionSection(
                            <Vulnerabilities
                                entityIds={[containerImageRepositoryVulnerabilityRisk.containerImageId]}
                                severityFilterValues={getWorkloadResourceVulnerabilityRiskSeverityValues(containerImageRepositoryVulnerabilityRisk.severeVulnerabilityMinSeverity ?? Contract.Severity.Critical)}
                                variant="risk"/>,
                            localization.sections.vulnerabilities(),
                            {
                                expandable: true
                            })
                    ]
        });
}