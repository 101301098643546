import { TextViewer, TimeFormatter, TimeHelper, useLocalization, useRoute, useSetRoute, VerticalTabView } from "@infrastructure";
import { Stack } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, CustomerConsoleAppUrlHelper, EntityModelHelper, entityModelStore, eventModelStore, EventsIcon, InfoCard, InfoItem, ProfileLayout, ProfileTopbar, StorageHelper, Tenant, TypeHelper, useTheme } from "../../../../../../common";
import { EntitiesInfoItem } from "../../../Entities/components/Profile/components";
import { CategoryIcon } from "./icons";

export type ProfileProps = {
    eventId: string;
};

export function Profile({ eventId }: ProfileProps) {
    const eventModel = eventModelStore.useGet(eventId);

    const { category } = useRoute(`${CustomerConsoleAppUrlHelper.getEventProfileHashUrl(eventId)}/{category}`);
    const setRoute = useSetRoute();
    const orderedCategories =
        useMemo(
            () => [
                ProfileCategory.Overview,
                ProfileCategory.RawEvent
            ],
            []);

    const currentCategory =
        useMemo(
            () =>
                _.includes(orderedCategories, category)
                    ? category as ProfileCategory
                    : _.first(orderedCategories)!,
            [category, orderedCategories]);

    const localization =
        useLocalization(
            "views.customer.events.profile",
            () => ({
                errorCode: "Result",
                identity: "Identity",
                ipAddress: "IP Address",
                name: "Action",
                originator: "Originator",
                range: "{{startTime | TimeFormatter.shortTime}} - {{endTime | TimeFormatter.shortTime}} ({{aggregatedEventCount | NumberFormatter.humanize}} occurrences)",
                resources: "Resources",
                service: "Service",
                tabs: {
                    [ProfileCategory.Overview]: "Overview",
                    [ProfileCategory.RawEvent]: "Raw Event"
                },
                tenantId: "Account",
                time: "Time",
                title: "Activity Event"
            }));

    const theme = useTheme();
    return (
        <ProfileLayout
            topBarElement={
                <ProfileTopbar
                    icon={<EventsIcon/>}
                    title={localization.title()}/>}>
            <VerticalTabView
                items={
                    _.map(
                        orderedCategories,
                        category => ({
                            icon: <CategoryIcon category={category}/>,
                            title: localization.tabs[category](),
                            view: category
                        }))}
                selectedView={currentCategory}
                storageItem={StorageHelper.customerEventsProfileTabsOpen}
                onSelectedViewChanged={
                    view => {
                        StorageHelper.customerEventsProfileSelectedTab.setValue(view);
                        setRoute(CustomerConsoleAppUrlHelper.getEventProfileHashUrl(eventId, view)!);
                    }}>
                <Stack
                    sx={{
                        height: "100%",
                        overflow: "hidden auto",
                        padding: theme.spacing(2, 2, 0, 2)
                    }}>
                    {currentCategory === ProfileCategory.Overview && (
                        <InfoCard columns={true}>
                            <InfoItem
                                title={localization.time()}
                                value={
                                    `${TimeFormatter.mediumDate(eventModel.event.time)}
                                    ${_.isNil(eventModel.event.aggregatedEventCount) ||
                                    eventModel.event.aggregatedEventCount === 1
                            ? TimeFormatter.longTime(eventModel.event.time)
                            : localization.range({
                                aggregatedEventCount: eventModel.event.aggregatedEventCount,
                                endTime: TimeHelper.addTime(eventModel.event.time, 1, "minutes"),
                                startTime: eventModel.event.time
                            })}`}/>
                            <EntitiesItem
                                entityIds={eventModel.identityIdReference}
                                title={localization.identity()}/>
                            <EntitiesItem
                                entityIds={eventModel.originatorEntityIdReference}
                                title={localization.originator()}/>
                            <InfoItem
                                title={localization.tenantId()}
                                value={<Tenant tenantId={eventModel.event.tenantId}/>}/>
                            <InfoItem
                                title={localization.name()}
                                value={eventModel.event.displayName}/>
                            <EntitiesItem
                                entityIds={eventModel.serviceIdReference}
                                title={localization.service()}/>
                            <EntitiesItem
                                entityIds={eventModel.resourceIdReferences}
                                title={localization.resources()}/>
                            <InfoItem
                                title={localization.ipAddress()}
                                value={eventModel.event.ipAddress}/>
                            <InfoItem
                                title={localization.errorCode()}
                                value={eventModel.event.errorCode}/>
                            {_.isNil(eventModel.event.errorMessage)
                                ? undefined
                                : <InfoItem
                                    title={eventModel.event.errorCode}
                                    value={eventModel.event.errorMessage}/>}
                        </InfoCard>)}
                    {currentCategory === ProfileCategory.RawEvent && (
                        <TextViewer
                            format="json"
                            height="100%"
                            text={
                                TypeHelper.extendOrImplement(eventModel.event.typeName, Contract.TypeNames.GcpEvent)
                                    ? JSON.stringify(JSON.parse(eventModel.event.raw), undefined, 2)
                                    : eventModel.event.raw}/>)}
                </Stack>
            </VerticalTabView>
        </ProfileLayout>);
}

export enum ProfileCategory {
    Overview = "overview",
    RawEvent = "rawEvent"
}

type EntitiesItemProps = {
    entityIds?: string | string[];
    title: string;
};

function EntitiesItem({ entityIds, title }: EntitiesItemProps) {
    const entityModels = entityModelStore.useGet(entityIds);
    return (<EntitiesInfoItem
        entityIdsOrModels={entityModels}
        entityTypeName={
            _.isNil(entityModels)
                ? Contract.TypeNames.Entity
                : EntityModelHelper.getCommonTypeName(entityModels) ?? Contract.TypeNames.Entity}
        title={title}/>);
}