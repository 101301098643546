import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, Entity, entityModelStore, RegionModelHelper, tenantModelStore } from "../../../../../../../../../../../../common";
import { OciConsoleUrlBuilder, useOciConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { useGetRiskPolicyConfigurationPasswordRequirementContextItem } from "../../../useGetRiskPolicyConfigurationPasswordRequirementContextItem";
import { useGetOciIamDomainRiskContext } from "../contexts";

export function useOciIamDomainPasswordLengthPolicyRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.OciIamDomainPasswordLengthPolicyRisk;
    const domainModel = entityModelStore.useGet(risk.entityId) as Contract.OciIamDomainModel;
    const domain = domainModel.entity as Contract.OciIamDomain;

    const getOciIamDomainRiskContext = useGetOciIamDomainRiskContext();
    const consoleSignInStepTranslator = useOciConsoleSignInStepTranslator();
    const getRiskPolicyConfigurationPasswordRequirementContextItem = useGetRiskPolicyConfigurationPasswordRequirementContextItem();

    const tenantModel = tenantModelStore.useGet(domainModel.entity.tenantId);
    const ociTenantModel = tenantModelStore.useGet((tenantModel.configuration as Contract.OciTenantConfiguration).ocid);
    const ociTenantConfiguration = ociTenantModel.configuration as Contract.OciTenantConfiguration;

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.oci.hooks.compliance.useOciIamDomainPasswordLengthPolicyRiskDefinition",
            () => ({
                contextItems: {
                    passwordPolicyMinLength: {
                        riskPolicyConfiguration: "required minimum password length of **{{value}} characters**"
                    }
                },
                description: "{{domain}} password length policy should enforce a minimum length of {{passwordPolicyMinLength}} characters",
                sections: {
                    resolution: {
                        step1: "Click **Add**",
                        step2: "Set **Password length (minimum)** to **{{passwordPolicyMinLength}}** or greater",
                        step3: "Set **Alphabetic (minimum)** or **Numeric (minimum)** to 1 or greater",
                        step4: "Click **Add**"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            domain:
                <Entity
                    entityIdOrModel={domainModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>,
            passwordPolicyMinLength: risk.passwordPolicyMinLength
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.OciConsoleView.PasswordPolicy,
                OciConsoleUrlBuilder.GetIamDomainPasswordPolicyUrl(
                    ociTenantConfiguration.realmId,
                    domain.ocid!,
                    RegionModelHelper.get(domain.regionId!).systemName!)),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2({
                passwordPolicyMinLength: risk.passwordPolicyMinLength
            }),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4()],
        riskModel,
        () => {
            const domainRiskContext = getOciIamDomainRiskContext(domainModel);
            return [
                domainRiskContext.generalInformation,
                getRiskPolicyConfigurationPasswordRequirementContextItem(localization.contextItems.passwordPolicyMinLength.riskPolicyConfiguration({ value: risk.passwordPolicyMinLength })),
                domainRiskContext.status,
                domainRiskContext.sensitive,
                domainRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}