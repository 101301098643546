import { Dialog, ListWelcomeView, makeContextProvider, StringHelper, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, ScopeHelper, scopeNodeModelStore, scopeSystemEntityModelStore, slackWorkspaceChannelOperationStore, StorageHelper, useScopeNavigationViewContext } from "../../../../../../../../common";
import { AddOrEdit, Table } from "./components";

export class AuditEventAutomationsContext {
    constructor(
        public addOrEditOpen: boolean | Contract.AutomationRuleConfiguration,
        public childScopeEnabled: boolean) {
    }
}

export const [useAuditEventAutomationsContext, useSetAuditEventAutomationsContext, useAuditEventAutomationsContextProvider] = makeContextProvider<AuditEventAutomationsContext>();

export function AuditEventAutomations() {
    const auditEventAutomationRuleModels = scopeSystemEntityModelStore.useGetAuditEventAutomationRule();
    const { scopeNodeModel } = useScopeNavigationViewContext();
    const workspaceIdToChannelRawIdToNameMap = slackWorkspaceChannelOperationStore.useGet();

    const [context, setContext, ContextProvider] =
        useAuditEventAutomationsContextProvider(
            () =>
                new AuditEventAutomationsContext(
                    false,
                    !StringHelper.isFalse(StorageHelper.customerConfigurationAutomationsAuditEventsFlatView.getValue())));
    const scopeNodeMap = scopeNodeModelStore.useGetActiveScopeNodeMap();
    const childScopeIds =
        useMemo(
            () => scopeNodeMap[scopeNodeModel.configuration.id].scopeIds,
            [scopeNodeModel.configuration.id, scopeNodeMap]);
    const filteredAuditEventAutomationRuleModels =
        useMemo(
            () =>
                _.filter(
                    auditEventAutomationRuleModels,
                    auditEventAutomationRuleModel =>
                        auditEventAutomationRuleModel.configuration.scopeId == scopeNodeModel.configuration.id ||
                        (context.childScopeEnabled && _.includes(childScopeIds, auditEventAutomationRuleModel.configuration.scopeId))),
            [auditEventAutomationRuleModels, context.childScopeEnabled, childScopeIds, scopeNodeModel]);

    const datadogOrganizationModels =
        ScopeHelper.getParentScopeSystemEntityModelsIntersection(
            [scopeNodeModel.configuration.id],
            scopeSystemEntityModelStore.useGetDatadog());
    const qRadarServerModels =
        ScopeHelper.getParentScopeSystemEntityModelsIntersection(
            [scopeNodeModel.configuration.id],
            scopeSystemEntityModelStore.useGetQRadar());
    const slackWorkspaceModels =
        ScopeHelper.getParentScopeSystemEntityModelsIntersection(
            [scopeNodeModel.configuration.id],
            scopeSystemEntityModelStore.useGetSlack());
    const splunkEventCollectorModels =
        ScopeHelper.getParentScopeSystemEntityModelsIntersection(
            [scopeNodeModel.configuration.id],
            scopeSystemEntityModelStore.useGetSplunk());
    const sqsQueueModels =
        ScopeHelper.getParentScopeSystemEntityModelsIntersection(
            [scopeNodeModel.configuration.id],
            scopeSystemEntityModelStore.useGetSqs());
    const teamsOrganizationModels =
        ScopeHelper.getParentScopeSystemEntityModelsIntersection(
            [scopeNodeModel.configuration.id],
            scopeSystemEntityModelStore.useGetTeams());
    const webhookEndpointModels =
        ScopeHelper.getParentScopeSystemEntityModelsIntersection(
            [scopeNodeModel.configuration.id],
            scopeSystemEntityModelStore.useGetWebhook());
    const addRuleDisabled =
        useMemo(
            () =>
                _.isEmpty(datadogOrganizationModels) &&
                _.isEmpty(qRadarServerModels) &&
                _(slackWorkspaceModels).
                    flatMap(slackWorkspaceModel => _.keys(workspaceIdToChannelRawIdToNameMap[slackWorkspaceModel.id])).
                    isEmpty() &&
                _.isEmpty(splunkEventCollectorModels) &&
                _.isEmpty(sqsQueueModels) &&
                _(teamsOrganizationModels).
                    flatMap(teamsOrganizationModel => (teamsOrganizationModel.state as Contract.TeamsOrganizationState).teamRawIdToChannelReferencesMap).
                    isEmpty() &&
                _.isEmpty(webhookEndpointModels),
            [datadogOrganizationModels, qRadarServerModels, slackWorkspaceModels, splunkEventCollectorModels, sqsQueueModels, teamsOrganizationModels, webhookEndpointModels]);

    const localization =
        useLocalization(
            "views.customer.configuration.automations.auditEventAutomations",
            () => ({
                welcome: {
                    addRuleDisabledTooltip: "To add an automation, first connect your SIEM, Slack or Teams integrations with Tenable Cloud Security",
                    title: "Add Audit-Based Automation"
                }
            }));

    return (
        <ContextProvider>
            {context.addOrEditOpen !== false && (
                <Dialog
                    size="medium"
                    variant="editor"
                    onClose={
                        () => {
                            setContext(
                                automationContext => ({
                                    ...automationContext,
                                    addOrEditOpen: false
                                }));
                        }}>
                    <AddOrEdit/>
                </Dialog>)}
            {_.isEmpty(filteredAuditEventAutomationRuleModels)
                ? <ListWelcomeView
                    disabledText={
                        addRuleDisabled
                            ? localization.welcome.addRuleDisabledTooltip()
                            : undefined}
                    title={localization.welcome.title()}
                    onStart={
                        () =>
                            setContext(
                                context => ({
                                    ...context,
                                    addOrEditOpen: true
                                }))}/>
                : <Table
                    addRuleDisabledTooltip={
                        addRuleDisabled
                            ? localization.welcome.addRuleDisabledTooltip()
                            : undefined}
                    ruleModels={filteredAuditEventAutomationRuleModels}/>}
        </ContextProvider>);
}