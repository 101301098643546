import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useAwsElastiCacheReplicationGroupClusterModeTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useAwsElastiCacheReplicationGroupClusterModeTranslator",
            () => ({
                [Contract.TypeNames.AwsElastiCacheReplicationGroupClusterMode]: {
                    [Contract.AwsElastiCacheReplicationGroupClusterMode.Compatible]: "Compatible",
                    [Contract.AwsElastiCacheReplicationGroupClusterMode.Disabled]: "Disabled",
                    [Contract.AwsElastiCacheReplicationGroupClusterMode.Enabled]: "Enabled"
                }
            }));
    return (clusterMode: Contract.AwsElastiCacheReplicationGroupClusterMode) =>
        localization[Contract.TypeNames.AwsElastiCacheReplicationGroupClusterMode][clusterMode]();
}