import { Shadows } from "@infrastructure";
import _ from "lodash";
import React, { memo, useMemo, useState } from "react";
import { DotProps } from "recharts";
import { ChartDataItem } from "../../..";
import { useTheme, VulnerabilityEventType } from "../../../../../../../../../../../../common";
import { getFilteredEvents } from "./utilities";

type CustomizedPointProps =
    DotProps & {
        activeEventType?: VulnerabilityEventType;
        hoverEventType?: VulnerabilityEventType;
        payload?: Partial<ChartDataItem>;
    };

const CustomizedPointMemo = memo(CustomizedPoint);
export { CustomizedPointMemo as CustomizedPoint };

function CustomizedPoint({ activeEventType, cx = 0, cy = 0, hoverEventType, payload = {} }: CustomizedPointProps) {
    const [hover, setHover] = useState(false);
    const { events, score } = payload;
    const filteredUniqueEventTypes =
        useMemo(
            () =>
                _(getFilteredEvents(
                    activeEventType,
                    events,
                    hoverEventType)).
                    map(event => event.eventType).
                    uniq().
                    value(),
            [activeEventType, events, hoverEventType]);

    const coefficient =
        (hover
            ? 2
            : 1) * 6;

    const eventsCount = _.size(events);
    const filteredUniqueEventsCount = _.size(filteredUniqueEventTypes);
    const theme = useTheme();
    return (
        _.isNil(payload) || _.isEmpty(events) || _.isNil(score)
            ? null
            : <g>
                <svg>
                    <rect
                        fill="transparent"
                        height="100%"
                        style={{ cursor: "pointer" }}
                        width={WrapperRectangleWidth}
                        x={cx - ( WrapperRectangleWidth / 2 )}
                        onMouseEnter={() => setHover(true)}
                        onMouseLeave={() => setHover(false)}/>
                    {filteredUniqueEventsCount <= 3 &&
                        _.map(
                            filteredUniqueEventTypes,
                            (eventType, index) => (
                                <g
                                    key={index}
                                    onMouseEnter={() => setHover(true)}
                                    onMouseLeave={() => setHover(false)}>
                                    <defs>
                                        <filter
                                            height="200%"
                                            id={`shadow-${index}`}
                                            width="200%"
                                            x="-50%"
                                            y="-50%">
                                            <feDropShadow
                                                dx="2"
                                                dy="2"
                                                floodColor={theme.shadows[Shadows.GraphDot]}
                                                stdDeviation="1"/>
                                        </filter>
                                    </defs>
                                    <circle
                                        cx={cx}
                                        cy={cy + coefficient * (index - ((filteredUniqueEventsCount - 1) / 2))}
                                        fill={theme.palette.workload.vulnerabilityEventType(eventType)}
                                        filter={`url(#shadow-${index})`}
                                        r={
                                            hoverEventType || hover
                                                ? 10
                                                : 8}
                                        stroke="white"
                                        strokeWidth={
                                            hoverEventType || hover
                                                ? 2.5
                                                : 2}
                                        style={{
                                            cursor: "pointer",
                                            transition: "cy 0.3s ease-in-out, r 0.3s ease-in-out"
                                        }}/>
                                </g>))}
                    {filteredUniqueEventsCount > 3 &&
                        <g
                            style={{ cursor: "pointer" }}
                            onMouseEnter={() => setHover(true)}
                            onMouseLeave={() => setHover(false)}>
                            <circle
                                cx={cx}
                                cy={cy}
                                fill={theme.palette.background.paper}
                                filter={
                                    eventsCount === 1
                                        ? `url(#shadow)`
                                        : ""}
                                r={
                                    hover
                                        ? 14
                                        : eventsCount <= 3
                                            ? 8
                                            : 11}
                                stroke={theme.palette.text.primary}
                                strokeWidth={
                                    eventsCount === 1
                                        ? 3
                                        : 2}
                                style={{
                                    cursor: "pointer",
                                    transition: "cy 0.3s ease-in-out, r 0.3s ease-in-out"
                                }}>
                            </circle>
                            <text
                                alignmentBaseline="central"
                                fill={theme.palette.text.primary}
                                fontSize="12"
                                style={{ cursor: "pointer" }}
                                textAnchor="middle"
                                x={cx}
                                y={cy}>
                                {events && eventsCount <= 9
                                    ? eventsCount
                                    : "9+"}
                            </text>
                        </g>}
                </svg>
            </g>);
}

const WrapperRectangleWidth = 6;