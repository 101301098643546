﻿import { DialogMenuItem, Message, Sx } from "@infrastructure";
import { Box, MenuItem as MuiMenuItem, Stack, SxProps, Typography, useTheme } from "@mui/material";
import _ from "lodash";
import React, { Fragment, useState } from "react";

type DialogMenuProps = {
    item: DialogMenuItem;
    onClose: () => void;
    sx?: SxProps;
};

export function DialogMenu({ item: { dialog, options, title }, onClose, sx }: DialogMenuProps) {
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const disabled = options?.disabled ?? false;
    return (
        <Fragment>
            {(!disabled) &&
                open &&
                dialog(() => {
                    setOpen(false);
                    onClose();
                })}
            <MuiMenuItem
                disabled={disabled}
                sx={{ padding: theme.spacing(1, 1.5) }}
                onClick={() => setOpen(!disabled)}>
                <Stack
                    alignItems="center"
                    direction="row"
                    spacing={1}
                    sx={
                        Sx.combine(
                            {
                                color: theme.palette.text.primary,
                                overflow: "hidden"
                            },
                            sx)}>
                    {!_.isNil(options?.icon) &&
                        <Box sx={{ fontSize: "14px" }}>
                            {options?.icon}
                        </Box>}
                    <Box sx={{
                        flex: 1,
                        overflow: "hidden"
                    }}>
                        {_.isString(title)
                            ? <Typography noWrap={true}>
                                {title}
                            </Typography>
                            : title}
                    </Box>
                    {!_.isNil(options?.tooltip) &&
                        <Message
                            level="info"
                            sx={{ pointerEvents: "all" }}
                            title={
                                <Typography sx={{ whiteSpace: "pre-wrap" }}>
                                    {options?.tooltip}
                                </Typography>}
                            variant="minimal"/>}
                </Stack>
            </MuiMenuItem>
        </Fragment>);
}