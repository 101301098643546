import { ItemSelector, useLocalization } from "@infrastructure";
import React from "react";
import { Contract } from "../controllers";
import { TimeRangeHelper } from "../utilities";

type TimeFrameSelectorProps = {
    onTimeFrameChanged: (timeFrame: Contract.TimeFrame) => void;
    timeFrame: Contract.TimeFrame;
};

export function TimeFrameSelector({ onTimeFrameChanged, timeFrame }: TimeFrameSelectorProps) {
    const localization =
        useLocalization(
            "common.timeFrameSelector",
            () => ({
                [Contract.TypeNames.TimeFrame]: "Last {{ time }} days"
            }));
    return (
        <ItemSelector
            className="timeFrameSelector"
            dense={true}
            items={[
                Contract.TimeFrame.Long,
                Contract.TimeFrame.Medium,
                Contract.TimeFrame.Short
            ]}
            selectedItem={timeFrame}
            sorted={false}
            onSelectedItemChanged={timeFrame => onTimeFrameChanged(timeFrame)}>
            {(timeFrame: Contract.TimeFrame) => localization[Contract.TypeNames.TimeFrame]({ time: TimeRangeHelper.getTimeFrameValue(timeFrame) })}
        </ItemSelector>);
}