import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useAwsEc2InstanceHypervisorTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useAwsEc2InstanceHypervisorTranslator",
            () => ({
                [Contract.TypeNames.AwsEc2InstanceHypervisor]: {
                    [Contract.AwsEc2InstanceHypervisor.Nitro]: "Nitro",
                    [Contract.AwsEc2InstanceHypervisor.Xen]: "Xen"
                }
            }));
    return (hypervisor: Contract.AwsEc2InstanceHypervisor) =>
        localization[Contract.TypeNames.AwsEc2InstanceHypervisor][hypervisor]();
}