﻿import _ from "lodash";
import { useMemo } from "react";
import { Contract } from "../../../../../../../../../../../../common";
import { useGetGcpEntityRiskContext } from "./useGetGcpEntityRiskContext";

export function useGetGcpCloudRunServiceRiskContext() {
    return useMemo(
        () => useGcpCloudRunServiceRiskContext,
        []);
}

function useGcpCloudRunServiceRiskContext(serviceModel: Contract.GcpCloudRunServiceModel) {
    const entityRiskContext = useGetGcpEntityRiskContext()(serviceModel);
    const service = serviceModel.entity as Contract.GcpCloudRunService;

    return {
        ...entityRiskContext,
        networkInboundAccessType:
            _.isNil(service.networkInboundAccessType)
                ? undefined
                : entityRiskContext.getNetworkInboundAccessType(service.networkInboundAccessType)
    };
}