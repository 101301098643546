import React from "react";
import { ScopeProfile, ScopeProfileActions } from "../../../../Scopes";
import { SideViewItem } from "../../../SideView";
import { SideViewDefinition } from "../useDefinition";

export function useScopeDefinition(item: SideViewItem): SideViewDefinition {
    return {
        getActionsElement: () => <ScopeProfileActions scopeNodeModelId={item.id}/>,
        getViewElement:
            () =>
                <ScopeProfile
                    key={item.id}
                    scopeNodeModelId={item.id}/>
    };
}