import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function VendorBadgeIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path d="M2.95455 8.57955C2.38163 8.57955 1.87263 8.4813 1.42756 8.2848C0.984848 8.08594 0.636837 7.8125 0.383523 7.46449C0.32918 7.38983 0.280611 7.31278 0.237817 7.23332C-0.0257907 6.7439 0.452194 6.2642 1.00809 6.2642H1.37364C1.7155 6.2642 1.99611 6.58053 2.26918 6.78622C2.45147 6.92116 2.68466 6.98864 2.96875 6.98864C3.24337 6.98864 3.46709 6.91761 3.63991 6.77557C3.81274 6.63116 3.89678 6.4465 3.89205 6.22159C3.89678 6.00142 3.79735 5.8215 3.59375 5.68182C3.39252 5.53977 3.1321 5.46875 2.8125 5.46875H2.75568C2.37912 5.46875 2.07386 5.16349 2.07386 4.78693C2.07386 4.41037 2.37912 4.10511 2.75568 4.10511H2.8125C3.10843 4.10511 3.34872 4.03527 3.53338 3.8956C3.72041 3.75355 3.81155 3.57244 3.80682 3.35227C3.81155 3.14157 3.73461 2.96875 3.57599 2.83381C3.41974 2.6965 3.21733 2.62784 2.96875 2.62784C2.6965 2.62784 2.47277 2.69886 2.29759 2.84091C2.02837 3.05849 1.75453 3.38068 1.40838 3.38068H1.09931C0.571455 3.38068 0.117377 2.92768 0.354621 2.45613C0.3988 2.36832 0.449863 2.28351 0.507812 2.2017C0.751657 1.8608 1.08546 1.59328 1.50923 1.39915C1.933 1.20502 2.41951 1.10795 2.96875 1.10795C3.50616 1.10795 3.98082 1.1991 4.39276 1.38139C4.80706 1.56368 5.13021 1.81463 5.36222 2.13423C5.59659 2.45147 5.71259 2.81487 5.71023 3.22443C5.71496 3.63163 5.57528 3.96544 5.29119 4.22585C5.00947 4.48627 4.65199 4.64015 4.21875 4.6875V4.74432C4.80587 4.80824 5.2474 4.98816 5.54332 5.28409C5.83925 5.57765 5.98485 5.94697 5.98011 6.39205C5.98248 6.81818 5.85464 7.19579 5.59659 7.52486C5.34091 7.85393 4.98461 8.11198 4.5277 8.29901C4.07315 8.48603 3.54877 8.57955 2.95455 8.57955Z"/>
            <path d="M7.33754 4.84375C6.98059 4.84375 6.69123 4.55439 6.69123 4.19744V1.83239C6.69123 1.48721 6.97105 1.20739 7.31623 1.20739C7.66141 1.20739 7.94123 1.48721 7.94123 1.83239V1.84659H7.98384C8.04066 1.61932 8.14128 1.4465 8.28569 1.32812C8.4301 1.20975 8.59464 1.15057 8.7793 1.15057C8.88583 1.15057 8.98171 1.16714 9.06694 1.20028C9.21585 1.2526 9.26723 1.42418 9.21313 1.57245L8.97836 2.21589C8.94739 2.30077 8.85153 2.34165 8.76509 2.31534C8.71301 2.2964 8.65146 2.28693 8.58043 2.28693C8.41708 2.28693 8.27622 2.34493 8.15785 2.46094C8.04184 2.57694 7.98384 2.72727 7.98384 2.91193V4.19744C7.98384 4.55439 7.69448 4.84375 7.33754 4.84375Z"/>
            <path d="M13.1578 4.21165C13.1578 4.56075 12.8748 4.84375 12.5257 4.84375H12.3198C12.0844 4.84375 11.8936 4.65296 11.8936 4.41761H11.8368C11.8037 4.45549 11.7528 4.51349 11.6841 4.59162C11.6178 4.66738 11.5208 4.7384 11.3929 4.80469C11.2675 4.86861 11.0982 4.90057 10.8851 4.90057C10.6105 4.90057 10.3572 4.8331 10.1252 4.69815C9.89554 4.56321 9.71088 4.35724 9.5712 4.08026C9.43389 3.80327 9.36523 3.4517 9.36523 3.02557C9.36523 2.5947 9.43626 2.24195 9.5783 1.96733C9.72272 1.69271 9.90974 1.49029 10.1394 1.36009C10.3714 1.22988 10.62 1.16477 10.8851 1.16477C11.1029 1.16477 11.2746 1.1991 11.4 1.26776C11.5279 1.33404 11.6238 1.40862 11.6877 1.49148C11.754 1.57197 11.8037 1.63352 11.8368 1.67614H11.8794V0.639204C11.8794 0.286182 12.1656 0 12.5186 0C12.8717 0 13.1578 0.286182 13.1578 0.639204V4.21165ZM11.9078 3.02557C11.9078 2.46686 11.7042 2.1875 11.2971 2.1875C11.084 2.1875 10.9289 2.26562 10.8319 2.42187C10.7348 2.57576 10.6863 2.77699 10.6863 3.02557C10.6863 3.28125 10.736 3.48722 10.8354 3.64347C10.9372 3.79972 11.0911 3.87784 11.2971 3.87784C11.4959 3.87784 11.6474 3.80208 11.7516 3.65057C11.8558 3.49669 11.9078 3.28835 11.9078 3.02557Z"/>
        </SvgIcon>);
}