import { InlineItems, StringHelper } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, GroupedTableColumnRenderProps, useRiskPolicyTitleTranslator } from "../../../../../../../../../../../../../common";
import { GroupedItem } from "../../../../../Grouped";

export function RiskPolicies({ item }: GroupedTableColumnRenderProps<GroupedItem>) {
    const riskPolicyTitleTranslator = useRiskPolicyTitleTranslator();
    const orderedRiskPolicyTitles =
        useMemo(
            () => {
                const riskTenantGroup = (item.riskGroup as Contract.RiskControllerGetRiskGroupsResponseRiskTenantGroup);
                return _(riskTenantGroup.builtInRiskPolicyConfigurationTypeNames).
                    concat(riskTenantGroup.customRiskPolicyIds).
                    map(riskPolicyTitleTranslator).
                    orderBy(riskPolicyTitle => StringHelper.getSortValue(riskPolicyTitle)).
                    value();
            },
            [item]);
    return (
        <InlineItems
            items={orderedRiskPolicyTitles}
            sx={{
                color: "unset",
                textDecoration: "underline"
            }}
            variant="itemCount"/>);
}