import { DataTable, DataTableColumn, EmptyMessageText, Optional, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract } from "../../../common";

type GcpSqlInstanceAuthorizedSubnetsTableProps = {
    authorizedSubnetDatas: Contract.GcpSqlInstanceAuthorizedSubnetData[];
    getHighlightColor?: (authorizedSubnetData: Contract.GcpSqlInstanceAuthorizedSubnetData, opacity?: number) => Optional<string>;
};

export function GcpSqlInstanceAuthorizedSubnetsTable({ authorizedSubnetDatas, getHighlightColor }: GcpSqlInstanceAuthorizedSubnetsTableProps) {
    const localization =
        useLocalization(
            "tenants.gcp.gcpSqlInstanceAuthorizedSubnetsTable",
            () => ({
                columns: {
                    name: "Network Name",
                    subnet: "CIDR"
                },
                empty: "No Authorized Networks"
            }));
    return (
        <DataTable
            emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty()) }}
            fetchItems={() => authorizedSubnetDatas}
            getItemId={(item: Contract.GcpSqlInstanceAuthorizedSubnetData) => _.indexOf(authorizedSubnetDatas, item).toString()}
            rowOptions={{
                getHighlightColor: (item: Contract.GcpSqlInstanceAuthorizedSubnetData) => getHighlightColor?.(item),
                getSx:
                    (item: Contract.GcpSqlInstanceAuthorizedSubnetData) => ({
                        backgroundColor: getHighlightColor?.(item, 0.1)
                    })
            }}
            sortOptions={{ enabled: false }}
            variant="card">
            <DataTableColumn
                id={AuthorizedSubnetColumnId.Name}
                itemProperty={(item: Contract.GcpSqlInstanceAuthorizedSubnetData) => item.name}
                title={localization.columns.name()}/>
            <DataTableColumn
                id={AuthorizedSubnetColumnId.Subnet}
                itemProperty={(item: Contract.GcpSqlInstanceAuthorizedSubnetData) => item.subnet}
                title={localization.columns.subnet()}/>
        </DataTable>);
}

enum AuthorizedSubnetColumnId {
    Name = "name",
    Subnet = "subnet"
}