import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, useLocalization } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { useAwsCommonEc2ResourceDefinition, useAwsCommonNetworkedResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, ItemSelectionHelper, NetworkedResourcesCell, NetworkScopeFormatter, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { DefinitionData, ExportData } from "../../../../Table";

export function useAwsEc2SecurityGroupDefinition(definitionData: DefinitionData) {
    const commonEc2ResourceDefinition = useAwsCommonEc2ResourceDefinition(definitionData);
    const commonNetworkedResourceDefinition = useAwsCommonNetworkedResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.aws.useAwsEc2SecurityGroupDefinition",
            () => ({
                columns: {
                    description: "Description",
                    inboundRules: "Inbound Rules",
                    networkedResourceIds: "Attached Resources",
                    outboundRules: "Outbound Rules"
                }
            }));

    function getRuleDisplayValue(
        rule: Contract.AwsEc2SecurityGroupRule,
        entityMap: Dictionary<Contract.EntityModel>) {
        const networkScope =
            NetworkScopeFormatter.networkScope(
                rule.destinationPortRange!,
                rule.protocolRange);
        const target =
            _.isNil(rule.securityGroupId)
                ? _.isNil(rule.prefixListRawId)
                    ? rule.subnet!
                    : rule.prefixListRawId
                : entityMap[rule.securityGroupId].entity.displayReference;
        return `${networkScope} | ${target}}`;
    }

    return new EntityTableDefinition(
        _.filter(
            [
                commonEc2ResourceDefinition.columns.rawIdColumn,
                commonEc2ResourceDefinition.columns.tenantColumn,
                commonEc2ResourceDefinition.columns.creationTimeColumn,
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsEc2SecurityGroupModel) => ({
                                [localization.columns.description()]: (item.entity as Contract.AwsEc2SecurityGroup).description
                            })
                    }}
                    id="description"
                    key="description"/>,
                commonEc2ResourceDefinition.columns.creatorIdentityCsvColumn,
                commonEc2ResourceDefinition.columns.creatorOriginatorEntityCsvColumn,
                <DataTableColumn
                    exportOptions={
                        entitiesExportOptions<Contract.AwsEc2SecurityGroupModel>(
                            Contract.TypeNames.INetworkedResource,
                            item =>
                                _(item.networkedResourceTypeNameToIdsMap).
                                    values().
                                    flatMap().
                                    value(),
                            localization.columns.networkedResourceIds())}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedEntityFilter
                                    getEntityIdPage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.AwsEc2SecurityGroupNetworkedResource)}
                                    placeholder={localization.columns.networkedResourceIds()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsEc2SecurityGroupNetworkedResource}
                    key={Contract.EntityModelProperty.AwsEc2SecurityGroupNetworkedResource}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.AwsEc2SecurityGroupModel>) =>
                            <NetworkedResourcesCell item={item.networkedResourceTypeNameToIdsMap}/>}
                    sortOptions={{ type: DataTableSortType.Numeric }}
                    title={localization.columns.networkedResourceIds()}/>,
                <DataTableColumn
                    exportOptions={{
                        getData:
                            (item: Contract.AwsEc2SecurityGroupModel) =>
                                _((item.entity as Contract.AwsEc2SecurityGroup).inboundRules).
                                    map(inboundRule => inboundRule.securityGroupId).
                                    filter().
                                    value(),
                        getItem:
                            (item: Contract.AwsEc2SecurityGroupModel, exportData: ExportData) => ({
                                [localization.columns.inboundRules()]:
                                    _((item.entity as Contract.AwsEc2SecurityGroup).inboundRules).
                                        map(inboundRule => getRuleDisplayValue(inboundRule, exportData.relatedEntityModelMap)).
                                        join("\n")
                            })
                    }}
                    id="inboundRules"
                    key="inboundRules"/>,
                <DataTableColumn
                    exportOptions={{
                        getData:
                            (item: Contract.AwsEc2SecurityGroupModel) =>
                                _((item.entity as Contract.AwsEc2SecurityGroup).outboundRules).
                                    map(outboundRule => outboundRule.securityGroupId).
                                    filter().
                                    value(),
                        getItem:
                            (item: Contract.AwsEc2SecurityGroupModel, exportData: ExportData) => ({
                                [localization.columns.outboundRules()]:
                                    _((item.entity as Contract.AwsEc2SecurityGroup).outboundRules).
                                        map(outboundRule => getRuleDisplayValue(outboundRule, exportData.relatedEntityModelMap)).
                                        join("\n")
                            })
                    }}
                    id="outboundRules"
                    key="outboundRules"/>,
                commonNetworkedResourceDefinition.columns.getVpcsColumn(Contract.EntityModelProperty.AwsEc2SecurityGroupVpc),
                commonEc2ResourceDefinition.columns.regionColumn,
                commonEc2ResourceDefinition.columns.regionLocationColumn,
                commonEc2ResourceDefinition.columns.tagsColumn,
                commonEc2ResourceDefinition.columns.attributesColumn,
                commonEc2ResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
                commonEc2ResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
                commonEc2ResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
            ]),
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAwsEc2SecurityGroupRequest(
                new Contract.EntityControllerGetEntityModelPageAwsEc2SecurityGroupRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEc2ResourceRawId]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEc2SecurityGroupNetworkedResource]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEc2SecurityGroupVpc])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags]))));
}