import { Typography } from "@mui/material";
import React from "react";
import { Contract } from "../..";
import { useDefinition } from "./hooks";

export type ChangeProps = {
    change: Contract.Change;
    entityLinkDisabled?: boolean;
};

export function Change({ change, entityLinkDisabled }: ChangeProps) {
    const definition = useDefinition(change, entityLinkDisabled);
    return (
        <Typography sx={{ wordBreak: "break-word" }}>
            {definition.title}
        </Typography>);
}