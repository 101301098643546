﻿import { CsvExportButton, Dropdown, Message, Optional, Steps, StringHelper, TimeHelper, useExecuteOperation, useLocalization } from "@infrastructure";
import { Check as EnabledIcon } from "@mui/icons-material";
import { Box, Divider, LinearProgress, Stack, Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { Fragment, useMemo } from "react";
import { ConfigurationController, Contract, FeatureHelper, LicensingHelper, ScopeHelper, tenantModelStore, TenantsEntitiesView, useScopeNavigationViewContext, useTenantTypeTranslator, useTheme } from "../../../../../../common";
import { Chart } from "./components";
import { useGetCustomerLicenseObjectInfo } from "./hooks";

export function Licensing() {
    const { scopeNodeModel } = useScopeNavigationViewContext();

    const [{ dateToObjectCountMap, licenseVersion, objectCountAverage, objectTypeToCountMap, subscriptionLicenseExpirationTime, subscriptionLicenseObjectCount }] =
        useExecuteOperation(
            Licensing,
            async () => await ConfigurationController.getLicensingData(new Contract.ConfigurationControllerGetLicensingDataRequest(scopeNodeModel.configuration.id)));
    return (
        <Core
            activeEvaluationLicenseTypes={LicensingHelper.activeEvaluationLicenseTypes}
            activeSubscriptionLicenseTypes={LicensingHelper.activeSubscriptionLicenseTypes}
            dateToObjectCountMap={dateToObjectCountMap}
            licenseShortName={LicensingHelper.getActiveLicenseShortName()}
            licenseVersion={licenseVersion}
            objectCountAverage={objectCountAverage}
            objectTypeToCountMap={objectTypeToCountMap}
            subscriptionLicenseExpirationTime={subscriptionLicenseExpirationTime}
            subscriptionLicenseObjectCount={subscriptionLicenseObjectCount}/>);
}

type CoreProps = {
    activeEvaluationLicenseTypes: Contract.ApplicationCustomerConfigurationLicensingLicenseType[];
    activeSubscriptionLicenseTypes: Contract.ApplicationCustomerConfigurationLicensingLicenseType[];
    dateToObjectCountMap: Optional<Dictionary<number>>;
    licenseShortName: string;
    licenseVersion: Contract.ApplicationCustomerConfigurationLicensingLicenseVersion;
    objectCountAverage: Optional<number>;
    objectTypeToCountMap: Optional<Dictionary<number>>;
    subscriptionLicenseExpirationTime: Optional<string>;
    subscriptionLicenseObjectCount: Optional<number>;
};

export function Core({ activeEvaluationLicenseTypes, activeSubscriptionLicenseTypes, dateToObjectCountMap, licenseShortName, licenseVersion, objectCountAverage, objectTypeToCountMap, subscriptionLicenseExpirationTime, subscriptionLicenseObjectCount }: CoreProps) {
    const getCustomerLicenseObjectInfo = useGetCustomerLicenseObjectInfo();
    const { scopeNodeModel } = useScopeNavigationViewContext();

    const [snapshotDate, snapshotObjectCount] =
        useMemo(
            () => {
                if (!_.isNil(dateToObjectCountMap)) {
                    const snapshotDate = _.max(_.keys(dateToObjectCountMap));
                    return [snapshotDate, dateToObjectCountMap![snapshotDate!]];
                } else {
                    return [undefined, undefined];
                }
            },
            [dateToObjectCountMap]);

    const tenantTypeTranslator = useTenantTypeTranslator();

    const localization =
        useLocalization(
            "views.customer.configuration.licensing",
            () => ({
                actions: {
                    csvExport: {
                        fileNamePrefix: "License_Usage_{{scopeName}}"
                    }
                },
                addonLicenses: "Add-ons",
                licenses: "Licenses",
                objectCountAverage: "**Average Usage:** {{objectCountAverage | NumberFormatter.humanize}}",
                objectCountAverageInfo: "The resource count is based on the average usage of resources in onboarded accounts, going back up to 90 days",
                subscriptionLicenseExpirationTime: {
                    active: "{{subscriptionLicenseExpirationTime | TimeFormatter.shortDate}} (in {{subscriptionLicenseExpirationTime | TimeFormatter.humanizeDuration}})",
                    expired: "{{subscriptionLicenseExpirationTime | TimeFormatter.shortDate}} (Expired)",
                    title: "**Renewal Date**: {{translatedExpirationTime}}"
                },
                subscriptionLicenseObjectCount: {
                    breakDown: "(see breakdown)",
                    info: {
                        opUnmanagedKubernetesClusterNodes: "**On-Premises K8s nodes:** 1 resource = 2 billable resources. The breakdown displays billable resources.",
                        serverlessFunctions: "**Serverless functions:** 10 resources = 1 billable resource. The breakdown displays billable resources.",
                        snapshotDate: "**Updated as of :** {{snapshotDate | TimeFormatter.shortDate}}"
                    },
                    snapshotObjectCountPercentage: "{{snapshotObjectCountPercentage | NumberFormatter.percentage}} used",
                    title: {
                        license: "**Last Snapshot:** {{snapshotObjectCount | NumberFormatter.humanize}} out of {{subscriptionLicenseObjectCount | NumberFormatter.humanize}} resources",
                        trial: "**Last Snapshot:** {{snapshotObjectCount | NumberFormatter.humanize}} resources"
                    }
                },
                usageTrend: "Usage Trend"
            }));

    const getCsvItemPage =
        async (limit: number, skip: number) => {
            if (skip > 0) {
                return [];
            }
            const { tenantIdToDataMap } =
                await ConfigurationController.getLicensingExportData(
                    new Contract.ConfigurationControllerGetLicensingExportDataRequest(scopeNodeModel.configuration.id));
            const tenantModelMap =
                _.keyBy(
                    await tenantModelStore.get(
                        _(tenantIdToDataMap).
                            keys().
                            value()),
                    tenantModel => tenantModel.configuration.id);
            return _(tenantIdToDataMap).
                map(({ average, count }, tenantId) =>
                    ({
                        /* eslint-disable sort-keys-fix/sort-keys-fix */
                        Cloud: tenantTypeTranslator(tenantModelMap[tenantId].configuration.type),
                        "Account Name": tenantModelMap[tenantId].configuration.name,
                        "Account Id": tenantId,
                        "Last Snapshot Count": count,
                        "90 Days Average Count": average
                        /* eslint-enable sort-keys-fix/sort-keys-fix */
                    })).
                orderBy([
                    csvItem => csvItem.Cloud,
                    csvItem => csvItem["Account Id"]
                ]).
                value();
        };


    const tenantTypeToObjectDataMap =
        useMemo(
            () =>
                !_.isEmpty(objectTypeToCountMap)
                    ? _(objectTypeToCountMap).
                        omit(
                            FeatureHelper.enabled(Contract.FeatureName.AwsEc2MachineImageAnalysisEnabled)
                                ? []
                                : [Contract.CustomerLicenseObjectType.AwsEc2Image]).
                        map(
                            (objectCount, objectType) => ({
                                ...getCustomerLicenseObjectInfo(objectType as Contract.CustomerLicenseObjectType, licenseVersion, snapshotDate),
                                count: objectCount
                            })).
                        filter(({ count }) => count > 0).
                        groupBy(({ tenantType }) => tenantType).
                        pickBy(objectDatas => !_.isEmpty(objectDatas)).
                        mapValues(
                            objectDatas =>
                                _(objectDatas).
                                    orderBy(({ title }) => StringHelper.getSortValue(title)).
                                    value()).
                        value()
                    : undefined,
            [objectTypeToCountMap]);

    const theme = useTheme();
    return (
        <Stack
            spacing={4}
            sx={{
                height: "100%",
                overflow: "auto",
                padding: theme.spacing(3)
            }}>
            <Stack
                spacing={2}
                sx={{ alignItems: "flex-start" }}>
                <Typography
                    sx={{
                        border: `1.5px solid ${theme.palette.primary.main}`,
                        borderRadius: theme.spacing(0.75),
                        color: theme.palette.primary.main,
                        fontWeight: 600,
                        padding: theme.spacing(0.75, 2)
                    }}
                    variant="h3">
                    {licenseShortName}
                </Typography>
                {!_.isNil(subscriptionLicenseExpirationTime) &&
                    <Typography>
                        {localization.subscriptionLicenseExpirationTime.title({
                            translatedExpirationTime:
                                TimeHelper.isAfter(subscriptionLicenseExpirationTime, TimeHelper.now())
                                    ? localization.subscriptionLicenseExpirationTime.active({ subscriptionLicenseExpirationTime })
                                    : <Typography
                                        component="span"
                                        sx={{ color: theme.palette.error.main }}>
                                        {localization.subscriptionLicenseExpirationTime.expired({ subscriptionLicenseExpirationTime })}
                                    </Typography>
                        })}
                    </Typography>}
                {!_.isNil(snapshotObjectCount) &&
                    !_.isNil(tenantTypeToObjectDataMap) && (
                    <Stack
                        spacing={1}
                        sx={{
                            maxWidth: "500px",
                            width: "100%"
                        }}>
                        <Stack
                            alignItems="center"
                            direction="row"
                            justifyContent="space-between">
                            <Stack
                                alignItems="center"
                                direction="row"
                                spacing={1}>
                                <Typography>
                                    {!_.isNil(subscriptionLicenseObjectCount)
                                        ? localization.subscriptionLicenseObjectCount.title.license({
                                            snapshotObjectCount,
                                            subscriptionLicenseObjectCount

                                        })
                                        : localization.subscriptionLicenseObjectCount.title.trial({ snapshotObjectCount })}
                                </Typography>
                                <Message
                                    level="info"
                                    title={
                                        <Steps variant="bullets">
                                            {localization.subscriptionLicenseObjectCount.info.snapshotDate({ snapshotDate })}
                                            {localization.subscriptionLicenseObjectCount.info.opUnmanagedKubernetesClusterNodes()}
                                            {localization.subscriptionLicenseObjectCount.info.serverlessFunctions()}
                                        </Steps>}
                                    variant="minimal"/>
                                {!_.isEmpty(tenantTypeToObjectDataMap) &&
                                        <Dropdown
                                            inline={true}
                                            justify="center"
                                            popoverElement={
                                                <TenantsEntitiesView
                                                    tenantTypeToEntityDatasMap={tenantTypeToObjectDataMap}/>}
                                            popoverElementContainerSx={{
                                                maxHeight: "fit-content",
                                                padding: 0
                                            }}>
                                            <Stack
                                                alignItems="center"
                                                sx={{ textDecoration: "underline" }}>
                                                <Typography
                                                    noWrap={true}
                                                    sx={{
                                                        fontSize: "13px"
                                                    }}>
                                                    {localization.subscriptionLicenseObjectCount.breakDown()}
                                                </Typography>
                                            </Stack>
                                        </Dropdown>}
                            </Stack>
                            {!_.isNil(subscriptionLicenseObjectCount) &&
                                    subscriptionLicenseObjectCount > 0 &&
                                    <Typography
                                        sx={{
                                            color:
                                                snapshotObjectCount > subscriptionLicenseObjectCount
                                                    ? theme.palette.error.main
                                                    : undefined,
                                            fontWeight: 700
                                        }}>
                                        {localization.subscriptionLicenseObjectCount.snapshotObjectCountPercentage({ snapshotObjectCountPercentage: snapshotObjectCount / subscriptionLicenseObjectCount })}
                                    </Typography>}
                        </Stack>
                        {
                            !_.isNil(subscriptionLicenseObjectCount) &&
                                (snapshotObjectCount > subscriptionLicenseObjectCount
                                    ? <LinearProgress
                                        sx={{ backgroundColor: theme.palette.error.main }}
                                        value={subscriptionLicenseObjectCount / snapshotObjectCount * 100}
                                        variant="determinate"/>
                                    : <LinearProgress
                                        value={snapshotObjectCount / subscriptionLicenseObjectCount * 100}
                                        variant="determinate"/>)}
                    </Stack>)}
                {!_.isNil(objectCountAverage) && (
                    <Stack
                        alignItems="center"
                        direction="row"
                        spacing={1}>
                        <Typography>
                            {localization.objectCountAverage({ objectCountAverage })}
                        </Typography>
                        <Message
                            level="info"
                            title={localization.objectCountAverageInfo()}
                            variant="minimal"/>
                    </Stack>)}
            </Stack>
            {!_.isEmpty(dateToObjectCountMap) &&
                <Fragment>
                    <Divider flexItem={true}/>
                    <Stack>
                        <Stack
                            direction="row"
                            justifyContent="space-between">
                            <Typography variant="h3">
                                {localization.usageTrend()}
                            </Typography>
                            { // LongMigration: Projects | Delete condition when export for project scopes is implemented (ask Anna\Aviv\Gil)
                                !ScopeHelper.isProjectScope(scopeNodeModel) &&
                                <CsvExportButton
                                    fileNameOptions={{
                                        default: true,
                                        filtered: false,
                                        prefix: localization.actions.csvExport.fileNamePrefix({ scopeName: scopeNodeModel.configuration.name })
                                    }}
                                    getItemPage={getCsvItemPage}/>}
                        </Stack>
                        <Box
                            sx={{
                                height: 200,
                                marginTop: theme.spacing(1),
                                overflow: "hidden",
                                paddingTop: theme.spacing(1),
                                width: "100%"
                            }}>
                            <Chart
                                dateToObjectCountMap={dateToObjectCountMap}
                                subscriptionLicenseObjectCount={subscriptionLicenseObjectCount}/>
                        </Box>
                    </Stack>
                </Fragment>}
            <Divider flexItem={true}/>
            <Items
                activeEvaluationLicenseTypes={activeEvaluationLicenseTypes}
                activeSubscriptionLicenseTypes={activeSubscriptionLicenseTypes}
                licenseTypes={LicensingHelper.standardLicenseTypes}
                title={localization.licenses()}/>
            <Divider flexItem={true}/>
            <Items
                activeEvaluationLicenseTypes={activeEvaluationLicenseTypes}
                activeSubscriptionLicenseTypes={activeSubscriptionLicenseTypes}
                licenseTypes={[Contract.ApplicationCustomerConfigurationLicensingLicenseType.PermissionManagement]}
                title={localization.addonLicenses()}/>
        </Stack>);
}


type ItemsProps = {
    activeEvaluationLicenseTypes: Contract.ApplicationCustomerConfigurationLicensingLicenseType[];
    activeSubscriptionLicenseTypes: Contract.ApplicationCustomerConfigurationLicensingLicenseType[];
    licenseTypes: Contract.ApplicationCustomerConfigurationLicensingLicenseType[];
    title: string;
};

export function Items({ activeEvaluationLicenseTypes, activeSubscriptionLicenseTypes, licenseTypes, title }: ItemsProps) {
    const localization =
        useLocalization(
            "views.customer.configuration.licensing.item",
            () => ({
                variant: {
                    active: "Available",
                    evaluation: "Trial",
                    subscription: "Subscribed"
                },
                [Contract.TypeNames.ApplicationCustomerConfigurationLicensingLicenseType]: {
                    [Contract.ApplicationCustomerConfigurationLicensingLicenseType.Ciem]: "Tenable Cloud Security CIEM continuously monitors identities, permissions, and activities, to help you surface, visualize, and mitigate identity-based risk. This license includes asset management, risk assessment, anomaly detection, guided and automated remediation, and reporting.",
                    [Contract.ApplicationCustomerConfigurationLicensingLicenseType.Cnapp]: "Tenable Cloud Security CNAPP includes all functionality from the CIEM/CSPM license, and extends it with agentless vulnerability management and workload protection, providing a unified view of your cloud security risk and delivering the deepest, most accurate remediation capabilities.",
                    [Contract.ApplicationCustomerConfigurationLicensingLicenseType.CnappEnterprise]: "Tenable Cloud Security Enterprise includes all functionality from the Standard license, and extends it with enterprise features and enhanced protection, including in-account workload scanning, which ensures that data never leaves your environment, and sensor-based scanning for an on-premises Kubernetes cluster.",
                    [Contract.ApplicationCustomerConfigurationLicensingLicenseType.Cspm]: "Tenable Cloud Security CIEM/CSPM includes all functionality from the CIEM license, and extends it with hundreds of policies that detect cloud misconfigurations and enforce compliance with industry standards. This license also includes Kubernetes Security Posture Management and IaC security, as well as extended retention of activity logs.",
                    [Contract.ApplicationCustomerConfigurationLicensingLicenseType.PermissionManagement]: "Tenable Cloud Security Self-Service Just-in-Time (JIT) is an add-on license that helps your developers get speedy access approval on an as-needed basis, helping to minimize your cloud attack surface and avoid the risk caused by unrevoked, long-standing privileges. Define who can request access to specific accounts, which permissions they can use, how long they have access for, and how requests are approved. All JIT-related activities are logged, allowing you to keep track of who did what."
                }
            }));

    const items =
        useMemo(
            () =>
                _(licenseTypes).
                    orderBy(
                        licenseType => _.indexOf(LicensingHelper.orderedLicenseTypes, licenseType),
                        "desc").
                    map(
                        licenseType => {
                            let variant: "evaluation" | "none" | "subscription" = "none";
                            if (_.includes(activeSubscriptionLicenseTypes, licenseType)) {
                                variant = "subscription";
                            } else if (_.includes(activeEvaluationLicenseTypes, licenseType)) {
                                variant = "evaluation";
                            }

                            return {
                                licenseType,
                                variant
                            };
                        }).
                    value(),
            []);

    const theme = useTheme();
    return (
        <Stack spacing={4}>
            <Typography variant="h3">
                {title}
            </Typography>
            {_.map(
                items,
                ({ licenseType, variant }) =>
                    <Stack
                        alignItems="center"
                        direction="row"
                        justifyContent="space-between"
                        key={licenseType}>
                        <Stack sx={{ maxWidth: theme.spacing(100) }}>
                            <Typography
                                sx={{ fontWeight: 500 }}
                                variant="h4">
                                {LicensingHelper.getLicenseData(licenseType).name}
                            </Typography>
                            <Typography>
                                {localization[Contract.TypeNames.ApplicationCustomerConfigurationLicensingLicenseType][licenseType]()}
                            </Typography>
                        </Stack>
                        {variant !== "none" &&
                            <Stack
                                alignItems="center"
                                direction="row"
                                spacing={1}>
                                <EnabledIcon
                                    sx={{
                                        color: theme.palette.success.main,
                                        fontSize: "18px"
                                    }}/>
                                <Typography
                                    sx={{
                                        fontSize: "14px",
                                        fontWeight: 500
                                    }}>
                                    {localization.variant[variant]()}
                                </Typography>
                            </Stack>}
                    </Stack>)}
        </Stack>);
}