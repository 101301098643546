import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, Entity, entityModelStore } from "../../../../../../..";

export function useGciDeletePrincipalGroupMembershipChangeDefinition(change: Contract.GciChange, entityLinkDisabled?: boolean) {
    const deletePrincipalGroupMembershipChange = change as Contract.GciDeletePrincipalGroupMembershipChange;
    const principalGroupMembershipChangePrincipalModel = entityModelStore.useGet(deletePrincipalGroupMembershipChange.principalId);

    const localization =
        useLocalization(
            "common.change.hooks.useDefinition.hooks.useGciDefinition.hooks.useGciDeletePrincipalGroupMembershipChangeDefinition",
            () => ({
                title: "Remove {{principal}} from {{group}}"
            }));

    return {
        title:
            localization.title({
                group:
                    <Entity
                        entityIdOrModel={deletePrincipalGroupMembershipChange.groupId}
                        glanceOptions={{ disabled: true }}
                        linkOptions={{ disabled: entityLinkDisabled }}
                        variant="typeText"/>,
                principal:
                    <Entity
                        entityIdOrModel={principalGroupMembershipChangePrincipalModel}
                        glanceOptions={{ disabled: true }}
                        linkOptions={{ disabled: entityLinkDisabled }}
                        variant="typeText"/>
            })
    };
}