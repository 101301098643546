import { Contract } from "../../../../../../../../../common";
import { useCommonDataAnalysisResourceInfoItemElements } from "./useCommonDataAnalysisResourceInfoItemElements";

export function useDefaultDataAnalysisResourceInfoItemElements(entityModel: Contract.EntityModel) {
    const commonDataAnalysisResourceInfoItemElements = useCommonDataAnalysisResourceInfoItemElements(entityModel);
    return [
        commonDataAnalysisResourceInfoItemElements.dataCategoriesInfoElement,
        commonDataAnalysisResourceInfoItemElements.dataAnalysisSensitivityInfoElement,
        commonDataAnalysisResourceInfoItemElements.dataAnalysisStatusInfoElement,
        commonDataAnalysisResourceInfoItemElements.scanTimeInfoElement
    ];
}