import _ from "lodash";
import React from "react";
import { useQueryParameters } from "@infrastructure";
import { Contract } from "../../../../../common";
import { Table } from "../../../../Customer/components/Events/components";

type EventsReportProps = {
    reportDefinitionConfiguration: Contract.EventsReportDefinitionConfiguration;
};

type EventsReportQueryParameters = {
    filtersTime: string;
};

export function EventsReport({ reportDefinitionConfiguration }: EventsReportProps) {
    const { filtersTime } = useQueryParameters<EventsReportQueryParameters>();

    return (
        <Table
            dataTableVariant="exportButtonOnly"
            entityId={reportDefinitionConfiguration.entityId}
            filtersOptions={{
                initialMap:
                    !_.isNil(reportDefinitionConfiguration.filterMap)
                        ? JSON.parse(reportDefinitionConfiguration.filterMap)
                        : {},
                time: filtersTime
            }}
            riskId={reportDefinitionConfiguration.riskId}/>);
}