import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { useGetRiskPolicyConfigurationRetentionTimeFrameContextItem } from "../../../useGetRiskPolicyConfigurationRetentionTimeFrameContextItem";
import { useGetAwsRdsNeptuneClusterRiskContext } from "../contexts";

export function useAwsRdsNeptuneClusterBackupRetentionTimeFrameRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsRdsNeptuneClusterBackupRetentionTimeFrameRisk;
    const neptuneClusterModel = entityModelStore.useGet(risk.entityId) as Contract.AwsRdsNeptuneClusterModel;
    const neptuneCluster = neptuneClusterModel.entity as Contract.AwsRdsNeptuneCluster;

    const getAwsRdsNeptuneClusterRiskContext = useGetAwsRdsNeptuneClusterRiskContext();
    const getRiskPolicyConfigurationRetentionTimeFrameContextItem = useGetRiskPolicyConfigurationRetentionTimeFrameContextItem();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsRdsNeptuneClusterBackupRetentionTimeFrameRiskDefinition",
            () => ({
                description: "{{neptuneCluster}} backup retention period is less than {{configuredBackupRetentionTimeFrame | TimeSpanFormatter.day}}",
                sections: {
                    resolution: {
                        step1: "In the top right corner, click **Modify**",
                        step2: "Scroll down to the **Additional settings** section, click **Show more** and set the **Backup retention period** to at least {{configuredBackupRetentionTimeFrame | TimeSpanFormatter.day}}",
                        step3: "Continue and save changes"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            configuredBackupRetentionTimeFrame: risk.backupRetentionTimeFrame,
            neptuneCluster:
                <Entity
                    entityIdOrModel={neptuneClusterModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.Rds,
                AwsConsoleUrlBuilder.getRdsNeptuneClusterUrl(neptuneCluster)),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2({ configuredBackupRetentionTimeFrame: risk.backupRetentionTimeFrame }),
            localization.sections.resolution.step3()
        ],
        riskModel,
        () => {
            const neptuneClusterRiskContext = getAwsRdsNeptuneClusterRiskContext(neptuneClusterModel);
            return [
                neptuneClusterRiskContext.generalInformation,
                getRiskPolicyConfigurationRetentionTimeFrameContextItem(risk.backupRetentionTimeFrame),
                neptuneClusterRiskContext.backupRetentionTimeFrame,
                neptuneClusterRiskContext.size,
                neptuneClusterRiskContext.instances,
                neptuneClusterRiskContext.sensitive,
                neptuneClusterRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}