﻿import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../../../../../../../../../../../common";

export function useGitLabOrganizationManagerErrorTranslator() {
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCodeOrganizationItems.gitLab.addOrEditGitLab.hooks.useGitLabOrganizationManagerErrorTranslator",
            () => ({
                [Contract.TypeNames.GitLabOrganizationManagerError]: {
                    [Contract.GitLabOrganizationManagerError.CommonErrorAccessTokenIncorrectRole]: "GitLab access token has a role with insufficient permissions",
                    [Contract.GitLabOrganizationManagerError.CommonErrorAccessTokenIncorrectScope]: "GitLab access token was not created for a root, parent group",
                    [Contract.GitLabOrganizationManagerError.CommonErrorAccessTokenMissingPermissions]: "GitLab access token is missing the required scopes",
                    [Contract.GitLabOrganizationManagerError.CommonErrorAccessTokenUnauthorized]: "GitLab access token is not valid",
                    [Contract.GitLabOrganizationManagerError.CommonErrorOrganizationExists]: "GitLab group already exists",
                    [Contract.GitLabOrganizationManagerError.CommonErrorOrganizationMismatch]: "GitLab access token does not belong to the current group",
                    [Contract.GitLabOrganizationManagerError.CommonErrorServerInvalid]: "The GitLab server integration is not connected"
                }
            }));
    return (error: Contract.GitLabOrganizationManagerError) =>
        localization[Contract.TypeNames.GitLabOrganizationManagerError][error]();
}