import { useLocalization } from "@infrastructure";
import { useMemo } from "react";
import { Contract } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetGcpEntityRiskContext } from "./useGetGcpEntityRiskContext";

export function useGetGcpDnsManagedZoneRiskContext() {
    return useMemo(
        () => useGcpDnsManagedZoneRiskContext,
        []);
}

function useGcpDnsManagedZoneRiskContext(managedZoneModel: Contract.GcpDnsManagedZoneModel) {

    const entityRiskContext = useGetGcpEntityRiskContext()(managedZoneModel);
    const managedZone = managedZoneModel.entity as Contract.GcpDnsManagedZone;

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.contexts.useGetGcpDnsManagedZoneRiskContext",
            () => ({
                loggingEnabled: {
                    false: "The managed zone logging status is **Off**",
                    true: "The managed zone logging status is **On**"
                },
                publicNetworkAccess: {
                    false: "The managed zone visibility is **Private**",
                    true: "The managed zone visibility is **Public**"
                },
                resourceRecordSets: {
                    empty: "The managed zone contains no record sets",
                    notEmpty: [
                        "The managed zone has **1** record set",
                        "The managed zone has **{{count | NumberFormatter.humanize}}** record sets"
                    ]
                }
            }));

    return {
        ...entityRiskContext,
        loggingEnabled:
            new RiskDefinitionContextItem(
                managedZone.loggingEnabled
                    ? localization.loggingEnabled.true()
                    : localization.loggingEnabled.false()),
        publicNetworkAccess:
            new RiskDefinitionContextItem(
                managedZone.publicNetworkAccess
                    ? localization.publicNetworkAccess.true()
                    : localization.publicNetworkAccess.false()),
        resourceRecordSets:
            new RiskDefinitionContextItem(
                managedZoneModel.resourceRecordSetCount === 0
                    ? localization.resourceRecordSets.empty()
                    : localization.resourceRecordSets.notEmpty(managedZoneModel.resourceRecordSetCount))

    };
}