import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useOciNetworkingSubnetStatusTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useOciNetworkingSubnetStatusTranslator",
            () => ({
                [Contract.TypeNames.OciNetworkingSubnetStatus]: {
                    [Contract.OciNetworkingSubnetStatus.Available]: "Available",
                    [Contract.OciNetworkingSubnetStatus.Provisioning]: "Provisioning",
                    [Contract.OciNetworkingSubnetStatus.Terminated]: "Terminated",
                    [Contract.OciNetworkingSubnetStatus.Terminating]: "Terminating",
                    [Contract.OciNetworkingSubnetStatus.Updating]: "Updating"
                }
            }));
    return (status: Contract.OciNetworkingSubnetStatus) =>
        localization[Contract.TypeNames.OciNetworkingSubnetStatus][status]();
}