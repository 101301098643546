import { map, useEvent, useExecuteOperation } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { ApiController, ApplicationHelper, ApplicationView, Contract, useApplicationViewRoute } from "../../common";
import { useSideViewContextProvider } from "../Customer/components";
import { ExportActivatedPermissionAssignmentRequestMail, ExportNewUserMail, ExportPendingApprovalPermissionAssignmentRequestMail, ExportRejectedPermissionAssignmentRequestMail, ExportReport, ExportReportMail, ExportRisksMail } from "./components";

export function System() {
    ApiController.initialize(Contract.ConsoleAppType.System);

    const [{ view }] =
        useApplicationViewRoute(
            `/${ApplicationView.System}`,
            _.values(SystemView),
            SystemView.ExportReport);

    const [registerDataChange, triggerDataChange] = useEvent<(type: Contract.CustomerConsoleAppSideViewType | Contract.TypeNames) => void>();
    const [, , SideViewContextProvider] =
        useSideViewContextProvider(
            () => (
                {
                    items: [],
                    registerDataChange,
                    triggerDataChange
                }
            ));

    useExecuteOperation(
        System,
        () => ApplicationHelper.initialize(Contract.ConsoleAppType.System));

    return (
        <SideViewContextProvider>
            {
                map(
                    view,
                    {
                        [SystemView.ExportActivatedPermissionAssignmentRequestMail]: () => <ExportActivatedPermissionAssignmentRequestMail/>,
                        [SystemView.ExportNewUserMail]: () => <ExportNewUserMail/>,
                        [SystemView.ExportPendingApprovalPermissionAssignmentRequestMail]: () => <ExportPendingApprovalPermissionAssignmentRequestMail/>,
                        [SystemView.ExportRejectedPermissionAssignmentRequestMail]: () => <ExportRejectedPermissionAssignmentRequestMail/>,
                        [SystemView.ExportReport]: () => <ExportReport/>,
                        [SystemView.ExportReportMail]: () => <ExportReportMail/>,
                        [SystemView.ExportRisksMail]: () => <ExportRisksMail/>
                    })}
        </SideViewContextProvider>);
}

const SystemView = {
    [Contract.SystemConsoleAppView.ExportActivatedPermissionAssignmentRequestMail]: "exportActivatedPermissionAssignmentRequestMail",
    [Contract.SystemConsoleAppView.ExportNewUserMail]: "exportNewUserMail",
    [Contract.SystemConsoleAppView.ExportPendingApprovalPermissionAssignmentRequestMail]: "exportPendingApprovalPermissionAssignmentRequestMail",
    [Contract.SystemConsoleAppView.ExportRejectedPermissionAssignmentRequestMail]: "exportRejectedPermissionAssignmentRequestMail",
    [Contract.SystemConsoleAppView.ExportReport]: "exportReport",
    [Contract.SystemConsoleAppView.ExportReportMail]: "exportReportMail",
    [Contract.SystemConsoleAppView.ExportRisksMail]: "exportRisksMail"
};