import { DataTable, DataTableColumn, DataTableColumnRenderProps, EmptyMessageText, InlineItems, Optional, StringHelper, useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, EntitiesCell, Entity, SourceToTargetMap } from "../../../common";

type AzureNetworkApplicationGatewayRoutingRulesTableProps = {
    applicationGateway: Contract.AzureNetworkApplicationGateway;
};

export function AzureNetworkApplicationGatewayRoutingRulesTable({ applicationGateway }: AzureNetworkApplicationGatewayRoutingRulesTableProps) {
    const localization =
        useLocalization(
            "tenants.azure.azureNetworkApplicationGatewayRoutingRulesTable",
            () => ({
                columns: {
                    backendPoolName: "Backend Pool",
                    ipAddress: "IP Address",
                    name: "Name",
                    pathBasedRoutingPaths: {
                        paths: [
                            "1 path",
                            "{{count | NumberFormatter.humanize}} paths"
                        ],
                        title: "Paths"
                    },
                    pathBasedRoutingTargetName: "Target name",
                    ports: "Port",
                    protocol: {
                        title: "Protocol",
                        [Contract.TypeNames.AzureNetworkApplicationGatewayProtocol]: {
                            [Contract.AzureNetworkApplicationGatewayProtocol.Http]: "HTTP",
                            [Contract.AzureNetworkApplicationGatewayProtocol.Https]: "HTTPS"
                        }
                    },
                    targets: {
                        title: "Targets",
                        value: [
                            "1 target",
                            "{{count | NumberFormatter.humanize}} targets"
                        ]
                    },
                    type: {
                        backend: "Backend",
                        redirect: "Redirect",
                        title: "Type"
                    }
                },
                empty: "No Rules",
                title: "Request Routing Rules"
            }));

    const items =
        useMemo(
            () => {
                const backendRuleItems =
                    _.map(
                        applicationGateway.backendRules,
                        backendRule =>
                            new AzureNetworkApplicationGatewayRoutingRulesTableItem(
                                backendRule.backendAddressPool,
                                backendRule.listenerPort,
                                backendRule.listenerPrivateIpAddress,
                                backendRule.listenerProtocol,
                                backendRule.listenerPublicIpAddressId,
                                backendRule.name,
                                backendRule.pathBasedRoutingPaths,
                                backendRule.pathBasedRoutingTargetName,
                                undefined,
                                undefined,
                                undefined,
                                backendRule.targetPort,
                                backendRule.targetProtocol,
                                localization.columns.type.backend()));
                const redirectRuleItems =
                    _.map(
                        applicationGateway.redirectRules,
                        redirectRule =>
                            new AzureNetworkApplicationGatewayRoutingRulesTableItem(
                                undefined,
                                redirectRule.listenerPort,
                                redirectRule.listenerPrivateIpAddress,
                                redirectRule.listenerProtocol,
                                redirectRule.listenerPublicIpAddressId,
                                redirectRule.name,
                                redirectRule.pathBasedRoutingPaths,
                                redirectRule.pathBasedRoutingTargetName,
                                redirectRule.targetPrivateIpAddress,
                                redirectRule.targetPublicIpAddressId,
                                redirectRule.targetUrl,
                                redirectRule.targetPort,
                                undefined,
                                localization.columns.type.redirect()));
                return _(backendRuleItems).
                    concat(redirectRuleItems).
                    orderBy(
                        routingRulesInfoCardItem => StringHelper.getSortValue(routingRulesInfoCardItem.name),
                        "asc").
                    value();
            },
            [applicationGateway.id]);

    return (
        <DataTable
            emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty()) }}
            fetchItems={() => items}
            getItemId={(item: AzureNetworkApplicationGatewayRoutingRulesTableItem) => `${item.name}.${item.pathBasedRoutingTargetName}`}
            sortOptions={{ enabled: false }}
            variant="card">
            {[
                <DataTableColumn
                    id={AzureNetworkApplicationGatewayRoutingRulesTableColumnId.Name}
                    itemProperty={(item: AzureNetworkApplicationGatewayRoutingRulesTableItem) => item.name}
                    key={AzureNetworkApplicationGatewayRoutingRulesTableColumnId.Name}
                    title={localization.columns.name()}/>,
                <DataTableColumn
                    id={AzureNetworkApplicationGatewayRoutingRulesTableColumnId.Type}
                    itemProperty={(item: AzureNetworkApplicationGatewayRoutingRulesTableItem) => item.type}
                    key={AzureNetworkApplicationGatewayRoutingRulesTableColumnId.Type}
                    title={localization.columns.type.title()}/>,
                <DataTableColumn
                    id={AzureNetworkApplicationGatewayRoutingRulesTableColumnId.IpAddress}
                    key={AzureNetworkApplicationGatewayRoutingRulesTableColumnId.IpAddress}
                    render={
                        ({ item }: DataTableColumnRenderProps<AzureNetworkApplicationGatewayRoutingRulesTableItem>) =>
                            _.isNil(item.listenerPublicIpAddressId)
                                ? <InlineItems
                                    items={item.listenerPrivateIpAddress}
                                    variant="text"/>
                                : <EntitiesCell
                                    entityIdsOrModels={item.listenerPublicIpAddressId}
                                    entityTypeName={Contract.TypeNames.AzureNetworkPublicIpAddress}/>}
                    title={localization.columns.ipAddress()}/>,
                <DataTableColumn
                    id={AzureNetworkApplicationGatewayRoutingRulesTableColumnId.Protocol}
                    key={AzureNetworkApplicationGatewayRoutingRulesTableColumnId.Protocol}
                    render={
                        ({ item }: DataTableColumnRenderProps<AzureNetworkApplicationGatewayRoutingRulesTableItem>) =>
                            <SourceToTargetMap
                                source={item.listenerProtocol}
                                target={item.targetProtocol ?? item.listenerProtocol}>
                                {(protocol: Contract.AzureNetworkApplicationGatewayProtocol) => localization.columns.protocol[Contract.TypeNames.AzureNetworkApplicationGatewayProtocol][protocol]()}
                            </SourceToTargetMap>}
                    title={localization.columns.protocol.title()}/>,
                <DataTableColumn
                    id={AzureNetworkApplicationGatewayRoutingRulesTableColumnId.Ports}
                    key={AzureNetworkApplicationGatewayRoutingRulesTableColumnId.Ports}
                    render={
                        ({ item }: DataTableColumnRenderProps<AzureNetworkApplicationGatewayRoutingRulesTableItem>) =>
                            <SourceToTargetMap
                                source={item.listenerPort}
                                target={item.targetPort ?? item.listenerPort}/>}
                    title={localization.columns.ports()}/>,
                <DataTableColumn
                    id={AzureNetworkApplicationGatewayRoutingRulesTableColumnId.PathBasedRoutingPaths}
                    key={AzureNetworkApplicationGatewayRoutingRulesTableColumnId.PathBasedRoutingPaths}
                    render={
                        ({ item }: DataTableColumnRenderProps<AzureNetworkApplicationGatewayRoutingRulesTableItem>) =>
                            <InlineItems
                                items={item.pathBasedRoutingPaths}
                                namePluralizer={localization.columns.pathBasedRoutingPaths.paths}
                                variant="itemOrItemCountAndType"/>}
                    title={localization.columns.pathBasedRoutingPaths.title()}/>,
                <DataTableColumn
                    id={AzureNetworkApplicationGatewayRoutingRulesTableColumnId.BackendPoolName}
                    itemProperty={(item: AzureNetworkApplicationGatewayRoutingRulesTableItem) => item.backendAddressPool?.name}
                    key={AzureNetworkApplicationGatewayRoutingRulesTableColumnId.BackendPoolName}
                    title={localization.columns.backendPoolName()}/>,
                <DataTableColumn
                    id={AzureNetworkApplicationGatewayRoutingRulesTableColumnId.Targets}
                    key={AzureNetworkApplicationGatewayRoutingRulesTableColumnId.Targets}
                    render={
                        ({ item }: DataTableColumnRenderProps<AzureNetworkApplicationGatewayRoutingRulesTableItem>) => {
                            if (!_.isNil(item.backendAddressPool)) {
                                const targetEntities =
                                    _(item.backendAddressPool.virtualMachineIds).
                                        concat(item.backendAddressPool.virtualMachineScaleSetIds).
                                        map(
                                            entityId =>
                                                <Entity
                                                    entityIdOrModel={entityId}
                                                    key={entityId}
                                                    variant="text"/>).
                                        value();
                                const targetHostnames =
                                    _(item.backendAddressPool.dnsNames).
                                        concat(
                                            item.backendAddressPool.privateIpAddresses,
                                            item.backendAddressPool.publicIpAddresses).
                                        map(
                                            (text, index) =>
                                                <Typography
                                                    key={index}
                                                    noWrap={true}>
                                                    {text}
                                                </Typography>).
                                        value();
                                return (
                                    <InlineItems
                                        items={_.concat(targetEntities, targetHostnames)}
                                        namePluralizer={localization.columns.targets.value}
                                        variant="itemPlusItemCount"/>);
                            } else if (!_.isNil(item.redirectRuleTargetUrl)) {
                                return (
                                    <Typography noWrap={true}>
                                        {item.redirectRuleTargetUrl}
                                    </Typography>);
                            } else if (!_.isNil(item.redirectRuleTargetPublicIpAddressId)) {
                                return (
                                    <Entity
                                        entityIdOrModel={item.redirectRuleTargetPublicIpAddressId}
                                        variant="text"/>);
                            } else {
                                return (
                                    <Typography noWrap={true}>
                                        {item.redirectRuleTargetPrivateIpAddress}
                                    </Typography>);
                            }
                        }}
                    title={localization.columns.targets.title()}/>
            ]}
        </DataTable>);
}

enum AzureNetworkApplicationGatewayRoutingRulesTableColumnId {
    BackendPoolName = "backendAddressPoolName",
    IpAddress = "ipAddress",
    Name = "name",
    PathBasedRoutingPaths = "pathBasedRoutingPaths",
    Ports = "ports",
    Protocol = "protocol",
    Targets = "targets",
    Type = "type"
}

class AzureNetworkApplicationGatewayRoutingRulesTableItem {
    constructor(
        public backendAddressPool: Optional<Contract.AzureNetworkApplicationGatewayBackendAddressPool>,
        public listenerPort: number,
        public listenerPrivateIpAddress: Optional<string>,
        public listenerProtocol: Contract.AzureNetworkApplicationGatewayProtocol,
        public listenerPublicIpAddressId: Optional<string>,
        public name: string,
        public pathBasedRoutingPaths: Optional<string[]>,
        public pathBasedRoutingTargetName: Optional<string>,
        public redirectRuleTargetPrivateIpAddress: Optional<string>,
        public redirectRuleTargetPublicIpAddressId: Optional<string>,
        public redirectRuleTargetUrl: Optional<string>,
        public targetPort: Optional<number>,
        public targetProtocol: Optional<Contract.AzureNetworkApplicationGatewayProtocol>,
        public type: string) {
    }
}