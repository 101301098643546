import { Step, Steps, TextSnippet, TextSnippetItem, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { useAddOrEditContext } from "../../..";
import { useTheme } from "../../../../../../../../../../../../../../../../../common";
import { GcpCloudShellIcon } from "../../../../../../../../../../../../../../../../../tenants";
import { defaultSinkPubSubRawShortId, defaultSinkPubSubSubscriptionRawShortId, useSinkPubSubSubscriptionRawIdStep } from "../hooks";

export function CloudShell() {
    const { gciTenantModel, organizationOnboardingInfo } = useAddOrEditContext();

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCloudProviderTenantOrganizationItems.gcp.addOrEdit.sinkPubSubSubscriptionItem.cloudShell",
            () => ({
                steps: {
                    step1: "Log into your Google Cloud Console.",
                    step2: "**Activate the Cloud Shell** by clicking {{cloudShellIcon}} on the top right of the console.",
                    step3: "**Run** the following script in your Cloud Shell:",
                    step4: "Wait until the script has completed successfully and copy the **Pub/Sub Subscription Name** from the output."
                }
            }));

    const bashScript =
        useMemo(
            () => {
                const serviceAccountProjectRawShortNameId = gciTenantModel!.serviceAccountTenantRawShortNameId;
                const exclusionFilterValue = _.replace(organizationOnboardingInfo.sinkExclusionFilter, "\n", " ");
                const inclusionFilterValue = _.replace(organizationOnboardingInfo.sinkInclusionFilter, "\n", " ");
                return _.join(
                    [
                        `organization_id=$(gcloud organizations describe ${gciTenantModel!.configuration.primaryDomainName} --format='value(name.sub("organizations/", ""))')`,
                        `gcloud pubsub topics create ${defaultSinkPubSubRawShortId} --message-retention-duration=${organizationOnboardingInfo.pubSubMessageRetentionDayCount}d --project=${serviceAccountProjectRawShortNameId}`,
                        `gcloud logging sinks create ${defaultSinkPubSubRawShortId} pubsub.googleapis.com/projects/${serviceAccountProjectRawShortNameId}/topics/${defaultSinkPubSubRawShortId} --exclusion=filter='${exclusionFilterValue}',name=exclude-k8s-internal --include-children --log-filter='${inclusionFilterValue}' --organization=$organization_id`,
                        `sink_writer_identity=$(gcloud logging sinks describe ${defaultSinkPubSubRawShortId} --organization=$organization_id --format='value(writerIdentity)')`,
                        `gcloud pubsub topics add-iam-policy-binding ${defaultSinkPubSubRawShortId} --project=${serviceAccountProjectRawShortNameId} --member=$sink_writer_identity --role='roles/pubsub.publisher'`,
                        `gcloud pubsub subscriptions create ${defaultSinkPubSubSubscriptionRawShortId} --message-retention-duration=${organizationOnboardingInfo.pubSubMessageRetentionDayCount}d --expiration-period=never --max-retry-delay=600 --topic=${defaultSinkPubSubRawShortId} --topic-project=${serviceAccountProjectRawShortNameId} --project=${serviceAccountProjectRawShortNameId}`,
                        `gcloud pubsub subscriptions add-iam-policy-binding ${defaultSinkPubSubSubscriptionRawShortId} --project=${serviceAccountProjectRawShortNameId} --member='serviceAccount:${gciTenantModel!.configuration.serviceAccountMail}' --role='roles/pubsub.subscriber'`,
                        `echo Pub/Sub Subscription Name: projects/${serviceAccountProjectRawShortNameId}/subscriptions/${defaultSinkPubSubSubscriptionRawShortId}`
                    ],
                    "\n");
            },
            []);

    const sinkPubSubSubscriptionRawIdStep = useSinkPubSubSubscriptionRawIdStep();
    const theme = useTheme();
    return (
        <Steps variant="letters">
            {localization.steps.step1()}
            {localization.steps.step2({
                cloudShellIcon:
                    <GcpCloudShellIcon
                        sx={{
                            display: "inline",
                            fontSize: "18px",
                            verticalAlign: "middle"
                        }}/>
            })}
            {new Step(
                localization.steps.step3(),
                {
                    contentElement:
                        <TextSnippet
                            height={theme.spacing(52)}
                            items={[
                                new TextSnippetItem(
                                    bashScript,
                                    "")
                            ]}
                            wordWrap={false}/>
                })}
            {localization.steps.step4()}
            {sinkPubSubSubscriptionRawIdStep}
        </Steps>);
}