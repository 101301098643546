import { FormLayout, Link, Message, StringHelper, useInputValidation, useLocalization } from "@infrastructure";
import { Button, CircularProgress, FormControl, FormHelperText, Stack, TextField, Typography } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useKubernetesClusterConnectorsContext, useSetKubernetesClusterConnectorsContext } from "../..";
import { ConfigurationController, Contract, CustomerConsoleAppUrlHelper, scopeSystemEntityModelStore, useScopeNavigationViewContext, useTheme } from "../../../../../../../../../../../../../../common";

export function AddOrEdit() {
    const { scopeNodeModel } = useScopeNavigationViewContext();
    const { addOrEditOpen } = useKubernetesClusterConnectorsContext();
    const setKubernetesClusterConnectorsContext = useSetKubernetesClusterConnectorsContext();

    const kubernetesClusterConnectorModels = scopeSystemEntityModelStore.useGetKubernetesClusterConnector();
    const kubernetesClusterConnectorModel =
        _.isBoolean(addOrEditOpen)
            ? undefined
            : addOrEditOpen;
    const kubernetesClusterConnectorName = (kubernetesClusterConnectorModel?.configuration as Contract.KubernetesClusterConnectorConfiguration)?.name;
    const [name, setName] = useState(kubernetesClusterConnectorName ?? "");

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useKubernetesItems.kubernetesClusterConnectors.addOrEdit",
            () => ({
                actions: {
                    save: {
                        error: "Failed to save",
                        title: {
                            add: "Add",
                            edit: "Save"
                        }
                    }
                },
                fields: {
                    kubernetesClusterConnectorModels: "We recommend adding one connector for all clusters in your environment.",
                    name: {
                        error: {
                            required: "Name cannot be empty"
                        },
                        title: "Name"
                    }
                },
                subtitle: {
                    link: {
                        documentationKubernetesOnboardCluster: "Learn more",
                        systemKubernetesClusters: "Kubernetes clusters"
                    },
                    text: "Create a connector before you onboard your {{systemKubernetesClustersPageLink}}. {{documentationKubernetesOnboardClusterLink}}."
                },
                title: {
                    add: "Add Kubernetes Cluster Connector",
                    edit: "Edit Kubernetes Cluster Connector"
                }
            }));

    const [saveExecuting, setSaveExecuting] = useState(false);
    const [saveError, setSaveError] = useState(false);

    async function save() {
        setSaveError(false);

        if (!_.isNil(kubernetesClusterConnectorModel) &&
            name === kubernetesClusterConnectorName) {
            return;
        }

        setSaveExecuting(true);

        try {
            const { scopeSystemEntityModel } =
                await ConfigurationController.upsertKubernetesClusterConnector(
                    new Contract.ConfigurationControllerUpsertKubernetesClusterConnectorRequest(
                        kubernetesClusterConnectorModel?.configuration.id,
                        name,
                        scopeNodeModel.configuration.id));
            await scopeSystemEntityModelStore.notify(scopeSystemEntityModel);

            setKubernetesClusterConnectorsContext(
                kubernetesClusterConnectorsContext => ({
                    ...kubernetesClusterConnectorsContext,
                    addOrEditOpen: false
                }));
        } catch {
            setSaveError(true);
        }

        setSaveExecuting(false);
    }

    const [nameValidationController, nameValidationMessage] =
        useInputValidation(
            () => {
                const normalizedName = StringHelper.normalize(name);

                if (_.isEmpty(normalizedName)) {
                    return localization.fields.name.error.required();
                }

                return undefined;
            },
            [name]);

    const [valid, setValid] = useState(false);
    useEffect(
        () => {
            setValid(nameValidationController.isValid());
        },
        [name]);

    const theme = useTheme();
    return (
        <FormLayout
            footerOptions={{
                contentElement:
                    <Stack
                        alignItems="center"
                        direction="row"
                        justifyContent="flex-end"
                        spacing={1}>
                        {saveExecuting && (
                            <CircularProgress
                                size={theme.spacing(2)}
                                variant="indeterminate"/>)}
                        <Button
                            disabled={!valid || saveExecuting}
                            onClick={() => save()}>
                            {_.isNil(kubernetesClusterConnectorModel)
                                ? localization.actions.save.title.add()
                                : localization.actions.save.title.edit()}
                        </Button>
                    </Stack>
            }}
            titleOptions={{
                text:
                    _.isNil(kubernetesClusterConnectorModel)
                        ? localization.title.add()
                        : localization.title.edit()
            }}>
            <Stack spacing={2}>
                <Typography sx={{ whiteSpace: "pre-wrap" }}>
                    {localization.subtitle.text({
                        documentationKubernetesOnboardClusterLink:
                            <Link
                                urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsOnboardClusterViaTenableConnectorAddKubernetesClusterRelativeUrl()}
                                variant="external">
                                {localization.subtitle.link.documentationKubernetesOnboardCluster()}
                            </Link>,
                        systemKubernetesClustersPageLink:
                            <Link urlOrGetUrl={CustomerConsoleAppUrlHelper.getSystemKubernetesClustersRelativeUrl()}>
                                {localization.subtitle.link.systemKubernetesClusters()}
                            </Link>
                    })}
                </Typography>
                {!_.isEmpty(kubernetesClusterConnectorModels) &&
                    <Message
                        level="info"
                        title={localization.fields.kubernetesClusterConnectorModels()}/>}
                <FormControl
                    fullWidth={true}
                    variant="standard">
                    <TextField
                        label={localization.fields.name.title()}
                        value={name}
                        variant="outlined"
                        onChange={event => setName(event.target.value)}/>
                    {!_.isNil(nameValidationMessage) && (
                        <FormHelperText error={true}>{nameValidationMessage}</FormHelperText>)}
                </FormControl>
                {saveError && (
                    <Message
                        level="error"
                        title={localization.actions.save.error()}/>)}
            </Stack>
        </FormLayout>);
}