import { DataTableColumn, DataTableSortType, optionalTableCell, useLocalization } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { useAwsCommonResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";

export function useAwsWafWebAclDefinition(definitionData: DefinitionData) {
    const commonResourceDefinition = useAwsCommonResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.aws.useAwsWafWebAclDefinition",
            () => ({
                columns: {
                    associatedResources: "Associated Resources",
                    name: "Name"
                }
            }));

    return new EntityTableDefinition(
        _.filter(
            [
                commonResourceDefinition.columns.tenantColumn,
                commonResourceDefinition.columns.creationTimeColumn,
                commonResourceDefinition.columns.creatorIdentityCsvColumn,
                commonResourceDefinition.columns.creatorOriginatorEntityCsvColumn,
                <DataTableColumn
                    exportOptions={
                        entitiesExportOptions<Contract.AwsWafWebAclModel>(
                            Contract.TypeNames.AwsResource,
                            item => item.associatedResourceIdReferences,
                            localization.columns.associatedResources())
                    }
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedEntityFilter
                                    getEntityIdPage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.AwsWafWebAclAssociatedResources)}
                                    placeholder={localization.columns.associatedResources()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsWafWebAclAssociatedResources}
                    key={Contract.EntityModelProperty.AwsWafWebAclAssociatedResources}
                    render={
                        optionalTableCell<Contract.AwsWafWebAclModel>(
                            item =>
                                _.isEmpty(item.associatedResourceIdReferences)
                                    ? undefined
                                    : <EntitiesCell
                                        entityIdsOrModels={item.associatedResourceIdReferences}
                                        entityTypeName={Contract.TypeNames.AwsResource}
                                        entityVariant="iconText"/>
                        )}
                    sortOptions={{ type: DataTableSortType.Numeric }}
                    title={localization.columns.associatedResources()}/>,
                commonResourceDefinition.columns.regionColumn,
                commonResourceDefinition.columns.regionLocationColumn,
                commonResourceDefinition.columns.tagsColumn,
                commonResourceDefinition.columns.attributesColumn
            ]),
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAwsWafWebAclRequest(
                new Contract.EntityControllerGetEntityModelPageAwsWafWebAclRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsWafWebAclAssociatedResources])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags]))));
}