import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../..";
import { useCustomEntityPropertyInfoItemElements } from "../..";
import { useAwsCommonEc2ResourceInfoElements, useAwsCommonEncryptedResourceInfoElements, useAwsDefaultResourceInfoItemElements } from "..";
import { Contract, InfoItem } from "../../../../../../../../../../../common";
import { EntitiesInfoItem, EntityTypeNameGroupsInfoCard, Info } from "../../../../../components";
import { Revisions } from "./components";

export function useAwsEc2LaunchTemplateDefinition(resourceModel: Contract.AwsResourceModel, options?: DefinitionOptions) {
    const commonEc2ResourceInfoItemElements = useAwsCommonEc2ResourceInfoElements(resourceModel);
    const commonEncryptedResourceInfoElements = useAwsCommonEncryptedResourceInfoElements(resourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useAwsDefaultResourceInfoItemElements(resourceModel);
    const launchTemplate = resourceModel.entity as Contract.AwsEc2LaunchTemplate;
    const launchTemplateModel = resourceModel as Contract.AwsEc2LaunchTemplateModel;

    const [autoScalingGroupIds, instanceIds, , vpcIds] =
        useMemo(
            () => {
                const revisionDatas = _.values(launchTemplateModel.revisionIdToDataMap);

                const autoScalingGroupIds =
                    _(revisionDatas).
                        flatMap(revisionData => revisionData.autoScalingGroupIds).
                        concat(launchTemplateModel.deletedRevisionAutoScalingGroupIds).
                        uniq().
                        value();

                const instanceIds =
                    _(revisionDatas).
                        flatMap(revisionData => revisionData.instanceIds).
                        concat(launchTemplateModel.deletedRevisionInstanceIds).
                        uniq().
                        value();

                const resourceIds =
                    _.concat(
                        autoScalingGroupIds,
                        instanceIds);

                const vpcIds =
                    _(launchTemplate.revisionMap).
                        values().
                        flatMap(revision => revision.vpcIds).
                        uniq().
                        value();

                return [autoScalingGroupIds, instanceIds, resourceIds, vpcIds];
            },
            [launchTemplateModel]);

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsEc2LaunchTemplateDefinition",
            () => ({
                info: {
                    cards: {
                        resourceIds: {
                            emptyMessage: "No resources",
                            title: "Resources"
                        }
                    },
                    items: {
                        defaultRevisionId: "Default Version",
                        latestRevisionId: "Latest Version",
                        rawId: "ID",
                        vpcIds: "VPC"
                    }
                },
                tabs: {
                    revisions: "Revisions ({{revisionCount | NumberFormatter.humanize}})"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Overview,
                <Revisions launchTemplateModel={launchTemplateModel}/>,
                localization.tabs.revisions({ revisionCount: _.size(launchTemplate.revisionMap) }),
                "revisions")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    ...commonEc2ResourceInfoItemElements,
                    ...commonEncryptedResourceInfoElements,
                    <InfoItem
                        key="rawId"
                        title={localization.info.items.rawId()}
                        value={launchTemplate.rawId}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={vpcIds}
                        entityTypeName={Contract.TypeNames.AwsEc2Vpc}
                        key="vpcIds"
                        title={localization.info.items.vpcIds()}/>,
                    <InfoItem
                        key="defaultRevisionId"
                        title={localization.info.items.defaultRevisionId()}
                        value={launchTemplate.defaultRevisionId}/>,
                    <InfoItem
                        key="latestRevisionId"
                        title={localization.info.items.latestRevisionId()}
                        value={launchTemplate.latestRevisionId}/>
                ]}
                options={options?.infoOptions}>
                <EntityTypeNameGroupsInfoCard
                    emptyMessage={localization.info.cards.resourceIds.emptyMessage()}
                    entityTypeNameToIdsMap={{
                        [Contract.TypeNames.AwsAutoScalingAutoScalingGroup]: autoScalingGroupIds,
                        [Contract.TypeNames.AwsEc2Instance]: instanceIds
                    }}
                    title={localization.info.cards.resourceIds.title()}/>
            </Info>
    });
}