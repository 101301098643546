import { DataTable, DataTableColumn, DataTableColumnRenderProps, DataTableFetchItemsResult, DataTableSort, DataTableSortDirection, DataTableSortType, DeferredFilter, EmptyMessageText, IconText, NoneIcon, Optional, optionalTableCell, PagedValuesFilter, PagedValuesFilterValuePage, useLocalization, useOperation, ValueFilter, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { useRef } from "react";
import { CodeController, ComplianceFilter, Compliances, Contract, CustomerConsoleAppUrlHelper, ItemSelectionHelper, LicensingHelper, RiskPolicyCategory, RiskPolicyTypeMetadataHelper, RiskTypeMetadataModelHelper, Severity, SeverityFilter, TenantIcon, useRiskPolicyCategoryTranslator, useRiskPolicyTitleTranslator, useTenantTypeTranslator, useTheme } from "../../../../../../../../../common";
import { CodeTypeIcon } from "../../../../../../../../../tenants";
import { useBuiltInRiskPolicyConfigurationTypeNameToAnalysisGroupTypeToComplianceTypesMap } from "../../../../../../Compliance/hooks";

type RisksTableProps = {
    scan: Contract.CodeIacScan;
};

export function RisksTable({ scan }: RisksTableProps) {
    const builtInRiskPolicyConfigurationTypeNameToAnalysisGroupTypeToComplianceTypesMap = useBuiltInRiskPolicyConfigurationTypeNameToAnalysisGroupTypeToComplianceTypesMap();

    const riskPolicyTitleTranslator = useRiskPolicyTitleTranslator();
    const riskPolicyCategoryTranslator = useRiskPolicyCategoryTranslator();
    const tenantTypeTranslator = useTenantTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.code.scans.scan.risksTable",
            () => ({
                columns: {
                    cloudTenantType: "Cloud",
                    compliance: "Compliance",
                    fileRelativePath: "File",
                    new: {
                        filter: "New Findings Only"
                    },
                    resourceName: "Resource",
                    resourceTypeSystemName: "Resource Type",
                    riskPolicyCategory: "Category",
                    riskPolicyTitle: "Policy",
                    severity: "Severity"
                },
                empty: "No IaC Findings"
            }));

    const fetchRisks =
        async (filterMap: Dictionary<any>, sort: Optional<DataTableSort>, skip: number, limit: number) => {
            const { riskPage } =
                await CodeController.getIacRiskPage(
                    new Contract.CodeControllerGetIacRiskPageRequest(
                        new Contract.CodeControllerGetIacRiskPageRequestFilters(
                            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.CodeControllerGetIacRiskPageRequestProperty.Category]),
                            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.CodeControllerGetIacRiskPageRequestProperty.CloudTenantType]),
                            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.CodeControllerGetIacRiskPageRequestProperty.ComplianceIdOrSectionType]),
                            ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.CodeControllerGetIacRiskPageRequestProperty.FileRelativePath]),
                            filterMap[Contract.CodeControllerGetIacRiskPageRequestProperty.New] as Optional<boolean>,
                            ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.CodeControllerGetIacRiskPageRequestProperty.ResourceName]),
                            ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.CodeControllerGetIacRiskPageRequestProperty.ResourceTypeSystemName]),
                            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.CodeControllerGetIacRiskPageRequestProperty.RiskPolicyConfigurationTypeName]),
                            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.CodeControllerGetIacRiskPageRequestProperty.Severity])),
                        limit,
                        scan.id,
                        skip,
                        new Contract.CodeControllerGetIacRiskPageRequestSort(
                            _.isNil(sort) || sort.direction === DataTableSortDirection.Descending
                                ? Contract.SortDirection.Descending
                                : Contract.SortDirection.Ascending,
                            _.isNil(sort)
                                ? Contract.CodeControllerGetIacRiskPageRequestProperty.Severity
                                : sort.columnId as Contract.CodeControllerGetIacRiskPageRequestProperty)));

            return new DataTableFetchItemsResult(
                { count: riskPage.count! },
                riskPage.items,
                !riskPage.hasMore);
        };

    const getScanRiskFilterItemPage =
        async (property: Contract.CodeControllerGetIacRiskPageRequestProperty, searchText: Optional<string>, skip: number, limit: number, data: CodeScanRiskFilterItemPageData) => {
            const { riskFilterItemPage } =
                await CodeController.getIacRiskFilterItemPage(
                    new Contract.CodeControllerGetIacRiskFilterItemPageRequest(
                        limit,
                        property,
                        scan.id,
                        searchText,
                        skip)) as Contract.CodeControllerGetIacRiskFilterItemPageResponse;
            const riskFilterItemCount = riskFilterItemPage.count ?? data.count;

            return new PagedValuesFilterValuePage(
                riskFilterItemCount,
                riskFilterItemPage.emptyValue,
                riskFilterItemPage.items,
                () => ({ count: riskFilterItemCount }));
        };

    const [filtersPromise] =
        useOperation(
            RisksTable,
            async () => {
                const { filters } = await CodeController.getIacRiskFilters(new Contract.CodeControllerGetIacRiskFiltersRequest(scan.id));
                return filters;
            });

    const sortRef = useRef<DataTableSort>();
    const theme = useTheme();
    return (
        <DataTable
            columnOptions={{
                resizable: true,
                selectorOptions: {
                    enabled: true
                }
            }}
            emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty()) }}
            fetchItems={fetchRisks}
            filtersOptions={{
                sx: {
                    padding: theme.spacing(0, 0, 1.5, 0)
                }
            }}
            getItemId={(item: Contract.CodeIacRisk) => item.id}
            rowOptions={{
                getUrl: (item: Contract.CodeIacRisk) => CustomerConsoleAppUrlHelper.getCodeScanRiskHashUrl(scan.id, item.id)
            }}
            onSortChanged={sort => sortRef.current = sort}>
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        default: true,
                        element:
                            <SeverityFilter
                                emptyValueOptions={{ enabled: false }}
                                placeholder={localization.columns.severity()}/>
                    }
                }}
                id={Contract.CodeControllerGetIacRiskPageRequestProperty.Severity}
                key={Contract.CodeControllerGetIacRiskPageRequestProperty.Severity}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.CodeIacRisk>) =>
                        <Severity severity={item.severity}/>}
                selectorOptions={{ disabled: true }}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.severity()}/>
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        element:
                            <DeferredFilter
                                promiseOrGetPromise={filtersPromise}
                                title={localization.columns.riskPolicyCategory()}>
                                {filters =>
                                    <ValuesFilter
                                        emptyValueOptions={{ enabled: filters.categoryItems.emptyValue }}
                                        placeholder={localization.columns.riskPolicyCategory()}>
                                        {_.map(
                                            filters.categoryItems.items,
                                            riskPolicyCategory =>
                                                <ValuesFilterItem
                                                    key={riskPolicyCategory}
                                                    title={riskPolicyCategoryTranslator(riskPolicyCategory)}
                                                    value={riskPolicyCategory}/>)}
                                    </ValuesFilter>}
                            </DeferredFilter>
                    }
                }}
                id={Contract.CodeControllerGetIacRiskPageRequestProperty.Category}
                key={Contract.CodeControllerGetIacRiskPageRequestProperty.Category}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.CodeIacRisk>) =>
                        <RiskPolicyCategory
                            riskPolicyCategory={RiskPolicyTypeMetadataHelper.get(RiskTypeMetadataModelHelper.get(item.riskTypeName).policyConfigurationTypeName).category}
                            variant="risk"/>}
                sortOptions={{ enabled: false }}
                title={localization.columns.riskPolicyCategory()}/>
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        element:
                            <DeferredFilter
                                promiseOrGetPromise={filtersPromise}
                                title={localization.columns.cloudTenantType()}>
                                {filters =>
                                    <ValuesFilter
                                        emptyValueOptions={{ enabled: filters.cloudTenantTypeItems.emptyValue }}
                                        placeholder={localization.columns.cloudTenantType()}>
                                        {_.map(
                                            filters.cloudTenantTypeItems.items,
                                            cloudTenantType =>
                                                <ValuesFilterItem
                                                    key={cloudTenantType}
                                                    title={tenantTypeTranslator(cloudTenantType)}
                                                    value={cloudTenantType}/>)}
                                    </ValuesFilter>}
                            </DeferredFilter>
                    }
                }}
                id={Contract.CodeControllerGetIacRiskPageRequestProperty.CloudTenantType}
                key={Contract.CodeControllerGetIacRiskPageRequestProperty.CloudTenantType}
                sortOptions={{ enabled: false }}
                title={localization.columns.cloudTenantType()}/>
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        default: true,
                        element:
                            <DeferredFilter
                                promiseOrGetPromise={filtersPromise}
                                title={localization.columns.riskPolicyTitle()}>
                                {filters =>
                                    <ValuesFilter
                                        emptyValueOptions={{ enabled: filters.riskPolicyConfigurationTypeNameItems.emptyValue }}
                                        groupItemTitle={true}
                                        placeholder={localization.columns.riskPolicyTitle()}>
                                        {_.map(
                                            filters.riskPolicyConfigurationTypeNameItems.items,
                                            riskPolicyConfigurationTypeName =>
                                                <ValuesFilterItem
                                                    key={riskPolicyConfigurationTypeName}
                                                    title={riskPolicyTitleTranslator(riskPolicyConfigurationTypeName)}
                                                    value={riskPolicyConfigurationTypeName}/>)}
                                    </ValuesFilter>}
                            </DeferredFilter>
                    }
                }}
                id={Contract.CodeControllerGetIacRiskPageRequestProperty.RiskPolicyConfigurationTypeName}
                itemProperty={(item: Contract.CodeIacRisk) => riskPolicyTitleTranslator(RiskTypeMetadataModelHelper.get(item.riskTypeName).policyConfigurationTypeName)}
                key={Contract.CodeControllerGetIacRiskPageRequestProperty.RiskPolicyConfigurationTypeName}
                selectorOptions={{ disabled: true }}
                sortOptions={{ enabled: false }}
                title={localization.columns.riskPolicyTitle()}/>
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedValuesFilter
                                getValuePage={
                                    (searchText, skip, limit, data) =>
                                        getScanRiskFilterItemPage(
                                            Contract.CodeControllerGetIacRiskPageRequestProperty.FileRelativePath,
                                            searchText,
                                            skip,
                                            limit,
                                            data)}
                                placeholder={localization.columns.fileRelativePath()}/>
                    }
                }}
                id={Contract.CodeControllerGetIacRiskPageRequestProperty.FileRelativePath}
                key={Contract.CodeControllerGetIacRiskPageRequestProperty.FileRelativePath}
                render={
                    optionalTableCell<Contract.CodeIacRisk>(
                        (item: Contract.CodeIacRisk) =>
                            <IconText
                                icon={
                                    <CodeTypeIcon
                                        codeType={item.codeType}
                                        sx={{
                                            color: theme.palette.text.secondary,
                                            fontSize: "18px"
                                        }}/>}
                                text={
                                    <Typography noWrap={true}>
                                        {item.fileRelativePath}
                                    </Typography>}/>
                    )}
                title={localization.columns.fileRelativePath()}/>
            <DataTableColumn
                disableAction={true}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedValuesFilter
                                getValuePage={
                                    (searchText, skip, limit, data) =>
                                        getScanRiskFilterItemPage(
                                            Contract.CodeControllerGetIacRiskPageRequestProperty.ResourceName,
                                            searchText,
                                            skip,
                                            limit,
                                            data)}
                                placeholder={localization.columns.resourceName()}/>
                    }
                }}
                id={Contract.CodeControllerGetIacRiskPageRequestProperty.ResourceName}
                key={Contract.CodeControllerGetIacRiskPageRequestProperty.ResourceName}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.CodeIacRisk>) =>
                        <IconText
                            icon={
                                <TenantIcon
                                    sx={{ fontSize: "18px" }}
                                    tenantType={
                                        _.isNil(item.cloudTenantType)
                                            ? Contract.TenantType.Code
                                            : item.cloudTenantType}/>}
                            text={
                                <Stack
                                    alignItems="center"
                                    direction="row"
                                    spacing={1}>
                                    <Typography
                                        noWrap={true}
                                        sx={{ width: "fit-content" }}>
                                        {item.resourceName}
                                    </Typography>
                                    <Typography
                                        noWrap={true}
                                        variant="subtitle1">
                                        {item.resourceTypeSystemName}
                                    </Typography>
                                </Stack>}/>}
                sortOptions={{ enabled: false }}
                title={localization.columns.resourceName()}/>
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedValuesFilter
                                getValuePage={
                                    (searchText, skip, limit, data) =>
                                        getScanRiskFilterItemPage(
                                            Contract.CodeControllerGetIacRiskPageRequestProperty.ResourceTypeSystemName,
                                            searchText,
                                            skip,
                                            limit,
                                            data)}
                                placeholder={localization.columns.resourceTypeSystemName()}/>
                    }
                }}
                id={Contract.CodeControllerGetIacRiskPageRequestProperty.ResourceTypeSystemName}
                key={Contract.CodeControllerGetIacRiskPageRequestProperty.ResourceTypeSystemName}
                title={localization.columns.resourceTypeSystemName()}/>
            {LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.Cspm) &&
                <DataTableColumn
                    disableAction={true}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <DeferredFilter
                                    promiseOrGetPromise={filtersPromise}
                                    title={localization.columns.compliance()}>
                                    {filters =>
                                        <ComplianceFilter
                                            complianceIdentifiers={filters.complianceIdentifierItems.items}
                                            emptyValue={filters.complianceIdentifierItems.emptyValue}
                                            placeholder={localization.columns.compliance()}/>
                                    }
                                </DeferredFilter>
                        }
                    }}
                    id={Contract.CodeControllerGetIacRiskPageRequestProperty.ComplianceIdOrSectionType}
                    key={Contract.CodeControllerGetIacRiskPageRequestProperty.ComplianceIdOrSectionType}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.CodeIacRisk>) => {
                            const builtInComplianceTypes = builtInRiskPolicyConfigurationTypeNameToAnalysisGroupTypeToComplianceTypesMap[RiskTypeMetadataModelHelper.get(item.riskTypeName).policyConfigurationTypeName][Contract.RiskPolicyTypeMetadataAnalysisGroupType.AllEntities];
                            return _.size(builtInComplianceTypes) > 0
                                ? <Compliances builtInComplianceTypes={builtInComplianceTypes}/>
                                : <NoneIcon/>;
                        }}
                    selectorOptions={{ default: false }}
                    sortOptions={{ enabled: false }}
                    title={localization.columns.compliance()}/>}
            <DataTableColumn
                disableAction={true}
                filterOptions={{
                    itemOrItems: {
                        default: true,
                        element:
                            <ValueFilter
                                items={[
                                    {
                                        title: localization.columns.new.filter(),
                                        value: true
                                    }
                                ]}
                                placeholder={localization.columns.new.filter()}/>
                    }
                }}
                id={Contract.CodeControllerGetIacRiskPageRequestProperty.New}
                key={Contract.CodeControllerGetIacRiskPageRequestProperty.New}
                title={localization.columns.new.filter()}/>
        </DataTable>);
}

type CodeScanRiskFilterItemPageData = {
    count: number;
    itemNextPageSearchCursor?: Contract.ElasticsearchIndexSearchCursor;
};