import { useMemo } from "react";
import { Contract } from "../../../../../../../../../../../../../../../../../common";
import { ConditionType } from "../../../utilities";

export function useTenantTagConditionDefinition(condition: Contract.ProjectConfigurationRuleCondition) {
    const entityNamePatternCondition = condition as Contract.ProjectConfigurationRuleTagPatternTenantCondition;
    return useMemo(
        () => ({
            conditionType: ConditionType.TenantTagPattern,
            not: entityNamePatternCondition.not,
            value: entityNamePatternCondition.tagPattern
        }),
        [entityNamePatternCondition]);
}