import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";
import { useTheme } from "../themes";

export function NvdIcon(props: SvgIconProps) {
    const theme = useTheme();
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <path
                d="M1.22638 9.05525C0.968224 9.12634 0.784894 9.21988 0.567892 9.39946C0.332182 9.59402 0.193749 9.78857 0.088989 10.0841C-0.0120295 10.3573 -0.00828805 10.245 0.0104191 13.4402L0.0179019 15.0977H0.728773H1.43964V12.8191C1.43964 10.5369 1.43964 10.5369 1.51821 10.4807C1.65291 10.3872 1.62672 10.3648 2.65561 11.5021C4.60115 13.6572 5.58889 14.7085 5.78718 14.8358C6.59159 15.3596 7.66163 15.0565 8.09938 14.1773L8.23033 13.9191L8.24156 11.4572L8.25278 8.99913H7.53817H6.8273V11.2739C6.8273 13.5038 6.8273 13.5524 6.75247 13.6198C6.61778 13.7395 6.86471 13.9902 3.85661 10.679C3.34777 10.1141 2.84268 9.57531 2.73418 9.47803C2.28895 9.06648 1.74644 8.91682 1.22638 9.05525Z"
                fill={theme.palette.text.primary}/>
            <path
                d="M8.51074 9.02158C8.51074 9.0328 8.5519 9.11137 8.60054 9.19743C8.64917 9.28348 8.84373 9.65762 9.03454 10.0243C9.22535 10.3947 9.41617 10.7576 9.46106 10.8287C9.5097 10.9035 9.68555 11.2328 9.85766 11.562C10.026 11.8913 10.243 12.2953 10.3366 12.46C10.4301 12.6246 10.6733 13.0773 10.8791 13.4701C11.3505 14.3718 11.4627 14.5364 11.7359 14.7497C12.0688 15.0041 12.2746 15.0752 12.7049 15.0752C13.0304 15.0789 13.0828 15.0677 13.3035 14.9592C13.7936 14.7123 13.8759 14.6113 14.5045 13.4327C14.6804 13.1035 14.9984 12.5123 15.2116 12.1232C15.4212 11.7304 15.6943 11.2178 15.8177 10.9821C15.9412 10.7464 16.0684 10.5219 16.0984 10.4845C16.1507 10.4246 16.3191 10.4209 18.6238 10.4209C20.7489 10.4209 21.1231 10.4284 21.3214 10.4807C21.9125 10.6341 22.3802 11.0756 22.5261 11.6256C22.6346 12.0334 22.571 12.5273 22.369 12.8752C22.2306 13.111 21.8976 13.4028 21.617 13.5375L21.3625 13.6572L19.6452 13.6684C18.1374 13.6797 17.9204 13.6722 17.8419 13.6198L17.7521 13.5637V12.2729V10.9821H17.0412H16.3303V13.0399V15.0977H18.7997C21.5197 15.0977 21.5721 15.0939 22.1221 14.8657C22.8629 14.5589 23.5064 13.9228 23.787 13.2157C24.0676 12.5198 24.0713 11.5807 23.7945 10.896C23.5064 10.1739 22.8666 9.54164 22.1221 9.2311C21.5646 8.99913 21.5534 8.99913 18.4779 8.99913C16.3752 8.99913 15.6456 9.01036 15.5297 9.04403C15.2116 9.14131 15.1892 9.17872 13.9732 11.4498C13.7338 11.9025 13.3783 12.5647 13.1875 12.9201C13.0005 13.2756 12.8096 13.5824 12.7722 13.6048C12.6338 13.6759 12.5627 13.6048 12.3607 13.2157C12.2522 13.0062 11.9865 12.5086 11.7695 12.1157C11.5525 11.7191 11.2719 11.2066 11.1484 10.9746C11.0212 10.7426 10.7294 10.2039 10.4974 9.77361L10.0709 8.99913H9.28896C8.86244 8.99913 8.51074 9.01036 8.51074 9.02158Z"
                fill={theme.palette.text.primary}/>
        </SvgIcon>);
}