import { useLocalization } from "@infrastructure";
import { useMemo } from "react";
import { Contract, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { useAzureTenantEntityStatusTranslator } from "../../../../../../../../../../../../tenants";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetAzureResourceRiskContext } from "./useGetAzureResourceRiskContext";

export function useGetAzureTenantEntityRiskContext() {
    return useMemo(
        () => useAzureTenantEntityRiskContext,
        []);
}

function useAzureTenantEntityRiskContext(tenantEntityModel: Contract.AzureTenantEntityModel) {
    const resourceRiskContext = useGetAzureResourceRiskContext()(tenantEntityModel);
    const tenantEntity = tenantEntityModel.entity as Contract.AzureTenantEntity;

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const tenantEntityStatusTranslator = useAzureTenantEntityStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.contexts.useGetAzureTenantEntityRiskContext",
            () => ({
                path: "The {{translatedTenantEntityTypeName}} path is **{{path}}**",
                status: "The {{translatedTenantEntityTypeName}} status is **{{status}}**"
            }));

    const translatedTenantEntityTypeName =
        entityTypeNameTranslator(
            tenantEntity.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });

    return {
        ...resourceRiskContext,
        path:
            new RiskDefinitionContextItem(
                localization.path({
                    path: `${tenantEntityModel.parentEntityPath}/${tenantEntity.displayName}`,
                    translatedTenantEntityTypeName
                })),
        status:
            new RiskDefinitionContextItem(
                localization.status({
                    status: tenantEntityStatusTranslator(tenantEntity.status),
                    translatedTenantEntityTypeName
                }))
    };
}