import { NumberFormatter, useLocalization } from "@infrastructure";
import { Grid, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { ComplianceHelper, ComplianceSectionIcon, ComplianceSecuredBar, Contract, useTheme } from "../../../../../../../../../../../common";

type DataProps = {
    borderBottom: boolean;
    complianceSectionData: Contract.ComplianceSectionData;
    complianceSectionTitle: string;
};

export function Data({ borderBottom, complianceSectionData, complianceSectionTitle }: DataProps) {
    const theme = useTheme();
    return (
        <Grid
            alignItems="center"
            container={true}
            style={{
                border: theme.border.primary,
                borderRadius:
                    borderBottom
                        ? theme.spacing(0, 0, 0.75, 0.75)
                        : undefined,
                borderTop: "none",
                padding: theme.spacing(2, 0, 2, 2)
            }}>
            <Grid
                item={true}
                sx={{ overflow: "hidden" }}
                xs={true}>
                <Grid
                    alignItems="center"
                    container={true}
                    spacing={1}
                    wrap="nowrap">
                    <Grid item={true}>
                        <ComplianceSectionIcon
                            complianceIdentifier={complianceSectionData.identifier}
                            size={theme.spacing(3)}/>
                    </Grid>
                    <Grid
                        item={true}
                        style={{
                            overflow: "hidden",
                            paddingRight: 10
                        }}
                        xs={true}>
                        <Typography
                            noWrap={true}
                            style={{
                                fontSize: "14px",
                                fontWeight: 500
                            }}>
                            {complianceSectionTitle}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                item={true}
                xs={true}>
                <ComplianceSecuredBar
                    data={complianceSectionData}
                    dense={true}/>
            </Grid>
            <Grid
                item={true}
                sx={{ width: theme.spacing(12) }}>
                <Percentage securedPercentageDelta={ComplianceHelper.getDataSecuredPercentageDelta(complianceSectionData, Contract.TimeFrame.Short)}/>
            </Grid>
            <Grid
                item={true}
                sx={{ width: theme.spacing(12) }}>
                <Percentage securedPercentageDelta={ComplianceHelper.getDataSecuredPercentageDelta(complianceSectionData, Contract.TimeFrame.Medium)}/>
            </Grid>
            <Grid
                item={true}
                sx={{ width: theme.spacing(10) }}>
                <Percentage securedPercentageDelta={ComplianceHelper.getDataSecuredPercentageDelta(complianceSectionData, Contract.TimeFrame.Long)}/>
            </Grid>
        </Grid>);
}

type PercentageProps = {
    securedPercentageDelta?: number;
};

function Percentage({ securedPercentageDelta }: PercentageProps) {
    const localization =
        useLocalization(
            "views.system.exportReport.dashboardReport.complianceContentPages.item.data.percentage",
            () => ({
                empty: "-"
            }));
    const theme = useTheme();
    return (
        <Typography
            style={{
                color:
                    _.isNil(securedPercentageDelta)
                        ? theme.palette.text.secondary
                        : securedPercentageDelta > 0
                            ? theme.palette.success.main
                            : theme.palette.error.main,
                fontSize: "11px",
                fontWeight: "bolder"
            }}>
            {_.isNil(securedPercentageDelta)
                ? localization.empty()
                : NumberFormatter.signedPercentage(securedPercentageDelta)}
        </Typography>);
}