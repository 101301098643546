import React from "react";
import { useLocalization } from "@infrastructure";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { useOciConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { useGetOciIamUserApiKeysRiskContext, useGetOciIamUserRiskContext } from "../contexts";

export function useOciIamUserOrganizationAdministratorApiKeyExistsRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.OciIamUserOrganizationAdministratorApiKeyExistsRisk;
    const userModel = entityModelStore.useGet(risk.entityId) as Contract.OciIamUserModel;

    const getOciIamUserApiKeysRiskContext = useGetOciIamUserApiKeysRiskContext();
    const getOciIamUserRiskContext = useGetOciIamUserRiskContext();

    const consoleSignInStepTranslator = useOciConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.oci.hooks.compliance.useOciIamUserOrganizationAdministratorApiKeyExistsRiskDefinition",
            () => ({
                description: "{{user}} is part of the Administrators group and has API keys",
                sections: {
                    resolution: {
                        step1: "Delete any associated keys from the **API Keys** table"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            user:
                <Entity
                    entityIdOrModel={userModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.OciConsoleView.User,
                userModel.consoleUrl!),
            localization.sections.resolution.step1()
        ],
        riskModel,
        () => {
            const userApiKeysRiskContext = getOciIamUserApiKeysRiskContext(userModel.apiKeyIds);
            const userRiskContext = getOciIamUserRiskContext(userModel);
            return [
                userRiskContext.generalInformation,
                userRiskContext.apiKeys,
                userApiKeysRiskContext.sensitive,
                userRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}