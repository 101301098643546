import { DataTableColumn, DataTableColumnRenderProps, useLocalization } from "@infrastructure";
import React from "react";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, PagedEntityFilter, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";
import { useAwsIamPrincipalDefinition } from "./useAwsIamPrincipalDefinition";

export function useAwsIamIdentityDefinition(definitionData: DefinitionData) {
    const entitiesExportOptions = useEntitiesExportOptions();
    const principalDefinition = useAwsIamPrincipalDefinition(definitionData);
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.aws.useAwsIamIdentityDefinition",
            () => ({
                columns: {
                    permissionBoundaryPolicyId: "Boundary"
                }
            }));
    return {
        columns: {
            ...principalDefinition.columns,
            boundary:
                <DataTableColumn
                    exportOptions={
                        entitiesExportOptions<Contract.AwsIamIdentityModel>(
                            Contract.TypeNames.AwsIamPolicy,
                            item => [(item.entity as Contract.AwsIamIdentity).permissionBoundaryPolicyId],
                            localization.columns.permissionBoundaryPolicyId())}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedEntityFilter
                                    getEntityIdPage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.AwsIamIdentityPermissionBoundary)}
                                    placeholder={localization.columns.permissionBoundaryPolicyId()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsIamIdentityPermissionBoundary}
                    key={Contract.EntityModelProperty.AwsIamIdentityPermissionBoundary}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.AwsIamIdentityModel>) =>
                            <EntitiesCell
                                entityIdsOrModels={(item.entity as Contract.AwsIamIdentity).permissionBoundaryPolicyId}
                                entityTypeName={Contract.TypeNames.AwsIamPolicy}
                                entityVariant="iconText"/>
                    }
                    title={localization.columns.permissionBoundaryPolicyId()}/>
        }
    };
}