import { Action0, DeleteIcon, Dialog, EventHandlerRegister, makeContextProvider, Message, MoveIcon, SelectionActions, SelectionActionsAction, setUrlRoute, SuccessIcon, ToastHelper, UnexpectedError, useEvent, useLocalization, useRoute, useSetRoute } from "@infrastructure";
import { Box, Button, CircularProgress, Stack, Typography } from "@mui/material";
import _, { Dictionary, uniqueId } from "lodash";
import React, { useMemo, useState } from "react";
import { ComplianceData, ComplianceHelper, ConfigurationController, Contract, CustomCompliance, CustomerConsoleAppUrlHelper, riskPolicyModelStore, scopeSystemEntityModelStore, SelectionActionButton, useComplianceTranslator, useScopeNavigationViewContext, useTheme } from "../../../../../../common";
import { ComplianceView, useComplianceContext } from "../../Compliance";
import { MoveDialog, Section } from "./components";

export class CustomComplianceContext {
    constructor(
        public sectionIdToSelectionDataMap: Dictionary<SelectionData>,
        public registerClearSelectedIdsClicked: EventHandlerRegister<Action0>,
        public lastCreatedSectionId?: string) {
    };
}

export const [useCustomComplianceContext, useSetCustomComplianceContext, useCustomComplianceContextProvider] = makeContextProvider<CustomComplianceContext>();

type AddOrEditProps = {
    complianceData: ComplianceData;
    templatePath: string;
};

export function AddOrEdit({ complianceData, templatePath }: AddOrEditProps) {
    const { complianceId, view } = useRoute(`${templatePath}/{view}/{complianceId}`);
    const customComplianceModels = scopeSystemEntityModelStore.useGetCustomCompliance();

    const complianceTranslator = useComplianceTranslator();
    const localization =
        useLocalization(
            "views.customer.compliance.addOrEdit",
            () => ({
                copy: "Copy of {{ title }}"
            }));

    function duplicateComplianceSectionData(section: Contract.ComplianceSectionData, root: boolean): CustomCompliance {
        const { description, title } = complianceTranslator(section.identifier);
        return {
            clientId: uniqueId(),
            description,
            id: undefined,
            name:
                root
                    ? localization.copy({ title })
                    : title,
            riskPolicies:
                _.map(
                    section.riskPolicyDatas,
                    riskPolicyData =>
                        new Contract.ConfigurationControllerUpsertCustomComplianceRequestRiskPolicy(
                            riskPolicyData.analysisGroupType ?? Contract.RiskPolicyTypeMetadataAnalysisGroupType.AllEntities,
                            riskPolicyData.riskPolicyIdentifier)),
            sections:
                _.map(
                    section.sectionDatas,
                    section => duplicateComplianceSectionData(section, false))
        };
    }

    const duplicatedBuiltIn =
        useMemo(
            () =>
                view === ComplianceView.Create &&
                !_.isNil(complianceId) &&
                _.isNil(complianceData.customComplianceSectionDatasMap[complianceId]),
            [complianceData, complianceId, view]);

    const compliance =
        useMemo(
            () => {
                if (view === ComplianceView.Edit) {
                    const customCompliance =
                        _(customComplianceModels).
                            map(customComplianceModel => customComplianceModel.configuration as Contract.CustomComplianceConfiguration).
                            find(customComplianceConfiguration => customComplianceConfiguration.id === complianceId)?.section;

                    if (_.isNil(customCompliance)) {
                        throw new UnexpectedError("Unknown compliance", complianceId);
                    }

                    return customCompliance;
                }

                if (_.isNil(complianceId)) {
                    return undefined;
                }

                const complianceSectionData =
                    complianceData.customComplianceSectionDatasMap[complianceId] ??
                    complianceData.enabledBuiltInComplianceSectionDatasMap[complianceId] ??
                    complianceData.disabledBuiltInComplianceSectionDatasMap[complianceId];

                return duplicateComplianceSectionData(complianceSectionData, true);
            },
            [customComplianceModels, complianceId, view]);

    return (
        <Container
            compliance={compliance}
            duplicatedBuiltIn={duplicatedBuiltIn}/>);
}

type ContainerProps = {
    compliance?: CustomCompliance;
    duplicatedBuiltIn: boolean;
};

function Container({ compliance, duplicatedBuiltIn }: ContainerProps) {
    const { scopeNodeModel } = useScopeNavigationViewContext();
    const { refreshCompliance } = useComplianceContext();
    const [error, setError] = useState(false);
    const [moveDialogOpen, setMoveDialogOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const setRoute = useSetRoute();
    const [registerClearSelectedIdsClicked, triggerClearSelectedIdsClicked] = useEvent<Action0>();

    const [{ sectionIdToSelectionDataMap }, setCustomComplianceContext, ContextProvider] =
        useCustomComplianceContextProvider(
            () => new CustomComplianceContext(
                {},
                registerClearSelectedIdsClicked),
            []);

    const localization =
            useLocalization(
                "views.customer.compliance.addOrEdit.container",
                () => ({
                    actions: {
                        add: "Add Section",
                        cancel: "Cancel",
                        create: "Add",
                        delete: "Remove",
                        move: "Move",
                        save: "Save"
                    },
                    errorMessage: {
                        create: "Failed to create compliance",
                        save: "failed to save compliance"
                    },
                    fields: {
                        description: {
                            label: "Description (optional)"
                        },
                        name: {
                            error: "Name cannot be empty",
                            label: "Name"
                        },
                        policy: "Policies"
                    },
                    newCompliance: "New Compliance",
                    subtitle: "Create a custom compliance framework to categorize sections and policies based on your internal organization standard.",
                    title: {
                        add: "Add Custom Compliance",
                        edit: "Edit Custom Compliance"
                    },
                    toast: {
                        content: {
                            create: "Compliance created successfully",
                            edit: "Compliance saved successfully"
                        },
                        title: "Compliance"
                    }
                }));

    const [customCompliance, setCustomCompliance] =
        useState<CustomCompliance>(
            _.isNil(compliance)
                ? {
                    clientId: uniqueId(),
                    description: undefined,
                    id: undefined,
                    name: localization.newCompliance(),
                    riskPolicies: [],
                    sections: []
                }
                : compliance);

    const valid =
        useMemo(
            () => {
                function sectionHasPolicies(section: CustomCompliance): boolean {
                    return !_.isEmpty(section.riskPolicies) ||
                            _.some(
                                section.sections,
                                section => sectionHasPolicies(section));
                }

                return !_.isEmpty(customCompliance.name) && sectionHasPolicies(customCompliance);
            },
            [customCompliance]);

    const selectedRiskPolicyIds =
        useMemo(
            () =>
                _(sectionIdToSelectionDataMap).
                    values().
                    flatMap(itemSelectionPath => itemSelectionPath.riskPolicyIds).
                    uniq().
                    value(),
            [sectionIdToSelectionDataMap]);

    async function upsertCustomCompliance() {
        setError(false);
        setLoading(true);
        try {
            const { scopeSystemEntityModel } =
                await ConfigurationController.upsertCustomCompliance(
                    new Contract.ConfigurationControllerUpsertCustomComplianceRequest(
                        scopeNodeModel.configuration.id,
                        new Contract.ConfigurationControllerUpsertCustomComplianceRequestSection(
                            customCompliance.description?.trim(),
                            customCompliance?.id,
                            _.trim(customCompliance.name),
                            customCompliance.riskPolicies,
                            customCompliance.sections)));

            await riskPolicyModelStore.notify();
            await scopeSystemEntityModelStore.notify(scopeSystemEntityModel);
            await refreshCompliance?.();

            ToastHelper.toast(
                {
                    content:
                        _.isNil(compliance?.id)
                            ? localization.toast.content.create()
                            : localization.toast.content.edit(),
                    icon: <SuccessIcon/>,
                    title: localization.toast.title()
                },
                { hideProgressBar: true });
            setUrlRoute(
                CustomerConsoleAppUrlHelper.getComplianceRelativeUrl(
                    scopeNodeModel.configuration.id,
                    ComplianceView.Custom));
        } catch {
            setError(true);
        }
        setLoading(false);
    }

    function deleteSelectionData() {
        const updatedCustomCompliance = _.cloneDeep(customCompliance);
        _(sectionIdToSelectionDataMap).
            values().
            forEach(
                selectionData => {
                    let updatedCustomComplianceSection = updatedCustomCompliance;
                    _.forEach(
                        selectionData.sectionPathIds,
                        sectionId => {
                            updatedCustomComplianceSection =
                                _.find(
                                    updatedCustomComplianceSection.sections,
                                    section => ComplianceHelper.getCustomComplianceId(section) === sectionId)!;
                        });

                    updatedCustomComplianceSection.riskPolicies =
                        _.filter(
                            updatedCustomComplianceSection.riskPolicies,
                            riskPolicy =>
                                !_.includes(
                                    selectionData?.riskPolicyIds,
                                    riskPolicy.identifier));
                });

        return updatedCustomCompliance;
    }

    function onMove(targetSectionPath: string[]) {
        const updatedCustomCompliance = deleteSelectionData();
        let updatedCustomComplianceSection = updatedCustomCompliance;
        _.forEach(
            targetSectionPath,
            sectionId => {
                updatedCustomComplianceSection =
                    _.find(
                        updatedCustomComplianceSection.sections,
                        section => ComplianceHelper.getCustomComplianceId(section) === sectionId)!;
            });

        updatedCustomComplianceSection.riskPolicies =
            _(updatedCustomComplianceSection.riskPolicies).
                map(riskPolicy => riskPolicy.identifier).
                concat(selectedRiskPolicyIds).
                map(
                    riskPolicyId =>
                        new Contract.ConfigurationControllerUpsertCustomComplianceRequestRiskPolicy(
                            Contract.RiskPolicyTypeMetadataAnalysisGroupType.AllEntities,
                            riskPolicyId)).
                value();

        setCustomCompliance(updatedCustomCompliance);
        setMoveDialogOpen(false);
        setCustomComplianceContext(
            context => ({
                ...context,
                sectionIdToSelectionDataMap: {}
            }));
    }

    const theme = useTheme();
    return (
        <ContextProvider>
            <Stack
                alignItems="center"
                sx={{
                    height: "100%",
                    overflow: "hidden"
                }}>
                {moveDialogOpen &&
                    <Dialog
                        size="small"
                        variant="editor"
                        onClose={() => setMoveDialogOpen(false)}>
                        <MoveDialog
                            compliance={customCompliance}
                            pathIds={
                                _.size(sectionIdToSelectionDataMap) === 1
                                    ? _.first(_.values(sectionIdToSelectionDataMap))?.sectionPathIds ?? []
                                    : undefined}
                            onSave={onMove}/>
                    </Dialog>}
                <Stack
                    sx={{
                        alignItems: "center",
                        overflow: "auto",
                        scrollbarGutter: "stable",
                        width: "100%"
                    }}>
                    <Stack
                        spacing={3}
                        sx={{
                            padding: theme.spacing(2),
                            width: "50%"
                        }}>
                        <Stack spacing={2}>
                            <Typography variant="h4">
                                {_.isNil(compliance?.id)
                                    ? localization.title.add()
                                    : localization.title.edit()}
                            </Typography>
                            <Typography>
                                {localization.subtitle()}
                            </Typography>
                        </Stack>
                        <Box sx={{ overflow: "auto" }}>
                            <Section
                                path={[]}
                                root={true}
                                section={customCompliance}
                                onSectionChanged={section => setCustomCompliance(section)}/>
                        </Box>
                    </Stack>
                </Stack>
                <Stack
                    alignItems="center"
                    direction="row"
                    spacing={1}
                    sx={{
                        borderTop: theme.border.primary,
                        justifyContent: "flex-end",
                        marginTop: "auto",
                        padding: theme.spacing(2),
                        width: "100%"
                    }}>
                    {loading &&
                        <CircularProgress
                            size={theme.spacing(2)}
                            variant="indeterminate"/>}
                    {error &&
                        <Message
                            level="error"
                            title={
                                _.isNil(compliance?.id)
                                    ? localization.errorMessage.create()
                                    : localization.errorMessage.save()}/>}
                    <Button
                        variant="outlined"
                        onClick={
                            () =>
                                setRoute(
                                    CustomerConsoleAppUrlHelper.getComplianceRelativeUrl(
                                        scopeNodeModel.configuration.id,
                                        duplicatedBuiltIn
                                            ? ComplianceView.BuiltIn
                                            : ComplianceView.Custom))}>
                        {localization.actions.cancel()}
                    </Button>
                    <Button
                        disabled={!valid || loading}
                        variant="contained"
                        onClick={upsertCustomCompliance}>
                        {_.isNil(compliance?.id)
                            ? localization.actions.create()
                            : localization.actions.save()}
                    </Button>
                </Stack>
            </Stack>
            <SelectionActions
                itemIds={selectedRiskPolicyIds}
                loadedItems={selectedRiskPolicyIds}
                onClear={
                    () => {
                        triggerClearSelectedIdsClicked();

                        setCustomComplianceContext(
                            context => ({
                                ...context,
                                sectionIdToSelectionDataMap: {}
                            }));
                    }}>
                <SelectionActionsAction
                    id="Move"
                    key="Move">
                    <SelectionActionButton
                        startIcon={<MoveIcon/>}
                        onClick={() => setMoveDialogOpen(true)}>
                        {localization.actions.move()}
                    </SelectionActionButton>
                </SelectionActionsAction>
                <SelectionActionsAction
                    id="Delete"
                    key="Delete">
                    <SelectionActionButton
                        startIcon={<DeleteIcon/>}
                        onClick={
                            () => {
                                const updatedCustomCompliance = deleteSelectionData();
                                setCustomCompliance(updatedCustomCompliance);
                                setCustomComplianceContext(
                                    context => ({
                                        ...context,
                                        sectionIdToSelectionDataMap: {}
                                    }));
                            }}>
                        {localization.actions.delete()}
                    </SelectionActionButton>
                </SelectionActionsAction>
            </SelectionActions>
        </ContextProvider>);
}

type SelectionData = {
    riskPolicyIds: string[];
    sectionPathIds: string[];
};