﻿import { useLocalization } from "@infrastructure";
import React from "react";
import { useOciCommonIamResourceInfoItemElements, useOciDefaultResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { Contract, InfoItem, TenantEntityParentPathCell } from "../../../../../../../../../../common";
import { Info } from "../../../../components";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";

export function useOciTenantEntityDefinition(resourceModel: Contract.OciResourceModel, options?: DefinitionOptions) {
    const tenantEntityModel = resourceModel as Contract.OciTenantEntityModel;
    const tenantEntity = tenantEntityModel.entity as Contract.OciTenantEntity;
    const commonIamResourceInfoItemElements = useOciCommonIamResourceInfoItemElements(tenantEntityModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useOciDefaultResourceInfoItemElements(resourceModel);

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.oci.useOciTenantEntityDefinition",
            () => ({
                info: {
                    items: {
                        parentTenantEntityPath: "Compartment Path"
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    commonIamResourceInfoItemElements.enabledInfoItemElement,
                    commonIamResourceInfoItemElements.descriptionInfoItemElement,
                    tenantEntity.root
                        ? undefined
                        : <InfoItem
                            key="parentTenantEntityPath"
                            title={localization.info.items.parentTenantEntityPath()}
                            value={
                                <TenantEntityParentPathCell
                                    parentEntityId={tenantEntity.parentTenantEntityId}
                                    parentEntityPath={tenantEntityModel.parentEntityPath!}/>}/>
                ]}
                options={options?.infoOptions}>
            </Info>,
        sensitive: false
    });
}