﻿import { Action1 } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React, { useEffect, useMemo } from "react";
import { Contract } from "../../../../../controllers";
import { useAccessContext } from "../../../Access";
import { AccessGraphHelper, AccessGraphPermissionEdgeData, EntitiesPermissionActionsEntity } from "../../../utilities";
import { Entities } from "./Entities";

type SourceEntitiesProps = {
    entityIdToSearchableReferenceMap: Dictionary<Contract.EntitySearchableReference>;
    excessivePermissionsEnabled: boolean;
    onSelectedSourceEntityChanged: Action1<string>;
    permissionEdgeIdToDataMap: Dictionary<AccessGraphPermissionEdgeData>;
    resourceServiceModelMap: Dictionary<Contract.EntityModel>;
    selectedSourceEntityId?: string;
};

export function SourceEntities({ entityIdToSearchableReferenceMap, excessivePermissionsEnabled, onSelectedSourceEntityChanged, permissionEdgeIdToDataMap, resourceServiceModelMap, selectedSourceEntityId }: SourceEntitiesProps) {
    const { definition } = useAccessContext();

    const sourceEntities =
        useMemo(
            () => {
                const sourceEntityIdToEntityMap: Dictionary<EntitiesPermissionActionsEntity> = {};
                for (const permissionEdgeData of _.values(permissionEdgeIdToDataMap)) {
                    for (const sourceEntityId of permissionEdgeData.sourceEntityIds) {
                        let entity = sourceEntityIdToEntityMap[sourceEntityId];
                        if (_.isNil(entity)) {
                            entity = new EntitiesPermissionActionsEntity(sourceEntityId, 0, 0);
                            sourceEntityIdToEntityMap[sourceEntityId] = entity;
                        }

                        entity.excessivePermissionActionCount += permissionEdgeData.excessivePermissionActionCount * permissionEdgeData.destinationEntityIds.size;
                        entity.nonexcessivePermissionActionCount += permissionEdgeData.nonexcessivePermissionActionCount * permissionEdgeData.destinationEntityIds.size;
                    }
                }

                return AccessGraphHelper.orderEntities(
                    _.values(sourceEntityIdToEntityMap),
                    entityIdToSearchableReferenceMap,
                    resourceServiceModelMap);
            },
            []);

    useEffect(
        () => {
            onSelectedSourceEntityChanged(sourceEntities[0].entityId);
        },
        []);

    return (
        <Entities
            entities={sourceEntities}
            entityTypeName={definition.entitiesPermissionActions.entityTypeNames.sourceEntityTypeName}
            excessivePermissionsEnabled={excessivePermissionsEnabled}
            selectedEntityId={selectedSourceEntityId}
            onSelectedEntityChanged={entity => onSelectedSourceEntityChanged(entity.entityId)}/>);
}