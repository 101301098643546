import { DataTableColumn, DataTableColumnRenderProps, EnumValuesFilter, PagedValuesFilter, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import { Dictionary } from "lodash";
import React from "react";
import { useOciCommonResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions, useEntityTypeNameTranslator } from "../../../../../../../../../../common";
import { useOciNetworkingServiceGatewayStatusTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";

export function useOciNetworkingServiceGatewayDefinition(definitionData: DefinitionData) {
    const commonResourceDefinition = useOciCommonResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const serviceGatewayStatusTranslator = useOciNetworkingServiceGatewayStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.oci.useOciNetworkingServiceGatewayDefinition",
            () => ({
                columns: {
                    blockTraffic: {
                        false: "Disabled",
                        title: "Block Traffic",
                        true: "Enabled"
                    },
                    serviceName: "Services",
                    status: "State"
                }
            }));

    return new EntityTableDefinition(
        [
            commonResourceDefinition.columns.tenantColumn,
            commonResourceDefinition.columns.creationTimeColumn,
            commonResourceDefinition.columns.creatorCsvColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.OciNetworkingServiceGatewayModel) => ({
                            [localization.columns.status()]: serviceGatewayStatusTranslator((item.entity as Contract.OciNetworkingServiceGateway).status)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.OciNetworkingServiceGatewayStatus}
                                enumTypeTranslator={serviceGatewayStatusTranslator}
                                placeholder={localization.columns.status()}/>
                    }
                }}
                id={Contract.EntityModelProperty.OciNetworkingServiceGatewayStatus}
                itemProperty={(item: Contract.OciNetworkingServiceGatewayModel) => serviceGatewayStatusTranslator((item.entity as Contract.OciNetworkingServiceGateway).status)}
                key={Contract.EntityModelProperty.OciNetworkingServiceGatewayStatus}
                title={localization.columns.status()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.OciNetworkingServiceGatewayModel) => ({
                            [localization.columns.blockTraffic.title()]:
                                (item.entity as Contract.OciNetworkingServiceGateway).blockTraffic
                                    ? localization.columns.blockTraffic.true()
                                    : localization.columns.blockTraffic.false()
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        default: true,
                        element:
                            <ValuesFilter placeholder={localization.columns.blockTraffic.title()}>
                                <ValuesFilterItem
                                    title={localization.columns.blockTraffic.false()}
                                    value={false}/>
                                <ValuesFilterItem
                                    title={localization.columns.blockTraffic.true()}
                                    value={true}/>
                            </ValuesFilter>
                    }
                }}
                id={Contract.EntityModelProperty.OciNetworkingServiceGatewayBlockTraffic}
                itemProperty={
                    (item: Contract.EntityModel) =>
                        (item.entity as Contract.OciNetworkingServiceGateway).blockTraffic
                            ? localization.columns.blockTraffic.true()
                            : localization.columns.blockTraffic.false()}
                key={Contract.EntityModelProperty.OciNetworkingServiceGatewayBlockTraffic}
                title={localization.columns.blockTraffic.title()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.OciNetworkingServiceGatewayModel) => ({
                            [localization.columns.serviceName()]: (item.entity as Contract.OciNetworkingServiceGateway).serviceName
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedValuesFilter
                                getValuePage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.OciNetworkingServiceGatewayServiceName)}
                                placeholder={localization.columns.serviceName()}/>
                    }
                }}
                id={Contract.EntityModelProperty.OciNetworkingServiceGatewayServiceName}
                itemProperty={(item: Contract.OciNetworkingServiceGatewayModel) => (item.entity as Contract.OciNetworkingServiceGateway).serviceName}
                key={Contract.EntityModelProperty.OciNetworkingServiceGatewayServiceName}
                title={localization.columns.serviceName()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.OciNetworkingServiceGatewayModel>(
                        Contract.TypeNames.OciNetworkingVcn,
                        item => [item.vcnIdReference],
                        entityTypeNameTranslator(Contract.TypeNames.OciNetworkingVcn))}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.OciNetworkingServiceGatewayVcn)}
                                placeholder={entityTypeNameTranslator(Contract.TypeNames.OciNetworkingVcn)}/>
                    }
                }}
                id={Contract.EntityModelProperty.OciNetworkingServiceGatewayVcn}
                key={Contract.EntityModelProperty.OciNetworkingServiceGatewayVcn}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.OciNetworkingServiceGatewayModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.vcnIdReference}
                            entityTypeName={Contract.TypeNames.OciNetworkingVcn}/>}
                title={entityTypeNameTranslator(Contract.TypeNames.OciNetworkingVcn)}/>,
            commonResourceDefinition.columns.regionColumn,
            commonResourceDefinition.columns.regionLocationColumn,
            commonResourceDefinition.columns.tagsColumn,
            commonResourceDefinition.columns.attributesColumn,
            commonResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageOciNetworkingServiceGatewayRequest(
                new Contract.EntityControllerGetEntityModelPageOciNetworkingServiceGatewayRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciNetworkingServiceGatewayBlockTraffic]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciNetworkingServiceGatewayServiceName]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciNetworkingServiceGatewayStatus]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciNetworkingServiceGatewayVcn])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags]))));
}