import type { editor } from "monaco-editor/esm/vs/editor/editor.api";

export const MAX_HEIGHT = 800;

export const commonOptions : editor.IEditorOptions = {
    lineDecorationsWidth: 28,
    lineHeight: 24,
    minimap: {
        enabled: false
    },
    overviewRulerBorder: false,
    readOnly: true,
    scrollbar: {
        alwaysConsumeMouseWheel: false,
        horizontalScrollbarSize: 8,
        verticalScrollbarSize: 8
    },
    scrollBeyondLastLine: false
};