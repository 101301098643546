import { Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Link, useLocalization } from "@infrastructure";
import { Contract, CustomerConsoleAppUrlHelper, InlineEntities, useActivityTranslator, useTheme, useVendorNameTranslator, VendorIcon } from "../../../../../../../../..";
import { SeverityItem } from "../../../components";

interface VendorStatusCardProps {
    vendorData: Contract.AccessControllerGetDashboardSummaryResponseAccessSummaryVendorData;
}

export function VendorItem({ vendorData }: VendorStatusCardProps) {
    const activityTranslator = useActivityTranslator();
    const vendorNameTranslator = useVendorNameTranslator();

    const localization =
        useLocalization(
            "common.dashboard.widget.hooks.useDefinition.hooks.useGetVendorsDefinition.vendorItem",
            () => ({
                activityTime: "Activity Time {{ activityName }}"
            }));

    const theme = useTheme();
    return (
        <Link
            urlOrGetUrl={CustomerConsoleAppUrlHelper.getPrincipalPermissionsRelativeUrl({ vendors: [vendorData.vendor] })}
            variant="text">
            <SeverityItem
                className="vendorItem"
                icon={
                    <VendorIcon
                        sx={{ fontSize: "22px" }}
                        vendorType={vendorData.vendor}/>
                }
                severity={vendorData.permissionSeverity}
                subTitles={[
                    <InlineEntities
                        entityIdsOrModels={vendorData.serviceIdentityIds}
                        entityTypeName={vendorData.serviceIdentityTypeName}
                        key={`${vendorData.vendor}-${vendorData.serviceIdentityTypeName}-serviceIdentityIds`}
                        variant="itemCountAndType"/>,
                    <InlineEntities
                        entityIdsOrModels={vendorData.permissionTenantIds}
                        entityTypeName={vendorData.permissionTenantEntityTypeName}
                        key={`${vendorData.vendor}-${vendorData.serviceIdentityTypeName}-permissionTenantIds`}
                        variant="itemCountAndType"/>,
                    _.isNil(vendorData.activity)
                        ? undefined
                        : <Typography
                            key="activity"
                            sx={{ color: theme.palette.text.secondary }}>
                            {localization.activityTime({ activityName: activityTranslator(vendorData.activity) })}
                        </Typography>
                ]}
                title={
                    <Typography
                        noWrap={true}
                        sx={{ fontWeight: 500 }}>
                        {vendorNameTranslator(vendorData.vendor)}
                    </Typography>}/>
        </Link>);
}