import { DataTableColumn, DataTableSortType, DeferredFilter, optionalTableCell, TimeFormatter, TimeRangeFilter, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, ScopeTenantFilter, tenantModelStore, TimeRangeHelper } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";
import { TenantCell, useCommonEntityDefinition } from "../useCommonEntityDefinition";

export function useGcpCommonEntityDefinition(definitionData: DefinitionData) {
    const entityModelFiltersPromise = definitionData.entityModelFiltersPromise as Promise<Contract.GcpEntityModelFilters>;
    const commonEntityDefinition = useCommonEntityDefinition(definitionData);

    const gcpTenantModelMap = tenantModelStore.useGetGcpTenantMap();
    const gcpTenantIds =
        _.map(
            gcpTenantModelMap,
            gcpTenantModel => gcpTenantModel.configuration.id);

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.gcp.useGcpCommonEntityDefinition",
            () => ({
                columns: {
                    creationTime: "Creation Time",
                    gcpTenant: "Account"
                }
            }));
    return {
        columns:
            {
                ...commonEntityDefinition.columns,
                creationTimeColumn:
                    <DataTableColumn
                        exportOptions={{
                            getItem:
                                (item: Contract.GcpEntityModel) => ({
                                    [localization.columns.creationTime()]:
                                        _.isNil(item.creationTime)
                                            ? ""
                                            : TimeFormatter.iso8601String(item.creationTime)
                                })
                        }}
                        filterOptions={{
                            itemOrItems: {
                                element:
                                    <DeferredFilter
                                        promiseOrGetPromise={entityModelFiltersPromise}
                                        title={localization.columns.creationTime()}>
                                        {filters =>
                                            <TimeRangeFilter
                                                emptyValue={filters.creationTimeRange.emptyValue}
                                                placeholder={localization.columns.creationTime()}
                                                timeRange={TimeRangeHelper.getTimeRangeFilterRange(filters.creationTimeRange.timeRange)}/>}
                                    </DeferredFilter>
                            }
                        }}
                        id={Contract.EntityModelProperty.ResourceCreationTime}
                        key={Contract.EntityModelProperty.ResourceCreationTime}
                        render={
                            optionalTableCell<Contract.GcpEntityModel>(
                                item =>
                                    _.isNil(item.creationTime)
                                        ? undefined
                                        : TimeFormatter.monthDayAndYear(item.creationTime))}
                        sortOptions={{ type: DataTableSortType.Date }}
                        title={localization.columns.creationTime()}/>,
                gcpTenantColumn:
                    <DataTableColumn
                        filterOptions={{
                            itemOrItems: {
                                default: true,
                                element:
                                    <DeferredFilter
                                        promiseOrGetPromise={definitionData.entityModelFiltersPromise}
                                        title={localization.columns.gcpTenant()}>
                                        {filters =>
                                            <ScopeTenantFilter
                                                placeholder={localization.columns.gcpTenant()}
                                                tenantIds={
                                                    _.intersection(
                                                        filters.tenantIdItems.items,
                                                        gcpTenantIds)}/>
                                        }
                                    </DeferredFilter>
                            }
                        }}
                        id={Contract.EntityModelProperty.TenantId}
                        key={Contract.EntityModelProperty.TenantId}
                        render={TenantCell}
                        sortOptions={{ enabled: false }}
                        title={localization.columns.gcpTenant()}/>
            }
    };
}