import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useGcpComputeDiskTypeTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useGcpComputeDiskTypeTranslator",
            () => ({
                [Contract.TypeNames.GcpComputeDiskType]: {
                    [Contract.GcpComputeDiskType.HddStandard]: "Standard Persistent Disk",
                    [Contract.GcpComputeDiskType.HyperBalanced]: "Hyperdisk Balanced",
                    [Contract.GcpComputeDiskType.HyperBalancedHighAvailability]: "Hyperdisk Balanced High Availability",
                    [Contract.GcpComputeDiskType.HyperExtreme]: "Hyperdisk Extreme",
                    [Contract.GcpComputeDiskType.HyperThroughput]: "Hyperdisk Throughput",
                    [Contract.GcpComputeDiskType.Ssd]: "Performance Persistent Disk (SSD)",
                    [Contract.GcpComputeDiskType.SsdBalanced]: "Balanced Persistent Disk (SSD)",
                    [Contract.GcpComputeDiskType.SsdEphemeral]: "Local SSD",
                    [Contract.GcpComputeDiskType.SsdExtreme]: "Extreme Persistent Disk (SSD)"
                }
            }));
    return (type: Contract.GcpComputeDiskType) =>
        localization[Contract.TypeNames.GcpComputeDiskType][type]();
}