﻿import React from "react";

export function SgIcon() {
    return (
        <svg
            fill="none"
            height={50}
            viewBox="0 0 50 50"
            width={50}>
            <path
                d="M49.5417 24.7708C49.5417 31.3405 46.9319 37.641 42.2865 42.2865C37.641 46.9319 31.3405 49.5417 24.7708 49.5417C18.2012 49.5417 11.9006 46.9319 7.25521 42.2865C2.60978 37.641 9.91987e-07 31.3405 0 24.7708L49.5417 24.7708Z"
                fill="#F0F0F0"/>
            <path
                d="M0 24.7708C5.74336e-07 18.2012 2.60978 11.9006 7.25521 7.25521C11.9006 2.60978 18.2012 -7.83421e-08 24.7708 0C31.3405 7.83421e-08 37.641 2.60978 42.2865 7.25521C46.9319 11.9006 49.5417 18.2012 49.5417 24.7708L0 24.7708Z"
                fill="#D80027"/>
            <path
                d="M18.6198 7.61636L19.0721 7.70297L18.6546 7.83288C16.8191 8.37853 15.175 9.80759 14.3748 11.5398C13.8963 12.5704 13.8093 13.0122 13.818 14.4152C13.818 15.5758 13.8354 15.723 14.0094 16.286C14.6879 18.4945 16.245 20.1142 18.3936 20.8503C19.1243 21.1015 19.1156 21.1188 18.1501 21.2314C15.3229 21.5432 12.4871 19.9323 11.2432 17.2907C10.8256 16.3813 10.6778 15.801 10.643 14.7444C10.5908 13.6357 10.6865 12.9948 11.017 12.0854C11.452 10.8642 12.3218 9.68633 13.3831 8.88952C14.166 8.30057 15.5752 7.72029 16.5582 7.58171C17.0105 7.50377 18.1935 7.52975 18.6198 7.61636Z"
                fill="white"/>
            <path
                d="M21.5558 9.35469L21.7265 9.92523H23.1098L22.5529 10.3202L22.0049 10.7064L22.2115 11.3121C22.3283 11.6369 22.4002 11.909 22.3732 11.909C22.3463 11.909 22.0948 11.751 21.8253 11.5579L21.3222 11.2068L21.0976 11.356C20.9719 11.4438 20.7473 11.6018 20.5946 11.7159C20.4419 11.83 20.3071 11.909 20.2802 11.8826C20.2443 11.8475 20.4149 11.277 20.5946 10.7942C20.6126 10.7503 20.388 10.5397 20.0916 10.3378L19.5615 9.96034L20.882 9.92523L21.0707 9.31958C21.1785 8.9948 21.2953 8.74025 21.3222 8.74903C21.3582 8.76659 21.457 9.04747 21.5558 9.35469Z"
                fill="white"/>
            <path
                d="M17.7592 12.0241L17.9546 12.6177L19.1945 12.6526L18.7104 13.0017C18.4386 13.1938 18.2178 13.3771 18.2178 13.4033C18.2178 13.4295 18.2943 13.7 18.3877 14.0056C18.4726 14.3023 18.5491 14.5642 18.5321 14.5729C18.5151 14.5817 18.2858 14.4333 18.0225 14.2325L17.5469 13.8746L17.0544 14.2325C16.7911 14.4333 16.5533 14.5904 16.5363 14.5817C16.5193 14.5729 16.5873 14.3111 16.6807 14.023C16.7741 13.735 16.842 13.4556 16.842 13.4207C16.842 13.3771 16.6297 13.185 16.3665 13.0017L15.8909 12.6526L17.1393 12.5828L17.3176 12.0067C17.4111 11.6837 17.5045 11.4218 17.5214 11.4218C17.5554 11.4218 17.6573 11.6924 17.7592 12.0241Z"
                fill="white"/>
            <path
                d="M26.1359 12.6005L26.7805 12.6182L26.286 12.972C26.0212 13.1666 25.7916 13.3347 25.7827 13.3436C25.7739 13.3524 25.8357 13.6178 25.9505 13.9451C26.0476 14.2724 26.1183 14.5555 26.1006 14.5643C26.083 14.582 25.8445 14.4316 25.5708 14.2282L25.0764 13.8655L24.5819 14.2193C24.3082 14.4228 24.0698 14.582 24.0433 14.582C24.0256 14.582 24.0874 14.3432 24.1757 14.0424C24.264 13.7416 24.3435 13.4586 24.3435 13.4055C24.3435 13.3524 24.1227 13.1578 23.849 12.9632L23.3546 12.6093L24.6437 12.5386L24.8115 12.0432C24.8998 11.769 24.9969 11.4948 25.0145 11.4328C25.041 11.3709 25.147 11.5744 25.2706 11.9459L25.4737 12.5651L26.1359 12.6005Z"
                fill="white"/>
            <path
                d="M19.2432 16.4898L19.4153 17.0143L20.6627 17.0819L20.1638 17.4372L19.6734 17.7925L19.8713 18.3762C19.9831 18.6976 20.0519 18.9598 20.0347 18.9598C20.0089 18.9598 19.7766 18.7991 19.5099 18.6215C18.9765 18.2408 19.0282 18.2324 18.4173 18.6807C18.2109 18.8414 18.0302 18.9429 18.013 18.9345C17.9958 18.9176 18.0646 18.6553 18.1678 18.3508C18.2625 18.0378 18.3313 17.7756 18.3313 17.7671C18.3141 17.7587 18.099 17.6064 17.8409 17.4203L17.3591 17.0819L18.598 17.0481L18.7959 16.4644C18.8991 16.143 19.0109 15.8977 19.0281 15.923C19.0453 15.9484 19.14 16.2022 19.2432 16.4898Z"
                fill="white"/>
            <path
                d="M23.8759 16.4983L24.0533 17.0227L25.3122 17.0566L24.8306 17.4034C24.5687 17.598 24.3491 17.8094 24.3491 17.8686C24.3491 17.9279 24.4251 18.207 24.5096 18.4692C24.6025 18.7399 24.6617 18.9598 24.6363 18.9598C24.6194 18.9598 24.3913 18.7991 24.1294 18.613C23.8337 18.3931 23.6309 18.2831 23.5802 18.317C23.5295 18.3508 23.3098 18.5031 23.0901 18.6638C22.8705 18.8245 22.6761 18.9429 22.6592 18.926C22.6423 18.9091 22.7099 18.6384 22.8113 18.3339C22.9211 18.0124 22.9718 17.7587 22.938 17.7333C22.9042 17.7164 22.6761 17.5557 22.4396 17.3865L22.0086 17.082L23.2422 17.0481L23.4197 16.4645C23.521 16.1346 23.614 15.8977 23.6478 15.9231C23.6816 15.9569 23.783 16.2107 23.8759 16.4983Z"
                fill="white"/>
        </svg>);
}