import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function TeamsIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                d="M23.9998 11.2V16C23.9998 17.9884 22.3882 19.6 20.3998 19.6C18.4114 19.6 16.7998 17.9884 16.7998 16V10H22.7998C23.4628 10 23.9998 10.537 23.9998 11.2ZM20.3998 7.60005C21.7252 7.60005 22.7998 6.52545 22.7998 5.20005C22.7998 3.87465 21.7252 2.80005 20.3998 2.80005C19.0744 2.80005 17.9998 3.87465 17.9998 5.20005C17.9998 6.52545 19.0744 7.60005 20.3998 7.60005Z"
                fill="#5059C9"/>
            <path
                d="M18.6001 11.2V17.8C18.6001 21.2458 15.6955 24.0136 12.2005 23.7868C9.0115 23.5804 6.6001 20.7898 6.6001 17.5942V10H17.4001C18.0631 10 18.6001 10.537 18.6001 11.2ZM12.6001 8.2C14.5885 8.2 16.2001 6.5884 16.2001 4.6C16.2001 2.6116 14.5885 1 12.6001 1C10.6117 1 9.0001 2.6116 9.0001 4.6C9.0001 6.5884 10.6117 8.2 12.6001 8.2Z"
                fill="#7B83EB"/>
            <path
                d="M12.6 8.2C14.5882 8.2 16.2 6.58822 16.2 4.6C16.2 2.61177 14.5882 1 12.6 1C10.6118 1 9 2.61177 9 4.6C9 6.58822 10.6118 8.2 12.6 8.2Z"
                fill="#7B83EB"/>
            <path
                d="M13.2001 17.9914V10H6.6001V17.5942C6.6001 18.5146 6.8059 19.399 7.1665 20.2H10.9909C12.2113 20.2 13.2001 19.2112 13.2001 17.9914Z"
                fill="black"
                opacity="0.05"/>
            <path
                d="M6.6001 10V17.5942C6.6001 18.2902 6.7207 18.9646 6.9325 19.6H10.9273C11.9617 19.6 12.7999 18.7618 12.7999 17.7274V10H6.6001Z"
                fill="black"
                opacity="0.07"/>
            <path
                d="M12.4003 10H6.6001V17.5942C6.6001 18.0754 6.6607 18.5446 6.7645 19H10.8637C11.7121 19 12.3997 18.3124 12.3997 17.464L12.4003 10Z"
                fill="black"
                opacity="0.09"/>
            <path
                d="M10.8 18.3999H1.2C0.537 18.3999 0 17.8629 0 17.1999V7.5999C0 6.9369 0.537 6.3999 1.2 6.3999H10.8C11.463 6.3999 12 6.9369 12 7.5999V17.1999C12 17.8629 11.463 18.3999 10.8 18.3999Z"
                fill="url(#paint0_linear_57262_5040)"/>
            <path
                d="M8.44068 9.39941H3.55908V10.4314H5.38728V15.3994H6.61248V10.4314H8.44068V9.39941Z"
                fill="white"/>
            <defs>
                <linearGradient
                    gradientUnits="userSpaceOnUse"
                    id="paint0_linear_57262_5040"
                    x1="0.3888"
                    x2="11.6418"
                    y1="6.78919"
                    y2="18.0422">
                    <stop stopColor="#5961C3"/>
                    <stop
                        offset="1"
                        stopColor="#3A41AC"/>
                </linearGradient>
            </defs>
        </SvgIcon>);

}