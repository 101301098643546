import { InlineItems, map, TimeSpanHelper, useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import React from "react";
import { AuditEventDefinitionDetailItem } from "../../..";
import { Contract } from "../../../../../../../../../../../common";

export function useGetScopeSettingUpdateAuditEventDetailsItems() {
    function getTimeFrameChangeDetailsItem(
        changeTimeFrame: string,
        title: string) {
        return new AuditEventDefinitionDetailItem(
            title,
            localization.days(
                TimeSpanHelper.getDays(changeTimeFrame),
                {
                    count: TimeSpanHelper.getDays(changeTimeFrame)
                }));
    }

    const localization =
        useLocalization(
            "views.customer.auditEvents.profile.hooks.useDefinition.hooks.useScopeSettingAuditEventDefinition.hooks.useGetScopeSettingUpdateAuditEventDetailsItems",
            () => ({
                aadSeverePermissionDirectoryRoleDefinitions: {
                    added: "Added role definitions",
                    removed: "Deleted role definitions",
                    roleDefinition: {
                        name: "Name",
                        templateId: "Template Id"
                    },
                    roleDefinitions: [
                        "{{count | NumberFormatter.humanize}} privileged role definition",
                        "{{count | NumberFormatter.humanize}} privileged role definitions"
                    ]
                },
                days: [
                    "{{count | NumberFormatter.humanize}} day",
                    "{{count | NumberFormatter.humanize}} days"
                ],
                serviceIdentityExcessivePermissionEvaluationTimeFrame: {
                    previous: "Old learning period",
                    updated: "New learning period"
                },
                userExcessivePermissionEvaluationTimeFrame: {
                    previous: "Old learning period",
                    updated: "New learning period"
                }
            }));

    return (data: Contract.ScopeSettingUpdateAuditEventData) =>
        map(
            data.typeName,
            {
                [Contract.TypeNames.AadSeverePermissionDirectoryRoleDefinitionsUpdateAuditEventData]:
                    () => {
                        const updateAuditEventData = data as Contract.AadSeverePermissionDirectoryRoleDefinitionsUpdateAuditEventData;
                        return [
                            new AuditEventDefinitionDetailItem(
                                localization.aadSeverePermissionDirectoryRoleDefinitions.added(),
                                localization.aadSeverePermissionDirectoryRoleDefinitions.roleDefinitions(
                                    updateAuditEventData.addedDirectoryRoleDefinitionDatas.length,
                                    {
                                        count: updateAuditEventData.addedDirectoryRoleDefinitionDatas.length
                                    }),
                                <InlineItems
                                    items={updateAuditEventData.addedDirectoryRoleDefinitionDatas}
                                    variant="itemPlusItemCount">
                                    {directoryRoleDefinitionData =>
                                        <Typography component="span">
                                            {`${directoryRoleDefinitionData.name}: ${directoryRoleDefinitionData.templateId}`}
                                        </Typography>}
                                </InlineItems>),
                            new AuditEventDefinitionDetailItem(
                                localization.aadSeverePermissionDirectoryRoleDefinitions.removed(),
                                localization.aadSeverePermissionDirectoryRoleDefinitions.roleDefinitions(
                                    updateAuditEventData.removedDirectoryRoleDefinitionDatas.length,
                                    {
                                        count: updateAuditEventData.removedDirectoryRoleDefinitionDatas.length
                                    }),
                                <InlineItems
                                    items={updateAuditEventData.removedDirectoryRoleDefinitionDatas}
                                    variant="itemPlusItemCount">
                                    {directoryRoleDefinitionData =>
                                        <Typography component="span">
                                            {`${directoryRoleDefinitionData.name}: ${directoryRoleDefinitionData.templateId}`}
                                        </Typography>}
                                </InlineItems>)];
                    },
                [Contract.TypeNames.ServiceIdentityExcessivePermissionEvaluationTimeFrameUpdateAuditEventData]:
                    () => [
                        getTimeFrameChangeDetailsItem(
                            (data as Contract.ServiceIdentityExcessivePermissionEvaluationTimeFrameUpdateAuditEventData).previousTimeFrame,
                            localization.serviceIdentityExcessivePermissionEvaluationTimeFrame.previous()),
                        getTimeFrameChangeDetailsItem(
                            (data as Contract.ServiceIdentityExcessivePermissionEvaluationTimeFrameUpdateAuditEventData).timeFrame,
                            localization.serviceIdentityExcessivePermissionEvaluationTimeFrame.updated())],
                [Contract.TypeNames.UserExcessivePermissionEvaluationTimeFrameUpdateAuditEventData]:
                    () => [
                        getTimeFrameChangeDetailsItem(
                            (data as Contract.UserExcessivePermissionEvaluationTimeFrameUpdateAuditEventData).previousTimeFrame,
                            localization.userExcessivePermissionEvaluationTimeFrame.previous()),
                        getTimeFrameChangeDetailsItem(
                            (data as Contract.UserExcessivePermissionEvaluationTimeFrameUpdateAuditEventData).timeFrame,
                            localization.userExcessivePermissionEvaluationTimeFrame.updated())]
            });
}