﻿import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";
import { useTheme } from "../../../themes";

export function GlobalIcon(props: SvgIconProps) {
    const theme = useTheme();
    return (
        <SvgIcon {...props}>
            <path
                d="M10.7773 10.4173C10.7097 10.1863 10.6164 9.97956 10.4974 9.79705C10.3811 9.61169 10.2404 9.45342 10.0754 9.32224C9.91314 9.19106 9.72651 9.09268 9.51554 9.02709C9.30457 8.95865 9.07467 8.92443 8.82583 8.92443C8.37956 8.92443 7.98196 9.04278 7.63305 9.27947C7.28414 9.51616 7.00961 9.86407 6.80946 10.3232C6.61202 10.7795 6.51329 11.3356 6.51329 11.9914C6.51329 12.653 6.61202 13.2134 6.80946 13.6725C7.00691 14.1317 7.28144 14.481 7.63305 14.7205C7.98467 14.9572 8.39308 15.0756 8.85829 15.0756C9.28023 15.0756 9.64537 14.99 9.95371 14.8189C10.2647 14.6478 10.5041 14.4054 10.6718 14.0917C10.8395 13.7752 10.9234 13.4045 10.9234 12.9796L11.2641 13.0352H9.0084V11.7947H12.3798V12.847C12.3798 13.597 12.2284 14.2457 11.9255 14.7933C11.6225 15.3408 11.206 15.7628 10.6759 16.0594C10.1457 16.3531 9.53718 16.5 8.85018 16.5C8.08474 16.5 7.41262 16.3189 6.8338 15.9567C6.2577 15.5917 5.80736 15.0741 5.48279 14.404C5.16093 13.731 5 12.9325 5 12.0086C5 11.3013 5.09467 10.6697 5.284 10.1136C5.47603 9.55751 5.7438 9.08555 6.0873 8.69772C6.4308 8.30703 6.8338 8.01046 7.29631 7.80798C7.75882 7.60266 8.2619 7.5 8.80555 7.5C9.26535 7.5 9.69405 7.57129 10.0916 7.71388C10.4892 7.85361 10.8422 8.05323 11.1505 8.31274C11.4616 8.57224 11.7172 8.88023 11.9173 9.23669C12.1175 9.59316 12.2487 9.98669 12.3109 10.4173H10.7773Z"
                fill={theme.palette.text.primary}/>
            <path
                d="M13.8353 16.3802V7.61977H15.3405V15.0499H19V16.3802H13.8353Z"
                fill={theme.palette.text.primary}/>
            <path
                clipRule="evenodd"
                d="M22 5.00227H2C1.44772 5.00227 1 5.451 1 6.00453V17.9955C1 18.549 1.44772 18.9977 2 18.9977H22C22.5523 18.9977 23 18.549 23 17.9955V6.00454C23 5.451 22.5523 5.00227 22 5.00227ZM2 4H22C23.1046 4 24 4.89746 24 6.00454V17.9955C24 19.1025 23.1046 20 22 20H2C0.89543 20 0 19.1025 0 17.9955V6.00453C0 4.89746 0.895431 4 2 4Z"
                fill={theme.palette.text.secondary}
                fillRule="evenodd"/>
        </SvgIcon>);
}