import { useLocalization } from "@infrastructure";
import { Contract } from "../../../..";
import { useGetPermissionEligibilityUpdateAuditEventCommonChanges } from "../useGetPermissionEligibilityUpdateAuditEventCommonChanges";

export function useGetPermissionAssignmentEligibilityUpdateAuditEventCommonChanges() {
    const getPermissionEligibilityUpdateAuditEventCommonChanges = useGetPermissionEligibilityUpdateAuditEventCommonChanges();
    const localization =
        useLocalization(
            "common.hooks.usePermissionEligibilityUpdateAuditEventChangesTranslator.hooks.useGetPermissionAssignmentEligibilityUpdateAuditEventChanges.useGetPermissionAssignmentEligibilityUpdateAuditEventCommonChanges",
            () => ({
                permissionRequestScopes: "Request Scopes"
            }));

    return (permissionEligibilityUpdateAuditEventPermissionEligibilityChanges: Contract.PermissionEligibilityUpdateAuditEventPermissionAssignmentEligibilityChanges) => ({
        ...getPermissionEligibilityUpdateAuditEventCommonChanges(permissionEligibilityUpdateAuditEventPermissionEligibilityChanges),
        permissionRequestScopes:
            permissionEligibilityUpdateAuditEventPermissionEligibilityChanges.permissionRequestScopesChanged
                ? localization.permissionRequestScopes()
                : undefined!
    });
}