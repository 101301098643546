﻿import { DataTableColumn, optionalTableCell, useLocalization } from "@infrastructure";
import React from "react";
import { Contract, EntitiesCell, EntityFilter, ItemSelectionHelper } from "../../../../../../../../../common";
import { TableColumnId } from "../../../Table";
import { TenantDefinition } from "../useDefinition";
import { useCommonColumns } from "./useCommonColumns";

export function useAzureDefinition(filters: Contract.PermissionManagementControllerPermissionEligibilityFilters): TenantDefinition {
    const azureFilters = filters as Contract.PermissionManagementControllerAzurePermissionEligibilityFilters;
    const localization =
        useLocalization(
            "views.user.permissionEligibilities.table.hooks.useDefinition.hooks.useAzureDefinition",
            () => ({
                columns: {
                    roleDefinitionIds: "Permissions"
                }
            }));
    const commonColumns = useCommonColumns();
    return new TenantDefinition(
        (commonFilters, filterMap, limit, scope, skip, sort) =>
            new Contract.PermissionManagementControllerGetAzurePermissionEligibilityModelPageRequest(
                new Contract.PermissionManagementControllerGetAzurePermissionEligibilityModelPageRequestFilters(
                    commonFilters.approvalRequired,
                    commonFilters.approverPrincipalIdSelection,
                    commonFilters.granteePrincipalIdSelection,
                    commonFilters.nameSelection,
                    commonFilters.principalTenantIdSelection,
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[TableColumnId.AzureRoleDefinitionIds])),
                limit,
                scope,
                skip,
                sort),
        getEntitiesExportOptions =>
            [
                <DataTableColumn
                    exportOptions={
                        getEntitiesExportOptions(
                            item => (item as Contract.AzureRoleAssignmentEligibilityModel).roleDefinitionIdReferences,
                            localization.columns.roleDefinitionIds())}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <EntityFilter
                                    entityIdsOrSearchableReferences={azureFilters.roleDefinitionSearchableReferences}
                                    placeholder={localization.columns.roleDefinitionIds()}/>
                        }
                    }}
                    id={TableColumnId.AzureRoleDefinitionIds}
                    key={TableColumnId.AzureRoleDefinitionIds}
                    render={
                        optionalTableCell<Contract.PermissionEligibilityModel>(
                            item =>
                                <EntitiesCell
                                    entityIdsOrModels={(item as Contract.AzureRoleAssignmentEligibilityModel).roleDefinitionIdReferences}
                                    entityLinkOptions={{ disabled: true }}
                                    entityTypeName={Contract.TypeNames.AzureAuthorizationRoleDefinition}
                                    entityVariant="iconText"/>)}
                    sortOptions={{ enabled: false }}
                    title={localization.columns.roleDefinitionIds()}/>,
                commonColumns.permissionRequestScopeIds
            ]);
}