import { Shadows, useSetWizardContext, useWizardContext } from "@infrastructure";
import { Avatar, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useMemo } from "react";
import { useSetAddContext } from "..";
import { TenantHelper, TenantIcon, tenantModelStore, useTenantTypeRenderer, useTenantTypeTranslator, useTheme } from "../../../../../../../../../../../../../common";

export function TenantTypeItem() {
    const { next, setLoaded } = useWizardContext();
    const setAddContext = useSetAddContext();
    const tenantTypeRenderer = useTenantTypeRenderer();
    const setWizardContext = useSetWizardContext();
    const filteredActiveTenantTypes = tenantModelStore.useGetFilteredActiveTenantTypes();
    useEffect(
        () => {
            setLoaded();
            setWizardContext(
                wizardContext => ({
                    ...wizardContext,
                    showBack: false,
                    showFooter: false,
                    showNext: false
                }));
        },
        []);

    const filteredCustomerRisksTenantTypes =
        useMemo(
            () => _.intersection(TenantHelper.CustomRisksTenantTypes, filteredActiveTenantTypes),
            [filteredActiveTenantTypes]);

    const tenantTypeTranslator = useTenantTypeTranslator();

    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            direction="row"
            justifyContent="center"
            sx={{ height: "100%" }}>
            {tenantTypeRenderer(
                filteredCustomerRisksTenantTypes,
                configuredRenderTenantTypes =>
                    _.map(
                        configuredRenderTenantTypes,
                        tenantType =>
                            <Stack
                                key={tenantType}
                                spacing={1.5}
                                sx={{
                                    "&:hover": {
                                        boxShadow: theme.shadows[Shadows.Card]
                                    },
                                    alignItems: "center",
                                    backgroundColor: theme.palette.background.alternate,
                                    border: theme.border.primary,
                                    borderRadius: theme.spacing(0.75),
                                    cursor: "pointer",
                                    height: theme.spacing(22),
                                    justifyContent: "center",
                                    margin: theme.spacing(0, 2),
                                    width: theme.spacing(22)
                                }}
                                onClick={
                                    () => {
                                        setAddContext(
                                            addOrEditContext => ({
                                                ...addOrEditContext,
                                                tenantType
                                            }));
                                        next();
                                    }}>
                                <Avatar
                                    sx={{
                                        fontSize: "30px",
                                        height: theme.spacing(8),
                                        width: theme.spacing(8)
                                    }}>
                                    <TenantIcon tenantType={tenantType}/>
                                </Avatar>
                                <Typography sx={{ fontSize: "16px" }}>
                                    {tenantTypeTranslator(tenantType)}
                                </Typography>
                            </Stack>))}
        </Stack>);
}