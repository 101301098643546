import { Stack, Typography } from "@mui/material";
import { Dictionary } from "lodash";
import React, { useMemo, useState } from "react";
import { ItemSelector, Loading, map, useChangeEffect, useLocalization } from "@infrastructure";
import { useStatusTranslator } from "../../../../..";
import { Filters } from "../../../..";
import { Contract, PagedResourceTagKeySelector, ReportItem, RiskType, useSelectedScopeId } from "../../../../../../../../../common";
import { useGetGenerateReportRequestDefinition } from "./useGetGenerateReportRequestDefinition";

export type ItemOptions = {
    additionalColumnResourceTagKeys?: string[];
    filtersMap?: Dictionary<any>;
    reportType: Contract.ReportType;
    riskType: RiskType;
    scopeId?: string;
    status?: Contract.RiskStatus;
};

export function useGetItem({ additionalColumnResourceTagKeys: initialAdditionalColumnResourceTagKeys, filtersMap: initialFiltersMap, riskType, scopeId, status: initialStatus }: ItemOptions) {
    const [additionalColumnResourceTagKeys, setAdditionalColumnResourceTagKeys] = useState(initialAdditionalColumnResourceTagKeys);
    const [filtersMap, setFiltersMap] = useState(initialFiltersMap);
    const [status, setStatus] = useState(initialStatus ?? Contract.RiskStatus.Open);
    const generateRiskReportRequestDefinition =
        useGetGenerateReportRequestDefinition(
            riskType,
            status,
            additionalColumnResourceTagKeys,
            filtersMap);
    const { selectedScopeId } = useSelectedScopeId();

    const statusTranslator = useStatusTranslator();
    const risksView =
        useMemo(
            () =>
                map(
                    status,
                    {
                        [Contract.RiskStatus.Closed]: () => Contract.RisksView.Closed,
                        [Contract.RiskStatus.Ignored]: () => Contract.RisksView.Ignored,
                        [Contract.RiskStatus.Open]: () => Contract.RisksView.Open
                    }),
            [status]);

    const localization =
        useLocalization(
            "views.customer.risks.report.hooks.useItems.hooks.useGetItem",
            () => ({
                additionalColumnResourceTagKeys: {
                    placeholder: "Resource Tag Keys",
                    pluralizer: [
                        "1 resource tag key",
                        "{{count | NumberFormatter.humanize}} resource tag keys"
                    ],
                    title: "Additional resource tag column"
                },
                filters: "Filters",
                status: "Status"
            }));

    useChangeEffect(
        () => setFiltersMap({}),
        [status]);

    return (): ReportItem => ({
        configuration:
            <Stack spacing={2}>
                <Typography variant="h4">
                    {localization.filters()}
                </Typography>
                <Stack
                    alignItems="center"
                    direction="row"
                    spacing={1}>
                    <Typography
                        sx={{ fontWeight: 400 }}
                        variant="h5">
                        {localization.status()}
                    </Typography>
                    <ItemSelector
                        fieldVariant="underline"
                        getItemText={status => statusTranslator(status)}
                        items={[
                            Contract.RiskStatus.Open,
                            Contract.RiskStatus.Ignored,
                            Contract.RiskStatus.Closed
                        ]}
                        selectedItem={status}
                        sorted={false}
                        onSelectedItemChanged={selectedStatus => setStatus(selectedStatus)}>
                        {item => (
                            <Stack
                                alignItems="center"
                                direction="row"
                                spacing={1}>
                                <Typography>
                                    {statusTranslator(item)}
                                </Typography>
                            </Stack>)}
                    </ItemSelector>
                </Stack>
                <Loading>
                    <Filters
                        initialFilterMap={filtersMap}
                        key={riskType}
                        risksView={risksView}
                        riskType={riskType}
                        onFilterChanged={setFiltersMap}/>
                </Loading>
                {riskType === RiskType.Cloud &&
                    <Stack
                        alignItems="center"
                        direction="row"
                        spacing={1}>
                        <Typography
                            sx={{ fontWeight: 400 }}
                            variant="h5">
                            {localization.additionalColumnResourceTagKeys.title()}
                        </Typography>
                        <PagedResourceTagKeySelector
                            multiSelect={true}
                            placeholder={localization.additionalColumnResourceTagKeys.placeholder()}
                            scopeId={scopeId ?? selectedScopeId}
                            selectedResourceTagKeys={additionalColumnResourceTagKeys ?? []}
                            onSelectedResourceTagKeyChanged={setAdditionalColumnResourceTagKeys}/>

                    </Stack>}
            </Stack>,
        generate: generateRiskReportRequestDefinition
    });
}