import { useLocalization } from "@infrastructure";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../../..";
import { useCustomEntityPropertyInfoItemElements } from "../..";
import { useAzureCommonComputeVirtualMachineResourceInfoItemElements, useAzureCommonManagedIdentityOriginatorResourceInfoElements, useAzureCommonNetworkingInfoItemElements, useAzureDefaultResourceInfoItemElements } from "..";
import { Contract, InfoItem, VerticalTopItemsInfoItem } from "../../../../../../../../../../../common";
import { useAzureComputeVirtualMachineScaleSetTypeTranslator } from "../../../../../../../hooks";
import { EntitiesInfoItem, Info } from "../../../../../components";
import { AzureResourceRoleAssignmentResources, NetworkingInfoCard } from "../../../components";
import { VirtualMachinesInfoCard } from "./components";

export function useAzureComputeVirtualMachineScaleSetDefinition(resourceGroupResourceModel: Contract.AzureResourceGroupResourceModel, options?: DefinitionOptions) {
    const commonComputeVirtualMachineResourceInfoItemElements = useAzureCommonComputeVirtualMachineResourceInfoItemElements(resourceGroupResourceModel as Contract.AzureComputeVirtualMachineResourceModel);
    const commonManagedIdentityOriginatorResourceInfoElements = useAzureCommonManagedIdentityOriginatorResourceInfoElements(resourceGroupResourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceGroupResourceModel);
    const defaultResourceInfoItemElements = useAzureDefaultResourceInfoItemElements(resourceGroupResourceModel);
    const virtualMachineScaleSet = resourceGroupResourceModel.entity as Contract.AzureComputeVirtualMachineScaleSet;
    const virtualMachineScaleSetModel = resourceGroupResourceModel as Contract.AzureComputeVirtualMachineScaleSetModel;
    const networkInfoItems = useAzureCommonNetworkingInfoItemElements({ resourceModel: resourceGroupResourceModel });

    const computeVirtualMachineScaleSetTypeTranslator = useAzureComputeVirtualMachineScaleSetTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.azure.useAzureComputeVirtualMachineScaleSetDefinition",
            () => ({
                info: {
                    items: {
                        agentPoolId: "Cluster Agent Pool",
                        availabilityZones: "Availability Zones",
                        type: "Orchestration Mode"
                    }
                },
                tabs: {
                    resourceRoleAssignments: "Resource Role Assignments"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <AzureResourceRoleAssignmentResources
                    csvExportFilePrefix={localization.tabs.resourceRoleAssignments()}
                    scopeResourceModel={resourceGroupResourceModel}/>,
                localization.tabs.resourceRoleAssignments(),
                "resourceRoleAssignments")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    commonComputeVirtualMachineResourceInfoItemElements.rawIdInfoItemElement,
                    commonComputeVirtualMachineResourceInfoItemElements.rawTypeInfoItemElement,
                    commonComputeVirtualMachineResourceInfoItemElements.imageInfoInfoItemElement,
                    ...commonManagedIdentityOriginatorResourceInfoElements,
                    <VerticalTopItemsInfoItem
                        items={virtualMachineScaleSet.availabilityZones}
                        key="availabilityZones"
                        title={localization.info.items.availabilityZones()}/>,
                    <InfoItem
                        key="type"
                        title={localization.info.items.type()}
                        value={computeVirtualMachineScaleSetTypeTranslator(virtualMachineScaleSet.type)}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={virtualMachineScaleSetModel.agentPoolId}
                        entityTypeName={Contract.TypeNames.AzureContainerServiceManagedClusterAgentPool}
                        key="agentPoolId"
                        title={localization.info.items.agentPoolId()}/>
                ]}
                options={options?.infoOptions}>
                {virtualMachineScaleSet.type === Contract.AzureComputeVirtualMachineScaleSetType.VirtualMachineScaleSetVirtualMachine &&
                    <NetworkingInfoCard>
                        {networkInfoItems.inboundAccessType}
                        {networkInfoItems.inboundExternalAccessScope}
                        {networkInfoItems.getVirtualNetwork()}
                        {networkInfoItems.getSubnets()}
                        {networkInfoItems.getSubnetNetworkSecurityGroupIds()}
                        {networkInfoItems.getInterfaceNetworkSecurityGroups()}
                        {commonComputeVirtualMachineResourceInfoItemElements.loadBalancersInfoItemElement}
                        {commonComputeVirtualMachineResourceInfoItemElements.applicationGatewaysInfoItemElement}
                    </NetworkingInfoCard>}
                <VirtualMachinesInfoCard virtualMachineScaleSetModel={virtualMachineScaleSetModel}/>
            </Info>
    });
}