import { map } from "@infrastructure";
import { Contract, tenantModelStore } from "../../../../../../../../../../../../../../../common";
import { GcpConsoleUrlBuilder } from "../../../../../../../../../../../../../../../tenants";
import { EntityExternalConsoleLinkDefinition } from "../useDefinition";

export function useGcpBigQueryDatasetDefinition(entityModel: Contract.EntityModel, page: Contract.GcpConsolePage) {
    const tenantModel = tenantModelStore.useGet(entityModel.entity.tenantId);
    const tenantConfiguration = tenantModel.configuration as Contract.GcpTenantConfiguration;

    const getUrl =
        map(
            page,
            {
                [Contract.GcpConsolePage.Permissions]: () => undefined,
                [Contract.GcpConsolePage.Resource]: () => GcpConsoleUrlBuilder.GetBigQueryDatasetUrl
            });

    return new EntityExternalConsoleLinkDefinition(
        getUrl?.(
            (entityModel.entity as Contract.GcpBigQueryDataset).name,
            tenantConfiguration.rawShortNameId));
}