import { DataTableColumnRenderProps, Message, SuccessIcon, useLocalization } from "@infrastructure";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { Fragment } from "react";
import { useTheme } from "../../..";
import { SummaryItemItem } from "./SummaryItem";

export function StatusCell({ item }: DataTableColumnRenderProps<SummaryItemItem>) {
    const localization =
        useLocalization(
            "common.riskResolutionAutomation.statusCell",
            () => ({
                error: "Failed to execute",
                outdated: "The server was updated, refresh the page and try again"
            }));
    const theme = useTheme();
    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "flex-end"
            }}>
            {item.executing
                ? <CircularProgress size="18px"/>
                : <Fragment>
                    {item.result === "success" && (
                        <SuccessIcon
                            sx={{
                                color: theme.palette.success.main,
                                fontSize: "18px"
                            }}/>)}
                    {item.result === "error" && (
                        <Stack
                            direction="row"
                            spacing={1}
                            sx={{
                                color: theme.palette.error.main,
                                fontSize: "18px"
                            }}>
                            {!_.isNil(item.resolveRiskAutomationResponse) && (
                                <Typography>
                                    {item.resolveRiskAutomationResponse.errorCode}
                                </Typography>)}
                            <Message
                                level="error"
                                title={
                                    _.isNil(item.resolveRiskAutomationResponse?.errorMessage)
                                        ? localization.error()
                                        : item.resolveRiskAutomationResponse!.errorMessage}
                                variant="minimal"/>
                        </Stack>)}
                    {item.result === "outdated" && (
                        <Message
                            level="error"
                            title={localization.outdated()}
                            variant="minimal"/>)}
                </Fragment>}
        </Box>);
}