import { Optional, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, SpecificCustomRiskPolicyProps } from "../../../..";
import { Edit, View } from "./components";

export function AwsS3BucketManagementRiskPolicy({ editOptions, riskPolicyConfiguration, sx }: SpecificCustomRiskPolicyProps) {
    const localization =
        useLocalization(
            "common.customRiskPolicy.awsS3BucketManagementRiskPolicy",
            () => ({
                template: "Monitor policy, ACL, and Block Public Access setting changes in {{buckets}}"
            }));
    return _.isNil(editOptions)
        ? <View
            riskPolicyConfiguration={riskPolicyConfiguration as Optional<Contract.AwsS3BucketManagementRiskPolicyConfiguration>}
            sx={sx}
            templateTranslator={localization.template}/>
        : <Edit
            actionsRef={editOptions.actionsRef}
            riskPolicyConfiguration={riskPolicyConfiguration as Optional<Contract.AwsS3BucketManagementRiskPolicyConfiguration>}
            templateTranslator={localization.template}
            onValidChange={editOptions.onValidChange}/>;
}