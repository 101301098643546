import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, optionalTableCell, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import { Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, Entity, EntityAttributeFilter, EntityAttributesCell, EntityFilter, entityModelStore, EntityTypeFilter, EntityTypeMetadataModelHelper, InlineEntities, InlineResourceTags, Region, ResourceTagHelper, riskPolicyModelStore, TypeHelper, useTableDefinition } from "../../../../../../../../../../../../common";
import { InlineEntitySelection } from "../../../../../../../../../../../../common/components/CustomRiskPolicy/components";
import { AwsResource } from "../../../../../../../../../../../../common/controllers/types.generated";
import { Table } from "../../../../components";
import { useCommonCustomSectionsAndDescriptionDefinition } from "../../../useCommonCustomSectionsAndDescriptionDefinition";

export function useAwsEncryptedResourceKmsEncryptionNotExistsRiskDefinition(riskModel: Contract.RiskModel) {
    const riskPolicyModel = riskPolicyModelStore.useGet(riskModel.risk.policyId);
    const riskPolicyConfiguration = riskPolicyModel.riskPolicyConfiguration as Contract.AwsEncryptedResourceKmsEncryptionNotExistsRiskPolicyConfiguration;
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.custom.useAwsEncryptedResourceKmsEncryptionNotExistsRiskDefinition",
            () => ({
                anyCustomerManagedKey: "any customer managed encryption {{keyTranslatedTypeName}}",
                anyKey: "any {{keyTranslatedTypeName}}",
                keyAttributes: [
                    "{{keyTranslatedTypeName}} with 1 label",
                    "{{keyTranslatedTypeName}} with {{count | NumberFormatter.humanize}} labels"
                ],
                keyTags: [
                    "{{keyTranslatedTypeName}} with 1 tag",
                    "{{keyTranslatedTypeName}} with {{count | NumberFormatter.humanize}} tags"
                ],
                violations: "{{encryptedResources}} should be encrypted using {{keys}}"
            }));

    return useCommonCustomSectionsAndDescriptionDefinition(
        localization.violations({
            encryptedResources:
                <InlineEntities
                    entityIdsOrModels={riskModel.risk.riskedEntityIds}
                    entityTypeName={Contract.TypeNames.IAwsEncryptedResource}
                    variant="itemAndTypeOrItemCountAndType"/>,
            keys:
                <InlineEntitySelection
                    entityTypeName={Contract.TypeNames.AwsKmsKey}
                    selection={{
                        anyCustomerManagedKmsKey: riskPolicyConfiguration?.anyCustomerManagedKmsKey,
                        anyEntity: riskPolicyConfiguration?.anyKmsKey,
                        entityBuiltInAttributeTypeNames: riskPolicyConfiguration?.kmsKeyBuiltInAttributeTypeNames,
                        entityCustomAttributeDefinitionIds: riskPolicyConfiguration?.kmsKeyCustomAttributeDefinitionIds,
                        entityIds: riskPolicyConfiguration?.kmsKeyIds,
                        entityTags: riskPolicyConfiguration?.kmsKeyTags
                    }}/>
        }),
        riskModel,
        "violations",
        <ViolationTable risk={riskModel.risk}/>);
}

type ViolationTableProps = {
    risk: Contract.AwsEncryptedResourceKmsEncryptionNotExistsRisk;
};

function ViolationTable({ risk }: ViolationTableProps) {
    const kmsEncryptionNotExistsEncryptedResourceModels = entityModelStore.useGet(risk.riskedEntityIds);
    const kmsEncryptionNotExistsEncryptedResourceKeyIds =
        useMemo(
            () =>
                _.flatMap(
                    kmsEncryptionNotExistsEncryptedResourceModels,
                    kmsEncryptionNotExistsEncryptedResourceModel =>_.as<Contract.IAwsEncryptedResourceModel>(kmsEncryptionNotExistsEncryptedResourceModel).kmsEncryptionKeyIdReferences),
            [kmsEncryptionNotExistsEncryptedResourceModels]);

    const kmsEncryptionNotExistsEncryptedResourceKeyIdToModelMap =
        _.keyBy(
            entityModelStore.useGet(kmsEncryptionNotExistsEncryptedResourceKeyIds),
            keyModel => keyModel.id);

    const items =
        useMemo(
            () => {
                const kmsEncryptionNotExistsEncryptedResourceModelMap =
                    _.keyBy(
                        kmsEncryptionNotExistsEncryptedResourceModels,
                        kmsEncryptionNotExistsEncryptedResourceModel => kmsEncryptionNotExistsEncryptedResourceModel.id);

                return _.map(
                    risk.items,
                    kmsEncryptionNotExistsEncryptedResourceItem =>
                        new ViolationTableItem(
                            kmsEncryptionNotExistsEncryptedResourceItem as Contract.RiskItem,
                            kmsEncryptionNotExistsEncryptedResourceModelMap[kmsEncryptionNotExistsEncryptedResourceItem.entityId]));
            },
            [kmsEncryptionNotExistsEncryptedResourceModels, risk]);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.custom.useAwsEncryptedResourceKmsEncryptionNotExistsRiskDefinition.violationTable",
            () => ({
                columns: {
                    attributes: "Labels",
                    encryption: "Encryption",
                    region: "Region",
                    resource: "Resource",
                    tags: "Tags",
                    typeName: "Resource Type"
                },
                encryptionType: {
                    None: "None",
                    Other: "Other"
                }
            }));
    const tableDefinition =
        useTableDefinition(
            items,
            {
                [ViolationTableColumnId.Attributes]: {
                    getFilterValue:
                        item =>
                            _.map(
                                item.kmsEncryptionNotExistsEncryptedResourceModel.attributes.attributes,
                                entityAttribute =>
                                    TypeHelper.extendOrImplement(entityAttribute.typeName, Contract.TypeNames.CustomEntityAttribute)
                                        ? (entityAttribute as Contract.CustomEntityAttribute).definitionId
                                        : entityAttribute.typeName),
                    getSortValue: item => item.kmsEncryptionNotExistsEncryptedResourceModel.attributes.attributes.length
                },
                [ViolationTableColumnId.Encryption]: {
                    getFilterValue:
                        item =>
                            !_.isEmpty(_.as<Contract.IAwsEncryptedResourceModel>(item.kmsEncryptionNotExistsEncryptedResourceModel).kmsEncryptionKeyIdReferences)
                                ? _.map(
                                    _.as<Contract.IAwsEncryptedResourceModel>(item.kmsEncryptionNotExistsEncryptedResourceModel).kmsEncryptionKeyIdReferences,
                                    keyIdReference => kmsEncryptionNotExistsEncryptedResourceKeyIdToModelMap[keyIdReference].entity.displayName)
                                : (_.as<Contract.IAwsEncryptedResource>(item.kmsEncryptionNotExistsEncryptedResourceModel.entity)).encryptionExists
                                    ? [localization.encryptionType.Other()]
                                    : [localization.encryptionType.None()],
                    getSortValue: item => _.as<Contract.IAwsEncryptedResourceModel>(item.kmsEncryptionNotExistsEncryptedResourceModel).kmsEncryptionKeyIdReferences.length
                },
                [ViolationTableColumnId.Region]: {
                    getFilterValue: item => item.kmsEncryptionNotExistsEncryptedResourceModel.entity.regionId,
                    getSortValue: item => item.kmsEncryptionNotExistsEncryptedResourceModel.entity.regionId ?? ""
                },
                [ViolationTableColumnId.Resource]: {
                    getFilterValue: item => item.riskItem.entityId,
                    getSortValue: item => item.kmsEncryptionNotExistsEncryptedResourceModel.entity.displayName.toLowerCase()
                },
                [ViolationTableColumnId.Tags]: {
                    getFilterValue:
                        item =>
                            _.map(
                                ResourceTagHelper.getDatas((item.kmsEncryptionNotExistsEncryptedResourceModel.entity as AwsResource).tags),
                                resourceTagData => resourceTagData.id),
                    getSortValue: item => (item.kmsEncryptionNotExistsEncryptedResourceModel.entity as AwsResource).tags.length
                },
                [ViolationTableColumnId.TypeName]: {
                    getFilterValue: item => EntityTypeMetadataModelHelper.get(item.kmsEncryptionNotExistsEncryptedResourceModel.entity.typeName).entitiesViewName,
                    getSortValue: item => EntityTypeMetadataModelHelper.get(item.kmsEncryptionNotExistsEncryptedResourceModel.entity.typeName).
                        entitiesViewName.
                        toLowerCase()
                }
            },
            ViolationTableColumnId.Resource);

    return (
        <Table
            fetchItems={tableDefinition.filterAndSortItems}
            getItemId={(item: ViolationTableItem) => item.kmsEncryptionNotExistsEncryptedResourceModel.id}
            sortEnabled={true}>
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EntityFilter
                                entityIdsOrSearchableReferences={tableDefinition.columnIdToItemValuesMap[ViolationTableColumnId.Resource]}
                                placeholder={localization.columns.resource()}/>
                    }
                }}
                id={ViolationTableColumnId.Resource}
                render={
                    ({ item }: DataTableColumnRenderProps<ViolationTableItem>) =>
                        <Entity
                            entityIdOrModel={item.kmsEncryptionNotExistsEncryptedResourceModel}
                            variant="iconTextTypeTenant"/>}
                title={localization.columns.resource()}/>
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EntityTypeFilter
                                entityTypeNames={tableDefinition.columnIdToItemValuesMap[ViolationTableColumnId.TypeName]}
                                placeholder={localization.columns.typeName()}/>
                    }
                }}
                id={ViolationTableColumnId.TypeName}
                title={localization.columns.typeName()}/>
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        element:
                            <ValuesFilter placeholder={localization.columns.encryption()}>
                                {_.map(
                                    tableDefinition.columnIdToItemValuesMap[ViolationTableColumnId.Encryption],
                                    (encryption: string) =>
                                        <ValuesFilterItem
                                            key={encryption}
                                            title={encryption}
                                            value={encryption}/>)}
                            </ValuesFilter>
                    }
                }}
                id={ViolationTableColumnId.Encryption}
                render={
                    optionalTableCell<ViolationTableItem>(({ kmsEncryptionNotExistsEncryptedResourceModel }) =>
                        !_.isEmpty(_.as<Contract.IAwsEncryptedResourceModel>(kmsEncryptionNotExistsEncryptedResourceModel).kmsEncryptionKeyIdReferences)
                            ? <InlineEntities
                                entityIdsOrModels={_.as<Contract.IAwsEncryptedResourceModel>(kmsEncryptionNotExistsEncryptedResourceModel).kmsEncryptionKeyIdReferences}
                                entityTypeName={Contract.TypeNames.AwsKmsKey}
                                variant="itemOrItemCountAndType"/>
                            : _.as<Contract.IAwsEncryptedResource>(kmsEncryptionNotExistsEncryptedResourceModel.entity).encryptionExists
                                ? localization.encryptionType.Other()
                                : localization.encryptionType.None()
                    )}
                title={localization.columns.encryption()}/>
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        element:
                            <ValuesFilter placeholder={localization.columns.region()}>
                                {_.map(
                                    tableDefinition.columnIdToItemValuesMap[ViolationTableColumnId.Region],
                                    regionId =>
                                        <ValuesFilterItem
                                            key={regionId}
                                            title={regionId}
                                            value={regionId}>
                                            {() =>
                                                <Typography noWrap={true}>
                                                    <Region
                                                        regionId={regionId}
                                                        variant="iconText"/>
                                                </Typography>}
                                        </ValuesFilterItem>)}
                            </ValuesFilter>
                    }
                }}
                id={ViolationTableColumnId.Region}
                render={
                    ({ item }: DataTableColumnRenderProps<ViolationTableItem>) =>
                        <Region regionId={item.kmsEncryptionNotExistsEncryptedResourceModel.entity.regionId}/>}
                title={localization.columns.region()}/>
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        element:
                            <ValuesFilter placeholder={localization.columns.tags()}>
                                {_.map(
                                    tableDefinition.columnIdToItemValuesMap[ViolationTableColumnId.Tags],
                                    (tagId: string) =>
                                        <ValuesFilterItem
                                            key={tagId}
                                            title={ResourceTagHelper.getDisplayName(tagId)}
                                            value={tagId}/>)}
                            </ValuesFilter>
                    }
                }}
                id={ViolationTableColumnId.Tags}
                render={
                    optionalTableCell<ViolationTableItem>(
                        ({ kmsEncryptionNotExistsEncryptedResourceModel }) =>
                            _.isEmpty((kmsEncryptionNotExistsEncryptedResourceModel.entity as AwsResource).tags)
                                ? undefined
                                : <InlineResourceTags tags={(kmsEncryptionNotExistsEncryptedResourceModel.entity as AwsResource).tags}/>)}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.tags()}/>
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EntityAttributeFilter
                                emptyValue={true}
                                entityAttributeValues={tableDefinition.columnIdToItemValuesMap[ViolationTableColumnId.Attributes]}
                                placeholder={localization.columns.attributes()}/>
                    }
                }}
                id={ViolationTableColumnId.Attributes}
                render={
                    ({ item }: DataTableColumnRenderProps<ViolationTableItem>) =>
                        <EntityAttributesCell
                            entityAttributes={item.kmsEncryptionNotExistsEncryptedResourceModel.attributes.attributes}
                            entityTypeName={item.kmsEncryptionNotExistsEncryptedResourceModel.entity.typeName}/>}
                title={localization.columns.attributes()}/>
        </Table>);
}

class ViolationTableItem {
    constructor(
        public riskItem: Contract.RiskItem,
        public kmsEncryptionNotExistsEncryptedResourceModel: Contract.EntityModel) {
    }
}

enum ViolationTableColumnId {
    Attributes = "attributes",
    Encryption = "encryption",
    Region = "region",
    Resource = "resource",
    Tags = "tags",
    TypeName = "typeName"
}