import { AnalyticsEventActionType, useTrackAnalyticsEvent } from "@infrastructure";
import { Portal } from "@mui/material";
import _ from "lodash";
import React, { Fragment, memo } from "react";
import { useDefinition } from "../hooks";
import { SideViewItem } from "../SideView";

type ViewItemProps = {
    actionsContainer: HTMLDivElement | null;
    item: SideViewItem;
};

const ViewItemMemo = memo(ViewItem);
export { ViewItemMemo as ViewItem };

function ViewItem({ actionsContainer, item }: ViewItemProps) {
    const definition = useDefinition(item);
    useTrackAnalyticsEvent(AnalyticsEventActionType.SideViewOpen);
    return (
        <Fragment>
            {!_.isNil(actionsContainer) &&
                <Portal container={actionsContainer}>
                    {definition.getActionsElement()}
                </Portal>}
            {definition.getViewElement()}
        </Fragment>);
}