﻿import React from "react";
import { useOciCommonIamCredentialInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { Contract } from "../../../../../../../../../../common";
import { Info } from "../../../../components";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";

export function useOciIamUserAuthTokenDefinition(resourceModel: Contract.OciResourceModel, options?: DefinitionOptions) {
    const userAuthTokenModel = resourceModel as Contract.OciIamUserAuthTokenModel;

    const commonIamCredentialInfoItemElements = useOciCommonIamCredentialInfoItemElements(userAuthTokenModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={
                    [
                        commonIamCredentialInfoItemElements.typeNameInfoElement,
                        commonIamCredentialInfoItemElements.organizationInfoItemElement,
                        commonIamCredentialInfoItemElements.tenantInfoItemElement,
                        commonIamCredentialInfoItemElements.ocidInfoItemElement,
                        commonIamCredentialInfoItemElements.creationTimeInfoItemElement,
                        commonIamCredentialInfoItemElements.createdByInfoItemElement,
                        commonIamCredentialInfoItemElements.syncTimeInfoItemElement
                    ]
                }
                entityPropertyInfoItemElements={[
                    commonIamCredentialInfoItemElements.userIdInfoItemElement
                ]}
                options={options?.infoOptions}>
            </Info>
    });
}