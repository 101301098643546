import { Tooltip, useLocalization, useSetRoute } from "@infrastructure";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import _ from "lodash";
import React from "react";
import { ClosedIcon, OpenIcon } from "../../../../../../common";
import { VulnerabilitiesView } from "./Vulnerabilities";

type TabsProps = {
    baseUrl: string;
    onChange: (newView: VulnerabilitiesView) => void;
    selectedView: VulnerabilitiesView;
};

export function Tabs({ baseUrl, onChange, selectedView }: TabsProps) {
    const localization =
        useLocalization(
            "views.customer.workloadAnalysis.vulnerabilities.tabs",
            () => ({
                tooltip: {
                    open: "Open",
                    resolved: "Resolved"
                }
            }));
    const setRoute = useSetRoute();
    return (
        <ToggleButtonGroup
            exclusive={true}
            value={selectedView}
            onChange={
                (_event, view) => {
                    if (!_.isNil(view)) {
                        onChange(view);
                        setRoute(`${baseUrl}/${view}`);
                    }
                }}>
            <Tooltip titleOrGetTitle={localization.tooltip.open()}>
                <ToggleButton value={VulnerabilitiesView.Open}>
                    <OpenIcon/>
                </ToggleButton>
            </Tooltip>
            <Tooltip titleOrGetTitle={localization.tooltip.resolved()}>
                <ToggleButton value={VulnerabilitiesView.Resolved}>
                    <ClosedIcon/>
                </ToggleButton>
            </Tooltip>
        </ToggleButtonGroup>);
}