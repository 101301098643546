import { map, Optional, useLocalization } from "@infrastructure";
import { Box, Divider, Stack, SxProps } from "@mui/material";
import _ from "lodash";
import React, { Children, createContext, Fragment, FunctionComponentElement, ReactNode } from "react";
import { InfoCard, InfoItemLocation, useTheme } from "../../../../../../../common";
import { DefinitionOptionsInfoOptions } from "../hooks";

export type InfoProps = {
    children?: ReactNode;
    customEntityPropertyInfoItemElements: Optional<FunctionComponentElement<object>>[];
    defaultTenantInfoItemElements: Optional<FunctionComponentElement<{ location?: InfoItemLocation }>>[];
    entityPropertyInfoItemElements: Optional<FunctionComponentElement<{ location?: InfoItemLocation }>>[];
    options?: DefinitionOptionsInfoOptions;
};

export class InfoContext {
    constructor(
        public itemTitleSx: Optional<SxProps>,
        public itemValueSx: Optional<SxProps>) {
    }
}

export const infoContext = createContext<Optional<InfoContext>>(undefined);

export function Info({ children: cardElements, customEntityPropertyInfoItemElements, defaultTenantInfoItemElements, entityPropertyInfoItemElements, options }: InfoProps) {
    const variant = options?.variant ?? "profile";

    const localization =
        useLocalization(
            "views.customer.entities.profile.info",
            () => ({
                customProperties: "Custom Properties"
            }));

    const profileInfoItemElements =
        _(entityPropertyInfoItemElements).
            filter(generalCardInfoItemElement => !_.isNil(generalCardInfoItemElement)).
            filter(
                generalCardInfoItemElement =>
                    _.isNil(generalCardInfoItemElement?.props.location) ||
                    generalCardInfoItemElement?.props.location === "all" ||
                    generalCardInfoItemElement?.props.location === "profile").
            value();

    const profileDefaultInfoItemElements =
        _(defaultTenantInfoItemElements).
            filter(
                generalCardInfoItemElement => !_.isNil(generalCardInfoItemElement)).
            filter(
                generalCardInfoItemElement =>
                    _.isNil(generalCardInfoItemElement?.props.location) ||
                    generalCardInfoItemElement?.props.location === "all" ||
                    generalCardInfoItemElement?.props.location === "profile").
            value();

    const theme = useTheme();
    return (
        <infoContext.Provider
            value={
                new InfoContext(
                    options?.itemOptions?.titleSx,
                    options?.itemOptions?.valueSx)}>
            {map(
                variant,
                {
                    "miniGlance":
                        () =>
                            <Stack
                                spacing={1}
                                sx={{ width: "100%" }}>
                                {_.concat(defaultTenantInfoItemElements, entityPropertyInfoItemElements).
                                    filter(
                                        generalCardInfoItemElement =>
                                            generalCardInfoItemElement?.props.location === "all" ||
                                            generalCardInfoItemElement?.props.location === "miniGlance").
                                    map(
                                        generalCardInfoItemElement => (
                                            <Box
                                                key={generalCardInfoItemElement!.key}
                                                sx={{ width: "inherit" }}>
                                                {generalCardInfoItemElement}
                                            </Box>))}
                            </Stack>,
                    "profile":
                        () =>
                            <Stack
                                spacing={0}
                                sx={{
                                    height: "100%",
                                    width: "100%"
                                }}>
                                <InfoCard columns={true}>
                                    {profileDefaultInfoItemElements}
                                </InfoCard>
                                {!_.isEmpty(profileInfoItemElements) &&
                                    <Fragment>
                                        <Divider
                                            sx={{
                                                borderBottomWidth: "1px",
                                                margin: theme.spacing(0, 0, 1, 0)
                                            }}
                                            variant="fullWidth"/>
                                        <InfoCard columns={true}>
                                            {profileInfoItemElements}
                                        </InfoCard>
                                    </Fragment>}
                                {!_.isEmpty(customEntityPropertyInfoItemElements) &&
                                    <Fragment>
                                        <Divider
                                            sx={{
                                                borderBottomWidth: "1px",
                                                margin: theme.spacing(0, 0, 1, 0)
                                            }}
                                            variant="fullWidth"/>
                                        <InfoCard
                                            columns={true}
                                            title={localization.customProperties()}>
                                            {customEntityPropertyInfoItemElements}
                                        </InfoCard>
                                    </Fragment>}
                                {_.map(
                                    Children.toArray(cardElements),
                                    (cardElement, cardElementIndex) =>
                                        <Box key={cardElementIndex}>
                                            <Divider
                                                sx={{
                                                    borderBottomWidth: "1px",
                                                    margin: theme.spacing(0, 0, 1, 0)
                                                }}
                                                variant="fullWidth"/>
                                            {cardElement}
                                        </Box>)}
                            </Stack>
                })}
        </infoContext.Provider>);
}