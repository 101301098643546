import { Link, useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../../";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { useOciConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { useGetOciFssFileSystemRiskContext } from "../contexts";

export function useOciFssFileSystemDefaultEncryptionRiskDefinition(riskModel: Contract.RiskModel) {
    const fssFileSystemDefaultEncryptionRiskModel = riskModel as Contract.OciFssFileSystemDefaultEncryptionRiskModel;
    const fileSystemModel = entityModelStore.useGet(fssFileSystemDefaultEncryptionRiskModel.risk.entityId) as Contract.OciFssFileSystemModel;

    const getOciFssFileSystemRiskContext = useGetOciFssFileSystemRiskContext();

    const consoleSignInStepTranslator = useOciConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.oci.hooks.compliance.useOciFssFileSystemDefaultEncryptionRiskDefinition",
            () => ({
                description: "{{fileSystem}} is not configured with encryption using CMK",
                sections: {
                    resolution: {
                        step1: "Under **Encryption**, click **Edit** next to **Encryption key**",
                        step2: "Select **Encrypt using customer-managed keys**",
                        step3: {
                            link: "OCI documentation",
                            text: "Select a **Vault** and a **Master encryption key**. Ensure an IAM policy exists that allows the file system to use the key. For more information, see {{grantingFssKeyAccessLink}}"
                        },
                        step4: "Click **Save changes**"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            fileSystem:
                <Entity
                    entityIdOrModel={fileSystemModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.OciConsoleView.Fss,
                fileSystemModel.consoleUrl!),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3.text({
                grantingFssKeyAccessLink:
                    <Link
                        urlOrGetUrl={fssFileSystemDefaultEncryptionRiskModel.grantingFssKeyAccessUrl}
                        variant="external">
                        {localization.sections.resolution.step3.link()}
                    </Link>
            }),
            localization.sections.resolution.step4()
        ],
        riskModel,
        () => {
            const ociFssFileSystemRiskContext = getOciFssFileSystemRiskContext(fileSystemModel);
            return [
                ociFssFileSystemRiskContext.generalInformation,
                ociFssFileSystemRiskContext.size,
                ociFssFileSystemRiskContext.sensitive,
                ociFssFileSystemRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        });
}