import { Action0, EventHandlerRegister, makeContextProvider } from "@infrastructure";
import { Contract } from "../../../../../common";

export class RiskContext {
    constructor(
        public excludedEntityIds: string[],
        public excludedRiskItemEntityIds: string[],
        public linkEnabled: boolean,
        public registerAuditEventsChange: EventHandlerRegister<Action0>,
        public reloadRiskModel: () => Promise<void>,
        public riskModel: Contract.RiskModel,
        public triggerAuditEventsChange: Action0) {
    }
}

export const [useRiskContext, useSetRiskContext, useRiskContextProvider] = makeContextProvider<RiskContext>();