import { Action0, useLocalization, WelcomeView } from "@infrastructure";
import { Button, Stack, Typography, useTheme } from "@mui/material";
import _ from "lodash";
import React, { ReactNode } from "react";

type ListWelcomeViewProps = {
    disabledText?: string;
    icon?: ReactNode;
    onStart: Action0;
    title: string;
};

export function ListWelcomeView({ disabledText = undefined, icon, onStart, title }: ListWelcomeViewProps) {
    const localization =
        useLocalization(
            "infrastructure.listWelcomeView",
            () => ({
                title: "Let's Start"
            }));

    const theme = useTheme();
    return (
        <WelcomeView title={title}>
            <Stack
                alignItems="center"
                justifyContent="center"
                spacing={2}>
                <Button
                    disabled={!_.isNil(disabledText)}
                    startIcon={icon}
                    sx={{ padding: theme.spacing(1, 3) }}
                    variant="outlined"
                    onClick={() => onStart()}>
                    {localization.title()}
                </Button>
                {!_.isNil(disabledText) && (
                    <Typography variant="h5">
                        {disabledText}
                    </Typography>)}
            </Stack>
        </WelcomeView>);
}