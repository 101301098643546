﻿import { Typography } from "@mui/material";
import React from "react";
import { InlineItems, InlineItemsVariant } from "./InlineItems";

type InlineTextsProps = {
    namePluralizer?: (count: number) => string;
    texts: string[];
    variant: InlineItemsVariant;
};

export function InlineTexts({ namePluralizer, texts, variant }: InlineTextsProps) {
    return (
        <InlineItems
            items={texts}
            namePluralizer={namePluralizer}
            variant={variant}>
            {text =>
                <Typography noWrap={true}>
                    {text}
                </Typography>}
        </InlineItems>);
}