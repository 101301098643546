import _ from "lodash";
import { ApiController, Contract, ReportController } from "../controllers";
import { DownloadHelper } from "./downloadHelper";

export class ExportReportHelper {
    public static async downloadRemote(
        reportRequestDefinition: Contract.ReportControllerReportRequestDefinition,
        scopeId?: string) {
        const reportControllerInsertExportReportDefinitionResponse = await ReportController.insertExportReportDefinition(new Contract.ReportControllerInsertExportReportDefinitionRequest(reportRequestDefinition));
        DownloadHelper.downloadFile(
            ReportController.generateExportReportUrl(
                reportControllerInsertExportReportDefinitionResponse.reportDefinitionId,
                _.isNil(scopeId)
                    ? ApiController.scopeId
                    : scopeId));
    }
}