import { EmptyMessage } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React, { ReactNode } from "react";
import { EntitiesInfoItem } from ".";
import { Contract, InfoCard } from "../../../../../../../common";

type EntityTypeNameGroupsInfoCardProps = {
    emptyMessage: string;
    entityTypeNameToIdsMap: Dictionary<string[]>;
    title: ReactNode;
};

export function EntityTypeNameGroupsInfoCard({ emptyMessage, entityTypeNameToIdsMap, title }: EntityTypeNameGroupsInfoCardProps) {
    return (
        <InfoCard
            columns={true}
            title={title}>
            {_(entityTypeNameToIdsMap).
                values().
                every(_.isEmpty)
                ? <EmptyMessage
                    message={emptyMessage}
                    verticalCenter={true}/>
                : _(entityTypeNameToIdsMap).
                    map(
                        (entityIds, entityTypeName) => ({
                            entityIds,
                            entityTypeName
                        })).
                    orderBy(({ entityTypeName }) => entityTypeName === Contract.TypeNames.AwsEc2NetworkInterface).
                    filter(({ entityIds }) => !_.isEmpty(entityIds)).
                    map(
                        ({ entityIds, entityTypeName }) =>
                            <EntitiesInfoItem
                                entityIdsOrModels={entityIds}
                                entityTypeName={entityTypeName}
                                key={entityTypeName}/>).
                    value()}
        </InfoCard>);
}