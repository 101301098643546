import { useLocalization } from "@infrastructure";
import React from "react";
import { useGetAwsRdsDatabaseInstanceRiskContext } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { useGetRiskPolicyConfigurationRetentionTimeFrameContextItem } from "../../../useGetRiskPolicyConfigurationRetentionTimeFrameContextItem";

export function useAwsRdsDatabaseInstanceBackupRetentionTimeFrameRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsRdsDatabaseInstanceBackupRetentionTimeFrameRisk;
    const databaseInstanceModel = entityModelStore.useGet(risk.entityId) as Contract.AwsRdsDatabaseInstanceModel;
    const databaseInstance = databaseInstanceModel.entity as Contract.AwsRdsDatabaseInstance;

    const getAwsRdsDatabaseInstanceRiskContext = useGetAwsRdsDatabaseInstanceRiskContext();
    const getRiskPolicyConfigurationRetentionTimeFrameContextItem = useGetRiskPolicyConfigurationRetentionTimeFrameContextItem();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsRdsDatabaseInstanceBackupRetentionTimeFrameRiskDefinition",
            () => ({
                description: "{{databaseInstance}} backup retention period is less than {{configuredBackupRetentionTimeFrame | TimeSpanFormatter.day}}",
                sections: {
                    resolution: {
                        step1: "In the top right corner, click **Modify**",
                        step2: "Scroll down to the **Additional configuration** section and set the **Backup retention period** to at least {{configuredBackupRetentionTimeFrame | TimeSpanFormatter.day}}",
                        step3: "Continue and save changes"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            configuredBackupRetentionTimeFrame: risk.backupRetentionTimeFrame,
            databaseInstance:
                <Entity
                    entityIdOrModel={databaseInstanceModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.Rds,
                AwsConsoleUrlBuilder.getRdsDatabaseInstanceUrl(databaseInstance)),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2({ configuredBackupRetentionTimeFrame: risk.backupRetentionTimeFrame }),
            localization.sections.resolution.step3()
        ],
        riskModel,
        () => {
            const databaseInstanceRiskContext = getAwsRdsDatabaseInstanceRiskContext(databaseInstanceModel);
            return [
                databaseInstanceRiskContext.generalInformation,
                getRiskPolicyConfigurationRetentionTimeFrameContextItem(risk.backupRetentionTimeFrame),
                databaseInstanceRiskContext.backupRetentionTimeFrame,
                databaseInstanceRiskContext.storageSize,
                databaseInstanceRiskContext.highestDataSensitivity,
                databaseInstanceRiskContext.sensitive,
                databaseInstanceRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}