import { FormLayout, makeContextProvider, Optional, OrderedWizard, OrderedWizardItem, useLocalization } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React, { useCallback, useEffect, useMemo } from "react";
import { useJiraContext, useSetJiraContext } from "../..";
import { Contract } from "../../../../../../../../../../../../../../common";
import { FinishItem, getSeverityToPriorityMap, PermissionsItem, RawIdItem, SyncIssuePrioritiesItem, SyncIssueStatusesItem } from "./components";

export class AddOrEditProjectContext {
    public issueTypeNameToRiskStatusToJiraStatusErrorMap: Optional<Dictionary<Dictionary<Optional<Contract.JiraStatus>>>>;
    public severityToPriorityErrorMap: Optional<Dictionary<Optional<Contract.JiraPriority>>>;
    public syncIssuePrioritiesItemOptionType: SyncOptionType;
    public syncIssueStatusesItemOptionType: SyncOptionType;

    constructor(
        public issueTypeNameToStatusesMap: Dictionary<Contract.JiraStatus[]>,
        public issueTypeNameToRiskStatusToJiraStatusMap: Optional<Dictionary<Dictionary<Optional<Contract.JiraStatus>>>>,
        public priorities: Optional<Contract.JiraPriority[]>,
        public rawId: Optional<string>,
        public severityToPriorityMap: Optional<Dictionary<Optional<Contract.JiraPriority>>>
    ) {
        this.issueTypeNameToRiskStatusToJiraStatusErrorMap =
            _(issueTypeNameToRiskStatusToJiraStatusMap).
                mapValues(
                    (riskStatusToJiraStatus, issueTypeName) =>
                        _.pickBy(
                            riskStatusToJiraStatus,
                            jiraStatus =>
                                _.isNil(issueTypeNameToStatusesMap[issueTypeName]) ||
                                !_.some(issueTypeNameToStatusesMap[issueTypeName], jiraStatus)
                        )).
                pickBy(value => !_.isEmpty(value)).
                value();
        this.severityToPriorityErrorMap =
            _(severityToPriorityMap).
                omitBy(_.isEmpty).
                pickBy(priority => !_.some(priorities, priority)).
                value();
        this.syncIssuePrioritiesItemOptionType =
            _.isNil(severityToPriorityMap)
                ? SyncOptionType.None
                : SyncOptionType.Custom;
        this.syncIssueStatusesItemOptionType =
            _.every(issueTypeNameToRiskStatusToJiraStatusMap, _.isEmpty)
                ? SyncOptionType.None
                : SyncOptionType.Custom;
    }
}

export const [useAddOrEditProjectContext, useSetAddOrEditProjectContext, useAddOrEditProjectContextProvider] = makeContextProvider<AddOrEditProjectContext>();

export function AddOrEditProject() {
    const { instanceModel, projectConfiguration } = useJiraContext();
    const setJiraContext = useSetJiraContext();

    const projectConfigurationRawId = projectConfiguration?.rawId;
    const project =
        !_.isNil(projectConfigurationRawId)
            ? (instanceModel?.state as Contract.JiraInstanceState).projectRawIdToProjectMap[projectConfigurationRawId].project
            : undefined;
    const projectIssueTypeNameToStatusesMap =
        useMemo(
            () =>
                !_.isNil(project)
                    ? _(project.issueTypeNames).
                        keyBy().
                        mapValues(issueType => project.issueTypeNameToDataMap[issueType].statuses).
                        value()
                    : {},
            [project]);
    const getIssueTypeNameToRiskStatusToJiraStatusMap =
        useCallback(
            (issueTypeNames?: string[]) =>
                _(issueTypeNames).
                    union(_.keys(projectConfiguration?.issueTypeNameToRiskStatusToJiraStatusMap)).
                    keyBy().
                    mapValues(issueTypeName => projectConfiguration?.issueTypeNameToRiskStatusToJiraStatusMap?.[issueTypeName] ?? {}).
                    value(),
            [project, projectConfiguration]);

    const [
        {
            issueTypeNameToRiskStatusToJiraStatusErrorMap,
            issueTypeNameToStatusesMap,
            priorities,
            severityToPriorityErrorMap: severityToPriorityMapErrors,
            severityToPriorityMap
        },
        setContext,
        ContextProvider] =
        useAddOrEditProjectContextProvider(
            () =>
                new AddOrEditProjectContext(
                    projectIssueTypeNameToStatusesMap,
                    getIssueTypeNameToRiskStatusToJiraStatusMap(project?.issueTypeNames),
                    project?.priorities,
                    projectConfigurationRawId,
                    projectConfiguration?.riskSeverityToJiraPriorityMap
                ));

    useEffect(
        () => {
            if (_.isNil(severityToPriorityMap) && !_.isNil(priorities)) {
                setContext(
                    context => ({
                        ...context,
                        severityToPriorityMap: getSeverityToPriorityMap(priorities)
                    }));
            }
        },
        [priorities]);

    useEffect(
        () => {
            if (!_.isEmpty(issueTypeNameToStatusesMap)) {
                setContext(
                    context => ({
                        ...context,
                        issueTypeNameToRiskStatusToJiraStatusMap: getIssueTypeNameToRiskStatusToJiraStatusMap(_.keys(issueTypeNameToStatusesMap))
                    }));
            }
        },
        [issueTypeNameToStatusesMap]);

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useTicketingItems.jira.addOrEditProject",
            () => ({
                add: "Add Jira project",
                edit: "Edit Jira project",
                issueTypeDeletedWarningMessage: "Clicking Finish will remove any issue types that were deleted in Jira from this mapping.",
                steps: {
                    configuration: "Configure Mapping",
                    permissions: "Grant Permissions",
                    rawId: "Activate Project",
                    syncIssuePriorities: "Sync Issue Priorities",
                    syncIssueStatuses: "Sync Issue Statuses"
                }
            }));

    return (
        <ContextProvider>
            <FormLayout
                disableContentPadding={true}
                titleOptions={{
                    text:
                        _.isNil(projectConfiguration)
                            ? localization.add()
                            : localization.edit()
                }}>
                <OrderedWizard
                    finishItemElement={<FinishItem/>}
                    startItemIndex={
                        _.isNil(projectConfiguration)
                            ? 0
                            : 2}
                    onClose={
                        () =>
                            setJiraContext(
                                () => ({
                                    addOrEditType: undefined,
                                    instanceModel: undefined,
                                    projectConfiguration: undefined
                                }))}>
                    <OrderedWizardItem title={localization.steps.permissions()}>
                        <PermissionsItem/>
                    </OrderedWizardItem>
                    <OrderedWizardItem
                        deferredLoading={true}
                        title={localization.steps.rawId()}>
                        <RawIdItem/>
                    </OrderedWizardItem>
                    <OrderedWizardItem
                        error={!_.isEmpty(severityToPriorityMapErrors)}
                        title={localization.steps.syncIssuePriorities()}>
                        <SyncIssuePrioritiesItem/>
                    </OrderedWizardItem>
                    <OrderedWizardItem
                        error={!_.isEmpty(issueTypeNameToRiskStatusToJiraStatusErrorMap)}
                        errorMessage={
                            _(issueTypeNameToRiskStatusToJiraStatusErrorMap).
                                keys().
                                some(errorIssueTypeName => _.isNil(issueTypeNameToStatusesMap[errorIssueTypeName]))
                                ? localization.issueTypeDeletedWarningMessage()
                                : undefined}
                        title={localization.steps.syncIssueStatuses()}>
                        <SyncIssueStatusesItem/>
                    </OrderedWizardItem>
                </OrderedWizard>
            </FormLayout>
        </ContextProvider>);
}

export enum SyncOptionType {
    Custom = "Custom",
    None = "None"
}