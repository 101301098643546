import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../../../../tenants";
import { useCommonSectionsAndDescriptionDefinition } from "../../../../../../useCommonSectionsAndDescriptionDefinition";
import { SnapshotContextSection } from "./components";


export function useAwsEc2SnapshotEncryptionDisabledRiskSnapshotDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsEc2SnapshotEncryptionDisabledRisk;
    const snapshotModel = entityModelStore.useGet(risk.entityId) as Contract.AwsEc2SnapshotModel;
    const snapshot = snapshotModel.entity as Contract.AwsEc2Snapshot;

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsEc2SnapshotEncryptionDisabledRiskDefinition.hooks.useAwsEc2SnapshotEncryptionDisabledRiskSnapshotDefinition",
            () => ({
                description: "EBS Snapshot {{snapshot}} is not configured with encryption using KMS",
                sections: {
                    resolution: {
                        step1: "Before encrypting the EBS snapshot, verify that identities using it are granted permissions to use the KMS key that will be used for encryption",
                        step2: "Click the **Actions** button on the top and **Copy snapshot**",
                        step3: "Check the **Encrypt this snapshot** checkbox and choose a **Master Key**",
                        step4: "Click **Copy snapshot**",
                        step5: "Select the current unencrypted snapshot and click **Actions** on top and then **Delete**"
                    }
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description(
            {
                snapshot:
                    <Entity
                        entityIdOrModel={snapshotModel}
                        variant="text"/>
            }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.Ec2,
                AwsConsoleUrlBuilder.getEc2SnapshotUrl(snapshot)),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4(),
            localization.sections.resolution.step5()
        ],
        riskModel,
        undefined,
        {
            contextSectionElement:
                <SnapshotContextSection
                    risk={risk}
                    snapshotModel={snapshotModel}/>
        });
}