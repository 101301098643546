import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract } from "../../../../../../..";
import { Entity } from "../../../../../..";

export function useAwsProvisionPermissionSetChangeDefinition(change: Contract.AwsChange, entityLinkDisabled?: boolean) {
    const provisionPermissionSetChange = change as Contract.AwsResourceChange;
    const localization =
        useLocalization(
            "common.change.hooks.useDefinition.hooks.useAwsDefinition.hooks.useAwsProvisionPermissionSetChangeDefinition",
            () => ({
                title: "Reprovision {{permissionSet}}"
            }));
    return {
        title:
            localization.title({
                permissionSet:
                    <Entity
                        entityIdOrModel={provisionPermissionSetChange.resourceId}
                        glanceOptions={{ disabled: true }}
                        linkOptions={{ disabled: entityLinkDisabled }}
                        variant="typeText"/>
            })
    };
}