import { useLocalization } from "@infrastructure";
import React, { useMemo } from "react";
import { Contract, entityModelStore, InlineEntities, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem, RiskDefinitionContextItemImpact } from "../../../../../../utilities";
import { useGetWorkloadAnalysisRiskContext } from "./useGetWorkloadAnalysisRiskContext";


export function useGetVirtualMachineImageWorkloadAnalysisRiskContext() {
    return useMemo(
        () => useVirtualMachineImageWorkloadAnalysisRiskContext,
        []);
}

function useVirtualMachineImageWorkloadAnalysisRiskContext(risk: Contract.VirtualMachineImageWorkloadAnalysisRisk) {
    const workloadAnalysisRiskContext = useGetWorkloadAnalysisRiskContext()(risk);
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const riskEntityModel = entityModelStore.useGet(risk.entityId);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.workloadAnalysis.hooks.contexts.useGetVirtualMachineImageWorkloadAnalysisRiskContext",
            () => ({
                virtualMachineUsage: "This {{riskedEntityTypeName}} is used by {{virtualMachines}}"
            }));

    return {
        ...workloadAnalysisRiskContext,
        virtualMachineUsage:
            new RiskDefinitionContextItem(
                localization.virtualMachineUsage({
                    riskedEntityTypeName:
                        entityTypeNameTranslator(
                            riskEntityModel.entity.typeName,
                            { includeServiceName: false }),
                    virtualMachines:
                        <InlineEntities
                            entityIdsOrModels={risk.virtualMachineIds}
                            entityTypeName={Contract.TypeNames.IVirtualMachine}
                            variant="itemCountAndType"/>
                }),
                (risk.severity === Contract.Severity.High && risk.virtualMachineIds.length >= 10)
                    ? RiskDefinitionContextItemImpact.SeverityIncreased
                    : RiskDefinitionContextItemImpact.Information)
    };
}