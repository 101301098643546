import { Link, Steps, useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import React from "react";
import { useTheme } from "../../../../../../../../../../../../../../../../../../../../common";

export function VariableItem() {
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCodePipelineItems.codePipeline.addOrEdit.hooks.useDefinition.hooks.useGitLabPipeline.variableItem",
            () => ({
                description: {
                    link: "masked variables",
                    text: "To protect Tenable Cloud Security's API token, it is recommended to use either GitLab Pipeline’s {{MaskedVariablesLink}}, or some other secret store, if you are currently using one."
                },
                note: "Be aware that GitLab CI/CD variables can be stored on different scopes, which can be used depending on how you plan to use Tenable Cloud Security's scanning. It is recommended, but not mandatory, to only associate Tenable Cloud Security's token with workflows linked to repositories with IaC code.",
                steps: {
                    step1: "Log into GitLab",
                    step2: "Go to your project’s **Settings > CI/CD**",
                    step3: "Under Variables, click **Expand**",
                    step4: "Click on **Add variable**",
                    step5: {
                        step1: "Set **Key** to **TENABLE_API_TOKEN**",
                        step2: "Paste the token created in the previous step as the **Value**",
                        step3: "Select an **Environment scope**, if applicable",
                        step4: "Under **Flags**, check the box that says **Mask variable**",
                        step5: "Click on **Add variable**",
                        text: "Fill in the following details:"
                    },
                    text: {
                        link: "define a CI/CD variable",
                        text: "To {{VariableLink}} on a project, follow these steps:"
                    }
                }
            }));

    const theme = useTheme();
    return (
        <Stack
            spacing={2}
            sx={{ maxWidth: theme.spacing(58) }}>
            <Typography>
                {localization.description.text({
                    MaskedVariablesLink:
                        <Link
                            sx={{ textDecorationLine: "underline" }}
                            urlOrGetUrl="https://docs.gitlab.com/ee/ci/variables/#mask-a-cicd-variable/"
                            variant="external">
                            {localization.description.link()}
                        </Link>
                })}
            </Typography>
            <Typography>
                {localization.note()}
            </Typography>
            <Typography>
                {localization.steps.text.text({
                    VariableLink:
                        <Link
                            sx={{ textDecorationLine: "underline" }}
                            urlOrGetUrl="https://docs.gitlab.com/ee/ci/variables/#define-a-cicd-variable-in-the-ui"
                            variant="external">
                            {localization.steps.text.link()}
                        </Link>
                })}
            </Typography>
            <Steps variant="letters">
                {localization.steps.step1()}
                {localization.steps.step2()}
                {localization.steps.step3()}
                {localization.steps.step4()}
                <Stack spacing={1}>
                    <Typography>
                        {localization.steps.step5.text()}
                    </Typography>
                    <Steps variant="plainNumbers">
                        {localization.steps.step5.step1()}
                        {localization.steps.step5.step2()}
                        {localization.steps.step5.step3()}
                        {localization.steps.step5.step4()}
                        {localization.steps.step5.step5()}
                    </Steps>
                </Stack>
            </Steps>
        </Stack>);
}