import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, DeferredFilter, Optional, optionalTableCell, PagedValuesFilter, TimeFormatter, TimeRangeFilter, useLocalization, ValueFilter, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { useGcpCommonScopeResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, EntityModelHelper, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useActivityTranslator, useEntitiesExportOptions, useEntityTypeNameTranslator } from "../../../../../../../../../../common";
import { GcpRoleBindingHelper, useGcpIamServiceAccountDisabledTranslator } from "../../../../../../../../../../tenants";
import { DefinitionData } from "../../../../Table";

export function useGcpIamServiceAccountDefinition(definitionData: DefinitionData) {
    const serviceAccountModelFiltersPromise = definitionData.entityModelFiltersPromise as Promise<Contract.GcpIamServiceAccountModelFilters>;
    const activityTranslator = useActivityTranslator();
    const commonScopeResourceDefinition = useGcpCommonScopeResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const serviceAccountDisabledTranslator = useGcpIamServiceAccountDisabledTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.gcp.useGcpIamServiceAccountDefinition",
            () => ({
                columns: {
                    activityTime: {
                        helpText: "The last time an activity was seen in GCP for this identity. This is highly dependent on the logging configuration. Activity that was not logged will not be taken into account.",
                        title: "Activity Time"
                    },
                    disabled: "Status",
                    groupIds: "Group Memberships",
                    identityContainedRoleBindingIds: {
                        helpText: "These role bindings are \"covered\" by another role binding at a higher scope level or one that is assigned via group membership.",
                        title: "Covered GCP Role Bindings"
                    },
                    identityRoleBindingIds: "GCP Role Bindings",
                    identityRoleIds: "GCP Roles",
                    mail: "Mail",
                    originatorResourceIds: "Assigned To",
                    signInTime: {
                        empty: "Not seen",
                        title: "Sign-in Time"
                    }
                }
            }));

    return new EntityTableDefinition(
        [
            commonScopeResourceDefinition.columns.tenantColumn,
            commonScopeResourceDefinition.columns.creationTimeColumn,
            commonScopeResourceDefinition.columns.creatorIdentityCsvColumn,
            commonScopeResourceDefinition.columns.creatorOriginatorEntityCsvColumn,
            commonScopeResourceDefinition.columns.accessLevelColumn,
            commonScopeResourceDefinition.columns.updateTimeColumn,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.GcpIamServiceAccountModel>(
                        Contract.TypeNames.GcpResource,
                        item => item.access!.originatorEntityIds,
                        localization.columns.originatorResourceIds(),
                        { getValue: entityModel => EntityModelHelper.formatDisplayReference(entityModel, entityTypeNameTranslator) })
                }
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.GcpIamServiceAccountOriginatorResources)}
                                placeholder={localization.columns.originatorResourceIds()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpIamServiceAccountOriginatorResources}
                key={Contract.EntityModelProperty.GcpIamServiceAccountOriginatorResources}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.GcpIamServiceAccountModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.access!.originatorEntityIds}
                            entityTypeName={Contract.TypeNames.GcpResource}
                            entityVariant="iconText"/>}
                sortOptions={{ enabled: false }}
                title={localization.columns.originatorResourceIds()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.GcpIamServiceAccountModel) => ({
                            [localization.columns.mail()]: (item.entity as Contract.GcpIamServiceAccount).mail
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedValuesFilter
                                getValuePage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.GcpIamServiceAccountMail)}
                                placeholder={localization.columns.mail()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpIamServiceAccountMail}
                itemProperty={(item: Contract.EntityModel) => (item.entity as Contract.GcpIamServiceAccount).mail}
                key={Contract.EntityModelProperty.GcpIamServiceAccountMail}
                title={localization.columns.mail()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.GcpIamServiceAccountModel) => ({
                            [localization.columns.disabled()]: serviceAccountDisabledTranslator((item.entity as Contract.GcpIamServiceAccount).disabled)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <ValuesFilter
                                placeholder={localization.columns.disabled()}
                                sorted={false}>
                                <ValuesFilterItem
                                    title={serviceAccountDisabledTranslator(true)}
                                    value={true}/>
                                <ValuesFilterItem
                                    title={serviceAccountDisabledTranslator(false)}
                                    value={false}/>
                            </ValuesFilter>
                    }
                }}
                id={Contract.EntityModelProperty.GcpIamServiceAccountDisabled}
                itemProperty={(item: Contract.EntityModel) => serviceAccountDisabledTranslator((item.entity as Contract.GcpIamServiceAccount).disabled)}
                key={Contract.EntityModelProperty.GcpIamServiceAccountDisabled}
                title={localization.columns.disabled()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.GcpIamServiceAccountModel) => ({
                            [localization.columns.signInTime.title()]:
                                _.isNil((item.entityProfile as Contract.GcpIamServiceAccountProfile)?.signInTime)
                                    ? localization.columns.signInTime.empty()
                                    : TimeFormatter.iso8601String((item.entityProfile as Contract.GcpIamServiceAccountProfile).signInTime)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <DeferredFilter
                                promiseOrGetPromise={serviceAccountModelFiltersPromise}
                                title={localization.columns.signInTime.title()}>
                                {filters =>
                                    <TimeRangeFilter
                                        emptyValue={filters.signInTimeRange.emptyValue}
                                        placeholder={localization.columns.signInTime.title()}
                                        timeRange={TimeRangeHelper.getTimeRangeFilterRange(filters.signInTimeRange.timeRange)}/>}
                            </DeferredFilter>
                    }
                }}
                id={Contract.EntityModelProperty.GcpIamServiceAccountSignInTime}
                itemProperty={
                    (item: Contract.GcpIamServiceAccountModel) => {
                        const serviceAccountProfile = item.entityProfile as Contract.GcpIamServiceAccountProfile;
                        return _.isNil(serviceAccountProfile?.signInTime)
                            ? localization.columns.signInTime.empty()
                            : TimeFormatter.monthDayAndYear(serviceAccountProfile.signInTime);
                    }}
                key={Contract.EntityModelProperty.GcpIamServiceAccountSignInTime}
                sortOptions={{ type: DataTableSortType.Date }}
                title={localization.columns.signInTime.title()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.GcpIamServiceAccountModel) => ({
                            [localization.columns.activityTime.title()]: activityTranslator(item.access?.activity) ?? ""
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <DeferredFilter
                                promiseOrGetPromise={serviceAccountModelFiltersPromise}
                                title={localization.columns.activityTime.title()}>
                                {filters =>
                                    <TimeRangeFilter
                                        emptyValue={filters.activityTimeRange.emptyValue}
                                        placeholder={localization.columns.activityTime.title()}
                                        timeRange={TimeRangeHelper.getTimeRangeFilterRange(filters.activityTimeRange.timeRange)}/>}
                            </DeferredFilter>
                    }
                }}
                id={Contract.EntityModelProperty.GcpIamServiceAccountActivityTime}
                key={Contract.EntityModelProperty.GcpIamServiceAccountActivityTime}
                message={localization.columns.activityTime.helpText()}
                messageLevel="info"
                render={
                    optionalTableCell<Contract.GcpIamServiceAccountModel>(
                        serviceAccountModel =>
                            activityTranslator(serviceAccountModel.access!.activity))}
                sortOptions={{ type: DataTableSortType.Date }}
                title={localization.columns.activityTime.title()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.GcpIamServiceAccountModel>(
                        Contract.TypeNames.GciDirectoryGroup,
                        item => item.groupIds,
                        localization.columns.groupIds())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.GcpIamServiceAccountGroups)}
                                placeholder={localization.columns.groupIds()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpIamServiceAccountGroups}
                key={Contract.EntityModelProperty.GcpIamServiceAccountGroups}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.GcpIamServiceAccountModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.groupIds}
                            entityTypeName={Contract.TypeNames.GciDirectoryGroup}
                            entityVariant="iconText"/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.groupIds()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.GcpIamServiceAccountModel>(
                        Contract.TypeNames.GcpIamRoleBinding,
                        item => item.identityRoleBindingIds,
                        localization.columns.identityRoleBindingIds(),
                        { getValue: entityModel => GcpRoleBindingHelper.getDisplayName(entityTypeNameTranslator, entityModel as Contract.GcpIamRoleBindingModel) })
                }
                id={Contract.EntityModelProperty.GciPrincipalGcpRoleBindings}
                key={Contract.EntityModelProperty.GciPrincipalGcpRoleBindings}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.GcpIamServiceAccountModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.identityRoleBindingIds}
                            entityTypeName={Contract.TypeNames.GcpIamRoleBinding}
                            entityVariant="iconText"/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.identityRoleBindingIds()}/>,
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.GcpIamServiceAccountIdentityRoles)}
                                placeholder={localization.columns.identityRoleIds()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpIamServiceAccountIdentityRoles}
                key={Contract.EntityModelProperty.GcpIamServiceAccountIdentityRoles}
                title={localization.columns.identityRoleIds()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.GcpIamServiceAccountModel>(
                        Contract.TypeNames.GcpIamRoleBinding,
                        item => item.identityContainedRoleBindingIds,
                        localization.columns.identityContainedRoleBindingIds.title(),
                        { getValue: entityModel => GcpRoleBindingHelper.getDisplayName(entityTypeNameTranslator, entityModel as Contract.GcpIamRoleBindingModel) })
                }
                filterOptions={{
                    itemOrItems: {
                        element:
                            <ValueFilter
                                items={[
                                    {
                                        value: true
                                    }
                                ]}
                                placeholder={localization.columns.identityContainedRoleBindingIds.title()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GciPrincipalContainedGcpRoleBindings}
                key={Contract.EntityModelProperty.GciPrincipalContainedGcpRoleBindings}
                message={localization.columns.identityContainedRoleBindingIds.helpText()}
                messageLevel="info"
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.GcpIamServiceAccountModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.identityContainedRoleBindingIds}
                            entityTypeName={Contract.TypeNames.GcpIamRoleBinding}
                            entityVariant="iconText"/>}
                selectorOptions={{ default: false }}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.identityContainedRoleBindingIds.title()}/>,
            commonScopeResourceDefinition.columns.regionColumn,
            commonScopeResourceDefinition.columns.regionLocationColumn,
            commonScopeResourceDefinition.columns.attributesColumn,
            commonScopeResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonScopeResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonScopeResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageGcpIamServiceAccountRequest(
                new Contract.EntityControllerGetEntityModelPageGcpIamServiceAccountRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.GcpResourceUpdateTime]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpScopeResourceAccessLevel]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpScopeResourceRawId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.GcpIamServiceAccountActivityTime]),
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpIamServiceAccountDisabled]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpIamServiceAccountGroups]),
                    filterMap[Contract.EntityModelProperty.GciPrincipalContainedGcpRoleBindings] as Optional<boolean>,
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpIamServiceAccountIdentityRoles]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpIamServiceAccountMail]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpIamServiceAccountOriginatorResources]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.GcpIamServiceAccountSignInTime])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags]))));
}