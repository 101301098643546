import { Function0 } from "lodash";
import React, { ReactNode } from "react";
import { RiskType } from "../../../../../../common";
import { CodeResourceData, RiskModel } from "../../../../../../common/controllers/types.generated";
import { Core, DeleteRisk } from "./components";

export type ProfileProps = {
    codeResourceDatas: CodeResourceData[];
    excludedEntityIds: string[];
    excludedRiskItemEntityIds: string[];
    executeGetRiskModel: Function0<Promise<void>>;
    riskModel: RiskModel;
    riskType: RiskType;
};

export function Profile({ codeResourceDatas, excludedEntityIds, excludedRiskItemEntityIds, executeGetRiskModel, riskModel, riskType }: ProfileProps) {
    return (
        <ProfileErrorBoundary
            errorElement={<DeleteRisk riskId={riskModel.id}/>}>
            <Core
                codeResourceDatas={codeResourceDatas}
                excludedEntityIds={excludedEntityIds}
                excludedRiskItemEntityIds={excludedRiskItemEntityIds}
                executeGetRiskModel={executeGetRiskModel}
                riskModel={riskModel}
                riskType={riskType}/>
        </ProfileErrorBoundary>);
}

type ProfileErrorBoundaryProps = {
    children: ReactNode;
    errorElement?: ReactNode;
};

class ProfileErrorBoundary extends React.Component<ProfileErrorBoundaryProps, { hasError: boolean }> {
    constructor(props: ProfileErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: Error) {
        if (/statusCode=404/.test(error.message)) {
            return { hasError: true };
        }

        return;
    }

    render() {
        if (this.state.hasError) {
            return this.props.errorElement;
        }
        return this.props.children;
    }
}