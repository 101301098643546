import { Action3, InfoIcon, Optional, Tooltip, useInputValidation, useLocalization } from "@infrastructure";
import { FormControl, FormHelperText, Stack, TextField, Typography } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Contract, ScopeMultiSelect, scopeNodeModelStore, useScopeNavigationViewContext } from "../../../../../../../common";

type AutomationRuleItemProps = {
    executing: boolean;
    initialDescription: Optional<string>;
    initialName: Optional<string>;
    initialScopeId: Optional<string>;
    initialScopeIds: Optional<string[]>;
    onValuesChange: Action3<Optional<string>, Optional<string>, Optional<string[]>>;
    setValidation: (valid: boolean) => void;
    tenantTypes?: Contract.TenantType[];
};

export function AutomationRuleItem({ executing, initialDescription, initialName, initialScopeId, initialScopeIds, onValuesChange, setValidation, tenantTypes = undefined }: AutomationRuleItemProps) {
    const scopeNodeMap = scopeNodeModelStore.useGetActiveScopeNodeMap(tenantTypes);
    const { scopeNodeModel } = useScopeNavigationViewContext();
    const scopeId = initialScopeId ?? scopeNodeModel.configuration.id;
    const [description, setDescription] = useState(initialDescription);
    const [name, setName] = useState(initialName);
    const [scopeIds, setScopeIds] =
        useState(
            () => {
                const activeScopeIds =
                    _.filter(
                        initialScopeIds,
                        automationRuleScopeId => !_.isEmpty(scopeNodeMap[automationRuleScopeId]?.tenantIds));
                return activeScopeIds;
            });

    const localization =
        useLocalization(
            "views.customer.configuration.automations.automationRuleItem",
            () => ({
                info: {
                    fields: {
                        description: "Description (optional)",
                        name: {
                            error: {
                                required: "Name cannot be empty"
                            },
                            title: "Name"
                        }
                    }
                },
                scope: {
                    emptyValue: "All existing and future accounts",
                    subtitle: "Select the cloud accounts that you want to trigger the automation rule for. When you select a folder (or the entire organization), the rule will be triggered for all existing and future accounts in that folder. ",
                    title: "Targets"
                }
            }));

    const [nameValidationController, nameValidationMessage] =
        useInputValidation(
            () => {
                const validationName = name?.trim();
                if (_.isEmpty(validationName)) {
                    return localization.info.fields.name.error.required();
                }
                return undefined;
            },
            [name]);

    useEffect(
        () => {
            onValuesChange(
                description,
                name,
                scopeIds);
        },
        [description, name, scopeIds]);

    useEffect(
        () => {
            setValidation(nameValidationController.isValid());
        },
        [name]);

    return (
        <Stack spacing={3}>
            <Stack spacing={1.5}>
                <FormControl
                    fullWidth={true}
                    variant="standard">
                    <TextField
                        disabled={executing}
                        label={localization.info.fields.name.title()}
                        value={name}
                        variant="outlined"
                        onChange={event => setName(event.target.value)}/>
                    {!_.isNil(nameValidationMessage) &&
                        <FormHelperText error={true}>
                            {nameValidationMessage}
                        </FormHelperText>}
                </FormControl>
                <FormControl
                    fullWidth={true}
                    variant="standard">
                    <TextField
                        disabled={executing}
                        label={localization.info.fields.description()}
                        value={description}
                        variant="outlined"
                        onChange={event => setDescription(event.target.value)}/>
                </FormControl>
            </Stack>
            {scopeNodeMap[scopeId].scopeNodeModel.type !== Contract.ScopeType.CloudProviderTenant && (
                <Stack spacing={2}>
                    <Stack
                        alignItems="center"
                        direction="row"
                        spacing={0.5}>
                        <Typography variant="h4">
                            {localization.scope.title()}
                        </Typography>
                        <Tooltip
                            titleOrGetTitle={localization.scope.subtitle()}>
                            <InfoIcon sx={{ fontSize: "18px" }}/>
                        </Tooltip>
                    </Stack>
                    <ScopeMultiSelect
                        emptyValue={localization.scope.emptyValue()}
                        fullWidth={true}
                        rootFolderId={scopeId}
                        selectedScopeIds={
                            scopeIds.length === 1 && scopeIds[0] == scopeId
                                ? []
                                : scopeIds}
                        tenantTypes={tenantTypes}
                        variant="itemSelector"
                        onSelectedScopeIdsChanged={selectedScopeIds => setScopeIds(selectedScopeIds)}/>
                </Stack>)}
        </Stack>);
}