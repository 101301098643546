import { useActions, useDeepDependency, useInputValidation, useLocalization } from "@infrastructure";
import { CircularProgress, FormHelperText } from "@mui/material";
import _ from "lodash";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Contract, PermissionManagementController, scopeNodeModelStore, tenantModelStore, TenantMultiSelect, useTheme } from "../../../../../../../../../common";
import { AdditionalFieldComponentActions, AdditionalFieldComponentProps, useAddContext, useSetAddContext } from "../../../Add";
import { AdditionalPermissionAssignmentRequestData } from "../useGetDefinition";

export function PermissionAssignmentRequestTenantIds({ actionsRef, disabled }: AdditionalFieldComponentProps) {
    const { additionalPermissionRequestData, permissionRequestModel } = useAddContext();
    const setAddContext = useSetAddContext();
    const maxTenantCount = 100;

    const { tenantIds: selectedTenantIds } = additionalPermissionRequestData as AdditionalPermissionAssignmentRequestData;

    const localization =
        useLocalization(
            "views.user.granteeUserPermissionRequests.add.hooks.useGetDefinition.permissionAssignmentRequestTenantIds",
            () => ({
                emptyValue: "Accounts",
                error: {
                    length: "The number of accounts cannot be larger than {{maxTenantCount}}"
                },
                title: "Accounts"
            }));

    function setAddContextTenantIds(tenantIds: string[]) {
        setAddContext(
            addContext => {
                (addContext.additionalPermissionRequestData as AdditionalPermissionAssignmentRequestData).tenantIds = tenantIds;
                return { ...addContext };
            });
    }

    const scopeNodeModelMap = scopeNodeModelStore.useGetScopeNodeMap();
    const firstFetchDataRef = useRef(!_.isNil(permissionRequestModel));
    const [fetchTenantIdsExecuting, setFetchTenantIdsExecuting] = useState(false);
    const [tenantIds, setTenantIds] = useState<string[]>();
    useActions<AdditionalFieldComponentActions>(
        actionsRef,
        {
            fetchData:
                async permissionEligibilityModel => {
                    setFetchTenantIdsExecuting(true);
                    setTenantIds([]);
                    if (!firstFetchDataRef.current) {
                        setAddContextTenantIds([]);
                    }

                    try {
                        const { tenantIds } =
                            await PermissionManagementController.getPermissionEligibilityTenantIds(
                                new Contract.PermissionManagementControllerGetPermissionEligibilityTenantIdsRequest(permissionEligibilityModel.configuration.id));

                        await tenantModelStore.get(tenantIds);

                        if (_.some(
                            tenantIds,
                            tenantId =>
                                !_.has(
                                    scopeNodeModelMap,
                                    tenantId))) {
                            await scopeNodeModelStore.notify();
                        }

                        setTenantIds(tenantIds);
                        if (firstFetchDataRef.current) {
                            const selectedTenantIds =
                                _.intersection(
                                    (permissionRequestModel!.permissionRequest as Contract.PermissionAssignmentRequest).tenantIds,
                                    tenantIds);
                            setAddContextTenantIds(
                                _.isEmpty(selectedTenantIds)
                                    ? tenantIds
                                    : selectedTenantIds);
                        }
                    } catch {
                        firstFetchDataRef.current = false;
                        return false;
                    }

                    firstFetchDataRef.current = false;
                    setFetchTenantIdsExecuting(false);
                    return true;
                }
        });

    const [selectedTenantIdsValidationController, selectedTenantIdsValidationMessage] =
        useInputValidation(
            () => {
                if (_.isEmpty(selectedTenantIds)) {
                    return undefined;
                }

                if (selectedTenantIds.length > maxTenantCount) {
                    return localization.error.length({ maxTenantCount });
                }

                return undefined;
            },
            [selectedTenantIds]);

    const selectedTenantIdsDeepDependency = useDeepDependency(selectedTenantIds);
    useEffect(
        () => {
            setAddContext(
                addContext => ({
                    ...addContext,
                    additionalFieldNameToValidMap: {
                        ...addContext.additionalFieldNameToValidMap,
                        permissionAssignmentRequestTenantIds:
                            !_.isEmpty(selectedTenantIds) &&
                            selectedTenantIdsValidationController.isValid()
                    }
                }));
        },
        [selectedTenantIdsDeepDependency]);

    const theme = useTheme();
    return (
        <Fragment>
            {!_.isNil(selectedTenantIdsValidationMessage) && (
                <FormHelperText error={true}>
                    {selectedTenantIdsValidationMessage}
                </FormHelperText>)}
            <TenantMultiSelect
                disabled={
                    disabled ||
                    fetchTenantIdsExecuting ||
                    _.isNil(tenantIds)}
                displayAllAsEmpty={false}
                dropdownIcon={
                    fetchTenantIdsExecuting
                        ? <CircularProgress
                            size={theme.spacing(2)}
                            variant="indeterminate"/>
                        : undefined}
                emptyValue={localization.emptyValue()}
                fullWidth={true}
                placeholder={localization.title()}
                selectedTenantIds={selectedTenantIds}
                tenantIds={tenantIds ?? []}
                variant="itemSelector"
                onSelectedTenantIdsChanged={selectedTenantIds => setAddContextTenantIds(selectedTenantIds)}/>
        </Fragment>);
}