﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, Entity, entityModelStore, useEntityTypeNameTranslator } from "../../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../../useCommonSectionsAndDescriptionDefinition";
import { useGetKubernetesResourceRiskContext } from "../contexts";

export function useKubernetesNamespaceRestrictedPodSecurityStandardNotExistRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.KubernetesNamespaceRestrictedPodSecurityStandardNotExistRisk;
    const namespaceModel = entityModelStore.useGet(risk.entityId);
    const namespace = _.as<Contract.IKubernetesNamespace>(namespaceModel.entity);

    const getKubernetesResourceRiskContext = useGetKubernetesResourceRiskContext();

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.kubernetes.hooks.resources.namespaces.useKubernetesNamespaceRestrictedPodSecurityStandardNotExistRiskDefinition",
            () => ({
                contextItems: {
                    podSecurityPolicy: {
                        [Contract.KubernetesNamespaceDataPodSecurityStandard.Baseline]: "The {{translatedEntityTypeName}} has a Baseline Pod Security Standard policy configured",
                        [Contract.KubernetesNamespaceDataPodSecurityStandard.None]: "The {{translatedEntityTypeName}} doesn't have a Pod Security Standard policy configured",
                        [Contract.KubernetesNamespaceDataPodSecurityStandard.Privileged]: "The {{translatedEntityTypeName}} has a Privileged Pod Security Standard policy configured"
                    }
                },
                description: "{{kubernetesNamespace}} does not have a **Restricted** policy configured",
                resolutionSection: {
                    step1: "Add a **Restricted** Pod Security Standard policy to the {{kubernetesNamespace}}"
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            kubernetesNamespace:
                <Entity
                    entityIdOrModel={namespaceModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            localization.resolutionSection.step1({
                kubernetesNamespace:
                    <Entity
                        entityIdOrModel={namespaceModel}
                        variant="typeText"/>
            })
        ],
        riskModel,
        () => {
            const namespaceRiskContext = getKubernetesResourceRiskContext(namespaceModel);
            return [
                namespaceRiskContext.generalInformation,
                namespaceRiskContext.sensitive,
                namespaceRiskContext.relatedResources,
                new RiskDefinitionContextItem(
                    localization.contextItems.podSecurityPolicy[risk.podSecurityStandard as Exclude<Contract.KubernetesNamespaceDataPodSecurityStandard, Contract.KubernetesNamespaceDataPodSecurityStandard.Restricted>]({
                        translatedEntityTypeName:
                            entityTypeNameTranslator(
                                namespace.typeName,
                                {
                                    includeServiceName: false,
                                    variant: "text"
                                })
                    })),
                namespaceRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        });
}