import { Link, TextViewer, useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { CustomerConsoleAppUrlHelper, useTheme } from "../../../../../../../../../../../../../../../../../../../common";
import { useAddOrEditContext } from "../../../../../AddOrEdit";

export function ScanContainerImageStageItem() {
    const { apiRootUrl } = useAddOrEditContext();

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCodePipelineItems.codePipeline.addOrEdit.hooks.useDefinition.hooks.useJenkinsPipeline.scanContainerImageStageItem",
            () => ({
                description: "Add the following stage to your Jenkins pipeline, adjusting it as necessary for your environment. The **TENABLE_API_TOKEN** field is sensitive, and should be treated as a secret.",
                note1: {
                    link: "Learn more",
                    text: "It's recommended to run the scan within a Git repository. If for some reason this isn't possible, additional variables must be included. {{docsLink}}"
                },
                note2: {
                    links: {
                        documentation: "product docs",
                        native: "native docker agent",
                        plugin: "Docker Pipelines plugin"
                    },
                    text: "The above example assumes that you are using the {{pluginLink}}. For examples using a {{nativeLink}} (available in Jenkins Pipeline version 2.5 and above), please refer to the {{documentationLink}}"
                },
                pipeline: {
                    gitUrl: "<PLACE YOUR REPOSITORY URL HERE>",
                    name: "<place your image name here>"
                }
            }));

    const pipelineText =
        _.join([
            `pipeline {`,
            `   agent any`,
            `    stages {`,
            `        stage('Checkout') {`,
            `            steps {`,
            `                git '${localization.pipeline.gitUrl()}'`,
            `            }`,
            `        }`,
            `        stage('Scan container image with Tenable Cloud Security') {`,
            `            environment {`,
            `                TENABLE_API_TOKEN = credentials('tenable-api-token')`,
            `                TENABLE_API_URL = '${apiRootUrl}'`,
            `                TENABLE_CODE_BRANCH = "$\{env.CHANGE_BRANCH ?: env.GIT_BRANCH}"`,
            `                TENABLE_CODE_COMMIT_HASH = "$\{env.GIT_COMMIT}"`,
            `                TENABLE_CODE_COMMIT_USER = "$\{env.CHANGE_AUTHOR}"`,
            `                TENABLE_PIPELINE_RUN_ID = "$\{env.BUILD_ID}"`,
            `                TENABLE_PIPELINE_RUN_TRIGGER = "$\{currentBuild.getBuildCauses()[0].shortDescription}"`,
            `                TENABLE_PIPELINE_RUN_URL = "$\{env.BUILD_URL}"`,
            `            }`,
            `            steps {`,
            `                script {`,
            `                        docker.image('tenable/cloud-security-scanner:latest').inside("--entrypoint='' -u 0 --pull=always") {`,
            `                            sh 'tenable container-image scan \\`,
            `                                --name ${localization.pipeline.name()} \\`,
            `                                --no-color \\`,
            `                                --output-file-formats JUnit \\`,
            `                                --output-path .'`,
            `                            junit skipPublishingChecks: true, testResults: 'results.junit.xml'`,
            `                           }`,
            `                    }`,
            `                }`,
            `            }`,
            `        }`,
            `    }`],
        "\n");

    const theme = useTheme();
    return (
        <Stack spacing={2}>
            <Typography sx={{ maxWidth: theme.spacing(60) }}>
                {localization.description()}
            </Typography>
            <TextViewer
                copyToClipboard={true}
                format="groovy"
                text={pipelineText}/>
            <Typography sx={{ maxWidth: theme.spacing(65) }}>
                {localization.note1.text({
                    docsLink:
                        <Link
                            sx={{ textDecorationLine: "underline" }}
                            urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsContainerImageCICDScanningAddJenkinsPipelineAdditionalVariableForNonGitRepositoriesRelativeUrl()}
                            variant="external">
                            {localization.note1.link()}
                        </Link>
                })}
            </Typography>
            <Typography sx={{ maxWidth: theme.spacing(60) }}>
                {localization.note2.text({
                    documentationLink:
                        <Link
                            sx={{ textDecorationLine: "underline" }}
                            urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsContainerImageCICDScanningAddJenkinsPipelineRelativeUrl()}
                            variant="external">
                            {localization.note2.links.documentation()}
                        </Link>,
                    nativeLink:
                        <Link
                            sx={{ textDecorationLine: "underline" }}
                            urlOrGetUrl="https://www.jenkins.io/doc/book/pipeline/docker"
                            variant="external">
                            {localization.note2.links.native()}
                        </Link>,
                    pluginLink:
                        <Link
                            sx={{ textDecorationLine: "underline" }}
                            urlOrGetUrl="https://plugins.jenkins.io/docker-workflow"
                            variant="external">
                            {localization.note2.links.plugin()}
                        </Link>
                })}
            </Typography>
        </Stack>);
}