import { SvgIcon, SvgIconProps, useTheme } from "@mui/material";
import React from "react";

type DownloadIconProps =
    SvgIconProps & {
        animated?: boolean;
    };

export function DownloadIcon({ animated, ...props }: DownloadIconProps) {
    const theme = useTheme();
    return (
        <SvgIcon {...props}>
            <defs>
                <linearGradient
                    gradientUnits="userSpaceOnUse"
                    id="DownloadIcon_gradient"
                    x1="0"
                    x2="0"
                    y1="0"
                    y2="100%">
                    <stop
                        offset="1"
                        stopColor={theme.palette.link.primary}>
                        <animate
                            attributeName="offset"
                            begin="0; fill.end"
                            dur="0.8s"
                            from="0"
                            id="clear"
                            to="1"/>
                        <animate
                            attributeName="offset"
                            begin="clear.end"
                            dur="0"
                            from="0"
                            id="clearReset"
                            to="0"/>
                    </stop>
                    <stop stopColor={theme.palette.action.active}>
                    </stop>
                    <stop
                        offset="1"
                        stopColor={theme.palette.action.active}>
                        <animate
                            attributeName="offset"
                            begin="clear.end"
                            dur="0.8s"
                            from="0"
                            id="fill"
                            to="1"/>
                    </stop>
                    <stop stopColor={theme.palette.link.primary}>
                    </stop>
                </linearGradient>
            </defs>
            <g fill={
                animated
                    ? "url(#DownloadIcon_gradient)"
                    : undefined}>
                <path d="M14.2757 12H12.5V4C12.5 3.44772 12.0523 3 11.5 3C10.9477 3 10.5 3.44772 10.5 4V12H8.72426C8.45699 12 8.32314 12.3231 8.51213 12.5121L11.2879 15.2879C11.405 15.405 11.595 15.405 11.7121 15.2879L14.4879 12.5121C14.6769 12.3231 14.543 12 14.2757 12Z"/>
                <path d="M18 19C18 18.4477 17.5523 18 17 18H7C6.44772 18 6 18.4477 6 19C6 19.5523 6.44771 20 7 20H17C17.5523 20 18 19.5523 18 19Z"/>
            </g>
        </SvgIcon>);
}