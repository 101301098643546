import { Action1, Optional, useInputValidation, useLocalization } from "@infrastructure";
import { Box, FormHelperText, Stack, TextField, Typography } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Contract, CustomRiskPolicy, CustomRiskPolicyActions, SeveritySelector, useTheme } from "../../../../../common";

type CustomRiskPolicyTemplateProps = {
    customRiskPolicyActionsRef: React.MutableRefObject<CustomRiskPolicyActions | undefined>;
    customRiskPolicyConfiguration?: Contract.CustomRiskPolicyConfiguration;
    inputOptions?: CustomRiskPolicyTemplateInputOptions;
    onValidChange: Action1<boolean>;
    riskPolicyConfigurationTypeName: string;
    scopeId: string;
};

type CustomRiskPolicyTemplateInputOptions = {
    description: CustomRiskPolicyTemplateInputOptionsProperty<Optional<string>>;
    name: CustomRiskPolicyTemplateInputOptionsProperty<string>;
    severity: CustomRiskPolicyTemplateInputOptionsProperty<Contract.Severity>;
};

type CustomRiskPolicyTemplateInputOptionsProperty<T> = {
    defaultValue?: T;
    onChange: Action1<T>;
};

export function CustomRiskPolicyTemplate({ customRiskPolicyActionsRef, customRiskPolicyConfiguration, inputOptions, onValidChange, riskPolicyConfigurationTypeName, scopeId }: CustomRiskPolicyTemplateProps) {
    const [description, setDescription] = useState(inputOptions?.description.defaultValue ?? customRiskPolicyConfiguration?.description);
    const [name, setName] = useState(inputOptions?.name.defaultValue ?? customRiskPolicyConfiguration?.name ?? "");
    const [severity, setSeverity] = useState(inputOptions?.severity.defaultValue ?? customRiskPolicyConfiguration?.severity ?? Contract.Severity.Information);
    const [valid, setValid] = useState(false);

    const [nameValidationController, nameValidationMessage] =
        useInputValidation(
            () => {
                const validationName = name.trim();
                if (_.isEmpty(validationName)) {
                    return localization.fields.name.error.required();
                }

                if (validationName.length > 120) {
                    return localization.fields.name.error.length();
                }

                return undefined;
            },
            [name]);

    useEffect(
        () => {
            onValidChange(
                nameValidationController.isValid() &&
                valid);
        },
        [name, valid]);

    useEffect(
        () => {
            inputOptions?.name.onChange(name);
        },
        [name]);

    useEffect(
        () => {
            inputOptions?.severity.onChange(severity);
        },
        [severity]);

    useEffect(
        () => {
            inputOptions?.description.onChange(description);
        },
        [description]);

    const localization =
        useLocalization(
            "views.customer.riskPolicies.customRiskPolicyTemplate",
            () => ({
                description: "Description (optional)",
                fields: {
                    name: {
                        error: {
                            length: "Name cannot be longer than 120 characters",
                            required: "Name cannot be empty"
                        }
                    }
                },
                name: "Name",
                severity: "Severity"
            }));

    const theme = useTheme();
    return (
        <Stack spacing={4}>
            <Stack
                spacing={3}
                sx={{ width: "100%" }}>
                <Stack spacing={2}>
                    <TextField
                        label={localization.name()}
                        sx={{ width: "100%" }}
                        value={name}
                        variant="outlined"
                        onChange={event => setName(event.target.value)}/>
                    {!_.isNil(nameValidationMessage) &&
                        <FormHelperText error={true}>
                            {nameValidationMessage}
                        </FormHelperText>}
                    <TextField
                        label={localization.description()}
                        sx={{ width: "100%" }}
                        value={description}
                        variant="outlined"
                        onChange={
                            event =>
                                setDescription(
                                    _.isEmpty(event.target.value)
                                        ? undefined
                                        : event.target.value)}/>
                </Stack>
                <Stack
                    alignItems="center"
                    direction="row"
                    spacing={1}>
                    <Typography sx={{ color: theme.palette.text.secondary }}>
                        {localization.severity()}
                    </Typography>
                    <SeveritySelector
                        selectedSeverity={severity}
                        sx={{ borderRadius: theme.spacing(0.75) }}
                        variant="outlined"
                        onSelectedSeverityChanged={setSeverity}/>
                </Stack>
            </Stack>
            <Box sx={{ width: "100%" }}>
                <CustomRiskPolicy
                    editOptions={{
                        actionsRef: customRiskPolicyActionsRef,
                        onValidChange: setValid
                    }}
                    riskPolicyConfiguration={customRiskPolicyConfiguration}
                    riskPolicyConfigurationTypeName={riskPolicyConfigurationTypeName}
                    scopeId={scopeId}/>
            </Box>
        </Stack>);
}