﻿import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function PolandIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <g clipPath="url(#clip0_60203_5279)">
                <rect
                    fill="white"
                    height="15.9638"
                    rx="2"
                    width="24"
                    y="4"/>
                <path
                    d="M24 4.00012H0V19.9639H24V4.00012Z"
                    fill="white"/>
                <path
                    clipRule="evenodd"
                    d="M22 5.00012H2C1.44772 5.00012 1 5.44784 1 6.00012V17.9639C1 18.5162 1.44772 18.9639 2 18.9639H22C22.5523 18.9639 23 18.5162 23 17.9639V6.00012C23 5.44784 22.5523 5.00012 22 5.00012ZM2 4.00012H22C23.1046 4.00012 24 4.89555 24 6.00012V17.9639C24 19.0685 23.1046 19.9639 22 19.9639H2C0.89543 19.9639 0 19.0685 0 17.9639V6.00012C0 4.89555 0.895431 4.00012 2 4.00012Z"
                    fill="#BAC5D3"
                    fillRule="evenodd"/>
                <path
                    d="M24 11.9821H0V19.964H24V11.9821Z"
                    fill="#DC143C"/>
            </g>
            <defs>
                <clipPath id="clip0_60203_5279">
                    <rect
                        fill="white"
                        height="15.9638"
                        rx="2"
                        width="24"
                        y="4"/>
                </clipPath>
            </defs>
        </SvgIcon>);
}