﻿import { useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import React from "react";
import { Contract } from "../../../../../../../controllers";
import { useEntityTypeNameTranslator } from "../../../../../../../hooks";
import { EntityTypeNameSelector } from "../../../../../../EntityTypeNameSelector";
import { EntityMultiSelectProperty, useMultiSelectorContext, useSetMultiSelectorContext } from "../../../EntityMultiSelect";

type TypeNamesProps = {
    disabled?: boolean;
    entityTypeName: string;
    entityTypeNameVariant?: "name" | "viewName";
    includeServiceName: boolean;
    permissionEvaluationEntities?: boolean;
};

export function TypeNames({ disabled, entityTypeName, entityTypeNameVariant = "viewName", includeServiceName, permissionEvaluationEntities = false }: TypeNamesProps) {
    const { propertyIdToConditionMap } = useMultiSelectorContext();
    const condition = propertyIdToConditionMap[EntityMultiSelectProperty.TypeNames] as Contract.EntityTypeNameMatchCondition;
    const setMultiSelectorContext = useSetMultiSelectorContext();
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "common.customRiskPolicy.entityMultiSelect.condition.typeNames",
            () => ({
                title: "*capitalize*{{translatedEntityTypeName}}** from any of these types"
            }));

    return (
        <Stack
            alignItems="center"
            direction="row"
            spacing={1}>
            <Typography>
                {localization.title({
                    translatedEntityTypeName:
                        entityTypeNameTranslator(
                            entityTypeName,
                            {
                                count: 0,
                                includeServiceName,
                                variant: "text"
                            })
                })}
            </Typography>
            <EntityTypeNameSelector
                disabled={disabled}
                entityTypeNames={[entityTypeName]}
                entityTypeNameVariant={entityTypeNameVariant}
                multiSelect={true}
                permissionEvaluationEntities={permissionEvaluationEntities}
                selectedEntityTypeNames={condition.entityTypeNames}
                onSelectedItemsChanged={
                    typeNames =>
                        setMultiSelectorContext(
                            context => ({
                                propertyIdToConditionMap: {
                                    ...context.propertyIdToConditionMap,
                                    [EntityMultiSelectProperty.TypeNames]: new Contract.EntityTypeNameMatchCondition(typeNames)
                                }
                            }))}/>
        </Stack>);
}