import { Dialog, getUrlQueryParameters, makeContextProvider, StringHelper, useRemoveQueryParameters } from "@infrastructure";
import React from "react";
import { Contract } from "../../../../../../../../../../../../common";
import { CodeServerQueryParameters } from "../../useCodeServerItems";
import { AddOrEditGitLabServer, Table } from "./components";

export class GitLabServerContext {
    constructor(
        public addOrEditOpen: boolean | Contract.CodeServerModel) {
    }
}

export const [useGitLabServerContext, useSetGitLabServerContext, useGitLabServerContextProvider] = makeContextProvider<GitLabServerContext>();

export function GitLabServer() {
    const removeQueryParameters = useRemoveQueryParameters();
    const [context, setContext, ContextProvider] =
        useGitLabServerContextProvider(
            () => {
                const queryParameters = getUrlQueryParameters<CodeServerQueryParameters>();
                removeQueryParameters("addOrEditOpen");
                return new GitLabServerContext(StringHelper.isTrue(queryParameters.addOrEditOpen));
            });

    return (
        <ContextProvider>
            {context.addOrEditOpen !== false &&
                <Dialog
                    size="large"
                    variant="editor"
                    onClose={
                        () =>
                            setContext(
                                context => ({
                                    ...context,
                                    addOrEditOpen: false
                                }))}>
                    <AddOrEditGitLabServer/>
                </Dialog>}
            <Table/>
        </ContextProvider>);
}