import { IconText, NumberFormatter, useLocalization } from "@infrastructure";
import { Divider, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract } from "../../../../../../../../../controllers";
import { usePrincipalTypeTranslator } from "../../../../../../../../../hooks";
import { PrincipalIcon } from "../../../../../../../../../icons";
import { useTheme } from "../../../../../../../../../themes";
import { CustomerConsoleAppUrlHelper, TypeHelper } from "../../../../../../../../../utilities";
import { Link } from "../../../../../../../../Entity/components";
import { Severity } from "../../../../../../../../Severity";

interface PrincipalProps {
    openRiskSeverityToPrincipalCountMap: _.Dictionary<number>;
    permissionPrincipalCount: number;
    principalRiskCategory: Contract.PrincipalRiskCategory;
    principalType: Contract.PrincipalType;
    privileged: number;
}

export function Principal({ openRiskSeverityToPrincipalCountMap, permissionPrincipalCount, principalRiskCategory, principalType, privileged }: PrincipalProps) {
    const principalTypeTranslator = usePrincipalTypeTranslator();
    const [highestPrivileged, principalCount] =
        useMemo(
            () => {
                const principalCount =
                    _(openRiskSeverityToPrincipalCountMap).
                        values().
                        sum();
                const highestPrivileged =
                    _(Contract.Severity).
                        values().
                        filter(severity => openRiskSeverityToPrincipalCountMap[severity] > 0).
                        orderBy(
                            severity => TypeHelper.getEnumValue(Contract.TypeNames.Severity, severity),
                            "desc").
                        first();
                return [highestPrivileged, principalCount];
            },
            [openRiskSeverityToPrincipalCountMap]);

    const localization =
        useLocalization(
            "common.dashboard.widget.hooks.useDefinition.hooks.useGetPrincipalsDefinition.principal",
            () => ({
                empty: "0",
                overprivileged: "Overprivileged ({{overprivileged | NumberFormatter.humanize }})",
                privileged: "Privileged"
            }));

    const theme = useTheme();
    return (
        <Stack
            className={`principal ${principalType}`}
            justifyContent="space-around"
            sx={{
                border: theme.border.primary,
                borderRadius: theme.spacing(0.75),
                minWidth: theme.spacing(25),
                padding: theme.spacing(1.5, 2)
            }}>
            <Stack>
                <IconText
                    icon={
                        <PrincipalIcon
                            principalType={principalType}
                            sx={{ fontSize: "18px" }}/>}
                    text={
                        <Typography
                            noWrap={true}
                            sx={{
                                fontSize: "16px",
                                fontWeight: 600
                            }}>
                            {NumberFormatter.humanize(permissionPrincipalCount)} {principalTypeTranslator(principalType)}
                        </Typography>}/>
            </Stack>
            <Divider sx={{ margin: theme.spacing(2, 0) }}/>
            <Stack
                flexDirection="row"
                justifyContent="space-between"
                sx={{ marginLeft: theme.spacing(1) }}>
                <Stack
                    sx={{
                        borderRight: theme.border.primary,
                        paddingRight: theme.spacing(1),
                        width: "33%"
                    }}>
                    <Typography noWrap={true}>
                        {localization.privileged()}
                    </Typography>
                    <Typography
                        noWrap={true}
                        sx={{
                            fontSize: "16px",
                            fontWeight: 600
                        }}>
                        {NumberFormatter.humanize(privileged)}
                    </Typography>
                </Stack>
                <Stack
                    sx={{
                        marginLeft: theme.spacing(2),
                        width: "66%"
                    }}>
                    <Typography noWrap={true}>
                        {localization.overprivileged({ overprivileged: principalCount })}
                    </Typography>
                    {highestPrivileged
                        ? <Link
                            sx={{ color: theme.palette.text.primary }}
                            urlOrGetUrl={
                                CustomerConsoleAppUrlHelper.getPrincipalExcessivePermissionsRelativeUrl(
                                    principalRiskCategory,
                                    { severities: [highestPrivileged] })}
                            variant="text">
                            <Stack
                                direction="row"
                                spacing={1}>
                                <Typography
                                    noWrap={true}
                                    sx={{
                                        fontSize: "16px",
                                        fontWeight: 600
                                    }}>
                                    {NumberFormatter.humanize(openRiskSeverityToPrincipalCountMap[highestPrivileged])}
                                </Typography>
                                <Severity severity={highestPrivileged}/>
                            </Stack>
                        </Link>
                        : <Typography
                            sx={{
                                fontSize: "16px",
                                fontWeight: 600
                            }}>
                            {localization.empty()}
                        </Typography>}
                </Stack>
            </Stack>
        </Stack>);
}