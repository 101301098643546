import { TextViewer, useLocalization } from "@infrastructure";
import { Box } from "@mui/material";
import _ from "lodash";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../..";
import { Contract, InfoItem, useTheme } from "../../../../../../../../../../../common";
import { useGciDirectoryDirectoryRoleTypeTranslator } from "../../../../../../../hooks";
import { Info } from "../../../../../components";
import { useGciDefaultEntityInfoItemElements } from "../useGciDefaultEntityInfoItemElements";
import { DirectoryRoleAssignments } from "./components";

export function useGciDirectoryDirectoryRoleDefinition(entityModel: Contract.GciEntityModel, options?: DefinitionOptions) {
    const defaultEntityInfoItemElements = useGciDefaultEntityInfoItemElements(entityModel);
    const directoryRole = entityModel.entity as Contract.GciDirectoryDirectoryRole;

    const directoryDirectoryRoleTypeTranslator = useGciDirectoryDirectoryRoleTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gci.useGciDirectoryDirectoryRoleDefinition",
            () => ({
                info: {
                    cards: {
                        rawRole: "Definition"
                    },
                    items: {
                        description: "Description",
                        type: "Role Type"
                    }
                },
                tabs: {
                    definition: "Definition",
                    directoryRoleAssignments: "Assignments"
                }
            }));

    const theme = useTheme();
    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <DirectoryRoleAssignments directoryRoleModel={entityModel as Contract.GciDirectoryDirectoryRoleModel}/>,
                localization.tabs.directoryRoleAssignments(),
                "directoryRoleAssignments"),
            new EntityProfileDefinitionTab(
                ProfileCategory.Overview,
                <Box sx={{ paddingTop: theme.spacing(1.5) }}>
                    <TextViewer
                        format="json"
                        text={directoryRole.rawRole}/>
                </Box>,
                localization.tabs.definition(),
                "definition")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={[]}
                defaultTenantInfoItemElements={defaultEntityInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="description"
                        location="all"
                        title={localization.info.items.description()}
                        value={
                            _.isEmpty(directoryRole.description)
                                ? undefined
                                : directoryRole.description}/>,
                    <InfoItem
                        key="type"
                        location="all"
                        title={localization.info.items.type()}
                        value={directoryDirectoryRoleTypeTranslator(directoryRole.type)}/>
                ]}
                options={options?.infoOptions}>
            </Info>,
        sensitive: false
    });
}