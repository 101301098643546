import { Step, Steps, useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../..";
import { Contract, Entity, entityModelStore, InlineEntities } from "../../../../../../../../../../../../common";
import { EntityExternalConsoleLink } from "../../components";
import { useGetGcpComputeVpcRiskContext } from "../contexts";

export function useGcpComputeVpcFlowLogsNotEnabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.GcpComputeVpcFlowLogsNotEnabledRisk;
    const vpcModel = entityModelStore.useGet(risk.entityId) as Contract.GcpComputeVpcModel;

    const getGcpComputeVpcRiskContext = useGetGcpComputeVpcRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.useGcpComputeVpcFlowLogsNotEnabledRiskDefinition",
            () => ({
                description: "{{vpc}} doesn’t have Flow Logs",
                sections: {
                    resolution: {
                        step1: {
                            step1: "Search for and select the misconfigured subnet",
                            step2: "Click **EDIT**",
                            step3: "In the **Flow Logs** section, set it to **On**",
                            step4: "Click **CONFIGURE LOGS**",
                            step5: "Set **Aggregation Interval** to **5 SEC**",
                            step6: "For **Additional fields**, check **Metadata annotations**",
                            step7: "Set **Sample rate** to **100**",
                            step8: "Click **SAVE**",
                            title: "Repeat the following steps for each {{subnets}}:"
                        }
                    }
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            vpc:
                <Entity
                    entityIdOrModel={vpcModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={risk.entityId}
                key={risk.entityId}
                page={Contract.GcpConsolePage.VpcSubnets}/>,
            new Step(
                localization.sections.resolution.step1.title({
                    subnets:
                        <InlineEntities
                            entityIdsOrModels={risk.flowLogsNotEnabledSubnetIds}
                            entityTypeName={Contract.TypeNames.GcpComputeSubnet}
                            variant="itemCountAndType"/>
                }),
                {
                    contentElement:
                        <Steps variant="plainNumbers">
                            {localization.sections.resolution.step1.step1()}
                            {localization.sections.resolution.step1.step2()}
                            {localization.sections.resolution.step1.step3()}
                            {localization.sections.resolution.step1.step4()}
                            {localization.sections.resolution.step1.step5()}
                            {localization.sections.resolution.step1.step6()}
                            {localization.sections.resolution.step1.step7()}
                            {localization.sections.resolution.step1.step8()}
                        </Steps>
                })
        ],
        riskModel,
        () => {
            const vpcRiskContext = getGcpComputeVpcRiskContext(vpcModel);
            return [
                vpcRiskContext.generalInformation,
                vpcRiskContext.subnets,
                vpcRiskContext.sensitive,
                vpcRiskContext.sensitiveSubnets,
                vpcRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}