import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../../../../../../../../../common";

export function usePingIdentityTenantModelStatusTranslator() {
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useIdentityProviderTenantItems.pingIdentityTenants.hooks.usePingIdentityTenantModelStatusTranslator",
            () => ({
                [Contract.TypeNames.PingIdentityTenantModelStatus]: {
                    [Contract.PingIdentityTenantModelStatus.ClientAuthenticationFailure]: "Disconnected",
                    [Contract.PingIdentityTenantModelStatus.Deleting]: "Deleting, this may take a while...",
                    [Contract.PingIdentityTenantModelStatus.Valid]: "Connected"
                }
            }));
    return (status: Contract.PingIdentityTenantModelStatus) =>
        localization[Contract.TypeNames.PingIdentityTenantModelStatus][status]();
}