import { Contract } from "../controllers";
import { AwsPartitionHelper } from "./awsPartitionHelper";

export class AwsResourceArnParser {
    public static getPartitionType(arn: string): Contract.AwsPartitionType {
        return AwsPartitionHelper.getPartitionType(arn.split(":")[1]);
    }

    public static tryGetCloudTrailTrailName(arn: string): any {
        return arn.match(/arn:.+:trail\/(?<trailName>.+)/)?.groups?.trailName;
    }

    public static tryGetTenantId(arn: string): any {
        return arn.match(/arn:.+:.+:.*:(?<accountId>\d{12}):/)?.groups?.accountId;
    }

    public static validateIamRole(arn: string): boolean {
        return /^arn:(aws|aws-cn|aws-us-gov):iam::\d{12}:role\/.*/.test(arn);
    }

    public static validateKmsKey(arn: string): boolean {
        return (
            /arn:(aws|aws-us-gov|aws-cn):kms:[^:]+:\d+:key\/[0-9A-Fa-f\-]{36}/i.test(arn) ||
            /arn:(aws|aws-us-gov|aws-cn):kms:[^:]+:\d+:key\/mrk-[0-9A-Fa-f]{32}/i.test(arn));
    }
}