﻿import { AnalyticsContext, AnalyticsEventActionType, map, useTrackAnalyticsEvent } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, CustomerConsoleAppUrlHelper, LicensingHelper, useApplicationViewRoute } from "../../../../common";
import { CustomerView } from "../../hooks";
import { Code, ContainerImages, MaliciousFiles, Onboarding, Packages, VirtualMachineImages, VirtualMachines, Vulnerabilities } from "./components";
import { WorkloadAnalysisView } from "./hooks";

export function WorkloadAnalysis() {
    const [{ view }] =
        useApplicationViewRoute(
            CustomerConsoleAppUrlHelper.getTemplatePath(CustomerView.WorkloadAnalysis),
            _.values(WorkloadAnalysisView),
            WorkloadAnalysisView.VirtualMachines);

    return (
        <AnalyticsContext context={view}>
            <View view={view}/>
        </AnalyticsContext>);
}

type ViewProps = {
    view: WorkloadAnalysisView;
};

function View({ view }: ViewProps) {
    useTrackAnalyticsEvent(AnalyticsEventActionType.PageView);

    return LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.Cnapp)
        ? map(
            view,
            {
                [WorkloadAnalysisView.CodeScans]: () => <Code/>,
                [WorkloadAnalysisView.ContainerImages]: () => <ContainerImages/>,
                [WorkloadAnalysisView.MaliciousFiles]: () => <MaliciousFiles/>,
                [WorkloadAnalysisView.Packages]: () => <Packages/>,
                [WorkloadAnalysisView.Vulnerabilities]: () => <Vulnerabilities/>,
                [WorkloadAnalysisView.VirtualMachines]: () => <VirtualMachines/>,
                [WorkloadAnalysisView.VirtualMachineImages]: () => <VirtualMachineImages/>
            })
        : <Onboarding/>;
}