import { Link, TextViewer, useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { CustomerConsoleAppUrlHelper, useTheme } from "../../../../../../../../../../../../../../../../../../../common";

export function RunSyncIacItem() {
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCodePipelineItems.codePipeline.addOrEdit.hooks.useDefinition.hooks.useGeneralCodePipeline.runSyncIacItem",
            () => ({
                description: "To sync your Cloud to Code, and remediate cloud findings at source, add the following step to your pipeline after the 'terraform apply' step (adjust as needed):",
                flags: {
                    envFile: "**--env-file**: The path in which the **environment.variables** file you created in the previous step is located",
                    volume: "**--volume**: Change the left side (before the \":\") to the path in which the code is located; keep the right side as is"
                },
                note1: {
                    link: "Learn more",
                    text: "It's recommended to run the sync within a Git repository. If for some reason this isn't possible, additional variables must be included. {{docsLink}}"
                },
                note2: {
                    link: "product documentation",
                    text: "Once the sync is triggered, you should start seeing resources/findings tagged with the IaC label in Tenable Cloud Security. Relevant resources will have an IaC tab in the Inventory view, showing the relevant code snippet. Please refer to the {{docsLink}} for more details"
                }
            }));

    const theme = useTheme();
    return (
        <Stack spacing={2}>
            <Typography sx={{ maxWidth: theme.spacing(60) }}>
                {localization.description()}
            </Typography>
            <TextViewer
                copyToClipboard={true}
                format="shell"
                text={
                    _.join(
                        [
                            `terraform state pull > terraform.tfstate`,
                            `docker run --workdir /tmp/tenable --volume /home/user/code:/tmp/tenable --env-file environment.variables --pull=always tenable/cloud-security-scanner:latest iac sync terraform`
                        ],
                        "\n")}/>
            <Stack sx={{ maxWidth: theme.spacing(80) }}>
                <Typography>
                    {localization.flags.envFile()}
                </Typography>
                <Typography>
                    {localization.flags.volume()}
                </Typography>
            </Stack>
            <Typography sx={{ maxWidth: theme.spacing(65) }}>
                {localization.note1.text({
                    docsLink:
                        <Link
                            sx={{ textDecorationLine: "underline" }}
                            urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsConnectYourCICDPipelineAddDifferentPipelineAdditionalVariablesForNonGitRepositoriesRelativeUrl()}
                            variant="external">
                            {localization.note1.link()}
                        </Link>
                })}
            </Typography>
            <Typography sx={{ maxWidth: theme.spacing(65) }}>
                {localization.note2.text({
                    docsLink:
                        <Link
                            sx={{ textDecorationLine: "underline" }}
                            urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsConnectYourCICDPipelineAddDifferentPipelineRelativeUrl()}
                            variant="external">
                            {localization.note2.link()}
                        </Link>
                })}
            </Typography>
        </Stack>);
}