import { Dropdown, IconText, NoneIcon, SearchList, useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, IgnoredIcon, RiskPoliciesType, riskPolicyItemConfigurationOperationStore, useTheme } from "../../../../../common";

type CodeExclusionsProps = {
    riskPoliciesType: RiskPoliciesType;
    riskPolicyConfigurationTypeNameOrId: string;
    scopeId: string;
};

export function CodeExclusions({ riskPoliciesType, riskPolicyConfigurationTypeNameOrId, scopeId }: CodeExclusionsProps) {
    const codeRiskConfigurationModel = riskPolicyItemConfigurationOperationStore.useGet(riskPolicyConfigurationTypeNameOrId, scopeId, false, riskPoliciesType);
    const codeExclusions =
        useMemo(
            () =>
                _.flatMap(
                    [...codeRiskConfigurationModel.parentRiskPolicyModels, codeRiskConfigurationModel.scopeRiskPolicyModel],
                    policiesModel => policiesModel.riskPolicyConfiguration.codeExclusions),
            [codeRiskConfigurationModel]);

    const localization =
        useLocalization(
            "views.customer.riskPolicies.codeExclusions",
            () => ({
                pattern: [
                    "1 Pattern",
                    "{{ count | NumberFormatter.humanize }} Patterns"
                ]
            }));

    const theme = useTheme();
    return _.isEmpty(codeExclusions)
        ? <NoneIcon/>
        : <Stack>
            <Dropdown
                popoverElement={
                    <SearchList
                        itemOptions={{
                            getSortValue: (item: Contract.RiskPolicyConfigurationCodeExclusion) => item.fileRelativePathPattern ?? "",
                            render: (item: Contract.RiskPolicyConfigurationCodeExclusion) => <FileRelativePathPattern codeExclusion={item}/>,
                            spacing: 0.5
                        }}
                        items={codeExclusions}
                        sorted={true}
                        sx={{
                            maxWidth: "350px",
                            minWidth: "100px",
                            padding: theme.spacing(0, 1.5)
                        }}
                        variant="dropdown"/>}
                popoverElementContainerSx={{ padding: 0 }}>
                <Typography sx={{ textDecoration: "underline" }}>
                    {localization.pattern(_.size(codeExclusions))}
                </Typography>
            </Dropdown>
        </Stack>;
}

type fileRelativePathPatternProps = {
    codeExclusion: Contract.RiskPolicyConfigurationCodeExclusion;
};

function FileRelativePathPattern({ codeExclusion }: fileRelativePathPatternProps) {
    const theme = useTheme();
    return (
        <IconText
            containerSx={{ paddingRight: 0 }}
            icon={<IgnoredIcon sx={{ fontSize: "24px" }}/>}
            text={
                <Stack>
                    <Typography
                        noWrap={true}
                        sx={{ fontSize: "12px" }}>
                        {codeExclusion.fileRelativePathPattern}
                    </Typography>
                    {!_.isNil(codeExclusion.data.message) &&
                        <Typography
                            noWrap={true}
                            sx={{
                                color: theme.palette.text.secondary,
                                fontSize: "10px",
                                lineHeight: 1
                            }}>
                            {codeExclusion.data.message}
                        </Typography>}
                </Stack>}/>);
}