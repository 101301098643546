import { CheckboxField, Message, useInputValidation, useLocalization, useOrderedWizardContext } from "@infrastructure";
import { FormControl, FormHelperText, Stack, TextField, Typography } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useAddOrEditContext, useSetAddOrEditContext } from "../..";
import { useTheme } from "../../../../../../../../../../../../../../../common";
import { FolderEnabled } from "../../../../../../../../../components";

export function OrganizationDetailsItem() {
    const { executing, setValid } = useOrderedWizardContext();
    const addOrEditContext = useAddOrEditContext();
    const setAddOrEditContext = useSetAddOrEditContext();

    const [enabled, setEnabled] = useState(addOrEditContext.enabled ?? true);
    const [folderEnabled, setFolderEnabled] = useState(addOrEditContext.folderEnabled ?? true);
    const [name, setName] = useState(addOrEditContext.name ?? "");

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCloudProviderTenantOrganizationItems.azure.addOrEdit.organizationDetailsItem",
            () => ({
                fields: {
                    organizationName: {
                        error: {
                            required: "Organization name cannot be empty"
                        },
                        title: "Organization Name"
                    },
                    settings: {
                        enabled: {
                            info: "Uncheck this option to prevent new subscriptions that you add in Azure from being automatically onboarded to Tenable.",
                            title: "Automatically onboard new subscriptions"
                        },
                        folderEnabled: {
                            info: "Mirror your subscription's structure as folders within Tenable, preserving its hierarchy and keeping the view synchronized.",
                            title: "Automatically update management group structure"
                        },
                        organizationNameFolderEnabled: {
                            info: "Select this option if you are planning to onboard multiple Microsoft Entra ID tenants. This will automatically create a dedicated folder for the Microsoft Entra ID tenant in Tenable and map child entities to their respective sub-folders. If you don't plan to onboard more than one tenant, unselect the checkbox.",
                            title: "Create a dedicated folder for the Microsoft Entra ID tenant"
                        },
                        title: "Onboarding Settings"
                    }
                }
            }));

    const [organizationNameValidationController, organizationNameValidationMessage] =
        useInputValidation(
            () => {
                const validationName = name?.trim();
                if (_.isEmpty(validationName)) {
                    return localization.fields.organizationName.error.required();
                }

                return undefined;
            },
            [name]);

    useEffect(
        () => {
            setAddOrEditContext(
                addOrEditContext => ({
                    ...addOrEditContext,
                    enabled,
                    folderEnabled,
                    name: name?.trim()
                }));
            setValid(organizationNameValidationController.isValid());
        },
        [enabled, folderEnabled, name]);

    const theme = useTheme();
    return (
        <Stack
            justifyContent="space-between"
            spacing={3}
            sx={{
                height: "100%",
                maxWidth: theme.spacing(80)
            }}>
            <Stack
                spacing={3}
                sx={{ paddingBottom: theme.spacing(3) }}>
                <Stack spacing={1}>
                    <Typography variant="h4">
                        {localization.fields.organizationName.title()}
                    </Typography>
                    <FormControl
                        fullWidth={true}
                        variant="standard">
                        <TextField
                            autoFocus={true}
                            disabled={executing}
                            value={name}
                            variant="outlined"
                            onChange={event => setName(event.target.value)}/>
                        {!_.isNil(organizationNameValidationMessage) && (
                            <FormHelperText error={true}>{organizationNameValidationMessage}</FormHelperText>)}
                    </FormControl>
                </Stack>
                <Stack spacing={1}>
                    <Typography variant="h4">
                        {localization.fields.settings.title()}
                    </Typography>
                    {!_.isNil(addOrEditContext.organizationConfiguration) &&
                        <CheckboxField
                            checked={enabled}
                            onChange={() => setEnabled(!enabled)}>
                            <Stack
                                direction="row"
                                justifyItems="center"
                                spacing={1}>
                                <Typography>
                                    {localization.fields.settings.enabled.title()}
                                </Typography>
                                <Message
                                    level="info"
                                    title={localization.fields.settings.enabled.info()}
                                    variant="minimal"/>
                            </Stack>
                        </CheckboxField>}
                    <FolderEnabled
                        folderEnabled={folderEnabled}
                        info={localization.fields.settings.folderEnabled.info()}
                        setFolderEnabled={setFolderEnabled}
                        title={localization.fields.settings.folderEnabled.title()}/>
                </Stack>
            </Stack>
        </Stack>);
}