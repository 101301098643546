import { NumberFormatter, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useAwsCommonAccessResourceInfoElements, useAwsCommonEc2ResourceInfoElements, useAwsCommonEncryptedResourceInfoElements, useAwsDefaultResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { useCustomEntityPropertyInfoItemElements } from "..";
import { Contract, InfoItem, useTenantNameTranslator, VerticalTopItemsInfoItem } from "../../../../../../../../../../common";
import { EntitiesInfoItem, Info } from "../../../../components";

export function useAwsEc2SnapshotDefinition(resourceModel: Contract.AwsResourceModel, options?: DefinitionOptions) {
    const commonAccessResourceInfoElements = useAwsCommonAccessResourceInfoElements(resourceModel);
    const commonEc2ResourceInfoItemElements = useAwsCommonEc2ResourceInfoElements(resourceModel);
    const commonEncryptedResourceInfoElements = useAwsCommonEncryptedResourceInfoElements(resourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useAwsDefaultResourceInfoItemElements(resourceModel);
    const snapshot = resourceModel.entity as Contract.AwsEc2Snapshot;
    const snapshotModel = resourceModel as Contract.AwsEc2SnapshotModel;

    const tenantNameTranslator = useTenantNameTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsEc2SnapshotDefinition",
            () => ({
                info: {
                    items: {
                        awsManaged: {
                            false: "Customer Managed",
                            title: "Management",
                            true: "AWS Managed"
                        },
                        description: "Description",
                        imageIds: "Images",
                        sharedTenantRawIds: "Permissions for other AWS accounts",
                        size: "Size",
                        sourceVolume: "Source Volume",
                        sourceVolumeInstanceIds: "Instances"
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    ...commonEc2ResourceInfoItemElements,
                    ...commonAccessResourceInfoElements,
                    ...commonEncryptedResourceInfoElements,
                    <InfoItem
                        key="awsManaged"
                        title={localization.info.items.awsManaged.title()}
                        value={
                            snapshot.awsManaged
                                ? localization.info.items.awsManaged.true()
                                : localization.info.items.awsManaged.false()}/>,
                    <InfoItem
                        key="description"
                        title={localization.info.items.description()}
                        value={snapshot.description}/>,
                    <VerticalTopItemsInfoItem
                        items={
                            _.map(
                                snapshot.sharedTenantRawIds,
                                sharedTenantRawId => tenantNameTranslator(sharedTenantRawId, { includeRawId: true }))}
                        key="sharedTenantRawIds"
                        title={localization.info.items.sharedTenantRawIds()}/>,
                    <EntitiesInfoItem
                        emptyValue={snapshot.sourceVolumeRawId}
                        entityIdsOrModels={snapshot.sourceVolumeId}
                        entityTypeName={Contract.TypeNames.AwsEc2Volume}
                        key="sourceVolume"
                        title={localization.info.items.sourceVolume()}/>,
                    <InfoItem
                        key="size"
                        title={localization.info.items.size()}
                        value={NumberFormatter.storage(snapshot.size)}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={snapshotModel.sourceVolumeInstanceIds}
                        entityTypeName={Contract.TypeNames.AwsEc2Instance}
                        key="sourceVolumeInstanceIds"
                        title={localization.info.items.sourceVolumeInstanceIds()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={snapshotModel.imageIds}
                        entityTypeName={Contract.TypeNames.AwsEc2Image}
                        key="imageIds"
                        title={localization.info.items.imageIds()}/>
                ]}
                options={options?.infoOptions}>
            </Info>
    });
}