﻿import { useLocalization } from "@infrastructure";
import React from "react";
import { useAzureCommonKubernetesResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../..";
import { Contract } from "../../../../../../../../../../common";
import { KubernetesResourceDataHelper } from "../../../../../../utilities";
import { Info } from "../../../../components";
import { KubernetesRoleBindings } from "../../components";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";
import { useAzureDefaultResourceInfoItemElements } from "./useAzureDefaultResourceInfoItemElements";

export function useAzureKubernetesGroupDefinition(resourceModel: Contract.AzureResourceModel, options?: DefinitionOptions) {
    const commonKubernetesResourceInfoItemElements = useAzureCommonKubernetesResourceInfoItemElements(resourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useAzureDefaultResourceInfoItemElements(resourceModel);
    const groupModel = resourceModel as Contract.AzureKubernetesGroupModel;
    const group = groupModel.entity as Contract.AzureKubernetesGroup;

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.azure.useAzureKubernetesGroupDefinition",
            () => ({
                tabs: {
                    roleBindings: "Role Bindings"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <KubernetesRoleBindings principalOrRoleModel={groupModel}/>,
                localization.tabs.roleBindings(),
                "roleBindings")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[commonKubernetesResourceInfoItemElements.clusterInfoItemElement]}
                options={options?.infoOptions}>
            </Info>,
        sensitive: KubernetesResourceDataHelper.entitySensitiveToggle(group.data)
    });
}