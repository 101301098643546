﻿import { EmptyMessageText, ItemSelector, useLocalization } from "@infrastructure";
import { Stack } from "@mui/material";
import _ from "lodash";
import React, { Fragment, useEffect, useMemo } from "react";
import { Contract, ScopeHelper, Tenant, TenantHelper, tenantModelStore, useScopeNavigationViewContext } from "../../../../../../../../../common";
import { useAddOrEditContext, useSetAddOrEditContext } from "../../../AddOrEdit";

export function PrincipalTenant() {
    const { permissionEligibilityData: { principalTenantId }, upsertPermissionEligibilityExecuting } = useAddOrEditContext();
    const setAddOrEditContext = useSetAddOrEditContext();

    const { scopeNodeModel } = useScopeNavigationViewContext();
    const tenantType = ScopeHelper.getTenantType(scopeNodeModel)!;
    const activeTenantModels = tenantModelStore.useGetActiveTenants();
    const activeTenantTypeTenantModels = tenantModelStore.useGetActiveTenants([tenantType]);

    const principalTenantModels =
        useMemo(
            () => {
                const activeTenantModelMap =
                    _.keyBy(
                        activeTenantModels,
                        activeTenantModel => activeTenantModel.configuration.id);

                return TenantHelper.isCloudProviderTenantType(tenantType)
                    ? _(activeTenantTypeTenantModels).
                        filter(
                            activeCloudProviderTenantModel =>
                                (ScopeHelper.isFolder(scopeNodeModel) || activeCloudProviderTenantModel.configuration.id === scopeNodeModel.configuration.id) &&
                                !_.isNil((activeCloudProviderTenantModel as Contract.CloudProviderTenantModel).state.permissionManagementPrincipalTenantId) &&
                                (activeCloudProviderTenantModel as Contract.CloudProviderTenantModel).state.permissionManagementEnabled).
                        map(activeCloudProviderTenantModel => activeTenantModelMap[(activeCloudProviderTenantModel as Contract.CloudProviderTenantModel).state.permissionManagementPrincipalTenantId!]).
                        uniq().
                        filter().
                        value()
                    : TenantHelper.isIdentityProviderTenantType(tenantType)
                        ? _(activeTenantTypeTenantModels).
                            filter(
                                activeIdentityProviderTenantModel =>
                                    (ScopeHelper.isFolder(scopeNodeModel) || activeIdentityProviderTenantModel.configuration.id === scopeNodeModel.configuration.id) &&
                                    (activeIdentityProviderTenantModel.state as Contract.IdentityProviderTenantState).permissionManagementEnabled).
                            value()
                        : [];
            },
            [activeTenantModels, scopeNodeModel]);
    const principalTenantModel =
        useMemo(
            () =>
                _.size(principalTenantModels) === 1
                    ? principalTenantModels[0]
                    : _.isNil(principalTenantId)
                        ? undefined
                        : _.find(
                            principalTenantModels,
                            principalTenantModel => principalTenantModel.configuration.id === principalTenantId),
            [principalTenantId, principalTenantModels]);
    useEffect(
        () => {
            if (!_.isNil(principalTenantModel) &&
                principalTenantModel.configuration.id !== principalTenantId) {
                setAddOrEditContext(
                    addOrEditContext => {
                        addOrEditContext.permissionEligibilityData.setPrincipalTenantId(principalTenantModel.configuration.id);
                        return { ...addOrEditContext };
                    });
            }
        },
        []);

    const localization =
        useLocalization(
            "views.user.permissionEligibilities.addOrEdit.hooks.useDefinition.principalTenant",
            () => ({
                empty: "No Results",
                placeholder: "Organization",
                title: "Organization"
            }));

    useEffect(
        () => {
            setAddOrEditContext(
                addOrEditContext => ({
                    ...addOrEditContext,
                    fieldNameToValidMap: {
                        ...addOrEditContext.fieldNameToValidMap,
                        principalTenant: !_.isNil(principalTenantModel)
                    }
                }));
        },
        [principalTenantModel]);

    return (
        _.size(principalTenantModels) > 1
            ? <Stack spacing={1.5}>
                <ItemSelector
                    disabled={upsertPermissionEligibilityExecuting}
                    emptyMessageText={new EmptyMessageText(localization.empty())}
                    fieldSx={{
                        fontSize: "1.1rem"
                    }}
                    fullWidth={true}
                    getItemText={principalTenantModel => principalTenantModel.configuration.name}
                    items={principalTenantModels}
                    placeholder={localization.placeholder()}
                    selectedItem={principalTenantModel}
                    onSelectedItemChanged={
                        principalTenantModel =>
                            setAddOrEditContext(
                                addOrEditContext => {
                                    addOrEditContext.permissionEligibilityData.setPrincipalTenantId(principalTenantModel.configuration.id);
                                    return { ...addOrEditContext };
                                })}>
                    {principalTenantModel =>
                        <Tenant tenantId={principalTenantModel.configuration.id}/>}
                </ItemSelector>
            </Stack>
            : <Fragment/>);
}