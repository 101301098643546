﻿import { Step, useInputValidation, useLocalization, useOrderedWizardContext } from "@infrastructure";
import { FormControl, FormHelperText, TextField } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useTheme } from "../../../../../../../../../../../../../common";
import { useAddOrEditContext, useSetAddOrEditContext } from "../../../AddOrEdit";

export const defaultSinkPubSubRawShortId = "tenable-audit-logs";
export const defaultSinkPubSubSubscriptionRawShortId = `${defaultSinkPubSubRawShortId}-sub`;

export function useSinkPubSubSubscriptionRawIdStep() {
    const { sinkPubSubSubscriptionRawId: initialSinkPubSubSubscriptionRawId } = useAddOrEditContext();
    const setAddOrEditContext = useSetAddOrEditContext();
    const { setValid } = useOrderedWizardContext();

    const [sinkPubSubSubscriptionRawId, setSinkPubSubSubscriptionRawId] = useState(initialSinkPubSubSubscriptionRawId ?? "");

    const localization =
        useLocalization(
            "views.customer.scopes.hooks.useDefinition.hooks.useGcpDefinition.addOrEdit.sinkPubSubSubscriptionItem.hooks.useSinkPubSubSubscriptionRawIdStep",
            () => ({
                fields: {
                    sinkPubSubSubscriptionRawId: {
                        error: {
                            format: "Must be valid Pub/Sub subscription name e.g: projects/project-id/subscriptions/tenable-audit-log-sub",
                            required: "Pub/Sub subscription name cannot be empty"
                        },
                        title: "Pub/Sub Subscription Name"
                    }
                },
                title: "Type in the **Pub/Sub Subscription Name** below:"
            }));

    const [sinkPubSubSubscriptionRawIdValidationController, sinkPubSubSubscriptionRawIdValidationMessage] =
        useInputValidation(
            () => {
                const sinkPubSubSubscriptionRawIdValue = sinkPubSubSubscriptionRawId.trim();
                if (_.isEmpty(sinkPubSubSubscriptionRawIdValue)) {
                    return localization.fields.sinkPubSubSubscriptionRawId.error.required();
                }

                if (!/^projects\/.+\/subscriptions\/.+/.test(sinkPubSubSubscriptionRawIdValue)) {
                    return localization.fields.sinkPubSubSubscriptionRawId.error.format();
                }

                return undefined;
            },
            [sinkPubSubSubscriptionRawId]);

    useEffect(
        () => {
            setAddOrEditContext(
                addContext => ({
                    ...addContext,
                    sinkPubSubSubscriptionRawId
                }));

            setValid(sinkPubSubSubscriptionRawIdValidationController.isValid());
        },
        [sinkPubSubSubscriptionRawId]);

    const theme = useTheme();
    return new Step(
        localization.title(),
        {
            contentElement:
                <FormControl
                    fullWidth={true}
                    sx={{ marginTop: theme.spacing(2) }}
                    variant="standard">
                    <TextField
                        label={localization.fields.sinkPubSubSubscriptionRawId.title()}
                        value={sinkPubSubSubscriptionRawId}
                        variant="outlined"
                        onChange={event => setSinkPubSubSubscriptionRawId(event.target.value)}/>
                    {!_.isNil(sinkPubSubSubscriptionRawIdValidationMessage) && (
                        <FormHelperText error={true}>{sinkPubSubSubscriptionRawIdValidationMessage}</FormHelperText>)}
                </FormControl>
        });
}