import { ApiError, FormLayout, ItemSelector, Message, PasswordTextField, StringHelper, useInputValidation, useLocalization } from "@infrastructure";
import { Box, Button, CircularProgress, FormControl, FormHelperText, Stack, TextField, Typography } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDatadogContext, useSetDatadogContext } from "../..";
import { ConfigurationController, Contract, ScopeHelper, scopeSystemEntityModelStore, useScopeNavigationViewContext, useTheme } from "../../../../../../../../../../../../../../common";
import { useDatadogOrganizationConfigurationSiteTranslator } from "../../hooks";

export function AddOrEdit() {
    const { scopeNodeModel } = useScopeNavigationViewContext();
    const organizationModels =
        ScopeHelper.getParentScopeSystemEntityModelsIntersection(
            [scopeNodeModel.configuration.id],
            scopeSystemEntityModelStore.useGetDatadog());
    const { addOrEditOpen } = useDatadogContext();
    const setDatadogContext = useSetDatadogContext();

    const organizationModel =
        _.isBoolean(addOrEditOpen)
            ? undefined
            : addOrEditOpen;
    const [apiKey, setApiKey] =
        useState(
            _.isNil(organizationModel)
                ? ""
                : undefined);
    const [name, setName] = useState((organizationModel?.configuration as Contract.DatadogOrganizationConfiguration)?.name ?? "");
    const [site, setSite] = useState((organizationModel?.configuration as Contract.DatadogOrganizationConfiguration)?.site);

    const datadogOrganizationConfigurationSiteTranslator = useDatadogOrganizationConfigurationSiteTranslator();
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useObservabilityItems.datadog.addOrEdit",
            () => ({
                actions: {
                    save: {
                        error: {
                            add: {
                                generic: "Failed to add",
                                [Contract.TypeNames.ConfigurationControllerInsertDatadogOrganizationError]: {
                                    [Contract.ConfigurationControllerInsertDatadogOrganizationError.InvalidApiKey]: "Invalid API Key"
                                }
                            },
                            edit: {
                                generic: "Failed to save",
                                [Contract.TypeNames.ConfigurationControllerUpdateDatadogOrganizationError]: {
                                    [Contract.ConfigurationControllerUpdateDatadogOrganizationError.InvalidApiKey]: "Invalid API Key"
                                }
                            }
                        },
                        title: "Add"
                    }
                },
                fields: {
                    apiKey: {
                        error: {
                            required: "API key cannot be empty"
                        },
                        title: "API Key"
                    },
                    name: {
                        error: {
                            exists: "Name already exists",
                            required: "Name cannot be empty"
                        },
                        title: "Organization Name"
                    },
                    site: "Site"
                },
                subtitle: "Provide Datadog Info",
                title: {
                    add: "Add Datadog Organization",
                    edit: "Edit Datadog Organization"
                }
            }));

    const [apiKeyValidationController, apiKeyValidationMessage] =
        useInputValidation(
            () => {
                if (!_.isNil(organizationModel) &&
                    _.isNil(apiKey)) {
                    return undefined;
                }

                const validationApiKey = apiKey!.trim();
                if (_.isEmpty(validationApiKey)) {
                    return localization.fields.apiKey.error.required();
                }

                return undefined;
            },
            [apiKey]);

    const [nameValidationController, nameValidationMessage] =
        useInputValidation(
            () => {
                const normalizedName = StringHelper.normalize(name);
                if (_.isEmpty(normalizedName)) {
                    return localization.fields.name.error.required();
                }
                if (_(organizationModels).
                    filter(
                        otherOrganizationModel =>
                            otherOrganizationModel.configuration.id !== organizationModel?.configuration.id &&
                            otherOrganizationModel.configuration.scopeId == scopeNodeModel.configuration.id).
                    some(otherOrganizationModel => StringHelper.compare((otherOrganizationModel.configuration as Contract.DatadogOrganizationConfiguration).name, normalizedName))) {
                    return localization.fields.name.error.exists();
                }

                return undefined;
            },
            [name]);

    const [saveOrganizationExecuting, setSaveOrganizationExecuting] = useState(false);
    const [saveOrganizationError, setSaveOrganizationError] = useState<string>();

    async function save() {
        setSaveOrganizationExecuting(true);
        setSaveOrganizationError(undefined);

        try {
            const { scopeSystemEntityModel } =
                _.isNil(organizationModel)
                    ? await ConfigurationController.insertDatadogOrganization(
                        new Contract.ConfigurationControllerInsertDatadogOrganizationRequest(
                            apiKey!,
                            name,
                            scopeNodeModel.configuration.id,
                            site))
                    : await ConfigurationController.updateDatadogOrganization(
                        new Contract.ConfigurationControllerUpdateDatadogOrganizationRequest(
                            apiKey,
                            organizationModel.configuration.id,
                            name,
                            site));
            await scopeSystemEntityModelStore.notify(scopeSystemEntityModel);

            setDatadogContext(
                datadogContext => ({
                    ...datadogContext,
                    addOrEditOpen: false
                }));
        } catch (error) {
            if (error instanceof ApiError && error.statusCode === 400) {
                setSaveOrganizationError(
                    _.isNil(organizationModel)
                        ? localization.actions.save.error.add[Contract.TypeNames.ConfigurationControllerInsertDatadogOrganizationError][error.error as Contract.ConfigurationControllerInsertDatadogOrganizationError]()
                        : localization.actions.save.error.edit[Contract.TypeNames.ConfigurationControllerUpdateDatadogOrganizationError][error.error as Contract.ConfigurationControllerUpdateDatadogOrganizationError]());
            } else {
                setSaveOrganizationError(
                    _.isNil(organizationModel)
                        ? localization.actions.save.error.add.generic()
                        : localization.actions.save.error.edit.generic());
            }
        }

        setSaveOrganizationExecuting(false);
    }

    const [valid, setValid] = useState(false);
    useEffect(
        () => {
            setValid(
                apiKeyValidationController.isValid() &&
                nameValidationController.isValid() &&
                !_.isNil(site));
        },
        [apiKey, name, site]);

    const theme = useTheme();
    return (
        <FormLayout
            disableContentPadding={true}
            footerOptions={{
                border: true,
                contentElement:
                    <Stack
                        alignItems="center"
                        direction="row"
                        justifyContent="flex-end"
                        spacing={1}>
                        {!_.isNil(saveOrganizationError) && (
                            <Message
                                level="error"
                                title={saveOrganizationError}/>)}
                        {saveOrganizationExecuting && (
                            <CircularProgress
                                size={theme.spacing(2)}
                                variant="indeterminate"/>)}
                        <Button
                            disabled={!valid}
                            onClick={() => save()}>
                            {localization.actions.save.title()}
                        </Button>
                    </Stack>
            }}
            titleOptions={{
                text:
                    _.isNil(organizationModel)
                        ? localization.title.add()
                        : localization.title.edit()
            }}>
            <Stack
                spacing={2}
                sx={{
                    height: "100%",
                    padding: theme.spacing(3)
                }}>
                <Typography variant="h4">
                    {localization.subtitle()}
                </Typography>
                <Box sx={{ width: theme.spacing(61) }}>
                    <FormControl
                        fullWidth={true}
                        variant="standard">
                        <TextField
                            label={localization.fields.name.title()}
                            value={name}
                            variant="outlined"
                            onChange={event => setName(event.target.value)}/>
                        {!_.isNil(nameValidationMessage) && (
                            <FormHelperText error={true}>{nameValidationMessage}</FormHelperText>)}
                    </FormControl>
                </Box>
                <Box sx={{ width: theme.spacing(61) }}>
                    <ItemSelector
                        fullWidth={true}
                        items={[
                            Contract.DatadogOrganizationConfigurationSite.Us1,
                            Contract.DatadogOrganizationConfigurationSite.Us3,
                            Contract.DatadogOrganizationConfigurationSite.Us5,
                            Contract.DatadogOrganizationConfigurationSite.Eu1,
                            Contract.DatadogOrganizationConfigurationSite.Us1Gov
                        ]}
                        placeholder={localization.fields.site()}
                        selectedItem={site}
                        sorted={false}
                        onSelectedItemChanged={setSite}>
                        {datadogOrganizationConfigurationSiteTranslator}
                    </ItemSelector>
                </Box>
                <Box sx={{ width: theme.spacing(61) }}>
                    <FormControl
                        fullWidth={true}
                        variant="standard">
                        <PasswordTextField
                            disabled={saveOrganizationExecuting}
                            label={localization.fields.apiKey.title()}
                            password={apiKey}
                            variant="outlined"
                            onPasswordChanged={apiKey => setApiKey(apiKey)}/>
                        {!_.isNil(apiKeyValidationMessage) && (
                            <FormHelperText error={true}>{apiKeyValidationMessage}</FormHelperText>)}
                    </FormControl>
                </Box>
            </Stack>
        </FormLayout>);
}