﻿import _, { Dictionary } from "lodash";
import { useOpCommonKubernetesNamespaceResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, ItemSelectionHelper, TimeRangeHelper } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";
import { useCommonKubernetesWorkloadResourceDefinition } from "../useCommonKubernetesWorkloadResourceDefinition";

export function useOpKubernetesWorkloadResourceDefinition(definitionData: DefinitionData) {
    const commonKubernetesWorkloadResourceDefinition = useCommonKubernetesWorkloadResourceDefinition(definitionData);
    const { columns, initialFilterMap } = useOpCommonKubernetesNamespaceResourceDefinition(definitionData);

    return new EntityTableDefinition(
        _.filter([
            columns.clusterColumn,
            columns.tenantColumn,
            commonKubernetesWorkloadResourceDefinition.columns.typeColumn,
            columns.creationTimeColumn,
            columns.namespaceColumn,
            commonKubernetesWorkloadResourceDefinition.columns.containerImagesColumn,
            commonKubernetesWorkloadResourceDefinition.columns.containerImageVulnerabilitiesColumn,
            commonKubernetesWorkloadResourceDefinition.columns.serviceAccountColumn,
            columns.tagsColumn,
            columns.attributesColumn,
            columns.openRiskedEntityRisksCsvColumn,
            columns.openRelatedEntityRiskHighestSeverityColumn,
            columns.openRelatedEntityRiskTypeIdentifiersColumn
        ]),
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageOpKubernetesWorkloadResourceRequest(
                new Contract.EntityControllerGetEntityModelPageOpKubernetesWorkloadResourceRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.KubernetesWorkloadResourceContainerImages]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.KubernetesWorkloadResourceContainerImageVulnerabilities]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.KubernetesWorkloadContainerImageVulnerabilitySeverities]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.KubernetesWorkloadResourceControllerResource]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.KubernetesWorkloadResourceServiceAccount]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.KubernetesWorkloadResourceTypeName])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags])),
                new Contract.EntityControllerGetEntityModelPageEntityRequestKubernetesResource(ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.KubernetesResourceCluster])),
                new Contract.EntityControllerGetEntityModelPageEntityRequestKubernetesNamespaceResource(ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.KubernetesNamespaceResourceNamespace]))),
        {
            initialFilterMap
        });
}