import { UnexpectedError } from "@infrastructure";
import { useMemo } from "react";
import { CustomerConsoleAppUrlHelper, RiskPoliciesType, RiskPoliciesView, TenantHelper } from "../../../../../common";
import { CustomerView } from "../../../hooks";
import { KubernetesView } from "../../Kubernetes";

export function useRiskPoliciesTypeData(policyType: RiskPoliciesType) {
    const riskPoliciesTenantTypes =
        useMemo(
            () => {
                switch (policyType) {
                    case RiskPoliciesType.Cloud:
                        return {
                            templatePath: CustomerConsoleAppUrlHelper.getTemplatePath(CustomerView.RiskPolicies),
                            tenantTypes: TenantHelper.CloudRiskPoliciesTenantTypes,
                            views: [RiskPoliciesView.BuiltIn, RiskPoliciesView.Custom]
                        };
                    case RiskPoliciesType.Code:
                        return {
                            templatePath: CustomerConsoleAppUrlHelper.getTemplatePath(`${CustomerView.Code}/${CustomerView.RiskPolicies}`),
                            tenantTypes: TenantHelper.CodeRiskPoliciesTenantTypes,
                            views: [RiskPoliciesView.BuiltIn]
                        };
                    case RiskPoliciesType.KubernetesAdmissionController:
                        return {
                            templatePath: CustomerConsoleAppUrlHelper.getTemplatePath(`${CustomerView.Kubernetes}/${KubernetesView.KubernetesAdmissionControllerRiskPolicies}`),
                            tenantTypes: TenantHelper.KubernetesAdmissionControllerRiskPoliciesTenantTypes,
                            views: [RiskPoliciesView.BuiltIn]
                        };
                    default:
                        throw new UnexpectedError("useRiskPolicyTypeData.policyType", policyType);
                }
            },
            [policyType]);

    return riskPoliciesTenantTypes;
}