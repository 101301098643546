import { Loading, Message, SuccessIcon, useSetWizardContext, WizardLayout } from "@infrastructure";
import { Box, List, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { Change, Contract, useTheme } from "../../../..";
import { useRiskResolutionAutomationContext } from "../../utilities";
import { ChangeInfo } from "../ChangeInfo";
import { ChangeListItem, EmptyChangeInfo } from "./components";

type EditItemProps = {
    getChangeNotChangeableMessage?: (changeModel: Contract.ChangeModel) => string;
    getChangeNotResolvableMessage?: (changeModel: Contract.ChangeModel) => string;
    isChangeResolvable: (changeModel: Contract.ChangeModel) => boolean;
    nextDisabled?: boolean;
    notChangeableMessage?: string;
};

export function EditItem({ getChangeNotChangeableMessage, getChangeNotResolvableMessage, isChangeResolvable, nextDisabled = false, notChangeableMessage }: EditItemProps) {
    const setWizardContext = useSetWizardContext();
    const {
        changeModels,
        someChangeableSomeNotChangeable
    } = useRiskResolutionAutomationContext();

    useEffect(
        () =>
            setWizardContext(
                wizardContext => ({
                    ...wizardContext,
                    bottomElement:
                        <Stack
                            alignItems="center"
                            direction="row"
                            spacing={2}>
                            {notChangeableMessage &&
                                <Message
                                    level="infoWarning"
                                    title={notChangeableMessage}/>}
                        </Stack>,
                    nextDisabled
                })),
        []);

    const [selectedChangeId, setSelectedChangeId] =
        useState(
            () =>
                _.find(
                    changeModels,
                    changeModel => isChangeResolvable(changeModel))?.change.id);

    const [infoIconExists, orderedChangeModels] =
        useMemo(
            () => {
                const infoIconExists =
                    someChangeableSomeNotChangeable ||
                    _.some(
                        changeModels,
                        changeModel => !isChangeResolvable(changeModel));

                const orderedChangeModels =
                    _.orderBy(
                        changeModels,
                        [
                            changeModel => changeModel.resolved,
                            isChangeResolvable
                        ],
                        [
                            "desc",
                            "desc"
                        ]);

                return [infoIconExists, orderedChangeModels];
            },
            [changeModels]);
    const theme = useTheme();
    return (
        <WizardLayout
            disablePadding={true}
            sideElement={
                <List disablePadding={true}>
                    {_.map(
                        orderedChangeModels,
                        (changeModel, changeModelIndex) => (
                            <ChangeListItem
                                disabled={!isChangeResolvable(changeModel)}
                                key={changeModel.change.id}
                                selected={changeModel.change.id === selectedChangeId}
                                onClick={() => setSelectedChangeId(changeModel.change.id)}>
                                <Stack
                                    alignItems="center"
                                    direction="row">
                                    <Box
                                        sx={{
                                            alignItems: "center",
                                            border: theme.border.primary,
                                            borderRadius: theme.spacing(0.75),
                                            display: "flex",
                                            height: theme.spacing(3),
                                            justifyContent: "center",
                                            marginRight: theme.spacing(1),
                                            width: theme.spacing(3),
                                            ...(!isChangeResolvable(changeModel)
                                                ? {
                                                    color: theme.palette.text.primary,
                                                    opacity: 0.3
                                                }
                                                : selectedChangeId === changeModel.change.id
                                                    ? {
                                                        backgroundColor: theme.palette.primary.main,
                                                        color: theme.palette.common.white
                                                    }
                                                    : undefined)
                                        }}>
                                        <Typography>
                                            {changeModelIndex + 1}
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            flex: 1,
                                            ...!isChangeResolvable(changeModel)
                                                ? {
                                                    color: theme.palette.text.primary,
                                                    opacity: 0.3
                                                }
                                                : undefined
                                        }}>
                                        <Change
                                            change={changeModel.change}
                                            entityLinkDisabled={true}/>
                                    </Box>
                                    {infoIconExists && (
                                        <Box
                                            sx={{
                                                marginLeft: theme.spacing(1.5),
                                                width: theme.spacing(2.25)
                                            }}>
                                            {changeModel.resolved
                                                ? <SuccessIcon
                                                    sx={{
                                                        color: theme.palette.success.main,
                                                        fontSize: "18px"
                                                    }}/>
                                                : !isChangeResolvable(changeModel)
                                                    ? <Message
                                                        level="warning"
                                                        title={getChangeNotResolvableMessage!(changeModel)}
                                                        variant="minimal"/>
                                                    : !changeModel.changeable && someChangeableSomeNotChangeable && (
                                                        <Message
                                                            level="infoWarning"
                                                            title={getChangeNotChangeableMessage!(changeModel)}
                                                            variant="minimal"/>)}
                                        </Box>)}
                                </Stack>
                            </ChangeListItem>))}
                </List>}>
            <Stack
                sx={{
                    flex: 1,
                    overflow: "hidden",
                    padding:
                        _.isNil(selectedChangeId)
                            ? theme.spacing(2.5)
                            : undefined,
                    position: "relative"
                }}>
                <Loading>
                    {_.isNil(selectedChangeId)
                        ? <EmptyChangeInfo/>
                        : <ChangeInfo
                            change={
                                _.find(
                                    orderedChangeModels,
                                    changeModel => changeModel.change.id === selectedChangeId)!.change}
                            key={selectedChangeId}/>}
                </Loading>
            </Stack>
        </WizardLayout>);
}