import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useOciKmsVaultKeyAlgorithmTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useOciKmsVaultKeyAlgorithmTranslator",
            () => ({
                [Contract.TypeNames.OciKmsVaultKeyAlgorithm]: {
                    [Contract.OciKmsVaultKeyAlgorithm.Aes]: "AES",
                    [Contract.OciKmsVaultKeyAlgorithm.Ecdsa]: "ECDSA",
                    [Contract.OciKmsVaultKeyAlgorithm.Rsa]: "RSA"
                }
            }));
    return (algorithm: Contract.OciKmsVaultKeyAlgorithm) =>
        localization[Contract.TypeNames.OciKmsVaultKeyAlgorithm][algorithm]();
}