import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function PhpSecurityAdvisoriesIcon(props: SvgIconProps) {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <ellipse
                cx="12"
                cy="11.7826"
                fill="url(#paint0_radial_52885_254802)"
                rx="12"
                ry="6.78261"/>
            <path
                d="M23.4781 11.7828C23.4781 14.9525 18.4832 18.0437 11.9999 18.0437C5.51653 18.0437 0.260742 15.4742 0.260742 12.3046C0.260742 9.13495 5.51653 5.52197 11.9999 5.52197C18.4832 5.52197 23.4781 8.61321 23.4781 11.7828Z"
                fill="#777BB3"/>
            <path
                d="M6.17063 12.6973C6.70168 12.6973 7.09813 12.598 7.34898 12.4022C7.59715 12.2085 7.76854 11.8728 7.85838 11.4046C7.94214 10.967 7.91023 10.6614 7.76357 10.4965C7.61361 10.3281 7.28938 10.2427 6.79993 10.2427H5.95125L5.48076 12.6973H6.17063ZM3.39484 15.7209C3.35544 15.7209 3.31803 15.703 3.29298 15.6722C3.26789 15.6413 3.25772 15.6007 3.26525 15.5615L4.51214 9.05675C4.5242 8.99387 4.57856 8.94844 4.64177 8.94844H7.32913C8.17371 8.94844 8.80235 9.18095 9.19749 9.63961C9.59473 10.1007 9.7174 10.7452 9.56207 11.5551C9.49886 11.8851 9.39014 12.1916 9.23895 12.466C9.08758 12.7407 8.88752 12.9948 8.64425 13.2215C8.35304 13.4979 8.02344 13.6983 7.6655 13.8162C7.31324 13.9325 6.86084 13.9915 6.3209 13.9915H5.23273L4.92198 15.6125C4.90992 15.6755 4.8556 15.7209 4.79239 15.7209H3.39484Z"
                fill="black"/>
            <path
                d="M6.06019 10.3772H6.80002C7.39076 10.3772 7.59596 10.5086 7.66568 10.5869C7.78136 10.717 7.80319 10.9912 7.72885 11.3799C7.6453 11.815 7.49046 12.1235 7.26853 12.2968C7.04131 12.4741 6.67193 12.564 6.17072 12.564H5.64103L6.06019 10.3772ZM7.32922 8.81517H4.64182C4.5154 8.81517 4.40673 8.90604 4.38261 9.03185L3.13572 15.5366C3.12066 15.6151 3.141 15.6962 3.19113 15.758C3.24131 15.8197 3.31605 15.8554 3.39493 15.8554H4.79244C4.9189 15.8554 5.02758 15.7645 5.0517 15.6387L5.34168 14.126H6.32099C6.8748 14.126 7.34102 14.0648 7.70657 13.9441C8.08252 13.8202 8.4284 13.6102 8.73453 13.32C8.98771 13.0838 9.19626 12.8187 9.35428 12.532C9.51234 12.2451 9.6259 11.9253 9.69179 11.5813C9.85531 10.7287 9.72247 10.046 9.29701 9.55218C8.87569 9.06313 8.2136 8.81517 7.32922 8.81517ZM5.32064 12.8318H6.17072C6.73417 12.8318 7.15386 12.7242 7.42963 12.5089C7.70543 12.2936 7.89144 11.9343 7.9881 11.4308C8.08058 10.9474 8.03846 10.6063 7.86173 10.4075C7.68479 10.2088 7.33094 10.1094 6.80002 10.1094H5.84249L5.32064 12.8318ZM7.32922 9.08295C8.13767 9.08295 8.72744 9.29806 9.09815 9.72833C9.46881 10.1586 9.58043 10.7592 9.43254 11.5302C9.37166 11.8478 9.26871 12.1382 9.12376 12.4013C8.97864 12.6646 8.78915 12.9053 8.55499 13.1236C8.27592 13.3884 7.96592 13.5771 7.62479 13.6895C7.28367 13.8022 6.84918 13.8582 6.32099 13.8582H5.12393L4.79244 15.5876H3.39493L4.64182 9.08295H7.32922Z"
                fill="white"/>
            <path
                d="M12.7667 13.9926C12.7273 13.9926 12.6899 13.9748 12.6648 13.9439C12.6397 13.9131 12.6295 13.8725 12.6371 13.8332L13.1886 10.9551C13.2411 10.6814 13.2282 10.485 13.1522 10.402C13.1058 10.3512 12.9661 10.2661 12.5533 10.2661H11.554L10.8606 13.8843C10.8485 13.9472 10.7942 13.9926 10.7309 13.9926H9.34444C9.305 13.9926 9.26763 13.9748 9.24254 13.9439C9.21745 13.9131 9.20728 13.8724 9.21481 13.8332L10.4617 7.32849C10.4738 7.26561 10.5281 7.22018 10.5913 7.22018H11.9778C12.0173 7.22018 12.0546 7.23803 12.0797 7.26887C12.1048 7.29975 12.115 7.34032 12.1075 7.37959L11.8066 8.94955H12.8815C13.7005 8.94955 14.2558 9.09593 14.5792 9.39709C14.9089 9.70431 15.0117 10.1955 14.885 10.8573L14.3048 13.8843C14.2928 13.9472 14.2385 13.9926 14.1752 13.9926H12.7667Z"
                fill="black"/>
            <path
                d="M11.9774 7.08691H10.5909C10.4645 7.08691 10.3558 7.17778 10.3317 7.30359L9.08477 13.8083C9.06971 13.8868 9.09005 13.968 9.14023 14.0297C9.1904 14.0914 9.26514 14.1271 9.34402 14.1271H10.7305C10.8569 14.1271 10.9657 14.0363 10.9898 13.9105L11.6625 10.4006H12.5529C12.965 10.4006 13.0515 10.4899 13.055 10.4937C13.08 10.521 13.1129 10.6473 13.0586 10.9302L12.507 13.8083C12.492 13.8868 12.5123 13.968 12.5625 14.0297C12.6127 14.0914 12.6874 14.1271 12.7663 14.1271H14.1748C14.3012 14.1271 14.4099 14.0363 14.434 13.9105L15.0141 10.8835C15.1503 10.173 15.0338 9.63996 14.6681 9.29917C14.3192 8.97423 13.7346 8.81629 12.8811 8.81629H11.9663L12.2367 7.40579C12.2517 7.32729 12.2314 7.24606 12.1812 7.18438C12.131 7.12266 12.0563 7.08691 11.9774 7.08691ZM11.9774 7.35469L11.6459 9.08406H12.8811C13.6583 9.08406 14.1944 9.22161 14.4894 9.49634C14.7845 9.77126 14.8728 10.2167 14.7549 10.8324L14.1748 13.8594H12.7663L13.3178 10.9813C13.3806 10.6539 13.3575 10.4306 13.2486 10.3115C13.1395 10.1925 12.9076 10.1328 12.5529 10.1328H11.4447L10.7305 13.8594H9.34402L10.5909 7.35469H11.9774Z"
                fill="white"/>
            <path
                d="M17.0787 12.6973C17.6098 12.6973 18.0062 12.598 18.2571 12.4022C18.5053 12.2085 18.6767 11.8729 18.7665 11.4046C18.8503 10.967 18.8184 10.6614 18.6717 10.4965C18.5217 10.3281 18.1975 10.2427 17.708 10.2427H16.8594L16.3889 12.6973H17.0787ZM14.303 15.7209C14.2636 15.7209 14.2262 15.703 14.2011 15.6722C14.176 15.6413 14.1658 15.6007 14.1734 15.5615L15.4203 9.05675C15.4324 8.99387 15.4867 8.94844 15.5499 8.94844H18.2372C19.0819 8.94844 19.7105 9.18095 20.1056 9.63961C20.5029 10.1006 20.6255 10.7451 20.4702 11.5551C20.4069 11.8851 20.2983 12.1916 20.1471 12.466C19.9957 12.7407 19.7956 12.9948 19.5524 13.2215C19.2611 13.4979 18.9316 13.6983 18.5736 13.8162C18.2214 13.9325 17.769 13.9915 17.229 13.9915H16.1408L15.8302 15.6125C15.8181 15.6755 15.7638 15.7209 15.7005 15.7209H14.303Z"
                fill="black"/>
            <path
                d="M16.9683 10.3772H17.7081C18.2988 10.3772 18.504 10.5086 18.5738 10.5869C18.6895 10.717 18.7113 10.9912 18.6369 11.3798C18.5534 11.815 18.3985 12.1235 18.1766 12.2968C17.9494 12.4741 17.58 12.564 17.0788 12.564H16.5491L16.9683 10.3772ZM18.2373 8.81517H15.5499C15.4235 8.81517 15.3148 8.90604 15.2907 9.03185L14.0438 15.5366C14.0288 15.6151 14.0491 15.6962 14.0993 15.758C14.1494 15.8197 14.2242 15.8554 14.303 15.8554H15.7006C15.827 15.8554 15.9357 15.7645 15.9598 15.6387L16.2497 14.126H17.2291C17.7829 14.126 18.2491 14.0648 18.6146 13.9441C18.9906 13.8202 19.3366 13.6102 19.6427 13.3199C19.8959 13.0838 20.1043 12.8187 20.2624 12.532C20.4204 12.2451 20.534 11.9253 20.5999 11.5813C20.7634 10.7286 20.6305 10.0459 20.2051 9.55213C19.7838 9.06313 19.1217 8.81517 18.2373 8.81517ZM16.2288 12.8318H17.0788C17.6422 12.8318 18.0619 12.7242 18.3377 12.5089C18.6135 12.2936 18.7995 11.9343 18.8962 11.4308C18.9887 10.9474 18.9466 10.6063 18.7698 10.4075C18.5929 10.2088 18.239 10.1094 17.7081 10.1094H16.7506L16.2288 12.8318ZM18.2373 9.08295C19.0457 9.08295 19.6355 9.29806 20.0062 9.72833C20.3769 10.1586 20.4885 10.7592 20.3406 11.5302C20.2797 11.8478 20.1768 12.1382 20.0318 12.4013C19.8867 12.6646 19.6972 12.9053 19.4631 13.1236C19.184 13.3884 18.874 13.5771 18.5329 13.6895C18.1917 13.8022 17.7573 13.8582 17.2291 13.8582H16.032L15.7006 15.5876H14.303L15.5499 9.08295H18.2373Z"
                fill="white"/>
            <defs>
                <radialGradient
                    cx="0"
                    cy="0"
                    gradientTransform="translate(3.65217 3.43478) rotate(32.4712) scale(27.2106 48.1418)"
                    gradientUnits="userSpaceOnUse"
                    id="paint0_radial_52885_254802"
                    r="1">
                    <stop stopColor="#B5B9E5"/>
                    <stop
                        offset="0.229167"
                        stopColor="#777BB3"/>
                    <stop
                        offset="0.640625"
                        stopColor="#484C89"/>
                    <stop
                        offset="1"
                        stopColor="#484C89"/>
                </radialGradient>
            </defs>
        </SvgIcon>);
}