import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useOciKmsVaultSecretStatusTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useOciKmsVaultSecretStatusTranslator",
            () => ({
                [Contract.TypeNames.OciKmsVaultSecretStatus]: {
                    [Contract.OciKmsVaultSecretStatus.Active]: "Active",
                    [Contract.OciKmsVaultSecretStatus.CancellingDeletion]: "Cancelling Deletion",
                    [Contract.OciKmsVaultSecretStatus.Creating]: "Creating",
                    [Contract.OciKmsVaultSecretStatus.Deleted]: "Deleted",
                    [Contract.OciKmsVaultSecretStatus.Deleting]: "Deleting",
                    [Contract.OciKmsVaultSecretStatus.Failed]: "Failed",
                    [Contract.OciKmsVaultSecretStatus.PendingDeletion]: "Pending Deletion",
                    [Contract.OciKmsVaultSecretStatus.SchedulingDeletion]: "Scheduling Deletion",
                    [Contract.OciKmsVaultSecretStatus.Updating]: "Updating"
                }
            }));
    return (status: Contract.OciKmsVaultSecretStatus) =>
        localization[Contract.TypeNames.OciKmsVaultSecretStatus][status]();
}