﻿import { UnexpectedError } from "@infrastructure";
import { useMemo } from "react";
import { Contract, TypeHelper } from "../../../../../../../../../../../../../common";
import { useAwsEc2InstanceMetadataServiceVersionRiskClusterDefinition, useAwsEc2InstanceMetadataServiceVersionRiskInstanceDefinition, useAwsEc2InstanceMetadataServiceVersionRiskLaunchConfigurationDefinition, useAwsEc2InstanceMetadataServiceVersionRiskLaunchTemplateDefinition, useAwsEc2InstanceMetadataServiceVersionRiskTagResourceGroupDefinition } from "./hooks";

export function useAwsEc2InstanceMetadataServiceVersionRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsEc2InstanceMetadataServiceVersionRisk;

    const useDefinition =
        useMemo(
            () => {
                switch (risk.aggregatingEntityTypeName) {
                    case Contract.TypeNames.AwsAutoScalingLaunchConfiguration:
                        return useAwsEc2InstanceMetadataServiceVersionRiskLaunchConfigurationDefinition;
                    case Contract.TypeNames.AwsEc2Instance:
                        return useAwsEc2InstanceMetadataServiceVersionRiskInstanceDefinition;
                    case Contract.TypeNames.AwsEc2LaunchTemplate:
                        return useAwsEc2InstanceMetadataServiceVersionRiskLaunchTemplateDefinition;
                    case Contract.TypeNames.AwsEksCluster:
                        return useAwsEc2InstanceMetadataServiceVersionRiskClusterDefinition;
                    default:
                        if (TypeHelper.extendOrImplement(risk.aggregatingEntityTypeName, Contract.TypeNames.AwsTagResourceGroup)) {
                            return useAwsEc2InstanceMetadataServiceVersionRiskTagResourceGroupDefinition;
                        }

                        throw new UnexpectedError("risk.aggregatingEntityTypeName", risk.aggregatingEntityTypeName);
                }
            },
            []);

    return useDefinition(riskModel as Contract.AwsEc2InstanceMetadataServiceVersionRiskModel);
}