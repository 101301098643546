import { Optional } from "@infrastructure";
import { Contract, RiskPolicyTypeMetadataHelper } from "..";

export abstract class RiskPolicyItem {
    public riskPolicyTypeMetadata: Contract.RiskPolicyTypeMetadata;
    public tenantTypes: Contract.TenantType[];

    protected constructor(
        public customComplianceIds: string[],
        public deliveries: Contract.DeliveryWrapper[],
        public enabled: boolean,
        public result: RiskPolicyItemResult,
        public riskPolicyConfigurationTypeName: string) {
        this.riskPolicyTypeMetadata = RiskPolicyTypeMetadataHelper.get(riskPolicyConfigurationTypeName);
        this.tenantTypes = RiskPolicyTypeMetadataHelper.getTenantTypes(riskPolicyConfigurationTypeName);
    }

    public get success(): boolean {
        return this.result.riskCount === 0;
    }

    public get custom(): boolean {
        return this.riskPolicyTypeMetadata.category === Contract.RiskPolicyCategory.Custom || this.riskPolicyTypeMetadata.category === Contract.RiskPolicyCategory.CustomKubernetesAdmissionController;
    }

    public abstract get riskPolicyConfigurationTypeNameOrId(): string;
}

export type RiskPolicyItemResult = {
    analyzedEntityCount?: number;
    failedEntityCount?: number;
    failedTenantCount: number;
    riskCount: number;
};

export abstract class BuiltInRiskPolicyItem extends RiskPolicyItem {
    protected constructor(
        customComplianceIds: string[],
        deliveries: Contract.DeliveryWrapper[],
        enabled: boolean,
        public enabledInherited: boolean,
        result: RiskPolicyItemResult,
        riskPolicyConfigurationTypeName: string) {
        super(
            customComplianceIds,
            deliveries,
            enabled,
            result,
            riskPolicyConfigurationTypeName);
    }

    public get riskPolicyConfigurationTypeNameOrId(): string {
        return this.riskPolicyConfigurationTypeName;
    }
}

export class CustomRiskPolicyItem extends RiskPolicyItem {
    constructor(
        public customComplianceIds: string[],
        deliveries: Contract.DeliveryWrapper[],
        public readOnly: boolean,
        result: RiskPolicyItemResult,
        public riskPolicyModel: Contract.RiskPolicyModel,
        public scopeId: string) {
        super(
            customComplianceIds,
            deliveries,
            riskPolicyModel.riskPolicyConfiguration.enabled!,
            result,
            riskPolicyModel.riskPolicyConfiguration.typeName);
    }

    public get riskPolicyConfiguration(): Contract.CustomRiskPolicyConfiguration {
        return this.riskPolicyModel.riskPolicyConfiguration as Contract.CustomRiskPolicyConfiguration;
    }

    public get riskPolicyConfigurationTypeNameOrId(): string {
        return this.riskPolicyConfiguration.id;
    }
}

export class BehaviorRiskPolicyItem extends BuiltInRiskPolicyItem {
    constructor(
        customComplianceIds: string[],
        deliveries: Contract.DeliveryWrapper[],
        enabled: boolean,
        enabledInherited: boolean,
        result: RiskPolicyItemResult,
        riskPolicyConfigurationTypeName: string) {
        super(
            customComplianceIds,
            deliveries,
            enabled,
            enabledInherited,
            result,
            riskPolicyConfigurationTypeName);
    }
}

export class CommonRiskPolicyItem extends BuiltInRiskPolicyItem {
    constructor(
        customComplianceIds: string[],
        deliveries: Contract.DeliveryWrapper[],
        enabled: boolean,
        enabledInherited: boolean,
        result: RiskPolicyItemResult,
        riskPolicyConfigurationTypeName: string,
        public securityStats?: Contract.SecurityStats) {
        super(
            customComplianceIds,
            deliveries,
            enabled,
            enabledInherited,
            result,
            riskPolicyConfigurationTypeName);
    }
}

export class KubernetesAdmissionControllerRiskPolicyItem extends CommonRiskPolicyItem {
    constructor(
        customComplianceIds: string[],
        deliveries: Contract.DeliveryWrapper[],
        public effect: Contract.KubernetesAdmissionControllerRiskPolicyEffect,
        enabled: boolean,
        enabledInherited: boolean,
        public lastViolationEventTime: Optional<string>,
        riskPolicyConfigurationTypeName: string) {
        super(
            customComplianceIds,
            deliveries,
            enabled,
            enabledInherited,
            {
                failedTenantCount: 0,
                riskCount: 0
            },
            riskPolicyConfigurationTypeName);
    }
}