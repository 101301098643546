import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { Link, Message, useLocalization } from "@infrastructure";
import { Contract, CustomerConsoleAppUrlHelper, LicensingHelper, useCloudProviderTenantPermissionTypesTranslator } from "../../../../../../../common";
import { TenantModelHelper } from "../../../utilities";
import { ProfileDataSection } from "../useProfileDefinition";

export function useGetCloudProviderTenantProfilePermissionsSection() {
    const { ContainerImageRepositoryAnalysis, DataAnalysis, PermissionManagement, Read, WorkloadAnalysis, Write } = Contract.CloudProviderTenantPermissionType;
    const permissionTypesByTenant =
        useMemo(
            () => ({
                [Contract.TenantType.Aws]: [Read, Write, DataAnalysis, PermissionManagement, ContainerImageRepositoryAnalysis, WorkloadAnalysis],
                [Contract.TenantType.Azure]: [Read, Write, DataAnalysis, PermissionManagement, WorkloadAnalysis],
                [Contract.TenantType.Gcp]: [Read, Write, DataAnalysis, PermissionManagement, WorkloadAnalysis]
            }),
            []);

    // make cloud providers (permissionsItem.tsx) use this ... or 1 simplified place for this
    const cloudProviderPermissionTypes =
        useMemo(
            () => ([
                { type: Contract.CloudProviderTenantPermissionType.Read, visible: true },
                { type: Contract.CloudProviderTenantPermissionType.Write, visible: true },
                { type: Contract.CloudProviderTenantPermissionType.DataAnalysis, visible: LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.Cspm) },
                { type: Contract.CloudProviderTenantPermissionType.PermissionManagement, visible: LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.PermissionManagement) },
                { type: Contract.CloudProviderTenantPermissionType.ContainerImageRepositoryAnalysis, visible: LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.Cnapp) },
                { type: Contract.CloudProviderTenantPermissionType.WorkloadAnalysis, visible: LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.Cnapp) }
            ]),
            []);

    const cloudProviderTenantPermissionTypesTranslator = useCloudProviderTenantPermissionTypesTranslator();
    const localization =
        useLocalization(
            "views.customer.scopes.hooks.useDefinition.hooks.useGetCloudProviderTenantProfilePermissionsSection",
            () => ({
                configurationPermissions: "Features",
                permissionValue: {
                    disabled: "Disabled",
                    enabled: "Enabled",
                    infoText: "The feature is disabled, but the required permissions are still granted. Consider removing permissions if the feature is not needed.",
                    learnMore: "Lean more.",
                    warningText: "The feature is enabled, but the required permissions were not granted. Grant permissions to activate it. {{link}}"
                }
            }));

    return (tenantModel: Contract.AwsTenantModel | Contract.AzureTenantModel | Contract.GcpTenantModel): ProfileDataSection => {
        const { configurationPermissionTypes, statePermissionTypes = [] } = TenantModelHelper.getTenantModelPermissions(tenantModel);
        const tenantType = tenantModel.tenantType as Contract.TenantType.Aws | Contract.TenantType.Azure | Contract.TenantType.Gcp;
        return {
            items: (
                _(cloudProviderPermissionTypes).
                    filter(({ type, visible }) => visible && permissionTypesByTenant[tenantType].includes(type)).
                    map(
                        ({ type }) => {
                            const permissionConfigurationEnabled = configurationPermissionTypes.includes(type);
                            const permissionStateEnabled = statePermissionTypes.includes(type);
                            return ({
                                title: cloudProviderTenantPermissionTypesTranslator.single(type, "longName"),
                                value:
                                    <Stack
                                        alignItems="center"
                                        direction="row"
                                        spacing={0.5}>
                                        <Typography>
                                            {permissionConfigurationEnabled
                                                ? localization.permissionValue.enabled()
                                                : localization.permissionValue.disabled()}
                                        </Typography>
                                        {permissionConfigurationEnabled && !permissionStateEnabled &&
                                            <Message
                                                level="warning"
                                                title={
                                                    localization.permissionValue.warningText({
                                                        link:
                                                            <Link
                                                                urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsCloudOnboardingRelativeUrl()}
                                                                variant="external">
                                                                {localization.permissionValue.learnMore()}
                                                            </Link>
                                                    })}
                                                variant="minimal"/>}
                                        {!permissionConfigurationEnabled && permissionStateEnabled &&
                                            <Message
                                                level="info"
                                                title={localization.permissionValue.infoText()}
                                                variant="minimal"/>}
                                    </Stack>
                            });
                        }).
                    value()
            ),
            title: localization.configurationPermissions()
        };
    };
}