import { DataTableAction, DataTableActions, useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useRef } from "react";
import { useTheme } from "../../../../../../../../../../../common";
import { RiskPolicySelectItem, RiskPolicyTableProps } from "../RiskPolicyTable";
import { Table } from "./Table";

type EditProps =
    Pick<RiskPolicyTableProps, | "selectedRiskPoliciesIds" | "onSelectChange"> & {
        items: RiskPolicySelectItem[];
    };

export function Edit({ items, onSelectChange, selectedRiskPoliciesIds }: EditProps) {
    const dataTableActionsRef = useRef<DataTableActions>();
    useEffect(
        () => {
            dataTableActionsRef.current?.setSelectedItemIds(selectedRiskPoliciesIds);
        },
        []);

    const localization =
        useLocalization(
            "views.customer.compliance.addOrEdit.section.riskPolicyTable.edit",
            () => ({
                selectedCount: [
                    "1 selected",
                    "{{count | NumberFormatter.humanize}} selected"
                ]
            }));

    const theme = useTheme();
    return (
        <Table
            dataTableOptions={{
                actions:
                    _.size(selectedRiskPoliciesIds) > 0
                        ? <DataTableAction>
                            <Typography sx={{ paddingTop: theme.px(5) }}>
                                {localization.selectedCount(_.size(selectedRiskPoliciesIds))}
                            </Typography>
                        </DataTableAction>
                        : undefined,
                actionsRef: dataTableActionsRef,
                variant: "view"
            }}
            items={items}
            variant="edit"
            onSelectChange={onSelectChange}/>);
}