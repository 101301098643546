import { DataTableColumnRenderProps, NoneIcon } from "@infrastructure";
import { Typography, useTheme } from "@mui/material";
import _ from "lodash";
import React, { Fragment, ReactNode } from "react";

export function optionalTableCell<TItem>(itemProperty: (item: TItem) => ReactNode) {
    return function OptionalTableCell({ item }: DataTableColumnRenderProps<TItem>) {
        const value = itemProperty(item);
        const empty =
            (_.isArray(value) &&
                _.isEmpty(value)) ||
            _.isNil(value) ||
            value === false;

        const theme = useTheme();
        return (
            <Fragment>
                {empty
                    ? <NoneIcon
                        sx={{
                            color: theme.palette.text.secondary,
                            fontSize: "18px"
                        }}/>
                    : _.isString(value)
                        ? <Typography noWrap={true}>
                            {value}
                        </Typography>
                        : value}
            </Fragment>);
    };
}