import { DataTable, DataTableColumn, DataTableFetchItemsResult, DataTableSort, DataTableSortDirection, DataTableSortType, EmptyMessageText, EnumValuesFilter, InlineItems, Optional, optionalTableCell, PagedValuesFilter, PagedValuesFilterValuePage, SearchList, useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { useRef } from "react";
import { CodeController, Contract, InlineVulnerability, ItemSelectionHelper, StorageHelper, useTheme } from "../../../../../../../../../../../common";
import { CodeControllerGetContainerImageScanPackagePageRequestFilters } from "../../../../../../../../../../../common/controllers/types.generated";

type PackagesProps = {
    scan: Contract.CodeContainerImageScan;
};

export function Packages({ scan }: PackagesProps) {
    const fetchPackages =
        async (filterMap: Dictionary<any>, sort: Optional<DataTableSort>, skip: number, limit: number) => {
            const { packagePage } =
                await CodeController.getContainerImageScanPackagePage(
                    new Contract.CodeControllerGetContainerImageScanPackagePageRequest(
                        new CodeControllerGetContainerImageScanPackagePageRequestFilters(
                            ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.CodeControllerGetContainerImageScanPackagePageRequestProperty.DisplayName]),
                            ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.CodeControllerGetContainerImageScanPackagePageRequestProperty.FilePath]),
                            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.CodeControllerGetContainerImageScanPackagePageRequestProperty.Type]),
                            ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.CodeControllerGetContainerImageScanPackagePageRequestProperty.VulnerabilityRawId])),
                        limit,
                        scan.id,
                        skip,
                        new Contract.CodeControllerGetContainerImageScanPackagePageRequestSort(
                            _.isNil(sort) || sort.direction === DataTableSortDirection.Descending
                                ? Contract.SortDirection.Descending
                                : Contract.SortDirection.Ascending,
                            _.isNil(sort)
                                ? Contract.CodeControllerGetContainerImageScanPackagePageRequestProperty.DisplayName
                                : sort.columnId as Contract.CodeControllerGetContainerImageScanPackagePageRequestProperty)));

            return new DataTableFetchItemsResult(
                { count: packagePage.count! },
                packagePage.items,
                !packagePage.hasMore);
        };

    const getScanPackageFilterItemPage =
        async (property: Contract.CodeControllerGetContainerImageScanPackagePageRequestProperty, searchText: Optional<string>, skip: number, limit: number, data: CodeScanPackageFilterItemPageData) => {
            const { packageFilterItemPage } =
                await CodeController.getContainerImageScanPackageFilterItemPage(
                    new Contract.CodeControllerGetContainerImageScanPackageFilterItemPageRequest(
                        limit,
                        property,
                        scan.id,
                        searchText,
                        skip)) as Contract.CodeControllerGetContainerImageScanPackageFilterItemPageResponse;
            const packageFilterItemCount = packageFilterItemPage.count ?? data.count;

            return new PagedValuesFilterValuePage(
                packageFilterItemCount,
                packageFilterItemPage.emptyValue,
                packageFilterItemPage.items,
                () => ({ count: packageFilterItemCount }));
        };

    const localization =
        useLocalization(
            "views.customer.workloadAnalysis.code.scans.profile.packages",
            () => ({
                columns: {
                    displayName: "Name",
                    filePaths: {
                        empty: "No Matching Paths",
                        title: "Paths"
                    },
                    type: {
                        title: "Type",
                        [Contract.TypeNames.WorkloadResourceScanPackageType]: {
                            [Contract.WorkloadResourceScanPackageType.LanguagePackage]: "Library",
                            [Contract.WorkloadResourceScanPackageType.OperatingSystemPackage]: "Package",
                            [Contract.WorkloadResourceScanPackageType.OperatingSystem]: "Operating System"
                        }
                    },
                    version: "Version",
                    vulnerabilityRawIds: {
                        empty: "No Matching Vulnerabilities",
                        title: "Vulnerabilities"
                    }
                },
                empty: {
                    withFilter: "No Matching Software",
                    withoutFilter: "No Software"
                },
                title: "Software"
            }));

    const sortRef = useRef<DataTableSort>();
    const theme = useTheme();
    return (
        <DataTable
            columnOptions={{
                orderOptions: {
                    enabled: true,
                    persistenceStorageItem: StorageHelper.customerWorkloadAnalysisCodeScansPackagesProfileColumnOrder
                },
                resizable: true,
                selectorOptions: {
                    enabled: true,
                    persistenceStorageItem: StorageHelper.customerWorkloadAnalysisCodeScansPackagesProfileColumnSelector
                }
            }}
            emptyMessageOptions={{
                emptyMessageText:
                    new EmptyMessageText(
                        localization.empty.withoutFilter(),
                        localization.empty.withFilter())
            }}
            exportOptions={{ fileNamePrefix: localization.title() }}
            fetchItems={fetchPackages}
            filtersOptions={{
                sx: {
                    padding: theme.spacing(0, 0, 1.5, 0)
                }
            }}
            getItemId={(item: Contract.CodeContainerImagePackage) => item.id}
            onSortChanged={sort => sortRef.current = sort}>
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.CodeContainerImagePackage) => ({
                            [localization.columns.displayName()]: item.displayName
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        default: true,
                        element:
                            <PagedValuesFilter
                                getValuePage={
                                    (searchText, skip, limit, data) =>
                                        getScanPackageFilterItemPage(
                                            Contract.CodeControllerGetContainerImageScanPackagePageRequestProperty.DisplayName,
                                            searchText,
                                            skip,
                                            limit,
                                            data)}
                                placeholder={localization.columns.displayName()}/>
                    }
                }}
                id={Contract.CodeControllerGetContainerImageScanPackagePageRequestProperty.DisplayName}
                itemProperty={(_package: Contract.CodeContainerImagePackage) => _package.displayName}
                key={Contract.CodeControllerGetContainerImageScanPackagePageRequestProperty.DisplayName}
                orderable={false}
                selectorOptions={{ disabled: true }}
                title={localization.columns.displayName()}/>
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.CodeContainerImagePackage) => ({
                            [localization.columns.filePaths.title()]: item.filePaths.join("\n")
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        default: true,
                        element:
                            <PagedValuesFilter
                                getValuePage={
                                    (searchText, skip, limit, data) =>
                                        getScanPackageFilterItemPage(
                                            Contract.CodeControllerGetContainerImageScanPackagePageRequestProperty.FilePath,
                                            searchText,
                                            skip,
                                            limit,
                                            data)}
                                placeholder={localization.columns.filePaths.title()}/>
                    }
                }}
                id={Contract.CodeControllerGetContainerImageScanPackagePageRequestProperty.FilePath}
                key={Contract.CodeControllerGetContainerImageScanPackagePageRequestProperty.FilePath}
                render={
                    optionalTableCell<Contract.CodeContainerImagePackage>(
                        containerImagePackage =>
                            !_.isEmpty(containerImagePackage.filePaths) && <InlineItems
                                items={containerImagePackage.filePaths}
                                itemsPopover={
                                    items =>
                                        <SearchList
                                            emptyMessageText={new EmptyMessageText(localization.columns.filePaths.empty())}
                                            itemOptions={{ spacing: 1 }}
                                            items={items}
                                            sx={{ padding: theme.spacing(0, 1.5) }}
                                            variant="dropdown"/>}
                                variant="itemPlusItemCount">
                                {filePath =>
                                    <Typography noWrap={true}>
                                        {filePath}
                                    </Typography>}
                            </InlineItems>)}
                sortOptions={{ enabled: false }}
                title={localization.columns.filePaths.title()}/>
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.CodeContainerImagePackage) => ({
                            [localization.columns.type.title()]: localization.columns.type[Contract.TypeNames.WorkloadResourceScanPackageType][item.type]()
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                emptyValueOptions={{ enabled: true }}
                                enumType={Contract.WorkloadResourceScanPackageType}
                                enumTypeTranslator={(type: Contract.WorkloadResourceScanPackageType) => localization.columns.type[Contract.TypeNames.WorkloadResourceScanPackageType][type]()}
                                placeholder={localization.columns.type.title()}/>
                    }
                }}
                id={Contract.CodeControllerGetContainerImageScanPackagePageRequestProperty.Type}
                itemProperty={(_package: Contract.CodeContainerImagePackage) => localization.columns.type[Contract.TypeNames.WorkloadResourceScanPackageType][_package.type]()}
                key={Contract.CodeControllerGetContainerImageScanPackagePageRequestProperty.Type}
                sortOptions={{ enabled: false }}
                title={localization.columns.type.title()}/>
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.CodeContainerImagePackage) => ({
                            [localization.columns.vulnerabilityRawIds.title()]: item.vulnerabilityRawIds.join("\n")
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        default: true,
                        element:
                            <PagedValuesFilter
                                getValuePage={
                                    (searchText, skip, limit, data) =>
                                        getScanPackageFilterItemPage(
                                            Contract.CodeControllerGetContainerImageScanPackagePageRequestProperty.VulnerabilityRawId,
                                            searchText,
                                            skip,
                                            limit,
                                            data)}
                                placeholder={localization.columns.vulnerabilityRawIds.title()}/>
                    }
                }}
                id={Contract.CodeControllerGetContainerImageScanPackagePageRequestProperty.VulnerabilityRawId}
                key={Contract.CodeControllerGetContainerImageScanPackagePageRequestProperty.VulnerabilityRawId}
                render={
                    optionalTableCell<Contract.CodeContainerImagePackage>(
                        containerImagePackage =>
                            !_.isEmpty(containerImagePackage.vulnerabilityRawIds) &&
                            <InlineItems
                                items={containerImagePackage.vulnerabilityRawIds}
                                itemsPopover={
                                    items =>
                                        <SearchList
                                            emptyMessageText={new EmptyMessageText(localization.columns.vulnerabilityRawIds.empty())}
                                            itemOptions={{
                                                render:
                                                    (vulnerabilityRawId: string) =>
                                                        <InlineVulnerability rawId={vulnerabilityRawId}/>,
                                                spacing: 1
                                            }}
                                            items={items}
                                            sx={{ padding: theme.spacing(0, 1.5) }}
                                            variant="dropdown"/>}
                                variant="itemPlusItemCount">
                                {(vulnerabilityRawId: string) =>
                                    <InlineVulnerability rawId={vulnerabilityRawId}/>}
                            </InlineItems>)}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.vulnerabilityRawIds.title()}/>
        </DataTable>);
}

type CodeScanPackageFilterItemPageData = {
    count: number;
    itemNextPageSearchCursor?: Contract.ElasticsearchIndexSearchCursor;
};