import { ActionMenuItem, useLocalization, useSetRoute } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, CustomerConsoleAppUrlHelper, ScopeHelper, scopeNodeModelStore, tenantModelStore } from "../../../../../../../../common";
import { AddOrEditDefinition } from "../../useAddOrEditDefinition";
import { AddOrEdit, Welcome } from "./components";

export function useGcpAddOrEditDefinition(): AddOrEditDefinition {
    const tenantTypes =
            useMemo(
                () => [Contract.TenantType.Gcp],
                []);
    const activeScopeNodeMap = scopeNodeModelStore.useGetActiveScopeNodeMap(tenantTypes);
    const gciTenantModels = tenantModelStore.useGetGciTenants();
    const nonGcpOrganizationGciTenantModels =
        useMemo(
            () =>
                _.filter(
                    gciTenantModels,
                    gciTenantModel => _.isNil(gciTenantModel.configuration.gcpOrganizationId)),
            [gciTenantModels]);
    const setRoute = useSetRoute();

    const localization =
        useLocalization(
            "views.customer.scopes.hooks.useDefinition.hooks.useGcpDefinition.useGcpAddOrEditDefinition",
            () => ({
                addOrganization: {
                    confirmTitle: "To onboard a GCP organization, first onboard the Google Workspace that's linked to the organization. Afterwards, you can proceed with organization onboarding. Do you want to onboard the Google Workspace now?",
                    title: "Add organization"
                },
                addTenant: "Add project"
            }));

    return useMemo(
        () => ({
            additionalActionItems: () => [
                new ActionMenuItem(
                    () => {
                        if (_.isEmpty(nonGcpOrganizationGciTenantModels)) {
                            setRoute(CustomerConsoleAppUrlHelper.getConfigurationIntegrationsIdentityProviderTenantsGciRelativeUrl(true));
                        } else {
                            setRoute(CustomerConsoleAppUrlHelper.getConfigurationIntegrationsCloudProviderTenantOrganizationsGcpRelativeUrl(true));
                        }
                    },
                    localization.addOrganization.title(),
                    {
                        confirmOptions:
                            _.isEmpty(nonGcpOrganizationGciTenantModels)
                                ? { message: localization.addOrganization.confirmTitle() }
                                : undefined
                    })
            ],
            addTitle: localization.addTenant(),
            element: AddOrEdit,
            welcome:
                _(activeScopeNodeMap).
                    filter(
                        scopeNode =>
                            !ScopeHelper.isRootFolder(scopeNode.scopeNodeModel) &&
                            scopeNode.scopeNodeModel.configuration.id !== ScopeHelper.customerId).
                    isEmpty()
                    ? <Welcome/>
                    : undefined
        }),
        [activeScopeNodeMap, localization, nonGcpOrganizationGciTenantModels]);
}