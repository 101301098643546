import { Message, Shadows, useLocalization } from "@infrastructure";
import { Check as SelectedIcon } from "@mui/icons-material";
import { CircularProgress, Stack, Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { useState } from "react";
import { EntityAttributeItemDefinition, useGetEntityAttributesItemDefinition } from "../hooks";
import { useTheme } from "../themes";
import { Attribute } from "./Attribute";
import { TopItems } from "./TopItems";

type EntityAttributeDefinitionsProps = {
    breakpointToTopCount?: Dictionary<number>;
    builtInEntityAttributeTypeNames?: string[];
    customEntityAttributeDefinitionIds?: string[];
    variant?: "breakpoints" | "dynamic" | "wrap";
};

export function EntityAttributeDefinitions({ breakpointToTopCount, builtInEntityAttributeTypeNames = [], customEntityAttributeDefinitionIds = [], variant = "breakpoints" }: EntityAttributeDefinitionsProps) {
    const getEntityAttributesItemDefinition = useGetEntityAttributesItemDefinition();
    const entityAttributesItemDefinitions = getEntityAttributesItemDefinition(builtInEntityAttributeTypeNames, customEntityAttributeDefinitionIds);

    return (
        <TopItems
            breakpointToTopCount={breakpointToTopCount}
            items={entityAttributesItemDefinitions}
            orderVariant="leftToRight"
            variant={variant}>
            {(item: EntityAttributeItemDefinition) => <EntityAttributeItem definition={item}/>}
        </TopItems>);
}

type EntityAttributeItemProps = {
    definition: EntityAttributeItemDefinition;
    onToggle?: (definition: EntityAttributeItemDefinition) => Promise<void>;
    selected?: boolean;
};

export function EntityAttributeItem({ definition, onToggle, selected }: EntityAttributeItemProps) {
    const [hover, setHover] = useState(false);
    const [selectedItem, setSelectedItem] = useState(selected);
    const [toggleEntityAttributeDefinitionError, setToggleEntityAttributeDefinitionError] = useState(false);
    const [toggleEntityAttributeDefinitionRunning, setToggleEntityAttributeDefinitionRunning] = useState(false);

    async function toggle() {
        setToggleEntityAttributeDefinitionRunning(true);
        setTimeout(
            async () => {
                setToggleEntityAttributeDefinitionError(false);
                try {
                    await onToggle?.(definition);
                    setSelectedItem(!selectedItem);
                } catch {
                    setToggleEntityAttributeDefinitionError(true);
                }
                setToggleEntityAttributeDefinitionRunning(false);
            });
    }

    const localization =
        useLocalization(
            "common.entityAttributeDefinitions.entityAttributeItem",
            () => ({
                actions: {
                    toggleCustomEntityAttributeDefinition: {
                        error: "Failed to update label"
                    }
                }
            }));

    const edit = !_.isNil(onToggle);
    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            direction="row"
            sx={{
                borderRadius: theme.spacing(0.75),
                boxShadow:
                    edit && hover
                        ? theme.shadows[Shadows.Tooltip]
                        : undefined,
                cursor:
                    edit
                        ? "pointer"
                        : undefined,
                padding:
                    edit
                        ? theme.spacing(1, 2)
                        : theme.spacing(0.5, 0.75)
            }}
            onClick={() => edit && toggle()}
            onMouseEnter={() => edit && setHover(true)}
            onMouseLeave={() => edit && setHover(false)}>
            <Attribute
                backgroundColor={theme.palette.opacity(definition.color, 0.2)}
                borderColor={definition.color}
                item={
                    <Stack
                        alignItems="center"
                        direction="row"
                        spacing={0.5}
                        sx={{ height: "100%" }}>
                        <Typography noWrap={true}>
                            {definition.name}
                        </Typography>
                        {edit && toggleEntityAttributeDefinitionError &&
                            <Message
                                level="error"
                                sx={{ fontSize: "12px" }}
                                title={localization.actions.toggleCustomEntityAttributeDefinition.error()}
                                variant="minimal"/>}
                        {edit && toggleEntityAttributeDefinitionRunning
                            ? <CircularProgress size="12px"/>
                            : selectedItem && <SelectedIcon/>}
                    </Stack>}/>
        </Stack>);
}