import { useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import React from "react";
import { RiskPolicyCategoryIcon, useRiskPolicyCategoryTranslator, useTheme } from "../../..";
import { RiskPolicyCategoryItem } from "..";

type ItemTooltipProps = {
    item: RiskPolicyCategoryItem;
};

export function ItemTooltip({ item }: ItemTooltipProps) {
    const riskPolicyCategoryTranslator = useRiskPolicyCategoryTranslator();
    const localization =
        useLocalization(
            "common.riskPolicyCategoryDistributionChart.itemTooltip",
            () => ({
                riskCount: "{{count | NumberFormatter.humanize}} findings"
            }));

    const theme = useTheme();
    return (
        <Stack
            alignItems="flex-start"
            direction="row">
            <RiskPolicyCategoryIcon
                riskPolicyCategory={item.riskPolicyCategory}
                sx={{
                    color: theme.palette.text.primary,
                    fontSize: "18px",
                    padding: theme.spacing(1, 1, 0, 0)
                }}/>
            <Stack sx={{ width: theme.spacing(18) }}>
                <Typography variant="h5">
                    {riskPolicyCategoryTranslator(item.riskPolicyCategory)}
                </Typography>
                <Typography sx={{ fontSize: "11px" }}>
                    {localization.riskCount({ count: item.riskCount })}
                </Typography>
            </Stack>
        </Stack>);
}