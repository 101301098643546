import _, { Function0 } from "lodash";
import React, { useCallback, useMemo } from "react";
import { useLocalization } from "@infrastructure";
import { CodeDashboardContext, Contract, CustomerConsoleAppUrlHelper, Tenant, tenantModelStore, useDashboardContext, WidgetDefinition } from "../../../../../../..";
import { TopRisks } from "../components";

export function useGetCodeTopRiskTenantsDefinition(): () => WidgetDefinition {
    const { summary } = useDashboardContext<CodeDashboardContext>();

    const getRisksRelativeUrl =
        useCallback(
            (tenantId: string, severity?: Contract.Severity) =>
                CustomerConsoleAppUrlHelper.getCodeRisksRelativeUrl(
                    {
                        severity:
                            severity
                                ? [severity]
                                : undefined,
                        tenantIds: [tenantId]
                    }),
            []);

    const gitTenantModels = tenantModelStore.useGetFilteredGitTenants();
    const tenantIdToCodeTenantTypeMap =
        useMemo(
            () =>
                _(summary.riskTenantIdToSeverityToRiskCountMap).
                    keys().
                    keyBy().
                    mapValues(
                        tenantId =>
                            _.find(
                                gitTenantModels,
                                tenantModel => tenantModel.configuration.id === tenantId)?.configuration.codeTenantType).
                    value(),
            [summary.riskTenantIdToSeverityToRiskCountMap]);
    const riskIdToElementMapper =
        useCallback(
            (tenantId: string) =>
                <Tenant
                    iconData={{ codeTenantType: tenantIdToCodeTenantTypeMap[tenantId] }}
                    tenantId={tenantId}/>,
            []);

    const localization =
        useLocalization(
            "common.dashboard.widget.hooks.useDefinition.hooks.useGetCodeTopRiskTenantsDefinition",
            () => ({
                helpText: "View the most at-risk repositories in your organization, with a breakdown of the number of findings per severity level. Click on a repository or a specific number to assess the findings.",
                title: "Top Repositories at Risk"
            }));

    return useCallback<Function0<WidgetDefinition>>(
        () => ({
            element: (
                <TopRisks
                    getRisksRelativeUrl={getRisksRelativeUrl}
                    itemToRiskSeverityToRiskCount={summary.riskTenantIdToSeverityToRiskCountMap}>
                    {riskIdToElementMapper}
                </TopRisks>),
            options: {
                helpText: localization.helpText(),
                title: localization.title(),
                variant: "scrollable"
            }
        }),
        [localization]);
}