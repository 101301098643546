﻿import { Link, useLocalization } from "@infrastructure";
import React from "react";
import { Contract } from "../../../../../../../../../../../../../../../../common";

export function useSpotInstanceGroupRemediationSteps(riskModel: Contract.AwsEc2InstanceUserDataSecretExistsRiskModel) {
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsEc2InstanceUserDataSecretExistsRiskDefinition.hooks.useAwsEc2InstanceUserDataSecretExistsRiskTagResourceGroupDefinition.hooks.useSpotInstanceGroupRemediationSteps",
            () => ({
                resolution: {
                    step1: {
                        link: "Secrets Manager",
                        text: "In Spot, locate the Elastigroup configuration and migrate your secrets to **{{secretsManagerLink}}**"
                    },
                    step2: {
                        link: "documentation",
                        text: "Update the **Elastigroup** configuration using the following {{documentationLink}}"
                    },
                    step3: "Use the **Update Elastigroup** endpoint and provide the **group.compute.launchSpecification.userData** object"
                }
            }));
    return [
        localization.resolution.step1.text({
            secretsManagerLink:
                <Link
                    sx={{ textDecorationLine: "underline" }}
                    urlOrGetUrl={riskModel.secretsManagerDocumentationUrl}
                    variant="external">
                    {localization.resolution.step1.link()}
                </Link>
        }),
        localization.resolution.step2.text({
            documentationLink:
                <Link
                    sx={{ textDecorationLine: "underline" }}
                    urlOrGetUrl={riskModel.spotDocumentationUrl}
                    variant="external">
                    {localization.resolution.step1.link()}
                </Link>
        }),
        localization.resolution.step3()
    ];
}