import { AnalyticsEventActionType, FormLayout, ItemSelector, Message, useChangeEffect, useInputValidation, useLocalization, useTrackAnalytics } from "@infrastructure";
import { Button, CircularProgress, FormControl, FormHelperText, Stack, TextField } from "@mui/material";
import _ from "lodash";
import React, { Fragment, useMemo, useState } from "react";
import { useSetEntityPropertiesContext } from "..";
import { ConfigurationController, Contract, ResourceTagPatternItem, ResourceTagPatternsSelector, scopeSystemEntityModelStore, useScopeNavigationViewContext, useTheme } from "../../../../../../../../common";
import { resourceTagsAreEqual } from "../../utilities";

export type AddOrEditProps = {
    entityPropertyDefinitionConfiguration?: Contract.EntityPropertyDefinitionConfiguration;
};

export function AddOrEdit({ entityPropertyDefinitionConfiguration }: AddOrEditProps) {
    const { scopeNodeModel } = useScopeNavigationViewContext();
    const entityPropertyModels = scopeSystemEntityModelStore.useGetEntityPropertyDefinition();
    const setContext = useSetEntityPropertiesContext();
    const trackAnalytics = useTrackAnalytics();

    const [upsertEntityPropertyError, setUpsertEntityPropertyError] = useState(false);
    const [upsertEntityPropertyExecuting, setUpsertEntityPropertyExecuting] = useState(false);
    const [name, setName] = useState(entityPropertyDefinitionConfiguration?.identifier.name);
    const [resourceTagPatterns, setResourceTagPatterns] = useState(entityPropertyDefinitionConfiguration?.resourceTagPatterns);
    const [resourceTagPatternsValid, setResourceTagPatternsValid] = useState(false);
    const [type, setType] = useState(entityPropertyDefinitionConfiguration?.identifier.type);
    const [valid, setValid] = useState(false);

    const add = _.isNil(entityPropertyDefinitionConfiguration);

    const changed =
        useMemo(
            () => add || !resourceTagsAreEqual(resourceTagPatterns!, entityPropertyDefinitionConfiguration?.resourceTagPatterns),
            [add, entityPropertyDefinitionConfiguration?.resourceTagPatterns, resourceTagPatterns]);

    const scopeEntityPropertyDefinitionConfigurations =
        useMemo(
            () =>
                _(entityPropertyModels).
                    map(entityPropertyModel => entityPropertyModel.configuration as Contract.EntityPropertyDefinitionConfiguration).
                    filter(entityPropertyDefinitionConfiguration => entityPropertyDefinitionConfiguration.scopeId === scopeNodeModel.configuration.id).
                    value(),
            [entityPropertyModels, scopeNodeModel.configuration.id]);

    async function upsertEntityProperty() {
        setUpsertEntityPropertyExecuting(true);
        setUpsertEntityPropertyError(false);
        try {
            const { scopeSystemEntityModel } =
                await ConfigurationController.upsertEntityPropertyDefinition(
                    new Contract.ConfigurationControllerUpsertEntityPropertyDefinitionRequest(
                        entityPropertyDefinitionConfiguration?.id,
                        new Contract.EntityPropertyIdentifier(
                            type === Contract.EntityPropertyType.Custom
                                ? name!
                                : type!,
                            undefined!,
                            type!,
                            type === Contract.EntityPropertyType.Owner
                                ? Contract.EntityPropertyValueType.PrincipalReference
                                : Contract.EntityPropertyValueType.String),
                        resourceTagPatterns!,
                        scopeNodeModel.configuration.id));
            await scopeSystemEntityModelStore.notify(scopeSystemEntityModel);

            trackAnalytics(
                add
                    ? AnalyticsEventActionType.EntityPropertyInsert
                    : AnalyticsEventActionType.EntityPropertyUpdate,
                {
                    "Entity Property Type": type!
                });

            setContext(
                usersContext => ({
                    ...usersContext,
                    addOrEditOpen: false
                }));
        } catch (error) {
            setUpsertEntityPropertyError(true);
        }
        finally {
            setUpsertEntityPropertyExecuting(false);
        }
    }

    const localization =
        useLocalization(
            "views.customer.configuration.entities.entityProperties.addOrEdit",
            () => ({
                actions: {
                    add: {
                        error: "Failed to add Custom Property",
                        save: "Add",
                        title: "Add a New Custom Property"
                    },
                    edit: {
                        error: "Failed to edit Custom Property",
                        save: "Save",
                        title: "Edit Custom Property"
                    }
                },
                fields: {
                    name: {
                        error: {
                            exists: "Name already exists",
                            required: "Name cannot be empty"
                        },
                        title: "Name"
                    },
                    resourceTagPatterns: {
                        error: "Tag patterns cannot be empty",
                        title: "Tag Patterns"
                    },
                    type: {
                        error: "{{type}} already exists",
                        title: "Type",
                        [Contract.TypeNames.EntityPropertyType]: {
                            [Contract.EntityPropertyType.Custom]: "Custom",
                            [Contract.EntityPropertyType.Environment]: "Environment",
                            [Contract.EntityPropertyType.Owner]: "Owner"
                        }
                    }
                }
            }));

    const [nameValidationController, nameValidationMessage] =
        useInputValidation(
            () => {
                if (!add ||
                    type !== Contract.EntityPropertyType.Custom) {
                    return undefined;
                }

                const validationName = name?.trim();
                if (_.isEmpty(validationName)) {
                    return localization.fields.name.error.required();
                }

                const builtInEntityPropertyName =
                    _(Contract.EntityPropertyType).
                        filter(type => type !== Contract.EntityPropertyType.Custom).
                        some(builtInEntityPropertyType => builtInEntityPropertyType === validationName);

                if (builtInEntityPropertyName ||
                    _.some(
                        scopeEntityPropertyDefinitionConfigurations,
                        scopeEntityPropertyDefinitionConfiguration => scopeEntityPropertyDefinitionConfiguration.identifier.name === validationName)) {
                    return localization.fields.name.error.exists();
                }

                return undefined;
            },
            [name]);

    const [typeValidationController, typeValidationMessage] =
        useInputValidation(
            () => {
                if (!add ||
                    type === Contract.EntityPropertyType.Custom) {
                    return undefined;
                }

                if (_.some(
                    scopeEntityPropertyDefinitionConfigurations,
                    scopeEntityPropertyDefinitionConfiguration => scopeEntityPropertyDefinitionConfiguration.identifier.type === type)) {
                    return localization.fields.type.error({ type });
                }

                return undefined;
            },
            [type]);

    useChangeEffect(
        () => {
            setValid(
                nameValidationController.isValid() &&
                resourceTagPatternsValid &&
                !_.isNil(type) &&
                typeValidationController.isValid());
        },
        [name, resourceTagPatternsValid, type]);

    const theme = useTheme();
    return (
        <FormLayout
            footerOptions={{
                contentElement:
                    <Stack
                        alignItems="center"
                        direction="row"
                        justifyContent="flex-end"
                        spacing={1}>
                        <Fragment>
                            {upsertEntityPropertyExecuting && (
                                <CircularProgress
                                    size={theme.spacing(2)}
                                    variant="indeterminate"/>)}
                            <Button
                                disabled={!valid || upsertEntityPropertyExecuting || !changed}
                                onClick={() => upsertEntityProperty()}>
                                {add
                                    ? localization.actions.add.save()
                                    : localization.actions.edit.save()}
                            </Button>
                        </Fragment>
                    </Stack>
            }}
            titleOptions={{
                text:
                    add
                        ? localization.actions.add.title()
                        : localization.actions.edit.title()
            }}>
            <Stack spacing={2}>
                <ItemSelector
                    disabled={!add}
                    fullWidth={true}
                    items={[
                        Contract.EntityPropertyType.Owner,
                        Contract.EntityPropertyType.Environment,
                        Contract.EntityPropertyType.Custom
                    ]}
                    placeholder={localization.fields.type.title()}
                    selectedItem={type}
                    sorted={false}
                    onSelectedItemChanged={setType}>
                    {(type: Contract.EntityPropertyType) => localization.fields.type[Contract.TypeNames.EntityPropertyType][type]()}
                </ItemSelector>
                {!_.isNil(typeValidationMessage) && (
                    <FormHelperText error={true}>{typeValidationMessage}</FormHelperText>)}
                {type === Contract.EntityPropertyType.Custom &&
                    <FormControl
                        fullWidth={true}
                        variant="standard">
                        <TextField
                            disabled={
                                !add ||
                                upsertEntityPropertyExecuting}
                            label={localization.fields.name.title()}
                            value={name}
                            variant="outlined"
                            onChange={event => setName(event.target.value)}/>
                        {!_.isNil(nameValidationMessage) && (
                            <FormHelperText error={true}>{nameValidationMessage}</FormHelperText>)}
                    </FormControl>}
                <ResourceTagPatternsSelector
                    initialItems={
                        _.map(
                            resourceTagPatterns,
                            resourceTagPattern => ResourceTagPatternItem.create(resourceTagPattern))}
                    valueDefaultWildCard={true}
                    onItemsChanged={items => setResourceTagPatterns(items)}
                    onValidChanged={valid => setResourceTagPatternsValid(valid)}/>
                {upsertEntityPropertyError && (
                    <Message
                        level="error"
                        title={
                            add
                                ? localization.actions.add.error()
                                : localization.actions.edit.error()}/>)}
            </Stack>
        </FormLayout>);
}