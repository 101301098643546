import { useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import React from "react";
import { useTheme } from "../../../../..";

type ScoreCardSubtitleProps = {
    total: number;
    value: number;
    variant?: "minimal" | "full";
};

export function ScoreCardSubtitle({ total, value, variant }: ScoreCardSubtitleProps) {
    const localization =
        useLocalization(
            "common.vulnerability.scoreCards.scoreCardSubtitle",
            () => ({
                title: "{{value}}/{{total}}"
            }));
    const theme = useTheme();
    return (
        <Stack
            alignItems="baseline"
            direction="row"
            sx={{
                color: theme.palette.text.secondary,
                fontSize: "12px"
            }}>
            <Typography>
                {localization.title({
                    total,
                    value:
                        <Typography
                            component="span"
                            sx={{
                                color: theme.palette.text.primary,
                                fontSize:
                                    variant === "minimal"
                                        ? "14px"
                                        : "16px",
                                fontWeight: 500
                            }}>
                            {value}
                        </Typography>
                })}
            </Typography>
        </Stack>);
}