import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function CiscoIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <g clipPath="url(#clip0_58343_2102)">
                <path
                    d="M7.84428 14.4088H6.79318V18.5742H7.84428V14.4088Z"
                    fill="#00BCEB"/>
                <path
                    d="M16.3309 15.5961C16.292 15.5766 15.9416 15.382 15.4355 15.382C14.7543 15.382 14.2871 15.8492 14.2871 16.4915C14.2871 17.1144 14.7348 17.601 15.4355 17.601C15.9221 17.601 16.2725 17.4063 16.3309 17.3869V18.5158C16.1946 18.5353 15.8443 18.6521 15.3771 18.6521C14.1898 18.6521 13.1581 17.8346 13.1581 16.4915C13.1581 15.2457 14.0924 14.3309 15.3771 14.3309C15.8637 14.3309 16.2336 14.4477 16.3309 14.4866V15.5961Z"
                    fill="#00BCEB"/>
                <path
                    d="M5.33333 15.5961C5.2944 15.5766 4.94404 15.382 4.45742 15.382C3.77616 15.382 3.309 15.8492 3.309 16.4915C3.309 17.1144 3.75669 17.601 4.45742 17.601C4.94404 17.601 5.2944 17.4063 5.33333 17.3869V18.5158C5.19708 18.5548 4.84671 18.6715 4.37956 18.6715C3.19221 18.6715 2.16058 17.854 2.16058 16.511C2.16058 15.2652 3.09489 14.3504 4.37956 14.3504C4.86618 14.3504 5.23601 14.4672 5.33333 14.5061V15.5961Z"
                    fill="#00BCEB"/>
                <path
                    d="M21.8394 16.4915C21.8394 17.6788 20.9246 18.6521 19.6399 18.6521C18.3552 18.6521 17.4404 17.6788 17.4404 16.4915C17.4404 15.3041 18.3552 14.3309 19.6399 14.3309C20.9246 14.3309 21.8394 15.3041 21.8394 16.4915ZM19.6399 15.4015C19.017 15.4015 18.5499 15.8881 18.5499 16.4915C18.5499 17.0949 19.017 17.5815 19.6399 17.5815C20.2628 17.5815 20.7299 17.0949 20.7299 16.4915C20.7299 15.8881 20.2628 15.4015 19.6399 15.4015Z"
                    fill="#00BCEB"/>
                <path
                    d="M11.8345 15.3625C11.8151 15.3625 11.3674 15.2263 11.017 15.2263C10.5888 15.2263 10.3747 15.3625 10.3747 15.5766C10.3747 15.8297 10.6861 15.927 10.8613 15.9659L11.1533 16.0633C11.8345 16.2774 12.146 16.7445 12.146 17.2506C12.146 18.3017 11.2311 18.6521 10.4136 18.6521C9.84914 18.6521 9.32359 18.5547 9.28466 18.5353V17.5815C9.38199 17.601 9.81021 17.7372 10.2774 17.7372C10.8029 17.7372 11.0365 17.5815 11.0365 17.3479C11.0365 17.1338 10.8224 17.017 10.5693 16.9392C10.5109 16.9197 10.4136 16.8808 10.3358 16.8613C9.75182 16.6667 9.2652 16.3358 9.2652 15.635C9.2652 14.8564 9.84914 14.3309 10.8224 14.3309C11.3285 14.3309 11.8151 14.4477 11.854 14.4672V15.3625H11.8345Z"
                    fill="#00BCEB"/>
                <path
                    d="M1.05109 9.93187C1.05109 9.6399 0.817518 9.40633 0.525547 9.40633C0.233577 9.40633 0 9.6399 0 9.93187V11.0219C0 11.3139 0.233577 11.5474 0.525547 11.5474C0.817518 11.5474 1.05109 11.3139 1.05109 11.0219V9.93187Z"
                    fill="#00BCEB"/>
                <path
                    d="M3.91242 8.49149C3.91242 8.19952 3.67885 7.96594 3.38688 7.96594C3.0949 7.96594 2.86133 8.19952 2.86133 8.49149V11.0219C2.86133 11.3139 3.0949 11.5475 3.38688 11.5475C3.67885 11.5475 3.91242 11.3139 3.91242 11.0219V8.49149Z"
                    fill="#00BCEB"/>
                <path
                    d="M6.79319 6.52555C6.79319 6.23358 6.55961 6 6.26764 6C5.97567 6 5.7421 6.23358 5.7421 6.52555V12.073C5.7421 12.365 5.97567 12.5985 6.26764 12.5985C6.55961 12.5985 6.79319 12.365 6.79319 12.073V6.52555Z"
                    fill="#00BCEB"/>
                <path
                    d="M9.65449 8.49149C9.65449 8.19952 9.42091 7.96594 9.12894 7.96594C8.83697 7.96594 8.60339 8.19952 8.60339 8.49149V11.0219C8.60339 11.3139 8.83697 11.5475 9.12894 11.5475C9.42091 11.5475 9.65449 11.3139 9.65449 11.0219V8.49149Z"
                    fill="#00BCEB"/>
                <path
                    d="M12.5158 9.93187C12.5158 9.6399 12.2822 9.40633 11.9903 9.40633C11.6983 9.40633 11.4647 9.6399 11.4647 9.93187V11.0219C11.4647 11.3139 11.6983 11.5474 11.9903 11.5474C12.2822 11.5474 12.5158 11.3139 12.5158 11.0219V9.93187Z"
                    fill="#00BCEB"/>
                <path
                    d="M15.3966 8.49149C15.3966 8.19952 15.163 7.96594 14.871 7.96594C14.5791 7.96594 14.3455 8.19952 14.3455 8.49149V11.0219C14.3455 11.3139 14.5791 11.5475 14.871 11.5475C15.163 11.5475 15.3966 11.3139 15.3966 11.0219V8.49149Z"
                    fill="#00BCEB"/>
                <path
                    d="M18.2579 6.52555C18.2579 6.23358 18.0243 6 17.7324 6C17.4404 6 17.2068 6.23358 17.2068 6.52555V12.073C17.2068 12.365 17.4404 12.5985 17.7324 12.5985C18.0243 12.5985 18.2579 12.365 18.2579 12.073V6.52555Z"
                    fill="#00BCEB"/>
                <path
                    d="M21.1387 8.49149C21.1387 8.19952 20.9051 7.96594 20.6131 7.96594C20.3212 7.96594 20.0876 8.19952 20.0876 8.49149V11.0219C20.0876 11.3139 20.3212 11.5475 20.6131 11.5475C20.9051 11.5475 21.1387 11.3139 21.1387 11.0219V8.49149Z"
                    fill="#00BCEB"/>
                <path
                    d="M24 9.93187C24 9.6399 23.7664 9.40633 23.4745 9.40633C23.1825 9.40633 22.9489 9.6399 22.9489 9.93187V11.0219C22.9489 11.3139 23.1825 11.5474 23.4745 11.5474C23.7664 11.5474 24 11.3139 24 11.0219V9.93187Z"
                    fill="#00BCEB"/>
            </g>
            <defs>
                <clipPath id="clip0_58343_2102">
                    <rect
                        fill="white"
                        height="24"
                        width="24"/>
                </clipPath>
            </defs>
        </SvgIcon>);
}