import _, { Function0 } from "lodash";
import React, { useCallback, useMemo } from "react";
import { useLocalization } from "@infrastructure";
import { Contract, TenantHelper, tenantModelStore, TenantTypeStatusItem, useCloudProviderTenantTranslator, useTenantTypeToTenantTypeStatusItem, useTenantTypeTranslator, WidgetDefinition } from "../../../../../../..";
import { SummaryDashboardContext, useDashboardContext } from "../../../../../Dashboard";
import { Tenants } from "../components";

export function useGetTenantsDefinition(): () => WidgetDefinition {
    const localization = useLocalization(
        "common.dashboard.widget.hooks.useDefinition.hooks.useGetTenantsDefinition",
        () => ({
            title: "Accounts"
        }));

    return useCallback<Function0<WidgetDefinition>>(
        () => ({
            element: <Core/>,
            options: { title: localization.title() }
        }),
        [localization]);
}

function Core() {
    const { analyzing } = useDashboardContext<SummaryDashboardContext>();
    const filteredCloudProviderTenants = tenantModelStore.useGetFilteredCloudProviderTenants(TenantHelper.DashboardTenantTypes);
    const tenantTypeToCloudProviderTenantModelsMap =
        useMemo(
            () =>
                _(filteredCloudProviderTenants).
                    filter(tenantModel => !_.isNil(tenantModel.state.orchestration.orchestrationTypeToDataMap[Contract.OrchestrationType.Analysis]?.previousTimeRange?.endTime)).
                    groupBy(tenantModel => tenantModel.tenantType).
                    value(),
            [filteredCloudProviderTenants]);

    const cloudProviderTenantTranslator = useCloudProviderTenantTranslator();
    const tenantTypeToTenantsStatusViewItem = useTenantTypeToTenantTypeStatusItem(tenantTypeToCloudProviderTenantModelsMap);
    const tenantTypeTranslator = useTenantTypeTranslator();

    const tenantTypeToTenantTypeStatusItem =
        analyzing
            ? _(TenantHelper.CloudProviderTenantTypes).
                keyBy().
                mapValues(
                    tenantType =>
                        new TenantTypeStatusItem(
                            () => "",
                            300,
                            {},
                            cloudProviderTenantTranslator(300, tenantType),
                            tenantType,
                            tenantTypeTranslator(tenantType),
                            {},
                            true)).
                value()
            : tenantTypeToTenantsStatusViewItem;

    return <Tenants tenantTypeToTenantTypeStatusItem={tenantTypeToTenantTypeStatusItem}/>;
}