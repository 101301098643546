import { ApiError, DataTableActions, DataTableColumn, EmptyMessageText, useLocalization, useSetWizardContext, useWizardContext } from "@infrastructure";
import { Box } from "@mui/material";
import _ from "lodash";
import React, { ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { Contract, ItemTable, RiskController, useTheme } from "../../..";
import { Change } from "../../Change";
import { StatusCell } from "./StatusCell";

type SummaryItemProps = {
    confirm: string;
    items: SummaryItemItem[];
};

export function SummaryItem({ confirm, items }: SummaryItemProps) {
    const dataTableActionsRef = useRef<DataTableActions>();
    const localization =
        useLocalization(
            "common.riskResolutionAutomation.summaryItem",
            () => ({
                actions: {
                    next: {
                        title: {
                            apply: "Apply",
                            failed: "Retry",
                            success: "Done"
                        }
                    }
                },
                columns: {
                    action: "Action"
                },
                fields: {
                    empty: "No remediation"
                }
            }));

    const { useNextEffect } = useWizardContext();
    const setWizardContext = useSetWizardContext();

    useEffect(
        () => {
            setWizardContext(
                wizardContext => ({
                    ...wizardContext,
                    nextConfirmMessage: confirm,
                    nextTitle: localization.actions.next.title.apply()
                }));
        },
        []);

    const riskIdToChangeIdsMap =
        useMemo(
            () =>
                _(items).
                    flatMap(item => item.changeDatas).
                    groupBy(changeData => changeData.riskId).
                    mapValues(
                        changeDatas =>
                            _.map(
                                changeDatas,
                                changeData => changeData.id)).
                    value(),
            [items]);

    const successRef = useRef(false);
    const [, setRefresh] = useState({});
    useNextEffect(
        async () => {
            if (successRef.current) {
                return undefined;
            }

            for (const item of items) {
                item.executing = false;
                item.result = undefined;
                item.resolveRiskAutomationResponse = undefined;
            }

            setRefresh({});
            dataTableActionsRef.current?.reset();

            for (const item of items) {
                item.executing = true;
                setRefresh({});

                let success = await item.execute();

                if (!success && item !== _.head(items) ||
                    item === _.last(items) && success) {
                    const riskIdToResolvedChangeIdsMap =
                        _(items).
                            takeWhile(currentItem => currentItem !== item).
                            concatIf(success, item).
                            flatMap(item => item.changeDatas).
                            groupBy(changeData => changeData.riskId).
                            mapValues(
                                changeDatas =>
                                    _.map(
                                        changeDatas,
                                        changeData => changeData.id)).
                            value();
                    try {
                        await RiskController.updateRiskChanges(
                            new Contract.RiskControllerUpdateRiskChangesRequest(
                                riskIdToChangeIdsMap,
                                riskIdToResolvedChangeIdsMap));
                    } catch {
                        item.result = "error";
                        success = false;
                    }
                }

                item.executing = false;
                setRefresh({});

                if (!success) {
                    setWizardContext(
                        wizardContext => ({
                            ...wizardContext,
                            backDisabled: true,
                            nextConfirmMessage: undefined,
                            nextTitle: localization.actions.next.title.failed()
                        }));
                    dataTableActionsRef.current?.reset();

                    return false;
                }
            }

            successRef.current = true;
            dataTableActionsRef.current?.reset();
            setRefresh({});
            setWizardContext(
                wizardContext => ({
                    ...wizardContext,
                    backDisabled: true,
                    nextConfirmMessage: undefined,
                    nextTitle: localization.actions.next.title.success()
                }));

            return false;
        },
        []);

    const theme = useTheme();
    return (
        <Box
            sx={{
                height: "100%",
                padding: theme.spacing(2)
            }}>
            <ItemTable
                actionsRef={dataTableActionsRef}
                columnIdToGetItemValueMap={{
                    [SummaryItemTableColumnId.Action]: item => item.getTitle()
                }}
                defaultSortColumnIdOrIds={SummaryItemTableColumnId.Action}
                emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.fields.empty()) }}
                getItemId={(item: SummaryItemItem) => item.getId()}
                items={items}
                sx={{
                    border: theme.border.primary,
                    borderRadius: theme.spacing(0.75),
                    padding: theme.spacing(1, 0)
                }}>
                {() =>
                    [
                        <DataTableColumn
                            id={SummaryItemTableColumnId.Action}
                            itemProperty={(item: SummaryItemItem) => item.getTitle()}
                            key={SummaryItemTableColumnId.Action}
                            title={localization.columns.action()}/>,
                        <DataTableColumn
                            id={SummaryItemTableColumnId.Status}
                            key={SummaryItemTableColumnId.Status}
                            render={StatusCell}/>
                    ]}
            </ItemTable>
        </Box>);
}

export abstract class SummaryItemItem {
    public resolveRiskAutomationResponse?: Contract.RiskControllerResolveRiskAutomationResponse;
    public executing = false;
    public result?: "error" | "outdated" | "success";
    protected riskId: string;

    protected constructor(
        protected change: Contract.Change,
        public changeDatas: Contract.ChangeModelChangeData[]) {
        this.riskId =
            _.find(
                changeDatas,
                changeData => changeData.id === change.id)!.riskId;
    }

    public async execute() {
        try {
            this.result =
                await this.executeCore()
                    ? "success"
                    : "error";
        } catch (error) {
            this.result =
                error instanceof ApiError && error.statusCode === 400
                    ? "outdated"
                    : "error";
        }

        return this.result === "success";
    }

    public getId(): string {
        return this.change.id;
    }

    public getTitle(): ReactNode {
        return (
            <Change
                change={this.change}
                entityLinkDisabled={true}/>);
    }

    protected abstract executeCore(): Promise<boolean>;
}

export enum SummaryItemTableColumnId {
    Action = "action",
    Status = "status"
}