import { Steps, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, Entity, entityModelStore, InlineEntities } from "../../../../../../../../../../../../../../../../common";
import { useAwsResourceAccessLevelTranslator } from "../../../../../../../../../../../../../../../../tenants";
import { useOpenRiskedEntityRisksStep } from "../../../../../../../useOpenRiskedEntityRisksStep";
import { useResourceGeneralInformationStep } from "../../../../../../../useResourceGeneralInformationStep";

export type VolumeContextSectionProps = {
    risk: Contract.AwsEc2SnapshotEncryptionDisabledRisk;
    volumeModel: Contract.AwsEc2VolumeModel;
};

export function VolumeContextSection({ risk, volumeModel }: VolumeContextSectionProps) {
    const riskData = risk.data as Contract.AwsEc2SnapshotEncryptionDisabledRiskVolumeData;
    const volume = volumeModel.entity as Contract.AwsEc2Volume;
    const imageModels = entityModelStore.useGet(riskData.imageIds);
    const snapshotModels = entityModelStore.useGet(risk.aggregatedEntityIds);


    const [accessLevelSnapshotModels, sensitiveSnapshotModels] =
        useMemo(
            () => {
                const accessLevelSnapshotModels =
                    _.filter(
                        snapshotModels,
                        snapshotModel => (snapshotModel as Contract.AwsEc2SnapshotModel).accessLevel === risk.accessLevel);
                const sensitiveSnapshotModels =
                    _.filter(
                        snapshotModels,
                        snapshotModel => snapshotModel.entityConfiguration?.sensitive === true);

                return [accessLevelSnapshotModels, sensitiveSnapshotModels];
            },
            [snapshotModels]);

    const sensitiveImageModels =
        useMemo(
            () =>
                _.filter(
                    imageModels,
                    imageModel => imageModel.entityConfiguration?.sensitive === true),
            [imageModels]);


    const openRiskedEntityRisksStep = useOpenRiskedEntityRisksStep(volumeModel, risk.id);
    const resourceGeneralInformationStep = useResourceGeneralInformationStep(volumeModel);

    const resourceAccessLevelTranslator = useAwsResourceAccessLevelTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsEc2SnapshotEncryptionDisabledRiskDefinition.hooks.useAwsEc2SnapshotEncryptionDisabledRiskVolumeDefinition.volumeContextSection",
            () => ({
                instanceTerminationDeletion: {
                    false: "The EBS volume's data is marked to **persist** on instance termination",
                    true: "The EBS volume's data is marked to **delete** on instance termination"
                },
                networkAccess: {
                    all: "All the EBS snapshots have **{{accessLevel}}** access",
                    some: "{{accessLevelSnapshots}} out of {{snapshotIds}} have **{{accessLevel}}** access"
                },
                sensitive: [
                    "The {{resources}} is marked as sensitive",
                    "The {{resources}} are marked as sensitive"],
                snapshots: "The EBS volume has {{snapshots}} without KMS encryption",
                volumeAssociation: {
                    images: "The EBS volume is associated with {{images}}",
                    imagesAndInstances: "The EBS volume is associated with {{instances}} and {{images}}",
                    instances: "The EBS volume is associated with {{instances}}"
                },
                volumeSizeRoot: {
                    false: "The EBS volume's size is {{storageSize | NumberFormatter.storage}}",
                    true: "The EBS volume's size is {{storageSize | NumberFormatter.storage}} and it is a root volume"
                }
            }));

    return (
        <Steps variant="bullets">
            {_.filter([
                resourceGeneralInformationStep,
                volumeModel.unknown
                    ? undefined
                    : volumeModel.root
                        ? localization.volumeSizeRoot.true({ storageSize: volume.size })
                        : localization.volumeSizeRoot.false({ storageSize: volume.size }),
                volumeModel.unknown
                    ? undefined
                    : volume.instanceTerminationDeletion
                        ? localization.instanceTerminationDeletion.true()
                        : localization.instanceTerminationDeletion.false(),
                !_.isEmpty(riskData.instanceIds) && !_.isEmpty(riskData.imageIds)
                    ? localization.volumeAssociation.imagesAndInstances({
                        images:
                            <InlineEntities
                                entityIdsOrModels={riskData.imageIds}
                                entityTypeName={Contract.TypeNames.AwsEc2Image}
                                variant="itemCountAndType"/>,
                        instances:
                            <InlineEntities
                                entityIdsOrModels={riskData.instanceIds}
                                entityTypeName={Contract.TypeNames.AwsEc2Instance}
                                variant="itemCountAndType"/>
                    })
                    : !_.isEmpty(riskData.instanceIds)
                        ? localization.volumeAssociation.instances({
                            instances:
                                <InlineEntities
                                    entityIdsOrModels={riskData.instanceIds}
                                    entityTypeName={Contract.TypeNames.AwsEc2Instance}
                                    variant="itemCountAndType"/>
                        })
                        : !_.isEmpty(riskData.imageIds)
                            ? localization.volumeAssociation.images({
                                images:
                                    <InlineEntities
                                        entityIdsOrModels={riskData.imageIds}
                                        entityTypeName={Contract.TypeNames.AwsEc2Image}
                                        variant="itemCountAndType"/>
                            })
                            : undefined,
                volumeModel.unknown ||
                _.isEmpty(risk.aggregatedEntityIds)
                    ? undefined
                    : localization.snapshots({
                        snapshots:
                            <InlineEntities
                                entityIdsOrModels={risk.aggregatedEntityIds}
                                entityTypeName={Contract.TypeNames.AwsEc2Snapshot}
                                variant="itemCountAndType"/>
                    }),
                volumeModel?.entityConfiguration?.sensitive &&
                !_.isEmpty(risk.aggregatedEntityIds)
                    ? localization.sensitive(
                        1,
                        {
                            resources:
                                <Entity
                                    entityIdOrModel={volumeModel}
                                    variant="text"/>
                        })
                    : undefined,
                _.isEmpty(sensitiveImageModels)
                    ? undefined
                    : localization.sensitive(
                        _.size(sensitiveImageModels),
                        {
                            resources:
                                <InlineEntities
                                    entityIdsOrModels={sensitiveImageModels}
                                    entityTypeName={Contract.TypeNames.AwsEc2Image}
                                    variant="itemOrItemCountAndType"/>
                        }),
                _.isEmpty(sensitiveSnapshotModels)
                    ? undefined
                    : localization.sensitive(
                        _.size(sensitiveSnapshotModels),
                        {
                            resources:
                                <InlineEntities
                                    entityIdsOrModels={sensitiveSnapshotModels}
                                    entityTypeName={Contract.TypeNames.AwsEc2Snapshot}
                                    variant="itemOrItemCountAndType"/>
                        }),
                _.isNil(risk.accessLevel) ||
                risk.accessLevel === Contract.AwsResourceAccessLevel.Internal ||
                _.isEmpty(risk.aggregatedEntityIds)
                    ? undefined
                    : (accessLevelSnapshotModels.length === risk.aggregatedEntityIds.length)
                        ? localization.networkAccess.all({ accessLevel: resourceAccessLevelTranslator(risk.accessLevel) })
                        : localization.networkAccess.some({
                            accessLevel: resourceAccessLevelTranslator(risk.accessLevel),
                            accessLevelSnapshots: (
                                <InlineEntities
                                    entityIdsOrModels={accessLevelSnapshotModels}
                                    entityTypeName={Contract.TypeNames.AwsEc2Snapshot}
                                    variant="itemCountAndType"/>),
                            snapshotIds: (
                                <InlineEntities
                                    entityIdsOrModels={risk.aggregatedEntityIds}
                                    entityTypeName={Contract.TypeNames.AwsEc2Snapshot}
                                    variant="itemCountAndType"/>)
                        }),
                openRiskedEntityRisksStep
            ])}
        </Steps>);
}