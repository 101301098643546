import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function FolderIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                clipRule="evenodd"
                d="M8.62775 3.69014C8.24787 3.25181 7.6964 3 7.11637 3H3.11852C1.95333 3 1.01059 4.1 1.01059 5.44444L1 17.1111C1 18.4556 1.95333 19.5556 3.11852 19.5556H20.8815C22.0467 19.5556 23 18.4556 23 17.1111V7.88889C23 6.54444 22.0467 5.44444 20.8815 5.44444H11.9747C10.8146 5.44444 9.71167 4.94082 8.95191 4.06417L8.62775 3.69014Z"
                fill="#5C728E"
                fillRule="evenodd"/>
            <path
                d="M1 9C1 7.89543 1.89543 7 3 7H21C22.1046 7 23 7.89543 23 9V19C23 20.1046 22.1046 21 21 21H3C1.89543 21 1 20.1046 1 19V9Z"
                fill="#A5B8CF"/>
        </SvgIcon>);
}