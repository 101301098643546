import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function EntityKubernetesIcon(props: SvgIconProps) {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <path
                clipRule="evenodd"
                d="M9.52076 3.3264C9.79996 2.57187 10.8672 2.57186 11.1464 3.3264L11.9387 5.46774C12.1548 6.05167 12.6152 6.51207 13.1991 6.72814L15.3405 7.52051C16.095 7.79971 16.095 8.86692 15.3405 9.14612L13.1991 9.93849C12.6152 10.1546 12.1548 10.615 11.9387 11.1989L11.1464 13.3402C10.8672 14.0948 9.79996 14.0948 9.52076 13.3402L8.72839 11.1989C8.51231 10.615 8.05192 10.1546 7.46798 9.93849L5.32664 9.14612C4.57211 8.86692 4.57211 7.79972 5.32664 7.52051L7.46798 6.72815C8.05192 6.51207 8.51231 6.05167 8.72839 5.46774L9.52076 3.3264ZM10.3336 4.20347L9.72876 5.83791C9.40465 6.71381 8.71406 7.40441 7.83815 7.72852L6.20372 8.33332L7.83816 8.93811C8.71406 9.26223 9.40465 9.95282 9.72876 10.8287L10.3336 12.4632L10.9384 10.8287C11.2625 9.95282 11.9531 9.26223 12.829 8.93811L14.4634 8.33332L12.829 7.72852C11.9531 7.40441 11.2625 6.71381 10.9384 5.83791L10.3336 4.20347Z"
                fillRule="evenodd"/>
            <path d="M3.5237 11.0536C3.57286 10.9207 3.7608 10.9207 3.80996 11.0536C4.11912 11.8891 4.77786 12.5478 5.61335 12.8569C5.74622 12.9061 5.74622 13.094 5.61335 13.1432C4.77786 13.4524 4.11912 14.1111 3.80996 14.9466C3.7608 15.0795 3.57286 15.0795 3.5237 14.9466C3.21454 14.1111 2.5558 13.4524 1.72031 13.1432C1.58744 13.094 1.58744 12.9061 1.72031 12.8569C2.5558 12.5478 3.21454 11.8891 3.5237 11.0536Z"/>
            <path d="M2.81597 0.49733C2.87919 0.326494 3.12081 0.326494 3.18403 0.49733C3.58152 1.57154 4.42846 2.41848 5.50267 2.81597C5.67351 2.87919 5.67351 3.12081 5.50267 3.18403C4.42846 3.58152 3.58152 4.42846 3.18403 5.50267C3.12081 5.67351 2.87919 5.67351 2.81597 5.50267C2.41848 4.42846 1.57154 3.58152 0.49733 3.18403C0.326494 3.12081 0.326494 2.87919 0.49733 2.81597C1.57154 2.41848 2.41848 1.57154 2.81597 0.49733Z"/>
        </SvgIcon>);
}