import { Optional } from "@infrastructure";
import _ from "lodash";
import { VulnerabilityEventType } from "../../../../../../../../../../../../../common";
import { VulnerabilityEventData } from "../../../../../utilities";

export function getFilteredEvents(
    activeEventType: Optional<VulnerabilityEventType>,
    events: Optional<VulnerabilityEventData[]>,
    hoverEventType: Optional<VulnerabilityEventType>) {
    return _.filter(
        events,
        event =>
            _.isNil(activeEventType) &&
            _.isNil(hoverEventType) ||
            event.eventType === activeEventType ||
            event.eventType === hoverEventType);
}