import { useMemo } from "react";
import { useAadDefaultDirectoryEntityDefinition, useAadDirectoryApplicationCertificateDefinition, useAadDirectoryApplicationDefinition, useAadDirectoryApplicationSecretDefinition, useAadDirectoryApplicationServicePrincipalDefinition, useAadDirectoryDirectoryRoleDefinitionDefinition, useAadDirectoryDirectoryRoleManagementPolicyAssignmentDefinition, useAadDirectoryGroupDefinition, useAadDirectoryManagedIdentityServicePrincipalDefinition, useAadDirectoryUserDefinition, useAadTenantEntityDefinition } from ".";
import { DefinitionOptions } from "../..";
import { Contract } from "../../../../../../../../../../common";

export function useAadDirectoryEntityDefinition(entityModel: Contract.EntityModel, options?: DefinitionOptions) {
    const useDefinition =
        useMemo(
            () => {
                switch (entityModel.entity.typeName) {
                    case Contract.TypeNames.AadDirectoryApplication:
                        return useAadDirectoryApplicationDefinition;
                    case Contract.TypeNames.AadDirectoryApplicationCertificate:
                        return useAadDirectoryApplicationCertificateDefinition;
                    case Contract.TypeNames.AadDirectoryApplicationSecret:
                        return useAadDirectoryApplicationSecretDefinition;
                    case Contract.TypeNames.AadDirectoryApplicationServicePrincipal:
                        return useAadDirectoryApplicationServicePrincipalDefinition;
                    case Contract.TypeNames.AadDirectoryDirectoryRoleDefinition:
                        return useAadDirectoryDirectoryRoleDefinitionDefinition;
                    case Contract.TypeNames.AadDirectoryDirectoryRoleManagementPolicyAssignment:
                        return useAadDirectoryDirectoryRoleManagementPolicyAssignmentDefinition;
                    case Contract.TypeNames.AadDirectoryGroup:
                        return useAadDirectoryGroupDefinition;
                    case Contract.TypeNames.AadDirectoryManagedIdentityServicePrincipal:
                        return useAadDirectoryManagedIdentityServicePrincipalDefinition;
                    case Contract.TypeNames.AadDirectoryUser:
                        return useAadDirectoryUserDefinition;
                    case Contract.TypeNames.AadTenantEntity:
                        return useAadTenantEntityDefinition;
                    default:
                        return useAadDefaultDirectoryEntityDefinition;
                }
            },
            []);

    return useDefinition(entityModel as Contract.AadDirectoryEntityModel, options);
}