import { ActionMenuItem, DataTableColumnRenderProps, DeleteIcon, EditIcon, Menu, Message, useLocalization } from "@infrastructure";
import { CircularProgress, Stack } from "@mui/material";
import React, { useState } from "react";
import { AzureDevOpsContext, useSetAzureDevOpsContext } from "../../..";
import { ConfigurationController, Contract, scopeSystemEntityModelStore, tenantModelStore, useTheme } from "../../../../../../../../../../../../../../../common";

export function ActionsCell({ item }: DataTableColumnRenderProps<Contract.CodeOrganizationModel>) {
    const setAzureDevOpsContext = useSetAzureDevOpsContext();

    const [deleteOrganizationExecuting, setDeleteOrganizationExecuting] = useState(false);
    const [deleteOrganizationError, setDeleteOrganizationError] = useState(false);

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCodeOrganizationItems.azureDevOps.table.actionsCell",
            () => ({
                actions: {
                    delete: {
                        error: "Failed to delete organization",
                        prompt: "Are you sure you want to delete the organization {{organizationName}}?\n(this will also delete all repositories)",
                        title: "Delete"
                    },
                    edit: "Edit"
                }
            }));

    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            direction="row"
            justifyContent="flex-end"
            spacing={1.5}>
            {deleteOrganizationExecuting && (
                <CircularProgress
                    size={theme.spacing(2)}
                    variant="indeterminate"/>)}
            {deleteOrganizationError && (
                <Message
                    level="error"
                    title={localization.actions.delete.error()}
                    variant="minimal"/>)}
            {!item.configuration.systemDeleted && (
                <Menu
                    itemsOrGetItems={[
                        new ActionMenuItem(
                            () => setAzureDevOpsContext(new AzureDevOpsContext(item)),
                            localization.actions.edit(),
                            {
                                disabled: deleteOrganizationExecuting,
                                icon: <EditIcon/>
                            }),
                        new ActionMenuItem(
                            async () => {
                                setDeleteOrganizationExecuting(true);
                                setDeleteOrganizationError(false);

                                try {
                                    const { deletedTenantIds } =
                                        await ConfigurationController.deleteAzureDevOpsOrganization(
                                            new Contract.ConfigurationControllerDeleteAzureDevOpsOrganizationRequest(item.configuration.id));
                                    await tenantModelStore.notify(deletedTenantIds);
                                    await scopeSystemEntityModelStore.notify(item.configuration.id);
                                } catch {
                                    setDeleteOrganizationError(true);
                                }

                                setDeleteOrganizationExecuting(false);
                            },
                            localization.actions.delete.title(),
                            {
                                confirmOptions: {
                                    message: localization.actions.delete.prompt({ organizationName: item.configuration.name })
                                },
                                disabled: deleteOrganizationExecuting,
                                icon: <DeleteIcon/>
                            })
                    ]}/>)}
        </Stack>);
}