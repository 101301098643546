import { useLocalization } from "@infrastructure";
import React, { useMemo } from "react";
import { Contract, StatusCell, tenantModelStore } from "../../../../../../../../common";
import { ProfileDefinition } from "../../useProfileDefinition";
import { useCiTenantModelStatusTranslator } from "./hooks";

export function useCiProfileDefinition(scopeNodeModel: Contract.ScopeNodeModel): ProfileDefinition {
    const tenantModelMap = tenantModelStore.useGetCiTenantMap();
    const ciTenantModelStatusTranslator = useCiTenantModelStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.scopes.hooks.useDefinition.hooks.useCiDefinition.useCiProfileDefinition",
            () => ({
                status: "Status"
            }));

    return useMemo(
        () => {
            const tenantModel = tenantModelMap[scopeNodeModel.configuration.id];
            return {
                sections: [
                    {
                        items: [
                            {
                                title: localization.status(),
                                value:
                                    <StatusCell
                                        statusSeverity={tenantModel.statusSeverity}
                                        title={ciTenantModelStatusTranslator(tenantModel.status)}/>
                            }
                        ]
                    }
                ]
            };
        },
        [localization, tenantModelMap]);
}