﻿import { useGetAzureResourceRiskContext, useResourceTagNotExistsRiskDefinition } from "../../..";
import { Contract } from "../../../../../../../../../../../../common";

export function useAzureResourceTagNotExistsRiskDefinition(riskModel: Contract.RiskModel) {
    const getAzureResourceRiskContext = useGetAzureResourceRiskContext();

    return useResourceTagNotExistsRiskDefinition({
        getContextItems:
            (resourceModel: Contract.EntityModel) => {
                const resourceRiskContext = getAzureResourceRiskContext(resourceModel as Contract.AzureResourceModel);

                return [
                    resourceRiskContext.generalInformation,
                    resourceRiskContext.sensitive,
                    resourceRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.risk.id)];
            },
        riskModel
    });
}