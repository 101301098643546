import { Action1, AnalyticsEventActionType, Message, useLocalization, useTrackAnalytics } from "@infrastructure";
import { Button, CircularProgress, Stack, TextareaAutosize } from "@mui/material";
import _ from "lodash";
import React, { useState } from "react";
import { AuditEventController, Contract, Identity, UserHelper, useTheme } from "../../../../../../../../../../../common";
import { RiskHelper } from "../../../../../../../utilities";

type InsertRiskNoteProps = {
    onCreated: Action1<Contract.AuditEventModel[]>;
    riskModel: Contract.RiskModel;
};

export function InsertRiskNoteSection({ onCreated, riskModel }: InsertRiskNoteProps) {
    const [editable, setEditable] = useState(false);
    const [error, setError] = useState(false);
    const [executing, setExecuting] = useState(false);
    const [message, setMessage] = useState("");
    const trackAnalytics = useTrackAnalytics();

    async function insertRiskNote() {
        setExecuting(true);

        try {
            const { auditEventModel } =
                await AuditEventController.insertRiskNoteCreationAuditEvent(
                    new Contract.AuditEventControllerInsertRiskNoteCreationAuditEventRequest(
                        [riskModel.id],
                        message));

            onCreated(auditEventModel);
        } catch {
            setError(true);
        }

        setError(false);
        setExecuting(false);
        setMessage("");
        setEditable(false);

        trackAnalytics(
            AnalyticsEventActionType.RiskCommentInsert,
            RiskHelper.getRiskAnalyticsPropertyNameToValueMap(riskModel));
    }

    const localization =
        useLocalization(
            "views.customer.risks.profile.core.auditEventsSection.insertRiskNoteSection",
            () => ({
                actions: {
                    insert: {
                        error: "Failed to add note",
                        title: "Save"
                    }
                },
                fields: {
                    message: {
                        placeholder: "Write a comment..."
                    }
                }
            }));

    const theme = useTheme();
    return (
        <Stack direction="row">
            <Identity
                identityIdOrReference={UserHelper.mail}
                variant="icon"/>
            <Stack
                spacing={2}
                sx={{
                    "&:hover": {
                        border: theme.border.hoverFocus
                    },
                    border:
                        editable
                            ? theme.border.hoverFocus
                            : theme.border.primary,
                    borderRadius: theme.spacing(0.75),
                    flex: 1,
                    marginLeft: theme.spacing(1.5),
                    maxWidth: theme.spacing(90),
                    padding: theme.spacing(1)
                }}>
                <TextareaAutosize
                    placeholder={localization.fields.message.placeholder()}
                    style={{
                        border: "none",
                        fontFamily: "Inter",
                        fontSize: "12px",
                        outline: "none",
                        resize: "none",
                        width: "100%"
                    }}
                    value={message}
                    onBlur={() => _.isEmpty(message) && setEditable(false)}
                    onChange={event => setMessage(event.target.value)}
                    onFocus={() => setEditable(true)}/>
                {editable &&
                    <Stack
                        alignItems="center"
                        direction="row"
                        spacing={1}>
                        <Button
                            disabled={_.isEmpty(message) || executing}
                            size="small"
                            onClick={() => insertRiskNote()}>
                            {localization.actions.insert.title()}
                        </Button>
                        {error && (
                            <Message
                                level="error"
                                title={localization.actions.insert.error()}/>)}
                        {executing && (
                            <CircularProgress
                                size={theme.spacing(3)}
                                variant="indeterminate"/>)}
                    </Stack>}
            </Stack>
        </Stack>);
}