import { useLocalization } from "@infrastructure";
import { Contract } from "../../../common";

export function useOciObjectStorageBucketObjectAccessLevelTranslator() {
    const localization =
        useLocalization(
            "tenants.oci.hooks.useOciObjectStorageBucketObjectAccessLevelTranslator",
            () => ({
                [Contract.TypeNames.OciObjectStorageBucketObjectAccessLevel]: {
                    [Contract.OciObjectStorageBucketObjectAccessLevel.Private]: "Private",
                    [Contract.OciObjectStorageBucketObjectAccessLevel.PublicGet]: "Public",
                    [Contract.OciObjectStorageBucketObjectAccessLevel.PublicRead]: "Public"
                }
            }));
    return (objectAccessLevel: Contract.OciObjectStorageBucketObjectAccessLevel) =>
        localization[Contract.TypeNames.OciObjectStorageBucketObjectAccessLevel][objectAccessLevel]();
}