﻿import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function SouthAfricaIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <g clipPath="url(#clip0_60203_5276)">
                <path
                    clipRule="evenodd"
                    d="M-0.000207648 16.7414V7.26196L7.94142 12.0017L-0.00372314 16.7414H-0.000207648Z"
                    fill="black"
                    fillRule="evenodd"/>
                <path
                    clipRule="evenodd"
                    d="M5.41724 20L14.3327 14.6698H24V17.9969C24 19.1015 23.1046 19.9969 22 19.9969H5.41724V20Z"
                    fill="#000C8A"
                    fillRule="evenodd"/>
                <path
                    d="M23.9998 9.33646V6C23.9998 4.89543 23.1044 4 21.9998 4H5.41388L14.3292 9.33646H23.9998Z"
                    fill="#E1392D"/>
                <path
                    clipRule="evenodd"
                    d="M-0.000207648 5.99963V7.26189L7.94142 11.9985L-0.00372314 16.7413V18.0004L9.99802 12.0016L-0.00372314 5.99963H-0.000207648Z"
                    fill="#FFB915"
                    fillRule="evenodd"/>
                <path
                    clipRule="evenodd"
                    d="M-0.000183105 5.99961C-0.000183105 4.89526 0.895073 4 1.99943 4H3.33959L13.9284 10.3738H24.0001V13.6294H13.9284L3.33607 19.9969H1.9963C0.893673 19.9969 -0.000183105 19.103 -0.000183105 18.0004L9.99804 12.0016L0.00333239 5.99961H-0.000183105Z"
                    fill="#007847"
                    fillRule="evenodd"/>
                <path
                    clipRule="evenodd"
                    d="M3.33606 4H5.41727L14.3327 9.33333H23.9999V10.3738H13.9284L3.33606 4.00312V4ZM3.33606 20H5.41727L14.3327 14.6698H23.9999V13.6325H13.9284L3.33606 19.9969V20Z"
                    fill="white"
                    fillRule="evenodd"/>
            </g>
            <defs>
                <clipPath id="clip0_60203_5276">
                    <rect
                        fill="white"
                        height="24"
                        width="24"/>
                </clipPath>
            </defs>
        </SvgIcon>);
}