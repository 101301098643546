import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, EmptyMessageText, map, Message, optionalTableCell, StringHelper, TimeFormatter, TimeRangeFilter, useLocalization, ValueFilter, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { Fragment, useMemo } from "react";
import { Contract, CustomerConsoleAppUrlHelper, EntitiesCell, Entity, EntityFilter, entityModelStore, ItemTable, TimeRangeHelper, useTheme } from "../../../../../../../../../common";
import { AadDirectoryPrincipalReferenceCell, AzureAuthorizationRoleAssignmentResourceHelper } from "../../../../../../../../../tenants";
import { useAzureAuthorizationRoleAssignmentResourceStatusTranslator } from "../../../../../hooks";
import { ProfileCategory } from "../useDefinition";

type AzureAuthorizationRoleAssignmentResourcesProps = {
    csvExportFilePrefixes: string[];
    principalOrResourceId?: string;
    roleAssignmentResourceIds: string[];
    variant: "principalAndRoleAndScopeAndRoleManagementPolicyAssignment" | "principalAndScopeAndRoleManagementPolicyAssignment" | "roleAndScopeAndPrincipal" | "roleAndScopeAndPrincipalAndRoleManagementPolicyAssignment";
};

export function AzureAuthorizationRoleAssignmentResources({ csvExportFilePrefixes, principalOrResourceId, roleAssignmentResourceIds, variant }: AzureAuthorizationRoleAssignmentResourcesProps) {
    const [roleAssignmentResourceModels, unknownRoleAssignmentResourceModels] =
        _.partition(
            entityModelStore.useGet(roleAssignmentResourceIds),
            entityModel => !entityModel.unknown) as [Contract.AzureAuthorizationRoleAssignmentResourceModel[], Contract.EntityModel[]];
    const roleAssignmentResourceRelatedEntityModels =
        entityModelStore.useGet(
            _(roleAssignmentResourceModels).
                flatMap(
                    roleAssignmentResourceModel => {
                        const roleAssignmentResource = _.as<Contract.AzureAuthorizationRoleAssignmentResource>(roleAssignmentResourceModel.entity);
                        return [
                            roleAssignmentResource.roleDefinitionId,
                            roleAssignmentResourceModel.roleManagementPolicyAssignmentId,
                            roleAssignmentResource.principalReference.id,
                            roleAssignmentResource.scopeEntityReference.id];
                    }).
                filter().
                as<string>().
                value());

    const roleAssignmentResourceStatusTranslator = useAzureAuthorizationRoleAssignmentResourceStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.azureAuthorizationRoleAssignmentResources",
            () => ({
                columns: {
                    containingRoleAssignmentResourceExists: {
                        false: "-",
                        helpText: "This role assignment is \"covered\" by another role assignment at a higher scope level or one that is assigned via group membership.",
                        title: "Covered Role Assignment",
                        true: "Yes"
                    },
                    endTime: "End Time",
                    principalReference: {
                        everyone: "Everyone",
                        this: "This Principal",
                        title: "Principal"
                    },
                    roleDefinitionId: "Role",
                    roleManagementPolicyAssignmentId: "PIM Role Settings",
                    scopeEntityReference: {
                        empty: "Unknown ({{path}})",
                        this: "This Resource",
                        title: "Scope"
                    },
                    status: "Type"
                },
                empty: "No Assignments",
                unknownRoleAssignmentResourceExists: {
                    helpText: "Since the current user is missing permissions on some of the subscriptions, some role assignments from those subscriptions might not be displayed",
                    title: "Some role assignments might not be displayed"
                }
            }));

    const [roleAssignmentResourceEndTimeExists, roleAssignmentResourceRelatedEntityModelMap, roleAssignmentResourceRoleManagementPolicyAssignmentExists] =
        useMemo(
            () => {
                const roleAssignmentResourceEndTimeExists =
                    _.some(
                        roleAssignmentResourceModels,
                        roleAssignmentResourceModel => !_.isNil(roleAssignmentResourceModel.endTime));
                const roleAssignmentResourceRelatedEntityModelMap =
                    _.keyBy(
                        roleAssignmentResourceRelatedEntityModels,
                        roleAssignmentResourceRelatedEntityModel => roleAssignmentResourceRelatedEntityModel.id);
                const roleAssignmentResourceRoleManagementPolicyAssignmentExists =
                    _.some(
                        roleAssignmentResourceModels,
                        roleAssignmentResourceModel => !_.isNil(roleAssignmentResourceModel.roleManagementPolicyAssignmentId));

                return [roleAssignmentResourceEndTimeExists, roleAssignmentResourceRelatedEntityModelMap, roleAssignmentResourceRoleManagementPolicyAssignmentExists];
            },
            [roleAssignmentResourceRelatedEntityModels]);

    const theme = useTheme();
    return (
        <Fragment>
            {!_.isEmpty(unknownRoleAssignmentResourceModels) &&
                <Stack
                    direction="row"
                    spacing={1}>
                    <Message
                        level="info"
                        title={localization.unknownRoleAssignmentResourceExists.helpText()}
                        variant="minimal"/>
                    <Typography sx={{ color: theme.palette.warning.main }}>
                        {localization.unknownRoleAssignmentResourceExists.title()}
                    </Typography>
                </Stack>}
            <ItemTable
                columnIdToGetItemValueMap={{
                    [AzureAuthorizationRoleAssignmentResourcesColumnId.ContainingRoleAssignmentResourceExists]: {
                        getFilterValue:
                            roleAssignmentResourceModel =>
                                roleAssignmentResourceModel.containingRoleAssignmentResourceExists
                                    ? localization.columns.containingRoleAssignmentResourceExists.true()
                                    : localization.columns.containingRoleAssignmentResourceExists.false(),
                        getSortValue: roleAssignmentResourceModel => roleAssignmentResourceModel.containingRoleAssignmentResourceExists
                    },
                    [AzureAuthorizationRoleAssignmentResourcesColumnId.EndTime]:
                        roleAssignmentResourceModel =>
                            _.isNil(roleAssignmentResourceModel.endTime)
                                ? "-"
                                : TimeFormatter.sortableDateTime(roleAssignmentResourceModel.endTime),
                    [AzureAuthorizationRoleAssignmentResourcesColumnId.PrincipalReference]: {
                        getFilterValue: roleAssignmentResourceModel => (roleAssignmentResourceModel.entity as Contract.AzureAuthorizationRoleAssignmentResource).principalReference.id,
                        getSortValue:
                            roleAssignmentResourceModel => {
                                const roleAssignmentResource = roleAssignmentResourceModel.entity as Contract.AzureAuthorizationRoleAssignmentResource;
                                return StringHelper.getCombineSortValue(
                                    AzureAuthorizationRoleAssignmentResourceHelper.getPrincipalSortValue(
                                        _.isNil(roleAssignmentResource.principalReference.id)
                                            ? undefined
                                            : roleAssignmentResourceRelatedEntityModelMap[roleAssignmentResource.principalReference.id!].entity as Contract.AadDirectoryServicePrincipal,
                                        roleAssignmentResource.principalReference.type),
                                    _.isNil(roleAssignmentResource.principalReference.id)
                                        ? roleAssignmentResource.principalReference.rawId
                                        : roleAssignmentResource.principalReference.id === principalOrResourceId
                                            ? ""
                                            : roleAssignmentResourceRelatedEntityModelMap[roleAssignmentResource.principalReference.id!].entity.displayName);
                            }
                    },
                    [AzureAuthorizationRoleAssignmentResourcesColumnId.RoleDefinitionId]: {
                        getFilterValue: roleAssignmentResourceModel => (roleAssignmentResourceModel.entity as Contract.AzureAuthorizationRoleAssignmentResource).roleDefinitionId,
                        getSortValue: roleAssignmentResourceModel => roleAssignmentResourceRelatedEntityModelMap[(roleAssignmentResourceModel.entity as Contract.AzureAuthorizationRoleAssignmentResource).roleDefinitionId].entity.displayName
                    },
                    [AzureAuthorizationRoleAssignmentResourcesColumnId.RoleManagementPolicyAssignment]:
                        roleAssignmentResourceModel =>
                            _.isNil(roleAssignmentResourceModel.roleManagementPolicyAssignmentId)
                                ? undefined
                                : roleAssignmentResourceRelatedEntityModelMap[roleAssignmentResourceModel.roleManagementPolicyAssignmentId].entity.displayName,
                    [AzureAuthorizationRoleAssignmentResourcesColumnId.ScopeEntityReference]: {
                        getFilterValue: roleAssignmentResourceModel => (roleAssignmentResourceModel.entity as Contract.AzureAuthorizationRoleAssignmentResource).scopeEntityReference.id ?? (roleAssignmentResourceModel.entity as Contract.AzureAuthorizationRoleAssignmentResource).scopeEntityReference.path,
                        getSortValue:
                            roleAssignmentResourceModel => {
                                const roleAssignmentResource = roleAssignmentResourceModel.entity as Contract.AzureAuthorizationRoleAssignmentResource;
                                return _.isNil(roleAssignmentResource.scopeEntityReference.id)
                                    ? roleAssignmentResource.scopeEntityReference.path
                                    : roleAssignmentResource.scopeEntityReference.id === principalOrResourceId
                                        ? ""
                                        : roleAssignmentResourceRelatedEntityModelMap[roleAssignmentResource.scopeEntityReference.id!].entity.displayName;
                            }
                    },
                    [AzureAuthorizationRoleAssignmentResourcesColumnId.Status]: roleAssignmentResourceModel => (roleAssignmentResourceModel.entity as Contract.AzureAuthorizationRoleAssignmentResource).status
                }}
                csvExportFilePrefixes={csvExportFilePrefixes}
                defaultSortColumnIdOrIds={
                    variant === "roleAndScopeAndPrincipal" || variant === "roleAndScopeAndPrincipalAndRoleManagementPolicyAssignment"
                        ? AzureAuthorizationRoleAssignmentResourcesColumnId.RoleDefinitionId
                        : AzureAuthorizationRoleAssignmentResourcesColumnId.PrincipalReference}
                emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty()) }}
                getCsvItem={
                    roleAssignmentResourceModel => {
                        const roleAssignmentResource = roleAssignmentResourceModel.entity as Contract.AzureAuthorizationRoleAssignmentResource;
                        const principal =
                            _.isNil(roleAssignmentResource.principalReference.id)
                                ? roleAssignmentResource.principalReference.type === Contract.AadDirectoryPrincipalType.Everyone
                                    ? localization.columns.principalReference.everyone()
                                    : roleAssignmentResource.principalReference.rawId
                                : roleAssignmentResourceRelatedEntityModelMap[roleAssignmentResource.principalReference.id!].entity.displayReference;
                        const containingRoleAssignmentResourceExists =
                            roleAssignmentResourceModel.containingRoleAssignmentResourceExists
                                ? localization.columns.containingRoleAssignmentResourceExists.true()
                                : localization.columns.containingRoleAssignmentResourceExists.false();
                        const endTime =
                            _.isNil(roleAssignmentResourceModel.endTime)
                                ? "-"
                                : TimeFormatter.sortableDateTime(roleAssignmentResourceModel.endTime);
                        const roleManagementPolicyAssignment =
                            _.isNil(roleAssignmentResourceModel.roleManagementPolicyAssignmentId)
                                ? "-"
                                : roleAssignmentResourceRelatedEntityModelMap[roleAssignmentResourceModel.roleManagementPolicyAssignmentId!].entity.displayReference;
                        const scope =
                            _.isNil(roleAssignmentResource.scopeEntityReference.id)
                                ? roleAssignmentResource.scopeEntityReference.path
                                : roleAssignmentResourceRelatedEntityModelMap[roleAssignmentResource.scopeEntityReference.id!].entity.displayReference;
                        const status = roleAssignmentResourceStatusTranslator(roleAssignmentResource.status);
                        return map<string, any>(
                            variant,
                            {
                                "principalAndRoleAndScopeAndRoleManagementPolicyAssignment": () => ({
                                    /* eslint-disable sort-keys-fix/sort-keys-fix */
                                    "Principal": principal,
                                    "Role": roleAssignmentResourceRelatedEntityModelMap[roleAssignmentResource.roleDefinitionId].entity.displayReference,
                                    "Scope": scope,
                                    "Type": status,
                                    "End Time": endTime,
                                    "PIM Role Settings": roleManagementPolicyAssignment,
                                    "Covered Role Assignment": containingRoleAssignmentResourceExists
                                    /* eslint-enable sort-keys-fix/sort-keys-fix */
                                }),
                                "principalAndScopeAndRoleManagementPolicyAssignment": () => ({
                                    /* eslint-disable sort-keys-fix/sort-keys-fix */
                                    "Principal": principal,
                                    "Scope": scope,
                                    "Type": status,
                                    "End Time": endTime,
                                    "PIM Role Settings": roleManagementPolicyAssignment,
                                    "Covered Role Assignment": containingRoleAssignmentResourceExists
                                    /* eslint-enable sort-keys-fix/sort-keys-fix */
                                }),
                                "roleAndScopeAndPrincipal": () => ({
                                    /* eslint-disable sort-keys-fix/sort-keys-fix */
                                    "Role": roleAssignmentResourceRelatedEntityModelMap[roleAssignmentResource.roleDefinitionId].entity.displayReference,
                                    "Scope": scope,
                                    "Principal": principal,
                                    "Type": status,
                                    "End Time": endTime,
                                    "Covered Role Assignment": containingRoleAssignmentResourceExists
                                    /* eslint-enable sort-keys-fix/sort-keys-fix */
                                }),
                                "roleAndScopeAndPrincipalAndRoleManagementPolicyAssignment": () => ({
                                    /* eslint-disable sort-keys-fix/sort-keys-fix */
                                    "Role": roleAssignmentResourceRelatedEntityModelMap[roleAssignmentResource.roleDefinitionId].entity.displayReference,
                                    "Scope": scope,
                                    "Principal": principal,
                                    "Type": status,
                                    "End Time": endTime,
                                    "PIM Role Settings": roleManagementPolicyAssignment,
                                    "Covered Role Assignment": containingRoleAssignmentResourceExists
                                    /* eslint-enable sort-keys-fix/sort-keys-fix */
                                })
                            });
                    }}
                getItemId={item => (item.entity as Contract.AzureAuthorizationRoleAssignmentResource).id}
                items={roleAssignmentResourceModels}
                rowOptions={{
                    getUrl: (item: Contract.AzureAuthorizationRoleAssignmentResourceModel) => CustomerConsoleAppUrlHelper.getEntityProfileRelativeUrl(item, { category: ProfileCategory.Overview })!
                }}>
                {columnIdToItemValuesMap => {
                    const principalColumnElement =
                        <DataTableColumn
                            filterOptions={{
                                itemOrItems: {
                                    element:
                                        <EntityFilter
                                            entityIdsOrSearchableReferences={columnIdToItemValuesMap[AzureAuthorizationRoleAssignmentResourcesColumnId.PrincipalReference]}
                                            placeholder={localization.columns.principalReference.title()}/>
                                }
                            }}
                            id={AzureAuthorizationRoleAssignmentResourcesColumnId.PrincipalReference}
                            key={AzureAuthorizationRoleAssignmentResourcesColumnId.PrincipalReference}
                            render={
                                ({ item }: DataTableColumnRenderProps<Contract.AzureAuthorizationRoleAssignmentResourceModel>) =>
                                    (item.entity as Contract.AzureAuthorizationRoleAssignmentResource).principalReference.id === principalOrResourceId
                                        ? <Typography noWrap={true}>
                                            {localization.columns.principalReference.this()}
                                        </Typography>
                                        : <AadDirectoryPrincipalReferenceCell
                                            principalReference={(item.entity as Contract.AzureAuthorizationRoleAssignmentResource).principalReference}/>}
                            title={localization.columns.principalReference.title()}/>;
                    const roleColumnElement =
                        <DataTableColumn
                            filterOptions={{
                                itemOrItems: {
                                    element:
                                        <EntityFilter
                                            entityIdsOrSearchableReferences={columnIdToItemValuesMap[AzureAuthorizationRoleAssignmentResourcesColumnId.RoleDefinitionId]}
                                            placeholder={localization.columns.roleDefinitionId()}/>
                                }
                            }}
                            id={AzureAuthorizationRoleAssignmentResourcesColumnId.RoleDefinitionId}
                            key={AzureAuthorizationRoleAssignmentResourcesColumnId.RoleDefinitionId}
                            render={
                                ({ item }: DataTableColumnRenderProps<Contract.AzureAuthorizationRoleAssignmentResourceModel>) =>
                                    <EntitiesCell
                                        entityIdsOrModels={(item.entity as Contract.AzureAuthorizationRoleAssignmentResource).roleDefinitionId}
                                        entityTypeName={Contract.TypeNames.AzureAuthorizationRoleDefinition}
                                        entityVariant="iconTextTypeTenant"/>}
                            title={localization.columns.roleDefinitionId()}/>;
                    const scopeResourceColumnElement =
                        <DataTableColumn
                            filterOptions={{
                                itemOrItems: {
                                    element:
                                        <ValuesFilter placeholder={localization.columns.scopeEntityReference.title()}>
                                            {_.map(
                                                columnIdToItemValuesMap[AzureAuthorizationRoleAssignmentResourcesColumnId.ScopeEntityReference],
                                                scopeEntityReferenceValue =>
                                                    <ValuesFilterItem
                                                        key={scopeEntityReferenceValue}
                                                        title={roleAssignmentResourceRelatedEntityModelMap[scopeEntityReferenceValue]?.entity.displayName}
                                                        value={scopeEntityReferenceValue}>
                                                        {() =>
                                                            _.isNil(roleAssignmentResourceRelatedEntityModelMap[scopeEntityReferenceValue])
                                                                ? <Typography noWrap={true}>
                                                                    {localization.columns.scopeEntityReference.empty({ path: scopeEntityReferenceValue })}
                                                                </Typography>
                                                                : <Entity
                                                                    entityIdOrModel={scopeEntityReferenceValue}
                                                                    linkOptions={{ disabled: true }}
                                                                    variant="iconTextTypeTenant"/>}
                                                    </ValuesFilterItem>)}
                                        </ValuesFilter>
                                }
                            }}
                            id={AzureAuthorizationRoleAssignmentResourcesColumnId.ScopeEntityReference}
                            key={AzureAuthorizationRoleAssignmentResourcesColumnId.ScopeEntityReference}
                            render={
                                ({ item }: DataTableColumnRenderProps<Contract.AzureAuthorizationRoleAssignmentResourceModel>) => {
                                    const roleAssignmentResource = item.entity as Contract.AzureAuthorizationRoleAssignmentResource;
                                    return _.isNil(roleAssignmentResource.scopeEntityReference.id)
                                        ? <Typography noWrap={true}>
                                            {localization.columns.scopeEntityReference.empty({ path: roleAssignmentResource.scopeEntityReference.path })}
                                        </Typography>
                                        : roleAssignmentResource.scopeEntityReference.id === principalOrResourceId
                                            ? <Typography noWrap={true}>
                                                {localization.columns.scopeEntityReference.this()}
                                            </Typography>
                                            : <EntitiesCell
                                                entityIdsOrModels={roleAssignmentResource.scopeEntityReference.id}
                                                entityTypeName={Contract.TypeNames.AzureScopeResource}
                                                entityVariant="iconTextTypeTenant"/>;
                                }}
                            title={localization.columns.scopeEntityReference.title()}/>;
                    const statusColumn =
                        <DataTableColumn
                            filterOptions={{
                                itemOrItems: {
                                    element:
                                        <ValuesFilter
                                            groupItemTitle={true}
                                            placeholder={localization.columns.status()}>
                                            {_.map(
                                                columnIdToItemValuesMap[AzureAuthorizationRoleAssignmentResourcesColumnId.Status],
                                                status =>
                                                    <ValuesFilterItem
                                                        key={status}
                                                        title={roleAssignmentResourceStatusTranslator(status as Contract.AzureAuthorizationRoleAssignmentResourceStatus)}
                                                        value={status}/>)}
                                        </ValuesFilter>
                                }
                            }}
                            id={AzureAuthorizationRoleAssignmentResourcesColumnId.Status}
                            itemProperty={(item: Contract.AzureAuthorizationRoleAssignmentResourceModel) => roleAssignmentResourceStatusTranslator((item.entity as Contract.AzureAuthorizationRoleAssignmentResource).status)}
                            title={localization.columns.status()}/>;
                    const endTimeColumn =
                        roleAssignmentResourceEndTimeExists
                            ? <DataTableColumn
                                filterOptions={{
                                    itemOrItems: {
                                        element:
                                            <TimeRangeFilter
                                                placeholder={localization.columns.endTime()}
                                                timeRange={TimeRangeHelper.getTimesFilterRange(columnIdToItemValuesMap[AzureAuthorizationRoleAssignmentResourcesColumnId.EndTime])}/>
                                    }
                                }}
                                id={AzureAuthorizationRoleAssignmentResourcesColumnId.EndTime}
                                itemProperty={
                                    (item: Contract.AzureAuthorizationRoleAssignmentResourceModel) =>
                                        _.isNil(item.endTime)
                                            ? "-"
                                            : TimeFormatter.monthDayAndYear(item.endTime)}
                                sortOptions={{ type: DataTableSortType.Date }}
                                title={localization.columns.endTime()}/>
                            : undefined;
                    const roleManagementPolicyAssignment =
                        roleAssignmentResourceRoleManagementPolicyAssignmentExists
                            ? <DataTableColumn
                                id={AzureAuthorizationRoleAssignmentResourcesColumnId.RoleManagementPolicyAssignment}
                                render={
                                    optionalTableCell<Contract.AzureAuthorizationRoleAssignmentResourceModel>(item =>
                                        _.isNil(item.roleManagementPolicyAssignmentId)
                                            ? undefined
                                            : <EntitiesCell
                                                entityIdsOrModels={item.roleManagementPolicyAssignmentId}
                                                entityTypeName={Contract.TypeNames.AzureAuthorizationRoleManagementPolicyAssignment}
                                                entityVariant="iconTextTypeTenant"/>)}
                                title={localization.columns.roleManagementPolicyAssignmentId()}/>
                            : undefined;
                    const containingRoleAssignmentResourceExist =
                        <DataTableColumn
                            filterOptions={{
                                itemOrItems: {
                                    element:
                                        <ValueFilter
                                            items={[
                                                {
                                                    value: localization.columns.containingRoleAssignmentResourceExists.true()
                                                }
                                            ]}
                                            placeholder={localization.columns.containingRoleAssignmentResourceExists.title()}/>
                                }
                            }}
                            id={AzureAuthorizationRoleAssignmentResourcesColumnId.ContainingRoleAssignmentResourceExists}
                            itemProperty={
                                (item: Contract.AzureAuthorizationRoleAssignmentResourceModel) =>
                                    item.containingRoleAssignmentResourceExists
                                        ? localization.columns.containingRoleAssignmentResourceExists.true()
                                        : localization.columns.containingRoleAssignmentResourceExists.false()}
                            key={AzureAuthorizationRoleAssignmentResourcesColumnId.ContainingRoleAssignmentResourceExists}
                            message={localization.columns.containingRoleAssignmentResourceExists.helpText()}
                            messageLevel="info"
                            title={localization.columns.containingRoleAssignmentResourceExists.title()}/>;
                    return _.concat(
                        map(
                            variant,
                            {
                                "principalAndRoleAndScopeAndRoleManagementPolicyAssignment": () => [
                                    principalColumnElement,
                                    roleColumnElement,
                                    scopeResourceColumnElement,
                                    statusColumn,
                                    endTimeColumn,
                                    roleManagementPolicyAssignment,
                                    containingRoleAssignmentResourceExist
                                ],
                                "principalAndScopeAndRoleManagementPolicyAssignment": () => [
                                    principalColumnElement,
                                    scopeResourceColumnElement,
                                    statusColumn,
                                    endTimeColumn,
                                    roleManagementPolicyAssignment,
                                    containingRoleAssignmentResourceExist
                                ],
                                "roleAndScopeAndPrincipal": () => [
                                    roleColumnElement,
                                    scopeResourceColumnElement,
                                    principalColumnElement,
                                    statusColumn,
                                    endTimeColumn,
                                    containingRoleAssignmentResourceExist
                                ],
                                "roleAndScopeAndPrincipalAndRoleManagementPolicyAssignment": () => [
                                    roleColumnElement,
                                    scopeResourceColumnElement,
                                    principalColumnElement,
                                    statusColumn,
                                    endTimeColumn,
                                    roleManagementPolicyAssignment,
                                    containingRoleAssignmentResourceExist
                                ]
                            }));
                }}
            </ItemTable>
        </Fragment>);
}

enum AzureAuthorizationRoleAssignmentResourcesColumnId {
    ContainingRoleAssignmentResourceExists = "containingRoleAssignmentResourceExists",
    EndTime = "endTime",
    PrincipalReference = "principalReference",
    RoleDefinitionId = "roleDefinitionId",
    RoleManagementPolicyAssignment = "roleManagementPolicyAssignment",
    ScopeEntityReference = "scopeEntityReference",
    Status = "status"
}