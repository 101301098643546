import { Box } from "@mui/material";
import _ from "lodash";
import React, { ReactNode } from "react";
import { Action0 } from "../types";

type MouseClickAreaProps = {
    children: ReactNode;
    onClick: Action0;
};

const mouseClickAreaScopeDisabledClassName = "MouseClickAreaScope-disabled";

export function MouseClickArea({ children, onClick }: MouseClickAreaProps) {
    return (
        <Box
            sx={{
                height: "100%",
                width: "100%"
            }}
            onMouseDown={
                event => {
                    if (_.isNil(event.target) ||
                        !_.isNil((event.target as HTMLBaseElement).closest(`.${mouseClickAreaScopeDisabledClassName}`)) ||
                        !_.isNil((event.target as HTMLBaseElement).closest("a")) ||
                        !_.isNil((event.target as HTMLBaseElement).closest(".MuiDialog-root")) ||
                        !_.isNil((event.target as HTMLBaseElement).closest(".MuiDrawer-root ")) ||
                        !_.isNil((event.target as HTMLBaseElement).closest(".MuiPopover-root")) ||
                        !_.isNil((event.target as HTMLBaseElement).closest(".MuiPopper-root"))) {
                        return;
                    }

                    onClick();
                }}>
            {children}
        </Box>);
}

type MouseClickAreaScopeProps = {
    children: ReactNode;
    disabled: boolean;
};

export function MouseClickAreaScope({ children, disabled }: MouseClickAreaScopeProps) {
    return (
        <Box
            className={
                disabled
                    ? mouseClickAreaScopeDisabledClassName
                    : undefined}
            sx={{ width: "100%" }}>
            {children}
        </Box>);
}