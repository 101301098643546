import { useLocalization, useLocalizeList } from "@infrastructure";
import _ from "lodash";
import { AuditEventDefinition, AuditEventDefinitionContext, AuditEventDefinitionDetailItem } from "..";
import { Contract } from "../../../../../../../../../common";

export function useGcpTenantAuditEventDefinition(context: AuditEventDefinitionContext) {
    const localizeList = useLocalizeList();
    const localization =
        useLocalization(
            "views.customer.auditEvents.profile.hooks.useDefinition.hooks.useGcpTenantAuditEventDefinition",
            () => ({
                propertyChanges: "Updated properties",
                tenantFolder: "Folder",
                tenantName: "Project name",
                tenantRawShortNameId: "Project ID",
                tenantSinkPubSubSubscriptionRawId: "Pub/Sub subscription"
            }));

    const tenantAuditEvent = context.auditEventModel.auditEvent as Contract.GcpTenantAuditEvent;
    return new AuditEventDefinition(
        _<AuditEventDefinitionDetailItem>([]).
            concat(
                new AuditEventDefinitionDetailItem(
                    localization.tenantRawShortNameId(),
                    tenantAuditEvent.tenantRawShortNameId),
                new AuditEventDefinitionDetailItem(
                    localization.tenantName(),
                    tenantAuditEvent.tenantName),
                new AuditEventDefinitionDetailItem(
                    localization.tenantFolder(),
                    tenantAuditEvent.tenantFolderPath)).
            concatIf(
                tenantAuditEvent.typeName === Contract.TypeNames.GcpTenantCreationAuditEvent ||
                tenantAuditEvent.typeName === Contract.TypeNames.GcpTenantUpdateAuditEvent,
                () =>
                    new AuditEventDefinitionDetailItem(
                        localization.tenantSinkPubSubSubscriptionRawId(),
                        (tenantAuditEvent as Contract.GcpTenantCreationAuditEvent | Contract.GcpTenantUpdateAuditEvent).tenantSinkPubSubSubscriptionRawId ?? "")).
            concatIf(
                tenantAuditEvent.typeName === Contract.TypeNames.GcpTenantUpdateAuditEvent,
                () => {
                    const properties =
                        _.filter([
                            (tenantAuditEvent as Contract.GcpTenantUpdateAuditEvent).tenantFolderPathChanged
                                ? localization.tenantFolder()
                                : undefined,
                            (tenantAuditEvent as Contract.GcpTenantUpdateAuditEvent).tenantSinkPubSubSubscriptionRawIdChanged
                                ? localization.tenantSinkPubSubSubscriptionRawId()
                                : undefined
                        ]);
                    return (
                        _<AuditEventDefinitionDetailItem>([]).
                            concatIf(
                                !_.isEmpty(properties),
                                new AuditEventDefinitionDetailItem(
                                    localization.propertyChanges(),
                                    localizeList(properties)!)).
                            value());
                }).
            value());
}