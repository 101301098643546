import { InlineItems, Optional, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, InlineEntities, NetworkScopeFormatter, useEntityTypeNameTranslator } from "../../../../../../../../../common";
import { DestinationNetworkScope } from "../../../../../../../../../common/controllers/types.generated";
import { RiskDefinitionContextItem } from "../../../utilities";

export function useGetEntityNetworkRiskContext() {
    return useMemo(
        () => useEntityNetworkRiskContext,
        []);
}

function useEntityNetworkRiskContext(entityModel: Contract.EntityModel) {
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.useGetEntityNetworkRiskContext",
            () => ({
                allNetworkAccess: "The {{translatedEntityTypeName}} allows inbound traffic from any IP address",
                inboundExternalAccess: {
                    ports: [
                        "1 port",
                        "{{count | NumberFormatter.humanize}} ports"
                    ],
                    title: "The {{translatedEntityTypeName}} is directly accessible via {{securityGroups}} through {{networkScopes}}"
                },
                wideRangeFirewallRuleSubnets: {
                    subnets: [
                        "1 IP address range",
                        "{{count | NumberFormatter.humanize}} IP address ranges"
                    ],
                    title: "The {{translatedEntityTypeName}} allows wide range network access from {{subnets}}"
                }
            }));

    const translatedEntityTypeName =
        entityTypeNameTranslator(
            entityModel.entity.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });

    return {
        getAllNetworkAccessContextItem:
            (allNetworkAccess: boolean) =>
                allNetworkAccess
                    ? new RiskDefinitionContextItem(localization.allNetworkAccess({ translatedEntityTypeName }))
                    : undefined,
        getInboundExternalAccessContextItem:
            (destinationNetworkScopes?: DestinationNetworkScope[], securityGroupIds?: string[]) =>
                !_.isEmpty(destinationNetworkScopes) && !_.isEmpty(securityGroupIds)
                    ? new RiskDefinitionContextItem(
                        localization.inboundExternalAccess.title({
                            networkScopes:
                                <InlineItems
                                    items={
                                        _.map(
                                            destinationNetworkScopes,
                                            networkScope => NetworkScopeFormatter.networkScopeFromDestinationNetworkScope(networkScope))}
                                    namePluralizer={localization.inboundExternalAccess.ports}
                                    variant="itemCountAndType"/>,
                            securityGroups:
                                <InlineEntities
                                    entityIdsOrModels={securityGroupIds}
                                    entityTypeName={Contract.TypeNames.AwsEc2SecurityGroup}
                                    variant="itemCountAndType"/>,
                            translatedEntityTypeName
                        }))
                    : undefined,
        getWideRangeInboundSubnetsContextItem:
            (subnets: Optional<string[]>) =>
                _.isEmpty(subnets)
                    ? undefined
                    : new RiskDefinitionContextItem(
                        localization.wideRangeFirewallRuleSubnets.title({
                            subnets:
                                <InlineItems
                                    items={subnets}
                                    namePluralizer={localization.wideRangeFirewallRuleSubnets.subnets}
                                    variant="itemCountAndType"/>,
                            translatedEntityTypeName
                        }))
    };
}