import { Optional, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useAwsCommonEc2ResourceInfoElements, useAwsDefaultResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { useCustomEntityPropertyInfoItemElements } from "..";
import { Contract, InfoItem, VerticalTopItemsInfoItem } from "../../../../../../../../../../common";
import { useAwsEc2FlowLogFilterTranslator, useAwsEc2VpcBlockPublicAccessPolicyNetworkDirectionTranslator } from "../../../../../../hooks";
import { EntitiesInfoItem, EntityTypeNameGroupsInfoCard, Info } from "../../../../components";
import { AwsEc2SubnetsInfoCard } from "../../components";

export function useAwsEc2VpcDefinition(resourceModel: Contract.AwsResourceModel, options?: DefinitionOptions) {
    const commonEc2ResourceInfoItemElements = useAwsCommonEc2ResourceInfoElements(resourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useAwsDefaultResourceInfoItemElements(resourceModel);
    const vpc = resourceModel.entity as Contract.AwsEc2Vpc;
    const vpcModel = resourceModel as Contract.AwsEc2VpcModel;
    const vpcNetwork = vpcModel.entityNetwork as Optional<Contract.AwsEc2VpcStateNetwork>;

    const flowLogFilterTranslator = useAwsEc2FlowLogFilterTranslator();
    const vpcBlockPublicAccessPolicyNetworkDirectionTranslator = useAwsEc2VpcBlockPublicAccessPolicyNetworkDirectionTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsEc2VpcDefinition",
            () => ({
                info: {
                    cards: {
                        resources: {
                            emptyMessage: "No resources",
                            title: "Resources"
                        }
                    },
                    items: {
                        defaultNetworkAcl: "Main Network ACL",
                        defaultRouteTable: "Main Route Table",
                        defaultSecurityGroup: "Default Security Group",
                        defaultVpc: {
                            false: "No",
                            title: "Default VPC",
                            true: "Yes"
                        },
                        flowLogDestinationResourceIdReferences: "Flow Logs Destinations",
                        flowLogFilter: "Flow Logs Filter",
                        hostedZones: "Route53 Hosted Zones",
                        internetGatewayId: "Internet Gateway",
                        subnets: "CIDR",
                        vpcBlockPublicAccessPolicyBlockNetworkDirection: "VPC Block Public Access Mode",
                        vpcBlockPublicAccessPolicyBlockPolicy: "VPC Block Public Access Settings"
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    ...commonEc2ResourceInfoItemElements,
                    <VerticalTopItemsInfoItem
                        items={vpc.subnets}
                        key="subnets"
                        title={localization.info.items.subnets()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={vpcNetwork?.internetGatewayId}
                        entityTypeName={Contract.TypeNames.AwsEc2InternetGateway}
                        key="internetGatewayId"
                        title={localization.info.items.internetGatewayId()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={vpcNetwork?.defaultRouteTableId}
                        entityTypeName={Contract.TypeNames.AwsEc2RouteTable}
                        key="defaultRouteTable"
                        title={localization.info.items.defaultRouteTable()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={vpcModel.defaultSecurityGroupId}
                        entityTypeName={Contract.TypeNames.AwsEc2SecurityGroup}
                        key="defaultSecurityGroup"
                        title={localization.info.items.defaultSecurityGroup()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={vpcNetwork?.defaultNetworkAclId}
                        entityTypeName={Contract.TypeNames.AwsEc2NetworkAcl}
                        key="defaultNetworkAcl"
                        title={localization.info.items.defaultNetworkAcl()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={vpcNetwork?.vpcBlockPublicAccessPolicyId}
                        entityTypeName={Contract.TypeNames.AwsEc2VpcBlockPublicAccessPolicy}
                        key="vpcBlockPublicAccessPolicyBlockPolicy"
                        title={localization.info.items.vpcBlockPublicAccessPolicyBlockPolicy()}/>,
                    <InfoItem
                        key="vpcBlockPublicAccessPolicyBlockNetworkDirection"
                        title={localization.info.items.vpcBlockPublicAccessPolicyBlockNetworkDirection()}
                        value={
                            _.isNil(vpcNetwork?.vpcBlockPublicAccessPolicyId)
                                ? undefined
                                : vpcBlockPublicAccessPolicyNetworkDirectionTranslator(vpcNetwork!.vpcBlockPublicAccessPolicyBlockNetworkDirection)}/>,
                    <InfoItem
                        key="defaultVpc"
                        title={localization.info.items.defaultVpc.title()}
                        value={
                            vpc.defaultVpc
                                ? localization.info.items.defaultVpc.true()
                                : localization.info.items.defaultVpc.false()}/>,
                    <InfoItem
                        key="flowLogFilter"
                        title={localization.info.items.flowLogFilter()}
                        value={flowLogFilterTranslator(vpcModel.flowLogFilter)}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={vpcModel.flowLogDestinationResourceIdReferences}
                        entityTypeName={Contract.TypeNames.AwsResource}
                        key="flowLogDestinationResourceIdReferences"
                        title={localization.info.items.flowLogDestinationResourceIdReferences()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={vpcModel.hostedZoneIds}
                        entityTypeName={Contract.TypeNames.AwsRoute53HostedZone}
                        key="hostedZones"
                        title={localization.info.items.hostedZones()}/>
                ]}
                options={options?.infoOptions}>
                <EntityTypeNameGroupsInfoCard
                    emptyMessage={localization.info.cards.resources.emptyMessage()}
                    entityTypeNameToIdsMap={{
                        ...vpcModel.networkedResourceTypeNameToIdsMap,
                        [Contract.TypeNames.AwsEc2SecurityGroup]: vpcModel.securityGroupIds
                    }}
                    title={localization.info.cards.resources.title()}/>
                <AwsEc2SubnetsInfoCard subnetIds={vpcModel.subnetIds}/>
            </Info>
    });
}