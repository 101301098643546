import { DataTable, DataTableColumn, DataTableColumnRenderProps, EmptyMessageText, InlineItems, useLocalization } from "@infrastructure";
import { Box, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract, EntitiesIcon, InfoCard, useTheme } from "../../../../../../../../../../../../../common";
import { AlexaIcon, KafkaIcon } from "./icons";

type TriggersInfoSectionProps = {
    externalTriggers: Contract.AwsLambdaFunctionConfigurationTriggersExternalTrigger[];
};

export function ExternalTriggersInfoSection({ externalTriggers }: TriggersInfoSectionProps) {
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsLambdaFunctionConfigurationDefinition.externalTriggersInfoSection",
            () => ({
                columns: {
                    serviceId: "Type",
                    triggerResource: "Resource"
                },
                empty: "No External Triggers",
                title: "External Triggers",
                type: {
                    [Contract.TypeNames.AwsLambdaFunctionConfigurationTriggersExternalTriggerType]: {
                        [Contract.AwsLambdaFunctionConfigurationTriggersExternalTriggerType.Alexa]: "Alexa",
                        [Contract.AwsLambdaFunctionConfigurationTriggersExternalTriggerType.Kafka]: "Apache Kafka"
                    }
                }
            }));
    const theme = useTheme();
    return (
        <InfoCard title={localization.title()}>
            <DataTable
                emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty()) }}
                fetchItems={
                    () =>
                        _(externalTriggers).
                            groupBy(externalTrigger => externalTrigger.type).
                            map(
                                externalTriggers =>
                                    new ExternalTriggersTableItem(
                                        externalTriggers,
                                        externalTriggers[0].type)).
                            value()}
                getItemId={(item: ExternalTriggersTableItem) => item.type}
                sortOptions={{ enabled: false }}
                variant="card">
                <DataTableColumn
                    id={ExternalTriggersTableColumnId.Service}
                    render={
                        ({ item }: DataTableColumnRenderProps<ExternalTriggersTableItem>) =>
                            <Stack
                                alignItems="center"
                                direction="row"
                                spacing={1}>
                                <Box sx={{ fontSize: "24px" }}>
                                    {item.type == Contract.AwsLambdaFunctionConfigurationTriggersExternalTriggerType.Alexa
                                        ? <AlexaIcon/>
                                        : <KafkaIcon/>}
                                </Box>
                                <Typography sx={{ flex: 1 }}>
                                    {localization.type[Contract.TypeNames.AwsLambdaFunctionConfigurationTriggersExternalTriggerType][item.type]()}
                                </Typography>
                            </Stack>}
                    title={localization.columns.serviceId()}/>
                <DataTableColumn
                    id={ExternalTriggersTableColumnId.ExternalTriggerResource}
                    render={
                        ({ item }: DataTableColumnRenderProps<ExternalTriggersTableItem>) =>
                            <InlineItems
                                items={item.externalTriggers}
                                variant="itemPlusItemCount">
                                {(externalTrigger: Contract.AwsLambdaFunctionConfigurationTriggersExternalTrigger) =>
                                    <Stack
                                        alignItems="center"
                                        direction="row"
                                        spacing={1}>
                                        <EntitiesIcon
                                            sx={{
                                                color: theme.palette.text.secondary,
                                                fontSize: "24px"
                                            }}/>
                                        <Box sx={{ overflow: "hidden" }}>
                                            <Typography noWrap={true}>
                                                {externalTrigger.sourceName}
                                            </Typography>
                                        </Box>
                                    </Stack>}
                            </InlineItems>}
                    title={localization.columns.triggerResource()}/>
            </DataTable>
        </InfoCard>);
}

enum ExternalTriggersTableColumnId {
    ExternalTriggerResource = "externalTriggerResource",
    Service = "service"
}

class ExternalTriggersTableItem {
    constructor(
        public externalTriggers: Contract.AwsLambdaFunctionConfigurationTriggersExternalTrigger[],
        public type: Contract.AwsLambdaFunctionConfigurationTriggersExternalTriggerType) {
    }
}