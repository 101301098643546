import { map, MultiSelect, Optional, PagedDropdownPage, StringHelper, useChangeEffect, useExecuteOperation, useLocalization } from "@infrastructure";
import { Box, Radio, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import { Contract, EntityController, PagedSelector, useActionRiskCategoryTranslator, useCustomRiskPolicyContext, useTheme } from "../../../..";
import { ActionHelper } from "../../../../utilities";
import { InlineActionsSelectionData } from "../InlineActionsSelection";
import { PatternsSelector } from "./components";

type ActionSelectorActionItem = {
    actions: string[];
    rawValue: string;
};

type ActionSelectorProps = {
    onSelectionChanged: (selection?: ActionSelectorSelection) => void;
    selection?: ActionSelectorSelection;
};

export function ActionSelector({ onSelectionChanged, selection }: ActionSelectorProps) {
    const { tenantTypes } = useCustomRiskPolicyContext();
    const [{ actions }] =
        useExecuteOperation(
            ActionSelector,
            () => EntityController.getActions(new Contract.EntityControllerGetActionsRequest(tenantTypes)));

    const [actionNamePatternSelection, setActionNamePatternSelection] =
        useState(
            () =>
                _.isNil(selection)
                    ? undefined
                    : ActionSelectorActionNamePatternsSelection.create(selection));
    const [actionRiskCategorySelection, setActionRiskCategorySelection] =
        useState(
            () =>
                _.isNil(selection)
                    ? undefined
                    : ActionSelectorActionRiskCategoriesSelection.create(selection));
    const [actionSelection, setActionSelection] =
        useState(
            () =>
                _.isNil(selection)
                    ? undefined
                    : ActionSelectorActionSelection.create(selection));
    const [type, setType] =
        useState(
            () => {
                if (_.isNil(selection)) {
                    return undefined;
                } else if (selection instanceof ActionSelectorActionSelection) {
                    return ActionSelectorType.Actions;
                } else if (selection instanceof ActionSelectorActionRiskCategoriesSelection) {
                    return ActionSelectorType.Categories;
                } else {
                    return ActionSelectorType.Patterns;
                }
            });
    useChangeEffect(
        () => {
            onSelectionChanged(
                map<ActionSelectorType, ActionSelectorSelection | undefined>(
                    type,
                    {
                        [ActionSelectorType.Actions]: () => actionSelection,
                        [ActionSelectorType.Categories]: () => actionRiskCategorySelection,
                        [ActionSelectorType.Patterns]: () => actionNamePatternSelection
                    },
                    () => undefined));
        },
        [actionNamePatternSelection, actionSelection, actionRiskCategorySelection, type]);


    const actionRiskCategoryValues =
        useMemo(
            () => _.values(Contract.ActionRiskCategory),
            []);
    const selectedItemRawValues =
        useMemo(
            () =>
                _(actionSelection?.actions ?? []).
                    map(action => ActionHelper.getRawValue(action)).
                    uniq().
                    value(),
            [actionSelection]);
    const [items, rawValueToActionsMap] =
        useMemo(
            () => {
                const rawValueToActionsMap =
                    _.groupBy(
                        actions,
                        action => ActionHelper.getRawValue(action));

                const items =
                    _.map(
                        rawValueToActionsMap,
                        (actions, rawValue) => ({
                            actions,
                            rawValue
                        } as ActionSelectorActionItem));

                return [items, rawValueToActionsMap];
            },
            [actions]);

    const actionRiskCategoryTranslator = useActionRiskCategoryTranslator();
    const localization =
        useLocalization(
            "common.customRiskPolicy.actionSelector",
            () => ({
                fields: {
                    actionNamePatterns: {
                        title: "Permissions that match any of these patterns"
                    },
                    actionRiskCategories: {
                        placeholder: "Categories",
                        title: "Permissions from these categories"
                    },
                    actions: {
                        placeholder: "Permissions",
                        title: "Specific permission"
                    }
                }
            }));

    const theme = useTheme();
    return (
        <Stack spacing={1}>
            <Stack
                alignItems="center"
                direction="row"
                spacing={1}>
                <Radio
                    checked={type === ActionSelectorType.Actions}
                    size="small"
                    onChange={() => setType(ActionSelectorType.Actions)}/>
                <Typography>
                    {localization.fields.actions.title()}
                </Typography>
                <PagedSelector
                    disabled={type !== ActionSelectorType.Actions}
                    fieldOptions={{ dense: true }}
                    getItemPage={
                        async (searchText: Optional<string>, skip: number) => {
                            const filteredItemRawValues =
                                _(items).
                                    filter(item => StringHelper.search(item.rawValue, searchText)).
                                    map(item => item.rawValue).
                                    value();
                            const itemPage = _.slice(filteredItemRawValues, skip, skip + 50);
                            return new PagedDropdownPage(
                                filteredItemRawValues.length > skip + 50,
                                itemPage);
                        }}
                    multiSelect={true}
                    placeholder={localization.fields.actions.placeholder()}
                    selectedItems={selectedItemRawValues}
                    onSelectedItemsChanged={
                        (itemRawValues: string[]) =>
                            setActionSelection(
                                new ActionSelectorActionSelection(
                                    _.flatMap(
                                        itemRawValues,
                                        itemRawValue => rawValueToActionsMap[itemRawValue])))}>
                    {(itemRawValue: string) =>
                        <Typography
                            sx={{
                                color: theme.palette.text.primary,
                                fontSize: "unset",
                                wordBreak: "break-word"
                            }}>
                            {itemRawValue}
                        </Typography>}
                </PagedSelector>
            </Stack>
            <Stack
                alignItems="center"
                direction="row"
                spacing={1}>
                <Radio
                    checked={type === ActionSelectorType.Categories}
                    size="small"
                    onChange={() => setType(ActionSelectorType.Categories)}/>
                <Typography>
                    {localization.fields.actionRiskCategories.title()}
                </Typography>
                <MultiSelect
                    disabled={type !== ActionSelectorType.Categories}
                    fieldOptions={{
                        dense: true,
                        variant: "itemSelector"
                    }}
                    items={actionRiskCategoryValues}
                    placeholder={localization.fields.actionRiskCategories.placeholder()}
                    selectedItems={actionRiskCategorySelection?.actionRiskCategories ?? []}
                    onSelectedItemsChanged={actionRiskCategories => setActionRiskCategorySelection(new ActionSelectorActionRiskCategoriesSelection(actionRiskCategories))}>
                    {actionRiskCategory => actionRiskCategoryTranslator(actionRiskCategory).title}
                </MultiSelect>
            </Stack>
            <Stack
                alignItems="start"
                direction="row"
                spacing={1}>
                <Radio
                    checked={type === ActionSelectorType.Patterns}
                    size="small"
                    onChange={() => setType(ActionSelectorType.Patterns)}/>
                <Stack
                    spacing={1}>
                    <Box sx={{ padding: theme.spacing(1.25, 0) }}>
                        <Typography>
                            {localization.fields.actionNamePatterns.title()}
                        </Typography>
                    </Box>
                    <PatternsSelector
                        disabled={type !== ActionSelectorType.Patterns}
                        selectedPatterns={actionNamePatternSelection?.actionNamePatterns ?? []}
                        onPatternsChanged={actionNamePatterns => setActionNamePatternSelection(new ActionSelectorActionNamePatternsSelection(actionNamePatterns))}/>
                </Stack>
            </Stack>
        </Stack>);
}

export enum ActionSelectorType {
    Actions = "actions",
    Categories = "categories",
    Patterns = "patterns"
}

export abstract class ActionSelectorSelection {
    public static getSelectorSelection(
        actions?: string[],
        categories?: Contract.ActionRiskCategory[],
        namePatterns?: string[])
        : Optional<ActionSelectorSelection> {
        if (!_.isNil(actions)) {
            return new ActionSelectorActionSelection(actions);
        } else if (!_.isNil(categories)) {
            return new ActionSelectorActionRiskCategoriesSelection(categories);
        } else if (!_.isNil(namePatterns)) {
            return new ActionSelectorActionNamePatternsSelection(namePatterns);
        } else {
            return undefined;
        }
    }

    public abstract getInlineSelectionData(): InlineActionsSelectionData;

    public abstract valid(): boolean;
}

export class ActionSelectorActionNamePatternsSelection extends ActionSelectorSelection {
    constructor(public actionNamePatterns: string[]) {
        super();
    }

    public static create(selection: ActionSelectorSelection) {
        return new ActionSelectorActionNamePatternsSelection(
            selection instanceof ActionSelectorActionNamePatternsSelection
                ? selection.actionNamePatterns
                : []);
    }

    public getInlineSelectionData(): InlineActionsSelectionData {
        return { actionNamePatterns: this.actionNamePatterns };
    }

    public valid(): boolean {
        return (
            !_.isEmpty(this.actionNamePatterns) &&
            _.every(
                this.actionNamePatterns,
                actionNamePattern => !_.isEmpty(actionNamePattern.trim())) &&
            _.uniq(this.actionNamePatterns).length === this.actionNamePatterns.length);
    }
}

export class ActionSelectorActionRiskCategoriesSelection extends ActionSelectorSelection {
    constructor(public actionRiskCategories: Contract.ActionRiskCategory[]) {
        super();
    }

    public static create(selection: ActionSelectorSelection) {
        return new ActionSelectorActionRiskCategoriesSelection(
            selection instanceof ActionSelectorActionRiskCategoriesSelection
                ? selection.actionRiskCategories
                : []);
    }

    public getInlineSelectionData(): InlineActionsSelectionData {
        return { actionRiskCategories: this.actionRiskCategories };
    }

    public valid(): boolean {
        return !_.isEmpty(this.actionRiskCategories);
    }
}

export class ActionSelectorActionSelection extends ActionSelectorSelection {
    constructor(public actions: string[]) {
        super();
    }

    public static create(selection: ActionSelectorSelection) {
        return new ActionSelectorActionSelection(
            selection instanceof ActionSelectorActionSelection
                ? selection.actions
                : []);
    }

    public getInlineSelectionData(): InlineActionsSelectionData {
        return { actions: this.actions };
    }

    public valid(): boolean {
        return !_.isEmpty(this.actions);
    }
}