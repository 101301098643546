﻿import { useLocalization, useLocalizeList } from "@infrastructure";
import _ from "lodash";
import { AuditEventDefinition, AuditEventDefinitionContext, AuditEventDefinitionDetailItem } from "..";
import { Contract } from "../../../../../../../../../common";

export function useOpTenantAuditEventDefinition(context: AuditEventDefinitionContext) {
    const localizeList = useLocalizeList();
    const localization =
        useLocalization(
            "views.customer.auditEvents.profile.hooks.useDefinition.hooks.useOpTenantAuditEventDefinition",
            () => ({
                propertyChanges: "Updated properties",
                tenantFolderPath: "Folder",
                tenantName: "Account name"
            }));

    const tenantAuditEvent = context.auditEventModel.auditEvent as Contract.OpTenantAuditEvent;
    return new AuditEventDefinition(
        _<AuditEventDefinitionDetailItem>([]).
            concat(
                new AuditEventDefinitionDetailItem(
                    localization.tenantName(),
                    tenantAuditEvent.tenantName),
                new AuditEventDefinitionDetailItem(
                    localization.tenantFolderPath(),
                    tenantAuditEvent.tenantFolderPath)).
            concatIf(
                tenantAuditEvent.typeName === Contract.TypeNames.OpTenantUpdateAuditEvent,
                () => {
                    const properties =
                        _<string>([]).
                            concatIf(
                                (tenantAuditEvent as Contract.OpTenantUpdateAuditEvent).tenantFolderPathChanged,
                                localization.tenantFolderPath()).
                            concatIf(
                                (tenantAuditEvent as Contract.OpTenantUpdateAuditEvent).tenantNameChanged,
                                localization.tenantName()).
                            value();
                    return (
                        _<AuditEventDefinitionDetailItem>([]).
                            concatIf(
                                !_.isEmpty(properties),
                                new AuditEventDefinitionDetailItem(
                                    localization.propertyChanges(),
                                    localizeList(properties)!)).
                            value());
                }).
            value());
}