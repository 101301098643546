import { UnexpectedError } from "@infrastructure";
import { ReactNode, useMemo } from "react";
import { Contract } from "../../../../../../../../common";
import { GranteeUserPermissionRequestsViewType } from "../../../../GranteeUserPermissionRequests";
import { useActiveDefinition, useHistoryDefinition } from "./hooks";

export function useDefinition(viewType: GranteeUserPermissionRequestsViewType) {
    const useDefinition =
        useMemo(
            () => {
                switch(viewType) {
                    case GranteeUserPermissionRequestsViewType.Active:
                        return useActiveDefinition;
                    case GranteeUserPermissionRequestsViewType.History:
                        return useHistoryDefinition;
                    default:
                        throw new UnexpectedError("itemType", viewType);
                }
            },
            [viewType]);

    return useDefinition();
}

export class TableDefinition {
    constructor(
        public getColumns: (maxLevel: number) => ReactNode[],
        public statuses: Contract.PermissionRequestStatus[]) {
    }
}