import { useLocalization } from "@infrastructure";
import { PermissionsDefinition } from "../useDefinition";

export function usePermissionsSourceEntityDefinition(): PermissionsDefinition {
    const localization =
        useLocalization(
            "views.customer.access.permissions.hooks.useDefinition.hooks.usePermissionsSourceEntityDefinition",
            () => ({
                columns: {
                    destinationEntities: "Resources",
                    sourceEntities: "Identity"
                }
            }));


    return {
        columns: {
            destinationEntities: localization.columns.destinationEntities(),
            sourceEntities: localization.columns.sourceEntities()
        },
        getPermissionsModelEntityId: permissionsModel => permissionsModel.sourceEntityTopIds[0]
    };
}