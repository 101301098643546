import { DataTableColumnRenderProps, InlineItems, Message, SearchList, StringHelper, useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, riskPolicyModelStore, RiskPolicyTypeMetadataHelper, useRiskPolicyTranslator, useTheme } from "../../../../../../../../../../../common";

export function RiskPoliciesCell({ item }: DataTableColumnRenderProps<Contract.RiskAutomationRuleConfiguration>) {
    const customRiskPolicyModels = riskPolicyModelStore.useGet(item.customRiskPolicyIds);

    const riskPolicyTranslator = useRiskPolicyTranslator();
    const localization =
        useLocalization(
            "views.customer.configuration.automations.riskAutomations.table.riskPoliciesCell",
            () => ({
                empty: "This rule has been configured to trigger automations on findings with specified policies that have been deleted, so no automation will be triggered. Edit the rule to configure new policies.",
                policies: {
                    all: "All existing and future",
                    some: [
                        "{{riskPolicyNames}} Policy",
                        "{{riskPolicyNames}} Policies"
                    ]
                },
                unlicensedRiskPolicies: "This automation contains unsupported policies with your current license"
            }));

    const [empty, riskPolicyNames, unlicensedRiskPolicies] =
        useMemo(
            () => {
                const empty =
                    !_.isNil(item.riskPolicyConfigurationTypeNames) &&
                    _.isEmpty(item.riskPolicyConfigurationTypeNames) &&
                    !_.isNil(item.customRiskPolicyIds) &&
                    _.isEmpty(item.customRiskPolicyIds);

                const riskPolicyNames =
                    _.orderBy(
                        [
                            ..._.map(
                                item.riskPolicyConfigurationTypeNames,
                                riskPolicyConfigurationTypeName => riskPolicyTranslator(riskPolicyConfigurationTypeName).title),
                            ..._.map(
                                customRiskPolicyModels ?? [],
                                customRiskPolicyModel => (customRiskPolicyModel.riskPolicyConfiguration as Contract.CustomRiskPolicyConfiguration).name)
                        ],
                        riskPolicyName => StringHelper.getSortValue(riskPolicyName));

                const unlicensedRiskPolicies =
                    _.some(
                        item.riskPolicyConfigurationTypeNames,
                        riskPolicyConfigurationTypeName => !RiskPolicyTypeMetadataHelper.isLicensed(riskPolicyConfigurationTypeName)) ||
                    _.some(
                        customRiskPolicyModels,
                        customRiskPolicyModel => !RiskPolicyTypeMetadataHelper.isLicensed(customRiskPolicyModel.riskPolicyConfiguration.typeName));

                return [empty, riskPolicyNames, unlicensedRiskPolicies];
            },
            [customRiskPolicyModels, item]);

    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            direction="row"
            spacing={1}>
            <Typography
                noWrap={true}
                sx={{ fontWeight: 600 }}>
                {_.isEmpty(riskPolicyNames)
                    ? localization.policies.all()
                    : localization.policies.some(
                        riskPolicyNames.length,
                        {
                            riskPolicyNames:
                                <InlineItems
                                    items={riskPolicyNames}
                                    itemsPopover={
                                        (riskPolicyNames: string[]) =>
                                            <SearchList
                                                disablePadding={true}
                                                itemOptions={{
                                                    render:
                                                        (riskPolicyName: string) =>
                                                            <Typography sx={{ color: theme.palette.text.primary }}>
                                                                {riskPolicyName}
                                                            </Typography>,
                                                    spacing: 1
                                                }}
                                                items={riskPolicyNames}
                                                sx={{ padding: theme.spacing(0, 2) }}
                                                variant="dropdown"/>}
                                    sx={{
                                        color: "unset",
                                        textDecoration: "underline"
                                    }}
                                    variant="itemCount"/>
                        })
                }
            </Typography>
            {empty &&
                <Message
                    level="error"
                    title={localization.empty()}
                    variant="minimal"/>}
            {!empty && unlicensedRiskPolicies &&
                <Message
                    level="warning"
                    title={localization.unlicensedRiskPolicies()}
                    variant="minimal"/>}
        </Stack>);
}