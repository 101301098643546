import { Action0, ClearIcon, Dropdown, ItemSelector, useChangeEffect } from "@infrastructure";
import { Box, Button, Divider, IconButton, Stack, SxProps, Typography } from "@mui/material";
import _ from "lodash";
import React, { Fragment, ReactNode, useEffect, useMemo, useState } from "react";
import { Contract, useTheme } from "../../../../../../..";
import { useSetUdmObjectTableContext, useUdmObjectTableContext } from "../../../../..";
import { useUdmObjectPropertyIdTranslator } from "../../../../../hooks";
import { useOperatorTranslator } from "../hooks";

type FilterProps = {
    children: ReactNode;
    emptyValue?: boolean;
    initialOperator?: Contract.UdmQueryRuleOperator;
    onClearClicked: Action0;
    onOperatorChange?: (operator: Contract.UdmQueryRuleOperator) => void;
    operators?: Contract.UdmQueryRuleOperator[];
    popoverElementContainerSx?: SxProps;
    propertyId: Contract.UdmObjectPropertyId;
    ruleId: string;
    selectionViewValue: ReactNode;
    values?: any | any[];
};

export function Filter({ children, emptyValue, initialOperator, onClearClicked, onOperatorChange, operators, popoverElementContainerSx, propertyId, ruleId, selectionViewValue, values }: FilterProps) {
    const [operator, setOperator] = useState(initialOperator ?? _.first(operators));

    const { newFiltersIds } = useUdmObjectTableContext();
    const setUdmObjectTableContext = useSetUdmObjectTableContext();

    const initialOpen =
        useMemo(
            () =>
                _.includes(
                    newFiltersIds,
                    ruleId),
            []);

    useEffect(
        () => {
            setUdmObjectTableContext(
                context => ({
                    ...context,
                    newFiltersIds: _.without(context.newFiltersIds, ruleId)
                }));
        },
        []);

    useChangeEffect(
        () => {
            if (operator) {
                onOperatorChange?.(operator);
            }
        },
        [operator]);

    const udmObjectPropertyIdTranslator = useUdmObjectPropertyIdTranslator();
    const operatorTranslator = useOperatorTranslator();

    const theme = useTheme();
    return (
        <Dropdown
            open={initialOpen}
            popoverElement={
                <Stack spacing={1}>
                    {!_.isNil(operators) &&
                        operators.length > 1 &&
                        <Fragment>
                            <ItemSelector
                                dense={true}
                                fieldSx={{
                                    minWidth: theme.spacing(40),
                                    padding: theme.spacing(0.5, 0)
                                }}
                                fullWidth={true}
                                items={operators}
                                selectedItem={operator}
                                sorted={false}
                                onSelectedItemChanged={setOperator}>
                                {item =>
                                    <Typography noWrap={true}>
                                        {operatorTranslator(item as Contract.UdmQueryRuleOperator, "title")}
                                    </Typography>}
                            </ItemSelector>
                            <Divider/>
                        </Fragment>}
                    {children}
                </Stack>}
            popoverElementContainerSx={{
                maxWidth: "400px",
                ...popoverElementContainerSx
            }}>
            <Stack>
                <Button
                    sx={{
                        "&:hover": {
                            border: theme.border.hoverFocus
                        },
                        border: theme.border.primary,
                        borderRadius: theme.spacing(0.5),
                        minHeight: theme.spacing(3.5),
                        padding: 0
                    }}
                    variant="outlined">
                    <Stack
                        alignItems="center"
                        direction="row"
                        spacing={0.25}
                        sx={{ overflow: "hidden" }}>
                        <Stack
                            direction="row"
                            spacing={0.75}
                            sx={{
                                alignItems: "center",
                                color:
                                    _.isEmpty(values)
                                        ? theme.palette.text.secondary
                                        : undefined,
                                overflow: "hidden",
                                padding: theme.spacing(0, 1)
                            }}>
                            <Typography
                                sx={{
                                    fontWeight:
                                        _.isEmpty(values)
                                            ? 400
                                            : 500
                                }}>
                                {udmObjectPropertyIdTranslator(propertyId)}
                            </Typography>
                            {!_.isEmpty(values) &&
                                <Fragment>
                                    <Typography
                                        sx={{
                                            color: theme.palette.text.secondary,
                                            fontWeight: 400
                                        }}>
                                        {operatorTranslator(operator!, "text")}
                                    </Typography>
                                    <Box
                                        sx={{
                                            flex: 1,
                                            overflow: "hidden"
                                        }}>
                                        {selectionViewValue}
                                    </Box>
                                </Fragment>}
                        </Stack>
                        <IconButton
                            sx={{
                                height: theme.spacing(2.5),
                                width: theme.spacing(2.5)
                            }}
                            onClick={
                                event => {
                                    onClearClicked();
                                    event.stopPropagation();
                                }}>
                            <ClearIcon
                                sx={{
                                    color:
                                        emptyValue
                                            ? theme.palette.text.secondary
                                            : theme.palette.text.primary,
                                    fontSize: "12px"
                                }}/>
                        </IconButton>
                    </Stack>
                </Button>
            </Stack>
        </Dropdown>);
}