import { NumberFormatter, useLocalization } from "@infrastructure";
import React, { useCallback } from "react";
import { RiskPolicyConfigurationDefinitionData } from "../";
import { Contract, CustomerConsoleAppUrlHelper, RiskPoliciesType } from "../../../../../../../common";
import { Numeric, ViewOnlyNumeric } from "../components";

export function useAwsIamPasswordReusePolicyRiskPolicyConfigurationDefinition(riskPoliciesType: RiskPoliciesType): RiskPolicyConfigurationDefinitionData {
    const passwordPolicyPreventReusePreviousPasswordCountMaxValue = 24;
    const passwordPolicyPreventReusePreviousPasswordCountMinValue = 1;

    const localization =
        useLocalization(
            "views.customer.riskPolicies.hooks.useConfigurationDefinition.hooks.useAwsIamPasswordReusePolicyRiskPolicyConfigurationDefinition",
            () => ({
                description: "By default, Tenable Cloud Security uses a minimum password reuse count of 24 previous passwords to analyze this policy before opening findings. ",
                invalidValue: "The value should be between {{minValue}} and {{maxValue}}",
                label: "Password Reuse Count",
                title: "Password Requirements"
            }));

    const getPasswordPolicyPreventReusePreviousPasswordCount =
        useCallback(
            (riskPolicyConfiguration: Contract.RiskPolicyConfiguration) =>
                (riskPolicyConfiguration as Contract.AwsIamPasswordReusePolicyRiskPolicyConfiguration).passwordPolicyPreventReusePreviousPasswordCount,
            []);

    return {
        generalAdditionalItems: [{
            configurationElement:
                <Numeric
                    description={localization.description()}
                    documentationUrl={CustomerConsoleAppUrlHelper.getDocsViewAndManagePoliciesRelativeUrl()}
                    fromNumber={passwordPolicyPreventReusePreviousPasswordCount => passwordPolicyPreventReusePreviousPasswordCount}
                    getValue={getPasswordPolicyPreventReusePreviousPasswordCount}
                    key={`${Contract.TypeNames.AwsIamPasswordReusePolicyRiskPolicyConfiguration}-passwordPolicyPreventReusePreviousPasswordCount`}
                    label={localization.label()}
                    maxValue={passwordPolicyPreventReusePreviousPasswordCountMaxValue}
                    minValue={passwordPolicyPreventReusePreviousPasswordCountMinValue}
                    riskPoliciesType={riskPoliciesType}
                    setValue={
                        (riskPolicyConfiguration, passwordPolicyPreventReusePreviousPasswordCount) => {
                            (riskPolicyConfiguration as Contract.AwsIamPasswordReusePolicyRiskPolicyConfiguration).passwordPolicyPreventReusePreviousPasswordCount = passwordPolicyPreventReusePreviousPasswordCount;
                            return undefined;
                        }}
                    title={localization.title()}
                    toNumber={passwordPolicyPreventReusePreviousPasswordCount => passwordPolicyPreventReusePreviousPasswordCount}
                    translateValue={NumberFormatter.humanize}
                    valueOutOfRangeErrorMessage={
                        localization.invalidValue({
                            maxValue: passwordPolicyPreventReusePreviousPasswordCountMaxValue,
                            minValue: passwordPolicyPreventReusePreviousPasswordCountMinValue
                        })}/>,
            viewItem: {
                getValue:
                    (_, item, scopeId) =>
                        <ViewOnlyNumeric
                            fromNumber={passwordPolicyPreventReusePreviousPasswordCount => passwordPolicyPreventReusePreviousPasswordCount}
                            getValue={getPasswordPolicyPreventReusePreviousPasswordCount}
                            item={item}
                            riskPoliciesType={riskPoliciesType}
                            scopeId={scopeId}
                            toNumber={passwordPolicyPreventReusePreviousPasswordCount => passwordPolicyPreventReusePreviousPasswordCount}
                            translateValue={NumberFormatter.humanize}/>,
                title: localization.title()
            }
        }]
    };
}