﻿import { Action1, ApiError, Dialog, FormLayout, ItemSelector, Message, Optional, useLocalization } from "@infrastructure";
import { Button, FormControl, InputBase, Radio, Stack, TextField, Typography } from "@mui/material";
import _ from "lodash";
import React, { useRef, useState } from "react";
import { ConfigurationController, Contract, UserHelper, useTheme } from "../../../../../../../common";

type SamlIdentityProviderProps = {
    configuration: Contract.ApplicationCustomerConfigurationAuthenticationSamlIdentityProvider;
    onClose: Action1<Optional<Contract.ApplicationCustomerConfigurationAuthenticationSamlIdentityProvider>>;
    title: string;
};

export function SamlIdentityProvider({ configuration: initialConfiguration, onClose, title }: SamlIdentityProviderProps) {
    const [configuration, setConfiguration] = useState<Contract.ApplicationCustomerConfigurationAuthenticationSamlIdentityProvider>(initialConfiguration);
    const [metadataFileEnabled, setMetadataFileEnabled] = useState(!_.isNil(initialConfiguration.metadataBytes));
    const [metadataUrlEnabled, setMetadataUrlEnabled] = useState(!_.isNil(initialConfiguration.metadataUrl));
    const [validateError, setValidateError] = useState<Contract.ConfigurationControllerValidateAuthenticationSamlIdentityProviderError | true>();
    const [validated, setValidated] = useState<boolean>(false);


    async function validate(metadataBytes?: string) {
        setValidateError(undefined);
        setValidated(false);

        let administrationControllerValidateSamlIdentityProviderResponse;
        try {
            administrationControllerValidateSamlIdentityProviderResponse =
                await ConfigurationController.validateAuthenticationSamlIdentityProvider(
                    new Contract.ConfigurationControllerValidateAuthenticationSamlIdentityProviderRequest(
                        metadataFileEnabled
                            ? metadataBytes ?? configuration.metadataBytes!
                            : undefined,
                        metadataUrlEnabled
                            ? configuration.metadataUrl
                            : undefined));
            setConfiguration(administrationControllerValidateSamlIdentityProviderResponse.samlIdentityProvider);
            setValidated(true);
        } catch (error) {
            setValidateError(
                error instanceof ApiError && error.statusCode === 400
                    ? error.error as Contract.ConfigurationControllerValidateAuthenticationSamlIdentityProviderError
                    : true);
        }
    }

    const localization =
        useLocalization(
            "views.customer.administration.authentication.samlIdentityProvider",
            () => ({
                actions: {
                    download: "Download",
                    save: "Save",
                    upload: "Upload",
                    validate: {
                        error: {
                            validate: "Failed",
                            [Contract.TypeNames.ConfigurationControllerValidateAuthenticationSamlIdentityProviderError]: {
                                [Contract.ConfigurationControllerValidateAuthenticationSamlIdentityProviderError.InvalidMetadata]: "Invalid Metadata"
                            }
                        },
                        success: "Validated",
                        title: "Validate"
                    }
                },
                file: "File",
                issuerId: {
                    placeholder: "Upload metadata file or provide URL",
                    title: "Issuer Id"
                },
                type: {
                    title: "Type",
                    warningOther: "Unable to resolve IDP type, please select manually if possible",
                    [Contract.TypeNames.ApplicationCustomerConfigurationAuthenticationSamlIdentityProviderType]: {
                        [Contract.ApplicationCustomerConfigurationAuthenticationSamlIdentityProviderType.Aad]: "Microsoft",
                        [Contract.ApplicationCustomerConfigurationAuthenticationSamlIdentityProviderType.AwsSso]: "AWS IAM Identity Center",
                        [Contract.ApplicationCustomerConfigurationAuthenticationSamlIdentityProviderType.Duo]: "Duo",
                        [Contract.ApplicationCustomerConfigurationAuthenticationSamlIdentityProviderType.Gci]: "Google",
                        [Contract.ApplicationCustomerConfigurationAuthenticationSamlIdentityProviderType.Okta]: "Okta",
                        [Contract.ApplicationCustomerConfigurationAuthenticationSamlIdentityProviderType.OneLogin]: "OneLogin",
                        [Contract.ApplicationCustomerConfigurationAuthenticationSamlIdentityProviderType.Other]: "Unknown",
                        [Contract.ApplicationCustomerConfigurationAuthenticationSamlIdentityProviderType.PingIdentity]: "Ping Identity"
                    }
                },
                url: "URL"
            }));

    const uploadButtonRef = useRef<HTMLInputElement>(null);
    const theme = useTheme();
    return (
        <Dialog
            size="small"
            variant="editor"
            onClose={() => onClose(undefined)}>
            <FormLayout
                footerOptions={{
                    contentElement:
                        <Stack
                            alignItems="center"
                            direction="row"
                            justifyContent="flex-end"
                            spacing={2}
                            sx={{ width: "100%" }}>
                            {!_.isNil(validateError) &&
                                (<Message
                                    level="error"
                                    title={
                                        validateError === true
                                            ? localization.actions.validate.error.validate()
                                            : localization.actions.validate.error[Contract.TypeNames.ConfigurationControllerValidateAuthenticationSamlIdentityProviderError][validateError]()}/>)}
                            {validated &&
                                (<Message
                                    level="success"
                                    title={localization.actions.validate.success()}/>)}
                            <Button
                                disabled={!metadataUrlEnabled && !metadataFileEnabled}
                                variant="outlined"
                                onClick={() => validate()}>
                                {localization.actions.validate.title()}
                            </Button>
                            <Button
                                disabled={!validated}
                                variant="contained"
                                onClick={() => onClose(configuration)}>
                                {localization.actions.save()}
                            </Button>
                        </Stack>
                }}
                titleOptions={{ text: title }}>
                <Stack
                    spacing={1}
                    sx={{
                        border: theme.border.primary,
                        borderRadius: theme.spacing(0.75),
                        padding: theme.spacing(2)
                    }}>
                    <Stack
                        alignItems="center"
                        direction="row"
                        justifyContent="space-between"
                        spacing={1}>
                        <Typography
                            sx={{
                                fontWeight: 500,
                                width: "150px"
                            }}
                            variant="h4">
                            {localization.issuerId.title()}
                        </Typography>
                        <TextField
                            placeholder={localization.issuerId.placeholder()}
                            slotProps={{ htmlInput: { readOnly: true } }}
                            sx={{ width: 300 }}
                            value={configuration.issuerId}
                            variant="outlined">
                        </TextField>
                    </Stack>
                    <Stack
                        alignItems="center"
                        direction="row"
                        justifyContent="space-between"
                        spacing={1}>
                        <Typography
                            sx={{
                                fontWeight: 500,
                                width: "150px"
                            }}
                            variant="h4">
                            {localization.type.title()}
                        </Typography>
                        <Stack
                            alignItems="center"
                            direction="row"
                            spacing={1}>
                            {validated &&
                                configuration.type === Contract.ApplicationCustomerConfigurationAuthenticationSamlIdentityProviderType.Other && (
                                <Message
                                    level="warning"
                                    title={localization.type.warningOther()}
                                    variant="minimal"/>
                            )}
                            <ItemSelector
                                fieldSx={{ width: "300px" }}
                                items={_.values(Contract.ApplicationCustomerConfigurationAuthenticationSamlIdentityProviderType)}
                                selectedItem={configuration.type}
                                onSelectedItemChanged={
                                    type =>
                                        setConfiguration(
                                            {
                                                ...configuration,
                                                type
                                            })}>
                                {(type: Contract.ApplicationCustomerConfigurationAuthenticationSamlIdentityProviderType) => localization.type[Contract.TypeNames.ApplicationCustomerConfigurationAuthenticationSamlIdentityProviderType][type]()}
                            </ItemSelector>
                        </Stack>
                    </Stack>
                    <Stack
                        direction="row"
                        justifyContent="space-between">
                        <Stack
                            alignItems="center"
                            direction="row"
                            spacing={1}>
                            <Radio
                                checked={metadataUrlEnabled}
                                onChange={
                                    (_, checked) => {
                                        setMetadataUrlEnabled(checked);
                                        if (checked) {
                                            setMetadataFileEnabled(false);
                                            setValidated(false);
                                        }
                                    }}/>
                            <Typography
                                sx={{ fontWeight: 500 }}
                                variant="h4">
                                {localization.url()}
                            </Typography>
                        </Stack>
                        <TextField
                            disabled={!metadataUrlEnabled}
                            sx={{ width: 300 }}
                            value={configuration.metadataUrl ?? ""}
                            onChange={
                                event => {
                                    setConfiguration({
                                        ...configuration,
                                        metadataUrl: event.target.value
                                    });
                                    setValidated(false);
                                }}>
                        </TextField>
                    </Stack>
                    <Stack
                        direction="row"
                        justifyContent="space-between">
                        <Stack
                            alignItems="center"
                            direction="row"
                            spacing={1}>
                            <Radio
                                checked={metadataFileEnabled}
                                onChange={(_, checked) => {
                                    setMetadataFileEnabled(checked);
                                    if (checked) {
                                        setMetadataUrlEnabled(false);
                                        setValidated(false);
                                    }
                                }}/>
                            <Typography
                                sx={{ fontWeight: 500 }}
                                variant="h4">
                                {localization.file()}
                            </Typography>
                        </Stack>
                        <Stack
                            alignItems="center"
                            direction="row"
                            justifyContent="space-between">
                            <Stack
                                direction="row"
                                justifyContent="flex-end"
                                spacing={1}>
                                <Button
                                    disabled={!metadataFileEnabled}
                                    variant="outlined"
                                    onClick={() => uploadButtonRef.current!.click()}>
                                    {localization.actions.upload()}
                                </Button>
                                <Button
                                    disabled={!configuration.metadataBytes}
                                    variant="outlined"
                                    onClick={() => {
                                        const a = document.createElement("a");
                                        const file = new Blob([atob(configuration.metadataBytes!)], { type: "application/xml" });
                                        a.href = URL.createObjectURL(file);
                                        a.download = `${UserHelper.customer!.id}-metadata.xml`;
                                        a.click();
                                        URL.revokeObjectURL(a.href);
                                    }}>
                                    {localization.actions.download()}
                                </Button>
                            </Stack>
                            <FormControl>
                                <InputBase
                                    inputRef={uploadButtonRef}
                                    slotProps={{ input: { accept: ".xml" } }}
                                    sx={{ display: "none" }}
                                    type="file"
                                    onChange={
                                        (event: React.ChangeEvent<HTMLInputElement>) => {
                                            const metadataFilePath = event.target.files?.item(0);
                                            if (metadataFilePath) {
                                                const fileReader = new FileReader();
                                                fileReader.readAsBinaryString(metadataFilePath);
                                                fileReader.onloadend =
                                                    async () => {
                                                        const metadataBytes = btoa(fileReader.result as string);
                                                        setConfiguration({
                                                            ...configuration,
                                                            metadataBytes
                                                        });
                                                        await validate(metadataBytes);
                                                    };
                                            }
                                        }
                                    }/>
                            </FormControl>
                        </Stack>
                    </Stack>
                </Stack>
            </FormLayout>
        </Dialog>);
}