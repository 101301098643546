import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, VerticalTopItemsInfoItem } from "../../../../../../../../../../common";
import { EntitiesInfoItem, TagsInfoItem } from "../../../../components";

export function useOpCommonKubernetesResourceInfoItemElements(resourceModel: Contract.OpResourceModel) {
    const resource = resourceModel.entity as Contract.OpKubernetesResourceBase;
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.op.useOpCommonKubernetesResourceInfoItemElements",
            () => ({
                annotations: "Annotations",
                cluster: "Cluster",
                tags: "Kubernetes Labels"
            }));

    return {
        annotationsInfoItemElement:
            <VerticalTopItemsInfoItem
                items={
                    _(resource.data.annotationKeyToValueMap).
                        map((annotationValue, annotationKey) => `${annotationKey}: ${annotationValue}`).
                        value()}
                key="annotations"
                location="all"
                title={localization.annotations()}/>,
        clusterInfoItemElement:
            <EntitiesInfoItem
                entityIdsOrModels={resource.clusterId}
                entityTypeName={Contract.TypeNames.OpUnmanagedKubernetesCluster}
                key="clusterId"
                location="all"
                title={localization.cluster()}/>,
        tagsInfoItemElement:
            <TagsInfoItem
                key="tags"
                location="all"
                resourceTags={resource.tags}
                title={localization.tags()}/>
    };
}