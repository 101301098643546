import { useLocalization } from "@infrastructure";
import React from "react";
import { useCustomEntityPropertyInfoItemElements } from "../..";
import { useAzureDefaultResourceInfoItemElements } from "..";
import { Contract, entityModelStore, InfoItem } from "../../../../../../../../../../../common";
import { EntitiesInfoItem, Info } from "../../../../../components";
import { AzureResourceRoleAssignmentResources } from "../../../components";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../../useDefinition";

export function useAzureContainerRegistryRepositoryDefinition(resourceGroupResourceModel: Contract.AzureResourceGroupResourceModel, options?: DefinitionOptions) {
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceGroupResourceModel);
    const defaultResourceInfoItemElements = useAzureDefaultResourceInfoItemElements(resourceGroupResourceModel);
    const repository = resourceGroupResourceModel.entity as Contract.AzureContainerRegistryRepository;
    const registryModel = entityModelStore.useGet(repository.parentEntityId);

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.azure.useAzureContainerRegistryRepositoryDefinition",
            () => ({
                info: {
                    items: {
                        containerImageCount: "Image Count",
                        rawUrl: "URI",
                        registry: "Registry"
                    }
                },
                tabs: {
                    resourceRoleAssignments: "Resource Role Assignments"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <AzureResourceRoleAssignmentResources
                    csvExportFilePrefix={localization.tabs.resourceRoleAssignments()}
                    scopeResourceModel={resourceGroupResourceModel}/>,
                localization.tabs.resourceRoleAssignments(),
                "resourceRoleAssignments")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="rawUrl"
                        location="all"
                        title={localization.info.items.rawUrl()}
                        value={repository.rawUrl}/>,
                    <InfoItem
                        key="artifactCount"
                        location="all"
                        title={localization.info.items.containerImageCount()}
                        value={repository.containerImageCount}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={registryModel}
                        entityTypeName={Contract.TypeNames.AzureContainerRegistryRegistry}
                        key="registryId"
                        location="all"
                        title={localization.info.items.registry()}/>
                ]}
                options={options?.infoOptions}>
            </Info>
    });
}