import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function PrincipalExcessivePermissionsIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <g clipPath="url(#clip0_57244_2470)">
                <path d="M5.23 23.9999H2.01C1.47 23.9999 0.97 23.7899 0.59 23.4099C0.21 23.0299 0 22.5299 0 21.9999V18.7699C0 18.4899 0.05 18.2099 0.16 17.9499C0.27 17.6899 0.43 17.4499 0.63 17.2499L6.92 10.9499C6.47 9.26991 6.53 7.46991 7.11 5.81991C7.76 3.96991 9.03 2.36991 10.69 1.32991C12.35 0.289907 14.33 -0.170093 16.29 0.0499071C18.24 0.269907 20.07 1.14991 21.46 2.53991C22.85 3.92991 23.73 5.75991 23.95 7.70991C24.13 9.32991 23.86 10.9599 23.16 12.4199C22.92 12.9199 22.32 13.1299 21.83 12.8899C21.33 12.6499 21.12 12.0599 21.36 11.5599C21.9 10.4299 22.11 9.17991 21.97 7.92991C21.8 6.42991 21.12 5.01991 20.05 3.94991C18.98 2.87991 17.57 2.19991 16.07 2.02991C14.57 1.85991 13.04 2.20991 11.76 3.00991C10.48 3.80991 9.51 5.03991 9.01 6.46991C8.51 7.89991 8.51 9.45991 9.01 10.8899L9.22 11.4799L2.01 18.7599V21.9799H5.24C5.24 21.9799 5.32 21.9799 5.35 21.9399L6.16 21.1299C6.16 21.1299 6.22 21.0299 6.22 20.9699V18.7499H8.25C8.36 18.7499 8.46 18.7099 8.54 18.6299C8.62 18.5499 8.66 18.4499 8.66 18.3399V16.3099H12.72C13.27 16.3099 13.72 16.7599 13.72 17.3099C13.72 17.8599 13.27 18.3099 12.72 18.3099L10.66 18.3399C10.66 18.9799 10.41 19.5899 9.96 20.0399C9.51 20.4899 8.89 20.7499 8.26 20.7499H8.23V20.9699C8.23 21.5599 8 22.1099 7.59 22.5299L6.77 23.3499C6.36 23.7499 5.82 23.9699 5.25 23.9699L5.23 23.9999Z"/>
                <path d="M16.2499 10.5599C15.6899 10.5599 15.1499 10.3999 14.6899 10.0899C14.2299 9.77988 13.8699 9.33988 13.6599 8.82988C13.4499 8.31988 13.3899 7.74988 13.4999 7.20988C13.6099 6.66988 13.8799 6.16988 14.2699 5.76988C14.6599 5.37988 15.1599 5.10988 15.7099 4.99988C16.2499 4.88988 16.8199 4.94988 17.3299 5.15988C17.8399 5.36988 18.2799 5.72988 18.5899 6.18988C18.8999 6.64988 19.0599 7.18988 19.0599 7.74988C19.0599 8.48988 18.7599 9.20988 18.2399 9.73988C17.7099 10.2699 16.9999 10.5599 16.2499 10.5599ZM16.2499 6.48988C15.9999 6.48988 15.7499 6.55988 15.5399 6.70988C15.3299 6.84988 15.1699 7.04988 15.0699 7.27988C14.9699 7.50988 14.9499 7.76988 14.9999 8.01988C15.0499 8.26988 15.1699 8.48988 15.3499 8.66988C15.5299 8.84988 15.7599 8.96988 15.9999 9.01988C16.2499 9.06988 16.4999 9.03988 16.7399 8.94988C16.9699 8.84988 17.1699 8.68988 17.3099 8.47988C17.4499 8.26988 17.5299 8.01988 17.5299 7.76988C17.5299 7.59988 17.4999 7.43988 17.4299 7.27988C17.3699 7.11988 17.2699 6.97988 17.1499 6.86988C17.0299 6.74988 16.8899 6.65988 16.7399 6.58988C16.5899 6.52988 16.4199 6.48988 16.2499 6.48988Z"/>
                <path d="M17.5996 24C16.7162 24 16 23.2838 16 22.4004C16 21.5169 16.7162 20.8007 17.5996 20.8007C18.4831 20.8007 19.1993 21.5169 19.1993 22.4004C19.1993 23.2838 18.4831 24 17.5996 24Z"/>
                <path d="M17.1667 12.0002C16.5301 12.0004 16.0205 12.5291 16.0445 13.1653L16.2416 18.3932C16.2643 18.9963 16.7595 19.4734 17.363 19.4734C17.4816 19.4734 17.6258 19.4734 17.8369 19.4734C18.4404 19.4734 18.9361 18.9963 18.9588 18.3932L19.156 13.1646C19.1799 12.5287 18.6708 11.9999 18.0344 12C17.7531 12 17.532 12.0002 17.1667 12.0002Z"/>
            </g>
            <defs>
                <clipPath id="clip0_57244_2470">
                    <rect
                        height="24"
                        width="24"/>
                </clipPath>
            </defs>
        </SvgIcon>);
}