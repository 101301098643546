import { ActionMenuItem, DataTableColumnRenderProps, DeleteIcon, EditIcon, Menu, Message, useLocalization } from "@infrastructure";
import { CircularProgress, Stack } from "@mui/material";
import React, { Fragment, useState } from "react";
import { OktaTenantsContext, useSetOktaTenantsContext } from "../../..";
import { Contract, tenantModelStore, useTheme } from "../../../../../../../../../../../../../../../common";

export function ActionsCell({ item }: DataTableColumnRenderProps<Contract.OktaTenantModel>) {
    const setOktaTenantsContext = useSetOktaTenantsContext();
    const [deleteTenantExecuting, setDeleteTenantExecuting] = useState(false);
    const [deleteTenantError, setDeleteTenantError] = useState(false);

    async function deleteTenant() {
        setDeleteTenantExecuting(true);
        setDeleteTenantError(false);
        try {
            await tenantModelStore.updateDeleted(item.configuration.id);
        } catch {
            setDeleteTenantError(true);
        }
        setDeleteTenantExecuting(false);
    }

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useIdentityProviderTenantItems.oktaTenants.table.actionsCell",
            () => ({
                actions: {
                    delete: {
                        error: "Failed to delete Okta organization",
                        prompt: "Are you sure you want to delete the Okta organization {{tenantName}}?",
                        title: "Delete"
                    },
                    edit: "Edit"
                }
            }));
    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            direction="row"
            justifyContent="flex-end"
            spacing={1.5}>
            {deleteTenantExecuting && (
                <CircularProgress
                    size={theme.spacing(2)}
                    variant="indeterminate"/>)}
            {deleteTenantError && (
                <Message
                    level="error"
                    title={localization.actions.delete.error()}
                    variant="minimal"/>)}
            {!item.configuration.systemDeleted && (
                <Fragment>
                    <Menu
                        itemsOrGetItems={[
                            new ActionMenuItem(
                                () => setOktaTenantsContext(new OktaTenantsContext(item.configuration as Contract.OktaTenantConfiguration)),
                                localization.actions.edit(),
                                {
                                    disabled: deleteTenantExecuting,
                                    icon: <EditIcon/>
                                }),
                            new ActionMenuItem(
                                () => deleteTenant(),
                                localization.actions.delete.title(),
                                {
                                    confirmOptions: {
                                        message: localization.actions.delete.prompt({ tenantName: item.configuration.name })
                                    },
                                    disabled: deleteTenantExecuting,
                                    icon: <DeleteIcon/>
                                })
                        ]}/>
                </Fragment>)}
        </Stack>);
}