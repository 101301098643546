import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function RemoveColumnIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path d="M17 3C16.45 3 16 3.45 16 4V10C16 10.55 16.45 11 17 11C17.55 11 18 10.55 18 10V4C18 3.45 17.55 3 17 3ZM5 19C5.55 19 6 18.55 6 18V4C6 3.45 5.55 3 5 3C4.45 3 4 3.45 4 4V18C4 18.55 4.45 19 5 19ZM11 19C11.55 19 12 18.55 12 18V4C12 3.45 11.55 3 11 3C10.45 3 10 3.45 10 4V18C10 18.55 10.45 19 11 19Z"/>
            <path
                clipRule="evenodd"
                d="M20.1816 13.318C20.5721 13.7086 20.5721 14.3417 20.1816 14.7323L15.2318 19.682C14.8413 20.0725 14.2081 20.0725 13.8176 19.682C13.4271 19.2915 13.4271 18.6583 13.8176 18.2678L18.7674 13.318C19.1579 12.9275 19.791 12.9275 20.1816 13.318Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd" 
                d="M20.1822 19.6821C19.7917 20.0726 19.1585 20.0726 18.768 19.6821L13.8182 14.7323C13.4277 14.3418 13.4277 13.7086 13.8182 13.3181C14.2088 12.9276 14.8419 12.9276 15.2325 13.3181L20.1822 18.2678C20.5727 18.6584 20.5727 19.2915 20.1822 19.6821Z"
                fillRule="evenodd"/>
        </SvgIcon>);
}