import { InlineCopyToClipboardText, Link, Step, Steps, useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import React from "react";
import { CustomerConsoleAppUrlHelper } from "../../../../../../../../../../../../../common";
import { useAddOrEditContext } from "../../../index";

export function Manual() {
    const { gciTenantModel, tenantOnboardingInfo } = useAddOrEditContext();

    const localization =
        useLocalization(
            "views.customer.scopes.hooks.useDefinition.hooks.useGcpDefinition.addOrEdit.organizationRolesItem.manual",
            () => ({
                steps: {
                    step1: "Log into your Google Cloud Console and select the **organization** that contains the project you want to add using the resource selector at the top left of the screen.",
                    step2: "Navigate to **IAM & Admin -> IAM**.",
                    step3: "Click **+ Grant Access.**",
                    step4: "Copy the following value and paste it in the **New principals** field: {{serviceAccountMail}}",
                    step5: {
                        documentationLink: "documentation",
                        info: "By default, and for simplicity, manual onboarding uses built-in roles. As an alternative, you can create and assign a custom role instead. For more information, see the {{documentationLink}}.",
                        text: "Copy the following roles and paste them into the **Role** field, one by one. Click **+ADD ANOTHER ROLE** for each additional role."
                    },
                    step6: "Click **Save**"
                }
            }));

    return (
        <Steps variant="letters">
            {localization.steps.step1()}
            {localization.steps.step2()}
            {localization.steps.step3()}
            {localization.steps.step4({
                serviceAccountMail:
                    <InlineCopyToClipboardText text={gciTenantModel!.configuration.serviceAccountMail}/>
            })}
            {new Step(
                localization.steps.step5.text(),
                {
                    contentElement:
                        <Stack spacing={3}>
                            <Steps variant="plainNumbers">
                                {tenantOnboardingInfo.serviceAccountOrganizationReadOnlyRoleNames}
                            </Steps>
                            <Typography>
                                {localization.steps.step5.info({
                                    documentationLink:
                                        <Link
                                            urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsOnboardGcpRelativeUrl()}
                                            variant="external">
                                            {localization.steps.step5.documentationLink()}
                                        </Link>
                                })}
                            </Typography>
                        </Stack>
                })}
            {localization.steps.step6()}
        </Steps>);
}