import { useLocalization } from "@infrastructure";
import React from "react";
import { useAwsCommonEncryptedResourceInfoElements, useAwsDefaultResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { useCustomEntityPropertyInfoItemElements } from "..";
import { Contract, InfoItem } from "../../../../../../../../../../common";
import { useAwsDocDbClusterModelTypeTranslator, useAwsResourceSnapshotTypeTranslator } from "../../../../../../hooks";
import { EntitiesInfoItem, Info } from "../../../../components";

export function useAwsDocDbElasticClusterSnapshotDefinition(resourceModel: Contract.AwsResourceModel, options?: DefinitionOptions) {
    const snapshotModel = resourceModel as Contract.AwsDocDbElasticClusterSnapshotModel;
    const snapshot = snapshotModel.entity as Contract.AwsDocDbElasticClusterSnapshot;
    const commonEncryptedResourceInfoElements = useAwsCommonEncryptedResourceInfoElements(resourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useAwsDefaultResourceInfoItemElements(resourceModel);

    const docDbClusterModelTypeTranslator = useAwsDocDbClusterModelTypeTranslator();
    const snapshotTypeTranslator = useAwsResourceSnapshotTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsDocDbElasticClusterSnapshotDefinition",
            () => ({
                info: {
                    items: {
                        clusterType: "Cluster Type",
                        status: "Status",
                        type: "Snapshot Type"
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="type"
                        title={localization.info.items.type()}
                        value={snapshotTypeTranslator(snapshot.type)}/>,
                    <InfoItem
                        key="clusterType"
                        title={localization.info.items.clusterType()}
                        value={docDbClusterModelTypeTranslator(snapshotModel.clusterType)}/>,
                    <InfoItem
                        key="status"
                        title={localization.info.items.status()}
                        value={snapshot.status}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={snapshot.clusterId}
                        entityTypeName={Contract.TypeNames.AwsDocDbElasticCluster}
                        key="clusterId"/>,
                    ...commonEncryptedResourceInfoElements
                ]}
                options={options?.infoOptions}>
            </Info>
    });
}