import { useLocalization } from "@infrastructure";
import _ from "lodash";
import { AuditEventDefinition, AuditEventDefinitionContext, AuditEventDefinitionDetailItem } from "../..";
import { Contract } from "../../../../../../../../../../common";
import { useScopeSettingConfigurationDerivedTypeNameTranslator } from "../../../../../../../Configuration/components/SettingConfigurationTable/hooks";
import { useGetScopeSettingDeletionAuditEventDetailsItems } from "./hooks/useGetScopeSettingDeletionAuditEventDetailsItems";
import { useGetScopeSettingUpdateAuditEventDetailsItems } from "./hooks/useGetScopeSettingUpdateAuditEventDetailsItems";

export function useScopeSettingAuditEventDefinition(context: AuditEventDefinitionContext) {
    const getScopeSettingDeletionAuditEventDetailsItems = useGetScopeSettingDeletionAuditEventDetailsItems();
    const getScopeSettingUpdateAuditEventDetailsItems = useGetScopeSettingUpdateAuditEventDetailsItems();
    const scopeSettingAuditEvent = context.auditEventModel.auditEvent as Contract.ScopeSettingAuditEvent;
    const scopeSettingConfigurationDerivedTypeNameTranslator = useScopeSettingConfigurationDerivedTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.auditEvents.profile.hooks.useDefinition.hooks.useScopeSettingAuditEventDefinition",
            () => ({
                scopeSettingName: "Name"
            }));

    return new AuditEventDefinition(
        _<AuditEventDefinitionDetailItem>(
            [new AuditEventDefinitionDetailItem(
                localization.scopeSettingName(),
                scopeSettingConfigurationDerivedTypeNameTranslator(scopeSettingAuditEvent.scopeSettingConfigurationTypeName as Contract.ScopeSettingConfigurationDerivedTypeNames).title)]).
            concatIf(
                scopeSettingAuditEvent.typeName === Contract.TypeNames.ScopeSettingDeletionAuditEvent,
                () => getScopeSettingDeletionAuditEventDetailsItems((scopeSettingAuditEvent as Contract.ScopeSettingDeletionAuditEvent).data)).
            concatIf(
                scopeSettingAuditEvent.typeName === Contract.TypeNames.ScopeSettingUpdateAuditEvent,
                () => getScopeSettingUpdateAuditEventDetailsItems((scopeSettingAuditEvent as Contract.ScopeSettingUpdateAuditEvent).data)).
            value());
}