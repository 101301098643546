import { useExecuteOperation, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, EntityController, entityModelStore } from "../../../../../../../../../common";
import { EntityTypeNameGroupsInfoCard } from "../../../components";

type KubernetesClusterResourcesInfoCardProps = {
    clusterId: string;
};

export function KubernetesClusterResourcesInfoCard({ clusterId }: KubernetesClusterResourcesInfoCardProps) {
    const [resourceTypeNameToIdsMap] =
        useExecuteOperation(
            KubernetesClusterResourcesInfoCard,
            async () => {
                const { resourceModels } =
                    await EntityController.getClusterKubernetesResourceModels(
                        new Contract.EntityControllerGetClusterKubernetesResourceModelsRequest(
                            clusterId));

                await entityModelStore.notify(resourceModels);

                return _(resourceModels).
                    groupBy(resourceModel => resourceModel.entity.typeName).
                    mapValues(
                        resourceModels =>
                            _.map(
                                resourceModels,
                                resourceModel => resourceModel.entity.id)).
                    value();
            });
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.kubernetesClusterResourcesInfoCard",
            () => ({
                empty: "No Resources",
                title: "Resources"
            }));
    return (
        <EntityTypeNameGroupsInfoCard
            emptyMessage={localization.empty()}
            entityTypeNameToIdsMap={resourceTypeNameToIdsMap}
            title={localization.title()}/>);
}