import { UnexpectedError } from "@infrastructure";
import { useMemo } from "react";
import { Contract } from "../../../../../..";
import { useAzureCreateRoleAssignmentResourceChangeDefinition, useAzureDeleteRoleAssignmentClassicAdministratorChangeDefinition, useAzureDeleteRoleAssignmentResourceChangeDefinition, useAzureDeleteSystemManagedIdentityChangeDefinition, useAzureDeleteUserManagedIdentityChangeDefinition } from "./hooks";

export function useAzureDefinition(change: Contract.AzureChange, entityLinkDisabled?: boolean) {
    const useDefinition =
        useMemo(
            () => {
                switch (change.typeName) {
                    case Contract.TypeNames.AzureCreateRoleAssignmentChange:
                    case Contract.TypeNames.AzureCreateRoleAssignmentScheduleChange:
                    case Contract.TypeNames.AzureCreateRoleEligibilityScheduleChange:
                        return useAzureCreateRoleAssignmentResourceChangeDefinition;
                    case Contract.TypeNames.AzureDeleteRoleAssignmentChange:
                    case Contract.TypeNames.AzureDeleteRoleAssignmentScheduleChange:
                    case Contract.TypeNames.AzureDeleteRoleEligibilityScheduleChange:
                        return useAzureDeleteRoleAssignmentResourceChangeDefinition;
                    case Contract.TypeNames.AzureDeleteRoleAssignmentClassicAdministratorChange:
                        return useAzureDeleteRoleAssignmentClassicAdministratorChangeDefinition;
                    case Contract.TypeNames.AzureDeleteSystemManagedIdentityChange:
                        return useAzureDeleteSystemManagedIdentityChangeDefinition;
                    case Contract.TypeNames.AzureDeleteUserManagedIdentityChange:
                        return useAzureDeleteUserManagedIdentityChangeDefinition;
                    default:
                        throw new UnexpectedError("change.typeName", change.typeName);
                }
            },
            []);

    return useDefinition(change, entityLinkDisabled);
}