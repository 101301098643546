import { AnalyticsEventActionType, Loading, makeContextProvider, useLocalization, useTrackAnalyticsEvent } from "@infrastructure";
import { Tab, Tabs } from "@mui/material";
import _ from "lodash";
import React, { Fragment, useEffect, useMemo } from "react";
import { CustomerConsoleAppUrlHelper, ScopeNavigationView, TenantHelper, useComplianceSectionDatas, useLayoutOptions, useScopeNavigationViewContext, useTheme } from "../../../../common";
import { CustomerView } from "../../hooks";
import { AddOrEdit, Items } from "./components";

export class ComplianceContext {
    constructor(
        public childScopeCustomCompliance: boolean,
        public refreshCompliance?: () => Promise<void>) {
    };
}

export const [useComplianceContext, useSetComplianceContext, useComplianceContextProvider] = makeContextProvider<ComplianceContext>();

export type ComplianceQueryParameters = {
    toggleFilterMap?: string;
};

export function Compliance() {
    const [, , ContextProvider] =
        useComplianceContextProvider(
            () => new ComplianceContext(false),
            []);

    const complianceTenantTypes =
        useMemo(
            () => TenantHelper.ComplianceTenantTypes,
            []);
    const localization =
        useLocalization(
            "views.customer.compliance",
            () => ({
                title: "Compliance"
            }));

    useLayoutOptions({ view: { title: localization.title() } });
    useTrackAnalyticsEvent(AnalyticsEventActionType.PageView);
    const templatePath = CustomerConsoleAppUrlHelper.getTemplatePath(CustomerView.Compliance);

    return (
        <ScopeNavigationView
            layout={"global"}
            templatePath={templatePath}
            tenantTypes={complianceTenantTypes}>
            <ContextProvider>
                <View templatePath={templatePath}/>
            </ContextProvider>
        </ScopeNavigationView>);
}

type ViewProps = {
    templatePath: string;
};

function View({ templatePath }: ViewProps) {
    const { scopeNodeModel, useViewRoute } = useScopeNavigationViewContext();
    const setComplianceContext = useSetComplianceContext();
    const { childScopeCustomCompliance } = useComplianceContext();
    const [view, setView] = useViewRoute("{view}", _.values(ComplianceView));

    useLayoutOptions({ scopeDisabled: view === ComplianceView.Edit || view === ComplianceView.Create });

    const complianceData =
        useComplianceSectionDatas(
            scopeNodeModel.configuration.id,
            {
                childScopeCustomCompliance,
                timeFrameData: true
            });

    useEffect(
        () => {
            setComplianceContext(
                context => ({
                    ...context,
                    refreshCompliance: complianceData.refreshComplianceSectionDatas
                }));
        },
        []);

    const localization =
        useLocalization(
            "views.customer.compliance.view",
            () => ({
                tabs: {
                    view: {
                        [ComplianceView.BuiltIn]: "Built-in",
                        [ComplianceView.Custom]: "Custom"
                    }
                }
            }));

    const theme = useTheme();
    return view === ComplianceView.BuiltIn || view === ComplianceView.Custom
        ? <Fragment>
            <Tabs
                sx={{
                    border: "unset",
                    padding: theme.spacing(0, 2.5)
                }}
                value={view}
                onChange={
                    (_, complianceView) =>
                        setView(complianceView)}>
                <Tab
                    label={localization.tabs.view[ComplianceView.BuiltIn]()}
                    sx={{
                        marginRight: theme.spacing(3),
                        padding: 0
                    }}
                    value={ComplianceView.BuiltIn}/>
                <Tab
                    label={localization.tabs.view[ComplianceView.Custom]()}
                    sx={{ padding: 0 }}
                    value={ComplianceView.Custom}/>
            </Tabs>
            <Loading key={view}>
                <Items
                    complianceData={complianceData}
                    view={view}/>
            </Loading>
        </Fragment>
        : <AddOrEdit
            complianceData={complianceData}
            templatePath={templatePath}/>;
}

export enum ComplianceView {
    BuiltIn = "builtIn",
    Create = "create",
    Custom = "custom",
    Edit = "edit"
}