import { DiffEditor, DownloadIcon, useLocalization } from "@infrastructure";
import { Box, Divider, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { useTheme } from "../../../../../../../../../../../common";

type ResourceRiskPolicyDiffProps = {
    existingRawPolicyDocument: string;
    newRawPolicyDocument?: string;
    policyFileName: string;
};

export function ResourceRiskPolicyDiff({ existingRawPolicyDocument, newRawPolicyDocument, policyFileName }: ResourceRiskPolicyDiffProps) {
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.resourceRiskPolicyDiff",
            () => ({
                existingPolicyName: "EXISTING POLICY",
                newPolicyName: "SUGGESTED POLICY",
                title: "Policy difference"
            }));


    const policyJsonFileName = `${policyFileName}.json`;
    const policyFileUrl =
        useMemo(
            () =>
                window.URL.createObjectURL(
                    new Blob(
                        [newRawPolicyDocument ?? ""],
                        { type: "application/json" })),
            [newRawPolicyDocument]);

    const theme = useTheme();
    return (
        <Stack
            spacing={1}
            sx={{
                height: "100%",
                padding: theme.spacing(1.5, 0)
            }}>
            <Stack
                direction="row"
                spacing={2}>
                <Typography
                    noWrap={true}
                    sx={{
                        flex: 1,
                        padding: theme.spacing(0.5, 0)
                    }}
                    variant="h2">
                    {localization.title()}
                </Typography>
                <Box
                    sx={{
                        alignItems: "center",
                        border: theme.border.primary,
                        borderRadius: theme.spacing(0.75),
                        display: "flex",
                        height: theme.spacing(4),
                        justifyContent: "center",
                        padding: theme.spacing(1),
                        width: theme.spacing(4)
                    }}>
                    <a
                        download={policyJsonFileName}
                        href={policyFileUrl}
                        title={`Download ${policyJsonFileName} file`}>
                        <DownloadIcon
                            sx={{
                                color: theme.palette.text.primary,
                                fontSize: "24px"
                            }}/>
                    </a>
                </Box>
            </Stack>
            <Divider/>
            <Box
                sx={{
                    flex: 1,
                    padding: theme.spacing(1.5, 0)
                }}>
                <DiffEditor
                    autoHeight={true}
                    format="json"
                    modified={newRawPolicyDocument}
                    modifiedTitle={
                        !_.isNil(newRawPolicyDocument)
                            ? localization.newPolicyName()
                            : localization.existingPolicyName()}
                    original={existingRawPolicyDocument}
                    originalTitle={localization.existingPolicyName()}/>
            </Box>
        </Stack>);
}