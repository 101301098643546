import { DataTableColumnRenderProps, Message, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, InlineDeliveries, useTheme } from "../../../../../../../common";

export function IntegrationsCell({ item }: DataTableColumnRenderProps<Contract.AutomationRuleConfiguration>) {
    const deliveries =
        useMemo(
            () =>
                _.map(
                    item.deliveries,
                    ({ delivery }) => delivery),
            [item]);

    const localization =
        useLocalization(
            "views.customer.configuration.automations.integrationsCell",
            () => ({
                empty: "This rule has been configured to trigger automations on {{type}} with specified integrations that have been deleted, so no automation will be triggered. Edit the rule to configure new integrations.",
                types: {
                    auditEvents: "audit events",
                    risks: "findings"
                }
            }));

    const theme = useTheme();

    return _.isEmpty(item.deliveries)
        ? <Message
            level="error"
            sx={{ padding: theme.spacing(1) }}
            title={localization.empty({
                type:
                    item.typeName === Contract.TypeNames.AuditEventAutomationRuleConfiguration
                        ? localization.types.auditEvents()
                        : localization.types.risks()
            })}
            variant="minimal"/>
        : <InlineDeliveries deliveries={deliveries}/>;
}