import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract } from "../../../../../../..";
import { Entity } from "../../../../../..";

export function useAwsDeleteInlinePolicyChangeDefinition(change: Contract.AwsChange, entityLinkDisabled?: boolean) {
    const deleteInlinePolicyChange = change as Contract.AwsDeleteInlinePolicyChange;
    const localization =
        useLocalization(
            "common.change.hooks.useDefinition.hooks.useAwsDefinition.hooks.useAwsDeleteInlinePolicyChangeDefinition",
            () => ({
                title: "Delete inline {{inlinePolicy}}"
            }));
    return {
        title:
            localization.title({
                inlinePolicy:
                    <Entity
                        entityIdOrModel={deleteInlinePolicyChange.resourceId}
                        glanceOptions={{ disabled: true }}
                        linkOptions={{ disabled: entityLinkDisabled }}
                        variant="typeText"/>
            })
    };
}