﻿import { DataTable, DataTableColumn, DataTableColumnRenderProps, EmptyMessageText, Optional, optionalTableCell, TimeFormatter, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { AadDirectoryApplicationExpirationDateCell } from ".";
import { Contract, EntitiesCell, entityModelStore } from "../../../common";

type AadDirectoryApplicationSecretsTableProps = {
    applicationModel: Contract.AadDirectoryApplicationModel;
    getHighlightColor?: (secretId: string, opacity?: number) => Optional<string>;
};

export function AadDirectoryApplicationSecretsTable({ applicationModel, getHighlightColor }: AadDirectoryApplicationSecretsTableProps) {
    const directoryApplicationSecretModel = entityModelStore.useGet(applicationModel.secretIds);

    const localization =
        useLocalization(
            "tenants.aad.aadDirectoryApplicationSecretsTable",
            () => ({
                columns: {
                    creationTime: "Creation Time",
                    description: "Description",
                    expirationTime: "Expiration Time",
                    secret: "Secret"
                },
                empty: "No Secrets"
            }));
    return (
        <DataTable
            emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty()) }}
            fetchItems={() => directoryApplicationSecretModel}
            getItemId={(secret: Contract.AadDirectoryApplicationSecretModel) => secret.id}
            rowOptions={{
                getHighlightColor: (secret: Contract.AadDirectoryApplicationSecretModel) => getHighlightColor?.(secret.id),
                getSx:
                    (secret: Contract.AadDirectoryApplicationSecretModel) => ({
                        backgroundColor: getHighlightColor?.(secret.id, 0.1)
                    })
            }}
            sortOptions={{ enabled: false }}
            variant="card">
            <DataTableColumn
                id={ApplicationSecretsInfoCardTableColumnId.SecretId}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AadDirectoryApplicationSecretModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item}
                            entityTypeName={Contract.TypeNames.AadDirectoryApplicationSecret}
                            entityVariant="iconTextTypeTenant"/>}
                title={localization.columns.secret()}/>
            <DataTableColumn
                id={ApplicationSecretsInfoCardTableColumnId.RawDisplayName}
                render={optionalTableCell<Contract.AadDirectoryApplicationSecretModel>(item => (item.entity as Contract.AadDirectoryApplicationSecret).description)}
                title={localization.columns.description()}/>
            <DataTableColumn
                id={ApplicationSecretsInfoCardTableColumnId.CreationTime}
                render={
                    optionalTableCell<Contract.AadDirectoryApplicationSecretModel>(
                        item =>
                            _.isNil((item.entity as Contract.AadDirectoryApplicationSecret).creationTime)
                                ? undefined
                                : TimeFormatter.monthDayAndYear((item.entity as Contract.AadDirectoryApplicationSecret).creationTime))}
                title={localization.columns.creationTime()}/>
            <DataTableColumn
                id={ApplicationSecretsInfoCardTableColumnId.ExpirationTime}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AadDirectoryApplicationSecretModel>) =>
                        <AadDirectoryApplicationExpirationDateCell
                            expirationDate={(item.entity as Contract.AadDirectoryApplicationSecret).expirationTime}
                            expirationStatus={(item.entity as Contract.AadDirectoryApplicationSecret).expirationStatus}
                            variant="date"/>}
                title={localization.columns.expirationTime()}/>
        </DataTable>);
}

enum ApplicationSecretsInfoCardTableColumnId {
    CreationTime = "creationTime",
    ExpirationTime = "expirationTime",
    RawDisplayName = "rawDisplayName",
    SecretId = "secretId"
}