import { NumberFormatter, useLocalization } from "@infrastructure";
import React from "react";
import { useAwsCommonEc2ResourceInfoElements, useAwsCommonEncryptedResourceInfoElements, useAwsDefaultResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { useCustomEntityPropertyInfoItemElements } from "..";
import { Contract, InfoItem } from "../../../../../../../../../../common";
import { EntitiesInfoItem, Info } from "../../../../components";

export function useAwsEc2VolumeDefinition(resourceModel: Contract.AwsResourceModel, options?: DefinitionOptions) {
    const commonEc2ResourceInfoItemElements = useAwsCommonEc2ResourceInfoElements(resourceModel);
    const commonEncryptedResourceInfoElements = useAwsCommonEncryptedResourceInfoElements(resourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useAwsDefaultResourceInfoItemElements(resourceModel);
    const volume = resourceModel.entity as Contract.AwsEc2Volume;
    const volumeModel = resourceModel as Contract.AwsEc2VolumeModel;

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsEc2VolumeDefinition",
            () => ({
                info: {
                    items: {
                        instanceIds: "Instances",
                        instanceTerminationDeletion: {
                            false: "No",
                            title: "Delete on Termination",
                            true: "Yes"
                        },
                        productCodeId: "Product Code Id",
                        productCodeType: "Product Code Type",
                        root: {
                            false: "No",
                            title: "Root Device",
                            true: "Yes"
                        },
                        size: "Size",
                        snapshotIds: "Snapshots",
                        sourceSnapshot: "Source Snapshot",
                        volumeType: "Volume Type"
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    ...commonEc2ResourceInfoItemElements,
                    ...commonEncryptedResourceInfoElements,
                    <InfoItem
                        key="size"
                        title={localization.info.items.size()}
                        value={NumberFormatter.storage(volume.size)}/>,
                    <InfoItem
                        key="volumeType"
                        title={localization.info.items.volumeType()}
                        value={volume.rawType}/>,
                    <InfoItem
                        key="root"
                        title={localization.info.items.root.title()}
                        value={
                            volumeModel.root
                                ? localization.info.items.root.true()
                                : localization.info.items.root.false()}/>,
                    <InfoItem
                        key="instanceTerminationDeletion"
                        title={localization.info.items.instanceTerminationDeletion.title()}
                        value={
                            volume.instanceTerminationDeletion
                                ? localization.info.items.instanceTerminationDeletion.true()
                                : localization.info.items.instanceTerminationDeletion.false()}/>,
                    <EntitiesInfoItem
                        emptyValue={volume.sourceSnapshotRawId}
                        entityIdsOrModels={volumeModel.sourceSnapshotId}
                        entityTypeName={Contract.TypeNames.AwsEc2Snapshot}
                        key="sourceSnapshotId"
                        title={localization.info.items.sourceSnapshot()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={volumeModel.snapshotIds}
                        entityTypeName={Contract.TypeNames.AwsEc2Snapshot}
                        key="snapshotIds"
                        title={localization.info.items.snapshotIds()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={volumeModel.instanceIds}
                        entityTypeName={Contract.TypeNames.AwsEc2Instance}
                        key="instanceIds"
                        title={localization.info.items.instanceIds()}/>,
                    <InfoItem
                        key="productCodeType"
                        title={localization.info.items.productCodeType()}
                        value={volume?.productCode?.type}/>,
                    <InfoItem
                        key="productCodeId"
                        title={localization.info.items.productCodeId()}
                        value={volume?.productCode?.id}/>
                ]}
                options={options?.infoOptions}>
            </Info>
    });
}