import { DeleteIcon, ItemSelector, map, useLocalization } from "@infrastructure";
import { Box, Grid2, IconButton, Stack, TextField, Typography } from "@mui/material";
import _ from "lodash";
import React, { Fragment } from "react";
import { Contract, EntityPropertyPatternHelper, InlineScopes, useExclusionEntityPropertyPatternTranslator, useTheme } from "../../../../../../../../../../../../common";
import { EntityTypeNamePropertyPatternItem } from "../../EntityTypeNamePropertyPattern";
import { AwsResourceArnPattern, EntityNamePattern, ResourceTagPattern } from "./components";

type PropertyPatternProps = {
    duplicate: boolean;
    onDelete?: () => void;
    onPropertyPatternItemChanged: (item: EntityTypeNamePropertyPatternItem) => void;
    propertyPatternItem?: EntityTypeNamePropertyPatternItem;
    propertyPatternTypeNames: string[];
    readOnly: boolean;
    scopeIds?: string[];
};

export function EntityPropertyPattern({ duplicate, onDelete, onPropertyPatternItemChanged, propertyPatternItem, propertyPatternTypeNames, readOnly, scopeIds }: PropertyPatternProps) {
    const exclusionEntityPropertyPatternTranslator = useExclusionEntityPropertyPatternTranslator();

    const localization =
        useLocalization(
            "views.customer.riskPolicies.configuration.entityExclusion.entityTypeNamePropertyPattern.entityPropertyPattern",
            () => ({
                inherited: "Inherited from",
                message: "Comment",
                placeHolder: "Property name"
            }));

    const inherited = !_.isEmpty(scopeIds);
    const theme = useTheme();
    return (
        <Grid2
            alignItems="start"
            container={true}
            spacing={1}
            width="100%">
            <Grid2
                size={{ xs: 1.5 }}
                sx={{ minWidth: theme.spacing(18.5) }}>
                <ItemSelector
                    disabled={inherited || readOnly}
                    fullWidth={true}
                    getItemText={item => exclusionEntityPropertyPatternTranslator(item)}
                    items={propertyPatternTypeNames}
                    placeholder={localization.placeHolder()}
                    selectedItem={propertyPatternItem?.propertyPattern.typeName}
                    sorted={true}
                    onSelectedItemChanged={
                        entityPropertyPatternTypeName =>
                            onPropertyPatternItemChanged(
                                new EntityTypeNamePropertyPatternItem(
                                    EntityPropertyPatternHelper.getExclusionEntityPropertyPattern(entityPropertyPatternTypeName),
                                    true))}>
                    {(entityPropertyPatternTypeName: string) => exclusionEntityPropertyPatternTranslator(entityPropertyPatternTypeName)}
                </ItemSelector>
            </Grid2>
            <Grid2 size={{ xs: 4 }}>
                {map(
                    propertyPatternItem?.propertyPattern.typeName,
                    {
                        [Contract.TypeNames.RiskPolicyConfigurationEntityExclusionAwsResourceArnPattern]:
                            () => (
                                <AwsResourceArnPattern
                                    duplicate={duplicate}
                                    propertyPatternItem={propertyPatternItem!}
                                    readOnly={inherited || readOnly}
                                    onItemChanged={onPropertyPatternItemChanged}/>),
                        [Contract.TypeNames.RiskPolicyConfigurationEntityExclusionEntityNamePattern]:
                            () => (
                                <EntityNamePattern
                                    duplicate={duplicate}
                                    propertyPatternItem={propertyPatternItem!}
                                    readOnly={inherited || readOnly}
                                    onItemChanged={onPropertyPatternItemChanged}/>),
                        [Contract.TypeNames.RiskPolicyConfigurationEntityExclusionResourceTagPattern]:
                            () => (
                                <ResourceTagPattern
                                    duplicate={duplicate}
                                    propertyPatternItem={propertyPatternItem!}
                                    readOnly={inherited || readOnly}
                                    onItemChanged={onPropertyPatternItemChanged}/>)
                    },
                    () => <Fragment/>)
                }
            </Grid2>
            {inherited && (
                <Grid2 sx={{ padding: theme.spacing(2.5, 1, 0) }}>
                    <Stack
                        direction="row"
                        spacing={1}>
                        <Typography>
                            {localization.inherited()}
                        </Typography>
                        <Box>
                            <InlineScopes
                                scopeIds={scopeIds!}
                                scopeVariant="iconText"
                                variant="itemCountAndType"/>
                        </Box>
                    </Stack>
                </Grid2>)}
            <Grid2 size={{ xs:5 }}>
                {(!_.isNil(onDelete) &&
                    !inherited) && (
                    <TextField
                        fullWidth={true}
                        label={localization.message()}
                        maxRows={1}
                        multiline={true}
                        slotProps={{ input: { readOnly } }}
                        value={propertyPatternItem?.propertyPattern?.data.message ?? ""}
                        variant="outlined"
                        onChange={
                            event => {
                                const newPropertyPatternItem = _.cloneDeep(propertyPatternItem)!;
                                newPropertyPatternItem.propertyPattern.data.message =
                                    _.isEmpty(event.target.value)
                                        ? undefined
                                        : event.target.value;
                                onPropertyPatternItemChanged(newPropertyPatternItem);

                            }}/>
                )}
            </Grid2>
            <Grid2 sx={{ padding: theme.spacing(2, 1, 0) }}>
                {!_.isNil(onDelete) &&
                    !inherited &&
                    !readOnly && (
                    <IconButton
                        size="large"
                        sx={{ alignSelf: "center" }}
                        onClick={() => onDelete!()}>
                        <DeleteIcon/>
                    </IconButton>)}
            </Grid2>
        </Grid2>);
}