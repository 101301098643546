import { DataTable, DataTableColumn, StringHelper, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, tenantModelStore } from "../../../../../../../../common";
import { useDefinition } from "./hooks";

type IdentityProviderTenantTableProps = {
    tenantType: Contract.TenantType;
};

export function IdentityProviderTenantTable({ tenantType }: IdentityProviderTenantTableProps) {
    const activeTenantModels = tenantModelStore.useGetActiveTenants([tenantType]);
    const orderedActiveTenantModels =
        useMemo(
            () =>
                _.orderBy(
                    activeTenantModels,
                    [
                        activeTenantModel => StringHelper.getSortValue(activeTenantModel.configuration.name),
                        activeTenantModel => activeTenantModel.configuration.id
                    ],
                    [
                        "asc",
                        "asc"
                    ]),
            [activeTenantModels]);

    const definition = useDefinition(tenantType);

    const localization =
        useLocalization(
            "views.customer.configuration.permissionManagement.identityProviderTenantTable",
            () => ({
                columns: {
                    name: "Name"
                }
            }));

    return (
        <DataTable
            fetchItems={() => orderedActiveTenantModels}
            getItemId={(item: Contract.TenantModel) => item.configuration.id}
            sortOptions={{ enabled: false }}
            variant="card">
            <DataTableColumn
                id={TableColumnId.Name}
                itemProperty={(item: Contract.TenantModel) => item.configuration.name}
                title={localization.columns.name()}/>
            {definition.additionalColumns}
        </DataTable>);
}

export enum TableColumnId {
    AadPermissions = "aadPermissions",
    AadRawId = "aadRawId",
    GciRawId = "gciRawId",
    Name = "name",
    OktaUrl = "oktaUrl",
    OneLoginUrl = "oneLoginUrl",
    Permissions = "permissions",
    PingIdentityRawId = "pingIdentityRawId"
}