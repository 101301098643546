import { Contract } from "../../../../../../../../../../common";
import { useAzureCommonResourceGroupResourceInfoItemElements } from "./useAzureCommonResourceGroupResourceInfoItemElements";
import { useAzureCommonResourceInfoItemElements } from "./useAzureCommonResourceInfoItemElements";

export function useAzureDefaultResourceInfoItemElements(resourceModel: Contract.AzureResourceModel) {
    const commonResourceInfoElements = useAzureCommonResourceInfoItemElements(resourceModel);
    const { resourceGroupInfoItemElement } = useAzureCommonResourceGroupResourceInfoItemElements(resourceModel as Contract.AzureResourceGroupResourceModel);
    return [
        commonResourceInfoElements.typeNameInfoElement,
        commonResourceInfoElements.aadTenantInfoItemElement,
        resourceModel.typeName === Contract.TypeNames.AzureTenantEntityModel
            ? commonResourceInfoElements.tenantIdInfoItemElement
            : commonResourceInfoElements.tenantInfoItemElement,
        commonResourceInfoElements.regionInfoItemElement,
        commonResourceInfoElements.regionLocationInfoItemElement,
        commonResourceInfoElements.tagsInfoItemElement,
        commonResourceInfoElements.creationTimeInfoItemElement,
        commonResourceInfoElements.createdByInfoItemElement,
        resourceGroupInfoItemElement,
        commonResourceInfoElements.syncTimeInfoItemElement
    ];
}