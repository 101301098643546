import { useMemo } from "react";
import { useGciDefaultEntityDefinition, useGciDirectoryDirectoryRoleDefinition, useGciDirectoryGroupDefinition, useGciDirectoryPartialGroupDefinition, useGciDirectoryPartialUserDefinition, useGciDirectoryUserDefinition, useGciTenantEntityDefinition } from ".";
import { DefinitionOptions } from "../../..";
import { Contract } from "../../../../../../../../../../common";

export function useGciEntityDefinition(entityModel: Contract.EntityModel, options?: DefinitionOptions) {
    const useDefinition =
        useMemo(
            () => {
                switch (entityModel.entity.typeName) {
                    case Contract.TypeNames.GciDirectoryDirectoryRole:
                        return useGciDirectoryDirectoryRoleDefinition;
                    case Contract.TypeNames.GciDirectoryGroup:
                        return useGciDirectoryGroupDefinition;
                    case Contract.TypeNames.GciDirectoryPartialGroup:
                        return useGciDirectoryPartialGroupDefinition;
                    case Contract.TypeNames.GciDirectoryPartialUser:
                        return useGciDirectoryPartialUserDefinition;
                    case Contract.TypeNames.GciDirectoryUser:
                        return useGciDirectoryUserDefinition;
                    case Contract.TypeNames.GciTenantEntity:
                        return useGciTenantEntityDefinition;
                    default:
                        return useGciDefaultEntityDefinition;
                }
            },
            []);

    return useDefinition(entityModel as Contract.GciEntityModel, options);
}