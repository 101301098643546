import { IconNodeContent, IconOutline, NodeContentContextMenuItem, useDirectedGraphNodeContext, useLocalization, useSetRoute } from "@infrastructure";
import { Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Icon } from ".";
import { CustomerConsoleAppUrlHelper, Entity, entityModelStore, EntityNodeContentProps, getEntityNodeContentSize, useEntityTypeNameTranslator, useNodeContentFilterContextMenuItems, useTheme } from "../../..";

type SingleProps =
    Omit<EntityNodeContentProps, "entityIds" | "entityTypeName"> & {
        entityId: string;
    };

export function Single({ contextMenuItems = [], entityId, entityProfileMenuItem, getTooltip, onFilter, textOptions, titlePosition, variant }: SingleProps) {
    const entityModel = entityModelStore.useGet(entityId);
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const { modelNode } = useDirectedGraphNodeContext();
    const localization =
        useLocalization(
            "common.entityNodeContent.single",
            () => ({
                menu: {
                    entityProfile: "Go to {{translatedEntityTypeName}} profile page"
                }
            }));
    const filterContextMenuItems =
        useNodeContentFilterContextMenuItems(
            1,
            <Entity
                entityIdOrModel={entityModel}
                variant="text"/>,
            onFilter);

    const setRoute = useSetRoute();
    const theme = useTheme();
    const url = CustomerConsoleAppUrlHelper.getEntityProfileRelativeUrl(entityModel);
    return (
        <IconNodeContent
            contextMenuItems={
                _(contextMenuItems).
                    concatIf(
                        !_.isNil(url) && entityProfileMenuItem,
                        () =>
                            new NodeContentContextMenuItem(
                                () => setRoute(url!),
                                localization.menu.entityProfile({
                                    translatedEntityTypeName:
                                        entityTypeNameTranslator(
                                            entityModel.entity.typeName,
                                            {
                                                includeServiceName: false,
                                                variant: "text"
                                            })
                                }))).
                    concat(filterContextMenuItems).
                    value()}
            iconOptions={{
                icon:
                    <IconOutline>
                        <Icon
                            entityIds={[entityId]}
                            entityTypeName={entityModel.entity.typeName}/>
                    </IconOutline>,
                shape: "circle",
                size: "large"
            }}
            size={getEntityNodeContentSize(titlePosition ?? "bottom", variant)}
            titleElementOrOptions={
                <Typography
                    align={
                        titlePosition === "right"
                            ? "inherit"
                            : "center"}
                    sx={{
                        color: "unset",
                        fontSize: "unset",
                        fontWeight: "unset"
                    }}>
                    <Entity
                        entityIdOrModel={entityModel}
                        linkOptions={{ disabled: true }}
                        sxOptions={{
                            title: {
                                color:
                                    modelNode.options.appearance === "highlighted"
                                        ? textOptions?.highlightColor ?? theme.palette.text.primary
                                        : textOptions?.normalColor ?? theme.palette.text.secondary
                            }
                        }}
                        tenantNameTranslatorOptions={{ includeRawId: false }}
                        variant="textTypeTenant"/>
                </Typography>}
            titlePosition={titlePosition}
            tooltip={getTooltip?.(entityModel)}/>);
}