import { Dialog, makeContextProvider } from "@infrastructure";
import React from "react";
import { Contract } from "../../../../../../../../../../../../common";
import { AddOrEdit, Table } from "./components";

export class SqsContext {
    constructor(
        public addOrEditOpen: boolean | Contract.ScopeSystemEntityModel) {
    }
}

export const [useSqsContext, useSetSqsContext, useSqsContextProvider] = makeContextProvider<SqsContext>();

export function Sqs() {
    const [context, setContext, ContextProvider] = useSqsContextProvider(() => new SqsContext(false));

    return (
        <ContextProvider>
            {context.addOrEditOpen !== false &&
                <Dialog
                    size="large"
                    variant="editor"
                    onClose={
                        () =>
                            setContext(
                                context => ({
                                    ...context,
                                    addOrEditOpen: false
                                })
                            )}>
                    <AddOrEdit/>
                </Dialog>}
            <Table/>
        </ContextProvider>);
}