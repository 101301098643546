import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useKubernetesAdmissionControllerRiskPolicyEffectTranslator() {
    const localization =
        useLocalization(
            "views.customer.riskPolicies.hooks.useKubernetesAdmissionControllerRiskPolicyEffectTranslator",
            () => ({
                [Contract.TypeNames.KubernetesAdmissionControllerRiskPolicyEffect]: {
                    [Contract.KubernetesAdmissionControllerRiskPolicyEffect.Alert]: "Alert",
                    [Contract.KubernetesAdmissionControllerRiskPolicyEffect.Deny]: "Deny"
                }
            }));

    return (effect: Contract.KubernetesAdmissionControllerRiskPolicyEffect) =>
        localization[Contract.TypeNames.KubernetesAdmissionControllerRiskPolicyEffect][effect]();
}