import { Link, useLocalization, useQueryParameters } from "@infrastructure";
import { Stack, Typography, useTheme } from "@mui/material";
import React from "react";
import { Contract, LogoTextIcon, SignInErrorImage } from "../../../common";

export type SignInErrorCallbackQueryParameters = {
    error?: Contract.AuthenticationControllerTeamsSignInError;
};

export function SignInError() {
    const { error } = useQueryParameters<SignInErrorCallbackQueryParameters>();

    const localization =
        useLocalization(
            "views.teams.signInError",
            () => ({
                support: "contact Tenable Cloud Security",
                title: "You are not authorized to sign in",
                [Contract.TypeNames.AuthenticationControllerTeamsSignInError]: {
                    [Contract.AuthenticationControllerTeamsSignInError.FailureNotEnabled]: "Teams in not enabled in Tenable Cloud Security. Ask your Tenable Cloud Security administrator to enable it via the Tenable Cloud Security Console (Settings > JIT). If you need additional assistance, {{supportLink}}.",
                    [Contract.AuthenticationControllerTeamsSignInError.FailureOrganizationNotExist]: "Your Teams organization hasn't been configured in Tenable Cloud Security. Ask your Tenable Cloud Security administrator to add it via the Tenable Cloud Security Console (Settings > Integrations > Teams). If you need additional assistance, {{supportLink}}.",
                    [Contract.AuthenticationControllerTeamsSignInError.FailureUnauthorized]: "Your user is not defined as an approver or requester in any eligibility. If you think this is a mistake, contact your Tenable Cloud Security administrator. If you need additional assistance, {{supportLink}}.",
                    [Contract.AuthenticationControllerTeamsSignInError.FailureUnauthenticated]: "Your user is not defined as an approver or requester in any eligibility. If you think this is a mistake, contact your Tenable Cloud Security administrator. If you need additional assistance, {{supportLink}}."
                }
            }));

    const theme = useTheme();
    return (
        <Stack
            sx={{
                alignItems: "center",
                background: theme.palette.background.paper,
                flex: 1,
                overflow: "hidden",
                padding: theme.spacing(6),
                position: "relative"
            }}>
            <LogoTextIcon
                sx={{
                    height: "20px",
                    position: "absolute",
                    top: "15vh",
                    width: "214px"
                }}/>

            <Stack
                sx={{
                    alignItems: "center",
                    flex: 1,
                    justifyContent: "center"
                }}>
                <SignInErrorImage/>
                <Typography
                    align="center"
                    sx={{
                        fontSize: "24px",
                        fontWeight: 700,
                        marginTop: theme.spacing(3)
                    }}>
                    {localization.title()}
                </Typography>
                <Typography
                    align="center"
                    sx={{ fontSize: "16px" }}>
                    {localization[Contract.TypeNames.AuthenticationControllerTeamsSignInError][error ?? Contract.AuthenticationControllerTeamsSignInError.FailureUnauthorized]({
                        supportLink:
                            <Link
                                sx={{ fontSize: "16px" }}
                                urlOrGetUrl="mailto:cs.support@tenable.com"
                                variant="text">
                                {localization.support()}
                            </Link>
                    })}
                </Typography>
            </Stack>
        </Stack>);
}