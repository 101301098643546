import _, { Dictionary } from "lodash";
import { TypeHelper } from ".";
import * as Contract from "../controllers/contract";
import { cloudProviderTenantTypes, entitiesTenantTypes, identityProviderTenantTypes, permissionManagementTenantTypes, tenantsTenantTypes } from "./tenantHelperTenantTypes";

export type CommonTenantData = {
    name: string;
    type: Contract.TenantType;
};

export class TenantHelper {
    public static commonTenantIdToDataMap: Dictionary<CommonTenantData> = {
        "aad": {
            name: "Microsoft Entra ID",
            type: Contract.TenantType.Aad
        },
        "aws": {
            name: "AWS",
            type: Contract.TenantType.Aws
        },
        "azure": {
            name: "Azure",
            type: Contract.TenantType.Azure
        },
        "ci": {
            name: "Container Registry",
            type: Contract.TenantType.Ci
        },
        "gci": {
            name: "Google Workspace",
            type: Contract.TenantType.Gci
        },
        "gciPartial": {
            name: "External",
            type: Contract.TenantType.Gci
        },
        "gcp": {
            name: "GCP",
            type: Contract.TenantType.Gcp
        },
        "gcpPartial": {
            name: "External",
            type: Contract.TenantType.Gcp
        },
        "oci": {
            name: "OCI",
            type: Contract.TenantType.Oci
        },
        "okta": {
            name: "Okta",
            type: Contract.TenantType.Okta
        },
        "oneLogin": {
            name: "OneLogin",
            type: Contract.TenantType.OneLogin
        },
        "pingIdentity": {
            name: "PingIdentity",
            type: Contract.TenantType.PingIdentity
        }
    };

    public static get AccessTenantTypes() {
        return [
            Contract.TenantType.Aws,
            Contract.TenantType.Azure,
            Contract.TenantType.Gcp
        ];
    }

    public static get BillableTenantTypes() {
        return [
            ...cloudProviderTenantTypes,
            Contract.TenantType.Ci
        ];
    }

    public static get CloudProviderTenantTypes() {
        return _.concat(cloudProviderTenantTypes);
    }

    public static get CloudRiskPoliciesTenantTypes() {
        return [
            Contract.TenantType.Aad,
            Contract.TenantType.Aws,
            Contract.TenantType.Azure,
            Contract.TenantType.Ci,
            Contract.TenantType.Gci,
            Contract.TenantType.Gcp,
            Contract.TenantType.Oci,
            Contract.TenantType.Okta,
            Contract.TenantType.OneLogin,
            Contract.TenantType.Op,
            Contract.TenantType.PingIdentity
        ];
    }

    public static get CodeTenantTypes() {
        return [
            Contract.TenantType.Code
        ];
    }

    public static get CodeRiskPoliciesTenantTypes() {
        return [
            Contract.TenantType.Code
        ];
    }

    public static get ComplianceTenantTypes() {
        return [
            Contract.TenantType.Aad,
            Contract.TenantType.Aws,
            Contract.TenantType.Azure,
            Contract.TenantType.Ci,
            Contract.TenantType.Gci,
            Contract.TenantType.Gcp,
            Contract.TenantType.Oci,
            Contract.TenantType.Okta,
            Contract.TenantType.OneLogin,
            Contract.TenantType.Op,
            Contract.TenantType.PingIdentity
        ];
    }

    public static get ConfigurationTenantTypes() {
        return [
            Contract.TenantType.Aad,
            Contract.TenantType.Aws,
            Contract.TenantType.Azure,
            Contract.TenantType.Ci,
            Contract.TenantType.Code,
            Contract.TenantType.Gci,
            Contract.TenantType.Gcp,
            Contract.TenantType.Oci,
            Contract.TenantType.Okta,
            Contract.TenantType.OneLogin,
            Contract.TenantType.Op,
            Contract.TenantType.PingIdentity
        ];
    }

    public static get CustomRisksTenantTypes() {
        return [
            Contract.TenantType.Aws,
            Contract.TenantType.Azure,
            Contract.TenantType.Gcp
        ];
    }

    public static get DashboardTenantTypes() {
        return [
            Contract.TenantType.Aad,
            Contract.TenantType.Aws,
            Contract.TenantType.Azure,
            Contract.TenantType.Ci,
            Contract.TenantType.Gci,
            Contract.TenantType.Gcp,
            Contract.TenantType.Oci,
            Contract.TenantType.Okta,
            Contract.TenantType.OneLogin,
            Contract.TenantType.Op,
            Contract.TenantType.PingIdentity
        ];
    }

    public static get DataAnalysisTenantTypes() {
        return [
            Contract.TenantType.Aws,
            Contract.TenantType.Azure,
            Contract.TenantType.Gcp
        ];
    }

    public static get EntitiesTenantTypes() {
        return entitiesTenantTypes;
    }

    public static get EventsTenantTypes() {
        return [
            Contract.TenantType.Aad,
            Contract.TenantType.Aws,
            Contract.TenantType.Azure,
            Contract.TenantType.Gci,
            Contract.TenantType.Gcp,
            Contract.TenantType.Okta,
            Contract.TenantType.OneLogin,
            Contract.TenantType.PingIdentity
        ];
    }

    public static get GenerateEntityNonexcessivePermitterTenantTypes() {
        return [
            Contract.TenantType.Aad,
            Contract.TenantType.Aws,
            Contract.TenantType.Gci,
            Contract.TenantType.Gcp
        ];
    }

    public static get GitTenantCodeTenantTypes() {
        return [
            Contract.CodeTenantType.AzureDevOps,
            Contract.CodeTenantType.Bitbucket,
            Contract.CodeTenantType.GitHub,
            Contract.CodeTenantType.GitLab
        ];
    }

    public static get IdentityProviderTenantTypes() {
        return identityProviderTenantTypes;
    }

    public static get IntegrationTenantTypes() {
        return [
            Contract.TenantType.Aad,
            Contract.TenantType.Aws,
            Contract.TenantType.Azure,
            Contract.TenantType.Ci,
            Contract.TenantType.Code,
            Contract.TenantType.Gci,
            Contract.TenantType.Gcp,
            Contract.TenantType.Oci,
            Contract.TenantType.Okta,
            Contract.TenantType.OneLogin,
            Contract.TenantType.Op,
            Contract.TenantType.PingIdentity
        ];
    }

    public static get KubernetesTenantTypes() {
        return [
            Contract.TenantType.Aws,
            Contract.TenantType.Azure,
            Contract.TenantType.Gcp,
            Contract.TenantType.Op
        ];
    }

    public static get PermissionManagementTenantTypes(): PermissionManagementTenantType[] {
        return _.concat(permissionManagementTenantTypes);
    }

    public static get PropertyTenantTypes() {
        return [
            Contract.TenantType.Aws,
            Contract.TenantType.Azure,
            Contract.TenantType.Gcp,
            Contract.TenantType.Oci
        ];
    }

    public static get ReportsTenantTypes() {
        return [
            Contract.TenantType.Aad,
            Contract.TenantType.Aws,
            Contract.TenantType.Azure,
            Contract.TenantType.Ci,
            Contract.TenantType.Code,
            Contract.TenantType.Gci,
            Contract.TenantType.Gcp,
            Contract.TenantType.Oci,
            Contract.TenantType.Okta,
            Contract.TenantType.OneLogin,
            Contract.TenantType.Op,
            Contract.TenantType.PingIdentity
        ];
    }

    public static get KubernetesAdmissionControllerRiskPoliciesTenantTypes() {
        return [
            Contract.TenantType.Aws,
            Contract.TenantType.Azure,
            Contract.TenantType.Gcp,
            Contract.TenantType.Op
        ];
    }

    public static get RisksTenantTypes() {
        return [
            Contract.TenantType.Aad,
            Contract.TenantType.Aws,
            Contract.TenantType.Azure,
            Contract.TenantType.Ci,
            Contract.TenantType.Gci,
            Contract.TenantType.Gcp,
            Contract.TenantType.Oci,
            Contract.TenantType.Okta,
            Contract.TenantType.OneLogin,
            Contract.TenantType.Op,
            Contract.TenantType.PingIdentity
        ];
    }

    public static get TenantsTenantTypes() {
        return _.concat(tenantsTenantTypes);

    }

    public static get WorkloadAnalysisTenantTypes() {
        return [
            Contract.TenantType.Aws,
            Contract.TenantType.Azure,
            Contract.TenantType.Ci,
            Contract.TenantType.Gcp,
            Contract.TenantType.Op
        ];
    }

    public static hasData(tenantModels: Contract.TenantModel[]) {
        return _.some(
            tenantModels,
            tenantModel =>
                !_.isNil(tenantModel.state.orchestration.orchestrationTypeToDataMap[Contract.OrchestrationType.Analysis]?.previousTimeRange?.endTime) &&
                (TenantHelper.isCodeTenant(tenantModel)
                    ? !_.isNil(tenantModel.state.orchestration.orchestrationTypeToDataMap[Contract.OrchestrationType.CodeAnalysis]?.previousTimeRange?.endTime)
                    : true));
    }

    public static isCloudProviderTenant(tenantModel: Contract.TenantModel) {
        return TypeHelper.extendOrImplement(tenantModel.configuration.typeName, Contract.TypeNames.CloudProviderTenantConfiguration);
    }

    public static isCloudProviderTenantType(tenantType: Contract.TenantType) {
        return _.includes(
            TenantHelper.CloudProviderTenantTypes,
            tenantType);
    }

    public static isCodeTenant(tenantModel: Contract.TenantModel) {
        return TypeHelper.extendOrImplement(tenantModel.configuration.typeName, Contract.TypeNames.CodeTenantConfiguration);
    }

    public static isCommonTenant(id: string) {
        return !_.isNil(TenantHelper.commonTenantIdToDataMap[id]);
    }

    public static isGeneralCodeTenant(tenantConfiguration: Contract.TenantConfiguration) {
        return tenantConfiguration.typeName === Contract.TypeNames.GeneralCodeTenantConfiguration;
    }

    public static isIdentityProviderTenantType(tenantType: Contract.TenantType) {
        return _.includes(
            TenantHelper.IdentityProviderTenantTypes,
            tenantType);
    }

    public static isIdentityProviderTenant(tenantModel: Contract.TenantModel) {
        return TypeHelper.extendOrImplement(tenantModel.configuration.typeName, Contract.TypeNames.IdentityProviderTenantConfiguration);
    }

    public static isPermissionManagementTenantType(tenantType: Contract.TenantType) {
        return _.includes(
            TenantHelper.PermissionManagementTenantTypes,
            tenantType);
    }
}

export type CloudProviderTenantType = typeof cloudProviderTenantTypes[number];

export type PermissionManagementTenantType = typeof permissionManagementTenantTypes[number];

export type TenantsTenantType = typeof tenantsTenantTypes[number];