import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, InlineEntities, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetAwsResourceRiskContext } from "./useGetAwsResourceRiskContext";

export function useGetAwsRdsNeptuneClusterRiskContext() {
    return useMemo(
        () => useAwsRdsNeptuneClusterRiskContext,
        []);
}

function useAwsRdsNeptuneClusterRiskContext(clusterModel: Contract.AwsRdsNeptuneClusterModel) {
    const cluster = clusterModel.entity as Contract.AwsRdsNeptuneCluster;
    const resourceContextItems = useGetAwsResourceRiskContext()(clusterModel);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.contexts.useGetAwsRdsNeptuneClusterRiskContext",
            () => ({
                backupRetentionTimeFrame: "The retention period is currently set to **{{backupRetentionTimeFrame | TimeSpanFormatter.day}}**",
                instances: "The Neptune Cluster has {{instances}}",
                size: "The {{translatedClusterTypeName}}'s size is {{storageSize | NumberFormatter.storage}}"
            }));
    const translatedClusterTypeName =
        entityTypeNameTranslator(
            cluster.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });

    return {
        ...resourceContextItems,
        backupRetentionTimeFrame:
            new RiskDefinitionContextItem(localization.backupRetentionTimeFrame({ backupRetentionTimeFrame: cluster.backupRetentionTimeFrame })),
        instances:
            _.isEmpty(clusterModel.instanceIds)
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.instances({
                        instances:
                            <InlineEntities
                                entityIdsOrModels={clusterModel.instanceIds}
                                entityTypeName={Contract.TypeNames.AwsRdsNeptuneDatabaseInstance}
                                variant="itemCountAndType"/>
                    })),
        size:
            new RiskDefinitionContextItem(
                localization.size({
                    storageSize: cluster.storageSize,
                    translatedClusterTypeName
                }))
    };
}