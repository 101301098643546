import { useLocalization } from "@infrastructure";
import { Contract } from "..";

export function useDataAnalysisStatusTranslator() {
    const localization =
        useLocalization(
            "common.hooks.useDataAnalysisStatusTranslator",
            () => ({
                [Contract.TypeNames.DataAnalysisResourceModelDataAnalysisStatus]: {
                    [Contract.DataAnalysisResourceModelDataAnalysisStatus.Disabled]: "Disabled",
                    [Contract.DataAnalysisResourceModelDataAnalysisStatus.Failed]: "Failed",
                    [Contract.DataAnalysisResourceModelDataAnalysisStatus.Pending]: "Pending",
                    [Contract.DataAnalysisResourceModelDataAnalysisStatus.Scanned]: "Scanned"
                }
            }));

    return (dataAnalysisResourceModelDataAnalysisStatus: Contract.DataAnalysisResourceModelDataAnalysisStatus) =>
        localization[Contract.TypeNames.DataAnalysisResourceModelDataAnalysisStatus][dataAnalysisResourceModelDataAnalysisStatus]();
}