import { useLocalization } from "@infrastructure";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition } from "../../../..";
import { Contract } from "../../../../../../../../../../../common";
import { EntitiesInfoItem, Info } from "../../../../../components";
import { useCustomEntityPropertyInfoItemElements } from "../../useCustomEntityPropertyInfoItemElements";
import { useAzureDefaultResourceInfoItemElements } from "../useAzureDefaultResourceInfoItemElements";
import { RoutesInfoCard } from "./components";

export function useAzureNetworkRouteTableDefinition(resourceGroupResourceModel: Contract.AzureResourceGroupResourceModel, options?: DefinitionOptions) {
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceGroupResourceModel);
    const defaultResourceInfoItemElements = useAzureDefaultResourceInfoItemElements(resourceGroupResourceModel);
    const routeTable = resourceGroupResourceModel.entity as Contract.AzureNetworkRouteTable;
    const routeTableModel = resourceGroupResourceModel as Contract.AzureNetworkRouteTableModel;

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.azure.useAzureNetworkRouteTableDefinition",
            () => ({
                info: {
                    items: {
                        subnetIds: "Subnets"
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <EntitiesInfoItem
                        entityIdsOrModels={routeTableModel.subnetIds}
                        entityTypeName={Contract.TypeNames.AzureNetworkVirtualNetworkSubnet}
                        key="subnetIds"
                        title={localization.info.items.subnetIds()}/>
                ]}
                options={options?.infoOptions}>
                <RoutesInfoCard routes={routeTable.routes}/>
            </Info>
    });
}