import _ from "lodash";
import { useCallback } from "react";
import { Contract, ScopeHelper, scopeSystemEntityModelStore } from "../../../../../../../common";

export function useGetCloudProviderTenantOrganizationByScopeNodeModel() {
    const cloudProviderTenantOrganizations = scopeSystemEntityModelStore.useGetCloudProviderTenantOrganization();
    return useCallback(
        (scopeNodeModel: Contract.ScopeNodeModel) => {
            const organizationId =
                ScopeHelper.isFolder(scopeNodeModel)
                    ? (scopeNodeModel.configuration as Contract.CloudProviderTenantFolderConfiguration).organization?.id
                    : (scopeNodeModel.configuration as Contract.CloudProviderTenantConfiguration).organizationId;
            if (!_.isNil(organizationId)) {
                return _.find(
                    cloudProviderTenantOrganizations,
                    cloudProviderTenantOrganizationModel => cloudProviderTenantOrganizationModel.id == organizationId);
            }
        },
        [cloudProviderTenantOrganizations]);
}