import { DataTableColumnRenderProps, useLocalizeList } from "@infrastructure";
import { Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract, IntRangeHelper, NetworkScopeHelper } from "../../../../../common";

export function RulePortCell({ item: portRanges }: DataTableColumnRenderProps<Contract.IntRange[]>) {
    const localizeList = useLocalizeList();
    return (
        <Typography noWrap={true}>
            {NetworkScopeHelper.isAll(portRanges[0])
                ? "Any"
                : localizeList(
                    _.map(
                        portRanges,
                        IntRangeHelper.format))}
        </Typography>);
}