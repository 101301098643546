import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function RedHatIcon(props: SvgIconProps) {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <path
                d="M15.9752 13.4213C17.5353 13.4213 19.7943 13.0987 19.7943 11.2502C19.7942 11.1087 19.7817 10.9676 19.7568 10.8283L18.8333 6.8087C18.6211 5.92785 18.4339 5.51844 16.8738 4.74925C15.6632 4.12893 13.0298 3.12402 12.2436 3.12402C11.5072 3.12402 11.295 4.0545 10.4339 4.0545C9.5977 4.0545 8.98615 3.35974 8.19988 3.35974C7.45105 3.35974 6.96431 3.8684 6.5899 4.91053C6.5899 4.91053 5.54154 7.85083 5.40425 8.28505C5.38363 8.36185 5.37521 8.44138 5.37929 8.52077C5.36681 9.66215 9.89723 13.4089 15.9752 13.4213ZM20.0314 12.0069C20.2436 13.0243 20.2436 13.1359 20.2436 13.26C20.2436 14.9969 18.2841 15.9646 15.7007 15.9646C9.85979 15.9646 4.75526 12.5652 4.75526 10.3197C4.75469 10.0082 4.81841 9.7 4.94247 9.41402C2.84575 9.51327 0.125 9.88546 0.125 12.2675C0.125 16.1755 9.43545 20.9891 16.7989 20.9891C22.4526 20.9891 23.8754 18.4458 23.8754 16.436C23.8754 14.8604 22.5025 13.0739 20.0314 12.0069Z"
                fill="#EE0000"/>
            <path
                d="M20.031 12.007C20.2432 13.0244 20.2432 13.136 20.2432 13.2601C20.2432 14.997 18.2837 15.9647 15.7003 15.9647C9.85941 15.9647 4.75489 12.5653 4.75489 10.3198C4.75431 10.0084 4.81803 9.70011 4.94209 9.41413L5.40387 8.28516C5.38325 8.36196 5.37483 8.44149 5.37891 8.52088C5.37891 9.66226 9.90933 13.409 15.9749 13.409C17.5349 13.409 19.7939 13.0864 19.7939 11.2379C19.7938 11.0964 19.7813 10.9553 19.7564 10.816L20.031 12.007Z"
                fill="black"/>
        </SvgIcon>);
}