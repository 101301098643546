import { Stack } from "@mui/material";
import React from "react";
import { Actions } from "./Actions";

type ActionsCellProps = {
    contentSha256String: string;
    tenantId?: string;
};

export function ActionsCell({ contentSha256String, tenantId }: ActionsCellProps) {
    return (
        <Stack
            direction="row"
            justifyContent="flex-end"
            sx={{
                backgroundColor: "inherit",
                overflow: "hidden"
            }}>
            <Actions
                contentSha256String={contentSha256String}
                tenantId={tenantId}/>
        </Stack>);
}