﻿import { Link, Step, Steps, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition, useGetAwsCloudFrontDistributionRiskContext } from "../../..";
import { Contract, Entity, entityModelStore, useTheme } from "../../../../../../../../../../../../common";
import { AwsCloudFrontDistributionOriginsTable, AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { RiskDefinitionSection } from "../../../../../../utilities";

export function useAwsCloudFrontDistributionOriginHttpsOnlyDisabledRiskDefinition(riskModel: Contract.RiskModel) {
    const distributionOriginHttpsOnlyDisabledRiskModel = riskModel as Contract.AwsCloudFrontDistributionOriginHttpsOnlyDisabledRiskModel;
    const distributionModel = entityModelStore.useGet(distributionOriginHttpsOnlyDisabledRiskModel.risk.entityId) as Contract.AwsCloudFrontDistributionModel;
    const distribution = distributionModel.entity as Contract.AwsCloudFrontDistribution;

    const getAwsCloudFrontDistributionRiskContext = useGetAwsCloudFrontDistributionRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsCloudFrontDistributionOriginHttpsOnlyDisabledRiskDefinition",
            () => ({
                description: "{{distribution}} is not enforcing transport encryption to origins",
                sections: {
                    origins: "Origins",
                    resolution: {
                        step1: "Navigate to **Origins** tab",
                        step2: {
                            step1: "Select the origin, and then click **Edit**",
                            step2: "Navigate to **Protocol** and select one of the following values: **HTTPS only** or **Match viewer**. Only choose **Match Viewer** if you selected **Redirect HTTP to HTTPS** or **HTTPS Only** for Viewer Protocol Policy in the behavior configuration (which follows Tenable best-practice recommendations)",
                            step3: "For the **Minimum origin SSL protocol**, select **TLSv1.2**",
                            step4: "Click **Save changes**",
                            title: "Repeat the following steps for each of the origins"
                        },
                        step3: {
                            link: "Installing an SSL/TLS certificate on your custom origin",
                            text: "Make sure you have a certificate configured on your origin server. Refer to the relevant AWS documentation about {{installCertificateGuideLink}} for more information"
                        }
                    }
                }
            }));

    const theme = useTheme();
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            distribution:
                <Entity
                    entityIdOrModel={distributionModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.CloudFront,
                AwsConsoleUrlBuilder.getCloudFrontDistributionUrl(distribution)),
            localization.sections.resolution.step1(),
            new Step(
                localization.sections.resolution.step2.title(),
                {
                    contentElement:
                        <Steps variant="plainNumbers">
                            {localization.sections.resolution.step2.step1()}
                            {localization.sections.resolution.step2.step2()}
                            {localization.sections.resolution.step2.step3()}
                            {localization.sections.resolution.step2.step4()}
                        </Steps>
                }),
            localization.sections.resolution.step3.text({
                installCertificateGuideLink:
                    <Link
                        urlOrGetUrl={distributionOriginHttpsOnlyDisabledRiskModel.installCertificateDocumentationUrl}
                        variant="external">
                        {localization.sections.resolution.step3.link()}
                    </Link>
            })
        ],
        distributionOriginHttpsOnlyDisabledRiskModel,
        () => {
            const distributionRiskContext = getAwsCloudFrontDistributionRiskContext(distributionModel);
            return [
                distributionRiskContext.generalInformation,
                distributionRiskContext.status,
                distributionRiskContext.originResources,
                distributionRiskContext.sensitive,
                distributionRiskContext.sensitiveOrigin,
                distributionRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        },
        {
            sections: [
                new RiskDefinitionSection(
                    <AwsCloudFrontDistributionOriginsTable
                        getHighlightColor={
                            (origin: Contract.AwsCloudFrontDistributionOrigin, opacity) =>
                                _.includes(distributionOriginHttpsOnlyDisabledRiskModel.risk.riskedOriginIds, origin.id)
                                    ? opacity
                                        ? theme.palette.opacity(theme.palette.severity(riskModel.risk.severity), opacity)
                                        : theme.palette.severity(riskModel.risk.severity)
                                    : undefined
                        }
                        originIdToResourceIdReferenceMap={distributionModel.originIdToResourceIdReferenceMap}
                        origins={distribution.origins}/>,
                    localization.sections.origins(),
                    {
                        expandable: true
                    })
            ]
        });
}