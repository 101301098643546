import { Optional } from "@infrastructure";
import _ from "lodash";
import { useMemo } from "react";
import { Contract, tenantModelStore, TypeHelper } from "..";

export function useTenantConfigurationTypeNameRenderer() {
    const activeTenantModels = tenantModelStore.useGetActiveTenants();
    return useMemo(
        () => {
            const configuredTenantConfigurationTypeNames =
                _(activeTenantModels).
                    map(activeTenantModel => activeTenantModel.configuration.typeName).
                    uniq().
                    value();
            return function <TValue>(renderTenantConfigurationTypeNames: Contract.TypeNames[], renderTenantConfigurationTypeName: (configuredRenderTenantConfigurationTypeNames: Contract.TypeNames[]) => TValue, defaultValue?: TValue): Optional<TValue> {
                const configuredRenderTenantConfigurationTypeNames =
                    _(renderTenantConfigurationTypeNames).
                        filter(
                            renderTenantConfigurationTypeName =>
                                _.some(
                                    configuredTenantConfigurationTypeNames,
                                    configuredTenantConfigurationTypeName =>
                                        configuredTenantConfigurationTypeName == renderTenantConfigurationTypeName ||
                                        TypeHelper.extendOrImplement(
                                            configuredTenantConfigurationTypeName,
                                            renderTenantConfigurationTypeName))).
                        value();
                return _.isEmpty(configuredRenderTenantConfigurationTypeNames)
                    ? defaultValue
                    : renderTenantConfigurationTypeName(configuredRenderTenantConfigurationTypeNames);
            };
        },
        [activeTenantModels]);
}

export function useTenantTypeRenderer() {
    const activeTenantModels = tenantModelStore.useGetActiveTenants();
    return useMemo(
        () => {
            const configuredTenantTypes =
                _(activeTenantModels).
                    map(activeTenantModel => activeTenantModel.tenantType).
                    uniq().
                    value();
            return function <TValue>(renderTenantTypes: Contract.TenantType[], renderTenantType: (configuredRenderTenantTypes: Contract.TenantType[]) => TValue, defaultValue?: TValue): Optional<TValue> {
                const configuredRenderTenantTypes = _.intersection(renderTenantTypes, configuredTenantTypes);
                return _.isEmpty(configuredRenderTenantTypes)
                    ? defaultValue
                    : renderTenantType(configuredRenderTenantTypes);
            };
        },
        [activeTenantModels]);
}