import { SvgIconProps } from "@mui/material";
import React from "react";
import { Contract } from "../../controllers";
import { AadFolderIcon, AwsFolderIcon, AzureFolderIcon, CiFolderIcon, CodeFolderIcon, FolderIcon as DefaultFolderIcon, GciFolderIcon, GcpFolderIcon, OciFolderIcon, OktaFolderIcon, OneLoginFolderIcon, OpFolderIcon, PingIdentityFolderIcon } from "./icons";

type FolderIconProps = SvgIconProps & {
    tenantType?: Contract.TenantType;
};

export function FolderIcon({ tenantType, ...props }: FolderIconProps) {
    switch(tenantType) {
        case Contract.TenantType.Aad:
            return <AadFolderIcon {...props}/>;
        case Contract.TenantType.Aws:
            return <AwsFolderIcon {...props}/>;
        case Contract.TenantType.Azure:
            return <AzureFolderIcon {...props}/>;
        case Contract.TenantType.Ci:
            return <CiFolderIcon {...props}/>;
        case Contract.TenantType.Code:
            return <CodeFolderIcon {...props}/>;
        case Contract.TenantType.Gci:
            return <GciFolderIcon {...props}/>;
        case Contract.TenantType.Gcp:
            return <GcpFolderIcon {...props}/>;
        case Contract.TenantType.Oci:
            return <OciFolderIcon {...props}/>;
        case Contract.TenantType.Okta:
            return <OktaFolderIcon {...props}/>;
        case Contract.TenantType.OneLogin:
            return <OneLoginFolderIcon {...props}/>;
        case Contract.TenantType.Op:
            return <OpFolderIcon {...props}/>;
        case Contract.TenantType.PingIdentity:
            return <PingIdentityFolderIcon {...props}/>;
        case Contract.TenantType.Unknown:
        default:
            return <DefaultFolderIcon {...props}/>;
    } ;
}