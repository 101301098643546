import _, { Dictionary } from "lodash";
import React from "react";
import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, EnumValuesFilter, NumberFormatter, optionalTableCell, PagedValuesFilter, TimeSpanFormatter, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import { useAwsCommonCloudWatchLoggingResourceDefinition, useAwsCommonEncryptedResourceDefinition, useAwsCommonResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions, useEntityTypeNameTranslator } from "../../../../../../../../../../common";
import { useAwsDocDbClusterModelTypeTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";

export function useAwsDocDbClusterDefinition(definitionData: DefinitionData) {
    const commonCloudWatchLoggingResourceDefinition = useAwsCommonCloudWatchLoggingResourceDefinition(definitionData);
    const commonEncryptedResourceDefinition = useAwsCommonEncryptedResourceDefinition(definitionData);
    const commonResourceDefinition = useAwsCommonResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const docDbClusterModelTypeTranslator = useAwsDocDbClusterModelTypeTranslator();
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.aws.useAwsDocDbClusterDefinition",
            () => ({
                columns: {
                    backupRetentionTimeFrame: "Automatic Backup Retention Period",
                    clusterType: "Cluster Type",
                    deleteProtection: {
                        disabled: "Disabled",
                        enabled: "Enabled",
                        title: "Delete Protection"
                    },
                    engineVersion: "Engine Version",
                    status: "Status",
                    storageSize: "Size"
                }
            }));

    const translatedDocDbClusterSnapshotTypeName =
        entityTypeNameTranslator(
            Contract.TypeNames.IAwsDocDbClusterSnapshot,
            {
                count: 0,
                includeServiceName: true,
                variant: "title"
            });
    const translatedDocDbGlobalClusterTypeName =
        entityTypeNameTranslator(
            Contract.TypeNames.AwsRdsDocDbGlobalCluster,
            {
                includeServiceName: true,
                variant: "title"
            });
    const translatedDocDbDatabaseInstanceTypeName =
        entityTypeNameTranslator(
            Contract.TypeNames.AwsRdsDocDbDatabaseInstance,
            {
                includeServiceName: true,
                variant: "title"
            });

    return new EntityTableDefinition(
        [
            commonResourceDefinition.columns.tenantColumn,
            commonResourceDefinition.columns.creationTimeColumn,
            commonResourceDefinition.columns.creatorIdentityCsvColumn,
            commonResourceDefinition.columns.creatorOriginatorEntityCsvColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.IAwsDocDbClusterModel) => ({
                            [localization.columns.clusterType()]: docDbClusterModelTypeTranslator(item.type)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.AwsDocDbClusterModelType}
                                enumTypeTranslator={docDbClusterModelTypeTranslator}
                                placeholder={localization.columns.clusterType()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AwsDocDbClusterType}
                itemProperty={(item: Contract.IAwsDocDbClusterModel) => docDbClusterModelTypeTranslator(item.type)}
                key={Contract.EntityModelProperty.AwsDocDbClusterType}
                title={localization.columns.clusterType()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.IAwsDocDbClusterModel) => ({
                            [localization.columns.status()]: _.as<Contract.IAwsDocDbCluster>(item.entity).status
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedValuesFilter
                                getValuePage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AwsDocDbClusterStatus)}
                                placeholder={localization.columns.status()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AwsDocDbClusterStatus}
                itemProperty={(item: Contract.IAwsDocDbClusterModel) => (item.entity as Contract.IEntity as Contract.IAwsDocDbCluster).status}
                key={Contract.EntityModelProperty.AwsDocDbClusterStatus}
                title={localization.columns.status()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.EntityModel) => ({
                            [localization.columns.engineVersion()]: _.as<Contract.IAwsDocDbCluster>(item.entity).engineVersion
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedValuesFilter
                                getValuePage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AwsDocDbClusterEngineVersion)}
                                placeholder={localization.columns.engineVersion()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AwsDocDbClusterEngineVersion}
                key={Contract.EntityModelProperty.AwsDocDbClusterEngineVersion}
                render={optionalTableCell<Contract.IAwsDocDbClusterModel>(item => (item.entity as Contract.IEntity as Contract.IAwsDocDbCluster).engineVersion)}
                selectorOptions={{ default: false }}
                title={localization.columns.engineVersion()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.EntityModel) => ({
                            [localization.columns.storageSize()]: NumberFormatter.storage(_.as<Contract.IAwsDocDbCluster>(item.entity).storageSize)
                        })
                }}
                id={Contract.EntityModelProperty.AwsDocDbClusterStorageSize}
                itemProperty={(item: Contract.AwsResourceModel) => NumberFormatter.storage(_.as<Contract.IAwsDocDbCluster>(item.entity).storageSize)}
                key={Contract.EntityModelProperty.AwsDocDbClusterStorageSize}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.storageSize()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.IAwsDocDbClusterModel>(
                        Contract.TypeNames.AwsRdsDocDbDatabaseInstance,
                        item => item.instanceIds ?? [],
                        translatedDocDbDatabaseInstanceTypeName)}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AwsDocDbClusterInstances)}
                                placeholder={translatedDocDbDatabaseInstanceTypeName}/>
                    }
                }}
                id={Contract.EntityModelProperty.AwsDocDbClusterInstances}
                key={Contract.EntityModelProperty.AwsDocDbClusterInstances}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.IAwsDocDbClusterModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.instanceIds}
                            entityTypeName={Contract.TypeNames.AwsRdsDocDbDatabaseInstance}
                            entityVariant="iconText"/>}
                selectorOptions={{ default: false }}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={translatedDocDbDatabaseInstanceTypeName}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.IAwsDocDbClusterModel>(
                        Contract.TypeNames.IAwsDocDbClusterSnapshot,
                        item => item.snapshotIds,
                        translatedDocDbClusterSnapshotTypeName)}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AwsDocDbClusterSnapshots)}
                                placeholder={translatedDocDbClusterSnapshotTypeName}/>
                    }
                }}
                id={Contract.EntityModelProperty.AwsDocDbClusterSnapshots}
                key={Contract.EntityModelProperty.AwsDocDbClusterSnapshots}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.IAwsDocDbClusterModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.snapshotIds}
                            entityTypeName={Contract.TypeNames.IAwsDocDbClusterSnapshot}
                            entityVariant="iconText"/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={translatedDocDbClusterSnapshotTypeName}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.IAwsDocDbClusterModel>(
                        Contract.TypeNames.AwsRdsDocDbGlobalCluster,
                        item => [_.as<Contract.IAwsDocDbCluster>(item.entity).globalClusterId],
                        translatedDocDbGlobalClusterTypeName)}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AwsDocDbClusterGlobalCluster)}
                                placeholder={translatedDocDbGlobalClusterTypeName}/>
                    }
                }}
                id={Contract.EntityModelProperty.AwsDocDbClusterGlobalCluster}
                key={Contract.EntityModelProperty.AwsDocDbClusterGlobalCluster}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.IAwsDocDbClusterModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={(item.entity as Contract.IEntity as Contract.IAwsDocDbCluster).globalClusterId}
                            entityTypeName={Contract.TypeNames.AwsRdsDocDbGlobalCluster}
                            entityVariant="iconText"/>}
                title={translatedDocDbGlobalClusterTypeName}/>,
            commonEncryptedResourceDefinition.columns.encryptionExistsColumn,
            commonEncryptedResourceDefinition.columns.kmsEncryptionKeyColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.IAwsDocDbClusterModel) => ({
                            [localization.columns.backupRetentionTimeFrame()]: TimeSpanFormatter.day(_.as<Contract.IAwsDocDbCluster>(item.entity).backupRetentionTimeFrame)
                        })
                }}
                id={Contract.EntityModelProperty.AwsDocDbClusterBackupRetentionTimeFrame}
                itemProperty={(item: Contract.IAwsDocDbClusterModel) => TimeSpanFormatter.day(_.as<Contract.IAwsDocDbCluster>(item.entity).backupRetentionTimeFrame)}
                key={Contract.EntityModelProperty.AwsDocDbClusterBackupRetentionTimeFrame}
                selectorOptions={{ default: false }}
                sortOptions={{
                    type: DataTableSortType.Numeric
                }}
                title={localization.columns.backupRetentionTimeFrame()}/>,
            commonCloudWatchLoggingResourceDefinition.columns.cloudWatchLoggingEnabledColumn,
            commonCloudWatchLoggingResourceDefinition.columns.cloudWatchLogTypesColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.IAwsDocDbClusterModel) => ({
                            [localization.columns.deleteProtection.title()]:
                                (item.entity as Contract.IEntity as Contract.IAwsDocDbCluster).deleteProtection
                                    ? localization.columns.deleteProtection.enabled()
                                    : localization.columns.deleteProtection.disabled()
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        default: true,
                        element:
                            <ValuesFilter
                                emptyValueOptions={{
                                    enabled: true,
                                    variant: "iconText"
                                }}
                                placeholder={localization.columns.deleteProtection.title()}>
                                <ValuesFilterItem
                                    key="false"
                                    title={localization.columns.deleteProtection.disabled()}
                                    value={false}/>
                                <ValuesFilterItem
                                    key="true"
                                    title={localization.columns.deleteProtection.enabled()}
                                    value={true}/>
                            </ValuesFilter>
                    }
                }}
                id={Contract.EntityModelProperty.AwsDocDbClusterDeleteProtection}
                key={Contract.EntityModelProperty.AwsDocDbClusterDeleteProtection}
                render={
                    optionalTableCell<Contract.IAwsDocDbClusterModel>(
                        item =>
                            _.isNil(_.as<Contract.IAwsDocDbCluster>(item.entity).deleteProtection)
                                ? undefined
                                : _.as<Contract.IAwsDocDbCluster>(item.entity).deleteProtection
                                    ? localization.columns.deleteProtection.enabled()
                                    : localization.columns.deleteProtection.disabled())}
                selectorOptions={{ default: false }}
                title={localization.columns.deleteProtection.title()}/>,
            commonResourceDefinition.columns.tagsColumn,
            commonResourceDefinition.columns.attributesColumn,
            commonResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAwsDocDbClusterRequest(
                new Contract.EntityControllerGetEntityModelPageAwsDocDbClusterRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsDocDbClusterType]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsDocDbClusterDeleteProtection]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsDocDbClusterEngineVersion]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsDocDbClusterGlobalCluster]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsDocDbClusterInstances]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsDocDbClusterSnapshots]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsDocDbClusterStatus])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags])),
                new Contract.EntityControllerGetEntityModelPageAwsResourceRequestCloudWatchLogging(
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsCloudWatchResourceCloudWatchLoggingEnabled]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsCloudWatchResourceCloudWatchLogTypes])),
                new Contract.EntityControllerGetEntityModelPageAwsResourceRequestEncrypted(
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEncryptedResourceEncryptionExists]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEncryptedResourceKmsEncryptionKeys]))));
}