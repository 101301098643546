import { map } from "@infrastructure";
import { Typography, useTheme } from "@mui/material";
import _ from "lodash";
import React, { Fragment, ReactNode } from "react";
import { NodeContentContextMenu, NodeContentContextMenuItem, Tooltip } from ".";
import { DirectedGraphModelNodeContentSize, useDirectedGraphNodeContext } from "./DirectedGraph";

export type IconNodeContentProps = {
    contextMenuItems?: NodeContentContextMenuItem[];
    iconOptions: IconNodeContentIconOptions;
    size: DirectedGraphModelNodeContentSize;
    titleElementOrOptions?: ReactNode | IconNodeContentTextOptions;
    titlePosition?: "bottom" | "right";
    tooltip?: string;
};

type IconNodeContentIconOptions = {
    highlightColor?: string;
    icon: ReactNode;
    normalColor?: string;
    shape?: "circle" | "circleGroup" | "rectangle";
    size?: "large" | "medium" | number;
};

export type IconNodeContentTextOptions = {
    highlightColor?: string;
    normalColor?: string;
    subtitle?: ReactNode;
    subtitleSize?: string;
    title: ReactNode;
};

export function IconNodeContent({ contextMenuItems, iconOptions, size, titleElementOrOptions, titlePosition, tooltip }: IconNodeContentProps) {
    const { modelNode } = useDirectedGraphNodeContext();
    const theme = useTheme();

    const iconShapeSize = 50;
    const iconSize =
        _.isNumber(iconOptions?.size)
            ? iconOptions?.size
            : iconOptions?.size === "large"
                ? iconShapeSize * 0.8
                : iconShapeSize * 0.64;
    const { height, width } = size;
    return (
        <g transform={`translate(${width / 2}, ${height / 2})`}>
            <NodeContentContextMenu items={contextMenuItems}>
                <Tooltip titleOrGetTitle={tooltip}>
                    <g
                        transform={`translate(0, ${(iconShapeSize - height) / 2})`}>
                        {map(
                            iconOptions.shape,
                            {
                                "circle": () =>
                                    <circle
                                        fill={theme.palette.background.alternate}
                                        r={iconShapeSize / 2}
                                        stroke={theme.palette.borders.alternate}/>,
                                "circleGroup": () =>
                                    <g>
                                        <circle
                                            cx={4}
                                            cy={8}
                                            fill={theme.palette.background.alternate}
                                            r={iconShapeSize / 2}
                                            stroke={theme.palette.borders.alternate}/>
                                        <circle
                                            cx={2}
                                            cy={4}
                                            fill={theme.palette.background.alternate}
                                            r={iconShapeSize / 2}
                                            stroke={theme.palette.borders.alternate}/>
                                        <circle
                                            fill={theme.palette.background.alternate}
                                            r={iconShapeSize / 2}
                                            stroke={theme.palette.borders.alternate}/>
                                    </g>,
                                "rectangle": () =>
                                    <g>
                                        <rect
                                            fill={theme.palette.background.alternate}
                                            height={iconShapeSize}
                                            rx={theme.spacing(0.5)}
                                            ry={theme.spacing(0.5)}
                                            stroke={theme.palette.borders.alternate}
                                            width={iconShapeSize}
                                            x={-iconShapeSize / 2}
                                            y={-iconShapeSize / 2}/>
                                    </g>
                            },
                            () => undefined)}
                        <svg
                            height={iconSize}
                            style={{
                                color:
                                    modelNode.options.appearance === "highlighted"
                                        ? iconOptions.highlightColor ?? theme.palette.text.primary
                                        : iconOptions.normalColor ?? theme.palette.text.secondary
                            }}
                            width={iconSize}
                            x={-iconSize / 2}
                            y={-iconSize / 2}>
                            {iconOptions.icon}
                        </svg>
                    </g>
                </Tooltip>
            </NodeContentContextMenu>
            {!_.isNil(titleElementOrOptions) &&
                <Fragment>
                    {_.isNil((titleElementOrOptions as IconNodeContentTextOptions).title)
                        ? <foreignObject
                            height={theme.spacing(8)}
                            transform={
                                titlePosition === "right"
                                    ? `translate(${iconShapeSize / 2 + 12}, -22)`
                                    : `translate(${-width / 2}, ${height / 2 - 64})`}
                            width={width}>
                            {titleElementOrOptions as ReactNode}
                        </foreignObject>
                        : <foreignObject
                            height={theme.spacing(8)}
                            transform={
                                titlePosition === "right"
                                    // eslint-disable-next-line @stylistic/multiline-ternary
                                    ? `translate(${iconShapeSize / 2 + 16}, ${(titleElementOrOptions as IconNodeContentTextOptions).subtitle ? -22 : -12})`
                                    : `translate(${-width / 2}, ${height / 2 - 64})`}
                            width={width}>
                            <Typography
                                align={
                                    titlePosition === "right"
                                        ? undefined
                                        : "center"}
                                noWrap={!_.isNil((titleElementOrOptions as IconNodeContentTextOptions).subtitle)}
                                sx={{
                                    color:
                                        modelNode.options.appearance === "highlighted"
                                            ? (titleElementOrOptions as IconNodeContentTextOptions)?.highlightColor ?? theme.palette.text.primary
                                            : (titleElementOrOptions as IconNodeContentTextOptions)?.normalColor ?? theme.palette.text.secondary,
                                    fontSize: "14px"
                                }}>
                                {(titleElementOrOptions as IconNodeContentTextOptions).title}
                            </Typography>
                            {!_.isNil((titleElementOrOptions as IconNodeContentTextOptions).subtitle) &&
                                <Typography
                                    align={
                                        titlePosition === "right"
                                            ? undefined
                                            : "center"}
                                    noWrap={true}
                                    sx={{
                                        color: theme.palette.text.secondary,
                                        fontSize: (titleElementOrOptions as IconNodeContentTextOptions).subtitleSize ?? "14px"
                                    }}>
                                    {(titleElementOrOptions as IconNodeContentTextOptions).subtitle}
                                </Typography>}
                        </foreignObject>}
                </Fragment>}
        </g>);
}