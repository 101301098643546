﻿import { DataTableColumn, DataTableColumnRenderProps, useExecuteOperation, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, EntityFilter, ItemSelectionHelper, PagedEntityFilter, RiskController, RiskType } from "../../../../../../../../../../../../../../../../../../../../../../common";
import { useItemsContext, useTableCommonCloudColumns } from "../../../../../../../../../../../../../../../../hooks";
import { TableColumnId } from "../../../../../../../../../../../../../../../Table";
import { useCommonColumns } from "../../../../../../../useCommonColumns";
import { useCommonCloudRiskPolicyTableDefinition } from "../../../useCommonCloudRiskPolicyTableDefinition";

export function useAwsExcessivePermissionGroupRiskDefinition(riskPolicyConfigurationTypeName: string) {
    const { requestFilters } = useItemsContext();
    const [{ filters }] =
        useExecuteOperation(
            [useAwsExcessivePermissionGroupRiskDefinition, riskPolicyConfigurationTypeName],
            () =>
                RiskController.getRiskPolicyTypeGroupFilters(
                    new Contract.RiskControllerGetRiskPolicyTypeGroupCloudRiskFiltersRequest(
                        requestFilters!,
                        riskPolicyConfigurationTypeName)));

    const localization =
        useLocalization(
            "views.customer.risks.items.grouped.item.table.hooks.useDefinition.hooks.useCloudDefinition.hooks.useRiskPolicyTypeDefinition.hooks.useAccessDefinition.hooks.aws.useAwsExcessivePermissionGroupRiskDefinition",
            () => ({
                columns: {
                    excessivePermissionServiceIds: "Excessive Services",
                    groupUserIds: "Users",
                    nonexcessivePermissionServiceIds: "Used Services"
                }
            }));

    const awsExcessivePermissionGroupRiskPolicyGroupFilter = filters as Contract.AwsExcessivePermissionGroupRiskPolicyGroupFilters;
    const commonColumns = useCommonColumns();
    const commonCloudColumns = useTableCommonCloudColumns();

    return useCommonCloudRiskPolicyTableDefinition({
        columns:
            _.filter([
                commonColumns.statusUpdateTimeColumn,
                commonColumns.severityColumn,
                commonCloudColumns.descriptionColumn,
                commonColumns.tenantIdColumn,
                commonColumns.riskedEntityIdColumn(
                    riskPolicyConfigurationTypeName,
                    requestFilters as Contract.RiskControllerCloudRiskModelFilters),
                commonColumns.resourceOwnerColumn,
                commonColumns.resourceEnvironmentColumn,
                <DataTableColumn
                    cellMaxWidth="medium"
                    filterOptions={{
                        itemOrItems: {
                            element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedRiskFilterEntityItem(
                                        requestFilters as Contract.RiskControllerCloudRiskModelFilters,
                                        Contract.RiskControllerGetRiskPolicyTypeGroupFilterItemPageRequestProperty.AwsExcessivePermissionGroupRiskGroupUsers,
                                        riskPolicyConfigurationTypeName)}
                                placeholder={localization.columns.groupUserIds()}/>
                        }
                    }}
                    id={TableColumnId.AwsExcessivePermissionPrincipalRiskGroupUserIds}
                    key={TableColumnId.AwsExcessivePermissionPrincipalRiskGroupUserIds}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.AwsExcessivePermissionGroupRiskModel>) =>
                            <EntitiesCell
                                entityIdsOrModels={item.risk.groupUserIds}
                                entityTypeName={Contract.TypeNames.AwsIamUser}
                                entityVariant="iconText"/>}
                    sortOptions={{ enabled: false }}
                    title={localization.columns.groupUserIds()}/>,
                <DataTableColumn
                    cellMaxWidth="medium"
                    filterOptions={{
                        itemOrItems: {
                            element:
                            <EntityFilter
                                emptyValue={awsExcessivePermissionGroupRiskPolicyGroupFilter.nonexcessivePermissionServiceIdItems.emptyValue}
                                entityIdsOrSearchableReferences={awsExcessivePermissionGroupRiskPolicyGroupFilter.nonexcessivePermissionServiceIdItems.items}
                                placeholder={localization.columns.nonexcessivePermissionServiceIds()}/>
                        }
                    }}
                    id={TableColumnId.AwsExcessivePermissionPrincipalRiskNonexcessivePermissionServices}
                    key={TableColumnId.AwsExcessivePermissionPrincipalRiskNonexcessivePermissionServices}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.AwsExcessivePermissionGroupRiskModel>) =>
                            <EntitiesCell
                                entityIdsOrModels={item.risk.serviceLevel!.nonexcessivePermissionServiceIds}
                                entityTypeName={Contract.TypeNames.AwsService}
                                entityVariant="iconText"/>}
                    sortOptions={{ enabled: false }}
                    title={localization.columns.nonexcessivePermissionServiceIds()}/>,
                <DataTableColumn
                    cellMaxWidth="medium"
                    filterOptions={{
                        itemOrItems: {
                            element:
                            <EntityFilter
                                emptyValue={awsExcessivePermissionGroupRiskPolicyGroupFilter.excessivePermissionServiceIdItems.emptyValue}
                                entityIdsOrSearchableReferences={awsExcessivePermissionGroupRiskPolicyGroupFilter.excessivePermissionServiceIdItems.items}
                                placeholder={localization.columns.excessivePermissionServiceIds()}/>
                        }
                    }}
                    id={TableColumnId.AwsExcessivePermissionPrincipalRiskExcessivePermissionServices}
                    key={TableColumnId.AwsExcessivePermissionPrincipalRiskExcessivePermissionServices}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.AwsExcessivePermissionGroupRiskModel>) =>
                            <EntitiesCell
                                entityIdsOrModels={item.risk.serviceLevel!.excessivePermissionServiceIds}
                                entityTypeName={Contract.TypeNames.AwsService}
                                entityVariant="iconText"/>}
                    sortOptions={{ enabled: false }}
                    title={localization.columns.excessivePermissionServiceIds()}/>,
                commonColumns.statusColumn(true),
                commonColumns.subStatusColumn(true),
                commonColumns.ignoreExpirationDateColumn,
                commonColumns.openStatusUpdateTimeColumn,
                commonColumns.systemCreationTimeColumn,
                commonColumns.riskedEntityAttributesColumn,
                commonColumns.actionsCell(RiskType.Cloud)
            ]),
        getGroupFilters:
            filterMap =>
                new Contract.RiskControllerAwsExcessivePermissionRiskPolicyTypeFilters(
                    riskPolicyConfigurationTypeName,
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[TableColumnId.RiskedEntityIds]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[TableColumnId.AwsExcessivePermissionPrincipalRiskExcessivePermissionServices]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[TableColumnId.AwsExcessivePermissionPrincipalRiskGroupUserIds]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[TableColumnId.AwsExcessivePermissionPrincipalRiskNonexcessivePermissionServices])),
        getRiskIdToScopesIdentityPermissionsMapRequest:
            requestFilters =>
                new Contract.RiskControllerGetRiskPolicyTypeGroupCloudRiskIdToScopeIdentityPermissionsMapRequest(
                    requestFilters,
                    riskPolicyConfigurationTypeName)
    });
}