import { UnexpectedError } from "@infrastructure";
import { ReactNode, useMemo } from "react";
import { Contract } from "../../../../../../../../../../../../common";
import { useAwsIamGroupDefinition, useAwsIamPrincipalPolicyDefinition, useAwsIamRoleDefinition, useAwsIamUserDefinition } from "./hooks";

export class EntitySnapshotDefinition {
    constructor(
        public description: string,
        public tableElements: ReactNode[]) {
    }
}

export function useDefinition(entitySnapshot: Contract.EntitySnapshot): EntitySnapshotDefinition {
    const useDefinition =
        useMemo(
            () => {
                switch (entitySnapshot.typeName) {
                    case Contract.TypeNames.AwsIamGroupSnapshot:
                        return useAwsIamGroupDefinition;
                    case Contract.TypeNames.AwsIamInlinePolicySnapshot:
                        return useAwsIamPrincipalPolicyDefinition;
                    case Contract.TypeNames.AwsIamManagedPolicySnapshot:
                        return useAwsIamPrincipalPolicyDefinition;
                    case Contract.TypeNames.AwsIamRoleSnapshot:
                        return useAwsIamRoleDefinition;
                    case Contract.TypeNames.AwsIamUserSnapshot:
                        return useAwsIamUserDefinition;
                    default:
                        throw new UnexpectedError("entitySnapshot.typeName", entitySnapshot.typeName);
                }
            },
            []);
    return useDefinition(entitySnapshot);
}