import { Action1, AddIcon, DataTableAction, DataTableActions, DataTableColumn, DataTableColumnRenderProps, Dialog, EmptyMessageText, makeContextProvider, Optional, optionalTableCell, StringHelper, useChangeEffect, useLocalization } from "@infrastructure";
import { Box, Button, Stack, Typography } from "@mui/material";
import _, { Function1 } from "lodash";
import React, { ComponentType, Fragment, useMemo, useRef, useState } from "react";
import { Contract, ItemTable, Scope, ScopeNameTranslatorOptions, scopeNodeModelStore, useScopeNameTranslator, useScopeNavigationViewContext, useTheme } from "../../../../../../../../common";

export class ExclusionTag extends Contract.ResourceTag {
    constructor(
        public inheritedScopeId: Optional<string>,
        public key: string,
        public value: Optional<string>
    ) {
        super(key, value);
    }
}

export type ExclusionTagsTableAddComponentProps = {
    onAdd: Action1<Contract.ResourceTag[]>;
    resourceTags: Contract.ResourceTag[];
};

export type ExclusionTagsTableActionsCellProps = {
    exclusionTagToDelete: ExclusionTag;
    onDelete: Action1<Contract.ResourceTag[]>;
    useScopeResourceTags: _.Function0<Contract.ResourceTag[]>;
};


type ExclusionTagsTableLocalization = {
    description: string;
    resourceType: {
        caps: string;
        noCaps: string;
    };
};

export type ExclusionTagsTableProps = {
    ActionsCell: ComponentType<ExclusionTagsTableActionsCellProps>;
    AddComponent: ComponentType<ExclusionTagsTableAddComponentProps>;
    exclusionTagsTableLocalization: ExclusionTagsTableLocalization;
    getScopeConfigurationWorkloadAnalysisResourceTags: Function1<Contract.ScopeConfigurationWorkloadAnalysis, Contract.ResourceTag[]>;
};

const [useScopeResourceTags, , useScopeResourceTagsContextProvider] = makeContextProvider<Contract.ResourceTag[]>();

export function ExclusionTagsTable({ ActionsCell, AddComponent, exclusionTagsTableLocalization, getScopeConfigurationWorkloadAnalysisResourceTags }: ExclusionTagsTableProps) {
    const { scopeNodeModel } = useScopeNavigationViewContext();
    const scopeNodeMap = scopeNodeModelStore.useGetActiveScopeNodeMap();

    const [scopeResourceTags, setScopeResourceTags, ScopeResourceTagsContextProvider] = useScopeResourceTagsContextProvider(() => getScopeConfigurationWorkloadAnalysisResourceTags(scopeNodeModel.configuration.scopeSections.workloadAnalysis));

    const localization =
        useLocalization(
            "views.customer.configuration.workloadAnalysis.exclusionTags.exclusionTagsTable",
            () => ({
                actions: {
                    add: "Add {{resourceType}} Exclusion Tag"
                },
                columns: {
                    inherited: "Inherited",
                    key: "Key",
                    value: "Value"
                },
                empty: "No {{resourceType}} exclusion tags",
                title: "{{resourceType}} Exclusion Tags"
            }));

    const scopeNameTranslator = useScopeNameTranslator();
    const scopeNameTranslationOptions: ScopeNameTranslatorOptions = { path: true };
    const theme = useTheme();

    const inheritedExclusionTags =
        useMemo(
            () =>
                _.flatMap(
                    _(scopeNodeMap[scopeNodeModel.configuration.id].parentScopeIds).
                        slice().
                        reverse().
                        value(),
                    parentScopeId =>
                        _.map(
                            getScopeConfigurationWorkloadAnalysisResourceTags(scopeNodeMap[parentScopeId].scopeNodeModel.configuration.scopeSections.workloadAnalysis),
                            resourceTag =>
                                new ExclusionTag(
                                    parentScopeId,
                                    resourceTag.key,
                                    resourceTag.value))),
            [scopeNodeMap, scopeNodeModel]);

    const exclusionTags =
        useMemo(
            () =>
                _(scopeResourceTags).
                    map(
                        resourceTag =>
                            new ExclusionTag(
                                undefined,
                                resourceTag.key,
                                resourceTag.value)).
                    concat(inheritedExclusionTags).
                    value(),
            [inheritedExclusionTags, scopeResourceTags]
        )
    ;

    const dataTableActionsRef = useRef<DataTableActions>();
    useChangeEffect(
        () => dataTableActionsRef.current!.reset(),
        [exclusionTags]);
    const [addOpen, setAddOpen] = useState(false);

    return (
        <ScopeResourceTagsContextProvider>
            {addOpen &&
                <Dialog
                    variant="editor"
                    onClose={() => setAddOpen(false)}>
                    <AddComponent
                        resourceTags={scopeResourceTags}
                        onAdd={
                            resourceTags => {
                                setAddOpen(false);
                                setScopeResourceTags(resourceTags);
                            }}/>
                </Dialog>}
            <Stack spacing={1}>
                <Typography variant="h4">
                    {localization.title({ resourceType: exclusionTagsTableLocalization.resourceType.caps })}
                </Typography>
                <Typography>
                    {exclusionTagsTableLocalization.description}
                </Typography>
            </Stack>
            <Box sx={{ overflow: "hidden" }}>
                <ItemTable
                    actionsRef={dataTableActionsRef}
                    columnIdToGetItemValueMap={{
                        ["exclusionTagInherited"]: exclusionTag =>
                            StringHelper.getSortValue(
                                _.isNil(exclusionTag.inheritedScopeId)
                                    ? undefined
                                    : scopeNameTranslator(exclusionTag.inheritedScopeId, scopeNameTranslationOptions)),
                        ["exclusionTagKey"]: exclusionTag => StringHelper.getSortValue(exclusionTag.key),
                        ["exclusionTagValue"]: exclusionTag => StringHelper.getSortValue(exclusionTag.value)
                    }}
                    defaultSortColumnIdOrIds={"exclusionTagKey"}
                    emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty({ resourceType: exclusionTagsTableLocalization.resourceType.noCaps })) }}
                    getItemId={(exclusionTag: ExclusionTag) => `${exclusionTag.key}:${exclusionTag.value}:${exclusionTag.inheritedScopeId}`}
                    items={exclusionTags}
                    showEmptyTable={true}
                    sx={{
                        maxHeight: theme.spacing(80),
                        minHeight: theme.spacing(25)
                    }}
                    variant="view">
                    {() => [
                        <DataTableAction key="DataTableAction_Add">
                            <Button
                                startIcon={<AddIcon/>}
                                onClick={() => setAddOpen(true)}>
                                {localization.actions.add({ resourceType: exclusionTagsTableLocalization.resourceType.caps })}
                            </Button>
                        </DataTableAction>,
                        <DataTableColumn
                            id={"exclusionTagKey"}
                            itemProperty={exclusionTag => exclusionTag.key}
                            key={"exclusionTagKey"}
                            title={localization.columns.key()}/>,
                        <DataTableColumn
                            id={"exclusionTagValue"}
                            key={"exclusionTagValue"}
                            render={optionalTableCell<ExclusionTag>(exclusionTag => exclusionTag.value)}
                            title={localization.columns.value()}/>,
                        <DataTableColumn
                            id={"exclusionTagInherited"}
                            key={"exclusionTagInherited"}
                            render={
                                optionalTableCell<ExclusionTag>(
                                    exclusionTag =>
                                        _.isNil(exclusionTag.inheritedScopeId)
                                            ? undefined
                                            : <Scope
                                                scopeId={exclusionTag.inheritedScopeId!}
                                                scopeNameTranslatorOptions={scopeNameTranslationOptions}
                                                variant="text"/>)}
                            title={localization.columns.inherited()}/>,
                        <DataTableColumn
                            id={"exclusionTagActions"}
                            key={"exclusionTagActions"}
                            render={
                                ({ item }: DataTableColumnRenderProps<ExclusionTag>) =>
                                    !_.isNil(item.inheritedScopeId)
                                        ? <Fragment/>
                                        : <ActionsCell
                                            exclusionTagToDelete={item}
                                            useScopeResourceTags={useScopeResourceTags}
                                            onDelete={resourceTags => setScopeResourceTags(resourceTags)}/>
                            }/>
                    ]}
                </ItemTable>
            </Box>
        </ScopeResourceTagsContextProvider>);
}