import { useLocalization } from "@infrastructure";
import { Contract } from "..";

export function useScopeTypeTranslator() {
    const localization =
        useLocalization(
            "common.hooks.useScopeTypeTranslator",
            () => ({
                [Contract.TypeNames.ScopeType]: {
                    [Contract.ScopeType.CiTenant]: "Container Registry",
                    [Contract.ScopeType.CloudProviderTenant]: "Account",
                    [Contract.ScopeType.CodeTenant]: "Code Repository",
                    [Contract.ScopeType.Customer]: "Organization",
                    [Contract.ScopeType.Folder]: "Folder",
                    [Contract.ScopeType.IdentityProviderTenant]: "Account",
                    [Contract.ScopeType.Project]: "Project",
                    [Contract.ScopeType.ProjectFolder]: "Folder"
                }
            }));
    return (scopeType: Contract.ScopeType) =>
        localization[Contract.TypeNames.ScopeType][scopeType]();
}