import { useLocalization } from "@infrastructure";
import { Avatar, Grid, Typography } from "@mui/material";
import React from "react";
import { Contract, RiskPolicyCategoryIcon, useRiskPolicyCategoryTranslator, useTheme } from "../../../../../../../../../../../common";

type RiskPolicyCategoryProps = {
    riskPolicyCategory: Contract.RiskPolicyCategory;
};

export function RiskPolicyCategory({ riskPolicyCategory }: RiskPolicyCategoryProps) {
    const riskPolicyCategoryTranslator = useRiskPolicyCategoryTranslator();
    const localization =
        useLocalization(
            "views.system.exportReport.riskPolicyReport.item.title.riskPolicyCategory",
            () => ({
                [Contract.TypeNames.RiskPolicyCategory]: {
                    [Contract.RiskPolicyCategory.Access]: "Policies that detect issues related to identity and access management, such as inactive or overprivileged IAM identities.",
                    [Contract.RiskPolicyCategory.Compute]: "Policies that detect issues related to cloud compute resources, such as public or vulnerable machines.",
                    [Contract.RiskPolicyCategory.Custom]: "Policies that you create, based on easy-to-use templates, to address specific issues relevant to your organization.",
                    [Contract.RiskPolicyCategory.Data]: "Policies that detect issues related to cloud data resources, such as public or unencrypted data resources.",
                    [Contract.RiskPolicyCategory.Logging]: "Policies that detect misconfigurations related to cloud logging.",
                    [Contract.RiskPolicyCategory.Kubernetes]: "Policies that detect issues related to Kubernetes resources, such as vulnerable nodes and network misconfigurations.",
                    [Contract.RiskPolicyCategory.Management]: "Policies that detect general changes to your cloud infrastructure.",
                    [Contract.RiskPolicyCategory.Monitoring]: "Policies that detect missing monitoring alerts within your cloud infrastructure environment.",
                    [Contract.RiskPolicyCategory.Network]: "Policies that detect insecure network configurations such as public network exposure.",
                    [Contract.RiskPolicyCategory.Secrets]: "Policies that detect risks related to secrets such as public or exposed cleartext secrets.",
                    [Contract.RiskPolicyCategory.WorkloadAnalysis]: "Policies that detect vulnerabilities and other issues related to your cloud workloads."
                }
            }));

    const theme = useTheme();
    return (
        <Grid
            alignItems="center"
            container={true}
            spacing={2}
            wrap="nowrap">
            <Grid item={true}>
                <Avatar
                    sx={{
                        backgroundColor: theme.palette.risk.defaultCategory,
                        height: "36px",
                        width: "36px"
                    }}>
                    <RiskPolicyCategoryIcon
                        riskPolicyCategory={riskPolicyCategory}
                        sx={{
                            color: theme.palette.text.primary,
                            fontSize: "18px",
                            fontWeight: "bold"
                        }}/>
                </Avatar>
            </Grid>
            <Grid item={true}>
                <Grid
                    container={true}
                    direction="column">
                    <Typography variant="h4">
                        {riskPolicyCategoryTranslator(riskPolicyCategory)}
                    </Typography>
                    <Typography>
                        {localization[Contract.TypeNames.RiskPolicyCategory].translate(riskPolicyCategory)}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>);
}