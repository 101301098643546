import { Step, Steps, useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { useGetAwsDynamoDbTableRiskContext } from "../contexts";

export function useAwsDynamoDbTableEncryptionDisabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsDynamoDbTableEncryptionDisabledRisk;
    const tableModel = entityModelStore.useGet(risk.entityId) as Contract.AwsDynamoDbTableModel;

    const getAwsDynamoDbTableRiskContext = useGetAwsDynamoDbTableRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsDynamoDbTableEncryptionDisabledRiskDefinition",
            () => ({
                description: "{{table}} is not configured with encryption using KMS",
                sections: {
                    resolution: {
                        step1: "Click the **Additional settings** tab",
                        step2: "Under the **Encryption** section, click on **Manage encryption**",
                        step3: {
                            step1: "Select **AWS managed key** to use the AWS-managed KMS key. This key is stored within your AWS account and is managed by Amazon KMS.",
                            step2: "Select **Stored in your account, and owned and managed by you** to use a Customer Managed Key that is stored in your AWS account and is owned and managed by you.",
                            title: "Choose one of the following options:"
                        },
                        step4: "Before saving the changes, make sure the relevant identities have the right permissions to the KMS key used to encrypt the DynamoDB table",
                        step5: "Click **Save changes**"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            table:
                <Entity
                    entityIdOrModel={tableModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.DynamoDb,
                AwsConsoleUrlBuilder.getDynamoDbTableUrl(tableModel.entity as Contract.AwsDynamoDbTable)),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            new Step(
                localization.sections.resolution.step3.title(),
                {
                    contentElement:
                        <Steps variant="plainNumbers">
                            {localization.sections.resolution.step3.step1()}
                            {localization.sections.resolution.step3.step2()}
                        </Steps>
                }),
            localization.sections.resolution.step4(),
            localization.sections.resolution.step5()
        ],
        riskModel,
        () => {
            const tableRiskContext = getAwsDynamoDbTableRiskContext(tableModel);
            return [
                tableRiskContext.generalInformation,
                tableRiskContext.sensitive,
                tableRiskContext.storageSize,
                tableRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}