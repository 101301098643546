﻿import { useLocalization } from "@infrastructure";
import React, { useMemo } from "react";
import { Contract, StatusCell, tenantModelStore } from "../../../../../../../../common";
import { useOpTenantModelStatusTranslator } from "../../../../../../../../tenants";
import { ProfileDefinition } from "../../useProfileDefinition";

export function useOpProfileDefinition(scopeNodeModel: Contract.ScopeNodeModel): ProfileDefinition {
    const tenantModelMap = tenantModelStore.useGetOpTenantMap();

    const opTenantModelStatusTranslator = useOpTenantModelStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.scopes.hooks.useDefinition.hooks.useOpDefinition.useOpProfileDefinition",
            () => ({
                id: "ID",
                status: "Status"
            }));

    return useMemo(
        () => {
            const tenantModel = tenantModelMap[scopeNodeModel.configuration.id];
            return {
                sections: [
                    {
                        items: [
                            {
                                title: localization.id(),
                                value: tenantModel.configuration.id
                            },
                            {
                                title: localization.status(),
                                value:
                                    <StatusCell
                                        statusSeverity={tenantModel.statusSeverity}
                                        title={opTenantModelStatusTranslator(tenantModel.status)}/>
                            }
                        ]
                    }
                ]
            };
        },
        [localization, tenantModelMap]);
}