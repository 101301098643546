import { StringHelper } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { IntegrationItem } from "../../utilities";
import { Item } from "./components";

type CategoryProps = {
    description: string;
    items: IntegrationItem[];
    onClick(item: IntegrationItem): void;
    title: string;
};

export function Category({ description, items, onClick, title }: CategoryProps) {
    return (
        <Stack sx={{ alignItems: "left" }}>
            <Stack spacing={1}>
                <Typography variant="h4">
                    {title}
                </Typography>
                <Typography
                    style={{ whiteSpace: "pre-wrap" }}
                    variant="subtitle1">
                    {description}
                </Typography>
            </Stack>
            <Stack
                direction="row"
                sx={{
                    alignItems: "center",
                    flexWrap: "wrap",
                    marginTop: "10px"
                }}>
                {_(items).
                    orderBy([
                        item => _.isEmpty(item.configurations),
                        item => StringHelper.getSortValue(item.title)
                    ]).
                    map(
                        item =>
                            <Item
                                item={item}
                                key={item.type}
                                onClick={() => onClick(item)}/>).
                    value()}
            </Stack>
        </Stack>);
}