import { Dropdown, DropdownProps, EmptyMessage, FilterField, InfiniteScroll, InfiniteScrollActions, InfiniteScrollFetchDataResult, ItemSelectorField, ItemSelectorFieldPropsVariant, Loading, Optional, SearchTextField, useChangeEffect } from "@infrastructure";
import { Box, Stack, SxProps, Typography, useTheme } from "@mui/material";
import _ from "lodash";
import React, { ReactNode, useRef, useState } from "react";
import { Action0 } from "../types";

type PagedDropdownProps =
    Omit<DropdownProps, "popoverElement" | "children"> & {
        children: (items: any[], emptyValue?: boolean) => ReactNode;
        emptyText?: string;
        fieldOptions: PagedDropdownFieldOptions;
        fullWidth?: boolean;
        getItemPage: (searchText: Optional<string>, skip: number, data?: any) => Promise<PagedDropdownPage>;
        headerElement?: ReactNode;
        onClose?: Action0;
        readOnly?: boolean;
        searchOptions?: PagedDropdownSearchOptions;
    };

export type PagedDropdownFieldOptions = {
    dense?: boolean;
    emptyValue?: boolean;
    placeholder: string;
    selection?: ReactNode;
    selectionCount?: number;
} & ({
    dense?: never;
    emptyValue?: boolean;
    fieldVariant?: never;
    selectionCount?: never;
    variant: "filter";
} | {
    emptyValue?: never;
    fieldVariant?: ItemSelectorFieldPropsVariant;
    onSelectionCleared?: Action0;
    variant: "itemSelector";
} | {
    dense?: never;
    emptyValue?: never;
    fieldVariant?: never;
    selectionCount?: never;
    variant: "text";
});

type PagedDropdownSearchOptions = {
    enabled?: boolean;
    onTextChanged?: (searchText: string) => void;
    placeholder?: string;
    sx?: SxProps;
};

export class PagedDropdownPage {
    constructor(
        public hasMore: boolean,
        public items: any[],
        public applyData?: () => any,
        public emptyValue?: boolean) {
    }
}

export function PagedDropdown({ children: renderItems, disabled = false, emptyText, fieldOptions, fullWidth, getItemPage, headerElement, readOnly = false, searchOptions, ...props }: PagedDropdownProps) {
    const theme = useTheme();
    return (
        <Dropdown
            {...props}
            disabled={disabled && fieldOptions.variant !== "filter" || readOnly}
            fullWidth={fullWidth}
            popoverElement={
                <Popover
                    emptyText={emptyText}
                    getItemPage={getItemPage}
                    headerElement={headerElement}
                    renderItems={renderItems}
                    searchOptions={searchOptions}/>}
            popoverElementContainerSx={{ padding: 0 }}>
            {fieldOptions.variant === "filter" &&
                <FilterField
                    emptyValue={fieldOptions.emptyValue}
                    focused={props.open}
                    placeholder={fieldOptions.placeholder}
                    selection={fieldOptions.selection}/>}
            {fieldOptions.variant === "itemSelector" &&
                <ItemSelectorField
                    dense={fieldOptions.dense}
                    disabled={disabled}
                    open={props.open}
                    placeholder={fieldOptions.placeholder}
                    readOnly={readOnly}
                    selection={fieldOptions.selection}
                    selectionCount={fieldOptions.selectionCount}
                    variant={fieldOptions.fieldVariant}
                    onSelectionCleared={fieldOptions.onSelectionCleared}/>}
            {fieldOptions.variant === "text" &&
                <Typography
                    component="span"
                    noWrap={true}
                    sx={{
                        color:
                            disabled
                                ? theme.palette.text.disabled
                                : undefined
                    }}>
                    {fieldOptions.placeholder}
                </Typography>}
        </Dropdown>);
}

type PopoverProps = {
    emptyText?: string;
    getItemPage: (searchText: Optional<string>, skip: number, data?: any) => Promise<PagedDropdownPage>;
    headerElement?: ReactNode;
    renderItems: (items: any[], emptyValue?: boolean) => ReactNode;
    searchOptions?: PagedDropdownSearchOptions;
};

function Popover({ emptyText, getItemPage, headerElement, renderItems, searchOptions }: PopoverProps) {
    const dataRef = useRef<any>();
    const firstItemPageRef = useRef<PagedDropdownPage>();
    const [items, setItems] = useState<any[]>([]);
    const [emptyValue, setEmptyValue] = useState<Optional<boolean>>();
    const [searchText, setSearchText] = useState<string>();
    const fetchItemPage =
        async () => {
            if (firstItemPageRef.current?.hasMore === false) {
                return new InfiniteScrollFetchDataResult(false, true);
            }

            const itemPage =
                await getItemPage(
                    searchText,
                    items.length,
                    dataRef.current);

            return new InfiniteScrollFetchDataResult(
                itemPage.items.length > 0,
                !itemPage.hasMore,
                () => {
                    setItems(items => _.concat(items, itemPage.items));
                    setEmptyValue(_.isEmpty(searchText) && (itemPage.emptyValue || emptyValue));
                    dataRef.current = itemPage.applyData?.();
                    firstItemPageRef.current = firstItemPageRef.current ?? itemPage;
                });
        };

    const infiniteScrollActionsRef = useRef<InfiniteScrollActions>(null);
    useChangeEffect(
        () => {
            searchOptions?.onTextChanged?.(searchText ?? "");
            setEmptyValue(false);

            setItems([]);
            dataRef.current = undefined;
            firstItemPageRef.current = undefined;

            infiniteScrollActionsRef.current!.reset();
            return () => searchOptions?.onTextChanged?.("");
        },
        [searchText],
        500);

    const theme = useTheme();
    return (
        <Stack>
            {searchOptions?.enabled && (
                <Box sx={{ padding: theme.spacing(0.5) }}>
                    <Stack
                        spacing={1}
                        sx={searchOptions.sx}>
                        <SearchTextField
                            placeholder={searchOptions.placeholder}
                            searchText={searchText}
                            onSearchTextChanged={searchText => setSearchText(searchText)}/>
                        {headerElement}
                    </Stack>
                </Box>)}
            <Box sx={{ flex: 1 }}>
                <Loading>
                    <InfiniteScroll
                        actionsRef={infiniteScrollActionsRef}
                        container="popup"
                        fetchData={fetchItemPage}
                        sx={{ maxHeight: theme.spacing(50) }}>
                        {!_.isNil(firstItemPageRef.current) &&
                        _.isEmpty(firstItemPageRef.current.items) &&
                        !_.isNil(emptyText) &&
                        !emptyValue
                            ? <EmptyMessage
                                message={emptyText}
                                size="small"/>
                            : renderItems(items, emptyValue)}
                    </InfiniteScroll>
                </Loading>
            </Box>
        </Stack>);
}