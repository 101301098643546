﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useAwsCommonEncryptedResourceInfoElements, useAwsDefaultResourceInfoItemElements } from ".";
import { useCustomEntityPropertyInfoItemElements } from "..";
import { Contract, InfoItem } from "../../../../../../../../../../common";
import { useAwsBedrockCustomModelCustomizationTypeTranslator } from "../../../../../../hooks";
import { EntitiesInfoItem, Info } from "../../../../components";
import { DefinitionOptions, EntityProfileDefinition } from "../../useDefinition";

export function useAwsBedrockCustomModelDefinition(resourceModel: Contract.AwsResourceModel, options?: DefinitionOptions) {
    const commonEncryptedResourceInfoElements = useAwsCommonEncryptedResourceInfoElements(resourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const customModel = resourceModel.entity as Contract.AwsBedrockCustomModel;
    const customModelModel = resourceModel as Contract.AwsBedrockCustomModelModel;
    const defaultResourceInfoItemElements = useAwsDefaultResourceInfoItemElements(resourceModel);

    const customModelCustomizationTypeTranslator = useAwsBedrockCustomModelCustomizationTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsBedrockCustomModelDefinition",
            () => ({
                info: {
                    items: {
                        customizationType: "Customization type",
                        inputTrainingBucketId: "Training Input Data",
                        inputValidationBucketIds: "Validation Input Data",
                        outputBucketId: "Output Data",
                        sourceModelArn: "Source Model Arn"
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    ...commonEncryptedResourceInfoElements,
                    <InfoItem
                        key="type"
                        title={localization.info.items.customizationType()}
                        value={
                            _.isNil(customModel.customizationType)
                                ? undefined
                                : customModelCustomizationTypeTranslator(customModel.customizationType!)}/>,
                    <InfoItem
                        key="sourceModelArn"
                        title={localization.info.items.sourceModelArn()}
                        value={customModel.sourceModelArn}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={customModelModel.inputTrainingBucketId}
                        entityTypeName={Contract.TypeNames.AwsS3Bucket}
                        key="trainingBucketId"
                        title={localization.info.items.inputTrainingBucketId()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={customModelModel.inputValidationBucketIds}
                        entityTypeName={Contract.TypeNames.AwsS3Bucket}
                        key="validationBucketIds"
                        title={localization.info.items.inputValidationBucketIds()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={customModelModel.outputBucketId}
                        entityTypeName={Contract.TypeNames.AwsS3Bucket}
                        key="outputBucketId"
                        title={localization.info.items.outputBucketId()}/>
                ]}
                options={options?.infoOptions}>
            </Info>
    });
}