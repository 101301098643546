﻿import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useGcpComputeSslPolicyFeatureProfileTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useGcpComputeSslPolicyFeatureProfileTranslator",
            () => ({
                [Contract.TypeNames.GcpComputeSslPolicyFeatureProfile]: {
                    [Contract.GcpComputeSslPolicyFeatureProfile.Compatible]: "Compatible",
                    [Contract.GcpComputeSslPolicyFeatureProfile.Custom]: "Custom",
                    [Contract.GcpComputeSslPolicyFeatureProfile.Modern]: "Modern",
                    [Contract.GcpComputeSslPolicyFeatureProfile.Restricted]: "Restricted"
                }
            }));
    return (featureProfile: Contract.GcpComputeSslPolicyFeatureProfile) =>
        localization[Contract.TypeNames.GcpComputeSslPolicyFeatureProfile][featureProfile]();
}