import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function GENIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <g clipPath="url(#clip0_58343_2215)">
                <path
                    d="M0 12.8454C0 5.69497 5.21497 0 12.6612 0C17.7151 0 22.6034 2.78245 23.4132 7.536H19.3966C18.2617 7.536 17.8095 7.11639 17.2274 6.53419C16.5461 5.88852 16.0939 5.46735 15.1866 5.11123C14.4743 4.82013 13.697 4.6591 12.7262 4.6591C8.19097 4.6591 5.63303 8.05626 5.63303 12.2942C5.63303 17.0508 8.57961 20.0594 12.2725 20.0594C15.3182 20.0594 17.52 18.2803 17.9721 15.8865H15.0906C13.6661 15.8865 12.8237 15.2717 12.8237 13.7187V11.9071H20.7283C22.865 11.9071 23.9985 12.8129 23.9985 14.9156V23.8792H20.0145V20.3195C18.0712 23.2955 15.0921 24.5574 11.4643 24.5574C5.73213 24.5574 0 19.9959 0 12.8454Z"
                    fill="#0400F5"/>
            </g>
            <defs>
                <clipPath id="clip0_58343_2215">
                    <rect
                        fill="white"
                        height="24"
                        width="24"/>
                </clipPath>
            </defs>
        </SvgIcon>);
}