import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, InlineEntities } from "../../../../../../../../../../../../common";
import { useAzureEventHubNamespaceStatusTranslator } from "../../../../../../../../../Entities/hooks/useAzureEventHubNamespaceStatusTranslator";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetAzureResourceRiskContext } from "./useGetAzureResourceRiskContext";

export function useGetAzureEventHubNamespaceRiskContext() {
    return useMemo(
        () => useAzureEventHubNamespaceRiskContext,
        []);
}

function useAzureEventHubNamespaceRiskContext(namespaceModel: Contract.AzureEventHubNamespaceModel) {
    const namespace = namespaceModel.entity as Contract.AzureEventHubNamespace;
    const resourceRiskContext = useGetAzureResourceRiskContext()(namespaceModel);

    const eventHubNamespaceStatusTranslator = useAzureEventHubNamespaceStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.contexts.useGetAzureEventHubNamespaceRiskContext",
            () => ({
                eventHubs: "The Event Hubs Namespace contains {{eventHubs}}",
                status: "The Event Hubs Namespace status is **{{status}}**"
            }));

    return {
        ...resourceRiskContext,
        eventHubs:
            _.isEmpty(namespaceModel.eventHubIds)
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.eventHubs({
                        eventHubs:
                            <InlineEntities
                                entityIdsOrModels={namespaceModel.eventHubIds!}
                                entityTypeName={Contract.TypeNames.AzureEventHubNamespaceEventHub}
                                variant="itemCountAndType"/>
                    })),
        status: new RiskDefinitionContextItem(localization.status({ status: eventHubNamespaceStatusTranslator(namespace.status) }))
    };
}