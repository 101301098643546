import { DialogMenuItem, TimeFormatter, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useAzureCommonComputeVirtualMachineResourceInfoItemElements, useAzureCommonManagedIdentityOriginatorResourceInfoElements, useAzureCommonNetworkingInfoItemElements, useAzureDefaultResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../..";
import { useCommonVirtualMachineDiskResourceInfoItemElements, useCommonVirtualMachineResourceInfoItemElements, useCustomEntityPropertyInfoItemElements } from "..";
import { Contract, InfoItem, VerticalTopItemsInfoItem } from "../../../../../../../../../../common";
import { useAzureComputeVirtualMachineStatusTranslator } from "../../../../../../../../../../tenants";
import { useGetVirtualMachinePriorityScanRequestDialogMenuItem } from "../../../../../../../WorkloadAnalysis";
import { EntitiesInfoItem, Info } from "../../../../components";
import { AzureResourceRoleAssignmentResources, NetworkingInfoCard } from "../../components";

export function useAzureComputeVirtualMachineDefinition(resourceGroupResourceModel: Contract.AzureResourceGroupResourceModel, options?: DefinitionOptions) {
    const commonComputeVirtualMachineResourceInfoItemElements = useAzureCommonComputeVirtualMachineResourceInfoItemElements(resourceGroupResourceModel as Contract.AzureComputeVirtualMachineResourceModel);
    const commonManagedIdentityOriginatorResourceInfoElements = useAzureCommonManagedIdentityOriginatorResourceInfoElements(resourceGroupResourceModel);
    const commonVirtualMachineDiskResourceInfoItemElements = useCommonVirtualMachineDiskResourceInfoItemElements(resourceGroupResourceModel);
    const commonVirtualMachineResourceInfoItemElements = useCommonVirtualMachineResourceInfoItemElements(resourceGroupResourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceGroupResourceModel);
    const defaultResourceInfoItemElements = useAzureDefaultResourceInfoItemElements(resourceGroupResourceModel);
    const virtualMachineModel = resourceGroupResourceModel as Contract.AzureComputeVirtualMachineModel;
    const getVirtualMachinePriorityScanRequestDialogMenuItem = useGetVirtualMachinePriorityScanRequestDialogMenuItem(virtualMachineModel);
    const virtualMachine = resourceGroupResourceModel.entity as Contract.AzureComputeVirtualMachine;
    const networkInfoItems =
        useAzureCommonNetworkingInfoItemElements({
            networkInterfaceIds: virtualMachine.networkInterfaceIds,
            resourceModel: resourceGroupResourceModel
        });

    const computeVirtualMachineStatusTranslator = useAzureComputeVirtualMachineStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.azure.useAzureComputeVirtualMachineDefinition",
            () => ({
                info: {
                    items: {
                        availabilityZone: {
                            empty: "N/A",
                            title: "Availability Zone"
                        },
                        diskIds: "Managed Disks",
                        dnsZoneIds: "DNS Zones",
                        status: "Status",
                        stopTime: "Stop Time",
                        unmanagedDisks: "Unmanaged Disks",
                        virtualMachineScaleSetId: "Virtual Machine Scale Set"
                    }
                },
                tabs: {
                    resourceRoleAssignments: "Resource Role Assignments"
                },
                toolbar: {
                    menu: {
                        generate: "Least Privilege Policy"
                    }
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <AzureResourceRoleAssignmentResources
                    csvExportFilePrefix={localization.tabs.resourceRoleAssignments()}
                    scopeResourceModel={resourceGroupResourceModel}/>,
                localization.tabs.resourceRoleAssignments(),
                "resourceRoleAssignments")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    commonComputeVirtualMachineResourceInfoItemElements.rawIdInfoItemElement,
                    commonComputeVirtualMachineResourceInfoItemElements.rawTypeInfoItemElement,
                    commonComputeVirtualMachineResourceInfoItemElements.imageInfoInfoItemElement,
                    ...commonManagedIdentityOriginatorResourceInfoElements,
                    <InfoItem
                        emptyValue={localization.info.items.availabilityZone.empty()}
                        key="availabilityZone"
                        title={localization.info.items.availabilityZone.title()}
                        value={virtualMachine.availabilityZoneName}/>,
                    <InfoItem
                        key="status"
                        location="all"
                        title={localization.info.items.status()}
                        value={computeVirtualMachineStatusTranslator(virtualMachine.status)}/>,
                    <InfoItem
                        key="stopTime"
                        location="all"
                        title={localization.info.items.stopTime()}
                        value={
                            _.isNil(virtualMachineModel.stopTime)
                                ? undefined
                                : TimeFormatter.profileFormatDateTime(virtualMachineModel.stopTime)}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={virtualMachineModel.dnsZoneIds}
                        entityTypeName={Contract.TypeNames.Entity}
                        key="dnsZoneIds"
                        title={localization.info.items.dnsZoneIds()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={virtualMachine.diskIds}
                        entityTypeName={Contract.TypeNames.AzureComputeDisk}
                        key="diskIds"
                        title={
                            _.isEmpty(virtualMachine.diskIds)
                                ? undefined
                                : localization.info.items.diskIds()}/>,
                    <VerticalTopItemsInfoItem
                        items={
                            _.map(
                                virtualMachine.unmanagedDisks,
                                unmanagedDisk => unmanagedDisk.name
                            )}
                        key="unmanagedDisks"
                        title={localization.info.items.unmanagedDisks()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={virtualMachine.virtualMachineScaleSetId}
                        entityTypeName={Contract.TypeNames.AzureComputeVirtualMachineScaleSet}
                        key="virtualMachineScaleSet"
                        title={localization.info.items.virtualMachineScaleSetId()}/>,
                    commonVirtualMachineDiskResourceInfoItemElements.operatingSystemDisplayNameInfoElement,
                    commonVirtualMachineDiskResourceInfoItemElements.operatingSystemTypeInfoElement,
                    commonVirtualMachineDiskResourceInfoItemElements.vulnerabilitiesInfoElement,
                    commonVirtualMachineDiskResourceInfoItemElements.workloadAnalysisStatusInfoElement,
                    commonVirtualMachineDiskResourceInfoItemElements.scanTimeInfoElement,
                    commonVirtualMachineResourceInfoItemElements.scanSourceInfoElement
                ]}
                options={options?.infoOptions}>
                <NetworkingInfoCard>
                    {networkInfoItems.inboundAccessType}
                    {networkInfoItems.inboundExternalAccessScope}
                    {networkInfoItems.getPublicIpAddresses()}
                    {networkInfoItems.getPrivateIpAddresses()}
                    {networkInfoItems.getVirtualNetwork()}
                    {networkInfoItems.getSubnets()}
                    {networkInfoItems.getNetworkInterfaces()}
                    {networkInfoItems.getSubnetNetworkSecurityGroupIds()}
                    {networkInfoItems.getInterfaceNetworkSecurityGroups()}
                    {networkInfoItems.getApplicationSecurityGroups()}
                    {commonComputeVirtualMachineResourceInfoItemElements.loadBalancersInfoItemElement}
                    {commonComputeVirtualMachineResourceInfoItemElements.applicationGatewaysInfoItemElement}
                </NetworkingInfoCard>
            </Info>,
        topbarMenuItems: _.filter([getVirtualMachinePriorityScanRequestDialogMenuItem()]) as DialogMenuItem[]
    });
}