import { DataTableColumn, Link, map, StringHelper, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, CustomerConsoleAppUrlHelper, ScopeHelper, StatusCell, tenantModelStore, UrlHelper } from "../../../../../../../../common";
import { TenantsDefinition } from "../../useDefinition";
import { useRenderTenant } from "../useRenderTenant";
import { useCiTenantModelStatusTranslator } from "./hooks";

export function useCiDefinition(): TenantsDefinition {
    const tenantModelMap = tenantModelStore.useGetCiTenantMap();
    const ciTenantModelStatusTranslator = useCiTenantModelStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.scopes.hooks.useDefinition.hooks.useCiDefinition",
            () => ({
                actions: {
                    delete: "delete the associated integration",
                    deleteOrganizationFolder: "This folder belongs to an organization which is configured to automatically onboard new registries, and can't be deleted.\nInstead, you can either {{deleteLink}} (including all its registries and folders), or exclude this specific registry by {{supportLink}}.",
                    deleteSelection: "Some/All of the selected registries and/or folders belong to an organization which is configured to automatically onboard new registries, and can't be deleted.\nInstead, you can either {{deleteLink}} (including all its registries and folders), or exclude this specific registry by {{supportLink}}.",
                    deleteTenant: "To delete a registry, you can either {{deleteLink}}, or exclude this specific registry by {{supportLink}}.",
                    supportLink: "contacting Support"
                },
                columns: {
                    status: "Status"
                },
                title: "Container Registries"
            }));

    const { renderTenantCell, renderTenantCsvItem } = useRenderTenant<Contract.CiTenantModel>();
    return useMemo(
        () => ({

            deleteTenantDisabled:  true,
            editTenantDisabled: true,
            filter:
                (filterMap, scopeNodeModel) => {
                    if (ScopeHelper.isFolder(scopeNodeModel)) {
                        return true;
                    }

                    const tenantModel = tenantModelMap[scopeNodeModel.configuration.id];

                    if (_.isNil(tenantModel)) {
                        return true;
                    }

                    if (!_.isNil(filterMap[TenantTableColumnId.Status]) &&
                        !_.includes(filterMap[TenantTableColumnId.Status].values, tenantModel.status)) {
                        return false;
                    }

                    return true;
                },
            getColumns:
                scopeNodeModels => {
                    const statuses = new Set<Contract.CiTenantModelStatus>();

                    _(scopeNodeModels).
                        filter(scopeNodeModel => scopeNodeModel.type === Contract.ScopeType.CiTenant && !_.isNil(tenantModelMap[scopeNodeModel.configuration.id])).
                        map(scopeNodeModel => tenantModelMap[scopeNodeModel.configuration.id]).
                        forEach(
                            (tenantModel: Contract.CiTenantModel) => {
                                statuses.add(tenantModel.status);
                            });

                    return [
                        <DataTableColumn
                            exportOptions={{
                                getItem:
                                    (scopeNodeModel: Contract.ScopeNodeModel) => ({
                                        [localization.columns.status()]:
                                            renderTenantCsvItem(
                                                tenantModel => ciTenantModelStatusTranslator(tenantModel.status),
                                                scopeNodeModel)
                                    })
                            }}
                            filterOptions={{
                                itemOrItems: {
                                    default: true,
                                    element:
                                        <ValuesFilter placeholder={localization.columns.status()}>
                                            {_.map(
                                                Array.from(statuses),
                                                status => (
                                                    <ValuesFilterItem
                                                        key={status}
                                                        title={ciTenantModelStatusTranslator(status)}
                                                        value={status}/>))}
                                        </ValuesFilter>
                                }
                            }}
                            id={TenantTableColumnId.Status}
                            key={TenantTableColumnId.Status}
                            render={
                                renderTenantCell(
                                    tenantModel =>
                                        <StatusCell
                                            statusSeverity={tenantModel.statusSeverity}
                                            title={ciTenantModelStatusTranslator(tenantModel.status)}/>)}
                            title={localization.columns.status()}/>
                    ];
                },
            getDeleteScopeDisabledMessage:
                (selection, scopeNodeModel) => {
                    if (scopeNodeModel.type === Contract.ScopeType.Folder &&
                        (scopeNodeModel.configuration as Contract.CiFolderConfiguration).managed) {
                        return (selection
                            ? localization.actions.deleteSelection
                            : localization.actions.deleteOrganizationFolder)({
                            deleteLink:
                                <Link
                                    urlOrGetUrl={CustomerConsoleAppUrlHelper.getConfigurationIntegrationsRelativeUrl()}>
                                    {localization.actions.delete()}
                                </Link>,
                            supportLink:
                                <Link
                                    urlOrGetUrl={UrlHelper.supportUrl}
                                    variant="external">
                                    {localization.actions.supportLink()}
                                </Link>
                        });
                    } else if (scopeNodeModel.type === Contract.ScopeType.CiTenant) {
                        return (selection
                            ? localization.actions.deleteSelection
                            : localization.actions.deleteTenant)({
                            deleteLink:
                                <Link
                                    urlOrGetUrl={
                                        selection
                                            ? CustomerConsoleAppUrlHelper.getConfigurationIntegrationsRelativeUrl()
                                            : CustomerConsoleAppUrlHelper.getConfigurationIntegrationsCiIntegrationRelativeUrl((scopeNodeModel.configuration as Contract.CiTenantConfiguration).ciTenantType)}>
                                    {localization.actions.delete()}
                                </Link>,
                            supportLink:
                                <Link
                                    urlOrGetUrl={UrlHelper.supportUrl}
                                    variant="external">
                                    {localization.actions.supportLink()}
                                </Link>
                        });
                    }

                    return undefined;
                },
            sort:
                (columnId, scopeNodeModel) => {
                    if (scopeNodeModel.type === Contract.ScopeType.CiTenant && !_.isNil(tenantModelMap[scopeNodeModel.configuration.id])) {
                        const tenantModel = tenantModelMap[scopeNodeModel.configuration.id];
                        return map<string, any>(
                            columnId,
                            {
                                [TenantTableColumnId.Status]: () => StringHelper.getSortValue(ciTenantModelStatusTranslator(tenantModel.status))
                            });
                    } else {
                        return undefined;
                    }
                },
            title: localization.title()
        }),
        [localization, tenantModelMap]);
}

enum TenantTableColumnId {
    Status = "status"
}