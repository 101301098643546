import { DialogMenuItem, TimeFormatter, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../..";
import { Contract, InfoItem, tenantModelStore, usePrincipalModelAccess } from "../../../../../../../../../../common";
import { GenerateAzureIdentityNonexcessiveRoleDefinitionDialog } from "../../../../../../../../../../tenants";
import { useAadDirectoryUserAuthenticationTypeTranslator, useAadDirectoryUserGuestUserInvitationStatusTranslator, useAadDirectoryUserTypeTranslator } from "../../../../../../hooks";
import { AadDirectoryPrincipalActivityInfoItem, EntitiesInfoCard, Info, PrincipalSecurityStatsStat } from "../../../../components";
import { GenerateEntityNonexcessivePermitterIcon } from "../../../../icons";
import { AadDirectoryPrincipalAzureRoleAssignmentResources, AadDirectoryPrincipalDirectoryRoleAssignmentResources } from "../../components";
import { useAadDefaultDirectoryEntityInfoItemElements } from "./useAadDefaultDirectoryEntityInfoItemElements";

export function useAadDirectoryUserDefinition(directoryEntityModel: Contract.AadDirectoryEntityModel, options?: DefinitionOptions) {
    const activeCloudProviderTenantTypes = tenantModelStore.useGetActiveCloudProviderTenantTypes();
    const userModel = directoryEntityModel as Contract.AadDirectoryUserModel;
    const defaultDirectoryEntityInfoItemElements = useAadDefaultDirectoryEntityInfoItemElements(userModel);
    const user = directoryEntityModel.entity as Contract.AadDirectoryUser;
    const userModelAccess = usePrincipalModelAccess<Contract.ServiceIdentityModelAccess>(userModel.id);
    const userProfile = directoryEntityModel.entityProfile as Contract.AadDirectoryUserProfile;

    const directoryUserAuthenticationTypeTranslator = useAadDirectoryUserAuthenticationTypeTranslator();
    const directoryUserGuestUserInvitationStatusTranslator = useAadDirectoryUserGuestUserInvitationStatusTranslator();
    const directoryUserTypeTranslator = useAadDirectoryUserTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aad.useAadDirectoryUserDefinition",
            () => ({
                info: {
                    cards: {
                        groupIds: "Group Memberships"
                    },
                    items: {
                        adOrigin: {
                            false: "No",
                            title: "On-premises sync",
                            true: "Yes"
                        },
                        adSyncUpdateTime: "On-premises Sync Time",
                        authenticationType: "Authentication Type",
                        enabled: {
                            false: "Disabled",
                            title: "Status",
                            true: "Enabled"
                        },
                        guestUserInvitationStatus: "Invitation State",
                        jobDepartment: "Department",
                        jobTitle: "Job Title",
                        location: "Location",
                        rawMail: "Mail",
                        signInTime: {
                            empty: "Not seen",
                            title: "Sign-in Time"
                        },
                        type: "User Type",
                        userPrincipalName: "User Principal Name"
                    }
                },
                tabs: {
                    aadAdministrativeRoleAssignments: "Microsoft Entra ID Administrative Role Assignments",
                    azureRoleAssignments: "Azure Role Assignments"
                },
                topbar: {
                    menu: {
                        generate: "Generate Custom Role"
                    }
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs:
            _<EntityProfileDefinitionTab>([]).
                concat(
                    new EntityProfileDefinitionTab(
                        ProfileCategory.Iam,
                        <AadDirectoryPrincipalDirectoryRoleAssignmentResources
                            csvExportFilePrefix={localization.tabs.aadAdministrativeRoleAssignments()}
                            principalModel={userModel}/>,
                        localization.tabs.aadAdministrativeRoleAssignments(),
                        "aadAdministrativeRoleAssignments")).
                concatIf(
                    _.includes(activeCloudProviderTenantTypes, Contract.TenantType.Azure),
                    new EntityProfileDefinitionTab(
                        ProfileCategory.Iam,
                        <AadDirectoryPrincipalAzureRoleAssignmentResources
                            csvExportFilePrefix={localization.tabs.azureRoleAssignments()}
                            principalModel={userModel}/>,
                        localization.tabs.azureRoleAssignments(),
                        "azureRoleAssignments")).
                value(),
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={[]}
                defaultTenantInfoItemElements={defaultDirectoryEntityInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="enabled"
                        title={localization.info.items.enabled.title()}
                        value={
                            user.enabled
                                ? localization.info.items.enabled.true()
                                : localization.info.items.enabled.false()}/>,
                    <InfoItem
                        key="adOrigin"
                        title={localization.info.items.adOrigin.title()}
                        value={
                            user.adOrigin
                                ? localization.info.items.adOrigin.true()
                                : localization.info.items.adOrigin.false()}/>,
                    user.adOrigin
                        ? <InfoItem
                            key="adSyncUpdateTime"
                            title={localization.info.items.adSyncUpdateTime()}
                            value={
                                _.isNil(user.adSyncUpdateTime)
                                    ? undefined
                                    : TimeFormatter.profileFormatDateTime(user.adSyncUpdateTime)}/>
                        : undefined,
                    <InfoItem
                        emptyValue={localization.info.items.signInTime.empty()}
                        key="signInTime"
                        title={localization.info.items.signInTime.title()}
                        value={
                            _.isNil(userProfile?.signInTime)
                                ? undefined
                                : TimeFormatter.profileFormatDateTime(userProfile.signInTime)}/>,
                    <AadDirectoryPrincipalActivityInfoItem
                        key="activityTime"
                        principalModel={userModel}/>,
                    <InfoItem
                        key="userPrincipalName"
                        title={localization.info.items.userPrincipalName()}
                        value={user.userPrincipalName}/>,
                    <InfoItem
                        key="rawMail"
                        location="all"
                        title={localization.info.items.rawMail()}
                        value={user.rawMail}/>,
                    <InfoItem
                        key="location"
                        location="profile"
                        title={localization.info.items.location()}
                        value={user.location}/>,
                    <InfoItem
                        key="type"
                        title={localization.info.items.type()}
                        value={directoryUserTypeTranslator(user.type)}/>,
                    <InfoItem
                        key="authenticationType"
                        title={localization.info.items.authenticationType()}
                        value={directoryUserAuthenticationTypeTranslator(user.authenticationType)}/>,
                    <InfoItem
                        key="guestUserInvitationStatus"
                        title={localization.info.items.guestUserInvitationStatus()}
                        value={
                            _.isNil(user.guestUserInvitationStatus)
                                ? undefined
                                : directoryUserGuestUserInvitationStatusTranslator(user.guestUserInvitationStatus)}/>,
                    <InfoItem
                        key="jobTitle"
                        location="all"
                        title={localization.info.items.jobTitle()}
                        value={user.jobInfo?.title}/>,
                    <InfoItem
                        key="jobDepartment"
                        location="all"
                        title={localization.info.items.jobDepartment()}
                        value={user.jobInfo?.department}/>
                ]}
                options={options?.infoOptions}>
                <EntitiesInfoCard
                    entityIdsOrModels={userModel.groupIds}
                    entityTypeName={Contract.TypeNames.AadDirectoryGroup}
                    title={localization.info.cards.groupIds()}/>
            </Info>,
        sensitive: false,
        statElements:
            _.filter([
                _.isNil(userModelAccess?.permissions)
                    ? undefined
                    : <PrincipalSecurityStatsStat
                        analyzingReason={userModel.analyzing?.reason}
                        analyzingTenants={
                            _.isNil(userModel.analyzing?.tenantIds)
                                ? undefined
                                : {
                                    ids: userModel.analyzing!.tenantIds,
                                    typeName: Contract.TypeNames.AzureTenantEntity
                                }}
                        securityStats={userModelAccess.permissions!.excessivePermissionActionStats}
                        typeName={Contract.TypeNames.AadDirectoryUser}/>
            ]),
        topbarMenuItems:
            _.isNil(userModel.systemDeletionTime)
                ? [
                    new DialogMenuItem(
                        onClose =>
                            <GenerateAzureIdentityNonexcessiveRoleDefinitionDialog
                                identityModel={userModel}
                                onClose={onClose}/>,
                        localization.topbar.menu.generate(),
                        { icon: <GenerateEntityNonexcessivePermitterIcon/> })
                ]
                : undefined
    });
}