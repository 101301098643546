import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function MongoDBIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <g clipPath="url(#clip0_58343_1951)">
                <path
                    d="M13.6826 2.66563C12.6742 1.47987 11.8059 0.256759 11.6379 0.0140051C11.6192 -0.00466835 11.5912 -0.00466835 11.5725 0.0140051C11.3951 0.266096 10.5268 1.47987 9.51843 2.67497C0.872642 13.7016 10.8816 21.143 10.8816 21.143L10.9656 21.199C11.0403 22.3474 11.227 24 11.227 24H11.6005H11.974C11.974 24 12.1607 22.3567 12.2354 21.199L12.3194 21.1336C12.3194 21.1336 22.3284 13.7016 13.6826 2.66563ZM11.6005 20.9749C11.6005 20.9749 11.1524 20.5921 11.031 20.396V20.3774L11.5725 8.37035C11.5725 8.33301 11.6285 8.33301 11.6285 8.37035L12.1607 20.368V20.3867C12.0393 20.5921 11.6005 20.9749 11.6005 20.9749Z"
                    fill="#00ED64"/>
            </g>
            <defs>
                <clipPath id="clip0_58343_1951">
                    <rect
                        fill="white"
                        height="24"
                        width="24"/>
                </clipPath>
            </defs>
        </SvgIcon>);
}