﻿import { Tooltip, useLocalization } from "@infrastructure";
import { Avatar, Box, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract, ServiceNowIcon, useServiceNowIncidentTranslator, useTheme } from "..";

export type ServiceNowAvatarProps = {
    incidentsOrGetIncidents: Contract.ServiceNowIncident[] | (() => Promise<Contract.ServiceNowIncident[]>);
};

export function ServiceNowAvatar({ incidentsOrGetIncidents }: ServiceNowAvatarProps) {
    const serviceNowIncidentTranslator = useServiceNowIncidentTranslator();
    const localization =
        useLocalization(
            "common.serviceNowAvatar",
            () => ({
                title: [
                    "1 ServiceNow Incident was created",
                    "{{count | NumberFormatter.humanize}} ServiceNow Incidents were created"
                ]
            }));
    const theme = useTheme();
    return (
        <Avatar
            sx={{
                backgroundColor: theme.palette.background.paper,
                height: "18px",
                width: "18px"
            }}
            onClick={event => event.stopPropagation()}>
            <Tooltip
                maxWidth={false}
                titleOrGetTitle={
                    async () => {
                        let incidents: Contract.ServiceNowIncident[];
                        if (_.isFunction(incidentsOrGetIncidents)) {
                            incidents = await incidentsOrGetIncidents();
                        } else {
                            incidents = incidentsOrGetIncidents;
                        }
                        return (
                            <Stack spacing={1}>
                                {incidents.length > 1 && (
                                    <Typography variant="h5">
                                        {localization.title(incidents.length)}
                                    </Typography>)}
                                <Stack
                                    spacing={1}
                                    sx={{
                                        maxHeight: theme.spacing(60),
                                        overflow: "auto",
                                        padding: theme.spacing(1)
                                    }}>
                                    {_.map(
                                        incidents,
                                        incident =>
                                            <Box key={incident.id}>
                                                {serviceNowIncidentTranslator(incident, "link")}
                                            </Box>)}
                                </Stack>
                            </Stack>);
                    }}>
                <ServiceNowIcon sx={{ fontSize: "15px" }}/>
            </Tooltip>
        </Avatar>);
}