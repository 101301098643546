import { useLocalization } from "@infrastructure";
import { useMemo } from "react";
import { RiskPoliciesType, RiskPolicyItem } from "../../../../../../../../../../../common";
import { CustomRiskPolicyConfiguration } from "../../../../../../../../../../../common/controllers/types.generated";
import { useRiskPoliciesItemConfiguration } from "../../../../../../../hooks";
import { RiskPolicyInfoItems } from "../../../useRiskPolicyInfoItems";
import { useCommonCloudRiskPolicyInfoItems } from "./useCommonCloudRiskPolicyInfoItems";

export function useCloudRiskPolicyCustomInfoItems(scopeId: string, item: RiskPolicyItem): RiskPolicyInfoItems {
    const { riskPolicyConfiguration } = useRiskPoliciesItemConfiguration(RiskPoliciesType.Cloud, item, scopeId);
    const commonCloudRiskPolicyInfoItems = useCommonCloudRiskPolicyInfoItems(item, scopeId);

    const localization =
        useLocalization(
            "views.customer.riskPolicies.profile.hooks.useRiskPolicyInfoItems.hooks.cloud.hooks.useCloudRiskPolicyCustomInfoItems",
            () => ({
                configuration: "Configuration",
                subtitle: "Policy"
            }));

    return useMemo(
        () => ({
            additionalInfoCard: [
                {
                    items: [
                        commonCloudRiskPolicyInfoItems.integration,
                        commonCloudRiskPolicyInfoItems.getCompliances([], scopeId)
                    ]
                },
                {
                    items: [
                        commonCloudRiskPolicyInfoItems.status,
                        commonCloudRiskPolicyInfoItems.getConfigurationDelay(),
                        ...commonCloudRiskPolicyInfoItems.getAdditionalInfoItems(),
                        commonCloudRiskPolicyInfoItems.configurationExcludedResources
                    ],
                    title: localization.configuration()
                }
            ],
            description: commonCloudRiskPolicyInfoItems.getDescription((riskPolicyConfiguration as CustomRiskPolicyConfiguration).description),
            general: [
                commonCloudRiskPolicyInfoItems.getTenantId(item.tenantTypes),
                commonCloudRiskPolicyInfoItems.category,
                commonCloudRiskPolicyInfoItems.getSeverity((riskPolicyConfiguration as CustomRiskPolicyConfiguration).severity),
                commonCloudRiskPolicyInfoItems.getRisks(),
                commonCloudRiskPolicyInfoItems.analyzedEntityCount,
                commonCloudRiskPolicyInfoItems.failedEntities,
                commonCloudRiskPolicyInfoItems.excludedEntities
            ],
            topbar: {
                subtitle: localization.subtitle(),
                title: (riskPolicyConfiguration as CustomRiskPolicyConfiguration).name
            }
        }),
        [scopeId, item]);
}