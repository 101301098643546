import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, Entity, entityModelStore, InlineEntities } from "../../../../../../../../../../../../common";
import { useAwsCommonBehaviorIdentityRiskDefinition } from "./useAwsCommonBehaviorIdentityRiskDefinition";

export function useAwsDataLeakageRiskDefinition(riskModel: Contract.RiskModel) {
    const dataLeakageRiskModel = riskModel as Contract.AwsDataLeakageRiskModel;
    const identityModel = entityModelStore.useGet(dataLeakageRiskModel.risk.identityId);
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.behavior.useAwsDataLeakageRiskDefinition",
            () => ({
                dataResources: [
                    "1 data resource",
                    "{{count | NumberFormatter.humanize}} data resources"
                ],
                description: {
                    [Contract.TypeNames.AwsDataLeakageRiskModelDescriptionType]: {
                        [Contract.AwsDataLeakageRiskModelDescriptionType.PreviousBehaviorDateEver]: "{{identity}} was observed accessing {{dataResources}} that were not accessed since {{previousBehaviorDate | TimeFormatter.longDate}}",
                        [Contract.AwsDataLeakageRiskModelDescriptionType.PreviousBehaviorDateNever]: "{{identity}} was observed accessing {{dataResources}} that were not accessed before"
                    }
                }
            }));

    return useAwsCommonBehaviorIdentityRiskDefinition(
        riskModel,
        localization.description[Contract.TypeNames.AwsDataLeakageRiskModelDescriptionType][dataLeakageRiskModel.descriptionType]({
            dataResources:
                <InlineEntities
                    entityIdsOrModels={dataLeakageRiskModel.resourceIdReferences}
                    entityTypeName={Contract.TypeNames.AwsResource}
                    namePluralizer={localization.dataResources}
                    variant="itemCountAndType"/>,
            identity:
                <Entity
                    entityIdOrModel={identityModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>,
            previousBehaviorDate: dataLeakageRiskModel.risk.previousBehaviorDate
        }));
}