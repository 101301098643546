import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, optionalTableCell, PagedValuesFilter, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import { Dictionary } from "lodash";
import React from "react";
import { useOciCommonResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions, useEntityTypeNameTranslator } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";

export function useOciComputeInstanceConfigurationDefinition(definitionData: DefinitionData) {
    const commonResourceDefinition = useOciCommonResourceDefinition(definitionData);

    const entitiesExportOptions = useEntitiesExportOptions();

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.oci.useOciComputeInstanceConfigurationDefinition",
            () => ({
                columns: {
                    metadataServiceVersion1Enabled: {
                        false: "Version 2 only",
                        title: "Instance Metadata Service",
                        true: "Versions 1 and 2"
                    },
                    security: {
                        enabled: {
                            false: "Disabled",
                            true: "Enabled"
                        },
                        memoryEncryptionEnabled: "Confidential Computing",
                        secureBootEnabled: "Secure Boot"
                    },
                    shape: "Shape",
                    volumeEncryptionInTransitEnabled: {
                        false: "Disabled",
                        title: "In-Transit Encryption",
                        true: "Enabled"
                    }
                }
            }));

    return new EntityTableDefinition(
        [
            commonResourceDefinition.columns.tenantColumn,
            commonResourceDefinition.columns.creationTimeColumn,
            commonResourceDefinition.columns.creatorCsvColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.OciComputeInstanceConfigurationModel) => ({
                            [localization.columns.shape()]: (item.entity as Contract.OciComputeInstanceConfiguration).shape
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedValuesFilter
                                getValuePage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.OciComputeInstanceConfigurationShape)}
                                placeholder={localization.columns.shape()}/>
                    }
                }}
                id={Contract.EntityModelProperty.OciComputeInstanceConfigurationShape}
                key={Contract.EntityModelProperty.OciComputeInstanceConfigurationShape}
                render={optionalTableCell<Contract.OciComputeInstanceConfigurationModel>(item => (item.entity as Contract.OciComputeInstanceConfiguration).shape)}
                selectorOptions={{ default: false }}
                title={localization.columns.shape()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.OciComputeInstanceConfigurationModel>(
                        Contract.TypeNames.OciComputeInstancePool,
                        item => item.instancePoolIds,
                        entityTypeNameTranslator(
                            Contract.TypeNames.OciComputeInstancePool,
                            { count: 0 }))}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityTypeFilter(
                                        Contract.TypeNames.OciComputeInstancePool,
                                        true)}
                                placeholder={
                                    entityTypeNameTranslator(
                                        Contract.TypeNames.OciComputeInstancePool,
                                        { count: 0 })}/>
                    }
                }}
                id={Contract.EntityModelProperty.OciComputeInstanceConfigurationInstancePools}
                key={Contract.EntityModelProperty.OciComputeInstanceConfigurationInstancePools}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.OciComputeInstanceConfigurationModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.instancePoolIds}
                            entityTypeName={Contract.TypeNames.OciComputeInstancePool}
                            entityVariant="iconText"/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={
                    entityTypeNameTranslator(
                        Contract.TypeNames.OciComputeInstancePool,
                        { count: 0 })}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.OciComputeInstanceConfigurationModel) => ({
                            [localization.columns.volumeEncryptionInTransitEnabled.title()]:
                                (item.entity as Contract.OciComputeInstanceConfiguration).volumeEncryptionInTransitEnabled
                                    ? localization.columns.volumeEncryptionInTransitEnabled.true()
                                    : localization.columns.volumeEncryptionInTransitEnabled.false()
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <ValuesFilter placeholder={localization.columns.volumeEncryptionInTransitEnabled.title()}>
                                <ValuesFilterItem
                                    title={localization.columns.volumeEncryptionInTransitEnabled.false()}
                                    value={false}/>
                                <ValuesFilterItem
                                    title={localization.columns.volumeEncryptionInTransitEnabled.true()}
                                    value={true}/>
                            </ValuesFilter>
                    }
                }}
                id={Contract.EntityModelProperty.OciComputeInstanceConfigurationVolumeEncryptionInTransitEnabled}
                itemProperty={
                    (item: Contract.EntityModel) =>
                        (item.entity as Contract.OciComputeInstanceConfiguration).volumeEncryptionInTransitEnabled
                            ? localization.columns.volumeEncryptionInTransitEnabled.true()
                            : localization.columns.volumeEncryptionInTransitEnabled.false()}
                key={Contract.EntityModelProperty.OciComputeInstanceConfigurationVolumeEncryptionInTransitEnabled}
                selectorOptions={{ default: false }}
                title={localization.columns.volumeEncryptionInTransitEnabled.title()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.OciComputeInstanceConfigurationModel) => ({
                            [localization.columns.security.secureBootEnabled()]:
                                (item.entity as Contract.OciComputeInstanceConfiguration).security.secureBootEnabled
                                    ? localization.columns.security.enabled.true()
                                    : localization.columns.security.enabled.false()
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <ValuesFilter placeholder={localization.columns.security.secureBootEnabled()}>
                                <ValuesFilterItem
                                    title={localization.columns.security.enabled.false()}
                                    value={false}/>
                                <ValuesFilterItem
                                    title={localization.columns.security.enabled.true()}
                                    value={true}/>
                            </ValuesFilter>
                    }
                }}
                id={Contract.EntityModelProperty.OciComputeInstanceConfigurationSecureBootEnabled}
                itemProperty={
                    (item: Contract.EntityModel) =>
                        (item.entity as Contract.OciComputeInstanceConfiguration).security.secureBootEnabled
                            ? localization.columns.security.enabled.true()
                            : localization.columns.security.enabled.false()}
                key={Contract.EntityModelProperty.OciComputeInstanceConfigurationSecureBootEnabled}
                selectorOptions={{ default: false }}
                title={localization.columns.security.secureBootEnabled()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.OciComputeInstanceConfigurationModel) => ({
                            [localization.columns.security.memoryEncryptionEnabled()]:
                                (item.entity as Contract.OciComputeInstanceConfiguration).security.memoryEncryptionEnabled
                                    ? localization.columns.security.enabled.true()
                                    : localization.columns.security.enabled.false()
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <ValuesFilter placeholder={localization.columns.security.memoryEncryptionEnabled()}>
                                <ValuesFilterItem
                                    title={localization.columns.security.enabled.false()}
                                    value={false}/>
                                <ValuesFilterItem
                                    title={localization.columns.security.enabled.true()}
                                    value={true}/>
                            </ValuesFilter>
                    }
                }}
                id={Contract.EntityModelProperty.OciComputeInstanceConfigurationMemoryEncryptionEnabled}
                itemProperty={
                    (item: Contract.EntityModel) =>
                        (item.entity as Contract.OciComputeInstanceConfiguration).security.memoryEncryptionEnabled
                            ? localization.columns.security.enabled.true()
                            : localization.columns.security.enabled.false()}
                key={Contract.EntityModelProperty.OciComputeInstanceConfigurationMemoryEncryptionEnabled}
                selectorOptions={{ default: false }}
                title={localization.columns.security.memoryEncryptionEnabled()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.OciComputeInstanceConfigurationModel) => ({
                            [localization.columns.metadataServiceVersion1Enabled.title()]:
                                (item.entity as Contract.OciComputeInstanceConfiguration).metadataServiceVersion1Enabled
                                    ? localization.columns.metadataServiceVersion1Enabled.true()
                                    : localization.columns.metadataServiceVersion1Enabled.false()
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <ValuesFilter placeholder={localization.columns.metadataServiceVersion1Enabled.title()}>
                                <ValuesFilterItem
                                    title={localization.columns.metadataServiceVersion1Enabled.false()}
                                    value={false}/>
                                <ValuesFilterItem
                                    title={localization.columns.metadataServiceVersion1Enabled.true()}
                                    value={true}/>
                            </ValuesFilter>
                    }
                }}
                id={Contract.EntityModelProperty.OciComputeInstanceConfigurationMetadataServiceVersion1Enabled}
                itemProperty={
                    (item: Contract.EntityModel) =>
                        (item.entity as Contract.OciComputeInstanceConfiguration).metadataServiceVersion1Enabled
                            ? localization.columns.metadataServiceVersion1Enabled.true()
                            : localization.columns.metadataServiceVersion1Enabled.false()}
                key={Contract.EntityModelProperty.OciComputeInstanceConfigurationMetadataServiceVersion1Enabled}
                selectorOptions={{ default: false }}
                title={localization.columns.metadataServiceVersion1Enabled.title()}/>,
            commonResourceDefinition.columns.regionColumn,
            commonResourceDefinition.columns.regionLocationColumn,
            commonResourceDefinition.columns.tagsColumn,
            commonResourceDefinition.columns.attributesColumn,
            commonResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageOciComputeInstanceConfigurationRequest(
                new Contract.EntityControllerGetEntityModelPageOciComputeInstanceConfigurationRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciComputeInstanceConfigurationInstancePools]),
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciComputeInstanceConfigurationMemoryEncryptionEnabled]),
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciComputeInstanceConfigurationMetadataServiceVersion1Enabled]),
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciComputeInstanceConfigurationSecureBootEnabled]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciComputeInstanceConfigurationShape]),
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciComputeInstanceConfigurationVolumeEncryptionInTransitEnabled])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags]))));
}