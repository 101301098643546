import { CheckboxField, useLocalization, useSetWizardContext, useWizardContext } from "@infrastructure";
import _ from "lodash";
import React, { useEffect, useMemo } from "react";
import { EditItem } from "../../../../../components";
import { useRiskResolutionAutomationContext } from "../../../../../utilities";
import { AwsRiskResolutionAutomationContext } from "../useAwsDefinition";

type AwsEditItemProps = {
    onShowAccessKeyItemChanged: (showAccessKeyItem: boolean) => void;
    showAccessKeyItem: boolean;
};

export function AwsEditItem({ onShowAccessKeyItemChanged, showAccessKeyItem }: AwsEditItemProps) {
    const { changeModels, isChangeResolvable, storedAwsAccessKeyUniqueId } = useRiskResolutionAutomationContext() as AwsRiskResolutionAutomationContext;
    const { useNextEffect } = useWizardContext();
    const setWizardContext = useSetWizardContext();

    const localization =
        useLocalization(
            "common.riskResolutionAutomation.hooks.useDefinition.hooks.useAwsDefinition.awsEditItem",
            () => ({
                accessKey: "Continue with access key",
                tenantNotChangeable: "Tenable Cloud Security does not have permissions to perform the remediation steps. To allow Tenable Cloud Security to perform the steps, provide one-time credentials when prompted."
            }
            ));

    useEffect(
        () => {
            setWizardContext(
                wizardContext => ({
                    ...wizardContext,
                    bottomElement:
                        _.some(
                            changeModels,
                            changeModel => !changeModel.changeable) &&
                        _.isNil(storedAwsAccessKeyUniqueId)
                            ? undefined
                            : <CheckboxField
                                checked={showAccessKeyItem}
                                onChange={() => onShowAccessKeyItemChanged(!showAccessKeyItem)}>
                                {localization.accessKey()}
                            </CheckboxField>
                }));
        },
        [showAccessKeyItem]);

    useNextEffect(
        async () => {
            setWizardContext(
                wizardContext => ({
                    ...wizardContext,
                    bottomElement: undefined
                }));
            return undefined;
        },
        [showAccessKeyItem]);
    const notChangeableMessage =
        useMemo(
            () =>
                _.some(changeModels, changeModel => !changeModel.changeable) &&
                _.isNil(storedAwsAccessKeyUniqueId)
                    ? localization.tenantNotChangeable()
                    : undefined,
            [changeModels, storedAwsAccessKeyUniqueId]);

    return (
        <EditItem
            isChangeResolvable={isChangeResolvable}
            notChangeableMessage={notChangeableMessage}/>);
}