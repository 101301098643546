﻿import { NotValidIcon, SuccessIcon, Tooltip } from "@infrastructure";
import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { Contract, useTheme } from "../../../../../common";
import { useSystemKubernetesClusterSensorStatusTooltipTranslator, useSystemKubernetesClusterSensorStatusTranslator } from "../../../../../tenants";

type KubernetesClusterSensorStatusProps = {
    status: Contract.SystemKubernetesClusterModelHelmComponentStatus;
};

export function KubernetesClusterSensorStatus({ status }: KubernetesClusterSensorStatusProps) {
    const kubernetesClusterSensorStatusTooltipTranslator = useSystemKubernetesClusterSensorStatusTooltipTranslator();
    const kubernetesClusterSensorStatusTranslator = useSystemKubernetesClusterSensorStatusTranslator();
    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            direction="row"
            flexWrap="nowrap"
            spacing={2}
            sx={{ width: "inherit" }}>
            <Tooltip titleOrGetTitle={kubernetesClusterSensorStatusTooltipTranslator(status)}>
                <Stack
                    alignItems="center"
                    direction="row"
                    spacing={1}
                    sx={{
                        overflow: "hidden",
                        width: "fit-content"
                    }}>
                    <Box sx={{ fontSize: "18px" }}>
                        {(() => {
                            switch (status) {
                                case Contract.SystemKubernetesClusterModelHelmComponentStatus.NotInstalled:
                                    return <NotValidIcon sx={{ color: theme.palette.severity(Contract.Severity.Information) }}/>;
                                case Contract.SystemKubernetesClusterModelHelmComponentStatus.NotRunning:
                                    return <NotValidIcon sx={{ color: theme.palette.severity(Contract.Severity.High) }}/>;
                                case Contract.SystemKubernetesClusterModelHelmComponentStatus.Running:
                                    return <SuccessIcon sx={{ color: theme.palette.success.main }}/>;
                            }
                        })()}
                    </Box>
                    <Typography noWrap={true}>
                        {kubernetesClusterSensorStatusTranslator(status)}
                    </Typography>
                </Stack>
            </Tooltip>
        </Stack>);
}