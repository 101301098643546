﻿import { Box, CircularProgress, FormControl, FormControlLabel, Radio, RadioGroup as MuiRadioGroup, Stack, SxProps, Typography } from "@mui/material";
import _ from "lodash";
import React, { Fragment, ReactNode, useEffect, useState } from "react";
import { Message } from "@infrastructure";
import { useTheme } from "../themes";

export type RadioGroupItem<T> = {
    children?: ReactNode;
    childrenVariant?: "default" | "hidden";
    disabled?: boolean;
    label: string | ReactNode;
    layout?: "horizontal" | "vertical";
    sx?: SxProps;
    value: T;
};

export type RadioGroupProps<T> = {
    description?: string | ReactNode;
    disabled?: boolean;
    items: RadioGroupItem<T>[];
    loading?: boolean;
    onChange?: (value: T) => Promise<void> | void;
    selectedValue?: T;
    sx?: SxProps;
    title?: string;
    tooltip?: string;
};

export function RadioGroup<T>({ description, disabled = false, items, loading, onChange, selectedValue, sx, title, tooltip }: RadioGroupProps<T>) {
    const [value, setValue] = useState(selectedValue);
    const [internalLoading, setInternalLoading] = useState(false);

    useEffect(
        () => setValue(selectedValue),
        [selectedValue]);

    const theme = useTheme();
    return (
        <Stack
            sx={{
                marginBottom: theme.spacing(4),
                ...sx
            }}>
            {title &&
                <Stack
                    direction="row"
                    spacing={1}>
                    <Typography
                        sx={{ marginBottom: theme.spacing(2) }}
                        variant="h4">
                        {title}
                    </Typography>
                    {tooltip &&
                        <Message
                            level="info"
                            title={tooltip}
                            variant="minimal"/>}
                    {internalLoading || loading
                        ? <CircularProgress
                            size={theme.spacing(2)}
                            variant="indeterminate"/>
                        : undefined}
                </Stack>}
            {description && (
                description instanceof String
                    ? <Typography sx={{ marginRight: theme.spacing(1) }}>
                        {description}
                    </Typography>
                    : description)}
            <FormControl>
                <MuiRadioGroup
                    value={value}
                    onChange={
                        async (event: React.ChangeEvent<HTMLInputElement>) => {
                            const changedValue = (event.target as HTMLInputElement).value as T;
                            setInternalLoading(true);
                            setValue(changedValue);
                            if (!_.isNil(onChange)) {
                                await onChange?.(changedValue);
                            }
                            setInternalLoading(false);
                        }}>
                    {_.map(
                        items,
                        (item, itemIndex) =>
                            <Stack
                                direction={
                                    item.layout === "horizontal"
                                        ? "row"
                                        : "column"}
                                key={itemIndex}>
                                <FormControlLabel
                                    control={
                                        _.size(items) === 1
                                            ? <Fragment/>
                                            : <Radio/>}
                                    disabled={disabled || item.disabled}
                                    label={item.label}
                                    sx={{ width: "fit-content" }}
                                    value={item.value}/>
                                {item.children && (item.childrenVariant === "hidden" || item.value == value) &&
                                    <Box
                                        sx={{
                                            display:
                                                item.value == value
                                                    ? undefined
                                                    : "none",
                                            paddingLeft: theme.spacing(3.5),
                                            ...item.sx
                                        }}>
                                        {item.children}
                                    </Box>}
                            </Stack>)}
                </MuiRadioGroup>
            </FormControl>
        </Stack>);
}