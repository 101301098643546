import _ from "lodash";
import React from "react";
import { DialogMenuItem, useLocalization } from "@infrastructure";
import { useAwsCommonEncryptedResourceInfoElements, useAwsCommonNetworkingInfoItemElements, useAwsCommonRdsClusterResourceInfoElements, useAwsDefaultResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { useCustomEntityPropertyInfoItemElements, useGetDataAnalysisResourcePriorityScanRequestDialogMenuItem } from "..";
import { Contract, FeatureHelper, InfoItem } from "../../../../../../../../../../common";
import { useAwsRdsClusterEngineTypeTranslator } from "../../../../../../hooks";
import { Info } from "../../../../components";
import { NetworkingInfoCard } from "../../components";
import { useDefaultDataAnalysisResourceInfoItemElements } from "../useDefaultDataAnalysisResourceInfoItemElements";

export function useAwsRdsClusterDefinition(resourceModel: Contract.AwsResourceModel, options?: DefinitionOptions) {
    const clusterModel = resourceModel as Contract.AwsRdsClusterModel;
    const cluster = clusterModel.entity as Contract.AwsRdsCluster;
    const commonEncryptedResourceInfoElements = useAwsCommonEncryptedResourceInfoElements(resourceModel);
    const commonNetworkCardInfoItems = useAwsCommonNetworkingInfoItemElements({ resourceModel });
    const commonRdsClusterResourceInfoElements =
        useAwsCommonRdsClusterResourceInfoElements(
            clusterModel,
            Contract.TypeNames.AwsRdsDatabaseInstance,
            Contract.TypeNames.AwsRdsAuroraGlobalCluster,
            Contract.TypeNames.AwsRdsClusterSnapshot);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultDataAnalysisResourceInfoItemElements = useDefaultDataAnalysisResourceInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useAwsDefaultResourceInfoItemElements(resourceModel);

    const rdsClusterEngineTypeTranslator = useAwsRdsClusterEngineTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsRdsClusterDefinition",
            () => ({
                info: {
                    items: {
                        engineType: "Engine",
                        instanceRawType: "Instance Class",
                        minorVersionUpgradeEnabled: {
                            false: "Disabled",
                            title: "Auto Minor Version Upgrade",
                            true: "Enabled"
                        }
                    }
                }
            }));

    const getDataAnalysisResourcePriorityScanRequestDialogMenuItem =
        useGetDataAnalysisResourcePriorityScanRequestDialogMenuItem(
            resourceModel as Contract.AwsRdsClusterModel,
            dataAnalysis => dataAnalysis.managedDatabaseScanEnabled);
    const menuItems = [getDataAnalysisResourcePriorityScanRequestDialogMenuItem()];

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="engineType"
                        title={localization.info.items.engineType()}
                        value={rdsClusterEngineTypeTranslator(cluster.engineType)}/>,
                    commonRdsClusterResourceInfoElements.status,
                    commonRdsClusterResourceInfoElements.engineVersion,
                    commonRdsClusterResourceInfoElements.storageSize,
                    <InfoItem
                        key="instanceRawType"
                        title={localization.info.items.instanceRawType()}
                        value={cluster.instanceRawType}/>,
                    commonRdsClusterResourceInfoElements.instances,
                    commonRdsClusterResourceInfoElements.snapshots,
                    commonRdsClusterResourceInfoElements.globalCluster,
                    ...commonEncryptedResourceInfoElements,
                    commonRdsClusterResourceInfoElements.backupRetentionTimeFrame,
                    commonRdsClusterResourceInfoElements.cloudWatchLoggingEnabled,
                    commonRdsClusterResourceInfoElements.cloudWatchLogTypes,
                    commonRdsClusterResourceInfoElements.deleteProtection,
                    // Migration: 1.161 | Remove condition and leave always true
                    FeatureHelper.enabled(Contract.FeatureName.GenerateCustomerEntityModelsCompleted)
                        ? <InfoItem
                            key="minorVersionUpgradeEnabled"
                            title={localization.info.items.minorVersionUpgradeEnabled.title()}
                            value={
                                cluster.minorVersionUpgradeEnabled
                                    ? localization.info.items.minorVersionUpgradeEnabled.true()
                                    : localization.info.items.minorVersionUpgradeEnabled.false()}/>
                        : undefined,
                    commonRdsClusterResourceInfoElements.availabilityZones,
                    ...(defaultDataAnalysisResourceInfoItemElements)
                ]}
                options={options?.infoOptions}>
                {
                    // Migration: 1.161 | Delete condition & keep false flow
                    !FeatureHelper.enabled(Contract.FeatureName.GenerateCustomerEntityModelsCompleted)
                        ? undefined
                        : <NetworkingInfoCard>
                            {commonNetworkCardInfoItems.inboundAccessType}
                            {commonNetworkCardInfoItems.inboundExternalAccessScope}
                            {commonNetworkCardInfoItems.getVpcs()}
                            {commonNetworkCardInfoItems.getSubnets()}
                            {commonNetworkCardInfoItems.getSecurityGroups()}
                        </NetworkingInfoCard>}
            </Info>,
        topbarMenuItems:
            _.filter(menuItems) as DialogMenuItem[]
    });
}