import { CollapsedIcon, ExpandedIcon, NavigationViewItem, Tooltip } from "@infrastructure";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import _ from "lodash";
import React, { useRef } from "react";

type TitleProps = {
    className: string;
    expanded: boolean;
    item: NavigationViewItem;
    level: number;
    onClick: (element: "expandCollapse" | "title") => void;
};

export function AccordionTitle({ className, expanded, item, level, onClick }: TitleProps) {
    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            className={className}
            direction="row"
            sx={{
                padding: theme.spacing(1, 2),
                width: "100%"
            }}
            onClick={
                () =>
                    onClick(
                        _.isEmpty(item.options?.items)
                            ? "title"
                            : "expandCollapse")}>
            <Box sx={{ width: theme.spacing(level * 2) }}/>
            <Box
                sx={{
                    flex: 1,
                    overflow: "hidden"
                }}>
                {item.title}
            </Box>
            {!_.isEmpty(item.options?.items) && (
                <Box sx={{ fontSize: "18px" }}>
                    {expanded
                        ? <ExpandedIcon/>
                        : <CollapsedIcon/>}
                </Box>)}
        </Stack>);
}

export function TreeTitle({ className, expanded, item, level, onClick }: TitleProps) {
    const clickTimeoutRef = useRef<NodeJS.Timeout>();
    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            className={className}
            direction="row"
            sx={{
                padding: theme.spacing(0.5),
                paddingLeft: theme.px(getTreeTitleLevelPadding(level)),
                width: "100%"
            }}>
            <Box
                sx={{
                    color: theme.palette.text.primary,
                    fontSize: "18px",
                    width: theme.spacing(2.5)
                }}
                onClick={() => onClick("expandCollapse")}>
                {_.isEmpty(item.options?.items)
                    ? <Box sx={{ width: theme.spacing(2.5) }}/>
                    : expanded
                        ? <ExpandedIcon/>
                        : <CollapsedIcon/>}
            </Box>
            <Box
                sx={{
                    flex: 1,
                    overflow: "hidden"
                }}
                onClick={
                    event => {
                        if (!_.isNil(clickTimeoutRef.current)) {
                            clearTimeout(clickTimeoutRef.current);
                            clickTimeoutRef.current = undefined;
                        }

                        if (event.detail === 1) {
                            clickTimeoutRef.current =
                                setTimeout(
                                    () => {
                                        onClick("title");
                                    },
                                    300);
                        }
                    }}
                onDoubleClick={() => onClick("expandCollapse")}>
                <Tooltip
                    enterDelay={500}
                    enterNextDelay={500}
                    placement="right"
                    sx={{ width: "fit-content" }}
                    titleOrGetTitle={item.options?.tooltip}>
                    {_.isString(item.title)
                        ? <Typography noWrap={true}>
                            {item.title}
                        </Typography>
                        : <Box>
                            {item.title}
                        </Box>}
                </Tooltip>

            </Box>
        </Stack>);
}

export function getTreeTitleLevelPadding(level: number) {
    return 4 + level *
        (level > 0
            ? 23
            : 20);
}