import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, PagedValuesFilter, useLocalization } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, SeverityFilter, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { useGetVulnerabilitiesCsvItem } from "../../../../../../../../hooks";
import { useContainerImageDataScanStatusTranslator } from "../../../../../../hooks";
import { VulnerabilitiesCell } from "../../../../../VulnerabilitiesCell";
import { DefinitionData } from "../../../../Table";
import { EntityTableDefinition } from "../../useDefinition";
import { useCiCommonEntityDefinition } from "./useCiCommonEntityDefinition";

export function useCiContainerImageRepositoryDefinition(definitionData: DefinitionData) {
    const entitiesExportOptions = useEntitiesExportOptions();
    const commonEntityDefinition = useCiCommonEntityDefinition(definitionData);
    const containerImageScanStatusTranslator = useContainerImageDataScanStatusTranslator();
    const getVulnerabilitiesCsvItem = useGetVulnerabilitiesCsvItem();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.ci.useCiContainerImageRepositoryDefinition",
            () => ({
                columns: {
                    containerImageResources: "Used By",
                    containerImageVulnerabilities: "Vulnerabilities",
                    containerImageVulnerabilitySeverities: "Vulnerability Severity",
                    rawUrl: "URI"
                }
            }));

    const getCiContainerImageVulnerabilitiesCsvItem =
        (containerImageScanStatus?: Contract.ContainerImageDataScanStatus, vulnerabilities?: Contract.VulnerabilityData[]) => {
            if (_.isNil(containerImageScanStatus)) {
                return "";
            }

            return _.isEmpty(vulnerabilities)
                ? containerImageScanStatusTranslator(containerImageScanStatus)
                : getVulnerabilitiesCsvItem(vulnerabilities);
        };


    return new EntityTableDefinition(
        [
            commonEntityDefinition.columns.registryColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.CiContainerImageRepositoryModel) => ({
                            [localization.columns.rawUrl()]: (item.entity as Contract.CiContainerImageRepository).rawUrl
                        })
                }}
                id="rawUri"
                key="rawUri"/>,
            <DataTableColumn
                cellMinWidth={118}
                exportOptions={{
                    getItem:
                        (item: Contract.CiContainerImageRepositoryModel) => ({
                            [localization.columns.containerImageVulnerabilities()]:
                                getCiContainerImageVulnerabilitiesCsvItem(
                                    item.containerImageScanStatus,
                                    item.containerImageVulnerabilities)
                        })
                }}
                filterOptions={{
                    itemOrItems: [
                        {
                            element:
                                <PagedValuesFilter
                                    getValuePage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.CiContainerImageRepositoryContainerImageVulnerabilities)}
                                    placeholder={localization.columns.containerImageVulnerabilities()}/>,
                            id: Contract.EntityModelProperty.CiContainerImageRepositoryContainerImageVulnerabilities,
                            title: localization.columns.containerImageVulnerabilities()
                        },
                        {
                            element:
                                <SeverityFilter placeholder={localization.columns.containerImageVulnerabilitySeverities()}/>,
                            id: Contract.EntityModelProperty.CiContainerImageRepositoryContainerImageVulnerabilitySeverities,
                            title: localization.columns.containerImageVulnerabilitySeverities()
                        }
                    ]
                }}
                id={Contract.EntityModelProperty.CiContainerImageRepositoryContainerImageVulnerabilities}
                key={Contract.EntityModelProperty.CiContainerImageRepositoryContainerImageVulnerabilities}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.CiContainerImageRepositoryModel>) =>
                        <VulnerabilitiesCell
                            containerImageScanStatus={item.containerImageScanStatus}
                            vulnerabilities={item.containerImageVulnerabilities}/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.containerImageVulnerabilities()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.CiContainerImageRepositoryModel>(
                        Contract.TypeNames.IContainerImageResource,
                        item => item.containerImageResourceIds,
                        localization.columns.containerImageResources())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.CiContainerImageRepositoryContainerImageResources)}
                                placeholder={localization.columns.containerImageResources()}/>
                    }
                }}
                id={Contract.EntityModelProperty.CiContainerImageRepositoryContainerImageResources}
                key={Contract.EntityModelProperty.CiContainerImageRepositoryContainerImageResources}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.CiContainerImageRepositoryModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.containerImageResourceIds}
                            entityTypeName={Contract.TypeNames.IContainerImageResource}
                            entityVariant="iconText"/>}
                title={localization.columns.containerImageResources()}/>,
            commonEntityDefinition.columns.attributesColumn,
            commonEntityDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonEntityDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonEntityDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageCiContainerImageRepositoryRequest(
                new Contract.EntityControllerGetEntityModelPageCiContainerImageRepositoryRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.CiContainerImageRepositoryContainerImageResources]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.CiContainerImageRepositoryContainerImageVulnerabilities]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.CiContainerImageRepositoryContainerImageVulnerabilitySeverities])),
                limit,
                skip,
                requestSort));
}