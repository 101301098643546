import { useLocalization } from "@infrastructure";
import { useMemo } from "react";
import { Contract, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetDataAnalysisResourceRiskContext } from "../../../useGetDataAnalysisResourceRiskContext";
import { useGetAwsResourceRiskContext } from "./useGetAwsResourceRiskContext";

export function useGetAwsDynamoDbTableRiskContext() {
    return useMemo(
        () => useAwsDynamoDbTableRiskContext,
        []);
}

function useAwsDynamoDbTableRiskContext(tableModel: Contract.AwsDynamoDbTableModel) {
    const table = tableModel.entity as Contract.AwsDynamoDbTable;
    const dataAnalysisResourceRiskContext = useGetDataAnalysisResourceRiskContext()(tableModel);
    const resourceRiskContext = useGetAwsResourceRiskContext()(tableModel);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.contexts.useGetAwsDynamoDbTableRiskContext",
            () => ({
                storageSize: {
                    empty: "The {{translatedTableTypeName}} is empty",
                    notEmpty: "The {{translatedTableTypeName}}’s size is {{storageSize | NumberFormatter.storage}}"
                }
            }));
    const translatedTableTypeName =
        entityTypeNameTranslator(
            table.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });

    return {
        ...dataAnalysisResourceRiskContext,
        ...resourceRiskContext,
        storageSize:
            new RiskDefinitionContextItem(
                (table.storageSize > 0
                    ? localization.storageSize.notEmpty
                    : localization.storageSize.empty)({
                    storageSize: table.storageSize,
                    translatedTableTypeName
                }))
    };
}