﻿import React from "react";

export function KrIcon() {
    return (
        <svg
            fill="none"
            height={50}
            viewBox="0 0 50 50"
            width={50}>
            <path
                d="M25.1029 49.5417C38.7834 49.5417 49.8737 38.4514 49.8737 24.7708C49.8737 11.0903 38.7834 0 25.1029 0C11.4223 0 0.332031 11.0903 0.332031 24.7708C0.332031 38.4514 11.4223 49.5417 25.1029 49.5417Z"
                fill="#F0F0F0"/>
            <path
                d="M33.7201 24.7707C33.7201 26.9247 29.8626 32.3096 25.1042 32.3096C20.3458 32.3096 16.4883 26.9247 16.4883 24.7707C16.4883 20.0122 20.3458 16.1548 25.1042 16.1548C29.8626 16.1548 33.7201 20.0122 33.7201 24.7707Z"
                fill="#D80027"/>
            <path
                d="M33.7201 24.771C33.7201 29.5295 29.8626 33.3869 25.1042 33.3869C20.3458 33.3869 16.4883 29.5295 16.4883 24.771"
                fill="#0052B4"/>
            <path
                d="M36.5268 30.1016L34.2422 32.3862L35.7653 33.9093L38.0499 31.6247L36.5268 30.1016Z"
                fill="black"/>
            <path
                d="M32.7182 33.9097L30.4336 36.1943L31.9567 37.7174L34.2413 35.4328L32.7182 33.9097Z"
                fill="black"/>
            <path
                d="M41.0971 34.67L38.8125 36.9546L40.3356 38.4777L42.6202 36.1931L41.0971 34.67Z"
                fill="black"/>
            <path
                d="M37.2885 38.4786L35.0039 40.7632L36.527 42.2863L38.8116 40.0017L37.2885 38.4786Z"
                fill="black"/>
            <path
                d="M38.8119 32.3863L36.5273 34.6709L38.0504 36.194L40.335 33.9094L38.8119 32.3863Z"
                fill="black"/>
            <path
                d="M35.0034 36.1939L32.7188 38.4785L34.2418 40.0016L36.5264 37.717L35.0034 36.1939Z"
                fill="black"/>
            <path
                d="M32.7202 11.0625L38.8125 17.1548L40.3356 15.6317L34.2433 9.53939L32.7202 11.0625Z"
                fill="black"/>
            <path
                d="M30.4341 13.3477L32.7188 15.6323L34.2418 14.1093L31.9572 11.8247L30.4341 13.3477Z"
                fill="black"/>
            <path
                d="M34.2427 17.1553L36.5273 19.4399L38.0504 17.9169L35.7658 15.6323L34.2427 17.1553Z"
                fill="black"/>
            <path
                d="M35.0006 8.77838L37.2852 11.063L38.8082 9.5399L36.5236 7.25529L35.0006 8.77838Z"
                fill="black"/>
            <path
                d="M38.8131 12.5865L41.0977 14.8711L42.6207 13.348L40.3361 11.0634L38.8131 12.5865Z"
                fill="black"/>
            <path
                d="M15.2017 40.7637L9.10938 34.6714L7.58628 36.1945L13.6786 42.2868L15.2017 40.7637Z"
                fill="black"/>
            <path
                d="M17.4877 38.478L15.2031 36.1934L13.6801 37.7164L15.9647 40.001L17.4877 38.478Z"
                fill="black"/>
            <path
                d="M13.6791 34.6708L11.3945 32.3862L9.87144 33.9093L12.156 36.1939L13.6791 34.6708Z"
                fill="black"/>
            <path
                d="M19.772 36.1939L13.6797 30.1016L12.1566 31.6247L18.2489 37.717L19.772 36.1939Z"
                fill="black"/>
            <path
                d="M9.11083 14.8706L15.2031 8.77832L13.68 7.25523L7.58773 13.3475L9.11083 14.8706Z"
                fill="black"/>
            <path
                d="M11.396 17.1543L17.4883 11.062L15.9652 9.53892L9.87289 15.6312L11.396 17.1543Z"
                fill="black"/>
            <path
                d="M13.6811 19.44L19.7734 13.3477L18.2504 11.8246L12.1581 17.9169L13.6811 19.44Z"
                fill="black"/>
        </svg>);
}