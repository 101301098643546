import { useMemo } from "react";
import { useGcpArtifactContainerImageRepositoryDefinition, useGcpArtifactPackageRepositoryDefinition, useGcpComputeLoadBalancerDefinition, useGcpContainerImageDefinition, useGcpIamPartialServiceAccountDefinition, useGcpIamRoleBindingDefinition, useGcpResourceDefinition, useGcpUnmanagedKubernetesClusterDefinition } from ".";
import { Contract } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";

export function useGcpEntityDefinition(definitionData: DefinitionData) {
    const useDefinition =
        useMemo(
            () => {
                switch (definitionData.entityTypeEntitiesViewName) {
                    case Contract.TypeNames.GcpArtifactContainerImageRepository:
                        return useGcpArtifactContainerImageRepositoryDefinition;
                    case Contract.TypeNames.GcpArtifactPackageRepository:
                        return useGcpArtifactPackageRepositoryDefinition;
                    case Contract.TypeNames.GcpComputeLoadBalancer:
                        return useGcpComputeLoadBalancerDefinition;
                    case Contract.TypeNames.GcpContainerImage:
                        return useGcpContainerImageDefinition;
                    case Contract.TypeNames.GcpIamPartialServiceAccount:
                        return useGcpIamPartialServiceAccountDefinition;
                    case Contract.TypeNames.GcpIamRoleBinding:
                        return useGcpIamRoleBindingDefinition;
                    case Contract.TypeNames.GcpUnmanagedKubernetesCluster:
                        return useGcpUnmanagedKubernetesClusterDefinition;
                    default:
                        return useGcpResourceDefinition;
                }
            },
            []);

    return useDefinition(definitionData);
}