import { useLocalization } from "@infrastructure";
import _ from "lodash";
import { Contract, entityModelStore, useEntityTypeNameTranslator } from "../../../../../../../../../../../../../common";

export function useGetVirtualMachineTerminationResolutionStep(risk: Contract.VirtualMachineWorkloadAnalysisRisk) {
    const entityTypenameTranslator = useEntityTypeNameTranslator();
    const virtualMachineModel = entityModelStore.useGet(_.first(risk.virtualMachineIds))!;
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.workloadAnalysis.hooks.useWorkloadAnalysisDefinition.hooks.useGetVirtualMachineTerminationResolutionStep",
            () => ({
                many: "Terminate these {{virtualMachineTypeName}}",
                single: "Terminate this {{virtualMachineTypeName}}"
            }));

    return () =>
        risk.singleVirtualMachine
            ? localization.single({
                virtualMachineTypeName:
                    entityTypenameTranslator(
                        virtualMachineModel.entity.typeName,
                        {
                            variant: "text"
                        })
            })
            : localization.many({
                virtualMachineTypeName:
                    entityTypenameTranslator(
                        virtualMachineModel.entity.typeName,
                        {
                            count: 0,
                            variant: "text"
                        })
            });
}