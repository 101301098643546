import { CircularProgress, Stack } from "@mui/material";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import { ActionMenuItem, DeleteIcon, EditIcon, Menu, MenuItem, Message, MoveIcon, Optional, useLocalization } from "@infrastructure";
import { ConfigurationController, Contract, FolderController, InlineScopes, InlineTenants, Scope, ScopeHelper, scopeNodeModelStore, TenantHelper, tenantModelStore, UserHelper, useSelectedScopeId, useTheme } from "../../../../../common";
import { TenantsDefinition, useMoveEnabled } from "../hooks";
import { ScopesViewType } from "../utilities";
import { MoveDialog } from "./MoveDialog";

type ActionsCellProps = {
    definition: TenantsDefinition;
    isProject?: boolean;
    item: Contract.ScopeNodeModel;
    onDelete?: (id: string) => void;
    onEditClick?: (item: Contract.ScopeNodeModel) => void;
    view: ScopesViewType;
};

export function ActionsCell({ definition, isProject = false, item, onDelete, onEditClick, view }: ActionsCellProps) {
    const configurationTenantTypes =
        useMemo(
            () => TenantHelper.ConfigurationTenantTypes,
            []);
    const scopeNodeMap =
        scopeNodeModelStore.useGetScopeNodeMap(
            isProject
                ? undefined
                : configurationTenantTypes,
            isProject);

    const { selectedScopeId } = useSelectedScopeId();

    const deleteScopeDisabledMessage =
        useMemo(
            () => definition.getDeleteScopeDisabledMessage?.(false, item),
            []);

    const parentScopeNodeModel =
        useMemo(
            () => scopeNodeMap[ScopeHelper.tryGetParentScopeId(item) ?? item.configuration.id].scopeNodeModel,
            [item, scopeNodeMap]);

    const { enabled: moveEnabled, tooltip: moveEnabledTooltip } = useMoveEnabled([item.configuration.id], scopeNodeMap, parentScopeNodeModel);

    const [deleteFolderDisabled, scopeIds] =
        useMemo(
            () => {
                const childScopeIds = scopeNodeMap[item.configuration.id]?.childScopeIds;
                const deleteFolderDisabled = ScopeHelper.isFolder(item) && !_.isEmpty(childScopeIds);
                return [deleteFolderDisabled, childScopeIds];
            },
            [scopeNodeMap]);

    const [deleteScopeExecuting, setDeleteScopeExecuting] = useState(false);
    const [deleteScopeError, setDeleteScopeError] = useState(false);
    const [moveOpen, setMoveOpen] = useState(false);

    async function deleteScope() {
        setDeleteScopeExecuting(true);
        setDeleteScopeError(false);
        try {
            if (ScopeHelper.isFolder(item)) {
                await FolderController.deleteFolder(new Contract.FolderControllerDeleteFolderRequest(item.configuration.id));
                await scopeNodeModelStore.notifyDeleted(scopeNodeMap[item.configuration.id].scopeIds);
                onDelete?.(item.configuration.id);
            } else if (item.type === Contract.ScopeType.Project) {
                await ConfigurationController.deleteProject(new Contract.ConfigurationControllerDeleteProjectRequest(item.configuration.id));
                await scopeNodeModelStore.notifyDeleted(scopeNodeMap[item.configuration.id].scopeIds);
                window.location.hash = "";
                onDelete?.(item.configuration.id);
            } else {
                await tenantModelStore.updateDeleted(item.configuration.id);
            }
        } catch (error) {
            setDeleteScopeError(true);
        }
        setDeleteScopeExecuting(false);
    }

    const localization =
        useLocalization(
            "views.customer.scopes.actionsCell",
            () => ({
                actions: {
                    delete: {
                        disabledTitle: "OK",
                        folder: {
                            disabled: "This folder contains {{scopes}} in sub folders. Please delete those {{scopeType}} before you can delete the entire folder",
                            error: "Failed to delete folder",
                            foldersPluralizer: [
                                "1 folder",
                                "{{count | NumberFormatter.humanize}} folders"
                            ],
                            projectsPluralizer: [
                                "1 project",
                                "{{count | NumberFormatter.humanize}} projects"
                            ],
                            prompt: "Are you sure you want to delete the folder?"
                        },
                        scopeSelected: "This {{scopeType}} can't be deleted because it's selected in the scope dropdown. Choose a different scope and try again.",
                        scopeType: {
                            [Contract.TenantType.Aws]: [
                                "account",
                                "accounts"
                            ],
                            [Contract.TenantType.Azure]: [
                                "subscription",
                                "subscriptions"
                            ],
                            [Contract.TenantType.Code]: [
                                "repository",
                                "repositories"
                            ],
                            [Contract.TenantType.Ci]: [
                                "registry",
                                "registries"
                            ],
                            [Contract.TenantType.Gcp]: [
                                "project",
                                "projects"
                            ],
                            [Contract.TenantType.Oci]: [
                                "compartment",
                                "compartments"
                            ],
                            [Contract.TenantType.Op]: [
                                "account",
                                "accounts"
                            ],
                            [Contract.ScopeType.Project]: [
                                "project",
                                "projects"
                            ],
                            folder: [
                                "folder",
                                "folders"
                            ]
                        },
                        [Contract.ScopeType.CiTenant]: {
                            error: "Failed to delete registry",
                            prompt: "Are you sure you want to delete the registry?"
                        },
                        [Contract.ScopeType.CloudProviderTenant]: {
                            error: "Failed to delete account",
                            prompt: "Are you sure you want to delete the account {{accountName}}?"
                        },
                        [Contract.ScopeType.CodeTenant]: {
                            error: "Failed to delete repository",
                            prompt: "Are you sure you want to delete the repository {{accountName}}?"
                        },
                        text: "Delete",
                        [Contract.ScopeType.Project]: {
                            error: "Failed to delete project",
                            prompt: "Are you sure you want to delete the project?"
                        }
                    },
                    edit: "Edit",
                    move: "Move"
                }
            }));

    const scopeSelected = item.configuration.id === selectedScopeId;

    const deleteDisabledTooltip =
        useMemo(
            () => {
                if (!_.isNil(deleteScopeDisabledMessage)) {
                    return deleteScopeDisabledMessage;
                } else if (deleteFolderDisabled) {
                    const onlyFolders =
                        _.every(
                            scopeIds,
                            scopeId => ScopeHelper.isFolder(scopeNodeMap[scopeId].scopeNodeModel));
                    return localization.actions.delete.folder.disabled({
                        scopes:
                            isProject || onlyFolders
                                ? <InlineScopes
                                    namePluralizer={
                                        onlyFolders
                                            ? localization.actions.delete.folder.foldersPluralizer
                                            : localization.actions.delete.folder.projectsPluralizer}
                                    scopeIds={scopeIds}
                                    scopeVariant="iconText"
                                    variant="itemCountAndType"/>
                                : <InlineTenants
                                    tenantIds={scopeIds}
                                    tenantVariant="iconText"
                                    variant="itemCountAndType"/>,
                        scopeType:
                            localization.actions.delete.scopeType[
                                onlyFolders
                                    ? "folder"
                                    : view](0)
                    });
                } else if (scopeSelected) {
                    return localization.actions.delete.scopeSelected({ scopeType: localization.actions.delete.scopeType[view](1) });
                }

                return undefined;
            },
            []);

    const menuItems =
        _<MenuItem>([]).
            concatIf(
                !_.isNil(onEditClick) &&
                (ScopeHelper.isFolder(item)
                    ? !(item.configuration as Optional<Contract.FolderConfiguration>)?.managed
                    : (item.type === Contract.ScopeType.Project || (ScopeHelper.isTenant(item) && !definition.editTenantDisabled)) &&
                    !item.configuration.systemDeleted) &&
                UserHelper.hasScopePermissions(item.configuration.id, Contract.IdentityPermission.SecurityAdministrationRead),
                new ActionMenuItem(
                    () => onEditClick!(item),
                    localization.actions.edit(),
                    {
                        disabled: deleteScopeExecuting,
                        icon: <EditIcon/>
                    })).
            concatIf(
                moveEnabled || !_.isEmpty(moveEnabledTooltip),
                new ActionMenuItem(
                    () => setMoveOpen(true),
                    localization.actions.move(),
                    {
                        disabled: deleteScopeExecuting || !moveEnabled,
                        icon: <MoveIcon/>,
                        tooltip: moveEnabledTooltip
                    })).
            concatIf(
                (ScopeHelper.isFolder(item) ||
                    item.type === Contract.ScopeType.Project ||
                    ScopeHelper.isTenant(item) && !definition.deleteTenantDisabled) &&
                !item.configuration.systemDeleted &&
                UserHelper.hasScopePermissions(item.configuration.id, Contract.IdentityPermission.SecurityAdministrationRead),
                new ActionMenuItem(
                    () => deleteScope(),
                    localization.actions.delete.text(),
                    {
                        confirmOptions: {
                            message:
                                (ScopeHelper.isFolder(item)
                                    ? localization.actions.delete.folder.prompt
                                    : localization.actions.delete[item.type as Exclude<Contract.ScopeType, Contract.ScopeType.Customer | Contract.ScopeType.IdentityProviderTenant | Contract.ScopeType.ProjectFolder | Contract.ScopeType.Folder>].prompt)({
                                    accountName:
                                        <Scope
                                            colored={false}
                                            scopeId={item.configuration.id}
                                            sx={{
                                                display: "inline-block",
                                                fontSize: "22px",
                                                fontWeight: 700,
                                                maxWidth: "90%",
                                                verticalAlign: "bottom"
                                            }}
                                            variant="text"/>
                                })
                        },
                        disabled: deleteFolderDisabled || !_.isNil(deleteScopeDisabledMessage) || scopeSelected,
                        icon: <DeleteIcon/>,
                        tooltip: deleteDisabledTooltip
                    })).
            value();

    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            direction="row"
            justifyContent="flex-end"
            spacing={1.5}>
            {deleteScopeExecuting && (
                <CircularProgress
                    size={theme.spacing(2)}
                    variant="indeterminate"/>)}
            {deleteScopeError && (
                <Message
                    level="error"
                    title={localization.actions.delete.folder.error()}
                    variant="minimal"/>)}
            {moveOpen &&
                <MoveDialog
                    parentScopeNodeModel={parentScopeNodeModel}
                    scopeIds={[item.configuration.id]}
                    scopeNodeMap={scopeNodeMap}
                    onClose={() => setMoveOpen(false)}/>}
            {!_.isEmpty(menuItems) &&
                <Menu
                    disabled={deleteScopeExecuting}
                    itemsOrGetItems={menuItems}/>}
        </Stack>);
}