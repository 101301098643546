import { Action0, ActionButton, MoveIcon, Tooltip, useLocalization } from "@infrastructure";
import { Box, Button, Typography } from "@mui/material";
import { Dictionary } from "lodash";
import React, { Fragment, useState } from "react";
import { MoveDialog } from "../../..";
import { Contract, ScopeNode, useTheme } from "../../../../../../../../common";

type MoveButtonProps = {
    disabled?: boolean;
    onClick?: Action0;
    onClose?: Action0;
    onExecute?: Action0;
    scopeIds: string[];
    scopeNodeMap: Dictionary<ScopeNode>;
    scopeNodeModel: Contract.ScopeNodeModel;
    tooltip?: string;
    variant: "action" | "selectionAction";
};

export function MoveButton({ disabled, onClick, onClose, onExecute, scopeIds, scopeNodeMap, scopeNodeModel, tooltip, variant }: MoveButtonProps) {
    const [open, setOpen] = useState(false);
    const localization =
        useLocalization(
            "views.customer.scopes.table.moveButton",
            () => ({
                title: "Move"
            }));

    const theme = useTheme();
    return (
        <Fragment>
            {open &&
                <MoveDialog
                    parentScopeNodeModel={scopeNodeModel}
                    scopeIds={scopeIds}
                    scopeNodeMap={scopeNodeMap}
                    onClose={
                        () => {
                            onClose?.();
                            setOpen(false);
                        }}
                    onExecute={onExecute}/>}
            {variant === "action"
                ? <ActionButton
                    disabled={disabled}
                    tooltip={tooltip}
                    onClick={
                        () => {
                            onClick?.();
                            setOpen(true);
                        }}>
                    <MoveIcon/>
                </ActionButton>
                : <Tooltip titleOrGetTitle={tooltip}>
                    <Box>
                        <Button
                            disabled={disabled}
                            startIcon={<MoveIcon/>}
                            sx={{
                                "& .MuiButton-startIcon": {
                                    marginLeft: 0,
                                    marginRight: theme.spacing(0.75)
                                },
                                "& .MuiButton-startIcon > :nth-of-type(1)": {
                                    fontSize: "16px"
                                },
                                "&.Mui-disabled": {
                                    backgroundColor: "unset",
                                    color: theme.palette.text.disabled
                                },
                                ":hover": {
                                    backgroundColor: theme.palette.sidebar.hover,
                                    textDecoration: "none"
                                },
                                width: "100%"
                            }}
                            variant="text"
                            onClick={
                                () => {
                                    onClick?.();
                                    setOpen(true);
                                }}>
                            <Typography
                                noWrap={true}
                                sx={{ fontWeight: 500 }}>
                                {localization.title()}
                            </Typography>
                        </Button>
                    </Box>
                </Tooltip>}
        </Fragment>);
}