import _ from "lodash";
import { Contract } from "../../../../../controllers";
import { EntityDefinition } from "../useDefinition";

export function useGciGcpResourceDefinition(entityModel: Contract.EntityModel) {
    const gciParentResourceData = _.as<Contract.IGciGcpResourceModel>(entityModel).gciParentResourceData!;
    return new EntityDefinition(
        entityModel,
        {
            showTenant: false,
            subtitleAdditionalAttributes: [
                `${gciParentResourceData.displayName} (${gciParentResourceData.rawShortId})`
            ]
        });
}