import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, entityModelStore, TypeHelper, useEntityTypeNameTranslator, usePrincipalModelAccess } from "../../../../../../..";
import { Entity } from "../../../../../..";

export function useAwsDeleteRoleChangeDefinition(change: Contract.AwsChange, entityLinkDisabled?: boolean) {
    const deleteRoleChange = change as Contract.AwsDeleteRoleChange;
    const roleModelAccess = usePrincipalModelAccess<Contract.ServiceIdentityModelAccess>(deleteRoleChange.resourceId);
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const originatorEntityModels = entityModelStore.useGet(roleModelAccess.originatorEntityIds);

    const originatorWorkloadResources =
        useMemo(
            () =>
                _.filter(
                    originatorEntityModels,
                    originatorEntityModel =>
                        TypeHelper.extendOrImplement(
                            originatorEntityModel.entity.typeName,
                            Contract.TypeNames.IAwsOriginatorWorkloadResource)),
            [roleModelAccess]);

    const localization =
        useLocalization(
            "common.change.hooks.useDefinition.hooks.useAwsDefinition.hooks.useAwsDeleteRoleChangeDefinition",
            () => ({
                title: {
                    withoutWarning: "Delete {{role}}",
                    withWarning: "Delete {{role}} (may prevent linked {{originatorWorkloadResourceTypeName}} from restarting)"
                }
            }));
    return {
        title:
            (_.isEmpty(originatorWorkloadResources)
                ? localization.title.withoutWarning
                : localization.title.withWarning)({
                originatorWorkloadResourceTypeName:
                    entityTypeNameTranslator(
                        Contract.TypeNames.IAwsOriginatorWorkloadResource,
                        {
                            count: originatorWorkloadResources.length,
                            includeServiceName: false,
                            variant: "text"
                        }),
                role:
                    <Entity
                        entityIdOrModel={deleteRoleChange.resourceId}
                        entityTypeNameTranslatorOptions={{ variant: "text" }}
                        glanceOptions={{ disabled: true }}
                        linkOptions={{ disabled: entityLinkDisabled }}
                        variant="typeText"/>
            })
    };
}