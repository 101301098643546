import { Steps } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useOpenRiskedEntityRisksStep } from "../../../../../../..";
import { Contract, entityModelStore, useEntityTypeNameTranslator } from "../../../../../../../../../../../../../../../../common";
import { useResourceGeneralInformationStep } from "../../../../../../../useResourceGeneralInformationStep";
import { useInformationSteps } from "../../useInformationSteps";

export type ContextSectionProps = {
    riskModel: Contract.AwsInboundExternalEc2InstanceRiskModel;
};

export function ContextSection({ riskModel }: ContextSectionProps) {
    const launchTemplateModel = entityModelStore.useGet(riskModel.risk.entityId) as Contract.AwsEc2LaunchTemplateModel;

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const contextSentences =
        useInformationSteps(
            riskModel,
            entityTypeNameTranslator(
                launchTemplateModel.entity.typeName,
                {
                    includeServiceName: false,
                    variant: "text"
                }));
    const openRiskedEntityRisksStep = useOpenRiskedEntityRisksStep(launchTemplateModel, riskModel.id);
    const resourceGeneralInformationStep = useResourceGeneralInformationStep(launchTemplateModel);

    return (
        <Steps variant="bullets">
            {_.filter([
                resourceGeneralInformationStep,
                ...contextSentences,
                openRiskedEntityRisksStep
            ])}
        </Steps>);
}