import { DataTableColumn, DataTableColumnRenderProps, EnumValuesFilter, useLocalization } from "@infrastructure";
import { Dictionary } from "lodash";
import React from "react";
import { useAwsCommonAccessResourceDefinition, useAwsCommonResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { useAwsApiGatewayApiEndpointTypeTranslator, useAwsApiGatewayApiTypeTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";

export function useAwsApiGatewayApiDefinition(definitionData: DefinitionData) {
    const commonAccessResourceDefinition = useAwsCommonAccessResourceDefinition(definitionData);
    const commonResourceDefinition = useAwsCommonResourceDefinition(definitionData);

    const apiGatewayApiEndpointTypeTranslator = useAwsApiGatewayApiEndpointTypeTranslator();
    const apiGatewayApiTypeTranslator = useAwsApiGatewayApiTypeTranslator();
    const entitiesExportOptions = useEntitiesExportOptions();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.aws.useAwsApiGatewayApiDefinition",
            () => ({
                columns: {
                    endpointType: "Endpoint",
                    type: "Protocol",
                    wafWebAcls: {
                        title: "Associated WAF web ACL",
                        warning: "Only applicable for APIs that use the REST protocol"
                    }
                }
            }));

    return new EntityTableDefinition(
        [
            commonResourceDefinition.columns.tenantColumn,
            commonResourceDefinition.columns.creationTimeColumn,
            commonResourceDefinition.columns.creatorIdentityCsvColumn,
            commonResourceDefinition.columns.creatorOriginatorEntityCsvColumn,
            commonAccessResourceDefinition.columns.accessLevelColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsApiGatewayApiModel) => ({
                            [localization.columns.type()]: apiGatewayApiTypeTranslator((item.entity as Contract.AwsApiGatewayApi).type)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.AwsApiGatewayApiType}
                                enumTypeTranslator={apiGatewayApiTypeTranslator}
                                placeholder={localization.columns.type()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AwsApiGatewayApiType}
                itemProperty={(item: Contract.AwsApiGatewayApiModel) => apiGatewayApiTypeTranslator((item.entity as Contract.AwsApiGatewayApi).type)}
                key={Contract.EntityModelProperty.AwsApiGatewayApiType}
                title={localization.columns.type()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsApiGatewayApiModel) => ({
                            [localization.columns.endpointType()]: apiGatewayApiEndpointTypeTranslator((item.entity as Contract.AwsApiGatewayApi).endpointType)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.AwsApiGatewayApiEndpointType}
                                enumTypeTranslator={apiGatewayApiEndpointTypeTranslator}
                                placeholder={localization.columns.endpointType()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AwsApiGatewayApiEndpointType}
                itemProperty={(item: Contract.AwsApiGatewayApiModel) => apiGatewayApiEndpointTypeTranslator((item.entity as Contract.AwsApiGatewayApi).endpointType)}
                key={Contract.EntityModelProperty.AwsApiGatewayApiEndpointType}
                title={localization.columns.endpointType()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.AwsApiGatewayApiModel>(
                        Contract.TypeNames.AwsWafWebAcl,
                        item =>
                            item.typeName === Contract.TypeNames.AwsApiGatewayV1ApiModel
                                ? (item as Contract.AwsApiGatewayV1ApiModel).wafWebAclIdReferences
                                : [],
                        localization.columns.wafWebAcls.title())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AwsApiGatewayApiWafWebAcls)}
                                placeholder={localization.columns.wafWebAcls.title()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AwsApiGatewayApiWafWebAcls}
                key={Contract.EntityModelProperty.AwsApiGatewayApiWafWebAcls}
                message={localization.columns.wafWebAcls.warning()}
                messageLevel="warning"
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AwsApiGatewayApiModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={
                                item.typeName === Contract.TypeNames.AwsApiGatewayV1ApiModel
                                    ? (item as Contract.AwsApiGatewayV1ApiModel).wafWebAclIdReferences
                                    : []}
                            entityTypeName={Contract.TypeNames.AwsWafWebAcl}
                            entityVariant="iconText"/>}
                selectorOptions={{ default: false }}
                title={localization.columns.wafWebAcls.title()}/>,
            commonResourceDefinition.columns.regionColumn,
            commonResourceDefinition.columns.regionLocationColumn,
            commonResourceDefinition.columns.tagsColumn,
            commonResourceDefinition.columns.attributesColumn,
            commonResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAwsApiGatewayApiRequest(
                new Contract.EntityControllerGetEntityModelPageAwsApiGatewayApiRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsApiGatewayApiEndpointType]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsApiGatewayApiType]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsApiGatewayApiWafWebAcls])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags])),
                new Contract.EntityControllerGetEntityModelPageAwsResourceRequestAccess(ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsResourceAccessLevel]))));
}