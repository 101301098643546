import { ActionMenuItem, Dialog, useLocalization, useSetRoute } from "@infrastructure";
import _ from "lodash";
import React, { Fragment, useCallback, useMemo, useState } from "react";
import { useScopesContext, useSetScopesContext } from "../../..";
import { Contract, CustomerConsoleAppUrlHelper, ScopeHelper, ScopeNavigationView, scopeNodeModelStore, scopeSystemEntityModelStore } from "../../../../../../common";
import { AddOrEditFolder, ScopeTooltip, Table } from "../../components";
import { ScopesView } from "../../utilities";
import { Welcome } from "./components";

export function CiTenants() {
    const ciIntegrationModels = scopeSystemEntityModelStore.useGetCiIntegration();
    const scopeNodeModels = scopeNodeModelStore.useGetAll();
    const { addOrEditOpen } = useScopesContext();
    const setScopesContext = useSetScopesContext();
    const tenantTypeRootFolderScopeNodeModel =
        useMemo(
            () =>
                _.find(
                    scopeNodeModels,
                    scopeNodeModel =>
                        ScopeHelper.isRootFolder(scopeNodeModel) &&
                        (scopeNodeModel.configuration as Contract.FolderConfiguration).tenantType === Contract.TenantType.Ci)!,
            [scopeNodeModels]);
    const [parentFolderId, setParentFolderId] = useState<string>(tenantTypeRootFolderScopeNodeModel.configuration.id);

    const onEditClick =
        useCallback(
            (scopeNodeModel: Contract.ScopeNodeModel) => {
                setScopesContext(
                    context => ({
                        ...context,
                        addOrEditOpen: scopeNodeModel
                    }));
            },
            [setScopesContext]);

    const onScopeChanged =
        useCallback(
            (scopeNodeModel: Contract.ScopeNodeModel) => setParentFolderId(scopeNodeModel.configuration.id),
            []);
    const localization =
        useLocalization(
            "views.customer.scopes.ciTenants",
            () => ({
                addFolder: "Add folder",
                title: {
                    [Contract.CiTenantType.Cloudsmith]: "Add Cloudsmith Organization",
                    [Contract.CiTenantType.General]: "Add Container Registry",
                    [Contract.CiTenantType.Jfrog]: "Add JFrog Organization"
                }
            }));

    const setRoute = useSetRoute();

    const ciTenantTypes =
        useMemo(
            () => [Contract.TenantType.Ci],
            []);
    const menuItems =
        useMemo(
            () =>
                _<ActionMenuItem>([]).
                    concat(
                        _.map(
                            [
                                Contract.CiTenantType.General,
                                Contract.CiTenantType.Jfrog,
                                Contract.CiTenantType.Cloudsmith
                            ],
                            ciTenantType =>
                                new ActionMenuItem(
                                    () => setRoute(CustomerConsoleAppUrlHelper.getConfigurationIntegrationsCiIntegrationRelativeUrl(ciTenantType, true)),
                                    localization.title.translate(ciTenantType)))).
                    concat(
                        new ActionMenuItem(
                            () =>
                                setScopesContext(
                                    context => ({
                                        ...context,
                                        addOrEditOpen: "folder"
                                    })),
                            localization.addFolder())).
                    value(),
            [localization, setScopesContext]);

    const onClose =
        useCallback(
            () => {
                setScopesContext(
                    context => ({
                        ...context,
                        addOrEditOpen: false
                    }));
            },
            []);

    return (
        <Fragment>
            {addOrEditOpen !== false &&
                <Dialog
                    variant="editor"
                    onClose={onClose}>
                    <AddOrEditFolder
                        folderScopeNodeModel={
                            addOrEditOpen === "folder"
                                ? undefined
                                : addOrEditOpen as Contract.ScopeNodeModel}
                        parentFolderId={parentFolderId}
                        onSave={onClose}/>
                </Dialog>}
            {_.isEmpty(ciIntegrationModels)
                ? <Welcome/>
                : <ScopeNavigationView
                    getItemTooltip={scopeNode => <ScopeTooltip scopeNode={scopeNode}/>}
                    hideNonPermittedScopes={true}
                    identityPermissions={[Contract.IdentityPermission.SecurityAdministrationRead]}
                    layout="view"
                    rootFolderId={tenantTypeRootFolderScopeNodeModel.configuration.id}
                    templatePath={`${CustomerConsoleAppUrlHelper.getScopesRelativeUrl(ScopesView.Ci)}/{scopeId}`}
                    tenantTypes={ciTenantTypes}
                    variant="folders"
                    onScopeChanged={onScopeChanged}>
                    {scopeNodeModel =>
                        <Table
                            key={scopeNodeModel.configuration.id}
                            menuItems={menuItems}
                            onEditClick={onEditClick}/>}
                </ScopeNavigationView>}
        </Fragment>);
}