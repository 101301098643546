﻿import { Cache, useExecuteOperation } from "@infrastructure";
import _, { Dictionary } from "lodash";
import { Contract, RiskController } from "..";

const getComplianceSectionDatasResultCache = new Cache<ComplianceCacheData>();
type ComplianceCacheData = {
    customComplianceSectionDatasMap: Dictionary<Contract.ComplianceSectionData>;
    disabledBuiltInComplianceSectionDatasMap: Dictionary<Contract.ComplianceSectionData>;
    enabledBuiltInComplianceSectionDatasMap: Dictionary<Contract.ComplianceSectionData>;
    response: Contract.RiskControllerGetComplianceSectionDatasResponse;
};

export function clearComplianceSectionDatasCache() {
    return getComplianceSectionDatasResultCache.removeAll();
}

export function useComplianceSectionDatas(scopeId: string, options?: ComplianceSectionDatasOptions): ComplianceData {
    const childScopeCustomCompliance = options?.childScopeCustomCompliance ?? false;
    const timeFrameData = options?.timeFrameData ?? false;
    const key = `${scopeId}_${timeFrameData}_${childScopeCustomCompliance}`;

    const [{ customComplianceSectionDatasMap, disabledBuiltInComplianceSectionDatasMap, enabledBuiltInComplianceSectionDatasMap, response }, executeGetComplianceSectionDatas] =
        useExecuteOperation(
            [useComplianceSectionDatas, key],
            async () => {
                if (!timeFrameData && getComplianceSectionDatasResultCache.has(`${scopeId}_true_${childScopeCustomCompliance}`)) {
                    return getComplianceSectionDatasResultCache.get(`${scopeId}_true_${childScopeCustomCompliance}`)!;
                }

                if (!getComplianceSectionDatasResultCache.has(key)) {
                    const getComplianceSectionDatasResponse =
                        await RiskController.getComplianceSectionDatas(
                            new Contract.RiskControllerGetComplianceSectionDatasRequest(
                                childScopeCustomCompliance,
                                scopeId,
                                timeFrameData));

                    getComplianceSectionDatasResultCache.set(
                        key,
                        {
                            customComplianceSectionDatasMap:
                                _.keyBy(
                                    getComplianceSectionDatasResponse.customComplianceSectionDatas,
                                    customComplianceSectionData => customComplianceSectionData.identifier),
                            disabledBuiltInComplianceSectionDatasMap:
                                _.keyBy(
                                    getComplianceSectionDatasResponse.disabledBuiltInComplianceSectionDatas,
                                    disabledBuiltInComplianceSectionData => disabledBuiltInComplianceSectionData.identifier),
                            enabledBuiltInComplianceSectionDatasMap:
                                _.keyBy(
                                    getComplianceSectionDatasResponse.enabledBuiltInComplianceSectionDatas,
                                    enabledBuiltInComplianceSectionData => enabledBuiltInComplianceSectionData.identifier),
                            response: getComplianceSectionDatasResponse
                        });
                }

                return getComplianceSectionDatasResultCache.get(key)!;
            });

    function refreshComplianceSectionDatas() {
        clearComplianceSectionDatasCache();
        return executeGetComplianceSectionDatas();
    }

    return {
        complianceSectionIdentifierToTopRiskPolicyDatasMap: response.complianceSectionIdentifierToTopRiskPolicyDatasMap,
        customComplianceSectionDatas: response.customComplianceSectionDatas,
        customComplianceSectionDatasMap,
        disabledBuiltInComplianceSectionDatas: response.disabledBuiltInComplianceSectionDatas,
        disabledBuiltInComplianceSectionDatasMap,
        enabledBuiltInComplianceSectionDatas: response.enabledBuiltInComplianceSectionDatas,
        enabledBuiltInComplianceSectionDatasMap,
        refreshComplianceSectionDatas
    };
}

export type ComplianceData = {
    complianceSectionIdentifierToTopRiskPolicyDatasMap: Dictionary<Contract.RiskControllerGetComplianceSectionDatasResponseRiskPolicyData[]>;
    customComplianceSectionDatas: Contract.ComplianceSectionData[];
    customComplianceSectionDatasMap: Dictionary<Contract.ComplianceSectionData>;
    disabledBuiltInComplianceSectionDatas: Contract.ComplianceSectionData[];
    disabledBuiltInComplianceSectionDatasMap: Dictionary<Contract.ComplianceSectionData>;
    enabledBuiltInComplianceSectionDatas: Contract.ComplianceSectionData[];
    enabledBuiltInComplianceSectionDatasMap: Dictionary<Contract.ComplianceSectionData>;
    refreshComplianceSectionDatas: () => Promise<void>;
};

type ComplianceSectionDatasOptions = {
    childScopeCustomCompliance?: boolean;
    timeFrameData?: boolean;
};