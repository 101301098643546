﻿import { ConfirmDialog, Sx, Tooltip, useTrackAnalytics } from "@infrastructure";
import { Box, MenuItem as MuiMenuItem, Stack, SxProps, Typography, useTheme } from "@mui/material";
import _ from "lodash";
import React, { Fragment, useState } from "react";
import { ActionMenuItem } from "../Menu";

type ActionMenuProps = {
    item: ActionMenuItem;
    onClose: () => void;
    onConfirmDialogOpen?: () => void;
    sx?: SxProps;
};

export function ActionMenu({ item: { onClick, options, title }, onClose, onConfirmDialogOpen, sx }: ActionMenuProps) {
    const [confirmOpen, setConfirmOpen] = useState(false);
    const trackAnalytics = useTrackAnalytics();
    const theme = useTheme();
    return (
        <Fragment>
            {!_.isNil(options?.confirmOptions) &&
                confirmOpen &&
                <ConfirmDialog
                    cancelTitle={options!.confirmOptions.cancelTitle}
                    descriptionElement={options!.confirmOptions.descriptionElement}
                    message={options!.confirmOptions.message}
                    messageElement={options!.confirmOptions.messageElement}
                    okTitles={options!.confirmOptions.okTitles}
                    onClose={
                        result => {
                            if (result === true) {
                                onClick();

                                if (options?.analyticsOptions?.onClick) {
                                    trackAnalytics(options.analyticsOptions.onClick);
                                }
                            }

                            onClose();
                            setConfirmOpen(false);
                        }}/>}
            <Tooltip
                slotProps={{ popper: { placement:"left" } }}
                titleOrGetTitle={options?.tooltip}>
                <Box>
                    <MuiMenuItem
                        disabled={options?.disabled}
                        selected={options?.selected}
                        sx={{ padding: theme.spacing(1, 1.5) }}
                        onClick={
                            () => {
                                if (_.isNil(options?.confirmOptions)) {
                                    onClick();

                                    if (options?.analyticsOptions?.onClick) {
                                        trackAnalytics(options.analyticsOptions.onClick);
                                    }

                                    onClose();
                                } else {
                                    setConfirmOpen(true);
                                    onConfirmDialogOpen?.();
                                }
                            }}>
                        <Stack
                            alignItems="center"
                            direction="row"
                            spacing={1}
                            sx={
                                Sx.combine(
                                    {
                                        color: theme.palette.text.primary,
                                        overflow: "hidden",
                                        width: "100%"
                                    },
                                    sx)}>
                            {!_.isNil(options?.icon) &&
                                <Box sx={{ fontSize: "14px" }}>
                                    {options?.icon}
                                </Box>}
                            <Box sx={{
                                flex: 1,
                                overflow: "hidden"
                            }}>
                                {_.isString(title)
                                    ? <Typography noWrap={true}>
                                        {title}
                                    </Typography>
                                    : title}
                            </Box>
                        </Stack>
                    </MuiMenuItem>
                </Box>
            </Tooltip>
        </Fragment>);
}