﻿import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function CodeBranchIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                clipRule="evenodd"
                d="M4 5C4 3.89543 4.89543 3 6 3C7.10457 3 8 3.89543 8 5C8 6.10457 7.10457 7 6 7C4.89543 7 4 6.10457 4 5ZM6 1C3.79086 1 2 2.79086 2 5C2 6.86384 3.27477 8.42994 5 8.87398V15.126C3.27477 15.5701 2 17.1362 2 19C2 21.2091 3.79086 23 6 23C8.20914 23 10 21.2091 10 19C10 17.1362 8.72523 15.5701 7 15.126V13.5H15C17.2091 13.5 19 11.7091 19 9.5V8.87398C20.7252 8.42994 22 6.86384 22 5C22 2.79086 20.2091 1 18 1C15.7909 1 14 2.79086 14 5C14 6.86384 15.2748 8.42994 17 8.87398V9.5C17 10.6046 16.1046 11.5 15 11.5H7V8.87398C8.72523 8.42994 10 6.86384 10 5C10 2.79086 8.20914 1 6 1ZM4 19C4 17.8954 4.89543 17 6 17C7.10457 17 8 17.8954 8 19C8 20.1046 7.10457 21 6 21C4.89543 21 4 20.1046 4 19ZM18 3C16.8954 3 16 3.89543 16 5C16 6.10457 16.8954 7 18 7C19.1046 7 20 6.10457 20 5C20 3.89543 19.1046 3 18 3Z"
                fillRule="evenodd"/>
        </SvgIcon>);
}