import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useGcpArtifactRegistryTypeTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useGcpArtifactRegistryTypeTranslator",
            () => ({
                [Contract.TypeNames.GcpArtifactRegistryType]: {
                    [Contract.GcpArtifactRegistryType.Apt]: "Apt",
                    [Contract.GcpArtifactRegistryType.ContainerImage]: "Docker",
                    [Contract.GcpArtifactRegistryType.Generic]: "Generic",
                    [Contract.GcpArtifactRegistryType.Go]: "Go",
                    [Contract.GcpArtifactRegistryType.Kfp]: "Kubeflow Pipelines",
                    [Contract.GcpArtifactRegistryType.Maven]: "Maven",
                    [Contract.GcpArtifactRegistryType.Npm]: "npm",
                    [Contract.GcpArtifactRegistryType.Python]: "Python",
                    [Contract.GcpArtifactRegistryType.Yum]: "Yum"
                }
            }));
    return (type: Contract.GcpArtifactRegistryType) =>
        localization[Contract.TypeNames.GcpArtifactRegistryType][type]();
}