﻿import { ItemSelector, TimeFormatter, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useState } from "react";
import { Contract, InfoCard, InfoItem, VerticalTopItemsInfoItem } from "../../../../../../../../../../../../common";
import { useAzureKeyVaultVaultKeyTypeTranslator } from "../../../../../../../../hooks/";
import { TagsInfoItem } from "../../../../../../components";

type VersionsProps = {
    keyModel: Contract.AzureKeyVaultVaultKeyModel;
};

export function Versions({ keyModel }: VersionsProps) {
    const key = keyModel.entity as Contract.AzureKeyVaultVaultKey;
    const [selectedVersion, setSelectedVersion] = useState(() => key.versionNameToVersionMap[key.currentVersionName]);

    const keyVaultVaultKeyTypeTranslator = useAzureKeyVaultVaultKeyTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.azure.useAzureKeyVaultVaultKeyDefinition.versions",
            () => ({
                current: {
                    empty: "current",
                    text: "{{versionName}} (current)"
                },
                fields: {
                    activationTime: "Activation Time",
                    creationTime: "Creation Time",
                    ellipticCurveType: {
                        title: "Elliptic Curve Name",
                        [Contract.TypeNames.AzureKeyVaultVaultKeyEllipticCurveType]: {
                            [Contract.AzureKeyVaultVaultKeyEllipticCurveType.P256]: "P-256",
                            [Contract.AzureKeyVaultVaultKeyEllipticCurveType.P256K]: "P-256K",
                            [Contract.AzureKeyVaultVaultKeyEllipticCurveType.P384]: "P-384",
                            [Contract.AzureKeyVaultVaultKeyEllipticCurveType.P521]: "P-521",
                            [Contract.AzureKeyVaultVaultKeyEllipticCurveType.Secp256K1]: "SECP256K1"
                        }
                    },
                    enabled: {
                        false: "Disabled",
                        title: "Status",
                        true: "Enabled"
                    },
                    expirationTime: "Expiration Time",
                    keyOperations: {
                        title: "Permitted Operations",
                        [Contract.TypeNames.AzureKeyVaultVaultKeyOperation]: {
                            [Contract.AzureKeyVaultVaultKeyOperation.Decrypt]: "Decrypt",
                            [Contract.AzureKeyVaultVaultKeyOperation.DeriveBits]: "DeriveBits",
                            [Contract.AzureKeyVaultVaultKeyOperation.DeriveKey]: "DeriveKey",
                            [Contract.AzureKeyVaultVaultKeyOperation.Encrypt]: "Encrypt",
                            [Contract.AzureKeyVaultVaultKeyOperation.Import]: "Import",
                            [Contract.AzureKeyVaultVaultKeyOperation.Sign]: "Sign",
                            [Contract.AzureKeyVaultVaultKeyOperation.Verify]: "Verify",
                            [Contract.AzureKeyVaultVaultKeyOperation.UnwrapKey]: "UnwrapKey",
                            [Contract.AzureKeyVaultVaultKeyOperation.WrapKey]: "WrapKey"
                        }
                    },
                    name: "Identifier",
                    rsaKeySize: "RSA Key Size",
                    tags: "Tags",
                    type: "Key Type",
                    updateTime: "Update Time"
                }
            }));

    return (
        <InfoCard>
            <ItemSelector
                dense={true}
                fullWidth={true}
                items={
                    _(key.versionNameToVersionMap).
                        values().
                        orderBy(
                            [
                                version => version.name === key.name,
                                version => version.creationTime
                            ],
                            [
                                "desc",
                                "desc"
                            ]).
                        map(version => version.name).
                        value()}
                selectedItem={selectedVersion.name}
                sorted={false}
                onSelectedItemChanged={versionName => setSelectedVersion(key.versionNameToVersionMap[versionName])}>
                {versionName =>
                    versionName === key.currentVersionName
                        ? _.isEmpty(versionName)
                            ? localization.current.empty()
                            : localization.current.text({ versionName })
                        : versionName}
            </ItemSelector>
            <InfoCard columns={true}>
                <InfoItem
                    key="creationTime"
                    title={localization.fields.creationTime()}
                    value={
                        _.isNil(selectedVersion.creationTime)
                            ? undefined
                            : TimeFormatter.profileFormatDateTime(selectedVersion.creationTime)}/>
                <InfoItem
                    key="updateTime"
                    title={localization.fields.updateTime()}
                    value={TimeFormatter.profileFormatDateTime(selectedVersion.updateTime)}/>
                <InfoItem
                    key="name"
                    title={localization.fields.name()}
                    value={
                        _.isEmpty(selectedVersion.name)
                            ? undefined
                            : selectedVersion.name}/>
                <InfoItem
                    key="type"
                    title={localization.fields.type()}
                    value={keyVaultVaultKeyTypeTranslator(selectedVersion.type)}/>
                <InfoItem
                    key="rsaKeySize"
                    title={localization.fields.rsaKeySize()}
                    value={
                        _.isNil(selectedVersion.rsaKeySize)
                            ? undefined
                            : selectedVersion.rsaKeySize}/>
                <InfoItem
                    key="ellipticCurveType"
                    title={localization.fields.ellipticCurveType.title()}
                    value={
                        _.isNil(selectedVersion.ellipticCurveType)
                            ? undefined
                            : localization.fields.ellipticCurveType[Contract.TypeNames.AzureKeyVaultVaultKeyEllipticCurveType][selectedVersion.ellipticCurveType]()}/>
                <InfoItem
                    key="enabled"
                    title={localization.fields.enabled.title()}
                    value={
                        selectedVersion.enabled
                            ? localization.fields.enabled.true()
                            : localization.fields.enabled.false()}/>
                <InfoItem
                    key="activationTime"
                    title={localization.fields.activationTime()}
                    value={
                        _.isNil(selectedVersion.activationTime)
                            ? undefined
                            : TimeFormatter.profileFormatDateTime(selectedVersion.activationTime)}/>
                <InfoItem
                    key="expirationTime"
                    title={localization.fields.expirationTime()}
                    value={
                        _.isNil(selectedVersion.expirationTime)
                            ? undefined
                            : TimeFormatter.profileFormatDateTime(selectedVersion.expirationTime)}/>
                <VerticalTopItemsInfoItem
                    items={
                        _.map(
                            selectedVersion.keyOperations,
                            keyOperation => localization.fields.keyOperations[Contract.TypeNames.AzureKeyVaultVaultKeyOperation][keyOperation]())}
                    key="keyOperations"
                    title={localization.fields.keyOperations.title()}/>
                <TagsInfoItem
                    key="tags"
                    location="all"
                    resourceTags={selectedVersion.tags}
                    title={localization.fields.tags()}/>
            </InfoCard>
        </InfoCard>);
}