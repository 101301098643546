import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, EntitiesCell, entityModelStore, RiskPolicyItem, useTheme } from "../../../../../common";
import { useRiskPoliciesItemExcludedEntities } from "../hooks";

type ExcludedEntitiesCellProps = {
    item: RiskPolicyItem;
    scopeId: string;
};

export function ExcludedEntitiesCell({ item, scopeId }: ExcludedEntitiesCellProps) {
    const riskPolicyExcludedEntityIds = useRiskPoliciesItemExcludedEntities(item, scopeId);
    const entityModels = entityModelStore.useGet(riskPolicyExcludedEntityIds);
    const entityTypeNames =
        useMemo(
            () =>
                _(entityModels).
                    map(entityModel => entityModel.entity.typeName).
                    uniq().
                    value(),
            [entityModels]);

    const theme = useTheme();
    return (
        <EntitiesCell
            entityIdsOrModels={entityModels}
            entityTypeName={
                entityTypeNames.length === 1
                    ? entityTypeNames[0]
                    : Contract.TypeNames.Entity}
            entityVariant="iconTextTypeTenant"
            inlineEntitiesVariant="itemCountAndType"
            sx={{
                color: theme.palette.text.primary,
                textDecoration: "underline"
            }}/>);
}