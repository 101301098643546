import { NumberFormatter } from "@infrastructure";
import { Box } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { useMemo, useRef } from "react";
import { Contract } from "../controllers";
import { TypeHelper } from "../utilities";
import { SeverityCount } from "./SeverityCount";
import { TopItems, TopItemsActions } from "./TopItems";

type RiskCountsChartProps = {
    itemMap: Dictionary<number>;
    onClick: (severity: Contract.Severity) => void;
    severityVariant?: "count" | "initialAndCount";
    variant?: "dynamic" | "wrap";
};

export function RiskCountsChart({ itemMap, onClick, severityVariant = "count", variant = "wrap" }: RiskCountsChartProps) {
    const riskSeverities =
        useMemo(
            () =>
                _(Contract.Severity).
                    values().
                    filter(severity => severity != Contract.Severity.Information).
                    orderBy(
                        riskSeverity => TypeHelper.getEnumValue(Contract.TypeNames.Severity, riskSeverity),
                        "desc").
                    value(),
            []);

    const topItemsActionsRef = useRef<TopItemsActions>();
    return (
        <TopItems
            actionsRef={topItemsActionsRef}
            items={riskSeverities}
            itemSizeForLimitCalculations={
                severityVariant === "initialAndCount"
                    ? 66
                    : 56}
            orderVariant="leftToRight"
            variant={variant}
            widthCounterSx={{ flex: undefined }}>
            {severity =>
                <Box
                    className={`item ${severity}`}
                    key={severity}
                    sx={{
                        "&:hover": {
                            cursor:
                                _.isNil(itemMap[severity]) || itemMap[severity] === 0
                                    ? undefined
                                    : "pointer"
                        }
                    }}
                    onClick={
                        event => {
                            event.preventDefault();
                            topItemsActionsRef.current?.close();
                            if (!_.isNil(itemMap[severity]) && itemMap[severity] !== 0) {
                                onClick?.(severity);
                            }
                        }}>
                    <SeverityCount
                        count={NumberFormatter.unitRound(itemMap[severity] ?? 0)}
                        disabled={_.isNil(itemMap[severity]) || itemMap[severity] === 0}
                        severity={severity}
                        variant={severityVariant}/>
                </Box>}
        </TopItems>);
}