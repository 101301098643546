import { useMemo } from "react";
import { useAzureResourceDefinition, useAzureUnmanagedKubernetesClusterDefinition } from ".";
import { Contract } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";

export function useAzureEntityDefinition(definitionData: DefinitionData) {
    const useDefinition =
        useMemo(
            () => {
                switch (definitionData.entityTypeEntitiesViewName) {
                    case Contract.TypeNames.AzureUnmanagedKubernetesCluster:
                        return useAzureUnmanagedKubernetesClusterDefinition;
                    default:
                        return useAzureResourceDefinition;
                }
            },
            []);

    return useDefinition(definitionData);
}