import { Optional } from "@infrastructure";
import { Stack } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract, CustomRiskPolicyActions, useTheme } from "../../../../../../../common";
import { CustomRiskPolicyTemplate } from "../../CustomRiskPolicyTemplate";
import { useConfigurationContext, useSetConfigurationContext } from "../Configuration";

type PolicyTemplateProps = {
    actionsRef: React.MutableRefObject<CustomRiskPolicyActions | undefined>;
};

export function PolicyTemplate({ actionsRef }: PolicyTemplateProps) {
    const { scopeId, ...configurationContext } = useConfigurationContext();
    const riskPolicyConfiguration = configurationContext.riskPolicyConfiguration as Contract.CustomRiskPolicyConfiguration;
    const setConfigurationContext = useSetConfigurationContext();

    function onChange(inputKey: "description" | "name" | "severity") {
        return (inputValue: Optional<string>) => {
            if (_.isNil(inputValue)) {
                return;
            }

            setConfigurationContext(
                context => ({
                    ...context,
                    riskPolicyConfiguration: {
                        ...context.riskPolicyConfiguration,
                        [inputKey]: inputValue
                    }
                }));
        };
    }

    const theme = useTheme();
    return (
        <Stack
            spacing={2}
            sx={{
                height: "100%",
                overflow: "auto",
                padding: theme.spacing(2, 2, 2, 3)
            }}>
            <CustomRiskPolicyTemplate
                customRiskPolicyActionsRef={actionsRef}
                customRiskPolicyConfiguration={riskPolicyConfiguration}
                inputOptions={{
                    description: { onChange: onChange("description") },
                    name: { onChange: onChange("name") },
                    severity: { onChange: onChange("severity") }
                }}
                riskPolicyConfigurationTypeName={riskPolicyConfiguration.typeName}
                scopeId={scopeId}
                onValidChange={
                    valid => {
                        setConfigurationContext(
                            context => ({
                                ...context,
                                dirty: valid
                            }));
                    }}/>
        </Stack>);
}