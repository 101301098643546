import { Contract } from "../../../../../../../../../../common";
import { useAadCommonDirectoryEntityInfoItemElements } from "./useAadCommonDirectoryEntityInfoItemElements";

export function useAadDefaultDirectoryEntityInfoItemElements(directoryEntityModel: Contract.AadDirectoryEntityModel) {
    const commonDirectoryEntityInfoElements = useAadCommonDirectoryEntityInfoItemElements(directoryEntityModel);
    return [
        commonDirectoryEntityInfoElements.typeNameInfoElement,
        commonDirectoryEntityInfoElements.rawIdInfoItemElement,
        commonDirectoryEntityInfoElements.tenantInfoItemElement,
        commonDirectoryEntityInfoElements.creationTimeInfoItemElement,
        commonDirectoryEntityInfoElements.syncTimeInfoItemElement
    ];
}