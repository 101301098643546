import { Store } from "@infrastructure";
import { Contract, KubernetesController } from "..";

export const kubernetesClusterAdmissionControllerEventModelStore =
    new Store<Contract.KubernetesClusterAdmissionControllerEventModel, never, never>(
        kubernetesClusterAdmissionControllerEventModel => kubernetesClusterAdmissionControllerEventModel.event.id,
        {
            get:
                async ids => {
                    const { eventModels } = await KubernetesController.getKubernetesClusterAdmissionControllerEventModels(new Contract.KubernetesControllerGetKubernetesClusterAdmissionControllerEventModelRequest(ids));
                    return eventModels;
                }
        });