import { Optional, UnexpectedError } from "@infrastructure";
import _ from "lodash";
import { Contract, scopeNodeModelStore, useScopeTypeTranslator, useTenantNameTranslator, useTenantTypeTranslator } from "..";

export function useFileNameScopeTranslator() {
    const scopeNodeModels = scopeNodeModelStore.useGetAll();
    const scopeNodeModelMap =
        _.keyBy(
            scopeNodeModels,
            scopeNodeModel => scopeNodeModel.configuration.id);

    const scopeTypeTranslator = useScopeTypeTranslator();
    const tenantNameTranslator = useTenantNameTranslator();
    const tenantTypeTranslator = useTenantTypeTranslator();

    return (scopeId: string) => {
        const scopeConfiguration = scopeNodeModelMap[scopeId].configuration;
        const scopeType = scopeNodeModelMap[scopeId].type;

        let scopeName: Optional<string>;
        let tenantType: Optional<Contract.TenantType>;
        switch (scopeType) {
            case Contract.ScopeType.CiTenant:
            case Contract.ScopeType.CodeTenant:
                scopeName = (scopeConfiguration as Contract.TenantConfiguration).displayReference;
                tenantType = (scopeConfiguration as Contract.TenantConfiguration).type;
                break;
            case Contract.ScopeType.CloudProviderTenant:
            case Contract.ScopeType.IdentityProviderTenant:
                scopeName = `${tenantNameTranslator(scopeId)}(${scopeId})`;
                tenantType = (scopeConfiguration as Contract.TenantConfiguration).type;
                break;
            case Contract.ScopeType.Folder:
                scopeName =
                    (scopeConfiguration as Contract.FolderConfiguration).root
                        ? undefined
                        : scopeConfiguration.name;
                tenantType = (scopeConfiguration as Contract.FolderConfiguration).tenantType;
                break;
            case Contract.ScopeType.Customer:
            case Contract.ScopeType.Project:
            case Contract.ScopeType.ProjectFolder:
                scopeName = scopeNodeModelMap[scopeId].configuration.name;
                tenantType = undefined;
                break;
            default:
                throw new UnexpectedError("scopeNodeModel.type", scopeNodeModelMap[scopeId].type);
        }

        return _<string>([]).
            concatIf(
                !_.isNil(tenantType),
                () => tenantTypeTranslator(tenantType!)
            ).
            concatIf(
                !_.isNil(scopeName),
                `${scopeTypeTranslator(scopeType)} ${scopeName}`
            ).
            map(namePart => namePart.replace(" ", "_")).
            join("_");
    };
}