﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, InlineEntities, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { useAwsCloudFormationStackStatusTranslator } from "../../../../../../../../../Entities/hooks";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetAwsResourceRiskContext } from "./useGetAwsResourceRiskContext";

export function useGetAwsCloudFormationStackRiskContext() {
    return useMemo(
        () => useAwsCloudFormationStackRiskContext,
        []);
}

function useAwsCloudFormationStackRiskContext(stackModel: Contract.AwsCloudFormationStackModel) {
    const stack = stackModel.entity as Contract.AwsCloudFormationStack;
    const resourceRiskContext = useGetAwsResourceRiskContext()(stackModel);

    const cloudFormationStackStatusTranslator = useAwsCloudFormationStackStatusTranslator();
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.contexts.useGetAwsCloudFormationStackRiskContext",
            () => ({
                resources: "The {{translatedStackTypeName}} was used to create {{resources}}",
                status: "The {{translatedStackTypeName}} status is **{{status}}**"
            }));

    const translatedStackTypeName =
        entityTypeNameTranslator(
            stack.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });

    return {
        ...resourceRiskContext,
        resources:
            _.isEmpty(stackModel.resourceIdReferences)
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.resources({
                        resources:
                            <InlineEntities
                                entityIdsOrModels={stackModel.resourceIdReferences}
                                entityTypeName={Contract.TypeNames.AwsResource}
                                variant="itemCountAndType"/>,
                        translatedStackTypeName
                    })),
        status:
            new RiskDefinitionContextItem(
                localization.status({
                    status: cloudFormationStackStatusTranslator(stack.status),
                    translatedStackTypeName
                }))
    };
}