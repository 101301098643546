import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useOciContainerEngineClusterStatusTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useOciContainerEngineClusterStatusTranslator",
            () => ({
                [Contract.TypeNames.OciContainerEngineClusterStatus]: {
                    [Contract.OciContainerEngineClusterStatus.Active]: "Active",
                    [Contract.OciContainerEngineClusterStatus.Creating]: "Creating",
                    [Contract.OciContainerEngineClusterStatus.Deleted]: "Deleted",
                    [Contract.OciContainerEngineClusterStatus.Deleting]: "Deleting",
                    [Contract.OciContainerEngineClusterStatus.Failed]: "Failed",
                    [Contract.OciContainerEngineClusterStatus.Updating]: "Updating"
                }
            }));
    return (status: Contract.OciContainerEngineClusterStatus) =>
        localization[Contract.TypeNames.OciContainerEngineClusterStatus][status]();
}