import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";
import { useTheme } from "../../../themes";

export function RiverMeadowIcon(props: SvgIconProps) {
    const theme = useTheme();
    return (
        <SvgIcon {...props}>
            <g clipPath="url(#clip0_58343_2238)">
                <path
                    d="M5.86233 16.7235C5.85911 16.3774 5.93774 16.0355 6.09182 15.7256C5.37626 15.5115 4.7489 15.0722 4.30306 14.4729C3.85721 13.8736 3.61668 13.1465 3.61724 12.3996C3.61693 12.2538 3.62693 12.1082 3.64717 11.9639H3.54407C2.6501 11.9632 1.79039 12.3077 1.14423 12.9255C0.498076 13.5433 0.115359 14.3867 0.0758948 15.2798C0.0364302 16.1729 0.343265 17.0468 0.93241 17.7191C1.52156 18.3915 2.34753 18.8105 3.23807 18.8887H7.86794C7.32488 18.8402 6.81915 18.5919 6.44864 18.1919C6.07814 17.7919 5.86922 17.2686 5.86233 16.7235Z"
                    fill="#22A2DC"/>
                <path
                    d="M6.95332 14.8275C7.29028 14.63 7.67365 14.5255 8.06422 14.5248H8.13074C8.19502 14.0022 8.44392 13.5198 8.83254 13.1645C8.31731 12.3434 8.04516 11.3931 8.04759 10.4238C8.04779 9.98981 8.10141 9.55749 8.20724 9.13661C7.84695 9.01076 7.46799 8.94666 7.08636 8.94702C6.24219 8.94762 5.4272 9.25601 4.7941 9.81441C4.16101 10.3728 3.75324 11.1429 3.64722 11.9804C4.44109 12.0043 5.20268 12.3001 5.80451 12.8184C6.40634 13.3367 6.8119 14.0459 6.95332 14.8275Z"
                    fill="#089DDA"/>
                <path
                    d="M6.09178 15.7421C6.28463 15.3605 6.58382 15.0428 6.95323 14.8275C6.8091 14.0483 6.40236 13.3422 5.80078 12.8265C5.19921 12.3109 4.43911 12.0169 3.64713 11.9937C3.62689 12.138 3.61688 12.2836 3.61719 12.4294C3.6195 13.174 3.86134 13.8981 4.30698 14.4947C4.75262 15.0913 5.37839 15.5287 6.09178 15.7421Z"
                    fill="#036193"/>
                <path
                    d="M8.83254 13.1644C9.23731 12.7959 9.76519 12.5918 10.3126 12.5924C10.3913 12.5873 10.4701 12.5873 10.5488 12.5924C10.5488 12.5292 10.5488 12.466 10.5488 12.4028C10.5483 11.6791 10.3213 10.9736 9.89956 10.3855C9.47783 9.79734 8.88256 9.35597 8.19726 9.12329C8.09143 9.54418 8.03781 9.97649 8.03761 10.4105C8.03564 11.3853 8.31137 12.3406 8.83254 13.1644Z"
                    fill="#202E54"/>
                <path
                    d="M19.0834 8.96037C18.7845 8.95993 18.4861 8.98665 18.192 9.04019C17.8842 7.94704 17.2248 6.98571 16.3159 6.30479C15.407 5.62386 14.2991 5.26125 13.1635 5.27298C12.0279 5.28472 10.9277 5.67015 10.0331 6.36971C9.13843 7.06927 8.49909 8.04401 8.21387 9.1433C8.89917 9.37597 9.49444 9.81735 9.91616 10.4055C10.3379 10.9936 10.5649 11.6991 10.5654 12.4228C10.5654 12.486 10.5654 12.5492 10.5654 12.6124C10.7273 12.6305 10.8868 12.6662 11.041 12.7188C11.2255 12.0261 11.6319 11.413 12.198 10.9732C12.7641 10.5334 13.4587 10.2912 14.1755 10.2836C14.8923 10.2761 15.5918 10.5036 16.167 10.9314C16.7423 11.3592 17.1615 11.9636 17.3605 12.6523C17.5482 12.6188 17.7386 12.6021 17.9293 12.6024C18.7016 12.5904 19.4516 12.8618 20.0375 13.3652C20.6234 13.8686 21.0046 14.5692 21.109 15.3346C21.2134 16.1 21.0339 16.8771 20.6043 17.5191C20.1747 18.1611 19.5248 18.6234 18.7774 18.8188V18.9219C18.8805 18.9219 18.9803 18.9219 19.0834 18.9219C20.4066 18.9219 21.6756 18.3963 22.6112 17.4606C23.5469 16.525 24.0725 15.256 24.0725 13.9328C24.0725 12.6096 23.5469 11.3406 22.6112 10.405C21.6756 9.46937 20.4066 8.94374 19.0834 8.94374V8.96037Z"
                    fill={theme.palette.text.primary}/>
                <path
                    d="M18.4482 18.882C18.5547 18.882 18.6678 18.9053 18.7808 18.912V18.8188C18.6744 18.8388 18.558 18.8621 18.4482 18.882Z"
                    fill="#3B4752"/>
            </g>
        </SvgIcon>);
}