import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";
import { useTheme } from "../../../themes";

export function ColoredOneLoginIcon(props: SvgIconProps) {
    const theme = useTheme();
    return (
        <SvgIcon {...props}>
            <ellipse
                fill="white"
                height="11"
                width="10"
                x="7"
                y="7"/>
            <path
                d="M10.4926 1.03891C8.7402 1.28179 6.96759 2.01042 5.47131 3.09663C4.71643 3.64311 3.59086 4.7833 3.04492 5.55916C2.22938 6.70609 1.53516 8.27131 1.25209 9.6139C0.382629 13.6416 1.79802 17.7908 4.94559 20.4355C7.85726 22.8778 11.8338 23.6334 15.4937 22.4528C16.2822 22.1964 17.6909 21.4813 18.4255 20.955C19.2074 20.4018 20.3464 19.2751 20.8856 18.5262C22.0314 16.9408 22.7324 15.1799 22.9481 13.3246C23.0559 12.4138 22.9885 10.5517 22.8133 9.72859C21.991 5.91673 19.2748 2.83352 15.6015 1.55165C14.4018 1.13336 13.775 1.03216 12.2585 1.00517C11.5171 0.991678 10.7218 1.00517 10.4926 1.03891ZM13.5323 8.5007C13.6267 8.54118 13.6402 8.93248 13.6402 12.2991C13.6402 15.1327 13.6199 16.0772 13.5593 16.1379C13.4582 16.2391 11.3014 16.2526 11.1464 16.1514C11.0588 16.0974 11.0453 15.7264 11.0251 13.5877L11.0048 11.0914H10.1826C9.62988 11.0914 9.32658 11.0644 9.27266 11.0105C9.21874 10.9565 9.19178 10.5922 9.19178 9.8163C9.19178 8.87851 9.212 8.68961 9.30636 8.58166C9.40746 8.46697 9.56248 8.46022 11.4227 8.46022C12.5213 8.46022 13.4717 8.48046 13.5323 8.5007Z" 
                fill={theme.palette.text.primary}/>
        </SvgIcon>);
}