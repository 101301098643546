import { defined, Optional, StringHelper, useExecuteOperation, useLocalization, useQueryParameters } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, entityRelativeUrlContext, RiskController, RiskHelper } from "../../../../common";
import { Mail, MailImg, MailTable, MailTd } from "../Mail";
import { RiskMailCard } from "./components";

type RisksMailQueryParameters = {
    automationRuleName: Optional<string>;
    message: boolean;
    riskCount: string;
    riskIds: string | string[];
    sharingUserMail: Optional<string>;
};

export function ExportRisksMail() {
    const queryParameters = useQueryParameters<RisksMailQueryParameters>();
    defined(queryParameters.automationRuleName ?? queryParameters.sharingUserMail);

    const [riskModels] =
        useExecuteOperation(
            ExportRisksMail,
            async () => {
                const riskIds = _.concat(queryParameters.riskIds);
                const { riskModels } = await RiskController.getRiskModels(new Contract.RiskControllerGetRiskModelsRequest(riskIds));
                return _.orderBy(
                    riskModels,
                    riskModel => _.indexOf(riskIds, riskModel.risk.id));
            });

    const localization =
        useLocalization(
            "views.system.exportRisksMail",
            () => ({
                body: {
                    automationRule: "Automation rule: {{automationRuleName}}",
                    text: "{{sender}}{{topRisks}}",
                    topRisks: " | Here are the top {{count | NumberFormatter.humanize}} risks",
                    user: "Manually sent by {{sharingUserMail}}"
                },
                seeAll: "See All",
                title: {
                    automation: "automation rule {{automationRuleName}}",
                    text: [
                        "1 finding was shared with you by {{sender}}",
                        "{{count | NumberFormatter.humanize}} findings were shared with you by {{sender}}"
                    ]
                }
            }));
    const riskType = RiskHelper.getRiskType(_.first(riskModels)!.risk);

    return (
        <entityRelativeUrlContext.Provider value={false}>
            <Mail
                backgroundColor="#ffffff"
                title={
                    localization.title.text(
                        Number(queryParameters.riskCount),
                        {
                            sender:
                                !_.isNil(queryParameters.automationRuleName)
                                    ? localization.title.automation({ automationRuleName: queryParameters.automationRuleName })
                                    : queryParameters.sharingUserMail
                        }
                    )}>
                <tr>
                    <MailTd align="center">
                        <MailTable
                            align="center"
                            border="0"
                            cellPadding="0"
                            cellSpacing="0"
                            style={{ background: "linear-gradient(247.48deg, #B3D6F3 9.77%, #E9FCFF  105.63%)" }}
                            width="600">
                            <tr>
                                <MailTd
                                    height="133"
                                    style={{ paddingLeft: 28 }}
                                    width="600">
                                    <MailImg
                                        alt="Company Logo"
                                        height="25"
                                        src="cid:Logo"
                                        width="271"/>
                                </MailTd>
                            </tr>
                        </MailTable>
                    </MailTd>
                </tr>
                <tr>
                    <MailTd
                        align="center"
                        style={{ paddingTop: 31 }}>
                        <MailTable
                            align="center"
                            border="0"
                            cellPadding="0"
                            cellSpacing="0"
                            style={{ maxWidth: 540 }}
                            width="100%">
                            {StringHelper.isTrue(queryParameters.message) &&
                                <tr>
                                    <MailTd
                                        style={{
                                            fontSize: 14,
                                            fontWeight: "bold",
                                            lineHeight: "15px",
                                            paddingBottom: 20
                                        }}>
                                        <pre
                                            id="message"
                                            style={{ whiteSpace: "pre-wrap" }}></pre>
                                    </MailTd>
                                </tr>}
                            <tr>
                                <MailTd
                                    style={{
                                        fontSize: 14,
                                        fontWeight: "bold",
                                        lineHeight: "15px",
                                        paddingBottom: 20
                                    }}>
                                    <span>
                                        {localization.body.text({
                                            sender:
                                                !_.isNil(queryParameters.automationRuleName)
                                                    ? localization.body.automationRule({ automationRuleName: queryParameters.automationRuleName })
                                                    : localization.body.user({ sharingUserMail: queryParameters.sharingUserMail }),
                                            topRisks:
                                                !_.isString(queryParameters.riskIds) &&
                                                queryParameters.riskIds.length !== Number(queryParameters.riskCount)
                                                    ? localization.body.topRisks({ count: queryParameters.riskIds.length })
                                                    : ""
                                        })}
                                    </span>
                                </MailTd>
                            </tr>
                            {_.map(
                                riskModels,
                                riskModel =>
                                    <RiskMailCard
                                        key={riskModel.id}
                                        riskModel={riskModel}
                                        riskType={riskType}/>)}
                            <tr>
                                <MailTd
                                    align="middle"
                                    style={{
                                        paddingBottom: 32,
                                        paddingTop: 16
                                    }}>
                                    <a
                                        href={RiskHelper.getRisksUrlByRiskType(riskType)}
                                        style={{
                                            color: "#37474F",
                                            fontSize: 12
                                        }}>
                                        {localization.seeAll()}
                                    </a>
                                </MailTd>
                            </tr>
                        </MailTable>
                    </MailTd>
                </tr>
            </Mail>
        </entityRelativeUrlContext.Provider>);
}