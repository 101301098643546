import { Link, useLocalization } from "@infrastructure";
import { CommitOutlined } from "@mui/icons-material";
import React from "react";
import { CodeConsoleUrlHelper } from "../../tenants";
import { CodeTenantType } from "../controllers/types.generated";
import { ProfileTopbarInfoItem } from "./ProfileTopbarInfoItem";

type ProfileTopbarCommitInfoItemProps = {
    codeTenantType: CodeTenantType;
    hash: string;
    url: string;
};

export function ProfileTopbarCommitInfoItem({ codeTenantType, hash, url }: ProfileTopbarCommitInfoItemProps) {
    const localization =
        useLocalization(
            "common.profileTopbarCommitInfoItem",
            () => ({
                hash: "Commit"
            }));

    return (
        <ProfileTopbarInfoItem
            icon={<CommitOutlined/>}
            text={
                <Link
                    urlOrGetUrl={
                        CodeConsoleUrlHelper.getCodeCommitUrl(
                            hash,
                            codeTenantType,
                            url)}
                    variant="external">
                    {hash.substring(0, 7)}
                </Link>}
            titleOrGetTitle={localization.hash()}/>);
}