import { Box, List, ListItem, Stack, SxProps, Typography } from "@mui/material";
import _ from "lodash";
import React, { Children, Fragment, ReactNode, useContext } from "react";
import { CopyToClipboardText, Dropdown, NoneIcon, Sx, useLocalization } from "@infrastructure";
import { useTheme } from "..";
import { infoContext } from "../../views/Customer/components/Entities/components/Profile/components";
import { DefinitionOptionsInfoOptionsVariant } from "../../views/Customer/components/Entities/components/Profile/hooks";

export type InfoItemProps = {
    children?: ReactNode | ReactNode[] | string[];
    containerSx?: SxProps;
    copyToClipboardOptions?: InfoItemCopyToClipboardOptions;
    dense?: boolean;
    emptyValue?: string;
    limitChildren?: boolean;
    location?: InfoItemLocation;
    title?: string;
    titleInfoElement?: ReactNode;
    titleSpacing?: "narrow" | "wide";
    value?: string | ReactNode;
    valueSx?: SxProps;
    vertical?: boolean;
};

type InfoItemCopyToClipboardOptions = ({
    copyValue?: string;
    disabled?: false;
} | {
    copyValue?: never;
    disabled?: true;
});

export type InfoItemLocation = "all" | DefinitionOptionsInfoOptionsVariant;

export function InfoItem({ children, containerSx, copyToClipboardOptions, dense = true, emptyValue, limitChildren = false, title, titleInfoElement, titleSpacing = "narrow", value, valueSx, vertical = false }: InfoItemProps) {
    const childElements = Children.toArray(children);
    const infoContextValue = useContext(infoContext);
    const limitChildrenCount = 3;
    const localization =
        useLocalization(
            "common.infoItem",
            () => ({
                children: {
                    additional: "+{{additionalCount | NumberFormatter.humanize}}"
                }
            }));

    const theme = useTheme();
    return (
        <Stack
            sx={
                Sx.combine({
                    maxWidth: "100%",
                    width: "inherit",
                    ...(vertical
                        ? {
                            alignItems: "flex-start",
                            flexDirection: "column"
                        }
                        : {
                            alignItems: "center",
                            flexDirection: "row"
                        })
                },
                containerSx)}>
            {!_.isNil(title) && (
                <Stack
                    alignItems="center"
                    direction="row"
                    spacing={1}
                    sx={{
                        marginBottom:
                            titleSpacing === "wide"
                                ? theme.spacing(0.5)
                                : undefined,
                        maxWidth: "100%",
                        minHeight: "26px",
                        width: "fit-content"
                    }}>
                    <Typography
                        color={theme.palette.text.secondary}
                        noWrap={true}
                        sx={infoContextValue?.itemTitleSx}>
                        {title}
                    </Typography>
                    {!_.isNil(titleInfoElement) && titleInfoElement}
                </Stack>)}
            <Stack
                sx={{
                    marginLeft:
                        _.isNil(title) ||
                            vertical
                            ? undefined
                            : theme.spacing(1),
                    maxWidth: "fit-content",
                    overflow: "hidden",
                    width: "inherit",
                    ...valueSx
                }}>
                {_.isNil(value) && _.isNil(children) && (
                    _.isNil(emptyValue)
                        ? <NoneIcon sx={{ fontSize: "18px" }}/>
                        : <CopyToClipboardText
                            sx={infoContextValue?.itemValueSx}
                            text={emptyValue}
                            title={emptyValue}/>)}
                {_.isString(value) && !copyToClipboardOptions?.disabled
                    ? <Box
                        sx={{
                            maxWidth: "fit-content",
                            position: "relative"
                        }}>
                        <CopyToClipboardText
                            copyValue={copyToClipboardOptions?.copyValue}
                            sx={infoContextValue?.itemValueSx}
                            text={value}
                            title={title}/>
                    </Box>
                    : <Stack
                        alignItems="center"
                        direction="row"
                        sx={{ width: "inherit" }}>
                        {value}
                    </Stack>}
                {!_.isNil(children) && (
                    <Fragment>
                        {_.isNil(value) && _.isEmpty(children) && (
                            _.isNil(emptyValue)
                                ? <NoneIcon sx={{ fontSize: "18px" }}/>
                                : <CopyToClipboardText
                                    sx={infoContextValue?.itemValueSx}
                                    text={emptyValue}
                                    title={emptyValue}/>)}
                        {_(childElements).
                            take(
                                limitChildren
                                    ? limitChildrenCount
                                    : childElements.length).
                            map(
                                (childItem, childItemIndex) =>
                                    <Box
                                        key={childItemIndex}
                                        sx={{
                                            lineHeight: 1.5,
                                            marginBottom:
                                                dense
                                                    ? undefined
                                                    : theme.spacing(0.5)
                                        }}>
                                        {childItem}
                                    </Box>).
                            value()}
                        {limitChildren && childElements.length > limitChildrenCount && (
                            <Dropdown
                                popoverElement={
                                    <List
                                        sx={{
                                            maxHeight: theme.spacing(50),
                                            overflow: "hidden auto"
                                        }}>
                                        {_(childElements).
                                            drop(limitChildrenCount).
                                            map(
                                                (childItem, childItemIndex) =>
                                                    <ListItem key={childItemIndex}>
                                                        {childItem}
                                                    </ListItem>).
                                            value()}
                                    </List>}>
                                <Typography sx={{ textDecoration: "underline" }}>
                                    {localization.children.additional({ additionalCount: childElements.length - limitChildrenCount })}
                                </Typography>
                            </Dropdown>)}
                    </Fragment>)}
            </Stack>
        </Stack>);
}