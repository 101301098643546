import { Link, useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { useGetGcpComputeVpcRiskContext } from "../contexts";

export function useGcpComputeVpcLegacyVpcRiskDefinition(riskModel: Contract.RiskModel) {
    const legacyVpcRiskModel = riskModel as Contract.GcpComputeVpcLegacyVpcRiskModel;
    const vpcModel = entityModelStore.useGet(legacyVpcRiskModel.risk.entityId) as Contract.GcpComputeVpcModel;

    const getGcpComputeVpcRiskContext = useGetGcpComputeVpcRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.useGcpComputeVpcLegacyVpcRiskDefinition",
            () => ({
                description: "{{vpc}} is in legacy mode",
                sections: {
                    resolution: {
                        step1: {
                            links: {
                                deleteLegacyVpcLink: "delete legacy networks",
                                updateLegacyVpcLink: "replace legacy networks"
                            },
                            text: "Follow GCP documentation on how to {{updateLegacyVpcLink}} or {{deleteLegacyVpcLink}}"
                        }
                    }
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            vpc:
                <Entity
                    entityIdOrModel={vpcModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            localization.sections.resolution.step1.text({
                deleteLegacyVpcLink:
                    <Link
                        urlOrGetUrl={legacyVpcRiskModel.deleteLegacyVpcDocumentationUrl}
                        variant="external">
                        {localization.sections.resolution.step1.links.deleteLegacyVpcLink()}
                    </Link>,
                updateLegacyVpcLink:
                    <Link
                        urlOrGetUrl={legacyVpcRiskModel.updateLegacyVpcDocumentationUrl}
                        variant="external">
                        {localization.sections.resolution.step1.links.updateLegacyVpcLink()}
                    </Link>
            })
        ],
        riskModel,
        () => {
            const vpcRiskContext = getGcpComputeVpcRiskContext(vpcModel);
            return [
                vpcRiskContext.generalInformation,
                vpcRiskContext.instances,
                vpcRiskContext.severityPermissionActionInstances,
                vpcRiskContext.sensitive
            ];
        });
}