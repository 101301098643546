import { Shadows } from "@infrastructure";
import { Box, ButtonBase, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { useTheme } from "../../../../../../../../../../common";
import { IntegrationItem } from "../../../../utilities";
import { ElementClass } from "./Item.element";

export type ItemProps = {
    item: IntegrationItem;
    onClick(): void;
};

export function Item({ item, onClick }: ItemProps) {
    const theme = useTheme();
    return (
        <ButtonBase
            className={ElementClass.integrationItem}
            sx={{
                "&:hover": {
                    "& .icon-element": {
                        filter: "grayscale(0)",
                        opacity: 1
                    },
                    boxShadow: theme.shadows[Shadows.Card]
                },
                background: theme.palette.background.alternate,
                border: theme.border.primary,
                borderRadius: theme.spacing(0.75),
                height: theme.spacing(17.5),
                marginBottom: theme.spacing(2.5),
                marginRight: theme.spacing(2.5),
                overflow: "hidden",
                padding: theme.spacing(1.5),
                width: theme.spacing(21)
            }}
            onClick={onClick}>
            <Stack
                spacing={1}
                sx={{
                    alignItems: "center",
                    height: "100%"
                }}>
                <Stack
                    sx={{
                        alignItems: "center",
                        height: "100%",
                        justifyContent: "end"
                    }}>
                    <Box
                        className="icon-element"
                        sx={{
                            "& .MuiSvgIcon-root": {
                                fontSize: "44px"
                            },
                            filter:
                                _.isEmpty(item.configurations)
                                    ? "grayscale(1) brightness(1.0)"
                                    : "grayscale(0)",
                            fontSize: "44px",
                            opacity:
                                _.isEmpty(item.configurations)
                                    ? 0.6
                                    : 1
                        }}>
                        {item.iconElement}
                    </Box>
                </Stack>
                <Stack
                    sx={{
                        alignItems: "center",
                        height: "100%",
                        padding: theme.spacing(0, 1)
                    }}>
                    <Typography
                        sx={{ fontWeight: 600 }}>
                        {item.title}
                    </Typography>
                    {!_.isEmpty(item.configurations) &&
                        _.map(
                            item.descriptions,
                            description =>
                                <Typography
                                    noWrap={true}
                                    sx={{ fontSize: "11px" }}>
                                    {description}
                                </Typography>)}
                </Stack>
            </Stack>
        </ButtonBase>);
}