import { ActionMenuItem, DeleteIcon, EditIcon, Menu, Message, Optional, useLocalization } from "@infrastructure";
import { CircularProgress, Stack } from "@mui/material";
import _ from "lodash";
import React, { useState } from "react";
import { ConfigurationController, Contract, scopeSystemEntityModelStore, useTheme } from "../../../../../../../../common";
import { UsageDialog } from "../../components";
import { useSetEntityPropertiesContext } from "../EntityProperties";

export type ActionsCellProps = {
    entityPropertyDefinitionConfiguration: Contract.EntityPropertyDefinitionConfiguration;
};

export function ActionsCell({ entityPropertyDefinitionConfiguration }: ActionsCellProps) {
    const setContext = useSetEntityPropertiesContext();
    const [deleteEntityPropertyExecuting, setDeleteEntityPropertyExecuting] = useState(false);
    const [deleteEntityPropertyError, setDeleteEntityPropertyError] = useState(false);
    const [entityPropertyIdentifierUsage, setEntityPropertyIdentifierUsage] = useState<Optional<Contract.ConfigurationControllerGetEntityPropertyIdentifierUsageResponse>>(undefined);

    async function deleteEntityProperty() {
        setDeleteEntityPropertyExecuting(true);
        setDeleteEntityPropertyError(false);

        try {
            const entityPropertyIdentifierUsage = await ConfigurationController.getEntityPropertyIdentifierUsage(new Contract.ConfigurationControllerGetEntityPropertyIdentifierUsageRequest(entityPropertyDefinitionConfiguration.identifier));
            if (!entityPropertyIdentifierUsage.deletable) {
                setDeleteEntityPropertyExecuting(false);
                setEntityPropertyIdentifierUsage(entityPropertyIdentifierUsage);
                return;
            }
            await ConfigurationController.deleteEntityPropertyDefinition(new Contract.ConfigurationControllerDeleteEntityPropertyDefinitionRequest(entityPropertyDefinitionConfiguration.id));
            await scopeSystemEntityModelStore.notifyDeleted(entityPropertyDefinitionConfiguration.id);
        } catch {
            setDeleteEntityPropertyError(true);
        }

        setDeleteEntityPropertyExecuting(false);
    }

    const localization =
        useLocalization(
            "views.customer.configuration.entities.entityProperties.actionsCell",
            () => ({
                actions: {
                    delete: {
                        confirm: "Are you sure you want to delete the field {{name}}?",
                        error: "Failed to delete",
                        title: "Delete"
                    },
                    edit: "Edit"
                }
            }));
    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            direction="row"
            justifyContent="flex-end"
            spacing={1.5}>
            {deleteEntityPropertyExecuting && (
                <CircularProgress
                    size={theme.spacing(2)}
                    variant="indeterminate"/>)}
            {deleteEntityPropertyError && (
                <Message
                    level="error"
                    title={localization.actions.delete.error({ name: entityPropertyDefinitionConfiguration.identifier.name })}
                    variant="minimal"/>)}
            {!_.isNil(entityPropertyIdentifierUsage) &&
                <UsageDialog
                    automationRuleConfigurationIds={[]}
                    customRiskPolicyConfigurationIds={entityPropertyIdentifierUsage.customRiskPolicyConfigurationIds}
                    reportScheduleConfigurationIds={entityPropertyIdentifierUsage.reportScheduleConfigurationIds}
                    variant="entityProperty"
                    onClose={() => setEntityPropertyIdentifierUsage(undefined)}/>}
            <Menu
                itemsOrGetItems={[
                    new ActionMenuItem(
                        () =>
                            setContext(
                                context => ({
                                    ...context,
                                    addOrEditOpen: entityPropertyDefinitionConfiguration
                                })),
                        localization.actions.edit(),
                        {
                            icon: <EditIcon/>
                        }),
                    new ActionMenuItem(
                        () => deleteEntityProperty(),
                        localization.actions.delete.title(),
                        {
                            confirmOptions: {
                                message: localization.actions.delete.confirm({ name: entityPropertyDefinitionConfiguration.identifier.name })
                            },
                            disabled: deleteEntityPropertyExecuting,
                            icon: <DeleteIcon/>
                        })
                ]}/>
        </Stack>);
}