import { UrlHelper as InfrastructureUrlHelper } from "@infrastructure";
import { Contract } from "../controllers";
import { ApplicationHelper } from "./applicationHelper";
import { GeographyHelper } from "./geographyHelper";

export class UrlHelper extends InfrastructureUrlHelper {
    public static readonly supportUrl = "https://docs.ermetic.com/docs/contact-support";

    public static get tenableBaseUrl(): string {
        if (ApplicationHelper.environmentType === Contract.EnvironmentType.Dev) {
            return "https://dev.cloud.aws.tenablesecurity.com";
        } else if (GeographyHelper.deploymentPartitionType === Contract.PartitionType.UsGov) {
            return "https://fedcloud.tenable.com";
        }

        return "https://cloud.tenable.com";
    }

    public static get tenableMyApplicationsUrl(): string {
        return `${UrlHelper.tenableBaseUrl}/tio/app.html#/myapps`;
    }

    public static get tenableSignInUrl(): string {
        return `${UrlHelper.tenableBaseUrl}/tio/app.html#/settings/saml-redirect/cnapp`;
    }

    public static openSupportTab() {
        UrlHelper.openNewTab(UrlHelper.supportUrl);
    }
}