import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function InfraDateIcon(props: SvgIconProps) {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <path 
                clipRule="evenodd"
                d="M8 2C8.55228 2 9 2.55964 9 3.25V6.75C9 7.44036 8.55228 8 8 8C7.44772 8 7 7.44036 7 6.75V3.25C7 2.55964 7.44772 2 8 2Z"
                fillRule="evenodd"/>
            <path 
                clipRule="evenodd"
                d="M16 2C16.5523 2 17 2.53726 17 3.2V6.8C17 7.46274 16.5523 8 16 8C15.4477 8 15 7.46274 15 6.8V3.2C15 2.53726 15.4477 2 16 2Z"
                fillRule="evenodd"/>
            <path 
                clipRule="evenodd"
                d="M19 6H5C4.44772 6 4 6.44772 4 7V19C4 19.5523 4.44772 20 5 20H19C19.5523 20 20 19.5523 20 19V7C20 6.44772 19.5523 6 19 6ZM5 4C3.34315 4 2 5.34315 2 7V19C2 20.6569 3.34315 22 5 22H19C20.6569 22 22 20.6569 22 19V7C22 5.34315 20.6569 4 19 4H5Z"
                fillRule="evenodd"/>
            <path 
                clipRule="evenodd"
                d="M3 11C3 10.4477 3.44772 10 4 10H20C20.5523 10 21 10.4477 21 11C21 11.5523 20.5523 12 20 12H4C3.44772 12 3 11.5523 3 11Z"
                fillRule="evenodd"/>
        </SvgIcon>);
}