import { Action1, MultiSelect, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import { Contract, ScopeHelper, scopeSystemEntityModelStore, useScopeNavigationViewContext } from "../../../../../../../common";

type DatadogSelectorProps = {
    disabled?: boolean;
    onSelectedItemsChanged: Action1<Contract.DeliveryWrapper[]>;
    selectedItems: Contract.DeliveryWrapper[];
};

export function DatadogSelector({ disabled, onSelectedItemsChanged, selectedItems }: DatadogSelectorProps) {
    const { scopeNodeModel } = useScopeNavigationViewContext();
    const datadogOrganizationModels =
        ScopeHelper.getParentScopeSystemEntityModelsIntersection(
            [scopeNodeModel.configuration.id],
            scopeSystemEntityModelStore.useGetDatadog());
    const [datadogOrganizationIds, datadogOrganizationIdToDeliveriesMap, datadogOrganizationModelMap] =
        useMemo(
            () => {
                const datadogOrganizationModelMap =
                    _.keyBy(
                        datadogOrganizationModels,
                        datadogOrganizationModel => datadogOrganizationModel.configuration.id);
                const datadogOrganizationIds = _.keys(datadogOrganizationModelMap);
                const datadogOrganizationIdToActionsMap =
                    _.mapValues(
                        datadogOrganizationModelMap,
                        _ =>
                            new Contract.DeliveryWrapper(
                                new Contract.DatadogDelivery(
                                    [],
                                    undefined,
                                    []),
                                ""));
                return [datadogOrganizationIds, datadogOrganizationIdToActionsMap, datadogOrganizationModelMap];
            },
            [datadogOrganizationModels]);

    const localization =
        useLocalization(
            "views.customer.configuration.automations.datadogSelector",
            () => ({
                organizations: "{{organizationsCount | NumberFormatter.humanize}} Datadog organizations",
                title: "Organizations"
            }));

    const [selectedOrganizationIds, setSelectedOrganizationIds] =
        useState(
            () =>
                _.flatMap(
                    selectedItems,
                    selectedItem => (selectedItem.delivery as Contract.DatadogDelivery).integrationIds));
    return (
        <MultiSelect
            disabled={disabled}
            fullWidth={true}
            items={datadogOrganizationIds}
            placeholder={localization.title()}
            selectedItems={selectedOrganizationIds}
            selectedItemsTranslator={
                selectedItems =>
                    selectedItems.length === 1
                        ? (datadogOrganizationModelMap[selectedItems[0]].configuration as Contract.DatadogOrganizationConfiguration).name
                        : localization.organizations({ organizationsCount: selectedItems.length })}
            onSelectedItemsChanged={
                selectedItems => {
                    setSelectedOrganizationIds(selectedItems);
                    onSelectedItemsChanged(
                        _.map(
                            selectedItems,
                            selectedItem => datadogOrganizationIdToDeliveriesMap[selectedItem]));
                }}>
            {organizationId => (datadogOrganizationModelMap[organizationId].configuration as Contract.DatadogOrganizationConfiguration).name}
        </MultiSelect>);
}