import { NumberFormatter, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition } from "../..";
import { useAzureCommonEncryptedResourceInfoElements, useAzureCommonManagedIdentityOriginatorResourceInfoElements, useAzureDefaultResourceInfoItemElements, useCustomEntityPropertyInfoItemElements } from "..";
import { Contract, InfoItem } from "../../../../../../../../../../common";
import { useAzureSqlServerDatabaseStatusTranslator } from "../../../../../../hooks";
import { EntitiesInfoItem, Info } from "../../../../components";

export function useAzureSqlServerDatabaseDefinition(resourceGroupResourceModel: Contract.AzureResourceGroupResourceModel, options?: DefinitionOptions) {
    const commonEncryptedResourceInfoElements = useAzureCommonEncryptedResourceInfoElements(resourceGroupResourceModel);
    const commonManagedIdentityOriginatorResourceInfoElements = useAzureCommonManagedIdentityOriginatorResourceInfoElements(resourceGroupResourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceGroupResourceModel);
    const defaultResourceInfoItemElements = useAzureDefaultResourceInfoItemElements(resourceGroupResourceModel);
    const database = resourceGroupResourceModel.entity as Contract.AzureSqlServerDatabase;

    const sqlServerDatabaseStatusTranslator = useAzureSqlServerDatabaseStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.azure.useAzureSqlServerDatabaseDefinition",
            () => ({
                info: {
                    items: {
                        server: "SQL Server",
                        status: "Status",
                        storageSize: "Size"
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    ...commonManagedIdentityOriginatorResourceInfoElements,
                    ...commonEncryptedResourceInfoElements,
                    <InfoItem
                        key="storageSize"
                        title={localization.info.items.storageSize()}
                        value={
                            _.isNil(database.storageSize)
                                ? undefined
                                : NumberFormatter.storage(database.storageSize!)}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={database.serverId}
                        entityTypeName={Contract.TypeNames.AzureSqlServer}
                        key="server"
                        title={localization.info.items.server()}/>,
                    <InfoItem
                        key="status"
                        title={localization.info.items.status()}
                        value={sqlServerDatabaseStatusTranslator(database.status)}/>
                ]}
                options={options?.infoOptions}>
            </Info>
    });
}