import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../../tenants";
import { RiskDefinitionSection } from "../../../../../../../utilities";
import { PolicySection } from "../../../components";
import { ContextSection } from "./components";

export function useAwsIamRolePublicAccessExistsRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsIamRolePublicAccessExistsRisk;
    const roleModel = entityModelStore.useGet(risk.entityId) as Contract.AwsIamRoleModel;
    const role = roleModel.entity as Contract.AwsIamRole;

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsIamRolePublicAccessExistsRiskDefinition",
            () => ({
                description: "{{role}} is publicly accessible via trust policy",
                sections: {
                    resolution: {
                        step1: "Click on **Edit trust relationships**",
                        step2: "Edit the policy statements to allow access only to principals that are required to assume the role",
                        step3: "Click **Update Trust Policy** to confirm"
                    },
                    trustRelationshipPolicy: "Trust Relationship Policy"
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            role:
                <Entity
                    entityIdOrModel={roleModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.Iam,
                AwsConsoleUrlBuilder.getIamRoleTrustRelationshipsUrl(role)),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3()
        ],
        riskModel,
        undefined,
        {
            contextSectionElement:
                <ContextSection
                    risk={risk}
                    roleModel={roleModel}/>,
            sections: [
                new RiskDefinitionSection(
                    <PolicySection policyDocument={risk.roleAssumeRolePolicyDocument}/>,
                    localization.sections.trustRelationshipPolicy(),
                    {
                        expandable: true
                    })
            ]
        });
}