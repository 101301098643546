﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import { useMemo } from "react";
import { Contract, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { useAwsEc2VpcEndpointTypeTranslator } from "../../../../../../../../../Entities/hooks";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetAwsNetworkedResourceRiskContext } from "./useGetAwsNetworkedResourceRiskContext";
import { useGetAwsResourceRiskContext } from "./useGetAwsResourceRiskContext";

export function useGetAwsEc2VpcEndpointRiskContext() {
    return useMemo(
        () => useAwsEc2VpcEndpointRiskContext,
        []);
}

function useAwsEc2VpcEndpointRiskContext(vpcEndpointModel: Contract.AwsEc2VpcEndpointModel) {
    const vpcEndpoint = vpcEndpointModel.entity as Contract.AwsEc2VpcEndpoint;
    const networkedResourceRiskContext = useGetAwsNetworkedResourceRiskContext()(vpcEndpointModel);
    const resourceRiskContext = useGetAwsResourceRiskContext()(vpcEndpointModel);

    const ec2VpcEndpointTypeTranslator = useAwsEc2VpcEndpointTypeTranslator();
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.contexts.useGetAwsEc2VpcEndpointRiskContext",
            () => ({
                vpcEndpointInfo: {
                    vpcEndpointType: "The {{translatedVpcEndpointTypeName}} type is **{{vpcEndpointType}}**",
                    vpcEndpointTypeAndServiceName: "The {{translatedVpcEndpointTypeName}} type is **{{vpcEndpointType}}**, targeting service {{serviceName}}"
                }
            }));
    const translatedVpcEndpointTypeName =
        entityTypeNameTranslator(
            vpcEndpoint.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });

    return {
        ...networkedResourceRiskContext,
        ...resourceRiskContext,
        vpcEndpointTypeAndServiceName:
            new RiskDefinitionContextItem(
                (_.isNil(vpcEndpoint.serviceName)
                    ? localization.vpcEndpointInfo.vpcEndpointType
                    : localization.vpcEndpointInfo.vpcEndpointTypeAndServiceName)({
                    serviceName: vpcEndpoint.serviceName,
                    translatedVpcEndpointTypeName,
                    vpcEndpointType: ec2VpcEndpointTypeTranslator(vpcEndpoint.type)
                }))
    };
}