﻿import { TimeSpanHelper, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useCallback } from "react";
import { RiskPolicyConfigurationDefinitionData } from "..";
import { Contract, CustomerConsoleAppUrlHelper, RiskPoliciesType } from "../../../../../../../common";
import { TimeFrame, ViewOnlyTimeFrame } from "../components";

export function useGcpTenantEntityUnusedRiskPolicyConfigurationDefinition(riskPoliciesType: RiskPoliciesType): RiskPolicyConfigurationDefinitionData {
    const localization =
        useLocalization(
            "views.customer.riskPolicies.hooks.useConfigurationDefinition.hooks.useGcpTenantEntityUnusedRiskPolicyConfigurationDefinition",
            () => ({
                description: "By default, Tenable Cloud Security uses a 30 days time frame to analyze this policy before opening findings.",
                error: "The value should be {{minProjectUsageTimeFrame}} days or higher",
                title: "Inactivity Period"
            }));

    const getTimeFrame =
        useCallback(
            (riskPolicyConfiguration: Contract.RiskPolicyConfiguration) =>
                (riskPolicyConfiguration as Contract.GcpTenantEntityUnusedRiskPolicyConfiguration).projectUsageTimeFrame,
            []);

    return {
        generalAdditionalItems: [{
            configurationElement:
                <TimeFrame
                    description={localization.description()}
                    documentationUrl={CustomerConsoleAppUrlHelper.getDocsViewAndManagePoliciesRelativeUrl()}
                    getTimeFrame={getTimeFrame}
                    key={`${Contract.TypeNames.GcpTenantEntityUnusedRiskPolicyConfiguration}-projectUsageTimeFrame`}
                    riskPoliciesType={riskPoliciesType}
                    setTimeFrame={
                        (riskPolicyConfiguration, projectUsageTimeFrame) => {
                            const minProjectUsageTimeFrame = TimeSpanHelper.getDays((riskPolicyConfiguration as Contract.GcpTenantEntityUnusedRiskPolicyConfiguration).minProjectUsageTimeFrame);
                            if (!_.isNil(projectUsageTimeFrame) &&
                                TimeSpanHelper.getDays(projectUsageTimeFrame) < minProjectUsageTimeFrame) {
                                return localization.error({ minProjectUsageTimeFrame });
                            }

                            (riskPolicyConfiguration as Contract.GcpTenantEntityUnusedRiskPolicyConfiguration).projectUsageTimeFrame = projectUsageTimeFrame;
                            return undefined;
                        }}
                    title={localization.title()}/>,
            viewItem: {
                getValue:
                    (_, item, scopeId) =>
                        <ViewOnlyTimeFrame
                            getTimeFrame={getTimeFrame}
                            item={item}
                            riskPoliciesType={riskPoliciesType}
                            scopeId={scopeId}/>,
                title: localization.title()
            }
        }]
    };
}