import { BackIcon, ConfirmButton, defined, getChildElements, Optional, useChangeEffect, useLocalization } from "@infrastructure";
import { Box, Button, CircularProgress, Stack, useTheme } from "@mui/material";
import _, { Function0 } from "lodash";
import React, { Children, Fragment, MutableRefObject, ReactElement, ReactNode, useEffect, useRef, useState } from "react";
import { FormLayout, FormLayoutTitleOptions, Message } from ".";
import { makeContextProvider } from "../utilities";

export class WizardContext {
    public backDisabled = false;
    public bottomElement?: ReactElement;
    public executing = false;
    public nextConfirmMessage?: string;
    public nextDisabled = false;
    public nextTitle?: string;
    public showBack = true;
    public showFooter = true;
    public showNext = true;

    constructor(
        public next: () => Promise<void>,
        public nextEffectHandlerRef: MutableRefObject<Optional<Function0<Promise<Optional<false | string>>>>>,
        public setLoaded: () => void,
        public setValid: (valid: boolean) => void) {
    }

    public useNextEffect =
        (handler: Function0<Promise<Optional<false | string>>>, dependencies: any[]) => {
            useEffect(
                () => {
                    this.nextEffectHandlerRef.current = handler;
                    return () => {
                        this.nextEffectHandlerRef.current = undefined;
                    };
                },
                dependencies);
        };
}

export const [useWizardContext, useSetWizardContext, useWizardContextProvider] = makeContextProvider<WizardContext>();

type WizardProps = {
    children: ReactNode;
    onFinished: (canceled: boolean) => Promise<void> | void;
    startItemIndex?: number;
    titleOptions: FormLayoutTitleOptions;
};

export function Wizard({ children, onFinished, startItemIndex = 0, titleOptions }: WizardProps) {
    const itemElements = getChildElements(Children.toArray(children), WizardItem);

    const nextEffectHandlerRef = useRef<Function0<Promise<Optional<string>>>>();
    const [loaded, setLoaded] = useState(!itemElements[startItemIndex].props.deferredLoading);
    const [valid, setValid] = useState(true);
    const [context, setContext, ContextProvider] =
        useWizardContextProvider(
            () =>
                new WizardContext(
                    next,
                    nextEffectHandlerRef,
                    () => setLoaded(true),
                    setValid));

    const [itemIndex, setItemIndex] = useState(startItemIndex);
    const [failureOrErrorMessage, setFailureOrErrorMessage] = useState<false | string>();
    const [nextExecuting, setNextExecuting] = useState(false);

    async function next() {
        if (!_.isNil(nextEffectHandlerRef.current)) {
            setNextExecuting(true);

            try {
                const nextFailureOrErrorMessage = await nextEffectHandlerRef.current();
                setFailureOrErrorMessage(nextFailureOrErrorMessage);
                if (!_.isNil(nextFailureOrErrorMessage)) {
                    return;
                }
            } finally {
                setNextExecuting(false);
            }
        }

        nextEffectHandlerRef.current = undefined;
        if (itemIndex + 1 === itemElements.length) {
            setNextExecuting(true);
            try {
                await onFinished(false);
            } finally {
                setNextExecuting(false);
            }
        } else {
            moveStep(1);
        }
    }

    function moveStep(direction: 1 | -1) {
        nextEffectHandlerRef.current = undefined;
        setItemIndex(itemIndex + direction);
        setLoaded(!itemElements[itemIndex + direction].props.deferredLoading);
        setValid(true);
        setContext(
            context => ({
                ...context,
                backDisabled: false,
                nextConfirmMessage: undefined,
                nextDisabled: false,
                nextTitle: undefined,
                showBack: true,
                showFooter: true,
                showNext: true
            }));
    }

    useChangeEffect(
        () => {
            setContext(
                context => ({
                    ...context,
                    executing: nextExecuting
                }));
        },
        [nextExecuting]);

    const localization =
        useLocalization(
            "infrastructure.wizard",
            () => ({
                actions: {
                    back: "Back",
                    next: {
                        title: {
                            close: "Done",
                            next: "Next"
                        }
                    }
                },
                title: "**{{title}}** - {{itemTitle}}"
            }));
    const nextTitle =
        _.isNil(context.nextTitle)
            ? itemIndex < itemElements.length - 1
                ? localization.actions.next.title.next()
                : localization.actions.next.title.close()
            : context.nextTitle;
    const backButtonDisabled = context.backDisabled || !loaded || nextExecuting;

    const theme = useTheme();
    return (
        <ContextProvider>
            <FormLayout
                disableContentPadding={true}
                footerOptions={
                    context.showFooter
                        ? {
                            border: true,
                            contentElement:
                                <Stack
                                    alignItems="center"
                                    direction="row"
                                    spacing={1}
                                    sx={{ width: "100%" }}>
                                    <Box sx={{ flex: 1 }}>
                                        {context.bottomElement}
                                    </Box>
                                    {nextExecuting && (
                                        <CircularProgress
                                            size={theme.spacing(3)}
                                            variant="indeterminate"/>)}
                                    {_.isString(failureOrErrorMessage) && (
                                        <Message
                                            level="error"
                                            title={failureOrErrorMessage}
                                            variant="minimal"/>)}
                                    {context.showBack && itemElements.length > 1 && itemIndex !== 0 && (
                                        <Button
                                            disabled={backButtonDisabled}
                                            startIcon={<BackIcon sx={{ fontSize: "14px" }}/>}
                                            sx={{
                                                "& .MuiButton-startIcon > :nth-of-type(1)": {
                                                    fontSize: "14px"
                                                }
                                            }}
                                            variant={
                                                backButtonDisabled
                                                    ? "contained"
                                                    : "outlined"}
                                            onClick={() => moveStep(-1)}>
                                            {localization.actions.back()}
                                        </Button>)}
                                    {context.showNext && (
                                        <Fragment>
                                            {_.isNil(context.nextConfirmMessage)
                                                ? <Button
                                                    disabled={context.nextDisabled || !loaded || nextExecuting || !valid}
                                                    onClick={() => next()}>
                                                    {nextTitle}
                                                </Button>
                                                : <ConfirmButton
                                                    disabled={context.nextDisabled || !loaded || nextExecuting || !valid}
                                                    message={context.nextConfirmMessage}
                                                    variant="contained"
                                                    onClick={() => next()}>
                                                    {nextTitle}
                                                </ConfirmButton>}
                                        </Fragment>)}
                                </Stack>
                        }
                        : undefined}
                titleOptions={{
                    ...titleOptions,
                    subtitle: itemElements[itemIndex].props.subtitle ?? titleOptions.subtitle,
                    text: itemElements[itemIndex].props.title ?? titleOptions.text
                }}>
                <Box
                    sx={{
                        flex: 1,
                        height: "100%",
                        padding:
                            itemElements[itemIndex].props.disablePadding
                                ? 0
                                : theme.spacing(4)
                    }}>
                    {itemElements[itemIndex].props.children}
                </Box>
            </FormLayout>
        </ContextProvider>);
}

type WizardItemProps = {
    children: ReactNode;
    deferredLoading?: boolean;
    disablePadding?: boolean;
    subtitle?: string;
    title?: string;
};

export function WizardItem(props: WizardItemProps) {
    defined(props);
    return <Fragment/>;
}