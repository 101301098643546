import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useGetAwsEc2SecurityGroupRiskContext } from "../../..";
import { Contract, Entity, entityModelStore, tenantModelStore, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, AwsEc2SecurityGroupRulesTable, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { RiskDefinitionContextItem, RiskDefinitionSection, RiskDefinitionSectionGroup } from "../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { useGetRiskPolicyConfigurationInactivityTimeFrameContextItem } from "../../../useGetRiskPolicyConfigurationInactivityTimeFrameContextItem";

export function useAwsEc2SecurityGroupUnusedRiskDefinition(riskModel: Contract.RiskModel) {
    const securityGroupUnusedRiskModel = riskModel as Contract.AwsEc2SecurityGroupUnusedRiskModel;
    const risk = securityGroupUnusedRiskModel.risk as Contract.AwsEc2SecurityGroupUnusedRisk;
    const securityGroupModel = entityModelStore.useGet(securityGroupUnusedRiskModel.risk.entityId) as Contract.AwsEc2SecurityGroupModel;
    const securityGroup = securityGroupModel.entity as Contract.AwsEc2SecurityGroup;
    const tenantConfiguration = tenantModelStore.useGet(riskModel.tenantId).configuration as Contract.AwsTenantConfiguration;

    const getAwsEc2SecurityGroupRiskContext = useGetAwsEc2SecurityGroupRiskContext();
    const getRiskPolicyConfigurationInactivityTimeFrameContextItem = useGetRiskPolicyConfigurationInactivityTimeFrameContextItem();

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsEc2SecurityGroupUnusedRiskDefinition",
            () => ({
                contextItems: {
                    [Contract.TypeNames.AwsEc2SecurityGroupUnusedRiskResourceAttachInfo]: {
                        [Contract.AwsEc2SecurityGroupUnusedRiskResourceAttachInfo.Ever]: "The last resource attached to the {{translatedSecurityGroupTypeName}} was on {{securityGroupResourceAttachTime | TimeFormatter.shortDate}}",
                        [Contract.AwsEc2SecurityGroupUnusedRiskResourceAttachInfo.Never]: "No resource was attached to the {{translatedSecurityGroupTypeName}} since it was created",
                        [Contract.AwsEc2SecurityGroupUnusedRiskResourceAttachInfo.Unknown]: "No resource was attached to the {{translatedSecurityGroupTypeName}} for more than {{tenantManagementEventStartDate | TimeFormatter.humanizeDuration}}"
                    },
                    usageTime: "The {{translatedSecurityGroupTypeName}} last usage was on {{usageTime | TimeFormatter.shortDate}}"
                },
                description: "{{securityGroup}} did not have any resources attached to it for more than **{{usageTimeFrame | TimeSpanFormatter.humanizeDays}}**",
                sections: {
                    inbound: "Inbound Rules",
                    outbound: "Outbound Rules",
                    resolution: {
                        step1: "For each referencing security group, delete all the rules that allow inbound and outbound access to {{securityGroup}}",
                        step2: "Delete the security group by clicking on **Actions**, **Delete Security Groups** and **Delete** buttons"
                    }
                }
            }));
    const translatedSecurityGroupTypeName =
        entityTypeNameTranslator(
            securityGroup.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            securityGroup:
                <Entity
                    entityIdOrModel={securityGroupModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>,
            usageTimeFrame: securityGroupUnusedRiskModel.risk.usageTimeFrame
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.Ec2,
                AwsConsoleUrlBuilder.getEc2Url(tenantConfiguration.partitionType)),
            _.isEmpty(securityGroupModel.referencingSecurityGroupIds)
                ? undefined
                : localization.sections.resolution.step1({
                    securityGroup:
                        <Entity
                            entityIdOrModel={securityGroupModel}
                            glanceOptions={{ disabled: true }}
                            linkOptions={{ disabled: true }}
                            variant="text"/>
                }),
            localization.sections.resolution.step2()
        ],
        securityGroupUnusedRiskModel,
        () => {
            const securityGroupRiskContext = getAwsEc2SecurityGroupRiskContext(securityGroupModel);
            return [
                securityGroupRiskContext.generalInformation,
                getRiskPolicyConfigurationInactivityTimeFrameContextItem(risk.usageTimeFrame),
                _.isNil(securityGroupUnusedRiskModel.risk.securityGroupUsageTime)
                    ? undefined
                    : new RiskDefinitionContextItem(
                        localization.contextItems.usageTime({
                            translatedSecurityGroupTypeName,
                            usageTime: securityGroupUnusedRiskModel.risk.securityGroupUsageTime
                        })),
                securityGroupRiskContext.vpc,
                securityGroupRiskContext.sensitive,
                securityGroupRiskContext.referencingSecurityGroups,
                securityGroupRiskContext.wideRangeInboundRuleExists,
                new RiskDefinitionContextItem(
                    localization.contextItems[Contract.TypeNames.AwsEc2SecurityGroupUnusedRiskResourceAttachInfo][securityGroupUnusedRiskModel.risk.securityGroupResourceAttachInfo]({
                        securityGroupResourceAttachTime: (securityGroupModel.entityProfile as Contract.AwsEc2SecurityGroupProfile)?.resourceAttachTime,
                        tenantManagementEventStartDate: securityGroupUnusedRiskModel.risk.tenantManagementEventStartDate,
                        translatedSecurityGroupTypeName
                    })),
                securityGroupRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        },
        {
            sections: [
                new RiskDefinitionSectionGroup(
                    [
                        new RiskDefinitionSection(
                            <AwsEc2SecurityGroupRulesTable
                                inbound={true}
                                rules={securityGroup.inboundRules}/>,
                            localization.sections.inbound()),
                        new RiskDefinitionSection(
                            <AwsEc2SecurityGroupRulesTable
                                inbound={false}
                                rules={securityGroup.outboundRules}/>,
                            localization.sections.outbound())
                    ])
            ]
        });
}