import { useMemo } from "react";
import { clearPersistentResults } from "@infrastructure";
import { cloudRiskPolicyItemsOperationStore, Contract, RiskController, RiskPoliciesType, riskPolicyItemConfigurationOperationStore, riskPolicyModelStore, scopeSystemEntityModelStore, useComplianceSectionDatas, useSelectedScopeId } from "../../../../..";
import { RiskPolicyConfigurationControllerDefinition } from "../../../useRiskPolicyConfigurationControllerDefinition";

export function useCloudRiskPolicyCustomConfigurationControllerDefinition(riskPolicyConfigurationTypeNameOrId: string, scopeId: string): RiskPolicyConfigurationControllerDefinition {
    const { selectedScopeId } = useSelectedScopeId();
    const { refreshComplianceSectionDatas } = useComplianceSectionDatas(selectedScopeId);

    return useMemo(
        () => ({
            async deleteCustomRiskPolicyConfiguration() {
                await RiskController.deleteCustomRiskPolicy(new Contract.RiskControllerDeleteCustomRiskPolicyRequest(riskPolicyConfigurationTypeNameOrId));
                await refreshComplianceSectionDatas();
                await riskPolicyModelStore.notifyDeleted(riskPolicyConfigurationTypeNameOrId);

                window.location.hash = "";

                await Promise.all([
                    cloudRiskPolicyItemsOperationStore.notifyAll(),
                    scopeSystemEntityModelStore.notify()
                ]);

                clearPersistentResults();
            },
            async updateRiskPolicyConfiguration({ entityTypeNameToExclusionsMap, riskPoliciesIds, riskPolicyConfiguration, riskPolicyIdToSystemUpdateTimeMap }) {
                await RiskController.updateRiskPolicyConfiguration(
                    new Contract.RiskControllerUpdateCloudRiskPolicyConfigurationRequest(
                        riskPolicyConfiguration,
                        entityTypeNameToExclusionsMap!,
                        riskPolicyIdToSystemUpdateTimeMap));

                await riskPolicyModelStore.notify(riskPoliciesIds);

                await Promise.all([
                    cloudRiskPolicyItemsOperationStore.notifyAll(),
                    riskPolicyItemConfigurationOperationStore.notify(riskPolicyConfigurationTypeNameOrId, scopeId, true, RiskPoliciesType.Cloud)
                ]);
            }
        }),
        [riskPolicyConfigurationTypeNameOrId, scopeId]);
}