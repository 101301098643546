﻿import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, EnumValuesFilter, Optional, useLocalization, ValueFilter } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { useAwsCommonEc2ResourceDefinition } from ".";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { useAwsEc2VpcPeeringConnectionStatusTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";
import { EntityTableDefinition } from "../../useDefinition";

export function useAwsEc2VpcPeeringConnectionDefinition(definitionData: DefinitionData) {
    const commonEc2ResourceDefinition = useAwsCommonEc2ResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const ec2VpcPeeringConnectionStatusTranslator = useAwsEc2VpcPeeringConnectionStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.aws.useAwsEc2VpcPeeringConnectionDefinition",
            () => ({
                columns: {
                    accepterVpc: "Accepter VPC",
                    crossTenant: "Cross Account",
                    requesterVpc: "Requester VPC",
                    routeTableIds: "Associated Route Tables",
                    routeTableSubnetIds: "Associated Subnets",
                    status: "State"
                }
            }));

    return new EntityTableDefinition(
        _.filter([
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        default: true,
                        element:
                            <ValueFilter
                                items={[
                                    {
                                        title: localization.columns.crossTenant(),
                                        value: true
                                    }
                                ]}
                                placeholder={localization.columns.crossTenant()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AwsEc2VpcPeeringConnectionCrossTenant}
                key={Contract.EntityModelProperty.AwsEc2VpcPeeringConnectionCrossTenant}
                title={localization.columns.crossTenant()}/>,
            commonEc2ResourceDefinition.columns.rawIdColumn,
            commonEc2ResourceDefinition.columns.tenantColumn,
            commonEc2ResourceDefinition.columns.creatorIdentityCsvColumn,
            commonEc2ResourceDefinition.columns.creatorOriginatorEntityCsvColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsEc2VpcPeeringConnectionModel) => ({
                            [localization.columns.status()]: ec2VpcPeeringConnectionStatusTranslator((item.entity as Contract.AwsEc2VpcPeeringConnection).status)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.AwsEc2VpcPeeringConnectionStatus}
                                enumTypeTranslator={ec2VpcPeeringConnectionStatusTranslator}
                                placeholder={localization.columns.status()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AwsEc2VpcPeeringConnectionStatus}
                itemProperty={(item: Contract.AwsEc2VpcPeeringConnectionModel) => ec2VpcPeeringConnectionStatusTranslator((item.entity as Contract.AwsEc2VpcPeeringConnection).status)}
                key={Contract.EntityModelProperty.AwsEc2VpcPeeringConnectionStatus}
                title={localization.columns.status()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.AwsEc2VpcPeeringConnectionModel>(
                        Contract.TypeNames.AwsEc2Vpc,
                        item => [item.requesterVpcIdReference],
                        localization.columns.requesterVpc())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AwsEc2VpcPeeringConnectionRequesterVpc)}
                                placeholder={localization.columns.requesterVpc()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AwsEc2VpcPeeringConnectionRequesterVpc}
                key={Contract.EntityModelProperty.AwsEc2VpcPeeringConnectionRequesterVpc}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AwsEc2VpcPeeringConnectionModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.requesterVpcIdReference}
                            entityTypeName={Contract.TypeNames.AwsEc2Vpc}
                            entityVariant="iconText"/>}
                title={localization.columns.requesterVpc()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.AwsEc2VpcPeeringConnectionModel>(
                        Contract.TypeNames.AwsEc2Vpc,
                        item => [item.accepterVpcIdReference],
                        localization.columns.accepterVpc())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AwsEc2VpcPeeringConnectionAccepterVpc)}
                                placeholder={localization.columns.accepterVpc()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AwsEc2VpcPeeringConnectionAccepterVpc}
                key={Contract.EntityModelProperty.AwsEc2VpcPeeringConnectionAccepterVpc}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AwsEc2VpcPeeringConnectionModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.accepterVpcIdReference}
                            entityTypeName={Contract.TypeNames.AwsEc2Vpc}
                            entityVariant="iconText"/>}
                title={localization.columns.accepterVpc()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.AwsEc2VpcPeeringConnectionModel>(
                        Contract.TypeNames.AwsEc2RouteTable,
                        item => item.routeTableIds,
                        localization.columns.routeTableIds())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AwsEc2VpcPeeringConnectionRouteTables)}
                                placeholder={localization.columns.routeTableIds()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AwsEc2VpcPeeringConnectionRouteTables}
                key={Contract.EntityModelProperty.AwsEc2VpcPeeringConnectionRouteTables}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AwsEc2VpcPeeringConnectionModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.routeTableIds}
                            entityTypeName={Contract.TypeNames.AwsEc2RouteTable}
                            entityVariant="iconText"/>}
                title={localization.columns.routeTableIds()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.AwsEc2VpcPeeringConnectionModel>(
                        Contract.TypeNames.AwsEc2Subnet,
                        item => item.routeTableSubnetIds,
                        localization.columns.routeTableSubnetIds())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AwsEc2VpcPeeringConnectionRouteTableSubnets)}
                                placeholder={localization.columns.routeTableSubnetIds()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AwsEc2VpcPeeringConnectionRouteTableSubnets}
                key={Contract.EntityModelProperty.AwsEc2VpcPeeringConnectionRouteTableSubnets}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AwsEc2VpcPeeringConnectionModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.routeTableSubnetIds}
                            entityTypeName={Contract.TypeNames.AwsEc2Subnet}
                            entityVariant="iconText"/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.routeTableSubnetIds()}/>,
            commonEc2ResourceDefinition.columns.regionColumn,
            commonEc2ResourceDefinition.columns.regionLocationColumn,
            commonEc2ResourceDefinition.columns.tagsColumn,
            commonEc2ResourceDefinition.columns.attributesColumn,
            commonEc2ResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonEc2ResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonEc2ResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ]),
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAwsEc2VpcPeeringConnectionRequest(
                new Contract.EntityControllerGetEntityModelPageAwsEc2VpcPeeringConnectionRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEc2ResourceRawId]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEc2VpcPeeringConnectionAccepterVpc]),
                    filterMap[Contract.EntityModelProperty.AwsEc2VpcPeeringConnectionCrossTenant] as Optional<boolean>,
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEc2VpcPeeringConnectionRequesterVpc]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEc2VpcPeeringConnectionRouteTables]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEc2VpcPeeringConnectionRouteTableSubnets]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEc2VpcPeeringConnectionStatus])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags]))));
}