import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useOpCommonKubernetesResourceInfoItemElements, useOpDefaultResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../..";
import { Contract } from "../../../../../../../../../../common";
import { KubernetesResourceDataHelper } from "../../../../../../utilities";
import { Info } from "../../../../components";
import { KubernetesYamlInfoCard } from "../../components";

export function useOpDefaultKubernetesResourceDefinition(resourceModel: Contract.OpResourceModel, options?: DefinitionOptions) {
    const commonKubernetesResourceInfoItemElements = useOpCommonKubernetesResourceInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useOpDefaultResourceInfoItemElements(resourceModel);
    const resource = resourceModel.entity as Contract.OpKubernetesResourceBase;

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.op.useOpDefaultKubernetesResourceDefinition",
            () => ({
                tabs: {
                    yaml: "YAML"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs:
            _<EntityProfileDefinitionTab>([]).
                concatIf(
                    !_.isNil(resource.data.rawYaml),
                    new EntityProfileDefinitionTab(
                        ProfileCategory.Overview,
                        <KubernetesYamlInfoCard rawYaml={resource.data.rawYaml!}/>,
                        localization.tabs.yaml(),
                        "yaml")).
                value(),
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={[]}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    commonKubernetesResourceInfoItemElements.tagsInfoItemElement,
                    commonKubernetesResourceInfoItemElements.annotationsInfoItemElement,
                    commonKubernetesResourceInfoItemElements.clusterInfoItemElement
                ]}
                options={options?.infoOptions}>
            </Info>,
        sensitive: KubernetesResourceDataHelper.entitySensitiveToggle(resource.data)
    });
}