import { Message, Optional, Tooltip, useLocalization } from "@infrastructure";
import { Box, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract, OutlineLabelButton, RatioGaugeChart, useTheme } from "../../../../../../../../../../../../../common";

type DataAnalysisTableSummarySectionProps = {
    countLoading?: boolean;
    dataSensitiveResourceCount?: number;
    getFilterActive: (filterKey: string, valueOrValues: string | string[]) => Optional<boolean>;
    monitoredResourceCount?: number;
    notMonitoredResourceCount?: number;
    onClick: (filterKey: string, newValue: string | string[], override?: boolean) => void;
};

export function DataAnalysisTableSummaryResourcesSection({ countLoading, dataSensitiveResourceCount, getFilterActive, monitoredResourceCount, notMonitoredResourceCount, onClick }: DataAnalysisTableSummarySectionProps) {
    const monitoredIsActive =
        getFilterActive(
            Contract.EntityModelProperty.DataAnalysisResourceScanStatus,
            [
                Contract.DataAnalysisResourceModelDataAnalysisStatus.Scanned,
                Contract.DataAnalysisResourceModelDataAnalysisStatus.Pending
            ]);
    const unmonitoredIsActive =
        getFilterActive(
            Contract.EntityModelProperty.DataAnalysisResourceScanStatus,
            [
                Contract.DataAnalysisResourceModelDataAnalysisStatus.Disabled,
                Contract.DataAnalysisResourceModelDataAnalysisStatus.Failed
            ]);

    const loading =
        countLoading ||
        _.isNil(dataSensitiveResourceCount || monitoredResourceCount || notMonitoredResourceCount);

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.useDataAnalysisResourceDefinition.dataAnalysisTableSummarySection.dataAnalysisTableSummaryResourcesSection",
            () => ({
                monitored: "Monitored",
                sensitiveResources: {
                    title: "Sensitive Resources",
                    tooltip: {
                        pluralizer: [
                            "1 sensitive resource",
                            "{{count | NumberFormatter.humanize}} sensitive resources"
                        ]
                    }
                },
                unmonitored: {
                    title: "Unmonitored",
                    tooltip: "Data analysis not available for these resources"
                }
            }));

    dataSensitiveResourceCount = dataSensitiveResourceCount ?? 0;
    monitoredResourceCount = monitoredResourceCount ?? 0;
    notMonitoredResourceCount = notMonitoredResourceCount ?? 0;

    const theme = useTheme();
    return (
        <Stack
            columnGap={3}
            direction="row"
            sx={{ minWidth: "270px" }}>
            <Tooltip titleOrGetTitle={localization.sensitiveResources.tooltip.pluralizer(dataSensitiveResourceCount || 0)}>
                <Stack
                    alignItems="center"
                    flexShrink={0}
                    rowGap={theme.spacing(0.8)}>
                    <RatioGaugeChart
                        count={dataSensitiveResourceCount}
                        labelFontSize={16}
                        thickness={4}
                        total={monitoredResourceCount + notMonitoredResourceCount}
                        width={82}/>
                    <Typography
                        sx={{
                            color: theme.palette.text.secondary,
                            fontSize: theme.px(12)
                        }}>
                        {localization.sensitiveResources.title()}
                    </Typography>
                </Stack>
            </Tooltip>
            <Stack
                rowGap={1}
                sx={{ fontSize: theme.px(12) }}>
                <Box>
                    <OutlineLabelButton
                        active={monitoredIsActive}
                        count={monitoredResourceCount}
                        countLoading={loading}
                        label={localization.monitored({})}
                        onClick={
                            () =>
                                onClick(
                                    Contract.EntityModelProperty.DataAnalysisResourceScanStatus,
                                    [
                                        Contract.DataAnalysisResourceModelDataAnalysisStatus.Pending,
                                        Contract.DataAnalysisResourceModelDataAnalysisStatus.Scanned
                                    ])}/>
                </Box>
                <Stack
                    columnGap={theme.spacing(0.75)}
                    direction="row">
                    <OutlineLabelButton
                        active={unmonitoredIsActive}
                        count={notMonitoredResourceCount}
                        countLoading={loading}
                        label={localization.unmonitored.title({})}
                        onClick={
                            () =>
                                onClick(
                                    Contract.EntityModelProperty.DataAnalysisResourceScanStatus,
                                    [
                                        Contract.DataAnalysisResourceModelDataAnalysisStatus.Disabled,
                                        Contract.DataAnalysisResourceModelDataAnalysisStatus.Failed
                                    ])}/>
                    <Message
                        level="infoWarning"
                        title={localization.unmonitored.tooltip()}
                        variant="minimal"/>
                </Stack>
            </Stack>
        </Stack>
    );
}