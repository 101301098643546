﻿import { Loading, WizardLayout } from "@infrastructure";
import { Box, List, Stack, Typography, useTheme } from "@mui/material";
import _ from "lodash";
import React, { useState } from "react";
import { Change, Contract } from "../../../../../../../../../../../../../../common";
import { ChangeListItem } from "../../../../../../../../../../../../../../common/components/RiskResolutionAutomation/components/EditItem/components";
import { EntityProfileInfo } from "../../../../../../../../../../../Entities/components/Profile/components";

export type InfoItemProps = {
    changeModels: Contract.ChangeModel[];
    entityId: string;
};

export function InfoItem({ changeModels, entityId }: InfoItemProps) {
    const [selectedChangeId, setSelectedChangeId] = useState(_.first(changeModels)!.change.id);

    const theme = useTheme();
    return (
        <WizardLayout
            disablePadding={true}
            sideElement={
                <List disablePadding={true}>
                    {_.map(
                        changeModels,
                        (changeModel, changeModelIndex) => (
                            <ChangeListItem
                                disabled={false}
                                key={changeModel.change.id}
                                selected={changeModel.change.id === selectedChangeId}
                                onClick={() => setSelectedChangeId(changeModel.change.id)}>
                                <Stack
                                    alignItems="center"
                                    direction="row">
                                    <Box
                                        sx={{
                                            alignItems: "center",
                                            border: theme.border.primary,
                                            borderRadius: theme.spacing(0.75),
                                            display: "flex",
                                            height: theme.spacing(3),
                                            justifyContent: "center",
                                            marginRight: theme.spacing(1),
                                            width: theme.spacing(3),
                                            ...(selectedChangeId === changeModel.change.id
                                                ? {
                                                    backgroundColor: theme.palette.primary.main,
                                                    color: theme.palette.common.white
                                                }
                                                : undefined)
                                        }}>
                                        <Typography>
                                            {changeModelIndex + 1}
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{ flex: 1 }}>
                                        <Change
                                            change={changeModel.change}
                                            entityLinkDisabled={true}/>
                                    </Box>
                                </Stack>
                            </ChangeListItem>))}
                </List>}>
            <Box
                sx={{
                    height: "100%",
                    overflow: "hidden",
                    padding:
                        _.isNil(selectedChangeId)
                            ? theme.spacing(2.5)
                            : undefined,
                    position: "relative"
                }}>
                <Loading>
                    <EntityProfileInfo
                        entityId={entityId}
                        key={selectedChangeId}/>
                </Loading>
            </Box>
        </WizardLayout>);
}