﻿import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useAzureContainerServiceManagedClusterNetworkTypeTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useAzureContainerServiceManagedClusterNetworkTypeTranslator",
            () => ({
                [Contract.TypeNames.AzureContainerServiceManagedClusterNetworkType]: {
                    [Contract.AzureContainerServiceManagedClusterNetworkType.Azure]: "Azure",
                    [Contract.AzureContainerServiceManagedClusterNetworkType.Kubenet]: "Kubenet",
                    [Contract.AzureContainerServiceManagedClusterNetworkType.None]: "None"
                }
            }));
    return (networkType: Contract.AzureContainerServiceManagedClusterNetworkType) =>
        localization[Contract.TypeNames.AzureContainerServiceManagedClusterNetworkType][networkType]();
}