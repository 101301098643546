import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function OrIcon(props: SvgIconProps) {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <path 
                clipRule="evenodd"
                d="M15 17C14.4477 17 14 16.5523 14 16V8C14 7.44772 14.4477 7 15 7C15.5523 7 16 7.44772 16 8V16C16 16.5523 15.5523 17 15 17Z"
                fillRule="evenodd"/>
            <path 
                clipRule="evenodd"
                d="M19 4H5C4.44772 4 4 4.44772 4 5V19C4 19.5523 4.44772 20 5 20H19C19.5523 20 20 19.5523 20 19V5C20 4.44772 19.5523 4 19 4ZM5 2C3.34315 2 2 3.34315 2 5V19C2 20.6569 3.34315 22 5 22H19C20.6569 22 22 20.6569 22 19V5C22 3.34315 20.6569 2 19 2H5Z"
                fillRule="evenodd"/>
            <path 
                clipRule="evenodd"
                d="M9 17C8.44772 17 8 16.5523 8 16V8C8 7.44772 8.44772 7 9 7C9.55228 7 10 7.44772 10 8V16C10 16.5523 9.55228 17 9 17Z"
                fillRule="evenodd"/>
        </SvgIcon>);
}