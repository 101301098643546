import { Link, Message, useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { BuiltInEntityAttributeHelper, Contract, customEntityAttributeDefinitionModelStore, CustomerConsoleAppUrlHelper, EntityAttributeDefinitionMultiSelect, EntityAttributeDefinitions, RiskPolicySelect, RiskStatusMultiSelect, ScopeHelper, scopeNodeModelStore, SeveritySelector, useScopeNavigationViewContext, useTheme } from "../../../../../../../../../../../common";
import { useAddOrEditContext, useSetAddOrEditContext } from "../AddOrEdit";

type RiskPoliciesItem = {
    setValidation: (valid: boolean) => void;
};

export function RiskPoliciesItem({ setValidation }: RiskPoliciesItem) {
    const customEntityAttributeDefinitionModels = customEntityAttributeDefinitionModelStore.useGetAll();
    const { scopeNodeModel } = useScopeNavigationViewContext();
    const scopeNodeMap = scopeNodeModelStore.useGetScopeNodeMap();
    const {
        automationRuleBuiltInEntityAttributeTypeNames,
        automationRuleCustomEntityAttributeDefinitionIds,
        automationRuleCustomRiskPolicyIds,
        automationRuleMinSeverity,
        automationRuleRiskPolicyConfigurationTypeNames,
        automationRuleRiskSubStatuses,
        automationRuleScopeIds
    } = useAddOrEditContext();
    const setAddOrEditContext = useSetAddOrEditContext();

    const scopeBuiltInEntityAttributeTypeNames =
        useMemo(
            () => BuiltInEntityAttributeHelper.getBuiltInEntityAttributeTypeNames(scopeNodeMap[scopeNodeModel.configuration.id].getTenantTypes()),
            [scopeNodeModel.configuration.id]);

    const scopeCustomEntityAttributeDefinitionIds =
        useMemo(
            () =>
                _(customEntityAttributeDefinitionModels).
                    filter(
                        customEntityAttributeDefinitionModel =>
                            customEntityAttributeDefinitionModel.configuration.scopeId === ScopeHelper.customerId ||
                            customEntityAttributeDefinitionModel.configuration.scopeId === scopeNodeModel.configuration.id).
                    map(customEntityAttributeDefinitionModel => customEntityAttributeDefinitionModel.configuration.id).
                    value(),
            [customEntityAttributeDefinitionModels]);
    const [builtInRiskPolicyConfigurationTypeNames, setBuiltInRiskPolicyConfigurationTypeNames] =
        useState(
            !_.isNil(automationRuleRiskPolicyConfigurationTypeNames) && _.isEmpty(automationRuleRiskPolicyConfigurationTypeNames)
                ? undefined
                : automationRuleRiskPolicyConfigurationTypeNames);
    const [customRiskPolicyIds, setCustomRiskPolicyIds] =
        useState(
            !_.isNil(automationRuleCustomRiskPolicyIds) && _.isEmpty(automationRuleCustomRiskPolicyIds)
                ? undefined
                : automationRuleCustomRiskPolicyIds);
    const [minSeverity, setMinSeverity] = useState(automationRuleMinSeverity);

    const [selectedBuiltInEntityAttributeTypeNames, setSelectedBuiltInEntityAttributeTypeNames] = useState<string[]>(automationRuleBuiltInEntityAttributeTypeNames ?? []);
    const [selectedCustomEntityAttributeDefinitionIds, setSelectedCustomEntityAttributeDefinitionIds] = useState(automationRuleCustomEntityAttributeDefinitionIds ?? []);
    const [selectedRiskSubStatuses, setSelectedRiskSubStatuses] = useState<Contract.RiskSubStatus[]>(automationRuleRiskSubStatuses);

    useEffect(
        () => {
            setValidation(
                ((_.isNil(builtInRiskPolicyConfigurationTypeNames) && _.isNil(customRiskPolicyIds)) ||
                    !_.isEmpty(builtInRiskPolicyConfigurationTypeNames) ||
                    !_.isEmpty(customRiskPolicyIds)) &&
                !_.isEmpty(selectedRiskSubStatuses));
        },
        [builtInRiskPolicyConfigurationTypeNames, customRiskPolicyIds, selectedRiskSubStatuses]);

    useEffect(
        () => {
            setAddOrEditContext(
                addOrEditContext => ({
                    ...addOrEditContext,
                    automationRuleBuiltInEntityAttributeTypeNames:
                        _.isEmpty(selectedBuiltInEntityAttributeTypeNames)
                            ? undefined
                            : selectedBuiltInEntityAttributeTypeNames,
                    automationRuleCustomEntityAttributeDefinitionIds:
                        _.isEmpty(selectedCustomEntityAttributeDefinitionIds)
                            ? undefined
                            : selectedCustomEntityAttributeDefinitionIds,
                    automationRuleCustomRiskPolicyIds: customRiskPolicyIds,
                    automationRuleMinSeverity: minSeverity,
                    automationRuleRiskPolicyConfigurationTypeNames: builtInRiskPolicyConfigurationTypeNames,
                    automationRuleRiskSubStatuses: selectedRiskSubStatuses
                }));
        },
        [builtInRiskPolicyConfigurationTypeNames, customRiskPolicyIds, minSeverity, selectedBuiltInEntityAttributeTypeNames, selectedCustomEntityAttributeDefinitionIds, selectedRiskSubStatuses]);

    const localization =
        useLocalization(
            "views.customer.configuration.automations.riskAutomations.addOrEdit.riskPoliciesItem",
            () => ({
                entityAttribute: "Only apply on findings with a label of:",
                severity: "Only apply on findings with a minimum severity of:",
                status: {
                    info: "{{link}} to learn more about finding statuses.",
                    link: "Click here",
                    title: "Only apply on findings with a status of:"
                }
            }));

    const onToggleBuiltInEntityAttributeItem =
        useCallback(
            async (builtInEntityAttributeTypeNames: any) =>
                setSelectedBuiltInEntityAttributeTypeNames(
                    selectedBuiltInEntityAttributeTypeNames =>
                        _(selectedBuiltInEntityAttributeTypeNames).
                            intersection(builtInEntityAttributeTypeNames).
                            isEmpty()
                            ? _.concat(selectedBuiltInEntityAttributeTypeNames, builtInEntityAttributeTypeNames)
                            : _.without(selectedBuiltInEntityAttributeTypeNames, ...builtInEntityAttributeTypeNames)),
            [setSelectedBuiltInEntityAttributeTypeNames]);
    const onToggleCustomEntityAttributeItem =
        useCallback(
            async (customEntityAttributeDefinitionId: any) =>
                setSelectedCustomEntityAttributeDefinitionIds(
                    selectedCustomEntityAttributeDefinitionIds =>
                        _.includes(selectedCustomEntityAttributeDefinitionIds, customEntityAttributeDefinitionId)
                            ? _.without(selectedCustomEntityAttributeDefinitionIds, customEntityAttributeDefinitionId)
                            : _.concat(selectedCustomEntityAttributeDefinitionIds, customEntityAttributeDefinitionId)),
            [setSelectedCustomEntityAttributeDefinitionIds]);

    const theme = useTheme();
    return (
        <Stack spacing={2}>
            <RiskPolicySelect
                allPoliciesEnabled={true}
                containerSx={{
                    border: 0,
                    padding: theme.spacing(1, 0, 0, 1)
                }}
                scopeId={scopeNodeModel.configuration.id}
                selectedBuiltInRiskPolicyConfigurationTypeNames={builtInRiskPolicyConfigurationTypeNames}
                selectedCustomRiskPolicyIds={customRiskPolicyIds}
                selectedScopeIds={automationRuleScopeIds}
                setSelectedBuiltInRiskPolicyConfigurationTypeNames={setBuiltInRiskPolicyConfigurationTypeNames}
                setSelectedCustomRiskPolicyIds={setCustomRiskPolicyIds}/>
            <Stack
                alignItems="center"
                direction="row"
                spacing={2}>
                <Typography variant="h5">
                    {localization.severity()}
                </Typography>
                <SeveritySelector
                    justify="left"
                    selectedSeverity={minSeverity}
                    variant="outlined"
                    onSelectedSeverityChanged={setMinSeverity}/>
            </Stack>
            <Stack
                alignItems="center"
                direction="row"
                spacing={2}>
                <Typography variant="h5">
                    {localization.status.title()}
                </Typography>
                <RiskStatusMultiSelect
                    selectedSubStatuses={selectedRiskSubStatuses}
                    onSelectedSubStatusesChanged={setSelectedRiskSubStatuses}/>
                <Message
                    level="info"
                    title={localization.status.info({
                        link:
                            <Link
                                urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsViewAndManageFindingsFindingStatusRelativeUrl()}
                                variant="external">
                                {localization.status.link()}
                            </Link>
                    })}
                    variant="minimal"/>
            </Stack>
            {!(_.isEmpty(scopeBuiltInEntityAttributeTypeNames) && _.isEmpty(scopeCustomEntityAttributeDefinitionIds)) && (
                <Stack
                    direction="row"
                    spacing={2}>
                    <Stack
                        alignItems="center"
                        direction="row"
                        spacing={2}>
                        <Typography
                            noWrap={true}
                            variant="h5">
                            {localization.entityAttribute()}
                        </Typography>
                        <EntityAttributeDefinitionMultiSelect
                            builtInEntityAttributeTypeNames={scopeBuiltInEntityAttributeTypeNames}
                            customEntityAttributeDefinitionIds={scopeCustomEntityAttributeDefinitionIds}
                            selectedBuiltInEntityAttributeTypeNames={selectedBuiltInEntityAttributeTypeNames}
                            selectedCustomEntityAttributeDefinitionIds={selectedCustomEntityAttributeDefinitionIds}
                            variant={
                                _.isEmpty(selectedCustomEntityAttributeDefinitionIds)
                                    ? "button"
                                    : "icon"}
                            onToggleBuiltInEntityAttributeItem={onToggleBuiltInEntityAttributeItem}
                            onToggleCustomEntityAttributeItem={onToggleCustomEntityAttributeItem}/>
                    </Stack>
                    <EntityAttributeDefinitions
                        builtInEntityAttributeTypeNames={selectedBuiltInEntityAttributeTypeNames}
                        customEntityAttributeDefinitionIds={selectedCustomEntityAttributeDefinitionIds}
                        variant="wrap"/>
                </Stack>)}
        </Stack>);
}