import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function OxSecurityIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                d="M0.0269058 3H2.93274L2.95964 3.02691L2.98655 4.04933L3.06726 4.47982L3.20179 4.8296L3.39013 5.15247L3.6861 5.47534L4.00897 5.71749L4.33184 5.87892L4.68161 5.98655L5.0852 6.04036L8.79821 6.06726L9.39013 6.14798L9.90135 6.28251L10.4664 6.49776L11.0314 6.79372L11.4888 7.11659L11.8386 7.41256L11.9731 7.54708L12.0538 7.57399L12.1076 7.46637L12.3229 7.27803L12.6996 6.98206L13.13 6.713L13.5605 6.49776L14.0179 6.30942L14.6368 6.14798L15.1749 6.06726L18.9417 6.04036L19.4529 5.95964L19.8565 5.79821L20.2332 5.55605L20.4753 5.34081L20.7444 4.96413L20.9327 4.53363L21.0135 4.18386L21.0404 3.78027L21.0673 3H24V4.18386L23.9462 4.69507L23.8386 5.20628L23.6502 5.7713L23.435 6.25561L23.1121 6.79372L22.8161 7.1704L22.5202 7.49327L22.3049 7.70852L21.9283 8.00448L21.5247 8.27354L21.0942 8.51569L20.5561 8.73094L19.991 8.89238L19.5067 8.97309L19.2108 9L15.4978 9.02691L15.0673 9.10762L14.7175 9.24215L14.3139 9.4843L13.9641 9.78027L13.5605 10.1839L13.2646 10.5605L12.9417 11.0179L12.5381 11.7175L12.1883 12.4439L12.0269 12.8744L11.9731 12.8206L11.7848 12.2018L11.5157 11.5291L11.1659 10.8565L10.9507 10.5336L10.6816 10.1839L10.3587 9.83408L10.0897 9.61883L9.6861 9.34978L9.2287 9.16143L8.7713 9.05381L8.55605 9.02691L4.78924 9L4.30493 8.94619L3.6861 8.81166L3.09417 8.59641L2.47534 8.27354L2.04484 7.97758L1.66816 7.65471L1.23767 7.22421L0.887892 6.76682L0.64574 6.36323L0.430493 5.93274L0.269058 5.50224L0.134529 5.04484L0.0269058 4.42601L0 4.21076V3.02691L0.0269058 3Z"
                fill="#6032F9"/>
            <path
                d="M4.76233 10.6682L8.60987 10.6951L9.01345 11.3677L9.44395 12.0942L9.6861 12.4978L10.1973 13.3587L10.6009 14.0314L11.0583 14.8117L11.5157 15.565L11.7848 16.0224L12 16.3722L12.0807 16.2915L12.5112 15.565L12.9686 14.8117L13.3722 14.139L13.722 13.5471L14.0448 13.009L14.5022 12.2556L14.852 11.6637L15.0942 11.2601L15.417 10.7489L15.4709 10.6951H19.2646L19.2377 10.8027L18.9148 11.3408L18.4843 12.0404L17.1928 14.1659L16.7623 14.8655L16.3049 15.6188L15.9283 16.2377L15.4978 16.9372L14.583 18.4439L14.2063 19.0628L13.7758 19.7623L13.426 20.3274L13.3991 20.3543H10.7085L10.574 20.2735L9.65919 18.7668L9.2287 18.0673L8.3139 16.5605L7.88341 15.861L6.59193 13.7354L6.16144 13.0359L5.32735 11.6637L4.97758 11.0987L4.76233 10.7489V10.6682Z"
                fill="#6032F9"/>
        </SvgIcon>);
}