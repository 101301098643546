import { Action0, Dropdown, DropdownActions, EmptyMessageText, ItemSelectorField, ItemSelectorFieldPropsVariant, SearchList, Sx } from "@infrastructure";
import { ListItemButton, Stack, SxProps, Typography, useTheme } from "@mui/material";
import _ from "lodash";
import React, { ReactElement, ReactNode, useRef, useState } from "react";

export type ItemSelectorProps = {
    children?: (item: any, inline: boolean) => ReactNode;
    className?: string;
    containerSx?: SxProps;
    dense?: boolean;
    disabled?: boolean;
    disablePopover?: boolean;
    dropdownIcon?: ReactElement;
    emptyMessageText?: EmptyMessageText;
    errorMessage?: string;
    fieldSx?: SxProps;
    fieldVariant?: ItemSelectorFieldPropsVariant;
    fullWidth?: boolean;
    getItemDisabled?: (item: any) => boolean;
    getItemText?: (item: any) => string | string[];
    items: any[];
    listItemSx?: SxProps;
    onSelectedItemChanged: (item: any) => void;
    onSelectionCleared?: Action0;
    placeholder?: string;
    selectedItem: any;
    sorted?: boolean;
};

export function ItemSelector({ children: renderItem, className, containerSx, dense = false, disabled = false, disablePopover = false, dropdownIcon, emptyMessageText, errorMessage, fieldSx, fieldVariant, fullWidth = false, getItemDisabled, getItemText, items, listItemSx, onSelectedItemChanged, onSelectionCleared = undefined, placeholder, selectedItem, sorted }: ItemSelectorProps) {
    const dropdownActionsRef = useRef<DropdownActions>(null);
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    return (
        <Stack
            className={className}
            sx={containerSx}>
            <Dropdown
                actionsRef={dropdownActionsRef}
                disabled={disabled}
                fullWidth={fullWidth}
                open={open}
                popoverElement={
                    <SearchList
                        dense={true}
                        disablePadding={true}
                        emptyMessageText={emptyMessageText}
                        itemOptions={{
                            getSortValue: getItemText,
                            render:
                                item =>
                                    <ListItemButton
                                        dense={true}
                                        disabled={getItemDisabled?.(item)}
                                        sx={
                                            Sx.combine(
                                                item === selectedItem
                                                    ? { 
                                                        backgroundColor: theme.palette.action.hover,
                                                        fontWeight: 600 
                                                    }
                                                    : {},
                                                listItemSx,
                                                { borderRadius: theme.spacing(0.75) })}
                                        onClick={
                                            () => {
                                                dropdownActionsRef.current!.close();
                                                onSelectedItemChanged(item);
                                            }}>
                                        {renderItem?.(item, false) ?? item}
                                    </ListItemButton>
                        }}
                        items={items}
                        searchOptions={{ enabled: !_.isNil(getItemText) }}
                        sorted={sorted}
                        sx={{ padding: theme.spacing(0, 0.5) }}
                        variant="dropdown"/>}
                popoverElementContainerSx={{ padding: 0 }}
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}>
                <ItemSelectorField
                    dense={dense}
                    disabled={disabled}
                    disablePopover={disablePopover}
                    error={!_.isNil(errorMessage)}
                    icon={dropdownIcon}
                    open={open}
                    placeholder={placeholder}
                    selection={
                        _.isNil(renderItem) || _.isNil(selectedItem)
                            ? selectedItem
                            : renderItem(selectedItem, true)}
                    sx={fieldSx}
                    variant={fieldVariant}
                    onSelectionCleared={onSelectionCleared}/>
            </Dropdown>
            {errorMessage &&
                <Typography
                    sx={{
                        color: theme.palette.error.main,
                        padding: theme.spacing(0.25, 2)
                    }}>
                    {errorMessage}
                </Typography>}
        </Stack>);
}