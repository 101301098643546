import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function GitLabAdvisoryDatabaseIcon(props: SvgIconProps) {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <path
                d="M22.3767 9.70819L22.3471 9.62569L19.3875 1.51536C19.3267 1.35621 19.22 1.22105 19.0823 1.12883C18.9446 1.03662 18.7829 0.991984 18.6199 1.00118C18.4568 1.01038 18.3006 1.07296 18.1731 1.18013C18.0457 1.2873 17.9533 1.43369 17.9091 1.59877L15.9105 8.02434H7.8174L5.81879 1.59877C5.77357 1.43455 5.68078 1.28919 5.55332 1.18287C5.42585 1.07656 5.27003 1.01456 5.10749 1.00549C4.94495 0.996422 4.78375 1.04073 4.64628 1.13226C4.50881 1.2238 4.40188 1.35801 4.34034 1.51628L1.3817 9.62661L1.35115 9.70819C0.925177 10.8775 0.872747 12.1606 1.20177 13.3639C1.53079 14.5673 2.22342 15.6256 3.17521 16.3794L3.18569 16.3886L3.21187 16.4087L7.71529 19.9533L9.94955 21.727L11.3058 22.805C11.4651 22.9315 11.6594 23 11.8591 23C12.0589 23 12.2532 22.9315 12.4125 22.805L13.7696 21.727L16.0039 19.9533L20.5396 16.3877L20.5518 16.3785C21.5036 15.6249 22.1962 14.5667 22.5254 13.3636C22.8546 12.1604 22.8024 10.8775 22.3767 9.70819Z"
                fill="#E24329"/>
            <path
                d="M22.377 9.70798L22.3474 9.62549C20.905 9.93629 19.546 10.5783 18.3676 11.5055L11.8682 16.6679L16.0068 19.9531L20.5425 16.3875L20.5547 16.3783C21.506 15.6243 22.1982 14.566 22.5269 13.3629C22.8556 12.1597 22.803 10.877 22.377 9.70798Z"
                fill="#FC6D26"/>
            <path
                d="M7.71484 19.9532L9.9491 21.7268L11.3062 22.8048C11.4655 22.9313 11.6598 22.9998 11.8596 22.9998C12.0593 22.9998 12.2536 22.9313 12.4129 22.8048L13.77 21.7268L16.0043 19.9532L11.8648 16.668L7.71484 19.9532Z"
                fill="#FCA326"/>
            <path
                d="M5.36059 11.5056C4.18229 10.5784 2.82319 9.9367 1.38082 9.62646L1.35115 9.70804C0.925177 10.8774 0.872747 12.1604 1.20177 13.3638C1.53079 14.5671 2.22342 15.6255 3.17521 16.3793L3.18569 16.3884L3.21187 16.4086L7.71529 19.9532L11.8565 16.668L5.35972 11.5056H5.36059Z"
                fill="#FC6D26"/>
        </SvgIcon>);
}