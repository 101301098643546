import { useLocalization } from "@infrastructure";
import _ from "lodash";
import { Contract, entityModelStore, useEntityTypeNameTranslator } from "../../../../../../../../../../../../../../../common";
import { AzureAccessPrincipalRiskDefinition } from "../useDefinition";

export function useAadDirectoryManagedIdentityServicePrincipalDefinition(riskModel: Contract.AzureAccessPrincipalRiskModel) {
    const aadDirectoryManagedIdentityServicePrincipalModel = entityModelStore.useGet(riskModel.risk.entityId);
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.access.hooks.useDefinition.hooks.useAadDirectoryManagedIdentityServicePrincipalDefinition",
            () => ({
                relatedPublicCompute: "The managed identity is used by {{originatorResourceTypeName}} that are publicly accessible",
                relatedWorkloadResourceVulnerability: "The managed identity is used by {{originatorResourceTypeName}} with critical vulnerabilities"
            }));

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const originatorResourceTypeName =
        entityTypeNameTranslator(
            Contract.TypeNames.IAzureManagedIdentityOriginatorResource,
            {
                count: 0,
                includeServiceName: false,
                variant: "text"
            }
        );

    return new AzureAccessPrincipalRiskDefinition([
        _.some(
            aadDirectoryManagedIdentityServicePrincipalModel.attributes.attributes,
            attribute => attribute.typeName == Contract.TypeNames.RelatedPublicComputeAttribute)
            ? localization.relatedPublicCompute({ originatorResourceTypeName })
            : undefined,
        _.some(
            aadDirectoryManagedIdentityServicePrincipalModel.attributes.attributes,
            attribute => attribute.typeName == Contract.TypeNames.RelatedWorkloadResourceVulnerabilityAttribute)
            ? localization.relatedWorkloadResourceVulnerability({ originatorResourceTypeName })
            : undefined
    ]);
}