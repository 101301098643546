import { InlineItems, useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import React from "react";
import { useCommonCustomSectionsAndDescriptionDefinition } from "../../..";
import { Contract, Entity, entityModelStore, InlineEntities } from "../../../../../../../../../../../../common";
import { Events } from "../../../../../../../../../Events";

export function useAwsIdentityActivityRiskDefinition(riskModel: Contract.RiskModel) {
    const identityActivityRiskModel = riskModel as Contract.AwsIdentityActivityRiskModel;
    const identityModel = entityModelStore.useGet(identityActivityRiskModel.risk.identityId);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.custom.useAwsIdentityActivityRiskDefinition",
            () => ({
                eventNames: [
                    "1 action",
                    "{{count | NumberFormatter.humanize}} actions"
                ],
                violations: "{{identity}} performed {{eventNames}} on {{services}}"
            }));

    const translatedViolations =
        localization.violations({
            eventNames:
                <InlineItems
                    items={identityActivityRiskModel.risk.eventNames}
                    namePluralizer={localization.eventNames}
                    variant="itemOrItemCountAndType">
                    {(eventName: string) =>
                        <Typography
                            component="span"
                            noWrap={true}>
                            {eventName}
                        </Typography>}
                </InlineItems>,
            identity:
                <Entity
                    entityIdOrModel={identityModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>,
            services:
                <InlineEntities
                    entityIdsOrModels={identityActivityRiskModel.serviceIdReferences}
                    entityTypeName={Contract.TypeNames.AwsService}
                    variant="itemAndTypeOrItemCountAndType"/>
        });
    return useCommonCustomSectionsAndDescriptionDefinition(
        translatedViolations,
        riskModel,
        "violations",
        <Events riskId={identityActivityRiskModel.risk.id}/>);
}