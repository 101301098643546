import { Message, useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract, InfoItem } from "../../../../../../../../../../common";
import { AwsResourceAccessLevel } from "../../../../../../../../../../tenants";

export type useAwsCommonAccessResourceInfoElementsOptions = {
    accessLevel?: {
        title?: string;
        warningMessage?: string;
    };
};

export function useAwsCommonAccessResourceInfoElements(entityModel: Contract.AwsEntityModel, options?: useAwsCommonAccessResourceInfoElementsOptions) {
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsCommonAccessResourceInfoElements",
            () => ({
                accessLevel: "Access",
                unknown: "Unknown"
            }));

    const getAccessLevelInfoItemElement =
        (title?: string, warningMessage?: string) => {
            title = title ?? localization.accessLevel();
            const accessLevel = _.as<Contract.IAwsAccessResourceModel>(entityModel).accessLevel;
            return _.isNil(accessLevel)
                ? _.isNil(warningMessage)
                    ? undefined
                    : <InfoItem
                        key="accessLevel"
                        title={title}
                        value={
                            <Stack
                                direction="row"
                                spacing={1}>
                                <Typography noWrap={true}>
                                    {localization.unknown()}
                                </Typography>
                                <Message
                                    level="infoWarning"
                                    title={warningMessage}
                                    variant="minimal"/>
                            </Stack>}/>
                : <InfoItem
                    key="accessLevel"
                    title={title}
                    value={<AwsResourceAccessLevel accessLevel={accessLevel}/>}/>;
        };
    return [getAccessLevelInfoItemElement(options?.accessLevel?.title, options?.accessLevel?.warningMessage)];
}