import { Link, Step, Steps, useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { useGetAwsElastiCacheReplicationGroupRiskContext } from "../contexts";

export function useAwsElastiCacheReplicationGroupEncryptionDisabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsElastiCacheReplicationGroupEncryptionDisabledRisk;
    const elastiCacheReplicationGroupModel = entityModelStore.useGet(risk.entityId) as Contract.AwsElastiCacheReplicationGroupModel;

    const getAwsElastiCacheReplicationGroupRiskContext = useGetAwsElastiCacheReplicationGroupRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsElastiCacheReplicationGroupEncryptionDisabledRiskDefinition",
            () => ({
                description: "{{cache}} is not configured with encryption using KMS",
                sections: {
                    resolution: {
                        step1: {
                            step1: "Click **Backup**.",
                            step2: "Fill in the backup name.",
                            step3: "Click **Create Backup**.",
                            title: "Create a manual backup of your existing replication group."
                        },
                        step2: {
                            step1: {
                                link: "ElastiCache Backups",
                                text: "Navigate to {{elastiCacheBackupsUrlLink}}."
                            },
                            step2: "Select the newly created backup.",
                            step3: "Click **Restore**.",
                            step4: "Select **Design your own cache**.",
                            step5: "Click **Next**.",
                            step6: "Under **Security** section, set **Encryption at rest** to **Enabled**.",
                            step7: "Choose between **Default key** and **Customer managed CMK**",
                            step8: "Before saving the changes, make sure the relevant identities have the right permissions to the KMS key used to encrypt.",
                            step9: "Click **Next** and then **Create**.",
                            title: "Create a new replication group by restoring from the backup and enable encryption at-rest."
                        },
                        step3: "Update the endpoints in your application to point to the new replication group.",
                        step4: "Delete the old replication group."
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            cache:
                <Entity
                    entityIdOrModel={elastiCacheReplicationGroupModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.ElastiCache,
                AwsConsoleUrlBuilder.getElastiCacheReplicationGroupUrl(elastiCacheReplicationGroupModel.entity as Contract.AwsElastiCacheReplicationGroup)),
            new Step(
                localization.sections.resolution.step1.title(),
                {
                    contentElement:
                        <Steps variant="plainNumbers">
                            {localization.sections.resolution.step1.step1()}
                            {localization.sections.resolution.step1.step2()}
                            {localization.sections.resolution.step1.step3()}
                        </Steps>
                }),
            new Step(
                localization.sections.resolution.step2.title(),
                {
                    contentElement:
                        <Steps variant="plainNumbers">
                            {localization.sections.resolution.step2.step1.text({
                                elastiCacheBackupsUrlLink:
                                    <Link
                                        urlOrGetUrl={AwsConsoleUrlBuilder.getElastiCacheBackupsUrl(elastiCacheReplicationGroupModel.entity as Contract.AwsElastiCacheReplicationGroup)}
                                        variant="external">
                                        {localization.sections.resolution.step2.step1.link()}
                                    </Link>
                            })}
                            {localization.sections.resolution.step2.step2()}
                            {localization.sections.resolution.step2.step3()}
                            {localization.sections.resolution.step2.step4()}
                            {localization.sections.resolution.step2.step5()}
                            {localization.sections.resolution.step2.step6()}
                            {localization.sections.resolution.step2.step7()}
                            {localization.sections.resolution.step2.step8()}
                            {localization.sections.resolution.step2.step9()}
                        </Steps>
                }),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4()
        ],
        riskModel,
        () => {
            const elastiCacheReplicationGroupRiskContext = getAwsElastiCacheReplicationGroupRiskContext(elastiCacheReplicationGroupModel);
            return [
                elastiCacheReplicationGroupRiskContext.generalInformation,
                elastiCacheReplicationGroupRiskContext.sensitive,
                elastiCacheReplicationGroupRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}