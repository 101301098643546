import { ActionMenuItem, AddIcon, AnalyticsEventActionType, DropdownActions, FilterOption, Menu, useFiltersContext, useLocalization, useSetFiltersContext } from "@infrastructure";
import { Button, IconButton, useTheme } from "@mui/material";
import _ from "lodash";
import React, { useCallback, useMemo, useRef } from "react";

export function AddFilter() {
    const { filterOptions, visibleFilters } = useFiltersContext();
    const setFiltersContext = useSetFiltersContext();
    const dropdownActionsRef = useRef<DropdownActions>();
    const onMenuItemClick =
        useCallback(
            (filter: FilterOption) => () => {
                dropdownActionsRef.current?.close();
                setFiltersContext(context => ({
                    ...context,
                    activeFilter: filter.id,
                    visibleFilters: [...context.visibleFilters, filter.id]
                }));
            },
            [dropdownActionsRef]);

    const menuItems =
        useMemo(
            () =>
                _(filterOptions).
                    filter(filter => !visibleFilters.includes(filter.id)).
                    map(filter =>
                        new ActionMenuItem(
                            onMenuItemClick(filter),
                            filter.title,
                            {
                                analyticsOptions: {
                                    onClick: {
                                        actionType: AnalyticsEventActionType.FilterValueOpen,
                                        propertyNameToValueMap: {
                                            "Filter ID": filter.id,
                                            "Filter Name": filter.title!,
                                            "Source": "Filters"
                                        }
                                    }
                                }
                            }
                        )).
                    value(),
            [filterOptions, visibleFilters]);

    const localization =
        useLocalization(
            "infrastructure.filters.addFilter",
            () => ({
                title: "Filters"
            }));

    const theme = useTheme();
    return _.isNil(filterOptions) || visibleFilters.length === filterOptions?.length
        ? null
        : <Menu
            analyticsOptions={{
                onOpen: {
                    actionType: AnalyticsEventActionType.FilterAdd
                }
            }}
            itemsOrGetItems={menuItems}
            itemSx={{ minWidth: theme.spacing(20) }}
            variant="bottomLeft">
            {visibleFilters.length === 0
                ? <Button
                    startIcon={<AddIcon style={{ fontSize: "16px" }}/>}
                    sx={{
                        "&:hover": {
                            background: theme.palette.action.hover,
                            textDecoration: "none"
                        }
                    }}
                    variant="text">
                    {localization.title()}
                </Button>
                : <IconButton
                    sx={{ width: "28px" }}>
                    <AddIcon sx={{ fontSize: "16px" }}/>
                </IconButton>}
        </Menu>;
}