import { Sx } from "@infrastructure";
import { Box, Stack, SxProps, useTheme } from "@mui/material";
import React from "react";

export type PercentBarProps = {
    item: PercentBarItem;
    itemSx?: SxProps;
    totalValue?: number;
};

type PercentBarItem = {
    color: string;
    value: number;
};

export function PercentBar({ item, itemSx, totalValue = 100 }: PercentBarProps) {
    const theme = useTheme();
    return (
        <Stack
            direction="row"
            sx={{
                background: theme.palette.chart.grey,
                borderRadius: theme.spacing(1.25),
                height: theme.spacing(0.5),
                overflow: "hidden",
                width: "100%"
            }}>
            <Box
                sx={
                    Sx.combine(
                        {
                            backgroundColor: item.color,
                            width: `${(item.value / totalValue) * 100}%`
                        },
                        itemSx)}/>
        </Stack>);
}