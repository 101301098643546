import { Grid, Typography } from "@mui/material";
import React from "react";
import type { LabelProps } from "recharts";
import type { PolarViewBox } from "recharts/types/util/types";

export function MultilineText({ dy, style, value, viewBox }: LabelProps) {
    const polarViewBox = viewBox as PolarViewBox;
    return (
        <foreignObject
            height="60px"
            width="132px"
            x={`calc(${polarViewBox.cx}px - 66px)`}
            y={polarViewBox.cy! + Number(dy)}>
            <Grid
                alignItems="center"
                container={true}
                justifyContent="center"
                sx={{ height: "100%" }}>
                <Typography
                    align="center"
                    style={{ ...style }}>
                    {value}
                </Typography>
            </Grid>
        </foreignObject>);
}