﻿import { UnexpectedError } from "@infrastructure";
import { useCodePipelineTypeTranslator, useCodeTenantTypeTranslator } from ".";
import { Contract, TypeHelper } from "../../../common";

export function useCodeIntegrationTranslator() {
    const pipelineTypeTranslator = useCodePipelineTypeTranslator();
    const tenantTypeTranslator = useCodeTenantTypeTranslator();

    return (codeIntegrationConfiguration: Contract.CodeIntegrationConfiguration) => {
        if (TypeHelper.extendOrImplement(codeIntegrationConfiguration.typeName, Contract.TypeNames.CodeOrganizationConfiguration)) {
            return tenantTypeTranslator((codeIntegrationConfiguration as Contract.CodeOrganizationConfiguration).type);
        }

        if (codeIntegrationConfiguration.typeName === Contract.TypeNames.CodePipelineConfiguration) {
            return pipelineTypeTranslator((codeIntegrationConfiguration as Contract.CodePipelineConfiguration).type);
        }

        throw new UnexpectedError("codeIntegrationConfiguration", codeIntegrationConfiguration.typeName);
    };
}