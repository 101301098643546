import _ from "lodash";
import { useLocalization } from "@infrastructure";
import { Contract } from "../../..";

export function useUdmObjectPropertyIdTranslator() {
    const localization =
        useLocalization(
            "common.udmObjectTable.hooks.useUdmObjectPropertyIdTranslator",
            () => ({
                [Contract.TypeNames.UdmObjectPropertyId]: {
                    [Contract.UdmObjectPropertyId.AwsAccessResourceAccessLevel]: {
                        description: "Defines the level of access granted to a resource, such as read-only or full control, within AWS.",
                        title: "Access Level"
                    },
                    [Contract.UdmObjectPropertyId.AwsAutoScalingGroupEc2Images]: {
                        description: "EC2 images associated with the Auto Scaling Group, used to launch instances as part of scaling operations.",
                        title: "EC2 Images"
                    },
                    [Contract.UdmObjectPropertyId.AwsDynamoDbTableSize]: {
                        description: "Represents the total size of the DynamoDB table in bytes, including all items and indexes.",
                        title: "Size"
                    },
                    [Contract.UdmObjectPropertyId.AwsEc2ImageAutoScalingGroupIds]: {
                        description: "The Auto Scaling Group IDs linked to the EC2 image, which automatically adjusts the number of EC2 instances.",
                        title: "Auto Scaling Group"
                    },
                    [Contract.UdmObjectPropertyId.AwsEc2ImageInstances]: {
                        description: "Instances that were launched using a specific EC2 image, showing the usage of the AMI across your environment.",
                        title: "Instances"
                    },
                    [Contract.UdmObjectPropertyId.AwsEc2ImagePlatform]: {
                        description: "The platform of the EC2 image, such as Linux or Windows, which determines the operating system of launched instances.",
                        title: "Platform"
                    },
                    [Contract.UdmObjectPropertyId.AwsEc2InstanceAmiName]: {
                        description: "The name of the Amazon Machine Image (AMI) used to launch the EC2 instance, representing the base image for the instance.",
                        title: "AMI Name"
                    },
                    [Contract.UdmObjectPropertyId.AwsEc2InstanceAmiRawId]: {
                        description: "The unique identifier of the Amazon Machine Image (AMI) used to launch the EC2 instance.",
                        title: "AMI ID"
                    },
                    [Contract.UdmObjectPropertyId.AwsEc2InstanceAvailabilityZone]: {
                        description: "The Availability Zone in which the EC2 instance is running, providing high availability and fault tolerance.",
                        title: "Availability Zone"
                    },
                    [Contract.UdmObjectPropertyId.AwsEc2InstanceCpuCores]: {
                        description: "The number of CPU cores allocated to the EC2 instance, influencing its computational power and performance.",
                        title: "CPU Cores"
                    },
                    [Contract.UdmObjectPropertyId.AwsEc2InstanceImageId]: {
                        description: "The ID of the image from which the EC2 instance was launched, useful for tracking and managing AMIs.",
                        title: "Image ID"
                    },
                    [Contract.UdmObjectPropertyId.AwsEc2InstanceInstanceType]: {
                        description: "The type of the EC2 instance, such as t2.micro or m5.large, which defines the instance’s hardware and performance characteristics.",
                        title: "Instance Type"
                    },
                    [Contract.UdmObjectPropertyId.AwsEc2InstanceKeyPair]: {
                        description: "The key pair used to access the EC2 instance via SSH, providing secure access to the instance.",
                        title: "Key Pair"
                    },
                    [Contract.UdmObjectPropertyId.AwsEc2InstanceLaunchTime]: {
                        description: "The timestamp when the EC2 instance was launched, useful for tracking instance uptime and lifecycle.",
                        title: "Launch Time"
                    },
                    [Contract.UdmObjectPropertyId.AwsEc2InstanceMetadataVersion]: {
                        description: "The version of instance metadata used by the EC2 instance, relevant for accessing instance-specific information.",
                        title: "Metadata Version"
                    },
                    [Contract.UdmObjectPropertyId.AwsEc2InstanceOperatingSystemType]: {
                        description: "The type of operating system running on the EC2 instance, such as Linux or Windows.",
                        title: "Operating System Type"
                    },
                    [Contract.UdmObjectPropertyId.AwsEc2InstanceOperatingSystemVersion]: {
                        description: "The version of the operating system running on the EC2 instance, indicating patch level and features.",
                        title: "Operating System Version"
                    },
                    [Contract.UdmObjectPropertyId.AwsEc2InstancePlatform]: {
                        description: "The platform on which the EC2 instance is running, such as Windows, Linux, or other supported platforms.",
                        title: "Platform"
                    },
                    [Contract.UdmObjectPropertyId.AwsEc2InstancePublicIpAddress]: {
                        description: "The public IPv4 address assigned to the EC2 instance, used to communicate with the instance over the internet.",
                        title: "Public Address"
                    },
                    [Contract.UdmObjectPropertyId.AwsEc2InstancePublicIPv4Dns]: {
                        description: "The public DNS name associated with the EC2 instance’s public IPv4 address, used for domain name resolution.",
                        title: "Public IPv4 DNS"
                    },
                    [Contract.UdmObjectPropertyId.AwsEc2InstanceState]: {
                        description: "The current state of the EC2 instance, such as running, stopped, or terminated, indicating its availability.",
                        title: "State"
                    },
                    [Contract.UdmObjectPropertyId.AwsEc2InstanceUserData]: {
                        description: "The user data script provided when launching the EC2 instance, used to configure the instance at boot time.",
                        title: "User Data"
                    },
                    [Contract.UdmObjectPropertyId.AwsEc2SecurityGroupNetworkedResources]: {
                        description: "Networked resources associated with a specific EC2 security group, detailing the instances, services, and other components that are protected or controlled by the security group.",
                        title: "Security Group Networked Resources"
                    },
                    [Contract.UdmObjectPropertyId.AwsEc2SubnetNetworkedResources]: {
                        description: "Networked resources within a specific EC2 subnet, including instances, services, and other network components that are connected to or interact with the subnet.",
                        title: "Subnet Networked Resources"
                    },
                    [Contract.UdmObjectPropertyId.AwsEc2VpcNetworkedResources]: {
                        description: "Networked resources within the Virtual Private Cloud (VPC) associated with the EC2 instance, defining network connections and isolation.",
                        title: "VPC Networked Resources"
                    },
                    [Contract.UdmObjectPropertyId.AwsEncryptedResourceKmsKey]: {
                        description: "The AWS Key Management Service (KMS) key used to encrypt the resource, providing additional data protection.",
                        title: "KMS Key"
                    },
                    [Contract.UdmObjectPropertyId.AwsIamIdentityActivityTime]: {
                        description: "The last recorded activity time of the IAM identity, used to monitor and audit identity usage.",
                        title: "Identity Activity Time"
                    },
                    [Contract.UdmObjectPropertyId.AwsIamIdentityPermissionBoundaryPolicyId]: {
                        description: "The identifier of the permission boundary policy attached to the IAM identity, which limits its permissions.",
                        title: "Permission Boundary Policy ID"
                    },
                    [Contract.UdmObjectPropertyId.AwsIamInlinePolicyPrincipalIds]: {
                        description: "A list of principal IDs associated with inline policies, defining permissions directly applied to the identity.",
                        title: "Inline Policy Principal IDs"
                    },
                    [Contract.UdmObjectPropertyId.AwsIamManagedPolicyAwsManaged]: {
                        description: "Indicates whether the managed policy is AWS-managed, meaning it is provided and maintained by AWS.",
                        title: "AWS Managed Policy"
                    },
                    [Contract.UdmObjectPropertyId.AwsIamManagedPolicyPermissionBoundaryIdentityIds]: {
                        description: "The list of identity IDs that have this managed policy as their permission boundary, restricting the permissions granted.",
                        title: "Permission Boundary Identity IDs"
                    },
                    [Contract.UdmObjectPropertyId.AwsIamManagedPolicyPermissionServiceIds]: {
                        description: "The list of service IDs that are granted permissions by this managed policy, defining allowed actions across services.",
                        title: "Permission Service IDs"
                    },
                    [Contract.UdmObjectPropertyId.AwsIamManagedPolicyPrincipalIds]: {
                        description: "A list of principal IDs associated with this managed policy, defining which identities have permissions from the policy.",
                        title: "Managed Policy Principal IDs"
                    },
                    [Contract.UdmObjectPropertyId.AwsIamManagedPolicyUpdateTime]: {
                        description: "The timestamp when the managed policy was last updated, indicating changes in permissions or policy structure.",
                        title: "Policy Update Time"
                    },
                    [Contract.UdmObjectPropertyId.AwsIamPrincipalInlinePolicyIds]: {
                        description: "A list of inline policy IDs associated with the IAM principal, defining permissions applied directly to the identity.",
                        title: "Principal Inline Policy IDs"
                    },
                    [Contract.UdmObjectPropertyId.AwsIamPrincipalManagedPolicyIds]: {
                        description: "A list of managed policy IDs associated with the IAM principal, defining which managed policies are attached to the identity.",
                        title: "Principal Managed Policy IDs"
                    },
                    [Contract.UdmObjectPropertyId.AwsIamPrincipalUniqueId]: {
                        description: "The unique identifier of the IAM principal, which is used to distinguish it from other principals in AWS.",
                        title: "Principal Unique ID"
                    },
                    [Contract.UdmObjectPropertyId.AwsKmsKeyEncryptedResources]: {
                        description: "A list of resources that are encrypted using a specific KMS key, showing which data is protected by that key.",
                        title: "Encrypted Resources"
                    },
                    [Contract.UdmObjectPropertyId.AwsKmsKeyRotationTimeFrame]: {
                        description: "The time frame within which the AWS KMS key rotates, ensuring enhanced data security.",
                        title: "Rotation Time Frame"
                    },
                    [Contract.UdmObjectPropertyId.AwsNetworkedResourceSecurityGroupIds]: {
                        description: "The Security Group IDs associated with the networked resource, defining the inbound and outbound traffic rules.",
                        title: "Security Group"
                    },
                    [Contract.UdmObjectPropertyId.AwsNetworkedResourceSubnetIds]: {
                        description: "The Subnet IDs associated with the networked resource, defining specific ranges of IP addresses within the VPC.",
                        title: "Subnet"
                    },
                    [Contract.UdmObjectPropertyId.AwsNetworkedResourceVpcIds]: {
                        description: "The Virtual Private Cloud (VPC) IDs associated with the networked resource, indicating the network boundaries within AWS.",
                        title: "VPC"
                    },
                    [Contract.UdmObjectPropertyId.AwsResourceArn]: {
                        description: "The Amazon Resource Name (ARN) of the resource, a unique identifier used across AWS services.",
                        title: "ARN"
                    },
                    [Contract.UdmObjectPropertyId.AwsServiceManagedPolicyIds]: {
                        description: "A list of policy IDs managed by AWS services, providing pre-defined permissions for specific services.",
                        title: "Service Managed Policy IDs"
                    },
                    [Contract.UdmObjectPropertyId.AzureComputeVirtualMachineAvailabilityZone]: {
                        description: "The Availability Zone within the Azure region where the virtual machine is located, providing redundancy and reliability.",
                        title: "Availability Zone"
                    },
                    [Contract.UdmObjectPropertyId.AzureComputeVirtualMachineState]: {
                        description: "The current state of the Azure virtual machine, such as running, stopped, or deallocated, indicating its operational status.",
                        title: "State"
                    },
                    [Contract.UdmObjectPropertyId.AzureEntityPath]: {
                        description: "The resource path within Azure, representing the hierarchical structure and organization of the resource.",
                        title: "Path"
                    },
                    [Contract.UdmObjectPropertyId.EntityAttributes]: {
                        description: "Custom labels or tags associated with the entity, used for identification, organization, and searchability.",
                        title: "Labels"
                    },
                    [Contract.UdmObjectPropertyId.EntityConsoleUrl]: {
                        description: "The URL to access the entity's management console or user interface, providing direct access for administrators.",
                        title: "Console URL"
                    },
                    [Contract.UdmObjectPropertyId.EntityCreationTime]: {
                        description: "The timestamp when the entity was created, providing historical context for the resource’s lifecycle.",
                        title: "Creation Time"
                    },
                    [Contract.UdmObjectPropertyId.EntityName]: {
                        description: "The human-readable name assigned to the entity, often used for easier identification and management.",
                        title: "Name"
                    },
                    [Contract.UdmObjectPropertyId.EntityNetworkAccessScope]: {
                        description: "Defines the network access scope of the entity, specifying the range or boundaries of allowed network communication.",
                        title: "Network Access Scope"
                    },
                    [Contract.UdmObjectPropertyId.EntityNetworkAccessType]: {
                        description: "Indicates the type of network access the entity has, such as public, private, or hybrid access.",
                        title: "Network Access Type"
                    },
                    [Contract.UdmObjectPropertyId.EntityRegion]: {
                        description: "The region where the resource is located, determining the physical location and availability of the resource.",
                        title: "Region"
                    },
                    [Contract.UdmObjectPropertyId.EntityRisks]: {
                        description: "Lists potential findings associated with the entity, such as security vulnerabilities or compliance issues.",
                        title: "Findings"
                    },
                    [Contract.UdmObjectPropertyId.EntitySyncTime]: {
                        description: "The last time the entity was synchronized with external systems or updated in the system.",
                        title: "Sync Time"
                    },
                    [Contract.UdmObjectPropertyId.EntityTags]: {
                        description: "Custom labels or tags applied to the entity for categorization, organization, or filtering purposes.",
                        title: "Tags"
                    },
                    [Contract.UdmObjectPropertyId.Id]: {
                        description: "A unique identifier representing the entity or resource in the system.",
                        title: "ID"
                    },
                    [Contract.UdmObjectPropertyId.RiskResolutionReason]: {
                        description: "Details the reason for the resolution of a finding, such as mitigation steps taken or false positives.",
                        title: "Resolution Reason"
                    },
                    [Contract.UdmObjectPropertyId.RiskRiskedEntityIds]: {
                        description: "A list of entity IDs that are affected by the identified finding, showing the scope of the finding.",
                        title: "Risked Entity IDs"
                    },
                    [Contract.UdmObjectPropertyId.RiskSeverity]: {
                        description: "Indicates the severity of the finding, typically categorized as low, medium, high, or critical.",
                        title: "Severity"
                    },
                    [Contract.UdmObjectPropertyId.RiskStatus]: {
                        description: "The current status of the finding, such as open, in progress, resolved, or closed.",
                        title: "Status"
                    },
                    [Contract.UdmObjectPropertyId.RiskStatusUpdateTime]: {
                        description: "The last time the finding status was updated, reflecting recent actions or changes in finding posture.",
                        title: "Status Update Time"
                    },
                    [Contract.UdmObjectPropertyId.RiskSubStatus]: {
                        description: "Provides additional context or details regarding the sub-status of a finding, often used for finer categorization.",
                        title: "Sub-Status"
                    },
                    [Contract.UdmObjectPropertyId.TenantType]: {
                        description: "Specifies the type of tenant associated with the resource, such as individual, corporate, or governmental.",
                        title: "Tenant"
                    },
                    [Contract.UdmObjectPropertyId.VendorServiceIdentityVendor]: {
                        description: "Indicates whether the service identity is for a 3*superscript*rd** Party vendor, indicating the external service or provider linked to the service identity.",
                        title: "3*superscript*rd** Party"
                    },
                    [Contract.UdmObjectPropertyId.VendorServiceIdentityVendors]: {
                        description: "A list of vendors associated with the service identity, identifying external services linked to the service identity for various operations.",
                        title: "Service Identity Vendors"
                    },
                    [Contract.UdmObjectPropertyId.VirtualMachineVulnerabilities]: {
                        description: "A list of vulnerabilities associated with the virtual machine, identifying potential security weaknesses.",
                        title: "Vulnerabilities"
                    }
                }
            }));

    return (id: Contract.UdmObjectPropertyId, variant: "title" | "description" = "title") => {
        if (_.isNil(localization[Contract.TypeNames.UdmObjectPropertyId][id])) {
            return id;
        }

        return localization[Contract.TypeNames.UdmObjectPropertyId][id][variant]();
    };
}