﻿import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function StorageStorageAccountBlobContainerIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                d="M23.2747 7.08801H0.725342V20.312C0.725342 20.5118 0.804711 20.7034 0.945988 20.8447C1.08727 20.986 1.27888 21.0653 1.47868 21.0653H12.9831C10.9075 19.9291 9.5 17.7248 9.5 15.1917C9.5 11.496 12.496 8.5 16.1917 8.5C19.8874 8.5 22.8833 11.496 22.8833 15.1917C22.8833 16.2551 22.6344 17.2627 22.1916 18.1573L23.2747 19.2404V7.08801Z"
                fill="url(#paint0_linear_52997_333)"/>
            <path
                d="M7.312 9.21335H6.90133C6.85568 9.21335 6.81866 9.25036 6.81866 9.29602V9.70668C6.81866 9.75234 6.85568 9.78935 6.90133 9.78935H7.312C7.35765 9.78935 7.39467 9.75234 7.39467 9.70668V9.29602C7.39467 9.25036 7.35765 9.21335 7.312 9.21335Z"
                fill="#D15900"/>
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12.323 9.73096L10.7694 8.69736C10.721 8.66435 10.6639 8.64669 10.6054 8.6467H5.96269C5.88419 8.6467 5.8089 8.67788 5.75339 8.73339C5.69788 8.7889 5.66669 8.86419 5.66669 8.9427V11.224V11.224V18.6787V18.6814V18.696C5.66651 18.735 5.67405 18.7735 5.68886 18.8095C5.70368 18.8455 5.72547 18.8782 5.75299 18.9057C5.78051 18.9333 5.81322 18.955 5.84921 18.9699C5.8852 18.9847 5.92377 18.9922 5.96269 18.992H10.6831C9.93702 17.9126 9.5 16.6032 9.5 15.1917C9.5 12.9374 10.6147 10.9434 12.323 9.73096Z"
                fill="#F78D1E"/>
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.0328 10.9294H6.96269C6.92388 10.9292 6.88542 10.9367 6.84952 10.9514C6.81361 10.9661 6.78097 10.9878 6.75347 11.0152C6.72596 11.0426 6.70414 11.0751 6.68925 11.1109C6.67436 11.1468 6.66669 11.1852 6.66669 11.224V18.6787C6.66651 18.7176 6.67405 18.7562 6.68886 18.7922C6.70368 18.8282 6.72547 18.8609 6.75299 18.8884C6.78051 18.9159 6.81322 18.9377 6.84921 18.9525C6.8852 18.9673 6.92377 18.9749 6.96269 18.9747H10.6712C9.93236 17.8986 9.5 16.5956 9.5 15.1917C9.5 13.5721 10.0754 12.0869 11.0328 10.9294Z"
                fill="white"/>
            <path
                d="M1.48167 2.93335H22.5163C22.7161 2.93335 22.9077 3.01272 23.049 3.154C23.1903 3.29527 23.2697 3.48689 23.2697 3.68668V7.08668H0.728334V3.68802C0.728158 3.58898 0.747515 3.49087 0.785295 3.39932C0.823075 3.30777 0.878537 3.22456 0.948508 3.15447C1.01848 3.08437 1.10158 3.02876 1.19307 2.99082C1.28456 2.95288 1.38263 2.93335 1.48167 2.93335Z"
                fill="#0078D4"/>
            <path
                d="M9.576 9.21335H6.94C6.87299 9.21335 6.81866 9.26767 6.81866 9.33468V9.66802C6.81866 9.73503 6.87299 9.78935 6.94 9.78935H9.576C9.64301 9.78935 9.69733 9.73503 9.69733 9.66802V9.33468C9.69733 9.26767 9.64301 9.21335 9.576 9.21335Z"
                fill="white"/>
            <path
                d="M19.4697 17.5567L23.6814 21.7684C23.996 22.083 23.996 22.5932 23.6814 22.9079L23.6022 22.9871C23.2875 23.3018 22.7773 23.3018 22.4626 22.9871L18.2509 18.7754C17.9363 18.4607 17.9363 17.9506 18.2509 17.6359L18.3301 17.5567C18.6448 17.242 19.155 17.242 19.4697 17.5567Z"
                fill="#198AB3"/>
            <path
                d="M16.1917 20.3833C13.3244 20.3833 11 18.0589 11 15.1917C11 12.3244 13.3244 10 16.1917 10C19.0589 10 21.3833 12.3244 21.3833 15.1917C21.3833 18.0589 19.0589 20.3833 16.1917 20.3833Z"
                fill="url(#paint1_radial_52997_333)"/>
            <path
                d="M16.2025 19.177C13.9504 19.177 12.1247 17.3513 12.1247 15.0991C12.1247 12.847 13.9504 11.0212 16.2025 11.0212C18.4547 11.0212 20.2804 12.847 20.2804 15.0991C20.2804 17.3513 18.4547 19.177 16.2025 19.177Z"
                fill="white"/>
            <path
                d="M20.1536 16.1079C20.1536 16.1079 19.0375 10.5219 13.8217 11.7889C14.519 11.2718 15.3655 10.9957 16.2335 11.0023C17.1015 11.0089 17.9438 11.2978 18.6331 11.8254C19.293 12.2991 19.7923 12.9631 20.064 13.7285C20.3358 14.494 20.3671 15.3242 20.1536 16.1079Z"
                fill="#C3F1FF"/>
            <defs>
                <linearGradient
                    id="paint0_linear_52997_333"
                    x1="12"
                    y1="21.0653"
                    x2="12"
                    y2="7.08801"
                    gradientUnits="userSpaceOnUse">
                    <stop stop-color="#32BEDD"/>
                    <stop
                        offset="0.775"
                        stop-color="#32D4F5"/>
                </linearGradient>
                <radialGradient
                    id="paint1_radial_52997_333"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(17.4587 16.6116) scale(6.45868 6.61155)">
                    <stop
                        offset="0.225"
                        stop-color="#32D4F5"/>
                    <stop
                        offset="0.59"
                        stop-color="#32D2F2"/>
                    <stop
                        offset="0.825"
                        stop-color="#32CAEA"/>
                    <stop
                        offset="1"
                        stop-color="#32BEDD"/>
                </radialGradient>
            </defs>
        </SvgIcon>);
}