﻿import { useLocalization } from "@infrastructure";
import { Contract } from "../../../common";

export function useCodeTenantTypeTranslator() {
    const localization =
        useLocalization(
            "tenants.code.hooks.useCodeTenantTypeTranslator",
            () => ({
                [Contract.TypeNames.CodeTenantType]: {
                    [Contract.CodeTenantType.AzureDevOps]: "Azure DevOps",
                    [Contract.CodeTenantType.Bitbucket]: "Bitbucket",
                    [Contract.CodeTenantType.General]: "Code",
                    [Contract.CodeTenantType.GitHub]: "GitHub",
                    [Contract.CodeTenantType.GitLab]: "GitLab"
                }
            }));
    return (codeTenantType: Contract.CodeTenantType) =>
        localization[Contract.TypeNames.CodeTenantType][codeTenantType as Exclude<Contract.CodeTenantType, Contract.CodeTenantType.General>]();
}