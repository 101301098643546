import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useOciFssMountTargetStatusTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useOciFssMountTargetStatusTranslator",
            () => ({
                [Contract.TypeNames.OciFssMountTargetStatus]: {
                    [Contract.OciFssMountTargetStatus.Active]: "Active",
                    [Contract.OciFssMountTargetStatus.Creating]: "Creating",
                    [Contract.OciFssMountTargetStatus.Deleted]: "Deleted",
                    [Contract.OciFssMountTargetStatus.Deleting]: "Deleting",
                    [Contract.OciFssMountTargetStatus.Failed]: "Failed"
                }
            }));
    return (status: Contract.OciFssMountTargetStatus) =>
        localization[Contract.TypeNames.OciFssMountTargetStatus][status]();
}