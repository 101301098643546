import { useLocalization } from "@infrastructure";
import { Contract } from "..";

export function useIdentityRoleTranslator() {
    const localization =
        useLocalization(
            "common.hooks.useIdentityRoleTranslator",
            () => ({
                [Contract.TypeNames.IdentityRole]: {
                    [Contract.IdentityRole.Administrator]: "Administrator",
                    [Contract.IdentityRole.AdministratorViewer]: "Administrator Viewer",
                    [Contract.IdentityRole.Collaborator]: "Collaborator",
                    [Contract.IdentityRole.Owner]: "Owner",
                    [Contract.IdentityRole.PermissionManagementAdministrator]: "JIT Administrator",
                    [Contract.IdentityRole.Viewer]: "Viewer"
                }
            }));
    return (identityRole: Contract.IdentityRole) =>
        localization[Contract.TypeNames.IdentityRole][identityRole]();
}