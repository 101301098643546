﻿import { useLocalization } from "@infrastructure";
import React from "react";
import { useOpCommonKubernetesResourceInfoItemElements, useOpDefaultResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../..";
import { Contract } from "../../../../../../../../../../common";
import { KubernetesResourceDataHelper } from "../../../../../../utilities";
import { Info } from "../../../../components";
import { KubernetesRoleBindings } from "../../components";

export function useOpKubernetesGroupDefinition(resourceModel: Contract.OpResourceModel, options?: DefinitionOptions) {
    const commonKubernetesResourceInfoItemElements = useOpCommonKubernetesResourceInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useOpDefaultResourceInfoItemElements(resourceModel);
    const groupModel = resourceModel as Contract.OpKubernetesGroupModel;
    const group = groupModel.entity as Contract.OpKubernetesGroup;

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.op.useOpKubernetesGroupDefinition",
            () => ({
                tabs: {
                    roleBindings: "Role Bindings"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <KubernetesRoleBindings principalOrRoleModel={groupModel}/>,
                localization.tabs.roleBindings(),
                "roleBindings")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={[]}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    commonKubernetesResourceInfoItemElements.clusterInfoItemElement
                ]}
                options={options?.infoOptions}>
            </Info>,
        sensitive: KubernetesResourceDataHelper.entitySensitiveToggle(group.data)
    });
}