import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useGcpComputeImageTypeTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useGcpComputeImageTypeTranslator",
            () => ({
                [Contract.TypeNames.GcpComputeImageType]: {
                    [Contract.GcpComputeImageType.Custom]: "Custom",
                    [Contract.GcpComputeImageType.ExternalManaged]: "Public"
                }
            }));
    return (type: Contract.GcpComputeImageType) =>
        localization[Contract.TypeNames.GcpComputeImageType][type]();
}