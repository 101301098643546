import { ContextMenu, ContextMenuItem, DeferredItems, EmptyMessageText, InlineItems, InlineItemsVariant, SearchList, StringHelper, useLocalization } from "@infrastructure";
import { SxProps, Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { useTheme } from "..";
import { ActionHelper } from "../utilities";

type InlinePermissionActionsProps = {
    getContextMenuItemsPromise?: (permissionAction: string) => Promise<ContextMenuItem[]>;
    namePluralizer?: (count: number) => string;
    sx?: SxProps;
    variant: InlineItemsVariant;
} & ({
    deferredPermissionActions?: never;
    permissionActions: string[];
} | {
    deferredPermissionActions: DeferredItems;
    permissionActions?: never;
});

export function InlinePermissionActions({ deferredPermissionActions, getContextMenuItemsPromise, namePluralizer, permissionActions, sx, variant }: InlinePermissionActionsProps) {
    const localization =
        useLocalization(
            "common.inlinePermissionActions",
            () => ({
                empty: {
                    withFilter: "No Matching Permissions",
                    withoutFilter: "No Permissions"
                },
                permissionActions: [
                    "1 permission",
                    "{{count | NumberFormatter.humanize}} permissions"
                ]
            }));

    const inlineItemsProps =
        useMemo(
            () =>
                _.isNil(deferredPermissionActions)
                    ? {
                        items: _(permissionActions).
                            map(permissionAction => ActionHelper.getRawValue(permissionAction)).
                            uniq().
                            orderBy(StringHelper.getSortValue).
                            value()
                    }
                    : { deferredItems: deferredPermissionActions },
            [deferredPermissionActions, permissionActions]);

    const theme = useTheme();
    return (
        <InlineItems
            {...inlineItemsProps}
            itemsPopover={
                items =>
                    <SearchList
                        emptyMessageText={
                            new EmptyMessageText(
                                localization.empty.withoutFilter(),
                                localization.empty.withFilter())}
                        itemOptions={{
                            render:
                                (permissionActionDisplayName: string) =>
                                    <ContextMenu
                                        getMenuItems={getContextMenuItemsPromise}
                                        item={permissionActionDisplayName}>
                                        <Typography sx={{ color: theme.palette.text.primary }}>
                                            {permissionActionDisplayName}
                                        </Typography>
                                    </ContextMenu>,
                            spacing: 1
                        }}
                        items={items}
                        sx={{ padding: theme.spacing(0, 1.5) }}
                        variant="dropdown"/>}
            namePluralizer={namePluralizer ?? localization.permissionActions}
            sx={sx}
            variant={variant}>
            {(permissionActionDisplayName: string) =>
                <ContextMenu
                    getMenuItems={getContextMenuItemsPromise}
                    item={permissionActionDisplayName}>
                    <Typography
                        component="span"
                        sx={{
                            fontSize: "unset",
                            fontWeight:
                                variant === "itemPlusItemCount"
                                    ? "unset"
                                    : 600
                        }}>
                        {permissionActionDisplayName}
                    </Typography>
                </ContextMenu>}
        </InlineItems>);
}