import { UnexpectedError } from "@infrastructure";
import { useMemo } from "react";
import { Contract } from "../../../../../../../../../../../common";
import { RiskDefinition } from "../../../../../utilities";
import { useGciDirectoryGroupInactiveRiskDefinition, useGciDirectoryUserInactiveRiskDefinition } from "./compliance";

export function useGciDefinition(riskModel: Contract.RiskModel): RiskDefinition {
    const useDefinition =
        useMemo(
            () => {
                switch (riskModel.risk.typeName) {
                    case Contract.TypeNames.GciDirectoryGroupInactiveRisk:
                        return useGciDirectoryGroupInactiveRiskDefinition;
                    case Contract.TypeNames.GciDirectoryUserInactiveRisk:
                        return useGciDirectoryUserInactiveRiskDefinition;
                    default:
                        throw new UnexpectedError("riskModel.risk.typeName", riskModel.risk.typeName);
                }
            },
            []);

    return useDefinition(riskModel);
}