﻿import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, NumberFormatter, useLocalization } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React, { ReactNode } from "react";
import { useGcpCommonEncryptedResourceDefinition, useGcpCommonScopeResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, ItemSelectionHelper, TimeRangeHelper, useEntityTypeNameTranslator } from "../../../../../../../../../../common";
import { GcpBigQueryDatasetTablesItem } from "../../../../../../../../../../tenants";
import { DefinitionData } from "../../../../Table";
import { useCommonDataAnalysisDefinition } from "../useCommonDataAnalysisDefinition";

export function useGcpBigQueryDatasetDefinition(definitionData: DefinitionData) {
    const commonDataAnalysisResourceDefinition = useCommonDataAnalysisDefinition(definitionData);
    const commonEncryptedResourceDefinition = useGcpCommonEncryptedResourceDefinition(definitionData);
    const commonScopeResourceDefinition = useGcpCommonScopeResourceDefinition(definitionData);
    const entityTypeNameTranslator = useEntityTypeNameTranslator();

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.gcp.useGcpBigQueryDatasetDefinition",
            () => ({
                columns: {
                    size: "Size",
                    tableIds: "Tables"
                }
            }));

    return new EntityTableDefinition(
        _<ReactNode>([]).
            concat([
                commonScopeResourceDefinition.columns.tenantColumn,
                commonScopeResourceDefinition.columns.creationTimeColumn,
                commonScopeResourceDefinition.columns.creatorIdentityCsvColumn,
                commonScopeResourceDefinition.columns.creatorOriginatorEntityCsvColumn,
                commonScopeResourceDefinition.columns.accessLevelColumn,
                commonScopeResourceDefinition.columns.updateTimeColumn,
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.GcpBigQueryDatasetModel) => ({
                                [localization.columns.tableIds()]:
                                    entityTypeNameTranslator(
                                        Contract.TypeNames.GcpBigQueryDatasetTable,
                                        {
                                            count: item.tableCount,
                                            includeCount: true,
                                            includeServiceName: false
                                        })
                            })
                    }}
                    id={Contract.EntityModelProperty.GcpBigQueryDatasetTables}
                    key={Contract.EntityModelProperty.GcpBigQueryDatasetTables}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.GcpBigQueryDatasetModel>) =>
                            <GcpBigQueryDatasetTablesItem
                                datasetId={item.id}
                                tableCount={(item as Contract.GcpBigQueryDatasetModel).tableCount}/>}
                    sortOptions={{ type: DataTableSortType.Numeric }}
                    title={localization.columns.tableIds()}/>,
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.GcpBigQueryDatasetModel) => ({
                                [localization.columns.size()]: NumberFormatter.storage((item.entity as Contract.GcpBigQueryDataset).storageSize)
                            })
                    }}
                    id={Contract.EntityModelProperty.GcpBigQueryDatasetStorageSize}
                    itemProperty={(item: Contract.GcpBigQueryDatasetModel) => NumberFormatter.storage((item.entity as Contract.GcpBigQueryDataset).storageSize)}
                    key={Contract.EntityModelProperty.GcpBigQueryDatasetStorageSize}
                    sortOptions={{ type: DataTableSortType.Numeric }}
                    title={localization.columns.size()}/>,
                commonEncryptedResourceDefinition.columns.encryptionExistsColumn,
                commonEncryptedResourceDefinition.columns.encryptionKeyColumn,
                commonScopeResourceDefinition.columns.regionColumn,
                commonScopeResourceDefinition.columns.regionLocationColumn]).
            concat(
                [
                    commonDataAnalysisResourceDefinition.columns.dataCategoriesColumn,
                    commonDataAnalysisResourceDefinition.columns.dataAnalysisResourceSensitivityColumn,
                    commonDataAnalysisResourceDefinition.columns.dataAnalysisStatusColumn,
                    commonDataAnalysisResourceDefinition.columns.dataClassifiersColumn?.(false),
                    commonDataAnalysisResourceDefinition.columns.scanTimeColumn
                ]).
            concat(
                [
                    commonScopeResourceDefinition.columns.tagsColumn,
                    commonScopeResourceDefinition.columns.attributesColumn,
                    commonScopeResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
                    commonScopeResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
                    commonScopeResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn]).
            value(),
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageGcpBigQueryDatasetRequest(
                new Contract.EntityControllerGetEntityModelPageGcpBigQueryDatasetRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.GcpResourceUpdateTime]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpScopeResourceAccessLevel]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpScopeResourceRawId])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags])),
                new Contract.EntityControllerGetEntityModelPageEntityRequestDataAnalysis(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.DataAnalysisResourceScanStatus]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.DataAnalysisResourceDataCategories]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.DataAnalysisResourceDataClassifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.DataAnalysisResourceDataSensitivities]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.DataAnalysisResourceScanTime])),
                new Contract.EntityControllerGetEntityModelPageGcpScopeResourceRequestEncrypted(
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpEncryptedResourceEncryptionExists]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpEncryptedResourceKmsEncryptionKeys]))));
}