import _, { Dictionary } from "lodash";
import { useMemo } from "react";
import { ComplianceHelper, Contract, RiskPolicyItem, RiskPolicyTypeMetadataHelper, useBuiltInComplianceTypes } from "../../../../../common";

export function useGetRiskPolicyItemBuiltInComplianceTypes(): (item: RiskPolicyItem) => Contract.BuiltInComplianceSectionType[] {
    const riskPolicyConfigurationTypeNameToTenantTypeToBuiltInComplianceTypesMap = useRiskPolicyConfigurationTypeNameToTenantTypeToBuiltInComplianceTypesMap();
    return item =>
        _(item.tenantTypes).
            flatMap(tenantType => riskPolicyConfigurationTypeNameToTenantTypeToBuiltInComplianceTypesMap[item.riskPolicyConfigurationTypeName]?.[tenantType] ?? []).
            uniq().
            value();
}

function useRiskPolicyConfigurationTypeNameToTenantTypeToBuiltInComplianceTypesMap(): Dictionary<Dictionary<Contract.BuiltInComplianceSectionType[]>> {
    const [, enabledBuiltInComplianceTypes] = useBuiltInComplianceTypes();

    return useMemo(
        () =>
            _(enabledBuiltInComplianceTypes).
                flatMap(
                    builtInComplianceType =>
                        _.flatMap(
                            ComplianceHelper.getBuiltInComplianceTypeAnalysisGroupTypeToRiskPolicyConfigurationTypeNamesMap(builtInComplianceType),
                            (riskPolicyConfigurationTypeNames, analysisGroupType) =>
                                riskPolicyConfigurationTypeNames.flatMap(
                                    riskPolicyConfigurationTypeName =>
                                        RiskPolicyTypeMetadataHelper.
                                            getTenantTypes(
                                                riskPolicyConfigurationTypeName,
                                                analysisGroupType).
                                            map(
                                                tenantType => ({
                                                    riskPolicyConfigurationTypeName,
                                                    tenantType,
                                                    type: builtInComplianceType
                                                }))))).
                groupBy(({ riskPolicyConfigurationTypeName }) => riskPolicyConfigurationTypeName).
                mapValues(
                    riskPolicyConfigurationTypeNameGroup =>
                        _(riskPolicyConfigurationTypeNameGroup).
                            groupBy(({ tenantType }) => tenantType).
                            mapValues(
                                tenantTypeGroup =>
                                    _.map(
                                        tenantTypeGroup,
                                        ({ type }) => type)).
                            value()).
                value(),
        [enabledBuiltInComplianceTypes]
    );
}