import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function ColoredOciIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                clipRule="evenodd"
                d="M2.24942 7.24942C3.6897 5.80914 5.64314 5 7.68 5H16.32C18.3569 5 20.3103 5.80914 21.7506 7.24942C23.1909 8.6897 24 10.6431 24 12.68C24 14.7169 23.1909 16.6703 21.7506 18.1106C20.3103 19.5509 18.3569 20.36 16.32 20.36H7.68C5.64314 20.36 3.6897 19.5509 2.24942 18.1106C0.809141 16.6703 0 14.7169 0 12.68C0 10.6431 0.809141 8.6897 2.24942 7.24942ZM7.68 8.84C6.66157 8.84 5.68485 9.24457 4.96471 9.96471C4.24457 10.6848 3.84 11.6616 3.84 12.68C3.84 13.6984 4.24457 14.6752 4.96471 15.3953C5.68485 16.1154 6.66157 16.52 7.68 16.52H16.32C17.3384 16.52 18.3151 16.1154 19.0353 15.3953C19.7554 14.6752 20.16 13.6984 20.16 12.68C20.16 11.6616 19.7554 10.6848 19.0353 9.96471C18.3151 9.24457 17.3384 8.84 16.32 8.84H7.68Z"
                fill="#C74634"
                fillRule="evenodd"/>
        </SvgIcon>);
}