﻿import { Link, TextViewer, TimeFormatter, useLocalization } from "@infrastructure";
import { Box } from "@mui/material";
import _ from "lodash";
import React from "react";
import { useCustomEntityPropertyInfoItemElements } from "../..";
import { useAzureCommonManagedIdentityOriginatorResourceInfoElements, useAzureDefaultResourceInfoItemElements } from "..";
import { Contract, InfoItem, useTheme } from "../../../../../../../../../../../common";
import { useAzureLogicWorkflowsStatusTranslator } from "../../../../../../../hooks";
import { Info } from "../../../../../components";
import { AzureResourceRoleAssignmentResources } from "../../../components";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../../useDefinition";
import { WorkflowNetworkInfoCard } from "./components";

export function useAzureLogicWorkflowDefinition(resourceGroupResourceModel: Contract.AzureResourceGroupResourceModel, options?: DefinitionOptions) {
    const commonManagedIdentityOriginatorResourceInfoElements = useAzureCommonManagedIdentityOriginatorResourceInfoElements(resourceGroupResourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceGroupResourceModel);
    const defaultResourceInfoItemElements = useAzureDefaultResourceInfoItemElements(resourceGroupResourceModel);
    const workflow = resourceGroupResourceModel.entity as Contract.AzureLogicWorkflow;
    const workflowModel = resourceGroupResourceModel as Contract.AzureLogicWorkflowModel;

    const logicWorkflowsStatusTranslator = useAzureLogicWorkflowsStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.azure.useAzureLogicWorkflowDefinition",
            () => ({
                info: {
                    items: {
                        endpointUrl: "Access Endpoint",
                        planType: {
                            text: "Consumption",
                            title: "Plan Type"
                        },
                        runTime: "Run Time",
                        status: "Status",
                        triggerNetworkAccessType: {
                            title: "Allowed inbound IP addresses",
                            [Contract.TypeNames.AzureLogicWorkflowTriggerNetworkAccessType]: {
                                [Contract.AzureLogicWorkflowTriggerNetworkAccessType.Public]: "Any Ip",
                                [Contract.AzureLogicWorkflowTriggerNetworkAccessType.LogicApp]: "Only Other Logic Apps",
                                [Contract.AzureLogicWorkflowTriggerNetworkAccessType.Restricted]: "Specific Ip Ranges"
                            }
                        },
                        updateTime: "Updated",
                        zoneRedundancy: {
                            false: "Disabled",
                            title: "Zone Redundancy",
                            true: "Enabled"
                        }
                    }
                },
                tabs: {
                    definition: "Definition",
                    resourceRoleAssignments: "Resource Role Assignments"
                }
            }));

    const theme = useTheme();
    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <AzureResourceRoleAssignmentResources
                    csvExportFilePrefix={localization.tabs.resourceRoleAssignments()}
                    scopeResourceModel={resourceGroupResourceModel}/>,
                localization.tabs.resourceRoleAssignments(),
                "resourceRoleAssignments"),
            new EntityProfileDefinitionTab(
                ProfileCategory.Overview,
                <Box sx={{ paddingTop: theme.spacing(1.5) }}>
                    <TextViewer
                        format="json"
                        text={workflow.rawDefinition}/>
                </Box>,
                localization.tabs.definition(),
                "definition")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    ...commonManagedIdentityOriginatorResourceInfoElements,
                    <InfoItem
                        key="status"
                        title={localization.info.items.status()}
                        value={logicWorkflowsStatusTranslator(workflow.status)}/>,
                    <InfoItem
                        key="endpointUrl"
                        title={localization.info.items.endpointUrl()}
                        value={
                            <Link
                                urlOrGetUrl={workflow.endpointUrl}
                                variant="external"/>}/>,
                    <InfoItem
                        key="triggerNetworkAccessType"
                        title={localization.info.items.triggerNetworkAccessType.title()}
                        value={localization.info.items.triggerNetworkAccessType[Contract.TypeNames.AzureLogicWorkflowTriggerNetworkAccessType][workflow.triggerNetworkAccessType]()}/>,
                    <InfoItem
                        key="updateTime"
                        title={localization.info.items.updateTime()}
                        value={TimeFormatter.profileFormatDateTime(workflow.updateTime)}/>,
                    <InfoItem
                        key="runTime"
                        title={localization.info.items.runTime()}
                        value={
                            _.isNil(workflow.runTime)
                                ? undefined
                                : TimeFormatter.profileFormatDateTime(workflow.runTime)}/>,
                    <InfoItem
                        key="planType"
                        title={localization.info.items.planType.title()}
                        value={localization.info.items.planType.text()}/>,
                    <InfoItem
                        key="zoneRedundancy"
                        title={localization.info.items.zoneRedundancy.title()}
                        value={
                            workflow.zoneRedundancy
                                ? localization.info.items.zoneRedundancy.true()
                                : localization.info.items.zoneRedundancy.false()}/>
                ]}
                options={options?.infoOptions}>
                {(workflow.contentIpAddressExists || workflow.triggerIpAddressExists) &&
                    <WorkflowNetworkInfoCard workflowModel={workflowModel}/>}
            </Info>
    });
}