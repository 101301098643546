import { SxProps, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract, DeferredEntities, EntityLinkOptions, EntityVariant, InlineEntities, InlineEntitiesVariant, NoneDefinition } from "..";

type EntitiesCellProps = {
    entityLinkOptions?: EntityLinkOptions;
    entityTypeName: string;
    entityVariant?: EntityVariant;
    inlineEntitiesVariant?: InlineEntitiesVariant;
    sx?: SxProps;
} & ({
    deferredEntities?: never;
    entityIdsOrModels?: string | string[] | Contract.EntityModel | Contract.EntityModel[];
} | {
    deferredEntities: DeferredEntities;
    entityIdsOrModels?: never;
});

export function EntitiesCell({ deferredEntities, entityIdsOrModels, entityLinkOptions, entityTypeName, entityVariant = "iconText", inlineEntitiesVariant = "itemPlusItemCount", sx }: EntitiesCellProps) {
    if (_.isNil(deferredEntities?.firstItem) &&
        _.isEmpty(entityIdsOrModels)) {
        return <NoneDefinition/>;
    } else {
        const inlineEntitiesProps =
            !_.isNil(deferredEntities)
                ? { deferredEntities }
                : { entityIdsOrModels: entityIdsOrModels! };
        return (
            <Typography noWrap={true}>
                <InlineEntities
                    {...inlineEntitiesProps}
                    entityLinkOptions={entityLinkOptions}
                    entityTypeName={entityTypeName}
                    entityVariant={entityVariant}
                    sx={sx}
                    variant={inlineEntitiesVariant}/>
            </Typography>);
    }
}