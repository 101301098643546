﻿import { Optional, StringHelper, useAsyncEffect, useChangeEffect, useSyncContext } from "@infrastructure";
import _ from "lodash";
import { useState } from "react";
import { Contract } from "../../..";

export function useSearchUsers(searchUsers: (searchText: Optional<string>, userRawId: Optional<string>) => Promise<Contract.JiraUser[]>, initUserRawId?: string) {
    const [users, setUsers] = useState<Contract.JiraUser[]>([]);
    const [userRawIdToDisplayNameMap, setUserRawIdToDisplayNameMap] = useState<_.Dictionary<string>>({});

    const searchSyncContext = useSyncContext();
    const [error, setError] = useState(false);
    const [executing, setExecuting] = useState(false);
    const [text, setText] = useState<string | undefined>();
    useChangeEffect(
        async () => {
            setExecuting(true);
            setError(false);

            const syncContext = searchSyncContext.create();

            try {
                const users = await searchUsers(text, undefined);
                if (!searchSyncContext.isActive(syncContext)) {
                    return;
                }

                setUsers(
                    _.orderBy(
                        users,
                        user => StringHelper.getSortValue(user.displayName)));
                setUserRawIdToDisplayNameMap(
                    _(users).
                        keyBy(user => user.rawId).
                        mapValues(user => user.displayName).
                        value());
            } catch {
                setError(true);
            }

            setExecuting(false);
        },
        [text],
        500);


    const [initUser, setInitUser] = useState<Contract.JiraUser | undefined>();
    useAsyncEffect(
        async () => {
            if (!_.isNil(initUserRawId)) {
                setExecuting(true);
                try {
                    const users = await searchUsers(undefined, initUserRawId);
                    setInitUser(users[0]);
                    setUsers(users);
                    setUserRawIdToDisplayNameMap(
                        _.isEmpty(users)
                            ? {}
                            : { [users[0].rawId]: users[0].displayName });
                } catch {
                    setError(true);
                }
                setExecuting(false);
            }
        },
        []);

    const updateText =
        (searchText: Optional<string>) => {
            if (_.isEmpty(searchText)) {
                setUsers([]);
                setUserRawIdToDisplayNameMap({});
                return;
            }

            const selectedUser = _.find(users, user => user.displayName === searchText);
            if (_.isNil(selectedUser)) {
                setText(searchText);
            } else {
                setUsers([selectedUser]);
                setUserRawIdToDisplayNameMap({ [selectedUser.rawId]: selectedUser.displayName });
            }
        };

    return { initUser, searchUsersError: error, searchUsersExecuting: executing, updateSearchUsersText: updateText, userRawIdToDisplayNameMap, users };
}