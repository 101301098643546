import { Shadows, useLocalization } from "@infrastructure";
import { CheckCircle } from "@mui/icons-material";
import { Box, Popper, PopperPlacementType, Stack, Typography, useTheme } from "@mui/material";
import _ from "lodash";
import React, { Fragment, ReactNode, useRef, useState } from "react";

type CopyToClipboardProps = {
    children: ReactNode;
    getValue: () => string;
    placement?: PopperPlacementType;
    title?: string;
};

export function CopyToClipboard({ children, getValue, placement = "top", title }: CopyToClipboardProps) {
    const localization =
        useLocalization(
            "infrastructure.copyToClipboard",
            () => ({
                action: {
                    copied: "Copied",
                    copy: "Copy"
                },
                withTitle: "{{title}} {{action}}"
            }));

    const [open, setOpen] = useState(false);
    const boxElementRef = useRef<HTMLDivElement>(null);

    async function copyToClipboard(event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();

        setOpen(true);
        setTimeout(
            () => {
                setOpen(false);
            },
            1000);

        await navigator.clipboard.writeText(getValue());
    }

    const theme = useTheme();
    return (
        <Fragment>
            {open &&
                <Popper
                    anchorEl={boxElementRef.current}
                    open={true}
                    placement={placement}
                    sx={{
                        backgroundColor: theme.palette.background.paper,
                        borderRadius: theme.spacing(0.75),
                        boxShadow: theme.shadows[Shadows.Tooltip],
                        maxWidth: theme.spacing(15),
                        padding: theme.spacing(1),
                        zIndex: theme.zIndex.tooltip
                    }}>
                    <Stack
                        alignItems="center"
                        direction="row"
                        spacing={1}>
                        <CheckCircle
                            sx={{
                                fill: theme.palette.success.main,
                                fontSize: "18px"
                            }}/>
                        <Typography sx={{ minWidth: 0 }}>
                            {_.isNil(title)
                                ? localization.action.copied()
                                : localization.withTitle({
                                    action: localization.action.copied(),
                                    title:
                                        <Typography
                                            component="span"
                                            noWrap={true}
                                            sx={{
                                                display: "inline-block",
                                                maxWidth: "100%"
                                            }}>
                                            {title}
                                        </Typography>
                                })}
                        </Typography>
                    </Stack>
                </Popper>}
            <Box
                ref={boxElementRef}
                sx={{ overflow: "hidden" }}
                onClick={event => copyToClipboard(event as any)}>
                {children}
            </Box>
        </Fragment>);
}