﻿import { InlineItems, Link, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, Entity, entityModelStore, InlineEntities, useEntityTypeNameTranslator, useTheme } from "../../../../../../../../../../../../common";
import { AwsCloudFormationStackOutputTable, AwsCloudFormationStackParameterTable, AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { RiskDefinitionContextItem, RiskDefinitionSection } from "../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { useResourceSecretExistsRiskContext } from "../../../useResourceSecretExistsRiskContext";
import { useGetAwsCloudFormationStackRiskContext } from "../contexts";

export function useAwsCloudFormationStackSecretExistsRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsCloudFormationStackSecretExistsRisk;
    const stackSecretExistsRiskModel = riskModel as Contract.AwsCloudFormationStackSecretExistsRiskModel;
    const stackModel = entityModelStore.useGet(risk.entityId) as Contract.AwsCloudFormationStackModel;
    const stack = stackModel.entity as Contract.AwsCloudFormationStack;

    const getAwsCloudFormationStackRiskContext = useGetAwsCloudFormationStackRiskContext();
    const secretRiskContext = useResourceSecretExistsRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsCloudFormationStackSecretExistsRiskDefinition",
            () => ({
                contextItems: {
                    identities: "{{identities}} are granted with permissions allowing them to read your secrets",
                    outputs: "The {{translatedStackTypeName}} is exposing {{secrets}} via outputs",
                    parameters: "The {{translatedStackTypeName}} is exposing {{secrets}} via parameters",
                    secrets: [
                        "1 secret",
                        "{{count | NumberFormatter.humanize}} secrets"
                    ]
                },
                description: "{{stack}} is exposing secrets in clear text",
                sections: {
                    outputs: "Outputs",
                    parameters: {
                        parameterDefaultValuesMissing: {
                            learnMoreLink: "Learn more",
                            message: "Since Tenable Cloud Security is missing the {{permission}} permission, the default values for parameters won’t be displayed or assessed for findings. Update the Monitoring policy with this permission. {{learnMoreLink}}.",
                            permission: "cloudformation:Get*",
                            title: "Missing default values for parameters"
                        },
                        title: "Parameters"
                    },
                    resolution: {
                        step1: {
                            links: {
                                secretsManager: "AWS Secrets Manager",
                                stackSecretsManager: "Retrieve an AWS Secrets Manager secret in an AWS CloudFormation resource"
                            },
                            text: "Store your secrets in {{secretsManagerLink}} and then dynamically reference to them. See {{stackSecretsManagerLink}} for more information."
                        },
                        step2: "Click on the **Update** button",
                        step3: "Update the {{translatedStackTypeName}} template and parameters with a reference to the newly created secrets",
                        step4: "Review the changes and click **Submit**"
                    }
                }
            }));

    const theme = useTheme();
    const translatedStackTypeName =
        entityTypeNameTranslator(
            stack.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            stack:
                <Entity
                    entityIdOrModel={stackModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            localization.sections.resolution.step1.text({
                secretsManagerLink:
                    <Link
                        urlOrGetUrl={stackSecretExistsRiskModel.secretsManagerDocumentationUrl}
                        variant="external">
                        {localization.sections.resolution.step1.links.secretsManager()}
                    </Link>,
                stackSecretsManagerLink:
                    <Link
                        urlOrGetUrl={stackSecretExistsRiskModel.stackSecretsManagerDocumentationUrl}
                        variant="external">
                        {localization.sections.resolution.step1.links.stackSecretsManager()}
                    </Link>
            }),
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.CloudFormation,
                AwsConsoleUrlBuilder.getCloudFormationStackUrl(stack)),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3({ translatedStackTypeName }),
            localization.sections.resolution.step4()
        ],
        riskModel,
        () => {
            const stackRiskContext = getAwsCloudFormationStackRiskContext(stackModel);
            return [
                stackRiskContext.generalInformation,
                stackRiskContext.status,
                stackRiskContext.resources,
                _.isEmpty(risk.secretParameterValueKeys) &&
                _.isEmpty(risk.secretParameterDefaultValueKeys)
                    ? undefined
                    : new RiskDefinitionContextItem(
                        localization.contextItems.parameters({
                            secrets:
                                <InlineItems
                                    items={
                                        _.uniq(
                                            _.concat(
                                                risk.secretParameterValueKeys,
                                                risk.secretParameterDefaultValueKeys))}
                                    namePluralizer={localization.contextItems.secrets}
                                    variant="itemCountAndType"/>,
                            translatedStackTypeName
                        })),
                _.isEmpty(risk.secretOutputKeys)
                    ? undefined
                    : new RiskDefinitionContextItem(
                        localization.contextItems.outputs({
                            secrets:
                                <InlineItems
                                    items={risk.secretOutputKeys}
                                    namePluralizer={localization.contextItems.secrets}
                                    variant="itemCountAndType"/>,
                            translatedStackTypeName
                        })),
                secretRiskContext.getExcludedSecretsContextItem(
                    risk.excludedSecrets,
                    risk.typeName,
                    risk.tenantId),
                _.isEmpty(risk.stackPermissionsIdentityIds)
                    ? undefined
                    : new RiskDefinitionContextItem(
                        localization.contextItems.identities({
                            identities:
                                <InlineEntities
                                    entityIdsOrModels={risk.stackPermissionsIdentityIds}
                                    entityTypeName={Contract.TypeNames.AwsIamIdentity}
                                    variant="itemCountAndType"/>
                        })),
                stackRiskContext.sensitive,
                stackRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        },
        {
            sections:
                _.filter([
                    _.isEmpty(risk.secretParameterValueKeys) &&
                    _.isEmpty(risk.secretParameterDefaultValueKeys)
                        ? undefined
                        : new RiskDefinitionSection(
                            <AwsCloudFormationStackParameterTable
                                getHighlightColor={
                                    (parameter, opacity) =>
                                        _.includes(risk.secretParameterDefaultValueKeys, parameter.key) ||
                                        _.includes(risk.secretParameterValueKeys, parameter.key)
                                            ? opacity
                                                ? theme.palette.opacity(theme.palette.severity(riskModel.risk.severity), opacity)
                                                : theme.palette.severity(riskModel.risk.severity)
                                            : undefined
                                }
                                getParameterSecretValues={
                                    parameter => {
                                        const secretValues = [];
                                        if (_.includes(risk.secretParameterDefaultValueKeys, parameter.key)) {
                                            secretValues.push(parameter.defaultValue!);
                                        }
                                        if (_.includes(risk.secretParameterValueKeys, parameter.key)) {
                                            secretValues.push(parameter.value!);
                                        }
                                        return secretValues;
                                    }
                                }
                                parameterDefaultValuesMissing={stack.parameterDefaultValuesMissing}
                                parameters={stack.parameters}
                                risk={risk}/>,
                            localization.sections.parameters.title(),
                            {
                                expandable: true
                            }),
                    _.isEmpty(risk.secretOutputKeys)
                        ? undefined
                        : new RiskDefinitionSection(
                            <AwsCloudFormationStackOutputTable
                                getHighlightColor={
                                    (output, opacity) =>
                                        _.includes(risk.secretOutputKeys, output.key)
                                            ? opacity
                                                ? theme.palette.opacity(theme.palette.severity(riskModel.risk.severity), opacity)
                                                : theme.palette.severity(riskModel.risk.severity)
                                            : undefined}
                                outputResourceReferences={stackModel.outputResourceReferences}
                                outputs={stack.outputs}
                                risk={risk}/>,
                            localization.sections.outputs(),
                            {
                                expandable: true
                            })]) as RiskDefinitionSection[]
        });
}