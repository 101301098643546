import { FormLayout, getHorizontalLinkedNodeContentAnchorPoint, getHorizontalLinkedNodeContentSize, HorizontalLinkedNodeContent, NodeContentContextMenuItem, useLocalization, useSetDirectedGraphContext } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, entityModelStore, EntityNodeContent, getEntityNodeContentAnchorPoint, getEntityNodeContentSize } from "../../../../..";
import { useNetworkContext } from "../../../Network";

type GatewayNodeContentProps = {
    gateway: Contract.NetworkGraphGateway;
};

export function GatewayNodeContent({ gateway }: GatewayNodeContentProps) {
    const { definition, entityId } = useNetworkContext();
    const gatewayResourceModel = entityModelStore.useGet(gateway.gatewayResourceId);
    const setDirectedGraphContext = useSetDirectedGraphContext();
    const localization =
        useLocalization(
            "common.network.graph.gatewayNodeContent",
            () => ({
                menu: {
                    rules: "View {{rulesTitle}}"
                }
            }));

    const gatewayResourceRulesDefinition = definition.getGatewayResourceRulesDefinition?.(gatewayResourceModel!);
    const gatewayResourceRulesContextMenuItems =
        _.isNil(gateway.gatewayResourceId) || _.isNil(gatewayResourceRulesDefinition )
            ? []
            : [new NodeContentContextMenuItem(
                () =>
                    setDirectedGraphContext(
                        context => ({
                            ...context,
                            dialogContentElement:
                                <FormLayout
                                    titleOptions={{ text: gatewayResourceRulesDefinition.title }}>
                                    {gatewayResourceRulesDefinition.rules}
                                </FormLayout>,
                            dialogContentElementSize: "content"
                        })),
                localization.menu.rules({ rulesTitle: gatewayResourceRulesDefinition.title }))];
    return _.isNil(gateway.gatewayResourceId) || _.isNil(gateway.internetGatewayId )
        ? <EntityNodeContent
            contextMenuItems={gatewayResourceRulesContextMenuItems}
            entityIds={[gateway.internetGatewayId ?? gateway.gatewayResourceId!]}
            entityProfileMenuItem={
                gateway.internetGatewayId !== entityId &&
                gateway.gatewayResourceId !== entityId}
            variant="large"/>
        : <HorizontalLinkedNodeContent
            childrenLeftAnchorPoint={getEntityNodeContentAnchorPoint("left", "bottom", "small")}
            childrenRightAnchorPoint={getEntityNodeContentAnchorPoint("right", "bottom", "small")}
            childrenSize={getEntityNodeContentSize("bottom", "small")}>
            <EntityNodeContent
                contextMenuItems={gatewayResourceRulesContextMenuItems}
                entityIds={[gateway.internetGatewayId]}
                entityProfileMenuItem={gateway.internetGatewayId !== entityId}
                variant="small"/>
            <EntityNodeContent
                entityIds={[gateway.gatewayResourceId]}
                entityProfileMenuItem={gateway.gatewayResourceId !== entityId}
                variant="small"/>
        </HorizontalLinkedNodeContent>;
}

export const getGatewayNodeContentAnchorPoint =
    (anchorPlacement: "left" | "right", gateway: Contract.NetworkGraphGateway) => {
        if (_.isNil(gateway.gatewayResourceId) || _.isNil(gateway.internetGatewayId ) ) {
            return getEntityNodeContentAnchorPoint(
                anchorPlacement,
                "bottom",
                "large");
        } else {
            return getHorizontalLinkedNodeContentAnchorPoint(
                anchorPlacement,
                2,
                getEntityNodeContentAnchorPoint("left", "bottom", "small"),
                getEntityNodeContentAnchorPoint("right", "bottom", "small"),
                getEntityNodeContentSize("bottom", "small"));
        }
    };

export const getGatewayNodeContentSize =
    (gateway: Contract.NetworkGraphGateway) =>
        _.isNil(gateway.gatewayResourceId) || _.isNil(gateway.internetGatewayId )
            ? getEntityNodeContentSize("bottom", "large")
            : getHorizontalLinkedNodeContentSize(2, getEntityNodeContentSize("bottom", "small"));