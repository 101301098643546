import { DataTableColumn, DataTableColumnRenderProps, optionalTableCell, TimeFormatter, TimeRangeFilter, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { useCommonCustomSectionsAndDescriptionDefinition } from "../../..";
import { Contract, Entity, EntityFilter, entityModelStore, InlineEntities, TimeRangeHelper, useTableDefinition } from "../../../../../../../../../../../../common";
import { Table } from "../../../../components";

export function useAwsInboundExternalVpcRiskDefinition(riskModel: Contract.RiskModel) {
    const inboundExternalVpcRiskModel = riskModel as Contract.AwsInboundExternalVpcRiskModel;
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.custom.useAwsInboundExternalVpcRiskDefinition",
            () => ({
                violations: "{{vpcs}} have internet gateway attached"
            }));
    const translatedViolations =
        localization.violations({
            vpcs:
                <InlineEntities
                    entityIdsOrModels={inboundExternalVpcRiskModel.vpcIds}
                    entityTypeName={Contract.TypeNames.AwsEc2Vpc}
                    variant="itemAndTypeOrItemCountAndType"/>
        });
    return useCommonCustomSectionsAndDescriptionDefinition(
        translatedViolations,
        riskModel,
        "violations",
        <ViolationTable riskModel={inboundExternalVpcRiskModel}/>);
}

type ViolationTableProps = {
    riskModel: Contract.AwsInboundExternalVpcRiskModel;
};

function ViolationTable({ riskModel }: ViolationTableProps) {
    const risk = riskModel.risk as Contract.AwsInboundExternalVpcRisk;
    const identityModels =
        entityModelStore.useGet(
            _(riskModel.vpcIdToInternetGatewayAttachInfoMap).
                values().
                map(internetGatewayAttachInfo => internetGatewayAttachInfo.identityIdReference!).
                filter().
                value());
    const internetGatewayModels =
        entityModelStore.useGet(
            _.map(
                risk.inboundExternalVpcItems,
                inboundExternalVpcItem => inboundExternalVpcItem.internetGatewayId));
    const originatorEntityModels =
        entityModelStore.useGet(
            _(riskModel.vpcIdToInternetGatewayAttachInfoMap).
                values().
                map(internetGatewayAttachInfo => internetGatewayAttachInfo.originatorEntityIdReference!).
                filter().
                value());
    const vpcModels =
        entityModelStore.useGet(
            _.map(
                risk.inboundExternalVpcItems,
                inboundExternalVpcItem => inboundExternalVpcItem.vpcId));
    const items =
        useMemo(
            () => {
                const identityModelMap =
                    _.keyBy(
                        identityModels,
                        identityModel => identityModel.id);
                const internetGatewayModelMap =
                    _.keyBy(
                        internetGatewayModels,
                        internetGateway => internetGateway.id);
                const originatorModelMap =
                    _.keyBy(
                        originatorEntityModels,
                        originatorModel => originatorModel.id);
                const vpcModelMap =
                    _.keyBy(
                        vpcModels,
                        vpc => vpc.id);

                return _.map(
                    risk.inboundExternalVpcItems,
                    riskItem =>
                        new ViolationTableItem(
                            riskItem,
                            internetGatewayModelMap[riskItem.internetGatewayId],
                            vpcModelMap[riskItem.vpcId],
                            _.isNil(riskModel.vpcIdToInternetGatewayAttachInfoMap[riskItem.vpcId]?.identityIdReference)
                                ? undefined
                                : identityModelMap[riskModel.vpcIdToInternetGatewayAttachInfoMap[riskItem.vpcId].identityIdReference!],
                            _.isNil(riskModel.vpcIdToInternetGatewayAttachInfoMap[riskItem.vpcId]?.originatorEntityIdReference)
                                ? undefined
                                : originatorModelMap[riskModel.vpcIdToInternetGatewayAttachInfoMap[riskItem.vpcId].originatorEntityIdReference!],
                            riskModel.vpcIdToInternetGatewayAttachInfoMap[riskItem.vpcId]?.time));
            },
            []);

    const tableDefinition =
        useTableDefinition(
            items,
            {
                [ViolationTableColumnId.Identity]: {
                    getFilterValue: item => item.identityModel?.entity.id,
                    getSortValue: item => item.identityModel?.entity.displayName
                },
                [ViolationTableColumnId.InternetGateway]: {
                    getFilterValue: item => item.internetGatewayModel.entity.id,
                    getSortValue: item => item.internetGatewayModel.entity.displayName
                },
                [ViolationTableColumnId.Originator]: {
                    getFilterValue: item => item.originatorModel?.entity.id,
                    getSortValue: item => item.originatorModel?.entity.displayName
                },
                [ViolationTableColumnId.Time]:
                    item =>
                        _.isNil(item.time)
                            ? undefined
                            : new Date(item.time),
                [ViolationTableColumnId.Vpc]: {
                    getFilterValue: item => item.vpcModel.entity.id,
                    getSortValue: item => item.vpcModel.entity.displayName
                }
            },
            ViolationTableColumnId.Vpc);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.custom.useAwsInboundExternalVpcRiskDefinition.violationTable",
            () => ({
                columns: {
                    identity: "Attaching Identity",
                    internetGateway: "Internet Gateway",
                    originator: "Attaching Originator",
                    time: "Attachment Time",
                    vpc: "VPC"
                }
            }));

    return (
        <Table
            fetchItems={tableDefinition.filterAndSortItems}
            getItemId={(item: ViolationTableItem) => item.vpcModel.id}
            sortEnabled={true}>
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EntityFilter
                                entityIdsOrSearchableReferences={tableDefinition.columnIdToItemValuesMap[ViolationTableColumnId.Vpc]}
                                placeholder={localization.columns.vpc()}/>
                    }
                }}
                id={ViolationTableColumnId.Vpc}
                render={
                    ({ item }: DataTableColumnRenderProps<ViolationTableItem>) =>
                        <Entity
                            entityIdOrModel={item.vpcModel}
                            variant="iconTextTypeTenantTags"/>}
                title={localization.columns.vpc()}/>
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EntityFilter
                                entityIdsOrSearchableReferences={tableDefinition.columnIdToItemValuesMap[ViolationTableColumnId.InternetGateway]}
                                placeholder={localization.columns.internetGateway()}/>
                    }
                }}
                id={ViolationTableColumnId.InternetGateway}
                render={
                    ({ item }: DataTableColumnRenderProps<ViolationTableItem>) =>
                        <Entity
                            entityIdOrModel={item.internetGatewayModel}
                            variant="iconTextTypeTenantTags"/>}
                title={localization.columns.internetGateway()}/>
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        element:
                            <TimeRangeFilter
                                placeholder={localization.columns.time()}
                                timeRange={TimeRangeHelper.getTimesFilterRange(tableDefinition.columnIdToItemValuesMap[ViolationTableColumnId.Time])}/>
                    }
                }}
                id={ViolationTableColumnId.Time}
                render={
                    optionalTableCell<ViolationTableItem>(
                        item =>
                            _.isNil(item.time)
                                ? undefined
                                : TimeFormatter.shortDateTime(item.time))}
                title={localization.columns.time()}/>
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EntityFilter
                                entityIdsOrSearchableReferences={tableDefinition.columnIdToItemValuesMap[ViolationTableColumnId.Identity]}
                                placeholder={localization.columns.identity()}/>
                    }
                }}
                id={ViolationTableColumnId.Identity}
                render={
                    optionalTableCell<ViolationTableItem>(
                        item =>
                            _.isNil(item.identityModel)
                                ? undefined
                                : <Entity
                                    entityIdOrModel={item.identityModel}
                                    variant="iconTextTypeTenantTags"/>)}
                title={localization.columns.identity()}/>
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EntityFilter
                                entityIdsOrSearchableReferences={tableDefinition.columnIdToItemValuesMap[ViolationTableColumnId.Originator]}
                                placeholder={localization.columns.originator()}/>
                    }
                }}
                id={ViolationTableColumnId.Originator}
                render={
                    optionalTableCell<ViolationTableItem>(
                        item =>
                            _.isNil(item.originatorModel)
                                ? undefined
                                : <Entity
                                    entityIdOrModel={item.originatorModel}
                                    variant="iconTextTypeTenantTags"/>)}
                title={localization.columns.originator()}/>
        </Table>);
}

enum ViolationTableColumnId {
    Identity = "identity",
    InternetGateway = "internetGateway",
    Originator = "originator",
    Time = "time",
    Vpc = "vpc"
}

class ViolationTableItem {
    constructor(
        public riskItem: Contract.AwsInboundExternalVpcRiskItem,
        public internetGatewayModel: Contract.EntityModel,
        public vpcModel: Contract.EntityModel,
        public identityModel?: Contract.EntityModel,
        public originatorModel?: Contract.EntityModel,
        public time?: string) {
    }
}