import { InlineItems, map, Steps, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useOpenRiskedEntityRisksStep } from "../../../../../../..";
import { Contract, entityModelStore, InlineEntities, InlinePermissionActions, NetworkScopeFormatter } from "../../../../../../../../../../../../../../../../common";
import { useAwsEc2InstanceStatusTranslator } from "../../../../../../../../../../../../../Entities/hooks";
import { useResourceGeneralInformationStep } from "../../../../../../../useResourceGeneralInformationStep";
import { useInstanceVulnerabilityStep } from "../../useInstanceVulnerabilityStep";

export type ContextSectionProps = {
    riskModel: Contract.AwsInboundExternalEc2InstanceRiskModel;
};

export function ContextSection({ riskModel }: ContextSectionProps) {
    const instanceModel = entityModelStore.useGet((riskModel.risk as Contract.EntityRisk).entityId) as Contract.AwsEc2InstanceModel;
    const instanceVulnerabilityStep = useInstanceVulnerabilityStep(riskModel);
    const openRiskedEntityRisksStep = useOpenRiskedEntityRisksStep(instanceModel, riskModel.risk.id);
    const resourceGeneralInformationStep = useResourceGeneralInformationStep(instanceModel);
    const publicAccess = riskModel.risk.instanceIdToDataMap[instanceModel.id].publicAccess;

    const ec2InstanceStatusTranslator = useAwsEc2InstanceStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsInboundExternalEc2InstanceRiskDefinition.hooks.useAwsInboundExternalEc2InstanceRiskInstanceDefinition.contextSection",
            () => ({
                [Contract.TypeNames.AwsInboundExternalEc2InstanceRiskInfoType]: {
                    [Contract.AwsInboundExternalEc2InstanceRiskInfoType.InstanceHighSeverityRolePermissions]: "The EC2 instance is associated with {{permissionActions}} with **high** severity",
                    [Contract.AwsInboundExternalEc2InstanceRiskInfoType.InstanceRoleNotExists]: "The EC2 instance has no credentials attached",
                    [Contract.AwsInboundExternalEc2InstanceRiskInfoType.NotSensitive]: "The EC2 instance is not marked as sensitive",
                    [Contract.AwsInboundExternalEc2InstanceRiskInfoType.Sensitive]: "The EC2 instance is marked as sensitive"
                },
                inboundExternalAccess: "The EC2 instance is publicly accessible from a wide range of public IP addresses and exposes {{networkScopes}} via {{securityGroups}}",
                ports: [
                    "1 port",
                    "{{count | NumberFormatter.humanize}} ports"
                ],
                status: "The EC2 instance state is **{{status}}**"
            }));
    return (
        <Steps variant="bullets">
            {_.filter([
                resourceGeneralInformationStep,
                instanceVulnerabilityStep,
                _.isNil(publicAccess)
                    ? undefined
                    : localization.inboundExternalAccess({
                        networkScopes:
                            <InlineItems
                                items={
                                    _(publicAccess.destinationNetworkScopes).
                                        map(networkScope => NetworkScopeFormatter.networkScopeFromDestinationNetworkScope(networkScope)).
                                        uniq().
                                        sort().
                                        value()}
                                namePluralizer={localization.ports}
                                variant="itemCountAndType"/>,
                        securityGroups:
                            <InlineEntities
                                entityIdsOrModels={publicAccess.securityGroupIds}
                                entityTypeName={Contract.TypeNames.AwsEc2SecurityGroup}
                                variant="itemCountAndType"/>
                    }),
                instanceModel.unknown
                    ? undefined
                    : localization.status({ status: ec2InstanceStatusTranslator(((instanceModel.entity) as Contract.AwsEc2Instance).status) }),
                ..._.map(
                    riskModel.risk.infos,
                    info => {
                        const infoLocalization = localization[Contract.TypeNames.AwsInboundExternalEc2InstanceRiskInfoType];
                        return map(
                            info.type,
                            {
                                [Contract.AwsInboundExternalEc2InstanceRiskInfoType.InstanceHighSeverityRolePermissions]: () =>
                                    infoLocalization[Contract.AwsInboundExternalEc2InstanceRiskInfoType.InstanceHighSeverityRolePermissions](
                                        {
                                            permissionActions:
                                                <InlinePermissionActions
                                                    permissionActions={(info as Contract.AwsInboundExternalEc2InstanceRiskHighSeverityInstanceRolePermissionsInfo).permissionActions}
                                                    variant="itemCountAndType"/>
                                        }),
                                [Contract.AwsInboundExternalEc2InstanceRiskInfoType.InstanceRoleNotExists]: () =>
                                    infoLocalization[Contract.AwsInboundExternalEc2InstanceRiskInfoType.InstanceRoleNotExists](),
                                [Contract.AwsInboundExternalEc2InstanceRiskInfoType.NotSensitive]: () =>
                                    infoLocalization[Contract.AwsInboundExternalEc2InstanceRiskInfoType.NotSensitive](),
                                [Contract.AwsInboundExternalEc2InstanceRiskInfoType.Sensitive]: () =>
                                    infoLocalization[Contract.AwsInboundExternalEc2InstanceRiskInfoType.Sensitive]()
                            },
                            () => undefined);
                    }
                ),
                openRiskedEntityRisksStep
            ])}
        </Steps>);
}