import { DataTableColumn, DataTableSortType, NumberFormatter, useLocalization } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { useGcpCommonEncryptedResourceDefinition, useGcpCommonScopeResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, ItemSelectionHelper, TimeRangeHelper } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";
import { useCommonDataAnalysisDefinition } from "../useCommonDataAnalysisDefinition";

export function useGcpStorageBucketDefinition(definitionData: DefinitionData) {
    const commonDataAnalysisResourceDefinition = useCommonDataAnalysisDefinition(definitionData);
    const commonEncryptedResourceDefinition = useGcpCommonEncryptedResourceDefinition(definitionData);
    const commonScopeResourceDefinition = useGcpCommonScopeResourceDefinition(definitionData);

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.gcp.useGcpStorageBucketDefinition",
            () => ({
                columns: {
                    objectCount: {
                        empty: "Unknown",
                        title: "Object Count"
                    },
                    size: {
                        empty: "Unknown",
                        title: "Size"
                    },
                    url: "Bucket URL"
                }
            }));

    return new EntityTableDefinition(
        [
            commonScopeResourceDefinition.columns.tenantColumn,
            commonScopeResourceDefinition.columns.creationTimeColumn,
            commonScopeResourceDefinition.columns.creatorIdentityCsvColumn,
            commonScopeResourceDefinition.columns.creatorOriginatorEntityCsvColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.GcpStorageBucketModel) => ({
                            [localization.columns.url()]: (item.entity as Contract.GcpStorageBucket).url
                        })
                }}
                id="url"
                key="url"/>,
            commonScopeResourceDefinition.columns.accessLevelColumn,
            commonScopeResourceDefinition.columns.updateTimeColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.GcpStorageBucketModel) => ({
                            [localization.columns.size.title()]:
                                _.isNil((item.entity as Contract.GcpStorageBucket).size)
                                    ? localization.columns.size.empty()
                                    : NumberFormatter.storage((item.entity as Contract.GcpStorageBucket).size!)
                        })
                }}
                id={Contract.EntityModelProperty.GcpStorageBucketSize}
                itemProperty={
                    (item: Contract.EntityModel) =>
                        _.isNil((item.entity as Contract.GcpStorageBucket).size)
                            ? localization.columns.size.empty()
                            : NumberFormatter.storage((item.entity as Contract.GcpStorageBucket).size!)}
                key={Contract.EntityModelProperty.GcpStorageBucketSize}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.size.title()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.GcpStorageBucketModel) => ({
                            [localization.columns.objectCount.title()]:
                                _.isNil((item.entity as Contract.GcpStorageBucket).objectCount)
                                    ? localization.columns.objectCount.empty()
                                    : NumberFormatter.humanize((item.entity as Contract.GcpStorageBucket).objectCount!)
                        })
                }}
                id={Contract.EntityModelProperty.GcpStorageBucketObjectCount}
                itemProperty={
                    (item: Contract.EntityModel) =>
                        _.isNil((item.entity as Contract.GcpStorageBucket).objectCount)
                            ? localization.columns.objectCount.empty()
                            : NumberFormatter.humanize((item.entity as Contract.GcpStorageBucket).objectCount!)}
                key={Contract.EntityModelProperty.GcpStorageBucketObjectCount}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.objectCount.title()}/>,
            commonEncryptedResourceDefinition.columns.encryptionExistsColumn,
            commonEncryptedResourceDefinition.columns.encryptionKeyColumn,
            commonScopeResourceDefinition.columns.regionColumn,
            commonScopeResourceDefinition.columns.regionLocationColumn,
            commonDataAnalysisResourceDefinition.columns.dataCategoriesColumn,
            commonDataAnalysisResourceDefinition.columns.dataAnalysisResourceSensitivityColumn,
            commonDataAnalysisResourceDefinition.columns.dataAnalysisStatusColumn,
            commonDataAnalysisResourceDefinition.columns.dataClassifiersColumn?.(false),
            commonDataAnalysisResourceDefinition.columns.scanTimeColumn,
            commonScopeResourceDefinition.columns.tagsColumn,
            commonScopeResourceDefinition.columns.attributesColumn,
            commonScopeResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonScopeResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonScopeResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageGcpStorageBucketRequest(
                new Contract.EntityControllerGetEntityModelPageGcpStorageBucketRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.GcpResourceUpdateTime]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpScopeResourceAccessLevel]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpScopeResourceRawId])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags])),
                new Contract.EntityControllerGetEntityModelPageEntityRequestDataAnalysis(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.DataAnalysisResourceScanStatus]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.DataAnalysisResourceDataCategories]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.DataAnalysisResourceDataClassifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.DataAnalysisResourceDataSensitivities]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.DataAnalysisResourceScanTime])),
                new Contract.EntityControllerGetEntityModelPageGcpScopeResourceRequestEncrypted(
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpEncryptedResourceEncryptionExists]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpEncryptedResourceKmsEncryptionKeys]))));
}