import { Avatar, IconButton, Typography } from "@mui/material";
import i18n from "i18next";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { ActionMenuItem, Menu, MenuItem, SeparatorMenuItem, StaticMenuItem, SubMenuItem, supportedLocalizationLanguages, ThemeType, UrlMenuItem, useChangeEffect, useLocalization } from "@infrastructure";
import { AuthenticationController, ConsoleAppUrlHelper, Contract, CustomerConsoleAppUrlHelper, FeatureHelper, PermissionManagementIcon, UrlHelper, UserConsoleAppUrlHelper, UserHelper, useTheme } from "../../../..";
import { ConsoleAppType } from "../../../../controllers/contract";
import { CustomerInfo } from "../CustomerInfo";
import { CustomerPortalIcon, DarkModeIcon, LanguageIcon, LightModeIcon, SignOutIcon, SwitchCustomerIcon, SystemDefaultIcon, ThemesIcon } from "../icons";
import { ElementId } from "./UserDropdown.element";

type UserDropdownProps = {
    consoleAppType: Contract.ConsoleAppType;
    customerContext: boolean;
};

export const avatarSize = 20;

export function UserDropdown({ consoleAppType, customerContext }: UserDropdownProps) {
    const localization =
        useLocalization(
            "common.topbar.userDropdown",
            () => ({
                actions: {
                    localization: {
                        languages: {
                            en: "English",
                            fr: "French",
                            ja: "Japanese"
                        },
                        title: "Language"
                    },
                    returnToTenable: "Return to Tenable One",
                    signOut: "Log out",
                    switchConsoleApp: {
                        customer: "Console Portal",
                        user: "JIT Portal"
                    },
                    switchCustomer: "Switch Customer",
                    themes: {
                        darkMode: "Dark Mode",
                        default: "System Default",
                        lightMode: "Light Mode",
                        title: "Themes"
                    }
                }
            }));

    const [selectedLanguage, setSelectedLanguage] = useState(i18n.resolvedLanguage);
    useChangeEffect(
        () => {
            i18n.changeLanguage(selectedLanguage);
        },
        [selectedLanguage]);
    useEffect(
        () => {
            if (!FeatureHelper.enabled(Contract.FeatureName.LocalizationEnabled)) {
                i18n.changeLanguage("en");
            }
        },
        []);

    const showSwitchCustomer = customerContext && UserHelper.multipleCustomers;
    const theme = useTheme();
    const [showSwitchToCustomerConsole, showSwitchToUserConsole] =
        useMemo(
            () => {
                const showSwitchToCustomerConsole = consoleAppType === ConsoleAppType.User && _.includes(UserHelper.enabledConsoleAppTypes, ConsoleAppType.Customer);
                const showSwitchToUserConsole = consoleAppType === ConsoleAppType.Customer && _.includes(UserHelper.enabledConsoleAppTypes, ConsoleAppType.User);

                return [showSwitchToCustomerConsole, showSwitchToUserConsole];
            },
            [consoleAppType]);
    return (
        <Menu
            itemsOrGetItems={
                _<MenuItem>([]).
                    concat([
                        new StaticMenuItem(<CustomerInfo/>),
                        new SeparatorMenuItem()]).
                    concatIf(
                        showSwitchCustomer,
                        new ActionMenuItem(
                            () => window.location.assign(ConsoleAppUrlHelper.getCustomersRelativeUrl(consoleAppType)),
                            localization.actions.switchCustomer(),
                            { icon: <SwitchCustomerIcon/> })).
                    concatIf(
                        showSwitchToCustomerConsole,
                        new UrlMenuItem(
                            localization.actions.switchConsoleApp.customer(),
                            CustomerConsoleAppUrlHelper.getRootRelativeUrl(),
                            { icon: <CustomerPortalIcon/> })).
                    concatIf(
                        showSwitchToUserConsole,
                        new UrlMenuItem(
                            localization.actions.switchConsoleApp.user(),
                            UserConsoleAppUrlHelper.getRootRelativeUrl(),
                            { icon: <PermissionManagementIcon/> })).
                    concatIf(
                        FeatureHelper.enabled(Contract.FeatureName.LocalizationEnabled),
                        new SubMenuItem(
                            _.map(
                                supportedLocalizationLanguages,
                                language =>
                                    new ActionMenuItem(
                                        () => setSelectedLanguage(language),
                                        localization.actions.localization.languages.translate(language),
                                        { selected: language === selectedLanguage })),
                            localization.actions.localization.title(),
                            { icon: <LanguageIcon/> })).
                    concat([
                        new SubMenuItem(
                            [
                                new ActionMenuItem(
                                    () => theme.setType(ThemeType.Light),
                                    localization.actions.themes.lightMode(),
                                    {
                                        icon: <LightModeIcon/>,
                                        selected: theme.type === ThemeType.Light
                                    }
                                ),
                                new ActionMenuItem(
                                    () => theme.setType(ThemeType.Dark),
                                    localization.actions.themes.darkMode(),
                                    {
                                        icon: <DarkModeIcon/>,
                                        selected: theme.type === ThemeType.Dark
                                    }
                                ),
                                new ActionMenuItem(
                                    () => theme.setType(ThemeType.System),
                                    localization.actions.themes.default(),
                                    {
                                        icon: <SystemDefaultIcon/>,
                                        selected: theme.type === ThemeType.System
                                    }
                                )
                            ],
                            localization.actions.themes.title(),
                            { icon: <ThemesIcon/> }),
                        new SeparatorMenuItem(),
                        new ActionMenuItem(
                            async () => {
                                await AuthenticationController.signOutUser();
                                window.location.assign(
                                    UserHelper.tenable
                                        ? UrlHelper.tenableMyApplicationsUrl
                                        : ConsoleAppUrlHelper.getSignInRelativeUrl(consoleAppType));
                            },
                            UserHelper.tenable
                                ? localization.actions.returnToTenable()
                                : localization.actions.signOut(),
                            { icon: <SignOutIcon/> }
                        )]).
                    value()}
            variant="bottomCenter">
            <IconButton size="medium">
                <Avatar
                    id={ElementId.avatar}
                    sx={{
                        backgroundColor: theme.palette.avatar.identity.defaultBackground,
                        borderRadius: theme.spacing(0.75),
                        color: theme.palette.avatar.identity.color,
                        height: theme.px(avatarSize),
                        width: theme.px(avatarSize)
                    }}>
                    <Typography
                        sx={{ lineHeight: 1 }}
                        variant="button">
                        {_.first(UserHelper.nameValue)}
                    </Typography>
                </Avatar>
            </IconButton>
        </Menu>);
}