import { alpha, Breakpoint, colors, createTheme, darken, GlobalStyles, outlinedInputClasses, StyledEngineProvider, Theme, ThemeProvider } from "@mui/material";
import _ from "lodash";
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { Action1, StorageHelper, useChangeEffect } from "@infrastructure";
import { AttributePalette, AvatarPalette, BorderColorPalette, BorderPalette, ChartPalette, DataPalette, InputPalette, RiskPalette, SeverityPalette, TablePalette, TextEditorPalette, ThemeType, ToastPalette, UdmPalette, useColorTheme, WorkloadAnalysisPalette } from "./useColorTheme";

export type ApplicationThemeProviderProps = {
    children: ReactNode;
};

export function ApplicationThemeProvider({ children }: ApplicationThemeProviderProps) {
    const [type, setType] =
        useState<ThemeType>(() => StorageHelper.theme.getValue() as ThemeType ?? ThemeType.System);
    const { colorPalette: palette, dark } = useColorTheme(type);
    useChangeEffect(
        () => StorageHelper.theme.setValue(type),
        [type]);

    useEffect(
        () => {
            if (dark) {
                window.document.body.classList.add("dark");
            } else {
                window.document.body.classList.remove("dark");
            }
        },
        [dark]);

    const px =
        (value: number | string) =>
            _.isString(value)
                ? value
                : `${value}px`;

    const theme =
        useMemo(
            () => {
                const theme =
                    createTheme({
                        breakpoints: {
                            values: {
                                lg: 1440,
                                md: 1280,
                                sm: 600,
                                xl: 1920,
                                xs: 0
                            }
                        },
                        palette: {
                            action: {
                                active: palette.action.active,
                                alternate: palette.action.alternate,
                                alternateHover: palette.action.alternateHover,
                                disabled: palette.action.disabled,
                                hover: palette.action.hover,
                                selected: palette.action.selected
                            },
                            attribute: palette.attribute,
                            avatar: palette.avatar,
                            background: {
                                alternate: palette.background.alternate,
                                default: palette.background.secondary,
                                paper: palette.background.primary
                            },
                            borders: palette.borders,
                            chart: palette.chart,
                            data: palette.data,
                            divider: palette.borders.primary,
                            error: {
                                dark: palette.error.dark,
                                main: palette.error.primary
                            },
                            info: {
                                main: palette.text.secondary
                            },
                            input: palette.input,
                            link: {
                                hover: palette.link.hover,
                                primary: palette.link.primary,
                                secondary: palette.link.secondary
                            },
                            logo: palette.logo,
                            primary: {
                                dark: palette.primary.dark,
                                main: palette.primary.main
                            },
                            risk: palette.risk,
                            scrollbar: palette.scrollbar,
                            secondary: {
                                dark: darken("#0079DD", 0.1),
                                main: "#0079DD"
                            },
                            severity: palette.severity,
                            sidebar: palette.sidebar,
                            success: {
                                main: palette.success
                            },
                            table: palette.table,
                            text: {
                                disabled: palette.text.disabled,
                                primary: palette.text.primary,
                                secondary: palette.text.secondary
                            },
                            textEditor: palette.textEditor,
                            toast: palette.toast,
                            udm: palette.udm,
                            warning: {
                                light: palette.warning.light,
                                main: palette.warning.primary
                            },
                            workload: palette.workload
                        },
                        typography: {
                            fontFamily: "Inter"
                        }
                    });

                theme.dark = dark;
                theme.type = type;
                theme.setType = setType;
                theme.shadows[Shadows.Avatar] = `0px 6px 12px ${palette.shadows.avatar}`;
                theme.shadows[Shadows.Card] = `0px 5px 20px ${palette.shadows.card}`;
                theme.shadows[Shadows.FloutItem] = `0px 0px 10px 0px ${palette.shadows.floutItem}`;
                theme.shadows[Shadows.GraphDot] = palette.shadows.graphDot;
                theme.shadows[Shadows.Highlight] = `0px 4px 30px ${palette.shadows.highlight}`;
                theme.shadows[Shadows.SideNavigation] = `0px 10px 70px 0px ${palette.shadows.sideNavigation}`;
                theme.shadows[Shadows.SelectionActions] = `0px 0px 30px 0px ${palette.shadows.selectionActions}`;
                theme.shadows[Shadows.TableLeftEdge] = `16px 0px 16px -16px inset ${palette.shadows.tableEdge}`;
                theme.shadows[Shadows.TableRightEdge] = `-16px 0px 16px -16px inset ${palette.shadows.tableEdge}`;
                theme.shadows[Shadows.Tooltip] = `0px 4px 20px ${palette.shadows.tooltip}`;

                const actionBorder = `solid 1px ${alpha(theme.palette.text.secondary, 0.7)}`;
                const alternateBorder = `solid 1px ${palette.borders.alternate}`;
                const defaultBorder = `solid 1px ${palette.borders.primary}`;
                const defaultInputBorder = `solid 1px ${palette.borders.hoverFocus}`;
                const transparentBorder = `solid 1px transparent`;

                const defaultThemeOptions =
                    createTheme(
                        theme,
                        {
                            border: {
                                action: actionBorder,
                                alternate: alternateBorder,
                                error: `solid 1px ${palette.borders.error}`,
                                hoverFocus: defaultInputBorder,
                                pageFrame: `solid 1px ${palette.borders.pageFrame}`,
                                primary: defaultBorder,
                                transparent: transparentBorder
                            },
                            breakpoints: {
                                downOrEqual: (breakpoint: Breakpoint) => theme.breakpoints.down(theme.breakpoints.values[breakpoint] + 1),
                                get: (breakpoint: Breakpoint) => theme.breakpoints.values[breakpoint]
                            },
                            components: {
                                MuiAccordion: {
                                    styleOverrides: {
                                        root: {
                                            "&.Mui-disabled": {
                                                backgroundColor: theme.palette.background.paper,
                                                color: theme.palette.text.disabled
                                            },
                                            "&.Mui-expanded": {
                                                margin: 0
                                            },
                                            "&:before": {
                                                display: "none"
                                            },
                                            "&:first-of-type": {
                                                borderTopLeftRadius: theme.spacing(1),
                                                borderTopRightRadius: theme.spacing(1)
                                            },
                                            "&:last-of-type": {
                                                borderBottomLeftRadius: theme.spacing(1),
                                                borderBottomRightRadius: theme.spacing(1)
                                            },
                                            "&:not(:last-child)": {
                                                borderBottom: 0
                                            },
                                            ".MuiTableBody-root .MuiTableRow-root:last-child .MuiTableCell-root": {
                                                borderBottom: "none"
                                            },
                                            boxShadow: "none"
                                        }
                                    }
                                },
                                MuiAccordionDetails: {
                                    styleOverrides: {
                                        root: {
                                            display: "flex",
                                            flexDirection: "column",
                                            margin: theme.spacing(0.5, 0, 0.5, 2),
                                            maxWidth: "100%",
                                            minHeight: theme.spacing(5),
                                            padding: theme.spacing(0, 0, 0, 1.75)
                                        }
                                    }
                                },
                                MuiAccordionSummary: {
                                    styleOverrides: {
                                        content: {
                                            "&.Mui-expanded": {
                                                margin: 0,
                                                marginLeft: theme.spacing(0.5)
                                            },
                                            alignItems: "center",
                                            margin: 0,
                                            marginLeft: theme.spacing(0.5),
                                            maxWidth: "calc(100% - 20px)"
                                        },
                                        expandIconWrapper: {
                                            "&.Mui-expanded": {
                                                color: theme.palette.text.primary,
                                                fontSize: "18px",
                                                transform: "rotate(90deg)"
                                            },
                                            color: theme.palette.text.primary,
                                            fontSize: "18px",
                                            marginBottom: 0,
                                            transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
                                        },
                                        root: {
                                            "&.Mui-disabled": {
                                                opacity: 1
                                            },
                                            "&.Mui-expanded": {
                                                margin: 0,
                                                minHeight: theme.spacing(5.5)
                                            },
                                            "&:hover": {
                                                background: palette.action.hover
                                            },
                                            borderRadius: theme.spacing(0.75),
                                            flexDirection: "row-reverse",
                                            minHeight: theme.spacing(5.5),
                                            padding: theme.spacing(1)
                                        }
                                    }
                                },
                                MuiAutocomplete: {
                                    defaultProps: {
                                        autoComplete: "on"
                                    },
                                    styleOverrides: {
                                        clearIndicator: {
                                            "& .MuiSvgIcon-root": {
                                                marginRight: 0
                                            },
                                            marginRight: 0
                                        },
                                        inputRoot: {
                                            backgroundColor: "unset"
                                        },
                                        listbox: {
                                            ".MuiAutocomplete-option[aria-selected=\"true\"], .MuiAutocomplete-option[aria-selected=\"true\"].Mui-focused": {
                                                backgroundColor: "unset"
                                            }
                                        },
                                        popupIndicator: {
                                            "& .MuiSvgIcon-root": {
                                                marginRight: 0
                                            },
                                            marginRight: 0
                                        }
                                    }
                                },
                                MuiAvatar: {
                                    styleOverrides: {
                                        root: {
                                            backgroundColor: "transparent"
                                        }
                                    }
                                },
                                MuiButton: {
                                    defaultProps: {
                                        color: "secondary",
                                        disableRipple: true,
                                        variant: "contained"
                                    },
                                    styleOverrides: {
                                        contained: {
                                            "&:hover": {
                                                boxShadow: "none"
                                            },
                                            background: theme.palette.secondary.main,
                                            boxShadow: "none",
                                            color: "white"
                                        },
                                        containedPrimary: {
                                            "&:hover": {
                                                background: theme.palette.primary.dark
                                            },
                                            background: theme.palette.primary.main
                                        },
                                        outlined: {
                                            "&:hover": {
                                                backgroundColor: "unset",
                                                borderColor: theme.palette.text.secondary
                                            },
                                            borderColor: theme.palette.borders.hoverFocus,
                                            color: theme.palette.text.primary
                                        },
                                        outlinedPrimary: {
                                            "&:hover": {
                                                background: theme.palette.background.paper,
                                                borderColor: palette.borders.hoverFocus
                                            },
                                            background: theme.palette.background.paper,
                                            borderColor: palette.borders.primary
                                        },
                                        root: {
                                            "& .MuiButton-endIcon": {
                                                marginLeft: theme.spacing(0.5)
                                            },
                                            "& .MuiButton-startIcon": {
                                                marginRight: theme.spacing(0.5)
                                            },
                                            "& .MuiButton-startIcon > :nth-of-type(1)": {
                                                fontSize: "18px"
                                            },
                                            "&.Mui-disabled": {
                                                background: palette.action.disabled,
                                                color: palette.text.disabled
                                            },
                                            borderRadius: theme.spacing(0.75),
                                            fontSize: "14px",
                                            fontWeight: 400,
                                            lineHeight: 1
                                        },
                                        sizeLarge: {
                                            fontSize: "16px",
                                            padding: "12px"
                                        },
                                        sizeMedium: {
                                            minHeight: 32,
                                            padding: theme.spacing(0.5, 1.5)
                                        },
                                        sizeSmall: {
                                            fontSize: "12px",
                                            minHeight: 28,
                                            padding: theme.spacing(0.5, 1)
                                        },
                                        text: {
                                            "&:hover": {
                                                background: "unset",
                                                textDecoration: "underline"
                                            },
                                            borderRadius: theme.spacing(0.75),
                                            color: theme.palette.text.primary,
                                            height: "unset",
                                            minWidth: "unset",
                                            padding: theme.spacing(0.5, 1.5),
                                            textDecoration: "none",
                                            width: "unset"
                                        }
                                    }
                                },
                                MuiButtonBase: {
                                    defaultProps: {
                                        disableRipple: true
                                    }
                                },
                                MuiCard: {
                                    styleOverrides: {
                                        root: {
                                            borderRadius: theme.spacing(0.75),
                                            boxShadow: theme.shadows[Shadows.Card],
                                            boxSizing: "border-box"
                                        }
                                    },
                                    variants: [
                                        {
                                            props: { variant: "alternate" },
                                            style: {
                                                background: palette.background.alternate
                                            }
                                        }
                                    ]
                                },
                                MuiCheckbox: {
                                    styleOverrides: {
                                        root: {
                                            "&.Mui-disabled": {
                                                color: palette.text.disabled
                                            },
                                            "&:hover": {
                                                backgroundColor: "unset"
                                            },
                                            paddingBottom: theme.spacing(0.5),
                                            paddingLeft: 0,
                                            paddingRight: theme.spacing(0.5),
                                            paddingTop: theme.spacing(0.5)
                                        }
                                    }
                                },
                                MuiCircularProgress: {
                                    styleOverrides: {
                                        root: {
                                            color: theme.palette.grey[400]
                                        }
                                    }
                                },
                                MuiDialog: {
                                    styleOverrides: {
                                        paper: {
                                            borderRadius: theme.spacing(0.75),
                                            maxHeight: "85vh",
                                            maxWidth: theme.spacing(187.5)
                                        }
                                    }
                                },
                                MuiDivider: {
                                    styleOverrides: {
                                        vertical: {
                                            borderColor: theme.palette.borders.hoverFocus
                                        }
                                    },
                                    variants: [
                                        {
                                            props: {
                                                variant: "text"
                                            },
                                            style: {
                                                borderBottomWidth: 0,
                                                borderRightWidth: "thin",
                                                marginBottom: "4px !important",
                                                marginTop: "4px !important"
                                            }
                                        }
                                    ]
                                },
                                MuiFab: {
                                    styleOverrides: {
                                        extended: {
                                            "&:active": {
                                                backgroundColor: "#A5A5A5"
                                            },
                                            "&:hover": {
                                                backgroundColor: "#F2F1F1"
                                            },
                                            backgroundColor: theme.palette.background.paper,
                                            color: "#444545",
                                            width: "100%"
                                        }
                                    }
                                },
                                MuiFilledInput: {
                                    styleOverrides: {
                                        root: {
                                            "&.Mui-focused": {
                                                backgroundColor: theme.palette.background.paper
                                            },
                                            "&:hover": {
                                                backgroundColor: theme.palette.background.paper
                                            },
                                            backgroundColor: theme.palette.background.paper
                                        }
                                    }
                                },
                                MuiFormControl: {
                                    styleOverrides: {
                                        marginDense: {
                                            marginBottom: 0,
                                            marginTop: 0
                                        }
                                    }
                                },
                                MuiFormControlLabel: {
                                    styleOverrides: {
                                        label: {
                                            overflow: "hidden",
                                            width: "100%"
                                        },
                                        root: {
                                            marginLeft: 0,
                                            marginRight: 0
                                        }
                                    }
                                },
                                MuiIconButton: {
                                    defaultProps: {
                                        disableRipple: true
                                    },
                                    styleOverrides: {
                                        root: {
                                            "&.Mui-disabled": {
                                                background: palette.action.disabled,
                                                color: palette.text.secondary
                                            },
                                            "&:hover": {
                                                background: palette.action.hover
                                            },
                                            borderRadius: theme.spacing(0.75),
                                            color: theme.palette.text.primary,
                                            height: 28,
                                            padding: 0,
                                            width: 28
                                        },
                                        sizeMedium: {
                                            fontSize: "18px",
                                            height: 28,
                                            width: 28
                                        },
                                        sizeSmall: {
                                            fontSize: "18px",
                                            height: 24,
                                            width: 24
                                        }
                                    }
                                },
                                MuiInput: {
                                    styleOverrides: {
                                        root: {
                                            "&:after": {
                                                borderBottom: `1px solid ${theme.palette.text.primary}`
                                            }
                                        }
                                    }
                                },
                                MuiInputAdornment: {
                                    styleOverrides: {
                                        root: {
                                            color: "inherit"
                                        }
                                    }
                                },
                                MuiInputBase: {
                                    defaultProps: {
                                        autoComplete: "off"
                                    },
                                    styleOverrides: {
                                        adornedStart: {
                                            "&.Mui-focused .MuiInputAdornment-root, &:hover .MuiInputAdornment-root": {
                                                color: theme.palette.text.primary
                                            },
                                            ".MuiInputAdornment-root": {
                                                marginLeft: theme.spacing(1)
                                            }
                                        },
                                        input: {
                                            "&.Mui-disabled": {
                                                color: palette.text.secondary,
                                                fontWeight: 400,
                                                WebkitTextFillColor: palette.text.secondary
                                            },
                                            "&::placeholder": {
                                                color: theme.palette.text.secondary,
                                                fontWeight: 400,
                                                opacity: 1
                                            },
                                            "input&:read-only": {
                                                color: theme.palette.text.secondary,
                                                fontWeight: 400
                                            }
                                        },
                                        root: {
                                            "& textarea": {
                                                background: "unset !important"
                                            },
                                            "&.Mui-disabled": {
                                                color: palette.text.disabled,
                                                WebkitTextFillColor: palette.text.disabled
                                            },
                                            "&.MuiInputBase-multiline": {
                                                maxHeight: 500,
                                                overflow: "auto"
                                            },
                                            ".MuiInputBase-input:not(:placeholder-shown):not(.Mui-disabled), &:has(.MuiInputBase-input:not(:placeholder-shown)):not(.Mui-disabled)": {
                                                caretColor: theme.palette.text.primary,
                                                color: theme.palette.text.primary,
                                                WebkitTextFillColor: theme.palette.text.primary
                                            },
                                            ".MuiInputBase-input:placeholder-shown:not(.Mui-disabled), &:has(.MuiInputBase-input:placeholder-shown):not(.Mui-disabled)": {
                                                caretColor: theme.palette.text.primary,
                                                color: theme.palette.text.secondary,
                                                WebkitTextFillColor: theme.palette.text.secondary
                                            },
                                            borderRadius: theme.spacing(0.75),
                                            fontWeight: 500
                                        }
                                    }
                                },
                                MuiInputLabel: {
                                    styleOverrides: {
                                        root: {
                                            "&.Mui-focused": {
                                                color: theme.palette.text.secondary
                                            },
                                            "&:not(.Mui-disabled)": {
                                                WebkitTextFillColor: theme.palette.text.secondary
                                            },
                                            color: theme.palette.text.secondary
                                        }
                                    }
                                },
                                MuiLink: {
                                    styleOverrides: {
                                        root: {
                                            ":hover": {
                                                color: palette.link.hover
                                            },
                                            color: palette.link.primary
                                        }
                                    }
                                },
                                MuiListItem: {
                                    styleOverrides: {
                                        button: {
                                            "&.Mui-focusVisible": {
                                                backgroundColor: "unset"
                                            },
                                            paddingBottom: 10,
                                            paddingTop: 10
                                        },
                                        root: {
                                            "&.Mui-focusVisible": {
                                                backgroundColor: "unset"
                                            },
                                            "&.Mui-selected": {
                                                "&:hover": {
                                                    backgroundColor: palette.action.selected
                                                },
                                                backgroundColor: theme.palette.action.selected
                                            },
                                            color: theme.palette.text.primary
                                        }
                                    }
                                },
                                MuiListItemButton: {
                                    styleOverrides: {
                                        root: {
                                            "&.Mui-focusVisible": {
                                                backgroundColor: "unset"
                                            },
                                            "&.Mui-selected": {
                                                "&:hover": {
                                                    backgroundColor: palette.action.selected
                                                },
                                                backgroundColor: palette.action.selected,
                                                borderLeftColor: "transparent !important"
                                            },
                                            border: "unset",
                                            paddingBottom: 10,
                                            paddingRight: 32,
                                            paddingTop: 10
                                        }
                                    }
                                },
                                MuiMenu: {
                                    styleOverrides: {
                                        root: {
                                            ".MuiList-root": {
                                                paddingBottom: 0,
                                                paddingTop: 0
                                            }
                                        }
                                    }
                                },
                                MuiMenuItem: {
                                    styleOverrides: {
                                        root: {
                                            "&.Mui-selected": {
                                                "&:hover": {
                                                    backgroundColor: palette.action.selected
                                                },
                                                backgroundColor: palette.action.selected
                                            }
                                        }
                                    }
                                },
                                MuiOutlinedInput: {
                                    styleOverrides: {
                                        input: {
                                            lineHeight: 1.5
                                        },
                                        notchedOutline: {
                                            borderColor: palette.borders.primary
                                        },
                                        root: {
                                            [`&.${outlinedInputClasses.notchedOutline}, &:hover .${outlinedInputClasses.notchedOutline}, &.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]: {
                                                border: defaultInputBorder
                                            },
                                            "& textarea": {
                                                padding: 0
                                            },
                                            "&.Mui-disabled": {
                                                background: palette.input.disabledBackground
                                            },
                                            "&.Mui-disabled .MuiInputAdornment-root": {
                                                opacity: 0.3
                                            },
                                            "&.Mui-focused": {
                                                color: theme.palette.text.primary
                                            },
                                            backgroundColor: theme.palette.background.paper,
                                            borderRadius: theme.spacing(0.75),
                                            fontSize: "12px",
                                            maxHeight: 50
                                        }
                                    }
                                },
                                MuiPickerStaticWrapper: {
                                    styleOverrides: {
                                        root: {
                                            ".PrivatePickersSlideTransition-root": {
                                                minHeight: "210px"
                                            }
                                        }
                                    }
                                },
                                MuiPopover: {
                                    styleOverrides: {
                                        paper: {
                                            background: theme.palette.background.paper,
                                            border: defaultBorder,
                                            borderRadius: theme.spacing(0.75)
                                        }
                                    }
                                },
                                MuiRadio: {
                                    defaultProps: {
                                        disableRipple: true
                                    },
                                    styleOverrides: {
                                        colorPrimary: {
                                            "&.Mui-checked": {
                                                color: theme.palette.text.primary
                                            },
                                            "&.Mui-disabled": {
                                                color: `${palette.text.disabled} !important`
                                            }
                                        },
                                        root: {
                                            "&:hover": {
                                                backgroundColor: "unset"
                                            },
                                            fontSize: "18px",
                                            paddingLeft: 0
                                        }
                                    }
                                },
                                MuiSelect: {
                                    styleOverrides: {
                                        icon: {
                                            fontSize: "18px"
                                        },
                                        outlined: {
                                            "&.Mui-disabled": {
                                                backgroundColor: theme.palette.grey[100],
                                                color: palette.text.disabled
                                            },
                                            borderRadius: theme.spacing(0.25)
                                        }
                                    }
                                },
                                MuiSlider: {
                                    styleOverrides: {
                                        mark: {
                                            borderRadius: 0,
                                            color: theme.palette.background.paper,
                                            height: "4px"
                                        },
                                        markActive: {
                                            opacity: 1
                                        },
                                        rail: {
                                            borderRadius: theme.spacing(0.25),
                                            color: "#D0D6E1",
                                            height: 4,
                                            opacity: 1
                                        },
                                        root: {
                                            boxSizing: "border-box"
                                        },
                                        thumb: {
                                            border: "solid 2px #D0D6E1",
                                            color: theme.palette.background.paper,
                                            height: theme.spacing(3),
                                            width: theme.spacing(3)
                                        },
                                        track: {
                                            borderRadius: theme.spacing(0.25),
                                            boxSizing: "border-box",
                                            height: 4
                                        }
                                    }
                                },
                                MuiStack: {
                                    styleOverrides: {
                                        root: {
                                            boxSizing: "border-box"
                                        }
                                    }
                                },
                                MuiSvgIcon: {
                                    styleOverrides: {
                                        root: {
                                            display: "flex",
                                            fontSize: "inherit"
                                        }
                                    }
                                },
                                MuiSwitch: {
                                    styleOverrides: {
                                        checked: {},
                                        colorPrimary: {
                                            "&.Mui-checked": {
                                                color: theme.palette.error.main
                                            },
                                            "&.Mui-checked + .Mui-track": {
                                                backgroundColor: theme.palette.error.main
                                            }
                                        },
                                        colorSecondary: {
                                            "&.Mui-checked": {
                                                color: theme.palette.secondary.main
                                            }
                                        },
                                        thumb: {
                                            ".Mui-disabled.Mui-checked.MuiSwitch-colorPrimary &": {
                                                backgroundColor: theme.palette.error.light,
                                                opacity: 0.65
                                            },
                                            ".Mui-disabled.Mui-checked.MuiSwitch-colorSecondary &": {
                                                color: theme.palette.primary.main,
                                                opacity: 0.4
                                            },
                                            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.3)"
                                        },
                                        track: {
                                            ".Mui-checked.Mui-checked + &": {
                                                backgroundColor: theme.palette.secondary.main
                                            },
                                            ".Mui-checked.MuiSwitch-colorPrimary + &": {
                                                backgroundColor: theme.palette.error.main,
                                                opacity: 0.5
                                            },
                                            ".Mui-disabled + &": {
                                                backgroundColor: theme.palette.secondary.main,
                                                opacity: 1
                                            },
                                            ".Mui-disabled.Mui-checked.MuiSwitch-colorPrimary + &": {
                                                backgroundColor: theme.palette.error.light,
                                                opacity: 0.15
                                            },
                                            ".Mui-disabled.Mui-checked.MuiSwitch-colorSecondary + &": {
                                                backgroundColor: theme.palette.secondary.main,
                                                opacity: 0.2
                                            },
                                            background: palette.action.slider,
                                            opacity: 1
                                        }
                                    }
                                },
                                MuiTab: {
                                    styleOverrides: {
                                        root: {
                                            "&.Mui-selected": {
                                                fontWeight: 500
                                            },
                                            color: theme.palette.text.secondary,
                                            fontSize: "13px",
                                            fontWeight: 400,
                                            maxWidth: "unset",
                                            minHeight: "40px",
                                            minWidth: "0",
                                            padding: theme.spacing(1, 2)
                                        }
                                    }
                                },
                                MuiTableCell: {
                                    styleOverrides: {
                                        head: {
                                            backgroundColor: theme.palette.background.paper,
                                            borderColor: palette.borders.primary
                                        },
                                        root: {
                                            borderColor: "transparent",
                                            fontSize: "12px",
                                            padding: theme.spacing(0.5, 1)
                                        }
                                    }
                                },
                                MuiTableRow: {
                                    styleOverrides: {
                                        root: {
                                            "&.Mui-selected, &.Mui-selected:hover": {
                                                backgroundColor: colors.blue[50]
                                            },
                                            "&.MuiTableRow-head": {
                                                height: theme.spacing(5)
                                            },
                                            "&:not(.MuiTableRow-head)": {
                                                height: theme.spacing(5.5)
                                            }
                                        }
                                    }
                                },
                                MuiTableSortLabel: {
                                    styleOverrides: {
                                        root: {
                                            "&:hover": {
                                                color: "unset"
                                            }
                                        }
                                    }
                                },
                                MuiTabs: {
                                    styleOverrides: {
                                        flexContainerVertical: {
                                            alignItems: "self-start",
                                            justifyContent: "flex-start"
                                        },
                                        root: {
                                            ".MuiButtonBase-root": {
                                                "&.Mui-selected": {
                                                    color: theme.palette.text.primary,
                                                    fontWeight: 400
                                                },
                                                color: theme.palette.text.primary
                                            },
                                            ".MuiTabs-indicator": {
                                                background: palette.tabs.selected
                                            },
                                            borderBottom: `solid 1px ${theme.palette.divider}`,
                                            minHeight: "40px"
                                        },
                                        scrollButtons: {
                                            fontSize: "18px",
                                            padding: theme.spacing(0, 2),
                                            width: "unset"
                                        },
                                        vertical: {
                                            ".MuiButtonBase-root": {
                                                "&.Mui-selected": {
                                                    "&:hover": {
                                                        backgroundColor: palette.action.selected
                                                    },
                                                    backgroundColor: theme.palette.action.selected,
                                                    fontWeight: 400
                                                },
                                                "&:hover": {
                                                    backgroundColor: palette.action.hover
                                                },
                                                alignItems: "center",
                                                borderRadius: theme.spacing(0.75),
                                                fontSize: "14px",
                                                justifyContent: "start",
                                                minHeight: theme.spacing(5),
                                                overflow: "hidden",
                                                padding: theme.spacing(1.25, 1.5),
                                                textWrap: "nowrap",
                                                width: "100%"
                                            },
                                            ".MuiTabs-indicator": {
                                                backgroundColor: "transparent",
                                                left: 0,
                                                transition: "unset"
                                            },
                                            ".MuiTabs-scroller": {
                                                width: "100%"
                                            },
                                            borderRight: `solid 1px ${theme.palette.divider}`,
                                            minWidth: "fit-content",
                                            padding: theme.spacing(0, 0.75)
                                        }
                                    }
                                },
                                MuiTextField: {
                                    defaultProps: {
                                        autoComplete: "off"
                                    },
                                    styleOverrides: {
                                        root: {
                                            "& .MuiInputBase-root.Mui-focused::before, & .MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error)::before": {
                                                borderBottom: `1px solid ${theme.palette.borders.hoverFocus}`
                                            },
                                            "& .MuiInputBase-root::before": {
                                                borderBottom: `1px solid ${theme.palette.borders.primary}`
                                            },
                                            "& input:-webkit-autofill": {
                                                borderRadius: "unset",
                                                WebkitBoxShadow: `0 0 0 100px ${theme.palette.background.paper} inset`,
                                                WebkitTextFillColor: theme.palette.text.primary
                                            }
                                        }
                                    }
                                },
                                MuiToggleButton: {
                                    styleOverrides: {
                                        root: {
                                            "&.Mui-disabled": {
                                                color: "rgba(0, 0, 0, 0.12)"
                                            },
                                            "&.Mui-selected": {
                                                "&:hover": {
                                                    backgroundColor: palette.action.selected
                                                },
                                                "&:is(:first-of-type)": {
                                                    borderRight: defaultBorder
                                                },
                                                "&:is(:last-of-type)": {
                                                    borderLeft: defaultBorder
                                                },
                                                "&:not(:first-of-type)&:not(:last-of-type)": {
                                                    borderLeft: defaultBorder,
                                                    borderRight: defaultBorder
                                                },
                                                backgroundColor: theme.palette.background.paper,
                                                border: `solid 1px ${palette.borders.hoverFocus}`,
                                                color: theme.palette.text.primary,
                                                fontWeight: 400
                                            },
                                            "&:hover": {
                                                backgroundColor: palette.action.hover
                                            },
                                            border: defaultBorder,
                                            borderRadius: theme.spacing(0.75),
                                            color: theme.palette.text.secondary,
                                            fontSize: "20px",
                                            height: 28,
                                            minWidth: "unset",
                                            textTransform: "none"
                                        }
                                    }
                                },
                                MuiToggleButtonGroup: {
                                    styleOverrides: {
                                        root: {
                                            ".MuiToggleButtonGroup-grouped:not(:first-of-type)": {
                                                marginLeft: 0
                                            },
                                            borderRadius: theme.spacing(0.75)
                                        }
                                    }
                                },
                                MuiTooltip: {
                                    styleOverrides: {
                                        tooltip: {
                                            background: theme.palette.background.paper,
                                            border: defaultBorder,
                                            borderRadius: theme.spacing(0.75),
                                            boxShadow: theme.shadows[Shadows.Tooltip],
                                            color: theme.palette.text.primary,
                                            fontSize: "12px",
                                            marginTop: 0,
                                            padding: theme.spacing(1)
                                        }
                                    }
                                },
                                MuiTypography: {
                                    defaultProps: {
                                        variantMapping: {
                                            body1: "div",
                                            body2: "div",
                                            subtitle1: "div",
                                            subtitle2: "div"
                                        }
                                    }
                                }
                            },
                            important: (value: number | string) => `${px(value)} !important`,
                            px,
                            typography: {
                                button: {
                                    fontSize: "12px",
                                    textTransform: "none"
                                },
                                caption: {
                                    fontSize: "12px",
                                    fontWeight: 700
                                },
                                h1: {
                                    fontSize: "22px",
                                    fontWeight: 500,
                                    lineHeight: "34px"
                                },
                                h2: {
                                    fontSize: "18px",
                                    fontWeight: 700
                                },
                                h3: {
                                    fontSize: "16px",
                                    fontWeight: 700
                                },
                                h4: {
                                    fontSize: "14px",
                                    fontWeight: 700
                                },
                                h5: {
                                    fontSize: "12px",
                                    fontWeight: 700
                                },
                                subtitle1: {
                                    color: theme.palette.text.secondary,
                                    fontSize: "12px",
                                    fontWeight: 400
                                }
                            }
                        });

                return createTheme(defaultThemeOptions);
            },
            [palette]);

    const globalStylesTheme =
        useCallback(
            (theme: Theme) => ({
                "*": {
                    scrollbarColor: theme.palette.scrollbar
                },
                "*::-webkit-scrollbar": {
                    cursor: "default",
                    height: "8px",
                    width: "8px"
                },
                "*::-webkit-scrollbar-corner": {
                    background: "transparent"
                },
                "*::-webkit-scrollbar-thumb": {
                    backgroundColor: theme.palette.scrollbar,
                    borderRadius: 3,
                    cursor: "default"
                },
                ".CodeMirror-info, .CodeMirror-lint-tooltip, reach-portal": {
                    position: "fixed",
                    zIndex: theme.zIndex.snackbar
                },
                ".MuiBox-root": {
                    boxSizing: "border-box"
                },
                ":focus": {
                    outline: "none"
                },
                ":root": {
                    "--side-view-width": "65vw"
                },
                body: {
                    "&.col-resize": {
                        cursor: "ew-resize !important"
                    },
                    "&.dark": {
                        "--bg-categories": "#555E6B",
                        "--bg-primary": "#2B2E33",
                        "--border": "#3D4149",
                        "--box-shadow": "0px 0px 250px 0px rgba(0, 0, 0, 0.90)",
                        "--primary": "#0079DD",
                        "--text-alternate": "#FFFFFF",
                        "--text-primary": "#E2E2E3",
                        "--text-secondary": "#9E9E9E"
                    },
                    ".grid-stack > .grid-stack-item > .grid-stack-item-content": {
                        overflow: "visible"
                    },
                    ".Toast-bodyClassName": {
                        backgroundColor: theme.palette.background.alternate
                    },
                    ".Toast-progressClassName": {
                        background: theme.palette.toast.info
                    },
                    ".Toast-progressClassName-error": {
                        background: theme.palette.toast.error
                    },
                    ".Toast-progressClassName-info": {
                        background: theme.palette.toast.info
                    },
                    ".Toast-progressClassName-success": {
                        background: theme.palette.toast.success
                    },
                    ".Toast-progressClassName-warning": {
                        background: theme.palette.toast.warning
                    },
                    ".Toast-toastClassName": {
                        backgroundColor: theme.palette.background.alternate,
                        border: `1px solid ${theme.palette.borders.primary}`,
                        boxShadow: theme.shadows[Shadows.Tooltip]
                    },
                    ".Toastify__close-button > svg": {
                        color: theme.palette.text.primary
                    },
                    ".Toastify__progress-bar": {
                        opacity: "inherit"
                    },
                    ".Toastify__progress-bar--bg": {
                        background: "transparent"
                    },
                    ".Toastify__toast-container": {
                        "--toastify-color-light": theme.palette.background.alternate
                    },
                    "--bg-categories": "#E0F2FF",
                    "--bg-primary": "#FFFFFF",
                    "--border": "#E0E0E0",
                    "--box-shadow": "0px 0px 50px 0px rgba(19, 49, 125, 0.15)",
                    "--primary": "#0079DD",
                    "--text-alternate": "#FFFFFF",
                    "--text-primary": "#37474F",
                    "--text-secondary": "#9E9E9E",
                    "--toastify-toast-top": theme.spacing(7),
                    fontFeatureSettings: "'calt' 0",
                    height: "100%",
                    margin: 0,
                    overflow: "hidden"
                },
                html: {
                    backgroundColor: theme.palette.background.default,
                    color: theme.palette.text.primary,
                    fontFamily: "Inter",
                    fontSize: 12,
                    height: "100%"
                },
                "input::-ms-reveal, input::-ms-clear": {
                    display: "none"
                },
                textarea: {
                    background: "transparent",
                    color: theme.palette.text.primary,
                    cursor: "auto"
                }
            }),
            []);

    return (
        <StyledEngineProvider injectFirst={true}>
            <ThemeProvider theme={theme}>
                <GlobalStyles styles={globalStylesTheme}/>
                {children}
            </ThemeProvider>
        </StyledEngineProvider>);
}

export enum Shadows {
    Avatar = 10,
    Card = 6,
    FloutItem = 7,
    GraphDot = 15,
    Highlight = 11,
    SelectionActions = 14,
    SideNavigation = 3,
    TableLeftEdge = 12,
    TableRightEdge = 13,
    Tooltip = 8
}

declare module "@mui/material" {
    interface TypeBackground {
        alternate: string;
    }

    interface Breakpoints {
        downOrEqual: (breakpoint: Breakpoint) => string;
        get: (breakpoint: Breakpoint) => number;
    }

    interface ButtonPropsColorOverrides {
        grey: true;
    }

    interface DividerPropsVariantOverrides {
        text: true;
    }

    interface Color {
        dark: string;
        main: string;
    }

    interface LinkPalette {
        hover: string;
        primary: string;
        secondary: string;
    }

    interface Palette {
        attribute: AttributePalette;
        avatar: AvatarPalette;
        borders: BorderColorPalette;
        chart: ChartPalette;
        data: DataPalette;
        input: InputPalette;
        link: LinkPalette;
        logo: string;
        risk: RiskPalette;
        scrollbar: string;
        severity: SeverityPalette;
        sidebar: SidebarPalette;
        table: TablePalette;
        textEditor: TextEditorPalette;
        toast: ToastPalette;
        udm: UdmPalette;
        workload: WorkloadAnalysisPalette;
    }

    interface SidebarPalette {
        hover: string;
        selected: string;
    }

    interface PaletteOptions {
        attribute: AttributePalette;
        avatar: AvatarPalette;
        borders: BorderColorPalette;
        chart?: ChartPalette;
        data: DataPalette;
        input: InputPalette;
        link: LinkPalette;
        logo: string;
        risk: RiskPalette;
        scrollbar: string;
        severity: SeverityPalette;
        sidebar: SidebarPalette;
        table: TablePalette;
        textEditor: TextEditorPalette;
        toast: ToastPalette;
        udm: UdmPalette;
        workload: WorkloadAnalysisPalette;
    }

    interface Theme {
        border: BorderPalette;
        dark: boolean;
        important: (value: number | string) => string;
        px: (value: number | string) => string;
        setType: Action1<ThemeType>;
        type: ThemeType;
    }

    interface ThemeOptions {
        border?: BorderPalette;
        important?: (value: string | number) => string;
    }

    interface TypeAction {
        alternate?: string;
        alternateHover?: string;
    }

    interface TypeText {
        hint: string;
    }
}