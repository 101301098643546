﻿import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useAwsResourceSnapshotTypeTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useAwsResourceSnapshotTypeTranslator",
            () => ({
                [Contract.TypeNames.AwsResourceSnapshotType]: {
                    [Contract.AwsResourceSnapshotType.Automatic]: "Automatic",
                    [Contract.AwsResourceSnapshotType.AwsBackup]: "Aws Backup",
                    [Contract.AwsResourceSnapshotType.Manual]: "Manual"
                }
            }));
    return (snapshotType: Contract.AwsResourceSnapshotType) =>
        localization[Contract.TypeNames.AwsResourceSnapshotType][snapshotType]();
}