import { UnexpectedError } from "@infrastructure";
import _ from "lodash";
import { useMemo } from "react";
import { RiskPoliciesView, RiskPolicyItem, ScopeHelper, scopeNodeModelStore, TenantHelper, tenantModelStore } from "../../../../../../../../../../common";
import { RiskPolicyInfoItems } from "../../useRiskPolicyInfoItems";
import { useCloudRiskPolicyBuiltInInfoItems, useCloudRiskPolicyCustomInfoItems } from "./hooks";

export function useCloudRiskPolicyInfoItems(scopeId: string, item: RiskPolicyItem, policyView: RiskPoliciesView): RiskPolicyInfoItems {
    const scopeNodeModel = scopeNodeModelStore.useGet(scopeId);
    const scopeNodeMap = scopeNodeModelStore.useGetActiveScopeNodeMap(TenantHelper.CloudRiskPoliciesTenantTypes);
    const scopeNode = scopeNodeMap[scopeNodeModel.configuration.id];
    const filteredActiveTenantModels = tenantModelStore.useGetFilteredActiveTenants();

    const tenantIds =
        useMemo(
            () => {
                const scopeTenantType = ScopeHelper.getTenantType(scopeNodeModel);
                if (_.isNil(scopeTenantType) || item.custom) {
                    return undefined;
                }

                return _(filteredActiveTenantModels).
                    filter(filteredActiveTenantModel => filteredActiveTenantModel.tenantType === scopeTenantType).
                    map(filteredActiveTenantModel => filteredActiveTenantModel.configuration.id).
                    intersection(scopeNode.tenantIds).
                    value();
            },
            [filteredActiveTenantModels, scopeNodeModel]);

    const useCloudRiskPolicyInfoItems =
        useMemo(
            () => {
                switch (policyView) {
                    case RiskPoliciesView.BuiltIn:
                        return useCloudRiskPolicyBuiltInInfoItems;
                    case RiskPoliciesView.Custom:
                        return useCloudRiskPolicyCustomInfoItems;
                    default:
                        throw new UnexpectedError("useCloudRiskPolicyInfoItems.policyView", policyView);
                }
            },
            [policyView]);

    return useCloudRiskPolicyInfoItems(scopeId, item, tenantIds);
}