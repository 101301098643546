import _ from "lodash";
import React from "react";
import { scopeSystemEntityModelStore } from "../../../../../../common";
import { Onboarding, Scans } from "./components";

export function Code() {
    const codeIntegrationConfigurationModels = scopeSystemEntityModelStore.useGetCodeIntegration();

    return (
        _.isEmpty(codeIntegrationConfigurationModels)
            ? <Onboarding/>
            : <Scans/>);
}