import { useLocalization } from "@infrastructure";
import { Grid, Typography } from "@mui/material";
import _ from "lodash";
import React, { Fragment } from "react";
import { Contract, useTheme } from "../../../../../../../common";

type PolicyTitleProps = {
    complianceSectionData: Contract.ComplianceSectionData;
    complianceSectionDescription?: string;
    complianceSectionTitle: string;
};

export function PolicyTitle({ complianceSectionData, complianceSectionDescription, complianceSectionTitle }: PolicyTitleProps) {
    const localization =
        useLocalization(
            "views.system.exportReport.complianceReport.policyTitle",
            () => ({
                empty: "There are no policies related to this section",
                excludedCount: "Excluded",
                policy: "Policy",
                result: "Summary",
                unsecuredAndAnalyzedCount: "Failed/Assessed"
            }));
    const theme = useTheme();
    return (
        <Grid container={true}>
            <Grid
                item={true}
                style={{ paddingBottom: theme.spacing(2.5) }}>
                <Typography
                    style={{
                        fontSize: "18px",
                        fontWeight: 600
                    }}>
                    {complianceSectionTitle}
                </Typography>
                <Typography style={{ whiteSpace: "pre-wrap" }}>
                    {complianceSectionDescription}
                </Typography>
            </Grid>
            <Grid
                alignItems="center"
                container={true}
                justifyContent="space-between"
                style={{
                    backgroundColor: theme.palette.background.paper,
                    border: theme.border.primary,
                    borderBottom: theme.border.primary,
                    borderRadius: theme.spacing(0.75, 0.75, 0, 0),
                    height: theme.spacing(5),
                    padding: theme.spacing(0, 1),
                    width: "100%"
                }}>
                {_.some(complianceSectionData.riskPolicyDatas)
                    ? <Fragment>
                        <Grid
                            item={true}
                            xs={5}>
                            <Typography variant="h5">
                                {localization.policy()}
                            </Typography>
                        </Grid>
                        <Grid
                            item={true}
                            xs={3}>
                            <Typography variant="h5">
                                {localization.unsecuredAndAnalyzedCount()}
                            </Typography>
                        </Grid>
                        <Grid
                            item={true}
                            xs={2}>
                            <Typography variant="h5">
                                {localization.excludedCount()}
                            </Typography>
                        </Grid>
                        <Grid
                            item={true}
                            xs={2}>
                            <Typography variant="h5">
                                {localization.result()}
                            </Typography>
                        </Grid>
                    </Fragment>
                    : <Grid
                        item={true}
                        xs={true}>
                        <Typography
                            style={{ textAlign: "center" }}
                            variant="h5">
                            {localization.empty()}
                        </Typography>
                    </Grid>}
            </Grid>
        </Grid>);
}