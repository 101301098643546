import { useMemo } from "react";
import { useOktaDefaultDirectoryEntityDefinition, useOktaDirectoryGroupDefinition, useOktaDirectoryUserDefinition } from ".";
import { DefinitionOptions } from "../../..";
import { Contract } from "../../../../../../../../../../common";

export function useOktaDirectoryEntityDefinition(entityModel: Contract.EntityModel, options?: DefinitionOptions) {
    const useDefinition =
        useMemo(
            () => {
                switch (entityModel.entity.typeName) {
                    case Contract.TypeNames.OktaDirectoryGroup:
                        return useOktaDirectoryGroupDefinition;
                    case Contract.TypeNames.OktaDirectoryUser:
                        return useOktaDirectoryUserDefinition;
                    default:
                        return useOktaDefaultDirectoryEntityDefinition;
                }
            },
            []);

    return useDefinition(entityModel as Contract.OktaDirectoryEntityModel, options);
}