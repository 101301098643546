import { ArrowDownIcon, ArrowUpIcon, FilterIcon, Message, MessageLevel, Tooltip } from "@infrastructure";
import { Box, Stack, SxProps, Theme, Typography, useTheme } from "@mui/material";
import _ from "lodash";
import React, { isValidElement, memo, ReactNode } from "react";
import { DataTableSortDirection } from "./DataTable";

const ColumnHeaderMemo = memo(ColumnHeader);
export { ColumnHeaderMemo as ColumnHeader };

type ColumnHeaderProps = {
    containerSx?: SxProps;
    disabled?: boolean;
    filterActive?: boolean;
    headerSx?: SxProps<Theme>;
    message?: ReactNode;
    messageLevel?: MessageLevel;
    sortDirection?: DataTableSortDirection;
    title?: string;
    titleElement?: ReactNode;
    tooltip?: string;
};

function ColumnHeader({ containerSx, disabled, filterActive = false, headerSx, message, messageLevel = "info", sortDirection, title, titleElement, tooltip }: ColumnHeaderProps) {
    const theme = useTheme();
    return (
        <Stack
            direction="row"
            spacing={1}
            sx={{
                alignItems: "center",
                padding: theme.spacing(1),
                ...(disabled
                    ? undefined
                    : { color: theme.palette.text.primary }),
                ...containerSx
            }}>
            {_.isNil(title) && _.isNil(titleElement)
                ? undefined
                : <Tooltip titleOrGetTitle={tooltip}>
                    {isValidElement(titleElement)
                        ? titleElement
                        : <Typography
                            noWrap={true}
                            sx={{
                                fontWeight: 500,
                                ...headerSx
                            }}>
                            {title}
                        </Typography>}
                </Tooltip>}
            {!_.isNil(message) &&
                <Box sx={{ marginLeft: theme.spacing(1) }}>
                    <Message
                        level={messageLevel}
                        title={message}
                        variant="minimal"/>
                </Box>}
            {sortDirection === DataTableSortDirection.Descending &&
                <ArrowDownIcon sx={{ fontSize: "16px" }}/>}
            {sortDirection === DataTableSortDirection.Ascending &&
                <ArrowUpIcon sx={{ fontSize: "16px" }}/>}
            {filterActive && <FilterIcon sx={{ fontSize: "16px" }}/>}
        </Stack>);
}