import { useLocalization } from "@infrastructure";
import { Divider, Stack } from "@mui/material";
import React from "react";
import { Contract, SummarySectionCard, useTheme } from "../../../../../../../../../../common";
import { ScoreSummary, TopPolicies } from "./components";

type SummaryProps = {
    complianceData: Contract.ComplianceSectionData;
};

export function Summary({ complianceData }: SummaryProps) {
    const localization =
        useLocalization(
            "views.customer.compliance.profile.item.summary",
            () => ({
                overall: "Score",
                policies: "Top Findings"
            }));

    const theme = useTheme();
    return (
        <Stack
            direction="row"
            divider={
                <Divider
                    flexItem={true}
                    orientation="vertical"
                    style={{ borderColor: theme.palette.borders.primary }}/>
            }
            spacing={2}
            sx={{
                border: theme.border.primary,
                borderRadius: theme.spacing(0.75),
                height: theme.spacing(23),
                padding: theme.spacing(1.5, 0, 1.5, 3)
            }}>
            <SummarySectionCard
                rowGap={3}
                sx={{ flex: 1 }}
                title={localization.overall()}>
                <ScoreSummary complianceData={complianceData}/>
            </SummarySectionCard>
            <SummarySectionCard
                childrenSx={{ overflow: "hidden" }}
                rowGap={1}
                sx={{
                    flex: 1,
                    maxHeight: "100%",
                    overflow: "hidden"
                }}
                title={localization.policies()}
                titleSx={{ marginLeft: theme.spacing(1) }}>
                <TopPolicies/>
            </SummarySectionCard>
        </Stack>
    );
}