﻿import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function ChileIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <g clipPath="url(#clip0_60203_5270)">
                <rect
                    fill="white"
                    height="15.9638"
                    rx="2"
                    width="24"
                    y="4"/>
                <path
                    d="M0 4.0011H24V19.9649H0V4.0011Z"
                    fill="white"/>
                <path
                    d="M0 11.983V4.0011H8V15.9739L0 11.983Z"
                    fill="#0039A6"/>
                <path
                    d="M0 11.983H24V19.9649H0V11.983Z"
                    fill="#D72B1F"/>
                <path
                    d="M3.99811 5.90155L4.43698 7.25225H5.85719L4.70821 8.08703L5.14708 9.43773L3.99811 8.60295L2.84913 9.43773L3.288 8.08703L2.13903 7.25225H3.55924L3.99811 5.90155Z"
                    fill="white"/>
                <path
                    clipRule="evenodd"
                    d="M21.5 5H8V4H21.5C22.8807 4 24 5.11929 24 6.5V12H23V6.5C23 5.67157 22.3284 5 21.5 5Z"
                    fill="#BAC5D3"
                    fillRule="evenodd"/>
            </g>
            <defs>
                <clipPath id="clip0_60203_5270">
                    <rect
                        fill="white"
                        height="15.9638"
                        rx="2"
                        width="24"
                        y="4"/>
                </clipPath>
            </defs>
        </SvgIcon>);
}