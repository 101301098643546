import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useVirtualMachineImageAccessLevelTranslator(){
    const localization =
        useLocalization(
            "views.customer.workloadAnalysis.hooks.useVirtualMachineImageAccessLevelTranslator",
            () => ({
                [Contract.VirtualMachineImageAccessLevel.Public]: "Public",
                [Contract.VirtualMachineImageAccessLevel.Internal]: "Internal",
                [Contract.VirtualMachineImageAccessLevel.CrossTenant]: "Cross Tenant"
            }));
    return (virtualMachineImageAccessLevel:Contract.VirtualMachineImageAccessLevel)=> localization[virtualMachineImageAccessLevel]();
}