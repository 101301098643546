import { Sx } from "@infrastructure";
import { Box, Stack, StackProps } from "@mui/material";
import _ from "lodash";
import React, { Children, forwardRef, Ref } from "react";
import { setRef } from "../utilities";

const VerticalFillGridForwardRef = forwardRef(VerticalFillGrid);
export { VerticalFillGridForwardRef as VerticalFillGrid };

function VerticalFillGrid({ children, sx, ...props }: StackProps, ref: Ref<HTMLDivElement>) {
    const childElements = _.filter(Children.toArray(children));
    return (
        <Stack
            sx={
                Sx.combine(
                    {
                        height: "100%",
                        width: "100%"
                    },
                    sx)}
            {...props}>
            {_(childElements).
                take(childElements.length - 1).
                map(
                    (childElement, childElementIndex) =>
                        <Box
                            key={childElementIndex}
                            sx={{ width: "100%" }}>
                            {childElement}
                        </Box>).
                value()}
            <Stack
                ref={stackRef => setRef(ref, stackRef)}
                sx={{
                    flex: 1,
                    overflowY: "hidden"
                }}>
                {childElements[childElements.length - 1]}
            </Stack>
        </Stack>);
}