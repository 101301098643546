﻿import { Collapse, CollapseProps } from "@mui/material";
import React, { ReactNode } from "react";

type ExpandProps = Omit<CollapseProps, "children" | "in"> & {
    children: ReactNode;
    expanded: boolean;
};

export function Expand({ children, expanded, ...props }: ExpandProps) {
    return (
        <Collapse
            in={expanded}
            unmountOnExit={true}
            {...props}>
            {children}
        </Collapse>);
}