import { useLocalization, useLocalizeList } from "@infrastructure";
import _ from "lodash";
import { AuditEventDefinition, AuditEventDefinitionContext, AuditEventDefinitionDetailItem } from "..";
import { Contract, UrlHelper } from "../../../../../../../../../common";

export function useWebhookEndpointAuditEventDefinition(context: AuditEventDefinitionContext) {
    const localizeList = useLocalizeList();
    const localization =
        useLocalization(
            "views.customer.auditEvents.profile.hooks.useDefinition.hooks.useWebhookEndpointAuditEventDefinition",
            () => ({
                propertyChanges: "Updated properties",
                webhookEndpointHttpHeaders: "HTTP headers",
                webhookEndpointName: "Name",
                webhookEndpointServerCertificateValidationEnabled: {
                    false: "Yes",
                    title: "Accept any server SSL certificates",
                    true: "No"
                },
                webhookEndpointUrl: "Endpoint URL"
            }));

    const webhookEndpointAuditEvent = context.auditEventModel.auditEvent as Contract.WebhookEndpointAuditEvent;
    return new AuditEventDefinition(
        _<AuditEventDefinitionDetailItem>([]).
            concat(
                new AuditEventDefinitionDetailItem(
                    localization.webhookEndpointUrl(),
                    webhookEndpointAuditEvent.webhookEndpointUrl),
                new AuditEventDefinitionDetailItem(
                    localization.webhookEndpointName(),
                    webhookEndpointAuditEvent.webhookEndpointName)).
            concatIf(
                UrlHelper.getUrlData(webhookEndpointAuditEvent.webhookEndpointUrl)?.protocolType === "https",
                new AuditEventDefinitionDetailItem(
                    localization.webhookEndpointServerCertificateValidationEnabled.title(),
                    webhookEndpointAuditEvent.webhookEndpointServerCertificateValidationEnabled
                        ? localization.webhookEndpointServerCertificateValidationEnabled.true()
                        : localization.webhookEndpointServerCertificateValidationEnabled.false())
            ).
            concatIf(
                webhookEndpointAuditEvent.typeName === Contract.TypeNames.WebhookEndpointUpdateAuditEvent,
                () => {
                    const properties =
                        _.filter([
                            (webhookEndpointAuditEvent as Contract.WebhookEndpointUpdateAuditEvent).webhookEndpointNameChanged
                                ? localization.webhookEndpointName()
                                : undefined,
                            (webhookEndpointAuditEvent as Contract.WebhookEndpointUpdateAuditEvent).webhookEndpointServerCertificateValidationEnabledChanged
                                ? localization.webhookEndpointServerCertificateValidationEnabled.title()
                                : undefined,
                            (webhookEndpointAuditEvent as Contract.WebhookEndpointUpdateAuditEvent).webhookEndpointHttpHeadersChanged
                                ? localization.webhookEndpointHttpHeaders()
                                : undefined
                        ]);
                    return (
                        _<AuditEventDefinitionDetailItem>([]).
                            concatIf(
                                !_.isEmpty(properties),
                                new AuditEventDefinitionDetailItem(
                                    localization.propertyChanges(),
                                    localizeList(properties)!)).
                            value());
                }).
            value());
}