import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { EntityExternalConsoleLink } from "../../components";
import { useGetAzureSqlServerRiskContext } from "../contexts";

export function useAzureSqlServerAadAuthenticationAdministratorPrincipalNotExistsRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AzureSqlServerAadAuthenticationAdministratorPrincipalNotExistsRisk;
    const serverModel = entityModelStore.useGet(risk.entityId) as Contract.AzureSqlServerModel;

    const getAzureSqlServerRiskContext = useGetAzureSqlServerRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.compliance.useAzureSqlServerAadAuthenticationAdministratorPrincipalNotExistsRiskDefinition",
            () => ({
                description: "{{server}} Microsoft Entra ID admin is not set",
                sections: {
                    resolution: {
                        step1: "Click **Set Admin**",
                        step2: "Choose a user to be the admin, and then click **Select**"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            server:
                <Entity
                    entityIdOrModel={serverModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={risk.entityId}
                key={risk.entityId}
                page={Contract.AzureConsoleEntityPage.ActiveDirectoryAdmin}/>,
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2()
        ],
        riskModel,
        () => {
            const serverRiskContext = getAzureSqlServerRiskContext(serverModel);
            return [
                serverRiskContext.generalInformation,
                serverRiskContext.sensitive,
                serverRiskContext.status,
                serverRiskContext.storageSize,
                serverRiskContext.inboundExternalAccessScope,
                serverRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}