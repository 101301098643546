import { Link, useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import React from "react";
import { CustomerConsoleAppUrlHelper, SupportButton, useLayoutOptions, useTheme } from "../../../../../common";

export function Onboarding() {
    const localization =
        useLocalization(
            "views.customer.workloadAnalysis.onboarding",
            () => ({
                content: {
                    firstParagraph: "Your current license does not include Tenable Cloud Security Workload Protection.",
                    links: {
                        documentation: "documentation"
                    },
                    secondParagraph: "Workload Protection helps you identify security weaknesses and flaws in your cloud workloads, in order to protect your organization from breaches and the exposure of sensitive data.",
                    thirdParagraph: "For more information, contact the Tenable Cloud Security team.\nTo learn more about this module, see our {{documentationLink}}."
                },
                title: "**Welcome to Tenable Cloud Security Workload Protection!**",
                viewTitle: "Workload Protection"
            }));
    useLayoutOptions({ view: { title: localization.viewTitle() } });

    const theme = useTheme();
    return (
        <Stack
            direction="row"
            sx={{
                height: "100%",
                position: "relative",
                width: "100%"
            }}>
            <Stack
                alignItems="flex-start"
                spacing={3}
                sx={{
                    padding: theme.spacing(7),
                    width: "100%"
                }}>
                <Typography variant="h1">
                    {localization.title()}
                </Typography>
                <Typography sx={{ fontSize: "14px" }}>
                    {localization.content.firstParagraph()}
                </Typography>
                <Typography sx={{ fontSize: "14px" }}>
                    {localization.content.secondParagraph()}
                </Typography>
                <Typography
                    sx={{
                        fontSize: "14px",
                        whiteSpace: "pre-wrap"
                    }}>
                    {localization.content.thirdParagraph({
                        documentationLink:
                            <Link
                                urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsRelativeUrl()}
                                variant="external">
                                {localization.content.links.documentation()}
                            </Link>
                    })}
                </Typography>
                <SupportButton/>
            </Stack>
        </Stack>);
}