﻿import { IconText, NotValidIcon, Tooltip, useLocalization, useLocalizeList } from "@infrastructure";
import { Box, Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, RiskPoliciesType, useRiskPolicyTranslator, useTheme } from "../../../../../../../../../../../../common";
import { useKubernetesAdmissionControllerResourceOperationTranslator } from "../../../../../../../../../Kubernetes/hooks";
import { useGetInheritedOverridableValue } from "../../../../../../../../hooks";
import { useConfigurationContext } from "../../../../../../Configuration";

export function Warning() {
    const { item, riskPolicyConfiguration } = useConfigurationContext();
    const { inherited, inheritedValue: inheritedEffect, value: effect } =
        useGetInheritedOverridableValue(
            riskPolicyConfiguration => (riskPolicyConfiguration as Contract.BuiltInKubernetesAdmissionControllerRiskPolicyConfiguration).effect,
            RiskPoliciesType.KubernetesAdmissionController,
            riskPolicyConfiguration,
            item);
    const effectValue =
        inherited
            ? inheritedEffect!
            : effect!;
    const kubernetesAdmissionControllerRiskPolicyConfiguration = riskPolicyConfiguration as Contract.BuiltInKubernetesAdmissionControllerRiskPolicyConfiguration;

    const localizeList = useLocalizeList();
    const operationTranslator = useKubernetesAdmissionControllerResourceOperationTranslator();
    const riskPolicyTranslator = useRiskPolicyTranslator();
    const localization =
        useLocalization(
            "views.customer.riskPolicies.configuration.hooks.useDefinition.hooks.kubernetesAdmissionController.hooks.warning",
            () => ({
                description: {
                    tooltip: "*underline*this policy**",
                    withoutResourceTypes: "Enabling {{tooltip}} will {{translatedEffect}} violated {{translatedResourceOperations}} actions.",
                    withResourceTypes: "Enabling {{tooltip}} will {{translatedEffect}} violated {{translatedResourceOperations}} actions on {{translatedResourceTypes}}."
                },
                [Contract.TypeNames.KubernetesAdmissionControllerRiskPolicyEffect]: {
                    [Contract.KubernetesAdmissionControllerRiskPolicyEffect.Alert]: "alert you on",
                    [Contract.KubernetesAdmissionControllerRiskPolicyEffect.Deny]: "deny"
                }
            }));

    const descriptionProps =
        useMemo(
            () => ({
                tooltip:
                    <Tooltip titleOrGetTitle={riskPolicyTranslator(kubernetesAdmissionControllerRiskPolicyConfiguration.typeName).description}>
                        <Typography sx={{ display: "inline-block" }}>
                            {localization.description.tooltip()}
                        </Typography>
                    </Tooltip>,
                translatedEffect: localization[Contract.TypeNames.KubernetesAdmissionControllerRiskPolicyEffect][effectValue](),
                translatedResourceOperations:
                    localizeList(
                        _.map(
                            kubernetesAdmissionControllerRiskPolicyConfiguration.resourceOperations,
                            resourceOperation => operationTranslator(resourceOperation).toLowerCase()))
            }),
            [effectValue, kubernetesAdmissionControllerRiskPolicyConfiguration]);

    const theme = useTheme();
    return (
        <Box
            sx={{
                backgroundColor: theme.palette.input.filledBackground,
                borderRadius: 2,
                padding: theme.spacing(1.5)
            }}>
            <IconText
                icon={<NotValidIcon/>}
                iconSx={{
                    color: theme.palette.warning.main,
                    fontSize: "20px"
                }}
                text={
                    <Typography>
                        {_.isEmpty(kubernetesAdmissionControllerRiskPolicyConfiguration.rawResourcePluralTypeNames)
                            ? localization.description.withoutResourceTypes(descriptionProps)
                            : localization.description.withResourceTypes({
                                ...descriptionProps,
                                translatedResourceTypes: localizeList(kubernetesAdmissionControllerRiskPolicyConfiguration.rawResourcePluralTypeNames)
                            })}
                    </Typography>}/>
        </Box>);
}