import { useMemo } from "react";
import { Contract } from "../../../../../../../../../../../../common";
import { useGetOciIamCredentialRiskContext } from "./useGetOciIamCredentialRiskContext";

export function useGetOciIamUserAuthTokenRiskContext() {
    return useMemo(
        () => useOciIamUserAuthTokenRiskContext,
        []);
}

function useOciIamUserAuthTokenRiskContext(UserAuthTokenModel: Contract.OciIamUserAuthTokenModel) {
    return useGetOciIamCredentialRiskContext()(UserAuthTokenModel);
}