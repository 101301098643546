import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useAzureWebApplicationTypeTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useAzureWebApplicationTypeTranslator",
            () => ({
                [Contract.TypeNames.AzureWebApplicationType]: {
                    [Contract.AzureWebApplicationType.Api]: "API App",
                    [Contract.AzureWebApplicationType.Function]: "Function App",
                    [Contract.AzureWebApplicationType.Logic]: "Logic App (Standard)",
                    [Contract.AzureWebApplicationType.Mobile]: "Mobile App",
                    [Contract.AzureWebApplicationType.Web]: "Web App"
                }
            }));
    return (type: Contract.AzureWebApplicationType) =>
        localization[Contract.TypeNames.AzureWebApplicationType][type]();
}