﻿import { useLocalization } from "@infrastructure";
import { Contract } from "../../../common";

export function useSystemKubernetesClusterAdmissionControllerStatusTooltipTranslator() {
    const localization =
        useLocalization(
            "tenants.kubernetes.hooks.useSystemKubernetesClusterAdmissionControllerStatusTooltipTranslator",
            () => ({
                [Contract.TypeNames.SystemKubernetesClusterModelHelmComponentStatus]: {
                    [Contract.SystemKubernetesClusterModelHelmComponentStatus.NotInstalled]: "Tenable Cloud Security Admission Controller is not installed.",
                    [Contract.SystemKubernetesClusterModelHelmComponentStatus.NotRunning]: "Tenable Cloud Security Admission Controller ran into issues.",
                    [Contract.SystemKubernetesClusterModelHelmComponentStatus.Running]: "Tenable Cloud Security Admission Controller is deployed."
                }
            }));
    return (status: Contract.SystemKubernetesClusterModelHelmComponentStatus) =>
        localization[Contract.TypeNames.SystemKubernetesClusterModelHelmComponentStatus][status]();
}