import { TextSnippet, TextSnippetItem, useLocalization } from "@infrastructure";
import { Box } from "@mui/material";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../..";
import { Contract, Entity, entityModelStore, useTheme } from "../../../../../../../../../../../../common";
import { useGetAwsIamServerCertificateRiskContext } from "../contexts";

export function useAwsIamServerCertificateExpiredRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsIamServerCertificateExpiredRisk;
    const serverCertificateModel = entityModelStore.useGet(risk.entityId) as Contract.AwsIamServerCertificateModel;

    const getAwsIamServerCertificateRiskContext = useGetAwsIamServerCertificateRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsIamServerCertificateExpiredRiskDefinition",
            () => ({
                description: "{{serverCertificate}} is expired",
                sections: {
                    resolution: {
                        step1: "Removing expired certificates via AWS management console is not currently supported. To delete expired SSL/TLS certificates stored in IAM via the AWS API use the Command Line Interface (CLI)",
                        step2: {
                            command: "aws iam delete-server-certificate --server-certificate-name {{serverCertificateName}}",
                            title: "Run the following command {{command}}"
                        }
                    }
                }
            }));

    const theme = useTheme();
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            serverCertificate:
                <Entity
                    entityIdOrModel={serverCertificateModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2.title({
                command:
                    <Box sx={{ maxWidth: theme.spacing(80) }}>
                        <TextSnippet
                            height={theme.spacing(3)}
                            items={[
                                new TextSnippetItem(
                                    localization.sections.resolution.step2.command({
                                        serverCertificateName: (serverCertificateModel.entity as Contract.AwsIamServerCertificate).name!
                                    }),
                                    "")
                            ]}
                            wordWrap={false}/>
                    </Box>
            })
        ],
        riskModel,
        () => {
            const serverCertificateRiskContext = getAwsIamServerCertificateRiskContext(serverCertificateModel);
            return [
                serverCertificateRiskContext.generalInformation,
                serverCertificateRiskContext.creationTime,
                serverCertificateRiskContext.expirationTime,
                serverCertificateRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}