﻿import React from "react";

export function UkIcon() {
    return (
        <svg
            fill="none"
            height={50}
            viewBox="0 0 50 50"
            width={50}>
            <path
                d="M0.746166 18.8261C4.03152 5.54563 17.4488 -2.54306 30.7281 0.742577C44.0075 4.02822 52.0954 17.4467 48.8101 30.7272C45.5247 43.9952 32.1075 52.0964 18.8281 48.8107C5.54881 45.5251 -2.55172 32.0941 0.746166 18.8261Z"
                fill="#24356B"/>
            <path
                d="M42.327 7.3145L32.3706 13.9485V1.20721C31.8314 1.03164 31.2797 0.868611 30.7154 0.730664C26.0507 -0.423072 21.3609 -0.172264 17.0975 1.21974V13.9485L7.17875 7.33957C4.9091 9.62196 3.06578 12.3809 1.81183 15.5035L4.43259 17.2592H1.17232C1.00931 17.7734 0.858834 18.3001 0.733438 18.8268C-0.432735 23.5295 -0.169409 28.2573 1.24756 32.5337H4.49529L1.89962 34.2643C3.15357 37.2866 5.00942 40.058 7.3543 42.3655L17.0975 35.8695V48.2972C17.6618 48.4853 18.2386 48.6484 18.8154 48.7863C23.4676 49.94 28.1323 49.6892 32.3706 48.3223V35.8695L42.1514 42.3906C44.4462 40.1333 46.3271 37.3994 47.6186 34.2894L44.9728 32.5211H48.2832C48.4839 31.9317 48.6594 31.3172 48.8099 30.7027C49.951 26.0878 49.7127 21.4603 48.371 17.2341H45.0481L47.7315 15.4533C46.4776 12.4311 44.6468 9.64705 42.327 7.3145Z"
                fill="#F5F1EE"/>
            <path
                d="M13.2855 17.2591L4.09406 11.1393C3.47962 12.0673 2.92787 13.0454 2.43883 14.0612L7.24147 17.2591H13.2855ZM45.3992 11.0891C44.7847 10.1611 44.0951 9.2707 43.3552 8.41794L32.3706 15.7291V17.2465H36.1325L45.3992 11.0891ZM4.16929 38.4903C4.78373 39.4183 5.47339 40.3087 6.21322 41.1489L17.0975 33.913V32.5335H13.1225L4.16929 38.4903ZM49.1485 20.4319H29.1981V0.404489C26.1761 -0.147298 23.1666 -0.122216 20.2826 0.404489V20.4193H0.394903C-0.144296 23.4416 -0.106687 26.4513 0.432512 29.3357H20.2826V49.0997C23.3046 49.664 26.3141 49.6389 29.1981 49.1248V29.3357H49.0983C49.6751 26.3259 49.6626 23.3162 49.1485 20.4319ZM36.3582 32.5335L45.3616 38.528C45.976 37.5999 46.5403 36.6343 47.0293 35.6185L42.4022 32.5335H36.3582Z"
                fill="#E52A18"/>
            <path
                d="M32.3831 3.79035V5.39556C32.4835 5.09458 32.5336 4.80614 32.5336 4.53025C32.5211 4.2669 32.4835 4.01608 32.3831 3.79035Z"
                fill="#333A63"/>
        </svg>);
}