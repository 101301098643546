import { useLocalization } from "@infrastructure";
import React from "react";
import { RiskPoliciesType, useLayoutOptions } from "../../../../../../common";
import { RiskPoliciesView } from "../../../RiskPolicies";

export function RiskPolicies() {
    const localization =
        useLocalization(
            "views.customer.code.riskPolicies",
            () => ({
                title: "IaC Policies"
            }));
    useLayoutOptions({ view: { title: localization.title() } });

    return <RiskPoliciesView type={RiskPoliciesType.Code}/>;
}