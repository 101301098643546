import { UnexpectedError } from "@infrastructure";
import { useMemo } from "react";
import { Contract, entityModelStore } from "../../../../../../../../../../../../../../../common";
import { RiskDefinition } from "../../../../../../../../../utilities";
import { useGcpPrincipalServiceAccountWideScopeImpersonateServiceAccountActionExistsRiskGciDirectoryGroupDefinition, useGcpPrincipalServiceAccountWideScopeImpersonateServiceAccountActionExistsRiskPartialServiceAccountDefinition, useGcpPrincipalServiceAccountWideScopeImpersonateServiceAccountActionExistsRiskServiceAccountDefinition } from "./hooks";

export function useGcpPrincipalServiceAccountWideScopeImpersonateServiceAccountActionExistsRiskDefinition(riskModel: Contract.GcpPrincipalWideScopeImpersonateServiceAccountActionExistsRiskModel): RiskDefinition {
    const principalModel = entityModelStore.useGet((riskModel.risk as Contract.GcpPrincipalServiceAccountWideScopeImpersonateServiceAccountActionExistsRisk).entityId);
    const useDefinition =
        useMemo(
            () => {
                switch (principalModel.entity.typeName) {
                    case Contract.TypeNames.GciDirectoryGroup:
                        return useGcpPrincipalServiceAccountWideScopeImpersonateServiceAccountActionExistsRiskGciDirectoryGroupDefinition;
                    case Contract.TypeNames.GcpIamPartialServiceAccount:
                        return useGcpPrincipalServiceAccountWideScopeImpersonateServiceAccountActionExistsRiskPartialServiceAccountDefinition;
                    case Contract.TypeNames.GcpIamServiceAccount:
                        return useGcpPrincipalServiceAccountWideScopeImpersonateServiceAccountActionExistsRiskServiceAccountDefinition;
                    default:
                        throw new UnexpectedError("principalModel.entity.typeName", principalModel.entity.typeName);
                }
            },
            []);

    return useDefinition(riskModel as Contract.GcpPrincipalServiceAccountWideScopeImpersonateServiceAccountActionExistsRiskModel);
}