import { useLocalization } from "@infrastructure";
import React from "react";
import { useGetAwsRdsDatabaseInstanceRiskContext } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";

export function useAwsRdsDatabaseInstanceDeleteProtectionDisabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsRdsDatabaseInstanceDeleteProtectionDisabledRisk;
    const databaseInstanceModel = entityModelStore.useGet(risk.entityId) as Contract.AwsRdsDatabaseInstanceModel;
    const databaseInstance = databaseInstanceModel.entity as Contract.AwsRdsDatabaseInstance;

    const getAwsRdsDatabaseInstanceRiskContext = useGetAwsRdsDatabaseInstanceRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsRdsDatabaseInstanceDeleteProtectionDisabledRiskDefinition",
            () => ({
                description: "{{databaseInstance}} delete protection is not enabled",
                sections: {
                    resolution: {
                        step1: "In the top right corner, click **Modify**",
                        step2: "Scroll down to the **Additional configuration** section and select the **Enable deletion protection** checkbox",
                        step3: "Continue and save changes"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            databaseInstance:
                <Entity
                    entityIdOrModel={databaseInstanceModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.Rds,
                AwsConsoleUrlBuilder.getRdsDatabaseInstanceUrl(databaseInstance)),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3()
        ],
        riskModel,
        () => {
            const databaseInstanceContextItems = getAwsRdsDatabaseInstanceRiskContext(databaseInstanceModel);
            return [
                databaseInstanceContextItems.generalInformation,
                databaseInstanceContextItems.storageSize,
                databaseInstanceContextItems.sensitive,
                databaseInstanceContextItems.highestDataSensitivity,
                databaseInstanceContextItems.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}