﻿import { ActionMenuItem, CollapsedIcon, DataTable, DataTableActions, DataTableColumn, DataTableSort, DataTableSortDirection, DeleteIcon, EmptyMessageText, Link, map, Menu, Message, NotValidIcon, Optional, StringHelper, SuccessIcon, Tooltip, useChangeEffect, useLocalization } from "@infrastructure";
import { Accordion, AccordionDetails, AccordionSummary, Box, CircularProgress, Divider, Stack, Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { Fragment, ReactNode, useRef, useState } from "react";
import { ConfigurationController, Contract, Scope, scopeSystemEntityModelStore, slackWorkspaceChannelOperationStore, useScopeNavigationViewContext, useTheme } from "../../../../../../../../../../../../../../../../common";
import { useSlackWorkspaceIssueTranslator } from "./hooks";

type WorkspaceProps = {
    oAuthAuthorizationUrl: Optional<string>;
    workspaceModel: Contract.SlackWorkspaceModel;
};

export function Workspace({ oAuthAuthorizationUrl, workspaceModel }: WorkspaceProps) {
    const workspaceIdToChannelRawIdToNameMap = slackWorkspaceChannelOperationStore.useGet();
    const { scopeNodeModel } = useScopeNavigationViewContext();

    const [deleteWorkspaceExecuting, setDeleteWorkspaceExecuting] = useState(false);
    const [deleteWorkspaceError, setDeleteWorkspaceError] = useState(false);

    async function deleteWorkspace() {
        setDeleteWorkspaceExecuting(true);
        setDeleteWorkspaceError(false);

        try {
            await ConfigurationController.deleteSlackWorkspace(new Contract.ConfigurationControllerDeleteSlackWorkspaceRequest(workspaceModel.configuration.id));
            await scopeSystemEntityModelStore.notify();
        } catch {
            setDeleteWorkspaceError(true);
        }

        setDeleteWorkspaceExecuting(false);
    }

    const dataTableActionsRef = useRef<DataTableActions>();
    useChangeEffect(
        () => {
            dataTableActionsRef.current!.reset();
        },
        [workspaceModel]);

    const workspaceIssueTranslator = useSlackWorkspaceIssueTranslator();

    const channelRawIdToNameMap = workspaceIdToChannelRawIdToNameMap[workspaceModel.id];

    const readOnly = scopeNodeModel.configuration.id != workspaceModel.configuration.scopeId;

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCollaborationItems.slack.list.workspace",
            () => ({
                actions: {
                    delete: {
                        error: "Failed to delete",
                        prompt: "Are you sure you want to delete the workspace {{workspaceName}}?",
                        title: "Delete"
                    },
                    update: {
                        title: "Reauthorize",
                        tooltip: "Reauthorize this workspace to enable all Slack-related functionality."
                    }
                },
                columns: {
                    name: "Name"
                },
                empty: "No Channels Found",
                inherited: "Inherited from: {{scope}}",
                summary: {
                    addChannel: "To add Tenable Cloud Security to a channel, type /invite @tenablecloudsecurity",
                    status: "Status: {{workspaceStatusIcon}} {{translatedWorkspaceStatus}}",
                    title: [
                        "1 channel {{addChannel}}",
                        "{{count | NumberFormatter.humanize}} channels {{addChannel}}"
                    ]
                }
            }));

    const theme = useTheme();
    return (
        <Accordion defaultExpanded={!_.isEmpty(channelRawIdToNameMap)}>
            <AccordionSummary expandIcon={<CollapsedIcon/>}>
                <Stack
                    alignItems="center"
                    direction="row"
                    sx={{ width: "100%" }}>
                    <Stack
                        alignItems="center"
                        direction="row"
                        spacing={1}
                        sx={{
                            flex: 1,
                            maxWidth: "100%"
                        }}>
                        <Typography noWrap={true}>
                            {workspaceModel.configuration.name}
                        </Typography>
                        <Divider
                            flexItem={true}
                            orientation="vertical"
                            sx={{ margin: theme.spacing(0, 0.5) }}/>
                        <Typography noWrap={true}>
                            {localization.summary.title(
                                _.size(channelRawIdToNameMap),
                                {
                                    addChannel:
                                        <Box
                                            sx={{
                                                display: "inline-block",
                                                fontSize: "18px",
                                                margin: theme.spacing(-0.5, 0.5)
                                            }}>
                                            <Message
                                                level="info"
                                                title={localization.summary.addChannel()}
                                                variant="minimal"/>
                                        </Box>
                                })}
                        </Typography>
                        <Divider
                            flexItem={true}
                            orientation="vertical"
                            sx={{ margin: theme.spacing(0, 0.5) }}/>
                        <Typography noWrap={true}>
                            {localization.summary.status({
                                translatedWorkspaceStatus: workspaceIssueTranslator(workspaceModel.state.issue),
                                workspaceStatusIcon:
                                    <Box
                                        sx={{
                                            display: "inline-block",
                                            fontSize: "18px",
                                            margin: theme.spacing(-0.5, 0.5)
                                        }}>
                                        {map<Contract.SlackWorkspaceStateIssue, ReactNode>(
                                            workspaceModel.state.issue,
                                            {
                                                [Contract.SlackWorkspaceStateIssue.AuthenticationFailure]: () =>
                                                    <NotValidIcon sx={{ color: theme.palette.error.main }}/>,
                                                [Contract.SlackWorkspaceStateIssue.MandatoryPermissionsNotExist]: () =>
                                                    <NotValidIcon sx={{ color: theme.palette.error.main }}/>,
                                                [Contract.SlackWorkspaceStateIssue.OptionalPermissionsNotExist]: () =>
                                                    <NotValidIcon sx={{ color: theme.palette.warning.main }}/>
                                            },
                                            () => <SuccessIcon sx={{ color: theme.palette.success.main }}/>)}
                                    </Box>
                            })}
                        </Typography>
                        {readOnly &&
                            <Fragment>
                                <Divider
                                    flexItem={true}
                                    orientation="vertical"
                                    sx={{ margin: theme.spacing(0, 0.5) }}/>
                                <Typography noWrap={true}>
                                    {localization.inherited({
                                        scope:
                                            <Scope
                                                scopeId={workspaceModel.configuration.scopeId}
                                                sx={{ color: theme.palette.text.primary }}
                                                variant="text"/>
                                    })}
                                </Typography>
                            </Fragment>}
                    </Stack>
                    {!readOnly &&
                        <Fragment>
                            {workspaceModel.state.issue === Contract.SlackWorkspaceStateIssue.OptionalPermissionsNotExist && (
                                <Stack
                                    alignItems="center"
                                    direction="row"
                                    spacing={1}>
                                    <Tooltip titleOrGetTitle={localization.actions.update.tooltip()}>
                                        <Link
                                            disabled={deleteWorkspaceExecuting}
                                            sx={{
                                                fontWeight: 700,
                                                textDecoration: "underline",
                                                width: "100%"
                                            }}
                                            urlOrGetUrl={oAuthAuthorizationUrl}>
                                            {localization.actions.update.title()}
                                        </Link>
                                    </Tooltip>
                                </Stack>)}
                            <Menu
                                itemsOrGetItems={[
                                    new ActionMenuItem(
                                        () => deleteWorkspace(),
                                        localization.actions.delete.title(),
                                        {
                                            confirmOptions: {
                                                message: localization.actions.delete.prompt({ workspaceName: workspaceModel.configuration.name })
                                            },
                                            disabled: deleteWorkspaceExecuting,
                                            icon: <DeleteIcon/>
                                        })
                                ]}/>
                        </Fragment>}
                    {deleteWorkspaceExecuting &&
                        <CircularProgress
                            size={theme.spacing(3)}
                            variant="indeterminate"/>}
                    {deleteWorkspaceError &&
                        <Message
                            level="error"
                            title={localization.actions.delete.error()}
                            variant="minimal"/>}
                </Stack>
            </AccordionSummary>
            <AccordionDetails>
                <DataTable
                    actionsRef={dataTableActionsRef}
                    emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty()) }}
                    fetchItems={
                        (_filterMap: Dictionary<unknown>, sort: Optional<DataTableSort>) =>
                            _(channelRawIdToNameMap).
                                values().
                                orderBy(
                                    channelName => StringHelper.getSortValue(channelName),
                                    sort?.direction === DataTableSortDirection.Descending
                                        ? "desc"
                                        : "asc").
                                value()}
                    getItemId={(item: string) => item}>
                    <DataTableColumn
                        id={WorkspaceColumnId.Name}
                        itemProperty={(item: string) => item}
                        title={localization.columns.name()}/>
                </DataTable>
            </AccordionDetails>
        </Accordion>);
}

enum WorkspaceColumnId {
    Name = "name"
}