﻿import { Button, Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { useMemo, useRef } from "react";
import { AddIcon, DataTable, DataTableAction, DataTableActions, DataTableColumn, DataTableColumnRenderProps, DataTableFetchItemsResult, DataTableSort, DataTableSortDirection, DataTableSortType, Dialog, EmptyMessageText, EnumValuesFilter, Link, makeContextProvider, map, Optional, optionalTableCell, TimeFormatter, TimeRangeFilter, useExecuteOperation, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import { Contract, CustomerConsoleAppUrlHelper, Entity, entityModelStore, ItemSelectionHelper, KubernetesController, LicensingHelper, PagedEntityFilter, PagedEntityFilterEntityIdPage, ScopeHelper, ScopeNavigationView, ScopeTenantFilter, StorageHelper, Tenant, TenantHelper, tenantModelStore, TimeRangeHelper, useEntityTypeNameTranslator, useKubernetesClusterPlatformTranslator, UserHelper, useScopeNavigationViewContext, useTheme } from "../../../../../../common";
import { useSystemKubernetesClusterAdmissionControllerStatusTranslator, useSystemKubernetesClusterApiConnectivityStatusTranslator, useSystemKubernetesClusterSensorStatusTranslator } from "../../../../../../tenants";
import { CustomerView } from "../../../../hooks";
import { KubernetesClusterAdmissionControllerStatus, KubernetesClusterApiConnectivityStatus, KubernetesClusterSensorStatus } from "../../../Entities/components";
import { KubernetesView } from "../../Kubernetes";
import { ActionsCell, AddOrEdit } from "./components";
import { SystemKubernetesClusterHelmStatus, SystemKubernetesClusterHelper } from "./utilities";

export class SystemKubernetesClustersContext {
    constructor(public addOrEditOpen: boolean | Contract.SystemKubernetesClusterModel) {
    }
}

export const [useSystemKubernetesClustersContext, useSetSystemKubernetesClustersContext, useSystemKubernetesClustersContextProvider] = makeContextProvider<SystemKubernetesClustersContext>();

export function SystemKubernetesClusters() {
    const kubernetesTenantTypes =
        useMemo(
            () => TenantHelper.KubernetesTenantTypes,
            []);
    return (
        <ScopeNavigationView
            identityPermissions={[Contract.IdentityPermission.SecurityRead]}
            layout={"global"}
            templatePath={CustomerConsoleAppUrlHelper.getTemplatePath(`${CustomerView.Kubernetes}/${KubernetesView.SystemKubernetesClusters}`)}
            tenantTypes={kubernetesTenantTypes}>
            {scopeNodeModel => <Core key={scopeNodeModel.configuration.id}/>}
        </ScopeNavigationView>);
}

function Core() {
    const { scopeNodeModel } = useScopeNavigationViewContext();
    const dataTableActionsRef = useRef<DataTableActions>();

    const [{ systemKubernetesClusterFilters }] =
        useExecuteOperation(
            Core,
            () => KubernetesController.getSystemKubernetesClusterFilters(new Contract.KubernetesControllerGetSystemKubernetesClusterFiltersRequest(scopeNodeModel.configuration.id)));

    const fetchSystemKubernetesClusterModels =
        async (filterMap: Dictionary<any>, sort: Optional<DataTableSort>, skip: number, limit: number) => {
            const { systemKubernetesClusterModelPage } =
                await KubernetesController.getSystemKubernetesClusterModelPage(
                    new Contract.KubernetesControllerGetSystemKubernetesClusterModelPageRequest(
                        new Contract.KubernetesControllerGetSystemKubernetesClusterModelPageRequestFilters(
                            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.KubernetesControllerSystemKubernetesClusterModelProperty.AdmissionControllerStatus]),
                            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.KubernetesControllerSystemKubernetesClusterModelProperty.ApiConnectivityStatus]),
                            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.KubernetesControllerSystemKubernetesClusterModelProperty.ApplicationVersion]),
                            TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(dataTableActionsRef.current?.getFiltersTime(), filterMap[Contract.KubernetesControllerSystemKubernetesClusterModelProperty.ClusterCreationTime]),
                            ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.KubernetesControllerSystemKubernetesClusterModelProperty.ClusterReference]),
                            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.KubernetesControllerSystemKubernetesClusterModelProperty.Platform]),
                            TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(dataTableActionsRef.current?.getFiltersTime(), filterMap[Contract.KubernetesControllerSystemKubernetesClusterModelProperty.ResourceUpdateTime]),
                            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.KubernetesControllerSystemKubernetesClusterModelProperty.SensorStatus]),
                            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.KubernetesControllerSystemKubernetesClusterModelProperty.ScopeId])),
                        limit,
                        scopeNodeModel.configuration.id,
                        skip,
                        new Contract.KubernetesControllerGetSystemKubernetesClusterModelPageRequestSort(
                            sort?.direction === DataTableSortDirection.Descending
                                ? Contract.SortDirection.Descending
                                : Contract.SortDirection.Ascending,
                            (sort?.columnId as Contract.KubernetesControllerSystemKubernetesClusterModelProperty) ??
                            Contract.KubernetesControllerSystemKubernetesClusterModelProperty.ClusterReference)));
            return new DataTableFetchItemsResult(
                { count: systemKubernetesClusterModelPage.count! },
                systemKubernetesClusterModelPage.items,
                !systemKubernetesClusterModelPage.hasMore);
        };

    async function getExportData(_exportData: any, items: Contract.SystemKubernetesClusterModel[]): Promise<ExportData> {
        const clusterModels =
            await entityModelStore.get(
                _(items).
                    map(item => item.clusterReference.idReference).
                    filter().
                    value());
        const tenantModels =
            await tenantModelStore.get(
                _.map(
                    items,
                    item => item.scopeId));

        return {
            clusterModelMap:
                _.keyBy(
                    clusterModels,
                    clusterModel => clusterModel.id),
            tenantModelMap:
                _.keyBy(
                    tenantModels,
                    tenantModel => tenantModel.configuration.id)
        };
    }

    const [context, setContext, ContextProvider] = useSystemKubernetesClustersContextProvider(() => new SystemKubernetesClustersContext(false));

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const kubernetesClusterAdmissionControllerStatusTranslator = useSystemKubernetesClusterAdmissionControllerStatusTranslator();
    const kubernetesClusterApiConnectivityStatusTranslator = useSystemKubernetesClusterApiConnectivityStatusTranslator();
    const kubernetesClusterPlatformTranslator = useKubernetesClusterPlatformTranslator();
    const kubernetesClusterSensorStatusTranslator = useSystemKubernetesClusterSensorStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.kubernetes.systemKubernetesClusters",
            () => ({
                actions: {
                    add: "Add Clusters via Helm"
                },
                columns: {
                    admissionControllerStatus: {
                        info: {
                            link: "Learn more",
                            title: "Enforces and validates policies on cluster actions. {{link}}"
                        },
                        title: "Admission Controller"
                    },
                    apiConnectivityStatus: {
                        info: {
                            link: "Learn more",
                            title: "Syncs and scans cluster resources. {{link}}."
                        },
                        title: "Resource Sync"
                    },
                    applicationVersion: {
                        legacyVersion: "Legacy",
                        title: "Helm Version"
                    },
                    clusterCreationTime: "Creation Time",
                    clusterReference: "Name",
                    connectorId: "Connector",
                    platform: "Platform",
                    resourceUpdateTime: "Resource Sync Time",
                    scopeId: "Account",
                    sensorStatus: {
                        info: {
                            link: "Learn more",
                            title: "Vulnerability scanning for cluster nodes and container images. {{link}}."
                        },
                        title: "Vulnerability Management"
                    }
                },
                empty: {
                    withFilter: "No Matching Clusters",
                    withoutFilter: "No Clusters"
                }
            }));

    return (
        <ContextProvider>
            {context.addOrEditOpen !== false &&
                <Dialog
                    size="large"
                    variant="editor"
                    onClose={
                        () =>
                            setContext(
                                context => ({
                                    ...context,
                                    addOrEditOpen: false
                                }))}>
                    <AddOrEdit/>
                </Dialog>}
            <DataTable
                actionsRef={dataTableActionsRef}
                columnOptions={{
                    orderOptions: {
                        enabled: true,
                        persistenceStorageItem: StorageHelper.customerKubernetesSystemKubernetesClustersColumnOrder
                    },
                    resizable: true,
                    selectorOptions: {
                        enabled: true,
                        persistenceStorageItem: StorageHelper.customerKubernetesSystemKubernetesClustersColumnSelector
                    },
                    stickyColumnId: Contract.KubernetesControllerSystemKubernetesClusterModelProperty.ClusterReference
                }}
                emptyMessageOptions={{
                    emptyMessageText:
                        new EmptyMessageText(
                            localization.empty.withoutFilter(),
                            localization.empty.withFilter())
                }}
                exportOptions={{
                    fileNamePrefix: "Clusters",
                    getData: getExportData
                }}
                fetchItems={fetchSystemKubernetesClusterModels}
                getItemId={item => item.id}>
                <DataTableAction>
                    <Button
                        disabled={
                            !UserHelper.hasScopePermissions(scopeNodeModel.configuration.id, Contract.IdentityPermission.SecurityAdministrationRead) ||
                            ScopeHelper.isProjectScope(scopeNodeModel)}
                        size="small"
                        startIcon={<AddIcon/>}
                        onClick={
                            () =>
                                setContext(
                                    context => ({
                                        ...context,
                                        addOrEditOpen: true
                                    }))}>
                        {localization.actions.add()}
                    </Button>
                </DataTableAction>
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.SystemKubernetesClusterModel, exportData: ExportData) => ({
                                Type:
                                    _.isNil(item.clusterTypeName)
                                        ? "-"
                                        : entityTypeNameTranslator(item.clusterTypeName),
                                [localization.columns.clusterReference()]: exportData.clusterModelMap[item.clusterReference.idReference].entity.displayName
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            default: true,
                            element:
                                <PagedEntityFilter
                                    getEntityIdPage={
                                        async (searchText: Optional<string>, skip: number, limit: number, data?: any) => {
                                            const { systemKubernetesClusterFilterItemPage } =
                                                await KubernetesController.getKubernetesControllerGetSystemKubernetesClusterFilterItemPage(
                                                    new Contract.KubernetesControllerGetSystemKubernetesClusterFilterItemPageRequest(
                                                        limit,
                                                        Contract.KubernetesControllerSystemKubernetesClusterModelProperty.ClusterReference,
                                                        scopeNodeModel.configuration.id,
                                                        searchText,
                                                        skip)) as Contract.KubernetesControllerGetSystemKubernetesClusterFilterItemPageResponse<string>;
                                            return new PagedEntityFilterEntityIdPage(
                                                systemKubernetesClusterFilterItemPage.count ?? data!.count,
                                                false,
                                                systemKubernetesClusterFilterItemPage.items,
                                                () => ({
                                                    count: systemKubernetesClusterFilterItemPage.count ?? data!.count
                                                }));
                                        }}
                                    placeholder={localization.columns.clusterReference()}/>
                        }
                    }}
                    id={Contract.KubernetesControllerSystemKubernetesClusterModelProperty.ClusterReference}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.SystemKubernetesClusterModel>) =>
                            <Entity
                                entityIdOrModel={item.clusterReference.idReference}
                                variant="iconText"/>}
                    selectorOptions={{ disabled: true }}
                    title={localization.columns.clusterReference()}/>
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.SystemKubernetesClusterModel, exportData: ExportData) => ({
                                [localization.columns.scopeId()]: exportData.tenantModelMap[item.scopeId].configuration.name
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            default: true,
                            element:
                                <ScopeTenantFilter
                                    placeholder={localization.columns.scopeId()}
                                    tenantIds={systemKubernetesClusterFilters.tenantIdItems.items}/>
                        }
                    }}
                    id={Contract.KubernetesControllerSystemKubernetesClusterModelProperty.ScopeId}
                    render={TenantCell}
                    title={localization.columns.scopeId()}/>
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.SystemKubernetesClusterModel) => ({
                                [localization.columns.platform()]:
                                    _.isNil(item.platform)
                                        ? undefined
                                        : kubernetesClusterPlatformTranslator(item.platform)
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            default: true,
                            element:
                                <EnumValuesFilter
                                    emptyValueOptions={{ enabled: true }}
                                    enumType={Contract.KubernetesClusterPlatform}
                                    enumTypeTranslator={kubernetesClusterPlatformTranslator}
                                    placeholder={localization.columns.platform()}/>
                        }
                    }}
                    id={Contract.KubernetesControllerSystemKubernetesClusterModelProperty.Platform}
                    render={
                        optionalTableCell<Contract.SystemKubernetesClusterModel>(
                            item =>
                                _.isNil(item.platform)
                                    ? undefined
                                    : kubernetesClusterPlatformTranslator(item.platform))}
                    title={localization.columns.platform()}/>
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.SystemKubernetesClusterModel) => ({
                                [localization.columns.clusterCreationTime()]:
                                    _.isNil(item.clusterCreationTime)
                                        ? undefined
                                        : TimeFormatter.sortableDateTime(item.clusterCreationTime)
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <TimeRangeFilter
                                    emptyValue={systemKubernetesClusterFilters.clusterCreationTimeRange.emptyValue}
                                    placeholder={localization.columns.clusterCreationTime()}
                                    timeRange={TimeRangeHelper.getTimeRangeFilterRange(systemKubernetesClusterFilters.clusterCreationTimeRange.timeRange)}/>
                        }
                    }}
                    id={Contract.KubernetesControllerSystemKubernetesClusterModelProperty.ClusterCreationTime}
                    render={
                        optionalTableCell<Contract.SystemKubernetesClusterModel>(
                            item =>
                                _.isNil(item.clusterCreationTime)
                                    ? undefined
                                    : TimeFormatter.monthDayAndYear(item.clusterCreationTime))}
                    selectorOptions={{ default: false }}
                    sortOptions={{ type: DataTableSortType.Date }}
                    title={localization.columns.clusterCreationTime()}/>
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.SystemKubernetesClusterModel) => ({
                                [localization.columns.apiConnectivityStatus.title()]:
                                    _.isNil(item.apiConnectivityStatus)
                                        ? ""
                                        : kubernetesClusterApiConnectivityStatusTranslator(item.apiConnectivityStatus)
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            default: true,
                            element:
                                <EnumValuesFilter
                                    emptyValueOptions={{ enabled: true }}
                                    enumType={Contract.SystemKubernetesClusterApiConnectivityStatus}
                                    enumTypeTranslator={kubernetesClusterApiConnectivityStatusTranslator}
                                    placeholder={localization.columns.apiConnectivityStatus.title()}/>
                        }
                    }}
                    id={Contract.KubernetesControllerSystemKubernetesClusterModelProperty.ApiConnectivityStatus}
                    message={
                        localization.columns.apiConnectivityStatus.info.title({
                            link:
                                <Link
                                    urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsViewAndManageClustersResourceSyncStatusRelativeUrl()}
                                    variant="external">
                                    {localization.columns.apiConnectivityStatus.info.link()}
                                </Link>
                        })}
                    messageLevel="info"
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.SystemKubernetesClusterModel>) =>
                            <KubernetesClusterApiConnectivityStatus
                                apiConnectivityStatus={item.apiConnectivityStatus}
                                connector={item.helm.connector}
                                documentationKubernetesOnboardClusterUrl={
                                    _.isNil(item.clusterTypeName)
                                        ? CustomerConsoleAppUrlHelper.getDocsOnboardClusterViaTenableConnectorRelativeUrl()
                                        : map(
                                            item.clusterTypeName,
                                            {
                                                [Contract.TypeNames.AwsEksCluster]: () => CustomerConsoleAppUrlHelper.getDocsOnboardAwsEksClusterGrantPermissionsRelativeUrl(),
                                                [Contract.TypeNames.AzureContainerServiceManagedCluster]: () => CustomerConsoleAppUrlHelper.getDocsOnboardAzureAksClusterRelativeUrl(),
                                                [Contract.TypeNames.GcpContainerCluster]: () => CustomerConsoleAppUrlHelper.getDocsOnboardClusterViaTenableConnectorRelativeUrl()
                                            },
                                            () => CustomerConsoleAppUrlHelper.getDocsOnboardClusterViaTenableConnectorRelativeUrl())}/>}
                    title={localization.columns.apiConnectivityStatus.title()}/>
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.SystemKubernetesClusterModel) => ({
                                [localization.columns.resourceUpdateTime()]:
                                    _.isNil(item.resourceUpdateTime)
                                        ? undefined
                                        : TimeFormatter.sortableDateTime(item.resourceUpdateTime)
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <TimeRangeFilter
                                    emptyValue={systemKubernetesClusterFilters.resourceUpdateTimeRange.emptyValue}
                                    placeholder={localization.columns.resourceUpdateTime()}
                                    timeRange={TimeRangeHelper.getTimeRangeFilterRange(systemKubernetesClusterFilters.resourceUpdateTimeRange.timeRange)}/>
                        }
                    }}
                    id={Contract.KubernetesControllerSystemKubernetesClusterModelProperty.ResourceUpdateTime}
                    render={
                        optionalTableCell<Contract.SystemKubernetesClusterModel>(
                            item =>
                                _.isNil(item.resourceUpdateTime)
                                    ? undefined
                                    : TimeFormatter.humanizePastDuration(item.resourceUpdateTime))}
                    selectorOptions={{ default: false }}
                    sortOptions={{ type: DataTableSortType.Date }}
                    title={localization.columns.resourceUpdateTime()}/>
                {LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.CnappEnterprise) &&
                    <DataTableColumn
                        exportOptions={{
                            getItem:
                                (item: Contract.SystemKubernetesClusterModel) => ({
                                    [localization.columns.admissionControllerStatus.title()]: kubernetesClusterAdmissionControllerStatusTranslator(item.helm.admissionController.status)
                                })
                        }}
                        filterOptions={{
                            itemOrItems: {
                                default: true,
                                element:
                                    <EnumValuesFilter
                                        enumType={Contract.SystemKubernetesClusterModelHelmComponentStatus}
                                        enumTypeTranslator={kubernetesClusterAdmissionControllerStatusTranslator}
                                        placeholder={localization.columns.admissionControllerStatus.title()}/>
                            }
                        }}
                        id={Contract.KubernetesControllerSystemKubernetesClusterModelProperty.AdmissionControllerStatus}
                        message={
                            localization.columns.admissionControllerStatus.info.title({
                                link:
                                    <Link
                                        urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsAdmissionControllerRelativeUrl()}
                                        variant="external">
                                        {localization.columns.admissionControllerStatus.info.link()}
                                    </Link>
                            })}
                        messageLevel="info"
                        render={
                            ({ item }: DataTableColumnRenderProps<Contract.SystemKubernetesClusterModel>) =>
                                <KubernetesClusterAdmissionControllerStatus status={item.helm.admissionController.status}/>}
                        title={localization.columns.admissionControllerStatus.title()}/>}
                {LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.CnappEnterprise) &&
                    <DataTableColumn
                        exportOptions={{
                            getItem:
                                (item: Contract.SystemKubernetesClusterModel) => ({
                                    [localization.columns.sensorStatus.title()]: kubernetesClusterSensorStatusTranslator(item.helm.sensor.status)
                                })
                        }}
                        filterOptions={{
                            itemOrItems: {
                                default: true,
                                element:
                                    <EnumValuesFilter
                                        enumType={Contract.SystemKubernetesClusterModelHelmComponentStatus}
                                        enumTypeTranslator={kubernetesClusterSensorStatusTranslator}
                                        placeholder={localization.columns.sensorStatus.title()}/>
                            }
                        }}
                        id={Contract.KubernetesControllerSystemKubernetesClusterModelProperty.SensorStatus}
                        message={
                            localization.columns.sensorStatus.info.title({
                                link:
                                    <Link
                                        urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsHowTenableScansYourOnPremisesKubernetesWorkloadsRelativeUrl()}
                                        variant="external">
                                        {localization.columns.sensorStatus.info.link()}
                                    </Link>
                            })}
                        messageLevel="info"
                        render={
                            ({ item }: DataTableColumnRenderProps<Contract.SystemKubernetesClusterModel>) =>
                                <KubernetesClusterSensorStatus status={item.helm.sensor.status}/>}
                        title={localization.columns.sensorStatus.title()}/>}
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.SystemKubernetesClusterModel) => ({
                                [localization.columns.applicationVersion.title()]:
                                    map(
                                        SystemKubernetesClusterHelper.getHelmStatus(item),
                                        {
                                            [SystemKubernetesClusterHelmStatus.NotInstalled]: () => "-",
                                            [SystemKubernetesClusterHelmStatus.Installed]: () => item.helm.applicationVersion,
                                            [SystemKubernetesClusterHelmStatus.Outdated]: () => localization.columns.applicationVersion.legacyVersion()
                                        })
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            default: true,
                            element:
                                <ValuesFilter
                                    emptyValueOptions={{ enabled: true }}
                                    placeholder={localization.columns.applicationVersion.title()}>
                                    {_.map(
                                        systemKubernetesClusterFilters.applicationVersionValueItems.items,
                                        applicationVersionValue =>
                                            <ValuesFilterItem
                                                key={applicationVersionValue}
                                                title={
                                                    applicationVersionValue === "LegacyVersion"
                                                        ? localization.columns.applicationVersion.legacyVersion()
                                                        : applicationVersionValue}
                                                value={applicationVersionValue}/>)}
                                </ValuesFilter>
                        }
                    }}
                    id={Contract.KubernetesControllerSystemKubernetesClusterModelProperty.ApplicationVersion}
                    render={
                        optionalTableCell<Contract.SystemKubernetesClusterModel>(
                            item =>
                                map(
                                    SystemKubernetesClusterHelper.getHelmStatus(item),
                                    {
                                        [SystemKubernetesClusterHelmStatus.NotInstalled]: () => undefined,
                                        [SystemKubernetesClusterHelmStatus.Installed]: () => item.helm.applicationVersion,
                                        [SystemKubernetesClusterHelmStatus.Outdated]: () => localization.columns.applicationVersion.legacyVersion()
                                    }))}
                    title={localization.columns.applicationVersion.title()}/>
                <DataTableColumn
                    id={TableColumnId.Actions}
                    orderable={false}
                    render={ActionsCell}
                    resizable={false}
                    selectorOptions={{ disabled: true }}
                    sortOptions={{ enabled: false }}/>
            </DataTable>
        </ContextProvider>);
}

function TenantCell({ item }: DataTableColumnRenderProps<Contract.SystemKubernetesClusterModel>) {
    const theme = useTheme();
    return (
        <Typography noWrap={true}>
            <Tenant
                sx={{ color: theme.palette.text.primary }}
                tenantId={item.scopeId}
                variant="iconText"/>
        </Typography>);
}

type ExportData = {
    clusterModelMap: Dictionary<Contract.EntityModel>;
    tenantModelMap: Dictionary<Contract.TenantModel>;
};

enum TableColumnId {
    Actions = "actions"
}