import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, TypeHelper, useBuiltInEntityAttributeTypeNameTranslator, useDataSensitivityTranslator, useEntityTypeNameTranslator, useTheme } from "../../../../..";
import { EntityAttributeProps } from "..";
import { Container } from "./Container";

export function BuiltIn({ entityAttribute, entityCount, entityTypeName }: EntityAttributeProps) {
    const builtInEntityAttributeTypeNameTranslator = useBuiltInEntityAttributeTypeNameTranslator();
    const dataSensitivityTranslator = useDataSensitivityTranslator();
    const entityTypeNameTranslator = useEntityTypeNameTranslator();

    const localization =
        useLocalization(
            "common.entityAttributes.entityAttribute.builtIn",
            () => ({
                [Contract.TypeNames.EntityAttribute]: {
                    [Contract.TypeNames.AadDirectoryUserExternalAttribute]: {
                        many: "Some {{translatedEntityTypeName}} authenticate via a different Microsoft Entra ID organization or an external identity provider",
                        single: "This {{translatedEntityTypeName}} authenticates via a different Microsoft Entra ID organization or an external identity provider"
                    },
                    [Contract.TypeNames.AadDirectoryUserGuestAttribute]: {
                        many: "Some {{translatedEntityTypeName}} are guest users",
                        single: "This {{translatedEntityTypeName}} is a guest user"
                    },
                    [Contract.TypeNames.AdministratorPrincipalAttribute]: {
                        many: "Some {{translatedEntityTypeName}} have admin privileges",
                        single: "This {{translatedEntityTypeName}} has admin privileges"
                    },
                    [Contract.TypeNames.ApplicationPciDataResourceAttribute]: {
                        many: "Some {{translatedEntityTypeName}} contain PCI data with {{sensitivity}} sensitivity",
                        single: "This {{translatedEntityTypeName}} contains PCI data with {{sensitivity}} sensitivity"
                    },
                    [Contract.TypeNames.ApplicationPciDataResourcePermissionActionPrincipalAttribute]: {
                        excessive: {
                            many: "Some {{translatedEntityTypeName}} have excessive permissions on sensitive PCI data resources",
                            single: "This {{translatedEntityTypeName}} has excessive permissions on sensitive PCI data resources"
                        },
                        nonexcessive: {
                            many: "Some {{translatedEntityTypeName}} have permissions on sensitive PCI data resources",
                            single: "This {{translatedEntityTypeName}} has permissions on sensitive PCI data resources"
                        }
                    },
                    [Contract.TypeNames.ApplicationPhiDataResourceAttribute]: {
                        many: "Some {{translatedEntityTypeName}} contain PHI data with {{sensitivity}} sensitivity",
                        single: "This {{translatedEntityTypeName}} contains PHI data with {{sensitivity}} sensitivity"
                    },
                    [Contract.TypeNames.ApplicationPhiDataResourcePermissionActionPrincipalAttribute]: {
                        excessive: {
                            many: "Some {{translatedEntityTypeName}} have excessive permissions on sensitive PHI data resources",
                            single: "This {{translatedEntityTypeName}} has excessive permissions on sensitive PHI data resources"
                        },
                        nonexcessive: {
                            many: "Some {{translatedEntityTypeName}} have permissions on sensitive PHI data resources",
                            single: "This {{translatedEntityTypeName}} has permissions on sensitive PHI data resources"
                        }
                    },
                    [Contract.TypeNames.ApplicationPiiDataResourceAttribute]: {
                        many: "Some {{translatedEntityTypeName}} contain PII data with {{sensitivity}} sensitivity",
                        single: "This {{translatedEntityTypeName}} contains PII data with {{sensitivity}} sensitivity"
                    },
                    [Contract.TypeNames.ApplicationPiiDataResourcePermissionActionPrincipalAttribute]: {
                        excessive: {
                            many: "Some {{translatedEntityTypeName}} have excessive permissions on sensitive PII data resources",
                            single: "This {{translatedEntityTypeName}} has excessive permissions on sensitive PII data resources"
                        },
                        nonexcessive: {
                            many: "Some {{translatedEntityTypeName}} have permissions on sensitive PII data resources",
                            single: "This {{translatedEntityTypeName}} has permissions on sensitive PII data resources"
                        }
                    },
                    [Contract.TypeNames.ApplicationSecretsDataResourceAttribute]: {
                        many: "Some {{translatedEntityTypeName}} contain secrets with {{sensitivity}} sensitivity",
                        single: "This {{translatedEntityTypeName}} contains secrets with {{sensitivity}} sensitivity"
                    },
                    [Contract.TypeNames.ApplicationSecretsDataResourcePermissionActionPrincipalAttribute]: {
                        excessive: {
                            many: "Some {{translatedEntityTypeName}} have excessive permissions on sensitive data resources with secrets",
                            single: "This {{translatedEntityTypeName}} has excessive permissions on sensitive data resources with secrets"
                        },
                        nonexcessive: {
                            many: "Some {{translatedEntityTypeName}} have permissions on sensitive data resources with secrets",
                            single: "This {{translatedEntityTypeName}} has permissions on sensitive data resources with secrets"
                        }
                    },
                    [Contract.TypeNames.AwsAccessKeyEnabledUserAttribute]: {
                        many: "Some {{translatedEntityTypeName}} have active access keys",
                        single: "This {{translatedEntityTypeName}} has active access keys",
                        [Contract.TypeNames.AwsIamGroup]: {
                            many: "Some {{translatedEntityTypeName}} have members with active access keys",
                            single: "This {{translatedEntityTypeName}} has members with active access keys"
                        }
                    },
                    [Contract.TypeNames.AwsAssumeRolePolicyDocumentNonComputeServiceGranteeOnlyRoleAttribute]: {
                        many: "Some {{translatedEntityTypeName}} are used by a trusted AWS service",
                        single: "This {{translatedEntityTypeName}} is used by a trusted AWS service"
                    },
                    [Contract.TypeNames.BehaviorRiskIdentityAttribute]: {
                        many: "Some {{translatedEntityTypeName}} were observed performing unusual behavior",
                        single: "This {{translatedEntityTypeName}} was observed performing unusual behavior"
                    },
                    [Contract.TypeNames.CodeResourceAttribute]: {
                        many: "Some {{translatedEntityTypeName}} are managed as code in one of your repositories",
                        single: "This {{translatedEntityTypeName}} is managed as code in one of your repositories"
                    },
                    [Contract.TypeNames.CredentialsDisabledUserAttribute]: {
                        many: "Some {{translatedEntityTypeName}} have no password or active access keys",
                        single: "This {{translatedEntityTypeName}} has no password or active access keys"
                    },
                    [Contract.TypeNames.DisabledIdentityAttribute]: {
                        many: "Some {{translatedEntityTypeName}} are disabled",
                        single: "This {{translatedEntityTypeName}} is disabled"
                    },
                    [Contract.TypeNames.GcpUnusedTenantEntityAttribute]: {
                        many: "Some {{translatedEntityTypeName}} are unused",
                        single: "This {{translatedEntityTypeName}} is unused"
                    },
                    [Contract.TypeNames.GcpUserManagedKeyExistsServiceAccountAttribute]: {
                        many: "Some {{translatedEntityTypeName}} have user managed keys",
                        single: "This {{translatedEntityTypeName}} has user managed keys"
                    },
                    [Contract.TypeNames.InactiveIdentityAttribute]: {
                        many: "Some {{translatedEntityTypeName}} are inactive",
                        single: "This {{translatedEntityTypeName}} is inactive"
                    },
                    [Contract.TypeNames.MfaDisabledUserAttribute]: {
                        many: "Some {{translatedEntityTypeName}} have no MFA enabled",
                        single: "This {{translatedEntityTypeName}} has no MFA enabled",
                        [Contract.TypeNames.AwsIamGroup]: {
                            many: "Some {{translatedEntityTypeName}} have members with no MFA enabled",
                            single: "This {{translatedEntityTypeName}} has members with no MFA enabled"
                        }
                    },
                    [Contract.TypeNames.PermissionManagementEntityAttribute]: {
                        [Contract.TypeNames.PermissionManagementEntityAttributeType]: {
                            [Contract.PermissionManagementEntityAttributeType.Groups]: {
                                many: "Membership to some {{translatedEntityTypeName}} can be requested through a Just-In-Time (JIT) eligibility",
                                single: "Membership to this {{translatedEntityTypeName}} can be requested through a Just-In-Time (JIT) eligibility"
                            },
                            [Contract.PermissionManagementEntityAttributeType.Permissions]: {
                                many: "Some {{translatedEntityTypeName}} can be requested through a Just-In-Time (JIT) eligibility",
                                single: "This {{translatedEntityTypeName}} can be requested through a Just-In-Time (JIT) eligibility"
                            }
                        }
                    },
                    [Contract.TypeNames.PublicEntityAttribute]: {
                        access: {
                            many: "Some {{translatedEntityTypeName}} are accessible to any identity",
                            single: "This {{translatedEntityTypeName}} is accessible to any identity"
                        },
                        accessAndNetwork: {
                            many: "Some {{translatedEntityTypeName}} are accessible from a wide range of public IP addresses and to any identity",
                            single: "This {{translatedEntityTypeName}} is accessible from a wide range of public IP addresses and to any identity"
                        },
                        network: {
                            many: "Some {{translatedEntityTypeName}} are accessible from a wide range of public IP addresses",
                            single: "This {{translatedEntityTypeName}} is accessible from a wide range of public IP addresses"
                        }
                    },
                    [Contract.TypeNames.RelatedPublicComputeAttribute]: {
                        many: "Some {{translatedEntityTypeName}} is linked to a compute resource that is accessible from a wide range of public IP addresses",
                        single: "This {{translatedEntityTypeName}} is linked to a compute resource that is accessible from a wide range of public IP addresses"
                    },
                    [Contract.TypeNames.RelatedWorkloadResourceVulnerabilityAttribute]: {
                        many: "Some {{translatedEntityTypeName}} is linked to a compute resource that has critical vulnerabilities",
                        single: "This {{translatedEntityTypeName}} is linked to a compute resource that has critical vulnerabilities"
                    },
                    [Contract.TypeNames.SensitiveResourceAttribute]: {
                        many: "Some {{translatedEntityTypeName}} are marked as sensitive",
                        single: "This {{translatedEntityTypeName}} is marked as sensitive"
                    },
                    [Contract.TypeNames.SensitiveResourcePermissionActionPrincipalAttribute]: {
                        excessive: {
                            many: "Some {{translatedEntityTypeName}} have excessive permissions on sensitive resources",
                            single: "This {{translatedEntityTypeName}} has excessive permissions on sensitive resources"
                        },
                        nonexcessive: {
                            many: "Some {{translatedEntityTypeName}} have permissions on sensitive resources",
                            single: "This {{translatedEntityTypeName}} has permissions on sensitive resources"
                        }
                    },
                    [Contract.TypeNames.SevereDirectoryPermissionAttribute]: {
                        many: "Some {{translatedEntityTypeName}} have privileged Microsoft Entra ID administrative roles assigned to them",
                        single: "This {{translatedEntityTypeName}} has a privileged Microsoft Entra ID administrative role assigned to it"
                    },
                    [Contract.TypeNames.SevereExcessivePermissionActionPrincipalAttribute]: {
                        many: "Some {{translatedEntityTypeName}} have high severity excessive permissions",
                        single: "This {{translatedEntityTypeName}} has high severity excessive permissions"
                    },
                    [Contract.TypeNames.SeverePermissionActionPrincipalAttribute]: {
                        many: "Some {{translatedEntityTypeName}} have high severity permissions",
                        single: "This {{translatedEntityTypeName}} has high severity permissions"
                    },
                    [Contract.TypeNames.VendorServiceIdentityAttribute]: {
                        many: "Some {{translatedEntityTypeName}} are used by a third party vendor",
                        single: "This {{translatedEntityTypeName}} is used by a third party vendor"
                    },
                    [Contract.TypeNames.WorkloadResourceMaliciousFileAttribute]: {
                        many: "Some {{translatedEntityTypeName}} have a suspected malicious file",
                        single: "This {{translatedEntityTypeName}} has a suspected malicious file"
                    },
                    [Contract.TypeNames.WorkloadResourceOperatingSystemEndOfLifeAttribute]: {
                        many: "Some {{translatedEntityTypeName}} are running a version of the operating system which is at or nearing End-of-Life",
                        single: "This {{translatedEntityTypeName}} is running a version of the operating system which is at or nearing End-of-Life"
                    },
                    [Contract.TypeNames.WorkloadResourceOperatingSystemUnpatchedAttribute]: {
                        many: "Some {{translatedEntityTypeName}} are running a version of the operating system for which there is a large number of known vulnerabilities",
                        single: "This {{translatedEntityTypeName}} is running a version of the operating system for which there is a large number of known vulnerabilities"
                    },
                    [Contract.TypeNames.WorkloadResourceVulnerabilityAttribute]: {
                        many: "Some {{translatedEntityTypeName}} have vulnerabilities that should be addressed",
                        single: "This {{translatedEntityTypeName}} has vulnerabilities that should be addressed"
                    }
                }
            }));

    const translatedEntityAttributeTypeName =
        useMemo(
            () => {
                const entityPluralization =
                    entityCount === 1
                        ? "single"
                        : "many";
                const translatedEntityTypeName =
                    entityTypeNameTranslator(
                        entityTypeName,
                        {
                            count: entityCount,
                            variant: "text"
                        });

                if (TypeHelper.extendOrImplement(entityAttribute.typeName, Contract.TypeNames.ApplicationDataResourceAttribute)) {
                    const translatedSensitivity =
                        dataSensitivityTranslator(
                            (entityAttribute as any).sensitivity,
                            "text");
                    return (localization[Contract.TypeNames.EntityAttribute] as any)
                        [entityAttribute.typeName]
                        [entityPluralization]({
                            sensitivity: translatedSensitivity,
                            translatedEntityTypeName
                        });
                } else if (entityAttribute.typeName === Contract.TypeNames.AwsAccessKeyEnabledUserAttribute &&
                    entityTypeName === Contract.TypeNames.AwsIamGroup) {
                    return localization[Contract.TypeNames.EntityAttribute]
                        [Contract.TypeNames.AwsAccessKeyEnabledUserAttribute]
                        [Contract.TypeNames.AwsIamGroup]
                        [entityPluralization]({ translatedEntityTypeName });

                } else if (TypeHelper.extendOrImplement(entityAttribute.typeName, Contract.TypeNames.DataResourcePermissionActionPrincipalAttribute)) {
                    return (localization[Contract.TypeNames.EntityAttribute] as any)
                        [entityAttribute.typeName]
                        [(entityAttribute as Contract.DataResourcePermissionActionPrincipalAttribute).excessive
                            ? "excessive"
                            : "nonexcessive"]
                        [entityPluralization]({ translatedEntityTypeName });
                } else if (
                    entityAttribute.typeName === Contract.TypeNames.MfaDisabledUserAttribute &&
                    entityTypeName === Contract.TypeNames.AwsIamGroup) {
                    return localization[Contract.TypeNames.EntityAttribute]
                        [Contract.TypeNames.MfaDisabledUserAttribute]
                        [Contract.TypeNames.AwsIamGroup]
                        [entityPluralization]({ translatedEntityTypeName });
                } else if (entityAttribute.typeName === Contract.TypeNames.PermissionManagementEntityAttribute) {
                    return localization[Contract.TypeNames.EntityAttribute]
                        [Contract.TypeNames.PermissionManagementEntityAttribute]
                        [Contract.TypeNames.PermissionManagementEntityAttributeType]
                        [(entityAttribute as Contract.PermissionManagementEntityAttribute).type]
                        [entityPluralization]({ translatedEntityTypeName });
                } else if (entityAttribute.typeName === Contract.TypeNames.PublicEntityAttribute) {
                    const isAccessPublic = _.as<Contract.PublicEntityAttribute>(entityAttribute).access;
                    const isNetworkPublic = _.as<Contract.PublicEntityAttribute>(entityAttribute).network;
                    if (isAccessPublic && isNetworkPublic) {
                        return localization[Contract.TypeNames.EntityAttribute]
                            [Contract.TypeNames.PublicEntityAttribute].
                            accessAndNetwork
                            [entityPluralization]({ translatedEntityTypeName });
                    } else if (isAccessPublic && !isNetworkPublic) {
                        return localization[Contract.TypeNames.EntityAttribute]
                            [Contract.TypeNames.PublicEntityAttribute].
                            access
                            [entityPluralization]({ translatedEntityTypeName });
                    } else if (!isAccessPublic && isNetworkPublic) {
                        return localization[Contract.TypeNames.EntityAttribute]
                            [Contract.TypeNames.PublicEntityAttribute].
                            network
                            [entityPluralization]({ translatedEntityTypeName });
                    }
                } else if (entityAttribute.typeName === Contract.TypeNames.SensitiveResourcePermissionActionPrincipalAttribute) {
                    return localization[Contract.TypeNames.EntityAttribute]
                        [Contract.TypeNames.SensitiveResourcePermissionActionPrincipalAttribute]
                        [(entityAttribute as Contract.SensitiveResourcePermissionActionPrincipalAttribute).excessive
                            ? "excessive"
                            : "nonexcessive"]
                        [entityPluralization]({ translatedEntityTypeName });
                } else {
                    return (localization[Contract.TypeNames.EntityAttribute] as any)
                        [entityAttribute.typeName]
                        [entityPluralization]({ translatedEntityTypeName });
                }
            },
            []);

    const theme = useTheme();
    return (
        <Container
            color={theme.palette.entityAttribute.builtIn(entityAttribute.typeName)}
            name={builtInEntityAttributeTypeNameTranslator(entityAttribute.typeName)}
            tooltip={translatedEntityAttributeTypeName}
            variant="automatic"/>);
}