﻿import { Link, useLocalization } from "@infrastructure";
import React, { Fragment } from "react";
import { Contract, Entity, tenantModelStore } from "../../../../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../../../../tenants";
import { RiskDefinitionSection } from "../../../../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../../../../useCommonSectionsAndDescriptionDefinition";
import { UserData } from "../../components";
import { ContextSection } from "./components";

export function useAwsEc2InstanceUserDataSecretExistsRiskInstanceDefinition(riskModel: Contract.AwsEc2InstanceUserDataSecretExistsRiskModel) {
    const tenantConfiguration = tenantModelStore.useGet(riskModel.tenantId).configuration as Contract.AwsTenantConfiguration;

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsEc2InstanceUserDataSecretExistsRiskDefinition.hooks.useAwsEc2InstanceUserDataSecretExistsRiskInstanceDefinition",
            () => ({
                description: "{{instance}} exposing secrets in clear text via user data",
                sections: {
                    resolution: {
                        step1: "Click on **Instances**",
                        step2: "Check then target instance",
                        step3: "If the instance is in running state, on the top menu click on **Instance state | Stop instance** and wait until it is stopped",
                        step4: "On the top menu click on **Actions | Instance settings | Edit user data**",
                        step5: {
                            link: "Secrets Manager",
                            text: "Migrate your secrets to {{secretsManagerLink}}"
                        },
                        step6: "Edit the user data and remove the stored secrets",
                        step7: "Click **Save** to confirm"
                    },
                    userData: "User Data"
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            instance:
                <Entity
                    entityIdOrModel={riskModel.risk.entityId}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () =>
            [
                consoleSignInStepTranslator(
                    Contract.AwsConsoleView.Ec2,
                    AwsConsoleUrlBuilder.getEc2Url(tenantConfiguration.partitionType)),
                localization.sections.resolution.step1(),
                localization.sections.resolution.step2(),
                localization.sections.resolution.step3(),
                localization.sections.resolution.step4(),
                localization.sections.resolution.step5.text({
                    secretsManagerLink:
                        <Link
                            urlOrGetUrl={riskModel.secretsManagerDocumentationUrl}
                            variant="external">
                            {localization.sections.resolution.step5.link()}
                        </Link>
                }),
                localization.sections.resolution.step6(),
                localization.sections.resolution.step7()
            ],
        riskModel,
        undefined,
        {
            contextSectionElement: <ContextSection riskModel={riskModel}/>,
            sections: [
                new RiskDefinitionSection(
                    <Fragment>
                        <UserData
                            riskModel={riskModel}
                            textBlock={riskModel.risk.instanceIdToUserDataSecretExistsTextBlockMap![riskModel.risk.entityId]}/>
                    </Fragment>,
                    localization.sections.userData())]
        });
}