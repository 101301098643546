﻿import { EmptyMessageText, Steps, useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { RiskContentProps } from "../../../../../../../..";
import { Contract, Entity, entityModelStore, InlineEntities, useRiskSeverityReasonTranslator, useSeverityTranslator, useTenantNameTranslator } from "../../../../../../../../../../../../../../../../common";
import { RiskDefinitionSection } from "../../../../../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../../../../../useCommonSectionsAndDescriptionDefinition";
import { useOpenRiskedEntityRisksStep } from "../../../../../../../useOpenRiskedEntityRisksStep";
import { EntityExternalConsoleLink } from "../../../../../../components";
import { RoleBindingTable } from "../../../components";
import { getSeverityValue, useGetServiceAccountSeverity } from "../../useGetServiceAccountSeverity";

export function useGcpPrincipalUserWideScopeImpersonateServiceAccountActionExistsRiskGciDirectoryUserDefinition(riskModel: Contract.GcpPrincipalUserWideScopeImpersonateServiceAccountActionExistsRiskModel) {
    const risk = riskModel.risk as Contract.GcpPrincipalUserWideScopeImpersonateServiceAccountActionExistsRisk;
    const userModel = entityModelStore.useGet(risk.entityId) as Contract.GciDirectoryUserModel;

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.useGcpPrincipalWideScopeImpersonateServiceAccountActionExistsRiskDefinition.hooks.useGcpPrincipalUserWideScopeImpersonateServiceAccountActionExistsRiskDefinition.hooks.useGcpPrincipalUserWideScopeImpersonateServiceAccountActionExistsRiskGciDirectoryUserDefinition",
            () => ({
                description: "User {{userId}} has {{roleIds}} assigned at the project level or above",
                sections: {
                    resolution: {
                        step1: "Before deleting the role bindings, assign the user role bindings to specific service accounts",
                        step2: "{{entityExternalConsoleLink}} and filter on **Principal:{{principalRoleBindingIdentifier}}**",
                        step3: "Under **Permissions** delete the role bindings to {{roleIds}}"
                    },
                    roleBindingIds: {
                        title: "GCP Role Bindings",
                        unknown: "Learning"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            roleIds:
                <InlineEntities
                    entityIdsOrModels={riskModel.roleIds}
                    entityTypeName={Contract.TypeNames.GcpIamRole}
                    variant="itemAndTypeOrItemCountAndType"/>,
            userId:
                <Entity
                    entityIdOrModel={userModel}
                    variant="text"/>
        }),
        () =>
            _.concat(
                localization.sections.resolution.step1(),
                _(risk.orderedResourceManagerResourceIds).
                    flatMap(
                        resourceManagerResourceId => [
                            localization.sections.resolution.step2({
                                entityExternalConsoleLink:
                                    <EntityExternalConsoleLink
                                        entityId={resourceManagerResourceId}
                                        page={Contract.GcpConsolePage.Permissions}/>,
                                principalRoleBindingIdentifier: risk.principalRoleBindingIdentifier
                            }),
                            localization.sections.resolution.step3({
                                roleIds:
                                    <InlineEntities
                                        entityIdsOrModels={risk.resourceManagerResourceIdToRoleIdsMap[resourceManagerResourceId]}
                                        entityTypeName={Contract.TypeNames.GcpIamRole}
                                        variant="itemAndTypeOrItemCountAndType"/>
                            })
                        ]).
                    value()),
        riskModel,
        undefined,
        {
            contextSectionElement: <ContextSection riskModel={riskModel}/>,
            sections: [
                new RiskDefinitionSection(
                    <RoleBindingTable
                        csvExportFilePrefixes={[localization.sections.roleBindingIds.title()]}
                        risk={risk}
                        unknownMessageText={new EmptyMessageText(localization.sections.roleBindingIds.unknown())}/>,
                    localization.sections.roleBindingIds.title())
            ]
        });
}

function ContextSection({ riskModel }: RiskContentProps) {
    const risk = riskModel.risk as Contract.GcpPrincipalUserWideScopeImpersonateServiceAccountActionExistsRisk;
    const userModel = entityModelStore.useGet(risk.entityId) as Contract.GciDirectoryUserModel;
    const openRiskedEntityRisksStep = useOpenRiskedEntityRisksStep(userModel, risk.id);
    const [severity, severityServiceAccountIds] = useGetServiceAccountSeverity(risk);

    const severityReasonTranslator = useRiskSeverityReasonTranslator();
    const severityTranslator = useSeverityTranslator();
    const tenantNameTranslator = useTenantNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.useGcpPrincipalWideScopeImpersonateServiceAccountActionExistsRiskDefinition.hooks.useGcpPrincipalUserWideScopeImpersonateServiceAccountActionExistsRiskDefinition.hooks.useGcpPrincipalUserWideScopeImpersonateServiceAccountActionExistsRiskGciDirectoryUserDefinition.contextSection",
            () => ({
                serviceAccountIds: {
                    withHighSeverityServiceAccountIds: "The user can impersonate {{serviceAccountIds}} in project {{tenantId}}, {{highSeverityServiceAccountIds}} with {{severity}} severity permissions",
                    withoutHighSeverityServiceAccountIds: "The user can impersonate {{serviceAccountIds}} in project {{tenantId}}, none with high severity permissions"
                },
                status:
                    {
                        disabled: "The user's status is not active",
                        enabled: "The user's status is active"
                    },
                user: {
                    withGroupIds: "User {{userId}} was created on {{creationTime | TimeFormatter.shortDate}} and part of {{groupIds}} in {{gciTenantId}} workspace",
                    withoutGroupIds: "User {{userId}} was created on {{creationTime | TimeFormatter.shortDate}} in {{gciTenantId}} workspace"
                }
            }));

    return (
        <Steps>
            {_.filter([
                (_.isEmpty(userModel.groupIds)
                    ? localization.user.withoutGroupIds
                    : localization.user.withGroupIds)({
                    creationTime: (userModel.entity as Contract.GciDirectoryUser).creationTime,
                    gciTenantId:
                        <Typography
                            component="span"
                            variant="h5">
                            {tenantNameTranslator(userModel.entity.tenantId)}
                        </Typography>,
                    groupIds:
                        <InlineEntities
                            entityIdsOrModels={userModel.groupIds}
                            entityTypeName={Contract.TypeNames.GciDirectoryGroup}
                            entityTypeNameTranslatorOptions={{
                                includeServiceName: false,
                                variant: "text"
                            }}
                            variant="itemCountAndType"/>,
                    userId:
                        <Entity
                            entityIdOrModel={userModel}
                            variant="text"/>
                }),
                risk.identityDisabled!
                    ? localization.status.disabled()
                    : localization.status.enabled(),
                (getSeverityValue(severity) >= getSeverityValue(Contract.Severity.High)
                    ? localization.serviceAccountIds.withHighSeverityServiceAccountIds
                    : localization.serviceAccountIds.withoutHighSeverityServiceAccountIds)({
                    highSeverityServiceAccountIds:
                        <InlineEntities
                            entityIdsOrModels={severityServiceAccountIds}
                            entityTypeName={Contract.TypeNames.GcpIamServiceAccount}
                            entityTypeNameTranslatorOptions={{ variant: "text" }}
                            variant="itemCountAndType"/>,
                    serviceAccountIds:
                        <InlineEntities
                            entityIdsOrModels={_.keys(risk.serviceAccountIdToSeverityMap)}
                            entityTypeName={Contract.TypeNames.GcpIamServiceAccount}
                            entityTypeNameTranslatorOptions={{ variant: "text" }}
                            variant="itemCountAndType"/>,
                    severity:
                        _.isNil(severity)
                            ? undefined
                            : severityTranslator(severity!, "text"),
                    tenantId:
                        <Entity
                            entityIdOrModel={risk.tenantId}
                            variant="text"/>
                }),
                openRiskedEntityRisksStep,
                severityReasonTranslator(
                    risk.severity,
                    risk.severityReason!)
            ])}
        </Steps>);
}