import { DataTable, DataTableActions, DataTableColumn, DataTableColumnRenderProps, DataTableFetchItemsResult, DataTableSort, DataTableSortDirection, DataTableSortType, DeferredFilter, EmptyMessageText, Optional, optionalTableCell, StringHelper, TimeFormatter, TimeRangeFilter, useLocalization, useOperation, ValueFilter, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import { Box } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { useCallback, useRef, useState } from "react";
import { AccessController, AnalyzingView, Contract, Entity, EntityAttributeFilter, EntityAttributesCell, entityModelStore, EntityTypeFilter, FeatureHelper, InlineEntities, ItemSelectionHelper, PagedEntityFilter, PagedEntityFilterEntityIdPage, ScopeTenantFilter, StorageHelper, tenantModelStore, TimeRangeHelper, useActivityTranslator, useGetEntityAttributesCsvItem, useGetSelectedScopeHasData, useLayoutOptions, useTenantNameTranslator } from "../../../../../../common";

type AwsFederationProps = {
    sourceEntityModel?: Contract.EntityModel;
    variant: "card" | "view";
};

export function AwsFederation({ sourceEntityModel, variant }: AwsFederationProps) {
    const getSelectedScopeHasData = useGetSelectedScopeHasData();
    const filteredActiveCloudProviderTenantModels = tenantModelStore.useGetFilteredActiveCloudProviderTenants();

    const localization =
        useLocalization(
            "views.customer.access.awsFederation",
            () => ({
                title: "AWS Federated Permissions"
            }));
    useLayoutOptions({
        view:
            variant === "view"
                ? { title: localization.title() }
                : undefined
    });

    return !getSelectedScopeHasData(filteredActiveCloudProviderTenantModels)
        ? <AnalyzingView/>
        : <Box sx={{ height: "100%" }}>
            <Table
                dataTableVariant={variant}
                sourceEntityId={
                    FeatureHelper.enabled(Contract.FeatureName.AwsFederationResolutionDisabled)
                        ? sourceEntityModel?.id
                        : (sourceEntityModel as Contract.AwsSsoUserModel)?.federatedIdentityReference?.id ?? sourceEntityModel?.id}/>
        </Box>;
}

type TableProps = {
    dataTableVariant: "card" | "view";
    sourceEntityId?: string;
};

function Table({ dataTableVariant, sourceEntityId }: TableProps) {
    const activityTranslator = useActivityTranslator();
    const getEntityAttributesCsvItem = useGetEntityAttributesCsvItem();
    const tenantNameTranslator = useTenantNameTranslator();

    const [filtersPromise] =
        useOperation(
            Table,
            async () => {
                const { filters } = await AccessController.getAwsFederationEdgeFilters(new Contract.AccessControllerGetAwsFederationEdgeFiltersRequest(sourceEntityId));
                return filters;
            });

    const localization =
        useLocalization(
            "views.customer.access.awsFederation.table",
            () => ({
                columns: {
                    assumeRoleActivity: "Assume Time",
                    destinationEntityAttributes: "Role Labels",
                    destinationEntityId: "Role",
                    destinationEntityTenantId: "Role Account",
                    firstSeenTime: {
                        title: "First Seen Time",
                        tooltip: "The time when Tenable first detected the federated permission. If the permission was created before account onboarding, this reflects the onboarding time."
                    },
                    granteeGroupIds: "Via Groups",
                    sourceEntityAttributes: "User Labels",
                    sourceEntityGrantee: {
                        false: "No",
                        title: "Direct Assignment",
                        true: "Yes"
                    },
                    sourceEntityId: "User",
                    sourceEntityTenantId: "User Account",
                    sourceEntityTypeName: "User Type",
                    Sso: {
                        false: "Legacy",
                        title: "Federation Type",
                        true: "AWS Identity Center"
                    },
                    unused: {
                        false: "-",
                        title: "Unused",
                        true: "Yes"
                    }
                },
                csvExport: {
                    fileNameEntityPrefix: "AWSFederatedPermissions_{{sourceEntityDisplayName}}",
                    fileNamePrefix: "AWSFederatedPermissions"
                },
                empty: {
                    withFilter: "No Matching Data",
                    withoutFilter: "No Data"
                }
            }));

    const fetchFederationEdgeModelPageNextPageSearchCursorRef = useRef<Contract.ElasticsearchIndexSearchCursor>();
    const [federationEdgeModelCount, setFederationEdgeModelCount] = useState(0);

    const fetchItems =
        useCallback(
            async (filterMap: Dictionary<any>, sort: Optional<DataTableSort>, skip: number, limit: number) => {
                const nextPageSearchCursor =
                    skip === 0
                        ? undefined
                        : fetchFederationEdgeModelPageNextPageSearchCursorRef.current;
                const requestFilters =
                    new Contract.AccessControllerGetAwsFederationEdgeModelRequestFilters(
                        TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(dataTableActionsRef.current?.getFiltersTime(), filterMap[Contract.AccessControllerGetAwsFederationEdgeModelPageRequestProperty.AssumeRoleActivity]),
                        ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.AccessControllerGetAwsFederationEdgeModelPageRequestProperty.DestinationEntityAttributes]),
                        ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.AccessControllerGetAwsFederationEdgeModelPageRequestProperty.DestinationEntityId]),
                        ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.AccessControllerGetAwsFederationEdgeModelPageRequestProperty.DestinationEntityTenantId]),
                        TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(dataTableActionsRef.current?.getFiltersTime(), filterMap[Contract.AccessControllerGetAwsFederationEdgeModelPageRequestProperty.FirstSeenTime]),
                        ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.AccessControllerGetAwsFederationEdgeModelPageRequestProperty.GranteeGroupIds]),
                        ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.AccessControllerGetAwsFederationEdgeModelPageRequestProperty.SourceEntityAttributes]),
                        ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.AccessControllerGetAwsFederationEdgeModelPageRequestProperty.SourceEntityGrantee]),
                        ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.AccessControllerGetAwsFederationEdgeModelPageRequestProperty.SourceEntityId]),
                        ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.AccessControllerGetAwsFederationEdgeModelPageRequestProperty.SourceEntityTenantId]),
                        ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.AccessControllerGetAwsFederationEdgeModelPageRequestProperty.SourceEntityTypeName]),
                        ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.AccessControllerGetAwsFederationEdgeModelPageRequestProperty.Sso]),
                        filterMap[Contract.AccessControllerGetAwsFederationEdgeModelPageRequestProperty.Unused] as boolean);
                const requestSort =
                    new Contract.AccessControllerGetAwsFederationEdgeModelPageRequestSort(
                        sort?.direction === DataTableSortDirection.Descending
                            ? Contract.SortDirection.Descending
                            : Contract.SortDirection.Ascending,
                        _.isNil(sort)
                            ? Contract.AccessControllerGetAwsFederationEdgeModelPageRequestProperty.SourceEntityId
                            : sort.columnId as Contract.AccessControllerGetAwsFederationEdgeModelPageRequestProperty);
                const { federationEdgeModelPage } =
                    await AccessController.getAwsFederationEdgeModelPage(
                        new Contract.AccessControllerGetAwsFederationEdgeModelPageRequest(
                            sourceEntityId,
                            requestFilters,
                            limit,
                            nextPageSearchCursor,
                            requestSort));

                return new DataTableFetchItemsResult(
                    { count: federationEdgeModelPage.count ?? federationEdgeModelCount },
                    federationEdgeModelPage.items,
                    _.isNil(federationEdgeModelPage.itemNextPageSearchCursor),
                    {
                        onAppendData:
                            () => {
                                if (!_.isNil(federationEdgeModelPage.count)) {
                                    setFederationEdgeModelCount(federationEdgeModelPage.count);
                                }
                                fetchFederationEdgeModelPageNextPageSearchCursorRef.current = federationEdgeModelPage.itemNextPageSearchCursor;
                            }
                    });
            },
            [federationEdgeModelCount]);

    const filterMapRef = useRef<Dictionary<any>>();
    const dataTableActionsRef = useRef<DataTableActions>();

    async function getExportData(itemIdToExportColumnElementIdToExportDataMap: Dictionary<Dictionary<ColumnExportData>>): Promise<ExportData> {
        const relatedEntityIds =
            _(itemIdToExportColumnElementIdToExportDataMap).
                values().
                flatMap(
                    exportColumnElementIdToExportDataMap =>
                        _(exportColumnElementIdToExportDataMap).
                            values().
                            flatMap(exportData => exportData.relatedEntityIds).
                            value()).
                uniq().
                filter().
                value();

        const relatedEntities = await entityModelStore.get(relatedEntityIds);

        return {
            relatedEntityModelMap:
                _.keyBy(
                    relatedEntities,
                    relatedEntity => relatedEntity.id)
        };
    }

    const getFederationEdgeFilterItemPage =
        (property: Contract.AccessControllerGetAwsFederationEdgeModelPageRequestProperty) =>
            async (searchText: Optional<string>, skip: number, data?: number) => {
                const { federationEdgeFilterItemPage } =
                    await AccessController.getAwsFederationEdgeFilterItemPage(
                        new Contract.AccessControllerGetAwsFederationEdgeFilterItemPageRequest(
                            sourceEntityId,
                            50,
                            property,
                            searchText,
                            skip));

                const federationEdgeFilterItemCount = federationEdgeFilterItemPage?.count ?? data ?? 0;
                return new PagedEntityFilterEntityIdPage(
                    federationEdgeFilterItemCount,
                    federationEdgeFilterItemPage.emptyValue,
                    federationEdgeFilterItemPage.items,
                    () => federationEdgeFilterItemCount);
            };

    const sourceEntityModel = entityModelStore.useGet(sourceEntityId);
    return (
        <DataTable
            actionsRef={dataTableActionsRef}
            columnOptions={{
                orderOptions: {
                    enabled: true,
                    persistenceStorageItem: StorageHelper.customerAccessAwsFederationColumnOrder
                },
                resizable: dataTableVariant === "view",
                selectorOptions: {
                    enabled: true,
                    persistenceStorageItem: StorageHelper.customerAccessAwsFederationColumnSelector
                }
            }}
            emptyMessageOptions={{
                emptyMessageText:
                    new EmptyMessageText(
                        localization.empty.withoutFilter(),
                        localization.empty.withFilter())
            }}
            exportOptions={{
                fileNamePrefix:
                    _.isNil(sourceEntityModel)
                        ? localization.csvExport.fileNamePrefix()
                        : localization.csvExport.fileNameEntityPrefix({ sourceEntityDisplayName: sourceEntityModel.entity.displayName }),
                getData: getExportData
            }}
            fetchItems={fetchItems}
            filtersOptions={{
                onChanged: filterMap => filterMapRef.current = filterMap,
                persist: {
                    visibilityStorageItem: StorageHelper.customerAccessAwsFederationFilters
                }
            }}
            getItemId={(item: Contract.AwsFederationEdgeModel) => item.id}
            variant={dataTableVariant}
            virtualizationEnabled={dataTableVariant === "view"}>
            <DataTableColumn
                exportOptions={{
                    getData: (item: Contract.AwsFederationEdgeModel) => ({ relatedEntityIds: [item.sourceEntity.searchableReference.id] }),
                    getItem:
                        (item: Contract.AwsFederationEdgeModel, exportData: ExportData) => ({
                            [localization.columns.sourceEntityId()]: exportData.relatedEntityModelMap[item.sourceEntity.searchableReference.id].entity.displayReference
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        default: true,
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={getFederationEdgeFilterItemPage(Contract.AccessControllerGetAwsFederationEdgeModelPageRequestProperty.SourceEntityId)}
                                placeholder={localization.columns.sourceEntityId()}/>
                    }
                }}
                id={Contract.AccessControllerGetAwsFederationEdgeModelPageRequestProperty.SourceEntityId}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AwsFederationEdgeModel>) =>
                        <Entity
                            entityIdOrModel={item.sourceEntity.searchableReference.id}
                            variant="iconTextTypeTenant"/>}
                selectorOptions={{ disabled: true }}
                title={localization.columns.sourceEntityId()}/>
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsFederationEdgeModel) => ({
                            [localization.columns.sourceEntityTenantId()]:
                                tenantNameTranslator(
                                    item.sourceEntity.tenantId,
                                    { includeRawId: true })
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        default: true,
                        element:
                            <DeferredFilter
                                promiseOrGetPromise={filtersPromise}
                                title={localization.columns.sourceEntityTenantId()}>
                                {filters =>
                                    <ScopeTenantFilter
                                        placeholder={localization.columns.sourceEntityTenantId()}
                                        tenantIds={filters.sourceEntityTenantIdItems.items}/>}
                            </DeferredFilter>
                    }
                }}
                id={Contract.AccessControllerGetAwsFederationEdgeModelPageRequestProperty.SourceEntityTenantId}
                title={localization.columns.sourceEntityTenantId()}/>
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        element:
                            <DeferredFilter
                                promiseOrGetPromise={filtersPromise}
                                title={localization.columns.sourceEntityTypeName()}>
                                {filters =>
                                    <EntityTypeFilter
                                        emptyValue={filters.sourceEntityTypeNameItems.emptyValue}
                                        entityTypeNames={filters.sourceEntityTypeNameItems.items}
                                        placeholder={localization.columns.sourceEntityTypeName()}/>}
                            </DeferredFilter>
                    }
                }}
                id={Contract.AccessControllerGetAwsFederationEdgeModelPageRequestProperty.SourceEntityTypeName}
                title={localization.columns.sourceEntityTypeName()}/>
            <DataTableColumn
                exportOptions={{
                    getData: (item: Contract.AwsFederationEdgeModel) => ({ relatedEntityIds: [item.destinationEntity.searchableReference.id] }),
                    getItem:
                        (item: Contract.AwsFederationEdgeModel, exportData: ExportData) => ({
                            [localization.columns.destinationEntityId()]: exportData.relatedEntityModelMap[item.destinationEntity.searchableReference.id].entity.displayReference
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        default: true,
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={getFederationEdgeFilterItemPage(Contract.AccessControllerGetAwsFederationEdgeModelPageRequestProperty.DestinationEntityId)}
                                placeholder={localization.columns.destinationEntityId()}/>
                    }
                }}
                id={Contract.AccessControllerGetAwsFederationEdgeModelPageRequestProperty.DestinationEntityId}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AwsFederationEdgeModel>) =>
                        <Entity
                            entityIdOrModel={item.destinationEntity.searchableReference.id}
                            variant="iconTextTypeTenant"/>}
                selectorOptions={{ disabled: true }}
                title={localization.columns.destinationEntityId()}/>
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsFederationEdgeModel) => ({
                            [localization.columns.destinationEntityTenantId()]:
                                tenantNameTranslator(
                                    item.destinationEntity.tenantId,
                                    { includeRawId: true })
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        default: true,
                        element:
                            <DeferredFilter
                                promiseOrGetPromise={filtersPromise}
                                title={localization.columns.destinationEntityTenantId()}>
                                {filters =>
                                    <ScopeTenantFilter
                                        placeholder={localization.columns.destinationEntityTenantId()}
                                        tenantIds={filters.destinationEntityTenantIdItems.items}/>}
                            </DeferredFilter>
                    }
                }}
                id={Contract.AccessControllerGetAwsFederationEdgeModelPageRequestProperty.DestinationEntityTenantId}
                title={localization.columns.destinationEntityTenantId()}/>
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsFederationEdgeModel) => ({
                            [localization.columns.Sso.title()]:
                                item.sso
                                    ? localization.columns.Sso.true()
                                    : localization.columns.Sso.false()
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <ValuesFilter placeholder={localization.columns.Sso.title()}>
                                <ValuesFilterItem
                                    title={localization.columns.Sso.false()}
                                    value={false}/>
                                <ValuesFilterItem
                                    title={localization.columns.Sso.true()}
                                    value={true}/>
                            </ValuesFilter>
                    }
                }}
                id={Contract.AccessControllerGetAwsFederationEdgeModelPageRequestProperty.Sso}
                itemProperty={
                    (item: Contract.AwsFederationEdgeModel) =>
                        item.sso
                            ? localization.columns.Sso.true()
                            : localization.columns.Sso.false()}
                title={localization.columns.Sso.title()}/>
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsFederationEdgeModel) => ({
                            [localization.columns.firstSeenTime.title()]: TimeFormatter.monthDayAndYear(item.firstSeenTime)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <DeferredFilter
                                promiseOrGetPromise={filtersPromise}
                                title={localization.columns.firstSeenTime.title()}>
                                {filters =>
                                    <TimeRangeFilter
                                        emptyValue={filters.firstSeenTimeRange.emptyValue}
                                        placeholder={localization.columns.firstSeenTime.title()}
                                        timeRange={TimeRangeHelper.getTimeRangeFilterRange(filters.firstSeenTimeRange.timeRange)}/>}
                            </DeferredFilter>
                    }
                }}
                id={Contract.AccessControllerGetAwsFederationEdgeModelPageRequestProperty.FirstSeenTime}
                itemProperty={(federationEdgeModel: Contract.AwsFederationEdgeModel) => TimeFormatter.monthDayAndYear(federationEdgeModel.firstSeenTime)}
                selectorOptions={{ default: false }}
                sortOptions={{ type: DataTableSortType.Date }}
                title={localization.columns.firstSeenTime.title()}
                tooltip={localization.columns.firstSeenTime.tooltip()}/>
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsFederationEdgeModel) => ({
                            [localization.columns.assumeRoleActivity()]: activityTranslator(item.assumeRoleActivity)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <DeferredFilter
                                promiseOrGetPromise={filtersPromise}
                                title={localization.columns.assumeRoleActivity()}>
                                {filters =>
                                    <TimeRangeFilter
                                        emptyValue={filters.assumeRoleActivityTimeRange.emptyValue}
                                        placeholder={localization.columns.assumeRoleActivity()}
                                        timeRange={TimeRangeHelper.getTimeRangeFilterRange(filters.assumeRoleActivityTimeRange.timeRange)}/>}
                            </DeferredFilter>
                    }
                }}
                id={Contract.AccessControllerGetAwsFederationEdgeModelPageRequestProperty.AssumeRoleActivity}
                render={
                    optionalTableCell<Contract.AwsFederationEdgeModel>(
                        federationEdgeModel =>
                            activityTranslator(federationEdgeModel.assumeRoleActivity))}
                sortOptions={{ type: DataTableSortType.Date }}
                title={localization.columns.assumeRoleActivity()}/>
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsFederationEdgeModel) => ({
                            [localization.columns.unused.title()]:
                                item.unused
                                    ? localization.columns.unused.true()
                                    : localization.columns.unused.false()
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        default: true,
                        element:
                            <ValueFilter
                                items={[
                                    {
                                        title: localization.columns.unused.true(),
                                        value: true
                                    }
                                ]}
                                placeholder={localization.columns.unused.title()}/>
                    }
                }}
                id={Contract.AccessControllerGetAwsFederationEdgeModelPageRequestProperty.Unused}
                itemProperty={
                    (item: Contract.AwsFederationEdgeModel) =>
                        item.unused
                            ? localization.columns.unused.true()
                            : localization.columns.unused.false()}
                title={localization.columns.unused.title()}/>
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsFederationEdgeModel) => ({
                            [localization.columns.sourceEntityGrantee.title()]:
                                item.sourceEntityGrantee
                                    ? localization.columns.sourceEntityGrantee.true()
                                    : localization.columns.sourceEntityGrantee.false()
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <ValuesFilter
                                placeholder={localization.columns.sourceEntityGrantee.title()}
                                sorted={false}>
                                <ValuesFilterItem
                                    title={localization.columns.sourceEntityGrantee.true()}
                                    value={true}/>
                                <ValuesFilterItem
                                    title={localization.columns.sourceEntityGrantee.false()}
                                    value={false}/>
                            </ValuesFilter>
                    }
                }}
                id={Contract.AccessControllerGetAwsFederationEdgeModelPageRequestProperty.SourceEntityGrantee}
                itemProperty={
                    (item: Contract.AwsFederationEdgeModel) =>
                        item.sourceEntityGrantee
                            ? localization.columns.sourceEntityGrantee.true()
                            : localization.columns.sourceEntityGrantee.false()}
                title={localization.columns.sourceEntityGrantee.title()}/>,
            <DataTableColumn
                exportOptions={{
                    getData: (item: Contract.AwsFederationEdgeModel) => ({ relatedEntityIds: item.granteeGroupIds }),
                    getItem:
                        (item: Contract.AwsFederationEdgeModel, exportData: ExportData) => ({
                            [localization.columns.granteeGroupIds()]:
                                _(item.granteeGroupIds).
                                    map(granteeGroupId => exportData.relatedEntityModelMap[granteeGroupId].entity.displayReference).
                                    orderBy(StringHelper.getSortValue).
                                    join("\n")
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={getFederationEdgeFilterItemPage(Contract.AccessControllerGetAwsFederationEdgeModelPageRequestProperty.GranteeGroupIds)}
                                placeholder={localization.columns.granteeGroupIds()}/>
                    }
                }}
                id={Contract.AccessControllerGetAwsFederationEdgeModelPageRequestProperty.GranteeGroupIds}
                render={
                    optionalTableCell<Contract.AwsFederationEdgeModel>(
                        item =>
                            _.isEmpty(item.granteeGroupIds)
                                ? undefined
                                : <InlineEntities
                                    entityIdsOrModels={item.granteeGroupIds}
                                    entityTypeName={Contract.TypeNames.Entity}
                                    entityVariant="iconTextTypeTenant"
                                    variant="itemPlusItemCount"/>)}
                title={localization.columns.granteeGroupIds()}/>
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsFederationEdgeModel) =>
                            getEntityAttributesCsvItem(
                                item.sourceEntity.attributes.attributes,
                                localization.columns.sourceEntityAttributes())
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <DeferredFilter
                                promiseOrGetPromise={filtersPromise}
                                title={localization.columns.sourceEntityAttributes()}>
                                {filters =>
                                    <EntityAttributeFilter
                                        emptyValue={filters.sourceEntityAttributeValueItems.emptyValue}
                                        entityAttributeValues={filters.sourceEntityAttributeValueItems.items}
                                        placeholder={localization.columns.sourceEntityAttributes()}/>}
                            </DeferredFilter>
                    }
                }}
                id={Contract.AccessControllerGetAwsFederationEdgeModelPageRequestProperty.SourceEntityAttributes}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AwsFederationEdgeModel>) =>
                        <EntityAttributesCell
                            entityAttributes={item.sourceEntity.attributes.attributes}
                            entityTypeName={item.sourceEntity.typeName}/>}
                sortOptions={{ enabled: false }}
                title={localization.columns.sourceEntityAttributes()}/>
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsFederationEdgeModel) =>
                            getEntityAttributesCsvItem(
                                item.destinationEntity.attributes.attributes,
                                localization.columns.destinationEntityAttributes())
                }}
                filterOptions={{
                    itemOrItems: {
                        default: true,
                        element:
                            <DeferredFilter
                                promiseOrGetPromise={filtersPromise}
                                title={localization.columns.destinationEntityAttributes()}>
                                {filters =>
                                    <EntityAttributeFilter
                                        emptyValue={filters.destinationEntityAttributeValueItems.emptyValue}
                                        entityAttributeValues={filters.destinationEntityAttributeValueItems.items}
                                        placeholder={localization.columns.destinationEntityAttributes()}/>}
                            </DeferredFilter>
                    }
                }}
                id={Contract.AccessControllerGetAwsFederationEdgeModelPageRequestProperty.DestinationEntityAttributes}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AwsFederationEdgeModel>) =>
                        <EntityAttributesCell
                            entityAttributes={item.destinationEntity.attributes.attributes}
                            entityTypeName={Contract.TypeNames.AwsIamRole}/>}
                sortOptions={{ enabled: false }}
                title={localization.columns.destinationEntityAttributes()}/>
        </DataTable>);
}

type ExportData = {
    relatedEntityModelMap: Dictionary<Contract.EntityModel>;
};

type ColumnExportData = {
    relatedEntityIds: string[];
};