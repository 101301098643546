import { Link, Shadows } from "@infrastructure";
import { Box, Stack } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { ReactNode, useState } from "react";
import { SecurityStatsDistributionChart } from "..";
import { Contract, TypeHelper, useSeverityTranslator, useTheme } from "../../../../../../../../../../common";
import { ColumnGrid } from "./components";

type DistributionChartGridProps = {
    activeItemId?: string;
    itemHeight: number;
    items: DistributionChartGridItem[];
    onActiveItemIdChanged?: (itemId?: string) => void;
    report: boolean;
};

export class DistributionChartGridItem {
    public securityStats: Contract.SecurityStats;

    constructor(
        public id: string,
        public getUrl: (severity?: Contract.Severity) => string,
        public render: (highlight: boolean) => ReactNode,
        public severityToCountMap: Dictionary<number>,
        public title: string) {
        this.securityStats =
            new Contract.SecurityStats(
                _(severityToCountMap).
                    values().
                    sum(),
                0,
                0,
                severityToCountMap);
    }
}

export function DistributionChartGrid({ activeItemId, itemHeight, items, onActiveItemIdChanged, report }: DistributionChartGridProps) {
    const [controlledActiveItemId, setControlledActiveItemId] = useState<string>();
    const theme = useTheme();
    return (
        <ColumnGrid itemHeight={itemHeight}>
            {_.map(
                items,
                item =>
                    <Box
                        key={item.id}
                        sx={{ height: theme.px(itemHeight) }}
                        {..._.isNil(onActiveItemIdChanged)
                            ? {
                                onMouseEnter: () => setControlledActiveItemId(item.id),
                                onMouseLeave: () => setControlledActiveItemId(undefined)
                            }
                            : {
                                onMouseEnter: () => onActiveItemIdChanged(item.id),
                                onMouseLeave: () => onActiveItemIdChanged(undefined)
                            }}>
                        <ItemStats
                            highlight={item.id === (activeItemId ?? controlledActiveItemId)}
                            item={item}
                            report={report}/>
                    </Box>)}
        </ColumnGrid>);
}

type ItemStatsProps = {
    highlight: boolean;
    item: DistributionChartGridItem;
    report: boolean;
};

function ItemStats({ highlight, item, report }: ItemStatsProps) {
    const severityTranslator = useSeverityTranslator();
    const theme = useTheme();
    return (
        <Link
            disabled={report}
            urlOrGetUrl={item.getUrl()}
            variant="text">
            <Box
                sx={{
                    borderRadius: theme.spacing(0.75),
                    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 1ms",
                    width: theme.px(140),
                    ...(highlight && {
                        background: theme.palette.background.alternate,
                        border: theme.border.alternate,
                        boxShadow: theme.shadows[Shadows.Tooltip],
                        zIndex: theme.zIndex.tooltip
                    })
                }}>
                <Stack
                    alignItems="center"
                    sx={{ paddingTop: theme.spacing(1) }}>
                    <SecurityStatsDistributionChart
                        disabled={!highlight && !report}
                        getUrl={
                            report
                                ? undefined
                                : severity => item.getUrl(TypeHelper.tryParseEnum<Contract.Severity>(Contract.TypeNames.Severity, severity))}
                        securityStats={item.securityStats}
                        size="small"
                        style={{ cursor: "pointer" }}
                        title={item.title}
                        variant="donut"
                        onTooltip={(_, severity) => severityTranslator(severity!)}/>
                    {item.render(highlight || report)}
                </Stack>
            </Box>
        </Link>);
}