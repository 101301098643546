﻿import { map } from "@infrastructure";
import { Contract } from "../../../../../../../../../../../../../../../common";
import { GcpConsoleUrlBuilder } from "../../../../../../../../../../../../../../../tenants";
import { EntityExternalConsoleLinkDefinition } from "../useDefinition";

export function useGcpAppEngineApplicationServiceDefinition(entityModel: Contract.EntityModel, page: Contract.GcpConsolePage) {
    const getUrl =
        map(
            page,
            {
                [Contract.GcpConsolePage.Permissions]: () => undefined,
                [Contract.GcpConsolePage.Versions]: () => GcpConsoleUrlBuilder.GetAppEngineApplicationServiceUrl
            });

    return new EntityExternalConsoleLinkDefinition(
        getUrl?.(
            (entityModel.entity as Contract.GcpAppEngineApplicationService).applicationName,
            (entityModel.entity as Contract.GcpAppEngineApplicationService).name));
}