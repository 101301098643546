﻿import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useAzureContainerServiceManagedClusterAuthenticationAndAuthorizationModeTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useAzureContainerServiceManagedClusterAuthenticationAndAuthorizationModeTranslator",
            () => ({
                [Contract.TypeNames.AzureContainerServiceManagedClusterAuthenticationAndAuthorizationMode]: {
                    [Contract.AzureContainerServiceManagedClusterAuthenticationAndAuthorizationMode.AuthenticationDisabled]: "No Authentication",
                    [Contract.AzureContainerServiceManagedClusterAuthenticationAndAuthorizationMode.IdentityAndKubernetesRoleBinding]: "Microsoft Entra ID with Kubernetes RBAC",
                    [Contract.AzureContainerServiceManagedClusterAuthenticationAndAuthorizationMode.IdentityAndRoleAssignment]: "Microsoft Entra ID with Azure RBAC",
                    [Contract.AzureContainerServiceManagedClusterAuthenticationAndAuthorizationMode.KubernetesUserAndKubernetesRoleBinding]: "Local accounts with Kubernetes RBAC",
                    [Contract.AzureContainerServiceManagedClusterAuthenticationAndAuthorizationMode.KubernetesUserAndUnrestrictedAccess]: "No RBAC"
                }
            }));
    return (authenticationAndAuthorizationMode: Contract.AzureContainerServiceManagedClusterAuthenticationAndAuthorizationMode) =>
        localization[Contract.TypeNames.AzureContainerServiceManagedClusterAuthenticationAndAuthorizationMode][authenticationAndAuthorizationMode]();
}