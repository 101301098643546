import { useLocalization, useLocalizeList } from "@infrastructure";
import _ from "lodash";
import { AuditEventDefinition, AuditEventDefinitionContext, AuditEventDefinitionDetailItem } from "..";
import { Contract } from "../../../../../../../../../common";

export function useDatadogOrganizationAuditEventDefinition(context: AuditEventDefinitionContext) {
    const localizeList = useLocalizeList();
    const localization =
        useLocalization(
            "views.customer.auditEvents.profile.hooks.useDefinition.hooks.useDatadogOrganizationAuditEventDefinition",
            () => ({
                datadogOrganizationApiKey: "API Key",
                datadogOrganizationName: "Name",
                datadogOrganizationSite: "Site",
                propertyChanges: "Updated properties"
            }));

    const datadogOrganizationAuditEvent = context.auditEventModel.auditEvent as Contract.DatadogOrganizationAuditEvent;
    return new AuditEventDefinition(
        _<AuditEventDefinitionDetailItem>([]).
            concat(
                new AuditEventDefinitionDetailItem(
                    localization.datadogOrganizationName(),
                    datadogOrganizationAuditEvent.datadogOrganizationName),
                new AuditEventDefinitionDetailItem(
                    localization.datadogOrganizationSite(),
                    datadogOrganizationAuditEvent.datadogOrganizationSite)).
            concatIf(
                datadogOrganizationAuditEvent.typeName === Contract.TypeNames.DatadogOrganizationUpdateAuditEvent,
                () => {
                    const properties =
                        _.filter([
                            (datadogOrganizationAuditEvent as Contract.DatadogOrganizationUpdateAuditEvent).datadogOrganizationNameChanged
                                ? localization.datadogOrganizationName()
                                : undefined,
                            (datadogOrganizationAuditEvent as Contract.DatadogOrganizationUpdateAuditEvent).datadogOrganizationSiteChanged
                                ? localization.datadogOrganizationSite()
                                : undefined,
                            (datadogOrganizationAuditEvent as Contract.DatadogOrganizationUpdateAuditEvent).datadogOrganizationApiKeyChanged
                                ? localization.datadogOrganizationApiKey()
                                : undefined
                        ]);
                    return (
                        _<AuditEventDefinitionDetailItem>([]).
                            concatIf(
                                !_.isEmpty(properties),
                                new AuditEventDefinitionDetailItem(
                                    localization.propertyChanges(),
                                    localizeList(properties)!)).
                            value());
                }).
            value());
}