import _, { Function0 } from "lodash";
import React, { useCallback, useMemo } from "react";
import { useLocalization } from "@infrastructure";
import { EntitiesWidgetHelper, EntityCategoryData, WidgetDefinition } from "../../../../../../..";
import { SummaryDashboardContext, useDashboardContext } from "../../../../../Dashboard";
import { Entities } from "../components";

export function useGetEntitiesDefinition(): () => WidgetDefinition {
    const localization =
        useLocalization(
            "common.dashboard.widget.hooks.useDefinition.hooks.useGetEntitiesDefinition",
            () => ({
                title: "Resources"
            }));

    return useCallback<Function0<WidgetDefinition>>(
        () => ({
            element: <Core/>,
            options: { title: localization.title() }
        }),
        [localization]);
}

function Core() {
    const { entityTypeEntitiesViewNameToCountMap } = useDashboardContext<SummaryDashboardContext>();

    const entityTypeCategoryToCount =
        useMemo(
            () => EntitiesWidgetHelper.getEntityTypeCategoryToCountMap(entityTypeEntitiesViewNameToCountMap),
            [entityTypeEntitiesViewNameToCountMap]);

    const entityTypeCategoryToEntityCategoryDataMap =
        _.mapValues(
            entityTypeCategoryToCount,
            entityTypeEntitiesCount => new EntityCategoryData(entityTypeEntitiesCount));

    return (
        <Entities
            entityTypeCategoryToEntityCategoryDataMap={entityTypeCategoryToEntityCategoryDataMap}
            entityTypeEntitiesViewNameToCountMap={entityTypeEntitiesViewNameToCountMap}/>);
}