import { Divider, Stack, Typography } from "@mui/material";
import React from "react";
import { Contract, useTheme } from "../../../../../../../common";
import { CodeResourceItemDefinition } from "../useDefinition";

export function useTerraformDefinition(codeBlock: Contract.CodeBlock) {
    const terraformBlock = codeBlock as Contract.TerraformBlock;

    const theme = useTheme();
    return new CodeResourceItemDefinition(
        <Stack
            alignItems="center"
            direction="row"
            sx={{ maxWidth: theme.spacing(57) }}>
            <Typography
                noWrap={true}
                sx={{ fontWeight: 600 }}>
                {terraformBlock.typeSystemName}
            </Typography>
            <Divider
                flexItem={true}
                orientation="vertical"
                sx={{ margin: theme.spacing(0, 1) }}/>
            <Typography noWrap={true}>
                {terraformBlock.name}
            </Typography>
        </Stack>);
}