import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { useCommonCustomSectionsAndDescriptionDefinition } from "../../..";
import { Contract, EntitiesCell, Entity, EntityFilter, entityModelStore, InlineEntities, usePrincipalModelAccess, useTableDefinition } from "../../../../../../../../../../../../common";
import { Table } from "../../../../components";
import { RiskContentProps } from "../../../../useCloudDefinition";

export function useAzurePrincipalNotAllowedResourceRoleDefinitionRiskDefinition(riskModel: Contract.RiskModel) {
    const principalNotAllowedResourceRoleDefinitionRiskModel = riskModel as Contract.AzurePrincipalNotAllowedResourceRoleDefinitionRiskModel;
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.custom.useAzurePrincipalNotAllowedResourceRoleDefinitionRiskDefinition",
            () => ({
                violation: "{{principal}} has {{roleAssignmentResources}} on {{scopeResources}}"
            }));
    return useCommonCustomSectionsAndDescriptionDefinition(
        localization.violation({
            principal:
                <Entity
                    entityIdOrModel={(principalNotAllowedResourceRoleDefinitionRiskModel.risk as Contract.AzurePrincipalNotAllowedResourceRoleDefinitionRisk).entityId}
                    variant="typeText"/>,
            roleAssignmentResources:
                <InlineEntities
                    entityIdsOrModels={principalNotAllowedResourceRoleDefinitionRiskModel.roleAssignmentResourceIds}
                    entityTypeName={Contract.TypeNames.AzureAuthorizationRoleAssignmentResource}
                    variant="itemCountAndType"/>,
            scopeResources:
                <InlineEntities
                    entityIdsOrModels={principalNotAllowedResourceRoleDefinitionRiskModel.scopeResourceIds}
                    entityTypeName={Contract.TypeNames.AzureScopeResource}
                    variant="itemAndTypeOrItemCountAndType"/>

        }),
        riskModel,
        "violations",
        <ViolationTable riskModel={riskModel}/>);
}

function ViolationTable({ riskModel }: RiskContentProps) {
    const principalNotAllowedResourceRoleDefinitionRiskModel = riskModel as Contract.AzurePrincipalNotAllowedResourceRoleDefinitionRiskModel;
    const principalNotAllowedResourceRoleDefinitionRisk = riskModel.risk as Contract.AzurePrincipalNotAllowedResourceRoleDefinitionRisk;

    const principalModel = entityModelStore.useGet(principalNotAllowedResourceRoleDefinitionRisk.entityId);
    const principalModelAccess = usePrincipalModelAccess<Contract.PrincipalModelAccess>(principalModel.id);
    const originatorEntityModels =
        entityModelStore.useGet(
            principalModelAccess.typeName === Contract.TypeNames.ServiceIdentityModelAccess
                ? (principalModelAccess as Contract.ServiceIdentityModelAccess).originatorEntityIds
                : []);
    const roleAssignmentResourceModels = entityModelStore.useGet(principalNotAllowedResourceRoleDefinitionRiskModel.roleAssignmentResourceIds);
    const scopeResourceModels = entityModelStore.useGet(principalNotAllowedResourceRoleDefinitionRiskModel.scopeResourceIds);
    const items =
        useMemo(
            () => {
                const roleAssignmentResourceModelMap =
                    _.keyBy(
                        roleAssignmentResourceModels,
                        roleAssignmentResourceModel => roleAssignmentResourceModel.id);
                const scopeResourceModelMap =
                    _.keyBy(
                        scopeResourceModels,
                        scopeResourceModel => scopeResourceModel.id);

                return _.map(
                    principalNotAllowedResourceRoleDefinitionRisk.resourceRoleDefinitionItems,
                    resourceRoleDefinitionItem =>
                        new ViolationTableItem(
                            _.map(
                                originatorEntityModels,
                                originatorEntityModel => originatorEntityModel.id),
                            originatorEntityModels,
                            principalModel,
                            resourceRoleDefinitionItem.roleAssignmentResourceIds,
                            _.map(
                                resourceRoleDefinitionItem.roleAssignmentResourceIds,
                                roleAssignmentResourceId => roleAssignmentResourceModelMap[roleAssignmentResourceId]),
                            scopeResourceModelMap[resourceRoleDefinitionItem.scopeResourceId]));
            },
            []);

    const tableDefinition =
        useTableDefinition(
            items,
            {
                [ViolationTableColumnId.Originators]: {
                    getFilterValue: item => item.originatorEntityIds,
                    getSortValue: item => item.originatorEntityIds.length
                },
                [ViolationTableColumnId.RoleAssignmentResources]: {
                    getFilterValue: item => item.roleAssignmentResourceIds,
                    getSortValue: item => item.roleAssignmentResourceIds.length
                },
                [ViolationTableColumnId.ScopeResource]: {
                    getFilterValue: item => item.scopeResourceModel.entity.id,
                    getSortValue: item => item.scopeResourceModel.entity.displayName
                }
            },
            ViolationTableColumnId.ScopeResource);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.custom.useAzurePrincipalNotAllowedResourceRoleDefinitionRiskDefinition.violationTable",
            () => ({
                columns: {
                    originators: "Originators",
                    principal: "Principal",
                    roleAssignmentResource: "Role Assignments",
                    scopeResource: "Resource"
                }
            }));

    return (
        <Table
            fetchItems={tableDefinition.filterAndSortItems}
            getItemId={(item: ViolationTableItem) => item.id}
            sortEnabled={true}>
            <DataTableColumn
                id={ViolationTableColumnId.Principal}
                render={
                    ({ item }: DataTableColumnRenderProps<ViolationTableItem>) =>
                        <Entity
                            entityIdOrModel={item.principalModel}
                            variant="iconTextTypeTenantTags"/>}
                sortOptions={{ enabled: false }}
                title={localization.columns.principal()}/>
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EntityFilter
                                entityIdsOrSearchableReferences={tableDefinition.columnIdToItemValuesMap[ViolationTableColumnId.Originators]}
                                placeholder={localization.columns.originators()}/>
                    }
                }}
                id={ViolationTableColumnId.Originators}
                render={
                    ({ item }: DataTableColumnRenderProps<ViolationTableItem>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.originatorEntityModels}
                            entityTypeName={Contract.TypeNames.AzureResource}
                            entityVariant="iconTextTypeTenant"/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.originators()}/>
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EntityFilter
                                entityIdsOrSearchableReferences={tableDefinition.columnIdToItemValuesMap[ViolationTableColumnId.RoleAssignmentResources]}
                                placeholder={localization.columns.roleAssignmentResource()}/>
                    }
                }}
                id={ViolationTableColumnId.RoleAssignmentResources}
                render={
                    ({ item }: DataTableColumnRenderProps<ViolationTableItem>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.roleAssignmentResourceModels}
                            entityTypeName={Contract.TypeNames.AzureAuthorizationRoleAssignmentResource}
                            entityVariant="iconTextTypeTenant"/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.roleAssignmentResource()}/>
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EntityFilter
                                entityIdsOrSearchableReferences={tableDefinition.columnIdToItemValuesMap[ViolationTableColumnId.ScopeResource]}
                                placeholder={localization.columns.scopeResource()}/>
                    }
                }}
                id={ViolationTableColumnId.ScopeResource}
                render={
                    ({ item }: DataTableColumnRenderProps<ViolationTableItem>) =>
                        <Entity
                            entityIdOrModel={item.scopeResourceModel}
                            variant="iconTextTypeTenantTags"/>}
                title={localization.columns.scopeResource()}/>
        </Table>);
}

enum ViolationTableColumnId {
    Originators = "originators",
    Principal = "principal",
    RoleAssignmentResources = "roleAssignmentResources",
    ScopeResource = "scopeResource"
}

class ViolationTableItem {
    public id: string;

    constructor(
        public originatorEntityIds: string[],
        public originatorEntityModels: Contract.EntityModel[],
        public principalModel: Contract.EntityModel,
        public roleAssignmentResourceIds: string[],
        public roleAssignmentResourceModels: Contract.EntityModel[],
        public scopeResourceModel: Contract.EntityModel) {
        this.id = `${principalModel.id}-${scopeResourceModel.id}`;
    }
}