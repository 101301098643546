import { DataTable, DataTableColumn, EmptyMessageText, Optional, useLocalization } from "@infrastructure";
import React from "react";
import { Contract } from "../../../../../../../../../../../common";

type FirewallRulesDetailsSectionProps = {
    firewallRules: Contract.AzureResourceFirewallIpAddressRangeRule[];
    getHighlightColor?: (firewallIpAddressRangeRule: Contract.AzureResourceFirewallIpAddressRangeRule, opacity?: number) => Optional<string>;
};

export function FirewallRulesDetailsSection({ firewallRules, getHighlightColor }: FirewallRulesDetailsSectionProps) {
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.firewallRulesDetailsSection",
            () => ({
                columns: {
                    endIpAddress: "End IP",
                    name: "Name",
                    startIpAddress: "Start IP"
                },
                empty: "No Rules",
                title: "Details"
            }));

    return (
        <DataTable
            emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty()) }}
            fetchItems={() => firewallRules}
            getItemId={(item: Contract.AzureResourceFirewallIpAddressRangeRule) => item.name}
            rowOptions={{
                getHighlightColor: (item: Contract.AzureResourceFirewallIpAddressRangeRule) => getHighlightColor?.(item),
                getSx:
                    (item: Contract.AzureResourceFirewallIpAddressRangeRule) =>
                        ({ backgroundColor: getHighlightColor?.(item, 0.1) })
            }}
            sortOptions={{ enabled: false }}
            variant="card">
            <DataTableColumn
                id={FirewallRulesInfoCardTableColumnId.Name}
                itemProperty={(firewallRule: Contract.AzureResourceFirewallIpAddressRangeRule) => firewallRule.name}
                title={localization.columns.name()}/>
            <DataTableColumn
                id={FirewallRulesInfoCardTableColumnId.StartIpAddress}
                itemProperty={(firewallRule: Contract.AzureResourceFirewallIpAddressRangeRule) => firewallRule.startIpAddress}
                title={localization.columns.startIpAddress()}/>
            <DataTableColumn
                id={FirewallRulesInfoCardTableColumnId.EndIpAddress}
                itemProperty={(firewallRule: Contract.AzureResourceFirewallIpAddressRangeRule) => firewallRule.endIpAddress}
                title={localization.columns.endIpAddress()}/>
        </DataTable>);
}

enum FirewallRulesInfoCardTableColumnId {
    EndIpAddress = "endIpAddress",
    Name = "name",
    StartIpAddress = "startIpAddress"
}