import { useLocalization } from "@infrastructure";
import _ from "lodash";
import { useMemo } from "react";
import { Contract, TenantHelper, tenantModelStore } from "..";

export function useTenantNameTranslator() {
    const tenantModels = tenantModelStore.useGetAll();

    const [tenantIdToNameMap, tenantModelMap] =
        useMemo(
            () => {
                const tenantModelMap =
                    _.keyBy(
                        tenantModels,
                        tenantModel => tenantModel.configuration.id);
                const tenantIdToNameMap =
                    _(tenantModelMap).
                        mapValues(tenantModel => tenantModel.configuration.name).
                        assign(
                            _.mapValues(
                                TenantHelper.commonTenantIdToDataMap,
                                commonTenantData => commonTenantData.name)).
                        value();
                return [tenantIdToNameMap, tenantModelMap];
            },
            [tenantModels]);

    const localization =
        useLocalization(
            "common.hooks.useTenantNameTranslator",
            () => ({
                titleWithRawId: "{{tenantName}} ({{displayReference}})"
            }));

    return useMemo(
        () =>
            (tenantId: string, options?: TenantNameTranslatorOptions) => {
                if (_.isNil(tenantIdToNameMap[tenantId])) {
                    return tenantId;
                }

                const tenantName =
                    options?.truncate
                        ? _.truncate(tenantIdToNameMap[tenantId], { length: 24 })
                        : tenantIdToNameMap[tenantId];

                const includeRawId =
                    !TenantHelper.isCommonTenant(tenantId) &&
                    tenantModelMap[tenantId].tenantType !== Contract.TenantType.Okta &&
                    tenantModelMap[tenantId].tenantType !== Contract.TenantType.OneLogin &&
                    tenantModelMap[tenantId].tenantType !== Contract.TenantType.Op &&
                    tenantModelMap[tenantId].tenantType !== Contract.TenantType.PingIdentity &&
                    (options?.includeRawId === true ||
                        options?.includeRawId !== false && tenantModelMap[tenantId].tenantType === Contract.TenantType.Gcp);

                return includeRawId
                    ? localization.titleWithRawId({
                        displayReference: tenantModelMap[tenantId].configuration.displayReference,
                        tenantName
                    })
                    : tenantName;
            },
        [tenantModels]);
}

export type TenantNameTranslatorOptions = {
    includeRawId?: boolean;
    truncate?: boolean;
};