import { useLocalization } from "@infrastructure";
import { Button, CircularProgress, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { Contract, PendingPermissionRequestIcon, PermissionManagementHelper, PermissionManagementTenantType, useTenantTypeTranslator, useTheme } from "../../../../../../../common";

type PendingActivationProps = {
    onClose: () => Promise<void>;
    tenantType: PermissionManagementTenantType;
};

export function PendingActivation({ onClose, tenantType }: PendingActivationProps) {
    const [executing, setExecuting] = useState(false);

    async function close() {
        setExecuting(true);
        await onClose();
        setExecuting(false);
    }

    const localization =
        useLocalization(
            "views.user.granteeUserPermissionRequests.add.pendingActivation",
            () => ({
                actions: {
                    close: "Close"
                },
                disclaimer: "It may take up to 10 minutes for {{translatedTenantType}} to apply the access",
                subtitle: "Once your access is granted, we will send you an email notification.",
                title: "Your access request is pending activation"
            }));
    const tenantTypeTranslator = useTenantTypeTranslator();

    const theme = useTheme();
    return (
        <Stack spacing={2}>
            <Stack
                alignItems="center"
                spacing={2}>
                <PendingPermissionRequestIcon
                    sx={{
                        color: PermissionManagementHelper.pendingPermissionRequestColor,
                        fontSize: "72px"
                    }}/>
                <Typography
                    sx={{
                        textAlign: "center",
                        width: theme.spacing(39)
                    }}
                    variant="h1">
                    {localization.title()}
                </Typography>
                <Typography
                    sx={{
                        fontSize: "14px",
                        textAlign: "center",
                        width: theme.spacing(42)
                    }}>
                    {localization.subtitle()}
                </Typography>
                {(tenantType === Contract.TenantType.Azure ||
                        tenantType === Contract.TenantType.Gcp) &&
                    <Typography
                        sx={{
                            fontSize: "12px",
                            textAlign: "center",
                            width: theme.spacing(50)
                        }}>
                        {localization.disclaimer({ translatedTenantType: tenantTypeTranslator(tenantType) })}
                    </Typography>}
            </Stack>
            <Stack
                alignItems="center"
                spacing={1}>
                <Button
                    disabled={executing}
                    onClick={() => close()}>
                    {localization.actions.close()}
                </Button>
                {executing &&
                    <CircularProgress
                        size={theme.spacing(3)}
                        variant="indeterminate"/>}
            </Stack>
        </Stack>);
}