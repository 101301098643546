import { NodeContentContextMenuItem, useLocalization } from "@infrastructure";
import _ from "lodash";
import { ReactNode } from "react";

export function useNodeContentFilterContextMenuItems(
    count: number,
    translatedValues: ReactNode,
    filter?: (type: "by" | "except") => void) {
    const localization =
        useLocalization(
            "common.hooks.useNodeContentFilterContextMenuItems",
            () => ({
                menu: {
                    filter: {
                        exclude: [
                            "Filter out **{{translatedValues}}**",
                            "Filter out these **{{translatedValues}}**"
                        ],
                        include: [
                            "Filter only **{{translatedValues}}**",
                            "Filter only these **{{translatedValues}}**"
                        ]
                    }
                }
            }));
    return _.isNil(filter)
        ? []
        : [
            new NodeContentContextMenuItem(
                () => filter("by"),
                localization.menu.filter.include(count, { translatedValues })),
            new NodeContentContextMenuItem(
                () => filter("except"),
                localization.menu.filter.exclude(count, { translatedValues }))
        ];
}