﻿import { TimeSpanFormatter, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useGcpCommonEncryptedResourceInfoElements, useGcpCommonLoggingLogSinkDestinationResourceInfoItemElements, useGcpDefaultResourceInfoItemElements, useGcpDefaultScopeResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../..";
import { Contract, InfoItem } from "../../../../../../../../../../common";
import { EntitiesInfoCard, EntitiesInfoItem, Info } from "../../../../components";
import { GcpScopeResourceRoleBindings } from "../../components";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";

export function useGcpPubSubTopicDefinition(scopeResourceModel: Contract.GcpScopeResourceModel, options?: DefinitionOptions) {
    const commonEncryptedResourceInfoElements = useGcpCommonEncryptedResourceInfoElements(scopeResourceModel);
    const commonLoggingLogSinkDestinationResourceInfoItemElements = useGcpCommonLoggingLogSinkDestinationResourceInfoItemElements(scopeResourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(scopeResourceModel);
    const defaultPropertyInfoElements = useGcpDefaultResourceInfoItemElements(scopeResourceModel);
    const defaultScopeResourceInfoItemElements = useGcpDefaultScopeResourceInfoItemElements(scopeResourceModel);
    const topicModel = scopeResourceModel as Contract.GcpPubSubTopicModel;
    const topic = topicModel.entity as Contract.GcpPubSubTopic;

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpPubSubTopicDefinition",
            () => ({
                info: {
                    cards: {
                        subscriptions: "Subscriptions"
                    },
                    items: {
                        messageRetentionDuration: "Retention duration",
                        triggerFunctionIds: "Trigger Function"
                    }
                },
                tabs: {
                    resourceRoleBindings: "Resource Role Bindings"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <GcpScopeResourceRoleBindings
                    csvExportFilePrefix={localization.tabs.resourceRoleBindings()}
                    scopeResourceModel={scopeResourceModel}/>,
                localization.tabs.resourceRoleBindings(),
                "resourceRoleBindings")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultPropertyInfoElements}
                entityPropertyInfoItemElements={[
                    ...defaultScopeResourceInfoItemElements,
                    commonEncryptedResourceInfoElements.encryptionExists,
                    commonEncryptedResourceInfoElements.kmsEncryptionKeys,
                    commonLoggingLogSinkDestinationResourceInfoItemElements.loggingLogSinks,
                    <InfoItem
                        key="topicMessageRetentionDuration"
                        title={localization.info.items.messageRetentionDuration()}
                        value={
                            _.isNil(topic.messageRetentionDuration)
                                ? undefined
                                : TimeSpanFormatter.humanizeDays(topic.messageRetentionDuration)}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={topicModel.triggerFunctionIds}
                        entityTypeName={Contract.TypeNames.GcpFunctionsFunction}
                        key="triggerFunctionIds"
                        title={localization.info.items.triggerFunctionIds()}/>
                ]}
                options={options?.infoOptions}>
                <EntitiesInfoCard
                    entityIdsOrModels={topicModel.subscriptionIds}
                    entityTypeName={Contract.TypeNames.GcpPubSubSubscription}
                    title={localization.info.cards.subscriptions()}/>
            </Info>
    });
}