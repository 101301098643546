import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, VerticalTopItemsInfoItem } from "../../../../../../../../../../common";
import { EntitiesInfoItem } from "../../../../components";
import { useGcpCommonResourceInfoItemElements } from "./useGcpCommonResourceInfoItemElements";

export function useGcpCommonKubernetesResourceInfoItemElements(resourceModel: Contract.GcpResourceModel) {
    const commonResourceInfoElements = useGcpCommonResourceInfoItemElements(resourceModel);
    const resource = resourceModel.entity as Contract.GcpKubernetesResourceBase;
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpCommonKubernetesResourceInfoItemElements",
            () => ({
                annotations: "Annotations",
                cluster: "Cluster",
                tags: "Kubernetes Labels"
            }));

    return {
        ...commonResourceInfoElements,
        annotationsInfoItemElement:
            <VerticalTopItemsInfoItem
                items={
                    _(resource.data.annotationKeyToValueMap).
                        map((annotationValue, annotationKey) => `${annotationKey}: ${annotationValue}`).
                        value()}
                key="annotations"
                location="all"
                title={localization.annotations()}/>,
        clusterInfoItemElement:
            <EntitiesInfoItem
                entityIdsOrModels={resource.clusterId}
                entityTypeName={Contract.TypeNames.GcpContainerCluster}
                key="clusterId"
                location="all"
                title={localization.cluster()}/>
    };
}