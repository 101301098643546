import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function GenerateEntityNonexcessivePermitterIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                clipRule="evenodd"
                d="M18.618 17.136c1.1-1.781 1.746-3.881 1.746-6.045V5.636L12.182 2 4 5.636v5.455C4 16.136 7.49 20.855 12.182 22c2.09-.51 3.936-1.727 5.345-3.373 0 0 .037 0 .564-.718.547-.746.527-.773.527-.773zm-1.834-.836l.132-.214c.919-1.488 1.448-3.227 1.448-4.995V6.936L12.182 4.19 6 6.936v4.155c0 4.026 2.692 7.728 6.183 8.833 1.45-.458 2.77-1.365 3.825-2.598l.167-.194a21.53 21.53 0 00.609-.832zm-.713.962l.005-.006-.005.006z"
                fillRule="evenodd"/>
        </SvgIcon>);
}