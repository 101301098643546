﻿import { EmptyMessageText, ItemSelector, Loading, TimeFormatter, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import { Contract, entityModelStore, InfoCard, InfoItem, KeyValueTable, KeyValueTableItem } from "../../../../../../../../../../../../common";
import { useGcpAppEngineApplicationResourceStatusTranslator } from "../../../../../../../../hooks";
import { VulnerabilitiesCell } from "../../../../../../../VulnerabilitiesCell";
import { EntitiesInfoItem } from "../../../../../../components";

type RevisionsProps = {
    revisionIds: string[];
};

export function Revisions({ revisionIds }: RevisionsProps) {
    const revisionModels = entityModelStore.useGet(revisionIds) as Contract.GcpAppEngineApplicationServiceRevisionModel[];
    const revisionModelMap =
        _.keyBy(
            revisionModels,
            revisionModel => revisionModel.id);

    const latestRevisionId =
        useMemo(
            () =>
                _.maxBy(
                    revisionModels,
                    revisionModel => revisionModel.creationTime)!.id,
            [revisionModels]);

    const [selectedRevisionModel, setSelectedRevisionModel] = useState(revisionModels[0]);

    const appEngineApplicationResourceStatusTranslator = useGcpAppEngineApplicationResourceStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpAppEngineApplicationServiceDefinition.revisions",
            () => ({
                items: {
                    containerImage: "Container Image",
                    containerImageVulnerabilities: "Vulnerabilities",
                    creationTime: "Creation Time",
                    creatorUserIdReference: "Created By",
                    environment: "Environment",
                    environmentVariables: {
                        columns: {
                            key: "Key",
                            value: "Value"
                        },
                        empty: "No Environment Variables",
                        title: "Environment Variables"
                    },
                    instanceClass: "Instance Class",
                    instanceCount: "Instances",
                    rawId: "Id",
                    runtime: "Runtime",
                    scaling: "Scaling",
                    securityLevel: "HTTPS Security",
                    serviceAccountIdReference: "Service Account",
                    status: "Status",
                    url: "URL"
                },
                latest: "Latest"
            }));

    const selectedRevision = selectedRevisionModel.entity as Contract.GcpAppEngineApplicationServiceRevision;
    return (
        <InfoCard>
            <ItemSelector
                dense={true}
                fullWidth={true}
                items={_.keys(revisionModelMap)}
                selectedItem={selectedRevision.id}
                sorted={false}
                onSelectedItemChanged={revisionId => setSelectedRevisionModel(revisionModelMap[revisionId])}>
                {revisionId =>
                    revisionId === latestRevisionId
                        ? localization.latest()
                        : revisionModelMap[revisionId].entity.displayName}
            </ItemSelector>
            <Loading>
                <InfoCard columns={true}>
                    <InfoItem
                        title={localization.items.rawId()}
                        value={selectedRevision.rawId}/>
                    <InfoItem
                        title={localization.items.url()}
                        value={selectedRevision.url}/>
                    <InfoItem
                        title={localization.items.status()}
                        value={appEngineApplicationResourceStatusTranslator(selectedRevision.status)}/>
                    <InfoItem
                        title={localization.items.creationTime()}
                        value={TimeFormatter.profileFormatDateTime(selectedRevisionModel.creationTime!)}/>
                    <EntitiesInfoItem
                        entityIdsOrModels={selectedRevisionModel.creatorUserIdReference}
                        entityTypeName={Contract.TypeNames.GciDirectoryUser}
                        title={localization.items.creatorUserIdReference()}/>
                    <EntitiesInfoItem
                        entityIdsOrModels={selectedRevisionModel.containerImageIdReferences}
                        entityTypeName={Contract.TypeNames.IContainerImage}
                        title={localization.items.containerImage()}/>
                    <InfoItem
                        key="containerImageVulnerabilities"
                        title={localization.items.containerImageVulnerabilities()}
                        value={
                            _.isEmpty(selectedRevisionModel.containerImageVulnerabilities)
                                ? undefined
                                : <VulnerabilitiesCell
                                    containerImageScanStatus={selectedRevisionModel.containerImageScanStatus}
                                    variant="initialAndCount"
                                    vulnerabilities={selectedRevisionModel.containerImageVulnerabilities}/>}/>
                    <InfoItem
                        title={localization.items.environment()}
                        value={selectedRevision.environment}/>
                    <InfoItem
                        title={localization.items.securityLevel()}
                        value={selectedRevision.securityLevel}/>
                    <InfoItem
                        title={localization.items.runtime()}
                        value={selectedRevision.runtime}/>
                    <InfoItem
                        title={localization.items.instanceCount()}
                        value={
                            _.isNil(selectedRevision.instanceCount)
                                ? undefined
                                : selectedRevision.instanceCount}/>
                    <InfoItem
                        title={localization.items.instanceClass()}
                        value={
                            _.isNil(selectedRevision.instanceClass)
                                ? undefined
                                : selectedRevision.instanceClass}/>
                    <EntitiesInfoItem
                        entityIdsOrModels={selectedRevisionModel?.serviceAccountIdReference}
                        entityTypeName={Contract.TypeNames.GcpIamServiceAccount}
                        title={localization.items.serviceAccountIdReference()}/>
                    <InfoItem
                        title={localization.items.scaling()}
                        value={selectedRevision.scaling}/>
                </InfoCard>
                <InfoCard title={localization.items.environmentVariables.title()}>
                    <KeyValueTable
                        emptyMessageText={new EmptyMessageText(localization.items.environmentVariables.empty())}
                        items={
                            _.map(
                                selectedRevision.environmentVariableNameToValueMap,
                                (value, key) =>
                                    new KeyValueTableItem(
                                        key,
                                        value))}/>
                </InfoCard>
            </Loading>
        </InfoCard>);
}