import { useLocalization } from "@infrastructure";
import { Contract } from "../../../common";

export function useOciObjectStorageBucketObjectVersioningTranslator() {
    const localization =
        useLocalization(
            "tenants.oci.hooks.useOciObjectStorageBucketObjectVersioningTranslator",
            () => ({
                [Contract.TypeNames.OciObjectStorageBucketObjectVersioning]: {
                    [Contract.OciObjectStorageBucketObjectVersioning.Disabled]: "Disabled",
                    [Contract.OciObjectStorageBucketObjectVersioning.Enabled]: "Enabled",
                    [Contract.OciObjectStorageBucketObjectVersioning.Suspended]: "Suspended"
                }
            }));
    return (objectVersioning: Contract.OciObjectStorageBucketObjectVersioning) =>
        localization[Contract.TypeNames.OciObjectStorageBucketObjectVersioning][objectVersioning]();
}