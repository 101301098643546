import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, VerticalTopItemsInfoItem } from "../../../../../../../../../../common";
import { EntitiesInfoItem } from "../../../../components";
import { useAzureCommonResourceInfoItemElements } from "./useAzureCommonResourceInfoItemElements";

export function useAzureCommonKubernetesResourceInfoItemElements(resourceModel: Contract.AzureResourceModel) {
    const commonResourceInfoElements = useAzureCommonResourceInfoItemElements(resourceModel);
    const resource = resourceModel.entity as Contract.AzureKubernetesResourceBase;
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.azure.useAzureCommonKubernetesResourceInfoItemElements",
            () => ({
                annotations: "Annotations",
                cluster: "Cluster",
                tags: "Kubernetes Labels"
            }));

    return {
        ...commonResourceInfoElements,
        annotationsInfoItemElement:
            <VerticalTopItemsInfoItem
                items={
                    _(resource.data.annotationKeyToValueMap).
                        map((annotationValue, annotationKey) => `${annotationKey}: ${annotationValue}`).
                        value()}
                key="annotations"
                location="all"
                title={localization.annotations()}/>,
        clusterInfoItemElement:
            <EntitiesInfoItem
                entityIdsOrModels={resource.clusterId}
                entityTypeName={Contract.TypeNames.AzureContainerServiceManagedCluster}
                key="clusterId"
                location="all"
                title={localization.cluster()}/>
    };
}