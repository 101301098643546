import { InlineItems, Link, Step, Steps, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { useCommonSectionsAndDescriptionDefinition, useGetAwsEcsTaskDefinitionRiskContext, useResourceSecretExistsRiskContext } from "../../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../../tenants";
import { RiskDefinitionSection } from "../../../../../../../utilities";
import { EnvironmentVariableSecretTable } from "./components";

export function useAwsEcsTaskDefinitionEnvironmentVariableSecretExistsRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsEcsTaskDefinitionEnvironmentVariableSecretExistsRisk;
    const taskDefinitionEnvironmentVariableSecretExistsRiskModel = riskModel as Contract.AwsEcsTaskDefinitionEnvironmentVariableSecretExistsRiskModel;
    const taskDefinitionModel = entityModelStore.useGet(risk.entityId) as Contract.AwsEcsTaskDefinitionModel;
    const taskDefinition = taskDefinitionModel.entity as Contract.AwsEcsTaskDefinition;

    const getAwsEcsTaskDefinitionRiskContext = useGetAwsEcsTaskDefinitionRiskContext();
    const secretContextItems = useResourceSecretExistsRiskContext();

    const [activeRevisionIds, inactiveRevisionIds, secretRevisionIds] =
        useMemo(
            () => {
                const secretRevisionIds =
                    _(risk.secretEnvironmentVariableNameToContainerDefinitionNameToRevisionIdsMap).
                        values().
                        flatMap(
                            containerDefinitionNameToRevisionIds =>
                                _(containerDefinitionNameToRevisionIds).
                                    values().
                                    flatMap().
                                    value()).
                        uniq().
                        value();

                const activeRevisionIds =
                    _.filter(
                        secretRevisionIds,
                        revisionId => taskDefinition.revisionMap[revisionId]?.status == Contract.AwsEcsTaskDefinitionStatus.Active);

                const inactiveRevisionIds =
                    _.filter(
                        secretRevisionIds,
                        revisionId => taskDefinition.revisionMap[revisionId]?.status == Contract.AwsEcsTaskDefinitionStatus.Inactive);

                return [activeRevisionIds, inactiveRevisionIds, secretRevisionIds];
            },
            [risk]);

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsEcsTaskDefinitionEnvironmentVariableSecretExistsRiskDefinition",
            () => ({
                description: "{{taskDefinition}} is using {{environmentVariableNames}} in {{revisions}} to store secrets in clear text",
                environmentVariableNames: [
                    "1 environment variable",
                    "{{count | NumberFormatter.humanize}} environment variables"
                ],
                revisions: {
                    active: [
                        "1 active task definition revision",
                        "{{count | NumberFormatter.humanize}} active task definition revisions"
                    ],
                    all: [
                        "1 task definition revision",
                        "{{count | NumberFormatter.humanize}} task definition revisions"
                    ],
                    inactive: [
                        "1 inactive task definition revision",
                        "{{count | NumberFormatter.humanize}} inactive task definition revisions"
                    ]
                },
                sections: {
                    environmentVariable: "Environment Variables",
                    resolution: {
                        step1: {
                            step1: "Select the old revision, and then click on **Actions > Deregister**",
                            step2: "Once the task definition is deregistered, change the filter to **Inactive task definitions**",
                            step3: "Select the old revision, and then click on **Actions > Delete**",
                            title: "Repeat the following steps for each of these {{revisions}}. (You can safely delete any inactive revisions)"
                        },
                        step2: {
                            step1: "Select the relevant revision and then click the **Create new revision** button",
                            step2: {
                                link: "instructions",
                                text: "For each container with environment variables exposing secrets, migrate your secrets to Secrets Manager using the following {{secretsManagerGuideLink}}"
                            },
                            step3: "Click **Create** to create the new revision",
                            step4: "Before deleting the old revision, update your clusters to use the newly created revision",
                            step5: "To delete the old task definition revision, select it and then click on **Actions > Deregister**",
                            step6: "Once the task definition is deregistered, change the filter to **Inactive task definitions**",
                            step7: "Select the old revision, and then click on **Actions > Delete**",
                            title: "Repeat the following steps for each of these {{revisions}}"
                        }
                    }
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            environmentVariableNames:
                <InlineItems
                    items={_.keys(risk.secretEnvironmentVariableNameToContainerDefinitionNameToRevisionIdsMap)}
                    namePluralizer={localization.environmentVariableNames}
                    variant="itemCountAndType"/>,
            revisions:
                <InlineItems
                    items={secretRevisionIds}
                    namePluralizer={localization.revisions.all}
                    variant="itemCountAndType"/>,
            taskDefinition:
                <Entity
                    entityIdOrModel={taskDefinitionModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.Ecs,
                AwsConsoleUrlBuilder.getEcsTaskDefinitionUrl(taskDefinition)),
            _.isEmpty(inactiveRevisionIds)
                ? undefined
                : new Step(
                    localization.sections.resolution.step1.title({
                        revisions:
                            <InlineItems
                                items={inactiveRevisionIds}
                                namePluralizer={localization.revisions.inactive}
                                variant="itemCountAndType"/>
                    }),
                    {
                        contentElement:
                            <Steps variant="plainNumbers">
                                {localization.sections.resolution.step1.step1()}
                                {localization.sections.resolution.step1.step2()}
                                {localization.sections.resolution.step1.step3()}
                            </Steps>
                    }),
            _.isEmpty(activeRevisionIds)
                ? undefined
                : new Step(
                    localization.sections.resolution.step2.title({
                        revisions:
                            <InlineItems
                                items={activeRevisionIds}
                                namePluralizer={localization.revisions.active}
                                variant="itemCountAndType"/>
                    }),
                    {
                        contentElement:
                            <Steps variant="plainNumbers">
                                {localization.sections.resolution.step2.step1()}
                                {localization.sections.resolution.step2.step2.text({
                                    secretsManagerGuideLink:
                                        <Link
                                            urlOrGetUrl={taskDefinitionEnvironmentVariableSecretExistsRiskModel.secretsManagerDocumentationUrl}
                                            variant="external">
                                            {localization.sections.resolution.step2.step2.link()}
                                        </Link>
                                })}
                                {localization.sections.resolution.step2.step3()}
                                {localization.sections.resolution.step2.step4()}
                                {localization.sections.resolution.step2.step5()}
                                {localization.sections.resolution.step2.step6()}
                                {localization.sections.resolution.step2.step7()}
                            </Steps>
                    })
        ],
        riskModel,
        () => {
            const taskDefinitionContextItems = getAwsEcsTaskDefinitionRiskContext(taskDefinitionModel);
            return [
                taskDefinitionContextItems.generalInformation,
                taskDefinitionContextItems.sensitive,
                secretContextItems.getSecretExistsContextItem(
                    risk.entityId,
                    Contract.TypeNames.AwsEcsTaskDefinition,
                    _(risk.secretEnvironmentVariableNameToContainerDefinitionNameToRevisionIdsMap).
                        keys().
                        value()),
                secretContextItems.getExcludedSecretsContextItem(
                    risk.excludedSecretEnvironmentVariableNames,
                    risk.typeName,
                    risk.tenantId),
                taskDefinitionContextItems.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        },
        {
            sections: [
                new RiskDefinitionSection(
                    <EnvironmentVariableSecretTable
                        risk={risk}
                        taskDefinition={taskDefinition}/>,
                    localization.sections.environmentVariable(),
                    {
                        expandable: true
                    })
            ]
        });
}