import { useMemo } from "react";
import { TimeSpanFormatter, TimeSpanHelper, useLocalization } from "@infrastructure";
import { useGetInheritedOverridableValue } from ".";
import { Contract, RiskPoliciesType, RiskPolicyItem, UserHelper } from "../../../../../common";

export function useEntityCreationTimeAnalysisDelayTimeFrame(riskPolicyItem: RiskPolicyItem, riskPolicyConfiguration: Contract.RiskPolicyConfiguration, riskPoliciesType = RiskPoliciesType.Cloud) {
    const userSecurityAdministrationRead =
        useMemo(
            () =>
                UserHelper.hasScopePermissions(
                    riskPolicyConfiguration.scopeId,
                    Contract.IdentityPermission.SecurityAdministrationRead),
            [riskPolicyConfiguration.scopeId]);

    const { inherited, inheritedScopeId, inheritedValue: inheritedEntityCreationTimeAnalysisDelayTimeFrame, value: entityCreationTimeAnalysisDelayTimeFrame } =
        useGetInheritedOverridableValue(
            riskPolicyConfiguration => riskPolicyConfiguration.entityCreationTimeAnalysisDelayTimeFrame,
            riskPoliciesType,
            riskPolicyConfiguration,
            riskPolicyItem);

    const [defaultCustomEntityCreationTimeAnalysisDelayTimeFrame, defaultSelectedEntityCreationTimeAnalysisDelayTimeFrame] =
        useMemo(
            () => {
                const defaultCustomEntityCreationTimeAnalysisDelayTimeFrame =
                    inherited ||
                    TimeSpanHelper.getDays(entityCreationTimeAnalysisDelayTimeFrame!) === 0
                        ? 1
                        : TimeSpanHelper.getDays(entityCreationTimeAnalysisDelayTimeFrame!);

                const defaultSelectedEntityCreationTimeAnalysisDelayTimeFrame =
                    inherited
                        ? undefined
                        : TimeSpanHelper.getDays(entityCreationTimeAnalysisDelayTimeFrame!);

                return [defaultCustomEntityCreationTimeAnalysisDelayTimeFrame, defaultSelectedEntityCreationTimeAnalysisDelayTimeFrame];
            },
            [entityCreationTimeAnalysisDelayTimeFrame, inherited]);

    const localization =
        useLocalization(
            "views.customer.riskPolicies.hooks.useEntityCreationTimeAnalysisDelayTimeFrame",
            () => ({
                zero: "Immediately"
            }));

    function getTranslatedDelayTimeFrame(entityCreationTimeAnalysisDelayTimeFrame: string) {
        const entityCreationTimeAnalysisDelayDays = TimeSpanHelper.getDays(entityCreationTimeAnalysisDelayTimeFrame);
        return entityCreationTimeAnalysisDelayDays === 0
            ? localization.zero()
            : TimeSpanFormatter.day(entityCreationTimeAnalysisDelayDays);
    }

    const inheritedTranslatedDelayTimeFrame =
        useMemo(
            () => getTranslatedDelayTimeFrame(inheritedEntityCreationTimeAnalysisDelayTimeFrame!),
            [inheritedEntityCreationTimeAnalysisDelayTimeFrame]);

    const translatedDelayTimeFrameValue =
        useMemo(
            () =>
                inherited
                    ? inheritedTranslatedDelayTimeFrame
                    : getTranslatedDelayTimeFrame(entityCreationTimeAnalysisDelayTimeFrame!),
            [entityCreationTimeAnalysisDelayTimeFrame, inheritedTranslatedDelayTimeFrame]);

    return useMemo(
        () => ({
            defaultCustomEntityCreationTimeAnalysisDelayTimeFrame,
            defaultSelectedEntityCreationTimeAnalysisDelayTimeFrame,
            inherited,
            inheritedScopeId,
            inheritedTranslatedDelayTimeFrame,
            translatedDelayTimeFrameValue,
            userSecurityAdministrationRead
        }),
        [defaultCustomEntityCreationTimeAnalysisDelayTimeFrame, defaultSelectedEntityCreationTimeAnalysisDelayTimeFrame, inherited, inheritedScopeId, inheritedTranslatedDelayTimeFrame, translatedDelayTimeFrameValue, userSecurityAdministrationRead]);
}