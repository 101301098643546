﻿import _, { Dictionary } from "lodash";
import React from "react";
import { DataTableColumn, DataTableColumnRenderProps, useLocalization } from "@infrastructure";
import { useAzureCommonKubernetesNamespaceResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";
import { useCommonKubernetesWorkloadResourceDefinition } from "../useCommonKubernetesWorkloadResourceDefinition";

export function useAzureKubernetesWorkloadResourceDefinition(definitionData: DefinitionData) {
    const commonKubernetesWorkloadResourceDefinition = useCommonKubernetesWorkloadResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();
    const { columns, initialFilterMap } = useAzureCommonKubernetesNamespaceResourceDefinition(definitionData);

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.azure.useAzureKubernetesWorkloadResourceDefinition",
            () => ({
                columns: {
                    aadServicePrincipalIds: "Microsoft Entra ID Application"
                }
            }));

    return new EntityTableDefinition(
        _.filter([
            columns.clusterColumn,
            columns.tenantColumn,
            commonKubernetesWorkloadResourceDefinition.columns.typeColumn,
            columns.creationTimeColumn,
            columns.namespaceColumn,
            commonKubernetesWorkloadResourceDefinition.columns.containerImagesColumn,
            commonKubernetesWorkloadResourceDefinition.columns.containerImageVulnerabilitiesColumn,
            commonKubernetesWorkloadResourceDefinition.columns.serviceAccountColumn,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.AzureKubernetesWorkloadResourceModel>(
                        Contract.TypeNames.AadDirectoryServicePrincipal,
                        item => item.aadServicePrincipalIds,
                        localization.columns.aadServicePrincipalIds())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AzureKubernetesWorkloadResourceAadServicePrincipals)}
                                placeholder={localization.columns.aadServicePrincipalIds()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AzureKubernetesWorkloadResourceAadServicePrincipals}
                key={Contract.EntityModelProperty.AzureKubernetesWorkloadResourceAadServicePrincipals}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AzureKubernetesWorkloadResourceModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.aadServicePrincipalIds}
                            entityTypeName={Contract.TypeNames.AadDirectoryServicePrincipal}
                            entityVariant="iconText"/>}
                title={localization.columns.aadServicePrincipalIds()}/>,
            columns.tagsColumn,
            columns.attributesColumn,
            columns.openRiskedEntityRisksCsvColumn,
            columns.openRelatedEntityRiskHighestSeverityColumn,
            columns.openRelatedEntityRiskTypeIdentifiersColumn
        ]),
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAzureKubernetesWorkloadResourceRequest(
                new Contract.EntityControllerGetEntityModelPageAzureKubernetesWorkloadResourceRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureKubernetesWorkloadResourceAadServicePrincipals]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.KubernetesWorkloadResourceContainerImages]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.KubernetesWorkloadResourceContainerImageVulnerabilities]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.KubernetesWorkloadContainerImageVulnerabilitySeverities]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.KubernetesWorkloadResourceControllerResource]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.KubernetesWorkloadResourceServiceAccount]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.KubernetesWorkloadResourceTypeName])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags])),
                new Contract.EntityControllerGetEntityModelPageEntityRequestKubernetesResource(ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.KubernetesResourceCluster])),
                new Contract.EntityControllerGetEntityModelPageEntityRequestKubernetesNamespaceResource(ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.KubernetesNamespaceResourceNamespace]))),
        {
            initialFilterMap
        });
}