﻿import { Link, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, CustomerConsoleAppUrlHelper, Entity, entityModelStore, useTheme } from "../../../../../../../../../../../../../common";
import { RiskDefinitionSection } from "../../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../../useCommonSectionsAndDescriptionDefinition";
import { EntityExternalConsoleLink } from "../../../components";
import { useGetGcpContainerClusterRiskContext } from "../../contexts";
import { AuthorizedNetworksSection } from "./components";

export function useGcpContainerClusterInboundExternalWideRangeRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.GcpContainerClusterInboundExternalWideRangeRisk;
    const clusterModel = entityModelStore.useGet(risk.entityId) as Contract.GcpContainerClusterModel;
    const cluster = clusterModel.entity as Contract.GcpContainerCluster;

    const getGcpContainerClusterRiskContext = useGetGcpContainerClusterRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.useGcpContainerClusterInboundExternalWideRangeRiskDefinition",
            () => ({
                description: "{{cluster}} authorized networks configuration allows wide inbound internet access to the Kubernetes API",
                sections: {
                    authorizedNetworks: "Control Plane Authorized Networks",
                    resolution: {
                        step1: "Under **Networking - Control plane authorized networks**, click the pencil button",
                        step2: "Check **Enable control plane authorized networks**",
                        step3: "Uncheck **Allow access through Google Cloud public IP addresses**",
                        step4: {
                            link: "Tenable Cloud Security's IP addresses",
                            text: "Edit the **Authorized Networks** to allow access only to specific IP addresses that require access to the Kubernetes API, including {{documentationApplicationIpAddressesLink}}"
                        },
                        step5: "Click **Save Changes** to confirm"
                    }
                }
            }));

    const theme = useTheme();
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            cluster:
                <Entity
                    entityIdOrModel={risk.entityId}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={risk.entityId}
                key={risk.entityId}
                page={Contract.GcpConsolePage.Resource}/>,
            localization.sections.resolution.step1(),
            _.isNil(cluster.network.authorizedSubnetDatas)
                ? localization.sections.resolution.step2()
                : undefined,
            cluster.network.inboundExternalGcpRange
                ? localization.sections.resolution.step3()
                : undefined,
            localization.sections.resolution.step4.text({
                documentationApplicationIpAddressesLink:
                    <Link
                        urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsArchitectureAndDataHandlingApplicationIpAddressesRelativeUrl()}
                        variant="external">
                        {localization.sections.resolution.step4.link()}
                    </Link>
            }),
            localization.sections.resolution.step5()
        ],
        riskModel,
        () => {
            const clusterContextItems = getGcpContainerClusterRiskContext(clusterModel);
            return [
                clusterContextItems.generalInformation,
                clusterContextItems.sensitive,
                clusterContextItems.vpc,
                clusterContextItems.mode,
                clusterContextItems.network,
                clusterContextItems.wideRangeAuthorizedSubnets,
                clusterContextItems.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        },
        {
            sections:
                _.isEmpty(clusterModel.wideRangeAuthorizedSubnetDatas)
                    ? undefined
                    : [
                        new RiskDefinitionSection(
                            <AuthorizedNetworksSection
                                authorizedSubnetDatas={cluster.network.authorizedSubnetDatas!}
                                getHighlightColor={
                                    (authorizedSubnetData, opacity) =>
                                        _.some(
                                            clusterModel.wideRangeAuthorizedSubnetDatas,
                                            wideRangeAuthorizedSubnetData => wideRangeAuthorizedSubnetData.subnet === authorizedSubnetData.subnet)
                                            ? opacity
                                                ? theme.palette.opacity(theme.palette.severity(risk.severity), opacity)
                                                : theme.palette.severity(risk.severity)
                                            : undefined
                                }/>,
                            localization.sections.authorizedNetworks())
                    ]
        });
}