import { StringHelper, UnexpectedError, useExecuteOperation } from "@infrastructure";
import _, { Dictionary } from "lodash";
import { useMemo } from "react";
import { Contract, EntityPropertyPatternHelper, RiskPoliciesType, RiskPolicyItem, RiskPolicyTypeMetadataHelper, useExclusionEntityPropertyPatternTranslator } from "../../../../../common";
import { EntityExclusionSection, EntityTypeNamePropertyPatternItem } from "../components/Configuration/components";
import { useRiskPoliciesItemConfiguration } from "./index";

export function useCloudRiskPolicyItemEntityExclusion(riskPolicyItem: RiskPolicyItem, scopeId: string, entityExclusionSection?: EntityExclusionSection) {
    const { parentRiskPolicyModels, scopeRiskPolicyModel, tenantRiskPolicyModels } = useRiskPoliciesItemConfiguration(RiskPoliciesType.Cloud, riskPolicyItem, scopeId);

    const [{ excludedEntityTypeNameToIdToScopeIdsMap, excludedEntityTypeNameToPropertyPatternKeyToScopeIdsMap, riskPolicyExclusionAnyScopeEntityTypeNames, riskPolicyExclusionEntityTypeNames, riskPolicyExclusionEntityTypeNameToPropertyPatternTypeNamesMap }] =
        useExecuteOperation(
            useCloudRiskPolicyItemEntityExclusion,
            async () => {
                const riskPolicyConfigurationTypeNames =
                    _<Contract.RiskPolicyModel>([]).
                        concat(parentRiskPolicyModels).
                        concat(scopeRiskPolicyModel).
                        concat(tenantRiskPolicyModels).
                        map(riskPolicyModel => riskPolicyModel.riskPolicyConfiguration.typeName).
                        uniq().
                        value();
                if (_.size(riskPolicyConfigurationTypeNames) !== 1) {
                    throw new UnexpectedError("riskPolicyConfigurationTypeNames", _.join(riskPolicyConfigurationTypeNames, " "));
                }

                const riskPolicyConfigurationTypeName = riskPolicyConfigurationTypeNames[0];
                const riskPolicyExclusionAnyScopeEntityTypeNames = RiskPolicyTypeMetadataHelper.getExclusionAnyScopeEntityTypeNames(riskPolicyConfigurationTypeName);
                const riskPolicyExclusionEntityTypeNameToPropertyPatternTypeNamesMap = RiskPolicyTypeMetadataHelper.getExclusionEntityTypeNameToPropertyPatternTypeNamesMap(riskPolicyConfigurationTypeName);
                const riskPolicyExclusionEntityTypeNames = RiskPolicyTypeMetadataHelper.getExclusionEntityTypeNames(riskPolicyConfigurationTypeName);

                const excludedEntityTypeNameToIdToScopeIdsMap =
                    _(parentRiskPolicyModels).
                        flatMap(
                            riskPolicyModel =>
                                _.flatMap(
                                    riskPolicyModel.riskPolicyConfiguration.entityTypeNameToExclusionsMap,
                                    (entityExclusion, entityTypeName) =>
                                        _.map(
                                            _.keys(entityExclusion.entityIdToDataMap),
                                            entityId => ({
                                                entityId,
                                                entityTypeName,
                                                scopeId: riskPolicyModel.riskPolicyConfiguration.scopeId
                                            })))).
                        groupBy(({ entityTypeName }) => entityTypeName).
                        mapValues(
                            exclusionEntityDatas =>
                                _(exclusionEntityDatas).
                                    groupBy(({ entityId }) => entityId).
                                    mapValues(
                                        exclusionEntityDatasGroup =>
                                            _.map(
                                                exclusionEntityDatasGroup,
                                                ({ scopeId }) => scopeId)).
                                    value()).
                        value();

                const excludedEntityTypeNameToPropertyPatternKeyToScopeIdsMap =
                    _(parentRiskPolicyModels).
                        flatMap(
                            riskPolicyModel =>
                                _.flatMap(
                                    riskPolicyModel.riskPolicyConfiguration.entityTypeNameToExclusionsMap,
                                    (entityExclusion, entityTypeName) =>
                                        _.map(
                                            entityExclusion.entityPropertyPatterns,
                                            entityPropertyPattern => ({
                                                entityPropertyPattern,
                                                entityTypeName,
                                                scopeId: riskPolicyModel.riskPolicyConfiguration.scopeId
                                            })))).
                        groupBy(({ entityTypeName }) => entityTypeName).
                        mapValues(
                            exclusionEntityDatas =>
                                _(exclusionEntityDatas).
                                    groupBy(({ entityPropertyPattern }) => EntityPropertyPatternHelper.getExclusionEntityPropertyPatternKey(entityPropertyPattern)).
                                    mapValues(
                                        excludedEntityPropertyPatternToDatasGroup =>
                                            _.map(
                                                excludedEntityPropertyPatternToDatasGroup,
                                                ({ scopeId }) => scopeId)).
                                    value()).
                        defaults(
                            _(riskPolicyExclusionEntityTypeNames).
                                keyBy().
                                mapValues(() => ({} as Dictionary<string[]>)).
                                value()).
                        value();

                return {
                    excludedEntityTypeNameToIdToScopeIdsMap,
                    excludedEntityTypeNameToPropertyPatternKeyToScopeIdsMap,
                    riskPolicyExclusionAnyScopeEntityTypeNames,
                    riskPolicyExclusionEntityTypeNames,
                    riskPolicyExclusionEntityTypeNameToPropertyPatternTypeNamesMap
                };
            },
            {
                persistenceStrategy: "updateOnMount"
            });

    const defaultExcludedEntityTypeNameToIdToExclusionMap =
        useMemo(
            () => (
                entityExclusionSection?.excludedEntityTypeNameToIdToExclusionMap ??
                _(tenantRiskPolicyModels).
                    map(
                        tenantRiskPolicyModel =>
                            _(tenantRiskPolicyModel.riskPolicyConfiguration.entityTypeNameToExclusionsMap).
                                pickBy(
                                    (_entityTypeExclusion, entityTypeName) =>
                                        !_.includes(
                                            riskPolicyExclusionAnyScopeEntityTypeNames,
                                            entityTypeName)).
                                mapValues(entityTypeExclusion => entityTypeExclusion!.entityIdToDataMap).
                                value()).
                    concat(
                        _.mapValues(
                            scopeRiskPolicyModel.riskPolicyConfiguration.entityTypeNameToExclusionsMap,
                            entityExclusions => entityExclusions.entityIdToDataMap)).
                    aggregate().
                    value() as Dictionary<Dictionary<Contract.RiskPolicyConfigurationExclusionData>>),
            [entityExclusionSection, tenantRiskPolicyModels]);

    const exclusionEntityPropertyPatternTranslator = useExclusionEntityPropertyPatternTranslator();

    const defaultEntityTypeNameToPropertyPatternItemsMap =
        useMemo(
            () =>
                entityExclusionSection?.entityTypeNameToPropertyPatternItemsMap ??
                _(parentRiskPolicyModels).
                    map(parentRiskPolicyModel => parentRiskPolicyModel.riskPolicyConfiguration.entityTypeNameToExclusionsMap).
                    concat(scopeRiskPolicyModel.riskPolicyConfiguration.entityTypeNameToExclusionsMap).
                    map(
                        entityTypeNameToExclusionsMap =>
                            _.mapValues(
                                entityTypeNameToExclusionsMap,
                                entityExclusions => entityExclusions.entityPropertyPatterns)).
                    aggregate(
                        (exclusionEntityPropertyPattern: Contract.RiskPolicyConfigurationEntityExclusionEntityPropertyPattern[], otherExclusionEntityPropertyPattern: Contract.RiskPolicyConfigurationEntityExclusionEntityPropertyPattern[]) =>
                            _(exclusionEntityPropertyPattern).
                                concat(otherExclusionEntityPropertyPattern).
                                filter().
                                value()).
                    mapValues(
                        (exclusionEntityPropertyPatterns, entityTypeName) =>
                            _(exclusionEntityPropertyPatterns).
                                map(
                                    exclusionEntityPropertyPattern =>
                                        new EntityTypeNamePropertyPatternItem(
                                            exclusionEntityPropertyPattern,
                                            false)).
                                uniqBy(exclusionEntityPropertyPatternItem => exclusionEntityPropertyPatternItem.key).
                                orderBy(
                                    [
                                        exclusionEntityPropertyPatternItem => _.isEmpty(excludedEntityTypeNameToPropertyPatternKeyToScopeIdsMap[entityTypeName]?.[exclusionEntityPropertyPatternItem.key]),
                                        exclusionEntityPropertyPatternItem => StringHelper.getSortValue(exclusionEntityPropertyPatternTranslator(exclusionEntityPropertyPatternItem.propertyPattern!.typeName))
                                    ],
                                    [
                                        "asc",
                                        "asc"
                                    ]).
                                value()).
                    defaults(
                        _(riskPolicyExclusionEntityTypeNames).
                            keyBy().
                            mapValues(() => [] as EntityTypeNamePropertyPatternItem[]).
                            value()).
                    value(),
            [entityExclusionSection, parentRiskPolicyModels]);

    const defaultEntityTypeNameToPropertyPatternValidMap =
        useMemo(
            () =>
                entityExclusionSection?.entityTypeNameToPropertyPatternValidMap ??
                _(riskPolicyExclusionEntityTypeNames).
                    keyBy().
                    mapValues(() => true).
                    value(),
            [entityExclusionSection, riskPolicyExclusionEntityTypeNames]);

    const defaultExclusionEntityTypeNameAndPropertyPatternValue =
        useMemo(
            () => {
                if (!_.isNil(entityExclusionSection?.exclusionEntityTypeNameAndPropertyPatternValue)) {
                    return entityExclusionSection!.exclusionEntityTypeNameAndPropertyPatternValue;
                }

                let entityTypeName =
                    _(defaultExcludedEntityTypeNameToIdToExclusionMap).
                        toPairs().
                        find(([, entityIdToMessageMap]) => !_.isEmpty(entityIdToMessageMap))?.[0];
                if (!_.isNil(entityTypeName)) {
                    return `${entityTypeName}-${false}`;
                }

                entityTypeName =
                    _(defaultEntityTypeNameToPropertyPatternItemsMap).
                        toPairs().
                        find(([, propertyPatternItems]) => !_.isEmpty(propertyPatternItems))?.[0];
                if (!_.isNil(entityTypeName)) {
                    return `${entityTypeName}-${true}`;
                }

                return `${riskPolicyExclusionEntityTypeNames[0]}-${false}`;
            },
            [entityExclusionSection, defaultExcludedEntityTypeNameToIdToExclusionMap]);


    return useMemo(
        () => ({
            defaultEntityTypeNameToPropertyPatternItemsMap,
            defaultEntityTypeNameToPropertyPatternValidMap,
            defaultExcludedEntityTypeNameToIdToExclusionMap,
            defaultExclusionEntityTypeNameAndPropertyPatternValue,
            excludedEntityTypeNameToIdToScopeIdsMap,
            excludedEntityTypeNameToPropertyPatternKeyToScopeIdsMap,
            riskPolicyExclusionAnyScopeEntityTypeNames,
            riskPolicyExclusionEntityTypeNames,
            riskPolicyExclusionEntityTypeNameToPropertyPatternTypeNamesMap
        }),
        [defaultEntityTypeNameToPropertyPatternItemsMap, defaultEntityTypeNameToPropertyPatternValidMap, defaultExcludedEntityTypeNameToIdToExclusionMap, defaultExclusionEntityTypeNameAndPropertyPatternValue, excludedEntityTypeNameToIdToScopeIdsMap, excludedEntityTypeNameToPropertyPatternKeyToScopeIdsMap, riskPolicyExclusionAnyScopeEntityTypeNames, riskPolicyExclusionEntityTypeNames, riskPolicyExclusionEntityTypeNameToPropertyPatternTypeNamesMap]);
}