import { NoneIcon } from "@infrastructure";
import { Stack, SxProps, Typography } from "@mui/material";
import _ from "lodash";
import React, { ReactNode } from "react";
import { Contract, SeverityLabel, useDynamicRiskSeverityTranslator, useSeverityTranslator, useTheme } from "..";

type SeverityProps = {
    disabled?: boolean;
    dynamicRiskPolicyConfigurationTypeName?: string;
    message?: ReactNode;
    severity?: Contract.Severity;
    sx?: SxProps;
};

export function Severity({ disabled, dynamicRiskPolicyConfigurationTypeName, message, severity, sx }: SeverityProps) {
    const severityTranslator = useSeverityTranslator();
    const dynamicRiskSeverityTranslator = useDynamicRiskSeverityTranslator();

    const theme = useTheme();
    return _.isNil(severity) && _.isNil(dynamicRiskPolicyConfigurationTypeName)
        ? <NoneIcon sx={{ color: theme.palette.text.secondary }}/>
        : <SeverityLabel
            backgroundColor={
                _.isNil(severity)
                    ? undefined
                    : theme.palette.opacity(theme.palette.severity(severity), 0.2)}
            borderColor={theme.palette.severity(severity)}
            disabled={disabled}
            item={
                <Stack
                    direction="row"
                    spacing={0.5}
                    sx={{
                        alignItems: "center",
                        height: "100%",
                        overflow: "hidden"
                    }}>
                    <Typography noWrap={true}>
                        {severityTranslator(severity)}
                    </Typography>
                    {!_.isNil(message) && message}
                </Stack>}
            sx={sx}
            tooltipTitle={
                !_.isNil(dynamicRiskPolicyConfigurationTypeName) && _.isNil(severity)
                    ? dynamicRiskSeverityTranslator(dynamicRiskPolicyConfigurationTypeName)
                    : undefined}/>;
}