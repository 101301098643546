import { useMemo } from "react";
import { useOneLoginDefaultDirectoryEntityDefinition, useOneLoginDirectoryGroupDefinition, useOneLoginDirectoryRoleDefinition, useOneLoginDirectoryUserDefinition } from ".";
import { DefinitionOptions } from "../../..";
import { Contract } from "../../../../../../../../../../common";

export function useOneLoginDirectoryEntityDefinition(entityModel: Contract.EntityModel, options?: DefinitionOptions) {
    const useDefinition =
        useMemo(
            () => {
                switch (entityModel.entity.typeName) {
                    case Contract.TypeNames.OneLoginDirectoryGroup:
                        return useOneLoginDirectoryGroupDefinition;
                    case Contract.TypeNames.OneLoginDirectoryRole:
                        return useOneLoginDirectoryRoleDefinition;
                    case Contract.TypeNames.OneLoginDirectoryUser:
                        return useOneLoginDirectoryUserDefinition;
                    default:
                        return useOneLoginDefaultDirectoryEntityDefinition;
                }
            },
            []);

    return useDefinition(entityModel as Contract.OneLoginDirectoryEntityModel, options);
}