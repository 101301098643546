﻿import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useAwsLambdaFunctionConfigurationPackageTypeTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useAwsLambdaFunctionConfigurationPackageTypeTranslator",
            () => ({
                [Contract.TypeNames.AwsLambdaFunctionConfigurationPackageType]: {
                    [Contract.AwsLambdaFunctionConfigurationPackageType.Image]: "Image",
                    [Contract.AwsLambdaFunctionConfigurationPackageType.Zip]: "Zip"
                }
            }));
    return (packageType: Contract.AwsLambdaFunctionConfigurationPackageType) =>
        localization[Contract.TypeNames.AwsLambdaFunctionConfigurationPackageType][packageType]();
}