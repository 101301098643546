import { DataTable, DataTableColumn, DataTableColumnRenderProps, EmptyMessageText, Optional, useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { useMemo } from "react";
import { Contract, EntitiesCell } from "../../../common";

type OciNetworkingNetworkLoadBalancerBackendsTableProps = {
    backendIpAddressToInstanceIdMap: Dictionary<string>;
    backendSetNameToBackendsMap: Dictionary<Contract.OciNetworkingNetworkLoadBalancerBackend[]>;
};

export function OciNetworkingNetworkLoadBalancerBackendsTable({ backendIpAddressToInstanceIdMap, backendSetNameToBackendsMap }: OciNetworkingNetworkLoadBalancerBackendsTableProps) {
    const localization =
        useLocalization(
            "tenants.oci.ociNetworkingNetworkLoadBalancerBackendsTable",
            () => ({
                columns: {
                    backendSetName: "Backend Set Name",
                    ipAddress: "IP Address",
                    name: "Name",
                    port: {
                        any: "Any",
                        title: "Port"
                    }
                },
                empty: "No Backends"
            }));

    const items =
        useMemo(
            () =>
                _.flatMap(
                    backendSetNameToBackendsMap,
                    (backends, backendSetName) =>
                        _.map(
                            backends,
                            backend =>
                                new OciNetworkingNetworkLoadBalancerBackendsTableItem(
                                    backendSetName,
                                    backendIpAddressToInstanceIdMap[backend.ipAddress],
                                    backend.ipAddress,
                                    backend.port))),
            [backendSetNameToBackendsMap]);

    return (
        <DataTable
            emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty()) }}
            fetchItems={() => items}
            getItemId={(item: OciNetworkingNetworkLoadBalancerBackendsTableItem) => item.ipAddress}
            sortOptions={{ enabled: false }}
            variant="card">
            <DataTableColumn
                id={OciNetworkingNetworkLoadBalancerBackendsTableColumnId.Name}
                render={
                    ({ item }: DataTableColumnRenderProps<OciNetworkingNetworkLoadBalancerBackendsTableItem>) =>
                        _.isNil(item.instanceId)
                            ? <Typography noWrap={true}> {item.ipAddress} </Typography>
                            : <EntitiesCell
                                entityIdsOrModels={item.instanceId}
                                entityTypeName={Contract.TypeNames.OciComputeInstance}
                                entityVariant="iconText"/>}
                title={localization.columns.name()}/>
            <DataTableColumn
                id={OciNetworkingNetworkLoadBalancerBackendsTableColumnId.IpAddress}
                itemProperty={(item: OciNetworkingNetworkLoadBalancerBackendsTableItem) => item.ipAddress}
                title={localization.columns.ipAddress()}/>
            <DataTableColumn
                id={OciNetworkingNetworkLoadBalancerBackendsTableColumnId.Port}
                itemProperty={(item: OciNetworkingNetworkLoadBalancerBackendsTableItem) =>
                    item.port == 0
                        ? localization.columns.port.any()
                        : item.port}
                title={localization.columns.port.title()}/>
            <DataTableColumn
                id={OciNetworkingNetworkLoadBalancerBackendsTableColumnId.BackendSetName}
                itemProperty={(item: OciNetworkingNetworkLoadBalancerBackendsTableItem) => item.backendSetName}
                title={localization.columns.backendSetName()}/>
        </DataTable>);
}

class OciNetworkingNetworkLoadBalancerBackendsTableItem {
    constructor(
        public backendSetName: string,
        public instanceId: Optional<string>,
        public ipAddress: string,
        public port: number) {
    }
}

enum OciNetworkingNetworkLoadBalancerBackendsTableColumnId {
    BackendSetName = "backendSetName",
    IpAddress = "ipAddress",
    Name = "name",
    Port = "port"
}