import { DataTableColumn, DataTableColumnRenderProps, SelectionActionsAction, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { ReactNode, useMemo } from "react";
import { useTableCommonCloudColumns, useTableCommonColumns } from "../..";
import { Contract, CustomerConsoleAppUrlHelper, EntitiesCell, RiskStatusIcon, RiskType, RiskTypeGroups, StorageHelper } from "../../../../../../../../../common";
import { RiskDefinitionSectionCategory } from "../../../../../hooks/useDefinition/utilities";
import { FiltersHelper, RisksItemsFilterId } from "../../../../Filters";
import { TableColumnId, UpdateStatusSelectionAction } from "../../../components";
import { RiskTypeDefinition } from "../useRiskTypeDefinition";
import { useCommonSelectionActions } from "./useCommonSelectionActions";

export function useCloudDefinition(view: Contract.RisksView) {
    const commonColumns = useTableCommonColumns(view, true);
    const commonCloudColumns = useTableCommonCloudColumns();
    const commonSelectionActions = useCommonSelectionActions(RiskType.Cloud, view);

    const localization =
        useLocalization(
            "views.customer.risks.items.hooks.useRiskTypeDefinition.hooks.useCloudDefinition",
            () => ({
                columns: {
                    riskedEntityIds: "Resources"
                }
            }));

    return useMemo(
        (): RiskTypeDefinition => ({
            groups: [
                RiskTypeGroups.RiskPolicyType,
                RiskTypeGroups.Tenant
            ],
            rowOptions: {
                getUrl: (item: Contract.RiskModel) =>
                    CustomerConsoleAppUrlHelper.getRiskProfileHashUrl(
                        item.risk.id,
                        { category: RiskDefinitionSectionCategory.Overview })
            },
            selectionActionElements:
                ({ clearSelection, getSelectedRiskIdToScopeIdsMap, reloadRiskModels }) =>
                    _<ReactNode>([]).
                        concatIf(
                            view !== Contract.RisksView.Closed,
                            commonSelectionActions.close(reloadRiskModels),
                            <SelectionActionsAction
                                id={`UpdateStatusButton-${Contract.RiskStatus.Ignored}`}
                                key={`UpdateStatusButton-${Contract.RiskStatus.Ignored}`}
                                permissions={[Contract.IdentityPermission.SecurityWrite]}>
                                <UpdateStatusSelectionAction
                                    icon={<RiskStatusIcon status={Contract.RiskStatus.Ignored}/>}
                                    reloadRiskModels={reloadRiskModels}
                                    riskType={RiskType.Cloud}
                                    status={Contract.RiskStatus.Ignored}
                                    view={view}/>
                            </SelectionActionsAction>,
                            commonSelectionActions.divider).
                        concat([
                            commonSelectionActions.star(reloadRiskModels),
                            commonSelectionActions.comment(clearSelection)]).
                        concatIf(
                            view === Contract.RisksView.Closed,
                            commonSelectionActions.open(reloadRiskModels)).
                        concat([
                            commonSelectionActions.sendMail(clearSelection),
                            commonSelectionActions.sendPerResourceOwner(clearSelection),
                            commonSelectionActions.sendWebhook(clearSelection, getSelectedRiskIdToScopeIdsMap),
                            commonSelectionActions.jiraCreate(getSelectedRiskIdToScopeIdsMap, reloadRiskModels, RiskType.Cloud),
                            commonSelectionActions.jiraUpdate(getSelectedRiskIdToScopeIdsMap, reloadRiskModels, RiskType.Cloud),
                            commonSelectionActions.serviceNowCreate(getSelectedRiskIdToScopeIdsMap, reloadRiskModels),
                            commonSelectionActions.serviceNowUpdate(getSelectedRiskIdToScopeIdsMap, reloadRiskModels)
                        ]).
                        value(),
            storageOptions: {
                groupBy: StorageHelper.customerRisksItemsGroupingType,
                grouped: {
                    getColumnOrderStorageItem: (groupId: string) => StorageHelper.customerRisksGroupColumnOrder(view, groupId),
                    getColumnSelectorStorageItem: (groupId: string) => StorageHelper.customerRisksGroupColumnSelector(view, groupId)
                },
                ungrouped: {
                    columnOrder: StorageHelper.customerRisksColumnOrder(view),
                    columnSelector: StorageHelper.customerRisksColumnSelector(view)
                }
            },
            toRequestRiskFiltersFromFilterMap: FiltersHelper.toRequestCloudRiskFiltersFromFilterMap,
            toRiskReportRequestDefinitionFromFilters:
                (filtered: boolean, filters: Contract.RiskControllerRiskModelFilters, status: Contract.RiskStatus, sort?: Contract.RiskControllerRiskModelSort, timeZoneInfoId?: string) =>
                    new Contract.ReportControllerCloudRisksReportRequestDefinition(
                        undefined,
                        timeZoneInfoId,
                        Contract.TypeNames.ReportControllerCloudRisksReportRequestDefinition,
                        undefined,
                        filtered,
                        filters,
                        undefined,
                        sort,
                        status),
            ungrouped: {
                columns: [
                    commonColumns.statusUpdateTimeColumn,
                    commonColumns.severityColumn,
                    commonColumns.riskPolicyIdColumn,
                    commonCloudColumns.descriptionColumn,
                    commonColumns.tenantIdColumn,
                    <DataTableColumn
                        cellMaxWidth="medium"
                        filterOptions={{ externalId: RisksItemsFilterId[Contract.RiskFilterId.RiskedEntityId] }}
                        id={TableColumnId.RiskedEntityIds}
                        key={TableColumnId.RiskedEntityIds}
                        render={
                            ({ item }: DataTableColumnRenderProps<Contract.RiskModel>) =>
                                <EntitiesCell
                                    entityIdsOrModels={item.risk.riskedEntityIds}
                                    entityTypeName={item.riskedEntityTypeName}
                                    entityVariant="iconText"/>}
                        sortOptions={{ enabled: false }}
                        title={localization.columns.riskedEntityIds()}/>,
                    commonColumns.resourceOwnerColumn,
                    commonColumns.resourceEnvironmentColumn,
                    commonColumns.statusColumn(true),
                    commonColumns.subStatusColumn(true),
                    commonColumns.ignoreExpirationDateColumn,
                    commonColumns.openStatusUpdateTimeColumn,
                    commonColumns.systemCreationTimeColumn,
                    commonColumns.compliancesColumn,
                    commonColumns.riskedEntityAttributesColumn,
                    commonColumns.actionsCell(RiskType.Cloud)
                ]
            }
        }),
        [StorageHelper, commonColumns, commonCloudColumns, commonSelectionActions, view]);
}