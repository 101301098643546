import { Checkbox, Tooltip } from "@infrastructure";
import { Box, CheckboxProps, FormControlLabel, Stack, Typography, useTheme } from "@mui/material";
import _ from "lodash";
import React, { isValidElement, memo, ReactNode } from "react";

const CheckboxFieldMemo = memo(CheckboxField);
export { CheckboxFieldMemo as CheckboxField };

type CheckboxFieldProps =
    CheckboxProps & {
        children?: ReactNode;
        tooltipText?: string;
    };

function CheckboxField({ checked, children, disabled, indeterminate, sx, tooltipText, ...props }: CheckboxFieldProps) {
    const theme = useTheme();
    return (
        <Tooltip titleOrGetTitle={tooltipText}>
            <Stack
                alignItems="center"
                direction="row"
                sx={{
                    width: "inherit",
                    ...sx
                }}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={checked}
                            disabled={disabled}
                            indeterminate={indeterminate}
                            sx={{ fontSize: "20px" }}
                            {...props}/>}
                    label={
                        _.isNil(children)
                            ? undefined
                            : <Box
                                sx={{
                                    flex: 1,
                                    overflow: "hidden"
                                }}>
                                {isValidElement(children)
                                    ? children
                                    : <Typography
                                        noWrap={true}
                                        sx={{
                                            color:
                                                disabled
                                                    ? theme.palette.text.secondary
                                                    : theme.palette.text.primary,
                                            opacity:
                                                disabled
                                                    ? 0.8
                                                    : undefined
                                        }}
                                        variant="body1">
                                        {children}
                                    </Typography>}
                            </Box>}
                    sx={{ width: "inherit" }}/>
            </Stack>
        </Tooltip>);
}