import { useLocalization, useLocalizeList } from "@infrastructure";
import _ from "lodash";
import { AuditEventDefinition, AuditEventDefinitionContext, AuditEventDefinitionDetailItem } from "..";
import { Contract } from "../../../../../../../../../common";
import { useCodeTenantTypeTranslator } from "../../../../../../../../../tenants";

export function useCodeOrganizationAuditEventDefinition(context: AuditEventDefinitionContext) {
    const tenantTypeTranslator = useCodeTenantTypeTranslator();
    const localizeList = useLocalizeList();
    const localization =
        useLocalization(
            "views.customer.auditEvents.profile.hooks.useDefinition.hooks.useCodeOrganizationAuditEventDefinition",
            () => ({
                organizationName: "Name",
                organizationType: "Provider",
                tenantNames: {
                    empty: "All",
                    title: "Repositories"
                }
            }));

    const codeOrganizationAuditEvent = context.auditEventModel.auditEvent as Contract.CodeOrganizationAuditEvent;
    return new AuditEventDefinition(
        _<AuditEventDefinitionDetailItem>([]).
            concat(
                new AuditEventDefinitionDetailItem(
                    localization.organizationName(),
                    codeOrganizationAuditEvent.organizationName),
                new AuditEventDefinitionDetailItem(
                    localization.organizationType(),
                    tenantTypeTranslator(codeOrganizationAuditEvent.organizationType))).
            concatIf(
                codeOrganizationAuditEvent.typeName === Contract.TypeNames.CodeOrganizationCreationAuditEvent ||
                codeOrganizationAuditEvent.typeName === Contract.TypeNames.CodeOrganizationUpdateAuditEvent,
                () => {
                    const tenantPaths = (codeOrganizationAuditEvent as Contract.CodeOrganizationCreationAuditEvent | Contract.CodeOrganizationUpdateAuditEvent).memberSelection?.rawIds;
                    return new AuditEventDefinitionDetailItem(
                        localization.tenantNames.title(),
                        _.isEmpty(tenantPaths)
                            ? localization.tenantNames.empty()
                            : localizeList(tenantPaths)!);
                }).
            value());
}