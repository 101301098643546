﻿import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useGcpContainerClusterNodePoolStatusTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useGcpContainerClusterNodePoolStatusTranslator",
            () => ({
                [Contract.TypeNames.GcpContainerClusterNodePoolStatus]: {
                    [Contract.GcpContainerClusterNodePoolStatus.Error]: "Error",
                    [Contract.GcpContainerClusterNodePoolStatus.Provisioning]: "Provisioning",
                    [Contract.GcpContainerClusterNodePoolStatus.Reconciling]: "Reconciling",
                    [Contract.GcpContainerClusterNodePoolStatus.Running]: "Running",
                    [Contract.GcpContainerClusterNodePoolStatus.RunningWithError]: "Running With Error",
                    [Contract.GcpContainerClusterNodePoolStatus.Stopping]: "Stopping"
                }
            }));
    return (status: Contract.GcpContainerClusterNodePoolStatus) =>
        localization[Contract.TypeNames.GcpContainerClusterNodePoolStatus][status]();
}