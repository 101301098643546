import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { EntityExternalConsoleLink } from "../../components";
import { useGetAzureBotServiceBotServiceRiskContext } from "../contexts";

export function useAzureBotServiceBotServiceInboundExternalWideRangeRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AzureBotServiceBotServiceInboundExternalWideRangeRisk;
    const botServiceModel = entityModelStore.useGet(risk.entityId) as Contract.AzureBotServiceBotServiceModel;

    const getAzureBotServiceBotServiceRiskContext = useGetAzureBotServiceBotServiceRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.compliance.useAzureBotServiceBotServiceInboundExternalWideRangeRiskDefinition",
            () => ({
                description: "{{botService}} allows inbound network access from a wide range of public IP addresses",
                sections: {
                    resolution: {
                        step1: "In the **Public access** section, disable the **Enable public access from all networks** toggle (**Disable public access and use private access**)",
                        step2: "Click **Apply** to confirm"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            botService:
                <Entity
                    entityIdOrModel={botServiceModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={risk.entityId}
                key={risk.entityId}
                page={Contract.AzureConsoleEntityPage.Network}/>,
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2()
        ],
        riskModel,
        () => {
            const botServiceRiskContext = getAzureBotServiceBotServiceRiskContext(botServiceModel);
            return [
                botServiceRiskContext.generalInformation,
                botServiceRiskContext.sensitive,
                botServiceRiskContext.inboundExternalAccessScope,
                botServiceRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}