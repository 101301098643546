﻿import { Link, useChangeEffect, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { Contract, RiskPoliciesType, TextInput } from "../../../../../../../common";
import { ConfigurationCategory, useConfigurationContext, useSetConfigurationContext } from "../../../components";
import { useGetGeneralInheritToggleInheritedValues } from "../../useGetGeneralInheritToggleInheritedValues";
import { ListParameter, Parameters } from "../components";
import { RiskPolicyConfigurationDefinitionData } from "../useConfigurationDefinition";

const MaxPatternLength = 256;

export function useCommonKubernetesAdmissionControllerWorkloadResourceContainerImageRepositoriesRiskPolicyConfigurationDefinition(description: string): RiskPolicyConfigurationDefinitionData {
    const { item, riskPolicyConfiguration } = useConfigurationContext();
    const setConfigurationContext = useSetConfigurationContext();

    const {
        additivePropertyNameToDataMap: { patterns: patternsData },
        inherited
    } = useGetGeneralInheritToggleInheritedValues(
        RiskPoliciesType.KubernetesAdmissionController,
        riskPolicyConfiguration,
        item,
        riskPolicyConfiguration => ({
            patterns: (riskPolicyConfiguration as KubernetesAdmissionControllerWorkloadResourceContainerImageRepositoriesRiskPoliciesConfiguration).patterns
        }));

    const [patterns, setPatterns] = useState(patternsData.values);
    const [patternsValid, setPatternsValid] = useState(true);

    useEffect(
        () => {
            if (inherited) {
                setPatterns([]);
                setPatternsValid(true);
            }
        },
        [inherited]);

    useChangeEffect(
        () =>
            setConfigurationContext(
                configurationContext => ({
                    ...configurationContext,
                    additionalTabIdToSectionMap: {
                        ...configurationContext.additionalTabIdToSectionMap,
                        [ConfigurationCategory.KubernetesAdmissionControllerParameters]: {
                            valid:
                                !configurationContext.riskPolicyConfiguration.enabled ||
                                !_.isEmpty(patterns) &&
                                patternsValid
                        }
                    },
                    dirty: true,
                    riskPolicyConfiguration: {
                        ...configurationContext.riskPolicyConfiguration,
                        patterns
                    }
                })),
        [patterns, patternsValid, riskPolicyConfiguration.enabled]);

    const localization =
        useLocalization(
            "views.customer.riskPolicies.hooks.useConfigurationDefinition.hooks.useCommonKubernetesAdmissionControllerWorkloadResourceContainerImageRepositoriesRiskPolicyConfigurationDefinition",
            () => ({
                helpText: {
                    links: {
                        regexDocumentationLink: "the documentation"
                    },
                    text: "Supported pattern operators include: * ? |\nSee {{regexDocumentationLink}} for details."
                },
                pattern: "Pattern"
            }));

    return useMemo(
        () => ({
            additionalTabs: [
                {
                    element:
                        <Parameters
                            helpText={localization.helpText.text({
                                regexDocumentationLink:
                                    <Link
                                        urlOrGetUrl={"https://github.com/google/re2/wiki/Syntax"}
                                        variant="external">
                                        {localization.helpText.links.regexDocumentationLink()}
                                    </Link>
                            })}
                            inherited={inherited}>
                            {disabled =>
                                <ListParameter<string>
                                    description={description}
                                    disabled={disabled}
                                    getValueKey={value => value!}
                                    inherited={inherited}
                                    inheritedValues={patternsData.inheritedValues}
                                    isParameterValueValid={value => !_.isEmpty(value)}
                                    values={patterns}
                                    onValuesChanged={
                                        (patterns, patternsValid) => {
                                            setPatterns(patterns);
                                            setPatternsValid(patternsValid);
                                        }}>
                                    {(onValueChanged, readOnly, value) =>
                                        <TextInput
                                            disabled={readOnly}
                                            formSx={{ width: "50%" }}
                                            placeholder={localization.pattern()}
                                            readOnly={readOnly}
                                            text={value ?? ""}
                                            onChange={pattern => onValueChanged(pattern.substring(0, MaxPatternLength))}/>}
                                </ListParameter>}
                        </Parameters>,
                    id: ConfigurationCategory.KubernetesAdmissionControllerParameters
                }]
        }),
        [description, inherited, patterns, patternsData, riskPolicyConfiguration.enabled]);
}

type KubernetesAdmissionControllerWorkloadResourceContainerImageRepositoriesRiskPoliciesConfiguration =
    Contract.KubernetesAdmissionControllerWorkloadResourceContainerImageAllowedRepositoriesRiskPolicyConfiguration |
    Contract.KubernetesAdmissionControllerWorkloadResourceContainerImageNotAllowedRepositoriesRiskPolicyConfiguration;