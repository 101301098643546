import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function AzureServiceSubnetIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                d="M11.0198 6.36273C12.6829 5.00398 14.9191 4.63144 16.9181 5.38012C18.917 6.1288 20.3887 7.89007 20.7999 10.0257C22.6243 10.8179 23.6509 12.814 23.2551 14.7998C22.8592 16.7856 21.1505 18.2118 19.1683 18.2108L4.17106 18.211C1.86745 18.211 0 16.3017 0 13.9465C0 11.5912 1.86745 9.68191 4.17106 9.68191H4.20023V9.65226C4.19883 8.0021 5.12956 6.49951 6.58925 5.79541C8.04893 5.09131 9.77503 5.31233 11.0198 6.36273Z"
                fill="white"/>
            <path d="M19.3303 10.6974C19.2712 8.90397 17.8 7.46809 15.9927 7.46606C15.8184 7.46606 15.6771 7.60736 15.6771 7.78167C15.6771 7.95599 15.8184 8.09733 15.9927 8.09733C17.4544 8.09897 18.6451 9.25728 18.699 10.7063L18.7009 10.8055L18.7038 10.8483C18.7247 11.0024 18.8567 11.1211 19.0165 11.1211L19.0593 11.1182C19.2134 11.0973 19.3321 10.9653 19.3317 10.8049L19.3303 10.6974Z"/>
            <path
                clipRule="evenodd"
                d="M17.3642 5.40111C15.4046 4.66719 13.2287 4.95441 11.5233 6.14795L11.3513 6.27267L11.3406 6.26453C10.0181 5.2834 8.26795 5.10857 6.77602 5.82821L6.65603 5.88832C5.26955 6.60907 4.35253 8.00731 4.21751 9.57622L4.20903 9.69168L4.13202 9.69714C1.81786 9.88892 0 11.867 0 14.2767C0 16.8133 2.01425 18.8716 4.50134 18.8716L19.4987 18.8714C21.6391 18.8725 23.4828 17.3341 23.9092 15.1947L23.9325 15.0689C24.2773 13.0571 23.2766 11.0574 21.4892 10.1594L21.4194 10.1259L21.3885 9.98948C20.8796 7.87906 19.3757 6.15448 17.3642 5.40111ZM11.559 6.94878C13.1318 5.66384 15.2444 5.31256 17.1325 6.0197C19.0213 6.7271 20.4159 8.39317 20.8058 10.4184L20.8387 10.5895L20.9986 10.6589C22.6767 11.3876 23.6277 13.2283 23.2614 15.0656C22.8954 16.9017 21.3184 18.2118 19.4987 18.2108L4.50134 18.211C2.38701 18.211 0.66055 16.4565 0.66055 14.2767C0.66055 12.097 2.38701 10.3424 4.50134 10.3424H4.86077V9.98254C4.85949 8.45605 5.72028 7.07086 7.063 6.42317C8.40462 5.77602 9.99122 5.97848 11.1371 6.94543L11.3467 7.12231L11.559 6.94878Z"
                fillRule="evenodd"/>
            <path d="M11 9C10.9402 8.99976 10.8817 9.01985 10.8334 9.05733C10.785 9.09481 10.7492 9.14773 10.7312 9.20834L9.01658 14.5992C9.00127 14.6445 8.99647 14.693 9.00257 14.7407C9.00867 14.7883 9.02551 14.8338 9.05165 14.8731C9.07779 14.9124 9.11247 14.9444 9.15276 14.9665C9.19304 14.9886 9.23774 15.0001 9.28308 14.9999H10.7006C10.7534 14.9899 10.8028 14.9652 10.8436 14.9283C10.8845 14.8915 10.9154 14.8438 10.9332 14.7901L11.2751 13.7207L12.4964 14.9296C12.5476 14.9745 12.6118 14.9994 12.6782 14.9999H14.2666L13.57 12.8873L11.1641 12.8878L12.7821 9H11Z"/>
            <path d="M13.2678 9.20407C13.2489 9.14459 13.2128 9.0929 13.1648 9.05629C13.1167 9.01968 13.0591 8.99999 12.9999 9H11.021C11.0802 9 11.1378 9.0197 11.1859 9.05631C11.2339 9.09291 11.27 9.1446 11.2889 9.20407L13.0063 14.604C13.0206 14.6491 13.0246 14.6973 13.018 14.7444C13.0114 14.7915 12.9942 14.8362 12.9681 14.8749C12.9419 14.9136 12.9074 14.9452 12.8675 14.9669C12.8275 14.9887 12.7833 15 12.7384 15H14.7174C14.7622 15 14.8065 14.9886 14.8464 14.9669C14.8863 14.9451 14.9208 14.9136 14.947 14.8749C14.9731 14.8362 14.9903 14.7915 14.9969 14.7444C15.0035 14.6972 14.9995 14.6491 14.9852 14.604L13.2678 9.20407Z"/>
        </SvgIcon>);
}