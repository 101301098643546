import { useFormatRelativeTime, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, InfoItem, LicensingHelper, TypeHelper } from "../../../../../../../../../common";
import { VulnerabilitiesChartCell, WorkloadAnalysisScanStatusCell } from "../../../../../../WorkloadAnalysis";
import { useOperatingSystemTypeTranslator } from "../../../../../hooks";

export function useCommonVirtualMachineDiskResourceInfoItemElements(resourceModel: Contract.EntityModel) {
    const virtualMachineDiskResourceModel = _.as<Contract.IVirtualMachineDiskResourceModel>(resourceModel);
    const formatRelativeTime = useFormatRelativeTime();

    const operatingSystemTypeTranslator = useOperatingSystemTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.useCommonVirtualMachineDiskResourceInfoItemElements",
            () => ({
                info: {
                    items: {
                        operatingSystem: "Operating System",
                        operatingSystemType: "Operating System Type",
                        scanTime: "Scan Time",
                        vulnerabilities: "Vulnerabilities",
                        workloadAnalysisStatus: "Scan Status"
                    }
                }
            }));
    return {
        operatingSystemDisplayNameInfoElement:
            <InfoItem
                key="operatingSystemDisplayName"
                location="all"
                title={localization.info.items.operatingSystem()}
                value={
                    _.isNil(virtualMachineDiskResourceModel.workloadAnalysisOperatingSystem)
                        ? undefined
                        : virtualMachineDiskResourceModel.workloadAnalysisOperatingSystem.displayName}/>,
        operatingSystemTypeInfoElement:
            <InfoItem
                key="operatingSystemType"
                location="all"
                title={localization.info.items.operatingSystemType()}
                value={
                    _.isNil(virtualMachineDiskResourceModel.operatingSystemType)
                        ? undefined
                        : operatingSystemTypeTranslator(virtualMachineDiskResourceModel.operatingSystemType)}/>,
        ...LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.Cnapp)
            ? {
                scanTimeInfoElement:
                    <InfoItem
                        key="scanTime"
                        location="all"
                        title={localization.info.items.scanTime()}
                        value={
                            _.isNil(virtualMachineDiskResourceModel.scanTime)
                                ? undefined
                                : formatRelativeTime(virtualMachineDiskResourceModel.scanTime)}/>,
                vulnerabilitiesInfoElement:
                    <InfoItem
                        key="vulnerabilities"
                        location="all"
                        title={localization.info.items.vulnerabilities()}
                        value={
                            <VulnerabilitiesChartCell
                                variant="initialAndCount"
                                vulnerabilities={virtualMachineDiskResourceModel.vulnerabilities}
                                workloadResourceEntityId={virtualMachineDiskResourceModel.id}/>}
                        valueSx={{ maxWidth: "100%" }}/>,
                workloadAnalysisStatusInfoElement:
                    <InfoItem
                        key="scanStatus"
                        location="all"
                        title={localization.info.items.workloadAnalysisStatus()}
                        value={
                            <WorkloadAnalysisScanStatusCell
                                azureKeyVaultId={
                                    TypeHelper.extendOrImplement(resourceModel.typeName, Contract.TypeNames.IVirtualMachineModel)
                                        ? _.as<Contract.IVirtualMachineModel>(resourceModel).diskEncryptionVaultIdReference
                                        : undefined}
                                resourceType={
                                    TypeHelper.extendOrImplement(resourceModel.entity.typeName, Contract.TypeNames.IVirtualMachine)
                                        ? Contract.WorkloadAnalysisWorkloadResourceType.VirtualMachine
                                        : Contract.WorkloadAnalysisWorkloadResourceType.VirtualMachineImage}
                                workloadAnalysisError={virtualMachineDiskResourceModel.workloadAnalysisError}
                                workloadAnalysisStatus={virtualMachineDiskResourceModel.workloadAnalysisStatus}/>}/>
            }
            : undefined
    };
}