import { InlineItems, useLocalization, useLocalizeList } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { AuditEventDefinition, AuditEventDefinitionContext, AuditEventDefinitionDetailItem } from "..";
import { Contract } from "../../../../../../../../../common";
import { AwsTenantHelper } from "../../../../../../../../../tenants";

export function useAwsTenantAuditEventDefinition(context: AuditEventDefinitionContext) {
    const localizeList = useLocalizeList();
    const localization =
        useLocalization(
            "views.customer.auditEvents.profile.hooks.useDefinition.hooks.useAwsTenantAuditEventDefinition",
            () => ({
                audit: {
                    false: "Monitored",
                    title: "Account type",
                    true: "Audit"
                },
                propertyChanges: "Updated properties",
                tenantAuditTrailBucketName: "Bucket",
                tenantFolderPath: "Folder",
                tenantLambdaFunctionConfigurationInvokeEventCategoryTrail: "Lambda Data CloudTrail",
                tenantManagementEventCategoryTrail: "Management CloudTrail",
                tenantName: "Account name",
                tenantRawId: "Account ID",
                tenantRegionSystemNames: {
                    all: "all regions",
                    some: [
                        "1 region",
                        "{{count | NumberFormatter.humanize}} regions"
                    ],
                    title: "Regions"
                },
                tenantRoleArn: "Role ARN",
                tenantS3ObjectEventCategoryTrail: "S3 Data CloudTrail"
            }));

    const tenantAuditEvent = context.auditEventModel.auditEvent as Contract.AwsTenantAuditEvent;
    const regionSystemNames =
        tenantAuditEvent.typeName === Contract.TypeNames.AwsTenantCreationAuditEvent ||
        tenantAuditEvent.typeName === Contract.TypeNames.AwsTenantUpdateAuditEvent
            ? (tenantAuditEvent as Contract.AwsTenantCreationAuditEvent | Contract.AwsTenantUpdateAuditEvent).tenantRegionSystemNames
            : undefined;
    const tenantTrailEventCategoryToTrailMap =
        tenantAuditEvent.typeName === Contract.TypeNames.AwsTenantCreationAuditEvent ||
        tenantAuditEvent.typeName === Contract.TypeNames.AwsTenantUpdateAuditEvent
            ? AwsTenantHelper.getTrailEventCategoryToTrailMap((tenantAuditEvent as Contract.AwsTenantCreationAuditEvent | Contract.AwsTenantUpdateAuditEvent).tenantConfigurationTrails)
            : undefined;

    return new AuditEventDefinition(
        _<AuditEventDefinitionDetailItem>([]).
            concat(
                new AuditEventDefinitionDetailItem(
                    localization.tenantRawId(),
                    tenantAuditEvent.tenantRawId),
                new AuditEventDefinitionDetailItem(
                    localization.tenantName(),
                    tenantAuditEvent.tenantName),
                new AuditEventDefinitionDetailItem(
                    localization.tenantFolderPath(),
                    tenantAuditEvent.tenantFolderPath),
                new AuditEventDefinitionDetailItem(
                    localization.tenantRoleArn(),
                    tenantAuditEvent.tenantRoleArn),
                new AuditEventDefinitionDetailItem(
                    localization.audit.title(),
                    tenantAuditEvent.audit
                        ? localization.audit.true()
                        : localization.audit.false())).
            concatIf(
                !tenantAuditEvent.audit && (
                    tenantAuditEvent.typeName === Contract.TypeNames.AwsTenantCreationAuditEvent ||
                    tenantAuditEvent.typeName === Contract.TypeNames.AwsTenantUpdateAuditEvent),
                new AuditEventDefinitionDetailItem(
                    localization.tenantRegionSystemNames.title(),
                    _.isNil(regionSystemNames)
                        ? localization.tenantRegionSystemNames.all()
                        : localization.tenantRegionSystemNames.some(regionSystemNames!.length),
                    _.isNil(regionSystemNames)
                        ? undefined
                        : <InlineItems
                            items={regionSystemNames!}
                            namePluralizer={localization.tenantRegionSystemNames.some}
                            variant="itemPlusItemCount"/>)).
            concatIf(
                tenantAuditEvent.audit &&
                (tenantAuditEvent.typeName === Contract.TypeNames.AwsTenantCreationAuditEvent ||
                    tenantAuditEvent.typeName === Contract.TypeNames.AwsTenantUpdateAuditEvent),
                () =>
                    new AuditEventDefinitionDetailItem(
                        localization.tenantAuditTrailBucketName(),
                        (tenantAuditEvent as Contract.AwsTenantCreationAuditEvent | Contract.AwsTenantUpdateAuditEvent).tenantTrailBucketName!)).
            concatIf(
                !tenantAuditEvent.audit &&
                (tenantAuditEvent.typeName === Contract.TypeNames.AwsTenantCreationAuditEvent ||
                    tenantAuditEvent.typeName === Contract.TypeNames.AwsTenantUpdateAuditEvent),
                () =>
                    new AuditEventDefinitionDetailItem(
                        localization.tenantManagementEventCategoryTrail(),
                        _([Contract.AwsTenantEventCategory.ManagementRead, Contract.AwsTenantEventCategory.ManagementWrite]).
                            map(eventCategory => tenantTrailEventCategoryToTrailMap![eventCategory]?.arn).
                            filter().
                            uniq().
                            join(", "))).
            concatIf(
                !tenantAuditEvent.audit &&
                (tenantAuditEvent.typeName === Contract.TypeNames.AwsTenantCreationAuditEvent ||
                    tenantAuditEvent.typeName === Contract.TypeNames.AwsTenantUpdateAuditEvent),
                () =>
                    new AuditEventDefinitionDetailItem(
                        localization.tenantS3ObjectEventCategoryTrail(),
                        _([Contract.AwsTenantEventCategory.S3ObjectRead, Contract.AwsTenantEventCategory.S3ObjectWrite]).
                            map(eventCategory => tenantTrailEventCategoryToTrailMap![eventCategory]?.arn).
                            filter().
                            uniq().
                            join(", "))).
            concatIf(
                !tenantAuditEvent.audit &&
                (tenantAuditEvent.typeName === Contract.TypeNames.AwsTenantCreationAuditEvent ||
                    tenantAuditEvent.typeName === Contract.TypeNames.AwsTenantUpdateAuditEvent),
                () =>
                    new AuditEventDefinitionDetailItem(
                        localization.tenantLambdaFunctionConfigurationInvokeEventCategoryTrail(),
                        tenantTrailEventCategoryToTrailMap![Contract.AwsTenantEventCategory.LambdaFunctionConfigurationInvokeWrite]?.arn ?? "")).
            concatIf(
                tenantAuditEvent.typeName === Contract.TypeNames.AwsTenantUpdateAuditEvent,
                () => {
                    const properties =
                        _.filter([
                            (tenantAuditEvent as Contract.AwsTenantUpdateAuditEvent).tenantTrailBucketChanged
                                ? localization.tenantAuditTrailBucketName()
                                : undefined,
                            (tenantAuditEvent as Contract.AwsTenantUpdateAuditEvent).tenantFolderPathChanged
                                ? localization.tenantFolderPath()
                                : undefined,
                            (tenantAuditEvent as Contract.AwsTenantUpdateAuditEvent).tenantLambdaFunctionConfigurationInvokeEventCategoryTrailChanged
                                ? localization.tenantLambdaFunctionConfigurationInvokeEventCategoryTrail()
                                : undefined,
                            (tenantAuditEvent as Contract.AwsTenantUpdateAuditEvent).tenantManagementEventCategoryTrailChanged
                                ? localization.tenantManagementEventCategoryTrail()
                                : undefined,
                            (tenantAuditEvent as Contract.AwsTenantUpdateAuditEvent).tenantNameChanged
                                ? localization.tenantName()
                                : undefined,
                            (tenantAuditEvent as Contract.AwsTenantUpdateAuditEvent).tenantRegionSystemNamesChanged
                                ? localization.tenantRegionSystemNames.title()
                                : undefined,
                            (tenantAuditEvent as Contract.AwsTenantUpdateAuditEvent).tenantRoleArnChanged
                                ? localization.tenantRoleArn()
                                : undefined,
                            (tenantAuditEvent as Contract.AwsTenantUpdateAuditEvent).tenantS3ObjectEventCategoryTrailChanged
                                ? localization.tenantS3ObjectEventCategoryTrail()
                                : undefined
                        ]);
                    return (
                        _<AuditEventDefinitionDetailItem>([]).
                            concatIf(
                                !_.isEmpty(properties),
                                new AuditEventDefinitionDetailItem(
                                    localization.propertyChanges(),
                                    localizeList(properties)!)).
                            value());
                }).
            value());
}