import { Avatar, SvgIconProps, SxProps, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract, DatadogIcon, SentraIcon, TenableIcon, useTheme } from "../..";
import { Vendor } from "../../controllers/types.generated";
import { AkamaiIcon, AnecdotesIcon, AnodotIcon, AquaSecurityIcon, BeyondTrustIcon, BrightcoveIcon, CheckPointIcon, CiscoIcon, CrowdStrikeIcon, CyberArkIcon, CyderesIcon, DatabricksIcon, DeepwatchIcon, DoiTIcon, DruvaIcon, DynatraceIcon, ExpelIcon, F5Icon, FinoutIcon, FireEyeIcon, FivetranIcon, FreshworksIcon, GoogleIcon, IBMIcon, ImplyIcon, InfinipointIcon, InstaclustrIcon, JupiterOneIcon, KentikIcon, KochavaIcon, LaceworkIcon, LogzIoIcon, LucidchartIcon, LumenIcon, MicrosoftIcon, MongoDBIcon, MSP360Icon, NetappIcon, NewRelicIcon, NOpsIcon, OneIdentityIcon, OrcaIcon, OxSecurityIcon, PaloAltoNetworksIcon, QualysIcon, Rapid7Icon, RedisIcon, RedLine13Icon, RiverMeadowIcon, RubrikIcon, SnowflakeIcon, SnykIcon, SolvoIcon, SplunkIcon, SysdigIcon, TalendIcon, TeraprocIcon, TrendCloudOneIcon, TrendMicroIcon, TurbotIcon, TwilioIcon, VaronisSystemsIcon, VmwareIcon, WizIcon, WorkatoIcon, ZestyIcon } from "./icons";

type TenantIconProps =
    SvgIconProps & {
        sx?: SxProps;
        vendorType: Vendor;
    };

export function VendorIcon({ vendorType, ...props }: TenantIconProps) {
    const theme = useTheme();
    switch (vendorType) {
        case Contract.Vendor.Akamai:
            return <AkamaiIcon {...props}/>;
        case Contract.Vendor.Anecdotes:
            return <AnecdotesIcon {...props}/>;
        case Contract.Vendor.Anodot:
            return <AnodotIcon {...props}/>;
        case Contract.Vendor.AquaSecurity:
            return <AquaSecurityIcon {...props}/>;
        case Contract.Vendor.BeyondTrust:
            return <BeyondTrustIcon {...props}/>;
        case Contract.Vendor.Brightcove:
            return <BrightcoveIcon {...props}/>;
        case Contract.Vendor.CheckPoint:
            return <CheckPointIcon {...props}/>;
        case Contract.Vendor.Cisco:
            return <CiscoIcon {...props}/>;
        case Contract.Vendor.CrowdStrike:
            return <CrowdStrikeIcon {...props}/>;
        case Contract.Vendor.CyberArk:
            return <CyberArkIcon {...props}/>;
        case Contract.Vendor.Cyderes:
            return <CyderesIcon {...props}/>;
        case Contract.Vendor.Databricks:
            return <DatabricksIcon {...props}/>;
        case Contract.Vendor.Datadog:
            return <DatadogIcon {...props}/>;
        case Contract.Vendor.Deepwatch:
            return <DeepwatchIcon {...props}/>;
        case Contract.Vendor.DoiT:
            return <DoiTIcon {...props}/>;
        case Contract.Vendor.Druva:
            return <DruvaIcon {...props}/>;
        case Contract.Vendor.Dynatrace:
            return <DynatraceIcon {...props}/>;
        case Contract.Vendor.Expel:
            return <ExpelIcon {...props}/>;
        case Contract.Vendor.F5:
            return <F5Icon {...props}/>;
        case Contract.Vendor.Finout:
            return <FinoutIcon {...props}/>;
        case Contract.Vendor.FireEye:
            return <FireEyeIcon {...props}/>;
        case Contract.Vendor.Fivetran:
            return <FivetranIcon {...props}/>;
        case Contract.Vendor.Freshworks:
            return <FreshworksIcon {...props}/>;
        case Contract.Vendor.Google:
            return <GoogleIcon {...props}/>;
        case Contract.Vendor.IBM:
            return <IBMIcon {...props}/>;
        case Contract.Vendor.Imply:
            return <ImplyIcon {...props}/>;
        case Contract.Vendor.Infinipoint:
            return <InfinipointIcon {...props}/>;
        case Contract.Vendor.Instaclustr:
            return <InstaclustrIcon {...props}/>;
        case Contract.Vendor.JupiterOne:
            return <JupiterOneIcon {...props}/>;
        case Contract.Vendor.Kentik:
            return <KentikIcon {...props}/>;
        case Contract.Vendor.Kochava:
            return <KochavaIcon {...props}/>;
        case Contract.Vendor.Lacework:
            return <LaceworkIcon {...props}/>;
        case Contract.Vendor.LogzIo:
            return <LogzIoIcon {...props}/>;
        case Contract.Vendor.Lucidchart:
            return <LucidchartIcon {...props}/>;
        case Contract.Vendor.Lumen:
            return <LumenIcon {...props}/>;
        case Contract.Vendor.Microsoft:
            return <MicrosoftIcon {...props}/>;
        case Contract.Vendor.MongoDB:
            return <MongoDBIcon {...props}/>;
        case Contract.Vendor.MSP360:
            return <MSP360Icon {...props}/>;
        case Contract.Vendor.NetApp:
            return <NetappIcon {...props}/>;
        case Contract.Vendor.NewRelic:
            return <NewRelicIcon {...props}/>;
        case Contract.Vendor.NOps:
            return <NOpsIcon {...props}/>;
        case Contract.Vendor.OneIdentity:
            return <OneIdentityIcon {...props}/>;
        case Contract.Vendor.Orca:
            return <OrcaIcon {...props}/>;
        case Contract.Vendor.OxSecurity:
            return <OxSecurityIcon {...props}/>;
        case Contract.Vendor.PaloAlto:
            return <PaloAltoNetworksIcon {...props}/>;
        case Contract.Vendor.Qualys:
            return <QualysIcon {...props}/>;
        case Contract.Vendor.Rapid7:
            return <Rapid7Icon {...props}/>;
        case Contract.Vendor.Redis:
            return <RedisIcon {...props}/>;
        case Contract.Vendor.RedLine13:
            return <RedLine13Icon {...props}/>;
        case Contract.Vendor.RiverMeadow:
            return <RiverMeadowIcon {...props}/>;
        case Contract.Vendor.Rubrik:
            return <RubrikIcon {...props}/>;
        case Contract.Vendor.Sentra:
            return <SentraIcon {...props}/>;
        case Contract.Vendor.Snowflake:
            return <SnowflakeIcon {...props}/>;
        case Contract.Vendor.Snyk:
            return <SnykIcon {...props}/>;
        case Contract.Vendor.Solvo:
            return <SolvoIcon {...props}/>;
        case Contract.Vendor.Splunk:
            return <SplunkIcon {...props}/>;
        case Contract.Vendor.Sysdig:
            return <SysdigIcon {...props}/>;
        case Contract.Vendor.Talend:
            return <TalendIcon {...props}/>;
        case Contract.Vendor.Tenable:
            return <TenableIcon {...props}/>;
        case Contract.Vendor.Teraproc:
            return <TeraprocIcon {...props}/>;
        case Contract.Vendor.TrendCloudOne:
            return <TrendCloudOneIcon {...props}/>;
        case Contract.Vendor.TrendMicro:
            return <TrendMicroIcon {...props}/>;
        case Contract.Vendor.Turbot:
            return <TurbotIcon {...props}/>;
        case Contract.Vendor.Twilio:
            return <TwilioIcon {...props}/>;
        case Contract.Vendor.VaronisSystems:
            return <VaronisSystemsIcon {...props}/>;
        case Contract.Vendor.Vmware:
            return <VmwareIcon {...props}/>;
        case Contract.Vendor.Wiz:
            return <WizIcon {...props}/>;
        case Contract.Vendor.Workato:
            return <WorkatoIcon {...props}/>;
        case Contract.Vendor.Zesty:
            return <ZestyIcon {...props}/>;
        default:
            return (
                <Avatar
                    sx={{
                        alignItems: "center",
                        backgroundColor: theme.palette.action.selected,
                        borderRadius: "50%",
                        color: theme.palette.text.primary,
                        display: "flex",
                        height: _.get(props, "sx.fontSize", "18px"),
                        justifyContent: "center",
                        width: _.get(props, "sx.fontSize", "18px"),
                        ...props.sx
                    }}>
                    <Typography
                        component="span"
                        sx={{ fontSize: "70%" }}>
                        {vendorType[0]}
                    </Typography>
                </Avatar>);
    }
}