import _ from "lodash";

export class StringHelper {
    public static compare(value?: string, otherValue?: string) {
        return StringHelper.normalize(value) === StringHelper.normalize(otherValue);
    }

    public static getCombineSortValue(...values: any[]) {
        return _.map(
            values,
            value => {
                if (_.isNumber(value)) {
                    return value.
                        toString().
                        padStart(15, "0");
                } else if (_.isString(value)) {
                    return StringHelper.normalize(value);
                } else if (_.isBoolean(value)) {
                    return value
                        ? "1"
                        : "0";
                }
                return value;
            });
    }

    public static getSortValue(value?: string) {
        return StringHelper.normalize(value);
    }

    public static isFalse(value?: any) {
        return (
            _.isBoolean(value) && !value ||
            _.isString(value) && value.toLowerCase() === "false");
    }

    public static isTrue(value?: any) {
        return (
            _.isBoolean(value) && value ||
            _.isString(value) && value.toLowerCase() === "true");
    }

    public static normalize<TValue extends string | undefined>(value: TValue): TValue extends string ? string : undefined {
        return value?.
            trim().
            toLowerCase() as any;
    }

    public static search(value: string, searchText?: string) {
        const normalizedSearchText = StringHelper.normalize(searchText);
        return (
            _.isEmpty(normalizedSearchText) ||
            value?.
                toLowerCase().
                includes(normalizedSearchText!));
    }
}