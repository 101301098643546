import { TimeFormatter, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { ReactNode } from "react";
import { Contract, Entity, InfoItem, TenantHelper, TypeHelper } from "../../../../../../../../../../common";
import { EntitiesInfoItem, TagsInfoItem } from "../../../../components";
import { useAwsCommonEntityInfoItemElements } from "./useAwsCommonEntityInfoItemElements";

export function useAwsCommonResourceInfoItemElements(resourceModel: Contract.AwsResourceModel) {
    const resource = resourceModel.entity as Contract.AwsResource;
    const commonEntityInfoItemElements = useAwsCommonEntityInfoItemElements(resourceModel);
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsCommonResourceInfoItemElements",
            () => ({
                arn: "ARN",
                creationTime: "Creation Time",
                creator: {
                    identity: "Identity: {{creatorIdentity}}",
                    originator: "Originator: {{creatorOriginatorEntity}}",
                    title: "Created By"
                },
                stack: "CloudFormation Stack",
                tags: "Tags"
            }));

    const getCreationTimeInfoItemElement =
        (title?: string) =>
            <InfoItem
                key={`creationTime-${title ?? localization.creationTime()}`}
                location="all"
                title={title ?? localization.creationTime()}
                value={
                    _.isNil(resourceModel.creationTime)
                        ? undefined
                        : TimeFormatter.profileFormatDateTime(resourceModel.creationTime)}/>;

    return {
        ...commonEntityInfoItemElements,
        arnInfoItemElement:
            <InfoItem
                key="arn"
                location="all"
                title={localization.arn()}
                value={
                    _.isNil(resource.arn)
                        ? undefined
                        : resource.arn}/>,
        creationTimeInfoItemElement: getCreationTimeInfoItemElement(localization.creationTime()),
        creatorEntityInfoItemElement:
            TenantHelper.isCommonTenant(resource.tenantId)
                ? undefined
                : <InfoItem
                    key="creator"
                    location="all"
                    title={localization.creator.title()}
                    vertical={!_.isNil(resourceModel.creatorIdentityIdReference) && !_.isNil(resourceModel.creatorOriginatorEntityIdReference)}>
                    {_.isNil(resourceModel.creatorIdentityIdReference)
                        ? undefined
                        : _<ReactNode>([]).
                            concat(
                                <InfoItem key="creatorIdentityIdReference">
                                    <Entity
                                        entityIdOrModel={resourceModel.creatorIdentityIdReference}
                                        linkOptions={{ disabledHighlight: false }}
                                        variant="iconText"/>
                                </InfoItem>).
                            concatIf(
                                !_.isNil(resourceModel.creatorOriginatorEntityIdReference),
                                <InfoItem key="creatorOriginatorEntityIdReference">
                                    <Entity
                                        entityIdOrModel={resourceModel.creatorOriginatorEntityIdReference!}
                                        linkOptions={{ disabledHighlight: false }}
                                        variant="iconText"/>
                                </InfoItem>).
                            value()}
                </InfoItem>,
        getCreationTimeInfoItemElement,
        stackInfoItemElement:
            _.isNil(resourceModel.stackId)
                ? undefined
                : <EntitiesInfoItem
                    entityIdsOrModels={resourceModel.stackId}
                    entityTypeName={Contract.TypeNames.AwsCloudFormationStack}
                    key="stack"
                    title={localization.stack()}/>,
        tagsInfoItemElement:
            TypeHelper.extendOrImplement(
                resource.typeName,
                Contract.TypeNames.IAwsTagResource)
                ? <TagsInfoItem
                    key="tags"
                    location="all"
                    resourceTags={resource.tags}
                    title={localization.tags()}/>
                : undefined
    };
}