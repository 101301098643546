import { useLocalization } from "@infrastructure";
import { Grid2, Stack } from "@mui/material";
import React from "react";
import { Contract, useTheme } from "../../../../../../../common";
import { Enabled } from "./Enabled";

export function ManagedDatabases() {
    const localization =
        useLocalization(
            "views.customer.configuration.dataAnalysis.managedDatabases",
            () => ({
                managedDatabaseScanEnabled: "Managed Database Scanning"
            }));

    const theme = useTheme();
    return (
        <Grid2
            size={{ xs: 8 }}
            sx={{
                height: "100%",
                maxWidth: theme.spacing(125),
                overflowY: "auto",
                padding: theme.spacing(3, 4)
            }}>
            <Stack>
                <Enabled
                    getConfigurationControllerUpdateDataAnalysisRequest={
                        (scopeNodeModelConfiguration, enabled) =>
                            new Contract.ConfigurationControllerUpdateDataAnalysisRequest(
                                enabled,
                                scopeNodeModelConfiguration.scopeSections.dataAnalysis.objectStoreScanEnabled,
                                scopeNodeModelConfiguration.scopeSections.dataAnalysis.privateObjectStoreScanEnabled,
                                scopeNodeModelConfiguration.id)}
                    getConfigurationEnabled={dataAnalysis => dataAnalysis.managedDatabaseScanEnabled}
                    title={localization.managedDatabaseScanEnabled()}/>
            </Stack>
        </Grid2>);
}