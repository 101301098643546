﻿import _, { Dictionary } from "lodash";
import React, { useRef, useState } from "react";
import { CsvExportButton, DataTable, DataTableAction, DataTableActions, DataTableSort, EmptyMessageText, Optional, TimeHelper, useGetExportFileName, useLocalization } from "@infrastructure";
import { Contract, ExportReportHelper, StorageHelper, useLayoutOptions } from "../../../../../../common";
import { useCommonContainerImageDefinition, useFetchWorkloadResourceModelPage, useGetWorkloadResourceModelsRequestSort } from "../../hooks";
import { WorkloadResourceModelRequestFiltersHelper } from "../../utilities";

type ContainerImagesProps = {
    vulnerabilityRawId?: string;
};

export function ContainerImages({ vulnerabilityRawId }: ContainerImagesProps) {
    const commonContainerImageDefinition = useCommonContainerImageDefinition(vulnerabilityRawId);
    const dataTableActionsRef = useRef<DataTableActions>();
    const getWorkloadResourceModelsRequestSort = useGetWorkloadResourceModelsRequestSort();

    const localization =
        useLocalization(
            "views.customer.workloadAnalysis.containerImages",
            () => ({
                empty: {
                    workloads: {
                        withFilter: "No Matching Container Images",
                        withoutFilter: "No Container Images"
                    }
                },
                title: "Container Images"
            }));
    useLayoutOptions({
        view:
            _.isNil(vulnerabilityRawId)
                ? { title: localization.title() }
                : undefined
    });

    const fetchWorkloadResourceModelPage =
        useFetchWorkloadResourceModelPage(
            dataTableActionsRef,
            WorkloadResourceModelRequestFiltersHelper.getContainerImageFilters,
            Contract.WorkloadControllerGetWorkloadResourceModelPageContainerImageRequest,
            vulnerabilityRawId);

    const filterMapRef = useRef<Dictionary<any>>();
    const sortRef = useRef<Optional<DataTableSort>>();
    const [workloadResourcesCount, setWorkloadResourcesCount] = useState<number>();

    const getExportFileName = useGetExportFileName(Contract.ReportContentType.Csv);

    return (
        <DataTable
            actionsRef={dataTableActionsRef}
            columnOptions={{
                orderOptions: {
                    enabled: true,
                    persistenceStorageItem:
                        vulnerabilityRawId
                            ? StorageHelper.customerWorkloadAnalysisContainerImagesProfileTableColumnOrder
                            : StorageHelper.customerWorkloadAnalysisContainerImagesTableColumnOrder
                },
                resizable: true,
                selectorOptions: {
                    enabled: true,
                    persistenceStorageItem:
                        vulnerabilityRawId
                            ? StorageHelper.customerWorkloadAnalysisContainerImagesProfileTableColumnSelector
                            : StorageHelper.customerWorkloadAnalysisContainerImagesTableColumnSelector
                },
                stickyColumnId: Contract.WorkloadControllerRequestProperty.ContainerImageId
            }}
            emptyMessageOptions={{
                emptyMessageText:
                    new EmptyMessageText(
                        localization.empty.workloads.withoutFilter(),
                        localization.empty.workloads.withFilter())
            }}
            fetchItems={fetchWorkloadResourceModelPage}
            filtersOptions={{
                initial: {
                    state: {
                        [Contract.WorkloadControllerRequestProperty.ContainerImageWorkloadExists]: true
                    }
                },
                onChanged: filterMap => filterMapRef.current = filterMap,
                persist: {
                    visibilityStorageItem:
                        vulnerabilityRawId
                            ? StorageHelper.customerWorkloadAnalysisContainerImagesProfileTableFilters
                            : StorageHelper.customerWorkloadAnalysisContainerImagesTableFilters
                },
                ...(vulnerabilityRawId && {
                    sx: {
                        paddingLeft: 0,
                        paddingTop: 0
                    }
                })
            }}
            getItemId={(workloadResourceModel: Contract.WorkloadResourceModel) => workloadResourceModel.id}
            virtualizationEnabled={true}
            onItemCountChanged={setWorkloadResourcesCount}
            onSortChanged={sort => sortRef.current = sort}>
            {commonContainerImageDefinition.columns.containerImageId}
            {commonContainerImageDefinition.columns.tenantId}
            {commonContainerImageDefinition.columns.containerImageRepository}
            {commonContainerImageDefinition.columns.containerImageTags}
            {commonContainerImageDefinition.columns.containerImageDigest}
            {commonContainerImageDefinition.columns.creationTime}
            {commonContainerImageDefinition.columns.workloadClusterResources}
            {commonContainerImageDefinition.columns.virtualMachines}
            {commonContainerImageDefinition.columns.kubernetesWorkloadResources}
            {commonContainerImageDefinition.columns.operatingSystemDisplayName}
            {commonContainerImageDefinition.columns.operatingSystemType}
            {commonContainerImageDefinition.columns.packageDisplayName}
            {commonContainerImageDefinition.columns.vulnerabilities}
            {commonContainerImageDefinition.columns.risksSeverity}
            {commonContainerImageDefinition.columns.vulnerabilityAttackVector}
            {commonContainerImageDefinition.columns.scanTime}
            {commonContainerImageDefinition.columns.attributes}
            {commonContainerImageDefinition.columns.containerImageUsage}
            <DataTableAction>
                <CsvExportButton
                    fileNameOptions={{
                        filtered: !_.isEmpty(filterMapRef.current),
                        prefix: localization.title()
                    }}
                    itemCount={workloadResourcesCount}
                    onClick={
                        async fileNameOptions => {
                            const reportRequestDefinition =
                                new Contract.ReportControllerContainerImagesReportRequestDefinition(
                                    getExportFileName(fileNameOptions),
                                    TimeHelper.timeZoneId(),
                                    Contract.TypeNames.ReportControllerContainerImagesReportRequestDefinition,
                                    true,
                                    WorkloadResourceModelRequestFiltersHelper.getContainerImageFilters(
                                        filterMapRef.current ?? {},
                                        dataTableActionsRef,
                                        vulnerabilityRawId),
                                    getWorkloadResourceModelsRequestSort(sortRef.current));
                            await ExportReportHelper.downloadRemote(reportRequestDefinition);
                        }}/>
            </DataTableAction>
        </DataTable>);
}