import _, { Dictionary } from "lodash";
import { Contract, EntityController } from "..";

export class EntityTypeMetadataModelHelper {
    private static entityTypeNameToMetadataModelMap: Dictionary<Contract.EntityTypeMetadataModel>;

    public static async initialize() {
        const { entityTypeMetadataModels } = await EntityController.getEntityTypeMetadataModels();
        EntityTypeMetadataModelHelper.entityTypeNameToMetadataModelMap =
            _.keyBy(
                entityTypeMetadataModels,
                entityTypeMetadataModel => entityTypeMetadataModel.name);
    }

    public static get(entityTypeName: string) {
        return EntityTypeMetadataModelHelper.entityTypeNameToMetadataModelMap[entityTypeName];
    }

    public static getAll() {
        return _.values(EntityTypeMetadataModelHelper.entityTypeNameToMetadataModelMap);
    }
}