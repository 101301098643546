import { AnalyticsEventActionType, CheckboxField, Dialog, FormLayout, Message, Optional, TimeHelper, useAsyncEffect, useChangeEffect, useExecuteOperation, useInputValidation, useLocalization, useTrackAnalytics } from "@infrastructure";
import { Button, CircularProgress, FormControl, FormHelperText, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { Fragment, useState } from "react";
import { AccessController, Contract, DocumentsViewer, ElasticsearchItemPageHelper, EntityController, entityModelStore, NonexcessivePermitterDateSelectorField, PagedEntityMultiSelect, PagedEntitySelector, RadioGroup, tenantModelStore, TenantMultiSelect, useEntityTypeNameTranslator, useTheme } from "../../../common";

export type GenerateGcpIdentityNonexcessiveRoleDialogSelectionOptions = {
    excessivePermissionEvaluationStartDate?: string;
    resourceManagerResourceIds: string[];
    resourceManagerResourceTypeName: string;
};

type GenerateGcpIdentityNonexcessiveRoleDialogProps = {
    identityModel: Contract.IPrincipalModel;
    onClose: () => void;
    selectionOptions?: GenerateGcpIdentityNonexcessiveRoleDialogSelectionOptions;
};

export function GenerateGcpIdentityNonexcessiveRoleDialog({ identityModel, onClose, selectionOptions }: GenerateGcpIdentityNonexcessiveRoleDialogProps) {
    return (
        <Dialog
            size="medium"
            variant="editor"
            onClose={onClose}>
            <Core
                identityModel={identityModel}
                selectionOptions={selectionOptions}
                onClose={onClose}/>
        </Dialog>);
}

function Core({ identityModel, onClose, selectionOptions }: GenerateGcpIdentityNonexcessiveRoleDialogProps) {
    const [{ destinationTenantIds }] =
        useExecuteOperation(
            [GenerateGcpIdentityNonexcessiveRoleDialog, identityModel.id],
            async () => await AccessController.getIdentityDestinationTenantIds(new Contract.AccessControllerGetGcpIdentityDestinationTenantIdsRequest(identityModel.id)));

    const [entityExcessivePermissionCustomEvaluationData, setEntityExcessivePermissionCustomEvaluationData] = useState<Contract.EntityControllerGetEntityExcessivePermissionCustomEvaluationDataResponse | undefined>(undefined);
    const [excessivePermissionEvaluationStartDate, setExcessivePermissionEvaluationStartDate] = useState<string>();
    const [includeGciDirectoryGroupRoleBindings, setIncludeGciDirectoryGroupRoleBindings] = useState(false);
    const [includeGcpSpecialGroupRoleBindings, setIncludeGcpSpecialGroupRoleBindings] = useState(true);
    const [generateError, setGenerateError] = useState<Contract.EntityControllerGenerateGcpIdentityNonexcessiveRoleError | undefined>(undefined);
    const [generateRoleExecuting, setGenerateRoleExecuting] = useState(false);
    const [rawRoles, setRawRoles] = useState<string[] | undefined>();
    const [resourceManagerResourceIds, setResourceManagerResourceIds] = useState<string[]>(selectionOptions?.resourceManagerResourceIds ?? []);
    const [resourceManagerResourceTypeName, setResourceManagerResourceTypeName] = useState(selectionOptions?.resourceManagerResourceTypeName ?? Contract.TypeNames.GcpTenantEntity);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const translatedIdentityTypeName =
        entityTypeNameTranslator(
            identityModel.entity.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });
    const minTime = _.last(entityExcessivePermissionCustomEvaluationData?.dateInfos);

    const localization =
        useLocalization(
            "tenants.gcp.generateGcpIdentityNonexcessiveRoleDialog",
            () => ({
                actions: {
                    close: "Done",
                    generate: {
                        [Contract.TypeNames.EntityControllerGenerateGcpIdentityNonexcessiveRoleError]: {
                            [Contract.EntityControllerGenerateGcpIdentityNonexcessiveRoleError.AllExcessive]: "Unable to generate a custom role for this {{translatedIdentityTypeName}}, because there is no activity in the chosen time frame",
                            [Contract.EntityControllerGenerateGcpIdentityNonexcessiveRoleError.AllNonexcessive]: "Permissions are not excessive based on the chosen scope and time frame, so the existing roles should be kept",
                            [Contract.EntityControllerGenerateGcpIdentityNonexcessiveRoleError.CommonErrorGeneration]: "Unable to generate a custom role for this {{translatedIdentityTypeName}}. For more information contact support via the 'Contact us' link under the '?' menu (top right)",
                            [Contract.EntityControllerGenerateGcpIdentityNonexcessiveRoleError.NoPermissions]: "Unable to generate a custom role for this {{translatedIdentityTypeName}}, because it has no permissions in the chosen scope"
                        },
                        title: "Generate"
                    }
                },
                fields: {
                    excessivePermissionEvaluationStartDate: {
                        disabled: "Unable to generate a custom role - no activity in the selected scopes",
                        footer: {
                            [Contract.EntityControllerGetEntityExcessivePermissionCustomEvaluationDataResponseDateType.CreationDate]: "Activity is available from {{minDate | TimeFormatter.monthDayAndYear}} ({{translatedIdentityTypeName}} creation) until {{eventsTime | TimeFormatter.monthDayAndYear}} ({{dateSelectionRange | TimeSpanFormatter.humanizeDays}} in total)",
                            [Contract.EntityControllerGetEntityExcessivePermissionCustomEvaluationDataResponseDateType.EventStartDate]: "Activity is available from {{minDate | TimeFormatter.monthDayAndYear}} (max activity time) until {{eventsTime | TimeFormatter.monthDayAndYear}} ({{dateSelectionRange | TimeSpanFormatter.humanizeDays}} in total)"
                        }
                    },
                    includeGciDirectoryGroupRoleBindings: "Include permissions granted via group membership",
                    includeGcpSpecialGroupRoleBindings: "Include permissions granted via basic roles assigned at the project level (e.g. Project Owner)",
                    resourceManagerResourceIds: {
                        error: {
                            gciTenantMany: "{{translatedResourceManagerResourceTypeName}} must be from the same organization"
                        },
                        footer: {
                            [Contract.TypeNames.GcpTenantEntity]: "Since you selected multiple projects, the role will be generated as an organization-level role",
                            [Contract.TypeNames.GcpResourceManagerFolder]: "Since you selected one or more folders, the role will be generated as an organization-level role"
                        },
                        placeholder: {
                            [Contract.TypeNames.GcpResourceManagerOrganization]: "Select one organization",
                            [Contract.TypeNames.GcpResourceManagerFolder]: "Select one or more folders",
                            [Contract.TypeNames.GcpTenantEntity]: "Select one or more projects"
                        }
                    },
                    resourceManagerResourceType: {
                        subtitle: "Choose a scope level to determine which resources are included in the analysis, and at what level to assign the role to the {{translatedIdentityTypeName}}.",
                        title: "Scope Level"
                    }
                },
                footer: "The custom role will be based on activity from {{excessivePermissionEvaluationStartDate | TimeFormatter.monthDayAndYear}} to today ({{excessivePermissionEvaluationTimeRange | TimeSpanFormatter.humanizeDays}} in total)",
                nonexcessivePermissionsRawRole: {
                    many: "LeastPrivilegeRole({{rawRoleIndex}} of {{rawRoleCount}})",
                    single: "LeastPrivilegeRole"
                },
                subtitle: "Generate a custom role which you can then use in GCP to grant the minimum privileges needed.",
                title: "Least Privilege Custom Role"
            }));

    const folderModels =
        entityModelStore.useGet(
            resourceManagerResourceTypeName === Contract.TypeNames.GcpResourceManagerFolder
                ? resourceManagerResourceIds
                : undefined);
    const tenantModels =
        tenantModelStore.useGet(
            resourceManagerResourceTypeName === Contract.TypeNames.GcpTenantEntity
                ? resourceManagerResourceIds
                : undefined);
    const [resourceManagerResourceIdsValidationController, resourceManagerResourceIdsValidationMessage] =
        useInputValidation(
            () => {
                if (_.uniqBy(folderModels, folderModel => folderModel.tenantId).length > 1) {
                    return localization.fields.resourceManagerResourceIds.error.gciTenantMany({
                        translatedResourceManagerResourceTypeName:
                            entityTypeNameTranslator(
                                resourceManagerResourceTypeName,
                                { count: 0 })
                    });
                }

                if (_.uniqBy(tenantModels, tenantModel => (tenantModel.configuration as Contract.GcpTenantConfiguration).gciTenantId).length > 1) {
                    return localization.fields.resourceManagerResourceIds.error.gciTenantMany({
                        translatedResourceManagerResourceTypeName:
                            entityTypeNameTranslator(
                                resourceManagerResourceTypeName,
                                { count: 0 })
                    });
                }

                return undefined;
            },
            [resourceManagerResourceIds, resourceManagerResourceTypeName]);

    const [valid, setValid] = useState(false);
    useChangeEffect(
        () => {
            setValid(
                resourceManagerResourceIdsValidationController.isValid() &&
                entityExcessivePermissionCustomEvaluationData?.nonexcessivePermitterCustomGenerationEnabled === true &&
                !_.isNil(excessivePermissionEvaluationStartDate));
        },
        [resourceManagerResourceIds, entityExcessivePermissionCustomEvaluationData, excessivePermissionEvaluationStartDate]);

    const trackAnalytics = useTrackAnalytics();
    async function generateNonexcessiveRole() {
        setGenerateRoleExecuting(true);
        setGenerateError(undefined);
        setRawRoles(undefined);

        try {
            const response =
                await EntityController.generateGcpIdentityNonexcessiveRole(
                    new Contract.EntityControllerGenerateGcpIdentityNonexcessiveRoleRequest(
                        excessivePermissionEvaluationStartDate!,
                        identityModel.id,
                        includeGciDirectoryGroupRoleBindings,
                        includeGcpSpecialGroupRoleBindings,
                        resourceManagerResourceIds));

            if (!_.isNil(response.error)) {
                setGenerateError(response.error);
            } else {
                trackAnalytics(
                    AnalyticsEventActionType.AccessGenerateLeastPrivilege,
                    { "Type": localization.title() });
                setRawRoles(response.rawRoles);
            }
        } catch {
            setGenerateError(Contract.EntityControllerGenerateGcpIdentityNonexcessiveRoleError.CommonErrorGeneration);
        }

        setGenerateRoleExecuting(false);
    }

    async function getEntityExcessivePermissionCustomEvaluationData(
        resourceManagerResourceIds: string[],
        resourceManagerResourceIdsValidationMessage: Optional<string>) {
        setExcessivePermissionEvaluationStartDate(undefined);
        setGenerateError(undefined);
        try {
            setEntityExcessivePermissionCustomEvaluationData(
                _.isEmpty(resourceManagerResourceIds) ||
                !_.isNil(resourceManagerResourceIdsValidationMessage)
                    ? undefined
                    : await EntityController.getEntityExcessivePermissionCustomEvaluationData(
                        new Contract.EntityControllerGetGcpEntityExcessivePermissionCustomEvaluationDataRequest(
                            identityModel.entity.id,
                            resourceManagerResourceIds)));
        } catch (error) {
            setEntityExcessivePermissionCustomEvaluationData(undefined);
        }
    }

    useAsyncEffect(
        async () => {
            await getEntityExcessivePermissionCustomEvaluationData(
                resourceManagerResourceIds,
                resourceManagerResourceIdsValidationMessage);
        },
        [resourceManagerResourceIds, resourceManagerResourceIdsValidationMessage]);

    useAsyncEffect(
        async () => {
            if (!_.isNil(selectionOptions)) {
                await getEntityExcessivePermissionCustomEvaluationData(
                    selectionOptions.resourceManagerResourceIds,
                    resourceManagerResourceIdsValidationMessage);
                if (!_.isNil(selectionOptions.excessivePermissionEvaluationStartDate)) {
                    setExcessivePermissionEvaluationStartDate(selectionOptions.excessivePermissionEvaluationStartDate);
                }
            }
        },
        []);

    const theme = useTheme();
    return <FormLayout
        footerOptions={{
            contentElement:
                <Stack
                    alignItems="center"
                    direction="row-reverse"
                    justifyContent="space-between"
                    spacing={1}>
                    {_.isNil(rawRoles)
                        ? <Fragment>
                            <Stack
                                alignItems="center"
                                direction="row-reverse"
                                justifyContent="flex-start"
                                spacing={1}>
                                <Button
                                    disabled={!valid || generateRoleExecuting}
                                    onClick={() => generateNonexcessiveRole()}>
                                    {localization.actions.generate.title()}
                                </Button>
                                {generateRoleExecuting &&
                                    <CircularProgress
                                        size={theme.spacing(2)}
                                        variant="indeterminate"/>}
                            </Stack>
                            {valid && _.isNil(generateError) &&
                                <Message
                                    level="info"
                                    title={localization.footer({
                                        excessivePermissionEvaluationStartDate,
                                        excessivePermissionEvaluationTimeRange: TimeHelper.getInterval(excessivePermissionEvaluationStartDate!)
                                    })}/>}
                            {!_.isNil(generateError) &&
                                <Message
                                    level={
                                        generateError === Contract.EntityControllerGenerateGcpIdentityNonexcessiveRoleError.AllNonexcessive
                                            ? "info"
                                            : "error"}
                                    title={localization.actions.generate[Contract.TypeNames.EntityControllerGenerateGcpIdentityNonexcessiveRoleError][generateError]({ translatedIdentityTypeName })}/>}
                        </Fragment>
                        : <Button onClick={onClose}>
                            {localization.actions.close()}
                        </Button>}
                </Stack>
        }}
        titleOptions={{
            subtitle: localization.subtitle(),
            text: localization.title()
        }}>
        {!_.isNil(rawRoles)
            ? <DocumentsViewer
                documents={
                    _.map(
                        rawRoles,
                        (rawRole, rawRoleIndex) => ({
                            document: rawRole,
                            name:
                                rawRoles.length === 1
                                    ? localization.nonexcessivePermissionsRawRole.single()
                                    : localization.nonexcessivePermissionsRawRole.many({
                                        rawRoleCount: rawRoles.length,
                                        rawRoleIndex: rawRoleIndex + 1
                                    })
                        }))
                }/>
            : <Stack spacing={3}>
                <Stack spacing={1.5}>
                    <Stack spacing={1}>
                        <Typography variant="h4">
                            {localization.fields.resourceManagerResourceType.title()}
                        </Typography>
                        <Typography sx={{ fontSize: "13px" }}>
                            {localization.fields.resourceManagerResourceType.subtitle({ translatedIdentityTypeName })}
                        </Typography>
                    </Stack>
                    <RadioGroup
                        disabled={generateRoleExecuting}
                        items={[
                            {
                                disabled:
                                    !_.isNil(selectionOptions) &&
                                    selectionOptions.resourceManagerResourceTypeName !== Contract.TypeNames.GcpResourceManagerOrganization,
                                label: entityTypeNameTranslator(Contract.TypeNames.GcpResourceManagerOrganization),
                                value: Contract.TypeNames.GcpResourceManagerOrganization
                            },
                            {
                                disabled:
                                    !_.isNil(selectionOptions) &&
                                    selectionOptions.resourceManagerResourceTypeName !== Contract.TypeNames.GcpResourceManagerFolder,
                                label:
                                    entityTypeNameTranslator(
                                        Contract.TypeNames.GcpResourceManagerFolder,
                                        { count: 0 }),
                                value: Contract.TypeNames.GcpResourceManagerFolder
                            },
                            {
                                disabled:
                                    !_.isNil(selectionOptions) &&
                                    selectionOptions.resourceManagerResourceTypeName !== Contract.TypeNames.GcpTenantEntity,
                                label:
                                    entityTypeNameTranslator(
                                        Contract.TypeNames.GcpTenantEntity,
                                        { count: 0 }),
                                value: Contract.TypeNames.GcpTenantEntity
                            }
                        ]}
                        selectedValue={resourceManagerResourceTypeName}
                        onChange={
                            event => {
                                setExcessivePermissionEvaluationStartDate(undefined);
                                setEntityExcessivePermissionCustomEvaluationData(undefined);
                                setResourceManagerResourceIds([]);
                                setResourceManagerResourceTypeName(event);
                            }}/>
                </Stack>
                <FormControl
                    fullWidth={true}
                    variant="standard">
                    <Stack spacing={1}>
                        {resourceManagerResourceTypeName === Contract.TypeNames.GcpResourceManagerFolder &&
                            <PagedEntityMultiSelect
                                disabled={generateRoleExecuting}
                                fullWidth={true}
                                getEntityModelPage={
                                    ElasticsearchItemPageHelper.makePagedEntityMultiSelect(
                                        async (itemNextPageSearchCursor, searchText) => {
                                            const { entityModelPage } =
                                                await EntityController.searchEntityModels(
                                                    new Contract.EntityControllerSearchEntityModelsTypeRequest(
                                                        false,
                                                        15,
                                                        itemNextPageSearchCursor,
                                                        undefined,
                                                        searchText,
                                                        false,
                                                        resourceManagerResourceTypeName));
                                            return entityModelPage;
                                        })}
                                placeholder={localization.fields.resourceManagerResourceIds.placeholder.translate(resourceManagerResourceTypeName)}
                                selectedEntityIds={resourceManagerResourceIds}
                                onSelectedEntityIdsChanged={selectedFolderIds => setResourceManagerResourceIds(selectedFolderIds)}/>}
                        {resourceManagerResourceTypeName === Contract.TypeNames.GcpResourceManagerOrganization &&
                            <PagedEntitySelector
                                disabled={generateRoleExecuting}
                                fullWidth={true}
                                getEntityModelPage={
                                    ElasticsearchItemPageHelper.makePagedEntityMultiSelect(
                                        async (itemNextPageSearchCursor, searchText) => {
                                            const { entityModelPage } =
                                                await EntityController.searchEntityModels(
                                                    new Contract.EntityControllerSearchEntityModelsTypeRequest(
                                                        false,
                                                        15,
                                                        itemNextPageSearchCursor,
                                                        undefined,
                                                        searchText,
                                                        false,
                                                        resourceManagerResourceTypeName));
                                            return entityModelPage;
                                        })}
                                placeholder={localization.fields.resourceManagerResourceIds.placeholder.translate(resourceManagerResourceTypeName)}
                                selectedEntityId={resourceManagerResourceIds[0]}
                                onSelectedEntityIdChanged={selectedOrganizationId => setResourceManagerResourceIds([selectedOrganizationId])}/>}
                        {resourceManagerResourceTypeName === Contract.TypeNames.GcpTenantEntity &&
                            <TenantMultiSelect
                                disabled={generateRoleExecuting}
                                displayAllAsEmpty={false}
                                fullWidth={true}
                                placeholder={localization.fields.resourceManagerResourceIds.placeholder.translate(resourceManagerResourceTypeName)}
                                selectedTenantIds={resourceManagerResourceIds}
                                tenantIds={destinationTenantIds}
                                variant="itemSelector"
                                onSelectedTenantIdsChanged={selectedTenantIds => setResourceManagerResourceIds(selectedTenantIds)}/>}
                        {_.isNil(resourceManagerResourceIdsValidationMessage) &&
                            (resourceManagerResourceTypeName === Contract.TypeNames.GcpResourceManagerFolder && resourceManagerResourceIds.length > 0 ||
                                resourceManagerResourceTypeName === Contract.TypeNames.GcpTenantEntity && resourceManagerResourceIds.length > 1) &&
                            <Message
                                level="info"
                                title={
                                    <Typography sx={{ fontSize: "12px" }}>
                                        {localization.fields.resourceManagerResourceIds.footer.translate(resourceManagerResourceTypeName)}
                                    </Typography>}/>}
                        {!_.isNil(resourceManagerResourceIdsValidationMessage) &&
                            <FormHelperText error={true}>
                                {resourceManagerResourceIdsValidationMessage}
                            </FormHelperText>}
                    </Stack>
                </FormControl>
                <FormControl
                    fullWidth={true}
                    variant="standard">
                    <Stack spacing={1}>
                        <NonexcessivePermitterDateSelectorField
                            disabled={
                                _.isEmpty(resourceManagerResourceIds) ||
                                !resourceManagerResourceIdsValidationController.isValid() ||
                                entityExcessivePermissionCustomEvaluationData?.nonexcessivePermitterCustomGenerationEnabled === false ||
                                generateRoleExecuting}
                            entityExcessivePermissionCustomEvaluationData={entityExcessivePermissionCustomEvaluationData}
                            selectedDate={excessivePermissionEvaluationStartDate}
                            onSelectedDateChanged={selectedDate => {
                                setExcessivePermissionEvaluationStartDate(selectedDate);
                                setGenerateError(undefined);
                            }}/>
                        {entityExcessivePermissionCustomEvaluationData?.nonexcessivePermitterCustomGenerationEnabled &&
                            _.isNil(resourceManagerResourceIdsValidationMessage) &&
                            <Typography sx={{ fontSize: "12px" }}>
                                {localization.fields.excessivePermissionEvaluationStartDate.footer.translate(
                                    minTime!.type,
                                    {
                                        dateSelectionRange: TimeHelper.getDuration(
                                            minTime!.date,
                                            entityExcessivePermissionCustomEvaluationData.eventsTime!),
                                        eventsTime: entityExcessivePermissionCustomEvaluationData.eventsTime,
                                        minDate: minTime!.date,
                                        translatedIdentityTypeName
                                    })}
                            </Typography>}
                        {entityExcessivePermissionCustomEvaluationData?.nonexcessivePermitterCustomGenerationEnabled === false &&
                            _.isNil(resourceManagerResourceIdsValidationMessage) &&
                            <FormHelperText error={true}>
                                {localization.fields.excessivePermissionEvaluationStartDate.disabled()}
                            </FormHelperText>}
                    </Stack>
                </FormControl>
                <CheckboxField
                    checked={includeGciDirectoryGroupRoleBindings}
                    disabled={generateRoleExecuting}
                    onChange={() => setIncludeGciDirectoryGroupRoleBindings(!includeGciDirectoryGroupRoleBindings)}>
                    <Typography
                        sx={{
                            color:
                                generateRoleExecuting
                                    ? theme.palette.text.disabled
                                    : theme.palette.text.primary,
                            fontSize: "12px"
                        }}>
                        {localization.fields.includeGciDirectoryGroupRoleBindings()}
                    </Typography>
                </CheckboxField>
                <CheckboxField
                    checked={includeGcpSpecialGroupRoleBindings}
                    disabled={generateRoleExecuting}
                    onChange={() => setIncludeGcpSpecialGroupRoleBindings(!includeGcpSpecialGroupRoleBindings)}>
                    <Typography
                        sx={{
                            color:
                                generateRoleExecuting
                                    ? theme.palette.text.disabled
                                    : theme.palette.text.primary,
                            fontSize: "12px"
                        }}>
                        {localization.fields.includeGcpSpecialGroupRoleBindings()}
                    </Typography>
                </CheckboxField>
            </Stack>}
    </FormLayout>;
}