import { DataTable, DataTableColumn, DataTableColumnRenderProps, useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, Entity, entityModelStore, InfoCard, useTheme } from "../../../../../../../../../common";

type AwsEc2RouteTableRoutesInfoCardProps = {
    routeTableId: string;
    title: string;
};

export function AwsEc2RouteTableRoutesInfoCard({ routeTableId, title }: AwsEc2RouteTableRoutesInfoCardProps) {
    const routeTableModel = entityModelStore.useGet(routeTableId);
    const routes = (routeTableModel.entity as Contract.AwsEc2RouteTable).routes;
    const targetResourceModels =
        entityModelStore.useGet(
            _(routes).
                map(route => route.targetResourceId!).
                filter().
                value());
    const items =
        useMemo(
            () => {
                const targetResourceModelMap =
                    _(targetResourceModels).
                        filter().
                        keyBy(targetResourceModel => targetResourceModel.entity.id).
                        value();
                return _.map(
                    routes,
                    route =>
                        new AwsEc2RouteTableRoutesInfoCardItem(
                            route,
                            _.isNil(route.targetResourceId)
                                ? undefined
                                : targetResourceModelMap[route.targetResourceId]));
            },
            [routes]);

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.awsEc2RouteTableRoutesInfoCard",
            () => ({
                columns: {
                    destination: "Destination",
                    target: "Target",
                    valid: {
                        false: "Blackhole",
                        title: "Status",
                        true: "Active"
                    }
                }
            }));
    const theme = useTheme();
    return (
        <InfoCard title={title}>
            <DataTable
                fetchItems={() => items}
                getItemId={(item: AwsEc2RouteTableRoutesInfoCardItem) => item.route.destinationPrefixListRawId ?? item.route.destinationSubnet!}
                sortOptions={{ enabled: false }}
                variant="card">
                <DataTableColumn
                    id={RouteTableRouteColumnId.Destination}
                    itemProperty={(item: AwsEc2RouteTableRoutesInfoCardItem) => item.route.destinationPrefixListRawId ?? item.route.destinationSubnet}
                    title={localization.columns.destination()}/>
                <DataTableColumn
                    id={RouteTableRouteColumnId.Target}
                    render={
                        ({ item }: DataTableColumnRenderProps<AwsEc2RouteTableRoutesInfoCardItem>) =>
                            _.isNil(item.targetResourceModel)
                                ? <Typography noWrap={true}>{item.route.targetResourceRawId}</Typography>
                                : <Entity
                                    entityIdOrModel={item.targetResourceModel}
                                    variant="iconTextTenant"/>}
                    title={localization.columns.target()}/>
                <DataTableColumn
                    id={RouteTableRouteColumnId.Valid}
                    render={
                        ({ item }: DataTableColumnRenderProps<AwsEc2RouteTableRoutesInfoCardItem>) =>
                            <Typography
                                noWrap={true}
                                sx={{
                                    color:
                                        item.route.valid
                                            ? theme.palette.success.main
                                            : theme.palette.error.main
                                }}>
                                {item.route.valid
                                    ? localization.columns.valid.true()
                                    : localization.columns.valid.false()}
                            </Typography>}
                    title={localization.columns.valid.title()}/>
            </DataTable>
        </InfoCard>);
}

class AwsEc2RouteTableRoutesInfoCardItem {
    constructor(
        public route: Contract.AwsEc2RouteTableRoute,
        public targetResourceModel?: Contract.EntityModel) {
    }
}

enum RouteTableRouteColumnId {
    Destination = "destination",
    Target = "target",
    Valid = "valid"
}