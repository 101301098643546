import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, Entity, EntityTypeNameTranslatorOptions, RegionModelHelper, TypeHelper, useEntityTypeNameTranslator } from "../../../../../../../../../../../common";


export function useAzureResourceGeneralInformationStep(resourceModel: Contract.EntityModel, resourceTypeNameTranslationOptions?: EntityTypeNameTranslatorOptions) {
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.useResourceGeneralInformationStep.hooks.useAzureResourceGeneralInformationStep",
            () => ({
                creationTime: {
                    resource: "*capitalize*{{resourceTypeName}}** {{resource}} was created on {{creationTime | TimeFormatter.shortDate}}",
                    resourceGroupResource: "*capitalize*{{resourceTypeName}}** {{resource}} is part of {{resourceGroup}} located at {{region}} and was created on {{creationTime | TimeFormatter.shortDate}}"
                },
                resourceGroupResource: "*capitalize*{{resourceTypeName}}** {{resource}} is part of {{resourceGroup}} located at {{region}}"
            }));

    return useMemo(
        () => {
            const azureResourceModel = resourceModel as Contract.AzureResourceModel;
            const azureResource = azureResourceModel.entity as Contract.AzureResource;
            const resourceGroupResource =
                TypeHelper.extendOrImplement(azureResourceModel.entity.typeName, Contract.TypeNames.AzureResourceGroupResource)
                    ? azureResource as Contract.AzureResourceGroupResource
                    : undefined;

            const resourceLocalizationOptions = {
                creationTime: azureResourceModel.creationTime,
                region: RegionModelHelper.tryGet(azureResourceModel.entity.regionId)?.name,
                resource:
                    <Entity
                        entityIdOrModel={azureResourceModel}
                        variant="text"/>,
                resourceGroup:
                    _.isNil(resourceGroupResource?.resourceGroupId)
                        ? undefined
                        : <Entity
                            entityIdOrModel={resourceGroupResource!.resourceGroupId}
                            variant="typeText"/>,
                resourceTypeName:
                    entityTypeNameTranslator(
                        azureResourceModel.entity.typeName,
                        {
                            includeServiceName: false,
                            variant: "text",
                            ...resourceTypeNameTranslationOptions
                        })
            };
            const hasCreationTime = !_.isNil(azureResourceModel.creationTime);

            if (hasCreationTime) {
                return _.isNil(resourceGroupResource)
                    ? localization.creationTime.resource(resourceLocalizationOptions)
                    : localization.creationTime.resourceGroupResource(resourceLocalizationOptions);
            } else {
                return _.isNil(resourceGroupResource)
                    ? undefined
                    : localization.resourceGroupResource(resourceLocalizationOptions);
            }
        },
        [resourceModel]);
}