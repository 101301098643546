﻿import { ActionButton, useLocalization } from "@infrastructure";
import { Stack } from "@mui/material";
import React, { useState } from "react";
import { DiffViewerIcon, useTheme } from "../../../../../../../../../../../../../../../common";
import { RolesDiffDialog } from "./components";

type ReplaceRoleBindingsChangeDiffProps = {
    existingRoleIds: string[];
    newRoleIds: string[];
};

export function ReplaceRoleBindingsChangeDiff({ existingRoleIds, newRoleIds }: ReplaceRoleBindingsChangeDiffProps) {
    const [roleDiff, setRoleDiff] = useState(false);
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.access.useGcpExcessivePermissionPrincipalRiskDefinition.replaceRoleBindingsChangeDiff",
            () => ({
                actions: {
                    diff: "Diff"
                }
            }));
    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            direction="row">
            {roleDiff &&
                <RolesDiffDialog
                    existingRoleIds={existingRoleIds}
                    newRoleIds={newRoleIds}
                    onClose={() => setRoleDiff(false)}/>}
            <ActionButton
                tooltip={localization.actions.diff()}
                onClick={() => setRoleDiff(true)}>
                <DiffViewerIcon
                    sx={{
                        color: theme.palette.text.primary,
                        fontSize: "24px"
                    }}/>
            </ActionButton>
        </Stack>);
}