﻿import { CheckboxField, Link, map, Message, Optional, TextViewer, useAsyncEffect, useChangeEffect, useInputValidation, useLocalization, useSyncContext } from "@infrastructure";
import { Box, FormHelperText, Stack, Typography } from "@mui/material";
import _ from "lodash";
import { editor, MarkerSeverity } from "monaco-editor/esm/vs/editor/editor.api";
import React, { useEffect, useMemo, useState } from "react";
import { Contract, ElasticsearchItemPageHelper, EntityController, PagedEntityMultiSelect, PermissionManagementController, PermissionManagementHelper, useTheme } from "../../../../../../../../../../../../../common";
import { useAddOrEditContext, useSetAddOrEditContext } from "../../../../../../../AddOrEdit";
import { AwsSsoPermissionSetAssignmentEligibilityData, AwsSsoPermissionSetAssignmentEligibilityDataPolicyPermissions } from "../../../utilities";
import { PermissionsProps } from "../Permissions";

export function PolicyPermissions({ disabled, onChange, onValidChange }: PermissionsProps) {
    const { permissionEligibilityData, upsertPermissionEligibilityError } = useAddOrEditContext();
    const setAddOrEditContext = useSetAddOrEditContext();
    const { permissions } = permissionEligibilityData as AwsSsoPermissionSetAssignmentEligibilityData;
    const policyPermissions =
        permissions instanceof AwsSsoPermissionSetAssignmentEligibilityDataPolicyPermissions
            ? permissions as AwsSsoPermissionSetAssignmentEligibilityDataPolicyPermissions
            : undefined;

    const [awsManagedPolicyIds, setAwsManagedPolicyIds] = useState(policyPermissions?.awsManagedPolicyIds ?? []);
    const [inlinePolicyDocumentJsonEnabled, setInlinePolicyDocumentJsonEnabled] = useState(!_.isNil(policyPermissions?.inlinePolicyDocumentJson));
    const [validatePolicyDocumentTextExecuting, setValidatePolicyDocumentTextExecuting] = useState<Optional<boolean>>(undefined);
    const [validatePolicyDocumentTextIssues, setValidatePolicyDocumentTextIssues] = useState<Contract.TextIssue[]>();

    const [inlinePolicyDocumentJson, setInlinePolicyDocumentJson] =
        useState(
            () =>
                policyPermissions?.inlinePolicyDocumentJson ??
                JSON.stringify(
                    /* eslint-disable sort-keys-fix/sort-keys-fix */
                    {
                        "Version": "2012-10-17",
                        "Statement": [{
                            "Sid": "",
                            "Effect": "Allow",
                            "Action": [],
                            "Resource": []
                        }]
                    },
                    /* eslint-enable sort-keys-fix/sort-keys-fix */
                    undefined,
                    4));

    const localization =
        useLocalization(
            "views.user.permissionEligibilities.addOrEdit.hooks.useDefinition.hooks.useAwsDefinition.permissions.policyPermissions",
            () => ({
                awsManagedPolicyIds: {
                    error: {
                        empty: "You must select at least one policy or provide inline policy",
                        maxCount: "You cannot select more than {{maxCount | NumberFormatter.humanize}} policies"
                    },
                    placeholder: "AWS managed policies"
                },
                inlinePolicyDocumentJson: {
                    error: {
                        format: "Invalid policy JSON",
                        length: "Policy JSON cannot be larger than {{inlinePolicyDocumentMaxLength | NumberFormatter.humanize}} characters",
                        required: "Policy JSON cannot be empty"
                    },
                    length: "Characters left {{count | NumberFormatter.humanize}}",
                    title: "Add inline policy",
                    tooltip: {
                        links: {
                            policyGenerator: "AWS Visual Editor"
                        },
                        text: "Define organization-specific permissions, in addition to any permissions selected above. We recommend using {{policyGeneratorLink}} to generate the policy"
                    }
                }
            }));

    const [awsManagedPolicyIdsValidationController, awsManagedPolicyIdsValidationMessage] =
        useInputValidation(
            () => {
                const awsManagedPolicyMaxCount = 10;
                if (awsManagedPolicyIds.length > awsManagedPolicyMaxCount) {
                    return localization.awsManagedPolicyIds.error.maxCount({ maxCount: awsManagedPolicyMaxCount });
                } else if (!inlinePolicyDocumentJsonEnabled && _.isEmpty(awsManagedPolicyIds)) {
                    return localization.awsManagedPolicyIds.error.empty();
                }

                return undefined;
            },
            [awsManagedPolicyIds, inlinePolicyDocumentJsonEnabled]);

    const [inlinePolicyDocumentLength, setInlinePolicyDocumentLength] = useState(0);
    const [inlinePolicyDocumentJsonValidationController, inlinePolicyDocumentJsonValidationMessage] =
        useInputValidation(
            () => {
                const validationInlinePolicyDocumentJsonLength = PermissionManagementHelper.getAwsIamRawPolicyDocumentCharacterCount(inlinePolicyDocumentJson);
                setInlinePolicyDocumentLength(validationInlinePolicyDocumentJsonLength);

                if (validationInlinePolicyDocumentJsonLength === 0) {
                    return localization.inlinePolicyDocumentJson.error.required();
                }

                if (validationInlinePolicyDocumentJsonLength > PermissionManagementHelper.awsInlinePolicyDocumentMaxLength) {
                    return localization.inlinePolicyDocumentJson.error.length({ inlinePolicyDocumentMaxLength: PermissionManagementHelper.awsInlinePolicyDocumentMaxLength });
                }

                return undefined;
            },
            [inlinePolicyDocumentJson]);

    useEffect(
        () => {
            onChange(
                new AwsSsoPermissionSetAssignmentEligibilityDataPolicyPermissions(
                    awsManagedPolicyIds,
                    inlinePolicyDocumentJsonEnabled
                        ? inlinePolicyDocumentJson
                        : undefined));
        },
        [awsManagedPolicyIds, inlinePolicyDocumentJson, inlinePolicyDocumentJsonEnabled]);

    useEffect(
        () => {
            onValidChange(
                awsManagedPolicyIdsValidationController.isValid() &&
                (!inlinePolicyDocumentJsonEnabled ||
                    inlinePolicyDocumentJsonValidationController.isValid() &&
                    validatePolicyDocumentTextExecuting === false &&
                    !_.some(
                        validatePolicyDocumentTextIssues,
                        validatePolicyDocumentTextIssue => validatePolicyDocumentTextIssue.severity === Contract.Severity.High)));
        },
        [awsManagedPolicyIds, inlinePolicyDocumentJson, inlinePolicyDocumentJsonEnabled, validatePolicyDocumentTextExecuting, validatePolicyDocumentTextIssues]);

    function setValidationEnded() {
        setAddOrEditContext(
            addOrEditContext => ({
                ...addOrEditContext,
                fieldNameToValidationExecutingMap: {
                    ...addOrEditContext.fieldNameToValidationExecutingMap,
                    permissions: false
                }
            }));
    }

    function setValidationStarted() {
        setAddOrEditContext(
            addOrEditContext => ({
                ...addOrEditContext,
                fieldNameToValidationExecutingMap: {
                    ...addOrEditContext.fieldNameToValidationExecutingMap,
                    permissions: true
                }
            }));
    }

    useChangeEffect(
        setValidationStarted,
        [inlinePolicyDocumentJson]);

    const validatePolicyDocumentSyncContext = useSyncContext();

    async function validatePolicyDocumentText() {
        setValidatePolicyDocumentTextExecuting(true);
        setValidationStarted();

        if (_.isEmpty(inlinePolicyDocumentJson)) {
            setValidatePolicyDocumentTextIssues([]);
            setValidatePolicyDocumentTextExecuting(false);
            setValidationEnded();
            return;
        }

        const syncContext = validatePolicyDocumentSyncContext.create();
        let issues: Contract.TextIssue[];
        try {
            const { issues: validatePolicyDocumentIssues } = await PermissionManagementController.validateAwsIamPolicyDocument(new Contract.PermissionManagementControllerValidateAwsIamPolicyDocumentRequest(inlinePolicyDocumentJson!));
            issues = validatePolicyDocumentIssues;
        } catch {
            issues = [];
        }

        if (!validatePolicyDocumentSyncContext.isActive(syncContext)) {
            return;
        }

        setValidatePolicyDocumentTextIssues(issues);
        setValidatePolicyDocumentTextExecuting(false);
        setValidationEnded();
    }

    useAsyncEffect(
        async () => {
            if (!_.isNil(policyPermissions?.inlinePolicyDocumentJson)) {
                await validatePolicyDocumentText();
            }
        },
        []);

    useChangeEffect(
        validatePolicyDocumentText,
        [inlinePolicyDocumentJson],
        1000);

    const markers: editor.IMarkerData[] =
        useMemo(
            () =>
                _.map(
                    validatePolicyDocumentTextIssues,
                    textIssue => ({
                        endColumn: textIssue.columnRange.end,
                        endLineNumber: textIssue.lineRange.end,
                        message: textIssue.message,
                        severity:
                map(
                    textIssue.severity,
                    {
                        [Contract.Severity.High]: () => MarkerSeverity.Error,
                        [Contract.Severity.Medium]: () => MarkerSeverity.Warning,
                        [Contract.Severity.Low]: () => MarkerSeverity.Info
                    },
                    () => MarkerSeverity.Hint),
                        startColumn: textIssue.columnRange.start,
                        startLineNumber: textIssue.lineRange.start
                    })),
            [validatePolicyDocumentTextIssues]);

    const theme = useTheme();
    return (
        <Stack spacing={1}>
            <PagedEntityMultiSelect
                disabled={disabled}
                fullWidth={true}
                getEntityModelPage={
                    ElasticsearchItemPageHelper.makePagedEntitySelector(
                        async (itemNextPageSearchCursor, searchText) => {
                            const { entityModelPage } =
                                await EntityController.searchEntityModels(
                                    new Contract.EntityControllerSearchEntityModelsAwsIamManagedPolicyRequest(
                                        false,
                                        15,
                                        itemNextPageSearchCursor,
                                        undefined,
                                        searchText,
                                        true,
                                        true));
                            return entityModelPage;
                        })}
                placeholder={localization.awsManagedPolicyIds.placeholder()}
                selectedEntityIds={awsManagedPolicyIds}
                onSelectedEntityIdsChanged={setAwsManagedPolicyIds}/>
            {!_.isNil(awsManagedPolicyIdsValidationMessage) && (
                <FormHelperText error={true}>
                    {awsManagedPolicyIdsValidationMessage}
                </FormHelperText>)}
            <Stack spacing={1.5}>
                <CheckboxField
                    checked={inlinePolicyDocumentJsonEnabled}
                    disabled={disabled}
                    onChange={() => setInlinePolicyDocumentJsonEnabled(inlinePolicyDocumentJsonEnabled => !inlinePolicyDocumentJsonEnabled)}>
                    <Stack
                        alignItems="center"
                        direction="row"
                        spacing={1}>
                        <Typography
                            sx={{
                                color:
                                    disabled
                                        ? theme.palette.text.disabled
                                        : theme.palette.text.primary,
                                fontSize: "13px"
                            }}>
                            {localization.inlinePolicyDocumentJson.title()}
                        </Typography>
                        <Message
                            disabled={disabled}
                            level="info"
                            title={
                                localization.inlinePolicyDocumentJson.tooltip.text({
                                    policyGeneratorLink:
                                        <Link
                                            urlOrGetUrl="https://awspolicygen.s3.amazonaws.com/policygen.html"
                                            variant="external">
                                            {localization.inlinePolicyDocumentJson.tooltip.links.policyGenerator()}
                                        </Link>
                                })}
                            variant="minimal"/>
                    </Stack>
                </CheckboxField>
                {inlinePolicyDocumentJsonEnabled &&
                    <Stack>
                        <TextViewer
                            format="json"
                            height={theme.spacing(34)}
                            markers={markers}
                            options={{ readOnly: disabled }}
                            text={inlinePolicyDocumentJson}
                            onChange={inlinePolicyDocumentJson => setInlinePolicyDocumentJson(inlinePolicyDocumentJson ?? "")}/>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "flex-end"
                            }}>
                            <Typography
                                sx={{
                                    color:
                                        inlinePolicyDocumentLength > PermissionManagementHelper.awsInlinePolicyDocumentMaxLength
                                            ? theme.palette.error.main
                                            : theme.palette.text.secondary,
                                    fontSize: "14px",
                                    paddingTop: theme.spacing(0.5)
                                }}>
                                {localization.inlinePolicyDocumentJson.length({ count: PermissionManagementHelper.awsInlinePolicyDocumentMaxLength - inlinePolicyDocumentLength })}
                            </Typography>
                        </Box>
                        {(!_.isNil(inlinePolicyDocumentJsonValidationMessage) ||
                                upsertPermissionEligibilityError === Contract.ConfigurationControllerUpsertPermissionEligibilityError.AwsInvalidPolicyDocumentFormat ||
                                upsertPermissionEligibilityError === Contract.ConfigurationControllerUpsertPermissionEligibilityError.AwsInvalidPolicyDocumentLength) &&
                            <FormHelperText error={true}>
                                {_.isNil(inlinePolicyDocumentJsonValidationMessage)
                                    ? map(
                                        upsertPermissionEligibilityError as Contract.ConfigurationControllerUpsertPermissionEligibilityError,
                                        {
                                            [Contract.ConfigurationControllerUpsertPermissionEligibilityError.AwsInvalidPolicyDocumentFormat]: () => localization.inlinePolicyDocumentJson.error.format(),
                                            [Contract.ConfigurationControllerUpsertPermissionEligibilityError.AwsInvalidPolicyDocumentLength]: () => localization.inlinePolicyDocumentJson.error.length({ inlinePolicyDocumentMaxLength: PermissionManagementHelper.awsInlinePolicyDocumentMaxLength })
                                        })
                                    : inlinePolicyDocumentJsonValidationMessage}
                            </FormHelperText>}
                    </Stack>}
            </Stack>
        </Stack>);
}