import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../../../common";

export function useStatusTranslator() {
    const localization =
        useLocalization(
            "views.customer.risks.items.hooks.useStatusTranslator",
            () => ({
                [Contract.TypeNames.RiskStatus]: {
                    [Contract.RiskStatus.Closed]: "Closed",
                    [Contract.RiskStatus.Ignored]: "Ignored",
                    [Contract.RiskStatus.Open]: "Open"
                }
            }));
    return (status: Contract.RiskStatus) => localization[Contract.TypeNames.RiskStatus][status]();
}