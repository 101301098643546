import { AnalyticsEventActionType, FilterField, Menu, SelectionView, TimeFormatter, useFilterConnectorContext, useLocalization, useSetFilterConnectorContext } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, useEntityExcessivePermissionCustomEvaluationMenuItems } from "../../../../../../..";

type AwsPrincipalExcessivePermissionCustomEvaluationFilterProps = {
    dateInfos: Contract.EntityControllerGetEntityExcessivePermissionCustomEvaluationDataResponseDateInfo[];
    maxTime?: string;
};

export function AwsPrincipalExcessivePermissionCustomEvaluationFilter({ dateInfos, maxTime }: AwsPrincipalExcessivePermissionCustomEvaluationFilterProps) {
    const { filter, open } = useFilterConnectorContext() as { filter?: string; open?: boolean };
    const setFilterConnectorContext = useSetFilterConnectorContext();

    const localization =
        useLocalization(
            "common.access.hooks.useDefinition.hooks.useAwsDefinition.awsPrincipalExcessivePermissionCustomEvaluationFilter",
            () => ({
                menu: {
                    subtitle: "The colors will reflect the usage in the selected time frame",
                    title: "Show excessiveness for time frame:"
                },
                title: "Excessive Time Frame"
            }));

    const menuItems =
        useEntityExcessivePermissionCustomEvaluationMenuItems(
            filter,
            dateInfos,
            maxTime,
            date =>
                setFilterConnectorContext(
                    context =>
                        (
                            {
                                ...context,
                                filter: date
                            }
                        )),
            "topRight");
    return (
        <Menu
            analyticsOptions={{
                onClose: {
                    actionType: AnalyticsEventActionType.FilterValueSet,
                    propertyNameToValueMap: {
                        "Filter Name": localization.title(),
                        "Filter Type": "ValueFilter"
                    }
                },
                onOpen: {
                    actionType: AnalyticsEventActionType.FilterValueOpen,
                    propertyNameToValueMap: {
                        "Filter Name": localization.title(),
                        "Filter Type": "ValueFilter"
                    }
                }
            }}
            itemsOrGetItems={menuItems}
            titleOptions={{
                subtitle: localization.menu.subtitle(),
                titleOrGetTitle: localization.menu.title(),
                underline: true
            }}
            variant="bottomCenter">
            <FilterField
                emptyValue={_.isNil(filter)}
                focused={open}
                placeholder={localization.title()}
                selection={
                    <SelectionView
                        empty={_.isNil(filter)}
                        label={localization.title()}
                        selectedValues={[TimeFormatter.humanizeDuration(filter)]}
                        totalCount={_.size(menuItems)}/>}/>
        </Menu>);
}