﻿import { Optional } from "@infrastructure";
import { Contract } from "../../../../../../../../../../../common";
import { PermissionAssignmentEligibilityData } from "../../../utilities";

export class AzureRoleAssignmentEligibilityData extends PermissionAssignmentEligibilityData {
    public roleDefinitionIds: string[];

    constructor(
        permissionEligibilityModel?: Optional<Contract.PermissionEligibilityModel>,
        scopeId?: string) {
        super(
            permissionEligibilityModel,
            scopeId);
        this.roleDefinitionIds = (permissionEligibilityModel as Contract.AzureRoleAssignmentEligibilityModel)?.existingRoleDefinitionIds ?? [];
    }

    protected UpdatePrincipalTenantRelatedData() {
        super.UpdatePrincipalTenantRelatedData();
        this.roleDefinitionIds = [];
    }
}