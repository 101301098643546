import { ActionMenuItem, DataTableColumnRenderProps, DeleteIcon, EditIcon, Menu, Message, useLocalization } from "@infrastructure";
import { CircularProgress, Stack } from "@mui/material";
import _ from "lodash";
import React, { Fragment, useMemo, useState } from "react";
import { useSetSqsContext } from "../../..";
import { ConfigurationController, Contract, scopeNodeModelStore, scopeSystemEntityModelStore, useScopeNavigationViewContext, useTheme } from "../../../../../../../../../../../../../../../common";

export function ActionsCell({ item }: DataTableColumnRenderProps<Contract.ScopeSystemEntityModel>) {
    const { scopeNodeModel } = useScopeNavigationViewContext();
    const setSqsContext = useSetSqsContext();
    const scopeNodeMap = scopeNodeModelStore.useGetActiveScopeNodeMap();
    const childScopeIds =
        useMemo(
            () => scopeNodeMap[scopeNodeModel.configuration.id].scopeIds,
            [scopeNodeModel.configuration.id, scopeNodeMap]);

    const [deleteQueueExecuting, setDeleteQueueExecuting] = useState(false);
    const [deleteQueueError, setDeleteQueueError] = useState(false);

    async function deleteQueue() {
        setDeleteQueueExecuting(true);
        setDeleteQueueError(false);

        try {
            await ConfigurationController.deleteSqsQueue(new Contract.ConfigurationControllerDeleteSqsQueueRequest(item.configuration.id));
            await scopeSystemEntityModelStore.notify();
        } catch {
            setDeleteQueueError(true);
        }

        setDeleteQueueExecuting(false);
    }

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useObservabilityItems.sqs.table.actionsCell",
            () => ({
                actions: {
                    delete: {
                        error: "Failed to delete",
                        prompt: "Are you sure you want to delete the queue {{queueName}}?",
                        title: "Delete"
                    },
                    edit: "Edit"
                }
            }));
    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            direction="row"
            justifyContent="flex-end"
            spacing={1.5}>
            {deleteQueueExecuting && (
                <CircularProgress
                    size={theme.spacing(2)}
                    variant="indeterminate"/>)}
            {deleteQueueError && (
                <Message
                    level="error"
                    title={localization.actions.delete.error()}
                    variant="minimal"/>)}
            {(scopeNodeModel.configuration.id === item.configuration.scopeId || _.includes(childScopeIds, item.configuration.scopeId)) &&
                <Fragment>
                    <Menu
                        itemsOrGetItems={[
                            new ActionMenuItem(
                                () =>
                                    setSqsContext(
                                        sqsContext => ({
                                            ...sqsContext,
                                            addOrEditOpen: item
                                        })),
                                localization.actions.edit(),
                                {
                                    disabled: deleteQueueExecuting,
                                    icon: <EditIcon/>
                                }),
                            new ActionMenuItem(
                                () => deleteQueue(),
                                localization.actions.delete.title(),
                                {
                                    confirmOptions: {
                                        message: localization.actions.delete.prompt({ queueName: (item.configuration as Contract.SqsQueueConfiguration).name })
                                    },
                                    disabled: deleteQueueExecuting,
                                    icon: <DeleteIcon/>
                                })
                        ]}/>
                </Fragment>}
        </Stack>);
}