import { InlineItems, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { useCommonSectionsAndDescriptionDefinition, useGetAzureKeyVaultKeyRiskContext, useGetAzureKeyVaultVaultRiskContext } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { EntityExternalConsoleLink } from "../../components";

export function useAzureKeyVaultVaultKeyExpirationDisabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AzureKeyVaultVaultKeyExpirationDisabledRisk;
    const keyModel = entityModelStore.useGet(risk.entityId) as Contract.AzureKeyVaultVaultKeyModel;
    const key = keyModel.entity as Contract.AzureKeyVaultVaultKey;
    const vaultModel = entityModelStore.useGet(key.vaultId) as Contract.AzureKeyVaultVaultModel;

    const getAzureKeyVaultVaultRiskContext = useGetAzureKeyVaultVaultRiskContext();
    const getAzureKeyVaultKeyRiskContext = useGetAzureKeyVaultKeyRiskContext();

    const expirationDisabledVersions =
        useMemo(
            () =>
                _.orderBy(
                    key.expirationDisabledVersionNames,
                    expirationDisabledVersionName => expirationDisabledVersionName === key.name,
                    "desc"),
            [risk]);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.compliance.useAzureKeyVaultVaultKeyExpirationDisabledRiskDefinition",
            () => ({
                description: "{{key}} has {{expirationDisabledVersions}} with no expiration date set",
                expirationDisabledVersions: [
                    "1 enabled version",
                    "{{count | NumberFormatter.humanize}} enabled versions"
                ],
                sections: {
                    resolution: {
                        step1: "Click on the target key **{{key}}**",
                        step2: "Click on the **Rotation policy** tab and fill out the **Expiry time** field (recommended not to exceed 90 days)",
                        step3: "For each of the **{{expirationDisabledVersions}}** with no expiration date set, click on the version to open it",
                        step4: "Under Settings, check **Set expiration date**, enter the required value (recommended not to exceed 90 days) and click **Save** to confirm"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            expirationDisabledVersions:
                <InlineItems
                    items={expirationDisabledVersions}
                    namePluralizer={localization.expirationDisabledVersions}
                    variant="itemCountAndType"/>,
            key:
                <Entity
                    entityIdOrModel={keyModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () =>
            [
                <EntityExternalConsoleLink
                    entityId={key.vaultId}
                    key={key.vaultId}
                    page={Contract.AzureConsoleEntityPage.Keys}/>,
                localization.sections.resolution.step1({
                    key:
                        <Entity
                            entityIdOrModel={keyModel}
                            glanceOptions={{ disabled: true }}
                            linkOptions={{ disabled: true }}
                            variant="text"/>
                }),
                key.rotationPolicy?.rotationEnabled &&
                _.isNil(key.rotationPolicy.keyExpirationTime)
                    ? localization.sections.resolution.step2()
                    : undefined,
                localization.sections.resolution.step3({
                    expirationDisabledVersions:
                        <InlineItems
                            items={expirationDisabledVersions}
                            namePluralizer={localization.expirationDisabledVersions}
                            variant="itemCountAndType"/>
                }),
                localization.sections.resolution.step4()
            ],
        riskModel,
        () => {
            const keyRiskContext = getAzureKeyVaultKeyRiskContext(keyModel);
            const vaultRiskContext = getAzureKeyVaultVaultRiskContext(vaultModel);
            return [
                keyRiskContext.generalInformation,
                keyRiskContext.sensitive,
                keyRiskContext.keyVault,
                vaultRiskContext.sensitive,
                keyRiskContext.expirationDisabledVersions,
                keyRiskContext.rotationPolicy,
                keyRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        });
}