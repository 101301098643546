import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, InlinePermissionActions } from "../../../../../../../../../../../../../../common";

type InlineHighSeverityPermissionActionsProps = {
    risk: Contract.GcpScopeResourcePublicAccessExistsRisk;
};

export function InlineHighSeverityPermissionActions({ risk }: InlineHighSeverityPermissionActionsProps) {
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.publicAccess.inlineHighSeverityPermissionActions",
            () => ({
                highSeverityPermissionActions: [
                    "1 high severity permission",
                    "{{count | NumberFormatter.humanize}} high severity permissions"
                ]
            }));
    return (
        <InlinePermissionActions
            namePluralizer={localization.highSeverityPermissionActions}
            permissionActions={
                _(risk.permissionActionToHighSeverityMap).
                    pickBy().
                    keys().
                    value()}
            variant="itemCountAndType"/>);
}