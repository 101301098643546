import { useLocalization } from "@infrastructure";
import _ from "lodash";
import { AuditEventDefinition, AuditEventDefinitionContext, AuditEventDefinitionDetailItem } from "..";
import { Contract } from "../../../../../../../../../common";
import { useRiskAuditEventDetailsItems } from "./useRiskAuditEventDetailsItems";

export function useRiskStatusUpdateNoteUpdateAuditEventDefinition(context: AuditEventDefinitionContext) {
    const riskAuditEventDetailsItems = useRiskAuditEventDetailsItems(context);
    const localization =
        useLocalization(
            "views.customer.auditEvents.profile.hooks.useDefinition.hooks.useRiskStatusUpdateNoteUpdateAuditEventDefinition",
            () => ({
                newMessage: "New Message",
                oldMessage: "Old Message",
                riskStatus: "status"
            }));

    const riskStatusUpdateNoteAuditEvent = context.auditEventModel.auditEvent as Contract.RiskStatusUpdateNoteUpdateAuditEvent;
    return new AuditEventDefinition(
        _.filter([
            !_.isNil(riskStatusUpdateNoteAuditEvent.oldMessage)
                ? new AuditEventDefinitionDetailItem(
                    localization.oldMessage(),
                    riskStatusUpdateNoteAuditEvent.oldMessage!)
                : undefined,
            new AuditEventDefinitionDetailItem(
                localization.newMessage(),
                riskStatusUpdateNoteAuditEvent.newMessage),
            riskAuditEventDetailsItems.riskPolicyTypeItem,
            riskAuditEventDetailsItems.riskIdItem,
            riskAuditEventDetailsItems.riskDeletedItem,
            riskAuditEventDetailsItems.riskLinkItem
        ]) as AuditEventDefinitionDetailItem[]);
}