﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, Entity, entityModelStore, InlineEntities, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetAwsResourceRiskContext } from "./useGetAwsResourceRiskContext";

export function useGetAwsEc2SecurityGroupRiskContext() {
    return useMemo(
        () => useAwsEc2SecurityGroupRiskContext,
        []);
}

function useAwsEc2SecurityGroupRiskContext(securityGroupModel: Contract.AwsEc2SecurityGroupModel) {
    const securityGroup = securityGroupModel.entity as Contract.AwsEc2SecurityGroup;
    const resourceRiskContext = useGetAwsResourceRiskContext()(securityGroupModel);

    const networkedResourceModels =
        entityModelStore.useGet(
            _(securityGroupModel.networkedResourceTypeNameToIdsMap).
                values().
                flatten().
                value());

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.contexts.useGetAwsEc2SecurityGroupRiskContext",
            () => ({
                attachedResources: {
                    empty: "The {{translatedSecurityGroupTypeName}} is not attached to any resources",
                    text: "The {{translatedSecurityGroupTypeName}} is attached to {{resources}}"
                },
                referencingSecurityGroups: "The {{translatedSecurityGroupTypeName}} is referenced by {{securityGroupReferencingSecurityGroups}} rules",
                vpc: "The {{translatedSecurityGroupTypeName}} is part of VPC {{vpc}}",
                wideRangeInboundRuleExists: {
                    empty: "The {{translatedSecurityGroupTypeName}} does not allow public inbound access",
                    exists: "The {{translatedSecurityGroupTypeName}} allows public inbound network access"
                }
            }));
    const translatedSecurityGroupTypeName =
        entityTypeNameTranslator(
            securityGroup.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });

    return {
        ...resourceRiskContext,
        attachedResources:
            new RiskDefinitionContextItem(
                (_.isEmpty(networkedResourceModels)
                    ? localization.attachedResources.empty
                    : localization.attachedResources.text)({
                    resources:
                        <InlineEntities
                            entityIdsOrModels={networkedResourceModels}
                            entityTypeName={Contract.TypeNames.IAwsNetworkedResource}
                            variant="itemCountAndType"/>,
                    translatedSecurityGroupTypeName
                })),
        referencingSecurityGroups:
            _.isEmpty(securityGroupModel.referencingSecurityGroupIds)
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.referencingSecurityGroups({
                        securityGroupReferencingSecurityGroups:
                            <InlineEntities
                                entityIdsOrModels={securityGroupModel.referencingSecurityGroupIds}
                                entityTypeName={Contract.TypeNames.AwsEc2SecurityGroup}
                                variant="itemCountAndType"/>,
                        translatedSecurityGroupTypeName
                    })),
        vpc:
            _.isNil(securityGroup.vpcId)
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.vpc({
                        translatedSecurityGroupTypeName,
                        vpc:
                            <Entity
                                entityIdOrModel={securityGroup.vpcId}
                                variant="text"/>
                    })),
        wideRangeInboundRuleExists:
            _.isNil(securityGroupModel.wideRangeInboundRuleExists)
                ? undefined
                : new RiskDefinitionContextItem(
                    (securityGroupModel.wideRangeInboundRuleExists
                        ? localization.wideRangeInboundRuleExists.exists
                        : localization.wideRangeInboundRuleExists.empty)({ translatedSecurityGroupTypeName })
                )
    };
}