import { TimeFormatter, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../..";
import { Contract, InfoItem, tenantModelStore, usePrincipalModelAccess } from "../../../../../../../../../../common";
import { useAadDirectoryGroupTypeTranslator } from "../../../../../../hooks";
import { EntitiesInfoCard, EntitiesInfoItem, Info, PrincipalSecurityStatsStat } from "../../../../components";
import { AadDirectoryPrincipalAzureRoleAssignmentResources, AadDirectoryPrincipalDirectoryRoleAssignmentResources } from "../../components";
import { useAadDefaultDirectoryEntityInfoItemElements } from "./useAadDefaultDirectoryEntityInfoItemElements";

export function useAadDirectoryGroupDefinition(directoryEntityModel: Contract.AadDirectoryEntityModel, options?: DefinitionOptions) {
    const activeCloudProviderTenantTypes = tenantModelStore.useGetActiveCloudProviderTenantTypes();
    const groupModel = directoryEntityModel as Contract.AadDirectoryGroupModel;
    const defaultResourceInfoItemElements = useAadDefaultDirectoryEntityInfoItemElements(groupModel);
    const group = directoryEntityModel.entity as Contract.AadDirectoryGroup;
    const groupModelAccess = usePrincipalModelAccess<Contract.GroupModelAccess>(groupModel.id);

    const directoryGroupTypeTranslator = useAadDirectoryGroupTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aad.useAadDirectoryGroupDefinition",
            () => ({
                info: {
                    cards: {
                        groupIds: "Group Memberships"
                    },
                    items: {
                        adOrigin: {
                            false: "No",
                            title: "On-premises sync",
                            true: "Yes"
                        },
                        adSyncUpdateTime: "On-premises Sync Time",
                        membershipType: "Membership Type",
                        ownerPrincipalIds: "Owners",
                        type: "Group Type"
                    },
                    memberPrincipals: "Members"
                },
                tabs: {
                    aadAdministrativeRoleAssignments: "Microsoft Entra ID Administrative Role Assignments",
                    azureRoleAssignments: "Azure Role Assignments"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs:
            _<EntityProfileDefinitionTab>([]).
                concat(
                    new EntityProfileDefinitionTab(
                        ProfileCategory.Iam,
                        <AadDirectoryPrincipalDirectoryRoleAssignmentResources
                            csvExportFilePrefix={localization.tabs.aadAdministrativeRoleAssignments()}
                            principalModel={groupModel}/>,
                        localization.tabs.aadAdministrativeRoleAssignments(),
                        "aadAdministrativeRoleAssignments")).
                concatIf(
                    _.includes(activeCloudProviderTenantTypes, Contract.TenantType.Azure),
                    new EntityProfileDefinitionTab(
                        ProfileCategory.Iam,
                        <AadDirectoryPrincipalAzureRoleAssignmentResources
                            csvExportFilePrefix={localization.tabs.azureRoleAssignments()}
                            principalModel={groupModel}/>,
                        localization.tabs.azureRoleAssignments(),
                        "azureRoleAssignments")).
                value(),
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={[]}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="type"
                        title={localization.info.items.type()}
                        value={directoryGroupTypeTranslator(group.type)}/>,
                    <InfoItem
                        key="adOrigin"
                        title={localization.info.items.adOrigin.title()}
                        value={
                            group.adOrigin
                                ? localization.info.items.adOrigin.true()
                                : localization.info.items.adOrigin.false()}/>,
                    group.adOrigin
                        ? <InfoItem
                            key="adSyncUpdateTime"
                            title={localization.info.items.adSyncUpdateTime()}
                            value={
                                _.isNil(group.adSyncUpdateTime)
                                    ? undefined
                                    : TimeFormatter.profileFormatDateTime(group.adSyncUpdateTime)}/>
                        : undefined,
                    <InfoItem
                        key="membershipType"
                        title={localization.info.items.membershipType()}
                        value={group.membershipType}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={group.ownerPrincipalIds}
                        entityTypeName={Contract.TypeNames.AadDirectoryPrincipal}
                        key="ownerPrincipalIds"
                        location="all"
                        title={localization.info.items.ownerPrincipalIds()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={groupModel.memberPrincipalIds}
                        entityTypeName={Contract.TypeNames.AadDirectoryPrincipal}
                        key="memberPrincipals"
                        location="miniGlance"
                        title={localization.info.memberPrincipals()}/>
                ]}
                options={options?.infoOptions}>
                <EntitiesInfoCard
                    entityIdsOrModels={groupModel.memberPrincipalIds}
                    entityTypeName={Contract.TypeNames.AadDirectoryPrincipal}
                    title={localization.info.memberPrincipals()}/>
                <EntitiesInfoCard
                    entityIdsOrModels={groupModel.groupIds}
                    entityTypeName={Contract.TypeNames.AadDirectoryGroup}
                    title={localization.info.cards.groupIds()}/>
            </Info>,
        sensitive: false,
        statElements:
            _.filter([
                _.isNil(groupModelAccess?.permissions)
                    ? undefined
                    : <PrincipalSecurityStatsStat
                        analyzingReason={groupModel.analyzing?.reason}
                        analyzingTenants={
                            _.isNil(groupModel.analyzing?.tenantIds)
                                ? undefined
                                : {
                                    ids: groupModel.analyzing!.tenantIds,
                                    typeName: Contract.TypeNames.AzureTenantEntity
                                }}
                        securityStats={groupModelAccess!.permissions!.excessivePermissionActionStats}
                        typeName={Contract.TypeNames.AadDirectoryGroup}/>
            ])
    });
}