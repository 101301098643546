import { SvgIcon, SvgIconProps, useTheme } from "@mui/material";
import React from "react";

export function IndeterminateIcon(props: SvgIconProps) {
    const theme = useTheme();
    return (
        <SvgIcon
            sx={{ color: theme.palette.primary.main }}
            {...props}>
            <rect
                fill="none"
                height="15"
                rx="1.5"
                stroke="#9E9E9E"
                width="15"
                x="4.5"
                y="4.5"/>
            <rect
                height="8"
                rx="1"
                width="8"
                x="8"
                y="8"/>
        </SvgIcon>);
}