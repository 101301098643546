import { Link, useLocalization, useOrderedWizardContext } from "@infrastructure";
import { Box, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { Contract, CustomerConsoleAppUrlHelper, RadioGroup, RadioGroupItem, Severity, useTheme } from "../../../../../../../../../../../../../../../common";
import { SyncOptionType, useAddOrEditProjectContext, useSetAddOrEditProjectContext } from "../AddOrEditProject";
import { MapTable } from "./MapTable";

export function SyncIssuePrioritiesItem() {
    const { executing, setValid } = useOrderedWizardContext();
    const { priorities, severityToPriorityErrorMap, severityToPriorityMap, syncIssuePrioritiesItemOptionType } = useAddOrEditProjectContext();
    const setAddOrEditProjectContext = useSetAddOrEditProjectContext();

    const [syncOptionType, setSyncOptionType] = useState(syncIssuePrioritiesItemOptionType);

    useEffect(
        () => {
            setAddOrEditProjectContext(
                context => ({
                    ...context,
                    syncIssuePrioritiesItemOptionType: syncOptionType
                }));
        },
        [syncOptionType]);

    useEffect(
        () => {
            setValid(
                syncOptionType === SyncOptionType.None
                    ? true
                    : _.every(
                        severityToPriorityMap,
                        priority => !_.isEmpty(priority)) &&
                    _.isEmpty(severityToPriorityErrorMap));
        },
        [severityToPriorityMap, syncOptionType]);

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useTicketingItems.jira.addOrEditProject.syncIssuePrioritiesItem",
            () => ({
                customMapping: {
                    priorityErrorMessage: "Jira priority doesn't exist",
                    priorityTitle: "Jira Issue Priority",
                    severityTitle: "Finding Severity"
                },
                description: "This allows you to align the finding severity with the corresponding Jira issue priority.",
                learnMore: "Learn more",
                options: {
                    custom: "Sync using custom mapping",
                    none: "Do not sync"
                },
                title: "Sync Issue Priorities"
            }));

    const theme = useTheme();
    const radioGroupItems =
        useMemo(
            (): RadioGroupItem<SyncOptionType>[] => [
                {
                    label: localization.options.none(),
                    value: SyncOptionType.None
                },
                {
                    children:
                        <MapTable
                            getErrorMessage={
                                severity =>
                                    _.isEmpty(severityToPriorityErrorMap?.[severity])
                                        ? undefined
                                        : localization.customMapping.priorityErrorMessage()}
                            itemSelectorProps={{
                                disabled: executing,
                                fieldSx: { height: theme.spacing(4) },
                                fullWidth: true,
                                items:
                                    _.isNil(priorities)
                                        ? []
                                        : _.map(
                                            priorities,
                                            priority => priority)
                            }}
                            itemSelectorRenderer={item => item.name}
                            keyRenderer={severity => <Severity severity={severity as Contract.Severity}/>}
                            keys={_.keys(defaultSeverityToPriorityName)}
                            keysToSelectedItems={severityToPriorityMap!}
                            keyTitle={localization.customMapping.severityTitle()}
                            valueTitle={localization.customMapping.priorityTitle()}
                            onItemSelected={
                                (severity, priority) =>
                                    setAddOrEditProjectContext(
                                        context => ({
                                            ...context,
                                            severityToPriorityErrorMap: _.omit(context.severityToPriorityErrorMap, severity),
                                            severityToPriorityMap: {
                                                ...context.severityToPriorityMap!,
                                                [severity]: priority
                                            }
                                        }))}/>,
                    label: localization.options.custom(),
                    value: SyncOptionType.Custom
                }
            ],
            [severityToPriorityMap, executing]);

    return (
        <Box sx={{ maxWidth: theme.spacing(82) }}>
            <Stack spacing={2}>
                <Typography variant="h6">
                    {localization.title()}
                </Typography>
                <Stack spacing={1}>
                    <Typography>
                        {localization.description()}
                    </Typography>
                    <Link
                        urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsTicketingIntegrationAddJiraProject()}
                        variant="external">
                        {localization.learnMore()}
                    </Link>
                </Stack>
                <RadioGroup
                    disabled={executing}
                    items={radioGroupItems}
                    selectedValue={syncOptionType}
                    onChange={value => setSyncOptionType(value)}/>
            </Stack>
        </Box>);
}

enum JiraPriorityDefaultNames {
    High = "High",
    Highest = "Highest",
    Low = "Low",
    Lowest = "Lowest",
    Medium = "Medium"
}

export const defaultSeverityToPriorityName: { [key in Contract.Severity]: string } = {
    [Contract.Severity.Information]: JiraPriorityDefaultNames.Lowest,
    [Contract.Severity.Low]: JiraPriorityDefaultNames.Low,
    [Contract.Severity.Medium]: JiraPriorityDefaultNames.Medium,
    [Contract.Severity.High]: JiraPriorityDefaultNames.High,
    [Contract.Severity.Critical]: JiraPriorityDefaultNames.Highest
};

export function getSeverityToPriorityMap(priorities?: Contract.JiraPriority[]) {
    return _.mapValues(
        defaultSeverityToPriorityName,
        priorityName =>
            _.find(
                priorities,
                priority => priority.name === priorityName));
}