import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, entityModelStore, SeveritySquare, useEntityTypeNameTranslator, useSeverityTranslator } from "../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../utilities";
import { useEntityRiskContext } from "./useEntityRiskContext";
import { useGetNetworkAccessResourceRiskContext } from "./useGetNetworkAccessResourceRiskContext";

export function useGetVirtualMachineRiskContext() {
    return useMemo(
        () => useVirtualMachineRiskContext,
        []);
}
export function useVirtualMachineRiskContext(virtualMachineIdOrModel: string | Contract.EntityModel) {
    const entityModel =
        entityModelStore.useGet(
            _.isString(virtualMachineIdOrModel)
                ? virtualMachineIdOrModel as string
                : undefined) ?? virtualMachineIdOrModel as Contract.EntityModel;
    const entityRiskContext = useEntityRiskContext(entityModel);
    const virtualMachineModel = _.as<Contract.IVirtualMachineModel>(entityModel);

    const getNetworkAccessResourceRiskContext = useGetNetworkAccessResourceRiskContext();

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const severityTranslator = useSeverityTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.useGetVirtualMachineRiskContext",
            () => ({
                identityPermissionActionSeverity: "The {{translatedVirtualMachineTypeName}} has {{severityElement}}**{{severity}}** severity permissions",
                stopped: "The {{translatedVirtualMachineTypeName}} has been stopped for more than 30 days"
            }));
    const translatedVirtualMachineTypeName =
        entityTypeNameTranslator(
            entityModel.entity.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });
    return {
        ...getNetworkAccessResourceRiskContext(entityModel),
        ...entityRiskContext,
        identityPermissionActionSeverity:
            _.isNil(virtualMachineModel.identityPermissionActionSeverity)
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.identityPermissionActionSeverity(
                        {
                            severity: severityTranslator(virtualMachineModel.identityPermissionActionSeverity!, "text"),
                            severityElement:
                                <SeveritySquare severity={virtualMachineModel.identityPermissionActionSeverity!}/>,
                            translatedVirtualMachineTypeName
                        })
                ),
        stopped:
            virtualMachineModel.stopped
                ? new RiskDefinitionContextItem(localization.stopped({ translatedVirtualMachineTypeName }))
                : undefined
    };
}