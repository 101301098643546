import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, InlineEntities, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { useAwsRdsClusterEngineTypeTranslator } from "../../../../../../../../../Entities/hooks";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetDataAnalysisResourceRiskContext } from "../../../useGetDataAnalysisResourceRiskContext";
import { useGetAwsResourceRiskContext } from "./useGetAwsResourceRiskContext";

export function useGetAwsRdsClusterRiskContext() {
    return useMemo(
        () => useAwsRdsClusterRiskContext,
        []);
}

function useAwsRdsClusterRiskContext(clusterModel: Contract.AwsRdsClusterModel) {
    const cluster = clusterModel.entity as Contract.AwsRdsCluster;
    const dataAnalysisResourceRiskContext = useGetDataAnalysisResourceRiskContext()(clusterModel);
    const resourceRiskContext = useGetAwsResourceRiskContext()(clusterModel);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const rdsClusterEngineTypeTranslator = useAwsRdsClusterEngineTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.contexts.useGetAwsRdsClusterRiskContext",
            () => ({
                backupRetentionTimeFrame: "The retention period is currently set to **{{backupRetentionTimeFrame | TimeSpanFormatter.day}}**",
                engine: "The {{translatedClusterTypeName}}'s engine is {{engine}}",
                instances: "The RDS Cluster has {{instances}}",
                storageSize: "The {{translatedClusterTypeName}}'s size is {{storageSize | NumberFormatter.storage}}"
            }));
    const translatedClusterTypeName =
        entityTypeNameTranslator(
            cluster.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });

    return {
        ...dataAnalysisResourceRiskContext,
        ...resourceRiskContext,
        backupRetentionTimeFrame:
            new RiskDefinitionContextItem(localization.backupRetentionTimeFrame({ backupRetentionTimeFrame: cluster.backupRetentionTimeFrame })),
        engine:
            new RiskDefinitionContextItem(
                localization.engine({
                    engine: rdsClusterEngineTypeTranslator(cluster.engineType),
                    translatedClusterTypeName
                })),
        instances:
            _.isEmpty(clusterModel.instanceIds)
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.instances({
                        instances:
                            <InlineEntities
                                entityIdsOrModels={clusterModel.instanceIds}
                                entityTypeName={Contract.TypeNames.AwsRdsDatabaseInstance}
                                variant="itemCountAndType"/>
                    })),
        storageSize:
            new RiskDefinitionContextItem(
                localization.storageSize({
                    storageSize: cluster.storageSize,
                    translatedClusterTypeName
                }))
    };
}