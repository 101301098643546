import { TimeFormatter, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, InfoItem } from "../../../../../../../../../../common";
import { useAadDirectoryServicePrincipalTypeTranslator } from "../../../../../../hooks";
import { AadDirectoryPrincipalActivityInfoItem, EntitiesInfoItem, TagsInfoItem } from "../../../../components";

export function useAadCommonDirectoryServicePrincipalInfoItemElements(directoryServicePrincipalModel: Contract.AadDirectoryServicePrincipalModel) {
    const servicePrincipal = directoryServicePrincipalModel.entity as Contract.AadDirectoryApplicationServicePrincipal;
    const servicePrincipalProfile = directoryServicePrincipalModel.entityProfile as Contract.AadDirectoryServicePrincipalProfile;

    const directoryServicePrincipalTypeTranslator = useAadDirectoryServicePrincipalTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aad.useAadCommonDirectoryServicePrincipalInfoItemElements",
            () => ({
                applicationRawId: "Application Id",
                assignmentRequired: {
                    false: "No",
                    title: "User Assignment Required",
                    true: "Yes"
                },
                enabled: {
                    false: "Disabled",
                    title: "Status",
                    true: "Enabled"
                },
                homepageRawUrl: "Homepage URL",
                ownerPrincipalIds: "Owners",
                signInTime: {
                    empty: "Not seen",
                    title: "Sign-in Time"
                },
                tags: "Tags",
                type: "Application Type",
                visible: {
                    false: "No",
                    title: "Visible to Users",
                    true: "Yes"
                }
            }));

    return {
        activityTimeInfoItemElement:
            <AadDirectoryPrincipalActivityInfoItem
                key="activityTime"
                principalModel={directoryServicePrincipalModel}/>,
        applicationRawIdInfoItemElement:
            <InfoItem
                key="applicationRawId"
                title={localization.applicationRawId()}
                value={servicePrincipal.applicationRawId}/>,
        assignmentRequiredInfoItemElement:
            <InfoItem
                key="assignmentRequired"
                title={localization.assignmentRequired.title()}
                value={
                    _.isNil(servicePrincipal.assignmentRequired)
                        ? undefined
                        : servicePrincipal.assignmentRequired
                            ? localization.assignmentRequired.true()
                            : localization.assignmentRequired.false()}/>,
        enabledInfoItemElement:
            <InfoItem
                key="enabled"
                title={localization.enabled.title()}
                value={
                    servicePrincipal.enabled
                        ? localization.enabled.true()
                        : localization.enabled.false()}/>,
        homepageRawUrlInfoItemElement:
            <InfoItem
                key="homepageRawUrl"
                title={localization.homepageRawUrl()}
                value={servicePrincipal.homepageRawUrl}/>,
        ownerPrincipalIdsInfoItemElement:
            <EntitiesInfoItem
                entityIdsOrModels={servicePrincipal.ownerPrincipalIds}
                entityTypeName={Contract.TypeNames.AadDirectoryPrincipal}
                key="ownerPrincipalIds"
                location="all"
                title={localization.ownerPrincipalIds()}/>,
        signInTimeInfoItemElement:
            servicePrincipal.signInEventsDisabled
                ? undefined
                : <InfoItem
                    emptyValue={localization.signInTime.empty()}
                    key="signInTime"
                    title={localization.signInTime.title()}
                    value={
                        _.isNil(servicePrincipalProfile?.signInTime)
                            ? undefined
                            : TimeFormatter.profileFormatDateTime(servicePrincipalProfile.signInTime)}/>,
        tagsInfoItemElement:
            <TagsInfoItem
                key="tags"
                location="all"
                resourceTags={servicePrincipal.tags}
                title={localization.tags()}/>,
        typeInfoItemElement:
            <InfoItem
                key="type"
                title={localization.type()}
                value={directoryServicePrincipalTypeTranslator(servicePrincipal.type)}/>,
        visibleInfoItemElement:
            <InfoItem
                key="visible"
                title={localization.visible.title()}
                value={
                    _.isNil(servicePrincipal.visible)
                        ? undefined
                        : servicePrincipal.visible
                            ? localization.visible.true()
                            : localization.visible.false()}/>
    };
}