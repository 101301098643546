import { DataTableColumnRenderProps, NotValidIcon, SuccessIcon, Tooltip, useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import React from "react";
import { Contract, useTheme } from "../../../../../../../../../../../common";

export function StatusCell({ item }: DataTableColumnRenderProps<Contract.ScopeSystemEntityModel>) {
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCloudProviderTenantOrganizationItems.statusCell",
            () => ({
                connected: "Connected",
                deleted: {
                    description: "Organization is being deleted, this could take a while",
                    title: "Deleting"
                }
            }));

    const isDeleted = item.configuration.systemDeleted;

    const theme = useTheme();
    return (
        <Tooltip
            titleOrGetTitle={
                isDeleted
                    ? localization.deleted.description()
                    : localization.connected()}>
            <Stack
                alignItems="center"
                direction="row"
                spacing={1}
                sx={{ fontSize: "18px" }}>
                {isDeleted
                    ? <NotValidIcon sx={{ color: theme.palette.warning.light }}/>
                    : <SuccessIcon sx={{ color: theme.palette.success.main }}/>}
                <Typography noWrap={true}>
                    {isDeleted
                        ? localization.deleted.title()
                        : localization.connected()}
                </Typography>
            </Stack>
        </Tooltip>);
}