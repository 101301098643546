import { DataTableColumn, DataTableColumnRenderProps, useLocalization } from "@infrastructure";
import React from "react";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, PagedEntityFilter, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";
import { useAzureCommonResourceDefinition } from "./useAzureCommonResourceDefinition";

export function useAzureCommonResourceGroupResourceDefinition(definitionData: DefinitionData) {
    const entitiesExportOptions = useEntitiesExportOptions();
    const commonResourceDefinition = useAzureCommonResourceDefinition(definitionData);

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.azure.useAzureCommonResourceGroupResourceDefinition",
            () => ({
                columns: {
                    resourceGroup: "Resource Group"
                }
            }));

    return {
        columns: {
            ...commonResourceDefinition.columns,
            resourceGroupColumn:
                <DataTableColumn
                    exportOptions={
                        entitiesExportOptions<Contract.AzureResourceGroupResourceModel>(
                            Contract.TypeNames.AzureResourcesResourceGroup,
                            item => [(item.entity as Contract.AzureResourceGroupResource).resourceGroupId],
                            localization.columns.resourceGroup())}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedEntityFilter
                                    getEntityIdPage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.AzureResourceGroupResourceResourceGroup)}
                                    placeholder={localization.columns.resourceGroup()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AzureResourceGroupResourceResourceGroup}
                    key={Contract.EntityModelProperty.AzureResourceGroupResourceResourceGroup}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.AzureResourceGroupResourceModel>) =>
                            <EntitiesCell
                                entityIdsOrModels={(item.entity as Contract.AzureResourceGroupResource).resourceGroupId}
                                entityTypeName={Contract.TypeNames.AzureResourcesResourceGroup}/>}
                    title={localization.columns.resourceGroup()}/>
        }
    };
}