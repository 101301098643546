import { Message, useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { ReactNode, useMemo } from "react";
import { Contract, UserHelper, useTheme } from "../../../../../../../../common";
import { useConfigurationContext } from "../../../../components";

export type ParametersProps = {
    children: (disabled: boolean) => ReactNode;
    helpText?: string;
    inherited: boolean;
};

export function Parameters({ children, helpText, inherited }: ParametersProps) {
    const { scopeId } = useConfigurationContext();

    const disabled =
        useMemo(
            () => {
                const userSecurityWrite =
                    UserHelper.hasScopePermissions(
                        scopeId,
                        Contract.IdentityPermission.SecurityWrite);

                const disabled = inherited || !userSecurityWrite;

                return disabled;
            },
            [inherited, scopeId]);

    const localization =
        useLocalization(
            "views.customer.riskPolicies.hooks.useConfigurationDefinition.kubernetesAdmissionController.parameters",
            () => ({
                title: "Parameters"
            }));

    const theme = useTheme();
    return (
        <Stack
            spacing={3}
            sx={{
                height: "100%",
                padding: theme.spacing(2, 2, 2, 3)
            }}>
            <Stack
                alignItems="center"
                direction="row"
                spacing={1}>
                <Typography variant="h4">
                    {localization.title()}
                </Typography>
                {!_.isNil(helpText) &&
                    <Message
                        level="info"
                        title={
                            <Typography style={{ whiteSpace: "pre-wrap" }}>
                                {helpText}
                            </Typography>}
                        variant="minimal"/>}
            </Stack>
            <Stack
                spacing={1}
                sx={{
                    flex: 1,
                    height: "100%",
                    overflow: "auto"
                }}>
                {children(disabled)}
            </Stack>
        </Stack>);
}