import { Box, Stack, Tab, Tabs } from "@mui/material";
import _ from "lodash";
import React, { Fragment, ReactNode, useMemo, useRef, useState } from "react";
import { CheckButton, CsvExportButton, DataTableAction, DataTableActions, Dialog, EmptyMessageText, Loading, makeContextProvider, StringHelper, useChangeEffect, useLocalization, VerticalFillGrid } from "@infrastructure";
import { Contract, ItemTable as ItemTableBase, RiskPoliciesType, RiskPoliciesView, ScopeNavigationView, ScopeNode, scopeNodeModelStore, useScopeNavigationViewContext, useTheme } from "../../../../../../common";
import { useDefinition, useRiskPoliciesTypeData, useRiskPoliciesViewItems } from "../../hooks";

export class RiskPoliciesContext {
    constructor(
        public addOrEditOpen: boolean | Contract.CustomRiskPolicyConfiguration,
        public scopeNodeMap: _.Dictionary<ScopeNode>) {
    }
}

export const [useRiskPoliciesContext, useSetRiskPoliciesContext, useRiskPoliciesContextProvider] = makeContextProvider<RiskPoliciesContext>();

interface ViewProps {
    type: RiskPoliciesType;
}

export function View({ type }: ViewProps) {
    const { templatePath, tenantTypes, views } = useRiskPoliciesTypeData(type);

    return (
        <ScopeNavigationView
            layout="global"
            scopeSelectionStrategy="none"
            templatePath={templatePath}
            tenantTypes={tenantTypes}>
            <Core
                type={type}
                views={views}/>
        </ScopeNavigationView>);
}

interface CoreProps {
    type: RiskPoliciesType;
    views: RiskPoliciesView[];
}

function Core({ type, views }: CoreProps) {
    const { useViewRoute } = useScopeNavigationViewContext();
    const [view, setView] = useViewRoute("{view}", _.values(RiskPoliciesView));
    const { tenantTypes } = useRiskPoliciesTypeData(type);
    const scopeNodeMap = scopeNodeModelStore.useGetActiveScopeNodeMap(tenantTypes);
    const [, , ContextProvider] =
        useRiskPoliciesContextProvider(
            () =>
                new RiskPoliciesContext(
                    false,
                    scopeNodeMap));

    const localization =
        useLocalization(
            "views.customer.riskPolicies.view.core",
            () => ({
                empty: {
                    withFilter: "No Matching Policies",
                    withoutFilter: "No Policies"
                },
                tabs: {
                    view: {
                        [RiskPoliciesView.BuiltIn]: "Built-In",
                        [RiskPoliciesView.Custom]: "Custom"
                    }
                }
            }));

    const theme = useTheme();
    return (
        <ContextProvider>
            <VerticalFillGrid>
                <Box
                    sx={{
                        height: "100%",
                        overflow: "hidden"
                    }}>
                    <VerticalFillGrid>
                        {_.size(views) > 1 &&
                            <Stack
                                alignItems="stretch"
                                direction="row"
                                sx={{ backgroundColor: theme.palette.background.paper }}>
                                <Box sx={{ flex: 1 }}>
                                    <Tabs
                                        indicatorColor="primary"
                                        sx={{
                                            border: "unset",
                                            padding: theme.spacing(0, 2.5),
                                            width: "100%"
                                        }}
                                        value={view ?? RiskPoliciesView.BuiltIn}
                                        variant="scrollable"
                                        onChange={(_, view) => setView(view)}>
                                        {_.map(
                                            views,
                                            view =>
                                                <Tab
                                                    key={`${view}-tab`}
                                                    label={localization.tabs.view[view]()}
                                                    sx={{
                                                        marginRight: theme.spacing(3),
                                                        padding: 0
                                                    }}
                                                    value={view}/>)}
                                    </Tabs>
                                </Box>
                            </Stack>}
                        <Loading key={view ?? RiskPoliciesView.BuiltIn}>
                            <ItemTable
                                type={type}
                                view={view}/>
                        </Loading>
                    </VerticalFillGrid>
                </Box>
            </VerticalFillGrid>
        </ContextProvider>);
}

interface TableProps {
    type: RiskPoliciesType;
    view?: RiskPoliciesView;
}

function ItemTable({ type, view = RiskPoliciesView.BuiltIn }: TableProps) {
    const { scopeNodeModel } = useScopeNavigationViewContext();
    const definition = useDefinition(type, scopeNodeModel.configuration.id, view);
    const [childScopeCustomPoliciesEnabled, setChildScopeCustomPoliciesEnabled] = useState(!StringHelper.isFalse(definition.storage.flatView?.getValue()));
    const items = useRiskPoliciesViewItems(type, view, childScopeCustomPoliciesEnabled, scopeNodeModel.configuration.id);
    const [filteredItems, setFilteredItems] = useState(items);
    const dataTableActionsRef = useRef<DataTableActions>();
    const { addOrEditOpen } = useRiskPoliciesContext();
    const setRiskPoliciesContext = useSetRiskPoliciesContext();

    useChangeEffect(
        () =>
            dataTableActionsRef.current!.reload({
                refreshColumns: true,
                refreshFilters: true
            }),
        [items]);

    const localization =
        useLocalization(
            "views.customer.riskPolicies.view.itemTable",
            () => ({
                actions: {
                    customPoliciesFlatView: "Flat View"
                },
                empty: {
                    withFilter: "No Matching Policies",
                    withoutFilter: "No Policies"
                }
            }));

    const dataTableActions =
        useMemo(
            () =>
                _<ReactNode>([]).
                    concatIf(
                        !_.isNil(definition.storage.flatView),
                        () =>
                            <DataTableAction>
                                <CheckButton
                                    checked={childScopeCustomPoliciesEnabled}
                                    title={localization.actions.customPoliciesFlatView()}
                                    onCheckedChanged={
                                        checked => {
                                            definition.storage.flatView?.setValue(checked);
                                            setChildScopeCustomPoliciesEnabled(checked);
                                        }}/>
                            </DataTableAction>).
                    concatIf(
                        !_.isNil(definition.csvOptions) &&
                        !_.isNil(definition.csvOptions.getCsvItemPage) &&
                        !_.isNil(definition.csvOptions.prefix),
                        () =>
                            <DataTableAction>
                                <CsvExportButton
                                    disabled={_.isEmpty(filteredItems)}
                                    fileNameOptions={{
                                        filtered: _.size(filteredItems) !== _.size(items),
                                        prefix: definition.csvOptions!.prefix!
                                    }}
                                    getItemPage={definition.csvOptions!.getCsvItemPage!(filteredItems)}/>
                            </DataTableAction>).
                    concat(definition.dataTableActions).
                    value(),
            [childScopeCustomPoliciesEnabled, definition.csvOptions, definition.dataTableActions, definition.storage.flatView, filteredItems]);

    return (
        <Fragment>
            {addOrEditOpen &&
                <Dialog
                    size="medium"
                    variant="editor"
                    onClose={
                        () =>
                            setRiskPoliciesContext(
                                context => ({
                                    ...context,
                                    addOrEditOpen: false
                                }))}>
                    <Loading>
                        {definition.addOrEdit}
                    </Loading>
                </Dialog>}
            <ItemTableBase
                actionsRef={dataTableActionsRef}
                columnIdToGetItemValueMap={definition.columnIdToGetItemValueMap}
                columnOptions={{
                    orderOptions: {
                        enabled: true,
                        persistenceStorageItem: definition.storage.columnOrder
                    },
                    resizable: true,
                    selectorOptions: {
                        enabled: true,
                        persistenceStorageItem: definition.storage.columnSelector
                    }
                }}
                csvExportFilePrefixes={definition.csvOptions?.csvExportFilePrefixes}
                dataTableActions={dataTableActions}
                defaultSortColumnIdOrIds={definition?.options?.defaultSortColumnIds ?? []}
                emptyMessageOptions={{
                    emptyMessageText:
                        new EmptyMessageText(
                            localization.empty.withoutFilter(),
                            localization.empty.withFilter())
                }}
                filterOptions={{
                    persist: true,
                    visibilityStorageItem: definition.storage.tableFilters
                }}
                getCsvItem={definition.csvOptions?.getCsvItem}
                getItemId={definition.getItemId}
                highlightItem={definition.highlightItemHashRouteTemplate}
                items={items}
                rowOptions={definition?.options?.rowOptions}
                showEmptyTable={true}
                variant="view"
                onFilteredItemsChanged={items => setFilteredItems(items)}>
                {definition.additionalColumnElements}
            </ItemTableBase>
        </Fragment>);
}