﻿import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition, useGetCommonKubernetesClusterRiskContext } from "../../../..";
import { Contract, Entity, entityModelStore, InlineEntities } from "../../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../../utilities";

export function useKubernetesClusterKubeletHostnameOverrideUsedRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.KubernetesClusterKubeletHostnameOverrideUsedRisk;
    const clusterModel = entityModelStore.useGet(risk.entityId);

    const getCommonKubernetesClusterRiskContext = useGetCommonKubernetesClusterRiskContext(clusterModel);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.kubernetes.hooks.clusters.kubelets.useKubernetesClusterKubeletHostnameOverrideUsedRiskDefinition",
            () => ({
                contextItems: {
                    hostnameOverrideUsedNodes: "The cluster has {{nodes}} in which the --hostname-override argument is set"
                },
                description: "{{cluster}} is overriding node hostnames",
                resolutionSection: {
                    step1: "For the following {{nodes}}, ensure that the --hostname-override argument is not set"
                }
            }));


    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            cluster:
                <Entity
                    entityIdOrModel={clusterModel}
                    entityTypeNameTranslatorOptions={{
                        includeServiceName: true,
                        variant: "title"
                    }}
                    variant="typeText"/>
        }),
        () => [
            localization.resolutionSection.step1({
                nodes:
                    <InlineEntities
                        entityIdsOrModels={risk.virtualMachineIds}
                        entityTypeName={Contract.TypeNames.IKubernetesNode}
                        variant="itemCountAndType"/>
            })
        ],
        riskModel,
        () => {
            const clusterRiskContext = getCommonKubernetesClusterRiskContext(clusterModel);

            return [
                clusterRiskContext.generalInformation,
                clusterRiskContext.virtualNetwork,
                new RiskDefinitionContextItem(
                    localization.contextItems.hostnameOverrideUsedNodes({
                        nodes:
                            <InlineEntities
                                entityIdsOrModels={risk.virtualMachineIds}
                                entityTypeName={Contract.TypeNames.IKubernetesNode}
                                variant="itemCountAndType"/>
                    })),
                clusterRiskContext.sensitive,
                clusterRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        });
}