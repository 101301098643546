import { NoneIcon, Optional, Tooltip, UnexpectedError, useLocalization } from "@infrastructure";
import { Divider, Grid2, Stack, Typography, useTheme } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { Fragment, useCallback, useMemo } from "react";
import { Contract, TypeHelper } from "../../../../../../../common";

type DataAnalysisResourceDataClassifierSamplesProps = {
    dataClassifierIdToSamplesMap: Dictionary<Contract.DataClassifierSample[]>;
    dataClassifierModelMap: Dictionary<Contract.DataClassifierModel>;
    showDataTypeColumn?: boolean;
};

export function DataAnalysisResourceDataClassifierSamples({ dataClassifierIdToSamplesMap, dataClassifierModelMap, showDataTypeColumn = true }: DataAnalysisResourceDataClassifierSamplesProps) {
    const localization =
        useLocalization(
            "views.customer.entities.profile.dataAnalysisResourceDataClassifierSamples",
            () => ({
                additionalItemCount: "+{{additionalItemCount | NumberFormatter.humanize}}",
                filedName: {
                    semiStructuredDataClassifierSampleLocation: "path",
                    structuredDataClassifierSampleLocation: "column",
                    unstructuredDataClassifierSampleLocation: "line"
                },
                location: "{{name}}: {{value}}",
                searchList: {
                    emptyMessageText: "No data",
                    title: {
                        dataType: "Data Type",
                        location: "Location",
                        sample: "Sample"
                    }
                }
            }));

    const getSampleLocation =
        useCallback(
            (location: Optional<Contract.DataClassifierSampleLocation>) => {
                function getText() {
                    if (_.isNil(location)) {
                        return ["", ""];
                    }

                    switch (location.typeName) {
                        case Contract.TypeNames.StructuredDataClassifierSampleLocation:
                            return [localization.filedName.structuredDataClassifierSampleLocation(), `${(location as Contract.StructuredDataClassifierSampleLocation).columnName ?? ""}`];
                        case Contract.TypeNames.SemiStructuredDataClassifierSampleLocation:
                            return [localization.filedName.semiStructuredDataClassifierSampleLocation(), `${(location as Contract.SemiStructuredDataClassifierSampleLocation).propertyPath ?? ""}`];
                        case Contract.TypeNames.UnstructuredDataClassifierSampleLocation:
                            return [localization.filedName.unstructuredDataClassifierSampleLocation(), `${(location as Contract.UnstructuredDataClassifierSampleLocation).line ?? ""}`];
                        default:
                            throw new UnexpectedError("getSampleLocation", location.typeName);
                    }
                }

                const [name, value] = getText();
                return {
                    name,
                    value
                };
            },
            [localization]);

    const classifierSampleMap: DataClassifierSampleData[] =
        useMemo(
            () =>
                _(dataClassifierIdToSamplesMap).
                    entries().
                    map(
                        ([dataClassifierId, samples]) =>
                            _.map(
                                samples,
                                sample => ({
                                    dataClassifierId,
                                    name: dataClassifierModelMap[dataClassifierId].name!,
                                    sample
                                }))).
                    flatten().
                    sort((a, b) =>
                        TypeHelper.getEnumValue(Contract.TypeNames.DataSensitivity, dataClassifierModelMap[b.dataClassifierId].sensitivity) -
                        TypeHelper.getEnumValue(Contract.TypeNames.DataSensitivity, dataClassifierModelMap[a.dataClassifierId].sensitivity)).
                    value(),
            [dataClassifierIdToSamplesMap]);

    const gridSize =
        showDataTypeColumn
            ? 4
            : 6;

    const theme = useTheme();
    return (
        <Tooltip
            sx={{
                maxWidth: "100%",
                width: "min-content"
            }}
            titleOrGetTitle={
                <Stack
                    spacing={0.5}
                    sx={{
                        minWidth: theme.spacing(60),
                        paddingLeft: theme.spacing(1)
                    }}>
                    <Grid2
                        alignItems="center"
                        container={true}
                        spacing={1}
                        sx={{
                            overflow: "auto",
                            scrollbarGutter: "stable"
                        }}>
                        {showDataTypeColumn &&
                            <Grid2 size={gridSize}>
                                <Typography sx={{ fontWeight: "bold" }}>
                                    {localization.searchList.title.dataType()}
                                </Typography>
                            </Grid2>}
                        <Grid2 size={gridSize}>
                            <Typography sx={{ fontWeight: "bold" }}>
                                {localization.searchList.title.sample()}
                            </Typography>
                        </Grid2>
                        <Grid2 size={gridSize}>
                            <Typography sx={{ fontWeight: "bold" }}>
                                {localization.searchList.title.location()}
                            </Typography>
                        </Grid2>
                    </Grid2>
                    <Divider/>
                    <Stack
                        sx={{
                            maxHeight: "300px",
                            overflow: "auto",
                            scrollbarGutter: "stable"
                        }}>
                        <Grid2
                            alignItems="center"
                            container={true}
                            spacing={1}
                            width="100%">
                            {_.map(
                                classifierSampleMap,
                                (segment, index) => {
                                    const { name: locationName, value: locationValue } = getSampleLocation(segment.sample.location);
                                    return (
                                        <Fragment key={`${segment.name} ${index}`}>
                                            {showDataTypeColumn &&
                                                <Grid2 size={gridSize}>
                                                    {_.isEmpty(segment.name)
                                                        ? <NoneIcon/>
                                                        : <Typography
                                                            noWrap={true}
                                                            sx={{ color: theme.palette.text.secondary }}>
                                                            {segment.name}
                                                        </Typography>}
                                                </Grid2>}
                                            <Grid2 size={gridSize}>
                                                {_.isEmpty(segment.sample.maskedValue)
                                                    ? <NoneIcon/>
                                                    : <Typography noWrap={true}>
                                                        {segment.sample.maskedValue}
                                                    </Typography>}
                                            </Grid2>
                                            <Grid2 size={gridSize}>
                                                {_.isEmpty(locationName) || _.isEmpty(locationValue)
                                                    ? <NoneIcon/>
                                                    : <Typography noWrap={true}>
                                                        {localization.location({
                                                            name: locationName,
                                                            value: locationValue
                                                        })}
                                                    </Typography>}
                                            </Grid2>
                                        </Fragment>);
                                })}
                        </Grid2>
                    </Stack>
                </Stack>}>
            <Typography
                component="span"
                sx={{
                    cursor: "default",
                    textDecoration: "underline"
                }}>
                {classifierSampleMap.length}
            </Typography>
        </Tooltip>);
}

type DataClassifierSampleData = {
    dataClassifierId: string;
    name: string;
    sample: Contract.DataClassifierSample;
};