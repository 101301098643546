import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useOpCommonKubernetesNamespaceResourceInfoItemElements, useOpCommonKubernetesWorkloadResourceInfoItemElements, useOpDefaultResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../..";
import { Contract, InfoItem } from "../../../../../../../../../../common";
import { Info } from "../../../../components";
import { KubernetesContainers, KubernetesLabelSelectorInfoItem, KubernetesTolerationsInfoCard, KubernetesYamlInfoCard } from "../../components";

export function useOpKubernetesReplicaSetDefinition(resourceModel: Contract.OpResourceModel, options?: DefinitionOptions) {
    const commonKubernetesNamespaceResourceInfoItemElements = useOpCommonKubernetesNamespaceResourceInfoItemElements(resourceModel);
    const commonKubernetesWorkloadResourceInfoItemElements = useOpCommonKubernetesWorkloadResourceInfoItemElements(resourceModel as Contract.OpKubernetesReplicaSetModel);
    const defaultResourceInfoItemElements = useOpDefaultResourceInfoItemElements(resourceModel);
    const replicaSet = resourceModel.entity as Contract.OpKubernetesReplicaSet;

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.op.useOpKubernetesReplicaSetDefinition",
            () => ({
                info: {
                    replicaCount: "Desired Replicas"
                },
                tabs: {
                    containers: "Containers",
                    yaml: "YAML"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs:
            _<EntityProfileDefinitionTab>([]).
                concatIf(
                    !_.isEmpty(replicaSet.data.podTemplateSpec.podSpec.containers),
                    () =>
                        new EntityProfileDefinitionTab(
                            ProfileCategory.Overview,
                            <KubernetesContainers workloadResourceModel={resourceModel as Contract.OpKubernetesWorkloadResourceModel}/>,
                            localization.tabs.containers(),
                            "containers")).
                concat(
                    new EntityProfileDefinitionTab(
                        ProfileCategory.Overview,
                        <KubernetesYamlInfoCard rawYaml={replicaSet.data.rawYaml!}/>,
                        localization.tabs.yaml(),
                        "yaml")).
                value(),
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={[]}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <KubernetesLabelSelectorInfoItem
                        key="podLabelSelector"
                        labelSelector={replicaSet.data.podLabelSelector}/>,
                    <InfoItem
                        key="replicaCount"
                        title={localization.info.replicaCount()}
                        value={replicaSet.data.replicaCount}/>,
                    commonKubernetesNamespaceResourceInfoItemElements.clusterInfoItemElement,
                    commonKubernetesNamespaceResourceInfoItemElements.namespaceInfoItemElement,
                    commonKubernetesWorkloadResourceInfoItemElements.controllerResourceInfoItemElement,
                    commonKubernetesWorkloadResourceInfoItemElements.serviceAccountInfoItemElement,
                    commonKubernetesNamespaceResourceInfoItemElements.tagsInfoItemElement,
                    commonKubernetesNamespaceResourceInfoItemElements.annotationsInfoItemElement
                ]}
                options={options?.infoOptions}>
                {!_.isEmpty(replicaSet.data.podTemplateSpec.podSpec.tolerations) &&
                    <KubernetesTolerationsInfoCard tolerations={replicaSet.data.podTemplateSpec.podSpec.tolerations}/>}
            </Info>
    });
}