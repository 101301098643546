import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { useGetAwsAthenaWorkgroupRiskContext } from "../contexts";

export function useAwsAthenaWorkgroupLoggingDisabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsAthenaWorkgroupLoggingDisabledRisk;
    const workgroupModel = entityModelStore.useGet(risk.entityId) as Contract.AwsAthenaWorkgroupModel;

    const getAwsAthenaWorkgroupRiskContext = useGetAwsAthenaWorkgroupRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsAthenaWorkgroupLoggingDisabledRiskDefinition",
            () => ({
                description: "{{workgroup}} logging is not enabled",
                sections: {
                    resolution: {
                        step1: "Click **Edit**",
                        step2: "Expand the **Settings** section",
                        step3: "Select the **Publish query metrics to AWS CloudWatch** option",
                        step4: "Click **Save changes**"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            workgroup:
                <Entity
                    entityIdOrModel={workgroupModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.AthenaWorkgroup,
                AwsConsoleUrlBuilder.getAthenaWorkgroupUrl(workgroupModel.entity as Contract.AwsAthenaWorkgroup)),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4()
        ],
        riskModel,
        () => {
            const athenaWorkGroupRiskContext = getAwsAthenaWorkgroupRiskContext(workgroupModel);
            return [
                athenaWorkGroupRiskContext.generalInformation,
                athenaWorkGroupRiskContext.status,
                athenaWorkGroupRiskContext.sensitive,
                athenaWorkGroupRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}