﻿import { map } from "@infrastructure";
import { Contract, tenantModelStore } from "../../../../../../../../../../../../../../../common";
import { AzureConsoleUrlBuilder } from "../../../../../../../../../../../../../../../tenants";

export function useAzureManagementManagementGroupDefinition(entityModel: Contract.EntityModel, page: Contract.AzureConsoleEntityPage) {
    const aadTenantModel = tenantModelStore.useGet(entityModel.entity.tenantId);
    const aadTenantConfiguration = aadTenantModel.configuration as Contract.AadTenantConfiguration;

    const getUrlFunction =
        map(
            page,
            {
                [Contract.AzureConsoleEntityPage.Overview]: () => AzureConsoleUrlBuilder.GetManagementManagementGroupOverviewUrl,
                [Contract.AzureConsoleEntityPage.RoleAssignments]: () => AzureConsoleUrlBuilder.GetManagementManagementGroupRoleAssignmentsUrl
            });

    return {
        url:
            getUrlFunction(
                aadTenantConfiguration.rawId,
                encodeURIComponent(entityModel.entity.displayName),
                aadTenantConfiguration.partitionType,
                (entityModel.entity as Contract.AzureManagementManagementGroup).rawShortId)
    };
}