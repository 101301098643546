import { useLocalization } from "@infrastructure";
import { VulnerabilityScoreSourceType } from "../components";

export function useVulnerabilityScoreSourceTypeTranslator() {
    const localization =
        useLocalization(
            "common.hooks.useVulnerabilityScoreSourceTypeTranslator",
            () => ({
                [VulnerabilityScoreSourceType.Epss]: "EPSS",
                [VulnerabilityScoreSourceType.Cvss]: "CVSS",
                [VulnerabilityScoreSourceType.Vpr]: "VPR"
            }));

    return (type: VulnerabilityScoreSourceType) => localization[type]();
}