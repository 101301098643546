﻿import { Steps } from "@infrastructure";
import React from "react";
import { Contract, useEntityTypeNameTranslator } from "../../../../../../../../../../../../../../../../common";
import { useInformationSteps } from "../../useInformationSteps";

export type ContextSectionProps = {
    riskModel: Contract.AwsInboundExternalEc2InstanceRiskModel;
    tagResourceGroupModel: Contract.AwsResourceGroupModel;
};

export function ContextSection({ riskModel, tagResourceGroupModel }: ContextSectionProps) {
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const translatedTypeName =
        entityTypeNameTranslator(
            tagResourceGroupModel.entity.typeName,
            {
                includeServiceName: true,
                variant: "text"
            });
    const informationSteps =
        useInformationSteps(
            riskModel,
            `${translatedTypeName} **${tagResourceGroupModel.entity.displayName}**`);

    return (
        <Steps variant="bullets">
            {informationSteps}
        </Steps>);
}