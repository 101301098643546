import { AnalyticsOptions, CheckboxField, PagedValuesFilterSelection, useFilterConnectorContext, useLocalization, useSetFilterConnectorContext, useTrackAnalytics } from "@infrastructure";
import { Divider, Stack, useTheme } from "@mui/material";
import _ from "lodash";
import React, { useCallback, useMemo } from "react";

type SelectorsProps = {
    analyticsOptions?: AnalyticsOptions<"toggleMatches" | "toggleValues">;
    emptyValueEnabled?: boolean;
    filtered: boolean;
    limitedFilteredFirstValuePageValues?: string[];
    unfilteredValueCount: number;
};

export function Selectors({ analyticsOptions, emptyValueEnabled, filtered, limitedFilteredFirstValuePageValues, unfilteredValueCount }: SelectorsProps) {
    const { filter } = useFilterConnectorContext() as { filter?: PagedValuesFilterSelection<any> };
    const setFilterConnectorContext = useSetFilterConnectorContext();

    const selectedFilteredValues =
        useMemo(
            () => {
                if (!filtered ||
                    _.isNil(limitedFilteredFirstValuePageValues)) {
                    return undefined;
                }

                if (_.isNil(filter)) {
                    return [];
                } else if (filter.type === "include") {
                    return _.intersection(limitedFilteredFirstValuePageValues, filter.values);
                } else {
                    return _.difference(limitedFilteredFirstValuePageValues, filter.values);
                }
            },
            [filter, filtered, limitedFilteredFirstValuePageValues]);

    const localization =
        useLocalization(
            "infrastructure.filters.pagedValuesFilter.selectors",
            () => ({
                actions: {
                    all: {
                        select: "Select all"
                    },
                    filtered: {
                        limitExceeded: "Select matches is not supported for more than 65K results. Narrow down your search to allow selecting all matches.",
                        select: "Select matches"
                    }
                }
            }));

    const trackAnalytics = useTrackAnalytics();
    const toggleValues =
        useCallback(
            (selectValues: boolean) => {
                setFilterConnectorContext(
                    context => {
                        const filter =
                            selectValues
                                ? new PagedValuesFilterSelection(false, "exclude", [])
                                : undefined;

                        return ({
                            ...context,
                            filter
                        });
                    });

                if (analyticsOptions?.toggleValues) {
                    trackAnalytics(
                        analyticsOptions.toggleValues,
                        {
                            "Filter Value All": selectValues
                        });
                }
            },
            [analyticsOptions]);

    const toggleMatches =
        useCallback(
            (selectFilterValues: boolean) => {
                setFilterConnectorContext(
                    context => {
                        const filter = context.filter as PagedValuesFilterSelection<any> | undefined;

                        if (_.isNil(filter)) {
                            return ({
                                ...context,
                                filter: new PagedValuesFilterSelection(
                                    false,
                                    selectFilterValues
                                        ? "include"
                                        : "exclude",
                                    limitedFilteredFirstValuePageValues!)
                            });
                        }

                        const newValues =
                            filter.type === "exclude" && !selectFilterValues || filter.type === "include" && selectFilterValues
                                ? _.union(filter.values, limitedFilteredFirstValuePageValues!)
                                : _.difference(filter.values, limitedFilteredFirstValuePageValues!);

                        return ({
                            ...context,
                            filter:
                                filter.type === "include" && _.isEmpty(newValues)
                                    ? undefined
                                    : new PagedValuesFilterSelection(
                                        filter.emptyValue,
                                        filter.type,
                                        newValues)
                        });
                    });

                if (analyticsOptions?.toggleMatches) {
                    trackAnalytics(
                        analyticsOptions.toggleMatches,
                        {
                            "Filter Value Matches": selectFilterValues
                        });
                }
            },
            [analyticsOptions, limitedFilteredFirstValuePageValues]);

    const excludeAllValues =
        filter?.type === "exclude" &&
        (_.isNil(emptyValueEnabled) || emptyValueEnabled) &&
        filter?.values.length === unfilteredValueCount;

    const excludeNoValues =
        filter?.type === "exclude" &&
        (_.isNil(emptyValueEnabled) || !emptyValueEnabled) &&
        _.isEmpty(filter?.values);

    const includeAllValues =
        filter?.type === "include" &&
        (_.isNil(emptyValueEnabled) || emptyValueEnabled) &&
        filter?.values.length === unfilteredValueCount;

    const includeNoValues =
        filter?.type === "include" &&
        (_.isNil(emptyValueEnabled) || !emptyValueEnabled) &&
        _.isEmpty(filter?.values);

    const theme = useTheme();
    return (
        <Stack
            spacing={1}
            sx={{ padding: theme.spacing(0, 0.5) }}>
            {filtered
                ? !_.isEmpty(limitedFilteredFirstValuePageValues) &&
                <CheckboxField
                    checked={
                        !_.isEmpty(limitedFilteredFirstValuePageValues) &&
                        !_.isNil(selectedFilteredValues) &&
                        selectedFilteredValues.length === limitedFilteredFirstValuePageValues!.length}
                    disabled={_.isNil(limitedFilteredFirstValuePageValues)}
                    indeterminate={
                        !_.isEmpty(limitedFilteredFirstValuePageValues) &&
                        !_.isEmpty(selectedFilteredValues) &&
                        selectedFilteredValues!.length < limitedFilteredFirstValuePageValues!.length}
                    tooltipText={
                        _.isNil(limitedFilteredFirstValuePageValues)
                            ? localization.actions.filtered.limitExceeded()
                            : undefined}
                    onChange={(event, checked) => toggleMatches(checked)}>
                    {localization.actions.filtered.select()}
                </CheckboxField>
                : <CheckboxField
                    checked={excludeNoValues || includeAllValues}
                    indeterminate={
                        !_.isNil(filter) &&
                        !(excludeNoValues || includeAllValues) &&
                        !(excludeAllValues || includeNoValues)}
                    onChange={(event, checked) => toggleValues(checked)}>
                    {localization.actions.all.select()}
                </CheckboxField>}
            <Divider flexItem={true}/>
        </Stack>);
}