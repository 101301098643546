import { useMemo } from "react";
import { useAzureBotServiceBotServiceDefinition, useAzureCognitiveServicesAccountDefinition, useAzureComputeDiskDefinition, useAzureComputeDiskSnapshotDefinition, useAzureComputeVirtualMachineDefinition, useAzureComputeVirtualMachineScaleSetDefinition, useAzureComputeVirtualMachineScaleSetVirtualMachineDefinition, useAzureContainerRegistryRegistryDefinition, useAzureContainerRegistryRepositoryDefinition, useAzureContainerServiceManagedClusterAgentPoolDefinition, useAzureContainerServiceManagedClusterDefinition, useAzureDefaultResourceGroupResourceDefinition, useAzureDocumentDbDatabaseAccountDefinition, useAzureEventHubNamespaceDefinition, useAzureKeyVaultVaultCertificateDefinition, useAzureKeyVaultVaultDefinition, useAzureKeyVaultVaultKeyDefinition, useAzureKeyVaultVaultSecretDefinition, useAzureLogicWorkflowDefinition, useAzureMachineLearningWorkspaceDefinition, useAzureManagedIdentityManagedIdentity, useAzureMySqlServerDefinition, useAzureNetworkApplicationGatewayDefinition, useAzureNetworkApplicationSecurityGroupDefinition, useAzureNetworkDnsZoneDefinition, useAzureNetworkLoadBalancerDefinition, useAzureNetworkNetworkInterfaceDefinition, useAzureNetworkNetworkSecurityGroupDefinition, useAzureNetworkPrivateDnsZoneDefinition, useAzureNetworkPrivateEndpointDefinition, useAzureNetworkPublicIpAddressDefinition, useAzureNetworkPublicIpPrefixDefinition, useAzureNetworkRouteTableDefinition, useAzureNetworkVirtualNetworkDefinition, useAzureNetworkVirtualNetworkSubnetDefinition, useAzurePostgreSqlServerDefinition, useAzureSqlServerDatabaseDefinition, useAzureSqlServerDefinition, useAzureStorageStorageAccountBlobContainerDefinition, useAzureStorageStorageAccountDefinition, useAzureWebApplicationDefinition } from ".";
import { DefinitionOptions } from "../../..";
import { Contract } from "../../../../../../../../../../common";

export function useAzureResourceGroupResourceDefinition(resourceModel: Contract.AzureResourceModel, options?: DefinitionOptions) {
    const useDefinition =
        useMemo(
            () => {
                switch (resourceModel.entity.typeName) {
                    case Contract.TypeNames.AzureBotServiceBotService:
                        return useAzureBotServiceBotServiceDefinition;
                    case Contract.TypeNames.AzureCognitiveServicesAccount:
                        return useAzureCognitiveServicesAccountDefinition;
                    case Contract.TypeNames.AzureComputeDisk:
                        return useAzureComputeDiskDefinition;
                    case Contract.TypeNames.AzureComputeDiskSnapshot:
                        return useAzureComputeDiskSnapshotDefinition;
                    case Contract.TypeNames.AzureComputeVirtualMachine:
                        return useAzureComputeVirtualMachineDefinition;
                    case Contract.TypeNames.AzureComputeVirtualMachineScaleSet:
                        return useAzureComputeVirtualMachineScaleSetDefinition;
                    case Contract.TypeNames.AzureComputeVirtualMachineScaleSetVirtualMachine:
                        return useAzureComputeVirtualMachineScaleSetVirtualMachineDefinition;
                    case Contract.TypeNames.AzureContainerRegistryRegistry:
                        return useAzureContainerRegistryRegistryDefinition;
                    case Contract.TypeNames.AzureContainerRegistryRepository:
                        return useAzureContainerRegistryRepositoryDefinition;
                    case Contract.TypeNames.AzureContainerServiceManagedCluster:
                        return useAzureContainerServiceManagedClusterDefinition;
                    case Contract.TypeNames.AzureContainerServiceManagedClusterAgentPool:
                        return useAzureContainerServiceManagedClusterAgentPoolDefinition;
                    case Contract.TypeNames.AzureDocumentDbDatabaseAccount:
                        return useAzureDocumentDbDatabaseAccountDefinition;
                    case Contract.TypeNames.AzureEventHubNamespace:
                        return useAzureEventHubNamespaceDefinition;
                    case Contract.TypeNames.AzureKeyVaultVault:
                        return useAzureKeyVaultVaultDefinition;
                    case Contract.TypeNames.AzureKeyVaultVaultCertificate:
                        return useAzureKeyVaultVaultCertificateDefinition;
                    case Contract.TypeNames.AzureKeyVaultVaultKey:
                        return useAzureKeyVaultVaultKeyDefinition;
                    case Contract.TypeNames.AzureKeyVaultVaultSecret:
                        return useAzureKeyVaultVaultSecretDefinition;
                    case Contract.TypeNames.AzureLogicWorkflow:
                        return useAzureLogicWorkflowDefinition;
                    case Contract.TypeNames.AzureMachineLearningWorkspace:
                        return useAzureMachineLearningWorkspaceDefinition;
                    case Contract.TypeNames.AzureManagedIdentitySystemManagedIdentity:
                    case Contract.TypeNames.AzureManagedIdentityUserManagedIdentity:
                        return useAzureManagedIdentityManagedIdentity;
                    case Contract.TypeNames.AzureMySqlFlexibleServer:
                    case Contract.TypeNames.AzureMySqlSingleServer:
                        return useAzureMySqlServerDefinition;
                    case Contract.TypeNames.AzureNetworkApplicationGateway:
                        return useAzureNetworkApplicationGatewayDefinition;
                    case Contract.TypeNames.AzureNetworkApplicationSecurityGroup:
                        return useAzureNetworkApplicationSecurityGroupDefinition;
                    case Contract.TypeNames.AzureNetworkDnsZone:
                        return useAzureNetworkDnsZoneDefinition;
                    case Contract.TypeNames.AzureNetworkLoadBalancer:
                        return useAzureNetworkLoadBalancerDefinition;
                    case Contract.TypeNames.AzureNetworkNetworkInterface:
                        return useAzureNetworkNetworkInterfaceDefinition;
                    case Contract.TypeNames.AzureNetworkNetworkSecurityGroup:
                        return useAzureNetworkNetworkSecurityGroupDefinition;
                    case Contract.TypeNames.AzureNetworkPrivateDnsZone:
                        return useAzureNetworkPrivateDnsZoneDefinition;
                    case Contract.TypeNames.AzureNetworkPrivateEndpoint:
                        return useAzureNetworkPrivateEndpointDefinition;
                    case Contract.TypeNames.AzureNetworkPublicIpAddress:
                        return useAzureNetworkPublicIpAddressDefinition;
                    case Contract.TypeNames.AzureNetworkPublicIpPrefix:
                        return useAzureNetworkPublicIpPrefixDefinition;
                    case Contract.TypeNames.AzureNetworkRouteTable:
                        return useAzureNetworkRouteTableDefinition;
                    case Contract.TypeNames.AzureNetworkVirtualNetwork:
                        return useAzureNetworkVirtualNetworkDefinition;
                    case Contract.TypeNames.AzureNetworkVirtualNetworkSubnet:
                        return useAzureNetworkVirtualNetworkSubnetDefinition;
                    case Contract.TypeNames.AzurePostgreSqlFlexibleServer:
                    case Contract.TypeNames.AzurePostgreSqlSingleServer:
                        return useAzurePostgreSqlServerDefinition;
                    case Contract.TypeNames.AzureSqlServer:
                        return useAzureSqlServerDefinition;
                    case Contract.TypeNames.AzureSqlServerDatabase:
                        return useAzureSqlServerDatabaseDefinition;
                    case Contract.TypeNames.AzureStorageStorageAccount:
                        return useAzureStorageStorageAccountDefinition;
                    case Contract.TypeNames.AzureStorageStorageAccountBlobContainer:
                        return useAzureStorageStorageAccountBlobContainerDefinition;
                    case Contract.TypeNames.AzureWebApplication:
                        return useAzureWebApplicationDefinition;
                    default:
                        return useAzureDefaultResourceGroupResourceDefinition;
                }
            },
            []);

    return useDefinition(resourceModel as Contract.AzureResourceGroupResourceModel, options);
}