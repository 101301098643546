import { Loading, Optional, useLocalization } from "@infrastructure";
import { Box } from "@mui/material";
import _ from "lodash";
import React from "react";
import { useGetAzurePostgreSqlServerRiskContext } from "../../..";
import { Contract, CustomerConsoleAppUrlHelper, Entity, entityModelStore, Network, useTheme } from "../../../../../../../../../../../../common";
import { ToolbarToggleFilterId } from "../../../../../../../../../../../../common/components/Network/components";
import { RiskDefinitionSection } from "../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { EntityExternalConsoleLink, FirewallRulesDetailsSection } from "../../components";

export function useAzurePostgreSqlServerWideRangeInboundRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AzurePostgreSqlServerWideRangeInboundRisk;
    const serverModel = entityModelStore.useGet(risk.entityId) as Contract.AzurePostgreSqlServerModel;
    const server = serverModel.entity as Contract.AzurePostgreSqlServer;
    const serverNetwork = serverModel.entityNetwork as Optional<Contract.AzureDatabaseResourceStateNetwork>;

    const getAzurePostgreSqlServerRiskContext = useGetAzurePostgreSqlServerRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.compliance.useAzurePostgreSqlServerWideRangeInboundRiskDefinition",
            () => ({
                description: "{{server}} network configuration allows wide network inbound access",
                sections: {
                    firewallRules: "Firewall Rules",
                    networkGraph: "Network",
                    resolution: {
                        step1: {
                            [Contract.TypeNames.AzurePostgreSqlFlexibleServer]: "Uncheck **Allow public access from any Azure service within Azure to this server** checkbox",
                            [Contract.TypeNames.AzurePostgreSqlSingleServer]: "Switch the **Allow access to Azure services** to **No**"
                        },
                        step2: "If any Azure resources require access to the database server, add the virtual network of the resource to the firewall rules",
                        step3: "For each public IP address rule, edit the address range to restrict it only to the required IP addresses or delete it",
                        step4: "Click **Save** button on top to confirm"
                    }
                }
            }));

    const theme = useTheme();
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            server:
                <Entity
                    entityIdOrModel={serverModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={risk.entityId}
                key={risk.entityId}
                page={
                    serverModel.entity.typeName === Contract.TypeNames.AzurePostgreSqlSingleServer
                        ? Contract.AzureConsoleEntityPage.ConnectionSecurity
                        : Contract.AzureConsoleEntityPage.Networking}/>,
            (serverModel.entity as Contract.AzurePostgreSqlServer).allowAnyAzureInternalIpAddress
                ? localization.sections.resolution.step1[serverModel.entity.typeName as Contract.TypeNames.AzurePostgreSqlFlexibleServer | Contract.TypeNames.AzurePostgreSqlSingleServer]()
                : undefined,
            localization.sections.resolution.step2(),
            _.isEmpty(serverNetwork?.wideRangeFirewallIpAddressRangeRules)
                ? undefined
                : localization.sections.resolution.step3(),
            localization.sections.resolution.step4()
        ],
        riskModel,
        () => {
            const serverRiskContext = getAzurePostgreSqlServerRiskContext(serverModel);
            return [
                serverRiskContext.generalInformation,
                serverRiskContext.sensitive,
                ...serverRiskContext.firewallRules,
                serverRiskContext.allowAnyAzureInternalIpAddress,
                serverRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        },
        {
            sections:
                !server.systemDeleted
                    ? _<RiskDefinitionSection>([]).
                        concat(
                            new RiskDefinitionSection(
                                <Box sx={{ minHeight: theme.spacing(20) }}>
                                    <Loading>
                                        <Network
                                            baseUrl={CustomerConsoleAppUrlHelper.getRiskProfileRelativeUrl(riskModel.id)}
                                            entityId={risk.entityId}
                                            initialFilterMap={{ [ToolbarToggleFilterId.WideRangeSourceSubnet]: true }}
                                            tenantType={Contract.TenantType.Azure}
                                            variant="risk"/>
                                    </Loading>
                                </Box>,
                                localization.sections.networkGraph(),
                                {
                                    profilePageOnly: true
                                })).
                        concatIf(
                            !_.isEmpty(serverNetwork?.wideRangeFirewallIpAddressRangeRules),
                            () =>
                                new RiskDefinitionSection(
                                    <FirewallRulesDetailsSection
                                        firewallRules={server.firewallIpAddressRangeRules}
                                        getHighlightColor={
                                            (firewallRule: Contract.AzureResourceFirewallIpAddressRangeRule, opacity) =>
                                                _.includes(serverNetwork!.wideRangeFirewallIpAddressRangeRules, firewallRule)
                                                    ? opacity
                                                        ? theme.palette.opacity(theme.palette.severity(riskModel.risk.severity), opacity)
                                                        : theme.palette.severity(riskModel.risk.severity)
                                                    : undefined
                                        }/>,
                                    localization.sections.firewallRules())).
                        value()
                    : undefined
        });
}