import { Stack } from "@mui/material";
import React from "react";
import { Contract, RiskType } from "../../../../../../../../common";
import { Actions } from "../../../Actions";
import { useItemsContext } from "../useItemsContextProvider";

type ActionsCellProps = {
    item: Contract.RiskModel;
    riskType: RiskType;
};

export function ActionsCell({ item, riskType }: ActionsCellProps) {
    const { reloadRiskModel } = useItemsContext();
    return (
        <Stack
            direction="row"
            justifyContent="flex-end"
            sx={{
                backgroundColor: "inherit",
                overflow: "hidden"
            }}>
            <Actions
                reloadRiskModel={reloadRiskModel}
                riskModel={item}
                riskType={riskType}
                variant="table"/>
        </Stack>);
}