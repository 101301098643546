﻿import { TimeFormatter, useLocalization } from "@infrastructure";
import React from "react";
import { Contract, entityModelStore, InfoItem, useTenantNameTranslator } from "../../../../../../../../../../common";
import { EntitiesInfoItem } from "../../../../components";
import { useCommonEntityInfoItemElements } from "../useCommonEntityInfoItemElements";

export function useAwsCommonEntityInfoItemElements(entityModel: Contract.AwsEntityModel) {
    const commonEntityInfoItemElements = useCommonEntityInfoItemElements(entityModel);
    const entity = entityModel.entity as Contract.AwsEntity;
    const tenantEntityModel = entityModelStore.useGet(entity.tenantId);

    const tenantNameTranslator = useTenantNameTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsCommonEntityInfoItemElements",
            () => ({
                syncTime: "Sync Time",
                tenantId: "Account"
            }));

    return {
        ...commonEntityInfoItemElements,
        syncTimeInfoItemElement:
            <InfoItem
                key="syncTime"
                title={localization.syncTime()}
                value={TimeFormatter.profileFormatDateTime(entity.syncTime)}/>,
        tenantInfoItemElement:
            tenantEntityModel.unknown
                ? <InfoItem
                    key="tenant"
                    title={localization.tenantId()}
                    value={tenantNameTranslator(entity.tenantId, { includeRawId: true })}/>
                : <EntitiesInfoItem
                    entityIdsOrModels={tenantEntityModel}
                    entityTypeName={tenantEntityModel.entity.typeName}
                    key="tenant"
                    title={localization.tenantId()}/>
    };
}