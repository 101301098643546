import _ from "lodash";
import { AuditEventDefinition, AuditEventDefinitionContext } from "..";
import { useCommonAuditEventDetailsItems } from "./useCommonAuditEventDetailsItems";

export function useDefaultAuditEventDefinition(context: AuditEventDefinitionContext) {
    const commonAuditEventDetailsItems = useCommonAuditEventDetailsItems(context);
    return new AuditEventDefinition(
        _.filter([
            commonAuditEventDetailsItems.errorMessageItem
        ]));
}