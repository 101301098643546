﻿import { useMemo } from "react";
import { Contract } from "../../../../../../../../../../common";
import { DefinitionOptions } from "../../useDefinition";
import { useCiContainerImageDefinition } from "./useCiContainerImageDefinition";
import { useCiContainerImageRepositoryDefinition } from "./useCiContainerImageRepositoryDefinition";
import { useCiDefaultEntityDefinition } from "./useCiDefaultEntityDefinition";

export function useCiEntityDefinition(entityModel: Contract.EntityModel, options?: DefinitionOptions) {
    const useDefinition =
        useMemo(
            () => {
                switch (entityModel.entity.typeName) {
                    case Contract.TypeNames.CiContainerImage:
                        return useCiContainerImageDefinition;
                    case Contract.TypeNames.CiContainerImageRepository:
                        return useCiContainerImageRepositoryDefinition;
                    default:
                        return useCiDefaultEntityDefinition;
                }
            },
            []);

    return useDefinition(entityModel as Contract.CiEntityModel, options);
}