import { DataTableColumn, DataTableSortType, EnumValuesFilter, optionalTableCell, useLocalization } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { useGcpCommonEncryptedResourceDefinition, useGcpCommonScopeResourceDefinition } from ".";
import { ExportData } from "../../../../..";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, InlineEntities, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper } from "../../../../../../../../../../common";
import { useGcpArtifactRegistryTypeTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";

export function useGcpArtifactRegistryDefinition(definitionData: DefinitionData) {
    const commonEncryptedResourceDefinition = useGcpCommonEncryptedResourceDefinition(definitionData);
    const commonScopeResourceDefinition = useGcpCommonScopeResourceDefinition(definitionData);

    const artifactRegistryTypeTranslator = useGcpArtifactRegistryTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.gcp.useGcpArtifactRegistryDefinition",
            () => ({
                columns: {
                    repositories: "Repositories",
                    type: "Format"
                }
            }));

    return new EntityTableDefinition(
        _.filter([
            commonScopeResourceDefinition.columns.tenantColumn,
            commonScopeResourceDefinition.columns.creationTimeColumn,
            commonScopeResourceDefinition.columns.creatorIdentityCsvColumn,
            commonScopeResourceDefinition.columns.creatorOriginatorEntityCsvColumn,
            commonScopeResourceDefinition.columns.accessLevelColumn,
            commonScopeResourceDefinition.columns.updateTimeColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.GcpArtifactRegistryModel) => ({
                            [localization.columns.type()]: artifactRegistryTypeTranslator((item.entity as Contract.GcpArtifactRegistry).type)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.GcpArtifactRegistryType}
                                enumTypeTranslator={artifactRegistryTypeTranslator}
                                placeholder={localization.columns.type()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpArtifactRegistryType}
                itemProperty={(item: Contract.GcpArtifactRegistryModel) => artifactRegistryTypeTranslator((item.entity as Contract.GcpArtifactRegistry).type)}
                key={Contract.EntityModelProperty.GcpArtifactRegistryType}
                title={localization.columns.type()}/>,
            <DataTableColumn
                exportOptions={{
                    getData:
                        (item: Contract.GcpArtifactRegistryModel) => item.repositoryIds,
                    getItem:
                        (item: Contract.GcpArtifactRegistryModel, exportData: ExportData) => ({
                            [localization.columns.repositories()]:
                                _(item.repositoryIds).
                                    map(repositoryId => exportData.relatedEntityModelMap[repositoryId].entity.displayReference).
                                    join("\n")
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.GcpArtifactRegistryRepositories)}
                                placeholder={localization.columns.repositories()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpArtifactRegistryRepositories}
                key={Contract.EntityModelProperty.GcpArtifactRegistryRepositories}
                render={
                    optionalTableCell<Contract.GcpArtifactRegistryModel>(
                        item =>
                            _.isEmpty(item.repositoryIds)
                                ? undefined
                                : <InlineEntities
                                    entityIdsOrModels={item.repositoryIds!}
                                    entityTypeName={Contract.TypeNames.GcpEntity}
                                    entityVariant="iconText"
                                    variant="itemPlusItemCount"/>)}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.repositories()}/>,
            commonEncryptedResourceDefinition.columns.encryptionExistsColumn,
            commonEncryptedResourceDefinition.columns.encryptionKeyColumn,
            commonScopeResourceDefinition.columns.regionColumn,
            commonScopeResourceDefinition.columns.regionLocationColumn,
            commonScopeResourceDefinition.columns.tagsColumn,
            commonScopeResourceDefinition.columns.attributesColumn,
            commonScopeResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonScopeResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonScopeResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ]),
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageGcpArtifactRegistryRequest(
                new Contract.EntityControllerGetEntityModelPageGcpArtifactRegistryRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.GcpResourceUpdateTime]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpScopeResourceAccessLevel]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpScopeResourceRawId]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpArtifactRegistryRepositories]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpArtifactRegistryType])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags])),
                new Contract.EntityControllerGetEntityModelPageGcpScopeResourceRequestEncrypted(
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpEncryptedResourceEncryptionExists]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpEncryptedResourceKmsEncryptionKeys]))));
}