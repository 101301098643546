﻿import { Steps, useLocalization } from "@infrastructure";
import React from "react";
import { Contract } from "../../../../../../../../../../../../../../../../common";
import { ChangeStep } from "../../../../../../../../../../utilities";
import { EntityExternalConsoleLink } from "../../../../../../components";

export function useGetAzureManagedIdentitySystemManagedIdentitySqlManagedInstanceGenericResourceResolutionChangeSteps() {
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.compliance.useAzureManagedIdentityManagedIdentityInactiveRiskDefinition.hooks.useGetResolutionChangeSteps.hooks.useGetAzureManagedIdentitySystemManagedIdentitySqlManagedInstanceGenericResourceResolutionChangeSteps",
            () => ({
                resolution: {
                    step1: "In the resource search bar, search for **Identity**",
                    step2: "In the **Identity** page, set the Status of the system assigned managed identity to Off",
                    step3: "Click **Save** and confirm. This will automatically delete the managed identity and its role assignments"
                }
            }));

    return (managedIdentityModel: Contract.AzureManagedIdentityManagedIdentityModel, risk: Contract.AzureManagedIdentityManagedIdentityInactiveRisk) =>
        [
            new ChangeStep(
                risk.resolutionChanges![0] as Contract.AzureDeleteManagedIdentityChange,
                {
                    contentElement:
                        <Steps variant="plainNumbers">
                            <EntityExternalConsoleLink
                                entityId={(managedIdentityModel.entity as Contract.AzureManagedIdentitySystemManagedIdentity).scopeEntityReference.id!}
                                key={(managedIdentityModel.entity as Contract.AzureManagedIdentitySystemManagedIdentity).scopeEntityReference.id!}/>
                            {localization.resolution.step1()}
                            {localization.resolution.step2()}
                            {localization.resolution.step3()}
                        </Steps>
                })
        ];
}