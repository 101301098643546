﻿import { Dialog, makeContextProvider } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, scopeSystemEntityModelStore } from "../../../../../../../../../../../../common";
import { AddOrEdit, Table } from "./components";

export class CodePipelineContext {
    constructor(public addOrEditOpen: boolean | Contract.CodePipelineConfiguration) {
    }
}

export const [useCodePipelineContext, useSetCodePipelineContext, useCodePipelineContextProvider] = makeContextProvider<CodePipelineContext>();

type CodePipelineProps = {
    type: Contract.CodePipelineType;
};

export function CodePipeline({ type }: CodePipelineProps) {
    const [context, setContext, ContextProvider] = useCodePipelineContextProvider(() => new CodePipelineContext(false));

    const pipelineModels =
        _.map(
            scopeSystemEntityModelStore.useGetCodePipeline(type),
            scopeEntityModel => scopeEntityModel as Contract.CodePipelineModel);

    return (
        <ContextProvider>
            {context.addOrEditOpen !== false &&
                <Dialog
                    size="large"
                    variant="editor"
                    onClose={
                        () =>
                            setContext(
                                context => ({
                                    ...context,
                                    addOrEditOpen: false
                                }))}>
                    <AddOrEdit pipelineType={type}/>
                </Dialog>}
            <Table
                pipelineModels={pipelineModels}
                pipelineType={type}/>
        </ContextProvider>);
}