import { EmptyValueOptions, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import React, { memo } from "react";
import { Contract, useSeverityTranslator } from "..";

const SeverityFilterMemo = memo(SeverityFilter);
export { SeverityFilterMemo as SeverityFilter };

type SeverityFilterProps = {
    emptyValueOptions?: EmptyValueOptions;
    information?: boolean;
    placeholder: string;
};

function SeverityFilter({ emptyValueOptions = { enabled: true }, information = true, placeholder }: SeverityFilterProps) {
    const severityTranslator = useSeverityTranslator();

    return (
        <ValuesFilter
            emptyValueOptions={emptyValueOptions}
            placeholder={placeholder}
            sorted={false}>
            <ValuesFilterItem
                title={severityTranslator(Contract.Severity.Critical)}
                value={Contract.Severity.Critical}/>
            <ValuesFilterItem
                title={severityTranslator(Contract.Severity.High)}
                value={Contract.Severity.High}/>
            <ValuesFilterItem
                title={severityTranslator(Contract.Severity.Medium)}
                value={Contract.Severity.Medium}/>
            <ValuesFilterItem
                title={severityTranslator(Contract.Severity.Low)}
                value={Contract.Severity.Low}/>
            {information && (
                <ValuesFilterItem
                    title={severityTranslator(Contract.Severity.Information)}
                    value={Contract.Severity.Information}/>)}
        </ValuesFilter>);
}