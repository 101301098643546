import React from "react";
import { useLocalization } from "@infrastructure";
import { Contract, Entity, entityModelStore, RegionModelHelper, tenantModelStore } from "../../../../../../../../../../../../common";
import { OciConsoleUrlBuilder, useOciConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { useGetOciNetworkingSubnetsRiskContext, useGetOciNetworkingVcnRiskContext } from "../contexts";

export function useOciNetworkingVcnFlowLogsNotEnabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.OciNetworkingVcnFlowLogsNotEnabledRisk;
    const vcnModel = entityModelStore.useGet(risk.entityId) as Contract.OciNetworkingVcnModel;

    const getOciNetworkingSubnetsRiskContext = useGetOciNetworkingSubnetsRiskContext();
    const getOciNetworkingVcnRiskContext = useGetOciNetworkingVcnRiskContext();

    const tenantModel = tenantModelStore.useGet(vcnModel.entity.tenantId);
    const ociTenantModel = tenantModelStore.useGet((tenantModel.configuration as Contract.OciTenantConfiguration).ocid);
    const ociTenantConfiguration = ociTenantModel.configuration as Contract.OciTenantConfiguration;

    const consoleSignInStepTranslator = useOciConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.oci.hooks.compliance.useOciNetworkingVcnFlowLogsNotEnabledRiskDefinition",
            () => ({
                description: "{{vcn}} doesn’t have flow logs enabled",
                sections: {
                    resolution: {
                        step1: "Click **Enable flow logs**",
                        step2: "Fill in the basic information and click **Next**",
                        step3: "In the **Enablement points** step, click **Add enablement points**",
                        step4: "Select **Virtual cloud network** to capture flow logs for all subnets in a VCN and select the {{vcn}}",
                        step5: "Click **Next** and then **Enable flow logs**"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            vcn:
                <Entity
                    entityIdOrModel={vcnModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.OciConsoleView.NetworkCommandCenter,
                OciConsoleUrlBuilder.GetNetworkingNetworkCommandCenterFlowLogsUrl(
                    ociTenantConfiguration.realmId,
                    RegionModelHelper.get(vcnModel.entity.regionId!).systemName!)),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4({
                vcn:
                    <Entity
                        entityIdOrModel={vcnModel}
                        entityTypeNameTranslatorOptions={{ variant: "title" }}
                        variant="typeText"/>
            }),
            localization.sections.resolution.step5()
        ],
        riskModel,
        () => {
            const subnetsContextItems = getOciNetworkingSubnetsRiskContext(vcnModel.subnetIds);
            const vcnContextItems = getOciNetworkingVcnRiskContext(vcnModel);
            return [
                vcnContextItems.generalInformation,
                vcnContextItems.subnets,
                vcnContextItems.sensitive,
                subnetsContextItems.sensitive,
                vcnContextItems.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}