import { useLocalization } from "@infrastructure";
import { Grid2 } from "@mui/material";
import _, { Dictionary } from "lodash";
import React from "react";
import { SectionHeader, Summary } from ".";
import { Contract } from "../../../../../../../common";

type IdentityDistributionSectionProps = {
    identityPermissionActionSeverityToSummaryMap: Dictionary<Contract.ReportControllerGetDashboardReportResponseCloudProviderTenantIdentitySummary>;
};

export function IdentityDistributionSection({ identityPermissionActionSeverityToSummaryMap }: IdentityDistributionSectionProps) {
    const localization =
        useLocalization(
            "views.system.exportReport.dashboardReport.identityDistributionSection",
            () => ({
                identityDistribution: {
                    statsTitle: "Identities",
                    subtitle: "View a breakdown of identities by severity level to map identities and their associated permissions to potential risks in your environment.",
                    title: "Identity Intelligence"
                },
                subtitle: "Govern your privileged identities and effectively minimize the risk that they impose on your organization by revealing unused identities, and identities with excessive or risky privileges. Tenable Cloud Security provides you with details about risk factors associated with identities, such as use of access keys, lack of multi-factor authentication, and internet exposure. This information can help you detect identity-related risks such as identity theft, or the blast radius of a potential breach.",
                title: "IAM"
            }));

    return (
        <Grid2
            container={true}
            direction="column"
            spacing={3}>
            <Grid2>
                <SectionHeader
                    subtitle={localization.subtitle()}
                    title={localization.title()}
                    variant="main"/>
            </Grid2>
            <Grid2>
                <SectionHeader
                    subtitle={localization.identityDistribution.subtitle()}
                    title={localization.identityDistribution.title()}
                    variant="sub"/>
            </Grid2>
            <Grid2>
                <Summary
                    severityToCountMap={
                        _.mapValues(
                            identityPermissionActionSeverityToSummaryMap,
                            identityPermissionActionSummary => identityPermissionActionSummary.count)}
                    title={localization.identityDistribution.statsTitle()}/>
            </Grid2>
        </Grid2>);
}