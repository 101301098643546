﻿import { FormLayout, makeContextProvider, OrderedWizard, OrderedWizardItem, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract } from "../../../../../../../../../../../../../../common";
import { useJfrogIntegrationContext, useSetJfrogIntegrationContext } from "../../JfrogIntegration";
import { ConfigureIntegrationItem, FinishItem } from "./components";


export class AddOrEditContext {
    public integrationEndpoint?: string;
    public integrationEndpointConnectorId?: string;
    public integrationFolderEnabled?: boolean;
    public integrationName: string;
    public integrationPassword?: string;
    public integrationServerCertificateValidationEnabled: boolean;
    public integrationUserName: string;
    public updatedFolderScopeNodeModel?: Contract.ScopeNodeModel;

    constructor(
        public integrationModel?: Contract.CiIntegrationModel) {
        this.integrationEndpoint = integrationModel?.configuration?.endpoint;
        this.integrationEndpointConnectorId = integrationModel?.configuration?.endpointConnectorId;
        this.integrationFolderEnabled = integrationModel?.configuration?.folderEnabled;
        this.integrationName = integrationModel?.configuration?.name ?? "";
        this.integrationPassword =
            _.isNil(integrationModel?.configuration)
                ? ""
                : undefined;
        this.integrationServerCertificateValidationEnabled = integrationModel?.configuration?.serverCertificateValidationEnabled ?? true;
        this.integrationUserName = integrationModel?.configuration?.userName ?? "";
    }
}

export const [useAddOrEditContext, useSetAddOrEditContext, useAddOrEditContextProvider] = makeContextProvider<AddOrEditContext>();

export function AddOrEdit() {
    const { addOrEditOpen } = useJfrogIntegrationContext();
    const setJfrogIntegrationContext = useSetJfrogIntegrationContext();

    const [, , ContextProvider] =
        useAddOrEditContextProvider(
            () =>
                new AddOrEditContext(
                    _.isBoolean(addOrEditOpen)
                        ? undefined
                        : addOrEditOpen));

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCiIntegrationItems.jfrogIntegration.addOrEdit",
            () => ({
                steps: {
                    configureIntegration: "Add JFrog Organization",
                    integration: "Configure Authentication Details"
                },
                title: {
                    add: "Add JFrog Organization",
                    edit: "Edit JFrog Organization"
                }
            }));

    return (
        <ContextProvider>
            <FormLayout
                disableContentPadding={true}
                titleOptions={{
                    text:
                        _.isBoolean(addOrEditOpen)
                            ? localization.title.add()
                            : localization.title.edit()
                }}>
                <OrderedWizard
                    finishItemElement={<FinishItem/>}
                    onClose={
                        () =>
                            setJfrogIntegrationContext(
                                context => ({
                                    ...context,
                                    addOrEditOpen: false
                                }))}>
                    <OrderedWizardItem title={localization.steps.integration()}>
                        <ConfigureIntegrationItem/>
                    </OrderedWizardItem>
                </OrderedWizard>
            </FormLayout>
        </ContextProvider>);
}