import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function VirtualMachineImagesIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <path
            clipRule="evenodd"
            d="M5.06452 8.08696V16.913H16.9355V8.08696H5.06452ZM3 8.03618C3 6.897 3.92301 6 5.02905 6H16.9709C18.077 6 19 6.897 19 8.03618V16.9638C19 18.103 18.077 19 16.971 19H5.02905C3.92301 19 3 18.103 3 16.9638V8.03618Z"
            fillRule="evenodd"/>
        <path
            clipRule="evenodd"
            d="M6.5 3C6.5 1.89543 7.39543 1 8.5 1H21.5C22.6046 1 23.5 1.89543 23.5 3V12C23.5 13.1046 22.6046 14 21.5 14H19V12H21.5V3H8.5V8H6.5V3Z"
            fillRule="evenodd"/>
        <path
            clipRule="evenodd"
            d="M1 22C1 21.4477 1.44772 21 2 21H19.5C20.0523 21 20.5 21.4477 20.5 22C20.5 22.5523 20.0523 23 19.5 23H2C1.44772 23 1 22.5523 1 22Z"
            fillRule="evenodd"/>
    </SvgIcon>;
}