import { NoneIcon } from "@infrastructure";
import { Stack } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { UdmObjectPropertyItemProps } from "../..";
import { useGetEntityAttributesItemDefinition } from "../../../../../hooks";
import { customEntityAttributeDefinitionModelStore } from "../../../../../stores";
import { EntityAttributes } from "../../../../EntityAttributes";
import { Container } from "../../../../EntityAttributes/components/EntityAttribute/components";

export function CommonEntityAttributes({ filter, item, objectTypeName }: UdmObjectPropertyItemProps) {
    const attributes =
        filter
            ? item
            : item?.attributes;
    return _.isEmpty(attributes)
        ? <NoneIcon sx={{ fontSize: "18px" }}/>
        : filter
            ? <Filter item={attributes}/>
            : <EntityAttributes
                entityAttributes={attributes}
                entityTypeName={objectTypeName}
                orderVariant="leftToRight"
                variant="dynamic"/>;
}

type FilterProps = {
    item: any;
};

export function Filter({ item }: FilterProps) {
    const getEntityAttributesItemDefinition = useGetEntityAttributesItemDefinition();
    const customEntityAttributeDefinitionModels = customEntityAttributeDefinitionModelStore.useGetAll();
    const customEntityAttributeDefinitionModelMap =
            useMemo(
                () =>
                    _.keyBy(
                        customEntityAttributeDefinitionModels,
                        customEntityAttributeDefinitionModel => customEntityAttributeDefinitionModel.configuration.id),
                [customEntityAttributeDefinitionModels]);
    const attributes =
        useMemo(
            () => {
                const entityAttributesIds =
                    !_.isEmpty(item) &&
                    typeof item[0] === "object"
                        ? _.map(
                            (item as { definitionId?: string; typeName: string }[]),
                            entityAttribute => entityAttribute.definitionId ?? entityAttribute.typeName)
                        : [item] as string[];

                const { builtin: builtInEntityAttributesIds, custom: customEntityAttributesIds } =
                    _.groupBy(
                        entityAttributesIds,
                        entityAttributeId => (
                            customEntityAttributeDefinitionModelMap[entityAttributeId]
                                ? "custom"
                                : "builtin"));

                return getEntityAttributesItemDefinition(builtInEntityAttributesIds, customEntityAttributesIds);
            },
            [item]);

    return (
        <Stack
            direction="row"
            gap={1}>
            {_.map(
                attributes,
                attribute =>
                    <Container
                        color={attribute.color}
                        key={attribute.name}
                        name={attribute.name}/>)}
        </Stack>);
}