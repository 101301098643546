import { useLocalization } from "@infrastructure";
import _ from "lodash";
import { useGetPermissionEligibilityUpdateAuditEventCommonChanges } from ".";
import { Contract } from "../../..";

export function useGetOneLoginDirectoryRoleAssignmentEligibilityUpdateAuditEventChanges() {
    const getPermissionEligibilityUpdateAuditEventCommonChanges = useGetPermissionEligibilityUpdateAuditEventCommonChanges();
    const localization =
        useLocalization(
            "common.hooks.usePermissionEligibilityUpdateAuditEventChangesTranslator.hooks.useGetOneLoginDirectoryRoleAssignmentEligibilityUpdateAuditEventChanges",
            () => ({
                roles: "Roles"
            }));
    return (permissionEligibilityUpdateAuditEventPermissionEligibilityChanges: Contract.PermissionEligibilityUpdateAuditEventOneLoginDirectoryRoleAssignmentEligibilityChanges) => {
        const permissionAssignmentEligibilityUpdateAuditEventCommonChanges = getPermissionEligibilityUpdateAuditEventCommonChanges(permissionEligibilityUpdateAuditEventPermissionEligibilityChanges);
        return _.filter([
            permissionAssignmentEligibilityUpdateAuditEventCommonChanges.name,
            permissionAssignmentEligibilityUpdateAuditEventCommonChanges.principalTenantId,
            permissionAssignmentEligibilityUpdateAuditEventCommonChanges.granteePrincipals,
            permissionEligibilityUpdateAuditEventPermissionEligibilityChanges.rolesChanged
                ? localization.roles()
                : undefined!,
            permissionAssignmentEligibilityUpdateAuditEventCommonChanges.expirationTimeFrame,
            permissionAssignmentEligibilityUpdateAuditEventCommonChanges.approval,
            permissionAssignmentEligibilityUpdateAuditEventCommonChanges.reasonRequired
        ]);
    };
}