import { Action0, DiffDialog, ItemSelector, useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import { Contract, entityModelStore, useTheme } from "../../../../../../../../../../../../../../../../common";

type RolesDiffProps = {
    existingRoleIds: string[];
    newRoleIds: string[];
    onClose: Action0;
};

export function RolesDiffDialog({ existingRoleIds, newRoleIds, onClose }: RolesDiffProps) {
    const [selectedExistingRoleId, setSelectedExistingRoleId] = useState(existingRoleIds[0]);
    const [selectedNewRoleId, setSelectedNewRoleId] = useState(newRoleIds[0]);
    const roleModels = entityModelStore.useGet(_.concat(existingRoleIds, newRoleIds));
    const roleMap =
        useMemo(
            () =>
                _(roleModels).
                    map(roleModel => roleModel.entity as Contract.GcpIamRole).
                    keyBy(role => role.id).
                    value(),
            [roleModels]);

    const [selectedNewRoleFileName, selectedNewRoleFileUrl] =
        useMemo(
            () => {
                const newRoleFileName = `${roleMap[selectedNewRoleId].displayName}.json`;
                return [
                    newRoleFileName,
                    window.URL.createObjectURL(
                        new Blob(
                            [roleMap[selectedNewRoleId].rawRole],
                            { type: "application/json" }))
                ];
            },
            [selectedNewRoleId]);
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.access.useGcpExcessivePermissionPrincipalRiskDefinition.replaceRoleBindingsChangeDiff.rolesDiffDialog",
            () => ({
                title: "Policy difference"
            }));

    const theme = useTheme();
    return (
        <DiffDialog
            downloadOptions={{
                data: selectedNewRoleFileUrl,
                fileName: selectedNewRoleFileName
            }}
            format="json"
            modified={roleMap[selectedNewRoleId].rawRole}
            modifiedTitle={
                newRoleIds.length === 1
                    ? <Typography
                        noWrap={true}
                        variant="h4">
                        {roleMap[newRoleIds[0]].displayName}
                    </Typography>
                    : <ItemSelector
                        dense={true}
                        fieldSx={{ border: "none" }}
                        fullWidth={true}
                        items={newRoleIds}
                        selectedItem={selectedNewRoleId}
                        sorted={false}
                        onSelectedItemChanged={setSelectedNewRoleId}>
                        {newRoleId =>
                            <Typography
                                noWrap={true}
                                variant="h4">
                                {roleMap[newRoleId].displayName}
                            </Typography>}
                    </ItemSelector>}
            original={roleMap[selectedExistingRoleId].rawRole}
            originalTitle={
                existingRoleIds.length === 1
                    ? <Typography
                        noWrap={true}
                        sx={{ padding: theme.spacing(0.5, 1.5) }}
                        variant="h4">
                        {roleMap[existingRoleIds[0]].displayName}
                    </Typography>
                    : <ItemSelector
                        dense={true}
                        fieldSx={{ border: "none" }}
                        fullWidth={true}
                        items={existingRoleIds}
                        selectedItem={selectedExistingRoleId}
                        sorted={false}
                        onSelectedItemChanged={setSelectedExistingRoleId}>
                        {existingRoleId =>
                            <Typography
                                noWrap={true}
                                variant="h4">
                                {roleMap[existingRoleId].displayName}
                            </Typography>}
                    </ItemSelector>}
            title={localization.title()}
            onClose={onClose}/>);
}