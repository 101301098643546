import { InlineItems, InlineItemsVariant, useLocalization } from "@infrastructure";
import React from "react";
import { Contract } from "../../../common";


type InlineAzureKeyVaultVaultKeyPermissionsProps = {
    permissions: Contract.AzureKeyVaultVaultKeyPermission[];
    variant: InlineItemsVariant;
};

export function InlineAzureKeyVaultVaultKeyPermissions({ permissions, variant }: InlineAzureKeyVaultVaultKeyPermissionsProps) {
    const localization =
        useLocalization(
            "tenants.azure.inlineAzureKeyVaultVaultKeyPermissions",
            () => ({
                permissions: [
                    "1 key permission",
                    "{{count | NumberFormatter.humanize}} key permissions"
                ],
                [Contract.TypeNames.AzureKeyVaultVaultKeyPermission]: {
                    [Contract.AzureKeyVaultVaultKeyPermission.All]: "All",
                    [Contract.AzureKeyVaultVaultKeyPermission.Backup]: "Backup",
                    [Contract.AzureKeyVaultVaultKeyPermission.Create]: "Create",
                    [Contract.AzureKeyVaultVaultKeyPermission.Decrypt]: "Decrypt",
                    [Contract.AzureKeyVaultVaultKeyPermission.Delete]: "Delete",
                    [Contract.AzureKeyVaultVaultKeyPermission.Encrypt]: "Encrypt",
                    [Contract.AzureKeyVaultVaultKeyPermission.Get]: "Get",
                    [Contract.AzureKeyVaultVaultKeyPermission.GetRotationPolicy]: "Get Rotation Policy",
                    [Contract.AzureKeyVaultVaultKeyPermission.Import]: "Import",
                    [Contract.AzureKeyVaultVaultKeyPermission.List]: "List",
                    [Contract.AzureKeyVaultVaultKeyPermission.Purge]: "Purge",
                    [Contract.AzureKeyVaultVaultKeyPermission.Recover]: "Recover",
                    [Contract.AzureKeyVaultVaultKeyPermission.Release]: "Release",
                    [Contract.AzureKeyVaultVaultKeyPermission.Restore]: "Restore",
                    [Contract.AzureKeyVaultVaultKeyPermission.Rotate]: "Rotate",
                    [Contract.AzureKeyVaultVaultKeyPermission.SetRotationPolicy]: "Set Rotation Policy",
                    [Contract.AzureKeyVaultVaultKeyPermission.Sign]: "Sign",
                    [Contract.AzureKeyVaultVaultKeyPermission.UnwrapKey]: "Unwrap Key",
                    [Contract.AzureKeyVaultVaultKeyPermission.Update]: "Update",
                    [Contract.AzureKeyVaultVaultKeyPermission.Verify]: "Verify",
                    [Contract.AzureKeyVaultVaultKeyPermission.WrapKey]: "Wrap Key"
                }
            }));
    return (
        <InlineItems
            items={permissions}
            namePluralizer={localization.permissions}
            variant={variant}>
            {(permission: Contract.AzureKeyVaultVaultKeyPermission) => localization[Contract.TypeNames.AzureKeyVaultVaultKeyPermission][permission]()}
        </InlineItems>);
}