﻿import { Steps, useLocalization } from "@infrastructure";
import React from "react";
import { Contract } from "../../../../../../../../../../../../../../../../common";
import { ChangeStep } from "../../../../../../../../../../utilities";
import { EntityExternalConsoleLink } from "../../../../../../components";

export function useGetAzureManagedIdentityUserManagedIdentityResolutionChangeSteps() {
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.compliance.useAzureManagedIdentityManagedIdentityInactiveRiskDefinition.hooks.useGetResolutionChangeSteps.hooks.useGetAzureManagedIdentityUserManagedIdentityResolutionChangeSteps",
            () => ({
                resolution: {
                    step1: "Click **Delete** and confirm the deletion"
                }
            }));

    return (risk: Contract.AzureManagedIdentityManagedIdentityInactiveRisk) =>
        [
            new ChangeStep(
                risk.resolutionChanges![0] as Contract.AzureDeleteManagedIdentityChange,
                {
                    contentElement:
                        <Steps variant="plainNumbers">
                            <EntityExternalConsoleLink
                                entityId={risk.entityId}
                                key={risk.entityId}/>
                            {localization.resolution.step1()}
                        </Steps>
                })
        ];
}