import { useTheme } from "@mui/material";
import _ from "lodash";
import React from "react";
import { DirectedGraphLayoutEdge } from "../utilities";

type EdgeProps = {
    layoutEdge: DirectedGraphLayoutEdge;
};

export function Edge({ layoutEdge }: EdgeProps) {
    const edgeId = `${layoutEdge.modelEdge.sourceNodeId}-${layoutEdge.modelEdge.destinationNodeId}`;
    const theme = useTheme();
    const color =
        layoutEdge.modelEdge.options.color ??
        (layoutEdge.modelEdge.options.appearance === "highlighted"
            ? theme.palette.text.primary
            : theme.palette.text.secondary);
    return (
        <g>
            <defs>
                <marker
                    fill="none"
                    id={`arrow-${edgeId}`}
                    markerHeight="8"
                    markerWidth="5"
                    orient="auto"
                    refY="4"
                    stroke={color}>
                    <path d="M0,4 L4,4"/>
                    <path d="M0,0 L4,4 0,8"/>
                </marker>
                {!_.isNil(layoutEdge.modelEdge.options.title) &&
                    <marker
                        fill={
                            layoutEdge.modelEdge.options.appearance === "highlighted"
                                ? theme.palette.text.primary
                                : theme.palette.text.secondary}
                        id={`arrowCaption-${edgeId}`}
                        orient="auto"
                        overflow="visible"
                        refY="4">
                        <text style={{ fontSize: "6px" }}>
                            <tspan textAnchor="middle">{layoutEdge.modelEdge.options.title}</tspan>
                        </text>
                    </marker>}
            </defs>
            <path
                d={layoutEdge.svgPath}
                fill="none"
                markerEnd={`url(#arrow-${edgeId})`}
                markerMid={`url(#arrowCaption-${edgeId})`}
                stroke={color}
                {...getEdgeAppearanceProps(layoutEdge.modelEdge.options.appearance)}/>
        </g>);
}

export function getEdgeAppearanceProps(appearance: "highlighted" | "normal" = "normal") {
    if (appearance === "highlighted") {
        return {
            strokeWidth: 1.5
        };
    } else {
        return {
            opacity: 0.7,
            strokeDasharray: 4,
            strokeWidth: 1
        };
    }
}