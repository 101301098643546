import { Loading, NavigationViewTree, NavigationViewTreeProps, useUniqueKey } from "@infrastructure";
import { Box, Stack, useTheme } from "@mui/material";
import React, { ReactNode } from "react";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";

type NavigationViewProps = {
    children: ReactNode;
} & Omit<NavigationViewTreeProps, "onNavigate">;

export class NavigationViewDivider {
}

export class NavigationViewItem {
    constructor(
        public id: string,
        public searchText: string,
        public title: ReactNode,
        public options?: NavigationViewItemOptions) {
    }
}

type NavigationViewItemOptions = {
    disabled?: boolean;
    initialExpanded?: boolean;
    items?: NavigationViewItem[];
    path?: string;
    tooltip?: ReactNode;
};

export function NavigationView({ children, ...props }: NavigationViewProps) {
    const [viewKey, updateViewKey] = useUniqueKey();

    const theme = useTheme();
    return (
        <Box
            sx={{
                ".panel" : {
                    height: "100%",
                    willChange: "flex",
                    zIndex: 1
                },
                ".panel-group": { isolation: "isolate" },
                ".panel-resize-handle": {
                    "&[data-resize-handle-state=hover], &[data-resize-handle-state=drag]": {
                        borderColor: theme.palette.background.alternate,
                        boxShadow: `0 0 0 1px ${theme.palette.background.alternate}`
                    },
                    borderLeft: theme.border.pageFrame,
                    position: "relative",
                    transition: "all 150ms ease-out",
                    transitionProperty: "border-color, box-shadow",
                    width: 0,
                    zIndex: "2"
                },
                height: "100%",
                position: "relative"
            }}>
            <PanelGroup
                className="panel-group"
                direction="horizontal">
                <Panel
                    className="panel"
                    defaultSize={15}
                    maxSize={50}
                    minSize={8}>
                    <NavigationViewTree
                        {...props}
                        onNavigate={() => updateViewKey()}/>
                </Panel>
                <PanelResizeHandle
                    className="panel-resize-handle"
                    hitAreaMargins={{
                        coarse: 20,
                        fine: 7
                    }}/>
                <Panel
                    className="panel"
                    defaultSize={85}>
                    <Stack
                        key={viewKey}
                        sx={{ height: "100%" }}>
                        <Loading>
                            {children}
                        </Loading>
                    </Stack>
                </Panel>
            </PanelGroup>
        </Box>);
}