import { useLocalization } from "@infrastructure";
import { Contract } from "../../../common";

export function useOciTenantManagerErrorTranslator() {
    const localization =
        useLocalization(
            "tenants.oci.hooks.useOciTenantManagerErrorTranslator",
            () => ({
                [Contract.TypeNames.OciTenantManagerError]: {
                    [Contract.OciTenantManagerError.CommonErrorMoveScopeDependencyExists]: "Target folder does not have the same configured integrations as the source folder.",
                    [Contract.OciTenantManagerError.CommonErrorParentFolderNotExist]: "Parent folder does not exist",
                    [Contract.OciTenantManagerError.CommonErrorTenantNotExist]: "Compartment does not exist",
                    [Contract.OciTenantManagerError.CommonErrorUserDomainNotFound]: "User identity domain was not found",
                    [Contract.OciTenantManagerError.CommonErrorUserNotAuthenticated]: "Failed to connect",
                    [Contract.OciTenantManagerError.CommonErrorUserNotAuthorized]: "User is not authorized",
                    [Contract.OciTenantManagerError.CommonErrorUserPrivateKeyNotValid]: "User credentials are not valid",
                    [Contract.OciTenantManagerError.InsertOrganizationErrorOrganizationExists]: "Tenancy already exists",
                    [Contract.OciTenantManagerError.InsertOrganizationErrorHomeRegionSystemNameMismatch]: "Home region is incorrect",
                    [Contract.OciTenantManagerError.InsertOrganizationErrorHomeRegionSystemNameNotValid]: "Home region is not valid"
                }
            }));
    return (error: Contract.OciTenantManagerError) => localization[Contract.TypeNames.OciTenantManagerError][error]();
}