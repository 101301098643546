import { DataTableColumn, DataTableColumnRenderProps, InlineItems, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { Fragment } from "react";
import { useDetailSectionTableFetchItems } from "../../..";
import { Contract, EntitiesCell, Entity, InlineEntities, NetworkScopeFormatter, Region } from "../../../../../../../../../../../../common";
import { RiskDetailsSectionItem } from "../../../../../../utilities";
import { EntityExclusionActionCell, EntityExclusionMessage, Table } from "../../../../components";
import { useCommonCustomSectionsAndDescriptionDefinition } from "../../../useCommonCustomSectionsAndDescriptionDefinition";

export function useAwsEc2SecurityGroupInboundRuleRiskDefinition(riskModel: Contract.RiskModel) {
    const securityGroupInboundRuleRiskModel = riskModel as Contract.AwsEc2SecurityGroupInboundRuleRiskModel;
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.custom.useAwsEc2SecurityGroupInboundRuleRiskDefinition",
            () => ({
                ports: [
                    "1 port range",
                    "{{count | NumberFormatter.humanize}} port ranges"
                ],
                subnets: [
                    "1 IP address range",
                    "{{count | NumberFormatter.humanize}} IP address ranges"
                ],
                violations: {
                    allowed: "{{securityGroups}} is missing required inbound rule",
                    notAllowed: "{{securityGroups}} allow inbound internet connection from {{subnets}} to {{networkScopes}}"
                }
            }));
    const createCommonInfoProps =
        () => ({
            securityGroups:
                <InlineEntities
                    entityIdsOrModels={securityGroupInboundRuleRiskModel.securityGroupIds}
                    entityTypeName={Contract.TypeNames.AwsEc2SecurityGroup}
                    variant="itemAndTypeOrItemCountAndType"/>
        });
    return useCommonCustomSectionsAndDescriptionDefinition(
        riskModel.risk.typeName === Contract.TypeNames.AwsEc2SecurityGroupAllowedInboundRuleRisk
            ? localization.violations.allowed(createCommonInfoProps())
            : localization.violations.notAllowed({
                ...createCommonInfoProps(),
                networkScopes:
                    <InlineItems
                        items={
                            _.map(
                                securityGroupInboundRuleRiskModel.networkScopes,
                                networkScope => NetworkScopeFormatter.networkScopeFromDestinationNetworkScope(networkScope))}
                        namePluralizer={localization.ports}
                        variant="itemOrItemCountAndType"/>,
                subnets:
                    <InlineItems
                        items={securityGroupInboundRuleRiskModel.subnets}
                        namePluralizer={localization.subnets}
                        variant="itemOrItemCountAndType"/>
            }),
        riskModel,
        "violations",
        <ViolationTable risk={riskModel.risk}/>);
}

type ViolationTableProps = {
    risk: Contract.AwsEc2SecurityGroupInboundRuleRisk;
};

function ViolationTable({ risk }: ViolationTableProps) {
    const detailSectionTableFetchItems =
        useDetailSectionTableFetchItems(
            risk.items,
            {
                createItem:
                    (entityModelMap, riskItem) => {
                        const entityModel = entityModelMap[riskItem.entityId];
                        const networkedResourceModels =
                            _((entityModel as Contract.AwsEc2SecurityGroupModel).networkedResourceTypeNameToIdsMap).
                                values().
                                flatMap().
                                map(networkedResourceId => entityModelMap[networkedResourceId]).
                                value();
                        const vpcModel =
                            !_.isNil((entityModel.entity as Contract.AwsEc2SecurityGroup).vpcId)
                                ? entityModelMap[(entityModel.entity as Contract.AwsEc2SecurityGroup).vpcId!] as Contract.AwsEc2VpcModel
                                : undefined;
                        return new DetailsSectionItem(
                            riskItem as Contract.AwsEc2SecurityGroupInboundRuleRiskItem,
                            networkedResourceModels,
                            entityModel,
                            vpcModel);
                    },
                getRelatedEntityIds:
                    entityModel =>
                        _((entityModel as Contract.AwsEc2SecurityGroupModel).networkedResourceTypeNameToIdsMap).
                            values().
                            flatMap().
                            concatIf(
                                !_.isNil((entityModel.entity as Contract.AwsEc2SecurityGroup).vpcId),
                                [(entityModel.entity as Contract.AwsEc2SecurityGroup).vpcId!]).
                            value()
            });

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.custom.useAwsEc2SecurityGroupInboundRuleRiskDefinition.violationTable",
            () => ({
                columns: {
                    networkedResources: "Attached Resources",
                    networkScopes: "Exposed Port",
                    regionSystemName: "Region",
                    securityGroup: "Security Group",
                    subnets: "Sources",
                    vpc: "VPC"
                }
            }));
    return (
        <Fragment>
            <EntityExclusionMessage/>
            <Table
                fetchItems={detailSectionTableFetchItems}
                getItemId={(item: DetailsSectionItem) => item.entityModel.id}>
                <DataTableColumn
                    id={DetailsSectionColumnId.SecurityGroup}
                    render={
                        ({ item }: DataTableColumnRenderProps<DetailsSectionItem>) =>
                            <Entity
                                entityIdOrModel={item.entityModel}
                                variant="iconTextTenant"/>}
                    title={localization.columns.securityGroup()}/>
                <DataTableColumn
                    id={DetailsSectionColumnId.NetworkScopes}
                    render={
                        ({ item }: DataTableColumnRenderProps<DetailsSectionItem>) =>
                            <InlineItems
                                items={
                                    _.map(
                                        (item.riskItem as Contract.AwsEc2SecurityGroupInboundRuleRiskItem).networkScopes,
                                        networkScope => NetworkScopeFormatter.networkScopeFromDestinationNetworkScope(networkScope))}
                                variant="itemPlusItemCount"/>}
                    title={localization.columns.networkScopes()}/>
                <DataTableColumn
                    id={DetailsSectionColumnId.Subnets}
                    render={
                        ({ item }: DataTableColumnRenderProps<DetailsSectionItem>) =>
                            <InlineItems
                                items={(item.riskItem as Contract.AwsEc2SecurityGroupInboundRuleRiskItem).subnets}
                                variant="itemPlusItemCount"/>}
                    title={localization.columns.subnets()}/>
                <DataTableColumn
                    id={DetailsSectionColumnId.NetworkedResources}
                    render={
                        ({ item }: DataTableColumnRenderProps<DetailsSectionItem>) =>
                            <EntitiesCell
                                entityIdsOrModels={item.networkedResourceModels}
                                entityTypeName={Contract.TypeNames.IAwsNetworkedResource}/>}
                    title={localization.columns.networkedResources()}/>
                <DataTableColumn
                    id={DetailsSectionColumnId.Vpc}
                    render={
                        ({ item }: DataTableColumnRenderProps<DetailsSectionItem>) =>
                            <EntitiesCell
                                entityIdsOrModels={item.vpcModel}
                                entityTypeName={Contract.TypeNames.AwsEc2Vpc}
                                entityVariant="iconTextTypeTenantTags"/>}
                    title={localization.columns.vpc()}/>
                <DataTableColumn
                    id={DetailsSectionColumnId.RegionSystemName}
                    render={
                        ({ item }: DataTableColumnRenderProps<DetailsSectionItem>) =>
                            <Region regionId={item.entityModel.entity.regionId}/>}
                    title={localization.columns.regionSystemName()}/>
                <DataTableColumn
                    id={DetailsSectionColumnId.Actions}
                    render={EntityExclusionActionCell}/>
            </Table>
        </Fragment>);
}

class DetailsSectionItem extends RiskDetailsSectionItem {
    constructor(
        inboundRuleRiskItem: Contract.AwsEc2SecurityGroupInboundRuleRiskItem,
        public networkedResourceModels: Contract.EntityModel[],
        securityGroupModel: Contract.EntityModel,
        public vpcModel?: Contract.AwsEc2VpcModel) {
        super(securityGroupModel, inboundRuleRiskItem);
    }
}

enum DetailsSectionColumnId {
    Actions = "actions",
    NetworkedResources = "networkedResources",
    NetworkScopes = "networkScopes",
    RegionSystemName = "regionSystemName",
    SecurityGroup = "securityGroup",
    Subnets = "subnets",
    Vpc = "vpc"
}