import { Link, useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, CustomerConsoleAppUrlHelper, ScopeHelper, scopeNodeModelStore, tenantModelStore, useRiskPolicyTitleTranslator, useTheme } from "../../../../../../../../../../../common";
import { useItemContext } from "../../../../../Profile";

export function TopPolicies() {
    const { riskPoliciesDatas, scopeId } = useItemContext();
    const riskPolicyTitleTranslator = useRiskPolicyTitleTranslator();

    const scopeNodeModel = scopeNodeModelStore.useGet(scopeId);

    const filteredActiveTenantModels = tenantModelStore.useGetFilteredActiveTenants();
    const tenantIds =
        useMemo(
            () => {
                if(ScopeHelper.isFolder(scopeNodeModel) || ScopeHelper.customerId === scopeNodeModel.configuration.id) {
                    const scopeTenantType = ScopeHelper.getTenantType(scopeNodeModel);
                    if (_.isNil(scopeTenantType)) {
                        return undefined;
                    }

                    return _(filteredActiveTenantModels).
                        filter(filteredActiveTenantModel => filteredActiveTenantModel.tenantType === scopeTenantType).
                        map(filteredActiveTenantModel => filteredActiveTenantModel.configuration.id).
                        value();
                } else {
                    return [scopeId];
                }
            },
            [filteredActiveTenantModels, scopeNodeModel]);

    const localization =
        useLocalization(
            "views.customer.compliance.profile.item.summary.topPolicies",
            () => ({
                riskPolicy: "{{ count | NumberFormatter.humanize }} findings"
            }));

    const theme = useTheme();
    return (
        <Stack
            sx={{
                overflow:"auto",
                paddingRight: theme.spacing(1)
            }}>
            {_.map(
                riskPoliciesDatas,
                policy =>
                    <Link
                        key={policy.riskPolicyIdentifier}
                        sx={{
                            marginRight: theme.spacing(1),
                            textDecoration: "none"
                        }}
                        urlOrGetUrl={
                            () =>
                                CustomerConsoleAppUrlHelper.getRisksRelativeUrl(
                                    Contract.RisksView.Open,
                                    {
                                        policyConfigurationTypeNameOrIds: [policy.riskPolicyIdentifier],
                                        tenantIds
                                    })}>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            spacing={1}
                            sx={{
                                ":hover" : {
                                    backgroundColor: theme.palette.action.hover
                                },
                                borderRadius: theme.spacing(0.75),
                                padding: theme.spacing(1)
                            }}>
                            <Typography noWrap={true}>
                                {riskPolicyTitleTranslator(policy.riskPolicyIdentifier)}
                            </Typography>
                            <Typography
                                noWrap={true}
                                sx={{
                                    color: theme.palette.text.secondary,
                                    overflow: "visible"
                                }}>
                                {localization.riskPolicy({ count: policy.riskCount })}
                            </Typography>
                        </Stack>
                    </Link>)}
        </Stack>);
}