import _ from "lodash";
import { useEffect, useState } from "react";
import { ApiError, InlineItems, Optional, useExecuteOperation, useLocalization, useOrderedWizardContext } from "@infrastructure";
import { ConfigurationController, Contract } from "../../../../../../../../../../../../../../../common";
import { useGcpTenantManagerErrorTranslator } from "../../../../../../../../../../../../../../../tenants";
import { OrganizationMemberSelection } from "../../../../../../../components";
import { OrganizationMemberSelectionHelper } from "../../../../../../../utilities";
import { useAddOrEditContext, useSetAddOrEditContext } from "../AddOrEdit";

export function OrganizationMemberSelectionItem() {
    const addOrEditContext = useAddOrEditContext();
    const setAddOrEditContext = useSetAddOrEditContext();

    const { setError, setLoaded, setValid, useNextEffect } = useOrderedWizardContext();

    const [scopeNodeRootTreeItem] =
        useExecuteOperation(
            OrganizationMemberSelectionItem,
            async () => {
                const { node } = await ConfigurationController.getOrganizationMemberDatas(new Contract.ConfigurationControllerGetGcpOrganizationMemberDatasRequest(addOrEditContext.gciTenantModel!.configuration.id));
                return OrganizationMemberSelectionHelper.createTreeItem(node);
            });

    const [selectionType, setSelectionType] = useState(addOrEditContext.memberSelection?.type);
    const [selectedTenantIds, setSelectedTenantIds] =
        useState(
            () =>
                OrganizationMemberSelectionHelper.getSelectedTenantIds(
                    scopeNodeRootTreeItem.value,
                    addOrEditContext.memberSelection?.rawIds));

    useEffect(setLoaded, []);

    const tenantManagerErrorTranslator = useGcpTenantManagerErrorTranslator();

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCloudProviderTenantOrganizationItems.gcp.addOrEdit.organizationMemberSelectionItem",
            () => ({
                actions: {
                    next: {
                        error: {
                            add: "Failed to add organization",
                            edit: "Failed to update organization"
                        },
                        notValid: {
                            serviceAccountMissingOrganizationRoleNames: {
                                roleNames: [
                                    "1 mandatory role",
                                    "{{count | NumberFormatter.humanize}} mandatory roles"
                                ],
                                text: "Service account is missing {{serviceAccountMissingOrganizationRoleNames}} at the organization level"
                            },
                            serviceAccountUnauthorizedErrorMessage: "Service account is not authorized. Error: {{serviceAccountUnauthorizedErrorMessage}}"
                        }
                    }
                },
                errors: {
                    empty: "Empty selection is not allowed",
                    excludeAll: "Excluding all {{name}} is not allowed"
                }
            }));

    useNextEffect(
        async () => {
            if (!_.isNil(selectionType) && _.isEmpty(selectedTenantIds)) {
                return localization.errors.empty();
            }

            const newMemberSelection: Optional<Contract.OrganizationMemberSelection> =
                _.isNil(selectionType)
                    ? undefined
                    : {
                        rawIds:
                            OrganizationMemberSelectionHelper.getSelectedScopeIds(
                                scopeNodeRootTreeItem.value,
                                selectedTenantIds),
                        type: selectionType
                    };

            try {
                const { organizationMemberTenantNames, serviceAccountAssignedRoleNames, serviceAccountMissingOrganizationRoleNames, serviceAccountMissingTenantRoleNames, serviceAccountUnauthorizedErrorMessage, valid } =
                    await ConfigurationController.upsertGcpOrganization(
                        new Contract.ConfigurationControllerUpsertGcpOrganizationRequest(
                            addOrEditContext.enabled!,
                            addOrEditContext.folderEnabled!,
                            addOrEditContext.gciTenantModel!.configuration.id,
                            newMemberSelection,
                            addOrEditContext.name!,
                            addOrEditContext.permissionTypes!,
                            addOrEditContext.sinkPubSubSubscriptionEnabled
                                ? addOrEditContext.sinkPubSubSubscriptionRawId
                                : undefined));

                if (!valid) {
                    return !_.isNil(serviceAccountUnauthorizedErrorMessage)
                        ? localization.actions.next.notValid.serviceAccountUnauthorizedErrorMessage({ serviceAccountUnauthorizedErrorMessage })
                        : localization.actions.next.notValid.serviceAccountMissingOrganizationRoleNames.text({
                            serviceAccountMissingOrganizationRoleNames:
                                <InlineItems
                                    items={serviceAccountMissingOrganizationRoleNames}
                                    namePluralizer={localization.actions.next.notValid.serviceAccountMissingOrganizationRoleNames.roleNames}
                                    sx={{ color: "unset" }}
                                    variant="itemCountAndType"/>
                        });
                }

                setAddOrEditContext(
                    addOrEditContext => ({
                        ...addOrEditContext,
                        organizationMemberTenantNames,
                        serviceAccountAssignedRoleNames,
                        serviceAccountMissingOrganizationRoleNames,
                        serviceAccountMissingTenantRoleNames
                    }));
            } catch (error) {
                return error instanceof ApiError && error.statusCode === 400
                    ? tenantManagerErrorTranslator(error.error as Contract.GcpTenantManagerError)
                    : _.isNil(addOrEditContext.organizationConfiguration)
                        ? localization.actions.next.error.add()
                        : localization.actions.next.error.edit();
            }
        },
        [selectedTenantIds, selectionType]);

    return (
        <OrganizationMemberSelection
            scopeNodeRootTreeItem={scopeNodeRootTreeItem}
            selectedTenantIds={selectedTenantIds}
            selectionType={selectionType}
            tenantType={Contract.TenantType.Gcp}
            onError={
                error => {
                    setValid(_.isNil(error));
                    setError(error);
                }}
            onSelectedTenantIdsChanged={setSelectedTenantIds}
            onSelectionTypeChanged={setSelectionType}/>);
}