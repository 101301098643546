import { Contract } from "../../../../../../../../../../common";
import { useAwsCommonResourceInfoItemElements } from "./useAwsCommonResourceInfoItemElements";

export type useAwsDefaultResourceInfoItemElementsOptions = {
    creationTime?: {
        title: string;
    };
};

export function useAwsDefaultResourceInfoItemElements(resourceModel: Contract.AwsResourceModel, options?: useAwsDefaultResourceInfoItemElementsOptions) {
    const commonResourceInfoElements = useAwsCommonResourceInfoItemElements(resourceModel);
    return [
        commonResourceInfoElements.typeNameInfoElement,
        resourceModel.typeName === Contract.TypeNames.AwsTenantEntityModel
            ? commonResourceInfoElements.tenantIdInfoItemElement
            : commonResourceInfoElements.tenantInfoItemElement,
        commonResourceInfoElements.regionInfoItemElement,
        commonResourceInfoElements.regionLocationInfoItemElement,
        commonResourceInfoElements.tagsInfoItemElement,
        commonResourceInfoElements.arnInfoItemElement,
        commonResourceInfoElements.getCreationTimeInfoItemElement(options?.creationTime?.title),
        commonResourceInfoElements.creatorEntityInfoItemElement,
        commonResourceInfoElements.stackInfoItemElement,
        commonResourceInfoElements.syncTimeInfoItemElement
    ];
}