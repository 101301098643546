import { useMemo } from "react";
import { kubernetesAdmissionControllerRiskPolicyItemsOperationStore, RiskController, riskPolicyModelStore } from "../../../../..";
import { RiskControllerUpdateKubernetesAdmissionControllerRiskPolicyConfigurationRequest } from "../../../../../controllers/types.generated";
import { riskPolicyItemConfigurationOperationStore } from "../../../../../operationStores/riskPolicyItemConfigurationOperationStore";
import { RiskPolicyConfigurationControllerDefinition } from "../../../useRiskPolicyConfigurationControllerDefinition";

export function useKubernetesAdmissionControllerBuiltInRiskPolicyConfigurationControllerDefinition(riskPolicyConfigurationTypeNameOrId: string, scopeId: string): RiskPolicyConfigurationControllerDefinition {
    return useMemo(
        () => ({
            async updateRiskPolicyConfiguration({ riskPoliciesIds, riskPolicyConfiguration }) {
                await RiskController.updateRiskPolicyConfiguration(new RiskControllerUpdateKubernetesAdmissionControllerRiskPolicyConfigurationRequest(riskPolicyConfiguration));

                await riskPolicyModelStore.notify(riskPoliciesIds);

                await Promise.all([
                    kubernetesAdmissionControllerRiskPolicyItemsOperationStore.notifyAll(),
                    riskPolicyItemConfigurationOperationStore.notifyAll()
                ]);
            }
        }),
        [riskPolicyConfigurationTypeNameOrId, scopeId]);
}