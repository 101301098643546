import { UnexpectedError, useLocalization } from "@infrastructure";
import React, { useMemo } from "react";
import { useGetAwsEc2InstancesRiskContext, useGetAwsTagResourceGroupRiskContext } from "../../../../..";
import { Contract, entityModelStore, InlineEntities, InlineResourceTags, useEntityTypeNameTranslator } from "../../../../../../../../../../../../../../../common";
import { useCommonSectionsAndDescriptionDefinition } from "../../../../../../useCommonSectionsAndDescriptionDefinition";
import { useGetAwsEc2InstanceMetadataServiceTokenHopMaxCountRiskContext } from "../useGetAwsEc2InstanceMetadataServiceTokenHopMaxCountRiskContext";
import { useDatabricksInstanceGroupResolutionSteps, useSpotInstanceGroupResolutionSteps } from "./hooks";

export function useAwsEc2InstanceMetadataServiceTokenHopMaxCountRiskTagResourceGroupDefinition(riskModel: Contract.AwsEc2InstanceMetadataServiceTokenHopMaxCountRiskModel) {
    const risk = riskModel.risk as Contract.AwsEc2InstanceMetadataServiceTokenHopMaxCountRisk;
    const tagResourceGroupModel = entityModelStore.useGet(risk.entityId) as Contract.AwsTagResourceGroupModel;
    const tagResourceGroup = (tagResourceGroupModel.entity) as Contract.AwsTagResourceGroup;

    const getAwsEc2InstanceMetadataServiceTokenHopMaxCountRiskContext = useGetAwsEc2InstanceMetadataServiceTokenHopMaxCountRiskContext();
    const getAwsEc2InstancesRiskContext = useGetAwsEc2InstancesRiskContext();
    const getAwsTagResourceGroupRiskContext = useGetAwsTagResourceGroupRiskContext();

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsEc2InstanceMetadataServiceTokenHopMaxCountRiskDefinition.hooks.useAwsEc2InstanceMetadataServiceTokenHopMaxCountRiskTagResourceGroupDefinition",
            () => ({
                description: "{{instances}} grouped by {{tags}} metadata response hop limit is greater than {{maxTokenHopMaxCount}}"
            }));
    const translatedTypeName =
        entityTypeNameTranslator(
            tagResourceGroupModel.entity.typeName,
            {
                includeServiceName: true,
                variant: "text"
            });

    const useResolutionSteps =
        useMemo(
            () => {
                switch (tagResourceGroupModel.entity.typeName) {
                    case Contract.TypeNames.AwsEc2DatabricksInstanceGroup:
                        return useDatabricksInstanceGroupResolutionSteps;
                    case Contract.TypeNames.AwsEc2SpotInstanceGroup:
                        return useSpotInstanceGroupResolutionSteps;
                    default:
                        throw new UnexpectedError("tagResourceGroupDefinitionConfiguration.typeName", tagResourceGroupModel.entity.typeName);
                }
            },
            [riskModel, tagResourceGroupModel]);

    const resolutionSteps = useResolutionSteps(riskModel);

    return useCommonSectionsAndDescriptionDefinition(
        localization.description(
            {
                instances:
                    <InlineEntities
                        entityIdsOrModels={riskModel.risk.aggregatedEntityIds}
                        entityTypeName={Contract.TypeNames.AwsEc2Instance}
                        variant="itemCountAndType"/>,
                maxTokenHopMaxCount: risk.maxTokenHopMaxCount,
                tags:
                    <InlineResourceTags
                        tags={tagResourceGroup.groupTags}
                        textVariant="text"
                        variant="itemCountAndType"/>
            }),
        () => resolutionSteps,
        riskModel,
        () => {
            const instanceMetadataServiceTokenHopMaxCountRiskContextItems =
                getAwsEc2InstanceMetadataServiceTokenHopMaxCountRiskContext(
                    riskModel,
                    `${translatedTypeName} **${tagResourceGroupModel.entity.displayName}**`);
            const instancesContextItems = getAwsEc2InstancesRiskContext(riskModel.risk.aggregatedEntityIds);
            const tagResourceGroupContextItems = getAwsTagResourceGroupRiskContext(tagResourceGroupModel);
            return [
                tagResourceGroupContextItems.getGroupTagsContextItem(
                    riskModel.risk.riskedEntityIds,
                    Contract.TypeNames.AwsEc2Instance),
                instanceMetadataServiceTokenHopMaxCountRiskContextItems.instances,
                instanceMetadataServiceTokenHopMaxCountRiskContextItems.permissionActions,
                instanceMetadataServiceTokenHopMaxCountRiskContextItems.instancePublicAccess,
                instancesContextItems.runningInstances,
                instancesContextItems.sensitive];
        });
}