﻿import { UnexpectedError } from "@infrastructure";
import { ReactElement, useMemo } from "react";
import { Contract } from "../../../../../../../../common";
import { useAadDefinition, useAwsDefinition, useAzureDefinition, useGciDefinition, useGcpDefinition, useOktaDefinition, useOneLoginDefinition, usePingIdentityDefinition } from "./hooks";
import { PermissionEligibilityData } from "./utilities";

export function useDefinition(tenantType: Contract.TenantType): TenantDefinition {
    const useDefinition =
        useMemo(
            () => {
                switch (tenantType) {
                    case Contract.TenantType.Aad:
                        return useAadDefinition;
                    case Contract.TenantType.Aws:
                        return useAwsDefinition;
                    case Contract.TenantType.Azure:
                        return useAzureDefinition;
                    case Contract.TenantType.Gci:
                        return useGciDefinition;
                    case Contract.TenantType.Gcp:
                        return useGcpDefinition;
                    case Contract.TenantType.Okta:
                        return useOktaDefinition;
                    case Contract.TenantType.OneLogin:
                        return useOneLoginDefinition;
                    case Contract.TenantType.PingIdentity:
                        return usePingIdentityDefinition;
                    default:
                        throw new UnexpectedError("tenantType", tenantType);
                }
            },
            []);

    return useDefinition();
}

export class TenantDefinition {
    constructor(
        public createInsertPermissionEligibilityRequest: (permissionEligibilityData: PermissionEligibilityData) => Contract.ConfigurationControllerInsertPermissionEligibilityRequest,
        public createPermissionEligibilityData: (permissionEligibilityModel?: Contract.PermissionEligibilityModel, scopeId?: string) => PermissionEligibilityData,
        public createUpdatePermissionEligibilityRequest: (permissionEligibilityData: PermissionEligibilityData, permissionEligibilityId: string) => Contract.ConfigurationControllerUpdatePermissionEligibilityRequest,
        public fieldElements: ReactElement[]) {
    }
}