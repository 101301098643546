import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, InfoItem } from "../../../../../../../../../../common";
import { EntitiesInfoItem } from "../../../../components";

export function useAwsCommonEncryptedResourceInfoElements(entityModel: Contract.AwsEntityModel) {
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsCommonEncryptedResourceInfoElements",
            () => ({
                encryptionExists: {
                    disabled: "Not enabled",
                    enabled: "Enabled",
                    title: "Encryption"
                },
                kmsEncryptionKeyReferences: "Encryption Key"
            }));

    return [
        <InfoItem
            key="encryptionExists"
            title={localization.encryptionExists.title()}
            value={
                _.as<Contract.IAwsEncryptedResource>(entityModel.entity).encryptionExists
                    ? localization.encryptionExists.enabled()
                    : localization.encryptionExists.disabled()}/>,
        <EntitiesInfoItem
            entityIdsOrModels={_.as<Contract.IAwsEncryptedResourceModel>(entityModel).kmsEncryptionKeyIdReferences}
            entityTypeName={Contract.TypeNames.AwsKmsKey}
            key="kmsEncryptionKeyReferences"
            title={localization.kmsEncryptionKeyReferences()}/>
    ];
}