import { Loading, Optional, PagedDropdownPage, StringHelper, useDebouncedEffect, useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { Fragment, useMemo, useState } from "react";
import { Contract, UdmController } from "../../../../../../../controllers";
import { useBuiltInEntityAttributeTypeNameTranslator } from "../../../../../../../hooks";
import { customEntityAttributeDefinitionModelStore } from "../../../../../../../stores";
import { ObjectTypeMetadataModelHelper } from "../../../../../../../utilities";
import { UdmObjectPropertyItem } from "../../../../UdmObjectPropertyItem";
import { UdmObjectPropertyFilterProps } from "../UdmObjectPropertyFilter";
import { Filter } from "./Filter";
import { ListFilterPopover } from "./ListFilterPopover";

export function CommonEntityAttributes({ objectTypeName, onClearClicked, onFilterChange, propertyId, rule }: UdmObjectPropertyFilterProps) {
    const [values, setValues] =
        useState<string[]>(
            _.isEmpty(rule.values)
                ? []
                : rule.values);
    const [operator, setOperator] = useState<Contract.UdmQueryRuleOperator>(rule.operator ?? operators[0]);

    const objectProperty =
        useMemo(
            () =>
                _.find(
                    ObjectTypeMetadataModelHelper.get(objectTypeName)?.udmProperties,
                    property => property.id === rule.propertyId),
            [objectTypeName, rule.propertyId]);

    const [filterItems, setFilterItems] = useState<string[]>([]);
    const [entityAttributeIdToNameMap, entityNameToIdsMap] = useEntityNameToIdsMap(filterItems);
    const uniqueSelectedItems =
        useMemo(
            () =>
                _(filterItems).
                    uniqBy(filterItem => entityAttributeIdToNameMap[filterItem]).
                    intersection(values).
                    value(),
            [entityAttributeIdToNameMap, filterItems]);

    useDebouncedEffect(
        () => {
            if (operator && !_.isNil(values)) {
                onFilterChange({
                    operator,
                    value: values
                });
            }
        },
        500,
        [entityNameToIdsMap, operator, values]);

    const localization =
        useLocalization(
            "common.udmObjectTable.udmQueryBuilder.udmObjectPropertyFilter.commonEntityAttributes",
            () => ({
                empty: "No items",
                options: "{{count}} options"
            }));

    const selectionViewValue =
        _.isEmpty(values)
            ? <Fragment/>
            : _.size(uniqueSelectedItems) > 3
                ? <Typography noWrap={true}>
                    {localization.options({ count: _.size(uniqueSelectedItems) })}
                </Typography>
                : <Stack
                    direction="row"
                    spacing={0.5}>
                    {_.map(
                        uniqueSelectedItems,
                        value =>
                            <Typography noWrap={true}>
                                <Loading container="cell">
                                    <UdmObjectPropertyItem
                                        filter={true}
                                        item={value}
                                        objectId={value}
                                        objectProperty={objectProperty!}
                                        objectTypeName={objectTypeName}/>
                                </Loading>
                            </Typography>)}
                </Stack>;

    async function fetchItems(searchText: Optional<string>, skip: number) {
        if (_.isEmpty(filterItems)) {
            const { hasMore, values } =
                await UdmController.getPropertyValuePage(
                    new Contract.UdmControllerGetPropertyValuePageRequest(
                        10000,
                        objectTypeName,
                        propertyId,
                        searchText || undefined,
                        skip));
            return new PagedDropdownPage(hasMore, values);
        }

        const filteredFilterItems =
            _.isEmpty(searchText)
                ? filterItems
                : _.filter(
                    filterItems,
                    filterItem => StringHelper.search(entityAttributeIdToNameMap[filterItem], searchText));

        return new PagedDropdownPage(false, filteredFilterItems);
    }

    function onItemPageLoad(items: any[]) {
        if (_.isEmpty(filterItems)) {
            setFilterItems(items);
        }
    }

    return (
        <Filter
            emptyValue={true}
            initialOperator={operator}
            operators={operators}
            propertyId={propertyId}
            ruleId={rule.id!}
            selectionViewValue={selectionViewValue}
            values={values}
            onClearClicked={onClearClicked}
            onOperatorChange={setOperator}>
            <ListFilterPopover
                emptyText={localization.empty()}
                getItemPage={fetchItems}
                objectProperty={objectProperty}
                objectTypeName={objectTypeName}
                setValues={setValues}
                values={values}
                onItemPageLoad={onItemPageLoad}/>
        </Filter>);
}

function useEntityNameToIdsMap(entityAttributesIds?: string[]) {
    const builtInEntityAttributeTypeNameTranslator = useBuiltInEntityAttributeTypeNameTranslator();
    const customEntityAttributeDefinitionModels = customEntityAttributeDefinitionModelStore.useGetAll();
    const customEntityAttributeDefinitionModelMap =
        useMemo(
            () =>
                _.keyBy(
                    customEntityAttributeDefinitionModels,
                    customEntityAttributeDefinitionModel => customEntityAttributeDefinitionModel.configuration.id),
            [customEntityAttributeDefinitionModels]);

    if (!entityAttributesIds) {
        return [
            {} as Record<string, string>,
            {} as Record<string, string[]>] as const;
    }

    const entityAttributeIdToNameMap =
        _.reduce<string, Record<string, string>>(
            entityAttributesIds,
            (agg, entityAttributeId) => {
                const isCustom = customEntityAttributeDefinitionModelMap[entityAttributeId];
                const name =
                    isCustom
                        ? customEntityAttributeDefinitionModelMap[entityAttributeId].configuration.name
                        : builtInEntityAttributeTypeNameTranslator(entityAttributeId);
                agg[entityAttributeId] = name;

                return agg;
            },
            {} as Record<string, string>);

    const entityNameToIds =
        _.reduce<string, Record<string, string[]>>(
            entityAttributesIds,
            (agg, entityAttributeId) => {
                const isCustom = customEntityAttributeDefinitionModelMap[entityAttributeId];

                const name =
                    isCustom
                        ? customEntityAttributeDefinitionModelMap[entityAttributeId].configuration.name
                        : builtInEntityAttributeTypeNameTranslator(entityAttributeId);
                agg[name] = agg[name] ?? [];
                agg[name].push(entityAttributeId);

                return agg;
            },
            {});

    return [entityAttributeIdToNameMap, entityNameToIds] as const;
}

const operators = [
    Contract.UdmQueryRuleOperator.In
];