import { useLocalization } from "@infrastructure";
import React from "react";
import { AuditEventDefinition, AuditEventDefinitionContext, AuditEventDefinitionDetailItem } from "..";
import { Contract, Entity } from "../../../../../../../../../common";

export function useEntitySensitivityUpdateAuditEventDefinition(context: AuditEventDefinitionContext) {
    const localization =
        useLocalization(
            "views.customer.auditEvents.profile.hooks.useDefinition.hooks.useEntitySensitivityUpdateAuditEventDefinition",
            () => ({
                entity: "Resource",
                sensitive: {
                    false: "Not sensitive",
                    title: "New sensitivity state",
                    true: "Sensitive"
                }
            }));

    const entitySensitivityUpdateAuditEvent = context.auditEventModel.auditEvent as Contract.EntitySensitivityUpdateAuditEvent;
    const entityModel = context.entityModelMap[entitySensitivityUpdateAuditEvent.entityId];
    return new AuditEventDefinition([
        new AuditEventDefinitionDetailItem(
            localization.entity(),
            entityModel.entity.displayName,
            <Entity
                entityIdOrModel={entityModel}
                variant="iconText"/>),
        new AuditEventDefinitionDetailItem(
            localization.sensitive.title(),
            entitySensitivityUpdateAuditEvent.sensitive
                ? localization.sensitive.true()
                : localization.sensitive.false())
    ]);
}