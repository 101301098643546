import { Optional } from "@infrastructure";
import _ from "lodash";
import React, { ReactNode, useMemo } from "react";
import { Contract, DistributionChart, DistributionChartItem, DistributionChartProps, TypeHelper, useTheme } from "../../../../../../../../../common";

type SecurityStatsDistributionChartProps =
    Omit<DistributionChartProps, "items" | "titleUrl"> & {
        disabled?: boolean;
        getUrl?: (item?: Contract.Severity | "secured" | "unsecuredExcluded") => Optional<string>;
        onTooltip: (excluded: boolean, severity?: Contract.Severity) => ReactNode;
        securityStats: Contract.SecurityStats;
    };

export function SecurityStatsDistributionChart({ disabled = false, getUrl, onTooltip, securityStats, ...props }: SecurityStatsDistributionChartProps) {
    const theme = useTheme();
    const items =
        useMemo(
            () => {
                const items =
                    _(Contract.Severity).
                        values().
                        filter(severity => securityStats.unsecuredSeverityToCountMap[severity] > 0).
                        orderBy(severity => TypeHelper.getEnumValue(Contract.TypeNames.Severity, severity)).
                        map(
                            severity =>
                                new DistributionChartItem(
                                    disabled
                                        ? theme.palette.chart.grey
                                        : theme.palette.severity(severity),
                                    severity,
                                    onTooltip(false, severity),
                                    securityStats.unsecuredSeverityToCountMap[severity],
                                    getUrl?.(severity))).
                        value();

                if (securityStats.unsecuredExcludedCount !== 0) {
                    items.unshift(
                        new DistributionChartItem(
                            disabled
                                ? theme.palette.chart.grey
                                : theme.palette.severity(),
                            "unsecuredExcluded",
                            onTooltip(true, undefined),
                            securityStats.unsecuredExcludedCount,
                            getUrl?.("unsecuredExcluded")));
                }

                if (securityStats.securedCount !== 0) {
                    items.unshift(
                        new DistributionChartItem(
                            disabled
                                ? theme.palette.chart.grey
                                : theme.palette.success.main,
                            "secured",
                            onTooltip(false, undefined),
                            securityStats.securedCount,
                            getUrl?.("secured")));
                }

                return items;
            },
            [disabled, securityStats]);
    return (
        <DistributionChart
            items={items}
            titleUrl={getUrl?.()}
            {...props}/>);
}