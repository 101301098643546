﻿import { Optional, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, Entity, useEntityTypeNameTranslator, useNetworkAccessScopeTranslator } from "../../../../../../../../../../../../common";
import { useAwsRdsDatabaseInstanceEngineTypeTranslator } from "../../../../../../../../../../../../tenants";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetDataAnalysisResourceRiskContext } from "../../../useGetDataAnalysisResourceRiskContext";
import { useGetAwsResourceRiskContext } from "./useGetAwsResourceRiskContext";

export function useGetAwsRdsDatabaseInstanceRiskContext() {
    return useMemo(
        () => useAwsRdsDatabaseInstanceRiskContext,
        []);
}

function useAwsRdsDatabaseInstanceRiskContext(databaseInstanceModel: Contract.AwsRdsDatabaseInstanceModel) {
    const databaseInstance = databaseInstanceModel.entity as Contract.AwsRdsDatabaseInstance;
    const dataAnalysisResourceRiskContext = useGetDataAnalysisResourceRiskContext()(databaseInstanceModel);
    const resourceRiskContext = useGetAwsResourceRiskContext()(databaseInstanceModel);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const networkAccessScopeTranslator = useNetworkAccessScopeTranslator();
    const rdsDatabaseInstanceEngineTypeTranslator = useAwsRdsDatabaseInstanceEngineTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.contexts.useGetAwsRdsDatabaseInstanceRiskContext",
            () => ({
                backupRetentionTimeFrame: "The retention period is currently set to **{{backupRetentionTimeFrame | TimeSpanFormatter.day}}**",
                encryption: {
                    kms: "The {{translatedDatabaseInstanceTypeName}} is encrypted using {{key}}",
                    none: "The {{translatedDatabaseInstanceTypeName}} is not configured with encryption using KMS"
                },
                engine: "The {{translatedDatabaseInstanceTypeName}}'s engine is {{engine}}",
                hasInboundExternalWideRange: {
                    false: "The {{translatedDatabaseInstanceTypeName}} is not exposed directly to the internet",
                    true: "The {{translatedDatabaseInstanceTypeName}} has **{{range}}** exposure directly to the internet"
                },
                storageSize: "The {{translatedDatabaseInstanceTypeName}}'s size is {{storageSize | NumberFormatter.storage}}"
            }));
    const translatedDatabaseInstanceTypeName =
        entityTypeNameTranslator(
            databaseInstance.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });
    const inboundExternalAccessScope = (databaseInstanceModel.entityNetwork as Optional<Contract.AwsNetworkedResourceStateNetwork>)?.inboundExternalAccessScope;

    return ({
        ...dataAnalysisResourceRiskContext,
        ...resourceRiskContext,
        backupRetentionTimeFrame:
            new RiskDefinitionContextItem(localization.backupRetentionTimeFrame({ backupRetentionTimeFrame: databaseInstance.backupRetentionTimeFrame })),
        encryption:
            new RiskDefinitionContextItem(
                (_.isNil(databaseInstance.storageKmsEncryptionKeyOrAliasArn)
                    ? localization.encryption.none
                    : localization.encryption.kms)({
                    key:
                        <Entity
                            entityIdOrModel={databaseInstanceModel.kmsEncryptionKeyIdReferences?.[0]}
                            variant="text"/>,
                    translatedDatabaseInstanceTypeName
                })),
        engine:
            new RiskDefinitionContextItem(
                localization.engine({
                    engine: rdsDatabaseInstanceEngineTypeTranslator(databaseInstance.engineType),
                    translatedDatabaseInstanceTypeName
                })),
        hasInboundExternalWideRange:
            new RiskDefinitionContextItem(
                _.isNil(inboundExternalAccessScope) || inboundExternalAccessScope === Contract.NetworkAccessScope.None
                    ? localization.hasInboundExternalWideRange.false({ translatedDatabaseInstanceTypeName })
                    : localization.hasInboundExternalWideRange.true({
                        range: networkAccessScopeTranslator(inboundExternalAccessScope!),
                        translatedDatabaseInstanceTypeName
                    })),
        storageSize:
            new RiskDefinitionContextItem(
                localization.storageSize({
                    storageSize: databaseInstance.storageSize,
                    translatedDatabaseInstanceTypeName
                }))
    });
}