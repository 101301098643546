﻿import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useAwsEc2VpcPeeringConnectionStatusTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useAwsEc2VpcPeeringConnectionStatusTranslator",
            () => ({
                [Contract.TypeNames.AwsEc2VpcPeeringConnectionStatus]: {
                    [Contract.AwsEc2VpcPeeringConnectionStatus.Active]: "Active",
                    [Contract.AwsEc2VpcPeeringConnectionStatus.Deleted]: "Deleted",
                    [Contract.AwsEc2VpcPeeringConnectionStatus.Deleting]: "Deleting",
                    [Contract.AwsEc2VpcPeeringConnectionStatus.Expired]: "Expired",
                    [Contract.AwsEc2VpcPeeringConnectionStatus.Failed]: "Failed",
                    [Contract.AwsEc2VpcPeeringConnectionStatus.InitiatingRequest]: "Initiating Request",
                    [Contract.AwsEc2VpcPeeringConnectionStatus.PendingAcceptance]: "Pending Acceptance",
                    [Contract.AwsEc2VpcPeeringConnectionStatus.Provisioning]: "Provisioning",
                    [Contract.AwsEc2VpcPeeringConnectionStatus.Rejected]: "Rejected"
                }
            }));
    return (status: Contract.AwsEc2VpcPeeringConnectionStatus) =>
        localization[Contract.TypeNames.AwsEc2VpcPeeringConnectionStatus][status]();
}