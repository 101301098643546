import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, JiraIcon, scopeNodeModelStore, scopeSystemEntityModelStore, ServiceNowIcon, useScopeNavigationViewContext } from "../../../../../../../../../../common";
import { IntegrationItem, IntegrationItemCategory } from "../../../../utilities";
import { Jira, ServiceNow } from "./components";

export function useTicketingItems(childScopeEnabled: boolean): IntegrationItem[] {
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useTicketingItems",
            () => ({
                description: {
                    childScope: "{{count | NumberFormatter.humanize}} configured (child scopes)",
                    title: "{{count | NumberFormatter.humanize}} configured"
                },
                [Contract.TypeNames.TicketingServiceType]: {
                    [Contract.TicketingServiceType.Jira]: "Jira",
                    [Contract.TicketingServiceType.ServiceNow]: "ServiceNow"
                }
            }));

    const { scopeNodeModel } = useScopeNavigationViewContext();
    const scopeNodeMap = scopeNodeModelStore.useGetActiveScopeNodeMap();
    const childScopeIds =
        useMemo(
            () => scopeNodeMap[scopeNodeModel.configuration.id].childScopeIds,
            [scopeNodeModel.configuration.id, scopeNodeMap]);
    const parentScopeIds =
        useMemo(
            () => scopeNodeMap[scopeNodeModel.configuration.id].parentScopeIds,
            [scopeNodeModel.configuration.id, scopeNodeMap]);
    const jiraInstanceModels = scopeSystemEntityModelStore.useGetJira();
    const filteredChildScopeJiraInstanceModels =
        useMemo(
            () =>
                childScopeEnabled
                    ? _.filter(
                        jiraInstanceModels,
                        jiraInstanceModel =>
                            _.includes(
                                childScopeIds,
                                jiraInstanceModel.configuration.scopeId))
                    : [],
            [childScopeEnabled, childScopeIds, jiraInstanceModels]);
    const filteredJiraInstanceModels =
        useMemo(
            () =>
                _.filter(
                    jiraInstanceModels,
                    jiraInstanceModel =>
                        _(parentScopeIds).
                            concat(scopeNodeModel.configuration.id).
                            includes(jiraInstanceModel.configuration.scopeId)),
            [jiraInstanceModels, parentScopeIds]);
    const serviceNowInstanceModels = scopeSystemEntityModelStore.useGetServiceNow();
    const filteredChildScopeServiceNowInstanceModels =
        useMemo(
            () =>
                childScopeEnabled
                    ? _.filter(
                        serviceNowInstanceModels,
                        serviceNowInstanceModel =>
                            _.includes(
                                childScopeIds,
                                serviceNowInstanceModel.configuration.scopeId))
                    : [],
            [childScopeEnabled, childScopeIds, serviceNowInstanceModels]);
    const filteredServiceNowInstanceModels =
        useMemo(
            () =>
                _.filter(
                    serviceNowInstanceModels,
                    serviceNowInstanceModel =>
                        _(parentScopeIds).
                            concat(scopeNodeModel.configuration.id).
                            includes(serviceNowInstanceModel.configuration.scopeId)),
            [parentScopeIds, serviceNowInstanceModels]);

    return [
        new IntegrationItem(
            IntegrationItemCategory.Ticketing,
            _.concat(filteredChildScopeServiceNowInstanceModels, filteredServiceNowInstanceModels),
            _.concatIf(
                [localization.description.title({ count: filteredServiceNowInstanceModels.length })],
                childScopeEnabled && filteredChildScopeServiceNowInstanceModels.length > 0,
                localization.description.childScope({ count: filteredChildScopeServiceNowInstanceModels.length })),
            <ServiceNowIcon/>,
            localization[Contract.TypeNames.TicketingServiceType][Contract.TicketingServiceType.ServiceNow](),
            Contract.IntegrationType.ServiceNow,
            <ServiceNow/>),
        new IntegrationItem(
            IntegrationItemCategory.Ticketing,
            _.concat(filteredChildScopeJiraInstanceModels, filteredJiraInstanceModels),
            _.concatIf(
                [localization.description.title({ count: filteredJiraInstanceModels.length })],
                childScopeEnabled && filteredChildScopeJiraInstanceModels.length > 0,
                localization.description.childScope({ count: filteredChildScopeJiraInstanceModels.length })),
            <JiraIcon/>,
            localization[Contract.TypeNames.TicketingServiceType][Contract.TicketingServiceType.Jira](),
            Contract.IntegrationType.Jira,
            <Jira/>)
    ];
}