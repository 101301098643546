﻿import { Action0, ActionMenuItem, DialogSize, UnexpectedError } from "@infrastructure";
import { FunctionComponent, ReactNode, useMemo } from "react";
import { Contract } from "../../../../../../common";
import { ScopesView, ScopesViewType } from "../../utilities";
import { useAwsAddOrEditDefinition, useAzureAddOrEditDefinition, useGcpAddOrEditDefinition, useOciAddOrEditDefinition, useOpAddOrEditDefinition, useProjectsAddOrEditDefinition } from "./hooks";

export function useAddOrEditDefinition(view: ScopesViewType): AddOrEditDefinition {
    const useAddOrEditDefinition =
        useMemo(
            () => {
                switch (view) {
                    case ScopesView.Aws:
                        return useAwsAddOrEditDefinition;
                    case ScopesView.Azure:
                        return useAzureAddOrEditDefinition;
                    case ScopesView.Gcp:
                        return useGcpAddOrEditDefinition;
                    case ScopesView.Oci:
                        return useOciAddOrEditDefinition;
                    case ScopesView.Op:
                        return useOpAddOrEditDefinition;
                    case ScopesView.Project:
                        return useProjectsAddOrEditDefinition;
                    case ScopesView.Ci:
                    case ScopesView.Code:
                        return () => ({});
                    default:
                        throw new UnexpectedError("view", view);
                }
            },
            [view]);

    return useAddOrEditDefinition();
}

export type AddOrEditDefinition = {
    addDisabled?: boolean;
    additionalActionItems?: (onEdit: Action0) => ActionMenuItem[];
    addTitle?: string;
    dialogSize?: DialogSize;
    element?: FunctionComponent<AddOrEditComponentProps>;
    welcome?: ReactNode;
};

export type AddOrEditComponentProps = {
    onClose: () => void;
    organizationTenant?: boolean;
    parentFolderId: string;
    scopeNodeModel?: Contract.ScopeNodeModel;
    startItemIndex?: number;
};