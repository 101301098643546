﻿import { InlineItems, map, Steps, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useOpenRiskedEntityRisksStep } from "../../../../../../..";
import { Contract, Entity, entityModelStore, InlineEntities, InlinePermissionActions, NetworkScopeFormatter } from "../../../../../../../../../../../../../../../../common";
import { useAwsEc2InstanceStatusTranslator } from "../../../../../../../../../../../../../Entities/hooks";
import { useResourceGeneralInformationStep } from "../../../../../../../useResourceGeneralInformationStep";
import { useResourceSecretExistsSteps } from "../../../../../../../useResourceSecretExistsSteps";

export type ContextSectionProps = {
    riskModel: Contract.AwsEc2InstanceUserDataSecretExistsRiskModel;
};

export function ContextSection({ riskModel }: ContextSectionProps) {
    const instanceModel = entityModelStore.useGet((riskModel.risk as Contract.EntityRisk).entityId) as Contract.AwsEc2InstanceModel;
    const instance = (instanceModel.entity) as Contract.AwsEc2Instance;
    const openRiskedEntityRisksStep = useOpenRiskedEntityRisksStep(instanceModel, riskModel.risk.id);
    const resourceGeneralInformationStep = useResourceGeneralInformationStep(instanceModel);
    const resourceSecretExistsSteps = useResourceSecretExistsSteps();

    const ec2InstanceStatusTranslator = useAwsEc2InstanceStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsEc2InstanceUserDataSecretExistsRiskDefinition.hooks.useAwsEc2InstanceUserDataSecretExistsRiskInstanceDefinition.contextSection",
            () => ({
                [Contract.TypeNames.AwsEc2InstanceUserDataSecretExistsRiskInfoType]: {
                    [Contract.AwsEc2InstanceUserDataSecretExistsRiskInfoType.InstancePublicAccess]: "The EC2 instance is publicly accessible from a wide range of public IP addresses and exposes {{networkScopes}} via {{securityGroups}}",
                    [Contract.AwsEc2InstanceUserDataSecretExistsRiskInfoType.InstancePublicAccessNotExists]: "The EC2 instance is not publicly accessible",
                    [Contract.AwsEc2InstanceUserDataSecretExistsRiskInfoType.NotSensitive]: "The EC2 instance is not marked as sensitive",
                    [Contract.AwsEc2InstanceUserDataSecretExistsRiskInfoType.Permissions]: [
                        "{{identities}}{{thirdPartyRolesPart}} is granted with {{permissionActions}} allowing it to read your secrets",
                        "{{identities}}{{thirdPartyRolesPart}} are granted with {{permissionActions}} allowing them to read your secrets"
                    ],
                    [Contract.AwsEc2InstanceUserDataSecretExistsRiskInfoType.Sensitive]: "The EC2 instance is marked as sensitive"
                },
                identities: {
                    identities: [
                        "1 identity",
                        "{{count | NumberFormatter.humanize}} identities"
                    ],
                    thirdPartyRolesPart: {
                        text: " including {{thirdPartyRoles}}",
                        thirdPartyRoles: [
                            "1 third party role",
                            "{{count | NumberFormatter.humanize}} third party roles"
                        ]
                    }
                },
                ports: [
                    "1 port",
                    "{{count | NumberFormatter.humanize}} ports"
                ],
                secrets: [
                    "1 secret",
                    "{{count | NumberFormatter.humanize}} secrets"
                ],
                status: "The EC2 instance state is **{{status}}**",
                userDataSecrets: "The EC2 instance is exposing {{secrets}} via user data"
            }));
    return (
        <Steps variant="bullets">
            {_.filter([
                resourceGeneralInformationStep,
                instanceModel.unknown
                    ? undefined
                    : localization.status({ status: ec2InstanceStatusTranslator(instance.status) }),
                instanceModel.unknown
                    ? undefined
                    : localization.userDataSecrets({
                        secrets:
                            <InlineItems
                                items={riskModel.risk.userDataSecrets}
                                namePluralizer={localization.secrets}
                                variant="itemCountAndType"/>
                    }),
                instanceModel.unknown
                    ? undefined
                    : resourceSecretExistsSteps.getExcludedSecretsStep(
                        riskModel.risk.userDataExcludedSecrets,
                        riskModel.risk.typeName,
                        riskModel.risk.tenantId),
                ..._.map(
                    riskModel.risk.infos,
                    info => {
                        const infoLocalization = localization[Contract.TypeNames.AwsEc2InstanceUserDataSecretExistsRiskInfoType];
                        return map(
                            info.type,
                            {
                                [Contract.AwsEc2InstanceUserDataSecretExistsRiskInfoType.InstanceNotExists]: () =>
                                    undefined,
                                [Contract.AwsEc2InstanceUserDataSecretExistsRiskInfoType.InstancePublicAccess]: () =>
                                    infoLocalization[Contract.AwsEc2InstanceUserDataSecretExistsRiskInfoType.InstancePublicAccess]({
                                        networkScopes:
                                            <InlineItems
                                                items={
                                                    _.map(
                                                        (info as Contract.AwsEc2InstanceUserDataSecretExistsRiskPublicAccessInfo).destinationNetworkScope,
                                                        networkScope => NetworkScopeFormatter.networkScopeFromDestinationNetworkScope(networkScope))}
                                                namePluralizer={localization.ports}
                                                variant="itemCountAndType"/>,
                                        securityGroups:
                                            <InlineEntities
                                                entityIdsOrModels={(info as Contract.AwsEc2InstanceUserDataSecretExistsRiskPublicAccessInfo).securityGroupIds}
                                                entityTypeName={Contract.TypeNames.AwsEc2SecurityGroup}
                                                variant="itemCountAndType"/>
                                    }),
                                [Contract.AwsEc2InstanceUserDataSecretExistsRiskInfoType.InstancePublicAccessNotExists]: () =>
                                    infoLocalization[Contract.AwsEc2InstanceUserDataSecretExistsRiskInfoType.InstancePublicAccessNotExists](),
                                [Contract.AwsEc2InstanceUserDataSecretExistsRiskInfoType.NotSensitive]: () =>
                                    infoLocalization[Contract.AwsEc2InstanceUserDataSecretExistsRiskInfoType.NotSensitive](),
                                [Contract.AwsEc2InstanceUserDataSecretExistsRiskInfoType.Permissions]: () =>
                                    infoLocalization[Contract.AwsEc2InstanceUserDataSecretExistsRiskInfoType.Permissions](
                                        (info as Contract.AwsEc2InstanceUserDataSecretExistsRiskPermissionsInfo).identityIds.length,
                                        {
                                            identities:
                                                <InlineEntities
                                                    entityIdsOrModels={(info as Contract.AwsEc2InstanceUserDataSecretExistsRiskPermissionsInfo).identityIds}
                                                    entityTypeName={Contract.TypeNames.AwsIamIdentity}
                                                    variant="itemCountAndType"/>,
                                            permissionActions:
                                                <InlinePermissionActions
                                                    permissionActions={(info as Contract.AwsEc2InstanceUserDataSecretExistsRiskPermissionsInfo).actions}
                                                    variant="itemCountAndType"/>,
                                            thirdPartyRolesPart:
                                                (info as Contract.AwsEc2InstanceUserDataSecretExistsRiskPermissionsInfo).vendorIds.length == 0
                                                    ? ""
                                                    : localization.identities.thirdPartyRolesPart.text({
                                                        thirdPartyRoles:
                                                            <InlineItems
                                                                items={
                                                                    _.map(
                                                                        (info as Contract.AwsEc2InstanceUserDataSecretExistsRiskPermissionsInfo).vendorIds,
                                                                        vendorId =>
                                                                            <Entity
                                                                                entityIdOrModel={vendorId}
                                                                                key={vendorId}
                                                                                linkOptions={{ disabledHighlight: false }}
                                                                                variant="iconTextTypeTenant"/>)}
                                                                namePluralizer={localization.identities.thirdPartyRolesPart.thirdPartyRoles}
                                                                variant="itemCountAndType"/>
                                                    })
                                        }),
                                [Contract.AwsEc2InstanceUserDataSecretExistsRiskInfoType.Sensitive]: () =>
                                    infoLocalization[Contract.AwsEc2InstanceUserDataSecretExistsRiskInfoType.Sensitive]()
                            });
                    }),
                openRiskedEntityRisksStep
            ])}
        </Steps>);
}