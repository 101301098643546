import { Shadows, Tooltip } from "@infrastructure";
import { Box, Stack, useTheme } from "@mui/material";
import _ from "lodash";
import React, { useMemo, useState } from "react";

export type DistributionBarProps = {
    height?: "narrow" | "normal" | "wide";
    items: DistributionBarItem[];
    totalValue?: number;
};

export class DistributionBarItem {
    constructor(
        public color: string,
        public value: number,
        public tooltipText?: string) {
    }
}

export function DistributionBar({ height = "wide", items, totalValue }: DistributionBarProps) {
    const [activeItemIndex, setActiveItemIndex] = useState<number>();
    const total =
        useMemo(
            () => totalValue ??
                _.sumBy(
                    items,
                    item => item.value),
            [items]);

    const theme = useTheme();
    return (
        <Stack
            direction="row"
            sx={{
                borderRadius: theme.spacing(1.25),
                height:
                    theme.spacing(
                        height === "narrow"
                            ? 0.5
                            : height === "normal"
                                ? 0.75
                                : 1),
                overflow: "hidden",
                width: "100%"
            }}>
            {_(items).
                filter(item => item.value > 0).
                map(
                    (item, itemIndex) =>
                        <Tooltip
                            disabled={_.isNil(item.tooltipText)}
                            key={itemIndex}
                            slotProps={{
                                popper: { placement: "bottom" },
                                tooltip: {
                                    sx: {
                                        backgroundColor: theme.palette.background.paper,
                                        borderRadius: 0,
                                        boxShadow: "unset",
                                        padding: 0
                                    }
                                }
                            }}
                            titleOrGetTitle={
                                <Box
                                    sx={{
                                        borderLeft: `solid 4px ${item.color}`,
                                        borderRadius: theme.spacing(0.75),
                                        boxShadow: theme.shadows[Shadows.Tooltip],
                                        padding: theme.spacing(1)
                                    }}>
                                    {item.tooltipText}
                                </Box>}>
                            <Box
                                sx={{
                                    backgroundColor: item.color,
                                    width: `${item.value / total * 100}%`,
                                    ...(itemIndex === activeItemIndex && {
                                        boxShadow: theme.shadows[Shadows.SideNavigation]
                                    })
                                }}
                                onMouseEnter={() => setActiveItemIndex(itemIndex)}
                                onMouseLeave={() => setActiveItemIndex(undefined)}/>
                        </Tooltip>).
                value()}
        </Stack>);
}