import { CopyToClipboardTextField, Link, Step, Steps, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract } from "../../../../../../../../../../../../../common";
import { AwsCloudFormationHelper, AwsCloudFormationTrail } from "../../../../../../../../../../../../../tenants";
import { useAddOrEditContext } from "../../../AddOrEdit";

export type CloudFormationCliProps = {
    roleArnStep: Step;
    tenantOnboardingPoliciesInfo: Contract.TenantControllerGetAwsTenantOnboardingPoliciesInfoResponse;
    trail?: AwsCloudFormationTrail;
};

export function CloudFormationCli({ roleArnStep, tenantOnboardingPoliciesInfo, trail }: CloudFormationCliProps) {
    const { tenantModel, tenantOnboardingInfo, tenantPartitionType, tenantPermissionTypes } = useAddOrEditContext();

    const localization =
        useLocalization(
            "views.customer.scopes.hooks.useDefinition.hooks.useAwsDefinition.addOrEdit.roleItem.cloudFormationCli",
            () => ({
                steps: {
                    step1: {
                        docsLink: "AWS CLI",
                        title: "Open a new shell session with the latest version of the {{docsLink}}. Administrator privileges are required to create the CloudFormation stack"
                    },
                    step2: "Copy the following command, and run it in your shell",
                    step3: "After completing stack creation, copy the **OutputValue** that corresponds with the **{{roleArnParameterName}}** key from the shell"
                }
            }));

    const { createStackCliCommand, updateStackCliCommand } =
        AwsCloudFormationHelper.getTenantSteps(
            tenantPartitionType,
            tenantOnboardingInfo,
            tenantOnboardingPoliciesInfo,
            tenantPermissionTypes,
            trail);

    return (
        <Steps variant="letters">
            {localization.steps.step1.title({
                docsLink:
                    <Link
                        urlOrGetUrl="https://docs.aws.amazon.com/cli/latest/userguide/cli-chap-getting-started.html"
                        variant="external">
                        {localization.steps.step1.docsLink()}
                    </Link>
            })}
            {new Step(
                localization.steps.step2(),
                {
                    contentElement:
                        <CopyToClipboardTextField
                            maxRows={5}
                            multiline={true}
                            value={
                                _.isNil(tenantModel)
                                    ? createStackCliCommand
                                    : updateStackCliCommand}/>
                })}
            {localization.steps.step3({ roleArnParameterName: AwsCloudFormationHelper.roleArnParameterName })}
            {roleArnStep}
        </Steps>);
}