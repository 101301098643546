import { useMemo } from "react";
import { useAzureDefaultKubernetesNamespaceResourceDefinition, useAzureDefaultKubernetesResourceDefinition, useAzureKubernetesClusterRoleBindingDefinition, useAzureKubernetesClusterRoleDefinition, useAzureKubernetesCronJobDefinition, useAzureKubernetesDaemonSetDefinition, useAzureKubernetesDeploymentDefinition, useAzureKubernetesGroupDefinition, useAzureKubernetesIngressDefinition, useAzureKubernetesJobDefinition, useAzureKubernetesNamespaceDefinition, useAzureKubernetesNamespaceRoleBindingDefinition, useAzureKubernetesNamespaceRoleDefinition, useAzureKubernetesReplicaSetDefinition, useAzureKubernetesServiceAccountDefinition, useAzureKubernetesServiceDefinition, useAzureKubernetesStatefulSetDefinition, useAzureKubernetesUserDefinition } from ".";
import { DefinitionOptions } from "../../..";
import { Contract, TypeHelper } from "../../../../../../../../../../common";

export function useAzureKubernetesResourceDefinition(entityModel: Contract.EntityModel, options?: DefinitionOptions) {
    const useDefinition =
        useMemo(
            () => {
                switch (entityModel.entity.typeName) {
                    case Contract.TypeNames.AzureKubernetesClusterRole:
                        return useAzureKubernetesClusterRoleDefinition;
                    case Contract.TypeNames.AzureKubernetesClusterRoleBinding:
                        return useAzureKubernetesClusterRoleBindingDefinition;
                    case Contract.TypeNames.AzureKubernetesCronJob:
                        return useAzureKubernetesCronJobDefinition;
                    case Contract.TypeNames.AzureKubernetesDaemonSet:
                        return useAzureKubernetesDaemonSetDefinition;
                    case Contract.TypeNames.AzureKubernetesDeployment:
                        return useAzureKubernetesDeploymentDefinition;
                    case Contract.TypeNames.AzureKubernetesGroup:
                        return useAzureKubernetesGroupDefinition;
                    case Contract.TypeNames.AzureKubernetesIngress:
                        return useAzureKubernetesIngressDefinition;
                    case Contract.TypeNames.AzureKubernetesJob:
                        return useAzureKubernetesJobDefinition;
                    case Contract.TypeNames.AzureKubernetesNamespace:
                        return useAzureKubernetesNamespaceDefinition;
                    case Contract.TypeNames.AzureKubernetesNamespaceRole:
                        return useAzureKubernetesNamespaceRoleDefinition;
                    case Contract.TypeNames.AzureKubernetesNamespaceRoleBinding:
                        return useAzureKubernetesNamespaceRoleBindingDefinition;
                    case Contract.TypeNames.AzureKubernetesReplicaSet:
                        return useAzureKubernetesReplicaSetDefinition;
                    case Contract.TypeNames.AzureKubernetesService:
                        return useAzureKubernetesServiceDefinition;
                    case Contract.TypeNames.AzureKubernetesServiceAccount:
                        return useAzureKubernetesServiceAccountDefinition;
                    case Contract.TypeNames.AzureKubernetesStatefulSet:
                        return useAzureKubernetesStatefulSetDefinition;
                    case Contract.TypeNames.AzureKubernetesUser:
                        return useAzureKubernetesUserDefinition;
                    default:
                        return TypeHelper.extendOrImplement(entityModel.entity.typeName, Contract.TypeNames.AzureKubernetesNamespaceResourceBase)
                            ? useAzureDefaultKubernetesNamespaceResourceDefinition
                            : useAzureDefaultKubernetesResourceDefinition;
                }
            },
            []);

    return useDefinition(entityModel as Contract.AzureResourceModel, options);
}