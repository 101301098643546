﻿import { ApiError, EditIcon, FormLayout, Loading, makeContextProvider, useLocalization, VerticalTabView } from "@infrastructure";
import { Button, CircularProgress, Divider, Stack, Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { useMemo, useState } from "react";
import { Contract, CustomRiskPolicyItem, EntityPropertyPatternHelper, RiskPoliciesType, RiskPoliciesView, RiskPolicyItem, RiskPolicyTypeMetadataHelper, Scope, scopeNodeModelStore, UserHelper, useRiskControllerUpdateRiskPolicyConfigurationErrorTranslator, useRiskPolicyConfigurationControllerDefinition, useRiskPolicyTranslator, useTheme } from "../../../../../../common";
import { useRiskPoliciesItemConfiguration } from "../../hooks";
import { CodeExclusionSection, EntityExclusionSection, General } from "./components";
import { useDefinition } from "./hooks";

export class ConfigurationContext {
    public additionalTabIdToSectionMap?: Dictionary<RiskPolicyAdditionalTabSection>;
    public codeExclusionSection?: CodeExclusionSection;
    public dirty: boolean;
    public enabled?: boolean;
    public entityExclusionSection?: EntityExclusionSection;
    public errorMessage?: string;
    public saveError: boolean;
    public saveExecuting: boolean;

    constructor(
        public item: RiskPolicyItem,
        public riskPolicyConfiguration: Contract.RiskPolicyConfiguration,
        public scopeId: string,
        public selectedCategory: ConfigurationCategory) {
        this.dirty = false;
        this.saveError = false;
        this.saveExecuting = false;
    }
}

export const [useConfigurationContext, useSetConfigurationContext, useConfigurationContextProvider] = makeContextProvider<ConfigurationContext>();

export type ConfigurationProps = {
    item: RiskPolicyItem;
    onClose: () => void;
    riskPoliciesType: RiskPoliciesType;
    scopeId: string;
};

export function Configuration({ item, onClose, riskPoliciesType, scopeId }: ConfigurationProps) {
    const { riskPolicyConfiguration } = useRiskPoliciesItemConfiguration(riskPoliciesType, item, scopeId);
    const [, , ContextProvider] =
        useConfigurationContextProvider(
            () =>
                new ConfigurationContext(
                    item,
                    riskPolicyConfiguration,
                    scopeId,
                    item.custom
                        ? ConfigurationCategory.Template
                        : ConfigurationCategory.General));

    return (
        <ContextProvider>
            <Core
                item={item}
                riskPoliciesType={riskPoliciesType}
                scopeId={scopeId}
                onClose={onClose}/>
        </ContextProvider>);
}

function Core({ item, onClose, riskPoliciesType, scopeId }: ConfigurationProps) {
    const scopeNodeModel = scopeNodeModelStore.useGet(scopeId);
    const { scopeRiskPolicyModel, tenantRiskPolicyModels } = useRiskPoliciesItemConfiguration(riskPoliciesType, item, scopeNodeModel.configuration.id);
    const { additionalTabIdToSectionMap, codeExclusionSection, dirty, entityExclusionSection, errorMessage, riskPolicyConfiguration, saveError, saveExecuting, selectedCategory } = useConfigurationContext();
    const setConfigurationContext = useSetConfigurationContext();
    const [saveErrorMessage, setSaveErrorMessage] = useState<string>();
    const riskPoliciesView =
        useMemo(
            () =>
                item.custom
                    ? RiskPoliciesView.Custom
                    : RiskPoliciesView.BuiltIn,
            [item]);
    const { updateRiskPolicyConfiguration } =
        useRiskPolicyConfigurationControllerDefinition(
            riskPoliciesType,
            item.riskPolicyConfigurationTypeNameOrId,
            scopeNodeModel.configuration.id,
            riskPoliciesView);
    const { configurationCategoryTabToViewItemMap, customRiskPolicyActionsRef, generalAdditionalItems } =
        useDefinition(
            riskPoliciesType,
            item,
            scopeNodeModel.configuration.id,
            riskPoliciesView);

    const userSecurityWrite =
        UserHelper.hasScopePermissions(
            riskPolicyConfiguration.scopeId,
            Contract.IdentityPermission.SecurityWrite);

    function getRiskPolicyConfiguration() {
        if (!item.custom) {
            return riskPolicyConfiguration;
        }

        const { description, name, scopeId, severity } = riskPolicyConfiguration as Contract.CustomRiskPolicyConfiguration;
        const customRiskPolicyRequest = customRiskPolicyActionsRef?.current?.createRequest(description, name, scopeId, severity);

        return {
            ...riskPolicyConfiguration,
            ...customRiskPolicyRequest
        };
    }

    const riskPolicyTranslator = useRiskPolicyTranslator();
    const riskControllerUpdateRiskPolicyConfigurationErrorTranslator = useRiskControllerUpdateRiskPolicyConfigurationErrorTranslator();
    const localization =
        useLocalization(
            "views.customer.riskPolicies.configuration.configuration",
            () => ({
                actions: {
                    close: "Close",
                    save: {
                        error: "Failed to save",
                        title: "Save"
                    }
                },
                category: {
                    [ConfigurationCategory.ApiGatewayRouteExclusions]: "API Gateway Path Exclusions",
                    [ConfigurationCategory.CodeExclusions]: "Code Exclusions",
                    [ConfigurationCategory.EntityExclusions]: "Resource Exclusions",
                    [ConfigurationCategory.General]: "General",
                    [ConfigurationCategory.KubernetesAdmissionControllerParameters]: "Parameters",
                    [ConfigurationCategory.SecretExclusions]: "Secret Exclusions",
                    [ConfigurationCategory.Template]: "Policy Template"
                },
                disabledItemTooltip: "This option is not available in the selected scope"
            }));

    async function onSave(
        tenantRiskPolicyModels: Contract.RiskPolicyModel[],
        scopeRiskPolicyModel: Contract.RiskPolicyModel,
        entityExclusionSection?: EntityExclusionSection) {
        setConfigurationContext(
            context => ({
                ...context,
                saveError: false,
                saveExecuting: true
            }));

        try {
            const entityTypeNameToExclusionsMap =
                _.isNil(entityExclusionSection)
                    ? {}
                    : _(RiskPolicyTypeMetadataHelper.getExclusionEntityTypeNames(riskPolicyConfiguration.typeName)).
                        keyBy().
                        mapValues(
                            exclusionEntityTypeName =>
                                new Contract.RiskPolicyConfigurationEntityExclusion(
                                    entityExclusionSection!.excludedEntityTypeNameToIdToExclusionMap[exclusionEntityTypeName],
                                    _(entityExclusionSection!.entityTypeNameToPropertyPatternItemsMap[exclusionEntityTypeName]).
                                        filter(entityTypeNamePropertyPatternItems => _.isEmpty(entityExclusionSection!.excludedEntityTypeNameToPropertyPatternKeyToScopeIdsMap[exclusionEntityTypeName][entityTypeNamePropertyPatternItems.key])).
                                        map(entityTypeNamePropertyPatternItems => entityTypeNamePropertyPatternItems.propertyPattern).
                                        concat(
                                            _(riskPolicyConfiguration.entityTypeNameToExclusionsMap[exclusionEntityTypeName].entityPropertyPatterns).
                                                filter(
                                                    entityPropertyPattern =>
                                                        _.has(
                                                            entityExclusionSection!.excludedEntityTypeNameToPropertyPatternKeyToScopeIdsMap[exclusionEntityTypeName],
                                                            EntityPropertyPatternHelper.getExclusionEntityPropertyPatternKey(entityPropertyPattern))).
                                                value()).
                                        value())).
                        value();
            const codeExclusions =
                _(codeExclusionSection?.items).
                    filter(codeExclusionItem => !codeExclusionItem.inherited).
                    map(codeExclusionItem => codeExclusionItem.codeExclusion).
                    value();

            await updateRiskPolicyConfiguration({
                codeExclusions,
                entityTypeNameToExclusionsMap,
                riskPoliciesIds:
                    _<any>(tenantRiskPolicyModels).
                        concat(scopeRiskPolicyModel).
                        map(riskPolicyModel => riskPolicyModel.riskPolicyConfiguration.id).
                        value(),
                riskPolicyConfiguration: getRiskPolicyConfiguration(),
                riskPolicyIdToSystemUpdateTimeMap:
                    _(tenantRiskPolicyModels).
                        keyBy(tenantRiskPolicyModel => tenantRiskPolicyModel.riskPolicyConfiguration.id).
                        mapValues(tenantRiskPolicyModel => tenantRiskPolicyModel.riskPolicyConfiguration.systemUpdateTime).
                        value()
            });

            onClose();
        } catch (error) {
            setConfigurationContext(
                context => ({
                    ...context,
                    saveError: true
                }));
            setSaveErrorMessage(
                error instanceof ApiError &&
                    error.statusCode === 400 &&
                    error.error === Contract.RiskControllerUpdateRiskPolicyConfigurationError.Conflict
                    ? riskControllerUpdateRiskPolicyConfigurationErrorTranslator(error.error)
                    : localization.actions.save.error());
        }

        setConfigurationContext(
            context => ({
                ...context,
                saveExecuting: false
            }));
    }


    const configurationCategories =
        useMemo(
            () => [
                ConfigurationCategory.General,
                ..._.keys(configurationCategoryTabToViewItemMap)
            ],
            [configurationCategoryTabToViewItemMap]);

    const theme = useTheme();
    return (
        <FormLayout
            disableContentPadding={true}
            footerOptions={{
                border: true,
                contentElement: (
                    <Stack
                        alignItems="center"
                        direction="row"
                        justifyContent="flex-end"
                        spacing={1}>
                        {saveExecuting && (
                            <CircularProgress
                                size={theme.spacing(2)}
                                variant="indeterminate"/>)}
                        {userSecurityWrite
                            ? <Button
                                disabled={
                                    !dirty ||
                                    !_.isNil(errorMessage) ||
                                    saveExecuting ||
                                    _.some(additionalTabIdToSectionMap, section => !section.valid) ||
                                    (!_.isNil(codeExclusionSection) &&
                                        !codeExclusionSection!.valid) ||
                                    _.some(
                                        entityExclusionSection?.entityTypeNameToPropertyPatternValidMap,
                                        valid => !valid)}
                                onClick={
                                    () =>
                                        onSave(
                                            tenantRiskPolicyModels,
                                            scopeRiskPolicyModel,
                                            entityExclusionSection)}>
                                {localization.actions.save.title()}
                            </Button>
                            : <Button
                                disabled={saveExecuting}
                                variant="outlined"
                                onClick={() => onClose()}>
                                {localization.actions.close()}
                            </Button>}
                    </Stack>),
                errorMessage:
                    saveError
                        ? saveErrorMessage
                        : errorMessage
            }}
            titleOptions={{
                subtitle: (
                    <Stack
                        alignItems="center"
                        direction="row"
                        spacing={1}>
                        <Typography noWrap={true}>
                            {item.custom
                                ? (item as CustomRiskPolicyItem).riskPolicyConfiguration.name
                                : riskPolicyTranslator(item.riskPolicyConfigurationTypeName).title}
                        </Typography>
                        <Divider
                            flexItem={true}
                            orientation="vertical"
                            sx={{
                                borderColor: theme.palette.text.secondary,
                                height: theme.spacing(1.75)
                            }}/>
                        <Scope
                            scopeId={scopeRiskPolicyModel.riskPolicyConfiguration.scopeId}
                            sx={{ color: theme.palette.text.secondary }}/>
                    </Stack>),
                text:
                    item.custom
                        ? (item as CustomRiskPolicyItem).riskPolicyConfiguration.name
                        : riskPolicyTranslator(item.riskPolicyConfigurationTypeName).title
            }}>
            <VerticalTabView
                collapseEnabled={false}
                items={
                    _.map(
                        configurationCategories,
                        configurationCategory => ({
                            disabled: configurationCategory !== ConfigurationCategory.General && configurationCategoryTabToViewItemMap[configurationCategory].disabled,
                            icon:
                                configurationCategory != ConfigurationCategory.General &&
                                    additionalTabIdToSectionMap?.[configurationCategory]?.valid === false
                                    ? <EditIcon
                                        sx={{
                                            color: theme.palette.error.main,
                                            fontSize: "16px"
                                        }}/>
                                    : undefined,
                            title: localization.category[configurationCategory as ConfigurationCategory](),
                            view: configurationCategory
                        }))}
                selectedView={selectedCategory}
                sx={{
                    minWidth: theme.spacing(25),
                    padding: theme.spacing(1, 1, 1, 0.25)
                }}
                onSelectedViewChanged={
                    view =>
                        setConfigurationContext(
                            context => ({
                                ...context,
                                selectedCategory: view as ConfigurationCategory
                            }))}>
                <Loading>
                    {selectedCategory === ConfigurationCategory.General
                        ? <General
                            additionalConfigurationElements={generalAdditionalItems}
                            key={ConfigurationCategory.General}
                            riskPoliciesType={riskPoliciesType}/>
                        : configurationCategoryTabToViewItemMap[selectedCategory].component()}
                </Loading>
            </VerticalTabView>
        </FormLayout>);
}

export enum ConfigurationCategory {
    ApiGatewayRouteExclusions = "apiGatewayRouteExclusions",
    CodeExclusions = "codeExclusions",
    EntityExclusions = "entityExclusions",
    General = "general",
    KubernetesAdmissionControllerParameters = "kubernetesAdmissionControllerParameters",
    SecretExclusions = "secretExclusions",
    Template = "template"
}

export interface RiskPolicyAdditionalTabSection {
    valid: boolean;
}