import { useLocalization } from "@infrastructure";
import React from "react";
import { ProfileTopbarInfoItem } from "../../../../../../../../../../../common";
import { UserIcon } from "../../../../../../../../../../../common/icons";

type UserTopbarInfoItemProps = {
    name: string;
};

export function ProfileTopbarUserInfoItem({ name }: UserTopbarInfoItemProps) {
    const localization =
        useLocalization(
            "views.customer.workloadAnalysis.code.scans.profile.profileTopbarUserInfoItem",
            () => ({
                user: "User"
            }));

    return (
        <ProfileTopbarInfoItem
            icon={<UserIcon/>}
            text={name}
            titleOrGetTitle={localization.user()}/>);
}