import { DataTableColumn, EnumValuesFilter, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { useAwsCommonResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, ItemSelectionHelper, TimeRangeHelper } from "../../../../../../../../../../common";
import { useAwsEc2VpcBlockPublicAccessPolicyNetworkDirectionTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";

export function useAwsEc2VpcBlockPublicAccessPolicyDefinition(definitionData: DefinitionData) {
    const commonResourceDefinition = useAwsCommonResourceDefinition(definitionData);

    const vpcBlockPublicAccessPolicyNetworkDirectionTranslator = useAwsEc2VpcBlockPublicAccessPolicyNetworkDirectionTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.aws.useAwsEc2VpcBlockPublicAccessPolicyDefinition",
            () => ({
                columns: {
                    blockNetworkDirection: "Internet Gateway Block Direction",
                    enabled: {
                        false: "Disabled",
                        title: "Status",
                        true: "Enabled"
                    }
                }
            }));

    function getEnabledTranslation(vpcBlockPublicAccessPolicyModel: Contract.EntityModel) {
        return (vpcBlockPublicAccessPolicyModel as Contract.AwsEc2VpcBlockPublicAccessPolicyModel).enabled
            ? localization.columns.enabled.true()
            : localization.columns.enabled.false();
    }

    return new EntityTableDefinition(
        _.filter(
            [
                commonResourceDefinition.columns.tenantColumn,
                commonResourceDefinition.columns.creationTimeColumn,
                commonResourceDefinition.columns.creatorIdentityCsvColumn,
                commonResourceDefinition.columns.creatorOriginatorEntityCsvColumn,
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsEc2VpcBlockPublicAccessPolicyModel) => ({
                                [localization.columns.enabled.title()]: getEnabledTranslation(item)
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <ValuesFilter
                                    placeholder={localization.columns.enabled.title()}
                                    sorted={false}>
                                    <ValuesFilterItem
                                        title={localization.columns.enabled.true()}
                                        value={true}/>
                                    <ValuesFilterItem
                                        title={localization.columns.enabled.false()}
                                        value={false}/>
                                </ValuesFilter>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsEc2VpcBlockPublicAccessPolicyEnabled}
                    itemProperty={getEnabledTranslation}
                    key={Contract.EntityModelProperty.AwsEc2VpcBlockPublicAccessPolicyEnabled}
                    title={localization.columns.enabled.title()}/>,
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsEc2VpcBlockPublicAccessPolicyModel) => ({
                                [localization.columns.blockNetworkDirection()]: vpcBlockPublicAccessPolicyNetworkDirectionTranslator((item.entity as Contract.AwsEc2VpcBlockPublicAccessPolicy).blockNetworkDirection)
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <EnumValuesFilter
                                    enumType={Contract.AwsEc2VpcBlockPublicAccessPolicyNetworkDirection}
                                    enumTypeTranslator={vpcBlockPublicAccessPolicyNetworkDirectionTranslator}
                                    placeholder={localization.columns.blockNetworkDirection()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsEc2VpcBlockPublicAccessPolicyBlockNetworkDirection}
                    itemProperty={item => vpcBlockPublicAccessPolicyNetworkDirectionTranslator((item.entity as Contract.AwsEc2VpcBlockPublicAccessPolicy).blockNetworkDirection)}
                    key={Contract.EntityModelProperty.AwsEc2VpcBlockPublicAccessPolicyBlockNetworkDirection}
                    title={localization.columns.blockNetworkDirection()}/>,
                commonResourceDefinition.columns.regionColumn,
                commonResourceDefinition.columns.regionLocationColumn,
                commonResourceDefinition.columns.attributesColumn,
                commonResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
                commonResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
                commonResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
            ]),
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAwsEc2VpcBlockPublicAccessPolicyRequest(
                new Contract.EntityControllerGetEntityModelPageAwsEc2VpcBlockPublicAccessPolicyRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEc2VpcBlockPublicAccessPolicyBlockNetworkDirection]),
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEc2VpcBlockPublicAccessPolicyEnabled])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags]))));
}