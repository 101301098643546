import React, { Fragment } from "react";
import { Contract } from "../../../../../../controllers";
import { EntitiesIcon, KubernetesIcon, RisksIcon } from "../../../../../../icons";
import { AccessIcon, DataIcon, ManagementIcon } from "../../../../../../icons/RiskPolicyCategoryIcon/icons";
import { EntityAiIcon, EntityComputeIcon, EntityContainersIcon, EntityIotIcon, EntityNetworkIcon, EntitySecurityIcon } from "./icons";

type ObjectTypeCategoryIconProps = {
    category: Contract.ObjectTypeCategory;
};

export function ObjectTypeCategoryIcon({ category }: ObjectTypeCategoryIconProps) {
    switch (category) {
        case Contract.ObjectTypeCategory.Entity:
            return <EntitiesIcon/>;
        case Contract.ObjectTypeCategory.EntityAi:
            return <EntityAiIcon/>;
        case Contract.ObjectTypeCategory.EntityCompute:
            return <EntityComputeIcon/>;
        case Contract.ObjectTypeCategory.EntityContainers:
            return <EntityContainersIcon/>;
        case Contract.ObjectTypeCategory.EntityData:
            return <DataIcon/>;
        case Contract.ObjectTypeCategory.EntityIam:
            return <AccessIcon/>;
        case Contract.ObjectTypeCategory.EntityIot:
            return <EntityIotIcon/>;
        case Contract.ObjectTypeCategory.EntityKubernetes:
            return <KubernetesIcon/>;
        case Contract.ObjectTypeCategory.EntityManagement:
            return <ManagementIcon/>;
        case Contract.ObjectTypeCategory.EntityNetwork:
            return <EntityNetworkIcon/>;
        case Contract.ObjectTypeCategory.EntitySecurity:
            return <EntitySecurityIcon/>;
        case Contract.ObjectTypeCategory.Risk:
            return <RisksIcon/>;
        default:
            return <Fragment/>;
    }
}