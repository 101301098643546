﻿import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function IrelandIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <g clipPath="url(#clip0_60203_5268)">
                <rect
                    fill="white"
                    height="15.9638"
                    rx="2"
                    width="24"
                    y="4"/>
                <path
                    d="M24 4.00012H0V19.9639H24V4.00012Z"
                    fill="#169B62"/>
                <path
                    d="M23.9999 4.00012H7.99994V19.9639H23.9999V4.00012Z"
                    fill="white"/>
                <path
                    d="M24.0001 4.00012H16.0001V19.9639H24.0001V4.00012Z"
                    fill="#FF883E"/>
            </g>
            <path
                clipRule="evenodd"
                d="M16 5H8V4H16V5Z"
                fill="#BAC5D3"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M16 20H8V19H16V20Z"
                fill="#BAC5D3"
                fillRule="evenodd"/>
            <defs>
                <clipPath id="clip0_60203_5268">
                    <rect
                        fill="white"
                        height="15.9638"
                        rx="2"
                        width="24"
                        y="4"/>
                </clipPath>
            </defs>
        </SvgIcon>);
}