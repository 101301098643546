import { Dictionary } from "lodash";
import React from "react";
import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, EnumValuesFilter, useLocalization } from "@infrastructure";
import { useAwsCommonEncryptedResourceDefinition, useAwsCommonNetworkedResourceDefinition, useAwsCommonRdsDatabaseInstanceResourceDefinition, useAwsCommonResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { useCommonDataAnalysisDefinition } from "..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions, useEntityTypeNameTranslator } from "../../../../../../../../../../common";
import { useAwsRdsDatabaseInstanceEngineTypeTranslator } from "../../../../../../../../../../tenants";
import { DefinitionData } from "../../../../Table";

export function useAwsRdsDatabaseInstanceDefinition(definitionData: DefinitionData) {
    const commonDataAnalysisResourceDefinition = useCommonDataAnalysisDefinition(definitionData);
    const commonEncryptedResourceDefinition = useAwsCommonEncryptedResourceDefinition(definitionData);
    const commonNetworkedResourceDefinition = useAwsCommonNetworkedResourceDefinition(definitionData);
    const commonRdsDatabaseInstanceResourceDefinition =
        useAwsCommonRdsDatabaseInstanceResourceDefinition(
            Contract.TypeNames.AwsRdsCluster,
            definitionData);
    const commonResourceDefinition = useAwsCommonResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const rdsDatabaseInstanceEngineTypeTranslator = useAwsRdsDatabaseInstanceEngineTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.aws.useAwsRdsDatabaseInstanceDefinition",
            () => ({
                columns: {
                    engineType: "Engine"
                }
            }));
    const translatedDatabaseInstanceSnapshotTypeName =
        entityTypeNameTranslator(
            Contract.TypeNames.AwsRdsDatabaseInstanceSnapshot,
            {
                count: 0,
                includeServiceName: true,
                variant: "title"
            });

    return new EntityTableDefinition(
        [
            commonResourceDefinition.columns.tenantColumn,
            commonResourceDefinition.columns.creationTimeColumn,
            commonResourceDefinition.columns.creatorIdentityCsvColumn,
            commonResourceDefinition.columns.creatorOriginatorEntityCsvColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsRdsDatabaseInstanceModel) => ({
                            [localization.columns.engineType()]: rdsDatabaseInstanceEngineTypeTranslator((item.entity as Contract.AwsRdsDatabaseInstance).engineType)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.AwsRdsDatabaseInstanceEngineType}
                                enumTypeTranslator={rdsDatabaseInstanceEngineTypeTranslator}
                                placeholder={localization.columns.engineType()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AwsRdsDatabaseInstanceEngineType}
                itemProperty={(item: Contract.EntityModel) => rdsDatabaseInstanceEngineTypeTranslator((item.entity as Contract.AwsRdsDatabaseInstance).engineType)}
                key={Contract.EntityModelProperty.AwsRdsDatabaseInstanceEngineType}
                title={localization.columns.engineType()}/>,
            commonRdsDatabaseInstanceResourceDefinition.columns.statusColumn,
            commonRdsDatabaseInstanceResourceDefinition.columns.engineVersionColumn,
            commonRdsDatabaseInstanceResourceDefinition.columns.storageSizeColumn,
            commonRdsDatabaseInstanceResourceDefinition.columns.instanceRawTypeColumn,
            commonRdsDatabaseInstanceResourceDefinition.columns.clusterColumn,
            commonRdsDatabaseInstanceResourceDefinition.columns.endpointColumn,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.AwsRdsDatabaseInstanceModel>(
                        Contract.TypeNames.AwsRdsDatabaseInstanceSnapshot,
                        item => item.snapshotIds,
                        translatedDatabaseInstanceSnapshotTypeName)}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AwsRdsDatabaseInstanceSnapshots)}
                                placeholder={translatedDatabaseInstanceSnapshotTypeName}/>
                    }
                }}
                id={Contract.EntityModelProperty.AwsRdsDatabaseInstanceSnapshots}
                key={Contract.EntityModelProperty.AwsRdsDatabaseInstanceSnapshots}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AwsRdsDatabaseInstanceModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.snapshotIds}
                            entityTypeName={Contract.TypeNames.AwsRdsDatabaseInstanceSnapshot}
                            entityVariant="iconText"/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={translatedDatabaseInstanceSnapshotTypeName}/>,
            commonNetworkedResourceDefinition.columns.accessLevelColumn,
            commonNetworkedResourceDefinition.columns.inboundExternalAccessScopeColumn,
            commonNetworkedResourceDefinition.columns.getVpcsColumn(),
            commonNetworkedResourceDefinition.columns.securityGroupsColumn,
            commonEncryptedResourceDefinition.columns.encryptionExistsColumn,
            commonEncryptedResourceDefinition.columns.kmsEncryptionKeyColumn,
            commonRdsDatabaseInstanceResourceDefinition.columns.backupRetentionTimeFrameColumn,
            commonRdsDatabaseInstanceResourceDefinition.columns.cloudWatchLoggingEnabledColumn,
            commonRdsDatabaseInstanceResourceDefinition.columns.cloudWatchLogTypesColumn,
            commonRdsDatabaseInstanceResourceDefinition.columns.deleteProtectionColumn,
            commonRdsDatabaseInstanceResourceDefinition.columns.minorVersionUpgradeEnabledColumn,
            commonResourceDefinition.columns.regionColumn,
            commonResourceDefinition.columns.regionLocationColumn,
            commonDataAnalysisResourceDefinition.columns.dataCategoriesColumn,
            commonDataAnalysisResourceDefinition.columns.dataAnalysisResourceSensitivityColumn,
            commonDataAnalysisResourceDefinition.columns.dataAnalysisStatusColumn,
            commonDataAnalysisResourceDefinition.columns.dataClassifiersColumn?.(false),
            commonDataAnalysisResourceDefinition.columns.scanTimeColumn,
            commonResourceDefinition.columns.tagsColumn,
            commonResourceDefinition.columns.attributesColumn,
            commonResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAwsRdsDatabaseInstanceRequest(
                new Contract.EntityControllerGetEntityModelPageAwsRdsDatabaseInstanceRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsRdsDatabaseInstanceResourceCluster]),
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsRdsDatabaseInstanceResourceDeleteProtection]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsRdsDatabaseInstanceResourceEngineVersion]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsRdsDatabaseInstanceResourceRawType]),
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsRdsDatabaseInstanceResourceMinorVersionUpgradeEnabled]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsRdsDatabaseInstanceResourceStatus]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsRdsDatabaseInstanceEngineType]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsRdsDatabaseInstanceSnapshots])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags])),
                new Contract.EntityControllerGetEntityModelPageAwsResourceRequestCloudWatchLogging(
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsCloudWatchResourceCloudWatchLoggingEnabled]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsCloudWatchResourceCloudWatchLogTypes])),
                new Contract.EntityControllerGetEntityModelPageEntityRequestDataAnalysis(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.DataAnalysisResourceScanStatus]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.DataAnalysisResourceDataCategories]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.DataAnalysisResourceDataClassifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.DataAnalysisResourceDataSensitivities]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.DataAnalysisResourceScanTime])),
                new Contract.EntityControllerGetEntityModelPageAwsResourceRequestEncrypted(
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEncryptedResourceEncryptionExists]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEncryptedResourceKmsEncryptionKeys])),
                new Contract.EntityControllerGetEntityModelPageEntityRequestNetworkAccess(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.NetworkInboundAccessType]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.NetworkInboundExternalAccessScope]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.NetworkInboundExternalDestinationNetworkScopes])),
                new Contract.EntityControllerGetEntityModelPageAwsResourceRequestNetworked(
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsNetworkedResourceSecurityGroups]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsNetworkedResourceVpcs]))));
}