import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition, useGetAwsEc2SecurityGroupRiskContext, useGetAwsEc2VpcRiskContext } from "../../..";
import { Contract, Entity, entityModelStore, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { RiskDefinitionContextItem } from "../../../../../../utilities";

export function useAwsEc2VpcDefaultSecurityGroupRuleExistsRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsEc2VpcDefaultSecurityGroupRuleExistsRisk;
    const securityGroupModel = entityModelStore.useGet(risk.securityGroupId) as Contract.AwsEc2SecurityGroupModel;
    const vpcModel = entityModelStore.useGet(risk.entityId) as Contract.AwsEc2VpcModel;

    const getAwsEc2SecurityGroupRiskContext = useGetAwsEc2SecurityGroupRiskContext();
    const getAwsEc2VpcRiskContext = useGetAwsEc2VpcRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsEc2VpcDefaultSecurityGroupRuleExistsRiskDefinition",
            () => ({
                description: "{{securityGroup}} allows traffic",
                sections: {
                    context: {
                        securityGroup: "The {{translatedVpcTypeName}} is using {{securityGroup}}"
                    },
                    resolution: {
                        step1: "Before deleting any rules, verify that the default security group is not used",
                        step2: "In the **Inbound rules** tab, press **Edit inbound rules** button",
                        step3: "For each inbound rules, click **Delete**",
                        step4: "Click **Save rules** to confirm",
                        step5: "In the **Outbound rules** tab, click **Edit outbound rules** button",
                        step6: "For each outbound rules, click **Delete**",
                        step7: "Click **Save rules**"
                    }
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            securityGroup:
                <Entity
                    entityIdOrModel={securityGroupModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.Vpc,
                AwsConsoleUrlBuilder.getEc2SecurityGroupsSecurityGroupUrl(securityGroupModel.entity as Contract.AwsEc2SecurityGroup)),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4(),
            localization.sections.resolution.step5(),
            localization.sections.resolution.step6(),
            localization.sections.resolution.step7()
        ],
        riskModel,
        () => {
            const securityGroupRiskContext = getAwsEc2SecurityGroupRiskContext(securityGroupModel);
            const vpcRiskContext = getAwsEc2VpcRiskContext(vpcModel);
            return [
                vpcRiskContext.generalInformation,
                new RiskDefinitionContextItem(
                    localization.sections.context.securityGroup({
                        securityGroup:
                            <Entity
                                entityIdOrModel={securityGroupModel}
                                variant="typeText"/>,
                        translatedVpcTypeName:
                            entityTypeNameTranslator(
                                vpcModel.entity.typeName,
                                {
                                    includeServiceName: false,
                                    variant: "text"
                                })
                    })),
                securityGroupRiskContext.attachedResources,
                vpcRiskContext.sensitive,
                securityGroupRiskContext.sensitive,
                vpcRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        });
}