﻿import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useAzureKeyVaultVaultKeyTypeTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useAzureKeyVaultVaultKeyTypeTranslator",
            () => ({
                [Contract.TypeNames.AzureKeyVaultVaultKeyType]: {
                    [Contract.AzureKeyVaultVaultKeyType.Ec]: "EC",
                    [Contract.AzureKeyVaultVaultKeyType.EcHsm]: "EC-HSM",
                    [Contract.AzureKeyVaultVaultKeyType.Oct]: "OCT",
                    [Contract.AzureKeyVaultVaultKeyType.OctHsm]: "OCT-HSM",
                    [Contract.AzureKeyVaultVaultKeyType.Rsa]: "RSA",
                    [Contract.AzureKeyVaultVaultKeyType.RsaHsm]: "RSA-HSM"
                }
            }));
    return (type: Contract.AzureKeyVaultVaultKeyType) =>
        localization[Contract.TypeNames.AzureKeyVaultVaultKeyType][type]();
}