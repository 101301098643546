import { IconText } from "@infrastructure";
import { SxProps, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import _ from "lodash";
import React, { Fragment, ReactNode } from "react";
import { ProfileInfoLine } from ".";
import { useTheme } from "../themes";

type ProfileTopbarProps = {
    icon: ReactNode;
    iconSx?: SxProps;
    infoChildren?: ReactNode[];
    subtitle?: string | ReactNode;
    title: string | ReactNode;
    titleInfo?: ReactNode;
};

export function ProfileTopbar({ icon, iconSx, infoChildren = [], subtitle, title, titleInfo }: ProfileTopbarProps) {
    const theme = useTheme();
    return (
        <Fragment>
            <Stack
                alignItems="center"
                direction="row"
                sx={{ justifyContent: "space-between" }}>
                <IconText
                    icon={icon}
                    iconSx={{
                        color: theme.palette.text.primary,
                        fontSize: "30px",
                        ...iconSx
                    }}
                    text={
                        <Stack>
                            {_.isString(title)
                                ? <Typography
                                    noWrap={true}
                                    sx={{ fontWeight: 500 }}
                                    variant="h2">
                                    {title}
                                </Typography>
                                : title}
                            {_.isNil(subtitle)
                                ? undefined
                                : _.isString(subtitle)
                                    ? <Typography
                                        noWrap={true}
                                        sx={{
                                            "&:first-letter": {
                                                textTransform: "capitalize"
                                            },
                                            color: theme.palette.text.secondary
                                        }}>
                                        {subtitle}
                                    </Typography>
                                    : subtitle}
                        </Stack>}/>
                {titleInfo}
            </Stack>
            {!_.isEmpty(infoChildren) &&
                <ProfileInfoLine>
                    {infoChildren}
                </ProfileInfoLine>}
        </Fragment>);
}