import { TimeSpanFormatter, useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../..";
import { Contract, InfoItem, useEntityTypeNameTranslator } from "../../../../../../../../../../common";
import { EntitiesInfoItem, Info } from "../../../../components";
import { AadDirectoryDirectoryRoleAssignmentResources } from "../../components";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";
import { useAadDefaultDirectoryEntityInfoItemElements } from "./useAadDefaultDirectoryEntityInfoItemElements";

export function useAadDirectoryDirectoryRoleManagementPolicyAssignmentDefinition(directoryEntityModel: Contract.AadDirectoryEntityModel, options?: DefinitionOptions) {
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(directoryEntityModel);
    const defaultDirectoryEntityInfoItemElements = useAadDefaultDirectoryEntityInfoItemElements(directoryEntityModel);
    const roleManagementPolicyAssignmentModel = directoryEntityModel as Contract.AadDirectoryDirectoryRoleManagementPolicyAssignmentModel;

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aad.useAadDirectoryDirectoryRoleManagementPolicyAssignmentDefinition",
            () => ({
                info: {
                    approvalRequired: {
                        false: "No",
                        title: "Requires Approval",
                        true: "Yes"
                    },
                    approverPrincipalReferences: "Approvers",
                    directoryRoleDefinitionId: "Role",
                    maxDuration: {
                        title: "Maximum Duration"
                    },
                    mfaEnabled: {
                        false: "Not enabled",
                        title: "MFA",
                        true: "Enabled"
                    },
                    principalReference: "Assigned To"
                },
                tabs: {
                    directoryRoleEligibilitySchedules: "Assignments"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs:
            [
                new EntityProfileDefinitionTab(
                    ProfileCategory.Iam,
                    <AadDirectoryDirectoryRoleAssignmentResources
                        csvExportFilePrefixes={[
                            entityTypeNameTranslator(roleManagementPolicyAssignmentModel.entity.typeName, { includeServiceName: false }),
                            roleManagementPolicyAssignmentModel.entity.displayName,
                            localization.tabs.directoryRoleEligibilitySchedules()
                        ]}
                        directoryRoleAssignmentResourceIds={roleManagementPolicyAssignmentModel.directoryRoleEligibilityScheduleIds}
                        variant="roleAndScopeAndPrincipal"/>,
                    localization.tabs.directoryRoleEligibilitySchedules(),
                    "directoryRoleEligibilitySchedules")
            ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultDirectoryEntityInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="maxDuration"
                        location="all"
                        title={localization.info.maxDuration.title()}
                        value={
                            <Typography noWrap={true}>
                                {TimeSpanFormatter.dayHourMinuteSecond((roleManagementPolicyAssignmentModel.entity as Contract.AadDirectoryDirectoryRoleManagementPolicyAssignment).activation.maxDuration)}
                            </Typography>}/>,
                    <InfoItem
                        key="mfaEnabled"
                        location="all"
                        title={localization.info.mfaEnabled.title()}
                        value={
                            <Typography noWrap={true}>
                                {roleManagementPolicyAssignmentModel.mfaEnabled
                                    ? localization.info.mfaEnabled.true()
                                    : localization.info.mfaEnabled.false()}
                            </Typography>}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={[roleManagementPolicyAssignmentModel.directoryRoleDefinitionId]}
                        entityTypeName={Contract.TypeNames.AadDirectoryDirectoryRoleDefinition}
                        key="directoryRoleDefinitionId"
                        location="all"
                        title={localization.info.directoryRoleDefinitionId()}/>,
                    <InfoItem
                        key="approvalRequired"
                        location="all"
                        title={localization.info.approvalRequired.title()}
                        value={
                            (roleManagementPolicyAssignmentModel.entity as Contract.AadDirectoryDirectoryRoleManagementPolicyAssignment).activation.approvalRequired
                                ? localization.info.approvalRequired.true()
                                : localization.info.approvalRequired.false()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={(roleManagementPolicyAssignmentModel.entity as Contract.AadDirectoryDirectoryRoleManagementPolicyAssignment).activation.approverPrincipalIds}
                        entityTypeName={Contract.TypeNames.AadDirectoryPrincipal}
                        key="approvers"
                        location="all"
                        title={localization.info.approverPrincipalReferences()}/>
                ]}
                options={options?.infoOptions}>
            </Info>
    });
}