import { EmptyMessageText, makeContextProvider, map, SearchTextField, StringHelper, Sx, useLocalization } from "@infrastructure";
import { ListProps, Stack, SxProps, useTheme } from "@mui/material";
import _ from "lodash";
import React, { ReactNode, useState } from "react";
import { CategorizedItems, DropdownContainer, InfiniteItems, Items, VerticalFillContainer, VerticalGrowContainer } from "./components";

export type SearchListProps =
    ListProps & {
        categories?: SearchListCategory[];
        dependencies?: any[];
        emptyMessageText?: EmptyMessageText;
        headerElement?: ReactNode;
        itemOptions?: SearchListItemOptions;
        items: any[];
        listOptions?: SearchListListOptions;
        onItemPageLoading?: (items: any[]) => Promise<void>;
        searchOptions?: SearchListSearchOptions;
        sorted?: boolean;
        variant?: "dropdown" | "verticalFill" | "verticalGrow";
    };

export class SearchListCategory {
    constructor(
        public id: string,
        public isItemIncluded: (item: any) => boolean,
        public options?: SearchListCategoryOptions) {
    }
}

export type SearchListCategoryOptions = {
    expandable?: boolean;
    headerElement?: ReactNode;
    initialExpand?: boolean;
    titleElement?: ReactNode;
};

export type SearchListItemOptions = {
    getDependencies?: (...args: any[]) => any[];
    getSearchValue?: (item: any) => string | string[];
    getSortValue?: (item: any) => string | any[];
    render?: (item: any) => ReactNode;
    spacing?: number;
};

type SearchListListOptions = {
    infinite?: boolean;
    sx?: SxProps;
};

type SearchListSearchOptions = {
    containerSx?: SxProps;
    enabled?: boolean;
    inputSx?: SxProps;
    itemMinCount?: number;
};

export class SearchListContext {
    constructor(
        public filtered: boolean,
        public filteredItems: any[],
        public orderedItems: any[]) {
    }
}

export const [useSearchListContext, , useSearchListContextProvider] = makeContextProvider<SearchListContext>();

export function SearchList({ categories, dependencies = [], emptyMessageText, headerElement, itemOptions, items, listOptions, onItemPageLoading, searchOptions, sorted = true, variant = "verticalFill", ...props }: SearchListProps) {
    const [searchText, setSearchText] = useState<string>();
    const [{ orderedItems }, , ContextProvider] =
        useSearchListContextProvider(
            () => {
                const orderedItems =
                    !sorted
                        ? items
                        : _.orderBy(
                            items,
                            item =>
                                _(itemOptions?.getSortValue?.(item) ?? item).
                                    concat().
                                    map(
                                        itemText =>
                                            _.isString(itemText)
                                                ? StringHelper.getSortValue(itemText)
                                                : itemText).
                                    value());
                const normalizedSearchText = StringHelper.normalize(searchText);
                return new SearchListContext(
                    !_.isEmpty(normalizedSearchText),
                    _.isEmpty(normalizedSearchText)
                        ? orderedItems
                        : _.filter(
                            orderedItems,
                            item =>
                                _(itemOptions?.getSearchValue?.(item) ?? itemOptions?.getSortValue?.(item) ?? item).
                                    concat().
                                    some(itemText => StringHelper.search(itemText, normalizedSearchText))),
                    orderedItems);
            },
            [items, searchText]);

    const localization =
        useLocalization(
            "infrastructure.searchList",
            () => ({
                search: "Search"
            }));
    const theme = useTheme();
    const Container =
        map(
            variant,
            {
                "dropdown": () => DropdownContainer,
                "verticalFill": () => VerticalFillContainer,
                "verticalGrow": () => VerticalGrowContainer
            });
    const searchEnabled =
        (searchOptions?.enabled ?? true) &&
        orderedItems.length >= (searchOptions?.itemMinCount ?? 10);
    const infiniteListItems =
        _.isNil(listOptions?.infinite)
            ? variant !== "verticalGrow"
            : listOptions!.infinite;
    const itemsSx =
        Sx.combine(
            props.sx,
            {
                maxHeight:
                    variant === "dropdown"
                        ? theme.spacing(50)
                        : undefined,
                paddingBottom:
                    !infiniteListItems || _.isNil(itemOptions?.spacing)
                        ? theme.spacing(0.5)
                        : undefined,
                paddingTop:
                    (!infiniteListItems || _.isNil(itemOptions?.spacing)) && !searchEnabled
                        ? theme.spacing(0.5)
                        : undefined
            });

    return (
        <ContextProvider>
            <Container
                listElement={
                    !_.isNil(categories) && categories.length > 1
                        ? <CategorizedItems
                            categories={categories}
                            dependencies={dependencies}
                            itemOptions={itemOptions}
                            listProps={props}
                            sx={itemsSx}/>
                        : !infiniteListItems
                            ? <Items
                                dependencies={dependencies}
                                emptyMessageText={emptyMessageText}
                                itemOptions={itemOptions}
                                listProps={props}
                                sx={itemsSx}/>
                            : <InfiniteItems
                                container={
                                    variant === "dropdown"
                                        ? "popup"
                                        : undefined}
                                dependencies={dependencies}
                                emptyMessageText={emptyMessageText}
                                itemOptions={itemOptions}
                                listSx={{ padding: 0 }}
                                sx={itemsSx}
                                onItemPageLoading={onItemPageLoading}/>}
                searchElement={
                    (searchEnabled || !_.isNil(headerElement)) && (
                        <Stack
                            spacing={1}
                            sx={{
                                padding: theme.spacing(0.5),
                                ...searchOptions?.containerSx
                            }}>
                            {searchEnabled && (
                                <SearchTextField
                                    inputSx={searchOptions?.inputSx}
                                    placeholder={localization.search()}
                                    searchText={searchText}
                                    onSearchTextChanged={searchText => setSearchText(searchText)}/>)}
                            {headerElement}
                        </Stack>)}
                sx={listOptions?.sx}/>
        </ContextProvider>);
}

export type SearchListItemsProps = {
    categories?: SearchListCategory[];
    dependencies: any[];
    emptyMessageText?: EmptyMessageText;
    itemOptions?: SearchListItemOptions;
    listProps: Omit<ListProps, "ref" | "onScroll">;
    sx?: SxProps;
};