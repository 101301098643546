import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { EntityExternalConsoleLink } from "../../components";
import { useGetAzureKeyVaultVaultRiskContext } from "../contexts";

export function useAzureKeyVaultVaultPrivateEndpointNotExistsRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AzureKeyVaultVaultPrivateEndpointNotExistsRisk;
    const vaultModel = entityModelStore.useGet(risk.entityId) as Contract.AzureKeyVaultVaultModel;

    const getAzureKeyVaultVaultRiskContext = useGetAzureKeyVaultVaultRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.compliance.useAzureKeyVaultVaultPrivateEndpointNotExistsRiskDefinition",
            () => ({
                description: "{{vault}} is not associated with private endpoint",
                sections: {
                    resolution: {
                        step1: "Click on the tab **Private endpoint connections**.",
                        step2: "Click **+ Create**.",
                        step3: "Follow the wizard and fill all relevant information required to create the private endpoint.",
                        step4: "To ensure your Key vault is secured, update your services to communicate with it through the private endpoint. Ensure that network traffic is functioning correctly to prevent any disruption to critical data flow.",
                        step5: "If public access is unnecessary, consider updating your firewall rules to allow access only from specific IP addresses and virtual networks that need access to the key vault, or completely disable public access."
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            vault:
                <Entity
                    entityIdOrModel={vaultModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={risk.entityId}
                key={risk.entityId}
                page={Contract.AzureConsoleEntityPage.Networking}/>,
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4(),
            localization.sections.resolution.step5()
        ],
        riskModel,
        () => {
            const vaultRiskContext = getAzureKeyVaultVaultRiskContext(vaultModel);
            return [
                vaultRiskContext.generalInformation,
                vaultRiskContext.sensitive,
                vaultRiskContext.allNetworkAccess,
                vaultRiskContext.wideRangeInboundSubnets,
                vaultRiskContext.vaultObjectsExist,
                vaultRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}