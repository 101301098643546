import { SxProps, Typography } from "@mui/material";
import { TOptions } from "i18next";
import React from "react";
import { Contract } from "../../../../..";
import { InlineEntitySelection, InlineTagsSelection } from "../..";

type ViewProps = {
    entityTypeName: string;
    riskPolicyConfiguration?: Contract.ResourceTagNotExistsRiskPolicyConfiguration;
    sx?: SxProps;
    templateTranslator: (options?: TOptions) => string;
};

export function View({ entityTypeName, riskPolicyConfiguration, sx, templateTranslator }: ViewProps) {
    return (
        <Typography
            component="span"
            sx={sx}>
            {templateTranslator({
                resource:
                    <InlineEntitySelection
                        entityTypeName={entityTypeName}
                        selection={{
                            allEntities: riskPolicyConfiguration?.allResources,
                            entityBuiltInAttributeTypeNames: riskPolicyConfiguration?.resourceBuiltInAttributeTypeNames,
                            entityCustomAttributeDefinitionIds: riskPolicyConfiguration?.resourceCustomAttributeDefinitionIds,
                            entityIds: riskPolicyConfiguration?.resourceIds,
                            entityTags: riskPolicyConfiguration?.resourceTags,
                            entityTypeNames: riskPolicyConfiguration?.resourceTypeNames
                        }}
                        sx={sx}/>,
                tag:
                    <InlineTagsSelection
                        selection={{
                            existsResourceTagKeys: riskPolicyConfiguration?.existsResourceTagKeys,
                            existsResourceTagPatternKey: riskPolicyConfiguration?.existsResourceTagPattern?.keyPattern,
                            existsResourceTagPatternValue: riskPolicyConfiguration?.existsResourceTagPattern?.valuePattern,
                            existsResourceTags: riskPolicyConfiguration?.existsResourceTags
                        }}
                        sx={sx}/>
            })}
        </Typography>);
}