﻿import { Tooltip, useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import React from "react";
import { Contract } from "../../../../common";
import { useOciObjectStorageBucketObjectAccessLevelTranslator } from "../../hooks";
import { AccessLevelIcon } from "./icons";

type OciObjectStorageBucketObjectAccessLevelProps = {
    objectAccessLevel: Contract.OciObjectStorageBucketObjectAccessLevel;
};

export function OciObjectStorageBucketObjectAccessLevel({ objectAccessLevel }: OciObjectStorageBucketObjectAccessLevelProps) {
    const bucketObjectAccessLevelTranslator = useOciObjectStorageBucketObjectAccessLevelTranslator();
    const localization =
        useLocalization(
            "tenants.oci.ociObjectStorageBucketObjectAccessLevel",
            () => ({
                tooltip: "GetObject only"
            }));
    return (
        <Stack
            alignItems="center"
            direction="row"
            spacing={1}>
            <Tooltip
                titleOrGetTitle={
                    objectAccessLevel === Contract.OciObjectStorageBucketObjectAccessLevel.PublicGet
                        ? localization.tooltip()
                        : undefined}>
                <Typography>
                    <AccessLevelIcon
                        accessLevel={objectAccessLevel}
                        sx={{ fontSize: "18px" }}/>
                </Typography>
            </Tooltip>
            <Typography noWrap={true}>
                {bucketObjectAccessLevelTranslator(objectAccessLevel)}
            </Typography>
        </Stack>);
}