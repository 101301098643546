﻿import { useLocalization } from "@infrastructure";
import React from "react";
import { useGcpCommonKubernetesResourceInfoItemElements, useGcpDefaultResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../..";
import { Contract } from "../../../../../../../../../../common";
import { KubernetesResourceDataHelper } from "../../../../../../utilities";
import { Info } from "../../../../components";
import { KubernetesRoleBindings } from "../../components";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";

export function useGcpKubernetesGroupDefinition(resourceModel: Contract.GcpResourceModel, options?: DefinitionOptions) {
    const commonKubernetesResourceInfoItemElements = useGcpCommonKubernetesResourceInfoItemElements(resourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultPropertyInfoElements = useGcpDefaultResourceInfoItemElements(resourceModel);
    const groupModel = resourceModel as Contract.GcpKubernetesGroupModel;
    const group = groupModel.entity as Contract.GcpKubernetesGroup;

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpKubernetesGroupDefinition",
            () => ({
                tabs: {
                    roleBindings: "Role Bindings"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <KubernetesRoleBindings principalOrRoleModel={groupModel}/>,
                localization.tabs.roleBindings(),
                "roleBindings")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultPropertyInfoElements}
                entityPropertyInfoItemElements={[commonKubernetesResourceInfoItemElements.clusterInfoItemElement]}
                options={options?.infoOptions}>
            </Info>,
        sensitive: KubernetesResourceDataHelper.entitySensitiveToggle(group.data)
    });
}