import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function OnboardingIcon(props: SvgIconProps) {
    return (
        <SvgIcon height="14" viewBox="0 0 14 14" width="14" {...props}>
            <path 
                clipRule="evenodd"
                d="M3.79167 1.74999C3.5596 1.74999 3.33704 1.84218 3.17295 2.00628C3.00885 2.17037 2.91667 2.39293 2.91667 2.62499V9.53033C3.18758 9.40183 3.48609 9.33333 3.79167 9.33333H11.0833V1.74999H3.79167ZM12.25 1.16666C12.25 0.844495 11.9888 0.583328 11.6667 0.583328H3.79167C3.25018 0.583328 2.73088 0.798432 2.34799 1.18132C1.9651 1.56421 1.75 2.08351 1.75 2.62499V11.375C1.75 11.9165 1.9651 12.4358 2.34799 12.8187C2.73088 13.2016 3.25018 13.4167 3.79167 13.4167H11.6667C11.9888 13.4167 12.25 13.1555 12.25 12.8333V1.16666ZM11.0833 10.5H3.79167C3.5596 10.5 3.33704 10.5922 3.17295 10.7563C3.00885 10.9204 2.91667 11.1429 2.91667 11.375C2.91667 11.6071 3.00885 11.8296 3.17295 11.9937C3.33704 12.1578 3.5596 12.25 3.79167 12.25H11.0833V10.5Z"
                fillRule="evenodd"/>
        </SvgIcon>);
}