import { DataTableSortType, useLocalization } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React, { useMemo } from "react";
import { Compliances, Contract, GroupedTableColumn, GroupedTableColumnRenderProps, RiskPolicyCategory, RiskPolicyTypeMetadataHelper, useRiskPolicyCategoryTranslator, useRiskPolicyTitleTranslator } from "../../../../../../../../../../../../common";
import { useBuiltInRiskPolicyConfigurationTypeNameToAnalysisGroupTypeToComplianceTypesMap } from "../../../../../../../../../Compliance/hooks";
import { RisksItemsFilterId } from "../../../../../../../Filters";
import { GroupedItem, GroupedTableColumnId } from "../../../../Grouped";
import { useCommonGroupedRiskColumns } from "../useCommonGroupedRiskColumns";
import { RiskPolicyConfigurationTypeName } from "./components";

export function useCommonGroupedRiskPolicyTypeColumns(): Dictionary<GroupedTableColumn> {
    const builtInRiskPolicyConfigurationTypeNameToAnalysisGroupTypeToComplianceTypesMap = useBuiltInRiskPolicyConfigurationTypeNameToAnalysisGroupTypeToComplianceTypesMap();
    const commonGroupedRiskColumns = useCommonGroupedRiskColumns();

    const localization =
        useLocalization(
            "views.customer.risks.items.grouped.hooks.useDefinition.hooks.useCommonGroupedRiskPolicyTypeColumns",
            () => ({
                complianceIdOrSectionTypes: "Compliance",
                riskPolicyCategory: "Category",
                riskPolicyConfigurationTypeName: "Policy"
            }));

    const riskPolicyCategoryTranslator = useRiskPolicyCategoryTranslator();
    const riskPolicyTitleTranslator = useRiskPolicyTitleTranslator();

    return useMemo(
        () => ({
            compliances: {
                element:
                    ({ item }: GroupedTableColumnRenderProps<GroupedItem>) => {
                        const riskPolicyTypeGroup = item.riskGroup as Contract.RiskControllerGetRiskGroupsResponseRiskPolicyTypeGroup;
                        return <Compliances
                            builtInComplianceTypes={
                                _.flatMap(
                                    riskPolicyTypeGroup.analysisGroupTypes,
                                    analysisGroupType => builtInRiskPolicyConfigurationTypeNameToAnalysisGroupTypeToComplianceTypesMap[riskPolicyTypeGroup.riskPolicyConfigurationTypeName]?.[analysisGroupType] ?? [])}
                            customComplianceIds={riskPolicyTypeGroup.customComplianceIds}
                            topItemsVariant="breakpoints"/>;
                    },
                filterExternalId: RisksItemsFilterId[Contract.RiskFilterId.ComplianceIdOrSectionType],
                id: GroupedTableColumnId.Compliances,
                minWidth: 160,
                title: localization.complianceIdOrSectionTypes(),
                width: 2
            },
            riskCount: {
                ...commonGroupedRiskColumns.riskCount,
                minWidth: 150,
                width: 2
            },
            riskPolicyCategory: {
                element:
                    ({ item }: GroupedTableColumnRenderProps<GroupedItem>) =>
                        <RiskPolicyCategory
                            riskPolicyCategory={RiskPolicyTypeMetadataHelper.get((item.riskGroup as Contract.RiskControllerGetRiskGroupsResponseRiskPolicyTypeGroup).riskPolicyConfigurationTypeName).category}
                            variant="risk"/>,
                filterExternalId: RisksItemsFilterId[Contract.RiskFilterId.RiskPolicyCategory],
                id: GroupedTableColumnId.RiskPolicyCategory,
                minWidth: 180,
                sortOptions: {
                    getIterates: () => [(item: GroupedItem) => riskPolicyCategoryTranslator(RiskPolicyTypeMetadataHelper.get((item.riskGroup as Contract.RiskControllerGetRiskGroupsResponseRiskPolicyTypeGroup).riskPolicyConfigurationTypeName).category)],
                    type: DataTableSortType.Alphabetic
                },
                title: localization.riskPolicyCategory(),
                width: 1.5
            },
            riskPolicyConfigurationTypeName: {
                element: RiskPolicyConfigurationTypeName,
                filterExternalId: RisksItemsFilterId[Contract.RiskFilterId.RiskPolicyConfigurationTypeNameOrId],
                id: GroupedTableColumnId.RiskPolicyConfigurationTypeName,
                minWidth: 230,
                sortOptions: {
                    getIterates: () => [(item: GroupedItem) => riskPolicyTitleTranslator((item.riskGroup as Contract.RiskControllerGetRiskGroupsResponseRiskPolicyTypeGroup).riskPolicyConfigurationTypeName)],
                    type: DataTableSortType.Alphabetic
                },
                title: localization.riskPolicyConfigurationTypeName(),
                width: 3
            },
            severity: {
                ...commonGroupedRiskColumns.severity,
                minWidth: 256
            }
        }),
        [builtInRiskPolicyConfigurationTypeNameToAnalysisGroupTypeToComplianceTypesMap]);
}