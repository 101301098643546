import { Action0, ActionMenuItem, ColumnHeader, FilterIcon, Menu, MenuItem, SeparatorMenuItem, TableColumnHeaderSortOptions, useLocalization, useSortMenuItems } from "@infrastructure";
import _ from "lodash";
import React, { memo, useMemo } from "react";
import { useTheme } from "../../..";

const RowHeaderColumnMemo = memo(RowHeaderColumn);
export { RowHeaderColumnMemo as RowHeaderColumn };

type RowHeaderColumnProps = {
    filterExternalOptions?: {
        active: boolean;
        onClick: Action0;
        visible: boolean;
    };
    sortOptions?: TableColumnHeaderSortOptions;
    title: string;
};

function RowHeaderColumn({ filterExternalOptions, sortOptions, title }: RowHeaderColumnProps) {
    const localization =
        useLocalization(
            "common.groupedTable.rowHeaderColumn",
            () => ({
                filters: {
                    add: "Add Filter"
                }
            }));

    const sortMenuItems = useSortMenuItems(sortOptions);
    const menuItems =
        useMemo(
            (): MenuItem[] =>
                _<MenuItem>([]).
                    concatIf(
                        !_.isNil(filterExternalOptions),
                        () =>
                            new ActionMenuItem(
                                () => filterExternalOptions!.onClick(),
                                localization.filters.add(),
                                { icon: <FilterIcon/> })).
                    concatIf(
                        sortMenuItems.length > 0,
                        () => [
                            !_.isNil(filterExternalOptions)
                                ? new SeparatorMenuItem()
                                : undefined,
                            ...sortMenuItems
                        ]).
                    value(),
            [filterExternalOptions, localization, sortMenuItems]);

    const theme = useTheme();
    return (
        <Menu
            itemsOrGetItems={menuItems}
            itemSx={{ minWidth: theme.spacing(15) }}
            variant="bottomLeft">
            <ColumnHeader
                containerSx={{ padding: 0 }}
                filterActive={filterExternalOptions?.active}
                sortDirection={sortOptions?.direction}
                title={title}/>
        </Menu>);
}