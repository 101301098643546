import React from "react";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { Contract } from "../../../../../../../../../../common";
import { Info } from "../../../../components";
import { useCommonContainerImageInfoItemElements } from "../useCommonContainerImageInfoItemElements";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";
import { useAwsCommonResourceInfoItemElements } from "./useAwsCommonResourceInfoItemElements";

export function useAwsContainerImageDefinition(resourceModel: Contract.AwsResourceModel, options?: DefinitionOptions) {
    const commonContainerImageInfoItemElements = useCommonContainerImageInfoItemElements(resourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const commonResourceInfoElements = useAwsCommonResourceInfoItemElements(resourceModel);

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={[
                    commonResourceInfoElements.typeNameInfoElement,
                    commonResourceInfoElements.tenantInfoItemElement,
                    commonResourceInfoElements.syncTimeInfoItemElement
                ]}
                entityPropertyInfoItemElements={[
                    commonContainerImageInfoItemElements.repositoryInfoItemElement,
                    commonContainerImageInfoItemElements.tagsInfoItemElement,
                    commonContainerImageInfoItemElements.digestInfoItemElement,
                    commonContainerImageInfoItemElements.creationTimeInfoItemElement,
                    commonContainerImageInfoItemElements.operatingSystemTypeInfoItemElement,
                    commonContainerImageInfoItemElements.operatingSystemInfoItemElement,
                    commonContainerImageInfoItemElements.vulnerabilitiesInfoItemElement,
                    commonContainerImageInfoItemElements.scanTimeInfoItemElement,
                    commonContainerImageInfoItemElements.workloadClusterResourceIdsInfoItemElement,
                    commonContainerImageInfoItemElements.virtualMachineIdsInfoItemElement,
                    commonContainerImageInfoItemElements.kubernetesWorkloadResourceIdsInfoItemElement
                ]}
                options={options?.infoOptions}>
            </Info>
    });
}