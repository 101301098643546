import { useLocalization } from "@infrastructure";
import { Stack } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract, InfoCard, InfoItem } from "../../../../../../../../../../../../common";

type CredentialsProps = {
    rootUser: Contract.AwsIamRootUser;
};

export function Credentials({ rootUser }: CredentialsProps) {
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsIamRootUserDefinition.credentials",
            () => ({
                items: {
                    accessKeys: {
                        accessKey: {
                            false: "Disabled",
                            status: "Status",
                            true: "Enabled",
                            usageTime: {
                                text: "{{usageTime | TimeFormatter.profileFormatDateTime}}",
                                title: "Usage Time"
                            }
                        },
                        empty: "None",
                        key1Name: "Access Key 1",
                        key2Name: "Access Key 2",
                        title: "Access Keys"
                    },
                    mfaEnabled: {
                        false: "Disabled",
                        status: "Status",
                        title: "MFA",
                        true: "Enabled"
                    },
                    password: {
                        title: "Password",
                        usageTime: {
                            text: "{{usageTime | TimeFormatter.profileFormatDateTime}}",
                            title: "Usage Time"
                        }
                    }
                }
            }));

    return (
        <Stack>
            <InfoCard title={localization.items.password.title()}>
                <InfoItem
                    title={localization.items.password.usageTime.title()}
                    value={
                        _.isNil(rootUser.passwordUsageTime)
                            ? undefined
                            : localization.items.password.usageTime.text({ usageTime: rootUser.passwordUsageTime })}/>
            </InfoCard>
            <InfoCard title={localization.items.mfaEnabled.title()}>
                <InfoItem
                    title={localization.items.mfaEnabled.status()}
                    value={
                        rootUser.mfaEnabled
                            ? localization.items.mfaEnabled.true()
                            : localization.items.mfaEnabled.false()}/>
            </InfoCard>
            <InfoCard title={localization.items.accessKeys.key1Name()}>
                <InfoItem
                    title={localization.items.accessKeys.accessKey.status()}
                    value={
                        rootUser.accessKey1Enabled
                            ? localization.items.accessKeys.accessKey.true()
                            : localization.items.accessKeys.accessKey.false()}/>
                <InfoItem
                    title={localization.items.accessKeys.accessKey.usageTime.title()}
                    value={
                        _.isNil(rootUser.accessKey1UsageTime)
                            ? undefined
                            : localization.items.accessKeys.accessKey.usageTime.text({ usageTime: rootUser.accessKey1UsageTime })}/>
            </InfoCard>
            <InfoCard title={localization.items.accessKeys.key2Name()}>
                <InfoItem
                    title={localization.items.accessKeys.accessKey.status()}
                    value={
                        rootUser.accessKey2Enabled
                            ? localization.items.accessKeys.accessKey.true()
                            : localization.items.accessKeys.accessKey.false()}/>
                <InfoItem
                    title={localization.items.accessKeys.accessKey.usageTime.title()}
                    value={
                        _.isNil(rootUser.accessKey2UsageTime)
                            ? undefined
                            : localization.items.accessKeys.accessKey.usageTime.text({ usageTime: rootUser.accessKey2UsageTime })}/>
            </InfoCard>
        </Stack>);
}