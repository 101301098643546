import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../../../common";
import { RiskDefinitionSection } from "../../../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../../../useCommonSectionsAndDescriptionDefinition";
import { EntityExternalConsoleLink } from "../../../../components";
import { useGetGcpPubSubSubscriptionRiskContext, useGetGcpScopeResourcePublicAccessExistsRiskContext } from "../../../contexts";
import { ScopeResourceRoleBindingTableSection } from "../components";

export function useGcpPubSubSubscriptionPublicAccessExistsRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.GcpPubSubSubscriptionPublicAccessExistsRisk;
    const subscriptionModel = entityModelStore.useGet(risk.entityId) as Contract.GcpPubSubSubscriptionModel;

    const getGcpPubSubSubscriptionRiskContext = useGetGcpPubSubSubscriptionRiskContext();
    const getGcpScopeResourcePublicAccessExistsRiskContext = useGetGcpScopeResourcePublicAccessExistsRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.publicAccess.hooks.useGcpPubSubSubscriptionPublicAccessExistsRiskDefinition",
            () => ({
                description: "{{subscription}} is publicly accessible via IAM roles",
                sections: {
                    resolution: {
                        step1: "Before changing the permissions, verify that identities can access the subscription without public access",
                        step2: "Click on **SHOW INFO PANEL** if not already shown",
                        step3: "Delete the role bindings to allUsers / allAuthenticatedUsers"
                    },
                    roleBindingIds: "Role Bindings"
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            subscription:
                <Entity
                    entityIdOrModel={subscriptionModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={risk.entityId}
                key={risk.entityId}
                page={Contract.GcpConsolePage.Resource}/>,
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3()
        ],
        riskModel,
        () => {
            const subscriptionRiskContext = getGcpPubSubSubscriptionRiskContext(subscriptionModel);
            const scopeResourcePublicAccessExistsRiskContext = getGcpScopeResourcePublicAccessExistsRiskContext(risk);
            return [
                subscriptionRiskContext.generalInformation,
                subscriptionRiskContext.sensitive,
                subscriptionRiskContext.topic,
                subscriptionRiskContext.messageDeliveryType,
                scopeResourcePublicAccessExistsRiskContext.permissionActions,
                subscriptionRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        },
        {
            sections: [
                new RiskDefinitionSection(
                    <ScopeResourceRoleBindingTableSection
                        csvExportFilePrefixes={[localization.sections.roleBindingIds()]}
                        risk={risk}/>,
                    localization.sections.roleBindingIds())
            ]
        });
}