﻿import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, DeferredFilter, EnumValuesFilter, Optional, optionalTableCell, TimeFormatter, TimeRangeFilter, useLocalization, ValueFilter } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, EntityTypeFilter, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { useAzureAuthorizationRoleAssignmentResourceStatusTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";
import { EntityTableDefinition } from "../../useDefinition";
import { useAzureCommonResourceDefinition } from "./useAzureCommonResourceDefinition";

export function useAzureAuthorizationRoleAssignmentResourceDefinition(definitionData: DefinitionData) {
    const commonResourceDefinition = useAzureCommonResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();
    const roleAssignmentResourceModelFiltersPromise = definitionData.entityModelFiltersPromise as Promise<Contract.AzureAuthorizationRoleAssignmentResourceModelFilters>;

    const roleAssignmentResourceStatusTranslator = useAzureAuthorizationRoleAssignmentResourceStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.azure.useAzureAuthorizationRoleAssignmentResourceDefinition",
            () => ({
                columns: {
                    containingRoleAssignmentResourceIds: {
                        helpText: "The role assignment is \"covered\" by these role assignments, which may come from a higher scope level or group membership",
                        title: "Covered By"
                    },
                    endTime: "End Time",
                    principalIdReference: "Principal",
                    roleDefinitionId: "Role",
                    roleManagementPolicyAssignmentId: "PIM Role Settings",
                    scopeEntityIdReference: "Scope",
                    scopeEntityTypeName: "Scope Type",
                    status: "Type"
                }
            }));

    return new EntityTableDefinition(
        [
            commonResourceDefinition.columns.azureTenantColumn,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.AzureAuthorizationRoleAssignmentResourceModel>(
                        Contract.TypeNames.AzureAuthorizationRoleDefinitionType,
                        item => [(item.entity as Contract.AzureAuthorizationRoleAssignmentResource).roleDefinitionId],
                        localization.columns.roleDefinitionId())}
                filterOptions={{
                    itemOrItems: {
                        default: true,
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AzureAuthorizationRoleAssignmentResourceRoleDefinition)}
                                placeholder={localization.columns.roleDefinitionId()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AzureAuthorizationRoleAssignmentResourceRoleDefinition}
                key={Contract.EntityModelProperty.AzureAuthorizationRoleAssignmentResourceRoleDefinition}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AzureAuthorizationRoleAssignmentResourceModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={(item.entity as Contract.AzureAuthorizationRoleAssignmentResource).roleDefinitionId}
                            entityTypeName={Contract.TypeNames.AzureAuthorizationRoleDefinition}
                            entityVariant="iconText"/>}
                title={localization.columns.roleDefinitionId()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.AzureAuthorizationRoleAssignmentResourceModel>(
                        Contract.TypeNames.AzureEntity,
                        item => [item.scopeEntityIdReference],
                        localization.columns.scopeEntityIdReference())}
                filterOptions={{
                    itemOrItems: {
                        default: true,
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AzureAuthorizationRoleAssignmentResourceScopeEntity)}
                                placeholder={localization.columns.scopeEntityIdReference()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AzureAuthorizationRoleAssignmentResourceScopeEntity}
                key={Contract.EntityModelProperty.AzureAuthorizationRoleAssignmentResourceScopeEntity}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AzureAuthorizationRoleAssignmentResourceModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.scopeEntityIdReference}
                            entityTypeName={Contract.TypeNames.AzureEntity}
                            entityVariant="iconText"/>}
                title={localization.columns.scopeEntityIdReference()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.AzureAuthorizationRoleAssignmentResourceModel>(
                        Contract.TypeNames.AadDirectoryPrincipal,
                        item => [item.principalIdReference],
                        localization.columns.principalIdReference())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AzureAuthorizationRoleAssignmentResourcePrincipal)}
                                placeholder={localization.columns.principalIdReference()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AzureAuthorizationRoleAssignmentResourcePrincipal}
                key={Contract.EntityModelProperty.AzureAuthorizationRoleAssignmentResourcePrincipal}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AzureAuthorizationRoleAssignmentResourceModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.principalIdReference}
                            entityTypeName={Contract.TypeNames.AadDirectoryPrincipal}
                            entityVariant="iconTextTypeTenant"/>}
                title={localization.columns.principalIdReference()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AzureAuthorizationRoleAssignmentResourceModel) => ({
                            [localization.columns.status()]: roleAssignmentResourceStatusTranslator((item.entity as Contract.AzureAuthorizationRoleAssignmentResource).status)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.AzureAuthorizationRoleAssignmentResourceStatus}
                                enumTypeTranslator={roleAssignmentResourceStatusTranslator}
                                placeholder={localization.columns.status()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AzureAuthorizationRoleAssignmentResourceStatus}
                itemProperty={(item: Contract.AzureAuthorizationRoleAssignmentResourceModel) => roleAssignmentResourceStatusTranslator((item.entity as Contract.AzureAuthorizationRoleAssignmentResource).status)}
                key={Contract.EntityModelProperty.AzureAuthorizationRoleAssignmentResourceStatus}
                title={localization.columns.status()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AzureAuthorizationRoleAssignmentResourceModel) => ({
                            [localization.columns.endTime()]:
                                _.isNil(item.endTime)
                                    ? "-"
                                    : TimeFormatter.iso8601String(item.endTime)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <DeferredFilter
                                promiseOrGetPromise={roleAssignmentResourceModelFiltersPromise}
                                title={localization.columns.endTime()}>
                                {filters =>
                                    <TimeRangeFilter
                                        emptyValue={filters.endTimeRange.emptyValue}
                                        placeholder={localization.columns.endTime()}
                                        timeRange={TimeRangeHelper.getTimeRangeFilterRange(filters.endTimeRange.timeRange)}/>}
                            </DeferredFilter>
                    }
                }}
                id={Contract.EntityModelProperty.AzureAuthorizationRoleAssignmentResourceEndTime}
                key={Contract.EntityModelProperty.AzureAuthorizationRoleAssignmentResourceEndTime}
                render={
                    optionalTableCell<Contract.AzureAuthorizationRoleAssignmentResourceModel>(
                        item =>
                            _.isNil(item.endTime)
                                ? undefined
                                : TimeFormatter.monthDayAndYear(item.endTime))}
                sortOptions={{ type: DataTableSortType.Date }}
                title={localization.columns.endTime()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.AzureAuthorizationRoleAssignmentResourceModel>(
                        Contract.TypeNames.AzureAuthorizationRoleManagementPolicyAssignment,
                        item => [item.roleManagementPolicyAssignmentId],
                        localization.columns.roleManagementPolicyAssignmentId())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AzureAuthorizationRoleAssignmentResourceRoleManagementPolicyAssignment)}
                                placeholder={localization.columns.roleManagementPolicyAssignmentId()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AzureAuthorizationRoleAssignmentResourceRoleManagementPolicyAssignment}
                key={Contract.EntityModelProperty.AzureAuthorizationRoleAssignmentResourceRoleManagementPolicyAssignment}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AzureAuthorizationRoleAssignmentResourceModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.roleManagementPolicyAssignmentId}
                            entityTypeName={Contract.TypeNames.AzureAuthorizationRoleManagementPolicyAssignment}
                            entityVariant="iconText"/>}
                selectorOptions={{ default: false }}
                title={localization.columns.roleManagementPolicyAssignmentId()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.AzureAuthorizationRoleAssignmentResourceModel>(
                        Contract.TypeNames.AzureAuthorizationRoleAssignmentResource,
                        item => item.containingRoleAssignmentResourceIds,
                        localization.columns.containingRoleAssignmentResourceIds.title())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <ValueFilter
                                items={[
                                    {
                                        value: true
                                    }
                                ]}
                                placeholder={localization.columns.containingRoleAssignmentResourceIds.title()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AzureAuthorizationRoleAssignmentResourceContainingRoleAssignmentResources}
                key={Contract.EntityModelProperty.AzureAuthorizationRoleAssignmentResourceContainingRoleAssignmentResources}
                message={localization.columns.containingRoleAssignmentResourceIds.helpText()}
                messageLevel="info"
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AzureAuthorizationRoleAssignmentResourceModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.containingRoleAssignmentResourceIds}
                            entityTypeName={Contract.TypeNames.AzureAuthorizationRoleAssignmentResource}
                            entityVariant="iconText"/>}
                selectorOptions={{ default: false }}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.containingRoleAssignmentResourceIds.title()}/>,
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        element:
                            <DeferredFilter
                                promiseOrGetPromise={roleAssignmentResourceModelFiltersPromise}
                                title={localization.columns.scopeEntityTypeName()}>
                                {filters =>
                                    <EntityTypeFilter
                                        emptyValue={false}
                                        entityTypeNames={filters.scopeEntityTypeNameItems.items}
                                        placeholder={localization.columns.scopeEntityTypeName()}/>}
                            </DeferredFilter>
                    }
                }}
                id={Contract.EntityModelProperty.AzureAuthorizationRoleAssignmentResourceScopeEntityTypeName}
                key={Contract.EntityModelProperty.AzureAuthorizationRoleAssignmentResourceScopeEntityTypeName}
                sortOptions={{ enabled: false }}
                title={localization.columns.scopeEntityTypeName()}/>,
            commonResourceDefinition.columns.attributesColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: _.Dictionary<any>, limit: number, propertyFilterMap: _.Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAzureAuthorizationRoleAssignmentResourceRequest(
                new Contract.EntityControllerGetEntityModelPageAzureAuthorizationRoleAssignmentResourceRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    filterMap[Contract.EntityModelProperty.AzureAuthorizationRoleAssignmentResourceContainingRoleAssignmentResources] as Optional<boolean>,
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.AzureAuthorizationRoleAssignmentResourceEndTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureAuthorizationRoleAssignmentResourcePrincipal]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureAuthorizationRoleAssignmentResourceRoleDefinition]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureAuthorizationRoleAssignmentResourceRoleManagementPolicyAssignment]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureAuthorizationRoleAssignmentResourceScopeEntity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureAuthorizationRoleAssignmentResourceScopeEntityTypeName]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureAuthorizationRoleAssignmentResourceStatus])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(undefined)));
}