﻿import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useAwsEksClusterNodeGroupStatusTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useAwsEksClusterNodeGroupStatusTranslator",
            () => ({
                [Contract.TypeNames.AwsEksClusterNodeGroupStatus]: {
                    [Contract.AwsEksClusterNodeGroupStatus.Active]: "Active",
                    [Contract.AwsEksClusterNodeGroupStatus.CreateFailed]: "Create Failed",
                    [Contract.AwsEksClusterNodeGroupStatus.Creating]: "Creating",
                    [Contract.AwsEksClusterNodeGroupStatus.Degraded]: "Degraded",
                    [Contract.AwsEksClusterNodeGroupStatus.Deleting]: "Deleting",
                    [Contract.AwsEksClusterNodeGroupStatus.DeleteFailed]: "Delete Failed",
                    [Contract.AwsEksClusterNodeGroupStatus.Updating]: "Updating"
                }
            }));
    return (status: Contract.AwsEksClusterNodeGroupStatus) =>
        localization[Contract.TypeNames.AwsEksClusterNodeGroupStatus][status]();
}