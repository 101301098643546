import { FormLayout, makeContextProvider, Optional, OrderedWizard, OrderedWizardItem, useExecuteOperation, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, TenantController, tenantModelStore } from "../../../../../../../../../../common";
import { TenantModelHelper } from "../../../../../../utilities";
import { AddOrEditComponentProps } from "../../../../useAddOrEditDefinition";
import { AadTenantItem, FinishItem, PermissionsItem, RoleDefinitionsItem, TenantItem } from "./components";

class AddOrEditContext {
    public rawShortId?: string;
    public tenantPermissionTypes?: Contract.CloudProviderTenantPermissionType[];
    public tenantTerraformStateStorageAccountBlobContainerEnabled: boolean;
    public updatedTenantModel?: Contract.AzureTenantModel;

    constructor(
        public aadTenantModel: Optional<Contract.AadTenantModel>,
        public parentFolderId: string,
        public tenantOnboardingInfo: Contract.TenantControllerGetAzureTenantOnboardingInfoResponse,
        public tenantModel?: Optional<Contract.AzureTenantModel>) {
        this.tenantPermissionTypes = TenantModelHelper.getTenantModelPermissions(tenantModel).configurationPermissionTypes;
        this.rawShortId = tenantModel?.configuration.rawShortId;
        this.tenantTerraformStateStorageAccountBlobContainerEnabled = !_.isEmpty(tenantModel?.state.codeAnalysis.terraformStateStorageAccountNameToBlobContainerNamesMap);
    }
}

export const [useAddOrEditContext, useSetAddOrEditContext, useAddOrEditContextProvider] = makeContextProvider<AddOrEditContext>();

export function AddOrEdit({ onClose, parentFolderId, scopeNodeModel, startItemIndex }: AddOrEditComponentProps) {
    const tenantModel = tenantModelStore.useGet(scopeNodeModel?.configuration.id) as Optional<Contract.AzureTenantModel>;
    const aadTenantModel = tenantModelStore.useGet(tenantModel?.configuration.aadTenantId) as Optional<Contract.AadTenantModel>;

    const [tenantOnboardingInfo] =
        useExecuteOperation(
            AddOrEdit,
            TenantController.getAzureTenantOnboardingInfo);

    const [, , ContextProvider] =
        useAddOrEditContextProvider(
            () =>
                new AddOrEditContext(
                    aadTenantModel,
                    parentFolderId,
                    tenantOnboardingInfo,
                    tenantModel));
    const localization =
        useLocalization(
            "views.customer.scopes.hooks.useDefinition.hooks.useAzureDefinition.addOrEdit",
            () => ({
                steps: {
                    aadTenantItem: "Select Microsoft Entra ID Tenant",
                    permissionsItem: "Permissions",
                    roleDefinitionsItem: "Assign Roles",
                    tenantItem: "Provide Subscription ID"
                },
                title: {
                    add: "Add Subscription",
                    edit: "Edit Subscription - {{tenantName}}"
                }
            }));

    return (
        <ContextProvider>
            <FormLayout
                disableContentPadding={true}
                titleOptions={{
                    text:
                        _.isNil(tenantModel)
                            ? localization.title.add()
                            : localization.title.edit({ tenantName: tenantModel.configuration.name })
                }}>
                <OrderedWizard
                    finishItemElement={<FinishItem/>}
                    startItemIndex={
                        startItemIndex ??
                        (_.isNil(tenantModel)
                            ? undefined
                            : 2)}
                    onClose={onClose}>
                    <OrderedWizardItem
                        deferredLoading={true}
                        title={localization.steps.aadTenantItem()}>
                        <AadTenantItem/>
                    </OrderedWizardItem>
                    <OrderedWizardItem
                        deferredLoading={true}
                        title={localization.steps.tenantItem()}>
                        <TenantItem/>
                    </OrderedWizardItem>
                    <OrderedWizardItem title={localization.steps.permissionsItem()}>
                        <PermissionsItem/>
                    </OrderedWizardItem>
                    <OrderedWizardItem title={localization.steps.roleDefinitionsItem()}>
                        <RoleDefinitionsItem/>
                    </OrderedWizardItem>
                </OrderedWizard>
            </FormLayout>
        </ContextProvider>);
}