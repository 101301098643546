import { Dialog, FormLayout, useLocalization } from "@infrastructure";
import { Button, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { Fragment, ReactNode, useState } from "react";

export type TicketingServicePromptProps = {
    children: ReactNode;
    message?: TicketingServicePromptMessage;
    onCancel: () => void;
};

export type TicketingServicePromptMessage = {
    description: string;
    title: string;
};

export function TicketingServicePrompt({ children, message, onCancel }: TicketingServicePromptProps) {
    const localization =
        useLocalization(
            "common.ticketingServicePrompt",
            () => ({
                actions: {
                    accept: "Continue",
                    cancel: "Cancel"
                }
            }));
    const [open, setOpen] = useState(!_.isNil(message));
    return (
        <Fragment>
            {open
                ? <Dialog
                    variant="editor"
                    onClose={onCancel}>
                    <FormLayout
                        footerOptions={{
                            contentElement:
                                <Stack
                                    alignItems="center"
                                    direction="row"
                                    justifyContent="flex-end"
                                    spacing={1}
                                    sx={{ width: "100%" }}>
                                    <Button
                                        variant="outlined"
                                        onClick={onCancel}>
                                        {localization.actions.cancel()}
                                    </Button>
                                    <Button onClick={() => setOpen(false)}>
                                        {localization.actions.accept()}
                                    </Button>
                                </Stack>
                        }}
                        titleOptions={{ text: message!.title }}>
                        <Typography sx={{ whiteSpace: "pre-wrap" }}>
                            {message!.description}
                        </Typography>
                    </FormLayout>
                </Dialog>
                : children}
        </Fragment>);
}