import { OperationStore } from "@infrastructure";
import { Contract, RiskController } from "..";

export const cloudRiskPolicyItemsOperationStore =
    new OperationStore(
        async (childScopeCustomRiskPolicies: boolean, scopeId: string) => {
            const [getRiskPoliciesResponse, getRiskPoliciesStatsResponse, getRiskPolicyFailedEntityCountResponse] =
                await Promise.all([
                    RiskController.getRiskPolicies(
                        new Contract.RiskControllerGetCloudRiskPoliciesRequest(
                            scopeId,
                            childScopeCustomRiskPolicies)),
                    RiskController.getRiskPolicySummary(new Contract.RiskControllerGetCloudRiskPolicySummaryRequest(scopeId)),
                    RiskController.getRiskPolicyFailedEntityCount(new Contract.RiskControllerGetRiskPolicyFailedEntityCountRequest(scopeId))
                ]);

            return {
                ...getRiskPoliciesResponse,
                ...getRiskPoliciesStatsResponse,
                ...getRiskPolicyFailedEntityCountResponse
            };
        });