import { Action0, FormLayout, Message, StringHelper, useInputValidation, useLocalization } from "@infrastructure";
import { Box, Button, CircularProgress, FormControl, FormHelperText, Stack, TextField } from "@mui/material";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import { Contract, FolderController, ScopeHelper, scopeNodeModelStore, useTheme } from "../../../../../common";

type AddOrEditFolderProps = {
    folderScopeNodeModel?: Contract.ScopeNodeModel;
    onSave: Action0;
    parentFolderId: string;
};

export function AddOrEditFolder({ folderScopeNodeModel, onSave, parentFolderId }: AddOrEditFolderProps) {
    const scopeNodeModels = scopeNodeModelStore.useGetAll();
    const normalizedScopeFolderNames =
        useMemo(
            () =>
                _(scopeNodeModels).
                    filter(
                        currentScopeNodeModel =>
                            ScopeHelper.isFolder(currentScopeNodeModel) &&
                            currentScopeNodeModel.configuration.id !== folderScopeNodeModel?.configuration.id &&
                            (currentScopeNodeModel.configuration as Contract.FolderConfiguration).parentScopeId === parentFolderId).
                    map(currentScopeNodeModel => StringHelper.normalize(currentScopeNodeModel.configuration.name)).
                    value(),
            [parentFolderId, scopeNodeModels]);
    const [name, setName] = useState(folderScopeNodeModel?.configuration.name ?? "");

    const [saveExecuting, setSaveExecuting] = useState(false);
    const [saveError, setSaveError] = useState(false);

    async function save() {
        setSaveExecuting(true);
        setSaveError(false);

        try {
            const { folderScopeNodeModel: updatedFolderScopeNodeModel } =
                _.isNil(folderScopeNodeModel)
                    ? await FolderController.insertFolder(
                        new Contract.FolderControllerInsertFolderRequest(
                            name,
                            parentFolderId))
                    : await FolderController.updateFolder(
                        new Contract.FolderControllerUpdateFolderRequest(
                            folderScopeNodeModel.configuration.id,
                            name));
            await scopeNodeModelStore.notify(updatedFolderScopeNodeModel);
            onSave();
        } catch {
            setSaveError(true);
        }

        setSaveExecuting(false);
    }

    const localization =
        useLocalization(
            "views.customer.scopes.addOrEditFolder",
            () => ({
                actions: {
                    add: {
                        error: "Failed to add folder",
                        title: "Add"
                    },
                    edit: {
                        error: "Failed to update folder",
                        title: "Update"
                    }
                },
                fields: {
                    name: {
                        error: {
                            exists: "Name already exists",
                            length: "Name length cannot be more than 1000 characters",
                            required: "Name cannot be empty"
                        },
                        title: "Folder name"
                    }
                },
                title: {
                    add: "Add a new folder",
                    edit: "Edit folder"
                }
            }));

    const [nameValidationController, nameValidationMessage] =
        useInputValidation(
            () => {
                const normalizedName = StringHelper.normalize(name);
                if (_.isEmpty(normalizedName)) {
                    return localization.fields.name.error.required();
                }
                if (_.includes(normalizedScopeFolderNames, normalizedName)) {
                    return localization.fields.name.error.exists();
                }
                if (normalizedName!.length > 1000) {
                    return localization.fields.name.error.length();
                }

                return undefined;
            },
            [name]);

    const valid =
        useMemo(
            () => nameValidationController.isValid(),
            [name]);

    const theme = useTheme();
    return (
        <FormLayout
            footerOptions={{
                contentElement:
                    <Stack
                        alignItems="center"
                        direction="row"
                        justifyContent="flex-end"
                        spacing={1}>
                        <Box sx={{ flex: 1 }}>
                            {saveError && (
                                <Message
                                    level="error"
                                    title={
                                        _.isNil(folderScopeNodeModel)
                                            ? localization.actions.add.error()
                                            : localization.actions.edit.error()}/>)}
                        </Box>
                        {saveExecuting && (
                            <CircularProgress
                                size={theme.spacing(2)}
                                variant="indeterminate"/>)}
                        <Button
                            disabled={!valid || saveExecuting}
                            onClick={() => save()}>
                            {_.isNil(folderScopeNodeModel)
                                ? localization.actions.add.title()
                                : localization.actions.edit.title()}
                        </Button>
                    </Stack>
            }}
            titleOptions={{
                text:
                    _.isNil(folderScopeNodeModel)
                        ? localization.title.add()
                        : localization.title.edit()
            }}>
            <FormControl
                fullWidth={true}
                variant="standard">
                <TextField
                    autoFocus={true}
                    disabled={saveExecuting}
                    label={localization.fields.name.title()}
                    value={name}
                    variant="outlined"
                    onChange={event => setName(event.target.value)}/>
                {!_.isNil(nameValidationMessage) && (
                    <FormHelperText error={true}>{nameValidationMessage}</FormHelperText>)}
            </FormControl>
        </FormLayout>);
}