import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useAadDirectoryServicePrincipalTypeTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useAadDirectoryServicePrincipalTypeTranslator",
            () => ({
                [Contract.TypeNames.AadDirectoryServicePrincipalType]: {
                    [Contract.AadDirectoryServicePrincipalType.Enterprise]: "Enterprise",
                    [Contract.AadDirectoryServicePrincipalType.Legacy]: "Legacy",
                    [Contract.AadDirectoryServicePrincipalType.MicrosoftFirstParty]: "Microsoft",
                    [Contract.AadDirectoryServicePrincipalType.ManagedIdentity]: "Managed identity",
                    [Contract.AadDirectoryServicePrincipalType.Other]: "Other",
                    [Contract.AadDirectoryServicePrincipalType.SocialIdentityProvider]: "Social identity provider"
                }
            }));
    return (type: Contract.AadDirectoryServicePrincipalType) =>
        localization[Contract.TypeNames.AadDirectoryServicePrincipalType][type]();
}