import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function SnsTopicIcon(props: SvgIconProps) {
    return (
        <SvgIcon
            {...props}>
            <path d="M9.5 1L16 3.72728V7.81819C16 8.43191 15.9267 8.69794 15.7882 9.19123C15.5703 9.2171 13.6211 9.21094 12.9266 9.19123C13.3478 8.07261 13.059 7.0327 12.0567 6.08637C10.6483 4.75682 8.35889 4.75682 6.95055 6.08637C5.54222 7.41591 5.54222 9.57728 6.95055 10.9068C7.78212 11.6918 8.9182 12.0163 9.99943 11.876V16.0625C9.83478 16.1121 9.67746 16.0409 9.5 16C5.85803 15.1604 3.12655 11.7618 3.00428 8.07579L3 7.81819V3.72728L9.5 1ZM9.5 6.45455C10.6966 6.45455 11.6667 7.37033 11.6667 8.5C11.6667 9.62968 10.6966 10.5455 9.5 10.5455C8.30338 10.5455 7.33333 9.62968 7.33333 8.5C7.33333 7.37033 8.30338 6.45455 9.5 6.45455Z"/>
            <path
                clipRule="evenodd"
                d="M21 10.875H12.125V12.625H21V10.875ZM12.125 9.875C11.5727 9.875 11.125 10.3227 11.125 10.875V12.625C11.125 13.1773 11.5727 13.625 12.125 13.625H21C21.5523 13.625 22 13.1773 22 12.625V10.875C22 10.3227 21.5523 9.875 21 9.875H12.125Z"
                fillRule="evenodd"/>
            <path d="M21.3338 17.9372H20.4883V17.0917H20.911V16.6689H21.7565V17.5144C21.7565 17.6265 21.712 17.7341 21.6327 17.8133C21.5534 17.8926 21.4459 17.9372 21.3338 17.9372Z"/>
            <path d="M19.7024 17.9373H18.1256V17.0918H19.7024V17.9373ZM17.3393 17.9373H15.7625V17.0918H17.3393V17.9373ZM14.9762 17.9373H13.3994V17.0918H14.9762V17.9373Z"/>
            <path d="M12.4567 17.9372H11.6112C11.4991 17.9372 11.3916 17.8926 11.3123 17.8133C11.233 17.7341 11.1885 17.6265 11.1885 17.5144V16.6689H12.0339V17.0917H12.4567V17.9372Z"/>
            <path d="M12.0339 15.6881H11.1885V14.8427C11.1885 14.7305 11.233 14.623 11.3123 14.5437C11.3916 14.4645 11.4991 14.4199 11.6112 14.4199H12.4567V15.2654H12.0339V15.6881Z"/>
            <path d="M19.7024 15.2654H18.1256V14.4199H19.7024V15.2654ZM17.3351 15.2654H15.7625V14.4199H17.3182L17.3351 15.2654ZM14.972 15.2654H13.3994V14.4199H14.972V15.2654Z"/>
            <path d="M21.7565 15.6881H20.911V15.2654H20.4883V14.4199H21.3338C21.4459 14.4199 21.5534 14.4645 21.6327 14.5437C21.712 14.623 21.7565 14.7305 21.7565 14.8427V15.6881Z"/>
            <path d="M21.3338 21.9372H20.4883V21.0917H20.911V20.6689H21.7565V21.5144C21.7565 21.6265 21.712 21.7341 21.6327 21.8133C21.5534 21.8926 21.4459 21.9372 21.3338 21.9372Z"/>
            <path d="M19.7024 21.9373H18.1256V21.0918H19.7024V21.9373ZM17.3393 21.9373H15.7625V21.0918H17.3393V21.9373ZM14.9762 21.9373H13.3994V21.0918H14.9762V21.9373Z"/>
            <path d="M12.4567 21.9372H11.6112C11.4991 21.9372 11.3916 21.8926 11.3123 21.8133C11.233 21.7341 11.1885 21.6265 11.1885 21.5144V20.6689H12.0339V21.0917H12.4567V21.9372Z"/>
            <path d="M12.0339 19.6881H11.1885V18.8427C11.1885 18.7305 11.233 18.623 11.3123 18.5437C11.3916 18.4645 11.4991 18.4199 11.6112 18.4199H12.4567V19.2654H12.0339V19.6881Z"/>
            <path d="M19.7024 19.2654H18.1256V18.4199H19.7024V19.2654ZM17.3351 19.2654H15.7625V18.4199H17.3182L17.3351 19.2654ZM14.972 19.2654H13.3994V18.4199H14.972V19.2654Z"/>
            <path d="M21.7565 19.6881H20.911V19.2654H20.4883V18.4199H21.3338C21.4459 18.4199 21.5534 18.4645 21.6327 18.5437C21.712 18.623 21.7565 18.7305 21.7565 18.8427V19.6881Z"/>
        </SvgIcon>);
}