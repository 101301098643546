import { Step, Steps, useLocalization, useSetOrderedWizardContext } from "@infrastructure";
import React, { useEffect } from "react";
import { InstructionsImageUrl } from "./images";

export function UserItem() {
    const setOrderedWizardContext = useSetOrderedWizardContext();
    useEffect(
        () => {
            setOrderedWizardContext(
                wizardContext => ({
                    ...wizardContext,
                    sideElement:
                        <img src={InstructionsImageUrl}/>
                }));
        },
        []);
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useTicketingItems.jira.addOrEdit.userItem",
            () => ({
                steps: {
                    step1: "Go to the Jira console \"**Administration**\" page",
                    step2: {
                        step1: "Enter the email address of the user you would like Tenable Cloud Security to use and click \"**Invite user**\"",
                        title: "On the side menu, click \"**Users**\" and then \"**Invite users**\"."
                    }
                }
            }));
    return (
        <Steps variant="letters">
            {localization.steps.step1()}
            {new Step(
                localization.steps.step2.title(),
                {
                    contentElement: localization.steps.step2.step1()
                })}
        </Steps>);
}