import { NoneIcon } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, EntityAttributes } from "../index";

type EntityAttributesCellProps = {
    entityAttributes: Contract.EntityAttribute[];
    entityTypeName: string;
};

export function EntityAttributesCell({ entityAttributes, entityTypeName }: EntityAttributesCellProps) {
    return _.isEmpty(entityAttributes)
        ? <NoneIcon sx={{ fontSize: "18px" }}/>
        : <EntityAttributes
            entityAttributes={entityAttributes}
            entityTypeName={entityTypeName}
            orderVariant="leftToRight"
            variant="dynamic"/>;
}