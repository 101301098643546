import { Contract } from "../../../../../../../../../../common";
import { useCiCommonEntityInfoItemElements } from "./useCiCommonEntityInfoItemElements";

export function useCiDefaultEntityInfoItemElements(entityModel: Contract.CiEntityModel) {
    const commonEntityInfoItemElements = useCiCommonEntityInfoItemElements(entityModel);

    return [
        commonEntityInfoItemElements.typeNameInfoElement,
        commonEntityInfoItemElements.tenantInfoElement,
        commonEntityInfoItemElements.syncTimeInfoElement
    ];
}