import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition, useGetAwsRdsSnapshotRiskContext } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";

export function useAwsRdsSnapshotPublicAccessExistsRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsRdsSnapshotPublicAccessExistsRisk;
    const snapshotModel = entityModelStore.useGet(risk.entityId) as Contract.AwsRdsSnapshotModel;
    const snapshot = snapshotModel.entity as Contract.AwsRdsSnapshot;

    const getAwsRdsSnapshotRiskContext = useGetAwsRdsSnapshotRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsRdsSnapshotPublicAccessExistsRiskDefinition",
            () => ({
                description: "{{snapshot}} is publicly accessible via visibility settings",
                sections: {
                    resolution: {
                        step1: "Change the access to **Private** and optionally add explicit AWS account numbers to share the snapshot with",
                        step2: "Click **Save** to confirm"
                    }
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description(
            {
                snapshot:
                    <Entity
                        entityIdOrModel={snapshotModel}
                        entityTypeNameTranslatorOptions={{ variant: "title" }}
                        variant="typeText"/>
            }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.Rds,
                AwsConsoleUrlBuilder.getRdsSnapshotShareUrl(snapshot)),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2()
        ],
        riskModel,
        () => {
            const snapshotRiskContext = getAwsRdsSnapshotRiskContext(snapshotModel);
            return [
                snapshotRiskContext.generalInformation,
                snapshotRiskContext.sensitive,
                snapshotRiskContext.sourceResource,
                snapshotRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        });
}