import { Link, NumberFormatter, Shadows, useLocalization } from "@infrastructure";
import { Divider, Stack, SxProps, Typography } from "@mui/material";
import React, { Fragment } from "react";
import { Contract, CustomerConsoleAppUrlHelper, EntityIcon, EntityTypeMetadataModelHelper, TenantIcon, useEntityTypeNameTranslator, useTheme } from "..";

type ItemProps = {
    containerSx?: SxProps;
    entityIds: string[];
    entityTypeName: string;
    interactive?: boolean;
    riskPolicyConfigurationTypeName: string;
    secretCount: number;
};

export function SecretItem({ containerSx, entityIds, entityTypeName, interactive = true, riskPolicyConfigurationTypeName, secretCount }: ItemProps) {
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "common.secretItem",
            () => ({
                [Contract.TypeNames.AwsCloudFormationStackSecretExistsRiskPolicyConfiguration]: [
                    "{{formattedSecretCount}} secret is exposed through {{translatedEntityTypeName}} template",
                    "{{formattedSecretCount}} secrets are exposed through {{translatedEntityTypeName}} template"
                ],
                [Contract.TypeNames.AwsEc2InstanceUserDataSecretExistsRiskPolicyConfiguration]: [
                    "{{formattedSecretCount}} secret is exposed through {{translatedEntityTypeName}} user data",
                    "{{formattedSecretCount}} secrets are exposed through {{translatedEntityTypeName}} user data"
                ],
                [Contract.TypeNames.AwsEcsTaskDefinitionEnvironmentVariableSecretExistsRiskPolicyConfiguration]: [
                    "{{formattedSecretCount}} secret is exposed through {{translatedEntityTypeName}} environment variables",
                    "{{formattedSecretCount}} secrets are exposed through {{translatedEntityTypeName}} environment variables"
                ],
                [Contract.TypeNames.AwsLambdaFunctionConfigurationEnvironmentVariableSecretExistsRiskPolicyConfiguration]: [
                    "{{formattedSecretCount}} secret is exposed through {{translatedEntityTypeName}} environment variables",
                    "{{formattedSecretCount}} secrets are exposed through {{translatedEntityTypeName}} environment variables"
                ],
                [Contract.TypeNames.AwsResourceTagSecretExistsRiskPolicyConfiguration]: [
                    "{{formattedSecretCount}} secret is exposed through {{translatedEntityTypeName}} tags",
                    "{{formattedSecretCount}} secrets are exposed through {{translatedEntityTypeName}} tags"
                ],
                [Contract.TypeNames.AwsSsmParameterSecretExistsRiskPolicyConfiguration]: [
                    "{{formattedSecretCount}} secret is exposed through {{translatedEntityTypeName}}",
                    "{{formattedSecretCount}} secrets are exposed through {{translatedEntityTypeName}}"
                ],
                [Contract.TypeNames.AzureLogicWorkflowSecretExistsRiskPolicyConfiguration]: [
                    "{{formattedSecretCount}} secret is exposed through {{translatedEntityTypeName}} parameters",
                    "{{formattedSecretCount}} secrets are exposed through {{translatedEntityTypeName}} parameters"
                ],
                [Contract.TypeNames.AzureResourceTagSecretExistsRiskPolicyConfiguration]: [
                    "{{formattedSecretCount}} secret is exposed through {{translatedEntityTypeName}} tags",
                    "{{formattedSecretCount}} secrets are exposed through {{translatedEntityTypeName}} tags"
                ],
                [Contract.TypeNames.GcpAppEngineApplicationServiceEnvironmentVariableSecretExistsRiskPolicyConfiguration]: [
                    "{{formattedSecretCount}} secret is exposed through {{translatedEntityTypeName}} environment variables",
                    "{{formattedSecretCount}} secrets are exposed through {{translatedEntityTypeName}} environment variables"
                ],
                [Contract.TypeNames.GcpComputeInstanceStartupScriptSecretExistsRiskPolicyConfiguration]: [
                    "{{formattedSecretCount}} secret is exposed through {{translatedEntityTypeName}} startup scripts",
                    "{{formattedSecretCount}} secrets are exposed through {{translatedEntityTypeName}} startup scripts"
                ],
                [Contract.TypeNames.GcpCloudRunServiceEnvironmentVariableSecretExistsRiskPolicyConfiguration]: [
                    "{{formattedSecretCount}} secret is exposed through {{translatedEntityTypeName}} environment variables",
                    "{{formattedSecretCount}} secrets are exposed through {{translatedEntityTypeName}} environment variables"
                ],
                [Contract.TypeNames.GcpFunctionsFunctionEnvironmentVariableSecretExistsRiskPolicyConfiguration]: [
                    "{{formattedSecretCount}} secret is exposed through {{translatedEntityTypeName}} environment variables",
                    "{{formattedSecretCount}} secrets are exposed through {{translatedEntityTypeName}} environment variables"
                ],
                [Contract.TypeNames.GcpResourceTagSecretExistsRiskPolicyConfiguration]: [
                    "{{formattedSecretCount}} secret is exposed through {{translatedEntityTypeName}} tags",
                    "{{formattedSecretCount}} secrets are exposed through {{translatedEntityTypeName}} tags"
                ]
            }));

    const theme = useTheme();
    return (
        <Link
            className="item"
            disabled={!interactive}
            sx={{
                height: theme.spacing(6.5),
                width: "100%"
            }}
            urlOrGetUrl={
                CustomerConsoleAppUrlHelper.getRisksUrl(
                    Contract.RisksView.Open,
                    {
                        policyConfigurationTypeNameOrIds: [riskPolicyConfigurationTypeName],
                        riskedEntityIds: entityIds
                    })}
            variant="text">
            <Stack
                alignItems="center"
                direction="row"
                spacing={2}
                sx={{
                    "&:hover": {
                        background: theme.palette.background.alternate,
                        border: theme.border.alternate,
                        borderRadius: theme.spacing(0.75),
                        boxShadow: theme.shadows[Shadows.Highlight],
                        zIndex: 1
                    },
                    border: "solid 1px transparent",
                    height: "100%",
                    padding: theme.spacing(1.5, 0.5),
                    width: "100%",
                    ...containerSx
                }}>
                {interactive &&
                    <Fragment>
                        <TenantIcon
                            sx={{ fontSize: "24px" }}
                            tenantType={EntityTypeMetadataModelHelper.get(entityTypeName).tenantType}/>
                        <Divider
                            flexItem={true}
                            orientation="vertical"
                            variant="middle"/>
                    </Fragment>}
                <EntityIcon
                    entityTypeName={entityTypeName}
                    sx={{
                        fontSize:
                            interactive
                                ? "24px"
                                : "30px"
                    }}/>
                <Typography
                    noWrap={true}
                    sx={{ fontSize: "13px" }}>
                    {localization[riskPolicyConfigurationTypeName as SecretsExistRiskPolicyConfigurationTypeNames](
                        secretCount,
                        {
                            formattedSecretCount:
                                <Typography
                                    component="span"
                                    variant="h4">
                                    {NumberFormatter.humanize(secretCount)}
                                </Typography>,
                            translatedEntityTypeName:
                                entityTypeNameTranslator(
                                    entityTypeName,
                                    {
                                        count:
                                            riskPolicyConfigurationTypeName === Contract.TypeNames.AwsSsmParameterSecretExistsRiskPolicyConfiguration
                                                ? 0
                                                : 1,
                                        variant: "text"
                                    })
                        })}
                </Typography>
            </Stack>
        </Link>);
}

type SecretsExistRiskPolicyConfigurationTypeNames =
    Contract.TypeNames.AwsCloudFormationStackSecretExistsRiskPolicyConfiguration |
    Contract.TypeNames.AwsEc2InstanceUserDataSecretExistsRiskPolicyConfiguration |
    Contract.TypeNames.AwsEcsTaskDefinitionEnvironmentVariableSecretExistsRiskPolicyConfiguration |
    Contract.TypeNames.AwsLambdaFunctionConfigurationEnvironmentVariableSecretExistsRiskPolicyConfiguration |
    Contract.TypeNames.AwsResourceTagSecretExistsRiskPolicyConfiguration |
    Contract.TypeNames.AwsSsmParameterSecretExistsRiskPolicyConfiguration |
    Contract.TypeNames.AzureLogicWorkflowSecretExistsRiskPolicyConfiguration |
    Contract.TypeNames.AzureResourceTagSecretExistsRiskPolicyConfiguration |
    Contract.TypeNames.GcpAppEngineApplicationServiceEnvironmentVariableSecretExistsRiskPolicyConfiguration |
    Contract.TypeNames.GcpComputeInstanceStartupScriptSecretExistsRiskPolicyConfiguration |
    Contract.TypeNames.GcpFunctionsFunctionEnvironmentVariableSecretExistsRiskPolicyConfiguration |
    Contract.TypeNames.GcpResourceTagSecretExistsRiskPolicyConfiguration;