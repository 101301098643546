import { SvgIcon, SvgIconProps, useTheme } from "@mui/material";
import React from "react";

export function DisabledCheckedIcon(props: SvgIconProps) {
    const theme = useTheme();
    return (
        <SvgIcon {...props}>
            <rect
                fill={theme.palette.text.primary}
                height="16"
                opacity="0.7"
                rx="2"
                width="16"
                x="4"
                y="4"/>
            <path
                d="M10.6515 14.0189L8.66288 12.0303L8 12.6932L10.6515 15.3447L16.3333 9.66288L15.6705 9L10.6515 14.0189Z"
                fill="white"
                stroke="white"
                strokeWidth="0.5"/>
        </SvgIcon>);
}