import { Loading, map, useLocalization, VerticalFillGrid } from "@infrastructure";
import { Stack, Tab, Tabs } from "@mui/material";
import _ from "lodash";
import React from "react";
import { useScopeNavigationViewContext, useTheme } from "../../../../../../common";
import { ConfigurationView } from "../../utilities";
import { AutomationsView } from "./AutomationsView";
import { AuditEventAutomations, RiskAutomations } from "./components";

export function Automations() {
    const automationsViews =
        [
            AutomationsView.RiskPolicies,
            AutomationsView.AuditEvents
        ];
    const { useViewRoute } = useScopeNavigationViewContext();
    const [view, setView] = useViewRoute(`${ConfigurationView.Automations}/{view}`, automationsViews);

    const localization =
        useLocalization(
            "views.customer.configuration.automations",
            () => ({
                tabs: {
                    automationsView: {
                        [AutomationsView.AuditEvents]: "Audit",
                        [AutomationsView.RiskPolicies]: "Policies"
                    }
                }
            }));
    const theme = useTheme();
    return (
        <VerticalFillGrid>
            <Tabs
                sx={{
                    border: "unset",
                    padding: theme.spacing(0, 2)
                }}
                value={view}
                variant="scrollable"
                onChange={(event, view) => setView(view as AutomationsView)}>
                {_.map(
                    automationsViews,
                    automationsView =>
                        <Tab
                            id={automationsView}
                            key={automationsView}
                            label={localization.tabs.automationsView[automationsView]()}
                            sx={{
                                marginRight: theme.spacing(3),
                                padding: 0
                            }}
                            value={automationsView}/>)}
            </Tabs>
            <Stack
                sx={{
                    height: "100%",
                    width: "100%"
                }}>
                <Loading>
                    {map(
                        view,
                        {
                            [AutomationsView.AuditEvents]: () => <AuditEventAutomations/>,
                            [AutomationsView.RiskPolicies]: () => <RiskAutomations/>
                        })}
                </Loading>
            </Stack>
        </VerticalFillGrid>);
}