import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, InlineTexts, Loading, Optional, optionalTableCell, TimeCell, TimeFormatter, useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import _ from "lodash";
import React, { useCallback, useMemo } from "react";
import { Compliances, Contract, ElasticsearchItemPageHelper, EntitiesCell, EntityPropertyHelper, LicensingHelper, PagedEntityFilter, RiskPolicyTypeMetadataHelper, RiskType, RiskTypeMetadataModelHelper, Severity, TenantCell, TenantHelper, tenantModelStore, useEntityTypeNameTranslator, useRiskSubStatusTranslator, useTheme } from "../../../../../../../../common";
import { useBuiltInRiskPolicyConfigurationTypeNameToAnalysisGroupTypeToComplianceTypesMap } from "../../../../../Compliance/hooks";
import { RisksItemsFilterId } from "../../../Filters";
import { TableColumnId } from "../../components";
import { ActionsCell } from "../components";
import { useStatusTranslator } from "../useStatusTranslator";
import { RiskedEntityAttributesCell, TitleCell } from "./components";

export function useTableCommonColumns(view: Contract.RisksView, ungrouped = false) {
    const builtInRiskPolicyConfigurationTypeNameToAnalysisGroupTypeToComplianceTypesMap = useBuiltInRiskPolicyConfigurationTypeNameToAnalysisGroupTypeToComplianceTypesMap();
    const filteredActiveTenantModels = tenantModelStore.useGetFilteredActiveTenants(TenantHelper.PropertyTenantTypes);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const riskSubStatusTranslator = useRiskSubStatusTranslator();
    const statusTranslator = useStatusTranslator();

    const localization =
        useLocalization(
            "views.customer.risks.items.hooks.useTableCommonColumns",
            () => ({
                columns: {
                    compliances: "Compliance",
                    ignoreExpirationDate: "Ignored Until",
                    resourceEnvironment: "Resource Environment",
                    resourceOwner: "Resource Owner",
                    riskedEntityAttributes: "Labels",
                    riskPolicyId: "Policy",
                    severity: "Severity",
                    status: "Status",
                    statusUpdateTime: {
                        [Contract.TypeNames.RisksView]: {
                            [Contract.RisksView.Closed]: "Close Time",
                            [Contract.RisksView.Ignored]: "Ignore Time",
                            [Contract.RisksView.Open]: "Open Time"
                        }
                    },
                    subStatus: "Sub-Status",
                    systemCreationTime: "Creation Time",
                    tenantId: "Account",
                    ticketingServiceType: {
                        placeholder: "Ticket",
                        [Contract.TypeNames.TicketingServiceType]: {
                            [Contract.TicketingServiceType.Jira]: "Jira issue",
                            [Contract.TicketingServiceType.ServiceNow]: "ServiceNow incident"
                        }
                    }
                }
            }));

    const getFilterOptions =
        useCallback(
            (filterId: Contract.RiskFilterId) =>
                ungrouped
                    ? { externalId: RisksItemsFilterId[filterId] }
                    : undefined,
            [ungrouped]);

    const getRiskedEntityFilterOptions =
        useCallback(
            (riskPolicyConfigurationTypeName: string, filters: Optional<Contract.RiskControllerCloudRiskModelFilters>, translatedEntityTypeName: string) => {
                if (ungrouped) {
                    return getFilterOptions(Contract.RiskFilterId.RiskedEntityAttribute);
                }
                if (_.isNil(filters)) {
                    return undefined;
                }

                return {
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedRiskFilterEntityItem(
                                        filters,
                                        Contract.RiskControllerGetRiskPolicyTypeGroupFilterItemPageRequestProperty.RiskedEntities,
                                        riskPolicyConfigurationTypeName)}
                                placeholder={translatedEntityTypeName}/>
                    }
                };
            },
            [ungrouped, getFilterOptions]);

    const isFilteredActiveTenantModelsResourceEnvironmentConfigured = EntityPropertyHelper.isResourceEnvironmentConfigured(filteredActiveTenantModels);
    const isFilteredActiveTenantModelsResourceOwnerConfigured = EntityPropertyHelper.isResourceOwnerConfigured(filteredActiveTenantModels);

    const theme = useTheme();
    return useMemo(
        () => ({
            actionsCell:
                (riskType: RiskType) =>
                    <DataTableColumn
                        disableAction={true}
                        id={TableColumnId.Actions}
                        key={TableColumnId.Actions}
                        orderable={false}
                        render={({ item }: DataTableColumnRenderProps<Contract.CodeRiskModel>) =>
                            <ActionsCell
                                item={item}
                                riskType={riskType}/>}
                        resizable={false}
                        selectorOptions={{ disabled: true }}/>,
            compliancesColumn:
                LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.Cspm)
                    ? <DataTableColumn
                        disableAction={true}
                        filterOptions={getFilterOptions(Contract.RiskFilterId.ComplianceIdOrSectionType)}
                        id={TableColumnId.Compliances}
                        key={TableColumnId.Compliances}
                        render={
                            ({ item }: DataTableColumnRenderProps<Contract.RiskModel>) =>
                                <Loading container="cell">
                                    <Compliances
                                        builtInComplianceTypes={
                                            _.flatMap(
                                                item.risk.analysisGroupTypes,
                                                analysisGroupType => builtInRiskPolicyConfigurationTypeNameToAnalysisGroupTypeToComplianceTypesMap[RiskTypeMetadataModelHelper.get(item.risk.typeName).policyConfigurationTypeName]?.[analysisGroupType] ?? [])}
                                        customComplianceIds={item.customComplianceIds}/>
                                </Loading>}
                        selectorOptions={{ default: false }}
                        sortOptions={{ enabled: false }}
                        title={localization.columns.compliances()}/>
                    : undefined,
            ignoreExpirationDateColumn:
                view === Contract.RisksView.Ignored
                    ? <DataTableColumn
                        id={TableColumnId.IgnoreExpirationDate}
                        key={TableColumnId.IgnoreExpirationDate}
                        render={
                            optionalTableCell<Contract.RiskModel>(
                                riskModel =>
                                    _.isNil(riskModel.risk.ignoreExpirationDate)
                                        ? undefined
                                        : TimeFormatter.mediumDate(riskModel.risk.ignoreExpirationDate))}
                        sortOptions={{ type: DataTableSortType.Date }}
                        title={localization.columns.ignoreExpirationDate()}/>
                    : undefined,
            openStatusUpdateTimeColumn:
                view !== Contract.RisksView.Open
                    ? <DataTableColumn
                        filterOptions={getFilterOptions(Contract.RiskFilterId.OpenStatusUpdateTime)}
                        id={TableColumnId.OpenStatusUpdateTime}
                        key={TableColumnId.OpenStatusUpdateTime}
                        render={({ item }: DataTableColumnRenderProps<Contract.RiskModel>) =>
                            <TimeCell time={item.risk.openStatusUpdateTime}/>}
                        sortOptions={{ type: DataTableSortType.Date }}
                        title={localization.columns.statusUpdateTime[Contract.TypeNames.RisksView][Contract.RisksView.Open]()}/>
                    : undefined,
            resourceEnvironmentColumn:
                isFilteredActiveTenantModelsResourceEnvironmentConfigured
                    ? <DataTableColumn
                        filterOptions={getFilterOptions(Contract.RiskFilterId.RiskedEntityEnvironment)}
                        id={TableColumnId.ResourceEnvironment}
                        key={TableColumnId.ResourceEnvironment}
                        render={
                            optionalTableCell<Contract.RiskModel>(
                                (item: Contract.RiskModel) =>
                                    _.isNil(item.riskedEntityEnvironment)
                                        ? undefined
                                        : <InlineTexts
                                            texts={
                                                _.map(
                                                    EntityPropertyHelper.getOrderedValues(item.riskedEntityEnvironment) as Contract.EntityPropertyStringValue[],
                                                    value => value.string)}
                                            variant="itemPlusItemCount"/>
                            )}
                        selectorOptions={{ default: false }}
                        sortOptions={{ enabled: false }}
                        title={localization.columns.resourceEnvironment()}/>
                    : undefined,
            resourceOwnerColumn:
                isFilteredActiveTenantModelsResourceOwnerConfigured
                    ? <DataTableColumn
                        filterOptions={getFilterOptions(Contract.RiskFilterId.RiskedEntityOwner)}
                        id={TableColumnId.ResourceOwner}
                        key={TableColumnId.ResourceOwner}
                        render={
                            ({ item }: DataTableColumnRenderProps<Contract.RiskModel>) =>
                                <EntitiesCell
                                    entityIdsOrModels={
                                        _.isNil(item.riskedEntityOwner)
                                            ? undefined
                                            : _.map(
                                                EntityPropertyHelper.getOrderedValues(item.riskedEntityOwner) as Contract.EntityPropertyPrincipalReferenceValue[],
                                                riskedEntityOwnerValue => riskedEntityOwnerValue.principalSearchableIdReference.idReference)}
                                    entityTypeName={Contract.TypeNames.PartialPrincipal}
                                    entityVariant="iconText"/>}
                        selectorOptions={{ default: false }}
                        sortOptions={{ enabled: false }}
                        title={localization.columns.resourceOwner()}/>
                    : undefined,
            riskedEntityAttributesColumn:
                <DataTableColumn
                    cellMaxWidth="small"
                    disableAction={true}
                    filterOptions={getFilterOptions(Contract.RiskFilterId.RiskedEntityAttribute)}
                    id={TableColumnId.RiskedEntityAttributes}
                    key={TableColumnId.RiskedEntityAttributes}
                    render={RiskedEntityAttributesCell}
                    sortOptions={{ enabled: false }}
                    title={localization.columns.riskedEntityAttributes()}/>,
            riskedEntityIdColumn:
                (riskPolicyConfigurationTypeName: Optional<string>, filters: Optional<Contract.RiskControllerCloudRiskModelFilters>, entityTypeName?: string) => {
                    const translatedEntityTypeName = entityTypeNameTranslator(entityTypeName ?? RiskPolicyTypeMetadataHelper.getRiskedEntityTypeName(riskPolicyConfigurationTypeName!));
                    return <DataTableColumn
                        cellMaxWidth="small"
                        filterOptions={getRiskedEntityFilterOptions(riskPolicyConfigurationTypeName!, filters, translatedEntityTypeName)}
                        id={TableColumnId.RiskedEntityIds}
                        key={TableColumnId.RiskedEntityIds}
                        render={
                            ({ item }: DataTableColumnRenderProps<Contract.RiskModel>) =>
                                <EntitiesCell
                                    entityIdsOrModels={item.risk.riskedEntityIds}
                                    entityTypeName={item.riskedEntityTypeName}
                                    entityVariant="iconText"/>}
                        sortOptions={{ enabled: false }}
                        title={translatedEntityTypeName}/>;
                },
            riskPolicyIdColumn:
                <DataTableColumn
                    cellSx={{ minWidth: theme.spacing(15) }}
                    filterOptions={getFilterOptions(Contract.RiskFilterId.RiskPolicyConfigurationTypeNameOrId)}
                    id={TableColumnId.RiskPolicyId}
                    key={TableColumnId.RiskPolicyId}
                    render={TitleCell}
                    selectorOptions={{ disabled: true }}
                    sortOptions={{ enabled: false }}
                    title={localization.columns.riskPolicyId()}/>,
            severityColumn:
                <DataTableColumn
                    filterOptions={getFilterOptions(Contract.RiskFilterId.RiskSeverity)}
                    id={TableColumnId.Severity}
                    key={TableColumnId.Severity}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.RiskModel>) =>
                            <Severity severity={item.risk.severity}/>}
                    selectorOptions={{ disabled: true }}
                    sortOptions={{ enabled: false }}
                    title={localization.columns.severity()}/>,
            statusColumn:
                (selectorDefault: boolean) =>
                    <DataTableColumn
                        id={TableColumnId.Status}
                        key={TableColumnId.Status}
                        render={
                            ({ item }: DataTableColumnRenderProps<Contract.RiskModel>) =>
                                <Typography noWrap={true}>
                                    {statusTranslator(item.risk.status)}
                                </Typography>}
                        selectorOptions={{ default: selectorDefault }}
                        sortOptions={{ enabled: false }}
                        title={localization.columns.status()}/>,
            statusUpdateTimeColumn:
                <DataTableColumn
                    filterOptions={getFilterOptions(Contract.RiskFilterId.StatusUpdateTime)}
                    id={TableColumnId.StatusUpdateTime}
                    key={TableColumnId.StatusUpdateTime}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.RiskModel>) =>
                            <TimeCell time={item.risk.statusUpdateTime}/>}
                    sortOptions={{ type: DataTableSortType.Date }}
                    title={localization.columns.statusUpdateTime[Contract.TypeNames.RisksView][view]()}/>,
            subStatusColumn:
                (selectorDefault: boolean) =>
                    <DataTableColumn
                        filterOptions={getFilterOptions(Contract.RiskFilterId.RiskSubStatus)}
                        id={TableColumnId.SubStatus}
                        key={TableColumnId.SubStatus}
                        render={
                            ({ item }: DataTableColumnRenderProps<Contract.RiskModel>) =>
                                <Typography noWrap={true}>
                                    {riskSubStatusTranslator(item.risk.subStatus!)}
                                </Typography>}
                        selectorOptions={{ default: selectorDefault }}
                        sortOptions={{ enabled: false }}
                        title={localization.columns.subStatus()}/>,
            systemCreationTimeColumn:
                <DataTableColumn
                    filterOptions={getFilterOptions(Contract.RiskFilterId.SystemCreationTime)}
                    id={TableColumnId.SystemCreationTime}
                    key={TableColumnId.SystemCreationTime}
                    render={({ item }: DataTableColumnRenderProps<Contract.RiskModel>) =>
                        <TimeCell time={item.risk.systemCreationTime}/>}
                    selectorOptions={{ default: false }}
                    sortOptions={{ type: DataTableSortType.Date }}
                    title={localization.columns.systemCreationTime()}/>,
            tenantIdColumn:
                <DataTableColumn
                    cellMaxWidth="medium"
                    filterOptions={getFilterOptions(Contract.RiskFilterId.TenantId)}
                    id={TableColumnId.TenantId}
                    key={TableColumnId.TenantId}
                    render={({ item }: DataTableColumnRenderProps<Contract.RiskModel>) =>
                        <TenantCell tenantId={item.tenantId}/>}
                    sortOptions={{ enabled: false }}
                    title={localization.columns.tenantId()}/>
        }),
        [builtInRiskPolicyConfigurationTypeNameToAnalysisGroupTypeToComplianceTypesMap, entityTypeNameTranslator, filteredActiveTenantModels, getFilterOptions, getRiskedEntityFilterOptions, isFilteredActiveTenantModelsResourceEnvironmentConfigured, isFilteredActiveTenantModelsResourceOwnerConfigured, localization, theme, view]);
}