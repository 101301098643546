import { Link, useLocalization } from "@infrastructure";
import React from "react";
import { useAwsCommonAccessResourceInfoElements, useAwsCommonEncryptedResourceInfoElements, useAwsDefaultResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../..";
import { useCustomEntityPropertyInfoItemElements } from "..";
import { Contract, InfoItem } from "../../../../../../../../../../common";
import { EntitiesInfoItem, Info } from "../../../../components";
import { AwsIamPolicyDocument } from "../../components";

export function useAwsSqsQueueDefinition(resourceModel: Contract.AwsResourceModel, options?: DefinitionOptions) {
    const commonAccessResourceInfoElements = useAwsCommonAccessResourceInfoElements(resourceModel);
    const commonEncryptedResourceInfoElements = useAwsCommonEncryptedResourceInfoElements(resourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useAwsDefaultResourceInfoItemElements(resourceModel);
    const queue = resourceModel.entity as Contract.AwsSqsQueue;
    const queueModel = resourceModel as Contract.AwsSqsQueueModel;

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsSqsQueueDefinition",
            () => ({
                info: {
                    items: {
                        encryptionExists: "Server-Side Encryption",
                        subscriptionTopicIds: "SNS Subscriptions",
                        triggerFunctionConfigurationIds: "Lambda Triggers",
                        type: {
                            title: "Queue Type",
                            [Contract.TypeNames.AwsSqsQueueType]: {
                                [Contract.AwsSqsQueueType.Fifo]: "Fifo",
                                [Contract.AwsSqsQueueType.Standard]: "Standard"
                            }
                        },
                        url: "URL"
                    }
                },
                tabs: {
                    policyDocument: "Policy"
                }
            }));


    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <AwsIamPolicyDocument policyDocument={queue.policyDocument}/>,
                localization.tabs.policyDocument(),
                "policyDocument")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    ...commonAccessResourceInfoElements,
                    ...commonEncryptedResourceInfoElements,
                    <InfoItem
                        key="type"
                        title={localization.info.items.type.title()}
                        value={localization.info.items.type[Contract.TypeNames.AwsSqsQueueType][queue.type]()}/>,
                    <InfoItem
                        key="url"
                        title={localization.info.items.url()}
                        value={
                            <Link
                                urlOrGetUrl={queue.url}
                                variant="external"/>}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={queueModel.triggerFunctionConfigurationIds}
                        entityTypeName={Contract.TypeNames.AwsLambdaFunctionConfiguration}
                        key="triggerFunctionConfigurationIds"
                        title={localization.info.items.triggerFunctionConfigurationIds()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={queueModel.subscriptionTopicIds}
                        entityTypeName={Contract.TypeNames.AwsSnsTopic}
                        key="subscriptionTopicIds"
                        title={localization.info.items.subscriptionTopicIds()}/>
                ]}
                options={options?.infoOptions}>
            </Info>
    });
}