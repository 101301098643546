import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useAwsEc2VpcBlockPublicAccessPolicyNetworkDirectionTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useAwsEc2VpcBlockPublicAccessPolicyNetworkDirectionTranslator",
            () => ({
                [Contract.TypeNames.AwsEc2VpcBlockPublicAccessPolicyNetworkDirection]: {
                    [Contract.AwsEc2VpcBlockPublicAccessPolicyNetworkDirection.All]: "Bidirectional",
                    [Contract.AwsEc2VpcBlockPublicAccessPolicyNetworkDirection.Inbound]: "Ingress-only",
                    [Contract.AwsEc2VpcBlockPublicAccessPolicyNetworkDirection.None]: "None",
                    [Contract.AwsEc2VpcBlockPublicAccessPolicyNetworkDirection.Outbound]: "Egress-only"
                }
            }));
    return (blockPublicAccessPolicyNetworkDirection: Contract.AwsEc2VpcBlockPublicAccessPolicyNetworkDirection) =>
        localization[Contract.TypeNames.AwsEc2VpcBlockPublicAccessPolicyNetworkDirection][blockPublicAccessPolicyNetworkDirection]();
}