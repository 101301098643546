import _ from "lodash";
import { Contract, scopeNodeModelStore, useScopeNavigationViewContext } from "../../../../../../../common";

export function useInheritedScopeId<TField>(fieldSelector: (workloadAnalysis: Contract.ScopeConfigurationWorkloadAnalysis) => TField | undefined) {
    const { scopeNodeModel } = useScopeNavigationViewContext();
    const scopeNodeMap = scopeNodeModelStore.useGetActiveScopeNodeMap();
    const parentScopeIds = scopeNodeMap[scopeNodeModel.configuration.id].parentScopeIds;

    for (const parentScopeId of parentScopeIds) {
        if (!_.isNil(fieldSelector(scopeNodeMap[parentScopeId].scopeNodeModel.configuration.scopeSections.workloadAnalysis))) {
            return parentScopeId;
        }
    }
    return undefined;
}