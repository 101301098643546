import { UnexpectedError } from "@infrastructure";
import { useMemo } from "react";
import { Contract } from "../../../../../../../../common";
import { usePermissionsDestinationEntityDefinition, usePermissionsSourceEntityDefinition } from "./hooks";

export function useDefinition(entityType: Contract.AccessControllerGetPermissionsModelEntityType): PermissionsDefinition {
    const useDefinition =
        useMemo(
            () => {
                switch (entityType) {
                    case Contract.AccessControllerGetPermissionsModelEntityType.SourceEntity:
                        return usePermissionsSourceEntityDefinition;
                    case Contract.AccessControllerGetPermissionsModelEntityType.DestinationEntity:
                        return usePermissionsDestinationEntityDefinition;
                    default:
                        throw new UnexpectedError("entityType", entityType);
                }
            },
            []);

    return useDefinition();
}


export type PermissionsDefinition = {
    columns: {
        destinationEntities: string;
        sourceEntities: string;
    };
    getPermissionsModelEntityId: (permissionEntityModel: Contract.PermissionsModel) => string;
};