import { Link, useLocalization } from "@infrastructure";
import React from "react";
import { Contract } from "../../../common";

export function useAwsConsoleSignInStepTranslator() {
    const localization =
        useLocalization(
            "tenants.aws.hooks.useAwsConsoleSignInStepTranslator",
            () => ({
                signInStep: "Sign in to the {{link}}",
                view: {
                    [Contract.AwsConsoleView.Acm]: "ACM page",
                    [Contract.AwsConsoleView.ApiGateway]: "API Gateway console",
                    [Contract.AwsConsoleView.AthenaWorkgroup]: "Athena Workgroup console",
                    [Contract.AwsConsoleView.CloudFormation]: "CloudFormation console",
                    [Contract.AwsConsoleView.CloudFront]: "CloudFront console",
                    [Contract.AwsConsoleView.CloudTrail]: "CloudTrail console",
                    [Contract.AwsConsoleView.CloudWatch]: "CloudWatch console",
                    [Contract.AwsConsoleView.Config]: "Config console",
                    [Contract.AwsConsoleView.DocDb]: "DocumentDB console",
                    [Contract.AwsConsoleView.DynamoDb]: "DynamoDB console",
                    [Contract.AwsConsoleView.Ec2]: "EC2 console",
                    [Contract.AwsConsoleView.Ecr]: "ECR console",
                    [Contract.AwsConsoleView.Ecs]: "ECS console",
                    [Contract.AwsConsoleView.ElasticBeanstalk]: "Elastic Beanstalk console",
                    [Contract.AwsConsoleView.ElastiCache]: "ElastiCache console",
                    [Contract.AwsConsoleView.ElasticFileSystem]: "EFS console",
                    [Contract.AwsConsoleView.Eks]: "EKS console",
                    [Contract.AwsConsoleView.Emr]: "EMR console",
                    [Contract.AwsConsoleView.Es]: "Elasticsearch console",
                    [Contract.AwsConsoleView.Firehose]: "Firehose console",
                    [Contract.AwsConsoleView.Iam]: "IAM console",
                    [Contract.AwsConsoleView.Kinesis]: "Kinesis console",
                    [Contract.AwsConsoleView.Kms]: "KMS console",
                    [Contract.AwsConsoleView.Lambda]: "Lambda console",
                    [Contract.AwsConsoleView.Neptune]: "Neptune console",
                    [Contract.AwsConsoleView.Rds]: "RDS console",
                    [Contract.AwsConsoleView.Redshift]: "Redshift console",
                    [Contract.AwsConsoleView.ResourceGroups]: "Resource Groups console",
                    [Contract.AwsConsoleView.Route53]: "Route 53 console",
                    [Contract.AwsConsoleView.S3]: "S3 console",
                    [Contract.AwsConsoleView.SageMaker]: "SageMaker console",
                    [Contract.AwsConsoleView.SecretsManager]: "Secrets Manager console",
                    [Contract.AwsConsoleView.SecurityGroups]: "Security Groups console",
                    [Contract.AwsConsoleView.SecurityHub]: "Security Hub console",
                    [Contract.AwsConsoleView.Sns]: "SNS console",
                    [Contract.AwsConsoleView.Sqs]: "SQS console",
                    [Contract.AwsConsoleView.Ssm]: "Systems Manager console",
                    [Contract.AwsConsoleView.Sso]: "IAM Identity Center console",
                    [Contract.AwsConsoleView.Subnets]: "Subnets page in the VPC console",
                    [Contract.AwsConsoleView.Timestream]: "Timestream console",
                    [Contract.AwsConsoleView.Vpc]: "VPC console"
                }
            }));
    return (view: Contract.AwsConsoleView, url: string) =>
        localization.signInStep({
            link:
                <Link
                    urlOrGetUrl={url}
                    variant="external">
                    {localization.view[view]()}
                </Link>
        });
}