import _ from "lodash";
import React from "react";
import { AccessController, Contract, EntitiesCell } from "../../../../../../../common";
import { usePermissionsContext } from "../Permissions";

type InlineDestinationResourcesProps = {
    getFilters: () => Contract.PermissionManagerRequestFilters;
    permissionsModel: Contract.PermissionsModel;
};

export function InlineDestinationEntities({ getFilters, permissionsModel }: InlineDestinationResourcesProps) {
    const { definition } = usePermissionsContext();
    return (
        <EntitiesCell
            deferredEntities={{
                count: permissionsModel.destinationEntityCount,
                firstItem: _.first(permissionsModel.destinationEntityTopIds),
                getItems:
                    async () => {
                        const { destinationEntityIds } =
                            await AccessController.getPermissionsModelDestinationEntityIds(
                                new Contract.AccessControllerGetPermissionsModelDestinationEntityIdsRequest(
                                    getFilters(),
                                    definition.getPermissionsModelEntityId(permissionsModel)));
                        return destinationEntityIds;
                    }
            }}
            entityTypeName={Contract.TypeNames.Entity}
            entityVariant="iconTextTypeTenant"/>);
}