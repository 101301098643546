import { useLocalization } from "@infrastructure";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { Contract, InfoItem } from "../../../../../../../../../../../common";
import { useOciContainerEngineClusterNodePoolStatusTranslator } from "../../../../../../../hooks";
import { EntitiesInfoItem, Info, TagsInfoItem } from "../../../../../components";
import { useCustomEntityPropertyInfoItemElements } from "../../useCustomEntityPropertyInfoItemElements";
import { useOciDefaultResourceInfoItemElements } from "../useOciDefaultResourceInfoItemElements";
import { InstanceInfoCard } from "./components/InstanceInfoCard";

export function useOciContainerEngineClusterNodePoolDefinition(resourceModel: Contract.OciResourceModel, options?: DefinitionOptions) {
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useOciDefaultResourceInfoItemElements(resourceModel);
    const clusterNodePoolModel = resourceModel as Contract.OciContainerEngineClusterNodePoolModel;
    const clusterNodePool = clusterNodePoolModel.entity as Contract.OciContainerEngineClusterNodePool;

    const clusterNodePoolStatusTranslator = useOciContainerEngineClusterNodePoolStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.oci.useOciContainerEngineClusterNodePoolDefinition",
            () => ({
                info: {
                    items: {
                        cluster: "Cluster",
                        node: {
                            image: "Image",
                            kubernetesTags: "Kubernetes Labels",
                            kubernetesVersion: "Kubernetes Version",
                            metadataServiceVersion1Enabled: {
                                false: "Version 2 only",
                                title: "Instance Metadata Service",
                                true: "Versions 1 and 2"
                            },
                            shape: "Shape",
                            tags: "Node Tags",
                            volumeEncryptionInTransitEnabled: {
                                false: "Disabled",
                                title: "In-Transit Encryption",
                                true: "Enabled"
                            }
                        },
                        nodeCount: "Worker Node Count",
                        status: "Status"
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <EntitiesInfoItem
                        entityIdsOrModels={clusterNodePoolModel.clusterIdReference}
                        entityTypeName={Contract.TypeNames.OciContainerEngineCluster}
                        key="cluster"
                        location="all"
                        title={localization.info.items.cluster()}/>,
                    <InfoItem
                        key="status"
                        location="all"
                        title={localization.info.items.status()}
                        value={clusterNodePoolStatusTranslator(clusterNodePool.status)}/>,
                    <InfoItem
                        key="nodeCount"
                        location="all"
                        title={localization.info.items.nodeCount()}
                        value={clusterNodePool.nodeCount}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={clusterNodePoolModel.nodeNodeImageIdReference}
                        entityTypeName={Contract.TypeNames.OciComputeImage}
                        key="image"
                        title={localization.info.items.node.image()}/>,
                    <InfoItem
                        key="nodeKubernetesVersion"
                        title={localization.info.items.node.kubernetesVersion()}
                        value={clusterNodePool.node.kubernetesVersion}/>,
                    <InfoItem
                        key="nodeShape"
                        title={localization.info.items.node.shape()}
                        value={clusterNodePool.node.shape}/>,
                    <TagsInfoItem
                        key="nodeKubernetesTags"
                        resourceTags={clusterNodePool.node.kubernetesTags}
                        title={localization.info.items.node.kubernetesTags()}/>,
                    <TagsInfoItem
                        key="nodeTags"
                        resourceTags={clusterNodePool.node.tags}
                        title={localization.info.items.node.tags()}/>,
                    <InfoItem
                        key="volumeEncryptionInTransitEnabled"
                        title={localization.info.items.node.volumeEncryptionInTransitEnabled.title()}
                        value={
                            clusterNodePool.node.volumeEncryptionInTransitEnabled
                                ? localization.info.items.node.volumeEncryptionInTransitEnabled.true()
                                : localization.info.items.node.volumeEncryptionInTransitEnabled.false()}/>,
                    <InfoItem
                        key="metadataServiceVersion1Enabled"
                        title={localization.info.items.node.metadataServiceVersion1Enabled.title()}
                        value={
                            clusterNodePool.node.metadataServiceVersion1Enabled
                                ? localization.info.items.node.metadataServiceVersion1Enabled.true()
                                : localization.info.items.node.metadataServiceVersion1Enabled.false()}/>
                ]}
                options={options?.infoOptions}>
                <InstanceInfoCard instanceIdReferences={clusterNodePoolModel.instanceIdReferences}/>
            </Info>
    });
}