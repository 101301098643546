import { UnexpectedError } from "@infrastructure";
import { ReactNode, useMemo } from "react";
import { Contract } from "../../../../../../../../../../common";
import { useAadDefinition, useGciDefinition, useOktaDefinition, useOneLoginDefinition, usePingIdentityDefinition } from "./hooks";

export function useDefinition(tenantType: Contract.TenantType): TenantDefinition {
    const useDefinition =
        useMemo(
            () => {
                switch (tenantType) {
                    case Contract.TenantType.Aad:
                        return useAadDefinition;
                    case Contract.TenantType.Gci:
                        return useGciDefinition;
                    case Contract.TenantType.Okta:
                        return useOktaDefinition;
                    case Contract.TenantType.OneLogin:
                        return useOneLoginDefinition;
                    case Contract.TenantType.PingIdentity:
                        return usePingIdentityDefinition;
                    default:
                        throw new UnexpectedError("tenantType", tenantType);
                }
            },
            []);

    return useDefinition();
}

export class TenantDefinition {
    constructor(public additionalColumns: ReactNode[]) {
    }
}