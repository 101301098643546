import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, KubernetesIcon, scopeNodeModelStore, scopeSystemEntityModelStore, useScopeNavigationViewContext } from "../../../../../../../../../../common";
import { IntegrationItem, IntegrationItemCategory } from "../../../../utilities";
import { KubernetesClusterConnectors } from "./components";

export function useKubernetesItems(childScopeEnabled: boolean): IntegrationItem[] {
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useKubernetesItems",
            () => ({
                description: {
                    childScope: "{{count | NumberFormatter.humanize}} configured (child scopes)",
                    title: "{{count | NumberFormatter.humanize}} configured"
                },
                [Contract.TypeNames.IntegrationType]: {
                    [Contract.IntegrationType.Kubernetes]: "Kubernetes Cluster Connectors"
                }
            }));

    const { scopeNodeModel } = useScopeNavigationViewContext();
    const scopeNodeMap = scopeNodeModelStore.useGetActiveScopeNodeMap();
    const childScopeIds =
        useMemo(
            () => scopeNodeMap[scopeNodeModel.configuration.id].childScopeIds,
            [scopeNodeModel.configuration.id, scopeNodeMap]);
    const parentScopeIds =
        useMemo(
            () => scopeNodeMap[scopeNodeModel.configuration.id].parentScopeIds,
            [scopeNodeModel.configuration.id, scopeNodeMap]);
    const kubernetesClusterConnectorModels = scopeSystemEntityModelStore.useGetKubernetesClusterConnector();
    const filteredChildScopeKubernetesClusterConnectorModels =
        useMemo(
            () =>
                childScopeEnabled
                    ? _.filter(
                        kubernetesClusterConnectorModels,
                        kubernetesClusterConnectorModel =>
                            _.includes(
                                childScopeIds,
                                kubernetesClusterConnectorModel.configuration.scopeId))
                    : [],
            [childScopeEnabled, childScopeIds, kubernetesClusterConnectorModels]);
    const filteredKubernetesClusterConnectorModels =
        useMemo(
            () =>
                _.filter(
                    kubernetesClusterConnectorModels,
                    kubernetesClusterConnectorModel =>
                        _(parentScopeIds).
                            concat(scopeNodeModel.configuration.id).
                            includes(kubernetesClusterConnectorModel.configuration.scopeId)),
            [kubernetesClusterConnectorModels, parentScopeIds]);

    return [
        new IntegrationItem(
            IntegrationItemCategory.Kubernetes,
            _.concat(filteredChildScopeKubernetesClusterConnectorModels, filteredKubernetesClusterConnectorModels),
            _.concatIf(
                [localization.description.title({ count: filteredKubernetesClusterConnectorModels.length })],
                childScopeEnabled && filteredChildScopeKubernetesClusterConnectorModels.length > 0,
                localization.description.childScope({ count: filteredChildScopeKubernetesClusterConnectorModels.length })),
            <KubernetesIcon/>,
            localization[Contract.TypeNames.IntegrationType][Contract.IntegrationType.Kubernetes](),
            Contract.IntegrationType.Kubernetes,
            <KubernetesClusterConnectors/>)
    ];
}