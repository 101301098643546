import { FormLayout, makeContextProvider, OrderedWizard, OrderedWizardItem, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract } from "../../../../../../../../common";
import { useSetSystemKubernetesClustersContext, useSystemKubernetesClustersContext } from "../../SystemKubernetesClusters";
import { SystemKubernetesClusterHelmStatus, SystemKubernetesClusterHelper } from "../../utilities";
import { CommandItem, HelmItem } from "./components";

export class AddOrEditContext {
    constructor(
        public systemKubernetesClusterModel?: Contract.SystemKubernetesClusterModel) {
    }
}

export const [useAddOrEditContext, useSetAddOrEditContext, useAddOrEditContextProvider] = makeContextProvider<AddOrEditContext>();

export function AddOrEdit() {
    const { addOrEditOpen } = useSystemKubernetesClustersContext();
    const setSystemKubernetesClustersContext = useSetSystemKubernetesClustersContext();

    const [context, , ContextProvider] =
        useAddOrEditContextProvider(
            () =>
                new AddOrEditContext(
                    _.isBoolean(addOrEditOpen)
                        ? undefined
                        : addOrEditOpen));

    const localization =
        useLocalization(
            "views.customer.kubernetes.systemKubernetesClusters.addOrEdit",
            () => ({
                finishButtonTitle: "Close",
                steps: {
                    command: "Run Helm Command",
                    helm: "Set up Helm"
                },
                title: {
                    empty: "Add Clusters via Helm",
                    helmStatus: {
                        [SystemKubernetesClusterHelmStatus.Installed]: "Upgrade Helm",
                        [SystemKubernetesClusterHelmStatus.NotInstalled]: "Install Helm",
                        [SystemKubernetesClusterHelmStatus.Outdated]: "Upgrade Helm"
                    }
                }
            }));

    const helmStatus = SystemKubernetesClusterHelper.getHelmStatus(context.systemKubernetesClusterModel);
    return (
        <ContextProvider>
            <FormLayout
                disableContentPadding={true}
                titleOptions={{
                    text:
                        _.isNil(context.systemKubernetesClusterModel)
                            ? localization.title.empty()
                            : localization.title.helmStatus[helmStatus]()
                }}>
                <OrderedWizard
                    finishItemButtonTitle={localization.finishButtonTitle()}
                    startItemIndex={
                        helmStatus === SystemKubernetesClusterHelmStatus.NotInstalled
                            ? 0
                            : 1}
                    onClose={
                        () =>
                            setSystemKubernetesClustersContext(
                                systemKubernetesClustersContext => ({
                                    ...systemKubernetesClustersContext,
                                    addOrEditOpen: false
                                }))}>
                    <OrderedWizardItem title={localization.steps.helm()}>
                        <HelmItem/>
                    </OrderedWizardItem>
                    <OrderedWizardItem title={localization.steps.command()}>
                        <CommandItem/>
                    </OrderedWizardItem>
                </OrderedWizard>
            </FormLayout>
        </ContextProvider>);
}