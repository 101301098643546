import { Steps, useLocalization, useSetOrderedWizardContext } from "@infrastructure";
import React, { useEffect } from "react";
import { InstructionsImageUrl } from "./images";

export function PermissionsItem() {
    const setOrderedWizardContext = useSetOrderedWizardContext();
    useEffect(
        () => {
            setOrderedWizardContext(
                wizardContext => ({
                    ...wizardContext,
                    sideElement: <img src={InstructionsImageUrl}/>
                }));
        },
        []);

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useTicketingItems.jira.addOrEditProject.permissionsItem",
            () => ({
                steps: {
                    step1: "Go to the \"**Projects**\" page and choose the project you would like to add",
                    step2: "On the side menu go to \"**Project settings**\"",
                    step3: "On the side menu go to \"**Access**\" and then \"**Add People**\"",
                    step4: "Choose the Tenable Cloud Security user and Click \"**Add**\""
                }
            }));

    return (
        <Steps variant="letters">
            {localization.steps.step1()}
            {localization.steps.step2()}
            {localization.steps.step3()}
            {localization.steps.step4()}
        </Steps>);
}