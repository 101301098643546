import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { EntityExternalConsoleLink } from "../../components";
import { useGetGcpStorageBucketRiskContext } from "../contexts";

export function useGcpStorageBucketLockedRetentionPolicyNotEnabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.GcpStorageBucketLockedRetentionPolicyNotEnabledRisk;
    const bucketModel = entityModelStore.useGet(risk.entityId) as Contract.GcpStorageBucketModel;
    const bucket = bucketModel.entity as Contract.GcpStorageBucket;

    const getGcpStorageBucketRiskContext = useGetGcpStorageBucketRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.useGcpStorageBucketLockedRetentionPolicyNotEnabledRiskDefinition",
            () => ({
                description: "{{bucket}} lock policy is not set",
                sections: {
                    resolution: {
                        step1: "Select the **PROTECTION** tab and go to the **Bucket retention policy** section",
                        step2: "Click **SET RETENTION POLICY**",
                        step3: "Configure the desired duration period and click **SAVE**",
                        step4: "Before locking the retention policy, please note this action is permanent. To proceed, click on **LOCK**"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            bucket:
                <Entity
                    entityIdOrModel={bucketModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () =>
            _([
                <EntityExternalConsoleLink
                    entityId={risk.entityId}
                    key={risk.entityId}
                    page={Contract.GcpConsolePage.Resource}/>,
                localization.sections.resolution.step1()
            ]).
                concatIf(
                    _.isNil(bucket.defaultObjectRetentionTimeFrame),
                    () => [
                        localization.sections.resolution.step2(),
                        localization.sections.resolution.step3()
                    ]).
                concat(localization.sections.resolution.step4()).
                value(),
        riskModel,
        () => {
            const bucketRiskContext = getGcpStorageBucketRiskContext(bucketModel);
            return [
                bucketRiskContext.generalInformation,
                bucketRiskContext.sensitive,
                bucketRiskContext.accessLevel,
                bucketRiskContext.loggingLogSinks,
                bucketRiskContext.retentionPolicy,
                bucketRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}