import { Sx } from "@infrastructure";
import { Typography } from "@mui/material";
import React from "react";
import { Tenant, TenantProps } from "..";

type TenantCellProps = TenantProps;

export function TenantCell({ colored = true, iconSx, sx, tenantId, tenantNameTranslatorOptions, variant = "iconText" }: TenantCellProps) {
    return (
        <Typography
            noWrap={true}>
            <Tenant
                colored={colored}
                iconSx={
                    Sx.combine(
                        {
                            fontSize: "20px"
                        },
                        iconSx)}
                sx={sx}
                tenantId={tenantId}
                tenantNameTranslatorOptions={tenantNameTranslatorOptions}
                variant={variant}/>
        </Typography>);
}