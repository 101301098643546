import { Step, Steps, TextSnippet, TextSnippetItem, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { useTheme } from "../../../../../../../../../../../../../common";
import { GcpCloudShellIcon } from "../../../../../../../../../../../../../tenants";
import { useAddOrEditContext } from "../../../index";

export function CloudShell() {
    const { gciTenantModel, organizationRawShortId, tenantOnboardingInfo } = useAddOrEditContext();

    const localization =
        useLocalization(
            "views.customer.scopes.hooks.useDefinition.hooks.useGcpDefinition.addOrEdit.organizationRolesItem.cloudShell",
            () => ({
                steps: {
                    step1: "Log into your Google Cloud Console.",
                    step2: "**Activate the Cloud Shell** by clicking {{cloudShellIcon}} on the top right of the console.",
                    step3: "**Run** the following script in your Cloud Shell:",
                    step4: "Wait until the script has completed successfully."
                }
            }));

    const cmdletCode =
        useMemo(
            () => {
                const serviceAccountOrganizationReadOnlyPermissionActionsCode =
                    _(tenantOnboardingInfo.serviceAccountOrganizationReadOnlyPermissionActions).
                        map(serviceAccountOrganizationReadOnlyPermissionAction => `   '${serviceAccountOrganizationReadOnlyPermissionAction}'`).
                        join("\n");
                return _.join([
                    `permissions=(\n${serviceAccountOrganizationReadOnlyPermissionActionsCode})`,
                    ``,
                    `if gcloud iam roles describe TenableOrganizationViewer --organization=${organizationRawShortId} ; then`,
                    `    gcloud iam roles update TenableOrganizationViewer --organization=${organizationRawShortId} --permissions=$(IFS=,; printf '%s' "\${permissions[*]}")`,
                    `else`,
                    `    gcloud iam roles create TenableOrganizationViewer --description="Minimal read permissions required by Tenable Cloud Security at the organization scope" --organization=${organizationRawShortId} --permissions=$(IFS=,; printf '%s' "\${permissions[*]}") --title="Tenable Organization Viewer"`,
                    `fi`,
                    ``,
                    `gcloud organizations add-iam-policy-binding ${organizationRawShortId} --member serviceAccount:${gciTenantModel!.configuration.serviceAccountMail} --role organizations/${organizationRawShortId}/roles/TenableOrganizationViewer`],
                "\n");
            },
            []);

    const theme = useTheme();
    return (
        <Steps variant="letters">
            {localization.steps.step1()}
            {localization.steps.step2({
                cloudShellIcon:
                    <GcpCloudShellIcon
                        sx={{
                            display: "inline",
                            fontSize: "18px",
                            verticalAlign: "middle"
                        }}/>
            })}
            {new Step(
                localization.steps.step3(),
                {
                    contentElement:
                        <TextSnippet
                            height={theme.spacing(52)}
                            items={[
                                new TextSnippetItem(
                                    cmdletCode,
                                    "")
                            ]}/>
                })}
            {localization.steps.step4()}
        </Steps>);
}