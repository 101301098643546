import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, DeferredFilter, EnumValuesFilter, optionalTableCell, PagedValuesFilter, TimeFormatter, TimeRangeFilter, useLocalization } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { usePingIdentityDirectoryUserStatusTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";
import { usePingIdentityCommonDirectoryEntityDefinition } from "./usePingIdentityCommonDirectoryEntityDefinition";

export function usePingIdentityDirectoryUserDefinition(definitionData: DefinitionData) {
    const userModelFiltersPromise = definitionData.entityModelFiltersPromise as Promise<Contract.PingIdentityDirectoryUserModelFilters>;
    const commonDirectoryEntityDefinition = usePingIdentityCommonDirectoryEntityDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const directoryUserStatusTranslator = usePingIdentityDirectoryUserStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.pingIdentity.usePingIdentityDirectoryUserDefinition",
            () => ({
                columns: {
                    activityTime: {
                        empty: "Not seen",
                        title: "AWS Activity Time"
                    },
                    awsRoles: "AWS Roles",
                    groups: "Groups",
                    mail: "Mail",
                    status: "Status"
                }
            }));

    function getActivityPastDurationTranslation(entityModel: Contract.EntityModel) {
        const userProfile = entityModel.entityProfile as Contract.PingIdentityDirectoryUserProfile;
        return _.isNil(userProfile?.activityTime)
            ? localization.columns.activityTime.empty()
            : TimeFormatter.humanizePastDuration(userProfile.activityTime);
    }

    return new EntityTableDefinition(
        [
            commonDirectoryEntityDefinition.columns.tenantColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.PingIdentityDirectoryUserModel) => ({
                            [localization.columns.mail()]: (item.entity as Contract.PingIdentityDirectoryUser).mail ?? ""
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedValuesFilter
                                getValuePage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.PingIdentityDirectoryUserMail)}
                                placeholder={localization.columns.mail()}/>
                    }
                }}
                id={Contract.EntityModelProperty.PingIdentityDirectoryUserMail}
                key={Contract.EntityModelProperty.PingIdentityDirectoryUserMail}
                render={optionalTableCell<Contract.PingIdentityDirectoryUserModel>(item => (item.entity as Contract.PingIdentityDirectoryUser).mail)}
                title={localization.columns.mail()}/>,
            commonDirectoryEntityDefinition.columns.creationTimeColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.PingIdentityDirectoryUserModel) => ({
                            [localization.columns.status()]: directoryUserStatusTranslator((item.entity as Contract.PingIdentityDirectoryUser).status)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.PingIdentityDirectoryUserStatus}
                                enumTypeTranslator={directoryUserStatusTranslator}
                                placeholder={localization.columns.status()}/>
                    }
                }}
                id={Contract.EntityModelProperty.PingIdentityDirectoryUserStatus}
                itemProperty={(item: Contract.EntityModel) => directoryUserStatusTranslator((item.entity as Contract.PingIdentityDirectoryUser).status)}
                key={Contract.EntityModelProperty.PingIdentityDirectoryUserStatus}
                title={localization.columns.status()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.PingIdentityDirectoryUserModel) => ({
                            [localization.columns.activityTime.title()]: getActivityPastDurationTranslation(item)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <DeferredFilter
                                promiseOrGetPromise={userModelFiltersPromise}
                                title={localization.columns.activityTime.title()}>
                                {filters =>
                                    <TimeRangeFilter
                                        emptyValue={filters.activityTimeRange.emptyValue}
                                        placeholder={localization.columns.activityTime.title()}
                                        timeRange={TimeRangeHelper.getTimeRangeFilterRange(filters.activityTimeRange.timeRange)}/>}
                            </DeferredFilter>
                    }
                }}
                id={Contract.EntityModelProperty.PingIdentityDirectoryUserActivityTime}
                itemProperty={getActivityPastDurationTranslation}
                key={Contract.EntityModelProperty.PingIdentityDirectoryUserActivityTime}
                sortOptions={{ type: DataTableSortType.Date }}
                title={localization.columns.activityTime.title()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.PingIdentityDirectoryUserModel>(
                        Contract.TypeNames.AwsIamRole,
                        item => item.awsRoleIds,
                        localization.columns.awsRoles())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.PingIdentityDirectoryUserAwsRoles)}
                                placeholder={localization.columns.awsRoles()}/>
                    }
                }}
                id={Contract.EntityModelProperty.PingIdentityDirectoryUserAwsRoles}
                key={Contract.EntityModelProperty.PingIdentityDirectoryUserAwsRoles}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.PingIdentityDirectoryUserModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.awsRoleIds}
                            entityTypeName={Contract.TypeNames.AwsIamRole}
                            entityVariant="iconText"/>}
                title={localization.columns.awsRoles()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.PingIdentityDirectoryUserModel>(
                        Contract.TypeNames.PingIdentityDirectoryGroup,
                        item => item.groupIds,
                        localization.columns.groups())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.PingIdentityDirectoryUserGroups)}
                                placeholder={localization.columns.groups()}/>
                    }
                }}
                id={Contract.EntityModelProperty.PingIdentityDirectoryUserGroups}
                key={Contract.EntityModelProperty.PingIdentityDirectoryUserGroups}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.PingIdentityDirectoryUserModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.groupIds}
                            entityTypeName={Contract.TypeNames.PingIdentityDirectoryGroup}
                            entityVariant="iconText"/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.groups()}/>,
            commonDirectoryEntityDefinition.columns.attributesColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPagePingIdentityDirectoryUserRequest(
                new Contract.EntityControllerGetEntityModelPagePingIdentityDirectoryUserRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.PingIdentityDirectoryEntityCreationTime]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.PingIdentityDirectoryUserActivityTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.PingIdentityDirectoryUserAwsRoles]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.PingIdentityDirectoryUserGroups]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.PingIdentityDirectoryUserMail]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.PingIdentityDirectoryUserStatus])),
                limit,
                skip,
                requestSort));
}