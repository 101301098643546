import { DataTableColumn, DataTableSortType, DeferredFilter, optionalTableCell, TimeFormatter, TimeRangeFilter, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, InlineResourceTags, PagedEntityResourceTagFilter, ResourceTagHelper, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";
import { useCommonEntityDefinition } from "../useCommonEntityDefinition";

export function useAwsCommonResourceDefinition(definitionData: DefinitionData) {
    const commonEntityDefinition = useCommonEntityDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();
    const resourceModelFiltersPromise = definitionData.entityModelFiltersPromise as Promise<Contract.AwsResourceModelFilters>;

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.aws.useAwsCommonResourceDefinition",
            () => ({
                columns: {
                    creationTime: "Creation Time",
                    creatorIdentityIdReference: "Created By Identity",
                    creatorOriginatorEntityIdReference: "Created By Originator",
                    tags: "Tags"
                }
            }));
    const getCreationTimeColumn =
        (title: string) =>
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsResourceModel) => ({
                            [title]:
                                _.isNil(item.creationTime)
                                    ? ""
                                    : TimeFormatter.iso8601String(item.creationTime)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <DeferredFilter
                                promiseOrGetPromise={resourceModelFiltersPromise}
                                title={title}>
                                {filters =>
                                    <TimeRangeFilter
                                        emptyValue={filters.creationTimeRange.emptyValue}
                                        placeholder={title}
                                        timeRange={TimeRangeHelper.getTimeRangeFilterRange(filters.creationTimeRange.timeRange)}/>}
                            </DeferredFilter>
                    }
                }}
                id={Contract.EntityModelProperty.ResourceCreationTime}
                key={Contract.EntityModelProperty.ResourceCreationTime}
                render={
                    optionalTableCell<Contract.AwsResourceModel>(
                        item =>
                            _.isNil(item.creationTime)
                                ? undefined
                                : TimeFormatter.monthDayAndYear((item as Contract.AwsResourceModel).creationTime))}
                sortOptions={{ type: DataTableSortType.Date }}
                title={title}/>;

    const getTagsColumn =
        (title: string) =>
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsResourceModel) => ({
                            [title]:
                                _((item.entity as Contract.AwsResource).tags).
                                    map(ResourceTagHelper.getDisplayName).
                                    join("\n")
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityResourceTagFilter
                                entityTypeName={definitionData.entityTypeEntitiesViewName}
                                placeholder={title}/>
                    }
                }}
                id={Contract.EntityModelProperty.ResourceTags}
                key={Contract.EntityModelProperty.ResourceTags}
                render={
                    optionalTableCell<Contract.AwsResourceModel>(
                        (item: Contract.AwsResourceModel) =>
                            _.isEmpty((item.entity as Contract.AwsResource).tags)
                                ? undefined
                                : <InlineResourceTags tags={(item.entity as Contract.AwsResource).tags}/>)}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={title}/>;

    return {
        columns: {
            ...commonEntityDefinition.columns,
            creationTimeColumn: getCreationTimeColumn(localization.columns.creationTime()),
            creatorIdentityCsvColumn:
                <DataTableColumn
                    exportOptions={
                        entitiesExportOptions<Contract.AwsResourceModel>(
                            Contract.TypeNames.AwsIamPrincipal,
                            item => [item.creatorIdentityIdReference],
                            localization.columns.creatorIdentityIdReference())}
                    id="creatorIdentityIdReference"
                    key="creatorIdentityIdReference"/>,
            creatorOriginatorEntityCsvColumn:
                <DataTableColumn
                    exportOptions={
                        entitiesExportOptions<Contract.AwsResourceModel>(
                            Contract.TypeNames.AwsIamPrincipal,
                            item => [item.creatorOriginatorEntityIdReference],
                            localization.columns.creatorOriginatorEntityIdReference())}
                    id="creatorOriginatorEntityIdReference"
                    key="creatorOriginatorEntityIdReference"/>,
            tagsColumn: getTagsColumn(localization.columns.tags())
        },
        getCreationTimeColumn,
        getTagsColumn
    };
}