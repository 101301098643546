import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useOciDefaultResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { useCustomEntityPropertyInfoItemElements } from "..";
import { Contract, InfoItem, useEntityTypeNameTranslator } from "../../../../../../../../../../common";
import { useOciFssMountTargetStatusTranslator } from "../../../../../../hooks";
import { EntitiesInfoItem, Info } from "../../../../components";

export function useOciFssMountTargetDefinition(resourceModel: Contract.OciResourceModel, options?: DefinitionOptions) {
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useOciDefaultResourceInfoItemElements(resourceModel);
    const mountTargetModel = resourceModel as Contract.OciFssMountTargetModel;
    const mountTarget = resourceModel.entity as Contract.OciFssMountTarget;

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const mountTargetStatusTranslator = useOciFssMountTargetStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.oci.useOciFssMountTargetDefinition",
            () => ({
                info: {
                    items: {
                        privateIpAddress: "IP Address",
                        status: "State"
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="status"
                        title={localization.info.items.status()}
                        value={
                            _.isNil(mountTarget.status)
                                ? undefined
                                : mountTargetStatusTranslator(mountTarget.status!)}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={mountTargetModel.fileSystemIdReferences}
                        entityTypeName={Contract.TypeNames.OciFssFileSystem}
                        key="fileSystems"
                        title={
                            entityTypeNameTranslator(
                                Contract.TypeNames.OciFssFileSystem,
                                { count: 0 })}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={mountTargetModel.exportIds}
                        entityTypeName={Contract.TypeNames.OciFssMountTargetExport}
                        key="exports"
                        location="all"
                        title={
                            entityTypeNameTranslator(
                                Contract.TypeNames.OciFssMountTargetExport,
                                { count: 0 })}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={mountTargetModel.vcnIdReference}
                        entityTypeName={Contract.TypeNames.OciNetworkingVcn}
                        key="vcn"
                        title={entityTypeNameTranslator(Contract.TypeNames.OciNetworkingVcn)}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={mountTargetModel.subnetIdReference}
                        entityTypeName={Contract.TypeNames.OciNetworkingSubnet}
                        key="subnet"
                        title={entityTypeNameTranslator(Contract.TypeNames.OciNetworkingSubnet)}/>,
                    <InfoItem
                        key="privateIpAddress"
                        location="all"
                        title={localization.info.items.privateIpAddress()}
                        value={mountTarget.privateIpAddress}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={mountTargetModel.networkSecurityGroupIdReferences}
                        entityTypeName={Contract.TypeNames.OciNetworkingNetworkSecurityGroup}
                        key="networkSecurityGroups"
                        title={
                            entityTypeNameTranslator(
                                Contract.TypeNames.OciNetworkingNetworkSecurityGroup,
                                { count: 0 })}/>
                ]}
                options={options?.infoOptions}/>
    });
}