﻿import _ from "lodash";
import { useMemo } from "react";
import { DataTableSortDirection, DataTableSortType, useLocalization } from "@infrastructure";
import { useCommonGroupedRiskPolicyTypeColumns } from "../../..";
import { Contract, GroupedTableColumn, LicensingHelper } from "../../../../../../../../../../../../../../common";
import { RisksItemsFilterId } from "../../../../../../../../../Filters";
import { GroupedItem, GroupedTableColumnId } from "../../../../../../Grouped";
import { GroupedDefinition } from "../../../../useDefinition";
import { Tenants } from "./components";

export function useRiskPolicyTypeDefinition() {
    const commonColumns = useCommonGroupedRiskPolicyTypeColumns();
    const localization =
        useLocalization(
            "views.customer.risks.items.grouped.hooks.useDefinition.hooks.useCloudDefinition.hooks.useRiskPolicyTypeDefinition",
            () => ({
                columns: {
                    tenants: "Accounts"
                }
            }));

    return useMemo(
        (): GroupedDefinition => ({
            columns:
                _<GroupedTableColumn>([]).
                    concat(
                        commonColumns.riskPolicyCategory,
                        commonColumns.riskPolicyConfigurationTypeName,
                        commonColumns.riskCount,
                        {
                            element: Tenants,
                            filterExternalId: RisksItemsFilterId[Contract.RiskFilterId.TenantId],
                            id: GroupedTableColumnId.Tenants,
                            minWidth: 150,
                            sortOptions: {
                                getIterates: () => [(item: GroupedItem) => (item.riskGroup as Contract.RiskControllerGetRiskGroupsResponseRiskPolicyTypeGroup).tenantIds.length],
                                type: DataTableSortType.Numeric
                            },
                            title: localization.columns.tenants(),
                            width: 2
                        }).
                    concatIf(
                        LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.Cspm),
                        commonColumns.compliances).
                    concat(commonColumns.severity).
                    value(),
            defaultSort: {
                columnId: commonColumns.severity.id,
                direction: DataTableSortDirection.Descending
            },
            groupRequestType: Contract.RiskControllerGetRiskGroupsRequestType.RiskPolicyType,
            riskGroupToId: riskGroup => (riskGroup as Contract.RiskControllerGetRiskGroupsResponseRiskPolicyTypeGroup).riskPolicyConfigurationTypeName
        }),
        [commonColumns]);
}