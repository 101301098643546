﻿import { SvgIconProps } from "@mui/material";
import React, { Fragment } from "react";
import { Contract } from "../../";
import { AfricaIcon, AsiaIcon, AsiaPacificIcon, AustraliaIcon, BahrainIcon, BangladeshIcon, BelgiumIcon, BrazilIcon, CanadaIcon, ChileIcon, ChinaIcon, ColombiaIcon, EuropeIcon, FinlandIcon, FranceIcon, GermanyIcon, GlobalIcon, IndiaIcon, IndonesiaIcon, IrelandIcon, IsraelIcon, ItalyIcon, JapanIcon, MalaysiaIcon, MexicoIcon, NetherlandsIcon, NewZealandIcon, NorthAmericaIcon, NorwayIcon, OmanIcon, PolandIcon, QatarIcon, SaudiArabiaIcon, SerbiaIcon, SingaporeIcon, SouthAfricaIcon, SouthKoreaIcon, SpainIcon, SwedenIcon, SwitzerlandIcon, ThailandIcon, UnitedArabEmiratesIcon, UnitedKingdomIcon, UnitedStatesIcon } from "./icons";

type RegionLocationIconProps =
    SvgIconProps & {
        regionLocation: Contract.RegionLocation;
    };

export function RegionLocationIcon({ regionLocation, ...props }: RegionLocationIconProps) {
    switch (regionLocation) {
        case Contract.RegionLocation.Africa:
            return <AfricaIcon {...props}/>;
        case Contract.RegionLocation.Asia:
            return <AsiaIcon {...props}/>;
        case Contract.RegionLocation.AsiaPacific:
            return <AsiaPacificIcon {...props}/>;
        case Contract.RegionLocation.Australia:
            return <AustraliaIcon {...props}/>;
        case Contract.RegionLocation.Bahrain:
            return <BahrainIcon {...props}/>;
        case Contract.RegionLocation.Bangladesh:
            return <BangladeshIcon {...props}/>;
        case Contract.RegionLocation.Belgium:
            return <BelgiumIcon {...props}/>;
        case Contract.RegionLocation.Brazil:
            return <BrazilIcon {...props}/>;
        case Contract.RegionLocation.Canada:
            return <CanadaIcon {...props}/>;
        case Contract.RegionLocation.Chile:
            return <ChileIcon {...props}/>;
        case Contract.RegionLocation.China:
            return <ChinaIcon {...props}/>;
        case Contract.RegionLocation.Colombia:
            return <ColombiaIcon {...props}/>;
        case Contract.RegionLocation.Europe:
            return <EuropeIcon {...props}/>;
        case Contract.RegionLocation.Finland:
            return <FinlandIcon {...props}/>;
        case Contract.RegionLocation.France:
            return <FranceIcon {...props}/>;
        case Contract.RegionLocation.Germany:
            return <GermanyIcon {...props}/>;
        case Contract.RegionLocation.Global:
            return <GlobalIcon {...props}/>;
        case Contract.RegionLocation.India:
            return <IndiaIcon {...props}/>;
        case Contract.RegionLocation.Indonesia:
            return <IndonesiaIcon {...props}/>;
        case Contract.RegionLocation.Ireland:
            return <IrelandIcon {...props}/>;
        case Contract.RegionLocation.Israel:
            return <IsraelIcon {...props}/>;
        case Contract.RegionLocation.Italy:
            return <ItalyIcon {...props}/>;
        case Contract.RegionLocation.Japan:
            return <JapanIcon {...props}/>;
        case Contract.RegionLocation.Malaysia:
            return <MalaysiaIcon {...props}/>;
        case Contract.RegionLocation.Mexico:
            return <MexicoIcon {...props}/>;
        case Contract.RegionLocation.Netherlands:
            return <NetherlandsIcon {...props}/>;
        case Contract.RegionLocation.NewZealand:
            return <NewZealandIcon {...props}/>;
        case Contract.RegionLocation.NorthAmerica:
            return <NorthAmericaIcon {...props}/>;
        case Contract.RegionLocation.Norway:
            return <NorwayIcon {...props}/>;
        case Contract.RegionLocation.Oman:
            return <OmanIcon {...props}/>;
        case Contract.RegionLocation.Poland:
            return <PolandIcon {...props}/>;
        case Contract.RegionLocation.Qatar:
            return <QatarIcon {...props}/>;
        case Contract.RegionLocation.SaudiArabia:
            return <SaudiArabiaIcon {...props}/>;
        case Contract.RegionLocation.Serbia:
            return <SerbiaIcon {...props}/>;
        case Contract.RegionLocation.Singapore:
            return <SingaporeIcon {...props}/>;
        case Contract.RegionLocation.SouthAfrica:
            return <SouthAfricaIcon {...props}/>;
        case Contract.RegionLocation.SouthKorea:
            return <SouthKoreaIcon {...props}/>;
        case Contract.RegionLocation.Spain:
            return <SpainIcon {...props}/>;
        case Contract.RegionLocation.Sweden:
            return <SwedenIcon {...props}/>;
        case Contract.RegionLocation.Switzerland:
            return <SwitzerlandIcon {...props}/>;
        case Contract.RegionLocation.Thailand:
            return <ThailandIcon {...props}/>;
        case Contract.RegionLocation.UnitedArabEmirates:
            return <UnitedArabEmiratesIcon {...props}/>;
        case Contract.RegionLocation.UnitedKingdom:
            return <UnitedKingdomIcon {...props}/>;
        case Contract.RegionLocation.UnitedStates:
        case Contract.RegionLocation.UnitedStatesGov:
            return <UnitedStatesIcon {...props}/>;
        default:
            return <Fragment/>;
    }
}