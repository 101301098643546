import { makeContextProvider } from "@infrastructure";
import _, { Dictionary } from "lodash";
import { Contract } from "../../..";

export const [useRiskResolutionAutomationContext, useSetRiskResolutionAutomationContext, useRiskResolutionAutomationContextProvider] = makeContextProvider<RiskResolutionAutomationContext>();

export class RiskResolutionAutomationContext {
    public isChangeResolvable: (changeModel: Contract.ChangeModel) => boolean;
    public someChangeableSomeNotChangeable: boolean;
    public updatedChangeMap: Dictionary<Contract.Change> = {};

    constructor(
        public changeModels: Contract.ChangeModel[],
        public riskedEntityModel: Contract.EntityModel) {
        this.isChangeResolvable = changeModel => !changeModel.resolved && changeModel.resolvable;

        const resolvableChangeModels = _.filter(changeModels, changeModel => this.isChangeResolvable(changeModel));
        this.someChangeableSomeNotChangeable =
            _.some(resolvableChangeModels, changeModel => changeModel.changeable) &&
            _.some(resolvableChangeModels, changeModel => !changeModel.changeable);
    }
}