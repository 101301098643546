import _ from "lodash";
import React from "react";
import { UdmObjectPropertyItemProps } from "../..";
import { TenantsIcon } from "../../../../../icons";

export function CommonTenantType({ item }: UdmObjectPropertyItemProps) {
    return (
        <TenantsIcon
            sx={{ fontSize: "16px" }}
            tenantTypes={_.concat(item)}
            variant="iconText"/>);
}