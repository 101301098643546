import { Link, LinkProps, NumberFormatter, Shadows } from "@infrastructure";
import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { RiskPolicyCategoryIcon, useRiskPolicyCategoryTranslator, useTheme } from "../../..";
import { RiskPolicyCategoryItem } from "..";

type ItemProps = Pick<LinkProps, "urlOrGetUrl"> & {
    highlight: boolean;
    item: RiskPolicyCategoryItem;
    report?: boolean;
};

export function Item({ highlight, item, report = false, urlOrGetUrl }: ItemProps) {
    const riskPolicyCategoryTranslator = useRiskPolicyCategoryTranslator();
    const theme = useTheme();
    return (
        <Link
            disabled={report}
            urlOrGetUrl={urlOrGetUrl}
            variant="text">
            <Stack
                alignItems="center"
                direction="row"
                sx={{
                    background:
                        highlight
                            ? theme.palette.background.alternate
                            : undefined,
                    border:
                        highlight
                            ? theme.border.alternate
                            : theme.border.transparent,
                    borderBottomRightRadius: theme.spacing(1),
                    borderTopRightRadius: theme.spacing(1),
                    boxShadow:
                        highlight
                            ? theme.shadows[Shadows.Highlight]
                            : undefined,
                    height: theme.spacing(5),
                    width: theme.spacing(25)
                }}>
                <Box
                    sx={{
                        backgroundColor: item.color,
                        height:
                            highlight
                                ? theme.spacing(5)
                                : theme.spacing(3.2),
                        marginRight: theme.spacing(1),
                        width: theme.spacing(0.4)
                    }}/>
                <Box sx={{ paddingRight: theme.spacing(1) }}>
                    <RiskPolicyCategoryIcon
                        riskPolicyCategory={item.riskPolicyCategory}
                        sx={{
                            color: theme.palette.text.primary,
                            fontSize: "20px",
                            marginRight: theme.spacing(1)
                        }}/>
                </Box>
                <Stack>
                    <Typography sx={{ fontWeight: 600 }}>
                        {riskPolicyCategoryTranslator(item.riskPolicyCategory)}
                    </Typography>
                    <Typography>
                        {NumberFormatter.humanize(item.riskCount)}
                    </Typography>
                </Stack>
            </Stack>
        </Link>);
}