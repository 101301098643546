import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { EntityExternalConsoleLink } from "../../components";
import { useGetGcpSqlInstanceRiskContext } from "../contexts";

export function useGcpSqlInstancePostgreSqlLogErrorVerbosityRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.GcpSqlInstancePostgreSqlLogErrorVerbosityRisk;
    const instanceModel = entityModelStore.useGet(risk.entityId) as Contract.GcpSqlInstanceModel;
    const instance = instanceModel.entity as Contract.GcpSqlInstance;

    const getGcpSqlInstanceRiskContext = useGetGcpSqlInstanceRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.useGcpSqlInstancePostgreSqlLogErrorVerbosityRiskDefinition",
            () => ({
                description: "{{instance}} 'log_error_verbosity' flag is set to {{logErrorVerbosity}}",
                logErrorVerbosity: {
                    [Contract.TypeNames.GcpSqlInstancePostgreSqlLogVerbosity]: {
                        [Contract.GcpSqlInstancePostgreSqlLogVerbosity.Default]: "Default",
                        [Contract.GcpSqlInstancePostgreSqlLogVerbosity.Terse]: "Terse",
                        [Contract.GcpSqlInstancePostgreSqlLogVerbosity.Verbose]: "Verbose"
                    }
                },
                sections: {
                    resolution: {
                        step1: "Under **Flags**, set the flag **log_error_verbosity** to **Default** or stricter and click on **Done**",
                        step2: "Click **SAVE** at the bottom to confirm"
                    }
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            instance:
                <Entity
                    entityIdOrModel={instanceModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>,
            logErrorVerbosity: localization.logErrorVerbosity[Contract.TypeNames.GcpSqlInstancePostgreSqlLogVerbosity][instance.postgreSqlLogErrorVerbosity!]()
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={risk.entityId}
                key={risk.entityId}
                page={Contract.GcpConsolePage.Edit}/>,
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2()
        ],
        riskModel,
        () => {
            const instanceRiskContext = getGcpSqlInstanceRiskContext(instanceModel);
            return [
                instanceRiskContext.generalInformation,
                instanceRiskContext.sensitive,
                instanceRiskContext.inboundExternalAccessScope,
                instanceRiskContext.status,
                instanceRiskContext.databaseVersion,
                instanceRiskContext.getFlagContextItem(
                    "log_error_verbosity",
                    localization.logErrorVerbosity[Contract.TypeNames.GcpSqlInstancePostgreSqlLogVerbosity][instance.postgreSqlLogErrorVerbosity!]()
                ),
                instanceRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        });
}