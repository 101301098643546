import { Dropdown, Loading, NoneIcon, useExecuteOperation, useLocalization } from "@infrastructure";
import { Box, Typography } from "@mui/material";
import React from "react";
import { Contract, Entities, EntityController, useTheme } from "../../../common";

type GcpSpannerInstanceDatabasesItemProps = {
    databasesCount: number;
    instanceId: string;
};

export function GcpSpannerInstanceDatabasesItem({ databasesCount, instanceId }: GcpSpannerInstanceDatabasesItemProps) {
    const localization =
        useLocalization(
            "tenants.gcp.gcpSpannerInstanceDatabasesItem",
            () => ({
                databases: [
                    "1 database",
                    "{{count | NumberFormatter.humanize}} databases"
                ]
            }));
    const theme = useTheme();
    return (
        <Dropdown
            disabled={databasesCount === 0}
            popoverElement={
                <Loading container="popup">
                    <Box
                        sx={{
                            maxWidth: theme.spacing(60),
                            minWidth: theme.spacing(30),
                            padding: theme.spacing(1)
                        }}>
                        <Popover instanceId={instanceId}/>
                    </Box>
                </Loading>}
            popoverElementContainerSx={{ padding: 0 }}>
            <Typography
                sx={{
                    color: theme.palette.text.primary,
                    fontWeight: 600,
                    whiteSpace: "nowrap",
                    ...(databasesCount === 0
                        ? undefined
                        : {
                            cursor: "pointer",
                            textDecoration: "underline"
                        })
                }}>
                {databasesCount === 0
                    ? <NoneIcon/>
                    : localization.databases(databasesCount)}
            </Typography>
        </Dropdown>);
}

type PopoverProps = {
    instanceId: string;
};

function Popover({ instanceId }: PopoverProps) {
    const [{ databaseIds: databaseIds }]: [Contract.EntityControllerGetGcpSpannerInstanceDatabaseIdsResponse, () => Promise<void>] =
        useExecuteOperation(
            [Popover, instanceId],
            () => EntityController.getGcpSpannerInstanceDatabaseIds(new Contract.EntityControllerGetGcpSpannerInstanceDatabaseIdsRequest(instanceId)));
    return (
        <Entities
            entityIdsOrModels={databaseIds}
            entityTypeName={Contract.TypeNames.GcpSpannerInstanceDatabase}
            entityVariant="iconTextTypeTenant"
            searchEnabled={true}
            variant="dropdown"/>);
}