import { EffectCallback, useLayoutEffect, useRef } from "react";

export function useLayoutChangeEffect(
    effect: EffectCallback,
    dependencies?: any[]) {
    const firstEffectRef = useRef(true);
    useLayoutEffect(
        () => {
            if (firstEffectRef.current) {
                firstEffectRef.current = false;
                return;
            }

            return effect();
        },
        dependencies);
}