import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function AnecdotesIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                d="M8.57909 2.25088C5.62703 2.92724 3.92165 4.87485 2.83962 6.12271C1.67526 7.45225 0.252154 9.04999 0.0286899 11.5223C-0.135968 13.4466 0.416811 15.4409 1.46355 17.0503C3.80404 20.6539 8.32034 21.9484 12.4368 21.3303C14.5068 21.0154 16.7531 20.2108 18.1527 18.5897C20.0463 16.4089 20.0698 13.8082 20.0816 12.7469C20.1051 8.81669 17.8822 4.01183 13.6129 2.48411C11.2724 1.65606 9.16715 2.11092 8.57909 2.25088Z"
                fill="#00DCE8"/>
            <path
                d="M15.9568 4.75808C16.5426 9.7078 17.3937 14.6024 18.5764 19.4419C18.919 20.8419 20.588 21.812 21.9807 21.3711C23.4397 20.8971 24.2797 19.486 23.915 17.9757C23.3623 15.7158 22.876 13.4339 22.4559 11.1409C22.246 10.0165 22.0581 8.881 21.8922 7.74557C21.848 7.45896 21.8038 7.18331 21.7707 6.89669C21.7485 6.75342 21.7265 6.61008 21.7154 6.4668C21.7043 6.40065 21.638 5.91558 21.6933 6.30146C21.7485 6.68727 21.6823 6.20219 21.6712 6.13604C21.6491 5.99277 21.638 5.84943 21.6159 5.70615C21.5717 5.38643 21.5386 5.06677 21.4944 4.74704C21.4059 3.98639 21.2512 3.35804 20.6875 2.79585C20.1901 2.33285 19.4053 1.96904 18.7201 2.00209C17.3605 2.06824 15.7689 3.22574 15.9568 4.75808Z"
                fill="#FFA84F"/>
            <path
                clipRule="evenodd"
                d="M16.0516 3.8606C15.9555 4.13976 15.9179 4.44141 15.9568 4.75921C16.5 9.34899 17.2713 13.8913 18.3236 18.3863C20.0423 16.256 20.0696 13.7907 20.0811 12.7571L20.0811 12.7481C20.0997 9.65175 18.7239 6.01252 16.0516 3.8606Z"
                fill="#FF3499"
                fillRule="evenodd"/>
        </SvgIcon>);
}