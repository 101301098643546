﻿import { Action1 } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React, { useEffect, useMemo } from "react";
import { Contract } from "../../../../../controllers";
import { useAccessContext } from "../../../Access";
import { AccessGraphHelper, AccessGraphPermissionEdgeData, EntitiesPermissionActionsEntity } from "../../../utilities";
import { Entities } from "./Entities";

type ServicesProps = {
    entityIdToSearchableReferenceMap: Dictionary<Contract.EntitySearchableReference>;
    excessivePermissionsEnabled: boolean;
    onSelectedServiceChanged: Action1<string>;
    permissionEdgeIdToDataMap: Dictionary<AccessGraphPermissionEdgeData>;
    resourceServiceModelMap: Dictionary<Contract.EntityModel>;
    selectedServiceId?: string;
    sourceEntityId: string;
};

export function Services({ entityIdToSearchableReferenceMap, excessivePermissionsEnabled, onSelectedServiceChanged, permissionEdgeIdToDataMap, resourceServiceModelMap, selectedServiceId, sourceEntityId }: ServicesProps) {
    const { definition } = useAccessContext();

    const services =
        useMemo(
            () => {
                const serviceIdToEntityMap: Dictionary<EntitiesPermissionActionsEntity> = {};
                for (const permissionEdgeData of _.values(permissionEdgeIdToDataMap)) {
                    if (!permissionEdgeData.sourceEntityIds.has(sourceEntityId)) {
                        continue;
                    }

                    let entity = serviceIdToEntityMap[permissionEdgeData.destinationEntitiesServiceId];
                    if (_.isNil(entity)) {
                        entity = new EntitiesPermissionActionsEntity(permissionEdgeData.destinationEntitiesServiceId, 0, 0);
                        serviceIdToEntityMap[permissionEdgeData.destinationEntitiesServiceId] = entity;
                    }

                    entity.excessivePermissionActionCount += permissionEdgeData.excessivePermissionActionCount * permissionEdgeData.destinationEntityIds.size;
                    entity.nonexcessivePermissionActionCount += permissionEdgeData.nonexcessivePermissionActionCount * permissionEdgeData.destinationEntityIds.size;
                }

                return AccessGraphHelper.orderEntities(
                    _.values(serviceIdToEntityMap),
                    entityIdToSearchableReferenceMap,
                    resourceServiceModelMap);
            },
            []);

    useEffect(
        () => {
            onSelectedServiceChanged(services[0].entityId);
        },
        []);

    return (
        <Entities
            entities={services}
            entityTypeName={definition.entitiesPermissionActions.entityTypeNames.serviceTypeName}
            excessivePermissionsEnabled={excessivePermissionsEnabled}
            selectedEntityId={selectedServiceId}
            onSelectedEntityChanged={entity => onSelectedServiceChanged(entity.entityId)}/>);
}