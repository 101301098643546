import { UnexpectedError } from "@infrastructure";
import { useMemo } from "react";
import { RiskPoliciesView } from "../../../..";
import { RiskPolicyConfigurationControllerDefinition } from "../../useRiskPolicyConfigurationControllerDefinition";
import { useCloudRiskPolicyBuiltInConfigurationControllerDefinition, useCloudRiskPolicyCustomConfigurationControllerDefinition } from "./hooks";

export function useCloudRiskPolicyConfigurationControllerDefinition(view: RiskPoliciesView, riskPolicyConfigurationTypeNameOrId: string, scopeId: string): RiskPolicyConfigurationControllerDefinition {
    const useDefinition =
        useMemo(
            () => {
                switch (view) {
                    case RiskPoliciesView.BuiltIn:
                        return useCloudRiskPolicyBuiltInConfigurationControllerDefinition;
                    case RiskPoliciesView.Custom:
                        return useCloudRiskPolicyCustomConfigurationControllerDefinition;
                    default:
                        throw new UnexpectedError("useCloudRiskPolicyConfigurationControllerDefinition.view", view);
                }
            },
            [view]);

    return useDefinition(riskPolicyConfigurationTypeNameOrId, scopeId);
}