import { SvgIconProps } from "@mui/material";
import React, { Fragment } from "react";
import { ScansIcon } from "..";
import { WorkloadAnalysisView } from "../../../views/Customer/components";
import { ContainerImagesIcon, MaliciousFilesIcon, PackagesIcon, VirtualMachinesIcon, VulnerabilitiesIcon } from "./icons";
import { VirtualMachineImagesIcon } from "./icons/VirtualMachineImagesIcon";

type WorkloadAnalysisViewIconProps =
    SvgIconProps & {
        view: WorkloadAnalysisView;
    };

export function WorkloadAnalysisViewIcon({ view, ...props }: WorkloadAnalysisViewIconProps) {
    switch (view) {
        case WorkloadAnalysisView.CodeScans:
            return <ScansIcon {...props}/>;
        case WorkloadAnalysisView.ContainerImages:
            return <ContainerImagesIcon {...props}/>;
        case WorkloadAnalysisView.MaliciousFiles:
            return <MaliciousFilesIcon {...props}/>;
        case WorkloadAnalysisView.Packages:
            return <PackagesIcon {...props}/>;
        case WorkloadAnalysisView.VirtualMachineImages:
            return <VirtualMachineImagesIcon {...props}/>;
        case WorkloadAnalysisView.VirtualMachines:
            return <VirtualMachinesIcon {...props}/>;
        case WorkloadAnalysisView.Vulnerabilities:
            return <VulnerabilitiesIcon {...props}/>;
        default:
            return <Fragment/>;
    }
}