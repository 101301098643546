import { PasswordTextField, Step, Steps, useChangeEffect, useInputValidation, useLocalization, useOrderedWizardContext, useSetOrderedWizardContext } from "@infrastructure";
import { FormControl, FormHelperText, TextField, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { Contract, useTheme } from "../../../../../../../../../../../../../../../../common";
import { useAddOrEditContext, useSetAddOrEditContext } from "../../AddOrEdit";
import { useUpsertTenant } from "../../hooks";
import { InstructionsImageUrl } from "./images";

export function PermissionManagementClientCredentialsItem() {
    const { tenantConfiguration, tenantName, tenantPermissionManagementClientId, tenantPermissionManagementClientSecret, tenantReadClientId, tenantReadClientSecret, tenantUrl } = useAddOrEditContext();
    const setAddOrEditContext = useSetAddOrEditContext();
    const setOrderedWizardContext = useSetOrderedWizardContext();

    const { executing, setLoaded, setValid, useNextEffect } = useOrderedWizardContext();
    useEffect(
        () => {
            setOrderedWizardContext(
                wizardContext => ({
                    ...wizardContext,
                    sideElement: <img src={InstructionsImageUrl}/>
                }));
            setLoaded();
        },
        []);

    const [clientId, setClientId] = useState(tenantPermissionManagementClientId);
    const [clientSecret, setClientSecret] = useState(tenantPermissionManagementClientSecret);

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useIdentityProviderTenantItems.oneLoginTenants.addOrEdit.permissionManagementClientCredentialsItem",
            () => ({
                actions: {
                    save: {
                        error: {
                            add: "Failed to add OneLogin account",
                            edit: "Failed to save OneLogin account",
                            [Contract.TypeNames.TenantControllerInsertOneLoginTenantError]: {
                                [Contract.TenantControllerInsertOneLoginTenantError.AuthenticationFailed]: "Authentication failure",
                                [Contract.TenantControllerInsertOneLoginTenantError.MissingPermissions]: "Insufficient permissions",
                                [Contract.TenantControllerInsertOneLoginTenantError.TenantExists]: "Account already exists"
                            },
                            [Contract.TypeNames.TenantControllerUpdateOneLoginTenantError]: {
                                [Contract.TenantControllerUpdateOneLoginTenantError.AuthenticationFailed]: "Authentication failure",
                                [Contract.TenantControllerUpdateOneLoginTenantError.MissingPermissions]: "Insufficient permissions",
                                [Contract.TenantControllerUpdateOneLoginTenantError.TenantMismatch]: "The client ID does not belong to this **Onelogin** account"

                            }
                        }
                    }
                },
                fields: {
                    clientId: {
                        error: {
                            required: "Client ID cannot be empty"
                        },
                        title: "Client ID"
                    },
                    clientSecret: {
                        error: {
                            required: "Client secret cannot be empty"
                        },
                        title: "Client Secret"
                    }
                },
                steps: {
                    step1: "From the API Access page, click **New Credential**, enter a **Name**, and select **Manage Users** to allow Tenable Cloud Security read/write access to your directory data. Click **Save**",
                    step2: "Copy the **Client ID** and **Client Secret** and paste them below. Click **Done**",
                    title: "Leverage Just-in-Time (JIT) Access to temporarily grant users access to OneLogin roles"
                }
            }));

    function isClientIdEmpty() {
        return _.isEmpty(clientId.trim());
    }

    function isClientSecretEmpty() {
        if (!_.isNil(tenantConfiguration) &&
            _.isNil(clientSecret)) {
            return false;
        }
        return _.isEmpty(clientSecret?.trim());

    }

    const clientDataEmpty =
        useMemo(
            () =>
                isClientIdEmpty() &&
                isClientSecretEmpty(),
            [clientId, clientSecret]);
    const [clientIdValidationController, clientIdValidationMessage] =
        useInputValidation(
            () => {
                if (clientDataEmpty) {
                    return undefined;
                }

                if (isClientIdEmpty()) {
                    return localization.fields.clientId.error.required();
                }

                return undefined;
            },
            [clientId]);

    const [clientSecretValidationController, clientSecretValidationMessage] =
        useInputValidation(
            () => {
                if (clientDataEmpty) {
                    return undefined;
                }

                if (isClientSecretEmpty()) {
                    return localization.fields.clientSecret.error.required();
                }

                return undefined;
            },
            [clientSecret]);

    useChangeEffect(
        () => {
            if (clientDataEmpty) {
                clientIdValidationController.clear();
                clientSecretValidationController.clear();
            }
        },
        [clientDataEmpty]);

    const upsertTenant = useUpsertTenant();
    useNextEffect(
        async () =>
            await upsertTenant(
                tenantName,
                clientId,
                clientSecret,
                tenantReadClientId,
                tenantReadClientSecret,
                tenantConfiguration,
                tenantUrl),
        [clientId, clientSecret]);

    useEffect(
        () => {
            setAddOrEditContext(
                addOrEditContext => ({
                    ...addOrEditContext,
                    tenantPermissionManagementClientId: clientId.trim(),
                    tenantPermissionManagementClientSecret: clientSecret?.trim()
                }));

            setValid(
                _.isEmpty(clientId) &&
                _.isEmpty(clientSecret) ||
                clientIdValidationController.isValid() &&
                clientSecretValidationController.isValid());
        },
        [clientId, clientSecret]);

    const theme = useTheme();
    return (
        <Stack spacing={2}>
            <Typography>
                {localization.steps.title()}
            </Typography>
            <Steps variant="letters">
                {localization.steps.step1()}
                {
                    new Step(
                        localization.steps.step2(),
                        {
                            contentElement:
                                <Stack
                                    spacing={2}
                                    sx={{ marginTop: theme.spacing(4) }}>
                                    <FormControl
                                        fullWidth={true}
                                        variant="standard">
                                        <TextField
                                            disabled={executing}
                                            label={localization.fields.clientId.title()}
                                            value={clientId}
                                            variant="outlined"
                                            onChange={
                                                event => {
                                                    setClientId(event.target.value);
                                                    if (_.isEmpty(event.target.value) &&
                                                        _.isNil(clientSecret)) {
                                                        setClientSecret("");
                                                    }
                                                }}/>
                                        {!_.isNil(clientIdValidationMessage) &&
                                            <FormHelperText error={true}>{clientIdValidationMessage}</FormHelperText>}
                                    </FormControl>
                                    <FormControl
                                        fullWidth={true}
                                        variant="standard">
                                        <PasswordTextField
                                            disabled={executing}
                                            label={localization.fields.clientSecret.title()}
                                            password={clientSecret}
                                            variant="outlined"
                                            onPasswordChanged={clientSecret => setClientSecret(clientSecret)}/>
                                        {!_.isNil(clientSecretValidationMessage) &&
                                            <FormHelperText error={true}>{clientSecretValidationMessage}</FormHelperText>}
                                    </FormControl>
                                </Stack>
                        })}
            </Steps>
        </Stack>);
}