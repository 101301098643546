import { Dictionary } from "lodash";
import React from "react";
import { DataTableColumn, EnumValuesFilter, optionalTableCell, PagedValuesFilter, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import { useAwsCommonEncryptedResourceDefinition, useAwsCommonNetworkedResourceDefinition, useAwsCommonRdsClusterResourceDefinition, useAwsCommonResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { useCommonDataAnalysisDefinition } from "..";
import { Contract, ElasticsearchItemPageHelper, FeatureHelper, ItemSelectionHelper, TimeRangeHelper } from "../../../../../../../../../../common";
import { useAwsRdsClusterEngineTypeTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";

export function useAwsRdsClusterDefinition(definitionData: DefinitionData) {
    const commonDataAnalysisResourceDefinition = useCommonDataAnalysisDefinition(definitionData);
    const commonEncryptedResourceDefinition = useAwsCommonEncryptedResourceDefinition(definitionData);
    const commonNetworkedResourceDefinition = useAwsCommonNetworkedResourceDefinition(definitionData);
    const commonRdsClusterResourceDefinition =
        useAwsCommonRdsClusterResourceDefinition(
            Contract.TypeNames.AwsRdsClusterSnapshot,
            Contract.TypeNames.AwsRdsDatabaseInstance,
            definitionData,
            Contract.TypeNames.AwsRdsAuroraGlobalCluster);
    const commonResourceDefinition = useAwsCommonResourceDefinition(definitionData);

    const rdsClusterEngineTypeTranslator = useAwsRdsClusterEngineTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.aws.useAwsRdsClusterDefinition",
            () => ({
                columns: {
                    engineType: "Engine",
                    instanceRawType: "Instance Class",
                    minorVersionUpgradeEnabled: {
                        false: "Disabled",
                        title: "Auto Minor Version Upgrade",
                        true: "Enabled"
                    }
                }
            }));

    function getMinorVersionUpgradeEnabledTranslation(clusterModel: Contract.AwsRdsClusterModel) {
        return (clusterModel.entity as Contract.AwsRdsCluster).minorVersionUpgradeEnabled
            ? localization.columns.minorVersionUpgradeEnabled.true()
            : localization.columns.minorVersionUpgradeEnabled.false();
    }

    return new EntityTableDefinition(
        [
            commonResourceDefinition.columns.tenantColumn,
            commonResourceDefinition.columns.creationTimeColumn,
            commonResourceDefinition.columns.creatorIdentityCsvColumn,
            commonResourceDefinition.columns.creatorOriginatorEntityCsvColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsRdsClusterModel) => ({
                            [localization.columns.engineType()]: rdsClusterEngineTypeTranslator((item.entity as Contract.AwsRdsCluster).engineType)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.AwsRdsClusterEngineType}
                                enumTypeTranslator={rdsClusterEngineTypeTranslator}
                                placeholder={localization.columns.engineType()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AwsRdsClusterEngineType}
                itemProperty={(item: Contract.EntityModel) => rdsClusterEngineTypeTranslator((item.entity as Contract.AwsRdsCluster).engineType)}
                key={Contract.EntityModelProperty.AwsRdsClusterEngineType}
                title={localization.columns.engineType()}/>,
            commonRdsClusterResourceDefinition.columns.statusColumn,
            commonRdsClusterResourceDefinition.columns.engineVersionColumn,
            commonRdsClusterResourceDefinition.columns.storageSizeColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsRdsClusterModel) => ({
                            [localization.columns.instanceRawType()]: (item.entity as Contract.AwsRdsCluster).instanceRawType ?? ""
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedValuesFilter
                                getValuePage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AwsRdsClusterInstanceRawType)}
                                placeholder={localization.columns.instanceRawType()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AwsRdsClusterInstanceRawType}
                key={Contract.EntityModelProperty.AwsRdsClusterInstanceRawType}
                render={optionalTableCell<Contract.AwsRdsClusterModel>(item => (item.entity as Contract.AwsRdsCluster).instanceRawType)}
                selectorOptions={{ default: false }}
                title={localization.columns.instanceRawType()}/>,
            commonRdsClusterResourceDefinition.columns.instancesColumn,
            commonRdsClusterResourceDefinition.columns.snapshotsColumn,
            commonRdsClusterResourceDefinition.columns.globalClusterColumn,
            // Migration: 1.161 | Delete condition & keep false flow
            ...(!FeatureHelper.enabled(Contract.FeatureName.GenerateCustomerEntityModelsCompleted)
                ? []
                : [
                    commonNetworkedResourceDefinition.columns.accessLevelColumn,
                    commonNetworkedResourceDefinition.columns.inboundExternalAccessScopeColumn,
                    commonNetworkedResourceDefinition.columns.getVpcsColumn(),
                    commonNetworkedResourceDefinition.columns.securityGroupsColumn]),
            commonEncryptedResourceDefinition.columns.encryptionExistsColumn,
            commonEncryptedResourceDefinition.columns.kmsEncryptionKeyColumn,
            commonRdsClusterResourceDefinition.columns.automaticBackupRetentionColumn,
            commonRdsClusterResourceDefinition.columns.cloudWatchLoggingEnabledColumn,
            commonRdsClusterResourceDefinition.columns.cloudWatchLogTypesColumn,
            commonRdsClusterResourceDefinition.columns.deleteProtectionColumn,
            // Migration: 1.161 | Remove condition and leave always true
            FeatureHelper.enabled(Contract.FeatureName.GenerateCustomerEntityModelsCompleted)
                ? <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsRdsClusterModel) => ({
                                [localization.columns.minorVersionUpgradeEnabled.title()]: getMinorVersionUpgradeEnabledTranslation(item)
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <ValuesFilter
                                    placeholder={localization.columns.minorVersionUpgradeEnabled.title()}
                                    sorted={false}>
                                    <ValuesFilterItem
                                        title={localization.columns.minorVersionUpgradeEnabled.true()}
                                        value={true}/>
                                    <ValuesFilterItem
                                        title={localization.columns.minorVersionUpgradeEnabled.false()}
                                        value={false}/>
                                </ValuesFilter>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsRdsClusterMinorVersionUpgradeEnabled}
                    itemProperty={getMinorVersionUpgradeEnabledTranslation}
                    key={Contract.EntityModelProperty.AwsRdsClusterMinorVersionUpgradeEnabled}
                    title={localization.columns.minorVersionUpgradeEnabled.title()}/>
                : undefined,
            commonResourceDefinition.columns.regionColumn,
            commonResourceDefinition.columns.regionLocationColumn,
            commonDataAnalysisResourceDefinition.columns.dataCategoriesColumn,
            commonDataAnalysisResourceDefinition.columns.dataAnalysisResourceSensitivityColumn,
            commonDataAnalysisResourceDefinition.columns.dataAnalysisStatusColumn,
            commonDataAnalysisResourceDefinition.columns.dataClassifiersColumn?.(false),
            commonDataAnalysisResourceDefinition.columns.scanTimeColumn,
            commonResourceDefinition.columns.tagsColumn,
            commonResourceDefinition.columns.attributesColumn,
            commonResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAwsRdsClusterRequest(
                new Contract.EntityControllerGetEntityModelPageAwsRdsClusterRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsRdsClusterResourceDeleteProtection]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsRdsClusterResourceEngineVersion]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsRdsClusterResourceGlobalCluster]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsRdsClusterResourceInstances]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsRdsClusterResourceSnapshots]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsRdsClusterResourceStatus]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsRdsClusterEngineType]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsRdsClusterInstanceRawType]),
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsRdsClusterMinorVersionUpgradeEnabled])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags])),
                new Contract.EntityControllerGetEntityModelPageAwsResourceRequestCloudWatchLogging(
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsCloudWatchResourceCloudWatchLoggingEnabled]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsCloudWatchResourceCloudWatchLogTypes])),
                new Contract.EntityControllerGetEntityModelPageAwsResourceRequestEncrypted(
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEncryptedResourceEncryptionExists]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEncryptedResourceKmsEncryptionKeys])),
                new Contract.EntityControllerGetEntityModelPageEntityRequestDataAnalysis(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.DataAnalysisResourceScanStatus]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.DataAnalysisResourceDataCategories]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.DataAnalysisResourceDataClassifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.DataAnalysisResourceDataSensitivities]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.DataAnalysisResourceScanTime])),
                new Contract.EntityControllerGetEntityModelPageEntityRequestNetworkAccess(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.NetworkInboundAccessType]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.NetworkInboundExternalAccessScope]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.NetworkInboundExternalDestinationNetworkScopes])),
                new Contract.EntityControllerGetEntityModelPageAwsResourceRequestNetworked(
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsNetworkedResourceSecurityGroups]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsNetworkedResourceVpcs]))));
}