import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function ThailandIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <g clipPath="url(#clip0_61633_3712)">
                <rect
                    fill="#fff"
                    height="15.964"
                    rx="2"
                    width="24"
                    y="4"/>
                <path
                    d="M24 4H0v10.643h24z"
                    fill="#fff"/>
                <path
                    d="M0 4h24v16H0z"
                    fill="#fff"/>
                <path
                    d="M0 9.5h24v5H0z"
                    fill="#0052B4"/>
                <path
                    clipRule="evenodd"
                    d="M1 6.5v3H0v-3zm22 3v-3h1v3zM1 14.5v3H0v-3zm22 3v-3h1v3z"
                    fill="#BAC5D3"
                    fillRule="evenodd"/>
                <path
                    d="M0 4h24v2.556H0zm0 13.444h24V20H0z"
                    fill="#D80027"/>
            </g>
            <defs>
                <clipPath id="clip0_61633_3712">
                    <rect
                        fill="#fff"
                        height="15.964"
                        rx="2"
                        width="24"
                        y="4"/>
                </clipPath>
            </defs>
        </SvgIcon>);
}