import React from "react";
import { ToastContainer as ToastifyToastBase } from "react-toastify";
import { autoClose } from "../utilities";
import "react-toastify/dist/ReactToastify.css";

export function ToastContainer() {
    return (
        <ToastifyToastBase 
            autoClose={autoClose}
            bodyClassName="Toast-bodyClassName"
            draggable={true}
            progressClassName={"Toast-progressClassName"}
            toastClassName="Toast-toastClassName"/>);
}