import { StringHelper, useLocalization } from "@infrastructure";
import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { Contract, RadioGroup, useTheme } from "../../../../..";
import { EntityProfileInfo } from "../../../../../../views/Customer/components/Entities/components/Profile/components";
import { useChangeInfoContext } from "../ChangeInfo";

export function AwsDetachPrincipalManagedPolicyChangeInfo() {
    const { change, updateChange, updatedChange } = useChangeInfoContext();
    const detachPrincipalManagedPolicyChange = change as Contract.AwsDetachPrincipalManagedPolicyChange;
    const updatedDetachPrincipalManagedPolicyChange = updatedChange as Contract.AwsDetachPrincipalManagedPolicyChange;

    const localization =
        useLocalization(
            "common.riskResolutionAutomation.changeInfo.awsDetachPrincipalManagedPolicyChangeInfo",
            () => ({
                fields: {
                    remove: {
                        false: "Detach only",
                        title: "Delete or Detach?",
                        true: "Delete"
                    }
                }
            }));
    const theme = useTheme();
    return (
        <Stack sx={{ height: "100%" }}>
            <Box
                sx={{
                    flex: 1,
                    overflow: "hidden"
                }}>
                <EntityProfileInfo entityId={detachPrincipalManagedPolicyChange.resourceId}/>
            </Box>
            {detachPrincipalManagedPolicyChange.deletePolicy &&
                <Stack
                    alignItems="center"
                    direction="row"
                    spacing={2}
                    sx={{
                        borderBottom: theme.border.alternate,
                        padding: theme.spacing(2)
                    }}>
                    <Typography>
                        {localization.fields.remove.title()}
                    </Typography>
                    <RadioGroup
                        items={[
                            {
                                label: localization.fields.remove.true(),
                                value: true
                            },
                            {
                                label: localization.fields.remove.false(),
                                value: false
                            }
                        ]}
                        selectedValue={updatedDetachPrincipalManagedPolicyChange.deletePolicy}
                        onChange={value => updateChange(updatedChange => (updatedChange as Contract.AwsDetachPrincipalManagedPolicyChange).deletePolicy = StringHelper.isTrue(value))}/>
                </Stack>}
        </Stack>);
}