import { StringFormatter } from "@infrastructure";
import { SvgIconProps } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract, UserHelper, useTheme } from "..";

export type CustomerIconProps =
    SvgIconProps & {
        customer?: Contract.AuthenticationControllerGetUserCustomerDataResponseCustomer;
        size?: string;
        textVariant?: "thick" | "thin";
    };

export function CustomerIcon({ customer, size = "80px", textVariant = "thick" }: CustomerIconProps) {
    customer = customer ?? UserHelper.customer;
    const theme = useTheme();
    return (
        <svg
            height={size}
            viewBox="0 0 80 80"
            width={size}>
            {_.isNil(customer!.logoConfiguration)
                ? <g>
                    <circle
                        cx="50%"
                        cy="50%"
                        fill={
                            _.isNil(customer!.colorIndex)
                                ? theme.palette.background.paper
                                : theme.palette.avatar.customer[customer!.colorIndex % theme.palette.avatar.customer.length]}
                        r={36}>
                    </circle>
                    <text
                        dominantBaseline="central"
                        fill={theme.palette.common.white}
                        fontSize={36}
                        fontWeight={
                            textVariant === "thin"
                                ? 300
                                : undefined}
                        textAnchor="middle"
                        x="50%"
                        y="50%">
                        {StringFormatter.initials(customer!.name)}
                    </text>
                </g>
                : <image href={`data:${customer!.logoConfiguration.contentType};base64, ${customer!.logoConfiguration.contentBytes}`}/>}
        </svg>);
}