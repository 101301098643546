﻿import { Steps, TimeFormatter, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useOpenRiskedEntityRisksStep } from "../../../../..";
import { Contract, SeveritySquare, useRiskSeverityReasonTranslator, useSeverityTranslator } from "../../../../../../../../../../../../../../common";
import { useResourceGeneralInformationStep } from "../../../../../useResourceGeneralInformationStep";
import { useRiskPolicyConfigurationRotationTimeFrameStep } from "../../../../../useRiskPolicyConfigurationRotationTimeFrameStep";

export type ContextSectionProps = {
    riskModel: Contract.AwsIamUserUnrotatedAccessKeyRiskModel;
    userModel: Contract.AwsIamUserModel;
};

export function ContextSection({ riskModel, userModel }: ContextSectionProps) {
    const risk = riskModel.risk as Contract.AwsIamUserUnrotatedAccessKeyRisk;
    const userAccessKeys = risk.userAccessKeys;
    const userPermissionActionSeverity = risk.userPermissionActionSeverity;
    const userUnrotatedAccessKeys = risk.userUnrotatedAccessKeys;

    const openRiskedEntityRisksStep = useOpenRiskedEntityRisksStep(userModel, risk.id);
    const resourceGeneralInformationStep = useResourceGeneralInformationStep(userModel);
    const severityReasonTranslator = useRiskSeverityReasonTranslator();
    const severityTranslator = useSeverityTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsIamUserUnrotatedAccessKeyRiskDefinition.contextSection",
            () => ({
                userAccessKey: {
                    lastUsed: "last used on **{{lastUsedDate}}**",
                    neverUsed: "**never used**",
                    text: "The user access key {{key}} is **enabled**, was created on {{createdDate}} and was {{lastUsed}}"
                },
                userHasTwoAccessKeys: "There is no reason to have 2 active access keys that increase the attack surface. You should have only one active access key",
                userPermissionActionSeverity: {
                    oneAccessKey: "The access key would give an attacker {{severitySquare}}**{{severity}}** severity permissions if compromised",
                    twoAccessKeys: "The access keys would give an attacker {{severitySquare}}**{{severity}}** severity permissions if compromised"
                }
            }));

    return (
        <Steps variant="bullets">
            {_.filter([
                resourceGeneralInformationStep,
                useRiskPolicyConfigurationRotationTimeFrameStep(risk.accessKeyRotationTimeFrame),
                ..._.map(
                    userUnrotatedAccessKeys,
                    userUnrotatedAccessKey =>
                        localization.userAccessKey.text({
                            createdDate: TimeFormatter.mediumDate(userUnrotatedAccessKey.creationTime),
                            key: userUnrotatedAccessKey.uniqueId,
                            lastUsed:
                                _.isNil(userUnrotatedAccessKey.usageTime)
                                    ? localization.userAccessKey.neverUsed()
                                    : localization.userAccessKey.lastUsed({ lastUsedDate: TimeFormatter.mediumDate(userUnrotatedAccessKey.usageTime) })
                        })),
                userAccessKeys.length === 2
                    ? localization.userHasTwoAccessKeys()
                    : undefined,
                _.isNil(userPermissionActionSeverity)
                    ? undefined
                    : getUserPermissionActionSeverityLocalization(userPermissionActionSeverity),
                openRiskedEntityRisksStep,
                severityReasonTranslator(
                    risk.severity,
                    risk.severityReason!,
                    {
                        userPermissionActionSeverity:
                            _.isNil(userPermissionActionSeverity)
                                ? undefined
                                : severityTranslator(userPermissionActionSeverity, "text")
                    })
            ])}
        </Steps>);

    function getUserPermissionActionSeverityLocalization(userPermissionActionSeverity: Contract.Severity) {
        const userPermissionActionSeverityOptions =
            {
                severity: severityTranslator(userPermissionActionSeverity, "text"),
                severitySquare: <SeveritySquare severity={userPermissionActionSeverity}/>
            };
        return userAccessKeys.length === 1
            ? localization.userPermissionActionSeverity.oneAccessKey(userPermissionActionSeverityOptions)
            : localization.userPermissionActionSeverity.twoAccessKeys(userPermissionActionSeverityOptions);
    }
}