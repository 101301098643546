import { InlineItems, useLocalization } from "@infrastructure";
import React from "react";
import { CodeTenant } from "./CodeTenant";

type InlineCodeTenantsProps = {
    tenantIds: string[];
};

export function InlineCodeTenants({ tenantIds }: InlineCodeTenantsProps) {
    const localization =
        useLocalization(
            "common.inlineCodeTenants",
            () => ({
                repositories: {
                    multiple:  "{{ count | NumberFormatter.humanize }} Repositories",
                    single:  "1 Repository"
                }
            }));

    return (
        <InlineItems
            items={tenantIds}
            namePluralizer={
                count =>
                    count > 1
                        ? localization.repositories.multiple({ count })
                        : localization.repositories.single() }
            variant="itemCountAndType">
            {(tenantId: string) => <CodeTenant tenantId={tenantId}/>}
        </InlineItems>);
}