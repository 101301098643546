import { FormLayout, getItemWithId, getItemWithValidation, ItemWithId, ItemWithValidation, Link, Message, StringHelper, useInputValidation, useLocalization } from "@infrastructure";
import { Button, CircularProgress, FormHelperText, Stack, TextField, Typography } from "@mui/material";
import _ from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { ConfigurationController, Contract, CustomerConsoleAppUrlHelper, projectModelStore, useTheme } from "../../../../../../../../../../common";
import { AddOrEditComponentProps } from "../../../../../index";
import { ProjectConfigurationRules } from "./components";

export function AddOrEdit({ onClose, parentFolderId, scopeNodeModel }: AddOrEditComponentProps) {
    const projectConfiguration = (scopeNodeModel?.configuration as Contract.ProjectConfiguration);
    const projectModelMap = projectModelStore.useGetProjectModelMap();

    const [name, setName] = useState(projectConfiguration?.name ?? "");
    const [description, setDescription] = useState(projectConfiguration?.description ?? "");

    const [projectConfigurationRules, setProjectConfigurationRules] =
        useState<ProjectConfigurationRuleWithValidation[]>(
            () =>
                _.isEmpty(projectConfiguration?.rules)
                    ? [getItemWithValidation(
                        new Contract.ProjectConfigurationRule(
                            [],
                            []),
                        true)]
                    : _.map(
                        projectConfiguration!.rules,
                        rule =>
                            getItemWithValidation({
                                ...rule,
                                conditions:
                                    _.map(
                                        rule.conditions,
                                        condition =>
                                            getItemWithValidation(
                                                getItemWithId(condition),
                                                true))
                            },
                            true)));

    const [executing, setExecuting] = useState(false);
    const [error, setError] = useState(false);

    const localization =
        useLocalization(
            "views.customer.scopes.hooks.useDefinition.hooks.useProjectsDefinition.addOrEdit",
            () => ({
                actions: {
                    add: {
                        label: "Add",
                        title: "Add Project"
                    },
                    edit: {
                        label: "Save",
                        title: "Edit Project"
                    }
                },
                error: "An unexpected error occurred.",
                fields: {
                    description: {
                        label: "Description (optional)"
                    },
                    name: {
                        error: {
                            exists: "Project name already exists",
                            length: "Project name length cannot be more than 1000 characters",
                            required: "Project name cannot be empty"
                        },
                        label: "Name"
                    },
                    title: "Details"
                },
                note: {
                    add: "Note that new projects may only be used after the next time {{link}}",
                    edit: "Note that project scope changes may only take effect after the next time {{link}}",
                    linkText: "data is synchronized"
                },
                scopes: {
                    subtitle: "Select the accounts that you want to include in the project. When you select a folder (or the entire organization), the project will contain all existing and future accounts in that folder.",
                    title: "Scopes"
                }
            }));

    const addMode = _.isNil(projectConfiguration);

    const [nameValidationController, nameValidationMessage] =
        useInputValidation(
            () => {
                const normalizedName = StringHelper.normalize(name);
                if (_.isEmpty(normalizedName)) {
                    return localization.fields.name.error.required();
                }
                if (_(projectModelMap).
                    map(
                        projectModel =>
                            projectModel.configuration.id !== projectConfiguration?.id &&
                            StringHelper.normalize(projectModel.configuration.name)).
                    includes(normalizedName)) {
                    return localization.fields.name.error.exists();
                }
                if (normalizedName!.length > 1000) {
                    return localization.fields.name.error.length();
                }

                return undefined;
            },
            [name, projectModelMap]);

    const projectConfigurationRulesValid =
        useMemo(
            () =>
                _.every(
                    projectConfigurationRules,
                    projectConfigurationRule => projectConfigurationRule.valid && !_.isEmpty(projectConfigurationRule.scopeIds)),
            [projectConfigurationRules]);

    const valid =
        useMemo(
            () =>
                nameValidationController.isValid() &&
                projectConfigurationRulesValid,
            [name, projectConfigurationRulesValid]);

    const onAddOrEditProject =
        useCallback(
            async () => {
                setExecuting(true);
                setError(false);
                try {
                    const { projectModel } =
                        addMode
                            ? await ConfigurationController.insertProject(
                                new Contract.ConfigurationControllerInsertProjectRequest(
                                    description,
                                    name,
                                    parentFolderId,
                                    projectConfigurationRules))
                            : await ConfigurationController.updateProject(
                                new Contract.ConfigurationControllerUpdateProjectRequest(
                                    description,
                                    projectConfiguration.id,
                                    name,
                                    parentFolderId,
                                    projectConfigurationRules));
                    await projectModelStore.notify(projectModel);
                    onClose();
                } catch (error) {
                    setError(true);
                }
                setExecuting(false);
            },
            [description, name, parentFolderId, projectConfigurationRules, projectConfiguration]);

    const [projectConfigurationRulesChanged, setProjectConfigurationRulesChanged] = useState(false);
    const dirty =
        useMemo(
            () =>
                name !== projectConfiguration?.name ||
                description !== projectConfiguration?.description ||
                projectConfigurationRulesChanged,
            [description, name, projectConfigurationRulesChanged, projectConfiguration]);

    const theme = useTheme();
    const localizationNoteKey =
        addMode
            ? "add"
            : "edit";
    return (
        <FormLayout
            footerOptions={{
                border: true,
                contentElement:
                    <Stack
                        alignItems="center"
                        direction="row"
                        justifyContent="space-between">
                        {error
                            ? <Message
                                level="error"
                                title={localization.error()}/>
                            : <Message
                                level="info"
                                title={
                                    localization.note[localizationNoteKey]({
                                        link:
                                            <Link
                                                urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsArchitectureAndDataHandlingHowOftenIsDataSyncedRelativeUrl()}
                                                variant="external">
                                                {localization.note.linkText()}
                                            </Link>
                                    })}/>}
                        <Stack
                            alignItems="center"
                            direction="row"
                            justifyContent="flex-end"
                            spacing={1}>
                            {executing && (
                                <CircularProgress
                                    size={theme.spacing(2)}
                                    variant="indeterminate"/>)}
                            <Button
                                disabled={executing || !valid || !dirty}
                                onClick={onAddOrEditProject}>
                                {addMode
                                    ? localization.actions.add.label()
                                    : localization.actions.edit.label()}
                            </Button>
                        </Stack>
                    </Stack>
            }}
            titleOptions={{
                text:
                    addMode
                        ? localization.actions.add.title()
                        : localization.actions.edit.title()
            }}>
            <Stack spacing={2}>
                <Stack>
                    <TextField
                        fullWidth={true}
                        label={localization.fields.name.label()}
                        type="text"
                        value={name}
                        variant="outlined"
                        onChange={event => setName(event.target.value)}/>
                    {!_.isNil(nameValidationMessage) &&
                        <FormHelperText error={true}>
                            {nameValidationMessage}
                        </FormHelperText>}
                </Stack>
                <TextField
                    fullWidth={true}
                    label={localization.fields.description.label()}
                    type="text"
                    value={description}
                    variant="outlined"
                    onChange={event => setDescription(event.target.value)}/>
                <Stack/>
                <Stack spacing={1}>
                    <Typography variant="h3">
                        {localization.scopes.title()}
                    </Typography>
                    <Typography>
                        {localization.scopes.subtitle()}
                    </Typography>
                </Stack>
                <ProjectConfigurationRules
                    projectConfigurationRules={projectConfigurationRules}
                    onProjectConfigurationRulesChanged={
                        projectConfigurationRules => {
                            setProjectConfigurationRules(projectConfigurationRules);
                            setProjectConfigurationRulesChanged(true);
                        }}/>
            </Stack>
        </FormLayout>);
}

export type ProjectConfigurationRuleConditionWithValidationWithId = ItemWithValidation<ItemWithId<Contract.ProjectConfigurationRuleCondition>>;

export type ProjectConfigurationRuleWithValidation = ItemWithValidation<ProjectConfigurationRule>;

type ProjectConfigurationRule =
    Omit<Contract.ProjectConfigurationRule, "conditions"> & {
        conditions: ProjectConfigurationRuleConditionWithValidationWithId[];
    };