import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, EnumValuesFilter, InlineItems, optionalTableCell, PagedValuesFilter, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import { Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React from "react";
import { useOciCommonResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, NetworkedResourcesCell, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions, useEntityTypeNameTranslator } from "../../../../../../../../../../common";
import { useOciNetworkingSubnetStatusTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";

export function useOciNetworkingSubnetDefinition(definitionData: DefinitionData) {
    const commonResourceDefinition = useOciCommonResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const subnetStatusTranslator = useOciNetworkingSubnetStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.oci.useOciNetworkingSubnetDefinition",
            () => ({
                columns: {
                    flowLogsEnabled: {
                        false: "Disabled",
                        title: "Flow Logs",
                        true: "Enabled"
                    },
                    ipv4Subnet: "IPv4 CIDR Block",
                    ipv6Subnets: "IPv6 Prefixes",
                    networkedResources: "Attached Resources",
                    prohibitPublicIpOnVnic: {
                        false: "Public",
                        title: "Subnet Access",
                        true: "Private"
                    },
                    regional: {
                        false: "Availability Domain-Specific",
                        title: "Subnet Type",
                        true: "Regional"
                    },
                    status: "State"
                }
            }));

    return new EntityTableDefinition(
        [
            commonResourceDefinition.columns.tenantColumn,
            commonResourceDefinition.columns.creationTimeColumn,
            commonResourceDefinition.columns.creatorCsvColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.OciNetworkingSubnetModel) => ({
                            [localization.columns.status()]: subnetStatusTranslator((item.entity as Contract.OciNetworkingSubnet).status)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.OciNetworkingSubnetStatus}
                                enumTypeTranslator={subnetStatusTranslator}
                                placeholder={localization.columns.status()}/>
                    }
                }}
                id={Contract.EntityModelProperty.OciNetworkingSubnetStatus}
                itemProperty={(item: Contract.OciNetworkingSubnetModel) => subnetStatusTranslator((item.entity as Contract.OciNetworkingSubnet).status)}
                key={Contract.EntityModelProperty.OciNetworkingSubnetStatus}
                title={localization.columns.status()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.OciNetworkingSubnetModel) => ({
                            [localization.columns.prohibitPublicIpOnVnic.title()]:
                                (item.entity as Contract.OciNetworkingSubnet).prohibitPublicIpOnVnic
                                    ? localization.columns.prohibitPublicIpOnVnic.true()
                                    : localization.columns.prohibitPublicIpOnVnic.false()
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <ValuesFilter placeholder={localization.columns.prohibitPublicIpOnVnic.title()}>
                                <ValuesFilterItem
                                    title={localization.columns.prohibitPublicIpOnVnic.false()}
                                    value={false}/>
                                <ValuesFilterItem
                                    title={localization.columns.prohibitPublicIpOnVnic.true()}
                                    value={true}/>
                            </ValuesFilter>
                    }
                }}
                id={Contract.EntityModelProperty.OciNetworkingSubnetProhibitPublicIpOnVnic}
                itemProperty={
                    (item: Contract.EntityModel) =>
                        (item.entity as Contract.OciNetworkingSubnet).prohibitPublicIpOnVnic
                            ? localization.columns.prohibitPublicIpOnVnic.true()
                            : localization.columns.prohibitPublicIpOnVnic.false()}
                key={Contract.EntityModelProperty.OciNetworkingSubnetProhibitPublicIpOnVnic}
                title={localization.columns.prohibitPublicIpOnVnic.title()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.OciNetworkingSubnetModel) => ({
                            [localization.columns.regional.title()]:
                                (item.entity as Contract.OciNetworkingSubnet).regional
                                    ? localization.columns.regional.true()
                                    : localization.columns.regional.false()
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <ValuesFilter placeholder={localization.columns.regional.title()}>
                                <ValuesFilterItem
                                    title={localization.columns.regional.false()}
                                    value={false}/>
                                <ValuesFilterItem
                                    title={localization.columns.regional.true()}
                                    value={true}/>
                            </ValuesFilter>
                    }
                }}
                id={Contract.EntityModelProperty.OciNetworkingSubnetRegional}
                itemProperty={
                    (item: Contract.EntityModel) =>
                        (item.entity as Contract.OciNetworkingSubnet).regional
                            ? localization.columns.regional.true()
                            : localization.columns.regional.false()}
                key={Contract.EntityModelProperty.OciNetworkingSubnetRegional}
                title={localization.columns.regional.title()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.OciNetworkingSubnetModel>(
                        Contract.TypeNames.OciNetworkingVcn,
                        item => [item.vcnIdReference],
                        entityTypeNameTranslator(Contract.TypeNames.OciNetworkingVcn))}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.OciNetworkingSubnetVcn)}
                                placeholder={entityTypeNameTranslator(Contract.TypeNames.OciNetworkingVcn)}/>
                    }
                }}
                id={Contract.EntityModelProperty.OciNetworkingSubnetVcn}
                key={Contract.EntityModelProperty.OciNetworkingSubnetVcn}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.OciNetworkingSubnetModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.vcnIdReference}
                            entityTypeName={Contract.TypeNames.OciNetworkingVcn}/>}
                title={entityTypeNameTranslator(Contract.TypeNames.OciNetworkingVcn)}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.OciNetworkingSubnetModel) => ({
                            [localization.columns.ipv4Subnet()]: (item.entity as Contract.OciNetworkingSubnet).ipv4Subnet
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedValuesFilter
                                getValuePage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.OciNetworkingSubnetIpv4Subnet)}
                                placeholder={localization.columns.ipv4Subnet()}/>
                    }
                }}
                id={Contract.EntityModelProperty.OciNetworkingSubnetIpv4Subnet}
                itemProperty={(item: Contract.OciNetworkingSubnetModel) => (item.entity as Contract.OciNetworkingSubnet).ipv4Subnet}
                key={Contract.EntityModelProperty.OciNetworkingSubnetIpv4Subnet}
                title={localization.columns.ipv4Subnet()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.OciNetworkingSubnetModel) => ({
                            [localization.columns.ipv6Subnets()]: (item.entity as Contract.OciNetworkingSubnet).ipv6Subnets.join("\n")
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedValuesFilter
                                getValuePage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.OciNetworkingSubnetIpv6Subnets)}
                                placeholder={localization.columns.ipv6Subnets()}/>
                    }
                }}
                id={Contract.EntityModelProperty.OciNetworkingSubnetIpv6Subnets}
                key={Contract.EntityModelProperty.OciNetworkingSubnetIpv6Subnets}
                render={
                    optionalTableCell<Contract.OciNetworkingSubnetModel>(
                        (item: Contract.OciNetworkingSubnetModel) => {
                            const subnetIpv6Subnets = (item.entity as Contract.OciNetworkingSubnet).ipv6Subnets;
                            return !_.isEmpty(subnetIpv6Subnets) &&
                                <Typography noWrap={true}>
                                    <InlineItems
                                        items={subnetIpv6Subnets}
                                        variant="itemPlusItemCount"/>
                                </Typography>;
                        })}
                title={localization.columns.ipv6Subnets()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.OciNetworkingSubnetModel>(
                        Contract.TypeNames.INetworkedResource,
                        item =>
                            _(item.networkedResourceTypeNameToIdsMap).values()
                                .flatMap()
                                .value(),
                        localization.columns.networkedResources())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.OciNetworkingSubnetNetworkedResources)}
                                placeholder={localization.columns.networkedResources()}/>
                    }
                }}
                id={Contract.EntityModelProperty.OciNetworkingSubnetNetworkedResources}
                key={Contract.EntityModelProperty.OciNetworkingSubnetNetworkedResources}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.OciNetworkingSubnetModel>) =>
                        <NetworkedResourcesCell item={item.networkedResourceTypeNameToIdsMap}/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.networkedResources()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.OciNetworkingSubnetModel) => ({
                            [localization.columns.flowLogsEnabled.title()]:
                                item.flowLogsEnabled
                                    ? localization.columns.flowLogsEnabled.true()
                                    : localization.columns.flowLogsEnabled.false()
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <ValuesFilter placeholder={localization.columns.flowLogsEnabled.title()}>
                                <ValuesFilterItem
                                    title={localization.columns.flowLogsEnabled.false()}
                                    value={false}/>
                                <ValuesFilterItem
                                    title={localization.columns.flowLogsEnabled.true()}
                                    value={true}/>
                            </ValuesFilter>
                    }
                }}
                id={Contract.EntityModelProperty.OciNetworkingSubnetFlowLogsEnabled}
                itemProperty={
                    (item: Contract.OciNetworkingVcnModel) =>
                        item.flowLogsEnabled
                            ? localization.columns.flowLogsEnabled.true()
                            : localization.columns.flowLogsEnabled.false()}
                key={Contract.EntityModelProperty.OciNetworkingSubnetFlowLogsEnabled}
                title={localization.columns.flowLogsEnabled.title()}/>,
            commonResourceDefinition.columns.regionColumn,
            commonResourceDefinition.columns.regionLocationColumn,
            commonResourceDefinition.columns.tagsColumn,
            commonResourceDefinition.columns.attributesColumn,
            commonResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageOciNetworkingSubnetRequest(
                new Contract.EntityControllerGetEntityModelPageOciNetworkingSubnetRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciNetworkingSubnetFlowLogsEnabled]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciNetworkingSubnetIpv4Subnet]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciNetworkingSubnetIpv6Subnets]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciNetworkingSubnetNetworkedResources]),
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciNetworkingSubnetProhibitPublicIpOnVnic]),
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciNetworkingSubnetRegional]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciNetworkingSubnetStatus]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciNetworkingSubnetVcn])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags]))));
}