import { ProjectIcon } from "@infrastructure";
import { Divider, Stack } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, InfoCard, InfoItem, ProfileLayout, ProfileTopbar, ProfileTopbarTimeInfoItem, ScopeHelper, scopeNodeModelStore, TenantHelper, TenantIcon, TenantIconData, useTheme } from "../../../../../common";
import { useProfileDefinition } from "../hooks";
import { ScopesViewType } from "../utilities";

export type ProfileProps = {
    scopeNodeModelId: string;
};

export function Profile({ scopeNodeModelId }: ProfileProps) {
    const scopeNodeModel = scopeNodeModelStore.useGet(scopeNodeModelId);
    const tenantType = ScopeHelper.getTenantType(scopeNodeModel);
    const isProject = scopeNodeModel.configuration.typeName === Contract.TypeNames.ProjectConfiguration;
    const definition =
        useProfileDefinition(
            isProject
                ? Contract.ScopeType.Project
                : tenantType as ScopesViewType,
            scopeNodeModel);

    const iconData =
        useMemo(
            (): TenantIconData | undefined => {
                if (ScopeHelper.isCodeTenant(scopeNodeModel)) {
                    return {
                        codeTenantType: (scopeNodeModel.configuration as Contract.CodeTenantConfiguration).codeTenantType,
                        generalCodeTenant: TenantHelper.isGeneralCodeTenant(scopeNodeModel.configuration as Contract.TenantConfiguration)
                    };
                } else if (ScopeHelper.isCiTenant(scopeNodeModel)) {
                    return {
                        ciTenantType: (scopeNodeModel.configuration as Contract.CiTenantConfiguration).ciTenantType
                    };
                }
            },
            [scopeNodeModel]);

    const theme = useTheme();
    return (
        <ProfileLayout
            topBarElement={
                <ProfileTopbar
                    icon={
                        isProject
                            ? <ProjectIcon/>
                            : <TenantIcon
                                data={iconData}
                                tenantType={tenantType!}/>}
                    infoChildren={[
                        <ProfileTopbarTimeInfoItem
                            key="created"
                            time={scopeNodeModel.configuration.systemCreationTime}
                            type="created"/>
                    ]}
                    subtitle={
                        isProject
                            ? (scopeNodeModel.configuration as Contract.ProjectConfiguration).description
                            : undefined}
                    title={scopeNodeModel.configuration.name}/>}>
            <Stack
                divider={<Divider/>}
                gap={1}
                sx={{
                    height: "100%",
                    overflow: "hidden auto",
                    padding: theme.spacing(2, 2, 0, 3)
                }}>
                {_.map(
                    definition?.sections,
                    ({ items, title }, index) => (
                        <InfoCard
                            columns={true}
                            key={`TenantsProfile-${title}-${index}`}
                            title={title}>
                            {_.map(
                                items,
                                ({ title, value }, index) =>
                                    <InfoItem
                                        key={`infoItem-${index}`}
                                        title={title}
                                        value={value}/>)}
                        </InfoCard>))}
            </Stack>
        </ProfileLayout>);
}