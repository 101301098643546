﻿import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useAwsBedrockAgentStatusTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useAwsBedrockAgentStatusTranslator",
            () => ({
                [Contract.TypeNames.AwsBedrockAgentStatus]: {
                    [Contract.AwsBedrockAgentStatus.Creating]: "Creating",
                    [Contract.AwsBedrockAgentStatus.Deleting]: "Deleting",
                    [Contract.AwsBedrockAgentStatus.Dissociated]: "Dissociated",
                    [Contract.AwsBedrockAgentStatus.Failed]: "Failed",
                    [Contract.AwsBedrockAgentStatus.NotPrepared]: "NotPrepared",
                    [Contract.AwsBedrockAgentStatus.Prepared]: "Prepared",
                    [Contract.AwsBedrockAgentStatus.Preparing]: "Preparing",
                    [Contract.AwsBedrockAgentStatus.Updating]: "Updating",
                    [Contract.AwsBedrockAgentStatus.Versioning]: "Versioning"
                }
            }));
    return (status: Contract.AwsBedrockAgentStatus) =>
        localization[Contract.TypeNames.AwsBedrockAgentStatus][status]();
}