import { Box, Stack } from "@mui/material";
import _ from "lodash";
import React, { Children, ReactNode, useMemo } from "react";
import { useTheme } from "../../../../../../../../../../../common";

type ColumnGridProps = {
    children: ReactNode;
    itemHeight: number;
};

export function ColumnGrid({ children, itemHeight }: ColumnGridProps) {
    const childElementPairs =
        useMemo(
            () => {
                const childElements = Children.toArray(children);
                const evenChildElements =
                    childElements.length % 2 === 0
                        ? childElements
                        : _.concat(childElements, undefined);
                return _.zip(
                    _.take(
                        evenChildElements,
                        evenChildElements.length / 2),
                    _.takeRight(
                        evenChildElements,
                        evenChildElements.length / 2));
            },
            [children]);

    const theme = useTheme();
    return (
        <Stack
            direction="row"
            justifyContent="space-evenly"
            sx={{ height: "100%" }}>
            {_.map(
                childElementPairs,
                (childElementPair, childElementPairIndex) =>
                    <Stack
                        justifyContent="space-around"
                        key={childElementPairIndex}
                        sx={{
                            flex: 1,
                            height: "100%",
                            marginLeft:
                                childElementPairIndex > 0
                                    ? theme.spacing(-2.25)
                                    : undefined
                        }}>
                        {_.map(
                            childElementPair,
                            (childElement, childElementIndex) =>
                                <Box
                                    key={childElementIndex}
                                    sx={{
                                        height:
                                            _.isNil(childElement)
                                                ? itemHeight
                                                : undefined
                                    }}>
                                    {childElement}
                                </Box>)}
                    </Stack>)}
        </Stack>);
}