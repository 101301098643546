import { Cache, useExecuteOperation } from "@infrastructure";
import { Contract, DashboardController } from "..";

const getTopCriticalVulnerabilityModelsResultCache = new Cache<Contract.DashboardControllerGetSummaryTopCriticalVulnerabilityModelsResponse>();

export function clearTopCriticalVulnerabilityModelsCache() {
    return getTopCriticalVulnerabilityModelsResultCache.removeAll();
}

export function useGetTopCriticalVulnerabilityModels(scopeId: string) {
    const [{ vulnerabilityModels }] =
        useExecuteOperation(
            [useGetTopCriticalVulnerabilityModels, scopeId],
            async () => {
                if (!getTopCriticalVulnerabilityModelsResultCache.has(`${scopeId}`)) {
                    const getTopCriticalVulnerabilityModelsResponse = await DashboardController.getSummaryTopCriticalVulnerabilityModels();
                    getTopCriticalVulnerabilityModelsResultCache.set(`${scopeId}`, getTopCriticalVulnerabilityModelsResponse);
                }

                return getTopCriticalVulnerabilityModelsResultCache.get(`${scopeId}`)!;
            });

    return vulnerabilityModels;
}