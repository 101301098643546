import { DataTableColumnRenderProps, useLocalizeList } from "@infrastructure";
import { Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract, EntitiesCell } from "../../../../../common";

export function RuleEntityCell({ item: ruleEntity }: DataTableColumnRenderProps<Contract.AzureNetworkNetworkSecurityGroupRuleEntity>) {
    const localizeList = useLocalizeList();
    return (
        <Typography noWrap={true}>
            {_.isEmpty(ruleEntity.applicationSecurityGroupIds)
                ? ruleEntity.any
                    ? "Any"
                    : _.isNil(ruleEntity.serviceTag)
                        ? localizeList(ruleEntity.subnets)
                        : ruleEntity.serviceTag
                : <EntitiesCell
                    entityIdsOrModels={ruleEntity.applicationSecurityGroupIds}
                    entityTypeName={Contract.TypeNames.AzureNetworkApplicationSecurityGroup}/>}
        </Typography>);
}