import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function AzureIcon(props: SvgIconProps) {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <path
                d="M8.83473 3.3628H14.1611L8.63181 20.749C8.57498 20.9277 8.46678 21.0829 8.32246 21.1929C8.17813 21.3029 8.00494 21.362 7.82726 21.3621H3.682C3.54738 21.3621 3.41468 21.3281 3.2949 21.2629C3.17512 21.1977 3.07169 21.1031 2.99317 20.9871C2.91464 20.871 2.86329 20.7368 2.84334 20.5955C2.8234 20.4543 2.83544 20.31 2.87847 20.1746L8.02998 3.97587C8.08679 3.79714 8.195 3.64182 8.33937 3.5318C8.48374 3.42178 8.65699 3.36281 8.83473 3.3628Z"
                fill="url(#paint0_linear_52885_254682)"/>
            <path
                d="M16.5751 15.0245H8.12866C8.05013 15.0244 7.9734 15.0494 7.90846 15.0962C7.84352 15.1431 7.79337 15.2096 7.76456 15.2871C7.73575 15.3646 7.72961 15.4496 7.74694 15.5309C7.76427 15.6121 7.80426 15.686 7.8617 15.7428L13.2892 21.1189C13.4472 21.2753 13.6553 21.3623 13.8714 21.3622H18.654L16.5751 15.0245Z"
                fill="#0078D4"/>
            <path
                d="M8.83473 3.3628C8.65505 3.36207 8.48024 3.4226 8.33513 3.53505C8.19001 3.64749 8.08258 3.80623 8.02852 3.98808L2.8852 20.1601C2.83927 20.296 2.82485 20.4416 2.84316 20.5846C2.86147 20.7276 2.91197 20.8639 2.99039 20.9818C3.06881 21.0997 3.17284 21.1959 3.29368 21.2621C3.41452 21.3284 3.54862 21.3627 3.68463 21.3624H7.93688C8.09525 21.3323 8.24327 21.2582 8.36583 21.1476C8.48838 21.037 8.58107 20.8939 8.63444 20.7328L9.66011 17.5248L13.3238 21.1513C13.4774 21.2861 13.67 21.3606 13.8692 21.3624H18.6341L16.5443 15.0246L10.4522 15.0261L14.1808 3.36308L8.83473 3.3628Z"
                fill="url(#paint1_linear_52885_254682)"/>
            <path
                d="M15.6377 3.97527C15.581 3.79683 15.473 3.64177 15.3288 3.53194C15.1846 3.42211 15.0117 3.36305 14.8342 3.36307H8.898C9.07544 3.36308 9.24842 3.42216 9.39256 3.53198C9.53671 3.64181 9.64477 3.79684 9.70152 3.97527L14.8532 20.1746C14.8963 20.31 14.9084 20.4544 14.8884 20.5957C14.8685 20.737 14.8172 20.8713 14.7386 20.9874C14.6601 21.1035 14.5567 21.198 14.4369 21.2633C14.3171 21.3285 14.1844 21.3626 14.0497 21.3626H19.9861C20.1208 21.3625 20.2534 21.3285 20.3732 21.2632C20.493 21.1979 20.5964 21.1034 20.6749 20.9873C20.7534 20.8712 20.8047 20.7369 20.8247 20.5956C20.8446 20.4543 20.8325 20.31 20.7894 20.1746L15.6377 3.97527Z"
                fill="url(#paint2_linear_52885_254682)"/>
            <defs>
                <linearGradient
                    gradientUnits="userSpaceOnUse"
                    id="paint0_linear_52885_254682"
                    x1="15.4547"
                    x2="11.2974"
                    y1="4.69684"
                    y2="23.087">
                    <stop stopColor="#114A8B"/>
                    <stop
                        offset="1"
                        stopColor="#0669BC"/>
                </linearGradient>
                <linearGradient
                    gradientUnits="userSpaceOnUse"
                    id="paint1_linear_52885_254682"
                    x1="13.8501"
                    x2="12.4148"
                    y1="12.779"
                    y2="13.3">
                    <stop stopOpacity="0.3"/>
                    <stop
                        offset="0.071"
                        stopOpacity="0.2"/>
                    <stop
                        offset="0.321"
                        stopOpacity="0.1"/>
                    <stop
                        offset="0.623"
                        stopOpacity="0.05"/>
                    <stop
                        offset="1"
                        stopOpacity="0"/>
                </linearGradient>
                <linearGradient
                    gradientUnits="userSpaceOnUse"
                    id="paint2_linear_52885_254682"
                    x1="11.8001"
                    x2="18.5337"
                    y1="4.19127"
                    y2="21.0955">
                    <stop stopColor="#3CCBF4"/>
                    <stop
                        offset="1"
                        stopColor="#2892DF"/>
                </linearGradient>
            </defs>
        </SvgIcon>);
}