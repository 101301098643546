import { UnexpectedError } from "@infrastructure";
import { ReactNode, useMemo } from "react";
import { TypeHelper } from "../../../../../";
import { Contract } from "../../../../../controllers";
import { useTerraformDefinition } from "./hooks";

export function useDefinition(codeBlock: Contract.CodeBlock) {
    const useDefinition =
        useMemo(
            () => {
                if (TypeHelper.extendOrImplement(codeBlock.typeName, Contract.TypeNames.TerraformBlock)) {
                    return useTerraformDefinition;
                }

                throw new UnexpectedError("codeBlock.typeName", codeBlock.typeName);
            },
            []);

    return useDefinition(codeBlock);
}

export class CodeResourceItemDefinition {
    constructor(public title: ReactNode) {
    }
}