﻿import { Link, useChangeEffect, useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Contract, CustomerConsoleAppUrlHelper, RadioGroup, RiskPoliciesType, UserHelper, useTheme } from "../../../../../../../../../../../../common";
import { useGetGeneralInheritToggleInheritedValues, useKubernetesAdmissionControllerRiskPolicyEffectTranslator } from "../../../../../../../../hooks";
import { useConfigurationContext, useSetConfigurationContext } from "../../../../../../Configuration";

export function Effect() {
    const { item, riskPolicyConfiguration } = useConfigurationContext();
    const setConfigurationContext = useSetConfigurationContext();

    const {
        inherited,
        overridablePropertyNameToDataMap: { effect: effectData }
    } =
        useGetGeneralInheritToggleInheritedValues(
            RiskPoliciesType.KubernetesAdmissionController,
            riskPolicyConfiguration,
            item,
            undefined,
            riskPolicyConfiguration => ({
                effect: (riskPolicyConfiguration as Contract.KubernetesAdmissionControllerRiskPolicyConfiguration).effect
            }));

    const disabled =
        useMemo(
            () => {
                const userSecurityWrite =
                    UserHelper.hasScopePermissions(
                        riskPolicyConfiguration.scopeId,
                        Contract.IdentityPermission.SecurityWrite);

                return inherited || !userSecurityWrite;
            },
            [riskPolicyConfiguration.enabled, riskPolicyConfiguration.scopeId]);

    const [selectedEffect, setSelectedEffect] = useState(effectData.value);

    useEffect(
        () => {
            if (inherited) {
                setSelectedEffect(undefined);
            } else if (_.isNil(effectData.value)) {
                setSelectedEffect(effectData.inheritedValue);
            }
        },
        [effectData, inherited]);

    useChangeEffect(
        () => {
            setConfigurationContext(
                configurationContext => ({
                    ...configurationContext,
                    dirty: true,
                    riskPolicyConfiguration: {
                        ...riskPolicyConfiguration,
                        effect: selectedEffect
                    }
                }));
        },
        [selectedEffect]);

    const effectTranslator = useKubernetesAdmissionControllerRiskPolicyEffectTranslator();
    const localization =
        useLocalization(
            "views.customer.riskPolicies.configuration.hooks.useDefinition.hooks.kubernetesAdmissionController.hooks.effect.effect",
            () => ({
                description: {
                    documentationLink: "Learn more",
                    text: "Choose what happens to a request that violates the policy rules."
                },
                title: "Effect"
            }));

    const theme = useTheme();
    return (
        <Fragment>
            <Typography variant="h4">
                {localization.title()}
            </Typography>
            <Stack sx={{ marginTop: theme.spacing(1) }}>
                <Typography>
                    {localization.description.text()}
                </Typography>
                <Typography>
                    <Link
                        urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsAdmissionControllerEnableDisableAndConfigurePoliciesRelativeUrl()}
                        variant="external">
                        {localization.description.documentationLink()}
                    </Link>
                </Typography>
                <RadioGroup
                    disabled={disabled}
                    items={[
                        {
                            label: effectTranslator(Contract.KubernetesAdmissionControllerRiskPolicyEffect.Alert),
                            value: Contract.KubernetesAdmissionControllerRiskPolicyEffect.Alert
                        },
                        {
                            label: effectTranslator(Contract.KubernetesAdmissionControllerRiskPolicyEffect.Deny),
                            value: Contract.KubernetesAdmissionControllerRiskPolicyEffect.Deny
                        }]}
                    selectedValue={effectData.value ?? effectData.inheritedValue}
                    onChange={
                        effect =>
                            setSelectedEffect(
                                inherited
                                    ? undefined
                                    : effect)}/>
            </Stack>
        </Fragment>
    );
}