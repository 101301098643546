import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function ColoredGcpIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                d="M15.6308 7.88268L17.5432 5.9703L17.6702 5.1654C14.1858 1.99621 8.64654 2.35599 5.5045 5.91502C4.63172 6.90321 3.98488 8.13579 3.63965 9.40909L4.3243 9.31211L8.14905 8.68176L8.44386 8.38017C10.1448 6.51143 13.0221 6.26026 14.9859 7.8497L15.6308 7.88268Z"
                fill="#EA4335"/>
            <path
                d="M20.2681 9.3515C19.8288 7.73297 18.9259 6.27735 17.671 5.16406L14.9867 7.84837C16.1204 8.77449 16.7653 10.169 16.7381 11.6324V12.1085C18.057 12.1085 19.1267 13.1782 19.1267 14.4971C19.1267 15.816 18.057 16.8594 16.7381 16.8594H11.9542L11.4849 17.3695V20.2352L11.9542 20.6851H16.7381C20.1692 20.7123 22.9728 17.9785 22.9999 14.5475C23.0164 12.4674 21.9904 10.5172 20.2681 9.3515Z"
                fill="#4285F4"/>
            <path
                d="M7.17683 20.6851H11.9549V16.8603H7.17683C6.83838 16.8603 6.50478 16.7866 6.19737 16.646L5.5195 16.8535L3.59355 18.7659L3.42578 19.4166C4.5061 20.2322 5.82401 20.6909 7.17683 20.6851Z"
                fill="#34A853"/>
            <path
                d="M7.17649 8.27836C3.74452 8.29872 0.979747 11.0975 1.00011 14.5295C1.01175 16.4457 1.90683 18.2495 3.42547 19.418L6.19703 16.6465C4.99454 16.1034 4.4602 14.6885 5.00326 13.486C5.54633 12.2835 6.9612 11.7491 8.1637 12.2922C8.69318 12.5317 9.11794 12.9555 9.35747 13.486L12.129 10.7144C10.9498 9.17248 9.11697 8.27157 7.17649 8.27836Z"
                fill="#FBBC05"/>
        </SvgIcon>);
}