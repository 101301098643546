import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, Entity, InlineEntities, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetAzureResourceRiskContext } from "./useGetAzureResourceRiskContext";

export function useGetAzureNetworkVirtualNetworkSubnetRiskContext() {
    return useMemo(
        () => useAzureNetworkVirtualNetworkSubnetRiskContext,
        []);
}

function useAzureNetworkVirtualNetworkSubnetRiskContext(subnetModel: Contract.AzureNetworkVirtualNetworkSubnetModel) {
    const subnet = subnetModel.entity as Contract.AzureNetworkVirtualNetworkSubnet;
    const resourceRiskContext = useGetAzureResourceRiskContext()(subnetModel);

    const networkedResourceIds =
        useMemo(
            () =>
                _(subnetModel.networkedResourceTypeNameToIdsMap).
                    values().
                    flatMap().
                    value(),
            [subnetModel]);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.contexts.useGetAzureNetworkVirtualNetworkSubnetRiskContext",
            () => ({
                networkedResources: {
                    empty: "The {{translatedSubnetTypeName}} is part of {{virtualNetwork}} and does not contain any resources",
                    resources: "The {{translatedSubnetTypeName}} is part of {{virtualNetwork}} and it contains {{resources}}",
                    unknown: "The {{translatedSubnetTypeName}} is part of {{virtualNetwork}} and it is used"
                }
            }));
    const translatedSubnetTypeName =
        entityTypeNameTranslator(
            subnet.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });

    return {
        ...resourceRiskContext,
        networkedResources:
            new RiskDefinitionContextItem(
                (subnet.hasNetworkedResources
                    ? _.isEmpty(networkedResourceIds)
                        ? localization.networkedResources.unknown
                        : localization.networkedResources.resources
                    : localization.networkedResources.empty)({
                    resources:
                        <InlineEntities
                            entityIdsOrModels={networkedResourceIds}
                            entityTypeName={Contract.TypeNames.AzureResource}
                            variant="itemAndTypeOrItemCountAndType"/>,
                    translatedSubnetTypeName,
                    virtualNetwork:
                        <Entity
                            entityIdOrModel={subnet.virtualNetworkId}
                            variant="typeText"/>
                }))
    };
}