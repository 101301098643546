import { UnexpectedError } from "@infrastructure";
import { useMemo } from "react";
import { Contract } from "../../../../../../../../../../../../common";
import { RiskDefinition } from "../../../../../../utilities";
import { useContainerImageMaliciousFileRiskDefinition, useContainerImageOperatingSystemEndOfLifeRiskDefinition, useContainerImageOperatingSystemUnpatchedRiskDefinition, useContainerImageRepositoryVulnerabilityRiskDefinition, useContainerImageVulnerabilityRiskDefinition, useVirtualMachineImageOperatingSystemEndOfLifeRiskDefinition, useVirtualMachineImageOperatingSystemUnpatchedRiskDefinition, useVirtualMachineImageVulnerabilityRiskDefinition, useVirtualMachineMaliciousFileRiskDefinition, useVirtualMachineOperatingSystemEndOfLifeRiskDefinition, useVirtualMachineOperatingSystemUnpatchedRiskDefinition, useVirtualMachineVulnerabilityRiskDefinition } from "./hooks";

export function useWorkloadAnalysisDefinition(riskModel: Contract.RiskModel): RiskDefinition {
    const useDefinition =
        useMemo(
            () => {
                switch (riskModel.risk.typeName) {
                    case Contract.TypeNames.ContainerImageMaliciousFileRisk:
                        return useContainerImageMaliciousFileRiskDefinition;
                    case Contract.TypeNames.ContainerImageOperatingSystemEndOfLifeRisk:
                        return useContainerImageOperatingSystemEndOfLifeRiskDefinition;
                    case Contract.TypeNames.ContainerImageOperatingSystemUnpatchedRisk:
                        return useContainerImageOperatingSystemUnpatchedRiskDefinition;
                    case Contract.TypeNames.ContainerImageRepositoryVulnerabilityRisk:
                        return useContainerImageRepositoryVulnerabilityRiskDefinition;
                    case Contract.TypeNames.ContainerImageVulnerabilityRisk:
                        return useContainerImageVulnerabilityRiskDefinition;
                    case Contract.TypeNames.VirtualMachineImageOperatingSystemUnpatchedRisk:
                        return useVirtualMachineImageOperatingSystemUnpatchedRiskDefinition;
                    case Contract.TypeNames.VirtualMachineImageOperatingSystemEndOfLifeRisk:
                        return useVirtualMachineImageOperatingSystemEndOfLifeRiskDefinition;
                    case Contract.TypeNames.VirtualMachineImageVulnerabilityRisk:
                        return useVirtualMachineImageVulnerabilityRiskDefinition;
                    case Contract.TypeNames.VirtualMachineMaliciousFileRisk:
                        return useVirtualMachineMaliciousFileRiskDefinition;
                    case Contract.TypeNames.VirtualMachineOperatingSystemEndOfLifeRisk:
                        return useVirtualMachineOperatingSystemEndOfLifeRiskDefinition;
                    case Contract.TypeNames.VirtualMachineOperatingSystemUnpatchedRisk:
                        return useVirtualMachineOperatingSystemUnpatchedRiskDefinition;
                    case Contract.TypeNames.VirtualMachineVulnerabilityRisk:
                        return useVirtualMachineVulnerabilityRiskDefinition;
                    default:
                        throw new UnexpectedError("riskModel.risk.typeName", riskModel.risk.typeName);
                }
            },
            []);

    return useDefinition(riskModel);
}