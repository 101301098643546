﻿import { Link, TimeFormatter, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../..";
import { useCustomEntityPropertyInfoItemElements } from "../..";
import { useGcpCommonServiceAccountOriginatorScopeResourceInfoElements, useGcpDefaultResourceInfoItemElements, useGcpDefaultScopeResourceInfoItemElements } from "..";
import { Contract, InfoItem } from "../../../../../../../../../../../common";
import { useGcpServerlessNetworkedResourceNetworkInboundAccessTypeTranslator } from "../../../../../../../hooks";
import { VulnerabilitiesCell } from "../../../../../../VulnerabilitiesCell";
import { EntitiesInfoItem, Info } from "../../../../../components";
import { GcpScopeResourceRoleBindings } from "../../../components";
import { Revisions, Triggers } from "./components";

export function useGcpCloudRunServiceDefinition(scopeResourceModel: Contract.GcpScopeResourceModel, options?: DefinitionOptions) {
    const commonServiceAccountOriginatorScopeResourceInfoElements = useGcpCommonServiceAccountOriginatorScopeResourceInfoElements(scopeResourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(scopeResourceModel);
    const defaultResourceInfoItemElements = useGcpDefaultResourceInfoItemElements(scopeResourceModel);
    const defaultScopeResourceInfoItemElements = useGcpDefaultScopeResourceInfoItemElements(scopeResourceModel);
    const serviceModel = scopeResourceModel as Contract.GcpCloudRunServiceModel;
    const service = serviceModel.entity as Contract.GcpCloudRunService;

    const serverlessNetworkedResourceNetworkInboundAccessTypeTranslator = useGcpServerlessNetworkedResourceNetworkInboundAccessTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpCloudRunServiceDefinition",
            () => ({
                info: {
                    items: {
                        containerImages: "Container Images",
                        containerImageVulnerabilities: "Vulnerabilities",
                        functionIds: "Cloud Functions",
                        ingress: "Ingress",
                        revisionLastDeployer: "Deployed By",
                        revisionMaxCreationTime: "Deployment Time",
                        updater: "Updated By",
                        updateTime: "Update Time",
                        url: "URL"
                    },
                    networkInboundAccessType: "Ingress traffic"
                },
                tabs: {
                    resourceRoleBindings: "Resource Role Bindings",
                    revisions: "Revisions ({{revisionCount | NumberFormatter.humanize}})",
                    triggers: "Triggers ({{triggerCount | NumberFormatter.humanize}})"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs:
            _<EntityProfileDefinitionTab>([]).
                concatIf(
                    !_.isEmpty(serviceModel.triggerIds),
                    new EntityProfileDefinitionTab(
                        ProfileCategory.Overview,
                        <Triggers triggerIds={serviceModel.triggerIds}/>,
                        localization.tabs.triggers({ triggerCount: serviceModel.triggerIds.length }),
                        "triggers")).
                concatIf(
                    !_.isEmpty(serviceModel.revisionIds),
                    new EntityProfileDefinitionTab(
                        ProfileCategory.Overview,
                        <Revisions revisionIds={serviceModel.revisionIds}/>,
                        localization.tabs.revisions({ revisionCount: serviceModel.revisionIds.length }),
                        "revisions")).
                concat(
                    new EntityProfileDefinitionTab(
                        ProfileCategory.Iam,
                        <GcpScopeResourceRoleBindings
                            csvExportFilePrefix={localization.tabs.resourceRoleBindings()}
                            scopeResourceModel={serviceModel}/>,
                        localization.tabs.resourceRoleBindings(),
                        "resourceRoleBindings")).
                value(),
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={
                    _.filter([
                        ...defaultScopeResourceInfoItemElements,
                        commonServiceAccountOriginatorScopeResourceInfoElements.serviceAccount,
                        !_.isNil(serviceModel.updaterIdentityIdReference)
                            ? <EntitiesInfoItem
                                entityIdsOrModels={serviceModel.updaterIdentityIdReference}
                                entityTypeName={Contract.TypeNames.IGciIdentity}
                                key="UpdaterIdentityIdReference"
                                title={localization.info.items.updater()}/>
                            : <InfoItem
                                key="updaterRawIdentifier"
                                title={localization.info.items.updater()}
                                value={service.updaterRawIdentifier}/>,
                        <InfoItem
                            key="revisionMaxCreationTime"
                            title={localization.info.items.revisionMaxCreationTime()}
                            value={
                                _.isNil(serviceModel.revisionMaxCreationTime)
                                    ? undefined
                                    : TimeFormatter.profileFormatDateTime(serviceModel.revisionMaxCreationTime)}/>,
                        !_.isNil(serviceModel.revisionLastDeployerIdentityIdReference)
                            ? <EntitiesInfoItem
                                entityIdsOrModels={serviceModel.revisionLastDeployerIdentityIdReference}
                                entityTypeName={Contract.TypeNames.IGciIdentity}
                                key="revisionLastDeployerIdentityIdReference"
                                title={localization.info.items.revisionLastDeployer()}/>
                            : <InfoItem
                                key="revisionLastDeployerRawIdentifier"
                                title={localization.info.items.revisionLastDeployer()}
                                value={serviceModel.revisionLastDeployerRawIdentifier}/>,
                        <InfoItem
                            key="url"
                            title={localization.info.items.url()}
                            value={
                                _.isNil(service.url)
                                    ? undefined
                                    : <Link
                                        urlOrGetUrl={service.url}
                                        variant="external"/>}/>,
                        <InfoItem
                            key="networkInboundAccessType"
                            title={localization.info.networkInboundAccessType()}
                            value={
                                _.isNil(service.networkInboundAccessType)
                                    ? undefined
                                    : serverlessNetworkedResourceNetworkInboundAccessTypeTranslator(service.networkInboundAccessType)}/>,
                        <EntitiesInfoItem
                            entityIdsOrModels={serviceModel.containerImageIdReferences}
                            entityTypeName={Contract.TypeNames.IContainerImage}
                            key="containerImages"
                            title={localization.info.items.containerImages()}/>,
                        <InfoItem
                            key="containerImageVulnerabilities"
                            title={localization.info.items.containerImageVulnerabilities()}
                            value={
                                _.isEmpty(serviceModel.containerImageVulnerabilities)
                                    ? undefined
                                    : <VulnerabilitiesCell
                                        containerImageScanStatus={serviceModel.containerImageScanStatus}
                                        variant="initialAndCount"
                                        vulnerabilities={serviceModel.containerImageVulnerabilities}/>}/>,
                        <EntitiesInfoItem
                            entityIdsOrModels={serviceModel.functionIds}
                            entityTypeName={Contract.TypeNames.GcpFunctionsFunction}
                            key="functionIds"
                            title={localization.info.items.functionIds()}/>
                    ])}
                options={options?.infoOptions}>
            </Info>
    });
}