import { PercentBar, Sx } from "@infrastructure";
import { Box, Stack, SxProps, Typography } from "@mui/material";
import _ from "lodash";
import React, { ReactNode } from "react";
import { useTheme } from "../../../../..";

type ScoreCardProps = {
    color: string;
    score: number;
    scoreCardSx?: React.CSSProperties;
    subtitle: string | ReactNode;
    sx?: SxProps;
    title: string;
    totalValue: number;
    variant?: "minimal" | "full";
};

export function ScoreCard({ color, score, subtitle, sx, title, totalValue, variant }: ScoreCardProps) {
    const minimalVariant = variant === "minimal";
    const theme = useTheme();
    return (
        <Box
            sx={
                Sx.combine(
                    {
                        background: theme.palette.background.alternate,
                        border: theme.border.alternate,
                        borderRadius: theme.spacing(0.75),
                        ...(minimalVariant
                            ? {
                                flex: 1,
                                height: theme.spacing(8),
                                padding: theme.spacing(0.75)
                            }
                            : {
                                height: theme.spacing(9),
                                padding: theme.spacing(1),
                                width: theme.spacing(16.25)
                            })
                    },
                    sx)}>
            <Stack
                spacing={0.25}
                sx={{ width: "100%" }}>
                <Typography
                    noWrap={true}
                    sx={{
                        color: theme.palette.text.secondary,
                        fontSize: "12px"
                    }}>
                    {title}
                </Typography>
                {_.isString(subtitle)
                    ? <Typography
                        noWrap={true}
                        sx={{
                            fontSize:
                                minimalVariant
                                    ? "14px"
                                    : "16px",
                            fontWeight: 500
                        }}>
                        {subtitle}
                    </Typography>
                    : subtitle}
                <PercentBar
                    item={{
                        color,
                        value: score
                    }}
                    itemSx={{ borderRadius: theme.px(10) }}
                    totalValue={totalValue}/>
            </Stack>
        </Box>);
}