import { useLocalization } from "@infrastructure";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { Contract, InfoItem } from "../../../../../../../../../../../common";
import { Info } from "../../../../../components";
import { useCustomEntityPropertyInfoItemElements } from "../../useCustomEntityPropertyInfoItemElements";
import { useOciDefaultResourceInfoItemElements } from "../.";
import { LogsInfoCard } from "./components";

export function useOciLoggingLogGroupDefinition(resourceModel: Contract.OciResourceModel, options?: DefinitionOptions) {
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useOciDefaultResourceInfoItemElements(resourceModel);
    const logGroupModel = resourceModel as Contract.OciLoggingLogGroupModel;
    const logGroup = logGroupModel.entity as Contract.OciLoggingLogGroup;

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.oci.useOciLoggingLogGroupDefinition",
            () => ({
                info: {
                    items: {
                        description: "Description"
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="description"
                        title={localization.info.items.description()}
                        value={logGroup.description}/>
                ]}
                options={options?.infoOptions}>
                <LogsInfoCard
                    logBucketNameToIdReferenceMap={logGroupModel.logBucketNameToIdReferenceMap}
                    logResourceOcidToIdReferenceMap={logGroupModel.logResourceOcidToIdReferenceMap}
                    logs={logGroup.logs}/>
            </Info>
    });
}