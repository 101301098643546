import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { useCommonCustomSectionsAndDescriptionDefinition } from "../../..";
import { Contract, EntitiesCell, Entity, EntityFilter, entityModelStore, InlineEntities, usePrincipalModelAccess, useTableDefinition } from "../../../../../../../../../../../../common";
import { Table } from "../../../../components";
import { RiskContentProps } from "../../../../useCloudDefinition";

export function useGcpPrincipalNotAllowedResourceRoleRiskDefinition(riskModel: Contract.RiskModel) {
    const principalNotAllowedResourceRoleRiskModel = riskModel as Contract.GcpPrincipalNotAllowedResourceRoleRiskModel;
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.custom.useGcpPrincipalNotAllowedResourceRoleRiskDefinition",
            () => ({
                violation: "{{principal}} has {{roleBindings}} on {{scopeResources}}"
            }));
    return useCommonCustomSectionsAndDescriptionDefinition(
        localization.violation({
            principal:
                <Entity
                    entityIdOrModel={(principalNotAllowedResourceRoleRiskModel.risk as Contract.GcpPrincipalNotAllowedResourceRoleRisk).entityId}
                    variant="typeText"/>,
            roleBindings:
                <InlineEntities
                    entityIdsOrModels={principalNotAllowedResourceRoleRiskModel.roleBindingIds}
                    entityTypeName={Contract.TypeNames.GcpIamRoleBinding}
                    variant="itemCountAndType"/>,
            scopeResources:
                <InlineEntities
                    entityIdsOrModels={principalNotAllowedResourceRoleRiskModel.scopeResourceIds}
                    entityTypeName={Contract.TypeNames.GcpScopeResource}
                    variant="itemAndTypeOrItemCountAndType"/>

        }),
        riskModel,
        "violations",
        <ViolationTable riskModel={riskModel}/>);
}

function ViolationTable({ riskModel }: RiskContentProps) {
    const principalNotAllowedResourceRoleRiskModel = riskModel as Contract.GcpPrincipalNotAllowedResourceRoleRiskModel;
    const principalNotAllowedResourceRoleRisk = riskModel.risk as Contract.GcpPrincipalNotAllowedResourceRoleRisk;

    const principalModel = entityModelStore.useGet(principalNotAllowedResourceRoleRisk.entityId);
    const principalModelAccess = usePrincipalModelAccess<Contract.PrincipalModelAccess>(principalModel.id);
    const originatorEntityModels =
        entityModelStore.useGet(
            principalModelAccess.typeName === Contract.TypeNames.ServiceIdentityModelAccess
                ? _.as<Contract.ServiceIdentityModelAccess>(principalModelAccess).originatorEntityIds
                : []);
    const roleBindingModels = entityModelStore.useGet(principalNotAllowedResourceRoleRiskModel.roleBindingIds);
    const scopeResourceModels = entityModelStore.useGet(principalNotAllowedResourceRoleRiskModel.scopeResourceIds);
    const items =
        useMemo(
            () => {
                const roleBindingModelMap =
                    _.keyBy(
                        roleBindingModels,
                        roleBindingModel => roleBindingModel.id);
                const scopeResourceModelMap =
                    _.keyBy(
                        scopeResourceModels,
                        scopeResourceModel => scopeResourceModel.id);

                return _.map(
                    principalNotAllowedResourceRoleRisk.resourceRoleItems,
                    resourceRoleItem =>
                        new ViolationTableItem(
                            _.map(
                                originatorEntityModels,
                                originatorEntityModel => originatorEntityModel.id),
                            originatorEntityModels,
                            principalModel,
                            resourceRoleItem.roleBindingIds,
                            _.map(
                                resourceRoleItem.roleBindingIds,
                                roleBindingId => roleBindingModelMap[roleBindingId]),
                            scopeResourceModelMap[resourceRoleItem.scopeResourceId]));
            },
            []);

    const tableDefinition =
        useTableDefinition(
            items,
            {
                [ViolationTableColumnId.Originators]: {
                    getFilterValue: item => item.originatorEntityIds,
                    getSortValue: item => item.originatorEntityIds.length
                },
                [ViolationTableColumnId.RoleBindings]: {
                    getFilterValue: item => item.roleBindingIds,
                    getSortValue: item => item.roleBindingIds.length
                },
                [ViolationTableColumnId.ScopeResource]: {
                    getFilterValue: item => item.scopeResourceModel.entity.id,
                    getSortValue: item => item.scopeResourceModel.entity.displayName
                }
            },
            ViolationTableColumnId.ScopeResource);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.custom.useGcpPrincipalNotAllowedResourceRoleRiskDefinition.violationTable",
            () => ({
                columns: {
                    originators: "Originators",
                    principal: "Principal",
                    roleBinding: "Role Bindings",
                    scopeResource: "Resource"
                }
            }));

    return (
        <Table
            fetchItems={tableDefinition.filterAndSortItems}
            getItemId={(item: ViolationTableItem) => item.id}
            sortEnabled={true}>
            <DataTableColumn
                id={ViolationTableColumnId.Principal}
                render={
                    ({ item }: DataTableColumnRenderProps<ViolationTableItem>) =>
                        <Entity
                            entityIdOrModel={item.principalModel}
                            variant="iconTextTypeTenantTags"/>}
                sortOptions={{ enabled: false }}
                title={localization.columns.principal()}/>
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EntityFilter
                                entityIdsOrSearchableReferences={tableDefinition.columnIdToItemValuesMap[ViolationTableColumnId.Originators]}
                                placeholder={localization.columns.originators()}/>
                    }
                }}
                id={ViolationTableColumnId.Originators}
                render={
                    ({ item }: DataTableColumnRenderProps<ViolationTableItem>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.originatorEntityModels}
                            entityTypeName={Contract.TypeNames.GcpResource}
                            entityVariant="iconTextTypeTenant"/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.originators()}/>
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EntityFilter
                                entityIdsOrSearchableReferences={tableDefinition.columnIdToItemValuesMap[ViolationTableColumnId.RoleBindings]}
                                placeholder={localization.columns.roleBinding()}/>
                    }
                }}
                id={ViolationTableColumnId.RoleBindings}
                render={
                    ({ item }: DataTableColumnRenderProps<ViolationTableItem>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.roleBindingModels}
                            entityTypeName={Contract.TypeNames.GcpIamRoleBinding}
                            entityVariant="iconTextTypeTenant"/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.roleBinding()}/>
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EntityFilter
                                entityIdsOrSearchableReferences={tableDefinition.columnIdToItemValuesMap[ViolationTableColumnId.ScopeResource]}
                                placeholder={localization.columns.scopeResource()}/>
                    }
                }}
                id={ViolationTableColumnId.ScopeResource}
                render={
                    ({ item }: DataTableColumnRenderProps<ViolationTableItem>) =>
                        <Entity
                            entityIdOrModel={item.scopeResourceModel}
                            variant="iconTextTypeTenantTags"/>}
                title={localization.columns.scopeResource()}/>
        </Table>);
}

enum ViolationTableColumnId {
    Originators = "originators",
    Principal = "principal",
    RoleBindings = "roleBindings",
    ScopeResource = "scopeResource"
}

class ViolationTableItem {
    public id: string;

    constructor(
        public originatorEntityIds: string[],
        public originatorEntityModels: Contract.EntityModel[],
        public principalModel: Contract.EntityModel,
        public roleBindingIds: string[],
        public roleBindingModels: Contract.EntityModel[],
        public scopeResourceModel: Contract.EntityModel) {
        this.id = `${principalModel.id}-${scopeResourceModel.id}`;
    }
}