import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, DeferredFilter, optionalTableCell, TimeFormatter, TimeRangeFilter, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useAzureCommonResourceGroupResourceDefinition } from ".";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";

export function useAzureCommonKeyVaultVaultObjectDefinition(definitionData: DefinitionData) {
    const commonResourceGroupResourceDefinition = useAzureCommonResourceGroupResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();
    const vaultObjectModelFiltersPromise = definitionData.entityModelFiltersPromise as Promise<Contract.AzureKeyVaultVaultObjectModelFilters>;

    function getAzureManaged(vaultObjectModel: Contract.AzureKeyVaultVaultObjectModel) {
        return vaultObjectModel.azureManaged
            ? localization.columns.azureManaged.true()
            : localization.columns.azureManaged.false();
    }

    function getEnabled(vaultObjectModel: Contract.AzureKeyVaultVaultObjectModel) {
        return (vaultObjectModel.entity as Contract.AzureKeyVaultVaultObject).enabled
            ? localization.columns.enabled.true()
            : localization.columns.enabled.false();
    }

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.azure.useAzureCommonKeyVaultVaultObjectDefinition",
            () => ({
                columns: {
                    azureManaged: {
                        false: "Customer managed",
                        title: "Management",
                        true: "Azure managed"
                    },
                    enabled: {
                        false: "Disabled",
                        title: "Status",
                        true: "Enabled"
                    },
                    expirationTime: "Expiration Time",
                    keyVault: "Key Vault"
                }
            }));

    return {
        columns: {
            ...commonResourceGroupResourceDefinition.columns,
            vaultObjectAzureManagedColumn:
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AzureKeyVaultVaultObjectModel) => ({
                                [localization.columns.azureManaged.title()]: getAzureManaged(item)
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <ValuesFilter
                                    placeholder={localization.columns.azureManaged.title()}
                                    sorted={false}>
                                    <ValuesFilterItem
                                        title={localization.columns.azureManaged.true()}
                                        value={true}/>
                                    <ValuesFilterItem
                                        title={localization.columns.azureManaged.false()}
                                        value={false}/>
                                </ValuesFilter>
                        }
                    }}
                    id={Contract.EntityModelProperty.AzureKeyVaultVaultObjectAzureManaged}
                    itemProperty={getAzureManaged}
                    key={Contract.EntityModelProperty.AzureKeyVaultVaultObjectAzureManaged}
                    title={localization.columns.azureManaged.title()}/>,
            vaultObjectEnabledColumn:
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AzureKeyVaultVaultObjectModel) => ({
                                [localization.columns.enabled.title()]: getEnabled(item)
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <ValuesFilter
                                    placeholder={localization.columns.enabled.title()}
                                    sorted={false}>
                                    <ValuesFilterItem
                                        title={localization.columns.enabled.true()}
                                        value={true}/>
                                    <ValuesFilterItem
                                        title={localization.columns.enabled.false()}
                                        value={false}/>
                                </ValuesFilter>
                        }
                    }}
                    id={Contract.EntityModelProperty.AzureKeyVaultVaultObjectEnabled}
                    itemProperty={getEnabled}
                    key={Contract.EntityModelProperty.AzureKeyVaultVaultObjectEnabled}
                    title={localization.columns.enabled.title()}/>,
            vaultObjectExpirationColumn:
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AzureKeyVaultVaultObjectModel) => ({
                                [localization.columns.expirationTime()]:
                                    _.isNil((item.entity as Contract.AzureKeyVaultVaultObject).expirationTime)
                                        ? "-"
                                        : TimeFormatter.iso8601String((item.entity as Contract.AzureKeyVaultVaultObject).expirationTime)
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <DeferredFilter
                                    promiseOrGetPromise={vaultObjectModelFiltersPromise}
                                    title={localization.columns.expirationTime()}>
                                    {filters =>
                                        <TimeRangeFilter
                                            emptyValue={filters.expirationTimeRange.emptyValue}
                                            placeholder={localization.columns.expirationTime()}
                                            timeRange={TimeRangeHelper.getTimeRangeFilterRange(filters.expirationTimeRange.timeRange)}/>}
                                </DeferredFilter>
                        }
                    }}
                    id={Contract.EntityModelProperty.AzureKeyVaultVaultObjectExpirationTime}
                    key={Contract.EntityModelProperty.AzureKeyVaultVaultObjectExpirationTime}
                    render={
                        optionalTableCell<Contract.AzureKeyVaultVaultObjectModel>(
                            item =>
                                _.isNil((item.entity as Contract.AzureKeyVaultVaultObject).expirationTime)
                                    ? undefined
                                    : TimeFormatter.monthDayAndYear((item.entity as Contract.AzureKeyVaultVaultObject).expirationTime))}
                    sortOptions={{ type: DataTableSortType.Date }}
                    title={localization.columns.expirationTime()}/>,
            vaultObjectVaultColumn:
                <DataTableColumn
                    exportOptions={
                        entitiesExportOptions<Contract.AzureKeyVaultVaultObjectModel>(
                            Contract.TypeNames.AzureKeyVaultVault,
                            item => [(item.entity as Contract.AzureKeyVaultVaultObject).vaultId],
                            localization.columns.keyVault())}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedEntityFilter
                                    getEntityIdPage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.AzureKeyVaultVaultObjectVault)}
                                    placeholder={localization.columns.keyVault()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AzureKeyVaultVaultObjectVault}
                    key={Contract.EntityModelProperty.AzureKeyVaultVaultObjectVault}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.AzureKeyVaultVaultObjectModel>) =>
                            <EntitiesCell
                                entityIdsOrModels={(item.entity as Contract.AzureKeyVaultVaultObject).vaultId}
                                entityTypeName={Contract.TypeNames.AzureKeyVaultVault}/>}
                    title={localization.columns.keyVault()}/>
        }
    };
}