import { Dialog, FormLayout, getItemWithValidation, ItemWithValidation, Message, useLocalization } from "@infrastructure";
import { Box, Button, CircularProgress, Stack } from "@mui/material";
import _ from "lodash";
import React, { useState } from "react";
import { Contract, Delivery, initialValidDeliveryTypeNames, RiskController, ScopeHelper, useTheme } from "../../../../../common";

type SendRisksMailProps = {
    onClose: (canceled?: boolean) => void;
    riskIds: string[];
    typeName: Contract.TypeNames.MailDelivery | Contract.TypeNames.ResourceOwnerMailDelivery;
};

export function SendRisksMailDialog({ onClose, riskIds, typeName }: SendRisksMailProps) {
    const [sendRisksMailExecuting, setSendRisksMailExecuting] = useState(false);
    const [sendRisksMailError, setSendRisksMailError] = useState(false);
    const [delivery, setDelivery] =
        useState(
            getItemWithValidation<ItemWithValidation<Contract.Delivery>>({
                destinations: [],
                message: undefined,
                typeName
            },
            _.includes(initialValidDeliveryTypeNames, typeName)));

    async function sendRisksMail() {
        setSendRisksMailExecuting(true);
        setSendRisksMailError(false);

        try {
            await RiskController.sendRisksMail(
                new Contract.RiskControllerSendRisksMailRequest(
                    {
                        delivery,
                        id: undefined
                    },
                    riskIds));
            onClose();
        } catch {
            setSendRisksMailError(true);
        }

        setSendRisksMailExecuting(false);
    }

    const localization =
        useLocalization(
            "views.customer.risks.sendRisksMailDialog",
            () => ({
                actions: {
                    send: "Send"
                },
                error: "Failed sharing risks",
                [Contract.TypeNames.MailDelivery]: {
                    title: [
                        "Send via Mail",
                        "Send {{count | NumberFormatter.humanize}} Findings via Mail"
                    ]
                },
                [Contract.TypeNames.ResourceOwnerMailDelivery]: {
                    title: [
                        "Mail per resource owner",
                        "Send {{count | NumberFormatter.humanize}} Findings to resource owner"
                    ]
                }
            }));

    const theme = useTheme();
    return (
        <Dialog
            variant="editor"
            onClose={() => onClose(true)}>
            <FormLayout
                footerOptions={{
                    contentElement:
                        <Stack
                            alignItems="center"
                            direction="row"
                            justifyContent="flex-end"
                            spacing={1}
                            sx={{ width: "100%" }}>
                            {sendRisksMailError && (
                                <Box sx={{ flex: 1 }}>
                                    <Message
                                        level="error"
                                        title={localization.error()}/>
                                </Box>)}
                            {sendRisksMailExecuting && (
                                <CircularProgress
                                    size={theme.spacing(2)}
                                    variant="indeterminate"/>)}
                            <Button
                                disabled={sendRisksMailExecuting || !delivery.valid}
                                onClick={sendRisksMail}>
                                {localization.actions.send()}
                            </Button>
                        </Stack>
                }}
                titleOptions={{ text: localization[typeName].title(riskIds.length) }}>
                <Stack spacing={2}>
                    <Delivery
                        delivery={delivery}
                        disabled={sendRisksMailExecuting}
                        iconVisible={true}
                        messageOptions={{ rows: 10 }}
                        scopeId={ScopeHelper.customerId}
                        onChange={delivery => setDelivery(delivery as ItemWithValidation<Contract.MailDelivery>)}/>
                </Stack>
            </FormLayout>
        </Dialog>);
}