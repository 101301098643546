import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function ArrowDownIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                clipRule="evenodd"
                d="M19.0711 13.8431L12.7072 20.2071C12.3166 20.5976 11.6835 20.5976 11.2929 20.2071L4.92898 13.8431C4.53846 13.4526 4.53846 12.8195 4.92898 12.4289C5.31951 12.0384 5.95267 12.0384 6.3432 12.4289L11.0001 17.0858L11.0001 4.5C11.0001 3.94771 11.4478 3.5 12.0001 3.5C12.5523 3.5 13.0001 3.94771 13.0001 4.5L13.0001 17.0858L17.6569 12.4289C18.0474 12.0384 18.6806 12.0384 19.0711 12.4289C19.4616 12.8195 19.4616 13.4526 19.0711 13.8431Z"
                fillRule="evenodd"/>
        </SvgIcon>);
}