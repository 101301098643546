import _, { Dictionary } from "lodash";
import { Contract, RiskController } from "../../../../../common";

export async function getWorkloadResourcesRiskModels(workloadResourceModels: Contract.WorkloadResourceModel[]): Promise<_.Dictionary<Contract.RiskModel>> {
    const riskIds =
        _(workloadResourceModels).
            map(workloadResourceModel => _.values(workloadResourceModel.riskSeverityToIdsMap)).
            flattenDeep().
            uniq().
            value();
    if (_.isEmpty(riskIds)) {
        return {};
    }
    const { riskModels } = await RiskController.getRiskModels(new Contract.RiskControllerGetRiskModelsRequest(riskIds));
    return _.keyBy(
        riskModels,
        riskModel => riskModel.risk.id);
}

export function getRiskSeverityToModelsMap(
    riskSeverityToIdsMap: Dictionary<string[]>,
    riskModelMap: Dictionary<Contract.RiskModel>): Dictionary<Contract.RiskModel[]> {
    return _(riskSeverityToIdsMap).
        mapValues(
            riskIds =>
                _(riskIds).
                    map(
                        riskId =>
                            _(riskModelMap).get(riskId, null)).
                    compact().
                    value()).
        value();
}