import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useGcpCommonScopeResourceInfoItemElements, useGcpCommonServiceAccountOriginatorScopeResourceInfoElements, useGcpDefaultResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../..";
import { useCustomEntityPropertyInfoItemElements } from "..";
import { Contract, InfoItem, InlineTextViewer, VerticalTopItemsInfoItem } from "../../../../../../../../../../common";
import { EntitiesInfoItem, Info } from "../../../../components";
import { GcpScopeResourceRoleBindings } from "../../components";

export function useGcpComputeInstanceTemplateDefinition(scopeResourceModel: Contract.GcpScopeResourceModel, options?: DefinitionOptions) {
    const commonServiceAccountOriginatorScopeResourceInfoElements = useGcpCommonServiceAccountOriginatorScopeResourceInfoElements(scopeResourceModel);
    const commonScopeResourcesInfoElements = useGcpCommonScopeResourceInfoItemElements(scopeResourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(scopeResourceModel);
    const defaultResourceInfoItemElements = useGcpDefaultResourceInfoItemElements(scopeResourceModel);
    const instanceTemplateModel = scopeResourceModel as Contract.GcpComputeInstanceTemplateModel;
    const effectiveSshSerialPortEnabled = instanceTemplateModel.effectiveSshSerialPortEnabled;
    const instanceTemplate = instanceTemplateModel.entity as Contract.GcpComputeInstanceTemplate;

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpComputeInstanceTemplateDefinition",
            () => ({
                info: {
                    items: {
                        bootIntegrityValidationEnabled: {
                            false: "Disabled",
                            title: "Integrity Monitoring",
                            true: "Enabled"
                        },
                        computeProjectSshKeysBlockEnabled: {
                            false: "Disabled",
                            title: "Block Project-Wide SSH Keys",
                            true: "Enabled"
                        },
                        description: "Description",
                        instanceGroupIds: "Used By",
                        instanceIds: "VM Instances",
                        ipForwardingEnabled: {
                            false: "Disabled",
                            title: "IP Forwarding",
                            true: "Enabled"
                        },
                        machineType: "Machine Type",
                        memoryEncryptionEnabled: {
                            false: "Disabled",
                            title: "Confidential VM Service",
                            true: "Enabled"
                        },
                        networkTags: "Network Tags",
                        serviceAccountAuthorizationScopes: "Access Scopes",
                        sshIamEnabled: {
                            false: "Disabled",
                            falseInherited: "Disabled (inherited)",
                            title: "OS Login",
                            true: "Enabled",
                            trueInherited: "Enabled (inherited)"
                        },
                        sshSerialPortEnabled: {
                            false: {
                                [Contract.GcpComputeInstanceScopePropertySource.Resource]: "Disabled",
                                [Contract.GcpComputeInstanceScopePropertySource.OrganizationPolicy]: "Disabled (enforced by organization policy)",
                                [Contract.GcpComputeInstanceScopePropertySource.Project]: "Disabled (inherited from project)"
                            },
                            title: "Serial Port Access",
                            true: {
                                [Contract.GcpComputeInstanceScopePropertySource.Resource]: "Enabled",
                                [Contract.GcpComputeInstanceScopePropertySource.Project]: "Enabled (inherited from project)"
                            }
                        },
                        startupScriptNameToValueMap: "Startup Scripts",
                        virtualTpmEnabled: {
                            false: "Disabled",
                            title: "vTPM",
                            true: "Enabled"
                        },
                        vpcIdReferences: "VPC Network"
                    }
                },
                tabs: {
                    resourceRoleBindings: "Resource Role Bindings"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <GcpScopeResourceRoleBindings
                    csvExportFilePrefix={localization.tabs.resourceRoleBindings()}
                    scopeResourceModel={scopeResourceModel}/>,
                localization.tabs.resourceRoleBindings(),
                "resourceRoleBindings")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    commonScopeResourcesInfoElements.accessLevelInfoItemElement,
                    <InfoItem
                        key="description"
                        title={localization.info.items.description()}
                        value={
                            _.isEmpty(instanceTemplate.description)
                                ? undefined
                                : instanceTemplate.description}/>,
                    <InfoItem
                        key="machineType"
                        location="all"
                        title={localization.info.items.machineType()}
                        value={instanceTemplate.machineType}/>,
                    commonServiceAccountOriginatorScopeResourceInfoElements.serviceAccount,
                    <VerticalTopItemsInfoItem
                        items={instanceTemplate.serviceAccountData.authorizationScopes}
                        key="authorizationScopes"
                        title={localization.info.items.serviceAccountAuthorizationScopes()}/>,
                    <InfoItem
                        key="computeProjectSshKeysBlockEnabled"
                        title={localization.info.items.computeProjectSshKeysBlockEnabled.title()}
                        value={
                            instanceTemplate.computeProjectSshKeysBlockEnabled
                                ? localization.info.items.computeProjectSshKeysBlockEnabled.true()
                                : localization.info.items.computeProjectSshKeysBlockEnabled.false()}/>,
                    <InfoItem
                        key="sshIamEnabled"
                        title={localization.info.items.sshIamEnabled.title()}
                        value={
                            _.isNil(instanceTemplateModel.effectiveSshIamEnabled)
                                ? undefined
                                : !_.isNil(instanceTemplate.sshIamEnabled)
                                    ? instanceTemplate.sshIamEnabled
                                        ? localization.info.items.sshIamEnabled.true()
                                        : localization.info.items.sshIamEnabled.false()
                                    : instanceTemplateModel.effectiveSshIamEnabled
                                        ? localization.info.items.sshIamEnabled.trueInherited()
                                        : localization.info.items.sshIamEnabled.falseInherited()}/>,
                    <InfoItem
                        key="sshSerialPortEnabled"
                        title={localization.info.items.sshSerialPortEnabled.title()}
                        value={
                            _.isNil(effectiveSshSerialPortEnabled)
                                ? undefined
                                : effectiveSshSerialPortEnabled!.enabled
                                    ? localization.info.items.sshSerialPortEnabled.true[effectiveSshSerialPortEnabled.source as Exclude<Contract.GcpComputeInstanceScopePropertySource, Contract.GcpComputeInstanceScopePropertySource.OrganizationPolicy>]()
                                    : localization.info.items.sshSerialPortEnabled.false[effectiveSshSerialPortEnabled.source]()}/>,
                    <InfoItem
                        key="virtualTpmEnabled"
                        title={localization.info.items.virtualTpmEnabled.title()}
                        value={
                            instanceTemplate.virtualTpmEnabled
                                ? localization.info.items.virtualTpmEnabled.true()
                                : localization.info.items.virtualTpmEnabled.false()}/>,
                    <InfoItem
                        key="bootIntegrityValidationEnabled"
                        title={localization.info.items.bootIntegrityValidationEnabled.title()}
                        value={
                            instanceTemplate.bootIntegrityValidationEnabled
                                ? localization.info.items.bootIntegrityValidationEnabled.true()
                                : localization.info.items.bootIntegrityValidationEnabled.false()}/>,
                    <VerticalTopItemsInfoItem
                        items={instanceTemplate.networkTags}
                        key="networkTags"
                        title={localization.info.items.networkTags()}/>,
                    <InfoItem
                        key="ipForwardingEnabled"
                        title={localization.info.items.ipForwardingEnabled.title()}
                        value={
                            instanceTemplate.ipForwardingEnabled
                                ? localization.info.items.ipForwardingEnabled.true()
                                : localization.info.items.ipForwardingEnabled.false()}/>,
                    <InfoItem
                        key="memoryEncryptionEnabled"
                        title={localization.info.items.memoryEncryptionEnabled.title()}
                        value={
                            instanceTemplate.memoryEncryptionEnabled
                                ? localization.info.items.memoryEncryptionEnabled.true()
                                : localization.info.items.memoryEncryptionEnabled.false()}/>,
                    <InfoItem
                        key="startupScriptNameToValueMap"
                        limitChildren={true}
                        title={localization.info.items.startupScriptNameToValueMap()}
                        vertical={_.size(instanceTemplate.startupScriptNameToValueMap) > 1}>
                        {_.isEmpty(instanceTemplate.startupScriptNameToValueMap)
                            ? undefined
                            : _.map(
                                instanceTemplate.startupScriptNameToValueMap,
                                (value, key) =>
                                    <InlineTextViewer
                                        dialogTitle={localization.info.items.startupScriptNameToValueMap()}
                                        text={value}
                                        title={key}/>)}
                    </InfoItem>,
                    <EntitiesInfoItem
                        entityIdsOrModels={instanceTemplateModel.instanceIds}
                        entityTypeName={Contract.TypeNames.GcpComputeInstance}
                        key="instanceIds"
                        location="all"
                        title={localization.info.items.instanceIds()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={instanceTemplateModel.instanceGroupIds}
                        entityTypeName={Contract.TypeNames.GcpComputeInstanceGroup}
                        key="instanceGroupIds"
                        location="all"
                        title={localization.info.items.instanceGroupIds()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={instanceTemplateModel.vpcIdReferences}
                        entityTypeName={Contract.TypeNames.GcpComputeVpc}
                        key="vpcIdReferences"
                        title={localization.info.items.vpcIdReferences()}/>
                ]}
                options={options?.infoOptions}>
            </Info>
    });
}