import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, DeferredFilter, PagedValuesFilter, TimeFormatter, TimeRangeFilter, useLocalization, ValueFilter } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, PagedEntityFilter, tenantModelStore, TimeRangeHelper, useEntitiesExportOptions, useEntityTypeNameTranslator } from "../../../../../../../../../../common";
import { GcpRoleBindingHelper } from "../../../../../../../../../../tenants";
import { DefinitionData } from "../../../../Table";
import { useGciCommonEntityDefinition } from "./useGciCommonEntityDefinition";

export function useGciCommonDirectoryPrincipalDefinition(definitionData: DefinitionData) {
    const principalModelFiltersPromise = definitionData.entityModelFiltersPromise as Promise<Contract.GciDirectoryPrincipalModelFilters>;
    const commonEntityDefinition = useGciCommonEntityDefinition(definitionData);
    const activeCloudProviderTenantTypes = tenantModelStore.useGetActiveCloudProviderTenantTypes();
    const entitiesExportOptions = useEntitiesExportOptions();
    const entityTypeNameTranslator = useEntityTypeNameTranslator();

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.gci.useGciCommonDirectoryPrincipalDefinition",
            () => ({
                columns: {
                    containedGcpRoleBindingIds: {
                        helpText: "These role bindings are \"covered\" by another role binding at a higher scope level or one that is assigned via group membership.",
                        title: "Covered GCP Role Bindings"
                    },
                    creationTime: "Creation Time",
                    gcpRoleBindingIds: "GCP Role Bindings",
                    gcpRoleIds: "GCP Roles",
                    mail: "Mail"
                }
            }));
    return {
        columns: {
            ...commonEntityDefinition.columns,
            containedGcpRoleBindingIdsColumn:
                _.includes(activeCloudProviderTenantTypes, Contract.TenantType.Gcp)
                    ? <DataTableColumn
                        exportOptions={
                            entitiesExportOptions<Contract.GciDirectoryPrincipalModel>(
                                Contract.TypeNames.GcpIamRoleBinding,
                                item => item.containedGcpRoleBindingIds,
                                localization.columns.containedGcpRoleBindingIds.title(),
                                { getValue: entityModel => GcpRoleBindingHelper.getDisplayName(entityTypeNameTranslator, entityModel as Contract.GcpIamRoleBindingModel) })
                        }
                        filterOptions={{
                            itemOrItems: {
                                element:
                                    <ValueFilter
                                        items={[
                                            {
                                                value: true
                                            }
                                        ]}
                                        placeholder={localization.columns.containedGcpRoleBindingIds.title()}/>
                            }
                        }}
                        id={Contract.EntityModelProperty.GciPrincipalContainedGcpRoleBindings}
                        key={Contract.EntityModelProperty.GciPrincipalContainedGcpRoleBindings}
                        message={localization.columns.containedGcpRoleBindingIds.helpText()}
                        messageLevel="info"
                        render={
                            ({ item }: DataTableColumnRenderProps<Contract.GciDirectoryPrincipalModel>) =>
                                <EntitiesCell
                                    entityIdsOrModels={item.containedGcpRoleBindingIds}
                                    entityTypeName={Contract.TypeNames.GcpIamRoleBinding}
                                    entityVariant="iconText"/>}
                        selectorOptions={{ default: false }}
                        sortOptions={{ type: DataTableSortType.Numeric }}
                        title={localization.columns.containedGcpRoleBindingIds.title()}/>
                    : undefined,
            creationTime:
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.GciDirectoryPrincipalModel) => ({
                                [localization.columns.creationTime()]: TimeFormatter.iso8601String((item.entity as Contract.GciDirectoryPrincipal).creationTime)
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <DeferredFilter
                                    promiseOrGetPromise={principalModelFiltersPromise}
                                    title={localization.columns.creationTime()}>
                                    {filters =>
                                        <TimeRangeFilter
                                            emptyValue={filters.creationTimeRange.emptyValue}
                                            placeholder={localization.columns.creationTime()}
                                            timeRange={TimeRangeHelper.getTimeRangeFilterRange(filters.creationTimeRange.timeRange)}/>}
                                </DeferredFilter>
                        }
                    }}
                    id={Contract.EntityModelProperty.GciDirectoryPrincipalCreationTime}
                    itemProperty={(item: Contract.EntityModel) => TimeFormatter.monthDayAndYear((item.entity as Contract.GciDirectoryPrincipal).creationTime)}
                    key={Contract.EntityModelProperty.GciDirectoryPrincipalCreationTime}
                    sortOptions={{ type: DataTableSortType.Date }}
                    title={localization.columns.creationTime()}/>,
            emailColumn:
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.GciDirectoryPrincipalModel) => ({
                                [localization.columns.mail()]: (item.entity as Contract.GciDirectoryPrincipal).mail
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedValuesFilter
                                    getValuePage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.GciDirectoryPrincipalMail)}
                                    placeholder={localization.columns.mail()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.GciDirectoryPrincipalMail}
                    itemProperty={(item: Contract.EntityModel) => (item.entity as Contract.GciDirectoryPrincipal).mail}
                    key={Contract.EntityModelProperty.GciDirectoryPrincipalMail}
                    title={localization.columns.mail()}/>,
            gcpRoleBindingIdsColumn:
                _.includes(activeCloudProviderTenantTypes, Contract.TenantType.Gcp)
                    ? <DataTableColumn
                        exportOptions={
                            entitiesExportOptions<Contract.GciDirectoryPrincipalModel>(
                                Contract.TypeNames.GcpIamRoleBinding,
                                item => item.gcpRoleBindingIds,
                                localization.columns.gcpRoleBindingIds(),
                                { getValue: entityModel => GcpRoleBindingHelper.getDisplayName(entityTypeNameTranslator, entityModel as Contract.GcpIamRoleBindingModel) })
                        }
                        id={Contract.EntityModelProperty.GciPrincipalGcpRoleBindings}
                        key={Contract.EntityModelProperty.GciPrincipalGcpRoleBindings}
                        render={
                            ({ item }: DataTableColumnRenderProps<Contract.GciDirectoryPrincipalModel>) =>
                                <EntitiesCell
                                    entityIdsOrModels={item.gcpRoleBindingIds}
                                    entityTypeName={Contract.TypeNames.GcpIamRoleBinding}
                                    entityVariant="iconText"/>}
                        sortOptions={{ type: DataTableSortType.Numeric }}
                        title={localization.columns.gcpRoleBindingIds()}/>
                    : undefined,
            gcpRoleIdsFilterColumn:
                _.includes(activeCloudProviderTenantTypes, Contract.TenantType.Gcp)
                    ? <DataTableColumn
                        filterOptions={{
                            itemOrItems: {
                                element:
                                    <PagedEntityFilter
                                        getEntityIdPage={
                                            ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                                definitionData.entityTypeEntitiesViewName,
                                                Contract.EntityModelProperty.GciDirectoryPrincipalGcpRoles)}
                                        placeholder={localization.columns.gcpRoleIds()}/>
                            }
                        }}
                        id={Contract.EntityModelProperty.GciDirectoryPrincipalGcpRoles}
                        key={Contract.EntityModelProperty.GciDirectoryPrincipalGcpRoles}
                        title={localization.columns.gcpRoleIds()}/>
                    : undefined
        }
    };
}