import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function CyberArkIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                clipRule="evenodd"
                d="M12.6794 23.4833L22.5167 17.799V12.1914L12.6794 17.8756V23.4833ZM12.2775 17.8756L7.49282 15.1196L2.63158 17.933L12.2775 23.4833V17.8756ZM12.2584 6.14354V0.535885L2.61244 6.10526L7.49282 8.89952L12.2584 6.14354ZM17.445 8.89952L22.3062 6.08612L12.6603 0.535885V6.14354L17.445 8.89952ZM2.42105 17.5885L7.2823 14.7751H7.26316V9.24402L2.40191 6.44976L2.42105 17.5885ZM7.68421 14.5263L12.2584 11.8852V6.62201L7.68421 9.26316V14.5263ZM17.2344 9.26316L12.6603 6.62201V11.8852L17.2344 14.5455V9.26316ZM7.89474 14.89L12.4689 17.512L17.0431 14.89L12.4689 12.2488L7.89474 14.89ZM17.6364 14.5263L22.6124 11.6555L22.9187 11.8278V17.9139L22.823 18.0861L12.5837 24H12.3732L2.11483 18.1053L2.01914 17.933L2 6.10526L2.09569 5.93301L12.3541 0H12.5646L22.8038 5.93301V6.27751L17.6364 9.26316V14.5263Z"
                fill="url(#paint0_linear_58343_2098)"
                fillRule="evenodd"/>
            <defs>
                <linearGradient
                    gradientUnits="userSpaceOnUse"
                    id="paint0_linear_58343_2098"
                    x1="12.4587"
                    x2="12.4587"
                    y1="-1.38682"
                    y2="26.0566">
                    <stop stopColor="#38B1E6"/>
                    <stop
                        offset="0.00527273"
                        stopColor="#38B1E6"/>
                    <stop
                        offset="0.2666"
                        stopColor="#329CD7"/>
                    <stop
                        offset="0.7878"
                        stopColor="#296FB8"/>
                    <stop
                        offset="1"
                        stopColor="#296FB8"/>
                </linearGradient>
            </defs>
        </SvgIcon>);
}