import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function InfinipointIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <g clipPath="url(#clip0_58343_2654)">
                <path
                    clipRule="evenodd"
                    d="M16.4251 12.4248C16.4251 14.8335 14.4505 16.7861 12.0146 16.7861C9.57872 16.7861 7.604 14.8335 7.604 12.4248C7.604 10.0156 9.57872 8.06299 12.0146 8.06299C14.4505 8.06299 16.4251 10.0156 16.4251 12.4248Z"
                    fill="#EF513D"
                    fillRule="evenodd"/>
                <path
                    clipRule="evenodd"
                    d="M16.9972 8.07412V16.7747C19.3277 16.658 21.1826 14.7579 21.1826 12.4247C21.1826 10.0909 19.3277 8.19082 16.9972 8.07412Z"
                    fill="#EF513D"
                    fillRule="evenodd"/>
                <path
                    clipRule="evenodd"
                    d="M21.8933 8.71334V16.1358C23.1555 15.3698 24 13.9972 24 12.4248C24 10.8522 23.1555 9.47928 21.8933 8.71334Z"
                    fill="#EF513D"
                    fillRule="evenodd"/>
                <path
                    clipRule="evenodd"
                    d="M7.00246 8.07412V16.7747C4.67221 16.658 2.81732 14.7579 2.81732 12.4247C2.81732 10.0909 4.67221 8.19082 7.00246 8.07412Z"
                    fill="#EF513D"
                    fillRule="evenodd"/>
                <path
                    clipRule="evenodd"
                    d="M2.10665 8.71334V16.1358C0.84414 15.3698 0 13.9972 0 12.4248C0 10.8522 0.84414 9.47928 2.10665 8.71334Z"
                    fill="#EF513D"
                    fillRule="evenodd"/>
            </g>
            <defs>
                <clipPath id="clip0_58343_2654">
                    <rect
                        fill="white"
                        height="24"
                        width="23.9962"/>
                </clipPath>
            </defs>
        </SvgIcon>);
}