﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition, useGetAwsSqsQueueRiskContext } from "../../..";
import { Contract, Entity, entityModelStore, InlinePermissionActions } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { RiskDefinitionContextItem } from "../../../../../../utilities";

export function useAwsSqsQueueEncryptionDisabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsSqsQueueEncryptionDisabledRisk;
    const queueModel = entityModelStore.useGet(risk.entityId) as Contract.AwsSqsQueueModel;

    const getAwsSqsQueueRiskContext = useGetAwsSqsQueueRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsSqsQueueEncryptionDisabledRiskDefinition",
            () => ({
                contextItems: {
                    crossTenantAccessDataLeakagePermissionActions: "The queue resource policy exposes {{crossTenantAccessDataLeakagePermissionActions}} to cross account identities"
                },
                description: "{{encryptionDisabledQueue}} is not configured with encryption using KMS",
                sections: {
                    resolution: {
                        step1: "Before changing the encryption, verify that applications using the queue have decrypt permissions",
                        step2: "In the **Encryption** section, set the **Server-side encryption** option to **Enabled**",
                        step3: "For the **Encryption key type**, select **AWS Key Management Service key (SSE-KMS)**",
                        step4: "For the **Customer master key**, select the relevant KMS key. By default, the managed SQS key is used",
                        step5: "Click **Save**"
                    }
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            encryptionDisabledQueue:
                <Entity
                    entityIdOrModel={queueModel}
                    variant="text"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.Sqs,
                AwsConsoleUrlBuilder.getSqsQueueEditUrl(queueModel.entity as Contract.AwsSqsQueue)),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4(),
            localization.sections.resolution.step5()
        ],
        riskModel,
        () => {
            const queueRiskContext = getAwsSqsQueueRiskContext(queueModel);
            return [
                queueRiskContext.generalInformation,
                queueRiskContext.accessLevel,
                queueRiskContext.publicAccessPermissionActions,
                _.isEmpty(risk.crossTenantAccessDataLeakagePermissionActions)
                    ? undefined
                    : new RiskDefinitionContextItem(
                        localization.contextItems.crossTenantAccessDataLeakagePermissionActions({
                            crossTenantAccessDataLeakagePermissionActions:
                                <InlinePermissionActions
                                    permissionActions={risk.crossTenantAccessDataLeakagePermissionActions}
                                    variant="itemCountAndType"/>
                        })),
                queueRiskContext.sensitive,
                queueRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        });
}