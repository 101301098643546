import { InlineItems, useLocalization } from "@infrastructure";
import React, { useMemo } from "react";
import { Contract, tenantModelStore, useTenantNameTranslator } from "../../../../../../../../common";
import { AzureAuthorizationRoleAssignmentResourceHelper } from "../../../../../../../../tenants";
import { ProfileDefinition } from "../../useProfileDefinition";
import { useGetCloudProviderTenantProfilePermissionsSection } from "../useGetCloudProviderTenantProfilePermissionsSection";
import { StatusCell } from "./components";

export function useAzureProfileDefinition(scopeNodeModel: Contract.ScopeNodeModel): ProfileDefinition {
    const tenantModelMap = tenantModelStore.useGetAzureTenantMap();

    const localization =
        useLocalization(
            "views.customer.scopes.hooks.useDefinition.hooks.useAzureDefinition.useAzureProfileDefinition",
            () => ({
                aadTenantId: "Microsoft Entra ID",
                permissions: "Permissions",
                rawShortId: "ID",
                servicePrincipalAssignedRoleDefinitions: "Application Roles",
                status: "Status"
            }));

    const getTenantProfilePermissionsSection = useGetCloudProviderTenantProfilePermissionsSection();
    const tenantNameTranslator = useTenantNameTranslator();
    return useMemo(
        () => {
            const tenantModel = tenantModelMap[scopeNodeModel.configuration.id];
            return {
                sections: [
                    {
                        items: [
                            {
                                title: localization.rawShortId(),
                                value: tenantModel.configuration.rawShortId
                            },
                            {
                                title: localization.aadTenantId(),
                                value: tenantNameTranslator((scopeNodeModel.configuration as Contract.AzureTenantConfiguration).aadTenantId)
                            },
                            {
                                title: localization.servicePrincipalAssignedRoleDefinitions(),
                                value:
                                    <InlineItems
                                        items={AzureAuthorizationRoleAssignmentResourceHelper.getTenantAssignedRoleDefinitionNames(tenantModel)}
                                        variant="itemPlusItemCount"/>
                            },
                            {
                                title: localization.status(),
                                value: <StatusCell item={tenantModel}/>
                            }
                        ]
                    },
                    getTenantProfilePermissionsSection(tenantModel)
                ]
            };
        },
        [localization, tenantModelMap]);
}