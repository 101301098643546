import { IconText } from "@infrastructure";
import React from "react";
import { Contract, GroupedTableColumnRenderProps, TenantIcon, tenantModelStore } from "../../../../../../../../../../../../../../../common";
import { GroupedItem } from "../../../../../../../Grouped";

export function Tenant({ item }: GroupedTableColumnRenderProps<GroupedItem>) {
    const tenantModel = tenantModelStore.useGet((item.riskGroup as Contract.RiskControllerGetRiskGroupsResponseRiskTenantGroup).tenantId);
    return (
        <IconText
            icon={<TenantIcon tenantType={tenantModel.tenantType}/>}
            iconSx={{ fontSize: "18px" }}
            text={tenantModel.configuration.name}/>);
}