import { CollapsedIcon, Optional, useActions, useLocalization } from "@infrastructure";
import { Check } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Box, Stack, Typography } from "@mui/material";
import { TOptions } from "i18next";
import _ from "lodash";
import React, { Ref, useEffect, useState } from "react";
import { Contract, useEntityTypeNameTranslator, useTheme } from "../../../../../../";
import { CustomRiskPolicyActions } from "../../../..";
import { EntityMultiSelect, EntityMultiSelectPermissionEvaluationEntities, EntityMultiSelectProperty, InlineEntityMultiSelection, InlineEntitySelection } from "../../../";
import { EntityMatchConditionHelper } from "../../../EntityMultiSelect/utilities";
import { RoleDefinitionSelector } from "./components";

type EditProps = {
    actionsRef: Ref<Optional<CustomRiskPolicyActions>>;
    onValidChange: (valid: boolean) => void;
    riskPolicyConfiguration?: Contract.AzurePrincipalNotAllowedResourceRoleDefinitionRiskPolicyConfiguration;
    templateTranslator: (options?: TOptions) => string;
};

export function Edit({ actionsRef, onValidChange, riskPolicyConfiguration, templateTranslator }: EditProps) {
    const [principalsValid, setPrincipalsValid] = useState(false);
    const [roleDefinitionsValid, setRoleDefinitionsValid] = useState(false);
    const [scopeResourcesValid, setScopeResourcesValid] = useState(false);
    const [view, setView] = useState<EditView | undefined>(EditView.Principals);

    const [principalExcludeMatchConditions, setPrincipalExcludeMatchConditions] = useState<Contract.EntityMatchCondition[]>(riskPolicyConfiguration?.excludePrincipalMatchConditions ?? []);
    const [principalMatchConditions, setPrincipalMatchConditions] = useState<Contract.EntityMatchCondition[]>(riskPolicyConfiguration?.principalMatchConditions ?? []);
    const [roleDefinitions, setRoleDefinitions] =
        useState<Contract.AzurePrincipalNotAllowedResourceRoleDefinitionRiskPolicyConfigurationRoleDefinitions>(
            _.isNil(riskPolicyConfiguration)
                ? new Contract.AzurePrincipalNotAllowedResourceRoleDefinitionRiskPolicyConfigurationRoleDefinitions(
                    [],
                    Contract.AzurePrincipalNotAllowedResourceRoleDefinitionRiskPolicyConfigurationRoleDefinitionsOperator.Subset)
                : riskPolicyConfiguration.roleDefinitions);
    const [scopeResourceExcludeMatchConditions, setScopeResourceExcludeMatchConditions] = useState<Contract.EntityMatchCondition[]>(riskPolicyConfiguration?.excludeScopeResourceMatchConditions ?? []);
    const [scopeResourceMatchConditions, setScopeResourceMatchConditions] = useState<Contract.EntityMatchCondition[]>(riskPolicyConfiguration?.scopeResourceMatchConditions ?? []);

    useActions(
        actionsRef,
        {
            createRequest:
                (description: Optional<string>, name: string, scopeId: string, severity: Contract.Severity) => new Contract.RiskControllerInsertAzurePrincipalNotAllowedResourceRoleDefinitionRiskPolicyRequest(
                    description,
                    name,
                    scopeId,
                    severity,
                    principalExcludeMatchConditions,
                    scopeResourceExcludeMatchConditions,
                    principalMatchConditions,
                    roleDefinitions,
                    scopeResourceMatchConditions)
        });

    useEffect(
        () => {
            const principalsValid =
                EntityMatchConditionHelper.validateConditions(
                    principalMatchConditions,
                    principalExcludeMatchConditions);
            const roleDefinitionsValid = !_.isEmpty(roleDefinitions.ids);
            const scopeResourcesValid =
                EntityMatchConditionHelper.validateConditions(
                    scopeResourceMatchConditions,
                    scopeResourceExcludeMatchConditions);
            setPrincipalsValid(principalsValid);
            setRoleDefinitionsValid(roleDefinitionsValid);
            setScopeResourcesValid(scopeResourcesValid);
            onValidChange(principalsValid && roleDefinitionsValid && scopeResourcesValid);
        },
        [principalExcludeMatchConditions, principalMatchConditions, roleDefinitions, scopeResourceExcludeMatchConditions, scopeResourceMatchConditions]);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "common.customRiskPolicy.azurePrincipalNotAllowedResourceRoleDefinitionRiskPolicy.edit",
            () => ({
                prompt: "Select the required principals, roles and resources",
                roleDefinitions: "Roles"
            }));
    const theme = useTheme();
    return (
        <Stack spacing={2}>
            <Typography variant="h5">
                {localization.prompt()}
            </Typography>
            <Stack spacing={1}>
                <Typography
                    sx={{
                        backgroundColor: theme.palette.background.alternate,
                        borderRadius: theme.spacing(0.75),
                        padding: theme.spacing(2)
                    }}>
                    {templateTranslator({
                        principals:
                            <InlineEntityMultiSelection
                                conditions={principalMatchConditions}
                                entityTypeName={Contract.TypeNames.AadDirectoryPrincipal}
                                excludeConditions={principalExcludeMatchConditions}/>,
                        roleDefinitions:
                            <InlineEntitySelection
                                entityTypeName={Contract.TypeNames.AzureAuthorizationRoleDefinition}
                                selection={{
                                    entityIds:
                                        _.isEmpty(roleDefinitions.ids)
                                            ? undefined
                                            : roleDefinitions.ids
                                }}/>,
                        scopeResources:
                            <InlineEntityMultiSelection
                                conditions={scopeResourceMatchConditions}
                                entityTypeName={Contract.TypeNames.AzureScopeResource}
                                excludeConditions={scopeResourceExcludeMatchConditions}/>
                    })}
                </Typography>
                <Box>
                    <Accordion
                        expanded={view === EditView.Principals}
                        onChange={
                            (_, expanded) =>
                                setView(
                                    expanded
                                        ? EditView.Principals
                                        : undefined)}>
                        <AccordionSummary expandIcon={<CollapsedIcon/>}>
                            <Stack
                                alignItems="center"
                                direction="row"
                                spacing={2}
                                sx={{ width: "100%" }}>
                                <Typography
                                    sx={{ flex: 1 }}
                                    variant="h5">
                                    {entityTypeNameTranslator(
                                        Contract.TypeNames.AadDirectoryPrincipal,
                                        {
                                            count: 0,
                                            includeServiceName: false
                                        })}
                                </Typography>
                                {principalsValid &&
                                    <Check
                                        sx={{
                                            color: theme.palette.success.main,
                                            fontSize: "18px"
                                        }}/>}
                            </Stack>
                        </AccordionSummary>
                        <AccordionDetails>
                            <EntityMultiSelect
                                builtInEntityAttributeTypeNames={[
                                    Contract.TypeNames.AadDirectoryUserExternalAttribute,
                                    Contract.TypeNames.AadDirectoryUserGuestAttribute,
                                    Contract.TypeNames.AdministratorPrincipalAttribute,
                                    Contract.TypeNames.BehaviorRiskIdentityAttribute,
                                    Contract.TypeNames.CodeResourceAttribute,
                                    Contract.TypeNames.DisabledIdentityAttribute,
                                    Contract.TypeNames.InactiveIdentityAttribute,
                                    Contract.TypeNames.MfaDisabledUserAttribute,
                                    Contract.TypeNames.RelatedPublicComputeAttribute,
                                    Contract.TypeNames.SensitiveResourcePermissionActionPrincipalAttribute,
                                    Contract.TypeNames.SevereExcessivePermissionActionPrincipalAttribute,
                                    Contract.TypeNames.SeverePermissionActionPrincipalAttribute,
                                    Contract.TypeNames.VendorServiceIdentityAttribute
                                ]}
                                conditions={principalMatchConditions}
                                directoryEntityType={true}
                                entityTypeName={Contract.TypeNames.AadDirectoryPrincipal}
                                entityTypeNameVariant="name"
                                excludeConditions={principalExcludeMatchConditions}
                                previewFilterScope={false}
                                includeServiceName={true}
                                properties={[
                                    EntityMultiSelectProperty.AadDirectoryPrincipalGroupIds,
                                    EntityMultiSelectProperty.All,
                                    EntityMultiSelectProperty.Attributes,
                                    EntityMultiSelectProperty.Ids,
                                    EntityMultiSelectProperty.NamePattern,
                                    EntityMultiSelectProperty.TypeNames
                                ]}
                                onConditionsChanged={setPrincipalMatchConditions}
                                onExcludeConditionsChanged={setPrincipalExcludeMatchConditions}/>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        expanded={view === EditView.RoleDefinitions}
                        onChange={
                            (_, expanded) =>
                                setView(
                                    expanded
                                        ? EditView.RoleDefinitions
                                        : undefined)}>
                        <AccordionSummary expandIcon={<CollapsedIcon/>}>
                            <Stack
                                alignItems="center"
                                direction="row"
                                spacing={2}
                                sx={{ width: "100%" }}>
                                <Typography
                                    sx={{ flex: 1 }}
                                    variant="h5">
                                    {localization.roleDefinitions()}
                                </Typography>
                                {roleDefinitionsValid &&
                                    <Check
                                        sx={{
                                            color: theme.palette.success.main,
                                            fontSize: "18px"
                                        }}/>}
                            </Stack>
                        </AccordionSummary>
                        <AccordionDetails>
                            <RoleDefinitionSelector
                                selection={roleDefinitions}
                                onSelectionChanged={setRoleDefinitions}/>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        expanded={view === EditView.ScopeResources}
                        onChange={
                            (_, expanded) =>
                                setView(
                                    expanded
                                        ? EditView.ScopeResources
                                        : undefined)}>
                        <AccordionSummary expandIcon={<CollapsedIcon/>}>
                            <Stack
                                alignItems="center"
                                direction="row"
                                spacing={2}
                                sx={{ width: "100%" }}>
                                <Typography
                                    sx={{ flex: 1 }}
                                    variant="h5">
                                    {entityTypeNameTranslator(
                                        Contract.TypeNames.AzureScopeResource,
                                        {
                                            count: 0,
                                            includeServiceName: false
                                        })}
                                </Typography>
                                {scopeResourcesValid &&
                                    <Check
                                        sx={{
                                            color: theme.palette.success.main,
                                            fontSize: "18px"
                                        }}/>}
                            </Stack>
                        </AccordionSummary>
                        <AccordionDetails>
                            <EntityMultiSelect
                                builtInEntityAttributeTypeNames={[
                                    Contract.TypeNames.CodeResourceAttribute,
                                    Contract.TypeNames.PublicEntityAttribute,
                                    Contract.TypeNames.RelatedPublicComputeAttribute,
                                    Contract.TypeNames.SensitiveResourceAttribute
                                ]}
                                conditions={scopeResourceMatchConditions}
                                entityTypeName={Contract.TypeNames.AzureScopeResource}
                                excludeConditions={scopeResourceExcludeMatchConditions}
                                previewFilterScope={true}
                                permissionEvaluationEntities={EntityMultiSelectPermissionEvaluationEntities.Azure}
                                properties={[
                                    EntityMultiSelectProperty.All,
                                    EntityMultiSelectProperty.Attributes,
                                    EntityMultiSelectProperty.AzureScopeResourceParentEntityIds,
                                    EntityMultiSelectProperty.Ids,
                                    EntityMultiSelectProperty.NamePattern,
                                    EntityMultiSelectProperty.Properties,
                                    EntityMultiSelectProperty.Tags,
                                    EntityMultiSelectProperty.TenantIds,
                                    EntityMultiSelectProperty.TypeNames
                                ]}
                                onConditionsChanged={setScopeResourceMatchConditions}
                                onExcludeConditionsChanged={setScopeResourceExcludeMatchConditions}/>
                        </AccordionDetails>
                    </Accordion>
                </Box>
            </Stack>
        </Stack>);
}

enum EditView {
    Principals = "principals",
    RoleDefinitions = "roleDefinitions",
    ScopeResources = "scopeResources"
}