﻿import { useLocalization } from "@infrastructure";
import React from "react";
import { useOciCommonIamResourceInfoItemElements } from ".";
import { Contract } from "../../../../../../../../../../common";
import { EntitiesInfoItem } from "../../../../components";

export function useOciCommonIamCredentialInfoItemElements(credentialModel: Contract.OciIamUserCredentialModel) {
    const commonIamResourceInfoItemElements = useOciCommonIamResourceInfoItemElements(credentialModel);
    const credential = credentialModel.entity as Contract.OciIamUserCredential;

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.oci.useOciCommonIamCredentialInfoItemElements",
            () => ({
                info: {
                    items: {
                        user: "User"
                    }
                }
            }));

    return {
        ...commonIamResourceInfoItemElements,
        userIdInfoItemElement:
            <EntitiesInfoItem
                entityIdsOrModels={credential.userId}
                entityTypeName={Contract.TypeNames.OciIamUser}
                key="user"
                location="all"
                title={localization.info.items.user()}/>
    };
}