import _ from "lodash";
import React from "react";
import { ActionButton, ActionMenuItem, ContentMenuItem, Menu, MenuItem, setUrlRoute, useApplicationContext, useLocalization } from "@infrastructure";
import { Contract } from "../../../..";
import { CustomerConsoleAppUrlHelper, FeatureHelper, PendoHelper, UrlHelper } from "../../../../utilities";
import { AboutIcon, ApiIcon, ContactUsIcon, DocumentationIcon, OnboardingIcon, ReleaseNotesIcon } from "../icons";
import { PendoOnboardingGuides } from "./components";

export function AboutDropdown() {
    const { customerActionAvailable, permittedContent } = useApplicationContext();

    const localization =
        useLocalization(
            "common.topbar.aboutDropdown",
            () => ({
                actions: {
                    apiExplorer: "API Explorer",
                    documentation: "Documentation",
                    onboarding: "Onboarding Guides",
                    releaseNotes: "Release Notes",
                    support: "Contact Us"
                }
            }));

    return (
        <Menu
            itemsOrGetItems={
                _<MenuItem>([]).
                    concat([
                        new ActionMenuItem(
                            () => UrlHelper.openNewTab(CustomerConsoleAppUrlHelper.getDocsLatestRelativeUrl()),
                            localization.actions.releaseNotes(),
                            { icon: <ReleaseNotesIcon/> })
                    ]).
                    concat(
                        new ActionMenuItem(
                            () => UrlHelper.openNewTab(CustomerConsoleAppUrlHelper.getDocsRelativeUrl()),
                            localization.actions.documentation(),
                            { icon: <DocumentationIcon/> })).
                    concatIf(
                        FeatureHelper.enabled(Contract.FeatureName.OnboardingGuidesEnabled) && customerActionAvailable && !_.isEmpty(PendoHelper.guides),
                        new ContentMenuItem(
                            onClose => <PendoOnboardingGuides onClick={onClose}/>,
                            localization.actions.onboarding(),
                            {
                                icon: <OnboardingIcon/>,
                                placement: "topRight",
                                variant: "subMenu"
                            })).
                    concatIf(
                        customerActionAvailable,
                        new ActionMenuItem(
                            () => setUrlRoute(CustomerConsoleAppUrlHelper.getGraphqlRelativeUrl()),
                            localization.actions.apiExplorer(),
                            {
                                disabled: !permittedContent,
                                icon: <ApiIcon/>
                            })).
                    concat(
                        new ActionMenuItem(
                            () => UrlHelper.openSupportTab(),
                            localization.actions.support(),
                            { icon: <ContactUsIcon/> })).
                    value()}
            variant="bottomRight">
            <ActionButton size="medium">
                <AboutIcon sx={{ fontSize: "20px" }}/>
            </ActionButton>
        </Menu>);
}