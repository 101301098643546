﻿import { useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import React from "react";
import { useEntityTypeNameTranslator } from "../../../../../../../hooks";

type AnyCustomerManagedKmsKeyProps = {
    entityTypeName: string;
    includeServiceName: boolean;
};

export function AwsKmsKeyCustomerManaged({ entityTypeName, includeServiceName }: AnyCustomerManagedKmsKeyProps) {
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "common.customRiskPolicy.entityMultiSelect.condition.awsKmsKeyCustomerManaged",
            () => ({
                title: "Any customer managed encryption {{keyTranslatedTypeName}}"
            }));

    return (
        <Typography>
            {localization.title({
                keyTranslatedTypeName:
                    entityTypeNameTranslator(
                        entityTypeName,
                        {
                            includeServiceName,
                            variant: "text"
                        })
            })}
        </Typography>);
}