import { DataTableColumnRenderProps, NoneIcon } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, InlineRisks } from "../../../../../../../../../../../common";

export function RisksCell({ item }: DataTableColumnRenderProps<Contract.IPrincipalModel>) {
    return (
        item.unknown
            ? <NoneIcon sx={{ fontSize: "18px" }}/>
            : <InlineRisks
                riskIdsOrModels={_.as<Contract.EntityModel>(item).risks.risks.openRiskedEntityRiskIds}
                variant="itemCount"/>);
}