import { Action0, ActionMenuItem, CsvIcon, DeleteIcon, DownloadIcon, DuplicateIcon, EditIcon, Menu, MenuItem, Message, PdfIcon, setUrlRoute, SubMenuItem, TimeHelper, ToastHelper, useExportCsv, useGetExportFileName, useLocalization } from "@infrastructure";
import { Box, CircularProgress, Stack } from "@mui/material";
import _ from "lodash";
import React, { Fragment, useMemo, useState } from "react";
import { ConfigurationController, Contract, CustomerConsoleAppUrlHelper, ExportReportHelper, RiskController, riskPolicyModelStore, scopeNodeModelStore, scopeSystemEntityModelStore, useComplianceExportCsvData, useGetComplianceScopeId, useProjectScopeSelected, UserHelper, useTheme } from "../../../../../../common";
import { ComplianceView } from "../../Compliance";
import { ComplianceItem } from "../../hooks";
import { DisableIcon, EnableIcon, ExportIcon } from "./icons";

type ActionsCellProps = {
    item: ComplianceItem;
    onDataChanged?: () => void;
    onDelete?: Action0;
    scopeId: string;
};

export function ActionsCell({ item, onDataChanged, onDelete, scopeId }: ActionsCellProps) {
    const [errorMessage, setErrorMessage] = useState<string>();
    const [executing, setExecuting] = useState(false);
    const customComplianceModels = scopeSystemEntityModelStore.useGetCustomCompliance();
    const customComplianceModelMap =
        useMemo(
            () =>
                _.keyBy(
                    customComplianceModels,
                    customComplianceModel => customComplianceModel.configuration.id),
            [customComplianceModels]);
    const scopeNodeMap =
        scopeNodeModelStore.useGetActiveScopeNodeMap(
            undefined,
            true);
    const { fileNameOptions, getCsvItems } =
        useComplianceExportCsvData(
            scopeId,
            item.sectionData);

    const complianceExportCsv =
        useExportCsv(
            fileNameOptions,
            getCsvItems);

    const getComplianceScopeId = useGetComplianceScopeId();
    const complianceScopeId =
        useMemo(
            () => getComplianceScopeId(item.sectionData.identifier, scopeId),
            [item, scopeId]);

    const editEnabled =
        useMemo(
            () =>
                (item.sectionData.custom && _.includes(scopeNodeMap[scopeId].scopeIds, customComplianceModelMap[item.sectionData.identifier].scopeId)) ||
                (scopeNodeMap[scopeId].scopeNodeModel.configuration.typeName === Contract.TypeNames.CustomerConfiguration &&
                    UserHelper.hasScopePermissions(complianceScopeId, Contract.IdentityPermission.SecurityWrite)),
            [item.sectionData, scopeNodeMap, complianceScopeId, scopeId]);

    const getExportPdfFileName = useGetExportFileName(Contract.ReportContentType.Pdf);
    const exportPdfFileName =
        useMemo(
            () => getExportPdfFileName(fileNameOptions),
            [fileNameOptions]);
    const projectScopeSelected = useProjectScopeSelected();
    async function complianceExportPdf() {
        await ExportReportHelper.downloadRemote(
            item.sectionData.custom
                ? new Contract.ReportControllerCustomComplianceReportRequestDefinition(
                    exportPdfFileName,
                    TimeHelper.timeZoneId(),
                    Contract.TypeNames.ReportControllerCustomComplianceReportRequestDefinition,
                    Contract.ReportContentType.Pdf,
                    item.sectionData.identifier)
                : new Contract.ReportControllerBuiltInComplianceReportRequestDefinition(
                    exportPdfFileName,
                    TimeHelper.timeZoneId(),
                    Contract.TypeNames.ReportControllerBuiltInComplianceReportRequestDefinition,
                    Contract.ReportContentType.Pdf,
                    item.sectionData.identifier as Contract.BuiltInComplianceSectionType),
            complianceScopeId);
        ToastHelper.toast(
            {
                content: localization.toast.content(),
                icon: <DownloadIcon/>,
                title: localization.toast.title()
            },
            { hideProgressBar: true });
    }

    async function updateBuiltInComplianceStatus(enable: boolean) {
        await RiskController.updateBuiltInComplianceEnabled(
            new Contract.RiskControllerUpdateBuiltInComplianceEnabledRequest(
                item.sectionData.identifier as Contract.BuiltInComplianceSectionType,
                enable));
        onDataChanged?.();
        await item.refreshComplianceSectionDatas();
    }

    async function deleteCustomComplianceConfiguration() {
        setErrorMessage(undefined);
        try {
            await ConfigurationController.deleteCustomCompliance(new Contract.ConfigurationControllerDeleteCustomComplianceRequest(item.sectionData.identifier));
            onDataChanged?.();
            await item.refreshComplianceSectionDatas();
            onDelete?.();
            await scopeSystemEntityModelStore.notify(item.sectionData.identifier);
            await riskPolicyModelStore.notify();
        } catch {
            setErrorMessage(localization.actions.delete.error());
        }
    }

    async function execute(action: () => Promise<void>) {
        setExecuting(true);
        try {
            await action();
        } catch (error) {
            setExecuting(false);
            throw error;
        }
        setExecuting(false);
    }

    const localization =
        useLocalization(
            "views.customer.compliance.actionsCell",
            () => ({
                actions: {
                    delete: {
                        error: "Failed to delete compliance",
                        prompt: "Are you sure you want to delete this compliance?",
                        title: "Delete"
                    },
                    disable: {
                        prompt: "Are you sure you want to disable this compliance?",
                        title: "Disable"
                    },
                    duplicate: "Duplicate",
                    edit: "Edit",
                    enable: {
                        prompt: "Are you sure you want to enable this compliance?",
                        title: "Enable"
                    },
                    export: {
                        csv: "CSV",
                        pdf: "PDF",
                        title: "Export"
                    }
                },
                toast: {
                    content: "Your download will start shortly.",
                    title: "Export"
                }
            }));

    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            direction="row"
            justifyContent="flex-end"
            spacing={1.5}>
            {executing
                ? <CircularProgress
                    size={theme.spacing(2)}
                    variant="indeterminate"/>
                : <Fragment>
                    <Menu
                        itemsOrGetItems={
                            _<MenuItem>([]).
                                concatIf(
                                    item.sectionData.custom && editEnabled,
                                    new ActionMenuItem(
                                        () => {
                                            setUrlRoute(
                                                CustomerConsoleAppUrlHelper.getComplianceRelativeUrl(
                                                    scopeId,
                                                    ComplianceView.Edit,
                                                    item.sectionData.identifier));
                                        },
                                        localization.actions.edit(),
                                        { icon: <EditIcon/> })).
                                concatIf(
                                    !item.sectionData.custom && editEnabled,
                                    new ActionMenuItem(
                                        () => execute(() => updateBuiltInComplianceStatus(item.disabled)),
                                        item.disabled
                                            ? localization.actions.enable.title()
                                            : localization.actions.disable.title(),
                                        {
                                            confirmOptions: {
                                                message:
                                                    item.disabled
                                                        ? localization.actions.enable.prompt()
                                                        : localization.actions.disable.prompt()
                                            },
                                            icon:
                                                item.disabled
                                                    ? <EnableIcon/>
                                                    : <DisableIcon/>
                                        })).
                                concatIf(
                                    !projectScopeSelected &&
                                    UserHelper.hasScopePermissions(
                                        scopeId,
                                        Contract.IdentityPermission.SecurityWrite),
                                    new ActionMenuItem(
                                        () => {
                                            setUrlRoute(
                                                CustomerConsoleAppUrlHelper.getComplianceRelativeUrl(
                                                    scopeId,
                                                    ComplianceView.Create,
                                                    item.sectionData.identifier));
                                        },
                                        localization.actions.duplicate(),
                                        { icon: <DuplicateIcon/> })).
                                concatIf(
                                    !item.disabled,
                                    new SubMenuItem(
                                        [
                                            new ActionMenuItem(
                                                () => execute(complianceExportCsv),
                                                localization.actions.export.csv(),
                                                { icon: <CsvIcon/> }),
                                            new ActionMenuItem(
                                                () => execute(complianceExportPdf),
                                                localization.actions.export.pdf(),
                                                { icon: <PdfIcon/> })
                                        ],
                                        <Box sx={{ paddingRight: theme.spacing(2) }}>
                                            {localization.actions.export.title()}
                                        </Box>,
                                        { icon: <ExportIcon/> })).
                                concatIf(
                                    item.sectionData.custom && editEnabled,
                                    new ActionMenuItem(
                                        () => execute(deleteCustomComplianceConfiguration),
                                        localization.actions.delete.title(),
                                        {
                                            confirmOptions: {
                                                message: localization.actions.delete.prompt()
                                            },
                                            icon: <DeleteIcon/>
                                        })).
                                value()}
                        variant="bottomLeft">
                    </Menu>
                    {!_.isNil(errorMessage) &&
                        <Message
                            level="error"
                            title={errorMessage}
                            variant="minimal"/>}
                </Fragment>}
        </Stack>);
}