import { Box, Divider, Stack, useTheme } from "@mui/material";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import { useLocalization } from "../hooks";
import { StringHelper } from "../utilities";
import { EmptyMessage } from "./EmptyMessage";
import { SearchTextField } from "./inputs";
import { NavigationViewDivider, NavigationViewItem } from "./NavigationView";
import { Item } from "./NavigationView/components";

export type NavigationViewTreeProps = {
    items: (NavigationViewDivider | NavigationViewItem)[];
    itemsCount: number;
    onNavigate?: () => void;
    paged?: boolean;
    search?: boolean;
    selectedItemId: string;
    variant: "accordion" | "tree";
};

export function NavigationViewTree({ items, itemsCount, onNavigate = _.noop, paged = false, search = false, selectedItemId, variant }: NavigationViewTreeProps) {
    const theme = useTheme();
    const [searchText, setSearchText] = useState<string>("");
    const localization =
        useLocalization(
            "infrastructure.navigationViewTree",
            () => ({
                search: {
                    noResults: "No Results",
                    placeholder: "Search"
                }
            }));

    const filteredItems =
        useMemo(
            () => {
                function filterItems(items: (NavigationViewDivider | NavigationViewItem)[]): (NavigationViewDivider | NavigationViewItem)[] {
                    return _(items).
                        map(
                            item => {
                                if (item instanceof NavigationViewDivider) {
                                    return item;
                                }
                                const filteredItems = filterItems(item.options?.items || []);
                                const searchMatched = StringHelper.search(item.searchText, searchText);
                                if (searchMatched && !_.isEmpty(item.options?.items)) {
                                    return item;
                                } else if (searchMatched || !_.isEmpty(filteredItems)) {
                                    return new NavigationViewItem(
                                        item.id,
                                        item.searchText,
                                        item.title,
                                        {
                                            ...item.options,
                                            items: filteredItems as NavigationViewItem[]
                                        });
                                } else {
                                    return undefined;
                                }
                            }).
                        filter(item => !_.isNil(item)).
                        value() as (NavigationViewDivider | NavigationViewItem)[];
                }

                return filterItems(items);
            },
            [items, searchText]);

    return (
        <Stack
            spacing={1}
            sx={{
                backgroundColor: theme.palette.background.paper,
                height: "100%",
                padding: theme.spacing(1, 0),
                width: "100%"
            }}>
            {search && itemsCount > 10 &&
                <Box sx={{ padding: theme.spacing(0, 1) }}>
                    <SearchTextField
                        placeholder={localization.search.placeholder()}
                        searchText={searchText}
                        onSearchTextChanged={searchText => setSearchText(searchText || "")}/>
                </Box>}
            <Stack sx={{ overflow: "hidden auto" }}>
                {!_.isNil(filteredItems) && filteredItems.length > 0
                    ? _.map(
                        filteredItems,
                        (item, itemIndex) =>
                            item instanceof NavigationViewItem
                                ? <Box
                                    key={item.id}
                                    sx={{ width: "100%" }}>
                                    <Item
                                        item={item}
                                        paged={paged}
                                        searchMode={!_.isEmpty(searchText)}
                                        selectedItemId={selectedItemId}
                                        variant={variant}
                                        onNavigate={() => onNavigate()}/>
                                </Box>
                                : <Divider
                                    flexItem={true}
                                    key={itemIndex}
                                    sx={{ margin: theme.spacing(1, 2) }}/>)
                    : <EmptyMessage message={localization.search.noResults()}/>}
            </Stack>
        </Stack>);
}