import { Loading, Optional, useLocalization } from "@infrastructure";
import { Box } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract, CustomerConsoleAppUrlHelper, Entity, entityModelStore, Network, useTheme } from "../../../../../../../../../../../../common";
import { ToolbarToggleFilterId } from "../../../../../../../../../../../../common/components/Network/components";
import { GcpSqlInstanceAuthorizedSubnetsTable } from "../../../../../../../../../../../../tenants";
import { RiskDefinitionSection } from "../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { EntityExternalConsoleLink } from "../../components";
import { useGetGcpSqlInstanceRiskContext } from "../contexts";

export function useGcpSqlInstanceWideRangeInboundRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.GcpSqlInstanceWideRangeInboundRisk;
    const instanceModel = entityModelStore.useGet(risk.entityId) as Contract.GcpSqlInstanceModel;
    const instance = instanceModel.entity as Contract.GcpSqlInstance;
    const instanceNetwork = instanceModel.entityNetwork as Optional<Contract.GcpSqlInstanceStateNetwork>;

    const getGcpSqlInstanceRiskContext = useGetGcpSqlInstanceRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.useGcpSqlInstanceWideRangeInboundRiskDefinition",
            () => ({
                description: "{{instance}} network configuration allows wide network inbound access",
                sections: {
                    authorizedNetworks: "Authorized Networks",
                    networkGraph: "Network Exposure",
                    resolution: {
                        step1: "For authorized network, edit the address range to restrict it or delete using **Delete** button",
                        step2: "Click **Save** at the bottom to confirm"
                    }
                }
            }));

    const theme = useTheme();
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            instance:
                <Entity
                    entityIdOrModel={instanceModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={risk.entityId}
                key={risk.entityId}
                page={Contract.GcpConsolePage.Connections}/>,
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2()
        ],
        riskModel,
        () => {
            const instanceRiskContext = getGcpSqlInstanceRiskContext(instanceModel);
            return [
                instanceRiskContext.generalInformation,
                instanceRiskContext.sensitive,
                instanceRiskContext.inboundExternalAccessScope,
                instanceRiskContext.status,
                instanceRiskContext.databaseVersion,
                instanceRiskContext.wideRangeInboundAuthorizedSubnets,
                instanceRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        },
        {
            sections:
                !instance.systemDeleted
                    ? [
                        new RiskDefinitionSection(
                            <GcpSqlInstanceAuthorizedSubnetsTable
                                authorizedSubnetDatas={instance.authorizedSubnetDatas}
                                getHighlightColor={
                                    (authorizedSubnetData, opacity) =>
                                        _.some(
                                            instanceNetwork!.wideRangeInboundAuthorizedSubnets,
                                            wideRangeInboundAuthorizedSubnet => wideRangeInboundAuthorizedSubnet === authorizedSubnetData.subnet)
                                            ? opacity
                                                ? theme.palette.opacity(theme.palette.severity(riskModel.risk.severity), opacity)
                                                : theme.palette.severity(riskModel.risk.severity)
                                            : undefined}/>,
                            localization.sections.authorizedNetworks()),
                        new RiskDefinitionSection(
                            <Box sx={{ minHeight: theme.spacing(20) }}>
                                <Loading>
                                    <Network
                                        baseUrl={CustomerConsoleAppUrlHelper.getRiskProfileRelativeUrl(risk.id)}
                                        entityId={risk.entityId}
                                        initialFilterMap={{ [ToolbarToggleFilterId.WideRangeSourceSubnet]: true }}
                                        tenantType={Contract.TenantType.Gcp}
                                        variant="risk"/>
                                </Loading>
                            </Box>,
                            localization.sections.networkGraph(),
                            {
                                profilePageOnly: true
                            })
                    ]
                    : undefined
        });
}