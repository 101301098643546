import { Stack } from "@mui/material";
import _, { Function0 } from "lodash";
import React, { Fragment, useCallback, useMemo, useState } from "react";
import { useLocalization } from "@infrastructure";
import { Contract, defaultSeverities, PrincipalRiskCategoryHelper, StorageHelper, TimeFrameSelector, WidgetDefinition } from "../../../../../../../..";
import { AccessDashboardContext, useDashboardContext } from "../../../../../../Dashboard";
import { PrincipalRiskCategoryChart, PrincipalRiskCategoryMultiSelect, PrincipalRiskCategoryTenantRanking } from "./components";

export function useGetExcessivePermissionPrincipalTrendsDefinition(): () => WidgetDefinition {
    const { summary } = useDashboardContext<AccessDashboardContext>();

    const localization =
        useLocalization(
            "common.dashboard.widget.hooks.useDefinition.hooks.useGetExcessivePermissionPrincipalTrendsDefinition",
            () => ({
                helpText: "View trends for the number of principals with an overprivileged finding. See a detailed progression over time across all accounts, and a snapshot of the change per account.",
                title: "Overprivileged Trend"
            }));

    const [timeFrame, setTimeFrame] =
        useState(
            () => {
                const timeFrame = StorageHelper.customerAccessDashboardExcessivePermissionTrendsWidgetTimeFrame.getValue();
                return _.isNil(timeFrame)
                    ? Contract.TimeFrame.Medium
                    : timeFrame as Contract.TimeFrame;
            });

    const [orderedExcessivePermissionPrincipalRiskCategories] =
        useMemo(
            () => {
                const orderedExcessivePermissionPrincipalRiskCategories =
                    _.filter([
                        Contract.PrincipalRiskCategory.ExcessivePermissionUser,
                        Contract.PrincipalRiskCategory.ExcessivePermissionServiceIdentity,
                        Contract.PrincipalRiskCategory.ExcessivePermissionGroup,
                        Contract.PrincipalRiskCategory.ExcessivePermissionAwsPermissionSet],
                    principalRiskCategory =>
                        _.isNil(summary.principalTypeToSummaryMap)
                            ? false
                            : summary.principalTypeToSummaryMap[PrincipalRiskCategoryHelper.getPrincipalType(principalRiskCategory)].permissionPrincipalCount > 0);

                return [orderedExcessivePermissionPrincipalRiskCategories];
            },
            [summary]);

    const [selectedPrincipalRiskCategories, setSelectedPrincipalRiskCategories] =
        useState(
            () => {
                const principalRiskCategoriesJson = StorageHelper.customerAccessDashboardExcessivePermissionTrendsWidgetExcessivePermissionPrincipalRiskCategoryChartRiskCategories.getValue();
                const principalRiskCategories =
                    _.isNil(principalRiskCategoriesJson)
                        ? []
                        : JSON.parse(principalRiskCategoriesJson) as Contract.PrincipalRiskCategory[];

                return _.intersection(
                    orderedExcessivePermissionPrincipalRiskCategories,
                    principalRiskCategories);
            });

    return useCallback<Function0<WidgetDefinition>>(
        () => ({
            element:
                <ExcessivePermissionPrincipalTrends
                    orderedExcessivePermissionPrincipalRiskCategories={orderedExcessivePermissionPrincipalRiskCategories}
                    selectedPrincipalRiskCategories={selectedPrincipalRiskCategories}
                    timeFrame={timeFrame}/>,
            options: {
                helpText: localization.helpText(),
                title: localization.title(),
                topElement:
                    <ExcessivePermissionPrincipalTrendsTopElement
                        orderedExcessivePermissionPrincipalRiskCategories={orderedExcessivePermissionPrincipalRiskCategories}
                        selectedPrincipalRiskCategories={selectedPrincipalRiskCategories}
                        setSelectedPrincipalRiskCategories={setSelectedPrincipalRiskCategories}
                        setTimeFrame={setTimeFrame}
                        timeFrame={timeFrame}/>
            }
        }),
        [localization, orderedExcessivePermissionPrincipalRiskCategories, selectedPrincipalRiskCategories, timeFrame]);
}

type ExcessivePermissionPrincipalTrendsProps = {
    orderedExcessivePermissionPrincipalRiskCategories: Contract.PrincipalRiskCategory[];
    selectedPrincipalRiskCategories: Contract.PrincipalRiskCategory[];
    timeFrame: Contract.TimeFrame;
};


function ExcessivePermissionPrincipalTrends({ orderedExcessivePermissionPrincipalRiskCategories, selectedPrincipalRiskCategories, timeFrame }: ExcessivePermissionPrincipalTrendsProps) {
    const { filters, summary } = useDashboardContext<AccessDashboardContext>();

    const localization =
        useLocalization(
            "common.dashboard.widget.hooks.useDefinition.hooks.useGetExcessivePermissionPrincipalTrendsDefinition.ExcessivePermissionPrincipalTrends",
            () => ({
                tenantRanking: {
                    title: {
                        excessivePermission: "Changes By Account"
                    }
                }
            }));

    const severities =
        useMemo(
            () =>
                _.isEmpty(filters.severities)
                    ? defaultSeverities
                    : filters.severities,
            [filters.severities]);

    return (
        <Fragment>
            <PrincipalRiskCategoryChart
                dateToPrincipalRiskCategoryToOpenRiskSeverityToPrincipalCountMap={summary.timeFrameToExcessivePermissionPrincipalTrendMap[timeFrame].dateToRiskCategoryToOpenRiskSeverityToCountMap}
                orderedPrincipalRiskCategories={orderedExcessivePermissionPrincipalRiskCategories}
                selectedPrincipalRiskCategories={selectedPrincipalRiskCategories}
                severities={severities}/>
            <PrincipalRiskCategoryTenantRanking
                orderedPrincipalRiskCategories={orderedExcessivePermissionPrincipalRiskCategories}
                principalRiskCategoryToTenantIdToOpenRiskSeverityToPrincipalTimeFrameSummaryMap={summary.timeFrameToExcessivePermissionPrincipalTrendMap[timeFrame].riskCategoryToTenantIdToOpenRiskSeverityToTimeFrameSummaryMap}
                severities={severities}
                timeFrame={timeFrame}
                title={localization.tenantRanking.title.excessivePermission()}/>
        </Fragment>);
}

type ExcessivePermissionPrincipalTrendsTopElementProps = {
    orderedExcessivePermissionPrincipalRiskCategories: Contract.PrincipalRiskCategory[];
    selectedPrincipalRiskCategories: Contract.PrincipalRiskCategory[];
    setSelectedPrincipalRiskCategories: React.Dispatch<React.SetStateAction<Contract.PrincipalRiskCategory[]>>;
    setTimeFrame: React.Dispatch<React.SetStateAction<Contract.TimeFrame>>;
    timeFrame: Contract.TimeFrame;
};

function ExcessivePermissionPrincipalTrendsTopElement({ orderedExcessivePermissionPrincipalRiskCategories, selectedPrincipalRiskCategories, setSelectedPrincipalRiskCategories, setTimeFrame, timeFrame }: ExcessivePermissionPrincipalTrendsTopElementProps) {
    return (
        <Stack
            direction="row"
            spacing={1}>
            <PrincipalRiskCategoryMultiSelect
                principalRiskCategories={orderedExcessivePermissionPrincipalRiskCategories}
                selectedPrincipalRiskCategories={selectedPrincipalRiskCategories}
                onSelectedPrincipalRiskCategoriesChanged={
                    selectedRiskCategories => {
                        setSelectedPrincipalRiskCategories(selectedRiskCategories);
                        StorageHelper.customerAccessDashboardExcessivePermissionTrendsWidgetExcessivePermissionPrincipalRiskCategoryChartRiskCategories.setValue(JSON.stringify(selectedRiskCategories));
                    }}/>
            <TimeFrameSelector
                timeFrame={timeFrame}
                onTimeFrameChanged={
                    timeFrame => {
                        setTimeFrame(timeFrame);
                        StorageHelper.customerAccessDashboardExcessivePermissionTrendsWidgetTimeFrame.setValue(timeFrame);
                    }}/>
        </Stack>);
}