import React from "react";

export function WelcomeImage() {
    return (
        <svg fill="none" height="274" viewBox="0 0 502 274" width="502" xmlns="http://www.w3.org/2000/svg">
            <path d="M74.6589 267.253C99.0088 267.253 428.192 266.546 428.192 266.497C428.323 266.185 469.974 215.135 417.528 161.753C391.535 135.3 441.385 101.043 417.528 71.6325C376.649 21.2404 336.198 49.6157 302.499 35.4527C296.551 32.9553 290.571 28.9299 284.59 22.752C281.222 19.2852 277.541 16.2456 273.582 13.6168C272.974 13.206 272.366 12.8117 271.741 12.4338C269.375 10.9715 266.927 9.6406 264.397 8.44118C262.705 7.63609 260.98 6.89672 259.205 6.22307C256.412 5.13867 253.537 4.21857 250.612 3.42991C246.455 2.31264 242.167 1.47469 237.796 0.916058C235.89 0.669602 233.968 0.472439 232.029 0.324566C194.371 -2.50146 152.013 13.4196 130.144 36.685C125.741 41.3677 105.729 65.6189 121.502 98.9398C131.459 119.987 89.7256 140.081 74.889 164.103C37.1977 225.174 74.3139 266.448 74.6589 267.253Z" fill="#C3D9FF" fillOpacity="0.2"/>
            <path d="M328.273 253.85C341.77 251.026 350.715 241.296 354.168 229.996C356.679 221.679 356.051 212.419 352.127 204.573C351.343 203.003 350.401 201.591 349.303 200.178C346.635 196.726 343.025 193.43 338.631 190.919C325.762 183.543 308.029 184.799 296.572 196.569C297.671 187.937 293.12 178.835 285.43 174.127C277.583 169.576 267.068 169.576 257.966 175.069C255.926 162.514 247.451 151.215 236.152 144.309C225.01 137.404 211.356 134.579 198.017 135.05C189.699 135.364 181.225 137.09 174.162 141.014C162.392 147.605 155.173 160.16 153.133 174.284C144.972 167.85 133.359 165.81 123.315 168.948C113.271 172.087 105.268 180.405 102.914 190.292C102.443 192.018 102.286 194.058 102.914 196.098C98.6764 193.744 93.0267 194.215 89.1033 196.883C87.6909 197.981 86.4354 199.394 85.4938 200.963C84.0813 203.474 83.2967 206.77 83.4536 210.065C76.5484 209.752 69.6433 209.595 63.052 211.321C58.5008 212.576 54.2636 214.93 51.2818 218.226C50.6541 218.697 50.1833 219.482 49.7124 220.109C48.9278 221.051 48.457 222.149 47.9861 223.405C46.2599 228.27 47.3584 233.763 50.0263 238.314C46.5737 238.785 43.435 240.197 41.8657 243.336C38.4131 250.398 45.946 257.774 52.8512 261.54C54.8773 262.61 56.9276 263.618 59 264.57L323.879 265.719C327.018 262.267 329.686 258.402 328.273 253.85Z" fill="white"/>
            <path d="M328.273 253.846C341.77 251.021 350.715 241.291 354.168 229.992C356.679 221.674 356.051 212.415 352.127 204.882C351.814 213.2 349.146 221.36 343.182 227.01C332.51 236.897 316.032 235.171 301.751 232.974C282.448 266.087 198.33 269.069 174.005 235.641C163.805 245.842 148.739 251.021 134.458 249.295C120.177 247.412 106.837 238.78 99.618 226.225C89.731 232.032 77.4901 233.915 66.1907 231.404C61.9534 230.306 57.7161 228.736 54.7344 225.598C52.8511 223.557 51.5957 220.89 51.2818 218.222C50.6541 218.692 50.1833 219.477 49.7124 220.105C48.9278 221.046 48.457 222.145 47.9861 223.401C46.2599 228.266 47.3584 233.758 50.0263 238.309C46.5737 238.78 43.435 240.193 41.8657 243.331C38.4131 250.393 45.946 257.769 52.8512 261.536C55.5268 262.948 58.2447 264.255 61 265.464L323.879 265.715C327.018 262.262 329.686 258.397 328.273 253.846Z" fill="#CDDDF8"/>
            <path d="M138.852 213.671C145.129 219.007 155.801 217.909 160.823 211.317C165.845 217.595 174.476 220.89 182.48 219.792C190.484 218.536 197.703 212.887 200.685 205.354C194.878 210.69 186.874 213.515 179.028 212.573C171.181 211.788 163.805 207.394 159.411 200.803C155.801 208.65 146.699 213.514 138.852 213.671Z" fill="#CDDDF8"/>
            <path d="M323.879 264.719C327.018 261.267 329.686 258.402 328.273 253.85C341.77 251.026 350.715 241.296 354.168 229.996C356.679 221.679 356.051 212.419 352.127 204.573C351.343 203.003 350.401 201.591 349.303 200.178C346.635 196.726 343.025 193.43 338.631 190.919C325.762 183.543 308.028 184.799 296.572 196.569C297.671 187.937 293.12 178.835 285.43 174.127C277.583 169.576 267.068 169.576 257.966 175.069C255.926 162.514 247.451 151.215 236.152 144.309C225.01 137.404 211.356 134.579 198.017 135.05C189.699 135.364 181.225 137.09 174.162 141.014C162.392 147.605 155.173 160.16 153.133 174.284C144.972 167.85 133.359 165.81 123.315 168.948C119.248 170.219 115.516 172.339 112.358 175.069L110.5 177M107.407 180.5C105.303 183.457 103.751 186.775 102.914 190.292C102.443 192.018 102.286 194.058 102.914 196.098C98.6764 193.744 93.0267 194.215 89.1033 196.883C87.6909 197.981 86.4354 199.394 85.4938 200.963C84.0813 203.474 83.2967 206.77 83.4536 210.065C76.5484 209.752 69.6433 209.595 63.052 211.321C58.5008 212.576 54.2636 214.93 51.2818 218.226C50.6541 218.697 50.1833 219.482 49.7124 220.109C48.9278 221.051 48.457 222.149 47.9861 223.405C46.2599 228.27 47.3584 233.763 50.0263 238.314C46.5737 238.785 43.435 240.197 41.8657 243.336C38.4131 250.398 45.946 257.774 52.8512 261.54C55.1847 262.772 57.5504 263.923 59.9451 265" stroke="#37474F" strokeLinecap="round"/>
            <path d="M220.875 262.477C219.213 264.416 217.828 262.77 217.136 265.263L473.605 265.294C473.051 264.047 472.359 265.94 471.666 264.832C470.835 263.447 469.727 262.201 468.481 261.093C463.357 256.661 455.879 255.138 449.509 257.492C452.279 252.507 452.556 248.491 452.002 244.752C450.894 235.196 445.493 226.472 437.738 221.487C430.121 216.501 420.151 215.393 411.565 218.44C413.504 211.793 412.673 206.392 410.18 202.238C407.826 198.083 403.948 194.898 399.655 192.821C390.377 188.389 379.16 188.528 370.02 193.513C361.019 198.499 354.233 208.054 352.433 218.579C345.924 214.978 336.646 219.548 334.153 227.303C326.398 218.025 314.212 216.224 304.241 220.656C294.27 225.087 287.069 235.473 286.238 246.829C280.007 239.766 271.421 238.105 264.497 241.151C257.573 244.198 252.726 251.814 252.726 259.708C246.771 256.938 241.37 255.692 235.831 255.969C230.43 256.246 225.029 258.323 221.152 262.201L220.875 262.477Z" fill="white"/>
            <path d="M218.5 265C218.638 264.862 221.013 262.339 221.151 262.201C225.029 258.323 230.43 256.246 235.831 255.969C241.37 255.692 246.771 256.938 252.725 259.708C252.725 251.814 257.572 244.198 264.496 241.151C271.421 238.105 280.006 239.766 286.238 246.829C287.069 235.473 294.27 225.087 304.241 220.656C314.212 216.224 326.398 218.025 334.153 227.303C336.646 219.548 345.924 214.978 352.433 218.579C354.233 208.054 361.019 198.499 370.02 193.513C377.366 189.506 386.055 188.63 394 190.742M399.655 192.821C403.948 194.898 407.826 198.083 410.18 202.238C412.672 206.392 413.503 211.793 411.565 218.44C420.15 215.393 430.121 216.501 437.738 221.487C445.493 226.472 450.894 235.196 452.001 244.752C452.555 248.491 452.278 252.507 449.509 257.492C455.879 255.138 463.357 256.661 468.481 261.093C469.727 262.201 470.835 263.447 471.666 264.832" stroke="#37474F" strokeLinecap="round"/>
            <path d="M501.254 267H0.626215C0.280716 267 0 266.827 0 266.613V266.387C0 266.173 0.280716 266 0.626215 266H501.232C501.578 266 501.858 266.173 501.858 266.387V266.613C501.88 266.827 501.599 267 501.254 267Z" fill="#37474F"/>
            <path d="M321.381 106.198C321.489 106.185 321.596 106.174 321.7 106.159C321.638 106.198 321.576 106.235 321.513 106.274C321.424 106.22 321.381 106.199 321.381 106.199V106.198Z" fill="#982E20"/>
            <path d="M260.93 85.1829H245.879V138.454H260.93V85.1829Z" fill="#B4C2D1"/>
            <path d="M260.93 85.1832H245.879V138.454H260.93V85.1832Z" fill="#9FB9DE"/>
            <path d="M261 85.0002H256V138H261V85.0002Z" fill="#CDDDF8"/>
            <path clipRule="evenodd" d="M245.379 84.6829H261.43V138.954H245.379V84.6829ZM246.379 85.6829V137.954H260.43V85.6829H246.379Z" fill="#37474F" fillRule="evenodd"/>
            <path d="M256.272 158.555L256.272 158.555L295.351 264.31C295.351 264.31 295.351 264.31 295.351 264.31C296.131 266.42 298.143 267.856 300.384 267.918H300.536C301.188 267.918 301.832 267.804 302.449 267.576L302.45 267.576C303.837 267.065 304.937 266.048 305.556 264.708L305.556 264.708C306.174 263.369 306.233 261.872 305.721 260.488L305.721 260.488L266.642 154.732L266.642 154.732C265.589 151.88 262.404 150.413 259.543 151.466L256.272 158.555ZM256.272 158.555C255.76 157.171 255.819 155.674 256.437 154.333M256.272 158.555L256.437 154.333M256.437 154.333C257.056 152.993 258.156 151.977 259.543 151.466L256.437 154.333Z" fill="#9FB9DE"/>
            <path d="M256.272 158.555L256.272 158.555L295.351 264.31C295.351 264.31 295.351 264.31 295.351 264.31C296.131 266.42 298.143 267.856 300.384 267.918H300.536C301.188 267.918 301.832 267.804 302.449 267.576L302.45 267.576C303.837 267.065 304.937 266.048 305.556 264.708L305.556 264.708C306.174 263.369 306.233 261.872 305.721 260.488L305.721 260.488L266.642 154.732L266.642 154.732C265.589 151.88 262.404 150.413 259.543 151.466L256.272 158.555ZM256.272 158.555C255.76 157.171 255.819 155.674 256.437 154.333M256.272 158.555L256.437 154.333M256.437 154.333C257.056 152.993 258.156 151.977 259.543 151.466L256.437 154.333Z" stroke="#9FB9DE"/>
            <path d="M256.272 158.555L256.272 158.555L295.351 264.31C295.351 264.31 295.351 264.31 295.351 264.31C296.131 266.42 298.143 267.856 300.384 267.918H300.536C301.188 267.918 301.832 267.804 302.449 267.576L302.45 267.576C303.837 267.065 304.937 266.048 305.556 264.708L305.556 264.708C306.174 263.369 306.233 261.872 305.721 260.488L305.721 260.488L266.642 154.732L266.642 154.732C265.589 151.88 262.404 150.413 259.543 151.466L256.272 158.555ZM256.272 158.555C255.76 157.171 255.819 155.674 256.437 154.333M256.272 158.555L256.437 154.333M256.437 154.333C257.056 152.993 258.156 151.977 259.543 151.466L256.437 154.333Z" stroke="#37474F"/>
            <path d="M300.537 267.148C301.084 267.148 301.638 267.053 302.182 266.854C304.645 265.946 305.904 263.216 304.996 260.756L265.917 155C265.009 152.541 262.276 151.284 259.812 152.191C257.349 153.098 256.09 155.828 256.999 158.288L296.078 264.044C296.786 265.962 298.604 267.148 300.537 267.148Z" fill="#CDDDF8"/>
            <path d="M299.715 260.756L260.636 155C260.275 154.022 259.623 153.24 258.817 152.702C259.123 152.498 259.453 152.323 259.812 152.191C262.276 151.284 265.008 152.541 265.917 155L304.996 260.756C305.904 263.216 304.645 265.945 302.182 266.854C301.639 267.053 301.084 267.148 300.537 267.148C299.588 267.148 298.67 266.86 297.895 266.342C299.681 265.151 300.49 262.857 299.714 260.756H299.715Z" fill="#9FB9DE"/>
            <path clipRule="evenodd" d="M267.111 154.559C265.962 151.448 262.49 149.849 259.371 150.997C257.859 151.553 256.657 152.663 255.983 154.124C255.31 155.585 255.245 157.22 255.803 158.728L294.882 264.484C295.635 266.521 297.437 267.979 299.536 268.336C299.812 268.383 300.093 268.41 300.378 268.418C300.43 268.419 300.483 268.42 300.536 268.42V268.418C301.247 268.418 301.95 268.293 302.622 268.045C304.134 267.489 305.336 266.378 306.01 264.918C306.683 263.458 306.748 261.823 306.19 260.315L267.111 154.559ZM300.392 267.418H300.536C301.129 267.418 301.715 267.314 302.276 267.107C303.539 266.642 304.539 265.718 305.102 264.499C305.664 263.28 305.718 261.921 305.252 260.662L266.173 154.906C265.216 152.313 262.319 150.977 259.716 151.935M300.392 267.418C298.356 267.359 296.528 266.053 295.82 264.137L256.741 158.381C256.275 157.122 256.329 155.763 256.891 154.543C257.454 153.323 258.454 152.4 259.716 151.935" fill="#9FB9DE" fillRule="evenodd"/>
            <path clipRule="evenodd" d="M267.111 154.559C265.962 151.448 262.49 149.849 259.371 150.997C257.859 151.553 256.657 152.663 255.983 154.124C255.31 155.585 255.245 157.22 255.803 158.728L294.882 264.484C295.635 266.521 297.437 267.979 299.536 268.336C299.812 268.383 300.093 268.41 300.378 268.418C300.43 268.419 300.483 268.42 300.536 268.42V268.418C301.247 268.418 301.95 268.293 302.622 268.045C304.134 267.489 305.336 266.378 306.01 264.918C306.683 263.458 306.748 261.823 306.19 260.315L267.111 154.559ZM300.392 267.418H300.536C301.129 267.418 301.715 267.314 302.276 267.107C303.539 266.642 304.539 265.718 305.102 264.499C305.664 263.28 305.718 261.921 305.252 260.662L266.173 154.906C265.216 152.313 262.319 150.977 259.716 151.935M300.392 267.418C298.356 267.359 296.528 266.053 295.82 264.137L256.741 158.381C256.275 157.122 256.329 155.763 256.891 154.543C257.454 153.323 258.454 152.4 259.716 151.935" fill="#37474F" fillRule="evenodd"/>
            <path d="M251.928 159.158L251.928 159.158L210.343 263.954C210.343 263.954 210.343 263.954 210.343 263.954C209.512 266.044 207.467 267.431 205.224 267.44L205.072 267.437C204.421 267.421 203.78 267.292 203.168 267.049L203.168 267.049C201.793 266.506 200.717 265.463 200.131 264.108L200.131 264.108C199.545 262.755 199.522 261.257 200.066 259.886L200.066 259.886L241.652 155.09L241.652 155.09C242.773 152.264 245.992 150.873 248.826 151.994L251.928 159.158ZM251.928 159.158C252.473 157.787 252.449 156.289 251.863 154.934M251.928 159.158L251.863 154.934M251.863 154.934C251.277 153.58 250.201 152.537 248.826 151.994L251.863 154.934Z" fill="#9FB9DE"/>
            <path d="M251.928 159.158L251.928 159.158L210.343 263.954C210.343 263.954 210.343 263.954 210.343 263.954C209.512 266.044 207.467 267.431 205.224 267.44L205.072 267.437C204.421 267.421 203.78 267.292 203.168 267.049L203.168 267.049C201.793 266.506 200.717 265.463 200.131 264.108L200.131 264.108C199.545 262.755 199.522 261.257 200.066 259.886L200.066 259.886L241.652 155.09L241.652 155.09C242.773 152.264 245.992 150.873 248.826 151.994L251.928 159.158ZM251.928 159.158C252.473 157.787 252.449 156.289 251.863 154.934M251.928 159.158L251.863 154.934M251.863 154.934C251.277 153.58 250.201 152.537 248.826 151.994L251.863 154.934Z" stroke="#9FB9DE"/>
            <path d="M251.928 159.158L251.928 159.158L210.343 263.954C210.343 263.954 210.343 263.954 210.343 263.954C209.512 266.044 207.467 267.431 205.224 267.44L205.072 267.437C204.421 267.421 203.78 267.292 203.168 267.049L203.168 267.049C201.793 266.506 200.717 265.463 200.131 264.108L200.131 264.108C199.545 262.755 199.522 261.257 200.066 259.886L200.066 259.886L241.652 155.09L241.652 155.09C242.773 152.264 245.992 150.873 248.826 151.994L251.928 159.158ZM251.928 159.158C252.473 157.787 252.449 156.289 251.863 154.934M251.928 159.158L251.863 154.934M251.863 154.934C251.277 153.58 250.201 152.537 248.826 151.994L251.863 154.934Z" stroke="#37474F"/>
            <path d="M205.091 266.667C204.544 266.654 203.992 266.546 203.453 266.333C201.012 265.368 199.818 262.608 200.785 260.171L242.371 155.375C243.337 152.938 246.099 151.746 248.54 152.712C250.981 153.678 252.175 156.437 251.208 158.875L209.622 263.67C208.868 265.571 207.023 266.713 205.091 266.667Z" fill="#CDDDF8"/>
            <path d="M206.064 260.296L247.65 155.501C248.034 154.532 248.704 153.765 249.523 153.247C249.223 153.036 248.896 152.853 248.54 152.712C246.099 151.746 243.338 152.938 242.37 155.375L200.785 260.171C199.818 262.608 201.012 265.367 203.452 266.333C203.99 266.546 204.544 266.654 205.09 266.667C206.039 266.69 206.963 266.423 207.75 265.924C205.993 264.691 205.239 262.378 206.065 260.297L206.064 260.296Z" fill="#9FB9DE"/>
            <path clipRule="evenodd" d="M241.187 154.906C242.41 151.823 245.92 150.307 249.01 151.529C250.508 152.121 251.683 153.259 252.322 154.736C252.961 156.212 252.986 157.848 252.393 159.343L210.807 264.138C210.006 266.156 208.169 267.572 206.062 267.878C205.785 267.918 205.504 267.94 205.219 267.94C205.166 267.94 205.113 267.94 205.06 267.939L205.06 267.937C204.35 267.92 203.651 267.778 202.984 267.514C201.486 266.922 200.311 265.783 199.672 264.307C199.033 262.832 199.008 261.196 199.601 259.701L241.187 154.906ZM205.229 266.94L205.084 266.937C204.491 266.923 203.909 266.805 203.352 266.584C202.101 266.09 201.123 265.143 200.59 263.91C200.057 262.678 200.035 261.318 200.531 260.07L242.116 155.275C243.135 152.706 246.064 151.439 248.642 152.458M205.229 266.94C207.266 266.929 209.124 265.668 209.878 263.769L251.464 158.974C251.959 157.726 251.938 156.366 251.404 155.133C250.871 153.9 249.894 152.953 248.642 152.458" fill="#9FB9DE" fillRule="evenodd"/>
            <path clipRule="evenodd" d="M241.187 154.906C242.41 151.823 245.92 150.307 249.01 151.529C250.508 152.121 251.683 153.259 252.322 154.736C252.961 156.212 252.986 157.848 252.393 159.343L210.807 264.138C210.006 266.156 208.169 267.572 206.062 267.878C205.785 267.918 205.504 267.94 205.219 267.94C205.166 267.94 205.113 267.94 205.06 267.939L205.06 267.937C204.35 267.92 203.651 267.778 202.984 267.514C201.486 266.922 200.311 265.783 199.672 264.307C199.033 262.832 199.008 261.196 199.601 259.701L241.187 154.906ZM205.229 266.94L205.084 266.937C204.491 266.923 203.909 266.805 203.352 266.584C202.101 266.09 201.123 265.143 200.59 263.91C200.057 262.678 200.035 261.318 200.531 260.07L242.116 155.275C243.135 152.706 246.064 151.439 248.642 152.458M205.229 266.94C207.266 266.929 209.124 265.668 209.878 263.769L251.464 158.974C251.959 157.726 251.938 156.366 251.404 155.133C250.871 153.9 249.894 152.953 248.642 152.458" fill="#37474F" fillRule="evenodd"/>
            <path d="M248.278 153.164L248.278 153.164L248.663 265.908C248.663 265.908 248.663 265.908 248.663 265.908C248.671 268.158 250.067 270.196 252.151 271.025L252.294 271.077C252.906 271.301 253.55 271.415 254.208 271.413L254.208 271.413C255.687 271.409 257.069 270.833 258.11 269.786L258.11 269.786C259.151 268.741 259.72 267.355 259.715 265.88L259.715 265.88L259.331 153.135L259.331 153.135C259.321 150.095 256.834 147.623 253.785 147.63L248.278 153.164ZM248.278 153.164C248.273 151.689 248.843 150.303 249.884 149.256M248.278 153.164L249.884 149.256M249.884 149.256C250.924 148.21 252.307 147.633 253.785 147.63L249.884 149.256Z" fill="#9FB9DE" stroke="#9FB9DE"/>
            <path d="M252.559 270.354C253.073 270.542 253.626 270.643 254.206 270.643C256.83 270.636 258.95 268.505 258.942 265.882L258.558 153.138C258.549 150.516 256.415 148.397 253.789 148.403C251.164 148.409 249.044 150.541 249.053 153.163L249.437 265.908C249.444 267.952 250.744 269.691 252.559 270.354Z" fill="#CDDDF8"/>
            <path d="M253.982 264.069L253.598 151.324C253.594 150.282 253.251 149.323 252.679 148.541C253.035 148.455 253.406 148.404 253.789 148.403C256.415 148.397 258.548 150.515 258.557 153.138L258.942 265.882C258.95 268.504 256.83 270.635 254.205 270.643C253.627 270.644 253.072 270.542 252.558 270.354C251.668 270.028 250.905 269.442 250.354 268.69C252.441 268.185 253.989 266.308 253.981 264.068L253.982 264.069Z" fill="#9FB9DE"/>
            <path clipRule="evenodd" d="M259.831 153.134C259.82 149.817 257.108 147.122 253.784 147.13C252.174 147.133 250.664 147.763 249.529 148.904C248.395 150.044 247.773 151.557 247.778 153.165L248.163 265.91C248.17 268.081 249.363 270.071 251.212 271.126C251.454 271.265 251.709 271.387 251.973 271.492C252.022 271.512 252.072 271.53 252.122 271.549L252.122 271.547C252.79 271.791 253.492 271.915 254.21 271.913C255.82 271.909 257.33 271.279 258.465 270.139C259.599 268.999 260.221 267.486 260.215 265.878L259.831 153.134ZM252.33 270.558L252.466 270.608C253.023 270.811 253.608 270.915 254.207 270.913C255.552 270.91 256.809 270.386 257.756 269.434C258.703 268.482 259.22 267.224 259.215 265.882L258.831 153.137C258.822 150.374 256.56 148.124 253.787 148.13M252.33 270.558C250.438 269.803 249.17 267.949 249.163 265.906L248.778 153.162C248.774 151.82 249.291 150.561 250.238 149.609C251.185 148.657 252.441 148.133 253.787 148.13" fill="#9FB9DE" fillRule="evenodd"/>
            <path clipRule="evenodd" d="M259.831 153.134C259.82 149.817 257.108 147.122 253.784 147.13C252.174 147.133 250.664 147.763 249.529 148.904C248.395 150.044 247.773 151.557 247.778 153.165L248.163 265.91C248.17 268.081 249.363 270.071 251.212 271.126C251.454 271.265 251.709 271.387 251.973 271.492C252.022 271.512 252.072 271.53 252.122 271.549L252.122 271.547C252.79 271.791 253.492 271.915 254.21 271.913C255.82 271.909 257.33 271.279 258.465 270.139C259.599 268.999 260.221 267.486 260.215 265.878L259.831 153.134ZM252.33 270.558L252.466 270.608C253.023 270.811 253.608 270.915 254.207 270.913C255.552 270.91 256.809 270.386 257.756 269.434C258.703 268.482 259.22 267.224 259.215 265.882L258.831 153.137C258.822 150.374 256.56 148.124 253.787 148.13M252.33 270.558C250.438 269.803 249.17 267.949 249.163 265.906L248.778 153.162C248.774 151.82 249.291 150.561 250.238 149.609C251.185 148.657 252.441 148.133 253.787 148.13" fill="#37474F" fillRule="evenodd"/>
            <path d="M253.14 163.506C259.63 163.506 264.89 159.433 264.89 154.408C264.89 149.383 259.63 145.31 253.14 145.31C246.651 145.31 241.39 149.383 241.39 154.408C241.39 159.433 246.651 163.506 253.14 163.506Z" fill="#B4C2D1"/>
            <path d="M253.14 163.506C259.63 163.506 264.89 159.433 264.89 154.408C264.89 149.384 259.63 145.31 253.14 145.31C246.651 145.31 241.39 149.384 241.39 154.408C241.39 159.433 246.651 163.506 253.14 163.506Z" fill="#9FB9DE"/>
            <path clipRule="evenodd" d="M253.14 145.81C246.8 145.81 241.89 149.771 241.89 154.408C241.89 159.045 246.8 163.006 253.14 163.006C259.48 163.006 264.39 159.045 264.39 154.408C264.39 149.771 259.48 145.81 253.14 145.81ZM240.89 154.408C240.89 148.996 246.501 144.81 253.14 144.81C259.779 144.81 265.39 148.996 265.39 154.408C265.39 159.821 259.779 164.006 253.14 164.006C246.501 164.006 240.89 159.821 240.89 154.408Z" fill="#37474F" fillRule="evenodd"/>
            <path d="M234.179 146.907C234.009 148.718 233.982 150.495 234.113 152.237C234.15 152.7 234.261 153.143 234.446 153.55C234.974 154.736 236.073 155.622 237.419 155.838C237.567 155.862 237.714 155.885 237.861 155.907C247.435 157.396 256.798 157.469 265.915 155.895C267.69 155.589 269.036 154.102 269.163 152.309C269.253 151.054 269.29 149.846 269.258 148.68C269.258 148.674 269.258 148.664 269.253 148.658C269.226 147.545 269.131 146.475 268.968 145.452C268.894 144.988 268.677 144.561 268.371 144.207C266.834 142.419 265.128 141.037 263.042 140.562L242.125 140.082C239.479 140.736 237.129 141.844 235.201 143.595C234.841 143.925 234.579 144.349 234.453 144.816C234.427 144.911 234.407 145.007 234.393 145.104C234.307 145.709 234.235 146.31 234.179 146.907Z" fill="#CDDDF8"/>
            <path d="M234.995 145.022C235.08 144.442 235.37 143.909 235.803 143.514C237.731 141.763 240.081 140.655 242.727 140.001L263.644 140.481C264.513 140.678 265.314 141.041 266.067 141.519L245.895 141.056C243.249 141.71 240.899 142.818 238.972 144.569C238.539 144.965 238.248 145.497 238.164 146.077C237.815 148.514 237.71 150.892 237.884 153.209C237.921 153.672 238.032 154.116 238.217 154.522C238.464 155.077 238.841 155.563 239.304 155.949C238.877 155.886 238.449 155.825 238.021 155.756C236.675 155.54 235.576 154.654 235.048 153.467C234.863 153.061 234.752 152.618 234.715 152.155C234.541 149.839 234.647 147.461 234.995 145.023V145.022Z" fill="#CDDDF8"/>
            <path d="M235.048 153.466C235.577 154.654 236.675 155.54 238.022 155.755C247.749 157.311 257.26 157.411 266.517 155.813C268.292 155.507 269.639 154.02 269.765 152.226C269.855 150.972 269.892 149.763 269.86 148.597C269.86 148.592 269.86 148.582 269.855 148.576C269.723 147.336 269.485 146.113 268.968 144.99C268.271 143.481 265.127 140.859 263.5 140.501C261.113 139.974 259.408 141.6 257 142C260.208 144.751 262.693 150.43 257.191 153.391C251.238 156.574 241.137 156.323 235.337 154C235.337 154.252 234.973 153.229 235.048 153.466Z" fill="#9FB9DE"/>
            <path clipRule="evenodd" d="M238.548 140.418C240.003 139.776 241.324 139.5 242 139.5H242.012L263.071 140.002L263.129 140.017C266.254 140.85 268.077 142.517 269.062 144.295C270.028 146.037 270.173 147.855 270 149.037C269.996 151.471 269.572 153.128 269.006 154.218C268.465 155.262 267.767 155.827 267.163 155.976C265.392 156.798 263.026 157.281 260.39 157.535C257.721 157.792 254.738 157.817 251.743 157.7C245.754 157.465 239.68 156.662 235.912 155.992L235.877 155.986L235.842 155.974C235.404 155.828 235.061 155.506 234.795 155.123C234.528 154.739 234.312 154.26 234.138 153.734C233.789 152.682 233.578 151.366 233.477 150.051C233.375 148.732 233.381 147.389 233.477 146.274C233.525 145.717 233.596 145.208 233.691 144.783C233.782 144.373 233.906 143.99 234.084 143.723L234.099 143.7L234.116 143.68C235.428 142.106 237.081 141.065 238.548 140.418ZM234.904 144.296C234.834 144.411 234.747 144.639 234.667 145.001C234.585 145.369 234.519 145.832 234.473 146.36C234.382 147.417 234.375 148.705 234.474 149.974C234.572 151.247 234.774 152.474 235.087 153.419C235.244 153.892 235.422 154.274 235.616 154.552C235.799 154.815 235.974 154.954 236.129 155.015C239.864 155.677 245.868 156.469 251.782 156.7C254.75 156.817 257.685 156.791 260.294 156.54C262.912 156.288 265.164 155.812 266.787 155.048L266.842 155.022L266.902 155.01C267.117 154.967 267.638 154.684 268.119 153.757C268.592 152.845 269 151.346 269 149V148.962L269.006 148.924C269.158 147.938 269.045 146.327 268.188 144.78C267.346 143.26 265.767 141.77 262.928 140.998L241.995 140.5C241.5 140.502 240.325 140.727 238.952 141.332C237.593 141.932 236.089 142.884 234.904 144.296Z" fill="#37474F" fillRule="evenodd"/>
            <path d="M248.543 132.097C248.543 132.097 248.543 132.097 248.543 132.097L262 135.683L261.965 140.114C255.129 142.631 248.141 142.626 241 140.114C241 140.114 241 140.114 241 140.114L241 134.532C242.793 132.447 245.353 131.716 248.543 132.097Z" fill="#CDDDF8"/>
            <path d="M262.447 140.61L262.483 135.363L257.202 134.308C257.179 137.781 254.674 140.724 251.255 141.356C248.849 141.8 246.425 141.9 243.983 141.657C250.253 143.525 256.409 143.184 262.447 140.61Z" fill="#8EA6C6"/>
            <path clipRule="evenodd" d="M248.717 131.114L262.483 135.361L262.447 140.609C255.454 143.589 248.305 143.583 241 140.609L241 133.998C242.835 131.529 245.453 130.663 248.717 131.114ZM248.501 132.094L261.478 136.098L261.452 139.938C255.106 142.478 248.64 142.477 242 139.929L242 134.34C243.546 132.432 245.682 131.723 248.501 132.094Z" fill="#37474F" fillRule="evenodd"/>
            <path d="M284.773 90.2783C283.31 93.0634 280.131 94.5083 277.042 93.8968L276.847 93.8598L240.862 86.7654L248.784 59.3384L281.267 73.242L282.36 73.7109C284.805 74.7555 286.458 77.0601 286.706 79.708C287.07 83.6 286.299 87.3558 284.773 90.2772V90.2783Z" fill="#CDDDF8"/>
            <g opacity="0.6">
                <path d="M248.256 61.9769L280.739 75.8805L281.832 76.3495C284.277 77.394 285.93 79.6986 286.178 82.3465C286.323 83.8938 286.281 85.4168 286.091 86.8765C286.699 84.668 286.94 82.2187 286.706 79.7092C286.458 77.0613 284.805 74.7567 282.36 73.7122L281.267 73.2432L248.784 59.3396L240.862 86.7666L241.083 86.8099L248.256 61.9769Z" fill="white"/>
            </g>
            <path d="M280.21 87.04L280.015 87.003L244.031 79.9086L249.841 59.7915L248.784 59.3384L240.862 86.7654L276.847 93.8598L277.042 93.8968C280.131 94.5083 283.31 93.0634 284.773 90.2783C285.54 88.8112 286.115 87.1329 286.453 85.3332C284.802 86.8235 282.483 87.4899 280.21 87.04Z" fill="#8EA6C6"/>
            <path d="M284.773 90.278C283.311 93.0631 280.131 94.508 277.042 93.8965L276.847 93.8595C280.052 87.609 281.436 80.7058 281.267 73.2417L282.36 73.7107C284.805 74.7552 286.458 77.0598 286.706 79.7077C287.071 83.5997 286.3 87.3555 284.773 90.2769V90.278Z" fill="#9FB9DE"/>
            <path d="M284.773 90.2783C283.31 93.0634 280.131 94.5083 277.042 93.8968L276.847 93.8598L240.862 86.7654L248.784 59.3384L281.267 73.242L282.36 73.7109C284.805 74.7555 286.458 77.0601 286.706 79.708C287.07 83.6 286.299 87.3558 284.773 90.2772V90.2783Z" stroke="#37474F"/>
            <path d="M277.042 93.8965L276.847 93.8595C280.052 87.609 281.436 80.7058 281.267 73.2417L282.36 73.7107" stroke="#37474F"/>
            <path d="M103.878 55.6646C106.916 63.4878 113.881 69.1204 122.129 70.6962L148.736 75.787L237.782 92.8222C242.649 93.7517 247.628 91.671 250.305 87.5022C255.754 79.02 257.692 65.5705 255.295 55.8431C254.369 52.0862 251.695 48.999 248.115 47.5214L159.759 10.9679L140.927 3.17749C133.855 0.247634 125.751 0.656381 119.183 4.59384C103.932 13.733 97.2111 38.4678 103.88 55.6646H103.878Z" fill="#CDDDF8"/>
            <path d="M103.878 55.6647C106.916 63.4878 113.881 69.1205 122.129 70.6963L148.736 75.7871C167.839 66.3448 175.383 30.7134 159.758 10.9669L140.926 3.17652C133.854 0.24666 125.75 0.655401 119.182 4.59286C103.931 13.732 97.2101 38.4668 103.879 55.6636L103.878 55.6647Z" fill="#9FB9DE" stroke="#37474F"/>
            <g opacity="0.6">
                <path d="M254.641 59.5156C253.716 55.7588 251.042 52.6715 247.461 51.1939L159.106 14.6404L140.273 6.85002C133.202 3.92016 125.098 4.32891 118.53 8.26636C109.989 13.3846 104.125 23.393 101.789 34.2305C103.496 22.0284 109.684 10.2858 119.182 4.59401C125.751 0.656551 133.855 0.24886 140.926 3.17766L159.759 10.9681L248.114 47.5216C251.694 48.9981 254.37 52.0864 255.294 55.8433C256.703 61.5604 256.604 68.5587 255.185 75.0827C255.933 69.6761 255.789 64.1702 254.641 59.5156Z" fill="white"/>
            </g>
            <path d="M106.791 18.3917C104.649 27.2584 104.871 36.7894 107.946 44.7192C110.985 52.5424 117.949 58.175 126.197 59.7509L152.805 64.8417L241.85 81.8769C246.717 82.8063 251.696 80.7256 254.374 76.5569C254.642 76.1386 254.895 75.6993 255.148 75.2578C254.149 79.7708 252.524 84.0505 250.306 87.5032C247.629 91.6719 242.65 93.7526 237.783 92.8232L148.737 75.788L122.13 70.6972C113.882 69.1213 106.918 63.4887 103.879 55.6655C99.4736 44.3073 100.914 29.6623 106.792 18.3928L106.791 18.3917Z" fill="#8EA6C6"/>
            <path d="M132.583 15.0248C138.91 31.0693 134.558 50.4492 120.426 62.1116C107.166 47.9439 110.819 17.9863 132.583 15.0248Z" fill="#37474F"/>
            <path d="M120.426 63.6937C120.002 63.6937 119.579 63.5247 119.269 63.192C111.521 54.9137 108.948 41.1653 113.012 29.7574C116.264 20.6288 123.319 14.6878 132.37 13.4563C133.095 13.3591 133.79 13.7658 134.057 14.4438C140.797 31.5329 135.607 51.6363 121.436 63.3304C121.141 63.5733 120.783 63.6927 120.427 63.6927L120.426 63.6937Z" fill="#7B99C2"/>
            <path clipRule="evenodd" d="M120.427 63.6927C120.783 63.6927 121.141 63.5733 121.436 63.3304C135.607 51.6363 140.797 31.5329 134.057 14.4438C133.79 13.7658 133.095 13.3591 132.37 13.4563C123.319 14.6878 116.264 20.6288 113.012 29.7574C108.948 41.1653 111.521 54.9137 119.269 63.192C119.579 63.5247 120.002 63.6937 120.426 63.6937L120.427 63.6927ZM120.391 62.6927C120.244 62.684 120.104 62.6218 120 62.5097L119.999 62.5087C112.527 54.526 110.005 41.1783 113.954 30.093L113.954 30.093C117.09 21.2902 123.851 15.6251 132.503 14.4473C132.776 14.4112 133.03 14.5632 133.127 14.8105L133.127 14.8107C139.708 31.4967 134.636 51.1407 120.799 62.5591C120.69 62.6489 120.559 62.6927 120.427 62.6927H120.391Z" fill="#37474F" fillRule="evenodd"/>
            <path clipRule="evenodd" d="M109.428 57.0684C110.309 59.1058 111.74 61.0105 113.775 62.2251L113.776 62.2256C114.785 62.8307 115.935 63.2544 117.148 63.419L117.15 63.4192C118.325 63.5817 119.098 63.4157 120.349 63.0236C120.442 62.9937 120.594 62.9001 120.798 62.7165C120.994 62.5411 121.198 62.3185 121.396 62.0844L122.16 62.7305C121.948 62.9805 121.71 63.2416 121.467 63.4603C121.233 63.6703 120.952 63.881 120.652 63.9766L120.65 63.9773C119.341 64.3875 118.402 64.6019 117.013 64.4098C115.658 64.2258 114.38 63.754 113.262 63.0835C111.007 61.7378 109.454 59.6482 108.51 57.4653L109.428 57.0684Z" fill="#37474F" fillRule="evenodd"/>
            <path clipRule="evenodd" d="M118.926 4.16496C125.638 0.141332 133.909 -0.271306 141.118 2.71554L159.951 10.5058L248.305 47.0592C252.031 48.597 254.816 51.8107 255.78 55.7235C258.208 65.5754 256.254 79.1673 250.726 87.7724C247.936 92.1167 242.751 94.2802 237.688 93.3133L122.035 71.1873C122.035 71.1873 122.035 71.1873 122.035 71.1873C113.624 69.5803 106.515 63.835 103.412 55.8456L103.147 55.1646H103.158C100.035 46.5783 100.126 36.281 102.831 26.9681C105.608 17.4103 111.162 8.81763 118.926 4.16496ZM104.45 55.753C107.479 63.2698 114.233 68.6786 122.222 70.2051L237.876 92.3311C242.546 93.2231 247.32 91.2253 249.884 87.232C255.254 78.8727 257.176 65.5656 254.809 55.9626C253.922 52.3618 251.358 49.401 247.924 47.9836L159.568 11.4299L140.735 3.63949C133.802 0.76662 125.865 1.17137 119.44 5.02266C111.953 9.50915 106.521 17.8535 103.792 27.2471C101.063 36.6381 101.058 47.0062 104.346 55.4838L104.45 55.753Z" fill="#37474F" fillRule="evenodd"/>
            <path clipRule="evenodd" d="M141.117 2.71462C141.117 2.71458 141.117 2.71466 141.117 2.71462L160.07 10.5547L160.151 10.6567C168.076 20.6728 170.101 34.676 167.762 47.332C165.425 59.9776 158.701 71.4192 148.958 76.2354L148.807 76.3098L122.035 71.1874C113.624 69.5804 106.515 63.8351 103.412 55.8457L103.294 55.5428L103.296 55.5404C100.032 46.8796 100.085 36.4167 102.83 26.9672C105.607 17.4093 111.161 8.81666 118.925 4.16399C125.637 0.140454 133.908 -0.272093 141.117 2.71462ZM140.734 3.63846C133.801 0.765592 125.864 1.17044 119.439 5.02173C111.952 9.50823 106.52 17.8526 103.791 27.2461C101.062 36.6371 101.058 47.0052 104.345 55.4829L104.432 55.7062C107.451 63.2474 114.216 68.6756 122.223 70.2052L148.664 75.2643C157.944 70.5943 164.486 59.551 166.778 47.1502C169.073 34.7367 167.079 21.1028 159.447 11.379L140.734 3.63846Z" fill="#37474F" fillRule="evenodd"/>
            <path d="M125.787 20.5557C124.786 20.8958 123.846 21.4048 122.994 22.0269C119.588 24.5121 117.755 28.6555 116.944 32.7905C116.701 34.0252 116.725 35.6411 117.88 36.1428C118.462 36.3953 119.162 36.2389 119.682 35.8767C120.203 35.5144 120.57 34.9736 120.876 34.4181C122.038 32.3089 122.501 29.7941 124.082 27.9775C124.832 27.1167 125.793 26.464 126.584 25.6401C127.375 24.8163 128.012 23.7189 127.861 22.5878C127.71 21.4566 126.467 20.468 125.417 20.9179" fill="#EBF1FC" fillOpacity="0.5"/>
            <path d="M126.913 48.5427C126.452 49.9189 125.608 51.1652 124.5 52.1052C123.997 52.5319 123.426 52.9037 122.783 53.0516C122.139 53.1994 121.413 53.0949 120.915 52.6618C120.618 52.4041 120.42 52.0471 120.293 51.6753C119.804 50.2315 120.367 48.6293 121.205 47.3545C122.154 45.9086 123.621 44.6316 125.351 44.5693C125.872 44.5503 126.438 44.6676 126.778 45.0615C126.939 45.2474 127.035 45.4787 127.106 45.7132C127.451 46.8655 127.184 48.1825 126.415 49.1099" fill="#EBF1FC" fillOpacity="0.5"/>
            <path clipRule="evenodd" d="M118.829 11.8763C118.829 11.8763 118.829 11.8762 118.829 11.8763L118.831 11.8749L118.838 11.8685L118.87 11.8416C118.898 11.8176 118.941 11.7817 118.997 11.7356C119.109 11.6433 119.274 11.5104 119.483 11.3505C119.903 11.0302 120.497 10.6033 121.198 10.1771C122.617 9.31299 124.393 8.49998 126 8.49998C127.444 8.49998 129.06 9.26041 130.405 10.4421C131.749 11.6225 132.743 13.1543 133.009 14.5909C133.059 14.8624 133.32 15.0419 133.591 14.9916C133.863 14.9414 134.042 14.6806 133.992 14.4091C133.674 12.6896 132.523 10.9714 131.065 9.69079C129.609 8.41149 127.767 7.49998 126 7.49998C124.107 7.49998 122.133 8.43696 120.678 9.32288C119.94 9.77165 119.316 10.2198 118.876 10.5557C118.656 10.7239 118.481 10.8645 118.361 10.9636C118.301 11.0132 118.254 11.0525 118.222 11.0797L118.185 11.1114L118.175 11.12L118.172 11.1225L118.171 11.1233C118.171 11.1233 118.171 11.1237 118.5 11.5L118.171 11.1233C117.964 11.3051 117.942 11.6214 118.124 11.8292C118.306 12.037 118.622 12.058 118.829 11.8763Z" fill="#37474F" fillRule="evenodd"/>
        </svg>);
}