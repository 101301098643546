import { InlineItems, useLocalization } from "@infrastructure";
import React from "react";
import { Contract, Permitter } from "../../../../../../..";
import { AzureResourcePermitterType } from "../../../../../../../../tenants";

export type InlinePermitterPoliciesProps = {
    entityTypeName: string;
    permissionPathIdentifiers: Contract.AzureAccessGraphPermissionPathIdentifier[];
};

export function InlinePermitterPolicies({ entityTypeName, permissionPathIdentifiers }: InlinePermitterPoliciesProps) {
    const localization =
        useLocalization(
            "common.access.hooks.useDefinition.hooks.useAzureDefinition.inlinePermitterPolicies",
            () => ({
                policies: [
                    "1 policy",
                    "{{count | NumberFormatter.humanize}} policies"
                ]
            }));
    return (
        <InlineItems
            items={permissionPathIdentifiers}
            namePluralizer={localization.policies}
            variant="itemOrItemCountAndType">
            {(item: Contract.AzureAccessGraphPermissionPathIdentifier, inline) => (
                <Permitter
                    permitterEntityIdOrPermitterType={
                        item.permitterType === Contract.AzurePermitterType.AzureRoleAssignmentResource
                            ? item.roleDefinitionId!
                            : new AzureResourcePermitterType(item.permitterType, entityTypeName)}
                    variant={
                        inline
                            ? "text"
                            : "iconTextTenant"}/>)}
        </InlineItems>);
}