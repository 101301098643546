import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition, useGetAwsCloudTrailTrailRiskContext, useGetAwsS3BucketRiskContext } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { RiskDefinitionSection, RiskDefinitionSectionGroup } from "../../../../../../utilities";
import { PolicySection, PublicS3BucketAccessControlListSection } from "../../components";

export function useAwsCloudTrailTrailBucketPublicAccessEnabledRiskDefinition(riskModel: Contract.RiskModel) {
    const trailBucketPublicAccessEnabledRiskModel = riskModel as Contract.AwsCloudTrailTrailBucketPublicAccessEnabledRiskModel;
    const trailModel = entityModelStore.useGet(trailBucketPublicAccessEnabledRiskModel.risk.entityId) as Contract.AwsCloudTrailTrailModel;
    const bucketModel = entityModelStore.useGet(trailBucketPublicAccessEnabledRiskModel.risk.trailNoncompliantBucketId) as Contract.AwsS3BucketModel;
    const bucket = bucketModel.entity as Contract.AwsS3Bucket;

    const getAwsCloudTrailTrailRiskContext = useGetAwsCloudTrailTrailRiskContext();
    const getAwsS3BucketRiskContext = useGetAwsS3BucketRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsCloudTrailTrailBucketPublicAccessEnabledRiskDefinition",
            () => ({
                description: "{{trail}} has a publicly accessible {{bucket}}",
                sections: {
                    accessControlList: "ACL",
                    policyDocument: "Policy",
                    publicAccessPolicy: "Block Public Access",
                    resolution: {
                        step1: "Since the bucket has public access, block public access might break identities access to the bucket. Verify that only identities from the same account access the bucket before applying the change",
                        step2: "Check **Block all public access** and click **Save** to confirm"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            bucket:
                <Entity
                    entityIdOrModel={bucketModel}
                    variant="typeText"/>,
            trail:
                <Entity
                    entityIdOrModel={trailModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.S3,
                AwsConsoleUrlBuilder.getS3BucketEditBlockPublicAccessUrl(bucket)),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2()
        ],
        trailBucketPublicAccessEnabledRiskModel,
        () => {
            const bucketRiskContext = getAwsS3BucketRiskContext(bucketModel);
            const trailRiskContext = getAwsCloudTrailTrailRiskContext(trailModel);
            return [
                trailRiskContext.generalInformation,
                trailRiskContext.multiRegion,
                trailRiskContext.enabled,
                trailRiskContext.encryption,
                trailRiskContext.sensitive,
                trailRiskContext.bucketName,
                bucketRiskContext.storageSize,
                bucketRiskContext.sensitive,
                bucketRiskContext.policyDocumentPublicAccessPermissionActions,
                bucketRiskContext.policyDocumentPublicAccessHighSeveritySensitivePermissionActions,
                bucketRiskContext.accessControlListPublicAccessPermissionActions,
                bucketRiskContext.accessControlListPublicAccessHighSeveritySensitivePermissionActions,
                trailRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)];
        },
        {
            sections: [
                new RiskDefinitionSectionGroup(
                    [
                        new RiskDefinitionSection(
                            <PublicS3BucketAccessControlListSection accessControlList={bucket.accessControlList}/>,
                            localization.sections.accessControlList()),
                        new RiskDefinitionSection(
                            <PolicySection policyDocument={bucket.policyDocument}/>,
                            localization.sections.policyDocument(),
                            {
                                expandable: true
                            })
                    ])
            ]
        });
}