import { Optional } from "@infrastructure";
import { useCallback } from "react";
import { Contract, WorkloadController } from "../../../../../common";

export function useGetWorkloadResourceScanFilterItemPage(
    property: Contract.WorkloadControllerRequestProperty,
    workloadResourceType: Contract.WorkloadAnalysisWorkloadResourceType,
    vulnerabilityRawId?: string) {
    return useCallback(
        async (searchText: Optional<string>, skip: number, limit: number) => {
            const { workloadResourceScanFilterItemPage } =
                await WorkloadController.getWorkloadResourceScanFilterItemPage(
                    new Contract.WorkloadControllerGetWorkloadResourceScanFilterItemPageRequest(
                        limit,
                        property,
                        searchText,
                        skip,
                        vulnerabilityRawId,
                        workloadResourceType)) as Contract.WorkloadControllerGetWorkloadResourceFilterScanItemPageResponse<string>;
            return workloadResourceScanFilterItemPage;
        },
        [property, workloadResourceType]);
}

export function useGetWorkloadResourceScanPackageFilterItemPage(
    property: Contract.WorkloadControllerRequestProperty,
    entityIds?: Optional<string[]>,
    workloadResourceType?: Optional<Contract.WorkloadAnalysisWorkloadResourceType>,
    resolvedVulnerabilities = false,
    vulnerableOnly = false,
    vulnerabilityRawId?: string) {
    return useCallback(
        async (searchText: Optional<string>, skip: number, limit: number) => {
            const { workloadResourceScanFilterItemPage } =
                await WorkloadController.getWorkloadResourceScanPackageFilterItemPage(
                    new Contract.WorkloadControllerGetWorkloadResourceScanPackageFilterItemPageRequest(
                        limit,
                        property,
                        resolvedVulnerabilities,
                        searchText,
                        skip,
                        vulnerabilityRawId,
                        vulnerableOnly,
                        entityIds,
                        workloadResourceType)) as Contract.WorkloadControllerGetWorkloadResourceFilterScanItemPageResponse<string>;
            return workloadResourceScanFilterItemPage;
        },
        [entityIds, property, resolvedVulnerabilities, vulnerableOnly, workloadResourceType]);
}

export function useGetWorkloadResourceScanFileFilterItemPage(
    property: Contract.WorkloadControllerRequestProperty,
    entityIds?: Optional<string[]>) {
    return useCallback(
        async (searchText: Optional<string>, skip: number, limit: number) => {
            const { workloadResourceScanFilterItemPage } =
                await WorkloadController.getWorkloadResourceScanFileFilterItemPage(
                    new Contract.WorkloadControllerGetWorkloadResourceScanFileFilterItemPageRequest(
                        limit,
                        property,
                        searchText,
                        skip,
                        entityIds)) as Contract.WorkloadControllerGetWorkloadResourceFilterScanItemPageResponse<string>;
            return workloadResourceScanFilterItemPage;
        },
        [entityIds, property]);
}