import { Box, Stack, Typography } from "@mui/material";
import _, { Function0 } from "lodash";
import React, { useCallback } from "react";
import { EmptyMessage, Link, Loading, useLocalization } from "@infrastructure";
import { Contract, CustomerConsoleAppUrlHelper, InlineEntities, RiskPolicyCategoryIcon, RiskPolicyTypeMetadataHelper, Tenant, useRiskPolicyTitleTranslator, useTheme, WidgetDefinition } from "../../../../../../..";
import { SummaryDashboardContext, useDashboardContext } from "../../../../../Dashboard";
import { SeverityItem } from "../components";

export function useGetTopRisksDefinition(): () => WidgetDefinition {
    const localization =
        useLocalization(
            "common.dashboard.widget.hooks.useDefinition.hooks.useGetTopRisksDefinition",
            () => ({
                helpText: "If you're short on time, or just want to focus on the most serious issues, use this list to assess and remediate the highest priority findings in your environment. Click on a finding or its associated resource to get started.",
                title: "If you only have 5 minutes..."
            }));

    return useCallback<Function0<WidgetDefinition>>(
        () => ({
            element: <TopRisks/>,
            options: {
                helpText: localization.helpText(),
                title: localization.title(),
                variant: "scrollable"
            }
        }),
        [localization]);
}

function TopRisks() {
    const { summary } = useDashboardContext<SummaryDashboardContext>();

    const localization =
        useLocalization(
            "common.dashboard.widget.hooks.useDefinition.hooks.useGetTopRisksDefinition.topRisks",
            () => ({
                empty: "No Findings"
            }));

    return _.isEmpty(summary.topRiskDatas)
        ? <EmptyMessage
            message={localization.empty()}
            verticalCenter={true}/>
        : <Stack spacing={1}>
            {_.map(
                summary.topRiskDatas,
                riskData =>
                    <Box
                        className="riskButton"
                        key={riskData.id}
                        sx={{ flex: 1 }}>
                        <Risk riskData={riskData}/>
                    </Box>)}
        </Stack>;
}

type RiskProps = {
    riskData: Contract.DashboardSummaryRiskData;
};

function Risk({ riskData }: RiskProps) {
    const riskPolicyTitleTranslator = useRiskPolicyTitleTranslator();
    const riskPolicyCategory = RiskPolicyTypeMetadataHelper.get(riskData.policyConfigurationTypeName).category;

    const theme = useTheme();
    return (
        <Link
            urlOrGetUrl={CustomerConsoleAppUrlHelper.getRiskProfileRelativeUrl(riskData.id)}
            variant="text">
            <SeverityItem
                icon={
                    <RiskPolicyCategoryIcon
                        riskPolicyCategory={riskPolicyCategory}
                        sx={{
                            color: theme.palette.text.primary,
                            fontSize: "22px"
                        }}/>}
                severity={riskData.severity}
                subTitles={
                    _.isEmpty(riskData.riskedEntityIds)
                        ? [
                            <Box
                                key="Tenant"
                                sx={{ overflow: "hidden" }}>
                                <Typography
                                    noWrap={true}
                                    sx={{ lineHeight: 1.4 }}>
                                    <Tenant
                                        iconSx={{ fontSize: "18px" }}
                                        sx={{ lineHeight: 1.4 }}
                                        tenantId={riskData.tenantId}
                                        tenantNameTranslatorOptions={{ includeRawId: true }}/>
                                </Typography>
                            </Box>
                        ]
                        : [
                            <Box
                                key="riskedEntityIds"
                                sx={{ overflow: "hidden" }}>
                                <Typography
                                    noWrap={true}
                                    sx={{ lineHeight: 1.2 }}>
                                    <Loading container="cell">
                                        <InlineEntities
                                            entityIdsOrModels={riskData.riskedEntityIds}
                                            entityTypeName={riskData.riskedEntityTypeName}
                                            variant="itemOrItemCountAndType"/>
                                    </Loading>
                                </Typography>
                            </Box>,
                            <Box
                                key="Tenant"
                                sx={{ overflow: "hidden" }}>
                                <Typography
                                    noWrap={true}
                                    sx={{ lineHeight: 1.4 }}>
                                    <Tenant
                                        iconSx={{ fontSize: "18px" }}
                                        sx={{ lineHeight: 1.4 }}
                                        tenantId={riskData.tenantId}
                                        tenantNameTranslatorOptions={{ includeRawId: true }}/>
                                </Typography>
                            </Box>
                        ]}
                title={
                    riskPolicyTitleTranslator(
                        riskPolicyCategory === Contract.RiskPolicyCategory.Custom
                            ? riskData.policyId
                            : riskData.policyConfigurationTypeName)}/>
        </Link>);
}