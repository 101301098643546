import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../../../tenants";

export function useAwsRdsSnapshotEncryptionDisabledRiskResolutionSteps(riskedEntityModel: Contract.AwsResourceModel) {
    const riskedEntity = riskedEntityModel.entity as Contract.AwsResource;

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsRdsSnapshotEncryptionDisabledRiskDefinition.hooks.useAwsRdsSnapshotEncryptionDisabledRiskResolutionSteps",
            () => ({
                step1: "RDS resources can only be encrypted at the time of creation. It is not possible to enable encryption for a snapshot after it has been created. To address this, you must restore an unencrypted snapshot to a new cluster or instance with encryption enabled",
                step2: "In the **Snapshots** page, select the unencrypted snapshot and click **Actions**, and then **Copy snapshot**",
                step3: "In the **Encryption** section, select **Enable Encryption** and select the encryption key",
                step4: "Click **Copy snapshot**",
                step5: "In the **Snapshots** page, select the copy of the snapshot and click **Actions**, and then **Restore snapshot**",
                step6: {
                    [Contract.TypeNames.AwsRdsCluster]: "Click **Restore DB cluster**",
                    [Contract.TypeNames.AwsRdsClusterSnapshot]: "Click **Restore DB cluster**",
                    [Contract.TypeNames.AwsRdsDatabaseInstance]: "Click **Restore DB instance**",
                    [Contract.TypeNames.AwsRdsDatabaseInstanceSnapshot]: "Click **Restore DB instance**"
                },
                step7: "In the **Databases** page, select the newly restored resource and click **Actions**, and then **Take snapshot**",
                step8: "Before deleting any cluster, instance or snapshot, ensure that your new resource is properly configured to avoid downtime",
                step9: "Delete the old unencrypted RDS resource"
            }));

    return [
        consoleSignInStepTranslator(
            Contract.AwsConsoleView.Rds,
            AwsConsoleUrlBuilder.getRdsSnapshotsUrl(riskedEntity)),
        localization.step1(),
        localization.step2(),
        localization.step3(),
        localization.step4(),
        localization.step5(),
        localization.step6.translate(riskedEntity.typeName),
        localization.step7(),
        localization.step8(),
        localization.step9()
    ];
}