﻿import { Steps, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, useRiskSeverityReasonTranslator, useVendorNameTranslator } from "../../../../../../../../../../../../../../common";
import { useOpenRiskedEntityRisksStep } from "../../../../../useOpenRiskedEntityRisksStep";
import { useResourceGeneralInformationStep } from "../../../../../useResourceGeneralInformationStep";

export type ContextSectionProps = {
    riskModel: Contract.AwsIamRoleVendorAssumeRolePolicyDocumentExternalIdConditionNotExistRiskModel;
    roleModel: Contract.AwsResourceModel;
};

export function ContextSection({ riskModel, roleModel }: ContextSectionProps) {
    const risk = (riskModel.risk as Contract.AwsIamRoleVendorAssumeRolePolicyDocumentExternalIdConditionNotExistRisk);
    const openRiskedEntityRisksStep = useOpenRiskedEntityRisksStep(roleModel, risk.id);
    const resourceGeneralInformationStep = useResourceGeneralInformationStep(roleModel);
    const severityReasonTranslator = useRiskSeverityReasonTranslator();
    const vendorNameTranslator = useVendorNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsIamRoleVendorAssumeRolePolicyDocumentExternalIdConditionNotExistRiskDefinition.contextSection",
            () => ({
                permissionsSeverity: "The role has {{severity}} severity permissions in the environment",
                vendor: {
                    withName: "The role trusts a 3rd party account {{vendorTenantRawId}} owned by **{{vendorName}}**",
                    withoutName: "The role trusts a 3rd party account {{vendorTenantRawId}}"
                }
            }));
    return (
        <Steps variant="bullets">
            {_.filter(
                _.concat(
                    [resourceGeneralInformationStep],
                    _.map(
                        riskModel.tenantRawIdToVendorMap,
                        (vendor, vendorTenantRawId) =>
                            _.isNil(vendor)
                                ? localization.vendor.withoutName({ vendorTenantRawId })
                                : localization.vendor.withName({
                                    vendorName: vendorNameTranslator(vendor),
                                    vendorTenantRawId
                                })),
                    [
                        openRiskedEntityRisksStep,
                        localization.permissionsSeverity({ severity: risk.severity }),
                        severityReasonTranslator(
                            risk.severity,
                            risk.severityReason!)
                    ]
                ))}
        </Steps>);
}