import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useAwsElastiCacheCacheDeploymentTypeTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useAwsElastiCacheCacheDeploymentTypeTranslator",
            () => ({
                [Contract.TypeNames.AwsElastiCacheCacheDeploymentType]: {
                    [Contract.AwsElastiCacheCacheDeploymentType.Cluster]: "Cluster",
                    [Contract.AwsElastiCacheCacheDeploymentType.Serverless]: "Serverless"
                }
            }));
    return (deploymentType: Contract.AwsElastiCacheCacheDeploymentType) =>
        localization[Contract.TypeNames.AwsElastiCacheCacheDeploymentType][deploymentType]();
}