import { InlineItems, Optional, useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, Entity, entityModelStore, InlineEntities, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { GciPrincipalReferenceCell } from "../../../../../../../../../../../../tenants";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetGcpEntityRiskContext } from "./useGetGcpEntityRiskContext";

export function useGetGcpTenantEntityRiskContext() {
    return useMemo(
        () => useGcpTenantEntityRiskContext,
        []);
}

function useGcpTenantEntityRiskContext(tenantEntityModel: Contract.GcpTenantEntityModel) {
    const entityRiskContext = useGetGcpEntityRiskContext()(tenantEntityModel);
    const tenantEntity = tenantEntityModel.entity as Contract.GcpTenantEntity;
    const computeProject = entityModelStore.useGet(tenantEntityModel.computeProjectId)?.entity as Optional<Contract.GcpComputeProject>;

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.contexts.useGetGcpTenantEntityRiskContext",
            () => ({
                apiKeysKeys: "The {{translatedTenantEntityTypeName}} has {{apiKeysKeys}}",
                auditLogTypes: {
                    auditLogTypes: [
                        "1 log types",
                        "{{count | NumberFormatter.humanize}} log types"
                    ],
                    [Contract.TypeNames.GcpAuditLogType]: {
                        [Contract.GcpAuditLogType.AdminRead]: "Admin Read",
                        [Contract.GcpAuditLogType.AdminWrite]: "Admin Write",
                        [Contract.GcpAuditLogType.DataRead]: "Data Read",
                        [Contract.GcpAuditLogType.DataWrite]: "Data Write"
                    },
                    text: "The {{translatedTenantEntityTypeName}}’s default audit configuration has {{enabledAuditLogTypes}} enabled out of the {{requiredAuditLogTypes}} log types required."
                },
                excludedPrincipalReferences: {
                    excludedPrincipalReferences: [
                        "1 principal",
                        "{{count | NumberFormatter.humanize}} principals"
                    ],
                    text: "Audit logs are not generated for {{excludedPrincipalReferences}}, part of the exempted users configuration."
                },
                owners: {
                    activeAll: {
                        namePluralizer: [
                            "1 active owner",
                            "{{count | NumberFormatter.humanize}} active owners"
                        ],
                        single: "The owner of the project is {{activeOwnerReference}} (active)",
                        some: "The {{translatedTenantEntityTypeName}} has {{activeOwnerReferences}}"
                    },
                    activeSomeInactiveSome: {
                        namePluralizer: [
                            "1 owner",
                            "{{count | NumberFormatter.humanize}} owners"
                        ],
                        text: "The {{translatedTenantEntityTypeName}} has {{ownerReferences}}, {{activeOwnerReferences}} are active"
                    },
                    inactiveAll: {
                        namePluralizer: [
                            "1 inactive owner",
                            "{{count | NumberFormatter.humanize}} inactive owners"
                        ],
                        single: "The owner of the project is {{inactiveOwnerReference}} (inactive)",
                        some: "The {{translatedTenantEntityTypeName}} has {{inactiveOwnerReferences}}"
                    },
                    none: "The {{translatedTenantEntityTypeName}} has no direct owners"
                },
                path: "The {{translatedTenantEntityTypeName}} path is **{{path}}**",
                sshKeys: {
                    pluralizer: [
                        "1 SSH key",
                        "{{count | NumberFormatter.humanize}} SSH keys"
                    ],
                    text: "The {{translatedTenantEntityTypeName}} has {{sshKeys}}"
                }
            }));
    const ownerReferences =
        _.concat(
            tenantEntityModel.activeOwnerReferences,
            tenantEntityModel.inactiveOwnerReferences);

    const translatedTenantEntityTypeName =
        entityTypeNameTranslator(
            tenantEntity.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });

    return {
        ...entityRiskContext,
        apiKeys:
            _.isEmpty(tenantEntityModel.apiKeysKeyIds)
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.apiKeysKeys({
                        apiKeysKeys:
                            <InlineEntities
                                entityIdsOrModels={tenantEntityModel.apiKeysKeyIds}
                                entityTypeName={Contract.TypeNames.GcpApiKeysKey}
                                variant="itemCountAndType"/>,
                        translatedTenantEntityTypeName
                    })),
        auditLogTypes:
            new RiskDefinitionContextItem(
                localization.auditLogTypes.text({
                    enabledAuditLogTypes:
                        <InlineItems
                            items={
                                _(tenantEntityModel.auditLogTypeToEnabledMap).
                                    pickBy((enabled, _) => enabled).
                                    map((_, auditLogType) => localization.auditLogTypes[Contract.TypeNames.GcpAuditLogType][auditLogType as Contract.GcpAuditLogType]()).
                                    value()}
                            namePluralizer={localization.auditLogTypes.auditLogTypes}
                            variant="itemCountAndType"/>,
                    requiredAuditLogTypes:
                        <InlineItems
                            items={[
                                localization.auditLogTypes[Contract.TypeNames.GcpAuditLogType][Contract.GcpAuditLogType.AdminRead](),
                                localization.auditLogTypes[Contract.TypeNames.GcpAuditLogType][Contract.GcpAuditLogType.AdminWrite](),
                                localization.auditLogTypes[Contract.TypeNames.GcpAuditLogType][Contract.GcpAuditLogType.DataRead](),
                                localization.auditLogTypes[Contract.TypeNames.GcpAuditLogType][Contract.GcpAuditLogType.DataWrite]()
                            ]}
                            namePluralizer={localization.auditLogTypes.auditLogTypes}
                            variant="itemCountAndType"/>,
                    translatedTenantEntityTypeName
                })),
        excludedPrincipalReferences:
            !_.isEmpty(tenantEntityModel.excludedPrincipalReferences)
                ? new RiskDefinitionContextItem(
                    localization.excludedPrincipalReferences.text({
                        excludedPrincipalReferences:
                            <InlineItems
                                items={tenantEntityModel.excludedPrincipalReferences}
                                namePluralizer={localization.excludedPrincipalReferences.excludedPrincipalReferences}
                                variant="itemCountAndType">
                                {(principalReference: Contract.GciPrincipalReference) =>
                                    <GciPrincipalReferenceCell principalReference={principalReference}/>}
                            </InlineItems>
                    }))
                : undefined,
        owners:
            new RiskDefinitionContextItem(
                _.isEmpty(ownerReferences)
                    ? localization.owners.none({ translatedTenantEntityTypeName })
                    : _.isEmpty(tenantEntityModel.inactiveOwnerReferences)
                        ? _.size(tenantEntityModel.activeOwnerReferences) === 1
                            ? localization.owners.activeAll.single({
                                activeOwnerReference:
                                    <Entity
                                        entityIdOrModel={tenantEntityModel.activeOwnerReferences[0]}
                                        variant="text"/>
                            })
                            : localization.owners.activeAll.some({
                                activeOwnerReferences:
                                    <InlineEntities
                                        entityIdsOrModels={tenantEntityModel.activeOwnerReferences}
                                        entityTypeName={Contract.TypeNames.IGciPrincipal}
                                        entityVariant="iconTextTypeTenant"
                                        namePluralizer={localization.owners.activeAll.namePluralizer}
                                        variant="itemCountAndType"/>,
                                translatedTenantEntityTypeName
                            })
                        : _.isEmpty(tenantEntityModel.activeOwnerReferences)
                            ? _.size(tenantEntityModel.inactiveOwnerReferences) === 1
                                ? localization.owners.inactiveAll.single({
                                    inactiveOwnerReference:
                                        <Entity
                                            entityIdOrModel={tenantEntityModel.inactiveOwnerReferences[0]}
                                            variant="text"/>
                                })
                                : localization.owners.inactiveAll.some({
                                    inactiveOwnerReferences:
                                        <InlineEntities
                                            entityIdsOrModels={tenantEntityModel.inactiveOwnerReferences}
                                            entityTypeName={Contract.TypeNames.IGciPrincipal}
                                            entityVariant="iconTextTypeTenant"
                                            namePluralizer={localization.owners.inactiveAll.namePluralizer}
                                            variant="itemCountAndType"/>,
                                    translatedTenantEntityTypeName
                                })
                            : localization.owners.activeSomeInactiveSome.text({
                                activeOwnerReferences:
                                    <InlineEntities
                                        entityIdsOrModels={tenantEntityModel.activeOwnerReferences}
                                        entityTypeName={Contract.TypeNames.IGciPrincipal}
                                        entityVariant="iconTextTypeTenant"
                                        namePluralizer={localization.owners.activeSomeInactiveSome.namePluralizer}
                                        variant="itemCountAndType"/>,
                                ownerReferences:
                                    <InlineEntities
                                        entityIdsOrModels={ownerReferences}
                                        entityTypeName={Contract.TypeNames.IGciPrincipal}
                                        entityVariant="iconTextTypeTenant"
                                        namePluralizer={localization.owners.activeSomeInactiveSome.namePluralizer}
                                        variant="itemCountAndType"/>,
                                translatedTenantEntityTypeName
                            })),
        path:
            new RiskDefinitionContextItem(
                localization.path({
                    path: `${tenantEntityModel.parentEntityPath}/${tenantEntity.displayName}`,
                    translatedTenantEntityTypeName
                })),
        sshKeys:
            _.isEmpty(computeProject?.sshKeys)
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.sshKeys.text({
                        sshKeys:
                            <InlineItems
                                items={computeProject!.sshKeys}
                                namePluralizer={localization.sshKeys.pluralizer}
                                variant="itemCountAndType">
                                {sshKey =>
                                    <Typography
                                        component="span"
                                        key={sshKey}
                                        noWrap={true}>
                                        {sshKey}
                                    </Typography>}
                            </InlineItems>,
                        translatedTenantEntityTypeName
                    }))
    };
}