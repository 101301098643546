import { Box, CircularProgress, Skeleton, Stack, Tooltip, Typography, useTheme } from "@mui/material";
import React, { Fragment, ReactNode, Suspense } from "react";
import { map } from "../../utilities";
import { CellErrorIcon } from "./icons";
import { ErrorImage } from "./images";
import { ElementClass } from "./Loading.element";

type LoadingProps = {
    children?: ReactNode;
    container?: "cell" | "cell-medium" | "cell-wide" | "default" | "popup" | "small";
    error?: boolean;
    loading?: boolean;
    root?: boolean;
};

export function Loading({ children, container = "default", error, loading, root = false }: LoadingProps) {
    const theme = useTheme();
    const loadingElement =
        map(
            container,
            {
                "cell":
                    () =>
                        <Skeleton
                            animation="wave"
                            className={ElementClass.loading}
                            sx={{ width: theme.spacing(14) }}/>,
                "cell-medium":
                    () =>
                        <Skeleton
                            animation="wave"
                            className={ElementClass.loading}
                            sx={{ width: theme.spacing(20) }}/>,
                "cell-wide":
                    () =>
                        <Skeleton
                            animation="wave"
                            className={ElementClass.loading}
                            sx={{ minWidth: theme.spacing(28) }}/>,
                "default":
                    () =>
                        <Stack
                            className={ElementClass.loading}
                            sx={{
                                alignItems: "center",
                                flex: 1,
                                justifyContent: "center"
                            }}>
                            <CircularProgress/>
                        </Stack>,
                "popup":
                    () =>
                        <Box
                            className={ElementClass.loading}
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                maxWidth: theme.spacing(60),
                                minWidth: theme.spacing(25),
                                padding: theme.spacing(3),
                                width: "100%"
                            }}>
                            <CircularProgress size="18px"/>
                        </Box>,
                "small":
                    () =>
                        <Stack
                            className={ElementClass.loading}
                            sx={{
                                alignItems: "center",
                                flex: 1,
                                justifyContent: "center"
                            }}>
                            <CircularProgress size="18px"/>
                        </Stack>
            });
    return (
        <LoadingErrorBoundary
            cellContainer={
                container === "cell" ||
                container === "cell-medium" ||
                container === "cell-wide"}
            root={root}>
            {!error &&
                <Suspense fallback={loadingElement}>
                    {loading
                        ? loadingElement
                        : children}
                </Suspense>}
        </LoadingErrorBoundary>);
}

type LoadingErrorBoundaryProps = {
    cellContainer: boolean;
    children: ReactNode;
    root: boolean;
};

type LoadingErrorBoundaryState = {
    hasError: boolean;
    requestId: string | undefined;
};

class LoadingErrorBoundary extends React.Component<LoadingErrorBoundaryProps, LoadingErrorBoundaryState> {
    constructor(props: LoadingErrorBoundaryProps) {
        super(props);
        this.state = {
            hasError: false,
            requestId: undefined
        };
    }

    static getDerivedStateFromError(error: Error) {
        if (/statusCode=40(1|3|6)/.test(error.message)) {
            return;
        }

        return {
            hasError: true,
            requestId: error.message.match(/requestId=(?<requestId>.*) /)?.groups?.requestId
        };
    }

    render() {
        if (this.state.hasError) {
            return (
                <Stack
                    className={ElementClass.errorBoundary}
                    spacing={2}
                    sx={
                        theme => ({
                            alignItems: "center",
                            background: theme.palette.background.paper,
                            height:
                                this.props.root
                                    ? "100vh"
                                    : "100%",
                            justifyContent: "center",
                            padding:
                                this.props.root
                                    ? undefined
                                    : 20,
                            width: "100%",
                            ...(this.props.cellContainer && {
                                alignItems: undefined,
                                background: undefined,
                                padding: 0
                            })
                        })}>
                    {this.props.cellContainer
                        ? <Stack
                            alignItems="center"
                            direction="row"
                            spacing={1}>
                            <Tooltip
                                title={
                                    this.state.requestId
                                        ? `Request ID: ${this.state.requestId}`
                                        : undefined}>
                                <CellErrorIcon sx={{ fontSize: "22px" }}/>
                            </Tooltip>
                            <Typography noWrap={true}>
                                Something went wrong
                            </Typography>
                        </Stack>
                        : <Fragment>
                            <ErrorImage/>
                            <Typography
                                noWrap={true}
                                sx={{ fontWeight: 700 }}
                                variant="h1">
                                Something went wrong
                            </Typography>
                            {this.state.requestId &&
                                <Typography sx={{ color: "#9E9E9E" }}>
                                    Request ID: {this.state.requestId}
                                </Typography>}
                        </Fragment>}
                </Stack>);
        }
        return this.props.children;
    }
}