import { useLocalization, useLocalizeList } from "@infrastructure";
import _ from "lodash";
import { AuditEventDefinition, AuditEventDefinitionContext, AuditEventDefinitionDetailItem } from "..";
import { Contract, TypeHelper } from "../../../../../../../../../common";
import { useCiTenantTypeTranslator } from "../../../../../../../../../tenants";

export function useCiTenantAuditEventDefinition(context: AuditEventDefinitionContext) {
    const tenantTypeTranslator = useCiTenantTypeTranslator();

    const localizeList = useLocalizeList();
    const localization =
        useLocalization(
            "views.customer.auditEvents.profile.hooks.useDefinition.hooks.useCiTenantAuditEventDefinition",
            () => ({
                propertyChanges: "Updated",
                tenantFolderPath: "Folder",
                tenantName: "Registry name",
                tenantType: "Provider"
            }));

    const integrationAuditEvent = context.auditEventModel.auditEvent as Contract.CiTenantAuditEvent;
    return new AuditEventDefinition(
        _<AuditEventDefinitionDetailItem>([]).
            concat(
                new AuditEventDefinitionDetailItem(
                    localization.tenantName(),
                    integrationAuditEvent.tenantName)).
            concatIf(
                TypeHelper.extendOrImplement(
                    integrationAuditEvent.typeName,
                    Contract.TypeNames.CiTenantUpdateAuditEvent),
                () => {
                    const properties =
                        _.filter([
                            (integrationAuditEvent as Contract.CiTenantUpdateAuditEvent).tenantNameChanged
                                ? localization.tenantName()
                                : undefined,
                            (integrationAuditEvent as Contract.CiTenantUpdateAuditEvent).tenantFolderPathChanged
                                ? localization.tenantFolderPath()
                                : undefined
                        ]);
                    return (
                        _<AuditEventDefinitionDetailItem>([]).
                            concatIf(
                                !_.isEmpty(properties),
                                new AuditEventDefinitionDetailItem(
                                    localization.propertyChanges(),
                                    localizeList(properties)!)).
                            value());
                }).
            concat(
                new AuditEventDefinitionDetailItem(
                    localization.tenantType(),
                    tenantTypeTranslator(integrationAuditEvent.ciTenantType))).
            value());
}