import { useLocalization } from "@infrastructure";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../..";
import { Contract } from "../../../../../../../../../../common";
import { Info } from "../../../../components";
import { KubernetesIngressRulesInfoCard, KubernetesYamlInfoCard } from "../../components";
import { useOpCommonKubernetesNamespaceResourceInfoItemElements } from "./useOpCommonKubernetesNamespaceResourceInfoItemElements";
import { useOpDefaultResourceInfoItemElements } from "./useOpDefaultResourceInfoItemElements";

export function useOpKubernetesIngressDefinition(resourceModel: Contract.OpResourceModel, options?: DefinitionOptions) {
    const commonKubernetesNamespaceResourceInfoItemElements = useOpCommonKubernetesNamespaceResourceInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useOpDefaultResourceInfoItemElements(resourceModel);
    const ingress = resourceModel.entity as Contract.OpKubernetesIngress;

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.op.useOpKubernetesIngressDefinition",
            () => ({
                tabs: {
                    yaml: "YAML"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Overview,
                <KubernetesYamlInfoCard rawYaml={ingress.data.rawYaml!}/>,
                localization.tabs.yaml(),
                "yaml")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={[]}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    commonKubernetesNamespaceResourceInfoItemElements.tagsInfoItemElement,
                    commonKubernetesNamespaceResourceInfoItemElements.annotationsInfoItemElement,
                    commonKubernetesNamespaceResourceInfoItemElements.clusterInfoItemElement,
                    commonKubernetesNamespaceResourceInfoItemElements.namespaceInfoItemElement
                ]}
                options={options?.infoOptions}>
                <KubernetesIngressRulesInfoCard rules={ingress.data.rules}/>
            </Info>
    });
}