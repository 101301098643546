import { map, Optional, StringHelper } from "@infrastructure";
import _, { Dictionary } from "lodash";
import { entityModelStore } from "../../..";
import { GcpRoleBindingHelper } from "../../../../tenants";
import * as Contract from "../../../controllers/contract";

export class AccessGraphHelper {
    private static commonTenantIdToPrincipalTypeSortValueMap: Record<string, number> = {
        [`gci/${Contract.GciPrincipalType.AllUsers}`]: GcpRoleBindingHelper.getPrincipalTypeSortValue(Contract.GciPrincipalType.AllUsers),
        [`gci/${Contract.GciPrincipalType.AllAuthenticatedUsers}`]: GcpRoleBindingHelper.getPrincipalTypeSortValue(Contract.GciPrincipalType.AllAuthenticatedUsers)
    };

    public static getColumnsData(
        accessVariant: Contract.EntityAccessVariant,
        sourceEntityCount: number,
        servicesOnly: boolean,
        graphVariant: "graph" | "list") {
        const forceHideEntities = accessVariant === Contract.EntityAccessVariant.DestinationEntity;
        return {
            showDestinationEntities: !forceHideEntities && (graphVariant === "graph" && !servicesOnly || graphVariant === "list"),
            showServices: !forceHideEntities && (graphVariant === "graph" && servicesOnly || graphVariant === "list"),
            showSourceEntities:
                map(
                    accessVariant,
                    {
                        [Contract.EntityAccessVariant.Identity]: () => false,
                        [Contract.EntityAccessVariant.PartialGroup]: () => false,
                        [Contract.EntityAccessVariant.WorkforcePool]: () => false,
                        [Contract.EntityAccessVariant.WorkloadIdentityPool]: () => false,
                        [Contract.EntityAccessVariant.OriginatorEntity]: () => sourceEntityCount > 1
                    },
                    () => true)
        };
    }

    public static useGetResourceServiceModelMap(accessGraph: Optional<Contract.AccessGraph>) {
        const serviceIds =
            _(accessGraph?.permissionEdgeIdToFragmentedDataMap).
                values().
                map(permissionEdgeFragmentedData => permissionEdgeFragmentedData.destinationEntitiesServiceId).
                uniq().
                value();
        const resourceServiceModels = entityModelStore.useGet(serviceIds);
        return _.keyBy(
            resourceServiceModels,
            resourceServiceModel => resourceServiceModel.entity.id);
    }

    public static getPermissionEdgeIdToDataMap(accessGraph: Optional<Contract.AccessGraph>) {
        if (_.isNil(accessGraph)) {
            return {};
        }

        return _.mapValues(
            accessGraph.permissionEdgeIdToFragmentedDataMap,
            permissionEdgeFragmentedData =>
                new AccessGraphPermissionEdgeData(
                    new Set<string>(accessGraph.entitiesReferenceConsistentHashToEntityIdsMap[permissionEdgeFragmentedData.destinationEntitiesReferenceConsistentHash]),
                    permissionEdgeFragmentedData.destinationEntitiesServiceId,
                    permissionEdgeFragmentedData.excessivePermissionActionCount,
                    permissionEdgeFragmentedData.nonexcessivePermissionActionCount,
                    new Set<string>(accessGraph.entitiesReferenceConsistentHashToEntityIdsMap[permissionEdgeFragmentedData.sourceEntitiesReferenceConsistentHash])));
    }

    public static getGroupIdentityIds(identityGroup: Contract.AccessGraphIdentityGroup | Contract.AccessGraphGroup) {
        return (identityGroup as Contract.AccessGraphIdentityGroup).identityIds ??
            [(identityGroup as Contract.AccessGraphGroup).groupId];
    }

    public static orderEntities(
        entities: EntitiesPermissionActionsEntity[],
        entityIdToSearchableReferenceMap: Dictionary<Contract.EntitySearchableReference>,
        resourceServiceModelMap: Dictionary<Contract.EntityModel>) {
        return _.orderBy(
            entities,
            [
                ({ entityId }) => AccessGraphHelper.commonTenantIdToPrincipalTypeSortValueMap[entityId],
                ({ entityId }) => !_.isNil(resourceServiceModelMap[entityId]),
                ({ excessivePermissionActionCount, nonexcessivePermissionActionCount }) => excessivePermissionActionCount / (excessivePermissionActionCount + nonexcessivePermissionActionCount),
                ({ entityId }) => StringHelper.getSortValue(entityIdToSearchableReferenceMap[entityId].displayName)
            ],
            [
                "asc",
                "desc",
                "desc",
                "asc"
            ]);
    }
}

export class AccessGraphPermissionEdgeData {
    constructor(
        public destinationEntityIds: Set<string>,
        public destinationEntitiesServiceId: string,
        public excessivePermissionActionCount: number,
        public nonexcessivePermissionActionCount: number,
        public sourceEntityIds: Set<string>) {
    }
}

export class EntitiesPermissionActionsEntity {
    constructor(
        public entityId: string,
        public excessivePermissionActionCount: number,
        public nonexcessivePermissionActionCount: number) {
    }
}