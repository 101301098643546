import { NoneIcon, useLocalization } from "@infrastructure";
import { Grid, Typography } from "@mui/material";
import React from "react";
import { ComplianceSecuredBar, Contract, EntitiesCell, RiskPolicyTypeMetadataHelper, useEntityTypeNameTranslator, useRiskPolicyTranslator, useTheme } from "../../../../../../../common";

type PolicyProps = {
    borderBottom: boolean;
    borderTop: boolean;
    complianceRiskPolicyData: Contract.ComplianceRiskPolicyData;
    count: number;
    excludedEntityIds: string[];
};

export function Policy({ borderBottom, borderTop, complianceRiskPolicyData, count, excludedEntityIds }: PolicyProps) {
    const riskPolicyTranslator = useRiskPolicyTranslator();
    const localization =
        useLocalization(
            "views.system.exportReport.complianceReport.policy",
            () => ({
                disabled: "Policy Disabled",
                unsecuredAndAnalyzed: "**{{unsecuredCount}}**/{{analyzedCount}} {{translatedEntityTypeName}}"
            }));
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const theme = useTheme();
    return (
        <Grid
            alignItems="center"
            container={true}
            sx={{
                border: theme.border.primary,
                borderRadius:
                    borderTop
                        ? theme.spacing(0.75, 0.75, 0, 0)
                        : borderBottom
                            ? theme.spacing(0, 0, 0.75, 0.75)
                            : undefined,
                borderTop:
                    borderTop
                        ? theme.border.primary
                        : "none",
                height: theme.px(70),
                padding: theme.spacing(1)
            }}>
            <Grid
                item={true}
                sx={{ paddingRight: theme.spacing(1) }}
                xs={5}>
                <Typography variant="h5">
                    {riskPolicyTranslator(complianceRiskPolicyData.riskPolicyIdentifier).title}
                </Typography>
            </Grid>
            <Grid
                item={true}
                sx={{ paddingRight: theme.spacing(1) }}
                xs={3}>
                {complianceRiskPolicyData.enabled && complianceRiskPolicyData.hasResults
                    ? localization.unsecuredAndAnalyzed({
                        analyzedCount: count,
                        translatedEntityTypeName:
                            entityTypeNameTranslator(
                                RiskPolicyTypeMetadataHelper.getRiskedEntityTypeName(complianceRiskPolicyData.riskPolicyConfigurationTypeName),
                                {
                                    count,
                                    includeServiceName: false,
                                    variant: "text"
                                }),
                        unsecuredCount: complianceRiskPolicyData.failedEntityCount
                    })
                    : <NoneIcon sx={{ fontSize: "18px" }}/>}
            </Grid>
            <Grid
                item={true}
                sx={{ paddingRight: theme.spacing(1) }}
                xs={2}>
                {complianceRiskPolicyData.enabled
                    ? <EntitiesCell
                        entityIdsOrModels={excludedEntityIds}
                        entityTypeName={RiskPolicyTypeMetadataHelper.getRiskedEntityTypeName(complianceRiskPolicyData.riskPolicyConfigurationTypeName)}
                        inlineEntitiesVariant="itemCountAndType"
                        sx={{
                            color: theme.palette.text.primary,
                            fontWeight: "bold",
                            textDecoration: "underline",
                            whiteSpace: "unset"
                        }}/>
                    : <NoneIcon sx={{ fontSize: "18px" }}/>}
            </Grid>
            <Grid
                item={true}
                xs={2}>
                {complianceRiskPolicyData.enabled
                    ? <ComplianceSecuredBar data={complianceRiskPolicyData}/>
                    : localization.disabled()}
            </Grid>
        </Grid>);
}