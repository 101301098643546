import { Link, MouseClickAreaScope } from "@infrastructure";
import { Box, Stack } from "@mui/material";
import React, { Fragment } from "react";
import { Contract, CustomerConsoleAppUrlHelper, LogoTextIcon, TenantHelper, Topbar as CommonTopbar, TopbarDividerItem, useTenantTypeRenderer, useTheme } from "../../../../common";
import { GlobalScopeFilter, SearchEntities } from "./components";

type TopbarProps = {
    consoleAppType: Contract.ConsoleAppType;
};

export function Topbar({ consoleAppType }: TopbarProps) {
    const tenantTypeRenderer = useTenantTypeRenderer();
    const theme = useTheme();
    return (
        <CommonTopbar consoleAppType={consoleAppType}>
            <Stack
                alignItems="center"
                direction="row"
                spacing={2}>
                <Link
                    urlOrGetUrl={CustomerConsoleAppUrlHelper.getRootRelativeUrl()}
                    variant="text">
                    <LogoTextIcon
                        sx={{
                            height: "22px",
                            width: "194px"
                        }}/>
                </Link>
                <Fragment>
                    <TopbarDividerItem/>
                    <GlobalScopeFilter/>
                </Fragment>
                <Box sx={{ flex: 1 }}>
                    {tenantTypeRenderer(
                        TenantHelper.EntitiesTenantTypes,
                        () =>
                            <Box
                                sx={{
                                    margin: "0 auto",
                                    maxWidth: theme.spacing(63),
                                    minWidth: theme.spacing(42),
                                    width: "100%"
                                }}>
                                <MouseClickAreaScope disabled={true}>
                                    <SearchEntities/>
                                </MouseClickAreaScope>
                            </Box>)}
                </Box>
            </Stack>
        </CommonTopbar>);
}