import { Link, map, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { Fragment } from "react";
import { Contract, useEntityTypeNameTranslator } from "../../../../../../../../../../../common";
import { AzureConsoleUrlBuilder } from "../../../../../../../../../../../tenants";

type ResourcesExternalConsoleLinkProps = {
    partitionType: Contract.AadPartitionType;
    typeName: string;
};

export function ResourcesExternalConsoleLink({ partitionType, typeName }: ResourcesExternalConsoleLinkProps) {
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const externalConsoleUrl =
        map(
            typeName,
            {
                [Contract.TypeNames.AzureComputeVirtualMachine]: () => AzureConsoleUrlBuilder.GetComputeVirtualMachineUrl(partitionType),
                [Contract.TypeNames.AzureNetworkNetworkSecurityGroup]: () => AzureConsoleUrlBuilder.GetNetworkNetworkSecurityGroupUrl(partitionType),
                [Contract.TypeNames.AzureStorageStorageAccount]: () => AzureConsoleUrlBuilder.GetStorageStorageAccountUrl(partitionType)
            },
            () => undefined);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.resourcesExternalConsoleLink",
            () => ({
                links: {
                    resourcePageLink: "{{translatedTypeName}} - Microsoft Azure"
                },
                withLink: "Sign in to Azure Portal and open the {{translatedTypeName}} console at {{resourcePageLink}}",
                withoutLink: "Sign in to Azure Portal and open the {{translatedTypeName}} console"
            }));
    return (
        <Fragment>
            {_.isNil(externalConsoleUrl)
                ? localization.withoutLink({ translatedTypeName: entityTypeNameTranslator(typeName) })
                : localization.withLink({
                    resourcePageLink:
                        <Link
                            urlOrGetUrl={externalConsoleUrl}
                            variant="external">
                            {localization.links.resourcePageLink({ translatedTypeName: entityTypeNameTranslator(typeName) })}
                        </Link>,
                    translatedTypeName: entityTypeNameTranslator(typeName)
                })}
        </Fragment>);
}