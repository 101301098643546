import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useAwsEc2InstanceMetadataServiceVersionTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useAwsEc2InstanceMetadataServiceVersionTranslator",
            () => ({
                [Contract.TypeNames.AwsEc2InstanceMetadataServiceVersion]: {
                    [Contract.AwsEc2InstanceMetadataServiceVersion.V1]: "V1 and V2 (token optional)",
                    [Contract.AwsEc2InstanceMetadataServiceVersion.V2]: "V2 (token required)"
                }
            }));
    return (metadataServiceVersion: Contract.AwsEc2InstanceMetadataServiceVersion) =>
        localization[Contract.TypeNames.AwsEc2InstanceMetadataServiceVersion][metadataServiceVersion]();
}