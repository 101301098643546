import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { useGetRiskPolicyConfigurationRetentionTimeFrameContextItem } from "../../../useGetRiskPolicyConfigurationRetentionTimeFrameContextItem";
import { useGetAwsRedshiftClusterRiskContext } from "../contexts";

export function useAwsRedshiftClusterBackupRetentionTimeFrameRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsRedshiftClusterBackupRetentionTimeFrameRisk;
    const clusterModel = entityModelStore.useGet(risk.entityId) as Contract.AwsRedshiftClusterModel;

    const getAwsRedshiftClusterRiskContext = useGetAwsRedshiftClusterRiskContext();
    const getRiskPolicyConfigurationRetentionTimeFrameContextItem = useGetRiskPolicyConfigurationRetentionTimeFrameContextItem();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsRedshiftClusterBackupRetentionTimeFrameRiskDefinition",
            () => ({
                description: "{{cluster}} backup retention period is less than {{configuredBackupRetentionTimeFrame | TimeSpanFormatter.day}}",
                sections: {
                    resolution: {
                        step1: "In the top right corner, click **Edit**",
                        step2: "Scroll down to the **Backup** section and set the **Automated snapshot retention period** to at least {{configuredBackupRetentionTimeFrame | TimeSpanFormatter.day}}",
                        step3: "Continue and save changes"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            cluster:
                <Entity
                    entityIdOrModel={clusterModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>,
            configuredBackupRetentionTimeFrame: risk.backupRetentionTimeFrame
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.Redshift,
                AwsConsoleUrlBuilder.getRedshiftClusterUrl(clusterModel.entity as Contract.AwsRedshiftCluster)),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2({ configuredBackupRetentionTimeFrame: risk.backupRetentionTimeFrame }),
            localization.sections.resolution.step3()
        ],
        riskModel,
        () => {
            const clusterRiskContext = getAwsRedshiftClusterRiskContext(clusterModel);
            return [
                clusterRiskContext.generalInformation,
                getRiskPolicyConfigurationRetentionTimeFrameContextItem(risk.backupRetentionTimeFrame),
                clusterRiskContext.backupRetentionTimeFrame,
                clusterRiskContext.storageSize,
                clusterRiskContext.sensitive,
                clusterRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}