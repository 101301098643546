import { Action0, ActionMenuItem, AnalyticsEventActionType, DataTableColumnRenderProps, Dialog, Menu, MenuItem, Optional, SeparatorMenuItem, SubMenuItem, UrlMenuItem, useExecuteOperation, useLocalization, useTrackAnalytics } from "@infrastructure";
import { Button, Stack } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { Fragment, useMemo, useState } from "react";
import { usePrincipalsContext } from "../../../../../..";
import { useTableContext } from "../..";
import { Contract, CustomerConsoleAppUrlHelper, Entity, EntityTypeMetadataModelHelper, JiraAvatar, JiraIcon, RiskController, RiskType, ScopeHelper, scopeSystemEntityModelStore, ServiceNowAvatar, ServiceNowIcon, TenantHelper, TicketingServicePromptMessage, ticketingServiceTicketStore, UpsertJiraIssueDialog, UpsertServiceNowIncidentDialog, useEntityTypeNameTranslator, useGetActivePermissionsTenantTypes, useJiraIssueTranslator, UserHelper, useServiceNowIncidentTranslator } from "../../../../../../../../../../../../common";
import { RiskResolutionAutomation as CommonRiskResolutionAutomation } from "../../../../../../../../../../../../common/components/RiskResolutionAutomation/RiskResolutionAutomation";
import { ProfileView } from "../../../../../../../../../Entities/components";
import { ProfileCategory } from "../../../../../../../../../Entities/components/Profile/hooks";
import { AccessView } from "../../../../../../../../hooks";
import { GenerateEntityNonexcessivePermitterDialog } from "./components";

export function ActionsCell({ item }: DataTableColumnRenderProps<Contract.IPrincipalModel>) {
    const { view } = usePrincipalsContext();
    const { reloadPrincipalModels, riskResolutionEnabled } = useTableContext();

    const [dialogType, setDialogType] = useState<ActionsCellDialogType>();
    const [riskIdToTicketIdsMap, setRiskIdToTicketIdsMap] = useState<Dictionary<string[]>>();

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const jiraIssueTranslator = useJiraIssueTranslator();


    const jiraIssueMap = ticketingServiceTicketStore.useGetJiraIssueMap(item.access!.risks.jiraIssueIds);

    const serviceNowIncidentTranslator = useServiceNowIncidentTranslator();
    const serviceNowIncidentMap = ticketingServiceTicketStore.useGetServiceNowIncidentMap(item.access!.risks.serviceNowIncidentIds);

    const trackAnalytics = useTrackAnalytics();
    const localization =
        useLocalization(
            "views.customer.access.principals.hooks.useDefinition.table.actionsCell",
            () => ({
                actions: {
                    resolve: "Remediate"
                },
                menu: {
                    accessGraph: "View {{translatedEntityTypeName}} permissions",
                    awsAccessGraph: "View {{translatedEntityTypeName}} aws permissions",
                    azureAccessGraph: "View {{translatedEntityTypeName}} azure permissions",
                    events: "View {{translatedEntityTypeName}} activity",
                    gcpAccessGraph: "View {{translatedEntityTypeName}} gcp permissions",
                    generateNonexcessivePermitter: {
                        [Contract.TypeNames.TenantType]: {
                            [Contract.TenantType.Aws]: "Generate least privilege policy",
                            [Contract.TenantType.Aad]: "Generate least privilege custom role",
                            [Contract.TenantType.Gci]: "Generate least privilege custom role",
                            [Contract.TenantType.Gcp]: "Generate least privilege custom role"
                        }
                    },
                    info: "View {{translatedEntityTypeName}} info",
                    jiraIssue: {
                        create: "Create Jira Issue",
                        selectUpdate: "Select Jira Issue to update",
                        update: "Update Jira Issue..."
                    },
                    openRisks: "View {{translatedEntityTypeName}} findings",
                    serviceNowIncident: {
                        create: "Create ServiceNow Incident",
                        selectUpdate: "Select ServiceNow Incident to update",
                        update: "Update ServiceNow Incident..."
                    }
                },
                prompt: {
                    description: {
                        [Contract.TypeNames.TicketingServiceType]: {
                            [Contract.TicketingServiceType.Jira]: "This {{translatedPrincipalTypeName}} is overprivileged/inactive in {{tenantCount | NumberFormatter.humanize}} different accounts on which you have permissions to create Jira issues. Each Jira issue we create relates to a single account and provides detailed information on those permissions with a link to the finding that details how to remediate it.\nWe are about to create **{{ticketCount | NumberFormatter.humanize}} Jira issues**, 1 for each account this {{translatedPrincipalTypeName}} is overprivileged/inactive in and you have permissions on.\nClick **Continue** if you want to proceed and select issue details or **Cancel** if you do not wish to create Jira issues.",
                            [Contract.TicketingServiceType.ServiceNow]: "This {{translatedPrincipalTypeName}} is overprivileged/inactive in {{tenantCount | NumberFormatter.humanize}} different accounts on which you have permissions to create ServiceNow incidents. Each ServiceNow incident we create relates to a single account and provides detailed information on those permissions with a link to the finding that details how to remediate it.\nWe are about to create **{{ticketCount | NumberFormatter.humanize}} ServiceNow incidents**, 1 for each account this {{translatedPrincipalTypeName}} is overprivileged/inactive in and you have permissions on.\nClick **Continue** if you want to proceed and select incident details or **Cancel** if you do not wish to create ServiceNow incidents."
                        }
                    },
                    title: {
                        [Contract.TypeNames.TicketingServiceType]: {
                            [Contract.TicketingServiceType.Jira]: "Create/Update Jira Issue for {{translatedPrincipalTypeName}} **{{principal}}**",
                            [Contract.TicketingServiceType.ServiceNow]: "Create/Update ServiceNow Incident for {{translatedPrincipalTypeName}} **{{principal}}**"
                        }
                    }
                }
            }));
    const [permittedRiskIdToScopeIds, permittedRiskJiraIssueIds, permittedOpenAccessPrincipalRiskIds, permittedServiceNowIncidentIds] =
        useMemo(
            () => {
                const permittedScopesRisks =
                    _.filter(
                        item.access!.scopesRisks,
                        scopesRisk =>
                            _.some(
                                scopesRisk.scopeIds,
                                scopeId =>
                                    UserHelper.hasScopePermissions(
                                        scopeId,
                                        Contract.IdentityPermission.SecurityWrite)));
                const permittedRiskJiraIssueIds =
                    _(permittedScopesRisks).
                        flatMap(scopesRisk => scopesRisk.jiraIssueIds).
                        filter().
                        value();
                const permittedOpenAccessPrincipalRiskIds =
                    _(permittedScopesRisks).
                        map(scopesRisk => scopesRisk.openAccessPrincipalRiskId).
                        value();
                const permittedRiskServiceNowIncidentIds =
                    _(permittedScopesRisks).
                        flatMap(scopesRisk => scopesRisk.serviceNowIncidentIds).
                        filter().
                        value();
                const permittedRiskIdToScopeIds =
                    _(permittedScopesRisks).
                        keyBy(permittedScopesRisk => permittedScopesRisk.openAccessPrincipalRiskId).
                        mapValues(permittedScopesRisk => permittedScopesRisk.scopeIds).
                        value();
                return [permittedRiskIdToScopeIds, permittedRiskJiraIssueIds, permittedOpenAccessPrincipalRiskIds, permittedRiskServiceNowIncidentIds];
            },
            [item]);

    const jiraInstanceModels =
        ScopeHelper.getScopesParentScopeSystemEntityModelsIntersection(
            _.values(permittedRiskIdToScopeIds),
            scopeSystemEntityModelStore.useGetJira());
    const serviceNowInstanceModels =
        ScopeHelper.getScopesParentScopeSystemEntityModelsIntersection(
            _.values(permittedRiskIdToScopeIds),
            scopeSystemEntityModelStore.useGetServiceNow());

    const [jiraEnabled, serviceNowEnabled] =
        useMemo(
            () => {
                const jiraEnabled =
                    view === AccessView.PrincipalExcessivePermissions &&
                    !_.isEmpty(permittedOpenAccessPrincipalRiskIds) &&
                    !_(jiraInstanceModels).
                        flatMap(jiraInstanceModel => (jiraInstanceModel.configuration as Contract.JiraInstanceConfiguration).projects).
                        isEmpty();

                const serviceNowEnabled =
                    view === AccessView.PrincipalExcessivePermissions &&
                    !_.isEmpty(permittedOpenAccessPrincipalRiskIds) &&
                    !_.isEmpty(serviceNowInstanceModels);

                return [jiraEnabled, serviceNowEnabled];
            },
            [jiraInstanceModels, serviceNowInstanceModels]);

    const activePermissionsTenantTypes = useGetActivePermissionsTenantTypes(item.entity.typeName);
    const entityTypeMetadataModel = EntityTypeMetadataModelHelper.get(item.entity.typeName);
    const translatedEntityTypeName =
        entityTypeNameTranslator(
            item.entity.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });

    const generateNonexcessivePermitterEnabled =
        !item.unknown &&
        view === AccessView.PrincipalPermissions &&
        _.includes(
            TenantHelper.GenerateEntityNonexcessivePermitterTenantTypes,
            entityTypeMetadataModel.tenantType);

    async function closeTicketDialog(successRiskIds?: string[]) {
        if (!_.isEmpty(successRiskIds)) {
            await reloadPrincipalModels();
        }

        setDialogType(undefined);
    }

    function getPromptMessage(ticketingServiceType: Contract.TicketingServiceType): Optional<TicketingServicePromptMessage> {
        return (
            !_.some(riskIdToTicketIdsMap, incidentIds => _.isEmpty(incidentIds)) ||
            permittedOpenAccessPrincipalRiskIds.length === 1)
            ? undefined
            : {
                description:
                    localization.prompt.description[Contract.TypeNames.TicketingServiceType][ticketingServiceType]({
                        tenantCount: permittedOpenAccessPrincipalRiskIds.length,
                        ticketCount: permittedOpenAccessPrincipalRiskIds.length,
                        translatedPrincipalTypeName:
                            entityTypeNameTranslator(
                                item.entity.typeName,
                                {
                                    includeServiceName: false,
                                    variant: "text"
                                })
                    }),
                title:
                    localization.prompt.title[Contract.TypeNames.TicketingServiceType][ticketingServiceType]({
                        principal:
                            <Entity
                                entityIdOrModel={_.as<Contract.EntityModel>(item)}
                                linkOptions={{ disabled: true }}
                                variant="text"/>,
                        translatedPrincipalTypeName:
                            entityTypeNameTranslator(
                                item.entity.typeName,
                                {
                                    variant: "text"
                                })
                    })
            };
    }

    return (
        <Fragment>
            {!_.isNil(dialogType) && (
                <Fragment>
                    {dialogType === ActionsCellDialogType.GenerateNonexcessivePermitter &&
                        <GenerateEntityNonexcessivePermitterDialog
                            principalModel={item}
                            onClose={() => setDialogType(undefined)}/>}
                    {dialogType === ActionsCellDialogType.Jira &&
                        <UpsertJiraIssueDialog
                            instanceModels={jiraInstanceModels}
                            promptMessage={getPromptMessage(Contract.TicketingServiceType.Jira)}
                            riskIdToIssueIdsMap={riskIdToTicketIdsMap!}
                            riskType={RiskType.Cloud}
                            onClose={closeTicketDialog}/>}
                    {dialogType === ActionsCellDialogType.ServiceNow &&
                        <UpsertServiceNowIncidentDialog
                            instanceModels={serviceNowInstanceModels}
                            promptMessage={getPromptMessage(Contract.TicketingServiceType.ServiceNow)}
                            riskIdToIncidentIdsMap={riskIdToTicketIdsMap!}
                            riskType={RiskType.Cloud}
                            onClose={closeTicketDialog}/>}
                    {dialogType === ActionsCellDialogType.Resolution &&
                        <Dialog
                            size="large"
                            variant="editor"
                            onClose={() => setDialogType(undefined)}>
                            <RiskResolutionAutomation
                                principalModel={item}
                                resolutionPermittedRiskIds={permittedOpenAccessPrincipalRiskIds}
                                onClose={() => setDialogType(undefined)}
                                onFinished={() => setDialogType(undefined)}/>
                        </Dialog>}
                </Fragment>)}
            <Stack
                alignItems="center"
                direction="row"
                justifyContent="flex-end"
                spacing={2}>
                {view === AccessView.PrincipalExcessivePermissions &&
                    !_.isEmpty(jiraIssueMap) && (
                    <JiraAvatar issuesOrGetIssues={_.values(jiraIssueMap)}/>)}
                {view === AccessView.PrincipalExcessivePermissions &&
                    !_.isEmpty(serviceNowIncidentMap) && (
                    <ServiceNowAvatar
                        incidentsOrGetIncidents={_.values(serviceNowIncidentMap)}/>)}
                {view === AccessView.PrincipalExcessivePermissions && riskResolutionEnabled &&
                    <Button
                        disabled={!_.some(permittedOpenAccessPrincipalRiskIds)}
                        size="small"
                        onClick={
                            () => {
                                trackAnalytics(AnalyticsEventActionType.AccessPrincipalExcessivePermissionsRemediateClick);
                                setDialogType(ActionsCellDialogType.Resolution);
                            }}>
                        {localization.actions.resolve()}
                    </Button>}
                <Menu
                    itemsOrGetItems={
                        _<MenuItem>([]).
                            concatIf(
                                generateNonexcessivePermitterEnabled,
                                () => new ActionMenuItem(
                                    () => setDialogType(ActionsCellDialogType.GenerateNonexcessivePermitter),
                                    localization.menu.generateNonexcessivePermitter[Contract.TypeNames.TenantType].translate(entityTypeMetadataModel.tenantType),
                                    {
                                        analyticsOptions: {
                                            onClick: {
                                                actionType: AnalyticsEventActionType.AccessPrincipalTableMenuClick,
                                                propertyNameToValueMap: {
                                                    "Type": localization.menu.generateNonexcessivePermitter[Contract.TypeNames.TenantType].translate(entityTypeMetadataModel.tenantType)
                                                }
                                            }
                                        }
                                    })).
                            concatIf(
                                !item.unknown,
                                new UrlMenuItem(
                                    localization.menu.info({ translatedEntityTypeName }),
                                    CustomerConsoleAppUrlHelper.getEntityProfileRelativeUrl(_.as<Contract.EntityModel>(item), { category: ProfileCategory.Overview })!,
                                    {
                                        analyticsOptions: {
                                            onClick: {
                                                actionType: AnalyticsEventActionType.AccessPrincipalTableMenuClick,
                                                propertyNameToValueMap: {
                                                    "Type": localization.menu.info({ translatedEntityTypeName })
                                                }
                                            }
                                        },
                                        newTab: false
                                    }),
                                new UrlMenuItem(
                                    localization.menu.openRisks({ translatedEntityTypeName }),
                                    CustomerConsoleAppUrlHelper.getEntityProfileRelativeUrl(_.as<Contract.EntityModel>(item), { category: ProfileCategory.Risks })!,
                                    {
                                        analyticsOptions: {
                                            onClick: {
                                                actionType: AnalyticsEventActionType.AccessPrincipalTableMenuClick,
                                                propertyNameToValueMap: {
                                                    "Type": localization.menu.openRisks({ translatedEntityTypeName })
                                                }
                                            }
                                        },
                                        newTab: false
                                    })).
                            concatIf(
                                _.includes(activePermissionsTenantTypes, Contract.TenantType.Aws) &&
                                !item.unknown,
                                () => {
                                    const title =
                                        activePermissionsTenantTypes.length > 1
                                            ? localization.menu.awsAccessGraph({ translatedEntityTypeName })
                                            : localization.menu.accessGraph({ translatedEntityTypeName });
                                    return new UrlMenuItem(
                                        title,
                                        CustomerConsoleAppUrlHelper.getEntityProfileRelativeUrl(_.as<Contract.EntityModel>(item), {
                                            category: ProfileCategory.Iam,
                                            view: ProfileView.AwsAccessGraph
                                        })!,
                                        {
                                            analyticsOptions: {
                                                onClick: {
                                                    actionType: AnalyticsEventActionType.AccessPrincipalTableMenuClick,
                                                    propertyNameToValueMap: {
                                                        "Type": title
                                                    }
                                                }
                                            },
                                            newTab: false
                                        });
                                }).
                            concatIf(
                                _.includes(activePermissionsTenantTypes, Contract.TenantType.Azure) &&
                                !item.unknown,
                                () => {
                                    const title =
                                        activePermissionsTenantTypes.length > 1
                                            ? localization.menu.azureAccessGraph({ translatedEntityTypeName })
                                            : localization.menu.accessGraph({ translatedEntityTypeName });
                                    return new UrlMenuItem(
                                        title,
                                        CustomerConsoleAppUrlHelper.getEntityProfileRelativeUrl(_.as<Contract.EntityModel>(item), {
                                            category: ProfileCategory.Iam,
                                            view: ProfileView.AzureAccessGraph
                                        })!,
                                        {
                                            analyticsOptions: {
                                                onClick: {
                                                    actionType: AnalyticsEventActionType.AccessPrincipalTableMenuClick,
                                                    propertyNameToValueMap: {
                                                        "Type": title
                                                    }
                                                }
                                            },
                                            newTab: false
                                        });
                                }).
                            concatIf(
                                _.includes(activePermissionsTenantTypes, Contract.TenantType.Gcp) &&
                                !item.unknown,
                                () => {
                                    const title =
                                        activePermissionsTenantTypes.length > 1
                                            ? localization.menu.gcpAccessGraph({ translatedEntityTypeName })
                                            : localization.menu.accessGraph({ translatedEntityTypeName });
                                    return new UrlMenuItem(
                                        title,
                                        CustomerConsoleAppUrlHelper.getEntityProfileRelativeUrl(_.as<Contract.EntityModel>(item), {
                                            category: ProfileCategory.Iam,
                                            view: ProfileView.GcpAccessGraph
                                        })!,
                                        {
                                            analyticsOptions: {
                                                onClick: {
                                                    actionType: AnalyticsEventActionType.AccessPrincipalTableMenuClick,
                                                    propertyNameToValueMap: {
                                                        "Type": title
                                                    }
                                                }
                                            },
                                            newTab: false
                                        });
                                }).
                            concatIf(
                                entityTypeMetadataModel.accessEvents &&
                                !item.unknown,
                                new UrlMenuItem(
                                    localization.menu.events({ translatedEntityTypeName }),
                                    CustomerConsoleAppUrlHelper.getEntityProfileRelativeUrl(_.as<Contract.EntityModel>(item), { category: ProfileCategory.Events })!,
                                    {
                                        analyticsOptions: {
                                            onClick: {
                                                actionType: AnalyticsEventActionType.AccessPrincipalTableMenuClick,
                                                propertyNameToValueMap: {
                                                    "Type": localization.menu.events({ translatedEntityTypeName })
                                                }
                                            }
                                        },
                                        newTab: false
                                    })).
                            concatIf(
                                view === AccessView.PrincipalExcessivePermissions &&
                                !_.isEmpty(permittedOpenAccessPrincipalRiskIds) && (
                                    !_(jiraInstanceModels).
                                        flatMap(jiraInstanceModel => (jiraInstanceModel.configuration as Contract.JiraInstanceConfiguration).projects).
                                        isEmpty() ||
                                    !_.isEmpty(serviceNowInstanceModels)),
                                () => new SeparatorMenuItem()).
                            concatIf(
                                jiraEnabled,
                                () =>
                                    new ActionMenuItem(
                                        () => {
                                            setRiskIdToTicketIdsMap(
                                                _(permittedOpenAccessPrincipalRiskIds).
                                                    keyBy().
                                                    mapValues(() => []).
                                                    value());
                                            setDialogType(ActionsCellDialogType.Jira);
                                        },
                                        localization.menu.jiraIssue.create(),
                                        { icon: <JiraIcon/> })).
                            concatIf(
                                jiraEnabled && !_.isEmpty(permittedRiskJiraIssueIds),
                                () =>
                                    new SubMenuItem(
                                        async () => {
                                            const { riskModels } = await RiskController.getRiskModels(new Contract.RiskControllerGetRiskModelsRequest(permittedOpenAccessPrincipalRiskIds));
                                            return _(riskModels).
                                                flatMap(
                                                    riskModel =>
                                                        _.map(
                                                            riskModel!.riskState?.integration.jira.issueIds,
                                                            jiraIssueId => ({
                                                                jiraIssue: jiraIssueMap[jiraIssueId],
                                                                riskModel
                                                            }))).
                                                map(
                                                    ({ jiraIssue, riskModel }) =>
                                                        new ActionMenuItem(
                                                            () => {
                                                                setRiskIdToTicketIdsMap({ [riskModel.risk.id]: [jiraIssue.id] });
                                                                setDialogType(ActionsCellDialogType.Jira);
                                                            },
                                                            jiraIssueTranslator(jiraIssue, "id"),
                                                            { disabled: jiraIssue.multipleRisks })).
                                                value();
                                        },
                                        localization.menu.jiraIssue.update(),
                                        {
                                            icon: <JiraIcon/>,
                                            subMenuTitle: localization.menu.jiraIssue.selectUpdate()
                                        })).
                            concatIf(
                                serviceNowEnabled,
                                () =>
                                    new ActionMenuItem(
                                        () => {
                                            setRiskIdToTicketIdsMap(
                                                _(permittedOpenAccessPrincipalRiskIds).
                                                    keyBy().
                                                    mapValues(() => []).
                                                    value());
                                            setDialogType(ActionsCellDialogType.ServiceNow);
                                        },
                                        localization.menu.serviceNowIncident.create(),
                                        { icon: <ServiceNowIcon/> })).
                            concatIf(
                                serviceNowEnabled && !_.isEmpty(permittedServiceNowIncidentIds),
                                () =>
                                    new SubMenuItem(
                                        async () => {
                                            const { riskModels } = await RiskController.getRiskModels(new Contract.RiskControllerGetRiskModelsRequest(permittedOpenAccessPrincipalRiskIds));

                                            return _(riskModels).
                                                flatMap(
                                                    riskModel =>
                                                        _.map(
                                                            riskModel!.riskState?.integration.serviceNow.incidentIds,
                                                            incidentId => ({
                                                                riskModel,
                                                                serviceNowIncident: serviceNowIncidentMap[incidentId]
                                                            }))).
                                                map(
                                                    ({ riskModel, serviceNowIncident }) =>
                                                        new ActionMenuItem(
                                                            () => {
                                                                setRiskIdToTicketIdsMap({ [riskModel.risk.id]: [serviceNowIncident.id] });
                                                                setDialogType(ActionsCellDialogType.ServiceNow);
                                                            },
                                                            serviceNowIncidentTranslator(serviceNowIncident, "id"),
                                                            { disabled: serviceNowIncident.multipleRisks })).
                                                value();
                                        },
                                        localization.menu.serviceNowIncident.update(),
                                        {
                                            icon: <ServiceNowIcon/>,
                                            subMenuTitle: localization.menu.serviceNowIncident.selectUpdate()
                                        })).
                            value()}/>
            </Stack>
        </Fragment>);
}

enum ActionsCellDialogType {
    GenerateNonexcessivePermitter = "generateNonexcessivePermitter",
    Jira = "jira",
    Resolution = "resolution",
    ServiceNow = "serviceNow"
}

type RiskResolutionAutomationProps = {
    onClose: Action0;
    onFinished: Action0;
    principalModel: Contract.IPrincipalModel;
    resolutionPermittedRiskIds: string[];
};

function RiskResolutionAutomation({ onFinished, principalModel, resolutionPermittedRiskIds }: RiskResolutionAutomationProps) {
    const [{ changeModels, openAccessPrincipalRiskModels }] =
        useExecuteOperation(
            [RiskResolutionAutomation, principalModel.id],
            async () => {
                const { changeModels } = await RiskController.getRiskChangeModels(new Contract.RiskControllerGetRiskChangeModelsRequest(resolutionPermittedRiskIds));
                const { riskModels } = await RiskController.getRiskModels(new Contract.RiskControllerGetRiskModelsRequest(resolutionPermittedRiskIds));
                return { changeModels, openAccessPrincipalRiskModels: riskModels };
            });

    const { executeGetPrincipalSummary } = usePrincipalsContext();
    const { reloadPrincipalModels } = useTableContext();
    const openAccessPrincipalRiskModelMap =
        useMemo(
            () =>
                _.keyBy(
                    openAccessPrincipalRiskModels,
                    openAccessPrincipalRiskModel => openAccessPrincipalRiskModel.id),
            []);

    return (
        <CommonRiskResolutionAutomation
            changeModels={changeModels}
            riskModelMap={openAccessPrincipalRiskModelMap}
            onFinished={
                async (canceled: boolean) => {
                    if (!canceled) {
                        if (_.every(
                            changeModels,
                            changeModel => changeModel.resolvable)) {
                            await reloadPrincipalModels();
                        }

                        await executeGetPrincipalSummary();
                    }

                    onFinished();
                }}/>);
}