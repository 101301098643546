import _ from "lodash";
import { useGetTopItemsExportData } from ".";
import { Contract, TypeHelper } from "../../../common";

export function useGetVulnerabilitiesCsvItem() {
    const getTopItemsExportData = useGetTopItemsExportData();
    return (vulnerabilities?: Contract.VulnerabilityData[], itemCount?: number, itemTopCount = 100) =>
        getTopItemsExportData(
            _.orderBy(
                vulnerabilities,
                vulnerability =>
                    [
                        TypeHelper.getEnumValue(
                            Contract.TypeNames.Severity,
                            vulnerability.severity),
                        vulnerability.rawId
                    ],
                [
                    "desc",
                    "desc"
                ]),
            itemCount ?? _.size(vulnerabilities),
            vulnerability => vulnerability.rawId,
            itemTopCount,
            false);
}