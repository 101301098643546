﻿import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";
import { useTheme } from "../../../themes";

export function NorthAmericaIcon(props: SvgIconProps) {
    const theme = useTheme();
    return (
        <SvgIcon {...props}>
            <path
                d="M11.1117 7.5V16.5H9.9133L6.40899 10.3696H6.34725V16.5H5V7.5H6.20563L9.70631 13.6348H9.77168V7.5H11.1117Z"
                fill={theme.palette.text.primary}/>
            <path
                d="M13.5347 16.5H12.0967L14.7149 7.5H16.3781L19 16.5H17.562L15.5756 9.3457H15.5175L13.5347 16.5ZM13.5819 12.9712H17.5039V14.2808H13.5819V12.9712Z"
                fill={theme.palette.text.primary}/>
            <path
                clipRule="evenodd"
                d="M22 5.00227H2C1.44772 5.00227 1 5.451 1 6.00453V17.9955C1 18.549 1.44772 18.9977 2 18.9977H22C22.5523 18.9977 23 18.549 23 17.9955V6.00454C23 5.451 22.5523 5.00227 22 5.00227ZM2 4H22C23.1046 4 24 4.89746 24 6.00454V17.9955C24 19.1025 23.1046 20 22 20H2C0.89543 20 0 19.1025 0 17.9955V6.00453C0 4.89746 0.895431 4 2 4Z"
                fill={theme.palette.text.secondary}
                fillRule="evenodd"/>
        </SvgIcon>);
}