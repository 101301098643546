import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";
import { useTheme } from "../themes";

export function EndpointConnectorIcon({ ...props }: SvgIconProps) {
    const theme = useTheme();
    return (
        <SvgIcon {...props}>
            <path
                clipRule="evenodd"
                d="M6.7209 5.31549C6.37162 6.94126 6.14961 9.26754 6.14961 12.0001C6.14961 14.7326 6.37162 17.0589 6.72089 18.6846C6.89669 19.5028 7.0973 19.9576 7.29754 20.3419C7.39794 20.5345 7.48201 20.6398 7.53614 20.6895C7.54063 20.6936 7.54459 20.6971 7.54804 20.7H16.4996V22H7.49961C7.15184 22 6.86734 21.8403 6.65694 21.6471C6.45068 21.4577 6.28357 21.2091 6.1447 20.9427C5.86639 20.4086 5.63603 19.824 5.4499 18.9576C5.07531 17.2141 4.84961 14.7904 4.84961 12.0001C4.84961 9.20977 5.07531 6.78601 5.4499 5.04243C5.63603 4.17603 5.86639 3.53403 6.1447 2.99997C6.28357 2.73348 6.45068 2.48488 6.65694 2.29551C6.86733 2.10233 7.15183 1.94262 7.49961 1.94262H16.4996V3.24262H7.54805C7.5446 3.24551 7.54064 3.24898 7.53615 3.2531C7.48202 3.3028 7.39796 3.40807 7.29755 3.60074C7.0973 3.98501 6.89669 4.49723 6.7209 5.31549Z"
                fill={theme.palette.text.primary}
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M16.8229 20.7948C16.8228 20.7955 16.8171 20.7914 16.806 20.7799C16.8175 20.7882 16.823 20.794 16.8229 20.7948ZM16.5 20.2728C16.6671 19.9151 16.8428 19.4111 17.0066 18.7558C17.4265 17.0763 17.7 14.6871 17.7 12C17.7 9.31285 17.4265 6.92374 17.0066 5.24423C16.8428 4.58893 16.6671 4.08487 16.5 3.72719C16.3329 4.08487 16.1572 4.58893 15.9934 5.24423C15.5735 6.92374 15.3 9.31285 15.3 12C15.3 14.6871 15.5735 17.0763 15.9934 18.7558C16.1572 19.4111 16.3329 19.9151 16.5 20.2728ZM16.8229 3.20525C16.823 3.20605 16.8175 3.21179 16.806 3.22009C16.8171 3.2086 16.8228 3.20445 16.8229 3.20525ZM16.1771 3.20525C16.1772 3.20445 16.1829 3.2086 16.194 3.22009C16.1825 3.21179 16.177 3.20605 16.1771 3.20525ZM16.194 20.7799C16.1829 20.7914 16.1772 20.7955 16.1771 20.7948C16.177 20.794 16.1825 20.7882 16.194 20.7799ZM16.5 22C15.1193 22 14 17.5228 14 12C14 6.47715 15.1193 2 16.5 2C17.8807 2 19 6.47715 19 12C19 17.5228 17.8807 22 16.5 22Z"
                fill={theme.palette.text.primary}
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M5.65049 9.64985L1.15 9.64985C0.791015 9.64985 0.5 9.35884 0.5 8.99985C0.5 8.64087 0.791015 8.34985 1.15 8.34985L5.65049 8.34985L5.65049 9.64985Z"
                fill={theme.palette.text.primary}
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M15 15.3501L23.0006 15.3501C23.3596 15.3501 23.6506 15.6412 23.6506 16.0001C23.6506 16.3591 23.3596 16.6501 23.0006 16.6501L15 16.6501L15 15.3501Z"
                fill={theme.palette.text.primary}
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M21.3633 6.40376L23.4999 8.54033C23.7537 8.79418 23.7537 9.20573 23.4999 9.45957L21.3633 11.5961C21.1095 11.85 20.6979 11.85 20.4441 11.5961C20.1902 11.3423 20.1902 10.9307 20.4441 10.6769L21.471 9.64995L15.0403 9.64995L15.0403 8.34995L21.471 8.34995L20.4441 7.323C20.1902 7.06916 20.1902 6.6576 20.4441 6.40376C20.6979 6.14992 21.1095 6.14992 21.3633 6.40376Z"
                fill={theme.palette.text.primary}
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M2.63643 18.5962L0.499854 16.4597C0.246013 16.2058 0.246013 15.7943 0.499854 15.5404L2.63643 13.4039C2.89027 13.15 3.30183 13.15 3.55567 13.4039C3.80951 13.6577 3.80951 14.0693 3.55567 14.3231L2.52871 15.35L5.95947 15.35L5.95947 16.65L2.52871 16.65L3.55567 17.677C3.80951 17.9308 3.80951 18.3424 3.55567 18.5962C3.30183 18.8501 2.89027 18.8501 2.63643 18.5962Z"
                fill={theme.palette.text.primary}
                fillRule="evenodd"/>
        </SvgIcon>);
}