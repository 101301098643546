import { TextViewer, useLocalization } from "@infrastructure";
import React from "react";
import { useGcpDefaultResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../..";
import { Contract, InfoItem } from "../../../../../../../../../../common";
import { useGcpIamRoleTypeTranslator } from "../../../../../../hooks";
import { Info } from "../../../../components";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";

export function useGcpIamRoleDefinition(resourceModel: Contract.GcpResourceModel, options?: DefinitionOptions) {
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultPropertyInfoElements = useGcpDefaultResourceInfoItemElements(resourceModel);
    const role = resourceModel.entity as Contract.GcpIamRole;

    const iamRoleTypeTranslator = useGcpIamRoleTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpIamRoleDefinition",
            () => ({
                info: {
                    items: {
                        description: "Description",
                        stage: {
                            title: "Launch Stage",
                            [Contract.TypeNames.GcpIamRoleStage]: {
                                [Contract.GcpIamRoleStage.Alpha]: "Alpha",
                                [Contract.GcpIamRoleStage.Beta]: "Beta",
                                [Contract.GcpIamRoleStage.Deprecated]: "Deprecated",
                                [Contract.GcpIamRoleStage.Disabled]: "Disabled",
                                [Contract.GcpIamRoleStage.EarlyAccessProgram]: "Early Access Program",
                                [Contract.GcpIamRoleStage.GeneralAvailability]: "General Availability"
                            }
                        },
                        type: "Role Type"
                    }
                },
                tabs: {
                    role: "Definition"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Overview,
                <TextViewer
                    format="json"
                    text={role.rawRole}/>,
                localization.tabs.role(),
                "role")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultPropertyInfoElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="description"
                        location="all"
                        title={localization.info.items.description()}
                        value={role.description}/>,
                    <InfoItem
                        key="type"
                        location="all"
                        title={localization.info.items.type()}
                        value={iamRoleTypeTranslator(role.type)}/>,
                    <InfoItem
                        key="stage"
                        location="all"
                        title={localization.info.items.stage.title()}
                        value={localization.info.items.stage[Contract.TypeNames.GcpIamRoleStage][role.stage]()}/>
                ]}
                options={options?.infoOptions}>
            </Info>,
        sensitive: false
    });
}