import { useLocalization } from "@infrastructure";
import React from "react";
import { useAwsCommonEncryptedResourceInfoElements, useAwsDefaultResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { useCustomEntityPropertyInfoItemElements } from "..";
import { Contract, InfoItem } from "../../../../../../../../../../common";
import { useAwsCloudTrailTrailEventSelectorTypeTranslator } from "../../../../../../../../../../tenants";
import { EntitiesInfoItem, Info } from "../../../../components";

export function useAwsCloudTrailTrailDefinition(resourceModel: Contract.AwsResourceModel, options?: DefinitionOptions) {
    const commonEncryptedResourceInfoElements = useAwsCommonEncryptedResourceInfoElements(resourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useAwsDefaultResourceInfoItemElements(resourceModel);
    const trail = resourceModel.entity as Contract.AwsCloudTrailTrail;
    const trailModel = resourceModel as Contract.AwsCloudTrailTrailModel;

    const cloudTrailTrailEventSelectorTypeTranslator = useAwsCloudTrailTrailEventSelectorTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsCloudTrailTrailDefinition",
            () => ({
                info: {
                    items: {
                        bucketId: "S3 Bucket",
                        bucketObjectEventSelectorType: "S3 Data Events",
                        digestEnabled: {
                            false: "Disabled",
                            title: "Log File Validation",
                            true: "Enabled"
                        },
                        enabled: {
                            false: "Off",
                            title: "Logging",
                            true: "On"
                        },
                        lambdaFunctionConfigurationInvokeEventSelectorType: "Lambda Data Events",
                        managementEventSelectorType: "Management Events",
                        multiRegion: {
                            false: "No",
                            title: "Multi-Region",
                            true: "Yes"
                        },
                        organizationTrail: {
                            false: "No",
                            title: "Organizational",
                            true: "Yes"
                        }
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    ...commonEncryptedResourceInfoElements,
                    <InfoItem
                        key="enabled"
                        location="all"
                        title={localization.info.items.enabled.title()}
                        value={
                            trail.enabled
                                ? localization.info.items.enabled.true()
                                : localization.info.items.enabled.false()}/>,
                    <InfoItem
                        key="multiRegion"
                        title={localization.info.items.multiRegion.title()}
                        value={
                            trail.multiRegion
                                ? localization.info.items.multiRegion.true()
                                : localization.info.items.multiRegion.false()}/>,
                    <InfoItem
                        key="organizationTrail"
                        title={localization.info.items.organizationTrail.title()}
                        value={
                            trail.organizationTrail
                                ? localization.info.items.organizationTrail.true()
                                : localization.info.items.organizationTrail.false()}/>,
                    <EntitiesInfoItem
                        emptyValue={trail.bucketArn}
                        entityIdsOrModels={trailModel.bucketId}
                        entityTypeName={Contract.TypeNames.AwsS3Bucket}
                        key="bucketId"
                        location="all"
                        title={localization.info.items.bucketId()}/>,
                    <InfoItem
                        key="digestEnabled"
                        title={localization.info.items.digestEnabled.title()}
                        value={
                            trail.digestEnabled
                                ? localization.info.items.digestEnabled.true()
                                : localization.info.items.digestEnabled.false()}/>,
                    <InfoItem
                        key="managementEventSelectorType"
                        location="all"
                        title={localization.info.items.managementEventSelectorType()}
                        value={cloudTrailTrailEventSelectorTypeTranslator(trail.managementEventSelectorType)}/>,
                    <InfoItem
                        key="bucketObjectEventSelectorType"
                        location="all"
                        title={localization.info.items.bucketObjectEventSelectorType()}
                        value={cloudTrailTrailEventSelectorTypeTranslator(trail.bucketObjectEventSelectorType)}/>,
                    <InfoItem
                        key="lambdaFunctionConfigurationInvokeEventSelectorType"
                        location="all"
                        title={localization.info.items.lambdaFunctionConfigurationInvokeEventSelectorType()}
                        value={cloudTrailTrailEventSelectorTypeTranslator(trail.lambdaFunctionConfigurationInvokeEventSelectorType)}/>
                ]}
                options={options?.infoOptions}/>
    });
}