import { ActionMenuItem, useLocalization, useSetRoute } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, CustomerConsoleAppUrlHelper, ScopeHelper, scopeNodeModelStore, tenantModelStore } from "../../../../../../../../common";
import { AddOrEditDefinition } from "../../useAddOrEditDefinition";
import { AddOrEdit, Welcome } from "./components";

export function useAwsAddOrEditDefinition(): AddOrEditDefinition {
    const tenantModels = tenantModelStore.useGetPermittedAwsTenants();
    const tenantTypes =
        useMemo(
            () => [Contract.TenantType.Aws],
            []);
    const activeScopeNodeMap = scopeNodeModelStore.useGetActiveScopeNodeMap(tenantTypes);
    const setRoute = useSetRoute();

    const masterTenantModels =
        _.filter(
            tenantModels,
            tenantModel =>
                tenantModel.master &&
                _.isNil(tenantModel.configuration.organizationId));

    const localization =
        useLocalization(
            "views.customer.scopes.hooks.useDefinition.hooks.useAwsDefinition.useAwsAddOrEditDefinition",
            () => ({
                addOrganization: {
                    confirmTitle: "To onboard an AWS organization, first onboard the management account with Monitoring permissions enabled. Afterwards, you can proceed with organization onboarding. Do you want to onboard the management account now?",
                    title: "Add organization"
                },
                addTenantTitle: "Add account"
            }));

    return useMemo(
        () => ({
            additionalActionItems:
                onEdit => [
                    new ActionMenuItem(
                        () => {
                            if (_.isEmpty(masterTenantModels)) {
                                onEdit();
                            } else {
                                setRoute(CustomerConsoleAppUrlHelper.getConfigurationIntegrationsCloudProviderTenantOrganizationsAwsRelativeUrl(true));
                            }
                        },
                        localization.addOrganization.title(),
                        {
                            confirmOptions:
                                _.isEmpty(masterTenantModels)
                                    ? { message: localization.addOrganization.confirmTitle() }
                                    : undefined
                        })
                ],
            addTitle: localization.addTenantTitle(),
            element: AddOrEdit,
            welcome:
                _(activeScopeNodeMap).
                    filter(
                        scopeNode =>
                            !ScopeHelper.isRootFolder(scopeNode.scopeNodeModel) &&
                            scopeNode.scopeNodeModel.configuration.id !== ScopeHelper.customerId).
                    isEmpty()
                    ? <Welcome/>
                    : undefined
        }),
        [activeScopeNodeMap, masterTenantModels]);
}