import { InlineItems, map, TimeSpanHelper, useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import React from "react";
import { AuditEventDefinitionDetailItem } from "../../..";
import { Contract } from "../../../../../../../../../../../common";

export function useGetScopeSettingDeletionAuditEventDetailsItems() {
    function getTimeFrameDetailsItem(
        timeFrame: string,
        title: string) {
        return new AuditEventDefinitionDetailItem(
            title,
            localization.days(
                TimeSpanHelper.getDays(timeFrame),
                {
                    count: TimeSpanHelper.getDays(timeFrame)
                }));
    }

    const localization =
        useLocalization(
            "views.customer.auditEvents.profile.hooks.useDefinition.hooks.useScopeSettingAuditEventDefinition.hooks.useGetScopeSettingDeletionAuditEventDetailsItems",
            () => ({
                aadSeverePermissionDirectoryRoleDefinitions: {
                    roleDefinition: {
                        name: "Name",
                        templateId: "Template Id"
                    },
                    roleDefinitions: [
                        "{{count | NumberFormatter.humanize}} privileged role definition",
                        "{{count | NumberFormatter.humanize}} privileged role definitions"
                    ],
                    title: "Role Definitions"
                },
                days: [
                    "{{count | NumberFormatter.humanize}} day",
                    "{{count | NumberFormatter.humanize}} days"
                ],
                serviceIdentityExcessivePermissionEvaluationTimeFrame: "Learning period",
                userExcessivePermissionEvaluationTimeFrame: "Learning period"
            }));

    return (data: Contract.ScopeSettingDeletionAuditEventData) =>
        map(
            data.typeName,
            {
                [Contract.TypeNames.AadSeverePermissionDirectoryRoleDefinitionsDeletionAuditEventData]:
                    () => {
                        const directoryRoleDefinitionDatas = (data as Contract.AadSeverePermissionDirectoryRoleDefinitionsDeletionAuditEventData).directoryRoleDefinitionDatas;
                        return [
                            new AuditEventDefinitionDetailItem(
                                localization.aadSeverePermissionDirectoryRoleDefinitions.title(),
                                localization.aadSeverePermissionDirectoryRoleDefinitions.roleDefinitions(
                                    directoryRoleDefinitionDatas.length,
                                    {
                                        count: directoryRoleDefinitionDatas.length
                                    }),
                                <InlineItems
                                    items={directoryRoleDefinitionDatas}
                                    variant="itemPlusItemCount">
                                    {directoryRoleDefinitionData =>
                                        <Typography component="span">
                                            {`${directoryRoleDefinitionData.name}: ${directoryRoleDefinitionData.templateId}`}
                                        </Typography>}
                                </InlineItems>)];
                    },
                [Contract.TypeNames.ServiceIdentityExcessivePermissionEvaluationTimeFrameDeletionAuditEventData]:
                    () => [
                        getTimeFrameDetailsItem(
                            (data as Contract.ServiceIdentityExcessivePermissionEvaluationTimeFrameDeletionAuditEventData).timeFrame,
                            localization.serviceIdentityExcessivePermissionEvaluationTimeFrame())],
                [Contract.TypeNames.UserExcessivePermissionEvaluationTimeFrameDeletionAuditEventData]:
                    () => [
                        getTimeFrameDetailsItem(
                            (data as Contract.UserExcessivePermissionEvaluationTimeFrameDeletionAuditEventData).timeFrame,
                            localization.userExcessivePermissionEvaluationTimeFrame())]
            });
}