import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, DeferredFilter, EnumValuesFilter, TimeFormatter, TimeRangeFilter, useLocalization } from "@infrastructure";
import { Dictionary } from "lodash";
import React from "react";
import { useAwsCommonEncryptedResourceDefinition, useAwsCommonResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";

export function useAwsSsmParameterDefinition(definitionData: DefinitionData) {
    const parameterModelFiltersPromise = definitionData.entityModelFiltersPromise as Promise<Contract.AwsSsmParameterModelFilters>;
    const commonEncryptedResourceDefinition = useAwsCommonEncryptedResourceDefinition(definitionData);
    const commonResourceDefinition = useAwsCommonResourceDefinition(definitionData);

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.aws.useAwsSsmParameterDefinition",
            () => ({
                columns: {
                    type: {
                        title: "Parameter Type",
                        [Contract.TypeNames.AwsSsmParameterType]: {
                            [Contract.AwsSsmParameterType.SecureString]: "SecureString",
                            [Contract.AwsSsmParameterType.String]: "String",
                            [Contract.AwsSsmParameterType.StringList]: "StringList"
                        }
                    },
                    updateIdentity: "Updated By",
                    updateTime: "Update Time"
                }
            }));
    return new EntityTableDefinition(
        [
            commonResourceDefinition.columns.tenantColumn,
            commonResourceDefinition.columns.creatorIdentityCsvColumn,
            commonResourceDefinition.columns.creatorOriginatorEntityCsvColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsSsmParameterModel) => ({
                            [localization.columns.updateTime()]: TimeFormatter.iso8601String((item.entity as Contract.AwsSsmParameter).updateTime)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <DeferredFilter
                                promiseOrGetPromise={parameterModelFiltersPromise}
                                title={localization.columns.updateTime()}>
                                {filters =>
                                    <TimeRangeFilter
                                        emptyValue={filters.updateTimeRange.emptyValue}
                                        placeholder={localization.columns.updateTime()}
                                        timeRange={TimeRangeHelper.getTimeRangeFilterRange(filters.updateTimeRange.timeRange)}/>}
                            </DeferredFilter>
                    }
                }}
                id={Contract.EntityModelProperty.AwsSsmParameterUpdateTime}
                itemProperty={(item: Contract.EntityModel) => TimeFormatter.monthDayAndYear((item.entity as Contract.AwsSsmParameter).updateTime)}
                key={Contract.EntityModelProperty.AwsSsmParameterUpdateTime}
                sortOptions={{ type: DataTableSortType.Date }}
                title={localization.columns.updateTime()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsSsmParameterModel) => ({
                            [localization.columns.updateIdentity()]: (item.entity as Contract.AwsSsmParameter).updateIdentityReference.arn
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AwsSsmParameterUpdateIdentity)}
                                placeholder={localization.columns.updateIdentity()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AwsSsmParameterUpdateIdentity}
                key={Contract.EntityModelProperty.AwsSsmParameterUpdateIdentity}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AwsSsmParameterModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={(item.entity as Contract.AwsSsmParameter).updateIdentityReference.idReference}
                            entityTypeName={Contract.TypeNames.AwsIamIdentity}
                            entityVariant="iconText"/>}
                title={localization.columns.updateIdentity()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsSsmParameterModel) => ({
                            [localization.columns.type.title()]: localization.columns.type[Contract.TypeNames.AwsSsmParameterType][(item.entity as Contract.AwsSsmParameter).type]()
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.AwsSsmParameterType}
                                enumTypeTranslator={(type: Contract.AwsSsmParameterType) => localization.columns.type[Contract.TypeNames.AwsSsmParameterType][type]()}
                                placeholder={localization.columns.type.title()}
                                sorted={false}/>
                    }
                }}
                id={Contract.EntityModelProperty.AwsSsmParameterType}
                itemProperty={(item: Contract.EntityModel) => localization.columns.type[Contract.TypeNames.AwsSsmParameterType][(item.entity as Contract.AwsSsmParameter).type]()}
                key={Contract.EntityModelProperty.AwsSsmParameterType}
                title={localization.columns.type.title()}/>,
            commonEncryptedResourceDefinition.columns.encryptionExistsColumn,
            commonEncryptedResourceDefinition.columns.kmsEncryptionKeyColumn,
            commonResourceDefinition.columns.tagsColumn,
            commonResourceDefinition.columns.attributesColumn,
            commonResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAwsSsmParameterRequest(
                new Contract.EntityControllerGetEntityModelPageAwsSsmParameterRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsSsmParameterType]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsSsmParameterUpdateIdentity]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.AwsSsmParameterUpdateTime])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags])),
                new Contract.EntityControllerGetEntityModelPageAwsResourceRequestEncrypted(
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEncryptedResourceEncryptionExists]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEncryptedResourceKmsEncryptionKeys]))));
}