import { clearOperation, executeOperation, Optional, useAsyncEffect, useSyncContext } from "@infrastructure";
import { Function0 } from "lodash";
import React, { createContext, ReactNode, useContext, useMemo, useRef, useState } from "react";
import { Cache, CacheKey } from "../utilities";

const globalOperationKeyToPersistentResultCache = new Cache<any | undefined>();

export function clearPersistentResults() {
    globalOperationKeyToPersistentResultCache.removeAll();
}

const executeOperationContext = createContext<Optional<Cache<any | undefined>>>(undefined);

export function useExecuteOperation<TResult>(
    key: CacheKey,
    operation: Function0<Promise<TResult>>,
    options?: ExecuteOperationOptions):
    [TResult, Function0<Promise<void>>] {
    const currentOptions: ExecuteOperationOptions = {
        persistenceStrategy: "none",
        ...options
    };

    const operationKeyToPersistentResultCache = useContext(executeOperationContext) ?? globalOperationKeyToPersistentResultCache;
    const componentMountedRef = useRef(false);
    const operationCachedResult =
        operationKeyToPersistentResultCache.has(key)
            ? operationKeyToPersistentResultCache.get(key)
            : componentMountedRef.current
                ? undefined
                : executeOperation(key, operation);

    useAsyncEffect(
        async () => {
            componentMountedRef.current = true;

            clearOperation(key);

            switch (currentOptions.persistenceStrategy) {
                case "always":
                    operationKeyToPersistentResultCache.set(key, operationCachedResult);
                    break;

                case "updateOnMount":
                    if (operationKeyToPersistentResultCache.has(key)) {
                        const nextResult = await operation();
                        operationKeyToPersistentResultCache.set(key, nextResult);
                    } else {
                        operationKeyToPersistentResultCache.set(key, operationCachedResult);
                    }
                    break;
            }
        },
        []);

    const [operationResult, setOperationResult] = useState<TResult>(operationCachedResult!);
    const operationSyncContext = useSyncContext();
    const refreshOperation =
        async () => {
            const syncContext = operationSyncContext.create();
            const operationResult = await operation();
            if (operationSyncContext.isActive(syncContext)) {
                setOperationResult(operationResult);
            }
        };

    return [operationResult, refreshOperation];
}

export type ExecuteOperationOptions = {
    persistenceStrategy: "always" | "none" | "updateOnMount";
};


type ExecuteOperationCacheProps = {
    children: ReactNode;
};

export function ExecuteOperationCache({ children }: ExecuteOperationCacheProps) {
    const operationKeyToPersistentResultCache =
        useMemo(
            () => new Cache<any | undefined>(),
            []);

    return (
        <executeOperationContext.Provider value={operationKeyToPersistentResultCache}>
            {children}
        </executeOperationContext.Provider>);
}