﻿import React from "react";
import { useLocalization } from "../hooks";
import { InlineItems } from "./InlineItems";

type InlineSecretsProps = {
    secrets: string[];
};

export function InlineSecrets({ secrets }: InlineSecretsProps) {
    const localization =
        useLocalization(
            "infrastructure.inlineSecrets",
            () => ({
                secrets: [
                    "1 secret",
                    "{{count | NumberFormatter.humanize}} secrets"
                ]
            }));
    return <InlineItems
        items={secrets}
        namePluralizer={localization.secrets}
        variant="itemCountAndType"/>;
}