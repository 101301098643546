﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, InfoCard, InfoItem, VerticalTopItemsInfoItem } from "../../../../../../../../../../../../common";
import { useAzureContainerServiceManagedClusterNetworkTypeTranslator } from "../../../../../../../../hooks";
import { EntitiesInfoItem } from "../../../../../../components";

type ManagedClusterNetworkingInfoCardProps = {
    managedCluster: Contract.AzureContainerServiceManagedCluster;
};

export function ManagedClusterNetworkingInfoCard({ managedCluster }: ManagedClusterNetworkingInfoCardProps) {
    const containerServiceManagedClusterNetworkTypeTranslator = useAzureContainerServiceManagedClusterNetworkTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.azure.useAzureContainerServiceManagedClusterDefinition.managedClusterNetworkingInfoCard",
            () => ({
                sections: {
                    dnsName: "DNS Name",
                    network: {
                        authorizedSubnets: "Authorized IP Address Ranges",
                        authorizedSubnetsEnabled: {
                            false: "Disabled",
                            title: "Authorized IP Addresses",
                            true: "Enabled"
                        },
                        inboundExternalAccess: {
                            false: "Enabled",
                            title: "Private Cluster",
                            true: "Disabled"
                        },
                        loadBalancerIds: "Load Balancers",
                        podInternalSubnet: "Pod IP Address Range",
                        serviceSubnet: "Services IP Address Range",
                        type: "Network Plugin",
                        virtualNetworkId: "Virtual Network"
                    }
                },
                title: "Network"
            }));
    return (
        <InfoCard
            columns={true}
            title={localization.title()}>
            <EntitiesInfoItem
                entityIdsOrModels={managedCluster.network.virtualNetworkId}
                entityTypeName={Contract.TypeNames.AzureNetworkVirtualNetwork}
                key="networkVirtualNetworkId"
                title={localization.sections.network.virtualNetworkId()}/>
            <EntitiesInfoItem
                entityIdsOrModels={managedCluster.loadBalancerIds}
                entityTypeName={Contract.TypeNames.AzureNetworkLoadBalancer}
                key="loadBalancerIds"
                title={localization.sections.network.loadBalancerIds()}/>
            <InfoItem
                title={localization.sections.dnsName()}
                value={managedCluster.dnsName}/>
            <InfoItem
                title={localization.sections.network.inboundExternalAccess.title()}
                value={
                    managedCluster.network.inboundExternal
                        ? localization.sections.network.inboundExternalAccess.true()
                        : localization.sections.network.inboundExternalAccess.false()}/>
            <InfoItem
                title={localization.sections.network.authorizedSubnetsEnabled.title()}
                value={
                    _.isEmpty(managedCluster.network.authorizedSubnets)
                        ? localization.sections.network.authorizedSubnetsEnabled.false()
                        : localization.sections.network.authorizedSubnetsEnabled.true()}/>
            <VerticalTopItemsInfoItem
                items={managedCluster.network.authorizedSubnets}
                title={localization.sections.network.authorizedSubnets()}/>
            <InfoItem
                title={localization.sections.network.type()}
                value={containerServiceManagedClusterNetworkTypeTranslator(managedCluster.network.type)}/>
            <InfoItem
                title={localization.sections.network.podInternalSubnet()}
                value={managedCluster.network.podInternalSubnet}/>
            <InfoItem
                title={localization.sections.network.serviceSubnet()}
                value={managedCluster.network.serviceSubnet}/>
        </InfoCard>);
}