import { map, ValuesFilterSelection } from "@infrastructure";
import _ from "lodash";
import { Contract } from "../../../../../../../../../../../controllers";
import { CustomerConsoleAppUrlHelper, CustomerConsoleAppUrlHelperGetEntitiesRelativeUrlFilters, CustomerConsoleAppUrlHelperGetEntitiesRelativeUrlFiltersSelection, EntityModelHelper, TypeHelper, ValueFilterKeys } from "../../../../../../../../../../../utilities";

export class RiskCombinationHelper {

    private static getFilters(entityTypeName: string, riskCombination: Contract.RiskCombination) {
        return map<string, Partial<CustomerConsoleAppUrlHelperGetEntitiesRelativeUrlFilters>>(
            riskCombination,
            {
                [Contract.RiskCombination.AwsContainerImageVulnerabilitySeverityCriticalInboundExternalEcsService]:
                    () => ({
                        awsEcrRepositoryContainerImageVulnerabilitySeverities: [Contract.Severity.Critical],
                        ...EntityModelHelper.getInboundExternalFilter()
                    }),
                [Contract.RiskCombination.AwsContainerImageVulnerabilitySeverityCriticalInboundExternalSeverePermissionActionEcsService]:
                    () => ({
                        awsEcrRepositoryContainerImageVulnerabilitySeverities: [Contract.Severity.Critical],
                        builtInAttributeTypeNames: [
                            Contract.TypeNames.AdministratorPrincipalAttribute,
                            Contract.TypeNames.SeverePermissionActionPrincipalAttribute
                        ],
                        ...EntityModelHelper.getInboundExternalFilter()
                    }),
                [Contract.RiskCombination.AwsContainerImageVulnerabilitySeverityCriticalSeverePermissionActionEcsService]:
                    () => ({
                        awsEcrRepositoryContainerImageVulnerabilitySeverities: [Contract.Severity.Critical],
                        builtInAttributeTypeNames: [
                            Contract.TypeNames.AdministratorPrincipalAttribute,
                            Contract.TypeNames.SeverePermissionActionPrincipalAttribute
                        ]
                    }),
                [Contract.RiskCombination.AwsInboundExternalSeverePermissionActionEcsService]:
                    () => ({
                        builtInAttributeTypeNames: [
                            Contract.TypeNames.AdministratorPrincipalAttribute,
                            Contract.TypeNames.SeverePermissionActionPrincipalAttribute
                        ],
                        ...EntityModelHelper.getInboundExternalFilter()
                    }),
                [Contract.RiskCombination.AwsMfaDisabledSeverePermissionActionIamUser]:
                    () => ({
                        awsIamUserMfaEnabled: [false],
                        awsIamUserPasswordEnabled: [true],
                        builtInAttributeTypeNames: [
                            Contract.TypeNames.AdministratorPrincipalAttribute,
                            Contract.TypeNames.SeverePermissionActionPrincipalAttribute
                        ]
                    }),
                [Contract.RiskCombination.AwsPublicAccessEncryptionDisabledDataResource]:
                    () => ({
                        awsEncryptedResourceEncryptionExists: [false],
                        awsResourceAccessLevels:
                            TypeHelper.extendOrImplement(entityTypeName, Contract.TypeNames.IAwsAccessResource)
                                ? [Contract.AwsResourceAccessLevel.Public]
                                : undefined,
                        ...EntityModelHelper.getInboundExternalFilter()
                    }),
                [Contract.RiskCombination.AzureInboundExternalSeverePermissionActionWebApplication]:
                    () => ({
                        authenticationTypes: [
                            Contract.AzureWebApplicationAuthenticationType.Disabled,
                            Contract.AzureWebApplicationAuthenticationType.Optional
                        ],
                        builtInAttributeTypeNames: [
                            Contract.TypeNames.AdministratorPrincipalAttribute,
                            Contract.TypeNames.SeverePermissionActionPrincipalAttribute
                        ],
                        ...EntityModelHelper.getInboundExternalFilter()
                    }),
                [Contract.RiskCombination.AzureInboundExternalSharedKeyAccessStorageStorageAccount]:
                    () => ({
                        azureStorageStorageAccountSharedKeyAccess: [true],
                        ...EntityModelHelper.getInboundExternalFilter()
                    }),
                [Contract.RiskCombination.GcpContainerImageVulnerabilitySeverityCriticalPublicAccessCloudRunService]:
                    () => ({
                        gcpCloudRunServiceContainerImageVulnerabilitySeverities: [Contract.Severity.Critical],
                        gcpScopeResourceAccessLevels: [Contract.GcpScopeResourceAccessLevel.Public]
                    }),
                [Contract.RiskCombination.GcpContainerImageVulnerabilitySeverityCriticalPublicAccessSeverePermissionActionCloudRunService]:
                    () => ({
                        builtInAttributeTypeNames: [
                            Contract.TypeNames.AdministratorPrincipalAttribute,
                            Contract.TypeNames.SeverePermissionActionPrincipalAttribute
                        ],
                        gcpCloudRunServiceContainerImageVulnerabilitySeverities: [Contract.Severity.Critical],
                        gcpScopeResourceAccessLevels: [Contract.GcpScopeResourceAccessLevel.Public]
                    }),
                [Contract.RiskCombination.GcpContainerImageVulnerabilitySeverityCriticalSeverePermissionActionAppEngineApplicationService]:
                    () => ({
                        builtInAttributeTypeNames: [
                            Contract.TypeNames.AdministratorPrincipalAttribute,
                            Contract.TypeNames.SeverePermissionActionPrincipalAttribute
                        ],
                        gcpAppEngineApplicationServiceContainerImageVulnerabilitySeverities: [Contract.Severity.Critical]
                    }),
                [Contract.RiskCombination.GcpContainerImageVulnerabilitySeverityCriticalSeverePermissionActionCloudRunService]:
                    () => ({
                        builtInAttributeTypeNames: [
                            Contract.TypeNames.AdministratorPrincipalAttribute,
                            Contract.TypeNames.SeverePermissionActionPrincipalAttribute
                        ],
                        gcpCloudRunServiceContainerImageVulnerabilitySeverities: [Contract.Severity.Critical]
                    }),
                [Contract.RiskCombination.InboundExternalOperatingSystemEndOfLifeWorkloadResource]:
                    () => ({
                        builtInAttributeTypeNames: [Contract.TypeNames.WorkloadResourceOperatingSystemEndOfLifeAttribute],
                        ...EntityModelHelper.getInboundExternalFilter()
                    }),
                [Contract.RiskCombination.InboundExternalOperatingSystemUnpatchedWorkloadResource]:
                    () => ({
                        builtInAttributeTypeNames: [Contract.TypeNames.WorkloadResourceOperatingSystemUnpatchedAttribute],
                        ...EntityModelHelper.getInboundExternalFilter()
                    }),
                [Contract.RiskCombination.InboundExternalSensitiveVirtualMachine]:
                    () => ({
                        builtInAttributeTypeNames: [Contract.TypeNames.SensitiveResourceAttribute],
                        ...EntityModelHelper.getInboundExternalFilter()
                    }),
                [Contract.RiskCombination.InboundExternalSeverePermissionActionVirtualMachine]:
                    () => ({
                        builtInAttributeTypeNames: [
                            Contract.TypeNames.AdministratorPrincipalAttribute,
                            Contract.TypeNames.SeverePermissionActionPrincipalAttribute
                        ],
                        ...EntityModelHelper.getInboundExternalFilter()
                    }),
                [Contract.RiskCombination.InboundExternalSevereVulnerabilitySeverePermissionActionWorkloadResource]:
                    () => ({
                        builtInAttributeTypeNames: [
                            Contract.TypeNames.AdministratorPrincipalAttribute,
                            Contract.TypeNames.SeverePermissionActionPrincipalAttribute
                        ],
                        vulnerabilitySeverities: [Contract.Severity.Critical],
                        ...EntityModelHelper.getInboundExternalFilter()
                    }),
                [Contract.RiskCombination.InboundExternalSevereVulnerabilityWorkloadResource]:
                    () => ({
                        vulnerabilitySeverities: [Contract.Severity.Critical],
                        ...EntityModelHelper.getInboundExternalFilter()
                    }),
                [Contract.RiskCombination.PublicAccessSensitiveDataResource]:
                    () => ({
                        awsResourceAccessLevels:
                            TypeHelper.extendOrImplement(entityTypeName, Contract.TypeNames.IAwsAccessResource)
                                ? [Contract.AwsResourceAccessLevel.Public]
                                : undefined,
                        builtInAttributeTypeNames: [
                            Contract.TypeNames.ApplicationPciDataResourceAttribute,
                            Contract.TypeNames.ApplicationPhiDataResourceAttribute,
                            Contract.TypeNames.ApplicationPiiDataResourceAttribute,
                            Contract.TypeNames.ApplicationSecretsDataResourceAttribute,
                            Contract.TypeNames.SensitiveResourceAttribute
                        ],
                        gcpScopeResourceAccessLevels:
                            TypeHelper.extendOrImplement(entityTypeName, Contract.TypeNames.GcpScopeResource)
                                ? [Contract.GcpScopeResourceAccessLevel.Public]
                                : undefined
                    }),
                [Contract.RiskCombination.PublicAccessSeverePermissionActionComputeFunction]:
                    () => ({
                        awsResourceAccessLevels:
                            entityTypeName === Contract.TypeNames.AwsLambdaFunctionConfiguration
                                ? [Contract.AwsResourceAccessLevel.Public]
                                : undefined,
                        builtInAttributeTypeNames: [
                            Contract.TypeNames.AdministratorPrincipalAttribute,
                            Contract.TypeNames.SeverePermissionActionPrincipalAttribute
                        ],
                        gcpScopeResourceAccessLevels:
                            entityTypeName === Contract.TypeNames.GcpFunctionsFunction
                                ? [Contract.GcpScopeResourceAccessLevel.Public]
                                : undefined
                    }),
                [Contract.RiskCombination.SensitiveResourcePermissionActionExternalPrincipal]:
                    () => {
                        const builtInAttributeTypeNames =
                            TypeHelper.
                                getSealedAssignableTypeNames(Contract.TypeNames.DataResourcePermissionActionPrincipalAttribute).
                                concat(
                                    Contract.TypeNames.ApplicationPciDataResourcePermissionActionPrincipalAttribute,
                                    Contract.TypeNames.ApplicationPhiDataResourcePermissionActionPrincipalAttribute,
                                    Contract.TypeNames.ApplicationPiiDataResourcePermissionActionPrincipalAttribute,
                                    Contract.TypeNames.ApplicationSecretsDataResourcePermissionActionPrincipalAttribute,
                                    Contract.TypeNames.SensitiveResourcePermissionActionPrincipalAttribute);
                        return ({
                            aadDirectoryUserAuthenticationTypes:
                                entityTypeName === Contract.TypeNames.AadDirectoryUser
                                    ? [Contract.AadDirectoryUserAuthenticationType.External]
                                    : undefined,
                            builtInAttributeTypeNames
                        });
                    },
                [Contract.RiskCombination.SensitiveResourcePermissionActionGuestUser]:
                    () => {
                        const builtInAttributeTypeNames =
                            TypeHelper.
                                getSealedAssignableTypeNames(Contract.TypeNames.DataResourcePermissionActionPrincipalAttribute).
                                concat(
                                    Contract.TypeNames.ApplicationPciDataResourcePermissionActionPrincipalAttribute,
                                    Contract.TypeNames.ApplicationPhiDataResourcePermissionActionPrincipalAttribute,
                                    Contract.TypeNames.ApplicationPiiDataResourcePermissionActionPrincipalAttribute,
                                    Contract.TypeNames.ApplicationSecretsDataResourcePermissionActionPrincipalAttribute,
                                    Contract.TypeNames.SensitiveResourcePermissionActionPrincipalAttribute);
                        return ({
                            aadDirectoryUserTypes:
                                entityTypeName === Contract.TypeNames.AadDirectoryUser
                                    ? [Contract.AadDirectoryUserType.Guest]
                                    : undefined,
                            builtInAttributeTypeNames
                        });
                    },
                [Contract.RiskCombination.SensitiveResourcePermissionActionVendorServiceIdentity]:
                    () => {
                        const builtInAttributeTypeNames =
                            TypeHelper.
                                getSealedAssignableTypeNames(Contract.TypeNames.DataResourcePermissionActionPrincipalAttribute).
                                concat(
                                    Contract.TypeNames.ApplicationPciDataResourcePermissionActionPrincipalAttribute,
                                    Contract.TypeNames.ApplicationPhiDataResourcePermissionActionPrincipalAttribute,
                                    Contract.TypeNames.ApplicationPiiDataResourcePermissionActionPrincipalAttribute,
                                    Contract.TypeNames.ApplicationSecretsDataResourcePermissionActionPrincipalAttribute,
                                    Contract.TypeNames.SensitiveResourcePermissionActionPrincipalAttribute);
                        return ({
                            aadDirectoryServicePrincipalVendor:
                                entityTypeName === Contract.TypeNames.AadDirectoryApplicationServicePrincipal
                                    ? true
                                    : undefined,
                            awsIamRoleVendor:
                                entityTypeName === Contract.TypeNames.AwsIamRole
                                    ? true
                                    : undefined,
                            builtInAttributeTypeNames
                        });
                    },
                [Contract.RiskCombination.SeverePermissionActionExternalPrincipal]:
                    () => ({
                        aadDirectoryUserAuthenticationTypes:
                            entityTypeName === Contract.TypeNames.AadDirectoryUser
                                ? [Contract.AadDirectoryUserAuthenticationType.External]
                                : undefined,
                        builtInAttributeTypeNames: [
                            Contract.TypeNames.AdministratorPrincipalAttribute,
                            Contract.TypeNames.SeverePermissionActionPrincipalAttribute
                        ]
                    }),
                [Contract.RiskCombination.SeverePermissionActionGuestUser]:
                    () => ({
                        aadDirectoryUserTypes:
                            entityTypeName === Contract.TypeNames.AadDirectoryUser
                                ? [Contract.AadDirectoryUserType.Guest]
                                : undefined,
                        builtInAttributeTypeNames: [
                            Contract.TypeNames.AdministratorPrincipalAttribute,
                            Contract.TypeNames.SeverePermissionActionPrincipalAttribute
                        ]
                    }),
                [Contract.RiskCombination.SevereVulnerabilitySeverePermissionActionWorkloadResource]:
                    () => ({
                        builtInAttributeTypeNames: [
                            Contract.TypeNames.AdministratorPrincipalAttribute,
                            Contract.TypeNames.SeverePermissionActionPrincipalAttribute
                        ],
                        vulnerabilitySeverities: [Contract.Severity.Critical]
                    })
            });
    }

    public static getEntityTypeNameRelativeUrl(entityTypeName: string, riskCombination: Contract.RiskCombination) {
        const valueFilterKeysArray: ValueFilterKeys[] = ["aadDirectoryServicePrincipalVendor", "awsIamRoleVendor"];
        return CustomerConsoleAppUrlHelper.getEntitiesRelativeUrl(
            entityTypeName,
            _.mapValues(
                RiskCombinationHelper.getFilters(entityTypeName, riskCombination),
                (value, key) =>
                    _.isNil(value)
                        ? undefined
                        : _.includes(valueFilterKeysArray, key)
                            ? value!
                            : new ValuesFilterSelection(false, value as any)) as CustomerConsoleAppUrlHelperGetEntitiesRelativeUrlFiltersSelection);
    }
}