import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition, useGetAzureKeyVaultVaultRiskContext } from "../../..";
import { Contract, Entity, entityModelStore, useTheme } from "../../../../../../../../../../../../common";
import { RiskDefinitionSection } from "../../../../../../utilities";
import { EntityExternalConsoleLink, FirewallRuleSubnetsDetailsSection } from "../../components";

export function useAzureKeyVaultVaultWideRangeInboundRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AzureKeyVaultVaultWideRangeInboundRisk;
    const vaultModel = entityModelStore.useGet(risk.entityId) as Contract.AzureKeyVaultVaultModel;
    const vault = vaultModel.entity as Contract.AzureKeyVaultVault;

    const getAzureKeyVaultVaultRiskContext = useGetAzureKeyVaultVaultRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.compliance.useAzureKeyVaultVaultWideRangeInboundRiskDefinition",
            () => ({
                description: "{{vault}} firewall allows unrestricted network inbound access",
                sections: {
                    firewallRules: "Firewall Rules",
                    resolution: {
                        step1: "Switch the **Allow public access from all networks** toggle to **Allow public access from specific virtual networks and IP addresses**",
                        step2: "If any Azure resources require access to the key vault, add the virtual network of the resource to the firewall rules",
                        step3: "For each public IP address rule, edit the address range to restrict it only to the required IP addresses or delete it",
                        step4: "Click **Save** button on top to confirm"
                    }
                }
            }));

    const theme = useTheme();
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            vault:
                <Entity
                    entityIdOrModel={vaultModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={risk.entityId}
                key={risk.entityId}
                page={Contract.AzureConsoleEntityPage.Networking}/>,
            vault.allNetworkAccess
                ? localization.sections.resolution.step1()
                : undefined,
            localization.sections.resolution.step2(),
            _.isEmpty(vault.firewallRuleSubnets)
                ? undefined
                : localization.sections.resolution.step3(),
            localization.sections.resolution.step4()
        ],
        riskModel,
        () => {
            const vaultRiskContext = getAzureKeyVaultVaultRiskContext(vaultModel);
            return [
                vaultRiskContext.generalInformation,
                vaultRiskContext.sensitive,
                vaultRiskContext.allNetworkAccess,
                vaultRiskContext.wideRangeInboundSubnets,
                vaultRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        },
        {
            sections:
                _.isEmpty(vaultModel.wideRangeFirewallRuleSubnets)
                    ? []
                    : [
                        new RiskDefinitionSection(
                            <FirewallRuleSubnetsDetailsSection
                                getHighlightColor={
                                    (subnet: string, opacity) =>
                                        _.includes(vaultModel.wideRangeFirewallRuleSubnets, subnet)
                                            ? opacity
                                                ? theme.palette.opacity(theme.palette.severity(riskModel.risk.severity), opacity)
                                                : theme.palette.severity(riskModel.risk.severity)
                                            : undefined
                                }
                                subnets={vault.firewallRuleSubnets}/>,
                            localization.sections.firewallRules())
                    ]
        });
}