import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function EntityNetworkIcon(props: SvgIconProps) {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <path
                clipRule="evenodd"
                d="M14.5 4H9.5L9.5 7H14.5V4ZM9.5 2C8.39543 2 7.5 2.89543 7.5 4V7C7.5 8.10457 8.39543 9 9.5 9H14.5C15.6046 9 16.5 8.10457 16.5 7V4C16.5 2.89543 15.6046 2 14.5 2H9.5Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M9 17H4L4 20H9V17ZM4 15C2.89543 15 2 15.8954 2 17V20C2 21.1046 2.89543 22 4 22H9C10.1046 22 11 21.1046 11 20V17C11 15.8954 10.1046 15 9 15H4Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M20 17H15L15 20H20V17ZM15 15C13.8954 15 13 15.8954 13 17V20C13 21.1046 13.8954 22 15 22H20C21.1046 22 22 21.1046 22 20V17C22 15.8954 21.1046 15 20 15H15Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M6.56006 12C7.11234 12 7.56006 12.4477 7.56006 13V15C7.56006 15.5523 7.11234 16 6.56006 16C6.00777 16 5.56006 15.5523 5.56006 15V13C5.56006 12.4477 6.00777 12 6.56006 12Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M17.5 12C18.0523 12 18.5 12.4477 18.5 13L18.5 15C18.5 15.5523 18.0523 16 17.5 16C16.9477 16 16.5 15.5523 16.5 15L16.5 13C16.5 12.4477 16.9477 12 17.5 12Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M2 12C2 11.4477 2.44772 11 3 11L21 11C21.5523 11 22 11.4477 22 12C22 12.5523 21.5523 13 21 13L3 13C2.44772 13 2 12.5523 2 12Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M11 11V9H13V11H11Z"
                fillRule="evenodd"/>
        </SvgIcon>);
}