import { UnexpectedError } from "@infrastructure";
import { useCallback } from "react";
import { Contract } from "../../../../../../../../common";
import { useGetCiTranslator, useGetCodeTranslator, useGetCustomerLicenseObjectTenantType, useGetVersion1Translator, useGetVersion2Translator } from "./hooks";

export function useGetCustomerLicenseObjectInfo() {
    const getCustomerLicenseObjectTenantType = useGetCustomerLicenseObjectTenantType();
    const getVersion1Translator = useGetVersion1Translator();
    const getVersion2Translator = useGetVersion2Translator();
    const getCiTranslator = useGetCiTranslator();
    const getCodeTranslator = useGetCodeTranslator();
    const translator =
        useCallback(
            (objectType: Contract.CustomerLicenseObjectType, version: Contract.ApplicationCustomerConfigurationLicensingLicenseVersion, tenantType: Contract.TenantType, snapshotDate?: string) => {
                if (tenantType === Contract.TenantType.Ci) {
                    return getCiTranslator();
                }
                if (tenantType === Contract.TenantType.Code) {
                    return getCodeTranslator();
                }
                switch (version) {
                    case Contract.ApplicationCustomerConfigurationLicensingLicenseVersion.Version1:
                        return getVersion1Translator(objectType);
                    case Contract.ApplicationCustomerConfigurationLicensingLicenseVersion.Version2:
                        return getVersion2Translator(objectType, tenantType, snapshotDate);
                    default:
                        throw new UnexpectedError("version", version);
                }
            },
            []);

    return (objectType: Contract.CustomerLicenseObjectType, version: Contract.ApplicationCustomerConfigurationLicensingLicenseVersion, snapshotDate?: string): CustomerLicenceObjectInfo => {
        const tenantType = getCustomerLicenseObjectTenantType(objectType);

        return {
            tenantType,
            ...translator(
                objectType,
                version,
                tenantType,
                snapshotDate)
        };
    };
}

type CustomerLicenceObjectInfo =
    CustomerLicenceObjectTranslationInfo & {
        tenantType: Contract.TenantType;
    };

export type CustomerLicenceObjectTranslationInfo = {
    link?: string;
    title: string;
};