import { Box, Button, Stack, Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ActionMenuItem, AddIcon, DataTable, DataTableAction, DataTableActions, DataTableColumn, DataTableColumnRenderProps, DataTableFetchItemsResult, DataTableFiltersOptions, DataTableSort, DataTableSortDirection, EmptyMessageText, Menu, NoneIcon, Optional, SelectionActionsAction, StringHelper, TextValuesFilter, ToggleFilter, useChangeEffect, useLocalization } from "@infrastructure";
import { Contract, CustomerConsoleAppUrlHelper, Scope, ScopeHelper, ScopeNode, scopeNodeModelStore, StorageHelper, TenantHelper, UserHelper, useScopeNavigationViewContext, useTheme } from "../../../../../../common";
import { useScopesContext } from "../../Scopes";
import { ScopesView } from "../../utilities";
import { ActionsCell } from "../ActionsCell";
import { DeleteSelectionAction, MoveSelectionAction } from "./components";

type TableProps = {
    menuItems?: ActionMenuItem[];
    onEditClick?: (item: Contract.ScopeNodeModel) => void;
};

const TableMemo = memo(Table);
export { TableMemo as Table };

function Table({ menuItems, onEditClick }: TableProps) {
    const configurationTenantTypes =
        useMemo(
            () => TenantHelper.ConfigurationTenantTypes,
            []);

    const { definition, view } = useScopesContext();
    const isProject = view === ScopesView.Project;
    const scopeNodeMap =
        scopeNodeModelStore.useGetScopeNodeMap(
            isProject
                ? undefined
                : configurationTenantTypes,
            isProject);

    const { scopeNodeModel, setRoute } = useScopeNavigationViewContext();
    const [filterMap, setFilterMap] = useState<Dictionary<any>>({});

    const childScopeTenantsEnabled =
        useMemo(
            () =>
                filterMap[TableColumnId.ChildScopeTenantsEnabled] ??
                StringHelper.
                    isTrue(
                        StorageHelper.
                            customerScopesTableFlatView(view).
                            getValue()),
            [filterMap]);
    const permittedScopeNodeModels =
        useMemo(
            () => {
                const scopeNodeModels =
                    _.filter(
                        _.filter(
                            childScopeTenantsEnabled
                                ? _(scopeNodeMap[scopeNodeModel.configuration.id].scopeIds).
                                    map(scopeId => scopeNodeMap[scopeId].scopeNodeModel).
                                    filter(scopeNodeModel => !ScopeHelper.isFolder(scopeNodeModel)).
                                    value()
                                : _.map(
                                    scopeNodeMap[scopeNodeModel.configuration.id].scopeNodes,
                                    scopeNode => scopeNode.scopeNodeModel)),
                        scopeNodeModel =>
                            _([scopeNodeModel.configuration.id]).
                                concat(scopeNodeMap[scopeNodeModel.configuration.id].scopeIds).
                                some(scopeId => UserHelper.hasScopePermissions(scopeId, Contract.IdentityPermission.SecurityAdministrationRead)));
                return definition.filterItems?.(scopeNodeModels) ?? scopeNodeModels;
            },
            [childScopeTenantsEnabled, definition, scopeNodeModel, scopeNodeMap]);

    const filteredScopeNodeModels =
        useMemo(
            () =>
                _.filter(
                    permittedScopeNodeModels,
                    permittedScopeNodeModel => {
                        if (!_.isNil(filterMap[TableColumnId.Name]) &&
                            !_.includes(filterMap[TableColumnId.Name].values, permittedScopeNodeModel.configuration.name)) {
                            return false;
                        }

                        return definition.filter(filterMap, permittedScopeNodeModel);
                    }),
            [definition, filterMap, permittedScopeNodeModels]);

    const getScopePath =
        useCallback(
            (scopeNodeModel: Contract.ScopeNodeModel) => ScopeHelper.getScopePath(scopeNodeModel, scopeNodeMap),
            [scopeNodeMap]);

    const getScopeNodeModels =
        useCallback(
            async (_filterMap: Dictionary<any>, sort: Optional<DataTableSort>, skip: number, limit: number) => {
                const pageScopeNodeModels =
                    _(filteredScopeNodeModels).
                        orderBy(
                            [
                                scopeNodeModel => ScopeHelper.isFolder(scopeNodeModel),
                                scopeNodeModel => {
                                    switch (sort?.columnId) {
                                        case undefined:
                                        case TableColumnId.Name:
                                            return StringHelper.getSortValue(scopeNodeModel.configuration.name);
                                        case TableColumnId.Path:
                                            return StringHelper.getSortValue(getScopePath(scopeNodeModel));
                                        default:
                                            return definition.sort(sort!.columnId, scopeNodeModel);
                                    }
                                }
                            ],
                            [
                                "desc",
                                sort?.direction === DataTableSortDirection.Descending
                                    ? "desc"
                                    : "asc"
                            ]).
                        drop(skip).
                        take(limit).
                        value();

                return new DataTableFetchItemsResult(
                    { count: filteredScopeNodeModels.length },
                    pageScopeNodeModels,
                    skip + pageScopeNodeModels.length === filteredScopeNodeModels.length,
                    {
                        itemIdToPermissionsMap:
                            skip === 0
                                ? _(filteredScopeNodeModels).
                                    keyBy(filteredScopeNodeModel => filteredScopeNodeModel.configuration.id).
                                    mapValues(() => []).
                                    value()
                                : undefined
                    });
            },
            [definition, filteredScopeNodeModels, getScopePath]);

    const dataTableActionsRef = useRef<DataTableActions>();
    useEffect(
        () => {
            dataTableActionsRef.current!.reset({
                refreshColumns: true,
                refreshFilters: true
            });
        },
        [permittedScopeNodeModels]);

    useChangeEffect(
        () => dataTableActionsRef.current!.setSelectedItemIds([]),
        [childScopeTenantsEnabled]);

    const localization =
        useLocalization(
            "views.customer.scopes.table",
            () => ({
                actions: {
                    add: "Add",
                    childScopeTenantsEnabled: "Flat View",
                    csvExport: {
                        fileNamePrefix: "{{title}}_{{scopeName}}"
                    }
                },
                columns: {
                    name: "Name",
                    path: "Scope"
                },
                empty: "No items",
                repositories: "repositories"
            }));

    const scopeNames =
        useMemo(
            () =>
                _(permittedScopeNodeModels).
                    map(permittedScopeNodeModel => permittedScopeNodeModel.configuration.name).
                    uniq().
                    value(),
            [permittedScopeNodeModels]);

    const definitionColumns =
        useMemo(
            () => definition.getColumns(permittedScopeNodeModels),
            [definition, permittedScopeNodeModels]);


    const filtersOptions =
        useMemo(
            (): DataTableFiltersOptions => ({
                initial: {
                    state:
                        childScopeTenantsEnabled
                            ? { [TableColumnId.ChildScopeTenantsEnabled]: childScopeTenantsEnabled }
                            : undefined
                },
                onChanged: setFilterMap,
                persist: {
                    visibilityStorageItem: StorageHelper.customerScopesTableFilters(view)
                }
            }),
            [childScopeTenantsEnabled, view]);

    function resetSelectedItemIds() {
        dataTableActionsRef.current!.setSelectedItemIds([]);
    }

    function onItemDelete(removeItemId: string) {
        dataTableActionsRef.current!.setSelectedItemIds(
            _.filter(
                dataTableActionsRef.current?.getSelectedItemIds(),
                selectedItemId => selectedItemId !== removeItemId));
    }

    const theme = useTheme();
    return (
        <Stack
            direction="row"
            sx={{ height: "100%" }}>
            <Box
                sx={{
                    flex: 1,
                    overflowX: "auto"
                }}>
                <DataTable
                    actionsRef={dataTableActionsRef}
                    columnOptions={{
                        orderOptions: {
                            enabled: true,
                            persistenceStorageItem: StorageHelper.customerScopesColumnOrder(view)
                        },
                        resizable: true,
                        selectorOptions: {
                            enabled: true,
                            persistenceStorageItem: StorageHelper.customerScopesColumnSelector(view)
                        },
                        stickyColumnId: TableColumnId.Name
                    }}
                    emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty()) }}
                    exportOptions={{
                        fileNamePrefix:
                            localization.actions.csvExport.fileNamePrefix({
                                scopeName: scopeNodeModel.configuration.name,
                                title: definition.title.replace(/\s+/g, "_")
                            })
                    }}
                    fetchItems={getScopeNodeModels}
                    filtersOptions={filtersOptions}
                    getItemId={(item: Contract.ScopeNodeModel) => item.configuration.id}
                    pageSize={20}
                    rowOptions={{
                        getUrl:
                            (item: Contract.ScopeNodeModel) =>
                                ScopeHelper.isFolder(item)
                                    ? undefined
                                    : CustomerConsoleAppUrlHelper.getScopeHashUrl(item.configuration.id)
                    }}
                    selectionOptions={{
                        persistence: true
                    }}>
                    <SelectionActionsAction id="move">
                        <MoveSelectionAction
                            resetSelectedItemIds={resetSelectedItemIds}
                            scopeNodeMap={scopeNodeMap}/>
                    </SelectionActionsAction>
                    <SelectionActionsAction id="delete">
                        <DeleteSelectionAction
                            resetSelectedItemIds={resetSelectedItemIds}
                            scopeNodeMap={scopeNodeMap}
                            {...(view === ScopesView.Code && {
                                scopeTypeName: localization.repositories()
                            })}/>
                    </SelectionActionsAction>
                    <DataTableColumn
                        cellSx={{ maxWidth: theme.spacing(44) }}
                        exportOptions={{
                            getItem:
                                (scopeNodeModel: Contract.ScopeNodeModel) => ({
                                    [localization.columns.name()]: scopeNodeModel.configuration.name
                                })
                        }}
                        filterOptions={{
                            itemOrItems: {
                                default: true,
                                element:
                                    <TextValuesFilter
                                        placeholder={localization.columns.name()}
                                        values={scopeNames}/>
                            }
                        }}
                        id={TableColumnId.Name}
                        key={TableColumnId.Name}
                        render={
                            ({ item }: DataTableColumnRenderProps<Contract.ScopeNodeModel>) =>
                                <Box
                                    {...(ScopeHelper.isFolder(item) && {
                                        onClick: () => setRoute(item.configuration.id),
                                        sx: {
                                            cursor: "pointer"
                                        }
                                    })}>
                                    <Scope
                                        scopeId={item.configuration.id}
                                        scopeNameTranslatorOptions={{
                                            tenantNameTranslatorOptions: {
                                                includeRawId: false
                                            }
                                        }}/>
                                </Box>}
                        selectorOptions={{ disabled: true }}
                        title={localization.columns.name()}/>
                    <DataTableColumn
                        exportOptions={{
                            getItem:
                                (scopeNodeModel: Contract.ScopeNodeModel) => ({
                                    [localization.columns.path()]: getScopePath(scopeNodeModel) ?? ""
                                })
                        }}
                        id={TableColumnId.Path}
                        key={TableColumnId.Path}
                        render={
                            ({ item }: DataTableColumnRenderProps<Contract.ScopeNodeModel>) =>
                                <ScopePath
                                    scopeNodeMap={scopeNodeMap}
                                    scopeNodeModel={item}/>}
                        title={localization.columns.path()}/>
                    {definitionColumns}
                    <DataTableColumn
                        exportOptions={{
                            getItem:
                                (scopeNodeModel: Contract.ScopeNodeModel) =>
                                    ScopeHelper.isTenant(scopeNodeModel) &&
                                    definition.getTenantAdditionalInfoItems
                                        ? _(definition.getTenantAdditionalInfoItems(scopeNodeModel)).
                                            keyBy(tenantAdditionalInfoItem => tenantAdditionalInfoItem.title).
                                            mapValues(
                                                tenantAdditionalInfoItem =>
                                                    _.isEmpty(tenantAdditionalInfoItem.valueOrValues)
                                                        ? ""
                                                        : _(tenantAdditionalInfoItem.valueOrValues).
                                                            concat().
                                                            join("\n")).
                                            value()
                                        : []
                        }}
                        id="tenantAdditionalInfoItems"
                        key="tenantAdditionalInfoItems"/>
                    <DataTableColumn
                        disableAction={true}
                        id={TableColumnId.Actions}
                        key={TableColumnId.Actions}
                        orderable={false}
                        render={
                            ({ item }: DataTableColumnRenderProps<Contract.ScopeNodeModel>) =>
                                <ActionsCell
                                    definition={definition}
                                    isProject={isProject}
                                    item={item}
                                    view={view}
                                    onDelete={onItemDelete}
                                    onEditClick={onEditClick}/>}
                        resizable={false}
                        selectorOptions={{ disabled: true }}/>
                    <DataTableColumn
                        filterOptions={{
                            toggleItem:
                                <ToggleFilter
                                    persistStorageItem={StorageHelper.customerScopesTableFlatView(view)}
                                    title={localization.actions.childScopeTenantsEnabled()}/>
                        }}
                        id={TableColumnId.ChildScopeTenantsEnabled}/>
                    {UserHelper.hasScopePermissions(scopeNodeModel.configuration.id, Contract.IdentityPermission.SecurityAdministrationRead) &&
                        menuItems &&
                        <DataTableAction>
                            <Menu
                                itemsOrGetItems={menuItems}
                                variant="bottomRight">
                                <Button
                                    size="small"
                                    startIcon={<AddIcon/>}>
                                    {localization.actions.add()}
                                </Button>
                            </Menu>
                        </DataTableAction>}
                </DataTable>
            </Box>
        </Stack>);
}

type ScopePathProps = {
    scopeNodeMap: Dictionary<ScopeNode>;
    scopeNodeModel: Contract.ScopeNodeModel;
};

function ScopePath({ scopeNodeMap, scopeNodeModel }: ScopePathProps) {
    const getScopePath =
        useCallback(
            (scopeNodeModel: Contract.ScopeNodeModel) => ScopeHelper.getScopePath(scopeNodeModel, scopeNodeMap),
            [scopeNodeMap]);

    return (
        <Typography noWrap={true}>
            {getScopePath(scopeNodeModel) ?? <NoneIcon/>}
        </Typography>);
}

export enum TableColumnId {
    Actions = "actions",
    ChildScopeTenantsEnabled = "childScopeTenantsEnabled",
    Name = "name",
    Path = "path"
}