import { InlineItems, Link, Step, Steps, useLocalization } from "@infrastructure";
import React from "react";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { useGetAwsLambdaFunctionConfigurationRiskContext } from "../contexts";

export function useAwsLambdaFunctionConfigurationVpcNotExistsRiskDefinition(riskModel: Contract.RiskModel) {
    const lambdaFunctionConfigurationVpcNotExistsRiskModel = riskModel as Contract.AwsLambdaFunctionConfigurationVpcNotExistsRiskModel;
    const risk = riskModel.risk as Contract.AwsLambdaFunctionConfigurationVpcNotExistsRisk;
    const functionConfigurationModel = entityModelStore.useGet(risk.entityId) as Contract.AwsLambdaFunctionConfigurationModel;
    const functionConfiguration = functionConfigurationModel.entity as Contract.AwsLambdaFunctionConfiguration;

    const getAwsLambdaFunctionConfigurationRiskContext = useGetAwsLambdaFunctionConfigurationRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsLambdaFunctionConfigurationVpcNotExistsRiskDefinition",
            () => ({
                contextItems: {
                    vpcNotExists: {
                        text: [
                            "The Lambda function has **{{versions}}** that is not associated with a VPC",
                            "The Lambda function has **{{versions}}** that are not associated with a VPC"
                        ],
                        versions: [
                            "1 version",
                            "{{count | NumberFormatter.humanize}} versions"
                        ]
                    }
                },
                description: "{{functionConfiguration}} is not associated with a VPC",
                sections: {
                    resolution: {
                        step1: "Older versions are immutable and cannot be modified. To remediate the issues, you need to publish a new version, update all aliases and dependent services to reference the newly created version, and then delete the outdated versions.",
                        step2: {
                            link: "AWS documentation",
                            text: "To attach a Lambda function to a VPC, Lambda needs permissions to create and manage the network interfaces it uses to give your function access to the resources in the VPC. You can give your function the permissions it needs by attaching the AWS managed policy **AWSLambdaVPCAccessExecutionRole** to your function's execution role. For more information, see {{attachingVpcUrl}}."
                        },
                        step3: "Before attaching the VPC to your Lambda function, make sure the VPC configuration is properly configured. If the VPC configuration is incorrect, the function may lose its ability to connect to required resources, effectively causing a disruption in functionality.",
                        step4: "Within the **VPC** section, click **Edit**",
                        step5: "Select the relevant **VPC**, **Subnets** and **Security Groups**",
                        step6: "Click **Save**",
                        step7: {
                            step1: "Click **Actions** and then **Publish new version**",
                            step2: "Fill in the **Version description** and click **Publish**",
                            step3: "Update all aliases and dependent services that reference older outdated versions, whether by ARN or specific version",
                            step4: "Before deleting the outdated versions, make sure your environment is properly configured and running",
                            step5: "Delete the outdated versions",
                            title: "Publish the new Lambda function version and delete older versions"
                        }
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            functionConfiguration:
                <Entity
                    entityIdOrModel={functionConfigurationModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.Lambda,
                AwsConsoleUrlBuilder.getLambdaFunctionConfigurationUrl(functionConfiguration)),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2.text({
                attachingVpcUrl:
                    <Link
                        urlOrGetUrl={lambdaFunctionConfigurationVpcNotExistsRiskModel.attachingVpcUrl}
                        variant="external">
                        {localization.sections.resolution.step2.link()}
                    </Link>
            }),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4(),
            localization.sections.resolution.step5(),
            localization.sections.resolution.step6(),
            new Step(
                localization.sections.resolution.step7.title(),
                {
                    contentElement:
                        <Steps variant="letters">
                            {localization.sections.resolution.step7.step1()}
                            {localization.sections.resolution.step7.step2()}
                            {localization.sections.resolution.step7.step3()}
                            {localization.sections.resolution.step7.step4()}
                            {localization.sections.resolution.step7.step5()}
                        </Steps>
                }
            )
        ],
        riskModel,
        () => {
            const functionConfigurationRiskContext = getAwsLambdaFunctionConfigurationRiskContext(functionConfigurationModel);
            return [
                functionConfigurationRiskContext.generalInformation,
                functionConfigurationRiskContext.accessLevel,
                new RiskDefinitionContextItem(
                    localization.contextItems.vpcNotExists.text(
                        risk.vpcNotExistsVersions.length,
                        {
                            versions:
                                <InlineItems
                                    items={risk.vpcNotExistsVersions}
                                    namePluralizer={localization.contextItems.vpcNotExists.versions}
                                    variant="itemCountAndType"/>
                        })),
                functionConfigurationRiskContext.sensitive,
                functionConfigurationRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        }
    );
}