import { FormLayout, useInputValidation, useLocalization } from "@infrastructure";
import { Box, Button, FormHelperText, Stack, TextField } from "@mui/material";
import _ from "lodash";
import React, { useState } from "react";
import { ConfigurationControllerUpsertCustomComplianceRequestSection } from "../../../../../../../common/controllers/types.generated";

type AddOrEditDetailsProps = {
    onSave: (section: AddOrEditDetailsOnSaveSection) => void;
    section?: Pick<ConfigurationControllerUpsertCustomComplianceRequestSection, "description" | "name">;
};

type AddOrEditDetailsOnSaveSection = {
    description?: string;
    name: string;
};

export function AddOrEditDetails({ onSave, section }: AddOrEditDetailsProps) {
    const [name, setName] = useState(section?.name ?? "");
    const [description, setDescription] = useState(section?.description);

    const [nameValidationController, nameValidationMessage] =
        useInputValidation(
            () =>
                _.isEmpty(name)
                    ? localization.error()
                    : undefined,
            [name]);

    const localization =
        useLocalization(
            "views.customer.compliance.addOrEdit.addOrEditDetails",
            () => ({
                description: "Description (optional)",
                error: "Name cannot be empty",
                save: {
                    add: "Add",
                    edit: "Save"
                },
                sectionName: "Name",
                title: {
                    add: "Add Section",
                    edit: "Edit Section"
                }
            }));
    return (
        <FormLayout
            footerOptions={{
                border: true,
                contentElement:
                    <Box sx={{ direction: "rtl" }}>
                        <Button
                            disabled={!nameValidationController.isValid()}
                            onClick={() => onSave({ description, name })}>
                            {_.isNil(section)
                                ? localization.save.add()
                                : localization.save.edit()}
                        </Button>
                    </Box>
            }}
            titleOptions={{
                text:
                    _.isNil(section)
                        ? localization.title.add()
                        : localization.title.edit()
            }}>
            <Stack spacing={1}>
                <TextField
                    autoFocus={true}
                    placeholder={localization.sectionName()}
                    value={name}
                    onChange={event => setName(event.target.value)}/>
                {!_.isNil(nameValidationMessage) &&
                    <FormHelperText error={true}>
                        {nameValidationMessage}
                    </FormHelperText>}
                <TextField
                    multiline={true}
                    placeholder={localization.description()}
                    rows={3}
                    value={description}
                    onChange={event => setDescription(event.target.value)}/>
            </Stack>
        </FormLayout>);
}