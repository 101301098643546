import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function UbuntuIcon(props: SvgIconProps) {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <g clipPath="url(#clip0_52885_254816)">
                <path
                    d="M12.2002 24.1992C18.8276 24.1992 24.2002 18.8266 24.2002 12.1992C24.2002 5.5718 18.8276 0.199219 12.2002 0.199219C5.57278 0.199219 0.200195 5.5718 0.200195 12.1992C0.200195 18.8266 5.57278 24.1992 12.2002 24.1992Z"
                    fill="#F47421"/>
                <path
                    clipRule="evenodd"
                    d="M12.1998 7.51828C9.61511 7.51828 7.5198 9.61359 7.5198 12.1983C7.5198 14.783 9.61511 16.8783 12.1998 16.8783C14.7845 16.8783 16.8798 14.783 16.8798 12.1983C16.8798 9.61359 14.7845 7.51828 12.1998 7.51828ZM5.2398 12.1983C5.2398 8.35438 8.3559 5.23828 12.1998 5.23828C16.0437 5.23828 19.1598 8.35438 19.1598 12.1983C19.1598 16.0422 16.0437 19.1583 12.1998 19.1583C8.3559 19.1583 5.2398 16.0422 5.2398 12.1983Z"
                    fill="white"
                    fillRule="evenodd"/>
                <path
                    d="M4.03963 14.4506C5.28229 14.4506 6.28966 13.4432 6.28966 12.2005C6.28966 10.9579 5.28229 9.95052 4.03963 9.95052C2.79698 9.95052 1.7896 10.9579 1.7896 12.2005C1.7896 13.4432 2.79698 14.4506 4.03963 14.4506Z"
                    fill="#F47421"/>
                <path
                    d="M16.7332 12.1988H19.3998H16.7332Z"
                    fill="black"/>
                <path
                    clipRule="evenodd"
                    d="M19.3998 12.6325H16.7332V11.7691H19.3998V12.6325Z"
                    fill="#F47421"
                    fillRule="evenodd"/>
                <path
                    d="M4.03938 13.8012C4.92413 13.8012 5.64136 13.084 5.64136 12.1992C5.64136 11.3145 4.92413 10.5972 4.03938 10.5972C3.15462 10.5972 2.43739 11.3145 2.43739 12.1992C2.43739 13.084 3.15462 13.8012 4.03938 13.8012Z"
                    fill="white"/>
                <path
                    d="M14.3315 4.00888C13.7102 5.08505 14.0789 6.46115 15.1551 7.08248C16.2312 7.7038 17.6073 7.33508 18.2287 6.25891C18.85 5.18274 18.4813 3.80664 17.4051 3.18532C16.3289 2.56399 14.9528 2.93271 14.3315 4.00888Z"
                    fill="#F47421"/>
                <path
                    d="M9.93365 16.1246L8.60031 18.434L9.93365 16.1246Z"
                    fill="black"/>
                <path
                    clipRule="evenodd"
                    d="M8.22638 18.2193L9.55971 15.9099L10.3075 16.3416L8.97412 18.651L8.22638 18.2193Z"
                    fill="#F47421"
                    fillRule="evenodd"/>
                <path
                    d="M14.8925 4.33442C14.4502 5.10064 14.7127 6.0804 15.4789 6.52277C16.2451 6.96515 17.2249 6.70262 17.6673 5.93641C18.1096 5.17019 17.8471 4.19043 17.0809 3.74805C16.3147 3.30567 15.3349 3.5682 14.8925 4.33442Z"
                    fill="white"/>
                <path
                    d="M18.2286 18.1395C17.6073 17.0633 16.2312 16.6946 15.155 17.3159C14.0789 17.9373 13.7101 19.3134 14.3315 20.3895C14.9528 21.4657 16.3289 21.8344 17.4051 21.2131C18.4812 20.5918 18.85 19.2157 18.2286 18.1395Z"
                    fill="#F47421"/>
                <path
                    d="M9.93348 8.27127L8.60015 5.96187L9.93348 8.27127Z"
                    fill="black"/>
                <path
                    clipRule="evenodd"
                    d="M8.97408 5.74717L10.3074 8.05658L9.55968 8.48828L8.22635 6.17888L8.97408 5.74717Z"
                    fill="#F47421"
                    fillRule="evenodd"/>
                <path
                    d="M17.6673 18.4633C17.2249 17.6971 16.2452 17.4346 15.4789 17.877C14.7127 18.3194 14.4502 19.2991 14.8926 20.0653C15.335 20.8315 16.3147 21.0941 17.0809 20.6517C17.8472 20.2093 18.1097 19.2296 17.6673 18.4633Z"
                    fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0_52885_254816">
                    <rect
                        fill="white"
                        height="24"
                        width="24"/>
                </clipPath>
            </defs>
        </SvgIcon>);
}