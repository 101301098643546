import { map, NoneIcon } from "@infrastructure";
import { SvgIconProps } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract, useTheme } from "../../../../../../../../../../../../../../common";
import { DataLeakageIcon, InfrastructureModificationIcon, PermissionManagementIcon, PrivilegeEscalationIcon, ReconnaissanceIcon } from "./icons";

type ActionRiskCategoryIconProps =
    SvgIconProps & {
        category?: Contract.ActionRiskCategory;
    };

export function ActionRiskCategoryIcon({ category, ...props }: ActionRiskCategoryIconProps) {
    const theme = useTheme();
    return (
        _.isNil(category)
            ? <NoneIcon
                {...props}
                sx={{
                    color: theme.palette.text.secondary,
                    ...props?.sx
                }}/>
            : map(
                category,
                {
                    [Contract.ActionRiskCategory.DataLeakage]: () => <DataLeakageIcon {...props}/>,
                    [Contract.ActionRiskCategory.InfrastructureModification]: () => <InfrastructureModificationIcon {...props}/>,
                    [Contract.ActionRiskCategory.PermissionManagement]: () => <PermissionManagementIcon {...props}/>,
                    [Contract.ActionRiskCategory.PrivilegeEscalation]: () => <PrivilegeEscalationIcon {...props}/>,
                    [Contract.ActionRiskCategory.Reconnaissance]: () => <ReconnaissanceIcon {...props}/>
                }));
}