﻿import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function AustraliaIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <g clipPath="url(#clip0_60203_3469)">
                <rect
                    fill="white"
                    height="16"
                    rx="2"
                    width="24"
                    y="4"/>
                <path
                    d="M24 3.99927H0V19.9993H24V3.99927Z"
                    fill="#012169"/>
                <mask
                    height="9"
                    id="mask0_60203_3469"
                    maskUnits="userSpaceOnUse"
                    width="12"
                    x="0"
                    y="4">
                    <path
                        d="M0 4.00134H12V12.0013H0V4.00134Z"
                        fill="white"/>
                </mask>
                <g mask="url(#mask0_60203_3469)">
                    <path
                        clipRule="evenodd"
                        d="M4.56105 8.00032L-0.442749 4.66445L0.442763 3.33618L6.00001 7.04101L11.5573 3.33618L12.4428 4.66445L7.43897 8.00032L12.4428 11.3362L11.5573 12.6645L6.00001 8.95962L0.442763 12.6645L-0.442749 11.3362L4.56105 8.00032Z"
                        fill="white"
                        fillRule="evenodd"/>
                    <mask
                        height="9"
                        id="mask1_60203_3469"
                        maskUnits="userSpaceOnUse"
                        width="12"
                        x="0"
                        y="4">
                        <path
                            d="M0 4.00134V8.00134H12V12.0013L0 4.00134ZM12 4.00134H6V12.0013H0L12 4.00134Z"
                            fill="white"/>
                    </mask>
                    <g mask="url(#mask1_60203_3469)">
                        <path
                            clipRule="evenodd"
                            d="M5.0407 8.00025L-0.295166 4.44301L0.295176 3.5575L6.00001 7.36071L11.7048 3.5575L12.2952 4.44301L6.95931 8.00025L12.2952 11.5575L11.7048 12.443L6.00001 8.63979L0.295176 12.443L-0.295166 11.5575L5.0407 8.00025Z"
                            fill="#E4002B"
                            fillRule="evenodd"/>
                    </g>
                    <path
                        clipRule="evenodd"
                        d="M4.66968 6.66895V3.99927H7.33032V6.66895H12V9.32959H7.33032V11.9993H4.66968V9.32959H0V6.66895H4.66968Z"
                        fill="white"
                        fillRule="evenodd"/>
                    <path
                        clipRule="evenodd"
                        d="M5.20181 7.20108V3.99927H6.79819V7.20108H12V8.79746H6.79819V11.9993H5.20181V8.79746H0V7.20108H5.20181Z"
                        fill="#E4002B"
                        fillRule="evenodd"/>
                </g>
                <path
                    d="M4.81437 13.3392L5.20426 14.5542L6.39512 14.1022L5.69044 15.1653L6.78553 15.8166L5.51692 15.9272L5.69162 17.1914L4.81437 16.2663L3.93712 17.1914L4.11181 15.9272L2.8432 15.8166L3.93829 15.1653L3.23361 14.1022L4.42448 14.5542L4.81437 13.3392Z"
                    fill="white"/>
                <path
                    d="M18.2934 15.5262L18.479 16.1048L19.0461 15.8896L18.7106 16.3958L19.232 16.7059L18.6279 16.7586L18.7111 17.3606L18.2934 16.9201L17.8756 17.3606L17.9588 16.7586L17.3547 16.7059L17.8762 16.3958L17.5406 15.8896L18.1077 16.1048L18.2934 15.5262Z"
                    fill="white"/>
                <path
                    d="M14.9237 10.179L15.1094 10.7575L15.6764 10.5423L15.3409 11.0485L15.8623 11.3587L15.2582 11.4113L15.3414 12.0133L14.9237 11.5728L14.506 12.0133L14.5891 11.4113L13.985 11.3587L14.5065 11.0485L14.171 10.5423L14.738 10.7575L14.9237 10.179Z"
                    fill="white"/>
                <path
                    d="M18.2934 6.5199L18.479 7.09847L19.0461 6.88322L18.7106 7.38944L19.232 7.6996L18.6279 7.75227L18.7111 8.35428L18.2934 7.91375L17.8756 8.35428L17.9588 7.75227L17.3547 7.6996L17.8762 7.38944L17.5406 6.88322L18.1077 7.09847L18.2934 6.5199Z"
                    fill="white"/>
                <path
                    d="M21.2887 9.27795L21.4743 9.85652L22.0414 9.64128L21.7059 10.1475L22.2273 10.4577L21.6232 10.5103L21.7064 11.1123L21.2887 10.6718L20.8709 11.1123L20.9541 10.5103L20.35 10.4577L20.8715 10.1475L20.5359 9.64128L21.103 9.85652L21.2887 9.27795Z"
                    fill="white"/>
                <path
                    d="M19.6413 11.988L19.788 12.3485L20.1754 12.377L19.8787 12.6282L19.9714 13.0063L19.6413 12.8011L19.3111 13.0063L19.4039 12.6282L19.1071 12.377L19.4945 12.3485L19.6413 11.988Z"
                    fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0_60203_3469">
                    <rect
                        fill="white"
                        height="16"
                        rx="2"
                        width="24"
                        y="4"/>
                </clipPath>
            </defs>
        </SvgIcon>);
}