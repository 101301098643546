import { AnalyticsEventActionType, map, useTrackAnalyticsEvent } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { CustomerConsoleAppUrlHelper, tenantModelStore, useApplicationViewRoute } from "../../../../common";
import { CustomerView } from "../../hooks";
import { Dashboard, Onboarding, RiskPolicies, Risks, Scans } from "./components";
import { CodeView } from "./hooks";

export function Code() {
    const [{ view }] =
        useApplicationViewRoute(
            CustomerConsoleAppUrlHelper.getTemplatePath(CustomerView.Code),
            _.values(CodeView),
            CodeView.Dashboard);
    const permittedCodeTenantModels = tenantModelStore.useGetPermittedCodeTenants();
    const permittedGitTenantModels = tenantModelStore.useGetPermittedGitTenants();
    useTrackAnalyticsEvent(AnalyticsEventActionType.PageView);

    const codeViewToCodeTenantView =
        useMemo(
            () => {
                const codeViewToCodeTenantView: Partial<{ [key in CodeView]: () => JSX.Element }> = {
                    [CodeView.Scans]: () => <Scans/>,
                    [CodeView.Policies]: () => <RiskPolicies/>
                };

                if (!_.isEmpty(permittedGitTenantModels)) {
                    codeViewToCodeTenantView[CodeView.Dashboard] = () => <Dashboard/>;
                    codeViewToCodeTenantView[CodeView.Risks] = () => <Risks/>;
                }

                return codeViewToCodeTenantView;
            },
            [permittedGitTenantModels]);

    return _.isEmpty(permittedCodeTenantModels)
        ? <Onboarding/>
        : map(view, codeViewToCodeTenantView);
}