﻿import { useLocalization } from "@infrastructure";
import React, { useMemo } from "react";
import { Contract, StatusCell, tenantModelStore } from "../../../../../../../../common";
import { useOciTenantModelStatusTranslator } from "../../../../../../../../tenants";
import { ProfileDefinition } from "../../useProfileDefinition";

export function useOciProfileDefinition(scopeNodeModel: Contract.ScopeNodeModel): ProfileDefinition {
    const tenantModelMap = tenantModelStore.useGetOciTenantMap();

    const ociTenantModelStatusTranslator = useOciTenantModelStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.scopes.hooks.useDefinition.hooks.useOciDefinition.useOciProfileDefinition",
            () => ({
                ocid: "OCID",
                status: "Status"
            }));

    return useMemo(
        () => {
            const tenantModel = tenantModelMap[scopeNodeModel.configuration.id];
            return {
                sections: [
                    {
                        items: [
                            {
                                title: localization.ocid(),
                                value: tenantModel.configuration.ocid
                            },
                            {
                                title: localization.status(),
                                value:
                                    <StatusCell
                                        statusSeverity={tenantModel.statusSeverity}
                                        title={ociTenantModelStatusTranslator(tenantModel.status)}/>
                            }
                        ]
                    }
                ]
            };
        },
        [ociTenantModelStatusTranslator, localization, tenantModelMap]);
}