import { AddIcon, DataTable, DataTableAction, DataTableActions, DataTableColumn, DataTableColumnExportOptions, DataTableColumnRenderProps, DataTableFetchItemsResult, DataTableSort, DataTableSortDirection, EmptyMessageText, Link, map, Optional, optionalTableCell, TimeSpanHelper, ToggleFilter, Tooltip, useChangeEffect, useExecuteOperation, useLocalization, useLocalizeList, useUncaptureValue, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import { Button } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Contract, CustomerConsoleAppUrlHelper, EntitiesCell, EntityFilter, entityModelStore, ItemSelectionHelper, PermissionManagementController, PermissionManagementTenantType, ScopeHelper, scopeNodeModelStore, StorageHelper, TenantCell, TenantFilter, TenantHelper, tenantModelStore, TypeHelper, useGetSelectedScopeHasData, useScopeNavigationViewContext, useTenantNameTranslator } from "../../../../../../common";
import { useEligibilitiesContext, useSetEligibilitiesContext } from "../../PermissionEligibilities";
import { ActionsCell, StatusCell } from "./components";
import { useDefinition, useGetPermissionEligibilityStatusData } from "./hooks";

export function Table() {
    const { scopeNodeModel } = useScopeNavigationViewContext();
    const { registerPermissionEligibilityChange } = useEligibilitiesContext();
    const setEligibilitiesContext = useSetEligibilitiesContext();
    const getSelectedScopeHasData = useGetSelectedScopeHasData();

    const [filterMap, setFilterMap] = useState<Dictionary<any>>({});
    const tenantType = ScopeHelper.getTenantType(scopeNodeModel)! as PermissionManagementTenantType;
    const requestScope =
        TenantHelper.isCloudProviderTenantType(tenantType)
            ? new Contract.PermissionManagementControllerPermissionAssignmentEligibilityRequestScope(
                scopeNodeModel.configuration.id,
                filterMap[TableColumnId.IncludeChildScopes] ?? false)
            : tenantType === Contract.TenantType.OneLogin
                ? new Contract.PermissionManagementControllerOneLoginEligibilityRequestScope(
                    scopeNodeModel.configuration.id,
                    filterMap[TableColumnId.IncludeChildScopes] ?? false)
                : new Contract.PermissionManagementControllerGroupMembershipEligibilityRequestScope(
                    scopeNodeModel.configuration.id,
                    filterMap[TableColumnId.IncludeChildScopes] ?? false);
    const [{ filters }, executeGetFilters] =
        useExecuteOperation(
            [Table, scopeNodeModel.configuration.id],
            () =>
                PermissionManagementController.getPermissionsEligibilityFilters(
                    TenantHelper.isCloudProviderTenantType(tenantType)
                        ? new Contract.PermissionManagementControllerGetPermissionAssignmentEligibilityFiltersRequest(requestScope)
                        : tenantType === Contract.TenantType.OneLogin
                            ? new Contract.PermissionManagementControllerGetOneLoginEligibilityFiltersRequest(requestScope)
                            : new Contract.PermissionManagementControllerGetGroupMembershipEligibilityFiltersRequest(requestScope)));

    const dataTableActionsRef = useRef<DataTableActions>();

    async function refreshFilters() {
        await executeGetFilters();
        dataTableActionsRef.current!.refreshFilters();
    }

    const refreshFiltersPromiseRef = useRef<Promise<void>>();
    useChangeEffect(
        () => {
            refreshFiltersPromiseRef.current = refreshFilters();
        },
        [filterMap[TableColumnId.IncludeChildScopes]]);

    const uncaptureRefreshFilters = useUncaptureValue(refreshFilters);
    useEffect(
        () => {
            const unregister =
                registerPermissionEligibilityChange(
                    async () => {
                        dataTableActionsRef.current!.reset();
                        await uncaptureRefreshFilters(refreshFilters => refreshFilters());
                    });
            return () => {
                unregister();
            };
        },
        []);

    const activeTenantModels = tenantModelStore.useGetActiveTenants();
    const [activePrincipalTenantModels, principalTenantIds, scopeActiveTenantModels] =
        useMemo(
            () => {
                const scopeActiveTenantModels =
                    _.filter(
                        activeTenantModels,
                        activeTenantModel =>
                            activeTenantModel.tenantType === tenantType &&
                            (ScopeHelper.isFolder(scopeNodeModel) ||
                                activeTenantModel.configuration.id === scopeNodeModel.configuration.id));

                const principalTenantIds =
                    _(scopeActiveTenantModels).
                        map(
                            scopeActiveTenantModel =>
                                TenantHelper.isCloudProviderTenantType(scopeActiveTenantModel.tenantType)
                                    ? (scopeActiveTenantModel as Contract.CloudProviderTenantModel).state.permissionManagementPrincipalTenantId
                                    : scopeActiveTenantModel.configuration.id).
                        filter().
                        as<string>().
                        uniq().
                        value();

                const activePrincipalTenantModels =
                    _.filter(
                        activeTenantModels,
                        activeTenantModel =>
                            _.includes(
                                principalTenantIds,
                                activeTenantModel.configuration.id));

                return [activePrincipalTenantModels, principalTenantIds, scopeActiveTenantModels];
            },
            [activeTenantModels]);

    const scopeTenantTypes =
        useMemo(
            () => [tenantType],
            [tenantType]);
    const scopeNodeMap = scopeNodeModelStore.useGetActiveScopeNodeMap(scopeTenantTypes);
    const scopeScopeIds =
        useMemo(
            () => new Set(scopeNodeMap[scopeNodeModel.configuration.id].scopeIds),
            [scopeNodeModel, scopeNodeMap]);

    const localizeList = useLocalizeList();
    const localization =
        useLocalization(
            "views.user.permissionEligibilities.table",
            () => ({
                actions: {
                    add: {
                        error: {
                            principalTenantNotActive: {
                                [Contract.ScopeType.CloudProviderTenant]: "The {{principalTenant}} is not configured in Tenable Cloud Security.",
                                [Contract.ScopeType.Folder]: "There is no {{principalTenant}} configured in Tenable Cloud Security.",
                                [Contract.ScopeType.IdentityProviderTenant]: "There is no enabled {{principalTenant}}",
                                principalTenant: {
                                    [Contract.TypeNames.TenantType]: {
                                        [Contract.TenantType.Aad]: "Microsoft Entra ID",
                                        [Contract.TenantType.Aws]: "management account",
                                        [Contract.TenantType.Azure]: "Microsoft Entra ID",
                                        [Contract.TenantType.Gci]: "Google Workspace",
                                        [Contract.TenantType.Gcp]: "Google Workspace",
                                        [Contract.TenantType.Okta]: "Okta Organization",
                                        [Contract.TenantType.OneLogin]: "OneLogin Account",
                                        [Contract.TenantType.PingIdentity]: "Ping Identity Environment"
                                    }
                                }
                            },
                            principalTenantNotExist: {
                                [Contract.ScopeType.Folder]: "There is no organization.",
                                [Contract.ScopeType.CloudProviderTenant]: "The account does not belong to an organization."
                            },
                            principalTenantNotSynced: "Identities are syncing...",
                            principalTenantPermissionManagementNotEnabled: {
                                links: {
                                    documentation: "documentation"
                                },
                                text: "To add an eligibility for the current scope, grant Tenable Cloud Security JIT permissions on the relevant organization. See the {{documentationLink}} for details."
                            }
                        },
                        title: "Add Eligibility"
                    },
                    csvExport: {
                        fileNamePrefix: "Eligibilities_{{scopeName}}"
                    }
                },
                columns: {
                    approvalRequired: {
                        false: "No",
                        title: "Requires Approval",
                        true: "Yes"
                    },
                    approverPrincipalIdReferences: "Approvers",
                    expirationTimeFrame: {
                        text: [
                            "1 hour",
                            "{{count | NumberFormatter.humanize}} hours"
                        ],
                        title: "Maximum Duration"
                    },
                    granteePrincipalIdReferences: "Principals",
                    includeChildScopes: "Flat View",
                    levelToApproverPrincipalIdsMap: {
                        "level0": "Approvers",
                        "level1": "Second Level Approvers",
                        "level2": "Third Level Approvers"
                    },
                    name: "Name",
                    principalTenantId: "Organization",
                    scope: "Path",
                    status: "Status"
                },
                empty: {
                    withFilter: "No Matching Eligibilities",
                    withoutFilter: "No Eligibilities"
                }
            }));

    const definition = useDefinition(filters, tenantType);

    function getScopePath(scopeId: string): string | undefined {
        const scopeNodeModel = scopeNodeMap[scopeId].scopeNodeModel;
        return (
            ScopeHelper.isRootFolder(scopeNodeModel)
                ? scopeNodeModel.configuration.name
                : `${getScopePath(ScopeHelper.tryGetParentScopeId(scopeNodeModel)!)} / ${scopeNodeModel.configuration.name}`);
    }

    const [maxLevel, setMaxLevel] = useState(1);
    useChangeEffect(
        () => dataTableActionsRef.current!.reload(),
        [maxLevel]);
    const awsPermitterModelMapRef = useRef<Dictionary<Contract.AwsEntityModel>>({});
    const fetchPermissionEligibilityModels =
        async (filterMap: Dictionary<any>, sort: Optional<DataTableSort>, skip: number, limit: number) => {
            const { permissionEligibilityModelPage } =
                await PermissionManagementController.getPermissionsEligibilityModelPage(
                    definition.createGetPermissionEligibilityModelPageRequest(
                        new Contract.PermissionManagementControllerGetPermissionEligibilityModelPageRequestFilters(
                            ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[TableColumnId.ApprovalRequired]),
                            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[TableColumnId.ApproverPrincipalIdReferences]),
                            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[TableColumnId.GranteePrincipalIdReferences]),
                            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[TableColumnId.Name]),
                            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[TableColumnId.PrincipalTenantId])),
                        filterMap,
                        limit,
                        requestScope,
                        skip,
                        _.isNil(sort)
                            ? undefined
                            : new Contract.PermissionManagementControllerGetPermissionEligibilityModelPageRequestSort(
                                sort.direction === DataTableSortDirection.Descending
                                    ? Contract.SortDirection.Descending
                                    : Contract.SortDirection.Ascending,
                                map(
                                    sort.columnId,
                                    {
                                        [TableColumnId.Name]: () => Contract.PermissionManagementControllerGetPermissionEligibilityModelPageRequestProperty.Name,
                                        [TableColumnId.Scope]: () => Contract.PermissionManagementControllerGetPermissionEligibilityModelPageRequestProperty.Scope
                                    }))));

            await refreshFiltersPromiseRef.current;

            const awsPermitterModels =
                await entityModelStore.get(
                    _(permissionEligibilityModelPage.items).
                        filter(permissionEligibilityModel => permissionEligibilityModel.configuration.typeName === Contract.TypeNames.AwsSsoPermissionSetAssignmentEligibilityConfiguration).
                        flatMap(
                            permissionEligibilityModel => {
                                const { permissions } = permissionEligibilityModel as Contract.AwsSsoPermissionSetAssignmentEligibilityModel;
                                return permissions.typeName === Contract.TypeNames.AwsSsoPermissionSetAssignmentEligibilityModelPermissionSetPermissions
                                    ? [(permissions as Contract.AwsSsoPermissionSetAssignmentEligibilityModelPermissionSetPermissions).permissionSetIdReference]
                                    : (permissions as Contract.AwsSsoPermissionSetAssignmentEligibilityModelPolicyPermissions).awsManagedPolicyIdReferences;
                            }).
                        value()) as Contract.AwsEntityModel[];

            return new DataTableFetchItemsResult(
                { count: permissionEligibilityModelPage.count! },
                permissionEligibilityModelPage.items,
                !permissionEligibilityModelPage.hasMore,
                {
                    onAppendData:
                        () => {
                            setMaxLevel(
                                maxLevel =>
                                    _(permissionEligibilityModelPage.items).
                                        map(
                                            permissionEligibilityModel =>
                                                _.isNil(permissionEligibilityModel.levelToApproverPrincipalIdReferencesMap)
                                                    ? 1
                                                    : _.size(permissionEligibilityModel.levelToApproverPrincipalIdReferencesMap)).
                                        concat(maxLevel).
                                        max()!);
                            awsPermitterModelMapRef.current =
                                _(awsPermitterModels).
                                    keyBy(awsPermitterModel => awsPermitterModel.id).
                                    merge(awsPermitterModelMapRef.current).
                                    value();
                        }
                });
        };

    function getEntitiesExportOptions(
        getEntityIds: (item: Contract.PermissionEligibilityModel) => Optional<string>[],
        title: string): DataTableColumnExportOptions {
        return {
            getData: getEntityIds,
            getItem:
                (item: Contract.PermissionEligibilityModel, exportData: ExportData) => {
                    const entityIds = _.filter(getEntityIds(item));
                    return ({
                        [title]:
                            _(entityIds).
                                map(entityId => exportData.entityIdToDisplayReferenceMap[entityId!]).
                                join("\n")
                    });
                }
        };
    }

    async function getExportData(itemIdToExportColumnElementIdToExportDataMap: Dictionary<Dictionary<string[]>>): Promise<ExportData> {
        const entityIds =
            _(itemIdToExportColumnElementIdToExportDataMap).
                values().
                flatMap(
                    exportColumnElementIdToExportDataMap =>
                        _(exportColumnElementIdToExportDataMap).
                            values().
                            flatMap().
                            value()).
                uniq().
                filter().
                value();
        const entityModels = await entityModelStore.get(entityIds);

        return {
            awsPermitterModelMap:
                _(entityModels).
                    filter(entityModel => TypeHelper.extendOrImplement(entityModel.entity.typeName, Contract.TypeNames.AwsEntity)).
                    as<Contract.AwsEntityModel>().
                    keyBy(awsPermitterModel => awsPermitterModel.id).
                    value(),
            entityIdToDisplayReferenceMap:
                _(entityModels).
                    keyBy(entityModel => entityModel.id).
                    mapValues(entityModel => entityModel.entity.displayReference).
                    value()
        };
    }

    const getPermissionEligibilityStatusData =
        useGetPermissionEligibilityStatusData(
            activePrincipalTenantModels,
            principalTenantIds,
            scopeActiveTenantModels);

    const tenantNameTranslator = useTenantNameTranslator();
    return (
        <DataTable
            actionsRef={dataTableActionsRef}
            columnOptions={{
                orderOptions: {
                    enabled: true,
                    persistenceStorageItem: StorageHelper.userPermissionEligibilitiesColumnOrder(tenantType)
                },
                resizable: true,
                selectorOptions: {
                    enabled: true,
                    persistenceStorageItem: StorageHelper.userPermissionEligibilitiesColumnSelector(tenantType)
                }
            }}
            emptyMessageOptions={{
                emptyMessageText:
                    new EmptyMessageText(
                        localization.empty.withoutFilter(),
                        localization.empty.withFilter())
            }}
            exportOptions={{
                fileNamePrefix: localization.actions.csvExport.fileNamePrefix({ scopeName: scopeNodeModel.configuration.name }),
                getData: getExportData
            }}
            fetchItems={fetchPermissionEligibilityModels}
            filtersOptions={{
                onChanged: filterMap => setFilterMap(filterMap),
                persist: {
                    visibilityStorageItem: StorageHelper.userPermissionEligibilityTableFilters
                }
            }}
            getItemId={(item: Contract.PermissionEligibilityModel) => item.configuration.id}>
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (permissionEligibilityModel: Contract.PermissionEligibilityModel) => ({
                            [localization.columns.name()]: permissionEligibilityModel.configuration.name
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        default: true,
                        element:
                            <ValuesFilter
                                emptyValueOptions={{ enabled: filters.nameItems.emptyValue }}
                                placeholder={localization.columns.name()}>
                                {_.map(
                                    filters.nameItems.items,
                                    name =>
                                        <ValuesFilterItem
                                            key={name}
                                            title={name}
                                            value={name}/>)}
                            </ValuesFilter>
                    }
                }}
                id={TableColumnId.Name}
                itemProperty={permissionEligibilityModel => permissionEligibilityModel.configuration.name}
                key={TableColumnId.Name}
                selectorOptions={{ disabled: true }}
                title={localization.columns.name()}/>
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (permissionEligibilityModel: Contract.PermissionEligibilityModel) => ({
                            [localization.columns.principalTenantId()]: tenantNameTranslator(permissionEligibilityModel.configuration.principalTenantId)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        default: true,
                        element:
                            <TenantFilter
                                emptyValueEnabled={filters.principalTenantIdItems.emptyValue}
                                placeholder={localization.columns.principalTenantId()}
                                tenantIds={filters.principalTenantIdItems.items}
                                variant="iconText"/>
                    }
                }}
                id={TableColumnId.PrincipalTenantId}
                key={TableColumnId.PrincipalTenantId}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.PermissionEligibilityModel>) =>
                        <TenantCell tenantId={item.configuration.principalTenantId}/>}
                sortOptions={{ enabled: false }}
                title={localization.columns.principalTenantId()}/>
            <DataTableColumn
                exportOptions={
                    getEntitiesExportOptions(
                        item => item.granteePrincipalIdReferences,
                        localization.columns.granteePrincipalIdReferences())}
                filterOptions={{
                    itemOrItems: {
                        default: true,
                        element:
                            <EntityFilter
                                emptyValue={filters.granteePrincipalSearchableReferenceItems.emptyValue}
                                entityIdsOrSearchableReferences={filters.granteePrincipalSearchableReferenceItems.items}
                                placeholder={localization.columns.granteePrincipalIdReferences()}/>
                    }
                }}
                id={TableColumnId.GranteePrincipalIdReferences}
                key={TableColumnId.GranteePrincipalIdReferences}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.PermissionEligibilityModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.granteePrincipalIdReferences}
                            entityLinkOptions={{ disabled: true }}
                            entityTypeName={Contract.TypeNames.IPermissionManagementPrincipal}
                            entityVariant="iconText"/>}
                sortOptions={{ enabled: false }}
                title={localization.columns.granteePrincipalIdReferences()}/>
            {definition.getColumns(getEntitiesExportOptions)}
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (permissionEligibilityModel: Contract.PermissionEligibilityModel) => ({
                            [localization.columns.expirationTimeFrame.title()]:
                                localization.columns.expirationTimeFrame.text(TimeSpanHelper.getHours(permissionEligibilityModel.configuration.expirationTimeFrame))
                        })
                }}
                id={TableColumnId.ExpirationTimeFrame}
                itemProperty={(item: Contract.PermissionEligibilityModel) => localization.columns.expirationTimeFrame.text(TimeSpanHelper.getHours(item.configuration.expirationTimeFrame))}
                key={TableColumnId.ExpirationTimeFrame}
                sortOptions={{ enabled: false }}
                title={localization.columns.expirationTimeFrame.title()}/>
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (permissionEligibilityModel: Contract.PermissionEligibilityModel) => ({
                            [localization.columns.approvalRequired.title()]:
                                _.isNil(permissionEligibilityModel.configuration.approval)
                                    ? localization.columns.approvalRequired.false()
                                    : localization.columns.approvalRequired.true()
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <ValuesFilter placeholder={localization.columns.approvalRequired.title()}>
                                <ValuesFilterItem
                                    key="false"
                                    title={localization.columns.approvalRequired.false()}
                                    value={false}/>
                                <ValuesFilterItem
                                    key="true"
                                    title={localization.columns.approvalRequired.true()}
                                    value={true}/>
                            </ValuesFilter>
                    }
                }}
                id={TableColumnId.ApprovalRequired}
                itemProperty={
                    (item: Contract.PermissionEligibilityModel) =>
                        _.isNil(item.configuration.approval)
                            ? localization.columns.approvalRequired.false()
                            : localization.columns.approvalRequired.true()}
                key={TableColumnId.ApprovalRequired}
                sortOptions={{ enabled: false }}
                title={localization.columns.approvalRequired.title()}/>
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EntityFilter
                                emptyValue={filters.approverPrincipalSearchableReferenceItems.emptyValue}
                                entityIdsOrSearchableReferences={filters.approverPrincipalSearchableReferenceItems.items}
                                placeholder={localization.columns.approverPrincipalIdReferences()}/>
                    }
                }}
                id={TableColumnId.ApproverPrincipalIdReferences}
                title={localization.columns.approverPrincipalIdReferences()}/>
            {_(maxLevel).
                range().
                map(
                    level => {
                        const title = localization.columns.levelToApproverPrincipalIdsMap.translate(`level${level}`);
                        return <DataTableColumn
                            exportOptions={
                                getEntitiesExportOptions(
                                    item => item.levelToApproverPrincipalIdReferencesMap[level] ?? [],
                                    title)}
                            id={`${TableColumnId.LevelToApproverPrincipalIdReferencesMap}-${level}`}
                            key={`${TableColumnId.LevelToApproverPrincipalIdReferencesMap}-${level}`}
                            render={
                                ({ item }: DataTableColumnRenderProps<Contract.PermissionEligibilityModel>) =>
                                    <EntitiesCell
                                        entityIdsOrModels={item.levelToApproverPrincipalIdReferencesMap[level] ?? []}
                                        entityLinkOptions={{ disabled: true }}
                                        entityTypeName={Contract.TypeNames.IPermissionManagementPrincipal}
                                        entityVariant="iconText"/>}
                            sortOptions={{ enabled: false }}
                            title={title}/>;
                    }).
                value()}
            <DataTableColumn
                exportOptions={{
                    getData:
                        item => {
                            if (item.configuration.typeName !== Contract.TypeNames.AwsSsoPermissionSetAssignmentEligibilityConfiguration ||
                                (item.configuration as Contract.AwsSsoPermissionSetAssignmentEligibilityConfiguration).permissions.typeName !== Contract.TypeNames.AwsSsoPermissionSetAssignmentEligibilityConfigurationPermissionSetPermissions) {
                                return [];
                            }
                            const { permissions } = item as Contract.AwsSsoPermissionSetAssignmentEligibilityModel;
                            return [(permissions as Contract.AwsSsoPermissionSetAssignmentEligibilityModelPermissionSetPermissions).permissionSetIdReference];
                        },
                    getItem:
                        (permissionEligibilityModel: Contract.PermissionEligibilityModel, exportData: ExportData) => {
                            const permissionEligibilityStatusData =
                                getPermissionEligibilityStatusData(
                                    exportData.awsPermitterModelMap,
                                    scopeNodeModel,
                                    permissionEligibilityModel);
                            return ({
                                [localization.columns.status()]:
                                    _.isEmpty(permissionEligibilityStatusData.infos)
                                        ? permissionEligibilityStatusData.title
                                        : `${permissionEligibilityStatusData.title} (${localizeList(permissionEligibilityStatusData.infos)})`
                            });
                        }
                }}
                id={TableColumnId.Status}
                key={TableColumnId.Status}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.PermissionEligibilityModel>) =>
                        <StatusCell
                            eligibilityStatusData={
                                getPermissionEligibilityStatusData(
                                    awsPermitterModelMapRef.current,
                                    scopeNodeModel,
                                    item)}/>}
                sortOptions={{ enabled: false }}
                title={localization.columns.status()}/>
            {filterMap[TableColumnId.IncludeChildScopes] === true &&
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (permissionEligibilityModel: Contract.PermissionEligibilityModel) => ({
                                [localization.columns.scope()]: getScopePath(permissionEligibilityModel.configuration.scopeId) ?? ""
                            })
                    }}
                    id={TableColumnId.Scope}
                    key={TableColumnId.Scope}
                    render={optionalTableCell<Contract.PermissionEligibilityModel>(item => getScopePath(item.configuration.scopeId))}
                    title={localization.columns.scope()}/>}
            <DataTableColumn
                id={TableColumnId.Actions}
                key={TableColumnId.Actions}
                orderable={false}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.PermissionEligibilityModel>) =>
                        <ActionsCell
                            activePrincipalTenantModels={activePrincipalTenantModels}
                            permissionEligibilityModel={item}
                            scopeActiveTenantModels={scopeActiveTenantModels}
                            scopeScopeIds={scopeScopeIds}/>}
                resizable={false}
                selectorOptions={{ disabled: true }}/>
            {ScopeHelper.isFolder(scopeNodeModel) &&
                <DataTableColumn
                    filterOptions={{
                        toggleItem: <ToggleFilter title={localization.columns.includeChildScopes()}/>
                    }}
                    id={TableColumnId.IncludeChildScopes}/>}
            <DataTableAction>
                <Tooltip
                    titleOrGetTitle={
                        _.isEmpty(principalTenantIds)
                            ? localization.actions.add.error.principalTenantNotExist[scopeNodeModel.type as Contract.ScopeType.Folder | Contract.ScopeType.CloudProviderTenant]()
                            : _.isEmpty(activePrincipalTenantModels)
                                ? localization.actions.add.error.principalTenantNotActive[scopeNodeModel.type as Contract.ScopeType.CloudProviderTenant | Contract.ScopeType.Folder | Contract.ScopeType.IdentityProviderTenant]({
                                    principalTenant: localization.actions.add.error.principalTenantNotActive.principalTenant[Contract.TypeNames.TenantType][tenantType]()
                                })
                                : !getSelectedScopeHasData(activePrincipalTenantModels)
                                    ? localization.actions.add.error.principalTenantNotSynced()
                                    : _.every(
                                        scopeActiveTenantModels,
                                        scopeActiveTenantModel => !(scopeActiveTenantModel.state as Contract.CloudProviderTenantState | Contract.IdentityProviderTenantState).permissionManagementEnabled)
                                        ? localization.actions.add.error.principalTenantPermissionManagementNotEnabled.text({
                                            documentationLink:
                                                <Link
                                                    urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsGrantTenableJitPermissionsRelativeUrl(tenantType)}
                                                    variant="external">
                                                    {localization.actions.add.error.principalTenantPermissionManagementNotEnabled.links.documentation()}
                                                </Link>
                                        })
                                        : undefined}>
                    <span>
                        <Button
                            disabled={
                                !getSelectedScopeHasData(activePrincipalTenantModels) ||
                                _.every(
                                    scopeActiveTenantModels,
                                    scopeActiveTenantModel => !(scopeActiveTenantModel.state as Contract.CloudProviderTenantState | Contract.IdentityProviderTenantState).permissionManagementEnabled)}
                            size="small"
                            startIcon={<AddIcon/>}
                            onClick={
                                () =>
                                    setEligibilitiesContext(
                                        eligibilitiesContext => ({
                                            ...eligibilitiesContext,
                                            addOrEditOpen: true,
                                            editMode: false
                                        }))}>
                            {localization.actions.add.title()}
                        </Button>
                    </span>
                </Tooltip>
            </DataTableAction>
        </DataTable>);
}

type ExportData = {
    awsPermitterModelMap: Dictionary<Contract.AwsEntityModel>;
    entityIdToDisplayReferenceMap: Dictionary<string>;
};

export enum TableColumnId {
    AadGroupIds = "aadGroupIds",
    Actions = "actions",
    ApprovalRequired = "approvalRequired",
    ApproverPrincipalIdReferences = "approverPrincipalIdReferences",
    AwsEntityIdReferences = "awsEntityIdReferences",
    AwsInlinePolicyDocument = "awsInlinePolicyDocument",
    AzureRoleDefinitionIds = "azureRoleDefinitionIds",
    ExpirationTimeFrame = "expirationTimeFrame",
    GciGroupIds = "gciGroupIds",
    GcpRoleIds = "gcpRoleIds",
    GranteePrincipalIdReferences = "granteePrincipalIdReferences",
    IncludeChildScopes = "includeChildScopes",
    LevelToApproverPrincipalIdReferencesMap = "levelToApproverPrincipalIdReferencesMap",
    Name = "name",
    OktaGroupIds = "oktaGroupIds",
    OneLoginRoleIds = "oneLoginRoleIds",
    PermissionRequestScopeIds = "permissionRequestScopeIds",
    PingIdentityGroupIds = "pingIdentityGroupIds",
    PrincipalTenantId = "principalTenantId",
    Scope = "scope",
    Status = "status"
}