﻿import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useGcpSqlInstanceTypeTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useGcpSqlInstanceTypeTranslator",
            () => ({
                [Contract.TypeNames.GcpSqlInstanceType]: {
                    [Contract.GcpSqlInstanceType.OnPremises]: "On Premises Replica",
                    [Contract.GcpSqlInstanceType.Primary]: "Primary",
                    [Contract.GcpSqlInstanceType.ReadReplica]: "Read Replica"
                }
            }));
    return (status: Contract.GcpSqlInstanceType) =>
        localization[Contract.TypeNames.GcpSqlInstanceType][status]();
}