﻿import { ItemSelector, Message, TimeFormatter, useLocalization } from "@infrastructure";
import { Box, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useState } from "react";
import { Contract, entityModelStore, InfoCard, InfoItem, useTheme } from "../../../../../../../../../../../../common";
import { TagsInfoItem } from "../../../../../../components";


type VersionsProps = {
    secretModel: Contract.AzureKeyVaultVaultSecretModel;
};

export function Versions({ secretModel }: VersionsProps) {
    const secret = secretModel.entity as Contract.AzureKeyVaultVaultSecret;
    const vault = entityModelStore.useGet(secret.vaultId).entity as Contract.AzureKeyVaultVault;

    const [selectedVersion, setSelectedVersion] = useState(() => secret.versionNameToVersionMap[secret.currentVersionName]);
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.azure.useAzureKeyVaultVaultSecretDefinition.versions",
            () => ({
                current: {
                    empty: "current",
                    text: "{{versionName}} (current)"
                },
                fields: {
                    activationTime: "Activation Time",
                    creationTime: "Creation Time",
                    enabled: {
                        false: "Disabled",
                        title: "Status",
                        true: "Enabled"
                    },
                    expirationTime: "Expiration Time",
                    name: "Identifier",
                    tags: "Tags",
                    updateTime: "Update Time"
                },
                versions: "Some versions might not be displayed",
                warningMessage: "Since this key vault uses vault access policy as its permission model, Tenable Cloud Security can't access previous versions of secrets. To grant Tenable Cloud Security access, switch the model to RBAC."
            }));
    const theme = useTheme();
    return (
        <InfoCard>
            {!vault.rbacEnabled &&
                <Stack direction="row">
                    <Box sx={{ marginLeft: theme.spacing(1) }}>
                        <Message
                            level="warning"
                            title={localization.warningMessage()}
                            variant="minimal"/>
                    </Box>
                    <Typography sx={{ marginLeft: theme.spacing(0.5) }}>
                        {localization.versions()}
                    </Typography>
                </Stack>}
            <ItemSelector
                dense={true}
                fullWidth={true}
                items={
                    _(secret.versionNameToVersionMap).
                        values().
                        orderBy(
                            [
                                version => version.name === secret.name,
                                version => version.creationTime
                            ],
                            [
                                "desc",
                                "desc"
                            ]).
                        map(version => version.name).
                        value()}
                selectedItem={selectedVersion.name}
                sorted={false}
                onSelectedItemChanged={versionName => setSelectedVersion(secret.versionNameToVersionMap[versionName])}>
                {versionName =>
                    versionName === secret.currentVersionName
                        ? _.isEmpty(versionName)
                            ? localization.current.empty()
                            : localization.current.text({ versionName })
                        : versionName}
            </ItemSelector>
            <InfoCard columns={true}>
                <InfoItem
                    key="creationTime"
                    title={localization.fields.creationTime()}
                    value={
                        _.isNil(selectedVersion.creationTime)
                            ? undefined
                            : TimeFormatter.profileFormatDateTime(selectedVersion.creationTime)}/>
                <InfoItem
                    key="updateTime"
                    title={localization.fields.updateTime()}
                    value={TimeFormatter.profileFormatDateTime(selectedVersion.updateTime)}/>
                <InfoItem
                    key="name"
                    title={localization.fields.name()}
                    value={
                        _.isEmpty(selectedVersion.name)
                            ? undefined
                            : selectedVersion.name}/>
                <InfoItem
                    key="enabled"
                    title={localization.fields.enabled.title()}
                    value={
                        selectedVersion.enabled
                            ? localization.fields.enabled.true()
                            : localization.fields.enabled.false()}/>
                <InfoItem
                    key="activationTime"
                    title={localization.fields.activationTime()}
                    value={
                        _.isNil(selectedVersion.activationTime)
                            ? undefined
                            : TimeFormatter.profileFormatDateTime(selectedVersion.activationTime)}/>
                <InfoItem
                    key="expirationTime"
                    title={localization.fields.expirationTime()}
                    value={
                        _.isNil(selectedVersion.expirationTime)
                            ? undefined
                            : TimeFormatter.profileFormatDateTime(selectedVersion.expirationTime)}/>
                <TagsInfoItem
                    key="tags"
                    resourceTags={selectedVersion.tags}
                    title={localization.fields.tags()}/>
            </InfoCard>
        </InfoCard>);
}