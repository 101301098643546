import { Optional, Sx } from "@infrastructure";
import { PersonSearch as PersonSearchIcon } from "@mui/icons-material";
import { SvgIcon, SvgIconProps } from "@mui/material";
import _ from "lodash";
import React, { cloneElement, ReactElement } from "react";
import { Contract, EntitiesIcon, TypeHelper, useTheme } from "../..";
import { AadDirectoryEntityIcon, AwsEntityIcon, AzureEntityIcon, CiEntityIcon, GciEntityIcon, GcpEntityIcon, OciEntityIcon, OktaDirectoryEntityIcon, OneLoginDirectoryEntityIcon, OpEntityIcon, PingIdentityDirectoryEntityIcon, PublicAccessBadgeIcon, VendorBadgeIcon } from "./icons";

type EntityIconProps =
    SvgIconProps & {
        badge?: "publicAccess" | "restrictedAccess" | "vendor";
        blurred?: boolean;
        entityTypeName: string;
        sensitive?: boolean;
        variant?: string;
    };

export function EntityIcon({ badge, blurred = false, entityTypeName, sensitive, variant, ...props }: EntityIconProps) {
    const theme = useTheme();
    const svgIconProps = {
        ...props,
        sx:
            Sx.combine(
                props.sx,
                {
                    color:
                        blurred
                            ? theme.palette.text.secondary
                            : theme.palette.text.primary
                })
    };

    const baseEntityTypeNameToIconComponentMap = {
        [Contract.TypeNames.AadDirectoryEntity]: AadDirectoryEntityIcon,
        [Contract.TypeNames.AzureEntity]: AzureEntityIcon,
        [Contract.TypeNames.AwsEntity]: AwsEntityIcon,
        [Contract.TypeNames.IAwsResource]: AwsEntityIcon,
        [Contract.TypeNames.CiEntity]: CiEntityIcon,
        [Contract.TypeNames.GciEntity]: GciEntityIcon,
        [Contract.TypeNames.GcpEntity]: GcpEntityIcon,
        [Contract.TypeNames.OciEntity]: OciEntityIcon,
        [Contract.TypeNames.OktaDirectoryEntity]: OktaDirectoryEntityIcon,
        [Contract.TypeNames.OpEntity]: OpEntityIcon,
        [Contract.TypeNames.OneLoginDirectoryEntity]: OneLoginDirectoryEntityIcon,
        [Contract.TypeNames.PingIdentityDirectoryEntity]: PingIdentityDirectoryEntityIcon
    };
    const IconComponent =
        _.find(
            baseEntityTypeNameToIconComponentMap,
            (IconComponent, baseEntityTypeName) => TypeHelper.extendOrImplement(entityTypeName, baseEntityTypeName));

    let icon: Optional<ReactElement>;
    if (!_.isNil(IconComponent)) {
        icon =
            <IconComponent
                blurred={blurred}
                entityTypeName={entityTypeName}
                variant={variant}
                {...svgIconProps}/>;
    } else {
        switch (entityTypeName) {
            case Contract.TypeNames.PartialAnonymousIdentity:
            case Contract.TypeNames.PartialPrincipal:
            case Contract.TypeNames.PartialUser:
                icon = <PersonSearchIcon {...svgIconProps}/>;
                break;
            case Contract.TypeNames.UnknownEntity:
                icon = <EntitiesIcon {...svgIconProps}/>;
                break;
            default:
                icon = <EntitiesIcon {...svgIconProps}/>;
        }
    }

    if (badge === "publicAccess" ||
        badge === "restrictedAccess") {
        icon =
            <SvgIcon>
                <g transform={`translate(0 4) scale(${20 / 24})`}>
                    {icon}
                </g>
                <g transform="translate(12)">
                    <PublicAccessBadgeIcon
                        sx={{
                            color:
                                badge === "publicAccess"
                                    ? theme.palette.error.main
                                    : theme.palette.warning.main
                        }}/>
                </g>
                {sensitive &&
                    <g>
                        <circle
                            cx="14"
                            cy="4"
                            fill={theme.palette.error.main}
                            r="3"
                            stroke={theme.palette.background.paper}/>
                    </g>}
            </SvgIcon>;
    } else if (badge === "vendor") {
        icon =
            <SvgIcon>
                <g transform={`translate(0 4) scale(${20 / 24})`}>
                    {icon}
                </g>
                <g transform="translate(10)">
                    <VendorBadgeIcon/>
                </g>
                {sensitive &&
                    <g>
                        <circle
                            cx="9"
                            cy="3"
                            fill={theme.palette.error.main}
                            r="2"
                            stroke={theme.palette.background.paper}/>
                    </g>}
            </SvgIcon>;
    } else if (sensitive) {
        icon =
            <SvgIcon>
                {icon}
                <circle
                    cx="20"
                    cy="4"
                    fill={theme.palette.error.main}
                    r="4"
                    stroke={theme.palette.background.paper}/>
            </SvgIcon>;
    }

    return cloneElement(icon, svgIconProps);
}