﻿import { ApiError, defined, Optional, useLocalization } from "@infrastructure";
import _ from "lodash";
import { Contract, TenantController } from "../../../../../../../../../../../../../../../common";
import { useSetAddOrEditContext } from "../AddOrEdit";

export function useUpsertTenant() {
    const setAddOrEditContext = useSetAddOrEditContext();

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useIdentityProviderTenantItems.oneLoginTenants.addOrEdit.hooks.useUpsertTenant",
            () => ({
                error: {
                    add: "Failed to add OneLogin account",
                    edit: "Failed to save OneLogin account",
                    [Contract.TypeNames.TenantControllerInsertOneLoginTenantError]: {
                        [Contract.TenantControllerInsertOneLoginTenantError.AuthenticationFailed]: "Authentication failure",
                        [Contract.TenantControllerInsertOneLoginTenantError.MissingPermissions]: "Insufficient permissions",
                        [Contract.TenantControllerInsertOneLoginTenantError.PermissionManagementAccountMismatch]: "The client ID does not belong to this **Onelogin** account",
                        [Contract.TenantControllerInsertOneLoginTenantError.PermissionManagementAuthenticationFailed]: "Authentication failure",
                        [Contract.TenantControllerInsertOneLoginTenantError.TenantExists]: "Account already exists"
                    },
                    [Contract.TypeNames.TenantControllerUpdateOneLoginTenantError]: {
                        [Contract.TenantControllerUpdateOneLoginTenantError.AuthenticationFailed]: "Authentication failure",
                        [Contract.TenantControllerUpdateOneLoginTenantError.MissingPermissions]: "Insufficient permissions",
                        [Contract.TenantControllerInsertOneLoginTenantError.PermissionManagementAccountMismatch]: "The client ID does not belong to this **Onelogin** account",
                        [Contract.TenantControllerInsertOneLoginTenantError.PermissionManagementAuthenticationFailed]: "Authentication failure",
                        [Contract.TenantControllerUpdateOneLoginTenantError.TenantMismatch]: "The client ID does not belong to this **Onelogin** account"

                    }
                }
            }));
    return async (name: string, permissionManagementClientId: Optional<string>, permissionManagementClientSecret: Optional<string>, readClientId: string, readClientSecret: Optional<string>, tenantConfiguration: Optional<Contract.OneLoginTenantConfiguration>, url: string): Promise<Optional<string>> => {
        try {
            let updatedTenantModel: Contract.OneLoginTenantModel;
            if (_.isNil(tenantConfiguration)) {
                const { tenantModel } =
                    await TenantController.insertOneLoginTenant(
                        new Contract.TenantControllerInsertOneLoginTenantRequest(
                            new Contract.OneLoginClientData(
                                readClientId.trim(),
                                defined(readClientSecret?.trim())),
                            name,
                            _.isEmpty(permissionManagementClientId)
                                ? undefined
                                : new Contract.OneLoginClientData(
                                    permissionManagementClientId!.trim(),
                                    defined(permissionManagementClientSecret?.trim())),
                            url.trim()));
                updatedTenantModel = tenantModel;
            } else {
                const { tenantModel } =
                    await TenantController.updateOneLoginTenant(
                        new Contract.TenantControllerUpdateOneLoginTenantRequest(
                            new Contract.TenantControllerUpdateOneLoginTenantRequestClientData(
                                readClientId.trim(),
                                readClientSecret?.trim()),
                            tenantConfiguration.id,
                            name,
                            _.isEmpty(permissionManagementClientId)
                                ? undefined
                                : new Contract.TenantControllerUpdateOneLoginTenantRequestClientData(
                                    permissionManagementClientId!.trim(),
                                    permissionManagementClientSecret?.trim()),
                            url));
                updatedTenantModel = tenantModel;
            }

            setAddOrEditContext(
                addOrEditContext => ({
                    ...addOrEditContext,
                    updatedTenantModel
                }));
        } catch (error) {
            return (
                error instanceof ApiError && error.statusCode === 400
                    ? _.isNil(tenantConfiguration)
                        ? localization.error[Contract.TypeNames.TenantControllerInsertOneLoginTenantError][error.error as Contract.TenantControllerInsertOneLoginTenantError]()
                        : localization.error[Contract.TypeNames.TenantControllerUpdateOneLoginTenantError][error.error as Contract.TenantControllerUpdateOneLoginTenantError]()
                    : _.isNil(tenantConfiguration)
                        ? localization.error.add()
                        : localization.error.edit());
        }
    };
}