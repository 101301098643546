import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, EnumValuesFilter, InlineItems, optionalTableCell, PagedValuesFilter, useLocalization, useOperation, ValueFilter } from "@infrastructure";
import { Typography } from "@mui/material";
import _ from "lodash";
import React, { useCallback } from "react";
import { Contract, EntitiesCell, Entity, PagedEntityFilter, SeverityFilter, useMakePagedEntityFilterEntityItem, useMakePagedEntityFilterValueItem, WorkloadController } from "../../../../../common";
import { ContainerImageUsageFilter } from "../../Entities/components/Table/hooks/useDefinition/components";
import { VulnerabilitiesChartCell } from "../components";
import { ExploitableValueFilter } from "../components/ExploitableValueFilter";
import { useAttackVectorTranslator } from "./useAttackVectorTranslator";
import { useGetWorkloadResourceScanValueFilterItemPage } from "./useGetValueFilterItemPage";
import { useGetWorkloadResourceScanEntityFilterItemPage } from "./useGetWorkloadResourceScanEntityFilterItemPage";
import { useAttributesDataTableColumn, useOperatingSystemDataTableColumn, useOperatingSystemTypeDataTableColumn, usePackageDisplayNameDataTableColumn, useRiskSeverityDataTableColumn, useScanTimeDataTableColumn, useTenantIdDataTableColumn, useTimeRangeDataTableColumn, useVirtualMachinesDataTableColumn } from "./WorkloadScanDataTableColumns";

export function useCommonContainerImageDefinition(vulnerabilityRawId?: string) {
    const attackVectorTranslator = useAttackVectorTranslator();

    const [filtersPromise] =
        useOperation(
            useCommonContainerImageDefinition,
            useCallback(
                async () => {
                    const { filters } = await WorkloadController.getContainerImageScanFilters(new Contract.WorkloadControllerGetWorkloadResourceScanFiltersRequest(vulnerabilityRawId));
                    return filters;
                },
                []));

    const vulnerabilitiesPagedValuesFilter =
        useMakePagedEntityFilterValueItem(
            Contract.TypeNames.IContainerImage,
            Contract.EntityModelProperty.ContainerImageVulnerabilities);

    const localization =
        useLocalization(
            "views.customer.workloadAnalysis.hooks.useCommonContainerImageDefinition",
            () => ({
                columns: {
                    attributes: "Labels",
                    cloud: "Cloud",
                    containerImageId: {
                        title: "Name",
                        typeName: "Type"
                    },
                    creationTime: "Date Added",
                    digest: "Digest",
                    exploitable: {
                        false: "No",
                        title: "Known Exploit",
                        true: "Yes"
                    },
                    fixableVulnerability: "With Fix Only",
                    kubernetesWorkloadResourceIds: "K8s Workloads",
                    operatingSystem: "Operating System",
                    operatingSystemType: "Operating System Type",
                    packages: "Software",
                    repositoryId: "Repository",
                    risks: "Findings",
                    risksSeverity: "Findings Severity",
                    scanTime: "Scan Time",
                    tags: "Image Tags",
                    tenantId: "Account",
                    usage: "Usage",
                    virtualMachineIds: "Virtual Machines",
                    vulnerabilities: "Vulnerabilities",
                    vulnerabilityAttackVector: "Vulnerability Attack Vector",
                    vulnerabilitySeverityChart: "Vulnerability Severity",
                    workloadClusterResourceIds: "Clusters"
                },
                empty: {
                    packages: {
                        withFilter: "No Matching Software",
                        withoutFilter: "No Software"
                    }
                }
            }));

    return {
        columnNames: localization.columns,
        columns: {
            attributes:
                useAttributesDataTableColumn(
                    filtersPromise,
                    localization.columns,
                    vulnerabilityRawId),
            containerImageDigest:
                <DataTableColumn
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedValuesFilter
                                    getValuePage={
                                        useGetWorkloadResourceScanValueFilterItemPage(
                                            Contract.WorkloadControllerRequestProperty.ContainerImageDigest,
                                            Contract.WorkloadAnalysisWorkloadResourceType.ContainerImage)}
                                    placeholder={localization.columns.digest()}/>
                        }
                    }}
                    id={Contract.WorkloadControllerRequestProperty.ContainerImageDigest}
                    itemProperty={(item: Contract.WorkloadResourceContainerImageModel) => item.digest}
                    key={Contract.WorkloadControllerRequestProperty.ContainerImageDigest}
                    selectorOptions={{ default: !vulnerabilityRawId }}
                    title={localization.columns.digest()}/>,
            containerImageId:
                <DataTableColumn
                    filterOptions={{
                        itemOrItems: {
                            default: !!vulnerabilityRawId,
                            element:
                                <PagedEntityFilter
                                    getEntityIdPage={
                                        useGetWorkloadResourceScanEntityFilterItemPage(
                                            Contract.WorkloadControllerRequestProperty.ContainerImageId,
                                            Contract.WorkloadAnalysisWorkloadResourceType.ContainerImage,
                                            vulnerabilityRawId)}
                                    placeholder={localization.columns.containerImageId.title()}/>
                        }
                    }}
                    id={Contract.WorkloadControllerRequestProperty.ContainerImageId}
                    key={Contract.WorkloadControllerRequestProperty.ContainerImageId}
                    render={
                        ({ item: workloadResourceModel }: DataTableColumnRenderProps<Contract.WorkloadResourceModel>) =>
                            <Entity
                                entityIdOrModel={workloadResourceModel.id}
                                variant="iconText"/>}
                    selectorOptions={{ disabled: true }}
                    title={localization.columns.containerImageId.title()}/>,
            containerImageRepository:
                <DataTableColumn
                    filterOptions={{
                        itemOrItems: {
                            default: !!vulnerabilityRawId,
                            element:
                                <PagedEntityFilter
                                    getEntityIdPage={
                                        useMakePagedEntityFilterEntityItem(
                                            Contract.TypeNames.IContainerImage,
                                            Contract.EntityModelProperty.ContainerImageRepository)}
                                    placeholder={localization.columns.repositoryId()}/>
                        }
                    }}
                    id={Contract.WorkloadControllerRequestProperty.ContainerImageRepository}
                    key={Contract.WorkloadControllerRequestProperty.ContainerImageRepository}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.WorkloadResourceContainerImageModel>) =>
                            <EntitiesCell
                                entityIdsOrModels={item.repositoryIdReference}
                                entityTypeName={Contract.TypeNames.IContainerImageRepository}
                                entityVariant="iconText"/>}
                    title={localization.columns.repositoryId()}/>,
            containerImageTags:
                <DataTableColumn
                    filterOptions={{
                        itemOrItems: {
                            default: !!vulnerabilityRawId,
                            element:
                                <PagedValuesFilter
                                    getValuePage={
                                        useGetWorkloadResourceScanValueFilterItemPage(
                                            Contract.WorkloadControllerRequestProperty.ContainerImageTags,
                                            Contract.WorkloadAnalysisWorkloadResourceType.ContainerImage,
                                            true,
                                            undefined,
                                            vulnerabilityRawId)}
                                    placeholder={localization.columns.tags()}/>
                        }
                    }}
                    id={Contract.WorkloadControllerRequestProperty.ContainerImageTags}
                    key={Contract.WorkloadControllerRequestProperty.ContainerImageTags}
                    render={
                        optionalTableCell(
                            (containerImageModel: Contract.WorkloadResourceContainerImageModel) =>
                                _.size(containerImageModel.tags) === 0
                                    ? undefined
                                    : <Typography noWrap={true}>
                                        <InlineItems
                                            items={containerImageModel.tags}
                                            variant="itemPlusItemCount"/>
                                    </Typography>)}
                    sortOptions={{ type: DataTableSortType.Numeric }}
                    title={localization.columns.tags()}/>,
            containerImageUsage:
                <DataTableColumn
                    filterOptions={{
                        itemOrItems: {
                            element: <ContainerImageUsageFilter/>
                        }
                    }}
                    id={Contract.EntityModelProperty.ContainerImageWorkloadExists}
                    key={Contract.EntityModelProperty.ContainerImageWorkloadExists}
                    selectorOptions={{ default: !vulnerabilityRawId }}
                    title={localization.columns.usage()}/>,
            creationTime: useTimeRangeDataTableColumn(
                filtersPromise,
                useCallback((
                    model: Contract.WorkloadResourceContainerImageModel) => model.creationTime,
                []),
                Contract.WorkloadControllerRequestProperty.ContainerImageCreationTime,
                useCallback(
                    filters => filters.creationTimeRange,
                    []),
                localization.columns.creationTime,
                vulnerabilityRawId),
            fixableVulnerability:
                <DataTableColumn
                    filterOptions={{
                        itemOrItems:
                        {
                            default: true,
                            element:
                                <ValueFilter
                                    items={[
                                        {
                                            title: localization.columns.fixableVulnerability(),
                                            value: true
                                        }
                                    ]}
                                    placeholder={localization.columns.fixableVulnerability()}/>
                        }
                    }}
                    id={Contract.WorkloadControllerRequestProperty.FixableVulnerability}
                    key={Contract.WorkloadControllerRequestProperty.FixableVulnerability}
                    selectorOptions={{ default: !vulnerabilityRawId }}
                    title={localization.columns.fixableVulnerability()}/>,
            kubernetesWorkloadResources:
                <DataTableColumn
                    filterOptions={{
                        itemOrItems: {
                            default: !!vulnerabilityRawId,
                            element:
                                <PagedEntityFilter
                                    getEntityIdPage={
                                        useMakePagedEntityFilterEntityItem(
                                            Contract.TypeNames.IContainerImage,
                                            Contract.EntityModelProperty.ContainerImageKubernetesWorkloadResources)}
                                    placeholder={localization.columns.kubernetesWorkloadResourceIds()}/>
                        }
                    }}
                    id={Contract.WorkloadControllerRequestProperty.ContainerImageKubernetesWorkloadResources}
                    key={Contract.WorkloadControllerRequestProperty.ContainerImageKubernetesWorkloadResources}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.WorkloadResourceContainerImageModel>) =>
                            <EntitiesCell
                                entityIdsOrModels={item.kubernetesWorkloadResourceIds}
                                entityTypeName={Contract.TypeNames.IKubernetesWorkloadResource}
                                entityVariant="iconText"/>}
                    selectorOptions={{ default: !!vulnerabilityRawId }}
                    sortOptions={{ type: DataTableSortType.Numeric }}
                    title={localization.columns.kubernetesWorkloadResourceIds()}/>,
            operatingSystemDisplayName:
                useOperatingSystemDataTableColumn(
                    localization.columns,
                    Contract.WorkloadAnalysisWorkloadResourceType.ContainerImage,
                    vulnerabilityRawId),
            operatingSystemType:
                useOperatingSystemTypeDataTableColumn(
                    localization.columns,
                    vulnerabilityRawId),
            packageDisplayName:
                usePackageDisplayNameDataTableColumn(
                    localization,
                    Contract.WorkloadAnalysisWorkloadResourceType.ContainerImage,
                    vulnerabilityRawId),
            risksSeverity: useRiskSeverityDataTableColumn(localization.columns),
            scanTime:
                useScanTimeDataTableColumn(
                    filtersPromise,
                    localization.columns,
                    vulnerabilityRawId),
            tenantId:
                useTenantIdDataTableColumn(
                    filtersPromise,
                    localization.columns,
                    vulnerabilityRawId),
            virtualMachines:
                useVirtualMachinesDataTableColumn(
                    Contract.WorkloadAnalysisWorkloadResourceType.ContainerImage,
                    localization.columns),
            vulnerabilities:
                <DataTableColumn
                    cellMinWidth={180}
                    filterOptions={{
                        itemOrItems:
                            _.isNil(vulnerabilityRawId)
                                ? [
                                    {
                                        default: !vulnerabilityRawId,
                                        element:
                                    <PagedValuesFilter
                                        getValuePage={vulnerabilitiesPagedValuesFilter}
                                        placeholder={localization.columns.vulnerabilities()}/>,
                                        id: Contract.WorkloadControllerRequestProperty.VulnerabilityRawId,
                                        title: localization.columns.vulnerabilities()
                                    },
                                    {
                                        element:
                                    <SeverityFilter
                                        information={false}
                                        placeholder={localization.columns.vulnerabilitySeverityChart()}/>,
                                        id: Contract.WorkloadControllerRequestProperty.VulnerabilitySeverity,
                                        title: localization.columns.vulnerabilitySeverityChart()
                                    }
                                ]
                                : undefined
                    }}
                    id={Contract.WorkloadControllerRequestProperty.VulnerabilityRawId}
                    key={Contract.WorkloadControllerRequestProperty.VulnerabilityRawId}
                    render={
                        ({ item: workloadResourceModel }: DataTableColumnRenderProps<Contract.WorkloadResourceModel>) =>
                            <VulnerabilitiesChartCell
                                vulnerabilities={workloadResourceModel.vulnerabilities}
                                workloadResourceEntityId={workloadResourceModel.id}/>}
                    selectorOptions={{ default: !vulnerabilityRawId }}
                    sortOptions={{ type: DataTableSortType.Numeric }}
                    title={localization.columns.vulnerabilities()}/>,
            vulnerabilityAttackVector:
                <DataTableColumn
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <EnumValuesFilter
                                    enumType={Contract.VulnerabilityAttackVector}
                                    enumTypeTranslator={attackVectorTranslator}
                                    placeholder={localization.columns.vulnerabilityAttackVector()}/>
                        }
                    }}
                    id={Contract.WorkloadControllerRequestProperty.VulnerabilityAttackVector}
                    key={Contract.WorkloadControllerRequestProperty.VulnerabilityAttackVector}
                    selectorOptions={{ default: !vulnerabilityRawId }}
                    title={localization.columns.vulnerabilityAttackVector()}/>,
            vulnerabilityExploitable:
                <DataTableColumn
                    filterOptions={{
                        itemOrItems: {
                            default: true,
                            element: <ExploitableValueFilter localization={localization.columns.exploitable}/>
                        }
                    }}
                    id={Contract.WorkloadControllerRequestProperty.VulnerabilityExploitable}
                    key={Contract.WorkloadControllerRequestProperty.VulnerabilityExploitable}
                    selectorOptions={{ default: !vulnerabilityRawId }}
                    sortOptions={{ enabled: false }}
                    title={localization.columns.exploitable.title()}/>,
            workloadClusterResources:
                <DataTableColumn
                    filterOptions={{
                        itemOrItems: {
                            default: !!vulnerabilityRawId,
                            element:
                                <PagedEntityFilter
                                    getEntityIdPage={
                                        useMakePagedEntityFilterEntityItem(
                                            Contract.TypeNames.IContainerImage,
                                            Contract.EntityModelProperty.ContainerImageWorkloadClusterResources)}
                                    placeholder={localization.columns.workloadClusterResourceIds()}/>
                        }
                    }}
                    id={Contract.WorkloadControllerRequestProperty.ContainerImageWorkloadClusterResources}
                    key={Contract.WorkloadControllerRequestProperty.ContainerImageWorkloadClusterResources}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.WorkloadResourceContainerImageModel>) =>
                            <EntitiesCell
                                entityIdsOrModels={item.workloadClusterResourceIds}
                                entityTypeName={Contract.TypeNames.Entity}
                                entityVariant="iconText"/>}
                    selectorOptions={{ default: !!vulnerabilityRawId }}
                    sortOptions={{ type: DataTableSortType.Numeric }}
                    title={localization.columns.workloadClusterResourceIds()}/>
        }
    };
}