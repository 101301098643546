import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, EnumValuesFilter, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { useAzureCommonEncryptedResourceDefinition, useAzureCommonResourceGroupResourceDefinition } from ".";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { useAzureDocumentDbDatabaseAccountTypeTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";
import { EntityTableDefinition } from "../../useDefinition";
import { useCommonNetworkAccessResourceDefinition } from "../useCommonNetworkAccessResourceDefinition";

export function useAzureDocumentDbDatabaseAccountDefinition(definitionData: DefinitionData) {
    const commonEncryptedResourceDefinition = useAzureCommonEncryptedResourceDefinition(definitionData);
    const commonNetworkAccessResourceDefinition = useCommonNetworkAccessResourceDefinition();
    const commonResourceGroupResourceDefinition = useAzureCommonResourceGroupResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const documentDbDatabaseAccountTypeTranslator = useAzureDocumentDbDatabaseAccountTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.azure.useAzureDocumentDbDatabaseAccountDefinition",
            () => ({
                columns: {
                    managementKeyAccess: {
                        false: "Disabled",
                        title: "Key Based Metadata Access",
                        true: "Enabled"
                    },
                    resources: "Resources",
                    sharedKeyAccess: {
                        false: "Disabled",
                        title: "Local Authentication",
                        true: "Enabled"
                    },
                    type: "API Type"
                }
            }));

    return new EntityTableDefinition(
        _.filter([
            commonResourceGroupResourceDefinition.columns.tenantColumn,
            commonResourceGroupResourceDefinition.columns.creationTimeColumn,
            commonResourceGroupResourceDefinition.columns.creatorIdentityCsvColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AzureDocumentDbDatabaseAccountModel) => ({
                            [localization.columns.type()]: documentDbDatabaseAccountTypeTranslator((item.entity as Contract.AzureDocumentDbDatabaseAccount).type)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.AzureDocumentDbDatabaseAccountType}
                                enumTypeTranslator={documentDbDatabaseAccountTypeTranslator}
                                placeholder={localization.columns.type()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AzureDocumentDbDatabaseAccountType}
                itemProperty={(item: Contract.AzureDocumentDbDatabaseAccountModel) => documentDbDatabaseAccountTypeTranslator((item.entity as Contract.AzureDocumentDbDatabaseAccount).type)}
                key={Contract.EntityModelProperty.AzureDocumentDbDatabaseAccountType}
                title={localization.columns.type()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AzureDocumentDbDatabaseAccountModel) => ({
                            [localization.columns.sharedKeyAccess.title()]:
                                (item.entity as Contract.AzureDocumentDbDatabaseAccount).sharedKeyAccess
                                    ? localization.columns.sharedKeyAccess.true()
                                    : localization.columns.sharedKeyAccess.false()
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <ValuesFilter
                                placeholder={localization.columns.sharedKeyAccess.title()}
                                sorted={false}>
                                <ValuesFilterItem
                                    title={localization.columns.sharedKeyAccess.true()}
                                    value={true}/>
                                <ValuesFilterItem
                                    title={localization.columns.sharedKeyAccess.false()}
                                    value={false}/>
                            </ValuesFilter>
                    }
                }}
                id={Contract.EntityModelProperty.AzureDocumentDbDatabaseAccountSharedKeyAccess}
                itemProperty={
                    (item: Contract.AzureDocumentDbDatabaseAccountModel) =>
                        (item.entity as Contract.AzureDocumentDbDatabaseAccount).sharedKeyAccess
                            ? localization.columns.sharedKeyAccess.true()
                            : localization.columns.sharedKeyAccess.false()}
                key={Contract.EntityModelProperty.AzureDocumentDbDatabaseAccountSharedKeyAccess}
                selectorOptions={{ default: false }}
                title={localization.columns.sharedKeyAccess.title()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AzureDocumentDbDatabaseAccountModel) => ({
                            [localization.columns.managementKeyAccess.title()]:
                                (item.entity as Contract.AzureDocumentDbDatabaseAccount).managementKeyAccess
                                    ? localization.columns.managementKeyAccess.true()
                                    : localization.columns.managementKeyAccess.false()
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <ValuesFilter
                                placeholder={localization.columns.managementKeyAccess.title()}
                                sorted={false}>
                                <ValuesFilterItem
                                    title={localization.columns.managementKeyAccess.true()}
                                    value={true}/>
                                <ValuesFilterItem
                                    title={localization.columns.managementKeyAccess.false()}
                                    value={false}/>
                            </ValuesFilter>
                    }
                }}
                id={Contract.EntityModelProperty.AzureDocumentDbDatabaseAccountManagementKeyAccess}
                itemProperty={
                    (item: Contract.AzureDocumentDbDatabaseAccountModel) =>
                        (item.entity as Contract.AzureDocumentDbDatabaseAccount).managementKeyAccess
                            ? localization.columns.managementKeyAccess.true()
                            : localization.columns.managementKeyAccess.false()}
                key={Contract.EntityModelProperty.AzureDocumentDbDatabaseAccountManagementKeyAccess}
                selectorOptions={{ default: false }}
                title={localization.columns.managementKeyAccess.title()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.AzureDocumentDbDatabaseAccountModel>(
                        Contract.TypeNames.AzureResource,
                        item => item.databaseAccountResourceIds,
                        localization.columns.resources())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AzureDocumentDbDatabaseAccountResources)}
                                placeholder={localization.columns.resources()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AzureDocumentDbDatabaseAccountResources}
                key={Contract.EntityModelProperty.AzureDocumentDbDatabaseAccountResources}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AzureResourceGroupResourceModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={(item as Contract.AzureDocumentDbDatabaseAccountModel).databaseAccountResourceIds}
                            entityTypeName={Contract.TypeNames.AzureDocumentDbDatabaseAccountResource}
                            entityVariant="iconText"/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.resources()}/>,
            commonNetworkAccessResourceDefinition.columns.accessLevelColumn,
            commonNetworkAccessResourceDefinition.columns.inboundExternalAccessScopeColumn,
            commonEncryptedResourceDefinition.columns.encryptionExistsColumn,
            commonEncryptedResourceDefinition.columns.vaultKeyColumn,
            commonResourceGroupResourceDefinition.columns.regionColumn,
            commonResourceGroupResourceDefinition.columns.regionLocationColumn,
            commonResourceGroupResourceDefinition.columns.resourceGroupColumn,
            commonResourceGroupResourceDefinition.columns.tagsColumn,
            commonResourceGroupResourceDefinition.columns.attributesColumn,
            commonResourceGroupResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonResourceGroupResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonResourceGroupResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ]),
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAzureDocumentDbDatabaseAccountRequest(
                new Contract.EntityControllerGetEntityModelPageAzureDocumentDbDatabaseAccountRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureResourceGroupResourceResourceGroup]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureDocumentDbDatabaseAccountResources]),
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureDocumentDbDatabaseAccountManagementKeyAccess]),
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureDocumentDbDatabaseAccountSharedKeyAccess]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureDocumentDbDatabaseAccountType])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags])),
                new Contract.EntityControllerGetEntityModelPageAzureResourceRequestEncrypted(
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureEncryptedResourceEncryptionExists]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureEncryptedResourceVaultKeys])),
                new Contract.EntityControllerGetEntityModelPageEntityRequestNetworkAccess(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.NetworkInboundAccessType]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.NetworkInboundExternalAccessScope]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.NetworkInboundExternalDestinationNetworkScopes]))));
}