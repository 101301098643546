import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, InlineEntities, SeveritySquare, useEntityTypeNameTranslator, usePrincipalModelAccess, useSeverityTranslator } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetGcpEntityRiskContext } from "./useGetGcpEntityRiskContext";

export function useGetGcpIamServiceAccountRiskContext() {
    return useMemo(
        () => useGcpIamServiceAccountRiskContext,
        []);
}

function useGcpIamServiceAccountRiskContext(serviceAccountModel: Contract.GcpIamServiceAccountModel) {
    const entityRiskContext = useGetGcpEntityRiskContext()(serviceAccountModel);
    const serviceAccount = serviceAccountModel.entity as Contract.GcpIamServiceAccount;
    const serviceAccountModelAccess = usePrincipalModelAccess<Contract.ServiceIdentityModelAccess>(serviceAccount.id);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const severityTranslator = useSeverityTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.contexts.useGetGcpIamServiceAccountRiskContext",
            () => ({
                originatorEntities: "The service account is linked to {{originatorEntityIds}}",
                permissionActionSeverity: "The {{translatedKeyTypeName}} would give an attacker {{severitySquare}}**{{severity}}** severity permissions if compromised"
            }));

    return {
        ...entityRiskContext,
        originatorEntities:
            _.isEmpty(serviceAccountModelAccess.originatorEntityIds)
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.originatorEntities({
                        originatorEntityIds:
                            <InlineEntities
                                entityIdsOrModels={serviceAccountModelAccess.originatorEntityIds}
                                entityTypeName={Contract.TypeNames.IGcpServiceAccountOriginatorScopeResource}
                                entityVariant="iconTextTypeTenant"
                                variant="itemAndTypeOrItemCountAndType"/>
                    })),
        permissionActionSeverity:
            _.isNil(serviceAccountModel.permissionActionSeverity)
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.permissionActionSeverity({
                        severity:
                            severityTranslator(
                                serviceAccountModel.permissionActionSeverity,
                                "text"),
                        severitySquare: <SeveritySquare severity={serviceAccountModel.permissionActionSeverity}/>,
                        translatedKeyTypeName:
                            entityTypeNameTranslator(
                                Contract.TypeNames.GcpIamServiceAccountKey,
                                {
                                    count: serviceAccount.userManagedKeyIds.length,
                                    variant: "title"
                                })
                    }))
    };
}