import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";
import { useTheme } from "../../../themes";

export function CheckPointIcon(props: SvgIconProps) {
    const theme = useTheme();
    return (
        <SvgIcon {...props}>
            <path
                d="M9.37185 4.99463C4.20625 4.99463 0.0139163 9.17308 3.44387e-05 14.3387C-0.0138474 19.5135 4.17077 23.7182 9.34717 23.7321C14.522 23.7444 18.7298 19.5614 18.7437 14.388C18.7452 13.6307 18.6557 12.8965 18.4876 12.1916C17.5159 12.6389 16.3514 12.6281 15.3534 12.0621L12.5909 15.7253C12.9364 16.1279 13.1277 16.6292 13.1462 17.1459C13.1647 17.604 13.0475 18.0729 12.7791 18.4878C12.0881 19.5583 10.6552 19.8652 9.58471 19.1727C9.21607 18.9336 8.93843 18.6066 8.76105 18.2379C8.44948 17.5917 8.44485 16.8174 8.79807 16.151L6.57542 14.4775L5.88441 15.3952L3.34248 13.4811L5.25664 10.9392L7.79857 12.8533L7.1091 13.7695L9.35643 15.46C10.0567 14.8724 11.0593 14.7459 11.8953 15.1747L14.6454 11.5268C13.5472 10.4518 13.2696 8.73348 14.0794 7.34529C14.2382 7.07228 14.431 6.83167 14.6485 6.62035C13.1508 5.59926 11.3446 5.0008 9.39653 4.99463H9.37185Z"
                fill="url(#paint0_radial_58343_2104)"/>
            <path
                d="M23.2244 6.70822C22.0105 8.21826 19.7986 8.45888 18.2886 7.24345C16.7785 6.02647 16.5379 3.82079 17.7534 2.30766C18.9688 0.799166 21.1776 0.560089 22.6891 1.77707C24.2007 2.98788 24.4398 5.19972 23.2244 6.70822Z"
                fill={theme.palette.text.primary}/>
            <defs>
                <radialGradient cx="0" cy="0" gradientTransform="translate(5.6114 12.9597) scale(13.6413 13.6413)" gradientUnits="userSpaceOnUse" id="paint0_radial_58343_2104" r="1">
                    <stop stopColor="#C40047"/>
                    <stop
                        offset="0.9"
                        stopColor="#FF0070"/>
                    <stop
                        offset="1"
                        stopColor="#FF0070"/>
                </radialGradient>
            </defs>
        </SvgIcon>);
}