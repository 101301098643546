import { NoneIcon, Optional } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React, { useMemo } from "react";
import { Contract } from "../controllers";
import { useTheme } from "../themes";
import { DataCategory } from "./DataCategory";
import { TopItems } from "./TopItems";

export const orderedDataCategories = [
    Contract.DataCategory.Pii,
    Contract.DataCategory.Pci,
    Contract.DataCategory.Phi,
    Contract.DataCategory.Secrets
];

export type DataCategoriesProps = {
    breakpointToTopCount?: Dictionary<number>;
    categories: Contract.DataCategory[];
    categoryToCountMap?: { [key in Contract.DataCategory]?: number };
    countLoading?: boolean;
    getCategoryActive?: (category: Contract.DataCategory) => Optional<boolean>;
    onClick?: (category: Contract.DataCategory) => void;
    orderVariant?: "leftToRight" | "rightToLeft";
    variant?: "breakpoints" | "dynamic" | "wrap";
};

export function DataCategories({ breakpointToTopCount = { "xs": 4 }, categories, categoryToCountMap, countLoading, getCategoryActive, onClick, orderVariant = "leftToRight", variant = "wrap" }: DataCategoriesProps) {
    const orderedCategories =
        useMemo(
            () =>
                _.orderBy(
                    categories,
                    dataCategory => orderedDataCategories.findIndex(item => item === dataCategory),
                    "asc"),
            [categories]);

    const theme = useTheme();
    return _.isEmpty(categories)
        ? <NoneIcon sx={{ color: theme.border.primary }}/>
        : <TopItems
            breakpointToTopCount={breakpointToTopCount}
            items={orderedCategories}
            itemSizeForLimitCalculations={64}
            orderVariant={orderVariant}
            variant={variant}>
            {(category: Contract.DataCategory) =>
                <DataCategory
                    active={getCategoryActive?.(category)}
                    category={category}
                    count={categoryToCountMap?.[category]}
                    countLoading={countLoading}
                    key={category}
                    onClick={onClick}/>}
        </TopItems>;
}

export const dataCategories = _.keys(Contract.DataCategory) as Contract.DataCategory[];