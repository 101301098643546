﻿import { Link, Shadows } from "@infrastructure";
import { Box, Stack } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { ReactNode, useState } from "react";
import { useTheme } from "../../../../../../../themes";
import { LineMetadata, LinesDataItem } from "../../../../../../../utilities";
import { Chart, LineChartMarginOptions } from "../../../../../../Chart";

type ChartWithLegendProps = {
    className?: string;
    lineChartMargin?: LineChartMarginOptions;
    lineKeyToLegendDataMap: Dictionary<LineLegendData>;
    lineMetadatas: LineMetadata[];
    orderedLinesDataItems: LinesDataItem[];
};

export class LineLegendData {
    constructor(
        public contentElement: ReactNode,
        public id?: string,
        public url?: string) {
    }
}

export function ChartWithLegend({ className, lineChartMargin, lineKeyToLegendDataMap, lineMetadatas, orderedLinesDataItems }: ChartWithLegendProps) {
    const [activeLineKey, setActiveLineKey] = useState<string>();

    const theme = useTheme();
    return (
        <Stack
            className={className}
            sx={{
                height: "100%",
                width: "100%"
            }}>
            <Stack
                alignItems="center"
                direction="row"
                flexWrap="wrap">
                {_.map(
                    lineMetadatas,
                    lineMetadata =>
                        <Box
                            className={`legendItem ${lineKeyToLegendDataMap[lineMetadata.key].id}`}
                            key={lineMetadata.key}
                            sx={{
                                cursor:
                                    _.isNil(lineKeyToLegendDataMap[lineMetadata.key].url)
                                        ? "default"
                                        : "pointer",
                                margin: theme.spacing(0, 1)
                            }}
                            onMouseEnter={() => setActiveLineKey(lineMetadata.key)}
                            onMouseLeave={() => setActiveLineKey(undefined)}>
                            <Link
                                urlOrGetUrl={lineKeyToLegendDataMap[lineMetadata.key].url}
                                variant="text">
                                <Stack
                                    alignItems="center"
                                    direction="row"
                                    sx={{
                                        height: theme.spacing(4),
                                        ...(activeLineKey === lineMetadata.key && {
                                            borderRadius: theme.spacing(0, 0.75, 0.75, 0),
                                            boxShadow: theme.shadows[Shadows.Highlight]
                                        })
                                    }}>
                                    <Box
                                        sx={{
                                            backgroundColor: lineMetadata.color,
                                            height:
                                                activeLineKey !== lineMetadata.key
                                                    ? theme.spacing(1.5)
                                                    : theme.spacing(4),
                                            marginRight: theme.spacing(1),
                                            width: "3px"
                                        }}/>
                                    {lineKeyToLegendDataMap[lineMetadata.key].contentElement}
                                </Stack>
                            </Link>
                        </Box>)}
            </Stack>
            <Box
                sx={{
                    flex: 1,
                    overflow: "hidden",
                    paddingTop: theme.spacing(2),
                    position: "relative"
                }}>
                <Chart
                    activeLineKey={activeLineKey}
                    lineChartMargin={lineChartMargin}
                    lineMetadatas={lineMetadatas}
                    orderedLinesDataItems={orderedLinesDataItems}/>
            </Box>
        </Stack>);
}