import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, EnumValuesFilter, optionalTableCell, PagedValuesFilter, useLocalization } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { useAwsCommonNetworkedResourceDefinition, useAwsCommonResourceDefinition } from ".";
import { VulnerabilitiesCell } from "../../../../../";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, SeverityFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { useAwsEcsServiceLaunchTypeTranslator, useGetAwsEcrRepositoryContainerImageVulnerabilitiesCsvItem } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";

export function useAwsEcsServiceDefinition(definitionData: DefinitionData) {
    const commonNetworkedResourceDefinition = useAwsCommonNetworkedResourceDefinition(definitionData);
    const commonResourceDefinition = useAwsCommonResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const ecsServiceLaunchTypeTranslator = useAwsEcsServiceLaunchTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.aws.useAwsEcsServiceDefinition",
            () => ({
                columns: {
                    containerImages: "Container Images",
                    containerImageVulnerabilities: "Vulnerabilities",
                    containerImageVulnerabilitySeverities: "Vulnerability Severity",
                    launchType: {
                        empty: "-",
                        title: "Launch Type"
                    },
                    taskDefinition: "Task Definition"
                }
            }));

    function getLaunchTypeTranslation(ecsService: Contract.EntityModel) {
        const launchType = (ecsService.entity as Contract.AwsEcsService).launchType;
        return _.isNil(launchType)
            ? localization.columns.launchType.empty()
            : ecsServiceLaunchTypeTranslator(launchType);
    }

    const getAwsEcrRepositoryContainerImageVulnerabilitiesCsvItem = useGetAwsEcrRepositoryContainerImageVulnerabilitiesCsvItem();
    return new EntityTableDefinition(
        _.filter(
            [
                commonResourceDefinition.columns.tenantColumn,
                commonResourceDefinition.columns.creationTimeColumn,
                commonResourceDefinition.columns.creatorIdentityCsvColumn,
                commonResourceDefinition.columns.creatorOriginatorEntityCsvColumn,
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsEcsServiceModel) => ({
                                [localization.columns.launchType.title()]: getLaunchTypeTranslation(item)
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <EnumValuesFilter
                                    enumType={Contract.AwsEcsServiceLaunchType}
                                    enumTypeTranslator={ecsServiceLaunchTypeTranslator}
                                    placeholder={localization.columns.launchType.title()}
                                    sorted={false}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsEcsServiceLaunchType}
                    itemProperty={getLaunchTypeTranslation}
                    key={Contract.EntityModelProperty.AwsEcsServiceLaunchType}
                    title={localization.columns.launchType.title()}/>,
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsEcsServiceModel) => ({
                                [localization.columns.taskDefinition()]:
                                    _.isNil((item.entity as Contract.AwsEcsService).taskDefinitionReference)
                                        ? ""
                                        : (item.entity as Contract.AwsEcsService).taskDefinitionReference!.arn
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedEntityFilter
                                    getEntityIdPage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.AwsEcsServiceTaskDefinition)}
                                    placeholder={localization.columns.taskDefinition()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsEcsServiceTaskDefinition}
                    key={Contract.EntityModelProperty.AwsEcsServiceTaskDefinition}
                    render={
                        optionalTableCell<Contract.AwsResourceModel>(
                            item =>
                                _.isNil((item.entity as Contract.AwsEcsService).taskDefinitionReference)
                                    ? undefined
                                    : <EntitiesCell
                                        entityIdsOrModels={(item.entity as Contract.AwsEcsService).taskDefinitionReference!.idReference}
                                        entityTypeName={Contract.TypeNames.AwsEcsTaskDefinition}
                                        entityVariant="iconText"/>)}
                    title={localization.columns.taskDefinition()}/>,
                commonNetworkedResourceDefinition.columns.accessLevelColumn,
                commonNetworkedResourceDefinition.columns.inboundExternalAccessScopeColumn,
                <DataTableColumn
                    exportOptions={
                        entitiesExportOptions<Contract.AwsEcsServiceModel>(
                            Contract.TypeNames.IContainerImage,
                            item => item.containerImageIdReferences,
                            localization.columns.containerImages())}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedEntityFilter
                                    getEntityIdPage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.AwsEcsServiceContainerImages)}
                                    placeholder={localization.columns.containerImages()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsEcsServiceContainerImages}
                    key={Contract.EntityModelProperty.AwsEcsServiceContainerImages}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.AwsEcsServiceModel>) =>
                            <EntitiesCell
                                entityIdsOrModels={item.containerImageIdReferences}
                                entityTypeName={Contract.TypeNames.IContainerImage}
                                entityVariant="iconText"/>}
                    title={localization.columns.containerImages()}/>,
                <DataTableColumn
                    cellMinWidth={118}
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsEcsServiceModel) => ({
                                [localization.columns.containerImageVulnerabilities()]:
                                    getAwsEcrRepositoryContainerImageVulnerabilitiesCsvItem(
                                        item.containerImageScanStatus,
                                        item.containerImageVulnerabilities)
                            })
                    }}
                    filterOptions={{
                        itemOrItems: [
                            {
                                element:
                                    <PagedValuesFilter
                                        getValuePage={
                                            ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                                definitionData.entityTypeEntitiesViewName,
                                                Contract.EntityModelProperty.AwsEcsServiceContainerImageVulnerabilities)}
                                        placeholder={localization.columns.containerImageVulnerabilities()}/>,
                                id: Contract.EntityModelProperty.AwsEcsServiceContainerImageVulnerabilities,
                                title: localization.columns.containerImageVulnerabilities()
                            },
                            {
                                element:
                                    <SeverityFilter placeholder={localization.columns.containerImageVulnerabilitySeverities()}/>,
                                id: Contract.EntityModelProperty.AwsEcrRepositoryContainerImageVulnerabilitySeverities,
                                title: localization.columns.containerImageVulnerabilitySeverities()
                            }
                        ]
                    }}
                    id={Contract.EntityModelProperty.AwsEcsServiceContainerImageVulnerabilities}
                    key={Contract.EntityModelProperty.AwsEcsServiceContainerImageVulnerabilities}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.AwsEcsServiceModel>) =>
                            <VulnerabilitiesCell
                                containerImageScanStatus={item.containerImageScanStatus}
                                vulnerabilities={item.containerImageVulnerabilities}/>}
                    sortOptions={{ type: DataTableSortType.Numeric }}
                    title={localization.columns.containerImageVulnerabilities()}/>,
                commonNetworkedResourceDefinition.columns.getVpcsColumn(),
                commonNetworkedResourceDefinition.columns.securityGroupsColumn,
                commonResourceDefinition.columns.regionColumn,
                commonResourceDefinition.columns.regionLocationColumn,
                commonResourceDefinition.columns.tagsColumn,
                commonResourceDefinition.columns.attributesColumn,
                commonResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
                commonResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
                commonResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
            ]),
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAwsEcsServiceRequest(
                new Contract.EntityControllerGetEntityModelPageAwsEcsServiceRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEcsServiceContainerImages]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEcsServiceContainerImageVulnerabilities]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEcrRepositoryContainerImageVulnerabilitySeverities]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEcsServiceLaunchType]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEcsServiceTaskDefinition])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags])),
                new Contract.EntityControllerGetEntityModelPageEntityRequestNetworkAccess(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.NetworkInboundAccessType]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.NetworkInboundExternalAccessScope]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.NetworkInboundExternalDestinationNetworkScopes])),
                new Contract.EntityControllerGetEntityModelPageAwsResourceRequestNetworked(
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsNetworkedResourceSecurityGroups]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsNetworkedResourceVpcs]))));
}