import { ActionMenuItem, Menu, Optional, useLocalization } from "@infrastructure";
import { Box, Stack } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { Fragment, useState } from "react";
import { Contract, riskPolicyModelStore, UserHelper } from "../../common";
import { SecretExclusionAction } from "./SecretExclusionAction";

type ValueSecretData = {
    secret: boolean;
    value: Optional<string>;
};

type SecretExclusionActionCellProps = {
    displayNameToValueSecretDataMap: Dictionary<ValueSecretData>;
    risk: Contract.Risk;
    secretKey: string;
};

export function SecretExclusionActionCell({ displayNameToValueSecretDataMap, risk, secretKey }: SecretExclusionActionCellProps) {
    const [exclude, setExclude] = useState<boolean>(false);
    const [excludeSecretValue, setExcludeSecretValue] = useState<string>();
    const { riskPolicyConfiguration } = riskPolicyModelStore.useGet(risk.policyId);

    const localization =
        useLocalization(
            "common.secretExclusionActionCell",
            () => ({
                actionTitle: "Exclude **{{secretDisplayName}}** from this policy"
            }));

    return (
        <Fragment>
            {exclude &&
                <SecretExclusionAction
                    riskPolicyConfiguration={riskPolicyConfiguration}
                    secretKey={secretKey}
                    secretValue={excludeSecretValue}
                    onExcludeChanged={setExclude}/>}
            <Stack
                alignItems="center"
                direction="row"
                justifyContent="flex-end"
                spacing={1}>
                <Box
                    sx={{
                        visibility:
                            UserHelper.hasScopePermissions(riskPolicyConfiguration.scopeId, Contract.IdentityPermission.SecurityWrite)
                                ? undefined
                                : "hidden"
                    }}>
                    <Menu
                        itemsOrGetItems={
                            _(displayNameToValueSecretDataMap).
                                map(
                                    (secretValueData, secretDisplayName) =>
                                        new ActionMenuItem(
                                            () => {
                                                setExcludeSecretValue(secretValueData.value ?? "");
                                                setExclude(true);
                                            },
                                            localization.actionTitle({ secretDisplayName: secretDisplayName.toLowerCase() }),
                                            {
                                                disabled: !secretValueData.secret,
                                                tooltip:
                                                    secretValueData.secret
                                                        ? undefined
                                                        : "This value is not a secret"
                                            })).
                                value()}/>
                </Box>
            </Stack>
        </Fragment>);
}