import { Contract } from "../../../../../controllers";
import { EntityDefinition } from "../useDefinition";

export function useAwsElbLoadBalancerListenerDefinition(entityModel: Contract.EntityModel) {
    return new EntityDefinition(
        entityModel,
        {
            glanceOptions: {
                entityId:
                    entityModel.unknown
                        ? undefined
                        : (entityModel.entity as Contract.AwsElbLoadBalancerListener).loadBalancerReference.idReference
            }
        });
}