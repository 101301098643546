﻿import { FormLayout, makeContextProvider, OrderedWizard, OrderedWizardItem, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract } from "../../../../../../../../../../../../../../common";
import { useCloudsmithIntegrationContext, useSetCloudsmithIntegrationContext } from "../../CloudsmithIntegration";
import { ConfigureIntegrationItem, FinishItem } from "./components";


export class AddOrEditContext {
    public integrationEndpoint?: string;
    public integrationEndpointConnectorId?: string;
    public integrationFolderEnabled?: boolean;
    public integrationName: string;
    public integrationPassword?: string;
    public integrationServerCertificateValidationEnabled: boolean;
    public updatedFolderScopeNodeModel?: Contract.ScopeNodeModel;

    constructor(
        public integrationModel?: Contract.CiIntegrationModel) {
        const cloudsmithIntegrationConfiguration =
            _.isNil(integrationModel?.configuration)
                ? undefined
                : _.as<Contract.CiCloudsmithIntegrationConfiguration>(integrationModel?.configuration);
        this.integrationEndpoint = cloudsmithIntegrationConfiguration?.endpoint;
        this.integrationEndpointConnectorId = cloudsmithIntegrationConfiguration?.endpointConnectorId;
        this.integrationFolderEnabled = cloudsmithIntegrationConfiguration?.folderEnabled;
        this.integrationName = cloudsmithIntegrationConfiguration?.name ?? "";
        this.integrationPassword =
            _.isNil(integrationModel?.configuration)
                ? ""
                : undefined;
        this.integrationServerCertificateValidationEnabled = integrationModel?.configuration?.serverCertificateValidationEnabled ?? true;
    }
}

export const [useAddOrEditContext, useSetAddOrEditContext, useAddOrEditContextProvider] = makeContextProvider<AddOrEditContext>();

export function AddOrEdit() {
    const { addOrEditOpen } = useCloudsmithIntegrationContext();
    const setCloudsmithIntegrationContext = useSetCloudsmithIntegrationContext();

    const [, , ContextProvider] =
        useAddOrEditContextProvider(
            () =>
                new AddOrEditContext(
                    _.isBoolean(addOrEditOpen)
                        ? undefined
                        : addOrEditOpen));

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCiIntegrationItems.cloudsmithIntegration.addOrEdit",
            () => ({
                steps: {
                    configureIntegration: "Add Cloudsmith Organization",
                    integration: "Configure Authentication Details"
                },
                title: {
                    add: "Add Cloudsmith Organization",
                    edit: "Edit Cloudsmith Organization"
                }
            }));

    return (
        <ContextProvider>
            <FormLayout
                disableContentPadding={true}
                titleOptions={{
                    text:
                        _.isBoolean(addOrEditOpen)
                            ? localization.title.add()
                            : localization.title.edit()
                }}>
                <OrderedWizard
                    finishItemElement={<FinishItem/>}
                    onClose={
                        () =>
                            setCloudsmithIntegrationContext(
                                context => ({
                                    ...context,
                                    addOrEditOpen: false
                                }))}>
                    <OrderedWizardItem title={localization.steps.integration()}>
                        <ConfigureIntegrationItem/>
                    </OrderedWizardItem>
                </OrderedWizard>
            </FormLayout>
        </ContextProvider>);
}