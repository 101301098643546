import { useLocalization } from "@infrastructure";
import { useMemo } from "react";
import { Contract, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { useGcpNotebooksInstanceStatusTranslator } from "../../../../../../../../../Entities/hooks";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetGcpEntityRiskContext } from "./useGetGcpEntityRiskContext";

export function useGetGcpNotebooksInstanceRiskContext() {
    return useMemo(
        () => useGcpNotebooksInstanceRiskContext,
        []);
}

function useGcpNotebooksInstanceRiskContext(instanceModel: Contract.GcpNotebooksInstanceModel) {
    const entityRiskContext = useGetGcpEntityRiskContext()(instanceModel);
    const instance = instanceModel.entity as Contract.GcpNotebooksInstance;

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const notebooksInstanceStatusTranslator = useGcpNotebooksInstanceStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.contexts.useGetGcpNotebooksInstanceRiskContext",
            () => ({
                status: "The {{translatedInstanceTypeName}} state is {{status}}"
            }));

    const translatedInstanceTypeName =
        entityTypeNameTranslator(
            instance.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });

    return {
        ...entityRiskContext,
        status:
            new RiskDefinitionContextItem(
                localization.status({
                    status: notebooksInstanceStatusTranslator(instance.status),
                    translatedInstanceTypeName
                }))
    };
}