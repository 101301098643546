import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { useAwsCommonBehaviorIdentityRiskDefinition } from "./useAwsCommonBehaviorIdentityRiskDefinition";

export function useAwsNetworkAccessManagementRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsNetworkAccessManagementRisk;
    const identityModel = entityModelStore.useGet(risk.identityId);
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.behavior.useAwsNetworkAccessManagementRiskDefinition",
            () => ({
                description: "{{identity}} was observed modifying network access settings"
            }));

    return useAwsCommonBehaviorIdentityRiskDefinition(
        riskModel,
        localization.description({
            identity:
                <Entity
                    entityIdOrModel={identityModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }));
}