import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function XMCyberIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                d="M5.88848 2L6.20074 2.04461L6.9145 2.40149L7.98513 3.02602L9.14498 3.69517L11.2862 4.94424L11.9108 5.30112H12.1338L13.026 4.7658L15.3457 3.42751L16.5948 2.71375L17.2639 2.31227L17.8439 2H18.1115L18.8253 2.40149L19.4052 2.84758L19.8959 3.24907L20.342 3.65056L20.9219 4.23048L21.3234 4.72119L21.8141 5.34573L22.3494 6.19331L22.8848 7.21933L23.197 7.93309L23.4647 8.69145L23.7323 9.67286L23.9108 10.6097L24 11.4572V13.197L23.8662 14.3123L23.6431 15.3383L23.2862 16.4981L22.7063 17.7918L22.2602 18.5948L21.9033 19.1301L21.5465 19.6208L21.0112 20.2454L20.6097 20.6915L19.9851 21.316L19.539 21.6729L18.7807 22.2528L18.1561 22.6097L17.9331 22.6543L17.2193 22.2974L16.5502 21.8959L15.6134 21.3606L14.5428 20.7361L13.3383 20.0223L12.223 19.3978L12.0892 19.3085H11.8662L11.5093 19.5316L10.3494 20.2007L9.05576 20.9591L6.73606 22.2974L6.28996 22.5651L5.93309 22.6097L5.62082 22.4758L5.13011 22.1636L4.5948 21.7621L3.97026 21.2268L3.61338 20.8699L3.52416 20.8253V20.7361H3.43494L3.12268 20.3792L2.63197 19.8439L2.05204 19.0409L1.56134 18.2379L1.02602 17.2119L0.669145 16.3197L0.312268 15.0706L0.133829 14.1338L0.0446097 13.4647L0 12.8848V11.6803L0.0892193 10.7435L0.267658 9.71747L0.535316 8.69145L0.847584 7.79926L1.47212 6.50558L2.00743 5.61338L2.54275 4.89963L2.94424 4.45353L3.25651 4.09665L4.1487 3.20446L4.5948 2.84758L5.39777 2.26766L5.88848 2Z"
                fill="#62D8FD"/>
            <path
                d="M5.88848 2L6.20074 2.04461L6.9145 2.40149L7.98513 3.02602L9.14498 3.69517L11.2862 4.94424L11.8662 5.30112V5.47955L11.3755 5.74721L10.7509 6.10409L8.34201 7.44238L7.58364 7.88848L7.13755 8.15614L6.78067 8.33457L6.37918 8.64684L6.06692 9.27138L5.9777 9.67286V14.9368L6.11152 15.5613L6.37918 15.9628L6.73606 16.2305L7.94052 16.8996L9.01115 17.5242L10.2156 18.1933L11.3755 18.8625L11.8216 19.1301L11.8662 19.3086L11.5093 19.5316L10.3494 20.2007L9.05576 20.9591L6.73606 22.2974L6.28996 22.5651L5.93309 22.6097L5.62082 22.4758L5.13011 22.1636L4.5948 21.7621L3.97026 21.2268L3.61338 20.8699L3.52416 20.8253V20.7361H3.43494L3.12268 20.3792L2.63197 19.8439L2.05204 19.0409L1.56134 18.2379L1.02602 17.2119L0.669145 16.3197L0.312268 15.0706L0.133829 14.1338L0.0446097 13.4647L0 12.8848V11.6803L0.0892193 10.7435L0.267658 9.71747L0.535316 8.69145L0.847584 7.79926L1.47212 6.50558L2.00744 5.61338L2.54275 4.89963L2.94424 4.45353L3.25651 4.09665L4.1487 3.20446L4.5948 2.84758L5.39777 2.26766L5.88848 2Z"
                fill="#52FDB7"/>
            <path
                d="M11.9108 5.39038L12.1785 5.43499L13.2491 6.05953L14.3643 6.68406L15.3904 7.26399L16.5948 7.93313L17.487 8.51306L17.7101 8.6915L17.9777 9.09298L18.0669 9.44986V14.1785L18.0223 15.2491L17.8439 15.7398L17.5316 16.0967L16.8625 16.4982L15.6134 17.2119L14.8104 17.658L13.8736 18.1934L12.5799 18.9517L12.0446 19.2194L11.8216 19.1748L11.3755 18.9071L10.1264 18.1934L8.83273 17.4796L7.85132 16.8997L6.64686 16.2305L6.24537 15.8737L6.06693 15.5614L5.93311 14.9369V9.67291L6.06693 9.13759L6.33459 8.64689L6.60225 8.42384L7.00374 8.15618L7.49444 7.88852L8.29742 7.44243L9.23422 6.90711L11.3309 5.74726L11.6431 5.56882L11.9108 5.39038Z"
                fill="#FAFBFB"/>
            <path
                d="M7.36085 7.30835H7.67312L7.71773 7.39757L7.67312 7.5314L7.22702 7.84367L6.78092 8.06671L6.29022 8.42359L6.15639 8.55742L6.02256 8.95891V9.09274L6.15639 8.82508L6.24561 8.86969L6.02256 9.405L5.97795 9.67266V14.9366L6.11178 15.5611L6.37944 15.9626L6.42405 16.0518L6.24561 15.9626L6.11178 15.8288L5.93334 15.9626L5.71029 15.8288L5.39802 15.4719L5.17498 15.2489L4.90732 14.892L4.59505 14.089L4.50583 13.6429L4.41661 13.4199L4.32739 12.3046V11.6355L4.46122 10.5649L4.68427 9.94032L4.77349 9.62805L5.04115 9.09274L5.35341 8.55742L5.71029 8.24515L6.6471 7.66523L7.36085 7.30835Z"
                fill="#87FDCB"/>
            <path
                d="M18.0225 8.37915L18.201 8.46837L18.3348 8.6022L18.6471 8.78064L18.8701 9.13751L19.1378 9.76205L19.2716 10.1635V10.2974H19.3608L19.5393 10.9665L19.5839 11.5464L19.4947 11.8141V11.9033L19.5839 11.9479L19.5393 12.4386L19.3162 13.5093L19.227 14.0892L19.0932 14.3122L19.004 14.7137L18.8701 14.9368L18.7809 15.249L18.5132 15.5613L18.201 15.6951L18.1564 15.2044L18.1118 15.026L18.0225 15.2044V9.44978L17.8887 9.00369L17.7549 8.78064L17.8441 8.73603V8.82525H17.9333L17.8441 8.64681L18.0225 8.37915Z"
                fill="#93E3F8"/>
            <path
                d="M6.24536 16.3643H6.60224L6.64685 16.5873H7.00373L7.13755 16.7211L7.09295 16.9442L6.78068 16.8996L6.69146 16.6765L6.4238 16.6319L6.24536 16.4535V16.3643Z"
                fill="#88FECB"/>
            <path
                d="M13.8739 4.27515L14.0077 4.31976L13.7846 4.45359L13.6062 4.67663L13.0709 4.94429L12.8032 4.9889L12.8478 4.85507L13.6508 4.40898L13.8739 4.27515Z"
                fill="#84E0F9"/>
            <path
                d="M11.4646 5.12256L11.6876 5.16717L11.8661 5.301V5.47944L11.5984 5.61326V5.47944L11.5092 5.43483L11.4646 5.12256Z"
                fill="#80FACE"/>
            <path
                d="M15.7474 3.2041L15.8366 3.24871L15.5243 3.47176L15.0336 3.73942L14.989 3.82864L14.6768 3.91786L14.7214 3.78403L15.7474 3.2041Z"
                fill="#82DFFA"/>
            <path
                d="M10.1711 4.36401L10.3942 4.40862L10.6618 4.58706V4.72089L10.5726 4.67628L10.4388 4.63167V4.54245L10.2158 4.49784L10.1711 4.36401Z"
                fill="#82F8D2"/>
        </SvgIcon>);
}