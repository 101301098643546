import { map, Message, useExecuteOperation, useLocalization, useOrderedWizardContext } from "@infrastructure";
import { Box, Stack, Tab, Tabs } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { Contract, TenantController, useTheme } from "../../../../../../../../../../../../common";
import { useAddOrEditContext } from "../../index";
import { InlineMissingItems } from "../InlineMissingItems";
import { CloudShell, Manual } from "./components";

export function TenantRolesItem() {
    const { gciTenantModel, parentFolderId, permissionTypes, rawShortNameId, tenantModel, tenantTerraformStateBucketEnabled } = useAddOrEditContext();
    const { setLoaded, useNextEffect } = useOrderedWizardContext();

    function isTenantValid(validateGcpTenantResponse: Contract.TenantControllerValidateGcpTenantResponse) {
        return (
            validateGcpTenantResponse.result === Contract.GcpTenantManagerValidateTenantResult.Valid ||
            validateGcpTenantResponse.result == Contract.GcpTenantManagerValidateTenantResult.ServiceAccountTenantBillingNotEnabled ||
            validateGcpTenantResponse.result == Contract.GcpTenantManagerValidateTenantResult.ServiceAccountTenantServiceApiHostNamesMismatch);
    }

    const [validTenant] =
        useExecuteOperation(
            TenantRolesItem,
            async () => {
                const validateGcpTenantResponse =
                    await TenantController.validateGcpTenant(
                        new Contract.TenantControllerValidateGcpTenantRequest(
                            gciTenantModel!.configuration.id,
                            parentFolderId,
                            permissionTypes!,
                            rawShortNameId!));
                return isTenantValid(validateGcpTenantResponse);
            });
    useEffect(
        () => {
            setLoaded();
        },
        []);

    const localization =
        useLocalization(
            "views.customer.scopes.hooks.useDefinition.hooks.useGcpDefinition.addOrEdit.tenantRolesItem",
            () => ({
                actions: {
                    next: {
                        error: {
                            general: "Failed to validate organization",
                            validation: {
                                serviceAccountMissingOrganizationRoleNames: {
                                    roleNames: [
                                        "1 mandatory role",
                                        "{{count | NumberFormatter.humanize}} mandatory roles"
                                    ],
                                    text: "Missing {{permissionActions}} at the organization scope"
                                },
                                serviceAccountMissingRoleNames: {
                                    roleNames: [
                                        "1 mandatory role",
                                        "{{count | NumberFormatter.humanize}} mandatory roles"
                                    ],
                                    text: "Missing {{roleNames}}"
                                },
                                [Contract.TypeNames.GcpTenantManagerValidateTenantResult]: {
                                    [Contract.GcpTenantManagerValidateTenantResult.GciTenantIssue]: "Google workspace is not connected",
                                    [Contract.GcpTenantManagerValidateTenantResult.ServiceAccountDataAnalysisPermissionsMismatch]: "Missing data protection permissions",
                                    [Contract.GcpTenantManagerValidateTenantResult.ServiceAccountUnauthorized]: "Service account is not authorized. Error: {{serviceAccountUnauthorizedErrorMessage}}",
                                    [Contract.GcpTenantManagerValidateTenantResult.ServiceAccountWorkloadAnalysisPermissionsMismatch]: "Missing workload protection permissions",
                                    [Contract.GcpTenantManagerValidateTenantResult.TenantDeletePending]: "GCP project is pending deletion",
                                    [Contract.GcpTenantManagerValidateTenantResult.TenantNotExist]: "GCP project does not exist",
                                    [Contract.GcpTenantManagerValidateTenantResult.TenantRawShortNameIdNotValid]: "GCP project ID format is invalid",
                                    [Contract.GcpTenantManagerValidateTenantResult.TenantSuspended]: "GCP project is suspended"
                                }
                            }
                        }
                    }
                },
                configured: "Tenable identified that the required roles were already assigned at the project level. You can continue with the next step.",
                onboardingType: {
                    [OnboardingType.CloudShell]: "gcloud CLI (Cloud Shell)",
                    [OnboardingType.Manual]: "Manual (Google Cloud Console)"
                }
            }));

    const onboardingTypes =
        useMemo(
            () =>
                _<OnboardingType>([]).
                    concatIf(
                        _.isNil(tenantModel),
                        OnboardingType.CloudShell).
                    concat(OnboardingType.Manual).
                    value(),
            []);

    const [selectedOnboardingType, setSelectedOnboardingType] = useState(() => _.first(onboardingTypes)!);
    useNextEffect(
        async () => {
            try {
                if (validTenant) {
                    return undefined;
                }

                const validateGcpTenantResponse =
                    await TenantController.validateGcpTenant(
                        new Contract.TenantControllerValidateGcpTenantRequest(
                            gciTenantModel!.configuration.id,
                            parentFolderId,
                            permissionTypes!,
                            rawShortNameId!));

                if (isTenantValid(validateGcpTenantResponse)) {
                    return undefined;
                }

                if (validateGcpTenantResponse.result === Contract.GcpTenantManagerValidateTenantResult.ServiceAccountMandatoryOrganizationPermissionsMismatch) {
                    return localization.actions.next.error.validation.serviceAccountMissingOrganizationRoleNames.text({
                        roleNames:
                            <InlineMissingItems
                                items={validateGcpTenantResponse.serviceAccountMissingOrganizationRoleNames!}
                                namePluralizer={localization.actions.next.error.validation.serviceAccountMissingOrganizationRoleNames.roleNames}/>
                    });
                }

                if (
                    validateGcpTenantResponse.result === Contract.GcpTenantManagerValidateTenantResult.ServiceAccountMandatoryPermissionsMismatch ||
                    validateGcpTenantResponse.result === Contract.GcpTenantManagerValidateTenantResult.ServiceAccountOptionalRoleMismatch) {
                    return localization.actions.next.error.validation.serviceAccountMissingRoleNames.text({
                        roleNames:
                            <InlineMissingItems
                                items={validateGcpTenantResponse.serviceAccountMissingRoleNames!}
                                namePluralizer={localization.actions.next.error.validation.serviceAccountMissingRoleNames.roleNames}/>
                    });
                }

                return localization.actions.next.error.validation[Contract.TypeNames.GcpTenantManagerValidateTenantResult].translate(
                    validateGcpTenantResponse.result,
                    {
                        serviceAccountUnauthorizedErrorMessage: validateGcpTenantResponse.serviceAccountUnauthorizedErrorMessage
                    });

            } catch {
                return localization.actions.next.error.general();
            }
        },
        [selectedOnboardingType]);

    const theme = useTheme();
    return (validTenant &&
        (!tenantTerraformStateBucketEnabled ||
            _.isEmpty(tenantModel?.state.monitoring.accessDeniedTerraformStateBucketNames)))
        ? <Message
            level="success"
            title={localization.configured()}/>
        : <Stack
            spacing={2}
            sx={{ height: "100%" }}>
            <Tabs
                indicatorColor="primary"
                value={selectedOnboardingType}
                variant="standard"
                onChange={(_, value) => setSelectedOnboardingType(value)}>
                {_.map(
                    onboardingTypes,
                    onboardingType =>
                        <Tab
                            key={onboardingType}
                            label={localization.onboardingType[onboardingType]()}
                            sx={{ padding: theme.spacing(1, 2) }}
                            value={onboardingType}/>)}
            </Tabs>
            <Box
                key={selectedOnboardingType}
                sx={{ overflow: "hidden auto" }}>
                <Box sx={{ maxWidth: theme.spacing(80) }}>
                    {map(
                        selectedOnboardingType,
                        {
                            [OnboardingType.CloudShell]: () => <CloudShell/>,
                            [OnboardingType.Manual]: () => <Manual/>
                        })}
                </Box>
            </Box>
        </Stack>;
}

enum OnboardingType {
    CloudShell = "cloudShell",
    Manual = "manual"
}