import { TimeFormatter, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, InfoItem, useTenantNameTranslator } from "../../../../../../../../../../common";
import { useCommonEntityInfoItemElements } from "../useCommonEntityInfoItemElements";

export function useAadCommonDirectoryEntityInfoItemElements(directoryEntityModel: Contract.AadDirectoryEntityModel) {
    const commonEntityInfoItemElements = useCommonEntityInfoItemElements(directoryEntityModel);
    const tenantNameTranslator = useTenantNameTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aad.useAadCommonDirectoryEntityInfoItemElements",
            () => ({
                creationTime: "Creation Time",
                rawId: "Object ID",
                syncTime: "Sync Time",
                tenant: "Tenant"
            }));
    return {
        ...commonEntityInfoItemElements,
        creationTimeInfoItemElement:
            <InfoItem
                key="creationTime"
                location="all"
                title={localization.creationTime()}
                value={
                    _.isNil((directoryEntityModel.entity as Contract.AadDirectoryEntity).creationTime)
                        ? undefined
                        : TimeFormatter.profileFormatDateTime((directoryEntityModel.entity as Contract.AadDirectoryEntity).creationTime)}/>,
        rawIdInfoItemElement:
            <InfoItem
                key="rawId"
                title={localization.rawId()}
                value={(directoryEntityModel.entity as Contract.AadDirectoryEntity).rawId}/>,
        syncTimeInfoItemElement:
            <InfoItem
                key="syncTime"
                title={localization.syncTime()}
                value={TimeFormatter.profileFormatDateTime(directoryEntityModel.entity.syncTime)}/>,
        tenantInfoItemElement:
            <InfoItem
                key="tenant"
                title={localization.tenant()}
                value={tenantNameTranslator(directoryEntityModel.entity.tenantId, { includeRawId: true })}/>
    };
}