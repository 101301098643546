import { DeleteIcon, useLocalization } from "@infrastructure";
import React from "react";
import { ProfileTopbarInfoItem } from "../../../../../../../../../common";

type ProfileTopbarDeletedInfoItemProps = {
    deletedTime: string;
};

export function ProfileTopbarDeletedInfoItem({ deletedTime }: ProfileTopbarDeletedInfoItemProps) {
    const localization =
        useLocalization(
            "views.customer.entities.profile.topbar.profileTopbarDeletedInfoItem",
            () => ({
                deleted: "Deleted at {{ deletedTime | TimeFormatter.mediumDateShortTime }}",
                tooltip: "Deleted"
            }));

    return (
        <ProfileTopbarInfoItem
            icon={<DeleteIcon/>}
            text={localization.deleted({ deletedTime })}
            titleOrGetTitle={localization.tooltip()}/>);
}