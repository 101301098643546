import { Optional, Step, useLocalization } from "@infrastructure";
import _ from "lodash";
import { ReactNode } from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../..";
import { Contract } from "../../../../../../../../../../../../common";
import { RiskDefinitionSection } from "../../../../../../utilities";

export function useAwsCommonComplianceRiskDefinition(
    description: string,
    detailsSectionElement: Optional<ReactNode>,
    resolutionSection: AwsCommonComplianceRiskDefinitionResolutionSection,
    riskModel: Contract.RiskModel,
    contextSectionElement?: ReactNode) {
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsCommonComplianceRiskDefinition",
            () => ({
                sections: {
                    details: "Details"
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        description,
        () => resolutionSection.steps,
        riskModel,
        undefined,
        {
            contextSectionElement,
            sections:
                _.isNil(detailsSectionElement)
                    ? undefined
                    : [
                        new RiskDefinitionSection(
                            detailsSectionElement,
                            localization.sections.details())
                    ]
        });
}

export class AwsCommonComplianceRiskDefinitionResolutionSection {
    constructor(
        public steps: (string | Step)[]) {
    }
}