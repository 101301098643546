import { GcpRoleBindingHelper } from "../../../../../../tenants";
import { Contract } from "../../../../../controllers";
import { useEntityTypeNameTranslator } from "../../../../../hooks";
import { EntityDefinition } from "../useDefinition";

export function useGcpIamRoleBindingDefinition(entityModel: Contract.EntityModel) {
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const roleBindingModel = entityModel as Contract.GcpIamRoleBindingModel;
    return new EntityDefinition(
        entityModel,
        {
            displayName: GcpRoleBindingHelper.getDisplayName(entityTypeNameTranslator, roleBindingModel)
        });
}