import { useLocalization, useQueryParameters } from "@infrastructure";
import { Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { MailImg, NotificationMail } from "..";
import { Contract, PermissionManagementHelper, PermissionManagementTenantType, useTenantTypeTranslator, useTheme } from "../../../../common";
import { useResourcesTranslator } from "./hooks";

type ActivatedPermissionAssignmentRequestMailQueryParameters = {
    additionalGroupCount?: string;
    additionalRoleCount: string;
    additionalTenantCount: string;
    approverUserName: string;
    consoleUrl?: string;
    granteeUserName: string;
    groupNames?: string | string[];
    permissionRequestType: Contract.PermissionRequestMailPermissionRequestType;
    roleNames?: string | string[];
    tenantDisplayReferences: string | string[];
    tenantNames: string | string[];
    tenantType: PermissionManagementTenantType;
};

export function ExportActivatedPermissionAssignmentRequestMail() {
    const { additionalGroupCount, additionalRoleCount, additionalTenantCount, approverUserName, consoleUrl, granteeUserName, groupNames, permissionRequestType, roleNames, tenantDisplayReferences, tenantNames, tenantType } = useQueryParameters<ActivatedPermissionAssignmentRequestMailQueryParameters>();
    const localization =
        useLocalization(
            "views.system.exportPermissionRequestMail.exportActivatedPermissionAssignmentRequestMail",
            () => ({
                disclaimer: "*It may take up to 10 minutes for {{translatedTenantType}} to apply the access",
                link: {
                    [Contract.TypeNames.TenantType]: {
                        [Contract.TenantType.Aad]: "Go to Azure Portal",
                        [Contract.TenantType.Aws]: "Go to AWS Console",
                        [Contract.TenantType.Azure]: "Go to Azure Portal",
                        [Contract.TenantType.Gci]: "Go to GCP Console",
                        [Contract.TenantType.Gcp]: "Go to GCP Console",
                        [Contract.TenantType.Okta]: "Go to Okta Console",
                        [Contract.TenantType.OneLogin]: "Go to OneLogin Console",
                        [Contract.TenantType.PingIdentity]: "Go to Ping Identity Application Portal"
                    }
                },
                mailTitle: "{{approverUserName}} has approved your access request",
                subtitle: "**{{approverUserName}}** has {{approvedElement}} your access request to {{resources}}.",
                title: "Hi {{granteeUserName}},"
            }));
    const resourcesTranslator = useResourcesTranslator();

    const tenantTypeTranslator = useTenantTypeTranslator();
    return (
        <NotificationMail
            footer={
                tenantType === Contract.TenantType.Azure ||
                    tenantType === Contract.TenantType.Gcp
                    ? localization.disclaimer({ translatedTenantType: tenantTypeTranslator(tenantType) })
                    : undefined}
            link={
                _.isNil(consoleUrl)
                    ? undefined
                    : {
                        text: localization.link[Contract.TypeNames.TenantType][tenantType](),
                        value: consoleUrl
                    }}
            mailTitle={localization.mailTitle({ approverUserName })}
            subtitle={
                localization.subtitle(
                    {
                        approvedElement: <Approved/>,
                        approverUserName,
                        resources:
                            resourcesTranslator(
                                permissionRequestType,
                                additionalGroupCount,
                                additionalRoleCount,
                                additionalTenantCount,
                                groupNames,
                                roleNames,
                                tenantDisplayReferences,
                                tenantNames)
                    })}
            title={localization.title({ granteeUserName })}/>);
}

function Approved() {
    const localization =
        useLocalization(
            "views.system.exportPermissionRequestMail.exportActivatedPermissionAssignmentRequestMail.approved",
            () => ({
                text: "approved"
            }));

    const theme = useTheme();
    return (
        <Typography
            component="span"
            style={{ color: PermissionManagementHelper.approvedPermissionRequestColor }}>
            <MailImg
                alt="Approved icon"
                height="18"
                src="cid:PermissionRequestApproved"
                style={{
                    display: "inline",
                    paddingRight: theme.spacing(0.5),
                    verticalAlign: "middle"
                }}
                width="18"/>
            {localization.text()}
        </Typography>);
}