import { Action0, AnalyticsOptions, map, Message, Optional, Shadows, Sx, useEntityMiniGlance } from "@infrastructure";
import { Box, ClickAwayListener, Popover, PopoverActions, PopoverProps, Popper, Stack, SxProps, Typography, useTheme } from "@mui/material";
import _ from "lodash";
import React, { Fragment, MouseEvent, ReactNode, Ref, useCallback, useLayoutEffect, useRef, useState } from "react";
import ResizeObserver from "react-resize-observer";
import { useActions, useTrackAnalytics } from "../hooks";

export type DropdownProps = {
    actionsRef?: Ref<Optional<DropdownActions>>;
    analyticsOptions?: DropdownAnalyticsOptions;
    children: ReactNode | ((open: boolean) => ReactNode);
    className?: string;
    containerSx?: SxProps;
    disabled?: boolean;
    fullWidth?: boolean;
    infoMessage?: string;
    inline?: boolean;
    justify?: "left" | "center" | "right";
    onClose?: Action0;
    onOpen?: Action0;
    open?: boolean;
    popoverElement: ReactNode | (() => ReactNode);
    popoverElementContainerSx?: SxProps;
    popoverPropsLocation?: Pick<PopoverProps, "anchorOrigin" | "transformOrigin">;
    sx?: SxProps;
    title?: string;
};

export type DropdownActions = {
    close: Action0;
};

export type DropdownAnalyticsOptions = AnalyticsOptions<"onClose" | "onOpen">;

export function Dropdown({ actionsRef, analyticsOptions, children, className, containerSx, disabled, fullWidth, infoMessage, inline = false, justify = "left", onClose, onOpen, open = false, popoverElement, popoverElementContainerSx = {}, popoverPropsLocation, sx, title }: DropdownProps) {
    const childElementRef = useRef<HTMLDivElement | null>(null);
    const popoverActionsRef = useRef<PopoverActions | null>(null);
    const [showDropdownElement, setShowDropdownElement] = useState(false);
    useLayoutEffect(() => setShowDropdownElement(open), [open]);
    const trackAnalytics = useTrackAnalytics();
    const closeDropdown =
        useCallback(
            () => {
                onClose?.();
                setShowDropdownElement(false);

                if (analyticsOptions?.onClose) {
                    trackAnalytics(analyticsOptions.onClose);
                }
            },
            [analyticsOptions, onClose]);

    const openDropdown =
        useCallback(
            () => {
                onOpen?.();
                setShowDropdownElement(true);

                if (analyticsOptions?.onOpen) {
                    trackAnalytics(analyticsOptions.onOpen);
                }
            },
            [analyticsOptions, onOpen]);

    useActions(
        actionsRef,
        {
            close: () => closeDropdown()
        });

    const theme = useTheme();
    const dropdownElement =
        <Stack
            className={className}
            sx={containerSx}>
            {!_.isNil(title) && (
                <Stack
                    alignItems="center"
                    direction="row"
                    sx={{ borderBottom: theme.border.primary }}>
                    <Box sx={{ flex: 1 }}>
                        <Typography
                            noWrap={true}
                            sx={{
                                fontWeight: 600,
                                padding: theme.spacing(1, 2)
                            }}>
                            {title}
                        </Typography>
                    </Box>
                    {!_.isNil(infoMessage) && (
                        <Box sx={{ margin: theme.spacing(1, 2) }}>
                            <Message
                                level="info"
                                title={
                                    <Typography sx={{ whiteSpace: "pre-wrap" }}>
                                        {infoMessage}
                                    </Typography>}
                                variant="minimal"/>
                        </Box>)}
                </Stack>)}
            <Box
                sx={{
                    maxHeight: theme.spacing(62),
                    padding: theme.spacing(1),
                    ...popoverElementContainerSx
                }}>
                {_.isFunction(popoverElement)
                    ? popoverElement()
                    : popoverElement}
            </Box>
        </Stack>;

    const entityMiniGlance = useEntityMiniGlance();
    const defaultSx = {
        color:
            disabled
                ? theme.palette.text.disabled
                : theme.palette.text.primary,
        cursor:
            disabled
                ? "default"
                : "pointer"
    };
    return (
        <Fragment>
            {entityMiniGlance
                ? <ClickAwayListener
                    mouseEvent="onMouseUp"
                    onClickAway={closeDropdown}>
                    <Popper
                        anchorEl={childElementRef.current}
                        disablePortal={true}
                        modifiers={[
                            {
                                enabled: true,
                                name: "preventOverflow",
                                options: {
                                    tether: true
                                }
                            }
                        ]}
                        open={showDropdownElement}
                        placement={
                            map(
                                justify,
                                {
                                    "center": () => "bottom",
                                    "left": () => "bottom-start",
                                    "right": () => "bottom-end"
                                })}
                        sx={{
                            background: theme.palette.background.paper,
                            boxShadow: theme.shadows[Shadows.Tooltip],
                            width:
                                fullWidth
                                    ? childElementRef.current?.getBoundingClientRect().width
                                    : undefined,
                            zIndex: theme.zIndex.modal
                        }}>
                        {dropdownElement}
                    </Popper>
                </ClickAwayListener>
                : <Popover
                    action={popoverActionsRef}
                    anchorEl={childElementRef.current}
                    anchorOrigin={{
                        horizontal: justify,
                        vertical: "bottom",
                        ...popoverPropsLocation?.anchorOrigin
                    }}
                    open={showDropdownElement}
                    slotProps={{
                        paper: {
                            sx: {
                                borderRadius: theme.spacing(0.75),
                                width:
                                    fullWidth
                                        ? childElementRef.current?.getBoundingClientRect().width
                                        : undefined
                            }
                        }
                    }}
                    transformOrigin={{
                        horizontal: justify,
                        vertical: "top",
                        ...popoverPropsLocation?.transformOrigin
                    }}
                    onClick={event => event.stopPropagation()}
                    onClose={closeDropdown}>
                    <ResizeObserver onResize={() => popoverActionsRef.current?.updatePosition()}/>
                    {dropdownElement}
                </Popover>}
            {inline
                ? <Typography
                    component="span"
                    ref={childElementRef}
                    sx={Sx.combine(defaultSx, sx)}
                    onClick={
                        (event: MouseEvent) => {
                            if (!disabled) {
                                openDropdown();
                                event.preventDefault();
                                event.stopPropagation();
                            }
                        }}>
                    {_.isFunction(children)
                        ? children(showDropdownElement)
                        : children}
                </Typography>
                : <Box
                    ref={childElementRef}
                    sx={Sx.combine(defaultSx, sx)}
                    onClick={
                        event => {
                            if (!disabled) {
                                openDropdown();
                                event.preventDefault();
                                event.stopPropagation();
                            }
                        }}>
                    {_.isFunction(children)
                        ? children(showDropdownElement)
                        : children}
                </Box>}
        </Fragment>);
}