import { Optional, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { NetworkAccessScope } from "../../../..";
import { Contract, InfoItem } from "../../../../../../../../../common";
import { useNetworkInboundAccessTypeTranslator } from "../../../../../hooks";

export function useCommonEntityNetworkInfoItemElements(resourceModel: Contract.EntityModel) {
    const entityNetwork = resourceModel.entityNetwork as Optional<Contract.NetworkAccessResourceStateNetwork>;
    const networkInboundAccessTypeTranslator = useNetworkInboundAccessTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.useCommonEntityNetworkInfoItemElements",
            () => ({
                inboundAccessType: "Network Exposure",
                inboundExternalAccessScope: "Network Exposure Scope"
            }));
    return {
        inboundAccessType:
            <InfoItem
                key="inboundAccessType"
                title={localization.inboundAccessType()}
                value={
                    _.isNil(entityNetwork) ||
                        entityNetwork.inboundAccessType === Contract.NetworkInboundAccessType.Internal
                        ? undefined
                        : networkInboundAccessTypeTranslator(entityNetwork.inboundAccessType)}/>,
        inboundExternalAccessScope:
            <InfoItem
                key="inboundExternalAccessScope"
                title={localization.inboundExternalAccessScope()}
                value={
                    _.isNil(entityNetwork?.inboundExternalAccessScope) ||
                        entityNetwork!.inboundExternalAccessScope === Contract.NetworkAccessScope.None
                        ? undefined
                        : <NetworkAccessScope accessScope={entityNetwork!.inboundExternalAccessScope}/>}/>
    };
}