import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function PackagesIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                clipRule="evenodd"
                d="M3 12C3 16.9607 7.03926 21 12 21C16.9607 21 21 16.9607 21 12C21 7.03926 16.9607 3 12 3C7.03926 3 3 7.03926 3 12ZM12 23C5.93469 23 1 18.0653 1 12C1 5.93469 5.93469 1 12 1C18.0653 1 23 5.93469 23 12C23 18.0653 18.0653 23 12 23Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M11 12C11 12.5498 11.4502 13 12 13C12.5498 13 13 12.5498 13 12C13 11.4502 12.5498 11 12 11C11.4502 11 11 11.4502 11 12ZM9 12C9 13.6545 10.3458 15 12 15C13.6542 15 15 13.6545 15 12C15 10.3455 13.6542 9 12 9C10.3458 9 9 10.3455 9 12Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M11 5.76775C11 5.34398 11.3985 4.97334 11.9179 5.00151C15.7156 5.20745 18.7629 7.87386 18.9983 11.1968C19.0305 11.6513 18.6069 12 18.1226 12C17.6229 12 17.2501 11.6489 17.2176 11.2485C17.0147 8.74472 14.7203 6.73713 11.8589 6.55958C11.4012 6.53118 11 6.20492 11 5.76775Z"
                fillRule="evenodd"/>
        </SvgIcon>);
}