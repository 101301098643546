import { useLocalization } from "@infrastructure";
import React from "react";
import { useOciDefaultResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { useCustomEntityPropertyInfoItemElements } from "..";
import { Contract, InfoItem, useEntityTypeNameTranslator, VerticalTopItemsInfoItem } from "../../../../../../../../../../common";
import { useOciNetworkingVcnStatusTranslator } from "../../../../../../hooks";
import { EntitiesInfoItem, EntityTypeNameGroupsInfoCard, Info } from "../../../../components";
import { OciNetworkingSubnetsInfoCard } from "../../components";

export function useOciNetworkingVcnDefinition(resourceModel: Contract.OciResourceModel, options?: DefinitionOptions) {
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useOciDefaultResourceInfoItemElements(resourceModel);
    const vcnModel = resourceModel as Contract.OciNetworkingVcnModel;
    const vcn = vcnModel.entity as Contract.OciNetworkingVcn;

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const vcnStatusTranslator = useOciNetworkingVcnStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.oci.useOciNetworkingVcnDefinition",
            () => ({
                info: {
                    cards: {
                        resources: {
                            emptyMessage: "No resources",
                            title: "Resources"
                        }
                    },
                    items: {
                        defaultRouteTable: "Default Route Table",
                        defaultSecurityList: "Default Security List",
                        domainName: "DNS Domain Name",
                        flowLogsEnabled: {
                            false: "Disabled",
                            title: "Flow Logs",
                            true: "Enabled"
                        },
                        ipv4Subnets: "IPv4 CIDR Blocks",
                        ipv6Subnets: "IPv6 Prefixes",
                        networkedResources: "Attached Resources",
                        status: "State"
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="status"
                        title={localization.info.items.status()}
                        value={vcnStatusTranslator(vcn.status)}/>,
                    <VerticalTopItemsInfoItem
                        items={vcn.ipv4Subnets}
                        key="ipv4Subnets"
                        title={localization.info.items.ipv4Subnets()}/>,
                    <VerticalTopItemsInfoItem
                        items={vcn.ipv6Subnets}
                        key="ipv6Subnets"
                        title={localization.info.items.ipv6Subnets()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={vcnModel.defaultRouteTableIdReference}
                        entityTypeName={Contract.TypeNames.OciNetworkingRouteTable}
                        key="defaultRouteTable"
                        title={localization.info.items.defaultRouteTable()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={vcnModel.defaultSecurityListIdReference}
                        entityTypeName={Contract.TypeNames.OciNetworkingSecurityList}
                        key="defaultSecurityList"
                        title={localization.info.items.defaultSecurityList()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={vcnModel.internetGatewayId}
                        entityTypeName={Contract.TypeNames.OciNetworkingInternetGateway}
                        key="internetGateway"
                        title={entityTypeNameTranslator(Contract.TypeNames.OciNetworkingInternetGateway)}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={vcnModel.serviceGatewayIds}
                        entityTypeName={Contract.TypeNames.OciNetworkingServiceGateway}
                        key="serviceGateway"
                        title={entityTypeNameTranslator(Contract.TypeNames.OciNetworkingServiceGateway)}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={vcnModel.natGatewayIds}
                        entityTypeName={Contract.TypeNames.OciNetworkingNatGateway}
                        key="natGateway"
                        title={entityTypeNameTranslator(Contract.TypeNames.OciNetworkingNatGateway)}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={vcnModel.localPeeringGatewayIds}
                        entityTypeName={Contract.TypeNames.OciNetworkingLocalPeeringGateway}
                        key="localPeeringGateway"
                        title={entityTypeNameTranslator(Contract.TypeNames.OciNetworkingLocalPeeringGateway)}/>,
                    <InfoItem
                        key="domainName"
                        title={localization.info.items.domainName()}
                        value={vcn.domainName}/>,
                    <InfoItem
                        key="flowLogsEnabled"
                        title={localization.info.items.flowLogsEnabled.title()}
                        value={
                            vcnModel.flowLogsEnabled
                                ? localization.info.items.flowLogsEnabled.true()
                                : localization.info.items.flowLogsEnabled.false()}/>
                ]}
                options={options?.infoOptions}>
                <EntityTypeNameGroupsInfoCard
                    emptyMessage={localization.info.cards.resources.emptyMessage()}
                    entityTypeNameToIdsMap={{
                        ...vcnModel.networkedResourceTypeNameToIdsMap,
                        [Contract.TypeNames.OciNetworkingNetworkSecurityGroup]: vcnModel.networkSecurityGroupIds,
                        [Contract.TypeNames.OciNetworkingSecurityList]: vcnModel.securityListIds
                    }}
                    title={localization.info.cards.resources.title()}/>
                <OciNetworkingSubnetsInfoCard subnetIds={vcnModel.subnetIds}/>
            </Info>
    });
}