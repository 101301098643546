import { useExecuteOperation, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { Contract, EntityController, InfoCard, InfoItem, VerticalTopItemsInfoItem } from "../../../../../../../../../../common";
import { AwsElbLoadBalancerListenersTable } from "../../../../../../../../../../tenants";
import { EntitiesInfoItem, Info } from "../../../../components";
import { NetworkingInfoCard } from "../../components";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";
import { useAwsCommonNetworkingInfoItemElements } from "./useAwsCommonNetworkingInfoItemElements";
import { useAwsDefaultResourceInfoItemElements } from "./useAwsDefaultResourceInfoItemElements";

export function useAwsElbNetworkLoadBalancerDefinition(resourceModel: Contract.AwsResourceModel, options?: DefinitionOptions) {
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useAwsDefaultResourceInfoItemElements(resourceModel);
    const networkLoadBalancer = resourceModel.entity as Contract.AwsElbNetworkLoadBalancer;
    const networkLoadBalancerModel = resourceModel as Contract.AwsElbNetworkLoadBalancerModel;
    const [{ distributionIds }] =
        useExecuteOperation(
            [useAwsElbNetworkLoadBalancerDefinition, networkLoadBalancerModel.id],
            async () => await EntityController.getAwsDistributionIds(new Contract.EntityControllerGetAwsDistributionIdsRequest(networkLoadBalancerModel.id)));
    const commonNetworkCardInfoItems =
        useAwsCommonNetworkingInfoItemElements({
            networkInterfaceIds: networkLoadBalancer.networkInterfaceIds,
            privateIpAddresses: networkLoadBalancer.privateIpAddresses,
            publicIpAddresses: networkLoadBalancer.publicIpAddresses,
            resourceModel
        });

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsElbNetworkLoadBalancerDefinition",
            () => ({
                info: {
                    cards: {
                        listeners: "Listeners"
                    },
                    items: {
                        accessLogsEnabled: {
                            false: "Disabled",
                            title: "Access Logs",
                            true: "Enabled"
                        },
                        autoScalingGroups: "Auto Scaling Groups",
                        availabilityZones: "Availability Zones",
                        cloudFrontDistributions: "CloudFront Distributions",
                        dnsName: "DNS Name",
                        dnsZoneIds: "DNS Zones",
                        external: {
                            false: "Off",
                            title: "Internet Facing",
                            true: "On"
                        },
                        vpc: "VPC"
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <VerticalTopItemsInfoItem
                        items={
                            _.map(
                                networkLoadBalancer.availabilityZones,
                                availabilityZone => availabilityZone.name)}
                        key="availabilityZones"
                        title={localization.info.items.availabilityZones()}/>,
                    <InfoItem
                        key="internal"
                        title={localization.info.items.external.title()}
                        value={
                            networkLoadBalancer.external
                                ? localization.info.items.external.true()
                                : localization.info.items.external.false()}/>,
                    <InfoItem
                        key="dnsName"
                        title={localization.info.items.dnsName()}
                        value={networkLoadBalancer.dnsName}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={networkLoadBalancerModel.dnsZoneIds}
                        entityTypeName={Contract.TypeNames.Entity}
                        key="dnsZoneIds"
                        title={localization.info.items.dnsZoneIds()}/>,
                    <InfoItem
                        key="accessLogsEnabled"
                        title={localization.info.items.accessLogsEnabled.title()}
                        value={
                            networkLoadBalancer.accessLogsEnabled
                                ? localization.info.items.accessLogsEnabled.true()
                                : localization.info.items.accessLogsEnabled.false()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={networkLoadBalancerModel.autoScalingGroupIds}
                        entityTypeName={Contract.TypeNames.AwsAutoScalingAutoScalingGroup}
                        key="autoScalingGroups"
                        title={localization.info.items.autoScalingGroups()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={distributionIds}
                        entityTypeName={Contract.TypeNames.AwsCloudFrontDistribution}
                        key="cloudFrontDistributions"
                        title={localization.info.items.cloudFrontDistributions()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={networkLoadBalancer.vpcIds}
                        entityTypeName={Contract.TypeNames.AwsEc2Vpc}
                        key="vpc"
                        location="miniGlance"
                        title={localization.info.items.vpc()}/>
                ]}
                options={options?.infoOptions}>
                <NetworkingInfoCard>
                    {commonNetworkCardInfoItems.inboundAccessType}
                    {commonNetworkCardInfoItems.inboundExternalAccessScope}
                    {commonNetworkCardInfoItems.getPrivateIpAddresses()}
                    {commonNetworkCardInfoItems.getPublicIpAddresses()}
                    {commonNetworkCardInfoItems.getVpcs()}
                    {commonNetworkCardInfoItems.getSubnets()}
                    {commonNetworkCardInfoItems.getNetworkInterfaces()}
                    {commonNetworkCardInfoItems.getSecurityGroups()}
                </NetworkingInfoCard>
                <InfoCard title={localization.info.cards.listeners()}>
                    <AwsElbLoadBalancerListenersTable
                        loadBalancerListenerIds={networkLoadBalancerModel.listenerIds}
                        targetGroupIds={networkLoadBalancerModel.targetGroupIds}/>
                </InfoCard>
            </Info>
    });
}