import { IconNodeContentTextOptions, NodeContentContextMenuItem, Optional } from "@infrastructure";
import React from "react";
import { Contract } from "../../controllers";
import { Many, Single } from "./components";

export type EntityNodeContentProps = {
    contextMenuItems?: NodeContentContextMenuItem[];
    entityIds: string[];
    entityProfileMenuItem: boolean;
    entityTypeName?: string;
    getTooltip?: (entityModel: Contract.EntityModel) => Optional<string>;
    onFilter?: (type: "by" | "except") => void;
    textOptions?: Omit<IconNodeContentTextOptions, "subtitle" | "title">;
    titlePosition?: "bottom" | "right";
    variant: "large" | "small";
};

export function EntityNodeContent({ entityIds, entityProfileMenuItem, ...props }: EntityNodeContentProps) {
    return entityIds.length === 1
        ? <Single
            entityId={entityIds[0]}
            entityProfileMenuItem={entityProfileMenuItem}
            {...props}/>
        : <Many
            entityIds={entityIds}
            entityProfileMenuItem={entityProfileMenuItem}
            {...props}/>;
}

const entityNodeContentHeightBottomTitle = 124;
const entityNodeContentHeightRightTitle = 60;
const entityNodeContentWidthLarge = 300;
const entityNodeContentWidthSmall = 230;

export const getEntityNodeContentAnchorPoint =
    (placement: "left" | "right", titlePosition: "bottom" | "right", variant: "large" | "small") => {
        const { width } = getEntityNodeContentSize(titlePosition, variant);
        return ({
            x: width / 2 + 40 *
                (placement === "right"
                    ? 1
                    : -1),
            y: 25
        });
    };

export const getEntityNodeContentSize =
    (titlePosition: "bottom" | "right", variant: "large" | "small") => ({
        height:
            titlePosition === "bottom"
                ? entityNodeContentHeightBottomTitle
                : entityNodeContentHeightRightTitle,
        width:
            variant === "large"
                ? entityNodeContentWidthLarge
                : entityNodeContentWidthSmall
    });