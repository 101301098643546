import { InlineItems, Sx, useLocalization } from "@infrastructure";
import { SxProps, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract, NetworkScopeFormatter } from "../../..";

type InlineDestinationNetworkScopesProps = {
    destinationNetworkScopes?: Contract.DestinationNetworkScope[];
    sx?: SxProps;
};

export function InlineDestinationNetworkScopes({ destinationNetworkScopes, sx }: InlineDestinationNetworkScopesProps) {
    const localization =
        useLocalization(
            "common.customRiskPolicy.inlineDestinationNetworkScopes",
            () => ({
                placeholder: "these ports",
                ports: [
                    "1 port range",
                    "{{count | NumberFormatter.humanize}} port ranges"
                ]
            }));
    const textSx =
        Sx.combine(
            {
                fontWeight: 600,
                textDecoration: "underline"
            },
            sx);
    return (
        <Typography
            component="span"
            sx={textSx}>
            {_.isEmpty(destinationNetworkScopes)
                ? localization.placeholder()
                : <InlineItems
                    items={
                        _.map(
                            destinationNetworkScopes,
                            destinationNetworkScope => NetworkScopeFormatter.networkScopeFromDestinationNetworkScope(destinationNetworkScope))}
                    namePluralizer={localization.ports}
                    sx={textSx}
                    variant="itemOrItemCountAndType"/>}
        </Typography>);
}