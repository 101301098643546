import { Link, setUrlRoute, Shadows } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { ReactNode } from "react";
import { Contract, RiskCountsChart, useTheme } from "../../../../../../../../..";

type ItemToRiskSeverityToRiskCountListProps = {
    children: (item: string) => ReactNode;
    getRisksRelativeUrl: (item: string, severity?: Contract.Severity) => string;
    itemToRiskSeverityToRiskCount: Dictionary<Dictionary<number>>;
};

export function ItemToRiskSeverityToRiskCountList({ children, getRisksRelativeUrl, itemToRiskSeverityToRiskCount }: ItemToRiskSeverityToRiskCountListProps) {
    const theme = useTheme();
    return (
        <Stack>
            {_.map(
                itemToRiskSeverityToRiskCount,
                ( riskSeverityRiskCountMap, item ) =>
                    <Stack
                        alignItems="center"
                        className="itemToRiskSeverityToRiskCountList"
                        direction="row"
                        gap={1}
                        key={item}
                        sx={{
                            "&:hover": {
                                background: theme.palette.background.alternate,
                                border: theme.border.alternate,
                                borderRadius: theme.spacing(0.75),
                                boxShadow: theme.shadows[Shadows.Highlight],
                                zIndex: 1
                            },
                            border: "solid 1px transparent",
                            height: theme.spacing(6.25),
                            padding: theme.spacing(1.5, 1)
                        }}>
                        <Link
                            sx={{ flex: 1, overflow: "hidden" }}
                            urlOrGetUrl={getRisksRelativeUrl(item)}
                            variant="text">
                            <Typography noWrap={true}>
                                {children(item)}
                            </Typography>
                        </Link>
                        <RiskCountsChart
                            itemMap={riskSeverityRiskCountMap}
                            onClick={severity => setUrlRoute(getRisksRelativeUrl(item, severity))}/>
                    </Stack>)}
        </Stack>);
}