import { KeyCodes, Link, MailIcon, StringHelper, Tooltip, useChangeEffect, useLocalization, useQueryParameters } from "@infrastructure";
import { Box, Button, CircularProgress, FormHelperText, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import _ from "lodash";
import React, { useState } from "react";
import { SignInQueryParameters } from "..";
import { AuthenticationController, Contract } from "../../../controllers";
import { useTheme } from "../../../themes";
import { ConsoleAppUrlHelper, UrlHelper } from "../../../utilities";

type ChangePasswordProps = {
    consoleAppType: Contract.ConsoleAppType;
};

export function ChangePassword({ consoleAppType }: ChangePasswordProps) {
    const { changePasswordRecovery, changePasswordUserMail: userMail } = useQueryParameters<SignInQueryParameters>();
    const recovery = StringHelper.isTrue(changePasswordRecovery);

    const [userExistingPasswordOrVerificationCode, setUserExistingPasswordOrVerificationCode] = useState<string>("");
    const [userNewPassword, setUserNewPassword] = useState<string>("");
    const [userNewPasswordConfirm, setUserNewPasswordConfirm] = useState<string>("");
    const [changePasswordExecuting, setChangePasswordExecuting] = useState(false);
    const [changePasswordResult, setChangePasswordResult] = useState<Contract.CognitoAuthenticationManagerChangeUserPasswordResult>();
    const [changePasswordError, setChangePasswordError] = useState<ChangePasswordError>();
    const changePassword =
        async () => {
            if (_.isEmpty(userExistingPasswordOrVerificationCode) ||
                _.isEmpty(userNewPassword) ||
                _.isEmpty(userNewPasswordConfirm)) {
                return;
            }

            if (userNewPassword !== userNewPasswordConfirm) {
                setChangePasswordError(ChangePasswordError.ValidationFailed);
                return;
            }

            setChangePasswordExecuting(true);
            setChangePasswordResult(undefined);
            setChangePasswordError(undefined);

            try {
                const { result } =
                    recovery
                        ? await AuthenticationController.recoverCognitoUserPassword(
                            new Contract.AuthenticationControllerRecoverCognitoUserPasswordRequest(
                                userMail,
                                userNewPassword,
                                userExistingPasswordOrVerificationCode))
                        : await AuthenticationController.changeCognitoUserPassword(
                            new Contract.AuthenticationControllerChangeCognitoUserPasswordRequest(
                                userExistingPasswordOrVerificationCode,
                                userMail,
                                userNewPassword));
                setChangePasswordResult(result);
            } catch {
                setChangePasswordError(ChangePasswordError.ExecutionFailed);
            }

            setChangePasswordExecuting(false);
        };

    useChangeEffect(
        () => {
            setChangePasswordResult(undefined);
            setChangePasswordError(undefined);
        },
        [userExistingPasswordOrVerificationCode, userNewPassword, userNewPasswordConfirm]);

    const localization =
        useLocalization(
            "common.signIn.changePassword",
            () => ({
                actions: {
                    changePassword: {
                        error: {
                            [ChangePasswordError.ExecutionFailed]: "Failed to change password",
                            [ChangePasswordError.ValidationFailed]: "Passwords do not match",
                            [Contract.TypeNames.CognitoAuthenticationManagerChangeUserPasswordResult]: {
                                [Contract.CognitoAuthenticationManagerChangeUserPasswordResult.FailureMailOrPasswordInvalid]: "Incorrect user credentials",
                                [Contract.CognitoAuthenticationManagerChangeUserPasswordResult.FailureNewPasswordInvalid]: "Invalid password. Must be at least 8 characters and include uppercase, lowercase & numbers",
                                [Contract.CognitoAuthenticationManagerChangeUserPasswordResult.FailureVerificationCodeIncorrect]: "Incorrect verification code"
                            }
                        },
                        title: "Change password"
                    }
                },
                fields: {
                    existingPassword: "Old Password",
                    newPassword: "New Password",
                    newPasswordConfirm: "Confirm Password",
                    verificationCode: "PIN Code"
                },
                recovery: {
                    subtitle: "We sent you an email with a PIN code. The code expires after one hour. Enter the code and your new password below.",
                    title: "Forgot my password"
                },
                title: "Change Password",
                tooltip: {
                    password: "• at least 8 characters\n• uppercase letter\n• lowercase letter\n• number"
                }
            }));

    const theme = useTheme();
    return changePasswordResult === Contract.CognitoAuthenticationManagerChangeUserPasswordResult.Success
        ? <Success consoleAppType={consoleAppType}/>
        : <Stack
            alignItems="center"
            spacing={5}
            sx={{ width: theme.spacing(60) }}>
            <Stack spacing={1}>
                <Typography
                    align="center"
                    variant="h3">
                    {recovery
                        ? localization.recovery.title()
                        : localization.title()}
                </Typography>
                {recovery && (
                    <Typography
                        align="center"
                        variant="h4">
                        {localization.recovery.subtitle()}
                    </Typography>)}
            </Stack>
            <Stack
                spacing={1}
                sx={{ width: theme.spacing(40) }}>
                <TextField
                    fullWidth={true}
                    slotProps={{
                        input: {
                            disableUnderline: true,
                            readOnly: true,
                            startAdornment:
                                <InputAdornment
                                    position="start"
                                    sx={{
                                        color: theme.important(theme.palette.text.primary),
                                        fontSize: "18px"
                                    }}>
                                    <MailIcon/>
                                </InputAdornment>
                        }
                    }}
                    sx={{
                        ".MuiInput-input:read-only": {
                            color: theme.palette.text.primary
                        }
                    }}
                    type="email"
                    value={userMail}
                    variant="standard"/>
                <TextField
                    autoFocus={true}
                    disabled={changePasswordExecuting}
                    fullWidth={true}
                    placeholder={
                        recovery
                            ? localization.fields.verificationCode()
                            : localization.fields.existingPassword()}
                    type="password"
                    value={userExistingPasswordOrVerificationCode}
                    variant="outlined"
                    onChange={event => setUserExistingPasswordOrVerificationCode(event.target.value)}/>
                <Tooltip
                    placement="bottom"
                    titleOrGetTitle={
                        <Typography
                            sx={{
                                padding: theme.spacing(1),
                                whiteSpace: "pre-wrap",
                                width: theme.spacing(40)
                            }}>
                            {localization.tooltip.password()}
                        </Typography>}
                    variant="arrow">
                    <TextField
                        disabled={changePasswordExecuting}
                        fullWidth={true}
                        placeholder={localization.fields.newPassword()}
                        type="password"
                        value={userNewPassword}
                        variant="outlined"
                        onChange={event => setUserNewPassword(event.target.value)}/>
                </Tooltip>
                <TextField
                    disabled={changePasswordExecuting}
                    fullWidth={true}
                    placeholder={localization.fields.newPasswordConfirm()}
                    type="password"
                    value={userNewPasswordConfirm}
                    variant="outlined"
                    onChange={event => setUserNewPasswordConfirm(event.target.value)}
                    onKeyPress={
                        event => {
                            if (event.charCode === KeyCodes.Enter) {
                                changePassword();
                            }
                        }}/>
                {!_.isNil(changePasswordResult) &&
                    <FormHelperText error={true}>
                        {localization.actions.changePassword.error[Contract.TypeNames.CognitoAuthenticationManagerChangeUserPasswordResult][changePasswordResult]()}
                    </FormHelperText>}
                {!_.isNil(changePasswordError) &&
                    <FormHelperText error={true}>
                        {localization.actions.changePassword.error[changePasswordError]()}
                    </FormHelperText>}
                <Button
                    color="primary"
                    disabled={
                        _.isEmpty(userExistingPasswordOrVerificationCode) ||
                        _.isEmpty(userNewPassword) ||
                        _.isEmpty(userNewPasswordConfirm) ||
                        changePasswordExecuting}
                    size="large"
                    sx={{ fontWeight: 400 }}
                    type="submit"
                    onClick={() => changePassword()}>
                    {localization.actions.changePassword.title()}
                </Button>
            </Stack>
            {changePasswordExecuting &&
                <CircularProgress variant="indeterminate"/>}
        </Stack>;
}

enum ChangePasswordError {
    ExecutionFailed = "general",
    ValidationFailed = "newPasswordNoMatch"
}

type SuccessProps = {
    consoleAppType: Contract.ConsoleAppType;
};

function Success({ consoleAppType }: SuccessProps) {
    const localization =
        useLocalization(
            "common.signIn.changePassword.finish",
            () => ({
                actions: {
                    back: "Go back to sign in page"
                },
                subtitle: "You can now login to your account...",
                title: "You've successfully changed your password!"
            }));
    const theme = useTheme();
    return (
        <Stack
            spacing={2}
            sx={{ width: theme.spacing(50) }}>
            <Typography
                align="center"
                variant="h3">
                {localization.title()}
            </Typography>
            <Typography
                align="center"
                variant="h2">
                {localization.subtitle()}
            </Typography>
            <Box sx={{ marginTop: theme.spacing(16) }}>
                <Link
                    urlOrGetUrl={ConsoleAppUrlHelper.getSignInRelativeUrl(consoleAppType, UrlHelper.getRedirectUrlQueryParameters().redirectUrl)}
                    variant="text">
                    <Typography
                        align="center"
                        sx={{ textDecorationLine: "underline" }}
                        variant="h2">
                        {localization.actions.back()}
                    </Typography>
                </Link>
            </Box>
        </Stack>);
}