import { DataTableColumn, DataTableColumnRenderProps, EmptyMessageText, EnumValuesFilter, map, useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, EntitiesCell, EntityFilter, entityModelStore, ItemTable } from "../../../../../../../../../../../../../../common";

export type IamRoleBindingDeltaTableProps = {
    csvExportFilePrefixes: string[];
    riskModel: Contract.GcpPermissionManagementRiskModel;
};

export function RoleBindingDeltaTable({ csvExportFilePrefixes, riskModel }: IamRoleBindingDeltaTableProps) {
    const iamRoleBindingDeltaRelatedEntityModels =
        entityModelStore.useGet(
            _(riskModel.roleBindingDeltaModels).
                flatMap(
                    iamRoleBindingDeltaModel => [
                        iamRoleBindingDeltaModel.principalIdReference,
                        iamRoleBindingDeltaModel.roleIdReference,
                        iamRoleBindingDeltaModel.scopeResourceIdReference
                    ]).
                filter().
                value());

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.behavior.useGcpPermissionManagementRiskDefinition.roleBindingDeltaTable",
            () => ({
                columns: {
                    action: {
                        title: "Action",
                        [Contract.TypeNames.GcpIamRoleBindingDeltaAction]: {
                            [Contract.GcpIamRoleBindingDeltaAction.Add]: "Add",
                            [Contract.GcpIamRoleBindingDeltaAction.Remove]: "Remove"
                        }
                    },
                    principalReference: "Principal",
                    roleReference: "Role",
                    scopeResourceReference: "Scope"
                },
                empty: "No Permission Changes"
            }));

    const [iamRoleBindingDeltaRelatedEntityModelMap, items] =
        useMemo(
            () => {
                const iamRoleBindingDeltaRelatedEntityModelMap =
                    _.keyBy(
                        iamRoleBindingDeltaRelatedEntityModels,
                        roleBindingRelatedEntityModel => roleBindingRelatedEntityModel.id);

                const items =
                    _(riskModel.roleBindingDeltaModels).
                        map(
                            (iamRoleBindingDeltaModel, iamRoleBindingDeltaModelIndex) =>
                                ({
                                    action: iamRoleBindingDeltaModel.action,
                                    index: iamRoleBindingDeltaModelIndex,
                                    principalReference: iamRoleBindingDeltaModel.principalIdReference,
                                    roleReference: iamRoleBindingDeltaModel.roleIdReference,
                                    scopeResourceReference: iamRoleBindingDeltaModel.scopeResourceIdReference
                                })).
                        as<RoleBindingDeltaTableItem>().
                        value();
                return [iamRoleBindingDeltaRelatedEntityModelMap, items];
            },
            [iamRoleBindingDeltaRelatedEntityModels]);

    return (
        <ItemTable
            columnIdToGetItemValueMap={{
                [IamRoleBindingDeltaTableColumnId.Action]: {
                    getFilterValue: item => item.action,
                    getSortValue: item =>
                        map(
                            item.action,
                            {
                                [Contract.GcpIamRoleBindingDeltaAction.Add]: () => 0,
                                [Contract.GcpIamRoleBindingDeltaAction.Remove]: () => 1
                            })
                },
                [IamRoleBindingDeltaTableColumnId.PrincipalReference]: {
                    getFilterValue: item => item.principalReference,
                    getSortValue: item => iamRoleBindingDeltaRelatedEntityModelMap[item.principalReference].entity.displayName
                },
                [IamRoleBindingDeltaTableColumnId.RoleReference]: {
                    getFilterValue: item => item.roleReference,
                    getSortValue: item => iamRoleBindingDeltaRelatedEntityModelMap[item.roleReference].entity.displayName
                },
                [IamRoleBindingDeltaTableColumnId.ScopeResourceReference]: {
                    getFilterValue: item => item.scopeResourceReference,
                    getSortValue: item => iamRoleBindingDeltaRelatedEntityModelMap[item.scopeResourceReference].entity.displayName
                }
            }}
            csvExportFilePrefixes={csvExportFilePrefixes}
            defaultSortColumnIdOrIds={IamRoleBindingDeltaTableColumnId.PrincipalReference}
            emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty()) }}
            getCsvItem={
                item => ({
                    "Action": localization.columns.action[Contract.TypeNames.GcpIamRoleBindingDeltaAction][item.action](),
                    "Principal": iamRoleBindingDeltaRelatedEntityModelMap[item.principalReference].entity.displayReference,
                    "Role": iamRoleBindingDeltaRelatedEntityModelMap[item.roleReference].entity.displayReference,
                    "Scope": iamRoleBindingDeltaRelatedEntityModelMap[item.scopeResourceReference].entity.displayReference
                })
            }
            getItemId={item => `${item.index}`}
            items={items}>
            {columnIdToItemValuesMap =>
                [
                    <DataTableColumn
                        filterOptions={{
                            itemOrItems: {
                                element:
                                    <EnumValuesFilter
                                        enumType={Contract.GcpIamRoleBindingDeltaAction}
                                        enumTypeTranslator={(action: Contract.GcpIamRoleBindingDeltaAction) => localization.columns.action[Contract.TypeNames.GcpIamRoleBindingDeltaAction][action]()}
                                        placeholder={localization.columns.action.title()}
                                        sorted={false}/>
                            }
                        }}
                        id={IamRoleBindingDeltaTableColumnId.Action}
                        key={IamRoleBindingDeltaTableColumnId.Action}
                        render={
                            ({ item }: DataTableColumnRenderProps<RoleBindingDeltaTableItem>) =>
                                <Typography>
                                    {localization.columns.action[Contract.TypeNames.GcpIamRoleBindingDeltaAction][item.action]()}
                                </Typography>}
                        title={localization.columns.action.title()}/>,
                    <DataTableColumn
                        filterOptions={{
                            itemOrItems: {
                                element:
                                    <EntityFilter
                                        entityIdsOrSearchableReferences={columnIdToItemValuesMap[IamRoleBindingDeltaTableColumnId.PrincipalReference]}
                                        placeholder={localization.columns.principalReference()}/>
                            }
                        }}
                        id={IamRoleBindingDeltaTableColumnId.PrincipalReference}
                        key={IamRoleBindingDeltaTableColumnId.PrincipalReference}
                        render={
                            ({ item }: DataTableColumnRenderProps<RoleBindingDeltaTableItem>) =>
                                <EntitiesCell
                                    entityIdsOrModels={item.principalReference}
                                    entityTypeName={Contract.TypeNames.IGciPrincipal}
                                    entityVariant="iconTextTypeTenant"/>}
                        title={localization.columns.principalReference()}/>,
                    <DataTableColumn
                        filterOptions={{
                            itemOrItems: {
                                element:
                                    <EntityFilter
                                        entityIdsOrSearchableReferences={columnIdToItemValuesMap[IamRoleBindingDeltaTableColumnId.RoleReference]}
                                        placeholder={localization.columns.roleReference()}/>
                            }
                        }}
                        id={IamRoleBindingDeltaTableColumnId.RoleReference}
                        key={IamRoleBindingDeltaTableColumnId.RoleReference}
                        render={
                            ({ item }: DataTableColumnRenderProps<RoleBindingDeltaTableItem>) =>
                                <EntitiesCell
                                    entityIdsOrModels={item.roleReference}
                                    entityTypeName={Contract.TypeNames.GcpIamRole}
                                    entityVariant="iconTextTypeTenant"/>}
                        title={localization.columns.roleReference()}/>,
                    <DataTableColumn
                        filterOptions={{
                            itemOrItems: {
                                element:
                                    <EntityFilter
                                        entityIdsOrSearchableReferences={columnIdToItemValuesMap[IamRoleBindingDeltaTableColumnId.ScopeResourceReference]}
                                        placeholder={localization.columns.scopeResourceReference()}/>
                            }
                        }}
                        id={IamRoleBindingDeltaTableColumnId.ScopeResourceReference}
                        key={IamRoleBindingDeltaTableColumnId.ScopeResourceReference}
                        render={
                            ({ item }: DataTableColumnRenderProps<RoleBindingDeltaTableItem>) =>
                                <EntitiesCell
                                    entityIdsOrModels={item.scopeResourceReference}
                                    entityTypeName={Contract.TypeNames.GcpScopeResource}
                                    entityVariant="iconTextTypeTenant"/>}
                        title={localization.columns.scopeResourceReference()}/>
                ]}
        </ItemTable>);
}

enum IamRoleBindingDeltaTableColumnId {
    Action = "action",
    PrincipalReference = "principalReference",
    RoleReference = "roleReference",
    ScopeResourceReference = "scopeResourceReference"
}

type RoleBindingDeltaTableItem = {
    action: Contract.GcpIamRoleBindingDeltaAction;
    index: number;
    principalReference: string;
    roleReference: string;
    scopeResourceReference: string;
};