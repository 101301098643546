import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function EntityDataIcon(props: SvgIconProps) {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <path 
                clipRule="evenodd"
                d="M20.2766 3.97705C20.2771 3.97709 20.274 3.98196 20.2658 3.99155C20.272 3.98182 20.2762 3.97702 20.2766 3.97705ZM19.7823 4.34862C19.4849 4.177 19.069 3.98804 18.52 3.80421C16.9329 3.27274 14.6275 2.91349 12.0002 2.91349C9.37291 2.91349 7.06752 3.27274 5.48038 3.80421C4.93138 3.98804 4.51545 4.177 4.21811 4.34862C4.51545 4.52023 4.93138 4.70919 5.48038 4.89303C7.06752 5.4245 9.37291 5.78374 12.0002 5.78374C14.6275 5.78374 16.9329 5.4245 18.52 4.89303C19.069 4.70919 19.4849 4.52023 19.7823 4.34862ZM3.72374 3.97705C3.72423 3.97702 3.72834 3.98182 3.7346 3.99155C3.72637 3.98195 3.72324 3.97709 3.72374 3.97705ZM3.72374 4.72018C3.72324 4.72015 3.72637 4.71528 3.7346 4.70568C3.72834 4.71541 3.72423 4.72021 3.72374 4.72018ZM20.2658 4.70569C20.274 4.71528 20.2771 4.72014 20.2766 4.72018C20.2762 4.72022 20.2721 4.71542 20.2658 4.70569ZM12.0002 7.69723C17.5231 7.69723 22.0002 6.198 22.0002 4.34862C22.0002 2.49923 17.5231 1 12.0002 1C6.47732 1 2.00016 2.49923 2.00016 4.34862C2.00016 6.198 6.47732 7.69723 12.0002 7.69723Z"
                fillRule="evenodd"/>
            <path 
                clipRule="evenodd"
                d="M17.2807 9.08675C18.804 8.75324 19.93 8.29825 20.5741 7.82901L21.609 9.40233C20.672 10.085 19.2695 10.6058 17.651 10.9601C16.0116 11.3191 14.0715 11.5241 12.0006 11.5241C8.18653 11.5241 4.72529 10.8272 2.81236 9.67949L3.71282 8.01715C5.21841 8.92045 8.30223 9.61057 12.0006 9.61057C13.9636 9.61057 15.7784 9.41567 17.2807 9.08675Z"
                fillRule="evenodd"/>
            <path 
                clipRule="evenodd"
                d="M17.2807 12.952C18.804 12.6185 19.93 12.1635 20.5741 11.6943L21.609 13.2676C20.672 13.9502 19.2695 14.4711 17.651 14.8254C16.0116 15.1844 14.0715 15.3894 12.0006 15.3894C8.18653 15.3894 4.72529 14.6925 2.81236 13.5448L3.71282 11.8824C5.21841 12.7857 8.30223 13.4759 12.0006 13.4759C13.9636 13.4759 15.7784 13.281 17.2807 12.952Z"
                fillRule="evenodd"/>
            <path 
                clipRule="evenodd"
                d="M17.2807 16.8173C18.804 16.4838 19.93 16.0288 20.5741 15.5596L21.609 17.1329C20.672 17.8155 19.2695 18.3364 17.651 18.6907C16.0116 19.0497 14.0715 19.2547 12.0006 19.2547C8.18653 19.2547 4.72529 18.5578 2.81236 17.4101L3.71282 15.7477C5.21841 16.651 8.30223 17.3412 12.0006 17.3412C13.9636 17.3412 15.7784 17.1463 17.2807 16.8173Z"
                fillRule="evenodd"/>
            <path 
                clipRule="evenodd"
                d="M2.11948 19.6611C2.36814 19.2021 2.92331 19.0421 3.35948 19.3038C4.8947 20.2248 8.35357 21.2065 12.0006 21.2065C13.9636 21.2065 15.7784 21.0116 17.2807 20.6826C18.804 20.3491 19.93 19.8941 20.5741 19.4249C20.987 19.1242 21.5533 19.2326 21.839 19.667C22.1248 20.1015 22.0218 20.6975 21.609 20.9982C20.672 21.6808 19.2695 22.2017 17.651 22.556C16.0116 22.915 14.0715 23.12 12.0006 23.12C8.13519 23.12 4.34233 22.096 2.45902 20.9661C2.02284 20.7044 1.87082 20.1202 2.11948 19.6611Z"
                fillRule="evenodd"/>
            <path 
                clipRule="evenodd"
                d="M2.00016 20.1349L2.00016 4.34862C2.00016 3.82022 2.40717 3.39187 2.90925 3.39187C3.41133 3.39187 3.81834 3.82022 3.81834 4.34862L3.81834 20.1349C3.81834 20.6633 3.41133 21.0917 2.90925 21.0917C2.40717 21.0917 2.00016 20.6633 2.00016 20.1349Z"
                fillRule="evenodd"/>
            <path 
                clipRule="evenodd"
                d="M20.182 20.1349L20.182 4.34862C20.182 3.82022 20.5891 3.39187 21.0911 3.39187C21.5932 3.39187 22.0002 3.82022 22.0002 4.34862V20.1349C22.0002 20.6633 21.5932 21.0917 21.0911 21.0917C20.5891 21.0917 20.182 20.6633 20.182 20.1349Z"
                fillRule="evenodd"/>
        </SvgIcon>);
}