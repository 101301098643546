﻿import { useLocalization } from "@infrastructure";
import React from "react";
import { useAzureCommonEncryptedResourceInfoElements, useAzureDefaultResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../..";
import { useCommonEntityNetworkInfoItemElements, useCustomEntityPropertyInfoItemElements } from "..";
import { Contract, InfoItem } from "../../../../../../../../../../common";
import { Info } from "../../../../components";
import { AzureResourceRoleAssignmentResources, NetworkingInfoCard } from "../../components";

export function useAzureBotServiceBotServiceDefinition(resourceGroupResourceModel: Contract.AzureResourceGroupResourceModel, options?: DefinitionOptions) {
    const botService = resourceGroupResourceModel.entity as Contract.AzureBotServiceBotService;
    const commonEncryptedResourceInfoElements = useAzureCommonEncryptedResourceInfoElements(resourceGroupResourceModel);
    const commonEntityNetworkInfoItemElements = useCommonEntityNetworkInfoItemElements(resourceGroupResourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceGroupResourceModel);
    const defaultResourceInfoItemElements = useAzureDefaultResourceInfoItemElements(resourceGroupResourceModel);

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.azure.useAzureBotServiceBotServiceDefinition",
            () => ({
                info: {
                    items: {
                        sharedKeyAccess: {
                            false: "Disabled",
                            title: "Local Authentication",
                            true: "Enabled"
                        }
                    }
                },
                tabs: {
                    resourceRoleAssignments: "Resource Role Assignments"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <AzureResourceRoleAssignmentResources
                    csvExportFilePrefix={localization.tabs.resourceRoleAssignments()}
                    scopeResourceModel={resourceGroupResourceModel}/>,
                localization.tabs.resourceRoleAssignments(),
                "resourceRoleAssignments")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    ...commonEncryptedResourceInfoElements,
                    <InfoItem
                        key="sharedKeyAccess"
                        title={localization.info.items.sharedKeyAccess.title()}
                        value={
                            botService.sharedKeyAccess
                                ? localization.info.items.sharedKeyAccess.true()
                                : localization.info.items.sharedKeyAccess.false()}/>
                ]}
                options={options?.infoOptions}>
                <NetworkingInfoCard>
                    {commonEntityNetworkInfoItemElements.inboundAccessType}
                    {commonEntityNetworkInfoItemElements.inboundExternalAccessScope}
                </NetworkingInfoCard>
            </Info>
    });
}