import { NumberFormatter, Tooltip, useWindowEventEffect } from "@infrastructure";
import { Box, Stack, Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { useState } from "react";
import { Contract, TenantModelStatusSeverityIcon, useTheme } from "../../..";

type TenantModelStatusSeverityItemProps = {
    getTenantStatusTranslation: (status: string, tenantModelCount: number) => string;
    severity?: Contract.Severity;
    tenantModelsStatusToCount: Dictionary<number>;
};

export function TenantModelStatusSeverityItem({ getTenantStatusTranslation, severity, tenantModelsStatusToCount }: TenantModelStatusSeverityItemProps) {
    const theme = useTheme();
    const [wide, setWide] =
        useState(
            window.innerWidth >= theme.breakpoints.get("xl") ||
            window.innerWidth < theme.breakpoints.get("lg"));
    useWindowEventEffect(
        "resize",
        () => {
            setWide(
                window.innerWidth >= theme.breakpoints.get("xl") ||
                window.innerWidth < theme.breakpoints.get("lg"));
        });

    return (
        <Box
            sx={{
                maxWidth: "100%",
                padding: theme.spacing(0, 0.5)
            }}>
            <Tooltip
                titleOrGetTitle={
                    <Stack spacing={1}>
                        {_.map(
                            tenantModelsStatusToCount,
                            (tenantModelCount, tenantModelStatus) =>
                                <Typography key={tenantModelStatus}>
                                    {getTenantStatusTranslation(tenantModelStatus, tenantModelCount)}
                                </Typography>)}
                    </Stack>}>
                <Stack
                    alignItems="center"
                    flexDirection="row"
                    sx={{ maxWidth: "100%" }}>
                    <TenantModelStatusSeverityIcon
                        statusSeverity={severity}
                        sx={{
                            fontSize: "16px",
                            marginRight: theme.spacing(0.5)
                        }}/>
                    {(wide || _.size(tenantModelsStatusToCount) < 3) &&
                        <Typography
                            noWrap={true}
                            sx={{ fontSize: "13px" }}>
                            {NumberFormatter.humanize(
                                _(tenantModelsStatusToCount).
                                    values().
                                    sum())}
                        </Typography>}
                </Stack>
            </Tooltip>
        </Box>);
}