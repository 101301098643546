﻿import { DataTableColumn, DataTableColumnRenderProps, PagedValuesFilter, useLocalization } from "@infrastructure";
import { Dictionary } from "lodash";
import React from "react";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";
import { useGcpCommonComputeLoadBalancerResourceDefinition } from "./useGcpCommonComputeLoadBalancerResourceDefinition";

export function useGcpComputeForwardingRuleDefinition(definitionData: DefinitionData) {
    const commonComputeLoadBalancerResourceDefinition = useGcpCommonComputeLoadBalancerResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.gcp.useGcpComputeForwardingRuleDefinition",
            () => ({
                columns: {
                    ipAddress: "Address",
                    targetIdReference: "Target",
                    vpcIdReference: "VPC Network"
                }
            }));

    return new EntityTableDefinition(
        [
            commonComputeLoadBalancerResourceDefinition.columns.tenantColumn,
            commonComputeLoadBalancerResourceDefinition.columns.creationTimeColumn,
            commonComputeLoadBalancerResourceDefinition.columns.creatorIdentityCsvColumn,
            commonComputeLoadBalancerResourceDefinition.columns.creatorOriginatorEntityCsvColumn,
            commonComputeLoadBalancerResourceDefinition.columns.accessLevelColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.GcpComputeForwardingRuleModel) => ({
                            [localization.columns.ipAddress()]: (item.entity as Contract.GcpComputeForwardingRule).ipAddress
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedValuesFilter
                                getValuePage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.GcpComputeForwardingRuleIpAddress)}
                                placeholder={localization.columns.ipAddress()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpComputeForwardingRuleIpAddress}
                itemProperty={(item: Contract.EntityModel) => (item.entity as Contract.GcpComputeForwardingRule).ipAddress}
                key={Contract.EntityModelProperty.GcpComputeForwardingRuleIpAddress}
                title={localization.columns.ipAddress()}/>,
            commonComputeLoadBalancerResourceDefinition.columns.loadBalancerIdsColumn,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.GcpComputeForwardingRuleModel>(
                        Contract.TypeNames.GcpScopeResource,
                        item => [item.targetIdReference],
                        localization.columns.targetIdReference())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.GcpComputeForwardingRuleTarget)}
                                placeholder={localization.columns.targetIdReference()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpComputeForwardingRuleTarget}
                key={Contract.EntityModelProperty.GcpComputeForwardingRuleTarget}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.GcpComputeForwardingRuleModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.targetIdReference}
                            entityTypeName={Contract.TypeNames.GcpScopeResource}
                            entityVariant="iconText"/>}
                title={localization.columns.targetIdReference()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.GcpComputeForwardingRuleModel>(
                        Contract.TypeNames.GcpScopeResource,
                        item => [item.vpcIdReference],
                        localization.columns.vpcIdReference())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.GcpComputeForwardingRuleVpcs)}
                                placeholder={localization.columns.vpcIdReference()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpComputeForwardingRuleVpcs}
                key={Contract.EntityModelProperty.GcpComputeForwardingRuleVpcs}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.GcpComputeForwardingRuleModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.vpcIdReference}
                            entityTypeName={Contract.TypeNames.GcpComputeVpc}
                            entityVariant="iconText"/>}
                title={localization.columns.vpcIdReference()}/>,
            commonComputeLoadBalancerResourceDefinition.columns.regionColumn,
            commonComputeLoadBalancerResourceDefinition.columns.regionLocationColumn,
            commonComputeLoadBalancerResourceDefinition.columns.tagsColumn,
            commonComputeLoadBalancerResourceDefinition.columns.attributesColumn,
            commonComputeLoadBalancerResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonComputeLoadBalancerResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonComputeLoadBalancerResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageGcpComputeForwardingRuleRequest(
                new Contract.EntityControllerGetEntityModelPageGcpComputeForwardingRuleRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.GcpResourceUpdateTime]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpScopeResourceAccessLevel]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpScopeResourceRawId]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpComputeLoadBalancerResourceLoadBalancers]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpComputeForwardingRuleIpAddress]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpComputeForwardingRuleTarget]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpComputeForwardingRuleVpcs])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags]))));
}