import { TranslateOptions, useLocalization } from "@infrastructure";
import { Contract } from "../../../../../../../../../../../../../../../common";
import { ConditionType } from "../utilities";

export function useConditionTypeTranslator() {
    const localization =
        useLocalization(
            "views.customer.scopes.hooks.useDefinition.hooks.useProjectsDefinition.addOrEdit.projectConfigurationRules.conditions.hooks.useConditionTypeTranslator",
            () => ({
                [Contract.TypeNames.ProjectConfigurationRuleAzureResourceGroupNamePatternEntityCondition]: {
                    description: "Resource's Azure resource group name pattern {{operatorType}} {{namePattern}}",
                    title: "Azure resource group name pattern"
                },
                [Contract.TypeNames.ProjectConfigurationRuleTagPatternEntityCondition]: {
                    description: "Resource's tag key pattern {{operatorType}} {{keyPattern}} and value pattern {{operatorValue}} {{valuePattern}}",
                    title: "Resource tag pattern"
                },
                [Contract.TypeNames.ProjectConfigurationRuleNamePatternTenantCondition]: {
                    description: "Account's name pattern {{operatorType}} {{namePattern}}",
                    title: "Account name pattern"
                },
                [Contract.TypeNames.ProjectConfigurationRuleTagPatternTenantCondition]: {
                    description: "Account's tag key pattern {{operatorType}} {{keyPattern}} and value pattern {{operatorValue}} {{valuePattern}}",
                    title: "Account tag pattern"
                }
            }));
    return (conditionType: ConditionType, variant: "title" | "description", options?: TranslateOptions) =>
        variant === "title"
            ? localization[conditionType].title()
            : localization[conditionType].description(options);
}