﻿import _ from "lodash";

export class Cache<TValue> {
    private compoundKeyMap = new Map<any, Map<any, any>>();
    private compoundKeyToValueMap = new Map<object, TValue>();

    public has(key: CacheKey) {
        const compoundKey = this.getCompoundKey(key);
        return this.compoundKeyToValueMap.has(compoundKey);
    }

    public get(key: CacheKey) {
        const compoundKey = this.getCompoundKey(key);
        return this.compoundKeyToValueMap.get(compoundKey);
    }

    public remove(key: CacheKey) {
        const compoundKey = this.getCompoundKey(key);
        this.compoundKeyToValueMap.delete(compoundKey);
        this.deleteCompoundKey(compoundKey);
    }

    public removeAll() {
        this.compoundKeyToValueMap.clear();
        this.compoundKeyMap.clear();
    }

    public set(key: CacheKey, value: any) {
        const compoundKey = this.getCompoundKey(key);
        this.compoundKeyToValueMap.set(compoundKey, value);
    }

    private getCompoundKey(key: CacheKey) {
        if (!_.isArray(key)) {
            return key;
        }

        const [key1, key2] = key;
        if (_.isNil(key2)) {
            return key1;
        }

        if (!this.compoundKeyMap.has(key1)) {
            this.compoundKeyMap.set(key1, new Map<object, object>());
        }

        const key1KeysMap = this.compoundKeyMap.get(key1)!;
        if (!key1KeysMap.has(key2)) {
            key1KeysMap.set(key2, {});
        }

        return key1KeysMap.get(key2)!;
    }

    private deleteCompoundKey(key: CacheKey) {
        if (!_.isArray(key)) {
            return;
        }

        const [key1, key2] = key;
        if (!this.compoundKeyMap.has(key1)) {
            return;
        }

        const key1KeysMap = this.compoundKeyMap.get(key1)!;
        key1KeysMap.delete(key2);

        if (key1KeysMap.size === 0) {
            this.compoundKeyMap.delete(key1);
        }
    }
}

export type CacheKey = any | [any, any];