﻿import { FormLayout, Message, Optional, useChangeEffect, useInputValidation, useLocalization } from "@infrastructure";
import { Button, CircularProgress, FormControl, FormHelperText, Stack, TextField } from "@mui/material";
import _ from "lodash";
import React, { Fragment, useState } from "react";
import { ConfigurationController, Contract, scopeNodeModelStore, useScopeNavigationViewContext, useTheme } from "../../../../../../../../../../../common";
import { useSetTrustedFilesContext } from "../TrustedFiles";

export function Add() {
    const { scopeNodeModel } = useScopeNavigationViewContext();
    const setContext = useSetTrustedFilesContext();

    const [addTrustedFileExecuting, setAddTrustedFileExecuting] = useState(false);
    const [addTrustedFileError, setAddTrustedFileError] = useState(false);

    const [trustedFileContentSha256String, setTrustedFileContentSha256String] = useState("");
    const [trustedFileComment, setTrustedFileComment] = useState<Optional<string>>(undefined);

    const localization =
        useLocalization(
            "views.customer.configuration.workloadAnalysis.maliciousFiles.trustedFiles.add",
            () => ({
                actions: {
                    add: {
                        error: "Failed to add the trusted file",
                        title: "Add"
                    }
                },
                fields: {
                    trustedFileComment: {
                        lengthExceedError: {
                            required: "Comment length cannot exceed {{maxCommentLength}} characters"
                        },
                        title: "Comment (Optional)"
                    },
                    trustedFileContentSha256String: {
                        duplicateError: {
                            required: "SHA-256 already exists"
                        },
                        emptyError: {
                            required: "SHA-256 cannot be empty"
                        },
                        invalidValue: {
                            required: "Invalid SHA-256"
                        },
                        title: "SHA-256"
                    }
                },
                title: "Add Trusted File SHA-256 Hashes"
            }));

    async function addTrustedFile() {
        setAddTrustedFileExecuting(true);
        setAddTrustedFileError(false);

        try {
            const insertWorkloadAnalysisTrustedFilesResponse =
                await ConfigurationController.insertWorkloadAnalysisTrustedFiles(
                    new Contract.ConfigurationControllerInsertWorkloadAnalysisTrustedFilesRequest(
                        trustedFileComment,
                        [trustedFileContentSha256String],
                        scopeNodeModel.configuration.id));

            await scopeNodeModelStore.notify(scopeNodeModel.configuration.id);

            setContext(
                context => ({
                    ...context,
                    addOpen: false,
                    scopeConfigurationTrustedFiles: insertWorkloadAnalysisTrustedFilesResponse.trustedFiles
                }));
        } catch (error) {
            setAddTrustedFileError(true);
        }

        setAddTrustedFileExecuting(false);
    }

    const maxCommentLength = 200;

    const sha256StringRegex = /^[A-Fa-f0-9]{64}?$/;
    const [trustedFileContentSha256StringValidationController, trustedFileContentSha256StringValidationMessage] =
        useInputValidation(
            () => {
                if (_.isEmpty(trustedFileContentSha256String.trim())) {
                    return localization.fields.trustedFileContentSha256String.emptyError.required();
                }

                if (!sha256StringRegex.test(trustedFileContentSha256String)) {
                    return localization.fields.trustedFileContentSha256String.invalidValue.required();
                }

                if (_.some(
                    scopeNodeModel.configuration.scopeSections.workloadAnalysis.trustedFiles,
                    trustedFile => trustedFile.contentSha256String === trustedFileContentSha256String)) {
                    return localization.fields.trustedFileContentSha256String.duplicateError.required();
                }

                return undefined;
            },
            [scopeNodeModel, trustedFileContentSha256String]);
    const [trustedFileCommentValidationController, trustedFileCommentValidationMessage] =
        useInputValidation(
            () => {
                if (_.isNil(trustedFileComment)) {
                    return undefined;
                }

                if (trustedFileComment.length > maxCommentLength) {
                    return localization.fields.trustedFileComment.lengthExceedError.required({ maxCommentLength });
                }
                return undefined;
            },
            [scopeNodeModel, trustedFileComment]);

    const [valid, setValid] = useState(false);
    useChangeEffect(
        () => {
            setValid(
                trustedFileContentSha256StringValidationController.isValid() &&
                trustedFileCommentValidationController.isValid);
        },
        [trustedFileContentSha256String, trustedFileComment]);

    const theme = useTheme();
    return (
        <FormLayout
            footerOptions={{
                contentElement:
                    <Stack
                        alignItems="center"
                        direction="row"
                        justifyContent="flex-end"
                        spacing={1}>
                        <Fragment>
                            {addTrustedFileExecuting && (
                                <CircularProgress
                                    size={theme.spacing(2)}
                                    variant="indeterminate"/>)}
                            <Button
                                disabled={!valid || addTrustedFileExecuting}
                                onClick={() => addTrustedFile()}>
                                {localization.actions.add.title()}
                            </Button>
                        </Fragment>
                    </Stack>
            }}
            titleOptions={{ text: localization.title() }}>
            <Stack spacing={2}>
                <FormControl
                    fullWidth={true}
                    variant="standard">
                    <TextField
                        disabled={addTrustedFileExecuting}
                        label={localization.fields.trustedFileContentSha256String.title()}
                        sx={{ marginBottom: theme.spacing(2) }}
                        value={trustedFileContentSha256String}
                        variant="outlined"
                        onChange={event => setTrustedFileContentSha256String(event.target.value)}/>
                    {!_.isNil(trustedFileContentSha256StringValidationMessage) && (
                        <FormHelperText error={true}>{trustedFileContentSha256StringValidationMessage}</FormHelperText>)}
                </FormControl>
                <FormControl
                    fullWidth={true}
                    variant="standard">
                    <TextField
                        disabled={addTrustedFileExecuting}
                        label={localization.fields.trustedFileComment.title()}
                        sx={{ marginBottom: theme.spacing(2) }}
                        value={trustedFileComment}
                        variant="outlined"
                        onChange={event => setTrustedFileComment(event.target.value)}/>
                    {!_.isNil(trustedFileCommentValidationMessage) && (
                        <FormHelperText error={true}>{trustedFileCommentValidationMessage}</FormHelperText>)}
                </FormControl>
                {addTrustedFileError && (
                    <Message
                        level="error"
                        title={localization.actions.add.error()}/>)}
            </Stack>
        </FormLayout>);
}