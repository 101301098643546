﻿import { ValuesFilter, ValuesFilterItem } from "@infrastructure";
import React, { memo } from "react";
import { Contract } from "..";
import { useAccessLevelTranslator } from "../hooks/useAccessLevelTranslator";

const AccessLevelFilterMemo = memo(AccessLevelFilter);
export { AccessLevelFilterMemo as AccessLevelFilter };

type AccessLevelFilterProps = {
    placeholder: string;
};

function AccessLevelFilter({ placeholder }: AccessLevelFilterProps) {
    const accessLevelTranslator = useAccessLevelTranslator();

    return (
        <ValuesFilter
            placeholder={placeholder}
            sorted={false}>
            <ValuesFilterItem
                title={accessLevelTranslator(Contract.AccessLevel.Public)}
                value={Contract.AccessLevel.Public}/>
            <ValuesFilterItem
                title={accessLevelTranslator(Contract.AccessLevel.External)}
                value={Contract.AccessLevel.External}/>
            <ValuesFilterItem
                title={accessLevelTranslator(Contract.AccessLevel.CrossTenant)}
                value={Contract.AccessLevel.CrossTenant}/>
            <ValuesFilterItem
                title={accessLevelTranslator(Contract.AccessLevel.Internal)}
                value={Contract.AccessLevel.Internal}/>
        </ValuesFilter>);
}