import { CopyToClipboardLinkButton } from "@infrastructure";
import { Stack } from "@mui/material";
import React, { ReactNode } from "react";

type ProfileActionsProps = {
    children?: ReactNode;
    getLink?: () => string;
    menu?: ReactNode;
    message?: ReactNode;
};

export function ProfileActions({ children, getLink, menu, message }: ProfileActionsProps) {
    return (
        <Stack
            alignItems="center"
            direction="row">
            <Stack
                alignItems="center"
                direction="row"
                spacing={1}>
                {message}
                {getLink &&
                    <CopyToClipboardLinkButton
                        getLink={getLink}
                        key="copyLink"/>}
                {children}
            </Stack>
            {menu}
        </Stack>);
}