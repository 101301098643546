import { Dialog, makeContextProvider } from "@infrastructure";
import React from "react";
import { Contract } from "../../../../../../../../../../../../common";
import { AddOrEdit, Table } from "./components";

export class WebhookContext {
    constructor(
        public addOrEditOpen: boolean | Contract.ScopeSystemEntityModel) {
    }
}

export const [useWebhookContext, useSetWebhookContext, useWebhookContextProvider] = makeContextProvider<WebhookContext>();

export function Webhook() {
    const [context, setContext, ContextProvider] = useWebhookContextProvider(() => new WebhookContext(false));

    return (
        <ContextProvider>
            {context.addOrEditOpen !== false && (
                <Dialog
                    size="large"
                    variant="editor"
                    onClose={
                        () =>
                            setContext(
                                context => ({
                                    ...context,
                                    addOrEditOpen: false
                                }))}>
                    <AddOrEdit/>
                </Dialog>)}
            <Table/>
        </ContextProvider>);
}