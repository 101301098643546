import { EmptyMessageText, useLocalization } from "@infrastructure";
import React from "react";
import { useOciCommonIamPrincipalInfoItemElements, useOciDefaultResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { Contract } from "../../../../../../../../../../common";
import { EntitiesInfoCard, Info } from "../../../../components";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";

export function useOciIamGroupDefinition(resourceModel: Contract.OciResourceModel, options?: DefinitionOptions) {
    const groupModel = resourceModel as Contract.OciIamGroupModel;
    const commonIamPrincipalInfoItemElements = useOciCommonIamPrincipalInfoItemElements(groupModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useOciDefaultResourceInfoItemElements(resourceModel);

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.oci.useOciIamGroupDefinition",
            () => ({
                info: {
                    cards: {
                        memberUserIds: {
                            allDomainUsers: "All users in the domain",
                            title: "Users"
                        }
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    commonIamPrincipalInfoItemElements.domainIdInfoItemElement,
                    commonIamPrincipalInfoItemElements.descriptionInfoItemElement
                ]}
                options={options?.infoOptions}>
                <EntitiesInfoCard
                    emptyMessage={new EmptyMessageText(localization.info.cards.memberUserIds.allDomainUsers())}
                    entityIdsOrModels={groupModel.memberUserIds}
                    entityTypeName={Contract.TypeNames.OciIamUser}
                    title={localization.info.cards.memberUserIds.title()}/>
            </Info>,
        sensitive: false
    });
}