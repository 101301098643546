//@ts-nocheck
import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";
import { Contract, EntitiesIcon, useTheme } from "../../../../common";

type OneLoginDirectoryEntityIconProps =
    SvgIconProps & {
        blurred: boolean;
        entityTypeName: string;
        variant?: string;
    };

export function OneLoginDirectoryEntityIcon({ blurred, entityTypeName, variant, ...props }: OneLoginDirectoryEntityIconProps) {
    switch (entityTypeName) {
        case Contract.TypeNames.OneLoginDirectoryUser:
            return (
                <SvgIcon {...props} filter={blurred ? "url(#greyscale)" : undefined}>
                    <path d="M7 10C8.93298 10 10.5 8.43298 10.5 6.5C10.5 4.56702 8.93298 3 7 3C5.06702 3 3.5 4.56702 3.5 6.5C3.5 8.43298 5.06702 10 7 10Z"  />
                    <path d="M6 11C2.68629 11 0 13.6863 0 17V18.4C0 18.7314 0.26863 19 0.6 19H9.57074C9.52412 18.6734 9.5 18.3396 9.5 18C9.5 15.8163 10.4976 13.8676 12.0623 12.5843C10.9936 11.6007 9.56696 11 8 11H6Z"  />
                    <path d="M15.3148 13.0276C14.5183 13.1379 13.7125 13.4687 13.0324 13.962C12.6893 14.2101 12.1777 14.7279 11.9295 15.0802C11.5588 15.601 11.2433 16.3118 11.1146 16.9214C10.7194 18.7504 11.3627 20.6345 12.7935 21.8355C14.1169 22.9445 15.9245 23.2876 17.588 22.7515C17.9465 22.6351 18.5868 22.3103 18.9207 22.0714C19.2761 21.8202 19.7938 21.3085 20.0389 20.9685C20.5597 20.2485 20.8784 19.4489 20.9764 18.6064C21.0254 18.1928 20.9948 17.3473 20.9151 16.9735C20.5414 15.2426 19.3067 13.8425 17.637 13.2604C17.0917 13.0705 16.8068 13.0245 16.1175 13.0122C15.7805 13.0061 15.419 13.0122 15.3148 13.0276ZM16.6965 16.4159C16.7394 16.4343 16.7455 16.612 16.7455 18.1407C16.7455 19.4275 16.7363 19.8564 16.7088 19.884C16.6628 19.9299 15.6824 19.936 15.612 19.8901C15.5722 19.8656 15.566 19.6971 15.5568 18.7259L15.5477 17.5924H15.1739C14.9227 17.5924 14.7848 17.5801 14.7603 17.5556C14.7358 17.5311 14.7235 17.3657 14.7235 17.0133C14.7235 16.5875 14.7327 16.5017 14.7756 16.4527C14.8216 16.4006 14.892 16.3975 15.7376 16.3975C16.237 16.3975 16.6689 16.4067 16.6965 16.4159Z"  />
                </SvgIcon>);
        case Contract.TypeNames.OneLoginDirectoryGroup:
            return (
                <SvgIcon {...props} filter={blurred ? "url(#greyscale)" : undefined}>
                    <path d="M17.7015 12.0277C16.905 12.138 16.0993 12.4688 15.4191 12.9621C15.076 13.2102 14.5644 13.728 14.3162 14.0803C13.9455 14.6011 13.63 15.3119 13.5013 15.9215C13.1061 17.7505 13.7495 19.6347 15.1802 20.8356C16.5037 21.9446 18.3112 22.2878 19.9747 21.7516C20.3332 21.6352 20.9735 21.3105 21.3074 21.0715C21.6628 20.8203 22.1806 20.3087 22.4256 19.9686C22.9465 19.2486 23.2651 18.449 23.3631 17.6065C23.4121 17.1929 23.3815 16.3474 23.3018 15.9736C22.9281 14.2427 21.6934 12.8426 20.0238 12.2605C19.4784 12.0706 19.1935 12.0246 18.5042 12.0124C18.1672 12.0062 17.8057 12.0124 17.7015 12.0277ZM19.0832 15.416C19.1261 15.4344 19.1322 15.6121 19.1322 17.1409C19.1322 18.4276 19.1231 18.8565 19.0955 18.8841C19.0495 18.93 18.0692 18.9361 17.9987 18.8902C17.9589 18.8657 17.9528 18.6972 17.9436 17.726L17.9344 16.5925H17.5606C17.3094 16.5925 17.1715 16.5802 17.147 16.5557C17.1225 16.5312 17.1103 16.3658 17.1103 16.0135C17.1103 15.5876 17.1194 15.5018 17.1623 15.4528C17.2083 15.4007 17.2788 15.3977 18.1243 15.3977C18.6237 15.3977 19.0557 15.4069 19.0832 15.416Z" />
                    <path d="M8.13292 5.11202C8.13292 6.83087 6.73956 8.22423 5.02071 8.22423C3.30204 8.22423 1.90869 6.83087 1.90869 5.11202C1.90869 3.39335 3.30204 2 5.02071 2C6.73956 2 8.13292 3.39335 8.13292 5.11202Z" />
                    <path d="M6.44668 15.6169L0.913527 15.6168C0.623894 15.6168 0.388344 15.3832 0.38623 15.0935V13.5127C0.38623 12.4242 0.818485 11.3806 1.58813 10.6111C2.3576 9.84143 3.40145 9.40918 4.48972 9.40918H5.55604C6.30551 9.40848 7.0409 9.61363 7.68182 10.0022C8.1867 10.3081 8.61771 10.7186 8.94725 11.2039C8.58478 11.4807 8.2491 11.8015 7.94743 12.1623C7.13593 13.1328 6.61683 14.3372 6.44668 15.6169Z" />
                    <path d="M15.7738 6.51654C15.7738 8.39609 14.2501 9.91979 12.3705 9.91979C10.491 9.91979 8.96729 8.39609 8.96729 6.51654C8.96729 4.63699 10.491 3.11328 12.3705 3.11328C14.2501 3.11328 15.7738 4.63699 15.7738 6.51654Z" />
                    <path d="M11.4423 17.8871C11.4056 17.5966 11.3867 17.3005 11.3867 17C11.3867 14.6834 12.5094 12.6313 14.2409 11.3575C13.4881 11.0588 12.6686 10.9424 11.8505 11.0268C10.6017 11.1555 9.4451 11.7429 8.6045 12.6751C7.76371 13.6075 7.29861 14.8185 7.29932 16.074V17.2379C7.29932 17.41 7.36765 17.5751 7.48936 17.6968C7.61109 17.8186 7.77637 17.8871 7.94849 17.8871H11.4423Z" />
                </SvgIcon>);
        case Contract.TypeNames.OneLoginDirectoryRole:
            return (
                <SvgIcon {...props} filter={blurred ? "url(#greyscale)" : undefined}>
                    <path d="M16.3419 9.5974C16.7368 9.4013 17.1497 9.2473 17.5671 9.14124C15.9881 7.90244 13.9735 7.17336 11.8211 7.17336C10.3653 7.17336 8.9411 7.50655 7.66115 8.13972C7.66639 8.1354 7.67111 8.13091 7.6757 8.12654C7.67921 8.1232 7.68265 8.11992 7.68619 8.11685C9.28736 6.80914 11.311 6.08886 13.3853 6.08886C16.0194 6.08886 18.4097 7.23462 20.0664 9.06383C20.3573 9.10728 20.6168 9.17968 20.9645 9.3009C21.3053 9.41985 21.6311 9.56724 21.9392 9.74019C20.1419 6.88788 16.9647 5 13.3853 5C11.0611 5 8.79247 5.80739 6.99804 7.27299C4.62814 9.20245 3.26924 12.0612 3.26924 15.116C3.26924 15.1272 3.26965 15.1384 3.27006 15.1495C3.27047 15.1607 3.27088 15.1718 3.27088 15.183C2.90774 15.3654 2.52392 15.5396 2.14228 15.7067L2.14009 15.7077C0.978325 16.2157 0.139245 16.5826 0.0141044 17.2453C-0.0174724 17.4135 -0.0229167 17.7429 0.297207 18.063C0.86178 18.6276 1.94519 18.8301 3.04603 18.8301C3.50444 18.8301 3.96502 18.7953 4.39294 18.7365C5.12193 18.6374 6.48301 18.3701 7.4069 17.6939C8.6802 16.771 11.3558 16.3527 13.1566 16.3503C13.074 15.9951 13.0235 15.6314 13.0065 15.2636C11.3444 15.2866 9.11879 15.6243 7.58983 16.341C6.52166 16.2931 5.5308 16.0056 4.76098 15.5309L4.76097 15.5307C4.75771 15.3827 4.75445 15.2347 4.75445 15.1776C4.75445 12.9258 5.80955 10.814 7.65081 9.37938C8.90136 8.64822 10.3425 8.26222 11.8211 8.26222C13.4797 8.26222 15.0453 8.74998 16.3419 9.5974ZM2.57782 16.7047C2.12377 16.9028 1.47862 17.1854 1.19279 17.3874C1.66427 17.6787 2.80321 17.8551 4.24595 17.6574C4.90634 17.5676 5.52318 17.4097 6.02133 17.2094C5.16004 16.9954 4.38206 16.6366 3.75379 16.1581C3.36887 16.3519 2.9709 16.5332 2.57782 16.7047Z" fill="#FF5558" fillRule="evenodd" clipRule="evenodd" />
                    <path d="M18.3148 10.0277C17.5183 10.138 16.7125 10.4688 16.0324 10.9621C15.6893 11.2102 15.1777 11.728 14.9295 12.0803C14.5588 12.6011 14.2433 13.3119 14.1146 13.9215C13.7194 15.7505 14.3627 17.6347 15.7935 18.8356C17.1169 19.9446 18.9245 20.2878 20.588 19.7516C20.9465 19.6352 21.5868 19.3105 21.9207 19.0715C22.2761 18.8203 22.7938 18.3087 23.0389 17.9686C23.5597 17.2486 23.8784 16.449 23.9764 15.6065C24.0254 15.1929 23.9948 14.3474 23.9151 13.9736C23.5414 12.2427 22.3067 10.8426 20.637 10.2605C20.0917 10.0706 19.8068 10.0246 19.1175 10.0124C18.7805 10.0062 18.419 10.0124 18.3148 10.0277ZM19.6965 13.416C19.7394 13.4344 19.7455 13.6121 19.7455 15.1409C19.7455 16.4276 19.7363 16.8565 19.7088 16.8841C19.6628 16.93 18.6824 16.9361 18.612 16.8902C18.5722 16.8657 18.566 16.6972 18.5568 15.726L18.5476 14.5925H18.1739C17.9227 14.5925 17.7848 14.5802 17.7603 14.5557C17.7358 14.5312 17.7235 14.3658 17.7235 14.0135C17.7235 13.5876 17.7327 13.5018 17.7756 13.4528C17.8216 13.4007 17.892 13.3977 18.7376 13.3977C19.237 13.3977 19.6689 13.4069 19.6965 13.416Z"  />
                </SvgIcon>);
    }

    return <EntitiesIcon {...props} filter={blurred ? "url(#greyscale)" : undefined}/>;
}