﻿import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function MalaysiaIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                clipRule="evenodd"
                d="M22 4.99829H2C1.44771 4.99829 1 5.44601 1 5.99829V17.9621C1 18.5144 1.44772 18.9621 2 18.9621H22C22.5523 18.9621 23 18.5144 23 17.9621V5.99829C23 5.44601 22.5523 4.99829 22 4.99829ZM2 3.99829C0.895431 3.99829 0 4.89372 0 5.99829V17.9621C0 19.0667 0.89543 19.9621 2 19.9621H22C23.1046 19.9621 24 19.0667 24 17.9621V5.99829C24 4.89372 23.1046 3.99829 22 3.99829H2Z"
                fill="#D8DEE6"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M22 4.99829H2C1.44771 4.99829 1 5.44601 1 5.99829V17.9621C1 18.5144 1.44772 18.9621 2 18.9621H22C22.5523 18.9621 23 18.5144 23 17.9621V5.99829C23 5.44601 22.5523 4.99829 22 4.99829ZM2 3.99829C0.895431 3.99829 0 4.89372 0 5.99829V17.9621C0 19.0667 0.89543 19.9621 2 19.9621H22C23.1046 19.9621 24 19.0667 24 17.9621V5.99829C24 4.89372 23.1046 3.99829 22 3.99829H2Z"
                fill="#D8DEE6"
                fillRule="evenodd"/>
            <g clipPath="url(#clip0_60203_5258)">
                <rect
                    fill="white"
                    height="15.9638"
                    rx="2"
                    width="24"
                    y="4"/>
                <path
                    d="M0 3.99829H24V19.9621H0V3.99829Z"
                    fill="#000008"/>
                <path
                    d="M0 3.99829H24V19.9621H0V3.99829Z"
                    fill="#CC0000"/>
                <path
                    d="M0 3.99829H24V5.13904H0V3.99829Z"
                    fill="#CC0000"/>
                <path
                    d="M0 5.13879H24V6.27954H0V5.13879Z"
                    fill="white"/>
                <path
                    d="M0 6.27991H24V7.42065H0V6.27991Z"
                    fill="#CC0000"/>
                <path
                    d="M0 7.42053H24V8.55463H0V7.42053Z"
                    fill="white"/>
                <path
                    d="M0 8.55811H24V9.69885H0V8.55811Z"
                    fill="#CC0000"/>
                <path
                    d="M0 9.69885H24V10.8396H0V9.69885Z"
                    fill="white"/>
                <path
                    d="M0 10.8392H24V11.98H0V10.8392Z"
                    fill="#CC0000"/>
                <path
                    d="M0 11.9803H24V13.1211H0V11.9803Z"
                    fill="white"/>
                <path
                    d="M0 13.1208H24V14.2616H0V13.1208Z"
                    fill="#CC0000"/>
                <path
                    d="M0 14.2616H24V15.4023H0V14.2616Z"
                    fill="white"/>
                <path
                    d="M0 15.4025H24V16.5366H0V15.4025Z"
                    fill="#CC0000"/>
                <path
                    d="M0 16.5398H24V17.6805H0V16.5398Z"
                    fill="white"/>
                <path
                    d="M0 17.6807H24V18.8214H0V17.6807Z"
                    fill="#CC0000"/>
                <path
                    d="M0 19H24V20H0V19Z"
                    fill="#BAC5D3"/>
                <path
                    d="M0 4.01501H12V13.1377H0V4.01501Z"
                    fill="#000066"/>
                <path
                    d="M7.61405 6.45475L7.804 7.74321L8.53212 6.66686L8.13956 7.90783L9.2634 7.24935L8.37383 8.19908L9.67178 8.09777L8.4593 8.5663L9.67178 9.03483L8.37383 8.93353L9.2634 9.88325L8.13956 9.22478L8.52895 10.4689L7.80083 9.38939L7.61089 10.6779L7.42411 9.38939L6.69599 10.4657L7.08854 9.22478L5.9647 9.88325L6.85111 8.93353L5.55315 9.03483L6.7688 8.5663L5.55632 8.09777L6.85427 8.19908L5.9647 7.24935L7.08854 7.90783L6.69599 6.66369L7.42411 7.74321L7.61405 6.45475ZM6.55986 6.50857C6.21707 6.35607 5.84159 6.29167 5.46757 6.32124C5.09355 6.35081 4.73285 6.47341 4.41828 6.67788C4.10371 6.88235 3.84523 7.16222 3.66637 7.49202C3.4875 7.82183 3.39392 8.19112 3.39412 8.5663C3.39392 8.94149 3.4875 9.31078 3.66637 9.64058C3.84523 9.97039 4.10371 10.2503 4.41828 10.4547C4.73285 10.6592 5.09355 10.7818 5.46757 10.8114C5.84159 10.8409 6.21707 10.7765 6.55986 10.624C6.18138 10.8959 5.73524 11.0581 5.27052 11.0927C4.80581 11.1273 4.34055 11.0331 3.92596 10.8203C3.51185 10.6072 3.1645 10.2841 2.92211 9.88647C2.67972 9.48881 2.55167 9.03201 2.55203 8.5663C2.55219 8.10071 2.68069 7.64417 2.92341 7.24685C3.16613 6.84953 3.51369 6.52681 3.92788 6.31415C4.34207 6.10149 4.80687 6.00713 5.2712 6.04143C5.73552 6.07573 6.18141 6.23736 6.55986 6.50857Z"
                    fill="#FFCC00"/>
            </g>
            <defs>
                <clipPath id="clip0_60203_5258">
                    <rect
                        fill="white"
                        height="15.9638"
                        rx="2"
                        width="24"
                        y="4"/>
                </clipPath>
            </defs>
        </SvgIcon>);
}