import { DataTableActions, UnexpectedError } from "@infrastructure";
import { Dictionary } from "lodash";
import { MutableRefObject, ReactNode, useMemo } from "react";
import { Contract, GetItemValueDefinition } from "../../../../../../../../common";
import { ComplianceView } from "../../../../Compliance";
import { useBuiltInDefinition, useCustomDefinition } from "./hooks";

export function useDefinition(view: ComplianceView, scopeId: string): ComplianceDefinition {
    const useDefinition =
        useMemo(
            () => {
                switch (view) {
                    case ComplianceView.BuiltIn:
                        return useBuiltInDefinition;
                    case ComplianceView.Custom:
                        return useCustomDefinition;
                    default:
                        throw new UnexpectedError("ComplianceDefinition.view", view);
                }
            },
            [view]);

    return useDefinition(scopeId);
}

export type ComplianceDefinition = {
    actions?: ReactNode[];
    actionsRef?: MutableRefObject<DataTableActions | undefined>;
    defaultSortColumn?: string;
    defaultSortMap?: Dictionary<"desc" | "asc">;
    getAdditionalColumn: (enabledComplianceSectionDatasMap: Dictionary<Contract.ComplianceSectionData>) => ReactNode;
    getColumnIdToGetItemValueMap?: (enabledComplianceSectionDatasMap: Dictionary<Contract.ComplianceSectionData>) => { [columnId: string]: GetItemValueDefinition<Contract.ComplianceSectionData> };
    initialFilterMap?: Dictionary<any>;
};