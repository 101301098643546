import { Divider } from "@mui/material";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import { EmptyMessageText, ItemSelector, Loading, TextViewer, useLocalization } from "@infrastructure";
import { Contract, InfoCard, InfoItem, KeyValueTable, KeyValueTableItem, VerticalTopItemsInfoItem } from "../../../../../../../../../../../../common";
import { AwsLambdaFunctionConfigurationHelper, AwsResourceAccessLevel } from "../../../../../../../../../../../../tenants";
import { EntitiesInfoItem } from "../../../../../../components";
import { ExternalTriggersInfoSection } from "./ExternalTriggersInfoSection";
import { TriggersInfoSection } from "./TriggersInfoSection";

type RevisionsProps = {
    functionConfigurationModel: Contract.AwsLambdaFunctionConfigurationModel;
};

export function Revisions({ functionConfigurationModel }: RevisionsProps) {
    const functionConfiguration = functionConfigurationModel.entity as Contract.AwsLambdaFunctionConfiguration;
    const [selectedRevision, setSelectedRevision] = useState(() => functionConfiguration.revisionMap[AwsLambdaFunctionConfigurationHelper.latest]);

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsLambdaFunctionConfigurationDefinition.revisions",
            () => ({
                fields: {
                    accessLevel: "Access",
                    aliases: "Aliases",
                    arn: "ARN",
                    environmentVariables: {
                        columns: {
                            key: "Key",
                            value: "Value"
                        },
                        empty: " No Environment Variables",
                        title: "Environment Variables"
                    },
                    kmsEncryptionKeyReferences: "Encryption Key",
                    network: {
                        empty: "No Network",
                        title: "Network"
                    },
                    policyDocument: {
                        empty: "No Policy",
                        title: "Policy"
                    },
                    role: "Role",
                    runtime: "Runtime"
                },
                latest: "Latest"
            }));

    const selectedRevisionAliasNames =
        useMemo(
            () =>
                _(functionConfiguration.aliasMap).
                    toPairs().
                    filter(([, alias]) =>
                        _(alias.revisionIdToWeightMap).
                            keys().
                            includes(selectedRevision.id)).
                    map(([aliasName]) => aliasName).
                    value(),
            [selectedRevision]);

    return (
        <InfoCard>
            <ItemSelector
                dense={true}
                fullWidth={true}
                items={
                    _(functionConfiguration.revisionMap).
                        keys().
                        orderBy(
                            [
                                revisionId => revisionId === AwsLambdaFunctionConfigurationHelper.latest,
                                revisionId => revisionId !== AwsLambdaFunctionConfigurationHelper.latest && Number(revisionId)
                            ],
                            [
                                "desc",
                                "desc"
                            ]).
                        value()}
                selectedItem={selectedRevision.id}
                sorted={false}
                onSelectedItemChanged={functionConfigurationRevisionId => setSelectedRevision(functionConfiguration.revisionMap[functionConfigurationRevisionId])}>
                {functionConfigurationRevisionId =>
                    functionConfigurationRevisionId === AwsLambdaFunctionConfigurationHelper.latest
                        ? localization.latest()
                        : functionConfigurationRevisionId}
            </ItemSelector>
            <Loading>
                <InfoCard columns={true}>
                    <InfoItem
                        title={localization.fields.arn()}
                        value={selectedRevision.arn}/>
                    <VerticalTopItemsInfoItem
                        items={selectedRevisionAliasNames}
                        title={localization.fields.aliases()}/>
                    <EntitiesInfoItem
                        entityIdsOrModels={selectedRevision.roleReference.idReference}
                        entityTypeName={Contract.TypeNames.AwsIamRole}
                        title={localization.fields.role()}/>
                    <InfoItem
                        key="accessLevel"
                        title={localization.fields.accessLevel()}
                        value={
                            _.isNil(functionConfigurationModel.revisionIdToAccessLevelMap)
                                ? undefined
                                : <AwsResourceAccessLevel accessLevel={functionConfigurationModel.revisionIdToAccessLevelMap[selectedRevision.id]}/>}/>
                    <EntitiesInfoItem
                        entityIdsOrModels={functionConfigurationModel?.revisionIdToKeyIdReferenceMap?.[selectedRevision.id]}
                        entityTypeName={Contract.TypeNames.AwsKmsKey}
                        title={localization.fields.kmsEncryptionKeyReferences()}/>
                    <InfoItem
                        key="runtime"
                        location="all"
                        title={localization.fields.runtime()}
                        value={selectedRevision.runtime}/>
                </InfoCard>
                <Divider/>
                <InfoCard
                    emptyMessage={new EmptyMessageText(localization.fields.network.empty())}
                    title={localization.fields.network.title()}>
                    {_.isEmpty(selectedRevision.securityGroupIds) &&
                    _.isEmpty(selectedRevision.subnetIds) &&
                    _.isNil(selectedRevision.vpcId)
                        ? undefined
                        : <InfoCard columns={true}>
                            <EntitiesInfoItem
                                entityIdsOrModels={selectedRevision.vpcId}
                                entityTypeName={Contract.TypeNames.AwsEc2Vpc}/>
                            <EntitiesInfoItem
                                entityIdsOrModels={selectedRevision.subnetIds}
                                entityTypeName={Contract.TypeNames.AwsEc2Subnet}/>
                            <EntitiesInfoItem
                                entityIdsOrModels={selectedRevision.securityGroupIds}
                                entityTypeName={Contract.TypeNames.AwsEc2SecurityGroup}/>
                        </InfoCard>}
                </InfoCard>
                <Divider/>
                <InfoCard
                    emptyMessage={new EmptyMessageText(localization.fields.policyDocument.empty())}
                    title={localization.fields.policyDocument.title()}>
                    {_.isNil(selectedRevision.policyDocument)
                        ? undefined
                        : <TextViewer
                            format="json"
                            text={selectedRevision.policyDocument!.raw}/>}
                </InfoCard>
                <Divider/>
                <TriggersInfoSection
                    triggerResourceArnToIdReferenceMap={functionConfigurationModel.triggerResourceArnToIdReferenceMap}
                    triggers={selectedRevision.triggers.triggers}/>
                <ExternalTriggersInfoSection externalTriggers={selectedRevision.triggers.externalTriggers}/>
                <InfoCard
                    title={localization.fields.environmentVariables.title()}>
                    <KeyValueTable
                        emptyMessageText={new EmptyMessageText(localization.fields.environmentVariables.empty())}
                        items={
                            _.map(
                                selectedRevision.environmentVariableNameToValueMap,
                                (value, key) =>
                                    new KeyValueTableItem(
                                        key,
                                        value))}/>
                </InfoCard>
            </Loading>
        </InfoCard>);
}