import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, Entity, entityModelStore } from "../../../../../../..";

export function useAadDeletePrincipalGroupMembershipChangeDefinition(change: Contract.AadChange, entityLinkDisabled?: boolean) {
    const deletePrincipalGroupMembershipChange = change as Contract.AadDeletePrincipalGroupMembershipChange;
    const principalModel = entityModelStore.useGet(deletePrincipalGroupMembershipChange.principalId);

    const localization =
        useLocalization(
            "common.change.hooks.useDefinition.hooks.useAadDefinition.hooks.useAadDeletePrincipalGroupMembershipChangeDefinition",
            () => ({
                title: "Remove {{principal}} from {{group}}"
            }));

    return {
        title:
            localization.title({
                group:
                    <Entity
                        entityIdOrModel={deletePrincipalGroupMembershipChange.groupId}
                        glanceOptions={{ disabled: true }}
                        linkOptions={{ disabled: entityLinkDisabled }}
                        variant="typeText"/>,
                principal:
                    <Entity
                        entityIdOrModel={principalModel}
                        glanceOptions={{ disabled: true }}
                        linkOptions={{ disabled: entityLinkDisabled }}
                        variant="typeText"/>
            })
    };
}