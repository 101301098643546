import { Link, useLocalization } from "@infrastructure";
import React from "react";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../../../common";
import { useOciConsoleSignInStepTranslator } from "../../../../../../../../../../../../../../tenants";
import { useCommonSectionsAndDescriptionDefinition } from "../../../../../useCommonSectionsAndDescriptionDefinition";
import { useGetOciComputeInstanceRiskContext } from "../../../contexts";

export function useOciComputeInstanceVolumeEncryptionInTransitDisabledRiskInstanceDefinition(riskModel: Contract.OciComputeInstanceVolumeEncryptionInTransitDisabledRiskModel) {
    const risk = riskModel.risk as Contract.OciComputeInstanceVolumeEncryptionInTransitDisabledRisk;
    const instanceModel = entityModelStore.useGet(risk.entityId) as Contract.OciComputeInstanceModel;

    const getOciComputeInstanceRiskContext = useGetOciComputeInstanceRiskContext();

    const consoleSignInStepTranslator = useOciConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.oci.hooks.compliance.useOciComputeInstanceVolumeEncryptionInTransitDisabledRiskDefinition.hooks.useOciComputeInstanceVolumeEncryptionInTransitDisabledRiskInstanceDefinition",
            () => ({
                description: "{{instance}} volume in-transit encryption is not enabled",
                sections: {
                    resolution: {
                        step1: {
                            link: "OCI documentation",
                            text: "Enabling in-transit encryption on an existing instance is not supported for shielded instances or instances with certain shapes as described in {{instanceVolumeEncryptionInTransitSupportedShapesAndImagesLink}}. To enable in-transit encryption for those instances, you must terminate the current instance and create a new one with in-transit encryption enabled."
                        },
                        step2: "Click **More actions** and then **Edit**",
                        step3: "Click **Show advanced options**",
                        step4: "In the **Launch options** tab, select the **Use in-transit encryption** option",
                        step5: "Click **Save changes**"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            instance:
                <Entity
                    entityIdOrModel={instanceModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () =>
            instanceModel.unknown
                ? []
                : [
                    consoleSignInStepTranslator(
                        Contract.OciConsoleView.Compute,
                        instanceModel.consoleUrl!),
                    localization.sections.resolution.step1.text({
                        instanceVolumeEncryptionInTransitSupportedShapesAndImagesLink:
                            <Link
                                urlOrGetUrl={riskModel.instanceVolumeEncryptionInTransitSupportedShapesAndImagesDocumentationUrl}
                                variant="external">
                                {localization.sections.resolution.step1.link()}
                            </Link>
                    }),
                    localization.sections.resolution.step2(),
                    localization.sections.resolution.step3(),
                    localization.sections.resolution.step4(),
                    localization.sections.resolution.step5()
                ],
        riskModel,
        () => {
            const instanceContextItems = getOciComputeInstanceRiskContext(instanceModel);
            return [
                instanceContextItems.generalInformation,
                instanceContextItems.status,
                instanceContextItems.sensitive,
                instanceContextItems.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}