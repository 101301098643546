import { ProjectIcon } from "@infrastructure";
import { SvgIconProps } from "@mui/material";
import React from "react";
import { Contract, FolderIcon, ScopeHelper, TenantHelper, TenantIcon } from "../../../..";
import { ProjectFolderIcon } from "./icons";

type IconProps = SvgIconProps & {
    colored?: boolean;
    scopeNodeModel: Contract.ScopeNodeModel;
};

export function Icon({ colored = true, scopeNodeModel, ...props }: IconProps) {
    if (ScopeHelper.isProject(scopeNodeModel)) {
        return <ProjectIcon/>;
    }

    if (ScopeHelper.isTenant(scopeNodeModel)) {
        const tenantType = ScopeHelper.getTenantType(scopeNodeModel);
        let data = undefined;
        if (tenantType === Contract.TenantType.Code) {
            const codeTenantConfiguration = scopeNodeModel.configuration as Contract.CodeTenantConfiguration;
            data = {
                codeTenantType: codeTenantConfiguration.codeTenantType,
                generalCodeTenant: TenantHelper.isGeneralCodeTenant(codeTenantConfiguration)
            };
        } else if (tenantType === Contract.TenantType.Ci) {
            data = {
                ciTenantType: (scopeNodeModel.configuration as Contract.CiTenantConfiguration).ciTenantType
            };
        }

        return (
            <TenantIcon
                colored={colored}
                data={data}
                tenantType={tenantType!}
                {...props}/>);
    }

    if (ScopeHelper.isProjectFolder(scopeNodeModel)) {
        return <ProjectFolderIcon {...props}/>;
    }

    return (
        <FolderIcon
            tenantType={ScopeHelper.getTenantType(scopeNodeModel)}
            {...props}/>);
}