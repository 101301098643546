import { map } from "@infrastructure";
import { Contract } from "../../../../../../../../../../../../../../../common";
import { EntityExternalConsoleLinkDefinition } from "../useDefinition";

export function useGcpEntityDefinition(entityModel: Contract.EntityModel, page: Contract.GcpConsolePage) {
    const getUrl =
        map(
            page,
            {
                [Contract.GcpConsolePage.Permissions]: () => () => undefined
            });

    return new EntityExternalConsoleLinkDefinition(getUrl());
}