import { useInputValidation, useLocalization, useOrderedWizardContext } from "@infrastructure";
import { FormControl, FormHelperText, Stack, TextField, Typography } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Contract, tenantModelStore, useTheme } from "../../../../../../../../../../../common";
import { useAddOrEditContext, useSetAddOrEditContext } from "../index";

export function TenantItem() {
    const addOrEditContext = useAddOrEditContext();
    const setAddOrEditContext = useSetAddOrEditContext();
    const tenantModels = tenantModelStore.useGetPermittedGcpTenants(addOrEditContext.gciTenantModel!.configuration.id);

    const [organizationRawShortId, setOrganizationRawShortId] = useState(addOrEditContext.organizationRawShortId ?? "");
    const [rawShortNameId, setRawShortNameId] = useState(addOrEditContext.rawShortNameId ?? "");

    const { setLoaded, setValid } = useOrderedWizardContext();
    useEffect(
        () => {
            setLoaded();
        },
        []);

    const localization =
        useLocalization(
            "views.customer.scopes.hooks.useDefinition.hooks.useGcpDefinition.addOrEdit.tenantItem",
            () => ({
                fields: {
                    organizationRawShortId: {
                        error: {
                            format : "Organization ID must contains numbers only",
                            required: "Organization ID cannot be empty"
                        },
                        subtitle: "Enter the GCP organization ID:",
                        title: "Organization ID"
                    },
                    rawShortNameId: {
                        error: {
                            exists: "Project ID already exists",
                            format: "Project ID must be 6-30 characters long, start with a letter, and only contain lowercase letters, numbers, and hyphens.",
                            required: "Project ID cannot be empty"
                        },
                        subtitle: "Enter the globally unique, **Project ID** of the project you want to add:",
                        title: "Project ID"
                    }
                }
            }));

    const [organizationRawShortIdValidationController, organizationRawShortIdValidationMessage] =
        useInputValidation(
            () => {
                const organizationRawIdValue = organizationRawShortId.trim();
                if (_.isEmpty(organizationRawIdValue)) {
                    return localization.fields.organizationRawShortId.error.required();
                }

                // https://cloud.google.com/resource-manager/docs/creating-managing-organization#retrieving_your_organization_id
                if (!/^[1-9][0-9]+$/.test(organizationRawIdValue)) {
                    return localization.fields.organizationRawShortId.error.format();
                }

                return undefined;
            },
            [organizationRawShortId]);

    const [rawShortNameIdValidationController, rawShortNameIdValidationMessage] =
        useInputValidation(
            () => {
                const validationRawShortIdValue = rawShortNameId.trim();
                if (_.isEmpty(validationRawShortIdValue)) {
                    return localization.fields.rawShortNameId.error.required();
                }

                // https://cloud.google.com/resource-manager/docs/creating-managing-projects#before_you_begin
                if (!/^[a-z][a-z0-9-]{4,28}[a-z0-9]$/.test(validationRawShortIdValue)) {
                    return localization.fields.rawShortNameId.error.format();
                }

                if (_.some(
                    tenantModels,
                    tenantModel => (tenantModel.configuration as Contract.GcpTenantConfiguration).rawShortNameId === validationRawShortIdValue)) {
                    return localization.fields.rawShortNameId.error.exists();
                }

                return undefined;
            },
            [rawShortNameId]);

    useEffect(
        () => {
            if (!_.isNil(addOrEditContext.tenantModel)) {
                return;
            }

            setAddOrEditContext(
                addContext => ({
                    ...addContext,
                    organizationRawShortId: organizationRawShortId.trim(),
                    rawShortNameId: rawShortNameId.trim()
                }));

            setValid(
                organizationRawShortIdValidationController.isValid() &&
                rawShortNameIdValidationController.isValid());
        },
        [organizationRawShortId, rawShortNameId]);

    const theme = useTheme();
    return (
        <Stack
            spacing={3}
            sx={{ maxWidth: theme.spacing(60) }}>
            <Typography>
                {localization.fields.rawShortNameId.subtitle()}
            </Typography>
            <FormControl
                fullWidth={true}
                variant="standard">
                <TextField
                    disabled={!_.isNil(addOrEditContext.tenantModel)}
                    label={localization.fields.rawShortNameId.title()}
                    value={rawShortNameId}
                    variant="outlined"
                    onChange={event => setRawShortNameId(event.target.value)}/>
                {!_.isNil(rawShortNameIdValidationMessage) && (
                    <FormHelperText error={true}>{rawShortNameIdValidationMessage}</FormHelperText>)}
            </FormControl>
            {_.isNil(addOrEditContext.gciTenantModel?.state.gcp?.organizationRawShortId) &&
                <Typography>
                    {localization.fields.organizationRawShortId.subtitle()}
                </Typography>}
            <FormControl
                fullWidth={true}
                variant="standard">
                <TextField
                    disabled={!_.isNil(addOrEditContext.gciTenantModel?.state.gcp?.organizationRawShortId)}
                    label={localization.fields.organizationRawShortId.title()}
                    value={organizationRawShortId}
                    variant="outlined"
                    onChange={event => setOrganizationRawShortId(event.target.value)}/>
                {!_.isNil(organizationRawShortIdValidationMessage) && (
                    <FormHelperText error={true}>{organizationRawShortIdValidationMessage}</FormHelperText>)}
            </FormControl>
        </Stack>);
}