﻿import { PasswordText, StringHelper, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, KeyValueTable, KeyValueTableItem, SecretExclusionActionCell, useTheme } from "../../../../../../../../../common";

export type ResourceTagSecretExistsRiskTagsSectionProps = {
    risk: Contract.Risk;
    secretTagKeys: string[];
    tags: Contract.ResourceTag[];
};

export function ResourceTagSecretExistsRiskTagsSection({ risk, secretTagKeys, tags }: ResourceTagSecretExistsRiskTagsSectionProps) {
    const [deletedSecretTags, resourceTagKeyToSecretMap] =
        useMemo(
            () => {
                const tagKeys =
                    new Set<string>(
                        _.map(
                            tags,
                            tag => tag.key));

                const deletedSecretTags =
                    _(secretTagKeys).
                        filter(secretTagKey => !tagKeys.has(secretTagKey)).
                        map(deletedSecretTagKey => new Contract.ResourceTag(deletedSecretTagKey, "-")).
                        value();

                const resourceTagKeyToSecretMap =
                    _(tags).
                        keyBy(resourceTag => resourceTag.key).
                        mapValues(resourceTag => _.includes(secretTagKeys, resourceTag.key)).
                        value();

                return [deletedSecretTags, resourceTagKeyToSecretMap];
            },
            [secretTagKeys, tags]);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.resourceTagSecretExistsRiskTagsSection",
            () => ({
                columns: {
                    key: "Key",
                    value: "Value"
                }
            }));

    const theme = useTheme();
    return (
        <KeyValueTable
            items={
                _(tags).
                    concat(deletedSecretTags).
                    map(
                        resourceTag =>
                            new KeyValueTableItem(
                                resourceTag.key,
                                resourceTag.value)).
                    orderBy(
                        [
                            tag => resourceTagKeyToSecretMap[tag.key],
                            tag => StringHelper.getSortValue(tag.key)
                        ],
                        [
                            "desc",
                            "asc"
                        ]).
                    value()}
            keyTitle={localization.columns.key()}
            renderActions={
                item =>
                    <SecretExclusionActionCell
                        displayNameToValueSecretDataMap={{
                            [localization.columns.value()]: {
                                secret: resourceTagKeyToSecretMap[item.key],
                                value: item.value
                            }
                        }}
                        risk={risk}
                        secretKey={item.key}/>}
            renderValue={
                item =>
                    resourceTagKeyToSecretMap[item.key]
                        ? <PasswordText password={item.value!}/>
                        : item.value}
            rowOptions={{
                getHighlightColor:
                    item =>
                        resourceTagKeyToSecretMap[item.key]
                            ? theme.palette.severity(risk.severity)
                            : undefined,
                getSx:
                    item =>
                        resourceTagKeyToSecretMap[item.key]
                            ? { backgroundColor: theme.palette.opacity(theme.palette.severity(risk.severity), 0.1) }
                            : undefined
            }}
            valueTitle={localization.columns.value()}/>);
}