import { Link, useLocalization } from "@infrastructure";
import React from "react";
import { CodeBranchIcon, CodeConsoleUrlHelper } from "../../tenants";
import { CodeTenantType } from "../controllers/types.generated";
import { ProfileTopbarInfoItem } from "./ProfileTopbarInfoItem";

type ProfileTopbarBranchInfoItemProps = {
    branchName: string;
    codeTenantType: CodeTenantType;
    url: string;
};

export function ProfileTopbarBranchInfoItem({ branchName, codeTenantType, url }: ProfileTopbarBranchInfoItemProps) {
    const localization =
        useLocalization(
            "common.profileTopbarBranchInfoItem",
            () => ({
                branch: "Branch"
            }));

    return (
        <ProfileTopbarInfoItem
            icon={<CodeBranchIcon/>}
            text={
                <Link
                    urlOrGetUrl={
                        CodeConsoleUrlHelper.getCodeBranchUrl(
                            branchName,
                            codeTenantType,
                            url)}
                    variant="external">
                    {branchName}
                </Link>}
            titleOrGetTitle={localization.branch()}/>);
}