import { Link, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { useGetRiskPolicyConfigurationRotationTimeFrameContextItem } from "../../../useGetRiskPolicyConfigurationRotationTimeFrameContextItem";
import { EntityExternalConsoleLink } from "../../components";
import { useGetGcpSecretManagerSecretRiskContext } from "../contexts";

export function useGcpSecretManagerSecretRotationTimeFrameRiskDefinition(riskModel: Contract.RiskModel) {
    const secretManagerSecretRotationTimeFrameRiskModel = riskModel as Contract.GcpSecretManagerSecretRotationTimeFrameRiskModel;
    const risk = riskModel.risk as Contract.GcpSecretManagerSecretRotationTimeFrameRisk;
    const secretModel = entityModelStore.useGet(secretManagerSecretRotationTimeFrameRiskModel.risk.entityId) as Contract.GcpSecretManagerSecretModel;
    const secret = secretModel.entity as Contract.GcpSecretManagerSecret;

    const getGcpSecretManagerSecretRiskContext = useGetGcpSecretManagerSecretRiskContext();
    const getRiskPolicyConfigurationRotationTimeFrameContextItem = useGetRiskPolicyConfigurationRotationTimeFrameContextItem();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.useGcpSecretManagerSecretRotationTimeFrameRiskDefinition",
            () => ({
                description: {
                    rotationTimeFrameExists: {
                        false: "{{secret}} rotation period is not enabled",
                        true: "{{secret}} was not rotated for more than {{rotationTimeFrame | TimeSpanFormatter.humanizeDays}}"
                    }
                },
                sections: {
                    resolution: {
                        step1: "Check **Set rotation period**",
                        step2: "Set the rotation period to less than {{secretRotationTimeFrame | TimeSpanFormatter.humanizeDays}} and select a start date",
                        step3: "Select a Pub/Sub topic to receive the secret rotation notification",
                        step4: {
                            link: "Create and configure",
                            text: "{{pubSubDocumentationLink}} a Cloud Run service to receive rotation notifications and execute rotation steps"
                        },
                        step5: "Click **UPDATE SECRET** to confirm"
                    }
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        (_.isNil(secret.rotationTimeFrame)
            ? localization.description.rotationTimeFrameExists.false
            : localization.description.rotationTimeFrameExists.true)({
            rotationTimeFrame: risk.secretRotationTimeFrame,
            secret:
                <Entity
                    entityIdOrModel={secretModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={secretManagerSecretRotationTimeFrameRiskModel.risk.entityId}
                key={secretManagerSecretRotationTimeFrameRiskModel.risk.entityId}
                page={Contract.GcpConsolePage.Edit}/>,
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2({ secretRotationTimeFrame: secretManagerSecretRotationTimeFrameRiskModel.risk.secretRotationTimeFrame }),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4.text({
                pubSubDocumentationLink:
                    <Link
                        urlOrGetUrl={secretManagerSecretRotationTimeFrameRiskModel.pubSubDocumentationUrl}
                        variant="external">
                        {localization.sections.resolution.step4.link()}
                    </Link>
            }),
            localization.sections.resolution.step5()
        ],
        secretManagerSecretRotationTimeFrameRiskModel,
        () => {
            const secretRiskContext = getGcpSecretManagerSecretRiskContext(secretModel);
            return [
                secretRiskContext.generalInformation,
                getRiskPolicyConfigurationRotationTimeFrameContextItem(risk.secretRotationTimeFrame),
                secretRiskContext.rotationTimeFrame,
                secretRiskContext.sensitive,
                secretRiskContext.enabledVersion,
                secretRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        });
}