import { Action0, useEvent, useExecuteOperation } from "@infrastructure";
import { useMemo } from "react";
import { CodeController, RiskController } from "../../../../../common";
import { CodeControllerGetResourcesRequest, RiskControllerGetRiskExcludedEntityIdsRequest, RiskControllerGetRiskModelRequest } from "../../../../../common/controllers/types.generated";

export function useItem(riskId: string) {
    const [registerAuditEventsChange, triggerAuditEventsChange] = useEvent<Action0>();
    const [[codeResourceDatas, excludedEntityIds, excludedRiskItemEntityIds, riskModel], executeGetRiskModel] =
        useExecuteOperation(
            [useItem, riskId],
            async () => {
                const [{ excludedEntityIds, excludedRiskItemEntityIds }, { riskModel }] =
                    await Promise.all([
                        RiskController.getRiskExcludedEntityIds(new RiskControllerGetRiskExcludedEntityIdsRequest(riskId)),
                        RiskController.getRiskModel(new RiskControllerGetRiskModelRequest(riskId))
                    ]);

                const codeResourceDatas = (await CodeController.getResources(new CodeControllerGetResourcesRequest(riskModel.risk.riskedEntityIds))).datas;

                return [codeResourceDatas, excludedEntityIds, excludedRiskItemEntityIds, riskModel];
            });

    return useMemo(
        () => ({
            codeResourceDatas,
            excludedEntityIds,
            excludedRiskItemEntityIds,
            executeGetRiskModel,
            registerAuditEventsChange,
            riskModel,
            triggerAuditEventsChange
        }),
        [codeResourceDatas, excludedEntityIds, excludedRiskItemEntityIds, executeGetRiskModel, riskModel]);
}