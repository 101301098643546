import { useQueryParameters } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract } from "../../../../../common";
import { Table } from "../../../../Customer/components/Entities/components";

type EntityTypeInventoryReportProps = {
    reportDefinitionConfiguration: Contract.InventoryReportDefinitionConfiguration;
};

type EntityTypeInventoryReportQueryParameters = {
    filtersTime: string;
};

export function EntityTypeInventoryReport({ reportDefinitionConfiguration }: EntityTypeInventoryReportProps) {
    const { filtersTime } = useQueryParameters<EntityTypeInventoryReportQueryParameters>();

    return (
        <Table
            {...(reportDefinitionConfiguration.data
                ? {
                    tenantTypes: [
                        Contract.TenantType.Aws,
                        Contract.TenantType.Azure,
                        Contract.TenantType.Gcp],
                    typeName: Contract.TypeNames.IDataAnalysisResource
                }
                : { entityTypeEntitiesViewName: reportDefinitionConfiguration.entityTypeName! })}
            filtersOptions={{
                initialMap:
                    !_.isNil(reportDefinitionConfiguration.filterMap)
                        ? JSON.parse(reportDefinitionConfiguration.filterMap)
                        : {},
                time: filtersTime
            }}
            key={reportDefinitionConfiguration.id}
            variant="exportButtonOnly"/>);
}