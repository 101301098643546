﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, InlineEntities } from "../../../../../../../../../../../../../../common";

export function useRemediationSteps(riskModel: Contract.AwsInboundExternalEc2InstanceRiskModel) {
    const securityGroupIds =
        _(riskModel.risk.instanceIdToDataMap).
            flatMap(instanceData => instanceData.publicAccess.securityGroupIds).
            uniq().
            value();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsInboundExternalEc2InstanceRiskDefinition.hooks.useRemediationSteps",
            () => ({
                resolution: {
                    step1: "Before changing any security group inbound rule, verify that it will not break existing communications",
                    step2: "Repeat the following steps for each of these {{securityGroups}}",
                    step3: "Open to the security group and click the **Inbound rules** tab, then click **Edit inbound rules**",
                    step4: "For each public inbound rule, update the **Source** field to limit access to specific IP addresses or click **Delete**",
                    step5: "Click **Save rules** to confirm"
                }
            }));
    return _.isEmpty(securityGroupIds)
        ? []
        : [
            localization.resolution.step1(),
            localization.resolution.step2({
                securityGroups:
                    <InlineEntities
                        entityIdsOrModels={securityGroupIds}
                        entityTypeName={Contract.TypeNames.AwsEc2SecurityGroup}
                        variant="itemCountAndType"/>
            }),
            localization.resolution.step3(),
            localization.resolution.step4(),
            localization.resolution.step5()];
}