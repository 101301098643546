import { Contract } from "../../../../../controllers";
import { EntityDefinition } from "../useDefinition";

export function useAwsServiceDefinition(entityModel: Contract.EntityModel) {
    return new EntityDefinition(
        entityModel,
        {
            glanceOptions: {
                enabled: false
            },
            iconVariant: (entityModel.entity as Contract.AwsService).shortName
        });
}