import { IconText, Link, Loading, Tooltip, useLocalization } from "@infrastructure";
import { Box } from "@mui/material";
import React from "react";
import { CustomerConsoleAppUrlHelper, MaliciousFile, MaliciousFilesIcon, useTheme } from "..";

type InlineMaliciousFileProps = {
    contentSha256String: string;
};

export function InlineMaliciousFile({ contentSha256String }: InlineMaliciousFileProps) {
    const localization =
        useLocalization(
            "common.inlineMaliciousFile",
            () => ({
                contentSha256ShortenedString: "{{contentSha256StringStart}}...{{contentSha256StringEnd}}"
            }));
    const theme = useTheme();
    return (
        <Tooltip
            enterDelay={1000}
            enterNextDelay={1000}
            leaveDelay={250}
            placement="bottom-start"
            slotProps={{
                tooltip: {
                    sx: {
                        margin: theme.spacing(0),
                        padding: theme.spacing(0),
                        spacing: theme.spacing(0)
                    }
                }
            }}
            titleOrGetTitle={
                <Loading container="popup">
                    <MaliciousFile contentSha256String={contentSha256String}/>
                </Loading>}>
            <Box>
                <Link
                    urlOrGetUrl={CustomerConsoleAppUrlHelper.getMaliciousFileHashUrl(contentSha256String)}
                    variant="text">
                    <IconText
                        icon={
                            <MaliciousFilesIcon
                                sx={{
                                    color: theme.palette.text.primary,
                                    fontSize: "18px"
                                }}/>}
                        text={
                            localization.contentSha256ShortenedString({
                                contentSha256StringEnd: contentSha256String.slice(-4),
                                contentSha256StringStart: contentSha256String.slice(0, 4)
                            })}
                        textSx={{
                            textOverflow: "ellipsis",
                            textWrap: "nowrap"
                        }}/>
                </Link>
            </Box>
        </Tooltip>);
}