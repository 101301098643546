import React from "react";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { Contract } from "../../../../../../../../../../common";
import { Info } from "../../../../components";
import { useOktaCommonDirectoryEntityInfoItemElements } from "./useOktaCommonDirectoryEntityInfoItemElements";

export function useOktaDefaultDirectoryEntityDefinition(directoryEntityModel: Contract.OktaDirectoryEntityModel, options?: DefinitionOptions) {
    const defaultResourceInfoItemElements = useOktaCommonDirectoryEntityInfoItemElements(directoryEntityModel);

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={[]}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[]}
                options={options?.infoOptions}/>,
        sensitive: false
    });
}