import { map } from "@infrastructure";
import { Contract, tenantModelStore } from "../../../../../../../../../../../../../../../common";
import { GcpConsoleUrlBuilder } from "../../../../../../../../../../../../../../../tenants";
import { EntityExternalConsoleLinkDefinition } from "../useDefinition";

export function useGcpDnsManagedZoneDefinition(entityModel: Contract.EntityModel, page: Contract.GcpConsolePage) {
    const managedZone = entityModel.entity as Contract.GcpDnsManagedZone;
    const tenantModel = tenantModelStore.useGet(managedZone.tenantId);

    const getUrl =
        map(
            page,
            {
                [Contract.GcpConsolePage.Permissions]: () => undefined,
                [Contract.GcpConsolePage.Resource]: () => GcpConsoleUrlBuilder.GetDnsManagedZoneUrl
            });

    return new EntityExternalConsoleLinkDefinition(
        getUrl?.(
            managedZone.name,
            (tenantModel.configuration as Contract.GcpTenantConfiguration).rawShortNameId));
}