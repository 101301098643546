import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, EmptyMessageText, InlineTexts, optionalTableCell, StringHelper, TextValuesFilter, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, EntityFilter, EntityModelHelper, entityModelStore, InfoCard, InlineEntities, ItemTable, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { useAwsRoute53HostedZoneRecordTypeTranslator } from "../../../../../../../../hooks";

type RecordsInfoCardProps = {
    hostedZoneModel: Contract.AwsRoute53HostedZoneModel;
};

export function RecordsInfoCard({ hostedZoneModel }: RecordsInfoCardProps) {
    const dnsRecordEntityModels = entityModelStore.useGet(_.flatMap(hostedZoneModel.recordIdToDnsRecordEntityIdsMap));
    const recordIdToDnsRecordEntityModelsMap =
        useMemo(
            () => {
                const dnsRecordResourceModelMap =
                    _.keyBy(
                        dnsRecordEntityModels,
                        dnsRecordEntityModel => dnsRecordEntityModel.id);
                return _.mapValues(
                    hostedZoneModel.recordIdToDnsRecordEntityIdsMap,
                    dnsRecordEntityIds =>
                        _.map(
                            dnsRecordEntityIds,
                            dnsRecordEntityId => dnsRecordResourceModelMap[dnsRecordEntityId]));
            },
            []);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const hostedZoneRecordTypeTranslator = useAwsRoute53HostedZoneRecordTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsRoute53HostedZoneDefinition.recordsInfoCard",
            () => ({
                columns: {
                    entities: "Resources",
                    name: "Name",
                    routingPolicy: {
                        title: "Routing Policy",
                        [Contract.TypeNames.AwsRoute53HostedZoneRecordRoutingPolicy]: {
                            [Contract.AwsRoute53HostedZoneRecordRoutingPolicy.Failover]: "Failover",
                            [Contract.AwsRoute53HostedZoneRecordRoutingPolicy.Geolocation]: "Geolocation",
                            [Contract.AwsRoute53HostedZoneRecordRoutingPolicy.Latency]: "Latency",
                            [Contract.AwsRoute53HostedZoneRecordRoutingPolicy.MultivalueAnswer]: "Multivalue Answer",
                            [Contract.AwsRoute53HostedZoneRecordRoutingPolicy.Simple]: "Simple",
                            [Contract.AwsRoute53HostedZoneRecordRoutingPolicy.Weighted]: "Weighted"
                        }
                    },
                    type: "Type",
                    values: "Value"
                },
                empty: "No Records",
                title: "Records"
            }));

    return (
        <InfoCard title={localization.title()}>
            <ItemTable
                columnIdToGetItemValueMap={{
                    [RecordsInfoCardTableColumnId.Entities]: {
                        getFilterValue: item => hostedZoneModel.recordIdToDnsRecordEntityIdsMap[item.id],
                        getSortValue:
                            item =>
                                StringHelper.getCombineSortValue(
                                    _.size(recordIdToDnsRecordEntityModelsMap[item.id]),
                                    ..._.map(
                                        recordIdToDnsRecordEntityModelsMap[item.id],
                                        entityModel => entityModel.entity.displayName))
                    },
                    [RecordsInfoCardTableColumnId.Name]: item => item.name,
                    [RecordsInfoCardTableColumnId.RoutingPolicy]: {
                        getFilterValue: item => item.routingPolicy,
                        getSortValue: item => localization.columns.routingPolicy[Contract.TypeNames.AwsRoute53HostedZoneRecordRoutingPolicy][item.routingPolicy]()
                    },
                    [RecordsInfoCardTableColumnId.Type]: item => item.type,
                    [RecordsInfoCardTableColumnId.Values]: {
                        getFilterValue: item => item.values,
                        getSortValue: item => StringHelper.getCombineSortValue(_.size(item.values), ...item.values)
                    }
                }}
                csvExportFilePrefixes={
                    [
                        entityTypeNameTranslator((hostedZoneModel.entity as Contract.AwsRoute53HostedZone).typeName, { includeServiceName: false }),
                        (hostedZoneModel.entity as Contract.AwsRoute53HostedZone).displayName,
                        localization.title()
                    ]}
                defaultSortColumnIdOrIds={RecordsInfoCardTableColumnId.Name}
                emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty()) }}
                getCsvItem={
                    item => ({
                        /* eslint-disable sort-keys-fix/sort-keys-fix */
                        "Name": item.name,
                        "Type": hostedZoneRecordTypeTranslator(item.type),
                        "Value": _.join(item.values, "\n"),
                        "Resources":
                            _(recordIdToDnsRecordEntityModelsMap[item.id]).
                                map(dnsRecordEntityModel => dnsRecordEntityModel.entity.displayReference).
                                join("\n"),
                        "Routing Policy": localization.columns.routingPolicy[Contract.TypeNames.AwsRoute53HostedZoneRecordRoutingPolicy][item.routingPolicy]()
                        /* eslint-enable sort-keys-fix/sort-keys-fix */
                    })}
                getItemId={item => item.id}
                items={(hostedZoneModel.entity as Contract.AwsRoute53HostedZone).records}>
                {columnIdToItemValuesMap => [
                    <DataTableColumn
                        cellMaxWidth="medium"
                        filterOptions={{
                            itemOrItems: {
                                element:
                                    <TextValuesFilter
                                        placeholder={localization.columns.name()}
                                        values={columnIdToItemValuesMap[RecordsInfoCardTableColumnId.Name]}/>
                            }
                        }}
                        id={RecordsInfoCardTableColumnId.Name}
                        itemProperty={(record: Contract.AwsRoute53HostedZoneRecord) => record.name}
                        key={RecordsInfoCardTableColumnId.Name}
                        title={localization.columns.name()}/>,
                    <DataTableColumn
                        filterOptions={{
                            itemOrItems: {
                                element:
                                    <ValuesFilter placeholder={localization.columns.type()}>
                                        {_.map(
                                            columnIdToItemValuesMap[RecordsInfoCardTableColumnId.Type],
                                            type =>
                                                <ValuesFilterItem
                                                    key={type}
                                                    title={hostedZoneRecordTypeTranslator(type)}
                                                    value={type}/>)}
                                    </ValuesFilter>
                            }
                        }}
                        id={RecordsInfoCardTableColumnId.Type}
                        itemProperty={(record: Contract.AwsRoute53HostedZoneRecord) => hostedZoneRecordTypeTranslator(record.type)}
                        key={RecordsInfoCardTableColumnId.Type}
                        title={localization.columns.type()}/>,
                    <DataTableColumn
                        cellMaxWidth="medium"
                        filterOptions={{
                            itemOrItems: {
                                element:
                                    <TextValuesFilter
                                        placeholder={localization.columns.values()}
                                        values={columnIdToItemValuesMap[RecordsInfoCardTableColumnId.Values]}/>
                            }
                        }}
                        id={RecordsInfoCardTableColumnId.Values}
                        key={RecordsInfoCardTableColumnId.Values}
                        render={
                            ({ item }: DataTableColumnRenderProps<Contract.AwsRoute53HostedZoneRecord>) =>
                                <InlineTexts
                                    texts={item.values}
                                    variant="itemPlusItemCount"/>}
                        sortOptions={{ type: DataTableSortType.Numeric }}
                        title={localization.columns.values()}/>,
                    <DataTableColumn
                        filterOptions={{
                            itemOrItems: {
                                element:
                                    <EntityFilter
                                        entityIdsOrSearchableReferences={columnIdToItemValuesMap[RecordsInfoCardTableColumnId.Entities]}
                                        placeholder={localization.columns.entities()}/>
                            }
                        }}
                        id={RecordsInfoCardTableColumnId.Entities}
                        key={RecordsInfoCardTableColumnId.Entities}
                        render={
                            optionalTableCell<Contract.AwsRoute53HostedZoneRecord>(
                                item =>
                                    _.isEmpty(recordIdToDnsRecordEntityModelsMap[item.id])
                                        ? undefined
                                        : <InlineEntities
                                            entityIdsOrModels={recordIdToDnsRecordEntityModelsMap[item.id]}
                                            entityTypeName={EntityModelHelper.getCommonTypeName(recordIdToDnsRecordEntityModelsMap[item.id]) ?? Contract.TypeNames.Entity}
                                            variant="itemPlusItemCount"/>)}
                        title={localization.columns.entities()}/>,
                    <DataTableColumn
                        filterOptions={{
                            itemOrItems: {
                                element:
                                    <ValuesFilter placeholder={localization.columns.routingPolicy.title()}>
                                        {_.map(
                                            columnIdToItemValuesMap[RecordsInfoCardTableColumnId.RoutingPolicy],
                                            (routingPolicy: Contract.AwsRoute53HostedZoneRecordRoutingPolicy) =>
                                                <ValuesFilterItem
                                                    key={routingPolicy}
                                                    title={localization.columns.routingPolicy[Contract.TypeNames.AwsRoute53HostedZoneRecordRoutingPolicy][routingPolicy]()}
                                                    value={routingPolicy}/>)}
                                    </ValuesFilter>
                            }
                        }}
                        id={RecordsInfoCardTableColumnId.RoutingPolicy}
                        itemProperty={(record: Contract.AwsRoute53HostedZoneRecord) => localization.columns.routingPolicy[Contract.TypeNames.AwsRoute53HostedZoneRecordRoutingPolicy][record.routingPolicy]()}
                        key={RecordsInfoCardTableColumnId.RoutingPolicy}
                        title={localization.columns.routingPolicy.title()}/>
                ]}
            </ItemTable>
        </InfoCard>);
}

enum RecordsInfoCardTableColumnId {
    Entities = "entities",
    Name = "name",
    RoutingPolicy = "routingPolicy",
    Type = "type",
    Values = "value"
}