import _ from "lodash";
import { Contract, VulnerabilityEventType } from "../../../../../../../../../common";

export type VulnerabilityEventData = {
    dayTime?: number;
    eventType: VulnerabilityEventType;
    score?: number;
    time?: number;
    url?: string;
};

export function getEvents(vulnerability?: Contract.Vulnerability, includeDiscovery = false): VulnerabilityEventData[] {
    if (_.isNil(vulnerability)) {
        return [];
    }

    return _<VulnerabilityEventData>([]).
        concatIf(
            includeDiscovery,
            _<VulnerabilityEventData>([]).
                concatIf(
                    !_.isNil(vulnerability.timeline.discoveryDate),
                    () => ({
                        eventType: VulnerabilityEventType.DiscoveryDate,
                        score: undefined,
                        time: new Date(vulnerability.timeline.discoveryDate!).getTime()
                    })).
                concatIf(
                    !_.isNil(vulnerability.timeline.tenableCoverageDate),
                    () => ({
                        eventType: VulnerabilityEventType.FirstTenableCoverage,
                        score: undefined,
                        time: new Date(vulnerability.timeline.tenableCoverageDate!).getTime()
                    })).
                concatIf(
                    !_.isNil(vulnerability.timeline.creationDate),
                    () => ({
                        eventType: VulnerabilityEventType.NVDPublished,
                        score: undefined,
                        time: new Date(vulnerability.timeline.creationDate!).getTime()
                    })).
                value()).
        concatIf(
            !_.isNil(vulnerability.timeline.functionalExploitDate),
            () => ({
                dayTime: (new Date(vulnerability.timeline.functionalExploitDate!).setHours(0, 0, 0, 0)),
                eventType: VulnerabilityEventType.FunctionalExploit,
                score: undefined,
                time: new Date(vulnerability.timeline.functionalExploitDate!).getTime()
            })).
        concatIf(
            !_.isNil(vulnerability.timeline.proofOfConceptDate),
            () => ({
                dayTime: (new Date(vulnerability.timeline.proofOfConceptDate!).setHours(0, 0, 0, 0)),
                eventType: VulnerabilityEventType.FirstProofOfConcept,
                score: undefined,
                time: new Date(vulnerability.timeline.proofOfConceptDate!).getTime()
            })).
        concatIf(
            !_.isNil(vulnerability.cisaKevData?.additionDate),
            () => ({
                dayTime: (new Date(vulnerability.cisaKevData!.additionDate).setHours(0, 0, 0, 0)),
                eventType: VulnerabilityEventType.CISAKnownExploit,
                score: undefined,
                time: new Date(vulnerability.cisaKevData!.additionDate).getTime()
            })).
        concatIf(
            !_.isNil(vulnerability.cisaKevData?.dueDate),
            () => ({
                dayTime: (new Date(vulnerability.cisaKevData!.dueDate).setHours(0, 0, 0, 0)),
                eventType: VulnerabilityEventType.CISADueDate,
                score: undefined,
                time: new Date(vulnerability.cisaKevData!.dueDate).getTime()
            })).
        concat(getReferenceEvents(vulnerability.references)).
        value();
}

function getEventTypes(referenceTags: Contract.VulnerabilityReferenceTag[]) {
    const hasEmergingThreatIntersectionTags =
        _(emergingThreatIndicators).
            intersection(referenceTags).
            size();
    const hasExploitedInTheWildTags =
        _(exploitedInTheWildIndicators).
            intersection(referenceTags).
            size();
    const hasRansomwareTags =
        _(ransomwareIndicators).
            intersection(referenceTags).
            size();
    const hasMalwareTags =
        _(malwareIndicators).
            intersection(referenceTags).
            size();

    const eventTypes: VulnerabilityEventType[] = [];
    if (hasEmergingThreatIntersectionTags) {
        eventTypes.push(VulnerabilityEventType.EmergingThreat);
    }

    if (hasExploitedInTheWildTags) {
        eventTypes.push(VulnerabilityEventType.ExploitedInTheWild);
    }

    if (hasRansomwareTags) {
        eventTypes.push(VulnerabilityEventType.Ransomware);
    }

    if (hasMalwareTags) {
        eventTypes.push(VulnerabilityEventType.Malware);
    }

    if (_(referenceTags).size() && !hasEmergingThreatIntersectionTags && !hasExploitedInTheWildTags && !hasRansomwareTags && !hasMalwareTags) {
        eventTypes.push(VulnerabilityEventType.Mention);
    }

    return eventTypes;
}

function getReferenceEvents(referenceArray: Contract.VulnerabilityReference[]) {
    const eventDatas: VulnerabilityEventData[] = [];
    for (const reference of referenceArray) {
        const { publicationDate, url } = reference;
        if (!_.isNil(publicationDate)) {
            const eventTypes =
                getEventTypes(
                    _.map(
                        reference.tagDatas,
                        tagData => tagData.tag));
            _.forEach(
                eventTypes,
                eventType => {
                    eventDatas.push({
                        dayTime:
                            publicationDate
                                ? (new Date(publicationDate)).setHours(0, 0, 0, 0)
                                : undefined,
                        eventType,
                        time:
                            publicationDate
                                ? new Date(publicationDate).getTime()
                                : undefined,
                        url
                    });
                });
        }
    }

    return eventDatas;
}

const emergingThreatIndicators: Contract.VulnerabilityReferenceTag[] = [
    Contract.VulnerabilityReferenceTag.VulnerabilityOfConcern,
    Contract.VulnerabilityReferenceTag.VulnerabilityOfInterest,
    Contract.VulnerabilityReferenceTag.VulnerabilityBeingMonitored
];

const exploitedInTheWildIndicators: Contract.VulnerabilityReferenceTag[] = [
    Contract.VulnerabilityReferenceTag.ExploitedInTheWild
];

const ransomwareIndicators: Contract.VulnerabilityReferenceTag[] = [
    Contract.VulnerabilityReferenceTag.WeaponizationRansomware
];

const malwareIndicators: Contract.VulnerabilityReferenceTag[] = [
    Contract.VulnerabilityReferenceTag.WeaponizationMalware
];