import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { Contract, InfoItem } from "../../../../../../../../../../common";
import { useAzureNetworkPrivateEndpointConnectionStatusTranslator } from "../../../../../../hooks/useAzureNetworkPrivateEndpointConnectionStatusTranslator";
import { EntitiesInfoItem, Info } from "../../../../components";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";
import { useAzureDefaultResourceInfoItemElements } from "./useAzureDefaultResourceInfoItemElements";

export function useAzureNetworkPrivateEndpointDefinition(resourceGroupResourceModel: Contract.AzureResourceGroupResourceModel, options?: DefinitionOptions) {
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceGroupResourceModel);
    const defaultResourceInfoItemElements = useAzureDefaultResourceInfoItemElements(resourceGroupResourceModel);
    const privateEndpointModel = resourceGroupResourceModel as Contract.AzureNetworkPrivateEndpointModel;
    const privateEndpoint = resourceGroupResourceModel.entity as Contract.AzureNetworkPrivateEndpoint;

    const privateEndpointConnectionStatusTranslator = useAzureNetworkPrivateEndpointConnectionStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.azure.useAzureNetworkPrivateEndpointDefinition",
            () => ({
                info: {
                    items: {
                        attachedResourceId: "Attached To",
                        connectionDescription: "Connection Description",
                        connectionStatus: "Connection State"
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="connectionStatus"
                        title={localization.info.items.connectionStatus()}
                        value={privateEndpointConnectionStatusTranslator(privateEndpoint.connectionStatus)}/>,
                    <InfoItem
                        key="connectionDescription"
                        title={localization.info.items.connectionDescription()}
                        value={
                            _.isEmpty(privateEndpoint.connectionDescription)
                                ? undefined
                                : privateEndpoint.connectionDescription
                        }/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={privateEndpointModel.attachedResourceIdReference}
                        entityTypeName={Contract.TypeNames.AzureResource}
                        key="attachedResourceId"
                        title={localization.info.items.attachedResourceId()}/>
                ]}
                options={options?.infoOptions}>
            </Info>
    });
}