import { useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { Container } from ".";
import { automaticCustomEntityAttributeDefinitionTemplateModelStore, Contract, customEntityAttributeDefinitionModelStore, ResourceTagIcon, ResourceTagPatternHelper, useEntityTypeNameTranslator, useTheme } from "../../../../..";
import { EntityAttributeProps } from "..";

export function Automatic({ entityAttribute, entityCount, entityTypeName }: EntityAttributeProps) {
    const automaticCustomEntityAttribute = entityAttribute as Contract.AutomaticCustomEntityAttribute;
    const customEntityAttributeDefinitionModels = customEntityAttributeDefinitionModelStore.useGetAll();
    const automaticCustomEntityAttributeDefinitionTemplateModels = automaticCustomEntityAttributeDefinitionTemplateModelStore.useGetAll();
    const [automaticCustomEntityAttributeDefinitionTemplateModel, customEntityAttributeDefinitionModel] =
        useMemo(
            () => {
                const customEntityAttributeDefinitionModel =
                    _.find(
                        customEntityAttributeDefinitionModels,
                        customEntityAttributeDefinitionModel => customEntityAttributeDefinitionModel.configuration.id === automaticCustomEntityAttribute.definitionId)!;

                const automaticCustomEntityAttributeDefinitionTemplateModel =
                    _.find(
                        automaticCustomEntityAttributeDefinitionTemplateModels,
                        automaticCustomEntityAttributeDefinitionTemplateModel => automaticCustomEntityAttributeDefinitionTemplateModel.configuration.id == (customEntityAttributeDefinitionModel.configuration as Contract.AutomaticCustomEntityAttributeDefinitionConfiguration).templateId)!;

                return [automaticCustomEntityAttributeDefinitionTemplateModel, customEntityAttributeDefinitionModel];
            },
            []);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "common.entityAttributes.entityAttribute.automatic",
            () => ({
                tooltip: {
                    notSelf: {
                        [Contract.TypeNames.AwsIamRole]: {
                            many: "Some {{translatedEntityTypeName}} are linked to compute resources with tag **{{automaticCustomEntityAttributeDefinitionConfigurationName}}**",
                            single: "This {{translatedEntityTypeName}} is linked to compute resources with tag **{{automaticCustomEntityAttributeDefinitionConfigurationName}}**"
                        }
                    },
                    pattern:
                        [
                            "Applied to resources with tag pattern\n{{resourceTagPatterns}}",
                            "Applied to resources with tag patterns\n{{resourceTagPatterns}}"
                        ],
                    self: "Applied to resources with tag **{{automaticCustomEntityAttributeDefinitionConfigurationName}}**"
                }
            }));

    const tooltip =
        useMemo(
            () => {
                if (automaticCustomEntityAttributeDefinitionTemplateModel.configuration.type === Contract.AutomaticCustomEntityAttributeDefinitionTemplateConfigurationType.ResourceTagPattern) {
                    return (
                        <Typography sx={{ whiteSpace: "pre-wrap" }}>
                            {localization.tooltip.pattern(
                                automaticCustomEntityAttributeDefinitionTemplateModel.configuration.resourceTagPatterns.length,
                                {
                                    resourceTagPatterns:
                                        _(automaticCustomEntityAttributeDefinitionTemplateModel.configuration.resourceTagPatterns).
                                            map(resourceTagPattern => ResourceTagPatternHelper.getDisplayName(resourceTagPattern)).
                                            join(", ")
                                })}
                        </Typography>);
                }

                if (!automaticCustomEntityAttribute.self) {
                    const entityPluralization =
                        entityCount === 1
                            ? "single"
                            : "many";
                    const translatedEntityTypeName =
                        entityTypeNameTranslator(
                            entityTypeName,
                            {
                                count: entityCount,
                                variant: "text"
                            });
                    switch (entityTypeName) {
                        case Contract.TypeNames.AwsIamRole:
                            return localization.tooltip.notSelf[Contract.TypeNames.AwsIamRole][entityPluralization]({
                                automaticCustomEntityAttributeDefinitionConfigurationName: customEntityAttributeDefinitionModel.configuration.name,
                                translatedEntityTypeName
                            });
                    }
                }

                return localization.tooltip.self({ automaticCustomEntityAttributeDefinitionConfigurationName: customEntityAttributeDefinitionModel.configuration.name });
            },
            []);

    const theme = useTheme();
    return (
        <Container
            color={theme.palette.entityAttribute.custom[customEntityAttributeDefinitionModel.configuration.colorIndex]}
            iconOptions={{
                icon: <ResourceTagIcon sx={{ fontSize: "14px" }}/>,
                orientation: "right"
            }}
            name={customEntityAttributeDefinitionModel.configuration.name}
            tooltip={tooltip}
            variant="automatic"/>);
}