import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useGcpIamWorkloadIdentityPoolProviderTypeTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useGcpIamWorkloadIdentityPoolProviderTypeTranslator",
            () => ({
                [Contract.TypeNames.GcpIamWorkloadIdentityPoolProviderType]: {
                    [Contract.GcpIamWorkloadIdentityPoolProviderType.Aws]: "AWS",
                    [Contract.GcpIamWorkloadIdentityPoolProviderType.Oidc]: "OIDC",
                    [Contract.GcpIamWorkloadIdentityPoolProviderType.Saml]: "SAML"
                }
            }));
    return (type: Contract.GcpIamWorkloadIdentityPoolProviderType) =>
        localization[Contract.TypeNames.GcpIamWorkloadIdentityPoolProviderType][type]();
}