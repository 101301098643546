import { AsyncFunctionPromiseResult, clearOperation, defined, executeOperation, FunctionParameters } from "@infrastructure";
import _, { Dictionary } from "lodash";
import { Dispatch, useEffect, useMemo, useState } from "react";

export class OperationStore<TOperation extends (...params: any[]) => Promise<any>> {
    private operationKeyToPromiseMap: Dictionary<Promise<void>> = {};
    private operationKeyToSetRefreshesMap: Dictionary<Dispatch<any>[]> = {};
    private operationKeyToResultMap: Dictionary<AsyncFunctionPromiseResult<TOperation>> = {};

    constructor(private operation: TOperation) {
    }

    public get =
        async (...operationParameters: FunctionParameters<TOperation>): Promise<AsyncFunctionPromiseResult<TOperation>> => {
            const operationKey = this.getOperationKey(operationParameters);
            if (_.isNil(this.operationKeyToResultMap[operationKey])) {
                await this.fetchData(operationParameters);
            }

            return this.operationKeyToResultMap[operationKey];
        };

    public notify =
        async (...operationParameters: FunctionParameters<TOperation>): Promise<void> => {
            const operationKey = this.getOperationKey(operationParameters);
            this.operationKeyToResultMap[operationKey] = await this.operation(...operationParameters);
            this.refresh(operationKey);
        };

    public notifyAll =
        async (operationParametersList?: FunctionParameters<TOperation>[]) => {
            this.operationKeyToResultMap = {};
            if (!_.isNil(operationParametersList)) {
                await Promise.all(
                    _.map(
                        operationParametersList,
                        async operationParameters => await this.fetchData(operationParameters)));
            }

            this.refresh();
        };

    public useGet =
        (...operationParameters: FunctionParameters<TOperation>): AsyncFunctionPromiseResult<TOperation> => {
            const operationKey = this.getOperationKey(operationParameters);
            if (_.isNil(this.operationKeyToResultMap[operationKey])) {
                const executeOperationKey = [defined(this.fetchData), operationKey];
                executeOperation(
                    executeOperationKey,
                    async () => {
                        await this.fetchData(operationParameters);
                        clearOperation(executeOperationKey);
                    });
            }

            const refresh = this.useRefresh(operationKey);
            return useMemo(
                () => this.operationKeyToResultMap[operationKey],
                [operationKey, refresh]);
        };

    private fetchData =
        async (operationParameters: FunctionParameters<TOperation>) => {
            const operationKey = this.getOperationKey(operationParameters);
            if (_.isNil(this.operationKeyToPromiseMap[operationKey])) {
                const fetchOperationResult =
                    async () => {
                        this.operationKeyToResultMap[operationKey] = await this.operation(...operationParameters);
                        delete this.operationKeyToPromiseMap[operationKey];
                    };
                this.operationKeyToPromiseMap[operationKey] = fetchOperationResult();
            }

            await this.operationKeyToPromiseMap[operationKey];
            this.refresh(operationKey);
        };

    private getOperationKey(operationParameters: FunctionParameters<TOperation>) {
        return JSON.stringify(operationParameters);
    }

    private refresh =
        (operationKey?: string) =>
            _.each(
                _.isNil(operationKey)
                    ? _.flatMap(this.operationKeyToSetRefreshesMap)
                    : this.operationKeyToSetRefreshesMap[operationKey] ?? [],
                setRefresh => setRefresh({}));

    private useRefresh =
        (operationKey: string) => {
            const [refresh, setRefresh] = useState({});
            useEffect(
                () => {
                    if (_.isNil(this.operationKeyToSetRefreshesMap[operationKey])) {
                        this.operationKeyToSetRefreshesMap[operationKey] = [];
                    }
                    this.operationKeyToSetRefreshesMap[operationKey].push(setRefresh);

                    return () => {
                        const documentSetRefreshes = this.operationKeyToSetRefreshesMap[operationKey];
                        documentSetRefreshes.splice(documentSetRefreshes.indexOf(setRefresh), 1);
                    };
                },
                []);

            return refresh;
        };
}