import { DataTable, DataTableColumn, DataTableColumnRenderProps, EmptyMessageText, InlineItems, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, EntitiesCell, entityModelStore, InfoCard, Region, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";

type SubnetsInfoCardProps = {
    subnetIds: string[];
};

export function SubnetsInfoCard({ subnetIds }: SubnetsInfoCardProps) {
    const subnetModels = entityModelStore.useGet(subnetIds);
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpComputeVpcDefinition.subnetsInfoCard",
            () => ({
                columns: {
                    flowLogsEnabled: {
                        false: "Off",
                        title: "Flow logs",
                        true: "On"
                    },
                    name: "Name",
                    region: "Region",
                    subnets: "IP address ranges"
                },
                empty: "No {{translatedEntityTypeName}}",
                title: "Subnets"
            }));
    return (
        <InfoCard title={localization.title()}>
            <DataTable
                emptyMessageOptions={{
                    emptyMessageText:
                        new EmptyMessageText(
                            localization.empty({
                                translatedEntityTypeName:
                                    entityTypeNameTranslator(
                                        Contract.TypeNames.GcpComputeSubnet,
                                        { count: 0 })
                            }))
                }}
                fetchItems={
                    () =>
                        _.map(
                            subnetModels,
                            subnetModel => subnetModel.entity)}
                getItemId={(item: Contract.GcpComputeSubnet) => item.id}
                sortOptions={{ enabled: false }}
                variant="card">
                <DataTableColumn
                    id={SubnetColumnId.Name}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.GcpComputeSubnet>) =>
                            <EntitiesCell
                                entityIdsOrModels={item.id}
                                entityTypeName={Contract.TypeNames.GcpComputeSubnet}
                                entityVariant="iconTextTenant"/>}
                    title={localization.columns.name()}/>
                <DataTableColumn
                    id={SubnetColumnId.Region}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.GcpComputeSubnet>) =>
                            <Region
                                regionId={item.regionId}
                                variant="iconText"/>}
                    title={localization.columns.region()}/>
                <DataTableColumn
                    id={SubnetColumnId.Subnets}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.GcpComputeSubnet>) =>
                            <InlineItems
                                items={item.subnets}
                                variant="itemPlusItemCount"/>}
                    title={localization.columns.subnets()}/>
                <DataTableColumn
                    id={SubnetColumnId.FlowLogsEnabled}
                    itemProperty={
                        (item: Contract.GcpComputeSubnet) =>
                            item.flowLogsEnabled
                                ? localization.columns.flowLogsEnabled.true()
                                : localization.columns.flowLogsEnabled.false()}
                    key="disabled"
                    title={localization.columns.flowLogsEnabled.title()}/>
            </DataTable>
        </InfoCard>);
}

enum SubnetColumnId {
    FlowLogsEnabled = "flowLogsEnabled",
    Name = "name",
    Region = "region",
    Subnets = "subnets"
}