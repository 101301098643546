import { useRef } from "react";

export function useSyncContext() {
    const contextRef = useRef<any>();
    return {
        clear() {
            contextRef.current = undefined;
        },
        create() {
            contextRef.current = {};
            return contextRef.current;
        },
        isActive(context: any) {
            return Object.is(context, contextRef.current);
        }
    };
}