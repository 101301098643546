﻿import { UnexpectedError } from "@infrastructure";
import { useMemo } from "react";
import { Contract, TypeHelper } from "../../../../../../../../../../../../../../common";
import { useGetAwsEksClusterRiskContext } from "../../../../../aws";
import { useGetAzureContainerServiceManagedClusterRiskContext } from "../../../../../azure";
import { useGetGcpContainerClusterRiskContext } from "../../../../../gcp";
import { useUnmanagedKubernetesClusterRiskContext } from "../useUnmanagedKubernetesClusterRiskContext";

export function useGetCommonKubernetesClusterRiskContext(clusterModel: Contract.EntityModel) {
    return useMemo(
        () => {
            if (TypeHelper.extendOrImplement(
                clusterModel.entity.typeName,
                Contract.TypeNames.IUnmanagedKubernetesCluster)) {
                return useUnmanagedKubernetesClusterRiskContext;
            }

            switch (clusterModel.entity.typeName) {
                case Contract.TypeNames.AwsEksCluster:
                    return useAwsEksClusterRiskContext;
                case Contract.TypeNames.AzureContainerServiceManagedCluster:
                    return useAzureContainerServiceManagedClusterRiskContext;
                case Contract.TypeNames.GcpContainerCluster:
                    return useGcpContainerClusterRiskContext;
                default:
                    throw new UnexpectedError(clusterModel.typeName);
            }
        },
        []);
}

function useAwsEksClusterRiskContext(entityModel: Contract.EntityModel) {
    const clusterModel = entityModel as Contract.AwsEksClusterModel;
    const clusterRiskContext = useGetAwsEksClusterRiskContext()(clusterModel);

    return {
        generalInformation: clusterRiskContext.generalInformation,
        getOpenRiskedEntityRisksContextItem: clusterRiskContext.getOpenRiskedEntityRisksContextItem,
        platformVersion: clusterRiskContext.platformVersion,
        sensitive: clusterRiskContext.sensitive,
        version: clusterRiskContext.version,
        virtualNetwork: clusterRiskContext.vpc
    };
}

function useAzureContainerServiceManagedClusterRiskContext(entityModel: Contract.EntityModel) {
    const clusterModel = entityModel as Contract.AzureContainerServiceManagedClusterModel;
    const clusterRiskContext = useGetAzureContainerServiceManagedClusterRiskContext()(clusterModel);

    return {
        generalInformation: clusterRiskContext.generalInformation,
        getOpenRiskedEntityRisksContextItem: clusterRiskContext.getOpenRiskedEntityRisksContextItem,
        platformVersion: clusterRiskContext.platformVersion,
        sensitive: clusterRiskContext.sensitive,
        version: clusterRiskContext.version,
        virtualNetwork: clusterRiskContext.virtualNetwork
    };
}

function useGcpContainerClusterRiskContext(entityModel: Contract.EntityModel) {
    const clusterModel = entityModel as Contract.GcpContainerClusterModel;
    const clusterRiskContext = useGetGcpContainerClusterRiskContext()(clusterModel);

    return {
        generalInformation: clusterRiskContext.generalInformation,
        getOpenRiskedEntityRisksContextItem: clusterRiskContext.getOpenRiskedEntityRisksContextItem,
        platformVersion: clusterRiskContext.platformVersion,
        sensitive: clusterRiskContext.sensitive,
        version: clusterRiskContext.version,
        virtualNetwork: clusterRiskContext.vpc
    };
}