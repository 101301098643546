import moment from "moment";
import { useCallback } from "react";
import { useLocalization } from "../hooks";
import { TimeFormatter } from "../utilities";

const dayMilliseconds = 24 * 1000 * 60 * 60;
const yearMilliseconds = dayMilliseconds * 365;

export function useFormatRelativeDays() {
    const localization =
        useLocalization(
            "infrastructure.hooks.useFormatRelativeDays",
            () => ({
                relative: {
                    days: [
                        "1 day ago",
                        "{{count | NumberFormatter.humanize}} days ago"
                    ],
                    today: "Today"
                }
            }));

    return useCallback((timeInput: moment.MomentInput) => {
        const relativeDurationMilliseconds = Date.now().
            valueOf() - moment(timeInput).
            valueOf();
        return relativeDurationMilliseconds < dayMilliseconds
            ? localization.relative.today()
            : relativeDurationMilliseconds < yearMilliseconds
                ? localization.relative.days(Math.floor(relativeDurationMilliseconds / dayMilliseconds))
                : TimeFormatter.monthDayAndYear(timeInput);
    },
    [localization]);
}