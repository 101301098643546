import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useGcpFunctionsFunctionTypeTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useGcpFunctionsFunctionTypeTranslator",
            () => ({
                [Contract.TypeNames.GcpFunctionsFunctionType]: {
                    [Contract.GcpFunctionsFunctionType.Gen1]: "1st gen",
                    [Contract.GcpFunctionsFunctionType.Gen2]: "2nd gen"
                }
            }));
    return (type: Contract.GcpFunctionsFunctionType) =>
        localization[Contract.TypeNames.GcpFunctionsFunctionType][type]();
}