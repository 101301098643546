import { useLocalization } from "@infrastructure";
import _ from "lodash";
import { useMemo } from "react";
import { Contract, InlineEntities, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetOciResourceRiskContext } from "./useGetOciResourceRiskContext";

export function useGetOciFssFileSystemRiskContext() {
    return useMemo(
        () => useOciFssFileSystemRiskContext,
        []);
}

function useOciFssFileSystemRiskContext(fileSystemModel: Contract.OciFssFileSystemModel) {
    const fileSystem = fileSystemModel.entity as Contract.OciFssFileSystem;
    const resourceRiskContext = useGetOciResourceRiskContext()(fileSystemModel);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.oci.hooks.contexts.useGetOciFssFileSystemRiskContext",
            () => ({
                mountTargetExports: "The {{translatedFileSystemTypeName}} is exported via {{mountTargetExports}}",
                size: "The {{translatedFileSystemTypeName}}'s utilization size is {{size | NumberFormatter.storage}}"
            }));
    const translatedFileSystemTypeName =
        entityTypeNameTranslator(
            fileSystem.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });

    return {
        ...resourceRiskContext,
        mountTargetExports:
            _.isEmpty(fileSystemModel.mountTargetExportIds)
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.mountTargetExports({
                        mountTargetExports:
                            <InlineEntities
                                entityIdsOrModels={fileSystemModel.mountTargetExportIds}
                                entityTypeName={Contract.TypeNames.OciFssMountTargetExport}
                                variant="itemCountAndType"/>,
                        translatedFileSystemTypeName
                    })),
        size: new RiskDefinitionContextItem(
            localization.size({
                size: fileSystem.size,
                translatedFileSystemTypeName
            }))
    };
}