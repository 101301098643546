import { AnalyticsEventActionType, ConfirmButton, DownloadIcon, ExportFileNameOptions, ToastHelper, useApplicationContext, useExportCsv, useLocalization, useTrackAnalytics } from "@infrastructure";
import _ from "lodash";
import React, { useState } from "react";

export type CsvExportButtonProps<T> = {
    disabled?: boolean;
    fileNameOptions: ExportFileNameOptions;
    getItemPage?: (limit: number, skip: number) => Promise<T[]>;
    itemCount?: number;
    itemPageSize?: number;
    onClick?: (fileNameOptions: ExportFileNameOptions) => Promise<void> | void;
    showLimitMessage?: boolean;
};

export function CsvExportButton<T>({ disabled = false, fileNameOptions, getItemPage, itemCount, itemPageSize = 10000, onClick, showLimitMessage = true }: CsvExportButtonProps<T>) {
    const limit = 10000;
    const [exportCsvExecuting, setExportCsvExecuting] = useState(false);

    const { viewValue } = useApplicationContext();

    const remoteExport =
        !_.isNil(onClick) &&
        viewValue !== "system";
    const exportCsv =
        useExportCsv(
            fileNameOptions,
            async () => {
                let items: T[] = [];
                while (items.length < limit) {
                    const itemPage =
                        await getItemPage!(
                            Math.min(
                                itemPageSize,
                                limit - items.length),
                            items.length);
                    items = _.concat(items, itemPage);

                    if (itemPage.length < itemPageSize) {
                        break;
                    }
                }

                return items;
            });

    const trackAnalytics = useTrackAnalytics();
    const localization =
        useLocalization(
            "infrastructure.csvExportButton",
            () => ({
                confirm: {
                    okTitle: "Download partial",
                    title: "The requested export contains too many results, narrow down the search or download the first {{limit | NumberFormatter.humanize}} results"
                },
                title: "Export",
                toast: {
                    content: "Your download will start shortly.",
                    title: "Export"
                }
            }));
    return (
        <ConfirmButton
            disableConfirm={
                !showLimitMessage ||
                viewValue === "system" ||
                _.isNil(itemCount) ||
                itemCount <= limit}
            disabled={
                disabled ||
                !_.isNil(itemCount) && itemCount <= 0 ||
                exportCsvExecuting}
            message={localization.confirm.title({ limit })}
            okTitles={[localization.confirm.okTitle()]}
            size="medium"
            tooltip={localization.title()}
            variant="actionOutlined"
            onClick={
                async () => {
                    if (remoteExport) {
                        await onClick(fileNameOptions);
                        ToastHelper.toast(
                            {
                                content: localization.toast.content(),
                                icon: <DownloadIcon/>,
                                title: localization.toast.title()
                            },
                            {
                                hideProgressBar: true
                            });
                    } else {
                        setExportCsvExecuting(true);
                        try {
                            await exportCsv();
                        } finally {
                            setExportCsvExecuting(false);
                        }
                    }
                    trackAnalytics(
                        AnalyticsEventActionType.CsvExportButtonClick,
                        { "Remote": remoteExport });
                }
            }>
            <DownloadIcon animated={exportCsvExecuting}/>
        </ConfirmButton>);
}