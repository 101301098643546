import { map, Optional, UnexpectedError } from "@infrastructure";
import _ from "lodash";
import { NetworkScopeHelperProtocol } from ".";
import { Contract, IntRangeHelper, NetworkScopeHelper } from "..";

export class NetworkScopeFormatter {
    public static networkScope(
        destinationPortRange: Contract.IntRange,
        protocolRange: Contract.IntRange): string {
        const protocolType = NetworkScopeHelper.getProtocolType(protocolRange);
        let formattedProtocol: string;
        switch (protocolType) {
            case Contract.ProtocolType.Icmp:
                return "Custom ICMP - IPv4";
            case Contract.ProtocolType.IcmpV6:
                return "Custom ICMP - IPv6";
            case Contract.ProtocolType.IPSecAuthenticationHeader:
                formattedProtocol = "AH";
                break;
            case Contract.ProtocolType.IPSecEncapsulatingSecurityPayload:
                formattedProtocol = "ESP";
                break;
            case Contract.ProtocolType.Tcp:
                formattedProtocol = "TCP";
                break;
            case Contract.ProtocolType.Udp:
                formattedProtocol = "UDP";
                break;
            case Contract.ProtocolType.Unknown:
                formattedProtocol = "Protocol ALL";
                break;
            case undefined:
                formattedProtocol = `Protocol ${IntRangeHelper.format(protocolRange as Contract.IntRange)}`;
                break;
            default:
                formattedProtocol = protocolType;
        }

        const formattedDestinationPortRange = NetworkScopeFormatter.portRange(destinationPortRange, protocolType);
        if (_.isNil(formattedDestinationPortRange)) {
            return protocolType === Contract.ProtocolType.Unknown ||
            _.isNil(protocolType)
                ? `${formattedProtocol} Port ${IntRangeHelper.format(destinationPortRange)}`
                : `${formattedProtocol} ${IntRangeHelper.format(destinationPortRange)}`;
        } else if (formattedDestinationPortRange === "ALL") {
            return `${formattedProtocol} Port ALL`;
        } else {
            return NetworkScopeHelper.getProtocolProtocolTypes(NetworkScopeHelper.getProtocol(destinationPortRange, protocolType)!).length > 1
                ? `${formattedProtocol} ${formattedDestinationPortRange} ${IntRangeHelper.format(destinationPortRange)}`
                : `${formattedDestinationPortRange} ${IntRangeHelper.format(destinationPortRange)}`;
        }
    }

    public static networkScopeFromDestinationNetworkScope({ destinationPortRange, protocolRange }: Contract.DestinationNetworkScope) {
        return NetworkScopeFormatter.networkScope(
            destinationPortRange,
            protocolRange);
    }

    public static portRange(portRange: Contract.IntRange, protocolType: Optional<Contract.ProtocolType>): Optional<"ALL" | NetworkScopeHelperProtocol> {
        return (
            NetworkScopeHelper.getProtocol(portRange, protocolType) ??
            (NetworkScopeHelper.isAll(portRange)
                ? "ALL"
                : undefined));
    }

    public static protocolRange(protocolRange: Contract.IntRange, tenantType: Contract.TenantType) {
        return NetworkScopeFormatter.protocolType(
            NetworkScopeHelper.getProtocolType(protocolRange),
            tenantType);
    }

    public static protocolType(protocolType: Optional<Contract.ProtocolType>, tenantType: Contract.TenantType) {
        return map(
            protocolType,
            {
                [Contract.ProtocolType.Icmp]: () => "ICMP",
                [Contract.ProtocolType.IcmpV6]: () => "IPv6 ICMP",
                [Contract.ProtocolType.IPSecAuthenticationHeader]: () => "AH",
                [Contract.ProtocolType.IPSecEncapsulatingSecurityPayload]: () => "ESP",
                [Contract.ProtocolType.Tcp]: () => "TCP",
                [Contract.ProtocolType.Udp]: () => "UDP",
                [Contract.ProtocolType.Unknown]: () => {
                    switch (tenantType) {
                        case Contract.TenantType.Aws:
                            return "All";
                        case Contract.TenantType.Azure:
                            return "Any";
                        case Contract.TenantType.Gcp:
                            return "All";
                        case Contract.TenantType.Oci:
                            return "All";
                        default:
                            throw new UnexpectedError("tenantType", tenantType);
                    }
                }
            },
            () => "Custom protocol");
    }

    public static type(
        destinationPortRange: Optional<Contract.IntRange>,
        icmp: Optional<number>,
        protocolRange: Contract.IntRange): string {
        const protocolType = NetworkScopeHelper.getProtocolType(protocolRange);
        switch (protocolType) {
            case Contract.ProtocolType.Unknown:
                return "ALL";
            case Contract.ProtocolType.Icmp:
                return _.isNil(icmp) || icmp === -1
                    ? "All ICMP - IPv4"
                    : "Custom ICMP - IPv4";
            case Contract.ProtocolType.IcmpV6:
                return _.isNil(icmp) || icmp === -1
                    ? "All ICMP - IPv6"
                    : "Custom ICMP - IPv6";
        }

        const formattedDestinationPortRange = NetworkScopeFormatter.portRange(destinationPortRange!, protocolType);
        if (formattedDestinationPortRange === "ALL") {
            switch (protocolType) {
                case Contract.ProtocolType.Tcp:
                    return "All TCP";
                case Contract.ProtocolType.Udp:
                    return "All UDP";
            }
        }

        if (_.isNil(formattedDestinationPortRange)) {
            switch (protocolType) {
                case Contract.ProtocolType.Tcp:
                    return "Custom TCP";
                case Contract.ProtocolType.Udp:
                    return "Custom UDP";
                default:
                    return "Custom Protocol";
            }
        }

        return formattedDestinationPortRange;
    }
}