﻿import { NoneIcon, NotValidIcon, SuccessIcon, Tooltip } from "@infrastructure";
import { Box, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract, useTheme } from "../../../../../common";
import { useSystemKubernetesClusterApiConnectivityStatusTooltipTranslator, useSystemKubernetesClusterApiConnectivityStatusTranslator } from "../../../../../tenants";
import { KubernetesClusterData } from "./KubernetesClusterData";

type KubernetesClusterApiConnectivityStatusProps = {
    apiConnectivityStatus?: Contract.SystemKubernetesClusterApiConnectivityStatus;
    connector: Contract.KubernetesClusterModelHelmConnector;
    documentationKubernetesOnboardClusterUrl: string;
};

export function KubernetesClusterApiConnectivityStatus({ apiConnectivityStatus, connector, documentationKubernetesOnboardClusterUrl }: KubernetesClusterApiConnectivityStatusProps) {
    const kubernetesClusterStateApiConnectivityStatusTooltipTranslator = useSystemKubernetesClusterApiConnectivityStatusTooltipTranslator();
    const kubernetesClusterStateApiConnectivityStatusTranslator = useSystemKubernetesClusterApiConnectivityStatusTranslator();
    const theme = useTheme();
    return (
        _.isNil(apiConnectivityStatus)
            ? <NoneIcon/>
            : <Stack
                alignItems="center"
                direction="row"
                flexWrap="nowrap"
                spacing={2}
                sx={{ width: "inherit" }}>
                <Tooltip
                    titleOrGetTitle={
                        kubernetesClusterStateApiConnectivityStatusTooltipTranslator(
                            documentationKubernetesOnboardClusterUrl,
                            apiConnectivityStatus)}>
                    <Stack
                        alignItems="center"
                        direction="row"
                        spacing={1}
                        sx={{
                            overflow: "hidden",
                            width: "fit-content"
                        }}>
                        <Box sx={{ fontSize: "18px" }}>
                            {(() => {
                                switch (apiConnectivityStatus) {
                                    case Contract.SystemKubernetesClusterApiConnectivityStatus.ErrorApplicationIpAddressNotConfigured:
                                    case Contract.SystemKubernetesClusterApiConnectivityStatus.ErrorAuthenticationFailure:
                                    case Contract.SystemKubernetesClusterApiConnectivityStatus.ErrorAzureAuthenticationIdentityDisabled:
                                    case Contract.SystemKubernetesClusterApiConnectivityStatus.ErrorClusterNotRunning:
                                    case Contract.SystemKubernetesClusterApiConnectivityStatus.ErrorConnectionFailure:
                                    case Contract.SystemKubernetesClusterApiConnectivityStatus.ErrorTunnelSessionNotConnected:
                                    case Contract.SystemKubernetesClusterApiConnectivityStatus.ErrorTunnelSessionNotExist:
                                        return <NotValidIcon sx={{ color: theme.palette.severity(Contract.Severity.High) }}/>;
                                    case Contract.SystemKubernetesClusterApiConnectivityStatus.ErrorAzureAuthenticationCustomRoleNotConfigured:
                                    case Contract.SystemKubernetesClusterApiConnectivityStatus.ErrorTunnelSessionUnstable:
                                    case Contract.SystemKubernetesClusterApiConnectivityStatus.Pending:
                                        return <NotValidIcon sx={{ color: theme.palette.severity(Contract.Severity.Medium) }}/>;
                                    case Contract.SystemKubernetesClusterApiConnectivityStatus.Success:
                                        return <SuccessIcon sx={{ color: theme.palette.success.main }}/>;
                                }
                            })()}
                        </Box>
                        <Typography noWrap={true}>
                            {kubernetesClusterStateApiConnectivityStatusTranslator(apiConnectivityStatus)}
                        </Typography>
                    </Stack>
                </Tooltip>
                <KubernetesClusterData connector={connector}/>
            </Stack>);
}