import { CopyToClipboardText, DropdownActions, EmptyMessageText, Optional, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { ReactNode, Ref } from "react";
import { InfoItem, InfoItemProps } from "../InfoItem";
import { VerticalTopItems } from "./VerticalTopItems";

type verticalTopItemsInfoItemProps = Partial<Omit<InfoItemProps, "children" | "title">> & {
    children?: (item: any) => ReactNode;
    disabledTitle?: boolean;
    dropdownActionsRef?: Ref<Optional<DropdownActions>>;
    emptyMessageText?: EmptyMessageText | undefined;
    getSortValue?: (item: any) => string;
    items?: unknown[];
    itemsPopover?: (items: any[]) => ReactNode;
    spacing?: number;
    title?: string;
    topItems?: number;
};

export function VerticalTopItemsInfoItem({ children, disabledTitle = false, dropdownActionsRef, getSortValue, items, itemsPopover, title, vertical, ...props }: verticalTopItemsInfoItemProps) {
    getSortValue =
        _.isFunction(getSortValue)
            ? getSortValue
            : item => item;
    const render =
        !_.isNil(children)
            ? children
            : (item: string) =>
                <CopyToClipboardText
                    key={item}
                    text={item}/>;
    const localization =
        useLocalization(
            "common.verticalTopItems.verticalTopItemsInfoItem",
            () => ({
                empty: {
                    withFilter: "No Matching {{title}}",
                    withoutFilter: "No {{title}}"
                }
            }));
    return (
        <InfoItem
            {...props}
            key={title}
            title={
                disabledTitle
                    ? undefined
                    : title}
            vertical={vertical || _.size(items) > 1}>
            {_.isEmpty(items)
                ? undefined
                : <VerticalTopItems
                    dropdownActionsRef={dropdownActionsRef}
                    emptyMessageText={
                        _.isNil(title)
                            ? undefined
                            : new EmptyMessageText(
                                localization.empty.withoutFilter({ title }),
                                localization.empty.withFilter({ title }))}
                    getSortValue={getSortValue}
                    items={items}
                    itemsPopover={itemsPopover}>
                    {item => render(item)}
                </VerticalTopItems>}
        </InfoItem>);
}