import { useMemo } from "react";
import { useAwsDefaultKubernetesNamespaceResourceDefinition, useAwsDefaultKubernetesResourceDefinition, useAwsKubernetesClusterRoleBindingDefinition, useAwsKubernetesClusterRoleDefinition, useAwsKubernetesCronJobDefinition, useAwsKubernetesDaemonSetDefinition, useAwsKubernetesDeploymentDefinition, useAwsKubernetesGroupDefinition, useAwsKubernetesIngressDefinition, useAwsKubernetesJobDefinition, useAwsKubernetesNamespaceDefinition, useAwsKubernetesNamespaceRoleBindingDefinition, useAwsKubernetesNamespaceRoleDefinition, useAwsKubernetesReplicaSetDefinition, useAwsKubernetesServiceAccountDefinition, useAwsKubernetesServiceDefinition, useAwsKubernetesStatefulSetDefinition, useAwsKubernetesUserDefinition } from ".";
import { DefinitionOptions } from "../../..";
import { Contract, TypeHelper } from "../../../../../../../../../../common";

export function useAwsKubernetesResourceDefinition(entityModel: Contract.EntityModel, options?: DefinitionOptions) {
    const useDefinition =
        useMemo(
            () => {
                switch (entityModel.entity.typeName) {
                    case Contract.TypeNames.AwsKubernetesClusterRole:
                        return useAwsKubernetesClusterRoleDefinition;
                    case Contract.TypeNames.AwsKubernetesClusterRoleBinding:
                        return useAwsKubernetesClusterRoleBindingDefinition;
                    case Contract.TypeNames.AwsKubernetesCronJob:
                        return useAwsKubernetesCronJobDefinition;
                    case Contract.TypeNames.AwsKubernetesDaemonSet:
                        return useAwsKubernetesDaemonSetDefinition;
                    case Contract.TypeNames.AwsKubernetesDeployment:
                        return useAwsKubernetesDeploymentDefinition;
                    case Contract.TypeNames.AwsKubernetesGroup:
                        return useAwsKubernetesGroupDefinition;
                    case Contract.TypeNames.AwsKubernetesIngress:
                        return useAwsKubernetesIngressDefinition;
                    case Contract.TypeNames.AwsKubernetesJob:
                        return useAwsKubernetesJobDefinition;
                    case Contract.TypeNames.AwsKubernetesNamespace:
                        return useAwsKubernetesNamespaceDefinition;
                    case Contract.TypeNames.AwsKubernetesNamespaceRole:
                        return useAwsKubernetesNamespaceRoleDefinition;
                    case Contract.TypeNames.AwsKubernetesNamespaceRoleBinding:
                        return useAwsKubernetesNamespaceRoleBindingDefinition;
                    case Contract.TypeNames.AwsKubernetesReplicaSet:
                        return useAwsKubernetesReplicaSetDefinition;
                    case Contract.TypeNames.AwsKubernetesService:
                        return useAwsKubernetesServiceDefinition;
                    case Contract.TypeNames.AwsKubernetesServiceAccount:
                        return useAwsKubernetesServiceAccountDefinition;
                    case Contract.TypeNames.AwsKubernetesStatefulSet:
                        return useAwsKubernetesStatefulSetDefinition;
                    case Contract.TypeNames.AwsKubernetesUser:
                        return useAwsKubernetesUserDefinition;
                    default:
                        return TypeHelper.extendOrImplement(entityModel.entity.typeName, Contract.TypeNames.AwsKubernetesNamespaceResourceBase)
                            ? useAwsDefaultKubernetesNamespaceResourceDefinition
                            : useAwsDefaultKubernetesResourceDefinition;
                }
            },
            []);

    return useDefinition(entityModel as Contract.AwsResourceModel, options);
}