import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function ConcealIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path d="M7.01797 17.8997C8.53149 18.6057 10.2193 19 11.9996 19C16.9996 19 21.2696 15.89 22.9996 11.5C21.6943 8.18755 18.9428 5.60385 15.5228 4.53505L14.4017 6.29668C17.2191 7.00685 19.5547 8.93776 20.8147 11.5C19.2108 14.7615 15.8642 17 11.9996 17C10.6156 17 9.298 16.7129 8.1031 16.1945L7.01797 17.8997Z"/>
            <path d="M9.24489 14.4003C9.98727 15.1054 10.9731 15.5 11.9996 15.5C13.0605 15.5 14.0779 15.0786 14.828 14.3284C15.5782 13.5783 15.9996 12.5609 15.9996 11.5C15.9996 10.4391 15.5782 9.42171 14.828 8.67156C14.4348 8.27832 13.9681 7.97541 13.4602 7.7762L12.3442 9.52989C12.7467 9.6003 13.121 9.79295 13.4138 10.0858C13.7889 10.4608 13.9996 10.9696 13.9996 11.5C13.9996 12.0304 13.7889 12.5391 13.4138 12.9142C13.0388 13.2893 12.53 13.5 11.9996 13.5C11.4692 13.5 10.9605 13.2893 10.5854 12.9142C10.5023 12.8311 10.4273 12.7415 10.3609 12.6466L9.24489 14.4003Z"/>
            <path 
                clipRule="evenodd" 
                d="M18.8262 0.563518C19.2825 0.87464 19.4002 1.49677 19.0891 1.95308L5.21579 22.3006C4.90467 22.7569 4.28254 22.8746 3.82623 22.5635C3.36991 22.2524 3.25221 21.6303 3.56334 21.174L17.4367 0.826408C17.7478 0.370096 18.3699 0.252396 18.8262 0.563518Z"
                fillRule="evenodd"/>
            <path 
                clipRule="evenodd" 
                d="M1.06853 11.5112C2.78403 7.1217 7.02343 4.00018 11.9999 4.00018H13.5L11.9999 6.00018C8.02335 6.00018 4.59806 8.41036 3.08203 11.8752C3.65012 13.1733 4.96509 14.568 6 15.5002L5 17.0002C3.64053 15.7756 1.74872 13.9796 1.06853 12.2392L0.92627 11.8752L1.06853 11.5112Z" 
                fillRule="evenodd"/>
        </SvgIcon>);
}