import React, { Fragment, ReactNode, useMemo } from "react";

type MemoItemRendererProps = {
    getDependencies?: (...args: any[]) => any[];
    render: (...args: any[]) => ReactNode;
    renderArguments: any[];
};

export function MemoItemRenderer({ getDependencies, render, renderArguments }: MemoItemRendererProps) {
    return (
        <Fragment>
            {useMemo(
                () => render(...renderArguments),
                [...(getDependencies?.(...renderArguments) ?? []), ...renderArguments])}
        </Fragment>);
}