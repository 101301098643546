import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function SophosIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <g clipPath="url(#clip0_58343_2209)">
                <path
                    clipRule="evenodd"
                    d="M24 0V14.925C24 16.4805 23.1431 17.913 21.7616 18.666L11.9908 24L2.23685 18.6675C0.856926 17.913 0 16.482 0 14.928V0H24ZM17.7865 3.64201H9.85006C7.58272 3.64201 5.72859 5.46753 5.72859 7.698C5.72859 9.92852 7.58272 11.754 9.85006 11.754H14.2551C15.0358 11.754 15.6732 12.3825 15.6732 13.1505C15.6732 13.9185 15.0358 14.547 14.2551 14.547L6.31715 14.5187V17.2082H14.2551C16.5225 17.2082 18.3781 15.3827 18.3781 13.1521C18.3781 10.9217 16.5225 9.09616 14.2551 9.09616H9.84854C9.06785 9.09616 8.43049 8.46766 8.43049 7.69964C8.43049 6.93162 9.06785 6.30312 9.84854 6.30312L17.7865 6.33147V3.64343V3.64201Z"
                    fill="#005BC8"
                    fillRule="evenodd"/>
            </g>
            <defs>
                <clipPath id="clip0_58343_2209">
                    <rect
                        fill="white"
                        height="24"
                        width="24"/>
                </clipPath>
            </defs>
        </SvgIcon>);
}