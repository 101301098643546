import { InlineItems, InlineItemsVariant, StringHelper, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, Scope, ScopeNameTranslatorOptions, scopeNodeModelStore, ScopeVariant, useScopeNameTranslator } from "..";

type InlineScopesProps = {
    namePluralizer?: (count: number) => string;
    scopeIds: string[];
    scopeNameTranslatorOptions?: ScopeNameTranslatorOptions;
    scopeVariant?: ScopeVariant;
    variant: InlineItemsVariant;
};

export function InlineScopes({ namePluralizer, scopeIds, scopeNameTranslatorOptions, scopeVariant, variant }: InlineScopesProps) {
    const scopeNameTranslator = useScopeNameTranslator();
    const localization =
        useLocalization(
            "common.inlineScopes",
            () => ({
                scopes: [
                    "1 scope",
                    "{{count | NumberFormatter.humanize}} scopes"
                ]
            }));

    const scopeNodeModels = scopeNodeModelStore.useGet(scopeIds);
    const orderedScopeNodeModels =
        useMemo(
            () =>
                _.orderBy(
                    scopeNodeModels,
                    scopeNodeModel => StringHelper.getSortValue(scopeNameTranslator(scopeNodeModel.configuration.id, scopeNameTranslatorOptions))),
            [scopeNodeModels]);

    return (
        <InlineItems
            anchorOrigin={{
                horizontal: "center",
                vertical: "top"
            }}
            items={orderedScopeNodeModels}
            namePluralizer={namePluralizer ?? localization.scopes}
            sx={{ fontWeight: 600 }}
            variant={variant}>
            {(scopeNodeModel: Contract.ScopeNodeModel, inline) =>
                <Scope
                    scopeId={scopeNodeModel.configuration.id}
                    scopeNameTranslatorOptions={scopeNameTranslatorOptions}
                    sx={{
                        fontWeight:
                            inline
                                ? 600
                                : undefined
                    }}
                    variant={
                        inline
                            ? "text"
                            : scopeVariant}/>}
        </InlineItems>);
}