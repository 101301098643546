import { RouteType, useRouteContext } from "@infrastructure";
import { useSideViewContext } from "..";

export function useSideViewState() {
    const { items } = useSideViewContext();
    const { type } = useRouteContext();

    return {
        inside: type === RouteType.Hash,
        open: items.length > 0
    };
}