import EventEmitter from "eventemitter3";
import { useEffect, useMemo } from "react";
import { Action0 } from "../types";

export type EventHandlerRegister<TEventHandler extends (...args: any[]) => any> = (handler: TEventHandler) => Action0;

export function useEvent<TEventHandler extends (...args: any[]) => any>(): [EventHandlerRegister<TEventHandler>, TEventHandler] {
    const eventName = "event";
    const [eventEmitter, registerEvent, triggerEvent] =
        useMemo(
            () => {
                const eventEmitter = new EventEmitter();
                let eventResult: any = undefined;
                const registerEvent =
                    (handler: TEventHandler) => {
                        const eventHandler =
                            (...args: any[]) => {
                                eventResult = handler(...args);
                            };

                        eventEmitter.on(eventName, eventHandler);
                        return () => eventEmitter.off(eventName, eventHandler);
                    };
                const triggerEvent =
                    (...args: any[]) => {
                        eventResult = undefined;
                        eventEmitter.emit(eventName, ...args);
                        return eventResult;
                    };

                return [eventEmitter, registerEvent, triggerEvent];
            },
            []);
    useEffect(
        () => () => {
            eventEmitter.off(eventName);
        },
        []);

    return [registerEvent, triggerEvent] as any;
}