import { Grid2 } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { TenantTypeStatus, TenantTypeStatusItem } from "../../../../../..";

type TenantsProps = {
    tenantTypeToTenantTypeStatusItem: { [x: string]: TenantTypeStatusItem };
};

export function Tenants({ tenantTypeToTenantTypeStatusItem }: TenantsProps) {
    const tenantTypes =
        useMemo(
            () =>
                _(tenantTypeToTenantTypeStatusItem).
                    keys().
                    value(),
            [tenantTypeToTenantTypeStatusItem]);

    return (
        <Grid2
            alignItems="center"
            columnSpacing={1}
            container={true}
            rowSpacing={0}
            sx={{ height: "100%" }}>
            {_.map(
                tenantTypes,
                tenantType =>
                    <Grid2
                        className={`item ${tenantType}`}
                        key={tenantType}
                        size={{ xs: 12 / _.size(tenantTypes) }}
                        sx={{ height: "100%" }}>
                        <TenantTypeStatus item={tenantTypeToTenantTypeStatusItem[tenantType]}/>
                    </Grid2>)}
        </Grid2>);
}