import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, useLocalization, ValueFilter } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, PagedEntityFilter, tenantModelStore, useEntitiesExportOptions, useEntityTypeNameTranslator } from "../../../../../../../../../../common";
import { GcpRoleBindingHelper } from "../../../../../../../../../../tenants";
import { DefinitionData } from "../../../../Table";
import { useGciCommonEntityDefinition } from "./useGciCommonEntityDefinition";

export function useGciCommonDirectoryPartialPrincipalDefinition(definitionData: DefinitionData) {
    const commonEntityDefinition = useGciCommonEntityDefinition(definitionData);
    const activeCloudProviderTenantTypes = tenantModelStore.useGetActiveCloudProviderTenantTypes();
    const entitiesExportOptions = useEntitiesExportOptions();
    const entityTypeNameTranslator = useEntityTypeNameTranslator();

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.gci.useGciCommonDirectoryPartialPrincipalDefinition",
            () => ({
                columns: {
                    containedGcpRoleBindingIds: {
                        helpText: "These role bindings are \"covered\" by another role binding at a higher scope level or one that is assigned via group membership.",
                        title: "Covered GCP Role Bindings"
                    },
                    gcpRoleBindingIds: "GCP Role Bindings",
                    gcpRoleIds: "GCP Roles"
                }
            }));
    return {
        columns: {
            ...commonEntityDefinition.columns,
            containedGcpRoleBindingIdsColumn:
                _.includes(activeCloudProviderTenantTypes, Contract.TenantType.Gcp)
                    ? <DataTableColumn
                        exportOptions={
                            entitiesExportOptions<Contract.GciDirectoryPartialPrincipalModel>(
                                Contract.TypeNames.GcpIamRoleBinding,
                                item => item.containedGcpRoleBindingIds,
                                localization.columns.containedGcpRoleBindingIds.title(),
                                { getValue: entityModel => GcpRoleBindingHelper.getDisplayName(entityTypeNameTranslator, entityModel as Contract.GcpIamRoleBindingModel) })
                        }
                        filterOptions={{
                            itemOrItems: {
                                element:
                                    <ValueFilter
                                        items={[
                                            {
                                                value: true
                                            }
                                        ]}
                                        placeholder={localization.columns.containedGcpRoleBindingIds.title()}/>
                            }
                        }}
                        id={Contract.EntityModelProperty.GciPrincipalContainedGcpRoleBindings}
                        key={Contract.EntityModelProperty.GciPrincipalContainedGcpRoleBindings}
                        message={localization.columns.containedGcpRoleBindingIds.helpText()}
                        messageLevel="info"
                        render={
                            ({ item }: DataTableColumnRenderProps<Contract.GciDirectoryPartialPrincipalModel>) =>
                                <EntitiesCell
                                    entityIdsOrModels={item.containedGcpRoleBindingIds}
                                    entityTypeName={Contract.TypeNames.GcpIamRoleBinding}
                                    entityVariant="iconText"/>}
                        selectorOptions={{ default: false }}
                        sortOptions={{ type: DataTableSortType.Numeric }}
                        title={localization.columns.containedGcpRoleBindingIds.title()}/>
                    : undefined,
            gcpRoleBindingIdsColumn:
                _.includes(activeCloudProviderTenantTypes, Contract.TenantType.Gcp)
                    ? <DataTableColumn
                        exportOptions={
                            entitiesExportOptions<Contract.GciDirectoryPartialPrincipalModel>(
                                Contract.TypeNames.GcpIamRoleBinding,
                                item => item.gcpRoleBindingIds,
                                localization.columns.gcpRoleBindingIds(),
                                { getValue: entityModel => GcpRoleBindingHelper.getDisplayName(entityTypeNameTranslator, entityModel as Contract.GcpIamRoleBindingModel) })
                        }
                        id={Contract.EntityModelProperty.GciPrincipalGcpRoleBindings}
                        key={Contract.EntityModelProperty.GciPrincipalGcpRoleBindings}
                        render={
                            ({ item }: DataTableColumnRenderProps<Contract.GciDirectoryPartialPrincipalModel>) =>
                                <EntitiesCell
                                    entityIdsOrModels={item.gcpRoleBindingIds}
                                    entityTypeName={Contract.TypeNames.GcpIamRoleBinding}
                                    entityVariant="iconText"/>}
                        sortOptions={{ type: DataTableSortType.Numeric }}
                        title={localization.columns.gcpRoleBindingIds()}/>
                    : undefined,
            gcpRoleIdsFilterColumn:
                _.includes(activeCloudProviderTenantTypes, Contract.TenantType.Gcp)
                    ? <DataTableColumn
                        filterOptions={{
                            itemOrItems: {
                                element:
                                    <PagedEntityFilter
                                        getEntityIdPage={
                                            ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                                definitionData.entityTypeEntitiesViewName,
                                                Contract.EntityModelProperty.GciDirectoryPartialPrincipalRoles)}
                                        placeholder={localization.columns.gcpRoleIds()}/>
                            }
                        }}
                        id={Contract.EntityModelProperty.GciDirectoryPartialPrincipalRoles}
                        key={Contract.EntityModelProperty.GciDirectoryPartialPrincipalRoles}
                        title={localization.columns.gcpRoleIds()}/>
                    : undefined
        }
    };
}