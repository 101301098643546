import { useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import React from "react";
import { Contract } from "../../../common";
import { AadDirectoryPrincipalType } from "../../../common/controllers/types.generated";

type AadDirectoryUnresolvedPrincipalProps = {
    rawId: string;
    type: AadDirectoryPrincipalType;
};

export function AadDirectoryUnresolvedPrincipal({ rawId, type }: AadDirectoryUnresolvedPrincipalProps) {
    const localization =
        useLocalization(
            "tenants.aad.aadDirectoryUnresolvedPrincipal",
            () => ({
                everyone: "Everyone",
                unknown: "Unknown {{type}} ({{rawId}})",
                unknownPrincipalType: "Unknown Principal ({{rawId}})"
            }));
    return (
        <Typography noWrap={true}>
            {type === Contract.AadDirectoryPrincipalType.Everyone
                ? localization.everyone()
                : type === Contract.AadDirectoryPrincipalType.Unknown
                    ? localization.unknownPrincipalType({
                        rawId
                    })
                    : localization.unknown({
                        rawId,
                        type
                    })}
        </Typography>);
}