import { Dialog, getUrlQueryParameters, makeContextProvider, useClearQueryParameters, useExecuteOperation } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { Contract, TenantController } from "../../../../../../../../../../../../common";
import { AddOrEdit, Table } from "./components";

export class AadTenantsContext {
    constructor(
        public addOrEditOpen: boolean | Contract.AadTenantConfiguration,
        public partitionTypes: Contract.AadPartitionType[],
        public partitionTypeToApplicationRawIdMap: Dictionary<string>) {
    }
}

export const [useAadTenantsContext, useSetAadTenantsContext, useAadTenantsContextProvider] = makeContextProvider<AadTenantsContext>();

export function AadTenants() {
    const [{ partitionTypes, partitionTypeToApplicationRawIdMap }] =
        useExecuteOperation(
            AadTenants,
            TenantController.getAadTenantOnboardingInfo);

    const [context, setContext, ContextProvider] =
        useAadTenantsContextProvider(
            () => {
                const queryParameters = getUrlQueryParameters();
                return new AadTenantsContext(
                    !_.isEmpty(queryParameters),
                    partitionTypes,
                    partitionTypeToApplicationRawIdMap);
            });

    const clearQueryParameters = useClearQueryParameters();
    return (
        <ContextProvider>
            {context.addOrEditOpen !== false &&
                <Dialog
                    size="large"
                    variant="editor"
                    onClose={
                        () => {
                            clearQueryParameters();
                            setContext(
                                context => ({
                                    ...context,
                                    addOrEditOpen: false
                                }));
                        }}>
                    <AddOrEdit/>
                </Dialog>}
            <Table/>
        </ContextProvider>);
}

export type IdentityProviderTenantAadTenantQueryParameters = {
    addOrEditOpen?: string;
};