﻿import { Contract } from "../../../../../controllers";
import { entityModelStore } from "../../../../../stores";
import { EntityDefinition } from "../useDefinition";

export function useAadDirectoryDirectoryRoleAssignmentResourceDefinition(entityModel: Contract.EntityModel) {
    const roleAssignmentResource = entityModel.entity as Contract.AadDirectoryDirectoryRoleAssignmentResource;
    const roleDefinitionModel = entityModelStore.useGet(roleAssignmentResource.roleDefinitionId);

    return new EntityDefinition(
        entityModel,
        {
            glanceOptions: {
                entityId: roleAssignmentResource.roleDefinitionId
            },
            iconVariant: (roleDefinitionModel.entity as Contract.AadDirectoryDirectoryRoleDefinition).type
        });
}