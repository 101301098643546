import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, Entity, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { useOciObjectStorageBucketObjectAccessLevelTranslator, useOciObjectStorageBucketObjectVersioningTranslator } from "../../../../../../../../../../../../tenants";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetOciResourceRiskContext } from "./useGetOciResourceRiskContext";

export function useGetOciObjectStorageBucketRiskContext() {
    return useMemo(
        () => useOciObjectStorageBucketRiskContext,
        []);
}

function useOciObjectStorageBucketRiskContext(bucketModel: Contract.OciObjectStorageBucketModel) {
    const bucket = bucketModel.entity as Contract.OciObjectStorageBucket;
    const resourceRiskContext = useGetOciResourceRiskContext()(bucketModel);

    const bucketObjectAccessLevelTranslator = useOciObjectStorageBucketObjectAccessLevelTranslator();
    const bucketObjectVersioningTranslator = useOciObjectStorageBucketObjectVersioningTranslator();
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.oci.hooks.contexts.useGetOciObjectStorageBucketRiskContext",
            () => ({
                accessLevel: "The {{translatedBucketTypeName}}'s access level is {{translatedAccessLevel}}",
                encryption: {
                    empty: "Oracle managed key",
                    text: "The {{translatedBucketTypeName}} is encrypted using {{key}}"
                },
                objectEventsEnabled: {
                    false: "The {{translatedBucketTypeName}} does not have Emit Object Events enabled",
                    true: "The {{translatedBucketTypeName}} has Emit Object Events enabled"
                },
                objectVersioning: "The {{translatedBucketTypeName}} has versioning {{translatedObjectVersioning}}",
                storageSize: {
                    empty: "The {{translatedBucketTypeName}} is empty",
                    text: "The {{translatedBucketTypeName}}’s size is {{storageSize | NumberFormatter.storage}}"
                }
            }));

    const translatedBucketTypeName =
        entityTypeNameTranslator(
            bucket.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });

    return {
        ...resourceRiskContext,
        accessLevel:
            new RiskDefinitionContextItem(
                localization.accessLevel({
                    translatedAccessLevel: bucketObjectAccessLevelTranslator(bucket.objectAccessLevel),
                    translatedBucketTypeName
                })),
        encryption:
            new RiskDefinitionContextItem(
                localization.encryption.text({
                    key:
                        _.isNil(bucketModel.encryptionVaultKeyIdReference)
                            ? localization.encryption.empty()
                            : <Entity
                                entityIdOrModel={bucketModel.encryptionVaultKeyIdReference}
                                variant="text"/>,
                    translatedBucketTypeName
                })),
        objectEventsEnabled:
            new RiskDefinitionContextItem(
                (bucket.objectEventsEnabled
                    ? localization.objectEventsEnabled.true
                    : localization.objectEventsEnabled.false)({ translatedBucketTypeName })),
        objectVersioning:
            new RiskDefinitionContextItem(
                localization.objectVersioning({
                    translatedBucketTypeName,
                    translatedObjectVersioning: bucketObjectVersioningTranslator(bucket.objectVersioning)
                })),
        storageSize:
            new RiskDefinitionContextItem(
                (bucket.storageSize > 0
                    ? localization.storageSize.text
                    : localization.storageSize.empty)({
                    storageSize: bucket.storageSize,
                    translatedBucketTypeName
                }))
    };
}