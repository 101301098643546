import { AnalyticsEventActionType, useLocalization, useTrackAnalyticsEvent } from "@infrastructure";
import _ from "lodash";
import React, { useCallback, useMemo } from "react";
import { compactLayout, Contract, Dashboard as DashboardCore, DashboardConfiguration, DashboardController, dashboardWidgetMap, DashboardWidgetType, EntityController, FeatureHelper, LicensingHelper, OperatingSystemTypeSelector, SummaryDashboardContext, TenantHelper, tenantModelStore, useGetSelectedScopeHasData } from "../../../../common";
import { Welcome } from "./components";

export function Dashboard() {
    useTrackAnalyticsEvent(AnalyticsEventActionType.PageView);

    const activeTenantModels = tenantModelStore.useGetActiveTenants();

    if (_.isEmpty(activeTenantModels)) {
        return <Welcome variant="newCustomer"/>;
    } else if (_.every(activeTenantModels, TenantHelper.isCodeTenant)) {
        return <Welcome variant="codeTenants"/>;
    } else {
        return <Core/>;
    }
}

function Core() {
    const tenantTypes =
        useMemo(
            () => TenantHelper.DashboardTenantTypes,
            []);
    const filteredCloudProviderTenants = tenantModelStore.useGetFilteredCloudProviderTenants(tenantTypes);
    const filteredActiveTenantModels = tenantModelStore.useGetFilteredActiveTenants(tenantTypes);
    const tenantTypeToCloudProviderTenantModelsMap =
        useMemo(
            () =>
                _(filteredCloudProviderTenants).
                    filter(tenantModel => !_.isNil(tenantModel.state.orchestration.orchestrationTypeToDataMap[Contract.OrchestrationType.Analysis]?.previousTimeRange?.endTime)).
                    groupBy(tenantModel => tenantModel.tenantType).
                    value(),
            [filteredCloudProviderTenants]);
    const getSelectedScopeHasData = useGetSelectedScopeHasData();

    const fetchDashboardContextData =
        useCallback(
            async () => {
                if (!getSelectedScopeHasData(filteredActiveTenantModels)) {
                    return getAnalyzingDashboardContext();
                }

                const [{ summary }, { entityTypeEntitiesViewNameToCountMap }] =
                    await Promise.all([
                        DashboardController.getSummary(),
                        EntityController.getSummary(new Contract.EntityControllerGetSummaryRequest(undefined)),
                        tenantModelStore.notify()
                    ]);

                return new SummaryDashboardContext(
                    entityTypeEntitiesViewNameToCountMap,
                    summary!,
                    false);
            },
            [filteredActiveTenantModels, getSelectedScopeHasData, tenantModelStore]);

    const getWidgetDatas =
        useCallback(
            (contextData: SummaryDashboardContext) => {
                const { analyzing = false, entityTypeEntitiesViewNameToCountMap, summary } = contextData;
                const dashboardWidgetWidthMap = {
                    [DashboardWidgetType.Compliance]: [
                        !_.isEmpty(summary.riskCombinationToDataMap)
                            ? 4
                            : 6],
                    [DashboardWidgetType.DataAnalysis]: [4, 6],
                    entities:
                        _.isEmpty(tenantTypeToCloudProviderTenantModelsMap) && !analyzing
                            ? [12]
                            : [6, 8, 10, 12],
                    [DashboardWidgetType.EntityPublicData]: [4, 6],
                    [DashboardWidgetType.OpenBehaviorRisk]: [12],
                    [DashboardWidgetType.RiskCombinations]: [4, 6],
                    [DashboardWidgetType.Risks]: [6],
                    [DashboardWidgetType.RiskTrends]: [6],
                    [DashboardWidgetType.Secrets]: [4, 6],
                    [DashboardWidgetType.Tenants]: [
                        _.size(tenantTypeToCloudProviderTenantModelsMap) === 1
                            ? 2
                            : _.size(tenantTypeToCloudProviderTenantModelsMap) > 3
                                ? 6
                                : 4],
                    [DashboardWidgetType.TopRisks]:
                        !_.isEmpty(summary.riskCombinationToDataMap)
                            ? [4]
                            : [6],
                    [DashboardWidgetType.TopRiskTenants]: [6],
                    [DashboardWidgetType.TopRiskTypes]: [6],
                    [DashboardWidgetType.WorkloadAnalysisCriticalVulnerabilities]: [4],
                    [DashboardWidgetType.WorkloadAnalysisRiskDistribution]: [4],
                    [DashboardWidgetType.WorkloadAnalysisVulnerabilityStatus]: [4]
                };

                const orderedDashboardWidgetDatas =
                    _<DashboardWidgetType>([]).
                        concatIf(
                            !_.isEmpty(tenantTypeToCloudProviderTenantModelsMap) || analyzing,
                            DashboardWidgetType.Tenants).
                        concatIf(
                            !_.isEmpty(entityTypeEntitiesViewNameToCountMap),
                            DashboardWidgetType.Entities).
                        concat(
                            DashboardWidgetType.Risks,
                            DashboardWidgetType.RiskTrends).
                        concatIf(
                            LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.Cspm),
                            DashboardWidgetType.Compliance).
                        concatIf(
                            !_.isEmpty(summary.riskCombinationToDataMap),
                            DashboardWidgetType.RiskCombinations).
                        concat(DashboardWidgetType.TopRisks).
                        concatIf(
                            LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.Cspm),
                            DashboardWidgetType.DataAnalysis).
                        concatIf(
                            LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.Cspm),
                            DashboardWidgetType.Secrets).
                        concat(
                            DashboardWidgetType.EntityPublicData,
                            DashboardWidgetType.OpenBehaviorRisk,
                            DashboardWidgetType.TopRiskTenants,
                            DashboardWidgetType.TopRiskTypes).
                        concatIf(
                            LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.Cnapp),
                            DashboardWidgetType.WorkloadAnalysisVulnerabilityStatus).
                        concatIf(
                            LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.Cnapp),
                            DashboardWidgetType.WorkloadAnalysisCriticalVulnerabilities).
                        concatIf(
                            LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.Cnapp),
                            DashboardWidgetType.WorkloadAnalysisRiskDistribution).
                        map(
                            orderedDashboardWidgetType => ({
                                location: {
                                    height: dashboardWidgetMap[orderedDashboardWidgetType].h!,
                                    width: dashboardWidgetMap[orderedDashboardWidgetType].w!
                                },
                                type: orderedDashboardWidgetType
                            })).
                        value();

                return compactLayout(
                    orderedDashboardWidgetDatas,
                    dashboardWidgetWidthMap);
            },
            [tenantTypeToCloudProviderTenantModelsMap]);

    const localization =
        useLocalization(
            "views.customer.dashboard.core",
            () => ({
                title: "Dashboard"
            }));

    const configuration =
        useMemo<DashboardConfiguration>(
            () => ({
                editable: FeatureHelper.enabled(Contract.FeatureName.DashboardEditEnabled),
                fetchDashboardContextData,
                title: localization.title(),
                widgetDatasOrGetWidgetDatas: getWidgetDatas
            }),
            [fetchDashboardContextData, getWidgetDatas, localization]);

    return (
        <DashboardCore
            className="mainDashboard"
            configuration={configuration}/>);
}

function getAnalyzingDashboardContext(): SummaryDashboardContext {
    return new SummaryDashboardContext(
        {
            [Contract.TypeNames.AwsS3Bucket]: 1000,
            [Contract.TypeNames.AwsSqsQueue]: 500,
            [Contract.TypeNames.AwsSnsTopic]: 1400,
            [Contract.TypeNames.AwsSecretsManagerSecret]: 2500,
            [Contract.TypeNames.AwsKmsKey]: 90,
            [Contract.TypeNames.AwsElbLoadBalancer]: 350,
            [Contract.TypeNames.AwsIamRole]: 1980,
            [Contract.TypeNames.AwsEc2Instance]: 2030
        },
        new Contract.DashboardSummary(
            {},
            {},
            {
                [Contract.TypeNames.AwsS3Bucket]: new Contract.ScopeSummaryCloudProviderEntityPublicData(0, 0, [], Contract.Severity.Critical),
                [Contract.TypeNames.AwsSqsQueue]: new Contract.ScopeSummaryCloudProviderEntityPublicData(0, 0, [], Contract.Severity.Critical),
                [Contract.TypeNames.AwsSnsTopic]: new Contract.ScopeSummaryCloudProviderEntityPublicData(0, 0, [], Contract.Severity.High),
                [Contract.TypeNames.AwsSecretsManagerSecret]: new Contract.ScopeSummaryCloudProviderEntityPublicData(0, 0, [], Contract.Severity.High),
                [Contract.TypeNames.AwsKmsKey]: new Contract.ScopeSummaryCloudProviderEntityPublicData(0, 0, [], Contract.Severity.Medium),
                [Contract.TypeNames.AwsElbLoadBalancer]: new Contract.ScopeSummaryCloudProviderEntityPublicData(0, 0, [], Contract.Severity.Medium),
                [Contract.TypeNames.AwsIamRole]: new Contract.ScopeSummaryCloudProviderEntityPublicData(0, 0, [], Contract.Severity.Low),
                [Contract.TypeNames.AwsEc2Instance]: new Contract.ScopeSummaryCloudProviderEntityPublicData(0, 0, [], Contract.Severity.Low)
            },
            0,
            {},
            {},
            {},
            0,
            {},
            0,
            {
                [Contract.Severity.Critical]: {
                    [Contract.RiskPolicyCategory.Access]: 100,
                    [Contract.RiskPolicyCategory.Compute]: 50
                },
                [Contract.Severity.High]: {
                    [Contract.RiskPolicyCategory.Logging]: 50,
                    [Contract.RiskPolicyCategory.Management]: 10
                }
            },
            {
                [Contract.TimeFrame.Medium]: {
                    closedRiskCount: 100,
                    createdTicketCount: 100,
                    dateToOpenBehaviorRiskPolicyConfigurationTypeNameToRiskCountMap: {},
                    highOrCriticalOpenRiskCount: 0,
                    ignoredRiskCount: 50,
                    newPolicyOpenRiskCount: 0,
                    openRiskCount: 1000,
                    openRiskNewBuiltInRiskPolicyConfigurationTypeNames: [],
                    openRiskNewCustomRiskPolicyIds: [],
                    riskAverageOpenTimeFrame: "",
                    startTime: ""
                }
            },
            [],
            {},
            new Contract.DashboardSummaryWorkloadAnalysisData(
                {
                    [OperatingSystemTypeSelector.All]: {
                        [Contract.OperatingSystemRiskPolicyType.EndOfLife]: 20,
                        [Contract.OperatingSystemRiskPolicyType.Unpatched]: 40,
                        [Contract.OperatingSystemRiskPolicyType.Secured]: 60
                    },
                    [OperatingSystemTypeSelector.Linux]: {
                        [Contract.OperatingSystemRiskPolicyType.EndOfLife]: 10,
                        [Contract.OperatingSystemRiskPolicyType.Unpatched]: 20,
                        [Contract.OperatingSystemRiskPolicyType.Secured]: 30
                    },
                    [OperatingSystemTypeSelector.Windows]: {
                        [Contract.OperatingSystemRiskPolicyType.EndOfLife]: 10,
                        [Contract.OperatingSystemRiskPolicyType.Unpatched]: 20,
                        [Contract.OperatingSystemRiskPolicyType.Secured]: 30
                    }
                },
                new Contract.SecurityStats(
                    80,
                    15,
                    65,
                    {
                        [Contract.Severity.Low]: 5,
                        [Contract.Severity.Medium]: 10,
                        [Contract.Severity.High]: 20,
                        [Contract.Severity.Critical]: 30
                    }))),
        true);
}