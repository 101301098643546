import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useOciNetworkingNetworkLoadBalancerStatusTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useOciNetworkingNetworkLoadBalancerStatusTranslator",
            () => ({
                [Contract.TypeNames.OciNetworkingNetworkLoadBalancerStatus]: {
                    [Contract.OciNetworkingNetworkLoadBalancerStatus.Active]: "Active",
                    [Contract.OciNetworkingNetworkLoadBalancerStatus.Creating]: "Creating",
                    [Contract.OciNetworkingNetworkLoadBalancerStatus.Deleted]: "Deleted",
                    [Contract.OciNetworkingNetworkLoadBalancerStatus.Deleting]: "Deleting",
                    [Contract.OciNetworkingNetworkLoadBalancerStatus.Failed]: "Failed",
                    [Contract.OciNetworkingNetworkLoadBalancerStatus.Updating]: "Updating"
                }
            }));
    return (status: Contract.OciNetworkingNetworkLoadBalancerStatus) =>
        localization[Contract.TypeNames.OciNetworkingNetworkLoadBalancerStatus][status]();
}