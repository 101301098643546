﻿import { Link, Optional, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, Entity, entityModelStore, InlineEntities } from "../../../../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../../../../tenants";
import { RiskDefinitionSection } from "../../../../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../../../../useCommonSectionsAndDescriptionDefinition";
import { ContextSection, DetailsSection } from "./components";

export function useAwsEc2InstanceUserDataSecretExistsRiskLaunchConfigurationDefinition(riskModel: Contract.AwsEc2InstanceUserDataSecretExistsRiskModel) {
    const autoScalingGroupsInfo = riskModel.risk.infos.find(info => info.typeName == Contract.TypeNames.AwsEc2InstanceUserDataSecretExistsRiskAutoScalingGroupsInfo) as Optional<Contract.AwsEc2InstanceUserDataSecretExistsRiskAutoScalingGroupsInfo>;
    const launchConfigurationModel = entityModelStore.useGet(riskModel.risk.entityId) as Contract.AwsAutoScalingLaunchConfigurationModel;
    const launchConfiguration = (launchConfigurationModel.entity) as Contract.AwsAutoScalingLaunchConfiguration;

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const detailsSection = DetailsSection({ riskModel });
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsEc2InstanceUserDataSecretExistsRiskDefinition.hooks.useAwsEc2InstanceUserDataSecretExistsRiskLaunchConfiguration",
            () => ({
                description: "{{launchConfiguration}} is exposing secrets in clear text via user data",
                sections: {
                    details: {
                        instances: "Instances",
                        userData: "Launch Configuration User Data"
                    },
                    resolution: {
                        step1: "Select launch configuration **{{launchConfigurationName}}** and choose **Actions**, **Copy launch configuration**",
                        step2: "In the **Additional Configuration** section, click on **Advanced Details**",
                        step3: "Expand the **Advanced Details** section",
                        step4: {
                            link: "Secrets Manager",
                            text: "In the **User data**, migrate your secrets to {{secretsManagerLink}}"
                        },
                        step5: "Click on the **Create launch configuration** button",
                        step6: "Update the **{{autoScalingGroups}}** to use the new launch configuration",
                        step7: "On the Launch Configuration page, select the old configuration and click **Actions, delete launch configuration**"
                    }
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            launchConfiguration:
                <Entity
                    entityIdOrModel={riskModel.risk.entityId}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () =>
            [
                consoleSignInStepTranslator(
                    Contract.AwsConsoleView.Ec2,
                    AwsConsoleUrlBuilder.getAutoScalingGroupLaunchConfigurationUrl(launchConfiguration)),
                localization.sections.resolution.step1({ launchConfigurationName: launchConfiguration.displayName }),
                localization.sections.resolution.step2(),
                localization.sections.resolution.step3(),
                localization.sections.resolution.step4.text({
                    secretsManagerLink:
                        <Link
                            urlOrGetUrl={riskModel.secretsManagerDocumentationUrl}
                            variant="external">
                            {localization.sections.resolution.step4.link()}
                        </Link>
                }),
                localization.sections.resolution.step5(),
                !_.isNil(autoScalingGroupsInfo) &&
                autoScalingGroupsInfo.autoScalingGroupIds.length > 0
                    ? localization.sections.resolution.step6({
                        autoScalingGroups:
                            <InlineEntities
                                entityIdsOrModels={autoScalingGroupsInfo.autoScalingGroupIds}
                                entityTypeName={Contract.TypeNames.AwsAutoScalingAutoScalingGroup}
                                variant="itemCountAndType"/>
                    })
                    : undefined,
                localization.sections.resolution.step7()
            ],
        riskModel,
        undefined,
        {
            contextSectionElement: <ContextSection riskModel={riskModel}/>,
            sections:
                _<RiskDefinitionSection>([]).
                    concatIf(!_.isEmpty(riskModel.risk.aggregatedEntityIds),
                        new RiskDefinitionSection(
                            detailsSection.instances,
                            localization.sections.details.instances())).
                    concat(
                        new RiskDefinitionSection(
                            detailsSection.userData,
                            localization.sections.details.userData())).
                    value()
        });
}