import { DataTableColumn, DataTableColumnRenderProps, useLocalization } from "@infrastructure";
import React from "react";
import { useOciCommonIamResourceDefinition } from ".";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, PagedEntityFilter, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";

export function useOciCommonIamPrincipalDefinition(definitionData: DefinitionData) {
    const commonIamResourceDefinition = useOciCommonIamResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.oci.useOciCommonIamPrincipalDefinition",
            () => ({
                columns: {
                    domainId: "Domain"
                }
            }));
    return {
        columns: {
            ...commonIamResourceDefinition.columns,
            domainIdColumn:
                <DataTableColumn
                    exportOptions={
                        entitiesExportOptions<Contract.OciIamPrincipalModel>(
                            Contract.TypeNames.OciIamDomain,
                            item => [(item.entity as Contract.OciIamPrincipal).domainId],
                            localization.columns.domainId())}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedEntityFilter
                                    getEntityIdPage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.OciIamPrincipalDomain)}
                                    placeholder={localization.columns.domainId()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.OciIamPrincipalDomain}
                    key={Contract.EntityModelProperty.OciIamPrincipalDomain}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.OciIamPrincipalModel>) =>
                            <EntitiesCell
                                entityIdsOrModels={(item.entity as Contract.OciIamPrincipal).domainId}
                                entityTypeName={Contract.TypeNames.OciIamDomain}/>}
                    title={localization.columns.domainId()}/>
        }
    };
}