import { DataTableColumnRenderProps, NotValidIcon, SuccessIcon } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract, useTheme } from "../../../../../../../../../../../../../../../common";
import { useSplunkEventCollectorIssueTranslator } from "../../../hooks";

export function StatusCell({ item }: DataTableColumnRenderProps<Contract.ScopeSystemEntityModel>) {
    const eventCollectorIssueTranslator = useSplunkEventCollectorIssueTranslator();
    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            direction="row"
            spacing={1}>
            {_.isNil((item?.state as Contract.SplunkEventCollectorState)?.issue)
                ? <SuccessIcon
                    sx={{
                        color: theme.palette.success.main,
                        fontSize: "18px"
                    }}/>
                : <NotValidIcon
                    sx={{
                        color: theme.palette.error.main,
                        fontSize: "18px"
                    }}/>}
            <Typography>
                {eventCollectorIssueTranslator((item.state as Contract.SplunkEventCollectorState)?.issue)}
            </Typography>
        </Stack>);
}