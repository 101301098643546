import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function AlmaIcon(props: SvgIconProps) {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <g clipPath="url(#clip0_52885_254663)">
                <path
                    d="M22.0671 13.5533C23.0896 13.4729 23.9154 14.156 23.9941 15.2007C24.0727 16.2855 23.3255 17.1695 22.303 17.2498C21.3199 17.3302 20.4547 16.5668 20.3761 15.5623C20.2974 14.4774 21.0053 13.6738 22.0671 13.5533Z"
                    fill="#86DA2F"/>
                <path
                    d="M9.64062 22.1115C9.64062 21.0668 10.4271 20.2632 11.371 20.2632C12.3148 20.2632 13.2193 21.1471 13.2193 22.1516C13.2193 23.116 12.4328 23.9597 11.5283 23.9999C10.3878 23.9999 9.64062 23.2767 9.64062 22.1115Z"
                    fill="#24C2FF"/>
                <path
                    d="M12.4325 10.3791C12.2752 10.4595 12.1966 10.339 12.1573 10.2184C10.7022 7.446 11.1348 3.95035 13.691 1.86099C14.3595 1.29847 15.618 1.17793 16.1685 1.74045C16.4045 1.94135 16.4438 2.18243 16.4831 2.46369C16.5618 3.06639 16.6798 3.66909 17.073 4.15125C17.5056 4.67359 18.0562 4.87449 18.6854 4.83431C19.236 4.83431 19.7865 4.75395 20.1405 5.39683C20.3371 5.75845 20.2584 7.16474 19.9438 7.446C19.7865 7.56654 19.6685 7.48618 19.5506 7.446C18.6461 7.08438 17.7022 7.08438 16.7584 7.2451C16.4438 7.28528 16.2865 7.20492 16.2865 6.8433C16.2472 6.2406 16.1292 5.67809 15.8146 5.15575C15.2247 4.07089 14.1236 4.03071 13.4157 5.03521C12.8258 5.83881 12.6685 6.80312 12.5112 7.76744C12.3539 8.61122 12.3932 9.49518 12.4325 10.3791Z"
                    fill="#FFCB12"/>
                <path
                    d="M13.4159 10.9815C13.3373 10.861 13.3766 10.7404 13.4946 10.6601C15.7362 8.57071 19.0789 8.16891 21.6744 10.2181C22.3429 10.7806 22.7755 11.9458 22.4216 12.6289C22.2643 12.9101 22.0676 13.0307 21.8317 13.111C21.2811 13.3521 20.7699 13.5932 20.416 14.1155C20.062 14.6379 19.9834 15.2406 20.1407 15.8835C20.2587 16.4058 20.4553 16.9683 19.944 17.4505C19.6688 17.7317 18.3317 17.9728 17.9777 17.7317C17.8204 17.6112 17.8598 17.4907 17.8991 17.3299C18.0171 16.3254 17.7811 15.4013 17.4272 14.5173C17.3092 14.1959 17.3485 14.0352 17.6631 13.9548C18.2137 13.7941 18.7249 13.5128 19.1182 13.111C19.9834 12.2673 19.7867 11.1824 18.6463 10.7002C17.7418 10.2984 16.798 10.3788 15.8935 10.4592C15.0283 10.4993 14.2024 10.7404 13.4159 10.9815Z"
                    fill="#86DA2F"/>
                <path
                    d="M12.9048 12.0263C13.0228 11.9057 13.1014 11.9459 13.2194 12.0263C15.8149 13.6335 17.152 16.8077 15.9722 19.9015C15.6576 20.7051 14.6352 21.5087 13.888 21.3078C13.5734 21.2275 13.416 21.0668 13.2587 20.8659C12.9048 20.3837 12.5116 19.9417 11.9217 19.7408C11.2924 19.5399 10.7419 19.6605 10.1913 19.9819C9.7194 20.2632 9.24749 20.6248 8.6576 20.2632C8.30366 20.0623 7.71377 18.8167 7.83175 18.4149C7.9104 18.2542 8.06771 18.2542 8.22501 18.2542C9.20816 18.0934 9.99468 17.6113 10.7419 16.9684C10.9778 16.7675 11.1745 16.7675 11.3711 17.0488C11.6857 17.5309 12.079 17.9327 12.5902 18.214C13.6127 18.8167 14.5958 18.2943 14.7138 17.0889C14.8318 16.0844 14.4779 15.2005 14.1632 14.3165C13.8093 13.5129 13.3767 12.7495 12.9048 12.0263Z"
                    fill="#24C2FF"/>
                <path
                    d="M11.6458 12.2673C11.4885 13.0709 11.1346 13.8343 10.6627 14.5173C9.24694 16.6871 7.20199 17.571 4.68512 17.3299C3.78062 17.2496 3.03343 16.4862 2.95477 15.7227C2.91545 15.4013 2.9941 15.1602 3.19073 14.9191C3.46601 14.5575 3.70197 14.2361 3.81995 13.7941C4.0559 12.9101 3.74129 12.1869 3.11208 11.544C2.2469 10.6601 2.36488 9.85647 3.38736 9.21359C3.50534 9.13323 3.66264 9.05287 3.81995 8.97251C4.0559 8.85197 4.25253 8.85197 4.33118 9.13323C4.68512 10.0574 5.39299 10.7404 6.17951 11.3029C6.45479 11.544 6.45479 11.7047 6.21883 11.986C5.74692 12.5083 5.47164 13.1512 5.43231 13.8745C5.35366 14.7584 5.8649 15.3209 6.73007 15.3209C7.28064 15.3209 7.79188 15.12 8.26379 14.879C9.4829 14.2361 10.4267 13.3119 11.3312 12.3476C11.4492 12.3074 11.4885 12.2271 11.6458 12.2673Z"
                    fill="#0069DA"/>
                <path
                    d="M5.19673 3.82972C5.27538 3.82972 5.47201 3.8699 5.66864 3.91008C7.12371 4.19134 8.02821 3.669 8.50012 2.2627C8.81473 1.33857 9.48327 1.05731 10.3484 1.53947C10.3878 1.53947 10.3878 1.57965 10.4271 1.57965C11.3316 2.10199 11.3316 2.18235 10.781 2.98594C10.3091 3.62882 10.0732 4.35206 9.95518 5.11548C9.87653 5.55746 9.71923 5.63782 9.32597 5.4771C8.69675 5.23602 8.02821 5.23602 7.35966 5.4771C6.61247 5.71818 6.29786 6.40124 6.53382 7.16466C6.84843 8.16916 7.7136 8.61114 8.46079 9.13348C9.20799 9.65582 10.0732 9.93708 10.899 10.2987C11.017 10.3389 11.2136 10.3389 11.1743 10.5398C11.135 10.6603 10.9777 10.6603 10.8204 10.6603C9.05068 10.7407 7.35966 10.4594 5.98325 9.25402C4.68549 8.16916 3.74167 6.84322 3.89897 4.99494C4.01695 4.39224 4.44954 3.95026 5.19673 3.82972Z"
                    fill="#FF4649"/>
                <path
                    d="M1.97174 15.1203C1.02791 15.2409 0.0840871 14.4373 0.00543497 13.4328C-0.0732171 12.4685 0.713304 11.5041 1.6178 11.4238C2.64028 11.3032 3.5841 12.0265 3.66276 12.9908C3.70208 13.9149 3.11219 15.04 1.97174 15.1203Z"
                    fill="#0069DA"/>
                <path
                    d="M18.4887 0.816092C19.4719 0.735732 20.4157 1.53933 20.4943 2.54383C20.573 3.50815 19.7865 4.43229 18.8426 4.51265C17.8202 4.59301 16.9157 3.82959 16.837 2.82509C16.7584 1.82059 17.4662 0.896452 18.4887 0.816092Z"
                    fill="#FFCB12"/>
                <path
                    d="M8.2249 1.57959C8.34288 2.62426 7.63501 3.50822 6.57321 3.66894C5.66871 3.78948 4.72488 3.02606 4.6069 2.1421C4.48893 0.976886 5.11814 0.133107 6.17995 0.0125673C7.1631 -0.107973 8.10692 0.655447 8.2249 1.57959Z"
                    fill="#FF4649"/>
            </g>
            <defs>
                <clipPath id="clip0_52885_254663">
                    <rect
                        fill="white"
                        height="24"
                        width="24"/>
                </clipPath>
            </defs>
        </SvgIcon>);
}