﻿import { FormLayout, makeContextProvider, Optional, OrderedWizard, OrderedWizardItem, useExecuteOperation, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useCodePipelineContext, useSetCodePipelineContext } from "../..";
import { ConfigurationController, Contract } from "../../../../../../../../../../../../../../common";
import { useCodePipelineTypeTranslator } from "../../../../../../../../../../../../../../tenants";
import { ApiKeyItem, FinishItem, PipelineItem } from "./components";
import { useDefinition } from "./hooks";

export class AddOrEditContext {
    public pipelineName: string;

    constructor(
        public apiRootUrl: string,
        public pipelineType: Contract.CodePipelineType,
        public terraformCloudScanApiUrl: string,
        public apiKeyToken?: Optional<string>,
        public pipelineConfiguration?: Contract.CodePipelineConfiguration) {
        this.pipelineName = pipelineConfiguration?.name ?? "";
    }
}

export const [useAddOrEditContext, useSetAddOrEditContext, useAddOrEditContextProvider] = makeContextProvider<AddOrEditContext>();

export type AddOrEditProps = {
    pipelineType: Contract.CodePipelineType;
};

export function AddOrEdit({ pipelineType }: AddOrEditProps) {
    const { addOrEditOpen } = useCodePipelineContext();
    const setCodePipelineContext = useSetCodePipelineContext();

    const [{ apiRootUrl, terraformCloudScanApiUrl }] =
        useExecuteOperation(
            [AddOrEdit, pipelineType],
            async () => {
                const { apiRootUrl } = await ConfigurationController.getCodePipelineApiRootUrl();
                const { scanApiUrl } = await ConfigurationController.getTerraformCloudScanApiUrl();

                return {
                    apiRootUrl,
                    terraformCloudScanApiUrl: scanApiUrl
                };
            });

    const [, , ContextProvider] =
        useAddOrEditContextProvider(
            () =>
                new AddOrEditContext(
                    apiRootUrl,
                    pipelineType,
                    terraformCloudScanApiUrl,
                    undefined,
                    _.isBoolean(addOrEditOpen)
                        ? undefined
                        : addOrEditOpen));

    const definition = useDefinition(pipelineType);
    const pipelineTypeTranslator = useCodePipelineTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCodePipelineItems.codePipeline.addOrEdit",
            () => ({
                steps: {
                    apiKey: "Store API Token",
                    pipeline: "Create API Token"
                },
                title: {
                    add: "Add {{translatePipelineType}}",
                    edit: "Edit {{translatePipelineType}}"
                }
            }));

    return (
        <ContextProvider>
            <FormLayout
                disableContentPadding={true}
                titleOptions={{
                    text:
                        addOrEditOpen !== true
                            ? localization.title.edit({ translatePipelineType: pipelineTypeTranslator(pipelineType, "configuration") })
                            : localization.title.add({ translatePipelineType: pipelineTypeTranslator(pipelineType, "configuration") })
                }}>
                <OrderedWizard
                    finishItemElement={<FinishItem/>}
                    onClose={
                        () => {
                            setCodePipelineContext(
                                codePipelineContext => ({
                                    ...codePipelineContext,
                                    addOrEditOpen: false
                                }));
                        }}>
                    <OrderedWizardItem
                        deferredLoading={true}
                        title={localization.steps.pipeline()}>
                        <PipelineItem/>
                    </OrderedWizardItem>
                    {(definition.apiKeyItem) &&
                        <OrderedWizardItem title={localization.steps.apiKey()}>
                            <ApiKeyItem/>
                        </OrderedWizardItem>}
                    {definition.orderedWizardItems}
                </OrderedWizard>
            </FormLayout>
        </ContextProvider>);
}