import { NoneIcon, Tooltip } from "@infrastructure";
import { Box, Divider, Stack, SxProps, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract, useSeverityTranslator, useTheme } from "..";

type SeverityCountProps = {
    borderColor?: string;
    count?: string | number;
    disabled?: boolean;
    severity?: Contract.Severity;
    sx?: SxProps;
    variant?: "count" | "initialAndCount";
};

export function SeverityCount({ borderColor, count, disabled, severity, sx, variant = "count" }: SeverityCountProps) {
    const severityTranslator = useSeverityTranslator();
    const theme = useTheme();
    return (
        <Tooltip titleOrGetTitle={severityTranslator(severity)}>
            <Box
                sx={{
                    border: theme.border.primary,
                    borderColor: borderColor ?? theme.palette.severity(severity),
                    borderRadius: theme.spacing(0.75),
                    padding: theme.spacing(0.25, 0.5),
                    width:
                        variant === "initialAndCount"
                            ? theme.spacing(7.5)
                            : theme.spacing(5.5),
                    ...sx,
                    ...(disabled && {
                        borderColor: theme.palette.severity(undefined),
                        opacity: 0.5
                    })
                }}>
                {_.isNil(count)
                    ? <NoneIcon sx={{ color: theme.palette.text.secondary }}/>
                    : <Stack
                        alignItems="center"
                        direction="row">
                        {variant === "initialAndCount" &&
                            <Stack
                                alignItems="center"
                                direction="row">
                                <Typography>
                                    {severityTranslator(severity, "initial")}
                                </Typography>
                                <Divider
                                    flexItem={true}
                                    orientation="vertical"
                                    style={{
                                        borderColor:
                                            disabled
                                                ? theme.palette.text.disabled
                                                : (borderColor ?? theme.palette.severity(severity)),
                                        margin: theme.spacing(0.25, 0.5)
                                    }}/>
                            </Stack>}
                        <Typography
                            sx={{
                                textAlign: "center",
                                width: "fill-available"
                            }}>
                            {count}
                        </Typography>
                    </Stack>}
            </Box>
        </Tooltip>);
}