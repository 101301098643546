﻿import { Message } from "@infrastructure";
import { Box, FormControlLabel, Radio, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { ReactNode } from "react";
import { useTheme } from "../themes";

type RadioFieldProps = {
    checked: boolean;
    children?: ReactNode;
    helpText?: string;
    indent?: boolean;
    onSelected: () => void;
    title: ReactNode;
};

export function RadioField({ checked, children = undefined, helpText = undefined, indent = true, onSelected, title }: RadioFieldProps) {
    const theme = useTheme();
    return (
        <Stack spacing={2}>
            <FormControlLabel
                checked={checked}
                control={<Radio sx={{ padding: theme.spacing(0, 1) }}/>}
                label={
                    _.isNil(helpText)
                        ? title
                        : <Stack direction="row">
                            <Typography sx={{ marginRight: theme.spacing(2.5) }}>
                                {title}
                            </Typography>
                            <Message
                                level="info"
                                title={
                                    <Typography sx={{ whiteSpace: "pre-wrap" }}>
                                        {helpText}
                                    </Typography>}
                                variant="minimal"/>
                        </Stack>}
                onChange={() => onSelected()}/>
            {children &&
                <Box
                    sx={{
                        display:
                            checked
                                ? "unset"
                                : "none",
                        paddingLeft:
                            indent
                                ? theme.spacing(2.5)
                                : undefined
                    }}>
                    {children}
                </Box>}
        </Stack>);
}