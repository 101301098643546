import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { useAzureCognitiveServicesAccountTypeTranslator } from "../../../../../../../../../Entities/hooks";
import { useGetAzureCognitiveServicesAccountRiskContext } from "../contexts";

export function useAzureCognitiveServicesAccountSharedKeyAccessEnabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AzureCognitiveServicesAccountSharedKeyAccessEnabledRisk;
    const accountModel = entityModelStore.useGet(risk.entityId) as Contract.AzureCognitiveServicesAccountModel;
    const account = accountModel.entity as Contract.AzureCognitiveServicesAccount;

    const getAzureCognitiveServicesAccountRiskContext = useGetAzureCognitiveServicesAccountRiskContext();

    const cognitiveServicesAccountTypeTranslator = useAzureCognitiveServicesAccountTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.compliance.useAzureCognitiveServicesAccountSharedKeyAccessEnabledRiskDefinition",
            () => ({
                description: "{{type}} {{account}} has local authentication enable",
                sections: {
                    resolution: {
                        step1: "Disabling local authentication is not supported via the Azure console. Run the following command from Azure PowerShell: Set-AzCognitiveServicesAccount -ResourceGroupName {{resourceGroupName}} -Name {{accountName}} -DisableLocalAuth $true"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            account:
                <Entity
                    entityIdOrModel={accountModel}
                    variant="text"/>,
            type: cognitiveServicesAccountTypeTranslator(account.type)
        }),
        () => [
            localization.sections.resolution.step1({
                accountName: account.name,
                resourceGroupName:
                    <Entity
                        entityIdOrModel={account.resourceGroupId}
                        glanceOptions={{ disabled: true }}
                        linkOptions={{
                            disabled: true,
                            disabledHighlight: false
                        }}
                        variant="text"/>
            })
        ],
        riskModel,
        () => {
            const accountRiskContext = getAzureCognitiveServicesAccountRiskContext(accountModel);
            return [
                accountRiskContext.generalInformation,
                accountRiskContext.sensitive,
                accountRiskContext.type,
                accountRiskContext.inboundExternalAccessScope,
                accountRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}