﻿import { DataTableColumn, EnumValuesFilter, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { useAzureCommonEncryptedResourceDefinition, useAzureCommonResourceGroupResourceDefinition } from ".";
import { Contract, ItemSelectionHelper, TimeRangeHelper } from "../../../../../../../../../../common";
import { useAzureCognitiveServicesAccountTypeTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";
import { EntityTableDefinition } from "../../useDefinition";
import { useCommonNetworkAccessResourceDefinition } from "../useCommonNetworkAccessResourceDefinition";

export function useAzureCognitiveServicesAccountDefinition(definitionData: DefinitionData) {
    const commonEncryptedResourceDefinition = useAzureCommonEncryptedResourceDefinition(definitionData);
    const commonNetworkAccessResourceDefinition = useCommonNetworkAccessResourceDefinition();
    const commonResourceGroupResourceDefinition = useAzureCommonResourceGroupResourceDefinition(definitionData);

    const cognitiveServicesAccountTypeTranslator = useAzureCognitiveServicesAccountTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.azure.useAzureCognitiveServicesAccountDefinition",
            () => ({
                columns: {
                    restrictOutboundAccess: {
                        false: "Disabled",
                        title: "Restrict Outbound Network Access",
                        true: "Enabled"
                    },
                    type: "Kind"
                }
            }));

    return new EntityTableDefinition(
        _.filter([
            commonResourceGroupResourceDefinition.columns.tenantColumn,
            commonResourceGroupResourceDefinition.columns.creationTimeColumn,
            commonResourceGroupResourceDefinition.columns.creatorIdentityCsvColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AzureCognitiveServicesAccountModel) => ({
                            [localization.columns.type()]: cognitiveServicesAccountTypeTranslator((item.entity as Contract.AzureCognitiveServicesAccount).type)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.AzureCognitiveServicesAccountType}
                                enumTypeTranslator={cognitiveServicesAccountTypeTranslator}
                                placeholder={localization.columns.type()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AzureCognitiveServicesAccountType}
                itemProperty={(item: Contract.AzureCognitiveServicesAccountModel) => cognitiveServicesAccountTypeTranslator((item.entity as Contract.AzureCognitiveServicesAccount).type)}
                key={Contract.EntityModelProperty.AzureCognitiveServicesAccountType}
                title={localization.columns.type()}/>,
            commonNetworkAccessResourceDefinition.columns.accessLevelColumn,
            commonNetworkAccessResourceDefinition.columns.inboundExternalAccessScopeColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AzureCognitiveServicesAccountModel) => ({
                            [localization.columns.restrictOutboundAccess.title()]:
                                (item.entity as Contract.AzureCognitiveServicesAccount).restrictOutboundAccess
                                    ? localization.columns.restrictOutboundAccess.true()
                                    : localization.columns.restrictOutboundAccess.false()
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <ValuesFilter
                                placeholder={localization.columns.restrictOutboundAccess.title()}
                                sorted={false}>
                                <ValuesFilterItem
                                    title={localization.columns.restrictOutboundAccess.true()}
                                    value={true}/>
                                <ValuesFilterItem
                                    title={localization.columns.restrictOutboundAccess.false()}
                                    value={false}/>
                            </ValuesFilter>
                    }
                }}
                id={Contract.EntityModelProperty.AzureCognitiveServicesAccountRestrictOutboundAccess}
                itemProperty={
                    (item: Contract.AzureCognitiveServicesAccountModel) =>
                        (item.entity as Contract.AzureCognitiveServicesAccount).restrictOutboundAccess
                            ? localization.columns.restrictOutboundAccess.true()
                            : localization.columns.restrictOutboundAccess.false()}
                key={Contract.EntityModelProperty.AzureCognitiveServicesAccountRestrictOutboundAccess}
                title={localization.columns.restrictOutboundAccess.title()}/>,
            commonEncryptedResourceDefinition.columns.encryptionExistsColumn,
            commonEncryptedResourceDefinition.columns.vaultKeyColumn,
            commonResourceGroupResourceDefinition.columns.regionColumn,
            commonResourceGroupResourceDefinition.columns.regionLocationColumn,
            commonResourceGroupResourceDefinition.columns.resourceGroupColumn,
            commonResourceGroupResourceDefinition.columns.tagsColumn,
            commonResourceGroupResourceDefinition.columns.attributesColumn,
            commonResourceGroupResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonResourceGroupResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonResourceGroupResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ]),
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAzureCognitiveServicesAccountRequest(
                new Contract.EntityControllerGetEntityModelPageAzureCognitiveServicesAccountRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureResourceGroupResourceResourceGroup]),
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureCognitiveServicesAccountRestrictOutboundAccess]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureCognitiveServicesAccountType])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags])),
                new Contract.EntityControllerGetEntityModelPageAzureResourceRequestEncrypted(
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureEncryptedResourceEncryptionExists]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureEncryptedResourceVaultKeys])),
                new Contract.EntityControllerGetEntityModelPageEntityRequestNetworkAccess(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.NetworkInboundAccessType]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.NetworkInboundExternalAccessScope]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.NetworkInboundExternalDestinationNetworkScopes]))));

}