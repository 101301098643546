import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function CopyToClipboardIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                clipRule="evenodd"
                d="M9.73556 7.12942C8.7459 7.25414 8.22232 7.48363 7.85531 7.8277C7.49749 8.16315 7.26508 8.62885 7.1363 9.52708C7.00241 10.461 7 11.7023 7 13.5C7 15.2981 7.00241 17.5394 7.1363 18.4732C7.26506 19.3712 7.49744 19.8368 7.85531 20.1723C8.22228 20.5163 8.74602 20.7458 9.73586 20.8706C10.7481 20.9981 12.088 21 14 21C15.9124 21 17.2523 20.9981 18.2644 20.8706C19.2541 20.7459 19.7777 20.5164 20.1447 20.1723C20.5025 19.8369 20.7349 19.3711 20.8637 18.4729C20.9976 17.539 21 15.2977 21 13.5C21 11.7019 20.9976 10.4606 20.8637 9.5268C20.7349 8.62875 20.5026 8.1632 20.1447 7.8277C19.7777 7.48367 19.254 7.25415 18.2641 7.12943C17.2519 7.00187 15.912 7 14 7C12.0876 7 10.7477 7.00187 9.73556 7.12942ZM13.9308 5H14.0692C15.8959 4.99998 17.3622 4.99996 18.5142 5.14512C19.7031 5.29493 20.7082 5.61449 21.5126 6.36862C22.3261 7.13128 22.6794 8.09862 22.8435 9.24294C23.0001 10.3352 23 11.7205 23 13.4233V13.5767C23 15.2791 23.0001 17.6644 22.8435 18.7568C22.6794 19.9011 22.3261 20.8687 21.5126 21.6314C20.7082 22.3855 19.7034 22.7051 18.5145 22.8549C17.3626 23 15.8964 23 14.0692 23H13.9308C12.1041 23 10.6378 23 9.48582 22.8549C8.29693 22.7051 7.29183 22.3855 6.48743 21.6314C5.67393 20.8687 5.32062 19.9014 5.15655 18.7571C4.99994 17.6648 4.99997 15.2795 5 13.5767V13.4233C4.99997 11.7209 4.99994 10.3356 5.15654 9.24325C5.32061 8.09891 5.67388 7.13132 6.48743 6.36862C7.29179 5.61453 8.29663 5.29494 9.4855 5.14512C10.6374 4.99996 12.1037 4.99998 13.9308 5Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M6.42073 3.16496C5.2381 3.32393 4.57682 3.6194 4.09811 4.09811C3.61945 4.57677 3.32396 5.23824 3.16497 6.42109C3.00213 7.63261 3.00001 9.23274 3.00001 11.5C3.00001 14.2772 3.00617 16.0351 3.28824 17.2788C3.41039 17.8174 3.07278 18.3531 2.53417 18.4752C1.99556 18.5974 1.45992 18.2598 1.33777 17.7212C0.999819 16.231 0.999899 14.2419 1 11.6386C1.00001 11.5926 1.00001 11.5464 1.00001 11.5L1.00001 11.4256C0.999983 9.24975 0.999965 7.51492 1.18279 6.15466C1.37156 4.75026 1.77157 3.59623 2.6839 2.68389C3.59619 1.7716 4.74992 1.37157 6.15428 1.18279C7.51442 0.999958 9.24925 0.999977 11.4256 1L11.5626 1C13.6284 0.999985 15.294 0.999973 16.6158 1.15402C17.1643 1.21796 17.5572 1.7145 17.4933 2.26307C17.4293 2.81164 16.9328 3.20451 16.3842 3.14058C15.1917 3.00158 13.6423 3 11.5 3C9.23224 3 7.63212 3.00212 6.42073 3.16496Z"
                fillRule="evenodd"/>
        </SvgIcon>);
}