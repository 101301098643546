import { UnexpectedError } from "@infrastructure";
import { ReactNode, useMemo } from "react";
import { CustomerSideViewItemType, SideViewItem } from "../../SideView";
import { useAuditEventsDefinition, useCodeScanDefinition, useCodeScanRiskDefinition, useComplianceDefinition, useDocumentationDefinition, useEntityDefinition, useEventsDefinition, useKubernetesClusterAdmissionControllerEventsDefinition, useMaliciousFileDefinition, useRiskDefinition, useRiskPoliciesDefinition, useScopeDefinition, useVulnerabilityDefinition, useWorkloadAnalysisCodeScanDefinition } from "./hooks";

export function useDefinition(item: SideViewItem): SideViewDefinition {
    const useDefinition =
        useMemo(
            () => {
                switch (item.type) {
                    case CustomerSideViewItemType.AuditEvent:
                        return useAuditEventsDefinition;
                    case CustomerSideViewItemType.CodeScan:
                        return useCodeScanDefinition;
                    case CustomerSideViewItemType.CodeScanRisk:
                        return useCodeScanRiskDefinition;
                    case CustomerSideViewItemType.Compliance:
                        return useComplianceDefinition;
                    case CustomerSideViewItemType.Documentation:
                        return useDocumentationDefinition;
                    case CustomerSideViewItemType.Entity:
                        return useEntityDefinition;
                    case CustomerSideViewItemType.Event:
                        return useEventsDefinition;
                    case CustomerSideViewItemType.KubernetesClusterAdmissionControllerEvent:
                        return useKubernetesClusterAdmissionControllerEventsDefinition;
                    case CustomerSideViewItemType.MaliciousFile:
                        return useMaliciousFileDefinition;
                    case CustomerSideViewItemType.Risk:
                        return useRiskDefinition;
                    case CustomerSideViewItemType.RiskPolicies:
                        return useRiskPoliciesDefinition;
                    case CustomerSideViewItemType.Scope:
                        return useScopeDefinition;
                    case CustomerSideViewItemType.Vulnerability:
                        return useVulnerabilityDefinition;
                    case CustomerSideViewItemType.WorkloadAnalysisCodeScan:
                        return useWorkloadAnalysisCodeScanDefinition;
                    default:
                        throw new UnexpectedError("item.type", item.type);
                }
            },
            []);

    return useDefinition(item);
}

export type SideViewDefinition = {
    getActionsElement: () => ReactNode;
    getViewElement: () => ReactNode;
};