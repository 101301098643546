import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { Events } from "../../../../../../../../../Events";
import { useCommonCustomSectionsAndDescriptionDefinition } from "../../../useCommonCustomSectionsAndDescriptionDefinition";

export function useAwsKmsKeyManagementRiskDefinition(riskModel: Contract.RiskModel) {
    const keyManagementRiskModel = riskModel as Contract.AwsKmsKeyManagementRiskModel;
    const keyModel = entityModelStore.useGet(_.head(keyManagementRiskModel.risk.resourceIds)!);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.custom.useAwsKmsKeyManagementRiskDefinition",
            () => ({
                violations: {
                    [Contract.TypeNames.AwsKmsKeyManagementRiskModelDescriptionType]: {
                        [Contract.AwsKmsKeyManagementRiskModelDescriptionType.Grant]: "{{key}} grant was updated",
                        [Contract.AwsKmsKeyManagementRiskModelDescriptionType.GrantPolicy]: "{{key}} policy and grant were updated",
                        [Contract.AwsKmsKeyManagementRiskModelDescriptionType.Policy]: "{{key}} policy was updated"
                    }
                }
            }));
    const translatedViolations =
        localization.violations[Contract.TypeNames.AwsKmsKeyManagementRiskModelDescriptionType][keyManagementRiskModel.descriptionType]({
            key:
                <Entity
                    entityIdOrModel={keyModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        });
    return useCommonCustomSectionsAndDescriptionDefinition(
        translatedViolations,
        riskModel,
        "violations",
        <Events riskId={keyManagementRiskModel.risk.id}/>);
}