import { DownloadIcon, TextViewer, useBlobUrl, useLocalization } from "@infrastructure";
import { Box, Divider, Stack, Typography, useTheme } from "@mui/material";
import React from "react";

export type DocumentViewerProps = {
    copyToClipboard?: boolean;
    dense?: boolean;
    document: string;
    documentFileName: string;
    title?: string;
};

export function DocumentViewer({ copyToClipboard = false, dense = false, document, documentFileName, title }: DocumentViewerProps) {
    const localization =
        useLocalization(
            "common.documentViewer",
            () => ({
                info: "Download {{documentFileName}} file"
            }));
    const documentFileUrl =
        useBlobUrl(
            document,
            "application/json");
    const theme = useTheme();
    return (
        <Stack
            spacing={1}
            sx={{
                height: "100%",
                padding:
                    dense
                        ? theme.spacing(1.5, 3)
                        : undefined
            }}>
            <Stack
                direction="row"
                spacing={2}
                sx={{
                    alignItems: "center",
                    paddingRight:
                        dense
                            ? theme.spacing(3)
                            : undefined
                }}>
                <Typography
                    sx={{ flex: 1 }}
                    variant="h3">
                    {title ?? localization.info({ documentFileName })}
                </Typography>
                <Box
                    sx={{
                        alignItems: "center",
                        border: theme.border.primary,
                        borderRadius: theme.spacing(0.75),
                        display: "flex",
                        height: theme.spacing(4),
                        justifyContent: "center",
                        width: theme.spacing(4)
                    }}>
                    <a
                        download={documentFileName}
                        href={documentFileUrl}
                        title={localization.info({ documentFileName })}>
                        <DownloadIcon
                            sx={{
                                color: theme.palette.text.primary,
                                fontSize: "24px"
                            }}/>
                    </a>
                </Box>
                <Divider
                    flexItem={true}
                    orientation="vertical"
                    style={{
                        marginBottom: theme.spacing(0.5),
                        marginTop: theme.spacing(0.5)
                    }}
                    variant="middle"/>
            </Stack>
            <Box sx={{ flex: 1 }}>
                <TextViewer
                    copyToClipboard={copyToClipboard}
                    format="json"
                    height="100%"
                    text={document}/>
            </Box>
        </Stack>);
}