import { EditIcon, NotValidIcon, SuccessIcon, Tooltip } from "@infrastructure";
import { Box, Typography } from "@mui/material";
import _ from "lodash";
import React, { Fragment } from "react";
import { useTheme } from "../../../../../../../../../../../../../../../../../common";
import { SyncOptionType, useAddOrEditProjectContext } from "../../../AddOrEditProject";
import { riskStatuses } from "../SyncIssueStatusesItem";

type IssueTypeItemProps = {
    issueTypeName: string;
    syncOptionType: SyncOptionType;
};

export function IssueTypeItem({ issueTypeName, syncOptionType }: IssueTypeItemProps) {
    const theme = useTheme();
    return (
        <Box
            sx={{
                alignItems: "center",
                display: "flex",
                textAlign: "left",
                width: theme.spacing(12.5)
            }}>
            <Tooltip
                disableInteractive={true}
                enterDelay={1000}
                enterNextDelay={1000}
                titleOrGetTitle={issueTypeName}>
                <Typography
                    sx={{
                        flexGrow: 1,
                        marginRight: theme.spacing(1),
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap"
                    }}>
                    {issueTypeName}
                </Typography>
            </Tooltip>
            <IssueTypeIndication
                issueTypeName={issueTypeName}
                syncOptionType={syncOptionType}/>
        </Box>);
}


type IssueTypeIndicationProps = {
    issueTypeName: string;
    syncOptionType: SyncOptionType;
};

function IssueTypeIndication({ issueTypeName, syncOptionType }: IssueTypeIndicationProps) {
    const { issueTypeNameToRiskStatusToJiraStatusErrorMap, issueTypeNameToRiskStatusToJiraStatusMap } = useAddOrEditProjectContext();

    const theme = useTheme();
    if (!_.isNil(issueTypeNameToRiskStatusToJiraStatusErrorMap?.[issueTypeName])) {
        return (
            <NotValidIcon
                sx={{
                    color: theme.palette.error.main,
                    fontSize: "16px"
                }}/>);
    }

    if (syncOptionType === SyncOptionType.None) {
        return <Fragment/>;
    }

    const riskStatusToJiraStatusMap = issueTypeNameToRiskStatusToJiraStatusMap?.[issueTypeName];
    if (_.every(
        riskStatuses,
        riskStatus => !_.isNil(riskStatusToJiraStatusMap?.[riskStatus]))) {
        return (
            <SuccessIcon
                sx={{
                    color: theme.palette.success.main,
                    fontSize: "16px"
                }}/>);
    }

    if (_.some(
        riskStatuses,
        riskStatus => _.isNil(riskStatusToJiraStatusMap?.[riskStatus]))) {
        return (
            <Box
                sx={{
                    color: theme.palette.error.main,
                    fontSize: "16px"
                }}>
                <EditIcon/>
            </Box>);
    }

    return <Fragment/>;
}