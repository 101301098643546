﻿import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function GcpCloudShellIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path d="M21 3H3C1.9 3 1 3.9 1 5V19C1 20.1 1.9 21 3 21H21C22.1 21 23 20.1 23 19V5C23 3.9 22.1 3 21 3ZM21 19.01H3V4.99H21V19.01Z"/>
            <path d="M7.87574 9H5.72426C5.45699 9 5.32314 9.32314 5.51213 9.51213L8.78787 12.7879C8.90503 12.905 8.90503 13.095 8.78787 13.2121L5.51213 16.4879C5.32314 16.6769 5.45699 17 5.72426 17H7.87574C7.9553 17 8.03161 16.9684 8.08787 16.9121L11.7879 13.2121C11.905 13.095 11.905 12.905 11.7879 12.7879L8.08787 9.08787C8.03161 9.03161 7.9553 9 7.87574 9Z"/>
            <path
                clipRule="evenodd"
                d="M5.51213 9.51213C5.32314 9.32314 5.45699 9 5.72426 9H7.87574C7.9553 9 8.03161 9.03161 8.08787 9.08787L11.7879 12.7879C11.905 12.905 11.905 13.095 11.7879 13.2121L8.08787 16.9121C8.03161 16.9684 7.9553 17 7.87574 17H5.72426C5.45699 17 5.32314 16.6769 5.51213 16.4879L8.78787 13.2121C8.90503 13.095 8.90503 12.905 8.78787 12.7879L5.51213 9.51213ZM7.41421 10L9.49497 12.0808C10.0027 12.5884 10.0027 13.4116 9.49497 13.9192L7.41421 16H7.58579L10.5858 13L7.58579 10H7.41421Z"
                fillRule="evenodd"/>
            <path
                d="M12 16.7V15.3C12 15.1343 12.1343 15 12.3 15H18.7C18.8657 15 19 15.1343 19 15.3V16.7C19 16.8657 18.8657 17 18.7 17H12.3C12.1343 17 12 16.8657 12 16.7Z"
                fill="black"/>
            <path
                clipRule="evenodd"
                d="M12 15.3V16.7C12 16.8657 12.1343 17 12.3 17H18.7C18.8657 17 19 16.8657 19 16.7V15.3C19 15.1343 18.8657 15 18.7 15H12.3C12.1343 15 12 15.1343 12 15.3Z"
                fillRule="evenodd"/>
        </SvgIcon>);
}