﻿import { OrderedWizardItem, useLocalization } from "@infrastructure";
import React, { useMemo } from "react";
import { ScannerCommand, ScannerItem } from "../../components";
import { InstallItem, ScanContainerImageTaskItem, ScanIacTaskItem, SecretItem, SyncIacTaskItem } from "./components";

export function useAzurePipelines() {
    const commands =
        useMemo(
            () =>
                [
                    {
                        contentElement: <ScanContainerImageTaskItem/>,
                        type: ScannerCommand.ContainerImageScan
                    },
                    {
                        contentElement: <ScanIacTaskItem/>,
                        type: ScannerCommand.IacScan
                    },
                    {
                        contentElement: <SyncIacTaskItem/>,
                        type: ScannerCommand.IacSync
                    }
                ],
            []);

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCodePipelineItems.codePipeline.addOrEdit.hooks.useDefinition.hooks.useAzurePipelines",
            () => ({
                steps: {
                    install: "Install Extension",
                    scanner: "Add Tasks",
                    secret: "Add Variable (Recommended)"
                },
                title: "Add the relevant tasks to your Azure Pipelines:"
            }));
    return {
        apiKeyItem: true,
        orderedWizardItems: [
            <OrderedWizardItem
                key="installItem"
                title={localization.steps.install()}>
                <InstallItem/>
            </OrderedWizardItem>,
            <OrderedWizardItem
                key="secretItem"
                title={localization.steps.secret()}>
                <SecretItem/>
            </OrderedWizardItem>,
            <OrderedWizardItem
                key="scannerItem"
                title={localization.steps.scanner()}>
                <ScannerItem
                    commands={commands}
                    title={localization.title()}/>
            </OrderedWizardItem>
        ]
    };
}