import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useAwsSnsDeliveryProtocolTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useAwsSnsDeliveryProtocolTranslator",
            () => ({
                [Contract.TypeNames.AwsSnsDeliveryProtocol]: {
                    [Contract.AwsSnsDeliveryProtocol.Application]: "Application",
                    [Contract.AwsSnsDeliveryProtocol.Email]: "Email",
                    [Contract.AwsSnsDeliveryProtocol.EmailJson]: "Email-json",
                    [Contract.AwsSnsDeliveryProtocol.Firehose]: "Firehose",
                    [Contract.AwsSnsDeliveryProtocol.Http]: "HTTP",
                    [Contract.AwsSnsDeliveryProtocol.Https]: "HTTPS",
                    [Contract.AwsSnsDeliveryProtocol.Lambda]: "Lambda Function Configuration",
                    [Contract.AwsSnsDeliveryProtocol.Sms]: "SMS",
                    [Contract.AwsSnsDeliveryProtocol.Sns]: "SNS",
                    [Contract.AwsSnsDeliveryProtocol.Sqs]: "SQS"
                }
            }));
    return (protocol: Contract.AwsSnsDeliveryProtocol) =>
        localization[Contract.TypeNames.AwsSnsDeliveryProtocol][protocol]();
}