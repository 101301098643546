﻿import { DataTableColumn, optionalTableCell, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, TypeHelper, useScopeNavigationViewContext } from "../../../../../../../../../common";
import { ScopesCell } from "../../../components";
import { TableColumnId } from "../../../Table";

export function useCommonColumns() {
    const { scopeNodeModel } = useScopeNavigationViewContext();
    const localization =
        useLocalization(
            "views.user.permissionEligibilities.table.hooks.useDefinition.hooks.useCommonColumns",
            () => ({
                columns: {
                    permissionRequestScopeIds: {
                        export: {
                            all: "All existing and future",
                            some: [
                                "1 Scope",
                                "{{count | NumberFormatter.humanize}} Scopes"
                            ]
                        },
                        title: "Scopes"
                    }
                }
            }));
    return {
        permissionRequestScopeIds:
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (permissionEligibilityModel: Contract.PermissionAssignmentEligibilityModel) => ({
                            [localization.columns.permissionRequestScopeIds.title()]:
                                _.isEmpty(permissionEligibilityModel.permissionRequestScopeIds) ||
                                permissionEligibilityModel.permissionRequestScopeIds[0] === permissionEligibilityModel.configuration.scopeId ||
                                permissionEligibilityModel.permissionRequestScopeIds[0] === scopeNodeModel.configuration.id
                                    ? localization.columns.permissionRequestScopeIds.export.all()
                                    : localization.columns.permissionRequestScopeIds.export.some(permissionEligibilityModel.permissionRequestScopeIds.length)
                        })
                }}
                id={TableColumnId.PermissionRequestScopeIds}
                key={TableColumnId.PermissionRequestScopeIds}
                render={
                    optionalTableCell<Contract.PermissionEligibilityModel>(
                        item =>
                            TypeHelper.extendOrImplement(item.configuration.typeName, Contract.TypeNames.PermissionAssignmentEligibilityConfiguration)
                                ? <ScopesCell item={item as Contract.PermissionAssignmentEligibilityModel}/>
                                : undefined)}
                sortOptions={{ enabled: false }}
                title={localization.columns.permissionRequestScopeIds.title()}/>
    };
}