import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, PagedValuesFilter, useLocalization } from "@infrastructure";
import { Dictionary } from "lodash";
import React from "react";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, Entity, ItemSelectionHelper, PagedEntityFilter, SeverityFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { useGetVulnerabilitiesCsvItem } from "../../../../../../../../hooks";
import { VulnerabilitiesCell } from "../../../../../VulnerabilitiesCell";
import { DefinitionData } from "../../../../Table";
import { useGcpCommonEntityDefinition } from "./useGcpCommonEntityDefinition";

export function useGcpArtifactContainerImageRepositoryDefinition(definitionData: DefinitionData) {
    const entitiesExportOptions = useEntitiesExportOptions();
    const commonEntityDefinition = useGcpCommonEntityDefinition(definitionData);
    const getVulnerabilitiesCsvItem = useGetVulnerabilitiesCsvItem();

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.gcp.useGcpArtifactContainerImageRepositoryDefinition",
            () => ({
                columns: {
                    containerImageResources: "Used By",
                    containerImageVulnerabilities: "Vulnerabilities",
                    containerImageVulnerabilitySeverities: "Vulnerability Severity",
                    registry: "Registry"
                }
            }));

    return new EntityTableDefinition(
        [
            commonEntityDefinition.columns.tenantColumn,
            commonEntityDefinition.columns.creationTimeColumn,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.GcpArtifactContainerImageRepositoryModel>(
                        Contract.TypeNames.GcpArtifactRegistry,
                        item => [item.registrySearchableReference.id],
                        localization.columns.registry())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.GcpArtifactContainerImageRepositoryRegistry)}
                                placeholder={localization.columns.registry()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpArtifactContainerImageRepositoryRegistry}
                key={Contract.EntityModelProperty.GcpArtifactContainerImageRepositoryRegistry}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.GcpArtifactContainerImageRepositoryModel>) =>
                        <Entity
                            entityIdOrModel={item.registrySearchableReference.id}
                            variant="iconText"/>}
                title={localization.columns.registry()}/>,
            <DataTableColumn
                cellMinWidth={118}
                exportOptions={{
                    getItem:
                        (item: Contract.GcpArtifactContainerImageRepositoryModel) => ({
                            [localization.columns.containerImageVulnerabilities()]: getVulnerabilitiesCsvItem(item.containerImageVulnerabilities)
                        })
                }}
                filterOptions={{
                    itemOrItems: [
                        {
                            element:
                                <PagedValuesFilter
                                    getValuePage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.GcpArtifactContainerImageRepositoryContainerImageVulnerabilities)}
                                    placeholder={localization.columns.containerImageVulnerabilities()}/>,
                            id: Contract.EntityModelProperty.GcpArtifactContainerImageRepositoryContainerImageVulnerabilities,
                            title: localization.columns.containerImageVulnerabilities()
                        },
                        {
                            element:
                                <SeverityFilter placeholder={localization.columns.containerImageVulnerabilitySeverities()}/>,
                            id: Contract.EntityModelProperty.GcpArtifactContainerImageRepositoryContainerImageVulnerabilitySeverities,
                            title: localization.columns.containerImageVulnerabilitySeverities()
                        }
                    ]
                }}
                id={Contract.EntityModelProperty.GcpArtifactContainerImageRepositoryContainerImageVulnerabilities}
                key={Contract.EntityModelProperty.GcpArtifactContainerImageRepositoryContainerImageVulnerabilities}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.GcpArtifactContainerImageRepositoryModel>) =>
                        <VulnerabilitiesCell
                            containerImageScanStatus={item.containerImageScanStatus}
                            vulnerabilities={item.containerImageVulnerabilities}/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.containerImageVulnerabilities()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.GcpArtifactContainerImageRepositoryModel>(
                        Contract.TypeNames.IContainerImageResource,
                        item => item.containerImageResourceIds,
                        localization.columns.containerImageResources())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.GcpArtifactContainerImageRepositoryContainerImageResources)}
                                placeholder={localization.columns.containerImageResources()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpArtifactContainerImageRepositoryContainerImageResources}
                key={Contract.EntityModelProperty.GcpArtifactContainerImageRepositoryContainerImageResources}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.GcpArtifactContainerImageRepositoryModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.containerImageResourceIds}
                            entityTypeName={Contract.TypeNames.IContainerImageResource}
                            entityVariant="iconText"/>}
                title={localization.columns.containerImageResources()}/>,
            commonEntityDefinition.columns.regionColumn,
            commonEntityDefinition.columns.regionLocationColumn,
            commonEntityDefinition.columns.attributesColumn,
            commonEntityDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonEntityDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonEntityDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageGcpArtifactContainerImageRepositoryRequest(
                new Contract.EntityControllerGetEntityModelPageGcpArtifactContainerImageRepositoryRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpArtifactContainerImageRepositoryContainerImageResources]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpArtifactContainerImageRepositoryContainerImageVulnerabilities]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpArtifactContainerImageRepositoryContainerImageVulnerabilitySeverities]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpArtifactContainerImageRepositoryRegistry])),
                limit,
                skip,
                requestSort));
}