import { useLocalization } from "@infrastructure";
import React from "react";
import { useOciDefaultResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { useCustomEntityPropertyInfoItemElements } from "..";
import { Contract, InfoItem, useEntityTypeNameTranslator } from "../../../../../../../../../../common";
import { useOciNetworkingInternetGatewayStatusTranslator } from "../../../../../../hooks";
import { EntitiesInfoItem, Info } from "../../../../components";

export function useOciNetworkingInternetGatewayDefinition(resourceModel: Contract.OciResourceModel, options?: DefinitionOptions) {
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useOciDefaultResourceInfoItemElements(resourceModel);
    const internetGatewayModel = resourceModel as Contract.OciNetworkingInternetGatewayModel;
    const internetGateway = internetGatewayModel.entity as Contract.OciNetworkingInternetGateway;

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const internetGatewayStatusTranslator = useOciNetworkingInternetGatewayStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.oci.useOciNetworkingInternetGatewayDefinition",
            () => ({
                info: {
                    items: {
                        enabled: {
                            false: "Disabled",
                            title: "Status",
                            true: "Enabled"
                        },
                        status: "State"
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="status"
                        title={localization.info.items.status()}
                        value={internetGatewayStatusTranslator(internetGateway.status)}/>,
                    <InfoItem
                        key="enabled"
                        title={localization.info.items.enabled.title()}
                        value={
                            internetGateway.enabled
                                ? localization.info.items.enabled.true()
                                : localization.info.items.enabled.false()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={internetGatewayModel.vcnIdReference}
                        entityTypeName={Contract.TypeNames.OciNetworkingVcn}
                        key="vcn"
                        title={entityTypeNameTranslator(Contract.TypeNames.OciNetworkingVcn)}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={internetGatewayModel.routeTableIdReference}
                        entityTypeName={Contract.TypeNames.OciNetworkingRouteTable}
                        key="routeTable"
                        title={entityTypeNameTranslator(Contract.TypeNames.OciNetworkingRouteTable)}/>
                ]}
                options={options?.infoOptions}>
            </Info>
    });
}