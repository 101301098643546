import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function ComplianceIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                clipRule="evenodd"
                d="M11.9256 1L15 1C15.5523 1 16 1.44772 16 2C16 2.55229 15.5523 3 15 3H12C9.73274 3 8.13261 3.00212 6.92109 3.16496C5.73824 3.32395 5.07677 3.61944 4.59811 4.09811C4.1194 4.57682 3.82393 5.23809 3.66496 6.42072C3.50212 7.63212 3.5 9.23223 3.5 11.5C3.5 13.7673 3.50212 15.3674 3.66496 16.5789C3.82395 17.7618 4.11944 18.4232 4.59811 18.9019C5.07682 19.3806 5.73809 19.6761 6.92072 19.835C8.13212 19.9979 9.73223 20 12 20C14.2673 20 15.8674 19.9979 17.0789 19.835C18.2618 19.6761 18.9232 19.3806 19.4019 18.9019C19.8806 18.4232 20.1761 17.7619 20.335 16.5793C20.4979 15.3679 20.5 13.7678 20.5 11.5V9.5C20.5 8.94772 20.9477 8.5 21.5 8.5C22.0523 8.5 22.5 8.94772 22.5 9.5V11.5745C22.5 13.7508 22.5 15.4856 22.3172 16.8457C22.1284 18.2501 21.7284 19.4038 20.8161 20.3161C19.9038 21.2284 18.7497 21.6284 17.3453 21.8172C15.9851 22 14.2503 22 12.0744 22H11.9256C9.74925 22 8.01441 22 6.65428 21.8172C5.24991 21.6284 4.09619 21.2284 3.18389 20.3161C2.27156 19.4038 1.87155 18.2497 1.68279 16.8453C1.49996 15.4851 1.49998 13.7503 1.5 11.5744V11.4255C1.49998 9.24924 1.49996 7.51441 1.68279 6.15428C1.87157 4.74991 2.2716 3.59619 3.18389 2.68389C4.09623 1.77156 5.25026 1.37155 6.65466 1.18279C8.01492 0.999958 9.74975 0.999977 11.9256 1Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M21.669 2.25671C22.0795 2.62617 22.1128 3.25846 21.7433 3.66897L12.7433 13.669C12.5598 13.8729 12.3005 13.9924 12.0263 13.9997C11.752 14.0069 11.4869 13.9011 11.2929 13.7071L7.79289 10.2071C7.40237 9.81658 7.40237 9.18342 7.79289 8.79289C8.18342 8.40237 8.81658 8.40237 9.20711 8.79289L11.9618 11.5476L20.2567 2.33104C20.6262 1.92053 21.2585 1.88725 21.669 2.25671Z"
                fillRule="evenodd"/>
        </SvgIcon>);
}