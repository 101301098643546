import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useAwsBedrockCustomModelCustomizationTypeTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useAwsBedrockCustomModelCustomizationTypeTranslator",
            () => ({
                [Contract.TypeNames.AwsBedrockCustomModelCustomizationType]: {
                    [Contract.AwsBedrockCustomModelCustomizationType.FineTuning]: "Fine-tuning",
                    [Contract.AwsBedrockCustomModelCustomizationType.ContinuedPreTraining]: "Continued Pre-training"
                }
            }));
    return (customizationType: Contract.AwsBedrockCustomModelCustomizationType) =>
        localization[Contract.TypeNames.AwsBedrockCustomModelCustomizationType][customizationType]();
}