﻿import { useLocalization, ValueFilter } from "@infrastructure";
import React from "react";

export function ContainerImageUsageFilter() {
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.containerImageUsageFilter",
            () => ({
                empty: "Used and Unused",
                false: "Unused",
                title: "Usage",
                true: "Used"
            }));
    return (
        <ValueFilter
            items={[
                {
                    title: localization.empty(),
                    value: undefined
                },
                {
                    title: localization.false(),
                    value: false
                },
                {
                    title: localization.true(),
                    value: true
                }
            ]}
            placeholder={localization.title()}/>);
}