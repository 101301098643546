﻿import { Dialog, getUrlQueryParameters, ListWelcomeView, makeContextProvider, StringHelper, useLocalization, useRemoveQueryParameters } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { CiIntegrationQueryParameters } from "../..";
import { Contract, scopeSystemEntityModelStore, TenantIcon } from "../../../../../../../../../../../../common";
import { AddOrEdit, Table } from "./components";

export class JfrogIntegrationContext {
    constructor(public addOrEditOpen: boolean | Contract.CiIntegrationModel) {
    }
}

export const [useJfrogIntegrationContext, useSetJfrogIntegrationContext, useJfrogIntegrationContextProvider] = makeContextProvider<JfrogIntegrationContext>();

export function JfrogIntegration() {
    const integrationModels = scopeSystemEntityModelStore.useGetCiIntegration(Contract.CiTenantType.Jfrog);

    const removeQueryParameters = useRemoveQueryParameters();
    const [context, setContext, ContextProvider] =
        useJfrogIntegrationContextProvider(
            () => {
                const queryParameters = getUrlQueryParameters<JfrogIntegrationQueryParameters>();
                removeQueryParameters("addOrEditOpen");
                return new JfrogIntegrationContext(StringHelper.isTrue(queryParameters.addOrEditOpen));
            });

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCiIntegrationItems.jfrogIntegration",
            () => ({
                addOrganization: "Add JFrog Organization"
            }));

    return (
        <ContextProvider>
            {context.addOrEditOpen !== false &&
                <Dialog
                    size="large"
                    variant="editor"
                    onClose={
                        () =>
                            setContext(
                                context => ({
                                    ...context,
                                    addOrEditOpen: false
                                }))}>
                    <AddOrEdit/>
                </Dialog>}
            {_.isEmpty(integrationModels)
                ? <ListWelcomeView
                    icon={
                        <TenantIcon
                            data={{ ciTenantType: Contract.CiTenantType.Jfrog }}
                            tenantType={Contract.TenantType.Ci}/>}
                    title={localization.addOrganization()}
                    onStart={() =>
                        setContext(
                            context => ({
                                ...context,
                                addOrEditOpen: true
                            }))}/>
                : <Table/>}
        </ContextProvider>);
}

export type JfrogIntegrationQueryParameters = CiIntegrationQueryParameters;