import { TimeSpanFormatter, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useAwsCommonEncryptedResourceInfoElements, useAwsCommonNetworkingInfoItemElements, useAwsDefaultResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { useCustomEntityPropertyInfoItemElements } from "..";
import { Contract, InfoItem } from "../../../../../../../../../../common";
import { useAwsElastiCacheCacheAutoFailoverTranslator, useAwsElastiCacheCacheDeploymentTypeTranslator } from "../../../../../../hooks";
import { EntitiesInfoItem, Info } from "../../../../components";
import { NetworkingInfoCard } from "../../components";

export function useAwsElastiCacheServerlessCacheDefinition(resourceModel: Contract.AwsResourceModel, options?: DefinitionOptions) {
    const commonEncryptedResourceInfoElements = useAwsCommonEncryptedResourceInfoElements(resourceModel);
    const commonNetworkCardInfoItems = useAwsCommonNetworkingInfoItemElements({ resourceModel });
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useAwsDefaultResourceInfoItemElements(resourceModel);
    const serverlessCache = resourceModel.entity as Contract.AwsElastiCacheServerlessCache;
    const serverlessCacheModel = resourceModel as Contract.AwsElastiCacheServerlessCacheModel;

    const elastiCacheCacheAutoFailoverTranslator = useAwsElastiCacheCacheAutoFailoverTranslator();
    const elastiCacheCacheDeploymentTypeTranslator = useAwsElastiCacheCacheDeploymentTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsElastiCacheServerlessCacheDefinition",
            () => ({
                info: {
                    items: {
                        autoFailover: "Auto Failover",
                        automaticBackups: {
                            false: "Disabled",
                            title: "Automatic Backups",
                            true: "Enabled"
                        },
                        autoUpgradeMinorVersions: {
                            false: "Disabled",
                            title: "Auto Upgrade Minor Versions",
                            true: "Enabled"
                        },
                        BackupRetentionTimeFrame: "Backup Retention Period",
                        deploymentType: "Deployment Type",
                        description: "Description",
                        endpoint: "Endpoint",
                        engineVersion: "Engine Version",
                        status: "Status",
                        transitEncryptionEnabled: {
                            false: "Disabled",
                            title: "Encryption In Transit",
                            true: "Enabled"
                        },
                        vpc: "VPC"
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="deploymentType"
                        title={localization.info.items.deploymentType()}
                        value={elastiCacheCacheDeploymentTypeTranslator(serverlessCacheModel.deploymentType)}/>,
                    <InfoItem
                        key="status"
                        title={localization.info.items.status()}
                        value={serverlessCache.status}/>,
                    <InfoItem
                        key="description"
                        title={localization.info.items.description()}
                        value={
                            _.isEmpty(serverlessCache.description)
                                ? undefined
                                : serverlessCache.description}/>,
                    <InfoItem
                        key="engineVersion"
                        title={localization.info.items.engineVersion()}
                        value={serverlessCache.engineVersion}/>,
                    <InfoItem
                        key="endpoint"
                        title={localization.info.items.endpoint()}
                        value={serverlessCache.endpoint}/>,
                    ...commonEncryptedResourceInfoElements,
                    <InfoItem
                        key="transitEncryptionEnabled"
                        title={localization.info.items.transitEncryptionEnabled.title()}
                        value={
                            serverlessCache.transitEncryptionEnabled
                                ? localization.info.items.transitEncryptionEnabled.true()
                                : localization.info.items.transitEncryptionEnabled.false()}/>,
                    <InfoItem
                        key="automaticBackups"
                        title={localization.info.items.automaticBackups.title()}
                        value={
                            serverlessCache.automaticBackups
                                ? localization.info.items.automaticBackups.true()
                                : localization.info.items.automaticBackups.false()}/>,
                    <InfoItem
                        key="BackupRetentionTimeFrame"
                        title={localization.info.items.BackupRetentionTimeFrame()}
                        value={TimeSpanFormatter.day(serverlessCache.backupRetentionTimeFrame)}/>,
                    <InfoItem
                        key="autoFailover"
                        title={localization.info.items.autoFailover()}
                        value={elastiCacheCacheAutoFailoverTranslator(serverlessCache.autoFailover)}/>,
                    <InfoItem
                        key="autoUpgradeMinorVersions"
                        title={localization.info.items.autoUpgradeMinorVersions.title()}
                        value={
                            serverlessCache.autoUpgradeMinorVersions
                                ? localization.info.items.autoUpgradeMinorVersions.true()
                                : localization.info.items.autoUpgradeMinorVersions.false()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={serverlessCache.vpcIds}
                        entityTypeName={Contract.TypeNames.AwsEc2Vpc}
                        key="vpc"
                        location="miniGlance"
                        title={localization.info.items.vpc()}/>
                ]}
                options={options?.infoOptions}>
                <NetworkingInfoCard>
                    {commonNetworkCardInfoItems.getVpcs()}
                    {commonNetworkCardInfoItems.getSubnets()}
                    {commonNetworkCardInfoItems.getSecurityGroups()}
                </NetworkingInfoCard>
            </Info>
    });
}