import { Link, Step, Steps, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, Entity, entityModelStore, tenantModelStore } from "../../../../../../../../../../../../common";
import { AadConsoleUrlBuilder } from "../../../../../../../../../../../../tenants/aad/utilities/aadConsoleUrlBuilder.generated";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { useGetAadDirectoryApplicationServicePrincipalRiskContext } from "../contexts";

export function useAadDirectoryApplicationServicePrincipalInactiveRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AadDirectoryApplicationServicePrincipalInactiveRisk;
    const applicationServicePrincipalModel = entityModelStore.useGet(risk.entityId) as Contract.AadDirectoryApplicationServicePrincipalModel;
    const applicationServicePrincipal = applicationServicePrincipalModel.entity as Contract.AadDirectoryApplicationServicePrincipal;
    const tenantConfiguration = tenantModelStore.useGet(riskModel.tenantId).configuration as Contract.AadTenantConfiguration;

    const getAadDirectoryApplicationServicePrincipalRiskContext = useGetAadDirectoryApplicationServicePrincipalRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aad.hooks.compliance.useAadDirectoryApplicationServicePrincipalInactiveRiskDefinition",
            () => ({
                description: "{{applicationServicePrincipal}} is inactive and should be disabled or deleted",
                sections: {
                    context: {
                        [Contract.TypeNames.AadDirectoryIdentityInactiveRiskModelInfoType]: {
                            [Contract.AadDirectoryIdentityInactiveRiskModelInfoType.SignInNever]: "The application was not active since it was created",
                            [Contract.AadDirectoryIdentityInactiveRiskModelInfoType.SignInTime]: "The application was last active {{signInTime | TimeFormatter.humanizePastDuration}}"
                        }
                    },
                    resolution: {
                        step1: {
                            link: "Properties page",
                            text: "In Azure Portal, open the {{propertiesLink}} of {{applicationServicePrincipal}}"
                        },
                        step2: {
                            [Contract.TypeNames.AadDirectoryIdentityInactiveRiskResolutionType]: {
                                [Contract.AadDirectoryIdentityInactiveRiskResolutionType.Delete]: "Click **Delete** at the top and then click **Yes**",
                                [Contract.AadDirectoryIdentityInactiveRiskResolutionType.DeleteDisable]: "To delete the application, click **Delete** at the top and then click **Yes**"
                            }
                        },
                        step3: "To disable the application, set **Enabled for users to sign-in?** to **No** and then click **Save**",
                        title: {
                            [Contract.TypeNames.AadDirectoryIdentityInactiveRiskResolutionType]: {
                                [Contract.AadDirectoryIdentityInactiveRiskResolutionType.Delete]: "Delete {{applicationServicePrincipal}}",
                                [Contract.AadDirectoryIdentityInactiveRiskResolutionType.DeleteDisable]: "Delete or disable {{applicationServicePrincipal}}"
                            }
                        }
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            applicationServicePrincipal:
                <Entity
                    entityIdOrModel={applicationServicePrincipalModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            new Step(
                localization.sections.resolution.title[Contract.TypeNames.AadDirectoryIdentityInactiveRiskResolutionType][risk.resolutionType]({
                    applicationServicePrincipal:
                        <Entity
                            entityIdOrModel={applicationServicePrincipalModel}
                            glanceOptions={{ disabled: true }}
                            linkOptions={{ disabled: true }}
                            variant="typeText"/>
                }),
                {
                    contentElement:
                        <Steps variant="plainNumbers">
                            {_.filter([
                                localization.sections.resolution.step1.text({
                                    applicationServicePrincipal:
                                        <Entity
                                            entityIdOrModel={applicationServicePrincipalModel}
                                            linkOptions={{ disabled: true }}
                                            variant="typeText"/>,
                                    propertiesLink:
                                        <Link
                                            urlOrGetUrl={AadConsoleUrlBuilder.GetServicePrincipalPropertiesUrl(
                                                applicationServicePrincipal.applicationRawId,
                                                tenantConfiguration.partitionType,
                                                applicationServicePrincipal.rawId)}
                                            variant="external">
                                            {localization.sections.resolution.step1.link()}
                                        </Link>
                                }),
                                localization.sections.resolution.step2[Contract.TypeNames.AadDirectoryIdentityInactiveRiskResolutionType][risk.resolutionType](),
                                risk.resolutionType === Contract.AadDirectoryIdentityInactiveRiskResolutionType.DeleteDisable
                                    ? localization.sections.resolution.step3()
                                    : undefined
                            ])}
                        </Steps>
                })
        ],
        riskModel,
        () => {
            const applicationServicePrincipalRiskContext = getAadDirectoryApplicationServicePrincipalRiskContext(applicationServicePrincipalModel);
            return [
                applicationServicePrincipalRiskContext.generalInformation,
                new RiskDefinitionContextItem(localization.sections.context[Contract.TypeNames.AadDirectoryIdentityInactiveRiskModelInfoType][(riskModel as Contract.AadDirectoryIdentityInactiveRiskModel).infoType]({ signInTime: risk.signInTime })),
                applicationServicePrincipalRiskContext.vendor,
                applicationServicePrincipalRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}