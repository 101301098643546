import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, Entity, InlineEntities, useEntityTypeNameTranslator } from "../../../../../../../../../../../common";
import { AuditEventDefinition, AuditEventDefinitionContext, AuditEventDefinitionDetailItem } from "../../../useDefinition";
import { usePermissionAssignmentEligibilityAuditEventCommonDetailItems } from "./usePermissionAssignmentEligibilityAuditEventCommonDetailItems";

export function useAwsSsoPermissionSetAssignmentEligibilityAuditEventDefinition(context: AuditEventDefinitionContext) {
    const permissionAssignmentEligibilityAuditEventCommonDetailItems = usePermissionAssignmentEligibilityAuditEventCommonDetailItems(context);
    const localization =
        useLocalization(
            "views.customer.auditEvents.profile.hooks.useDefinition.hooks.usePermissionEligibilityAuditEventDefinition.hooks.useAwsSsoPermissionSetAssignmentEligibilityAuditEventDefinition",
            () => ({
                inlinePolicyDocument: {
                    exists: "Yes",
                    notExists: "No",
                    title: "Inline policy"
                },
                permitterIdReferences: "Permissions"
            }));

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const permissionEligibilityAuditEventModel = context.auditEventModel as Contract.PermissionEligibilityAuditEventModel;
    const auditEventSsoPermissionSetAssignmentEligibilityData = permissionEligibilityAuditEventModel.auditEvent.permissionEligibilityData as Contract.PermissionEligibilityAuditEventAwsSsoPermissionSetAssignmentEligibilityData;
    const auditEventModelSsoPermissionSetAssignmentEligibilityData = permissionEligibilityAuditEventModel.permissionEligibilityData as Contract.PermissionEligibilityAuditEventModelAwsSsoPermissionSetAssignmentEligibilityData;

    return new AuditEventDefinition(
        _.filter([
            permissionAssignmentEligibilityAuditEventCommonDetailItems.name,
            permissionAssignmentEligibilityAuditEventCommonDetailItems.principalTenantId,
            permissionAssignmentEligibilityAuditEventCommonDetailItems.granteePrincipalIdReferences,
            _.isEmpty(auditEventModelSsoPermissionSetAssignmentEligibilityData.permitterIdReferences)
                ? new AuditEventDefinitionDetailItem(
                    localization.permitterIdReferences(),
                    undefined)
                : auditEventModelSsoPermissionSetAssignmentEligibilityData.permitterIdReferences.length === 1
                    ? new AuditEventDefinitionDetailItem(
                        localization.permitterIdReferences(),
                        context.entityModelMap[auditEventModelSsoPermissionSetAssignmentEligibilityData.permitterIdReferences[0]].entity.displayName,
                        <Entity
                            entityIdOrModel={auditEventModelSsoPermissionSetAssignmentEligibilityData.permitterIdReferences[0]}
                            variant="iconText"/>)
                    : new AuditEventDefinitionDetailItem(
                        localization.permitterIdReferences(),
                        entityTypeNameTranslator(
                            context.entityModelMap[auditEventModelSsoPermissionSetAssignmentEligibilityData.permitterIdReferences[0]].entity.typeName,
                            {
                                count: auditEventModelSsoPermissionSetAssignmentEligibilityData.permitterIdReferences.length,
                                includeCount: true
                            }),
                        <InlineEntities
                            entityIdsOrModels={auditEventModelSsoPermissionSetAssignmentEligibilityData.permitterIdReferences}
                            entityTypeName={context.entityModelMap[auditEventModelSsoPermissionSetAssignmentEligibilityData.permitterIdReferences[0]].entity.typeName}
                            variant="itemPlusItemCount"/>),
            new AuditEventDefinitionDetailItem(
                localization.inlinePolicyDocument.title(),
                _.isNil(auditEventSsoPermissionSetAssignmentEligibilityData.inlinePolicyDocument)
                    ? localization.inlinePolicyDocument.notExists()
                    : localization.inlinePolicyDocument.exists()),
            permissionAssignmentEligibilityAuditEventCommonDetailItems.permissionRequestScopePaths,
            permissionAssignmentEligibilityAuditEventCommonDetailItems.expirationTimeFrame,
            permissionAssignmentEligibilityAuditEventCommonDetailItems.approval,
            ...permissionAssignmentEligibilityAuditEventCommonDetailItems.approvalLevels,
            permissionAssignmentEligibilityAuditEventCommonDetailItems.reasonRequired,
            permissionAssignmentEligibilityAuditEventCommonDetailItems.updateAuditEventChanges
        ]));
}