import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, InfoItem } from "../../../../../../../../../../common";
import { EntitiesInfoItem } from "../../../../components";

export function useAzureDefaultKeyVaultVaultObjectInfoItemElements(vaultObjectModel: Contract.AzureKeyVaultVaultObjectModel) {
    const vaultObject = vaultObjectModel.entity as Contract.AzureKeyVaultVaultObject;
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.azure.useAzureDefaultKeyVaultVaultObjectInfoItemElements",
            () => ({
                info: {
                    azureManaged: {
                        false: "Customer manged",
                        title: "Managed type",
                        true: "Azure managed"
                    },
                    vaultId: "Key Vault"
                }
            }));
    return _.filter([
        <EntitiesInfoItem
            entityIdsOrModels={vaultObject.vaultId}
            entityTypeName={Contract.TypeNames.AzureKeyVaultVault}
            key="vaultId"
            location="all"
            title={localization.info.vaultId()}/>,
        vaultObject.typeName === Contract.TypeNames.AzureKeyVaultVaultCertificate
            ? undefined
            : <InfoItem
                key="keyVaultManaged"
                location="all"
                title={localization.info.azureManaged.title()}
                value={
                    vaultObjectModel.azureManaged
                        ? localization.info.azureManaged.true()
                        : localization.info.azureManaged.false()}/>
    ]);
}