import { ActionButton, Step, Steps, UnexpectedError, useLocalization } from "@infrastructure";
import { Stack } from "@mui/material";
import _ from "lodash";
import React, { Fragment, useMemo, useState } from "react";
import utf8 from "utf8";
import { RiskContentProps } from "../../../..";
import { Contract, DiffViewerIcon, DocumentViewerDialog, Entity, entityModelStore, InlineEntities, InlinePermissionActions, PolicyDocumentIcon, useRiskSeverityReasonTranslator, useSeverityTranslator, useTheme } from "../../../../../../../../../../../../common";
import { CodeTypeIcon } from "../../../../../../../../../../../../tenants";
import { ChangeStep, makeAccessResolutionSectionDefinitionComponent } from "../../../../../../utilities";
import { PrincipalRiskPolicyDiffDialog, RiskPolicyDiffPolicyItem } from "../../components";
import { useAwsCommonAccessPrincipalRiskDefinition } from "./useAwsCommonAccessPrincipalRiskDefinition";

export function useAwsExcessivePermissionPermissionSetRiskDefinition(riskModel: Contract.RiskModel) {
    const excessivePermissionPermissionSetRiskModel = riskModel as Contract.AwsExcessivePermissionPermissionSetRiskModel;
    const permissionSetModel = entityModelStore.useGet(excessivePermissionPermissionSetRiskModel.risk.entityId) as Contract.AwsSsoPermissionSetModel;
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.access.useAwsExcessivePermissionPermissionSetRiskDefinition",
            () => ({
                description: {
                    [Contract.TypeNames.AwsExcessivePermissionPermissionSetRiskModelDescriptionType]: {
                        [Contract.AwsExcessivePermissionPermissionSetRiskModelDescriptionType.ExcessivePermissionAccountsAll]: "{{permissionSet}}, assigned to {{assignedPrincipalIds}} on {{accountIds}} is granting access to {{serviceIds}} but the permissions were not used by any principal on any account",
                        [Contract.AwsExcessivePermissionPermissionSetRiskModelDescriptionType.ExcessivePermissionAccountsSome]: "{{permissionSet}}, assigned to {{assignedPrincipalIds}} on {{accountIds}} is granting access to {{serviceIds}} but the permissions were not used by any principal on {{excessivePermissionAccountIds}}",
                        [Contract.AwsExcessivePermissionPermissionSetRiskModelDescriptionType.ServiceLevelExcessivePermissionServicesAllAndExcessivePermissionAccountsSome]: "{{permissionSet}}, assigned to {{assignedPrincipalIds}} on {{accountIds}} is granting access to {{serviceIds}} but the permissions were not used by any principal on {{knownAccountIds}}",
                        [Contract.AwsExcessivePermissionPermissionSetRiskModelDescriptionType.ServiceLevelExcessivePermissionServicesSome]: "{{permissionSet}}, assigned to {{assignedPrincipalIds}} on {{accountIds}} is granting access to {{serviceIds}} but only {{nonexcessivePermissionServiceIds}} were used",
                        [Contract.AwsExcessivePermissionPermissionSetRiskModelDescriptionType.ServiceLevelExcessivePermissionServicesSomeAndExcessivePermissionAccountsSome]: "{{permissionSet}}, assigned to {{assignedPrincipalIds}} on {{accountIds}} is granting access to {{serviceIds}} but only {{nonexcessivePermissionServiceIds}} were used and only on {{nonexcessivePermissionAccountIds}}",
                        [Contract.AwsExcessivePermissionPermissionSetRiskModelDescriptionType.ServiceResourceLevelAndExcessivePermissionAccountsSome]: "{{permissionSet}}, assigned to {{assignedPrincipalIds}} on {{accountIds}} is granting access to {{serviceIds}} but only some of the permissions were used and only on {{nonexcessivePermissionAccountIds}}",
                        [Contract.AwsExcessivePermissionPermissionSetRiskModelDescriptionType.ServiceResourceLevels]: "{{permissionSet}}, assigned to {{assignedPrincipalIds}} on {{accountIds}} is granting access to {{serviceIds}} but only some of the permissions were used"
                    }
                },
                sections: {
                    accessGraph: "Permissions and usage of {{permissionSet}}"
                }
            }));

    return useAwsCommonAccessPrincipalRiskDefinition(
        Contract.EntityAccessScope.GranteePermitterInvolved,
        <ContextSection riskModel={riskModel}/>,
        localization.description[Contract.TypeNames.AwsExcessivePermissionPermissionSetRiskModelDescriptionType][excessivePermissionPermissionSetRiskModel.descriptionType]({
            accountIds:
                <InlineEntities
                    entityIdsOrModels={excessivePermissionPermissionSetRiskModel.accountIds}
                    entityTypeName={Contract.TypeNames.AwsOrganizationsAccount}
                    variant="itemAndTypeOrItemCountAndType"/>,
            assignedPrincipalIds:
                <InlineEntities
                    entityIdsOrModels={excessivePermissionPermissionSetRiskModel.risk.assignedPrincipalIds}
                    entityTypeName={Contract.TypeNames.AwsSsoPrincipal}
                    entityTypeNameTranslatorOptions={{ includeServiceName: true }}
                    variant="itemAndTypeOrItemCountAndType"/>,
            excessivePermissionAccountIds:
                <InlineEntities
                    entityIdsOrModels={excessivePermissionPermissionSetRiskModel.risk.excessivePermissionAccountIds}
                    entityTypeName={Contract.TypeNames.AwsOrganizationsAccount}
                    variant="itemAndTypeOrItemCountAndType"/>,
            knownAccountIds:
                <InlineEntities
                    entityIdsOrModels={excessivePermissionPermissionSetRiskModel.knownAccountIds}
                    entityTypeName={Contract.TypeNames.AwsOrganizationsAccount}
                    variant="itemAndTypeOrItemCountAndType"/>,
            nonexcessivePermissionAccountIds:
                <InlineEntities
                    entityIdsOrModels={excessivePermissionPermissionSetRiskModel.risk.nonexcessivePermissionAccountIds}
                    entityTypeName={Contract.TypeNames.AwsOrganizationsAccount}
                    variant="itemAndTypeOrItemCountAndType"/>,
            nonexcessivePermissionServiceIds:
                excessivePermissionPermissionSetRiskModel.risk.serviceLevel?.hasExcessivePermissions
                    ? <InlineEntities
                        entityIdsOrModels={excessivePermissionPermissionSetRiskModel.risk.serviceLevel!.nonexcessivePermissionServiceIds}
                        entityTypeName={Contract.TypeNames.AwsService}
                        variant="itemAndTypeOrItemCountAndType"/>
                    : undefined,
            permissionSet:
                <Entity
                    entityIdOrModel={permissionSetModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>,
            serviceIds:
                <InlineEntities
                    entityIdsOrModels={excessivePermissionPermissionSetRiskModel.serviceIds}
                    entityTypeName={Contract.TypeNames.AwsService}
                    variant="itemAndTypeOrItemCountAndType"/>
        }),
        riskModel,
        {
            accessGraphTitle:
                localization.sections.accessGraph({
                    permissionSet:
                        <Entity
                            entityIdOrModel={permissionSetModel}
                            variant="text"/>
                }),
            resolutionSectionDefinitionComponent
        });
}

function ContextSection({ riskModel }: RiskContentProps) {
    const excessivePermissionPermissionSetRiskModel = riskModel as Contract.AwsExcessivePermissionPermissionSetRiskModel;
    const permissionSetModel = entityModelStore.useGet(excessivePermissionPermissionSetRiskModel.risk.entityId) as Contract.AwsSsoPermissionSetModel;
    const severityReasonTranslator = useRiskSeverityReasonTranslator();
    const severityTranslator = useSeverityTranslator();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.access.useAwsExcessivePermissionPermissionSetRiskDefinition.contextSection",
            () => ({
                sensitiveExcessivePermissionActions: [
                    "1 sensitive excessive permission",
                    "{{count | NumberFormatter.humanize}} sensitive excessive permissions"
                ],
                sensitiveResources: [
                    "1 sensitive resource",
                    "{{count | NumberFormatter.humanize}} sensitive resources"
                ],
                [Contract.AwsExcessivePermissionPermissionSetRiskModelInfo.Assignments]: "{{permissionSet}} is assigned to {{assignedPrincipalIds}} on {{accountIds}}",
                [Contract.AwsExcessivePermissionPermissionSetRiskModelInfo.ExcessivePermissionAccounts]: "{{permissionSet}} was not used by any of the assignees on {{excessivePermissionAccountIds}}",
                [Contract.AwsExcessivePermissionPermissionSetRiskModelInfo.InactiveUsedEver]: "None of the assignees used this permission set for more than {{activityTime | TimeFormatter.humanizeDuration}}",
                [Contract.AwsExcessivePermissionPermissionSetRiskModelInfo.InactiveUsedNever]: "None of the assignees used this permission set since it was created {{creationTime | TimeFormatter.humanizePastDuration}}",
                [Contract.AwsExcessivePermissionPermissionSetRiskModelInfo.InlinePolicy]: "{{permissionSet}} is configured with a custom policy granting its assignees {{permissionActions}} on {{serviceIds}}",
                [Contract.AwsExcessivePermissionPermissionSetRiskModelInfo.InlinePolicyAndManagedPolicy]: "{{permissionSet}} is configured with {{managedPolicyIds}} and a custom policy granting its assignees {{permissionActions}} on {{serviceIds}}",
                [Contract.AwsExcessivePermissionPermissionSetRiskModelInfo.ManagedPolicy]: "{{permissionSet}} is configured with {{managedPolicyIds}} granting its assignees {{permissionActions}} on {{serviceIds}}",
                [Contract.AwsExcessivePermissionPermissionSetRiskModelInfo.SensitiveResourcesSensitiveExcessivePermissionActionsAny]: "This Permission Set has {{sensitiveExcessivePermissionActions}} on {{sensitiveResources}}",
                [Contract.AwsExcessivePermissionPermissionSetRiskModelInfo.ServiceLevelExcessivePermissionServicesAllUsedEver]: [
                    "On {{nonexcessivePermissionAccountIds}} that was used, none of the {{serviceIds}} were used for more than {{excessivePermissionServiceMaxUsageTime | TimeFormatter.humanizeDuration}}",
                    "On the {{nonexcessivePermissionAccountIds}} that were used, none of the {{serviceIds}} were used for more than {{excessivePermissionServiceMaxUsageTime | TimeFormatter.humanizeDuration}}"
                ],
                [Contract.AwsExcessivePermissionPermissionSetRiskModelInfo.ServiceLevelExcessivePermissionServicesAllUsedNever]: [
                    "On {{nonexcessivePermissionAccountIds}} that was used, none of the {{serviceIds}} were used since the permission set was created {{creationTime | TimeFormatter.humanizePastDuration}}",
                    "On the {{nonexcessivePermissionAccountIds}} that were used, none out of the {{serviceIds}} were used since the permission set was created {{creationTime | TimeFormatter.humanizePastDuration}}"
                ],
                [Contract.AwsExcessivePermissionPermissionSetRiskModelInfo.ServiceLevelExcessivePermissionServicesSomeUsedEver]: [
                    "On {{nonexcessivePermissionAccountIds}} that was used, {{excessivePermissionServiceIds}} out of the {{serviceIds}} were unused for more than {{excessivePermissionServiceMaxUsageTime | TimeFormatter.humanizeDuration}}",
                    "On the {{nonexcessivePermissionAccountIds}} that were used, {{excessivePermissionServiceIds}} out of the {{serviceIds}} were unused for more than {{excessivePermissionServiceMaxUsageTime | TimeFormatter.humanizeDuration}}"
                ],
                [Contract.AwsExcessivePermissionPermissionSetRiskModelInfo.ServiceLevelExcessivePermissionServicesSomeUsedEverAndServiceResourceLevel]: [
                    "On {{nonexcessivePermissionAccountIds}} that was used, {{excessivePermissionServiceIds}} out of the {{serviceIds}} were unused for more than {{excessivePermissionServiceMaxUsageTime | TimeFormatter.humanizeDuration}} and only some of the permissions on {{resourceLevelServiceIds}} were used",
                    "On the {{nonexcessivePermissionAccountIds}} that were used, {{excessivePermissionServiceIds}} out of the {{serviceIds}} were unused for more than {{excessivePermissionServiceMaxUsageTime | TimeFormatter.humanizeDuration}} and only some of the permissions on {{resourceLevelServiceIds}} were used"
                ],
                [Contract.AwsExcessivePermissionPermissionSetRiskModelInfo.ServiceLevelExcessivePermissionServicesSomeUsedNever]: [
                    "On {{nonexcessivePermissionAccountIds}} that was used, {{excessivePermissionServiceIds}} out of the {{serviceIds}} were unused since the permission set was created {{creationTime | TimeFormatter.humanizePastDuration}}",
                    "On the {{nonexcessivePermissionAccountIds}} that were used, {{excessivePermissionServiceIds}} out of the {{serviceIds}} were unused since the permission set was created {{creationTime | TimeFormatter.humanizePastDuration}}"
                ],
                [Contract.AwsExcessivePermissionPermissionSetRiskModelInfo.ServiceLevelExcessivePermissionServicesSomeUsedNeverAndServiceResourceLevel]: [
                    "On {{nonexcessivePermissionAccountIds}} that was used, {{excessivePermissionServiceIds}} out of the {{serviceIds}} were unused since the permission set was created {{creationTime | TimeFormatter.humanizePastDuration}} and only some of the permissions on {{resourceLevelServiceIds}} were used",
                    "On the {{nonexcessivePermissionAccountIds}} that were used, {{excessivePermissionServiceIds}} out of the {{serviceIds}} were unused since the permission set was created {{creationTime | TimeFormatter.humanizePastDuration}} and only some of the permissions on {{resourceLevelServiceIds}} were used"
                ],
                [Contract.AwsExcessivePermissionPermissionSetRiskModelInfo.ServiceResourceLevels]: [
                    "On {{nonexcessivePermissionAccountIds}} that was used, only some of the permissions on {{resourceLevelServiceIds}} out of the {{serviceIds}} were used",
                    "On the {{nonexcessivePermissionAccountIds}} that were used, only some of the permissions on {{resourceLevelServiceIds}} out of the {{serviceIds}} were used"
                ],
                [Contract.AwsExcessivePermissionPermissionSetRiskModelInfo.UnknownAccounts]: [
                    "{{permissionSet}} is assigned to {{unknownAccountIds}} which is not configured in Tenable Cloud Security thus we cannot recommend to rightsize the permissions!",
                    "{{permissionSet}} is assigned to {{unknownAccountIds}} which are not configured in Tenable Cloud Security thus we cannot recommend to rightsize the permissions!"
                ]
            }));

    const permissionSetInfoProps = () => ({
        accountIds:
            <InlineEntities
                entityIdsOrModels={excessivePermissionPermissionSetRiskModel.accountIds}
                entityTypeName={Contract.TypeNames.AwsOrganizationsAccount}
                entityTypeNameTranslatorOptions={{
                    includeServiceName: false,
                    variant: "text"
                }}
                variant="itemAndTypeOrItemCountAndType"/>,
        activityTime: excessivePermissionPermissionSetRiskModel.risk.activityTime,
        assignedPrincipalIds:
            <InlineEntities
                entityIdsOrModels={excessivePermissionPermissionSetRiskModel.risk.assignedPrincipalIds}
                entityTypeName={Contract.TypeNames.AwsSsoPrincipal}
                variant="itemAndTypeOrItemCountAndType"/>,
        creationTime: permissionSetModel.creationTime,
        excessivePermissionAccountIds:
            <InlineEntities
                entityIdsOrModels={excessivePermissionPermissionSetRiskModel.risk.excessivePermissionAccountIds}
                entityTypeName={Contract.TypeNames.AwsOrganizationsAccount}
                variant="itemAndTypeOrItemCountAndType"/>,
        excessivePermissionServiceIds:
            excessivePermissionPermissionSetRiskModel.risk.serviceLevel?.hasExcessivePermissions
                ? <InlineEntities
                    entityIdsOrModels={excessivePermissionPermissionSetRiskModel.risk.serviceLevel!.excessivePermissionServiceIds}
                    entityTypeName={Contract.TypeNames.AwsService}
                    variant="itemAndTypeOrItemCountAndType"/>
                : undefined,
        excessivePermissionServiceMaxUsageTime: excessivePermissionPermissionSetRiskModel.excessivePermissionServiceMaxUsageTime,
        managedPolicyIds:
            <InlineEntities
                entityIdsOrModels={excessivePermissionPermissionSetRiskModel.risk.managedPolicyIds}
                entityTypeName={Contract.TypeNames.AwsIamPrincipalPolicy}
                variant="itemAndTypeOrItemCountAndType"/>,
        nonexcessivePermissionAccountIds:
            <InlineEntities
                entityIdsOrModels={excessivePermissionPermissionSetRiskModel.risk.nonexcessivePermissionAccountIds}
                entityTypeName={Contract.TypeNames.AwsOrganizationsAccount}
                variant="itemAndTypeOrItemCountAndType"/>,
        permissionActions:
            <InlinePermissionActions
                permissionActions={excessivePermissionPermissionSetRiskModel.risk.permissionActions}
                variant="itemCountAndType"/>,
        permissionSet:
            <Entity
                entityIdOrModel={permissionSetModel}
                variant="text"/>,
        resourceLevelServiceIds:
            <InlineEntities
                entityIdsOrModels={excessivePermissionPermissionSetRiskModel.risk.resourceLevelServiceIds}
                entityTypeName={Contract.TypeNames.AwsService}
                variant="itemAndTypeOrItemCountAndType"/>,
        sensitiveExcessivePermissionActions:
            <InlinePermissionActions
                namePluralizer={localization.sensitiveExcessivePermissionActions}
                permissionActions={excessivePermissionPermissionSetRiskModel.risk.sensitiveResources.sensitiveExcessivePermissionActions}
                variant="itemOrItemCountAndType"/>,
        sensitiveResources:
            <InlineEntities
                entityIdsOrModels={excessivePermissionPermissionSetRiskModel.risk.sensitiveResources.ids}
                entityTypeName={Contract.TypeNames.AwsResource}
                namePluralizer={localization.sensitiveResources}
                variant="itemAndTypeOrItemCountAndType"/>,
        serviceIds:
            <InlineEntities
                entityIdsOrModels={excessivePermissionPermissionSetRiskModel.serviceIds}
                entityTypeName={Contract.TypeNames.AwsService}
                variant="itemAndTypeOrItemCountAndType"/>,
        unknownAccountIds:
            <InlineEntities
                entityIdsOrModels={excessivePermissionPermissionSetRiskModel.risk.unknownAccountIds}
                entityTypeName={Contract.TypeNames.AwsOrganizationsAccount}
                variant="itemAndTypeOrItemCountAndType"/>
    });

    return (
        <Steps>
            {_<string>([]).
                concat(
                    _.map(
                        excessivePermissionPermissionSetRiskModel.infos,
                        info => {
                            switch (info) {
                                case Contract.AwsExcessivePermissionPermissionSetRiskModelInfo.ServiceLevelExcessivePermissionServicesAllUsedEver:
                                case Contract.AwsExcessivePermissionPermissionSetRiskModelInfo.ServiceLevelExcessivePermissionServicesAllUsedNever:
                                case Contract.AwsExcessivePermissionPermissionSetRiskModelInfo.ServiceLevelExcessivePermissionServicesSomeUsedEver:
                                case Contract.AwsExcessivePermissionPermissionSetRiskModelInfo.ServiceLevelExcessivePermissionServicesSomeUsedEverAndServiceResourceLevel:
                                case Contract.AwsExcessivePermissionPermissionSetRiskModelInfo.ServiceLevelExcessivePermissionServicesSomeUsedNever:
                                case Contract.AwsExcessivePermissionPermissionSetRiskModelInfo.ServiceLevelExcessivePermissionServicesSomeUsedNeverAndServiceResourceLevel:
                                case Contract.AwsExcessivePermissionPermissionSetRiskModelInfo.ServiceResourceLevels:
                                    return localization[info](
                                        excessivePermissionPermissionSetRiskModel.risk.nonexcessivePermissionAccountIds.length,
                                        permissionSetInfoProps());
                                case Contract.AwsExcessivePermissionPermissionSetRiskModelInfo.UnknownAccounts:
                                    return localization[info](
                                        excessivePermissionPermissionSetRiskModel.risk.unknownAccountIds.length,
                                        permissionSetInfoProps());
                                default:
                                    return localization[info](permissionSetInfoProps());
                            }
                        })).
                concatIf(
                    !_.isNil(excessivePermissionPermissionSetRiskModel.risk.severityReason),
                    () => severityReasonTranslator(
                        excessivePermissionPermissionSetRiskModel.risk.severity,
                        excessivePermissionPermissionSetRiskModel.risk.severityReason!,
                        {
                            excessivePermissionActionSeverity:
                                _.isNil(excessivePermissionPermissionSetRiskModel.risk.excessivePermissionActionSeverity)
                                    ? undefined
                                    : severityTranslator(excessivePermissionPermissionSetRiskModel.risk.excessivePermissionActionSeverity, "text"),
                            nonexcessivePermissionActionSeverity:
                                _.isNil(excessivePermissionPermissionSetRiskModel.risk.nonexcessivePermissionSeverity)
                                    ? undefined
                                    : severityTranslator(excessivePermissionPermissionSetRiskModel.risk.nonexcessivePermissionSeverity, "text"),
                            resourceTypeName: permissionSetModel.entity.typeName
                        })).
                value()}
        </Steps>);
}

const resolutionSectionDefinitionComponent =
    makeAccessResolutionSectionDefinitionComponent(
        riskModel => {
            const excessivePermissionPermissionSetRiskModel = riskModel as Contract.AwsExcessivePermissionPermissionSetRiskModel;
            const permissionSetModel = entityModelStore.useGet(excessivePermissionPermissionSetRiskModel.risk.entityId) as Contract.AwsSsoPermissionSetModel;

            entityModelStore.useGet(
                _.flatMap(
                    excessivePermissionPermissionSetRiskModel.risk.resolutionChanges,
                    resolutionChange => resolutionChange.entityIds));

            const localization =
                useLocalization(
                    "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.access.useAwsExcessivePermissionPermissionSetRiskDefinition.useResolutionSectionOptions",
                    () => ({
                        steps: {
                            detachPolicies: "Detach all existing managed policies of permission set {{permissionSet}}"
                        }
                    }));

            return _.map(
                excessivePermissionPermissionSetRiskModel.risk.resolutionChanges,
                resolutionChange => {
                    if (resolutionChange.typeName === Contract.TypeNames.ChangeSet) {
                        const changeSet = resolutionChange as Contract.ChangeSet;
                        const changeSetChangeTypeName = changeSet.changes[0].typeName;
                        switch (changeSetChangeTypeName) {
                            case Contract.TypeNames.AwsDetachPermissionSetManagedPolicyChange: {
                                const detachPermissionSetManagedPolicyChanges = changeSet.changes as Contract.AwsDetachPermissionSetManagedPolicyChange[];
                                return new Step(
                                    localization.steps.detachPolicies({
                                        permissionSet:
                                            <Entity
                                                entityIdOrModel={permissionSetModel}
                                                glanceOptions={{ disabled: true }}
                                                linkOptions={{ disabled: true }}
                                                variant="text"/>
                                    }),
                                    {
                                        contentElement:
                                            <Steps variant="plainNumbers">
                                                {_.map(
                                                    detachPermissionSetManagedPolicyChanges,
                                                    detachPermissionSetManagedPolicyChange =>
                                                        new ChangeStep(
                                                            detachPermissionSetManagedPolicyChange,
                                                            {
                                                                actionElements: [
                                                                    <DetachPermissionSetManagedPolicyChangeActions
                                                                        key={detachPermissionSetManagedPolicyChange.id}
                                                                        policyId={detachPermissionSetManagedPolicyChange.managedPolicyId}
                                                                        policyInfo={excessivePermissionPermissionSetRiskModel.risk.resolutionChangeIdToPolicyInfoMap[detachPermissionSetManagedPolicyChange.id]}/>
                                                                ]
                                                            }))}
                                            </Steps>
                                    });
                            }
                            default:
                                throw new UnexpectedError("changeSetChangeTypeName", changeSetChangeTypeName);
                        }
                    } else {
                        const change = resolutionChange as Contract.Change;
                        switch (change.typeName) {
                            case Contract.TypeNames.AwsDeletePermissionSetAccountAssignmentsChange:
                            case Contract.TypeNames.AwsProvisionPermissionSetChange:
                                return new ChangeStep(change);
                            case Contract.TypeNames.AwsDeletePermissionSetInlinePolicyChange: {
                                const deletePermissionSetInlinePolicyChange = change as Contract.AwsDeletePermissionSetInlinePolicyChange;
                                return new ChangeStep(
                                    deletePermissionSetInlinePolicyChange,
                                    {
                                        actionElements: [
                                            <DeleteOrUpsertPermissionSetInlinePolicyChangeActions
                                                key={`${Contract.TypeNames.AwsDeletePermissionSetInlinePolicyChange}_DeleteOrUpsert`}
                                                managedPolicyIds={excessivePermissionPermissionSetRiskModel.risk.managedPolicyIds}
                                                permissionSetModel={permissionSetModel}
                                                policyInfo={excessivePermissionPermissionSetRiskModel.risk.resolutionChangeIdToPolicyInfoMap[deletePermissionSetInlinePolicyChange.id]}
                                                riskModel={excessivePermissionPermissionSetRiskModel}/>
                                        ]
                                    });
                            }
                            case Contract.TypeNames.AwsUpsertPermissionSetInlinePolicyChange: {
                                const upsertPermissionSetInlinePolicyChange = change as Contract.AwsUpsertPermissionSetInlinePolicyChange;
                                return new ChangeStep(
                                    upsertPermissionSetInlinePolicyChange,
                                    {
                                        actionElements: [
                                            <DeleteOrUpsertPermissionSetInlinePolicyChangeActions
                                                key={`${Contract.TypeNames.AwsUpsertPermissionSetInlinePolicyChange}_DeleteOrUpsert`}
                                                managedPolicyIds={excessivePermissionPermissionSetRiskModel.risk.managedPolicyIds}
                                                permissionSetModel={permissionSetModel}
                                                policyInfo={excessivePermissionPermissionSetRiskModel.risk.resolutionChangeIdToPolicyInfoMap[upsertPermissionSetInlinePolicyChange.id]}
                                                riskModel={excessivePermissionPermissionSetRiskModel}/>
                                        ]
                                    });
                            }
                            default:
                                throw new UnexpectedError("change.typeName", change.typeName);
                        }
                    }
                });
        });

type DeleteOrUpsertPermissionSetInlinePolicyChangeActionsProps = {
    managedPolicyIds: string[];
    permissionSetModel: Contract.AwsSsoPermissionSetModel;
    policyInfo: Contract.AwsExcessivePermissionPrincipalRiskResolutionPolicyInfo;
    riskModel: Contract.AwsExcessivePermissionPermissionSetRiskModel;
};

function DeleteOrUpsertPermissionSetInlinePolicyChangeActions({ managedPolicyIds, permissionSetModel, policyInfo, riskModel }: DeleteOrUpsertPermissionSetInlinePolicyChangeActionsProps) {
    const [policyDocumentDiff, setPolicyDocumentDiff] = useState(false);
    const [riskFile, setRiskFile] = useState<Contract.RiskFile>();
    const permissionSet = permissionSetModel.entity as Contract.AwsSsoPermissionSet;
    const inlinePolicyName = `${permissionSet.name}_InlinePolicy`;
    const managedPolicyModels = entityModelStore.useGet(managedPolicyIds);
    const managedPolicies =
        useMemo(
            () =>
                _.map(
                    managedPolicyModels,
                    managedPolicyModel => managedPolicyModel.entity as Contract.AwsIamManagedPolicy),
            [managedPolicyModels]);
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.access.useAwsExcessivePermissionPermissionSetRiskDefinition.deleteOrUpsertPermissionSetInlinePolicyChangeActions",
            () => ({
                actions: {
                    cloudFormation: "CloudFormation",
                    diff: "Diff",
                    json: "JSON",
                    terraform: "Terraform"
                }
            }));
    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            direction="row">
            {!_.isNil(riskFile) && (
                <DocumentViewerDialog
                    document={utf8.decode(atob(riskFile.contentBytes))}
                    documentFileName={riskFile.name}
                    title={riskFile.name}
                    onClose={() => setRiskFile(undefined)}/>)}
            {policyDocumentDiff && (
                <PrincipalRiskPolicyDiffDialog
                    existingPolicyItems={
                        _<RiskPolicyDiffPolicyItem>([]).
                            concatIf(
                                !_.isNil(policyInfo.existingDocument),
                                () =>
                                    new RiskPolicyDiffPolicyItem(
                                        inlinePolicyName,
                                        policyInfo.existingDocument!.raw)).
                            concat(
                                managedPolicies.map(
                                    existingPolicy =>
                                        new RiskPolicyDiffPolicyItem(
                                            existingPolicy.name!,
                                            existingPolicy.document.raw))).
                            value()}
                    newPolicyItem={
                        _.isNil(policyInfo.newDocument)
                            ? undefined
                            : new RiskPolicyDiffPolicyItem(
                                inlinePolicyName,
                                policyInfo.newDocument.raw)}
                    policyFileName={inlinePolicyName}
                    onClose={() => setPolicyDocumentDiff(false)}/>)}
            {!_.isNil(policyInfo.newDocument) && (
                <ActionButton
                    tooltip={localization.actions.json()}
                    onClick={() => setRiskFile(riskModel.fileTypeToFileMap![Contract.RiskFileType.AwsPolicyDocumentRaw])}>
                    <PolicyDocumentIcon
                        sx={{
                            color: theme.palette.text.primary,
                            fontSize: "18px"
                        }}/>
                </ActionButton>)}
            <ActionButton
                tooltip={localization.actions.diff()}
                onClick={() => setPolicyDocumentDiff(true)}>
                <DiffViewerIcon
                    sx={{
                        color: theme.palette.text.primary,
                        fontSize: "18px"
                    }}/>
            </ActionButton>
            {!_.isNil(policyInfo.newDocument) && (
                <Fragment>
                    <ActionButton
                        tooltip={localization.actions.terraform()}
                        onClick={() => setRiskFile(riskModel.fileTypeToFileMap![Contract.RiskFileType.Terraform])}>
                        <CodeTypeIcon
                            codeType={Contract.CodeType.Terraform}
                            sx={{ fontSize: "18px" }}/>
                    </ActionButton>
                    <ActionButton
                        tooltip={localization.actions.cloudFormation()}
                        onClick={() => setRiskFile(riskModel.fileTypeToFileMap![Contract.RiskFileType.AwsCloudFormation])}>
                        <CodeTypeIcon
                            codeType={Contract.CodeType.CloudFormation}
                            sx={{ fontSize: "18px" }}/>
                    </ActionButton>
                </Fragment>)}
        </Stack>);
}

type DetachPermissionSetManagedPolicyChangeActionsProps = {
    policyId: string;
    policyInfo: Contract.AwsExcessivePermissionPrincipalRiskResolutionPolicyInfo;
};

function DetachPermissionSetManagedPolicyChangeActions({ policyId, policyInfo }: DetachPermissionSetManagedPolicyChangeActionsProps) {
    const policyModel = entityModelStore.useGet(policyId);
    const policy = policyModel.entity as Contract.AwsIamManagedPolicy;
    const [policyDocument, setPolicyDocument] = useState(false);
    const [policyDocumentDiff, setPolicyDocumentDiff] = useState(false);
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.access.useAwsExcessivePermissionPermissionSetRiskDefinition.detachPermissionSetManagedPolicyChangeActions",
            () => ({
                actions: {
                    diff: "Diff",
                    json: "JSON"
                }
            }));
    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            direction="row">
            {policyDocument && (
                <DocumentViewerDialog
                    document={policy.document.raw}
                    documentFileName={`${policyModel.entity.displayName}.json`}
                    title={policyModel.entity.displayName}
                    onClose={() => setPolicyDocument(false)}/>)}
            {policyDocumentDiff && (
                <PrincipalRiskPolicyDiffDialog
                    existingPolicyItems={[
                        new RiskPolicyDiffPolicyItem(
                            policy.name!,
                            policyInfo.existingDocument!.raw)]}
                    newPolicyItem={
                        _.isNil(policyInfo.newDocument)
                            ? undefined
                            : new RiskPolicyDiffPolicyItem(
                                policy.name!,
                                policyInfo.newDocument.raw)}
                    policyFileName={policy.name!}
                    onClose={() => setPolicyDocumentDiff(false)}/>)}
            <ActionButton
                tooltip={localization.actions.json()}
                onClick={() => setPolicyDocument(true)}>
                <PolicyDocumentIcon
                    sx={{
                        color: theme.palette.text.primary,
                        fontSize: "18px"
                    }}/>
            </ActionButton>
            <ActionButton
                tooltip={localization.actions.diff()}
                onClick={() => setPolicyDocumentDiff(true)}>
                <DiffViewerIcon
                    sx={{
                        color: theme.palette.text.primary,
                        fontSize: "18px"
                    }}/>
            </ActionButton>
        </Stack>);
}