import { Optional } from "@infrastructure";
import _ from "lodash";
import { useMemo } from "react";
import { useGetInheritedOverridableValue } from "../../..";
import { Contract, RiskPoliciesType, RiskPolicyItem, UserHelper } from "../../../../../../../../common";

export function useNumeric<T>(item: RiskPolicyItem, getValue: (riskPolicyConfiguration: Contract.RiskPolicyConfiguration) => Optional<T>, riskPolicyConfiguration: Contract.RiskPolicyConfiguration, riskPoliciesType: RiskPoliciesType, toNumber: (value: T) => number) {
    const userSecurityAdministrationWrite =
        useMemo(
            () =>
                UserHelper.hasScopePermissions(
                    riskPolicyConfiguration.scopeId,
                    Contract.IdentityPermission.SecurityAdministrationWrite),
            [riskPolicyConfiguration]);

    const { inherited, inheritedScopeId, inheritedValue, value } =
        useGetInheritedOverridableValue(
            getValue,
            riskPoliciesType,
            riskPolicyConfiguration,
            item);

    return useMemo(
        () => {
            const defaultSelectedValue = value ?? inheritedValue;
            return ({
                defaultSelectedValue:
                    _.isNil(defaultSelectedValue)
                        ? undefined
                        : toNumber(defaultSelectedValue),
                inheritedScopeId,
                inheritedValue,
                selectedValueInherit: inherited,
                userSecurityAdministrationWrite
            });
        },
        [inheritedScopeId, inheritedValue, value, userSecurityAdministrationWrite]);
}