import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function PhotonIcon(props: SvgIconProps) {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <circle
                cx="11.9742"
                cy="11.4293"
                fill="white"
                r="9.37933"
                stroke="#686566"
                strokeWidth="2.1"/>
            <path
                d="M10.0434 10.6569L1.15918 11.0432V8.72559L10.0434 10.6569Z"
                fill="url(#paint0_linear_52885_254758)"/>
            <path
                d="M13.9057 10.6566L21.3719 4.58956L22.7168 6.47705L13.9057 10.6566Z"
                fill="url(#paint1_linear_52885_254758)"/>
            <path
                d="M12.747 11.4292L10.3132 17.6869L8.30371 16.5322L12.747 11.4292Z"
                fill="url(#paint2_linear_52885_254758)"/>
            <path
                d="M11.2601 10.5401L4.0201 6.86162L5.16699 4.84766L11.2601 10.5401Z"
                fill="url(#paint3_linear_52885_254758)"/>
            <path
                d="M14.6788 11.8156L22.9657 15.4644L21.9229 17.5342L14.6788 11.8156Z"
                fill="url(#paint4_linear_52885_254758)"/>
            <path
                d="M12.3569 9.88726L9.82401 5.45507L11.9741 4.47643L12.3569 9.88726Z"
                fill="url(#paint5_linear_52885_254758)"/>
            <path
                d="M11.588 11.4291L3.21635 17.6537L1.7207 15.8833L11.588 11.4291Z"
                fill="url(#paint6_linear_52885_254758)"/>
            <path
                d="M13.5195 12.9744L17.2144 20.7561L15.1992 21.9009L13.5195 12.9744Z"
                fill="url(#paint7_linear_52885_254758)"/>
            <circle
                cx="1.15881"
                cy="9.8844"
                fill="#1E396F"
                r="1.15881"/>
            <circle
                cx="22.044"
                cy="5.53584"
                fill="#356288"
                r="1.15881"
                transform="rotate(144.529 22.044 5.53584)"/>
            <circle
                cx="9.30768"
                cy="17.1068"
                fill="#5792D1"
                r="1.15881"
                transform="rotate(-60.1172 9.30768 17.1068)"/>
            <circle
                cx="4.59369"
                cy="5.85142"
                fill="#9FD0DF"
                r="1.15881"
                transform="rotate(29.6602 4.59369 5.85142)"/>
            <circle
                cx="22.4436"
                cy="16.4998"
                fill="#9FD0DF"
                r="1.15881"
                transform="rotate(-153.258 22.4436 16.4998)"/>
            <circle
                cx="10.8443"
                cy="4.89182"
                fill="#BED045"
                r="1.15881"
                transform="rotate(61.174 10.8443 4.89182)"/>
            <circle
                cx="2.4055"
                cy="16.7692"
                fill="#78B745"
                r="1.15881"
                transform="rotate(-40.1907 2.4055 16.7692)"/>
            <circle
                cx="16.2057"
                cy="21.3316"
                fill="#BED045"
                r="1.15881"
                transform="rotate(-119.601 16.2057 21.3316)"/>
            <path
                d="M19.0043 4.55386C19.0043 4.80986 18.7967 5.01738 18.5407 5.01738C18.2848 5.01738 18.0772 4.80986 18.0772 4.55386C18.0772 4.29786 18.2848 4.09033 18.5407 4.09033C18.7967 4.09033 19.0043 4.29786 19.0043 4.55386Z"
                fill="#78B745"/>
            <path
                d="M15.0647 7.95181C15.0647 8.2078 14.8572 8.41533 14.6012 8.41533C14.3452 8.41533 14.1377 8.2078 14.1377 7.95181C14.1377 7.69581 14.3452 7.48828 14.6012 7.48828C14.8572 7.48828 15.0647 7.69581 15.0647 7.95181Z"
                fill="#78B745"/>
            <path
                d="M15.1419 10.194C15.1419 10.45 14.9344 10.6575 14.6784 10.6575C14.4224 10.6575 14.2148 10.45 14.2148 10.194C14.2148 9.938 14.4224 9.73047 14.6784 9.73047C14.9344 9.73047 15.1419 9.938 15.1419 10.194Z"
                fill="#1E396F"/>
            <path
                d="M13.5198 9.88296C13.5198 10.139 13.3123 10.3465 13.0563 10.3465C12.8003 10.3465 12.5928 10.139 12.5928 9.88296C12.5928 9.62696 12.8003 9.41943 13.0563 9.41943C13.3123 9.41943 13.5198 9.62696 13.5198 9.88296Z"
                fill="#5792D1"/>
            <path
                d="M3.63115 12.7077C3.63115 12.9637 3.42363 13.1712 3.16763 13.1712C2.91163 13.1712 2.7041 12.9637 2.7041 12.7077C2.7041 12.4517 2.91163 12.2441 3.16763 12.2441C3.42363 12.2441 3.63115 12.4517 3.63115 12.7077Z"
                fill="#78B745"/>
            <path
                d="M8.49736 14.3976C8.49736 14.6536 8.28984 14.8611 8.03384 14.8611C7.77784 14.8611 7.57031 14.6536 7.57031 14.3976C7.57031 14.1416 7.77784 13.9341 8.03384 13.9341C8.28984 13.9341 8.49736 14.1416 8.49736 14.3976Z"
                fill="#5792D1"/>
            <path
                d="M8.26494 12.8947C8.26494 13.1507 8.05741 13.3582 7.80142 13.3582C7.54542 13.3582 7.33789 13.1507 7.33789 12.8947C7.33789 12.6387 7.54542 12.4312 7.80142 12.4312C8.05741 12.4312 8.26494 12.6387 8.26494 12.8947Z"
                fill="#1E396F"/>
            <path
                d="M9.19268 10.8971C9.19268 11.1531 8.98515 11.3606 8.72915 11.3606C8.47315 11.3606 8.26562 11.1531 8.26562 10.8971C8.26562 10.6411 8.47315 10.4336 8.72915 10.4336C8.98515 10.4336 9.19268 10.6411 9.19268 10.8971Z"
                fill="#9FD0DF"/>
            <path
                d="M12.0491 11.1232C12.0491 11.3792 11.8416 11.5867 11.5856 11.5867C11.3296 11.5867 11.1221 11.3792 11.1221 11.1232C11.1221 10.8672 11.3296 10.6597 11.5856 10.6597C11.8416 10.6597 12.0491 10.8672 12.0491 11.1232Z"
                fill="#356288"/>
            <path
                d="M10.428 12.8947C10.428 13.1507 10.2205 13.3582 9.9645 13.3582C9.7085 13.3582 9.50098 13.1507 9.50098 12.8947C9.50098 12.6387 9.7085 12.4312 9.9645 12.4312C10.2205 12.4312 10.428 12.6387 10.428 12.8947Z"
                fill="#BED045"/>
            <path
                d="M10.1194 14.2077C10.1194 14.4637 9.91191 14.6712 9.65591 14.6712C9.39991 14.6712 9.19238 14.4637 9.19238 14.2077C9.19238 13.9517 9.39991 13.7441 9.65591 13.7441C9.91191 13.7441 10.1194 13.9517 10.1194 14.2077Z"
                fill="#78B745"/>
            <path
                d="M12.0491 14.3976C12.0491 14.6536 11.8416 14.8611 11.5856 14.8611C11.3296 14.8611 11.1221 14.6536 11.1221 14.3976C11.1221 14.1416 11.3296 13.9341 11.5856 13.9341C11.8416 13.9341 12.0491 14.1416 12.0491 14.3976Z"
                fill="#356288"/>
            <path
                d="M12.9759 12.7077C12.9759 12.9637 12.7684 13.1712 12.5124 13.1712C12.2564 13.1712 12.0488 12.9637 12.0488 12.7077C12.0488 12.4517 12.2564 12.2441 12.5124 12.2441C12.7684 12.2441 12.9759 12.4517 12.9759 12.7077Z"
                fill="#5792D1"/>
            <path
                d="M13.594 11.3585C13.594 11.6145 13.3865 11.8221 13.1305 11.8221C12.8745 11.8221 12.667 11.6145 12.667 11.3585C12.667 11.1025 12.8745 10.895 13.1305 10.895C13.3865 10.895 13.594 11.1025 13.594 11.3585Z"
                fill="#9FD0DF"/>
            <path
                d="M14.7532 12.8947C14.7532 13.1507 14.5457 13.3582 14.2897 13.3582C14.0337 13.3582 13.8262 13.1507 13.8262 12.8947C13.8262 12.6387 14.0337 12.4312 14.2897 12.4312C14.5457 12.4312 14.7532 12.6387 14.7532 12.8947Z"
                fill="#BED045"/>
            <path
                d="M15.7171 14.3976C15.7171 14.6536 15.5096 14.8611 15.2536 14.8611C14.9976 14.8611 14.79 14.6536 14.79 14.3976C14.79 14.1416 14.9976 13.9341 15.2536 13.9341C15.5096 13.9341 15.7171 14.1416 15.7171 14.3976Z"
                fill="#1E396F"/>
            <path
                d="M12.9759 16.0119C12.9759 16.2679 12.7684 16.4754 12.5124 16.4754C12.2564 16.4754 12.0488 16.2679 12.0488 16.0119C12.0488 15.7559 12.2564 15.5483 12.5124 15.5483C12.7684 15.5483 12.9759 15.7559 12.9759 16.0119Z"
                fill="#78B745"/>
            <path
                d="M13.4397 18.9572C13.4397 19.2132 13.2322 19.4207 12.9762 19.4207C12.7202 19.4207 12.5127 19.2132 12.5127 18.9572C12.5127 18.7012 12.7202 18.4937 12.9762 18.4937C13.2322 18.4937 13.4397 18.7012 13.4397 18.9572Z"
                fill="#5792D1"/>
            <path
                d="M20.5472 18.8429C20.5472 19.0989 20.3396 19.3064 20.0836 19.3064C19.8276 19.3064 19.6201 19.0989 19.6201 18.8429C19.6201 18.5869 19.8276 18.3794 20.0836 18.3794C20.3396 18.3794 20.5472 18.5869 20.5472 18.8429Z"
                fill="#78B745"/>
            <path
                d="M17.1067 15.5475C17.1067 15.8035 16.8992 16.011 16.6432 16.011C16.3872 16.011 16.1797 15.8035 16.1797 15.5475C16.1797 15.2915 16.3872 15.084 16.6432 15.084C16.8992 15.084 17.1067 15.2915 17.1067 15.5475Z"
                fill="#5792D1"/>
            <path
                d="M17.3802 12.5871C17.3802 12.8431 17.1727 13.0506 16.9167 13.0506C16.6607 13.0506 16.4531 12.8431 16.4531 12.5871C16.4531 12.3311 16.6607 12.1235 16.9167 12.1235C17.1727 12.1235 17.3802 12.3311 17.3802 12.5871Z"
                fill="#78B745"/>
            <path
                d="M17.0706 10.7311C17.0706 10.9871 16.8631 11.1946 16.6071 11.1946C16.3511 11.1946 16.1436 10.9871 16.1436 10.7311C16.1436 10.4751 16.3511 10.2676 16.6071 10.2676C16.8631 10.2676 17.0706 10.4751 17.0706 10.7311Z"
                fill="#5792D1"/>
            <path
                d="M11.2786 9.03481C11.2786 9.29081 11.0711 9.49834 10.8151 9.49834C10.5591 9.49834 10.3516 9.29081 10.3516 9.03481C10.3516 8.77882 10.5591 8.57129 10.8151 8.57129C11.0711 8.57129 11.2786 8.77882 11.2786 9.03481Z"
                fill="#1E396F"/>
            <path
                d="M9.34795 9.42153C9.34795 9.67753 9.14042 9.88506 8.88442 9.88506C8.62843 9.88506 8.4209 9.67753 8.4209 9.42153C8.4209 9.16554 8.62843 8.95801 8.88442 8.95801C9.14042 8.95801 9.34795 9.16554 9.34795 9.42153Z"
                fill="#5792D1"/>
            <path
                d="M13.6741 7.17935C13.6741 7.43534 13.4666 7.64287 13.2106 7.64287C12.9546 7.64287 12.7471 7.43534 12.7471 7.17935C12.7471 6.92335 12.9546 6.71582 13.2106 6.71582C13.4666 6.71582 13.6741 6.92335 13.6741 7.17935Z"
                fill="#5792D1"/>
            <defs>
                <linearGradient
                    gradientUnits="userSpaceOnUse"
                    id="paint0_linear_52885_254758"
                    x1="0.735613"
                    x2="9.55023"
                    y1="9.90754"
                    y2="10.4367">
                    <stop stopColor="#356288"/>
                    <stop
                        offset="1"
                        stopColor="white"/>
                </linearGradient>
                <linearGradient
                    gradientUnits="userSpaceOnUse"
                    id="paint1_linear_52885_254758"
                    x1="22.4037"
                    x2="14.292"
                    y1="5.24883"
                    y2="10.2704">
                    <stop stopColor="#356288"/>
                    <stop
                        offset="1"
                        stopColor="white"/>
                </linearGradient>
                <linearGradient
                    gradientUnits="userSpaceOnUse"
                    id="paint2_linear_52885_254758"
                    x1="9.17084"
                    x2="12.7141"
                    y1="17.3955"
                    y2="11.8233">
                    <stop stopColor="#5792D1"/>
                    <stop
                        offset="1"
                        stopColor="white"/>
                </linearGradient>
                <linearGradient
                    gradientUnits="userSpaceOnUse"
                    id="paint3_linear_52885_254758"
                    x1="3.95136"
                    x2="10.9162"
                    y1="5.52109"
                    y2="10.1778">
                    <stop stopColor="#9FD0DF"/>
                    <stop
                        offset="0.994792"
                        stopColor="white"/>
                </linearGradient>
                <linearGradient
                    gradientUnits="userSpaceOnUse"
                    id="paint4_linear_52885_254758"
                    x1="23.1767"
                    x2="15.2029"
                    y1="16.8371"
                    y2="11.9855">
                    <stop stopColor="#9FD0DF"/>
                    <stop
                        offset="0.994792"
                        stopColor="white"/>
                </linearGradient>
                <linearGradient
                    gradientUnits="userSpaceOnUse"
                    id="paint5_linear_52885_254758"
                    x1="10.8104"
                    x2="13.9649"
                    y1="4.46354"
                    y2="13.6236">
                    <stop
                        offset="0.0226491"
                        stopColor="#C9D56F"/>
                    <stop
                        offset="0.984375"
                        stopColor="white"/>
                </linearGradient>
                <linearGradient
                    gradientUnits="userSpaceOnUse"
                    id="paint6_linear_52885_254758"
                    x1="0.772377"
                    x2="16.6095"
                    y1="17.9957"
                    y2="8.33894">
                    <stop stopColor="#78B745"/>
                    <stop
                        offset="1"
                        stopColor="white"
                        stopOpacity="0"/>
                </linearGradient>
                <linearGradient
                    gradientUnits="userSpaceOnUse"
                    id="paint7_linear_52885_254758"
                    x1="16.6096"
                    x2="11.9744"
                    y1="21.8586"
                    y2="6.40778">
                    <stop
                        offset="0.0226491"
                        stopColor="#C9D56F"/>
                    <stop
                        offset="0.984375"
                        stopColor="white"/>
                </linearGradient>
            </defs>
        </SvgIcon>);
}