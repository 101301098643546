import { NumberFormatter, useLocalization } from "@infrastructure";
import React from "react";
import { useGcpCommonEncryptedResourceInfoElements, useGcpDefaultResourceInfoItemElements, useGcpDefaultScopeResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../..";
import { Contract, InfoItem } from "../../../../../../../../../../common";
import { useGcpComputeDiskStatusTranslator, useGcpComputeDiskTypeTranslator } from "../../../../../../hooks";
import { EntitiesInfoItem, Info } from "../../../../components";
import { GcpScopeResourceRoleBindings } from "../../components";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";

export function useGcpComputeDiskDefinition(scopeResourceModel: Contract.GcpScopeResourceModel, options?: DefinitionOptions) {
    const commonEncryptedResourceInfoElements = useGcpCommonEncryptedResourceInfoElements(scopeResourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(scopeResourceModel);
    const defaultResourceInfoItemElements = useGcpDefaultResourceInfoItemElements(scopeResourceModel);
    const defaultScopeResourceInfoItemElements = useGcpDefaultScopeResourceInfoItemElements(scopeResourceModel);
    const diskModel = scopeResourceModel as Contract.GcpComputeDiskModel;
    const disk = diskModel.entity as Contract.GcpComputeDisk;

    const computeDiskStatusTranslator = useGcpComputeDiskStatusTranslator();
    const computeDiskTypeTranslator = useGcpComputeDiskTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpComputeDiskDefinition",
            () => ({
                info: {
                    items: {
                        instanceIdReferences: "VM Instances",
                        size: "Size",
                        sourceIdReference: "Source",
                        status: "Status",
                        type: "Type"
                    }
                },
                tabs: {
                    resourceRoleBindings: "Resource Role Bindings"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <GcpScopeResourceRoleBindings
                    csvExportFilePrefix={localization.tabs.resourceRoleBindings()}
                    scopeResourceModel={scopeResourceModel}/>,
                localization.tabs.resourceRoleBindings(),
                "resourceRoleBindings")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    ...defaultScopeResourceInfoItemElements,
                    commonEncryptedResourceInfoElements.encryptionExists,
                    commonEncryptedResourceInfoElements.kmsEncryptionKeys,
                    <InfoItem
                        key="status"
                        location="all"
                        title={localization.info.items.status()}
                        value={computeDiskStatusTranslator(disk.status)}/>,
                    <InfoItem
                        key="type"
                        location="profile"
                        title={localization.info.items.type()}
                        value={computeDiskTypeTranslator(disk.type)}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={diskModel.sourceIdReference}
                        entityTypeName={Contract.TypeNames.GcpEntity}
                        key="sourceIdReference"
                        location="all"
                        title={localization.info.items.sourceIdReference()}/>,
                    <InfoItem
                        key="size"
                        location="profile"
                        title={localization.info.items.size()}
                        value={NumberFormatter.storage(disk.size)}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={diskModel.instanceIdReferences}
                        entityTypeName={Contract.TypeNames.GcpComputeInstance}
                        key="instanceIdReferences"
                        location="all"
                        title={localization.info.items.instanceIdReferences()}/>
                ]}
                options={options?.infoOptions}>
            </Info>
    });
}