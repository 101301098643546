//@ts-nocheck
import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";
import { Contract, EntitiesIcon, useTheme } from "../../../../common";

type OktaDirectoryEntityIconProps =
    SvgIconProps & {
        blurred: boolean;
        entityTypeName: string;
        variant?: string;
    };

export function OktaDirectoryEntityIcon({ blurred, entityTypeName, variant, ...props }: OktaDirectoryEntityIconProps) {
    switch (entityTypeName) {
        case Contract.TypeNames.OktaDirectoryUser:
            return (
                <SvgIcon {...props} filter={blurred ? "url(#greyscale)" : undefined}>
                    <path d="M7 10C8.93298 10 10.5 8.43298 10.5 6.5C10.5 4.56702 8.93298 3 7 3C5.06702 3 3.5 4.56702 3.5 6.5C3.5 8.43298 5.06702 10 7 10Z"  />
                    <path d="M6 11C2.68629 11 0 13.6863 0 17V18.4C0 18.7314 0.26863 19 0.6 19H9.57074C9.52412 18.6734 9.5 18.3396 9.5 18C9.5 15.8163 10.4976 13.8676 12.0623 12.5843C10.9936 11.6007 9.56696 11 8 11H6Z"  />
                    <path d="M13.8579 15.6958C13.3195 16.3124 12.993 17.1187 12.993 18C12.993 18.3473 13.0437 18.683 13.1381 19H14V17C14 16.5521 13.9509 16.1157 13.8579 15.6958Z"  />
                    <path d="M11.5 18C11.5 15.2366 13.7366 13 16.5 13C19.2634 13 21.5 15.2366 21.5 18C21.5 20.7634 19.2634 23 16.5 23C13.7366 23 11.5 20.7634 11.5 18ZM13.995 18C13.995 19.3817 15.1183 20.505 16.5 20.505C17.8817 20.505 19.005 19.3817 19.005 18C19.005 16.6183 17.8817 15.495 16.5 15.495C15.1183 15.495 13.995 16.6183 13.995 18Z" fill="#007DC1" fillRule="evenodd" clipRule="evenodd" />
                </SvgIcon>);
        case Contract.TypeNames.OktaDirectoryGroup:
            return (
                <SvgIcon {...props} filter={blurred ? "url(#greyscale)" : undefined}>
                    <path d="M7.7462 6.11202C7.7462 7.83087 6.35285 9.22423 4.634 9.22423C2.91533 9.22423 1.52197 7.83087 1.52197 6.11202C1.52197 4.39335 2.91533 3 4.634 3C6.35285 3 7.7462 4.39335 7.7462 6.11202Z"  />
                    <path d="M6.05997 16.6169L0.526808 16.6168C0.237175 16.6168 0.00162506 16.3832 -0.000488281 16.0935V14.5127C-0.000488281 13.4242 0.431767 12.3806 1.20141 11.6111C1.97088 10.8414 3.01473 10.4092 4.103 10.4092H5.16932C5.91879 10.4085 6.65418 10.6136 7.2951 11.0022C7.79998 11.3081 8.23099 11.7186 8.56053 12.2039C8.19806 12.4807 7.86239 12.8015 7.56071 13.1623C6.74921 14.1328 6.23012 15.3372 6.05997 16.6169Z"  />
                    <path d="M15.3871 7.51654C15.3871 9.39609 13.8634 10.9198 11.9838 10.9198C10.1043 10.9198 8.58057 9.39609 8.58057 7.51654C8.58057 5.63699 10.1043 4.11328 11.9838 4.11328C13.8634 4.11328 15.3871 5.63699 15.3871 7.51654Z"  />
                    <path d="M11.0556 18.8871C11.0189 18.5966 11 18.3005 11 18C11 15.6834 12.1227 13.6313 13.8542 12.3575C13.1014 12.0588 12.2819 11.9424 11.4638 12.0268C10.215 12.1555 9.05838 12.7429 8.21778 13.6751C7.37699 14.6075 6.9119 15.8185 6.9126 17.074V18.2379C6.9126 18.41 6.98093 18.5751 7.10264 18.6968C7.22437 18.8186 7.38965 18.8871 7.56178 18.8871H11.0556Z"  />
                    <path d="M13 18C13 15.2366 15.2366 13 18 13C20.7634 13 23 15.2366 23 18C23 20.7634 20.7634 23 18 23C15.2366 23 13 20.7634 13 18ZM15.495 18C15.495 19.3817 16.6183 20.505 18 20.505C19.3817 20.505 20.505 19.3817 20.505 18C20.505 16.6183 19.3817 15.495 18 15.495C16.6183 15.495 15.495 16.6183 15.495 18Z" fill="#007DC1" fillRule="evenodd" clipRule="evenodd" />
                </SvgIcon>);
    }

    return <EntitiesIcon {...props} filter={blurred ? "url(#greyscale)" : undefined}/>;
}