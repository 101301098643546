import { NumberFormatter, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useAwsCommonAccessResourceInfoElements, useAwsCommonCloudWatchLoggingResourceInfoElements, useAwsCommonEncryptedResourceInfoElements, useAwsCommonNetworkingInfoItemElements, useAwsDefaultResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../..";
import { useCustomEntityPropertyInfoItemElements } from "..";
import { Contract, InfoItem, VerticalTopItemsInfoItem } from "../../../../../../../../../../common";
import { useAwsEsDomainEngineTypeTranslator } from "../../../../../../hooks";
import { EntitiesInfoItem, Info } from "../../../../components";
import { AwsIamPolicyDocument, NetworkingInfoCard } from "../../components";

export function useAwsEsDomainDefinition(resourceModel: Contract.AwsResourceModel, options?: DefinitionOptions) {
    const commonAccessResourceInfoElements = useAwsCommonAccessResourceInfoElements(resourceModel);
    const commonCloudWatchLoggingResourceInfoElements = useAwsCommonCloudWatchLoggingResourceInfoElements(resourceModel);
    const commonEncryptedResourceInfoElements = useAwsCommonEncryptedResourceInfoElements(resourceModel);
    const commonNetworkCardInfoItems = useAwsCommonNetworkingInfoItemElements({ resourceModel });
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useAwsDefaultResourceInfoItemElements(resourceModel);
    const domain = resourceModel.entity as Contract.AwsEsDomain;

    const esDomainEngineTypeTranslator = useAwsEsDomainEngineTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsEsDomainDefinition",
            () => ({
                info: {
                    items: {
                        availabilityZones: "Availability Zones",
                        engineType: "Engine",
                        engineVersion: "Version",
                        httpsEnabled: {
                            false: "Not enabled",
                            title: "Require HTTPS",
                            true: "Enabled"
                        },
                        nodeCommunicationEncryptionEnabled: {
                            false: "Not enabled",
                            title: "Node-to-Node Encryption",
                            true: "Enabled"
                        },
                        storageSize: "Size",
                        vpc: "VPC"
                    }
                },
                tabs: {
                    policyDocument: "Policy"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <AwsIamPolicyDocument policyDocument={domain.policyDocument}/>,
                localization.tabs.policyDocument(),
                "policyDocument")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    ...commonAccessResourceInfoElements,
                    <InfoItem
                        key="engineType"
                        title={localization.info.items.engineType()}
                        value={esDomainEngineTypeTranslator(domain.engineType)}/>,
                    <InfoItem
                        key="engineVersion"
                        title={localization.info.items.engineVersion()}
                        value={domain.engineVersion}/>,
                    ...commonEncryptedResourceInfoElements,
                    <InfoItem
                        key="nodeCommunicationEncryptionEnabled"
                        title={localization.info.items.nodeCommunicationEncryptionEnabled.title()}
                        value={
                            domain.nodeCommunicationEncryptionEnabled
                                ? localization.info.items.nodeCommunicationEncryptionEnabled.true()
                                : localization.info.items.nodeCommunicationEncryptionEnabled.false()}/>,
                    commonCloudWatchLoggingResourceInfoElements.cloudWatchLoggingEnabled,
                    commonCloudWatchLoggingResourceInfoElements.cloudWatchLogTypes,
                    <InfoItem
                        key="storageSize"
                        title={localization.info.items.storageSize()}
                        value={NumberFormatter.storage(domain.storageSize)}/>,
                    <InfoItem
                        key="transitEncryptionEnabled"
                        title={localization.info.items.httpsEnabled.title()}
                        value={
                            domain.httpsEnabled
                                ? localization.info.items.httpsEnabled.true()
                                : localization.info.items.httpsEnabled.false()}/>,
                    <VerticalTopItemsInfoItem
                        items={
                            _.map(
                                domain.availabilityZones,
                                availabilityZone => availabilityZone.name)}
                        key="availabilityZones"
                        title={localization.info.items.availabilityZones()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={domain.vpcIds}
                        entityTypeName={Contract.TypeNames.AwsEc2Vpc}
                        key="vpc"
                        location="miniGlance"
                        title={localization.info.items.vpc()}/>
                ]}
                options={options?.infoOptions}>
                <NetworkingInfoCard>
                    {commonNetworkCardInfoItems.getVpcs()}
                    {commonNetworkCardInfoItems.getSubnets()}
                    {commonNetworkCardInfoItems.getSecurityGroups()}
                </NetworkingInfoCard>
            </Info>
    });
}