import { useLocalization } from "@infrastructure";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition } from "../../../..";
import { useCustomEntityPropertyInfoItemElements } from "../..";
import { useAwsDefaultResourceInfoItemElements } from "../";
import { Contract, InfoItem } from "../../../../../../../../../../../common";
import { useAwsEc2VpcBlockPublicAccessPolicyNetworkDirectionTranslator } from "../../../../../../../hooks";
import { Info } from "../../../../../components";
import { ExclusionsInfoCard } from "./components";

export function useAwsEc2VpcBlockPublicAccessPolicyDefinition(resourceModel: Contract.AwsResourceModel, options?: DefinitionOptions) {
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useAwsDefaultResourceInfoItemElements(resourceModel);
    const vpcBlockPublicAccessPolicyModel = resourceModel as Contract.AwsEc2VpcBlockPublicAccessPolicyModel;
    const vpcBlockPublicAccessPolicy = vpcBlockPublicAccessPolicyModel.entity as Contract.AwsEc2VpcBlockPublicAccessPolicy;

    const vpcBlockPublicAccessPolicyNetworkDirectionTranslator = useAwsEc2VpcBlockPublicAccessPolicyNetworkDirectionTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsEc2VpcBlockPublicAccessPolicyDefinition",
            () => ({
                info: {
                    items: {
                        blockNetworkDirection: "Internet Gateway Block Direction",
                        enabled: {
                            false: "Disabled",
                            title: "Status",
                            true: "Enabled"
                        }
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="enabled"
                        title={localization.info.items.enabled.title()}
                        value={
                            vpcBlockPublicAccessPolicyModel.enabled
                                ? localization.info.items.enabled.true()
                                : localization.info.items.enabled.false()}/>,
                    <InfoItem
                        key="blockNetworkDirection"
                        title={localization.info.items.blockNetworkDirection()}
                        value={vpcBlockPublicAccessPolicyNetworkDirectionTranslator(vpcBlockPublicAccessPolicy.blockNetworkDirection)}/>
                ]}
                options={options?.infoOptions}>
                <ExclusionsInfoCard resourceIdToExclusionMap={vpcBlockPublicAccessPolicyModel.resourceIdToExclusionMap}/>
            </Info>
    });
}