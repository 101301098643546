import { Divider, Stack, Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React from "react";
import { Contract } from "../../../../../../../../../../../controllers";
import { useEntityTypeNameTranslator } from "../../../../../../../../../../../hooks";
import { TenantIcon } from "../../../../../../../../../../../icons";
import { useTheme } from "../../../../../../../../../../../themes";
import { EntityTypeMetadataModelHelper } from "../../../../../../../../../../../utilities";
import { Link } from "../../../../../../../../../../Entity/components";
import { EntityIcon } from "../../../../../../../../../../EntityIcon";
import { RiskCombinationHelper } from "../utilities";

type EntityTypeNamesProps = {
    entityTypeNameToCountMap: Dictionary<number>;
    riskCombination: Contract.RiskCombination;
};

export function EntityTypeNames({ entityTypeNameToCountMap, riskCombination }: EntityTypeNamesProps) {
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const theme = useTheme();
    return (
        <Stack
            spacing={2}
            sx={{ padding: theme.spacing(1) }}>
            {_.map(
                entityTypeNameToCountMap,
                (entityCount, entityTypeName) =>
                    <Link
                        key={entityTypeName}
                        sx={{ color: theme.palette.text.primary }}
                        urlOrGetUrl={RiskCombinationHelper.getEntityTypeNameRelativeUrl(entityTypeName, riskCombination)}>
                        <Stack
                            alignItems="center"
                            direction="row"
                            spacing={1}>
                            <TenantIcon
                                sx={{ fontSize: "24px" }}
                                tenantType={EntityTypeMetadataModelHelper.get(entityTypeName).tenantType}/>
                            <Divider
                                flexItem={true}
                                orientation="vertical"
                                sx={{
                                    alignSelf: "center",
                                    backgroundColor: "#D0D6E1",
                                    height: theme.spacing(2)
                                }}/>
                            <EntityIcon
                                entityTypeName={entityTypeName}
                                sx={{ fontSize: "18px" }}/>
                            <Typography>
                                {entityTypeNameTranslator(
                                    entityTypeName,
                                    {
                                        count: entityCount,
                                        includeCount: true
                                    })}
                            </Typography>
                        </Stack>
                    </Link>)}
        </Stack>);
}