import { TimeFormatter, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../..";
import { useGcpCommonServiceAccountOriginatorScopeResourceInfoElements, useGcpDefaultResourceInfoItemElements, useGcpDefaultScopeResourceInfoItemElements } from "..";
import { Contract, InfoItem } from "../../../../../../../../../../../common";
import { useGcpAppEngineApplicationResourceStatusTranslator, useGcpServerlessNetworkedResourceNetworkInboundAccessTypeTranslator } from "../../../../../../../hooks";
import { VulnerabilitiesCell } from "../../../../../../VulnerabilitiesCell";
import { EntitiesInfoItem, Info } from "../../../../../components";
import { GcpScopeResourceRoleBindings } from "../../../components";
import { useCustomEntityPropertyInfoItemElements } from "../../useCustomEntityPropertyInfoItemElements";
import { Revisions } from "./components";

export function useGcpAppEngineApplicationServiceDefinition(scopeResourceModel: Contract.GcpScopeResourceModel, options?: DefinitionOptions) {
    const commonServiceAccountOriginatorScopeResourceInfoElements = useGcpCommonServiceAccountOriginatorScopeResourceInfoElements(scopeResourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(scopeResourceModel);
    const defaultResourceInfoItemElements = useGcpDefaultResourceInfoItemElements(scopeResourceModel);
    const defaultScopeResourceInfoItemElements = useGcpDefaultScopeResourceInfoItemElements(scopeResourceModel);
    const serviceModel = scopeResourceModel as Contract.GcpAppEngineApplicationServiceModel;
    const service = serviceModel.entity as Contract.GcpAppEngineApplicationService;

    const appEngineApplicationResourceStatusTranslator = useGcpAppEngineApplicationResourceStatusTranslator();
    const serverlessNetworkedResourceNetworkInboundAccessTypeTranslator = useGcpServerlessNetworkedResourceNetworkInboundAccessTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpAppEngineApplicationServiceDefinition",
            () => ({
                info: {
                    dnsName: "DNS Name",
                    items: {
                        applicationId: "App Engine Application",
                        containerImages: "Container Images",
                        containerImageVulnerabilities: "Vulnerabilities",
                        revisionMaxCreationTime: "Deployment Time",
                        status: "Status"
                    },
                    networkInboundAccessType: "Ingress traffic"
                },
                tabs: {
                    resourceRoleBindings: "Resource Role Bindings",
                    revisions: "Revisions ({{revisionsCount | NumberFormatter.humanize}})"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs:
            _<EntityProfileDefinitionTab>([]).
                concatIf(
                    !_.isEmpty(serviceModel.revisionIds),
                    new EntityProfileDefinitionTab(
                        ProfileCategory.Overview,
                        <Revisions revisionIds={serviceModel.revisionIds}/>,
                        localization.tabs.revisions({ revisionsCount: serviceModel.revisionIds.length }),
                        "revisions")).
                concat(
                    new EntityProfileDefinitionTab(
                        ProfileCategory.Iam,
                        <GcpScopeResourceRoleBindings
                            csvExportFilePrefix={localization.tabs.resourceRoleBindings()}
                            scopeResourceModel={serviceModel}/>,
                        localization.tabs.resourceRoleBindings(),
                        "resourceRoleBindings")).
                value(),
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    ...defaultScopeResourceInfoItemElements,
                    commonServiceAccountOriginatorScopeResourceInfoElements.serviceAccount,
                    <EntitiesInfoItem
                        entityIdsOrModels={service.applicationId}
                        entityTypeName={Contract.TypeNames.GcpAppEngineApplication}
                        key="applicationId"
                        location="all"
                        title={localization.info.items.applicationId()}/>,
                    <InfoItem
                        key="revisionMaxCreationTime"
                        title={localization.info.items.revisionMaxCreationTime()}
                        value={
                            _.isNil(serviceModel.revisionMaxCreationTime)
                                ? undefined
                                : TimeFormatter.profileFormatDateTime(serviceModel.revisionMaxCreationTime)}/>,
                    <InfoItem
                        key="networkInboundAccessType"
                        title={localization.info.networkInboundAccessType()}
                        value={serverlessNetworkedResourceNetworkInboundAccessTypeTranslator(service.networkInboundAccessType!)}/>,
                    <InfoItem
                        key="dnsName"
                        title={localization.info.dnsName()}
                        value={service.dnsName}/>,
                    <InfoItem
                        key="status"
                        location="all"
                        title={localization.info.items.status()}
                        value={appEngineApplicationResourceStatusTranslator(serviceModel.status)}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={serviceModel.containerImageIdReferences}
                        entityTypeName={Contract.TypeNames.IContainerImage}
                        key="containerImages"
                        title={localization.info.items.containerImages()}/>,
                    <InfoItem
                        key="containerImageVulnerabilities"
                        title={localization.info.items.containerImageVulnerabilities()}
                        value={
                            <VulnerabilitiesCell
                                containerImageScanStatus={serviceModel.containerImageScanStatus}
                                variant="initialAndCount"
                                vulnerabilities={serviceModel.containerImageVulnerabilities}/>}/>
                ]}
                options={options?.infoOptions}>
            </Info>
    });
}