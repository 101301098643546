import { SetHelper } from "@infrastructure";
import _, { Dictionary } from "lodash";
import { Contract } from "../../..";
import { AccessGraphPermissionEdgeData } from "./accessGraphHelper";

export class ExcessivePermissionHelper {
    public static getDestinationEntityGroupActionsExcessiveness =
        (destinationEntityGroup: Contract.AccessGraphDestinationEntityGroup, identityIds: string[], permissionEdgeIdToDataMap: Dictionary<AccessGraphPermissionEdgeData>, permissionPath: Contract.AccessGraphPermissionPath) => {
            const identityIdsSet = new Set(identityIds);
            const destinationEntityGroupEntityIdsSet = new Set(destinationEntityGroup.entityIds);

            let excessiveness = null;

            for (const permissionEdgeId of _.keys(permissionPath.permissionEdgeIdToPathActionsExcessivenessMap)) {
                const permissionEdgeData = permissionEdgeIdToDataMap[permissionEdgeId];
                if (SetHelper.overlap(
                    identityIdsSet,
                    permissionEdgeData.sourceEntityIds) &&
                    SetHelper.overlap(
                        destinationEntityGroupEntityIdsSet,
                        permissionEdgeData.destinationEntityIds)) {
                    if (_.isNil(excessiveness)) {
                        excessiveness = permissionPath.permissionEdgeIdToPathActionsExcessivenessMap[permissionEdgeId];
                    } else {
                        if (excessiveness != permissionPath.permissionEdgeIdToPathActionsExcessivenessMap[permissionEdgeId]) {
                            return Contract.AccessGraphPermissionPathActionsExcessiveness.Some;
                        }
                    }
                }
            }

            return excessiveness!;
        };
}