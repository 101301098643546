﻿import { useLocalization } from "@infrastructure";
import React, { useMemo } from "react";
import { Contract, Entity, entityModelStore, InlineEntities } from "../../../../../../../../../../../../../common";
import { RiskDefinitionSection } from "../../../../../../../utilities";
import { useGcpCommonBehaviorIdentitySectionElements } from "../useGcpCommonBehaviorIdentitySectionsElements";
import { RoleBindingDeltaTable } from "./components";

export function useGcpPermissionManagementRiskDefinition(riskModel: Contract.RiskModel) {
    const permissionManagementRiskModel = riskModel as Contract.GcpPermissionManagementRiskModel;
    const identityModel = entityModelStore.useGet(permissionManagementRiskModel.risk.identityId) as Contract.EntityModel;
    const riskInfoTranslator = useRiskInfoTranslator(permissionManagementRiskModel);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.behavior.useGcpPermissionManagementRiskDefinition",
            () => ({
                description: "{{identity}} was observed modifying the permissions of {{resources}}",
                sections: {
                    context: "Context",
                    description: {
                        text: "An identity modified permissions, despite having no history of performing similar actions before. This is a common technique used by attackers to gain a foothold in the environment.",
                        title: "Description"
                    },
                    events: "Unusual Activity of {{identity}}",
                    roleBindingDeltas: "Permission changes performed by {{identityId}}"
                }
            }));

    const commonBehaviorIdentitySectionsElements =
        useGcpCommonBehaviorIdentitySectionElements(
            permissionManagementRiskModel,
            riskInfoTranslator);

    const description =
        useMemo(
            () =>
                localization.description({
                    identity:
                        <Entity
                            entityIdOrModel={identityModel}
                            entityTypeNameTranslatorOptions={{ variant: "title" }}
                            variant="typeText"/>,
                    resources:
                        <InlineEntities
                            entityIdsOrModels={permissionManagementRiskModel.resourceIdReferences}
                            entityTypeName={Contract.TypeNames.GcpScopeResource}
                            variant="itemAndTypeOrItemCountAndType"/>
                }),
            [identityModel, permissionManagementRiskModel]);

    return useMemo(
        () => ({
            description,
            sections: [
                new RiskDefinitionSection(
                    <RoleBindingDeltaTable
                        csvExportFilePrefixes={[localization.sections.roleBindingDeltas({ identityId: identityModel.entity.displayName })]}
                        riskModel={permissionManagementRiskModel}/>,
                    localization.sections.roleBindingDeltas({
                        identityId:
                            <Entity
                                entityIdOrModel={identityModel}
                                variant="text"/>
                    })),
                new RiskDefinitionSection(
                    localization.sections.description.text(),
                    localization.sections.description.title()),
                new RiskDefinitionSection(
                    commonBehaviorIdentitySectionsElements.context,
                    localization.sections.context()),
                new RiskDefinitionSection(
                    commonBehaviorIdentitySectionsElements.events,
                    localization.sections.events({
                        identity:
                            <Entity
                                entityIdOrModel={identityModel}
                                variant="text"/>
                    }))
            ]
        }),
        [riskModel, commonBehaviorIdentitySectionsElements, identityModel, description]);
}

function useRiskInfoTranslator(permissionManagementRiskModel: Contract.GcpPermissionManagementRiskModel) {
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.behavior.useGcpPermissionManagementRiskDefinition.riskInfoTranslator",
            () => ({
                [Contract.GcpBehaviorIdentityRiskModelInfo.ResourcesSensitiveScopeResources]: {
                    many: "Affected resources: {{resources}}, of which {{sensitiveScopeResourceIds}} are marked as sensitive",
                    one: "Affected resources: {{resources}}, of which {{sensitiveScopeResourceIds}} is marked as sensitive",
                    single: "Affected resources: {{resources}}, which is marked as sensitive"
                },
                [Contract.GcpBehaviorIdentityRiskModelInfo.SensitiveWideScopeChildScopeResources]: {
                    many: "This change also impacts {{sensitiveWideScopeChildScopeResources}} in the above project that are marked as sensitive",
                    one: "This change also impacts {{sensitiveWideScopeChildScopeResources}} in the above project that is marked as sensitive"
                }
            }));
    return (info: Contract.GcpBehaviorIdentityRiskModelInfo) => {
        switch (info) {
            case Contract.GcpBehaviorIdentityRiskModelInfo.ResourcesSensitiveScopeResources: {
                const resourcesSensitiveScopeResourcesTranslatorVariant =
                    permissionManagementRiskModel.resourceIdReferences.length === 1
                        ? "single"
                        : permissionManagementRiskModel.risk.sensitiveScopeResourceIds.length === 1
                            ? "one"
                            : "many";
                return localization[info][resourcesSensitiveScopeResourcesTranslatorVariant]({
                    resources:
                        <InlineEntities
                            entityIdsOrModels={permissionManagementRiskModel.resourceIdReferences}
                            entityTypeName={Contract.TypeNames.GcpResource}
                            variant="itemAndTypeOrItemCountAndType"/>,
                    sensitiveScopeResourceIds:
                        <InlineEntities
                            entityIdsOrModels={permissionManagementRiskModel.risk.sensitiveScopeResourceIds}
                            entityTypeName={Contract.TypeNames.GcpScopeResource}
                            variant="itemAndTypeOrItemCountAndType"/>
                });
            }
            case Contract.GcpBehaviorIdentityRiskModelInfo.SensitiveWideScopeChildScopeResources: {
                const sensitiveWideScopeChildScopeResourcesTranslatorVariant =
                    permissionManagementRiskModel.risk.sensitiveWideScopeChildScopeResourceIds.length === 1
                        ? "one"
                        : "many";
                return localization[info][sensitiveWideScopeChildScopeResourcesTranslatorVariant]({
                    sensitiveWideScopeChildScopeResources:
                        <InlineEntities
                            entityIdsOrModels={permissionManagementRiskModel.risk.sensitiveWideScopeChildScopeResourceIds}
                            entityTypeName={Contract.TypeNames.GcpScopeResource}
                            variant="itemAndTypeOrItemCountAndType"/>
                });
            }
            default:
                return undefined;
        }
    };
}