﻿import { useLocalization } from "@infrastructure";
import { Grid2 } from "@mui/material";
import _, { Dictionary } from "lodash";
import React from "react";
import { SectionHeader, Summary } from ".";
import { Contract, RiskPolicyCategoryDistributionChart } from "../../../../../../../common";

type RiskSectionProps = {
    severityToRiskPolicyCategoryToCountMap: Dictionary<Dictionary<number>>;
};

export function RiskSection({ severityToRiskPolicyCategoryToCountMap }: RiskSectionProps) {
    const riskSeverityToCountMap =
        _([
            Contract.Severity.Critical,
            Contract.Severity.High,
            Contract.Severity.Medium,
            Contract.Severity.Low]).
            keyBy().
            mapValues(
                severity =>
                    _(severityToRiskPolicyCategoryToCountMap[severity]).
                        values().
                        sum()).
            value();

    const localization =
        useLocalization(
            "views.system.exportReport.dashboardReport.riskSection",
            () => ({
                openRisks: "Open findings",
                riskPolicyCategory: {
                    subtitle: "View a breakdown of findings by category. Each category represents a logical grouping of policies that triggered findings.",
                    title: "Categories"
                },
                subtitle: "See a high-level overview of all findings in Tenable Cloud Security according to severity level. Findings represent at-risk identities and resources, including hard-to-spot toxic combinations.",
                summary: {
                    subtitle: "View a breakdown of findings by severity level to discover and prioritize potential risks in your environment.",
                    title: "Overview"
                },
                title: "Findings"
            }));

    return (
        <Grid2
            container={true}
            direction="column"
            spacing={2}>
            <Grid2>
                <SectionHeader
                    subtitle={localization.subtitle()}
                    title={localization.title()}
                    variant="main"/>
            </Grid2>
            <Grid2>
                <Grid2
                    container={true}
                    direction="column"
                    spacing={1.5}>
                    <Grid2>
                        <SectionHeader
                            subtitle={localization.summary.subtitle()}
                            title={localization.summary.title()}
                            variant="sub"/>
                    </Grid2>
                    <Grid2>
                        <Summary
                            severityToCountMap={riskSeverityToCountMap}
                            showInformation={true}
                            title={localization.openRisks()}/>
                    </Grid2>
                </Grid2>
            </Grid2>
            <Grid2>
                <Grid2
                    container={true}
                    direction="column"
                    spacing={1.5}>
                    <Grid2>
                        <SectionHeader
                            subtitle={localization.riskPolicyCategory.subtitle()}
                            title={localization.riskPolicyCategory.title()}
                            variant="sub"/>
                    </Grid2>
                    <Grid2>
                        <RiskPolicyCategoryDistributionChart
                            report={true}
                            severityToRiskPolicyCategoryToCountMap={severityToRiskPolicyCategoryToCountMap}/>
                    </Grid2>
                </Grid2>
            </Grid2>
        </Grid2>);
}