import { useLocalization } from "@infrastructure";
import { Box, Stack } from "@mui/material";
import _ from "lodash";
import React from "react";
import { JiraIcon, ProfileTopbarInfoItem, useJiraIssueTranslator, useTheme } from "../../../../../../../../../../common";
import { JiraIssue } from "../../../../../../../../../../common/controllers/types.generated";

type ProfileTopbarJiraInfoItemProps = {
    issues: JiraIssue[];
};

export function ProfileTopbarJiraInfoItem({ issues }: ProfileTopbarJiraInfoItemProps) {
    const jiraIssueTranslator = useJiraIssueTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.profile.hooks.useProfileDefinition.hooks.profileTopbarJiraInfoItem",
            () => ({
                issues: [
                    "1 Jira Issue",
                    "{{count | NumberFormatter.humanize}} Jira Issues"
                ],
                title: [
                    "1 Jira Issue was created for this finding",
                    "{{count | NumberFormatter.humanize}} Jira Issues were created"
                ]
            }));

    const theme = useTheme();
    return (
        <ProfileTopbarInfoItem
            icon={<JiraIcon/>}
            text={localization.issues(_.size(issues))}
            titleOrGetTitle={
                async () => (
                    <Stack
                        spacing={1}
                        sx={{
                            maxHeight: theme.spacing(60),
                            overflow: "auto",
                            padding: theme.spacing(1)
                        }}>
                        {_.map(
                            issues,
                            issue =>
                                <Box key={issue.id}>
                                    {jiraIssueTranslator(issue, "link")}
                                </Box>)}
                    </Stack>)}/>);
}