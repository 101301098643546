import { map, useLocalization } from "@infrastructure";
import { Contract, useEntityTypeNameTranslator } from "../../../common";

export class AzureResourcePermitterType {
    constructor(
        public resourcePermitterType: Contract.AzurePermitterType,
        public resourceTypeName?: string) {
    }

    public getResourceTypeName() {
        return map(
            this.resourcePermitterType,
            {
                [Contract.AzurePermitterType.AzureKeyVaultVaultAccessPolicy]: () => Contract.TypeNames.AzureKeyVaultVault,
                [Contract.AzurePermitterType.AzureRoleAssignmentResource]: () => undefined,
                [Contract.AzurePermitterType.AzureStorageStorageAccountAccessKey]: () => Contract.TypeNames.AzureStorageStorageAccount,
                [Contract.AzurePermitterType.AzureStorageStorageAccountBlobContainerPublicAccess]: () => Contract.TypeNames.AzureStorageStorageAccountBlobContainer
            });
    }
}

export function useAzurePermitterTranslator() {
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "tenants.azure.hooks.useAzurePermitterTranslator",
            () => ({
                [Contract.TypeNames.AzurePermitterType]: {
                    [Contract.AzurePermitterType.AzureKeyVaultVaultAccessPolicy]: "{{translatedEntityTypeName}} Access Policy",
                    [Contract.AzurePermitterType.AzureRoleAssignmentResource]: "{{translatedEntityTypeName}}",
                    [Contract.AzurePermitterType.AzureStorageStorageAccountAccessKey]: "{{translatedEntityTypeName}} Access Keys",
                    [Contract.AzurePermitterType.AzureStorageStorageAccountBlobContainerPublicAccess]: "{{translatedEntityTypeName}} Anonymous Access Level"
                }
            }));
    return (resourceTypePermitterType: AzureResourcePermitterType) =>
        localization[Contract.TypeNames.AzurePermitterType][resourceTypePermitterType.resourcePermitterType]({
            translatedEntityTypeName:
                entityTypeNameTranslator(
                    resourceTypePermitterType.getResourceTypeName() ??
                    Contract.TypeNames.AzureResource)
        });
}