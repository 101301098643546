import { useMemo } from "react";
import { useOpDefaultKubernetesNamespaceResourceDefinition, useOpDefaultKubernetesResourceDefinition, useOpKubernetesClusterRoleBindingDefinition, useOpKubernetesClusterRoleDefinition, useOpKubernetesGroupDefinition, useOpKubernetesIngressDefinition, useOpKubernetesNamespaceDefinition, useOpKubernetesNamespaceRoleBindingDefinition, useOpKubernetesNamespaceRoleDefinition, useOpKubernetesServiceAccountDefinition, useOpKubernetesServiceDefinition, useOpKubernetesUserDefinition, useOpKubernetesWorkloadResourceDefinition } from ".";
import { Contract, TypeHelper } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";

export function useOpKubernetesResourceDefinition(definitionData: DefinitionData) {
    const useDefinition =
        useMemo(
            () => {
                switch (definitionData.entityTypeEntitiesViewName) {
                    case Contract.TypeNames.OpKubernetesClusterRole:
                        return useOpKubernetesClusterRoleDefinition;
                    case Contract.TypeNames.OpKubernetesClusterRoleBinding:
                        return useOpKubernetesClusterRoleBindingDefinition;
                    case Contract.TypeNames.OpKubernetesGroup:
                        return useOpKubernetesGroupDefinition;
                    case Contract.TypeNames.OpKubernetesIngress:
                        return useOpKubernetesIngressDefinition;
                    case Contract.TypeNames.OpKubernetesNamespace:
                        return useOpKubernetesNamespaceDefinition;
                    case Contract.TypeNames.OpKubernetesNamespaceRole:
                        return useOpKubernetesNamespaceRoleDefinition;
                    case Contract.TypeNames.OpKubernetesNamespaceRoleBinding:
                        return useOpKubernetesNamespaceRoleBindingDefinition;
                    case Contract.TypeNames.OpKubernetesService:
                        return useOpKubernetesServiceDefinition;
                    case Contract.TypeNames.OpKubernetesServiceAccount:
                        return useOpKubernetesServiceAccountDefinition;
                    case Contract.TypeNames.OpKubernetesUser:
                        return useOpKubernetesUserDefinition;
                    case Contract.TypeNames.OpKubernetesWorkloadResourceBase:
                        return useOpKubernetesWorkloadResourceDefinition;
                    default:
                        return TypeHelper.extendOrImplement(definitionData.entityTypeEntitiesViewName, Contract.TypeNames.OpKubernetesNamespaceResourceBase)
                            ? useOpDefaultKubernetesNamespaceResourceDefinition
                            : useOpDefaultKubernetesResourceDefinition;
                }
            },
            []);

    return useDefinition(definitionData);
}