import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function DynatraceIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <g clipPath="url(#clip0_58343_2090)">
                <path
                    d="M8.02625 2.00663C7.72188 3.6412 7.32733 6.0536 7.12442 8.49982C6.76369 12.8286 6.98915 15.7145 6.98915 15.7145L0.913055 21.4975C0.913055 21.4975 0.439593 18.2509 0.202862 14.5985C0.0563144 12.3439 0.0112228 10.3486 -5.01245e-05 9.14238C-5.01245e-05 9.07474 0.0337686 9.0071 0.0337686 8.93946C0.0337686 8.86055 0.135225 8.04891 0.924327 7.30489C1.79234 6.48197 8.11644 1.5219 8.02625 2.00663Z"
                    fill="#1496FF"/>
                <path
                    d="M8.02625 2.00663C7.72188 3.6412 7.32733 6.0536 7.12442 8.49982C7.12442 8.49982 0.394501 7.69945 -5.01245e-05 9.32274C-5.01245e-05 9.24383 0.112679 8.25182 0.901782 7.49653C1.76979 6.66234 8.11644 1.5219 8.02625 2.00663Z"
                    fill="#1284EA"/>
                <path
                    d="M-5.01245e-05 8.93946C-5.01245e-05 9.05219 -5.01245e-05 9.1762 -5.01245e-05 9.31147C0.0675873 9.01837 0.180316 8.80419 0.42832 8.49982C0.924328 7.86854 1.73598 7.69945 2.06289 7.65435C3.70873 7.44017 6.14368 7.16962 8.5899 7.10198C12.93 6.96671 15.7933 7.32744 15.7933 7.32744L21.8694 1.54444C21.8694 1.54444 18.6791 0.935706 15.038 0.507336C12.6594 0.225514 10.5627 0.0789659 9.39027 0.0113285C9.31136 0.0113285 8.4659 -0.1014 7.67679 0.653884C6.80878 1.47681 2.45744 5.60269 0.710142 7.27108C-0.0564146 8.01509 -5.01245e-05 8.86055 -5.01245e-05 8.93946Z"
                    fill="#B4DC00"/>
                <path
                    d="M21.6552 16.3232C20.0093 16.5487 17.5857 16.8192 15.1282 16.8981C10.7881 17.0447 7.91352 16.6839 7.91352 16.6839L1.82616 22.4669C1.82616 22.4669 5.05021 23.0982 8.68008 23.5153C10.9008 23.7746 12.8849 23.9099 14.0798 23.9775C14.1587 23.9775 14.3053 23.9099 14.3842 23.9099C14.4631 23.9099 15.3085 23.7633 16.0976 23.008C16.9769 22.1851 22.1399 16.2668 21.6552 16.3232Z"
                    fill="#6F2DA8"/>
                <path
                    d="M21.6552 16.3232C20.0093 16.5487 17.5857 16.8192 15.1282 16.8981C15.1282 16.8981 15.6016 23.6619 13.9558 23.9662C14.0347 23.9662 15.1507 23.9099 15.9398 23.1659C16.8078 22.3429 22.1399 16.2668 21.6552 16.3232Z"
                    fill="#591F91"/>
                <path
                    d="M14.3278 24.0001C14.2151 23.9888 14.0911 23.9888 13.9558 23.9775C14.2602 23.9211 14.4631 23.8197 14.79 23.5942C15.4438 23.1208 15.658 22.3317 15.7144 22.0048C16.0075 20.3702 16.3907 17.9578 16.5937 15.5116C16.9431 11.1828 16.7177 8.30818 16.7177 8.30818L22.7937 2.52518C22.7937 2.52518 23.2447 5.73796 23.4927 9.39038C23.6505 11.7802 23.6956 13.877 23.7069 15.0494C23.7069 15.1283 23.7745 15.9738 22.9854 16.729C22.1174 17.552 17.7773 21.7004 16.03 23.3688C15.2522 24.1015 14.4067 24.0001 14.3278 24.0001Z"
                    fill="#73BE28"/>
            </g>
            <defs>
                <clipPath id="clip0_58343_2090">
                    <rect
                        fill="white"
                        height="24"
                        width="24"/>
                </clipPath>
            </defs>
        </SvgIcon>);
}