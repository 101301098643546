import { useLocalization } from "@infrastructure";
import { FormControl, TextField } from "@mui/material";
import React from "react";

type MessageProps = {
    disabled?: boolean;
    message?: string;
    options?: Options;
    setMessage: (message: string) => void;
};

export type Options = {
    rows?: number;
};

export function Message({ disabled, message, options = { rows: 3 }, setMessage }: MessageProps) {
    const localization =
        useLocalization(
            "common.delivery.message",
            () => ({
                message: "Message (Optional)"
            }));

    return (
        <FormControl
            fullWidth={true}
            variant="standard">
            <TextField
                disabled={disabled}
                label={localization.message()}
                multiline={true}
                rows={options.rows}
                type="textarea"
                value={message}
                variant="outlined"
                onChange={event => setMessage(event.target.value)}/>
        </FormControl>);
}