import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition, useGetAzureSqlServerRiskContext } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { EntityExternalConsoleLink } from "../../components";

export function useAzureSqlServerEventDisabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AzureSqlServerEventDisabledRisk;
    const serverModel = entityModelStore.useGet(risk.entityId) as Contract.AzureSqlServerModel;

    const getAzureSqlServerRiskContext = useGetAzureSqlServerRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.compliance.useAzureSqlServerEventDisabledRiskDefinition",
            () => ({
                description: "{{server}} auditing is not enabled",
                sections: {
                    resolution: {
                        step1: "Under the **Azure SQL Auditing** section, switch **Enable Azure SQL Auditing** to **On**",
                        step2: "Select the destination of the audit logs",
                        step3: "Click **Save** on top to confirm"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            server:
                <Entity
                    entityIdOrModel={serverModel}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={risk.entityId}
                key={risk.entityId}
                page={Contract.AzureConsoleEntityPage.ServerAuditing}/>,
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3()
        ],
        riskModel,
        () => {
            const serverRiskContext = getAzureSqlServerRiskContext(serverModel);
            return [
                serverRiskContext.generalInformation,
                serverRiskContext.authenticationType,
                serverRiskContext.status,
                serverRiskContext.storageSize,
                serverRiskContext.inboundExternalAccessScope,
                serverRiskContext.sensitive,
                serverRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        });
}