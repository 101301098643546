import _, { Function0 } from "lodash";
import React, { useCallback, useMemo } from "react";
import { useLocalization } from "@infrastructure";
import { Contract, CustomerConsoleAppUrlHelper, TenantHelper, tenantModelStore, TenantTypeStatusItem, useGetSelectedScopeHasData, WidgetDefinition } from "../../../../../../..";
import { useCodeTenantModelStatusTranslator, useCodeTenantTypeTranslator } from "../../../../../../../../tenants";
import { CodeDashboardContext, useDashboardContext } from "../../../../../../Dashboard";
import { Tenants } from "../components";

export function useGetCodeTenantsDefinition(): () => WidgetDefinition {
    const localization =
        useLocalization(
            "common.dashboard.widget.hooks.useDefinition.hooks.useGetCodeTenantsDefinition",
            () => ({
                title: "Repositories"
            }));

    return useCallback<Function0<WidgetDefinition>>(
        () => ({
            element: <CodeTenants/>,
            options: { title: localization.title() }
        }),
        [localization]);
}

function CodeTenants() {
    const { analyzing } = useDashboardContext<CodeDashboardContext>();
    const filteredGitTenants = tenantModelStore.useGetFilteredGitTenants();
    const getSelectedScopeHasData = useGetSelectedScopeHasData();

    const codeTenantTypeTranslator = useCodeTenantTypeTranslator();
    const codeTenantModelStatusTranslator = useCodeTenantModelStatusTranslator();
    const localization =
        useLocalization(
            "common.dashboard.widget.hooks.useDefinition.hooks.useGetCodeTenantsDefinition.codeTenants",
            () => ({
                tenantCountPluralizer: [
                    "Repository",
                    "Repositories"
                ],
                tenantModelStatusTranslator: [
                    "{{count | NumberFormatter.humanize}} Repository | **{{status}}**",
                    "{{count | NumberFormatter.humanize}} Repositories | **{{status}}**"
                ]
            }));

    const codeTenantTypeToTenantModelsMap =
        useMemo(
            () =>
                _(filteredGitTenants).
                    filter(tenantModel => getSelectedScopeHasData([tenantModel])).
                    groupBy(tenantModel => tenantModel.configuration.codeTenantType).
                    value(),
            [filteredGitTenants]);

    const tenantTypeToTenantTypeStatusItem =
        useMemo(
            () =>
                _(TenantHelper.GitTenantCodeTenantTypes).
                    filter(
                        codeTenantType =>
                            analyzing ||
                            !_.isNil(codeTenantTypeToTenantModelsMap[codeTenantType])).
                    keyBy().
                    mapValues(
                        codeTenantType =>
                            analyzing
                                ? new TenantTypeStatusItem(
                                    () => ``,
                                    100,
                                    {},
                                    localization.tenantCountPluralizer(100),
                                    Contract.TenantType.Code,
                                    codeTenantTypeTranslator(codeTenantType),
                                    {},
                                    true,
                                    { codeTenantType },
                                    false)
                                : new TenantTypeStatusItem(
                                    (status, tenantModelCount) =>
                                        localization.tenantModelStatusTranslator(
                                            tenantModelCount,
                                            { status: codeTenantModelStatusTranslator(status as Contract.CodeTenantModelStatus) }),
                                    codeTenantTypeToTenantModelsMap[codeTenantType].length,
                                    _(codeTenantTypeToTenantModelsMap[codeTenantType] as Contract.GitTenantModel[]).
                                        filter(tenantModel => !_.isNil(tenantModel.statusSeverity)).
                                        groupBy(tenantModel => tenantModel.statusSeverity).
                                        mapValues(
                                            tenantModels =>
                                                _(tenantModels).
                                                    groupBy(tenantModel => tenantModel.status!).
                                                    mapValues(tenantModels => tenantModels.length).
                                                    value()).
                                        value(),
                                    localization.tenantCountPluralizer(codeTenantTypeToTenantModelsMap[codeTenantType].length),
                                    Contract.TenantType.Code,
                                    codeTenantTypeTranslator(codeTenantType as Contract.CodeTenantType),
                                    _(codeTenantTypeToTenantModelsMap[codeTenantType] as Contract.GitTenantModel[]).
                                        filter(tenantModel => _.isNil(tenantModel.statusSeverity)).
                                        groupBy(tenantModel => tenantModel.status!).
                                        mapValues(tenantModels => tenantModels.length).
                                        value(),
                                    false,
                                    { codeTenantType },
                                    true,
                                    undefined,
                                    CustomerConsoleAppUrlHelper.getCodeTenantsRelativeUrl({
                                        flatView: true,
                                        provider: codeTenantType as Contract.CodeTenantType
                                    }))).
                    value(),
            [codeTenantTypeToTenantModelsMap]);

    return <Tenants tenantTypeToTenantTypeStatusItem={tenantTypeToTenantTypeStatusItem}/>;
}