import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useAadDirectoryGroupTypeTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useAadDirectoryGroupTypeTranslator",
            () => ({
                [Contract.TypeNames.AadDirectoryGroupType]: {
                    [Contract.AadDirectoryGroupType.Distribution]: "Distribution",
                    [Contract.AadDirectoryGroupType.Microsoft365]: "Microsoft 365",
                    [Contract.AadDirectoryGroupType.Microsoft365SecurityEnabled]: "Microsoft 365 Security Enabled",
                    [Contract.AadDirectoryGroupType.Security]: "Security",
                    [Contract.AadDirectoryGroupType.SecurityDistributionEnabled]: "Security Mail Enabled",
                    [Contract.AadDirectoryGroupType.Unknown]: "Unknown"
                }
            }));
    return (type: Contract.AadDirectoryGroupType) =>
        localization[Contract.TypeNames.AadDirectoryGroupType][type]();
}