import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useAadDirectoryDirectoryRoleDefinitionTypeTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useAadDirectoryDirectoryRoleDefinitionTypeTranslator",
            () => ({
                [Contract.TypeNames.AadDirectoryDirectoryRoleDefinitionType]: {
                    [Contract.AadDirectoryDirectoryRoleDefinitionType.BuiltIn]: "Built-in",
                    [Contract.AadDirectoryDirectoryRoleDefinitionType.Custom]: "Custom"
                }
            }));
    return (type: Contract.AadDirectoryDirectoryRoleDefinitionType) => localization[Contract.TypeNames.AadDirectoryDirectoryRoleDefinitionType][type]();
}