import { AddIcon, CheckButton, DataTable, DataTableAction, DataTableActions, DataTableColumn, DataTableSort, DataTableSortDirection, EmptyMessageText, map, Optional, StringHelper, useChangeEffect, useLocalization } from "@infrastructure";
import { Button } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { useMemo, useRef } from "react";
import { useSetSqsContext } from "../..";
import { Contract, scopeNodeModelStore, scopeSystemEntityModelStore, StorageHelper, useScopeNameTranslator, useScopeNavigationViewContext } from "../../../../../../../../../../../../../../common";
import { useIntegrationsContext, useSetIntegrationsContext } from "../../../../../../../../Integrations";
import { useSqsQueueIssueTranslator } from "../../hooks";
import { ActionsCell, StatusCell } from "./components";

export function Table() {
    const { scopeNodeModel } = useScopeNavigationViewContext();
    const { childScopeEnabled } = useIntegrationsContext();
    const setIntegrationsContext = useSetIntegrationsContext();
    const setSqsContext = useSetSqsContext();
    const queueModels = scopeSystemEntityModelStore.useGetSqs();
    const scopeNodeMap = scopeNodeModelStore.useGetActiveScopeNodeMap();
    const childScopeIds =
        useMemo(
            () => scopeNodeMap[scopeNodeModel.configuration.id].scopeIds,
            [scopeNodeModel.configuration.id, scopeNodeMap]);
    const parentScopeIds =
        useMemo(
            () => scopeNodeMap[scopeNodeModel.configuration.id].parentScopeIds,
            [scopeNodeModel.configuration.id, scopeNodeMap]);

    const filteredQueueModels =
        useMemo(
            () =>
                _.filter(
                    queueModels,
                    queueModel =>
                        _(parentScopeIds).
                            concat(scopeNodeModel.configuration.id).
                            concatIf(
                                childScopeEnabled,
                                childScopeIds).
                            includes(queueModel.configuration.scopeId)),
            [childScopeEnabled, childScopeIds, parentScopeIds, queueModels]);

    const scopeNameTranslator = useScopeNameTranslator();
    const sqsQueueIssueTranslator = useSqsQueueIssueTranslator();
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useObservabilityItems.sqs.table",
            () => ({
                actions: {
                    add: "Add SQS Queue",
                    childScopeEnabled: "Flat View"
                },
                columns: {
                    name: "Name",
                    scope: "Scope",
                    status: "Status",
                    url: "URL"
                },
                empty: "No SQS queues"
            }));

    function getQueues(_filterMap: Dictionary<any>, sort: Optional<DataTableSort>) {
        return _.orderBy(
            filteredQueueModels,
            filteredQueueModel =>
                map<string, number | string | undefined>(
                    sort?.columnId ?? TableColumnId.Name,
                    {
                        [TableColumnId.Scope]: () => StringHelper.getSortValue(scopeNameTranslator(filteredQueueModel.scopeId, { path: true })),
                        [TableColumnId.Name]: () => StringHelper.getSortValue((filteredQueueModel.configuration as Contract.SqsQueueConfiguration).name),
                        [TableColumnId.Status]: () => StringHelper.getSortValue(sqsQueueIssueTranslator((filteredQueueModel.state as Contract.SqsQueueState)?.issue)),
                        [TableColumnId.Url]: () => StringHelper.getSortValue((filteredQueueModel.configuration as Contract.SqsQueueConfiguration).url)
                    }),
            sort?.direction === DataTableSortDirection.Descending
                ? "desc"
                : "asc");
    }

    const dataTableActionsRef = useRef<DataTableActions>();
    useChangeEffect(
        () => {
            dataTableActionsRef.current!.reset();
        },
        [filteredQueueModels]);

    return (
        <DataTable
            actionsRef={dataTableActionsRef}
            emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty()) }}
            fetchItems={getQueues}
            getItemId={(item: Contract.ScopeSystemEntityModel) => item.configuration.id}>
            <DataTableAction>
                <CheckButton
                    checked={childScopeEnabled}
                    title={localization.actions.childScopeEnabled()}
                    onCheckedChanged={
                        checked => {
                            StorageHelper.customerConfigurationIntegrationsFlatView.setValue(checked);
                            setIntegrationsContext(_ => ({ childScopeEnabled: checked }));
                        }}/>
            </DataTableAction>
            <DataTableAction>
                <Button
                    startIcon={<AddIcon/>}
                    onClick={
                        () =>
                            setSqsContext(
                                sqsContext => ({
                                    ...sqsContext,
                                    addOrEditOpen: true
                                }))}>
                    {localization.actions.add()}
                </Button>
            </DataTableAction>
            <DataTableColumn
                id={TableColumnId.Name}
                itemProperty={(item: Contract.ScopeSystemEntityModel) => (item.configuration as Contract.SqsQueueConfiguration).name}
                title={localization.columns.name()}/>
            <DataTableColumn
                id={TableColumnId.Url}
                itemProperty={(item: Contract.ScopeSystemEntityModel) => (item.configuration as Contract.SqsQueueConfiguration).url}
                title={localization.columns.url()}/>
            <DataTableColumn
                id={TableColumnId.Status}
                render={StatusCell}
                title={localization.columns.status()}/>
            <DataTableColumn
                id={TableColumnId.Scope}
                itemProperty={item => scopeNameTranslator(item.scopeId, { path: true })}
                title={localization.columns.scope()}/>
            <DataTableColumn
                id={TableColumnId.Actions}
                render={ActionsCell}/>
        </DataTable>);
}

enum TableColumnId {
    Actions = "actions",
    Name = "name",
    Scope = "scope",
    Status = "status",
    Url = "url"
}