﻿import { DataTable, DataTableColumn, EmptyMessageText, map, Optional, optionalTableCell, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { ReactNode } from "react";
import { Contract, Entity, InlineEntities } from "../../../common";
import { useAwsApiGatewayApiRouteMethodTranslator } from "../hooks";


type AwsApiGatewayV1ApiRoutesTableProps = {
    apiModel: Contract.AwsApiGatewayV1ApiModel;
    getHighlightColor?: (method: Contract.AwsApiGatewayV1ApiRoute, opacity?: number) => Optional<string>;
};

export function AwsApiGatewayV1ApiRoutesTable({ apiModel, getHighlightColor }: AwsApiGatewayV1ApiRoutesTableProps) {
    const api = apiModel.entity as Contract.AwsApiGatewayV1Api;

    const apiGatewayApiMethodTranslator = useAwsApiGatewayApiRouteMethodTranslator();
    const localization =
        useLocalization(
            "tenants.aws.awsApiGatewayV1ApiRoutesTable",
            () => ({
                columns: {
                    apiKey: {
                        false: "No",
                        title: "API key required",
                        true: "Yes"
                    },
                    authorizationType: {
                        iam: "IAM",
                        invalid: "Invalid",
                        title: "Authorization"
                    },
                    integration: {
                        mock: "Mock",
                        title: "Integration"
                    },
                    method: "Method",
                    path: "Resource"
                },
                empty: "No resources"
            }));
    return (
        <DataTable
            emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty()) }}
            fetchItems={
                () =>
                    _.orderBy(
                        api.routes,
                        [
                            route => !_.isNil(getHighlightColor?.(route)),
                            route => route.path
                        ],
                        [
                            "desc",
                            "desc"
                        ])}
            getItemId={(route: Contract.AwsApiGatewayV1ApiRoute) => route.path}
            rowOptions={{
                getHighlightColor: (item: Contract.AwsApiGatewayV1ApiRoute) => getHighlightColor?.(item),
                getSx:
                    (route: Contract.AwsApiGatewayV1ApiRoute) => ({
                        backgroundColor: getHighlightColor?.(route, 0.1)
                    })
            }}
            sortOptions={{ enabled: false }}
            variant="card">
            <DataTableColumn
                id={TableColumnId.Path}
                itemProperty={(route: Contract.AwsApiGatewayV1ApiRoute) => route.path}
                title={localization.columns.path()}/>
            <DataTableColumn
                id={TableColumnId.Method}
                itemProperty={(route: Contract.AwsApiGatewayV1ApiRoute) => apiGatewayApiMethodTranslator(route.method!)}
                title={localization.columns.method()}/>
            <DataTableColumn
                id={TableColumnId.AuthorizationType}
                render={
                    optionalTableCell<Contract.AwsApiGatewayV1ApiRoute>(
                        route =>
                            map<Contract.AwsApiGatewayV1ApiAuthorizationType, ReactNode | string | undefined>(
                                route.authorizationType,
                                {
                                    [Contract.AwsApiGatewayV1ApiAuthorizationType.Cognito]:
                                        () =>
                                            <InlineEntities
                                                entityIdsOrModels={
                                                    _(api.authorizerMap[route.authorizerId!].userPoolArns).
                                                        map(userPoolArn => apiModel.resourceArnToReferenceMap[userPoolArn]).
                                                        value()}
                                                entityTypeName={Contract.TypeNames.AwsCognitoIdpUserPool}
                                                variant="itemOrItemCountAndType"/>,
                                    [Contract.AwsApiGatewayV1ApiAuthorizationType.Iam]:
                                        () => localization.columns.authorizationType.iam(),
                                    [Contract.AwsApiGatewayV1ApiAuthorizationType.Lambda]:
                                        () =>
                                            <Entity
                                                entityIdOrModel={apiModel.resourceArnToReferenceMap[api.authorizerMap[route.authorizerId!].functionConfigurationArn!]}
                                                variant="text"/>,
                                    [Contract.AwsApiGatewayV1ApiAuthorizationType.None]:
                                        () => undefined
                                },
                                () => localization.columns.authorizationType.invalid()))}
                title={localization.columns.authorizationType.title()}/>
            <DataTableColumn
                id={TableColumnId.ApiKey}
                itemProperty={
                    (route: Contract.AwsApiGatewayV1ApiRoute) =>
                        route.apiKeyRequired
                            ? localization.columns.apiKey.true()
                            : localization.columns.apiKey.false()}
                title={localization.columns.apiKey.title()}/>
            <DataTableColumn
                id={TableColumnId.IntegrationType}
                render={
                    optionalTableCell<Contract.AwsApiGatewayV1ApiRoute>(
                        route =>
                            map<Contract.AwsApiGatewayV1ApiRouteIntegrationType, ReactNode | string | undefined>(
                                route.integration?.type,
                                {
                                    [Contract.AwsApiGatewayV1ApiRouteIntegrationType.Http]:
                                        () => route.integration!.rawUrl!,
                                    [Contract.AwsApiGatewayV1ApiRouteIntegrationType.Lambda]:
                                        () =>
                                            <Entity
                                                entityIdOrModel={apiModel.resourceArnToReferenceMap[route.integration!.functionConfigurationArn!]}
                                                variant="text"/>,
                                    [Contract.AwsApiGatewayV1ApiRouteIntegrationType.Mock]:
                                        () => localization.columns.integration.mock(),
                                    [Contract.AwsApiGatewayV1ApiRouteIntegrationType.Service]:
                                        () =>
                                            _.isNil(route.integration!.serviceId)
                                                ? route.integration!.arn
                                                : <Entity
                                                    entityIdOrModel={route.integration!.serviceId}
                                                    variant="text"/>,
                                    [Contract.AwsApiGatewayV1ApiRouteIntegrationType.Vpc]:
                                        () => route.integration!.rawUrl!
                                },
                                () => undefined
                            ))}
                title={localization.columns.integration.title()}/>
        </DataTable>);
}

enum TableColumnId {
    ApiKey = "apiKey",
    AuthorizationType = "authorizationType",
    IntegrationType = "integrationType",
    Method = "method",
    Path = "path"
}