﻿import { UnexpectedError } from "@infrastructure";
import { useMemo } from "react";
import { Contract } from "../../../../../../../../../../../../../common";
import { RiskDefinition } from "../../../../../../../utilities";
import { useAwsEc2VolumeEncryptionDisabledRiskEmrClustersDefinition, useAwsEc2VolumeEncryptionDisabledRiskEmrSecurityConfigurationDefinition, useAwsEc2VolumeEncryptionDisabledRiskLaunchConfigurationDefinition, useAwsEc2VolumeEncryptionDisabledRiskLaunchTemplateDefinition, useAwsEc2VolumeEncryptionDisabledRiskVolumeDefinition } from "./hooks";

export function useAwsEc2VolumeEncryptionDisabledRiskDefinition(riskModel: Contract.RiskModel): RiskDefinition {
    const risk = riskModel.risk as Contract.AwsEc2VolumeEncryptionDisabledRisk;

    const useDefinition =
        useMemo(
            () => {
                switch (risk.aggregatingEntityTypeName) {
                    case Contract.TypeNames.AwsAutoScalingLaunchConfiguration:
                        return useAwsEc2VolumeEncryptionDisabledRiskLaunchConfigurationDefinition;
                    case Contract.TypeNames.AwsEc2LaunchTemplate:
                        return useAwsEc2VolumeEncryptionDisabledRiskLaunchTemplateDefinition;
                    case Contract.TypeNames.AwsEc2Volume:
                        return useAwsEc2VolumeEncryptionDisabledRiskVolumeDefinition;
                    case Contract.TypeNames.AwsEmrSecurityConfiguration:
                        return useAwsEc2VolumeEncryptionDisabledRiskEmrSecurityConfigurationDefinition;
                    case Contract.TypeNames.AwsTenantEntity:
                        return useAwsEc2VolumeEncryptionDisabledRiskEmrClustersDefinition;
                    default:
                        throw new UnexpectedError("risk.aggregatingEntityTypeName", risk.aggregatingEntityTypeName);
                }
            },
            []);

    return useDefinition(riskModel);
}