import { useLocalization } from "@infrastructure";
import _ from "lodash";
import { useMemo } from "react";
import { Contract, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { useAwsOrganizationsAccountStatusTranslator } from "../../../../../../../../../Entities/hooks";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetAwsResourceRiskContext } from "./useGetAwsResourceRiskContext";

export function useGetAwsTenantEntityRiskContext() {
    return useMemo(
        () => useAwsTenantEntityRiskContext,
        []);
}

function useAwsTenantEntityRiskContext(tenantEntityModel: Contract.AwsTenantEntityModel) {
    const resourceRiskContext = useGetAwsResourceRiskContext()(tenantEntityModel);

    const accountStatusTranslator = useAwsOrganizationsAccountStatusTranslator();
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.contexts.useGetAwsTenantEntityRiskContext",
            () => ({
                accountStatus: "The {{translatedTenantEntityTypeName}} status is **{{status}}**",
                path: "The {{translatedTenantEntityTypeName}}'s path is **{{path}}**"
            }));
    const translatedTenantEntityTypeName =
        entityTypeNameTranslator(
            tenantEntityModel.entity.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });

    return {
        ...resourceRiskContext,
        accountStatus:
            _.isNil(tenantEntityModel.account)
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.accountStatus({
                        status: accountStatusTranslator(tenantEntityModel.account.status),
                        translatedTenantEntityTypeName
                    })),
        path:
            new RiskDefinitionContextItem(
                localization.path({
                    path: `${tenantEntityModel.parentEntityPath}/${tenantEntityModel.entity.displayName}`,
                    translatedTenantEntityTypeName
                }))
    };
}