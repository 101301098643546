import { DataTableColumnRenderProps } from "@infrastructure";
import React, { memo } from "react";
import { RiskPolicyItem, useScopeNavigationViewContext } from "../../../../../common";
import { FailedEntities } from "./FailedEntities";

const FailedEntitiesCellMemo = memo(FailedEntitiesCell);
export { FailedEntitiesCellMemo as FailedEntitiesCell };

function FailedEntitiesCell({ item }: DataTableColumnRenderProps<RiskPolicyItem>) {
    const { scopeNodeModel } = useScopeNavigationViewContext();
    return (
        <FailedEntities
            item={item}
            scopeId={scopeNodeModel.configuration.id}/>);
}