import { map, NoneIcon } from "@infrastructure";
import { SvgIconProps } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract, useTheme } from "../../../..";
import { ConfidentialIcon, PrivateIcon, ProprietaryIcon, PublicIcon, RestrictedIcon } from "../icons";

type DataAnalysisSensitivityIconProps =
    SvgIconProps & {
        sensitivity?: Contract.DataAnalysisSensitivity;
    };

export function DataAnalysisSensitivityIcon({ sensitivity, ...props }: DataAnalysisSensitivityIconProps) {
    const theme = useTheme();
    return (
        _.isNil(sensitivity)
            ? <NoneIcon
                {...props}
                sx={{
                    color: theme.palette.text.secondary,
                    ...props?.sx
                }}/>
            : map(
                sensitivity,
                {
                    [Contract.DataAnalysisSensitivity.Confidential]: () => <ConfidentialIcon {...props}/>,
                    [Contract.DataAnalysisSensitivity.NonSensitive]: () => null,
                    [Contract.DataAnalysisSensitivity.Public]: () => <PublicIcon {...props}/>,
                    [Contract.DataAnalysisSensitivity.Private]: () => <PrivateIcon {...props}/>,
                    [Contract.DataAnalysisSensitivity.Proprietary]: () => <ProprietaryIcon {...props}/>,
                    [Contract.DataAnalysisSensitivity.Restricted]: () => <RestrictedIcon {...props}/>
                }));
}