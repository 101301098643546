import { Store } from "@infrastructure";
import _ from "lodash";
import { useMemo } from "react";
import { ConfigurationController, Contract, scopeNodeModelStore, UserHelper } from "..";

export class ProjectModelStore extends Store<Contract.ProjectModel, never, never> {
    public updateDeleted =
        async (id: string) => {
            await ConfigurationController.deleteProject(new Contract.ConfigurationControllerDeleteProjectRequest(id));
            await this.notify(id);
        };

    public useGetPermittedProjects = () => {
        const projectModels = this.useGetAll();
        return useMemo(
            () =>
                _.filter(
                    projectModels,
                    projectModel => (
                        UserHelper.hasScopePermissions(
                            projectModel.configuration.id,
                            Contract.IdentityPermission.PermissionManagementAdministrationRead,
                            Contract.IdentityPermission.PermissionManagementPermissionRequest,
                            Contract.IdentityPermission.PermissionManagementResourceRead,
                            Contract.IdentityPermission.SecurityRead))),
            [projectModels]);
    };

    public useGetProjectModelMap = () => {
        const projectModels = this.useGetPermittedProjects();
        return useMemo(
            () =>
                _.keyBy(
                    projectModels,
                    projectModel => projectModel.configuration.id),
            [projectModels]);
    };
}

export const projectModelStore =
    new ProjectModelStore(
        projectModel => projectModel.configuration.id,
        {
            get:
                async ids => {
                    const { projectModels } = await ConfigurationController.getProjectModels(new Contract.ProjectControllerGetProjectModelsRequest(ids));
                    return projectModels;
                },
            getAll:
                async () => {
                    const { projectModels } = await ConfigurationController.getProjectModels(new Contract.ProjectControllerGetProjectModelsRequest(undefined));
                    return projectModels;
                },
            onNotify:
                async (projectModels?: Contract.ProjectModel[]) => {
                    await scopeNodeModelStore.notify(
                        _.isNil(projectModels)
                            ? undefined
                            : _.map(
                                projectModels,
                                projectModel => projectModel.configuration.id));
                },
            onNotifyDeleted:
                async (ids: string[]) => {
                    await scopeNodeModelStore.notifyDeleted(ids);
                }
        });