import { Dialog } from "@infrastructure";
import React from "react";
import { DocumentViewer, DocumentViewerProps } from ".";

type DocumentViewerDialogProps = DocumentViewerProps & {
    onClose: () => void;
};

export function DocumentViewerDialog({ document, documentFileName, onClose, title }: DocumentViewerDialogProps) {
    return (
        <Dialog
            size="large"
            variant="viewer"
            onClose={onClose}>
            <DocumentViewer
                dense={true}
                document={document}
                documentFileName={documentFileName}
                title={title}/>
        </Dialog>);
}