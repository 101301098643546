import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function WindowsIcon(props: SvgIconProps) {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <g clipPath="url(#clip0_52885_254818)">
                <path
                    d="M0 0H24V24H0V0Z"
                    fill="#F3F3F3"/>
                <path
                    d="M1.04395 1.04346H11.4787V11.4782H1.04395V1.04346Z"
                    fill="#F35325"/>
                <path
                    d="M12.5215 1.04346H22.9563V11.4782H12.5215V1.04346Z"
                    fill="#81BC06"/>
                <path
                    d="M1.04395 12.522H11.4787V22.9568H1.04395V12.522Z"
                    fill="#05A6F0"/>
                <path
                    d="M12.5215 12.522H22.9563V22.9568H12.5215V12.522Z"
                    fill="#FFBA08"/>
            </g>
            <defs>
                <clipPath id="clip0_52885_254818">
                    <rect
                        fill="white"
                        height="24"
                        width="24"/>
                </clipPath>
            </defs>
        </SvgIcon>);
}