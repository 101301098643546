import { Optional, useLocalization } from "@infrastructure";
import React, { useCallback, useState } from "react";
import { Contract } from "../../../../../../../../../../common";
import { useUpdateConfiguration } from "../../../../hooks";
import { AddExclusionTags, ExclusionTagsTableAddComponentProps } from "../../../ExclusionTags";

export function VirtualMachineAddExclusionTags({ onAdd, resourceTags }: ExclusionTagsTableAddComponentProps) {
    const [addVirtualMachineExclusionTagExecuting, setAddVirtualMachineExclusionTagExecuting] = useState(false);
    const [addVirtualMachineExclusionTagError, setAddVirtualMachineExclusionTagError] = useState(false);

    const updateConfiguration = useUpdateConfiguration();

    const addVirtualMachineExclusionTag =
        useCallback(
            async (
                virtualMachineExclusionTagKey: string,
                virtualMachineExclusionTagValue: Optional<string>) => {
                setAddVirtualMachineExclusionTagExecuting(true);
                setAddVirtualMachineExclusionTagError(false);
                try {
                    const updateWorkloadAnalysisResponse =
                        await updateConfiguration({
                            virtualMachineExclusionTags: [
                                new Contract.ResourceTag(
                                    virtualMachineExclusionTagKey,
                                    virtualMachineExclusionTagValue),
                                ...resourceTags]
                        });

                    onAdd(updateWorkloadAnalysisResponse.virtualMachineExclusionTags);
                } catch {
                    setAddVirtualMachineExclusionTagError(true);
                }

                setAddVirtualMachineExclusionTagExecuting(false);
            },
            []);

    const localization =
        useLocalization(
            "views.customer.configuration.workloadAnalysis.virtualMachines.virtualMachineExclusionTags.virtualMachineAddExclusionTags",
            () => ({
                resourceTypeCaps: "Virtual Machine",
                resourceTypeNoCaps: "virtual machine"
            }));

    return <AddExclusionTags
        addExclusionTag={addVirtualMachineExclusionTag}
        addExclusionTagError={addVirtualMachineExclusionTagError}
        addExclusionTagExecuting={addVirtualMachineExclusionTagExecuting}
        resourceTypeLocalization={{
            resourceTypeCaps: localization.resourceTypeCaps(),
            resourceTypeNoCaps: localization.resourceTypeNoCaps()
        }}/>;
}