import { InlineItems, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, entityModelStore, InlineEntities, TypeHelper, useEntityTypeNameTranslator, useSeverityTranslator } from "../../../../../../../../../../../../common";
import { GcpComputeInstanceStatus } from "../../../../../../../../../../../../common/controllers/types.generated";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetGcpEntityRiskContext } from "./useGetGcpEntityRiskContext";

export function useGetGcpComputeVpcRiskContext() {
    return useMemo(
        () => useGcpComputeVpcRiskContext,
        []);
}

function useGcpComputeVpcRiskContext(vpcModel: Contract.GcpComputeVpcModel) {
    const entityRiskContext = useGetGcpEntityRiskContext()(vpcModel);
    const vpc = vpcModel.entity as Contract.GcpComputeVpc;
    const instanceModels = entityModelStore.useGet(vpcModel.networkedResourceTypeNameToIdsMap[Contract.TypeNames.GcpComputeInstance] ?? []) as Contract.GcpComputeInstanceModel[];
    const subnetModels = entityModelStore.useGet(vpcModel.subnetIds) as Contract.GcpComputeSubnetModel[];
    const serviceAccountModels =
        entityModelStore.useGet(
            _(instanceModels).
                map(instanceModel => instanceModel.serviceAccountIdReference).
                filter().
                as<string>().
                value()) as Contract.GcpIamServiceAccountModel[];
    const [permissionActionSeverity, runningInstancePermissionActionSeverityToInstanceModelsMap] =
        useMemo(
            () => {
                const serviceAccountModelMap =
                    _.keyBy(
                        serviceAccountModels,
                        serviceAccountModel => serviceAccountModel.id);
                const runningInstancePermissionActionSeverityToInstanceModelsMap =
                    _(instanceModels).
                        filter(
                            instanceModel =>
                                (instanceModel.entity as Contract.GcpComputeInstance).status === GcpComputeInstanceStatus.Running &&
                                !_.isNil(instanceModel.serviceAccountIdReference) &&
                                (serviceAccountModelMap[instanceModel.serviceAccountIdReference].permissionActionSeverity ?? Contract.Severity.Low) !== Contract.Severity.Low).
                        groupBy(instanceModel => serviceAccountModelMap[instanceModel.serviceAccountIdReference!].permissionActionSeverity).
                        value();
                const maxPermissionActionSeverity =
                    _(runningInstancePermissionActionSeverityToInstanceModelsMap).
                        keys().
                        maxBy(severity =>
                            TypeHelper.getEnumValue(
                                Contract.TypeNames.Severity,
                                severity));
                return [maxPermissionActionSeverity, runningInstancePermissionActionSeverityToInstanceModelsMap];
            },
            [vpcModel]);

    const sensitiveInstanceModels =
        _.filter(
            instanceModels,
            instanceModel => instanceModel.entityConfiguration?.sensitive === true);
    const sensitiveSubnetModels =
        _.filter(
            subnetModels,
            subnetModel => subnetModel.entityConfiguration?.sensitive === true);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const severityTranslator = useSeverityTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.contexts.useGetGcpComputeVpcRiskContext",
            () => ({
                instances: {
                    empty: "The {{translatedVpcTypeName}} currently has no instances associated",
                    notEmpty: "The {{translatedVpcTypeName}} contains {{instances}}"
                },
                sensitiveInstances: "The {{translatedVpcTypeName}} contains {{sensitiveInstances}} marked as sensitive",
                sensitiveSubnets: "The {{translatedVpcTypeName}} contains {{sensitiveSubnets}} marked as sensitive",
                severityPermissionActionInstances: {
                    empty: "The {{translatedVpcTypeName}} does not contain any running instances with high severity permissions",
                    notEmpty: "The {{translatedVpcTypeName}} contains {{instances}} running with {{severity}} severity permissions"
                },
                subnets: {
                    regions: [
                        "1 region",
                        "{{count | NumberFormatter.humanize}} regions"
                    ],
                    text: "The {{translatedVpcTypeName}} has {{subnetIds}} in {{regions}}"
                }
            }));
    const translatedVpcTypeName =
        entityTypeNameTranslator(
            vpc.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });

    return {
        ...entityRiskContext,
        instances:
            _.isEmpty(instanceModels)
                ? new RiskDefinitionContextItem(localization.instances.empty({ translatedVpcTypeName }))
                : new RiskDefinitionContextItem(
                    localization.instances.notEmpty({
                        instances:
                            <InlineEntities
                                entityIdsOrModels={instanceModels}
                                entityTypeName={Contract.TypeNames.GcpComputeInstance}
                                variant="itemCountAndType"/>,
                        translatedVpcTypeName
                    })),
        sensitiveInstances:
            _.isEmpty(sensitiveInstanceModels)
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.sensitiveInstances({
                        sensitiveInstances:
                            <InlineEntities
                                entityIdsOrModels={sensitiveInstanceModels}
                                entityTypeName={Contract.TypeNames.GcpComputeInstance}
                                variant="itemCountAndType"/>,
                        translatedVpcTypeName
                    })),
        sensitiveSubnets:
            _.isEmpty(sensitiveSubnetModels)
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.sensitiveSubnets({
                        sensitiveSubnets:
                            <InlineEntities
                                entityIdsOrModels={sensitiveSubnetModels}
                                entityTypeName={Contract.TypeNames.GcpComputeSubnet}
                                variant="itemCountAndType"/>,
                        translatedVpcTypeName
                    })),
        severityPermissionActionInstances:
            _.isEmpty(instanceModels)
                ? undefined
                : new RiskDefinitionContextItem(
                    !_.isNil(permissionActionSeverity)
                        ? localization.severityPermissionActionInstances.notEmpty({
                            instances:
                                <InlineEntities
                                    entityIdsOrModels={runningInstancePermissionActionSeverityToInstanceModelsMap[permissionActionSeverity]}
                                    entityTypeName={Contract.TypeNames.GcpComputeInstance}
                                    variant="itemCountAndType"/>,
                            severity:
                                severityTranslator(
                                    TypeHelper.tryParseEnum<Contract.Severity>(Contract.TypeNames.Severity, permissionActionSeverity)!,
                                    "text"),
                            translatedVpcTypeName
                        })
                        : localization.severityPermissionActionInstances.empty({ translatedVpcTypeName })),
        subnets:
            _.isEmpty(subnetModels)
                ? undefined
                : new RiskDefinitionContextItem
                (localization.subnets.text({
                    regions:
                        <InlineItems
                            items={vpcModel.subnetRegions}
                            namePluralizer={localization.subnets.regions}
                            variant="itemCountAndType"/>,
                    subnetIds:
                        <InlineEntities
                            entityIdsOrModels={subnetModels}
                            entityTypeName={Contract.TypeNames.GcpComputeSubnet}
                            variant="itemCountAndType"/>,
                    translatedVpcTypeName
                }))
    };
}