import { Link, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, Entity, entityModelStore, InlineEntities, tenantModelStore } from "../../../../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../../../../tenants";
import { useCommonSectionsAndDescriptionDefinition } from "../../../../../../useCommonSectionsAndDescriptionDefinition";
import { VolumeContextSection } from "./components";

export function useAwsEc2SnapshotEncryptionDisabledRiskVolumeDefinition(riskModel: Contract.RiskModel) {
    const snapshotEncryptionDisabledRiskModel = riskModel as Contract.AwsEc2SnapshotEncryptionDisabledRiskModel;
    const tenantConfiguration = tenantModelStore.useGet(riskModel.tenantId).configuration as Contract.AwsTenantConfiguration;
    const volumeModel = entityModelStore.useGet(snapshotEncryptionDisabledRiskModel.risk.entityId) as Contract.AwsEc2VolumeModel;
    const volume = volumeModel.entity as Contract.AwsEc2Volume;

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsEc2SnapshotEncryptionDisabledRiskDefinition.hooks.useAwsEc2SnapshotEncryptionDisabledRiskVolumeDefinition",
            () => ({
                description: {
                    none: "EBS volume {{volume}} is not encrypted using KMS",
                    snapshot: "EBS volume {{volume}} has {{snapshots}} without KMS encryption"
                },
                sections: {
                    resolution: {
                        step1: {
                            link: "Amazon EBS encryption",
                            text: "Create a new encrypted EBS volume instead of {{volume}} in order to stop generation of unencrypted snapshots. For more information see: {{awsEc2VolumeEncryptionLink}}"
                        },
                        step2: "For each unencrypted snapshot, select it and click **Actions** on top and then **Delete**"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        (_.isEmpty(snapshotEncryptionDisabledRiskModel.risk.aggregatedEntityIds)
            ? localization.description.none
            : localization.description.snapshot)({
            snapshots:
                <InlineEntities
                    entityIdsOrModels={snapshotEncryptionDisabledRiskModel.risk.aggregatedEntityIds}
                    entityTypeName={Contract.TypeNames.AwsEc2Snapshot}
                    variant="itemCountAndType"/>,
            volume:
                <Entity
                    entityIdOrModel={volumeModel}
                    variant="text"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.Ec2,
                volumeModel.unknown
                    ? AwsConsoleUrlBuilder.getEc2Url(tenantConfiguration.partitionType)
                    : AwsConsoleUrlBuilder.getEc2VolumeUrl(volume)),
            localization.sections.resolution.step1.text({
                awsEc2VolumeEncryptionLink:
                    <Link
                        urlOrGetUrl={snapshotEncryptionDisabledRiskModel.ebsEncryptionDocumentationUrl}
                        variant="external">
                        {localization.sections.resolution.step1.link()}
                    </Link>,
                volume:
                    <Entity
                        entityIdOrModel={volumeModel}
                        glanceOptions={{ disabled: true }}
                        linkOptions={{ disabled: true }}
                        variant="text"/>
            }),
            localization.sections.resolution.step2()
        ],
        riskModel,
        undefined,
        {
            contextSectionElement:
                <VolumeContextSection
                    risk={snapshotEncryptionDisabledRiskModel.risk}
                    volumeModel={volumeModel}/>
        });
}