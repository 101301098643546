import { useMemo } from "react";
import { useAwsResourceDefinition, useAwsServiceDefinition, useAwsUnmanagedKubernetesClusterDefinition } from ".";
import { Contract } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";

export function useAwsEntityDefinition(definitionData: DefinitionData) {
    const useDefinition =
        useMemo(
            () => {
                switch (definitionData.entityTypeEntitiesViewName) {
                    case Contract.TypeNames.AwsService:
                        return useAwsServiceDefinition;
                    case Contract.TypeNames.AwsUnmanagedKubernetesCluster:
                        return useAwsUnmanagedKubernetesClusterDefinition;
                    default:
                        return useAwsResourceDefinition;
                }
            },
            []);

    return useDefinition(definitionData);
}