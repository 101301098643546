import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { Contract, InfoItem } from "../../../../../../../../../../../common";
import { useAwsRoute53HostedZoneSigningStatusTranslator } from "../../../../../../../hooks";
import { EntitiesInfoItem, Info } from "../../../../../components";
import { useCustomEntityPropertyInfoItemElements } from "../../useCustomEntityPropertyInfoItemElements";
import { useAwsDefaultResourceInfoItemElements } from "../useAwsDefaultResourceInfoItemElements";
import { RecordsInfoCard } from "./components";

export function useAwsRoute53HostedZoneDefinition(resourceModel: Contract.AwsResourceModel, options?: DefinitionOptions) {
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useAwsDefaultResourceInfoItemElements(resourceModel);
    const hostedZone = resourceModel.entity as Contract.AwsRoute53HostedZone;
    const hostedZoneModel = resourceModel as Contract.AwsRoute53HostedZoneModel;

    const route53HostedZoneSigningStatusTranslator = useAwsRoute53HostedZoneSigningStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsRoute53HostedZoneDefinition",
            () => ({
                info: {
                    items: {
                        description: "Description",
                        publicNetworkAccess: {
                            false: "Private",
                            title: "Hosted Zone Type",
                            true: "Public"
                        },
                        rawId: "Hosted Zone ID",
                        recordCount: "Record Count",
                        signingStatus: "DNSSEC Signing Status",
                        vpcs: "VPC"
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="rawId"
                        title={localization.info.items.rawId()}
                        value={hostedZone.rawId}/>,
                    <InfoItem
                        key="publicNetworkAccess"
                        title={localization.info.items.publicNetworkAccess.title()}
                        value={
                            hostedZone.publicNetworkAccess
                                ? localization.info.items.publicNetworkAccess.true()
                                : localization.info.items.publicNetworkAccess.false()}/>,
                    <InfoItem
                        key="description"
                        title={localization.info.items.description()}
                        value={hostedZone.description}/>,
                    <InfoItem
                        key="recordCount"
                        title={localization.info.items.recordCount()}
                        value={hostedZoneModel.recordCount}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={hostedZone?.vpcIds}
                        entityTypeName={Contract.TypeNames.AwsEc2Vpc}
                        key="vpcIds"
                        title={localization.info.items.vpcs()}/>,
                    <InfoItem
                        key="signingStatus"
                        title={localization.info.items.signingStatus()}
                        value={
                            _.isNil(hostedZone.signingStatus)
                                ? undefined
                                : route53HostedZoneSigningStatusTranslator(hostedZone.signingStatus)}/>
                ]}
                options={options?.infoOptions}>
                <RecordsInfoCard hostedZoneModel={hostedZoneModel}/>
            </Info>
    });
}