﻿import { DataTable, DataTableColumn, DataTableColumnRenderProps, EmptyMessageText, NumberFormatter, Optional, useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract, NetworkScopeFormatter, useTheme } from "../../../../common";
import { RuleEntityCell, RulePortCell } from "./components";

type RulesInfoCardProps = {
    getHighlightColor?: (path: string, opacity?: number) => Optional<string>;
    rules: Contract.AzureNetworkNetworkSecurityGroupRule[];
};

export function AzureNetworkNetworkSecurityGroupRulesTable({ getHighlightColor, rules }: RulesInfoCardProps) {
    const localization =
        useLocalization(
            "tenants.azure.azureNetworkNetworkSecurityGroupRulesTable.azureNetworkNetworkSecurityGroupRulesTable",
            () => ({
                columns: {
                    action: {
                        title: "Action",
                        [Contract.TypeNames.AzureNetworkNetworkSecurityGroupRuleAction]: {
                            [Contract.AzureNetworkNetworkSecurityGroupRuleAction.Allow]: "Allow",
                            [Contract.AzureNetworkNetworkSecurityGroupRuleAction.Deny]: "Deny"
                        }
                    },
                    destination: "Destination",
                    destinationPort: "Destination Port",
                    name: "Name",
                    priority: "Priority",
                    protocolRange: "Protocol",
                    source: "Source",
                    sourcePort: "Source Port"
                },
                empty: "No Rules"
            }));
    const theme = useTheme();
    return (
        <DataTable
            emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty()) }}
            fetchItems={
                () =>
                    _.orderBy(
                        rules,
                        [
                            rule => !_.isNil(getHighlightColor?.(rule.path)),
                            rule => rule.priority
                        ],
                        [
                            "desc",
                            "desc"
                        ])}
            getItemId={
                (item: Contract.AzureNetworkNetworkSecurityGroupRule) =>
                    _(rules).
                        indexOf(item).
                        toString()}
            rowOptions={{
                getHighlightColor: (rule: Contract.AzureNetworkNetworkSecurityGroupRule) => getHighlightColor?.(rule.path),
                getSx:
                    (rule: Contract.AzureNetworkNetworkSecurityGroupRule) => ({
                        backgroundColor: getHighlightColor?.(rule.path, 0.1)
                    })
            }}
            sortOptions={{ enabled: false }}
            variant="card">
            <DataTableColumn
                id={RulesInfoCardTableColumnId.Priority}
                itemProperty={(rule: Contract.AzureNetworkNetworkSecurityGroupRule) => NumberFormatter.humanize(rule.priority)}
                title={localization.columns.priority()}/>
            <DataTableColumn
                id={RulesInfoCardTableColumnId.Name}
                itemProperty={(rule: Contract.AzureNetworkNetworkSecurityGroupRule) => rule.name}
                title={localization.columns.name()}/>
            <DataTableColumn
                id={RulesInfoCardTableColumnId.ProtocolRange}
                itemProperty={(rule: Contract.AzureNetworkNetworkSecurityGroupRule) => NetworkScopeFormatter.protocolRange(rule.protocolRange, Contract.TenantType.Azure)}
                title={localization.columns.protocolRange()}/>
            <DataTableColumn
                id={RulesInfoCardTableColumnId.Source}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AzureNetworkNetworkSecurityGroupRule>) =>
                        <RuleEntityCell item={item.source}/>}
                title={localization.columns.source()}/>
            <DataTableColumn
                id={RulesInfoCardTableColumnId.SourcePortRanges}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AzureNetworkNetworkSecurityGroupRule>) =>
                        <RulePortCell item={item.source.portRanges}/>}
                title={localization.columns.sourcePort()}/>
            <DataTableColumn
                id={RulesInfoCardTableColumnId.Destination}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AzureNetworkNetworkSecurityGroupRule>) =>
                        <RuleEntityCell item={item.destination}/>}
                title={localization.columns.destination()}/>
            <DataTableColumn
                id={RulesInfoCardTableColumnId.DestinationPortRanges}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AzureNetworkNetworkSecurityGroupRule>) =>
                        <RulePortCell item={item.destination.portRanges}/>}
                title={localization.columns.destinationPort()}/>
            <DataTableColumn
                id={RulesInfoCardTableColumnId.Action}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AzureNetworkNetworkSecurityGroupRule>) =>
                        <Typography
                            noWrap={true}
                            sx={{
                                color:
                                    item.action === Contract.AzureNetworkNetworkSecurityGroupRuleAction.Allow
                                        ? theme.palette.success.main
                                        : theme.palette.error.main
                            }}>
                            {localization.columns.action[Contract.TypeNames.AzureNetworkNetworkSecurityGroupRuleAction][item.action]()}
                        </Typography>}
                title={localization.columns.action.title()}/>
        </DataTable>);
}

enum RulesInfoCardTableColumnId {
    Action = "action",
    Destination = "destination",
    DestinationPortRanges = "destinationPortRanges",
    Name = "name",
    Priority = "priority",
    ProtocolRange = "protocol",
    Source = "source",
    SourcePortRanges = "sourcePortRanges"
}