import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";
import { Contract } from "../../../../controllers";
import { useTheme } from "../../../../themes";

export function PrivateIcon(props: SvgIconProps) {
    const theme = useTheme();
    return (
        <SvgIcon fill="none" height="14" viewBox="0 0 14 14" width="14" {...props}>
            <rect
                fill={theme.palette.data.sensitivity(Contract.DataSensitivity.Private)}
                height="1.45833"
                rx="0.729167"
                transform="rotate(90 11.9583 7.41699)"
                width="4.08333"
                x="11.9583"
                y="7.41699"/>
            <rect
                fill={theme.palette.data.sensitivity(Contract.DataSensitivity.NonSensitive)}
                height="1.45833"
                rx="0.729167"
                transform="rotate(90 9.04175 5.66699)"
                width="5.83333"
                x="9.04175"
                y="5.66699"/>
            <rect
                fill={theme.palette.data.sensitivity(Contract.DataSensitivity.NonSensitive)}
                height="1.45833"
                rx="0.729167"
                transform="rotate(90 6.125 3.91699)"
                width="7.58333"
                x="6.125"
                y="3.91699"/>
            <rect
                fill={theme.palette.data.sensitivity(Contract.DataSensitivity.NonSensitive)}
                height="1.45833"
                rx="0.729167"
                transform="rotate(90 3.20825 2.16699)"
                width="9.33333"
                x="3.20825"
                y="2.16699"/>
        </SvgIcon>);
}