import { Link, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../..";
import { Contract, Entity, entityModelStore, InlineEntities, InlinePermissionActions, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { EntityExternalConsoleLink } from "../../components";
import { useGetGcpCloudBuildBuildTriggerRiskContext } from "../contexts";

export function useGcpCloudBuildBuildTriggerDefaultServiceAccountRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.GcpCloudBuildBuildTriggerDefaultServiceAccountRisk;
    const cloudBuildBuildTriggerDefaultServiceAccountRiskModel = riskModel as Contract.GcpCloudBuildBuildTriggerDefaultServiceAccountRiskModel;
    const buildTriggerModel = entityModelStore.useGet(risk.entityId) as Contract.GcpCloudBuildBuildTriggerModel;

    const getGcpCloudBuildBuildTriggerRiskContext = useGetGcpCloudBuildBuildTriggerRiskContext();

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.useGcpCloudBuildBuildTriggerDefaultServiceAccountRiskDefinition",
            () => ({
                contextItems: {
                    updatePermissionActions: "{{principalIds}} were granted {{updatePermissionAction}}, allowing them to update the {{translatedBuildTriggerTypeName}}",
                    updatePermissionActionsExternalPrincipals: "{{principalIds}} including {{partialPrincipalIds}} were granted {{updatePermissionAction}}, allowing them to update the {{translatedBuildTriggerTypeName}}"
                },
                description: "{{buildTrigger}} is using the default service account",
                sections: {
                    resolution: {
                        step1: "Under the **Advanced** section",
                        step2: {
                            link: "Follow GCP documentation on how to create a custom service account for Cloud Build trigger",
                            text: "Create a custom service account for the Cloud Build trigger. This service account should be tailored to the specific requirements of the Cloud Build trigger deployment. This form of deployment is more secure. {{createCustomServiceAccountLink}}."
                        },
                        step3: "Select the secured service account in the **Service account** selector",
                        step4: "Click **SAVE**"
                    }
                }
            }));

    const partialPrincipalIds =
        _(risk.principalIdToExternalMap).
            pickBy(external => external).
            keys().
            value();

    const createPrincipalIdsProps =
        () => ({
            partialPrincipalIds:
                <InlineEntities
                    entityIdsOrModels={partialPrincipalIds}
                    entityTypeName={Contract.TypeNames.IGciPartialPrincipal}
                    variant="itemCountAndType"/>,
            principalIds:
                <InlineEntities
                    entityIdsOrModels={_.keys(risk.principalIdToExternalMap)}
                    entityTypeName={Contract.TypeNames.IGciPrincipal}
                    variant="itemCountAndType"/>,
            translatedBuildTriggerTypeName:
                entityTypeNameTranslator(
                    buildTriggerModel.entity.typeName,
                    {
                        includeServiceName: false,
                        variant: "text"
                    }),
            updatePermissionAction:
                <InlinePermissionActions
                    permissionActions={[risk.updatePermissionAction]}
                    variant="itemCountAndType"/>
        });

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            buildTrigger:
                <Entity
                    entityIdOrModel={buildTriggerModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={risk.entityId}
                key={risk.entityId}
                page={Contract.GcpConsolePage.Resource}/>,
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2.text({
                createCustomServiceAccountLink:
                    <Link
                        urlOrGetUrl={cloudBuildBuildTriggerDefaultServiceAccountRiskModel.createCustomServiceAccountUrl}
                        variant="external">
                        {localization.sections.resolution.step2.link()}
                    </Link>
            }),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4()
        ],
        riskModel,
        () => {
            const buildTriggerRiskContext = getGcpCloudBuildBuildTriggerRiskContext(buildTriggerModel);
            return [
                buildTriggerRiskContext.generalInformation,
                buildTriggerRiskContext.sensitive,
                _.isEmpty(risk.principalIdToExternalMap)
                    ? undefined
                    : new RiskDefinitionContextItem(
                        _.isEmpty(partialPrincipalIds)
                            ? localization.contextItems.updatePermissionActions({ ...createPrincipalIdsProps() })
                            : localization.contextItems.updatePermissionActionsExternalPrincipals({ ...createPrincipalIdsProps() })),
                buildTriggerRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}