﻿import { useMemo } from "react";
import { Contract } from "../../../../../../../../../../../../../../common";
import { useAzureContainerServiceManagedClusterDefinition, useAzureEntityDefinition, useAzureKeyVaultVaultDefinition, useAzureManagementManagementGroupDefinition, useAzureSqlServerDefinition, useAzureStorageStorageAccountBlobContainerDefinition, useAzureStorageStorageAccountDefinition, useAzureStorageStorageAccountQueueDefinition } from "./hooks";

export function useDefinition(entityModel: Contract.EntityModel, page: Contract.AzureConsoleEntityPage) {
    const useDefinition =
        useMemo(
            () => {
                switch (entityModel.entity.typeName) {
                    case Contract.TypeNames.AzureContainerServiceManagedCluster:
                        return useAzureContainerServiceManagedClusterDefinition;
                    case Contract.TypeNames.AzureKeyVaultVault:
                        return useAzureKeyVaultVaultDefinition;
                    case Contract.TypeNames.AzureManagementManagementGroup:
                        return useAzureManagementManagementGroupDefinition;
                    case Contract.TypeNames.AzureSqlServer:
                        return useAzureSqlServerDefinition;
                    case Contract.TypeNames.AzureStorageStorageAccount:
                        return useAzureStorageStorageAccountDefinition;
                    case Contract.TypeNames.AzureStorageStorageAccountBlobContainer:
                        return useAzureStorageStorageAccountBlobContainerDefinition;
                    case Contract.TypeNames.AzureStorageStorageAccountQueue:
                        return useAzureStorageStorageAccountQueueDefinition;
                    default:
                        return useAzureEntityDefinition;
                }
            },
            []);

    return useDefinition(entityModel, page);
}