import { Link, NumberFormatter, useLocalization } from "@infrastructure";
import { Box, Stack, Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { useMemo } from "react";
import { RiskPolicyCategoryDistributionChart } from "../../../../../../..";
import { Contract } from "../../../../../../../../controllers";
import { useSeverityTranslator } from "../../../../../../../../hooks";
import { useTheme } from "../../../../../../../../themes";
import { CheckIcon } from "./icons";

type RiskProps = {
    getPolicyCategoryUrl?: (riskPolicyCategory: Contract.RiskPolicyCategory, severities: Contract.Severity[]) => string;
    getSeverityUrl?: (severity: Contract.Severity) => string;
    selectedSeverities: Contract.Severity[];
    severityToRiskPolicyCategoryToCountMap: Dictionary<Dictionary<number>>;
};

export function Risks({ getPolicyCategoryUrl, getSeverityUrl, selectedSeverities, severityToRiskPolicyCategoryToCountMap }: RiskProps) {
    const riskSeverityToCountMap =
        useMemo(
            () =>
                _.mapValues(
                    severityToRiskPolicyCategoryToCountMap,
                    riskPolicyCategoryToCountMap =>
                        _(riskPolicyCategoryToCountMap).
                            values().
                            sum()),
            [severityToRiskPolicyCategoryToCountMap]);

    const [severities, severityToPercentageMap, totalRiskCount] =
        useMemo(
            () => {
                const totalRiskCount =
                    _(riskSeverityToCountMap).
                        values().
                        sum();

                const allSeverities = [
                    Contract.Severity.Critical,
                    Contract.Severity.High,
                    Contract.Severity.Medium,
                    Contract.Severity.Low
                ];

                const severities =
                    _.isEmpty(selectedSeverities)
                        ? allSeverities
                        : selectedSeverities;

                let severityToPercentageMap: { [severity: string]: number } = {};
                if (totalRiskCount > 0) {
                    const percentages =
                        NumberFormatter.distributionPercentages(
                            _.map(
                                allSeverities,
                                severity => (riskSeverityToCountMap[severity] ?? 0) / totalRiskCount));
                    severityToPercentageMap =
                        _(allSeverities).
                            keyBy().
                            mapValues(severity => percentages[_.indexOf(allSeverities, severity)]).
                            value();
                }

                return [severities, severityToPercentageMap, totalRiskCount];
            },
            [riskSeverityToCountMap, selectedSeverities]);

    const severityTranslator = useSeverityTranslator();

    const localization =
        useLocalization(
            "common.dashboard.widget.hooks.useDefinition.risks",
            () => ({
                empty: {
                    subtitle: "You don't have any open findings",
                    title: "Great Job!"
                },
                risks: "{{severity}}"
            }));

    const theme = useTheme();
    return (
        <Stack
            spacing={5}
            sx={{ height: "100%" }}>
            <Stack
                direction="row"
                sx={{ marginTop: theme.spacing(1) }}>
                {_.map(
                    severities,
                    severity => {
                        const riskCount = riskSeverityToCountMap[severity] ?? 0;
                        return (
                            <Stack
                                className={`severity ${severity}`}
                                key={severity}
                                sx={{
                                    "&:not(:last-child)": {
                                        marginRight: theme.spacing(8)
                                    }
                                }}>
                                <Link
                                    urlOrGetUrl={
                                        riskCount === 0
                                            ? undefined
                                            : getSeverityUrl?.(severity)}
                                    variant="text">
                                    <Stack
                                        alignItems="center"
                                        direction="row">
                                        <Box
                                            sx={{
                                                backgroundColor: theme.palette.severity(severity),
                                                height: theme.spacing(3.75),
                                                marginRight: theme.spacing(0.5),
                                                width: theme.spacing(0.75)
                                            }}/>
                                        <Typography
                                            component="span"
                                            noWrap={true}
                                            sx={{
                                                fontSize: "28px",
                                                fontWeight: 700,
                                                lineHeight: 1,
                                                marginRight: theme.spacing(0.5)
                                            }}>
                                            {NumberFormatter.unit(riskCount)}
                                        </Typography>
                                        <Stack>
                                            <Typography
                                                noWrap={true}
                                                sx={{
                                                    fontWeight: 700,
                                                    lineHeight: 1.2
                                                }}>
                                                {localization.risks({ severity: severityTranslator(severity) })}
                                            </Typography>
                                            {severityToPercentageMap[severity] != undefined &&
                                                <Typography
                                                    noWrap={true}
                                                    sx={{ lineHeight: 1.2 }}>
                                                    {NumberFormatter.percentage(severityToPercentageMap[severity])}
                                                </Typography>}
                                        </Stack>
                                    </Stack>
                                </Link>
                            </Stack>);
                    })}
            </Stack>
            <Box
                sx={{
                    flex: 1,
                    marginTop:
                        theme.spacing(
                            totalRiskCount === 0
                                ? 1
                                : 5)
                }}>
                {_(riskSeverityToCountMap).
                    pickBy(
                        (_count, riskSeverity) =>
                            _.includes(
                                severities,
                                riskSeverity)).
                    values().
                    sum() === 0
                    ? <Stack
                        alignItems="center"
                        className="empty"
                        justifyContent="center"
                        spacing={0.5}
                        sx={{
                            border: theme.border.primary,
                            borderRadius: theme.spacing(1),
                            height: "100%",
                            width: "100%"
                        }}>
                        <CheckIcon
                            sx={{
                                height: theme.spacing(12),
                                width: theme.spacing(12)
                            }}/>
                        <Typography
                            sx={{
                                fontSize: "24px",
                                fontWeight: 700
                            }}>
                            {localization.empty.title()}
                        </Typography>
                        <Typography sx={{ fontSize: "18px" }}>
                            {localization.empty.subtitle()}
                        </Typography>
                    </Stack>
                    : <RiskPolicyCategoryDistributionChart
                        getPolicyCategoryUrl={getPolicyCategoryUrl}
                        severityToRiskPolicyCategoryToCountMap={
                            _.pickBy(
                                severityToRiskPolicyCategoryToCountMap,
                                (_riskPolicyCategoryToCountMap, severity) =>
                                    _.includes(
                                        severities,
                                        severity))}/>}
            </Box>
        </Stack>);
}