import { DataTableColumn, DataTableColumnRenderProps, Link, map, Optional, StringHelper, TextValuesFilter, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { useGetCloudProviderTenantOrganizationByScopeNodeModel } from "..";
import { Contract, CustomerConsoleAppUrlHelper, ScopeHelper, tenantModelStore, UrlHelper } from "../../../../../../../../common";
import { useAwsTenantModelStatusTranslator } from "../../../../../../../../tenants";
import { ScopeRawId } from "../../components";
import { TenantsDefinition } from "../../useDefinition";
import { useRenderTenant } from "../useRenderTenant";
import { StatusCell, TrailStatusCell } from "./components";
import { useAwsTenantModelTrailStatusTranslator } from "./hooks";

export function useAwsDefinition(): TenantsDefinition {
    const getCloudProviderTenantOrganizationByScopeNodeModel = useGetCloudProviderTenantOrganizationByScopeNodeModel();
    const tenantModelMap = tenantModelStore.useGetAwsTenantMap();

    const localization =
        useLocalization(
            "views.customer.scopes.hooks.useDefinition.hooks.useAwsDefinition",
            () => ({
                actions: {
                    delete: "delete the entire organization",
                    deleteOrganizationFolder: "This folder belongs to an organization which is configured to automatically onboard new accounts, and can't be deleted.\nInstead, you can either {{deleteLink}} (including all its accounts and folders), or exclude this specific account by {{supportLink}}.",
                    deleteOrganizationTenant: "This account/s belongs to an organization which is configured to automatically onboard new accounts, and can't be deleted. Instead, you can either {{deleteLink}} (including all its accounts), or exclude this specific account by {{supportLink}}",
                    deleteSelection: "Some/All of the selected accounts and/or folders belong to an organization which is configured to automatically onboard new accounts, and can't be deleted.\nInstead, you can either {{deleteLink}} (including all its accounts and folders), or exclude this specific account by {{supportLink}}.",
                    supportLink: "contacting Support"
                },
                columns: {
                    rawId: "ID",
                    roleName: "Role Name",
                    status: "Status",
                    trailStatus: "CloudTrail Status"
                },
                title: "AWS Accounts"
            }));

    const { renderTenantCell, renderTenantCsvItem } = useRenderTenant<Contract.AwsTenantModel>();
    const tenantModelStatusTranslator = useAwsTenantModelStatusTranslator();
    const tenantModelTrailStatusTranslator = useAwsTenantModelTrailStatusTranslator();

    return useMemo(
        () => ({
            filter:
                (filterMap, scopeNodeModel) => {
                    if (ScopeHelper.isFolder(scopeNodeModel)) {
                        const folderRawId = (scopeNodeModel.configuration as Contract.CloudProviderTenantFolderConfiguration)?.organization?.folderRawId;
                        if (!_.isNil(filterMap[TableColumnId.RawId]) &&
                            !_.isNil(folderRawId) &&
                            !_.includes(
                                filterMap[TableColumnId.RawId].values,
                                folderRawId)) {
                            return false;
                        }

                        return true;
                    }

                    const tenantModel = tenantModelMap[scopeNodeModel.configuration.id];
                    if (_.isNil(tenantModel)) {
                        return true;
                    }

                    if (!_.isNil(filterMap[TableColumnId.RawId]) &&
                        !_.includes(filterMap[TableColumnId.RawId].values, tenantModel.configuration.rawId)) {
                        return false;
                    }

                    if (!_.isNil(filterMap[TableColumnId.RoleName]) &&
                        !_.includes(filterMap[TableColumnId.RoleName].values, tenantModel.roleName)) {
                        return false;
                    }

                    if (!_.isNil(filterMap[TableColumnId.Status]) &&
                        !_.includes(filterMap[TableColumnId.Status].values, tenantModel.status)) {
                        return false;
                    }

                    if (!_.isNil(filterMap[TableColumnId.TrailStatus]) &&
                        !_.includes(filterMap[TableColumnId.TrailStatus].values, tenantModel.trailStatus)) {
                        return false;
                    }

                    return true;
                },
            getColumns:
                scopeNodeModels => {
                    const rawIds: string[] = [];
                    const roleNames = new Set<string>();
                    const statuses = new Set<Contract.AwsTenantModelStatus>();
                    const trailStatuses = new Set<Contract.AwsTenantModelTrailStatus>();
                    _.forEach(
                        scopeNodeModels,
                        scopeNodeModel => {
                            const folderRawId = (scopeNodeModel.configuration as Contract.CloudProviderTenantFolderConfiguration)?.organization?.folderRawId;
                            if (!_.isNil(folderRawId) && ScopeHelper.isFolder(scopeNodeModel)) {
                                rawIds.push(folderRawId);
                            } else if (scopeNodeModel.type === Contract.ScopeType.CloudProviderTenant && !_.isNil(tenantModelMap[scopeNodeModel.configuration.id])) {
                                const tenantModel = tenantModelMap[scopeNodeModel.configuration.id];
                                rawIds.push(tenantModel.configuration.rawId);
                                roleNames.add(tenantModel.roleName);
                                statuses.add(tenantModel.status);
                                if (!_.isNil(tenantModel.trailStatus)) {
                                    trailStatuses.add(tenantModel.trailStatus);
                                }
                            }
                        });

                    return [
                        <DataTableColumn
                            exportOptions={{
                                getItem:
                                    (scopeNodeModel: Contract.ScopeNodeModel) => ({
                                        [localization.columns.rawId()]:
                                            renderTenantCsvItem(
                                                tenantModel => tenantModel.configuration.rawId,
                                                scopeNodeModel)
                                    })
                            }}
                            filterOptions={{
                                itemOrItems: {
                                    element:
                                        <TextValuesFilter
                                            placeholder={localization.columns.rawId()}
                                            values={rawIds}/>
                                }
                            }}
                            id={TableColumnId.RawId}
                            key={TableColumnId.RawId}
                            render={
                                (scopeNodeModel: DataTableColumnRenderProps<Contract.ScopeNodeModel>) =>
                                    <ScopeRawId
                                        fallbackRender={renderTenantCell(tenantModel => tenantModel.configuration.rawId)}
                                        scopeNodeModel={scopeNodeModel}/>}
                            title={localization.columns.rawId()}/>,
                        <DataTableColumn
                            cellMaxWidth="small"
                            exportOptions={{
                                getItem:
                                    (scopeNodeModel: Contract.ScopeNodeModel) => ({
                                        [localization.columns.roleName()]:
                                            renderTenantCsvItem(
                                                tenantModel => tenantModel.roleName,
                                                scopeNodeModel)
                                    })
                            }}
                            filterOptions={{
                                itemOrItems: {
                                    default: true,
                                    element:
                                        <TextValuesFilter
                                            placeholder={localization.columns.roleName()}
                                            values={Array.from(roleNames)}/>
                                }
                            }}
                            id={TableColumnId.RoleName}
                            key={TableColumnId.RoleName}
                            render={renderTenantCell(tenantModel => tenantModel.roleName)}
                            title={localization.columns.roleName()}/>,
                        <DataTableColumn
                            exportOptions={{
                                getItem:
                                    (scopeNodeModel: Contract.ScopeNodeModel) => ({
                                        [localization.columns.status()]:
                                            renderTenantCsvItem(
                                                tenantModel => tenantModelStatusTranslator(tenantModel.status),
                                                scopeNodeModel)
                                    })
                            }}
                            filterOptions={{
                                itemOrItems: {
                                    default: true,
                                    element:
                                        <ValuesFilter placeholder={localization.columns.status()}>
                                            {_.map(
                                                Array.from(statuses),
                                                status => (
                                                    <ValuesFilterItem
                                                        key={status}
                                                        title={tenantModelStatusTranslator(status)}
                                                        value={status}/>))}
                                        </ValuesFilter>
                                }
                            }}
                            id={TableColumnId.Status}
                            key={TableColumnId.Status}
                            render={renderTenantCell(tenantModel => <StatusCell item={tenantModel}/>)}
                            title={localization.columns.status()}/>,
                        <DataTableColumn
                            exportOptions={{
                                getItem:
                                    (scopeNodeModel: Contract.ScopeNodeModel) => ({
                                        [localization.columns.trailStatus()]:
                                            renderTenantCsvItem(
                                                tenantModel =>
                                                    _.isNil(tenantModel.trailStatus)
                                                        ? undefined
                                                        : tenantModelTrailStatusTranslator(tenantModel.trailStatus),
                                                scopeNodeModel)
                                    })
                            }}
                            filterOptions={{
                                itemOrItems: {
                                    element:
                                        <ValuesFilter placeholder={localization.columns.trailStatus()}>
                                            {_.map(
                                                Array.from(trailStatuses),
                                                trailStatus => (
                                                    <ValuesFilterItem
                                                        key={trailStatus}
                                                        title={tenantModelTrailStatusTranslator(trailStatus)}
                                                        value={trailStatus}/>))}
                                        </ValuesFilter>
                                }
                            }}
                            id={TableColumnId.TrailStatus}
                            key={TableColumnId.TrailStatus}
                            render={renderTenantCell(tenantModel => <TrailStatusCell item={tenantModel}/>)}
                            title={localization.columns.trailStatus()}/>
                    ];
                },
            getDeleteScopeDisabledMessage:
                (selection, scopeNodeModel) => {
                    const organizationConfiguration = getCloudProviderTenantOrganizationByScopeNodeModel(scopeNodeModel)?.configuration as Optional<Contract.CloudProviderTenantOrganizationConfiguration>;
                    if (scopeNodeModel.type === Contract.ScopeType.Folder &&
                        (scopeNodeModel.configuration as Contract.AwsFolderConfiguration).managed) {
                        return (selection
                            ? localization.actions.deleteSelection
                            : localization.actions.deleteOrganizationFolder)({
                            deleteLink:
                                <Link
                                    urlOrGetUrl={CustomerConsoleAppUrlHelper.getConfigurationIntegrationsCloudProviderTenantOrganizationsAwsRelativeUrl()}>
                                    {localization.actions.delete()}
                                </Link>,
                            supportLink:
                                <Link
                                    urlOrGetUrl={UrlHelper.supportUrl}
                                    variant="external">
                                    {localization.actions.supportLink()}
                                </Link>
                        });
                    } else if (scopeNodeModel.type === Contract.ScopeType.CloudProviderTenant &&
                        organizationConfiguration?.enabled === true) {
                        return (selection
                            ? localization.actions.deleteSelection
                            : localization.actions.deleteOrganizationTenant)({
                            deleteLink:
                                <Link
                                    urlOrGetUrl={CustomerConsoleAppUrlHelper.getConfigurationIntegrationsCloudProviderTenantOrganizationsAwsRelativeUrl()}>
                                    {localization.actions.delete()}
                                </Link>,
                            supportLink:
                                <Link
                                    urlOrGetUrl={UrlHelper.supportUrl}
                                    variant="external">
                                    {localization.actions.supportLink()}
                                </Link>
                        });
                    }

                    return undefined;
                },
            sort:
                (columnId, scopeNodeModel) => {
                    if (scopeNodeModel.type === Contract.ScopeType.CloudProviderTenant && !_.isNil(tenantModelMap[scopeNodeModel.configuration.id])) {
                        const tenantModel = tenantModelMap[scopeNodeModel.configuration.id];
                        return map<string, any>(
                            columnId,
                            {
                                [TableColumnId.RawId]: () => StringHelper.getSortValue((tenantModel.configuration as Contract.AwsTenantConfiguration).rawId),
                                [TableColumnId.RoleName]: () => StringHelper.getSortValue(tenantModel.roleName),
                                [TableColumnId.Status]: () => StringHelper.getSortValue(tenantModelStatusTranslator(tenantModel.status)),
                                [TableColumnId.TrailStatus]:
                                    () =>
                                        StringHelper.getSortValue(
                                            tenantModel.trailStatus
                                                ? tenantModelTrailStatusTranslator(tenantModel.trailStatus)
                                                : undefined)
                            });
                    } else {
                        return undefined;
                    }
                },
            title: localization.title()
        }),
        [tenantModelMap]);
}

enum TableColumnId {
    RawId = "rawId",
    RoleName = "roleName",
    Status = "status",
    TrailStatus = "trailStatus"
}