import { CopyToClipboardActionButton, CopyToClipboardButton } from "@infrastructure";
import { InputAdornment, TextField, TextFieldProps, useTheme } from "@mui/material";
import React from "react";

export type CopyToClipboardTextFieldProps = TextFieldProps & {
    getValue?: () => string;
    title?: string;
    value: string;
};

export function CopyToClipboardTextField({ getValue, title, value, ...props }: CopyToClipboardTextFieldProps) {
    const theme = useTheme();
    return (
        <TextField
            fullWidth={true}
            label={title}
            sx={{ marginTop: theme.spacing(3) }}
            value={value}
            variant="outlined"
            {...props}
            slotProps={{
                input: {
                    disableUnderline: true,
                    endAdornment:
                        <InputAdornment position="end">
                            {props.multiline
                                ? <CopyToClipboardButton getValue={() => getValue?.() ?? value}/>
                                : <CopyToClipboardActionButton
                                    getValue={() => getValue?.() ?? value}
                                    size="normal"/>}
                        </InputAdornment>,
                    readOnly: true
                }
            }}/>);
}