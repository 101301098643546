import React, { ReactNode } from "react";
import { Entity, entityModelStore, ProfileLayout, useTheme } from "../../../../../../../common";

type EntityProfileInfoLayoutProps = {
    children?: ReactNode;
    entityId: string;
};

export function EntityProfileInfoLayout({ children, entityId }: EntityProfileInfoLayoutProps) {
    const entityModel = entityModelStore.useGet(entityId);

    const theme = useTheme();
    return (
        <ProfileLayout
            topBarElement={
                <Entity
                    entityIdOrModel={entityModel}
                    linkOptions={{ disabled: true }}
                    sxOptions={{
                        icon: { fontSize: "30px" },
                        subtitle: { color: theme.palette.text.primary },
                        title: {
                            fontSize: "inherit",
                            fontWeight: "inherit"
                        }
                    }}
                    variant="iconTextTenant"/>}
            topBarSx={{ padding: theme.spacing(1.5, 2) }}>
            {children}
        </ProfileLayout>);
}