import { useLocalization } from "@infrastructure";
import React from "react";
import { useAwsCommonExcessivePermissionResourceRiskDefinition } from ".";
import { Contract, entityModelStore } from "../../../../../../../../../../../../common";
import { RiskDefinitionSection, RiskDefinitionSectionGroup } from "../../../../../../utilities";
import { PolicySection } from "../../components";
import { useGetAwsSecretsManagerSecretRiskContext } from "../contexts";

export function useAwsExcessivePermissionSecretRiskDefinition(riskModel: Contract.RiskModel) {
    const excessivePermissionSecretRiskModel = riskModel as Contract.AwsExcessivePermissionSecretRiskModel;
    const secretModel = entityModelStore.useGet(excessivePermissionSecretRiskModel.risk.entityId) as Contract.AwsSecretsManagerSecretModel;
    const secret = secretModel.entity as Contract.AwsSecretsManagerSecret;

    const getAwsSecretsManagerSecretRiskContext = useGetAwsSecretsManagerSecretRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.access.useAwsExcessivePermissionSecretRiskDefinition",
            () => ({
                sections: {
                    policyDocument: "Secret Policy"
                }
            }));
    return useAwsCommonExcessivePermissionResourceRiskDefinition(
        () => {
            const secretsManagerSecretRiskContext = getAwsSecretsManagerSecretRiskContext(secretModel);

            return [
                secretsManagerSecretRiskContext.generalInformation,
                secretsManagerSecretRiskContext.sensitive,
                secretsManagerSecretRiskContext.encryption
            ];
        },
        riskModel,
        [
            new RiskDefinitionSectionGroup(
                [
                    new RiskDefinitionSection(
                        <PolicySection policyDocument={secret.policyDocument}/>,
                        localization.sections.policyDocument(),
                        {
                            expandable: true
                        })
                ])
        ]);
}