import { ActionMenuItem, AddIcon, DeleteIcon, ItemSelector, map, Menu, Message, Optional, useChangeEffect, useLocalization } from "@infrastructure";
import { Box, Button, IconButton, List, ListItem, Radio, Stack, TextField, Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import { InlineEntitySelectionData } from ".";
import { Contract, customEntityAttributeDefinitionModelStore, ElasticsearchItemPageHelper, EntityController, EntityPropertyHelper, ScopeHelper, scopeNodeModelStore, tenantModelStore, TypeHelper, useEntityTypeNameTranslator, useTheme } from "../../..";
import { EntityAttributeDefinitionMultiSelect, EntityAttributeDefinitions, EntityTypeNameSelector, PagedEntityMultiSelect, PagedEntityPropertyValueSelector, PagedResourceTagSelector, TenantMultiSelect, useCustomRiskPolicyContext } from "../..";

type EntitySelectorProps = {
    builtInEntityAttributeTypeNames?: string[];
    directoryEntityType?: boolean;
    disableManualCustomEntityAttributes?: boolean;
    entityTypeName?: string;
    excludeCommonCloudProviderTenants?: boolean;
    includeServiceName?: boolean;
    namePattern?: boolean;
    onSelectionChanged: (selection?: EntitySelectorSelection) => void;
    permissionEvaluationEntities?: EntitySelectorPermissionEvaluationEntities;
    properties?: EntitySelectorProperty[];
    scopeId: string;
    selection?: EntitySelectorSelection;
};

type EntitySelectorProperty = "all" | "any" | "anyCustomerManagedKmsKey" | "attributes" | "criticalActionSeverityPermissionPrincipal" | "ids" | "namePattern" | "properties" | "provisioned" | "sensitive" | "tags" | "tenantIds" | "typeNames";

export function EntitySelector({ builtInEntityAttributeTypeNames = [], directoryEntityType = false, disableManualCustomEntityAttributes = false, entityTypeName, excludeCommonCloudProviderTenants = false, includeServiceName = false, namePattern = false, onSelectionChanged, permissionEvaluationEntities = undefined, properties, scopeId, selection }: EntitySelectorProps) {
    const scopeNodeModel = scopeNodeModelStore.useGet(scopeId);
    const { tenantTypes } = useCustomRiskPolicyContext();
    const [builtInAttributeTypeNames, setBuiltInAttributeTypeNames] =
        useState(
            () =>
                selection instanceof EntitySelectorAttributeSelection
                    ? selection.builtInAttributeTypeNames
                    : []);
    const [customAttributeDefinitionIds, setCustomAttributeDefinitionIds] =
        useState(
            () =>
                selection instanceof EntitySelectorAttributeSelection
                    ? selection.customAttributeDefinitionIds
                    : []);
    const [entityPropertyRawIdentifierToValuesMap, setEntityPropertyRawIdentifierToValuesMap] =
        useState(
            () =>
                selection instanceof EntitySelectorPropertySelection
                    ? selection.entityPropertyRawIdentifierToValuesMap
                    : {});
    const [ids, setIds] =
        useState(
            () =>
                selection instanceof EntitySelectorIdSelection
                    ? selection.ids
                    : []);
    const [namePatternSelection, setNamePatternSelection] =
        useState(
            () =>
                selection instanceof EntitySelectorNamePatternSelection
                    ? selection.namePattern
                    : "");
    const [provisioned, setProvisioned] =
        useState(
            () =>
                selection instanceof EntitySelectorProvisionedSelection
                    ? selection.provisioned
                    : true);
    const [tags, setTags] =
        useState(
            () =>
                selection instanceof EntitySelectorTagSelection
                    ? selection.tags
                    : []);
    const [tenantIds, setTenantIds] =
        useState(
            () =>
                selection instanceof EntitySelectorTenantIdSelection
                    ? selection.tenantIds
                    : []);
    const [typeNames, setTypeNames] =
        useState(
            () =>
                selection instanceof EntitySelectorTypeNameSelection
                    ? selection.typeNames
                    : []);
    const [selectionProperty, setSelectionProperty] =
        useState<Optional<EntitySelectorProperty>>(
            () => {
                if (_.isNil(selection)) {
                    return undefined;
                } else if (selection instanceof EntitySelectorAllSelection) {
                    return "all";
                } else if (selection instanceof EntitySelectorAnySelection) {
                    return "any";
                } else if (selection instanceof EntitySelectorAnyCustomerManagedKmsKeySelection) {
                    return "anyCustomerManagedKmsKey";
                } else if (selection instanceof EntitySelectorCriticalActionSeverityPermissionPrincipalSelection) {
                    return "criticalActionSeverityPermissionPrincipal";
                } else if (selection instanceof EntitySelectorIdSelection) {
                    return "ids";
                } else if (selection instanceof EntitySelectorNamePatternSelection) {
                    return "namePattern";
                } else if (selection instanceof EntitySelectorPropertySelection) {
                    return "properties";
                } else if (selection instanceof EntitySelectorProvisionedSelection) {
                    return "provisioned";
                } else if (selection instanceof EntitySelectorSensitiveSelection) {
                    return "sensitive";
                } else if (selection instanceof EntitySelectorTagSelection) {
                    return "tags";
                } else if (selection instanceof EntitySelectorTenantIdSelection) {
                    return "tenantIds";
                } else if (selection instanceof EntitySelectorTypeNameSelection) {
                    return "typeNames";
                } else {
                    return "attributes";
                }
            });

    useChangeEffect(
        () => {
            if (_.isNil(selectionProperty)) {
                onSelectionChanged(undefined);
            } else if (selectionProperty === "all") {
                onSelectionChanged(new EntitySelectorAllSelection());
            } else if (selectionProperty === "any") {
                onSelectionChanged(new EntitySelectorAnySelection());
            } else if (selectionProperty === "anyCustomerManagedKmsKey") {
                onSelectionChanged(new EntitySelectorAnyCustomerManagedKmsKeySelection());
            } else if (selectionProperty === "criticalActionSeverityPermissionPrincipal") {
                onSelectionChanged(new EntitySelectorCriticalActionSeverityPermissionPrincipalSelection());
            } else if (selectionProperty === "ids") {
                onSelectionChanged(new EntitySelectorIdSelection(ids));
            } else if (selectionProperty === "namePattern") {
                onSelectionChanged(new EntitySelectorNamePatternSelection(namePatternSelection));
            } else if (selectionProperty === "properties") {
                onSelectionChanged(new EntitySelectorPropertySelection(entityPropertyRawIdentifierToValuesMap));
            } else if (selectionProperty === "provisioned") {
                onSelectionChanged(new EntitySelectorProvisionedSelection(provisioned));
            } else if (selectionProperty === "sensitive") {
                onSelectionChanged(new EntitySelectorSensitiveSelection());
            } else if (selectionProperty === "tags") {
                onSelectionChanged(new EntitySelectorTagSelection(tags));
            } else if (selectionProperty === "tenantIds") {
                onSelectionChanged(new EntitySelectorTenantIdSelection(tenantIds));
            } else if (selectionProperty === "typeNames") {
                onSelectionChanged(new EntitySelectorTypeNameSelection(typeNames));
            } else {
                onSelectionChanged(
                    new EntitySelectorAttributeSelection(
                        builtInAttributeTypeNames,
                        customAttributeDefinitionIds));
            }
        },
        [builtInAttributeTypeNames, customAttributeDefinitionIds, entityPropertyRawIdentifierToValuesMap, ids, namePatternSelection, provisioned, selectionProperty, tags, tenantIds, typeNames]);

    entityTypeName =
        map(
            permissionEvaluationEntities,
            {
                [EntitySelectorPermissionEvaluationEntities.Azure]: () => Contract.TypeNames.AzureResource,
                [EntitySelectorPermissionEvaluationEntities.Gcp]: () => Contract.TypeNames.GcpScopeResource
            },
            () => entityTypeName);
    return (
        <Stack spacing={1}>
            {_(properties ?? []).
                concat(
                    namePattern
                        ? "namePattern"
                        : "ids").
                uniq().
                orderBy(
                    [
                        property => property === "all",
                        property => property === "any",
                        property => property === "ids",
                        property => property === "namePattern",
                        property => property === "properties"
                    ],
                    [
                        "desc",
                        "desc",
                        "desc",
                        "desc",
                        "asc"
                    ]).
                map(
                    property =>
                        <Stack
                            alignItems={
                                property === "properties"
                                    ? "start"
                                    : "center"}
                            direction="row"
                            key={property}
                            spacing={1}>
                            <Radio
                                checked={selectionProperty === property}
                                size="small"
                                onChange={() => setSelectionProperty(property)}/>
                            <Box>
                                {map(
                                    property,
                                    {
                                        "all": () =>
                                            <All
                                                entityTypeName={entityTypeName!}
                                                includeServiceName={includeServiceName}/>,
                                        "any": () =>
                                            <Any
                                                entityTypeName={entityTypeName!}
                                                includeServiceName={includeServiceName}/>,
                                        "anyCustomerManagedKmsKey": () =>
                                            <AnyCustomerManagedKmsKey
                                                entityTypeName={entityTypeName!}
                                                includeServiceName={includeServiceName}/>,
                                        "attributes": () =>
                                            <Attributes
                                                builtInEntityAttributeTypeNames={builtInEntityAttributeTypeNames}
                                                directoryEntityType={directoryEntityType}
                                                disabled={selectionProperty !== property}
                                                disableManualCustomEntityAttributes={disableManualCustomEntityAttributes}
                                                entityTypeName={entityTypeName!}
                                                includeServiceName={includeServiceName}
                                                scopeId={scopeId}
                                                selectedBuiltInEntityAttributeTypeNames={builtInAttributeTypeNames}
                                                selectedCustomEntityAttributeDefinitionIds={customAttributeDefinitionIds}
                                                onSelectedBuiltInEntityAttributesChanged={builtInEntityAttributeTypeNames => setBuiltInAttributeTypeNames(builtInEntityAttributeTypeNames)}
                                                onSelectedCustomEntityAttributesChanged={customEntityAttributeDefinitionIds => setCustomAttributeDefinitionIds(customEntityAttributeDefinitionIds)}/>,
                                        "criticalActionSeverityPermissionPrincipal": () =>
                                            <CriticalActionSeverityPermissionPrincipal
                                                entityTypeName={entityTypeName!}
                                                includeServiceName={includeServiceName}/>,
                                        "ids": () =>
                                            <Ids
                                                directoryEntityType={directoryEntityType}
                                                disabled={selectionProperty !== property}
                                                entityTypeName={entityTypeName!}
                                                excludeCommonCloudProviderTenants={excludeCommonCloudProviderTenants}
                                                includeServiceName={includeServiceName}
                                                permissionEvaluationEntities={permissionEvaluationEntities}
                                                scopeId={scopeId}
                                                selectItems={ids}
                                                onSelectedItemsChanged={items => setIds(items)}/>,
                                        "namePattern": () =>
                                            <NamePattern
                                                disabled={selectionProperty !== property}
                                                entityTypeName={entityTypeName!}
                                                includeServiceName={includeServiceName}
                                                namePatternSelection={namePatternSelection}
                                                onChange={namePattern => setNamePatternSelection(namePattern)}/>,
                                        "properties": () =>
                                            <Properties
                                                disabled={selectionProperty !== property}
                                                entityTypeName={entityTypeName!}
                                                includeServiceName={includeServiceName}
                                                key={property}
                                                scopeId={scopeId}
                                                selectedPropertyRawIdentifierToValuesMap={entityPropertyRawIdentifierToValuesMap}
                                                tenantTypes={tenantTypes}
                                                onSelectedPropertyRawIdentifierToValuesMapChanged={selectedPropertyRawIdentifierToValuesMap => setEntityPropertyRawIdentifierToValuesMap(selectedPropertyRawIdentifierToValuesMap)}/>,
                                        "provisioned": () =>
                                            <Provisioned
                                                disabled={selectionProperty !== property}
                                                provisioned={provisioned}
                                                onSelectedItemsChanged={provisioned => setProvisioned(provisioned)}/>,
                                        "sensitive": () =>
                                            <Sensitive
                                                entityTypeName={entityTypeName!}
                                                includeServiceName={includeServiceName}/>,
                                        "tags": () =>
                                            <Tags
                                                disabled={selectionProperty !== property}
                                                entityTypeName={entityTypeName!}
                                                includeServiceName={includeServiceName}
                                                scopeId={scopeId}
                                                selectItems={tags}
                                                tenantTypes={tenantTypes}
                                                onSelectedTagsChanged={items => setTags(items)}/>,
                                        "tenantIds": () =>
                                            <Scopes
                                                disabled={selectionProperty !== property}
                                                entityTypeName={entityTypeName!}
                                                includeServiceName={includeServiceName}
                                                scopeNodeModel={scopeNodeModel}
                                                selectedScopeIds={tenantIds}
                                                tenantTypes={tenantTypes}
                                                onSelectedScopeIdsChanged={items => setTenantIds(items)}/>,
                                        "typeNames": () =>
                                            <TypeNames
                                                disabled={selectionProperty !== property}
                                                includeServiceName={includeServiceName}
                                                selectedTypeNames={typeNames}
                                                selectionEntityTypeName={entityTypeName!}
                                                onSelectedTypeNamesChanged={items => setTypeNames(items)}/>
                                    })}
                            </Box>
                        </Stack>).
                value()}
        </Stack>);
}

type AllProps = {
    entityTypeName: string;
    includeServiceName: boolean;
};

function All({ entityTypeName, includeServiceName }: AllProps) {
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "common.customRiskPolicy.entitySelector.all",
            () => ({
                title: "All {{translatedEntityTypeName}}"
            }));

    return (
        <Typography>
            {localization.title({
                translatedEntityTypeName:
                    entityTypeNameTranslator(
                        entityTypeName,
                        {
                            count: 0,
                            includeServiceName,
                            variant: "text"
                        })
            })}
        </Typography>);
}

type AnyProps = {
    entityTypeName: string;
    includeServiceName: boolean;
};

function Any({ entityTypeName, includeServiceName }: AnyProps) {
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "common.customRiskPolicy.entitySelector.any",
            () => ({
                title: "Any {{translatedEntityTypeName}}"
            }));

    return (
        <Typography>
            {localization.title({
                translatedEntityTypeName:
                    entityTypeNameTranslator(
                        entityTypeName,
                        {
                            includeServiceName,
                            variant: "text"
                        })
            })}
        </Typography>);
}

type AnyCustomerManagedKmsKeyProps = {
    entityTypeName: string;
    includeServiceName: boolean;
};

function AnyCustomerManagedKmsKey({ entityTypeName, includeServiceName }: AnyCustomerManagedKmsKeyProps) {
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "common.customRiskPolicy.entitySelector.anyCustomerManagedKmsKey",
            () => ({
                title: "Any customer managed encryption {{keyTranslatedTypeName}}"
            }));

    return (
        <Typography>
            {localization.title({
                keyTranslatedTypeName:
                    entityTypeNameTranslator(
                        entityTypeName,
                        {
                            includeServiceName,
                            variant: "text"
                        })
            })}
        </Typography>);
}

type AttributesProps = {
    builtInEntityAttributeTypeNames: string[];
    directoryEntityType: boolean;
    disabled?: boolean;
    disableManualCustomEntityAttributes: boolean;
    entityTypeName: string;
    includeServiceName: boolean;
    onSelectedBuiltInEntityAttributesChanged: (builtInEntityAttributeTypeNames: string[]) => void;
    onSelectedCustomEntityAttributesChanged: (customEntityAttributeDefinitionIds: string[]) => void;
    scopeId: string;
    selectedBuiltInEntityAttributeTypeNames: string[];
    selectedCustomEntityAttributeDefinitionIds: string[];
};

function Attributes({ builtInEntityAttributeTypeNames, directoryEntityType, disabled, disableManualCustomEntityAttributes, entityTypeName, includeServiceName, onSelectedBuiltInEntityAttributesChanged, onSelectedCustomEntityAttributesChanged, scopeId, selectedBuiltInEntityAttributeTypeNames, selectedCustomEntityAttributeDefinitionIds }: AttributesProps) {
    const customEntityAttributeDefinitionModels = customEntityAttributeDefinitionModelStore.useGetAll();
    const [scopeCustomEntityAttributeDefinitionIds] =
        useMemo(
            () => {
                if (directoryEntityType) {
                    return [];
                }
                const scopeCustomEntityAttributeDefinitionIds =
                    _(customEntityAttributeDefinitionModels).
                        filter(
                            customEntityAttributeDefinitionModel =>
                                (customEntityAttributeDefinitionModel.configuration.scopeId === ScopeHelper.customerId ||
                                    customEntityAttributeDefinitionModel.configuration.scopeId === scopeId) &&
                                (!disableManualCustomEntityAttributes || !TypeHelper.extendOrImplement(customEntityAttributeDefinitionModel.configuration.typeName, Contract.TypeNames.CustomEntityAttributeDefinitionConfiguration))).
                        map(customEntityAttributeDefinitionModel => customEntityAttributeDefinitionModel.configuration.id).
                        value();
                return [scopeCustomEntityAttributeDefinitionIds];
            },
            [customEntityAttributeDefinitionModels, scopeId]);
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "common.customRiskPolicy.entitySelector.attributes",
            () => ({
                title: "*capitalize*{{translatedEntityTypeName}}** with these labels"
            }));

    return (
        <Stack
            alignItems="center"
            direction="row"
            spacing={1}>
            <Box>
                {localization.title({
                    translatedEntityTypeName:
                        entityTypeNameTranslator(
                            entityTypeName,
                            {
                                count: 0,
                                includeServiceName,
                                variant: "text"
                            })
                })}
            </Box>
            <EntityAttributeDefinitionMultiSelect
                builtInEntityAttributeTypeNames={builtInEntityAttributeTypeNames}
                customEntityAttributeDefinitionIds={scopeCustomEntityAttributeDefinitionIds}
                disabled={disabled}
                selectedBuiltInEntityAttributeTypeNames={selectedBuiltInEntityAttributeTypeNames}
                selectedCustomEntityAttributeDefinitionIds={selectedCustomEntityAttributeDefinitionIds}
                variant={
                    _.isEmpty(selectedBuiltInEntityAttributeTypeNames) &&
                    _.isEmpty(selectedCustomEntityAttributeDefinitionIds)
                        ? "button"
                        : "icon"}
                onToggleBuiltInEntityAttributeItem={
                    async builtInEntityAttributeTypeNames =>
                        onSelectedBuiltInEntityAttributesChanged(
                            _(selectedBuiltInEntityAttributeTypeNames).
                                intersection(builtInEntityAttributeTypeNames).
                                isEmpty()
                                ? _.concat(selectedBuiltInEntityAttributeTypeNames, builtInEntityAttributeTypeNames)
                                : _.without(selectedBuiltInEntityAttributeTypeNames, ...builtInEntityAttributeTypeNames))}
                onToggleCustomEntityAttributeItem={
                    async customEntityAttributeDefinitionId =>
                        onSelectedCustomEntityAttributesChanged(
                            _.includes(selectedCustomEntityAttributeDefinitionIds, customEntityAttributeDefinitionId)
                                ? _.without(selectedCustomEntityAttributeDefinitionIds, customEntityAttributeDefinitionId)
                                : _.concat(selectedCustomEntityAttributeDefinitionIds, customEntityAttributeDefinitionId))}/>
            <EntityAttributeDefinitions
                builtInEntityAttributeTypeNames={selectedBuiltInEntityAttributeTypeNames}
                customEntityAttributeDefinitionIds={selectedCustomEntityAttributeDefinitionIds}
                variant="wrap"/>
        </Stack>);
}

type CriticalActionSeverityPermissionPrincipalProps = {
    entityTypeName: string;
    includeServiceName: boolean;
};

function CriticalActionSeverityPermissionPrincipal({ entityTypeName, includeServiceName }: CriticalActionSeverityPermissionPrincipalProps) {
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "common.customRiskPolicy.entitySelector.criticalActionSeverityPermissionPrincipal",
            () => ({
                message: "{{translatedEntityTypeName}} which are admins or can escalate their privileges to an admin.",
                title: "{{translatedEntityTypeName}} with high privileges"
            }));

    return (
        <Stack
            alignItems="center"
            direction="row"
            spacing={1}>
            <Typography>
                {localization.title({
                    translatedEntityTypeName:
                        entityTypeNameTranslator(
                            entityTypeName,
                            {
                                count: 0,
                                includeServiceName
                            })
                })}
            </Typography>
            <Message
                level="info"
                title={
                    localization.message({
                        translatedEntityTypeName:
                            entityTypeNameTranslator(
                                entityTypeName,
                                {
                                    count: 0,
                                    includeServiceName
                                })
                    })}
                variant="minimal"/>
        </Stack>);
}

type IdsProps = {
    directoryEntityType: boolean;
    disabled?: boolean;
    entityTypeName: string;
    excludeCommonCloudProviderTenants: boolean;
    includeServiceName: boolean;
    onSelectedItemsChanged: (item: any[]) => void;
    permissionEvaluationEntities?: EntitySelectorPermissionEvaluationEntities;
    scopeId: string;
    selectItems: any[];
};

function Ids({ directoryEntityType, disabled, entityTypeName, excludeCommonCloudProviderTenants, includeServiceName, onSelectedItemsChanged, permissionEvaluationEntities, scopeId, selectItems }: IdsProps) {
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "common.customRiskPolicy.entitySelector.ids",
            () => ({
                placeholder: "Ids",
                title: "Specific {{translatedEntityTypeName}}"
            }));

    return (
        <Stack
            alignItems="center"
            direction="row"
            spacing={1}>
            <Box>
                {localization.title({
                    translatedEntityTypeName:
                        entityTypeNameTranslator(
                            entityTypeName!,
                            {
                                count: 0,
                                includeServiceName,
                                variant: "text"
                            })
                })}
            </Box>
            <PagedEntityMultiSelect
                disabled={disabled}
                fieldOptions={{ dense: true }}
                getEntityModelPage={
                    ElasticsearchItemPageHelper.makePagedEntityMultiSelect(
                        async (itemNextPageSearchCursor, searchText) => {
                            const { entityModelPage } =
                                await EntityController.searchEntityModels(
                                    map<EntitySelectorPermissionEvaluationEntities, Contract.EntityControllerSearchEntityModelsRequest>(
                                        permissionEvaluationEntities,
                                        {
                                            [EntitySelectorPermissionEvaluationEntities.Azure]: () =>
                                                new Contract.EntityControllerSearchEntityModelsAzurePermissionEvaluationRequest(
                                                    false,
                                                    15,
                                                    itemNextPageSearchCursor,
                                                    scopeId,
                                                    searchText),
                                            [EntitySelectorPermissionEvaluationEntities.Gcp]: () =>
                                                new Contract.EntityControllerSearchEntityModelsGcpPermissionEvaluationRequest(
                                                    false,
                                                    15,
                                                    itemNextPageSearchCursor,
                                                    scopeId,
                                                    searchText)
                                        },
                                        () =>
                                            directoryEntityType
                                                ? new Contract.EntityControllerSearchEntityModelsDirectoryEntityTypeRequest(
                                                    false,
                                                    15,
                                                    itemNextPageSearchCursor,
                                                    undefined,
                                                    searchText,
                                                    entityTypeName!)
                                                : new Contract.EntityControllerSearchEntityModelsTypeRequest(
                                                    false,
                                                    15,
                                                    itemNextPageSearchCursor,
                                                    scopeId,
                                                    searchText,
                                                    excludeCommonCloudProviderTenants,
                                                    entityTypeName!)));
                            return entityModelPage;
                        })}
                placeholder={entityTypeNameTranslator(entityTypeName!, { includeServiceName })}
                selectedEntityIds={selectItems}
                onSelectedEntityIdsChanged={entityIds => onSelectedItemsChanged(entityIds)}/>
        </Stack>);
}

type NamePatternProps = {
    disabled?: boolean;
    entityTypeName: string;
    includeServiceName: boolean;
    namePatternSelection: Optional<string>;
    onChange: (item: string) => void;
};

function NamePattern({ disabled, entityTypeName, includeServiceName, namePatternSelection, onChange }: NamePatternProps) {
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "common.customRiskPolicy.entitySelector.namePattern",
            () => ({
                helpText: "Supported pattern operators:\n* indicates zero or more characters.\n? indicates a single character.",
                placeholder: "Pattern",
                title: "*capitalize*{{translatedEntityTypeName}}** that match this pattern"
            }));

    return (
        <Stack
            alignItems="center"
            direction="row"
            spacing={1}>
            <Box>
                {localization.title({
                    translatedEntityTypeName:
                        entityTypeNameTranslator(
                            entityTypeName!,
                            {
                                count: 0,
                                includeServiceName,
                                variant: "text"
                            })
                })}
            </Box>
            <TextField
                disabled={disabled}
                label={localization.placeholder()}
                size="small"
                value={namePatternSelection}
                variant="outlined"
                onChange={event => onChange(event.target.value)}/>
            <Message
                level="info"
                title={
                    <Typography style={{ whiteSpace: "pre-wrap" }}>
                        {localization.helpText()}
                    </Typography>}
                variant="minimal"/>
        </Stack>);
}

type PropertiesProps = {
    disabled?: boolean;
    entityTypeName: string;
    includeServiceName: boolean;
    onSelectedPropertyRawIdentifierToValuesMapChanged: (propertyRawIdentifierToValuesMap: _.Dictionary<string[]>) => void;
    scopeId: string;
    selectedPropertyRawIdentifierToValuesMap: _.Dictionary<string[]>;
    tenantTypes: Contract.TenantType[];
};

function Properties({ disabled, entityTypeName, includeServiceName, onSelectedPropertyRawIdentifierToValuesMapChanged, scopeId, selectedPropertyRawIdentifierToValuesMap, tenantTypes }: PropertiesProps) {
    const scopeActiveTenantIds = ScopeHelper.getScopeActiveTenantIds([scopeId]);
    const scopeNodeModel = scopeNodeModelStore.useGet(scopeId);
    const activeTenantModels = tenantModelStore.useGet(scopeActiveTenantIds);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "common.customRiskPolicy.entitySelector.properties",
            () => ({
                actions: {
                    add: "Add Field"
                },
                entityProperty: {
                    text: "*capitalize*{{identifierName}}** matching"
                },
                title: "*capitalize*{{translatedEntityTypeName}}** with these fields",
                warning: "No fields defined for {{scopeName}}"
            }));

    const propertyDefinitionIdentifiers =
        EntityPropertyHelper.getDefinitionIdentifiers(
            _.isEmpty(tenantTypes)
                ? activeTenantModels
                : _.filter(
                    activeTenantModels,
                    activeTenantModel => _.includes(tenantTypes, activeTenantModel.tenantType)));

    const rawIdentifierToIdentifierMap =
        useMemo(
            () =>
                _.keyBy(
                    propertyDefinitionIdentifiers,
                    propertyDefinitionIdentifier => propertyDefinitionIdentifier.raw),
            [propertyDefinitionIdentifiers]);

    const [selectedPropertyRawIdentifiers, setSelectedPropertyRawIdentifiers] = useState<string[]>(_.keys(selectedPropertyRawIdentifierToValuesMap));
    const menuItems =
        useMemo(
            () =>
                _(rawIdentifierToIdentifierMap).
                    keys().
                    without(...selectedPropertyRawIdentifiers).
                    map(
                        unusedRawIdentifier =>
                            new ActionMenuItem(
                                () => {
                                    setSelectedPropertyRawIdentifiers(
                                        selectedPropertyRawIdentifiers => {
                                            const updatedSelectedPropertyRawIdentifiers = [
                                                ...selectedPropertyRawIdentifiers,
                                                unusedRawIdentifier
                                            ];

                                            onSelectedPropertyRawIdentifierToValuesMapChanged(
                                                _(updatedSelectedPropertyRawIdentifiers).
                                                    keyBy().
                                                    mapValues(selectedRawIdentifier => selectedPropertyRawIdentifierToValuesMap[selectedRawIdentifier] ?? []).
                                                    value());

                                            return updatedSelectedPropertyRawIdentifiers;
                                        });
                                },
                                rawIdentifierToIdentifierMap[unusedRawIdentifier].name)).
                    value(),
            [rawIdentifierToIdentifierMap, selectedPropertyRawIdentifiers, selectedPropertyRawIdentifierToValuesMap]);

    const theme = useTheme();
    return (
        <Stack spacing={1}>
            <Stack
                alignItems="center"
                direction="row"
                spacing={1}
                sx={{ padding: theme.spacing(0.5, 0) }}>
                <Box sx={{
                    color:
                        _.isEmpty(propertyDefinitionIdentifiers)
                            ? theme.palette.text.disabled
                            : theme.palette.text.primary
                }}>
                    {localization.title({
                        translatedEntityTypeName:
                            entityTypeNameTranslator(
                                entityTypeName,
                                {
                                    count: 0,
                                    includeServiceName,
                                    variant: "text"
                                })
                    })}
                </Box>
                <Menu
                    disabled={disabled}
                    itemsOrGetItems={menuItems}
                    itemSx={{ minWidth: theme.spacing(20) }}
                    variant="bottomLeft">
                    <Button
                        disabled={disabled || _.isEmpty(menuItems)}
                        size="small"
                        startIcon={<AddIcon/>}
                        variant="outlined">
                        <Typography>
                            {localization.actions.add()}
                        </Typography>
                    </Button>
                </Menu>
                {_.isEmpty(propertyDefinitionIdentifiers) &&
                    <Message
                        level="info"
                        title={localization.warning({ scopeName: scopeNodeModel.configuration.name })}
                        variant="minimal"/>}
            </Stack>
            <Box>
                {!disabled &&
                    <List disablePadding={true}>
                        <Stack spacing={2}>
                            {_.map(
                                selectedPropertyRawIdentifierToValuesMap,
                                (values, rawIdentifier) =>
                                    <ListItem
                                        disablePadding={true}
                                        key={rawIdentifier}>
                                        <Stack
                                            alignItems="center"
                                            direction="row"
                                            key={rawIdentifier}
                                            spacing={1}>
                                            <Typography>
                                                {localization.entityProperty.text({ identifierName: rawIdentifierToIdentifierMap[rawIdentifier].name })}
                                            </Typography>
                                            <PagedEntityPropertyValueSelector
                                                disabled={disabled}
                                                entityTypeName={entityTypeName}
                                                identifier={rawIdentifierToIdentifierMap[rawIdentifier]}
                                                scopeId={scopeId}
                                                selectedValues={values}
                                                tenantTypes={tenantTypes}
                                                onSelectedValuesChanged={
                                                    selectedValues =>
                                                        onSelectedPropertyRawIdentifierToValuesMapChanged(
                                                            _(selectedPropertyRawIdentifiers).
                                                                keyBy().
                                                                mapValues(
                                                                    selectedRawIdentifier =>
                                                                        selectedRawIdentifier === rawIdentifier
                                                                            ? selectedValues
                                                                            : selectedPropertyRawIdentifierToValuesMap[selectedRawIdentifier]).
                                                                value())}/>
                                            <IconButton
                                                size="small"
                                                onClick={
                                                    () =>
                                                        setSelectedPropertyRawIdentifiers(
                                                            selectedPropertyRawIdentifiers => {
                                                                const updatedSelectedPropertyRawIdentifiers =
                                                                    _.filter(
                                                                        selectedPropertyRawIdentifiers,
                                                                        selectedPropertyRawIdentifier => selectedPropertyRawIdentifier !== rawIdentifier);

                                                                onSelectedPropertyRawIdentifierToValuesMapChanged(
                                                                    _(updatedSelectedPropertyRawIdentifiers).
                                                                        keyBy().
                                                                        mapValues(selectedRawIdentifier => selectedPropertyRawIdentifierToValuesMap[selectedRawIdentifier] ?? []).
                                                                        value());

                                                                return updatedSelectedPropertyRawIdentifiers;
                                                            })}>
                                                <DeleteIcon/>
                                            </IconButton>
                                        </Stack>
                                    </ListItem>)}
                        </Stack>
                    </List>}
            </Box>
        </Stack>);
}

type ProvisionedProps = {
    disabled: boolean;
    onSelectedItemsChanged: (item: boolean) => void;
    provisioned: boolean;
};

function Provisioned({ disabled, onSelectedItemsChanged, provisioned }: ProvisionedProps) {
    const localization =
        useLocalization(
            "common.customRiskPolicy.entitySelector.provisioned",
            () => ({
                provisioned: {
                    false: "Not provisioned only",
                    true: "Provisioned only"
                },
                title: "Provisioned status"
            }));

    return (
        <Stack
            alignItems="center"
            direction="row"
            spacing={1}>
            <Box>
                {localization.title()}
            </Box>
            <ItemSelector
                dense={true}
                disabled={disabled}
                items={["true", "false"]}
                selectedItem={provisioned.toString()}
                onSelectedItemChanged={item => onSelectedItemsChanged(item == "true")}>
                {item => localization.provisioned[item as ("true" | "false")]()}
            </ItemSelector>
        </Stack>);
}

type ScopesProps = {
    disabled?: boolean;
    entityTypeName: string;
    includeServiceName: boolean;
    onSelectedScopeIdsChanged: (item: any[]) => void;
    scopeNodeModel: Contract.ScopeNodeModel;
    selectedScopeIds: any[];
    tenantTypes: Contract.TenantType[];
};

function Scopes({ disabled, entityTypeName, includeServiceName, onSelectedScopeIdsChanged, scopeNodeModel, selectedScopeIds, tenantTypes }: ScopesProps) {
    const scopeNodeMap = scopeNodeModelStore.useGetActiveScopeNodeMap();
    const tenantModels = tenantModelStore.useGet(scopeNodeMap[scopeNodeModel.configuration.id].tenantIds);
    const tenantIds =
        useMemo(
            () =>
                _(tenantModels).
                    filter(
                        tenantModel =>
                            _.includes(
                                tenantTypes,
                                tenantModel.tenantType)).
                    map(tenantModel => tenantModel.configuration.id).
                    value(),
            [tenantModels]);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "common.customRiskPolicy.entitySelector.scopes",
            () => ({
                selected: "{{selectedTypeNameCount}} selected",
                title: "*capitalize*{{translatedEntityTypeName}}** in these accounts"
            }));

    return (
        <Stack
            alignItems="center"
            direction="row"
            spacing={1}>
            <Typography>
                {localization.title({
                    translatedEntityTypeName:
                        entityTypeNameTranslator(
                            entityTypeName,
                            {
                                count: 0,
                                includeServiceName,
                                variant: "text"
                            })
                })}
            </Typography>
            <Stack spacing={2}>
                <TenantMultiSelect
                    disabled={disabled}
                    displayAllAsEmpty={false}
                    fullWidth={false}
                    rootFolderId={scopeNodeModel.configuration.id}
                    selectedTenantIds={selectedScopeIds}
                    tenantIds={tenantIds}
                    variant="itemSelector"
                    onSelectedTenantIdsChanged={selectedTenantIds => onSelectedScopeIdsChanged(selectedTenantIds)}/>
            </Stack>
        </Stack>);
}

type SensitiveProps = {
    entityTypeName: string;
    includeServiceName: boolean;
};

function Sensitive({ entityTypeName, includeServiceName }: SensitiveProps) {
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "common.customRiskPolicy.entitySelector.sensitive",
            () => ({
                title: "Sensitive {{translatedEntityTypeName}}"
            }));

    return (
        <Typography>
            {localization.title({
                translatedEntityTypeName:
                    entityTypeNameTranslator(
                        entityTypeName,
                        {
                            count: 0,
                            includeServiceName,
                            variant: "text"
                        })
            })}
        </Typography>);
}

type TagsProps = {
    disabled?: boolean;
    entityTypeName: string;
    includeServiceName: boolean;
    onSelectedTagsChanged: (item: Contract.ResourceTag[]) => void;
    scopeId: string;
    selectItems: any[];
    tenantTypes: Contract.TenantType[];
};

function Tags({ disabled, entityTypeName, includeServiceName, onSelectedTagsChanged, scopeId, selectItems, tenantTypes }: TagsProps) {
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "common.customRiskPolicy.entitySelector.tags",
            () => ({
                placeholder: "Tags",
                title: "*capitalize*{{translatedEntityTypeName}}** with these tags"
            }));

    return (
        <Stack
            alignItems="center"
            direction="row"
            spacing={1}>
            <Box>
                {localization.title({
                    translatedEntityTypeName:
                        entityTypeNameTranslator(
                            entityTypeName,
                            {
                                count: 0,
                                includeServiceName,
                                variant: "text"
                            })
                })}
            </Box>
            <PagedResourceTagSelector
                disabled={disabled}
                entityTypeName={entityTypeName}
                multiSelect={true}
                placeholder={localization.placeholder()}
                scopeId={scopeId}
                selectedTags={selectItems}
                tenantTypes={tenantTypes}
                onSelectedTagsChanged={onSelectedTagsChanged}/>
        </Stack>);
}

type TypeNamesProps = {
    disabled?: boolean;
    includeServiceName: boolean;
    onSelectedTypeNamesChanged: (item: string[]) => void;
    selectedTypeNames: any[];
    selectionEntityTypeName: string;
};

function TypeNames({ disabled, includeServiceName, onSelectedTypeNamesChanged, selectedTypeNames, selectionEntityTypeName }: TypeNamesProps) {
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "common.customRiskPolicy.entitySelector.typeNames",
            () => ({
                title: "*capitalize*{{translatedEntityTypeName}}** from these types"
            }));

    return (
        <Stack
            alignItems="center"
            direction="row"
            spacing={1}>
            <Typography>
                {localization.title({
                    translatedEntityTypeName:
                        entityTypeNameTranslator(
                            selectionEntityTypeName,
                            {
                                count: 0,
                                includeServiceName,
                                variant: "text"
                            })
                })}
            </Typography>
            <EntityTypeNameSelector
                disabled={disabled}
                entityTypeNames={[selectionEntityTypeName]}
                multiSelect={true}
                selectedEntityTypeNames={selectedTypeNames}
                onSelectedItemsChanged={onSelectedTypeNamesChanged}/>
        </Stack>);
}

export class EntitySelectorSelection {
    public static getSelectorSelection(
        all?: boolean,
        any?: boolean,
        anyCustomerManagedKmsKey?: boolean,
        builtInAttributeTypeNames?: string[],
        criticalActionSeverityPermissionPrincipal?: boolean,
        customAttributeDefinitionIds?: string[],
        entityPropertyRawIdentifierToValuesMap?: _.Dictionary<string[]>,
        ids?: string[],
        namePattern?: string,
        sensitive?: boolean,
        tags?: Contract.ResourceTag[],
        tenantIds?: string[],
        typeNames?: string[])
        : Optional<EntitySelectorSelection> {
        if (all === true) {
            return new EntitySelectorAllSelection();
        } else if (any == true) {
            return new EntitySelectorAnySelection();
        } else if (anyCustomerManagedKmsKey == true) {
            return new EntitySelectorAnyCustomerManagedKmsKeySelection();
        } else if (
            !_.isNil(builtInAttributeTypeNames) ||
            !_.isNil(customAttributeDefinitionIds)) {
            return new EntitySelectorAttributeSelection(
                builtInAttributeTypeNames ?? [],
                customAttributeDefinitionIds ?? []);
        } else if (criticalActionSeverityPermissionPrincipal === true) {
            return new EntitySelectorCriticalActionSeverityPermissionPrincipalSelection();
        } else if (!_.isNil(ids)) {
            return new EntitySelectorIdSelection(ids);
        } else if (!_.isNil(namePattern)) {
            return new EntitySelectorNamePatternSelection(namePattern);
        } else if (!_.isNil(entityPropertyRawIdentifierToValuesMap)) {
            return new EntitySelectorPropertySelection(entityPropertyRawIdentifierToValuesMap);
        } else if (sensitive === true) {
            return new EntitySelectorSensitiveSelection();
        } else if (!_.isNil(tags)) {
            return new EntitySelectorTagSelection(tags);
        } else if (!_.isNil(tenantIds)) {
            return new EntitySelectorTenantIdSelection(tenantIds);
        } else if (!_.isNil(typeNames)) {
            return new EntitySelectorTypeNameSelection(typeNames);
        } else {
            return undefined;
        }
    }

    public getInlineSelectionData(): InlineEntitySelectionData {
        return {};
    }

    public valid() {
        return true;
    }
}

export class EntitySelectorAllSelection extends EntitySelectorSelection {
    constructor() {
        super();
    }

    public getInlineSelectionData(): InlineEntitySelectionData {
        return {
            allEntities: true
        };
    }
}

export class EntitySelectorAnySelection extends EntitySelectorSelection {
    constructor() {
        super();
    }

    public getInlineSelectionData(): InlineEntitySelectionData {
        return {
            anyEntity: true
        };
    }
}

export class EntitySelectorAnyCustomerManagedKmsKeySelection extends EntitySelectorSelection {
    constructor() {
        super();
    }

    public getInlineSelectionData(): InlineEntitySelectionData {
        return {
            anyCustomerManagedKmsKey: true
        };
    }
}

export enum EntitySelectorPermissionEvaluationEntities {
    Azure = "azure",
    Gcp = "gcp"
}

export class EntitySelectorAttributeSelection extends EntitySelectorSelection {
    constructor(
        public builtInAttributeTypeNames: string[],
        public customAttributeDefinitionIds: string[]) {
        super();
    }

    public getInlineSelectionData(): InlineEntitySelectionData {
        return {
            entityBuiltInAttributeTypeNames: this.builtInAttributeTypeNames,
            entityCustomAttributeDefinitionIds: this.customAttributeDefinitionIds
        };
    }

    public valid() {
        return !_.isEmpty(this.builtInAttributeTypeNames) ||
            !_.isEmpty(this.customAttributeDefinitionIds);
    }
}

export class EntitySelectorCriticalActionSeverityPermissionPrincipalSelection extends EntitySelectorSelection {
    constructor() {
        super();
    }

    public getInlineSelectionData(): InlineEntitySelectionData {
        return {
            criticalActionSeverityPermissionPrincipal: true
        };
    }
}

export class EntitySelectorIdSelection extends EntitySelectorSelection {
    constructor(public ids: string[]) {
        super();
    }

    public getInlineSelectionData(): InlineEntitySelectionData {
        return {
            entityIds: this.ids
        };
    }

    public valid() {
        return !_.isEmpty(this.ids);
    }
}

export class EntitySelectorNamePatternSelection extends EntitySelectorSelection {
    constructor(public namePattern?: string) {
        super();
    }

    public getInlineSelectionData(): InlineEntitySelectionData {
        return {
            entityNamePattern: this.namePattern
        };
    }

    public valid() {
        return !_.isEmpty(this.namePattern);
    }
}

export class EntitySelectorPropertySelection extends EntitySelectorSelection {
    constructor(public entityPropertyRawIdentifierToValuesMap: _.Dictionary<string[]>) {
        super();
    }

    public getInlineSelectionData(): InlineEntitySelectionData {
        return {
            entityPropertyRawIdentifierToValuesMap: this.entityPropertyRawIdentifierToValuesMap
        };
    }

    public valid() {
        return !_.isEmpty(this.entityPropertyRawIdentifierToValuesMap) &&
            _.every(
                this.entityPropertyRawIdentifierToValuesMap,
                values => !_.isEmpty(values));
    }
}

export class EntitySelectorProvisionedSelection extends EntitySelectorSelection {
    constructor(public provisioned: boolean) {
        super();
    }

    public getInlineSelectionData(): InlineEntitySelectionData {
        return {
            provisionedEntity: this.provisioned
        };
    }
}

export class EntitySelectorSensitiveSelection extends EntitySelectorSelection {
    constructor() {
        super();
    }

    public getInlineSelectionData(): InlineEntitySelectionData {
        return {
            sensitiveEntity: true
        };
    }
}

export class EntitySelectorTagSelection extends EntitySelectorSelection {
    constructor(public tags: Contract.ResourceTag[]) {
        super();
    }

    public getInlineSelectionData(): InlineEntitySelectionData {
        return {
            entityTags: this.tags
        };
    }

    public valid() {
        return !_.isEmpty(this.tags);
    }
}

export class EntitySelectorTenantIdSelection extends EntitySelectorSelection {
    constructor(public tenantIds: string[]) {
        super();
    }

    public getInlineSelectionData(): InlineEntitySelectionData {
        return {
            entityTenantIds: this.tenantIds
        };
    }

    public valid() {
        return !_.isEmpty(this.tenantIds);
    }
}

export class EntitySelectorTypeNameSelection extends EntitySelectorSelection {
    constructor(public typeNames: string[]) {
        super();
    }

    public getInlineSelectionData(): InlineEntitySelectionData {
        return {
            entityTypeNames: this.typeNames
        };
    }

    public valid() {
        return !_.isEmpty(this.typeNames);
    }
}