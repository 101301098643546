import { useLocalization } from "@infrastructure";
import _ from "lodash";
import { AuditEventDefinition, AuditEventDefinitionContext, AuditEventDefinitionDetailItem } from "..";
import { Contract } from "../../../../../../../../../common";
import { useRiskAuditEventDetailsItems } from "./useRiskAuditEventDetailsItems";

export function useRiskNoteUpdateAuditEventDefinition(context: AuditEventDefinitionContext) {
    const riskAuditEventDetailsItems = useRiskAuditEventDetailsItems(context);
    const localization =
        useLocalization(
            "views.customer.auditEvents.profile.hooks.useDefinition.hooks.useRiskNoteUpdateAuditEventDefinition",
            () => ({
                newMessage: "New message",
                oldMessage: "Old message"
            }));

    const riskNoteUpdateAuditEvent = context.auditEventModel.auditEvent as Contract.RiskNoteUpdateAuditEvent;
    return new AuditEventDefinition(
        _.filter([
            new AuditEventDefinitionDetailItem(
                localization.oldMessage(),
                riskNoteUpdateAuditEvent.oldMessage),
            new AuditEventDefinitionDetailItem(
                localization.newMessage(),
                riskNoteUpdateAuditEvent.newMessage),
            riskAuditEventDetailsItems.riskPolicyTypeItem,
            riskAuditEventDetailsItems.riskIdItem,
            riskAuditEventDetailsItems.riskDeletedItem,
            riskAuditEventDetailsItems.riskLinkItem
        ]) as AuditEventDefinitionDetailItem[]);
}