﻿import { InlineSecrets, Link, TextViewer, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../../../common";
import { RiskDefinitionSection } from "../../../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../../../useCommonSectionsAndDescriptionDefinition";
import { EntityExternalConsoleLink } from "../../../../components";
import { useGetGcpComputeInstancesRiskContext, useGetGcpTenantEntityRiskContext } from "../../../contexts";
import { useGetGcpComputeInstanceStartupScriptSecretExistsRiskContext } from "./hooks";

export function useGcpComputeInstanceStartupScriptSecretExistsRiskTenantEntityDefinition(riskModel: Contract.GcpComputeInstanceStartupScriptSecretExistsRiskModel) {
    const risk = riskModel.risk as Contract.GcpComputeInstanceStartupScriptSecretExistsRisk;
    const tenantEntityModel = entityModelStore.useGet(risk.entityId) as Contract.GcpTenantEntityModel;

    const getGcpComputeInstancesRiskContext = useGetGcpComputeInstancesRiskContext();
    const getGcpComputeInstanceStartupScriptSecretExistsRiskContext = useGetGcpComputeInstanceStartupScriptSecretExistsRiskContext();
    const getGcpTenantEntityRiskContext = useGetGcpTenantEntityRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.useGcpComputeInstanceStartupScriptSecretExistsRiskDefinition.hooks.useGcpComputeInstanceStartupScriptSecretExistsRiskTenantEntityDefinition",
            () => ({
                description: "{{secrets}} exposed via a startup script defined on the {{tenantEntity}}",
                sections: {
                    resolution: {
                        step1: "Click on **Edit**",
                        step2: {
                            link: "Secrets Manager",
                            text: "Migrate your secrets to {{secretManagerDocumentationLink}}"
                        },
                        step3: "Edit the metadata entry **{{scriptName}}** to remove the exposed secrets and point to the newly created Secret Manager secret instead",
                        step4: "Click **Save** to confirm"
                    }
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            secrets: <InlineSecrets secrets={riskModel.risk.secrets}/>,
            tenantEntity:
                <Entity
                    entityIdOrModel={riskModel.risk.entityId}
                    entityTypeNameTranslatorOptions={{ includeServiceName: true }}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={riskModel.risk.entityId}
                key={riskModel.risk.entityId}
                page={Contract.GcpConsolePage.Metadata}/>,
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2.text({
                secretManagerDocumentationLink:
                    <Link
                        urlOrGetUrl={riskModel.secretManagerDocumentationUrl}
                        variant="external">
                        {localization.sections.resolution.step2.link()}
                    </Link>
            }),
            ...(
                _.map(
                    riskModel.risk.startupScriptNameToSecretExistsTextBlockMap,
                    (_textBlock, startupScriptName) => localization.sections.resolution.step3({ scriptName: startupScriptName }))
            ),
            localization.sections.resolution.step4()
        ],
        riskModel,
        () => {
            const computeInstanceStartupScriptSecretExistsRiskContextItems = getGcpComputeInstanceStartupScriptSecretExistsRiskContext(risk);
            const instancesContextItems = getGcpComputeInstancesRiskContext(risk.aggregatedEntityIds);
            const tenantEntityContextItems = getGcpTenantEntityRiskContext(tenantEntityModel);
            return [
                tenantEntityContextItems.generalInformation,
                tenantEntityContextItems.sensitive,
                instancesContextItems.sensitive,
                instancesContextItems.runningInstances,
                computeInstanceStartupScriptSecretExistsRiskContextItems.secrets,
                computeInstanceStartupScriptSecretExistsRiskContextItems.excludedSecrets,
                computeInstanceStartupScriptSecretExistsRiskContextItems.permissionActionPrincipals,
                tenantEntityContextItems.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        },
        {
            sections: [
                ...(_.map(
                    riskModel.risk.startupScriptNameToSecretExistsTextBlockMap,
                    (textBlock, startupScriptName) =>
                        new RiskDefinitionSection(
                            <TextViewer
                                format="json"
                                highlightedLines={textBlock.highlightedLines}
                                startLine={textBlock.startLine}
                                text={textBlock.text}/>,
                            startupScriptName)))
            ]
        });
}