import { ApiError, InfoIcon, InlineCopyToClipboardText, Link, StringHelper, Tooltip, useInputValidation, useLocalization, useOrderedWizardContext } from "@infrastructure";
import { Box, FormControl, FormHelperText, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useAddOrEditContext, useSetAddOrEditContext } from "..";
import { AwsResourceArnParser, ConfigurationController, Contract, CustomerConsoleAppUrlHelper, RadioGroup, ScopeHelper, scopeSystemEntityModelStore, UrlHelper, useScopeNavigationViewContext, useTheme } from "../../../../../../../../../../../../../../../common";

export function QueueInfoItem() {
    const { scopeNodeModel } = useScopeNavigationViewContext();
    const queueModels =
        ScopeHelper.getParentScopeSystemEntityModelsUnion(
            [scopeNodeModel.configuration.id],
            scopeSystemEntityModelStore.useGetSqs());
    const setAddOrEditContext = useSetAddOrEditContext();
    const { queueModel, queueName, queueRoleArn, queueUrl } = useAddOrEditContext();
    const [name, setName] = useState(queueName);
    const [roleArn, setRoleArn] = useState(queueRoleArn);
    const [url, setUrl] = useState(queueUrl);

    const { executing, setLoaded, setValid, useNextEffect } = useOrderedWizardContext();
    useEffect(
        () => {
            setLoaded();
        },
        []);

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useObservabilityItems.sqs.addOrEdit.queueInfoItem",
            () => ({
                actions: {
                    save: {
                        error: {
                            add: "Failed to add",
                            edit: "Failed to save",
                            [Contract.TypeNames.ConfigurationControllerUpsertSqsQueueError]: {
                                [Contract.ConfigurationControllerUpsertSqsQueueError.AssumeRoleFailed]: "Failed to assume role",
                                [Contract.ConfigurationControllerUpsertSqsQueueError.InvalidUrl]: "Invalid URL",
                                [Contract.ConfigurationControllerUpsertSqsQueueError.MissingQueuePermissions]: "The role has no SQS:SendMessage permission for the queue",
                                [Contract.ConfigurationControllerUpsertSqsQueueError.MissingRole]: "No role was found for the queue's account. Please create one manually and enter the ARN."
                            }
                        }
                    }
                },
                fields: {
                    name: {
                        error: {
                            exists: "Name already exists",
                            required: "Name cannot be empty"
                        },
                        title: "Name"
                    },
                    roleArn: {
                        actionRequired: {
                            documentation: "documentation",
                            text: "ACTION REQUIRED: You need to add specific permissions for the role. For more information, and detailed instructions, see the {{documentationLink}}."
                        },
                        custom: "Use custom IAM role",
                        default: "Use default IAM role",
                        error: {
                            invalid: "Invalid role ARN",
                            required: "Role ARN cannot be empty"
                        },
                        permissions: {
                            subtitle: "Tenable uses an IAM role to push messages to your SQS queue. Use the same role used to onboard your AWS environment to Tenable, or create a custom role (for example, if your queue is in an account that hasn't been onboarded).",
                            title: "Permissions"
                        },
                        roleExternalId: "External ID: {{roleExternalId}}",
                        title: "IAM role ARN"
                    },
                    url: {
                        error: {
                            exists: "Queue URL already exists",
                            invalidFormat: "Invalid URL",
                            required: "Queue URL cannot be empty"
                        },
                        title: "URL",
                        tooltip: "Available in **AWS Console** -> **Queue details**. Valid format - https://sqs.<region>.amazonaws.com/<account id>/<queue name>"
                    }
                },
                info: {
                    documentation: "documentation",
                    text: "Use the {{documentationLink}} to learn how to connect your SQS queue."
                }
            }));

    const [nameValidationController, nameValidationMessage] =
        useInputValidation(
            () => {
                const validationName =
                    name.
                        trim().
                        toLowerCase();
                if (_.isEmpty(validationName)) {
                    return localization.fields.name.error.required();
                }

                if (_.some(
                    queueModels,
                    otherQueueModel =>
                        otherQueueModel.configuration.id !== queueModel?.configuration.id &&
                        otherQueueModel.configuration.scopeId === scopeNodeModel.configuration.id &&
                        StringHelper.compare((otherQueueModel.configuration as Contract.SqsQueueConfiguration).name, validationName))) {
                    return localization.fields.name.error.exists();
                }

                return undefined;
            },
            [name]);

    const [roleArnValidationController, roleArnValidationMessage] =
        useInputValidation(
            () => {
                if (_.isNil(roleArn)) {
                    return undefined;
                }

                const validationRoleArn = roleArn.trim();
                if (_.isEmpty(validationRoleArn)) {
                    return localization.fields.roleArn.error.required();
                }
                if (!AwsResourceArnParser.validateIamRole(validationRoleArn)) {
                    return localization.fields.roleArn.error.invalid();
                }

                return undefined;
            },
            [roleArn]);

    const [urlValidationController, urlValidationMessage] =
        useInputValidation(
            () => {
                const validationUrl = url.trim();
                if (_.isEmpty(validationUrl)) {
                    return localization.fields.url.error.required();
                }

                const urlData = UrlHelper.getUrlData(validationUrl);
                if (_.isNil(urlData)) {
                    return localization.fields.url.error.invalidFormat();
                }

                if (_.some(
                    queueModels,
                    otherQueueModel =>
                        otherQueueModel.configuration.id !== queueModel?.configuration.id &&
                        otherQueueModel.configuration.scopeId === scopeNodeModel.configuration.id &&
                        StringHelper.compare((otherQueueModel.configuration as Contract.SqsQueueConfiguration).url, validationUrl))) {
                    return localization.fields.url.error.exists();
                }

                return undefined;
            },
            [url]);

    useEffect(
        () => {
            setAddOrEditContext(
                addOrEditContext => ({
                    ...addOrEditContext,
                    queueName: name.trim(),
                    queueRoleArn: roleArn?.trim(),
                    queueUrl: url.trim()
                }));

            setValid(
                nameValidationController.isValid() &&
                roleArnValidationController.isValid() &&
                urlValidationController.isValid());
        },
        [name, roleArn, url]);

    useNextEffect(
        async () => {
            try {
                const { scopeSystemEntityModel } =
                    await ConfigurationController.upsertSqsQueue(
                        new Contract.ConfigurationControllerUpsertSqsQueueRequest(
                            queueModel?.configuration.id,
                            name,
                            roleArn,
                            scopeNodeModel.configuration.id,
                            url));

                await scopeSystemEntityModelStore.notify(scopeSystemEntityModel);
            } catch (error) {
                return error instanceof ApiError && error.statusCode === 400
                    ? localization.actions.save.error[Contract.TypeNames.ConfigurationControllerUpsertSqsQueueError][error.error as Contract.ConfigurationControllerUpsertSqsQueueError]()
                    : _.isNil(queueModel)
                        ? localization.actions.save.error.add()
                        : localization.actions.save.error.edit();
            }
        },
        [name, roleArn, url]);

    const theme = useTheme();
    return (
        <Stack
            spacing={4}
            sx={{ maxWidth: theme.spacing(65) }}>
            <Typography sx={{ whiteSpace: "pre-wrap" }}>
                {localization.info.text({
                    documentationLink:
                        <Link
                            urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsRelativeUrl()}
                            variant="external">
                            {localization.info.documentation()}
                        </Link>
                })}
            </Typography>
            <Box>
                <FormControl
                    fullWidth={true}
                    variant="standard">
                    <TextField
                        disabled={executing}
                        label={localization.fields.name.title()}
                        value={name}
                        variant="outlined"
                        onChange={event => setName(event.target.value)}/>
                    {!_.isNil(nameValidationMessage) && (
                        <FormHelperText error={true}>{nameValidationMessage}</FormHelperText>)}
                </FormControl>
            </Box>
            <Box>
                <FormControl
                    fullWidth={true}
                    variant="standard">
                    <TextField
                        disabled={executing}
                        label={localization.fields.url.title()}
                        slotProps={{
                            input: {
                                endAdornment:
                                    <InputAdornment position="end">
                                        <Tooltip titleOrGetTitle={localization.fields.url.tooltip()}>
                                            <InfoIcon
                                                sx={{
                                                    color: theme.palette.text.secondary,
                                                    fontSize: "24px"
                                                }}/>
                                        </Tooltip>
                                    </InputAdornment>
                            }
                        }}
                        value={url}
                        variant="outlined"
                        onChange={event => setUrl(event.target.value)}/>
                    {!_.isNil(urlValidationMessage) && (
                        <FormHelperText error={true}>{urlValidationMessage}</FormHelperText>)}
                </FormControl>
            </Box>
            <Stack spacing={2}>
                <Box>
                    <Typography variant="h4">
                        {localization.fields.roleArn.permissions.title()}
                    </Typography>
                    <Typography variant="subtitle1">
                        {localization.fields.roleArn.permissions.subtitle()}
                    </Typography>
                </Box>
                <Typography
                    sx={{
                        backgroundColor: theme.palette.background.alternate,
                        borderRadius: theme.spacing(0.75),
                        padding: theme.spacing(1),
                        whiteSpace: "pre-wrap"
                    }}>
                    {localization.fields.roleArn.actionRequired.text({
                        documentationLink:
                            <Link
                                urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsRelativeUrl()}
                                variant="external">
                                {localization.fields.roleArn.actionRequired.documentation()}
                            </Link>
                    })}
                </Typography>
                <RadioGroup
                    items={[
                        {
                            label: localization.fields.roleArn.default(),
                            value: true
                        },
                        {
                            label: localization.fields.roleArn.custom(),
                            value: false
                        }
                    ]}
                    selectedValue={_.isNil(roleArn)}
                    sx={{ marginBottom: theme.spacing(1) }}
                    onChange={
                        _roleSelection =>
                            setRoleArn(
                                _.isNil(roleArn)
                                    ? ""
                                    : undefined)}/>
                {!_.isNil(roleArn) &&
                    <Stack spacing={2}>
                        <Box>
                            {localization.fields.roleArn.roleExternalId({
                                roleExternalId: <InlineCopyToClipboardText text={ScopeHelper.customerId}/>
                            })}
                        </Box>
                        <FormControl
                            fullWidth={true}
                            variant="standard">
                            <TextField
                                disabled={executing}
                                label={localization.fields.roleArn.title()}
                                value={roleArn}
                                variant="outlined"
                                onChange={event => setRoleArn(event.target.value)}/>
                            {!_.isNil(roleArnValidationMessage) && (
                                <FormHelperText error={true}>{roleArnValidationMessage}</FormHelperText>)}
                        </FormControl>
                    </Stack>}
            </Stack>
        </Stack>);
}