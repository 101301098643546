import { DataTableColumn, DataTableColumnRenderProps, InlineTexts, optionalTableCell, PagedValuesFilter, UnexpectedError } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, EntityPropertyHelper, PagedEntityFilter } from "../../../../../../../common";

export class TableHelper {
    public static getEntityPropertyColumnElements(entityTypeEntitiesViewName: string, propertyDefinitionIdentifiers: Contract.EntityPropertyIdentifier[]): JSX.Element[] {
        return _.map(
            propertyDefinitionIdentifiers,
            propertyDefinitionIdentifier => {
                switch (propertyDefinitionIdentifier.valueType) {
                    case Contract.EntityPropertyValueType.PrincipalReference:
                        return (
                            <DataTableColumn
                                exportOptions={{
                                    getItem:
                                        (item: Contract.EntityModel) => {
                                            const entityProperty =
                                                _.find(
                                                    item.properties,
                                                    entityProperty => entityProperty.identifier.raw === propertyDefinitionIdentifier.raw);
                                            return ({
                                                [propertyDefinitionIdentifier.name]:
                                                    _.isNil(entityProperty)
                                                        ? ""
                                                        : entityProperty.valueDisplayNames.join("\n")
                                            });
                                        }
                                }}
                                filterOptions={{
                                    itemOrItems: {
                                        element:
                                            <PagedEntityFilter
                                                getEntityIdPage={
                                                    ElasticsearchItemPageHelper.makePagedEntityPropertyFilterEntityItem(
                                                        entityTypeEntitiesViewName,
                                                        propertyDefinitionIdentifier)}
                                                placeholder={propertyDefinitionIdentifier.name}/>
                                    }
                                }}
                                id={propertyDefinitionIdentifier.raw}
                                key={propertyDefinitionIdentifier.raw}
                                render={
                                    ({ item }: DataTableColumnRenderProps<Contract.EntityModel>) => {
                                        const entityProperty =
                                            _.find(
                                                item.properties,
                                                entityProperty => entityProperty.identifier.raw === propertyDefinitionIdentifier.raw);
                                        return (
                                            <EntitiesCell
                                                entityIdsOrModels={
                                                    _.isNil(entityProperty)
                                                        ? undefined
                                                        : _.map(
                                                            EntityPropertyHelper.getOrderedValues(entityProperty) as Contract.EntityPropertyPrincipalReferenceValue[],
                                                            principalReferenceValue => principalReferenceValue.principalSearchableIdReference.idReference)}
                                                entityTypeName={Contract.TypeNames.PartialPrincipal}
                                                entityVariant="iconText"/>);
                                    }}
                                selectorOptions={{ default: false }}
                                sortOptions={{ enabled: false }}
                                title={propertyDefinitionIdentifier.name}/>);
                    case Contract.EntityPropertyValueType.String:
                        return (
                            <DataTableColumn
                                exportOptions={{
                                    getItem:
                                        (item: Contract.EntityModel) => {
                                            const entityProperty =
                                                _.find(
                                                    item.properties,
                                                    entityProperty => entityProperty.identifier.raw === propertyDefinitionIdentifier.raw);
                                            return ({
                                                [propertyDefinitionIdentifier.name]:
                                                    _.isNil(entityProperty)
                                                        ? ""
                                                        : entityProperty.valueDisplayNames.join("\n")
                                            });
                                        }
                                }}
                                filterOptions={{
                                    itemOrItems: {
                                        element:
                                            <PagedValuesFilter
                                                getValuePage={
                                                    ElasticsearchItemPageHelper.makePagedEntityPropertyFilterValueItem(
                                                        entityTypeEntitiesViewName,
                                                        propertyDefinitionIdentifier)}
                                                placeholder={propertyDefinitionIdentifier.name}/>
                                    }
                                }}
                                id={propertyDefinitionIdentifier.raw}
                                key={propertyDefinitionIdentifier.raw}
                                render={
                                    optionalTableCell<Contract.EntityModel>(
                                        (item: Contract.EntityModel) => {
                                            const entityProperty =
                                                _.find(
                                                    item.properties,
                                                    entityProperty => entityProperty.identifier.raw === propertyDefinitionIdentifier.raw);
                                            return _.isEmpty(entityProperty)
                                                ? undefined
                                                : <InlineTexts
                                                    texts={
                                                        _.map(
                                                            EntityPropertyHelper.getOrderedValues(entityProperty) as Contract.EntityPropertyStringValue[],
                                                            value => value.string)}
                                                    variant="itemPlusItemCount"/>;
                                        })}
                                selectorOptions={
                                    propertyDefinitionIdentifier.type !== Contract.EntityPropertyType.Custom
                                        ? { default: false }
                                        : undefined}
                                sortOptions={{ enabled: false }}
                                title={propertyDefinitionIdentifier.name}/>);
                    default:
                        throw new UnexpectedError("propertyDefinitionIdentifier.valueType", propertyDefinitionIdentifier.valueType);
                }
            });
    }
}