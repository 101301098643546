﻿import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";
import { useTheme } from "../../../themes";

export function AfricaIcon(props: SvgIconProps) {
    const theme = useTheme();
    return (
        <SvgIcon {...props}>
            <path
                d="M6.59185 16.5H5L7.89828 7.5H9.73936L12.6417 16.5H11.0498L8.85098 9.3457H8.78666L6.59185 16.5ZM6.6441 12.9712H10.9855V14.2808H6.6441V12.9712Z"
                fill={theme.palette.text.primary}/>
            <path
                d="M13.726 16.5V7.5H19V8.8667H15.2174V11.3101H18.6382V12.6768H15.2174V16.5H13.726Z"
                fill={theme.palette.text.primary}/>
            <path
                clipRule="evenodd"
                d="M22 5.00227H2C1.44772 5.00227 1 5.451 1 6.00453V17.9955C1 18.549 1.44772 18.9977 2 18.9977H22C22.5523 18.9977 23 18.549 23 17.9955V6.00454C23 5.451 22.5523 5.00227 22 5.00227ZM2 4H22C23.1046 4 24 4.89746 24 6.00454V17.9955C24 19.1025 23.1046 20 22 20H2C0.89543 20 0 19.1025 0 17.9955V6.00453C0 4.89746 0.895431 4 2 4Z"
                fill={theme.palette.text.secondary}
                fillRule="evenodd"/>
        </SvgIcon>);
}