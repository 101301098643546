import { DataTableColumn, DataTableColumnProps, DataTableColumnRenderProps, DataTableSortType, Loading, NoneIcon, StringHelper, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import _ from "lodash";
import React, { ReactNode, useMemo } from "react";
import { BuiltInRiskPolicyItem, ComplianceFilter, Compliances, Contract, CustomRiskPolicyItem, GetItemValueDefinition, RiskPoliciesType, RiskPoliciesView, RiskPolicyCategory, RiskPolicyItem, Severity, SeverityFilter, TypeHelper, useRiskPolicyCategoryTranslator, useRiskPolicyTranslator, useTenantTypeTranslator, useTheme } from "../../../../../../../common";
import { useGetRiskPolicyItemBuiltInComplianceTypes } from "../../../../Compliance/hooks";
import { ExcludedEntitiesCell, TenantTypeCell } from "../../../components";
import { useRiskPoliciesTypeData } from "../../useRiskPoliciesTypeData";

type RiskPolicyItemTableItemValueMap = {
    [columnId: string]: GetItemValueDefinition<RiskPolicyItem>;
};

type RiskPolicyItemTableItems = {
    [column in CommonTableColumnId]: {
        getColumn: (item?: any, props?: Partial<DataTableColumnProps>) => ReactNode;
        getValueMap: () => RiskPolicyItemTableItemValueMap;
    }
};

export function useCommonRiskPolicyItemTableItems(scopeId: string, policyType: RiskPoliciesType, view = RiskPoliciesView.BuiltIn): RiskPolicyItemTableItems {
    const getRiskPolicyItemBuiltInComplianceTypes = useGetRiskPolicyItemBuiltInComplianceTypes();
    const { tenantTypes } = useRiskPoliciesTypeData(policyType);

    const riskPolicyCategoryTranslator = useRiskPolicyCategoryTranslator();
    const riskPolicyTranslator = useRiskPolicyTranslator();
    const tenantTypeTranslator = useTenantTypeTranslator();

    const localization =
        useLocalization(
            "views.customer.riskPolicies.hooks.useDefinition.hooks.useCommonRiskPolicyItemTableItems",
            () => ({
                columns: {
                    category: "Category",
                    compliance: "Compliance",
                    exclusions: {
                        entities: "{{count | NumberFormatter.humanize}} Excluded {{translatedEntityTypeName}}",
                        patterns: "{{count | NumberFormatter.humanize}} Excluded {{translatedEntityTypeName}} by pattern",
                        title: "Excluded Resources"
                    },
                    name: "Name",
                    platform: "Platform",
                    severity: {
                        empty: "Dynamic",
                        title: "Severity"
                    },
                    status: {
                        false: "Disabled",
                        inheritedFalse: "Disabled (Inherited)",
                        inheritedTrue: "Enabled (Inherited)",
                        title: "Status",
                        true: "Enabled"
                    }
                }
            }));

    const theme = useTheme();
    return useMemo(
        () => ({
            [CommonTableColumnId.Category]: {
                getColumn:
                    (riskPolicyCategories: Contract.RiskPolicyCategory[]) =>
                        <DataTableColumn
                            filterOptions={{
                                itemOrItems: {
                                    default: true,
                                    element:
                                        <ValuesFilter placeholder={localization.columns.category()}>
                                            {_.map(
                                                riskPolicyCategories,
                                                riskPolicyCategory =>
                                                    <ValuesFilterItem
                                                        key={riskPolicyCategory}
                                                        title={riskPolicyCategoryTranslator(riskPolicyCategory)}
                                                        value={riskPolicyCategory}/>)}
                                        </ValuesFilter>
                                }
                            }}
                            id={CommonTableColumnId.Category}
                            itemProperty={(item: RiskPolicyItem) => riskPolicyCategoryTranslator(item.riskPolicyTypeMetadata.category)}
                            key={CommonTableColumnId.Category}
                            render={
                                ({ item }: DataTableColumnRenderProps<RiskPolicyItem>) =>
                                    <RiskPolicyCategory
                                        riskPolicyCategory={item.riskPolicyTypeMetadata.category}
                                        variant="riskPolicy"/>}
                            selectorOptions={{ disabled: true }}
                            title={localization.columns.category()}/>,
                getValueMap:
                    () => ({
                        [CommonTableColumnId.Category]: {
                            getFilterValue: item => item.riskPolicyTypeMetadata.category,
                            getSortValue: item => riskPolicyCategoryTranslator(item.riskPolicyTypeMetadata.category)
                        }
                    })
            },
            [CommonTableColumnId.Compliances]: {
                getColumn:
                    (complianceIdentifiers: string[]) =>
                        <DataTableColumn
                            filterOptions={{
                                itemOrItems: {
                                    element:
                                        <ComplianceFilter
                                            complianceIdentifiers={complianceIdentifiers}
                                            placeholder={localization.columns.compliance()}/>
                                }
                            }}
                            id={CommonTableColumnId.Compliances}
                            key={CommonTableColumnId.Compliances}
                            render={
                                ({ item }: DataTableColumnRenderProps<BuiltInRiskPolicyItem>) => {
                                    const builtInComplianceTypes = getRiskPolicyItemBuiltInComplianceTypes(item);
                                    return _.some(builtInComplianceTypes) || _.some(item.customComplianceIds)
                                        ? <Loading container="cell">
                                            <Compliances
                                                builtInComplianceTypes={builtInComplianceTypes}
                                                customComplianceIds={item.customComplianceIds}/>
                                        </Loading>
                                        : <NoneIcon/>;
                                }}
                            selectorOptions={{ default: false }}
                            sortOptions={{ type: DataTableSortType.Numeric }}
                            title={localization.columns.compliance()}/>,
                getValueMap:
                    () => ({
                        [CommonTableColumnId.Compliances]: {
                            getFilterValue:
                                item =>
                                    _(getRiskPolicyItemBuiltInComplianceTypes(item)).
                                        map(complianceType => complianceType.toString()).
                                        concat(item.customComplianceIds).
                                        value(),
                            getSortValue: item => _.size(getRiskPolicyItemBuiltInComplianceTypes(item)) + _.size(item.customComplianceIds)
                        }
                    })
            },
            [CommonTableColumnId.ExcludedEntityIds]: {
                getColumn:
                    (_, props) =>
                        <DataTableColumn
                            id={CommonTableColumnId.ExcludedEntityIds}
                            key={CommonTableColumnId.ExcludedEntityIds}
                            render={
                                ({ item }: DataTableColumnRenderProps<BuiltInRiskPolicyItem>) =>
                                    item.enabled
                                        ? <Loading container="cell">
                                            <ExcludedEntitiesCell
                                                item={item}
                                                scopeId={scopeId}/>
                                        </Loading>
                                        : <NoneIcon/>}
                            sortOptions={{ enabled: false }}
                            title={localization.columns.exclusions.title()}
                            {...props}/>,
                getValueMap:
                    () => ({
                        [CommonTableColumnId.ExcludedEntityIds]: item => item
                    })
            },
            [CommonTableColumnId.Name]: {
                getColumn:
                    (riskPolicyConfigurationTypeNameOrPolicyIds: string[]) =>
                        <DataTableColumn
                            cellSx={{ minWidth: theme.spacing(44) }}
                            filterOptions={{
                                itemOrItems: {
                                    default: true,
                                    element:
                                        <ValuesFilter
                                            groupItemTitle={true}
                                            placeholder={localization.columns.name()}>
                                            {_.map(
                                                riskPolicyConfigurationTypeNameOrPolicyIds,
                                                riskPolicyName =>
                                                    <ValuesFilterItem
                                                        key={riskPolicyName}
                                                        title={riskPolicyName}
                                                        value={riskPolicyName}/>)}
                                        </ValuesFilter>
                                }
                            }}
                            id={CommonTableColumnId.Name}
                            itemProperty={
                                item =>
                                    view === RiskPoliciesView.Custom
                                        ? (item as CustomRiskPolicyItem).riskPolicyConfiguration.name
                                        : riskPolicyTranslator(item.riskPolicyConfigurationTypeName).title}
                            key={CommonTableColumnId.Name}
                            selectorOptions={{ disabled: true }}
                            title={localization.columns.name()}/>,
                getValueMap:
                    () => ({
                        [CommonTableColumnId.Name]:
                            item =>
                                view === RiskPoliciesView.Custom
                                    ? (item as CustomRiskPolicyItem).riskPolicyConfiguration?.name
                                    : riskPolicyTranslator(item.riskPolicyConfigurationTypeName)?.title
                    })
            },
            [CommonTableColumnId.TenantsType]: {
                getColumn:
                    (filterTenantTypes: Contract.TenantType[]) =>
                        <DataTableColumn
                            filterOptions={{
                                itemOrItems: {
                                    default: true,
                                    element:
                                        <ValuesFilter placeholder={localization.columns.platform()}>
                                            {_.map(
                                                _.intersection(tenantTypes, filterTenantTypes),
                                                tenantType =>
                                                    <ValuesFilterItem
                                                        key={tenantType}
                                                        title={tenantTypeTranslator(tenantType)}
                                                        value={tenantType}/>)}
                                        </ValuesFilter>
                                }
                            }}
                            id={CommonTableColumnId.TenantsType}
                            key={CommonTableColumnId.TenantsType}
                            render={
                                ({ item }: DataTableColumnRenderProps<BuiltInRiskPolicyItem>) =>
                                    <TenantTypeCell tenantTypes={_.intersection(tenantTypes, item.tenantTypes)}/>}
                            title={localization.columns.platform()}/>,
                getValueMap:
                    () => ({
                        [CommonTableColumnId.TenantsType]: {
                            getFilterValue: item => item.tenantTypes,
                            getSortValue:
                                item =>
                                    _.includes(
                                        item.tenantTypes,
                                        Contract.TenantType.Code)
                                        ? StringHelper.getCombineSortValue(
                                            item.tenantTypes.length,
                                            "z".repeat(15),
                                            ...item.tenantTypes)
                                        : StringHelper.getCombineSortValue(
                                            item.tenantTypes.length,
                                            ...item.tenantTypes)
                        }
                    })
            },
            [CommonTableColumnId.Severity]: {
                getColumn:
                    () =>
                        <DataTableColumn
                            filterOptions={{
                                itemOrItems: {
                                    element:
                                        <SeverityFilter
                                            emptyValueOptions={{
                                                enabled: true,
                                                title: localization.columns.severity.empty()
                                            }}
                                            placeholder={localization.columns.severity.title()}/>
                                }
                            }}
                            id={CommonTableColumnId.Severity}
                            key={CommonTableColumnId.Severity}
                            render={
                                ({ item }: DataTableColumnRenderProps<RiskPolicyItem>) =>
                                    item.custom
                                        ? <Severity
                                            severity={(item as CustomRiskPolicyItem).riskPolicyConfiguration.severity}/>
                                        : <Severity
                                            dynamicRiskPolicyConfigurationTypeName={item.riskPolicyConfigurationTypeName}
                                            severity={item.riskPolicyTypeMetadata.severity}/>}
                            selectorOptions={{ default: false }}
                            sortOptions={{ type: DataTableSortType.Numeric }}
                            title={localization.columns.severity.title()}/>,
                getValueMap:
                    () => ({
                        [CommonTableColumnId.Severity]: {
                            getFilterValue: item =>
                                item.custom
                                    ? (item as CustomRiskPolicyItem).riskPolicyConfiguration.severity
                                    : item.riskPolicyTypeMetadata.severity,
                            getSortValue:
                                item =>
                                    item.custom
                                        ? TypeHelper.getEnumValue(Contract.TypeNames.Severity, (item as CustomRiskPolicyItem).riskPolicyConfiguration.severity)
                                        : _.isNil(item.riskPolicyTypeMetadata.severity)
                                            ? -1
                                            : TypeHelper.getEnumValue(Contract.TypeNames.Severity, item.riskPolicyTypeMetadata.severity)
                        }
                    })
            },
            [CommonTableColumnId.Status]: {
                getColumn:
                    () =>
                        <DataTableColumn
                            filterOptions={{
                                itemOrItems: {
                                    default: true,
                                    element:
                                        <ValuesFilter placeholder={localization.columns.status.title()}>
                                            <ValuesFilterItem
                                                title={localization.columns.status.true()}
                                                value={localization.columns.status.true()}/>
                                            <ValuesFilterItem
                                                title={localization.columns.status.false()}
                                                value={localization.columns.status.false()}/>
                                        </ValuesFilter>
                                }
                            }}
                            id={CommonTableColumnId.Status}
                            itemProperty={
                                (item: BuiltInRiskPolicyItem) =>
                                    item.enabled
                                        ? item.enabledInherited
                                            ? localization.columns.status.inheritedTrue()
                                            : localization.columns.status.true()
                                        : item.enabledInherited
                                            ? localization.columns.status.inheritedFalse()
                                            : localization.columns.status.false()}
                            key={CommonTableColumnId.Status}
                            sortOptions={{ enabled: false }}
                            title={localization.columns.status.title()}/>,
                getValueMap:
                    () => ({
                        [CommonTableColumnId.Status]:
                            item =>
                                item.enabled
                                    ? localization.columns.status.true()
                                    : localization.columns.status.false()
                    })
            }
        }),
        [scopeId, tenantTypes, view]);
}

export enum CommonTableColumnId {
    Category = "category",
    Compliances = "compliances",
    ExcludedEntityIds = "excludedEntityIds",
    Name = "name",
    Severity = "severity",
    Status = "status",
    TenantsType = "tenantsType"
}