import { Stack, Tab, Tabs } from "@mui/material";
import _ from "lodash";
import React, { useEffect } from "react";
import { Loading, map, useLocalization, VerticalFillGrid } from "@infrastructure";
import { Contract, FeatureHelper, LicensingHelper, ScopeHelper, useScopeNavigationViewContext, useTheme } from "../../../../../../common";
import { ConfigurationView } from "../../utilities";
import { ContainerImageRepositories, EndOfLife, MaliciousFiles, VirtualMachineImages, VirtualMachines, Vulnerabilities } from "./components";
import { WorkloadAnalysisView } from "./WorkloadAnalysisView";

export function WorkloadAnalysis() {
    const { scopeNodeModel, useViewRoute } = useScopeNavigationViewContext();

    const scopeTenantType = ScopeHelper.getTenantType(scopeNodeModel);

    const workloadAnalysisViews =
        LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.Cnapp)
            ? _<WorkloadAnalysisView>([]).
                concatIf(
                    !_.includes(
                        [
                            Contract.TenantType.Ci,
                            Contract.TenantType.Op
                        ],
                        scopeTenantType),
                    WorkloadAnalysisView.VirtualMachines).
                concatIf(
                    FeatureHelper.enabled(Contract.FeatureName.AwsEc2MachineImageAnalysisEnabled) &&
                    (scopeNodeModel.type === Contract.ScopeType.Customer ||
                        scopeTenantType === Contract.TenantType.Aws),
                    WorkloadAnalysisView.VirtualMachineImages).
                concatIf(
                    scopeTenantType != Contract.TenantType.Op,
                    WorkloadAnalysisView.ContainerImageRepositories).
                concat(WorkloadAnalysisView.Vulnerabilities).
                concatIf(
                    scopeNodeModel.configuration.id === ScopeHelper.customerId,
                    WorkloadAnalysisView.EndOfLife).
                concatIf(
                    scopeTenantType != Contract.TenantType.Op,
                    WorkloadAnalysisView.MaliciousFiles).
                value()
            : [WorkloadAnalysisView.Vulnerabilities];

    const [view, setView] = useViewRoute(`${ConfigurationView.WorkloadAnalysis}/{view}`, workloadAnalysisViews);
    useEffect(
        () => {
            if ((view == WorkloadAnalysisView.VirtualMachines ||
                    view == WorkloadAnalysisView.VirtualMachineImages) &&
                ScopeHelper.getTenantType(scopeNodeModel) == Contract.TenantType.Ci) {
                setView(workloadAnalysisViews[0] as WorkloadAnalysisView);
            }
        },
        [scopeNodeModel]);


    const localization =
        useLocalization(
            "views.customer.configuration.workloadAnalysis",
            () => ({
                tabs: {
                    workloadAnalysisView: {
                        [WorkloadAnalysisView.ContainerImageRepositories]: "Container Registry",
                        [WorkloadAnalysisView.EndOfLife]: "End-of-Life",
                        [WorkloadAnalysisView.MaliciousFiles]: "Malicious Files",
                        [WorkloadAnalysisView.VirtualMachines]: "Virtual Machine",
                        [WorkloadAnalysisView.VirtualMachineImages]: "Virtual Machine Image",
                        [WorkloadAnalysisView.Vulnerabilities]: "Vulnerabilities"
                    }
                }
            }));
    const theme = useTheme();
    return (
        <VerticalFillGrid>
            <Tabs
                sx={{
                    border: "unset",
                    padding: theme.spacing(0, 2)
                }}
                value={view}
                variant="scrollable"
                onChange={(_, view) => setView(view as WorkloadAnalysisView)}>
                {_.map(
                    workloadAnalysisViews,
                    workloadAnalysisView =>
                        <Tab
                            id={workloadAnalysisView}
                            key={workloadAnalysisView}
                            label={localization.tabs.workloadAnalysisView[workloadAnalysisView]()}
                            sx={{
                                marginRight: theme.spacing(3),
                                padding: 0
                            }}
                            value={workloadAnalysisView}/>)}
            </Tabs>
            <Stack
                sx={{
                    height: "100%",
                    width: "100%"
                }}>
                <Loading>
                    {map(
                        view,
                        {
                            [WorkloadAnalysisView.ContainerImageRepositories]: () => <ContainerImageRepositories/>,
                            [WorkloadAnalysisView.EndOfLife]: () => <EndOfLife/>,
                            [WorkloadAnalysisView.MaliciousFiles]: () => <MaliciousFiles/>,
                            [WorkloadAnalysisView.VirtualMachineImages]: () => <VirtualMachineImages/>,
                            [WorkloadAnalysisView.VirtualMachines]: () => <VirtualMachines/>,
                            [WorkloadAnalysisView.Vulnerabilities]: () => <Vulnerabilities/>
                        })}
                </Loading>
            </Stack>
        </VerticalFillGrid>);
}