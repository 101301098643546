﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, Entity, entityModelStore, InlineEntities } from "../../../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../../../tenants";
import { useCommonSectionsAndDescriptionDefinition } from "../../../../../useCommonSectionsAndDescriptionDefinition";
import { useGetAwsAutoScalingAutoScalingGroupsRiskContext, useGetAwsAutoScalingLaunchConfigurationRiskContext, useGetAwsEc2InstancesRiskContext } from "../../../contexts";
import { useGetAwsEc2InstanceMetadataServiceVersionRiskContext } from "./useGetAwsEc2InstanceMetadataServiceVersionRiskContext";
import { useGetResolutionSteps } from "./useGetResolutionSteps";

export function useAwsEc2InstanceMetadataServiceVersionRiskLaunchConfigurationDefinition(riskModel: Contract.AwsEc2InstanceMetadataServiceVersionRiskModel) {
    const launchConfigurationModel = entityModelStore.useGet(riskModel.risk.entityId) as Contract.AwsAutoScalingLaunchConfigurationModel;
    const launchConfiguration = (launchConfigurationModel.entity) as Contract.AwsAutoScalingLaunchConfiguration;

    const getAwsAutoScalingAutoScalingGroupsRiskContext = useGetAwsAutoScalingAutoScalingGroupsRiskContext();
    const getAwsAutoScalingLaunchConfigurationRiskContext = useGetAwsAutoScalingLaunchConfigurationRiskContext();
    const getAwsEc2InstanceMetadataServiceVersionRiskContext = useGetAwsEc2InstanceMetadataServiceVersionRiskContext();
    const getAwsEc2InstancesRiskContext = useGetAwsEc2InstancesRiskContext();
    const getResolutionSteps = useGetResolutionSteps();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsEc2InstanceMetadataServiceVersionRiskDefinition.hooks.useAwsEc2InstanceMetadataServiceVersionRiskLaunchConfigurationDefinition",
            () => ({
                description: "{{launchConfiguration}} is not enforcing Instance Metadata Service Version 2 (IMDSv2)",
                sections: {
                    resolution: {
                        step1: "Select launch configuration **{{launchConfigurationName}}** and choose **Actions**, **Copy launch configuration**",
                        step2: "In the **Additional Configuration** section, click on **Advanced Details**",
                        step3: "Under **Metadata accessible**, select **Enabled**",
                        step4: "Under **Metadata version**, select **V2 only (token required)**",
                        step5: "Click on the **Create launch configuration** button",
                        step6: "Update the **{{autoScalingGroups}}** to use the new launch configuration",
                        step7: "On the Launch Configuration page, select the old configuration and click **Actions, delete launch configuration**",
                        step8: "Wait until all instances are relaunched with the updated metadata version"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            launchConfiguration:
                <Entity
                    entityIdOrModel={launchConfigurationModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            ...getResolutionSteps(
                launchConfiguration.typeName,
                riskModel),
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.Ec2,
                AwsConsoleUrlBuilder.getAutoScalingGroupLaunchConfigurationUrl(launchConfiguration)),
            localization.sections.resolution.step1({ launchConfigurationName: launchConfiguration.displayName }),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4(),
            localization.sections.resolution.step5(),
            !_.isEmpty(riskModel.risk.autoScalingGroupIds)
                ? localization.sections.resolution.step6({
                    autoScalingGroups:
                        <InlineEntities
                            entityIdsOrModels={riskModel.risk.autoScalingGroupIds}
                            entityTypeName={Contract.TypeNames.AwsAutoScalingAutoScalingGroup}
                            variant="itemCountAndType"/>
                })
                : undefined,
            localization.sections.resolution.step7(),
            localization.sections.resolution.step8()
        ],
        riskModel,
        () => {
            const autoScalingGroupsContextItems = getAwsAutoScalingAutoScalingGroupsRiskContext(riskModel.risk.autoScalingGroupIds!);
            const instanceMetadataServiceVersionRiskContextItems = getAwsEc2InstanceMetadataServiceVersionRiskContext(riskModel);
            const instancesContextItems = getAwsEc2InstancesRiskContext(riskModel.risk.aggregatedEntityIds);
            const launchConfigurationContextItems = getAwsAutoScalingLaunchConfigurationRiskContext(launchConfigurationModel);
            return [
                launchConfigurationContextItems.generalInformation,
                launchConfigurationContextItems.sensitive,
                launchConfigurationContextItems.getAutoScalingGroupsContextItem(riskModel.risk.autoScalingGroupIds),
                autoScalingGroupsContextItems.sensitive,
                instanceMetadataServiceVersionRiskContextItems.instances,
                instanceMetadataServiceVersionRiskContextItems.permissionActions,
                instanceMetadataServiceVersionRiskContextItems.instancePublicAccess,
                instancesContextItems.usedInstances,
                instancesContextItems.runningInstances,
                instancesContextItems.sensitive,
                launchConfigurationContextItems.getOpenRiskedEntityRisksContextItem(riskModel.id)];
        });
}