import { useMemo } from "react";
import { useAzureDefaultKubernetesNamespaceResourceDefinition, useAzureDefaultKubernetesResourceDefinition, useAzureKubernetesClusterRoleBindingDefinition, useAzureKubernetesClusterRoleDefinition, useAzureKubernetesGroupDefinition, useAzureKubernetesIngressDefinition, useAzureKubernetesNamespaceDefinition, useAzureKubernetesNamespaceRoleBindingDefinition, useAzureKubernetesNamespaceRoleDefinition, useAzureKubernetesServiceAccountDefinition, useAzureKubernetesServiceDefinition, useAzureKubernetesUserDefinition, useAzureKubernetesWorkloadResourceDefinition } from ".";
import { Contract, TypeHelper } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";

export function useAzureKubernetesResourceDefinition(definitionData: DefinitionData) {
    const useDefinition =
        useMemo(
            () => {
                switch (definitionData.entityTypeEntitiesViewName) {
                    case Contract.TypeNames.AzureKubernetesClusterRole:
                        return useAzureKubernetesClusterRoleDefinition;
                    case Contract.TypeNames.AzureKubernetesClusterRoleBinding:
                        return useAzureKubernetesClusterRoleBindingDefinition;
                    case Contract.TypeNames.AzureKubernetesGroup:
                        return useAzureKubernetesGroupDefinition;
                    case Contract.TypeNames.AzureKubernetesIngress:
                        return useAzureKubernetesIngressDefinition;
                    case Contract.TypeNames.AzureKubernetesNamespace:
                        return useAzureKubernetesNamespaceDefinition;
                    case Contract.TypeNames.AzureKubernetesNamespaceRole:
                        return useAzureKubernetesNamespaceRoleDefinition;
                    case Contract.TypeNames.AzureKubernetesNamespaceRoleBinding:
                        return useAzureKubernetesNamespaceRoleBindingDefinition;
                    case Contract.TypeNames.AzureKubernetesService:
                        return useAzureKubernetesServiceDefinition;
                    case Contract.TypeNames.AzureKubernetesServiceAccount:
                        return useAzureKubernetesServiceAccountDefinition;
                    case Contract.TypeNames.AzureKubernetesUser:
                        return useAzureKubernetesUserDefinition;
                    case Contract.TypeNames.AzureKubernetesWorkloadResourceBase:
                        return useAzureKubernetesWorkloadResourceDefinition;
                    default:
                        return TypeHelper.extendOrImplement(definitionData.entityTypeEntitiesViewName, Contract.TypeNames.AzureKubernetesNamespaceResourceBase)
                            ? useAzureDefaultKubernetesNamespaceResourceDefinition
                            : useAzureDefaultKubernetesResourceDefinition;
                }
            },
            []);

    return useDefinition(definitionData);
}