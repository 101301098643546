import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useGetGcpIamServiceAccountRiskContext } from "../../..";
import { Contract, Entity, entityModelStore, InlineEntities, useEntityTypeNameTranslator, useTheme } from "../../../../../../../../../../../../common";
import { GcpIamServiceAccountKeysTable, GcpIamServiceAccountKeysTableColumnId } from "../../../../../../../../../../../../tenants";
import { RiskDefinitionContextItem, RiskDefinitionSection } from "../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { useGetRiskPolicyConfigurationRotationTimeFrameContextItem } from "../../../useGetRiskPolicyConfigurationRotationTimeFrameContextItem";
import { EntityExternalConsoleLink } from "../../components";

export function useGcpIamServiceAccountUnrotatedUserManagedKeyRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.GcpIamServiceAccountUnrotatedUserManagedKeyRisk;
    const serviceAccountModel = entityModelStore.useGet(risk.entityId) as Contract.GcpIamServiceAccountModel;
    const unrotatedActiveKeyModels = entityModelStore.useGet(risk.unrotatedActiveUserManagedKeyIds) as Contract.GcpIamServiceAccountKeyModel[];

    const getGcpIamServiceAccountRiskContext = useGetGcpIamServiceAccountRiskContext();
    const getRiskPolicyConfigurationRotationTimeFrameContextItem = useGetRiskPolicyConfigurationRotationTimeFrameContextItem();

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.useGcpIamServiceAccountUnrotatedUserManagedKeyRiskDefinition",
            () => ({
                contextItems: {
                    unrotatedActiveUserManagedKey: "{{translatedKeyTypeName}} {{keyName}} was not rotated for more than {{keyCreationTime | TimeFormatter.humanizeDuration}}"
                },
                description: {
                    text: [
                        "{{serviceAccount}} has {{unrotatedActiveKeys}} that was not rotated for more than {{keyRotationTimeFrame | TimeSpanFormatter.humanizeDays}}",
                        "{{serviceAccount}} has {{unrotatedActiveKeys}} that were not rotated for more than {{keyRotationTimeFrame | TimeSpanFormatter.humanizeDays}}"
                    ],
                    unrotatedActiveKeys: [
                        "1 user managed key",
                        "{{count | NumberFormatter.humanize}} user managed keys"
                    ]
                },
                sections: {
                    resolution: {
                        step1: "Click **ADD KEY** and **Create new key** button to create a new key",
                        step2: "Update existing applications to use the newly created key",
                        step3: "Click the tray icon to on the right side of the unrotated key to delete it"
                    },
                    userManagedKeyIds: "User Managed Keys"
                }
            }));
    const serviceAccountKeyIdToHighlightedMap =
        _((serviceAccountModel.entity as Contract.GcpIamServiceAccount).userManagedKeyIds).
            keyBy(userManagedKeyId => userManagedKeyId).
            mapValues(userManagedKeyId => _.includes(risk.unrotatedActiveUserManagedKeyIds, userManagedKeyId)).
            value();

    const theme = useTheme();
    return useCommonSectionsAndDescriptionDefinition(
        localization.description.text(
            risk.unrotatedActiveUserManagedKeyIds.length,
            {
                keyRotationTimeFrame: risk.keyRotationTimeFrame,
                serviceAccount:
                    <Entity
                        entityIdOrModel={serviceAccountModel}
                        entityTypeNameTranslatorOptions={{ variant: "title" }}
                        variant="typeText"/>,
                unrotatedActiveKeys:
                    <InlineEntities
                        entityIdsOrModels={unrotatedActiveKeyModels}
                        entityTypeName={Contract.TypeNames.GcpIamServiceAccountKey}
                        namePluralizer={localization.description.unrotatedActiveKeys}
                        variant="itemCountAndType"/>
            }),
        () => [
            <EntityExternalConsoleLink
                entityId={risk.entityId}
                key={risk.entityId}
                page={Contract.GcpConsolePage.Keys}/>,
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3()
        ],
        riskModel,
        () => {
            const serviceAccountRiskContext = getGcpIamServiceAccountRiskContext(serviceAccountModel);

            return [
                serviceAccountRiskContext.generalInformation,
                getRiskPolicyConfigurationRotationTimeFrameContextItem(risk.keyRotationTimeFrame),
                ..._.map(
                    unrotatedActiveKeyModels,
                    unrotatedActiveKeyModel =>
                        new RiskDefinitionContextItem(
                            localization.contextItems.unrotatedActiveUserManagedKey({
                                keyCreationTime: unrotatedActiveKeyModel.creationTime,
                                keyName: (unrotatedActiveKeyModel.entity as Contract.GcpIamServiceAccountKey).name,
                                translatedKeyTypeName:
                                    entityTypeNameTranslator(
                                        Contract.TypeNames.GcpIamServiceAccountKey,
                                        { variant: "title" })
                            }))),
                serviceAccountRiskContext.permissionActionSeverity,
                serviceAccountRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        },
        {
            sections: [
                new RiskDefinitionSection(
                    <GcpIamServiceAccountKeysTable
                        csvExportFilePrefixes={[localization.sections.userManagedKeyIds()]}
                        rowOptions={{
                            getHighlightColor:
                                (serviceAccountKey: Contract.GcpIamServiceAccountKey) =>
                                    serviceAccountKeyIdToHighlightedMap[serviceAccountKey.id]
                                        ? theme.palette.severity(riskModel.risk.severity)
                                        : undefined,
                            getSx: (serviceAccountKey: Contract.GcpIamServiceAccountKey) =>
                                serviceAccountKeyIdToHighlightedMap[serviceAccountKey.id]
                                    ? { backgroundColor: theme.palette.opacity(theme.palette.severity(riskModel.risk.severity), 0.1) }
                                    : undefined
                        }}
                        serviceAccountKeyIds={(serviceAccountModel.entity as Contract.GcpIamServiceAccount).userManagedKeyIds}
                        sortColumnId={GcpIamServiceAccountKeysTableColumnId.CreationTime}/>,
                    localization.sections.userManagedKeyIds())
            ]
        });
}