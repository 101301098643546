import { Message, useLocalization } from "@infrastructure";
import { CircularProgress, Stack, Switch, Typography } from "@mui/material";
import React, { useState } from "react";
import { Contract, EntityController, entityModelStore, UserHelper } from "../../../../../common";

type SensitiveToggleProps = {
    entityId: string;
    variant?: "minimal" | "normal";
};

export function SensitiveToggle({ entityId, variant = "normal" }: SensitiveToggleProps) {
    const entityModel = entityModelStore.useGet(entityId);
    const [toggleSensitivityExecuting, setToggleSensitivityExecuting] = useState(false);
    const [toggleSensitivityError, setToggleSensitivityError] = useState(false);

    async function toggleSensitivity() {
        setToggleSensitivityExecuting(true);
        setToggleSensitivityError(false);

        try {
            await EntityController.updateConfiguration(
                new Contract.EntityControllerUpdateConfigurationRequest(
                    entityModel.entity.id,
                    undefined,
                    !entityModel.entityConfiguration?.sensitive));

            await entityModelStore.notify(entityId);
        } catch {
            setToggleSensitivityError(true);
        }

        setToggleSensitivityExecuting(false);
    }

    const localization =
        useLocalization(
            "views.customer.entities.sensitiveToggle",
            () => ({
                error: "Failed to update sensitivity for **{{entityName}}**",
                title: "Sensitive"
            }));
    return (
        <Stack
            alignItems="center"
            direction="row"
            spacing={1}>
            {variant === "normal" &&
                <Typography>
                    {localization.title()}
                </Typography>}
            <Switch
                checked={entityModel.entityConfiguration?.sensitive ?? false}
                disabled={!UserHelper.hasAnyScopePermissions(entityModel.entity.scopeIds, Contract.IdentityPermission.SecurityWrite)}
                size="small"
                onClick={() => toggleSensitivity()}/>
            {(toggleSensitivityExecuting || toggleSensitivityError) && (
                toggleSensitivityExecuting
                    ? <CircularProgress size="18px"/>
                    : <Message
                        level="error"
                        title={localization.error({ entityName: entityModel.entity.displayName })}
                        variant={
                            variant === "minimal"
                                ? "minimal"
                                : "standard"}/>)}
        </Stack>);
}