import { InlineItems, InlineItemsVariant, useLocalization } from "@infrastructure";
import { SxProps } from "@mui/material";
import React from "react";
import { Contract, ResourceTag, ResourceTagData, ResourceTagHelper } from "..";

type InlineResourceTagsProps = {
    sx?: SxProps;
    tags: Contract.ResourceTag[];
    textVariant?: "text" | "title";
    variant?: InlineItemsVariant;
};

export function InlineResourceTags({ sx, tags, textVariant = "title", variant = "itemPlusItemCount" }: InlineResourceTagsProps) {
    const localization =
        useLocalization(
            "common.inlineResourceTags",
            () => ({
                tags: {
                    text: [
                        "1 tag",
                        "{{count | NumberFormatter.humanize}} tags"
                    ],
                    title: [
                        "1 Tag",
                        "{{count | NumberFormatter.humanize}} Tags"
                    ]
                }
            }));
    return (
        <InlineItems
            items={ResourceTagHelper.getDatas(tags)}
            namePluralizer={count => localization.tags[textVariant](count)}
            sx={sx}
            variant={variant}>
            {(resourceTagData: ResourceTagData, inline) =>
                <ResourceTag
                    key={resourceTagData.id}
                    resourceTag={resourceTagData}
                    variant={
                        inline && variant !== "itemPlusItemCount"
                            ? "text"
                            : "item"}/>}
        </InlineItems>);
}