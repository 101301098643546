import { useMemo } from "react";
import { Contract, RiskController, riskModelStore } from "../../../../../../../common";
import { RiskController as RiskControllerDefinition } from "../useRiskController";

export function useCloudRiskController() {
    return useMemo(
        (): RiskControllerDefinition => ({
            excludeEntity:
                (entityId, expirationDate, reason, message, riskId) =>
                    RiskController.excludeEntity(
                        new Contract.RiskControllerExcludeEntityRequest(
                            entityId,
                            expirationDate,
                            reason,
                            message,
                            riskId)),
            getRiskGroups:
                (requestFilters, groupRequestType) =>
                    RiskController.getRiskGroups(
                        new Contract.RiskControllerGetCloudRiskGroupsRequest(
                            requestFilters,
                            groupRequestType
                        )),
            getRiskIdToScopeIdentityPermissionsMap:
                requestFilters =>
                    RiskController.getRiskIdToScopeIdentityPermissionsMap(
                        new Contract.RiskControllerGetCloudRiskIdToScopeIdentityPermissionsMapRequest(requestFilters)),
            getRiskModelPage:
                async (requestFilters, limit, skip, sort) => {
                    const riskModelPageResponse =
                        await RiskController.getRiskModelPage(
                            new Contract.RiskControllerGetCloudRiskModelPageRequest(
                                requestFilters,
                                limit,
                                skip,
                                sort));
                    riskModelStore.notify(riskModelPageResponse.riskModelPage.items);
                    return riskModelPageResponse;
                },
            updateCloseRiskStatus:
                (riskIds, message) =>
                    RiskController.updateRiskStatus(
                        new Contract.RiskControllerUpdateRiskCloseStatusRequest(
                            message,
                            riskIds)),
            updateIgnoreRiskStatus:
                (message, riskIds, ignoreExpirationDate, ignoreReason) =>
                    RiskController.updateRiskStatus(
                        new Contract.RiskControllerUpdateRiskIgnoreStatusRequest(
                            message,
                            riskIds,
                            ignoreExpirationDate,
                            ignoreReason)),
            updateOpenRiskStatus:
                (riskIds, message) =>
                    RiskController.updateRiskStatus(
                        new Contract.RiskControllerUpdateRiskOpenStatusRequest(
                            message,
                            riskIds))
        }),
        []);
}