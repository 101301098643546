import { Link, Step, Steps, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../../../..";
import { Contract, Entity, entityModelStore, InlineEntities } from "../../../../../../../../../../../../../../common";
import { useOciConsoleSignInStepTranslator } from "../../../../../../../../../../../../../../tenants";
import { useGetOciComputeInstanceConfigurationRiskContext, useGetOciComputeInstancePoolsRiskContext, useGetOciComputeInstancesRiskContext } from "../../../contexts";

export function useOciComputeInstanceMetadataServiceVersionRiskInstanceConfigurationDefinition(riskModel: Contract.OciComputeInstanceMetadataServiceVersionRiskModel) {
    const risk = riskModel.risk as Contract.OciComputeInstanceMetadataServiceVersionRisk;
    const instanceConfigurationModel = entityModelStore.useGet(risk.entityId) as Contract.OciComputeInstanceConfigurationModel;

    const getOciComputeInstanceConfigurationRiskContext = useGetOciComputeInstanceConfigurationRiskContext();
    const getOciComputeInstancePoolsRiskContext = useGetOciComputeInstancePoolsRiskContext();
    const getOciComputeInstancesRiskContext = useGetOciComputeInstancesRiskContext();

    const consoleSignInStepTranslator = useOciConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.oci.hooks.compliance.useOciComputeInstanceMetadataServiceVersionRiskDefinition.hooks.useOciComputeInstanceMetadataServiceVersionRiskInstanceConfigurationDefinition",
            () => ({
                description: "{{instanceConfiguration}} is not enforcing Instance Metadata Service Version 2 (IMDSv2)",
                sections: {
                    resolution: {
                        step1: {
                            link: "InstanceMetadataRequests metric",
                            text: "Verify that the instance does not use the IMDSv1 endpoints before you disable requests to IMDSv1. If the instance still relies on IMDSv1 when you disable requests to it, you might lose some functionality. To identify whether IMDSv1 is being used, use the {{instanceMetadataServiceVersion1RequestMetricDocumentationLink}}."
                        },
                        step2: {
                            step1: "Updating an existing instance configuration to use IMDSv2 only is not supported and a new one must be created. In order to resolve the issue, the old instance configuration must be removed.",
                            step2: "Click **Create instance configuration**",
                            step3: {
                                link: "platform images",
                                text: "Fill in the instance configuration details. Make sure the selected image supports the new IMDSv2 version. IMDSv2 is supported on the following {{instanceMetadataServiceVersion2SupportedImagesDocumentationLink}}."
                            },
                            step4: "At the bottom of the page, click **Show advanced options**",
                            step5: "Under **Management > Instance metadata service**, select **Require an authorization header**",
                            step6: "Click **Create**",
                            title: "Create a new instance configuration"
                        },
                        step3: {
                            step1: "For each instance pool, Click **Edit**",
                            step2: "For **Instance configuration**, select the new instance configuration",
                            step3: "Click **Save**",
                            step4: "Changes only apply to new instances. To update old instances, terminate them, and the instance pool will automatically spawn replacements as needed",
                            title: "Update {{instancePools}} with the newly created instance configuration"
                        },
                        step4: {
                            step1: "Navigate to the newly created Instance Configuration page",
                            step2: "Click **Launch instance**",
                            step3: "Click **Create**",
                            step4: "Delete the insecure instance",
                            title: "Repeat the following steps for {{instances}} that are not attached to any instance pool, and therefore need to be recreated manually"
                        },
                        step5: "Delete the insecure instance configuration {{instanceConfiguration}}"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            instanceConfiguration:
                <Entity
                    entityIdOrModel={instanceConfigurationModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.OciConsoleView.Compute,
                instanceConfigurationModel.consoleUrl!),
            localization.sections.resolution.step1.text({
                instanceMetadataServiceVersion1RequestMetricDocumentationLink:
                    <Link
                        urlOrGetUrl={riskModel.instanceMetadataServiceVersion1RequestMetricDocumentationUrl}
                        variant="external">
                        {localization.sections.resolution.step1.link()}
                    </Link>
            }),
            new Step(
                localization.sections.resolution.step2.title(),
                {
                    contentElement:
                        <Steps variant="plainNumbers">
                            {localization.sections.resolution.step2.step1()}
                            {localization.sections.resolution.step2.step2()}
                            {localization.sections.resolution.step2.step3.text({
                                instanceMetadataServiceVersion2SupportedImagesDocumentationLink:
                                    <Link
                                        urlOrGetUrl={riskModel.instanceMetadataServiceVersion2SupportedImagesDocumentationUrl}
                                        variant="external">
                                        {localization.sections.resolution.step2.step3.link()}
                                    </Link>
                            })}
                            {localization.sections.resolution.step2.step4()}
                            {localization.sections.resolution.step2.step5()}
                            {localization.sections.resolution.step2.step6()}
                        </Steps>
                }),
            _.isEmpty(instanceConfigurationModel.instancePoolIds)
                ? undefined
                : new Step(
                    localization.sections.resolution.step3.title({
                        instancePools:
                            <InlineEntities
                                entityIdsOrModels={instanceConfigurationModel.instancePoolIds}
                                entityTypeName={Contract.TypeNames.OciComputeInstancePool}
                                variant="itemCountAndType"/>
                    }),
                    {
                        contentElement:
                            <Steps variant="plainNumbers">
                                {localization.sections.resolution.step3.step1()}
                                {localization.sections.resolution.step3.step2()}
                                {localization.sections.resolution.step3.step3()}
                                {localization.sections.resolution.step3.step4()}
                            </Steps>
                    }),
            _.isEmpty(riskModel.risk.nonInstancePoolInstanceIds)
                ? undefined
                : new Step(
                    localization.sections.resolution.step4.title({
                        instances:
                            <InlineEntities
                                entityIdsOrModels={riskModel.risk.nonInstancePoolInstanceIds}
                                entityTypeName={Contract.TypeNames.OciComputeInstance}
                                variant="itemCountAndType"/>
                    }),
                    {
                        contentElement:
                            <Steps variant="plainNumbers">
                                {localization.sections.resolution.step4.step1()}
                                {localization.sections.resolution.step4.step2()}
                                {localization.sections.resolution.step4.step3()}
                                {localization.sections.resolution.step4.step4()}
                            </Steps>
                    }),
            localization.sections.resolution.step5({
                instanceConfiguration:
                    <Entity
                        entityIdOrModel={instanceConfigurationModel}
                        variant="text"/>
            })
        ],
        riskModel,
        () => {
            const instanceConfigurationContextItems = getOciComputeInstanceConfigurationRiskContext(instanceConfigurationModel);
            const instancePoolsContextItems = getOciComputeInstancePoolsRiskContext(instanceConfigurationModel.instancePoolIds);
            const instancesContextItems = getOciComputeInstancesRiskContext(instanceConfigurationModel.instanceIds);
            return [
                instanceConfigurationContextItems.generalInformation,
                instanceConfigurationContextItems.instancePools,
                instanceConfigurationContextItems.instances,
                instancesContextItems.runningInstances,
                instancesContextItems.sensitive,
                instanceConfigurationContextItems.sensitive,
                instancePoolsContextItems.sensitive,
                instanceConfigurationContextItems.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}