﻿import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useAzureContainerServiceManagedClusterAgentPoolModeTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useAzureContainerServiceManagedClusterAgentPoolModeTranslator",
            () => ({
                [Contract.TypeNames.AzureContainerServiceManagedClusterAgentPoolMode]: {
                    [Contract.AzureContainerServiceManagedClusterAgentPoolMode.Gateway]: "Gateway",
                    [Contract.AzureContainerServiceManagedClusterAgentPoolMode.User]: "User",
                    [Contract.AzureContainerServiceManagedClusterAgentPoolMode.System]: "System"
                }
            }));
    return (mode: Contract.AzureContainerServiceManagedClusterAgentPoolMode) =>
        localization[Contract.TypeNames.AzureContainerServiceManagedClusterAgentPoolMode][mode]();
}