import { useLocalization } from "@infrastructure";
import React from "react";
import { useOciDefaultResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { useCustomEntityPropertyInfoItemElements } from "..";
import { Contract, InfoCard, InfoItem, useEntityTypeNameTranslator } from "../../../../../../../../../../common";
import { OciFssMountTargetExportOptionsTable } from "../../../../../../../../../../tenants";
import { useOciFssMountTargetExportStatusTranslator } from "../../../../../../hooks";
import { EntitiesInfoItem, Info } from "../../../../components";

export function useOciFssMountTargetExportDefinition(resourceModel: Contract.OciResourceModel, options?: DefinitionOptions) {
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useOciDefaultResourceInfoItemElements(resourceModel);
    const mountTargetExportModel = resourceModel as Contract.OciFssMountTargetExportModel;
    const mountTargetExport = resourceModel.entity as Contract.OciFssMountTargetExport;

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const mountTargetExportStatusTranslator = useOciFssMountTargetExportStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.oci.useOciFssMountTargetExportDefinition",
            () => ({
                info: {
                    cards: {
                        exportOptions: "Export Options"
                    },
                    items: {
                        status: "State"
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="status"
                        title={localization.info.items.status()}
                        value={mountTargetExportStatusTranslator(mountTargetExport.status)}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={mountTargetExportModel.fileSystemIdReference}
                        entityTypeName={Contract.TypeNames.OciFssFileSystem}
                        key="fileSystem"
                        location="all"
                        title={entityTypeNameTranslator(Contract.TypeNames.OciFssFileSystem)}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={mountTargetExportModel.mountTargetId}
                        entityTypeName={Contract.TypeNames.OciFssMountTarget}
                        key="mountTarget"
                        location="all"
                        title={entityTypeNameTranslator(Contract.TypeNames.OciFssMountTarget)}/>
                ]}
                options={options?.infoOptions}>
                <InfoCard title={localization.info.cards.exportOptions()}>
                    <OciFssMountTargetExportOptionsTable exportOptions={mountTargetExport.options}/>
                </InfoCard>
            </Info>
    });
}