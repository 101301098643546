import { Optional } from "@infrastructure";
import _ from "lodash";
import { TypeHelper } from "..";
import { Contract } from "../controllers";

export enum NetworkScopeHelperProtocol {
    Dns = "DNS",
    Geneve = "GENEVE",
    Http = "HTTP",
    Https = "HTTPS",
    Imap = "IMAP",
    Imaps = "IMAPS",
    Ldap = "LDAP",
    MongoDb = "MongoDB",
    Mssql = "MSSQL",
    MysqlAurora = "MYSQL/Aurora",
    Nfs = "NFS",
    OracleRds = "OracleRDS",
    Pop3 = "POP3",
    Pop3s = "POP3S",
    Postgresql = "PostgreSQL",
    Rdp = "RDP",
    Redshift = "Redshift",
    Smb = "SMB",
    Smtp = "SMTP",
    Smtps = "SMTPS",
    Ssh = "SSH"
}

export class NetworkScopeHelper {
    public static protocolToProtocolDataMap = {
        [NetworkScopeHelperProtocol.Dns]: {
            portNumber: 53,
            types: [
                Contract.ProtocolType.Tcp,
                Contract.ProtocolType.Udp
            ]
        },
        [NetworkScopeHelperProtocol.Geneve]: {
            portNumber: 6081,
            types: [Contract.ProtocolType.Tcp]
        },
        [NetworkScopeHelperProtocol.Http]: {
            portNumber: 80,
            types: [Contract.ProtocolType.Tcp]
        },
        [NetworkScopeHelperProtocol.Https]: {
            portNumber: 443,
            types: [Contract.ProtocolType.Tcp]
        },
        [NetworkScopeHelperProtocol.Imap]: {
            portNumber: 143,
            types: [Contract.ProtocolType.Tcp]
        },
        [NetworkScopeHelperProtocol.Imaps]: {
            portNumber: 993,
            types: [Contract.ProtocolType.Tcp]
        },
        [NetworkScopeHelperProtocol.Ldap]: {
            portNumber: 389,
            types: [Contract.ProtocolType.Tcp]
        },
        [NetworkScopeHelperProtocol.MongoDb]: {
            portNumber: 27017,
            types: [Contract.ProtocolType.Tcp]
        },
        [NetworkScopeHelperProtocol.Mssql]: {
            portNumber: 1433,
            types: [Contract.ProtocolType.Tcp]
        },
        [NetworkScopeHelperProtocol.MysqlAurora]: {
            portNumber: 3306,
            types: [Contract.ProtocolType.Tcp]
        },
        [NetworkScopeHelperProtocol.Nfs]: {
            portNumber: 2049,
            types: [Contract.ProtocolType.Tcp]
        },
        [NetworkScopeHelperProtocol.OracleRds]: {
            portNumber: 1521,
            types: [Contract.ProtocolType.Tcp]
        },
        [NetworkScopeHelperProtocol.Pop3]: {
            portNumber: 110,
            types: [Contract.ProtocolType.Tcp]
        },
        [NetworkScopeHelperProtocol.Pop3s]: {
            portNumber: 995,
            types: [Contract.ProtocolType.Tcp]
        },
        [NetworkScopeHelperProtocol.Postgresql]: {
            portNumber: 5432,
            types: [Contract.ProtocolType.Tcp]
        },
        [NetworkScopeHelperProtocol.Rdp]: {
            portNumber: 3389,
            types: [Contract.ProtocolType.Tcp]
        },
        [NetworkScopeHelperProtocol.Redshift]: {
            portNumber: 5439,
            types: [Contract.ProtocolType.Tcp]
        },
        [NetworkScopeHelperProtocol.Smb]: {
            portNumber: 445,
            types: [Contract.ProtocolType.Tcp]
        },
        [NetworkScopeHelperProtocol.Smtp]: {
            portNumber: 25,
            types: [Contract.ProtocolType.Tcp]
        },
        [NetworkScopeHelperProtocol.Smtps]: {
            portNumber: 465,
            types: [Contract.ProtocolType.Tcp]
        },
        [NetworkScopeHelperProtocol.Ssh]: {
            portNumber: 22,
            types: [Contract.ProtocolType.Tcp]
        }
    };

    public static getProtocol(portRange: Contract.IntRange, protocolType: Optional<Contract.ProtocolType>): Optional<NetworkScopeHelperProtocol> {
        return portRange.start === portRange.end && !_.isNil(protocolType)
            ? _.findKey(
                NetworkScopeHelper.protocolToProtocolDataMap,
                protocolData =>
                    protocolData.portNumber === portRange.start &&
                    _.includes(protocolData.types, protocolType)) as Optional<NetworkScopeHelperProtocol>
            : undefined;
    }

    public static getProtocolProtocolTypes(protocol: NetworkScopeHelperProtocol) {
        return NetworkScopeHelper.protocolToProtocolDataMap[protocol].types;
    }

    public static getProtocolType(protocolRange: Contract.IntRange): Optional<Contract.ProtocolType> {
        return protocolRange.start === protocolRange.end
            ? TypeHelper.getEnumValueMemberName(Contract.TypeNames.ProtocolType, protocolRange.start)
            : protocolRange.start === 0 &&
            protocolRange.end === 255
                ? Contract.ProtocolType.Unknown
                : undefined;
    }

    public static isAll(portRange: Contract.IntRange) {
        return portRange.start === 0 && portRange.end === 65535;
    }

    public static isSensitive(portRange: Contract.IntRange, protocolRange: Contract.IntRange) {
        const protocolType = NetworkScopeHelper.getProtocolType(protocolRange);
        const protocol = NetworkScopeHelper.getProtocol(portRange, protocolType);
        return (
            protocolType === Contract.ProtocolType.Unknown && NetworkScopeHelper.isAll(portRange) ||
            protocol === NetworkScopeHelperProtocol.Rdp ||
            protocol === NetworkScopeHelperProtocol.Ssh);
    }
}