﻿import _ from "lodash";
import React from "react";
import { DialogMenuItem, useLocalization } from "@infrastructure";
import { Contract, UserHelper, WorkloadAnalysisIcon } from "../../../../../common";
import { GenerateVirtualMachinePriorityScanRequestDialog } from "../components";

export function useGetVirtualMachinePriorityScanRequestDialogMenuItem(virtualMachineModel: Contract.IVirtualMachineModel) {

    const { workloadAnalysisError, workloadAnalysisPriorityScanRequested } = virtualMachineModel;
    const localization =
        useLocalization(
            "views.customer.workloadAnalysis.hooks.useGetVirtualMachinePriorityScanRequestDialogMenuItem",
            () => ({
                enabled: "Scan Now",
                requested: "Scan Requested",
                tooltip: {
                    disabled: "Workload Protection scanning is disabled",
                    excluded: "This VM is excluded from scanning",
                    notSupported: "Scanning is not supported for this virtual machine"
                }
            }));

    const disabledErrorToTooltipMap = {
        [Contract.WorkloadAnalysisError.VirtualMachineExcluded]: localization.tooltip.excluded(),
        [Contract.WorkloadAnalysisError.VirtualMachineProductCodeRemoved]: localization.tooltip.notSupported(),
        [Contract.WorkloadAnalysisError.WorkloadAnalysisDisabled]: localization.tooltip.disabled()
    };


    return () =>
        !UserHelper.hasAnyScopePermissions(virtualMachineModel.entity.scopeIds, Contract.IdentityPermission.SecurityWrite)
            ? undefined
            : new DialogMenuItem(
                onClose =>
                    <GenerateVirtualMachinePriorityScanRequestDialog
                        virtualMachineModel={virtualMachineModel}
                        onClose={() => {
                            onClose();
                        }}/>,
                workloadAnalysisPriorityScanRequested
                    ? localization.requested()
                    : localization.enabled(),
                {
                    disabled: virtualMachineModel.kubernetesClusterSensor ||
                        workloadAnalysisPriorityScanRequested ||
                        (!_.isNil(workloadAnalysisError) &&
                            _.has(
                                disabledErrorToTooltipMap,
                                workloadAnalysisError)),
                    icon: <WorkloadAnalysisIcon/>,
                    tooltip:
                        workloadAnalysisError
                            ? _.get(
                                disabledErrorToTooltipMap,
                                workloadAnalysisError)
                            : undefined
                });
}