﻿import React from "react";

export function JpIcon() {
    return (
        <svg
            fill="none"
            height={50}
            viewBox="0 0 50 50"
            width={50}>
            <path
                d="M0.731085 18.8194C4.01745 5.53593 17.4389 -2.55456 30.7223 0.731808C44.0057 4.01817 52.0962 17.4396 48.8099 30.723C45.5235 43.9939 32.1021 52.0969 18.8186 48.8106C5.54774 45.5242 -2.55528 32.0903 0.731085 18.8194Z"
                fill="#F6F6F6"/>
            <path
                d="M11.8571 21.441C13.5881 14.4418 20.6625 10.177 27.6618 11.908C34.661 13.639 38.9257 20.7135 37.1947 27.7127C35.4637 34.7119 28.3893 38.9766 21.3901 37.2456C14.3909 35.5146 10.1136 28.4402 11.8571 21.441Z"
                fill="#E52A18"/>
        </svg>);
}