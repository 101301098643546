import { FormLayout, getUrlQueryParameters, Message, useClearQueryParameters, useLocalization } from "@infrastructure";
import { Box, Button, CircularProgress, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import { ConsoleApiUrlHelper, Contract, GeographyHelper, RadioGroup, TenantController, tenantModelStore, useAadPartitionTypeTranslator, useTheme } from "../../../../../../../../../../../../../../common";
import { useAadTenantsContext, useSetAadTenantsContext } from "../../AadTenants";
import { ConsentApplication, Success, TenantApplicationData } from "./components";

export type InsertRequestQueryParameters = {
    aadTenantId?: string;
    error?: Contract.CustomerConsoleAppUrlBuilderGetConfigurationIntegrationsAadTenantsRelativeUrlError;
    name?: string;
    primaryDomainName?: string;
};

export function AddOrEdit() {
    const aadTenantsContext = useAadTenantsContext();
    const tenantConfiguration =
        _.isBoolean(aadTenantsContext.addOrEditOpen)
            ? undefined
            : aadTenantsContext.addOrEditOpen;

    const aadPartitionTypeTranslator = useAadPartitionTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useIdentityProviderTenantItems.aadTenants.addOrEdit",
            () => ({
                actions: {
                    add: "Add",
                    edit: "Update",
                    grantConsent: "Grant Consent",
                    success: "Done"
                },
                error: {
                    [Contract.TypeNames.CustomerConsoleAppUrlBuilderGetConfigurationIntegrationsAadTenantsRelativeUrlError]: {
                        [Contract.CustomerConsoleAppUrlBuilderGetConfigurationIntegrationsAadTenantsRelativeUrlError.ApplicationConsentError]: "Consent is required to add tenant",
                        [Contract.CustomerConsoleAppUrlBuilderGetConfigurationIntegrationsAadTenantsRelativeUrlError.ApplicationServicePrincipalDisabled]: "Tenable cloud security connector app is disabled",
                        [Contract.CustomerConsoleAppUrlBuilderGetConfigurationIntegrationsAadTenantsRelativeUrlError.ApplicationUnauthorized]: "Permissions not granted",
                        [Contract.CustomerConsoleAppUrlBuilderGetConfigurationIntegrationsAadTenantsRelativeUrlError.B2CTenant]: "B2C tenants cannot be onboarded",
                        [Contract.CustomerConsoleAppUrlBuilderGetConfigurationIntegrationsAadTenantsRelativeUrlError.GuestUserUnauthorized]: "Guest users must have Global Administrator role on connected tenant",
                        [Contract.CustomerConsoleAppUrlBuilderGetConfigurationIntegrationsAadTenantsRelativeUrlError.TenantExists]: "Tenant already exists",
                        [Contract.CustomerConsoleAppUrlBuilderGetConfigurationIntegrationsAadTenantsRelativeUrlError.TenantNotExist]: "Failed to connect to tenant",
                        [Contract.CustomerConsoleAppUrlBuilderGetConfigurationIntegrationsAadTenantsRelativeUrlError.UserAuthenticationError]: "Failed to sign in to tenant"
                    }
                },
                fields: {
                    id: "Tenant ID",
                    name: "Tenant name",
                    primaryDomain: "Primary Domain"
                },
                title: {
                    add: "Add Microsoft Entra ID Tenant",
                    update: "Update Tenant"
                }
            }));

    const [applicationRawId, setApplicationRawId] = useState(tenantConfiguration?.application!.rawId);
    const [applicationSecret, setApplicationSecret] = useState<string>();
    const [selectedPartitionType, setSelectedPartitionType] = useState(tenantConfiguration?.partitionType ?? GeographyHelper.deploymentAadPartitionType);
    const [tenantRawId, setTenantRawId] = useState(tenantConfiguration?.rawId);

    const clearQueryParameters = useClearQueryParameters();
    const consentResponse =
        useMemo(
            () => {
                const consentResponse = getUrlQueryParameters<InsertRequestQueryParameters>();
                clearQueryParameters();

                return _.isNil(consentResponse.aadTenantId) && _.isNil(consentResponse.error) && _.isNil(consentResponse.name) && _.isNil(consentResponse.primaryDomainName)
                    ? undefined
                    : consentResponse;
            },
            []);

    const [executing, setExecuting] = useState(false);
    const [error, setError] = useState(consentResponse?.error);
    const [updatedTenantConfiguration, setUpdatedTenantConfiguration] = useState<Contract.AadTenantConfiguration>();

    async function save() {
        try {
            setExecuting(true);
            setError(undefined);

            const { tenantModel } =
                _.isNil(tenantConfiguration)
                    ? await TenantController.insertAadChinaTenant(
                        new Contract.TenantControllerInsertAadChinaTenantRequest(
                            applicationRawId!,
                            applicationSecret!,
                            tenantRawId!))
                    : await TenantController.updateAadTenant(
                        new Contract.TenantControllerUpdateAadTenantRequest(
                            applicationRawId!,
                            applicationSecret!,
                            tenantConfiguration.id));

            await tenantModelStore.notify(tenantModel.configuration.id);

            setUpdatedTenantConfiguration(tenantModel.configuration);
        } catch {
            setError(Contract.CustomerConsoleAppUrlBuilderGetConfigurationIntegrationsAadTenantsRelativeUrlError.TenantNotExist);
        }

        setExecuting(false);
    }

    const [valid, setValid] = useState(false);
    const setAadTenantsContext = useSetAadTenantsContext();
    const theme = useTheme();
    return (
        <FormLayout
            disableContentPadding={true}
            footerOptions={{
                border: true,
                contentElement:
                    <Stack
                        alignItems="center"
                        direction="row"
                        justifyContent="flex-end"
                        spacing={1}>
                        {!_.isNil(error) &&
                            <Message
                                level="error"
                                title={localization.error[Contract.TypeNames.CustomerConsoleAppUrlBuilderGetConfigurationIntegrationsAadTenantsRelativeUrlError][error]()}/>}
                        {executing &&
                            <CircularProgress size={theme.spacing(2)}/>}
                        {(_.isNil(consentResponse) && _.isNil(updatedTenantConfiguration)) &&
                            _.has(aadTenantsContext.partitionTypeToApplicationRawIdMap, selectedPartitionType) &&
                            <Button
                                disabled={executing || !valid}
                                onClick={
                                    () =>
                                        window.location.assign(
                                            ConsoleApiUrlHelper.getAadConsentUrl(
                                                aadTenantsContext.partitionTypeToApplicationRawIdMap[selectedPartitionType],
                                                selectedPartitionType,
                                                tenantRawId))}>
                                {localization.actions.grantConsent()}
                            </Button>}
                        {(_.isNil(consentResponse) && _.isNil(updatedTenantConfiguration)) &&
                            !_.has(aadTenantsContext.partitionTypeToApplicationRawIdMap, selectedPartitionType) &&
                            <Button
                                disabled={executing || !valid}
                                onClick={save}>
                                {_.isNil(tenantConfiguration)
                                    ? localization.actions.add()
                                    : localization.actions.edit()}
                            </Button>}
                        {(!_.isNil(consentResponse) || !_.isNil(updatedTenantConfiguration)) &&
                            <Button
                                onClick={
                                    () =>
                                        setAadTenantsContext(
                                            context => ({
                                                ...context,
                                                addOrEditOpen: false
                                            }))}>
                                {localization.actions.success()}
                            </Button>}
                    </Stack>
            }}
            titleOptions={{
                text:
                    !_.isNil(tenantConfiguration)
                        ? localization.title.update()
                        : localization.title.add()
            }}>
            <Box
                sx={{
                    height: "100%",
                    padding: theme.spacing(3)
                }}>
                {!_.isNil(consentResponse?.aadTenantId) ||
                !_.isNil(updatedTenantConfiguration)
                    ? <Success
                        consentResponse={consentResponse}
                        updatedTenantConfiguration={updatedTenantConfiguration}/>
                    : <RadioGroup
                        items={
                            _(aadTenantsContext.partitionTypes).
                                orderBy(partitionType => partitionType !== GeographyHelper.deploymentAadPartitionType).
                                map(
                                    partitionType => ({
                                        children:
                                            _.has(aadTenantsContext.partitionTypeToApplicationRawIdMap, partitionType)
                                                ? <ConsentApplication
                                                    tenantRawId={tenantRawId}
                                                    onTenantRawIdChanged={setTenantRawId}
                                                    onValidChanged={setValid}/>
                                                : <TenantApplicationData
                                                    data={{ applicationRawId, applicationSecret, tenantRawId }}
                                                    onDataChanged={({ applicationRawId, applicationSecret, tenantRawId }) => {
                                                        setApplicationRawId(applicationRawId);
                                                        setApplicationSecret(applicationSecret);
                                                        setTenantRawId(tenantRawId);
                                                    }}
                                                    onValidChanged={setValid}/>,
                                        disabled: !_.isNil(tenantConfiguration) && tenantConfiguration.partitionType != partitionType,
                                        label:
                                            <Typography variant="h4">
                                                {aadPartitionTypeTranslator(partitionType)}
                                            </Typography>,
                                        value: partitionType
                                    })).
                                value()}
                        selectedValue={selectedPartitionType}
                        onChange={partitionType => setSelectedPartitionType(partitionType)}/>}
            </Box>
        </FormLayout>);
}