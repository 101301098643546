import { InlineSecrets, Link, TextViewer, useLocalization } from "@infrastructure";
import { Button } from "@mui/material";
import _ from "lodash";
import React, { Fragment, useState } from "react";
import { Contract, Entity, entityModelStore, riskPolicyModelStore, SecretExclusionAction, UserHelper, useTheme } from "../../../../../../../../../../../../../../common";
import { RiskDefinitionSection } from "../../../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../../../useCommonSectionsAndDescriptionDefinition";
import { EntityExternalConsoleLink } from "../../../../components";
import { useGetGcpComputeInstanceRiskContext } from "../../../contexts";
import { useGetGcpComputeInstanceStartupScriptSecretExistsRiskContext } from "./hooks";

export function useGcpComputeInstanceStartupScriptSecretExistsRiskInstanceDefinition(riskModel: Contract.GcpComputeInstanceStartupScriptSecretExistsRiskModel) {
    const risk = riskModel.risk as Contract.GcpComputeInstanceStartupScriptSecretExistsRisk;
    const { riskPolicyConfiguration } = riskPolicyModelStore.useGet(risk.policyId);
    const instanceModel = entityModelStore.useGet(risk.entityId) as Contract.GcpComputeInstanceModel;

    const getGcpComputeInstanceRiskContext = useGetGcpComputeInstanceRiskContext();
    const getGcpComputeInstanceStartupScriptSecretExistsRiskContext = useGetGcpComputeInstanceStartupScriptSecretExistsRiskContext();
    const [exclude, setExclude] = useState<boolean>(false);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.useGcpComputeInstanceStartupScriptSecretExistsRiskDefinition.hooks.useGcpComputeInstanceStartupScriptSecretExistsRiskInstanceDefinition",
            () => ({
                contextItems: {
                    secrets: "The {{translatedInstanceTypeName}} is exposing {{secrets}} via startup script"
                },
                description: "{{instance}} is exposing {{secrets}} via a startup script",
                excludeAction: "Exclude Secret",
                sections: {
                    resolution: {
                        step1: "Click the **Edit** button",
                        step2: {
                            link: "Secrets Manager",
                            text: "Migrate your secrets to {{secretManagerDocumentationLink}}"
                        },
                        step3: "Edit the metadata entry **{{scriptName}}** to remove the exposed secrets and point to the newly created Secret Manager secret instead",
                        step4: "Click **Save** to confirm"
                    }
                }
            }));

    const theme = useTheme();
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            instance:
                <Entity
                    entityIdOrModel={riskModel.risk.entityId}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>,
            secrets:
                <InlineSecrets
                    secrets={riskModel.risk.secrets}/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={riskModel.risk.entityId}
                key={riskModel.risk.entityId}
                page={Contract.GcpConsolePage.Resource}/>,
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2.text({
                secretManagerDocumentationLink:
                    <Link
                        urlOrGetUrl={riskModel.secretManagerDocumentationUrl}
                        variant="external">
                        {localization.sections.resolution.step2.link()}
                    </Link>
            }),
            ...(_.map(
                riskModel.risk.startupScriptNameToSecretExistsTextBlockMap,
                (_textBlock, startupScriptName) => localization.sections.resolution.step3({ scriptName: startupScriptName }))),
            localization.sections.resolution.step4()
        ],
        riskModel,
        () => {
            const computeInstanceStartupScriptSecretExistsRiskContextItems = getGcpComputeInstanceStartupScriptSecretExistsRiskContext(risk);
            const instanceContextItems = getGcpComputeInstanceRiskContext(instanceModel);
            return [
                instanceContextItems.generalInformation,
                instanceContextItems.sensitive,
                instanceContextItems.status,
                instanceContextItems.vpcsAndSubnets,
                computeInstanceStartupScriptSecretExistsRiskContextItems.secrets,
                computeInstanceStartupScriptSecretExistsRiskContextItems.excludedSecrets,
                computeInstanceStartupScriptSecretExistsRiskContextItems.permissionActionPrincipals,
                instanceContextItems.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        },
        {
            sections:
                _.map(
                    riskModel.risk.startupScriptNameToSecretExistsTextBlockMap,
                    (textBlock, startupScriptName) =>
                        new RiskDefinitionSection(
                            <Fragment>
                                {exclude &&
                                    <SecretExclusionAction
                                        riskPolicyConfiguration={riskPolicyConfiguration}
                                        onExcludeChanged={setExclude}/>}
                                <TextViewer
                                    actionsElement={
                                        UserHelper.hasAnyScopePermissions(riskModel.risk.scopeIds, Contract.IdentityPermission.SecurityWrite)
                                            ? <Button
                                                sx={{
                                                    "&:hover": {
                                                        backgroundColor: theme.palette.textEditor.background
                                                    },
                                                    backgroundColor: theme.palette.textEditor.background,
                                                    margin: theme.spacing(1),
                                                    padding: theme.spacing(1, 3)
                                                }}
                                                variant="outlined"
                                                onClick={() => setExclude(true)}>
                                                {localization.excludeAction()}
                                            </Button>
                                            : undefined}
                                    actionsElementSx={{ backgroundColor: "unset" }}
                                    format="json"
                                    highlightedLines={textBlock.highlightedLines}
                                    startLine={textBlock.startLine}
                                    sx={{ minHeight: theme.spacing(6.25) }}
                                    text={textBlock.text}/>
                            </Fragment>,
                            startupScriptName))
        });
}