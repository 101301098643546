import React from "react";
import { Contract, useEntityTypeNameTranslator } from "../../../../../../../../../common";
import { AadDirectoryDirectoryRoleAssignmentResources } from "./AadDirectoryDirectoryRoleAssignmentResources";

type AadDirectoryPrincipalDirectoryRoleAssignmentResourcesProps = {
    csvExportFilePrefix: string;
    principalModel: Contract.AadDirectoryPrincipalModel;
};

export function AadDirectoryPrincipalDirectoryRoleAssignmentResources({ csvExportFilePrefix, principalModel }: AadDirectoryPrincipalDirectoryRoleAssignmentResourcesProps) {
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    return (
        <AadDirectoryDirectoryRoleAssignmentResources
            csvExportFilePrefixes={[
                entityTypeNameTranslator(principalModel.entity.typeName, { includeServiceName: false }),
                principalModel.entity.displayName,
                csvExportFilePrefix
            ]}
            directoryRoleAssignmentResourceIds={principalModel.directoryRoleAssignmentResourceIds}
            principalId={principalModel.entity.id}
            variant="roleAndScopeAndPrincipalAndRoleManagementPolicyAssignment"/>);
}