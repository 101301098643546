﻿import { UnexpectedError } from "@infrastructure";
import _, { Dictionary } from "lodash";
import { Contract, RiskPolicyTypeMetadataHelper, RiskType, RiskTypeMetadataModelHelper } from "../../../../../common";

export class RiskHelper {
    public static riskStatusToSubStatusMap: Dictionary<Contract.RiskSubStatus[]> = {
        [Contract.RiskStatus.Closed]: [
            Contract.RiskSubStatus.ClosedFalsePositive,
            Contract.RiskSubStatus.ClosedFixed,
            Contract.RiskSubStatus.ClosedManually,
            Contract.RiskSubStatus.ClosedResourceDeleted,
            Contract.RiskSubStatus.ClosedSelfResolved],
        [Contract.RiskStatus.Ignored]: [
            Contract.RiskSubStatus.IgnoredByDesign,
            Contract.RiskSubStatus.IgnoredException,
            Contract.RiskSubStatus.IgnoredFalsePositive],
        [Contract.RiskStatus.Open]: [
            Contract.RiskSubStatus.OpenAnomaly,
            Contract.RiskSubStatus.OpenExistingResource,
            Contract.RiskSubStatus.OpenNewResource,
            Contract.RiskSubStatus.OpenReopened,
            Contract.RiskSubStatus.OpenUnignored]
    };

    public static getRiskAnalyticsPropertyNameToValueMap(riskModel: Contract.RiskModel) {
        return {
            "Risk Creation Time": riskModel.systemCreationTime,
            "Risk Entity Type": riskModel.riskedEntityTypeName,
            "Risk Policy Category": RiskPolicyTypeMetadataHelper.get(RiskTypeMetadataModelHelper.get(riskModel.risk.typeName).policyConfigurationTypeName).category,
            "Risk Severity": riskModel.risk.severity,
            "Risk Status": riskModel.risk.status,
            "Risk Tenant Type": riskModel.risk.tenantType,
            "Risk Type Name": riskModel.risk.typeName
        };
    }

    public static getSideViewItemData(itemId: string) {
        const itemIdParts = _.split(itemId, "/");
        if (_.size(itemIdParts) < 2) {
            throw new UnexpectedError("RiskHelper.getSideViewData", itemId);
        }

        return {
            itemId: itemIdParts[1],
            riskType: itemIdParts[0]
        };
    }

    public static getSideViewItemId(riskType: RiskType, riskId: string) {
        return `${riskType}/${riskId}`;
    }
}