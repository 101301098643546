import { useLocalization } from "@infrastructure";
import _ from "lodash";
import { AuditEventDefinition, AuditEventDefinitionContext, AuditEventDefinitionDetailItem } from "..";
import { Contract, useIdentityRoleTranslator } from "../../../../../../../../../common";
import { useCommonAuditEventDetailsItems } from "./useCommonAuditEventDetailsItems";

export function useUserRoleAssignmentAuditEventDefinition(context: AuditEventDefinitionContext) {
    const commonAuditEventDetailsItems = useCommonAuditEventDetailsItems(context);
    const identityRoleTranslator = useIdentityRoleTranslator();
    const localization =
        useLocalization(
            "views.customer.auditEvents.profile.hooks.useDefinition.hooks.useUserRoleAssignmentAuditEventDefinition",
            () => ({
                userMail: "User",
                userRole: "Role"
            }));

    const userRoleAssignmentAuditEvent = context.auditEventModel.auditEvent as Contract.UserRoleAssignmentAuditEvent;
    return new AuditEventDefinition(
        _.filter([
            new AuditEventDefinitionDetailItem(
                localization.userMail(),
                userRoleAssignmentAuditEvent.userMail),
            new AuditEventDefinitionDetailItem(
                localization.userRole(),
                identityRoleTranslator(userRoleAssignmentAuditEvent.userRole)),
            commonAuditEventDetailsItems.errorMessageItem
        ]));
}