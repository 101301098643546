﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, InfoCard, VerticalTopItemsInfoItem } from "../../../../../../../../../../../../common";

type WorkflowNetworkInfoCardProps = {
    workflowModel: Contract.AzureLogicWorkflowModel;
};

export function WorkflowNetworkInfoCard({ workflowModel }: WorkflowNetworkInfoCardProps) {
    const workflow = workflowModel.entity as Contract.AzureLogicWorkflow;
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.azure.useAzureLogicWorkflowDefinition.workflowNetworkInfoCard",
            () => ({
                contentsIpAddress: "IP Address Ranges For Contents",
                ipAddresses: {
                    range: "{{startIpAddress}}-{{endIpAddress}}"
                },
                title: "Network",
                triggerIpAddresses: "IP Address Ranges For Triggers"
            }));

    return (
        <InfoCard
            columns={true}
            title={localization.title()}>
            <VerticalTopItemsInfoItem
                items={
                    _(workflow.triggerIpAddressRanges).
                        map(
                            triggerIpAddressRange =>
                                localization.ipAddresses.range({
                                    endIpAddress: triggerIpAddressRange.end,
                                    startIpAddress: triggerIpAddressRange.start
                                })).
                        concat(workflow.triggerIpAddressSubnets).
                        value()}
                title={localization.triggerIpAddresses()}/>
            <VerticalTopItemsInfoItem
                items={
                    _(workflow.contentIpAddressRanges).
                        map(
                            contentIpAddressRange =>
                                localization.ipAddresses.range({
                                    endIpAddress: contentIpAddressRange.end,
                                    startIpAddress: contentIpAddressRange.start
                                })).
                        concat(workflow.contentIpAddressSubnets).
                        value()}
                title={localization.contentsIpAddress()}/>
        </InfoCard>);
}