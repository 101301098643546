import { Optional, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, SpecificCustomRiskPolicyProps } from "../../../..";
import { Edit, View } from "./components";

export function AwsInboundExternalVpcsRiskPolicy({ editOptions, riskPolicyConfiguration, sx }: SpecificCustomRiskPolicyProps) {
    const localization =
        useLocalization(
            "common.customRiskPolicy.awsInboundExternalVpcRiskPolicy",
            () => ({
                template: "*capitalize*{{vpcs}}** should not have an internet gateway attached"
            }));
    return _.isNil(editOptions)
        ? <View
            riskPolicyConfiguration={riskPolicyConfiguration as Optional<Contract.AwsInboundExternalVpcRiskPolicyConfiguration>}
            sx={sx}
            templateTranslator={localization.template}/>
        : <Edit
            actionsRef={editOptions.actionsRef}
            riskPolicyConfiguration={riskPolicyConfiguration as Optional<Contract.AwsInboundExternalVpcRiskPolicyConfiguration>}
            templateTranslator={localization.template}
            onValidChange={editOptions.onValidChange}/>;
}