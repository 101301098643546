import { useMemo } from "react";
import { Contract, usePrincipalModelAccess } from "../../../../../../../../../../../../common";
import { useGetAwsIamPrincipalRiskContext } from "./useGetAwsIamPrincipalRiskContext";

export function useGetAwsIamGroupRiskContext() {
    return useMemo(
        () => useAwsIamGroupRiskContext,
        []);
}

function useAwsIamGroupRiskContext(groupModel: Contract.AwsIamGroupModel) {
    const iamPrincipalRiskContext = useGetAwsIamPrincipalRiskContext()(groupModel);
    const groupModelAccess = usePrincipalModelAccess<Contract.AwsIamRoleGroupModelAccess>(groupModel.id);

    return {
        ...iamPrincipalRiskContext,
        activityTime: groupModelAccess.activity.time
    };
}