import { Action0, Dialog, DownloadIcon } from "@infrastructure";
import { Box, Divider, Stack, Typography, useTheme } from "@mui/material";
import React, { Fragment } from "react";
import { DiffEditor, DiffEditorProps } from "./DiffEditor";

type DiffDialogProps = Omit<DiffEditorProps, "autoHeight" | "sx"> & {
    downloadOptions?: {
        data: any;
        fileName: string;
    };
    onClose: Action0;
    title: string;
};

export function DiffDialog({ downloadOptions, format, modified, modifiedTitle, onClose, original, originalTitle, title }: DiffDialogProps) {
    const theme = useTheme();
    return (
        <Dialog
            size="large"
            variant="viewer"
            onClose={onClose}>
            <Stack
                spacing={1}
                sx={{
                    height: "100%",
                    padding: theme.spacing(1.5, 0)
                }}>
                <Stack
                    direction="row"
                    spacing={2}
                    sx={{ paddingRight: theme.spacing(7) }}>
                    <Typography
                        noWrap={true}
                        sx={{
                            flex: 1,
                            padding: theme.spacing(0.5, 3)
                        }}
                        variant="h2">
                        {title}
                    </Typography>
                    {!!downloadOptions &&
                        <Fragment>
                            <Box
                                sx={{
                                    alignItems: "center",
                                    border: theme.border.primary,
                                    borderRadius: theme.spacing(0.75),
                                    display: "flex",
                                    height: theme.spacing(4),
                                    justifyContent: "center",
                                    padding: theme.spacing(1),
                                    width: theme.spacing(4)
                                }}>
                                <a
                                    download={downloadOptions.fileName}
                                    href={downloadOptions.data}
                                    title={`Download ${downloadOptions.fileName} file`}>
                                    <DownloadIcon
                                        sx={{
                                            color: theme.palette.text.primary,
                                            fontSize: "24px"
                                        }}/>
                                </a>
                            </Box>
                            <Divider
                                flexItem={true}
                                orientation="vertical"
                                style={{
                                    marginBottom: theme.spacing(0.5),
                                    marginTop: theme.spacing(0.5)
                                }}
                                variant="middle"/>
                        </Fragment>}
                </Stack>
                <Divider/>
                <Box
                    sx={{
                        flex: 1,
                        padding: theme.spacing(1.5, 3)
                    }}>
                    <DiffEditor
                        format={format}
                        modified={modified}
                        modifiedTitle={modifiedTitle}
                        original={original}
                        originalTitle={originalTitle}/>
                </Box>
            </Stack>
        </Dialog>);
}