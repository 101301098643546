﻿import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useAzureCognitiveServicesAccountTypeTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useAzureCognitiveServicesAccountTypeTranslator",
            () => ({
                [Contract.TypeNames.AzureCognitiveServicesAccountType]: {
                    [Contract.AzureCognitiveServicesAccountType.AiServices]: "AI Services",
                    [Contract.AzureCognitiveServicesAccountType.AnomalyDetector]: "Anomaly Detector",
                    [Contract.AzureCognitiveServicesAccountType.BingCustomSearch]: "Bing Custom Search",
                    [Contract.AzureCognitiveServicesAccountType.BingSearch]: "Bing Search",
                    [Contract.AzureCognitiveServicesAccountType.BingSearchV7]: "Bing Search V7",
                    [Contract.AzureCognitiveServicesAccountType.BingSpeech]: "Bing Speech",
                    [Contract.AzureCognitiveServicesAccountType.BingSpellCheck]: "Bing Spell Check",
                    [Contract.AzureCognitiveServicesAccountType.BingSpellCheckV7]: "Bing Spell Check V7",
                    [Contract.AzureCognitiveServicesAccountType.CognitiveServices]: "Cognitive Services",
                    [Contract.AzureCognitiveServicesAccountType.ComputerVision]: "Computer Vision",
                    [Contract.AzureCognitiveServicesAccountType.ContentModerator]: "Content Moderator",
                    [Contract.AzureCognitiveServicesAccountType.ContentSafety]: "Content Safety",
                    [Contract.AzureCognitiveServicesAccountType.ConversationalLanguageUnderstanding]: "Conversational Language Understanding",
                    [Contract.AzureCognitiveServicesAccountType.CustomVisionPrediction]: "Custom Vision Prediction",
                    [Contract.AzureCognitiveServicesAccountType.CustomVisionTraining]: "Custom Vision Training",
                    [Contract.AzureCognitiveServicesAccountType.Face]: "Face",
                    [Contract.AzureCognitiveServicesAccountType.FormRecognizer]: "Form Recognizer",
                    [Contract.AzureCognitiveServicesAccountType.HealthInsights]: "Health Insights",
                    [Contract.AzureCognitiveServicesAccountType.ImmersiveReader]: "Immersive Reader",
                    [Contract.AzureCognitiveServicesAccountType.InternalAllInOne]: "Internal All In One",
                    [Contract.AzureCognitiveServicesAccountType.LanguageAuthoring]: "Language Authoring",
                    [Contract.AzureCognitiveServicesAccountType.Luis]: "LUIS",
                    [Contract.AzureCognitiveServicesAccountType.LuisAuthoring]: "LUIS Authoring",
                    [Contract.AzureCognitiveServicesAccountType.MetricsAdvisor]: "Metrics Advisor",
                    [Contract.AzureCognitiveServicesAccountType.OpenAi]: "OpenAI",
                    [Contract.AzureCognitiveServicesAccountType.Personalizer]: "Personalizer",
                    [Contract.AzureCognitiveServicesAccountType.QnAMaker]: "QnA Maker",
                    [Contract.AzureCognitiveServicesAccountType.QnAMakerV2]: "QnA Maker V2",
                    [Contract.AzureCognitiveServicesAccountType.SpeechServices]: "Speech Services",
                    [Contract.AzureCognitiveServicesAccountType.SpeechTranslation]: "Speech Translation",
                    [Contract.AzureCognitiveServicesAccountType.TextAnalytics]: "Text Analytics",
                    [Contract.AzureCognitiveServicesAccountType.TextTranslation]: "Text Translation"
                }
            }));
    return (type: Contract.AzureCognitiveServicesAccountType) =>
        localization[Contract.TypeNames.AzureCognitiveServicesAccountType][type]();
}