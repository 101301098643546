import { MemoItemRenderer, Optional, PagedDropdownPage } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { PagedMultiSelectFieldOptions, PagedSelector } from ".";
import { Contract, Entity, entityModelStore } from "..";

type PagedEntityMultiSelectProps = {
    disabled?: boolean;
    fieldOptions?: PagedEntityMultiSelectFieldOptions;
    fullWidth?: boolean;
    getEntityModelPage: (searchText: Optional<string>, skip: number, data?: any) => Promise<PagedEntityMultiSelectPage>;
    onSelectedEntityIdsChanged: (entityIds: string[]) => void;
    placeholder: string;
    selectedEntityIds: string[];
};

export class PagedEntityMultiSelectPage {
    constructor(
        public hasMore: boolean,
        public items: Contract.EntityModel[],
        public applyData?: () => any) {
    }
}

export type PagedEntityMultiSelectFieldOptions = Pick<PagedMultiSelectFieldOptions, "dense">;

export function PagedEntityMultiSelect({ disabled = false, fieldOptions, fullWidth, getEntityModelPage, onSelectedEntityIdsChanged, placeholder, selectedEntityIds }: PagedEntityMultiSelectProps) {
    return (
        <PagedSelector
            disabled={disabled}
            fieldOptions={fieldOptions}
            fullWidth={fullWidth}
            getItemPage={
                async (searchText: Optional<string>, skip: number, data?: any) => {
                    const page =
                        await getEntityModelPage(
                            searchText,
                            skip,
                            data);

                    await entityModelStore.notify(page.items);

                    return new PagedDropdownPage(
                        page.hasMore,
                        _.map(
                            page.items,
                            entityModel => entityModel.id),
                        page.applyData);
                }}
            multiSelect={true}
            placeholder={placeholder}
            selectedItems={selectedEntityIds}
            onSelectedItemsChanged={onSelectedEntityIdsChanged}>
            {entityId =>
                <MemoItemRenderer
                    render={
                        entityId =>
                            <Entity
                                entityIdOrModel={entityId}
                                linkOptions={{ disabled: true }}
                                variant="iconTextTypeTenant"/>}
                    renderArguments={[entityId]}/>}
        </PagedSelector>);
}