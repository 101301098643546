﻿import { Link, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, Entity, entityModelStore, InlineEntities, tenantModelStore, useSeverityTranslator, useTheme } from "../../../../../../../../../../../../common";
import { AadDirectoryApplicationCertificatesTable, AzureConsoleUrlBuilder } from "../../../../../../../../../../../../tenants";
import { RiskDefinitionContextItem, RiskDefinitionSection } from "../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { useGetRiskPolicyConfigurationRotationTimeFrameContextItem } from "../../../useGetRiskPolicyConfigurationRotationTimeFrameContextItem";
import { useGetAadDirectoryApplicationRiskContext } from "../contexts";

export function useAadDirectoryApplicationCertificateNotRotatedRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AadDirectoryApplicationCertificateNotRotatedRisk;
    const tenantConfiguration = tenantModelStore.useGet(risk.tenantId).configuration as Contract.AadTenantConfiguration;
    const applicationModel = entityModelStore.useGet(risk.entityId) as Contract.AadDirectoryApplicationModel;

    const getAadDirectoryApplicationRiskContext = useGetAadDirectoryApplicationRiskContext();
    const getRiskPolicyConfigurationRotationTimeFrameContextItem = useGetRiskPolicyConfigurationRotationTimeFrameContextItem();

    const severityTranslator = useSeverityTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aad.hooks.compliance.useAadDirectoryApplicationCertificateNotRotatedRiskDefinition",
            () => ({
                contextItems: {
                    permissions: "{{servicePrincipals}} granting **{{actionSeverity}}** severity permissions on {{tenants}}"
                },
                description: [
                    "{{application}} has {{certificates}} that was not rotated for more than {{certificateRotationTimeFrame | TimeSpanFormatter.humanizeDays}}",
                    "{{application}} has {{certificates}} that were not rotated for more than {{certificateRotationTimeFrame | TimeSpanFormatter.humanizeDays}}"],
                sections: {
                    certificates: "Certificates",
                    resolution: {
                        step1: {
                            link: "Azure key vault",
                            text: "Sign in to {{azureKeyVaultLink}} portal and select the relevant key vault where you want to store your certificate"
                        },
                        step2: "Click **Certificates**, and then click **Generate/Import**",
                        step3: "Enter certificate details, making sure that the validity period meets policy requirements. When finished, click **Create**",
                        step4: "Download the newly created certificate in **.CER** format",
                        step5: "Navigate to **Certificates & secrets** in the app registration, and, under **Certificates**, upload the certificate in **.CER** format",
                        step6: "Before deleting the old certificate, update and verify that your software is properly running with the new certificate credentials",
                        step7: "Delete the old certificate"
                    }
                }
            }));

    const highActionSeverityTenantIds = risk.permissionActionSeverityToTenantIdsMap[Contract.Severity.High] ?? [];
    const criticalActionSeverityTenantIds = risk.permissionActionSeverityToTenantIdsMap[Contract.Severity.Critical] ?? [];

    const theme = useTheme();
    return useCommonSectionsAndDescriptionDefinition(
        localization.description(
            risk.aggregatedEntityIds.length,
            {
                application:
                    <Entity
                        entityIdOrModel={applicationModel}
                        entityTypeNameTranslatorOptions={{ variant: "title" }}
                        variant="typeText"/>,
                certificateRotationTimeFrame: risk.certificateRotationTimeFrame,
                certificates:
                    <InlineEntities
                        entityIdsOrModels={risk.aggregatedEntityIds}
                        entityTypeName={Contract.TypeNames.AadDirectoryApplicationCertificate}
                        variant="itemCountAndType"/>
            }),
        () => [
            localization.sections.resolution.step1.text({
                azureKeyVaultLink:
                    <Link
                        urlOrGetUrl={AzureConsoleUrlBuilder.GetKeyVaultVaultUrl(tenantConfiguration.partitionType)}
                        variant="external">
                        {localization.sections.resolution.step1.link()}
                    </Link>
            }),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4(),
            localization.sections.resolution.step5(),
            localization.sections.resolution.step6(),
            localization.sections.resolution.step7()
        ],
        riskModel,
        () => {
            const applicationRiskContext = getAadDirectoryApplicationRiskContext(applicationModel);
            return [
                applicationRiskContext.generalInformation,
                getRiskPolicyConfigurationRotationTimeFrameContextItem(risk.certificateRotationTimeFrame),
                applicationRiskContext.applicationObjects,
                applicationRiskContext.ownerPrincipals,
                applicationRiskContext.servicePrincipals,
                _.isEmpty(highActionSeverityTenantIds) && _.isEmpty(criticalActionSeverityTenantIds)
                    ? undefined
                    : new RiskDefinitionContextItem(
                        localization.contextItems.permissions({
                            actionSeverity:
                                severityTranslator(
                                    _.isEmpty(criticalActionSeverityTenantIds)
                                        ? Contract.Severity.High
                                        : Contract.Severity.Critical,
                                    "text"),
                            servicePrincipals:
                                <InlineEntities
                                    entityIdsOrModels={applicationModel.servicePrincipalIds}
                                    entityTypeName={Contract.TypeNames.AadDirectoryApplicationServicePrincipal}
                                    variant="itemCountAndType"/>,
                            tenants:
                                <InlineEntities
                                    entityIdsOrModels={
                                        _.concat(
                                            highActionSeverityTenantIds,
                                            criticalActionSeverityTenantIds)}
                                    entityTypeName={Contract.TypeNames.AzureTenantEntity}
                                    variant="itemCountAndType"/>
                        })
                    ),
                applicationRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        },
        {
            sections: [
                new RiskDefinitionSection(
                    <AadDirectoryApplicationCertificatesTable
                        applicationModel={applicationModel}
                        getHighlightColor={
                            (certificateId: string, opacity) =>
                                _.includes(risk.aggregatedEntityIds, certificateId)
                                    ? opacity
                                        ? theme.palette.opacity(theme.palette.severity(risk.severity), opacity)
                                        : theme.palette.severity(risk.severity)
                                    : undefined}/>,
                    localization.sections.certificates(),
                    {
                        expandable: true
                    })
            ]
        });
}