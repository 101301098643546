import { useLocalization, useLocalizeList } from "@infrastructure";
import _ from "lodash";
import { AuditEventDefinition, AuditEventDefinitionContext, AuditEventDefinitionDetailItem } from "..";
import { Contract, TypeHelper } from "../../../../../../../../../common";

export function useJiraInstanceAuditEventDefinition(context: AuditEventDefinitionContext) {
    const localizeList = useLocalizeList();
    const localization =
        useLocalization(
            "views.customer.auditEvents.profile.hooks.useDefinition.hooks.useJiraInstanceAuditEventDefinition",
            () => ({
                jiraInstanceUrl: "Site",
                jiraInstanceUserName: "User name",
                jiraInstanceUserToken: "User token",
                jiraProjectId: "Project ID",
                propertyChanges: "Updated properties",
                severityMappingChanged: "Risk severity to Jira priority mapping",
                statusMappingChanged: "Risk status to Jira status mapping"
            }));

    const jiraInstanceAuditEvent = context.auditEventModel.auditEvent as Contract.JiraInstanceAuditEvent;
    return new AuditEventDefinition(
        _<AuditEventDefinitionDetailItem>([]).
            concat(
                new AuditEventDefinitionDetailItem(
                    localization.jiraInstanceUrl(),
                    jiraInstanceAuditEvent.jiraInstanceUrl),
                new AuditEventDefinitionDetailItem(
                    localization.jiraInstanceUserName(),
                    jiraInstanceAuditEvent.jiraInstanceUserName)).
            concatIf(
                context.auditEventModel.auditEvent.typeName === Contract.TypeNames.JiraInstanceUpdateAuditEvent &&
                (context.auditEventModel.auditEvent as Contract.JiraInstanceUpdateAuditEvent).jiraInstanceUserTokenChanged,
                () => {
                    const properties =
                        _.filter([
                            (jiraInstanceAuditEvent as Contract.JiraInstanceUpdateAuditEvent).jiraInstanceUserNameChanged
                                ? localization.jiraInstanceUserName()
                                : undefined,
                            (jiraInstanceAuditEvent as Contract.JiraInstanceUpdateAuditEvent).jiraInstanceUserTokenChanged
                                ? localization.jiraInstanceUserToken()
                                : undefined
                        ]);
                    return (
                        _<AuditEventDefinitionDetailItem>([]).
                            concatIf(
                                !_.isEmpty(properties),
                                new AuditEventDefinitionDetailItem(
                                    localization.propertyChanges(),
                                    localizeList(properties)!)).
                            value());
                }).
            concatIf(
                context.auditEventModel.auditEvent.typeName == Contract.TypeNames.JiraProjectUpdateAuditEvent,
                () => {
                    const properties =
                        _.filter([
                            (jiraInstanceAuditEvent as Contract.JiraProjectUpdateAuditEvent).jiraProjectSeverityMappingChanged
                                ? localization.severityMappingChanged()
                                : undefined,
                            (jiraInstanceAuditEvent as Contract.JiraProjectUpdateAuditEvent).jiraProjectStatusMappingChanged
                                ? localization.statusMappingChanged()
                                : undefined
                        ]);
                    return (
                        _<AuditEventDefinitionDetailItem>([]).
                            concatIf(
                                !_.isEmpty(properties),
                                new AuditEventDefinitionDetailItem(
                                    localization.propertyChanges(),
                                    localizeList(properties)!)).
                            value());
                }).
            concatIf(
                TypeHelper.extendOrImplement(context.auditEventModel.auditEvent.typeName, Contract.TypeNames.JiraProjectAuditEvent),
                new AuditEventDefinitionDetailItem(
                    localization.jiraProjectId(),
                    (context.auditEventModel.auditEvent as Contract.JiraProjectAuditEvent).jiraProjectId)).
            value());
}