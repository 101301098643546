import { useLocalization } from "@infrastructure";
import { AuditEventDefinition, AuditEventDefinitionContext, AuditEventDefinitionDetailItem } from "..";
import { Contract } from "../../../../../../../../../common";

export function useEntityPropertyDefinitionAuditEventDefinition(context: AuditEventDefinitionContext) {
    const localization =
        useLocalization(
            "views.customer.auditEvents.profile.hooks.useDefinition.hooks.useEntityPropertyDefinitionAuditEventDefinition",
            () => ({
                name: "Name"
            }));

    return new AuditEventDefinition([
        new AuditEventDefinitionDetailItem(
            localization.name(),
            (context.auditEventModel.auditEvent as Contract.EntityPropertyDefinitionAuditEvent).name)
    ]);
}