import { Link, Steps, useLocalization } from "@infrastructure";
import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { useTheme } from "../../../../../../../../../../../../../../../../../../../common";

export function SecretItem() {
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCodePipelineItems.codePipeline.addOrEdit.hooks.useDefinition.hooks.useAzurePipelines.secretItem",
            () => ({
                description: {
                    link: "secret variables",
                    title: "To store the API token securely, it is recommended to use {{secretLink}}, or an alternative secret store."
                },
                steps: {
                    step1: "Log into Azure DevOps and click on **Pipelines**",
                    step2: "Select the relevant pipeline, and then click on **Edit**",
                    step3: "Click on **Variables**",
                    step4: "Click on the **plus sign (+)** or on **New variable**",
                    step5: {
                        step1: "Set **Name** to **TENABLE_API_TOKEN**",
                        step2: "Paste the token created in the previous step as the **Value**",
                        step3: "Check **Keep this value secret**",
                        step4: "Click **OK**",
                        step5: "Click **Save**",
                        title: "To add a variable, follow these steps:"
                    }
                }
            }));

    const theme = useTheme();
    return (
        <Stack
            spacing={2}
            sx={{ maxWidth: theme.spacing(58) }}>
            <Typography>
                {localization.description.title({
                    secretLink:
                        <Link
                            sx={{ textDecorationLine: "underline" }}
                            urlOrGetUrl="https://learn.microsoft.com/en-us/azure/devops/pipelines/process/variables?view=azure-devops&tabs=yaml%2Cbatch#secret-variables"
                            variant="external">
                            {localization.description.link()}
                        </Link>
                })}
            </Typography>
            <Steps variant="letters">
                {localization.steps.step1()}
                {localization.steps.step2()}
                {localization.steps.step3()}
                {localization.steps.step4()}
                <Stack>
                    <Typography>
                        {localization.steps.step5.title()}
                    </Typography>
                    <Box sx={{ paddingLeft: theme.spacing(1) }}>
                        <Steps variant="plainNumbers">
                            {localization.steps.step5.step1()}
                            {localization.steps.step5.step2()}
                            {localization.steps.step5.step3()}
                            {localization.steps.step5.step4()}
                            {localization.steps.step5.step5()}
                        </Steps>
                    </Box>
                </Stack>
            </Steps>
        </Stack>);
}