import { VerticalFillGrid } from "@infrastructure";
import { SxProps } from "@mui/material";
import React, { ReactNode } from "react";

type VerticalFillContainerProps = {
    listElement: ReactNode;
    searchElement?: ReactNode;
    sx?: SxProps;
};

export function VerticalFillContainer({ listElement, searchElement, sx }: VerticalFillContainerProps) {
    return (
        <VerticalFillGrid sx={sx}>
            {searchElement}
            {listElement}
        </VerticalFillGrid>);
}