import { useLocalization } from "@infrastructure";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../..";
import { Contract, InfoItem } from "../../../../../../../../../../common";
import { EntitiesInfoCard, EntitiesInfoItem, Info } from "../../../../components";
import { AwsSsoPrincipalAccountAssignments } from "../../components";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";
import { useAwsDefaultResourceInfoItemElements } from "./useAwsDefaultResourceInfoItemElements";

export function useAwsSsoGroupDefinition(resourceModel: Contract.AwsResourceModel, options?: DefinitionOptions) {
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useAwsDefaultResourceInfoItemElements(resourceModel);
    const group = (resourceModel.entity as Contract.AwsSsoGroup);
    const groupModel = (resourceModel as Contract.AwsSsoGroupModel);

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsSsoGroupDefinition",
            () => ({
                info: {
                    items: {
                        federatedIdentityReference: "Federated Identity",
                        groupId: "Group ID"
                    },
                    members: "Members"
                },
                tabs: {
                    accountAssignments: "Assignments"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <AwsSsoPrincipalAccountAssignments
                    principalModel={groupModel}
                    variant="group"/>,
                localization.tabs.accountAssignments(),
                "accountAssignments")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="groupId"
                        title={localization.info.items.groupId()}
                        value={group.rawId}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={groupModel.federatedIdentityReference?.id}
                        entityTypeName={groupModel.federatedIdentityReference?.typeName ?? Contract.TypeNames.AwsSsoPrincipal}
                        key="federatedIdentityReference"
                        title={localization.info.items.federatedIdentityReference()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={group.userIds}
                        entityTypeName={Contract.TypeNames.AwsSsoUser}
                        key="members"
                        location="miniGlance"
                        title={localization.info.members()}/>
                ]}
                options={options?.infoOptions}>
                <EntitiesInfoCard
                    entityIdsOrModels={group.userIds}
                    entityTypeName={Contract.TypeNames.AwsSsoUser}
                    title={localization.info.members()}/>
            </Info>,
        sensitive: false
    });
}