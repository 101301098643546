import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function CblMarinerIcon(props: SvgIconProps) {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <g clipPath="url(#clip0_52885_254705)">
                <path
                    d="M11.9997 23.2161C9.64249 23.2161 7.38697 22.493 5.47694 21.1252C3.60787 19.7866 2.21155 17.9402 1.43907 15.7857L1.38867 15.645H22.6106L22.5602 15.7857C21.7877 17.9402 20.3915 19.7866 18.5223 21.1252C16.6123 22.493 14.3568 23.2161 11.9997 23.2161Z"
                    fill="#6AD0FF"/>
                <path
                    d="M1.1165 14.7188L1.09709 14.6383C0.88946 13.7772 0.78418 12.8896 0.78418 12C0.78418 9.00396 1.95085 6.18732 4.06935 4.06887C6.18781 1.95036 9.00445 0.783691 12.0004 0.783691C14.9964 0.783691 17.8131 1.95036 19.9315 4.06887C22.05 6.18737 23.2168 9.00401 23.2168 12C23.2168 12.8894 23.1115 13.777 22.9039 14.6383L22.8844 14.7188H1.1165Z"
                    fill="#00A4EF"/>
                <path
                    d="M12 0C5.37253 0 0 5.37253 0 12C0 12.8978 0.09936 13.7722 0.286293 14.6137C0.372107 14.9999 0.476533 15.3791 0.59856 15.7503C2.17344 20.5409 6.6824 24 12 24C17.3176 24 21.8266 20.5409 23.4014 15.7503C23.5235 15.3791 23.6279 14.9999 23.7137 14.6137C23.9006 13.7722 24 12.8978 24 12C24 5.37253 18.6274 0 12 0ZM12 23.1111C7.17968 23.1111 3.07691 20.0413 1.53845 15.7503H22.4616C20.9231 20.0413 16.8203 23.1111 12 23.1111ZM1.19888 14.6137C0.996747 13.7754 0.888907 12.9004 0.888907 12C0.888907 5.86347 5.86347 0.888907 12 0.888907C18.1365 0.888907 23.1111 5.86347 23.1111 12C23.1111 12.9004 23.0033 13.7754 22.8011 14.6137H1.19888Z"
                    fill="white"/>
                <path
                    d="M5.24856 13.9276C5.154 13.9764 4.95581 14.0252 4.70573 14.0252C4.12637 14.0252 3.69336 13.6593 3.69336 12.9823C3.69336 12.3358 4.12941 11.9028 4.76669 11.9028C5.01981 11.9028 5.18445 11.9577 5.25459 11.9944L5.18749 12.2109C5.08989 12.1621 4.94659 12.1254 4.77581 12.1254C4.29405 12.1254 3.97384 12.4334 3.97384 12.9732C3.97384 13.4794 4.26349 13.7995 4.76056 13.7995C4.9252 13.7995 5.08984 13.766 5.19656 13.7141L5.24856 13.9276Z"
                    fill="white"/>
                <path
                    d="M5.58398 11.9671C5.69988 11.9396 5.8859 11.9214 6.06884 11.9214C6.33412 11.9214 6.50489 11.9671 6.6299 12.0708C6.73662 12.1501 6.80372 12.2721 6.80372 12.4337C6.80372 12.6349 6.66953 12.8087 6.45609 12.885V12.8941C6.65124 12.9398 6.87993 13.1015 6.87993 13.4064C6.87993 13.5833 6.8098 13.7205 6.70308 13.8181C6.56281 13.9492 6.33108 14.0102 5.99566 14.0102C5.81268 14.0102 5.67241 13.998 5.58398 13.9858V11.9671ZM5.84926 12.8086H6.09017C6.36766 12.8086 6.53236 12.6593 6.53236 12.4611C6.53236 12.2231 6.35241 12.1256 6.08414 12.1256C5.96212 12.1256 5.89198 12.1348 5.84932 12.1439L5.84926 12.8086ZM5.84926 13.7906C5.90414 13.7997 5.97737 13.8028 6.07188 13.8028C6.34633 13.8028 6.59945 13.7021 6.59945 13.4033C6.59945 13.1258 6.35854 13.0069 6.06884 13.0069H5.84926V13.7906Z"
                    fill="white"/>
                <path
                    d="M7.2334 11.9365H7.50172V13.7691H8.37996V13.9917H7.2334V11.9365Z"
                    fill="white"/>
                <path
                    d="M9.93584 13.0708V13.2659H9.18262V13.0708H9.93584Z"
                    fill="white"/>
                <path
                    d="M12.57 13.0892C12.5548 12.8026 12.5365 12.4549 12.5365 12.2018H12.5304C12.4572 12.4397 12.3749 12.6988 12.2713 12.9824L11.9084 13.9796H11.7072L11.3717 13.0007C11.2741 12.708 11.1948 12.4458 11.1369 12.2018H11.1308C11.1248 12.458 11.1095 12.7995 11.0912 13.1105L11.0363 13.9917H10.7832L10.9266 11.9365H11.265L11.6157 12.9306C11.7011 13.1837 11.7682 13.4093 11.823 13.6228H11.8291C11.884 13.4155 11.9572 13.1898 12.0486 12.9306L12.4146 11.9365H12.7531L12.8811 13.9917H12.6189L12.57 13.0892Z"
                    fill="white"/>
                <path
                    d="M14.2289 13.6379C14.2289 13.766 14.2349 13.891 14.2502 13.9916H14.0092L13.9879 13.8057H13.9788C13.8965 13.9216 13.7378 14.0252 13.5275 14.0252C13.2286 14.0252 13.0762 13.8148 13.0762 13.6014C13.0762 13.2446 13.3933 13.0494 13.9635 13.0525V13.022C13.9635 12.9031 13.93 12.6805 13.6281 12.6835C13.4879 12.6835 13.3445 12.7231 13.2409 12.7933L13.1799 12.6134C13.3018 12.5372 13.4817 12.4854 13.6677 12.4854C14.119 12.4854 14.2288 12.7933 14.2288 13.0861V13.6379H14.2289ZM13.9697 13.2385C13.677 13.2324 13.3445 13.2842 13.3445 13.5708C13.3445 13.7477 13.4604 13.827 13.5946 13.827C13.7897 13.827 13.9148 13.705 13.9575 13.58C13.9666 13.5495 13.9697 13.519 13.9697 13.4946V13.2385Z"
                    fill="white"/>
                <path
                    d="M14.6196 12.9763C14.6196 12.8025 14.6165 12.6531 14.6074 12.5159H14.8422L14.8544 12.8086H14.8635C14.9306 12.6104 15.0953 12.4854 15.2752 12.4854C15.3027 12.4854 15.324 12.4884 15.3484 12.4914V12.7445C15.3209 12.7384 15.2935 12.7384 15.2569 12.7384C15.0678 12.7384 14.9337 12.8787 14.8971 13.0799C14.891 13.1166 14.8879 13.1623 14.8879 13.205V13.9917H14.6196V12.9763Z"
                    fill="white"/>
                <path
                    d="M15.8968 12.1009C15.8968 12.1924 15.8328 12.2656 15.7261 12.2656C15.6285 12.2656 15.5645 12.1924 15.5645 12.1009C15.5645 12.0094 15.6316 11.9331 15.7322 11.9331C15.8298 11.9331 15.8968 12.0063 15.8968 12.1009ZM15.5981 13.9915V12.5156H15.8664V13.9915H15.5981Z"
                    fill="white"/>
                <path
                    d="M16.2808 12.9153C16.2808 12.7598 16.2777 12.6379 16.2686 12.5159H16.5064L16.5217 12.7598H16.5278C16.601 12.6226 16.7716 12.4854 17.0156 12.4854C17.2199 12.4854 17.537 12.6073 17.537 13.1135V13.9917H17.2687V13.141C17.2687 12.9032 17.1803 12.7049 16.9272 12.7049C16.7534 12.7049 16.6161 12.83 16.5674 12.9794C16.5552 13.013 16.5491 13.0586 16.5491 13.1044V13.9918H16.2808V12.9153Z"
                    fill="white"/>
                <path
                    d="M18.0921 13.3026C18.0982 13.6655 18.3269 13.8148 18.5983 13.8148C18.7904 13.8148 18.9093 13.7813 19.0069 13.7386L19.0557 13.9307C18.9612 13.9735 18.7965 14.0252 18.5616 14.0252C18.1073 14.0252 17.8359 13.7234 17.8359 13.2782C17.8359 12.833 18.0982 12.4854 18.5282 12.4854C19.013 12.4854 19.138 12.9062 19.138 13.1776C19.138 13.2324 19.1349 13.2721 19.1288 13.3026H18.0921ZM18.8788 13.1105C18.8819 12.9427 18.8087 12.6775 18.5068 12.6775C18.2324 12.6775 18.1165 12.9244 18.0951 13.1105H18.8788Z"
                    fill="white"/>
                <path
                    d="M19.4399 12.9763C19.4399 12.8025 19.4368 12.6531 19.4277 12.5159H19.6625L19.6747 12.8086H19.6838C19.7509 12.6104 19.9156 12.4854 20.0955 12.4854C20.123 12.4854 20.1443 12.4884 20.1687 12.4914V12.7445C20.1412 12.7384 20.1138 12.7384 20.0772 12.7384C19.8882 12.7384 19.754 12.8787 19.7174 13.0799C19.7113 13.1166 19.7082 13.1623 19.7082 13.205V13.9917H19.4399V12.9763Z"
                    fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0_52885_254705">
                    <rect
                        fill="white"
                        height="24"
                        width="24"/>
                </clipPath>
            </defs>
        </SvgIcon>);
}