import { UnexpectedError, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { useCommonSectionsAndDescriptionDefinition, useGetAwsEc2InstancesRiskContext, useGetAwsTagResourceGroupRiskContext } from "../../../../../..";
import { Contract, entityModelStore, InlineEntities, InlineResourceTags, useEntityTypeNameTranslator } from "../../../../../../../../../../../../../../../common";
import { useGetAwsEc2InstanceMetadataServiceVersionRiskContext } from "../useGetAwsEc2InstanceMetadataServiceVersionRiskContext";
import { useDatabricksInstanceGroupResolutionSteps, useSpotInstanceGroupResolutionSteps } from "./hooks";

export function useAwsEc2InstanceMetadataServiceVersionRiskTagResourceGroupDefinition(riskModel: Contract.AwsEc2InstanceMetadataServiceVersionRiskModel) {
    const tagResourceGroupModel = entityModelStore.useGet(riskModel.risk.entityId) as Contract.AwsTagResourceGroupModel;
    const tagResourceGroup = (tagResourceGroupModel.entity) as Contract.AwsTagResourceGroup;

    const getAwsEc2InstanceMetadataServiceVersionRiskContext = useGetAwsEc2InstanceMetadataServiceVersionRiskContext();
    const getAwsEc2InstancesRiskContext = useGetAwsEc2InstancesRiskContext();
    const getAwsTagResourceGroupRiskContext = useGetAwsTagResourceGroupRiskContext();

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsEc2InstanceMetadataServiceVersionRiskDefinition.hooks.useAwsEc2InstanceMetadataServiceVersionRiskTagResourceGroupDefinition",
            () => ({
                description: [
                    "{{instances}} grouped by {{tags}} is not enforcing Instance Metadata Service Version 2 (IMDSv2)",
                    "{{instances}} grouped by {{tags}} are not enforcing Instance Metadata Service Version 2 (IMDSv2)"
                ]
            }));
    const translatedTypeName =
        entityTypeNameTranslator(
            tagResourceGroupModel.entity.typeName,
            {
                includeServiceName: true,
                variant: "text"
            });

    const useResolutionSteps =
        useMemo(
            () => {
                switch (tagResourceGroupModel.entity.typeName) {
                    case Contract.TypeNames.AwsEc2DatabricksInstanceGroup:
                        return useDatabricksInstanceGroupResolutionSteps;
                    case Contract.TypeNames.AwsEc2SpotInstanceGroup:
                        return useSpotInstanceGroupResolutionSteps;
                    default:
                        throw new UnexpectedError("tagResourceGroupDefinitionConfiguration.typeName", tagResourceGroupModel.entity.typeName);
                }
            },
            [riskModel, tagResourceGroupModel]);

    const resolutionSteps =
        useResolutionSteps(
            riskModel,
            tagResourceGroupModel);

    return useCommonSectionsAndDescriptionDefinition(
        localization.description(
            _.size(riskModel.risk.aggregatedEntityIds),
            {
                instances:
                    <InlineEntities
                        entityIdsOrModels={riskModel.risk.aggregatedEntityIds}
                        entityTypeName={Contract.TypeNames.AwsEc2Instance}
                        variant="itemCountAndType"/>,
                tags:
                    <InlineResourceTags
                        tags={tagResourceGroup.groupTags}
                        textVariant="text"
                        variant="itemCountAndType"/>
            }),
        () => resolutionSteps,
        riskModel,
        () => {
            const instanceMetadataServiceVersionRiskContextItems =
                getAwsEc2InstanceMetadataServiceVersionRiskContext(
                    riskModel,
                    `${translatedTypeName} **${tagResourceGroupModel.entity.displayName}**`);
            const instancesContextItems = getAwsEc2InstancesRiskContext(riskModel.risk.aggregatedEntityIds);
            const tagResourceGroupContextItems = getAwsTagResourceGroupRiskContext(tagResourceGroupModel);
            return [
                tagResourceGroupContextItems.getGroupTagsContextItem(
                    riskModel.risk.aggregatedEntityIds,
                    Contract.TypeNames.AwsEc2Instance),
                instanceMetadataServiceVersionRiskContextItems.instances,
                instanceMetadataServiceVersionRiskContextItems.permissionActions,
                instanceMetadataServiceVersionRiskContextItems.instancePublicAccess,
                instancesContextItems.usedInstances,
                instancesContextItems.runningInstances,
                instancesContextItems.sensitive];
        });
}