import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, EnumValuesFilter, NumberFormatter, useLocalization } from "@infrastructure";
import { Dictionary } from "lodash";
import React from "react";
import { useOciCommonEncryptedResourceDefinition, useOciCommonResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, ItemSelectionHelper, TimeRangeHelper } from "../../../../../../../../../../common";
import { OciObjectStorageBucketObjectAccessLevel, useOciObjectStorageBucketObjectAccessLevelTranslator } from "../../../../../../../../../../tenants";
import { useOciObjectStorageBucketLogCategoryTranslator, useOciObjectStorageBucketStorageTierTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";

export function useOciObjectStorageBucketDefinition(definitionData: DefinitionData) {
    const commonEncryptedResourceDefinition = useOciCommonEncryptedResourceDefinition(definitionData);
    const commonResourceDefinition = useOciCommonResourceDefinition(definitionData);

    const bucketLogCategoryTranslator = useOciObjectStorageBucketLogCategoryTranslator();
    const bucketObjectAccessLevelTranslator = useOciObjectStorageBucketObjectAccessLevelTranslator();
    const bucketStorageTierTranslator = useOciObjectStorageBucketStorageTierTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.oci.useOciObjectStorageBucketDefinition",
            () => ({
                columns: {
                    logCategory: "Object-Level Logging",
                    objectAccessLevel: "Object Access",
                    storageSize: "Storage Size",
                    storageTier: "Default Storage Tier"
                }
            }));

    return new EntityTableDefinition(
        [
            commonResourceDefinition.columns.tenantColumn,
            commonResourceDefinition.columns.creationTimeColumn,
            commonResourceDefinition.columns.creatorCsvColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.OciObjectStorageBucketModel) => ({
                            [localization.columns.storageTier()]: bucketStorageTierTranslator((item.entity as Contract.OciObjectStorageBucket).storageTier)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.OciObjectStorageBucketStorageTier}
                                enumTypeTranslator={bucketStorageTierTranslator}
                                placeholder={localization.columns.storageTier()}/>
                    }
                }}
                id={Contract.EntityModelProperty.OciObjectStorageBucketStorageTier}
                itemProperty={(item: Contract.EntityModel) => bucketStorageTierTranslator((item.entity as Contract.OciObjectStorageBucket).storageTier)}
                key={Contract.EntityModelProperty.OciObjectStorageBucketStorageTier}
                title={localization.columns.storageTier()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.OciObjectStorageBucketModel) => ({
                            [localization.columns.storageSize()]: NumberFormatter.storage((item.entity as Contract.OciObjectStorageBucket).storageSize)
                        })
                }}
                id={Contract.EntityModelProperty.OciObjectStorageBucketStorageSize}
                itemProperty={(item: Contract.OciObjectStorageBucketModel) => NumberFormatter.storage((item.entity as Contract.OciObjectStorageBucket).storageSize)}
                key={Contract.EntityModelProperty.OciObjectStorageBucketStorageSize}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.storageSize()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.OciObjectStorageBucketModel) => ({
                            [localization.columns.objectAccessLevel()]: bucketObjectAccessLevelTranslator((item.entity as Contract.OciObjectStorageBucket).objectAccessLevel)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.OciObjectStorageBucketObjectAccessLevel}
                                enumTypeTranslator={bucketObjectAccessLevelTranslator}
                                placeholder={localization.columns.objectAccessLevel()}/>
                    }
                }}
                id={Contract.EntityModelProperty.OciObjectStorageBucketObjectAccessLevel}
                key={Contract.EntityModelProperty.OciObjectStorageBucketObjectAccessLevel}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.OciObjectStorageBucketModel>) =>
                        <OciObjectStorageBucketObjectAccessLevel
                            objectAccessLevel={(item.entity as Contract.OciObjectStorageBucket).objectAccessLevel}/>}
                title={localization.columns.objectAccessLevel()}/>,
            commonEncryptedResourceDefinition.columns.encryptionExistsColumn,
            commonEncryptedResourceDefinition.columns.encryptionVaultKeyColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.OciObjectStorageBucketModel) => ({
                            [localization.columns.logCategory()]: bucketLogCategoryTranslator(item.logCategory)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.OciObjectStorageBucketLogCategory}
                                enumTypeTranslator={bucketLogCategoryTranslator}
                                placeholder={localization.columns.logCategory()}/>
                    }
                }}
                id={Contract.EntityModelProperty.OciObjectStorageBucketLogCategory}
                itemProperty={(item: Contract.OciObjectStorageBucketModel) => bucketLogCategoryTranslator(item.logCategory)}
                key={Contract.EntityModelProperty.OciObjectStorageBucketLogCategory}
                selectorOptions={{ default: false }}
                title={localization.columns.logCategory()}/>,
            commonResourceDefinition.columns.regionColumn,
            commonResourceDefinition.columns.regionLocationColumn,
            commonResourceDefinition.columns.tagsColumn,
            commonResourceDefinition.columns.attributesColumn,
            commonResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageOciObjectStorageBucketRequest(
                new Contract.EntityControllerGetEntityModelPageOciObjectStorageBucketRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciObjectStorageBucketLogCategory]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciObjectStorageBucketObjectAccessLevel]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciObjectStorageBucketStorageTier])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags])),
                new Contract.EntityControllerGetEntityModelPageOciResourceRequestEncrypted(
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciEncryptedResourceEncryptionExists]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciEncryptedResourceEncryptionVaultKey]))));
}