import { useLocalization } from "@infrastructure";
import { useMemo } from "react";
import { Contract } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetAadDirectoryEntityRiskContext } from "./useGetAadDirectoryEntityRiskContext";

export function useGetAadDirectoryApplicationServicePrincipalRiskContext() {
    return useMemo(
        () => useAadDirectoryApplicationServicePrincipalRiskContext,
        []);
}

function useAadDirectoryApplicationServicePrincipalRiskContext(applicationServicePrincipalModel: Contract.AadDirectoryApplicationServicePrincipalModel) {
    const entityRiskContext = useGetAadDirectoryEntityRiskContext()(applicationServicePrincipalModel);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aad.hooks.contexts.useGetAadDirectoryApplicationServicePrincipalRiskContext",
            () => ({
                vendor: "The application is used by a 3rd party vendor"
            }));

    return {
        ...entityRiskContext,
        vendor:
            (applicationServicePrincipalModel.entity as Contract.AadDirectoryApplicationServicePrincipal).vendor
                ? new RiskDefinitionContextItem(localization.vendor())
                : undefined
    };
}