import { NoneIcon, NumberFormatter } from "@infrastructure";
import { Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { UdmObjectPropertyItemProps } from "../..";

export function InfraByteSize({ item }: UdmObjectPropertyItemProps) {
    return _.isNil(item)
        ? <NoneIcon sx={{ fontSize: "18px" }}/>
        : <Typography noWrap={true}>
            {NumberFormatter.storage(item)}
        </Typography>;
}