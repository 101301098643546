import { TimeSpanFormatter, useLocalization } from "@infrastructure";
import React from "react";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { useGetRiskPolicyConfigurationRetentionTimeFrameContextItem } from "../../../useGetRiskPolicyConfigurationRetentionTimeFrameContextItem";
import { useGetAwsElasticBeanstalkEnvironmentRiskContext } from "../contexts";

export function useAwsElasticBeanstalkEnvironmentCloudWatchLoggingDisabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsElasticBeanstalkEnvironmentCloudWatchLoggingDisabledRisk;
    const environmentModel = entityModelStore.useGet(risk.entityId) as Contract.AwsElasticBeanstalkEnvironmentModel;
    const environment = environmentModel.entity as Contract.AwsElasticBeanstalkEnvironment;

    const getAwsElasticBeanstalkEnvironmentRiskContext = useGetAwsElasticBeanstalkEnvironmentRiskContext();
    const getRiskPolicyConfigurationRetentionTimeFrameContextItem = useGetRiskPolicyConfigurationRetentionTimeFrameContextItem();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsElasticBeanstalkEnvironmentCloudWatchLoggingDisabledRiskDefinition",
            () => ({
                description: {
                    cloudWatchLoggingDisabled: "{{environment}} does not publish logs to CloudWatch Logs",
                    cloudWatchLoggingEnabled: "{{environment}} CloudWatch logs retention days is less than {{cloudWatchLogRetentionTimeFrame}}"
                },
                sections: {
                    resolution: {
                        step1: "Go to **Configuration** tab, scroll down to the **Updates, monitoring, and logging** section and click **Edit**",
                        step2: "In the **Platform software** section, go to **Instance log streaming to CloudWatch logs**",
                        step3: "Under **Log streaming**, check the **Activated** checkbox\n(i) Set the **Retention period** to at least {{retentionTimeFrame}} days",
                        step4: "Continue and save changes"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        (environment.cloudWatchLoggingEnabled
            ? localization.description.cloudWatchLoggingEnabled
            : localization.description.cloudWatchLoggingDisabled)({
            cloudWatchLogRetentionTimeFrame: TimeSpanFormatter.day(risk.cloudWatchLogRetentionTimeFrame),
            environment:
                <Entity
                    entityIdOrModel={environmentModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.ElasticBeanstalk,
                AwsConsoleUrlBuilder.getElasticBeanstalkEnvironmentUrl(environment)),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3({ retentionTimeFrame: TimeSpanFormatter.day(risk.cloudWatchLogRetentionTimeFrame) }),
            localization.sections.resolution.step4()
        ],
        riskModel,
        () => {
            const environmentRiskContext = getAwsElasticBeanstalkEnvironmentRiskContext(environmentModel);
            return [
                environmentRiskContext.generalInformation,
                environmentRiskContext.sensitive,
                getRiskPolicyConfigurationRetentionTimeFrameContextItem(risk.cloudWatchLogRetentionTimeFrame),
                environmentRiskContext.cloudWatchLogRetentionTimeFrame,
                environmentRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}