﻿import { Tooltip, useLocalization } from "@infrastructure";
import { Avatar, Box, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract, JiraIcon, useJiraIssueTranslator, useTheme } from "..";

export type JiraAvatarProps = {
    issuesOrGetIssues: Contract.JiraIssue[] | (() => Promise<Contract.JiraIssue[]>);
};

export function JiraAvatar({ issuesOrGetIssues }: JiraAvatarProps) {
    const jiraIssueTranslator = useJiraIssueTranslator();
    const localization =
        useLocalization(
            "common.jiraAvatar",
            () => ({
                title: [
                    "1 Jira Issue was created for this finding",
                    "{{count | NumberFormatter.humanize}} Jira Issues were created"
                ]
            }));
    const theme = useTheme();
    return (
        <Avatar
            sx={{
                backgroundColor: theme.palette.background.paper,
                height: "18px",
                width: "18px"
            }}
            onClick={event => event.stopPropagation()}>
            <Tooltip
                maxWidth={false}
                titleOrGetTitle={
                    async () => {
                        let issues: Contract.JiraIssue[];
                        if (_.isFunction(issuesOrGetIssues)) {
                            issues = await issuesOrGetIssues();
                        } else {
                            issues = issuesOrGetIssues;
                        }
                        return (
                            <Stack spacing={1}>
                                {issues.length > 1 && (
                                    <Typography variant="h5">
                                        {localization.title(issues.length)}
                                    </Typography>)}
                                <Stack
                                    spacing={1}
                                    sx={{
                                        maxHeight: theme.spacing(60),
                                        overflow: "auto",
                                        padding: theme.spacing(1)
                                    }}>
                                    {_.map(
                                        issues,
                                        issue =>
                                            <Box key={issue.id}>
                                                {jiraIssueTranslator(issue, "link")}
                                            </Box>)}
                                </Stack>
                            </Stack>);
                    }}>
                <JiraIcon sx={{ fontSize: "15px" }}/>
            </Tooltip>
        </Avatar>);
}