﻿import { useLocalization } from "@infrastructure";
import { Contract } from "../../../common";

export function useGcpComputeVpcPeeringConnectionStatusTranslator() {
    const localization =
        useLocalization(
            "tenants.gcp.hooks.useGcpComputeVpcPeeringConnectionStatusTranslator",
            () => ({
                [Contract.TypeNames.GcpComputeVpcPeeringConnectionStatus]: {
                    [Contract.GcpComputeVpcPeeringConnectionStatus.Active]: "Active",
                    [Contract.GcpComputeVpcPeeringConnectionStatus.Inactive]: "Inactive"
                }
            }));
    return (status: Contract.GcpComputeVpcPeeringConnectionStatus) =>
        localization[Contract.TypeNames.GcpComputeVpcPeeringConnectionStatus][status]();
}