import { Step, Steps, useLocalization, useSetOrderedWizardContext } from "@infrastructure";
import React, { useEffect } from "react";
import { InstructionsImageUrl } from "./images";

export function UserItem() {
    const setOrderedWizardContext = useSetOrderedWizardContext();
    useEffect(
        () => {
            setOrderedWizardContext(
                orderedWizardContext => ({
                    ...orderedWizardContext,
                    sideElement: <img src={InstructionsImageUrl}/>
                }));
        },
        []);

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useTicketingItems.serviceNow.addOrEdit.userItem",
            () => ({
                steps: {
                    step1: "Click on \"**Users**\" under \"**Users and Groups**\" section",
                    step2: "Click \"**New**\"",
                    step3: {
                        step1: "Check the \"**Web service access only**\"",
                        step2: "Click \"**Submit**\"",
                        title: "Fill \"**User ID**\", \"**First name**\", and \"**Password**\""
                    }
                }
            }));
    return (
        <Steps variant="letters">
            {localization.steps.step1()}
            {localization.steps.step2()}
            {new Step(
                localization.steps.step3.title(),
                {
                    contentElement:
                        <Steps variant="plain">
                            {localization.steps.step3.step1()}
                            {localization.steps.step3.step2()}
                        </Steps>
                })}
        </Steps>);
}