import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function PrincipalPermissionsIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <g clipPath="url(#clip0_57244_2679)">
                <path
                    clipRule="evenodd"
                    d="M14.8894 2.26933C9.73915 0.889337 4.39133 3.97691 3.01133 9.12711C2.8727 9.64449 2.3409 9.95153 1.82352 9.8129C1.30615 9.67426 0.999111 9.14246 1.13774 8.62509C2.795 2.44012 9.20643 -1.26152 15.3914 0.395737C17.1366 0.863359 18.7489 1.73088 20.1007 2.92961C21.4526 4.12833 22.5067 5.62533 23.1797 7.30206C23.3792 7.79914 23.138 8.36385 22.6409 8.56337C22.1439 8.76289 21.5792 8.52167 21.3796 8.02459C20.8188 6.62732 19.9403 5.37982 18.8138 4.38089C17.6873 3.38195 16.3437 2.65901 14.8894 2.26933Z"
                    fillRule="evenodd"/>
                <path
                    clipRule="evenodd"
                    d="M7.64483 7.21182C8.08758 7.51328 8.20212 8.11657 7.90067 8.55932C7.55934 9.06065 7.29743 9.6187 7.13322 10.2316C6.28471 13.3982 4.90418 16.4532 3.90444 17.9673C3.60932 18.4143 3.00771 18.5374 2.56072 18.2423C2.11373 17.9472 1.99062 17.3456 2.28575 16.8986C3.14482 15.5975 4.45145 12.7457 5.25962 9.72954C5.48196 8.89975 5.83737 8.14324 6.29734 7.46767C6.59879 7.02492 7.20208 6.91037 7.64483 7.21182Z"
                    fillRule="evenodd"/>
                <path
                    clipRule="evenodd"
                    d="M17.0295 18.5107C17.531 18.6987 17.7852 19.2577 17.5971 19.7593C17.3068 20.5337 16.497 22.2408 16.2093 22.8226C15.9719 23.3027 15.3902 23.4995 14.9101 23.2621C14.4299 23.0247 14.2331 22.443 14.4705 21.9629C14.7615 21.3744 15.5294 19.7491 15.7809 19.0783C15.969 18.5767 16.528 18.3226 17.0295 18.5107Z"
                    fillRule="evenodd"/>
                <path
                    clipRule="evenodd"
                    d="M10.8275 8.94954C11.5403 8.53799 12.3874 8.42646 13.1825 8.6395C13.6999 8.77813 14.0069 9.30993 13.8683 9.82731C13.7296 10.3447 13.1978 10.6517 12.6804 10.5131C12.3823 10.4332 12.0646 10.475 11.7973 10.6294C11.53 10.7837 11.335 11.0379 11.2551 11.336C10.9609 12.4338 10.4331 13.9773 9.85055 15.4847C9.65748 15.9843 9.09593 16.2328 8.59631 16.0397C8.09669 15.8467 7.84819 15.2851 8.04127 14.7855C8.61131 13.3104 9.11238 11.8383 9.38149 10.834C9.59453 10.0389 10.1147 9.36108 10.8275 8.94954Z"
                    fillRule="evenodd"/>
                <path
                    clipRule="evenodd"
                    d="M7.71552 18.2345C8.20093 18.4609 8.41088 19.038 8.18444 19.5234C7.90059 20.1319 7.52406 20.7087 7.1947 21.2132C7.1211 21.3259 7.04985 21.4351 6.98252 21.5402C6.69354 21.9912 6.09368 22.1225 5.64269 21.8335C5.1917 21.5445 5.06036 20.9447 5.34934 20.4937C5.43574 20.3589 5.51988 20.2296 5.60141 20.1044C5.92442 19.6084 6.2066 19.175 6.4266 18.7034C6.65304 18.218 7.23011 18.008 7.71552 18.2345Z"
                    fillRule="evenodd"/>
                <path
                    clipRule="evenodd"
                    d="M14.3819 12.4069C14.8993 12.5455 15.2063 13.0773 15.0677 13.5947L15.0648 13.6056C14.7373 14.8276 14.289 16.5009 13.7031 18.1609C13.1207 19.8113 12.3754 21.529 11.4251 22.7991C11.1042 23.228 10.4965 23.3156 10.0676 22.9947C9.63868 22.6739 9.55111 22.0661 9.87197 21.6372C10.6425 20.6073 11.3098 19.1141 11.874 17.5153C12.4331 15.9312 12.8646 14.3223 13.1941 13.0927C13.3327 12.5753 13.8645 12.2683 14.3819 12.4069Z"
                    fillRule="evenodd"/>
                <path
                    clipRule="evenodd"
                    d="M0.0332873 12.747C0.171918 12.2296 0.703719 11.9226 1.2211 12.0612L1.23047 12.0637C1.74784 12.2023 2.05488 12.7341 1.91625 13.2515C1.77762 13.7689 1.24582 14.0759 0.728438 13.9373L0.71907 13.9348C0.201692 13.7961 -0.105344 13.2643 0.0332873 12.747Z"
                    fillRule="evenodd"/>
                <path
                    clipRule="evenodd"
                    d="M23.09 11.3142C23.6246 11.3473 24.0312 11.8074 23.9981 12.342C23.9821 12.6015 23.9039 13.0197 23.8077 13.4664C23.7062 13.9384 23.5682 14.5149 23.4085 15.1288C23.0909 16.35 22.6775 17.7582 22.2806 18.808C22.0912 19.309 21.5315 19.5616 21.0305 19.3722C20.5294 19.1828 20.2768 18.6231 20.4663 18.1221C20.8277 17.1661 21.2209 15.8338 21.5313 14.6406C21.6856 14.0472 21.8168 13.498 21.9115 13.0583C22.0115 12.5933 22.0565 12.3139 22.0621 12.2224C22.0952 11.6878 22.5553 11.2812 23.09 11.3142Z"
                    fillRule="evenodd"/>
                <path
                    clipRule="evenodd"
                    d="M13.7868 6.38741C12.8657 6.14044 11.8958 6.14036 10.9747 6.38719C10.4573 6.52582 9.92547 6.21878 9.78684 5.7014C9.64821 5.18403 9.95524 4.65223 10.4726 4.51359C11.7228 4.17862 13.0391 4.17872 14.2892 4.5139C15.5392 4.84907 16.679 5.5075 17.5939 6.42296C18.5088 7.33842 19.1665 8.47864 19.5009 9.72894C19.8352 10.9792 19.8345 12.2955 19.4987 13.5455C19.3688 14.0304 19.1728 14.7618 18.9197 15.6228C18.7687 16.1367 18.2296 16.4308 17.7157 16.2798C17.2018 16.1287 16.9077 15.5897 17.0588 15.0758C17.305 14.2381 17.4957 13.5266 17.6253 13.0428C17.8727 12.1218 17.8734 11.1513 17.627 10.2301C17.3806 9.30879 16.896 8.46863 16.2219 7.79409C15.5478 7.11954 14.7079 6.63438 13.7868 6.38741Z"
                    fillRule="evenodd"/>
            </g>
            <defs>
                <clipPath id="clip0_57244_2679">
                    <rect
                        height="24"
                        width="24"/>
                </clipPath>
            </defs>
        </SvgIcon>);
}