import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";
import { useTheme } from "../../../..";

export function ExpiredIcon(props: SvgIconProps) {
    const theme = useTheme();
    return (
        <SvgIcon {...props}>
            <path
                clipRule="evenodd"
                d="M0.000425775 11.92C0.0537573 5.36002 5.41357 0 12 0C18.6931 0.0533328 24.0529 5.46665 23.9996 12.08C23.9463 18.64 18.5865 24 12 24H11.92C8.72013 23.9733 5.7069 22.72 3.46697 20.4267C1.20038 18.16 -0.02624 15.12 0.000425775 11.92Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M16.4571 16.4571C16.0666 16.8476 15.4334 16.8476 15.0429 16.4571L11 12.4142V7C11 6.44772 11.4477 6 12 6C12.5523 6 13 6.44772 13 7V11.5858L16.4571 15.0429C16.8476 15.4334 16.8476 16.0666 16.4571 16.4571Z"
                fill={theme.palette.background.paper}
                fillRule="evenodd"/>
        </SvgIcon>);
}