import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, EmptyMessageText, Optional, useLocalization } from "@infrastructure";
import { Box, Divider, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract, InfoCard, ItemTable, useTheme, Vulnerability, vulnerabilityModelStore, VulnerabilitySourceType } from "../../../../../../../../common";
import { TimelineScoreAndEventTrendChart } from "./TimelineScoreAndEventTrendChart";

type OverviewProps = {
    rawId: string;
};

export function Overview({ rawId }: OverviewProps) {
    const vulnerabilityModel = vulnerabilityModelStore.useGet(rawId) as Optional<Contract.VulnerabilityModel>;
    const localization =
        useLocalization(
            "views.customer.workloadAnalysis.vulnerabilities.profile.overview",
            () => ({
                columns: {
                    name: "Name",
                    score: "Score",
                    vector: "Vector",
                    version: "Version"
                },
                empty: "No Items",
                sections: {
                    timeline: "Timeline"
                },
                title: "CVSS Severity Metrics",
                [Contract.TypeNames.VulnerabilityScoreType]: {
                    [Contract.CvssVersion.Cvss2]: "CVSS v2",
                    [Contract.CvssVersion.Cvss3]: "CVSS v3",
                    [Contract.CvssVersion.Cvss31]: "CVSS v3.1"
                }
            }));

    const theme = useTheme();
    return (
        <Box>
            <Vulnerability
                rawId={rawId}
                variant="full"/>
            {!_.isNil(vulnerabilityModel) &&
                <Stack
                    sx={{
                        gap: theme.spacing(2),
                        padding: theme.spacing(0, 2)
                    }}>
                    <Divider/>
                    <InfoCard
                        itemContainerSx={{ paddingBottom: 0 }}
                        title={localization.sections.timeline()}
                        titleSx={{ margin: theme.spacing(0) }}>
                        <TimelineScoreAndEventTrendChart vulnerability={vulnerabilityModel.vulnerability}/>
                    </InfoCard>
                    <Divider/>
                    <InfoCard
                        containerSx={{ gap: theme.spacing(1) }}
                        title={localization.title()}
                        titleSx={{ margin: theme.spacing(0) }}>
                        <ItemTable
                            columnIdToDefaultSortDirectionMap={{
                                [CVSSSeverityMetricsTableColumnId.Version]: "desc"
                            }}
                            columnIdToGetItemValueMap={{
                                [CVSSSeverityMetricsTableColumnId.Name]: item => item.sourceType,
                                [CVSSSeverityMetricsTableColumnId.Version]: item => localization[Contract.TypeNames.VulnerabilityScoreType][item.scoreType](),
                                [CVSSSeverityMetricsTableColumnId.ScoreVector]: item => item.score,
                                [CVSSSeverityMetricsTableColumnId.Vector]: item => item.scoreVector
                            }}
                            defaultSortColumnIdOrIds={[
                                CVSSSeverityMetricsTableColumnId.Name,
                                CVSSSeverityMetricsTableColumnId.Version
                            ]}
                            emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty()) }}
                            getItemId={item => `${item.sourceType}-${item.scoreType}`}
                            items={vulnerabilityModel?.vulnerability.sourceDatas}>
                            {() => [
                                <DataTableColumn
                                    id={CVSSSeverityMetricsTableColumnId.Name}
                                    key={CVSSSeverityMetricsTableColumnId.Name}
                                    render={({ item }: { item: Contract.VulnerabilitySourceData }) => <VulnerabilitySourceType type={item.sourceType}/>}
                                    title={localization.columns.name()}/>,
                                <DataTableColumn
                                    id={CVSSSeverityMetricsTableColumnId.Version}
                                    key={CVSSSeverityMetricsTableColumnId.Version}
                                    render={
                                        ({ item }: DataTableColumnRenderProps<Contract.VulnerabilitySourceData>) =>
                                            <Typography noWrap={true}>
                                                {localization[Contract.TypeNames.VulnerabilityScoreType][item.scoreType]()}
                                            </Typography>}
                                    title={localization.columns.version()}/>,
                                <DataTableColumn
                                    id={CVSSSeverityMetricsTableColumnId.ScoreVector}
                                    itemProperty={item => item.score}
                                    key={CVSSSeverityMetricsTableColumnId.ScoreVector}
                                    sortOptions={{ type: DataTableSortType.Numeric }}
                                    title={localization.columns.score()}/>,
                                <DataTableColumn
                                    id={CVSSSeverityMetricsTableColumnId.Vector}
                                    itemProperty={(item: Contract.VulnerabilitySourceData) => item.scoreVector}
                                    key={CVSSSeverityMetricsTableColumnId.Vector}
                                    title={localization.columns.vector()}/>
                            ]}
                        </ItemTable>
                    </InfoCard>
                </Stack>}
        </Box>);
}

enum CVSSSeverityMetricsTableColumnId {
    Name = "name",
    ScoreVector = "scoreVector",
    Vector = "vector",
    Version = "version"
}