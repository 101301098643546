﻿import { useLocalization } from "@infrastructure";
import React from "react";
import { useAzureCommonKubernetesNamespaceResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../..";
import { Contract } from "../../../../../../../../../../common";
import { KubernetesResourceDataHelper } from "../../../../../../utilities";
import { Info } from "../../../../components";
import { KubernetesRoleBindings, KubernetesYamlInfoCard } from "../../components";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";
import { useAzureDefaultResourceInfoItemElements } from "./useAzureDefaultResourceInfoItemElements";

export function useAzureKubernetesNamespaceRoleDefinition(resourceModel: Contract.AzureResourceModel, options?: DefinitionOptions) {
    const commonKubernetesNamespaceResourceInfoItemElements = useAzureCommonKubernetesNamespaceResourceInfoItemElements(resourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useAzureDefaultResourceInfoItemElements(resourceModel);
    const namespaceRole = resourceModel.entity as Contract.AzureKubernetesNamespaceRole;
    const namespaceRoleModel = resourceModel as Contract.AzureKubernetesNamespaceRoleModel;

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.azure.useAzureKubernetesNamespaceRoleDefinition",
            () => ({
                tabs: {
                    roleBindings: "Role Bindings",
                    yaml: "YAML"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <KubernetesRoleBindings principalOrRoleModel={namespaceRoleModel}/>,
                localization.tabs.roleBindings(),
                "roleBindings"),
            new EntityProfileDefinitionTab(
                ProfileCategory.Overview,
                <KubernetesYamlInfoCard rawYaml={namespaceRole.data.rawYaml!}/>,
                localization.tabs.yaml(),
                "yaml")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    commonKubernetesNamespaceResourceInfoItemElements.annotationsInfoItemElement,
                    commonKubernetesNamespaceResourceInfoItemElements.clusterInfoItemElement,
                    commonKubernetesNamespaceResourceInfoItemElements.namespaceInfoItemElement
                ]}
                options={options?.infoOptions}>
            </Info>,
        sensitive: KubernetesResourceDataHelper.entitySensitiveToggle(namespaceRole.data)
    });
}