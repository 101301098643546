import { DataTable, DataTableColumn, DataTableColumnRenderProps, EmptyMessageText, TimeFormatter, useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract, entityModelStore, InlineEntities, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";

type VersionsProps = {
    versionIds: string[];
};

export function Versions({ versionIds }: VersionsProps) {
    const versionModels = entityModelStore.useGet(versionIds);
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpSecretManagerSecretDefinition.versions",
            () => ({
                table: {
                    columns: {
                        creationTime: "Creation Time",
                        kmsEncryptionKeyReferences: {
                            empty: "Google managed",
                            title: "Encryption"
                        },
                        status: "Status",
                        version: "Version"
                    },
                    empty: "No {{translatedEntityTypeName}}"
                }
            }));
    return (
        <DataTable
            emptyMessageOptions={{
                emptyMessageText:
                    new EmptyMessageText(
                        localization.table.empty({
                            translatedEntityTypeName:
                                entityTypeNameTranslator(
                                    Contract.TypeNames.GcpSecretManagerSecretVersion,
                                    { count: 0 })
                        }))
            }}
            fetchItems={() => versionModels}
            getItemId={(item: Contract.GcpSecretManagerSecretVersionModel) => item.id}
            sortOptions={{ enabled: false }}
            variant="card">
            <DataTableColumn
                id={VersionColumnId.Version}
                itemProperty={(item: Contract.GcpSecretManagerSecretVersionModel) => (item.entity as Contract.GcpSecretManagerSecretVersion).version}
                title={localization.table.columns.version()}/>
            <DataTableColumn
                id={VersionColumnId.CreationTime}
                itemProperty={(item: Contract.GcpSecretManagerSecretVersionModel) => TimeFormatter.monthDayAndYear(item.creationTime)}
                title={localization.table.columns.creationTime()}/>
            <DataTableColumn
                id={VersionColumnId.Status}
                itemProperty={(item: Contract.GcpSecretManagerSecretVersionModel) => (item.entity as Contract.GcpSecretManagerSecretVersion).status}
                title={localization.table.columns.status()}/>
            <DataTableColumn
                id={VersionColumnId.Encryption}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.GcpSecretManagerSecretVersionModel>) =>
                        _.isEmpty(item.kmsEncryptionKeyReferences)
                            ? <Typography>{localization.table.columns.kmsEncryptionKeyReferences.empty()}</Typography>
                            : <InlineEntities
                                entityIdsOrModels={
                                    _.map(
                                        item.kmsEncryptionKeyReferences,
                                        kmsEncryptionKeyReference => kmsEncryptionKeyReference.idReference)}
                                entityTypeName={Contract.TypeNames.GcpKmsKeyRingKey}
                                variant="itemOrItemCountAndType"/>}
                title={localization.table.columns.kmsEncryptionKeyReferences.title()}/>
        </DataTable>);
}

enum VersionColumnId {
    CreationTime = "creationTime",
    Encryption = "encryption",
    Status = "status",
    Version = "version"
}