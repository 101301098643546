import _, { Dictionary } from "lodash";
import { useMemo } from "react";
import { ComplianceHelper, Contract, useBuiltInComplianceTypes } from "../../../../../common";

export function useBuiltInRiskPolicyConfigurationTypeNameToAnalysisGroupTypeToComplianceTypesMap(): Dictionary<Dictionary<Contract.BuiltInComplianceSectionType[]>> {
    const [, enabledBuiltInComplianceTypes] = useBuiltInComplianceTypes();

    return useMemo(
        () =>
            _(enabledBuiltInComplianceTypes).
                flatMap(
                    builtInComplianceType =>
                        _.flatMap(
                            ComplianceHelper.getBuiltInComplianceTypeAnalysisGroupTypeToRiskPolicyConfigurationTypeNamesMap(builtInComplianceType),
                            (riskPolicyConfigurationTypeNames, analysisGroupType) =>
                                riskPolicyConfigurationTypeNames.flatMap(
                                    riskPolicyConfigurationTypeName => ({
                                        analysisGroupType,
                                        riskPolicyConfigurationTypeName,
                                        type: builtInComplianceType
                                    })))).
                groupBy(({ riskPolicyConfigurationTypeName }) => riskPolicyConfigurationTypeName).
                mapValues(
                    riskPolicyConfigurationTypeNameGroup =>
                        _(riskPolicyConfigurationTypeNameGroup).
                            groupBy(({ analysisGroupType }) => analysisGroupType).
                            mapValues(
                                analysisGroupTypeGroup =>
                                    _.map(
                                        analysisGroupTypeGroup,
                                        ({ type }) => type)).
                            value()).
                value(),
        [enabledBuiltInComplianceTypes]);
}