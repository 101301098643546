import { DataTableColumnRenderProps, useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import React from "react";
import { Contract, RiskType } from "../../../../../../../../../common";
import { useDefinition } from "../../../../../hooks";

export function DescriptionCell({ item }: DataTableColumnRenderProps<Contract.RiskModel>) {
    const definition = useDefinition(RiskType.Cloud, item);
    const localization =
        useLocalization(
            "views.customer.risks.items.hooks.useTableCommonCloudColumns.descriptionCell",
            () => ({
                text: "*capitalize*{{description}}**"
            }));
    return (
        <Typography
            sx={{
                "&.MuiTypography-root > span:first-of-type": {
                    display: "-webkit-box",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 1
                }
            }}>
            {localization.text({ description: definition.description })}
        </Typography>);
}