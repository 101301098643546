﻿import _ from "lodash";
import React from "react";
import { FormLayout, makeContextProvider, Optional, OrderedWizard, OrderedWizardItem, useLocalization } from "@infrastructure";
import { useAwsContext, useSetAwsContext } from "../..";
import { Contract, tenantModelStore } from "../../../../../../../../../../../../../../common";
import { FinishItem, MasterTenantItem, OrganizationDetailsItem, OrganizationMemberSelectionItem, PermissionsItem, RoleItem } from "./components";

export class AddOrEditContext {
    public enabled?: boolean;
    public folderEnabled?: boolean;
    public memberSelection?: Contract.OrganizationMemberSelection;
    public name?: string;
    public organizationMemberTenantNames?: string[];
    public permissionTypes?: Contract.CloudProviderTenantPermissionType[];
    public regionSystemNames?: string[];
    public roleName?: string;
    public rootRawId?: string;
    public trailNamePattern?: string;

    constructor(
        public masterTenantModel: Optional<Contract.AwsTenantModel>,
        public organizationConfiguration: Optional<Contract.AwsOrganizationConfiguration>) {
        this.enabled = this.organizationConfiguration?.enabled;
        this.folderEnabled = this.organizationConfiguration?.folderEnabled;
        this.memberSelection = this.organizationConfiguration?.memberSelection;
        this.name = this.organizationConfiguration?.name;
        this.permissionTypes = this.organizationConfiguration?.permissionTypes;
        this.regionSystemNames = this.organizationConfiguration?.regionSystemNames;
        this.roleName = this.organizationConfiguration?.roleName;
        this.trailNamePattern = this.organizationConfiguration?.trailNamePattern;
    }
}

export const [useAddOrEditContext, useSetAddOrEditContext, useAddOrEditContextProvider] = makeContextProvider<AddOrEditContext>();

export function AddOrEdit() {
    const { addOrEditOpen } = useAwsContext();
    const setAwsContext = useSetAwsContext();

    const masterTenantModel =
        tenantModelStore.useGet(
            _.isBoolean(addOrEditOpen)
                ? undefined
                : addOrEditOpen.masterTenantId);

    const [context, , ContextProvider] =
        useAddOrEditContextProvider(
            () =>
                new AddOrEditContext(
                    masterTenantModel as Contract.AwsTenantModel,
                    _.isBoolean(addOrEditOpen)
                        ? undefined
                        : addOrEditOpen));

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCloudProviderTenantOrganizationItems.aws.addOrEdit",
            () => ({
                steps: {
                    configureScopes: "Configure Scopes",
                    masterTenantItem: "Select Management Account",
                    organizationDetailsItem: "Organization Details",
                    permissionsItem: "Permissions",
                    roleItem: "Create an IAM Role"
                },
                title: {
                    add: "Add AWS Organization",
                    edit: "Edit AWS Organization - {{name}}"
                }
            }));

    return (
        <ContextProvider>
            <FormLayout
                disableContentPadding={true}
                titleOptions={{
                    text:
                        _.isNil(context.organizationConfiguration)
                            ? localization.title.add()
                            : localization.title.edit({ name: context.name })
                }}>
                <OrderedWizard
                    finishItemElement={<FinishItem/>}
                    startItemIndex={
                        _.isNil(context.organizationConfiguration)
                            ? undefined
                            : 1}
                    onClose={
                        () =>
                            setAwsContext(
                                awsContext => ({
                                    ...awsContext,
                                    addOrEditOpen: false
                                }))}>
                    <OrderedWizardItem
                        deferredLoading={true}
                        title={localization.steps.masterTenantItem()}>
                        <MasterTenantItem/>
                    </OrderedWizardItem>
                    <OrderedWizardItem
                        deferredLoading={true}
                        title={localization.steps.organizationDetailsItem()}>
                        <OrganizationDetailsItem/>
                    </OrderedWizardItem>
                    <OrderedWizardItem title={localization.steps.permissionsItem()}>
                        <PermissionsItem/>
                    </OrderedWizardItem>
                    <OrderedWizardItem title={localization.steps.roleItem()}>
                        <RoleItem/>
                    </OrderedWizardItem>
                    <OrderedWizardItem
                        deferredLoading={true}
                        title={localization.steps.configureScopes()}>
                        <OrganizationMemberSelectionItem/>
                    </OrderedWizardItem>
                </OrderedWizard>
            </FormLayout>
        </ContextProvider>);
}