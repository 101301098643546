﻿import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, PagedValuesFilter, useLocalization } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { useAzureCommonResourceGroupResourceDefinition } from ".";
import { EntityTableDefinition } from "../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, SeverityFilter, TimeRangeHelper, TypeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { VulnerabilitiesCell } from "../../../../../VulnerabilitiesCell";
import { DefinitionData } from "../../../../Table";

export function useAzureContainerRegistryRepositoryDefinition(definitionData: DefinitionData) {
    const commonResourceGroupResourceDefinition = useAzureCommonResourceGroupResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.azure.useAzureContainerRegistryRepositoryDefinition",
            () => ({
                columns: {
                    containerImageResources: "Used By",
                    containerImageVulnerabilities: "Vulnerabilities",
                    containerImageVulnerabilitySeverities: "Vulnerability Severity",
                    rawUrl: "URI",
                    registry: "Container Registry"
                }
            }));

    return new EntityTableDefinition(
        _.filter([
            commonResourceGroupResourceDefinition.columns.tenantColumn,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.AzureContainerRegistryRepositoryModel>(
                        Contract.TypeNames.AzureContainerRegistryRegistry,
                        item => [(item.entity as Contract.AzureContainerRegistryRepository).parentEntityId],
                        localization.columns.registry())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AzureContainerRegistryRepositoryRegistry)}
                                placeholder={localization.columns.registry()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AzureContainerRegistryRepositoryRegistry}
                key={Contract.EntityModelProperty.AzureContainerRegistryRepositoryRegistry}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AzureContainerRegistryRepositoryModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={(item.entity as Contract.AzureContainerRegistryRepository).parentEntityId}
                            entityTypeName={Contract.TypeNames.AzureContainerRegistryRegistry}/>}
                title={localization.columns.registry()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AzureContainerRegistryRepositoryModel) => ({
                            [localization.columns.rawUrl()]: (item.entity as Contract.AzureContainerRegistryRepository).rawUrl
                        })
                }}
                id={Contract.EntityModelProperty.AzureContainerRegistryRepositoryRawUrl}
                key={Contract.EntityModelProperty.AzureContainerRegistryRepositoryRawUrl}/>,
            <DataTableColumn
                cellMinWidth={118}
                exportOptions={{
                    getItem:
                        (item: Contract.AzureContainerRegistryRepositoryModel) => ({
                            [localization.columns.containerImageVulnerabilities()]:
                                _(item.containerImageVulnerabilities).
                                    orderBy(
                                        [
                                            containerImageVulnerabilityData => TypeHelper.getEnumValue(Contract.TypeNames.Severity, containerImageVulnerabilityData.severity),
                                            containerImageVulnerabilityData => containerImageVulnerabilityData.rawId
                                        ],
                                        [
                                            "desc",
                                            "desc"
                                        ]).
                                    map(containerImageVulnerabilityData => containerImageVulnerabilityData.rawId).
                                    join("\n")
                        })
                }}
                filterOptions={{
                    itemOrItems: [
                        {
                            element:
                                <PagedValuesFilter
                                    getValuePage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.AzureContainerRegistryRepositoryContainerImageVulnerabilities)}
                                    placeholder={localization.columns.containerImageVulnerabilities()}/>,
                            id: Contract.EntityModelProperty.AzureContainerRegistryRepositoryContainerImageVulnerabilities,
                            title: localization.columns.containerImageVulnerabilities()
                        },
                        {
                            element:
                                <SeverityFilter
                                    placeholder={localization.columns.containerImageVulnerabilitySeverities()}/>,
                            id: Contract.EntityModelProperty.AzureContainerRegistryRepositoryContainerImageVulnerabilitySeverities,
                            title: localization.columns.containerImageVulnerabilitySeverities()
                        }
                    ]
                }}
                id={Contract.EntityModelProperty.AzureContainerRegistryRepositoryContainerImageVulnerabilities}
                key={Contract.EntityModelProperty.AzureContainerRegistryRepositoryContainerImageVulnerabilities}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AzureContainerRegistryRepositoryModel>) =>
                        <VulnerabilitiesCell
                            containerImageScanStatus={item.containerImageScanStatus}
                            vulnerabilities={item.containerImageVulnerabilities}/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.containerImageVulnerabilities()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.AzureContainerRegistryRepositoryModel>(
                        Contract.TypeNames.IContainerImageResource,
                        item => item.containerImageResourceIds,
                        localization.columns.containerImageResources())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AzureContainerRegistryRepositoryContainerImageResources)}
                                placeholder={localization.columns.containerImageResources()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AzureContainerRegistryRepositoryContainerImageResources}
                key={Contract.EntityModelProperty.AzureContainerRegistryRepositoryContainerImageResources}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AzureContainerRegistryRepositoryModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.containerImageResourceIds}
                            entityTypeName={Contract.TypeNames.IContainerImageResource}
                            entityVariant="iconText"/>}
                title={localization.columns.containerImageResources()}/>,
            commonResourceGroupResourceDefinition.columns.regionColumn,
            commonResourceGroupResourceDefinition.columns.regionLocationColumn,
            commonResourceGroupResourceDefinition.columns.resourceGroupColumn,
            commonResourceGroupResourceDefinition.columns.tagsColumn,
            commonResourceGroupResourceDefinition.columns.attributesColumn
        ]),
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAzureContainerRegistryRepositoryRequest(
                new Contract.EntityControllerGetEntityModelPageAzureContainerRegistryRepositoryRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureResourceGroupResourceResourceGroup]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureContainerRegistryRepositoryContainerImageResources]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureContainerRegistryRepositoryContainerImageVulnerabilities]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureContainerRegistryRepositoryContainerImageVulnerabilitySeverities]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureContainerRegistryRepositoryRegistry])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags]))));
}