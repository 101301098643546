﻿import { TimeSpanHelper, UnexpectedError } from "@infrastructure";
import _ from "lodash";
import React, { ReactElement } from "react";
import { Contract, ScopeHelper, useScopeNavigationViewContext } from "../../../../../../../../../../common";
import { Approval, Description, ExpirationTimeFrame, GranteePrincipals, Name, PermissionRequestScopes, PrincipalTenant, Reason } from "../../components";
import { TenantDefinition } from "../../useDefinition";
import { PermissionEligibilityData } from "../../utilities";
import { Permissions, PermissionSetNamePrefix } from "./components";
import { AwsSsoPermissionSetAssignmentEligibilityData, AwsSsoPermissionSetAssignmentEligibilityDataPermissionSetPermissions, AwsSsoPermissionSetAssignmentEligibilityDataPolicyPermissions } from "./utilities";

export function useAwsDefinition(): TenantDefinition {
    const { scopeNodeModel } = useScopeNavigationViewContext();

    function createInsertPermissionEligibilityRequest(permissionEligibilityData: PermissionEligibilityData): Contract.ConfigurationControllerInsertPermissionEligibilityRequest {
        const approval = permissionEligibilityData.approval.createConfigurationControllerPermissionEligibilityApproval();
        const { description, expirationTimeFrameHours, granteePrincipalIds, name, permissionRequestScopeIds, permissions, permissionSetNamePrefix, principalTenantId, reasonRequired, scopeId } = permissionEligibilityData as AwsSsoPermissionSetAssignmentEligibilityData;
        const requestExpirationTimeFrame = TimeSpanHelper.fromHours(expirationTimeFrameHours);
        const requestPermissionRequestScopeIds =
            _.isEmpty(permissionRequestScopeIds)
                ? [scopeId]
                : permissionRequestScopeIds;
        switch (permissions.constructor) {
            case AwsSsoPermissionSetAssignmentEligibilityDataPolicyPermissions: {
                const { awsManagedPolicyIds, inlinePolicyDocumentJson } = permissions as AwsSsoPermissionSetAssignmentEligibilityDataPolicyPermissions;
                return new Contract.ConfigurationControllerInsertAwsPolicySsoPermissionSetAssignmentEligibilityRequest(
                    approval,
                    description,
                    requestExpirationTimeFrame,
                    granteePrincipalIds,
                    name,
                    principalTenantId!,
                    reasonRequired,
                    scopeId,
                    requestPermissionRequestScopeIds,
                    permissionSetNamePrefix,
                    awsManagedPolicyIds,
                    inlinePolicyDocumentJson?.trim());
            }
            case AwsSsoPermissionSetAssignmentEligibilityDataPermissionSetPermissions: {
                const { permissionSetId } = permissions as AwsSsoPermissionSetAssignmentEligibilityDataPermissionSetPermissions;
                return new Contract.ConfigurationControllerInsertAwsSsoPermissionSetPermissionSetAssignmentEligibilityRequest(
                    approval,
                    description,
                    requestExpirationTimeFrame,
                    granteePrincipalIds,
                    name,
                    principalTenantId!,
                    reasonRequired,
                    scopeId,
                    requestPermissionRequestScopeIds,
                    permissionSetNamePrefix,
                    permissionSetId!);
            }
            default:
                throw new UnexpectedError(`permissions=${typeof permissions}`);
        }
    }

    function createUpdatePermissionEligibilityRequest(permissionEligibilityData: PermissionEligibilityData, permissionEligibilityId: string): Contract.ConfigurationControllerUpdatePermissionEligibilityRequest {
        const approval = permissionEligibilityData.approval.createConfigurationControllerPermissionEligibilityApproval();
        const { description, expirationTimeFrameHours, granteePrincipalIds, name, permissionRequestScopeIds, permissions, permissionSetNamePrefix, principalTenantId, reasonRequired, scopeId } = permissionEligibilityData as AwsSsoPermissionSetAssignmentEligibilityData;
        const requestExpirationTimeFrame = TimeSpanHelper.fromHours(expirationTimeFrameHours);
        const requestPermissionRequestScopeIds =
            _.isEmpty(permissionRequestScopeIds)
                ? [scopeId]
                : permissionRequestScopeIds;
        switch (permissions.constructor) {
            case AwsSsoPermissionSetAssignmentEligibilityDataPolicyPermissions: {
                const { awsManagedPolicyIds, inlinePolicyDocumentJson } = permissions as AwsSsoPermissionSetAssignmentEligibilityDataPolicyPermissions;
                return new Contract.ConfigurationControllerUpdateAwsPolicySsoPermissionSetAssignmentEligibilityRequest(
                    approval,
                    description,
                    requestExpirationTimeFrame,
                    granteePrincipalIds,
                    permissionEligibilityId,
                    name,
                    principalTenantId!,
                    reasonRequired,
                    requestPermissionRequestScopeIds,
                    permissionSetNamePrefix,
                    awsManagedPolicyIds,
                    inlinePolicyDocumentJson?.trim());
            }
            case AwsSsoPermissionSetAssignmentEligibilityDataPermissionSetPermissions: {
                const { permissionSetId } = permissions as AwsSsoPermissionSetAssignmentEligibilityDataPermissionSetPermissions;
                return new Contract.ConfigurationControllerUpdateAwsSsoPermissionSetPermissionSetAssignmentEligibilityRequest(
                    approval,
                    description,
                    requestExpirationTimeFrame,
                    granteePrincipalIds,
                    permissionEligibilityId,
                    name,
                    principalTenantId!,
                    reasonRequired,
                    requestPermissionRequestScopeIds,
                    permissionSetNamePrefix,
                    permissionSetId!);
            }
            default:
                throw new UnexpectedError(`permissions=${typeof permissions}`);
        }
    }

    return new TenantDefinition(
        createInsertPermissionEligibilityRequest,
        (permissionEligibilityModel, scopeId) =>
            new AwsSsoPermissionSetAssignmentEligibilityData(
                permissionEligibilityModel,
                scopeId),
        createUpdatePermissionEligibilityRequest,
        _<ReactElement>([]).
            concat(
                <Name/>,
                <PermissionSetNamePrefix/>,
                <Description/>,
                <PrincipalTenant/>,
                <GranteePrincipals/>,
                <Permissions/>).
            concatIf(
                ScopeHelper.isFolder(scopeNodeModel),
                () => <PermissionRequestScopes/>).
            concat(
                <ExpirationTimeFrame/>,
                <Approval/>,
                <Reason/>).
            value());
}