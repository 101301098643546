import { Link, useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../../../common";
import { useOciConsoleSignInStepTranslator } from "../../../../../../../../../../../../../../tenants";
import { useGetOciComputeInstanceRiskContext } from "../../../contexts";

export function useOciComputeInstanceMetadataServiceVersionRiskInstanceDefinition(riskModel: Contract.OciComputeInstanceMetadataServiceVersionRiskModel) {
    const risk = riskModel.risk as Contract.OciComputeInstanceMetadataServiceVersionRisk;
    const instanceModel = entityModelStore.useGet(risk.entityId) as Contract.OciComputeInstanceModel;

    const getOciComputeInstanceRiskContext = useGetOciComputeInstanceRiskContext();

    const consoleSignInStepTranslator = useOciConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.oci.hooks.compliance.useOciComputeInstanceMetadataServiceVersionRiskDefinition.hooks.useOciComputeInstanceMetadataServiceVersionRiskInstanceDefinition",
            () => ({
                description: "{{instance}} is not enforcing Instance Metadata Service Version 2 (IMDSv2)",
                sections: {
                    resolution: {
                        step1: {
                            link: "InstanceMetadataRequests metric",
                            text: "Verify that the instance does not use the IMDSv1 endpoints before you disable requests to IMDSv1. If the instance still relies on IMDSv1 when you disable requests to it, you might lose some functionality. To identify whether IMDSv1 is being used, use the {{instanceMetadataServiceVersion1RequestMetricDocumentationLink}}."
                        },
                        step2: {
                            link: "platform images",
                            text: "Make sure the image deployed on the instance supports the new IMDSv2 version. IMDSv2 is supported on the following {{instanceMetadataServiceVersion2SupportedImagesDocumentationLink}}."
                        },
                        step3: "Under **Instance information > Instance details**, click **Edit** next to **Instance metadata service**",
                        step4: "For **Allowed IMDS version**, Select **Version 2 only**",
                        step5: "Click **Save changes**",
                        step6: "For changes to take effect, click **Reboot** to restart the instance"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            instance:
                <Entity
                    entityIdOrModel={instanceModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () =>
            instanceModel.unknown
                ? []
                : [
                    consoleSignInStepTranslator(
                        Contract.OciConsoleView.Compute,
                        instanceModel.consoleUrl!),
                    localization.sections.resolution.step1.text({
                        instanceMetadataServiceVersion1RequestMetricDocumentationLink:
                            <Link
                                urlOrGetUrl={riskModel.instanceMetadataServiceVersion1RequestMetricDocumentationUrl}
                                variant="external">
                                {localization.sections.resolution.step1.link()}
                            </Link>
                    }),
                    localization.sections.resolution.step2.text({
                        instanceMetadataServiceVersion2SupportedImagesDocumentationLink:
                            <Link
                                urlOrGetUrl={riskModel.instanceMetadataServiceVersion2SupportedImagesDocumentationUrl}
                                variant="external">
                                {localization.sections.resolution.step2.link()}
                            </Link>
                    }),
                    localization.sections.resolution.step3(),
                    localization.sections.resolution.step4(),
                    localization.sections.resolution.step5(),
                    localization.sections.resolution.step6()
                ],
        riskModel,
        () => {
            const instanceContextItems = getOciComputeInstanceRiskContext(instanceModel);
            return [
                instanceContextItems.generalInformation,
                instanceContextItems.status,
                instanceContextItems.sensitive,
                instanceContextItems.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}