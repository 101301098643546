import { useExecuteOperation, useLocalization } from "@infrastructure";
import React from "react";
import { CodeController, useLayoutOptions } from "../../../../../../../../common";
import { Empty, Table } from "./components";

export function Scans() {
    const localization =
        useLocalization(
            "views.customer.workloadAnalysis.code.scans",
            () => ({
                title: "Container Image CI/CD Scans"
            }));
    useLayoutOptions({ view: { title: localization.title() } });

    const [{ scanExists }] =
        useExecuteOperation(
            Scans,
            () => CodeController.getContainerImageScanExists());

    return (
        scanExists
            ? <Table/>
            : <Empty/>);
}