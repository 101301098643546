import { Optional, useActions, useLocalization } from "@infrastructure";
import { TOptions } from "i18next";
import React, { Ref, useEffect, useState } from "react";
import { Contract, useEntityTypeNameTranslator } from "../../../../..";
import { CustomRiskPolicyActions, useCustomRiskPolicyContext } from "../../../..";
import { AwsResourceUsageRiskPolicyEdit, EntitySelector, EntitySelectorSelection, InlineEntitySelection } from "../..";

type EditProps = {
    actionsRef: Ref<Optional<CustomRiskPolicyActions>>;
    onValidChange: (valid: boolean) => void;
    riskPolicyConfiguration?: Contract.AwsSecretsManagerSecretManagementRiskPolicyConfiguration;
    templateTranslator: (options?: TOptions) => string;
};

export function Edit({ actionsRef, onValidChange, riskPolicyConfiguration, templateTranslator }: EditProps) {
    const [resourcesValid, setResourcesValid] = useState(false);
    const { scopeId } = useCustomRiskPolicyContext();

    const [secretSelectorSelection, setSecretSelectorSelection] =
        useState<EntitySelectorSelection | undefined>(
            EntitySelectorSelection.getSelectorSelection(
                riskPolicyConfiguration?.allResources,
                undefined,
                undefined,
                riskPolicyConfiguration?.resourceBuiltInAttributeTypeNames,
                undefined,
                riskPolicyConfiguration?.resourceCustomAttributeDefinitionIds,
                undefined,
                riskPolicyConfiguration?.resourceIds,
                undefined,
                riskPolicyConfiguration?.sensitiveSecret,
                riskPolicyConfiguration?.resourceTags,
                undefined));
    useActions(
        actionsRef,
        {
            createRequest:
                (description: Optional<string>, name: string, scopeId: string, severity: Contract.Severity) => {
                    const { allEntities, entityBuiltInAttributeTypeNames, entityCustomAttributeDefinitionIds, entityIds, entityTags, sensitiveEntity } = secretSelectorSelection!.getInlineSelectionData();
                    return new Contract.RiskControllerInsertAwsSecretsManagerSecretManagementRiskPolicyRequest(
                        description,
                        name,
                        scopeId,
                        severity,
                        allEntities ?? false,
                        entityBuiltInAttributeTypeNames,
                        entityCustomAttributeDefinitionIds,
                        entityIds,
                        undefined,
                        entityTags,
                        sensitiveEntity ?? false);
                }
        });

    useEffect(
        () => {
            const resourcesValid = secretSelectorSelection?.valid() ?? false;
            setResourcesValid(resourcesValid);

            onValidChange(resourcesValid);
        },
        [secretSelectorSelection]);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "common.customRiskPolicy.awsSecretsManagerSecretManagementRiskPolicy.edit",
            () => ({
                prompt: "Select the required {{resourceTranslatedTypeName}}"
            }));

    return (
        <AwsResourceUsageRiskPolicyEdit
            accordionContents={[
                {
                    selector:
                        <EntitySelector
                            builtInEntityAttributeTypeNames={[
                                Contract.TypeNames.PublicEntityAttribute,
                                Contract.TypeNames.SensitiveResourceAttribute,
                                Contract.TypeNames.CodeResourceAttribute
                            ]}
                            entityTypeName={Contract.TypeNames.AwsSecretsManagerSecret}
                            properties={["all", "ids", "sensitive", "tags", "attributes"]}
                            scopeId={scopeId}
                            selection={secretSelectorSelection}
                            onSelectionChanged={setSecretSelectorSelection}/>,
                    title:
                        entityTypeNameTranslator(
                            Contract.TypeNames.AwsSecretsManagerSecret,
                            {
                                count: 0,
                                includeServiceName: false
                            }),
                    valid: resourcesValid
                }
            ]}
            prompt={localization.prompt({
                resourceTranslatedTypeName:
                    entityTypeNameTranslator(
                        Contract.TypeNames.AwsSecretsManagerSecret,
                        {
                            count: 0,
                            includeServiceName: false,
                            variant: "text"
                        })
            })}
            template={
                templateTranslator({
                    secrets:
                        <InlineEntitySelection
                            entityTypeName={Contract.TypeNames.AwsSecretsManagerSecret}
                            selection={secretSelectorSelection}/>
                })}/>);
}