import React, { Fragment } from "react";
import { DataAnalysisIcon, EntitiesIcon, EventsIcon, RisksIcon, WorkloadAnalysisIcon } from "../../../../../../../common";
import { PrincipalAccessIcon } from "../../../../../../../common/icons/PrincipalAccessIcon";
import { NetworkIcon } from "../../../../../../../common/icons/RiskPolicyCategoryIcon/icons";
import { CodeIcon } from "../../../../../../../tenants";
import { ProfileCategory } from "../hooks";

type CategoryViewIconProps = {
    category: ProfileCategory;
};

export function CategoryIcon({ category }: CategoryViewIconProps) {
    switch (category) {
        case ProfileCategory.Code:
            return <CodeIcon/>;
        case ProfileCategory.DataAnalysis:
            return <DataAnalysisIcon/>;
        case ProfileCategory.Events:
            return <EventsIcon/>;
        case ProfileCategory.Iam:
            return <PrincipalAccessIcon/>;
        case ProfileCategory.Network:
            return <NetworkIcon/>;
        case ProfileCategory.Overview:
            return <EntitiesIcon/>;
        case ProfileCategory.Risks:
            return <RisksIcon/>;
        case ProfileCategory.WorkloadAnalysis:
            return <WorkloadAnalysisIcon/>;
        default:
            return <Fragment/>;
    }
}