import { InlineItems, Step, Steps, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, Entity, InlineEntities, InlineMaliciousFile } from "../../../../../../../../../../../../../common";
import { MaliciousFiles } from "../../../../../../../../../../WorkloadAnalysis";
import { RiskDefinition, RiskDefinitionSection } from "../../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../../useCommonSectionsAndDescriptionDefinition";
import { InsertTrustedFiles } from "../../../components";
import { useGetVirtualMachineWorkloadAnalysisRiskContext } from "../../contexts";

export function useVirtualMachineMaliciousFileRiskDefinition(riskModel: Contract.RiskModel): RiskDefinition {
    const virtualMachineMaliciousFileRisk = riskModel.risk as Contract.VirtualMachineMaliciousFileRisk;

    const getVirtualMachineWorkloadAnalysisRiskContext = useGetVirtualMachineWorkloadAnalysisRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.workloadAnalysis.hooks.useWorkloadAnalysisDefinition.hooks.useVirtualMachineMaliciousFileRiskDefinition",
            () => ({
                description: {
                    many: "{{riskedEntity}} with {{virtualMachines}} have {{maliciousFiles}}",
                    single: "{{riskedEntity}} has {{maliciousFiles}}"
                },
                maliciousFiles: [
                    "1 suspected malicious file",
                    "{{count | NumberFormatter.humanize}} suspected malicious files"
                ],
                sections: {
                    maliciousFiles: "Malicious Files",
                    resolution: {
                        step2: {
                            step1: "Isolate and Disconnect: Isolate the infected instance from the network, to prevent Malware from spreading to other devices.",
                            step2: "Quarantine: Isolate/quarantine the infected files or software. This prevents the Malware from further executing and causing harm.",
                            step3: "Take a snapshot of the infected instance for analysis and forensic evidence.",
                            step4: "Terminate the infected instance.",
                            title: "Otherwise:"
                        }
                    }
                }
            }));

    const description =
        useMemo(
            () => {
                const descriptionProps = {
                    maliciousFiles:
                        <InlineItems
                            items={
                                _.map(
                                    virtualMachineMaliciousFileRisk.fileContentSha256Strings,
                                    fileContentSha256String =>
                                        <InlineMaliciousFile contentSha256String={fileContentSha256String}/>)}
                            namePluralizer={localization.maliciousFiles}
                            variant="itemCountAndType"/>,
                    riskedEntity:
                        <Entity
                            entityIdOrModel={virtualMachineMaliciousFileRisk.entityId}
                            entityTypeNameTranslatorOptions={{ variant: "title" }}
                            variant="typeText"/>,
                    virtualMachines:
                        <InlineEntities
                            entityIdsOrModels={virtualMachineMaliciousFileRisk.virtualMachineIds}
                            entityTypeName={Contract.TypeNames.IVirtualMachine}
                            variant="itemOrItemCountAndType"/>
                };
                return virtualMachineMaliciousFileRisk.singleVirtualMachine
                    ? localization.description.single(descriptionProps)
                    : localization.description.many(descriptionProps);
            },
            [localization, virtualMachineMaliciousFileRisk]);

    return useCommonSectionsAndDescriptionDefinition(
        description,
        () => [
            <InsertTrustedFiles
                contentSha256Strings={virtualMachineMaliciousFileRisk.fileContentSha256Strings}
                key={riskModel.id}
                tenantId={riskModel.tenantId}/>,
            new Step(
                localization.sections.resolution.step2.title(),
                {
                    contentElement:
                        <Steps variant="plainNumbers">
                            {localization.sections.resolution.step2.step1()}
                            {localization.sections.resolution.step2.step2()}
                            {localization.sections.resolution.step2.step3()}
                            {localization.sections.resolution.step2.step4()}
                        </Steps>
                })
        ],
        riskModel,
        () => {
            const virtualMachineWorkloadAnalysisRiskContext = getVirtualMachineWorkloadAnalysisRiskContext(virtualMachineMaliciousFileRisk);

            return [
                virtualMachineWorkloadAnalysisRiskContext.generalInformation,
                virtualMachineWorkloadAnalysisRiskContext.virtualMachines,
                virtualMachineWorkloadAnalysisRiskContext.getVulnerabilities(),
                virtualMachineWorkloadAnalysisRiskContext.getSeverityVulnerabilities(Contract.Severity.Critical),
                virtualMachineWorkloadAnalysisRiskContext.getSeverityVulnerabilities(Contract.Severity.High),
                virtualMachineWorkloadAnalysisRiskContext.getOpenRiskedEntityRisksContextItem(virtualMachineMaliciousFileRisk.id)
            ];
        },
        {
            sections:
                [
                    new RiskDefinitionSection(
                        <MaliciousFiles
                            entityIds={virtualMachineMaliciousFileRisk.virtualMachineIds}
                            tenantId={riskModel.tenantId}
                            variant="risk"/>,
                        localization.sections.maliciousFiles(),
                        {
                            expandable: true
                        })]
        });
}