﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, Entity, entityModelStore, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetAwsResourceRiskContext } from "./useGetAwsResourceRiskContext";

export function useGetAwsEsDomainRiskContext() {
    return useMemo(
        () => useAwsEsDomainRiskContext,
        []);
}

function useAwsEsDomainRiskContext(domainModel: Contract.AwsEsDomainModel) {
    const domain = domainModel.entity as Contract.AwsEsDomain;
    const resourceRiskContext = useGetAwsResourceRiskContext()(domainModel);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.contexts.useGetAwsEsDomainRiskContext",
            () => ({
                encryption: {
                    text: "The {{translatedDomainTypeName}} is {{encryption}}",
                    type: {
                        kms: "encrypted using KMS key {{key}}",
                        none: "not encrypted"
                    }
                },
                publicAccessHighSeveritySensitivePermissionActions: {
                    all: "The {{translatedDomainTypeName}}'s resource policy grants {{publicAccessHighSeveritySensitivePermissionActions}} with high severity to any principal",
                    none: "The {{translatedDomainTypeName}}'s resource policy grants {{publicAccessPermissionActions}} to any principal, none with high severity",
                    some: "The {{translatedDomainTypeName}}'s resource policy grants {{publicAccessPermissionActions}} to any principal, {{publicAccessHighSeveritySensitivePermissionActions}} with high severity"
                },
                size: "The {{translatedDomainTypeName}}'s size is {{storageSize | NumberFormatter.storage}}",
                vpc: {
                    empty: "The {{translatedDomainTypeName}} has public network access since it is not part of a VPC",
                    text: "The {{translatedDomainTypeName}} is part of VPC {{vpc}}"
                }
            }));
    const translatedDomainTypeName =
        entityTypeNameTranslator(
            domain.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });
    const vpcModel =
        _.isEmpty(domain.vpcIds)
            ? undefined
            : entityModelStore.useGet(domain.vpcIds[0]);

    return {
        ...resourceRiskContext,
        encryption:
            new RiskDefinitionContextItem(
                localization.encryption.text({
                    encryption:
                        _.isNil(domain.storageKmsEncryptionKeyOrAliasArn)
                            ? localization.encryption.type.none()
                            : localization.encryption.type.kms(
                                {
                                    key:
                                        <Entity
                                            entityIdOrModel={domainModel.kmsEncryptionKeyIdReferences[0]}
                                            variant="text"/>
                                }),
                    translatedDomainTypeName
                })
            ),
        publicAccessPermissionActions:
            resourceRiskContext.getPublicAccessPermissionActions(
                domain.publicAccessHighSeveritySensitivePermissionActions,
                domain.publicAccessPermissionActions),
        storageSize:
            new RiskDefinitionContextItem(
                localization.size({
                    storageSize: domain.storageSize,
                    translatedDomainTypeName
                })),
        vpc:
            new RiskDefinitionContextItem(
                (_.isNil(vpcModel)
                    ? localization.vpc.empty
                    : localization.vpc.text)({
                    translatedDomainTypeName,
                    vpc:
                        <Entity
                            entityIdOrModel={vpcModel!}
                            variant="text"/>
                }))

    };
}