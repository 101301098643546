import { AddIcon, getItemWithValidation, useLocalization } from "@infrastructure";
import { Box, Button, Stack } from "@mui/material";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import { Contract, ScopeHelper, ScopeMultiSelect, scopeNodeModelStore, UserHelper, useTheme } from "../../../../../../../../../../../../common";
import { ProjectConfigurationRuleWithValidation } from "../../AddOrEdit";
import { Conditions, MatchingActiveTenants } from "./components";

type ProjectConfigurationRulesProps = {
    onProjectConfigurationRulesChanged: (projectConfigurationRules: ProjectConfigurationRuleWithValidation[]) => void;
    projectConfigurationRules: ProjectConfigurationRuleWithValidation[];
};

export function ProjectConfigurationRules({ onProjectConfigurationRulesChanged, projectConfigurationRules }: ProjectConfigurationRulesProps) {
    const localization =
        useLocalization(
            "views.customer.scopes.hooks.useDefinition.hooks.useProjectsDefinition.addOrEdit.projectConfigurationRules",
            () => ({
                addProjectConfigurationRule: "Scope Group",
                deleteTooltip: {
                    delete: "Delete scope group",
                    reset: "Reset scope group"
                }
            }));

    return (
        <Stack spacing={2}>
            {_.map(
                projectConfigurationRules,
                (projectConfigurationRule, index) => {
                    const deleteDisabled = _.size(projectConfigurationRules) === 1 && _.isEmpty(projectConfigurationRule.scopeIds) && _.isEmpty(projectConfigurationRule.conditions);
                    return (
                        <ProjectConfigurationRule
                            deleteDisabled={deleteDisabled}
                            deleteTooltipTitle={
                                _.size(projectConfigurationRules) === 1 && !deleteDisabled
                                    ? localization.deleteTooltip.reset()
                                    : localization.deleteTooltip.delete()}
                            key={`projectConfigurationRule-${index}`}
                            projectConfigurationRule={projectConfigurationRule}
                            onProjectConfigurationRuleChange={
                                updatedProjectConfigurationRule =>
                                    onProjectConfigurationRulesChanged(
                                        _.map(
                                            projectConfigurationRules,
                                            (projectConfigurationRule, projectConfigurationRuleIndex) =>
                                                projectConfigurationRuleIndex === index
                                                    ? updatedProjectConfigurationRule
                                                    : projectConfigurationRule))}
                            onProjectConfigurationRuleClear={
                                () =>
                                    _.size(projectConfigurationRules) === 1
                                        ? onProjectConfigurationRulesChanged([getItemWithValidation(new Contract.ProjectConfigurationRule([], []), true)])
                                        : onProjectConfigurationRulesChanged(
                                            _.filter(
                                                projectConfigurationRules,
                                                (_, clearIndex) => index !== clearIndex))}/>);
                })}
            <Stack
                direction="row"
                justifyContent="space-between">
                <Button
                    size="small"
                    startIcon={<AddIcon/>}
                    sx={{ width: "fit-content" }}
                    variant="outlined"
                    onClick={
                        () =>
                            onProjectConfigurationRulesChanged([
                                ...projectConfigurationRules,
                                getItemWithValidation(
                                    new Contract.ProjectConfigurationRule([], []),
                                    true)
                            ])}>
                    {localization.addProjectConfigurationRule()}
                </Button>
                {projectConfigurationRules.length > 1 &&
                    <Box>
                        <MatchingActiveTenants projectConfigurationRules={projectConfigurationRules}/>
                    </Box>}
            </Stack>
        </Stack>);
}

type ProjectConfigurationRuleProps = {
    deleteDisabled: boolean;
    deleteTooltipTitle: string;
    onProjectConfigurationRuleChange: (projectConfigurationRule: ProjectConfigurationRuleWithValidation) => void;
    onProjectConfigurationRuleClear: () => void;
    projectConfigurationRule: ProjectConfigurationRuleWithValidation;
};

function ProjectConfigurationRule({ deleteDisabled, deleteTooltipTitle, onProjectConfigurationRuleChange, onProjectConfigurationRuleClear, projectConfigurationRule }: ProjectConfigurationRuleProps) {
    const scopeNodeMap = scopeNodeModelStore.useGetActiveScopeNodeMap();
    const scopeIds =
        useMemo(
            () =>
                _.filter(
                    scopeNodeMap[ScopeHelper.customerId].tenantIds,
                    tenantId =>
                        UserHelper.hasScopePermissions(
                            tenantId,
                            Contract.IdentityPermission.SecurityAdministrationRead)),
            [scopeNodeMap]);
    const [hover, setHover] = useState(false);

    const theme = useTheme();
    return (
        <Stack
            spacing={2}
            sx={{
                border: theme.border.primary,
                borderRadius: theme.spacing(0.75),
                padding: theme.spacing(2)
            }}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}>
            <ScopeMultiSelect
                displayAllAsEmpty={false}
                fullWidth={true}
                scopeIds={scopeIds}
                selectedScopeIds={projectConfigurationRule.scopeIds}
                selectionCompareVariant="organization"
                validateScopeSelection={
                    scopeId =>
                        UserHelper.hasScopePermissions(
                            scopeId,
                            Contract.IdentityPermission.SecurityRead)}
                variant="itemSelector"
                onSelectedScopeIdsChanged={
                    scopeIds =>
                        onProjectConfigurationRuleChange(
                            {
                                ...projectConfigurationRule,
                                scopeIds
                            })}/>
            <Conditions
                deleteDisabled={deleteDisabled}
                deleteTooltipTitle={deleteTooltipTitle}
                deleteVisible={hover}
                projectConfigurationRule={projectConfigurationRule}
                onProjectConfigurationRuleChanged={onProjectConfigurationRuleChange}
                onProjectConfigurationRuleClear={onProjectConfigurationRuleClear}/>
        </Stack>);
}