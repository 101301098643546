import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { Contract, tenantModelStore, usePrincipalModelAccess } from "../../../../../../../../../../common";
import { AwsTenantHelper } from "../../../../../../../../../../tenants";
import { EntitiesInfoCard, Info, PrincipalSecurityStatsStat } from "../../../../components";
import { AwsPrincipalPoliciesInfoCard } from "../../components";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";
import { useAwsDefaultResourceInfoItemElements } from "./useAwsDefaultResourceInfoItemElements";

export function useAwsIamGroupDefinition(resourceModel: Contract.AwsResourceModel, options?: DefinitionOptions) {
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useAwsDefaultResourceInfoItemElements(resourceModel);
    const group = resourceModel.entity as Contract.AwsIamGroup;
    const groupModel = resourceModel as Contract.AwsIamGroupModel;
    const groupModelAccess = usePrincipalModelAccess<Contract.GroupModelAccess>(resourceModel.id);
    const tenantModelMap = tenantModelStore.useGetAwsTenantMap();

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsIamGroupDefinition",
            () => ({
                info: {
                    cards: {
                        userIds: "Users"
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[]}
                options={options?.infoOptions}>
                <AwsPrincipalPoliciesInfoCard
                    inlinePolicyIds={group.inlinePolicyIds}
                    managedPolicyIds={group.managedPolicyIds}/>
                <EntitiesInfoCard
                    entityIdsOrModels={group.userIds}
                    entityTypeName={Contract.TypeNames.AwsIamUser}
                    title={localization.info.cards.userIds()}/>
            </Info>,
        sensitive: false,
        statElements:
            _.filter([
                AwsTenantHelper.isAccessAdvisorEnabled(resourceModel.entity, tenantModelMap) &&
                    _.isNil(groupModelAccess?.permissions)
                    ? undefined
                    : <PrincipalSecurityStatsStat
                        analyzingReason={groupModel.analyzingReason}
                        securityStats={groupModelAccess.permissions!.excessivePermissionActionStats}
                        typeName={Contract.TypeNames.AwsIamGroup}/>
            ])
    });
}