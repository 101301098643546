﻿import { Dialog, ListWelcomeView, makeContextProvider, StringHelper, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, scopeNodeModelStore, scopeSystemEntityModelStore, StorageHelper, useScopeNavigationViewContext } from "../../../../../../../../common";
import { AddOrEdit, Table } from "./components";

export class RiskAutomationsContext {
    constructor(
        public addOrEditOpen: boolean | Contract.AutomationRuleConfiguration,
        public childScopeEnabled: boolean) {
    }
}

export const [useRiskAutomationsContext, useSetRiskAutomationsContext, useRiskAutomationsContextProvider] = makeContextProvider<RiskAutomationsContext>();

export function RiskAutomations() {
    const riskAutomationRuleModels = scopeSystemEntityModelStore.useGetRiskAutomationRule();
    const { scopeNodeModel } = useScopeNavigationViewContext();
    const [context, setContext, ContextProvider] =
        useRiskAutomationsContextProvider(
            () =>
                new RiskAutomationsContext(
                    false,
                    !StringHelper.isFalse(StorageHelper.customerConfigurationAutomationsRiskFlatView.getValue())));
    const scopeNodeMap = scopeNodeModelStore.useGetActiveScopeNodeMap();
    const childScopeIds =
        useMemo(
            () => scopeNodeMap[scopeNodeModel.configuration.id].scopeIds,
            [scopeNodeModel.configuration.id, scopeNodeMap]);
    const filteredRiskAutomationRuleModels =
        useMemo(
            () =>
                _.filter(
                    riskAutomationRuleModels,
                    riskAutomationRuleModel =>
                        riskAutomationRuleModel.configuration.scopeId == scopeNodeModel.configuration.id ||
                        (context.childScopeEnabled && _.includes(childScopeIds, riskAutomationRuleModel.configuration.scopeId))),
            [context.childScopeEnabled, riskAutomationRuleModels, childScopeIds, scopeNodeModel]);

    const localization =
        useLocalization(
            "views.customer.configuration.automations.riskAutomations",
            () => ({
                welcome: "Add Policy-Based Automation"
            }));

    return (
        <ContextProvider>
            {context.addOrEditOpen !== false && (
                <Dialog
                    size="medium"
                    variant="editor"
                    onClose={
                        () => {
                            setContext(
                                automationContext => ({
                                    ...automationContext,
                                    addOrEditOpen: false
                                }));
                        }}>
                    <AddOrEdit/>
                </Dialog>)}
            {_.isEmpty(filteredRiskAutomationRuleModels)
                ? <ListWelcomeView
                    title={localization.welcome()}
                    onStart={
                        () =>
                            setContext(
                                context => ({
                                    ...context,
                                    addOrEditOpen: true
                                }))}/>
                : <Table ruleModels={filteredRiskAutomationRuleModels}/>}
        </ContextProvider>);
}