import { Action0, ContextMenu, ContextMenuItem, Dialog, Dom, entityMiniGlanceContext, Loading, map, NoneIcon, Optional, Shadows, useEntityMiniGlance, useLocalization, useWindowEventEffect } from "@infrastructure";
import { Box, Divider, Popper, Stack, SxProps, Typography } from "@mui/material";
import { Instance as PopperJSInstance } from "@popperjs/core";
import _ from "lodash";
import React, { createContext, Fragment, memo, MouseEvent, ReactNode, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Contract, CustomerConsoleAppUrlHelper, CustomerConsoleAppUrlHelperCategoryView, EntityAttributes, entityModelStore, EntityTypeMetadataModelHelper, EntityTypeNameTranslatorOptions, StorageHelper, TenantNameTranslatorOptions, useEntityTypeNameTranslator, UserHelper, useTheme } from "../..";
import { useEntityProfileDefinition } from "../../../views/Customer/components/Entities";
import { ProfileView } from "../../../views/Customer/components/Entities/components";
import { EntityProfileInfo } from "../../../views/Customer/components/Entities/components/Profile/components";
import { ProfileCategory } from "../../../views/Customer/components/Entities/components/Profile/hooks";
import { Icon, IconTextTypeTenant, Link, Text } from "./components";
import { EntityDefinition, useDefinition } from "./hooks";

const EntityMemo = memo(Entity);
export { EntityMemo as Entity };

type EntityProps = {
    blurred?: boolean;
    entityIdOrModel: string | Contract.EntityModel;
    entityTypeNameTranslatorOptions?: EntityTypeNameTranslatorOptions;
    glanceOptions?: EntityGlanceOptions;
    linkOptions?: EntityLinkOptions;
    onClick?: Action0;
    sxOptions?: EntitySxOptions;
    tenantNameTranslatorOptions?: TenantNameTranslatorOptions;
} & ({
    children: ReactNode;
    variant: "link";
} | {
    children?: never;
    variant: EntityVariant;
});

export type EntityGlanceOptions = {
    disabled?: boolean;
    miniGlancePlacement?: EntityGlanceMiniGlancePlacement;
};

type EntityGlanceMiniGlancePlacement = "center" | "left";

type EntitySxOptions = {
    icon?: SxProps;
    subtitle?: SxProps;
    title?: SxProps;
};

export type EntityLinkOptions = {
    categoryView?: CustomerConsoleAppUrlHelperCategoryView<ProfileCategory, ProfileView>;
    color?: "default" | "inherit";
    disabled?: boolean;
    disabledHighlight?: boolean;
};

export type EntityVariant = "icon" | "iconText" | "iconTextTenant" | "iconTextTypeTenant" | "iconTextTypeTenantTags" | "text" | "typeText" | "textTypeTenant";

const entityContextMenuContext = createContext<Optional<(entityModel: Contract.EntityModel) => ContextMenuItem[] | Promise<ContextMenuItem[]>>>(undefined);

export const entityRelativeUrlContext = createContext(true);

function Entity({ blurred = false, children, entityIdOrModel, entityTypeNameTranslatorOptions, glanceOptions, linkOptions, onClick, sxOptions, tenantNameTranslatorOptions, variant }: EntityProps) {
    const entityTitleElementRef = useRef<HTMLAnchorElement>(null);
    const miniGlanceElementRef = useRef<PopperJSInstance>(null);
    const closeMiniGlanceTimeoutIdRef = useRef<number>();
    const openMiniGlanceTimeoutIdRef = useRef<number>();

    const [miniGlanceOpen, setMiniGlanceOpen] = useState(false);

    const entityMiniGlance = useEntityMiniGlance();
    const entityRelativeUrl = useEntityRelativeUrl();
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "common.entity",
            () => ({
                typeText: "{{translatedEntityTypeName}} {{entity}}"
            }));

    const entityModel =
        entityModelStore.useGet(
            _.isString(entityIdOrModel)
                ? entityIdOrModel
                : undefined) ?? entityIdOrModel as Contract.EntityModel;

    function getUrl() {
        return entityRelativeUrl
            ? CustomerConsoleAppUrlHelper.getEntityProfileRelativeUrl(
                entityModel,
                linkOptions?.categoryView ??
                {
                    category:
                        StorageHelper.
                            customerEntitiesProfileSelectedTab(EntityTypeMetadataModelHelper.get(entityModel.entity.typeName).entitiesViewName).
                            getValue() as ProfileCategory ?? ProfileCategory.Overview
                })
            : CustomerConsoleAppUrlHelper.getEntityProfileUrl(
                entityModel,
                linkOptions?.categoryView ??
                {
                    category: ProfileCategory.Overview,
                    view: ProfileView.Info
                });
    }

    function resetMiniGlance() {
        window.clearTimeout(openMiniGlanceTimeoutIdRef.current);
        openMiniGlanceTimeoutIdRef.current = undefined;
    }

    function tryCloseMiniGlance(event: MouseEvent) {
        if (
            _.includes((event.target as HTMLElement).className, "MuiTooltip") ||
            Dom.isChildrenOf(
                event.target as HTMLElement,
                parentElement =>
                    parentElement === entityTitleElementRef.current ||
                    parentElement === miniGlanceElementRef.current?.state.elements.popper ||
                    _.includes(parentElement.className, "MuiTooltip"))) {
            window.clearTimeout(closeMiniGlanceTimeoutIdRef.current);
            closeMiniGlanceTimeoutIdRef.current = undefined;
            return;
        }

        if (_.isNil(closeMiniGlanceTimeoutIdRef.current)) {
            closeMiniGlanceTimeoutIdRef.current =
                window.setTimeout(
                    () => {
                        setMiniGlanceOpen(false);
                        closeMiniGlanceTimeoutIdRef.current = undefined;
                    },
                    100);
        }

        resetMiniGlance();
    }

    const debounceTryCloseMiniGlance = useMemo(() => _.debounce(tryCloseMiniGlance, 300), []);

    useWindowEventEffect(
        "mousemove",
        debounceTryCloseMiniGlance);
    useWindowEventEffect(
        "wheel",
        debounceTryCloseMiniGlance);

    function onEntityClick() {
        setMiniGlanceOpen(false);
        closeMiniGlanceTimeoutIdRef.current = undefined;
        resetMiniGlance();
        onClick?.();
    }

    function openMiniGlance() {
        if (glanceOptions?.disabled !== true &&
            !entityMiniGlance &&
            !_.isNil(getUrl()) &&
            _.isNil(openMiniGlanceTimeoutIdRef.current)) {
            openMiniGlanceTimeoutIdRef.current =
                window.setTimeout(
                    () => {
                        setMiniGlanceOpen(true);
                        openMiniGlanceTimeoutIdRef.current = undefined;
                    },
                    1000);
        }
    }

    const [glanceOpen, setGlanceOpen] = useState(false);

    const theme = useTheme();
    return (
        <Definition
            entityModel={entityModel}
            key={entityModel.entity.typeName}>
            {definition =>
                <Fragment>
                    {definition.glanceEnabled &&
                        glanceOpen &&
                        <Dialog
                            size="large"
                            variant="viewer"
                            onClick={event => event.stopPropagation()}
                            onClose={() => setGlanceOpen(false)}>
                            <EntityProfileInfo entityId={definition.glanceEntityId}/>
                        </Dialog>}
                    {definition.glanceEnabled &&
                        miniGlanceOpen &&
                        !(window.document.body.classList.contains("col-resize") || window.document.body.classList.contains("col-drag")) &&
                        <entityMiniGlanceContext.Provider value={true}>
                            <Popper
                                anchorEl={entityTitleElementRef.current}
                                modifiers={[
                                    {
                                        enabled: true,
                                        name: "preventOverflow",
                                        options: {
                                            tether: true
                                        }
                                    }
                                ]}
                                open={true}
                                placement={
                                    map(
                                        glanceOptions?.miniGlancePlacement,
                                        {
                                            "center": () => "bottom",
                                            "left": () => "bottom-start"
                                        },
                                        () => "bottom-start")}
                                popperRef={miniGlanceElementRef}
                                sx={{
                                    backgroundColor: theme.palette.background.paper,
                                    border: theme.border.primary,
                                    borderRadius: theme.spacing(0.75),
                                    boxShadow: theme.shadows[Shadows.Tooltip],
                                    width: theme.spacing(36),
                                    zIndex: theme.zIndex.tooltip
                                }}>
                                <Loading container="popup">
                                    <MiniGlance
                                        entityId={definition.glanceEntityId}
                                        onMount={() => miniGlanceElementRef.current?.update()}/>
                                </Loading>
                            </Popper>
                        </entityMiniGlanceContext.Provider>}
                    {map(
                        variant,
                        {
                            "icon":
                                () =>
                                    <Icon
                                        blurred={blurred}
                                        definition={definition}
                                        linkOptions={linkOptions}
                                        linkRef={entityTitleElementRef}
                                        sx={{
                                            fontSize: "18px",
                                            ...sxOptions?.icon
                                        }}
                                        urlOrGetUrl={getUrl}
                                        onClick={onEntityClick}
                                        onMouseEnter={openMiniGlance}
                                        onMouseLeave={resetMiniGlance}/>,
                            "iconText":
                                () =>
                                    <IconTextTypeTenant
                                        definition={definition}
                                        iconSx={{
                                            fontSize: "18px",
                                            ...sxOptions?.icon
                                        }}
                                        linkOptions={linkOptions}
                                        showTags={false}
                                        showTenant={false}
                                        showType={false}
                                        subtitleSx={sxOptions?.subtitle}
                                        tenantNameTranslatorOptions={tenantNameTranslatorOptions}
                                        titleRef={entityTitleElementRef}
                                        titleSx={sxOptions?.title}
                                        urlOrGetUrl={getUrl}
                                        onClick={onEntityClick}
                                        onTitleMouseEnter={openMiniGlance}
                                        onTitleMouseLeave={resetMiniGlance}/>,
                            "iconTextTenant":
                                () =>
                                    <IconTextTypeTenant
                                        definition={definition}
                                        iconSx={{
                                            fontSize: "18px",
                                            ...sxOptions?.icon
                                        }}
                                        linkOptions={linkOptions}
                                        showTags={false}
                                        showTenant={true}
                                        showType={false}
                                        subtitleSx={sxOptions?.subtitle}
                                        tenantNameTranslatorOptions={tenantNameTranslatorOptions}
                                        titleRef={entityTitleElementRef}
                                        titleSx={sxOptions?.title}
                                        urlOrGetUrl={getUrl}
                                        onClick={onEntityClick}
                                        onTitleMouseEnter={openMiniGlance}
                                        onTitleMouseLeave={resetMiniGlance}/>,
                            "iconTextTypeTenant":
                                () =>
                                    <IconTextTypeTenant
                                        definition={definition}
                                        iconSx={{
                                            fontSize: "18px",
                                            ...sxOptions?.icon
                                        }}
                                        linkOptions={linkOptions}
                                        showTags={false}
                                        showTenant={true}
                                        showType={true}
                                        subtitleSx={{
                                            fontSize: "11px",
                                            ...sxOptions?.subtitle
                                        }}
                                        tenantNameTranslatorOptions={tenantNameTranslatorOptions}
                                        titleRef={entityTitleElementRef}
                                        titleSx={{
                                            fontSize: "11px",
                                            ...sxOptions?.title
                                        }}
                                        urlOrGetUrl={getUrl}
                                        onClick={onEntityClick}
                                        onTitleMouseEnter={openMiniGlance}
                                        onTitleMouseLeave={resetMiniGlance}/>,
                            "iconTextTypeTenantTags": () =>
                                <IconTextTypeTenant
                                    definition={definition}
                                    iconSx={{
                                        fontSize: "18px",
                                        ...sxOptions?.icon
                                    }}
                                    linkOptions={linkOptions}
                                    showTags={true}
                                    showTenant={true}
                                    showType={true}
                                    subtitleSx={sxOptions?.subtitle}
                                    tenantNameTranslatorOptions={tenantNameTranslatorOptions}
                                    titleRef={entityTitleElementRef}
                                    titleSx={sxOptions?.title}
                                    urlOrGetUrl={getUrl}
                                    onClick={onEntityClick}
                                    onTitleMouseEnter={openMiniGlance}
                                    onTitleMouseLeave={resetMiniGlance}/>,
                            "link": () =>
                                <Typography
                                    component="span"
                                    ref={entityTitleElementRef}
                                    sx={{
                                        color: "unset",
                                        fontSize: "unset",
                                        fontWeight: "unset"
                                    }}>
                                    <Link
                                        linkOptions={linkOptions}
                                        sx={{
                                            fontSize: "unset",
                                            fontWeight:
                                                linkOptions?.disabledHighlight != false &&
                                                (linkOptions?.disabled || _.isNil(getUrl))
                                                    ? 600
                                                    : undefined,
                                            ...sxOptions?.title
                                        }}
                                        urlOrGetUrl={getUrl}
                                        onClick={onEntityClick}
                                        onMouseEnter={openMiniGlance}
                                        onMouseLeave={resetMiniGlance}>
                                        {children}
                                    </Link>
                                </Typography>,
                            "text": () =>
                                <Typography
                                    component="span"
                                    ref={entityTitleElementRef}
                                    sx={{
                                        color: "unset",
                                        fontSize: "unset",
                                        fontWeight: "unset"
                                    }}>
                                    <Link
                                        linkOptions={linkOptions}
                                        sx={{
                                            fontSize: "unset",
                                            fontWeight:
                                                linkOptions?.disabledHighlight != false &&
                                                (linkOptions?.disabled || _.isNil(getUrl))
                                                    ? 600
                                                    : undefined,
                                            ...sxOptions?.title
                                        }}
                                        urlOrGetUrl={getUrl}
                                        onClick={onEntityClick}
                                        onMouseEnter={openMiniGlance}
                                        onMouseLeave={resetMiniGlance}>
                                        {_.truncate(
                                            entityModel.unknown
                                                ? definition.displayName
                                                : definition.textDisplayName,
                                            { length: 200 })}
                                    </Link>
                                </Typography>,
                            "textTypeTenant": () =>
                                <Text
                                    definition={definition}
                                    linkOptions={linkOptions}
                                    showTags={false}
                                    showTenant={true}
                                    showType={true}
                                    subtitleSx={{
                                        fontSize: "unset",
                                        ...sxOptions?.subtitle
                                    }}
                                    tenantNameTranslatorOptions={tenantNameTranslatorOptions}
                                    titleRef={entityTitleElementRef}
                                    titleSx={{
                                        fontSize: "unset",
                                        ...sxOptions?.title
                                    }}
                                    urlOrGetUrl={getUrl}
                                    onClick={onEntityClick}
                                    onTitleMouseEnter={openMiniGlance}
                                    onTitleMouseLeave={resetMiniGlance}/>,
                            "typeText": () =>
                                <Typography
                                    component="span"
                                    ref={entityTitleElementRef}>
                                    {localization.typeText({
                                        entity:
                                            <Link
                                                linkOptions={linkOptions}
                                                sx={{
                                                    fontSize: "unset",
                                                    fontWeight:
                                                        linkOptions?.disabledHighlight != false &&
                                                        (linkOptions?.disabled || _.isNil(getUrl))
                                                            ? 600
                                                            : undefined,
                                                    ...sxOptions?.title
                                                }}
                                                urlOrGetUrl={getUrl}
                                                onClick={onEntityClick}
                                                onMouseEnter={openMiniGlance}
                                                onMouseLeave={resetMiniGlance}>
                                                {_.truncate(definition.displayName, { length: 200 })}
                                            </Link>,
                                        translatedEntityTypeName:
                                            definition.translatedEntityTypeName ??
                                            entityTypeNameTranslator(
                                                definition.typeName,
                                                {
                                                    includeServiceName: false,
                                                    variant: "text",
                                                    ...entityTypeNameTranslatorOptions
                                                })
                                    })}
                                </Typography>
                        })}
                </Fragment>}
        </Definition>);
}

type MiniGlanceProps = {
    entityId: string;
    onMount: Action0;
};

function MiniGlance({ entityId, onMount }: MiniGlanceProps) {
    const entityModel = entityModelStore.useGet(entityId);

    const theme = useTheme();
    const entityProfileDefinition =
        useEntityProfileDefinition(
            entityModel,
            {
                infoOptions: {
                    itemOptions: {
                        titleSx: { fontWeight: 500 },
                        valueSx: {
                            color: theme.palette.text.primary,
                            fontWeight: 500
                        }
                    },
                    variant: "miniGlance"
                }
            });

    useEffect(
        () => {
            onMount();
        },
        []);

    const localization =
        useLocalization(
            "common.entity.miniGlance",
            () => ({
                attributes: "Labels"
            }));

    return (
        <Stack
            onClick={event => event.stopPropagation()}
            onContextMenu={event => event.stopPropagation()}>
            <Box
                sx={{
                    flex: 1,
                    padding: theme.spacing(1.5)
                }}>
                <Entity
                    entityIdOrModel={entityModel}
                    glanceOptions={{ disabled: true }}
                    linkOptions={{ disabled: true }}
                    variant="iconTextTypeTenant"/>
            </Box>
            <Divider flexItem={true}/>
            <Box
                sx={{
                    maxHeight: 500,
                    overflowY: "auto",
                    padding: theme.spacing(2)
                }}>
                {entityProfileDefinition.options.infoElement}
                {
                    UserHelper.hasAnyScopePermissions(entityModel.entity.scopeIds, Contract.IdentityPermission.SecurityRead) &&
                    !_.isEmpty(entityModel.attributes.attributes) &&
                    <Stack
                        spacing={1}
                        sx={{ marginTop: theme.spacing(1) }}>
                        <Typography sx={{ fontWeight: 500 }}>
                            {localization.attributes()}
                        </Typography>
                        <Box>
                            <EntityAttributes
                                entityAttributes={entityModel.attributes.attributes}
                                entityTypeName={entityModel.entity.typeName}
                                orderVariant="leftToRight"
                                variant="wrap"/>
                        </Box>
                    </Stack>}
            </Box>
        </Stack>);
}

type EntityContextMenuContextProviderProps = {
    children: ReactNode;
    getContextMenuItems: Optional<(entityModel: Contract.EntityModel) => ContextMenuItem[] | Promise<ContextMenuItem[]>>;
};

export function EntityContextMenuContextProvider({ children, getContextMenuItems }: EntityContextMenuContextProviderProps) {
    return (
        <entityContextMenuContext.Provider value={getContextMenuItems}>
            {children}
        </entityContextMenuContext.Provider>);
}

export function useEntityRelativeUrl() {
    const relativeUrl = useContext(entityRelativeUrlContext);
    return relativeUrl;
}

type DefinitionProps = {
    children: (definition: EntityDefinition) => ReactNode;
    entityModel: Contract.EntityModel;
};

export function Definition({ children, entityModel }: DefinitionProps) {
    const definition = useDefinition(entityModel);
    const getContextMenuItems = useContext(entityContextMenuContext);
    return (
        <ContextMenu
            getMenuItems={getContextMenuItems}
            item={entityModel}>
            {children(definition)}
        </ContextMenu>);
}

export function NoneDefinition() {
    const getContextMenuItems = useContext(entityContextMenuContext);

    return (
        <ContextMenu
            getMenuItems={getContextMenuItems}
            item={undefined}>
            <NoneIcon sx={{ fontSize: "18px" }}/>
        </ContextMenu>);
}