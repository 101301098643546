import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function EntitiesIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                clipRule="evenodd"
                d="M8.81779 0.383223C9.89948 -0.12774 11.1531 -0.127741 12.2348 0.383222L18.761 3.46609C20.1601 4.12699 21.0526 5.53554 21.0526 7.08287V14.4501C21.0526 15.8791 20.2902 17.1996 19.0526 17.9142L12.5263 21.6821C11.2887 22.3967 9.76388 22.3967 8.52628 21.6821L2 17.9142C0.762396 17.1996 0 15.8791 0 14.4501V7.08287C0 5.53554 0.892427 4.12699 2.29152 3.46609L8.81779 0.383223ZM11.3805 2.19161C10.8397 1.93613 10.2129 1.93613 9.67204 2.19161L3.14576 5.27448C2.44621 5.60493 2 6.3092 2 7.08287V14.4501C2 15.1646 2.3812 15.8249 3 16.1821L9.52628 19.9501C10.1451 20.3073 10.9075 20.3073 11.5263 19.9501L18.0526 16.1821C18.6714 15.8249 19.0526 15.1646 19.0526 14.4501V7.08287C19.0526 6.3092 18.6063 5.60493 17.9068 5.27448L11.3805 2.19161Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M20.7945 6.06521L10.5304 11.2943L0.0790041 6.0686L0.973431 4.27975L10.522 9.05403L19.8866 4.28315L20.7945 6.06521Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M9.33984 21.6367V10.6367H11.3398V21.6367H9.33984Z"
                fillRule="evenodd"/>
        </SvgIcon>);
}