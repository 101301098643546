import { FormLayout, makeContextProvider, OrderedWizard, OrderedWizardItem, useExecuteOperation, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { GciTenantsContext, useGciTenantsContext, useSetGciTenantsContext } from "../..";
import { Contract, TenantController } from "../../../../../../../../../../../../../../common";
import { FinishItem, PermissionsItem, ServiceAccountItem, TenantItem } from "./components";

class AddOrEditContext {
    public tenantRawId: string;
    public tenantServiceAccountMail: string;
    public tenantUserMail: string;
    public updatedTenantModel?: Contract.GciTenantModel;

    constructor(
        public tenantOnboardingInfo: Contract.TenantControllerGetGciTenantOnboardingInfoResponse,
        public tenantConfiguration?: Contract.GciTenantConfiguration) {
        this.tenantRawId = tenantConfiguration?.rawId ?? "";
        this.tenantServiceAccountMail = tenantConfiguration?.serviceAccountMail ?? "";
        this.tenantUserMail = tenantConfiguration?.userMail ?? "";
    }
}

export const [useAddOrEditContext, useSetAddOrEditContext, useAddOrEditContextProvider] = makeContextProvider<AddOrEditContext>();

export function AddOrEdit() {
    const { addOrEditOpen } = useGciTenantsContext();
    const tenantConfiguration =
        _.isBoolean(addOrEditOpen)
            ? undefined
            : addOrEditOpen;

    const [tenantOnboardingInfo] =
        useExecuteOperation(
            [AddOrEdit, tenantConfiguration?.id],
            TenantController.getGciTenantOnboardingInfo);

    const setGciTenantsContext = useSetGciTenantsContext();
    const [, , ContextProvider] =
        useAddOrEditContextProvider(
            () =>
                new AddOrEditContext(
                    tenantOnboardingInfo,
                    tenantConfiguration));
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useIdentityProviderTenantItems.gciTenants.addOrEdit",
            () => ({
                steps: {
                    permissions: "Configure Domain Wide Delegation",
                    serviceAccount: "Create a Service Account for Tenable Cloud Security",
                    tenant: "Provide Organization Details"
                },
                title: {
                    add: "Add Google Workspace",
                    edit: "Edit Google Workspace"
                }
            }));
    return (
        <ContextProvider>
            <FormLayout
                disableContentPadding={true}
                titleOptions={{
                    text:
                        _.isNil(tenantConfiguration)
                            ? localization.title.add()
                            : localization.title.edit()
                }}>
                <OrderedWizard
                    finishItemElement={<FinishItem/>}
                    startItemIndex={
                        _.isNil(tenantConfiguration)
                            ? 0
                            : 2}
                    onClose={() => setGciTenantsContext(new GciTenantsContext(false))}>
                    <OrderedWizardItem title={localization.steps.serviceAccount()}>
                        <ServiceAccountItem/>
                    </OrderedWizardItem>
                    <OrderedWizardItem title={localization.steps.permissions()}>
                        <PermissionsItem/>
                    </OrderedWizardItem>
                    <OrderedWizardItem
                        deferredLoading={true}
                        title={localization.steps.tenant()}>
                        <TenantItem/>
                    </OrderedWizardItem>
                </OrderedWizard>
            </FormLayout>
        </ContextProvider>);
}