import { UnexpectedError } from "@infrastructure";
import { useMemo } from "react";
import { Contract, entityModelStore } from "../../../../../../../../../../../../../common";
import { RiskDefinition } from "../../../../../../../utilities";
import { useGcpPrincipalServiceAccountWideScopeAdministratorRoleRiskGciDirectoryGroupDefinition, useGcpPrincipalServiceAccountWideScopeAdministratorRoleRiskPartialServiceAccountDefinition, useGcpPrincipalServiceAccountWideScopeAdministratorRoleRiskServiceAccountDefinition } from "./hooks";

export function useGcpPrincipalServiceAccountWideScopeAdministratorRoleRiskDefinition(riskModel: Contract.RiskModel): RiskDefinition {
    const principalModel = entityModelStore.useGet((riskModel.risk as Contract.GcpPrincipalServiceAccountWideScopeAdministratorRoleRisk).entityId);
    const useDefinition =
        useMemo(
            () => {
                switch (principalModel.entity.typeName) {
                    case Contract.TypeNames.GciDirectoryGroup:
                        return useGcpPrincipalServiceAccountWideScopeAdministratorRoleRiskGciDirectoryGroupDefinition;
                    case Contract.TypeNames.GcpIamPartialServiceAccount:
                        return useGcpPrincipalServiceAccountWideScopeAdministratorRoleRiskPartialServiceAccountDefinition;
                    case Contract.TypeNames.GcpIamServiceAccount:
                        return useGcpPrincipalServiceAccountWideScopeAdministratorRoleRiskServiceAccountDefinition;
                    default:
                        throw new UnexpectedError("principalModel.entity.typeName", principalModel.entity.typeName);
                }
            },
            []);

    return useDefinition(riskModel as Contract.GcpPrincipalServiceAccountWideScopeAdministratorRoleRiskModel);
}