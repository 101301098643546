import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../../../common";
import { RiskDefinitionSection } from "../../../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../../../useCommonSectionsAndDescriptionDefinition";
import { ResourcesExternalConsoleLink } from "../../../../components";
import { useGetGcpCloudRunServiceRiskContext, useGetGcpScopeResourcePublicAccessExistsRiskContext } from "../../../contexts";
import { ScopeResourceRoleBindingTableSection } from "../components";

export function useGcpCloudRunServicePublicAccessExistsRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.GcpCloudRunServicePublicAccessExistsRisk;
    const serviceModel = entityModelStore.useGet(risk.entityId) as Contract.GcpCloudRunServiceModel;

    const getGcpCloudRunServiceRiskContext = useGetGcpCloudRunServiceRiskContext();
    const getGcpScopeResourcePublicAccessExistsRiskContext = useGetGcpScopeResourcePublicAccessExistsRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.publicAccess.hooks.useGcpCloudRunServicePublicAccessExistsRiskDefinition",
            () => ({
                description: {
                    invocationOnly: "{{service}} allows anonymous invocation",
                    notInvocationOnly: "{{service}} is publicly accessible via IAM roles"
                },
                sections: {
                    resolution: {
                        step1: "In the services list, check the service **{{service}}** and go the **Permissions** tab on the right pane",
                        step2: "Before changing the permissions, verify that identities can access the service without public access",
                        step3: "Delete the role bindings to allUsers / allAuthenticatedUsers"
                    },
                    roleBindingIds: "Role Bindings"
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        (risk.invocationOnly
            ? localization.description.invocationOnly
            : localization.description.notInvocationOnly)({
            service:
                <Entity
                    entityIdOrModel={serviceModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            <ResourcesExternalConsoleLink
                key={risk.tenantId}
                tenantId={risk.tenantId}
                typeName={serviceModel.entity.typeName}/>,
            localization.sections.resolution.step1({
                service:
                    <Entity
                        entityIdOrModel={risk.entityId}
                        glanceOptions={{ disabled: true }}
                        linkOptions={{ disabled: true }}
                        variant="text"/>
            }),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3()
        ],
        riskModel,
        () => {
            const serviceRiskContext = getGcpCloudRunServiceRiskContext(serviceModel);
            const scopeResourcePublicAccessExistsRiskContext = getGcpScopeResourcePublicAccessExistsRiskContext(risk);
            return [
                serviceRiskContext.generalInformation,
                serviceRiskContext.sensitive,
                serviceRiskContext.networkInboundAccessType,
                scopeResourcePublicAccessExistsRiskContext.permissionActions,
                serviceRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        },
        {
            sections:
                risk.invocationOnly
                    ? []
                    : [
                        new RiskDefinitionSection(
                            <ScopeResourceRoleBindingTableSection
                                csvExportFilePrefixes={[localization.sections.roleBindingIds()]}
                                risk={risk}/>,
                            localization.sections.roleBindingIds())
                    ]
        });
}