import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function OciFolderIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                d="M8.62775 0.690138C8.24787 0.251813 7.6964 0 7.11637 0H3.11852C1.95333 0 1.01059 1.1 1.01059 2.44444L1.00752 5.82532C1.00254 5.88288 1 5.94114 1 6V16C1 17.1046 1.89543 18 3 18H3.12898C3.04375 17.5085 3 17.0068 3 16.5C3 14.2349 3.87411 12.0694 5.41957 10.4779C6.96395 8.8876 9.05192 8 11.2225 8H17.7775C19.6868 8 21.5323 8.68681 23 9.93458V4.88889C23 3.54444 22.0467 2.44444 20.8815 2.44444H11.0614C10.4814 2.44444 9.92991 2.19263 9.55003 1.75431L8.62775 0.690138Z"
                fill="#BAC5D3"/>
            <path
                clipRule="evenodd"
                d="M7.18707 12.2574C8.26727 11.1321 9.73235 10.5 11.26 10.5H17.74C19.2676 10.5 20.7327 11.1321 21.8129 12.2574C22.8931 13.3826 23.5 14.9087 23.5 16.5C23.5 18.0913 22.8931 19.6174 21.8129 20.7426C20.7327 21.8679 19.2676 22.5 17.74 22.5H11.26C9.73235 22.5 8.26727 21.8679 7.18707 20.7426C6.10686 19.6174 5.5 18.0913 5.5 16.5C5.5 14.9087 6.10686 13.3826 7.18707 12.2574ZM11.26 13.5C10.4962 13.5 9.76364 13.8161 9.22353 14.3787C8.68343 14.9413 8.38 15.7044 8.38 16.5C8.38 17.2956 8.68343 18.0587 9.22353 18.6213C9.76364 19.1839 10.4962 19.5 11.26 19.5H17.74C18.5038 19.5 19.2364 19.1839 19.7765 18.6213C20.3166 18.0587 20.62 17.2956 20.62 16.5C20.62 15.7044 20.3166 14.9413 19.7765 14.3787C19.2364 13.8161 18.5038 13.5 17.74 13.5H11.26Z"
                fill="#C74634"
                fillRule="evenodd"/>
        </SvgIcon>);
}