import { useLocalization } from "@infrastructure";
import _ from "lodash";
import { useMemo } from "react";
import { Contract, InlineEntities, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetOciResourceRiskContext } from "./useGetOciResourceRiskContext";

export function useGetOciIamUserRiskContext() {
    return useMemo(
        () => useOciIamUserRiskContext,
        []);
}

function useOciIamUserRiskContext(userModel: Contract.OciIamUserModel) {
    const user = userModel.entity as Contract.OciFssFileSystem;
    const resourceRiskContext = useGetOciResourceRiskContext()(userModel);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.oci.hooks.contexts.useGetOciIamUserRiskContext",
            () => ({
                apiKeys: "The {{translatedUserTypeName}} has {{apiKeys}}"
            }));

    return {
        ...resourceRiskContext,
        apiKeys:
            _.isEmpty(userModel.apiKeyIds)
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.apiKeys({
                        apiKeys:
                            <InlineEntities
                                entityIdsOrModels={userModel.apiKeyIds}
                                entityTypeName={Contract.TypeNames.OciIamUserApiKey}
                                variant="itemCountAndType"/>,
                        translatedUserTypeName:
                            entityTypeNameTranslator(
                                user.typeName,
                                {
                                    includeServiceName: false,
                                    variant: "text"
                                })
                    }))
    };
}