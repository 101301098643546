﻿import { TimeFormatter, useLocalization } from "@infrastructure";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { Contract, InfoItem } from "../../../../../../../../../../common";
import { Info } from "../../../../components";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";
import { useAwsDefaultResourceInfoItemElements } from "./useAwsDefaultResourceInfoItemElements";

export function useAwsIamServerCertificateDefinition(resourceModel: Contract.AwsResourceModel, options?: DefinitionOptions) {
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const serverCertificate = resourceModel.entity as Contract.AwsIamServerCertificate;

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsIamServerCertificateDefinition",
            () => ({
                info: {
                    items: {
                        creationTime: "Upload Time",
                        expirationTime: "Expiration Time",
                        path: "Path",
                        uniqueId: "ID"
                    }
                }
            }));

    const defaultResourceInfoItemElements =
        useAwsDefaultResourceInfoItemElements(
            resourceModel,
            {
                creationTime: {
                    title: localization.info.items.creationTime()
                }
            });

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="expirationTime"
                        title={localization.info.items.expirationTime()}
                        value={TimeFormatter.shortDate(serverCertificate.expirationTime)}/>,
                    <InfoItem
                        key="path"
                        title={localization.info.items.path()}
                        value={serverCertificate.path}/>,
                    <InfoItem
                        key="uniqueId"
                        title={localization.info.items.uniqueId()}
                        value={serverCertificate.uniqueId}/>
                ]}
                options={options?.infoOptions}>
            </Info>
    });
}