import { SvgIconProps } from "@mui/material";
import React from "react";
import { Contract } from "../../../../../../../../../../controllers";
import { VulnerabilitiesIcon } from "../../../../../../../../../../icons";
import { CommonEntityAttributesIcon, CommonTenantTypeIcon, InfraBooleanIcon, InfraDateIcon, InfraIpAddressIcon, InfraStringIcon, InfraUrlIcon } from "./icons";

type UdmObjectPropertyDataTypeIconProps =
    SvgIconProps & {
        propertyDataType: Contract.UdmObjectPropertyDataType;
    };

export function UdmObjectPropertyDataTypeIcon({ propertyDataType, ...props }: UdmObjectPropertyDataTypeIconProps) {
    switch (propertyDataType) {
        case Contract.UdmObjectPropertyDataType.CommonEntityAttributes:
            return <CommonEntityAttributesIcon {...props}/>;
        // case Contract.UdmObjectPropertyDataType.CommonEntityTags:
        //     return <Fragment {...props}/>;
        // case Contract.UdmObjectPropertyDataType.CommonSeverity:
        //     return <Fragment {...props}/>;
        case Contract.UdmObjectPropertyDataType.CommonTenantType:
            return <CommonTenantTypeIcon {...props}/>;
        // case Contract.UdmObjectPropertyDataType.CommonVendor:
        //     return <Fragment {...props}/>;
        case Contract.UdmObjectPropertyDataType.CommonVulnerabilities:
            return <VulnerabilitiesIcon {...props}/>;
        case Contract.UdmObjectPropertyDataType.InfraBoolean:
            return <InfraBooleanIcon {...props}/>;
        // case Contract.UdmObjectPropertyDataType.InfraByteSize:
        //     return <Fragment {...props}/>;
        case Contract.UdmObjectPropertyDataType.InfraDate:
            return <InfraDateIcon {...props}/>;
        case Contract.UdmObjectPropertyDataType.InfraDateTime:
            return <InfraDateIcon {...props}/>;
        // case Contract.UdmObjectPropertyDataType.InfraEnum:
        //     return <Fragment {...props}/>;
        // case Contract.UdmObjectPropertyDataType.InfraInteger:
        //     return <Fragment {...props}/>;
        case Contract.UdmObjectPropertyDataType.InfraIpAddress:
            return <InfraIpAddressIcon {...props}/>;
        // case Contract.UdmObjectPropertyDataType.InfraPortRange:
        //     return <Fragment {...props}/>;
        case Contract.UdmObjectPropertyDataType.InfraString:
            return <InfraStringIcon {...props}/>;
        case Contract.UdmObjectPropertyDataType.InfraTimeSpan:
            return <InfraDateIcon {...props}/>;
        case Contract.UdmObjectPropertyDataType.InfraUrl:
            return <InfraUrlIcon {...props}/>;
        default:
            return <InfraStringIcon {...props}/>;
    }
}