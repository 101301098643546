import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import { setUrlRoute, Tooltip, useLocalization } from "@infrastructure";
import { Contract, CustomerConsoleAppUrlHelper, ScopeHelper, UserHelper, useTheme } from "../../../../../../../common";

export function Onboarding() {
    const localization =
        useLocalization(
            "views.customer.workloadAnalysis.code.onboarding",
            () => ({
                button: {
                    text: "Configure Integration",
                    tooltip: "Missing permissions. Only users with the Administrator role can configure CI/CD pipelines"
                },
                description: {
                    action: "To start, integrate Tenable Cloud Security with your **CI/CD Pipelines**.",
                    title: "Use CI/CD scanning to gain pre-production visibility into container image vulnerabilities, which is critical to understanding potential risks in containerized applications before deployment"
                },
                title: "Tenable Cloud Security Container Image CI/CD Scanning"
            }));

    const theme = useTheme();
    return (
        <Stack
            spacing={3}
            sx={{
                height: "100%",
                maxWidth: theme.spacing(100),
                padding: theme.spacing(10)
            }}>
            <Typography
                sx={{ fontWeight: 700 }}
                variant="h1">
                {localization.title()}
            </Typography>
            <Typography variant="h4">
                {localization.description.title()}
            </Typography>
            <Typography sx={{ fontSize: "14px" }}>
                {localization.description.action()}
            </Typography>
            <Tooltip
                titleOrGetTitle={
                    UserHelper.hasScopePermissions(ScopeHelper.customerId, Contract.IdentityPermission.SecurityAdministrationRead)
                        ? undefined
                        : localization.button.tooltip()}>
                <Box sx={{ width: "fit-content" }}>
                    <Button
                        disabled={!UserHelper.hasScopePermissions(ScopeHelper.customerId, Contract.IdentityPermission.SecurityAdministrationRead)}
                        onClick={() => setUrlRoute(CustomerConsoleAppUrlHelper.getConfigurationIntegrationsRelativeUrl())}>
                        {localization.button.text()}
                    </Button>
                </Box>
            </Tooltip>
        </Stack>);
}