﻿import { DataTableColumn, DataTableColumnRenderProps, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, ItemSelectionHelper, RiskType, Severity, SeverityFilter } from "../../../../../../../../../../../../../../../../../../../../../../common";
import { useItemsContext, useTableCommonCloudColumns } from "../../../../../../../../../../../../../../../../hooks";
import { TableColumnId } from "../../../../../../../../../../../../../../../Table";
import { useCommonColumns } from "../../../../../../../useCommonColumns";
import { useCommonCloudRiskPolicyTableDefinition } from "../../../useCommonCloudRiskPolicyTableDefinition";

export function useAwsIamRoleVendorAssumeRolePolicyDocumentExternalIdConditionNotExistRiskDefinition(riskPolicyConfigurationTypeName: string) {
    const { requestFilters } = useItemsContext();

    const localization =
        useLocalization(
            "views.customer.risks.items.grouped.item.table.hooks.useDefinition.hooks.useCloudDefinition.hooks.useRiskPolicyTypeDefinition.hooks.useAccessDefinition.hooks.aws.useAwsIamRoleVendorAssumeRolePolicyDocumentExternalIdConditionNotExistRiskDefinition",
            () => ({
                columns: {
                    permissionsRiskSeverity: "IAM Role Permissions"
                }
            }));

    const commonColumns = useCommonColumns();
    const commonCloudColumns = useTableCommonCloudColumns();

    return useCommonCloudRiskPolicyTableDefinition({
        columns:
            _.filter([
                commonColumns.statusUpdateTimeColumn,
                commonColumns.severityColumn,
                commonColumns.riskPolicyIdColumn,
                commonCloudColumns.descriptionColumn,
                commonColumns.tenantIdColumn,
                commonColumns.riskedEntityIdColumn(
                    riskPolicyConfigurationTypeName,
                    requestFilters as Contract.RiskControllerCloudRiskModelFilters),
                commonColumns.resourceOwnerColumn,
                commonColumns.resourceEnvironmentColumn,
                <DataTableColumn
                    cellMaxWidth="medium"
                    filterOptions={{
                        itemOrItems: {
                            element: <SeverityFilter placeholder={localization.columns.permissionsRiskSeverity()}/>
                        }
                    }}
                    id={TableColumnId.AwsIamRoleVendorAssumeRolePolicyDocumentExternalIdConditionNotExistRiskPermissionsRiskSeverity}
                    key={TableColumnId.AwsIamRoleVendorAssumeRolePolicyDocumentExternalIdConditionNotExistRiskPermissionsRiskSeverity}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.AwsIamRoleVendorAssumeRolePolicyDocumentExternalIdConditionNotExistRiskModel>) =>
                            <Severity severity={item.risk.permissionsRiskSeverity}/>}
                    sortOptions={{ enabled: false }}
                    title={localization.columns.permissionsRiskSeverity()}/>,
                commonColumns.statusColumn(true),
                commonColumns.subStatusColumn(true),
                commonColumns.ignoreExpirationDateColumn,
                commonColumns.openStatusUpdateTimeColumn,
                commonColumns.systemCreationTimeColumn,
                commonColumns.riskedEntityAttributesColumn,
                commonColumns.actionsCell(RiskType.Cloud)
            ]),
        getGroupFilters:
            filterMap =>
                new Contract.RiskControllerAwsIamRoleVendorAssumeRolePolicyDocumentExternalIdConditionNotExistRiskPolicyTypeFilters(
                    riskPolicyConfigurationTypeName,
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[TableColumnId.RiskedEntityIds]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[TableColumnId.AwsIamRoleVendorAssumeRolePolicyDocumentExternalIdConditionNotExistRiskPermissionsRiskSeverity])),
        getRiskIdToScopesIdentityPermissionsMapRequest:
            requestFilters =>
                new Contract.RiskControllerGetRiskPolicyTypeGroupCloudRiskIdToScopeIdentityPermissionsMapRequest(
                    requestFilters,
                    riskPolicyConfigurationTypeName)
    });
}