import React, { Fragment } from "react";
import { VulnerabilitiesIcon } from "../../../../../../../../../common";
import { CategoryView } from "../../CategoryView";
import { RiskImpactIcon, SourcesIcon, TimelineIcon } from "./icons";

type CategoryIconProps = {
    view: CategoryView;
};

export function CategoryIcon({ view }: CategoryIconProps) {
    switch (view) {
        case CategoryView.Overview:
            return <VulnerabilitiesIcon/>;
        case CategoryView.RiskImpact:
            return <RiskImpactIcon/>;
        case CategoryView.Sources:
            return <SourcesIcon/>;
        case CategoryView.Timeline:
            return <TimelineIcon/>;
        default:
            return <Fragment/>;
    }
}