import { NumberFormatter, TimeSpanFormatter, useLocalization } from "@infrastructure";
import React from "react";
import { Contract, InfoCard, InfoItem } from "../../../../../../../../../../../../common";

type FlowLogsProps = {
    enabled: boolean;
    flowLogs: Contract.GcpComputeSubnetFlowLogs;
};

export function FlowLogs({ enabled, flowLogs }: FlowLogsProps) {
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpComputeSubnetDefinition.flowLogs",
            () => ({
                items: {
                    aggregationTimeFrame: "Aggregation Interval",
                    enabled: {
                        disabled: "Disabled",
                        enabled: "Enabled",
                        title: "Status"
                    },
                    includeMetadata: {
                        disabled: "Disabled",
                        enabled: "Enabled",
                        title: "Include metadata"
                    },
                    sampleRate: "Sample rate"
                }
            }));

    return (
        <InfoCard columns={true}>
            <InfoItem
                key="enabled"
                title={localization.items.enabled.title()}
                value={
                    enabled
                        ? localization.items.enabled.enabled()
                        : localization.items.enabled.disabled()}/>
            <InfoItem
                key="aggregationTimeFrame"
                title={localization.items.aggregationTimeFrame()}
                value={TimeSpanFormatter.dayHourMinuteSecond(flowLogs.aggregationTimeFrame)}/>
            <InfoItem
                key="includeMetadata"
                title={localization.items.includeMetadata.title()}
                value={
                    flowLogs.includeMetadata
                        ? localization.items.includeMetadata.enabled()
                        : localization.items.includeMetadata.disabled()}/>
            <InfoItem
                key="sampleRate"
                title={localization.items.sampleRate()}
                value={NumberFormatter.percentage(flowLogs.sampleRate)}/>
        </InfoCard>);
}