import { Action0, useSelectionActionsContext } from "@infrastructure";
import { Dictionary } from "lodash";
import React from "react";
import { ScopeNode, useScopeNavigationViewContext } from "../../../../../../../common";
import { useMoveEnabled } from "../../../hooks";
import { MoveButton } from "./MoveButton";

type MoveSelectionActionProps = {
    resetSelectedItemIds: Action0;
    scopeNodeMap: Dictionary<ScopeNode>;
};

export function MoveSelectionAction({ resetSelectedItemIds, scopeNodeMap }: MoveSelectionActionProps) {
    const { actionEnded, actionExecuting, actionStarted, itemIds } = useSelectionActionsContext();
    const { scopeNodeModel } = useScopeNavigationViewContext();
    const { enabled: moveEnabled, tooltip } = useMoveEnabled(itemIds, scopeNodeMap, scopeNodeModel);
    return (
        <MoveButton
            disabled={
                !moveEnabled ||
                actionExecuting}
            scopeIds={itemIds}
            scopeNodeMap={scopeNodeMap}
            scopeNodeModel={scopeNodeModel}
            tooltip={tooltip}
            variant="selectionAction"
            onClick={() => actionStarted(true)}
            onClose={actionEnded}
            onExecute={resetSelectedItemIds}/>);
}