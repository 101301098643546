import { map } from "@infrastructure";
import React from "react";
import { Contract } from "../../../../../../common";
import { CsvReport, PdfReport } from "./components";


export type ComplianceReportProps = {
    reportDefinitionConfiguration: Contract.ComplianceReportDefinitionConfiguration;
    scopeId: string;
};

export function ComplianceReport({ reportDefinitionConfiguration, scopeId }: ComplianceReportProps) {
    return map(
        reportDefinitionConfiguration.contentType,
        {
            [Contract.ReportContentType.Csv]: () =>
                <CsvReport
                    reportDefinitionConfiguration={reportDefinitionConfiguration}
                    scopeId={scopeId}/>,
            [Contract.ReportContentType.Pdf]: () =>
                <PdfReport
                    reportDefinitionConfiguration={reportDefinitionConfiguration}
                    scopeId={scopeId}/>
        });
}