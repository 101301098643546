import _ from "lodash";
import { useCallback, useMemo } from "react";
import { Contract, scopeNodeModelStore, scopeSystemEntityModelStore } from "../../../../../common";

export function useGetRiskAutomationRuleDeliveries(scopeId: string) {
    const scopeNodeMap =
        scopeNodeModelStore.useGetActiveScopeNodeMap(
            undefined,
            true);
    const riskAutomationRuleModels = scopeSystemEntityModelStore.useGetRiskAutomationRule();
    const scopeRiskAutomationRuleConfigurations =
        useMemo(
            () => {
                const scopeIds =
                    _.concat(
                        scopeId,
                        scopeNodeMap[scopeId].parentScopeIds);
                return _(riskAutomationRuleModels).
                    map(riskAutomationRuleModel => riskAutomationRuleModel.configuration as Contract.RiskAutomationRuleConfiguration).
                    filter(
                        riskAutomationRuleConfiguration =>
                            !_(scopeIds).
                                intersection(riskAutomationRuleConfiguration.scopeIds).
                                isEmpty()).
                    value();
            },
            [riskAutomationRuleModels, scopeNodeMap, scopeId]);

    return useCallback(
        (riskPolicyConfigurationTypeNameOrId: string) =>
            _(scopeRiskAutomationRuleConfigurations).
                filter(
                    riskAutomationRuleConfiguration =>
                        riskPolicyConfigurationTypeNameOrId in Contract.TypeNames
                            ? _.isNil(riskAutomationRuleConfiguration.riskPolicyConfigurationTypeNames) ||
                            _.includes(riskAutomationRuleConfiguration.riskPolicyConfigurationTypeNames, riskPolicyConfigurationTypeNameOrId)
                            : _.includes(riskAutomationRuleConfiguration.customRiskPolicyIds, riskPolicyConfigurationTypeNameOrId)).
                flatMap(
                    riskAutomationRuleConfiguration =>
                        riskAutomationRuleConfiguration.deliveries).
                uniqWith(
                    (delivery, otherDelivery) => {
                        if (delivery.delivery.typeName !== otherDelivery.delivery.typeName) {
                            return false;
                        }

                        switch (delivery.delivery.typeName) {
                            case Contract.DeliveryDerivedTypeNames.JiraDelivery:
                                return (
                                    _((delivery.delivery as Contract.JiraDelivery).integrationIds).
                                        xor((otherDelivery.delivery as Contract.JiraDelivery).integrationIds).
                                        isEmpty() &&
                                    _(_.map(
                                        (delivery.delivery as Contract.JiraDelivery).destinations,
                                        destination => destination.issueCreationData.projectRawId)).
                                        xor(
                                            _.map(
                                                (otherDelivery.delivery as Contract.JiraDelivery).destinations,
                                                destination => destination.issueCreationData.projectRawId)).
                                        isEmpty());
                            case Contract.DeliveryDerivedTypeNames.MailDelivery:
                                return _(_.map(
                                    (delivery.delivery as Contract.MailDelivery).destinations,
                                    destination => destination.mail)).
                                    xor(_.map(
                                        (otherDelivery.delivery as Contract.MailDelivery).destinations,
                                        destination => destination.mail)).
                                    isEmpty();
                            case Contract.DeliveryDerivedTypeNames.ResourceOwnerMailDelivery:
                                return true;
                            case Contract.DeliveryDerivedTypeNames.SlackDelivery:
                                return _(_.map(
                                    (delivery.delivery as Contract.SlackDelivery).destinations,
                                    destination => destination.slackChannelReference)).
                                    xor(_.map(
                                        (otherDelivery.delivery as Contract.SlackDelivery).destinations,
                                        destination => destination.slackChannelReference)).
                                    isEmpty();
                            case Contract.DeliveryDerivedTypeNames.TeamsDelivery:
                                return _(_.map(
                                    (delivery.delivery as Contract.TeamsDelivery).destinations,
                                    destination => destination.teamsChannelReference)).
                                    xor(_.map(
                                        (otherDelivery.delivery as Contract.TeamsDelivery).destinations,
                                        destination => destination.teamsChannelReference)).
                                    isEmpty();
                            default:
                                return _((delivery.delivery as Contract.IntegrationDelivery).integrationIds).
                                    xor((otherDelivery.delivery as Contract.IntegrationDelivery).integrationIds).
                                    isEmpty();
                        }
                    }).
                value(),
        [scopeRiskAutomationRuleConfigurations]);
}