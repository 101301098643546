﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, getScopeConfigurationValueType, getScopeConfigurationValueTypeValue, RadioGroup, RadioGroupItem, ScopeConfigurationValueType, ScopeLabel, Severity, useTheme } from "../../../../../../../common";

export type FailureRiskPolicyMinSeverityEnabledProps = {
    failureRiskPolicyMinSeverityEnabled: boolean | undefined;
    inheritDisabled: boolean;
    inheritedFailureRiskPolicyMinSeverity?: Contract.Severity;
    inheritedScopeId?: string;
    setFailureRiskPolicyMinSeverityEnabled: (enabled: boolean | undefined) => void;
};

export function FailureRiskPolicyMinSeverityEnabled({ failureRiskPolicyMinSeverityEnabled, inheritDisabled, inheritedFailureRiskPolicyMinSeverity, inheritedScopeId, setFailureRiskPolicyMinSeverityEnabled }: FailureRiskPolicyMinSeverityEnabledProps) {
    const localization =
        useLocalization(
            "views.customer.configuration.code.failureRiskPolicyMinSeverityEnabled",
            () => ({
                description: "Choose whether to fail the scan when policies with a specific minimum severity are scanned.",
                options: {
                    disabled: "Disabled",
                    enabled: "Enabled",
                    inherit: "Inherit",
                    inheritedFailureRiskPolicyMinSeverity: "{{severity}}"
                },
                title: "Minimum Severity to Fail Scan"
            }));

    const items =
        useMemo(
            () =>
                _<RadioGroupItem<ScopeConfigurationValueType>>([]).
                    concatIf(
                        !inheritDisabled,
                        {
                            label:
                                <ScopeLabel
                                    inheritedScopeId={inheritedScopeId}
                                    text={localization.options.inherit()}
                                    translatedInheritedValue={
                                        _.isNil(inheritedFailureRiskPolicyMinSeverity)
                                            ? localization.options.disabled()
                                            : localization.options.inheritedFailureRiskPolicyMinSeverity({
                                                severity: <Severity severity={inheritedFailureRiskPolicyMinSeverity}/>
                                            })}/>,
                            value: ScopeConfigurationValueType.Inherit
                        }).
                    concat(
                        {
                            label: <ScopeLabel text={localization.options.disabled()}/>,
                            value: ScopeConfigurationValueType.Disabled
                        },
                        {
                            label: <ScopeLabel text={localization.options.enabled()}/>,
                            value: ScopeConfigurationValueType.Enabled
                        }).
                    value(),
            [inheritDisabled, inheritedScopeId]);

    const theme = useTheme();
    return (
        <RadioGroup
            description={localization.description()}
            items={items}
            selectedValue={getScopeConfigurationValueType(failureRiskPolicyMinSeverityEnabled)}
            sx={{ marginBottom: theme.spacing(1) }}
            title={localization.title()}
            onChange={value => setFailureRiskPolicyMinSeverityEnabled(getScopeConfigurationValueTypeValue(value))}/>);
}