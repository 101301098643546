﻿import { DataTableColumn, DataTableSortType, EnumValuesFilter, PagedValuesFilter, useLocalization } from "@infrastructure";
import { Dictionary } from "lodash";
import React from "react";
import { useGcpCommonComputeLoadBalancerResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, ItemSelectionHelper, TimeRangeHelper } from "../../../../../../../../../../common";
import { useGcpComputeSslPolicyFeatureProfileTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";

export function useGcpComputeSslPolicyDefinition(definitionData: DefinitionData) {
    const commonComputeLoadBalancerResourceDefinition = useGcpCommonComputeLoadBalancerResourceDefinition(definitionData);

    const computeSslPolicyFeatureProfileTranslator = useGcpComputeSslPolicyFeatureProfileTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.gcp.useGcpComputeSslPolicyDefinition",
            () => ({
                columns: {
                    featureProfile: "Profile",
                    tlsMinVersion: "Minimum TLS Version"
                }
            }));

    return new EntityTableDefinition(
        [
            commonComputeLoadBalancerResourceDefinition.columns.tenantColumn,
            commonComputeLoadBalancerResourceDefinition.columns.creationTimeColumn,
            commonComputeLoadBalancerResourceDefinition.columns.creatorIdentityCsvColumn,
            commonComputeLoadBalancerResourceDefinition.columns.creatorOriginatorEntityCsvColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.GcpComputeSslPolicyModel) => ({
                            [localization.columns.tlsMinVersion()]: (item.entity as Contract.GcpComputeSslPolicy).tlsMinVersion
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedValuesFilter
                                getValuePage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                        Contract.TypeNames.GcpComputeSslPolicy,
                                        Contract.EntityModelProperty.GcpComputeSslPolicyTlsMinVersion)}
                                placeholder={localization.columns.tlsMinVersion()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpComputeSslPolicyTlsMinVersion}
                itemProperty={(item: Contract.GcpComputeSslPolicyModel) => (item.entity as Contract.GcpComputeSslPolicy).tlsMinVersion}
                key={Contract.EntityModelProperty.GcpComputeSslPolicyTlsMinVersion}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.tlsMinVersion()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.GcpComputeSslPolicyModel) => ({
                            [localization.columns.featureProfile()]: computeSslPolicyFeatureProfileTranslator((item.entity as Contract.GcpComputeSslPolicy).featureProfile)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.GcpComputeSslPolicyFeatureProfile}
                                enumTypeTranslator={computeSslPolicyFeatureProfileTranslator}
                                placeholder={localization.columns.featureProfile()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpComputeSslPolicyFeatureProfile}
                itemProperty={(item: Contract.GcpComputeSslPolicyModel) => computeSslPolicyFeatureProfileTranslator((item.entity as Contract.GcpComputeSslPolicy).featureProfile)}
                key={Contract.EntityModelProperty.GcpComputeSslPolicyFeatureProfile}
                title={localization.columns.featureProfile()}/>,
            commonComputeLoadBalancerResourceDefinition.columns.loadBalancerIdsColumn,
            commonComputeLoadBalancerResourceDefinition.columns.attributesColumn,
            commonComputeLoadBalancerResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonComputeLoadBalancerResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonComputeLoadBalancerResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageGcpComputeSslPolicyRequest(
                new Contract.EntityControllerGetEntityModelPageGcpComputeSslPolicyRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.GcpResourceUpdateTime]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpScopeResourceAccessLevel]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpScopeResourceRawId]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpComputeLoadBalancerResourceLoadBalancers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpComputeSslPolicyFeatureProfile]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpComputeSslPolicyTlsMinVersion])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags]))));
}