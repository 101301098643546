import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function EntitySecurityIcon(props: SvgIconProps) {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <path
                clipRule="evenodd"
                d="M18.6182 17.1371C19.7182 15.3553 20.3636 13.2553 20.3636 11.0916V5.6371L12.1818 2.00073L4 5.6371V11.0916C4 16.1371 7.49091 20.8553 12.1818 22.0007C14.2727 21.4916 16.1182 20.2735 17.5273 18.628C17.5273 18.628 17.5645 18.628 18.0909 17.9098C18.6381 17.1633 18.6182 17.1371 18.6182 17.1371ZM16.7839 16.3011L16.9164 16.0865C17.8347 14.5989 18.3636 12.8597 18.3636 11.0916V6.93684L12.1818 4.18937L6 6.93684V11.0916C6 15.1179 8.69245 18.8192 12.1827 19.9251C13.6336 19.4669 14.9529 18.5594 16.0082 17.3271L16.1748 17.1326C16.2389 17.0495 16.3361 16.9208 16.4778 16.7275C16.6259 16.5255 16.7226 16.3894 16.7839 16.3011ZM16.0714 17.2623C16.0715 17.2622 16.073 17.2604 16.0758 17.2572C16.0728 17.2608 16.0714 17.2624 16.0714 17.2623Z"
                fillRule="evenodd"/>
        </SvgIcon>);
}