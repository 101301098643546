﻿import { DataTable, DataTableColumn, DataTableColumnRenderProps, EmptyMessageText, optionalTableCell, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, EntitiesCell, entityModelStore, InfoCard, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { useGcpComputeInstanceGroupManagerStatusTranslator } from "../../../../../../../../../../../../tenants";

type InstanceGroupsInfoCardProps = {
    computeInstanceGroupIds: string[];
};

export function ComputeInstanceGroupsInfoCard({ computeInstanceGroupIds }: InstanceGroupsInfoCardProps) {
    const computeInstanceGroupModels = entityModelStore.useGet(computeInstanceGroupIds);

    const computeInstanceGroupManagerStatusTranslator = useGcpComputeInstanceGroupManagerStatusTranslator();
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpContainerClusterNodePoolDefinition.computeInstanceGroupsInfoCard",
            () => ({
                columns: {
                    computeInstanceGroup: "Instance Group",
                    computeInstanceGroupInstanceIds: "VM Instances",
                    instanceGroupManagerStatus: "Status"
                },
                empty: "No {{translatedEntityTypeName}}",
                title: "Instance Groups"
            }));
    return (
        <InfoCard title={localization.title()}>
            <DataTable
                emptyMessageOptions={{
                    emptyMessageText:
                        new EmptyMessageText(
                            localization.empty({
                                translatedEntityTypeName:
                                    entityTypeNameTranslator(
                                        Contract.TypeNames.GcpComputeInstanceGroup,
                                        { count: 0 })
                            }))
                }}
                fetchItems={() => computeInstanceGroupModels}
                getItemId={(item: Contract.GcpComputeInstanceGroup) => item.id}
                sortOptions={{ enabled: false }}
                variant="card">
                <DataTableColumn
                    id="computeInstanceGroup"
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.GcpComputeInstanceGroupModel>) =>
                            <EntitiesCell
                                entityIdsOrModels={item.id}
                                entityTypeName={Contract.TypeNames.GcpComputeInstanceGroup}
                                entityVariant="iconTextTenant"/>}
                    title={localization.columns.computeInstanceGroup()}/>
                <DataTableColumn
                    id="computeInstanceGroupInstanceIds"
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.GcpComputeInstanceGroupModel>) =>
                            <EntitiesCell
                                entityIdsOrModels={item.instanceIds}
                                entityTypeName={Contract.TypeNames.GcpComputeInstance}
                                entityVariant="iconTextTenant"/>}
                    title={localization.columns.computeInstanceGroupInstanceIds()}/>
                <DataTableColumn
                    id="instanceGroupManagerStatus"
                    render={
                        optionalTableCell(
                            (item: Contract.GcpComputeInstanceGroupModel) =>
                                _.isNil(item.instanceGroupManagerStatus)
                                    ? undefined
                                    : computeInstanceGroupManagerStatusTranslator(item.instanceGroupManagerStatus))}
                    title={localization.columns.instanceGroupManagerStatus()}/>
            </DataTable>
        </InfoCard>);
}