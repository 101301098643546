﻿import { Link, useLocalization } from "@infrastructure";
import React from "react";
import { Contract, CustomerConsoleAppUrlHelper, UrlHelper } from "../../../common";

export function useSystemKubernetesClusterApiConnectivityStatusTooltipTranslator() {
    const localization =
        useLocalization(
            "tenants.kubernetes.hooks.useSystemKubernetesClusterApiConnectivityStatusTooltipTranslator",
            () => ({
                links: {
                    contact: "contact support",
                    documentation: "Learn more",
                    documentationCustomRole: "custom Tenable role"
                },
                [Contract.TypeNames.SystemKubernetesClusterApiConnectivityStatus]: {
                    [Contract.SystemKubernetesClusterApiConnectivityStatus.ErrorApplicationIpAddressNotConfigured]: "Tenable Cloud Security cannot access the cluster because you haven't allowed Tenable's IP addresses. {{documentationApplicationIpAddressesLink}}.",
                    [Contract.SystemKubernetesClusterApiConnectivityStatus.ErrorAuthenticationFailure]: "Tenable Cloud Security hasn't been granted permissions to this cluster. {{documentationKubernetesOnboardClusterLink}}.",
                    [Contract.SystemKubernetesClusterApiConnectivityStatus.ErrorAzureAuthenticationCustomRoleNotConfigured]: "The cluster is connected, but there is no IAM visibility for it, since the {{documentationAzureCustomRoleLink}} hasn't been added.",
                    [Contract.SystemKubernetesClusterApiConnectivityStatus.ErrorAzureAuthenticationIdentityDisabled]: "Tenable Cloud Security cannot access the cluster because it is missing identity-based permissions to access the cluster. {{documentationKubernetesOnboardClusterLink}}.",
                    [Contract.SystemKubernetesClusterApiConnectivityStatus.ErrorClusterNotRunning]: "Tenable Cloud Security cannot connect to the cluster because it is inactive.",
                    [Contract.SystemKubernetesClusterApiConnectivityStatus.ErrorConnectionFailure]: "Tenable Cloud Security cannot connect to the cluster due to an unknown error, please {{contactLink}}.",
                    [Contract.SystemKubernetesClusterApiConnectivityStatus.ErrorTunnelSessionNotConnected]: "Tenable Cloud Security cannot access the cluster because the configured networking tunnel is unreachable, please {{contactLink}}.",
                    [Contract.SystemKubernetesClusterApiConnectivityStatus.ErrorTunnelSessionNotExist]: "Tenable Cloud Security cannot access the cluster because it is missing a networking tunnel. {{documentationKubernetesClusterConnectorLink}}.",
                    [Contract.SystemKubernetesClusterApiConnectivityStatus.ErrorTunnelSessionUnstable]: "Connection was lost within the last 48 hours and may reconnect on its own. {{documentationKubernetesClusterConnectorLink}}.",
                    [Contract.SystemKubernetesClusterApiConnectivityStatus.Pending]: "The cluster status will be updated at the next system sync. {{documentationHowOftenIsDataSyncedLink}}.",
                    [Contract.SystemKubernetesClusterApiConnectivityStatus.Success]: "Tenable Cloud Security successfully connected to this cluster."
                }
            }));
    return (documentationKubernetesOnboardClusterUrl: string, status: Contract.SystemKubernetesClusterApiConnectivityStatus) =>
        localization[Contract.TypeNames.SystemKubernetesClusterApiConnectivityStatus][status]({
            contactLink:
                <Link
                    urlOrGetUrl={UrlHelper.supportUrl}
                    variant="external">
                    {localization.links.contact()}
                </Link>,
            documentationApplicationIpAddressesLink:
                <Link
                    urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsArchitectureAndDataHandlingApplicationIpAddressesRelativeUrl()}
                    variant="external">
                    {localization.links.documentation()}
                </Link>,
            documentationAzureCustomRoleLink:
                <Link
                    urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsOnboardAnAzureAksClusterAddACustomTenableRoleRelativeUrl()}
                    variant="external">
                    {localization.links.documentationCustomRole()}
                </Link>,
            documentationHowOftenIsDataSyncedLink:
                <Link
                    urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsArchitectureAndDataHandlingHowOftenIsDataSyncedRelativeUrl()}
                    variant="external">
                    {localization.links.documentation()}
                </Link>,
            documentationKubernetesClusterConnectorLink:
                <Link
                    urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsOnboardClusterViaTenableConnectorRelativeUrl()}
                    variant="external">
                    {localization.links.documentation()}
                </Link>,
            documentationKubernetesOnboardClusterLink:
                <Link
                    urlOrGetUrl={documentationKubernetesOnboardClusterUrl}
                    variant="external">
                    {localization.links.documentation()}
                </Link>
        });
}