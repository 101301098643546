import { Action0, ActionMenuItem, DeleteIcon, Link, Menu, Message, useLocalization } from "@infrastructure";
import { Box, Card as MuiCard, Stack, Typography } from "@mui/material";
import React, { ReactNode, useState } from "react";
import { useTheme } from "../../../../index";
import { useDefinition } from "./hooks";

export enum DashboardWidgetType {
    AccessExcessivePermissionPrincipalTrends = "accessExcessivePermissionPrincipalTrends",
    AccessPrincipals = "accessPrincipals",
    AccessRiskCombinations = "accessRiskCombinations",
    AccessRisks = "accessAccessRisks",
    AccessVendors = "accessVendors",
    CodeEntities = "codeEntities",
    CodeRisks = "codeRisks",
    CodeTenants = "codeTenants",
    CodeTopRiskTenants = "codeTopRiskTenants",
    Compliance = "compliance",
    DataAnalysis = "dataAnalysis",
    Entities = "entities",
    EntityPublicData = "entityPublicData",
    OpenBehaviorRisk = "openBehaviorRisk",
    RiskCombinations = "riskCombinations",
    Risks = "risks",
    RiskTrends = "riskTrends",
    Secrets = "secrets",
    Tenants = "tenants",
    TopRisks = "topRisks",
    TopRiskTenants = "topRiskTenants",
    TopRiskTypes = "topRiskTypes",
    WorkloadAnalysisCriticalVulnerabilities = "workloadAnalysisCriticalVulnerabilities",
    WorkloadAnalysisRiskDistribution = "workloadAnalysisRiskDistribution",
    WorkloadAnalysisVulnerabilityStatus = "workloadAnalysisVulnerabilityStatus"
}

type WidgetProps = {
    children?: ReactNode;
    editMode?: boolean;
    onRemoveWidget: Action0;
    type: DashboardWidgetType;
};

export function Widget({ editMode, onRemoveWidget, type }: WidgetProps) {
    const [showTopBorder, setShowTopBorder] = useState(false);

    const definition = useDefinition(type);
    const { details, helpText, title, topElement, variant } = definition.options;

    const localization =
        useLocalization(
            "common.dashboard.widget",
            () => ({
                remove: "Remove",
                showMore: "Show more"
            }));

    const theme = useTheme();
    return (
        <MuiCard
            className={type}
            sx={{
                height: {
                    lg: "100%",
                    xs: "100%"
                },
                padding: theme.spacing(2),
                ...(variant === "scrollable" && {
                    maxWidth: "100%",
                    padding: 0,
                    paddingTop: theme.spacing(2)
                })
            }}>
            <Stack sx={{ height: "100%" }}>
                <Stack
                    direction="row"
                    flexWrap="wrap"
                    justifyContent="space-between"
                    sx={{
                        paddingBottom: theme.spacing(2),
                        ...(variant === "scrollable" && {
                            borderBottomColor:
                                showTopBorder
                                    ? theme.palette.borders.primary
                                    : "transparent",
                            borderBottomStyle: "solid",
                            borderBottomWidth: "1px",
                            padding: theme.spacing(0, 2, 2)
                        })
                    }}>
                    <Stack
                        alignItems="center"
                        direction="row"
                        spacing={1}
                        sx={{ overflow: "hidden" }}>
                        <Typography
                            noWrap={true}
                            sx={{ fontWeight: 600 }}
                            variant="h3">
                            {title}
                        </Typography>
                        {helpText &&
                            <Message
                                level="info"
                                title={
                                    <Typography sx={{ whiteSpace: "pre-wrap" }}>
                                        {helpText}
                                    </Typography>}
                                variant="minimal"/>}
                    </Stack>
                    <Stack
                        alignItems="center"
                        direction="row"
                        gap={2}
                        sx={{ height: theme.px(28) }}>
                        {topElement &&
                            <Box>
                                {topElement}
                            </Box>}
                        {details?.detailsUrl &&
                            <Link
                                noWrap={true}
                                sx={{
                                    color: theme.palette.text.primary,
                                    textDecoration: "underline"
                                }}
                                urlOrGetUrl={details.detailsUrl}
                                variant="text">
                                {details.title ?? localization.showMore()}
                            </Link>}
                        {editMode &&
                            <Menu
                                itemsOrGetItems={[
                                    new ActionMenuItem(
                                        onRemoveWidget,
                                        localization.remove(),
                                        { icon: <DeleteIcon/> })
                                ]}
                                variant="bottomRight"/>}
                    </Stack>
                </Stack>
                <Stack
                    sx={{
                        flex: 1,
                        position: "relative",
                        ...(variant === "scrollable"
                            ? { overflow: "auto" }
                            : undefined)
                    }}>
                    {variant === "scrollable"
                        ? <Stack
                            spacing={1}
                            sx={{
                                flex: 1,
                                overflow: "auto",
                                padding: theme.spacing(0, 1, 1, 1.5)
                            }}
                            onScroll={event => setShowTopBorder(event.currentTarget && event.currentTarget.scrollTop > 0)}>
                            {definition.element}
                        </Stack>
                        : definition.element}
                </Stack>
            </Stack>
        </MuiCard>);
}