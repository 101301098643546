import { InlineItems, useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract, Entity } from "../../../../../../../../../../../common";
import { useAwsSnsDeliveryProtocolTranslator } from "../../../../../../../../Entities/hooks";

export type SnsTopicSubscriptionsProps = {
    topicModel: Contract.AwsSnsTopicModel;
};

export function SnsTopicSubscriptions({ topicModel }: SnsTopicSubscriptionsProps) {
    const topic = topicModel.entity as Contract.AwsSnsTopic;

    const snsDeliveryProtocolTranslator = useAwsSnsDeliveryProtocolTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.snsTopicSubscriptions",
            () => ({
                subscriptions: [
                    "1 subscriber",
                    "{{count | NumberFormatter.humanize}} subscribers"
                ]
            }));
    return (
        <InlineItems
            items={
                _(topic.subscriptions).
                    orderBy(
                        [
                            subscription => subscription.deliveryProtocol,
                            subscription => subscription.endpoint
                        ]).
                    map(
                        subscription =>
                            <Typography
                                key={subscription.arn}
                                noWrap={true}>
                                {`${snsDeliveryProtocolTranslator(subscription.deliveryProtocol)} | `}
                                {_.isNil(topicModel.subscriptionArnToEndpointEntityIdMap[subscription.arn])
                                    ? subscription.endpoint
                                    : <Entity
                                        entityIdOrModel={topicModel.subscriptionArnToEndpointEntityIdMap[subscription.arn]}
                                        variant="text"/>}
                            </Typography>).
                    value()}
            namePluralizer={localization.subscriptions}
            variant="itemCountAndType"/>);
}