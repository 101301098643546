import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, EnumValuesFilter, NumberFormatter, useLocalization } from "@infrastructure";
import { Dictionary } from "lodash";
import React from "react";
import { useOciCommonEncryptedResourceDefinition, useOciCommonResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions, useEntityTypeNameTranslator } from "../../../../../../../../../../common";
import { useOciBlockStorageBlockVolumeStatusTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";

export function useOciBlockStorageBlockVolumeDefinition(definitionData: DefinitionData) {
    const commonEncryptedResourceDefinition = useOciCommonEncryptedResourceDefinition(definitionData);
    const commonResourceDefinition = useOciCommonResourceDefinition(definitionData);

    const entitiesExportOptions = useEntitiesExportOptions();

    const blockVolumeStatusTranslator = useOciBlockStorageBlockVolumeStatusTranslator();
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.oci.useOciBlockStorageBlockVolumeDefinition",
            () => ({
                columns: {
                    size: "Size",
                    status: "Status"
                }
            }));

    return new EntityTableDefinition(
        [
            commonResourceDefinition.columns.tenantColumn,
            commonResourceDefinition.columns.creationTimeColumn,
            commonResourceDefinition.columns.creatorCsvColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.OciBlockStorageBlockVolumeModel) => ({
                            [localization.columns.status()]: blockVolumeStatusTranslator((item.entity as Contract.OciBlockStorageBlockVolume).status)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.OciBlockStorageBlockVolumeStatus}
                                enumTypeTranslator={blockVolumeStatusTranslator}
                                placeholder={localization.columns.status()}/>
                    }
                }}
                id={Contract.EntityModelProperty.OciBlockStorageBlockVolumeStatus}
                itemProperty={(item: Contract.OciBlockStorageBlockVolumeModel) => blockVolumeStatusTranslator((item.entity as Contract.OciBlockStorageBlockVolume).status)}
                key={Contract.EntityModelProperty.OciBlockStorageBlockVolumeStatus}
                title={localization.columns.status()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.OciBlockStorageBlockVolumeModel) => ({
                            [localization.columns.size()]: NumberFormatter.storage((item.entity as Contract.OciBlockStorageBlockVolume).size)
                        })
                }}
                id={Contract.EntityModelProperty.OciBlockStorageBlockVolumeSize}
                itemProperty={(item: Contract.OciBlockStorageBlockVolumeModel) => NumberFormatter.storage((item.entity as Contract.OciBlockStorageBlockVolume).size)}
                key={Contract.EntityModelProperty.OciBlockStorageBlockVolumeSize}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.size()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.OciBlockStorageBlockVolumeModel>(
                        Contract.TypeNames.OciComputeInstance,
                        item => item.instanceIdReferences,
                        entityTypeNameTranslator(
                            Contract.TypeNames.OciComputeInstance,
                            { count: 0 }))}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.OciBlockStorageBlockVolumeInstances)}
                                placeholder={
                                    entityTypeNameTranslator(
                                        Contract.TypeNames.OciComputeInstance,
                                        { count: 0 })}/>
                    }
                }}
                id={Contract.EntityModelProperty.OciBlockStorageBlockVolumeInstances}
                key={Contract.EntityModelProperty.OciBlockStorageBlockVolumeInstances}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.OciBlockStorageBlockVolumeModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.instanceIdReferences}
                            entityTypeName={Contract.TypeNames.OciComputeInstance}
                            entityVariant="iconText"/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={
                    entityTypeNameTranslator(
                        Contract.TypeNames.OciComputeInstance,
                        { count: 0 })}/>,
            commonEncryptedResourceDefinition.columns.encryptionExistsColumn,
            commonEncryptedResourceDefinition.columns.encryptionVaultKeyColumn,
            commonResourceDefinition.columns.regionColumn,
            commonResourceDefinition.columns.regionLocationColumn,
            commonResourceDefinition.columns.tagsColumn,
            commonResourceDefinition.columns.attributesColumn,
            commonResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageOciBlockStorageBlockVolumeRequest(
                new Contract.EntityControllerGetEntityModelPageOciBlockStorageBlockVolumeRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciBlockStorageBlockVolumeInstances]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciBlockStorageBlockVolumeStatus])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags])),
                new Contract.EntityControllerGetEntityModelPageOciResourceRequestEncrypted(
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciEncryptedResourceEncryptionExists]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciEncryptedResourceEncryptionVaultKey]))));
}