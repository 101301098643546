import { AddIcon, DeleteIcon, Dropdown, DropdownActions, getItemWithValidation, Tooltip, useChangeEffect, useLocalization } from "@infrastructure";
import { Button, IconButton, List, ListItemButton, Stack } from "@mui/material";
import _ from "lodash";
import React, { useCallback, useMemo, useRef } from "react";
import { MatchingActiveTenants } from "..";
import { Contract, FeatureHelper, ScopeHelper, scopeNodeModelStore, useTheme } from "../../../../../../../../../../../../../../common";
import { ProjectConfigurationRuleConditionWithValidationWithId, ProjectConfigurationRuleWithValidation } from "../../../../AddOrEdit";
import { Condition } from "./components";
import { useConditionTypeTranslator } from "./hooks";
import { ConditionsHelper, ConditionType } from "./utilities";

type ConditionsProps = {
    deleteDisabled: boolean;
    deleteTooltipTitle: string;
    deleteVisible: boolean;
    onProjectConfigurationRuleChanged: (projectConfigurationRule: ProjectConfigurationRuleWithValidation) => void;
    onProjectConfigurationRuleClear: () => void;
    projectConfigurationRule: ProjectConfigurationRuleWithValidation;
};

export function Conditions({ deleteDisabled, deleteTooltipTitle, deleteVisible, onProjectConfigurationRuleChanged, onProjectConfigurationRuleClear, projectConfigurationRule }: ConditionsProps) {
    const scopeNodeMap = scopeNodeModelStore.useGetScopeNodeMap();

    const conditionTypeTranslator = useConditionTypeTranslator();

    const filteredConditionTypes =
        useMemo(
            () => {
                if (!FeatureHelper.enabled(Contract.FeatureName.ProjectsResourceConditionsEnabled)) {
                    return tenantConditionTypes;
                }

                return _.some(
                    projectConfigurationRule.scopeIds,
                    scopeId =>
                        ScopeHelper.getTenantType(scopeNodeMap[scopeId].scopeNodeModel) === Contract.TenantType.Azure ||
                        ScopeHelper.customerId === scopeId)
                    ? [...tenantConditionTypes, ...resourceConditionTypes, ...azureResourceConditionTypes]
                    : [...tenantConditionTypes, ...resourceConditionTypes];
            },
            [projectConfigurationRule.scopeIds]);

    const isConditionTypeAllowed =
        useCallback(
            (conditionType: string) =>
                _.includes(
                    filteredConditionTypes,
                    conditionType),
            [filteredConditionTypes]);

    function isProjectConfigurationRuleConditionsValid(conditions: ProjectConfigurationRuleConditionWithValidationWithId[]) {
        return _.isEmpty(conditions) ||
            _.every(
                conditions,
                condition =>
                    condition.valid &&
                    isConditionTypeAllowed(condition.typeName));
    }

    useChangeEffect(
        () => {
            const conditionsValid = isProjectConfigurationRuleConditionsValid(projectConfigurationRule.conditions);
            if (projectConfigurationRule.valid === conditionsValid) {
                return;
            }

            onProjectConfigurationRuleChanged({
                ...projectConfigurationRule,
                valid: conditionsValid
            });
        },
        [filteredConditionTypes, projectConfigurationRule.valid]);

    const projectConfigurationRules =
        useMemo(
            () => [projectConfigurationRule],
            [projectConfigurationRule]);

    const localization =
        useLocalization(
            "views.customer.scopes.hooks.useDefinition.hooks.useProjectsDefinition.addOrEdit.projectConfigurationRules.conditions",
            () => ({
                addCondition: "Condition",
                conditionTypeNotAllowed: "This condition type is not available for the selected scopes",
                conditionTypes: {
                    [ConditionType.TenantTagPattern]: "Account tag pattern",
                    [ConditionType.TenantNamePattern]: "Account name pattern",
                    [ConditionType.EntityTagPattern]: "Resource tag pattern",
                    [ConditionType.EntityNamePattern]: "Resource group name pattern"
                }
            }));

    const dropdownActionsRef = useRef<DropdownActions>();

    const theme = useTheme();
    return (
        <Stack spacing={2}>
            {!_.isEmpty(projectConfigurationRule.conditions) &&
                <Stack spacing={1}>
                    {_.map(
                        projectConfigurationRule.conditions,
                        (condition, index) =>
                            <Stack
                                direction="row"
                                key={condition.id}>
                                <Condition
                                    condition={condition}
                                    errorMessage={
                                        isConditionTypeAllowed(condition.typeName)
                                            ? undefined
                                            : localization.conditionTypeNotAllowed()}
                                    onConditionChanged={
                                        updatedCondition => {
                                            const conditions =
                                                _.map(
                                                    projectConfigurationRule.conditions,
                                                    (condition, conditionIndex) =>
                                                        index === conditionIndex
                                                            ? updatedCondition
                                                            : condition);
                                            return onProjectConfigurationRuleChanged({
                                                ...projectConfigurationRule,
                                                conditions,
                                                valid: isProjectConfigurationRuleConditionsValid(conditions)
                                            });
                                        }}
                                    onConditionClear={
                                        () => {
                                            const conditions =
                                                _.filter(
                                                    projectConfigurationRule.conditions,
                                                    (_, removeIndex) => index !== removeIndex);
                                            return onProjectConfigurationRuleChanged({
                                                ...projectConfigurationRule,
                                                conditions,
                                                valid: isProjectConfigurationRuleConditionsValid(conditions)
                                            });
                                        }}/>
                            </Stack>)}
                </Stack>}

            <Stack
                direction="row"
                justifyContent="space-between">
                <Dropdown
                    actionsRef={dropdownActionsRef}
                    popoverElement={
                        <List sx={{ padding: 0 }}>
                            {_.map(
                                filteredConditionTypes,
                                (conditionType, index) =>
                                    <ListItemButton
                                        key={`${conditionType}_${index}`}
                                        onClick={
                                            () => {
                                                onProjectConfigurationRuleChanged({
                                                    ...projectConfigurationRule,
                                                    conditions: [
                                                        ...projectConfigurationRule.conditions,
                                                        getItemWithValidation(
                                                            ConditionsHelper.createCondition(
                                                                conditionType,
                                                                false))
                                                    ],
                                                    valid: false
                                                });
                                                dropdownActionsRef.current?.close();
                                            }}>
                                        {conditionTypeTranslator(conditionType, "title")}
                                    </ListItemButton>)}
                        </List>}
                    popoverElementContainerSx={{ padding: 0 }}
                    sx={{ width: "fit-content" }}>
                    <Button
                        size="small"
                        startIcon={<AddIcon/>}
                        variant="outlined">
                        {localization.addCondition()}
                    </Button>
                </Dropdown>
                <Stack
                    alignItems="center"
                    direction="row"
                    spacing={1}>
                    <MatchingActiveTenants projectConfigurationRules={projectConfigurationRules}/>
                    <Tooltip titleOrGetTitle={deleteTooltipTitle}>
                        <IconButton
                            disabled={deleteDisabled}
                            size="small"
                            sx={{
                                marginLeft: theme.spacing(1),
                                visibility:
                                    deleteVisible && !deleteDisabled
                                        ? "visible"
                                        : "hidden"
                            }}
                            onClick={
                                () => {
                                    onProjectConfigurationRuleClear();
                                }}>
                            <DeleteIcon/>
                        </IconButton>
                    </Tooltip>
                </Stack>
            </Stack>
        </Stack>);
}

const tenantConditionTypes = [
    ConditionType.TenantNamePattern,
    ConditionType.TenantTagPattern
];

const resourceConditionTypes = [
    ConditionType.EntityTagPattern
];

const azureResourceConditionTypes = [
    ConditionType.EntityNamePattern
];