import { useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import React from "react";

export function Empty() {
    const localization =
        useLocalization(
            "views.customer.administration.empty",
            () => ({
                title: "No Settings"
            }));

    return (
        <Stack
            alignItems="center"
            justifyContent="center"
            style={{ height: "100%" }}>
            <Typography
                align="center"
                variant="h1">
                {localization.title()}
            </Typography>
        </Stack>);
}