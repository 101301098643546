import { DataTableColumnRenderProps } from "@infrastructure";
import { Typography } from "@mui/material";
import _ from "lodash";
import React, { ReactElement } from "react";
import { Contract, ScopeHelper } from "../../../../../../../common";

type ScopeRawIdProps = {
    fallbackRender: (scopeNodeModel: DataTableColumnRenderProps<Contract.ScopeNodeModel>) => JSX.Element;
    scopeNodeModel: DataTableColumnRenderProps<Contract.ScopeNodeModel>;
};

export function ScopeRawId({ fallbackRender, scopeNodeModel }: ScopeRawIdProps): ReactElement {
    const folderRawId = (scopeNodeModel.item.configuration as Contract.CloudProviderTenantFolderConfiguration)?.organization?.folderRawId;

    return (
        ScopeHelper.isFolder(scopeNodeModel.item) && !_.isNil(folderRawId)
            ? <Typography noWrap={true}>
                {folderRawId}
            </Typography>
            : fallbackRender(scopeNodeModel));
}