import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { EntityExternalConsoleLink } from "../../components";
import { useGetGcpSqlInstanceRiskContext } from "../contexts";

export function useGcpSqlInstanceHttpsOnlyDisabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.GcpSqlInstanceHttpsOnlyDisabledRisk;
    const instanceModel = entityModelStore.useGet(risk.entityId) as Contract.GcpSqlInstanceModel;

    const getGcpSqlInstanceRiskContext = useGetGcpSqlInstanceRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.useGcpSqlInstanceHttpsOnlyDisabledRiskDefinition",
            () => ({
                description: "{{instance}} is not enforcing SSL connections",
                sections: {
                    resolution: {
                        step1: "Before enforcing SSL connections, verify that applications use only HTTPS to access the database. Also note that the change requires a restart to the instance",
                        step2: "Check the **Allow only SSL connections** checkbox",
                        step3: "Click **SAVE & RESTART**"
                    }
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            instance:
                <Entity
                    entityIdOrModel={instanceModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={risk.entityId}
                key={risk.entityId}
                page={Contract.GcpConsolePage.Security}/>,
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3()
        ],
        riskModel,
        () => {
            const instanceRiskContext = getGcpSqlInstanceRiskContext(instanceModel);
            return [
                instanceRiskContext.generalInformation,
                instanceRiskContext.sensitive,
                instanceRiskContext.inboundExternalAccessScope,
                instanceRiskContext.status,
                instanceRiskContext.databaseVersion,
                instanceRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        });
}