//@ts-nocheck
import Mustache from "mustache";
import { Contract } from "../../../common";
import { OciConsoleHelper } from "./ociConsoleHelper";

export class OciConsoleUrlBuilder {
    private static readonly _blockStorageBlockVolumeUrlTemplate = "https://{{domainName}}/block-storage/volumes/{{ocid}}?region={{regionSystemName}}";
    private static readonly _blockStorageBootVolumeUrlTemplate = "https://{{domainName}}/block-storage/boot-volumes/{{ocid}}?region={{regionSystemName}}";
    private static readonly _blockStorageUrlTemplate = "https://{{domainName}}/block-storage/";
    private static readonly _computeImageUrlTemplate = "https://{{domainName}}/compute/images/{{ocid}}?region={{regionSystemName}}";
    private static readonly _computeInstanceConfigurationsUrlTemplate = "https://{{domainName}}/compute/instance-configs?region={{regionSystemName}}";
    private static readonly _computeInstanceConfigurationUrlTemplate = "https://{{domainName}}/compute/instance-configs/{{ocid}}?region={{regionSystemName}}";
    private static readonly _computeInstancePoolUrlTemplate = "https://{{domainName}}/compute/instance-pools/{{ocid}}?region={{regionSystemName}}";
    private static readonly _computeInstancesUrlTemplate = "https://{{domainName}}/compute/instances?region={{regionSystemName}}";
    private static readonly _computeInstanceUrlTemplate = "https://{{domainName}}/compute/instances/{{ocid}}?region={{regionSystemName}}";
    private static readonly _computeUrlTemplate = "https://{{domainName}}/compute/";
    private static readonly _containerEngineClusterNodePoolUrlTemplate = "https://{{domainName}}/containers/clusters/{{clusterOcid}}/node-pools/{{nodePoolOcid}}/provisioned?region={{regionSystemName}}";
    private static readonly _fssFileSystemUrlTemplate = "https://{{domainName}}/fss/file-systems/{{ocid}}?region={{regionSystemName}}";
    private static readonly _fssMountTargetExportUrlTemplate = "https://{{domainName}}/fss/mount-targets/{{mountTargetOcid}}/exports/{{exportOcid}}?region={{regionSystemName}}";
    private static readonly _fssMountTargetUrlTemplate = "https://{{domainName}}/fss/mount-targets/{{ocid}}?region={{regionSystemName}}";
    private static readonly _fssUrlTemplate = "https://{{domainName}}/fss/";
    private static readonly _iamDomainPasswordPolicyUrlTemplate = "https://{{domainName}}/identity/domains/{{domainOcid}}/settings/password-policy-settings?region={{regionSystemName}}";
    private static readonly _iamDynamicResourceGroupUrlTemplate = "https://{{domainName}}/identity/domains/{{domainOcid}}/dynamic-groups/{{dynamicResourceGroupOcid}}?region={{regionSystemName}}";
    private static readonly _iamGroupUrlTemplate = "https://{{domainName}}/identity/domains/{{domainOcid}}/groups/{{groupOcid}}?region={{regionSystemName}}";
    private static readonly _iamUrlTemplate = "https://{{domainName}}/identity/domains/";
    private static readonly _iamUserApiKeyUrlTemplate = "https://{{domainName}}/identity/domains/{{domainOcid}}/users/{{userOcid}}/api-keys?region={{regionSystemName}}";
    private static readonly _iamUserAuthTokenUrlTemplate = "https://{{domainName}}/identity/domains/{{domainOcid}}/users/{{userOcid}}/auth-tokens?region={{regionSystemName}}";
    private static readonly _iamUserDatabasePasswordUrlTemplate = "https://{{domainName}}/identity/domains/{{domainOcid}}/users/{{userOcid}}/db-passwords?region={{regionSystemName}}";
    private static readonly _iamUserSecretKeyUrlTemplate = "https://{{domainName}}/identity/domains/{{domainOcid}}/users/{{userOcid}}/secret-keys?region={{regionSystemName}}";
    private static readonly _iamUserUrlTemplate = "https://{{domainName}}/identity/domains/{{domainOcid}}/users/{{userOcid}}?region={{regionSystemName}}";
    private static readonly _kmsUrlTemplate = "https://{{domainName}}/security/kms/";
    private static readonly _kmsVaultKeyUrlTemplate = "https://{{domainName}}/security/kms/vaults/{{vaultOcid}}/keys/{{keyOcid}}?region={{regionSystemName}}";
    private static readonly _kmsVaultSecretUrlTemplate = "https://{{domainName}}/security/kms/vaults/{{vaultOcid}}/secrets/{{secretOcid}}?region={{regionSystemName}}";
    private static readonly _kmsVaultUrlTemplate = "https://{{domainName}}/security/kms/vaults/{{ocid}}?region={{regionSystemName}}";
    private static readonly _loadBalancingLoadBalancerUrlTemplate = "https://{{domainName}}/load-balancer/load-balancers/{{ocid}}?region={{regionSystemName}}";
    private static readonly _loggingLogGroupUrlTemplate = "https://{{domainName}}/logging/log-groups/{{ocid}}?region={{regionSystemName}}";
    private static readonly _networkingInternetGatewayUrlTemplate = "https://{{domainName}}/networking/vcns/{{vcnOcid}}/internet-gateways?compartmentId={{compartmentOcid}}&region={{regionSystemName}}";
    private static readonly _networkingLocalPeeringGatewayUrlTemplate = "https://{{domainName}}/networking/vcns/{{vcnOcid}}/lpg-connections?compartmentId={{compartmentOcid}}&region={{regionSystemName}}";
    private static readonly _networkingNatGatewayUrlTemplate = "https://{{domainName}}/networking/vcns/{{vcnOcid}}/nat-gateways?compartmentId={{compartmentOcid}}&region={{regionSystemName}}";
    private static readonly _networkingNetworkCommandCenterFlowLogsUrlTemplate = "https://{{domainName}}/networking/network-command-center/flow-logs?region={{regionSystemName}}";
    private static readonly _networkingNetworkLoadBalancerUrlTemplate = "https://{{domainName}}/networking/load-balancers/network-load-balancer/{{ocid}}?region={{regionSystemName}}";
    private static readonly _networkingNetworkSecurityGroupUrlTemplate = "https://{{domainName}}/networking/vcns/{{vcnOcid}}/network-security-groups/{{networkSecurityGroupOcid}}?region={{regionSystemName}}";
    private static readonly _networkingRouteTableUrlTemplate = "https://{{domainName}}/networking/vcns/{{vcnOcid}}/route-tables/{{routeTableOcid}}?region={{regionSystemName}}";
    private static readonly _networkingSecurityListUrlTemplate = "https://{{domainName}}/networking/vcns/{{vcnOcid}}/security-lists/{{securityListOcid}}?region={{regionSystemName}}";
    private static readonly _networkingServiceGatewayUrlTemplate = "https://{{domainName}}/networking/vcns/{{vcnOcid}}/service-gateways?compartmentId={{compartmentOcid}}&region={{regionSystemName}}";
    private static readonly _networkingSubnetUrlTemplate = "https://{{domainName}}/networking/vcns/{{vcnOcid}}/subnets/{{subnetOcid}}?region={{regionSystemName}}";
    private static readonly _networkingUrlTemplate = "https://{{domainName}}/networking/";
    private static readonly _networkingVcnUrlTemplate = "https://{{domainName}}/networking/vcns/{{ocid}}?region={{regionSystemName}}";
    private static readonly _objectStorageBucketUrlTemplate = "https://{{domainName}}/object-storage/buckets/{{namespace}}/{{name}}?region={{regionSystemName}}";

public static GetBlockStorageBlockVolumeUrl(

        realmId: string,
        ocid: string,
        regionSystemName: string) {
        return Mustache.render(
            OciConsoleUrlBuilder._blockStorageBlockVolumeUrlTemplate,
            {
                "domainName": OciConsoleHelper.getDomainName(realmId),
                "ocid": ocid,
                "regionSystemName": regionSystemName
            });
    }

public static GetBlockStorageBootVolumeUrl(

        realmId: string,
        ocid: string,
        regionSystemName: string) {
        return Mustache.render(
            OciConsoleUrlBuilder._blockStorageBootVolumeUrlTemplate,
            {
                "domainName": OciConsoleHelper.getDomainName(realmId),
                "ocid": ocid,
                "regionSystemName": regionSystemName
            });
    }

public static GetBlockStorageUrl(realmId: string) {
        return Mustache.render(
            OciConsoleUrlBuilder._blockStorageUrlTemplate,
            {
                "domainName": OciConsoleHelper.getDomainName(realmId)
            });
    }

public static GetComputeImageUrl(

        realmId: string,
        ocid: string,
        regionSystemName: string) {
        return Mustache.render(
            OciConsoleUrlBuilder._computeImageUrlTemplate,
            {
                "domainName": OciConsoleHelper.getDomainName(realmId),
                "ocid": ocid,
                "regionSystemName": regionSystemName
            });
    }

public static GetComputeInstanceConfigurationsUrl(

        realmId: string,
        regionSystemName: string) {
        return Mustache.render(
            OciConsoleUrlBuilder._computeInstanceConfigurationsUrlTemplate,
            {
                "domainName": OciConsoleHelper.getDomainName(realmId),
                "regionSystemName": regionSystemName
            });
    }

public static GetComputeInstanceConfigurationUrl(

        realmId: string,
        ocid: string,
        regionSystemName: string) {
        return Mustache.render(
            OciConsoleUrlBuilder._computeInstanceConfigurationUrlTemplate,
            {
                "domainName": OciConsoleHelper.getDomainName(realmId),
                "ocid": ocid,
                "regionSystemName": regionSystemName
            });
    }

public static GetComputeInstancePoolUrl(

        realmId: string,
        ocid: string,
        regionSystemName: string) {
        return Mustache.render(
            OciConsoleUrlBuilder._computeInstancePoolUrlTemplate,
            {
                "domainName": OciConsoleHelper.getDomainName(realmId),
                "ocid": ocid,
                "regionSystemName": regionSystemName
            });
    }

public static GetComputeInstancesUrl(

        realmId: string,
        regionSystemName: string) {
        return Mustache.render(
            OciConsoleUrlBuilder._computeInstancesUrlTemplate,
            {
                "domainName": OciConsoleHelper.getDomainName(realmId),
                "regionSystemName": regionSystemName
            });
    }

public static GetComputeInstanceUrl(

        realmId: string,
        ocid: string,
        regionSystemName: string) {
        return Mustache.render(
            OciConsoleUrlBuilder._computeInstanceUrlTemplate,
            {
                "domainName": OciConsoleHelper.getDomainName(realmId),
                "ocid": ocid,
                "regionSystemName": regionSystemName
            });
    }

public static GetComputeUrl(realmId: string) {
        return Mustache.render(
            OciConsoleUrlBuilder._computeUrlTemplate,
            {
                "domainName": OciConsoleHelper.getDomainName(realmId)
            });
    }

public static GetContainerEngineClusterNodePoolUrl(

        clusterOcid: string,
        realmId: string,
        nodePoolOcid: string,
        regionSystemName: string) {
        return Mustache.render(
            OciConsoleUrlBuilder._containerEngineClusterNodePoolUrlTemplate,
            {
                "clusterOcid": clusterOcid,
                "domainName": OciConsoleHelper.getDomainName(realmId),
                "nodePoolOcid": nodePoolOcid,
                "regionSystemName": regionSystemName
            });
    }

public static GetFssFileSystemUrl(

        realmId: string,
        ocid: string,
        regionSystemName: string) {
        return Mustache.render(
            OciConsoleUrlBuilder._fssFileSystemUrlTemplate,
            {
                "domainName": OciConsoleHelper.getDomainName(realmId),
                "ocid": ocid,
                "regionSystemName": regionSystemName
            });
    }

public static GetFssMountTargetExportUrl(

        realmId: string,
        exportOcid: string,
        mountTargetOcid: string,
        regionSystemName: string) {
        return Mustache.render(
            OciConsoleUrlBuilder._fssMountTargetExportUrlTemplate,
            {
                "domainName": OciConsoleHelper.getDomainName(realmId),
                "exportOcid": exportOcid,
                "mountTargetOcid": mountTargetOcid,
                "regionSystemName": regionSystemName
            });
    }

public static GetFssMountTargetUrl(

        realmId: string,
        ocid: string,
        regionSystemName: string) {
        return Mustache.render(
            OciConsoleUrlBuilder._fssMountTargetUrlTemplate,
            {
                "domainName": OciConsoleHelper.getDomainName(realmId),
                "ocid": ocid,
                "regionSystemName": regionSystemName
            });
    }

public static GetFssUrl(realmId: string) {
        return Mustache.render(
            OciConsoleUrlBuilder._fssUrlTemplate,
            {
                "domainName": OciConsoleHelper.getDomainName(realmId)
            });
    }

public static GetIamDomainPasswordPolicyUrl(

        realmId: string,
        domainOcid: string,
        regionSystemName: string) {
        return Mustache.render(
            OciConsoleUrlBuilder._iamDomainPasswordPolicyUrlTemplate,
            {
                "domainName": OciConsoleHelper.getDomainName(realmId),
                "domainOcid": domainOcid,
                "regionSystemName": regionSystemName
            });
    }

public static GetIamDynamicResourceGroupUrl(

        realmId: string,
        domainOcid: string,
        dynamicResourceGroupOcid: string,
        regionSystemName: string) {
        return Mustache.render(
            OciConsoleUrlBuilder._iamDynamicResourceGroupUrlTemplate,
            {
                "domainName": OciConsoleHelper.getDomainName(realmId),
                "domainOcid": domainOcid,
                "dynamicResourceGroupOcid": dynamicResourceGroupOcid,
                "regionSystemName": regionSystemName
            });
    }

public static GetIamGroupUrl(

        realmId: string,
        domainOcid: string,
        groupOcid: string,
        regionSystemName: string) {
        return Mustache.render(
            OciConsoleUrlBuilder._iamGroupUrlTemplate,
            {
                "domainName": OciConsoleHelper.getDomainName(realmId),
                "domainOcid": domainOcid,
                "groupOcid": groupOcid,
                "regionSystemName": regionSystemName
            });
    }

public static GetIamUrl(realmId: string) {
        return Mustache.render(
            OciConsoleUrlBuilder._iamUrlTemplate,
            {
                "domainName": OciConsoleHelper.getDomainName(realmId)
            });
    }

public static GetIamUserApiKeyUrl(

        realmId: string,
        domainOcid: string,
        regionSystemName: string,
        userOcid: string) {
        return Mustache.render(
            OciConsoleUrlBuilder._iamUserApiKeyUrlTemplate,
            {
                "domainName": OciConsoleHelper.getDomainName(realmId),
                "domainOcid": domainOcid,
                "regionSystemName": regionSystemName,
                "userOcid": userOcid
            });
    }

public static GetIamUserAuthTokenUrl(

        realmId: string,
        domainOcid: string,
        regionSystemName: string,
        userOcid: string) {
        return Mustache.render(
            OciConsoleUrlBuilder._iamUserAuthTokenUrlTemplate,
            {
                "domainName": OciConsoleHelper.getDomainName(realmId),
                "domainOcid": domainOcid,
                "regionSystemName": regionSystemName,
                "userOcid": userOcid
            });
    }

public static GetIamUserDatabasePasswordUrl(

        realmId: string,
        domainOcid: string,
        regionSystemName: string,
        userOcid: string) {
        return Mustache.render(
            OciConsoleUrlBuilder._iamUserDatabasePasswordUrlTemplate,
            {
                "domainName": OciConsoleHelper.getDomainName(realmId),
                "domainOcid": domainOcid,
                "regionSystemName": regionSystemName,
                "userOcid": userOcid
            });
    }

public static GetIamUserSecretKeyUrl(

        realmId: string,
        domainOcid: string,
        regionSystemName: string,
        userOcid: string) {
        return Mustache.render(
            OciConsoleUrlBuilder._iamUserSecretKeyUrlTemplate,
            {
                "domainName": OciConsoleHelper.getDomainName(realmId),
                "domainOcid": domainOcid,
                "regionSystemName": regionSystemName,
                "userOcid": userOcid
            });
    }

public static GetIamUserUrl(

        realmId: string,
        domainOcid: string,
        regionSystemName: string,
        userOcid: string) {
        return Mustache.render(
            OciConsoleUrlBuilder._iamUserUrlTemplate,
            {
                "domainName": OciConsoleHelper.getDomainName(realmId),
                "domainOcid": domainOcid,
                "regionSystemName": regionSystemName,
                "userOcid": userOcid
            });
    }

public static GetKmsUrl(realmId: string) {
        return Mustache.render(
            OciConsoleUrlBuilder._kmsUrlTemplate,
            {
                "domainName": OciConsoleHelper.getDomainName(realmId)
            });
    }

public static GetKmsVaultKeyUrl(

        realmId: string,
        keyOcid: string,
        regionSystemName: string,
        vaultOcid: string) {
        return Mustache.render(
            OciConsoleUrlBuilder._kmsVaultKeyUrlTemplate,
            {
                "domainName": OciConsoleHelper.getDomainName(realmId),
                "keyOcid": keyOcid,
                "regionSystemName": regionSystemName,
                "vaultOcid": vaultOcid
            });
    }

public static GetKmsVaultSecretUrl(

        realmId: string,
        regionSystemName: string,
        secretOcid: string,
        vaultOcid: string) {
        return Mustache.render(
            OciConsoleUrlBuilder._kmsVaultSecretUrlTemplate,
            {
                "domainName": OciConsoleHelper.getDomainName(realmId),
                "regionSystemName": regionSystemName,
                "secretOcid": secretOcid,
                "vaultOcid": vaultOcid
            });
    }

public static GetKmsVaultUrl(

        realmId: string,
        ocid: string,
        regionSystemName: string) {
        return Mustache.render(
            OciConsoleUrlBuilder._kmsVaultUrlTemplate,
            {
                "domainName": OciConsoleHelper.getDomainName(realmId),
                "ocid": ocid,
                "regionSystemName": regionSystemName
            });
    }

public static GetLoadBalancingLoadBalancerUrl(

        realmId: string,
        ocid: string,
        regionSystemName: string) {
        return Mustache.render(
            OciConsoleUrlBuilder._loadBalancingLoadBalancerUrlTemplate,
            {
                "domainName": OciConsoleHelper.getDomainName(realmId),
                "ocid": ocid,
                "regionSystemName": regionSystemName
            });
    }

public static GetLoggingLogGroupUrl(

        realmId: string,
        ocid: string,
        regionSystemName: string) {
        return Mustache.render(
            OciConsoleUrlBuilder._loggingLogGroupUrlTemplate,
            {
                "domainName": OciConsoleHelper.getDomainName(realmId),
                "ocid": ocid,
                "regionSystemName": regionSystemName
            });
    }

public static GetNetworkingInternetGatewayUrl(

        compartmentOcid: string,
        realmId: string,
        regionSystemName: string,
        vcnOcid: string) {
        return Mustache.render(
            OciConsoleUrlBuilder._networkingInternetGatewayUrlTemplate,
            {
                "compartmentOcid": compartmentOcid,
                "domainName": OciConsoleHelper.getDomainName(realmId),
                "regionSystemName": regionSystemName,
                "vcnOcid": vcnOcid
            });
    }

public static GetNetworkingLocalPeeringGatewayUrl(

        compartmentOcid: string,
        realmId: string,
        regionSystemName: string,
        vcnOcid: string) {
        return Mustache.render(
            OciConsoleUrlBuilder._networkingLocalPeeringGatewayUrlTemplate,
            {
                "compartmentOcid": compartmentOcid,
                "domainName": OciConsoleHelper.getDomainName(realmId),
                "regionSystemName": regionSystemName,
                "vcnOcid": vcnOcid
            });
    }

public static GetNetworkingNatGatewayUrl(

        compartmentOcid: string,
        realmId: string,
        regionSystemName: string,
        vcnOcid: string) {
        return Mustache.render(
            OciConsoleUrlBuilder._networkingNatGatewayUrlTemplate,
            {
                "compartmentOcid": compartmentOcid,
                "domainName": OciConsoleHelper.getDomainName(realmId),
                "regionSystemName": regionSystemName,
                "vcnOcid": vcnOcid
            });
    }

public static GetNetworkingNetworkCommandCenterFlowLogsUrl(

        realmId: string,
        regionSystemName: string) {
        return Mustache.render(
            OciConsoleUrlBuilder._networkingNetworkCommandCenterFlowLogsUrlTemplate,
            {
                "domainName": OciConsoleHelper.getDomainName(realmId),
                "regionSystemName": regionSystemName
            });
    }

public static GetNetworkingNetworkLoadBalancerUrl(

        realmId: string,
        ocid: string,
        regionSystemName: string) {
        return Mustache.render(
            OciConsoleUrlBuilder._networkingNetworkLoadBalancerUrlTemplate,
            {
                "domainName": OciConsoleHelper.getDomainName(realmId),
                "ocid": ocid,
                "regionSystemName": regionSystemName
            });
    }

public static GetNetworkingNetworkSecurityGroupUrl(

        realmId: string,
        networkSecurityGroupOcid: string,
        regionSystemName: string,
        vcnOcid: string) {
        return Mustache.render(
            OciConsoleUrlBuilder._networkingNetworkSecurityGroupUrlTemplate,
            {
                "domainName": OciConsoleHelper.getDomainName(realmId),
                "networkSecurityGroupOcid": networkSecurityGroupOcid,
                "regionSystemName": regionSystemName,
                "vcnOcid": vcnOcid
            });
    }

public static GetNetworkingRouteTableUrl(

        realmId: string,
        regionSystemName: string,
        routeTableOcid: string,
        vcnOcid: string) {
        return Mustache.render(
            OciConsoleUrlBuilder._networkingRouteTableUrlTemplate,
            {
                "domainName": OciConsoleHelper.getDomainName(realmId),
                "regionSystemName": regionSystemName,
                "routeTableOcid": routeTableOcid,
                "vcnOcid": vcnOcid
            });
    }

public static GetNetworkingSecurityListUrl(

        realmId: string,
        regionSystemName: string,
        securityListOcid: string,
        vcnOcid: string) {
        return Mustache.render(
            OciConsoleUrlBuilder._networkingSecurityListUrlTemplate,
            {
                "domainName": OciConsoleHelper.getDomainName(realmId),
                "regionSystemName": regionSystemName,
                "securityListOcid": securityListOcid,
                "vcnOcid": vcnOcid
            });
    }

public static GetNetworkingServiceGatewayUrl(

        compartmentOcid: string,
        realmId: string,
        regionSystemName: string,
        vcnOcid: string) {
        return Mustache.render(
            OciConsoleUrlBuilder._networkingServiceGatewayUrlTemplate,
            {
                "compartmentOcid": compartmentOcid,
                "domainName": OciConsoleHelper.getDomainName(realmId),
                "regionSystemName": regionSystemName,
                "vcnOcid": vcnOcid
            });
    }

public static GetNetworkingSubnetUrl(

        realmId: string,
        regionSystemName: string,
        subnetOcid: string,
        vcnOcid: string) {
        return Mustache.render(
            OciConsoleUrlBuilder._networkingSubnetUrlTemplate,
            {
                "domainName": OciConsoleHelper.getDomainName(realmId),
                "regionSystemName": regionSystemName,
                "subnetOcid": subnetOcid,
                "vcnOcid": vcnOcid
            });
    }

public static GetNetworkingUrl(realmId: string) {
        return Mustache.render(
            OciConsoleUrlBuilder._networkingUrlTemplate,
            {
                "domainName": OciConsoleHelper.getDomainName(realmId)
            });
    }

public static GetNetworkingVcnUrl(

        realmId: string,
        ocid: string,
        regionSystemName: string) {
        return Mustache.render(
            OciConsoleUrlBuilder._networkingVcnUrlTemplate,
            {
                "domainName": OciConsoleHelper.getDomainName(realmId),
                "ocid": ocid,
                "regionSystemName": regionSystemName
            });
    }

public static GetObjectStorageBucketUrl(

        realmId: string,
        name: string,
        namespace: string,
        regionSystemName: string) {
        return Mustache.render(
            OciConsoleUrlBuilder._objectStorageBucketUrlTemplate,
            {
                "domainName": OciConsoleHelper.getDomainName(realmId),
                "name": name,
                "namespace": namespace,
                "regionSystemName": regionSystemName
            });
    }
}