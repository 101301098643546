import { NumberFormatter } from "@infrastructure";
import { Box, Divider, Grid2, Stack, Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { useMemo } from "react";
import { Contract, TypeHelper, useSeverityTranslator, useTheme } from "../../../../../../../common";

type SummaryProps = {
    severityToCountMap: Dictionary<number>;
    showInformation?: boolean;
    title: string;
};

export function Summary({ severityToCountMap, showInformation = false, title }: SummaryProps) {
    const filteredSeverityToCountMap =
        useMemo(
            () =>
                showInformation
                    ? severityToCountMap
                    : _.pickBy(
                        severityToCountMap,
                        (count, severity) => severity != Contract.Severity.Information),
            []);

    const severityTranslator = useSeverityTranslator();
    const theme = useTheme();
    return (
        <Grid2
            alignItems="center"
            container={true}
            sx={{
                border: theme.border.primary,
                borderRadius: theme.spacing(0.75)
            }}>
            <Grid2 sx={{ padding: theme.spacing(2.5, 4) }}>
                <Stack alignItems="center">
                    <Typography
                        sx={{
                            fontSize: "24px",
                            fontWeight: "bold"
                        }}>
                        {NumberFormatter.humanize(
                            _(filteredSeverityToCountMap).
                                values().
                                sum())}
                    </Typography>
                    <Typography>
                        {title}
                    </Typography>
                </Stack>
            </Grid2>
            <Divider
                flexItem={true}
                orientation="vertical"
                sx={{ margin: theme.spacing(1, 0) }}
                variant="middle"/>
            <Grid2
                container={true}
                size="grow"
                sx={{ padding: theme.spacing(2.5, 0, 2.5, 4) }}>
                {_(filteredSeverityToCountMap).
                    keys().
                    orderBy(
                        severity =>
                            TypeHelper.getEnumValue(
                                Contract.TypeNames.Severity,
                                severity),
                        "desc").
                    map(
                        (severity: Contract.Severity) =>
                            <Grid2
                                container={true}
                                key={severity}
                                size="grow">
                                <Grid2 size={{ xs: 1 }}>
                                    <Box
                                        sx={{
                                            backgroundColor: theme.palette.severity(severity),
                                            height: "100%",
                                            width: theme.px(3)
                                        }}/>
                                </Grid2>
                                <Grid2>
                                    <Typography variant="h4">
                                        {NumberFormatter.humanize(filteredSeverityToCountMap[severity])}
                                    </Typography>
                                    {severityTranslator(severity)}
                                </Grid2>
                            </Grid2>).
                    value()}
            </Grid2>
        </Grid2>);
}