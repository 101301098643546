﻿import { UnexpectedError } from "@infrastructure";
import { useMemo } from "react";
import { Contract } from "../../../../../..";
import { useGciDeletePrincipalGroupMembershipChangeDefinition } from "./hooks";

export function useGciDefinition(change: Contract.GciChange, entityLinkDisabled?: boolean) {
    const useDefinition =
        useMemo(
            () => {
                switch (change.typeName) {
                    case Contract.TypeNames.GciDeletePrincipalGroupMembershipChange:
                        return useGciDeletePrincipalGroupMembershipChangeDefinition;
                    default:
                        throw new UnexpectedError("change.typeName", change.typeName);
                }
            },
            []);

    return useDefinition(change, entityLinkDisabled);
}