import { ReactNode, useMemo } from "react";
import { AccessView } from "../../../../hooks";
import { useExcessivePermissionDefinition, usePermissionDefinition } from "./hooks";

export function useDefinition(view: AccessView.PrincipalExcessivePermissions | AccessView.PrincipalPermissions) {
    const useDefinition =
        useMemo(
            () => {
                switch (view) {
                    case AccessView.PrincipalExcessivePermissions:
                        return useExcessivePermissionDefinition;
                    case AccessView.PrincipalPermissions:
                        return usePermissionDefinition;
                }
            },
            []);

    return useDefinition();
}

export class PrincipalsDefinition {
    constructor(
        public tableElement: ReactNode,
        public summaryElement?: ReactNode) {
    }
}