import { Action0, Action1, DataTable, DataTableActions, DataTableFetchItemsResult, DataTableFilterExternalOptions, DataTableFiltersOptions, DataTableSort, EmptyMessageText, Optional, SelectionActionsItemPermissions, TableResetOptions, useActions, useLocalization } from "@infrastructure";
import { SxProps } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { ReactNode, Ref, useCallback, useEffect, useMemo, useRef } from "react";
import { Contract, useTheme } from "../../../../../../../common";
import { useIdentityPermissionsTranslator } from "../../../../../hooks";
import { CustomerSideViewItemType, useSideViewContext, useSideViewState } from "../../../../SideView";
import { RiskHelper } from "../../../utilities";
import { useRiskContext } from "../../Items";
import { useItemsContext, useRiskTypeDefinition } from "../hooks";

type TableProps = {
    actionsRef?: Ref<Optional<TableActions>>;
    children?: ReactNode;
    columns: ReactNode[];
    filterExternalOptions?: DataTableFilterExternalOptions;
    getRiskIdToScopeIdentityPermissionsMap: (filterMap: Dictionary<any>) => Promise<Dictionary<Contract.IdentityPermission[]>>;
    getRiskModelPage: (filterMap: Dictionary<any>, limit: number, skip: number, sort: Optional<DataTableSort>) => Promise<Contract.RiskControllerGetRiskModelPageResponse>;
    groupId?: string;
    onFiltersChanged?: (filterMap: Dictionary<any>) => void;
    onLoadedRiskModelsChanged?: (loadedRiskModels: Contract.RiskModel[]) => void;
    onRiskModelCountChanged?: (riskModelCount?: number) => void;
    onSelectionChanged?: (selectedRiskIds: string[], selectionPermissions?: SelectionActionsItemPermissions) => void;
    onSelectorChanged?: Action1<string[]>;
    onSortChanged?: (sort?: DataTableSort) => void;
    pageSize?: number;
    sx?: SxProps;
    waitForReset?: boolean;
};

export type TableActions = {
    fetchItems: Action0;
    getDataTableActionRef: () => React.MutableRefObject<DataTableActions | undefined>;
    reloadRiskModels: () => Promise<void>;
    reset: (options?: TableResetOptions) => void;
    setSelectedItemIds: (selectedItemIds: string[]) => void;
};

export function Table({ actionsRef, children, columns, filterExternalOptions, getRiskIdToScopeIdentityPermissionsMap, getRiskModelPage, groupId, onFiltersChanged, onLoadedRiskModelsChanged, onRiskModelCountChanged, onSelectionChanged, onSelectorChanged, onSortChanged, pageSize = 30, sx, waitForReset = false }: TableProps) {
    const sideViewState = useSideViewState();
    const { riskType } = useRiskContext();
    const { filtered, riskIdToScopeIdsMapRef, view } = useItemsContext();
    const riskTypeDefinition = useRiskTypeDefinition(riskType, view);
    const grouped = !_.isNil(groupId);

    const { registerDataChange } = useSideViewContext();
    useEffect(
        () => {
            const unregister =
                registerDataChange(
                    type => {
                        if (type === Contract.CustomerConsoleAppSideViewType.Risk) {
                            reloadRiskModels();
                        }
                    });
            return unregister;
        },
        []);

    const viewRef = useRef<Contract.RisksView>(view);
    const dataTableActionsRef = useRef<DataTableActions>();
    const fetchRiskModels =
        useCallback(
            async (filterMap: Dictionary<any>, sort: Optional<DataTableSort>, skip: number, limit: number) => {
                const { riskIdToScopeIdToIdentityPermissionsMap, riskModelPage } =
                    await getRiskModelPage(
                        filterMap,
                        limit,
                        skip,
                        sort);
                riskIdToScopeIdsMapRef.current =
                    _.merge(
                        riskIdToScopeIdsMapRef.current,
                        _.mapValues(
                            riskIdToScopeIdToIdentityPermissionsMap,
                            scopeIdToIdentityPermissionsMap => _.keys(scopeIdToIdentityPermissionsMap)));

                return new DataTableFetchItemsResult(
                    { count: riskModelPage.count! },
                    riskModelPage.items,
                    !riskModelPage.hasMore,
                    {
                        itemIdToPermissionsMap:
                            _.mapValues(
                                riskIdToScopeIdToIdentityPermissionsMap,
                                scopeIdToIdentityPermissionsMap =>
                                    _(scopeIdToIdentityPermissionsMap).
                                        values().
                                        flatMap().
                                        uniq().
                                        value()),
                        onAppendData:
                            () => {
                                viewRef.current = view;
                            }
                    });
            },
            [getRiskModelPage, riskIdToScopeIdsMapRef, view]);

    const getSelectedRiskIdToScopeIdsMap =
        useCallback(
            () =>
                _(dataTableActionsRef.current!.getSelectedItemIds()).
                    keyBy(selectedRiskId => selectedRiskId).
                    mapValues(selectedRiskId => riskIdToScopeIdsMapRef.current![selectedRiskId]).
                    value(),
            [dataTableActionsRef, riskIdToScopeIdsMapRef]);

    const reloadRiskModels =
        useCallback(
            () => dataTableActionsRef.current!.reload(),
            []);

    useActions<TableActions>(
        actionsRef,
        {
            fetchItems() {
                dataTableActionsRef.current!.fetchItems();
            },
            getDataTableActionRef() {
                return dataTableActionsRef;
            },
            async reloadRiskModels() {
                return await reloadRiskModels();
            },
            reset(options) {
                dataTableActionsRef.current!.reset(options);
            },
            setSelectedItemIds(selectedItemIds: string[]) {
                dataTableActionsRef.current!.setSelectedItemIds(selectedItemIds);
            }
        });

    const identityPermissionsTranslator = useIdentityPermissionsTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.items.table",
            () => ({
                empty: {
                    withFilter: "No Matching Findings",
                    withoutFilter: "No Findings"
                }
            }));
    const theme = useTheme();
    const filtersOptions =
        useMemo(
            (): DataTableFiltersOptions => ({
                external: filterExternalOptions,
                itemCount: false,
                onChanged: filterMap => onFiltersChanged?.(filterMap),
                persist: {
                    filterMap: false
                },
                sx: {
                    ...(grouped && {
                        padding: theme.spacing(1.5, 2.5)
                    })
                },
                waitForReset
            }),
            [filterExternalOptions, onFiltersChanged, waitForReset]);

    const selectionActionsElements =
        useMemo(
            () =>
                riskTypeDefinition.selectionActionElements?.(
                    {
                        clearSelection: () => dataTableActionsRef.current!.setSelectedItemIds([]),
                        getSelectedRiskIdToScopeIdsMap,
                        reloadRiskModels
                    }),
            [dataTableActionsRef, reloadRiskModels, riskTypeDefinition]);

    return (
        <DataTable
            actionsRef={dataTableActionsRef}
            columnOptions={{
                orderOptions: {
                    enabled: true,
                    persistenceStorageItem:
                        grouped
                            ? riskTypeDefinition.storageOptions.grouped.getColumnOrderStorageItem(groupId)
                            : riskTypeDefinition.storageOptions.ungrouped.columnOrder
                },
                resizable: true,
                selectorOptions: {
                    enabled: grouped,
                    enabledExternal: !grouped,
                    onChanged: onSelectorChanged,
                    persistenceStorageItem:
                        grouped
                            ? riskTypeDefinition.storageOptions.grouped.getColumnSelectorStorageItem(groupId)
                            : riskTypeDefinition.storageOptions.ungrouped.columnSelector
                }
            }}
            emptyMessageOptions={{
                emptyMessageText:
                    new EmptyMessageText(
                        filtered
                            ? localization.empty.withFilter()
                            : localization.empty.withoutFilter())
            }}
            fetchItems={fetchRiskModels}
            filtersOptions={filtersOptions}
            getItemId={(item: Contract.RiskModel) => item.risk.id}
            highlightItem={{
                getItemId: (item: Contract.RiskModel) => RiskHelper.getSideViewItemId(riskType, item.id),
                hashRouteTemplate: `${CustomerSideViewItemType.Risk}/{itemId}`
            }}
            manualScroll={grouped}
            pageSize={pageSize}
            rowOptions={riskTypeDefinition.rowOptions}
            selectionOptions={{
                disabled: sideViewState.open &&
                    !sideViewState.inside,
                getItemIdToPermissionsMap: filterMap => getRiskIdToScopeIdentityPermissionsMap(filterMap),
                onChanged:
                    (selectedItemIds, selectionAccessLevelRange) =>
                        onSelectionChanged?.(
                            selectedItemIds,
                            selectionAccessLevelRange),
                permissionsTranslator: permissions => identityPermissionsTranslator(permissions as Contract.IdentityPermission[]),
                showActions: !grouped &&
                    (!sideViewState.open || sideViewState.inside)
            }}
            sx={sx}
            variant={
                grouped
                    ? "card"
                    : undefined}
            virtualizationEnabled={!grouped}
            onItemCountChanged={itemCount => onRiskModelCountChanged?.(itemCount)}
            onLoadedItemsChanged={loadedItems => onLoadedRiskModelsChanged?.(loadedItems as Contract.RiskModel[])}
            onSortChanged={sort => onSortChanged?.(sort)}>
            {selectionActionsElements}
            {columns}
            {children}
        </DataTable>);
}

export enum TableColumnId {
    AadDirectoryGroupInactiveRiskGroupIdentityExists = "aadDirectoryGroupInactiveRiskGroupIdentityExists",
    Actions = "actions",
    AwsBehaviorIdentityRiskEventActions = "awsBehaviorIdentityRiskEventActions",
    AwsBehaviorIdentityRiskResourceIds = "awsBehaviorIdentityRiskResourceIds",
    AwsEc2InstanceMetadataServiceVersionRiskRiskedEntityTypeName = "awsEc2InstanceMetadataServiceVersionRiskRiskedEntityTypeName",
    AwsEc2InstanceMetadataServiceVersionV1Used = "awsEc2InstanceMetadataServiceVersionV1Used",
    AwsEc2NetworkResourceInboundRuleSubnetAnyExistsRiskUnrestrictedDestinationNetworkScopes = "awsEc2NetworkResourceInboundRuleSubnetAnyExistsRiskUnrestrictedDestinationNetworkScopes",
    AwsEcsTaskDefinitionEnvironmentVariableSecretExistsRiskEcsTaskDefinitionStatus = "awsEcsTaskDefinitionEnvironmentVariableSecretExistsRiskEcsTaskDefinitionStatus",
    AwsEcsTaskDefinitionEnvironmentVariableSecretExistsRiskRegionSystemName = "awsEcsTaskDefinitionEnvironmentVariableSecretExistsRiskRegionSystemName",
    AwsExcessivePermissionPrincipalRiskExcessivePermissionServices = "awsExcessivePermissionPrincipalRiskExcessivePermissionServices",
    AwsExcessivePermissionPrincipalRiskGroupUserIds = "awsExcessivePermissionPrincipalRiskGroupUserIds",
    AwsExcessivePermissionPrincipalRiskNonexcessivePermissionServices = "awsExcessivePermissionPrincipalRiskNonexcessivePermissionServices",
    AwsIamGroupInactiveRiskGroupUserExists = "awsIamGroupInactiveRiskGroupUserExists",
    AwsIamRoleVendorAssumeRolePolicyDocumentExternalIdConditionNotExistRiskPermissionsRiskSeverity = "awsIamRoleVendorAssumeRolePolicyDocumentExternalIdConditionNotExistRiskPermissionsRiskSeverity",
    AwsLambdaFunctionConfigurationPublicAccessExistsRiskPublicAccessTypes = "awsLambdaFunctionConfigurationPublicAccessExistsRiskPublicAccessTypes",
    AwsLambdaFunctionConfigurationPublicAccessExistsRiskTriggerResources = "awsLambdaFunctionConfigurationPublicAccessExistsRiskTriggerResources",
    AwsSecretsManagerSecretRotationDisabledRiskCreationTime = "awsSecretsManagerSecretRotationDisabledRiskCreationTime",
    AwsSecretsManagerSecretRotationDisabledRiskRotationTime = "awsSecretsManagerSecretRotationDisabledRiskRotationTime",
    AwsSecretsManagerSecretRotationDisabledRiskUsageTime = "awsSecretsManagerSecretRotationDisabledRiskUsageTime",
    AzureBehaviorIdentityRiskEventActions = "azureBehaviorIdentityRiskEventActions",
    AzureBehaviorIdentityRiskResourceIds = "azureBehaviorIdentityRiskResourceIds",
    Compliances = "compliances",
    CustomPolicyName = "customPolicyName",
    Description = "description",
    GciDirectoryGroupInactiveRiskGroupIdentityExists = "gciDirectoryGroupInactiveRiskGroupIdentityExists",
    GcpBehaviorIdentityRiskEventActions = "gcpBehaviorIdentityRiskEventActions",
    GcpBehaviorIdentityRiskResourceIds = "gcpBehaviorIdentityRiskResourceIds",
    IgnoreExpirationDate = "ignoreExpirationDate",
    NetworkInboundExternalResourceRiskDestinationNetworkScopes = "networkInboundExternalResourceRiskDestinationNetworkScopes",
    NetworkInboundExternalResourceRiskSourceSubnets = "networkInboundExternalResourceRiskSourceSubnets",
    OpenStatusUpdateTime = "openStatusUpdateTime",
    ResourceEnvironment = "resourceEnvironment",
    ResourceOwner = "resourceOwner",
    RiskedEntityAttributes = "riskedEntityAttributes",
    RiskedEntityIds = "riskedEntityIds",
    RiskPolicyId = "riskPolicyId",
    Severity = "severity",
    Status = "status",
    StatusUpdateTime = "statusUpdateTime",
    SubStatus = "subStatus",
    SystemCreationTime = "systemCreationTime",
    TenantId = "tenantId"
}