import { InlineItems, Link, useLocalization } from "@infrastructure";
import React from "react";
import { Contract, Entity, entityModelStore, useEntityTypeNameTranslator, useTheme } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem, RiskDefinitionSection } from "../../../../../../utilities";
import { ResourceTagSecretExistsRiskTagsSection } from "../../../../components";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { useResourceSecretExistsRiskContext } from "../../../useResourceSecretExistsRiskContext";
import { EntityExternalConsoleLink } from "../../components";
import { useGetAzureResourceRiskContext } from "../contexts";

export function useAzureResourceTagSecretExistsRiskDefinition(riskModel: Contract.RiskModel) {
    const resourceTagSecretExistsRiskModel = riskModel as Contract.AzureResourceTagSecretExistsRiskModel;
    const resourceModel = entityModelStore.useGet(resourceTagSecretExistsRiskModel.risk.entityId) as Contract.AzureResourceModel;
    const resourceTagSecretExistsRisk = resourceTagSecretExistsRiskModel.risk;

    const getAzureResourceRiskContext = useGetAzureResourceRiskContext();
    const secretRiskContext = useResourceSecretExistsRiskContext();

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.compliance.useAzureResourceTagSecretExistsRiskDefinition.useAzureResourceTagSecretExistsRiskDefinition",
            () => ({
                contextItems: {
                    secretTags: "The {{resourceTypeName}} has {{tags}} exposing secrets in clear text",
                    tags: [
                        "1 tag",
                        "{{count | NumberFormatter.humanize}} tags"
                    ]
                },
                description: "{{resource}} is exposing secrets via tags in clear text",
                sections: {
                    resolution: {
                        step1: "Click on **Tags**",
                        step2: {
                            link: "relevant Azure documentation",
                            text: "For all tags that expose secrets, migrate the secrets to Azure Key Vault. For more information, refer to {{keyVaultCreateSecretDocumentationLink}}"
                        },
                        step3: "Before removing the tags, update applications to use the secrets from the KeyVault",
                        step4: "Click the **Delete** button on the right of the relevant tag"
                    },
                    Tags: "Tags"
                }
            }));

    const theme = useTheme();
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            resource:
                <Entity
                    entityIdOrModel={resourceTagSecretExistsRiskModel.risk.entityId}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={resourceTagSecretExistsRiskModel.risk.entityId}
                key={resourceTagSecretExistsRiskModel.risk.entityId}/>,
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2.text({
                keyVaultCreateSecretDocumentationLink:
                    <Link
                        urlOrGetUrl={resourceTagSecretExistsRiskModel.keyVaultCreateSecretDocumentationUrl}
                        variant="external">
                        {localization.sections.resolution.step2.link()}
                    </Link>
            }),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4()
        ],
        resourceTagSecretExistsRiskModel,
        () => {
            const resourceRiskContext = getAzureResourceRiskContext(resourceModel);
            return [
                resourceRiskContext.generalInformation,
                resourceRiskContext.sensitive,
                new RiskDefinitionContextItem(
                    localization.contextItems.secretTags({
                        resourceTypeName:
                            entityTypeNameTranslator(
                                resourceTagSecretExistsRisk.riskedEntityTypeName,
                                {
                                    includeServiceName: false,
                                    variant: "text"
                                }),
                        tags:
                            <InlineItems
                                items={resourceTagSecretExistsRisk.secretTagKeys}
                                namePluralizer={localization.contextItems.tags}
                                sx={{ color: theme.palette.text.primary }}
                                variant="itemCountAndType"/>
                    })),
                secretRiskContext.getExcludedSecretsContextItem(
                    resourceTagSecretExistsRisk.excludedSecretTagKeys,
                    resourceTagSecretExistsRisk.typeName,
                    resourceTagSecretExistsRisk.tenantId),
                resourceRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.risk.id)
            ];
        },
        {
            sections: [
                new RiskDefinitionSection(
                    <ResourceTagSecretExistsRiskTagsSection
                        risk={resourceTagSecretExistsRiskModel.risk}
                        secretTagKeys={resourceTagSecretExistsRiskModel.risk.secretTagKeys}
                        tags={
                            resourceModel.unknown
                                ? []
                                : (resourceModel.entity as Contract.AzureResource).tags}/>,
                    localization.sections.Tags(),
                    {
                        expandable: true
                    })
            ]
        });
}