import { Grid } from "@mui/material";
import React from "react";
import { Contract, useTheme } from "../../../../../../../../../../common";
import { GroupedItem } from "../../../../../../../../../Customer/components";
import { Common, Custom, RiskPolicyCategory } from "./components";

type TitleProps = {
    categoryTitle: boolean;
    groupedItem: GroupedItem;
};

export function Title({ categoryTitle, groupedItem }: TitleProps) {
    const theme = useTheme();
    return (
        <Grid container={true}>
            {categoryTitle &&
                <Grid
                    item={true}
                    style={{ paddingBottom: theme.spacing(2.5) }}>
                    <RiskPolicyCategory riskPolicyCategory={groupedItem.riskPolicyCategory}/>
                </Grid>}
            <Grid
                alignItems="center"
                container={true}
                justifyContent="space-between"
                style={{
                    backgroundColor: theme.palette.background.paper,
                    border: theme.border.primary,
                    borderBottom: theme.border.primary,
                    borderRadius: theme.spacing(0.75, 0.75, 0, 0),
                    height: theme.spacing(5),
                    padding: theme.spacing(0, 1),
                    width: "100%"
                }}>
                {groupedItem.riskPolicyCategory === Contract.RiskPolicyCategory.Custom
                    ? <Custom/>
                    : <Common/>}
            </Grid>
        </Grid>);
}