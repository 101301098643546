import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function AzurePipelineIcon(props: SvgIconProps) {
    return (
        <SvgIcon
            sx={{ fontSize: "24px" }}
            {...props}>
            <path
                d="M8.60039 19.9934L11.5855 22.9786H16.4307C17.0205 22.9786 17.4953 22.4977 17.4953 21.914V10.7235L8.60039 19.6214C8.49454 19.7212 8.49454 19.8876 8.60039 19.9934Z"
                fill="#154BBF"/>
            <path
                d="M1 7.55068V12.3959L3.98514 15.381C4.091 15.4869 4.25432 15.4869 4.35413 15.381L13.2581 6.48002H2.06763C1.48089 6.48002 1 6.96091 1 7.55068Z"
                fill="#154BBF"/>
            <path
                d="M12.2028 2.12486L5.01667 13.1188C4.77471 13.4938 4.8231 13.9838 5.13764 14.2983L9.74087 18.9015C10.0554 19.2161 10.5514 19.2645 10.9204 19.0225L21.9143 11.8334C22.616 11.3767 23.0334 10.5903 23.0334 9.75859V2.09159C23.0334 1.48972 22.5434 0.999756 21.9415 0.999756H14.2836C13.4458 0.999756 12.6655 1.42318 12.2028 2.12486Z"
                fill="#2D66E1"/>
            <path
                d="M2.61809 21.4664V17.5799H1V22.9998H6.50149V21.4664H2.61809Z"
                fill="#91B0F2"/>
            <path
                d="M17.5225 9.14148C19.0347 9.14148 20.2596 7.91657 20.2596 6.40434C20.2596 4.89211 19.0347 3.6672 17.5225 3.6672C16.0103 3.6672 14.7853 4.89211 14.7853 6.40434C14.7853 7.91657 16.0103 9.14148 17.5225 9.14148Z"
                fill="#94B2F2"/>
            <path
                d="M12.6216 10.0721L6.76824 15.9255L8.11769 17.2749L13.971 11.4216L12.6216 10.0721Z"
                fill="#5082F0"/>
            <path
                d="M6.76728 15.9273L5.60388 17.0907L6.95334 18.4402L8.11673 17.2768L6.76728 15.9273Z"
                fill="#749BF2"/>
        </SvgIcon>);
}