﻿import { InlineItems, useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { GciPrincipalReferenceCell } from "../../../../../../../../../../../../tenants";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { EntityExternalConsoleLink } from "../../components";
import { useGetGcpTenantEntityRiskContext } from "../contexts";

export function useGcpLoggingAuditLogTenantDefaultNotEnabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.GcpLoggingAuditLogTenantDefaultNotEnabledRisk;
    const tenantEntityModel = entityModelStore.useGet(risk.entityId) as Contract.GcpTenantEntityModel;

    const getGcpTenantEntityRiskContext = useGetGcpTenantEntityRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.useGcpLoggingAuditLogTenantDefaultNotEnabledRiskDefinition",
            () => ({
                description: {
                    disabledAuditLogTypes: {
                        [Contract.TypeNames.GcpAuditLogType]: {
                            [Contract.GcpAuditLogType.AdminRead]: "admin read",
                            [Contract.GcpAuditLogType.AdminWrite]: "admin write",
                            [Contract.GcpAuditLogType.DataRead]: "data read",
                            [Contract.GcpAuditLogType.DataWrite]: "data write"
                        },
                        text: "{{tenantEntity}} does not have audit logs enabled on {{disabledAuditLogTypes}} operations"
                    },
                    excludedPrincipalReferences: {
                        excludedPrincipalReferences: [
                            "1 principal",
                            "{{count | NumberFormatter.humanize}} principals"
                        ],
                        text: "{{tenantEntity}} does not have audit logs generated for {{excludedPrincipalReferences}}"
                    }
                },
                sections: {
                    resolution: {
                        step1: "Check all the log types: **Admin Read, Data Read and Data Write**",
                        step2: "Switch to the **Exempted users** tab and delete all the users configured",
                        step3: "Click Save to confirm"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        _.some(
            tenantEntityModel.auditLogTypeToEnabledMap,
            (enabled, _) => !enabled)
            ? localization.description.disabledAuditLogTypes.text({
                disabledAuditLogTypes:
                    <Typography component="span">
                        {_(tenantEntityModel.auditLogTypeToEnabledMap).
                            pickBy((enabled, _) => !enabled).
                            map((_, auditLogType) => localization.description.disabledAuditLogTypes[Contract.TypeNames.GcpAuditLogType][auditLogType as Contract.GcpAuditLogType]()).
                            join(", ")}
                    </Typography>,
                tenantEntity:
                    <Entity
                        entityIdOrModel={risk.entityId}
                        entityTypeNameTranslatorOptions={{ variant: "title" }}
                        variant="typeText"/>
            })
            : localization.description.excludedPrincipalReferences.text({
                excludedPrincipalReferences:
                    <InlineItems
                        items={tenantEntityModel.excludedPrincipalReferences}
                        namePluralizer={localization.description.excludedPrincipalReferences.excludedPrincipalReferences}
                        variant="itemCountAndType">
                        {(principalReference: Contract.GciPrincipalReference) =>
                            <GciPrincipalReferenceCell principalReference={principalReference}/>}
                    </InlineItems>,
                tenantEntity:
                    <Entity
                        entityIdOrModel={risk.entityId}
                        entityTypeNameTranslatorOptions={{ variant: "title" }}
                        variant="typeText"/>
            }),
        () => [
            <EntityExternalConsoleLink
                entityId={risk.entityId}
                key={risk.entityId}
                page={Contract.GcpConsolePage.AuditLogs}/>,
            localization.sections.resolution.step1(),
            _.isEmpty(tenantEntityModel.excludedPrincipalReferences)
                ? undefined
                : localization.sections.resolution.step2(),
            localization.sections.resolution.step3()
        ],
        riskModel,
        () => {
            const tenantEntityRiskContext = getGcpTenantEntityRiskContext(tenantEntityModel);
            return [
                tenantEntityRiskContext.generalInformation,
                tenantEntityRiskContext.sensitive,
                tenantEntityRiskContext.auditLogTypes,
                tenantEntityRiskContext.excludedPrincipalReferences,
                tenantEntityRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}