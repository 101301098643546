﻿import { CollapsedIcon, Optional } from "@infrastructure";
import { Box, Grid2, Stack } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, Scope, ScopeNode, scopeNodeModelStore, useTheme } from "..";

type ScopePathProps = {
    onClick?: (parentScopeId: string) => void;
    scopeId: string;
    variant?: "customer" | "rootFolder";
};

export function ScopePath({ onClick, scopeId, variant = "rootFolder" }: ScopePathProps) {
    const scopeNodeMap = scopeNodeModelStore.useGetScopeNodeMap(undefined, true);
    const pathScopeNodeModels =
        useMemo(
            () => {
                const pathScopeNodeModels = [];
                let scopeNode: Optional<ScopeNode> = scopeNodeMap[scopeId];
                while (!_.isNil(scopeNode) && (scopeNode.scopeNodeModel.type !== Contract.ScopeType.Customer || variant === "customer")) {
                    pathScopeNodeModels.push(scopeNode.scopeNodeModel);
                    scopeNode = scopeNode.parentScopeNode;
                }

                return _.reverse(pathScopeNodeModels);
            },
            [scopeId, scopeNodeMap, variant]);

    const theme = useTheme();
    return (
        <Grid2
            alignItems="center"
            container={true}
            rowGap={theme.spacing(1)}>
            {_.map(
                pathScopeNodeModels,
                (pathScopeNodeModel, pathScopeNodeModelIndex) =>
                    <Grid2 key={pathScopeNodeModel.configuration.id}>
                        <Stack
                            alignItems="center"
                            direction="row">
                            {pathScopeNodeModelIndex !== 0 && (
                                <CollapsedIcon sx={{ fontSize: "18px" }}/>)}
                            <Box onClick={() => onClick?.(pathScopeNodeModel.configuration.id)}>
                                <Scope
                                    scopeId={pathScopeNodeModel.configuration.id}
                                    sx={{
                                        cursor:
                                            _.isNil(onClick)
                                                ? undefined
                                                : "pointer",
                                        fontSize: "13px",
                                        fontWeight:
                                            pathScopeNodeModelIndex === pathScopeNodeModels.length - 1
                                                ? "bold"
                                                : undefined,
                                        maxWidth: theme.spacing(20)
                                    }}/>
                            </Box>
                        </Stack>
                    </Grid2>)}
        </Grid2>);
}