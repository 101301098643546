import { Loading, useLocalization, useRoute, useSetRoute, VerticalTabView } from "@infrastructure";
import { Stack } from "@mui/material";
import _ from "lodash";
import React, { ReactNode, useEffect, useMemo } from "react";
import { codeContainerImageScanStore, CodeTenantLink, Contract, CustomerConsoleAppUrlHelper, ProfileLayout, ProfileTopbar, ProfileTopbarBranchInfoItem, ProfileTopbarCommitInfoItem, ProfileTopbarTimeInfoItem, ScansIcon, StorageHelper, tenantModelStore, useTheme } from "../../../../../../../../../../common";
import { Overview, Packages, ProfileTopbarUserInfoItem, Vulnerabilities } from "./components";
import { CategoryIcon } from "./icons";
import { ProfileCategory } from "./utilities";

type ProfileProps = {
    scanId: string;
};

export function Profile({ scanId }: ProfileProps) {
    const scan = codeContainerImageScanStore.useGet(scanId);
    const tenantConfiguration = tenantModelStore.useGet(scan.tenantId).configuration as Contract.CodeTenantConfiguration;

    const { category } = useRoute(`${CustomerConsoleAppUrlHelper.getWorkloadAnalysisCodeScanProfileHashUrl(scanId)}/{category}`);
    const [categories, categoryToDefinitionMap] =
        useMemo(
            () => {
                const categoryToDefinitionMap =
                    _<ScanProfileCategoryDefinition>([]).
                        concat(
                            new ScanProfileCategoryDefinition(
                                ProfileCategory.Overview,
                                <Overview scan={scan}/>)).
                        concatIf(
                            !_.isEmpty(scan.vulnerabilityRawIds),
                            new ScanProfileCategoryDefinition(
                                ProfileCategory.Vulnerabilities,
                                <Vulnerabilities scan={scan}/>)).
                        concat(
                            new ScanProfileCategoryDefinition(
                                ProfileCategory.Packages,
                                <Packages scan={scan}/>)).
                        keyBy(definition => definition.category).
                        value();

                const categories =
                    _(categoryToDefinitionMap).
                        values().
                        map(definition => definition.category).
                        value();

                return [categories, categoryToDefinitionMap];
            },
            [scan]);

    const setRoute = useSetRoute();
    useEffect(
        () => {
            if (_.isNil(category)) {
                setRoute(
                    CustomerConsoleAppUrlHelper.getWorkloadAnalysisCodeScanProfileHashUrl(scanId, ProfileCategory.Overview),
                    undefined,
                    { appendBrowserHistory: false });
            }
        },
        []);

    const currentCategory =
        useMemo(
            () =>
                _.includes(categories, category)
                    ? category as ProfileCategory
                    : _.first(categories)!,
            [category, categories]);

    const localization =
        useLocalization(
            "views.customer.workloadAnalysis.code.scans.profile",
            () => ({
                commitIdentityName: "User",
                tabs: {
                    categoryView: {
                        [ProfileCategory.Overview]: "Overview",
                        [ProfileCategory.Packages]: "Software",
                        [ProfileCategory.Vulnerabilities]: "Vulnerabilities"
                    }
                },
                time: "Scan Time",
                title: "Container Image CI/CD Scan"
            }));

    const infoChildren =
        useMemo(
            () =>
                _<ReactNode>([]).
                    concat(
                        <CodeTenantLink
                            iconSx={{ fontSize: "16px" }}
                            key="codeTenantLink"
                            spacing={0.5}
                            tenantConfiguration={tenantConfiguration}/>).
                    concatIf(
                        !_.isNil(scan.commit.branchName),
                        <ProfileTopbarBranchInfoItem
                            branchName={scan.commit.branchName!}
                            codeTenantType={tenantConfiguration.codeTenantType}
                            key="commitBranchName"
                            url={tenantConfiguration.url}/>).
                    concat(
                        <ProfileTopbarCommitInfoItem
                            codeTenantType={tenantConfiguration.codeTenantType}
                            hash={scan.commit.hash}
                            key="commitHash"
                            url={tenantConfiguration.url}/>,
                        <ProfileTopbarUserInfoItem
                            key="commitIdentityName"
                            name={scan.commit.identityName}/>,
                        <ProfileTopbarTimeInfoItem
                            key="scannedTime"
                            time={scan.time}
                            type="scanned"/>).
                    value(),
            [scan, tenantConfiguration]);

    const theme = useTheme();
    return (
        <ProfileLayout
            topBarElement={
                <ProfileTopbar
                    icon={<ScansIcon/>}
                    infoChildren={infoChildren}
                    title={localization.title()}/>}>
            <VerticalTabView
                items={
                    _.map(
                        categories,
                        category => ({
                            icon: <CategoryIcon category={category}/>,
                            title: localization.tabs.categoryView[category](),
                            view: category
                        }))}
                selectedView={currentCategory}
                storageItem={StorageHelper.customerWorkloadAnalysisCodeScanProfileTabsOpen}
                onSelectedViewChanged={
                    view => {
                        StorageHelper.customerWorkloadAnalysisCodeScanProfileSelectedTab.setValue(view ?? ProfileCategory.Overview);
                        setRoute(CustomerConsoleAppUrlHelper.getWorkloadAnalysisCodeScanProfileHashUrl(scanId, view as ProfileCategory));
                    }}>
                <Stack
                    sx={{
                        height: "100%",
                        padding: theme.spacing(2, 2, 0, 2)
                    }}>
                    {!_.isNil(categoryToDefinitionMap[currentCategory]) &&
                        <Loading>
                            {categoryToDefinitionMap[currentCategory].contentElement}
                        </Loading>}
                </Stack>
            </VerticalTabView>
        </ProfileLayout>);
}

export class ScanProfileCategoryDefinition {
    constructor(
        public category: ProfileCategory,
        public contentElement: ReactNode) {
    }
}