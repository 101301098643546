import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function AlexaIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                d="M20.4853 3.51472C18.745 1.77439 16.5116 0.611276 14.0879 0.183041C11.6643 -0.245194 9.16757 0.082164 6.93624 1.12074C4.70491 2.15931 2.84688 3.85887 1.61404 5.98903C0.38119 8.11918 -0.166852 10.5769 0.0442027 13.029C0.255257 15.4812 1.2152 17.8091 2.7939 19.6973C4.37261 21.5854 6.49371 22.9425 8.86969 23.5845C11.2457 24.2265 13.7616 24.1225 16.0764 23.2864C18.3913 22.4503 20.393 20.9227 21.8104 18.9106L19.8803 17.551C18.7418 19.1673 17.1339 20.3943 15.2744 21.0659C13.415 21.7375 11.3941 21.8211 9.48554 21.3054C7.57701 20.7897 5.8732 19.6996 4.60509 18.1829C3.33698 16.6662 2.56589 14.7963 2.39636 12.8266C2.22683 10.8569 2.66705 8.88269 3.65735 7.17161C4.64765 5.46054 6.14013 4.09535 7.93247 3.2611C9.72482 2.42685 11.7303 2.1639 13.6772 2.50788C15.624 2.85187 17.418 3.78615 18.8159 5.18409L20.4853 3.51472Z"
                fill="#0068A8"/>
        </SvgIcon>);
}