import { Message, Optional } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import React, { ReactNode } from "react";
import { Contract, useTheme } from "../../../../../common";
import { useWorkloadAnalysisErrorTranslator, useWorkloadAnalysisScanStatusTranslator } from "../hooks";

export type WorkloadAnalysisScanStatusCellProps = {
    azureKeyVaultId?: string;
    resourceType: Contract.WorkloadAnalysisWorkloadResourceType.VirtualMachine | Contract.WorkloadAnalysisWorkloadResourceType.VirtualMachineImage;
    workloadAnalysisError: Optional<Contract.WorkloadAnalysisError>;
    workloadAnalysisStatus: Contract.WorkloadAnalysisStatus;
};

export function WorkloadAnalysisScanStatusCell({ azureKeyVaultId, resourceType, workloadAnalysisError, workloadAnalysisStatus }: WorkloadAnalysisScanStatusCellProps) {
    const workloadAnalysisErrorTranslator = useWorkloadAnalysisErrorTranslator();
    return <ScanStatusCell
        status={workloadAnalysisStatus}
        tooltip={
            workloadAnalysisErrorTranslator(
                resourceType,
                workloadAnalysisError,
                azureKeyVaultId)}/>;
}

type ScanStatusCellParameters = {
    status: Contract.WorkloadAnalysisStatus;
    tooltip?: string | ReactNode;
};

function ScanStatusCell({ status, tooltip }: ScanStatusCellParameters) {
    const workloadAnalysisScanStatusTranslator = useWorkloadAnalysisScanStatusTranslator();
    const theme = useTheme();
    return (
        <Stack
            direction="row"
            spacing={1}
            sx={{ paddingRight: theme.px(1) }}>
            <Typography noWrap={true}>
                {workloadAnalysisScanStatusTranslator(status)}
            </Typography>
            {tooltip &&
                <Message
                    level="info"
                    title={
                        <Typography sx={{ whiteSpace: "pre-wrap" }}>
                            {tooltip}
                        </Typography>}
                    variant="minimal"/>}
        </Stack>);
}