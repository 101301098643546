import { useLocalization } from "@infrastructure";
import React from "react";
import { RiskPoliciesType } from "../../../../../../../common";
import { ConfigurationCategory } from "../../../components";
import { ApiGatewayRouteExclusion } from "../../../components/Configuration/components/ApiGatewayRouteExclusion/ApiGatewayRouteExclusion";
import { RiskPolicyConfigurationDefinitionData } from "../useConfigurationDefinition";

export function useAwsLambdaFunctionConfigurationPublicAccessExistsRiskPolicyConfigurationDefinition(riskPoliciesType: RiskPoliciesType): RiskPolicyConfigurationDefinitionData {
    const localization =
        useLocalization(
            "views.customer.riskPolicies.hooks.useConfigurationDefinition.hooks.useAwsLambdaFunctionConfigurationPublicAccessExistsRiskPolicyConfigurationDefinition",
            () => ({
                [ConfigurationCategory.ApiGatewayRouteExclusions]: "API Gateway Path Exclusions"
            }));
    return {
        additionalTabs: [
            {
                element:
                    <ApiGatewayRouteExclusion
                        key={ConfigurationCategory.ApiGatewayRouteExclusions}
                        riskPoliciesType={riskPoliciesType}
                        title={localization[ConfigurationCategory.ApiGatewayRouteExclusions]()}/>,
                id: ConfigurationCategory.ApiGatewayRouteExclusions
            }
        ]
    };
}