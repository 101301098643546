﻿import { Optional, PagedValuesFilterValuePage } from "@infrastructure";
import _ from "lodash";
import { useCallback } from "react";
import { PagedEntityFilterEntityIdPage, PagedEntityMultiSelectPage, PagedEntitySelectorPage } from "../components";
import { AuditEventController, Contract, EntityController, RiskController } from "../controllers";

export function useMakePagedEntityFilterEntityItem(entityTypeName: Contract.TypeNames, property: Contract.EntityModelProperty){
    return useCallback(
        ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
            entityTypeName,
            property),
        [entityTypeName, property]);
}

export function useMakePagedEntityFilterValueItem(entityTypeName: Optional<Contract.TypeNames>, property: Contract.EntityModelProperty, transformItem?: (item: any) => string){
    return useCallback(
        ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
            entityTypeName,
            property,
            transformItem),
        [entityTypeName, property, transformItem]);
}

export class ElasticsearchItemPageHelper {
    public static isEmpty(filterItems: Contract.FilterItems<any>) {
        return _.isEmpty(filterItems.items) && !filterItems.emptyValue;
    }

    public static makePagedEntityFilter(getItemPage: (itemNextPageSearchCursor: Optional<Contract.ElasticsearchIndexSearchCursor>, searchText: Optional<string>, limit: number) => Promise<Contract.ElasticsearchItemPage<string>>, emptyValue = false) {
        return async (searchText: Optional<string>, skip: number, limit: number, data?: any) =>
            await ElasticsearchItemPageHelper.getPagedComponentItemPage<string, PagedEntityFilterEntityIdPage>(
                (itemNextPageSearchCursor, searchText) =>
                    getItemPage(
                        itemNextPageSearchCursor,
                        searchText,
                        limit),
                itemPage => {
                    const itemCount = itemPage?.count ?? data?.count ?? 0;
                    return new PagedEntityFilterEntityIdPage(
                        itemCount,
                        emptyValue,
                        itemPage.items,
                        () => ({
                            count: itemCount,
                            itemNextPageSearchCursor: itemPage.itemNextPageSearchCursor
                        }));
                },
                skip,
                data,
                searchText);
    }

    public static makePagedEntityFilterEntityItem(entityTypeName: string, property: Contract.EntityModelProperty) {
        return async (searchText: Optional<string>, skip: number, limit: number, data?: number) => {
            const { entityModelFilterItemPage } =
                await EntityController.getEntityModelFilterItemPage(
                    new Contract.EntityControllerGetEntityModelFilterItemPageRequest(
                        entityTypeName,
                        limit,
                        property,
                        undefined,
                        searchText,
                        skip,
                        undefined)) as Contract.EntityControllerGetEntityModelFilterItemPageResponse<string>;

            const count = entityModelFilterItemPage?.count ?? data ?? 0;
            return new PagedEntityFilterEntityIdPage(
                count,
                entityModelFilterItemPage?.emptyValue,
                entityModelFilterItemPage.items,
                () => count);
        };
    }

    public static makePagedEntityFilterValueItem(entityTypeName: Optional<string>, property: Contract.EntityModelProperty, transformItem?: (item: any) => string) {
        return async (searchText: Optional<string>, skip: number, limit: number, data?: any) => {
            const { entityModelFilterItemPage } =
                await EntityController.getEntityModelFilterItemPage(
                    new Contract.EntityControllerGetEntityModelFilterItemPageRequest(
                        entityTypeName,
                        limit,
                        property,
                        undefined,
                        searchText,
                        skip,
                        undefined)) as Contract.EntityControllerGetEntityModelFilterItemPageResponse<any>;

            const count = entityModelFilterItemPage.count ?? data;
            return new PagedValuesFilterValuePage(
                count,
                entityModelFilterItemPage.emptyValue,
                _.isNil(transformItem)
                    ? entityModelFilterItemPage.items
                    : _.map(
                        entityModelFilterItemPage.items,
                        transformItem),
                () => count);
        };
    }

    public static makePagedEntityMultiSelect(getItemPage: (itemNextPageSearchCursor: Optional<Contract.ElasticsearchIndexSearchCursor>, searchText: Optional<string>) => Promise<Contract.ElasticsearchItemPage<Contract.EntityModel>>) {
        return async (searchText: Optional<string>, skip: number, data?: any) =>
            await ElasticsearchItemPageHelper.getPagedComponentItemPage<Contract.EntityModel, PagedEntityMultiSelectPage>(
                getItemPage,
                itemPage =>
                    new PagedEntityMultiSelectPage(
                        !_.isNil(itemPage.itemNextPageSearchCursor),
                        itemPage.items,
                        () => ({
                            itemNextPageSearchCursor: itemPage.itemNextPageSearchCursor
                        })),
                skip,
                data,
                searchText);
    }

    public static makePagedEntityPropertyFilterEntityItem(entityTypeName: Optional<string>, identifier: Contract.EntityPropertyIdentifier) {
        return async (searchText: Optional<string>, skip: number, limit: number, data?: any) => {
            const { entityModelFilterItemPage } =
                await EntityController.getEntityModelFilterItemPage(
                    new Contract.EntityControllerGetEntityModelPropertyFilterItemPageRequest(
                        entityTypeName,
                        limit,
                        Contract.EntityModelProperty.EntityProperties,
                        undefined,
                        searchText,
                        skip,
                        undefined,
                        identifier)) as Contract.EntityControllerGetEntityModelFilterItemPageResponse<any>;

            const count = entityModelFilterItemPage?.count ?? data ?? 0;
            return new PagedEntityFilterEntityIdPage(
                count,
                entityModelFilterItemPage?.emptyValue,
                entityModelFilterItemPage.items,
                () => count);
        };
    }

    public static makePagedEntityPropertyFilterValueItem(entityTypeName: Optional<string>, identifier: Contract.EntityPropertyIdentifier) {
        return async (searchText: Optional<string>, skip: number, limit: number, data?: any) => {
            const { entityModelFilterItemPage } =
                await EntityController.getEntityModelFilterItemPage(
                    new Contract.EntityControllerGetEntityModelPropertyFilterItemPageRequest(
                        entityTypeName,
                        limit,
                        Contract.EntityModelProperty.EntityProperties,
                        undefined,
                        searchText,
                        skip,
                        undefined,
                        identifier)) as Contract.EntityControllerGetEntityModelFilterItemPageResponse<any>;

            const count = entityModelFilterItemPage?.count ?? data ?? 0;
            return new PagedValuesFilterValuePage(
                count,
                entityModelFilterItemPage?.emptyValue,
                entityModelFilterItemPage.items,
                () => count);
        };
    }

    public static makePagedEntitySelector(getItemPage: (itemNextPageSearchCursor: Optional<Contract.ElasticsearchIndexSearchCursor>, searchText: Optional<string>) => Promise<Contract.ElasticsearchItemPage<Contract.EntityModel>>, onApplyData?: (itemPage: Contract.ElasticsearchItemPage<Contract.EntityModel>) => void) {
        return async (searchText: Optional<string>, skip: number, data?: any) =>
            await ElasticsearchItemPageHelper.getPagedComponentItemPage<Contract.EntityModel, PagedEntitySelectorPage>(
                getItemPage,
                itemPage =>
                    new PagedEntitySelectorPage(
                        !_.isNil(itemPage.itemNextPageSearchCursor),
                        itemPage.items,
                        () => {
                            onApplyData?.(itemPage);
                            return {
                                itemNextPageSearchCursor: itemPage.itemNextPageSearchCursor
                            };
                        }),
                skip,
                data,
                searchText);
    }

    public static makePagedEntityTypeFilter(entityTypeName: string, emptyValue = false) {
        return ElasticsearchItemPageHelper.makePagedEntityFilter(
            async (itemNextPageSearchCursor, searchText, limit) => {
                const { entityIdPage } =
                    await EntityController.searchEntityIds(
                        new Contract.EntityControllerSearchEntityIdsTypeRequest(
                            true,
                            limit,
                            itemNextPageSearchCursor,
                            searchText,
                            entityTypeName));
                return entityIdPage;
            },
            emptyValue);
    }

    public static makePagedPermissionAuditEventFilterEntityItem(auditEventTypeName: string, property: Contract.AuditEventControllerGetAuditEventModelPageRequestProperty) {
        return async (searchText: Optional<string>, skip: number, limit: number, data?: any) => {
            const { permissionAuditEventFilterItemPage } =
                await AuditEventController.getPermissionAuditEventFilterItemPage(
                    new Contract.AuditEventControllerGetPermissionAuditEventFilterItemPageRequest(
                        auditEventTypeName,
                        limit,
                        property,
                        searchText,
                        skip)) as Contract.AuditEventControllerGetPermissionAuditEventFilterItemPageResponse<string>;

            const count = permissionAuditEventFilterItemPage?.count ?? data ?? 0;
            return new PagedEntityFilterEntityIdPage(
                count,
                permissionAuditEventFilterItemPage?.emptyValue,
                permissionAuditEventFilterItemPage.items,
                () => count);
        };
    }

    public static makePagedRiskFilterValueItem(filters: Contract.RiskControllerCloudRiskModelFilters, property: Contract.RiskControllerGetRiskPolicyTypeGroupFilterItemPageRequestProperty, riskPolicyConfigurationTypeName: string) {
        return async (searchText: Optional<string>, skip: number, data?: any) => {
            const { riskPolicyTypeGroupFilterItemPage } =
                await RiskController.getRiskPolicyTypeGroupFilterItemPage(
                    new Contract.RiskControllerGetRiskPolicyTypeGroupFilterItemPageRequest(
                        filters,
                        100,
                        property,
                        riskPolicyConfigurationTypeName,
                        searchText,
                        skip)) as Contract.RiskControllerGetRiskPolicyTypeGroupFilterItemPageResponse<string>;

            const count = riskPolicyTypeGroupFilterItemPage.count ?? data;
            return new PagedValuesFilterValuePage(
                count,
                riskPolicyTypeGroupFilterItemPage.emptyValue,
                riskPolicyTypeGroupFilterItemPage.items,
                () => count);
        };
    }

    public static makePagedRiskFilterEntityItem(filters: Contract.RiskControllerCloudRiskModelFilters, property: Contract.RiskControllerGetRiskPolicyTypeGroupFilterItemPageRequestProperty, riskPolicyConfigurationTypeName: string) {
        return async (searchText: Optional<string>, skip: number, limit: number, data?: number) => {
            const { riskPolicyTypeGroupFilterItemPage } =
                await RiskController.getRiskPolicyTypeGroupFilterItemPage(
                    new Contract.RiskControllerGetRiskPolicyTypeGroupFilterItemPageRequest(
                        filters,
                        limit,
                        property,
                        riskPolicyConfigurationTypeName,
                        searchText,
                        skip)) as Contract.RiskControllerGetRiskPolicyTypeGroupFilterItemPageResponse<string>;

            const count = riskPolicyTypeGroupFilterItemPage?.count ?? data ?? 0;
            return new PagedEntityFilterEntityIdPage(
                count,
                riskPolicyTypeGroupFilterItemPage?.emptyValue,
                riskPolicyTypeGroupFilterItemPage.items,
                () => count);
        };
    }

    private static async getPagedComponentItemPage<TItem, TPagedComponentItemPage>(
        getItemPage: (itemNextPageSearchCursor: Optional<Contract.ElasticsearchIndexSearchCursor>, searchText: Optional<string>) => Promise<Contract.ElasticsearchItemPage<TItem>>,
        getPagedComponentItemPage: (itemPage: Contract.ElasticsearchItemPage<TItem>) => TPagedComponentItemPage,
        skip: number,
        data?: any,
        searchText?: string) {
        const itemPage =
            await getItemPage(
                skip === 0
                    ? undefined
                    : data?.itemNextPageSearchCursor,
                searchText);
        return getPagedComponentItemPage(itemPage);
    }
}