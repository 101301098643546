import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function GrabIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                clipRule="evenodd"
                d="M8.8 7.40005C9.79411 7.40005 10.6 6.59416 10.6 5.60005C10.6 4.60594 9.79411 3.80005 8.8 3.80005C7.80589 3.80005 7 4.60594 7 5.60005C7 6.59416 7.80589 7.40005 8.8 7.40005ZM15.2 7.40005C16.1941 7.40005 17 6.59416 17 5.60005C17 4.60594 16.1941 3.80005 15.2 3.80005C14.2059 3.80005 13.4 4.60594 13.4 5.60005C13.4 6.59416 14.2059 7.40005 15.2 7.40005ZM17 12C17 12.9942 16.1941 13.8 15.2 13.8C14.2059 13.8 13.4 12.9942 13.4 12C13.4 11.0059 14.2059 10.2 15.2 10.2C16.1941 10.2 17 11.0059 17 12ZM8.8 13.8C9.79411 13.8 10.6 12.9942 10.6 12C10.6 11.0059 9.79411 10.2 8.8 10.2C7.80589 10.2 7 11.0059 7 12C7 12.9942 7.80589 13.8 8.8 13.8ZM17 18.4C17 19.3941 16.1941 20.2 15.2 20.2C14.2059 20.2 13.4 19.3941 13.4 18.4C13.4 17.406 14.2059 16.6 15.2 16.6C16.1941 16.6 17 17.406 17 18.4ZM8.8 20.2C9.79411 20.2 10.6 19.3941 10.6 18.4C10.6 17.406 9.79411 16.6 8.8 16.6C7.80589 16.6 7 17.406 7 18.4C7 19.3941 7.80589 20.2 8.8 20.2Z"
                fillRule="evenodd"/>
        </SvgIcon>);
}