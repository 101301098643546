﻿import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function IndonesiaIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                d="M23 19.0001L1 19.0001L1 11.0001L23 11.0001L23 19.0001Z"
                fill="white"/>
            <path
                clipRule="evenodd"
                d="M23 8.00012H2C1.44772 8.00012 1 8.44784 1 9.00012V17.9639C1 18.5162 1.44772 18.9639 2 18.9639H22C22.5523 18.9639 23 18.5162 23 17.9639V8.00012ZM2 7.00012C0.895431 7.00012 0 7.89555 0 9.00012V17.9639C0 19.0685 0.89543 19.9639 2 19.9639H22C23.1046 19.9639 24 19.0685 24 17.9639V7.00012H2Z"
                fill="#BAC5D3"
                fillRule="evenodd"/>
            <path
                d="M0 6.00012C0 4.89555 0.895431 4.00012 2 4.00012H22C23.1046 4.00012 24 4.89555 24 6.00012V12.0001H0V6.00012Z"
                fill="#FF0000"/>
        </SvgIcon>);
}