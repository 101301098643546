import _ from "lodash";
import React from "react";
import { Contract, entityModelStore, EntityPropertyHelper, EntityPropertyValue, tenantModelStore, VerticalTopItemsInfoItem } from "../../../../../../../../../common";

export function useCustomEntityPropertyInfoItemElements(resourceModel: Contract.EntityModel) {
    const tenantModel = tenantModelStore.useGet(resourceModel.tenantId);
    const entityPropertyDefinitionIdentifiers =
        _.isNil(tenantModel)
            ? []
            : EntityPropertyHelper.getDefinitionIdentifiers([tenantModel]);
    const entityPropertyRawIdentifierToPropertyMap =
        _.keyBy(
            resourceModel.properties,
            entityProperty => entityProperty.identifier.raw);
    const principalReferencePropertyEntityModels =
        entityModelStore.useGet(
            _(resourceModel.properties).
                filter(property => property.typeName === Contract.TypeNames.EntityPrincipalReferenceProperty).
                flatMap(property => property.values as Contract.EntityPropertyPrincipalReferenceValue[]).
                map(principalReferenceValue => principalReferenceValue.principalSearchableIdReference.idReference).
                value());

    return _.map(
        entityPropertyDefinitionIdentifiers,
        entityPropertyIdentifier =>
            <VerticalTopItemsInfoItem
                getSortValue={
                    (entityPropertyValue: Contract.EntityPropertyPrincipalReferenceValue | Contract.EntityPropertyStringValue) =>
                        entityPropertyValue.typeName === Contract.TypeNames.EntityPropertyPrincipalReferenceValue
                            ? (entityPropertyValue as Contract.EntityPropertyPrincipalReferenceValue).principalSearchableIdReference.displayName
                            : (entityPropertyValue as Contract.EntityPropertyStringValue).displayName}
                items={
                    _.isNil(entityPropertyRawIdentifierToPropertyMap[entityPropertyIdentifier.raw])
                        ? undefined
                        : EntityPropertyHelper.getOrderedValues(entityPropertyRawIdentifierToPropertyMap[entityPropertyIdentifier.raw])}
                key={entityPropertyIdentifier.raw}
                title={entityPropertyIdentifier.name ?? ""}>
                {entityPropertyValue =>
                    <EntityPropertyValue
                        key={
                            entityPropertyValue.typeName === Contract.TypeNames.EntityPropertyPrincipalReferenceValue
                                ? (entityPropertyValue as Contract.EntityPropertyPrincipalReferenceValue).principalSearchableIdReference.idReference
                                : (entityPropertyValue as Contract.EntityPropertyStringValue).string}
                        principalReferencePropertyEntityModels={principalReferencePropertyEntityModels}
                        showSourceEntity={
                            entityPropertyValue.source.typeName === Contract.TypeNames.EntityPropertyValueAutomaticSource &&
                            resourceModel.id !== (entityPropertyValue.source as Contract.EntityPropertyValueAutomaticSource).entityId}
                        value={entityPropertyValue}/>}
            </VerticalTopItemsInfoItem>);
}