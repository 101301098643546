﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, entityModelStore, InlineEntities, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetAwsResourceRiskContext } from "./useGetAwsResourceRiskContext";

export function useGetAwsApiGatewayV1ApiRiskContext() {
    return useMemo(
        () => useAwsApiGatewayV1ApiContext,
        []);
}

function useAwsApiGatewayV1ApiContext(apiModel: Contract.AwsApiGatewayV1ApiModel) {
    const api = apiModel.entity as Contract.AwsApiGatewayV1Api;
    const resourceRiskContext = useGetAwsResourceRiskContext()(apiModel);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.contexts.useGetAwsApiGatewayV1ApiRiskContext",
            () => ({
                integratedResource: "The {{translatedApiTypeName}} redirects traffic to {{resources}}",
                sensitiveIntegratedResource: [
                    "The {{translatedApiTypeName}} redirects traffic to {{resources}} that is marked as sensitive",
                    "The {{translatedApiTypeName}} redirects traffic to {{resources}} that are marked as sensitive"
                ]
            }));
    const integratedLambdaReferences =
        useMemo(
            () =>
                _(api.routes).
                    filter(route => route.integration?.type === Contract.AwsApiGatewayV1ApiRouteIntegrationType.Lambda).
                    map(route => apiModel.resourceArnToReferenceMap[route.integration!.functionConfigurationArn!]).
                    value(),
            [apiModel]);
    const sensitiveIntegratedLambdaReferenceModels =
        _.filter(
            entityModelStore.useGet(integratedLambdaReferences),
            integratedLambdaReference => integratedLambdaReference.entityConfiguration?.sensitive === true);
    const translatedApiTypeName =
        entityTypeNameTranslator(
            api.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });

    return {
        ...resourceRiskContext,
        integratedResource:
            _.isEmpty(apiModel.integratedResourceIdReferences)
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.integratedResource({
                        resources:
                            <InlineEntities
                                entityIdsOrModels={apiModel.integratedResourceIdReferences}
                                entityTypeName={Contract.TypeNames.AwsResource}
                                variant="itemCountAndType"/>,
                        translatedApiTypeName
                    })),
        sensitiveIntegratedResource:
            _.isEmpty(sensitiveIntegratedLambdaReferenceModels)
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.sensitiveIntegratedResource(
                        sensitiveIntegratedLambdaReferenceModels.length,
                        {
                            resources:
                                <InlineEntities
                                    entityIdsOrModels={sensitiveIntegratedLambdaReferenceModels}
                                    entityTypeName={Contract.TypeNames.AwsLambdaFunctionConfiguration}
                                    variant="itemCountAndType"/>,
                            translatedApiTypeName
                        }))
    };
}