import { useLocalization } from "@infrastructure";
import _ from "lodash";
import { AuditEventDefinition, AuditEventDefinitionContext, AuditEventDefinitionDetailItem } from "..";
import { Contract } from "../../../../../../../../../common";

export function useEndpointConnectorAuditEventDefinition(context: AuditEventDefinitionContext) {
    const localization =
        useLocalization(
            "views.customer.auditEvents.profile.hooks.useDefinition.hooks.useEndpointConnectorAuditEventDefinition",
            () => ({
                endpoint: "Endpoint",
                name: "Name"
            }));

    const endpointConnectorAuditEvent = context.auditEventModel.auditEvent as Contract.EndpointConnectorAuditEvent;
    return new AuditEventDefinition(
        _<AuditEventDefinitionDetailItem>([]).
            concat(
                new AuditEventDefinitionDetailItem(
                    localization.name(),
                    endpointConnectorAuditEvent.name),
                new AuditEventDefinitionDetailItem(
                    localization.endpoint(),
                    endpointConnectorAuditEvent.endpoint)).
            value());
}