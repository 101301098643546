﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, Entity, TypeHelper, useEntityTypeNameTranslator } from "../../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../../utilities";
import { useEntityRiskContext } from "../../../../useEntityRiskContext";
import { useResourceGeneralInformationStep } from "../../../../useResourceGeneralInformationStep";

export function useGetKubernetesResourceRiskContext() {
    return useMemo(
        () => useKubernetesResourceRiskContext,
        []);
}

function useKubernetesResourceRiskContext(resourceModel: Contract.EntityModel) {
    const entityRiskContext = useEntityRiskContext(resourceModel);
    const workloadResourceGeneralInformation = useResourceGeneralInformationStep(resourceModel);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.kubernetes.hooks.resources.contexts.useGetKubernetesResourceRiskContext",
            () => ({
                resourceType: {
                    namespaceResource: "The {{translatedEntityTypeName}} is part of {{cluster}} and {{kubernetesNamespace}}",
                    resource: "The {{translatedEntityTypeName}} is part of {{cluster}}"
                }
            }));

    const resourceType =
        TypeHelper.extendOrImplement(
            resourceModel.entity.typeName,
            Contract.TypeNames.IKubernetesNamespaceResource)
            ? "namespaceResource"
            : "resource";

    return {
        ...entityRiskContext,
        generalInformation: new RiskDefinitionContextItem(workloadResourceGeneralInformation),
        relatedResources:
            new RiskDefinitionContextItem(
                localization.resourceType[resourceType]({
                    cluster:
                        <Entity
                            entityIdOrModel={_.as<Contract.IKubernetesResource>(resourceModel.entity).clusterId}
                            entityTypeNameTranslatorOptions={{ includeServiceName: true }}
                            variant="typeText"/>,
                    kubernetesNamespace:
                        resourceType === "namespaceResource"
                            ? <Entity
                                entityIdOrModel={_.as<Contract.IKubernetesNamespaceResource>(resourceModel.entity).data.namespaceId}
                                entityTypeNameTranslatorOptions={{ includeServiceName: true }}
                                variant="typeText"/>
                            : undefined,
                    translatedEntityTypeName:
                        entityTypeNameTranslator(
                            resourceModel.entity.typeName,
                            {
                                includeServiceName: false,
                                variant: "text"
                            })
                })),
        sensitive: entityRiskContext.sensitive
    };
}