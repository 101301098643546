import { Action0, AnalyticsEventActionType, Loading, useChangeEffect, useEvent, useLocalization, useRoute, useSetRoute, useTrackAnalyticsEvent, VerticalTabView } from "@infrastructure";
import { List, ListItem, Stack } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useMemo, useRef } from "react";
import { CodeResource, Contract, ProfileLayout } from "../../../../../../../../common";
import { RiskContext, useDefinition, useRiskContextProvider } from "../../../../hooks";
import { RiskDefinitionSection, RiskDefinitionSectionCategory } from "../../../../hooks/useDefinition/utilities";
import { RiskHelper, RiskView } from "../../../../utilities";
import { useProfileDefinition } from "../../hooks";
import { ProfileProps } from "../../Profile";
import { AuditEventsSection, Card, Category, CategoryTab, ResolutionCategory } from "./components";
import { CategoryIcon } from "./icons";

export function Core({ codeResourceDatas, excludedEntityIds, excludedRiskItemEntityIds, executeGetRiskModel, riskModel, riskType }: ProfileProps) {
    const [registerAuditEventsChange, triggerAuditEventsChange] = useEvent<Action0>();
    const [, setRiskContext, RiskContextProvider] =
        useRiskContextProvider(
            () => new RiskContext(
                excludedEntityIds,
                excludedRiskItemEntityIds,
                false,
                registerAuditEventsChange,
                executeGetRiskModel,
                riskModel,
                triggerAuditEventsChange));

    useChangeEffect(
        () => {
            setRiskContext(
                riskContext => ({
                    ...riskContext,
                    excludedEntityIds,
                    excludedRiskItemEntityIds,
                    riskModel
                }));
        },
        [excludedEntityIds, excludedRiskItemEntityIds, riskModel]);


    return (
        <RiskContextProvider>
            <Container
                codeResourceDatas={codeResourceDatas}
                riskModel={riskModel}
                riskType={riskType}/>
        </RiskContextProvider>);
}

function Container({ codeResourceDatas, riskModel, riskType }: Pick<ProfileProps, "codeResourceDatas" | "riskModel" | "riskType">) {
    const gridElementRef = useRef<HTMLDivElement>(null);
    const setRoute = useSetRoute();

    const localization =
        useLocalization(
            "views.customer.risks.profile.core",
            () => ({
                audit: "Activity",
                category: {
                    [RiskDefinitionSectionCategory.Activity]: "Activity",
                    [RiskDefinitionSectionCategory.CodeResource]: "IaC",
                    [RiskDefinitionSectionCategory.Overview]: "Overview",
                    [RiskDefinitionSectionCategory.Resolution]: "Remediation"
                },
                view: {
                    [RiskView.Activity]: "Activity",
                    [RiskView.CodeResource]: "IaC",
                    [RiskView.Info]: "Info",
                    [RiskView.ResolutionAzureCustomRole]: "Via New Role",
                    [RiskView.ResolutionAzureCustomRoles]: "Via New Roles",
                    [RiskView.ResolutionGcpCustomRole]: "Via New Role",
                    [RiskView.ResolutionGcpCustomRoles]: "Via New Roles",
                    [RiskView.ResolutionCode]: "IaC",
                    [RiskView.ResolutionConsole]: "Console",
                    [RiskView.ResolutionRole]: "Via Existing Role",
                    [RiskView.ResolutionRoles]: "Via Existing Roles"
                }
            }));
    const definition = useDefinition(riskType, riskModel);
    const profileDefinition = useProfileDefinition(riskType, riskModel);
    const { category } = useRoute(`${profileDefinition.urlHelper.getHashUrl(riskModel.id)}/{category}`);

    const categoryToTabsMap =
        useMemo(
            () => {
                const codeResourceSection =
                    _.isEmpty(codeResourceDatas)
                        ? undefined
                        : new RiskDefinitionSection(
                            <List>
                                {_(codeResourceDatas).
                                    map(
                                        codeResourceData =>
                                            <ListItem key={codeResourceData.id}>
                                                <CodeResource resourceData={codeResourceData}/>
                                            </ListItem>).
                                    value()}
                            </List>,
                            undefined,
                            {
                                categoryView: {
                                    category: RiskDefinitionSectionCategory.CodeResource,
                                    view: RiskView.CodeResource
                                }
                            });

                const visibleSections =
                    _(definition.sections).
                        concatIf(
                            !_.isNil(codeResourceSection),
                            codeResourceSection!).
                        filter(
                            section =>
                                section instanceof RiskDefinitionSection
                                    ? riskModel.risk.status !== Contract.RiskStatus.Closed || section.options?.resolvedRiskVisible === true
                                    : _.some(
                                        section.sections,
                                        section => riskModel.risk.status !== Contract.RiskStatus.Closed || section.options?.resolvedRiskVisible === true)).
                        value();

                const categoryToViewToSectionsMap =
                    _(visibleSections).
                        groupBy(
                            visibleSection =>
                                visibleSection instanceof RiskDefinitionSection &&
                                !_.isNil(visibleSection.options?.categoryView)
                                    ? visibleSection.options?.categoryView.category
                                    : RiskDefinitionSectionCategory.Overview).
                        mapValues(
                            categorySections =>
                                _.groupBy(
                                    categorySections,
                                    categorySection =>
                                        categorySection instanceof RiskDefinitionSection &&
                                        !_.isNil(categorySection.options?.categoryView)
                                            ? categorySection.options?.categoryView.view
                                            : RiskView.Info)).
                        value();

                return (
                    _(categoryToViewToSectionsMap).
                        mapValues(
                            (viewToSectionsMap, category) =>
                                _.map(
                                    viewToSectionsMap,
                                    (viewSections, view) =>
                                        new CategoryTab(
                                            category as RiskDefinitionSectionCategory,
                                            viewSections,
                                            localization.view[view as RiskView](),
                                            view as RiskView))).
                        assign({
                            [RiskDefinitionSectionCategory.Activity]: [
                                new CategoryTab(
                                    RiskDefinitionSectionCategory.Activity,
                                    [
                                        new RiskDefinitionSection(
                                            <AuditEventsSection
                                                riskModel={riskModel}
                                                scrollElementRef={gridElementRef}/>,
                                            "")
                                    ],
                                    localization.view[RiskView.Activity](),
                                    RiskView.Activity)
                            ]
                        }).
                        value());
            },
            [codeResourceDatas, definition]);

    const orderedCategories =
        useMemo(
            () => {
                const categories = _.keys(categoryToTabsMap) as RiskDefinitionSectionCategory[];
                return (
                    _.intersection(
                        [
                            RiskDefinitionSectionCategory.Overview,
                            RiskDefinitionSectionCategory.Resolution,
                            RiskDefinitionSectionCategory.CodeResource,
                            RiskDefinitionSectionCategory.Activity
                        ],
                        categories));
            },
            []);

    const currentCategory =
        useMemo(
            () =>
                _.includes(orderedCategories, category)
                    ? category as RiskDefinitionSectionCategory
                    : _.first(orderedCategories)!,
            [category, orderedCategories]);

    useTrackAnalyticsEvent(
        AnalyticsEventActionType.PageContentView,
        RiskHelper.getRiskAnalyticsPropertyNameToValueMap(riskModel));

    useEffect(
        () => {
            if (_.isNil(category) ||
                !_.includes(orderedCategories, category)) {
                setRoute(
                    profileDefinition.urlHelper.getHashUrl(riskModel.id, { category: currentCategory }),
                    undefined,
                    { appendBrowserHistory: false });
            }
        },
        []);
    return (
        <ProfileLayout
            topBarElement={
                <Card
                    profileDefinition={profileDefinition}
                    subtitle={definition.description}/>}>
            <VerticalTabView
                items={
                    _.map(
                        orderedCategories,
                        category => ({
                            icon: <CategoryIcon view={category}/>,
                            title: localization.category[category](),
                            view: category
                        }))}
                selectedView={currentCategory}
                storageItem={profileDefinition.storageData.openTab}
                onSelectedViewChanged={category => setRoute(profileDefinition.urlHelper.getHashUrl(riskModel.id, { category }))}>
                <Stack
                    sx={{
                        height: "100%",
                        width: "100%"
                    }}>
                    {!_.isNil(categoryToTabsMap[currentCategory]) &&
                        <Loading>
                            {currentCategory === RiskDefinitionSectionCategory.Resolution
                                ? <ResolutionCategory
                                    containerRef={gridElementRef}
                                    getUrl={profileDefinition.urlHelper.getHashUrl}
                                    riskModel={riskModel}
                                    tabs={categoryToTabsMap[currentCategory]}/>
                                : <Category
                                    category={currentCategory}
                                    containerRef={gridElementRef}
                                    getUrl={profileDefinition.urlHelper.getHashUrl}
                                    riskModel={riskModel}
                                    tabs={categoryToTabsMap[currentCategory]}/>}
                        </Loading>}
                </Stack>
            </VerticalTabView>
        </ProfileLayout>);
}