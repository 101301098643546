import { Link, useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { useGetAzureStorageStorageAccountRiskContext } from "../contexts";

export function useAzureStorageStorageAccountInfrastructureEncryptionDisabledRiskDefinition(riskModel: Contract.RiskModel) {
    const storageAccountInfrastructureEncryptionDisabledRiskModel = riskModel as Contract.AzureStorageStorageAccountInfrastructureEncryptionDisabledRiskModel;
    const risk = storageAccountInfrastructureEncryptionDisabledRiskModel.risk;
    const storageAccountModel = entityModelStore.useGet(risk.entityId) as Contract.AzureStorageStorageAccountModel;

    const getAzureStorageStorageAccountRiskContext = useGetAzureStorageStorageAccountRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.compliance.useAzureStorageStorageAccountInfrastructureEncryptionDisabledRiskDefinition",
            () => ({
                description: "{{storageAccount}} infrastructure encryption is not enabled",
                sections: {
                    resolution: {
                        step1: {
                            link: "documentation",
                            text: "Infrastructure encryption cannot be enabled or disabled after the storage account has been created. To enable infrastructure encryption, first create a new storage account. See Azure {{documentationLink}} for more information"
                        },
                        step2: "After creating a new storage account with infrastructure encryption enabled, delete the old storage account"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            storageAccount:
                <Entity
                    entityIdOrModel={storageAccountModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            localization.sections.resolution.step1.text({
                documentationLink:
                        <Link
                            urlOrGetUrl={storageAccountInfrastructureEncryptionDisabledRiskModel.infrastructureEncryptionDocumentationUrl}
                            variant="external">
                            {localization.sections.resolution.step1.link()}
                        </Link>
            }),
            localization.sections.resolution.step2()
        ],
        riskModel,
        () => {
            const storageAccountRiskContext = getAzureStorageStorageAccountRiskContext(storageAccountModel);
            return [
                storageAccountRiskContext.generalInformation,
                storageAccountRiskContext.inboundExternalAccessScope,
                storageAccountRiskContext.childResources,
                storageAccountRiskContext.sensitive,
                storageAccountRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}