import { useLocalization } from "@infrastructure";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../../..";
import { useCommonEntityNetworkInfoItemElements } from "../..";
import { Contract, entityModelStore, InfoCard, InfoItem, VerticalTopItemsInfoItem } from "../../../../../../../../../../../common";
import { AzureNetworkLoadBalancerRulesTable } from "../../../../../../../../../../../tenants";
import { useAzureNetworkLoadBalancerSkuTranslator, useAzureNetworkLoadBalancerTypeTranslator } from "../../../../../../../hooks";
import { EntitiesInfoItem, Info } from "../../../../../components";
import { AzureResourceRoleAssignmentResources, NetworkingInfoCard } from "../../../components";
import { useCustomEntityPropertyInfoItemElements } from "../../useCustomEntityPropertyInfoItemElements";
import { useAzureDefaultResourceInfoItemElements } from "../useAzureDefaultResourceInfoItemElements";

export function useAzureNetworkLoadBalancerDefinition(resourceGroupResourceModel: Contract.AzureResourceGroupResourceModel, options?: DefinitionOptions) {
    const commonEntityNetworkInfoItemElements = useCommonEntityNetworkInfoItemElements(resourceGroupResourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceGroupResourceModel);
    const defaultResourceInfoItemElements = useAzureDefaultResourceInfoItemElements(resourceGroupResourceModel);
    const loadBalancerModel = resourceGroupResourceModel as Contract.AzureNetworkLoadBalancerModel;
    const loadBalancer = loadBalancerModel.entity as Contract.AzureNetworkLoadBalancer;
    const publicIpAddressModels = entityModelStore.useGet(loadBalancer.publicIpAddressIds);

    const networkLoadBalancerSkuTranslator = useAzureNetworkLoadBalancerSkuTranslator();
    const networkLoadBalancerTypeTranslator = useAzureNetworkLoadBalancerTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.azure.useAzureNetworkLoadBalancerDefinition",
            () => ({
                info: {
                    cards: {
                        rules: "Rules"
                    },
                    items: {
                        dnsNames: "DNS Names",
                        dnsZoneIds: "DNS Zones",
                        privateIpAddresses: "Private IP Addresses",
                        publicIpAddressIds: "Public IP Addresses",
                        publicIpPrefixIds: "Public IP Prefixes",
                        sku: "SKU",
                        type: "Load Balancer Type"
                    }
                },
                tabs: {
                    resourceRoleAssignments: "Resource Role Assignments"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <AzureResourceRoleAssignmentResources
                    csvExportFilePrefix={localization.tabs.resourceRoleAssignments()}
                    scopeResourceModel={resourceGroupResourceModel}/>,
                localization.tabs.resourceRoleAssignments(),
                "resourceRoleAssignments")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="type"
                        title={localization.info.items.type()}
                        value={networkLoadBalancerTypeTranslator(loadBalancer.type)}/>,
                    <InfoItem
                        key="sku"
                        title={localization.info.items.sku()}
                        value={networkLoadBalancerSkuTranslator(loadBalancer.sku)}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={loadBalancer.publicIpPrefixIds}
                        entityTypeName={Contract.TypeNames.AzureNetworkPublicIpPrefix}
                        key="publicIpPrefixIds"
                        title={localization.info.items.publicIpPrefixIds()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={loadBalancerModel.dnsZoneIds}
                        entityTypeName={Contract.TypeNames.Entity}
                        key="dnsZoneIds"
                        title={localization.info.items.dnsZoneIds()}/>
                ]}
                options={options?.infoOptions}>
                <NetworkingInfoCard>
                    {commonEntityNetworkInfoItemElements.inboundAccessType}
                    {commonEntityNetworkInfoItemElements.inboundExternalAccessScope}
                    <VerticalTopItemsInfoItem
                        items={loadBalancer.dnsNames}
                        key="dnsNames"
                        title={localization.info.items.dnsNames()}/>
                    <VerticalTopItemsInfoItem
                        items={loadBalancer.privateIpAddresses}
                        key="privateIpAddresses"
                        title={localization.info.items.privateIpAddresses()}/>
                    <EntitiesInfoItem
                        entityIdsOrModels={publicIpAddressModels}
                        entityTypeName={Contract.TypeNames.AzureNetworkPublicIpAddress}
                        key="publicIpAddressIds"
                        title={localization.info.items.publicIpAddressIds()}/>
                </NetworkingInfoCard>
                <InfoCard title={localization.info.cards.rules()}>
                    <AzureNetworkLoadBalancerRulesTable loadBalancer={loadBalancer}/>
                </InfoCard>
            </Info>
    });
}