﻿import { TimeFormatter, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useOpCommonEntityInfoItemElements } from ".";
import { Contract, InfoItem } from "../../../../../../../../../../common";

export function useOpCommonResourceInfoItemElements(resourceModel: Contract.OpResourceModel) {
    const commonEntitiesInfoElements = useOpCommonEntityInfoItemElements(resourceModel);
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.op.useOpCommonResourceInfoItemElements",
            () => ({
                creationTime: "Creation Time"
            }));

    return {
        ...commonEntitiesInfoElements,
        creationTimeInfoItemElement:
            <InfoItem
                key="creationTime"
                location="all"
                title={localization.creationTime()}
                value={
                    _.isNil(resourceModel.creationTime)
                        ? undefined
                        : TimeFormatter.profileFormatDateTime(resourceModel.creationTime)}/>
    };
}