import { Loading, UnexpectedError, useExecuteOperation, useSetRoute } from "@infrastructure";
import { Stack } from "@mui/material";
import _ from "lodash";
import React, { CSSProperties, useEffect, useMemo, useRef, useState } from "react";
import { CustomerConsoleAppUrlHelper, SystemController, useTheme } from "../../../../../common";

type DocumentationViewProps = {
    itemId: string;
    style?: CSSProperties;
};

export function DocumentationView({ itemId, style }: DocumentationViewProps) {
    const [error, setError] = useState(false);
    const setRoute = useSetRoute();
    useExecuteOperation(
        DocumentationView,
        () => SystemController.getInfo());
    const [loading, setLoading] = useState(true);
    const iframeRef = useRef<HTMLIFrameElement>(null);

    useEffect(
        () => {
            const timeout =
                setTimeout(
                    () => {
                        if (loading) {
                            setLoading(false);
                            setError(true);
                        }
                    },
                    iframeTimeout);

            return () => clearTimeout(timeout);
        },
        [loading]);

    const theme = useTheme();
    const path =
        useMemo(
            () => `${CustomerConsoleAppUrlHelper.getDocsRelativeUrl()}/${itemId}`,
            [itemId]);
    useEffect(
        () => {
            const handleIframeInternalChangeUrl =
                (event: MessageEvent) => {
                    const message = event.data;
                    if (message.action === DocumentationAction.Loading) {
                        setLoading(true);
                        if (!_.isNil(message?.src) && !isIframeOpenByUrl(new URL(message.src), path)) {
                            const sideViewUrlByDocsUrl = getSideViewUrlByDocsUrl(new URL(message.src));
                            if (sideViewUrlByDocsUrl) {
                                setRoute(CustomerConsoleAppUrlHelper.getDocumentationProfileHashUrl(sideViewUrlByDocsUrl));
                            }
                        }
                    }
                    if (message.action === DocumentationAction.Loaded || (!_.isNil((message.src) && isIframeOpenByUrl(new URL(message.src), path)))) {
                        setLoading(false);
                    }
                };

            if (iframeRef.current) {
                window.addEventListener("message", handleIframeInternalChangeUrl);
            }

            return () => window.removeEventListener("message", handleIframeInternalChangeUrl);
        },
        [iframeRef.current, path]);


    if (error) {
        throw new UnexpectedError("DocumentationView", path);
    }

    return (
        <Stack
            sx={{
                backgroundColor: theme.palette.background.paper,
                border: 0,
                height: "100%",
                right: "0",
                top: "0",
                zIndex: 10
            }}>
            <Loading loading={loading}/>
            <iframe
                ref={iframeRef}
                src={`${path}?iframe=true&dark=${theme.dark}`}
                style={{
                    border: 0,
                    ...(loading
                        ? {
                            height: 0,
                            visibility: "hidden",
                            width: 0
                        }
                        : style)
                }}/>
        </Stack>);
}

enum DocumentationAction {
    Loaded = "LOADED",
    Loading = "LOADING"
}

const iframeTimeout = 10000;

function getSideViewUrlByDocsUrl(url: URL | Location) {
    const urlParts = _.split(url.pathname, "/");
    return _.last(urlParts);
}

function isIframeOpenByUrl(url: URL | Location, sideViewRoute: string) {
    const sideViewUrlByDocsUrl = getSideViewUrlByDocsUrl(url);
    const sideViewRouteParts = _.split(sideViewRoute, "/");
    return sideViewUrlByDocsUrl === _.last(sideViewRouteParts);
}