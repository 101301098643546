import { DataTable, DataTableActions, DataTableColumn, DataTableColumnRenderProps, DataTableFetchItemsResult, DataTableSort, DataTableSortDirection, EmptyMessageText, Loading, makeContextProvider, Optional, useLocalization, useQueryParameters, useSetQueryParameters } from "@infrastructure";
import { Box, Stack } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Contract, ObjectTypeMetadataModelHelper, TypeHelper, UdmController, useTheme } from "../..";
import { Actions, UdmObjectPropertyItem, UdmQueryBuilder, Welcome } from "./components";
import { useUdmObjectPropertyIdTranslator, useUdmObjectTypeNameTranslator } from "./hooks";
import { UdmQueryHelper } from "./utilities";

type UdmObjectTableQueryParameters = {
    query?: string;
};

export class UdmObjectTableContext {
    constructor(
        public compact: boolean,
        public newFiltersIds: string[],
        public closedGroupIds: string[]) {
    }
}

export const [useUdmObjectTableContext, useSetUdmObjectTableContext, useUdmObjectTableContextProvider] = makeContextProvider<UdmObjectTableContext>();

export function UdmObjectTable() {
    const queryParams = useQueryParameters<UdmObjectTableQueryParameters>();
    const setQueryParams = useSetQueryParameters();

    const validQueryRef = useRef<Contract.UdmQuery>();
    const [query, setQuery] =
        useState<Contract.UdmQuery>(
            () =>
                _.isNil(queryParams.query)
                    ? UdmQueryHelper.createQuery()
                    : JSON.parse(queryParams.query));

    const objectIdentifierToDataMap =
        useMemo(
            () => {
                function getObjectIdentifierToDataMap(query: Contract.UdmQueryBase, itemStartIndex = 0): Dictionary<ObjectIdentifierData> {
                    if (_.isEmpty((query as Contract.UdmQuery).objectTypeName ?? (query as Contract.UdmQueryJoin).propertyId)) {
                        return {};
                    }

                    const [objectIdentifier, objectTypeName] =
                        UdmQueryHelper.isQueryJoin(query)
                            ? [(query as Contract.UdmQueryJoin).propertyId, ObjectTypeMetadataModelHelper.getProperty((query as Contract.UdmQueryJoin).propertyId).relation!.objectTypeName]
                            : [(query as Contract.UdmQuery).objectTypeName, (query as Contract.UdmQuery).objectTypeName];
                    const objectTypeMetadataModel = ObjectTypeMetadataModelHelper.get(objectTypeName);
                    const udmTableProperties =
                        _.filter(
                            objectTypeMetadataModel.udmProperties,
                            udmProperty => !udmProperty.options.filterOnly);
                    const udmQueryProperties =
                        _.filter(
                            udmTableProperties,
                            udmTableProperty =>
                                _.includes(
                                    query.options?.propertyIds,
                                    udmTableProperty.id));
                    const udmSelfIdProperty =
                        _.find(
                            udmQueryProperties,
                            udmQueryProperty => udmQueryProperty.dataType === Contract.UdmObjectPropertyDataType.CommonSelfId)!;
                    return {
                        [objectIdentifier]: {
                            itemStartIndex,
                            itemUdmSelfIdPropertyIndex: _.indexOf(udmQueryProperties, udmSelfIdProperty),
                            objectTypeName,
                            udmQueryOptions: query.options,
                            udmQueryProperties,
                            udmSelfIdProperty,
                            udmTablePropertyIds:
                                _.map(
                                    udmTableProperties,
                                    udmProperty => udmProperty.id)
                        },
                        ...(_.isNil(query.join)
                            ? undefined
                            : getObjectIdentifierToDataMap(query.join, itemStartIndex + _.size(udmQueryProperties)))
                    };
                }

                return getObjectIdentifierToDataMap(query);
            },
            [query]);

    const dataTableActionsRef = useRef<DataTableActions>();
    const [itemsCount, setItemsCount] = useState<number>();
    useEffect(
        () => {
            function isQueryValid(query: Contract.UdmQueryBase): boolean {
                if (!UdmQueryHelper.isQueryJoin(query) && _.isEmpty((query as Contract.UdmQuery).objectTypeName)) {
                    return false;
                }

                if (UdmQueryHelper.isQueryJoin(query) && _.isEmpty((query as Contract.UdmQueryJoin).propertyId)) {
                    return false;
                }

                return (
                    _.every(query.ruleGroup.rules, isRuleValid) &&
                    (_.isNil(query.join) || isQueryValid(query.join)));
            }

            function isRuleValid(rule: Contract.UdmQueryRuleBase): boolean {
                if (TypeHelper.extendOrImplement(rule.typeName, Contract.TypeNames.UdmQueryRuleGroup)) {
                    return _.every(
                        (rule as Contract.UdmQueryRuleGroup).rules,
                        isRuleValid);
                }

                const values = (rule as Contract.UdmQueryRule).values as any[];
                return (
                    !_.isEmpty(values) &&
                    _.every(
                        values,
                        value => !_.isEmpty(value) || _.isBoolean(value) || _.isNumber(value)));
            }

            if (isQueryValid(query) && (
                _.isNil(validQueryRef.current) ||
                !_.isEqualByProperties(validQueryRef.current, query))) {
                setItemsCount(undefined);
                setQueryParams({ query: JSON.stringify(query) }, { ignoreUrlMaxLength: true });

                dataTableActionsRef.current?.reset();
                validQueryRef.current = _.cloneDeep(query);
            }
        },
        [query]);

    const udmObjectPropertyIdTranslator = useUdmObjectPropertyIdTranslator();
    const udmObjectTypeNameTranslator = useUdmObjectTypeNameTranslator();
    const localization =
        useLocalization(
            "common.udmObjectTable",
            () => ({
                empty: "No items"
            }));

    const [, , ContextProvider] =
        useUdmObjectTableContextProvider(
            () => new UdmObjectTableContext(true, [], []),
            []);

    const theme = useTheme();
    const columnElements =
        useMemo(
            () =>
                _(objectIdentifierToDataMap).
                    toPairs().
                    orderBy(([, objectIdentifierData]) => objectIdentifierData.itemStartIndex).
                    flatMap(
                        ([objectIdentifier, { itemStartIndex, itemUdmSelfIdPropertyIndex, objectTypeName, udmQueryProperties }], objectIdentifierIndex) =>
                            _(udmQueryProperties).
                                orderBy(udmQueryProperty => _.indexOf(objectIdentifierToDataMap[objectIdentifier].udmTablePropertyIds, udmQueryProperty.id)).
                                map(
                                    (udmQueryProperty, udmQueryPropertyIndex) =>
                                        <DataTableColumn
                                            cellSx={{
                                                background:
                                                    objectIdentifierIndex > 0
                                                        ? theme.palette.udm(objectIdentifierIndex as (0 | 1 | 2 | 3 | 4))
                                                        : undefined
                                            }}
                                            headerSx={{
                                                background:
                                                    objectIdentifierIndex > 0
                                                        ? theme.palette.udm(objectIdentifierIndex as (0 | 1 | 2 | 3 | 4))
                                                        : undefined
                                            }}
                                            id={getColumnId(objectIdentifier, udmQueryProperty.id)}
                                            key={getColumnId(objectIdentifier, udmQueryProperty.id)}
                                            render={
                                                ({ item }: DataTableColumnRenderProps<any[]>) =>
                                                    <Loading container="cell-medium">
                                                        <UdmObjectPropertyItem
                                                            item={item[itemStartIndex + udmQueryPropertyIndex]}
                                                            objectId={item[itemStartIndex + itemUdmSelfIdPropertyIndex!]}
                                                            objectProperty={udmQueryProperty!}
                                                            objectTypeName={objectTypeName}/>
                                                    </Loading>}
                                            sortOptions={{ enabled: udmQueryProperty!.options.sortable }}
                                            title={
                                                udmQueryProperty.dataType === Contract.UdmObjectPropertyDataType.CommonSelfId
                                                    ? udmObjectTypeNameTranslator(objectTypeName)
                                                    : udmObjectPropertyIdTranslator(udmQueryProperty!.id)}/>).
                                value()).
                    value(),
            [objectIdentifierToDataMap]);

    function findQueryTarget(query: Optional<Contract.UdmQueryBase>, objectIdentifier: string): Optional<Contract.UdmQueryBase> {
        if (_.isNil(query)) {
            return undefined;
        }

        if (!UdmQueryHelper.isQueryJoin(query) &&
            (query as Contract.UdmQuery).objectTypeName === objectIdentifier) {
            return query;
        }

        if (UdmQueryHelper.isQueryJoin(query) &&
            (query as Contract.UdmQueryJoin).propertyId === objectIdentifier) {
            return query;
        }

        return findQueryTarget(query.join, objectIdentifier);
    }

    const onQueryPropertyIdsChanged =
        useCallback(
            (objectIdentifier: string, propertyIds: Contract.UdmObjectPropertyId[]) => {
                const updatedQuery = _.cloneDeep(query);
                const updatedQueryTarget = findQueryTarget(updatedQuery, objectIdentifier);
                updatedQueryTarget!.options!.propertyIds =
                    _.orderBy(
                        propertyIds,
                        propertyIds =>
                            _.indexOf(
                                objectIdentifierToDataMap[objectIdentifier].udmTablePropertyIds,
                                propertyIds));

                setQuery(updatedQuery as Contract.UdmQuery);
            },
            [objectIdentifierToDataMap, query]);

    const [fetchItemsError, setFetchItemsError] = useState(false);
    const [fetchItemsLoading, setFetchItemsLoading] = useState(false);
    const fetchItems =
        async (_filterMap: Dictionary<any>, sort: Optional<DataTableSort>, skip: number, limit: number) => {
            if (skip === 0) {
                setFetchItemsError(false);
                setFetchItemsLoading(true);
            }

            const updatedQuery = _.cloneDeep(validQueryRef.current!);
            if (!_.isNil(sort)) {
                const [sortObjectIdentifier, sortObjectPropertyId] = parseColumnId(sort.columnId);
                const updatedQueryTarget = findQueryTarget(updatedQuery, sortObjectIdentifier);
                updatedQueryTarget!.options!.sort = {
                    direction:
                        sort.direction === DataTableSortDirection.Ascending
                            ? Contract.SortDirection.Ascending
                            : Contract.SortDirection.Descending,
                    propertyId: sortObjectPropertyId as Contract.UdmObjectPropertyId
                };
            }

            try {
                const { count, hasMore, itemsList } =
                    await UdmController.getObjectItemsPage(
                        new Contract.UdmControllerGetObjectItemsPageRequest(
                            limit,
                            updatedQuery as Contract.UdmQuery,
                            skip));

                if (!_.isNil(count)) {
                    setItemsCount(count);
                    setFetchItemsLoading(false);
                }

                return new DataTableFetchItemsResult(
                    { count: itemsCount ?? count ?? 0 },
                    itemsList,
                    !hasMore);
            } catch {
                setFetchItemsLoading(false);
                setFetchItemsError(true);

                return new DataTableFetchItemsResult(
                    { count: itemsCount ?? 0 },
                    [],
                    true);
            }
        };

    return (
        <ContextProvider>
            <Stack
                sx={{
                    flex: 1,
                    overflow: "hidden"
                }}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    spacing={1}
                    sx={{ padding: theme.spacing(2) }}>
                    <Stack
                        direction="row"
                        spacing={1}
                        sx={{
                            overflow: "hidden",
                            position: "relative",
                            width: "fit-content"
                        }}>
                        <UdmQueryBuilder
                            query={query}
                            queryLevel={0}
                            onQueryChanged={
                                updateQuery => {
                                    updateQuery = _.cloneDeep(updateQuery);
                                    _.each(
                                        objectIdentifierToDataMap,
                                        (objectIdentifierData, objectIdentifier) => {
                                            const updateQueryTarget = findQueryTarget(updateQuery, objectIdentifier);
                                            if (!_.isNil(updateQueryTarget)) {
                                                updateQueryTarget.options = objectIdentifierData.udmQueryOptions;
                                            }
                                        });

                                    setQuery(updateQuery as Contract.UdmQuery);

                                    if (fetchItemsError) {
                                        dataTableActionsRef.current!.reset();
                                    }
                                }}/>
                    </Stack>
                    {!_.isEmpty(query.objectTypeName) &&
                        <Box>
                            <Actions
                                itemsCount={itemsCount}
                                itemsCountLoading={fetchItemsLoading}
                                objectIdentifierToDataMap={objectIdentifierToDataMap}
                                query={query}
                                onSelectedPropertyIdsChanged={onQueryPropertyIdsChanged}/>
                        </Box>}
                </Stack>
                {_.isEmpty(objectIdentifierToDataMap[query.objectTypeName])
                    ? <Welcome/>
                    : <Stack
                        sx={{
                            height: "100%",
                            overflow: "hidden"
                        }}>
                        <DataTable
                            actionsRef={dataTableActionsRef}
                            columnOptions={{
                                orderOptions: {
                                    disabled: true
                                },
                                resizable: true
                            }}
                            emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty()) }}
                            fetchItems={fetchItems}
                            getItemId={(items: any[]) => JSON.stringify(items)}>
                            {columnElements}
                        </DataTable>
                    </Stack>}
            </Stack>
        </ContextProvider>);
}

export type ObjectIdentifierData = {
    itemStartIndex: number;
    itemUdmSelfIdPropertyIndex: number;
    objectTypeName: string;
    udmQueryOptions: Optional<Contract.UdmQueryOptions>;
    udmQueryProperties: Contract.UdmObjectProperty[];
    udmSelfIdProperty: Contract.UdmObjectProperty;
    udmTablePropertyIds: Contract.UdmObjectPropertyId[];
};

function getColumnId(objectIdentifier: string, propertyId: Contract.UdmObjectPropertyId) {
    return `${objectIdentifier}.${propertyId}`;
}

function parseColumnId(columnId: string): [string, Contract.UdmObjectPropertyId] {
    const columnIdParts = columnId.split(".");
    return [columnIdParts[0], columnIdParts[1] as Contract.UdmObjectPropertyId];
}