import { useLocalization, ValueFilter } from "@infrastructure";
import React from "react";
import { Contract } from "../controllers";

export function PermissionActionExcessivenessFilter() {
    const localization =
        useLocalization(
            "common.permissionActionExcessivenessFilter",
            () => ({
                items: {
                    [Contract.PermissionActionExcessiveness.Excessive]: "Unused (Excessive)",
                    [Contract.PermissionActionExcessiveness.Nonexcessive]: "Used"
                },
                placeholder: "Usage"
            }));

    return (
        <ValueFilter
            items={[
                {
                    title: localization.items[Contract.PermissionActionExcessiveness.Excessive](),
                    value: Contract.PermissionActionExcessiveness.Excessive
                },
                {
                    title: localization.items[Contract.PermissionActionExcessiveness.Nonexcessive](),
                    value: Contract.PermissionActionExcessiveness.Nonexcessive
                }
            ]}
            placeholder={localization.placeholder()}/>);
}