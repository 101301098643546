﻿import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function GermanyIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <g clipPath="url(#clip0_60203_5274)">
                <rect
                    fill="white"
                    height="15.9638"
                    rx="2"
                    width="24"
                    y="4"/>
                <path
                    d="M24 4.00012H0V19.9639H24V4.00012Z"
                    fill="#000008"/>
                <path
                    d="M24 9.32141H0V19.9639H24V9.32141Z"
                    fill="#DD0000"/>
                <path
                    d="M24 14.6427H0V19.964H24V14.6427Z"
                    fill="#FFCE00"/>
            </g>
            <defs>
                <clipPath id="clip0_60203_5274">
                    <rect
                        fill="white"
                        height="15.9638"
                        rx="2"
                        width="24"
                        y="4"/>
                </clipPath>
            </defs>
        </SvgIcon>);
}