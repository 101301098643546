import { Optional } from "@infrastructure";
import { Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { useRiskPoliciesItemConfiguration } from "../../..";
import { Contract, RiskPoliciesType, RiskPolicyItem } from "../../../../../../../../common";
import { useNumeric } from "./useNumeric";

type ViewOnlyNumericProps<T> = {
    fromNumber: (value: number) => T;
    getValue: (riskPolicyConfiguration: Contract.RiskPolicyConfiguration) => Optional<T>;
    item: RiskPolicyItem;
    riskPoliciesType: RiskPoliciesType;
    scopeId: string;
    toNumber: (value: T) => number;
    translateValue: (value: T) => string;
};

export function ViewOnlyNumeric<T>({ fromNumber, getValue, item, riskPoliciesType, scopeId, toNumber, translateValue }: ViewOnlyNumericProps<T>) {
    const { inheritDisabled, riskPolicyConfiguration } =
        useRiskPoliciesItemConfiguration(
            riskPoliciesType,
            item,
            scopeId);
    const { defaultSelectedValue, inheritedValue } =
        useNumeric<T>(
            item,
            getValue,
            riskPolicyConfiguration,
            riskPoliciesType,
            toNumber);

    return (
        <Typography>
            {translateValue(
                !inheritDisabled &&
                _.isNil(defaultSelectedValue)
                    ? inheritedValue!
                    : fromNumber(defaultSelectedValue!))}
        </Typography>);
}