import { Sx } from "@infrastructure";
import { SxProps, Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, TenantHelper, TenantIcon, tenantModelStore, useTheme } from "..";
import { TenantNameTranslatorOptions, useTenantNameTranslator } from "../hooks";

export type TenantProps = {
    colored?: boolean;
    iconData?: any;
    iconSx?: SxProps;
    sx?: SxProps;
    tenantId: string;
    tenantNameTranslatorOptions?: TenantNameTranslatorOptions;
    variant?: TenantVariant;
};

export type TenantVariant = "icon" | "iconText" | "text";

export function Tenant({ colored = true, iconData, iconSx, sx, tenantId, tenantNameTranslatorOptions, variant = "iconText" }: TenantProps) {
    const tenantModels = tenantModelStore.useGetAll();
    const tenantModel =
        useMemo(
            () =>
                _.find(
                    tenantModels,
                    tenantModel => tenantModel.configuration.id === tenantId),
            [tenantId, tenantModels]);

    if (tenantModel?.tenantType === Contract.TenantType.Ci) {
        iconData = { ciTenantType: (tenantModel.configuration as Contract.CiTenantConfiguration).ciTenantType };
    }

    const tenantNameTranslator = useTenantNameTranslator();
    const theme = useTheme();
    return (
        <Typography
            component="span"
            noWrap={true}
            sx={
                Sx.combine(
                    {
                        color: "unset",
                        fontSize: "unset"
                    },
                    sx)}>
            {variant !== "text" && (!_.isNil(tenantModel) || !_.isNil(TenantHelper.commonTenantIdToDataMap[tenantId])) && (
                <TenantIcon
                    colored={colored}
                    data={iconData}
                    sx={
                        Sx.combine(
                            {
                                display: "inline-flex",
                                fontSize: "18px",
                                marginRight:
                                    variant === "icon"
                                        ? undefined
                                        : theme.spacing(0.75),
                                verticalAlign: "middle"
                            },
                            iconSx)}
                    tenantType={tenantModel?.tenantType ?? TenantHelper.commonTenantIdToDataMap[tenantId].type}/>)}
            {variant !== "icon" && (
                tenantNameTranslator(tenantId, tenantNameTranslatorOptions))}
        </Typography>);
}