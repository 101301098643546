﻿import { Stack } from "@mui/material";
import React from "react";
import { Analyzing } from ".";

export function AnalyzingView() {
    return (
        <Stack
            sx={{
                alignItems: "center",
                height: "100%",
                justifyContent: "center",
                width: "100%"
            }}>
            <Analyzing/>
        </Stack>);
}