import React from "react";
import { Action0, ActionMenuItem, setUrlRoute, useLocalization } from "@infrastructure";
import { useCommonDefinition } from "..";
import { BuiltInRiskPolicyItem, CustomerConsoleAppUrlHelper, RiskPoliciesType } from "../../../../../../../../../../../../common";
import { EventsIcon } from "./icons";

export function useKubernetesAdmissionControllerDefinition(item: BuiltInRiskPolicyItem, onClick: Action0, scopeId: string) {
    const { actionMenuItems: commonActionMenuItems } = useCommonDefinition(item, onClick, RiskPoliciesType.KubernetesAdmissionController, scopeId);

    const localization =
        useLocalization(
            "views.customer.riskPolicies.actionsCell.builtInActionsCell.hooks.useDefinition.hooks.useKubernetesAdmissionControllerDefinition",
            () => ({
                events: "View logs"
            }));

    return {
        actionMenuItems:
            [
                new ActionMenuItem(
                    () =>
                        setUrlRoute(
                            CustomerConsoleAppUrlHelper.getKubernetesAdmissionControllerEventRelativeUrl(
                                item.riskPolicyConfigurationTypeName,
                                undefined)),
                    localization.events(),
                    {
                        icon: <EventsIcon/>
                    }),
                ...commonActionMenuItems
            ]
    };
}