import { useLocalization } from "@infrastructure";

export function useAwsDeleteBucketPolicyChangeDefinition() {
    const localization =
        useLocalization(
            "common.change.hooks.useDefinition.hooks.useAwsDefinition.hooks.useAwsDeleteBucketPolicyChangeDefinition",
            () => ({
                title: "Delete the bucket policy"
            }));
    return {
        title: localization.title()
    };
}