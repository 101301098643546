﻿import { DataTableColumn, DataTableSortType, DeferredFilter, EnumValuesFilter, optionalTableCell, TimeFormatter, TimeRangeFilter, useLocalization } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { useAwsCommonEncryptedResourceDefinition } from ".";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { useAwsBedrockAgentStatusTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";
import { EntityTableDefinition } from "../../useDefinition";
import { useAwsCommonResourceDefinition } from "./useAwsCommonResourceDefinition";

export function useAwsBedrockAgentDefinition(definitionData: DefinitionData) {
    const agentModelFiltersPromise = definitionData.entityModelFiltersPromise as Promise<Contract.AwsBedrockAgentModelFilters>;
    const commonEncryptedResourceDefinition = useAwsCommonEncryptedResourceDefinition(definitionData);
    const commonResourceDefinition = useAwsCommonResourceDefinition(definitionData);

    const agentStatusTranslator = useAwsBedrockAgentStatusTranslator();
    const entitiesExportOptions = useEntitiesExportOptions();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.aws.useAwsBedrockAgentDefinition",
            () => ({
                columns: {
                    roleReference: "Role",
                    status: "Status",
                    updateTime: "Update Time"
                }
            }));

    return new EntityTableDefinition(
        _.filter(
            [
                commonResourceDefinition.columns.tenantColumn,
                commonResourceDefinition.columns.creationTimeColumn,
                commonResourceDefinition.columns.creatorIdentityCsvColumn,
                commonResourceDefinition.columns.creatorOriginatorEntityCsvColumn,
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsBedrockAgentModel) => ({
                                [localization.columns.updateTime()]:
                                    TimeFormatter.iso8601String((item.entity as Contract.AwsBedrockAgent).updateTime)
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <DeferredFilter
                                    promiseOrGetPromise={agentModelFiltersPromise}
                                    title={localization.columns.updateTime()}>
                                    {filters =>
                                        <TimeRangeFilter
                                            emptyValue={filters.updateTimeRange.emptyValue}
                                            placeholder={localization.columns.updateTime()}
                                            timeRange={TimeRangeHelper.getTimeRangeFilterRange(filters.updateTimeRange.timeRange)}/>}
                                </DeferredFilter>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsBedrockAgentUpdateTime}
                    itemProperty={(item: Contract.AwsBedrockAgentModel) => TimeFormatter.monthDayAndYear((item.entity as Contract.AwsBedrockAgent).updateTime)}
                    key={Contract.EntityModelProperty.AwsBedrockAgentUpdateTime}
                    selectorOptions={{ default: false }}
                    sortOptions={{ type: DataTableSortType.Date }}
                    title={localization.columns.updateTime()}/>,
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsBedrockAgentModel) => ({
                                [localization.columns.status()]: agentStatusTranslator((item.entity as Contract.AwsBedrockAgent).status)
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <EnumValuesFilter
                                    enumType={Contract.AwsBedrockAgentStatus}
                                    enumTypeTranslator={agentStatusTranslator}
                                    exclude={[Contract.AwsBedrockAgentStatus.Dissociated]}
                                    placeholder={localization.columns.status()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsBedrockAgentStatus}
                    itemProperty={(item: Contract.AwsBedrockAgentModel) => agentStatusTranslator((item.entity as Contract.AwsBedrockAgent).status)}
                    key={Contract.EntityModelProperty.AwsBedrockAgentStatus}
                    title={localization.columns.status()}/>,
                <DataTableColumn
                    exportOptions={
                        entitiesExportOptions<Contract.AwsResourceModel>(
                            Contract.TypeNames.AwsIamRole,
                            item =>
                                _.isNil((item.entity as Contract.AwsBedrockAgent).roleReference)
                                    ? []
                                    : [(item.entity as Contract.AwsBedrockAgent).roleReference!.idReference],
                            localization.columns.roleReference())}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedEntityFilter
                                    getEntityIdPage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.AwsBedrockAgentRole)}
                                    placeholder={localization.columns.roleReference()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsBedrockAgentRole}
                    key={Contract.EntityModelProperty.AwsBedrockAgentRole}
                    render={
                        optionalTableCell<Contract.AwsBedrockAgentModel>(
                            item =>
                                _.isNil((item.entity as Contract.AwsBedrockAgent).roleReference)
                                    ? undefined
                                    : <EntitiesCell
                                        entityIdsOrModels={(item.entity as Contract.AwsBedrockAgent).roleReference!.idReference}
                                        entityTypeName={Contract.TypeNames.AwsIamRole}
                                        entityVariant="iconText"/>)}
                    sortOptions={{ type: DataTableSortType.Numeric }}
                    title={localization.columns.roleReference()}/>,
                commonEncryptedResourceDefinition.columns.encryptionExistsColumn,
                commonEncryptedResourceDefinition.columns.kmsEncryptionKeyColumn,
                commonResourceDefinition.columns.regionColumn,
                commonResourceDefinition.columns.regionLocationColumn,
                commonResourceDefinition.columns.tagsColumn,
                commonResourceDefinition.columns.attributesColumn
            ]),
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAwsBedrockAgentRequest(
                new Contract.EntityControllerGetEntityModelPageAwsBedrockAgentRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsBedrockAgentRole]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsBedrockAgentStatus]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.AwsBedrockAgentUpdateTime])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags])),
                new Contract.EntityControllerGetEntityModelPageAwsResourceRequestEncrypted(
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEncryptedResourceEncryptionExists]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEncryptedResourceKmsEncryptionKeys]))));
}