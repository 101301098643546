import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, Entity, entityModelStore, InlineEntities, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { EntityExternalConsoleLink, ResourcesExternalConsoleLink } from "../../components";
import { useGetGcpTenantEntityRiskContext } from "../contexts";

export function useGcpComputeProjectSshIamNotEnabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.GcpComputeProjectSshIamNotEnabledRisk;
    const tenantEntityModel = entityModelStore.useGet(risk.entityId) as Contract.GcpTenantEntityModel;

    const getGcpTenantEntityRiskContext = useGetGcpTenantEntityRiskContext();

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.useGcpComputeProjectSshIamNotEnabledRiskDefinition",
            () => ({
                contextItems: {
                    runningInstances: {
                        pluralizer: [
                            "1 running VM instance",
                            "{{count | NumberFormatter.humanize}} running VM instances"
                        ],
                        text: "The {{translatedTenantEntityTypeName}} contains {{runningInstanceIds}}"
                    }
                },
                description: "{{tenantEntity}} does not have OS Login enabled",
                sections: {
                    resolution: {
                        step1: "Click on **Edit**",
                        step2: "Add a metadata entry, setting the key to **enable-oslogin** and the value to **TRUE**",
                        step3: "Click **Save** to confirm",
                        step4: "Click the name of {{instanceIds}} to remove the metadata value that overrides the project setting",
                        step5: "Click on **Edit**",
                        step6: "Under **metadata** remove any entry with key **enable-oslogin** which does not have its value set to **TRUE or 1**",
                        step7: "Click **Save** to confirm",
                        step8: "Click the name of {{instanceTemplateIds}} to remove the metadata value that overrides the project setting",
                        step9: "Click the **CREATE SIMILAR** button",
                        step10: "Under **Management** remove any entry with key **enable-oslogin** which does not have its value set to **TRUE or 1**",
                        step11: "Click on **Create**",
                        step12: "For each instance group and cluster using the instance template, update the configuration to use the newly created instance template",
                        step13: "Delete the old unsecured instance template. Note that you cannot delete an instance template if any managed instance group still references it"
                    }
                }
            }));
    const translatedTenantEntityTypeName =
        entityTypeNameTranslator(
            tenantEntityModel.entity.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            tenantEntity:
                <Entity
                    entityIdOrModel={tenantEntityModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={risk.entityId}
                key={`${Contract.GcpConsolePage.Metadata}_${risk.entityId}`}
                page={Contract.GcpConsolePage.Metadata}/>,
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3(),
            ...(!_.isEmpty(risk.sshIamDisabledInstanceIds)
                ? ([
                    <ResourcesExternalConsoleLink
                        includeSignIn={false}
                        key={`${Contract.TypeNames.GcpComputeInstance}_${risk.tenantId}`}
                        tenantId={risk.tenantId}
                        typeName={Contract.TypeNames.GcpComputeInstance}/>,
                    localization.sections.resolution.step4({
                        instanceIds:
                            <InlineEntities
                                entityIdsOrModels={risk.sshIamDisabledInstanceIds}
                                entityTypeName={Contract.TypeNames.GcpComputeInstance}
                                variant="itemCountAndType"/>
                    }),
                    localization.sections.resolution.step5(),
                    localization.sections.resolution.step6(),
                    localization.sections.resolution.step7()
                ])
                : []),
            ...(!_.isEmpty(risk.sshIamDisabledInstanceTemplateIds)
                ? ([
                    <ResourcesExternalConsoleLink
                        includeSignIn={false}
                        key={`${Contract.TypeNames.GcpComputeInstanceTemplate}_${risk.tenantId}`}
                        tenantId={risk.tenantId}
                        typeName={Contract.TypeNames.GcpComputeInstanceTemplate}/>,
                    localization.sections.resolution.step8({
                        instanceTemplateIds:
                            <InlineEntities
                                entityIdsOrModels={risk.sshIamDisabledInstanceTemplateIds}
                                entityTypeName={Contract.TypeNames.GcpComputeInstanceTemplate}
                                variant="itemCountAndType"/>
                    }),
                    localization.sections.resolution.step9(),
                    localization.sections.resolution.step10(),
                    localization.sections.resolution.step11(),
                    localization.sections.resolution.step12(),
                    localization.sections.resolution.step13()
                ])
                : [])
        ],
        riskModel,
        () => {
            const tenantEntityRiskContext = getGcpTenantEntityRiskContext(tenantEntityModel);
            return [
                tenantEntityRiskContext.generalInformation,
                tenantEntityRiskContext.sensitive,
                !_.isEmpty(risk.runningInstanceIds)
                    ? new RiskDefinitionContextItem(
                        localization.contextItems.runningInstances.text({
                            runningInstanceIds:
                                <InlineEntities
                                    entityIdsOrModels={risk.runningInstanceIds}
                                    entityTypeName={Contract.TypeNames.GcpComputeInstance}
                                    namePluralizer={localization.contextItems.runningInstances.pluralizer}
                                    variant="itemCountAndType"/>,
                            translatedTenantEntityTypeName
                        }))
                    : undefined,
                tenantEntityRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}