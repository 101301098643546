import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function S3BucketAccessPointIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path d="M13.4805 3.72727L6.98047 1L0.480469 3.72727V7.81818C0.480469 11.6023 3.2538 15.1409 6.98047 16C8.64158 15.6182 10.1077 14.7045 11.2271 13.4705L8.9738 11.3432C7.57269 12.2227 5.66602 12.0727 4.43102 10.9068C3.02269 9.57727 3.02269 7.41591 4.43102 6.08636C5.83936 4.75682 8.1288 4.75682 9.53714 6.08636C10.7721 7.25227 10.9238 9.05227 9.99936 10.375L12.0938 12.3523C12.9677 11.0159 13.4805 9.44091 13.4805 7.81818V3.72727Z"/>
            <path d="M6.98047 10.5455C8.17709 10.5455 9.14714 9.62967 9.14714 8.5C9.14714 7.37033 8.17709 6.45455 6.98047 6.45455C5.78385 6.45455 4.8138 7.37033 4.8138 8.5C4.8138 9.62967 5.78385 10.5455 6.98047 10.5455Z"/>
            <path d="M12.0211 18.9929L10.8175 10.1242C10.8038 10.0542 10.8186 9.98167 10.8587 9.92254C10.8988 9.8634 10.9609 9.82251 11.0314 9.80887C11.1019 9.79522 11.1749 9.80993 11.2344 9.84977C11.294 9.8896 11.3351 9.9513 11.3489 10.0213C11.4817 10.5306 13.4877 11.243 16.4501 11.243C19.4126 11.243 21.4159 10.5306 21.5487 10.0213C21.5624 9.9513 21.6036 9.8896 21.6632 9.84977C21.7227 9.80993 21.7957 9.79522 21.8662 9.80887C21.9367 9.82251 21.9988 9.8634 22.0389 9.92254C22.0791 9.98167 22.0938 10.0542 22.0801 10.1242L20.8553 18.9929C20.8553 19.3412 20.5311 19.6394 19.9227 19.8795C19.6526 19.9817 19.3757 20.0646 19.0937 20.1275C18.2217 20.3148 17.3317 20.4068 16.4395 20.402C14.7417 20.402 12.0556 20.0194 12.0211 18.9929Z" stroke="white"/>
            <path d="M16.4411 11.7707C13.925 11.7707 11.1007 11.2034 10.8271 10.1558C10.8262 10.1444 10.8262 10.1329 10.8271 10.1215V10.0133C10.8259 10.0019 10.8259 9.99044 10.8271 9.97904C10.8271 8.81272 13.7975 8 16.465 8C19.1326 8 22.0791 8.81272 22.0791 9.97904C22.0791 9.97904 22.0791 10.0001 22.0791 10.0133L22.0631 10.1215C22.0642 10.1329 22.0642 10.1444 22.0631 10.1558C21.7788 11.2034 18.9546 11.7707 16.4411 11.7707Z" stroke="white"/>
            <path d="M17.0605 14.0659L17.2944 13.5909C19.6856 14.7598 22.5019 15.8391 22.9828 15.6359C22.9456 15.5594 22.7676 15.3219 21.92 14.8654L21.8775 14.839L21.9788 14.3035L22.1831 14.4036C23.0359 14.8707 23.4425 15.2295 23.5115 15.5673C23.5251 15.6378 23.5226 15.7104 23.5042 15.7798C23.4858 15.8492 23.452 15.9137 23.4053 15.9684C23.3275 16.0384 23.2357 16.0911 23.1357 16.1229C23.0359 16.1548 22.9303 16.1651 22.8261 16.1531C21.5614 16.1531 18.7929 14.9102 17.0605 14.0659Z" stroke="#37474F" strokeWidth="0.3"/>
            <path d="M21.4723 15.9352C19.1259 14.9789 17.6553 14.3558 17.0605 14.0658L17.2944 13.5909C18.3629 14.1132 19.7725 14.7137 21.523 15.3922L21.4723 15.9352Z" fill="white" stroke="white" strokeWidth="0.5"/>
            <path
                d="M16.4421 14.1732L16.4398 14.1732C16.2277 14.1732 16.02 14.1107 15.8431 13.9933L16.4421 14.1732ZM16.4421 14.1732C16.7255 14.1719 16.9976 14.0596 17.1991 13.8595L17.1991 13.8595C17.4006 13.6593 17.5151 13.3875 17.5164 13.1029L17.5164 13.1029V13.1006C17.5164 12.8877 17.4528 12.6799 17.3341 12.5034C17.2154 12.327 17.047 12.19 16.8507 12.1092L16.4421 14.1732ZM15.6798 12.341C15.529 12.4907 15.4259 12.682 15.3841 12.8907L15.6798 12.341ZM15.3841 12.8907C15.3423 13.0994 15.3638 13.3158 15.4457 13.5122L15.3841 12.8907ZM15.4457 13.5122C15.5276 13.7086 15.6661 13.8759 15.8431 13.9933L15.4457 13.5122ZM16.8507 12.1092C16.6544 12.0284 16.4386 12.0074 16.2305 12.0485L16.8507 12.1092ZM16.2305 12.0485C16.0223 12.0896 15.8306 12.1912 15.6798 12.3409L16.2305 12.0485Z"
                stroke="white"/>
            <path
                clipRule="evenodd"
                d="M21.155 14.201H21.8862L21.782 14.934L21.0586 14.9889L21.155 14.201Z"
                fill="white"
                fillRule="evenodd"/>
        </SvgIcon>);
}