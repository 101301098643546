import { ItemWithValidation, MultiSelect, useChangeEffect, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import { Contract } from "../../../controllers";
import { scopeSystemEntityModelStore } from "../../../stores";
import { ScopeHelper } from "../../../utilities";
import { DeliveryProps } from "../Delivery";

export function Splunk({ delivery, disabled, onChange, scopeId }: DeliveryProps) {
    const splunkModels = scopeSystemEntityModelStore.useGetSplunk();
    const [selectedItems, setSelectedItems] =
        useState(
            _.map(
                (delivery.destinations ?? []) as Contract.SplunkDeliveryEventCollector[],
                ({ eventCollectorId }) => eventCollectorId));
    const splunkEventCollectorModels =
        ScopeHelper.getParentScopeSystemEntityModelsIntersection(
            [scopeId],
            splunkModels);
    const splunkEventCollectorModelMap =
        useMemo(
            () => _.keyBy(
                splunkEventCollectorModels,
                splunkEventCollectorModel => splunkEventCollectorModel.configuration.id),
            [splunkEventCollectorModels]);

    useChangeEffect(
        () =>
            onChange({
                ...(delivery as Contract.SplunkDelivery),
                destinations:
                    _.map(
                        selectedItems,
                        eventCollectorId => ({
                            eventCollectorId,
                            typeName: Contract.TypeNames.SplunkDeliveryEventCollector
                        })) as Contract.SplunkDeliveryEventCollector[],
                valid: !_.isNil(selectedItems)
            } as ItemWithValidation<Contract.SplunkDelivery>),
        [selectedItems],
        500);

    const localization =
        useLocalization(
            "common.delivery.splunk",
            () => ({
                eventCollectors: "{{eventCollectorCount | NumberFormatter.humanize}} Splunk event collectors",
                title: "Event collectors"
            }));

    return (
        <MultiSelect
            disabled={disabled}
            fullWidth={true}
            items={_.keys(splunkEventCollectorModelMap)}
            placeholder={localization.title()}
            selectedItems={selectedItems}
            selectedItemsTranslator={
                selectedItems =>
                    selectedItems.length === 1
                        ? (splunkEventCollectorModelMap[selectedItems[0]].configuration as Contract.SplunkEventCollectorConfiguration).name
                        : localization.eventCollectors({ eventCollectorCount: selectedItems.length })}
            onSelectedItemsChanged={setSelectedItems}>
            {organizationId => (splunkEventCollectorModelMap[organizationId].configuration as Contract.SplunkEventCollectorConfiguration).name}
        </MultiSelect>);
}