import { Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { map, Message, StringHelper, useLocalization } from "@infrastructure";
import { Contract, entityModelStore } from "../../../../../../../../../common";


type ActivationFailureDataProps = {
    permissionRequestAuditEventModelActivationFailureData: Contract.PermissionRequestAuditEventModelPermissionRequestActivationFailureData;
};

export function ActivationFailureData({ permissionRequestAuditEventModelActivationFailureData }: ActivationFailureDataProps) {
    const displayedItemCount = 3;
    const localization =
        useLocalization(
            "views.user.auditEvents.permissionRequestAuditEvents.typeNameCell.activationFailureData",
            () => ({
                errorMessage: "Error: {{errorMessage}}",
                more: "and {{count}} more.",
                [Contract.TypeNames.PermissionRequestAuditEventPermissionRequestActivationFailureData]: {
                    [Contract.TypeNames.PermissionRequestAuditEventGroupMembershipRequestActivationFailureData]: {
                        group: "{{groupName}}: {{groupErrorMessage}}",
                        title: [
                            "Failed group:",
                            "Failed groups:"
                        ]
                    },
                    [Contract.TypeNames.PermissionRequestAuditEventPermissionAssignmentRequestActivationFailureData]: {
                        tenant: "{{tenantName}} ({{tenantDisplayReference}}): {{tenantErrorMessage}}",
                        title: [
                            "Failed account:",
                            "Failed accounts:"
                        ]
                    }
                }
            }));

    const entityModels =
        entityModelStore.useGet(
            permissionRequestAuditEventModelActivationFailureData.activationFailureData.typeName === Contract.TypeNames.PermissionRequestAuditEventGroupMembershipRequestActivationFailureData
                ? _.keys((permissionRequestAuditEventModelActivationFailureData as Contract.PermissionRequestAuditEventModelGroupMembershipRequestActivationFailureData).groupIdToErrorMessageMap)
                : undefined);
    const entityModelMap =
        useMemo(
            () =>
                _.keyBy(
                    entityModels,
                    entityModel => entityModel.id),
            [entityModels]);

    function getGroupMembershipRequestActivationFailureDataTranslation() {
        const permissionRequestAuditEventModelGroupMembershipRequestActivationFailureData = permissionRequestAuditEventModelActivationFailureData as Contract.PermissionRequestAuditEventModelGroupMembershipRequestActivationFailureData;
        const groupMembershipRequestActivationFailureDataLocalization = localization[Contract.TypeNames.PermissionRequestAuditEventPermissionRequestActivationFailureData][Contract.TypeNames.PermissionRequestAuditEventGroupMembershipRequestActivationFailureData];
        const failedGroupCount = _.size(permissionRequestAuditEventModelGroupMembershipRequestActivationFailureData.groupIdToErrorMessageMap);
        return _<string>([]).
            concat(groupMembershipRequestActivationFailureDataLocalization.title(failedGroupCount)).
            concat(
                _(permissionRequestAuditEventModelGroupMembershipRequestActivationFailureData.groupIdToErrorMessageMap).
                    toPairs().
                    orderBy(([groupIdReference]) => StringHelper.getSortValue(entityModelMap[groupIdReference].entity.displayName)).
                    take(displayedItemCount).
                    map(
                        ([groupIdReference, groupErrorMessage]) =>
                            groupMembershipRequestActivationFailureDataLocalization.group(
                                {
                                    groupErrorMessage,
                                    groupName: entityModelMap[groupIdReference].entity.displayName
                                })).
                    value()).
            concatIf(
                failedGroupCount - displayedItemCount > 0,
                () => localization.more({ count: failedGroupCount - displayedItemCount })).
            join("\n");
    }

    function getPermissionAssignmentRequestActivationFailureDataTranslation() {
        const permissionRequestAuditEventPermissionAssignmentRequestActivationFailureData = permissionRequestAuditEventModelActivationFailureData.activationFailureData as Contract.PermissionRequestAuditEventPermissionAssignmentRequestActivationFailureData;
        const permissionAssignmentRequestActivationFailureDataLocalization = localization[Contract.TypeNames.PermissionRequestAuditEventPermissionRequestActivationFailureData][Contract.TypeNames.PermissionRequestAuditEventPermissionAssignmentRequestActivationFailureData];
        const failedTenantCount = _.size(permissionRequestAuditEventPermissionAssignmentRequestActivationFailureData.tenantDisplayReferenceToDataMap);
        return _<string>([]).
            concat(permissionAssignmentRequestActivationFailureDataLocalization.title(failedTenantCount)).
            concat(
                _(permissionRequestAuditEventPermissionAssignmentRequestActivationFailureData.tenantDisplayReferenceToDataMap).
                    toPairs().
                    orderBy(([tenantDisplayReference]) => StringHelper.getSortValue(tenantDisplayReference)).
                    take(displayedItemCount).
                    map(
                        ([tenantDisplayReference, { errorMessage: tenantErrorMessage, name: tenantName }]) =>
                            permissionAssignmentRequestActivationFailureDataLocalization.tenant(
                                {
                                    tenantDisplayReference,
                                    tenantErrorMessage,
                                    tenantName
                                })).
                    value()).
            concatIf(
                failedTenantCount - displayedItemCount > 0,
                () => localization.more({ count: failedTenantCount - displayedItemCount })).
            join("\n");
    }

    return (
        <Message
            level="error"
            title={
                <Typography sx={{ whiteSpace: "pre-wrap" }}>
                    {_.isNil(permissionRequestAuditEventModelActivationFailureData.activationFailureData.errorMessage)
                        ? map(
                            permissionRequestAuditEventModelActivationFailureData.activationFailureData.typeName,
                            {
                                [Contract.TypeNames.PermissionRequestAuditEventGroupMembershipRequestActivationFailureData]: () => getGroupMembershipRequestActivationFailureDataTranslation(),
                                [Contract.TypeNames.PermissionRequestAuditEventPermissionAssignmentRequestActivationFailureData]: () => getPermissionAssignmentRequestActivationFailureDataTranslation()
                            })
                        : localization.errorMessage({ errorMessage: permissionRequestAuditEventModelActivationFailureData.activationFailureData.errorMessage })}
                </Typography>}
            variant="minimal"/>);
}