import { VisibilityOffOutlined as ConcealIcon, VisibilityOutlined as RevealIcon } from "@mui/icons-material";
import { IconButton, Stack, Typography, useTheme } from "@mui/material";
import _ from "lodash";
import React, { useLayoutEffect, useRef, useState } from "react";

type PasswordTextProps = {
    password: string;
};

export function PasswordText({ password }: PasswordTextProps) {
    const [passwordVisible, setPasswordVisible] = useState(true);
    const [passwordWidth, setPasswordWidth] = useState(0);
    const passwordRef = useRef<HTMLDivElement>(null);

    useLayoutEffect(
        () => {
            setPasswordWidth(passwordRef.current!.offsetWidth);
            setPasswordVisible(false);
        },
        []);

    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            direction="row">
            <Typography
                noWrap={true}
                ref={passwordRef}
                sx={{
                    minWidth:
                        passwordWidth == 0
                            ? "auto"
                            : theme.px(passwordWidth)
                }}>
                {passwordVisible
                    ? password
                    : _.repeat("•", _.max([passwordWidth / 6.88, 1]))}
            </Typography>
            <IconButton
                size="small"
                sx={{
                    color: theme.palette.text.primary,
                    fontSize: "18px",
                    padding: theme.spacing(0, 0, 0, 1)
                }}
                onClick={() => setPasswordVisible(!passwordVisible)}>
                {passwordVisible
                    ? <ConcealIcon/>
                    : <RevealIcon/>}
            </IconButton>
        </Stack>);
}