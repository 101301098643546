import { useMemo } from "react";
import { Contract } from "../../../../../../../../../../../../common";
import { useGetEntitiesRiskContext } from "../../../useGetEntitiesRiskContext";

export function useGetAzureKeyVaultSecretsRiskContext() {
    return useMemo(
        () => useAzureKeyVaultSecretsRiskContext,
        []);
}

function useAzureKeyVaultSecretsRiskContext(secretIdsOrModels: string[] | Contract.AzureKeyVaultVaultSecretModel[]) {
    return useGetEntitiesRiskContext()(
        secretIdsOrModels,
        Contract.TypeNames.AzureKeyVaultVaultSecret);
}