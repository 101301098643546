﻿import { TimeSpanHelper } from "@infrastructure";
import _ from "lodash";
import React, { ReactElement } from "react";
import { Contract, ElasticsearchItemPageHelper, EntityController, scopeNodeModelStore, tenantModelStore, useScopeNavigationViewContext } from "../../../../../../../../../../common";
import { Approval, Description, ExpirationTimeFrame, GranteePrincipals, Groups, Name, Notice, PermissionEligibilityType, PrincipalTenant, Reason } from "../../components";
import { TenantDefinition } from "../../useDefinition";
import { PermissionEligibilityData } from "../../utilities";
import { OktaDirectoryGroupMembershipEligibilityData } from "./utilities";

export function useOktaDefinition(): TenantDefinition {
    function createInsertPermissionEligibilityRequest(permissionEligibilityData: PermissionEligibilityData): Contract.ConfigurationControllerInsertPermissionEligibilityRequest {
        const approval = permissionEligibilityData.approval.createConfigurationControllerPermissionEligibilityApproval();
        const { description, expirationTimeFrameHours, granteePrincipalIds, groupIds, name, principalTenantId, reasonRequired, scopeId } = permissionEligibilityData as OktaDirectoryGroupMembershipEligibilityData;
        return new Contract.ConfigurationControllerInsertOktaDirectoryGroupMembershipEligibilityRequest(
            approval,
            description,
            TimeSpanHelper.fromHours(expirationTimeFrameHours),
            granteePrincipalIds,
            name,
            principalTenantId!,
            reasonRequired,
            scopeId,
            groupIds);
    }

    function createUpdatePermissionEligibilityRequest(permissionEligibilityData: PermissionEligibilityData, permissionEligibilityId: string): Contract.ConfigurationControllerUpdatePermissionEligibilityRequest {
        const approval = permissionEligibilityData.approval.createConfigurationControllerPermissionEligibilityApproval();
        const { description, expirationTimeFrameHours, granteePrincipalIds, groupIds, name, principalTenantId, reasonRequired } = permissionEligibilityData as OktaDirectoryGroupMembershipEligibilityData;
        return new Contract.ConfigurationControllerUpdateOktaDirectoryGroupMembershipEligibilityRequest(
            approval,
            description,
            TimeSpanHelper.fromHours(expirationTimeFrameHours),
            granteePrincipalIds,
            permissionEligibilityId,
            name,
            principalTenantId!,
            reasonRequired,
            groupIds);
    }

    const { scopeNodeModel } = useScopeNavigationViewContext();
    const scopeNodeMap = scopeNodeModelStore.useGetActiveScopeNodeMap();
    const scopeTenantIds = scopeNodeMap[scopeNodeModel.configuration.id].tenantIds;
    const tenantModels = tenantModelStore.useGet(scopeTenantIds) as Contract.OktaTenantModel[];

    return new TenantDefinition(
        createInsertPermissionEligibilityRequest,
        (permissionEligibilityModel, scopeId) =>
            new OktaDirectoryGroupMembershipEligibilityData(
                permissionEligibilityModel,
                scopeId),
        createUpdatePermissionEligibilityRequest,
        _<ReactElement>([]).
            concatIf(
                _.some(
                    tenantModels,
                    oktaTenantModel => !_.isNil(oktaTenantModel.state.monitoring.permissionManagementIssue)),
                () => <Notice permissionEligibilityType={PermissionEligibilityType.GroupMembership}/>).
            concat(
                <Name/>,
                <Description/>,
                <PrincipalTenant/>,
                <GranteePrincipals/>,
                <Groups
                    getModelPage={
                        principalTenantId =>
                            ElasticsearchItemPageHelper.makePagedEntitySelector(
                                async (itemNextPageSearchCursor, searchText) => {
                                    const { entityModelPage } =
                                        await EntityController.searchEntityModels(
                                            new Contract.EntityControllerSearchEntityModelsPermissionManagementOktaDirectoryGroupRequest(
                                                false,
                                                15,
                                                itemNextPageSearchCursor,
                                                principalTenantId,
                                                searchText));
                                    return entityModelPage;
                                })}/>).
            concat(
                <ExpirationTimeFrame/>,
                <Approval/>,
                <Reason/>).
            value());
}