﻿import { Optional } from "@infrastructure";
import React from "react";
import { Contract, RadioGroupProps, RiskPoliciesType, ScopeConfigurationValueType } from "../../../../../../../common";
import { EnabledRadioGroupBase, useSetConfigurationContext } from "../../../components";

export type EnabledRadioGroupProps = Omit<RadioGroupProps<ScopeConfigurationValueType>, "items"> & {
    description: string;
    documentationUrl?: string;
    getEnabled: (riskPolicyConfiguration: Contract.RiskPolicyConfiguration) => Optional<boolean>;
    riskPoliciesType: RiskPoliciesType;
    setEnabled: (riskPolicyConfiguration: Contract.RiskPolicyConfiguration, enabled: Optional<boolean>) => void;
    title: string;
};

export function EnabledRadioGroup({ setEnabled, ...props }: EnabledRadioGroupProps) {
    const setConfigurationContext = useSetConfigurationContext();
    return (
        <EnabledRadioGroupBase
            setEnabled={
                (riskPolicyConfiguration, selectedEnabled) => {
                    setEnabled(
                        riskPolicyConfiguration,
                        selectedEnabled);

                    setConfigurationContext(
                        configurationContext => ({
                            ...configurationContext,
                            dirty: true,
                            riskPolicyConfiguration
                        }));
                }}
            sx={{ marginBottom: 0 }}
            {...props}/>);
}