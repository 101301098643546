﻿import { useLocalization } from "@infrastructure";
import { Contract } from "..";

export function useAccessLevelTranslator() {
    const localization =
        useLocalization(
            "common.hooks.useAccessLevelTranslator",
            () => ({
                [Contract.TypeNames.AccessLevel]: {
                    [Contract.AccessLevel.Internal]: "Internal",
                    [Contract.AccessLevel.CrossTenant]: "Cross Account",
                    [Contract.AccessLevel.External]: "External",
                    [Contract.AccessLevel.Public]: "Public"
                }
            }));

    return (accessLevel: Contract.AccessLevel) =>
        localization[Contract.TypeNames.AccessLevel][accessLevel]();
}