import React from "react";
import { usePrincipalsContext } from "../..";
import { Contract } from "../../../../../../../../common";
import { AadGroup, Group, User } from "./components";

export type AddPrincipalProps = {
    roleAssignment?: Contract.ConfigurationControllerGetPrincipalRoleAssignmentsResponsePrincipalRoleAssignment;
    type: "group" | "user";
};

export function AddOrEditPrincipal({ roleAssignment, type }: AddPrincipalProps) {
    const { aadGroups } = usePrincipalsContext();

    const PrincipalComponent =
        type === "group"
            ? aadGroups
                ? AadGroup
                : Group
            : User;
    return <PrincipalComponent roleAssignment={roleAssignment}/>;
}