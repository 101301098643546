import { useLocalization, WizardFinishItem } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useAddOrEditContext } from "..";

export function FinishItem() {
    const { instanceModel } = useAddOrEditContext();

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useTicketingItems.serviceNow.addOrEdit.finishItem",
            () => ({
                title: {
                    add: "ServiceNow instance was added successfully!",
                    edit: "ServiceNow instance was changed successfully!"
                }
            }));

    return (
        <WizardFinishItem
            title={
                _.isNil(instanceModel)
                    ? localization.title.add()
                    : localization.title.edit()}/>);
}