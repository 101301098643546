import { Dialog, getUrlQueryParameters, Loading, makeContextProvider, StringHelper, useRemoveQueryParameters } from "@infrastructure";
import React from "react";
import { Contract } from "../../../../../../../../../../../../common";
import { AddOrView, Table } from "./components";

export class EndpointConnectorsContext {
    constructor(
        public addOrViewOpen: boolean | Contract.ScopeSystemEntityModel) {
    }
}

export const [useEndpointConnectorsContext, useSetEndpointConnectorsContext, useEndpointConnectorsContextProvider] = makeContextProvider<EndpointConnectorsContext>();

export function EndpointConnectors() {
    const removeQueryParameters = useRemoveQueryParameters();
    const [context, setContext, ContextProvider] =
        useEndpointConnectorsContextProvider(
            () => {
                const queryParameters = getUrlQueryParameters<EndpointConnectorQueryParameters>();
                removeQueryParameters("addOrViewOpen");
                return new EndpointConnectorsContext(StringHelper.isTrue(queryParameters.addOrViewOpen));
            });

    return (
        <ContextProvider>
            {context.addOrViewOpen !== false &&
                <Dialog
                    size="large"
                    variant="editor"
                    onClose={
                        () =>
                            setContext(
                                context => ({
                                    ...context,
                                    addOrViewOpen: false
                                })
                            )}>
                    <AddOrView/>
                </Dialog>}
            <Loading>
                <Table/>
            </Loading>
        </ContextProvider>);
}

export type EndpointConnectorQueryParameters = {
    addOrViewOpen?: string;
};