﻿import { CheckboxField, Message, useChangeEffect, useInputValidation, useLocalization } from "@infrastructure";
import { FormControl, FormHelperText, Stack, TextField } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useTheme } from "../../../../../../../../../themes";

type AccessTokenInputProps = {
    disabled: boolean;
    initialAccessToken: string;
    onAccessTokenChanged: (accessToken: string, valid: boolean) => void;
    onStoreChanged: (store: boolean) => void;
};

export function AccessTokenInput({ disabled, initialAccessToken, onAccessTokenChanged, onStoreChanged }: AccessTokenInputProps) {
    const [accessToken, setAccessToken] = useState(initialAccessToken);
    const [store, setStore] = useState(false);

    const localization =
        useLocalization(
            "common.riskResolutionAutomation.hooks.useDefinition.hooks.useGcpDefinition.gcpAccessTokenItem.accessTokenInput",
            () => ({
                accessToken: {
                    error: {
                        required: "Access Token cannot be empty"
                    },
                    title: "Access Token"
                },
                store: {
                    info: "Credentials will be stored only for the current browser session and will be deleted when you sign out of Tenable Cloud Security.",
                    title: "Store credentials for the current session"
                }
            }));

    const [accessTokenValidationController, accessTokenValidationMessage] =
        useInputValidation(
            () => {
                const validationAccessToken = accessToken?.trim();
                if (_.isEmpty(validationAccessToken)) {
                    return localization.accessToken.error.required();
                }

                return undefined;
            },
            [accessToken]);

    useEffect(
        () => onAccessTokenChanged(accessToken?.trim(), accessTokenValidationController.isValid()),
        [accessToken]);

    useChangeEffect(
        () => onStoreChanged(store),
        [store]);


    const theme = useTheme();
    return (
        <Stack
            spacing={1}
            sx={{ maxWidth: theme.spacing(73) }}>
            <FormControl
                fullWidth={true}
                variant="standard">
                <TextField
                    disabled={disabled}
                    label={localization.accessToken.title()}
                    value={accessToken}
                    variant="outlined"
                    onChange={event => setAccessToken(event.target.value)}/>
                {!_.isNil(accessTokenValidationMessage) &&
                    <FormHelperText error={true}>
                        {accessTokenValidationMessage}
                    </FormHelperText>}
            </FormControl>
            <Stack
                alignItems="center"
                direction="row"
                spacing={1}>
                <CheckboxField
                    checked={store}
                    disabled={disabled}
                    onChange={() => setStore(!store)}>
                    {localization.store.title()}
                </CheckboxField>
                <Message
                    disabled={disabled}
                    level="info"
                    title={localization.store.info()}
                    variant="minimal"/>
            </Stack>
        </Stack>);
}