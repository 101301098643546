import { Action1, InfiniteScroll, InfiniteScrollActions, InfiniteScrollFetchDataResult, Optional, StringHelper, useChangeEffect, useLocalization } from "@infrastructure";
import { Divider, ListItem, ListItemButton } from "@mui/material";
import _ from "lodash";
import React, { Fragment, ReactNode, useMemo, useRef, useState } from "react";
import { Contract, entityModelStore, useEntityTypeNameTranslator, useTheme } from "../../../../..";
import { Item } from "../..";
import { entitySearchableReferenceStore } from "../../../../../stores";

type EntityIdsProps = {
    entityIds: string[];
    entityTypeName: string;
    onSelectionChanged?: Action1<string>;
    renderEntityStats?: (entityId: string) => ReactNode;
    searchText: Optional<string>;
    selectedEntityId: Optional<string>;
};

export function EntityIds({ entityIds, entityTypeName, onSelectionChanged, renderEntityStats, searchText, selectedEntityId }: EntityIdsProps) {
    const entitySearchableReferences = entitySearchableReferenceStore.useGet(entityIds);
    const infiniteScrollActionsRef = useRef<InfiniteScrollActions>();
    const filteredEntitySearchableReferencesRef = useRef<Contract.EntitySearchableReference[]>(entitySearchableReferences);
    const [visibleEntitySearchableReferences, setVisibleEntitySearchableReferences] = useState<Contract.EntitySearchableReference[]>([]);
    const localization =
        useLocalization(
            "common.entityList.list.entityIds",
            () => ({
                empty: "No Matching {{translatedEntityTypeName}}"
            }));
    useChangeEffect(
        () => {
            filteredEntitySearchableReferencesRef.current =
                _.filter(
                    entitySearchableReferences,
                    entitySearchableReference => StringHelper.search(entitySearchableReference.displayName, searchText));
            setVisibleEntitySearchableReferences([]);

            infiniteScrollActionsRef.current!.reset();
        },
        [entitySearchableReferences, searchText]);

    const fetch =
        async () => {
            const visibleEntitySearchableReferencePage =
                filteredEntitySearchableReferencesRef.current.slice(
                    visibleEntitySearchableReferences.length,
                    visibleEntitySearchableReferences.length + 20);

            await entityModelStore.get(
                _.map(
                    visibleEntitySearchableReferencePage,
                    visibleEntitySearchableReference => visibleEntitySearchableReference.id));

            return Promise.resolve(
                new InfiniteScrollFetchDataResult(
                    !_.isEmpty(filteredEntitySearchableReferencesRef.current),
                    visibleEntitySearchableReferences.length + 20 > filteredEntitySearchableReferencesRef.current.length,
                    () => {
                        setVisibleEntitySearchableReferences(
                            visibleEntitySearchableReferences =>
                                _.concat(
                                    visibleEntitySearchableReferences,
                                    visibleEntitySearchableReferencePage));
                    }));
        };

    const theme = useTheme();
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    return (
        <InfiniteScroll
            actionsRef={infiniteScrollActionsRef}
            emptyTextOptions={{
                text:
                    localization.empty({
                        translatedEntityTypeName:
                            entityTypeNameTranslator(
                                entityTypeName,
                                {
                                    count: 0,
                                    includeServiceName: false
                                })
                    })
            }}
            fetchData={fetch}>
            {useMemo(
                () =>
                    _.map(
                        visibleEntitySearchableReferences,
                        (visibleEntitySearchableReference, visibleEntitySearchableReferenceIndex) =>
                            <Fragment key={visibleEntitySearchableReference.id}>
                                {visibleEntitySearchableReferenceIndex !== 0 &&
                                    <Divider/>}
                                {_.isNil(onSelectionChanged)
                                    ? <ListItem
                                        sx={{
                                            "&.Mui-selected": {
                                                color: theme.palette.text.primary
                                            },
                                            "&.MuiListItemButton-root": {
                                                flex: 0
                                            },
                                            padding: theme.spacing(1)
                                        }}>
                                        <Item
                                            entityId={visibleEntitySearchableReference.id}
                                            renderEntityStats={renderEntityStats}/>
                                    </ListItem>
                                    : <ListItemButton
                                        selected={visibleEntitySearchableReference.id === selectedEntityId}
                                        sx={{
                                            "&.Mui-selected": {
                                                color: theme.palette.text.primary
                                            },
                                            "&.MuiListItemButton-root": {
                                                flex: 0
                                            },
                                            padding: theme.spacing(1)
                                        }}
                                        onClick={() => onSelectionChanged?.(visibleEntitySearchableReference.id)}>
                                        <Item
                                            entityId={visibleEntitySearchableReference.id}
                                            renderEntityStats={renderEntityStats}
                                            selected={visibleEntitySearchableReference.id === selectedEntityId}/>
                                    </ListItemButton>}
                            </Fragment>),
                [selectedEntityId, visibleEntitySearchableReferences])}
        </InfiniteScroll>);
}