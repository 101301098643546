import { Dialog, DialogSize, FormLayout, TextViewer } from "@infrastructure";
import { SxProps, Typography } from "@mui/material";
import _ from "lodash";
import React, { Fragment, MouseEvent, ReactNode, useState } from "react";

type InlineTextViewerProps = {
    actionsElement?: ReactNode;
    actionsElementSx?: SxProps;
    copyToClipboard?: boolean;
    dialogSize?: DialogSize;
    dialogTitle: string;
    format?: string;
    highlightedLines?: number[];
    startLine?: number;
    text?: string;
    title: string;
};

export function InlineTextViewer({ actionsElement, actionsElementSx, copyToClipboard, dialogSize = "large", dialogTitle, format = "json", highlightedLines, startLine, text, title }: InlineTextViewerProps) {
    const [open, setOpen] = useState(false);
    return (
        <Fragment>
            {open &&
                <Dialog
                    size={dialogSize}
                    variant="viewer"
                    onClose={() => setOpen(false)}>
                    <FormLayout titleOptions={{ text: dialogTitle }}>
                        <TextViewer
                            actionsElement={actionsElement}
                            actionsElementSx={actionsElementSx}
                            copyToClipboard={copyToClipboard}
                            format={format}
                            height="100%"
                            highlightedLines={highlightedLines}
                            startLine={startLine}
                            text={text!}/>
                    </FormLayout>
                </Dialog>}
            {!_.isNil(text) &&
                <Typography
                    component="span"
                    sx={{
                        cursor: "pointer",
                        textDecoration: "underline"
                    }}
                    onClick={
                        (event: MouseEvent) => {
                            setOpen(true);
                            event.stopPropagation();
                        }}>
                    {title}
                </Typography>}
        </Fragment>);
}