import { UnexpectedError } from "@infrastructure";
import { Contract } from "..";
import { RiskPolicyConfigurationExclusionDataReason } from "../controllers/types.generated";

export class EntityPropertyPatternHelper {
    public static getExclusionEntityPropertyPattern(entityPropertyPatternTypeName: string): Contract.RiskPolicyConfigurationEntityExclusionEntityPropertyPattern {
        const exclusionData =
            new Contract.RiskPolicyConfigurationExclusionData(
                undefined,
                undefined,
                RiskPolicyConfigurationExclusionDataReason.Exception);
        switch (entityPropertyPatternTypeName) {
            case Contract.TypeNames.RiskPolicyConfigurationEntityExclusionAwsResourceArnPattern:
                return new Contract.RiskPolicyConfigurationEntityExclusionAwsResourceArnPattern(exclusionData, "");
            case Contract.TypeNames.RiskPolicyConfigurationEntityExclusionEntityNamePattern:
                return new Contract.RiskPolicyConfigurationEntityExclusionEntityNamePattern(exclusionData, "");
            case Contract.TypeNames.RiskPolicyConfigurationEntityExclusionResourceTagPattern:
                return new Contract.RiskPolicyConfigurationEntityExclusionResourceTagPattern(exclusionData, new Contract.ResourceTagPattern(true, "", ""));
            default:
                throw new UnexpectedError("ExcludedEntityPropertyPattern.typeName", entityPropertyPatternTypeName);
        }
    }

    public static getExclusionEntityPropertyPatternKey(entityPropertyPattern: Contract.RiskPolicyConfigurationEntityExclusionEntityPropertyPattern): string {
        switch (entityPropertyPattern.typeName) {
            case Contract.TypeNames.RiskPolicyConfigurationEntityExclusionAwsResourceArnPattern:
                return `${entityPropertyPattern.typeName}-${(entityPropertyPattern as Contract.RiskPolicyConfigurationEntityExclusionAwsResourceArnPattern).arnPattern}`;
            case Contract.TypeNames.RiskPolicyConfigurationEntityExclusionEntityNamePattern:
                return `${entityPropertyPattern.typeName}-${(entityPropertyPattern as Contract.RiskPolicyConfigurationEntityExclusionEntityNamePattern).namePattern}`;
            case Contract.TypeNames.RiskPolicyConfigurationEntityExclusionResourceTagPattern:
                return `${entityPropertyPattern.typeName}-${(entityPropertyPattern as Contract.RiskPolicyConfigurationEntityExclusionResourceTagPattern).resourceTagPattern.keyPattern}-${(entityPropertyPattern as Contract.RiskPolicyConfigurationEntityExclusionResourceTagPattern).resourceTagPattern.valuePattern}`;
            default:
                throw new UnexpectedError("ExcludedEntityPropertyPattern.typeName", entityPropertyPattern.typeName);
        }
    }
}