import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../..";
import { Contract, Entity, entityModelStore, InlineEntities } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetAwsSsmParameterRiskContext } from "../contexts";

export function useAwsSsmParameterSecretExistsRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsSsmParameterSecretExistsRisk;
    const parameterModel = entityModelStore.useGet(risk.entityId) as Contract.AwsSsmParameterModel;
    const parameter = parameterModel.entity as Contract.AwsSsmParameter;

    const getAwsSsmParameterRiskContext = useGetAwsSsmParameterRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsSsmParameterSecretExistsRiskDefinition",
            () => ({
                contextItems: {
                    identities: {
                        text: "{{identities}}{{thirdPartyRolesPart}} are granted with permissions allowing them to read the parameter value",
                        thirdPartyRolesPart: {
                            text: " including {{thirdPartyRoles}}",
                            thirdPartyRoles: [
                                "1 third party role",
                                "{{count | NumberFormatter.humanize}} third party roles"
                            ]
                        }
                    }
                },
                description: "{{parameter}} is exposing a secret",
                sections: {
                    resolution: {
                        step1: "Copy the clear text value",
                        step2: "Create a new SecureString parameter with the same value",
                        step3: "Migrate your applications to use the new parameter",
                        step4: "Delete the non secured parameter"
                    }
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            parameter:
                <Entity
                    entityIdOrModel={parameterModel}
                    entityTypeNameTranslatorOptions={{
                        includeServiceName: true,
                        variant: "title"
                    }}
                    variant="typeText"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.Ssm,
                AwsConsoleUrlBuilder.getSsmParameterUrl(parameter)),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4()
        ],
        riskModel,
        () => {
            const parameterRiskContext = getAwsSsmParameterRiskContext(parameterModel);
            return [
                parameterRiskContext.generalInformation,
                parameterRiskContext.updateIdentity,
                new RiskDefinitionContextItem(
                    localization.contextItems.identities.text({
                        identities:
                            <InlineEntities
                                entityIdsOrModels={risk.parameterPermissionsIdentityIds}
                                entityTypeName={Contract.TypeNames.AwsIamIdentity}
                                variant="itemCountAndType"/>,
                        thirdPartyRolesPart:
                            risk.parameterPermissionsThirdPartyRoleIds.length == 0
                                ? ""
                                : localization.contextItems.identities.thirdPartyRolesPart.text({
                                    thirdPartyRoles:
                                        <InlineEntities
                                            entityIdsOrModels={risk.parameterPermissionsThirdPartyRoleIds}
                                            entityTypeName={Contract.TypeNames.AwsIamRole}
                                            namePluralizer={localization.contextItems.identities.thirdPartyRolesPart.thirdPartyRoles}
                                            variant="itemCountAndType"/>
                                })
                    })),
                parameterRiskContext.sensitive,
                parameterRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        });
}