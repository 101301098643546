import { map, useLocalization, useLocalizeList } from "@infrastructure";
import _ from "lodash";
import { AuditEventDefinition, AuditEventDefinitionContext, AuditEventDefinitionDetailItem } from "..";
import { Contract, ResourceTagPatternHelper } from "../../../../../../../../../common";

export function useAutomaticCustomEntityAttributeDefinitionTemplateAuditEventDefinition(context: AuditEventDefinitionContext) {
    const localizeList = useLocalizeList();
    const localization =
        useLocalization(
            "views.customer.auditEvents.profile.hooks.useDefinition.hooks.useAutomaticCustomEntityAttributeDefinitionTemplateAuditEventDefinition",
            () => ({
                color: "Color",
                configuration: {
                    title: {
                        [Contract.TypeNames.AutomaticCustomEntityAttributeDefinitionTemplateConfigurationType]: {
                            [Contract.AutomaticCustomEntityAttributeDefinitionTemplateConfigurationType.ResourceTag]: "Tag",
                            [Contract.AutomaticCustomEntityAttributeDefinitionTemplateConfigurationType.ResourceTagKey]: "Tag Key",
                            [Contract.AutomaticCustomEntityAttributeDefinitionTemplateConfigurationType.ResourceTagPattern]: "Tag Patterns"
                        }
                    }
                },
                id: "Id",
                name: "Name",
                propertyChanges: "Updated properties",
                type: {
                    text: {
                        [Contract.TypeNames.AutomaticCustomEntityAttributeDefinitionTemplateConfigurationType]: {
                            [Contract.AutomaticCustomEntityAttributeDefinitionTemplateConfigurationType.ResourceTag]: "Automatic label based on exact tag key and value",
                            [Contract.AutomaticCustomEntityAttributeDefinitionTemplateConfigurationType.ResourceTagKey]: "Automatic label based on exact tag key",
                            [Contract.AutomaticCustomEntityAttributeDefinitionTemplateConfigurationType.ResourceTagPattern]: "Automatic label based on tag key and value patterns"
                        }
                    },
                    title: "Type"
                }
            }));

    function TranslateConfiguration(
        type: Contract.AutomaticCustomEntityAttributeDefinitionTemplateConfigurationType,
        resourceTagPatterns: Contract.ResourceTagPattern[]) {
        return map(
            type,
            {
                [Contract.AutomaticCustomEntityAttributeDefinitionTemplateConfigurationType.ResourceTag]: () => ResourceTagPatternHelper.getDisplayName(_.head(resourceTagPatterns)!),
                [Contract.AutomaticCustomEntityAttributeDefinitionTemplateConfigurationType.ResourceTagKey]: () => _.head(resourceTagPatterns)!.keyPattern,
                [Contract.AutomaticCustomEntityAttributeDefinitionTemplateConfigurationType.ResourceTagPattern]: () =>
                    _(resourceTagPatterns).
                        map(resourceTagPattern => ResourceTagPatternHelper.getDisplayName(resourceTagPattern)).
                        join(", ")
            });
    }

    const customEntityAttributeDefinitionTemplateAuditEvent = context.auditEventModel.auditEvent as Contract.AutomaticCustomEntityAttributeDefinitionTemplateAuditEvent;

    return new AuditEventDefinition(
        _<AuditEventDefinitionDetailItem>([]).
            concat(
                new AuditEventDefinitionDetailItem(
                    localization.name(),
                    customEntityAttributeDefinitionTemplateAuditEvent.name ?? "-"),
                new AuditEventDefinitionDetailItem(
                    localization.type.title(),
                    localization.type.text[Contract.TypeNames.AutomaticCustomEntityAttributeDefinitionTemplateConfigurationType][customEntityAttributeDefinitionTemplateAuditEvent.type!]()),
                new AuditEventDefinitionDetailItem(
                    localization.configuration.title[Contract.TypeNames.AutomaticCustomEntityAttributeDefinitionTemplateConfigurationType][customEntityAttributeDefinitionTemplateAuditEvent.type!](),
                    TranslateConfiguration(customEntityAttributeDefinitionTemplateAuditEvent.type!, customEntityAttributeDefinitionTemplateAuditEvent.resourceTagPatterns!))).
            concatIf(
                customEntityAttributeDefinitionTemplateAuditEvent.typeName === Contract.TypeNames.AutomaticCustomEntityAttributeDefinitionTemplateUpdateAuditEvent,
                () => {
                    const properties =
                        _.filter([
                            (customEntityAttributeDefinitionTemplateAuditEvent as Contract.AutomaticCustomEntityAttributeDefinitionTemplateUpdateAuditEvent).nameChanged
                                ? localization.name()
                                : undefined,
                            (customEntityAttributeDefinitionTemplateAuditEvent as Contract.AutomaticCustomEntityAttributeDefinitionTemplateUpdateAuditEvent).typeChanged
                                ? localization.type.title()
                                : undefined,
                            !(customEntityAttributeDefinitionTemplateAuditEvent as Contract.AutomaticCustomEntityAttributeDefinitionTemplateUpdateAuditEvent).typeChanged &&
                            (customEntityAttributeDefinitionTemplateAuditEvent as Contract.AutomaticCustomEntityAttributeDefinitionTemplateUpdateAuditEvent).resourceTagPatternsChanged
                                ? localization.configuration.title[Contract.TypeNames.AutomaticCustomEntityAttributeDefinitionTemplateConfigurationType][customEntityAttributeDefinitionTemplateAuditEvent.type!]()
                                : undefined,
                            (customEntityAttributeDefinitionTemplateAuditEvent as Contract.AutomaticCustomEntityAttributeDefinitionTemplateUpdateAuditEvent).colorChanged
                                ? localization.color()
                                : undefined]);
                    return (
                        _<AuditEventDefinitionDetailItem>([]).
                            concatIf(
                                !_.isEmpty(properties),
                                new AuditEventDefinitionDetailItem(
                                    localization.propertyChanges(),
                                    localizeList(properties)!)).
                            value());
                }).
            value());
}