import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, DeferredFilter, EnumValuesFilter, optionalTableCell, PagedValuesFilter, TimeFormatter, TimeRangeFilter, useLocalization } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { DefinitionData } from "../../../..";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { useOneLoginDirectoryUserProvisioningStateTranslator, useOneLoginDirectoryUserStatusTranslator } from "../../../../../../hooks";
import { useOneLoginCommonDirectoryEntityDefinition } from "./useOneLoginCommonDirectoryEntityDefinition";

export function useOneLoginDirectoryUserDefinition(definitionData: DefinitionData) {
    const userModelFiltersPromise = definitionData.entityModelFiltersPromise as Promise<Contract.OneLoginDirectoryUserModelFilters>;
    const commonDirectoryEntityDefinition = useOneLoginCommonDirectoryEntityDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const directoryUserProvisioningStateTranslator = useOneLoginDirectoryUserProvisioningStateTranslator();
    const directoryUserStatusTranslator = useOneLoginDirectoryUserStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.oneLogin.useOneLoginDirectoryUserDefinition",
            () => ({
                columns: {
                    activityTime: {
                        empty: "Not seen",
                        title: "AWS Activity Time"
                    },
                    awsRoles: "AWS Roles",
                    creationTime: "Creation Time",
                    group: "Group",
                    provisioningState: "State",
                    rawMail: "Mail",
                    roles: "Roles",
                    status: "Status"
                }
            }));

    function getActivityPastDurationTranslation(entityModel: Contract.EntityModel) {
        const userProfile = entityModel.entityProfile as Contract.OneLoginDirectoryUserProfile;
        return _.isNil(userProfile?.activityTime)
            ? localization.columns.activityTime.empty()
            : TimeFormatter.humanizePastDuration(userProfile.activityTime);
    }

    return new EntityTableDefinition(
        [
            commonDirectoryEntityDefinition.columns.tenantColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.OneLoginDirectoryUserModel) => ({
                            [localization.columns.rawMail()]: (item.entity as Contract.OneLoginDirectoryUser).rawMail
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedValuesFilter
                                getValuePage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.OneLoginDirectoryUserRawMail)}
                                placeholder={localization.columns.rawMail()}/>
                    }
                }}
                id={Contract.EntityModelProperty.OneLoginDirectoryUserRawMail}
                key={Contract.EntityModelProperty.OneLoginDirectoryUserRawMail}
                render={optionalTableCell<Contract.OneLoginDirectoryUserModel>(item => (item.entity as Contract.OneLoginDirectoryUser).rawMail)}
                title={localization.columns.rawMail()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.OneLoginDirectoryUserModel) => ({
                            [localization.columns.creationTime()]: TimeFormatter.iso8601String((item.entity as Contract.OneLoginDirectoryUser).creationTime)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <DeferredFilter
                                promiseOrGetPromise={userModelFiltersPromise}
                                title={localization.columns.creationTime()}>
                                {filters =>
                                    <TimeRangeFilter
                                        emptyValue={filters.creationTimeRange.emptyValue}
                                        placeholder={localization.columns.creationTime()}
                                        timeRange={TimeRangeHelper.getTimeRangeFilterRange(filters.creationTimeRange.timeRange)}/>}
                            </DeferredFilter>
                    }
                }}
                id={Contract.EntityModelProperty.OneLoginDirectoryUserCreationTime}
                itemProperty={(item: Contract.OneLoginDirectoryEntityModel) => TimeFormatter.monthDayAndYear((item.entity as Contract.OneLoginDirectoryUser).creationTime)}
                key={Contract.EntityModelProperty.OneLoginDirectoryUserCreationTime}
                sortOptions={{ type: DataTableSortType.Date }}
                title={localization.columns.creationTime()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.OneLoginDirectoryUserModel) => ({
                            [localization.columns.status()]: directoryUserStatusTranslator((item.entity as Contract.OneLoginDirectoryUser).status)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.OneLoginDirectoryUserStatus}
                                enumTypeTranslator={directoryUserStatusTranslator}
                                placeholder={localization.columns.status()}/>
                    }
                }}
                id={Contract.EntityModelProperty.OneLoginDirectoryUserStatus}
                itemProperty={(item: Contract.EntityModel) => directoryUserStatusTranslator((item.entity as Contract.OneLoginDirectoryUser).status)}
                key={Contract.EntityModelProperty.OneLoginDirectoryUserStatus}
                title={localization.columns.status()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.OneLoginDirectoryUserModel) => ({
                            [localization.columns.provisioningState()]: directoryUserProvisioningStateTranslator((item.entity as Contract.OneLoginDirectoryUser).provisioningState)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.OneLoginDirectoryUserProvisioningState}
                                enumTypeTranslator={directoryUserProvisioningStateTranslator}
                                placeholder={localization.columns.provisioningState()}/>
                    }
                }}
                id={Contract.EntityModelProperty.OneLoginDirectoryUserProvisioningState}
                itemProperty={(item: Contract.EntityModel) => directoryUserProvisioningStateTranslator((item.entity as Contract.OneLoginDirectoryUser).provisioningState)}
                key={Contract.EntityModelProperty.OneLoginDirectoryUserProvisioningState}
                title={localization.columns.provisioningState()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.OneLoginDirectoryUserModel) => ({
                            [localization.columns.activityTime.title()]: getActivityPastDurationTranslation(item)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <DeferredFilter
                                promiseOrGetPromise={userModelFiltersPromise}
                                title={localization.columns.activityTime.title()}>
                                {filters =>
                                    <TimeRangeFilter
                                        emptyValue={filters.activityTimeRange.emptyValue}
                                        placeholder={localization.columns.activityTime.title()}
                                        timeRange={TimeRangeHelper.getTimeRangeFilterRange(filters.activityTimeRange.timeRange)}/>}
                            </DeferredFilter>
                    }
                }}
                id={Contract.EntityModelProperty.OneLoginDirectoryUserActivityTime}
                itemProperty={getActivityPastDurationTranslation}
                key={Contract.EntityModelProperty.OneLoginDirectoryUserActivityTime}
                sortOptions={{ type: DataTableSortType.Date }}
                title={localization.columns.activityTime.title()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.OneLoginDirectoryUserModel>(
                        Contract.TypeNames.AwsIamRole,
                        item => item.awsRoleIds,
                        localization.columns.awsRoles())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.OneLoginDirectoryUserAwsRoles)}
                                placeholder={localization.columns.awsRoles()}/>
                    }
                }}
                id={Contract.EntityModelProperty.OneLoginDirectoryUserAwsRoles}
                key={Contract.EntityModelProperty.OneLoginDirectoryUserAwsRoles}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.OneLoginDirectoryUserModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.awsRoleIds}
                            entityTypeName={Contract.TypeNames.AwsIamRole}
                            entityVariant="iconText"/>}
                title={localization.columns.awsRoles()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.OneLoginDirectoryUserModel>(
                        Contract.TypeNames.OneLoginDirectoryGroup,
                        item => [item.groupId],
                        localization.columns.group(),
                        { getValue: entityModel => entityModel.entity.displayName })}
                filterOptions={{
                    itemOrItems: {
                        default: true,
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.OneLoginDirectoryUserGroup)}
                                placeholder={localization.columns.group()}/>
                    }
                }}
                id={Contract.EntityModelProperty.OneLoginDirectoryUserGroup}
                key={Contract.EntityModelProperty.OneLoginDirectoryUserGroup}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.OneLoginDirectoryUserModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.groupId}
                            entityTypeName={Contract.TypeNames.OneLoginDirectoryGroup}/>}
                title={localization.columns.group()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.OneLoginDirectoryUserModel>(
                        Contract.TypeNames.OneLoginDirectoryRole,
                        item => item.roleIds,
                        localization.columns.roles())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.OneLoginDirectoryUserRoles)}
                                placeholder={localization.columns.roles()}/>
                    }
                }}
                id={Contract.EntityModelProperty.OneLoginDirectoryUserRoles}
                key={Contract.EntityModelProperty.OneLoginDirectoryUserRoles}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.OneLoginDirectoryUserModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.roleIds}
                            entityTypeName={Contract.TypeNames.OneLoginDirectoryRole}
                            entityVariant="iconText"/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.roles()}/>,
            commonDirectoryEntityDefinition.columns.attributesColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageOneLoginDirectoryUserRequest(
                new Contract.EntityControllerGetEntityModelPageOneLoginDirectoryUserRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.OneLoginDirectoryUserActivityTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.OneLoginDirectoryUserAwsRoles]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.OneLoginDirectoryUserCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.OneLoginDirectoryUserGroup]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OneLoginDirectoryUserProvisioningState]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.OneLoginDirectoryUserRawMail]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.OneLoginDirectoryUserRoles]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OneLoginDirectoryUserStatus])),
                limit,
                skip,
                requestSort));
}