import { DataTableActions, DataTableColumn, DataTableColumnRenderProps, useChangeEffect, useLocalization, ValuesFilter, ValuesFilterItem, ValuesFilterSelection } from "@infrastructure";
import { Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { useCallback, useMemo, useRef } from "react";
import { Contract } from "../../../../../../../../../common";
import { ItemTableColumnId } from "../../../Items";
import { ComplianceDefinition } from "../useDefinition";

export function useBuiltInDefinition(scopeId: string) {
    const dataTableActionsRef = useRef<DataTableActions>();

    useChangeEffect(
        async () => {
            dataTableActionsRef.current?.setFilter(
                ItemTableColumnId.Enabled,
                () =>
                    new ValuesFilterSelection(
                        false,
                        [true]));
        },
        [scopeId]);

    const localization =
        useLocalization(
            "views.customer.compliance.items.hooks.useDefinition.hooks.useBuiltInDefinition",
            () => ({
                columns: {
                    scope: "Scope",
                    status: {
                        disabled: "Disabled",
                        enabled: "Enabled",
                        title: "Status"
                    }
                }
            }));

    const getAdditionalColumn =
        useCallback(
            (enabledBuiltInComplianceSectionDatasMap: Dictionary<Contract.ComplianceSectionData>) =>
                <DataTableColumn
                    filterOptions={{
                        itemOrItems: {
                            default: true,
                            element:
                                <ValuesFilter placeholder={localization.columns.status.title()}>
                                    <ValuesFilterItem
                                        title={localization.columns.status.enabled()}
                                        value={true}/>
                                    <ValuesFilterItem
                                        title={localization.columns.status.disabled()}
                                        value={false}/>
                                </ValuesFilter>,
                            title: localization.columns.status.title()
                        }
                    }}
                    id={ItemTableColumnId.Enabled}
                    key={ItemTableColumnId.Enabled}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.ComplianceSectionData>) =>
                            <Typography>
                                {_.isNil(enabledBuiltInComplianceSectionDatasMap[item.identifier])
                                    ? localization.columns.status.disabled()
                                    : localization.columns.status.enabled()}
                            </Typography>}
                    sortOptions={{ enabled: false }}
                    title={localization.columns.status.title()}/>,
            [localization]);

    return useMemo(
        (): ComplianceDefinition => ({
            actionsRef: dataTableActionsRef,
            defaultSortColumn: ItemTableColumnId.Enabled,
            defaultSortMap: { [ItemTableColumnId.Enabled]: "desc" },
            getAdditionalColumn,
            getColumnIdToGetItemValueMap:
                enabledBuiltInComplianceSectionDatasMap => ({
                    [ItemTableColumnId.Enabled]: (item: Contract.ComplianceSectionData) => !_.isNil(enabledBuiltInComplianceSectionDatasMap[item.identifier])
                }),
            initialFilterMap: {
                [ItemTableColumnId.Enabled]:
                    new ValuesFilterSelection(
                        false,
                        [true])
            }
        }),
        [getAdditionalColumn]);
}