import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useAzureDocumentDbDatabaseAccountTypeTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useAzureDocumentDbDatabaseAccountTypeTranslator",
            () => ({
                [Contract.TypeNames.AzureDocumentDbDatabaseAccountType]: {
                    [Contract.AzureDocumentDbDatabaseAccountType.Cassandra]: "Cassandra",
                    [Contract.AzureDocumentDbDatabaseAccountType.Gremlin]: "Gremlin",
                    [Contract.AzureDocumentDbDatabaseAccountType.MongoDb]: "MongoDB",
                    [Contract.AzureDocumentDbDatabaseAccountType.Sql]: "SQL",
                    [Contract.AzureDocumentDbDatabaseAccountType.Table]: "Table"
                }
            }));
    return (databaseAccountType: Contract.AzureDocumentDbDatabaseAccountType) =>
        localization[Contract.TypeNames.AzureDocumentDbDatabaseAccountType][databaseAccountType]();
}