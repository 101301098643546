import { Action1, FormLayout, Message, Optional, useLocalization } from "@infrastructure";
import { Box, Button, CircularProgress, Stack } from "@mui/material";
import _ from "lodash";
import React, { useState } from "react";
import { Tree, TreeProps, useTheme } from "..";

type TreeItemMoveFormProps<T> =
    Pick<TreeProps<T>, "children" | "rootItem" | "onTreeItemClick"> & {
        errorMessage?: string;
        getItemDisabled?: (item: T) => boolean;
        loading?: boolean;
        onSave: Action1<T>;
        selected?: T;
    };

export function TreeItemMoveForm<T>({ children, errorMessage, getItemDisabled, loading = false, onSave, onTreeItemClick, rootItem, selected: initialSelected }: TreeItemMoveFormProps<T>) {
    const [selected, setSelected] = useState<Optional<T>>(initialSelected);
    const localization =
        useLocalization(
            "common.treeItemMoveForm",
            () => ({
                save: "Move",
                title: "Move to..."
            }));

    const theme = useTheme();
    return (
        <FormLayout
            disableContentPadding={true}
            footerOptions={{
                border: true,
                contentElement:
                    <Stack
                        alignItems="center"
                        direction="row"
                        spacing={1}
                        sx={{ width: "100%" }}>
                        <Box sx={{ flex: 1 }}>
                            {!_.isNil(errorMessage) &&
                                <Message
                                    level="error"
                                    title={errorMessage}/>}
                        </Box>
                        {loading &&
                            <CircularProgress
                                size={theme.spacing(2)}
                                variant="indeterminate"/>}
                        <Button
                            disabled={
                                _.isNil(selected) ||
                                _.isEqual(initialSelected, selected) ||
                                getItemDisabled?.(selected)}
                            sx={{ width: "fit-content" }}
                            onClick={() => onSave(selected!)}>
                            {localization.save()}
                        </Button>
                    </Stack>
            }}
            titleOptions={{ text: localization.title() }}>
            <Tree
                containerSx={{
                    height: theme.spacing(50),
                    padding: theme.spacing(1, 2),
                    scrollbarGutter: "stable"
                }}
                expandOnItemClick={false}
                getItemSx={
                    item =>
                        _.isEqual(
                            item.value,
                            selected)
                            ? { backgroundColor: theme.palette.background.alternate }
                            : {}}
                isExpanded={() => true}
                itemHover={true}
                rootItem={rootItem}
                searchEnabled={false}
                onTreeItemClick={
                    item => {
                        onTreeItemClick?.(item);
                        setSelected(item.value);
                    }}>
                {item => children(item)}
            </Tree>
        </FormLayout>);
}