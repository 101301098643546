import { TimeFormatter, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useAzureCommonEntityInfoItemElements } from ".";
import { Contract, entityModelStore, InfoItem, TenantHelper, tenantModelStore, useTenantNameTranslator } from "../../../../../../../../../../common";
import { EntitiesInfoItem, TagsInfoItem } from "../../../../components";

export function useAzureCommonResourceInfoItemElements(resourceModel: Contract.AzureResourceModel) {
    const resource = resourceModel.entity as Contract.AzureResource;
    const commonEntityInfoItemElements = useAzureCommonEntityInfoItemElements(resourceModel);
    const creatorIdentityModel = entityModelStore.useGet(resourceModel.creatorIdentityIdReference);
    const tenantModel =
        tenantModelStore.useGet(
            TenantHelper.isCommonTenant(resource.tenantId)
                ? undefined
                : resource.tenantId);
    const tenantNameTranslator = useTenantNameTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.azure.useAzureCommonResourceInfoItemElements",
            () => ({
                aadTenantId: "Directory",
                creationTime: "Creation Time",
                creatorIdentity: "Created By",
                tags: "Tags"
            }));
    return {
        ...commonEntityInfoItemElements,
        aadTenantInfoItemElement:
            <InfoItem
                key="aadTenantId"
                title={localization.aadTenantId()}
                value={
                    _.isNil((tenantModel?.configuration as Contract.AzureTenantConfiguration)?.aadTenantId)
                        ? undefined
                        : tenantNameTranslator((tenantModel!.configuration as Contract.AzureTenantConfiguration).aadTenantId)}/>,
        createdByInfoItemElement:
            <EntitiesInfoItem
                entityIdsOrModels={creatorIdentityModel}
                entityTypeName={creatorIdentityModel?.typeName ?? Contract.TypeNames.AadDirectoryIdentity}
                key="creatorIdentityModel"
                title={localization.creatorIdentity()}/>,
        creationTimeInfoItemElement:
            <InfoItem
                key="creationTime"
                location="all"
                title={localization.creationTime()}
                value={
                    _.isNil(resourceModel.creationTime)
                        ? undefined
                        : TimeFormatter.profileFormatDateTime(resourceModel.creationTime)}/>,
        tagsInfoItemElement:
            <TagsInfoItem
                key="tags"
                location="all"
                resourceTags={resource.tags}
                title={localization.tags()}/>
    };
}