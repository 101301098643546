import _ from "lodash";
import React from "react";
import { useLocalization } from "@infrastructure";
import { Contract, InfoItem, VerticalTopItemsInfoItem } from "../../../../../../../../../../common";

export function useAwsCommonCloudWatchLoggingResourceInfoElements(entityModel: Contract.AwsEntityModel) {
    const entity = _.as<Contract.IAwsCloudWatchLoggingResource>(entityModel.entity);

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsCommonCloudWatchLoggingResourceInfoElements",
            () => ({
                cloudWatchLoggingEnabled: {
                    disabled: "Disabled",
                    enabled: "Enabled",
                    title: "CloudWatch Logging"
                },
                cloudWatchLogTypes: "CloudWatch Log Types"
            }));

    return {
        cloudWatchLoggingEnabled:
            <InfoItem
                key="cloudWatchLogging"
                title={localization.cloudWatchLoggingEnabled.title()}
                value={
                    entity.cloudWatchLoggingEnabled
                        ? localization.cloudWatchLoggingEnabled.enabled()
                        : localization.cloudWatchLoggingEnabled.disabled()}/>,
        cloudWatchLogTypes:
            <VerticalTopItemsInfoItem
                items={entity.cloudWatchLogTypes}
                key="CloudWatchLogTypes"
                title={localization.cloudWatchLogTypes()}/>
    };
}