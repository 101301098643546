import { ConfirmButton, useLocalization, useSetRoute, WelcomeView } from "@infrastructure";
import { Stack } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, CustomerConsoleAppUrlHelper, TenantIcon, tenantModelStore, useTheme } from "../../../../../../../../../common";
import { useSetScopesContext } from "../../../../../Scopes";

export function Welcome() {
    const setRoute = useSetRoute();
    const aadTenantModels = tenantModelStore.useGetAadTenants();
    const [nonAzureOrganizationAadTenantModels, validAadTenantModels] =
        useMemo(
            () => {
                const validAadTenantModels =
                    _.filter(
                        aadTenantModels,
                        aadTenantModel => aadTenantModel.status === Contract.AadTenantModelStatus.Valid);

                const nonAzureOrganizationAadTenantModels =
                    _.filter(
                        validAadTenantModels,
                        validAadTenantModel => _.isNil(validAadTenantModel.configuration.azureOrganizationId));

                return [nonAzureOrganizationAadTenantModels, validAadTenantModels];
            },
            [aadTenantModels]);
    const setScopesContext = useSetScopesContext();

    const localization =
        useLocalization(
            "views.customer.scopes.hooks.useDefinition.hooks.useAzureDefinition.welcome",
            () => ({
                action: {
                    organization: {
                        confirmTitle: "To onboard an Azure organization, first onboard the Microsoft Entra ID tenant that’s linked to the organization. Afterwards, you can proceed with organization onboarding. Do you want to onboard the tenant now?",
                        title: "Add Organization"
                    },
                    tenant: {
                        confirmTitle: "To onboard an Azure subscription, first onboard the Microsoft Entra ID tenant that’s linked to the subscription. Afterwards, you can proceed with subscription onboarding. Do you want to onboard the tenant now?",
                        title: "Add Subscription"
                    }
                },
                title: "Connect your Azure Environment"
            }));

    const theme = useTheme();
    return (
        <WelcomeView title={localization.title()}>
            <Stack
                spacing={4}
                sx={{
                    height: "100%",
                    width: "100%"
                }}>
                <ConfirmButton
                    disableConfirm={!_.isEmpty(nonAzureOrganizationAadTenantModels)}
                    icon={<TenantIcon tenantType={Contract.TenantType.Azure}/>}
                    message={localization.action.organization.confirmTitle()}
                    sx={{
                        padding: theme.spacing(1, 3),
                        width: "100%"
                    }}
                    variant="outlined"
                    onClick={
                        () => {
                            if (_.isEmpty(nonAzureOrganizationAadTenantModels)) {
                                setRoute(CustomerConsoleAppUrlHelper.getConfigurationIntegrationsIdentityProviderTenantsAadRelativeUrl(true));
                            } else {
                                setRoute(CustomerConsoleAppUrlHelper.getConfigurationIntegrationsCloudProviderTenantOrganizationsAzureRelativeUrl(true));
                            }
                        }}>
                    {localization.action.organization.title()}
                </ConfirmButton>
                <ConfirmButton
                    disableConfirm={!_.isEmpty(validAadTenantModels)}
                    icon={<TenantIcon tenantType={Contract.TenantType.Azure}/>}
                    message={localization.action.tenant.confirmTitle()}
                    sx={{
                        padding: theme.spacing(1, 3),
                        width: "100%"
                    }}
                    variant="outlined"
                    onClick={
                        () => {
                            if (_.isEmpty(validAadTenantModels)) {
                                setRoute(CustomerConsoleAppUrlHelper.getConfigurationIntegrationsIdentityProviderTenantsAadRelativeUrl(true));
                            } else {
                                setScopesContext(
                                    context => ({
                                        ...context,
                                        addOrEditOpen: "scope"
                                    }));
                            }
                        }}>
                    {localization.action.tenant.title()}
                </ConfirmButton>
            </Stack>
        </WelcomeView>);
}