import { useLocalization, useLocalizeList } from "@infrastructure";
import _ from "lodash";
import { AuditEventDefinition, AuditEventDefinitionContext, AuditEventDefinitionDetailItem } from "..";
import { Contract } from "../../../../../../../../../common";

export function useInternalSubnetAuditEventDefinition(context: AuditEventDefinitionContext) {
    const localizeList = useLocalizeList();

    const localization =
        useLocalization(
            "views.customer.auditEvents.profile.hooks.useDefinition.hooks.useInternalSubnetAuditEventDefinition",
            () => ({
                existingSubnet: "Subnet",
                name: "Name",
                propertyChanges: "Updated properties",
                subnet: "New subnet"
            }));

    const auditEvent = context.auditEventModel.auditEvent;
    return new AuditEventDefinition(
        _.filter([
            new AuditEventDefinitionDetailItem(
                localization.existingSubnet(),
                (auditEvent as Contract.InternalSubnetUpdateAuditEvent).existingSubnet!),
            auditEvent.typeName === Contract.TypeNames.InternalSubnetCreationAuditEvent ||
                auditEvent.typeName === Contract.TypeNames.InternalSubnetDeletionAuditEvent
                ? new AuditEventDefinitionDetailItem(
                    localization.subnet(),
                    (auditEvent as (Contract.InternalSubnetCreationAuditEvent | Contract.InternalSubnetDeletionAuditEvent)).subnet)
                : undefined!,
            auditEvent.typeName === Contract.TypeNames.InternalSubnetUpdateAuditEvent &&
                !_.isNil((auditEvent as Contract.InternalSubnetUpdateAuditEvent).subnet)
                ? new AuditEventDefinitionDetailItem(
                    localization.subnet(),
                    (auditEvent as Contract.InternalSubnetUpdateAuditEvent).subnet!)
                : undefined!,
            auditEvent.typeName === Contract.TypeNames.InternalSubnetUpdateAuditEvent &&
                (auditEvent as Contract.InternalSubnetUpdateAuditEvent).nameChanged
                ? new AuditEventDefinitionDetailItem(
                    localization.propertyChanges(),
                    localizeList([localization.name()])!)
                : undefined!
        ]));
}