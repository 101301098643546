import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, Entity, entityModelStore, InlineEntities, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetKubernetesClusterRiskContext } from "../../../useGetKubernetesClusterRiskContext";
import { useGetAzureResourceRiskContext } from "./useGetAzureResourceRiskContext";

export function useGetAzureContainerServiceManagedClusterRiskContext() {
    return useMemo(
        () => useAzureContainerServiceManagedClusterRiskContext,
        []);
}

function useAzureContainerServiceManagedClusterRiskContext(clusterModel: Contract.AzureContainerServiceManagedClusterModel) {
    const cluster = clusterModel.entity as Contract.AzureContainerServiceManagedCluster;
    const agentPoolModels = entityModelStore.useGet(cluster.agentPoolIds) as Contract.AzureContainerServiceManagedClusterAgentPoolModel[];
    const kubernetesClusterRiskContext = useGetKubernetesClusterRiskContext()(clusterModel);
    const resourceRiskContext = useGetAzureResourceRiskContext()(clusterModel);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.contexts.useGetAzureContainerServiceManagedClusterRiskContext",
            () => ({
                agentPoolIdsAndComputeResources: {
                    nodes: [
                        "{{count | NumberFormatter.humanize}} node",
                        "{{count | NumberFormatter.humanize}} nodes"
                    ],
                    virtualMachineScaleSets: [
                        "{{count | NumberFormatter.humanize}} scale set",
                        "{{count | NumberFormatter.humanize}} scale sets"
                    ],
                    withoutVirtualMachineScaleSetIds: "The {{translatedClusterTypeName}} has {{agentPools}} running {{nodes}}",
                    withVirtualMachineScaleSetIds: "The {{translatedClusterTypeName}} has {{agentPools}} running {{nodes}} using {{virtualMachineScaleSets}}"
                },
                virtualNetwork: "The cluster is part of {{virtualNetwork}}"
            }));
    const translatedClusterTypeName =
        entityTypeNameTranslator(
            cluster.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });

    const [nodeCount, virtualMachineScaleSetIds] =
        useMemo(
            () => {
                const nodeCount =
                    _(agentPoolModels).
                        map(agentPoolModel => (agentPoolModel.entity as Contract.AzureContainerServiceManagedClusterAgentPool).nodeCount).
                        sum();
                const virtualMachineScaleSetIds =
                    _.flatMap(
                        agentPoolModels,
                        agentPoolModel => (agentPoolModel.entity as Contract.AzureContainerServiceManagedClusterAgentPool).virtualMachineScaleSetIds);
                return [nodeCount, virtualMachineScaleSetIds];
            },
            [agentPoolModels]);

    return {
        ...kubernetesClusterRiskContext,
        ...resourceRiskContext,
        agentPoolIdsAndComputeResources:
            new RiskDefinitionContextItem(
                (_.isEmpty(virtualMachineScaleSetIds)
                    ? localization.agentPoolIdsAndComputeResources.withoutVirtualMachineScaleSetIds
                    : localization.agentPoolIdsAndComputeResources.withVirtualMachineScaleSetIds)({
                    agentPools:
                        <InlineEntities
                            entityIdsOrModels={cluster.agentPoolIds}
                            entityTypeName={Contract.TypeNames.AzureContainerServiceManagedClusterAgentPool}
                            variant="itemCountAndType"/>,
                    nodes: localization.agentPoolIdsAndComputeResources.nodes(nodeCount),
                    translatedClusterTypeName,
                    virtualMachineScaleSets:
                        <InlineEntities
                            entityIdsOrModels={virtualMachineScaleSetIds}
                            entityTypeName={Contract.TypeNames.AzureComputeVirtualMachineScaleSet}
                            namePluralizer={localization.agentPoolIdsAndComputeResources.virtualMachineScaleSets}
                            variant="itemCountAndType"/>
                })),
        virtualNetwork:
            _.isNil(cluster.network.virtualNetworkId)
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.virtualNetwork({
                        virtualNetwork:
                            <Entity
                                entityIdOrModel={cluster.network.virtualNetworkId}
                                variant="typeText"/>
                    })),
        wideRangeInboundSubnets: resourceRiskContext.getWideRangeInboundSubnetsContextItem(clusterModel.wideRangeAuthorizedSubnets)
    };
}