import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, Entity, entityModelStore, InlineEntities } from "../../../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../../../tenants";
import { useCommonSectionsAndDescriptionDefinition } from "../../../../../useCommonSectionsAndDescriptionDefinition";
import { useGetAwsDocDbClusterRiskContext } from "../../../contexts";
import { useGetAwsRdsDocDbClusterSnapshotEncryptionDisabledRiskClusterContext } from "./useGetAwsRdsDocDbClusterSnapshotEncryptionDisabledRiskClusterContext";

export function useAwsRdsDocDbClusterSnapshotEncryptionDisabledRiskClusterDefinition(riskModel: Contract.AwsRdsDocDbClusterSnapshotEncryptionDisabledRiskModel) {
    const risk = riskModel.risk;
    const clusterModel = entityModelStore.useGet(riskModel.risk.entityId) as Contract.AwsRdsDocDbClusterModel;
    const cluster = clusterModel.entity as Contract.AwsRdsDocDbCluster;

    const getAwsRdsDocDbClusterRiskContext = useGetAwsDocDbClusterRiskContext();
    const getAwsRdsDocDbClusterSnapshotEncryptionDisabledRiskClusterContext = useGetAwsRdsDocDbClusterSnapshotEncryptionDisabledRiskClusterContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsRdsDocDbClusterSnapshotEncryptionDisabledRiskDefinition.hooks.useAwsRdsDocDbClusterSnapshotEncryptionDisabledRiskClusterDefinition",
            () => ({
                description: {
                    many: "{{cluster}} has {{snapshots}} that are not configured with KMS encryption",
                    none: "{{cluster}} is not encrypted using KMS",
                    single: "{{cluster}} has {{snapshots}} that is not configured with KMS encryption"
                },
                sections: {
                    resolution: {
                        step1: "DocumentDB cluster snapshots can only be encrypted at the time of creation. It is not possible to enable encryption for a snapshot after it has been created. To address this, you must restore an unencrypted snapshot to a new cluster with encryption enabled",
                        step2: "On the **Snapshots** page, select the unencrypted cluster snapshot and click **Actions**, and then Restore",
                        step3: "At the bottom of the page, choose **Show advanced settings**",
                        step4: "In the **Encryption-at-rest** section, select **Enable encryption** and select the encryption key",
                        step5: "Click **Restore cluster**",
                        step6: "On the **Clusters** page, select the newly restored cluster and click **Actions**, and then **Take snapshot**",
                        step7: "Before deleting any cluster or snapshot, ensure that your cluster is properly configured to avoid downtime",
                        step8: "Delete the old, unencrypted DocumentDB cluster snapshots"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        (_.isEmpty(risk.aggregatedEntityIds)
            ? localization.description.none
            : risk.aggregatedEntityIds.length === 1
                ? localization.description.single
                : localization.description.many)({
            cluster:
                <Entity
                    entityIdOrModel={clusterModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>,
            snapshots:
                <InlineEntities
                    entityIdsOrModels={risk.aggregatedEntityIds}
                    entityTypeName={Contract.TypeNames.AwsRdsDocDbClusterSnapshot}
                    variant="itemCountAndType"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.DocDb,
                AwsConsoleUrlBuilder.getRdsDocDbClusterUrl(cluster)),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4(),
            localization.sections.resolution.step5(),
            localization.sections.resolution.step6(),
            localization.sections.resolution.step7(),
            localization.sections.resolution.step8()
        ],
        riskModel,
        () => {
            const clusterContextItems = getAwsRdsDocDbClusterRiskContext(clusterModel);
            const snapshotEncryptionDisabledRiskClusterContextItems = getAwsRdsDocDbClusterSnapshotEncryptionDisabledRiskClusterContext(riskModel);
            return [
                clusterContextItems.generalInformation,
                snapshotEncryptionDisabledRiskClusterContextItems.awsBackup,
                snapshotEncryptionDisabledRiskClusterContextItems.manual,
                snapshotEncryptionDisabledRiskClusterContextItems.automatic,
                clusterContextItems.size,
                snapshotEncryptionDisabledRiskClusterContextItems.internal,
                snapshotEncryptionDisabledRiskClusterContextItems.snapshotIdsEmpty,
                clusterContextItems.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        });
}