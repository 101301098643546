import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function MailIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                clipRule="evenodd"
                d="M9.1001 21H12.0001H12.1001H15.0001C18.3001 21 20.1001 20.9 21.5001 19.5C22.9001 18.1 23.0001 16.3 23.0001 13.1V10.1C23.0001 6.89995 22.9001 5.09995 21.5001 3.69995C20.1001 2.29995 18.3001 2.19995 15.0001 2.19995H9.1001C5.8001 2.19995 4.0001 2.29995 2.6001 3.69995C1.2001 5.09995 1.1001 6.89995 1.1001 10.1V13.1C1.1001 16.3 1.2001 18.1 2.6001 19.5C4.0001 20.9 5.8001 21 9.1001 21ZM3.9001 18C3.1001 17.2 3.0001 16.1 3.0001 13V10.1C3.0001 8.59353 3.02371 7.55939 3.13979 6.80245L8.7 10.3C10 11.1 11 11.5 12 11.5H12.1C13.1 11.5 14.1 11.1 15.4 10.3L20.869 6.85985C20.9777 7.61149 21.0001 8.63126 21.0001 10.1V13C21.0001 16.1 20.9001 17.2 20.1001 18C19.3001 18.9 18.1001 19 14.9001 19H9.1001C5.9001 18.9 4.7001 18.8 3.9001 18ZM20.006 5.01076L14.3 8.6C12.3 9.8 11.8 9.8 9.8 8.6L4.02617 4.96807C4.82805 4.18999 6.06372 4.09995 9.1001 4.09995H14.9001C17.9771 4.19611 19.2049 4.29226 20.006 5.01076Z"
                fillRule="evenodd"/>
        </SvgIcon>);
}