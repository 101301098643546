﻿import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function UnitedStatesIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <g clipPath="url(#clip0_60203_5283)">
                <rect
                    fill="white"
                    height="16"
                    rx="2"
                    width="24"
                    y="4"/>
                <path
                    d="M24 4.00012H0V20.0001H24V4.00012Z"
                    fill="#B31942"/>
                <path
                    d="M0 5.84619H24H0ZM24 8.30773H0H24ZM0 10.7693H24H0ZM24 13.2308H0H24ZM0 15.6923H24H0ZM24 18.1539H0H24Z"
                    fill="#000008"/>
                <path
                    d="M0 5.84619H24M24 8.30773H0M0 10.7693H24M24 13.2308H0M0 15.6923H24M24 18.1539H0"
                    stroke="white"
                    strokeWidth="1.22799"/>
                <path
                    d="M12.1325 4.00012H0V12.6155H12.1325V4.00012Z"
                    fill="#0A3161"/>
                <path
                    d="M1.01531 4.37097L1.30403 5.26156L0.548157 4.71115H1.48246L0.726594 5.26156L1.01531 4.37097Z"
                    fill="white"/>
                <path
                    d="M1.01531 6.09399L1.30403 6.98459L0.548157 6.43417H1.48246L0.726594 6.98459L1.01531 6.09399Z"
                    fill="white"/>
                <path
                    d="M1.01531 7.81714L1.30403 8.70773L0.548157 8.15731H1.48246L0.726594 8.70773L1.01531 7.81714Z"
                    fill="white"/>
                <path
                    d="M1.01531 9.54016L1.30403 10.4308L0.548157 9.88034H1.48246L0.726594 10.4308L1.01531 9.54016Z"
                    fill="white"/>
                <path
                    d="M1.01446 11.2628L1.30317 12.1534L0.547302 11.603H1.48161L0.725739 12.1534L1.01446 11.2628Z"
                    fill="white"/>
                <path
                    d="M2.02453 5.23254L2.31324 6.12314L1.55737 5.57272H2.49168L1.73581 6.12314L2.02453 5.23254Z"
                    fill="white"/>
                <path
                    d="M2.02453 6.95557L2.31324 7.84616L1.55737 7.29574H2.49168L1.73581 7.84616L2.02453 6.95557Z"
                    fill="white"/>
                <path
                    d="M2.02453 8.67871L2.31324 9.5693L1.55737 9.01889H2.49168L1.73581 9.5693L2.02453 8.67871Z"
                    fill="white"/>
                <path
                    d="M2.02453 10.4017L2.31324 11.2923L1.55737 10.7419H2.49168L1.73581 11.2923L2.02453 10.4017Z"
                    fill="white"/>
                <path
                    d="M3.03295 4.37097L3.32167 5.26156L2.5658 4.71115H3.5001L2.74423 5.26156L3.03295 4.37097Z"
                    fill="white"/>
                <path
                    d="M3.03295 6.09424L3.32167 6.98483L2.5658 6.43441H3.5001L2.74423 6.98483L3.03295 6.09424Z"
                    fill="white"/>
                <path
                    d="M3.03295 7.81714L3.32167 8.70773L2.5658 8.15731H3.5001L2.74423 8.70773L3.03295 7.81714Z"
                    fill="white"/>
                <path
                    d="M3.03295 9.54016L3.32167 10.4308L2.5658 9.88034H3.5001L2.74423 10.4308L3.03295 9.54016Z"
                    fill="white"/>
                <path
                    d="M3.03356 11.2631L3.32228 12.1537L2.56641 11.6032H3.50071L2.74484 12.1537L3.03356 11.2631Z"
                    fill="white"/>
                <path
                    d="M4.04595 5.23254L4.33467 6.12314L3.5788 5.57272H4.5131L3.75723 6.12314L4.04595 5.23254Z"
                    fill="white"/>
                <path
                    d="M4.04595 6.95557L4.33467 7.84616L3.5788 7.29574H4.5131L3.75723 7.84616L4.04595 6.95557Z"
                    fill="white"/>
                <path
                    d="M4.04595 8.67871L4.33467 9.5693L3.5788 9.01889H4.5131L3.75723 9.5693L4.04595 8.67871Z"
                    fill="white"/>
                <path
                    d="M4.04595 10.4017L4.33467 11.2923L3.5788 10.7419H4.5131L3.75723 11.2923L4.04595 10.4017Z"
                    fill="white"/>
                <path
                    d="M5.05669 4.37097L5.34541 5.26156L4.58954 4.71115H5.52385L4.76798 5.26156L5.05669 4.37097Z"
                    fill="white"/>
                <path
                    d="M5.05657 6.09399L5.34529 6.98459L4.58942 6.43417H5.52372L4.76785 6.98459L5.05657 6.09399Z"
                    fill="white"/>
                <path
                    d="M5.05657 7.81714L5.34529 8.70773L4.58942 8.15731H5.52372L4.76785 8.70773L5.05657 7.81714Z"
                    fill="white"/>
                <path
                    d="M5.05657 9.54016L5.34529 10.4308L4.58942 9.88034H5.52372L4.76785 10.4308L5.05657 9.54016Z"
                    fill="white"/>
                <path
                    d="M5.05645 11.2631L5.34516 12.1537L4.58929 11.6032H5.5236L4.76773 12.1537L5.05645 11.2631Z"
                    fill="white"/>
                <path
                    d="M6.06695 5.23254L6.35566 6.12314L5.59979 5.57272H6.5341L5.77823 6.12314L6.06695 5.23254Z"
                    fill="white"/>
                <path
                    d="M6.06695 6.95557L6.35566 7.84616L5.59979 7.29574H6.5341L5.77823 7.84616L6.06695 6.95557Z"
                    fill="white"/>
                <path
                    d="M6.06695 8.67871L6.35566 9.5693L5.59979 9.01889H6.5341L5.77823 9.5693L6.06695 8.67871Z"
                    fill="white"/>
                <path
                    d="M6.06695 10.4017L6.35566 11.2923L5.59979 10.7419H6.5341L5.77823 11.2923L6.06695 10.4017Z"
                    fill="white"/>
                <path
                    d="M7.08007 4.37097L7.36879 5.26156L6.61292 4.71115H7.54722L6.79135 5.26156L7.08007 4.37097Z"
                    fill="white"/>
                <path
                    d="M7.08007 6.09399L7.36879 6.98459L6.61292 6.43417H7.54722L6.79135 6.98459L7.08007 6.09399Z"
                    fill="white"/>
                <path
                    d="M7.08007 7.81714L7.36879 8.70773L6.61292 8.15731H7.54722L6.79135 8.70773L7.08007 7.81714Z"
                    fill="white"/>
                <path
                    d="M7.08007 9.54016L7.36879 10.4308L6.61292 9.88034H7.54722L6.79135 10.4308L7.08007 9.54016Z"
                    fill="white"/>
                <path
                    d="M7.07946 11.2628L7.36818 12.1534L6.6123 11.603H7.54661L6.79074 12.1534L7.07946 11.2628Z"
                    fill="white"/>
                <path
                    d="M8.08996 5.23254L8.37867 6.12314L7.6228 5.57272H8.55711L7.80124 6.12314L8.08996 5.23254Z"
                    fill="white"/>
                <path
                    d="M8.08996 6.95557L8.37867 7.84616L7.6228 7.29574H8.55711L7.80124 7.84616L8.08996 6.95557Z"
                    fill="white"/>
                <path
                    d="M8.08996 8.67871L8.37867 9.5693L7.6228 9.01889H8.55711L7.80124 9.5693L8.08996 8.67871Z"
                    fill="white"/>
                <path
                    d="M8.08996 10.4017L8.37867 11.2923L7.6228 10.7419H8.55711L7.80124 11.2923L8.08996 10.4017Z"
                    fill="white"/>
                <path
                    d="M9.09759 4.37073L9.3863 5.26132L8.63043 4.7109H9.56474L8.80887 5.26132L9.09759 4.37073Z"
                    fill="white"/>
                <path
                    d="M9.09759 6.09375L9.3863 6.98434L8.63043 6.43393H9.56474L8.80887 6.98434L9.09759 6.09375Z"
                    fill="white"/>
                <path
                    d="M9.09759 7.81689L9.3863 8.70749L8.63043 8.15707H9.56474L8.80887 8.70749L9.09759 7.81689Z"
                    fill="white"/>
                <path
                    d="M9.09759 9.53992L9.3863 10.4305L8.63043 9.88009H9.56474L8.80887 10.4305L9.09759 9.53992Z"
                    fill="white"/>
                <path
                    d="M9.09838 11.2626L9.3871 12.1532L8.63123 11.6027H9.56553L8.80966 12.1532L9.09838 11.2626Z"
                    fill="white"/>
                <path
                    d="M10.1108 5.2323L10.3995 6.12289L9.64368 5.57248H10.578L9.82211 6.12289L10.1108 5.2323Z"
                    fill="white"/>
                <path
                    d="M10.1108 6.95532L10.3995 7.84592L9.64368 7.2955H10.578L9.82211 7.84592L10.1108 6.95532Z"
                    fill="white"/>
                <path
                    d="M10.1108 8.67847L10.3995 9.56906L9.64368 9.01864H10.578L9.82211 9.56906L10.1108 8.67847Z"
                    fill="white"/>
                <path
                    d="M10.1108 10.4015L10.3995 11.2921L9.64368 10.7417H10.578L9.82211 11.2921L10.1108 10.4015Z"
                    fill="white"/>
                <path
                    d="M11.1222 4.37036L11.4109 5.26095L10.655 4.71054H11.5893L10.8335 5.26095L11.1222 4.37036Z"
                    fill="white"/>
                <path
                    d="M11.122 6.09363L11.4107 6.98422L10.6548 6.4338H11.5892L10.8333 6.98422L11.122 6.09363Z"
                    fill="white"/>
                <path
                    d="M11.122 7.81653L11.4107 8.70712L10.6548 8.1567H11.5892L10.8333 8.70712L11.122 7.81653Z"
                    fill="white"/>
                <path
                    d="M11.122 9.53955L11.4107 10.4301L10.6548 9.87973H11.5892L10.8333 10.4301L11.122 9.53955Z"
                    fill="white"/>
                <path
                    d="M11.1221 11.2625L11.4108 12.153L10.655 11.6026H11.5893L10.8334 12.153L11.1221 11.2625Z"
                    fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0_60203_5283">
                    <rect
                        fill="white"
                        height="16"
                        rx="2"
                        width="24"
                        y="4"/>
                </clipPath>
            </defs>
        </SvgIcon>);
}