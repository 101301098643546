import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function SourcesIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                clipRule="evenodd"
                d="M11.4268 1L12.5647 1C14.3921 0.999979 15.8644 0.999962 17.022 1.15582C18.2221 1.31738 19.2322 1.6627 20.0348 2.46459C20.8378 3.26692 21.1832 4.27755 21.3446 5.47771C21.5001 6.63368 21.5 8.10351 21.5 9.92683V16.929C21.5 17.4813 21.0523 17.929 20.5 17.929C19.9477 17.929 19.5 17.4813 19.5 16.929V10C19.5 8.08622 19.4979 6.7514 19.3624 5.7443C19.2308 4.76595 18.9902 4.24808 18.6212 3.87941C18.2518 3.51031 17.7334 3.26962 16.7552 3.13794C15.7483 3.00237 14.4136 3 12.5 3H11.5C9.58622 3 8.2514 3.00213 7.2443 3.13758C6.26595 3.26916 5.74808 3.50982 5.37941 3.87881C5.0103 4.24823 4.76962 4.76662 4.63794 5.7448C4.50237 6.75174 4.5 8.08638 4.5 10V19.5C4.5 20.0523 4.05229 20.5 3.5 20.5C2.94772 20.5 2.5 20.0523 2.5 19.5L2.5 9.93534C2.49998 8.10792 2.49996 6.63564 2.65582 5.47795C2.81738 4.27789 3.1627 3.26778 3.96459 2.4652C4.76692 1.66218 5.77755 1.31684 6.97771 1.15543C8.13368 0.999951 9.6035 0.999973 11.4268 1Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M3.52513 17.0251C4.1815 16.3687 5.07174 16 6 16H20.5C21.0523 16 21.5 16.4477 21.5 17C21.5 17.5523 21.0523 18 20.5 18H6C5.60218 18 5.22064 18.158 4.93934 18.4393C4.65804 18.7206 4.5 19.1022 4.5 19.5C4.5 19.8978 4.65804 20.2794 4.93934 20.5607C5.22064 20.842 5.60218 21 6 21H20.5C21.0523 21 21.5 21.4477 21.5 22C21.5 22.5523 21.0523 23 20.5 23H6C5.07174 23 4.1815 22.6313 3.52513 21.9749C2.86875 21.3185 2.5 20.4283 2.5 19.5C2.5 18.5717 2.86875 17.6815 3.52513 17.0251Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M8 7C8 6.44772 8.44772 6 9 6H15C15.5523 6 16 6.44772 16 7C16 7.55228 15.5523 8 15 8H9C8.44772 8 8 7.55228 8 7ZM8 11C8 10.4477 8.44772 10 9 10H12C12.5523 10 13 10.4477 13 11C13 11.5523 12.5523 12 12 12H9C8.44772 12 8 11.5523 8 11ZM20.5 18C20.1022 18 19.7206 18.158 19.4393 18.4393C19.158 18.7206 19 19.1022 19 19.5C19 19.8978 19.158 20.2794 19.4393 20.5607C19.7206 20.842 20.1022 21 20.5 21C21.0523 21 21.5 21.4477 21.5 22C21.5 22.5523 21.0523 23 20.5 23C19.5717 23 18.6815 22.6313 18.0251 21.9749C17.3687 21.3185 17 20.4283 17 19.5C17 18.5717 17.3687 17.6815 18.0251 17.0251C18.6815 16.3687 19.5717 16 20.5 16C21.0523 16 21.5 16.4477 21.5 17C21.5 17.5523 21.0523 18 20.5 18Z"
                fillRule="evenodd"/>
        </SvgIcon>);
}