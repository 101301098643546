import { useLocalization } from "@infrastructure";
import _ from "lodash";
import { AuditEventDefinition, AuditEventDefinitionContext, AuditEventDefinitionDetailItem } from "..";
import { Contract, RiskTypeMetadataModelHelper, useRiskResolutionReasonTranslator, useRiskStatusSummeryTranslator } from "../../../../../../../../../common";
import { useRiskAuditEventDetailsItems } from "./useRiskAuditEventDetailsItems";

export function useRiskStatusUpdateAuditEventDefinition(context: AuditEventDefinitionContext) {
    const riskAuditEventDetailsItems = useRiskAuditEventDetailsItems(context);

    const riskResolutionReasonTranslator = useRiskResolutionReasonTranslator();
    const riskStatusSummeryTranslator = useRiskStatusSummeryTranslator();
    const localization =
        useLocalization(
            "views.customer.auditEvents.profile.hooks.useDefinition.hooks.useRiskStatusUpdateAuditEventDefinition",
            () => ({
                originalMessage: "Message",
                riskNewStatus: "New status",
                riskOldStatus: "Old status",
                riskResolutionReason: "Resolve reason"
            }));

    const riskStatusUpdateAuditEvent = context.auditEventModel.auditEvent as Contract.RiskStatusUpdateAuditEvent;
    const riskModel = context.riskModelMap[riskStatusUpdateAuditEvent.riskId];
    return new AuditEventDefinition(
        _.filter([
            riskAuditEventDetailsItems.riskPolicyTypeItem,
            riskAuditEventDetailsItems.riskIdItem,
            riskAuditEventDetailsItems.riskDeletedItem,
            riskAuditEventDetailsItems.riskedEntitiesItem,
            riskAuditEventDetailsItems.riskLinkItem,
            new AuditEventDefinitionDetailItem(
                localization.riskOldStatus(),
                riskStatusSummeryTranslator(
                    riskStatusUpdateAuditEvent.riskOldStatus,
                    riskStatusUpdateAuditEvent.riskOldSubStatus,
                    riskStatusUpdateAuditEvent.riskOldIgnoreExpirationDate)),
            new AuditEventDefinitionDetailItem(
                localization.riskNewStatus(),
                riskStatusSummeryTranslator(
                    riskStatusUpdateAuditEvent.riskNewStatus,
                    riskStatusUpdateAuditEvent.riskNewSubStatus,
                    riskStatusUpdateAuditEvent.riskNewIgnoreExpirationDate)),
            _.isNil(riskStatusUpdateAuditEvent.riskResolutionReason)
                ? undefined
                : new AuditEventDefinitionDetailItem(
                    localization.riskResolutionReason(),
                    riskResolutionReasonTranslator(
                        riskStatusUpdateAuditEvent.riskResolutionReason,
                        RiskTypeMetadataModelHelper.get(riskModel.risk.typeName).riskedEntityTypeName)),
            _.isNil(riskStatusUpdateAuditEvent.originalMessage)
                ? undefined
                : new AuditEventDefinitionDetailItem(
                    localization.originalMessage(),
                    riskStatusUpdateAuditEvent.originalMessage),
            riskAuditEventDetailsItems.errorMessageItem
        ]) as AuditEventDefinitionDetailItem[]);
}