import { useLocalization } from "@infrastructure";
import { ToolbarToggleFilterId } from "../../../../../components";

export function useAzureNetworkToolbarToggleFilterIdTranslator() {
    const localization =
        useLocalization(
            "common.network.hooks.useDefinition.hooks.useAzureDefinition.hooks.useAzureNetworkToolbarToggleFilterIdTranslator",
            () => ({
                toggleFilters: {
                    [ToolbarToggleFilterId.WideRangeSourceSubnet]: "Wide Range"
                }
            }));
    return (toolbarToggleFilterId: ToolbarToggleFilterId) =>
        localization.toggleFilters[toolbarToggleFilterId]();
}