import { map, PrivateIcon, PublicIcon, Sx } from "@infrastructure";
import { SvgIconProps } from "@mui/material";
import React from "react";
import { Contract, useTheme } from "../../../../../common";

type PublicAccessIconProps = SvgIconProps & {
    publicAccess: Contract.AzureStorageStorageAccountBlobContainerPublicAccess;
};

export function PublicAccessIcon({ publicAccess, sx, ...props }: PublicAccessIconProps) {
    const theme = useTheme();
    return map(
        publicAccess,
        {
            [Contract.AzureStorageStorageAccountBlobContainerPublicAccess.Blob]: () =>
                <PublicIcon
                    sx={Sx.combine(sx, { color: theme.palette.warning.main })}
                    {...props}/>,
            [Contract.AzureStorageStorageAccountBlobContainerPublicAccess.Container]: () =>
                <PublicIcon
                    sx={Sx.combine(sx, { color: theme.palette.error.main })}
                    {...props}/>,
            [Contract.AzureStorageStorageAccountBlobContainerPublicAccess.None]: () =>
                <PrivateIcon
                    sx={Sx.combine(sx, { color: theme.palette.text.secondary })}
                    {...props}/>
        });
}