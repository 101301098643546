import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function GraphIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <g clipPath="url(#clip0_10_3258)">
                <path d="M0 11C0 10.4477 0.447715 10 1 10H5C5.55228 10 6 10.4477 6 11V13C6 13.5523 5.55228 14 5 14H1C0.447715 14 0 13.5523 0 13V11Z"/>
                <path d="M17 7C17 6.44772 17.4477 6 18 6H23C23.5523 6 24 6.44772 24 7V9C24 9.55229 23.5523 10 23 10H18C17.4477 10 17 9.55229 17 9V7Z"/>
                <path d="M17 15C17 14.4477 17.4477 14 18 14H23C23.5523 14 24 14.4477 24 15V17C24 17.5523 23.5523 18 23 18H18C17.4477 18 17 17.5523 17 17V15Z"/>
                <path d="M11 6H14C14.5523 6 15 6.44772 15 7C15 7.55228 14.5523 8 14 8H11V6Z"/>
                <path d="M11 16H14C14.5523 16 15 16.4477 15 17C15 17.5523 14.5523 18 14 18H11V16Z"/>
                <path d="M9 8C9 6.89543 9.89543 6 11 6V18C9.89543 18 9 17.1046 9 16V8Z"/>
            </g>
            <defs>
                <clipPath id="clip0_10_3258">
                    <rect
                        fill="white"
                        height="24"
                        width="24"/>
                </clipPath>
            </defs>
        </SvgIcon>);
}