﻿import { Box, ListItemButton, Stack, SxProps } from "@mui/material";
import _ from "lodash";
import React, { MouseEvent, ReactNode, useEffect, useState } from "react";
import { CollapsedIcon, Expand, ExpandedIcon, Optional, PagedItems } from "@infrastructure";
import { useTheme } from "../../..";
import { TreeItem } from "..";

type ItemProps<T> = {
    expandOnTreeItemClick: boolean;
    getSx?: (treeItem: TreeItem<T>) => Optional<SxProps>;
    isExpanded: (treeItem: TreeItem<T>) => boolean;
    item: TreeItem<T>;
    level?: number;
    onClick?: (treeItem: TreeItem<T>) => void;
    render: (treeItem: TreeItem<T>) => ReactNode;
    searchMode: boolean;
};

export function Item<T>({ expandOnTreeItemClick, getSx, isExpanded, item, level = 0, onClick, render, searchMode }: ItemProps<T>) {
    const [expanded, setExpanded] = useState(() => isExpanded(item));
    useEffect(
        () => {
            setExpanded(
                searchMode
                    ? true
                    : isExpanded(item));
        },
        [searchMode]);

    const theme = useTheme();
    return (
        <Stack>
            <ListItemButton
                component="a"
                disableGutters={true}
                sx={{
                    borderRadius: theme.spacing(0.75),
                    color: theme.palette.text.secondary,
                    cursor: "default",
                    padding: theme.spacing(0, 1, 0, 0),
                    ...getSx?.(item)
                }}
                onClick={
                    (event: MouseEvent) => {
                        event.preventDefault();
                        event.stopPropagation();
                    }}>
                <Stack
                    alignItems="center"
                    direction="row"
                    sx={{
                        paddingLeft: theme.spacing(level * 2.75 + 1),
                        width: "100%"
                    }}>
                    <Box
                        sx={{
                            cursor: "pointer",
                            fontSize: "18px",
                            width: "20px"
                        }}
                        onClick={
                            () => {
                                if (!_.isEmpty(item.items)) {
                                    setExpanded(expanded => !expanded);
                                }
                            }}>
                        {_.isEmpty(item.items)
                            ? undefined
                            : expanded
                                ? <ExpandedIcon/>
                                : <CollapsedIcon/>}
                    </Box>
                    <Box
                        sx={{
                            cursor: "pointer",
                            flex: 1,
                            overflow: "hidden",
                            width: "inherit"
                        }}
                        onClick={
                            () => {
                                if (expandOnTreeItemClick && !_.isEmpty(item.items)) {
                                    setExpanded(expanded => !expanded);
                                }

                                onClick?.(item);
                            }}>
                        {render(item)}
                    </Box>
                </Stack>
            </ListItemButton>
            {!_.isEmpty(item.items) && (
                <Expand expanded={expanded}>
                    <Stack>
                        <PagedItems
                            items={item.items!}
                            loaderSx={{ paddingLeft: theme.px((level + 2) * 21 + 5) }}>
                            {(item, index) =>
                                <Item<T>
                                    expandOnTreeItemClick={expandOnTreeItemClick}
                                    getSx={getSx}
                                    isExpanded={isExpanded}
                                    item={item}
                                    key={`${level}-${index}`}
                                    level={level + 1}
                                    render={render}
                                    searchMode={searchMode}
                                    onClick={onClick}/>}
                        </PagedItems>
                    </Stack>
                </Expand>)}
        </Stack>);
}