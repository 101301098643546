import React from "react";
import { useAwsCommonEncryptedResourceInfoElements, useAwsCommonRdsGlobalClusterResourceInfoElements, useAwsDefaultResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { useCustomEntityPropertyInfoItemElements } from "..";
import { Contract } from "../../../../../../../../../../common";
import { Info } from "../../../../components";

export function useAwsRdsNeptuneGlobalClusterDefinition(resourceModel: Contract.AwsResourceModel, options?: DefinitionOptions) {
    const commonEncryptedResourceInfoElements = useAwsCommonEncryptedResourceInfoElements(resourceModel);
    const commonRdsGlobalClusterResourceInfoElements =
        useAwsCommonRdsGlobalClusterResourceInfoElements(
            Contract.TypeNames.AwsRdsNeptuneCluster,
            resourceModel as Contract.AwsRdsNeptuneGlobalClusterModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useAwsDefaultResourceInfoItemElements(resourceModel);

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    commonRdsGlobalClusterResourceInfoElements.status,
                    commonRdsGlobalClusterResourceInfoElements.engineVersion,
                    commonRdsGlobalClusterResourceInfoElements.clusters,
                    ...commonEncryptedResourceInfoElements,
                    commonRdsGlobalClusterResourceInfoElements.deleteProtection
                ]}
                options={options?.infoOptions}>
            </Info>
    });
}