import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { useGetAzureBotServiceBotServiceRiskContext } from "../contexts";

export function useAzureBotServiceBotServiceSharedKeyAccessEnabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AzureBotServiceBotServiceSharedKeyAccessEnabledRisk;
    const botServiceModel = entityModelStore.useGet(risk.entityId) as Contract.AzureBotServiceBotServiceModel;
    const botService = botServiceModel.entity as Contract.AzureBotServiceBotService;

    const getAzureBotServiceBotServiceRiskContext = useGetAzureBotServiceBotServiceRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.compliance.useAzureBotServiceBotServiceSharedKeyAccessEnabledRiskDefinition",
            () => ({
                description: "{{botService}} has local authentication enable",
                sections: {
                    resolution: {
                        step1: "Disabling local authentication is not supported via the Azure console. Run the following command from Azure PowerShell: Update-AzBotService -ResourceGroupName {{resourceGroupName}} -Name {{botServiceName}} -DisableLocalAuth"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            botService:
                <Entity
                    entityIdOrModel={botServiceModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            localization.sections.resolution.step1({
                botServiceName: botService.name,
                resourceGroupName:
                    <Entity
                        entityIdOrModel={botService.resourceGroupId}
                        glanceOptions={{ disabled: true }}
                        linkOptions={{
                            disabled: true,
                            disabledHighlight: false
                        }}
                        variant="text"/>
            })
        ],
        riskModel,
        () => {
            const botServiceRiskContext = getAzureBotServiceBotServiceRiskContext(botServiceModel);
            return [
                botServiceRiskContext.generalInformation,
                botServiceRiskContext.sensitive,
                botServiceRiskContext.inboundExternalAccessScope,
                botServiceRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}