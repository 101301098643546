import { DirectedGraphModelColumn, UnexpectedError, useLocalization } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { NetworkDefinition } from "../..";
import { AwsElbClassicLoadBalancerListenersTable, AwsElbLoadBalancerListenersTable } from "../../../../../../../tenants";
import { Contract, EntityController } from "../../../../../../controllers";
import { ItemSelectionHelper } from "../../../../../../utilities";
import { GraphColumnId, ToolbarFilterId, ToolbarToggleFilterId } from "../../../../components";
import { AwsServiceSubnetIcon, AwsServiceSubnetsIcon } from "./components";
import { useAwsNetworkToolbarFilterIdTranslator, useAwsNetworkToolbarToggleFilterIdTranslator } from "./hooks";

export function useAwsDefinition() {
    const localization =
        useLocalization(
            "common.network.hooks.useDefinition.hooks.useAwsDefinition",
            () => ({
                columns: {
                    [GraphColumnId.DestinationGroups]: "Targets",
                    [GraphColumnId.Gateways]: "Via",
                    [GraphColumnId.InterfaceSecurityGroups]: "Security Groups",
                    [GraphColumnId.Sources]: "Sources"
                },
                listeners: "Listeners"
            }));

    return new NetworkDefinition(
        _.map(
            [
                GraphColumnId.Sources,
                GraphColumnId.Gateways,
                GraphColumnId.InterfaceSecurityGroups,
                GraphColumnId.DestinationGroups
            ],
            (columnId: Exclude<GraphColumnId, GraphColumnId.FirewallPolicyAndVpcs | GraphColumnId.SubnetSecurityGroups>) =>
                new DirectedGraphModelColumn(
                    columnId,
                    localization.columns[columnId]())),
        (entityId: string, filterMap: Dictionary<any>) =>
            EntityController.getNetworkGraph(
                new Contract.EntityControllerGetAwsNetworkGraphRequest(
                    entityId,
                    {
                        destinationScopeFilterIdSelection: ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[ToolbarFilterId.DestinationScopeFilterIds]),
                        securityGroupIdSelection: ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[ToolbarFilterId.InterfaceSecurityGroupIds]),
                        sourceEntityIdSelection: ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[ToolbarFilterId.SourceEntityIds]),
                        sourceSubnetSelection: ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[ToolbarFilterId.SourceSubnets]),
                        wideRangeSourceSubnet: filterMap[ToolbarToggleFilterId.WideRangeSourceSubnet] as boolean
                    })
            ),
        (specialSource: Contract.NetworkGraphSpecialSource) => {
            throw new UnexpectedError("specialSource", specialSource);
        },
        <AwsServiceSubnetIcon/>,
        <AwsServiceSubnetsIcon/>,
        useAwsNetworkToolbarFilterIdTranslator(),
        useAwsNetworkToolbarToggleFilterIdTranslator(),
        (gatewayResourceModel: Contract.EntityModel) => ({
            rules:
                gatewayResourceModel.entity.typeName === Contract.TypeNames.AwsElbClassicLoadBalancer
                    ? <AwsElbClassicLoadBalancerListenersTable
                        instanceIds={(gatewayResourceModel.entity as Contract.AwsElbClassicLoadBalancer).instanceIds}
                        loadBalancerListenerIds={(gatewayResourceModel as Contract.AwsElbLoadBalancerModel).listenerIds}/>
                    : <AwsElbLoadBalancerListenersTable
                        loadBalancerListenerIds={(gatewayResourceModel as Contract.AwsElbLoadBalancerModel).listenerIds}
                        targetGroupIds={(gatewayResourceModel as Contract.AwsElbLoadBalancerModel).targetGroupIds}/>,
            title: localization.listeners()
        }));
}