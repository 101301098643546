import { AnalyticsEventActionType, useLocalization, useRoute, useSetRoute, useTrackAnalyticsEvent } from "@infrastructure";
import _ from "lodash";
import React, { useEffect, useMemo } from "react";
import { Contract, CustomerConsoleAppUrlHelper, entityModelStore, RiskType, StorageHelper, TypeHelper, useLayoutOptions } from "../../../../common";
import { ProfileCategory } from "../Entities/components/Profile/hooks";
import { Items } from "./components";
import { useViewTranslator } from "./components/Items/hooks";
import { RiskDefinitionSectionCategory } from "./hooks/useDefinition/utilities";

type RisksProps = {
    relatedEntityId?: string;
};

export function Risks({ relatedEntityId }: RisksProps) {
    return _.isNil(relatedEntityId)
        ? <View/>
        : <EntityProfile relatedEntityId={relatedEntityId!}/>;
}

export type EntityProfileProps = {
    relatedEntityId: string;
};

function EntityProfile({ relatedEntityId }: EntityProfileProps) {
    const relatedEntityModel = entityModelStore.useGet(relatedEntityId);
    return (
        <Items
            baseUrl={CustomerConsoleAppUrlHelper.getEntityProfileRelativeUrl(relatedEntityModel, { category: ProfileCategory.Risks })!}
            filtersVisibilityStorageItem={StorageHelper.customerRisksEntityProfileFilters}
            relatedEntityModel={relatedEntityModel}
            riskType={RiskType.Cloud}
            variant="entityProfile"/>);
}

function View() {
    const localization =
        useLocalization(
            "views.customer.risks.view",
            () => ({
                title: "{{view}} Findings"
            }));
    useTrackAnalyticsEvent(AnalyticsEventActionType.PageView);

    const { match, viewOrId } = useRoute(`${CustomerConsoleAppUrlHelper.getRisksRelativeUrl()}/{viewOrId}`);
    const setRoute = useSetRoute();

    const viewTranslator = useViewTranslator();
    const risksView =
        useMemo(
            () =>
                TypeHelper.tryParseEnum<Contract.RisksView>(
                    Contract.TypeNames.RisksView,
                    _(Contract.RisksView).
                        values().
                        find(risksViewValue => risksViewValue.toLowerCase() === viewOrId?.toLowerCase())) ?? Contract.RisksView.Open,
            [viewOrId]);

    useLayoutOptions({ view:{ title: localization.title({ view: viewTranslator(risksView) }) } });

    useEffect(
        () => {
            if (viewOrId &&
                !_(Contract.RisksView).
                    values().
                    includes(viewOrId as Contract.RisksView) &&
                match) {
                setRoute(
                    `${CustomerConsoleAppUrlHelper.getRisksRelativeUrl()}${CustomerConsoleAppUrlHelper.getRiskProfileHashUrl(viewOrId, { category: RiskDefinitionSectionCategory.Overview })}`,
                    undefined,
                    { appendBrowserHistory: false });
            }
        },
        [viewOrId]);

    return (
        <Items
            baseUrl={CustomerConsoleAppUrlHelper.getRisksRelativeUrl()}
            filtersVisibilityStorageItem={StorageHelper.customerRisksViewFilters}
            riskType={RiskType.Cloud}
            variant="view"/>);
}