import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useTheme } from "../../../../../../../../../../../../common";

type CustomReferenceLineLabelProps = {
    color: string;
    value: string;
    viewBox?: CustomReferenceLineLabelViewBox;
};

type CustomReferenceLineLabelViewBox = {
    height: number;
    width: number;
    x: number;
    y: number;
};

export function CustomReferenceLineLabel({ color, value, viewBox }: CustomReferenceLineLabelProps) {
    const textRef = useRef<SVGTextElement>(null);
    const [textWidth, setTestWidth] = useState(0);

    useEffect(
        () => {
            if (textRef.current) {
                setTestWidth(textRef.current.getBBox().width);
            }
        },
        [value, textRef.current]);
    
    const theme = useTheme();
    return (
        _.isNil(viewBox) 
            ? null 
            : <g>
                <rect
                    fill={theme.palette.background.paper}
                    height="20"
                    rx="5"
                    ry="5"
                    width={textWidth}
                    x={viewBox.width + 36 - textWidth}
                    y={viewBox.y - 10}/>
                <text
                    fill={color}
                    fontSize="11"
                    ref={textRef}
                    textAnchor="end"
                    x={viewBox.width + 36}
                    y={viewBox.y + 4}>
                    {value}
                </text>
            </g>);
}