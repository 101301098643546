import _ from "lodash";
import { useEffect, useState } from "react";
import { ApiError, InlineItems, Optional, useExecuteOperation, useLocalization, useOrderedWizardContext } from "@infrastructure";
import { ConfigurationController, Contract } from "../../../../../../../../../../../../../../../common";
import { useAzureTenantManagerErrorTranslator } from "../../../../../../../../../../../../../../../tenants";
import { OrganizationMemberSelection } from "../../../../../../../components";
import { OrganizationMemberSelectionHelper } from "../../../../../../../utilities";
import { useAddOrEditContext, useSetAddOrEditContext } from "../AddOrEdit";

export function OrganizationMemberSelectionItem() {
    const addOrEditContext = useAddOrEditContext();
    const setAddOrEditContext = useSetAddOrEditContext();

    const { setError, setLoaded, setValid, useNextEffect } = useOrderedWizardContext();

    const [scopeNodeRootTreeItem] =
        useExecuteOperation(
            OrganizationMemberSelectionItem,
            async () => {
                const { node } = await ConfigurationController.getOrganizationMemberDatas(new Contract.ConfigurationControllerGetAzureOrganizationMemberDatasRequest(addOrEditContext.aadTenantModel!.configuration.id));
                return OrganizationMemberSelectionHelper.createTreeItem(node);
            });

    const [selectionType, setSelectionType] = useState(addOrEditContext.memberSelection?.type);
    const [selectedTenantIds, setSelectedTenantIds] =
        useState(
            () =>
                OrganizationMemberSelectionHelper.getSelectedTenantIds(
                    scopeNodeRootTreeItem.value,
                    addOrEditContext.memberSelection?.rawIds));

    useEffect(setLoaded, []);

    const tenantManagerErrorTranslator = useAzureTenantManagerErrorTranslator();

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCloudProviderTenantOrganizationItems.azure.addOrEdit.organizationMemberSelectionItem",
            () => ({
                actions: {
                    save: {
                        error: {
                            add: "Failed to add organization",
                            edit: "Failed to save organization"
                        },
                        notValid: {
                            servicePrincipalMissingAssignedRoleDefinitionNames: [
                                "1 mandatory role assignment",
                                "{{count | NumberFormatter.humanize}} mandatory role assignments"
                            ],
                            text: "Missing {{servicePrincipalMissingAssignedRoleDefinitionNames}}"
                        }
                    }
                },
                errors: {
                    empty: "Empty selection is not allowed",
                    excludeAll: "Excluding all {{name}} is not allowed"
                }
            }));

    useNextEffect(
        async () => {
            if (!_.isNil(selectionType) && _.isEmpty(selectedTenantIds)) {
                return localization.errors.empty();
            }

            const newMemberSelection: Optional<Contract.OrganizationMemberSelection> =
                _.isNil(selectionType)
                    ? undefined
                    : {
                        rawIds:
                            OrganizationMemberSelectionHelper.getSelectedScopeIds(
                                scopeNodeRootTreeItem.value,
                                selectedTenantIds),
                        type: selectionType
                    };

            try {
                const { organizationMemberTenantNames, servicePrincipalAssignedRoleDefinitionNames, servicePrincipalMissingAssignedRoleDefinitionNames, valid } =
                    await ConfigurationController.upsertAzureOrganization(
                        new Contract.ConfigurationControllerUpsertAzureOrganizationRequest(
                            addOrEditContext.aadTenantModel!.configuration.id,
                            _.isNil(addOrEditContext.organizationConfiguration) || addOrEditContext.enabled!,
                            addOrEditContext.folderEnabled!,
                            newMemberSelection,
                            addOrEditContext.name!,
                            addOrEditContext.permissionTypes!));

                if (!valid) {
                    return localization.actions.save.notValid.text({
                        servicePrincipalMissingAssignedRoleDefinitionNames:
                            <InlineItems
                                items={servicePrincipalMissingAssignedRoleDefinitionNames}
                                namePluralizer={localization.actions.save.notValid.servicePrincipalMissingAssignedRoleDefinitionNames}
                                sx={{ color: "unset" }}
                                variant="itemCountAndType"/>
                    });
                }

                setAddOrEditContext(
                    addOrEditContext => ({
                        ...addOrEditContext,
                        organizationMemberTenantNames,
                        servicePrincipalAssignedRoleDefinitionNames,
                        servicePrincipalMissingAssignedRoleDefinitionNames
                    }));
            } catch (error) {
                return error instanceof ApiError && error.statusCode === 400
                    ? tenantManagerErrorTranslator(error.error as Contract.AzureTenantManagerError)
                    : localization.actions.save.error[
                        _.isNil(addOrEditContext.organizationConfiguration)
                            ? "add"
                            : "edit"]();
            }
        },
        [selectedTenantIds, selectionType]);

    return (
        <OrganizationMemberSelection
            scopeNodeRootTreeItem={scopeNodeRootTreeItem}
            selectedTenantIds={selectedTenantIds}
            selectionType={selectionType}
            tenantType={Contract.TenantType.Azure}
            onError={
                error => {
                    setValid(_.isNil(error));
                    setError(error);
                }}
            onSelectedTenantIdsChanged={setSelectedTenantIds}
            onSelectionTypeChanged={setSelectionType}/>);
}