import { InlineCopyToClipboardText, Step, Steps, useLocalization } from "@infrastructure";
import { useTheme } from "@mui/material";
import _ from "lodash";
import React, { useState } from "react";
import { useAddOrEditContext } from "../../..";
import { Contract, TenantRoleItemAccordion } from "../../../../../../../../../../../../../common";

export function Manual() {
    const dataAnalysisCustomRoleId = "tenable_data_scanning";
    const dataAnalysisCustomRoleName = "Tenable Data Scanning";
    const workloadAnalysisCustomRoleName = "Tenable Workload Scanning";

    const { gciTenantModel, organizationRawShortId, permissionTypes, rawShortNameId, tenantModel, tenantOnboardingInfo, tenantTerraformStateBucketEnabled } = useAddOrEditContext();

    const localization =
        useLocalization(
            "views.customer.scopes.hooks.useDefinition.hooks.useGcpDefinition.addOrEdit.tenantRolesItem.manual",
            () => ({
                dataAnalysisCustomRole: {
                    steps: {
                        step1: "Log into your **Google Cloud Console** and select the organization **{{organizationRawShortId}}** using the resource selector at the top left of the screen.",
                        step2: "Navigate to **IAM & Admin -> Roles**.",
                        step3: "Click on **Create** role.",
                        step4: "Change the title to {{dataAnalysisCustomRoleName}}.",
                        step5: "Change the ID to {{dataAnalysisCustomRoleId}}.",
                        step6: "Click **+ ADD PERMISSIONS**.",
                        step7: "In the Filter field, search and check the box next to permission name for the following permissions",
                        step8: "Click **ADD**, and then click **CREATE**."
                    },
                    text: "To create a custom role for Data Analysis, follow these steps:"
                },
                steps: {
                    step1: "Navigate to **IAM & Admin -> IAM**.",
                    step2: "Click **+ Grant Access.**",
                    step3: "Copy the following value and paste it in the **New principals** field: {{serviceAccountMail}}",
                    step4: "Copy the following roles and paste them into the **Role** field, one by one. Click **+ADD ANOTHER ROLE** for each additional role.",
                    step5: "Click **Save**",
                    step6: "Navigate to **Cloud Storage -> Buckets**.",
                    step7: "Check the boxes next to the names of the following buckets: {{terraformStateBucketNames}}",
                    step8: "In the **buckets selected** bar on the top, click **PERMISSIONS**.",
                    step9: "In the side view on the right, click **ADD PRINCIPAL**.",
                    step10: "Copy the following value and paste it in the **New principals** field: {{serviceAccountMail}}",
                    step11: "Copy the following roles and paste them into the **Role** field, one by one. Click **+ADD ANOTHER ROLE** for each additional role.",
                    step12: "Click **Save**"
                },
                withCustomRole: "Select the project **{{rawShortNameId}}** you would like to onboard using the project picker at the top left of the screen.",
                withoutCustomRole: "Log into your **Google Cloud Console** and select the **project** you would like to onboard using the project picker at the top left of the screen.",
                workloadAnalysisCustomRole: {
                    steps: {
                        step1: "Log into your **Google Cloud Console** and select the organization **{{organizationRawShortId}}** using the resource selector at the top left of the screen.",
                        step2: "Navigate to **IAM & Admin -> Roles**.",
                        step3: "In the filter field, search for and select {{backupComputeEngineOperatorRole}}.",
                        step4: "Click the three dots next to the role name and then **CREATE ROLE FROM THIS ROLE**.",
                        step5: "Change the title to {{workloadAnalysisCustomRoleName}}.",
                        step6: "Click **+ ADD PERMISSIONS**.",
                        step7: "In the Filter field, search for and select: **compute.snapshots.setIamPolicy**.",
                        step8: "Check the box next to the permission name, and click **ADD**.",
                        step9: "Under assigned permissions, search for the following permission: **iam.serviceAccounts.actAs**.",
                        step10: "Clear the checkbox to **remove** the permission, and then click **CREATE**."
                    },
                    text: "To create a custom role for Workload Protection, follow these steps:"
                }
            }));

    const theme = useTheme();
    const [permissionState, setPermissionState] = useState<boolean>(false);

    return (
        <Steps variant="letters">
            {_<any>([]).
                concatIf(
                    _.includes(permissionTypes, Contract.CloudProviderTenantPermissionType.DataAnalysis),
                    new Step(
                        localization.dataAnalysisCustomRole.text(),
                        {
                            contentElement:
                                <Steps variant="plainNumbers">
                                    {localization.dataAnalysisCustomRole.steps.step1({ organizationRawShortId })}
                                    {localization.dataAnalysisCustomRole.steps.step2()}
                                    {localization.dataAnalysisCustomRole.steps.step3()}
                                    {localization.dataAnalysisCustomRole.steps.step4({
                                        dataAnalysisCustomRoleName:
                                            <InlineCopyToClipboardText text={dataAnalysisCustomRoleName}/>
                                    })}
                                    {localization.dataAnalysisCustomRole.steps.step5({
                                        dataAnalysisCustomRoleId:
                                            <InlineCopyToClipboardText text={dataAnalysisCustomRoleId}/>
                                    })}
                                    {localization.dataAnalysisCustomRole.steps.step6()}
                                    {new Step(
                                        localization.dataAnalysisCustomRole.steps.step7(),
                                        {
                                            contentElement:
                                                <TenantRoleItemAccordion
                                                    expanded={permissionState}
                                                    rawText={tenantOnboardingInfo.serviceAccountDataAnalysisPermissionActions.join("\n")}
                                                    title="Tenable Data Scanning Permissions"
                                                    tooltip="Tenable Data Scanning Permissions"
                                                    onChange={ expanded => setPermissionState(expanded)}/>
                                        })}
                                    {localization.dataAnalysisCustomRole.steps.step8()}
                                </Steps>
                        })).
                concatIf(
                    _.includes(permissionTypes, Contract.CloudProviderTenantPermissionType.WorkloadAnalysis),
                    new Step(
                        localization.workloadAnalysisCustomRole.text(),
                        {
                            contentElement:
                                <Steps variant="plainNumbers">
                                    {localization.workloadAnalysisCustomRole.steps.step1({ organizationRawShortId })}
                                    {localization.workloadAnalysisCustomRole.steps.step2()}
                                    {localization.workloadAnalysisCustomRole.steps.step3({
                                        backupComputeEngineOperatorRole:
                                            <InlineCopyToClipboardText text="Backup and DR Compute Engine Operator"/>
                                    })}
                                    {localization.workloadAnalysisCustomRole.steps.step4()}
                                    {localization.workloadAnalysisCustomRole.steps.step5({
                                        workloadAnalysisCustomRoleName:
                                            <InlineCopyToClipboardText text={workloadAnalysisCustomRoleName}/>
                                    })}
                                    {localization.workloadAnalysisCustomRole.steps.step6()}
                                    {localization.workloadAnalysisCustomRole.steps.step7()}
                                    {localization.workloadAnalysisCustomRole.steps.step8()}
                                    {localization.workloadAnalysisCustomRole.steps.step9()}
                                    {localization.workloadAnalysisCustomRole.steps.step10()}
                                </Steps>
                        })).
                concatIf(
                    _.includes(permissionTypes, Contract.CloudProviderTenantPermissionType.DataAnalysis) ||
                    _.includes(permissionTypes, Contract.CloudProviderTenantPermissionType.WorkloadAnalysis),
                    localization.withCustomRole({ rawShortNameId })).
                concatIf(
                    !_.includes(permissionTypes, Contract.CloudProviderTenantPermissionType.DataAnalysis) &&
                    !_.includes(permissionTypes, Contract.CloudProviderTenantPermissionType.WorkloadAnalysis),
                    localization.withoutCustomRole()).
                concat(
                    localization.steps.step1(),
                    localization.steps.step2(),
                    localization.steps.step3({
                        serviceAccountMail:
                            <InlineCopyToClipboardText text={gciTenantModel!.configuration.serviceAccountMail}/>
                    }),
                    new Step(
                        localization.steps.step4(),
                        {
                            contentElement:
                                <Steps variant="plainNumbers">
                                    {_<string>([]).
                                        concat(
                                            _.values(tenantOnboardingInfo.serviceAccountReadOnlyRoleRawIdToNameMap)).
                                        concatIf(
                                            _.includes(permissionTypes, Contract.CloudProviderTenantPermissionType.PermissionManagement),
                                            _.values(tenantOnboardingInfo.serviceAccountPermissionManagementRoleRawIdToNameMap)).
                                        concatIf(
                                            _.includes(permissionTypes, Contract.CloudProviderTenantPermissionType.DataAnalysis),
                                            dataAnalysisCustomRoleName)
                                        .concatIf(
                                            _.includes(permissionTypes, Contract.CloudProviderTenantPermissionType.WorkloadAnalysis),
                                            workloadAnalysisCustomRoleName).
                                        concatIf(
                                            _.includes(permissionTypes, Contract.CloudProviderTenantPermissionType.Write),
                                            _.values(tenantOnboardingInfo.serviceAccountManagementRoleRawIdToNameMap)).
                                        orderBy().
                                        uniq().
                                        value()}
                                </Steps>
                        }),
                    localization.steps.step5(),
                    ...tenantTerraformStateBucketEnabled
                        ? [
                            localization.steps.step6(),
                            localization.steps.step7({
                                terraformStateBucketNames:
                                    <Steps sx={{ paddingLeft: theme.spacing(2) }}>
                                        {tenantModel!.state.codeAnalysis.terraformStateBucketNames}
                                    </Steps>
                            }),
                            localization.steps.step8(),
                            localization.steps.step9(),
                            localization.steps.step10({
                                serviceAccountMail:
                                    <InlineCopyToClipboardText text={gciTenantModel!.configuration.serviceAccountMail}/>
                            }),
                            new Step(
                                localization.steps.step11(),
                                {
                                    contentElement:
                                        <Steps variant="plainNumbers">
                                            {_.values(tenantOnboardingInfo.serviceAccountReadOnlyTerraformStateBucketRoleRawIdToNameMap)}
                                        </Steps>
                                }),
                            localization.steps.step12()]
                        : []).
                value()}
        </Steps>);
}