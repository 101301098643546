﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, Entity, useEntityTypeNameTranslator } from "../../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../../utilities";
import { useEntityRiskContext } from "../../../../useEntityRiskContext";
import { useGetKubernetesClusterRiskContext } from "../../../../useGetKubernetesClusterRiskContext";

export function useUnmanagedKubernetesClusterRiskContext(entityModel: Contract.EntityModel) {
    const unmanagedKubernetesCluster = _.as<Contract.IUnmanagedKubernetesCluster>(entityModel.entity);
    const entityRiskContext = useEntityRiskContext(entityModel);
    const kubernetesClusterRiskContext = useGetKubernetesClusterRiskContext()(_.as<Contract.IUnmanagedKubernetesClusterModel>(entityModel));

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.kubernetes.hooks.clusters.contexts.useUnmanagedKubernetesClusterRiskContext",
            () => ({
                generalInformation: "*capitalize*{{resourceTypeName}}** {{resource}} was created on {{creationTime | TimeFormatter.shortDate}}"
            }));
    return {
        ...entityRiskContext,
        ...kubernetesClusterRiskContext,
        generalInformation:
            new RiskDefinitionContextItem(
                localization.generalInformation({
                    creationTime: unmanagedKubernetesCluster.data.creationTime,
                    resource:
                        <Entity
                            entityIdOrModel={entityModel}
                            variant="text"/>,
                    resourceTypeName:
                        entityTypeNameTranslator(
                            entityModel.entity.typeName,
                            {
                                includeServiceName: false,
                                variant: "text"
                            })
                })),
        virtualNetwork: undefined
    };
}