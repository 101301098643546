import { ApiError, AutocompleteItems, FormLayout, InfoIcon, ItemSelector, MailIcon, MailParser, Message, TimeFormatter, useChangeEffect, useInputValidation, useLocalization } from "@infrastructure";
import { Group as GroupIcon, Person as UserIcon } from "@mui/icons-material";
import { Button, CircularProgress, FormControl, FormHelperText, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import _ from "lodash";
import moment from "moment";
import React, { useState } from "react";
import { useSetSupportPrincipalsContext, useSupportPrincipalsContext } from "..";
import { AdministrationController, Contract, useIdentityRoleTranslator, useTheme } from "../../../../../../../common";

export function AddOrEditPrincipal() {
    const { addOrEditPrincipalOpen, executeGetSupportPrincipals, supportPrincipalDatas } = useSupportPrincipalsContext();
    const setSupportPrincipalsContext = useSetSupportPrincipalsContext();

    const supportPrincipalData =
        addOrEditPrincipalOpen === "group" || addOrEditPrincipalOpen === "user"
            ? undefined
            : addOrEditPrincipalOpen!;
    const group =
        addOrEditPrincipalOpen === "group" ||
        supportPrincipalData?.group === true;

    const [expirationTimeHours, setExpirationTimeHours] = useState<number>();
    const [identifier, setIdentifier] = useState(supportPrincipalData?.identifier ?? "");
    const [role, setRole] = useState(supportPrincipalData?.role);

    const [setSupportPrincipalExecuting, setSetSupportPrincipalExecuting] = useState(false);
    const [setSupportPrincipalError, setSetSupportPrincipalError] = useState<Contract.AdministrationControllerUpsertSupportPrincipalError | true>();

    async function setSupportPrincipal() {
        setSetSupportPrincipalExecuting(true);
        setSetSupportPrincipalError(undefined);

        try {
            await AdministrationController.upsertSupportPrincipal(
                new Contract.AdministrationControllerUpsertSupportPrincipalRequest(
                    expirationTimeHours === 0
                        ? undefined
                        : TimeFormatter.date(
                            moment().
                                add(
                                    expirationTimeHours,
                                    "hours")),
                    group,
                    identifier.trim(),
                    role!));
            await executeGetSupportPrincipals();
            setSupportPrincipalsContext(
                supportPrincipalsContext => ({
                    ...supportPrincipalsContext,
                    addOrEditPrincipalOpen: undefined
                }));
        } catch (error) {
            setSetSupportPrincipalError(
                error instanceof ApiError && error.statusCode === 400
                    ? error.error as Contract.AdministrationControllerUpsertSupportPrincipalError
                    : true);
        }

        setSetSupportPrincipalExecuting(false);
    }

    const identityRoleTranslator = useIdentityRoleTranslator();
    const localization =
        useLocalization(
            "views.customer.administration.supportPrincipals.addOrEditPrincipal",
            () => ({
                actions: {
                    save: {
                        add: {
                            group: {
                                error: "Failed to add the group",
                                title: "Add Group"
                            },
                            user: {
                                error: "Failed to add the user",
                                title: "Add User"
                            }
                        },
                        edit: {
                            error: "Failed to save",
                            title: "Save"
                        },
                        error: {
                            [Contract.TypeNames.AdministrationControllerUpsertSupportPrincipalError]: {
                                [Contract.AdministrationControllerUpsertSupportPrincipalError.UserMailDomainInvalid]: "The domain must be the system root domain name"
                            }
                        }
                    }
                },
                fields: {
                    expirationTime: {
                        never: "Never",
                        title: "Expiration time"
                    },
                    identifier: {
                        group: {
                            error: {
                                exists: "Group already exists",
                                required: "Group name cannot be empty"
                            },
                            title: "Group name"
                        },
                        user: {
                            error: {
                                domain: "Only Tenable emails are allowed",
                                exists: "Mail already exists",
                                format: "Must be a valid mail address",
                                required: "Mail address cannot be empty"
                            },
                            title: "User email address"
                        }
                    },
                    role: "Role"
                },
                title: {
                    group: {
                        add: "Add Group",
                        edit: "Edit Group"
                    },
                    user: {
                        add: "Add User",
                        edit: "Edit User"
                    }
                }
            }

            ));

    const [identifierValidationController, identifierValidationMessage] =
        useInputValidation(
            () => {
                const validationIdentifier = identifier.trim();
                if (_.isEmpty(validationIdentifier)) {
                    return group
                        ? localization.fields.identifier.group.error.required()
                        : localization.fields.identifier.user.error.required();
                }

                if (!group &&
                    !MailParser.validate(validationIdentifier)) {
                    return localization.fields.identifier.user.error.format();
                }

                if (_.some(
                    supportPrincipalDatas,
                    supportPrincipalData =>
                        !supportPrincipalData.application &&
                        supportPrincipalData.identifier === validationIdentifier)) {
                    return group
                        ? localization.fields.identifier.group.error.exists()
                        : localization.fields.identifier.user.error.exists();
                }

                if (!group &&
                    !validationIdentifier.endsWith("@tenable.com")) {
                    return localization.fields.identifier.user.error.domain();
                }

                return undefined;
            },
            [identifier]);

    const [valid, setValid] = useState(!_.isNil(expirationTimeHours) && !_.isNil(supportPrincipalData));
    useChangeEffect(
        () => {
            setValid(
                !_.isNil(expirationTimeHours) &&
                (
                    !_.isNil(supportPrincipalData) ||
                    identifierValidationController.isValid()) &&
                !_.isNil(role));
        },
        [expirationTimeHours, identifier, role]);

    const theme = useTheme();
    return (
        <FormLayout
            footerOptions={{
                contentElement:
                    <Stack
                        alignItems="center"
                        direction="row"
                        justifyContent="flex-end"
                        spacing={1}>
                        {setSupportPrincipalExecuting &&
                            <CircularProgress
                                size={theme.spacing(2)}
                                variant="indeterminate"/>}
                        {!_.isNil(setSupportPrincipalError) &&
                            <Message
                                level="error"
                                title={
                                    setSupportPrincipalError === true
                                        ? _.isNil(supportPrincipalData)
                                            ? group
                                                ? localization.actions.save.add.group.error()
                                                : localization.actions.save.add.user.error()
                                            : localization.actions.save.edit.error()
                                        : localization.actions.save.error[Contract.TypeNames.AdministrationControllerUpsertSupportPrincipalError][setSupportPrincipalError]()}/>}
                        <Button
                            disabled={!valid || setSupportPrincipalExecuting}
                            onClick={setSupportPrincipal}>
                            {_.isNil(supportPrincipalData)
                                ? group
                                    ? localization.actions.save.add.group.title()
                                    : localization.actions.save.add.user.title()
                                : localization.actions.save.edit.title()}
                        </Button>
                    </Stack>
            }}
            titleOptions={{
                text:
                    _.isNil(supportPrincipalData)
                        ? group
                            ? localization.title.group.add()
                            : localization.title.user.add()
                        : group
                            ? localization.title.group.edit()
                            : localization.title.user.edit()
            }}>
            <Stack
                spacing={5}
                sx={{ width: "100%" }}>
                <Stack spacing={2}>
                    <FormControl
                        fullWidth={true}
                        variant="standard">
                        <AutocompleteItems
                            autoSelect={true}
                            disableClearable={true}
                            disabled={setSupportPrincipalExecuting || !_.isNil(supportPrincipalData)}
                            freeSolo={true}
                            fullWidth={true}
                            options={
                                _(supportPrincipalDatas).
                                    filter(supportPrincipalData => supportPrincipalData.group === group).
                                    map(supportPrincipalData => supportPrincipalData.identifier).
                                    value()}
                            renderInput={
                                params => (
                                    <TextField
                                        {...params}
                                        placeholder={
                                            group
                                                ? localization.fields.identifier.group.title()
                                                : localization.fields.identifier.user.title()}
                                        slotProps={{
                                            input: {
                                                ...params.InputProps,
                                                startAdornment:
                                                    <InputAdornment
                                                        position="start"
                                                        sx={{ fontSize: "18px" }}>
                                                        {group
                                                            ? <InfoIcon
                                                                sx={{
                                                                    color: theme.palette.text.secondary,
                                                                    fontSize: "24px"
                                                                }}/>
                                                            : <MailIcon/>}
                                                    </InputAdornment>
                                            }
                                        }}
                                        variant="outlined"/>)}
                            value={identifier}
                            onChange={(_event, identifier) => setIdentifier(identifier)}>
                            {identifier =>
                                <Stack
                                    alignItems="center"
                                    direction="row"
                                    spacing={1}>
                                    {group
                                        ? <GroupIcon
                                            sx={{
                                                color: theme.palette.text.primary,
                                                fontSize: "18px"
                                            }}/>
                                        : <UserIcon
                                            sx={{
                                                color: theme.palette.text.primary,
                                                fontSize: "18px"
                                            }}/>}
                                    {identifier}
                                </Stack>}
                        </AutocompleteItems>
                        {!_.isNil(identifierValidationMessage) && (
                            <FormHelperText error={true}>{identifierValidationMessage}</FormHelperText>)}
                    </FormControl>
                    <ItemSelector
                        disabled={setSupportPrincipalExecuting}
                        fullWidth={true}
                        items={[
                            Contract.IdentityRole.Administrator,
                            Contract.IdentityRole.AdministratorViewer,
                            Contract.IdentityRole.Collaborator,
                            Contract.IdentityRole.Viewer
                        ]}
                        placeholder={localization.fields.role()}
                        selectedItem={role}
                        sorted={false}
                        onSelectedItemChanged={setRole}>
                        {identityRoleTranslator}
                    </ItemSelector>
                    <ItemSelector
                        disabled={setSupportPrincipalExecuting}
                        fullWidth={true}
                        items={
                            [
                                4,
                                8,
                                12,
                                0
                            ]}
                        placeholder={localization.fields.expirationTime.title()}
                        selectedItem={expirationTimeHours}
                        sorted={false}
                        onSelectedItemChanged={setExpirationTimeHours}>
                        {(hours: number) =>
                            <Typography
                                noWrap={true}
                                sx={{ width: "100%" }}>
                                {hours === 0
                                    ? "Never"
                                    : TimeFormatter.humanizeDuration(moment().
                                        add(hours, "hours"))}
                            </Typography>}
                    </ItemSelector>
                </Stack>
            </Stack>
        </FormLayout>);
}