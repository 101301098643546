import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract } from "../../../../../../..";
import { Entity, InlineEntities } from "../../../../../..";

export function useAwsDeletePermissionSetAccountAssignmentsChangeDefinition(change: Contract.AwsChange, entityLinkDisabled?: boolean) {
    const deletePermissionSetAccountAssignmentsChange = change as Contract.AwsDeletePermissionSetAccountAssignmentsChange;
    const localization =
        useLocalization(
            "common.change.hooks.useDefinition.hooks.useAwsDefinition.hooks.useAwsDeletePermissionSetAccountAssignmentsChangeDefinition",
            () => ({
                title: "Unassign {{permissionSet}} permission set from the unused {{accountIds}}"
            }));
    return {
        title:
            localization.title({
                accountIds:
                    <InlineEntities
                        entityIdsOrModels={deletePermissionSetAccountAssignmentsChange.accountIds}
                        entityTypeName={Contract.TypeNames.AwsOrganizationsAccount}
                        variant="itemAndTypeOrItemCountAndType"/>,
                permissionSet:
                    <Entity
                        entityIdOrModel={deletePermissionSetAccountAssignmentsChange.resourceId}
                        glanceOptions={{ disabled: true }}
                        linkOptions={{ disabled: entityLinkDisabled }}
                        variant="text"/>
            })
    };
}