﻿import { Step, Steps, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition, useGetAwsCloudFrontDistributionRiskContext } from "../../..";
import { Contract, Entity, entityModelStore, useTheme } from "../../../../../../../../../../../../common";
import { AwsCloudFrontDistributionBehaviorsTable, AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { RiskDefinitionSection } from "../../../../../../utilities";

export function useAwsCloudFrontDistributionHttpsOnlyDisabledRiskDefinition(riskModel: Contract.RiskModel) {
    const distributionHttpsOnlyDisabledRiskModel = riskModel as Contract.AwsCloudFrontDistributionHttpsOnlyDisabledRiskModel;
    const distributionModel = entityModelStore.useGet(distributionHttpsOnlyDisabledRiskModel.risk.entityId) as Contract.AwsCloudFrontDistributionModel;
    const distribution = distributionModel.entity as Contract.AwsCloudFrontDistribution;

    const getAwsCloudFrontDistributionRiskContext = useGetAwsCloudFrontDistributionRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsCloudFrontDistributionHttpsOnlyDisabledRiskDefinition",
            () => ({
                description: "{{distribution}} is not enforcing transport encryption with viewers",
                sections: {
                    cacheBehaviors: "Behaviors",
                    resolution: {
                        step1: "Navigate to the **Behaviors** tab",
                        step2: {
                            step1: "Select the behavior that you want to update, and then click **Edit**",
                            step2: "Navigate to **Viewer protocol policy** (Viewer section) and select one of the following values **Redirect HTTP** to **HTTPS** or **HTTPS Only**",
                            step3: "Click **Save changes**",
                            title: "Repeat the following steps for each of the behaviors"
                        }
                    }
                }
            }));

    const theme = useTheme();
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            distribution:
                <Entity
                    entityIdOrModel={distributionModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.CloudFront,
                AwsConsoleUrlBuilder.getCloudFrontDistributionUrl(distribution)),
            localization.sections.resolution.step1(),
            new Step(
                localization.sections.resolution.step2.title(),
                {
                    contentElement:
                        <Steps variant="plainNumbers">
                            {localization.sections.resolution.step2.step1()}
                            {localization.sections.resolution.step2.step2()}
                            {localization.sections.resolution.step2.step3()}
                        </Steps>
                })
        ],
        distributionHttpsOnlyDisabledRiskModel,
        () => {
            const distributionRiskContext = getAwsCloudFrontDistributionRiskContext(distributionModel);
            return [
                distributionRiskContext.generalInformation,
                distributionRiskContext.status,
                distributionRiskContext.originResources,
                distributionRiskContext.sensitive,
                distributionRiskContext.sensitiveOrigin,
                distributionRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        },
        {
            sections: [
                new RiskDefinitionSection(
                    <AwsCloudFrontDistributionBehaviorsTable
                        behaviors={distribution.behaviors}
                        getHighlightColor={
                            (behavior: Contract.AwsCloudFrontDistributionBehavior, opacity) =>
                                _.includes(distributionHttpsOnlyDisabledRiskModel.risk.nonHttpsOnlyBehaviorPrecedences, behavior.precedence)
                                    ? opacity
                                        ? theme.palette.opacity(theme.palette.severity(riskModel.risk.severity), opacity)
                                        : theme.palette.severity(riskModel.risk.severity)
                                    : undefined}
                        originIdToResourceIdReferenceMap={distributionModel.originIdToResourceIdReferenceMap}/>,
                    localization.sections.cacheBehaviors(),
                    {
                        expandable: true
                    })
            ]
        });

}