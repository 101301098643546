import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { EntityExternalConsoleLink } from "../../components";
import { useGetAzureMachineLearningWorkspaceRiskContext } from "../contexts";

export function useAzureMachineLearningWorkspaceDiagnosticSettingsLogCategoryNotEnabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AzureMachineLearningWorkspaceDiagnosticSettingsLogCategoryNotEnabledRisk;
    const workspaceModel = entityModelStore.useGet(risk.entityId) as Contract.AzureMachineLearningWorkspaceModel;

    const getAzureMachineLearningWorkspaceRiskContext = useGetAzureMachineLearningWorkspaceRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.compliance.useAzureMachineLearningWorkspaceDiagnosticSettingsLogCategoryNotEnabledRiskDefinition",
            () => ({
                description: "{{workspace}} logging is not enabled",
                sections: {
                    resolution: {
                        step1: "Click **Add diagnostic setting**",
                        step2: "Fill in the **Diagnostic setting name**",
                        step3: "Under the **Category groups** section, select at least one log type",
                        step4: "Select the **Destination details**",
                        step5: "Click **Save**"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            workspace:
                <Entity
                    entityIdOrModel={workspaceModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={risk.entityId}
                key={risk.entityId}
                page={Contract.AzureConsoleEntityPage.Diagnostics}/>,
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4(),
            localization.sections.resolution.step5()
        ],
        riskModel,
        () => {
            const workspaceRiskContext = getAzureMachineLearningWorkspaceRiskContext(workspaceModel);
            return [
                workspaceRiskContext.generalInformation,
                workspaceRiskContext.sensitive,
                workspaceRiskContext.highBusinessImpact,
                workspaceRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}