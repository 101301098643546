import { AnalyticsEventActionType, Dialog, EventHandlerRegister, InlineItems, makeContextProvider, Message, useEvent, useLocalization, useTrackAnalyticsEvent, VerticalFillGrid } from "@infrastructure";
import { Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, ScopeHelper, ScopeNavigationView, scopeNodeModelStore, scopeSystemEntityModelStore, TenantHelper, tenantModelStore, useLayoutOptions, UserConsoleAppUrlHelper, UserHelper } from "../../../../common";
import { AddOrEdit, Table } from "./components";

class PermissionEligibilitiesContext {
    constructor(
        public addOrEditOpen: boolean | Contract.PermissionEligibilityModel,
        public editMode: boolean,
        public registerPermissionEligibilityChange: EventHandlerRegister<() => Promise<void>>,
        public triggerPermissionEligibilityChange: () => Promise<void>) {
    }
}

export const [useEligibilitiesContext, useSetEligibilitiesContext, useEligibilitiesContextProvider] = makeContextProvider<PermissionEligibilitiesContext>();

export function PermissionEligibilities() {
    useTrackAnalyticsEvent(AnalyticsEventActionType.PageView);

    const [registerPermissionEligibilityChange, triggerPermissionEligibilityChange] = useEvent<() => Promise<void>>();
    const [context, setContext, ContextProvider] =
        useEligibilitiesContextProvider(
            () =>
                new PermissionEligibilitiesContext(
                    false,
                    false,
                    registerPermissionEligibilityChange,
                    triggerPermissionEligibilityChange));

    const localization =
        useLocalization(
            "views.user.permissionEligibilities",
            () => ({
                error: {
                    organizations: [
                        "1 organization",
                        "{{count | NumberFormatter.humanize}} organizations"
                    ],
                    text: [
                        "Organization {{organizationsElement}} is not connected or we dont have permissions anymore. Eligibilities assigned to this organization will not take effect.",
                        "{{organizationsElement}} are not connected or we dont have permissions anymore. Eligibilities assigned to these organizations will not take effect."
                    ]
                },
                title: "Eligibilities"
            }));

    useLayoutOptions({ view: { title: localization.title() } });

    const activeTenantModels = tenantModelStore.useGetActiveTenants();
    const activeTenantModelMap =
        useMemo(
            () =>
                _.keyBy(
                    activeTenantModels,
                    activeTenantModel => activeTenantModel.configuration.id),
            [activeTenantModels]);
    const scopeNodeModels = scopeNodeModelStore.useGetAll();
    const scopeNodeModelMap =
        useMemo(
            () =>
                _.keyBy(
                    scopeNodeModels,
                    scopeNodeModel => scopeNodeModel.configuration.id),
            [scopeNodeModels]);
    const permissionEligibilityModels = scopeSystemEntityModelStore.useGetPermissionEligibility();
    const invalidPrincipalTenantDisplayReferences =
        useMemo(
            () =>
                _(permissionEligibilityModels).
                    filter(
                        permissionEligibilityModel =>
                            !permissionEligibilityModel.configuration.systemDeleted &&
                            UserHelper.hasScopePermissions(
                                permissionEligibilityModel.configuration.scopeId,
                                Contract.IdentityPermission.PermissionManagementAdministrationRead) &&
                            TenantHelper.isCloudProviderTenantType(ScopeHelper.getTenantType(scopeNodeModelMap[permissionEligibilityModel.scopeId])!) &&
                            _.has(
                                activeTenantModelMap,
                                permissionEligibilityModel.configuration.principalTenantId) &&
                            !_(activeTenantModelMap).
                                values().
                                some(
                                    activeTenantModel =>
                                        (activeTenantModel.state as Contract.CloudProviderTenantState).permissionManagementPrincipalTenantId === permissionEligibilityModel.configuration.principalTenantId &&
                                        (activeTenantModel.state as Contract.CloudProviderTenantState).permissionManagementEnabled)).
                    map(permissionEligibilityModel => activeTenantModelMap[permissionEligibilityModel.configuration.principalTenantId].configuration.displayReference).
                    uniq().
                    value(),
            [activeTenantModelMap, permissionEligibilityModels, scopeNodeModelMap]);

    const permissionManagementTenantTypes =
        useMemo(
            () => TenantHelper.PermissionManagementTenantTypes,
            []);

    return (
        <ScopeNavigationView
            disabledScopeIds={[ScopeHelper.customerId]}
            hideNonPermittedScopes={true}
            identityPermissions={[Contract.IdentityPermission.PermissionManagementAdministrationRead]}
            layout="viewTitle"
            templatePath={`${UserConsoleAppUrlHelper.getPermissionEligibilitiesRelativeUrl()}/{scopeId}`}
            tenantTypes={permissionManagementTenantTypes}>
            {scopeNodeModel =>
                <ContextProvider>
                    {context.addOrEditOpen !== false &&
                        <Dialog
                            variant="editor"
                            onClose={
                                () =>
                                    setContext(
                                        context => ({
                                            ...context,
                                            addOrEditOpen: false,
                                            editMode: false
                                        }))}>
                            <AddOrEdit/>
                        </Dialog>}
                    <VerticalFillGrid
                        key={scopeNodeModel.configuration.id}
                        spacing={1}>
                        {!_.isEmpty(invalidPrincipalTenantDisplayReferences) &&
                            <Message
                                level="error"
                                title={
                                    <Typography
                                        sx={{
                                            fontSize: "13px",
                                            fontWeight: 500
                                        }}>
                                        {localization.error.text(
                                            invalidPrincipalTenantDisplayReferences.length,
                                            {
                                                organizationsElement:
                                                    <InlineItems
                                                        items={invalidPrincipalTenantDisplayReferences}
                                                        namePluralizer={localization.error.organizations}
                                                        variant="itemOrItemCountAndType">
                                                        {(invalidOrganizationRawId, inline) =>
                                                            <Typography
                                                                component="span"
                                                                sx={{
                                                                    fontWeight:
                                                                        inline
                                                                            ? "bold"
                                                                            : undefined
                                                                }}>
                                                                {invalidOrganizationRawId}
                                                            </Typography>}
                                                    </InlineItems>
                                            })}
                                    </Typography>}
                                variant="highlight"/>}
                        <Table/>
                    </VerticalFillGrid>
                </ContextProvider>}
        </ScopeNavigationView>);
}