import { Link, Step, Steps, TextSnippet, TextSnippetItem, useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import React from "react";
import { useTheme } from "../../../../../../../../../common";

export function HelmItem() {
    const localization =
        useLocalization(
            "views.customer.kubernetes.systemKubernetesClusters.addOrEdit.helmItem",
            () => ({
                steps: {
                    step1: {
                        links: {
                            documentation: "documentation"
                        },
                        title: "Install Helm as a package manager for Kubernetes. Please refer to Helm’s {{documentationLink}} to get started."
                    },
                    step2: "Configure kubectl to connect to your Kubernetes cluster.",
                    step3: "Run the following command to add the Tenable Helm chart repository:"
                },
                title: "Perform the following steps for clusters that you want to connect to Tenable Cloud Security."
            }));

    const theme = useTheme();
    return (
        <Stack spacing={2}>
            <Typography>
                {localization.title()}
            </Typography>
            <Steps variant="plainNumbers">
                {[
                    localization.steps.step1.title({
                        documentationLink:
                            <Link
                                urlOrGetUrl="https://helm.sh/docs/"
                                variant="external">
                                {localization.steps.step1.links.documentation()}
                            </Link>
                    }),
                    localization.steps.step2(),
                    new Step(
                        localization.steps.step3(),
                        {
                            contentElement:
                                <TextSnippet
                                    height={theme.spacing(5)}
                                    items={[
                                        new TextSnippetItem(
                                            "helm repo add tenable https://charts.tenable.com",
                                            "")
                                    ]}/>
                        })
                ]}
            </Steps>
        </Stack>);
}