import { Checkbox, DataTableColumnHeader, useChangeEffect, useDataTableActionsContext, useDataTableSelectionContext, useLayoutChangeEffect } from "@infrastructure";
import { Box, CircularProgress, useTheme } from "@mui/material";
import _ from "lodash";
import React, { Fragment, useMemo, useRef, useState } from "react";

type DataTableActionHeaderProps = {
    highlightedItemExists: boolean;
    itemSelectionEnabled: boolean;
    onRemoveVisibleColumn?: (id: string) => void;
    sticky: boolean;
};

export function DataTableActionHeader({ highlightedItemExists, itemSelectionEnabled, onRemoveVisibleColumn, sticky }: DataTableActionHeaderProps) {
    const theme = useTheme();
    return !itemSelectionEnabled && !highlightedItemExists
        ? <Fragment/>
        : <DataTableColumnHeader
            columnProps={{
                headerSx: {
                    ...(itemSelectionEnabled
                        ? {
                            background: theme.palette.background.paper,
                            width: "58px",
                            ...(sticky && {
                                left: 0,
                                position: "sticky",
                                zIndex: 99
                            })
                        }
                        : {
                            padding: 0,
                            width: theme.spacing(0.5)
                        })
                },
                id: "DataTableActionHeader",
                orderable: false,
                resizable: false,
                sortOptions: { enabled: false },
                titleElement: itemSelectionEnabled && <ToggleAll/>
            }}
            filterOptions={{ enabled: false }}
            itemSelectionEnabled={itemSelectionEnabled}
            sortOptions={{ enabled: false }}
            tableOptions={{
                orderable: false,
                resizable: false
            }}
            onRemoveVisibleColumn={onRemoveVisibleColumn}/>;
}

function ToggleAll() {
    const { actions } = useDataTableActionsContext();
    const { firstPageLoading, itemCount, itemIdToSelectionEnabledMap, loadSelectionData, selectedItemIds } = useDataTableSelectionContext();

    const [itemIds, selectionEnabledItemIds] =
        useMemo(
            () => {
                if (_.isEmpty(itemIdToSelectionEnabledMap)) {
                    return [[], []];
                }

                const itemIds = _.keys(itemIdToSelectionEnabledMap);
                const selectionEnabledItemIds =
                    _.filter(
                        itemIds,
                        itemId => itemIdToSelectionEnabledMap![itemId]);
                return [itemIds, selectionEnabledItemIds];
            },
            [itemIdToSelectionEnabledMap]);

    const filteredSelectedItemIds =
        useMemo(
            () =>
                _.intersection(
                    itemIds,
                    selectedItemIds),
            [itemIds, selectedItemIds]);

    function toggleAll() {
        actions.setSelectedItemIds(
            filteredSelectedItemIds.length === selectionEnabledItemIds.length
                ? _.difference(selectedItemIds, selectionEnabledItemIds)
                : _.union(selectedItemIds, selectionEnabledItemIds));
    }

    const selectAllRef = useRef(false);
    useChangeEffect(
        () => {
            if (firstPageLoading) {
                selectAllRef.current = false;
            }
        },
        [firstPageLoading]);

    useLayoutChangeEffect(
        () => {
            if (selectAllRef.current) {
                toggleAll();
            }

            selectAllRef.current = false;
        },
        [itemIdToSelectionEnabledMap]);

    const [loading, setLoading] = useState(false);
    const theme = useTheme();
    const itemSelectionDataLoaded =
        _.isNil(loadSelectionData) ||
        _.size(itemIdToSelectionEnabledMap) === itemCount;
    const selectionDisabled =
        firstPageLoading ||
        itemCount > 10000 ||
        _.isEmpty(selectionEnabledItemIds) && itemSelectionDataLoaded;
    return loading
        ? <Box
            sx={{
                height: theme.spacing(4),
                width: theme.spacing(3.5)
            }}>
            <CircularProgress
                size={theme.spacing(2)}
                sx={{ padding: theme.spacing(0.75, 0.5) }}
                variant="indeterminate"/>
        </Box>
        : <Checkbox
            checked={
                !selectionDisabled &&
                filteredSelectedItemIds.length === selectionEnabledItemIds.length &&
                itemSelectionDataLoaded}
            disabled={selectionDisabled}
            indeterminate={
                filteredSelectedItemIds.length > 0 &&
                filteredSelectedItemIds.length < selectionEnabledItemIds.length}
            sx={{
                fontSize: "24px",
                opacity:
                    selectionDisabled
                        ? 0.4
                        : 1,
                padding: 0
            }}
            onChange={
                async () => {
                    if (!itemSelectionDataLoaded) {
                        selectAllRef.current = true;
                        setLoading(true);

                        try {
                            await loadSelectionData!();
                        } finally {
                            setLoading(false);
                        }
                    } else {
                        toggleAll();
                    }
                }}
            onClick={event => event.stopPropagation()}/>;
}