import { NumberFormatter, useLocalization } from "@infrastructure";
import React from "react";
import { useOciCommonEncryptedResourceInfoElements, useOciDefaultResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { useCustomEntityPropertyInfoItemElements } from "..";
import { Contract, InfoItem, useEntityTypeNameTranslator } from "../../../../../../../../../../common";
import { useOciFssFileSystemStatusTranslator } from "../../../../../../hooks";
import { EntitiesInfoItem, Info } from "../../../../components";

export function useOciFssFileSystemDefinition(resourceModel: Contract.OciResourceModel, options?: DefinitionOptions) {
    const commonEncryptedResourceInfoElements = useOciCommonEncryptedResourceInfoElements(resourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useOciDefaultResourceInfoItemElements(resourceModel);
    const fileSystemModel = resourceModel as Contract.OciFssFileSystemModel;
    const fileSystem = fileSystemModel.entity as Contract.OciFssFileSystem;

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const fileSystemStatusTranslator = useOciFssFileSystemStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.oci.useOciFssFileSystemDefinition",
            () => ({
                info: {
                    items: {
                        availabilityDomain: "Availability Domain",
                        size: "Utilization Size",
                        status: "State"
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="status"
                        title={localization.info.items.status()}
                        value={fileSystemStatusTranslator(fileSystem.status)}/>,
                    <InfoItem
                        key="size"
                        location="all"
                        title={localization.info.items.size()}
                        value={NumberFormatter.storage(fileSystem.size)}/>,
                    commonEncryptedResourceInfoElements.encryptionExists,
                    commonEncryptedResourceInfoElements.encryptionVaultKeyIdReference,
                    <InfoItem
                        key="availabilityDomain"
                        location="all"
                        title={localization.info.items.availabilityDomain()}
                        value={fileSystem.availabilityDomain}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={fileSystemModel.mountTargetExportIds}
                        entityTypeName={Contract.TypeNames.OciFssMountTargetExport}
                        key="mountTargetExports"
                        location="all"
                        title={
                            entityTypeNameTranslator(
                                Contract.TypeNames.OciFssMountTargetExport,
                                { count: 0 })}/>
                ]}
                options={options?.infoOptions}>
            </Info>
    });
}