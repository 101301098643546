import { EmptyMessage, ItemSelector, useLocalization } from "@infrastructure";
import { Stack } from "@mui/material";
import _ from "lodash";
import React, { memo, useMemo, useState } from "react";
import { Contract, useVulnerabilityScoreSourceTypeTranslator, VulnerabilityEventType, VulnerabilityScoreSourceType } from "../../../../../../../../../common";
import { getEvents, VulnerabilityEventData } from "../utilities";
import { Legend, TimelineScoreAndEventTrend } from "./components";

export type ChartDataItem = {
    dayTime: number;
    events?: VulnerabilityEventData[];
    score?: number;
    vprScoreEvent?: boolean;
};

const TimelineScoreAndEventTrendChartMemo = memo(TimelineScoreAndEventTrendChart);
export { TimelineScoreAndEventTrendChartMemo as TimelineScoreAndEventTrendChart };

type TimelineScoreAndEventTrendChartProps = {
    vulnerability: Contract.Vulnerability;
};

function TimelineScoreAndEventTrendChart({ vulnerability }: TimelineScoreAndEventTrendChartProps) {
    const [activeEventType, setActiveEventType] = useState<VulnerabilityEventType | undefined>();
    const [hoverEventType, setHoverEventType] = useState<VulnerabilityEventType | undefined>();
    const [selectedScoreType, setSelectedScoreType] = useState<VulnerabilityScoreSourceType.Vpr | VulnerabilityScoreSourceType.Epss>(VulnerabilityScoreSourceType.Vpr);

    const [vprEventTypes, vprScoreAndEventsChartDataItems, epssScoreChartDataItems] =
        useMemo(
            () => {
                const todayDateTime = new Date().setHours(0, 0, 0, 0);
                const epssScoreChartDataItems =
                    _(vulnerability.epssScoreDatas).
                        map(
                            epssScoreData => ({
                                ...epssScoreData,
                                dayTime: new Date(epssScoreData.time).setHours(0, 0, 0, 0)
                            })).
                        as<ChartDataItem>().
                        concatIf(
                            !_.isEmpty(vulnerability.epssScoreDatas),
                            () => ({
                                dayTime: todayDateTime,
                                score: _.last(vulnerability.epssScoreDatas)!.score
                            })).
                        value();
                const vprScoreAndEventsChartDataItems = getVprScoreAndEventsChartDataItems(vulnerability);
                if (!_.isEmpty(vprScoreAndEventsChartDataItems)) {
                    vprScoreAndEventsChartDataItems.push({
                        dayTime: todayDateTime,
                        score: _.last(vprScoreAndEventsChartDataItems)?.score,
                        vprScoreEvent: true
                    });
                }

                const vprEventTypes =
                    _(vprScoreAndEventsChartDataItems).
                        filter(item => !item.vprScoreEvent).
                        flatMap(
                            ({ events }) =>
                                _.map(
                                    events,
                                    event => event.eventType)).
                        uniq().
                        value();

                return [vprEventTypes, vprScoreAndEventsChartDataItems, epssScoreChartDataItems];
            },
            [vulnerability]);

    const xAxisDomain =
        useMemo(
            () => {
                const chartDataItems =
                    selectedScoreType === VulnerabilityScoreSourceType.Vpr
                        ? vprScoreAndEventsChartDataItems
                        : epssScoreChartDataItems;
                return [_.first(chartDataItems)?.dayTime, _.last(chartDataItems)?.dayTime] as [number, number];
            },
            [selectedScoreType, vprScoreAndEventsChartDataItems, epssScoreChartDataItems]);

    const vulnerabilityScoreTypeTranslator = useVulnerabilityScoreSourceTypeTranslator();
    const timelineScoreAndEventTrendProperties =
        useMemo(
            () => ({
                [VulnerabilityScoreSourceType.Vpr]: {
                    items: vprScoreAndEventsChartDataItems,
                    ticks: vprTicks,
                    title: vulnerabilityScoreTypeTranslator(VulnerabilityScoreSourceType.Vpr),
                    xAxisDomain,
                    yAxisDomain: [0, 10.5] as [number, number]
                },
                [VulnerabilityScoreSourceType.Epss]: {
                    items: epssScoreChartDataItems,
                    ticks: epssTicks,
                    title: vulnerabilityScoreTypeTranslator(VulnerabilityScoreSourceType.Epss),
                    xAxisDomain,
                    yAxisDomain: [0, 1] as [number, number]
                }
            }),
            [epssScoreChartDataItems, vprScoreAndEventsChartDataItems, xAxisDomain]);

    const localization =
            useLocalization(
                "views.customer.workloadAnalysis.vulnerabilities.profile.timelineScoreAndEventTrendChart.timelineScoreAndEventTrendChart",
                () => ({
                    empty: "No Items"
                }));

    return (
        <Stack
            spacing={1}>
            <Stack
                alignItems="center"
                direction="row"
                justifyContent={
                    vprEventTypes.length && selectedScoreType === VulnerabilityScoreSourceType.Vpr
                        ? "space-between"
                        : "flex-end"}>
                {selectedScoreType === VulnerabilityScoreSourceType.Vpr &&
                    <Legend
                        activeEventType={activeEventType}
                        eventTypes={vprEventTypes}
                        hoverEventType={hoverEventType}
                        setActiveEventType={setActiveEventType}
                        setHoverEventType={setHoverEventType}/>}
                <ItemSelector
                    dense={true}
                    items={[VulnerabilityScoreSourceType.Vpr, VulnerabilityScoreSourceType.Epss]}
                    selectedItem={selectedScoreType}
                    sorted={false}
                    onSelectedItemChanged={item => setSelectedScoreType(item)}>
                    {vulnerabilityScoreTypeTranslator}
                </ItemSelector>
            </Stack>
            {_.isEmpty(timelineScoreAndEventTrendProperties[selectedScoreType].items)
                ? <EmptyMessage
                    message={localization.empty()}
                    verticalCenter={true}/>
                : <TimelineScoreAndEventTrend
                    activeEventType={activeEventType}
                    hoverEventType={hoverEventType}
                    type={selectedScoreType}
                    {...timelineScoreAndEventTrendProperties[selectedScoreType]}/>}
        </Stack>);
}

const epssTicks = [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1];
const vprTicks = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

function getVprScoreAndEventsChartDataItems(vulnerability: Contract.Vulnerability): ChartDataItem[] {
    const eventsChartDataItems: ChartDataItem[] =
        _(getEvents(vulnerability)).
            groupBy(event => event.dayTime).
            entries().
            map(
                ([dayTime, events]) => ({
                    dayTime: Number(dayTime),
                    events
                })).
            value();

    const vprScoreChartDataItems: ChartDataItem[] =
        _.map(
            vulnerability.vprScoreDatas,
            vprScoreData => ({
                ...vprScoreData,
                dayTime: new Date(vprScoreData.time).setHours(0, 0, 0, 0),
                vprScoreEvent: true
            }));

    const vprScoreAndEventsChartDataItems: ChartDataItem[] =
        _(eventsChartDataItems).
            concat(vprScoreChartDataItems).
            sortBy(
                (item: ChartDataItem) => item.dayTime,
                (item: ChartDataItem) =>
                    item.vprScoreEvent
                        ? 0
                        : 1).
            reduce(
                (acc, vprAndEventsChartDataItem) => {
                    acc.push({
                        ...vprAndEventsChartDataItem,
                        score: vprAndEventsChartDataItem.score ?? (_.last(acc)?.score ?? NaN)
                    });
                    return acc;
                },
                [] as ChartDataItem[]);

    const firstVprScoreChartDataItemIndex =
        _.findIndex(
            vprScoreAndEventsChartDataItems,
            vprScoreAndEventsChartDataItem => !!vprScoreAndEventsChartDataItem.vprScoreEvent);

    return firstVprScoreChartDataItemIndex === -1
        ? vprScoreAndEventsChartDataItems
        : _.slice(
            vprScoreAndEventsChartDataItems,
            firstVprScoreChartDataItemIndex);
}