﻿import React from "react";

export function UsIcon() {
    return (
        <svg
            fill="none"
            height={50}
            viewBox="0 0 50 50"
            width={50}>
            <path
                d="M0.882812 28.4375C1.38528 31.7541 2.55553 34.8505 4.24892 37.5896H45.9032C47.5966 34.8505 48.7669 31.7541 49.2693 28.4375H0.882812Z"
                fill="#F3F4F5"/>
            <path
                d="M38.4739 45.2163H11.6777C15.5273 47.7406 20.1283 49.2138 25.0758 49.2138C30.0233 49.2138 34.6243 47.7406 38.4739 45.2163Z"
                fill="#F3F4F5"/>
            <path
                d="M4.25 37.5894C6.13806 40.6439 8.67977 43.2492 11.6788 45.2161H38.475C41.4745 43.2492 44.0157 40.6439 45.9038 37.5894H4.25Z"
                fill="#DC3027"/>
            <path
                d="M27.5936 0.400309C26.7659 0.315476 25.9263 0.271973 25.0763 0.271973C11.5614 0.271973 0.605469 11.2279 0.605469 24.7428C0.605469 25.9985 0.700633 27.2323 0.883349 28.4374H27.5936V0.400309Z"
                fill="#283991"/>
            <path
                d="M8.04933 12.8304L7.29236 10.5062L9.26363 9.07332H6.82579L6.67733 8.61816C5.90405 9.49966 5.19385 10.4366 4.55217 11.4231L4.09375 12.8304L6.071 11.3932L8.04933 12.8304Z"
                fill="#F3F4F5"/>
            <path
                d="M12.9738 5.52416L14.9424 6.95489L14.1876 4.63722L16.1567 3.19834H13.7188L13.683 3.0874C12.914 3.49308 12.1674 3.93464 11.4496 4.41644L11.7585 4.64103L11.0156 6.95489L12.9738 5.52416Z"
                fill="#F3F4F5"/>
            <path
                d="M4.85289 22.2576L4.09701 24.5796L6.071 23.144L8.05042 24.5796L7.29454 22.2576L9.26309 20.8219H6.82687L6.071 18.5054L5.31512 20.8219H2.87891L4.85289 22.2576Z"
                fill="#F3F4F5"/>
            <path
                d="M18.6654 22.2576L17.9095 24.5796L19.8835 23.144L21.8629 24.5796L21.107 22.2576L23.0756 20.8219H20.6394L19.8835 18.5054L19.1331 20.8219H16.6914L18.6654 22.2576Z"
                fill="#F3F4F5"/>
            <path
                d="M18.6654 10.506L17.9095 12.8281L19.8835 11.3924L21.8629 12.8281L21.107 10.506L23.0756 9.07042H20.6394L19.8835 6.75928L19.1331 9.07042H16.6914L18.6654 10.506Z"
                fill="#F3F4F5"/>
            <path
                d="M11.742 16.3791L10.9861 18.7065L12.9655 17.2709L14.9395 18.7065L14.1836 16.3791L16.1576 14.9489H13.716L12.9655 12.6323L12.2096 14.9489H9.77344L11.742 16.3791Z"
                fill="#F3F4F5"/>
            <path
                d="M49.2287 20.8101H27.5938V28.4368H49.2695C49.4522 27.2323 49.5474 25.9984 49.5474 24.7428C49.5474 23.4034 49.4354 22.0912 49.2287 20.8101Z"
                fill="#DC3027"/>
            <path
                d="M27.5938 20.8103H49.2287C48.6915 17.4872 47.4837 14.3902 45.7511 11.6582H27.5938V20.8103Z"
                fill="#F3F4F5"/>
            <path
                d="M27.5938 11.658H45.7506C43.8 8.58229 41.1843 5.97315 38.1026 4.03125H27.5938V11.658Z"
                fill="#DC3027"/>
        </svg>);
}