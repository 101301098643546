import { ActionButton, useLocalization } from "@infrastructure";
import { Stack, useTheme } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { useState } from "react";
import utf8 from "utf8";
import { Contract, DocumentViewerDialog, PolicyDocumentIcon } from "../../../../../../../../../../../common";
import { CodeTypeIcon } from "../../../../../../../../../../../tenants/code/icons";

export type UpsertResourcePolicyChangeActionsProps = {
    newPolicyFileTypeToFileMap: Dictionary<Contract.RiskFile>;
};

export function UpsertResourcePolicyChangeActions({ newPolicyFileTypeToFileMap }: UpsertResourcePolicyChangeActionsProps) {
    const [riskFile, setRiskFile] = useState<Contract.RiskFile>();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.upsertResourcePolicyChangeActions",
            () => ({
                actions: {
                    cloudFormation: "CloudFormation",
                    json: "JSON",
                    terraform: "Terraform"
                }
            }));
    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            direction="row">
            {!_.isNil(riskFile) && (
                <DocumentViewerDialog
                    document={utf8.decode(atob(riskFile.contentBytes))}
                    documentFileName={riskFile.name}
                    title={riskFile.name}
                    onClose={() => setRiskFile(undefined)}/>)}
            <ActionButton
                tooltip={localization.actions.json()}
                onClick={() => setRiskFile(newPolicyFileTypeToFileMap[Contract.RiskFileType.AwsPolicyDocumentRaw])}>
                <PolicyDocumentIcon
                    sx={{
                        color: theme.palette.text.primary,
                        fontSize: "24px"
                    }}/>
            </ActionButton>
            <ActionButton
                tooltip={localization.actions.terraform()}
                onClick={() => setRiskFile(newPolicyFileTypeToFileMap[Contract.RiskFileType.Terraform])}>
                <CodeTypeIcon
                    codeType={Contract.CodeType.Terraform}
                    sx={{ fontSize: "24px" }}/>
            </ActionButton>
            <ActionButton
                tooltip={localization.actions.cloudFormation()}
                onClick={() => setRiskFile(newPolicyFileTypeToFileMap[Contract.RiskFileType.AwsCloudFormation])}>
                <CodeTypeIcon
                    codeType={Contract.CodeType.CloudFormation}
                    sx={{ fontSize: "24px" }}/>
            </ActionButton>
        </Stack>);
}