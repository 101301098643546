import { useLocalization } from "@infrastructure";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { Contract, InfoItem } from "../../../../../../../../../../common";
import { EntitiesInfoItem, Info } from "../../../../components";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";
import { useAzureDefaultResourceInfoItemElements } from "./useAzureDefaultResourceInfoItemElements";

export function useAzureNetworkPublicIpAddressDefinition(resourceGroupResourceModel: Contract.AzureResourceGroupResourceModel, options?: DefinitionOptions) {
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceGroupResourceModel);
    const defaultResourceInfoItemElements = useAzureDefaultResourceInfoItemElements(resourceGroupResourceModel);
    const publicIpAddress = resourceGroupResourceModel.entity as Contract.AzureNetworkPublicIpAddress;
    const publicIpAddressModel = resourceGroupResourceModel as Contract.AzureNetworkPublicIpAddressModel;

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.azure.useAzureNetworkPublicIpAddressDefinition",
            () => ({
                info: {
                    items: {
                        attachedResourceId: "Attached To",
                        dnsName: "DNS Name",
                        dnsZoneIds: "DNS Zones",
                        ipAddress: "IP Address",
                        sku: {
                            title: "SKU",
                            [Contract.TypeNames.AzureNetworkPublicIpAddressSku]: {
                                [Contract.AzureNetworkPublicIpAddressSku.Basic]: "Basic",
                                [Contract.AzureNetworkPublicIpAddressSku.Standard]: "Standard"
                            }
                        },
                        type: {
                            title: "Allocation Method",
                            [Contract.TypeNames.AzureNetworkPublicIpAddressType]: {
                                [Contract.AzureNetworkPublicIpAddressType.Dynamic]: "Dynamic",
                                [Contract.AzureNetworkPublicIpAddressType.Static]: "Static"
                            }
                        }
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="dnsName"
                        title={localization.info.items.dnsName()}
                        value={publicIpAddress.dnsName}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={publicIpAddressModel.dnsZoneIds}
                        entityTypeName={Contract.TypeNames.Entity}
                        key="dnsZoneIds"
                        title={localization.info.items.dnsZoneIds()}/>,
                    <InfoItem
                        key="ipAddress"
                        title={localization.info.items.ipAddress()}
                        value={publicIpAddress.ipAddress}/>,
                    <InfoItem
                        key="sku"
                        title={localization.info.items.sku.title()}
                        value={localization.info.items.sku[Contract.TypeNames.AzureNetworkPublicIpAddressSku][publicIpAddress.sku]()}/>,
                    <InfoItem
                        key="allocationMethod"
                        title={localization.info.items.type.title()}
                        value={localization.info.items.type[Contract.TypeNames.AzureNetworkPublicIpAddressType][publicIpAddress.type]()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={publicIpAddressModel.attachedResourceId}
                        entityTypeName={Contract.TypeNames.AzureResourceGroupResource}
                        key="attachedResourceId"
                        title={localization.info.items.attachedResourceId()}/>
                ]}
                options={options?.infoOptions}>
            </Info>
    });
}