import { DataTableColumnRenderProps } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, EntitiesCell } from "../";

export function NetworkedResourcesCell({ item: networkedResourceTypeNameToIdsMap }: DataTableColumnRenderProps<_.Dictionary<string[]>>) {
    return (
        <EntitiesCell
            entityIdsOrModels={
                _(networkedResourceTypeNameToIdsMap).
                    values().
                    flatMap().
                    value()}
            entityTypeName={Contract.TypeNames.INetworkedResource}
            entityVariant="iconText"/>);
}