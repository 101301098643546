import { DropdownIcon, useLocalization } from "@infrastructure";
import { Autocomplete, CircularProgress, FormHelperText, TextField } from "@mui/material";
import _ from "lodash";
import React, { Fragment, useEffect, useState } from "react";
import { Contract, TicketingServiceController } from "../../../controllers";
import { useTheme } from "../../../themes";

type LabelsSelectorProps = {
    disabled: boolean;
    disablePortal?: boolean;
    instanceId?: string;
    mandatoryField: Contract.JiraCustomLabelsField;
    mandatoryFieldName: string;
    mandatoryFieldNameToErrorMessageMap: _.Dictionary<string | undefined>;
    mandatoryFieldNameToValuesMap: _.Dictionary<string[]>;
    setMandatoryFieldNameToErrorMessageMap: React.Dispatch<React.SetStateAction<_.Dictionary<string | undefined>>>;
    setMandatoryFieldNameToValuesMap: React.Dispatch<React.SetStateAction<_.Dictionary<string[]>>>;
};

export function MandatoryLabelsSelector({ disabled, disablePortal, instanceId, mandatoryField, mandatoryFieldNameToErrorMessageMap, mandatoryFieldNameToValuesMap, setMandatoryFieldNameToErrorMessageMap, setMandatoryFieldNameToValuesMap }: LabelsSelectorProps) {
    const localization =
        useLocalization(
            "common.jiraIssueSelector.mandatoryLabelsSelector",
            () => ({
                error: {
                    required: "{{fieldName}} cannot be empty",
                    spaces: "Labels cannot contain spaces"
                },
                loading: "Searching..."
            }));

    const [inputValue, setInputValue] = useState("");
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState<Contract.JiraAutoCompleteSuggestion[]>([]);

    useEffect(() => {
        setLoading(true);
        const timeoutId =
            setTimeout(async () => {
                try {
                    if (!_.isNil(instanceId)) {
                        const ticketingServiceControllerGetJiraCustomLabelsFieldAutocompleteSuggestionsResponse =
                            await TicketingServiceController.getJiraCustomLabelsFieldAutocompleteSuggestions(
                                new Contract.TicketingServiceControllerGetJiraCustomLabelsFieldAutocompleteSuggestionsRequest(
                                    mandatoryField.customId,
                                    mandatoryField.name,
                                    instanceId,
                                    inputValue));
                        setOptions(ticketingServiceControllerGetJiraCustomLabelsFieldAutocompleteSuggestionsResponse.suggestions);
                    } else {
                        setOptions([]);
                    }
                } catch {
                    setOptions([]);
                }
                setLoading(false);
            }, 500);
        return () => {
            clearTimeout(timeoutId);
            setLoading(false);
        };
    }, [inputValue]);

    const theme = useTheme();
    return (
        <Fragment>
            <Autocomplete
                disabled={disabled}
                disablePortal={disablePortal}
                filterSelectedOptions={true}
                forcePopupIcon={true}
                freeSolo={true}
                fullWidth={true}
                getOptionLabel={
                    suggestion =>
                        _.isString(suggestion)
                            ? suggestion
                            : suggestion.value}
                includeInputInList={true}
                loading={loading}
                loadingText={localization.loading()}
                multiple={true}
                options={options}
                popupIcon={
                    loading
                        ? <CircularProgress
                            size={theme.spacing(2)}
                            variant="indeterminate"/>
                        : <DropdownIcon
                            sx={{
                                color:
                                    disabled
                                        ? theme.palette.text.disabled
                                        : theme.palette.text.secondary
                            }}/>}
                renderInput={
                    params => (
                        <TextField
                            {...params}
                            label={mandatoryField.name}
                            slotProps={{
                                input: {
                                    ...params.InputProps,
                                    sx: { maxHeight: "unset" }
                                }
                            }}
                            variant="outlined"/>)}
                slotProps={{
                    chip: {
                        clickable: true,
                        variant: "outlined"
                    }
                }}
                sx={{ display: "grid" }}
                value={mandatoryFieldNameToValuesMap[mandatoryField.name] ?? []}
                onChange={
                    (_event, selectedLabels) => {
                        setMandatoryFieldNameToValuesMap(
                            mandatoryFieldNameToValuesMap => ({
                                ...mandatoryFieldNameToValuesMap,
                                [mandatoryField.name]:
                                    _.map(
                                        selectedLabels,
                                        suggestion =>
                                            _.isString(suggestion)
                                                ? suggestion
                                                : suggestion.value)
                            }));

                        setMandatoryFieldNameToErrorMessageMap(
                            mandatoryFieldNameToErrorMessageMap => ({
                                ...mandatoryFieldNameToErrorMessageMap,
                                [mandatoryField.name]:
                                    _.isEmpty(selectedLabels)
                                        ? localization.error.required({ fieldName: mandatoryField.name })
                                        : _.some(
                                            selectedLabels,
                                            label =>
                                                _.isString(label) &&
                                                _.includes(label, " "))
                                            ? localization.error.spaces()
                                            : undefined
                            }));
                    }}
                onInputChange={
                    (_event, newInputValue) => {
                        setInputValue(newInputValue);
                    }}/>
            {!_.isNil(mandatoryFieldNameToErrorMessageMap[mandatoryField.name]) && (
                <FormHelperText error={true}>
                    {mandatoryFieldNameToErrorMessageMap[mandatoryField.name]}
                </FormHelperText>)}
        </Fragment>);
}