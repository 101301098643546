import { Step, Steps, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { useGetAwsAcmCertificateRiskContext } from "../contexts";

export function useAwsAcmCertificateKeyMinLengthRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsAcmCertificateKeyMinLengthRisk;
    const certificateModel = entityModelStore.useGet(risk.entityId) as Contract.AwsAcmCertificateModel;
    const certificate = certificateModel.entity as Contract.AwsAcmCertificate;

    const getAwsAcmCertificateRiskContext = useGetAwsAcmCertificateRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsAcmCertificateKeyMinLengthRiskDefinition",
            () => ({
                description: "{{certificate}} key algorithm should use a minimum of 2048-bit key",
                sections: {
                    resolution: {
                        step1: {
                            step1: "To reimport, click on the {{certificate}}, and then click **Reimport** and provide the certificate details",
                            step2: "To request or upload a new certificate, click on **Request** or **Import** from the **Certificate** page",
                            title: "It is possible to upgrade an existing certificate to 2048-bit or 4096-bit by either reimporting it or requesting a new one"
                        },
                        step2: "Before reimporting or deleting the old certificate, make sure that any software or resources are using the newly created certificate"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            certificate:
                <Entity
                    entityIdOrModel={certificateModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.Acm,
                AwsConsoleUrlBuilder.getAcmCertificatesUrl(certificate)),
            new Step(
                localization.sections.resolution.step1.title(),
                {
                    contentElement:
                        <Steps variant="plainNumbers">
                            {_<string>([]).
                                concatIf(
                                    certificate.type === Contract.AwsAcmCertificateType.Imported,
                                    localization.sections.resolution.step1.step1({
                                        certificate:
                                            <Entity
                                                entityIdOrModel={certificateModel}
                                                glanceOptions={{ disabled: true }}
                                                linkOptions={{ disabled: true }}
                                                variant="typeText"/>
                                    })).
                                concat(localization.sections.resolution.step1.step2()).
                                value()}
                        </Steps>
                }),
            localization.sections.resolution.step2()
        ],
        riskModel,
        () => {
            const certificateRiskContext = getAwsAcmCertificateRiskContext(certificateModel);
            return [
                certificateRiskContext.generalInformation,
                certificateRiskContext.sensitive,
                certificateRiskContext.keyType,
                certificateRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}