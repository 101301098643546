import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, tenantModelStore } from "../../../../../../../../../../common";
import { EntitiesInfoCard, Info } from "../../../../components";
import { GciDirectoryPrincipalGcpRoleBindings } from "../../components";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../useDefinition";
import { useGciDefaultPartialPrincipalInfoItemElements } from "./useGciDefaultDirectoryPartialPrincipalInfoItemElements";
import { useGciDefaultEntityInfoItemElements } from "./useGciDefaultEntityInfoItemElements";

export function useGciDirectoryPartialGroupDefinition(entityModel: Contract.GciEntityModel, options?: DefinitionOptions) {
    const activeCloudProviderTenantTypes = tenantModelStore.useGetActiveCloudProviderTenantTypes();
    const defaultPartialPrincipalInfoItemElements = useGciDefaultPartialPrincipalInfoItemElements(entityModel);
    const defaultResourceInfoItemElements = useGciDefaultEntityInfoItemElements(entityModel);
    const partialGroupModel = entityModel as Contract.GciDirectoryPartialGroupModel;

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gci.useGciDirectoryPartialGroupDefinition",
            () => ({
                cards: {
                    groupIds: "Group Memberships"
                },
                tabs: {
                    gcpRoleBindings: "Bindings"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs:
            _<EntityProfileDefinitionTab>([]).
                concatIf(
                    _.includes(activeCloudProviderTenantTypes, Contract.TenantType.Gcp),
                    new EntityProfileDefinitionTab(
                        ProfileCategory.Iam,
                        <GciDirectoryPrincipalGcpRoleBindings
                            csvExportFilePrefix={localization.tabs.gcpRoleBindings()}
                            gcpRoleBindingIds={partialGroupModel.gcpRoleBindingIds}
                            principalId={partialGroupModel.id}/>,
                        localization.tabs.gcpRoleBindings(),
                        "gcpRoleBindings")).
                value(),
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={[]}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={defaultPartialPrincipalInfoItemElements}
                options={options?.infoOptions}>
                <EntitiesInfoCard
                    entityIdsOrModels={partialGroupModel.groupIds}
                    entityTypeName={Contract.TypeNames.GciDirectoryGroup}
                    title={localization.cards.groupIds()}/>
            </Info>,
        sensitive: false
    });
}