import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function AadFolderIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                d="M8.62775 0.690138C8.24787 0.251813 7.6964 0 7.11637 0H3.11852C1.95333 0 1.01059 1.1 1.01059 2.44444L1.00752 5.82532C1.00254 5.88288 1 5.94114 1 6V16C1 17.1046 1.89543 18 3 18H6.52275L7.58612 16.8293L15.6536 7.01718C16.0511 6.53376 16.7899 6.5301 17.1922 7.00954L23 13.9321V4.88889C23 3.54444 22.0467 2.44444 20.8815 2.44444H11.0614C10.4814 2.44444 9.92991 2.19263 9.55003 1.75431L8.62775 0.690138Z"
                fill="#BAC5D3"/>
            <path
                d="M9.85 18.0171L16.4417 22.4103L23.1583 18L24 19.0085L16.4417 24L9 19.0085L9.85 18.0171Z"
                fill="#50E6FF"/>
            <path
                d="M16.4416 10V21.4957L10.3416 17.453L16.4416 10Z"
                fill="#50E6FF"/>
            <path
                d="M16.4416 10V21.4957L22.6666 17.4616L16.4416 10Z"
                fill="url(#paint0_linear_61600_19037)"/>
            <path
                d="M16.4416 15.9401L22.6666 17.4616L16.4416 21.4957L16.4416 15.9401Z"
                fill="#53B1E0"/>
            <path
                d="M16.4416 21.4957L10.3416 17.453L16.4416 15.9401L16.4416 21.4957Z"
                fill="#9CEBFF"/>
            <path
                d="M16.4417 24L24 19.0085L23.1583 18L16.4417 22.4103V24Z"
                fill="url(#paint1_linear_61600_19037)"/>
            <defs>
                <linearGradient
                    gradientUnits="userSpaceOnUse"
                    id="paint0_linear_61600_19037"
                    x1="17.6948"
                    x2="14.7229"
                    y1="22.7093"
                    y2="11.8497">
                    <stop stopColor="#1988D9"/>
                    <stop
                        offset="0.9"
                        stopColor="#54AEF0"/>
                </linearGradient>
                <linearGradient
                    gradientUnits="userSpaceOnUse"
                    id="paint1_linear_61600_19037"
                    x1="18.3834"
                    x2="21.1609"
                    y1="18.2564"
                    y2="22.9132">
                    <stop
                        offset="0.1"
                        stopColor="#54AEF0"/>
                    <stop
                        offset="0.29"
                        stopColor="#4FABEE"/>
                    <stop
                        offset="0.51"
                        stopColor="#41A2E9"/>
                    <stop
                        offset="0.74"
                        stopColor="#2A93E0"/>
                    <stop
                        offset="0.88"
                        stopColor="#1988D9"/>
                </linearGradient>
            </defs>
        </SvgIcon>);
}