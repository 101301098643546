import React from "react";
import { Contract, useDataAnalysisSensitivityTranslator } from "../../index";
import { OutlineLabelButton } from "../OutlineLabelButton";
import { DataAnalysisSensitivityIcon } from "./icons";

type DataAnalysisSensitivityCellProps = {
    count?: number;
    disabled?: boolean;
    sensitivity: Contract.DataAnalysisSensitivity;
};

export function DataAnalysisSensitivity({ count, disabled, sensitivity }: DataAnalysisSensitivityCellProps) {
    const dataSensitivityTranslator = useDataAnalysisSensitivityTranslator();

    return (
        <OutlineLabelButton
            count={count}
            disabled={disabled}
            icon={
                <DataAnalysisSensitivityIcon
                    sensitivity={sensitivity}
                    sx={{
                        opacity:
                            disabled
                                ? 0.5
                                : 1
                    }}/>}
            label={dataSensitivityTranslator(sensitivity)}/>);
}