import { useLocalization } from "@infrastructure";
import { PermissionsDefinition } from "../useDefinition";

export function usePermissionsDestinationEntityDefinition(): PermissionsDefinition {
    const localization =
        useLocalization(
            "views.customer.access.permissions.hooks.useDefinition.hooks.usePermissionsDestinationEntityDefinition",
            () => ({
                columns: {
                    destinationEntities: "Resource",
                    sourceEntities: "Identities"
                }
            }));

    return {
        columns: {
            destinationEntities: localization.columns.destinationEntities(),
            sourceEntities: localization.columns.sourceEntities()
        },
        getPermissionsModelEntityId: permissionsModel => permissionsModel.destinationEntityTopIds[0]
    };
}