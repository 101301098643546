﻿import { ActionMenuItem, Dialog, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { Fragment, memo, useCallback, useMemo, useState } from "react";
import { Contract, CustomerConsoleAppUrlHelper, ScopeHelper, ScopeNavigationView, scopeNodeModelStore } from "../../../../../../common";
import { AddOrEditFolder, Table } from "../../components";
import { useAddOrEditDefinition } from "../../hooks";
import { useScopesContext, useSetScopesContext } from "../../Scopes";
import { ScopesView } from "../../utilities";

const ProjectsMemo = memo(Projects);
export { ProjectsMemo as Projects };

function Projects() {
    const scopeNodeModels = scopeNodeModelStore.useGetAll();
    const { addOrEditOpen } = useScopesContext();
    const addOrEditDefinition = useAddOrEditDefinition(Contract.ScopeType.Project);
    const setScopesContext = useSetScopesContext();
    const rootFolderScopeNodeModel =
        useMemo(
            () =>
                _.find(
                    scopeNodeModels,
                    scopeNodeModel =>
                        ScopeHelper.isRootFolder(scopeNodeModel) &&
                        (scopeNodeModel.configuration as Contract.ProjectFolderConfiguration).typeName == Contract.TypeNames.ProjectFolderConfiguration)!,
            [scopeNodeModels]);

    const [parentFolderId, setParentFolderId] = useState<string>(() => rootFolderScopeNodeModel.configuration.id);
    const localization =
        useLocalization(
            "views.customer.scopes.projects",
            () => ({
                actions: {
                    add: {
                        folder: "Add folder",
                        project: "Add project"
                    }
                }
            }));
    const menuItems =
        useMemo(
            () =>
                ([
                    new ActionMenuItem(
                        () =>
                            setScopesContext(
                                context => ({
                                    ...context,
                                    addOrEditOpen: "folder"
                                })),
                        localization.actions.add.folder()),
                    new ActionMenuItem(
                        () =>
                            setScopesContext(
                                context => ({
                                    ...context,
                                    addOrEditOpen: "scope"
                                })),
                        localization.actions.add.project()
                    )]),
            [localization]);

    const onEditClick =
        useCallback(
            (scopeNodeModel: Contract.ScopeNodeModel) => {
                setScopesContext(
                    context => ({
                        ...context,
                        addOrEditOpen: scopeNodeModel
                    }));
            },
            [setScopesContext]);

    const onCloseAddOrEdit =
        useCallback(
            () => {
                setScopesContext(
                    context => ({
                        ...context,
                        addOrEditOpen: false
                    }));
            },
            [setScopesContext]);

    const onScopeChanged =
        useCallback(
            (scopeNodeModel: Contract.ScopeNodeModel) => {
                setParentFolderId(scopeNodeModel.configuration.id);
            },
            []);

    const addOrEditProjectOpen =
        addOrEditOpen === "scope" ||
        (addOrEditOpen as Contract.ScopeNodeModel).type === Contract.ScopeType.Project;

    return (
        <Fragment>
            {addOrEditOpen !== false && (
                !_.isNil(addOrEditDefinition.element) && addOrEditProjectOpen
                    ? <Dialog
                        size={addOrEditDefinition.dialogSize}
                        variant="editor"
                        onClose={onCloseAddOrEdit}>
                        <addOrEditDefinition.element
                            parentFolderId={parentFolderId}
                            scopeNodeModel={
                                addOrEditOpen === "scope"
                                    ? undefined
                                    : addOrEditOpen as Contract.ScopeNodeModel}
                            onClose={onCloseAddOrEdit}/>
                    </Dialog>
                    : <Dialog
                        variant="editor"
                        onClose={onCloseAddOrEdit}>
                        <AddOrEditFolder
                            folderScopeNodeModel={
                                addOrEditOpen === "folder"
                                    ? undefined
                                    : addOrEditOpen as Contract.ScopeNodeModel}
                            parentFolderId={parentFolderId}
                            onSave={onCloseAddOrEdit}/>
                    </Dialog>)}
            <ScopeNavigationView
                identityPermissions={[Contract.IdentityPermission.SecurityAdministrationRead]}
                layout="view"
                rootFolderId={rootFolderScopeNodeModel.configuration.id}
                search={false}
                templatePath={`${CustomerConsoleAppUrlHelper.getScopesRelativeUrl(ScopesView.Project)}/{scopeId}`}
                variant="folders"
                onScopeChanged={onScopeChanged}>
                {scopeNodeModel =>
                    <Table
                        key={scopeNodeModel.configuration.id}
                        menuItems={menuItems}
                        onEditClick={onEditClick}/>}
            </ScopeNavigationView>
        </Fragment>);
}