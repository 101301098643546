import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function OutboundIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path d="M5.33333 15.2796C7.17428 15.2796 8.66667 13.7872 8.66667 11.9463C8.66667 10.1053 7.17428 8.61296 5.33333 8.61296C3.49238 8.61296 2 10.1053 2 11.9463C2 13.7872 3.49238 15.2796 5.33333 15.2796Z"/>
            <path
                clipRule="evenodd"
                d="M20 11.7786C20 12.2389 19.6269 12.612 19.1667 12.612H10.8333C10.3731 12.612 10 12.2389 10 11.7786C10 11.3184 10.3731 10.9453 10.8333 10.9453H19.1667C19.6269 10.9453 20 11.3184 20 11.7786Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M19.3715 9.98864L21.9054 11.7672C22.0089 11.8399 22.0309 11.9785 21.9544 12.0769C21.9406 12.0946 21.9241 12.1103 21.9054 12.1234L19.3715 13.902C19.268 13.9746 19.1221 13.9538 19.0456 13.8554C19.016 13.8174 19 13.7712 19 13.7239V10.1667C19 10.0444 19.1043 9.94531 19.233 9.94531C19.2829 9.94531 19.3314 9.9605 19.3715 9.98864Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M19.6747 4.32832C19.8637 4.74795 19.6768 5.24136 19.2572 5.43039L11.2635 9.03135C10.8439 9.22038 10.3505 9.03344 10.1614 8.61382C9.9724 8.19419 10.1593 7.70078 10.579 7.51175L18.5726 3.91079C18.9923 3.72176 19.4857 3.9087 19.6747 4.32832Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M18.3592 3.00534L21.3846 3.66159C21.5082 3.6884 21.5822 3.80768 21.5499 3.928C21.5441 3.9497 21.5349 3.97055 21.5228 3.98987L19.8774 6.61217C19.8102 6.71931 19.6676 6.75672 19.5589 6.69574C19.5169 6.67212 19.4842 6.63582 19.4659 6.59217L18.0859 3.31362C18.0384 3.20091 18.0961 3.06907 18.2148 3.01913C18.2607 2.99979 18.3113 2.99496 18.3592 3.00534Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M18.3592 20.7933L21.3846 20.137C21.5082 20.1102 21.5822 19.9909 21.5499 19.8706C21.5441 19.8489 21.5349 19.828 21.5228 19.8087L19.8774 17.1864C19.8102 17.0793 19.6676 17.0419 19.5589 17.1029C19.5169 17.1265 19.4842 17.1628 19.4659 17.2064L18.0859 20.485C18.0384 20.5977 18.0961 20.7295 18.2148 20.7795C18.2607 20.7988 18.3113 20.8036 18.3592 20.7933Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M19.6747 19.4716C19.8637 19.052 19.6768 18.5586 19.2572 18.3696L11.2635 14.7686C10.8439 14.5796 10.3505 14.7665 10.1614 15.1861C9.9724 15.6058 10.1593 16.0992 10.579 16.2882L18.5726 19.8892C18.9923 20.0782 19.4857 19.8913 19.6747 19.4716Z"
                fillRule="evenodd"/>
        </SvgIcon>);
}