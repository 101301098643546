﻿import { InlineItems, Step, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, InlineVulnerability, TypeHelper, useSeverityTranslator, useTheme } from "../../../../../../../../../../../../../../common";

export function useInstanceVulnerabilityStep(riskModel: Contract.AwsInboundExternalEc2InstanceRiskModel) {
    const severityTranslator = useSeverityTranslator();
    const vulnerabilitiesData =
        _(riskModel.risk.instanceIdToDataMap).
            map(instanceData => instanceData.vulnerabilities!).
            filter().
            groupBy(vulnerabilities => vulnerabilities.severity).
            map(
                (vulnerabilitiesList, severity) => ({
                    rawIds:
                        _(vulnerabilitiesList).
                            flatMap(vulnerabilities => vulnerabilities.rawIds).
                            uniq().
                            sort().
                            value(),
                    severity: TypeHelper.tryParseEnum<Contract.Severity>(Contract.TypeNames.Severity, severity)!
                })).
            maxBy(({ severity }) =>
                TypeHelper.getEnumValue(
                    Contract.TypeNames.Severity,
                    severity));

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsInboundExternalEc2InstanceRiskDefinition.hooks.useInstanceVulnerabilityStep",
            () => ({
                vulnerabilities: {
                    text: [
                        "The EC2 instance contains {{vulnerabilitySeverity}}",
                        "The EC2 instances contains {{vulnerabilitySeverity}}"
                    ],
                    vulnerabilitySeverity: [
                        "1 {{severity}} vulnerability",
                        "{{count | NumberFormatter.humanize}} {{severity}} vulnerabilities"
                    ]
                }
            }));
    const theme = useTheme();
    return _.isNil(vulnerabilitiesData)
        ? undefined
        : new Step(
            localization.vulnerabilities.text(
                riskModel.risk.aggregatedEntityIds.length,
                {
                    vulnerabilitySeverity:
                        <InlineItems
                            items={
                                _(vulnerabilitiesData.rawIds).
                                    map(
                                        vulnerabilityRawId =>
                                            <InlineVulnerability
                                                key={vulnerabilityRawId}
                                                rawId={vulnerabilityRawId}/>).
                                    value()}
                            namePluralizer={count => localization.vulnerabilities.vulnerabilitySeverity(count, { severity: severityTranslator(vulnerabilitiesData.severity, "text") })}
                            sx={{ color: theme.palette.text.primary }}
                            variant="itemCountAndType"/>
                }));
}