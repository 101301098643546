import { DataTable, DataTableColumn, EmptyMessageText, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useAzureCommonManagedIdentityOriginatorResourceInfoElements, useAzureDefaultResourceInfoItemElements } from ".";
import { useCommonEntityNetworkInfoItemElements, useCustomEntityPropertyInfoItemElements } from "..";
import { Contract, InfoCard, InfoItem } from "../../../../../../../../../../common";
import { useAzureMySqlServerAuthenticationTypeTranslator, useAzureMySqlServerStatusTranslator } from "../../../../../../hooks";
import { EntitiesInfoItem, Info } from "../../../../components";
import { AzureFirewallInfoCard, AzureResourceRoleAssignmentResources, NetworkingInfoCard } from "../../components";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../useDefinition";

export function useAzureMySqlServerDefinition(resourceGroupResourceModel: Contract.AzureResourceGroupResourceModel, options?: DefinitionOptions) {
    const commonEntityNetworkInfoItemElements = useCommonEntityNetworkInfoItemElements(resourceGroupResourceModel);
    const commonManagedIdentityOriginatorResourceInfoElements = useAzureCommonManagedIdentityOriginatorResourceInfoElements(resourceGroupResourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceGroupResourceModel);
    const defaultResourceInfoItemElements = useAzureDefaultResourceInfoItemElements(resourceGroupResourceModel);
    const server = resourceGroupResourceModel.entity as Contract.AzureMySqlServer;
    const serverModel = resourceGroupResourceModel as Contract.AzureMySqlServerModel;

    const mySqlServerAuthenticationTypeTranslator = useAzureMySqlServerAuthenticationTypeTranslator();
    const mySqlServerStatusTranslator = useAzureMySqlServerStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.azure.useAzureMySqlServerDefinition",
            () => ({
                empty: "No server parameters",
                info: {
                    items: {
                        aadAuthenticationAdministratorPrincipalIdReference: "Microsoft Entra ID Admin",
                        administratorUserName: "Server Admin",
                        allowAnyAzureInternalIpAddress: {
                            false: "Disabled",
                            title: "Allow access to Azure services",
                            true: "Enabled"
                        },
                        authenticationType: "Authentication Method",
                        databases: {
                            columns: {
                                name: "Name",
                                userSchema: {
                                    false: "System",
                                    title: "Schema type",
                                    true: "User"
                                }
                            },
                            title: "Databases"
                        },
                        endpoint: "Server",
                        eventEnabled: {
                            false: "Disabled",
                            title: "Auditing",
                            true: "Enabled"
                        },
                        httpsOnly: {
                            false: "Disabled",
                            title: "Enforce SSL Connection",
                            true: "Enabled"
                        },
                        publicNetworkAccess: {
                            false: "Enabled",
                            title: "Deny Public Network Access",
                            true: "Disabled"
                        },
                        serverConfigurations: {
                            description: "Description",
                            name: "Name",
                            title: "Server Parameters",
                            type: "Type",
                            value: "Value"
                        },
                        skuTier: "SkuTier",
                        status: "Status",
                        tlsMinVersion: "Minimum TLS Version",
                        version: "Version"
                    }
                },
                tabs: {
                    resourceRoleAssignments: "Resource Role Assignments"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <AzureResourceRoleAssignmentResources
                    csvExportFilePrefix={localization.tabs.resourceRoleAssignments()}
                    scopeResourceModel={resourceGroupResourceModel}/>,
                localization.tabs.resourceRoleAssignments(),
                "resourceRoleAssignments")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    ...commonManagedIdentityOriginatorResourceInfoElements,
                    <InfoItem
                        key="status"
                        title={localization.info.items.status()}
                        value={mySqlServerStatusTranslator(server.status)}/>,
                    <InfoItem
                        key="endpoint"
                        title={localization.info.items.endpoint()}
                        value={server.endpoint}/>,
                    <InfoItem
                        key="administratorUserName"
                        title={localization.info.items.administratorUserName()}
                        value={server.administratorUserName}/>,
                    <InfoItem
                        key="version"
                        title={localization.info.items.version()}
                        value={server.version}/>,
                    <InfoItem
                        key="skuTier"
                        title={localization.info.items.skuTier()}
                        value={server.skuTier}/>,
                    <InfoItem
                        key="httpsOnly"
                        title={localization.info.items.httpsOnly.title()}
                        value={
                            _.isNil(server.httpsOnly)
                                ? undefined
                                : server.httpsOnly
                                    ? localization.info.items.httpsOnly.true()
                                    : localization.info.items.httpsOnly.false()}/>,
                    <InfoItem
                        key="tlsMinVersion"
                        title={localization.info.items.tlsMinVersion()}
                        value={server.tlsMinVersion}/>,
                    <InfoItem
                        key="authenticationType"
                        title={localization.info.items.authenticationType()}
                        value={mySqlServerAuthenticationTypeTranslator(server.authenticationType)}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={server.aadAuthenticationAdministratorPrincipalIdReference}
                        entityTypeName={Contract.TypeNames.AadDirectoryPrincipal}
                        key="aadAuthenticationAdministratorPrincipalIdReference"
                        title={localization.info.items.aadAuthenticationAdministratorPrincipalIdReference()}/>,
                    <InfoItem
                        key="publicNetworkAccess"
                        title={localization.info.items.publicNetworkAccess.title()}
                        value={
                            server.publicNetworkAccess
                                ? localization.info.items.publicNetworkAccess.true()
                                : localization.info.items.publicNetworkAccess.false()}/>,
                    <InfoItem
                        key="allowAnyAzureInternalIpAddress"
                        title={localization.info.items.allowAnyAzureInternalIpAddress.title()}
                        value={
                            _.isNil(server.publicNetworkAccess)
                                ? undefined
                                : server.allowAnyAzureInternalIpAddress
                                    ? localization.info.items.allowAnyAzureInternalIpAddress.true()
                                    : localization.info.items.allowAnyAzureInternalIpAddress.false()}/>
                ]}
                options={options?.infoOptions}>
                <NetworkingInfoCard>
                    {commonEntityNetworkInfoItemElements.inboundAccessType}
                    {commonEntityNetworkInfoItemElements.inboundExternalAccessScope}
                </NetworkingInfoCard>
                {server.publicNetworkAccess &&
                    <AzureFirewallInfoCard
                        networkResourcePathToIdReferencesMap={serverModel.networkResourcePathToIdReferencesMap}
                        rules={server.firewallIpAddressRangeRules}
                        virtualNetworkPathToFirewallSubnetRulesMap={server.virtualNetworkPathToFirewallSubnetRulesMap}/>}
                {!_.isEmpty(_.values(server.nameToConfigurationMap)) &&
                    <InfoCard title={localization.info.items.serverConfigurations.title()}>
                        <DataTable
                            emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty()) }}
                            fetchItems={() => _.values(server.nameToConfigurationMap)}
                            getItemId={(item: Contract.AzureMySqlServerConfiguration) => item.name}
                            sortOptions={{ enabled: false }}
                            variant="card">
                            <DataTableColumn
                                id={ServerConfigurationsInfoCardTableColumnId.Name}
                                itemProperty={(item: Contract.AzureMySqlServerConfiguration) => item.name}
                                title={localization.info.items.serverConfigurations.name()}/>
                            <DataTableColumn
                                id={ServerConfigurationsInfoCardTableColumnId.Value}
                                itemProperty={(item: Contract.AzureMySqlServerConfiguration) => item.value}
                                title={localization.info.items.serverConfigurations.value()}/>
                            <DataTableColumn
                                id={ServerConfigurationsInfoCardTableColumnId.Type}
                                itemProperty={(item: Contract.AzureMySqlServerConfiguration) => item.dynamic}
                                title={localization.info.items.serverConfigurations.type()}/>
                            <DataTableColumn
                                id={ServerConfigurationsInfoCardTableColumnId.Description}
                                itemProperty={(item: Contract.AzureMySqlServerConfiguration) => item.description}
                                title={localization.info.items.serverConfigurations.description()}/>
                        </DataTable>
                    </InfoCard>}
                {server.typeName === Contract.TypeNames.AzureMySqlFlexibleServer &&
                    <InfoCard title={localization.info.items.databases.title()}>
                        <DataTable
                            fetchItems={() => (server as Contract.AzureMySqlFlexibleServer).databaseNames}
                            getItemId={(item: string) => item}
                            sortOptions={{ enabled: false }}
                            variant="card">
                            <DataTableColumn
                                id={ServerDatabasesInfoCardTableColumnId.Name}
                                itemProperty={(item: string) => item}
                                title={localization.info.items.databases.columns.name()}/>
                            <DataTableColumn
                                id={ServerDatabasesInfoCardTableColumnId.UserSchema}
                                itemProperty={
                                    (item: string) =>
                                        (server as Contract.AzureMySqlFlexibleServer).databaseNameToUserSchemaMap[item]
                                            ? localization.info.items.databases.columns.userSchema.true()
                                            : localization.info.items.databases.columns.userSchema.false()}
                                title={localization.info.items.databases.columns.userSchema.title()}/>
                        </DataTable>
                    </InfoCard>}
            </Info>
    });
}

enum ServerConfigurationsInfoCardTableColumnId {
    Description = "Description",
    Name = "Name",
    Type = "Type",
    Value = "Value"
}

enum ServerDatabasesInfoCardTableColumnId {
    Name = "name",
    UserSchema = "userSchema"
}