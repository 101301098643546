﻿import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function OpIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path d="M5 15.5C5 14.9477 5.44772 14.5 6 14.5H11C11.5523 14.5 12 14.9477 12 15.5C12 16.0523 11.5523 16.5 11 16.5H6C5.44772 16.5 5 16.0523 5 15.5Z"/>
            <path d="M16 15C16 14.4477 15.5523 14 15 14C14.4477 14 14 14.4477 14 15V16C14 16.5523 14.4477 17 15 17C15.5523 17 16 16.5523 16 16V15Z"/>
            <path d="M18 14C18.5523 14 19 14.4477 19 15V16C19 16.5523 18.5523 17 18 17C17.4477 17 17 16.5523 17 16V15C17 14.4477 17.4477 14 18 14Z"/>
            <path d="M6 7.5C5.44772 7.5 5 7.94772 5 8.5C5 9.05228 5.44772 9.5 6 9.5H11C11.5523 9.5 12 9.05228 12 8.5C12 7.94772 11.5523 7.5 11 7.5H6Z"/>
            <path d="M15 7C15.5523 7 16 7.44772 16 8V9C16 9.55228 15.5523 10 15 10C14.4477 10 14 9.55228 14 9V8C14 7.44772 14.4477 7 15 7Z"/>
            <path d="M19 8C19 7.44772 18.5523 7 18 7C17.4477 7 17 7.44772 17 8V9C17 9.55228 17.4477 10 18 10C18.5523 10 19 9.55228 19 9V8Z"/>
            <path
                clipRule="evenodd"
                d="M0 6C0 4.34315 1.34315 3 3 3H21C22.6569 3 24 4.34315 24 6V18C24 19.6569 22.6569 21 21 21H3C1.34314 21 0 19.6569 0 18V6ZM3 5C2.44772 5 2 5.44772 2 6V18C2 18.5523 2.44772 19 3 19H21C21.5523 19 22 18.5523 22 18V6C22 5.44771 21.5523 5 21 5H3Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M22 13H2V11H22V13Z"
                fillRule="evenodd"/>
        </SvgIcon>);
}