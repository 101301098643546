﻿import { Link, useLocalization } from "@infrastructure";
import React from "react";
import { Contract } from "../../../../../../../../../../../../../../../../common";

export function useSpotInstanceGroupResolutionSteps(riskModel: Contract.AwsEc2InstanceMetadataServiceTokenHopMaxCountRiskModel) {
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsEc2InstanceMetadataServiceTokenHopMaxCountRiskDefinition.hooks.useAwsEc2InstanceMetadataServiceTokenHopMaxCountRiskTagResourceGroupDefinition.hooks.useSpotInstanceGroupResolutionSteps",
            () => ({
                step1: "Setting the hop limit to {{maxTokenHopMaxCount}} can potentially restrict network communications beyond the immediate local network or subnet, which may affect certain network configurations or applications relying on multiple hops for communication. Careful consideration should be given to the specific networking requirements and potential impacts before configuring such restrictions",
                step2: {
                    documentationLink: "documentation",
                    text: "In Spot, update the hop limit to {{maxTokenHopMaxCount}} using the following **{{documentationLink}}**"
                }
            }));

    return [
        localization.step1({ maxTokenHopMaxCount: riskModel.risk.maxTokenHopMaxCount }),
        localization.step2.text({
            documentationLink:
                <Link
                    sx={{ textDecorationLine: "underline" }}
                    urlOrGetUrl={riskModel.spotDocumentationUrl}
                    variant="external">
                    {localization.step2.documentationLink()}
                </Link>,
            maxTokenHopMaxCount: riskModel.risk.maxTokenHopMaxCount
        })
    ];
}