import { DataTableColumnRenderProps, InlineItems, useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract, Scope, useScopeNavigationViewContext } from "../../../../../../../common";

export function ScopesCell({ item }: DataTableColumnRenderProps<Contract.PermissionAssignmentEligibilityModel>) {
    const { scopeNodeModel } = useScopeNavigationViewContext();
    const localization =
        useLocalization(
            "views.user.permissionEligibilities.table.scopesCell",
            () => ({
                all: "All existing and future",
                some: [
                    "{{scopes}} Scope",
                    "{{scopes}} Scopes"
                ]
            }));

    return (
        <Typography noWrap={true}>
            {_.isEmpty(item.permissionRequestScopeIds) ||
            item.permissionRequestScopeIds[0] === item.configuration.scopeId ||
            item.permissionRequestScopeIds[0] === scopeNodeModel.configuration.id
                ? localization.all()
                : localization.some(
                    item.permissionRequestScopeIds.length,
                    {
                        scopes:
                            <InlineItems
                                items={item.permissionRequestScopeIds}
                                sx={{
                                    color: "unset",
                                    fontWeight: "unset",
                                    textDecoration: "underline"
                                }}
                                variant="itemCount">
                                {scopeId =>
                                    <Typography noWrap={true}>
                                        <Scope
                                            scopeId={scopeId}
                                            scopeNameTranslatorOptions={{ path: true }}
                                            variant="text"/>
                                    </Typography>}
                            </InlineItems>
                    })}
        </Typography>);
}