import { CheckboxField, DeleteIcon, EmptyMessage, EmptyMessageText, SearchList, SearchListSelectors, StringHelper, TimeFormatter, Tooltip, useLocalization, useUncaptureValue } from "@infrastructure";
import { IconButton, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { useMemo, useState } from "react";
import { CommentIcon, Contract, EmptyCommentIcon, Entity, entityModelStore, entitySearchableReferenceStore, InlineScopes, useTheme } from "../../../../../../../../../../../common";
import { ExpirationIcon } from "../../../../../../../icons";

type ListProps = {
    emptyMessageText: EmptyMessageText;
    entityIds: string[];
    entityIdToExclusionMap?: _.Dictionary<Contract.RiskPolicyConfigurationExclusionData>;
    entityIdToScopeIdsMap?: Dictionary<string[]>;
    entityIdToSearchableReferenceMap: Dictionary<Contract.EntitySearchableReference>;
    onSelectedEntityIdsChanged: (entityIds: string[]) => void;
    onSelectedEntityIdToExclusionChanged?: (selectedEntityIdToMessageMap: _.Dictionary<Contract.RiskPolicyConfigurationExclusionData>) => void;
    readOnly: boolean;
    selectedEntityIds: string[];
    title: string;
};

export function List({ emptyMessageText, entityIds, entityIdToExclusionMap, entityIdToScopeIdsMap = {}, entityIdToSearchableReferenceMap, onSelectedEntityIdsChanged, onSelectedEntityIdToExclusionChanged, readOnly, selectedEntityIds, title }: ListProps) {
    const [visibleMessageEntityIds, setVisibleMessageEntityIds] = useState<string[]>([]);
    const missingEntitySearchableReferences =
        entitySearchableReferenceStore.useGet(
            _.difference(
                entityIds,
                _.keys(entityIdToSearchableReferenceMap)));

    const [missingEntitySearchableReferenceMap, orderedEntityIds] =
        useMemo(
            () => {
                const missingEntitySearchableReferenceMap =
                    _.keyBy(
                        missingEntitySearchableReferences,
                        missingEntitySearchableReference => missingEntitySearchableReference.id);
                return [
                    missingEntitySearchableReferenceMap,
                    _(entityIdToScopeIdsMap).
                        keys().
                        union(entityIds).
                        orderBy([
                            entityId => !_.isEmpty(entityIdToScopeIdsMap[entityId]),
                            entityId => StringHelper.getSortValue(entityIdToSearchableReferenceMap[entityId]?.displayName ?? missingEntitySearchableReferenceMap[entityId].displayName)
                        ]).
                        value()];
            },
            [entityIdToScopeIdsMap, entityIdToSearchableReferenceMap, missingEntitySearchableReferences]);

    const localization =
        useLocalization(
            "views.customer.riskPolicies.configuration.entityExclusion.entityTypeName.list",
            () => ({
                expirationDate: "Excluded until {{expirationDate}}",
                inherited: "Inherited from",
                message: "Comment"
            }));
    const uncaptureSelectedEntityIds = useUncaptureValue(selectedEntityIds);
    const theme = useTheme();
    return (
        <Stack
            sx={{
                backgroundColor: theme.palette.background.alternate,
                border: theme.border.alternate,
                borderRadius: theme.spacing(0.75),
                height: "100%"
            }}>
            <Typography
                sx={{ padding: theme.spacing(1.5) }}
                variant="h4">
                {title}
            </Typography>
            <Stack
                sx={{
                    flex: 1,
                    overflow: "hidden"
                }}>
                {_.isEmpty(orderedEntityIds)
                    ? <EmptyMessage
                        message={emptyMessageText.getText(false)}
                        verticalCenter={true}/>
                    : <SearchList
                        dense={true}
                        dependencies={[entityIdToExclusionMap, selectedEntityIds, visibleMessageEntityIds]}
                        emptyMessageText={emptyMessageText}
                        headerElement={
                            !readOnly && (
                                <SearchListSelectors
                                    items={entityIds}
                                    selectedItems={selectedEntityIds}
                                    onSelectedItemsChanged={selectedExclusionEntityIds => onSelectedEntityIdsChanged(selectedExclusionEntityIds ?? [])}/>)}
                        itemOptions={{
                            getDependencies: (entityId: string) => [_.includes(selectedEntityIds, entityId), entityIdToExclusionMap, visibleMessageEntityIds],
                            getSortValue: (entityId: string) => entityIdToSearchableReferenceMap[entityId]?.displayName.toLowerCase() ?? missingEntitySearchableReferenceMap[entityId].displayName.toLowerCase(),
                            render:
                                (entityId: string) =>
                                    <Stack
                                        direction="row"
                                        spacing={2}
                                        sx={{ width: "100%" }}>
                                        <Stack
                                            sx={{
                                                flex: 1,
                                                overflow: "hidden"
                                            }}>
                                            {readOnly
                                                ? <Entity
                                                    entityIdOrModel={entityId}
                                                    linkOptions={{ disabled: true }}
                                                    variant="iconTextTypeTenant"/>
                                                : <CheckboxField
                                                    checked={_.includes(selectedEntityIds, entityId)}
                                                    disabled={!_.isEmpty(entityIdToScopeIdsMap[entityId])}
                                                    onChange={
                                                        (event, checked) =>
                                                            uncaptureSelectedEntityIds(
                                                                selectedEntityIds =>
                                                                    onSelectedEntityIdsChanged(
                                                                        checked
                                                                            ? _.concat(selectedEntityIds, entityId)
                                                                            : _.without(selectedEntityIds, entityId)))}>
                                                    <Entity
                                                        entityIdOrModel={entityId}
                                                        linkOptions={{ disabled: true }}
                                                        variant="iconTextTypeTenant"/>
                                                </CheckboxField>}
                                            {!_.isNil(entityIdToExclusionMap) &&
                                                _.includes(visibleMessageEntityIds, entityId) &&
                                                <TextField
                                                    fullWidth={true}
                                                    maxRows={2}
                                                    multiline={true}
                                                    placeholder={localization.message()}
                                                    slotProps={{
                                                        input: {
                                                            endAdornment:
                                                                !readOnly
                                                                    ? <InputAdornment
                                                                        position="end"
                                                                        sx={{
                                                                            cursor: "pointer",
                                                                            fontSize: "18px"
                                                                        }}
                                                                        onClick={
                                                                            () => {
                                                                                setVisibleMessageEntityIds(
                                                                                    _.without(
                                                                                        visibleMessageEntityIds,
                                                                                        entityId));

                                                                                return onSelectedEntityIdToExclusionChanged?.({
                                                                                    ...entityIdToExclusionMap,
                                                                                    [entityId]:
                                                                                        new Contract.RiskPolicyConfigurationExclusionData(
                                                                                            entityIdToExclusionMap[entityId].expirationDate,
                                                                                            undefined,
                                                                                            entityIdToExclusionMap[entityId].reason)
                                                                                });
                                                                            }}>
                                                                        <DeleteIcon/>
                                                                    </InputAdornment>
                                                                    : undefined,
                                                            readOnly
                                                        }
                                                    }}
                                                    sx={{ paddingTop: 1 }}
                                                    value={entityIdToExclusionMap[entityId]?.message ?? ""}
                                                    variant="outlined"
                                                    onChange={
                                                        event =>
                                                            onSelectedEntityIdToExclusionChanged?.({
                                                                ...entityIdToExclusionMap,
                                                                [entityId]:
                                                                    new Contract.RiskPolicyConfigurationExclusionData(
                                                                        entityIdToExclusionMap[entityId].expirationDate,
                                                                        _.isEmpty(event.target.value)
                                                                            ? undefined
                                                                            : event.target.value,
                                                                        entityIdToExclusionMap[entityId].reason)
                                                            })}/>}
                                        </Stack>
                                        {!_.isEmpty(entityIdToScopeIdsMap[entityId]) &&
                                            <Stack>
                                                <Typography>
                                                    {localization.inherited()}
                                                </Typography>
                                                <InlineScopes
                                                    scopeIds={entityIdToScopeIdsMap[entityId]}
                                                    scopeVariant="iconText"
                                                    variant="itemCountAndType"/>
                                            </Stack>}
                                        {!_.isNil(entityIdToExclusionMap) &&
                                            !_.isNil(entityIdToExclusionMap?.[entityId]?.expirationDate) &&
                                            <Tooltip titleOrGetTitle={localization.expirationDate({ expirationDate: TimeFormatter.mediumDateShortTime(entityIdToExclusionMap[entityId].expirationDate) })}>
                                                <IconButton size="large">
                                                    <ExpirationIcon/>
                                                </IconButton>
                                            </Tooltip>}
                                        {!_.isNil(entityIdToExclusionMap) &&
                                            _.isEmpty(entityIdToScopeIdsMap[entityId]) &&
                                            <IconButton
                                                size="large"
                                                sx={{
                                                    color:
                                                        _.isEmpty(entityIdToExclusionMap?.[entityId])
                                                            ? theme.palette.text.secondary
                                                            : theme.palette.text.primary
                                                }}
                                                onClick={
                                                    () =>
                                                        setVisibleMessageEntityIds(
                                                            _.includes(
                                                                visibleMessageEntityIds,
                                                                entityId)
                                                                ? _.without(visibleMessageEntityIds, entityId)
                                                                : _.concat(visibleMessageEntityIds, entityId))}>
                                                {_.isNil(entityIdToExclusionMap[entityId]?.message)
                                                    ? <EmptyCommentIcon color="inherit"/>
                                                    : <CommentIcon color="inherit"/>}
                                            </IconButton>}
                                    </Stack>,
                            spacing: 1
                        }}
                        items={orderedEntityIds}
                        searchOptions={{
                            containerSx: {
                                padding: theme.spacing(0, 1.5)
                            },
                            inputSx: {
                                backgroundColor: theme.palette.background.paper,
                                border: theme.border.primary
                            },
                            itemMinCount: 0
                        }}
                        sorted={false}
                        sx={{ padding: theme.spacing(0, 2) }}
                        variant="verticalFill"
                        onItemPageLoading={
                            async (entityIds: string[]) => {
                                await entityModelStore.get(entityIds);
                            }}/>}
            </Stack>
        </Stack>);
}