import { UnexpectedError } from "@infrastructure";
import { SvgIconProps } from "@mui/material";
import React from "react";
import { Contract } from "../../controllers";
import { ClosedIcon } from "../ClosedIcon";
import { IgnoredIcon, OpenIcon } from "./icons";

type RiskStatusIconProps =
    SvgIconProps & {
        status: Contract.RiskStatus;
    };

export function RiskStatusIcon({ status, ...props }: RiskStatusIconProps) {
    switch (status) {
        case Contract.RiskStatus.Closed:
            return <ClosedIcon {...props}/>;
        case Contract.RiskStatus.Ignored:
            return <IgnoredIcon {...props}/>;
        case Contract.RiskStatus.Open:
            return <OpenIcon {...props}/>;
        default:
            throw new UnexpectedError("status", status);
    }
}