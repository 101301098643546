import { DataTableColumn, EnumValuesFilter, optionalTableCell, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useAzureCommonResourceGroupResourceDefinition } from ".";
import { Contract, ElasticsearchItemPageHelper, InlineEntities, PagedEntityFilter, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { useAzureComputeDiskResourceNetworkAccessPolicyTypeTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";

export function useAzureCommonComputeDiskResourceDefinition(definitionData: DefinitionData) {
    const commonResourceGroupResourceDefinition = useAzureCommonResourceGroupResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const computeDiskResourceNetworkAccessPolicyTypeTranslator = useAzureComputeDiskResourceNetworkAccessPolicyTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.azure.useAzureCommonComputeDiskResourceDefinition",
            () => ({
                columns: {
                    networkAccessPolicyType: "Network Access",
                    sourceResourceSearchableIdReference: "Source"
                }
            }));

    return {
        columns: {
            ...commonResourceGroupResourceDefinition.columns,
            diskResourceSourceColumn:
                <DataTableColumn
                    exportOptions={
                        entitiesExportOptions<Contract.AzureComputeDiskResourceModel>(
                            Contract.TypeNames.AzureComputeDiskResource,
                            item => [item.sourceResourceSearchableIdReference?.idReference],
                            localization.columns.sourceResourceSearchableIdReference())}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedEntityFilter
                                    getEntityIdPage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.AzureComputeDiskResourceSource)}
                                    placeholder={localization.columns.sourceResourceSearchableIdReference()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AzureComputeDiskResourceSource}
                    key={Contract.EntityModelProperty.AzureComputeDiskResourceSource}
                    render={
                        optionalTableCell<Contract.AzureComputeDiskResourceModel>(
                            item =>
                                _.isNil(item.sourceResourceSearchableIdReference)
                                    ? undefined
                                    : <InlineEntities
                                        entityIdsOrModels={item.sourceResourceSearchableIdReference.idReference}
                                        entityTypeName={Contract.TypeNames.AzureComputeDiskResource}
                                        entityVariant="iconText"
                                        variant="itemPlusItemCount"/>)}
                    title={localization.columns.sourceResourceSearchableIdReference()}/>,
            networkAccessPolicyType:
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AzureComputeDiskResourceModel) => ({
                                [localization.columns.networkAccessPolicyType()]:
                                    computeDiskResourceNetworkAccessPolicyTypeTranslator((item.entity as Contract.AzureComputeDiskResource).networkAccessPolicyType)
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <EnumValuesFilter
                                    enumType={Contract.AzureComputeDiskResourceNetworkAccessPolicyType}
                                    enumTypeTranslator={computeDiskResourceNetworkAccessPolicyTypeTranslator}
                                    placeholder={localization.columns.networkAccessPolicyType()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AzureComputeDiskResourceNetworkAccessPolicyType}
                    itemProperty={(item: Contract.AzureComputeDiskResourceModel) => computeDiskResourceNetworkAccessPolicyTypeTranslator((item.entity as Contract.AzureComputeDiskResource).networkAccessPolicyType)}
                    key={Contract.EntityModelProperty.AzureComputeDiskResourceNetworkAccessPolicyType}
                    title={localization.columns.networkAccessPolicyType()}/>
        }
    };
}