﻿import { SvgIcon, SvgIconProps } from "@mui/material";
import React, { Fragment } from "react";
import { Contract, KubernetesIcon } from "../../../common";

type CodeTypeIconProps =
    SvgIconProps & {
        codeType: Contract.CodeType;
    };

export function CodeTypeIcon({ codeType, ...props }: CodeTypeIconProps) {
    switch (codeType) {
        case Contract.CodeType.CloudFormation:
            return (
                <SvgIcon {...props}>
                    <path
                        clipRule="evenodd"
                        d="M6.66238 6.40771L3.61523 7.09521V16.7177L6.66238 17.2577V6.40771Z"
                        fill="#759C3E"
                        fillRule="evenodd"/>
                    <path
                        clipRule="evenodd"
                        d="M4.1582 8.16762L12.0705 2.80762V7.24762L6.6602 8.49012L4.1582 8.16762Z"
                        fill="#B7CA9D"
                        fillRule="evenodd"/>
                    <path
                        clipRule="evenodd"
                        d="M17.334 7.24728L20.3323 7.09229L20.4146 16.1573L17.334 17.0523V7.24728Z"
                        fill="#4B612C"
                        fillRule="evenodd"/>
                    <path
                        clipRule="evenodd"
                        d="M12.0705 16.6629L6.6602 15.5129L4.1582 15.8329L12.0705 21.0504V16.6629Z"
                        fill="#3C4929"
                        fillRule="evenodd"/>
                    <path
                        clipRule="evenodd"
                        d="M19.8361 8.16757L11.9238 3.13257V7.25007L17.3341 8.49257L19.8361 8.16757Z"
                        fill="#B7CA9D"
                        fillRule="evenodd"/>
                    <path
                        clipRule="evenodd"
                        d="M11.9238 16.6629L17.3341 15.5129L19.8361 15.8329L12.001 21.2604L11.9238 16.6629Z"
                        fill="#3C4929"
                        fillRule="evenodd"/>
                    <path
                        clipRule="evenodd"
                        d="M12 22L3 17.855V6.145L12 2L14.2886 11.8625L12 22ZM9.35914 6.745L4.15971 8.17V15.8325L9.35914 17.2575V6.745Z"
                        fill="#4B612C"
                        fillRule="evenodd"/>
                    <path
                        clipRule="evenodd"
                        d="M12 2L21 6.145V17.8525L12 22V2ZM14.6511 17.255L19.8506 15.83V8.1675L14.6409 6.7425L14.6511 17.255Z"
                        fill="#759C3E"
                        fillRule="evenodd"/>
                </SvgIcon>);
        case Contract.CodeType.Terraform:
        case Contract.CodeType.TerraformPlan:
            return (
                <SvgIcon {...props}>
                    <path
                        d="M9.07267 5.4698L14.808 8.74683V15.1841L9.07267 11.907V5.4698ZM15.1124 15.1552L20.7978 11.8879V5.49227L15.1124 8.73709V15.1552ZM3 8.43725L8.7353 11.7143V5.27703L3 2V8.43725ZM9.07267 18.723L14.808 22V15.5403L9.07267 12.2632V18.723Z"
                        fill="#623CE4"/>
                    <path
                        d="M15.1124 15.155L20.7978 11.8877V5.49209L15.1124 8.73691V15.155Z"
                        fill="#3C2AA8"/>
                </SvgIcon>);
        case Contract.CodeType.Kubernetes:
            return <KubernetesIcon {...props}/>;
        default:
            return <Fragment/>;
    }
}