import { Dropdown, Loading, NoneIcon, useExecuteOperation, useLocalization } from "@infrastructure";
import { Box, Typography } from "@mui/material";
import React from "react";
import { Contract, Entities, EntityController, useTheme } from "../../../common";

type AwsTimestreamDatabaseTablesItemProps = {
    databaseId: string;
    tableCount: number;
};

export function AwsTimestreamDatabaseTablesItem({ databaseId, tableCount }: AwsTimestreamDatabaseTablesItemProps) {
    const localization =
        useLocalization(
            "tenants.aws.awsTimestreamDatabaseTablesItem",
            () => ({
                tables: [
                    "1 table",
                    "{{count | NumberFormatter.humanize}} tables"
                ]
            }));
    const theme = useTheme();
    return (
        <Dropdown
            disabled={tableCount === 0}
            popoverElement={
                <Loading container="popup">
                    <Box
                        sx={{
                            maxWidth: theme.spacing(60),
                            minWidth: theme.spacing(30),
                            padding: theme.spacing(1)
                        }}>
                        <Popover databaseId={databaseId}/>
                    </Box>
                </Loading>}
            popoverElementContainerSx={{ padding: 0 }}>
            <Typography
                sx={{
                    color: theme.palette.text.primary,
                    fontWeight: 600,
                    whiteSpace: "nowrap",
                    ...(tableCount === 0
                        ? undefined
                        : {
                            cursor: "pointer",
                            textDecoration: "underline"
                        })
                }}>
                {tableCount === 0
                    ? <NoneIcon/>
                    : localization.tables(tableCount)}
            </Typography>
        </Dropdown>);
}

type PopoverProps = {
    databaseId: string;
};

function Popover({ databaseId }: PopoverProps) {
    const [{ tableIds: tableIds }]: [Contract.EntityControllerGetAwsTimestreamDatabaseTableIdsResponse, () => Promise<void>] =
        useExecuteOperation(
            [Popover, databaseId],
            () => EntityController.getAwsTimestreamDatabaseTableIds(new Contract.EntityControllerGetAwsTimestreamDatabaseTableIdsRequest(databaseId)));
    return (
        <Entities
            entityIdsOrModels={tableIds}
            entityTypeName={Contract.TypeNames.AwsTimestreamDatabaseTable}
            entityVariant="iconTextTypeTenant"
            searchEnabled={true}
            variant="dropdown"/>);
}