import _ from "lodash";
import numbro from "numbro";

export class NumberFormatter {
    public static distributionPercentages(values: number[], fraction = false) {
        if (_.isEmpty(values)) {
            return [];
        }

        const normalizedValues =
            _.map(
                values,
                value =>
                    value === 0
                        ? 0
                        : fraction
                            ? Math.max(Math.round(value * 1000) / 1000, 0.001)
                            : Math.max(Math.round(value * 100) / 100, 0.01));
        const normalizedValueSum = _.sum(normalizedValues);
        const maxNormalizedValue = _.max(normalizedValues)!;
        const maxNormalizedValueIndex =
            _.findIndex(
                normalizedValues,
                normalizedValue => normalizedValue === maxNormalizedValue);
        normalizedValues[maxNormalizedValueIndex] = maxNormalizedValue + 1 - normalizedValueSum;

        return normalizedValues;
    }

    public static humanize(value: number) {
        return NumberFormatter.humanizeCore(
            value,
            false);
    }

    public static percentage(value: number) {
        return NumberFormatter.percentageCore(
            value,
            false,
            false);
    }

    public static percentageFraction(value: number) {
        return NumberFormatter.percentageCore(
            value,
            true,
            false);
    }

    public static signedHumanize(value: number) {
        return NumberFormatter.humanizeCore(
            value,
            true);
    }

    public static signedPercentage(value: number) {
        return NumberFormatter.percentageCore(
            value,
            false,
            true);
    }

    public static storage(value: number) {
        return numbro(value).
            format({
                base: "binary",
                mantissa: 1,
                output: "byte",
                spaceSeparated: true,
                trimMantissa: true
            });
    }

    public static unit(value: number) {
        return value < 1000
            ? value.toString()
            : numbro(value).
                format({
                    average: true,
                    mantissa: 1,
                    trimMantissa: true
                }).
                toUpperCase();
    }

    public static unitRound(value: number) {
        return value < 1000
            ? value
            : numbro(value).
                format({
                    average: true,
                    mantissa: 1,
                    optionalMantissa: true,
                    roundingFunction: num => Number(num.toPrecision(5))
                }).
                toUpperCase();
    }

    private static humanizeCore(value: number, signed: boolean) {
        return numbro(value).
            format({
                forceSign: signed,
                mantissa: 3,
                thousandSeparated: true,
                trimMantissa: true
            });
    }

    private static percentageCore(value: number, fraction: boolean, signed: boolean) {
        function format(value: number) {
            return numbro(value).
                format({
                    forceSign: signed,
                    mantissa: 0,
                    output: "percent"
                });
        }

        const threshold =
            fraction
                ? 0.0005
                : 0.005;
        return value > 0 && value < threshold
            ? `< ${format(0.01)}`
            : value > 1 - threshold && value < 1
                ? `> ${format(0.99)}`
                : value < 0 && value > -threshold
                    ? `> ${format(-0.01)}`
                    : value < -1 + threshold && value > -1
                        ? `< ${format(-0.99)}`
                        : numbro(value).
                            format({
                                forceSign: signed,
                                mantissa:
                                    fraction
                                        ? 1
                                        : 0,
                                output: "percent",
                                trimMantissa: fraction
                            });
    }
}