import { DataTableColumn, DataTableSortType, EnumValuesFilter, PagedValuesFilter, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { useAzureCommonEncryptedResourceDefinition, useAzureCommonResourceGroupResourceDefinition } from ".";
import { Contract, ElasticsearchItemPageHelper, ItemSelectionHelper, TimeRangeHelper } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";
import { EntityTableDefinition } from "../../useDefinition";
import { useCommonNetworkAccessResourceDefinition } from "../useCommonNetworkAccessResourceDefinition";

export function useAzureStorageStorageAccountDefinition(definitionData: DefinitionData) {
    const commonEncryptedResourceDefinition = useAzureCommonEncryptedResourceDefinition(definitionData);
    const commonNetworkAccessResourceDefinition = useCommonNetworkAccessResourceDefinition();
    const commonResourceGroupResourceDefinition = useAzureCommonResourceGroupResourceDefinition(definitionData);

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.azure.useAzureStorageStorageAccountDefinition",
            () => ({
                columns: {
                    blobContainerSoftDeleteEnabled: {
                        false: "Disabled",
                        title: "Blob Container Soft Delete",
                        true: "Enabled"
                    },
                    blobSoftDeleteEnabled: {
                        false: "Disabled",
                        title: "Blob Soft Delete",
                        true: "Enabled"
                    },
                    httpsOnly: {
                        false: "Disabled",
                        title: "Secure Transfer Required",
                        true: "Enabled"
                    },
                    infrastructureEncryption: {
                        false: "Disabled",
                        title: "Infrastructure Encryption",
                        true: "Enabled"
                    },
                    sharedKeyAccess: {
                        false: "Disabled",
                        title: "Shared Key Access",
                        true: "Enabled"
                    },
                    staticWebsiteEnabled: {
                        false: "Disabled",
                        title: "Static Website",
                        true: "Enabled"
                    },
                    tlsMinVersion: "Minimum TLS version",
                    type: {
                        title: "Account Kind",
                        [Contract.TypeNames.AzureStorageStorageAccountType]: {
                            [Contract.AzureStorageStorageAccountType.BlobStorage]: "BlobStorage",
                            [Contract.AzureStorageStorageAccountType.BlockBlobStorage]: "BlockBlobStorage",
                            [Contract.AzureStorageStorageAccountType.FileStorage]: "FileStorage",
                            [Contract.AzureStorageStorageAccountType.Storage]: "Storage v1",
                            [Contract.AzureStorageStorageAccountType.StorageV2]: "Storage v2"
                        }
                    }
                }
            }));

    const getBlobContainerSoftDeleteEnabledTranslation =
        (item: Contract.AzureStorageStorageAccountModel) => {
            const blobContainerSoftDeleteEnabled = (item.entity as Contract.AzureStorageStorageAccount).blobContainerSoftDeleteEnabled;
            return _.isNil(blobContainerSoftDeleteEnabled)
                ? ""
                : blobContainerSoftDeleteEnabled
                    ? localization.columns.blobContainerSoftDeleteEnabled.true()
                    : localization.columns.blobContainerSoftDeleteEnabled.false();
        };
    const getBlobSoftDeleteEnabledTranslation =
        (item: Contract.AzureStorageStorageAccountModel) => {
            const blobSoftDeleteEnabled = (item.entity as Contract.AzureStorageStorageAccount).blobSoftDeleteEnabled;
            return _.isNil(blobSoftDeleteEnabled)
                ? ""
                : blobSoftDeleteEnabled
                    ? localization.columns.blobSoftDeleteEnabled.true()
                    : localization.columns.blobSoftDeleteEnabled.false();
        };

    return new EntityTableDefinition(
        _.filter([
            commonResourceGroupResourceDefinition.columns.tenantColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AzureStorageStorageAccountModel) => ({
                            [localization.columns.type.title()]: localization.columns.type[Contract.TypeNames.AzureStorageStorageAccountType][(item.entity as Contract.AzureStorageStorageAccount).type]()
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.AzureStorageStorageAccountType}
                                enumTypeTranslator={(type: Contract.AzureStorageStorageAccountType) => localization.columns.type[Contract.TypeNames.AzureStorageStorageAccountType][type]()}
                                placeholder={localization.columns.type.title()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AzureStorageStorageAccountType}
                itemProperty={(item: Contract.EntityModel) => localization.columns.type[Contract.TypeNames.AzureStorageStorageAccountType][(item.entity as Contract.AzureStorageStorageAccount).type]()}
                key={Contract.EntityModelProperty.AzureStorageStorageAccountType}
                title={localization.columns.type.title()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AzureStorageStorageAccountModel) => ({
                            [localization.columns.staticWebsiteEnabled.title()]:
                                _.isNil((item.entity as Contract.AzureStorageStorageAccount).staticWebsiteEnabled)
                                    ? ""
                                    : (item.entity as Contract.AzureStorageStorageAccount).staticWebsiteEnabled!
                                        ? localization.columns.staticWebsiteEnabled.true()
                                        : localization.columns.staticWebsiteEnabled.false()
                        })
                }}
                id="staticWebsiteEnabled"
                key="staticWebsiteEnabled"/>,
            commonResourceGroupResourceDefinition.columns.creationTimeColumn,
            commonResourceGroupResourceDefinition.columns.creatorIdentityCsvColumn,
            commonNetworkAccessResourceDefinition.columns.accessLevelColumn,
            commonNetworkAccessResourceDefinition.columns.inboundExternalAccessScopeColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AzureStorageStorageAccountModel) => ({
                            [localization.columns.sharedKeyAccess.title()]:
                                (item.entity as Contract.AzureStorageStorageAccount).sharedKeyAccess
                                    ? localization.columns.sharedKeyAccess.true()
                                    : localization.columns.sharedKeyAccess.false()
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <ValuesFilter
                                placeholder={localization.columns.sharedKeyAccess.title()}
                                sorted={false}>
                                <ValuesFilterItem
                                    title={localization.columns.sharedKeyAccess.true()}
                                    value={true}/>
                                <ValuesFilterItem
                                    title={localization.columns.sharedKeyAccess.false()}
                                    value={false}/>
                            </ValuesFilter>
                    }
                }}
                id={Contract.EntityModelProperty.AzureStorageStorageAccountSharedKeyAccess}
                itemProperty={
                    (item: Contract.AzureStorageStorageAccountModel) =>
                        (item.entity as Contract.AzureStorageStorageAccount).sharedKeyAccess
                            ? localization.columns.sharedKeyAccess.true()
                            : localization.columns.sharedKeyAccess.false()}
                key={Contract.EntityModelProperty.AzureStorageStorageAccountSharedKeyAccess}
                title={localization.columns.sharedKeyAccess.title()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AzureStorageStorageAccountModel) => ({
                            [localization.columns.httpsOnly.title()]:
                                (item.entity as Contract.AzureStorageStorageAccount).httpsOnly
                                    ? localization.columns.httpsOnly.true()
                                    : localization.columns.httpsOnly.false()
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <ValuesFilter
                                placeholder={localization.columns.httpsOnly.title()}
                                sorted={false}>
                                <ValuesFilterItem
                                    title={localization.columns.httpsOnly.true()}
                                    value={true}/>
                                <ValuesFilterItem
                                    title={localization.columns.httpsOnly.false()}
                                    value={false}/>
                            </ValuesFilter>
                    }
                }}
                id={Contract.EntityModelProperty.AzureStorageStorageAccountHttpsOnly}
                itemProperty={
                    (item: Contract.AzureStorageStorageAccountModel) =>
                        (item.entity as Contract.AzureStorageStorageAccount).httpsOnly
                            ? localization.columns.httpsOnly.true()
                            : localization.columns.httpsOnly.false()}
                key={Contract.EntityModelProperty.AzureStorageStorageAccountHttpsOnly}
                selectorOptions={{ default: false }}
                title={localization.columns.httpsOnly.title()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AzureStorageStorageAccountModel) => ({
                            [localization.columns.tlsMinVersion()]: (item.entity as Contract.AzureStorageStorageAccount).tlsMinVersion
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedValuesFilter
                                getValuePage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                        Contract.TypeNames.AzureStorageStorageAccount,
                                        Contract.EntityModelProperty.AzureStorageStorageAccountTlsMinVersion)}
                                placeholder={localization.columns.tlsMinVersion()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AzureStorageStorageAccountTlsMinVersion}
                itemProperty={(item: Contract.EntityModel) => (item.entity as Contract.AzureStorageStorageAccount).tlsMinVersion}
                key={Contract.EntityModelProperty.AzureStorageStorageAccountTlsMinVersion}
                selectorOptions={{ default: false }}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.tlsMinVersion()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AzureStorageStorageAccountModel) => ({
                            [localization.columns.infrastructureEncryption.title()]:
                                (item.entity as Contract.AzureStorageStorageAccount).infrastructureEncryption
                                    ? localization.columns.infrastructureEncryption.true()
                                    : localization.columns.infrastructureEncryption.false()
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <ValuesFilter
                                placeholder={localization.columns.infrastructureEncryption.title()}
                                sorted={false}>
                                <ValuesFilterItem
                                    title={localization.columns.infrastructureEncryption.true()}
                                    value={true}/>
                                <ValuesFilterItem
                                    title={localization.columns.infrastructureEncryption.false()}
                                    value={false}/>
                            </ValuesFilter>
                    }
                }}
                id={Contract.EntityModelProperty.AzureStorageStorageAccountInfrastructureEncryption}
                itemProperty={
                    (item: Contract.AzureStorageStorageAccountModel) =>
                        (item.entity as Contract.AzureStorageStorageAccount).infrastructureEncryption
                            ? localization.columns.infrastructureEncryption.true()
                            : localization.columns.infrastructureEncryption.false()}
                key={Contract.EntityModelProperty.AzureStorageStorageAccountInfrastructureEncryption}
                selectorOptions={{ default: false }}
                title={localization.columns.infrastructureEncryption.title()}/>,
            <DataTableColumn
                exportOptions={{ getItem: (item: Contract.AzureStorageStorageAccountModel) => ({ [localization.columns.blobContainerSoftDeleteEnabled.title()]: getBlobContainerSoftDeleteEnabledTranslation(item) }) }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <ValuesFilter
                                emptyValueOptions={{ enabled: true }}
                                placeholder={localization.columns.blobContainerSoftDeleteEnabled.title()}
                                sorted={false}>
                                <ValuesFilterItem
                                    title={localization.columns.blobContainerSoftDeleteEnabled.true()}
                                    value={true}/>
                                <ValuesFilterItem
                                    title={localization.columns.blobContainerSoftDeleteEnabled.false()}
                                    value={false}/>
                            </ValuesFilter>
                    }
                }}
                id={Contract.EntityModelProperty.AzureStorageStorageAccountBlobContainerSoftDeleteEnabled}
                itemProperty={getBlobContainerSoftDeleteEnabledTranslation}
                key={Contract.EntityModelProperty.AzureStorageStorageAccountBlobContainerSoftDeleteEnabled}
                selectorOptions={{ default: false }}
                title={localization.columns.blobContainerSoftDeleteEnabled.title()}/>,
            <DataTableColumn
                exportOptions={{ getItem: (item: Contract.AzureStorageStorageAccountModel) => ({ [localization.columns.blobSoftDeleteEnabled.title()]: getBlobSoftDeleteEnabledTranslation(item) }) }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <ValuesFilter
                                emptyValueOptions={{ enabled: true }}
                                placeholder={localization.columns.blobSoftDeleteEnabled.title()}
                                sorted={false}>
                                <ValuesFilterItem
                                    title={localization.columns.blobSoftDeleteEnabled.true()}
                                    value={true}/>
                                <ValuesFilterItem
                                    title={localization.columns.blobSoftDeleteEnabled.false()}
                                    value={false}/>
                            </ValuesFilter>
                    }
                }}
                id={Contract.EntityModelProperty.AzureStorageStorageAccountBlobSoftDeleteEnabled}
                itemProperty={getBlobSoftDeleteEnabledTranslation}
                key={Contract.EntityModelProperty.AzureStorageStorageAccountBlobSoftDeleteEnabled}
                selectorOptions={{ default: false }}
                title={localization.columns.blobSoftDeleteEnabled.title()}/>,
            commonResourceGroupResourceDefinition.columns.regionColumn,
            commonResourceGroupResourceDefinition.columns.regionLocationColumn,
            commonEncryptedResourceDefinition.columns.encryptionExistsColumn,
            commonEncryptedResourceDefinition.columns.vaultKeyColumn,
            commonResourceGroupResourceDefinition.columns.resourceGroupColumn,
            commonResourceGroupResourceDefinition.columns.tagsColumn,
            commonResourceGroupResourceDefinition.columns.attributesColumn,
            commonResourceGroupResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonResourceGroupResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonResourceGroupResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ]),
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAzureStorageStorageAccountRequest(
                new Contract.EntityControllerGetEntityModelPageAzureStorageStorageAccountRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureResourceGroupResourceResourceGroup]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureStorageStorageAccountBlobContainerSoftDeleteEnabled]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureStorageStorageAccountBlobSoftDeleteEnabled]),
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureStorageStorageAccountHttpsOnly]),
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureStorageStorageAccountInfrastructureEncryption]),
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureStorageStorageAccountSharedKeyAccess]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureStorageStorageAccountTlsMinVersion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureStorageStorageAccountType])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags])),
                new Contract.EntityControllerGetEntityModelPageAzureResourceRequestEncrypted(
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureEncryptedResourceEncryptionExists]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureEncryptedResourceVaultKeys])),
                new Contract.EntityControllerGetEntityModelPageEntityRequestNetworkAccess(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.NetworkInboundAccessType]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.NetworkInboundExternalAccessScope]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.NetworkInboundExternalDestinationNetworkScopes]))));
}