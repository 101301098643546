import { useMemo } from "react";
import { codeRiskPolicyItemsOperationStore, Contract, RiskController, riskPolicyModelStore } from "../../../../..";
import { riskPolicyItemConfigurationOperationStore } from "../../../../../operationStores";
import { RiskPolicyConfigurationControllerDefinition } from "../../../useRiskPolicyConfigurationControllerDefinition";

export function useCodeBuiltInRiskPolicyConfigurationControllerDefinition(riskPolicyConfigurationTypeNameOrId: string, scopeId: string): RiskPolicyConfigurationControllerDefinition {
    return useMemo(
        () => ({
            async updateRiskPolicyConfiguration({ codeExclusions, riskPoliciesIds, riskPolicyConfiguration }) {
                await RiskController.updateRiskPolicyConfiguration(
                    new Contract.RiskControllerUpdateCodeRiskPolicyConfigurationRequest(
                        riskPolicyConfiguration,
                        codeExclusions!));

                await riskPolicyModelStore.notify(riskPoliciesIds);

                await Promise.all([
                    codeRiskPolicyItemsOperationStore.notifyAll(),
                    riskPolicyItemConfigurationOperationStore.notifyAll()
                ]);
            }
        }),
        [riskPolicyConfigurationTypeNameOrId, scopeId]);
}