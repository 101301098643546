import { Stack, Typography, useTheme } from "@mui/material";
import React, { ReactNode } from "react";

type WelcomeViewProps = {
    children?: ReactNode;
    title: string;
};

export function WelcomeView({ children, title }: WelcomeViewProps) {
    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            sx={{
                alignItems: "center",
                background: theme.palette.background.paper,
                height: "100%",
                justifyContent: "center",
                width: "100%"
            }}>
            <Stack
                alignItems="center"
                justifyContent="center"
                spacing={5}
                sx={{
                    background: theme.palette.background.paper,
                    border: theme.border.primary,
                    borderRadius: theme.spacing(0.75),
                    padding: theme.spacing(10, 15)
                }}>
                <Typography
                    sx={{ whiteSpace: "pre-wrap" }}
                    variant="h1">
                    {title}
                </Typography>
                {children}
            </Stack>
        </Stack>);
}