import { Link, Optional, useChangeEffect, useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useRef, useState } from "react";
import { useRiskPoliciesItemConfiguration } from "../../..";
import { Contract, RiskPoliciesType, useTheme } from "../../../../../../../../common";
import { useConfigurationContext, useSetConfigurationContext } from "../../../../components";
import { NumericItem } from "./components";
import { useNumeric } from "./useNumeric";

type NumericProps<T> = {
    description: string;
    documentationUrl?: string;
    fromNumber: (value: number) => T;
    getValue: (riskPolicyConfiguration: Contract.RiskPolicyConfiguration) => Optional<T>;
    label: string;
    maxValue: number;
    minValue: number;
    riskPoliciesType: RiskPoliciesType;
    setValue: (riskPolicyConfiguration: Contract.RiskPolicyConfiguration, value: Optional<T>) => Optional<string>;
    title: string;
    toNumber: (value: T) => number;
    translateValue: (value: T) => string;
    valueOutOfRangeErrorMessage: string;
};

export function Numeric<T>({ description, documentationUrl, fromNumber, getValue, label, maxValue, minValue, riskPoliciesType, setValue, title, toNumber, translateValue, valueOutOfRangeErrorMessage }: NumericProps<T>) {
    const { item, riskPolicyConfiguration, scopeId } = useConfigurationContext();
    const { inheritDisabled } =
        useRiskPoliciesItemConfiguration(
            riskPoliciesType,
            item,
            scopeId);
    const { defaultSelectedValue, inheritedScopeId, inheritedValue, selectedValueInherit, userSecurityAdministrationWrite } =
        useNumeric<T>(
            item,
            getValue,
            riskPolicyConfiguration,
            riskPoliciesType,
            toNumber);

    const setConfigurationContext = useSetConfigurationContext();

    const localization =
        useLocalization(
            "views.customer.riskPolicies.hooks.useConfigurationDefinition.numeric",
            () => ({
                documentationLink: "Learn more",
                inheritedValue: "{{scope}} ({{inheritedValue}})",
                options: {
                    [Options.Inherit]: "Inherit",
                    [Options.Many]: "Custom"
                }
            }));

    const [selectedValue, setSelectedValue] = useState(defaultSelectedValue);
    const [selectedItem, setSelectedItem] =
        useState<Options>(
            selectedValueInherit
                ? Options.Inherit
                : Options.Many);

    const selectedValueRef = useRef(defaultSelectedValue);

    useChangeEffect(
        () => {
            function getErrorMessage() {
                const errorMessage =
                    setValue(
                        riskPolicyConfiguration,
                        _.isNil(selectedValue)
                            ? undefined
                            : fromNumber(selectedValue));
                if (_.isNil(errorMessage) &&
                    selectedValue &&
                    (selectedValue < minValue ||
                        selectedValue > maxValue)) {
                    return valueOutOfRangeErrorMessage;
                }

                return errorMessage;
            }

            setConfigurationContext(
                configurationContext => ({
                    ...configurationContext,
                    dirty: true,
                    errorMessage: getErrorMessage(),
                    riskPolicyConfiguration
                }));
        },
        [selectedValue]);

    function onClick(value?: number) {
        if (userSecurityAdministrationWrite) {
            setSelectedItem(
                _.isNil(value)
                    ? Options.Inherit
                    : Options.Many);
            setSelectedValue(value);
        }
    }

    const theme = useTheme();
    return (
        <Stack>
            <Typography
                sx={{
                    fontSize: "16px",
                    fontWeight: 600,
                    marginBottom: theme.spacing(1)
                }}>
                {title}
            </Typography>
            <Typography sx={{ whiteSpace: "pre-wrap" }}>
                {description}
            </Typography>
            {!_.isNil(documentationUrl) &&
                <Typography>
                    <Link
                        urlOrGetUrl={documentationUrl}
                        variant="external">
                        {localization.documentationLink()}
                    </Link>
                </Typography>}
            <Stack
                sx={{
                    marginTop:
                        theme.spacing(
                            inheritDisabled
                                ? 1.5
                                : 1)
                }}>
                {!inheritDisabled &&
                    <NumericItem
                        checked={selectedItem === Options.Inherit}
                        disabled={!userSecurityAdministrationWrite}
                        inheritedScopeId={inheritedScopeId}
                        label={label}
                        title={localization.options.inherit()}
                        translatedInheritedValue={translateValue(inheritedValue!)}
                        onClick={() => onClick(undefined)}/>}
                <NumericItem
                    checked={selectedItem === Options.Many}
                    disabled={!userSecurityAdministrationWrite}
                    disableRadio={inheritDisabled}
                    label={label}
                    maxValue={maxValue}
                    minValue={minValue}
                    title={localization.options.many()}
                    value={selectedValueRef.current}
                    onChange={
                        changedValue => {
                            selectedValueRef.current = changedValue;
                            setSelectedValue(changedValue);
                        }}
                    onClick={() => onClick(selectedValueRef.current)}/>
            </Stack>
        </Stack>);
}

enum Options {
    Inherit = "inherit",
    Many = "many"
}