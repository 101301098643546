import { GroupedSearchList as GroupedSearchListBase, IconText, setUrlRoute, useLocalization } from "@infrastructure";
import { Box, Typography } from "@mui/material";
import _ from "lodash";
import { useMemo } from "react";
import { ComplianceSectionIcon, CustomerConsoleAppUrlHelper, useBuiltInComplianceTypes, useComplianceSectionDatas, useComplianceTranslator, useScopeNavigationViewContext, useTheme } from "../../../../../../../../../../../common";
import { ComplianceView, useComplianceContext } from "../../../../../../../Compliance";

export function GroupedSearchList() {
    const { scopeNodeModel } = useScopeNavigationViewContext();
    const { childScopeCustomCompliance } = useComplianceContext();
    const [disabledBuiltInCompliance, enabledBuiltInCompliance] = useBuiltInComplianceTypes();
    const { customComplianceSectionDatas } = useComplianceSectionDatas(scopeNodeModel.configuration.id, { childScopeCustomCompliance });

    const localization =
        useLocalization(
            "views.customer.compliance.items.hooks.useDefinition.hooks.useCustomDefinition.groupedSearchList",
            () => ({
                builtIn: "Built In",
                custom: "Custom"
            }));

    const groups =
        useMemo(
            () =>
                _.filter([
                    {
                        id: ComplianceView.BuiltIn,
                        items: [...enabledBuiltInCompliance, ...disabledBuiltInCompliance],
                        name: localization.builtIn()
                    },
                    {
                        id: ComplianceView.Custom,
                        items:
                            _.map(
                                customComplianceSectionDatas,
                                customComplianceModel => customComplianceModel.identifier),
                        name: localization.custom()
                    }
                ],
                section => !_.isEmpty(section.items)),
            [customComplianceSectionDatas, disabledBuiltInCompliance, enabledBuiltInCompliance]);

    const complianceTranslator = useComplianceTranslator();
    const theme = useTheme();
    return (
        <GroupedSearchListBase
            getGroupItemSearchText={compliance => complianceTranslator(compliance).title}
            groupItemRenderer={
                compliance =>
                    <Box
                        sx={{
                            "&:hover": {
                                backgroundColor: theme.palette.action.hover
                            },
                            borderRadius: theme.spacing(0.75),
                            cursor: "pointer",
                            padding: theme.spacing(1),
                            width: "100%"
                        }}
                        onClick={
                            () =>
                                setUrlRoute(
                                    CustomerConsoleAppUrlHelper.getComplianceRelativeUrl(
                                        scopeNodeModel.configuration.id,
                                        ComplianceView.Create,
                                        compliance))}>
                        <IconText
                            icon={
                                <ComplianceSectionIcon
                                    complianceIdentifier={compliance}
                                    size={theme.spacing(2)}/>}
                            placement="bottom-start"
                            text={
                                <Typography
                                    noWrap={true}
                                    sx={{
                                        color: theme.palette.text.secondary,
                                        fontWeight: 400
                                    }}>
                                    {complianceTranslator(compliance).title}
                                </Typography>}/>
                    </Box>}
            groups={groups}/>);
}