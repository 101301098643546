import { NumberFormatter, useLocalization } from "@infrastructure";
import { Divider, Grid, Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { DistributionChart, DistributionChartItem } from "../..";
import { ComplianceHelper, Contract, TypeHelper, useSeverityTranslator, useTheme } from "../../../../../../../common";

type ChartProps = {
    complianceSectionData: Contract.ComplianceSectionData;
};

export function Chart({ complianceSectionData }: ChartProps) {
    const severityTranslator = useSeverityTranslator();
    const localization =
        useLocalization(
            "views.system.exportReport.complianceReport.chart",
            () => ({
                securityStats: {
                    securedPercentage: "Passed"
                },
                subtitle: "COMPLIANT"
            }));

    const riskSeverities =
        useMemo(
            () =>
                _(Contract.Severity).
                    values().
                    filter(
                        riskSeverity =>
                            !_.isNil(complianceSectionData.stats) &&
                            (complianceSectionData.stats!.unsecuredSeverityToCountMap[riskSeverity] > 0)).
                    orderBy(
                        riskSeverity => TypeHelper.getEnumValue(Contract.TypeNames.Severity, riskSeverity),
                        "desc").
                    value(),
            []);

    const [securedPercentage, unsecuredSeverityToPercentageMap] = ComplianceHelper.getDataPercentages(complianceSectionData);

    const theme = useTheme();
    return (
        <Grid
            container={true}
            style={{
                border: theme.border.primary,
                borderRadius: theme.spacing(0.75),
                padding: theme.spacing(3, 0)
            }}>
            <Grid
                alignItems="center"
                container={true}>
                <Grid
                    item={true}
                    sx={{ padding: theme.spacing(0, 10) }}
                    xs={true}>
                    <Grid
                        container={true}
                        direction="column"
                        spacing={2}
                        sx={{ height: theme.px(162) }}>
                        <Legend
                            color={theme.palette.success.main}
                            title={localization.securityStats.securedPercentage()}
                            value={securedPercentage}/>
                        {_.map(
                            riskSeverities,
                            riskSeverity =>
                                <Legend
                                    color={theme.palette.severity(riskSeverity)}
                                    key={riskSeverity}
                                    title={severityTranslator(riskSeverity)}
                                    value={unsecuredSeverityToPercentageMap[riskSeverity]}/>)}
                    </Grid>
                </Grid>
                <Divider
                    flexItem={true}
                    orientation="vertical"/>
                <Grid
                    item={true}
                    sx={{ padding: theme.spacing(0, 6) }}>
                    <DistributionChart
                        items={[
                            new DistributionChartItem(
                                theme.palette.success.main,
                                complianceSectionData.stats?.securedCount ?? 0),
                            ..._.map(
                                riskSeverities,
                                riskSeverity =>
                                    new DistributionChartItem(
                                        theme.palette.severity(riskSeverity),
                                        complianceSectionData.stats?.unsecuredSeverityToCountMap[riskSeverity] ?? 0))
                        ]}
                        newReport={true}
                        title={localization.subtitle()}
                        total={securedPercentage}
                        totalFormatter={NumberFormatter.percentage}/>
                </Grid>
            </Grid>
        </Grid>);
}

type LegendProps = {
    color: string;
    title: string;
    value: number;
};

function Legend({ color, title, value }: LegendProps) {
    const theme = useTheme();
    return (
        <Grid
            item={true}
            xs={3}>
            <Grid
                container={true}
                direction="column">
                <Grid item={true}>
                    <Typography
                        style={{
                            fontSize: "13px",
                            fontWeight: 500
                        }}>
                        {title}
                    </Typography>
                </Grid>
                <Grid item={true}>
                    <Grid
                        alignItems="center"
                        container={true}>
                        <Grid
                            item={true}
                            style={{
                                backgroundColor: color,
                                height: theme.spacing(1),
                                marginRight: theme.spacing(1),
                                width: theme.spacing(1)
                            }}>
                        </Grid>
                        <Grid item={true}>
                            <Typography>
                                {NumberFormatter.percentage(value)}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>);
}