﻿import { Action0, Action1, useLocalization } from "@infrastructure";
import { Radio, Stack, TextField, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Scope, useTheme } from "../../../../../../../../../../../common";

type EntityCreationTimeAnalysisDelayTimeFrameItemProps = {
    checked: boolean;
    disabled: boolean;
    inheritedScopeId?: string;
    onChange?: Action1<number>;
    onClick: Action0;
    title: string;
    translatedInheritedEntityCreationTimeAnalysisDelayTimeFrameTitle?: string;
    value?: number;
};

export function EntityCreationTimeAnalysisDelayTimeFrameItem({ checked, disabled, inheritedScopeId, onChange, onClick, title, translatedInheritedEntityCreationTimeAnalysisDelayTimeFrameTitle, value }: EntityCreationTimeAnalysisDelayTimeFrameItemProps) {
    const localization =
        useLocalization(
            "views.customer.riskPolicies.configuration.general.entityCreationTimeAnalysisDelayTimeFrame.entityCreationTimeAnalysisDelayTimeFrameItem",
            () => ({
                inherit: "({{translatedInheritedValue}})"
            }));

    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            direction="row"
            key={title}
            sx={{
                color:
                    disabled
                        ? theme.palette.text.disabled
                        : undefined
            }}
            onClick={onClick}>
            <Radio
                checked={checked}
                disabled={disabled}
                size="small"/>
            {title}
            {!_.isNil(onChange)
                ? <TextField
                    disabled={!checked}
                    label="Days"
                    size="small"
                    slotProps={{
                        htmlInput: {
                            max: 90,
                            min: 1
                        }
                    }}
                    sx={{
                        marginLeft: theme.spacing(1),
                        width: theme.spacing(16)
                    }}
                    type="number"
                    value={value}
                    variant="outlined"
                    onChange={
                        event => {
                            const newValue = _.parseInt(event.target.value);
                            if (!_.isNaN(newValue) &&
                                newValue > 0 &&
                                newValue <= 90) {
                                onChange(newValue);
                            }
                        }}/>
                : undefined}
            {_.isNil(translatedInheritedEntityCreationTimeAnalysisDelayTimeFrameTitle)
                ? undefined
                : <Stack
                    alignItems="center"
                    direction="row"
                    spacing={0.5}
                    sx={{
                        color: theme.palette.text.secondary,
                        marginLeft: theme.spacing(1)
                    }}>
                    <Scope scopeId={inheritedScopeId!}/>
                    <Typography
                        noWrap={true}
                        sx={{ flex: 1 }}>
                        {localization.inherit({ translatedInheritedValue: translatedInheritedEntityCreationTimeAnalysisDelayTimeFrameTitle })}
                    </Typography>
                </Stack>}
        </Stack>);
}