﻿import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../../../../../../../../../../../common";

export function useBitbucketOrganizationManagerErrorTranslator() {
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCodeOrganizationItems.bitbucket.addOrEditBitbucket.hooks.useBitbucketOrganizationManagerErrorTranslator",
            () => ({
                [Contract.TypeNames.BitbucketOrganizationManagerError]: {
                    [Contract.BitbucketOrganizationManagerError.CommonErrorAccessTokenIncorrectScope]: "Bitbucket access token is not a workspace-level access token",
                    [Contract.BitbucketOrganizationManagerError.CommonErrorAccessTokenMissingPermissions]: "Bitbucket workspace access token is missing the required permissions",
                    [Contract.BitbucketOrganizationManagerError.CommonErrorAccessTokenUnauthorized]: "Bitbucket workspace access token is not valid",
                    [Contract.BitbucketOrganizationManagerError.CommonErrorOrganizationEmpty]: "Bitbucket workspace contains no repositories",
                    [Contract.BitbucketOrganizationManagerError.CommonErrorOrganizationExists]: "Bitbucket workspace already exists",
                    [Contract.BitbucketOrganizationManagerError.CommonErrorOrganizationMismatch]: "Bitbucket workspace access token does not belong to the current workspace"
                }
            }));
    return (error: Contract.BitbucketOrganizationManagerError) =>
        localization[Contract.TypeNames.BitbucketOrganizationManagerError][error]();
}