﻿import { Box, Divider, Stack, Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { useMemo } from "react";
import { TimeFormatter, useLocalization } from "@infrastructure";
import { Contract, LineMetadata, LinesDataItem, PrincipalIcon, PrincipalRiskCategoryHelper, usePrincipalTypeTranslator, useTheme } from "../../../../../../../../..";
import { ChartWithLegend, LineLegendData } from "../../../components";

type PrincipalRiskCategoryChartProps = {
    dateToPrincipalRiskCategoryToOpenRiskSeverityToPrincipalCountMap: Dictionary<Dictionary<Dictionary<number>>>;
    orderedPrincipalRiskCategories: Contract.PrincipalRiskCategory[];
    selectedPrincipalRiskCategories: Contract.PrincipalRiskCategory[];
    severities: Contract.Severity[];
};

export function PrincipalRiskCategoryChart({ dateToPrincipalRiskCategoryToOpenRiskSeverityToPrincipalCountMap, orderedPrincipalRiskCategories, selectedPrincipalRiskCategories, severities }: PrincipalRiskCategoryChartProps) {
    const principalTypeTranslator = usePrincipalTypeTranslator();
    const principalRiskCategoryToPrincipalTypeMap =
        useMemo(
            () =>
                _(orderedPrincipalRiskCategories).
                    keyBy(principalRiskCategory => principalRiskCategory).
                    mapValues(principalRiskCategory => PrincipalRiskCategoryHelper.getPrincipalType(principalRiskCategory)).
                    value(),
            [orderedPrincipalRiskCategories]);

    const principalRiskCategoryToDataMap =
        useMemo(
            () => {
                const endTime =
                    _(dateToPrincipalRiskCategoryToOpenRiskSeverityToPrincipalCountMap).
                        toPairs().
                        maxBy(([date, _principalRiskCategoryToOpenRiskSeverityToPrincipalCountMap]) => TimeFormatter.sortableDateTime(date))![0];
                const startTime =
                    _(dateToPrincipalRiskCategoryToOpenRiskSeverityToPrincipalCountMap).
                        toPairs().
                        minBy(([date, _principalRiskCategoryToOpenRiskSeverityToPrincipalCountMap]) => TimeFormatter.sortableDateTime(date))![0];
                const principalRiskCategories =
                    _.isEmpty(selectedPrincipalRiskCategories)
                        ? orderedPrincipalRiskCategories
                        : selectedPrincipalRiskCategories;
                return _(principalRiskCategories).
                    keyBy(principalRiskCategory => principalRiskCategory).
                    mapValues(principalRiskCategory => {
                        const endTimePrincipalCount =
                            _(dateToPrincipalRiskCategoryToOpenRiskSeverityToPrincipalCountMap[endTime][principalRiskCategory] ?? {}).
                                pickBy((_principalCount, openRiskSeverity) =>
                                    _.includes(
                                        severities,
                                        openRiskSeverity)).
                                values().
                                sum();
                        const startTimePrincipalCount =
                            _(dateToPrincipalRiskCategoryToOpenRiskSeverityToPrincipalCountMap[startTime][principalRiskCategory] ?? {}).
                                pickBy((_principalCount, openRiskSeverity) =>
                                    _.includes(
                                        severities,
                                        openRiskSeverity)).
                                values().
                                sum();
                        const delta = endTimePrincipalCount - startTimePrincipalCount;
                        return ({
                            delta,
                            deltaPercent:
                                delta === 0
                                    ? 0
                                    : startTimePrincipalCount === 0
                                        ? undefined
                                        : delta / startTimePrincipalCount,
                            endTimePrincipalCount,
                            principalType: principalRiskCategoryToPrincipalTypeMap[principalRiskCategory]
                        });
                    }).
                    value();
            },
            [dateToPrincipalRiskCategoryToOpenRiskSeverityToPrincipalCountMap, orderedPrincipalRiskCategories, principalRiskCategoryToPrincipalTypeMap, selectedPrincipalRiskCategories, severities]);

    const theme = useTheme();
    const localization =
        useLocalization(
            "common.dashboard.widget.hooks.useDefinition.hooks.useGetExcessivePermissionPrincipalTrendsDefinition.principalRiskCategoryChart",
            () => ({
                legend: {
                    count: "**{{principalType}}** {{endTimePrincipalCount | NumberFormatter.humanize}}",
                    delta: "**{{delta | NumberFormatter.signedHumanize}}**",
                    deltaPercent: "**({{deltaPercent | NumberFormatter.signedPercentage}})**"
                }
            }));

    const [principalRiskCategoryMetadatas, principalRiskCategoryKeyToLegendDataMap] =
        useMemo(
            () => {
                const principalRiskCategoryMetadatas =
                    _.map(
                        principalRiskCategoryToDataMap,
                        (principalRiskCategoryData, principalRiskCategory) =>
                            new LineMetadata(
                                theme.palette.risk.access(principalRiskCategoryData.principalType),
                                principalTypeTranslator(principalRiskCategoryData.principalType),
                                principalRiskCategory));

                const principalRiskCategoryKeyToLegendDataMap =
                    _.mapValues(
                        principalRiskCategoryToDataMap,
                        (principalRiskCategoryData, _principalRiskCategory) => {
                            const deltaColor =
                                principalRiskCategoryData.delta > 0
                                    ? theme.palette.error.main
                                    : theme.palette.success.main;
                            return new LineLegendData(
                                <Stack
                                    alignItems="center"
                                    direction="row"
                                    spacing={0.5}
                                    sx={{ overflow: "hidden" }}>
                                    <PrincipalIcon principalType={principalRiskCategoryData.principalType}/>
                                    <Typography noWrap={true}>
                                        {localization.legend.count({
                                            endTimePrincipalCount: principalRiskCategoryData.endTimePrincipalCount,
                                            principalType: principalTypeTranslator(principalRiskCategoryData.principalType)
                                        })}
                                    </Typography>
                                    <Divider
                                        flexItem={true}
                                        orientation="vertical"/>
                                    <Typography sx={{ color: deltaColor }}>
                                        {localization.legend.delta({ delta: principalRiskCategoryData.delta })}
                                    </Typography>
                                    {!_.isNil(principalRiskCategoryData.deltaPercent) &&
                                        <Typography sx={{ color: deltaColor }}>
                                            {localization.legend.deltaPercent({ deltaPercent: principalRiskCategoryData.deltaPercent })}
                                        </Typography>}
                                </Stack>,
                                principalTypeTranslator(principalRiskCategoryData.principalType).replaceAll(" ", ""));
                        });

                return [principalRiskCategoryMetadatas, principalRiskCategoryKeyToLegendDataMap];
            },
            [principalRiskCategoryToDataMap]);

    const orderedPrincipalRiskCategoriesDataItems =
        useMemo(
            () =>
                _(dateToPrincipalRiskCategoryToOpenRiskSeverityToPrincipalCountMap).
                    map(
                        (principalRiskCategoryToOpenRiskSeverityToPrincipalCountMap, date) => ({
                            dataItem:
                                new LinesDataItem(
                                    TimeFormatter.monthAndDay(date),
                                    _(principalRiskCategoryToDataMap).
                                        mapValues(
                                            (_data, principalRiskCategory) =>
                                                _(principalRiskCategoryToOpenRiskSeverityToPrincipalCountMap[principalRiskCategory]).
                                                    pickBy(
                                                        (_principalCount, opeRiskSeverity) =>
                                                            _.includes(
                                                                severities,
                                                                opeRiskSeverity)).
                                                    values().
                                                    sum()).
                                        value()),
                            date
                        })).
                    orderBy(({ date }) => TimeFormatter.sortableDateTime(date)).
                    map(({ dataItem }) => dataItem).
                    value(),
            [dateToPrincipalRiskCategoryToOpenRiskSeverityToPrincipalCountMap, principalRiskCategoryToDataMap, severities]);

    return (
        <Stack
            className="principalRiskCategoryChart"
            spacing={2}
            sx={{ height: theme.px(340) }}>
            <Box
                sx={{
                    flex: 1,
                    height: "100%",
                    position: "relative"
                }}>
                <ChartWithLegend
                    lineChartMargin={{
                        left: -20,
                        right: 10
                    }}
                    lineKeyToLegendDataMap={principalRiskCategoryKeyToLegendDataMap}
                    lineMetadatas={principalRiskCategoryMetadatas}
                    orderedLinesDataItems={orderedPrincipalRiskCategoriesDataItems}/>
            </Box>
        </Stack>);
}