﻿import { TimeSpanHelper } from "@infrastructure";
import _ from "lodash";
import React, { ReactElement } from "react";
import { Contract, ElasticsearchItemPageHelper, EntityController } from "../../../../../../../../../../common";
import { Approval, Description, ExpirationTimeFrame, GranteePrincipals, Groups, Name, PrincipalTenant, Reason } from "../../components";
import { TenantDefinition } from "../../useDefinition";
import { PermissionEligibilityData } from "../../utilities";
import { GciDirectoryGroupMembershipEligibilityData } from "./utilities";

export function useGciDefinition(): TenantDefinition {
    function createInsertPermissionEligibilityRequest(permissionEligibilityData: PermissionEligibilityData): Contract.ConfigurationControllerInsertPermissionEligibilityRequest {
        const approval = permissionEligibilityData.approval.createConfigurationControllerPermissionEligibilityApproval();
        const { description, expirationTimeFrameHours, granteePrincipalIds, groupIds, name, principalTenantId, reasonRequired, scopeId } = permissionEligibilityData as GciDirectoryGroupMembershipEligibilityData;
        return new Contract.ConfigurationControllerInsertGciDirectoryGroupMembershipEligibilityRequest(
            approval,
            description,
            TimeSpanHelper.fromHours(expirationTimeFrameHours),
            granteePrincipalIds,
            name,
            principalTenantId!,
            reasonRequired,
            scopeId,
            groupIds);
    }

    function createUpdatePermissionEligibilityRequest(permissionEligibilityData: PermissionEligibilityData, permissionEligibilityId: string): Contract.ConfigurationControllerUpdatePermissionEligibilityRequest {
        const approval = permissionEligibilityData.approval.createConfigurationControllerPermissionEligibilityApproval();
        const { description, expirationTimeFrameHours, granteePrincipalIds, groupIds, name, principalTenantId, reasonRequired } = permissionEligibilityData as GciDirectoryGroupMembershipEligibilityData;
        return new Contract.ConfigurationControllerUpdateGciDirectoryGroupMembershipEligibilityRequest(
            approval,
            description,
            TimeSpanHelper.fromHours(expirationTimeFrameHours),
            granteePrincipalIds,
            permissionEligibilityId,
            name,
            principalTenantId!,
            reasonRequired,
            groupIds);
    }
    return new TenantDefinition(
        createInsertPermissionEligibilityRequest,
        (permissionEligibilityModel, scopeId) =>
            new GciDirectoryGroupMembershipEligibilityData(
                permissionEligibilityModel,
                scopeId),
        createUpdatePermissionEligibilityRequest,
        _<ReactElement>([]).
            concat(
                <Name/>,
                <Description/>,
                <PrincipalTenant/>,
                <GranteePrincipals/>,
                <Groups
                    getModelPage={
                        principalTenantId =>
                            ElasticsearchItemPageHelper.makePagedEntitySelector(
                                async (itemNextPageSearchCursor, searchText) => {
                                    const { entityModelPage } =
                                        await EntityController.searchEntityModels(
                                            new Contract.EntityControllerSearchEntityModelsTypeRequest(
                                                false,
                                                15,
                                                itemNextPageSearchCursor,
                                                principalTenantId,
                                                searchText,
                                                true,
                                                Contract.TypeNames.GciDirectoryGroup));
                                    return entityModelPage;
                                })}/>).
            concat(
                <ExpirationTimeFrame/>,
                <Approval/>,
                <Reason/>).
            value());
}