import { useLocalization } from "@infrastructure";
import _ from "lodash";
import { AuditEventDefinitionContext, AuditEventDefinitionDetailItem } from "..";
import { Contract, useRiskPolicyTitleTranslator } from "../../../../../../../../../common";

export function useRiskPolicyAuditEventDetailsItems(context: AuditEventDefinitionContext) {
    const riskPolicyTitleTranslator = useRiskPolicyTitleTranslator();
    const localization =
        useLocalization(
            "views.customer.auditEvents.profile.hooks.useDefinition.hooks.useRiskPolicyAuditEventDetailsItems",
            () => ({
                customRiskDescription: "Description",
                riskTypeName: "Name"
            }));

    const riskPolicyAuditEvent = context.auditEventModel.auditEvent as Contract.RiskPolicyAuditEvent;
    return {
        customPolicyDescriptionItem:
            !_.isNil(riskPolicyAuditEvent.customRiskPolicyDescription)
                ? new AuditEventDefinitionDetailItem(
                    localization.customRiskDescription(),
                    riskPolicyAuditEvent.customRiskPolicyDescription)
                : undefined!,
        titleItem:
            new AuditEventDefinitionDetailItem(
                localization.riskTypeName(),
                riskPolicyAuditEvent.customRiskPolicyName ?? riskPolicyTitleTranslator(riskPolicyAuditEvent.riskPolicyConfigurationTypeName))
    };
}