import { DataTableColumn, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { EntityTableDefinition } from "../../..";
import { useAwsCommonEncryptedResourceDefinition, useAwsCommonResourceDefinition } from "..";
import { Contract, ItemSelectionHelper, TimeRangeHelper } from "../../../../../../../../../../../common";
import { useAwsCloudTrailTrailEventSelectorTypeTranslator } from "../../../../../../../../../../../tenants";
import { DefinitionData } from "../../../../../Table";
import { AwsCloudTrailTrailEventSelectorTypeFilter } from "./components";

export function useAwsCloudTrailTrailDefinition(definitionData: DefinitionData) {
    const commonEncryptedResourceDefinition = useAwsCommonEncryptedResourceDefinition(definitionData);
    const commonResourceDefinition = useAwsCommonResourceDefinition(definitionData);

    const cloudTrailTrailEventSelectorTypeTranslator = useAwsCloudTrailTrailEventSelectorTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.aws.useAwsCloudTrailTrailDefinition",
            () => ({
                columns: {
                    bucketObjectEventSelectorType: "S3 Data Events",
                    enabled: {
                        false: "Off",
                        title: "Logging",
                        true: "On"
                    },
                    managementEventSelectorType: "Management Events",
                    multiRegion: {
                        false: "No",
                        title: "Multi-Region",
                        true: "Yes"
                    }
                }
            }));

    function getTrailEnabledTranslation(trailModel: Contract.EntityModel) {
        return (trailModel.entity as Contract.AwsCloudTrailTrail).enabled
            ? localization.columns.enabled.true()
            : localization.columns.enabled.false();
    }

    return new EntityTableDefinition(
        _.filter(
            [
                commonResourceDefinition.columns.tenantColumn,
                commonResourceDefinition.columns.creationTimeColumn,
                commonResourceDefinition.columns.creatorIdentityCsvColumn,
                commonResourceDefinition.columns.creatorOriginatorEntityCsvColumn,
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsCloudTrailTrailModel) => ({
                                [localization.columns.enabled.title()]: getTrailEnabledTranslation(item)
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <ValuesFilter
                                    placeholder={localization.columns.enabled.title()}
                                    sorted={false}>
                                    <ValuesFilterItem
                                        title={localization.columns.enabled.true()}
                                        value={true}/>
                                    <ValuesFilterItem
                                        title={localization.columns.enabled.false()}
                                        value={false}/>
                                </ValuesFilter>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsCloudTrailTrailEnabled}
                    itemProperty={getTrailEnabledTranslation}
                    key={Contract.EntityModelProperty.AwsCloudTrailTrailEnabled}
                    title={localization.columns.enabled.title()}/>,
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsCloudTrailTrailModel) => ({
                                [localization.columns.multiRegion.title()]:
                                    (item.entity as Contract.AwsCloudTrailTrail).multiRegion
                                        ? localization.columns.multiRegion.true()
                                        : localization.columns.multiRegion.false()
                            })
                    }}
                    id="multiRegion"
                    key="multiRegion"/>,
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsCloudTrailTrailModel) => ({
                                [localization.columns.managementEventSelectorType()]: cloudTrailTrailEventSelectorTypeTranslator((item.entity as Contract.AwsCloudTrailTrail).managementEventSelectorType)
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <AwsCloudTrailTrailEventSelectorTypeFilter placeholder={localization.columns.managementEventSelectorType()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsCloudTrailTrailManagementEventSelectorType}
                    itemProperty={(item: Contract.EntityModel) => cloudTrailTrailEventSelectorTypeTranslator((item.entity as Contract.AwsCloudTrailTrail).managementEventSelectorType)}
                    key={Contract.EntityModelProperty.AwsCloudTrailTrailManagementEventSelectorType}
                    title={localization.columns.managementEventSelectorType()}/>,
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsCloudTrailTrailModel) => ({
                                [localization.columns.bucketObjectEventSelectorType()]: cloudTrailTrailEventSelectorTypeTranslator((item.entity as Contract.AwsCloudTrailTrail).bucketObjectEventSelectorType)
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <AwsCloudTrailTrailEventSelectorTypeFilter placeholder={localization.columns.bucketObjectEventSelectorType()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsCloudTrailTrailBucketObjectEventSelectorType}
                    itemProperty={(item: Contract.EntityModel) => cloudTrailTrailEventSelectorTypeTranslator((item.entity as Contract.AwsCloudTrailTrail).bucketObjectEventSelectorType)}
                    key={Contract.EntityModelProperty.AwsCloudTrailTrailBucketObjectEventSelectorType}
                    title={localization.columns.bucketObjectEventSelectorType()}/>,
                commonEncryptedResourceDefinition.columns.encryptionExistsColumn,
                commonEncryptedResourceDefinition.columns.kmsEncryptionKeyColumn,
                commonResourceDefinition.columns.regionColumn,
                commonResourceDefinition.columns.regionLocationColumn,
                commonResourceDefinition.columns.tagsColumn,
                commonResourceDefinition.columns.attributesColumn,
                commonResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
                commonResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
                commonResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
            ]),
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAwsCloudTrailTrailRequest(
                new Contract.EntityControllerGetEntityModelPageAwsCloudTrailTrailRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsCloudTrailTrailBucketObjectEventSelectorType]),
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsCloudTrailTrailEnabled]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsCloudTrailTrailManagementEventSelectorType])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags])),
                new Contract.EntityControllerGetEntityModelPageAwsResourceRequestEncrypted(
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEncryptedResourceEncryptionExists]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEncryptedResourceKmsEncryptionKeys]))));
}