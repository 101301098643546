import { DataTable, DataTableColumn, DataTableColumnRenderProps, InlineItems, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, InfoCard, useTheme } from "../../../../../../../../../../../../common";

type FirewallRuleInfoCardProps = {
    firewallRules: Contract.GcpAppEngineApplicationFirewallRule[];
};

export function FirewallRuleInfoCard({ firewallRules }: FirewallRuleInfoCardProps) {
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpAppEngineApplicationDefinition.firewallRuleInfoCard",
            () => ({
                columns: {
                    action: "Action",
                    description: "Description",
                    priority: "Priority",
                    subnets: "Source Range"
                },
                title: "Firewall Rules"
            }));

    const theme = useTheme();
    return (
        <InfoCard title={localization.title()}>
            <DataTable
                fetchItems={() => firewallRules}
                getItemId={(item: Contract.GcpAppEngineApplicationFirewallRule) => _.indexOf(firewallRules, item).toString()}
                sortOptions={{ enabled: false }}
                sx={{ maxHeight: theme.spacing(60) }}
                variant="card">
                <DataTableColumn
                    id="priority"
                    itemProperty={(firewallRule: Contract.GcpAppEngineApplicationFirewallRule) => firewallRule.priority}
                    title={localization.columns.priority()}/>
                <DataTableColumn
                    id="action"
                    itemProperty={(firewallRule: Contract.GcpAppEngineApplicationFirewallRule) => firewallRule.action}
                    title={localization.columns.action()}/>
                <DataTableColumn
                    id="subnets"
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.GcpAppEngineApplicationFirewallRule>) =>
                            <InlineItems
                                items={item.subnets}
                                variant="itemPlusItemCount"/>}
                    title={localization.columns.subnets()}/>
                <DataTableColumn
                    id="description"
                    itemProperty={(firewallRule: Contract.GcpAppEngineApplicationFirewallRule) => firewallRule.description}
                    title={localization.columns.description()}/>
            </DataTable>
        </InfoCard>);
}