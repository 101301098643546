﻿import { useMemo } from "react";
import { Contract } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";
import { useCiContainerImageDefinition } from "./useCiContainerImageDefinition";
import { useCiContainerImageRepositoryDefinition } from "./useCiContainerImageRepositoryDefinition";
import { useCiDefaultEntityDefinition } from "./useCiDefaultEntityDefinition";


export function useCiEntityDefinition(definitionData: DefinitionData) {
    const useDefinition =
        useMemo(
            () => {
                switch (definitionData.entityTypeEntitiesViewName) {
                    case Contract.TypeNames.CiContainerImageRepository:
                        return useCiContainerImageRepositoryDefinition;
                    case Contract.TypeNames.CiContainerImage:
                        return useCiContainerImageDefinition;
                    default:
                        return useCiDefaultEntityDefinition;
                }
            },
            []);

    return useDefinition(definitionData);
}