import { Sx } from "@infrastructure";
import { SvgIconProps, useTheme } from "@mui/material";
import React from "react";
import { FailedIcon, PassedIcon } from ".";

export type ResultIconProps =
    SvgIconProps & {
        success: boolean;
    };

export function ResultIcon({ success, sx, ...props }: ResultIconProps) {
    const theme = useTheme();
    return success
        ? <PassedIcon
            {...props}
            sx={Sx.combine(sx, { color: theme.palette.success.main })}/>
        : <FailedIcon
            {...props}
            sx={Sx.combine(sx, { color: theme.palette.error.main })}/>;
}