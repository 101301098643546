import { NoneIcon, PublicIcon, Sx, UnexpectedError } from "@infrastructure";
import { SvgIconProps } from "@mui/material";
import React from "react";
import { Contract, useTheme } from "..";

type NetworkAccessScopeIconProps =
    SvgIconProps & {
        accessScope: Contract.NetworkAccessScope;
    };

export function NetworkAccessScopeIcon({ accessScope, sx, ...props }: NetworkAccessScopeIconProps) {
    const theme = useTheme();
    return (
        accessScope === Contract.NetworkAccessScope.None
            ? <NoneIcon
                sx={sx}
                {...props}/>
            : <PublicIcon
                sx={
                    Sx.combine(
                        sx,
                        {
                            color:
                                (() => {
                                    switch (accessScope) {
                                        case Contract.NetworkAccessScope.All:
                                            return theme.palette.severity(Contract.Severity.Critical);
                                        case Contract.NetworkAccessScope.Wide:
                                            return theme.palette.severity(Contract.Severity.High);
                                        case Contract.NetworkAccessScope.Restricted:
                                            return theme.palette.severity(Contract.Severity.Medium);
                                        default:
                                            throw new UnexpectedError("accessScope", accessScope);
                                    }
                                })()
                        })}
                {...props}/>);
}