﻿import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function NewZealandIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <rect
                fill="#00247D"
                height="16"
                rx="2"
                width="24"
                y="4"/>
            <path
                clipRule="evenodd"
                d="M18.4316 7.26338L18.6772 8.01952H19.3341L19.5539 7.983L19.5036 8.01952H19.5286L19.4485 8.05957L18.8041 8.52774L19.0072 9.15294L19.1095 9.34995L19.0594 9.31356L19.0672 9.33737L19.0042 9.27343L18.3909 8.82784L17.7784 9.2728L17.7149 9.33737L17.7227 9.31333L17.6723 9.34995L17.7752 9.15168L17.9779 8.52774L17.3341 8.05996L17.2532 8.01952H17.2784L17.2281 7.983L17.4479 8.01952H18.1431L18.3576 7.3593L18.3909 7.13818L18.4101 7.19749L18.418 7.17334L18.4316 7.26338Z"
                fill="white"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M18.4177 7.6349L18.5803 8.13536H19.0141L19.1592 8.11124L19.126 8.13536H19.1426L19.0893 8.16202L18.6639 8.4711L18.7982 8.88429L18.8658 9.01451L18.8327 8.99046L18.8378 9.00618L18.7962 8.96395L18.3909 8.66947L17.9864 8.96332L17.9442 9.00618L17.9494 8.99023L17.9159 9.01451L17.9842 8.88303L18.118 8.47124L17.6924 8.16202L17.6391 8.13536H17.6557L17.6225 8.11124L17.7677 8.13536H18.2271L18.3692 7.69827L18.391 7.55298L18.4037 7.59195L18.4088 7.57621L18.4177 7.6349Z"
                fill="#CC142B"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M21.4077 9.97086L21.6268 10.645H22.2111L22.4066 10.6125L22.3619 10.645H22.3842L22.3127 10.6808L21.7396 11.0971L21.9203 11.6532L22.0115 11.8289L21.9668 11.7964L21.9737 11.8177L21.9175 11.7606L21.372 11.3642L20.8264 11.7606L20.7702 11.8177L20.7771 11.7964L20.7324 11.8289L20.8236 11.6532L21.0043 11.0971L20.4318 10.6812L20.3594 10.645H20.382L20.3373 10.6125L20.5328 10.645H21.1512L21.3425 10.0562L21.372 9.86072L21.389 9.91316L21.3959 9.892L21.4077 9.97086Z"
                fill="white"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M21.3941 10.3438L21.5296 10.7608H21.8913L22.0123 10.7407L21.9846 10.7608H21.9983L21.9543 10.7828L21.5995 11.0406L21.7116 11.3858L21.7675 11.4935L21.7402 11.4736L21.7444 11.4865L21.7101 11.4517L21.3718 11.206L21.0344 11.4511L20.9995 11.4865L21.0038 11.4733L20.9761 11.4935L21.0327 11.3845L21.1444 11.0406L20.7901 10.7832L20.7453 10.7608H20.7593L20.7317 10.7407L20.8526 10.7608H21.2353L21.3537 10.3966L21.3719 10.2755L21.3825 10.308L21.3867 10.2949L21.3941 10.3438Z"
                fill="#CC142B"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M14.9592 10.758L15.2053 11.5155H15.862L16.0817 11.479L16.0314 11.5155H16.0567L15.9758 11.5559L15.3319 12.0237L15.5347 12.6477L15.6376 12.8459L15.5872 12.8093L15.595 12.8333L15.5315 12.7688L14.919 12.3238L14.3056 12.7695L14.2427 12.8333L14.2504 12.8096L14.2003 12.8459L14.3026 12.649L14.5058 12.0237L13.8614 11.5555L13.7813 11.5155H13.8062L13.756 11.479L13.9757 11.5155H14.6709L14.8859 10.8539L14.9189 10.6342L14.9381 10.6931L14.9458 10.6693L14.9592 10.758Z"
                fill="white"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M14.9455 11.1307L15.1081 11.6312H15.5422L15.6873 11.6071L15.6541 11.6312H15.6707L15.6174 11.6579L15.1918 11.9671L15.3256 12.3789L15.3939 12.5104L15.3604 12.4861L15.3656 12.502L15.3234 12.4592L14.919 12.1653L14.5136 12.4598L14.472 12.502L14.4772 12.4863L14.4441 12.5104L14.5117 12.3801L14.6459 11.9669L14.2205 11.6579L14.1672 11.6312H14.1838L14.1506 11.6071L14.2958 11.6312H14.755L14.897 11.1941L14.9189 11.0488L14.9316 11.0878L14.9367 11.0721L14.9455 11.1307Z"
                fill="#CC142B"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M18.4356 16.1624L18.7089 17.0033H19.4379L19.6818 16.9628L19.626 17.0033H19.6539L19.5645 17.048L18.8495 17.5675L19.0747 18.2607L19.1887 18.4803L19.1329 18.4397L19.1415 18.4663L19.0712 18.3949L18.3909 17.9006L17.7105 18.3949L17.6402 18.4663L17.6489 18.4397L17.593 18.4803L17.707 18.2607L17.9323 17.5673L17.218 17.0484L17.1279 17.0033H17.156L17.1002 16.9628L17.3441 17.0033H18.1156L18.3542 16.2688L18.391 16.0249L18.4122 16.0903L18.4208 16.0639L18.4356 16.1624Z"
                fill="white"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M18.422 16.5353L18.6117 17.1191H19.118L19.2874 17.091L19.2487 17.1191H19.2679L19.2061 17.1501L18.7093 17.511L18.8657 17.992L18.945 18.1448L18.9061 18.1166L18.9121 18.1351L18.8632 18.0854L18.391 17.7423L17.9179 18.086L17.8696 18.1351L17.8755 18.1168L17.837 18.1448L17.9157 17.9933L18.0724 17.5108L17.5764 17.1505L17.5138 17.1191H17.5333L17.4946 17.091L17.664 17.1191H18.1997L18.3654 16.6092L18.3909 16.4397L18.4057 16.4852L18.4116 16.4668L18.422 16.5353Z"
                fill="#CC142B"
                fillRule="evenodd"/>
            <path
                d="M0 6C0 4.89543 0.895431 4 2 4H12V13H0V6Z"
                fill="#012169"/>
            <path
                clipRule="evenodd"
                d="M0 11.875V13H1.51875L6 9.64375L10.5 13H12V11.8563L7.5 8.51875L12 5.1625V4H10.5375L5.98125 7.39375L1.49347 4.0647C0.863514 4.22912 0.35455 4.69299 0.127745 5.29514L4.48125 8.5375L0 11.875Z"
                fill="white"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M0 13H1.0125L4.6125 10.3L4.5 9.64375L0 13ZM12 13V12.25L7.95 9.26875H6.91875L12 13ZM12 4.05625V4H11.0625L7.36875 6.75625L7.33125 7.58125L12 4.05625ZM0.272509 4.9915C0.380814 4.80638 0.518024 4.64023 0.677947 4.49924L4.48125 7.3H3.35625L0.272509 4.9915Z"
                fill="#C8102E"
                fillRule="evenodd"/>
            <path
                d="M4.51875 4V13H7.51875V4H4.51875ZM0 7V10H12V7H0Z"
                fill="white"/>
            <path
                d="M0 7.61875V9.41875H12V7.61875H0ZM5.11875 4V13H6.91875V4H5.11875Z"
                fill="#C8102E"/>
        </SvgIcon>);
}