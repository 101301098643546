﻿import { Stack, Typography } from "@mui/material";
import React from "react";
import { Contract } from "../../../../common";
import { useAzureStorageStorageAccountBlobContainerPublicAccessTranslator } from "../../hooks";
import { PublicAccessIcon } from "./icons";

type AzureStorageStorageAccountBlobContainerPublicAccessProps = {
    publicAccess: Contract.AzureStorageStorageAccountBlobContainerPublicAccess;
};

export function AzureStorageStorageAccountBlobContainerPublicAccess({ publicAccess }: AzureStorageStorageAccountBlobContainerPublicAccessProps) {
    const storageStorageAccountBlobContainerPublicAccessTranslator = useAzureStorageStorageAccountBlobContainerPublicAccessTranslator();
    return (
        <Stack
            alignItems="center"
            direction="row"
            spacing={1}>
            <PublicAccessIcon
                publicAccess={publicAccess}
                sx={{ fontSize: "18px" }}/>
            <Typography noWrap={true}>
                {storageStorageAccountBlobContainerPublicAccessTranslator(publicAccess)}
            </Typography>
        </Stack>);
}