﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, entityModelStore, InlineEntities, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetEntitiesRiskContext } from "../../../useGetEntitiesRiskContext";

export function useGetGcpComputeInstancesRiskContext() {
    return useMemo(
        () => useGcpComputeInstancesRiskContext,
        []);
}

function useGcpComputeInstancesRiskContext(instanceIdsOrModels: string[] | Contract.GcpComputeInstanceModel[]) {
    const instanceModels =
        entityModelStore.useGet(
            _.isString(instanceIdsOrModels[0])
                ? instanceIdsOrModels as string[]
                : undefined) ??
        instanceIdsOrModels as Contract.EntityModel[];
    const entityRiskContext =
        useGetEntitiesRiskContext()(
            instanceModels,
            Contract.TypeNames.GcpComputeInstance);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.contexts.useGetGcpComputeInstancesRiskContext",
            () => ({
                instances: "The {{translatedInstanceTemplateTypeName}} launched {{instances}}",
                runningInstances: {
                    empty: "All VM instances are not running",
                    some: [
                        "{{instances}} has state of **running**",
                        "{{instances}} have state of **running**"
                    ]
                }
            }));
    const runningInstanceModels =
        useMemo(
            () =>
                _.filter(
                    instanceModels,
                    instanceModel => (instanceModel.entity as Contract.GcpComputeInstance).status === Contract.GcpComputeInstanceStatus.Running),
            [instanceIdsOrModels]);

    return {
        ...entityRiskContext,
        instances:
            _.isEmpty(instanceIdsOrModels)
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.instances({
                        instances:
                            <InlineEntities
                                entityIdsOrModels={instanceIdsOrModels}
                                entityTypeName={Contract.TypeNames.GcpComputeInstance}
                                variant="itemCountAndType"/>,
                        translatedInstanceTemplateTypeName:
                            entityTypeNameTranslator(
                                Contract.TypeNames.GcpComputeInstanceTemplate,
                                {
                                    includeServiceName: false,
                                    variant: "text"
                                })
                    })),
        runningInstances:
            _.isEmpty(instanceIdsOrModels)
                ? undefined
                : _.isEmpty(runningInstanceModels)
                    ? new RiskDefinitionContextItem(localization.runningInstances.empty())
                    : new RiskDefinitionContextItem(
                        localization.runningInstances.some(
                            runningInstanceModels.length,
                            {
                                instances:
                                    <InlineEntities
                                        entityIdsOrModels={runningInstanceModels}
                                        entityTypeName={Contract.TypeNames.GcpComputeInstance}
                                        variant="itemCountAndType"/>
                            }))
    };
}