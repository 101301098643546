import { Optional, TimeFormatter, useLocalization } from "@infrastructure";
import _ from "lodash";
import { Contract } from "../../../../../common";

export function useAwsEc2InstanceStatusTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useAwsEc2InstanceStatusTranslator",
            () => ({
                [Contract.TypeNames.AwsEc2InstanceStatus]: {
                    [Contract.AwsEc2InstanceStatus.Pending]: "Pending",
                    [Contract.AwsEc2InstanceStatus.Running]: "Running",
                    [Contract.AwsEc2InstanceStatus.ShuttingDown]: "Shutting down",
                    [Contract.AwsEc2InstanceStatus.Stopped]: "Stopped",
                    [Contract.AwsEc2InstanceStatus.Stopping]: "Stopping",
                    [Contract.AwsEc2InstanceStatus.Terminated]: "Terminated"
                },
                terminatedWithTime: "Stopped (Since {{stopTime}})"
            }));
    return (status: Contract.AwsEc2InstanceStatus, stopTime: Optional<string> = undefined) =>
        status === Contract.AwsEc2InstanceStatus.Stopped &&
        !_.isNil(stopTime)
            ? localization.terminatedWithTime({ stopTime: TimeFormatter.monthDayAndYear(stopTime) })
            : localization[Contract.TypeNames.AwsEc2InstanceStatus][status]();
}