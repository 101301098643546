import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useAwsElastiCacheCacheStatusTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useAwsElastiCacheCacheStatusTranslator",
            () => ({
                [Contract.TypeNames.AwsElastiCacheCacheStatus]: {
                    [Contract.AwsElastiCacheCacheStatus.Available]: "Available",
                    [Contract.AwsElastiCacheCacheStatus.CreateFailed]: "Create-Failed",
                    [Contract.AwsElastiCacheCacheStatus.Creating]: "Creating",
                    [Contract.AwsElastiCacheCacheStatus.Deleting]: "Deleting",
                    [Contract.AwsElastiCacheCacheStatus.Modifying]: "Modifying",
                    [Contract.AwsElastiCacheCacheStatus.Snapshotting]: "Snapshotting"
                }
            }));
    return (statusType: Contract.AwsElastiCacheCacheStatus) =>
        localization[Contract.TypeNames.AwsElastiCacheCacheStatus][statusType]();
}