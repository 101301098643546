import { useLocalization } from "./useLocalization";

export enum ReportContentType {
    Csv = "Csv",
    Pdf = "Pdf"
}

export function useGetExportFileName(contentType: ReportContentType) {
    const localization =
        useLocalization(
            "infrastructure.hooks.useGetExportFileName",
            () => ({
                fileName: {
                    default: "{{prefix}}_{{time | TimeFormatter.fileNameDateTime}}.{{contentType}}",
                    filtered: "{{prefix}}_Filtered_{{time | TimeFormatter.fileNameDateTime}}.{{contentType}}",
                    notFiltered: "{{prefix}}_All_{{time | TimeFormatter.fileNameDateTime}}.{{contentType}}"
                },
                reportContentType: {
                    [ReportContentType.Csv]: "csv",
                    [ReportContentType.Pdf]: "pdf"
                }
            }));

    return (fileNameOptions: ExportFileNameOptions) => {
        const fileNameTranslator =
            fileNameOptions.default
                ? localization.fileName.default
                : fileNameOptions.filtered
                    ? localization.fileName.filtered
                    : localization.fileName.notFiltered;

        return fileNameTranslator(
            {
                contentType: localization.reportContentType[contentType](),
                prefix: fileNameOptions.prefix,
                time: Date.now()
            }).
            replace(/[\\/:"*?<>|]/g, "");
    };
}

export type ExportFileNameOptions = {
    default?: boolean;
    filtered: boolean;
    prefix: string;
};