import { AnalyticsEventActionType, ApplicationViewValue, EventHandlerRegister, Loading, makeContextProvider, useEvent, useExecuteOperation, useLocalization, useRoute, useSetRoute, useTrackAnalyticsEvent } from "@infrastructure";
import { Stack, Tab, Tabs } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, PermissionManagementController, scopeNodeModelStore, tenantModelStore, useLayoutOptions, useTheme } from "../../../../common";
import { UserView } from "../../userView";
import { Table } from "./components";

export class ApproverUserPermissionRequestsContext {
    constructor(
        public getFiltersResponse: Contract.PermissionManagementControllerGetApproverUserPermissionRequestFiltersResponse,
        public refreshFilters: () => Promise<void>,
        public registerPermissionRequestModelChange: EventHandlerRegister<() => Promise<void>>,
        public triggerPermissionRequestModelChange: () => Promise<void>,
        public userIds: string[]) {
    }
}

export enum ApproverUserPermissionRequestViewType {
    Active = "active",
    History = "history"
}

export const [useApproverUserPermissionRequestsContext, , useApproverUserPermissionRequestsContextProvider] = makeContextProvider<ApproverUserPermissionRequestsContext>();

type ApproverUserPermissionRequestsProps = {
    applicationView: ApplicationViewValue;
};

export function ApproverUserPermissionRequests({ applicationView }: ApproverUserPermissionRequestsProps) {
    useTrackAnalyticsEvent(AnalyticsEventActionType.PageView);

    const localization =
        useLocalization(
            "views.user.approverUserPermissionRequests",
            () => ({
                tabs: {
                    active: "Active",
                    history: "History"
                },
                title: "Access Reviews"
            }));

    useLayoutOptions({ view: { title: localization.title() } });

    const scopeNodeModelMap = scopeNodeModelStore.useGetScopeNodeMap();
    const [{ getFiltersResponse, userIds }, executeGetFilters] =
        useExecuteOperation(
            ApproverUserPermissionRequests,
            async () => {
                const getFiltersResponse = await PermissionManagementController.getApproverUserPermissionRequestFilters();
                await tenantModelStore.get(getFiltersResponse.tenantIdItems.items);

                if (_.some(
                    getFiltersResponse.tenantIdItems.items,
                    tenantId =>
                        !_.has(
                            scopeNodeModelMap,
                            tenantId))) {
                    await scopeNodeModelStore.notify();
                }

                const { userIds } = await PermissionManagementController.getUserPrincipals();
                return { getFiltersResponse, userIds };
            });

    const { view } = useRoute(`/${applicationView}/${UserView.ApproverUserPermissionRequests}/{view}`);
    const setRoute = useSetRoute();

    const orderedTabs =
        useMemo(
            () => [
                ApproverUserPermissionRequestViewType.Active,
                ApproverUserPermissionRequestViewType.History
            ],
            []);

    const currentView =
        useMemo(
            () =>
                _.includes(orderedTabs, view)
                    ? view as ApproverUserPermissionRequestViewType
                    : _.first(orderedTabs),
            [orderedTabs, view]);

    const [registerPermissionRequestModelChange, triggerPermissionRequestModelChange] = useEvent<() => Promise<void>>();

    const [, , ContextProvider] =
        useApproverUserPermissionRequestsContextProvider(
            () =>
                new ApproverUserPermissionRequestsContext(
                    getFiltersResponse,
                    executeGetFilters,
                    registerPermissionRequestModelChange,
                    triggerPermissionRequestModelChange,
                    userIds),
            [getFiltersResponse, userIds]);

    const theme = useTheme();
    return (
        <ContextProvider>
            <Stack sx={{ height: "100%" }}>
                <Tabs
                    indicatorColor="secondary"
                    sx={{
                        border: "unset",
                        padding: theme.spacing(0, 2)
                    }}
                    value={currentView}
                    variant="scrollable"
                    onChange={(_event, view) => setRoute(`/${applicationView}/${UserView.ApproverUserPermissionRequests}/${view}`)}>
                    {_.map(
                        orderedTabs,
                        tab =>
                            <Tab
                                key={tab}
                                label={localization.tabs[tab]()}
                                sx={{
                                    marginRight: theme.spacing(3),
                                    padding: 0
                                }}
                                value={tab}/>)}
                </Tabs>
                <Stack
                    sx={{
                        height: "100%",
                        overflow: "hidden auto",
                        width: "100%"
                    }}>
                    <Loading key={currentView}>
                        {currentView && <Table viewType={currentView}/>}
                    </Loading>
                </Stack>
            </Stack>
        </ContextProvider>);
}