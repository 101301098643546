import { DataTableColumn, DataTableSortType, EnumValuesFilter, optionalTableCell, PagedValuesFilter, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { useAwsCommonEncryptedResourceDefinition, useAwsCommonNetworkedResourceDefinition, useAwsCommonResourceDefinition } from ".";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { useAwsSageMakerNotebookInstanceMetadataServiceVersionTranslator, useAwsSageMakerNotebookInstanceStatusTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";
import { EntityTableDefinition } from "../../useDefinition";

export function useAwsSageMakerNotebookInstanceDefinition(definitionData: DefinitionData) {
    const commonEncryptedResourceDefinition = useAwsCommonEncryptedResourceDefinition(definitionData);
    const commonNetworkedResourceDefinition = useAwsCommonNetworkedResourceDefinition(definitionData);
    const commonResourceDefinition = useAwsCommonResourceDefinition(definitionData);

    const entitiesExportOptions = useEntitiesExportOptions();
    const notebookInstanceMetadataServiceVersionTranslator = useAwsSageMakerNotebookInstanceMetadataServiceVersionTranslator();
    const notebookInstanceStatusTranslator = useAwsSageMakerNotebookInstanceStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.aws.useAwsSageMakerNotebookInstanceDefinition",
            () => ({
                columns: {
                    metadataServiceVersion: "Metadata Version",
                    rawType: "Instance Type",
                    roleReference: "Role",
                    rootAccess: {
                        false: "Disabled",
                        title: "Root Access",
                        true: "Enabled"
                    },
                    status: "Status",
                    unrestrictedOutboundAccess: {
                        false: "Disabled",
                        title: "Direct Internet Access",
                        true: "Enabled"
                    }
                }
            }));

    return new EntityTableDefinition(
        _.filter(
            [
                commonResourceDefinition.columns.tenantColumn,
                commonResourceDefinition.columns.creationTimeColumn,
                commonResourceDefinition.columns.creatorIdentityCsvColumn,
                commonResourceDefinition.columns.creatorOriginatorEntityCsvColumn,
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsSageMakerNotebookInstanceModel) => ({
                                [localization.columns.status()]: notebookInstanceStatusTranslator((item.entity as Contract.AwsSageMakerNotebookInstance).status)
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <EnumValuesFilter
                                    enumType={Contract.AwsSageMakerNotebookInstanceStatus}
                                    enumTypeTranslator={notebookInstanceStatusTranslator}
                                    placeholder={localization.columns.status()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsSageMakerNotebookInstanceStatus}
                    itemProperty={(item: Contract.AwsSageMakerNotebookInstanceModel) => notebookInstanceStatusTranslator((item.entity as Contract.AwsSageMakerNotebookInstance).status)}
                    key={Contract.EntityModelProperty.AwsSageMakerNotebookInstanceStatus}
                    title={localization.columns.status()}/>,
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsSageMakerNotebookInstanceModel) => ({
                                [localization.columns.unrestrictedOutboundAccess.title()]:
                                    (item.entity as Contract.AwsSageMakerNotebookInstance).unrestrictedOutboundAccess
                                        ? localization.columns.unrestrictedOutboundAccess.true()
                                        : localization.columns.unrestrictedOutboundAccess.false()
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <ValuesFilter
                                    placeholder={localization.columns.unrestrictedOutboundAccess.title()}
                                    sorted={false}>
                                    <ValuesFilterItem
                                        title={localization.columns.unrestrictedOutboundAccess.true()}
                                        value={true}/>
                                    <ValuesFilterItem
                                        title={localization.columns.unrestrictedOutboundAccess.false()}
                                        value={false}/>
                                </ValuesFilter>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsSageMakerNotebookInstanceUnrestrictedOutboundAccess}
                    itemProperty={
                        (item: Contract.AwsSageMakerNotebookInstanceModel) =>
                            (item.entity as Contract.AwsSageMakerNotebookInstance).unrestrictedOutboundAccess
                                ? localization.columns.unrestrictedOutboundAccess.true()
                                : localization.columns.unrestrictedOutboundAccess.false()}
                    key={Contract.EntityModelProperty.AwsSageMakerNotebookInstanceUnrestrictedOutboundAccess}
                    title={localization.columns.unrestrictedOutboundAccess.title()}/>,
                commonEncryptedResourceDefinition.columns.encryptionExistsColumn,
                commonEncryptedResourceDefinition.columns.kmsEncryptionKeyColumn,
                <DataTableColumn
                    exportOptions={
                        entitiesExportOptions<Contract.AwsResourceModel>(
                            Contract.TypeNames.AwsIamRole,
                            item =>
                                _.isNil((item.entity as Contract.AwsSageMakerNotebookInstance).roleReference)
                                    ? []
                                    : [(item.entity as Contract.AwsSageMakerNotebookInstance).roleReference!.idReference],
                            localization.columns.roleReference())}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedEntityFilter
                                    getEntityIdPage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.AwsSageMakerNotebookInstanceRole)}
                                    placeholder={localization.columns.roleReference()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsSageMakerNotebookInstanceRole}
                    key={Contract.EntityModelProperty.AwsSageMakerNotebookInstanceRole}
                    render={
                        optionalTableCell<Contract.AwsSageMakerNotebookInstanceModel>(
                            item =>
                                _.isNil((item.entity as Contract.AwsSageMakerNotebookInstance).roleReference)
                                    ? undefined
                                    : <EntitiesCell
                                        entityIdsOrModels={(item.entity as Contract.AwsSageMakerNotebookInstance).roleReference!.idReference}
                                        entityTypeName={Contract.TypeNames.AwsIamRole}
                                        entityVariant="iconText"/>)}
                    sortOptions={{ type: DataTableSortType.Numeric }}
                    title={localization.columns.roleReference()}/>,
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsSageMakerNotebookInstanceModel) => ({
                                [localization.columns.rootAccess.title()]:
                                    (item.entity as Contract.AwsSageMakerNotebookInstance).rootAccess
                                        ? localization.columns.rootAccess.true()
                                        : localization.columns.rootAccess.false()
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <ValuesFilter
                                    placeholder={localization.columns.rootAccess.title()}
                                    sorted={false}>
                                    <ValuesFilterItem
                                        title={localization.columns.rootAccess.true()}
                                        value={true}/>
                                    <ValuesFilterItem
                                        title={localization.columns.rootAccess.false()}
                                        value={false}/>
                                </ValuesFilter>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsSageMakerNotebookInstanceRootAccess}
                    itemProperty={
                        (item: Contract.AwsSageMakerNotebookInstanceModel) =>
                            (item.entity as Contract.AwsSageMakerNotebookInstance).rootAccess
                                ? localization.columns.rootAccess.true()
                                : localization.columns.rootAccess.false()}
                    key={Contract.EntityModelProperty.AwsSageMakerNotebookInstanceRootAccess}
                    title={localization.columns.rootAccess.title()}/>,
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsSageMakerNotebookInstanceModel) => ({
                                [localization.columns.metadataServiceVersion()]: notebookInstanceMetadataServiceVersionTranslator((item.entity as Contract.AwsSageMakerNotebookInstance).metadataServiceVersion)
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <EnumValuesFilter
                                    enumType={Contract.AwsSageMakerNotebookInstanceMetadataServiceVersion}
                                    enumTypeTranslator={notebookInstanceMetadataServiceVersionTranslator}
                                    placeholder={localization.columns.metadataServiceVersion()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsSageMakerNotebookInstanceMetadataServiceVersion}
                    itemProperty={(item: Contract.AwsSageMakerNotebookInstanceModel) => notebookInstanceMetadataServiceVersionTranslator((item.entity as Contract.AwsSageMakerNotebookInstance).metadataServiceVersion)}
                    key={Contract.EntityModelProperty.AwsSageMakerNotebookInstanceMetadataServiceVersion}
                    selectorOptions={{ default: false }}
                    title={localization.columns.metadataServiceVersion()}/>,
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsSageMakerNotebookInstanceModel) =>
                                ({ [localization.columns.rawType()]: (item.entity as Contract.AwsSageMakerNotebookInstance).rawType })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedValuesFilter
                                    getValuePage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.AwsSageMakerNotebookInstanceRawType)}
                                    placeholder={localization.columns.rawType()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsSageMakerNotebookInstanceRawType}
                    itemProperty={(item: Contract.AwsSageMakerNotebookInstanceModel) => (item.entity as Contract.AwsSageMakerNotebookInstance).rawType}
                    key={Contract.EntityModelProperty.AwsSageMakerNotebookInstanceRawType}
                    selectorOptions={{ default: false }}
                    title={localization.columns.rawType()}/>,
                commonNetworkedResourceDefinition.columns.getVpcsColumn(),
                commonNetworkedResourceDefinition.columns.securityGroupsColumn,
                commonResourceDefinition.columns.regionColumn,
                commonResourceDefinition.columns.regionLocationColumn,
                commonResourceDefinition.columns.tagsColumn,
                commonResourceDefinition.columns.attributesColumn,
                commonResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
                commonResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
                commonResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
            ]),
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAwsSageMakerNotebookInstanceRequest(
                new Contract.EntityControllerGetEntityModelPageAwsSageMakerNotebookInstanceRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsSageMakerNotebookInstanceMetadataServiceVersion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsSageMakerNotebookInstanceRawType]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsSageMakerNotebookInstanceRole]),
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsSageMakerNotebookInstanceRootAccess]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsSageMakerNotebookInstanceStatus]),
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsSageMakerNotebookInstanceUnrestrictedOutboundAccess])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags])),
                new Contract.EntityControllerGetEntityModelPageAwsResourceRequestEncrypted(
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEncryptedResourceEncryptionExists]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEncryptedResourceKmsEncryptionKeys])),
                new Contract.EntityControllerGetEntityModelPageAwsResourceRequestNetworked(
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsNetworkedResourceSecurityGroups]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsNetworkedResourceVpcs]))));
}