import { ConfirmButton, useLocalization, useSetRoute, WelcomeView } from "@infrastructure";
import { Stack } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, CustomerConsoleAppUrlHelper, TenantIcon, tenantModelStore, useTheme } from "../../../../../../../../../common";
import { useSetScopesContext } from "../../../../../Scopes";

export function Welcome() {
    const setRoute = useSetRoute();
    const gciTenantModels = tenantModelStore.useGetGciTenants();
    const [nonGcpOrganizationGciTenantModels, validGciTenantModels] =
        useMemo(
            () => {
                const validGciTenantModels =
                    _.filter(
                        gciTenantModels,
                        gciTenantModel => gciTenantModel.status === Contract.GciTenantModelStatus.Valid);

                const nonGcpOrganizationGciTenantModels =
                    _.filter(
                        validGciTenantModels,
                        validGciTenantModel => _.isNil(validGciTenantModel.configuration.gcpOrganizationId));

                return [nonGcpOrganizationGciTenantModels, validGciTenantModels];
            },
            [gciTenantModels]);
    const setScopesContext = useSetScopesContext();

    const localization =
        useLocalization(
            "views.customer.scopes.hooks.useDefinition.hooks.useGcpDefinition.welcome",
            () => ({
                action: {
                    organization: {
                        confirmTitle: "To onboard a GCP organization, first onboard the Google Workspace that’s linked to the organization. Afterwards, you can proceed with organization onboarding. Do you want to onboard the Google Workspace now?",
                        title: "Add Organization"
                    },
                    tenant: {
                        confirmTitle: "To onboard a GCP project, first onboard the Google Workspace that’s linked to the project. Afterwards, you can proceed with project onboarding. Do you want to onboard the Google Workspace now?",
                        title: "Add Project"
                    }
                },
                title: "Connect your GCP Environment"
            }));

    const theme = useTheme();
    return (
        <WelcomeView title={localization.title()}>
            <Stack
                spacing={4}
                sx={{
                    height: "100%",
                    width: "100%"
                }}>
                <ConfirmButton
                    disableConfirm={!_.isEmpty(nonGcpOrganizationGciTenantModels)}
                    icon={<TenantIcon tenantType={Contract.TenantType.Gcp}/>}
                    message={localization.action.organization.confirmTitle()}
                    sx={{
                        padding: theme.spacing(1, 3),
                        width: "100%"
                    }}
                    variant="outlined"
                    onClick={
                        () => {
                            if (_.isEmpty(nonGcpOrganizationGciTenantModels)) {
                                setRoute(CustomerConsoleAppUrlHelper.getConfigurationIntegrationsIdentityProviderTenantsGciRelativeUrl(true));
                            } else {
                                setRoute(CustomerConsoleAppUrlHelper.getConfigurationIntegrationsCloudProviderTenantOrganizationsGcpRelativeUrl(true));
                            }
                        }}>
                    {localization.action.organization.title()}
                </ConfirmButton>
                <ConfirmButton
                    disableConfirm={!_.isEmpty(validGciTenantModels)}
                    icon={<TenantIcon tenantType={Contract.TenantType.Gcp}/>}
                    message={localization.action.tenant.confirmTitle()}
                    sx={{
                        padding: theme.spacing(1, 3),
                        width: "100%"
                    }}
                    variant="outlined"
                    onClick={
                        () => {
                            if (_.isEmpty(validGciTenantModels)) {
                                setRoute(CustomerConsoleAppUrlHelper.getConfigurationIntegrationsIdentityProviderTenantsGciRelativeUrl(true));
                            } else {
                                setScopesContext(
                                    context => ({
                                        ...context,
                                        addOrEditOpen: "scope"
                                    }));
                            }
                        }}>
                    {localization.action.tenant.title()}
                </ConfirmButton>
            </Stack>
        </WelcomeView>);
}