import { closestCenter, DndContext, DragEndEvent, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { restrictToParentElement, restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { arrayMove, SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { Loading } from "@infrastructure";
import { Stack } from "@mui/material";
import _ from "lodash";
import React, { useCallback, useMemo } from "react";
import { ComplianceHelper, CustomCompliance, useTheme } from "../../../../../../../../../common";
import { useCustomComplianceContext, useSetCustomComplianceContext } from "../../../AddOrEdit";
import { RiskPolicyTable } from "../components";
import { Section } from "../Section";

type SectionsProps = {
    complianceSection: CustomCompliance;
    onSectionsChanged: (section: CustomCompliance) => void;
    path: string[];
};

export function Sections({ complianceSection, onSectionsChanged, path }: SectionsProps) {
    const setCustomComplianceContext = useSetCustomComplianceContext();
    const { registerClearSelectedIdsClicked, sectionIdToSelectionDataMap } = useCustomComplianceContext();
    const dndSensors =
        useSensors(
            useSensor(
                PointerSensor,
                { activationConstraint: { distance: 10 } }));

    const selectedRiskPolicyIds =
        useMemo(
            () =>
                _.map(
                    complianceSection.riskPolicies,
                    riskPolicy => riskPolicy.identifier),
            [complianceSection.riskPolicies]);

    const onDragEnd =
        useCallback(
            ({ active, over }: DragEndEvent) => {
                if (_.isNil(over)) {
                    return;
                }

                const fromIndex =
                    _.findIndex(
                        complianceSection.sections,
                        section => ComplianceHelper.getCustomComplianceId(section) === active.id);
                const toIndex =
                    _.findIndex(
                        complianceSection.sections,
                        section => ComplianceHelper.getCustomComplianceId(section) === over.id);
                const newSections =
                    arrayMove(
                        complianceSection.sections,
                        fromIndex,
                        toIndex);

                onSectionsChanged({
                    ...complianceSection,
                    sections: newSections
                });
            },
            [complianceSection]);

    const theme = useTheme();
    return (
        <Stack spacing={1}>
            <DndContext
                collisionDetection={closestCenter}
                modifiers={[
                    restrictToVerticalAxis,
                    restrictToParentElement
                ]}
                sensors={dndSensors}
                onDragEnd={onDragEnd}>
                <Stack
                    sx={{
                        maxHeight: "100%",
                        overflowY: "auto"
                    }}>
                    <SortableContext
                        items={
                            _.map(
                                complianceSection.sections,
                                section => ComplianceHelper.getCustomComplianceId(section))}
                        strategy={verticalListSortingStrategy}>
                        {_.map(
                            complianceSection.sections,
                            (section, index) =>
                                <Section
                                    key={ComplianceHelper.getCustomComplianceId(section)}
                                    path={path}
                                    section={section}
                                    onDelete={
                                        () => {
                                            const updatedSections = [...complianceSection.sections];
                                            updatedSections.splice(index, 1);

                                            onSectionsChanged({
                                                ...complianceSection,
                                                sections: updatedSections
                                            });
                                        }}
                                    onSectionChanged={
                                        updatedSection =>
                                            onSectionsChanged({
                                                ...complianceSection,
                                                sections:
                                                    _.map(
                                                        complianceSection.sections,
                                                        section =>
                                                            ComplianceHelper.getCustomComplianceId(section) === ComplianceHelper.getCustomComplianceId(updatedSection)
                                                                ? updatedSection
                                                                : section)
                                            })}/>)}
                    </SortableContext>
                </Stack>
            </DndContext>
            {!_.isEmpty(complianceSection.riskPolicies) &&
                <Stack
                    sx={{
                        height:
                            _.size(selectedRiskPolicyIds) > 10
                                ? theme.spacing(61)
                                : undefined,
                        overflow: "hidden",
                        paddingLeft:
                            _.isEmpty(path)
                                ? undefined
                                : theme.spacing(1.5)
                    }}>
                    <Loading container="small">
                        <RiskPolicyTable
                            registerClearSelected={registerClearSelectedIdsClicked}
                            riskPolicyIds={selectedRiskPolicyIds}
                            selectedRiskPoliciesIds={sectionIdToSelectionDataMap[ComplianceHelper.getCustomComplianceId(complianceSection)]?.riskPolicyIds}
                            variant="view"
                            onSelectChange={
                                itemIds =>
                                    setCustomComplianceContext(
                                        context => ({
                                            ...context,
                                            sectionIdToSelectionDataMap:
                                                _.isEmpty(itemIds)
                                                    ? _.omit(
                                                        context.sectionIdToSelectionDataMap,
                                                        ComplianceHelper.getCustomComplianceId(complianceSection))
                                                    : {
                                                        ...context.sectionIdToSelectionDataMap,
                                                        [ComplianceHelper.getCustomComplianceId(complianceSection)]: {
                                                            riskPolicyIds: itemIds,
                                                            sectionPathIds: path
                                                        }
                                                    }
                                        }))}/>
                    </Loading>
                </Stack>}
        </Stack>);
}