import { Box, Card, SxProps, Theme, useTheme } from "@mui/material";
import React, { ReactNode } from "react";
import { Sx } from "../assets";

type ContentCardProps = {
    cardSx?: SxProps;
    children: ReactNode;
    sx?: SxProps<Theme>;
};

export function ContentCard({ cardSx, children, sx }: ContentCardProps) {
    const theme = useTheme();
    return (
        <Box
            sx={
                Sx.combine(
                    {
                        height: "100%",
                        padding: theme.spacing(2)
                    },
                    sx)}>
            <Card
                sx={
                    Sx.combine(
                        {
                            height: "100%"
                        },
                        cardSx)}>
                {children}
            </Card>
        </Box>);
}