import _ from "lodash";
import React, { useMemo } from "react";
import { ArrowDownIcon, ArrowUpIcon } from "../assets";
import { ActionMenuItem, DataTableSortDirection, DataTableSortType, MenuItem } from "../components";
import { Action1 } from "../types";
import { useLocalization } from "./useLocalization";

export type TableColumnHeaderSortOptions = {
    direction?: never;
    enabled: false;
    onClick?: never;
    type?: never;
} | {
    direction?: DataTableSortDirection;
    enabled?: boolean;
    onClick: Action1<DataTableSortDirection>;
    type: DataTableSortType;
};

export function useSortMenuItems(sortOptions?: TableColumnHeaderSortOptions) {
    const localization =
        useLocalization(
            "infrastructure.hooks.useSortMenuItems",
            () => ({
                sort: {
                    [DataTableSortType.Alphabetic]: {
                        ascending: "Sort A to Z",
                        descending: "Sort Z to A"
                    },
                    [DataTableSortType.Date]: {
                        ascending: "Sort oldest to newest",
                        descending: "Sort newest to oldest"
                    },
                    [DataTableSortType.Numeric]: {
                        ascending: "Sort lowest to highest",
                        descending: "Sort highest to lowest"
                    }
                }
            }));

    return useMemo(
        () =>
            _<MenuItem>([]).
                concatIf(
                    sortOptions?.enabled === true,
                    () => [
                        new ActionMenuItem(
                            () => sortOptions!.onClick!(DataTableSortDirection.Ascending),
                            localization.sort[sortOptions!.type!]?.ascending(),
                            {
                                icon: <ArrowUpIcon/>,
                                selected: sortOptions?.direction === DataTableSortDirection.Ascending
                            }),
                        new ActionMenuItem(
                            () => sortOptions!.onClick!(DataTableSortDirection.Descending),
                            localization.sort[sortOptions!.type!]?.descending(),
                            {
                                icon: <ArrowDownIcon/>,
                                selected: sortOptions?.direction === DataTableSortDirection.Descending
                            })]).
                value(),
        [sortOptions]);
}