import { useDebouncedEffect, useLocalization } from "@infrastructure";
import { List, ListItemButton, Typography } from "@mui/material";
import _ from "lodash";
import React, { Fragment, useState } from "react";
import { Contract } from "../../../../../../../controllers";
import { UdmObjectPropertyFilterProps } from "../UdmObjectPropertyFilter";
import { Filter } from "./Filter";

export function InfraBoolean({ onClearClicked, onFilterChange, propertyId, rule }: UdmObjectPropertyFilterProps) {
    const localization =
        useLocalization(
            "common.udmObjectTable.udmQueryBuilder.udmObjectPropertyFilter.infraBoolean",
            () => ({
                false: "False",
                true: "True"
            }));

    const [values, setValues] =
        useState<(false | true)[]>(
            _.isEmpty(rule.values)
                ? []
                : rule.values);

    const [operator, setOperator] = useState<Contract.UdmQueryRuleOperator>(rule.operator ?? Contract.UdmQueryRuleOperator.Equals);

    useDebouncedEffect(
        () => {
            if (operator && !_.isNil(values)) {
                onFilterChange({
                    operator,
                    value: values
                });
            }
        },
        500,
        [operator, values]);

    return (
        <Filter
            emptyValue={true}
            initialOperator={operator}
            operators={operators}
            propertyId={propertyId}
            ruleId={rule.id!}
            selectionViewValue={
                !_.isEmpty(values)
                    ? <Typography sx={{ fontWeight: 500 }}>
                        {values[0]
                            ? localization.true()
                            : localization.false()}
                    </Typography>
                    : <Fragment/>}
            values={values}
            onClearClicked={onClearClicked}
            onOperatorChange={setOperator}>
            <List
                dense={true}
                disablePadding={true}>
                <ListItemButton
                    selected={values[0]}
                    onClick={() => setValues([true])}>
                    {localization.true()}
                </ListItemButton>
                <ListItemButton
                    selected={!values[0]}
                    onClick={() => setValues([false])}>
                    {localization.false()}
                </ListItemButton>
            </List>
        </Filter>);
}

const operators = [
    Contract.UdmQueryRuleOperator.Equals
];