import { NumberFormatter, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useGcpCommonEncryptedResourceInfoElements, useGcpDefaultResourceInfoItemElements, useGcpDefaultScopeResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../..";
import { useCustomEntityPropertyInfoItemElements } from "..";
import { Contract, InfoItem } from "../../../../../../../../../../common";
import { useGcpComputeImageStatusTranslator, useGcpComputeImageTypeTranslator } from "../../../../../../hooks";
import { EntitiesInfoItem, Info } from "../../../../components";
import { GcpScopeResourceRoleBindings } from "../../components";

export function useGcpComputeImageDefinition(scopeResourceModel: Contract.GcpScopeResourceModel, options?: DefinitionOptions) {
    const commonEncryptedResourceInfoElements = useGcpCommonEncryptedResourceInfoElements(scopeResourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(scopeResourceModel);
    const defaultScopeResourceInfoItemElements = useGcpDefaultScopeResourceInfoItemElements(scopeResourceModel);
    const defaultResourceInfoItemElements = useGcpDefaultResourceInfoItemElements(scopeResourceModel);
    const imageModel = scopeResourceModel as Contract.GcpComputeImageModel;
    const image = imageModel.entity as Contract.GcpComputeImage;

    const computeImageStatusTranslator = useGcpComputeImageStatusTranslator();
    const computeImageTypeTranslator = useGcpComputeImageTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpComputeImageDefinition",
            () => ({
                info: {
                    items: {
                        familyTag: "Family",
                        instanceIdReferences: "VM Instances",
                        size: "Disk Size",
                        sourceIdReference: "Source",
                        status: "Status",
                        storageSize: "Archive Size",
                        type: "Type"
                    }
                },
                tabs: {
                    resourceRoleBindings: "Resource Role Bindings"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs:
            _<EntityProfileDefinitionTab>([]).
                concatIf(
                    image.type == Contract.GcpComputeImageType.Custom,
                    new EntityProfileDefinitionTab(
                        ProfileCategory.Iam,
                        <GcpScopeResourceRoleBindings
                            csvExportFilePrefix={localization.tabs.resourceRoleBindings()}
                            scopeResourceModel={scopeResourceModel}/>,
                        localization.tabs.resourceRoleBindings(),
                        "resourceRoleBindings")).
                value(),
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    ...defaultScopeResourceInfoItemElements,
                    commonEncryptedResourceInfoElements.encryptionExists,
                    commonEncryptedResourceInfoElements.kmsEncryptionKeys,
                    <EntitiesInfoItem
                        entityIdsOrModels={imageModel.instanceIdReferences}
                        entityTypeName={Contract.TypeNames.GcpComputeInstance}
                        key="instanceIdReferences"
                        location="all"
                        title={localization.info.items.instanceIdReferences()}/>,
                    <InfoItem
                        key="status"
                        location="all"
                        title={localization.info.items.status()}
                        value={computeImageStatusTranslator(image.status)}/>,
                    <InfoItem
                        key="type"
                        location="profile"
                        title={localization.info.items.type()}
                        value={computeImageTypeTranslator(image.type)}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={imageModel.sourceIdReference}
                        entityTypeName={Contract.TypeNames.GcpEntity}
                        key="sourceIdReference"
                        location="all"
                        title={localization.info.items.sourceIdReference()}/>,
                    <InfoItem
                        key="familyTag"
                        location="profile"
                        title={localization.info.items.familyTag()}
                        value={image.familyTag}/>,
                    <InfoItem
                        key="storageSize"
                        location="profile"
                        title={localization.info.items.storageSize()}
                        value={NumberFormatter.storage(image.storageSize)}/>,
                    <InfoItem
                        key="size"
                        location="profile"
                        title={localization.info.items.size()}
                        value={NumberFormatter.storage(image.size)}/>
                ]}
                options={options?.infoOptions}>
            </Info>
    });
}