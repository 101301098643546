﻿import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition, useGetAwsCloudFrontDistributionRiskContext } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";

export function useAwsCloudFrontDistributionRootObjectNotExistsRiskDefinition(riskModel: Contract.RiskModel) {
    const distributionRootObjectNotExistsRiskModel = riskModel as Contract.AwsCloudFrontDistributionRootObjectNotExistsRiskModel;
    const distributionModel = entityModelStore.useGet(distributionRootObjectNotExistsRiskModel.risk.entityId) as Contract.AwsCloudFrontDistributionModel;
    const distribution = distributionModel.entity as Contract.AwsCloudFrontDistribution;

    const getAwsCloudFrontDistributionRiskContext = useGetAwsCloudFrontDistributionRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsCloudFrontDistributionRootObjectNotExistsRiskDefinition",
            () => ({
                description: "{{distribution}} default root object is not set",
                sections: {
                    resolution: {
                        step1: "To specify a default root object for your distribution, first upload the object to the origin that your distribution points to",
                        step2: "In the General tab, under **Settings**, click **Edit**",
                        step3: "Navigate to **Default root object** and enter the file name of the default root object. Enter only the object name, for example, **index.html**. Do not add a / before the object name",
                        step4: "Click **Save changes**"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            distribution:
                <Entity
                    entityIdOrModel={distributionModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            localization.sections.resolution.step1(),
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.CloudFront,
                AwsConsoleUrlBuilder.getCloudFrontDistributionUrl(distribution)),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4()
        ],
        distributionRootObjectNotExistsRiskModel,
        () => {
            const distributionRiskContext = getAwsCloudFrontDistributionRiskContext(distributionModel);
            return [
                distributionRiskContext.generalInformation,
                distributionRiskContext.status,
                distributionRiskContext.originResources,
                distributionRiskContext.sensitive,
                distributionRiskContext.sensitiveOrigin,
                distributionRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        });
}