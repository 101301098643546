import _ from "lodash";
import React, { Fragment, useMemo, useState } from "react";
import { Dialog } from "@infrastructure";
import { Contract, CustomerConsoleAppUrlHelper, ProfileActions as BaseProfileActions, ScopeHelper, scopeNodeModelStore } from "../../../../../common";
import { useAddOrEditDefinition, useDefinition } from "../hooks";
import { ScopesViewType } from "../utilities";
import { ActionsCell } from "./ActionsCell";

type ProfileActionsProps = {
    scopeNodeModelId: string;
};

export function ProfileActions({ scopeNodeModelId }: ProfileActionsProps) {
    const scopeNodeModel = scopeNodeModelStore.useGet(scopeNodeModelId);
    const [addOrEditOpen, setAddOrEditOpen] = useState(false);
    const tenantType = ScopeHelper.getTenantType(scopeNodeModel);
    const project = ScopeHelper.isProject(scopeNodeModel);
    const view =
        project
            ? Contract.ScopeType.Project
            : tenantType as ScopesViewType;
    const definition = useDefinition(view);
    const addOrEditDefinition = useAddOrEditDefinition(view);
    const scopeNodeMap = scopeNodeModelStore.useGetScopeNodeMap(undefined, project);

    const parentFolderId =
        useMemo(
            () => scopeNodeMap[scopeNodeModelId].parentScopeNode!.scopeNodeModel.configuration.id,
            [scopeNodeMap]);

    return (
        <Fragment>
            <BaseProfileActions
                getLink={() => CustomerConsoleAppUrlHelper.getScopeProfileUrl(tenantType as ScopesViewType, scopeNodeModel.configuration.id)}
                menu={
                    <ActionsCell
                        definition={definition}
                        isProject={project}
                        item={scopeNodeModel}
                        view={view}
                        onEditClick={() => setAddOrEditOpen(true)}/>}/>
            {addOrEditOpen &&
                !_.isNil(addOrEditDefinition.element) &&
                <Dialog
                    size={addOrEditDefinition.dialogSize ?? "large"}
                    variant="editor"
                    onClose={() => setAddOrEditOpen(false)}>
                    <addOrEditDefinition.element
                        parentFolderId={parentFolderId}
                        scopeNodeModel={scopeNodeModel}
                        onClose={() => setAddOrEditOpen(false)}/>
                </Dialog>}
        </Fragment>);
}