import { map } from "@infrastructure";
import _, { Dictionary } from "lodash";
import { ConfigurationController, Contract } from "..";

export class LicensingHelper {
    public static activeEvaluationLicenseTypes: Contract.ApplicationCustomerConfigurationLicensingLicenseType[];
    public static activeSubscriptionLicenseTypes: Contract.ApplicationCustomerConfigurationLicensingLicenseType[];
    public static addonLicenseTypes = [
        Contract.ApplicationCustomerConfigurationLicensingLicenseType.PermissionManagement
    ];
    public static orderedLicenseTypes = [
        Contract.ApplicationCustomerConfigurationLicensingLicenseType.CnappEnterprise,
        Contract.ApplicationCustomerConfigurationLicensingLicenseType.Cnapp,
        Contract.ApplicationCustomerConfigurationLicensingLicenseType.Cspm,
        Contract.ApplicationCustomerConfigurationLicensingLicenseType.Ciem,
        Contract.ApplicationCustomerConfigurationLicensingLicenseType.PermissionManagement
    ];
    public static standardLicenseTypes = [
        Contract.ApplicationCustomerConfigurationLicensingLicenseType.Ciem,
        Contract.ApplicationCustomerConfigurationLicensingLicenseType.Cnapp,
        Contract.ApplicationCustomerConfigurationLicensingLicenseType.CnappEnterprise,
        Contract.ApplicationCustomerConfigurationLicensingLicenseType.Cspm
    ];
    public static visible: boolean;

    private static licenseTypeToDataMap: Dictionary<LicenseData> = {
        [Contract.ApplicationCustomerConfigurationLicensingLicenseType.Ciem]: {
            name: "Tenable CIEM",
            shortName: "CIEM"
        },
        [Contract.ApplicationCustomerConfigurationLicensingLicenseType.Cnapp]: {
            name: "Tenable Cloud Security Standard",
            shortName: "Standard"
        },
        [Contract.ApplicationCustomerConfigurationLicensingLicenseType.CnappEnterprise]: {
            name: "Tenable Cloud Security Enterprise",
            shortName: "Enterprise"
        },
        [Contract.ApplicationCustomerConfigurationLicensingLicenseType.Cspm]: {
            name: "Tenable CSPM ",
            shortName: "CIEM/CSPM"
        },
        [Contract.ApplicationCustomerConfigurationLicensingLicenseType.PermissionManagement]: {
            name: "Tenable JIT",
            shortName: "JIT"
        }
    };

    public static async initialize() {
        const { activeEvaluationLicenseTypes, activeSubscriptionLicenseTypes, visible } = await ConfigurationController.getLicensing();
        LicensingHelper.activeEvaluationLicenseTypes = activeEvaluationLicenseTypes;
        LicensingHelper.activeSubscriptionLicenseTypes = activeSubscriptionLicenseTypes;
        LicensingHelper.visible = visible;
    }

    public static getActiveLicenseShortName(activeLicenseTypes?: Contract.ApplicationCustomerConfigurationLicensingLicenseType[]) {
        activeLicenseTypes ??= LicensingHelper.activeSubscriptionLicenseTypes;
        if (_.isEmpty(activeLicenseTypes)) {
            return "TRIAL";
        } else {
            const licenseType = LicensingHelper.getActiveLicenseType(activeLicenseTypes);
            return LicensingHelper.licenseTypeToDataMap[licenseType!].shortName;
        }
    }

    public static getActiveLicenseType(activeLicenseTypes: Contract.ApplicationCustomerConfigurationLicensingLicenseType[]) {
        return _.find(
            LicensingHelper.orderedLicenseTypes,
            licenseType => _.includes(activeLicenseTypes, licenseType));
    }

    public static getIncludedLicenseTypes(type: Contract.ApplicationCustomerConfigurationLicensingLicenseType): Contract.ApplicationCustomerConfigurationLicensingLicenseType[] {
        return _([type]).
            concat(
                map(
                    type,
                    {
                        [Contract.ApplicationCustomerConfigurationLicensingLicenseType.Cnapp]: () => LicensingHelper.getIncludedLicenseTypes(Contract.ApplicationCustomerConfigurationLicensingLicenseType.Cspm),
                        [Contract.ApplicationCustomerConfigurationLicensingLicenseType.CnappEnterprise]: () => LicensingHelper.getIncludedLicenseTypes(Contract.ApplicationCustomerConfigurationLicensingLicenseType.Cnapp),
                        [Contract.ApplicationCustomerConfigurationLicensingLicenseType.Ciem]: () => [],
                        [Contract.ApplicationCustomerConfigurationLicensingLicenseType.Cspm]: () => LicensingHelper.getIncludedLicenseTypes(Contract.ApplicationCustomerConfigurationLicensingLicenseType.Ciem),
                        [Contract.ApplicationCustomerConfigurationLicensingLicenseType.PermissionManagement]: () => LicensingHelper.getIncludedLicenseTypes(Contract.ApplicationCustomerConfigurationLicensingLicenseType.Ciem)
                    })).
            uniq().
            value();
    }

    public static getLicenseData(licenseType: Contract.ApplicationCustomerConfigurationLicensingLicenseType) {
        return LicensingHelper.licenseTypeToDataMap[licenseType];
    }

    public static isActiveLicenseType(licenseType: Contract.ApplicationCustomerConfigurationLicensingLicenseType) {
        return (
            _.includes(LicensingHelper.activeEvaluationLicenseTypes, licenseType) ||
            _.includes(LicensingHelper.activeSubscriptionLicenseTypes, licenseType));
    }
}

type LicenseData = {
    name: string;
    shortName: string;
};