import { DataTableColumn, DataTableSortType, NumberFormatter, useLocalization } from "@infrastructure";
import { Dictionary } from "lodash";
import React from "react";
import { useAwsCommonEncryptedResourceDefinition, useAwsCommonResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, ItemSelectionHelper, TimeRangeHelper } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";

export function useAwsAossCollectionDefinition(definitionData: DefinitionData) {
    const commonEncryptedResourceDefinition = useAwsCommonEncryptedResourceDefinition(definitionData);
    const commonResourceDefinition = useAwsCommonResourceDefinition(definitionData);

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.aws.useAwsAossCollectionDefinition",
            () => ({
                columns: {
                    storageSize: "Size"
                }
            }));

    return new EntityTableDefinition(
        [
            commonResourceDefinition.columns.tenantColumn,
            commonResourceDefinition.columns.creationTimeColumn,
            commonResourceDefinition.columns.creatorIdentityCsvColumn,
            commonResourceDefinition.columns.creatorOriginatorEntityCsvColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsAossCollectionModel) => ({
                            [localization.columns.storageSize()]: NumberFormatter.storage((item.entity as Contract.AwsAossCollection).storageSize)
                        })
                }}
                id={Contract.EntityModelProperty.AwsAossCollectionStorageSize}
                itemProperty={(item: Contract.AwsResourceModel) => NumberFormatter.storage((item.entity as Contract.AwsAossCollection).storageSize)}
                key={Contract.EntityModelProperty.AwsAossCollectionStorageSize}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.storageSize()}/>,
            commonEncryptedResourceDefinition.columns.encryptionExistsColumn,
            commonEncryptedResourceDefinition.columns.kmsEncryptionKeyColumn,
            commonResourceDefinition.columns.regionColumn,
            commonResourceDefinition.columns.regionLocationColumn,
            commonResourceDefinition.columns.tagsColumn,
            commonResourceDefinition.columns.attributesColumn,
            commonResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAwsAossCollectionRequest(
                new Contract.EntityControllerGetEntityModelPageAwsAossCollectionRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags])),
                new Contract.EntityControllerGetEntityModelPageAwsResourceRequestEncrypted(
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEncryptedResourceEncryptionExists]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEncryptedResourceKmsEncryptionKeys]))));
}