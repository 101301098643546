import { ActionMenuItem, useLocalization, useSetRoute } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, CustomerConsoleAppUrlHelper, ScopeHelper, scopeNodeModelStore, tenantModelStore } from "../../../../../../../../common";
import { AddOrEditDefinition } from "../../useAddOrEditDefinition";
import { AddOrEdit, Welcome } from "./components";

export function useAzureAddOrEditDefinition(): AddOrEditDefinition {
    const tenantTypes =
            useMemo(
                () => [Contract.TenantType.Azure],
                []);
    const activeScopeNodeMap = scopeNodeModelStore.useGetActiveScopeNodeMap(tenantTypes);
    const setRoute = useSetRoute();
    const aadTenantModels = tenantModelStore.useGetAadTenants();
    const nonAzureOrganizationAadTenantModels =
        useMemo(
            () =>
                _.filter(
                    aadTenantModels,
                    aadTenantModel => _.isNil(aadTenantModel.configuration.azureOrganizationId)),
            [aadTenantModels]);

    const localization =
        useLocalization(
            "views.customer.scopes.hooks.useDefinition.hooks.useAzureDefinition.useAzureAddOrEditDefinition",
            () => ({
                addOrganization: {
                    confirmTitle: "To onboard an Azure organization, first onboard the Microsoft Entra ID tenant that's linked to the organization. Afterwards, you can proceed with organization onboarding. Do you want to onboard the tenant now?",
                    title: "Add organization"
                },
                addTenant: "Add subscription"
            }));

    return useMemo(
        () => ({
            additionalActionItems: () => [
                new ActionMenuItem(
                    () => {
                        if (_.isEmpty(nonAzureOrganizationAadTenantModels)) {
                            setRoute(CustomerConsoleAppUrlHelper.getConfigurationIntegrationsIdentityProviderTenantsAadRelativeUrl(true));
                        } else {
                            setRoute(CustomerConsoleAppUrlHelper.getConfigurationIntegrationsCloudProviderTenantOrganizationsAzureRelativeUrl(true));
                        }
                    },
                    localization.addOrganization.title(),
                    {
                        confirmOptions:
                            _.isEmpty(nonAzureOrganizationAadTenantModels)
                                ? { message: localization.addOrganization.confirmTitle() }
                                : undefined
                    })
            ],
            addTitle: localization.addTenant(),
            element: AddOrEdit,
            welcome:
                _(activeScopeNodeMap).
                    filter(
                        scopeNode =>
                            !ScopeHelper.isRootFolder(scopeNode.scopeNodeModel) &&
                            scopeNode.scopeNodeModel.configuration.id !== ScopeHelper.customerId).
                    isEmpty()
                    ? <Welcome/>
                    : undefined
        }),
        [aadTenantModels, activeScopeNodeMap, nonAzureOrganizationAadTenantModels]);
}