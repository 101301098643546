import { DataTable, DataTableColumn, DataTableColumnRenderProps, DataTableFetchItemsResult, DataTableSort, EmptyMessage, InlineItems, Optional, optionalTableCell, TimeFormatter, useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React from "react";
import { Contract, entityModelStore, InfoCard } from "../../../../../../../../../../../../common";

type PackagesInfoCardProps = {
    repositoryModel: Contract.GcpArtifactPackageRepositoryModel;
};

export function PackagesInfoCard({ repositoryModel }: PackagesInfoCardProps) {
    const repository = repositoryModel.entity as Contract.GcpArtifactPackageRepository;
    const registryModel = entityModelStore.useGet(repository.registryId);
    const registry = registryModel.entity as Contract.GcpArtifactRegistry;

    const fetchItems =
        async (filterMap: Dictionary<any>, sort: Optional<DataTableSort>, skip: number, limit = 10) => {
            const packagePage =
                _(repository.packages).
                    drop(skip).
                    take(limit).
                    value();

            return new DataTableFetchItemsResult(
                { count: packagePage.length },
                packagePage,
                repository.packages.length <= skip + limit);
        };

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpArtifactPackageRepositoryDefinition.packagesInfoCard",
            () => ({
                columns: {
                    creationTime: "Creation Time",
                    npmVersion: "Version",
                    tags: {
                        empty: "<untagged>",
                        title: "Tags"
                    }
                },
                empty: "No Packages",
                title: {
                    all: "Packages ({{packageCount | NumberFormatter.humanize}})",
                    none: "Packages",
                    some: "Packages (Top {{syncedPackageCount | NumberFormatter.humanize}} out of {{packageCount | NumberFormatter.humanize}})"
                }
            }));

    return (
        <InfoCard
            title={
                _.isEmpty(repository.packages)
                    ? localization.title.none()
                    : repository.packageCount === repository.packages.length
                        ? localization.title.all({ packageCount: repository.packageCount })
                        : localization.title.some({
                            packageCount: repository.packageCount,
                            syncedPackageCount: repository.packages.length
                        })}>
            {_.isEmpty(repository.packages)
                ? <EmptyMessage
                    message={localization.empty()}
                    verticalCenter={true}/>
                : <DataTable
                    fetchItems={fetchItems}
                    getItemId={(item: Contract.GcpArtifactPackageRepositoryPackage) => _.indexOf(repository.packages, item).toString()}
                    sortOptions={{ enabled: false }}
                    variant="card">
                    <DataTableColumn
                        id={PackagesTableColumnId.Tags}
                        key={PackagesTableColumnId.Tags}
                        render={
                            ({ item }: DataTableColumnRenderProps<Contract.GcpArtifactPackageRepositoryPackage>) =>
                                _.isEmpty(item.tags)
                                    ? <Typography noWrap={true}>
                                        {localization.columns.tags.empty()}
                                    </Typography>
                                    : <InlineItems
                                        items={item.tags}
                                        variant="itemPlusItemCount"/>}
                        title={localization.columns.tags.title()}/>
                    <DataTableColumn
                        id={PackagesTableColumnId.CreationTime}
                        itemProperty={(item: Contract.GcpArtifactPackageRepositoryPackage) => TimeFormatter.shortDate(item.creationTime)}
                        key={PackagesTableColumnId.CreationTime}
                        title={localization.columns.creationTime()}/>
                    {registry.type === Contract.GcpArtifactRegistryType.Npm &&
                        <DataTableColumn
                            id={PackagesTableColumnId.NpmVersion}
                            key={PackagesTableColumnId.NpmVersion}
                            render={optionalTableCell<Contract.GcpArtifactPackageRepositoryNpmPackage>(item => item.version)}
                            title={localization.columns.npmVersion()}/>}
                </DataTable>}
        </InfoCard>);
}

enum PackagesTableColumnId {
    CreationTime = "creationTime",
    NpmVersion = "npmVersion",
    Tags = "tags"
}