import { useLocalization } from "@infrastructure";
import _ from "lodash";
import { Contract } from "../../../common";

export function useAwsIamRoleAssumeRolePolicyDocumentStatementGranteeTranslator() {
    const localization =
        useLocalization(
            "tenants.aws.hooks.useAwsIamRoleAssumeRolePolicyDocumentStatementGranteeTranslator",
            () => ({
                identityArnPattern: "Principal: {{identityArnPattern}}",
                identityProvider: "Identity Provider: {{identityProviderId}}",
                serviceHostName: "AWS Service: {{serviceHostName}}",
                tenant: "Account: {{tenantCanonicalId}}"
            }));
    return (role: Contract.AwsIamRole): string[] =>
        _.concat(
            _(role.assumeRolePolicyDocumentAllowStatementGrantees).
                flatMap(grantee => grantee.tenantCanonicalIds ?? []).
                uniq().
                map(tenantCanonicalId => localization.tenant({ tenantCanonicalId })).
                value(),
            _(role.assumeRolePolicyDocumentAllowStatementGrantees).
                flatMap(grantee => grantee.serviceHostNames ?? []).
                map(serviceHostName => serviceHostName.split(".")[0]).
                uniq().
                map(serviceHostName => localization.serviceHostName({ serviceHostName })).
                value(),
            _(role.assumeRolePolicyDocumentAllowStatementGrantees).
                flatMap(grantee => grantee.identityProviderIds ?? []).
                uniq().
                map(identityProviderId => localization.identityProvider({ identityProviderId })).
                value(),
            _(role.assumeRolePolicyDocumentAllowStatementGrantees).
                flatMap(grantee => grantee.identityArnPatterns ?? []).
                uniq().
                map(identityArnPattern => localization.identityArnPattern({ identityArnPattern })).
                value());
}