import { useLocalization } from "@infrastructure";
import { AuditEventDefinition, AuditEventDefinitionContext, AuditEventDefinitionDetailItem } from "..";
import { Contract } from "../../../../../../../../../common";

export function useOciTenantAuditEventDefinition(context: AuditEventDefinitionContext) {
    const localization =
        useLocalization(
            "views.customer.auditEvents.profile.hooks.useDefinition.hooks.useOciTenantAuditEventDefinition",
            () => ({
                tenantFolder: "Folder",
                tenantName: "Compartment name",
                tenantOcid: "Compartment OCID"
            }));

    const tenantAuditEvent = context.auditEventModel.auditEvent as Contract.OciTenantAuditEvent;
    return new AuditEventDefinition([
        new AuditEventDefinitionDetailItem(
            localization.tenantOcid(),
            tenantAuditEvent.tenantOcid),
        new AuditEventDefinitionDetailItem(
            localization.tenantName(),
            tenantAuditEvent.tenantName),
        new AuditEventDefinitionDetailItem(
            localization.tenantFolder(),
            tenantAuditEvent.tenantFolderPath)]);
}