import { AutocompleteItems, DropdownIcon, Link, StringHelper, useExecuteOperation, useInputValidation, useLocalization, useOrderedWizardContext } from "@infrastructure";
import { Box, FormHelperText, Stack, TextField, Typography } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { AddOrEdit, useAddOrEditContext, useSetAddOrEditContext } from "..";
import { AwsResourceArnParser, ConfigurationController, Contract, CustomerConsoleAppUrlHelper, GeographyHelper, tenantModelStore, useTheme } from "../../../../../../../../../../../../../../../common";

export function MasterTenantItem() {
    const tenantModels = tenantModelStore.useGetPermittedAwsTenants();
    const { masterTenantModel, organizationConfiguration } = useAddOrEditContext();
    const setAddOrEditContext = useSetAddOrEditContext();

    const [onboardingInfo] =
        useExecuteOperation(
            AddOrEdit,
            () =>
                ConfigurationController.getAwsOrganizationOnboardingInfo(
                    new Contract.ConfigurationControllerGetAwsOrganizationOnboardingInfoRequest(
                        masterTenantModel?.configuration.partitionType ??
                        GeographyHelper.deploymentAwsPartitionType)));
    const sortedMasterTenantModels =
        useMemo(
            () =>
                _(tenantModels).
                    filter(
                        tenantModel =>
                            tenantModel.master &&
                            _.isNil(tenantModel.configuration.organizationId) &&
                            (tenantModel.status === Contract.AwsTenantModelStatus.Valid || tenantModel.status === Contract.AwsTenantModelStatus.ValidWithIssues)).
                    orderBy(masterTenantModel => StringHelper.getSortValue(masterTenantModel.configuration.name)).
                    value(),
            [tenantModels]);

    const [selectedMasterTenantModel, setSelectedMasterTenantModel] = useState<Contract.AwsTenantModel | undefined>(masterTenantModel);

    const { executing, setLoaded, setValid } = useOrderedWizardContext();
    useEffect(
        () => {
            setLoaded();
        },
        []);

    useEffect(
        () => {
            if (selectedMasterTenantModel !== masterTenantModel) {
                setAddOrEditContext(
                    context => ({
                        ...context,
                        masterTenantModel: selectedMasterTenantModel,
                        name: selectedMasterTenantModel?.state.organization?.rawId,
                        permissionTypes: selectedMasterTenantModel?.configuration.permissionTypes,
                        regionSystemNames: selectedMasterTenantModel?.configuration.regionSystemNames,
                        roleName: selectedMasterTenantModel?.roleName,
                        trailName:
                            _.size(selectedMasterTenantModel?.configuration.trails) == 1
                                ? AwsResourceArnParser.tryGetCloudTrailTrailName(_.first(selectedMasterTenantModel?.configuration.trails)!.arn)
                                : undefined
                    }));
            }
        },
        [selectedMasterTenantModel]);

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCloudProviderTenantOrganizationItems.aws.addOrEdit.masterTenantItem",
            () => ({
                fields: {
                    tenant: {
                        empty: "No management accounts",
                        error: {
                            china: "To onboard an organization from the China partition, please configure a user at the organization scope first. For more details, please contact support",
                            required: "You must choose a management account"
                        },
                        label: {
                            organization: "Organization ID: {{organizationRawId}}",
                            tenant: "Management Account: {{tenantName}} ({{tenantRawId}})"
                        },
                        subtitle: {
                            tenantsPageLink: "AWS Accounts",
                            text: "Select the Management account. If the account does not appear in the dropdown, go to {{tenantsPageLink}} to onboard it"
                        },
                        title: "Management account"
                    }
                }
            }));

    const [tenantModelValidationController, tenantModelValidationMessage] =
        useInputValidation(
            () => {
                if (_.isNil(selectedMasterTenantModel)) {
                    return localization.fields.tenant.error.required();
                }

                if (selectedMasterTenantModel.configuration.partitionType === Contract.AwsPartitionType.China &&
                    _.isNil(onboardingInfo.chinaAuthenticationUserArn)) {
                    return localization.fields.tenant.error.china();
                }

                return undefined;
            },
            [selectedMasterTenantModel]);

    useEffect(
        () => setValid(tenantModelValidationController.isValid()),
        [selectedMasterTenantModel]);

    const theme = useTheme();
    return (
        <Stack
            spacing={4}
            sx={{ maxWidth: theme.spacing(60) }}>
            <Stack spacing={3}>
                <Typography>
                    {localization.fields.tenant.subtitle.text({
                        tenantsPageLink:
                            <Link urlOrGetUrl={CustomerConsoleAppUrlHelper.getScopesRelativeUrl(Contract.TenantType.Aws)}>
                                {localization.fields.tenant.subtitle.tenantsPageLink()}
                            </Link>
                    })}
                </Typography>
                <Box>
                    <AutocompleteItems
                        disableClearable={true}
                        disabled={executing || !_.isNil(organizationConfiguration)}
                        fullWidth={true}
                        getOptionLabel={
                            tenantModel =>
                                localization.fields.tenant.label.tenant({
                                    tenantName: tenantModel.configuration.name,
                                    tenantRawId: tenantModel.configuration.rawId
                                })}
                        noOptionsText={localization.fields.tenant.empty()}
                        options={sortedMasterTenantModels}
                        popupIcon={
                            <DropdownIcon
                                sx={{
                                    color:
                                        executing
                                            ? theme.palette.text.disabled
                                            : theme.palette.text.secondary
                                }}/>}
                        renderInput={
                            params => (
                                <TextField
                                    {...params}
                                    label={localization.fields.tenant.title()}
                                    variant="outlined"/>)}
                        value={selectedMasterTenantModel}
                        onChange={(event, tenantModel) => setSelectedMasterTenantModel(tenantModel)}>
                        {tenantModel =>
                            <Stack>
                                <Typography>
                                    {localization.fields.tenant.label.tenant({
                                        tenantName: tenantModel.configuration.name,
                                        tenantRawId: tenantModel.configuration.rawId
                                    })}
                                </Typography>
                                <Typography variant="subtitle1">
                                    {localization.fields.tenant.label.organization({ organizationRawId: tenantModel.state.organization?.rawId })}
                                </Typography>
                            </Stack>}
                    </AutocompleteItems>
                    {!_.isNil(tenantModelValidationMessage) &&
                        <FormHelperText error={true}>{tenantModelValidationMessage}</FormHelperText>}
                </Box>
            </Stack>
        </Stack>);
}