import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { useCommonCustomSectionsAndDescriptionDefinition } from "../../..";
import { ActionHelper, Contract, EntitiesCell, Entity, EntityFilter, entityModelStore, InlineEntities, InlinePermissionActions, usePrincipalModelAccess, useTableDefinition } from "../../../../../../../../../../../../common";
import { Table } from "../../../../components";
import { RiskContentProps } from "../../../../useCloudDefinition";

export function useAzureManagedIdentityNotAllowedResourcePermissionRiskDefinition(riskModel: Contract.RiskModel) {
    const managedIdentityNotAllowedResourcePermissionRiskModel = riskModel as Contract.AzureManagedIdentityNotAllowedResourcePermissionRiskModel;
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.custom.useAzureManagedIdentityNotAllowedResourcePermissionRiskDefinition",
            () => ({
                violation: "{{managedIdentity}} has {{permissionActions}} on {{scopeResources}}"
            }));
    return useCommonCustomSectionsAndDescriptionDefinition(
        localization.violation({
            managedIdentity:
                <Entity
                    entityIdOrModel={(managedIdentityNotAllowedResourcePermissionRiskModel.risk as Contract.AzureManagedIdentityNotAllowedResourcePermissionRisk).entityId}
                    variant="typeText"/>,
            permissionActions:
                <InlinePermissionActions
                    permissionActions={managedIdentityNotAllowedResourcePermissionRiskModel.permissionActions}
                    variant="itemOrItemCountAndType"/>,
            scopeResources:
                <InlineEntities
                    entityIdsOrModels={managedIdentityNotAllowedResourcePermissionRiskModel.scopeResourceIds}
                    entityTypeName={Contract.TypeNames.AzureScopeResource}
                    variant="itemAndTypeOrItemCountAndType"/>

        }),
        riskModel,
        "violations",
        <ViolationTable riskModel={riskModel}/>);
}

function ViolationTable({ riskModel }: RiskContentProps) {
    const managedIdentityNotAllowedResourcePermissionRiskModel = riskModel as Contract.AzureManagedIdentityNotAllowedResourcePermissionRiskModel;
    const managedIdentityNotAllowedResourcePermissionRisk = riskModel.risk as Contract.AzureManagedIdentityNotAllowedResourcePermissionRisk;

    const managedIdentityModel = entityModelStore.useGet(managedIdentityNotAllowedResourcePermissionRisk.entityId);
    const principalModelAccess = usePrincipalModelAccess<Contract.PrincipalModelAccess>(_.as<Contract.AzureManagedIdentityManagedIdentity>(managedIdentityModel.entity).servicePrincipalId);
    const originatorEntityModels = entityModelStore.useGet(_.as<Contract.ServiceIdentityModelAccess>(principalModelAccess).originatorEntityIds);
    const scopeResourceModels = entityModelStore.useGet(managedIdentityNotAllowedResourcePermissionRiskModel.scopeResourceIds);
    const items =
        useMemo(
            () => {
                const scopeResourceModelMap =
                    _.keyBy(
                        scopeResourceModels,
                        scopeResourceModel => scopeResourceModel.id);

                return _.map(
                    managedIdentityNotAllowedResourcePermissionRisk.resourcePermissionItems,
                    resourcePermissionItem =>
                        new ViolationTableItem(
                            managedIdentityModel,
                            _.map(
                                originatorEntityModels,
                                originatorEntityModel => originatorEntityModel.id),
                            originatorEntityModels,
                            resourcePermissionItem.permissionActions,
                            scopeResourceModelMap[resourcePermissionItem.scopeResourceId]));
            },
            []);

    const tableDefinition =
        useTableDefinition(
            items,
            {
                [ViolationTableColumnId.Originators]: {
                    getFilterValue: item => item.originatorEntityIds,
                    getSortValue: item => item.originatorEntityIds.length
                },
                [ViolationTableColumnId.PermissionActions]: {
                    getFilterValue: item => item.permissionActions,
                    getSortValue: item => item.permissionActions.length
                },
                [ViolationTableColumnId.ScopeResource]: {
                    getFilterValue: item => item.scopeResourceModel.entity.id,
                    getSortValue: item => item.scopeResourceModel.entity.displayName
                }
            },
            ViolationTableColumnId.ScopeResource);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.custom.useAzureManagedIdentityNotAllowedResourcePermissionRiskDefinition.violationTable",
            () => ({
                columns: {
                    managedIdentity: "Managed Identity",
                    originators: "Originators",
                    permissionActions: "Permissions",
                    scopeResource: "Resource"
                }
            }));

    return (
        <Table
            fetchItems={tableDefinition.filterAndSortItems}
            getItemId={(item: ViolationTableItem) => item.id}
            sortEnabled={true}>
            <DataTableColumn
                id={ViolationTableColumnId.ManagedIdentity}
                render={
                    ({ item }: DataTableColumnRenderProps<ViolationTableItem>) =>
                        <Entity
                            entityIdOrModel={item.managedIdentityModel}
                            variant="iconTextTypeTenantTags"/>}
                sortOptions={{ enabled: false }}
                title={localization.columns.managedIdentity()}/>
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EntityFilter
                                entityIdsOrSearchableReferences={tableDefinition.columnIdToItemValuesMap[ViolationTableColumnId.Originators]}
                                placeholder={localization.columns.originators()}/>
                    }
                }}
                id={ViolationTableColumnId.Originators}
                render={
                    ({ item }: DataTableColumnRenderProps<ViolationTableItem>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.originatorEntityModels}
                            entityTypeName={Contract.TypeNames.AzureResource}
                            entityVariant="iconTextTypeTenant"/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.originators()}/>
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        element:
                            <ValuesFilter placeholder={localization.columns.permissionActions()}>
                                {_.map(
                                    tableDefinition.columnIdToItemValuesMap[ViolationTableColumnId.PermissionActions],
                                    permissionAction =>
                                        <ValuesFilterItem
                                            key={permissionAction}
                                            title={ActionHelper.getRawValue(permissionAction)}
                                            value={permissionAction}/>)}
                            </ValuesFilter>
                    }
                }}
                id={ViolationTableColumnId.PermissionActions}
                render={
                    ({ item }: DataTableColumnRenderProps<ViolationTableItem>) =>
                        <InlinePermissionActions
                            permissionActions={item.permissionActions}
                            variant="itemOrItemCountAndType"/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.permissionActions()}/>
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EntityFilter
                                entityIdsOrSearchableReferences={tableDefinition.columnIdToItemValuesMap[ViolationTableColumnId.ScopeResource]}
                                placeholder={localization.columns.scopeResource()}/>
                    }
                }}
                id={ViolationTableColumnId.ScopeResource}
                render={
                    ({ item }: DataTableColumnRenderProps<ViolationTableItem>) =>
                        <Entity
                            entityIdOrModel={item.scopeResourceModel}
                            variant="iconTextTypeTenantTags"/>}
                title={localization.columns.scopeResource()}/>
        </Table>);
}

enum ViolationTableColumnId {
    ManagedIdentity = "managedIdentity",
    Originators = "originators",
    PermissionActions = "permissionActions",
    ScopeResource = "scopeResource"
}

class ViolationTableItem {
    public id: string;

    constructor(
        public managedIdentityModel: Contract.EntityModel,
        public originatorEntityIds: string[],
        public originatorEntityModels: Contract.EntityModel[],
        public permissionActions: string[],
        public scopeResourceModel: Contract.EntityModel) {
        this.id = `${managedIdentityModel.id}-${scopeResourceModel.id}`;
    }
}