﻿import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, InfoCard, InfoItem } from "../../../../../../../../../../../../common";
import { EntitiesInfoItem } from "../../../../../../components";

type AgentPoolNetworkingInfoCardProps = {
    agentPool: Contract.AzureContainerServiceManagedClusterAgentPool;
};

export function AgentPoolNetworkingInfoCard({ agentPool }: AgentPoolNetworkingInfoCardProps) {
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.azure.useAzureContainerServiceManagedClusterAgentPoolDefinition.agentPoolNetworkingInfoCard",
            () => ({
                sections: {
                    nodeInboundExternalAccess: {
                        false: "Disabled",
                        title: "Node Public IP",
                        true: "Enabled"
                    },
                    subnetId: "Subnet",
                    virtualNetwork: "Virtual Network"
                },
                title: "Network"
            }));
    return (
        <InfoCard
            columns={true}
            title={localization.title()}>
            <EntitiesInfoItem
                entityIdsOrModels={agentPool?.virtualNetworkId}
                entityTypeName={Contract.TypeNames.AzureNetworkVirtualNetwork}
                title={localization.sections.virtualNetwork()}/>
            <EntitiesInfoItem
                entityIdsOrModels={agentPool.subnetId}
                entityTypeName={Contract.TypeNames.AzureNetworkVirtualNetworkSubnet}
                title={localization.sections.subnetId()}/>
            <InfoItem
                key="nodeInboundExternalAccess"
                title={localization.sections.nodeInboundExternalAccess.title()}
                value={
                    agentPool.node.publicIpAddressExists
                        ? localization.sections.nodeInboundExternalAccess.true()
                        : localization.sections.nodeInboundExternalAccess.false()}/>
        </InfoCard>);
}