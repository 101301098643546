import { NumberFormatter, SubnetHelper, SubnetHelperType, useLocalization } from "@infrastructure";
import { BigInteger } from "jsbn";
import _ from "lodash";
import React from "react";
import { Contract, useNetworkContext, useTheme } from "../../../../../../..";
import { SubnetIcon, SubnetsIcon } from "../icons";

export function useSubnetDefinition(
    serviceSubnets: boolean | undefined,
    specialSource: Contract.NetworkGraphSpecialSource | undefined,
    subnetOrSubnets: string | string[] | undefined) {
    const localization =
        useLocalization(
            "common.network.graph.subnetNodeContent.hooks.useSubnetDefinition",
            () => ({
                customSubnet: [
                    "{{formattedIpAddressCount}} IP Address",
                    "{{formattedIpAddressCount}} IP Addresses"
                ],
                subnet: [
                    "1 IP Address range",
                    "{{count | NumberFormatter.humanize}} IP Address ranges"
                ],
                subnetType: {
                    [SubnetHelperType.Any]: "All IP Addresses",
                    [SubnetHelperType.AnyIpV4]: "All IPv4 Addresses",
                    [SubnetHelperType.AnyIpV6]: "All IPv6 Addresses"
                }
            }));
    const { definition } = useNetworkContext();
    const theme = useTheme();

    if (!_.isNil(specialSource)) {
        return definition.getSpecialSourceSubnetDefinition(specialSource);
    }

    const subnets = _.concat(subnetOrSubnets!);
    const subnetsType = SubnetHelper.getType(subnets);
    const subnetsIpCount =
        _(subnets).
            map(SubnetHelper.getIpCount).
            reduce(
                (subnetsIpCount, subnetIpCount) => subnetsIpCount.add(subnetIpCount),
                new BigInteger("0"))!;
    const [formattedIpAddressCount, ipAddressCount] =
        subnetsIpCount.compareTo(
            new BigInteger("1000000")) > 0
            ? ["> 1M", 1000000]
            : [NumberFormatter.humanize(subnetsIpCount.intValue()), subnetsIpCount.intValue()];
    return ({
        ...(!serviceSubnets && SubnetHelper.isSensitive(subnets)
            ? {
                highlightColor: theme.palette.error.main,
                normalColor: theme.palette.error.light
            }
            : {
                highlightColor: theme.palette.text.primary,
                normalColor: theme.palette.text.secondary
            }),
        icon:
            serviceSubnets!
                ? subnets.length === 1
                    ? definition.serviceSubnetIcon
                    : definition.servicesSubnetIcon
                : subnets.length === 1
                    ? <SubnetIcon/>
                    : <SubnetsIcon/>,
        subtitle:
            subnetsType === SubnetHelperType.Custom
                ? localization.customSubnet(
                    ipAddressCount,
                    {
                        formattedIpAddressCount
                    })
                : localization.subnetType[subnetsType]({ formattedIpAddressCount }),
        title:
            subnets.length === 1
                ? subnets[0]
                : localization.subnet(subnets.length)
    });
}