﻿import { ItemSelector, Loading, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useState } from "react";
import { Contract, entityModelStore, InfoCard, InfoItem, KeyValueTable, KeyValueTableItem } from "../../../../../../../../../../../../common";
import { useGcpIamWorkloadIdentityPoolProviderTypeTranslator } from "../../../../../../../../hooks";

type ProvidersProps = {
    providerIds: string[];
};

export function Providers({ providerIds }: ProvidersProps) {
    const providerModels = entityModelStore.useGet(providerIds) as Contract.GcpIamWorkloadIdentityPoolProviderModel[];
    const providerModelMap =
        _.keyBy(
            providerModels,
            providerModel => providerModel.id);

    const [selectedProviderModel, setSelectedProviderModel] = useState(providerModels[0]);

    const providerTypeTranslator = useGcpIamWorkloadIdentityPoolProviderTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpIamWorkloadIdentityPoolDefinition.providers",
            () => ({
                items: {
                    awsTenantRawId: "AWS Account",
                    enabled: {
                        false: "Disabled",
                        title: "Status",
                        true: "Enabled"
                    },
                    federationAttributeToGcpAttributeMap: "Attribute Mapping",
                    type: "Type"
                }
            }));

    const selectedProvider = selectedProviderModel.entity as Contract.GcpIamWorkloadIdentityPoolProvider;
    return (
        <InfoCard>
            <ItemSelector
                dense={true}
                fullWidth={true}
                items={_.keys(providerModelMap)}
                selectedItem={selectedProvider.id}
                sorted={false}
                onSelectedItemChanged={providerModelId => setSelectedProviderModel(providerModelMap[providerModelId])}>
                {providerModelId => providerModelMap[providerModelId].entity.displayName}
            </ItemSelector>
            <Loading>
                <InfoCard columns={true}>
                    <InfoItem
                        key="type"
                        title={localization.items.type()}
                        value={providerTypeTranslator(selectedProvider.type)}/>
                    <InfoItem
                        title={localization.items.enabled.title()}
                        value={
                            selectedProvider.enabled
                                ? localization.items.enabled.true()
                                : localization.items.enabled.false()}/>
                    {!_.isNil(selectedProvider.awsTenantRawId) &&
                        <InfoItem
                            title={localization.items.awsTenantRawId()}
                            value={selectedProvider.awsTenantRawId}/>}
                </InfoCard>
                <InfoCard title={localization.items.federationAttributeToGcpAttributeMap()}>
                    <KeyValueTable
                        items={
                            _.map(
                                selectedProvider.attributeData.federationAttributeToGcpAttributeMap,
                                (value, key) =>
                                    new KeyValueTableItem(
                                        key,
                                        value))}/>
                </InfoCard>
            </Loading>
        </InfoCard>);
}