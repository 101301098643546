import { CopyToClipboardText } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import React from "react";
import { Contract, NetworkAccessScopeIcon, useNetworkAccessScopeTranslator } from "../../../../../common";

type NetworkAccessScopeProps = {
    accessScope: Contract.NetworkAccessScope;
    copyToClipboard?: boolean;
};

export function NetworkAccessScope({ accessScope, copyToClipboard = true }: NetworkAccessScopeProps) {
    const networkAccessScopeTranslator = useNetworkAccessScopeTranslator();
    const translatedAccessScope = networkAccessScopeTranslator(accessScope);
    return (
        <Stack
            alignItems="center"
            direction="row"
            spacing={1}
            sx={{ overflow: "hidden" }}>
            <NetworkAccessScopeIcon
                accessScope={accessScope}
                sx={{ fontSize: "18px" }}/>
            {copyToClipboard
                ? <CopyToClipboardText text={translatedAccessScope}/>
                : <Typography>
                    {translatedAccessScope}
                </Typography>}
        </Stack>);
}