import { InlineTexts } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useAwsIamRoleAssumeRolePolicyDocumentStatementGranteeTranslator } from "..";
import { Contract } from "../../../common";

export function AwsIamRoleAssumeRolePolicyDocumentStatementGranteesCell(item: Contract.EntityModel) {
    const iamRoleAssumeRolePolicyDocumentStatementGranteeTranslator = useAwsIamRoleAssumeRolePolicyDocumentStatementGranteeTranslator();
    const iamRoleAssumeRolePolicyDocumentStatementGranteeTranslated = iamRoleAssumeRolePolicyDocumentStatementGranteeTranslator(item.entity as Contract.AwsIamRole);
    return (
        _.isEmpty(iamRoleAssumeRolePolicyDocumentStatementGranteeTranslated)
            ? undefined
            : <InlineTexts
                texts={iamRoleAssumeRolePolicyDocumentStatementGranteeTranslated}
                variant="itemPlusItemCount"/>);
}