﻿import { Box, Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { useCallback, useRef, useState } from "react";
import { CopyToClipboardText, CsvExportButton, DataTable, DataTableAction, DataTableActions, DataTableColumn, DataTableColumnRenderProps, DataTableFetchItemsResult, DataTableSort, DeferredFilter, EmptyMessageText, EnumValuesFilter, InlineItems, Optional, optionalTableCell, PagedValuesFilter, StringHelper, TimeFormatter, TimeHelper, TimeRangeFilter, useGetExportFileName, useLocalization, useOperation, ValuesFilter, ValuesFilterItem, VerticalFillGrid } from "@infrastructure";
import { Contract, EntitiesCell, EntityTypeFilter, ExportReportHelper, InlineMaliciousFile, ItemSelectionHelper, PagedEntityFilter, ScopeTenantFilter, StorageHelper, Tenant, TimeRangeHelper, useLayoutOptions, WorkloadController } from "../../../../../../common";
import { useOperatingSystemTypeTranslator } from "../../../Entities/hooks";
import { useGetWorkloadResourceScanFileEntityFilterIdPage, useGetWorkloadResourceScanFileValueFilterItemPage, useWorkloadResourceScanFileTypeTranslator } from "../../hooks";
import { InlineItemsCell } from "../InlineItemsCell";
import { ActionsCell } from "./components";

type MaliciousFilesProp = {
    entityIds?: string[];
    tenantId?: string;
    variant?: "risk" | "view" | "entity";
};

export function MaliciousFiles({ entityIds, tenantId, variant = "view" }: MaliciousFilesProp) {
    const getExportFileName = useGetExportFileName(Contract.ReportContentType.Csv);
    const operatingSystemTypeTranslator = useOperatingSystemTypeTranslator();
    const workloadResourceScanFileTypeTranslator = useWorkloadResourceScanFileTypeTranslator();

    const getWorkloadResourceScanFileEntityFilterIdPage =
        useGetWorkloadResourceScanFileEntityFilterIdPage(
            Contract.WorkloadControllerRequestProperty.WorkloadResourceId,
            entityIds);
    const localization =
        useLocalization(
            "views.customer.workloadAnalysis.maliciousFiles",
            () => ({
                columns: {
                    containerImageUsage: "Container Image Usage",
                    contentSha256String: "SHA-256",
                    firstScanTime: "First Scan Time",
                    operatingSystemType: "Operating System Type",
                    paths: "Paths",
                    tenantIds: "Accounts",
                    type: "Type",
                    workloadResourceIds: "Scanned Resources",
                    workloadResourceTypeName: "Scanned Resources Type"
                },
                empty: {
                    withFilter: "No Matching Malicious Files",
                    withoutFilter: "No Malicious Files"
                },
                title: "Malicious Files"
            }));
    useLayoutOptions({
        view:
            _.isNil(entityIds)
                ? { title: localization.title() }
                : undefined
    });

    const getFilters =
        (filterMap: Dictionary<any>) =>
            new Contract.WorkloadControllerMaliciousFileModelsFilters(
                ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.TenantId]),
                _.isNil(entityIds)
                    ? ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.WorkloadResourceId])
                    : new Contract.PagedItemSelection<string>(
                        false,
                        entityIds,
                        Contract.PagedItemSelectionType.Include) as Optional<Contract.PagedItemSelection<string>>,
                ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.WorkloadResourceTypeName]),
                ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.MaliciousFileContentSha256String]),
                TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(dataTableActionsRef.current?.getFiltersTime(), filterMap[Contract.WorkloadControllerRequestProperty.MaliciousFileFirstScanTime]),
                ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.OperatingSystemType]),
                ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.MaliciousFilePath]),
                ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.MaliciousFileType]));

    const fetchMaliciousFileModelNextPageAggregationCursorRef = useRef<Contract.ElasticsearchIndexAggregationCursor>();

    async function getMaliciousFileModelPageRequest(filterMap: Dictionary<any>, limit: number, skip: number) {
        const nextPageAggregationCursor =
            skip === 0
                ? undefined
                : fetchMaliciousFileModelNextPageAggregationCursorRef.current;

        return new Contract.WorkloadControllerGetMaliciousFileModelPageRequest(
            getFilters(filterMap),
            limit,
            nextPageAggregationCursor);
    }

    const fetchMaliciousFileModels =
        async (filterMap: Dictionary<any>, _sort: Optional<DataTableSort>, skip: number, limit: number) => {

            const maliciousFilesModelPageRequest =
                await getMaliciousFileModelPageRequest(
                    filterMap,
                    limit,
                    skip);

            const getMaliciousFileModelCount =
                async () => {
                    const { maliciousFileModelCount } = await WorkloadController.getMaliciousFileModelCount(maliciousFilesModelPageRequest);
                    return { count: maliciousFileModelCount };
                };

            const { maliciousFileModelPage } = await WorkloadController.getMaliciousFileModelPage(maliciousFilesModelPageRequest);

            return new DataTableFetchItemsResult(
                getMaliciousFileModelCount,
                maliciousFileModelPage.items,
                _.isNil(maliciousFileModelPage.itemNextPageSearchCursor),
                {
                    onAppendData: () => fetchMaliciousFileModelNextPageAggregationCursorRef.current = maliciousFileModelPage.itemNextPageSearchCursor
                });
        };

    const filterMapRef = useRef<Dictionary<any>>();
    const [maliciousFileCount, setMaliciousFileCount] = useState<number>();

    const dataTableActionsRef = useRef<DataTableActions>();


    const fetchPropertyItems =
        async (item: Contract.WorkloadAnalysisFileModel, property: Contract.WorkloadControllerRequestProperty) => {
            const { items } =
                await WorkloadController.getMaliciousFileModelPropertyItems(
                    new Contract.WorkloadControllerMaliciousFileModelPropertyItemsRequest(
                        getFilters(filterMapRef.current ?? {}),
                        property,
                        item.sortModelRawId)) as Contract.WorkloadControllerMaliciousFileModelPropertyItemsResponse<any>;
            return items;
        };

    const [filtersPromise] =
        useOperation(
            MaliciousFiles,
            useCallback(
                async () => {
                    const { filters } = await WorkloadController.getWorkloadResourceScanFileFilters();
                    return filters;
                },
                []));

    return (
        <Box
            sx={{
                height: "100%",
                width: "100%"
            }}>
            <VerticalFillGrid>
                <DataTable
                    actionsRef={dataTableActionsRef}
                    columnOptions={{
                        orderOptions: {
                            enabled: true,
                            persistenceStorageItem: StorageHelper.customerWorkloadAnalysisMaliciousFilesTableColumnOrder
                        },
                        resizable: true,
                        selectorOptions: {
                            enabled: true,
                            persistenceStorageItem: StorageHelper.customerWorkloadAnalysisMaliciousFilesTableColumnSelector
                        },
                        stickyColumnId: Contract.WorkloadControllerRequestProperty.MaliciousFileContentSha256String
                    }}
                    emptyMessageOptions={{
                        emptyMessageText:
                            new EmptyMessageText(
                                localization.empty.withoutFilter(),
                                localization.empty.withFilter())
                    }}
                    fetchItems={fetchMaliciousFileModels}
                    filtersOptions={{
                        initial: {
                            state: filterMapRef.current
                        },
                        onChanged: filterMap => filterMapRef.current = filterMap,
                        persist: {
                            visibilityStorageItem: StorageHelper.customerWorkloadAnalysisMaliciousFilesTableFilters
                        }
                    }}
                    getItemId={fileModel => fileModel.id}
                    sortOptions={{ enabled: false }}
                    variant={
                        variant === "risk"
                            ? "card"
                            : undefined}
                    virtualizationEnabled={true}
                    onItemCountChanged={maliciousFileCount => setMaliciousFileCount(maliciousFileCount)}>
                    <DataTableColumn
                        filterOptions={{
                            itemOrItems: {
                                default: true,
                                element:
                                    <PagedValuesFilter
                                        getValuePage={
                                            useGetWorkloadResourceScanFileValueFilterItemPage(
                                                Contract.WorkloadControllerRequestProperty.MaliciousFileContentSha256String,
                                                entityIds)}
                                        placeholder={localization.columns.contentSha256String()}/>
                            }
                        }}
                        id={Contract.WorkloadControllerRequestProperty.MaliciousFileContentSha256String}
                        key={TableColumnId.MaliciousFileContentSha256String}
                        render={
                            ({ item: fileModel }: DataTableColumnRenderProps<Contract.WorkloadAnalysisFileModel>) =>
                                <InlineMaliciousFile contentSha256String={fileModel.contentSha256String}/>}
                        selectorOptions={{ disabled: true }}
                        title={localization.columns.contentSha256String()}/>
                    <DataTableColumn
                        filterOptions={{
                            itemOrItems: {
                                element:
                                    <DeferredFilter
                                        promiseOrGetPromise={filtersPromise}
                                        title={localization.columns.firstScanTime()}>
                                        {filters =>
                                            <TimeRangeFilter
                                                emptyValue={filters.firstScanTimeRange.emptyValue}
                                                placeholder={localization.columns.firstScanTime()}
                                                timeRange={TimeRangeHelper.getTimeRangeFilterRange(filters.firstScanTimeRange.timeRange)}/>}
                                    </DeferredFilter>
                            }
                        }}
                        id={Contract.WorkloadControllerRequestProperty.MaliciousFileFirstScanTime}
                        key={TableColumnId.MaliciousFileFirstScanTime}
                        render={optionalTableCell<Contract.WorkloadAnalysisFileModel>(fileModel => TimeFormatter.workloadResourceDateTime(fileModel.firstScanTime))}
                        title={localization.columns.firstScanTime()}/>
                    <DataTableColumn
                        filterOptions={{
                            itemOrItems: {
                                default: true,
                                element:
                                    <PagedValuesFilter
                                        getValuePage={
                                            useGetWorkloadResourceScanFileValueFilterItemPage(
                                                Contract.WorkloadControllerRequestProperty.MaliciousFilePath,
                                                entityIds)}
                                        placeholder={localization.columns.paths()}/>
                            }
                        }}
                        id={Contract.WorkloadControllerRequestProperty.MaliciousFilePath}
                        key={TableColumnId.MaliciousFilePath}
                        render={
                            ({ item: fileModel }: DataTableColumnRenderProps<Contract.WorkloadAnalysisFileModel>) =>
                                <InlineItemsCell
                                    emptyMessageText={
                                        new EmptyMessageText(
                                            localization.empty.withoutFilter(),
                                            localization.empty.withFilter())}
                                    getPopoverItems={
                                        async () =>
                                            await fetchPropertyItems(
                                                fileModel,
                                                Contract.WorkloadControllerRequestProperty.MaliciousFilePath)}
                                    itemCount={fileModel.pathCount}
                                    items={fileModel.topPaths}
                                    renderItem={path => <CopyToClipboardText text={path}/>}/>}
                        title={localization.columns.paths()}/>
                    {_.isNil(entityIds) && (
                        <DataTableColumn
                            filterOptions={{
                                itemOrItems: {
                                    default: true,
                                    element:
                                        <DeferredFilter
                                            promiseOrGetPromise={filtersPromise}
                                            title={localization.columns.tenantIds()}>
                                            {filters =>
                                                <ScopeTenantFilter
                                                    placeholder={localization.columns.tenantIds()}
                                                    tenantIds={filters.tenantIdItems.items}/>}
                                        </DeferredFilter>
                                }
                            }}
                            id={Contract.WorkloadControllerRequestProperty.TenantId}
                            key={TableColumnId.TenantId}
                            render={
                                ({ item: fileModel }: DataTableColumnRenderProps<Contract.WorkloadAnalysisFileModel>) =>
                                    <InlineItemsCell
                                        getPopoverItems={
                                            async () =>
                                                await fetchPropertyItems(
                                                    fileModel,
                                                    Contract.WorkloadControllerRequestProperty.TenantId)}
                                        itemCount={fileModel.tenantCount}
                                        items={fileModel.tenantTopIds}
                                        renderItem={
                                            tenantId =>
                                                <Tenant
                                                    tenantId={tenantId}
                                                    variant="iconText"/>}/>}
                            title={localization.columns.tenantIds()}/>)}
                    {_.isNil(entityIds) &&
                        <DataTableColumn
                            filterOptions={{
                                itemOrItems: [
                                    {
                                        default: true,
                                        element:
                                            <PagedEntityFilter
                                                getEntityIdPage={getWorkloadResourceScanFileEntityFilterIdPage}
                                                placeholder={localization.columns.workloadResourceIds()}/>,
                                        id: Contract.WorkloadControllerRequestProperty.WorkloadResourceId,
                                        title: localization.columns.workloadResourceIds()
                                    },
                                    {
                                        default: true,
                                        element:
                                            <DeferredFilter
                                                promiseOrGetPromise={filtersPromise}
                                                title={localization.columns.workloadResourceTypeName()}>
                                                {filters =>
                                                    <EntityTypeFilter
                                                        emptyValue={filters.workloadResourceTypeNameItems.emptyValue}
                                                        entityTypeNames={filters.workloadResourceTypeNameItems.items}
                                                        groupItemTitle={false}
                                                        placeholder={localization.columns.workloadResourceTypeName()}/>}
                                            </DeferredFilter>,
                                        id: Contract.WorkloadControllerRequestProperty.WorkloadResourceTypeName,
                                        title: localization.columns.workloadResourceTypeName()
                                    }
                                ]
                            }}
                            id={Contract.WorkloadControllerRequestProperty.WorkloadResourceId}
                            key={TableColumnId.WorkloadResourceId}
                            render={
                                ({ item: fileModel }: DataTableColumnRenderProps<Contract.WorkloadAnalysisFileModel>) =>
                                    <EntitiesCell
                                        deferredEntities={{
                                            count: fileModel.workloadResourceCount,
                                            firstItem: _.first(fileModel.workloadResourceTopIds),
                                            getItems:
                                                async () =>
                                                    await fetchPropertyItems(
                                                        fileModel,
                                                        Contract.WorkloadControllerRequestProperty.WorkloadResourceId)
                                        }}
                                        entityTypeName={Contract.TypeNames.Entity}
                                        entityVariant="iconTextTypeTenant"/>}
                            title={localization.columns.workloadResourceIds()}/>}
                    <DataTableColumn
                        filterOptions={{
                            itemOrItems: {
                                default: true,
                                element:
                                    <ValuesFilter
                                        placeholder={localization.columns.type()}
                                        sorted={false}>
                                        {_(Contract.WorkloadResourceScanFileType).
                                            values().
                                            orderBy(
                                                workloadResourceScanFileType =>
                                                    StringHelper.getCombineSortValue(
                                                        workloadResourceScanFileType === Contract.WorkloadResourceScanFileType.Unknown,
                                                        workloadResourceScanFileTypeTranslator(workloadResourceScanFileType))).
                                            map(
                                                workloadResourceScanFileType =>
                                                    <ValuesFilterItem
                                                        key={workloadResourceScanFileType}
                                                        title={workloadResourceScanFileTypeTranslator(workloadResourceScanFileType)}
                                                        value={workloadResourceScanFileType}/>).
                                            value()}
                                    </ValuesFilter>
                            }
                        }}
                        id={Contract.WorkloadControllerRequestProperty.MaliciousFileType}
                        key={TableColumnId.MaliciousFileType}
                        render={
                            ({ item }: DataTableColumnRenderProps<Contract.WorkloadAnalysisFileModel>) =>
                                <Typography noWrap={true}>
                                    <InlineItems
                                        items={
                                            _.map(
                                                item.types,
                                                workloadResourceScanFileTypeTranslator)}
                                        variant="itemPlusItemCount"/>
                                </Typography>}
                        title={localization.columns.type()}/>
                    <DataTableColumn
                        filterOptions={{
                            itemOrItems: {
                                default: true,
                                element:
                                    <EnumValuesFilter
                                        enumType={Contract.OperatingSystemType}
                                        enumTypeTranslator={operatingSystemTypeTranslator}
                                        placeholder={localization.columns.operatingSystemType()}/>
                            }
                        }}
                        id={Contract.WorkloadControllerRequestProperty.OperatingSystemType}
                        itemProperty={(fileModel: Contract.WorkloadAnalysisFileModel) => operatingSystemTypeTranslator(fileModel.operatingSystemType)}
                        key={TableColumnId.OperatingSystemType}
                        title={localization.columns.operatingSystemType()}/>
                    <DataTableColumn
                        cellMinWidth={50}
                        disableAction={true}
                        id={TableColumnId.Actions}
                        key={TableColumnId.Actions}
                        orderable={false}
                        render={
                            ({ item }: DataTableColumnRenderProps<Contract.WorkloadAnalysisFileModel>) =>
                                <ActionsCell
                                    contentSha256String={item.contentSha256String}
                                    tenantId={tenantId}/>}
                        resizable={false}
                        selectorOptions={{ disabled: true }}/>
                    <DataTableAction>
                        <CsvExportButton
                            fileNameOptions={{
                                filtered: !_.isEmpty(filterMapRef.current),
                                prefix: localization.title()
                            }}
                            itemCount={maliciousFileCount}
                            showLimitMessage={false}
                            onClick={
                                async fileNameOptions => {
                                    await ExportReportHelper.downloadRemote(
                                        new Contract.ReportControllerWorkloadAnalysisMaliciousFilesReportRequestDefinition(
                                            getExportFileName(fileNameOptions),
                                            TimeHelper.timeZoneId(),
                                            Contract.TypeNames.ReportControllerWorkloadAnalysisMaliciousFilesReportRequestDefinition,
                                            _.isNil(filterMapRef.current),
                                            getFilters(filterMapRef.current ?? {})));
                                }}/>
                    </DataTableAction>
                </DataTable>
            </VerticalFillGrid>
        </Box>);
}

enum TableColumnId {
    Actions = "actions",
    MaliciousFileContentSha256String = "maliciousFileContentSha256String",
    MaliciousFileFirstScanTime = "maliciousFileFirstScanTime",
    MaliciousFilePath = "maliciousFilePath",
    MaliciousFileType = "maliciousFileType",
    OperatingSystemType = "operatingSystemType",
    TenantId = "tenantId",
    WorkloadResourceId = "workloadResourceId"
}