﻿import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useWorkloadResourceScanFileTypeTranslator() {
    const localization =
        useLocalization(
            "views.customer.workloadAnalysis.hooks.useWorkloadResourceScanFileTypeTranslator",
            () => ({
                [Contract.TypeNames.WorkloadResourceScanFileType]: {
                    [Contract.WorkloadResourceScanFileType.Elf]: "ELF",
                    [Contract.WorkloadResourceScanFileType.Pe]: "PE",
                    [Contract.WorkloadResourceScanFileType.Script]: "Script",
                    [Contract.WorkloadResourceScanFileType.Unknown]: "Other Executable"
                }
            }));
    return (workloadResourceScanFileType: Contract.WorkloadResourceScanFileType) =>
        localization[Contract.TypeNames.WorkloadResourceScanFileType][workloadResourceScanFileType]();
}