import { DataTable, DataTableColumn, DataTableColumnRenderProps, EmptyMessageText, InlineItems, optionalTableCell, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { AwsElbLoadBalancerListenerProtocols } from ".";
import { Contract, EntitiesCell, entityModelStore, SourceToTargetMap } from "../../../common";

type AwsElbClassicLoadBalancerListenersTableProps = {
    instanceIds: string[];
    loadBalancerListenerIds: string[];
};

export function AwsElbClassicLoadBalancerListenersTable({ instanceIds, loadBalancerListenerIds }: AwsElbClassicLoadBalancerListenersTableProps) {
    const loadBalancerListenerModels = entityModelStore.useGet(loadBalancerListenerIds);

    const localization =
        useLocalization(
            "tenants.aws.awsElbClassicLoadBalancerListenersTable",
            () => ({
                columns: {
                    certificates: "SSL Certificate",
                    instances: "Instances",
                    ports: "Port",
                    protocols: "Protocol",
                    securityPolicy: {
                        custom: "Custom Policy",
                        title: "Security Policy"
                    },
                    sslProtocolNames: "Supported Protocols"
                },
                empty: "No Listeners"
            }));
    return (
        <DataTable
            emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty()) }}
            fetchItems={() => loadBalancerListenerModels}
            getItemId={(item: Contract.EntityModel) => item.id}
            sortOptions={{ enabled: false }}
            variant="card">
            <DataTableColumn
                id={AwsElbClassicLoadBalancerListenersTableColumnId.Protocols}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.EntityModel>) =>
                        <AwsElbLoadBalancerListenerProtocols
                            protocol={(item.entity as Contract.AwsElbClassicLoadBalancerListener).protocol}
                            targetProtocol={(item.entity as Contract.AwsElbClassicLoadBalancerListener).targetProtocol}/>}
                title={localization.columns.protocols()}/>
            <DataTableColumn
                id={AwsElbClassicLoadBalancerListenersTableColumnId.Ports}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.EntityModel>) =>
                        <SourceToTargetMap
                            source={(item.entity as Contract.AwsElbClassicLoadBalancerListener).port}
                            target={(item.entity as Contract.AwsElbClassicLoadBalancerListener).targetPort}/>}
                title={localization.columns.ports()}/>
            <DataTableColumn
                id={AwsElbClassicLoadBalancerListenersTableColumnId.SecurityPolicy}
                render={
                    optionalTableCell<Contract.EntityModel>(
                        item =>
                            _.isNil((item.entity as Contract.AwsElbClassicLoadBalancerListener).securityPolicy)
                                ? undefined
                                : (item.entity as Contract.AwsElbClassicLoadBalancerListener).securityPolicy!.name ?? localization.columns.securityPolicy.custom())}
                title={localization.columns.securityPolicy.title()}/>
            <DataTableColumn
                id={AwsElbClassicLoadBalancerListenersTableColumnId.Certificates}
                render={
                    optionalTableCell<Contract.EntityModel>(
                        item =>
                            _.isEmpty((item.entity as Contract.AwsElbClassicLoadBalancerListener).certificateIds)
                                ? undefined
                                : <EntitiesCell
                                    entityIdsOrModels={(item.entity as Contract.AwsElbClassicLoadBalancerListener).certificateIds}
                                    entityTypeName={Contract.TypeNames.AwsAcmCertificate}
                                    entityVariant="iconTextTypeTenant"/>)}
                title={localization.columns.certificates()}/>
            <DataTableColumn
                id={AwsElbClassicLoadBalancerListenersTableColumnId.SslProtocolNames}
                render={
                    optionalTableCell<Contract.EntityModel>(
                        item =>
                            _.isNil((item.entity as Contract.AwsElbLoadBalancerListener).securityPolicy)
                                ? undefined
                                : <InlineItems
                                    items={_.orderBy((item.entity as Contract.AwsElbLoadBalancerListener).securityPolicy!.sslProtocolNames)}
                                    variant="itemPlusItemCount"/>)}
                title={localization.columns.sslProtocolNames()}/>
            <DataTableColumn
                id={AwsElbClassicLoadBalancerListenersTableColumnId.Instances}
                render={
                    () =>
                        <EntitiesCell
                            entityIdsOrModels={instanceIds}
                            entityTypeName={Contract.TypeNames.AwsEc2Instance}
                            inlineEntitiesVariant="itemOrItemCountAndType"/>}
                title={localization.columns.instances()}/>
        </DataTable>);
}

enum AwsElbClassicLoadBalancerListenersTableColumnId {
    Certificates = "certificate",
    Instances = "instances",
    Ports = "ports",
    Protocols = "protocols",
    SecurityPolicy = "securityPolicy",
    SslProtocolNames = "sslProtocolNames"
}