import { Link, Step, Steps, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, Entity, entityModelStore, InlineEntities, RegionModelHelper, tenantModelStore } from "../../../../../../../../../../../../../../common";
import { OciConsoleUrlBuilder, useOciConsoleSignInStepTranslator } from "../../../../../../../../../../../../../../tenants";
import { useCommonSectionsAndDescriptionDefinition } from "../../../../../useCommonSectionsAndDescriptionDefinition";
import { useGetOciComputeInstanceConfigurationRiskContext, useGetOciComputeInstancePoolsRiskContext, useGetOciComputeInstancesRiskContext } from "../../../contexts";

export function useOciComputeInstanceVolumeEncryptionInTransitDisabledRiskInstanceConfigurationDefinition(riskModel: Contract.OciComputeInstanceVolumeEncryptionInTransitDisabledRiskModel) {
    const risk = riskModel.risk as Contract.OciComputeInstanceVolumeEncryptionInTransitDisabledRisk;
    const instanceConfigurationModel = entityModelStore.useGet(risk.entityId) as Contract.OciComputeInstanceConfigurationModel;

    const getOciComputeInstanceConfigurationRiskContext = useGetOciComputeInstanceConfigurationRiskContext();
    const getOciComputeInstancePoolsRiskContext = useGetOciComputeInstancePoolsRiskContext();
    const getOciComputeInstancesRiskContext = useGetOciComputeInstancesRiskContext();

    const tenantModel = tenantModelStore.useGet(instanceConfigurationModel.entity.tenantId);
    const ociTenantModel = tenantModelStore.useGet((tenantModel.configuration as Contract.OciTenantConfiguration).ocid);
    const ociTenantConfiguration = ociTenantModel.configuration as Contract.OciTenantConfiguration;

    const consoleSignInStepTranslator = useOciConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.oci.hooks.compliance.useOciComputeInstanceVolumeEncryptionInTransitDisabledRiskDefinition.hooks.useOciComputeInstanceVolumeEncryptionInTransitDisabledRiskInstanceConfigurationDefinition",
            () => ({
                description: "{{instanceConfiguration}} volume in-transit encryption is not enabled",
                sections: {
                    resolution: {
                        step1: "Enabling in-transit encryption on an existing instance configuration is not supported. To resolve this issue, you must delete the current instance configuration and create a new one with in-transit encryption enabled.",
                        step2: {
                            step1: "Click **Create instance configuration**",
                            step2: {
                                link: "OCI documentation",
                                text: "Make sure the selected instance shape supports in-transit encryption. For more information, see {{instanceVolumeEncryptionInTransitSupportedShapesAndImagesLink}}"
                            },
                            step3: "In the **Boot volume** section, select the **Use in-transit encryption** option",
                            step4: "Click **Create**",
                            title: "Create a new instance configuration"
                        },
                        step3: {
                            step1: "For each instance pool, Click **Edit**",
                            step2: "For **Instance configuration**, select the new instance configuration",
                            step3: "Click **Save**",
                            step4: "Changes only apply to new instances. To update old instances, terminate them, and the instance pool will automatically spawn replacements as needed",
                            title: "Update {{instancePools}} with the newly created instance configuration"
                        },
                        step4: {
                            step1: "Navigate to the newly created Instance Configuration page",
                            step2: "Click **Launch instance**",
                            step3: "Click **Create**",
                            step4: "Delete the insecure instance",
                            title: "Repeat the following steps for {{instances}} that are not attached to any instance pool, and therefore need to be recreated manually"
                        },
                        step5: "Delete the insecure instance configuration {{instanceConfiguration}}"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            instanceConfiguration:
                <Entity
                    entityIdOrModel={instanceConfigurationModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.OciConsoleView.Compute,
                OciConsoleUrlBuilder.GetComputeInstanceConfigurationsUrl(
                    ociTenantConfiguration.realmId,
                    RegionModelHelper.get(instanceConfigurationModel.entity.regionId!).systemName!)),
            localization.sections.resolution.step1(),
            new Step(
                localization.sections.resolution.step2.title(),
                {
                    contentElement:
                        <Steps variant="plainNumbers">
                            {localization.sections.resolution.step2.step1()}
                            {localization.sections.resolution.step2.step2.text({
                                instanceVolumeEncryptionInTransitSupportedShapesAndImagesLink:
                                    <Link
                                        urlOrGetUrl={riskModel.instanceVolumeEncryptionInTransitSupportedShapesAndImagesDocumentationUrl}
                                        variant="external">
                                        {localization.sections.resolution.step2.step2.link()}
                                    </Link>
                            })}
                            {localization.sections.resolution.step2.step3()}
                            {localization.sections.resolution.step2.step4()}
                        </Steps>
                }),
            _.isEmpty(instanceConfigurationModel.instancePoolIds)
                ? undefined
                : new Step(
                    localization.sections.resolution.step3.title({
                        instancePools:
                            <InlineEntities
                                entityIdsOrModels={instanceConfigurationModel.instancePoolIds}
                                entityTypeName={Contract.TypeNames.OciComputeInstancePool}
                                variant="itemCountAndType"/>
                    }),
                    {
                        contentElement:
                            <Steps variant="plainNumbers">
                                {localization.sections.resolution.step3.step1()}
                                {localization.sections.resolution.step3.step2()}
                                {localization.sections.resolution.step3.step3()}
                                {localization.sections.resolution.step3.step4()}
                            </Steps>
                    }),
            _.isEmpty(riskModel.risk.nonInstancePoolInstanceIds)
                ? undefined
                : new Step(
                    localization.sections.resolution.step4.title({
                        instances:
                            <InlineEntities
                                entityIdsOrModels={riskModel.risk.nonInstancePoolInstanceIds}
                                entityTypeName={Contract.TypeNames.OciComputeInstance}
                                variant="itemCountAndType"/>
                    }),
                    {
                        contentElement:
                            <Steps variant="plainNumbers">
                                {localization.sections.resolution.step4.step1()}
                                {localization.sections.resolution.step4.step2()}
                                {localization.sections.resolution.step4.step3()}
                                {localization.sections.resolution.step4.step4()}
                            </Steps>
                    }),
            localization.sections.resolution.step5({
                instanceConfiguration:
                    <Entity
                        entityIdOrModel={instanceConfigurationModel}
                        variant="text"/>
            })
        ],
        riskModel,
        () => {
            const instanceConfigurationContextItems = getOciComputeInstanceConfigurationRiskContext(instanceConfigurationModel);
            const instancePoolsContextItems = getOciComputeInstancePoolsRiskContext(instanceConfigurationModel.instancePoolIds);
            const instancesContextItems = getOciComputeInstancesRiskContext(instanceConfigurationModel.instanceIds);
            return [
                instanceConfigurationContextItems.generalInformation,
                instanceConfigurationContextItems.instancePools,
                instanceConfigurationContextItems.instances,
                instancesContextItems.runningInstances,
                instancesContextItems.sensitive,
                instanceConfigurationContextItems.sensitive,
                instancePoolsContextItems.sensitive,
                instanceConfigurationContextItems.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}