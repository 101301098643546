import { useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, useObjectTypeCategoryTranslator, useTheme } from "../../../../../../../..";
import { ObjectTypeMetadataModelHelper } from "../../../../../../../../utilities";
import { useUdmObjectPropertyIdTranslator, useUdmObjectTypeNameTranslator } from "../../../../../../hooks";
import { AndIcon, JoinIcon, ObjectTypeCategoryIcon, OrIcon } from "../../../../icons";
import { ObjectSelector, ObjectSelectorItem } from "../../../ObjectSelector";
import { UdmObject } from "../../../UdmObject";
import { PropertiesIcon, UdmObjectPropertyDataTypeIcon } from "./icons";

type PopupProps = {
    enableJoinOperator: boolean;
    objectTypeName: string;
    onGroupOperatorSelected?: (operator: Contract.UdmQueryRuleGroupOperator) => void;
    onJoinOperatorSelected?: () => void;
    onPropertySelected: (property: Contract.UdmObjectProperty) => void;
    parentObjectTypeName?: string;
};

export function Popup({ enableJoinOperator, objectTypeName, onGroupOperatorSelected, onJoinOperatorSelected, onPropertySelected, parentObjectTypeName }: PopupProps) {
    const objectTypeCategoryTranslator = useObjectTypeCategoryTranslator();
    const udmObjectPropertyIdTranslator = useUdmObjectPropertyIdTranslator();
    const udmObjectTypeNameTranslator = useUdmObjectTypeNameTranslator();
    const localization =
        useLocalization(
            "common.udmObjectTable.udmQueryBuilder.udmObjectFilterSelector.popup.popup",
            () => ({
                operators: {
                    and: {
                        description: "And Description",
                        title: "And expression"
                    },
                    join: {
                        description: "Join Description",
                        title: "Join"
                    },
                    or: {
                        description: "Or Description",
                        title: "Or expression"
                    },
                    title: "Operators"
                },
                properties: "Properties"
            }));

    const [objectPropertyMap, objectDirectProperties, objectCategoryToPropertyMap] =
            useMemo(
                () => {
                    const objectTypeMetadataModel = ObjectTypeMetadataModelHelper.get(objectTypeName);
                    const objectPropertyMap =
                        _.keyBy(
                            objectTypeMetadataModel.udmProperties,
                            udmProperty => udmProperty.id);

                    const [objectDirectProperties, objectRelationProperties] =
                        _(objectTypeMetadataModel.udmProperties).
                            orderBy(property => udmObjectPropertyIdTranslator(property.id)).
                            partition(property => _.isNil(property.relation)).
                            value();

                    const objectCategoryToPropertyMap =
                        _(objectRelationProperties).
                            filter(objectRelationProperty => objectRelationProperty.relation!.objectTypeName !== parentObjectTypeName).
                            groupBy(objectRelationProperty => ObjectTypeMetadataModelHelper.get(objectRelationProperty.relation!.objectTypeName).category).
                            value();

                    return [objectPropertyMap, objectDirectProperties, objectCategoryToPropertyMap];
                },
                [objectTypeName, parentObjectTypeName]);

    const theme = useTheme();
    const objectSelectorItems =
        useMemo(
            () => {
                const operatorsItem =
                    new ObjectSelectorItem(
                        "operators",
                        localization.operators.title(),
                        localization.operators.title(),
                        {
                            icon: <AndIcon/>,
                            items:
                                _<ObjectSelectorItem>([]).
                                    concat(
                                        new ObjectSelectorItem(
                                            Contract.UdmQueryRuleGroupOperator.And,
                                            "and",
                                            localization.operators.and.title(),
                                            {
                                                description:
                                                    <Typography sx={{ color: theme.palette.text.secondary }}>
                                                        {localization.operators.and.description()}
                                                    </Typography>,
                                                icon: <AndIcon/>
                                            }),
                                        new ObjectSelectorItem(
                                            Contract.UdmQueryRuleGroupOperator.Or,
                                            "or",
                                            localization.operators.or.title(),
                                            {
                                                description:
                                                    <Typography sx={{ color: theme.palette.text.secondary }}>
                                                        {localization.operators.or.description()}
                                                    </Typography>,
                                                icon: <OrIcon/>
                                            })).
                                    concatIf(
                                        enableJoinOperator,
                                        new ObjectSelectorItem(
                                            FilterOperator.Join,
                                            "join",
                                            localization.operators.join.title(),
                                            {
                                                description:
                                                    <Typography sx={{ color: theme.palette.text.secondary }}>
                                                        {localization.operators.join.description()}
                                                    </Typography>,
                                                icon: <JoinIcon/>
                                            })).
                                    value()
                        });

                const objectDirectPropertiesItem =
                    new ObjectSelectorItem(
                        "properties",
                        localization.properties(),
                        localization.properties(),
                        {
                            icon: <PropertiesIcon/>,
                            items:
                                _.map(
                                    objectDirectProperties,
                                    objectDirectProperty =>
                                        new ObjectSelectorItem(
                                            objectDirectProperty.id,
                                            udmObjectPropertyIdTranslator(objectDirectProperty.id),
                                            udmObjectPropertyIdTranslator(objectDirectProperty.id),
                                            {
                                                description:
                                                    <Typography sx={{ color: theme.palette.text.secondary }}>
                                                        {udmObjectPropertyIdTranslator(objectDirectProperty.id, "description")}
                                                    </Typography>,
                                                icon:
                                                    <UdmObjectPropertyDataTypeIcon propertyDataType={objectDirectProperty.dataType}/>
                                            }))
                        });

                const objectCategoryItems =
                    _(objectCategoryToPropertyMap).
                        keys().
                        orderBy([
                            objectTypeCategory => objectTypeCategory === Contract.ObjectTypeCategory.Risk,
                            objectTypeCategory => objectTypeCategoryTranslator(objectTypeCategory as Contract.ObjectTypeCategory)
                        ]).
                        map<ObjectSelectorItem>(
                            objectTypeCategory =>
                                new ObjectSelectorItem(
                                    objectTypeCategory,
                                    objectTypeCategoryTranslator(objectTypeCategory as Contract.ObjectTypeCategory),
                                    objectTypeCategoryTranslator(objectTypeCategory as Contract.ObjectTypeCategory),
                                    {
                                        icon:
                                            <ObjectTypeCategoryIcon category={objectTypeCategory as Contract.ObjectTypeCategory}/>,
                                        items:
                                            _.map(
                                                objectCategoryToPropertyMap[objectTypeCategory],
                                                objectProperty =>
                                                    new ObjectSelectorItem(
                                                        objectProperty.id,
                                                        udmObjectTypeNameTranslator(objectProperty.relation!.objectTypeName),
                                                        <UdmObject objectTypeName={objectProperty.relation!.objectTypeName}/>,
                                                        {
                                                            description:
                                                                <Typography sx={{ color: theme.palette.text.secondary }}>
                                                                    {udmObjectPropertyIdTranslator(objectProperty.id, "description")}
                                                                </Typography>
                                                        }))
                                    })).
                        value();

                return [operatorsItem, objectDirectPropertiesItem, ...objectCategoryItems];
            },
            [objectCategoryToPropertyMap, theme]);

    return (
        <ObjectSelector
            items={objectSelectorItems}
            selectedItemId={objectTypeName}
            onSelectedItemIdChanged={
                itemId => {
                    if (itemId === FilterOperator.Join) {
                        onJoinOperatorSelected?.();
                    } else if (itemId === Contract.UdmQueryRuleGroupOperator.And || itemId === Contract.UdmQueryRuleGroupOperator.Or) {
                        onGroupOperatorSelected?.(itemId);
                    } else {
                        onPropertySelected(objectPropertyMap[itemId]);
                    }
                }}/>);
}

export enum FilterOperator {
    And = "and",
    Join = "join",
    Or = "or"
}