import { Link, Optional } from "@infrastructure";
import { Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { entityModelStore } from "../stores";

export type TenantEntityParentPathProps = {
    parentEntityId: Optional<string>;
    parentEntityPath: string;
};

export function TenantEntityParentPathCell({ parentEntityId, parentEntityPath }: TenantEntityParentPathProps) {
    const parentEntityModel = entityModelStore.useGet(parentEntityId);

    return (
        _.isNil(parentEntityModel?.consoleUrl)
            ? <Typography noWrap={true}>
                {parentEntityPath}
            </Typography>
            : <Link
                urlOrGetUrl={parentEntityModel!.consoleUrl}
                variant="external">
                {parentEntityPath}
            </Link>);
}