import { Dialog } from "@infrastructure";
import React from "react";
import { TicketingServicePrompt, TicketingServicePromptMessage } from "../TicketingServicePrompt";
import { UpsertServiceNowIncident, UpsertServiceNowIncidentProps } from "./components";

export type UpsertServiceNowIncidentDialogProps =
    UpsertServiceNowIncidentProps & {
        promptMessage?: TicketingServicePromptMessage;
    };

export function UpsertServiceNowIncidentDialog({ instanceId, instanceModels, onClose, promptMessage, riskIdToIncidentIdsMap, riskType }: UpsertServiceNowIncidentDialogProps) {
    return (
        <TicketingServicePrompt
            message={promptMessage}
            onCancel={() => onClose()}>
            <Dialog
                variant="editor"
                onClose={onClose}>
                <UpsertServiceNowIncident
                    instanceId={instanceId}
                    instanceModels={instanceModels}
                    riskIdToIncidentIdsMap={riskIdToIncidentIdsMap}
                    riskType={riskType}
                    onClose={onClose}/>
            </Dialog>
        </TicketingServicePrompt>);
}