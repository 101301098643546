import { SvgIcon, SvgIconProps, useTheme } from "@mui/material";
import React from "react";

export function DisabledUncheckedIcon(props: SvgIconProps) {
    const theme = useTheme();
    return (
        <SvgIcon {...props}>
            <rect
                fill="none"
                height="15"
                rx="1.5"
                stroke={theme.palette.borders.primary}
                width="15"
                x="4.5"
                y="4.5"/>
        </SvgIcon>);
}