import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, DeferredFilter, InlineItems, optionalTableCell, PagedValuesFilter, TimeFormatter, TimeRangeFilter, useFormatRelativeTime, useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, PagedEntityFilter, SeverityFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../common";
import { VulnerabilitiesChartCell } from "../../../../../../WorkloadAnalysis";
import { useGetWorkloadResourceVulnerabilitiesCsvItem } from "../../../../../../WorkloadAnalysis/hooks";
import { DefinitionData } from "../../../Table";
import { ContainerImageUsageFilter } from "../components";

export function useCommonContainerImageDefinition(definitionData: DefinitionData) {
    const entitiesExportOptions = useEntitiesExportOptions();
    const formatRelativeTime = useFormatRelativeTime();
    const containerImageModelFiltersPromise = _.as<Promise<Contract.IContainerImageFilters>>(definitionData.entityModelFiltersPromise);
    const getWorkloadAnalysisVulnerabilitiesCsvItem = useGetWorkloadResourceVulnerabilitiesCsvItem();

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.useCommonContainerImageDefinition",
            () => ({
                columns: {
                    creationTime: "Addition Time",
                    digest: "Digest",
                    kubernetesWorkloadResourceIds: "K8s Workloads",
                    repositoryId: "Repository",
                    scanTime: "Scan Time",
                    tags: "Image Tags",
                    usage: "Usage",
                    virtualMachineIds: "Virtual Machines",
                    vulnerabilities: "Vulnerabilities",
                    vulnerabilitySeverities: "Vulnerability Severity",
                    workloadClusterResourceIds: "Clusters"
                }
            }));

    return {
        columns: {
            creationTimeColumn:
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.IContainerImageModel) => ({
                                [localization.columns.creationTime()]:
                                    _.isNil(_.as<Contract.IContainerImage>(item.entity).data.creationTime)
                                        ? ""
                                        : TimeFormatter.iso8601String(_.as<Contract.IContainerImage>(item.entity).data.creationTime)
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <DeferredFilter
                                    promiseOrGetPromise={containerImageModelFiltersPromise}
                                    title={localization.columns.creationTime()}>
                                    {filters =>
                                        <TimeRangeFilter
                                            emptyValue={filters.containerImageCreationTimeRange.emptyValue}
                                            placeholder={localization.columns.creationTime()}
                                            timeRange={TimeRangeHelper.getTimeRangeFilterRange(filters.containerImageCreationTimeRange.timeRange)}/>}
                                </DeferredFilter>
                        }
                    }}
                    id={Contract.EntityModelProperty.ContainerImageCreationTime}
                    key={Contract.EntityModelProperty.ContainerImageCreationTime}
                    render={
                        optionalTableCell<Contract.IContainerImageModel>(
                            item =>
                                _.isNil(_.as<Contract.IContainerImage>(item.entity).data.creationTime)
                                    ? undefined
                                    : formatRelativeTime(_.as<Contract.IContainerImage>(item.entity).data.creationTime))}
                    sortOptions={{ type: DataTableSortType.Date }}
                    title={localization.columns.creationTime()}/>,
            digestColumn:
                <DataTableColumn
                    cellMaxWidth="small"
                    exportOptions={{
                        getItem:
                            (item: Contract.IContainerImageModel) => ({
                                [localization.columns.digest()]: _.as<Contract.IContainerImage>(item.entity).data.digest
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedValuesFilter
                                    getValuePage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.ContainerImageDigest)}
                                    placeholder={localization.columns.digest()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.ContainerImageDigest}
                    itemProperty={(item: Contract.IContainerImageModel) => _.as<Contract.IContainerImage>(item.entity).data.digest}
                    key={Contract.EntityModelProperty.ContainerImageDigest}
                    title={localization.columns.digest()}/>,
            kubernetesWorkloadResourcesColumn:
                <DataTableColumn
                    exportOptions={
                        entitiesExportOptions<Contract.IContainerImageModel>(
                            Contract.TypeNames.IKubernetesWorkloadResource,
                            item => (item as Contract.IContainerImageModel).kubernetesWorkloadResourceIds!,
                            localization.columns.kubernetesWorkloadResourceIds())}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedEntityFilter
                                    getEntityIdPage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.ContainerImageKubernetesWorkloadResources)}
                                    placeholder={localization.columns.kubernetesWorkloadResourceIds()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.ContainerImageKubernetesWorkloadResources}
                    key={Contract.EntityModelProperty.ContainerImageKubernetesWorkloadResources}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.IContainerImageModel>) =>
                            <EntitiesCell
                                entityIdsOrModels={item.kubernetesWorkloadResourceIds}
                                entityTypeName={Contract.TypeNames.IKubernetesWorkloadResource}
                                entityVariant="iconText"/>}
                    sortOptions={{ type: DataTableSortType.Numeric }}
                    title={localization.columns.kubernetesWorkloadResourceIds()}/>,
            repositoryColumn:
                <DataTableColumn
                    exportOptions={
                        entitiesExportOptions<Contract.IContainerImageModel>(
                            Contract.TypeNames.IContainerImageRepository,
                            item => [(item as Contract.IContainerImageModel).repositoryIdReference],
                            localization.columns.repositoryId())}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedEntityFilter
                                    getEntityIdPage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.ContainerImageRepository)}
                                    placeholder={localization.columns.repositoryId()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.ContainerImageRepository}
                    key={Contract.EntityModelProperty.ContainerImageRepository}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.IContainerImageModel>) =>
                            <EntitiesCell
                                entityIdsOrModels={item.repositoryIdReference}
                                entityTypeName={Contract.TypeNames.IContainerImageRepository}
                                entityVariant="iconText"/>}
                    title={localization.columns.repositoryId()}/>,
            scanTimeColumn:
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.IContainerImageModel) => ({
                                [localization.columns.scanTime()]:
                                    _.isNil(_.as<Contract.IContainerImage>(item.entity).data.scanTime)
                                        ? ""
                                        : TimeFormatter.iso8601String(_.as<Contract.IContainerImage>(item.entity).data.scanTime)
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <DeferredFilter
                                    promiseOrGetPromise={containerImageModelFiltersPromise}
                                    title={localization.columns.scanTime()}>
                                    {filters =>
                                        <TimeRangeFilter
                                            emptyValue={filters.scanTimeRange.emptyValue}
                                            placeholder={localization.columns.scanTime()}
                                            timeRange={TimeRangeHelper.getTimeRangeFilterRange(filters.scanTimeRange.timeRange)}/>}
                                </DeferredFilter>
                        }
                    }}
                    id={Contract.EntityModelProperty.ContainerImageScanTime}
                    key={Contract.EntityModelProperty.ContainerImageScanTime}
                    render={
                        optionalTableCell<Contract.IContainerImageModel>(
                            item =>
                                _.isNil(_.as<Contract.IContainerImage>(item.entity).data.scanTime)
                                    ? undefined
                                    : formatRelativeTime(_.as<Contract.IContainerImage>(item.entity).data.scanTime))}
                    sortOptions={{ type: DataTableSortType.Date }}
                    title={localization.columns.scanTime()}/>,
            tagsColumn:
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.IContainerImageModel) => ({
                                [localization.columns.tags()]:
                                    _.join(
                                        _.as<Contract.IContainerImage>(item.entity).data.tags,
                                        "\n")
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedValuesFilter
                                    getValuePage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.ContainerImageTags)}
                                    placeholder={localization.columns.tags()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.ContainerImageTags}
                    key={Contract.EntityModelProperty.ContainerImageTags}
                    render={
                        optionalTableCell(
                            (containerImageModel: Contract.IContainerImageModel) =>
                                _.size(_.as<Contract.IContainerImage>(containerImageModel.entity).data.tags) === 0
                                    ? undefined
                                    : <Typography noWrap={true}>
                                        <InlineItems
                                            items={_.as<Contract.IContainerImage>(containerImageModel.entity).data.tags}
                                            variant="itemPlusItemCount"/>
                                    </Typography>)}
                    sortOptions={{ type: DataTableSortType.Numeric }}
                    title={localization.columns.tags()}/>,
            usageColumn:
                <DataTableColumn
                    filterOptions={{
                        itemOrItems: {
                            element: <ContainerImageUsageFilter/>
                        }
                    }}
                    id={Contract.EntityModelProperty.ContainerImageWorkloadExists}
                    key={Contract.EntityModelProperty.ContainerImageWorkloadExists}
                    title={localization.columns.usage()}/>,
            virtualMachinesColumn:
                <DataTableColumn
                    exportOptions={
                        entitiesExportOptions<Contract.IContainerImageModel>(
                            Contract.TypeNames.IVirtualMachine,
                            item => (item as Contract.IContainerImageModel).virtualMachineIds!,
                            localization.columns.virtualMachineIds())}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedEntityFilter
                                    getEntityIdPage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.ContainerImageVirtualMachines)}
                                    placeholder={localization.columns.virtualMachineIds()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.ContainerImageVirtualMachines}
                    key={Contract.EntityModelProperty.ContainerImageVirtualMachines}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.IContainerImageModel>) =>
                            <EntitiesCell
                                entityIdsOrModels={item.virtualMachineIds}
                                entityTypeName={Contract.TypeNames.IVirtualMachine}
                                entityVariant="iconText"/>}
                    sortOptions={{ type: DataTableSortType.Numeric }}
                    title={localization.columns.virtualMachineIds()}/>,
            vulnerabilitiesColumn:
                <DataTableColumn
                    cellMinWidth={190}
                    exportOptions={{
                        getItem:
                            (item: Contract.IContainerImageModel) => ({
                                [localization.columns.vulnerabilities()]:
                                    getWorkloadAnalysisVulnerabilitiesCsvItem(_.as<Contract.IContainerImage>(item.entity).data.vulnerabilities)
                            })
                    }}
                    filterOptions={{
                        itemOrItems: [
                            {
                                element:
                                    <PagedValuesFilter
                                        getValuePage={
                                            ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                                definitionData.entityTypeEntitiesViewName,
                                                Contract.EntityModelProperty.ContainerImageVulnerabilities)}
                                        placeholder={localization.columns.vulnerabilities()}/>,
                                id: Contract.EntityModelProperty.ContainerImageVulnerabilities,
                                title: localization.columns.vulnerabilities()
                            },
                            {
                                element: <SeverityFilter placeholder={localization.columns.vulnerabilitySeverities()}/>,
                                id: Contract.EntityModelProperty.ContainerImageVulnerabilitySeverities,
                                title: localization.columns.vulnerabilitySeverities()
                            }
                        ]
                    }}
                    id={Contract.EntityModelProperty.ContainerImageVulnerabilities}
                    key={Contract.EntityModelProperty.ContainerImageVulnerabilities}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.IContainerImageModel>) =>
                            <VulnerabilitiesChartCell
                                vulnerabilities={_.as<Contract.IContainerImage>(item.entity).data.vulnerabilities}
                                workloadResourceEntityId={item.id}/>}
                    sortOptions={{ type: DataTableSortType.Numeric }}
                    title={localization.columns.vulnerabilities()}/>,
            workloadClusterResourcesColumn:
                <DataTableColumn
                    cellMinWidth={200}
                    exportOptions={
                        entitiesExportOptions<Contract.IContainerImageModel>(
                            Contract.TypeNames.Entity,
                            item => (item as Contract.IContainerImageModel).workloadClusterResourceIds!,
                            localization.columns.workloadClusterResourceIds())}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedEntityFilter
                                    getEntityIdPage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.ContainerImageWorkloadClusterResources)}
                                    placeholder={localization.columns.workloadClusterResourceIds()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.ContainerImageWorkloadClusterResources}
                    key={Contract.EntityModelProperty.ContainerImageWorkloadClusterResources}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.IContainerImageModel>) =>
                            <EntitiesCell
                                entityIdsOrModels={item.workloadClusterResourceIds}
                                entityTypeName={Contract.TypeNames.Entity}
                                entityVariant="iconText"/>}
                    sortOptions={{ type: DataTableSortType.Numeric }}
                    title={localization.columns.workloadClusterResourceIds()}/>
        }
    };
}