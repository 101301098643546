import { useLocalization } from "@infrastructure";
import _ from "lodash";
import { useMemo } from "react";
import { Contract, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetAwsResourceRiskContext } from "./useGetAwsResourceRiskContext";

export function useGetAwsRoute53HostedZoneRiskContext() {
    return useMemo(
        () => useAwsRoute53HostedZoneContext,
        []);
}

function useAwsRoute53HostedZoneContext(hostedZoneModel: Contract.AwsRoute53HostedZoneModel) {
    const hostedZone = hostedZoneModel.entity as Contract.AwsRoute53HostedZone;
    const resourceRiskContext = useGetAwsResourceRiskContext()(hostedZoneModel);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.contexts.useGetAwsRoute53HostedZoneRiskContext",
            () => ({
                publicNetworkAccess: "The {{translatedHostedZoneTypeName}} type is **Public**",
                records: {
                    none: "The {{translatedHostedZoneTypeName}} contains no records",
                    some: "The {{translatedHostedZoneTypeName}} contains {{recordCount | NumberFormatter.humanize}} records"
                }
            }));
    const translatedHostedZoneTypeName =
        entityTypeNameTranslator(
            hostedZone.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });

    return {
        ...resourceRiskContext,
        publicNetworkAccess:
            hostedZone.publicNetworkAccess
                ? new RiskDefinitionContextItem(localization.publicNetworkAccess({ translatedHostedZoneTypeName }))
                : undefined,
        records:
            new RiskDefinitionContextItem(
                (_.isEmpty(hostedZone.records)
                    ? localization.records.none
                    : localization.records.some)({
                    recordCount: _.size(hostedZone.records),
                    translatedHostedZoneTypeName
                }))
    };
}