import { ActionButton } from "@infrastructure";
import React from "react";
import { CopyToClipboard } from "../CopyToClipboard";
import { CopyToClipboardIcon } from "../icons";

type CopyToClipboardActionButtonProps = {
    getValue: () => string;
    size?: "normal" | "small";
    title?: string;
    tooltip?: string;
};

export function CopyToClipboardActionButton({ getValue, size, title, tooltip }: CopyToClipboardActionButtonProps) {
    return (
        <CopyToClipboard
            getValue={getValue}
            title={title}>
            <ActionButton
                sx={{
                    fontSize:
                        size === "small"
                            ? "12px"
                            : "16px",
                    paddingLeft: 0
                }}
                tooltip={tooltip}>
                <CopyToClipboardIcon/>
            </ActionButton>
        </CopyToClipboard>);
}