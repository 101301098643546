import { DataTableColumn, DataTableColumnRenderProps, EnumValuesFilter, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import { Dictionary } from "lodash";
import React from "react";
import { useOciCommonResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions, useEntityTypeNameTranslator } from "../../../../../../../../../../common";
import { useOciNetworkingLocalPeeringGatewayPeeringStatusTranslator, useOciNetworkingLocalPeeringGatewayStatusTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";

export function useOciNetworkingLocalPeeringGatewayDefinition(definitionData: DefinitionData) {
    const commonResourceDefinition = useOciCommonResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localPeeringGatewayPeeringStatusTranslator = useOciNetworkingLocalPeeringGatewayPeeringStatusTranslator();
    const localPeeringGatewayStatusTranslator = useOciNetworkingLocalPeeringGatewayStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.oci.useOciNetworkingLocalPeeringGatewayDefinition",
            () => ({
                columns: {
                    crossTenancyPeering: {
                        false: "No",
                        title: "Cross-Tenancy",
                        true: "Yes"
                    },
                    peeringStatus: "Peering Status",
                    peerVcn: "Peered Virtual Cloud Network",
                    status: "State"
                }
            }));

    return new EntityTableDefinition(
        [
            commonResourceDefinition.columns.tenantColumn,
            commonResourceDefinition.columns.creationTimeColumn,
            commonResourceDefinition.columns.creatorCsvColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.OciNetworkingLocalPeeringGatewayModel) => ({
                            [localization.columns.status()]: localPeeringGatewayStatusTranslator((item.entity as Contract.OciNetworkingLocalPeeringGateway).status)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.OciNetworkingLocalPeeringGatewayStatus}
                                enumTypeTranslator={localPeeringGatewayStatusTranslator}
                                placeholder={localization.columns.status()}/>
                    }
                }}
                id={Contract.EntityModelProperty.OciNetworkingLocalPeeringGatewayStatus}
                itemProperty={(item: Contract.OciNetworkingLocalPeeringGatewayModel) => localPeeringGatewayStatusTranslator((item.entity as Contract.OciNetworkingLocalPeeringGateway).status)}
                key={Contract.EntityModelProperty.OciNetworkingLocalPeeringGatewayStatus}
                title={localization.columns.status()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.OciNetworkingLocalPeeringGatewayModel) => ({
                            [localization.columns.peeringStatus()]: localPeeringGatewayPeeringStatusTranslator((item.entity as Contract.OciNetworkingLocalPeeringGateway).peeringStatus)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.OciNetworkingLocalPeeringGatewayPeeringStatus}
                                enumTypeTranslator={localPeeringGatewayPeeringStatusTranslator}
                                placeholder={localization.columns.peeringStatus()}/>
                    }
                }}
                id={Contract.EntityModelProperty.OciNetworkingLocalPeeringGatewayPeeringStatus}
                itemProperty={(item: Contract.OciNetworkingLocalPeeringGatewayModel) => localPeeringGatewayPeeringStatusTranslator((item.entity as Contract.OciNetworkingLocalPeeringGateway).peeringStatus)}
                key={Contract.EntityModelProperty.OciNetworkingLocalPeeringGatewayPeeringStatus}
                title={localization.columns.peeringStatus()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.OciNetworkingLocalPeeringGatewayModel>(
                        Contract.TypeNames.OciNetworkingVcn,
                        item => [item.vcnIdReference],
                        entityTypeNameTranslator(Contract.TypeNames.OciNetworkingVcn))}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.OciNetworkingLocalPeeringGatewayVcn)}
                                placeholder={entityTypeNameTranslator(Contract.TypeNames.OciNetworkingVcn)}/>
                    }
                }}
                id={Contract.EntityModelProperty.OciNetworkingLocalPeeringGatewayVcn}
                key={Contract.EntityModelProperty.OciNetworkingLocalPeeringGatewayVcn}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.OciNetworkingLocalPeeringGatewayModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.vcnIdReference}
                            entityTypeName={Contract.TypeNames.OciNetworkingVcn}/>}
                title={entityTypeNameTranslator(Contract.TypeNames.OciNetworkingVcn)}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.OciNetworkingLocalPeeringGatewayModel>(
                        Contract.TypeNames.OciNetworkingVcn,
                        item => [item.peerVcnIdReference],
                        localization.columns.peerVcn())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.OciNetworkingLocalPeeringGatewayPeerVcn)}
                                placeholder={localization.columns.peerVcn()}/>
                    }
                }}
                id={Contract.EntityModelProperty.OciNetworkingLocalPeeringGatewayPeerVcn}
                key={Contract.EntityModelProperty.OciNetworkingLocalPeeringGatewayPeerVcn}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.OciNetworkingLocalPeeringGatewayModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.peerVcnIdReference}
                            entityTypeName={Contract.TypeNames.OciNetworkingVcn}/>}
                title={localization.columns.peerVcn()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.OciNetworkingLocalPeeringGatewayModel) => ({
                            [localization.columns.crossTenancyPeering.title()]:
                                (item.entity as Contract.OciNetworkingLocalPeeringGateway).crossTenancyPeering
                                    ? localization.columns.crossTenancyPeering.true()
                                    : localization.columns.crossTenancyPeering.false()
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <ValuesFilter placeholder={localization.columns.crossTenancyPeering.title()}>
                                <ValuesFilterItem
                                    title={localization.columns.crossTenancyPeering.false()}
                                    value={false}/>
                                <ValuesFilterItem
                                    title={localization.columns.crossTenancyPeering.true()}
                                    value={true}/>
                            </ValuesFilter>
                    }
                }}
                id={Contract.EntityModelProperty.OciNetworkingLocalPeeringGatewayCrossTenancyPeering}
                itemProperty={
                    (item: Contract.EntityModel) =>
                        (item.entity as Contract.OciNetworkingLocalPeeringGateway).crossTenancyPeering
                            ? localization.columns.crossTenancyPeering.true()
                            : localization.columns.crossTenancyPeering.false()}
                key={Contract.EntityModelProperty.OciNetworkingLocalPeeringGatewayCrossTenancyPeering}
                title={localization.columns.crossTenancyPeering.title()}/>,
            commonResourceDefinition.columns.regionColumn,
            commonResourceDefinition.columns.regionLocationColumn,
            commonResourceDefinition.columns.tagsColumn,
            commonResourceDefinition.columns.attributesColumn,
            commonResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageOciNetworkingLocalPeeringGatewayRequest(
                new Contract.EntityControllerGetEntityModelPageOciNetworkingLocalPeeringGatewayRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciNetworkingLocalPeeringGatewayCrossTenancyPeering]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciNetworkingLocalPeeringGatewayPeeringStatus]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciNetworkingLocalPeeringGatewayPeerVcn]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciNetworkingLocalPeeringGatewayStatus]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciNetworkingLocalPeeringGatewayVcn])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags]))));
}