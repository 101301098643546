import React, { Fragment, ReactNode, useState } from "react";
import { Dialog } from "./Dialog";

type ExpandPanelProps = {
    children: (expanded: boolean, setExpanded: () => void) => ReactNode;
};

export function ExpandPanel({ children }: ExpandPanelProps) {
    const [expanded, setExpanded] = useState(false);
    return (
        <Fragment>
            {expanded &&
                <Dialog
                    size="large"
                    variant="viewer"
                    onClose={() => setExpanded(false)}>
                    {children(true, () => setExpanded(true))}
                </Dialog>}
            {children(false, () => setExpanded(true))}
        </Fragment>);
}