import { DataTable, DataTableColumn, DataTableRowOptions, EmptyMessageText, useLocalization } from "@infrastructure";
import type { SxProps } from "@mui/material";
import React from "react";

type AzureFirewallRulesSubnetTableProps = {
    rowOptions?: DataTableRowOptions;
    subnets: string[];
    sx?: SxProps;
};

export function AzureFirewallRulesSubnetTable({ rowOptions, subnets, sx }: AzureFirewallRulesSubnetTableProps) {
    const localization =
        useLocalization(
            "tenants.azure.azureFirewallRulesSubnetTable",
            () => ({
                columns: {
                    subnet: "IP Address Range"
                },
                empty: "No Rules"
            }));
    return (
        <DataTable
            emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty()) }}
            fetchItems={() => subnets}
            getItemId={(item: string) => item}
            rowOptions={rowOptions}
            sortOptions={{ enabled: false }}
            sx={sx}
            variant="card">
            <DataTableColumn
                id="ipAddress"
                itemProperty={(subnet: string) => subnet}
                title={localization.columns.subnet()}/>
        </DataTable>);
}