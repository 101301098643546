import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition, useGetGcpTenantEntityRiskContext } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { EntityExternalConsoleLink } from "../../components";
import { useGetGcpApiKeysKeyRiskContext } from "../contexts";

export function useGcpApiKeysKeyClientRestrictionNotExistsRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.GcpApiKeysKeyClientRestrictionNotExistsRisk;
    const keyModel = entityModelStore.useGet(risk.entityId) as Contract.GcpApiKeysKeyModel;
    const tenantEntityModel = entityModelStore.useGet(risk.tenantId) as Contract.GcpTenantEntityModel;

    const getGcpApiKeysKeyRiskContext = useGetGcpApiKeysKeyRiskContext();
    const getGcpTenantEntityRiskContext = useGetGcpTenantEntityRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.useGcpApiKeysKeyClientRestrictionNotExistsRiskDefinition",
            () => ({
                description: "{{project}} has an {{key}} with no application restriction set",
                sections: {
                    resolution: {
                        step1: "Setting Application Restrictions may break existing application functionality. Ensure that current usage is authorized before making changes",
                        step2: "In the **Key restrictions** section, set the application restrictions to either **Websites**, **IP addresses**, **Android apps** or **iOS apps**. Note: Do not set Websites to wild-cards (* or *.[TLD] or *.[TLD]/*), which allows access to any/wide website. Do not set IP addresses to 0.0.0.0 or 0.0.0.0/0 or 0:: to avoid setting the option to ‘any host/address’",
                        step3: "Click **SAVE**"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            key:
                <Entity
                    entityIdOrModel={keyModel}
                    entityTypeNameTranslatorOptions={{ variant: "text" }}
                    variant="typeText"/>,
            project:
                <Entity
                    entityIdOrModel={tenantEntityModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={risk.entityId}
                key={risk.entityId}
                page={Contract.GcpConsolePage.Resource}/>,
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3()
        ],
        riskModel,
        () => {
            const apiKeysKeyRiskContext = getGcpApiKeysKeyRiskContext(keyModel);
            const tenantEntityRiskContext = getGcpTenantEntityRiskContext(tenantEntityModel);
            return [
                apiKeysKeyRiskContext.generalInformation,
                apiKeysKeyRiskContext.creationTime,
                apiKeysKeyRiskContext.sensitive,
                apiKeysKeyRiskContext.emptyRestrictions,
                apiKeysKeyRiskContext.clientRawIdentifiers,
                apiKeysKeyRiskContext.serviceHostNames,
                tenantEntityRiskContext.sensitive,
                apiKeysKeyRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}