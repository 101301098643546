import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useAwsCloudFormationStackStatusTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useAwsCloudFormationStackStatusTranslator",
            () => ({
                [Contract.TypeNames.AwsCloudFormationStackStatus]: {
                    [Contract.AwsCloudFormationStackStatus.CreateComplete]: "Create Complete",
                    [Contract.AwsCloudFormationStackStatus.CreateFailed]: "Create Failed",
                    [Contract.AwsCloudFormationStackStatus.CreateInProgress]: "Create In-Progress",
                    [Contract.AwsCloudFormationStackStatus.DeleteComplete]: "Delete Complete",
                    [Contract.AwsCloudFormationStackStatus.DeleteFailed]: "Delete Failed",
                    [Contract.AwsCloudFormationStackStatus.DeleteInProgress]: "Delete In-Progress",
                    [Contract.AwsCloudFormationStackStatus.ImportComplete]: "Import Complete",
                    [Contract.AwsCloudFormationStackStatus.ImportInProgress]: "Import In-Progress",
                    [Contract.AwsCloudFormationStackStatus.ImportRollbackComplete]: "Import Rollback Complete",
                    [Contract.AwsCloudFormationStackStatus.ImportRollbackFailed]: "Import Rollback Failed",
                    [Contract.AwsCloudFormationStackStatus.ImportRollbackInProgress]: "Import Rollback In-Progress",
                    [Contract.AwsCloudFormationStackStatus.ReviewInProgress]: "Review In-Progress",
                    [Contract.AwsCloudFormationStackStatus.RollbackComplete]: "Rollback Complete",
                    [Contract.AwsCloudFormationStackStatus.RollbackFailed]: "Rollback Failed",
                    [Contract.AwsCloudFormationStackStatus.RollbackInProgress]: "Rollback In-Progress",
                    [Contract.AwsCloudFormationStackStatus.UpdateComplete]: "Update Complete",
                    [Contract.AwsCloudFormationStackStatus.UpdateCompleteCleanupInProgress]: "Update Complete Cleanup In-Progress",
                    [Contract.AwsCloudFormationStackStatus.UpdateFailed]: "Update Failed",
                    [Contract.AwsCloudFormationStackStatus.UpdateInProgress]: "Update In-Progress",
                    [Contract.AwsCloudFormationStackStatus.UpdateRollbackComplete]: "Update Rollback Complete",
                    [Contract.AwsCloudFormationStackStatus.UpdateRollbackCompleteCleanupInProgress]: "Update Rollback Complete Cleanup In-Progress",
                    [Contract.AwsCloudFormationStackStatus.UpdateRollbackFailed]: "Update Rollback Failed",
                    [Contract.AwsCloudFormationStackStatus.UpdateRollbackInProgress]: "Update Rollback In-Progress"
                }
            }));

    return (status: Contract.AwsCloudFormationStackStatus) =>
        localization[Contract.TypeNames.AwsCloudFormationStackStatus][status]();
}