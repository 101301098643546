import { UnexpectedError } from "@infrastructure";
import _ from "lodash";
import { Contract, TypeHelper } from "..";

export class DagHelper {
    public static getDagEntityReferences(dag: Contract.Dag): Contract.EntityReference[] {
        function getDagNodeEntityReferences(dagNode: Contract.DagNode): Contract.EntityReference[] {
            if (dagNode.typeName === Contract.TypeNames.EntityDagNode) {
                return [(dagNode as Contract.EntityDagNode).entityReference];
            } else if (TypeHelper.extendOrImplement(dagNode.typeName, Contract.TypeNames.DagContainer)) {
                return _.flatMap(
                    (dagNode as Contract.DagContainer).nodes,
                    getDagNodeEntityReferences);
            }

            throw new UnexpectedError("dagNode.typeName", dagNode.typeName);
        }

        return getDagNodeEntityReferences(dag);
    }
}