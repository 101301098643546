import { Dialog, getUrlQueryParameters, makeContextProvider, StringHelper, useClearQueryParameters } from "@infrastructure";
import React from "react";
import { Contract } from "../../../../../../../../../../../../common";
import { AddOrEdit, Table } from "./components";

export class AzureContext {
    constructor(public addOrEditOpen: boolean | Contract.AzureOrganizationConfiguration) {
    }
}

export const [useAzureContext, useSetAzureContext, useAzureContextProvider] = makeContextProvider<AzureContext>();

export function Azure() {
    const clearQueryParameters = useClearQueryParameters();
    const [context, setContext, ContextProvider] =
        useAzureContextProvider(
            () => {
                const queryParameters = getUrlQueryParameters<CloudProviderTenantOrganizationAzureQueryParameters>();
                clearQueryParameters();
                return new AzureContext(StringHelper.isTrue(queryParameters.addOrEditOpen));
            });

    return (
        <ContextProvider>
            {context.addOrEditOpen !== false &&
                <Dialog
                    size="large"
                    variant="editor"
                    onClose={
                        () =>
                            setContext(
                                context => ({
                                    ...context,
                                    addOrEditOpen: false
                                }))}>
                    <AddOrEdit/>
                </Dialog>}
            <Table/>
        </ContextProvider>);
}

export type CloudProviderTenantOrganizationAzureQueryParameters = {
    addOrEditOpen?: string;
};