﻿import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useAwsApiGatewayApiTypeTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useAwsApiGatewayApiTypeTranslator",
            () => ({
                [Contract.TypeNames.AwsApiGatewayApiType]: {
                    [Contract.AwsApiGatewayApiType.Http]: "HTTP",
                    [Contract.AwsApiGatewayApiType.Rest]: "REST",
                    [Contract.AwsApiGatewayApiType.Websocket]: "Websocket"
                }
            }));
    return (protocol: Contract.AwsApiGatewayApiType) =>
        localization[Contract.TypeNames.AwsApiGatewayApiType][protocol]();
}