import { Step, StepOptions, SuccessIcon } from "@infrastructure";
import { Box, Stack } from "@mui/material";
import _ from "lodash";
import React, { ReactNode } from "react";
import { Change, Contract, useTheme } from "../../../../../../../common";

type ChangeStepOptions = Omit<StepOptions, "actionsElement"> & {
    actionElements?: ReactNode[];
    title?: ReactNode;
};

export class ChangeStep extends Step {
    constructor(
        change: Contract.Change,
        options?: ChangeStepOptions) {
        super(
            options?.title ??
            <Change
                change={change}
                entityLinkDisabled={true}/>,
            {
                ...options,
                actionsElement:
                    !_.isNil(options?.actionElements) || change.resolved
                        ? <Actions
                            actionElements={options?.actionElements}
                            resolved={change.resolved}/>
                        : undefined
            });
    }
}

type ActionsProps = {
    actionElements?: ReactNode[];
    resolved: boolean;
};

function Actions({ actionElements, resolved }: ActionsProps) {
    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            direction="row">
            <Box sx={{ flex: 1 }}>
                {resolved && (
                    <SuccessIcon
                        sx={{
                            color: theme.palette.success.main,
                            fontSize: "18px"
                        }}/>)}
            </Box>
            {_.map(
                actionElements,
                actionElement =>
                    actionElement)}
        </Stack>);
}