﻿import { UnexpectedError, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, Entity, InlineEntities, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetAwsResourceRiskContext } from "./useGetAwsResourceRiskContext";

export function useGetAwsSqsQueueRiskContext() {
    return useMemo(
        () => useAwsSqsQueueRiskContext,
        []);
}

function useAwsSqsQueueRiskContext(queueModel: Contract.AwsSqsQueueModel) {
    const queue = queueModel.entity as Contract.AwsSqsQueue;
    const resourceRiskContext = useGetAwsResourceRiskContext()(queueModel);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.contexts.useGetAwsSqsQueueRiskContext",
            () => ({
                encryption: {
                    text: "The {{translatedQueueTypeName}} is {{encryption}}",
                    type: {
                        kms: "encrypted using KMS key {{key}}",
                        none: "not encrypted",
                        sqs: "encrypted using SSE-SQS"
                    }
                },
                lambdaFunctions: "The {{translatedQueueTypeName}} triggers {{lambdaFunctions}}",
                snsSubscriptions: {
                    snsSubscriptions: [
                        "1 SNS subscription",
                        "{{count | NumberFormatter.humanize}} SNS subscriptions"],
                    text: "The {{translatedQueueTypeName}} has {{snsSubscriptions}}"
                }
            }));
    const translatedQueueTypeName =
        entityTypeNameTranslator(
            queue.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });

    return {
        ...resourceRiskContext,
        encryption:
            new RiskDefinitionContextItem(
                localization.encryption.text({
                    encryption:
                        _.isNil(queue.serverSideEncryptionType)
                            ? localization.encryption.type.none()
                            : (() => {
                                switch (queue.serverSideEncryptionType) {
                                    case Contract.AwsSqsQueueServerSideEncryptionType.Sqs:
                                        return localization.encryption.type.sqs();
                                    case Contract.AwsSqsQueueServerSideEncryptionType.Kms:
                                        return localization.encryption.type.kms({
                                            key:
                                                <Entity
                                                    entityIdOrModel={queueModel.kmsEncryptionKeyIdReferences[0]}
                                                    variant="text"/>
                                        });
                                    default:
                                        throw new UnexpectedError("queue.serverSideEncryptionType", queue.serverSideEncryptionType);
                                }
                            })(),
                    translatedQueueTypeName
                })),
        lambdaFunctions:
            _.isEmpty(queueModel.triggerFunctionConfigurationIds)
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.lambdaFunctions({
                        lambdaFunctions:
                            <InlineEntities
                                entityIdsOrModels={queueModel.triggerFunctionConfigurationIds}
                                entityTypeName={Contract.TypeNames.AwsLambdaFunctionConfiguration}
                                variant="itemCountAndType"/>,
                        translatedQueueTypeName
                    })),
        publicAccessPermissionActions:
            resourceRiskContext.getPublicAccessPermissionActions(
                queue.publicAccessHighSeveritySensitivePermissionActions,
                queue.publicAccessPermissionActions),
        snsSubscriptions:
            _.isEmpty(queueModel.subscriptionTopicIds)
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.snsSubscriptions.text({
                        snsSubscriptions:
                            <InlineEntities
                                entityIdsOrModels={queueModel.subscriptionTopicIds}
                                entityTypeName={Contract.TypeNames.AwsResource}
                                namePluralizer={localization.snsSubscriptions.snsSubscriptions}
                                variant="itemCountAndType"/>,
                        translatedQueueTypeName
                    }))
    };
}