import { Grid2 } from "@mui/material";
import _, { Function0 } from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { BreakpointResizer, EmptyMessage, SlideItems, useLocalization } from "@infrastructure";
import { Contract, CustomerConsoleAppUrlHelper, RiskTypeGroups, TypeHelper, useTheme, WidgetDefinition } from "../../../../../../../..";
import { SummaryDashboardContext, useDashboardContext } from "../../../../../../Dashboard";
import { Tile } from "./components";

export function useGetEntityPublicDataDefinition(): () => WidgetDefinition {
    const { summary } = useDashboardContext<SummaryDashboardContext>();

    const localization =
        useLocalization(
            "common.dashboard.widget.hooks.useDefinition.hooks.useGetEntityPublicDataDefinition",
            () => ({
                empty: "No Public Resources",
                helpText: "Assess and remediate findings related to public resources, categorized by type, and prioritized by risk severity. Click on a number to quickly find a specific resource, or SEE ALL to view a complete list.",
                showMore: "See all",
                title: "Public Resources"
            }));

    return useCallback<Function0<WidgetDefinition>>(
        () => ({
            element: <EntityPublicData emptyText={localization.empty()}/>,
            options: {
                details: {
                    detailsUrl:
                    CustomerConsoleAppUrlHelper.getRisksRelativeUrl(
                        Contract.RisksView.Open,
                        {
                            policyConfigurationTypeNameOrIds:
                                _(summary.entityEntitiesViewTypeNameToPublicDataMap).
                                    flatMap(entityPublicData => entityPublicData.riskPolicyConfigurationTypeNames).
                                    uniq().
                                    value()
                        },
                        RiskTypeGroups.RiskPolicyType),
                    title: localization.showMore()
                },
                helpText: localization.helpText(),
                title: localization.title()
            }
        }),
        [localization, summary.entityEntitiesViewTypeNameToPublicDataMap]);
}

type EntityPublicDataProps = {
    emptyText: string;
};

function EntityPublicData({ emptyText }: EntityPublicDataProps) {
    const { summary } = useDashboardContext<SummaryDashboardContext>();

    const [tilesContainerWidth, setTilesContainerWidth] = useState(0);
    const entityTypeNames =
        useMemo(
            () =>
                _(summary.entityEntitiesViewTypeNameToPublicDataMap).
                    toPairs().
                    orderBy(
                        [
                            ([_entityTypeName, publicData]) =>
                                TypeHelper.getEnumValue(
                                    Contract.TypeNames.Severity,
                                    publicData.severity),
                            ([_entityTypeName, publicData]) => publicData.riskedEntityCount
                        ],
                        [
                            "desc",
                            "desc"
                        ]).
                    map(([entityTypeName]) => entityTypeName).
                    value(),
            [summary, tilesContainerWidth]);

    const theme = useTheme();
    return (
        <SlideItems
            chunkSize={
                tilesContainerWidth >= breakpointX
                    ? 8
                    : 6}
            items={entityTypeNames}>
            {entityTypeNameChunk =>
                <Grid2
                    columns={
                        tilesContainerWidth >= breakpointX
                            ? 4
                            : 3}
                    columnSpacing={
                        tilesContainerWidth >= breakpointX
                            ? 2
                            : 1}
                    container={true}
                    rowSpacing={1}
                    sx={{
                        flex: 1,
                        height: "100%",
                        paddingBottom: theme.spacing(1)
                    }}>
                    <BreakpointResizer
                        breakpointXs={[breakpointX]}
                        onSize={breakpointX => {
                            if (tilesContainerWidth !== breakpointX) {
                                setTilesContainerWidth(breakpointX);
                            }
                        }}/>
                    {_.isEmpty(summary.entityEntitiesViewTypeNameToPublicDataMap) &&
                        <EmptyMessage
                            message={emptyText}
                            verticalCenter={true}/>}
                    {_.map(
                        entityTypeNameChunk,
                        (entityTypeName, entityTypeNameIndex) =>
                            <Grid2
                                className={`tile ${entityTypeName}`}
                                key={entityTypeNameIndex}
                                size={{ xs: 1 }}
                                sx={{ height: "50%" }}>
                                <Tile
                                    entityPublicData={summary.entityEntitiesViewTypeNameToPublicDataMap[entityTypeName]}
                                    entityTypeName={entityTypeName}/>
                            </Grid2>)}
                </Grid2>}
        </SlideItems>);
}

const breakpointX = 950;