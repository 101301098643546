import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function PartialWidthIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                clipRule="evenodd"
                d="M6.30005 6.39961C5.90005 5.99961 5.20005 5.99961 4.80005 6.39961C4.40005 6.79961 4.40005 7.39961 4.80005 7.89961L7.60005 10.6996H1.30005C0.800049 10.6996 0.300049 11.0996 0.300049 11.6996C0.300049 12.2996 0.800049 12.6996 1.30005 12.6996H7.50005L4.60005 15.5996C4.20005 15.9996 4.20005 16.5996 4.60005 16.9996C5.00005 17.3996 5.60005 17.3996 6.00005 16.9996L10.9 12.0996C11.1 11.8996 11.1 11.4996 10.9 11.1996L6.30005 6.39961Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M17.7 6.39961C18.1 5.99961 18.8 5.99961 19.2 6.39961C19.6 6.79961 19.6 7.39961 19.2 7.89961L16.4 10.6996H22.7C23.2 10.6996 23.7 11.0996 23.7 11.6996C23.7 12.2996 23.2 12.6996 22.7 12.6996H16.5L19.4 15.5996C19.8 15.9996 19.8 16.5996 19.4 16.9996C19 17.3996 18.4 17.3996 18 16.9996L13.1 12.0996C12.9 11.8996 12.9 11.4996 13.1 11.1996L17.7 6.39961Z"
                fillRule="evenodd"/>
        </SvgIcon>);
}