import { DataTableColumn, DataTableColumnRenderProps, EnumValuesFilter, useLocalization } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { useAwsCommonNetworkedResourceDefinition, useAwsCommonResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";

export function useAwsElbLoadBalancerDefinition(definitionData: DefinitionData) {
    const commonNetworkedResourceDefinition = useAwsCommonNetworkedResourceDefinition(definitionData);
    const commonResourceDefinition = useAwsCommonResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();
    const loadBalancerModelFiltersPromise = definitionData.entityModelFiltersPromise as Promise<Contract.AwsElbLoadBalancerModelFilters>;

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.aws.useAwsElbLoadBalancerDefinition",
            () => ({
                columns: {
                    dnsName: "DNS Name",
                    type: {
                        title: "Type",
                        [Contract.TypeNames.AwsElbLoadBalancerModelType]: {
                            [Contract.AwsElbLoadBalancerModelType.Application]: "Application",
                            [Contract.AwsElbLoadBalancerModelType.Classic]: "Classic",
                            [Contract.AwsElbLoadBalancerModelType.Network]: "Network"
                        }
                    },
                    webAcl: "WAF Web ACL"
                }
            }));

    return new EntityTableDefinition(
        _.filter(
            [
                commonResourceDefinition.columns.tenantColumn,
                commonResourceDefinition.columns.creationTimeColumn,
                commonResourceDefinition.columns.creatorIdentityCsvColumn,
                commonResourceDefinition.columns.creatorOriginatorEntityCsvColumn,
                <DataTableColumn
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <EnumValuesFilter
                                    enumType={Contract.AwsElbLoadBalancerModelType}
                                    enumTypeTranslator={(type: Contract.AwsElbLoadBalancerModelType) => localization.columns.type[Contract.TypeNames.AwsElbLoadBalancerModelType][type]()}
                                    placeholder={localization.columns.type.title()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsElbLoadBalancerType}
                    itemProperty={(item: Contract.AwsElbLoadBalancerModel) => localization.columns.type[Contract.TypeNames.AwsElbLoadBalancerModelType][item.type]()}
                    key={Contract.EntityModelProperty.AwsElbLoadBalancerType}
                    title={localization.columns.type.title()}/>,
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsElbLoadBalancerModel) => ({
                                [localization.columns.dnsName()]: (item.entity as Contract.AwsElbLoadBalancer).dnsName
                            })
                    }}
                    id="dnsName"
                    key="dnsName"/>,
                commonNetworkedResourceDefinition.columns.accessLevelColumn,
                commonNetworkedResourceDefinition.columns.inboundExternalAccessScopeColumn,
                commonNetworkedResourceDefinition.columns.destinationNetworkScopesColumn(loadBalancerModelFiltersPromise),
                commonNetworkedResourceDefinition.columns.getVpcsColumn(),
                commonNetworkedResourceDefinition.columns.securityGroupsColumn,
                <DataTableColumn
                    exportOptions={
                        entitiesExportOptions<Contract.AwsElbLoadBalancerModel>(
                            Contract.TypeNames.AwsWafWebAcl,
                            item => [(item as Contract.AwsElbApplicationLoadBalancerModel).webAclId],
                            localization.columns.webAcl())}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedEntityFilter
                                    getEntityIdPage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.AwsElbLoadBalancerWebAcl)}
                                    placeholder={localization.columns.webAcl()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsElbLoadBalancerWebAcl}
                    key={Contract.EntityModelProperty.AwsElbLoadBalancerWebAcl}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.AwsElbLoadBalancerModel>) =>
                            <EntitiesCell
                                entityIdsOrModels={(item as Contract.AwsElbApplicationLoadBalancerModel).webAclId}
                                entityTypeName={Contract.TypeNames.AwsWafWebAcl}
                                entityVariant="iconText"/>}
                    selectorOptions={{ default: false }}
                    title={localization.columns.webAcl()}/>,
                commonResourceDefinition.columns.regionColumn,
                commonResourceDefinition.columns.regionLocationColumn,
                commonResourceDefinition.columns.tagsColumn,
                commonResourceDefinition.columns.attributesColumn,
                commonResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
                commonResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
                commonResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
            ]),
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAwsElbLoadBalancerRequest(
                new Contract.EntityControllerGetEntityModelPageAwsElbLoadBalancerRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsElbLoadBalancerInternal]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsElbLoadBalancerType]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsElbLoadBalancerWebAcl])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags])),
                new Contract.EntityControllerGetEntityModelPageEntityRequestNetworkAccess(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.NetworkInboundAccessType]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.NetworkInboundExternalAccessScope]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.NetworkInboundExternalDestinationNetworkScopes])),
                new Contract.EntityControllerGetEntityModelPageAwsResourceRequestNetworked(
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsNetworkedResourceSecurityGroups]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsNetworkedResourceVpcs]))));
}