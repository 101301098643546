import { StringHelper, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import _ from "lodash";
import React, { memo } from "react";
import { Contract, useComplianceTranslator } from "..";

const ComplianceFilterMemo = memo(ComplianceFilter);
export { ComplianceFilterMemo as ComplianceFilter };

type ComplianceFilterProps = {
    complianceIdentifiers: string[];
    emptyValue?: boolean;
    placeholder: string;
};

function ComplianceFilter({ complianceIdentifiers, emptyValue, placeholder }: ComplianceFilterProps) {
    const complianceTranslator = useComplianceTranslator();
    return (
        <ValuesFilter
            emptyValueOptions={{ enabled: emptyValue }}
            placeholder={placeholder}
            sorted={false}>
            {_(complianceIdentifiers).
                uniq().
                orderBy([
                    complianceIdentifier => !_.includes(Contract.BuiltInComplianceSectionType, complianceIdentifier),
                    complianceIdentifier => StringHelper.getSortValue(complianceTranslator(complianceIdentifier).title)
                ]).
                map(
                    complianceIdentifier =>
                        <ValuesFilterItem
                            key={complianceIdentifier}
                            title={complianceTranslator(complianceIdentifier).title}
                            value={complianceIdentifier}/>).
                value()}
        </ValuesFilter>);
}