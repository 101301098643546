import { NoneIcon, Optional } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { NetworkAccessScope } from "../../views/Customer/components/Entities/components";
import { Contract } from "../controllers";

type NetworkAccessScopeCellProps = {
    networkAccessScope: Optional<Contract.NetworkAccessScope>;
};

export function NetworkAccessScopeCell({ networkAccessScope }: NetworkAccessScopeCellProps) {
    return (
        _.isNil(networkAccessScope) ||
        networkAccessScope === Contract.NetworkAccessScope.None
            ? <NoneIcon sx={{ fontSize: "18px" }}/>
            : <NetworkAccessScope accessScope={networkAccessScope}/>);
}