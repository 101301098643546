import { DataTable, DataTableActions, DataTableColumn, DataTableColumnRenderProps, DataTableFetchItemsResult, DataTableSort, DataTableSortDirection, DataTableSortType, DeferredFilter, EmptyMessageText, InlineItems, map, Optional, optionalTableCell, TimeCell, TimeFormatter, TimeRangeFilter, TimeSpanHelper, useChangeEffect, useLocalization, useOperation, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import { Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { useMemo, useRef, useState } from "react";
import { AuditEventController, Contract, ElasticsearchItemPageHelper, EntitiesCell, entityModelStore, InlineTextViewer, ItemSelectionHelper, PagedEntityFilter, Scope, ScopeFilter, scopeNodeModelStore, StorageHelper, Tenant, TenantFilter, TimeRangeHelper, TypeHelper, usePermissionEligibilityUpdateAuditEventChangesTranslator, useTenantNameTranslator } from "../../../../../../common";
import { DetailsCell } from "./components";

export function PermissionEligibilityAuditEvents() {
    const [filtersPromise] =
        useOperation(
            PermissionEligibilityAuditEvents,
            async () => {
                const { filters } = await AuditEventController.getAuditEventFilters(new Contract.AuditEventControllerGetPermissionEligibilityAuditEventFiltersRequest());
                return filters as Contract.PermissionEligibilityAuditEventFilters;
            });
    const dataTableActionsRef = useRef<DataTableActions>();

    const localization =
        useLocalization(
            "views.user.auditEvents.permissionEligibilityAuditEvents",
            () => ({
                actions: {
                    csvExport: {
                        fileNamePrefix: "EligibilitiesAuditLog"
                    }
                },
                columns: {
                    approvalRequired: {
                        false: "No",
                        title: "Requires Approval",
                        true: "Yes"
                    },
                    approverPrincipalIdReferences: "Approvers",
                    awsInlinePolicyDocument: {
                        policy: "View JSON",
                        title: "Inline Policy"
                    },
                    details: {
                        propertyChanges: "Updated: {{propertyChanges}}",
                        title: "Details"
                    },
                    expirationTimeFrame: {
                        text: [
                            "1 hour",
                            "{{count | NumberFormatter.humanize}} hours"
                        ],
                        title: "Maximum Duration"
                    },
                    granteePrincipalIdReferences: "Requesters",
                    groupIdReferences: "Groups",
                    identityReference: "User",
                    levelToApproverPrincipalIdReferencesMap: {
                        "level0": "Approvers",
                        "level1": "Second Level Approvers",
                        "level2": "Third Level Approvers"
                    },
                    name: "Name",
                    permissionRequestScopePaths: {
                        pluralizer: [
                            "1 Scope",
                            "{{count | NumberFormatter.humanize}} Scopes"
                        ],
                        title: "Request Scopes"
                    },
                    permitterIds: "Eligibility",
                    principalTenantId: "Organization",
                    reasonRequired: {
                        false: "No",
                        title: "Justification Required",
                        true: "Yes"
                    },
                    scopeId: "Scope",
                    systemCreationTime: "Time",
                    typeName: {
                        title: "Action",
                        [Contract.TypeNames.PermissionEligibilityAuditEvent]: {
                            [Contract.TypeNames.PermissionEligibilityCreationAuditEvent]: "Create eligibility",
                            [Contract.TypeNames.PermissionEligibilityDeletionAuditEvent]: "Delete eligibility",
                            [Contract.TypeNames.PermissionEligibilityUpdateAuditEvent]: "Update eligibility"
                        }
                    }
                },
                empty: {
                    withFilter: "No Matching Audit Events",
                    withoutFilter: "No Audit Events"
                }
            }));

    const fetchAuditEventModelNextPageSearchCursorRef = useRef<Contract.ElasticsearchIndexSearchCursor>();
    const [auditEventModelCount, setAuditEventModelCount] = useState(0);
    const [maxLevel, setMaxLevel] = useState(1);
    useChangeEffect(
        () => dataTableActionsRef.current!.reload(),
        [maxLevel]);
    const fetchAuditEventModels =
        async (filterMap: Dictionary<any>, sort: Optional<DataTableSort>, skip: number, limit: number) => {
            const { auditEventModelPage } =
                await AuditEventController.getAuditEventModelPage(
                    new Contract.AuditEventControllerGetPermissionEligibilityAuditEventModelPageRequest(
                        limit,
                        skip === 0
                            ? undefined
                            : fetchAuditEventModelNextPageSearchCursorRef.current,
                        new Contract.AuditEventControllerGetPermissionEligibilityAuditEventModelPageRequestFilters(
                            ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[TableColumnId.Approval]),
                            ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[TableColumnId.ApproverPrincipalIdReferences]),
                            ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[TableColumnId.GranteePrincipalIdReferences]),
                            ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[TableColumnId.GroupIdReferences]),
                            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[TableColumnId.IdentityReference]),
                            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[TableColumnId.Name]),
                            ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[TableColumnId.PermitterIds]),
                            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[TableColumnId.PermissionAssignmentRequestScopePaths]),
                            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[TableColumnId.PrincipalTenantId]),
                            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[TableColumnId.ScopeId]),
                            TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(dataTableActionsRef.current?.getFiltersTime(), filterMap[TableColumnId.SystemCreationTime]),
                            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[TableColumnId.TypeName])),
                        new Contract.AuditEventControllerGetPermissionEligibilityAuditEventModelPageRequestSort(
                            _.isNil(sort) || sort.direction === DataTableSortDirection.Descending
                                ? Contract.SortDirection.Descending
                                : Contract.SortDirection.Ascending,
                            map(
                                sort?.columnId ?? TableColumnId.SystemCreationTime,
                                {
                                    [TableColumnId.Name]: () => Contract.AuditEventControllerGetAuditEventModelPageRequestProperty.PermissionEligibilityAuditEventName,
                                    [TableColumnId.SystemCreationTime]: () => Contract.AuditEventControllerGetAuditEventModelPageRequestProperty.SystemCreationTime
                                }))));

            return new DataTableFetchItemsResult(
                { count: auditEventModelPage.count ?? auditEventModelCount },
                auditEventModelPage.items,
                _.isNil(auditEventModelPage.itemNextPageSearchCursor),
                {
                    onAppendData:
                        () => {
                            setMaxLevel(
                                maxLevel =>
                                    _(auditEventModelPage.items).
                                        as<Contract.PermissionEligibilityAuditEventModel>().
                                        map(auditEventModel => _.size(auditEventModel.permissionEligibilityData.levelToApproverPrincipalIdReferencesMap)).
                                        concat(maxLevel).
                                        max()!);

                            if (!_.isNil(auditEventModelPage.count)) {
                                setAuditEventModelCount(auditEventModelPage.count);
                            }
                            fetchAuditEventModelNextPageSearchCursorRef.current = auditEventModelPage.itemNextPageSearchCursor;
                        }
                });
        };
    const scopeNodeModels = scopeNodeModelStore.useGetAll();
    const scopeNodeModelMap =
        useMemo(
            () =>
                _.keyBy(
                    scopeNodeModels,
                    scopeNodeModel => scopeNodeModel.configuration.id),
            [scopeNodeModels]);

    const sortRef = useRef<DataTableSort>();

    async function getExportData(itemIdToExportColumnElementIdToExportDataMap: Dictionary<Dictionary<ColumnExportData>>): Promise<ExportData> {
        const columnExportDatas =
            _(itemIdToExportColumnElementIdToExportDataMap).
                values().
                flatMap(exportColumnElementIdToExportDataMap => _.values(exportColumnElementIdToExportDataMap)).
                value();

        const relatedEntityModels =
            await entityModelStore.get(
                _(columnExportDatas).
                    flatMap(data => data.entityIds!).
                    filter().
                    uniq().
                    value());
        const relatedEntityModelMap =
            _.keyBy(
                relatedEntityModels,
                relatedEntityModel => relatedEntityModel.id);

        return {
            relatedEntityModelMap
        };
    }

    function getPermitterIds(item: Contract.PermissionEligibilityAuditEventModel) {
        const permissionEligibilityData = item.permissionEligibilityData;
        return item.auditEvent.permissionEligibilityData.typeName === Contract.TypeNames.PermissionEligibilityAuditEventGroupMembershipEligibilityData
            ? []
            : map(
                item.auditEvent.permissionEligibilityData.typeName,
                {
                    [Contract.TypeNames.PermissionEligibilityAuditEventAwsSsoPermissionSetAssignmentEligibilityData]: () => (item.permissionEligibilityData as Contract.PermissionEligibilityAuditEventModelAwsSsoPermissionSetAssignmentEligibilityData).permitterIdReferences,
                    [Contract.TypeNames.PermissionEligibilityAuditEventAzureRoleAssignmentEligibilityData]: () => (permissionEligibilityData as Contract.PermissionEligibilityAuditEventModelAzureRoleAssignmentEligibilityData).roleDefinitionIdReferences,
                    [Contract.TypeNames.PermissionEligibilityAuditEventGcpRoleBindingEligibilityData]: () => (permissionEligibilityData as Contract.PermissionEligibilityAuditEventModelGcpRoleBindingEligibilityData).roleIdReferences,
                    [Contract.TypeNames.PermissionEligibilityAuditEventOneLoginDirectoryRoleAssignmentEligibilityData]: () => (permissionEligibilityData as Contract.PermissionEligibilityAuditEventModelOneLoginDirectoryRoleAssignmentEligibilityData).roleIdReferences
                });
    }

    const permissionEligibilityUpdateAuditEventChangesTranslator = usePermissionEligibilityUpdateAuditEventChangesTranslator();
    const tenantNameTranslator = useTenantNameTranslator();
    return (
        <DataTable
            actionsRef={dataTableActionsRef}
            columnOptions={{ resizable: true }}
            emptyMessageOptions={{
                emptyMessageText:
                    new EmptyMessageText(
                        localization.empty.withoutFilter(),
                        localization.empty.withFilter())
            }}
            exportOptions={{
                fileNamePrefix: localization.actions.csvExport.fileNamePrefix(),
                getData: getExportData
            }}
            fetchItems={fetchAuditEventModels}
            filtersOptions={{
                persist: {
                    visibilityStorageItem: StorageHelper.userAuditEventsPermissionEligibilityTableFilters
                }
            }}
            getItemId={(item: Contract.PermissionEligibilityAuditEventModel) => item.auditEvent.id}
            onSortChanged={sort => sortRef.current = sort}>
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.PermissionEligibilityAuditEventModel) => ({
                            [localization.columns.systemCreationTime()]: TimeFormatter.sortableDateTime(item.auditEvent.systemCreationTime)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <DeferredFilter
                                promiseOrGetPromise={filtersPromise}
                                title={localization.columns.systemCreationTime()}>
                                {filters =>
                                    <TimeRangeFilter
                                        emptyValue={filters.systemCreationTimeRange.emptyValue}
                                        placeholder={localization.columns.systemCreationTime()}
                                        timeRange={TimeRangeHelper.getTimeRangeFilterRange(filters.systemCreationTimeRange.timeRange)}/>}
                            </DeferredFilter>
                    }
                }}
                id={TableColumnId.SystemCreationTime}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.PermissionEligibilityAuditEventModel>) =>
                        <TimeCell
                            short={false}
                            time={item.auditEvent.systemCreationTime}/>}
                sortOptions={{ type: DataTableSortType.Date }}
                title={localization.columns.systemCreationTime()}/>
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.PermissionEligibilityAuditEventModel) => ({
                            [localization.columns.identityReference()]: item.auditEvent.identityReference.displayName
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <DeferredFilter
                                promiseOrGetPromise={filtersPromise}
                                title={localization.columns.identityReference()}>
                                {filters =>
                                    <ValuesFilter
                                        emptyValueOptions={{ enabled: filters.identitySearchableReferenceItems.emptyValue }}
                                        placeholder={localization.columns.identityReference()}>
                                        {_.map(
                                            filters.identitySearchableReferenceItems.items,
                                            identitySearchableReference =>
                                                <ValuesFilterItem
                                                    key={identitySearchableReference.id}
                                                    title={identitySearchableReference.displayName}
                                                    value={identitySearchableReference.id}/>)}
                                    </ValuesFilter>}
                            </DeferredFilter>
                    }
                }}
                id={TableColumnId.IdentityReference}
                itemProperty={(item: Contract.PermissionEligibilityAuditEventModel) => item.auditEvent.identityReference.displayName}
                sortOptions={{ enabled: false }}
                title={localization.columns.identityReference()}/>
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.PermissionEligibilityAuditEventModel) => ({
                            [localization.columns.typeName.title()]: localization.columns.typeName[Contract.TypeNames.PermissionEligibilityAuditEvent].translate(item.auditEvent.typeName)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <DeferredFilter
                                promiseOrGetPromise={filtersPromise}
                                title={localization.columns.typeName.title()}>
                                {filters =>
                                    <ValuesFilter
                                        emptyValueOptions={{ enabled: filters.typeNameItems.emptyValue }}
                                        placeholder={localization.columns.typeName.title()}>
                                        {_.map(
                                            filters.typeNameItems.items,
                                            typeName =>
                                                <ValuesFilterItem
                                                    key={typeName}
                                                    title={localization.columns.typeName[Contract.TypeNames.PermissionEligibilityAuditEvent].translate(typeName)}
                                                    value={typeName}/>)}
                                    </ValuesFilter>}
                            </DeferredFilter>
                    }
                }}
                id={TableColumnId.TypeName}
                itemProperty={(item: Contract.PermissionEligibilityAuditEventModel) => localization.columns.typeName[Contract.TypeNames.PermissionEligibilityAuditEvent].translate(item.auditEvent.typeName)}
                sortOptions={{ enabled: false }}
                title={localization.columns.typeName.title()}/>
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.PermissionEligibilityAuditEventModel) => ({
                            [localization.columns.name()]: item.auditEvent.permissionEligibilityData.name
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <DeferredFilter
                                promiseOrGetPromise={filtersPromise}
                                title={localization.columns.name()}>
                                {filters =>
                                    <ValuesFilter
                                        emptyValueOptions={{ enabled: filters.nameItems.emptyValue }}
                                        placeholder={localization.columns.name()}>
                                        {_.map(
                                            filters.nameItems.items,
                                            name =>
                                                <ValuesFilterItem
                                                    key={name}
                                                    title={name}
                                                    value={name}/>)}
                                    </ValuesFilter>}
                            </DeferredFilter>
                    }
                }}
                id={TableColumnId.Name}
                itemProperty={(item: Contract.PermissionEligibilityAuditEventModel) => item.auditEvent.permissionEligibilityData.name}
                title={localization.columns.name()}/>
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.PermissionEligibilityAuditEventModel) => ({
                            [localization.columns.principalTenantId()]: tenantNameTranslator(item.auditEvent.permissionEligibilityData.principalTenantId)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <DeferredFilter
                                promiseOrGetPromise={filtersPromise}
                                title={localization.columns.principalTenantId()}>
                                {filters =>
                                    <TenantFilter
                                        placeholder={localization.columns.principalTenantId()}
                                        tenantIds={filters.principalTenantIdItems.items}
                                        variant="iconText"/>}
                            </DeferredFilter>
                    }
                }}
                id={TableColumnId.PrincipalTenantId}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.PermissionEligibilityAuditEventModel>) =>
                        <Tenant tenantId={item.auditEvent.permissionEligibilityData.principalTenantId}/>}
                sortOptions={{ enabled: false }}
                title={localization.columns.principalTenantId()}/>
            <DataTableColumn
                exportOptions={{
                    getData: (item: Contract.PermissionEligibilityAuditEventModel) => ({ entityIds: item.permissionEligibilityData.granteePrincipalIdReferences }),
                    getItem:
                        (item: Contract.PermissionEligibilityAuditEventModel, exportData: ExportData) => ({
                            [localization.columns.granteePrincipalIdReferences()]:
                                _(item.permissionEligibilityData.granteePrincipalIdReferences).
                                    map(granteePrincipalIdReference => exportData.relatedEntityModelMap[granteePrincipalIdReference].entity.displayName).
                                    join("\n")
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        default: true,
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedPermissionAuditEventFilterEntityItem(
                                        Contract.TypeNames.PermissionEligibilityAuditEvent,
                                        Contract.AuditEventControllerGetAuditEventModelPageRequestProperty.PermissionEligibilityAuditEventGranteePrincipal)}
                                placeholder={localization.columns.granteePrincipalIdReferences()}/>
                    }
                }}
                id={TableColumnId.GranteePrincipalIdReferences}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.PermissionEligibilityAuditEventModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.permissionEligibilityData.granteePrincipalIdReferences}
                            entityLinkOptions={{ disabled: true }}
                            entityTypeName={Contract.TypeNames.IPermissionManagementPrincipal}
                            entityVariant="iconText"/>}
                sortOptions={{ enabled: false }}
                title={localization.columns.granteePrincipalIdReferences()}/>
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.PermissionEligibilityAuditEventModel) => ({
                            [localization.columns.scopeId()]: scopeNodeModelMap[item.auditEvent.scopeId].configuration.name
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <DeferredFilter
                                promiseOrGetPromise={filtersPromise}
                                title={localization.columns.scopeId()}>
                                {filters =>
                                    <ScopeFilter
                                        placeholder={localization.columns.scopeId()}
                                        scopeIds={filters.scopeIdItems.items}/>}
                            </DeferredFilter>
                    }
                }}
                id={TableColumnId.ScopeId}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.PermissionEligibilityAuditEventModel>) =>
                        <Typography noWrap={true}>
                            <Scope scopeId={item.auditEvent.scopeId}/>
                        </Typography>}
                sortOptions={{ enabled: false }}
                title={localization.columns.scopeId()}/>
            <DataTableColumn
                exportOptions={{
                    getData:
                        (item: Contract.PermissionEligibilityAuditEventModel) => ({
                            entityIds:
                                item.auditEvent.permissionEligibilityData.typeName === Contract.TypeNames.PermissionEligibilityAuditEventGroupMembershipEligibilityData
                                    ? (item.permissionEligibilityData as Contract.PermissionEligibilityAuditEventModelGroupMembershipEligibilityData).groupIdReferences
                                    : []
                        }),
                    getItem:
                        (item: Contract.PermissionEligibilityAuditEventModel, exportData: ExportData) => ({
                            [localization.columns.groupIdReferences()]:
                                item.auditEvent.permissionEligibilityData.typeName === Contract.TypeNames.PermissionEligibilityAuditEventGroupMembershipEligibilityData
                                    ? _((item.permissionEligibilityData as Contract.PermissionEligibilityAuditEventModelGroupMembershipEligibilityData).groupIdReferences).
                                        map(groupIdReference => exportData.relatedEntityModelMap[groupIdReference].entity.displayName).
                                        join("\n")
                                    : ""
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        default: true,
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedPermissionAuditEventFilterEntityItem(
                                        Contract.TypeNames.PermissionEligibilityAuditEvent,
                                        Contract.AuditEventControllerGetAuditEventModelPageRequestProperty.PermissionEligibilityAuditEventGroupMembershipEligibilityGroups)}
                                placeholder={localization.columns.groupIdReferences()}/>
                    }
                }}
                id={TableColumnId.GroupIdReferences}
                render={
                    optionalTableCell<Contract.PermissionEligibilityAuditEventModel>(
                        item =>
                            item.auditEvent.permissionEligibilityData.typeName === Contract.TypeNames.PermissionEligibilityAuditEventGroupMembershipEligibilityData
                                ? <EntitiesCell
                                    entityIdsOrModels={(item.permissionEligibilityData as Contract.PermissionEligibilityAuditEventModelGroupMembershipEligibilityData).groupIdReferences}
                                    entityLinkOptions={{ disabled: true }}
                                    entityTypeName={Contract.TypeNames.IDirectoryGroup}
                                    entityVariant="iconText"/>
                                : undefined)}
                sortOptions={{ enabled: false }}
                title={localization.columns.groupIdReferences()}/>
            <DataTableColumn
                exportOptions={{
                    getData: (item: Contract.PermissionEligibilityAuditEventModel) => ({ entityIds: getPermitterIds(item) }),
                    getItem:
                        (item: Contract.PermissionEligibilityAuditEventModel, exportData: ExportData) => ({
                            [localization.columns.permitterIds()]:
                                _(getPermitterIds(item)).
                                    map(permitterId => exportData.relatedEntityModelMap[permitterId].entity.displayName).
                                    join("\n")
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedPermissionAuditEventFilterEntityItem(
                                        Contract.TypeNames.PermissionEligibilityAuditEvent,
                                        Contract.AuditEventControllerGetAuditEventModelPageRequestProperty.PermissionEligibilityAuditEventPermissionAssignmentEligibilityPermitters)}
                                placeholder={localization.columns.permitterIds()}/>
                    }
                }}
                id={TableColumnId.PermitterIds}
                render={
                    optionalTableCell<Contract.PermissionEligibilityAuditEventModel>(
                        item => {
                            const permitterIds = getPermitterIds(item);
                            return _.isEmpty(permitterIds)
                                ? undefined
                                : <EntitiesCell
                                    entityIdsOrModels={permitterIds}
                                    entityLinkOptions={{ disabled: true }}
                                    entityTypeName={Contract.TypeNames.Entity}
                                    entityVariant="iconText"/>;
                        })}
                sortOptions={{ enabled: false }}
                title={localization.columns.permitterIds()}/>
            <DataTableColumn
                id={TableColumnId.AwsInlinePolicyDocument}
                render={
                    optionalTableCell<Contract.PermissionEligibilityAuditEventModel>(
                        item => {
                            const inlinePolicyDocument =
                                item.auditEvent.permissionEligibilityData.typeName === Contract.TypeNames.PermissionEligibilityAuditEventAwsSsoPermissionSetAssignmentEligibilityData
                                    ? (item.auditEvent.permissionEligibilityData as Contract.PermissionEligibilityAuditEventAwsSsoPermissionSetAssignmentEligibilityData).inlinePolicyDocument
                                    : undefined;
                            return _.isNil(inlinePolicyDocument)
                                ? undefined
                                : <InlineTextViewer
                                    dialogTitle={localization.columns.awsInlinePolicyDocument.title()}
                                    text={inlinePolicyDocument.raw}
                                    title={localization.columns.awsInlinePolicyDocument.policy()}/>;
                        })}
                sortOptions={{ enabled: false }}
                title={localization.columns.awsInlinePolicyDocument.title()}/>
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.PermissionEligibilityAuditEventModel) => ({
                            [localization.columns.permissionRequestScopePaths.title()]:
                                TypeHelper.extendOrImplement(item.auditEvent.permissionEligibilityData.typeName, Contract.TypeNames.PermissionEligibilityAuditEventPermissionAssignmentEligibilityData)
                                    ? localization.columns.permissionRequestScopePaths.pluralizer((item.auditEvent.permissionEligibilityData as Contract.PermissionEligibilityAuditEventPermissionAssignmentEligibilityData).permissionRequestScopePaths.length)
                                    : ""
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <DeferredFilter
                                promiseOrGetPromise={filtersPromise}
                                title={localization.columns.scopeId()}>
                                {filters =>
                                    <ValuesFilter
                                        emptyValueOptions={{ enabled: filters.permissionRequestScopePathItems.emptyValue }}
                                        placeholder={localization.columns.permissionRequestScopePaths.title()}>
                                        {_.map(
                                            filters.permissionRequestScopePathItems.items,
                                            permissionRequestScopePath =>
                                                <ValuesFilterItem
                                                    key={permissionRequestScopePath}
                                                    title={permissionRequestScopePath}
                                                    value={permissionRequestScopePath}/>)}
                                    </ValuesFilter>}
                            </DeferredFilter>
                    }
                }}
                id={TableColumnId.PermissionAssignmentRequestScopePaths}
                render={
                    optionalTableCell<Contract.PermissionEligibilityAuditEventModel>(
                        item =>
                            TypeHelper.extendOrImplement(item.auditEvent.permissionEligibilityData.typeName, Contract.TypeNames.PermissionEligibilityAuditEventPermissionAssignmentEligibilityData)
                                ? <InlineItems
                                    items={(item.auditEvent.permissionEligibilityData as Contract.PermissionEligibilityAuditEventPermissionAssignmentEligibilityData).permissionRequestScopePaths}
                                    namePluralizer={localization.columns.permissionRequestScopePaths.pluralizer}
                                    variant="itemCountAndType"/>
                                : undefined)}
                sortOptions={{ enabled: false }}
                title={localization.columns.permissionRequestScopePaths.title()}/>
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.PermissionEligibilityAuditEventModel) => ({
                            [localization.columns.expirationTimeFrame.title()]: localization.columns.expirationTimeFrame.text(TimeSpanHelper.getHours(item.auditEvent.permissionEligibilityData.expirationTimeFrame))
                        })
                }}
                id={TableColumnId.ExpirationTimeFrame}
                itemProperty={(item: Contract.PermissionEligibilityAuditEventModel) => localization.columns.expirationTimeFrame.text(TimeSpanHelper.getHours(item.auditEvent.permissionEligibilityData.expirationTimeFrame))}
                sortOptions={{ enabled: false }}
                title={localization.columns.expirationTimeFrame.title()}/>
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.PermissionEligibilityAuditEventModel) => ({
                            [localization.columns.approvalRequired.title()]:
                                _.isEmpty(item.permissionEligibilityData.levelToApproverPrincipalIdReferencesMap)
                                    ? localization.columns.approvalRequired.false()
                                    : localization.columns.approvalRequired.true()
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        default: true,
                        element:
                            <ValuesFilter placeholder={localization.columns.approvalRequired.title()}>
                                <ValuesFilterItem
                                    key="false"
                                    title={localization.columns.approvalRequired.false()}
                                    value={false}/>
                                <ValuesFilterItem
                                    key="true"
                                    title={localization.columns.approvalRequired.true()}
                                    value={true}/>
                            </ValuesFilter>
                    }
                }}
                id={TableColumnId.Approval}
                itemProperty={
                    (item: Contract.PermissionEligibilityAuditEventModel) =>
                        _.isEmpty(item.permissionEligibilityData.levelToApproverPrincipalIdReferencesMap)
                            ? localization.columns.approvalRequired.false()
                            : localization.columns.approvalRequired.true()}
                sortOptions={{ enabled: false }}
                title={localization.columns.approvalRequired.title()}/>
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedPermissionAuditEventFilterEntityItem(
                                        Contract.TypeNames.PermissionEligibilityAuditEvent,
                                        Contract.AuditEventControllerGetAuditEventModelPageRequestProperty.PermissionEligibilityAuditEventApproverPrincipals)}
                                placeholder={localization.columns.approverPrincipalIdReferences()}/>
                    }
                }}
                id={TableColumnId.ApproverPrincipalIdReferences}
                title={localization.columns.approverPrincipalIdReferences()}/>
            {_(maxLevel).
                range().
                map(
                    level =>
                        <DataTableColumn
                            exportOptions={{
                                getData: (item: Contract.PermissionEligibilityAuditEventModel) => ({ entityIds: item.permissionEligibilityData.levelToApproverPrincipalIdReferencesMap[level] }),
                                getItem:
                                    (item: Contract.PermissionEligibilityAuditEventModel, exportData: ExportData) => ({
                                        [localization.columns.levelToApproverPrincipalIdReferencesMap.translate(`level${level}`)]:
                                            _(item.permissionEligibilityData.levelToApproverPrincipalIdReferencesMap[level]).
                                                map(approverPrincipalIdReference => exportData.relatedEntityModelMap[approverPrincipalIdReference].entity.displayName).
                                                join("\n")
                                    })
                            }}
                            id={`${TableColumnId.LevelToApproverPrincipalIdReferencesMap}-${level}`}
                            key={`${TableColumnId.LevelToApproverPrincipalIdReferencesMap}-${level}`}
                            render={
                                ({ item }: DataTableColumnRenderProps<Contract.PermissionEligibilityAuditEventModel>) =>
                                    <EntitiesCell
                                        entityIdsOrModels={item.permissionEligibilityData.levelToApproverPrincipalIdReferencesMap[level] ?? []}
                                        entityLinkOptions={{ disabled: true }}
                                        entityTypeName={Contract.TypeNames.IPermissionManagementPrincipal}
                                        entityVariant="iconText"/>}
                            sortOptions={{ enabled: false }}
                            title={localization.columns.levelToApproverPrincipalIdReferencesMap.translate(`level${level}`)}/>).
                value()}
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.PermissionEligibilityAuditEventModel) => ({
                            [localization.columns.reasonRequired.title()]:
                                item.auditEvent.permissionEligibilityData.reasonRequired
                                    ? localization.columns.reasonRequired.true()
                                    : localization.columns.reasonRequired.false()
                        })
                }}
                id={TableColumnId.ReasonRequired}
                itemProperty={
                    (item: Contract.PermissionEligibilityAuditEventModel) =>
                        item.auditEvent.permissionEligibilityData.reasonRequired
                            ? localization.columns.approvalRequired.true()
                            : localization.columns.approvalRequired.false()}
                sortOptions={{ enabled: false }}
                title={localization.columns.reasonRequired.title()}/>
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AuditEventModel) => {
                            const propertyChanges =
                                item.auditEvent.typeName === Contract.TypeNames.PermissionEligibilityUpdateAuditEvent
                                    ? permissionEligibilityUpdateAuditEventChangesTranslator(item.auditEvent as Contract.PermissionEligibilityUpdateAuditEvent)
                                    : undefined;
                            return {
                                [localization.columns.details.title()]:
                                    _.isNil(propertyChanges)
                                        ? ""
                                        : localization.columns.details.propertyChanges({ propertyChanges })
                            };
                        }
                }}
                id={TableColumnId.Details}
                render={DetailsCell}
                sortOptions={{ enabled: false }}
                title={localization.columns.details.title()}/>
        </DataTable>);
}

type ColumnExportData = {
    entityIds?: string[];
};

enum TableColumnId {
    Approval = "approval",
    ApproverPrincipalIdReferences = "approverPrincipalIdReferences",
    AwsInlinePolicyDocument = "awsInlinePolicyDocument",
    Details = "details",
    ExpirationTimeFrame = "expirationTimeFrame",
    GranteePrincipalIdReferences = "granteePrincipalIdReferences",
    GroupIdReferences = "groupIdReferences",
    IdentityReference = "identityReference",
    LevelToApproverPrincipalIdReferencesMap = "levelToApproverPrincipalIdReferencesMap",
    Name = "name",
    PermissionAssignmentRequestScopePaths = "permissionAssignmentRequestScopePaths",
    PermitterIds = "permitterIds",
    PrincipalTenantId = "principalTenantId",
    ReasonRequired = "reasonRequired",
    ScopeId = "scopeId",
    SystemCreationTime = "systemCreationTime",
    TypeName = "typeName"
}

type ExportData = {
    relatedEntityModelMap: Dictionary<Contract.EntityModel>;
};