import { ActionMenuItem, DataTableColumnRenderProps, DeleteIcon, EditIcon, Menu, Message, useLocalization } from "@infrastructure";
import { CircularProgress, Stack } from "@mui/material";
import React, { Fragment, useState } from "react";
import { useSetServiceNowContext } from "../../..";
import { ConfigurationController, Contract, scopeSystemEntityModelStore, useScopeNavigationViewContext, useTheme } from "../../../../../../../../../../../../../../../common";

export function ActionsCell({ item }: DataTableColumnRenderProps<Contract.ScopeSystemEntityModel>) {
    const { scopeNodeModel } = useScopeNavigationViewContext();
    const setServiceNowContext = useSetServiceNowContext();

    const [deleteInstanceExecuting, setDeleteInstanceExecuting] = useState(false);
    const [deleteInstanceError, setInstanceError] = useState(false);

    async function deleteInstance() {
        setDeleteInstanceExecuting(true);
        setInstanceError(false);

        try {
            await ConfigurationController.deleteServiceNowInstance(new Contract.ConfigurationControllerDeleteServiceNowInstanceRequest(item.configuration.id));
            await scopeSystemEntityModelStore.notify();
        } catch {
            setInstanceError(true);
        }

        setDeleteInstanceExecuting(false);
    }

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useTicketingItems.serviceNow.table.actionsCell",
            () => ({
                actions: {
                    delete: {
                        error: "Failed to delete",
                        prompt: "Are you sure you want to delete the instance {{instanceName}}?",
                        title: "Delete"
                    },
                    edit: "Edit"
                }
            }));
    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            direction="row"
            justifyContent="flex-end"
            spacing={1.5}>
            {deleteInstanceExecuting && (
                <CircularProgress
                    size={theme.spacing(2)}
                    variant="indeterminate"/>)}
            {deleteInstanceError && (
                <Message
                    level="error"
                    title={localization.actions.delete.error()}
                    variant="minimal"/>)}
            {scopeNodeModel.configuration.id === item.configuration.scopeId &&
                <Fragment>
                    <Menu
                        itemsOrGetItems={[
                            new ActionMenuItem(
                                () =>
                                    setServiceNowContext(
                                        serviceNowContext => ({
                                            ...serviceNowContext,
                                            addOrEditOpen: item
                                        })),
                                localization.actions.edit(),
                                {
                                    disabled: deleteInstanceExecuting,
                                    icon: <EditIcon/>
                                }),
                            new ActionMenuItem(
                                () => deleteInstance(),
                                localization.actions.delete.title(),
                                {
                                    confirmOptions: {
                                        message: localization.actions.delete.prompt({ instanceName: (item.configuration as Contract.ServiceNowInstanceConfiguration).name })
                                    },
                                    disabled: deleteInstanceExecuting,
                                    icon: <DeleteIcon/>
                                })
                        ]}/>
                </Fragment>}
        </Stack>);
}