import { FormLayout, makeContextProvider, Optional, OrderedWizard, OrderedWizardItem, useExecuteOperation, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, scopeSystemEntityModelStore, TenantController, tenantModelStore } from "../../../../../../../../../../common";
import { TenantModelHelper } from "../../../../../../utilities";
import { AddOrEditComponentProps } from "../../../../useAddOrEditDefinition";
import { FinishItem, GciTenantItem, OrganizationRolesItem, PermissionsItem, SinkPubSubSubscriptionItem, TenantItem, TenantRolesItem } from "./components";

class AddOrEditContext {
    public organizationRawShortId?: string;
    public organizationSinkPubSubSubscriptionEnabled: boolean;
    public permissionTypes?: Contract.CloudProviderTenantPermissionType[];
    public rawShortNameId?: string;
    public sinkPubSubSubscriptionEnabled: boolean;
    public sinkPubSubSubscriptionRawId?: string;
    public tenantTerraformStateBucketEnabled: boolean;
    public updatedTenantModel?: Contract.GcpTenantModel;

    constructor(
        public gciTenantModel: Optional<Contract.GciTenantModel>,
        organizationModel: Optional<Contract.ScopeSystemEntityModel>,
        public parentFolderId: string,
        public tenantOnboardingInfo: Contract.TenantControllerGetGcpTenantOnboardingInfoResponse,
        public tenantModel?: Optional<Contract.GcpTenantModel>) {
        this.organizationRawShortId = gciTenantModel?.state.gcp?.organizationRawShortId;
        this.organizationSinkPubSubSubscriptionEnabled = !_.isNil((organizationModel?.configuration as Optional<Contract.GcpOrganizationConfiguration>)?.sink);
        this.permissionTypes = TenantModelHelper.getTenantModelPermissions(tenantModel).configurationPermissionTypes;
        this.rawShortNameId = tenantModel?.configuration.rawShortNameId;

        if (this.organizationSinkPubSubSubscriptionEnabled) {
            this.sinkPubSubSubscriptionEnabled = true;
            this.sinkPubSubSubscriptionRawId = undefined;
        } else {
            this.sinkPubSubSubscriptionEnabled =
                _.isNil(tenantModel) ||
                !_.isNil(tenantModel.configuration.sink?.pubSubSubscriptionRawId);
            this.sinkPubSubSubscriptionRawId = tenantModel?.configuration.sink?.pubSubSubscriptionRawId;
        }

        this.tenantTerraformStateBucketEnabled = !_.isEmpty(tenantModel?.state.codeAnalysis.terraformStateBucketNames);
    }
}

export const [useAddOrEditContext, useSetAddOrEditContext, useAddOrEditContextProvider] = makeContextProvider<AddOrEditContext>();

export function AddOrEdit({ onClose, parentFolderId, scopeNodeModel, startItemIndex }: AddOrEditComponentProps) {
    const tenantModel = tenantModelStore.useGet(scopeNodeModel?.configuration.id) as Optional<Contract.GcpTenantModel>;
    const gciTenantModel = tenantModelStore.useGet(tenantModel?.configuration.gciTenantId) as Optional<Contract.GciTenantModel>;
    const organizationModels = scopeSystemEntityModelStore.useGetGcpOrganization();

    const [tenantOnboardingInfo] =
        useExecuteOperation(
            AddOrEdit,
            TenantController.getGcpTenantOnboardingInfo);

    const [, , ContextProvider] =
        useAddOrEditContextProvider(
            () =>
                new AddOrEditContext(
                    gciTenantModel,
                    _.find(
                        organizationModels,
                        organizationModel => organizationModel.configuration.id === gciTenantModel?.configuration.gcpOrganizationId),
                    parentFolderId,
                    tenantOnboardingInfo,
                    tenantModel));

    const localization =
        useLocalization(
            "views.customer.scopes.hooks.useDefinition.hooks.useGcpDefinition.addOrEdit",
            () => ({
                steps: {
                    gciTenantItem: "Select Google Workspace",
                    organizationRolesItem: "Assign Organization Roles",
                    permissionsItem: "Permissions",
                    sinkPubSubSubscriptionItem: "Audit Logs",
                    tenantItem: "Provide Project ID",
                    tenantRolesItem: "Assign Roles"
                },
                title: {
                    add: "Add Project",
                    edit: "Edit Project - {{tenantName}}"
                }
            }));

    return (
        <ContextProvider>
            <FormLayout
                disableContentPadding={true}
                titleOptions={{
                    text:
                        _.isNil(tenantModel)
                            ? localization.title.add()
                            : localization.title.edit({ tenantName: tenantModel.configuration.name })
                }}>
                <OrderedWizard
                    finishItemElement={<FinishItem/>}
                    startItemIndex={
                        startItemIndex ??
                        (_.isNil(tenantModel)
                            ? undefined
                            : 2)}
                    onClose={onClose}>
                    <OrderedWizardItem
                        deferredLoading={true}
                        title={localization.steps.gciTenantItem()}>
                        <GciTenantItem/>
                    </OrderedWizardItem>
                    <OrderedWizardItem
                        deferredLoading={true}
                        title={localization.steps.tenantItem()}>
                        <TenantItem/>
                    </OrderedWizardItem>
                    <OrderedWizardItem title={localization.steps.permissionsItem()}>
                        <PermissionsItem/>
                    </OrderedWizardItem>
                    <OrderedWizardItem
                        deferredLoading={true}
                        title={localization.steps.organizationRolesItem()}>
                        <OrganizationRolesItem/>
                    </OrderedWizardItem>
                    <OrderedWizardItem
                        deferredLoading={true}
                        title={localization.steps.tenantRolesItem()}>
                        <TenantRolesItem/>
                    </OrderedWizardItem>
                    <OrderedWizardItem title={localization.steps.sinkPubSubSubscriptionItem()}>
                        <SinkPubSubSubscriptionItem/>
                    </OrderedWizardItem>
                </OrderedWizard>
            </FormLayout>
        </ContextProvider>);
}