import { Link, useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import React from "react";

export function EventCollectorSetupItem() {
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useObservabilityItems.splunk.addOrEdit.eventCollectorSetupItem",
            () => ({
                title: "Follow these instructions to set up HTTP Event Collector in Splunk:"
            }));

    return (
        <Stack spacing={4}>
            <Typography variant="h5">
                {localization.title()}
            </Typography>
            <Typography>
                <Link
                    urlOrGetUrl="https://docs.splunk.com/Documentation/Splunk/8.1.0/Data/UsetheHTTPEventCollector"
                    variant="external">
                </Link>
            </Typography>
        </Stack>);
}