﻿import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function JapanIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <g clipPath="url(#clip0_60203_5260)">
                <rect
                    fill="white"
                    height="15.9638"
                    rx="2"
                    width="24"
                    y="4"/>
                <path
                    d="M0 3.99829H24V19.9621H0V3.99829Z"
                    fill="white"/>
                <path
                    clipRule="evenodd"
                    d="M22 4.99829H2C1.44771 4.99829 1 5.44601 1 5.99829V17.9621C1 18.5144 1.44772 18.9621 2 18.9621H22C22.5523 18.9621 23 18.5144 23 17.9621V5.99829C23 5.44601 22.5523 4.99829 22 4.99829ZM2 3.99829C0.895431 3.99829 0 4.89372 0 5.99829V17.9621C0 19.0667 0.89543 19.9621 2 19.9621H22C23.1046 19.9621 24 19.0667 24 17.9621V5.99829C24 4.89372 23.1046 3.99829 22 3.99829H2Z"
                    fill="#BAC5D3"
                    fillRule="evenodd"/>
                <path
                    d="M11.9552 16.9105C14.692 16.9105 16.9105 14.692 16.9105 11.9552C16.9105 9.21854 14.692 7 11.9552 7C9.21854 7 7 9.21854 7 11.9552C7 14.692 9.21854 16.9105 11.9552 16.9105Z"
                    fill="#BC002D"/>
            </g>
            <defs>
                <clipPath id="clip0_60203_5260">
                    <rect
                        fill="white"
                        height="15.9638"
                        rx="2"
                        width="24"
                        y="4"/>
                </clipPath>
            </defs>
        </SvgIcon>);
}