﻿import { useMemo } from "react";
import { Contract } from "../../../../../../../../../../../../common";
import { useGetEntitiesRiskContext } from "../../../useGetEntitiesRiskContext";

export function useGetOciComputeInstancePoolsRiskContext() {
    return useMemo(
        () => useOciComputeInstancePoolsRiskContext,
        []);
}

function useOciComputeInstancePoolsRiskContext(instancePoolIdsOrModels: string[] | Contract.OciComputeInstancePoolModel[]) {
    return useGetEntitiesRiskContext()(
        instancePoolIdsOrModels,
        Contract.TypeNames.OciComputeInstancePool);
}