import { map, Steps, useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract, StatusCell as BaseStatusCell, useTenantNameTranslator } from "../../../../../../../../../common";
import { useGcpTenantModelStatusTranslator } from "../../../../../../../../../tenants";

type StatusCellProps = {
    serviceAccountTenantRawShortNameIdOrModel: string | Contract.GcpTenantModel;
    tenantModel: Contract.GcpTenantModel;
};

export function StatusCell({ serviceAccountTenantRawShortNameIdOrModel, tenantModel }: StatusCellProps) {
    const gcpTenantStatusTranslator = useGcpTenantModelStatusTranslator();
    const tenantNameTranslator = useTenantNameTranslator();
    const localization =
        useLocalization(
            "views.customer.scopes.hooks.useDefinition.hooks.useGcpDefinition.statusCell",
            () => ({
                [Contract.TypeNames.GcpTenantModelIssue]: {
                    [Contract.GcpTenantModelIssue.TerraformStateBucketAccessDenied]: "Missing permissions to read Terraform state files from the following storage buckets - {{terraformStateBucketNames}}"
                },
                serviceAccountMissingOrganizationRoleNames: "The following roles are missing at the organization scope:",
                serviceAccountMissingPermissionActions: "The following permissions are missing:",
                serviceAccountMissingRoleNames: "The following roles are missing:",
                serviceAccountTenantBillingNotEnabled: "Project **{{serviceAccountTenantName}}** doesn't have a billing account connected, which causes limitations on queries to this project. Until a billing account is connected, some features will be disabled or otherwise affected. These include, for example, findings related to inactive service accounts, Kubernetes visibility and security, and network evaluation",
                serviceAccountTenantServiceApiHostNamesMismatch: "Project **{{serviceAccountTenantName}}** has some APIs disabled, which causes limitations on queries to this project"
            }));

    function getServiceAccountMissingPermissions() {
        const serviceAccountMissingPermissionActions =
            _.uniq(
                _.concat(
                    tenantModel.state.monitoring.serviceAccountMissingDataAnalysisPermissionActions,
                    tenantModel.state.monitoring.serviceAccountMissingWorkloadAnalysisPermissionActions)).
                sort();

        return (
            <Stack spacing={2}>
                {_.some(tenantModel.state.monitoring.serviceAccountMissingOrganizationRoleNames) && (
                    <Stack>
                        <Typography variant="h5">
                            {localization.serviceAccountMissingOrganizationRoleNames()}
                        </Typography>
                        <Steps>
                            {_(tenantModel.state.monitoring.serviceAccountMissingOrganizationRoleNames).
                                map(
                                    serviceAccountMissingOrganizationRoleName =>
                                        <Typography key={serviceAccountMissingOrganizationRoleName}>
                                            {serviceAccountMissingOrganizationRoleName}
                                        </Typography>).
                                value()}
                        </Steps>
                    </Stack>)}
                {_.some(serviceAccountMissingPermissionActions) && (
                    <Stack>
                        <Typography variant="h5">
                            {localization.serviceAccountMissingPermissionActions()}
                        </Typography>
                        <Steps>
                            {_(serviceAccountMissingPermissionActions).
                                map(
                                    serviceAccountMissingPermissionAction =>
                                        <Typography key={serviceAccountMissingPermissionAction}>
                                            {serviceAccountMissingPermissionAction}
                                        </Typography>).
                                value()}
                        </Steps>
                    </Stack>)}
                {_.some(tenantModel.state.monitoring.serviceAccountMissingRoleNames) && (
                    <Stack>
                        <Typography variant="h5">
                            {localization.serviceAccountMissingRoleNames()}
                        </Typography>
                        <Steps>
                            {_.map(
                                tenantModel.state.monitoring.serviceAccountMissingRoleNames,
                                serviceAccountMissingRoleName =>
                                    <Typography key={serviceAccountMissingRoleName}>
                                        {serviceAccountMissingRoleName}
                                    </Typography>)}
                        </Steps>
                    </Stack>)}
            </Stack>);
    }

    return (
        <BaseStatusCell
            statusSeverity={tenantModel.statusSeverity}
            title={gcpTenantStatusTranslator(tenantModel.status)}
            tooltip={
                map(
                    tenantModel.status,
                    {
                        [Contract.GcpTenantModelStatus.AccessDenied]: () =>
                            <Typography sx={{ whiteSpace: "pre-wrap" }}>
                                {tenantModel.state.monitoring.analysisAccessDeniedErrorMessage}
                            </Typography>,
                        [Contract.GcpTenantModelStatus.ServiceAccountMandatoryPermissionsMismatch]: () => getServiceAccountMissingPermissions(),
                        [Contract.GcpTenantModelStatus.ServiceAccountOptionalRoleMismatch]: () => getServiceAccountMissingPermissions(),
                        [Contract.GcpTenantModelStatus.ServiceAccountTenantBillingNotEnabled]: () =>
                            <Typography>
                                {localization.serviceAccountTenantBillingNotEnabled({
                                    serviceAccountTenantName:
                                        _.isString(serviceAccountTenantRawShortNameIdOrModel)
                                            ? serviceAccountTenantRawShortNameIdOrModel
                                            : tenantNameTranslator(serviceAccountTenantRawShortNameIdOrModel.configuration.id, { includeRawId: true })
                                })}
                            </Typography>,
                        [Contract.GcpTenantModelStatus.ServiceAccountTenantServiceApiHostNamesMismatch]: () =>
                            <Stack>
                                <Typography variant="h5">
                                    {localization.serviceAccountTenantServiceApiHostNamesMismatch({
                                        serviceAccountTenantName:
                                            _.isString(serviceAccountTenantRawShortNameIdOrModel)
                                                ? serviceAccountTenantRawShortNameIdOrModel
                                                : tenantNameTranslator(serviceAccountTenantRawShortNameIdOrModel.configuration.id, { includeRawId: true })
                                    })}
                                </Typography>
                                <Steps>
                                    {_(tenantModel.state.monitoring.serviceAccountTenantMissingServiceApiHostNames).
                                        map(
                                            serviceAccountTenantMissingServiceApiHostName =>
                                                <Typography key={serviceAccountTenantMissingServiceApiHostName}>
                                                    {serviceAccountTenantMissingServiceApiHostName}
                                                </Typography>).
                                        value()}
                                </Steps>
                            </Stack>,
                        [Contract.GcpTenantModelStatus.ServiceAccountUnauthorized]: () =>
                            <Typography sx={{ whiteSpace: "pre-wrap" }}>
                                {tenantModel.state.monitoring.serviceAccountUnauthorizedErrorMessage}
                            </Typography>,
                        [Contract.GcpTenantModelStatus.ServiceAccountWorkloadAnalysisPermissionsMismatch]: () => getServiceAccountMissingPermissions(),
                        [Contract.GcpTenantModelStatus.ValidWithIssues]: () =>
                            <Steps variant="bullets">
                                {_.map(
                                    tenantModel.issues,
                                    issue =>
                                        localization[Contract.TypeNames.GcpTenantModelIssue][issue]({
                                            terraformStateBucketNames: _.join(tenantModel.state.monitoring.accessDeniedTerraformStateBucketNames, ", ")
                                        }))}
                            </Steps>
                    },
                    () => undefined)}/>);
}