import { TimeSpanHelper, useLocalization } from "@infrastructure";
import _ from "lodash";
import { AuditEventDefinition, AuditEventDefinitionContext, AuditEventDefinitionDetailItem } from "..";
import { Contract, useSeverityTranslator } from "../../../../../../../../../common";

export function useWorkloadAnalysisUpdateAuditEventDefinition(context: AuditEventDefinitionContext) {
    const severityTranslator = useSeverityTranslator();
    const localization =
        useLocalization(
            "views.customer.auditEvents.profile.hooks.useDefinition.hooks.useWorkloadAnalysisUpdateAuditEventDefinition",
            () => ({
                containerImageRepositoryEnabled: {
                    false: "Disabled",
                    inherit: "Inherit",
                    title: "Set container image repository scanning status",
                    true: "Enabled"
                },
                enabled: {
                    false: "Disabled",
                    inherit: "Inherit",
                    title: "Set workload protection status",
                    true: "Enabled"
                },
                excludeUnresolvableVulnerabilities: {
                    false: "Disabled",
                    inherit: "Inherit",
                    title: "Set if findings will be created for vulnerabilities with fixes only",
                    true: "Enabled"
                },
                exclusionTags: {
                    added: "Added",
                    removed: "Deleted",
                    tags: [
                        "1 exclusion tag",
                        "{{count | NumberFormatter.humanize}} exclusion tags"
                    ]
                },
                fileScanEnabled: {
                    false: "Disabled",
                    inherit: "Inherit",
                    title: "Set file scan status",
                    true: "Enabled"
                },
                operatingSystemEndOfLifeTimeFrame: {
                    days: [
                        "{{count | NumberFormatter.humanize}} day",
                        "{{count | NumberFormatter.humanize}} days"
                    ],
                    title: "Updated operating system end-of-life alert threshold"
                },
                severeVulnerabilityMinSeverity: {
                    inherit: "Inherit",
                    title: "Set finding vulnerability minimum severity"
                },
                trustedFiles: {
                    added: "Added",
                    files: [
                        "1 file",
                        "{{count | NumberFormatter.humanize}} files"
                    ],
                    removed: "Deleted"
                },
                virtualMachineEnabled: {
                    false: "Disabled",
                    inherit: "Inherit",
                    title: "Set virtual machine scanning status",
                    true: "Enabled"
                },
                virtualMachineImageEnabled: {
                    false: "Disabled",
                    inherit: "Inherit",
                    title: "Set virtual machine image scanning status",
                    true: "Enabled"
                },
                vulnerabilityScoreType: {
                    title: "Set vulnerability severity metric",
                    [Contract.TypeNames.VulnerabilityScoreType]: {
                        [Contract.VulnerabilityScoreType.Cvss]: "CVSS",
                        [Contract.VulnerabilityScoreType.Vpr]: "VPR"
                    }
                }
            }));
    const workloadAnalysisUpdateAuditEvent = context.auditEventModel.auditEvent as Contract.WorkloadAnalysisUpdateAuditEvent;
    return new AuditEventDefinition(
        _.filter([
            workloadAnalysisUpdateAuditEvent.enabledChanged
                ? new AuditEventDefinitionDetailItem(
                    localization.enabled.title(),
                    _.isNil(workloadAnalysisUpdateAuditEvent.enabled)
                        ? localization.enabled.inherit()
                        : workloadAnalysisUpdateAuditEvent.enabled
                            ? localization.enabled.true()
                            : localization.enabled.false())
                : undefined!,
            workloadAnalysisUpdateAuditEvent.containerImageRepositoryEnabledChanged
                ? new AuditEventDefinitionDetailItem(
                    localization.containerImageRepositoryEnabled.title(),
                    _.isNil(workloadAnalysisUpdateAuditEvent.containerImageRepositoryEnabled)
                        ? localization.containerImageRepositoryEnabled.inherit()
                        : workloadAnalysisUpdateAuditEvent.containerImageRepositoryEnabled
                            ? localization.containerImageRepositoryEnabled.true()
                            : localization.containerImageRepositoryEnabled.false())
                : undefined!,
            workloadAnalysisUpdateAuditEvent.excludeUnresolvableVulnerabilitiesChanged
                ? new AuditEventDefinitionDetailItem(
                    localization.excludeUnresolvableVulnerabilities.title(),
                    _.isNil(workloadAnalysisUpdateAuditEvent.excludeUnresolvableVulnerabilities)
                        ? localization.excludeUnresolvableVulnerabilities.inherit()
                        : workloadAnalysisUpdateAuditEvent.excludeUnresolvableVulnerabilities
                            ? localization.excludeUnresolvableVulnerabilities.true()
                            : localization.excludeUnresolvableVulnerabilities.false())
                : undefined!,
            workloadAnalysisUpdateAuditEvent.severeVulnerabilityMinSeverityChanged
                ? new AuditEventDefinitionDetailItem(
                    localization.severeVulnerabilityMinSeverity.title(),
                    _.isNil(workloadAnalysisUpdateAuditEvent.severeVulnerabilityMinSeverity)
                        ? localization.excludeUnresolvableVulnerabilities.inherit()
                        : severityTranslator(workloadAnalysisUpdateAuditEvent.severeVulnerabilityMinSeverity))
                : undefined!,
            _.isEmpty(workloadAnalysisUpdateAuditEvent.virtualMachineAddedExclusionTags)
                ? undefined!
                : new AuditEventDefinitionDetailItem(
                    localization.exclusionTags.added(),
                    localization.exclusionTags.tags(workloadAnalysisUpdateAuditEvent.virtualMachineAddedExclusionTags.length)),
            workloadAnalysisUpdateAuditEvent.virtualMachineEnabledChanged
                ? new AuditEventDefinitionDetailItem(
                    localization.virtualMachineEnabled.title(),
                    _.isNil(workloadAnalysisUpdateAuditEvent.virtualMachineEnabled)
                        ? localization.virtualMachineEnabled.inherit()
                        : workloadAnalysisUpdateAuditEvent.virtualMachineEnabled
                            ? localization.virtualMachineEnabled.true()
                            : localization.virtualMachineEnabled.false())
                : undefined!,
            _.isEmpty(workloadAnalysisUpdateAuditEvent.virtualMachineImageAddedExclusionTags)
                ? undefined!
                : new AuditEventDefinitionDetailItem(
                    localization.exclusionTags.added(),
                    localization.exclusionTags.tags(workloadAnalysisUpdateAuditEvent.virtualMachineImageAddedExclusionTags.length)),
            workloadAnalysisUpdateAuditEvent.virtualMachineImageEnabledChanged
                ? new AuditEventDefinitionDetailItem(
                    localization.virtualMachineImageEnabled.title(),
                    _.isNil(workloadAnalysisUpdateAuditEvent.virtualMachineImageEnabled)
                        ? localization.virtualMachineImageEnabled.inherit()
                        : workloadAnalysisUpdateAuditEvent.virtualMachineImageEnabled
                            ? localization.virtualMachineImageEnabled.true()
                            : localization.virtualMachineImageEnabled.false())
                : undefined!,
            _.isEmpty(workloadAnalysisUpdateAuditEvent.virtualMachineImageRemovedExclusionTags)
                ? undefined!
                : new AuditEventDefinitionDetailItem(
                    localization.exclusionTags.removed(),
                    localization.exclusionTags.tags(workloadAnalysisUpdateAuditEvent.virtualMachineImageRemovedExclusionTags.length)),
            _.isEmpty(workloadAnalysisUpdateAuditEvent.virtualMachineRemovedExclusionTags)
                ? undefined!
                : new AuditEventDefinitionDetailItem(
                    localization.exclusionTags.removed(),
                    localization.exclusionTags.tags(workloadAnalysisUpdateAuditEvent.virtualMachineRemovedExclusionTags.length)),
            workloadAnalysisUpdateAuditEvent.fileScanEnabledChanged
                ? new AuditEventDefinitionDetailItem(
                    localization.fileScanEnabled.title(),
                    _.isNil(workloadAnalysisUpdateAuditEvent.fileScanEnabled)
                        ? localization.fileScanEnabled.inherit()
                        : workloadAnalysisUpdateAuditEvent.fileScanEnabled
                            ? localization.fileScanEnabled.true()
                            : localization.fileScanEnabled.false())
                : undefined!,
            workloadAnalysisUpdateAuditEvent.operatingSystemEndOfLifeTimeFrameChanged
                ? new AuditEventDefinitionDetailItem(
                    localization.operatingSystemEndOfLifeTimeFrame.title(),
                    localization.operatingSystemEndOfLifeTimeFrame.days(TimeSpanHelper.getDays(workloadAnalysisUpdateAuditEvent.operatingSystemEndOfLifeTimeFrame!)))
                : undefined!,
            _.isEmpty(workloadAnalysisUpdateAuditEvent.addedTrustedFiles)
                ? undefined!
                : new AuditEventDefinitionDetailItem(
                    localization.trustedFiles.added(),
                    localization.trustedFiles.files(workloadAnalysisUpdateAuditEvent.addedTrustedFiles.length)),
            _.isEmpty(workloadAnalysisUpdateAuditEvent.removedTrustedFiles)
                ? undefined!
                : new AuditEventDefinitionDetailItem(
                    localization.trustedFiles.removed(),
                    localization.trustedFiles.files(workloadAnalysisUpdateAuditEvent.removedTrustedFiles.length)),
            workloadAnalysisUpdateAuditEvent.vulnerabilityScoreTypeChanged
                ? new AuditEventDefinitionDetailItem(
                    localization.vulnerabilityScoreType.title(),
                    localization.vulnerabilityScoreType[Contract.TypeNames.VulnerabilityScoreType][workloadAnalysisUpdateAuditEvent.vulnerabilityScoreType!]())
                : undefined!
        ]));
}