import { DataTable, DataTableColumn, DataTableColumnRenderProps, EmptyMessageText, optionalTableCell, useLocalization } from "@infrastructure";
import { SxProps, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { InlineAzureKeyVaultVaultCertificatePermissions, InlineAzureKeyVaultVaultKeyPermissions, InlineAzureKeyVaultVaultSecretPermissions } from ".";
import { Contract, entityModelStore } from "../../../common";
import { AadDirectoryPrincipalReferenceCell } from "../../aad";

type AzureKeyVaultVaultAccessPoliciesTableProps = {
    accessPolicies: Contract.AzureKeyVaultVaultAccessPolicy[];
    sx?: SxProps;
    variant?: "card" | "view";
};

export function AzureKeyVaultVaultAccessPoliciesTable({ accessPolicies, sx, variant = "view" }: AzureKeyVaultVaultAccessPoliciesTableProps) {
    entityModelStore.useGet(
        _(accessPolicies).
            flatMap(
                accessPolicy => [
                    accessPolicy.principalReference.id!,
                    accessPolicy.applicationServicePrincipalReference?.id
                ]).
            filter().
            uniq().
            as<string>().
            value());
    const localization =
        useLocalization(
            "tenants.azure.azureKeyVaultVaultAccessPoliciesTable",
            () => ({
                columns: {
                    applicationServicePrincipal: "Application",
                    certificatePermissions: "Certificate Permissions",
                    keyPermissions: "Key Permissions",
                    principal: "Principal",
                    secretPermissions: "Secret Permissions"
                },
                empty: "No Access Policies"
            }));
    return (
        <DataTable
            emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty()) }}
            fetchItems={() => accessPolicies}
            getItemId={(item: Contract.AzureKeyVaultVaultAccessPolicy) => item.principalReference.id ?? item.principalReference.rawId}
            sortOptions={{ enabled: false }}
            sx={sx}
            variant={variant}>
            <DataTableColumn
                id={AzureKeyVaultVaultAccessPoliciesTableColumnId.Principal}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AzureKeyVaultVaultAccessPolicy>) =>
                        <AadDirectoryPrincipalReferenceCell principalReference={item.principalReference}/>}
                title={localization.columns.principal()}/>
            <DataTableColumn
                id={AzureKeyVaultVaultAccessPoliciesTableColumnId.applicationServicePrincipal}
                render={
                    optionalTableCell<Contract.AzureKeyVaultVaultAccessPolicy>(
                        item =>
                            _.isNil(item.applicationServicePrincipalReference)
                                ? undefined
                                : <AadDirectoryPrincipalReferenceCell principalReference={item.applicationServicePrincipalReference}/>)}
                title={localization.columns.applicationServicePrincipal()}/>
            <DataTableColumn
                id={AzureKeyVaultVaultAccessPoliciesTableColumnId.KeyPermissions}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AzureKeyVaultVaultAccessPolicy>) =>
                        <Typography>
                            <InlineAzureKeyVaultVaultKeyPermissions
                                permissions={item.keyPermissions}
                                variant="itemCount"/>
                        </Typography>
                }
                title={localization.columns.keyPermissions()}/>
            <DataTableColumn
                id={AzureKeyVaultVaultAccessPoliciesTableColumnId.SecretPermissions}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AzureKeyVaultVaultAccessPolicy>) =>
                        <Typography>
                            <InlineAzureKeyVaultVaultSecretPermissions
                                permissions={item.secretPermissions}
                                variant="itemCount"/>
                        </Typography>
                }
                title={localization.columns.secretPermissions()}/>
            <DataTableColumn
                id={AzureKeyVaultVaultAccessPoliciesTableColumnId.CertificatePermissions}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AzureKeyVaultVaultAccessPolicy>) =>
                        <Typography>
                            <InlineAzureKeyVaultVaultCertificatePermissions
                                permissions={item.certificatePermissions}
                                variant="itemCount"/>
                        </Typography>
                }
                title={localization.columns.certificatePermissions()}/>
        </DataTable>);
}

enum AzureKeyVaultVaultAccessPoliciesTableColumnId {
    applicationServicePrincipal = "applicationServicePrincipal",
    CertificatePermissions = "certificatePermissions",
    KeyPermissions = "keyPermissions",
    Principal = "principal",
    SecretPermissions = "secretPermissions"
}