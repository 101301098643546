import { DataTableColumn, DataTableColumnRenderProps, PagedValuesFilter, useLocalization } from "@infrastructure";
import React from "react";
import { useGcpCommonResourceDefinition } from ".";
import { Contract, ElasticsearchItemPageHelper } from "../../../../../../../../../../common";
import { GcpScopeResourceAccessLevel, GcpScopeResourceAccessLevelFilter, useGcpScopeResourceAccessLevelTranslator } from "../../../../../../../../../../tenants";
import { DefinitionData } from "../../../../Table";

export function useGcpCommonScopeResourceDefinition(definitionData: DefinitionData) {
    const commonResourceDefinition = useGcpCommonResourceDefinition(definitionData);

    const scopeResourceAccessLevelTranslator = useGcpScopeResourceAccessLevelTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.gcp.useGcpCommonScopeResourceDefinition",
            () => ({
                columns: {
                    accessLevel: "Access",
                    rawId: "ID"
                }
            }));

    return {
        columns:
            {
                ...commonResourceDefinition.columns,
                accessLevelColumn:
                    <DataTableColumn
                        exportOptions={{
                            getItem:
                                (item: Contract.GcpScopeResourceModel) => ({
                                    [localization.columns.accessLevel()]: scopeResourceAccessLevelTranslator(item.accessLevel)
                                })
                        }}
                        filterOptions={{
                            itemOrItems: {
                                element:
                                    <GcpScopeResourceAccessLevelFilter placeholder={localization.columns.accessLevel()}/>
                            }
                        }}
                        id={Contract.EntityModelProperty.GcpScopeResourceAccessLevel}
                        key={Contract.EntityModelProperty.GcpScopeResourceAccessLevel}
                        render={
                            ({ item }: DataTableColumnRenderProps<Contract.GcpScopeResourceModel>) =>
                                <GcpScopeResourceAccessLevel accessLevel={item.accessLevel}/>}
                        title={localization.columns.accessLevel()}/>,
                rawIdColumn:
                    <DataTableColumn
                        exportOptions={{
                            getItem:
                                (item: Contract.GcpScopeResourceModel) => ({
                                    [localization.columns.rawId()]: (item.entity as Contract.GcpScopeResource).rawId
                                })
                        }}
                        filterOptions={{
                            itemOrItems: {
                                default: true,
                                element:
                                    <PagedValuesFilter
                                        getValuePage={
                                            ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                                definitionData.entityTypeEntitiesViewName,
                                                Contract.EntityModelProperty.GcpScopeResourceRawId)}
                                        placeholder={localization.columns.rawId()}/>
                            }
                        }}
                        id={Contract.EntityModelProperty.GcpScopeResourceRawId}
                        itemProperty={item => (item.entity as Contract.GcpScopeResource).rawId}
                        key={Contract.EntityModelProperty.GcpScopeResourceRawId}
                        selectorOptions={{ default: false }}
                        title={localization.columns.rawId()}/>
            }
    };
}