﻿import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, entityModelStore, InfoItem } from "../../../../../../../../../../common";
import { useAzureComputeDiskResourceNetworkAccessPolicyTypeTranslator } from "../../../../../../hooks";
import { EntitiesInfoItem } from "../../../../components";
import { useAzureCommonResourceGroupResourceInfoItemElements } from "./useAzureCommonResourceGroupResourceInfoItemElements";

export function useAzureCommonComputeDiskResourceInfoItemElements(computeDiskResourceModel: Contract.AzureResourceGroupResourceModel) {
    const commonResourceGroupResourceInfoItemElements = useAzureCommonResourceGroupResourceInfoItemElements(computeDiskResourceModel);
    const computeDiskResource = computeDiskResourceModel.entity as Contract.AzureComputeDiskResource;
    const diskEncryptionSetModel = entityModelStore.useGet(computeDiskResource.encryptionSetId) as Contract.AzureComputeDiskEncryptionSetModel;

    const computeDiskResourceNetworkAccessPolicyTypeTranslator = useAzureComputeDiskResourceNetworkAccessPolicyTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.azure.useAzureCommonComputeDiskResourceInfoItemElements",
            () => ({
                info: {
                    encryptionType: {
                        title: "Encryption",
                        [Contract.TypeNames.AzureComputeDiskResourceEncryptionType]: {
                            [Contract.AzureComputeDiskResourceEncryptionType.CustomerManagedKey]: "Customer Managed",
                            [Contract.AzureComputeDiskResourceEncryptionType.PlatformAndCustomerManagedKeys]: "Platform Managed and Customer Managed",
                            [Contract.AzureComputeDiskResourceEncryptionType.PlatformManagedKey]: "Platform Managed"
                        }
                    },
                    networkAccessPolicyType: "Network Access",
                    vaultIdReference: "Encryption Key Vault",
                    vaultKeyIdReference: "Encryption Key"
                }
            }));
    return {
        ...commonResourceGroupResourceInfoItemElements,
        diskEncryptionSetVaultIdReference:
            <EntitiesInfoItem
                entityIdsOrModels={diskEncryptionSetModel?.vaultIdReference}
                entityTypeName={Contract.TypeNames.AzureKeyVaultVault}
                key="vaultIdReference"
                title={localization.info.vaultIdReference()}/>,
        diskEncryptionSetVaultKeyIdReference:
            <EntitiesInfoItem
                entityIdsOrModels={diskEncryptionSetModel?.vaultKeyIdReference}
                entityTypeName={Contract.TypeNames.AzureKeyVaultVaultKey}
                key="vaultKeyIdReference"
                title={localization.info.vaultKeyIdReference()}/>,
        encryptionType:
            <InfoItem
                key="encryptionType"
                title={localization.info.encryptionType.title()}
                value={localization.info.encryptionType[Contract.TypeNames.AzureComputeDiskResourceEncryptionType][computeDiskResource.encryptionType]()}/>,
        networkAccessPolicyType:
            <InfoItem
                key="networkAccessPolicy"
                title={localization.info.networkAccessPolicyType()}
                value={computeDiskResourceNetworkAccessPolicyTypeTranslator(computeDiskResource.networkAccessPolicyType)}/>

    };
}