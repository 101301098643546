import { Grid2, Typography } from "@mui/material";
import _, { Function0 } from "lodash";
import React, { useCallback, useState } from "react";
import { NumberFormatter, TimeFormatter, TimeHelper, TimeSpanFormatter, TimeSpanHelper, useLocalization } from "@infrastructure";
import { Contract, CustomerConsoleAppUrlHelper, CustomerConsoleAppUrlHelperGetRisksRelativeUrlFilters, RiskStatusIcon, RiskTypeGroups, SummaryDashboardContext, TicketingIcon, TimeFrameSelector, TimeRangeHelper, useDashboardContext, WidgetDefinition } from "../../../../../../../..";
import { Item, ItemInfoItem } from "./components";

export function useGetRiskTrendsDefinition(): () => WidgetDefinition {
    const localization =
        useLocalization(
            "common.dashboard.widget.hooks.useDefinition.hooks.useGetRiskTrendsDefinition",
            () => ({
                helpText: "Track the status of findings by viewing a snapshot of activity data over a set period of time (last 7, 30, or 90 days). See how many findings were opened/closed/ignored, and how many tickets were created (manually or via automation rules) based on findings.",
                title: "Trends"
            }));
    const [timeFrame, setTimeFrame] = useState<Contract.TimeFrame>(Contract.TimeFrame.Medium);

    return useCallback<Function0<WidgetDefinition>>(
        () => ({
            element: <RiskTrends timeFrame={timeFrame}/>,
            options: {
                helpText: localization.helpText(),
                title: localization.title(),
                topElement: <TimeFrameSelector
                    timeFrame={timeFrame}
                    onTimeFrameChanged={setTimeFrame}/>
            }
        }),
        [localization, timeFrame]);
}

type RiskTrendsProps = {
    timeFrame: Contract.TimeFrame;
};

function RiskTrends({ timeFrame }: RiskTrendsProps) {
    const { summary } = useDashboardContext<SummaryDashboardContext>();

    function getRisksRelativeUrl(view: Contract.RisksView, filters?: CustomerConsoleAppUrlHelperGetRisksRelativeUrlFilters) {
        const filterTimeRange =
            new Contract.TimeRange(
                TimeFormatter.date(
                    TimeHelper.addTime(
                        summary.timeFrameToRiskTrendsDataMap[timeFrame].startTime,
                        TimeRangeHelper.getTimeFrameValue(timeFrame),
                        "day")),
                summary.timeFrameToRiskTrendsDataMap[timeFrame].startTime);
        return CustomerConsoleAppUrlHelper.getRisksRelativeUrl(
            view,
            {
                statusUpdateTimeRange: filterTimeRange,
                ...filters
            },
            RiskTypeGroups.RiskPolicyType);
    }

    const localization =
        useLocalization(
            "common.dashboard.widget.hooks.useDefinition.hooks.useGetRiskTrendsDefinition.riskTrends",
            () => ({
                item: {
                    closedRiskCount: "Closed findings",
                    createdTicketCount: "Tickets created",
                    ignoredRiskCount: "Ignored findings",
                    openRiskCount: {
                        highOrCriticalSeverityOpenRiskCount: "{{formattedRiskCount}} have high/critical severity",
                        newPolicyOpenRiskCount: {
                            helpText: "Track findings that were opened as a result of new policies added to Tenable Cloud Security during this time period.",
                            title: "{{formattedRiskCount}} due to new features"
                        },
                        title: "Opened findings"
                    },
                    riskAverageOpenTimeFrame: "average time to resolve {{formattedRiskAverageOpenTimeFrame}}"
                }
            }));

    return (
        <Grid2
            container={true}
            spacing={1}>
            <Item
                className={Contract.RiskStatus.Open}
                count={summary.timeFrameToRiskTrendsDataMap[timeFrame].openRiskCount}
                icon={<RiskStatusIcon status={Contract.RiskStatus.Open}/>}
                infoItems={
                    _<ItemInfoItem>([]).
                        concatIf(
                            summary.timeFrameToRiskTrendsDataMap[timeFrame].highOrCriticalOpenRiskCount > 0,
                            new ItemInfoItem(
                                localization.item.openRiskCount.highOrCriticalSeverityOpenRiskCount({
                                    formattedRiskCount:
                                        <Typography
                                            component="span"
                                            sx={{
                                                fontWeight: 600,
                                                lineHeight: 1.2
                                            }}>
                                            {NumberFormatter.humanize(summary.timeFrameToRiskTrendsDataMap[timeFrame].highOrCriticalOpenRiskCount)}
                                        </Typography>
                                }),
                                getRisksRelativeUrl(
                                    Contract.RisksView.Open,
                                    {
                                        severity: [Contract.Severity.Critical, Contract.Severity.High]
                                    }))).
                        concatIf(
                            summary.timeFrameToRiskTrendsDataMap[timeFrame].newPolicyOpenRiskCount > 0,
                            new ItemInfoItem(
                                localization.item.openRiskCount.newPolicyOpenRiskCount.title({
                                    formattedRiskCount:
                                        <Typography
                                            component="span"
                                            sx={{
                                                fontWeight: 600,
                                                lineHeight: 1.2
                                            }}>
                                            {NumberFormatter.humanize(summary.timeFrameToRiskTrendsDataMap[timeFrame].newPolicyOpenRiskCount)}
                                        </Typography>
                                }),
                                getRisksRelativeUrl(
                                    Contract.RisksView.Open,
                                    {
                                        policyConfigurationTypeNameOrIds:
                                            _.concat(
                                                summary.timeFrameToRiskTrendsDataMap[timeFrame].openRiskNewBuiltInRiskPolicyConfigurationTypeNames,
                                                summary.timeFrameToRiskTrendsDataMap[timeFrame].openRiskNewCustomRiskPolicyIds)
                                    }),
                                localization.item.openRiskCount.newPolicyOpenRiskCount.helpText())).
                        value()}
                linkUrl={getRisksRelativeUrl(Contract.RisksView.Open)}
                title={localization.item.openRiskCount.title()}/>
            <Item
                className={Contract.RiskStatus.Closed}
                count={summary.timeFrameToRiskTrendsDataMap[timeFrame].closedRiskCount}
                icon={<RiskStatusIcon status={Contract.RiskStatus.Closed}/>}
                infoItems={
                    TimeSpanHelper.getDays(summary.timeFrameToRiskTrendsDataMap[timeFrame].riskAverageOpenTimeFrame) > 0
                        ? [
                            new ItemInfoItem(localization.item.riskAverageOpenTimeFrame({
                                formattedRiskAverageOpenTimeFrame:
                                    <Typography
                                        component="span"
                                        sx={{
                                            fontWeight: 600,
                                            lineHeight: 1.2
                                        }}>
                                        {TimeSpanFormatter.humanizeDays(summary.timeFrameToRiskTrendsDataMap[timeFrame].riskAverageOpenTimeFrame)}
                                    </Typography>
                            }))
                        ]
                        : undefined}
                linkUrl={getRisksRelativeUrl(Contract.RisksView.Closed)}
                title={localization.item.closedRiskCount()}/>
            <Item
                className={Contract.RiskStatus.Ignored}
                count={summary.timeFrameToRiskTrendsDataMap[timeFrame].ignoredRiskCount}
                icon={<RiskStatusIcon status={Contract.RiskStatus.Ignored}/>}
                linkUrl={getRisksRelativeUrl(Contract.RisksView.Ignored)}
                title={localization.item.ignoredRiskCount()}/>
            <Item
                className={Contract.TypeNames.TicketingServiceType}
                count={summary.timeFrameToRiskTrendsDataMap[timeFrame].createdTicketCount}
                icon={<TicketingIcon/>}
                linkUrl={
                    CustomerConsoleAppUrlHelper.getRisksRelativeUrl(
                        Contract.RisksView.Open,
                        {
                            ticketingServiceTypes: _.values(Contract.TicketingServiceType)
                        },
                        RiskTypeGroups.RiskPolicyType)}
                title={localization.item.createdTicketCount()}/>
        </Grid2>
    );
}