import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { Contract, InfoCard, InfoItem } from "../../../../../../../../../../common";
import { AadDirectoryApplicationCertificatesTable, AadDirectoryApplicationSecretsTable } from "../../../../../../../../../../tenants";
import { useAadDirectoryApplicationTypeTranslator } from "../../../../../../hooks";
import { EntitiesInfoItem, Info } from "../../../../components";
import { useAadDefaultDirectoryEntityInfoItemElements } from "./useAadDefaultDirectoryEntityInfoItemElements";

export function useAadDirectoryApplicationDefinition(directoryEntityModel: Contract.AadDirectoryEntityModel, options?: DefinitionOptions) {
    const application = directoryEntityModel.entity as Contract.AadDirectoryApplication;
    const applicationModel = directoryEntityModel as Contract.AadDirectoryApplicationModel;
    const defaultDirectoryEntityInfoItemElements = useAadDefaultDirectoryEntityInfoItemElements(directoryEntityModel as Contract.AadDirectoryPrincipalModel);

    const directoryApplicationTypeTranslator = useAadDirectoryApplicationTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aad.useAadDirectoryApplicationDefinition",
            () => ({
                info: {
                    cards: {
                        certificates: "Certificates",
                        secrets: "Secrets"
                    },
                    items: {
                        applicationRawId: "Application Id",
                        homepageRawUrl: "Homepage URL",
                        ownerPrincipalIds: "Owners",
                        servicePrincipalIds: "Application",
                        type: "Account Types"
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={[]}
                defaultTenantInfoItemElements={defaultDirectoryEntityInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="applicationRawId"
                        title={localization.info.items.applicationRawId()}
                        value={application.applicationRawId}/>,
                    <InfoItem
                        key="type"
                        location="all"
                        title={localization.info.items.type()}
                        value={
                            _.isNil(application.type)
                                ? undefined
                                : directoryApplicationTypeTranslator(application.type)}/>,
                    <InfoItem
                        key="homepageRawUrl"
                        title={localization.info.items.homepageRawUrl()}
                        value={application.homepageRawUrl}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={application.ownerPrincipalIds}
                        entityTypeName={Contract.TypeNames.AadDirectoryApplicationServicePrincipal}
                        key="ownerPrincipalIds"
                        location="all"
                        title={localization.info.items.ownerPrincipalIds()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={applicationModel.servicePrincipalIds}
                        entityTypeName={Contract.TypeNames.AadDirectoryApplicationServicePrincipal}
                        key="servicePrincipalIds"
                        title={localization.info.items.servicePrincipalIds()}/>
                ]}
                options={options?.infoOptions}>
                <InfoCard title={localization.info.cards.certificates()}>
                    <AadDirectoryApplicationCertificatesTable applicationModel={applicationModel}/>
                </InfoCard>
                <InfoCard title={localization.info.cards.secrets()}>
                    <AadDirectoryApplicationSecretsTable applicationModel={applicationModel}/>
                </InfoCard>
            </Info>,
        sensitive: false
    });
}