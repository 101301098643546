import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, Entity, InlineEntities, useEntityTypeNameTranslator } from "../../../../../../../../../../../common";
import { AuditEventDefinition, AuditEventDefinitionContext, AuditEventDefinitionDetailItem } from "../../../useDefinition";
import { usePermissionAssignmentEligibilityAuditEventCommonDetailItems } from "./usePermissionAssignmentEligibilityAuditEventCommonDetailItems";

export function useGcpRoleBindingEligibilityAuditEventDefinition(context: AuditEventDefinitionContext) {
    const permissionAssignmentEligibilityAuditEventCommonDetailItems = usePermissionAssignmentEligibilityAuditEventCommonDetailItems(context);
    const localization =
        useLocalization(
            "views.customer.auditEvents.profile.hooks.useDefinition.hooks.usePermissionEligibilityAuditEventDefinition.hooks.useGcpRoleBindingEligibilityAuditEventDefinition",
            () => ({
                roleIdReferences: "Permissions"
            }));

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const permissionEligibilityAuditEventModel = context.auditEventModel as Contract.PermissionEligibilityAuditEventModel;
    const auditEventModelGcpRoleBindingEligibilityData = permissionEligibilityAuditEventModel.permissionEligibilityData as Contract.PermissionEligibilityAuditEventModelGcpRoleBindingEligibilityData;

    return new AuditEventDefinition(
        _.filter([
            permissionAssignmentEligibilityAuditEventCommonDetailItems.name,
            permissionAssignmentEligibilityAuditEventCommonDetailItems.principalTenantId,
            permissionAssignmentEligibilityAuditEventCommonDetailItems.granteePrincipalIdReferences,
            auditEventModelGcpRoleBindingEligibilityData.roleIdReferences.length === 1
                ? new AuditEventDefinitionDetailItem(
                    localization.roleIdReferences(),
                    context.entityModelMap[auditEventModelGcpRoleBindingEligibilityData.roleIdReferences[0]].entity.displayName,
                    <Entity
                        entityIdOrModel={auditEventModelGcpRoleBindingEligibilityData.roleIdReferences[0]}
                        variant="iconText"/>)
                : new AuditEventDefinitionDetailItem(
                    localization.roleIdReferences(),
                    entityTypeNameTranslator(
                        Contract.TypeNames.GcpIamRole,
                        {
                            count: auditEventModelGcpRoleBindingEligibilityData.roleIdReferences.length,
                            includeCount: true
                        }),
                    <InlineEntities
                        entityIdsOrModels={auditEventModelGcpRoleBindingEligibilityData.roleIdReferences}
                        entityTypeName={Contract.TypeNames.GcpIamRole}
                        variant="itemPlusItemCount"/>
                ),
            permissionAssignmentEligibilityAuditEventCommonDetailItems.permissionRequestScopePaths,
            permissionAssignmentEligibilityAuditEventCommonDetailItems.expirationTimeFrame,
            permissionAssignmentEligibilityAuditEventCommonDetailItems.approval,
            ...permissionAssignmentEligibilityAuditEventCommonDetailItems.approvalLevels,
            permissionAssignmentEligibilityAuditEventCommonDetailItems.reasonRequired,
            permissionAssignmentEligibilityAuditEventCommonDetailItems.updateAuditEventChanges
        ]));
}