import { useMemo } from "react";
import { useOciResourceDefinition } from ".";
import { DefinitionData } from "../../../../Table";

export function useOciEntityDefinition(definitionData: DefinitionData) {
    const useDefinition =
        useMemo(
            () => {
                switch (definitionData.entityTypeEntitiesViewName) {
                    default:
                        return useOciResourceDefinition;
                }
            },
            []);

    return useDefinition(definitionData);
}