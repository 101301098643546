import { IconNodeContent, useDirectedGraphNodeContext } from "@infrastructure";
import React from "react";
import { Contract, getEntityNodeContentSize } from "../../../../../../..";
import { AwsResourcePermitterIcon, AwsResourcePermitterType, useAwsResourcePermitterTranslator } from "../../../../../../../../tenants";

type ResourcePermitterNoteContentProps = {
    entityTypeName: string;
    resourcePermitterType: Contract.AwsResourcePermitterType;
    variant: "large" | "small";
};

export function ResourcePermitterNodeContent({ entityTypeName, resourcePermitterType, variant }: ResourcePermitterNoteContentProps) {
    const resourcePermitterTranslator = useAwsResourcePermitterTranslator();
    const { modelNode } = useDirectedGraphNodeContext();
    return (
        <IconNodeContent
            iconOptions={{
                icon:
                    <AwsResourcePermitterIcon
                        blurred={modelNode.options.appearance === "normal"}
                        resourceTypeName={entityTypeName}/>,
                shape: "rectangle"
            }}
            size={getEntityNodeContentSize("bottom", variant)}
            titleElementOrOptions={{ title: resourcePermitterTranslator(new AwsResourcePermitterType(resourcePermitterType, entityTypeName)) }}/>);
}