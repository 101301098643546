import { AddIcon, CheckButton, DataTable, DataTableAction, DataTableActions, DataTableColumn, DataTableColumnRenderProps, InlineTexts, Message, StringHelper, useChangeEffect, useLocalization } from "@infrastructure";
import { Button, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useRef } from "react";
import { ActionsCell, IntegrationsCell, NameCell, ScopesCell, useAuditEventAutomationsContext } from "../../..";
import { Contract, StorageHelper, TypeHelper, useScopeNameTranslator, useScopeNavigationViewContext } from "../../../../../../../../../../common";
import { useAuditEventTypeNameTranslator } from "../../../../../../../AuditEvents/hooks";
import { useSetAuditEventAutomationsContext } from "../../AuditEventAutomations";

type TableProps = {
    addRuleDisabledTooltip?: string;
    ruleModels: Contract.ScopeSystemEntityModel[];
};

export function Table({ addRuleDisabledTooltip = undefined, ruleModels }: TableProps) {
    const { childScopeEnabled } = useAuditEventAutomationsContext();
    const { scopeNodeModel } = useScopeNavigationViewContext();
    const setAuditEventAutomationsContext = useSetAuditEventAutomationsContext();

    const auditEventTypeNameTranslator = useAuditEventTypeNameTranslator();
    const scopeNameTranslator = useScopeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.configuration.automations.auditEventAutomations.table",
            () => ({
                actions: {
                    add: "Add Rule",
                    childScopeEnabled: "Flat View"
                },
                columns: {
                    auditEventTypeNames: {
                        empty: "All existing and future",
                        some: [
                            "1 action",
                            "{{count | NumberFormatter.humanize}} actions"
                        ],
                        title: "Actions"
                    },
                    integrations: "Integrations",
                    rule: "Rule",
                    scope: "Scope",
                    scopes: "Targets"
                }
            }));

    const dataTableActionsRef = useRef<DataTableActions>();
    useChangeEffect(
        () => {
            dataTableActionsRef.current!.reset();
        },
        [ruleModels]);

    return (
        <DataTable
            actionsRef={dataTableActionsRef}
            fetchItems={
                () =>
                    _(ruleModels).
                        map(ruleModel => (ruleModel.configuration as Contract.AuditEventAutomationRuleConfiguration)).
                        orderBy(item => StringHelper.getSortValue(item.name)).
                        value()}
            getItemId={item => item.id}
            sortOptions={{ enabled: false }}>
            <DataTableAction>
                <CheckButton
                    checked={childScopeEnabled}
                    title={localization.actions.childScopeEnabled()}
                    onCheckedChanged={
                        checked => {
                            StorageHelper.customerConfigurationAutomationsAuditEventsFlatView.setValue(checked);
                            setAuditEventAutomationsContext(
                                context => ({
                                    ...context,
                                    childScopeEnabled: checked
                                }));
                        }}/>
            </DataTableAction>
            <DataTableAction>
                <Stack
                    alignItems="center"
                    direction="row"
                    spacing={0.5}>
                    {!_.isNil(addRuleDisabledTooltip) && (
                        <Message
                            level="infoWarning"
                            title={addRuleDisabledTooltip}
                            variant="minimal"/>)}
                    <Button
                        disabled={!_.isNil(addRuleDisabledTooltip)}
                        size="small"
                        startIcon={<AddIcon/>}
                        onClick={
                            () =>
                                setAuditEventAutomationsContext(
                                    context => ({
                                        ...context,
                                        addOrEditOpen: true
                                    }))}>
                        {localization.actions.add()}
                    </Button>
                </Stack>
            </DataTableAction>
            <DataTableColumn
                id={TableColumnId.Rule}
                render={NameCell}
                title={localization.columns.rule()}/>
            <DataTableColumn
                id={TableColumnId.Scope}
                itemProperty={(item: Contract.AuditEventAutomationRuleConfiguration) => scopeNameTranslator(item.scopeId, { path: true })}
                title={localization.columns.scope()}/>
            {!TypeHelper.extendOrImplement(scopeNodeModel.configuration.typeName, Contract.TypeNames.TenantConfiguration) && (
                <DataTableColumn
                    id={TableColumnId.Tenants}
                    render={ScopesCell}
                    title={localization.columns.scopes()}/>)}
            <DataTableColumn
                id={TableColumnId.TypeNames}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AuditEventAutomationRuleConfiguration>) =>
                        _.isEmpty(item.auditEventTypeNames)
                            ? <Typography sx={{ fontWeight: 600 }}>
                                {localization.columns.auditEventTypeNames.empty()}
                            </Typography>
                            : <InlineTexts
                                namePluralizer={localization.columns.auditEventTypeNames.some}
                                texts={
                                    _(item.auditEventTypeNames).
                                        map(auditEventTypeName => auditEventTypeNameTranslator(auditEventTypeName)).
                                        uniq().
                                        orderBy(translatedAuditEventTypeName => StringHelper.getSortValue(translatedAuditEventTypeName)).
                                        value()}
                                variant="itemCountAndType"/>}
                title={localization.columns.auditEventTypeNames.title()}/>
            <DataTableColumn
                id={TableColumnId.Integrations}
                render={IntegrationsCell}
                title={localization.columns.integrations()}/>
            <DataTableColumn
                id={TableColumnId.Actions}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AuditEventAutomationRuleConfiguration>) =>
                        <ActionsCell
                            automationRuleConfiguration={item}
                            onEdit={
                                () =>
                                    setAuditEventAutomationsContext(
                                        automationContext => ({
                                            ...automationContext,
                                            addOrEditOpen: item
                                        }))}/>}/>
        </DataTable>);
}

enum TableColumnId {
    Actions = "actions",
    Integrations = "integrations",
    Rule = "rule",
    Scope = "scope",
    Tenants = "tenants",
    TypeNames = "typeNames"
}