import { useLocalization } from "@infrastructure";
import _ from "lodash";
import { Contract } from "../../../../../../../../../../../../../common";

export function useOrganizationIssueTranslator() {
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useObservabilityItems.datadog.hooks.useOrganizationIssueTranslator",
            () => ({
                valid: "Connected",
                [Contract.TypeNames.DatadogOrganizationStateIssue]: {
                    [Contract.DatadogOrganizationStateIssue.InvalidApiKey]: "Invalid API key"
                }
            }));
    return (organizationIssue?: Contract.DatadogOrganizationStateIssue) =>
        _.isNil(organizationIssue)
            ? localization.valid()
            : localization[Contract.TypeNames.DatadogOrganizationStateIssue][organizationIssue]();
}