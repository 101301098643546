export class AppError extends Error {
    constructor(message: string) {
        super(message);
        Object.setPrototypeOf(this, AppError.prototype);
    }
}

export class ApiError extends AppError {
    constructor(
        public error: string,
        public requestId: string | undefined,
        public statusCode: number) {
        super(`API error [error=${error} requestId=${requestId} statusCode=${statusCode}]`);
        Object.setPrototypeOf(this, ApiError.prototype);
    }
}

export class UnexpectedError extends AppError {
    constructor(name: string, value?: string) {
        super(`Unexpected value [${name}=${value}]`);
        Object.setPrototypeOf(this, UnexpectedError.prototype);
    }
}