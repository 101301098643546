﻿import { ItemSelector, Link, Optional, useLocalization, useOrderedWizardContext } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useAddOrEditContext, useSetAddOrEditContext } from "..";
import { Contract, CustomerConsoleAppUrlHelper, RadioField, scopeSystemEntityModelStore, useTheme } from "../../../../../../../../../../../../../../../common";

export function ChooseServerItem() {
    const setAddOrEditContext = useSetAddOrEditContext();
    const { organizationModel, updatedServerId } = useAddOrEditContext();

    const organizationConfiguration = organizationModel?.configuration as Optional<Contract.GitLabOrganizationConfiguration>;
    const serverModels = scopeSystemEntityModelStore.useGetCodeServer(Contract.CodeTenantType.GitLab);
    const serverConfigurations =
        useMemo(
            () =>
                _(serverModels).
                    filter(gitLabServerModel => gitLabServerModel.status === Contract.CodeServerModelStatus.Valid).
                    map(gitLabServerModel => (gitLabServerModel.configuration as Contract.GitLabServerConfiguration)).
                    value(),
            [serverModels]);
    const updatedServerConfiguration =
        useMemo(
            () =>
                _.find(
                    serverConfigurations,
                    serverConfiguration => serverConfiguration.id === updatedServerId)
            , [serverConfigurations, updatedServerId]);


    const [defaultServer, setDefaultServer] = useState(_.isNil((updatedServerId ?? organizationConfiguration?.serverId)));
    const [selectedServerConfiguration, setSelectedServerConfiguration] = useState(updatedServerConfiguration ?? _.first(serverConfigurations));

    const { executing, setValid, useNextEffect } = useOrderedWizardContext();

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCodeOrganizationItems.gitLab.addOrEditGitLab.chooseServerItem",
            () => ({
                fields: {
                    server: {
                        custom: "GitLab Self-Managed Server",
                        default: "GitLab.com",
                        description: {
                            link: "click here",
                            title: "Select where your project is hosted. If you are using GitLab Self-Managed Server, and your server doesn't appear in the dropdown, {{addServerWizardLink}} to add it before you proceed to add your group."
                        }
                    }
                }
            }));

    useEffect(
        () => {
            if (!_.isNil(selectedServerConfiguration)) {
                return;
            }

            const firstServerConfiguration = _.first(serverConfigurations);
            if (!_.isNil(firstServerConfiguration)) {
                setSelectedServerConfiguration(firstServerConfiguration);
            }
        },
        [serverConfigurations, selectedServerConfiguration]);

    useEffect(
        () => setValid(defaultServer || !!selectedServerConfiguration),
        [defaultServer, selectedServerConfiguration]);

    useNextEffect(
        async () => {
            const newUpdatedServerId =
                defaultServer
                    ? undefined
                    : selectedServerConfiguration!.id;
            if (newUpdatedServerId != updatedServerId) {
                setAddOrEditContext(
                    context => ({
                        ...context,
                        updatedServerId: newUpdatedServerId
                    }));
            }

            return undefined;
        }, [defaultServer, selectedServerConfiguration]);

    const theme = useTheme();
    return (
        <Stack
            spacing={4}
            sx={{ maxWidth: theme.spacing(60) }}>
            <Typography>
                {localization.fields.server.description.title({
                    addServerWizardLink:
                        <Link
                            urlOrGetUrl={
                                CustomerConsoleAppUrlHelper.getConfigurationIntegrationsCodeServersRelativeUrl(
                                    Contract.IntegrationType.GitLabServer,
                                    true)}
                            variant="external">
                            {localization.fields.server.description.link()}
                        </Link>
                })}
            </Typography>
            <RadioField
                checked={defaultServer}
                title={localization.fields.server.default()}
                onSelected={() => _.isNil(organizationConfiguration) && setDefaultServer(true)}/>
            <RadioField
                checked={!defaultServer}
                indent={false}
                title={localization.fields.server.custom()}
                onSelected={() => _.isNil(organizationConfiguration) && setDefaultServer(false)}>
                <ItemSelector
                    disabled={executing || !_.isNil(organizationConfiguration)}
                    fullWidth={true}
                    getItemText={(serverConfiguration: Contract.GitLabServerConfiguration) => serverConfiguration.name}
                    items={
                        !_.isNil(organizationConfiguration)
                            ? [updatedServerConfiguration]
                            : serverConfigurations ?? []}
                    selectedItem={selectedServerConfiguration}
                    sorted={true}
                    onSelectedItemChanged={setSelectedServerConfiguration}>
                    {(serverConfiguration: Contract.GitLabServerConfiguration) => serverConfiguration.name}
                </ItemSelector>
            </RadioField>
        </Stack>);
}