﻿import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, Entity, useEntityTypeNameTranslator } from "../../../../../../../../../../../common";

export function useOpResourceGeneralInformationStep(resourceModel: Contract.EntityModel) {
    const opResourceModel = resourceModel as Contract.OpResourceModel;

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.useResourceGeneralInformationStep.hooks.useOpResourceGeneralInformationStep",
            () => ({
                creationTime: "*capitalize*{{resourceTypeName}}** {{resource}} was created on {{creationTime | TimeFormatter.shortDate}}"
            }));
    return localization.creationTime({
        creationTime: opResourceModel.creationTime,
        resource:
            <Entity
                entityIdOrModel={opResourceModel}
                entityTypeNameTranslatorOptions={{ variant: "title" }}
                variant="typeText"/>,
        resourceTypeName:
            entityTypeNameTranslator(
                opResourceModel.entity.typeName,
                {
                    includeServiceName: false,
                    variant: "text"
                })
    });
}