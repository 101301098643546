import _, { Dictionary } from "lodash";
import { Contract, RiskController } from "..";

export function useGetAnalysisGroupTypeToRiskPolicyIdentifierToCountMap(
    getDataOrDatas:() => Contract.ComplianceRiskPolicyData | Contract.ComplianceRiskPolicyData[],
    complianceScopeId: string) {
    return async (): Promise<Dictionary<Dictionary<number>>> => {
        const analysisGroupTypeToRiskPolicyIdentifierToCountMap: Dictionary<Dictionary<number>> = {};
        const requestRiskPolicyDatas: Contract.RiskControllerGetRiskPolicyAnalyzedEntityCountRequestRiskPolicyData[] = [];

        const complianceRiskPolicyDatas = _.concat(getDataOrDatas());

        _.each(
            complianceRiskPolicyDatas,
            complianceRiskPolicyData => {
                analysisGroupTypeToRiskPolicyIdentifierToCountMap[complianceRiskPolicyData.analysisGroupType] ??= {};

                if (!complianceRiskPolicyData.hasResults) {
                    analysisGroupTypeToRiskPolicyIdentifierToCountMap[complianceRiskPolicyData.analysisGroupType][complianceRiskPolicyData.riskPolicyIdentifier] = 0;
                } else {
                    requestRiskPolicyDatas.push(
                        new Contract.RiskControllerGetRiskPolicyAnalyzedEntityCountRequestRiskPolicyData(
                            complianceRiskPolicyData.analysisGroupType,
                            complianceRiskPolicyData.riskPolicyIdentifier));
                }
            });
        if (!_.isEmpty(requestRiskPolicyDatas)) {
            const { riskPolicyDatas } =
                await RiskController.getRiskPolicyAnalyzedEntityCount(
                    new Contract.RiskControllerGetRiskPolicyAnalyzedEntityCountRequest(
                        requestRiskPolicyDatas,
                        complianceScopeId));

            _(complianceRiskPolicyDatas).
                filter(complianceRiskPolicyData => complianceRiskPolicyData.hasResults).
                each(
                    complianceRiskPolicyData =>
                        analysisGroupTypeToRiskPolicyIdentifierToCountMap[complianceRiskPolicyData.analysisGroupType][complianceRiskPolicyData.riskPolicyIdentifier] =
                            _(riskPolicyDatas).
                                find(
                                    riskPolicyData =>
                                        riskPolicyData.analysisGroupType === complianceRiskPolicyData.analysisGroupType &&
                                        riskPolicyData.riskPolicyIdentifier === complianceRiskPolicyData.riskPolicyIdentifier)!.analyzedEntityCount);
        }

        return analysisGroupTypeToRiskPolicyIdentifierToCountMap;
    };
}