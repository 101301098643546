import { AutocompleteItems, DropdownIcon, Link, StringHelper, useInputValidation, useLocalization, useOrderedWizardContext } from "@infrastructure";
import { Box, FormHelperText, Stack, TextField, Typography } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { Contract, CustomerConsoleAppUrlHelper, tenantModelStore, useTheme } from "../../../../../../../../../../../common";
import { useAddOrEditContext, useSetAddOrEditContext } from "../index";

export function AadTenantItem() {
    const aadTenantModels = tenantModelStore.useGetAadTenants();
    const { aadTenantModel, tenantModel } = useAddOrEditContext();
    const setAddOrEditContext = useSetAddOrEditContext();

    const orderedAadTenantModels =
        useMemo(
            () =>
                _(aadTenantModels).
                    filter(aadTenantModel => aadTenantModel.status === Contract.AadTenantModelStatus.Valid).
                    orderBy(aadTenantModel => StringHelper.getSortValue(aadTenantModel.configuration.name)).
                    value(),
            [aadTenantModels]);

    const [selectedAadTenantModel, setSelectedAadTenantModel] = useState<Contract.AadTenantModel | undefined>(aadTenantModel);

    const { setLoaded, setValid } = useOrderedWizardContext();
    useEffect(
        () => {
            setLoaded();
        },
        []);

    const localization =
        useLocalization(
            "views.customer.scopes.hooks.useDefinition.hooks.useAzureDefinition.addOrEdit.aadTenantItem",
            () => ({
                fields: {
                    aadTenant: {
                        empty: "No Microsoft Entra ID tenants",
                        error: {
                            required: "You must choose an Microsoft Entra ID tenant"
                        },
                        subtitle: {
                            aadTenantsPageLink: "click here",
                            text: "Select the Microsoft Entra ID tenant linked to the Azure subscription you want to add. If the tenant doesn’t appear in the dropdown, {{aadTenantsPageLink}} to add it before you proceed to add your subscription."
                        },
                        title: "Microsoft Entra ID tenant"
                    }
                }
            }));

    const [aadTenantModelValidationController, aadTenantModelValidationMessage] =
        useInputValidation(
            () => {
                if (_.isNil(selectedAadTenantModel)) {
                    return localization.fields.aadTenant.error.required();
                }

                return undefined;
            },
            [selectedAadTenantModel]);

    useEffect(
        () => {
            setAddOrEditContext(
                addContext => ({
                    ...addContext,
                    aadTenantModel: selectedAadTenantModel
                }));

            setValid(aadTenantModelValidationController.isValid());
        },
        [selectedAadTenantModel]);

    const theme = useTheme();
    return (
        <Stack
            spacing={4}
            sx={{ maxWidth: theme.spacing(60) }}>
            <Stack spacing={3}>
                <Typography>
                    {localization.fields.aadTenant.subtitle.text({
                        aadTenantsPageLink:
                            <Link urlOrGetUrl={CustomerConsoleAppUrlHelper.getConfigurationIntegrationsIdentityProviderTenantsAadRelativeUrl(true)}>
                                {localization.fields.aadTenant.subtitle.aadTenantsPageLink()}
                            </Link>
                    })}
                </Typography>
                <Box>
                    <AutocompleteItems
                        disableClearable={true}
                        disabled={!_.isNil(tenantModel)}
                        fullWidth={true}
                        getOptionLabel={aadTenantModel => aadTenantModel.configuration.name}
                        noOptionsText={localization.fields.aadTenant.empty()}
                        options={orderedAadTenantModels}
                        popupIcon={<DropdownIcon sx={{ color: theme.palette.text.secondary }}/>}
                        renderInput={
                            params => (
                                <TextField
                                    {...params}
                                    label={localization.fields.aadTenant.title()}
                                    variant="outlined"/>)}
                        value={selectedAadTenantModel}
                        onChange={(_event, aadTenantModel) => setSelectedAadTenantModel(aadTenantModel)}>
                        {aadTenantModel =>
                            <Stack>
                                <Typography>
                                    {aadTenantModel.configuration.name}
                                </Typography>
                                <Typography variant="subtitle1">
                                    {aadTenantModel.configuration.rawId}
                                </Typography>
                            </Stack>}
                    </AutocompleteItems>
                    {!_.isNil(aadTenantModelValidationMessage) &&
                        <FormHelperText error={true}>{aadTenantModelValidationMessage}</FormHelperText>}
                </Box>
            </Stack>
        </Stack>);
}