﻿import { useLocalization, WelcomeView } from "@infrastructure";
import _ from "lodash";
import React, { useCallback, useMemo } from "react";
import { CodeController, CodeDashboardContext, compactLayout, Contract, Dashboard as DashboardCore, DashboardConfiguration, dashboardWidgetMap, DashboardWidgetType, EntityController, FeatureHelper, SummaryDashboardContext, tenantModelStore, useGetSelectedScopeHasData, useLayoutOptions } from "../../../../../../common";

export function Dashboard() {
    const filteredActiveCodeTenantModels = tenantModelStore.useGetFilteredActiveCodeTenants();
    const localization =
        useLocalization(
            "views.customer.code.dashboard",
            () => ({
                filtered: "You are currently filtering on a specific scope.\nExpand to the Organization scope to see relevant data"
            }));

    return (
        _.isEmpty(filteredActiveCodeTenantModels)
            ? <WelcomeView title={localization.filtered()}/>
            : <Core/>);
}

export function Core() {
    useLayoutOptions({ contained: false });
    const filteredGitTenantModels = tenantModelStore.useGetFilteredGitTenants();
    const filteredGitTenants = tenantModelStore.useGetFilteredGitTenants();
    const getSelectedScopeHasData = useGetSelectedScopeHasData();

    const fetchDashboardContextData =
        useCallback(
            async () => {
                if (!getSelectedScopeHasData(filteredGitTenantModels)) {
                    return getAnalyzingDashboardContext();
                }

                const [{ summary }, { entityTypeEntitiesViewNameToCountMap }] =
                    await Promise.all([
                        CodeController.getDashboardSummary(),
                        EntityController.getSummary(new Contract.EntityControllerGetSummaryRequest(undefined)),
                        tenantModelStore.notify()
                    ]);

                return new CodeDashboardContext(
                    entityTypeEntitiesViewNameToCountMap,
                    summary!,
                    false);
            },
            [getSelectedScopeHasData, filteredGitTenantModels, tenantModelStore]);

    const getWidgetDatas =
        useCallback(
            (contextData: SummaryDashboardContext) => {
                const { analyzing = false } = contextData;

                const codeTenantTypeToTenantModelsMap =
                    _(filteredGitTenants).
                        filter(tenantModel => getSelectedScopeHasData([tenantModel])).
                        groupBy(tenantModel => tenantModel.configuration.codeTenantType).
                        value();
                const codeTenantTypeCount = _.size(codeTenantTypeToTenantModelsMap);

                const dashboardWidgetWidthMap = {
                    [DashboardWidgetType.CodeEntities]:
                        _.isEmpty(codeTenantTypeToTenantModelsMap) && !analyzing
                            ? [12]
                            : [6, 8, 10, 12],
                    [DashboardWidgetType.CodeTenants]:
                        [codeTenantTypeCount === 1
                            ? 2
                            : codeTenantTypeCount > 3
                                ? 6
                                : 4],
                    [DashboardWidgetType.CodeRisks]: [6],
                    [DashboardWidgetType.CodeTopRiskTenants]: [6]
                };

                const orderedDashboardWidgetDatas =
                    _<DashboardWidgetType>([]).
                        concatIf(
                            !_.isEmpty(codeTenantTypeToTenantModelsMap) || analyzing,
                            DashboardWidgetType.CodeTenants).
                        concat(
                            DashboardWidgetType.CodeEntities,
                            DashboardWidgetType.CodeRisks,
                            DashboardWidgetType.CodeTopRiskTenants).
                        map(
                            orderedDashboardWidgetType => ({
                                location: {
                                    height: dashboardWidgetMap[orderedDashboardWidgetType].h!,
                                    width: dashboardWidgetMap[orderedDashboardWidgetType].w!
                                },
                                type: orderedDashboardWidgetType
                            })).
                        value();

                return compactLayout(
                    orderedDashboardWidgetDatas,
                    dashboardWidgetWidthMap);
            },
            [getSelectedScopeHasData, filteredGitTenants]);

    const localization =
        useLocalization(
            "views.customer.code.dashboard.core",
            () => ({
                title: "IaC Dashboard"
            }));

    const configuration =
        useMemo<DashboardConfiguration>(
            () => ({
                editable: FeatureHelper.enabled(Contract.FeatureName.DashboardEditEnabled),
                fetchDashboardContextData,
                title: localization.title(),
                widgetDatasOrGetWidgetDatas: getWidgetDatas
            }),
            [fetchDashboardContextData, getWidgetDatas, localization]);

    return (
        <DashboardCore
            className="codeDashboard"
            configuration={configuration}/>);
}

function getAnalyzingDashboardContext() {
    return new CodeDashboardContext(
        {
            [Contract.TypeNames.AwsS3Bucket]: 1000,
            [Contract.TypeNames.AwsSqsQueue]: 500,
            [Contract.TypeNames.AwsSnsTopic]: 1400,
            [Contract.TypeNames.AwsSecretsManagerSecret]: 2500,
            [Contract.TypeNames.AwsKmsKey]: 90,
            [Contract.TypeNames.AwsElbLoadBalancer]: 350,
            [Contract.TypeNames.AwsIamRole]: 1980,
            [Contract.TypeNames.AwsEc2Instance]: 2030
        },
        new Contract.CodeControllerGetDashboardSummaryResponseSummary(
            {
                [Contract.TypeNames.AwsS3Bucket]: 10,
                [Contract.TypeNames.AwsSqsQueue]: 50,
                [Contract.TypeNames.AwsSnsTopic]: 140,
                [Contract.TypeNames.AwsSecretsManagerSecret]: 250,
                [Contract.TypeNames.AwsKmsKey]: 9,
                [Contract.TypeNames.AwsElbLoadBalancer]: 35,
                [Contract.TypeNames.AwsIamRole]: 198,
                [Contract.TypeNames.AwsEc2Instance]: 203
            },
            {},
            {
                [Contract.Severity.Critical]: {
                    [Contract.RiskPolicyCategory.Access]: 80,
                    [Contract.RiskPolicyCategory.Compute]: 50
                },
                [Contract.Severity.High]: {
                    [Contract.RiskPolicyCategory.Network]: 40,
                    [Contract.RiskPolicyCategory.Secrets]: 20
                }
            }),
        true);
}