import { map } from "@infrastructure";
import React from "react";
import { Contract, DefaultPermitterResourceIcon } from "../../../../common";
import { KeyVaultVaultIcon, StorageStorageAccountBlobContainerIcon, StorageStorageAccountIcon } from "./icons";

export type AzurePermitterIconProps = {
    permitterType: Contract.AzurePermitterType;
};

export function AzurePermitterIcon({ permitterType }: AzurePermitterIconProps) {
    return map(
        permitterType,
        {
            [Contract.AzurePermitterType.AzureKeyVaultVaultAccessPolicy]: () => <KeyVaultVaultIcon/>,
            [Contract.AzurePermitterType.AzureStorageStorageAccountAccessKey]: () => <StorageStorageAccountIcon/>,
            [Contract.AzurePermitterType.AzureStorageStorageAccountBlobContainerPublicAccess]: () => <StorageStorageAccountBlobContainerIcon/>
        },
        () => <DefaultPermitterResourceIcon/>);
}