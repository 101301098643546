import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useAzureEventHubNamespaceStatusTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useAzureEventHubNamespaceStatusTranslator",
            () => ({
                [Contract.TypeNames.AzureEventHubNamespaceStatus]: {
                    [Contract.AzureEventHubNamespaceStatus.Activating]: "Activating",
                    [Contract.AzureEventHubNamespaceStatus.Active]: "Active",
                    [Contract.AzureEventHubNamespaceStatus.Disabled]: "Disabled",
                    [Contract.AzureEventHubNamespaceStatus.Failed]: "Failed",
                    [Contract.AzureEventHubNamespaceStatus.Removing]: "Removing"
                }
            }));
    return (status: Contract.AzureEventHubNamespaceStatus) =>
        localization[Contract.TypeNames.AzureEventHubNamespaceStatus][status]();
}