import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function SolvoIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <g clipPath="url(#clip0_58343_2233)">
                <path
                    d="M0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12Z"
                    fill="#543DE0"/>
                <path
                    d="M4.49245 8.94889L4.56675 10.2936C4.5951 10.6236 4.51908 10.9542 4.34925 11.2394C4.08322 11.4913 3.74349 11.6531 3.37884 11.7017C3.75038 11.7531 4.0929 11.9291 4.34925 12.2005C4.52348 12.5016 4.60283 12.8475 4.57711 13.1936L4.50281 14.4232C4.49136 14.62 4.54547 14.815 4.65681 14.9783C4.73499 15.0558 4.82862 15.1165 4.93173 15.1562C5.03483 15.196 5.14517 15.2141 5.25572 15.2094V16.4073C4.66779 16.438 4.0856 16.2796 3.59589 15.9558C3.42028 15.7785 3.28607 15.5652 3.20274 15.3309C3.11941 15.0967 3.08899 14.8471 3.11361 14.5999L3.18791 13.3284C3.18791 12.8171 2.79194 12.4601 2 12.2576V11.1654C2.79164 10.9834 3.18761 10.6541 3.18791 10.1776L3.11361 8.84316C3.08558 8.58937 3.11425 8.33259 3.1976 8.09103C3.28095 7.84946 3.41693 7.62901 3.59589 7.44535C4.0954 7.12667 4.68365 6.97226 5.27689 7.0041V8.20203C5.04985 8.20087 4.82983 8.27997 4.65636 8.42511C4.53694 8.57348 4.47826 8.76095 4.492 8.95023"
                    fill="white"/>
                <path
                    d="M8.48142 15.2817C7.9349 15.2826 7.41018 15.0518 7.02143 14.6394C6.82681 14.4368 6.67205 14.1942 6.56638 13.9261C6.46072 13.658 6.40628 13.3699 6.40628 13.0788C6.40628 12.7877 6.46072 12.4995 6.56638 12.2315C6.67205 11.9634 6.82681 11.7208 7.02143 11.5181C7.29883 11.2241 7.64786 11.02 8.02841 10.9293C8.05107 10.1789 8.34451 9.46735 8.84676 8.94491C9.349 8.42247 10.0208 8.13002 10.7202 8.12932C11.1629 8.12957 11.5987 8.24718 11.989 8.47173C12.3792 8.69628 12.7117 9.02081 12.9569 9.41648C13.2531 9.24732 13.5836 9.15905 13.919 9.15947C14.3912 9.16252 14.8481 9.33877 15.2141 9.65897C15.5801 9.97916 15.833 10.4241 15.931 10.9198C16.3271 11.0054 16.6911 11.2134 16.9785 11.5181C17.173 11.7208 17.3277 11.9635 17.4333 12.2315C17.539 12.4996 17.5934 12.7877 17.5934 13.0788C17.5934 13.3698 17.539 13.658 17.4333 13.926C17.3277 14.1941 17.173 14.4367 16.9785 14.6394C16.5899 15.0517 16.0653 15.2825 15.519 15.2817H8.48142Z"
                    fill="white"/>
                <path
                    d="M19.4975 14.4234L19.4231 13.1939C19.3975 12.8479 19.4769 12.5022 19.651 12.2013C19.9072 11.9298 20.2498 11.7538 20.6213 11.7025C20.2569 11.6546 19.9171 11.4934 19.651 11.2421C19.4812 10.957 19.4051 10.6266 19.4335 10.2968L19.5078 8.95171C19.5218 8.762 19.4631 8.57402 19.3435 8.4253C19.17 8.28024 18.95 8.20116 18.723 8.20223V7.00396C19.3162 6.97269 19.9042 7.12721 20.4038 7.44562C20.5828 7.62919 20.7188 7.84951 20.8022 8.09098C20.8856 8.33245 20.9144 8.58914 20.8865 8.84286L20.8122 10.1772C20.8122 10.6531 21.2082 10.9823 22 11.1649V12.2579C21.2082 12.461 20.8122 12.8179 20.8122 13.3286L20.8865 14.6C20.911 14.8472 20.8804 15.0967 20.797 15.331C20.7136 15.5652 20.5794 15.7785 20.4038 15.9558C19.9143 16.2798 19.332 16.4381 18.7441 16.4072V15.2099C18.8548 15.2146 18.9652 15.1966 19.0683 15.1568C19.1715 15.117 19.2652 15.0564 19.3435 14.9788C19.4547 14.8153 19.5089 14.6202 19.4975 14.4234Z"
                    fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0_58343_2233">
                    <rect
                        fill="white"
                        height="24"
                        width="24"/>
                </clipPath>
            </defs>
        </SvgIcon>);
}