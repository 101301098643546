import _ from "lodash";
import { useEffect, useState } from "react";
import { Optional, useExecuteOperation, useLocalization, useOrderedWizardContext } from "@infrastructure";
import { ConfigurationController, Contract } from "../../../../../../../../../../../../../../../common";
import { OrganizationMemberSelection } from "../../../../../../../components";
import { OrganizationMemberSelectionHelper } from "../../../../../../../utilities";
import { useAddOrEditContext, useSetAddOrEditContext } from "../AddOrEdit";

export function OrganizationMemberSelectionItem() {
    const addOrEditContext = useAddOrEditContext();
    const setAddOrEditContext = useSetAddOrEditContext();
    const { setError, setLoaded, setValid, useNextEffect } = useOrderedWizardContext();

    const [scopeNodeRootTreeItem] =
        useExecuteOperation(
            OrganizationMemberSelectionItem,
            async () => {
                const { node } = await ConfigurationController.getOrganizationMemberDatas(new Contract.ConfigurationControllerGetAwsOrganizationMemberDatasRequest(addOrEditContext.masterTenantModel!.configuration.id));
                return OrganizationMemberSelectionHelper.createTreeItem(node);
            });

    const [selectionType, setSelectionType] = useState(addOrEditContext.memberSelection?.type);
    const [selectedTenantIds, setSelectedTenantIds] =
        useState(
            () =>
                OrganizationMemberSelectionHelper.getSelectedTenantIds(
                    scopeNodeRootTreeItem.value,
                    addOrEditContext.memberSelection?.rawIds));

    useEffect(setLoaded, []);

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCloudProviderTenantOrganizationItems.aws.addOrEdit.organizationMemberSelectionItem",
            () => ({
                actions: {
                    save: {
                        error: {
                            add: "Failed to add organization",
                            edit: "Failed to save organization"
                        }
                    }
                },
                errors: {
                    empty: "Empty selection is not allowed",
                    excludeAll: "Excluding all {{name}} is not allowed"
                }
            }));

    useNextEffect(
        async () => {
            if (!_.isNil(selectionType) && _.isEmpty(selectedTenantIds)) {
                return localization.errors.empty();
            }

            const newMemberSelection: Optional<Contract.OrganizationMemberSelection> =
                _.isNil(selectionType)
                    ? undefined
                    : {
                        rawIds:
                            OrganizationMemberSelectionHelper.getSelectedScopeIds(
                                scopeNodeRootTreeItem.value,
                                selectedTenantIds),
                        type: selectionType
                    };

            try {
                const { organizationMemberTenantNames } =
                    await ConfigurationController.upsertAwsOrganization(
                        new Contract.ConfigurationControllerUpsertAwsOrganizationRequest(
                            _.isNil(addOrEditContext.organizationConfiguration) || addOrEditContext.enabled!,
                            addOrEditContext.folderEnabled!,
                            addOrEditContext.masterTenantModel!.configuration.id,
                            newMemberSelection,
                            addOrEditContext.name!,
                            addOrEditContext.permissionTypes!,
                            addOrEditContext.regionSystemNames,
                            addOrEditContext.roleName!,
                            addOrEditContext.trailNamePattern));

                setAddOrEditContext(
                    addOrEditContext => ({
                        ...addOrEditContext,
                        organizationMemberTenantNames
                    }));
            } catch {
                return _.isNil(addOrEditContext.organizationConfiguration)
                    ? localization.actions.save.error.add()
                    : localization.actions.save.error.edit();
            }
        },
        [selectedTenantIds, selectionType]);

    return (
        <OrganizationMemberSelection
            scopeNodeRootTreeItem={scopeNodeRootTreeItem}
            selectedTenantIds={selectedTenantIds}
            selectionType={selectionType}
            tenantType={Contract.TenantType.Aws}
            onError={
                error => {
                    setValid(_.isNil(error));
                    setError(error);
                }}
            onSelectedTenantIdsChanged={setSelectedTenantIds}
            onSelectionTypeChanged={setSelectionType}/>);
}