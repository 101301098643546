import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition, useGetCommonKubernetesClusterRiskContext } from "../../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../../common";

export function useKubernetesClusterEtcdPeerTlsCertificateNotExistRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.KubernetesClusterEtcdPeerTlsCertificateNotExistRisk;
    const clusterModel = entityModelStore.useGet(risk.entityId);

    const getCommonKubernetesClusterRiskContext = useGetCommonKubernetesClusterRiskContext(clusterModel);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.kubernetes.hooks.clusters.etcds.useKubernetesClusterEtcdPeerTlsCertificateNotExistRiskDefinition",
            () => ({
                description: "{{cluster}} etcd is missing appropriate --peer-cert-file and --peer-key-file arguments",
                resolutionSection: {
                    step1: "Edit the etcd configuration file or command-line parameters used to start the etcd service",
                    step2: "Ensure the **--peer-cert-file** argument points to a valid certificate file (e.g., **/path/to/peer-cert.pem**)",
                    step3: "Ensure the **--peer-key-file** argument points to a valid key file (e.g., **/path/to/peer-key.pem)**",
                    step4: "Restart the etcd service to apply the changes"
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            cluster:
                <Entity
                    entityIdOrModel={clusterModel}
                    entityTypeNameTranslatorOptions={{
                        includeServiceName: true,
                        variant: "title"
                    }}
                    variant="typeText"/>
        }),
        () => [
            localization.resolutionSection.step1(),
            localization.resolutionSection.step2(),
            localization.resolutionSection.step3(),
            localization.resolutionSection.step4()
        ],
        riskModel,
        () => {
            const clusterContextItems = getCommonKubernetesClusterRiskContext(clusterModel);

            return [
                clusterContextItems.generalInformation,
                clusterContextItems.virtualNetwork,
                clusterContextItems.sensitive,
                clusterContextItems.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        });
}