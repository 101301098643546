import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function CiFolderIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                d="M8.62775 0.690138C8.24787 0.251813 7.6964 0 7.11637 0H3.11852C1.95333 0 1.01059 1.1 1.01059 2.44444L1.00752 5.82532C1.00254 5.88288 1 5.94114 1 6V16C1 17.1046 1.89543 18 3 18H6.00002C6.00181 17.5312 6.1101 17.0876 6.30197 16.692C6.1085 16.293 6 15.8453 6 15.3721V11.4127C6 9.74026 7.35575 8.3844 9.02826 8.3844H12.9876C13.6196 8.3844 14.2063 8.57798 14.6918 8.90906L14.9198 8.51418C15.756 7.06577 17.608 6.56947 19.0565 7.40574L23 9.47522V4.88889C23 3.54444 22.0467 2.44444 20.8815 2.44444H11.0614C10.4814 2.44444 9.92991 2.19263 9.55003 1.75431L8.62775 0.690138Z"
                fill="#BAC5D3"/>
            <path
                d="M8.5 17.9722C8.5 17.615 8.81187 17.3254 9.19657 17.3254H13.376C13.7607 17.3254 14.0726 17.615 14.0726 17.9722V21.8532C14.0726 22.2104 13.7607 22.5 13.376 22.5H9.19657C8.81187 22.5 8.5 22.2104 8.5 21.8532V17.9722Z"
                fill="#249ED2"/>
            <path
                d="M15.4657 17.9722C15.4657 17.615 15.7776 17.3254 16.1623 17.3254H20.3417C20.7264 17.3254 21.0383 17.615 21.0383 17.9722V21.8532C21.0383 22.2104 20.7264 22.5 20.3417 22.5H16.1623C15.7776 22.5 15.4657 22.2104 15.4657 21.8532V17.9722Z"
                fill="#249ED2"/>
            <path
                d="M8.5 11.5039C8.5 11.1467 8.81187 10.8571 9.19657 10.8571H13.376C13.7607 10.8571 14.0726 11.1467 14.0726 11.5039V15.3849C14.0726 15.7421 13.7607 16.0317 13.376 16.0317H9.19657C8.81187 16.0317 8.5 15.7421 8.5 15.3849V11.5039Z"
                fill="#249ED2"/>
            <path
                d="M17.5806 9.82352C17.7729 9.51415 18.1989 9.40815 18.5321 9.58677L22.1516 11.5273C22.4848 11.7059 22.5989 12.1015 22.4066 12.4108L20.3169 15.7719C20.1245 16.0812 19.6985 16.1872 19.3653 16.0086L15.7458 14.0681C15.4127 13.8895 15.2985 13.4939 15.4909 13.1846L17.5806 9.82352Z"
                fill="#249ED2"/>
        </SvgIcon>);
}