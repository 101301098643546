﻿import { InlineItems, Link, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { AwsLambdaFunctionConfigurationHelper } from "../../../../../../../../../../../../tenants";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { InlineActions } from "../../components";
import { useGetAwsResourceRiskContext } from "./useGetAwsResourceRiskContext";

export function useGetAwsLambdaFunctionConfigurationRiskContext() {
    return useMemo(
        () => useAwsLambdaFunctionConfigurationRiskContext,
        []);
}

function useAwsLambdaFunctionConfigurationRiskContext(functionConfigurationModel: Contract.AwsLambdaFunctionConfigurationModel) {
    const functionConfiguration = functionConfigurationModel.entity as Contract.AwsLambdaFunctionConfiguration;
    const resourceRiskContext = useGetAwsResourceRiskContext()(functionConfigurationModel);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.contexts.useGetAwsLambdaFunctionConfigurationRiskContext",
            () => ({
                aliases: [
                    "1 alias",
                    "{{count | NumberFormatter.humanize}} aliases"
                ],
                aliasFunctionUrl: "The {{translatedFunctionConfigurationTypeName}} has {{aliases}} that allows anonymous invocation through {{urls}}",
                aliasResourcePolicyPermission: "The {{translatedFunctionConfigurationTypeName}} has {{aliases}} with resource policy that grants {{permissionActions}} to any principal",
                functionUrl: "The {{translatedFunctionConfigurationTypeName}} allows anonymous invocation through function URL {{urls}}",
                revisions: {
                    latest: "Latest",
                    text: [
                        "1 revision",
                        "{{count | NumberFormatter.humanize}} revisions"
                    ]
                },
                revisionsPrincipalPermission: "The {{translatedFunctionConfigurationTypeName}} has {{revisions}} with resource policy that grants {{permissionActions}} to any principal",
                urls: [
                    "1 function URL",
                    "{{count | NumberFormatter.humanize}} function URLs"
                ]
            }));
    const translatedFunctionConfigurationTypeName =
        entityTypeNameTranslator(
            functionConfiguration.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });
    const [aliasNames, aliasNameToPublicAccessUrlMap, aliasPolicyDocumentPublicAccessPermissionActions, revisionIds, revisionPolicyDocumentPublicAccessPermissionActions] =
        useMemo(
            () => {
                const aliasNames = _.keys(functionConfiguration.aliasNameToPolicyDocumentPublicAccessPermissionActionsMap);
                const aliasNameToPublicAccessUrlMap =
                    _(functionConfiguration.aliasMap).
                        pickBy(alias => alias.urlAccessLevel === Contract.AwsResourceAccessLevel.Public).
                        mapValues(alias => alias.url!.url).
                        value();
                const aliasPolicyDocumentPublicAccessPermissionActions =
                    _(functionConfiguration.aliasNameToPolicyDocumentPublicAccessPermissionActionsMap).
                        values().
                        flatMap().
                        uniq().
                        value();
                const revisionIds =
                    _.keys(functionConfiguration.revisionIdToPolicyDocumentPublicAccessPermissionActionsMap).
                        map(revisionId =>
                            revisionId === AwsLambdaFunctionConfigurationHelper.latest
                                ? localization.revisions.latest()
                                : revisionId);
                const revisionPolicyDocumentPublicAccessPermissionActions =
                    _(functionConfiguration.revisionIdToPolicyDocumentPublicAccessPermissionActionsMap).
                        values().
                        flatMap().
                        uniq().
                        value();
                return [aliasNames, aliasNameToPublicAccessUrlMap, aliasPolicyDocumentPublicAccessPermissionActions, revisionIds, revisionPolicyDocumentPublicAccessPermissionActions];
            },
            [functionConfigurationModel]);

    return {
        ...resourceRiskContext,
        aliasFunctionUrl:
            !_.isEmpty(aliasNameToPublicAccessUrlMap)
                ? new RiskDefinitionContextItem(
                    localization.aliasFunctionUrl({
                        aliases:
                            <InlineItems
                                items={_.keys(aliasNameToPublicAccessUrlMap)}
                                namePluralizer={localization.aliases}
                                variant="itemCountAndType"/>,
                        translatedFunctionConfigurationTypeName,
                        urls:
                            <InlineItems
                                items={
                                    _.map(
                                        _.values(aliasNameToPublicAccessUrlMap),
                                        url => <Link urlOrGetUrl={url}/>)}
                                namePluralizer={localization.urls}
                                variant="itemOrItemCountAndType"/>
                    }))
                : undefined,
        aliasResourcePolicyPermission:
            !_.isEmpty(functionConfiguration.aliasNameToPolicyDocumentPublicAccessPermissionActionsMap)
                ? new RiskDefinitionContextItem(
                    localization.aliasResourcePolicyPermission({
                        aliases:
                            <InlineItems
                                items={aliasNames}
                                namePluralizer={localization.aliases}
                                variant="itemCountAndType"/>,
                        permissionActions:
                            <InlineActions actions={aliasPolicyDocumentPublicAccessPermissionActions}/>,
                        translatedFunctionConfigurationTypeName
                    }))
                : undefined,
        functionUrl:
            functionConfiguration.urlAccessLevel === Contract.AwsResourceAccessLevel.Public
                ? new RiskDefinitionContextItem(
                    localization.functionUrl({
                        translatedFunctionConfigurationTypeName,
                        urls:
                            <InlineItems
                                items={<Link urlOrGetUrl={functionConfiguration.url!.url}/>}
                                namePluralizer={localization.urls}
                                variant="itemOrItemCountAndType"/>
                    }))
                : undefined,
        publicAccessPermissionActions:
            resourceRiskContext.getPublicAccessPermissionActions(
                functionConfiguration.publicAccessHighSeveritySensitivePermissionActions,
                functionConfiguration.publicAccessPermissionActions),
        revisionsPrincipalPermission:
            !_.isEmpty(functionConfiguration.revisionIdToPolicyDocumentPublicAccessPermissionActionsMap)
                ? new RiskDefinitionContextItem(
                    localization.revisionsPrincipalPermission({
                        permissionActions:
                            <InlineActions actions={revisionPolicyDocumentPublicAccessPermissionActions}/>,
                        revisions:
                            <InlineItems
                                items={revisionIds}
                                namePluralizer={localization.revisions.text}
                                variant="itemCountAndType"/>,
                        translatedFunctionConfigurationTypeName
                    }))
                : undefined
    };
}