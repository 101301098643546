import { Dropdown, Link, Shadows } from "@infrastructure";
import { Box, Stack, Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React from "react";
import { Contract } from "../../../../../../../../../../controllers";
import { useRiskCombinationTranslator } from "../../../../../../../../../../hooks";
import { useTheme } from "../../../../../../../../../../themes";
import { EntityTypeNames, RiskPolicyCategories } from "./components";
import { RiskCombinationHelper } from "./utilities";

type RiskCombinationProps = {
    entityTypeNameToCountMap: Dictionary<number>;
    orderedRiskPolicyCategories: Contract.RiskPolicyCategory[];
    riskCombination: Contract.RiskCombination;
};

export function RiskCombination({ entityTypeNameToCountMap, orderedRiskPolicyCategories, riskCombination }: RiskCombinationProps) {
    const riskCombinationTranslator = useRiskCombinationTranslator();
    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            className="riskCombination"
            direction="row">
            <RiskPolicyCategories orderedRiskPolicyCategories={orderedRiskPolicyCategories}/>
            <Box
                sx={{
                    "&:hover": {
                        background: theme.palette.background.alternate,
                        border: theme.border.alternate,
                        borderRadius: theme.spacing(0.75),
                        boxShadow: theme.shadows[Shadows.Highlight],
                        zIndex: 102
                    },
                    background: theme.palette.background.paper,
                    border: "solid 1px transparent",
                    flex: 1,
                    height: theme.spacing(6.5),
                    marginLeft: theme.spacing(-0.5)
                }}>
                <Box
                    sx={{
                        alignItems: "center",
                        display: "flex",
                        height: "100%"
                    }}>
                    <Dropdown
                        disabled={_.size(entityTypeNameToCountMap) === 1}
                        popoverElement={
                            <EntityTypeNames
                                entityTypeNameToCountMap={entityTypeNameToCountMap}
                                riskCombination={riskCombination}/>}>
                        <Link
                            disabled={_.size(entityTypeNameToCountMap) !== 1}
                            sx={{ color: theme.palette.text.primary }}
                            urlOrGetUrl={RiskCombinationHelper.getEntityTypeNameRelativeUrl(_.keys(entityTypeNameToCountMap)[0], riskCombination)}
                            variant="text">
                            <Typography
                                sx={{
                                    fontSize: "13px",
                                    paddingLeft: theme.spacing(1.5)
                                }}>
                                {riskCombinationTranslator(
                                    riskCombination,
                                    _(entityTypeNameToCountMap).
                                        values().
                                        sum())}
                            </Typography>
                        </Link>
                    </Dropdown>
                </Box>
            </Box>
        </Stack>);
}