import { AnalyticsEventActionType, ArrowBackIcon, formatQueryParameters, useLocalization, useSetRoute, useTrackAnalytics } from "@infrastructure";
import { Button, Divider, Typography } from "@mui/material";
import _ from "lodash";
import React, { Fragment, useCallback } from "react";
import { useTheme } from "../../../../../common";
import { useSetSideViewContext, useSideViewContext } from "../SideView";

export function BackButton() {
    const { items } = useSideViewContext();
    const setSideViewContext = useSetSideViewContext();
    const setRoute = useSetRoute();
    const trackAnalytics = useTrackAnalytics();
    const localization =
        useLocalization(
            "views.customer.sideView.backButton",
            () => ({
                back: "Back"
            }));

    const navigate =
        useCallback(
            () => {
                const newItems = _.dropRight(items, 1);
                const lastItem = _.last(newItems)!;
                // eslint-disable-next-line @stylistic/multiline-ternary
                setRoute(`${lastItem.type}/${encodeURIComponent(lastItem.id)}${lastItem.category ? `/${lastItem.category}` : ""}${lastItem.view ? `/${lastItem.view}` : ""}${lastItem.additionalParts ? `/${_.join(lastItem.additionalParts, "/")}` : ""}${lastItem.queryParameters ? `?${formatQueryParameters(lastItem.queryParameters)}` : ""}`);
                setSideViewContext(
                    context => ({
                        ...context,
                        items: newItems
                    }));

                trackAnalytics(AnalyticsEventActionType.SideViewNavigate);
            },
            [items]);

    const theme = useTheme();
    return (
        <Fragment>
            <Divider
                flexItem={true}
                orientation="vertical"
                sx={{
                    alignSelf: "center",
                    borderColor: theme.palette.borders.hoverFocus,
                    height: theme.spacing(1.75)
                }}/>
            <Button
                size="small"
                sx={{
                    minHeight: "unset",
                    padding: 0
                }}
                variant="text"
                onClick={navigate}>
                <ArrowBackIcon sx={{ fontSize: "12px" }}/>
                <Typography>
                    {localization.back()}
                </Typography>
            </Button>
        </Fragment>);
}