import { SxProps, Typography } from "@mui/material";
import { TOptions } from "i18next";
import React from "react";
import { Contract } from "../../../../..";
import { InlineActionsSelection, InlineEntitySelection } from "../..";

type ViewProps = {
    riskPolicyConfiguration?: Contract.AwsPrincipalAllowedPermissionRiskPolicyConfiguration;
    sx?: SxProps;
    templateTranslator: (options?: TOptions) => string;
};

export function View({ riskPolicyConfiguration, sx, templateTranslator }: ViewProps) {
    return (
        <Typography
            component="span"
            sx={sx}>
            {templateTranslator({
                actions:
                    <InlineActionsSelection
                        selection={riskPolicyConfiguration}
                        sx={sx}/>,
                principals:
                    <InlineEntitySelection
                        entityTypeName={Contract.TypeNames.AwsIamPrincipal}
                        selection={{
                            entityBuiltInAttributeTypeNames: riskPolicyConfiguration?.principalBuiltInAttributeTypeNames,
                            entityCustomAttributeDefinitionIds: riskPolicyConfiguration?.principalCustomAttributeDefinitionIds,
                            entityIds: riskPolicyConfiguration?.principalIds,
                            entityTags: riskPolicyConfiguration?.principalTags
                        }}
                        sx={sx}/>
            })}
        </Typography>);
}