import { useLocalization } from "@infrastructure";
import { useCallback } from "react";
import { Contract } from "../../../../../common";

export function useOperatingSystemTypeTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useOperatingSystemTypeTranslator",
            () => ({
                [Contract.TypeNames.OperatingSystemType]: {
                    [Contract.OperatingSystemType.Linux]: "Linux",
                    [Contract.OperatingSystemType.Windows]: "Windows"
                }
            }));
    return useCallback(
        (operatingSystemType: Contract.OperatingSystemType) =>
            localization[Contract.TypeNames.OperatingSystemType][operatingSystemType](),
        [localization]);
}