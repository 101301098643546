import { AddIcon, DataTable, DataTableAction, DataTableActions, DataTableColumn, DataTableColumnRenderProps, DataTableSortDirection, EmptyMessageText, StringHelper, useChangeEffect, useLocalization } from "@infrastructure";
import { Button } from "@mui/material";
import _ from "lodash";
import React, { useRef } from "react";
import { GcpContext, useSetGcpContext } from "../..";
import { Contract, scopeSystemEntityModelStore, Tenant } from "../../../../../../../../../../../../../../common";
import { StatusCell } from "../../../StatusCell";
import { ActionsCell } from "./components";

export function Table() {
    const organizationModels = scopeSystemEntityModelStore.useGetGcpOrganization();
    const setGcpContext = useSetGcpContext();

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCloudProviderTenantOrganizationItems.gcp.table",
            () => ({
                actions: {
                    add: "Add GCP Organization"
                },
                columns: {
                    gciTenant: "Google Workspace",
                    name: "Name",
                    status: "Status"
                },
                empty: "No GCP organizations"
            }));

    const dataTableActionsRef = useRef<DataTableActions>();
    useChangeEffect(
        () => {
            dataTableActionsRef.current!.reset();
        },
        [organizationModels]);

    return (
        <DataTable
            actionsRef={dataTableActionsRef}
            emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty()) }}
            fetchItems={
                (_filterMap, sort) =>
                    _.orderBy(
                        organizationModels,
                        organizationModel => StringHelper.getSortValue((organizationModel.configuration as Contract.GcpOrganizationConfiguration).name),
                        sort?.direction === DataTableSortDirection.Descending
                            ? "desc"
                            : "asc")}
            getItemId={(item: Contract.ScopeSystemEntityModel) => item.configuration.id}>
            <DataTableColumn
                id={TableColumnId.Name}
                itemProperty={(item: Contract.ScopeSystemEntityModel) => (item.configuration as Contract.GcpOrganizationConfiguration).name}
                title={localization.columns.name()}/>
            <DataTableColumn
                id={TableColumnId.GciTenant}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.ScopeSystemEntityModel>) =>
                        <Tenant
                            tenantId={(item.configuration as Contract.GcpOrganizationConfiguration).gciTenantId}
                            tenantNameTranslatorOptions={{ includeRawId: true }}
                            variant="iconText"/>}
                sortOptions={{ enabled: false }}
                title={localization.columns.gciTenant()}/>
            <DataTableColumn
                id={TableColumnId.Status}
                render={StatusCell}
                sortOptions={{ enabled: false }}
                title={localization.columns.status()}/>
            <DataTableColumn
                id={TableColumnId.Actions}
                render={ActionsCell}/>
            <DataTableAction>
                <Button
                    size="small"
                    startIcon={<AddIcon/>}
                    onClick={() => setGcpContext(new GcpContext(true))}>
                    {localization.actions.add()}
                </Button>
            </DataTableAction>
        </DataTable>);
}

enum TableColumnId {
    Actions = "actions",
    GciTenant = "gciTenant",
    Name = "name",
    Status = "status"
}