import { EnumValuesFilter } from "@infrastructure";
import React from "react";
import { Contract } from "../../../common";
import { useAwsResourceAccessLevelTranslator } from "../hooks";

type AwsResourceAccessLevelFilterProps = {
    placeholder: string;
};

export function AwsResourceAccessLevelFilter({ placeholder }: AwsResourceAccessLevelFilterProps) {
    const resourceAccessLevelTranslator = useAwsResourceAccessLevelTranslator();
    return (
        <EnumValuesFilter
            enumType={Contract.AwsResourceAccessLevel}
            enumTypeTranslator={resourceAccessLevelTranslator}
            placeholder={placeholder}
            sorted={false}/>);
}