import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { useGetAwsIamUserRiskContext } from "../contexts";

export function useAwsIamUserMultipleEnabledAccessKeyRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsIamUserMultipleEnabledAccessKeyRisk;
    const userModel = entityModelStore.useGet(risk.entityId) as Contract.AwsIamUserModel;

    const getAwsIamUserRiskContext = useGetAwsIamUserRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsIamUserMultipleEnabledAccessKeyRiskDefinition",
            () => ({
                description: "{{user}} has multiple active access keys",
                sections: {
                    resolution: {
                        step1: "Before deleting the access key, make sure that your applications are using the second access key",
                        step2: "Under **Access keys** section, identify the redundant key and click **Actions** and then **Deactivate** or **Delete**"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            user:
                <Entity
                    entityIdOrModel={userModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.Iam,
                AwsConsoleUrlBuilder.getIamUserSecurityCredentialsUrl(userModel.entity as Contract.AwsIamUser)),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2()
        ],
        riskModel,
        () => {
            const userRiskContext = getAwsIamUserRiskContext(userModel);
            return [
                userRiskContext.generalInformation,
                userRiskContext.permissionActionSeverity,
                ...userRiskContext.accessKeys,
                userRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}