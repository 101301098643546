import { Steps, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract } from "../../../../../../../../../../../../../../common";

export type ContextSectionProps = {
    risk: Contract.AwsIamRootUserActivityRisk;
};

export function ContextSection({ risk }: ContextSectionProps) {
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsIamRootUserActivityRiskDefinition.contextSection",
            () => ({
                reopened: "This finding was reopened because additional root user activity was detected on the same day of, or on consecutive days after, the initial activity"
            }));

    return (
        <Steps variant="bullets">
            {
                _.filter([
                    risk.reopened
                        ? localization.reopened()
                        : undefined
                ])}
        </Steps>);
}