import { ValuesFilter, ValuesFilterItem } from "@infrastructure";
import _ from "lodash";
import React, { memo, useEffect } from "react";
import { Entity, EntityVariant } from ".";
import { Contract } from "../../common";
import { entityModelStore, entitySearchableReferenceStore } from "../stores";

type EntityFilterProps = {
    emptyValue?: boolean;
    entityIdsOrSearchableReferences: string[] | Contract.EntitySearchableIdReference[] | Contract.EntitySearchableReference[];
    entityVariant?: EntityVariant;
    placeholder: string;
};

const EntityFilterMemo = memo(EntityFilter);
export { EntityFilterMemo as EntityFilter };

function EntityFilter({ emptyValue, entityIdsOrSearchableReferences, entityVariant = "iconTextTypeTenant", placeholder }: EntityFilterProps) {
    const entitySearchableReferences =
        entitySearchableReferenceStore.useGet(
            _.isString(entityIdsOrSearchableReferences[0])
                ? entityIdsOrSearchableReferences as string[]
                : undefined) ??
        (_.isString((entityIdsOrSearchableReferences[0] as Contract.EntitySearchableReference)?.id)
            ? entityIdsOrSearchableReferences as Contract.EntitySearchableReference[]
            : _.map(
                entityIdsOrSearchableReferences as Contract.EntitySearchableIdReference[],
                entitySearchableIdReference =>
                    new Contract.EntitySearchableReference(
                        entitySearchableIdReference.displayName,
                        entitySearchableIdReference.idReference)));

    useEffect(
        () => {
            if (!_.isString(entityIdsOrSearchableReferences[0])) {
                entitySearchableReferenceStore.notify(entitySearchableReferences);
            }
        },
        [entityIdsOrSearchableReferences, entitySearchableReferences]);

    return (
        <ValuesFilter
            emptyValueOptions={{
                enabled: emptyValue,
                variant: "iconText"
            }}
            placeholder={placeholder}
            onValuePageLoading={
                async (entityIds: string[]) => {
                    await entityModelStore.get(_.filter(entityIds));
                }}>
            {_.map(
                entitySearchableReferences,
                entitySearchableReference =>
                    <ValuesFilterItem
                        key={entitySearchableReference.id}
                        title={entitySearchableReference.displayName}
                        value={entitySearchableReference.id}>
                        {() =>
                            <Entity
                                entityIdOrModel={entitySearchableReference.id}
                                linkOptions={{ disabled: true }}
                                variant={entityVariant}/>}
                    </ValuesFilterItem>)}
        </ValuesFilter>);
}