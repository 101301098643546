import { useLocalization } from "@infrastructure";
import React from "react";
import { useOciDefaultResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { useCustomEntityPropertyInfoItemElements } from "..";
import { Contract, InfoCard, InfoItem, useEntityTypeNameTranslator } from "../../../../../../../../../../common";
import { OciNetworkingSecurityListRulesTable } from "../../../../../../../../../../tenants";
import { useOciNetworkingSecurityListStatusTranslator } from "../../../../../../hooks";
import { EntitiesInfoItem, Info } from "../../../../components";
import { OciNetworkingSubnetsInfoCard } from "../../components";

export function useOciNetworkingSecurityListDefinition(resourceModel: Contract.OciResourceModel, options?: DefinitionOptions) {
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useOciDefaultResourceInfoItemElements(resourceModel);
    const securityListModel = resourceModel as Contract.OciNetworkingSecurityListModel;
    const securityList = securityListModel.entity as Contract.OciNetworkingSecurityList;

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const securityListStatusTranslator = useOciNetworkingSecurityListStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.oci.useOciNetworkingSecurityListDefinition",
            () => ({
                info: {
                    cards: {
                        inboundRules: "Inbound Rules",
                        outboundRules: "Outbound Rules"
                    },
                    items: {
                        status: "State"
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="status"
                        title={localization.info.items.status()}
                        value={securityListStatusTranslator(securityList.status)}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={securityListModel.vcnIdReference}
                        entityTypeName={Contract.TypeNames.OciNetworkingVcn}
                        key="vcn"
                        title={entityTypeNameTranslator(Contract.TypeNames.OciNetworkingVcn)}/>
                ]}
                options={options?.infoOptions}>
                <OciNetworkingSubnetsInfoCard subnetIds={securityListModel.subnetIds}/>
                <InfoCard title={localization.info.cards.inboundRules()}>
                    <OciNetworkingSecurityListRulesTable
                        inbound={true}
                        rules={securityList.inboundRules}/>
                </InfoCard>
                <InfoCard title={localization.info.cards.outboundRules()}>
                    <OciNetworkingSecurityListRulesTable
                        inbound={false}
                        rules={securityList.outboundRules}/>
                </InfoCard>
            </Info>
    });
}