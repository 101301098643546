import { DataTableColumn, DataTableColumnRenderProps, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import { Dictionary } from "lodash";
import React from "react";
import { useOciCommonResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntityTypeNameTranslator } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";

export function useOciAutoscalingAutoscalingConfigurationDefinition(definitionData: DefinitionData) {
    const commonResourceDefinition = useOciCommonResourceDefinition(definitionData);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.oci.useOciAutoscalingAutoscalingConfigurationDefinition",
            () => ({
                columns: {
                    enabled: {
                        false: "Disabled",
                        title: "Status",
                        true: "Enabled"
                    }
                }
            }));

    function translateEnabled(ResourceModel: Contract.OciIamResourceModel) {
        return (ResourceModel.entity as Contract.OciIamResource).enabled
            ? localization.columns.enabled.true()
            : localization.columns.enabled.false();
    }

    return new EntityTableDefinition(
        [
            commonResourceDefinition.columns.tenantColumn,
            commonResourceDefinition.columns.creationTimeColumn,
            commonResourceDefinition.columns.creatorCsvColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.OciIamResourceModel) => ({
                            [localization.columns.enabled.title()]: translateEnabled(item)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <ValuesFilter
                                placeholder={localization.columns.enabled.title()}
                                sorted={false}>
                                <ValuesFilterItem
                                    title={localization.columns.enabled.true()}
                                    value={true}/>
                                <ValuesFilterItem
                                    title={localization.columns.enabled.false()}
                                    value={false}/>
                            </ValuesFilter>
                    }
                }}
                id={Contract.EntityModelProperty.OciAutoscalingAutoscalingConfigurationEnabled}
                itemProperty={translateEnabled}
                key={Contract.EntityModelProperty.OciAutoscalingAutoscalingConfigurationEnabled}
                title={localization.columns.enabled.title()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.OciAutoscalingAutoscalingConfigurationModel) => ({
                            [entityTypeNameTranslator(Contract.TypeNames.OciComputeInstancePool)]: (item.entity as Contract.OciAutoscalingAutoscalingConfiguration).instancePoolOcid
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.OciAutoscalingAutoscalingConfigurationInstancePool)}
                                placeholder={entityTypeNameTranslator(Contract.TypeNames.OciComputeInstancePool)}/>
                    }
                }}
                id={Contract.EntityModelProperty.OciAutoscalingAutoscalingConfigurationInstancePool}
                key={Contract.EntityModelProperty.OciAutoscalingAutoscalingConfigurationInstancePool}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.OciAutoscalingAutoscalingConfigurationModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.instancePoolIdReference}
                            entityTypeName={Contract.TypeNames.OciComputeInstancePool}/>}
                title={entityTypeNameTranslator(Contract.TypeNames.OciComputeInstancePool)}/>,
            commonResourceDefinition.columns.regionColumn,
            commonResourceDefinition.columns.regionLocationColumn,
            commonResourceDefinition.columns.tagsColumn,
            commonResourceDefinition.columns.attributesColumn,
            commonResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageOciAutoscalingAutoscalingConfigurationRequest(
                new Contract.EntityControllerGetEntityModelPageOciAutoscalingAutoscalingConfigurationRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciAutoscalingAutoscalingConfigurationEnabled]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciAutoscalingAutoscalingConfigurationInstancePool])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags]))));
}