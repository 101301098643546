import _ from "lodash";
import React from "react";
import { DialogMenuItem, TimeSpanHelper, useLocalization } from "@infrastructure";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../../..";
import { useCommonEntityNetworkInfoItemElements, useCustomEntityPropertyInfoItemElements, useGetDataAnalysisResourcePriorityScanRequestDialogMenuItem } from "../..";
import { useAzureCommonEncryptedResourceInfoElements, useAzureCommonManagedIdentityOriginatorResourceInfoElements, useAzureDefaultResourceInfoItemElements } from "..";
import { Contract, FeatureHelper, InfoItem } from "../../../../../../../../../../../common";
import { useAzureSqlServerAuthenticationTypeTranslator, useAzureSqlServerStatusTranslator } from "../../../../../../../hooks";
import { EntitiesInfoItem, Info } from "../../../../../components";
import { AzureFirewallInfoCard, AzureResourceRoleAssignmentResources, NetworkingInfoCard } from "../../../components";
import { useDefaultDataAnalysisResourceInfoItemElements } from "../../useDefaultDataAnalysisResourceInfoItemElements";
import { DatabasesInfoCard } from "./components";

export function useAzureSqlServerDefinition(resourceGroupResourceModel: Contract.AzureResourceGroupResourceModel, options?: DefinitionOptions) {
    const commonEncryptedResourceInfoElements = useAzureCommonEncryptedResourceInfoElements(resourceGroupResourceModel);
    const commonEntityNetworkInfoItemElements = useCommonEntityNetworkInfoItemElements(resourceGroupResourceModel);
    const commonManagedIdentityOriginatorResourceInfoElements = useAzureCommonManagedIdentityOriginatorResourceInfoElements(resourceGroupResourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceGroupResourceModel);
    const defaultDataAnalysisResourceInfoItemElements = useDefaultDataAnalysisResourceInfoItemElements(resourceGroupResourceModel);
    const defaultResourceInfoItemElements = useAzureDefaultResourceInfoItemElements(resourceGroupResourceModel);
    const server = resourceGroupResourceModel.entity as Contract.AzureSqlServer;
    const serverModel = resourceGroupResourceModel as Contract.AzureSqlServerModel;

    const sqlServerAuthenticationTypeTranslator = useAzureSqlServerAuthenticationTypeTranslator();
    const sqlServerStatusTranslator = useAzureSqlServerStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.azure.useAzureSqlServerDefinition",
            () => ({
                info: {
                    items: {
                        aadAuthenticationAdministratorPrincipalIdReference: "Microsoft Entra ID Admin",
                        adminUserName: "Server Admin",
                        allowAnyAzureInternalIpAddress: {
                            false: "Disabled",
                            title: "Allow Azure Services Access",
                            true: "Enabled"
                        },
                        authenticationType: "Authentication Method",
                        eventEnabled: {
                            false: "Disabled",
                            title: "Auditing",
                            true: "Enabled"
                        },
                        eventRetentionTimeFrame: {
                            indefinite: "Indefinite",
                            text: "{{eventRetentionTimeFrame | TimeSpanFormatter.humanizeDays}}",
                            title: "Audit Retention"
                        },
                        publicNetworkAccess: {
                            false: "Enabled",
                            title: "Deny Public Network Access",
                            true: "Disabled"
                        },
                        serverName: "Server",
                        status: "Status"
                    }
                },
                tabs: {
                    resourceRoleAssignments: "Resource Role Assignments"
                }
            }));

    const getDataAnalysisResourcePriorityScanRequestDialogMenuItem =
        useGetDataAnalysisResourcePriorityScanRequestDialogMenuItem(
            resourceGroupResourceModel as Contract.AzureSqlServerModel,
            dataAnalysis => dataAnalysis.managedDatabaseScanEnabled);
    const menuItems = [getDataAnalysisResourcePriorityScanRequestDialogMenuItem()];

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <AzureResourceRoleAssignmentResources
                    csvExportFilePrefix={localization.tabs.resourceRoleAssignments()}
                    scopeResourceModel={resourceGroupResourceModel}/>,
                localization.tabs.resourceRoleAssignments(),
                "resourceRoleAssignments")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    ...commonManagedIdentityOriginatorResourceInfoElements,
                    ...commonEncryptedResourceInfoElements,
                    <InfoItem
                        key="status"
                        title={localization.info.items.status()}
                        value={sqlServerStatusTranslator(server.status)}/>,
                    <InfoItem
                        key="serverName"
                        title={localization.info.items.serverName()}
                        value={server.endpoint}/>,
                    <InfoItem
                        key="adminUserName"
                        title={localization.info.items.adminUserName()}
                        value={server.administratorUserName}/>,
                    <InfoItem
                        key="authenticationType"
                        title={localization.info.items.authenticationType()}
                        value={sqlServerAuthenticationTypeTranslator(server.authenticationType)}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={server.aadAuthenticationAdministratorPrincipalIdReference}
                        entityTypeName={Contract.TypeNames.AadDirectoryPrincipal}
                        key="aadAuthenticationAdministratorPrincipalIdReference"
                        title={localization.info.items.aadAuthenticationAdministratorPrincipalIdReference()}/>,
                    ...(FeatureHelper.enabled(Contract.FeatureName.AzureSqlServerAnalysisEnabled)
                        ? defaultDataAnalysisResourceInfoItemElements
                        : []),
                    <InfoItem
                        key="publicNetworkAccess"
                        title={localization.info.items.publicNetworkAccess.title()}
                        value={
                            server.publicNetworkAccess
                                ? localization.info.items.publicNetworkAccess.true()
                                : localization.info.items.publicNetworkAccess.false()}/>,
                    <InfoItem
                        key="allowAnyAzureInternalIpAddress"
                        title={localization.info.items.allowAnyAzureInternalIpAddress.title()}
                        value={
                            _.isNil(server.publicNetworkAccess)
                                ? undefined
                                : server.allowAnyAzureInternalIpAddress
                                    ? localization.info.items.allowAnyAzureInternalIpAddress.true()
                                    : localization.info.items.allowAnyAzureInternalIpAddress.false()}/>,
                    <InfoItem
                        key="eventEnabled"
                        title={localization.info.items.eventEnabled.title()}
                        value={
                            server.eventEnabled
                                ? localization.info.items.eventEnabled.true()
                                : localization.info.items.eventEnabled.false()}/>,
                    <InfoItem
                        key="retentionDays"
                        title={localization.info.items.eventRetentionTimeFrame.title()}
                        value={
                            _.isNil(server.eventRetentionTimeFrame)
                                ? undefined
                                : TimeSpanHelper.getDays(server.eventRetentionTimeFrame) === 0
                                    ? localization.info.items.eventRetentionTimeFrame.indefinite()
                                    : localization.info.items.eventRetentionTimeFrame.text({ eventRetentionTimeFrame: server.eventRetentionTimeFrame })
                        }/>
                ]}
                options={options?.infoOptions}>
                <NetworkingInfoCard>
                    {commonEntityNetworkInfoItemElements.inboundAccessType}
                    {commonEntityNetworkInfoItemElements.inboundExternalAccessScope}
                </NetworkingInfoCard>
                {server.publicNetworkAccess &&
                    <AzureFirewallInfoCard
                        networkResourcePathToIdReferencesMap={serverModel.networkResourcePathToIdReferencesMap}
                        rules={server.firewallIpAddressRangeRules}
                        virtualNetworkPathToFirewallSubnetRulesMap={server.virtualNetworkPathToFirewallSubnetRulesMap}/>}
                <DatabasesInfoCard databaseIds={server.databaseIds}/>
            </Info>,
        topbarMenuItems:
            FeatureHelper.enabled(Contract.FeatureName.AzureSqlServerAnalysisEnabled)
                ? _.filter(menuItems) as DialogMenuItem[]
                : undefined
    });
}