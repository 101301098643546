import { DataTable, DataTableActions, DataTableColumn, DataTableColumnRenderProps, EmptyMessageText, useChangeEffect, useLocalization } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React, { useRef } from "react";
import { Contract, Entity, InfoCard, InlineEntities } from "../../../../../../../../../../../../common";

type TriggersInfoSectionProps = {
    triggerResourceArnToIdReferenceMap: Dictionary<string>;
    triggers: Contract.AwsLambdaFunctionConfigurationTriggersTrigger[];
};

export function TriggersInfoSection({ triggerResourceArnToIdReferenceMap, triggers }: TriggersInfoSectionProps) {
    const dataTableActionsRef = useRef<DataTableActions>();
    useChangeEffect(
        () => {
            dataTableActionsRef.current?.reset();
        },
        [triggerResourceArnToIdReferenceMap]);

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsLambdaFunctionConfigurationDefinition.triggersInfoSection",
            () => ({
                columns: {
                    serviceId: "Type",
                    triggerResource: "Resource"
                },
                empty: "No Triggers",
                title: "Triggers"
            }));
    return (
        <InfoCard title={localization.title()}>
            <DataTable
                actionsRef={dataTableActionsRef}
                emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty()) }}
                fetchItems={
                    () =>
                        _(triggers).
                            groupBy(trigger => [trigger.serviceId, trigger.resourceTypeName]).
                            map(
                                triggers =>
                                    new TriggersTableItem(
                                        _.map(
                                            triggers,
                                            trigger => triggerResourceArnToIdReferenceMap[trigger.resourceArn]),
                                        triggers[0].resourceTypeName,
                                        triggers[0].serviceId)).
                            value()}
                getItemId={(item: TriggersTableItem) => item.serviceId}
                sortOptions={{ enabled: false }}
                variant="card">
                <DataTableColumn
                    id={TriggersTableColumnId.Service}
                    render={
                        ({ item }: DataTableColumnRenderProps<TriggersTableItem>) =>
                            <Entity
                                entityIdOrModel={item.serviceId}
                                variant="iconTextTenant"/>}
                    title={localization.columns.serviceId()}/>
                <DataTableColumn
                    id={TriggersTableColumnId.TriggerResource}
                    render={
                        ({ item }: DataTableColumnRenderProps<TriggersTableItem>) =>
                            <InlineEntities
                                entityIdsOrModels={item.resourceIdReferences}
                                entityTypeName={item.resourceTypeName}
                                entityVariant="iconTextTypeTenant"
                                variant="itemPlusItemCount"/>}
                    title={localization.columns.triggerResource()}/>
            </DataTable>
        </InfoCard>);
}

enum TriggersTableColumnId {
    Service = "service",
    TriggerResource = "triggerResource"
}

class TriggersTableItem {
    constructor(
        public resourceIdReferences: string[],
        public resourceTypeName: string,
        public serviceId: string) {
    }
}