import { ActionMenuItem, ConfirmDialog, DataTableColumnRenderProps, Menu, Message, useLocalization } from "@infrastructure";
import { CircularProgress, Stack } from "@mui/material";
import React, { Fragment, useState } from "react";
import { useApproverUserPermissionRequestsContext } from "../../../../../../..";
import { Contract, PermissionManagementController, useTheme } from "../../../../../../../../../../../common";

export function ApprovedActionsCell({ item }: DataTableColumnRenderProps<Contract.PermissionRequestModel>) {
    const { triggerPermissionRequestModelChange } = useApproverUserPermissionRequestsContext();

    const localization =
        useLocalization(
            "views.user.approverUserPermissionRequests.table.hooks.useDefinition.hooks.useActiveDefinition.approvedActionsCell",
            () => ({
                actions: {
                    revoke: {
                        confirm: "Are you sure you want to revoke this request?",
                        error: "Failed to revoke request",
                        title: "Revoke"
                    }
                }
            }));

    const [confirmRevoke, setConfirmRevoke] = useState(false);
    const [revokePermissionRequestError, setRevokePermissionRequestError] = useState(false);
    const [revokePermissionRequestExecuting, setRevokePermissionRequestExecuting] = useState(false);

    async function revokePermissionRequest() {
        setRevokePermissionRequestExecuting(true);
        setRevokePermissionRequestError(false);

        try {
            if (item.permissionRequest.status === Contract.PermissionRequestStatus.Active) {
                await PermissionManagementController.revokePermissionRequest(
                    new Contract.PermissionManagementControllerRevokePermissionRequestRequest(item.permissionRequest.id));
            } else {
                await PermissionManagementController.rejectPermissionRequest(
                    new Contract.PermissionManagementControllerRejectPermissionRequestRequest(item.permissionRequest.id));
            }

            await triggerPermissionRequestModelChange();
        } catch {
            setRevokePermissionRequestError(true);
        }

        setRevokePermissionRequestExecuting(false);
    }

    const theme = useTheme();
    return (
        <Fragment>
            {confirmRevoke &&
                <ConfirmDialog
                    message={localization.actions.revoke.confirm()}
                    onClose={
                        confirmed => {
                            if (confirmed) {
                                revokePermissionRequest();
                            }

                            setConfirmRevoke(false);
                        }}/>}
            <Stack
                alignItems="center"
                direction="row"
                justifyContent="flex-end"
                spacing={1.5}>
                {revokePermissionRequestExecuting && (
                    <CircularProgress
                        size={theme.spacing(3)}
                        variant="indeterminate"/>)}
                {revokePermissionRequestError && (
                    <Message
                        level="error"
                        title={localization.actions.revoke.error()}
                        variant="minimal"/>)}
                <Menu
                    itemsOrGetItems={[
                        new ActionMenuItem(
                            () => setConfirmRevoke(true),
                            localization.actions.revoke.title())
                    ]}/>
            </Stack>
        </Fragment>);
}