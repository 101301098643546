﻿import { useOpCommonResourceInfoItemElements } from ".";
import { Contract } from "../../../../../../../../../../common";
export function useOpDefaultResourceInfoItemElements(resourceModel: Contract.OpResourceModel) {
    const commonResourceInfoItemElements = useOpCommonResourceInfoItemElements(resourceModel);

    return [
        commonResourceInfoItemElements.typeNameInfoElement,
        commonResourceInfoItemElements.tenantInfoItemElement,
        commonResourceInfoItemElements.creationTimeInfoItemElement,
        commonResourceInfoItemElements.syncTimeInfoItemElement
    ];
}