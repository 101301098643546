import { DataTable, DataTableColumn, DataTableColumnRenderProps, EmptyMessageText, Optional, optionalTableCell, PasswordText, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, Entity, SecretExclusionActionCell } from "../../../common";

type AwsCloudFormationStackOutputTableProps = {
    getHighlightColor?: (output: Contract.AwsCloudFormationStackOutput, opacity?: number) => Optional<string>;
    outputResourceReferences: Contract.AwsResourceReference[];
    outputs: Contract.AwsCloudFormationStackOutput[];
    risk?: Contract.Risk;
};

export function AwsCloudFormationStackOutputTable({ getHighlightColor, outputResourceReferences, outputs, risk }: AwsCloudFormationStackOutputTableProps) {
    const items =
        useMemo(
            () => {
                const arnToOutputResourceReferenceMap =
                    _.keyBy(
                        outputResourceReferences,
                        outputResourceReference => outputResourceReference.arn);
                return _.map(
                    outputs,
                    output =>
                        new AwsCloudFormationStackOutputResourceCardItem(
                            output,
                            !_.isNil(output.value)
                                ? arnToOutputResourceReferenceMap[output.value]
                                : undefined));
            },
            [outputs]);

    const localization =
        useLocalization(
            "tenants.aws.awsCloudFormationStackOutputTable",
            () => ({
                columns: {
                    description: "Description",
                    exportName: "Export name",
                    key: "Key",
                    value: "Value"
                },
                empty: "No outputs",
                title: "Outputs Table"
            }));

    return (
        <DataTable
            emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty()) }}
            fetchItems={() => items}
            getItemId={(item: AwsCloudFormationStackOutputResourceCardItem) => item.output.key}
            rowOptions={{
                getHighlightColor: (item: AwsCloudFormationStackOutputResourceCardItem) => getHighlightColor?.(item.output),
                getSx:
                    (item: AwsCloudFormationStackOutputResourceCardItem) => ({
                        backgroundColor: getHighlightColor?.(item.output, 0.1)
                    })
            }}
            sortOptions={{ enabled: false }}
            variant="card">
            <DataTableColumn
                id={StackOutputColumnId.Key}
                itemProperty={(item: AwsCloudFormationStackOutputResourceCardItem) => item.output.key}
                key={StackOutputColumnId.Key}
                title={localization.columns.key()}/>
            <DataTableColumn
                id={StackOutputColumnId.Value}
                key={StackOutputColumnId.Value}
                render={
                    optionalTableCell<AwsCloudFormationStackOutputResourceCardItem>(
                        item =>
                            !_.isNil(item.resourceReference)
                                ? <Entity
                                    entityIdOrModel={item.resourceReference.idReference}
                                    variant="iconTextTypeTenant"/>
                                : !_.isNil(getHighlightColor?.(item.output))
                                    ? <PasswordText password={item.output.value!}/>
                                    : item.output.value)}
                title={localization.columns.value()}/>
            <DataTableColumn
                id={StackOutputColumnId.Description}
                key={StackOutputColumnId.Description}
                render={optionalTableCell<AwsCloudFormationStackOutputResourceCardItem>(item => item.output.description)}
                title={localization.columns.description()}/>
            <DataTableColumn
                id={StackOutputColumnId.ExportName}
                key={StackOutputColumnId.ExportName}
                render={optionalTableCell<AwsCloudFormationStackOutputResourceCardItem>(item => item.output.exportName)}
                title={localization.columns.exportName()}/>
            {!_.isNil(getHighlightColor) &&
                !_.isNil(risk) &&
                <DataTableColumn
                    id={StackOutputColumnId.Actions}
                    key={StackOutputColumnId.Actions}
                    render={
                        ({ item }: DataTableColumnRenderProps<AwsCloudFormationStackOutputResourceCardItem>) =>
                            <SecretExclusionActionCell
                                displayNameToValueSecretDataMap={{
                                    [localization.columns.value()]: {
                                        secret: !_.isNil(getHighlightColor?.(item.output)),
                                        value: item.output.value
                                    }
                                }}
                                risk={risk}
                                secretKey={item.output.key}/>}/>}
        </DataTable>);
}

class AwsCloudFormationStackOutputResourceCardItem {
    constructor(
        public output: Contract.AwsCloudFormationStackOutput,
        public resourceReference?: Contract.AwsResourceReference) {
    }
}

enum StackOutputColumnId {
    Actions = "actions",
    Description = "description",
    ExportName = "exportName",
    Key = "key",
    Value = "value"
}