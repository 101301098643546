﻿import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useGcpComputeLoadBalancerTypeTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useGcpComputeLoadBalancerTypeTranslator",
            () => ({
                [Contract.TypeNames.GcpComputeLoadBalancerType]: {
                    [Contract.GcpComputeLoadBalancerType.HttpProxy]: "HTTP(S)",
                    [Contract.GcpComputeLoadBalancerType.HttpClassicProxy]: "HTTP(S) (Classic)",
                    [Contract.GcpComputeLoadBalancerType.HttpInternalProxy]: "HTTP(S) (Internal)",
                    [Contract.GcpComputeLoadBalancerType.NetworkBackendService]: "Network (backend service-based)",
                    [Contract.GcpComputeLoadBalancerType.NetworkTargetPool]: "Network (target pool-based)",
                    [Contract.GcpComputeLoadBalancerType.SslTcpProxy]: "SSL/TCP (Proxy)",
                    [Contract.GcpComputeLoadBalancerType.NetworkInternal]: "TCP/UDP (Internal)",
                    [Contract.GcpComputeLoadBalancerType.TcpInternalProxy]: "TCP Proxy (Internal)"
                }
            }));
    return (type: Contract.GcpComputeLoadBalancerType) =>
        localization[Contract.TypeNames.GcpComputeLoadBalancerType][type]();
}