﻿import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function UnitedKingdomIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <g clipPath="url(#clip0_60203_5285)">
                <rect
                    fill="white"
                    height="16"
                    rx="2"
                    width="24"
                    y="4"/>
                <path
                    d="M0 6C0 4.89543 0.895431 4 2 4H22C23.1046 4 24 4.89543 24 6V18.0363C24 19.1409 23.1046 20.0363 22 20.0363H2C0.89543 20.0363 0 19.1409 0 18.0363V6Z"
                    fill="#012169"/>
                <path
                    d="M2.8125 4L11.9625 10.047L21.075 4H24V6.07135L15 12.0515L24 17.9983V20.0363H21L12 14.0561L3.0375 20.0363H0V18.0317L8.9625 12.085L0 6.13817V4H2.8125Z"
                    fill="white"/>
                <path
                    d="M15.9 13.3879L24 18.6999V20.0363L13.8375 13.3879H15.9ZM9 14.0561L9.225 15.2254L2.025 20.0363H0L9 14.0561ZM24 4V4.10023L14.6625 10.3811L14.7375 8.91111L22.125 4H24ZM0 4L8.9625 9.87997H6.7125L0 5.40317V4Z"
                    fill="#C8102E"/>
                <path
                    d="M9.0375 4V20.0363H15.0375V4H9.0375ZM0 9.34543V14.6909H24V9.34543H0Z"
                    fill="white"/>
                <path
                    d="M0 10.4479V13.6552H24V10.4479H0ZM10.2375 4V20.0363H13.8375V4H10.2375Z"
                    fill="#C8102E"/>
            </g>
            <defs>
                <clipPath id="clip0_60203_5285">
                    <rect
                        fill="white"
                        height="16"
                        rx="2"
                        width="24"
                        y="4"/>
                </clipPath>
            </defs>
        </SvgIcon>);
}