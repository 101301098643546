import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../../..";
import { Contract, Entity, entityModelStore, InlineEntities, InlineKubernetesContainerNames, useEntityTypeNameTranslator } from "../../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../../utilities";
import { useGetKubernetesResourceRiskContext } from "../contexts";

export function useKubernetesNamespaceWorkloadResourceContainerPrivilegeEscalationRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.KubernetesNamespaceWorkloadResourceContainerPrivilegeEscalationRisk;
    const namespaceModel = entityModelStore.useGet(risk.entityId);
    const namespace = _.as<Contract.IKubernetesNamespace>(namespaceModel.entity);

    const getKubernetesResourceRiskContext = useGetKubernetesResourceRiskContext();

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.kubernetes.hooks.resources.workloadResources.useKubernetesNamespaceWorkloadResourceContainerPrivilegeEscalationRiskDefinition",
            () => ({
                contextItems: {
                    podSecurityPolicy: {
                        [Contract.KubernetesNamespaceDataPodSecurityStandard.Baseline]: "The {{translatedEntityTypeName}} has a Baseline Pod Security Standard policy configured, meaning that containers can be provisioned with allowPrivilegeEscalation set to **true**",
                        [Contract.KubernetesNamespaceDataPodSecurityStandard.None]: "The {{translatedEntityTypeName}} doesn't have a Pod Security Standard policy configured, meaning that containers can be provisioned with allowPrivilegeEscalation set to **true**",
                        [Contract.KubernetesNamespaceDataPodSecurityStandard.Privileged]: "The {{translatedEntityTypeName}} has a Privileged Pod Security Standard policy configured, meaning that containers can be provisioned with allowPrivilegeEscalation set to **true**"
                    },
                    workloadResources: "The {{translatedEntityTypeName}} contains {{workloadResources}} running {{containerNames}} with allowPrivilegeEscalation set to **true**"
                },
                description: "{{kubernetesNamespace}} contains {{workloadResources}} running {{containerNames}} with allowPrivilegeEscalation set to true",
                resolutionSection: {
                    step1: "For each of the {{workloadResources}}, set the allowPrivilegeEscalation flag to **false**",
                    step2: "Add a Restricted Pod Security Standard policy to the {{kubernetesNamespace}}"
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            containerNames: <InlineKubernetesContainerNames containerNames={risk.containerNames}/>,
            kubernetesNamespace:
                <Entity
                    entityIdOrModel={namespaceModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>,
            workloadResources:
                <InlineEntities
                    entityIdsOrModels={risk.workloadResourceIds}
                    entityTypeName={Contract.TypeNames.IKubernetesWorkloadResource}
                    variant="itemAndTypeOrItemCountAndType"/>
        }),
        () => [
            localization.resolutionSection.step1({
                workloadResources:
                    <InlineEntities
                        entityIdsOrModels={risk.workloadResourceIds}
                        entityTypeName={Contract.TypeNames.IKubernetesWorkloadResource}
                        variant="itemAndTypeOrItemCountAndType"/>
            }),
            localization.resolutionSection.step2({
                kubernetesNamespace:
                    <Entity
                        entityIdOrModel={namespaceModel}
                        variant="typeText"/>
            })
        ],
        riskModel,
        () => {
            const namespaceRiskContext = getKubernetesResourceRiskContext(namespaceModel);
            return [
                namespaceRiskContext.generalInformation,
                namespaceRiskContext.sensitive,
                namespaceRiskContext.relatedResources,
                new RiskDefinitionContextItem(
                    localization.contextItems.workloadResources({
                        containerNames: <InlineKubernetesContainerNames containerNames={risk.containerNames}/>,
                        translatedEntityTypeName:
                            entityTypeNameTranslator(
                                namespace.typeName,
                                {
                                    includeServiceName: false,
                                    variant: "text"
                                }),
                        workloadResources:
                            <InlineEntities
                                entityIdsOrModels={risk.workloadResourceIds}
                                entityTypeName={Contract.TypeNames.IKubernetesWorkloadResource}
                                variant="itemAndTypeOrItemCountAndType"/>
                    })),
                new RiskDefinitionContextItem(
                    localization.contextItems.podSecurityPolicy[risk.podSecurityStandard as Exclude<Contract.KubernetesNamespaceDataPodSecurityStandard, Contract.KubernetesNamespaceDataPodSecurityStandard.Restricted>]({
                        translatedEntityTypeName:
                            entityTypeNameTranslator(
                                namespace.typeName,
                                {
                                    includeServiceName: false,
                                    variant: "text"
                                })
                    })),
                namespaceRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        });
}