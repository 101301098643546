import { useLocalization, useLocalizeList } from "@infrastructure";
import _ from "lodash";
import { AuditEventDefinition, AuditEventDefinitionContext, AuditEventDefinitionDetailItem } from "..";
import { Contract } from "../../../../../../../../../common";

export function useProjectAuditEventDefinition(context: AuditEventDefinitionContext) {
    const localizeList = useLocalizeList();
    const localization =
        useLocalization(
            "views.customer.auditEvents.profile.hooks.useDefinition.hooks.useProjectAuditEventDefinition",
            () => ({
                projectDescription: "Description",
                projectName: "Name",
                projectParentScopeId: "Folder",
                projectRules: "Scopes",
                propertyChanges: "Updated properties"
            }));

    const projectAuditEvent = context.auditEventModel.auditEvent as Contract.ProjectAuditEvent;
    return new AuditEventDefinition(
        _<AuditEventDefinitionDetailItem>([]).
            concat(
                new AuditEventDefinitionDetailItem(
                    localization.projectName(),
                    projectAuditEvent.projectName),
                new AuditEventDefinitionDetailItem(
                    localization.projectDescription(),
                    projectAuditEvent.projectDescription)).
            concatIf(
                projectAuditEvent.typeName === Contract.TypeNames.ProjectUpdateAuditEvent,
                () => {
                    const properties =
                        _.filter([
                            (projectAuditEvent as Contract.ProjectUpdateAuditEvent).projectNameChanged
                                ? localization.projectName()
                                : undefined,
                            (projectAuditEvent as Contract.ProjectUpdateAuditEvent).projectDescriptionChanged
                                ? localization.projectDescription()
                                : undefined,
                            (projectAuditEvent as Contract.ProjectUpdateAuditEvent).projectRulesChanged
                                ? localization.projectRules()
                                : undefined,
                            (projectAuditEvent as Contract.ProjectUpdateAuditEvent).projectParentChanged
                                ? localization.projectParentScopeId()
                                : undefined
                        ]);
                    return (
                        _<AuditEventDefinitionDetailItem>([]).
                            concatIf(
                                !_.isEmpty(properties),
                                new AuditEventDefinitionDetailItem(
                                    localization.propertyChanges(),
                                    localizeList(properties)!)).
                            value());
                }).
            value());
}