import { UnexpectedError } from "@infrastructure";
import { useCallback } from "react";
import { Contract, CustomerConsoleAppUrlHelper, useEntityTypeNameTranslator } from "../../../../../../../../../../../common";
import { CustomerLicenseObjectTypeTranslator } from "../useGetVersion2Translator";

export function useGetVersion2OpTranslator(): CustomerLicenseObjectTypeTranslator {
    const entityTypeNameTranslator = useEntityTypeNameTranslator();

    return useCallback(objectType => {
        switch (objectType) {
            case Contract.CustomerLicenseObjectType.OpUnmanagedKubernetesClusterNode:
                return {
                    link: CustomerConsoleAppUrlHelper.getEntitiesRelativeUrl(Contract.TypeNames.OpKubernetesNode),
                    title:
                            entityTypeNameTranslator(
                                Contract.TypeNames.IKubernetesNode,
                                {
                                    count: 0,
                                    includeServiceName: true
                                })
                };
            default:
                throw new UnexpectedError("objectType", objectType);
        }
    },
    [entityTypeNameTranslator]);
}