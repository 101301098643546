import { EmptyValueOptions, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import React, { memo } from "react";
import { Contract, useDataSensitivityTranslator } from "../../../../../common";

const DataSensitivityFilterMemo = memo(DataSensitivityFilter);
export { DataSensitivityFilterMemo as DataSensitivityFilter };

type DataSensitivityFilterProps = {
    emptyValueOptions?: EmptyValueOptions;
    placeholder: string;
};

function DataSensitivityFilter({ emptyValueOptions, placeholder }: DataSensitivityFilterProps) {
    const dataSensitivityTranslator = useDataSensitivityTranslator();
    return (
        <ValuesFilter
            emptyValueOptions={emptyValueOptions}
            placeholder={placeholder}
            sorted={false}>
            <ValuesFilterItem
                title={dataSensitivityTranslator(Contract.DataSensitivity.Restricted)}
                value={Contract.DataSensitivity.Restricted}/>
            <ValuesFilterItem
                title={dataSensitivityTranslator(Contract.DataSensitivity.Proprietary)}
                value={Contract.DataSensitivity.Proprietary}/>
            <ValuesFilterItem
                title={dataSensitivityTranslator(Contract.DataSensitivity.Confidential)}
                value={Contract.DataSensitivity.Confidential}/>
            <ValuesFilterItem
                title={dataSensitivityTranslator(Contract.DataSensitivity.Private)}
                value={Contract.DataSensitivity.Private}/>
            <ValuesFilterItem
                title={dataSensitivityTranslator(Contract.DataSensitivity.NonSensitive)}
                value={Contract.DataSensitivity.NonSensitive}/>
        </ValuesFilter>);
}