import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, EnumValuesFilter, InlineItems, optionalTableCell, PagedValuesFilter, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { useOciCommonResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions, useEntityTypeNameTranslator } from "../../../../../../../../../../common";
import { useOciNetworkingNetworkLoadBalancerStatusTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";

export function useOciNetworkingNetworkLoadBalancerDefinition(definitionData: DefinitionData) {
    const commonResourceDefinition = useOciCommonResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const networkLoadBalancerStatusTranslator = useOciNetworkingNetworkLoadBalancerStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.oci.useOciNetworkingNetworkLoadBalancerDefinition",
            () => ({
                columns: {
                    external: {
                        false: "Private",
                        title: "Visibility Type",
                        true: "Public"
                    },
                    ipAddresses: {
                        private: "Private IP",
                        public: "Public IP"
                    },
                    status: "State"
                }
            }));

    return new EntityTableDefinition(
        [
            commonResourceDefinition.columns.tenantColumn,
            commonResourceDefinition.columns.creationTimeColumn,
            commonResourceDefinition.columns.creatorCsvColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.OciNetworkingNetworkLoadBalancerModel) => ({
                            [localization.columns.status()]: networkLoadBalancerStatusTranslator((item.entity as Contract.OciNetworkingNetworkLoadBalancer).status)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        default: true,
                        element:
                            <EnumValuesFilter
                                enumType={Contract.OciNetworkingNetworkLoadBalancerStatus}
                                enumTypeTranslator={networkLoadBalancerStatusTranslator}
                                placeholder={localization.columns.status()}/>
                    }
                }}
                id={Contract.EntityModelProperty.OciNetworkingNetworkLoadBalancerStatus}
                itemProperty={(item: Contract.OciNetworkingNetworkLoadBalancerModel) => networkLoadBalancerStatusTranslator((item.entity as Contract.OciNetworkingNetworkLoadBalancer).status)}
                key={Contract.EntityModelProperty.OciNetworkingNetworkLoadBalancerStatus}
                title={localization.columns.status()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.OciNetworkingNetworkLoadBalancerModel) => ({
                            [localization.columns.external.title()]:
                                (item.entity as Contract.OciNetworkingNetworkLoadBalancer).external
                                    ? localization.columns.external.true()
                                    : localization.columns.external.false()
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        default: true,
                        element:
                            <ValuesFilter placeholder={localization.columns.external.title()}>
                                <ValuesFilterItem
                                    title={localization.columns.external.false()}
                                    value={false}/>
                                <ValuesFilterItem
                                    title={localization.columns.external.true()}
                                    value={true}/>
                            </ValuesFilter>
                    }
                }}
                id={Contract.EntityModelProperty.OciNetworkingNetworkLoadBalancerExternal}
                itemProperty={
                    (item: Contract.EntityModel) =>
                        (item.entity as Contract.OciNetworkingNetworkLoadBalancer).external
                            ? localization.columns.external.true()
                            : localization.columns.external.false()}
                key={Contract.EntityModelProperty.OciNetworkingNetworkLoadBalancerExternal}
                title={localization.columns.external.title()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.OciNetworkingNetworkLoadBalancerModel) => ({
                            [localization.columns.ipAddresses.public()]: (item.entity as Contract.OciNetworkingNetworkLoadBalancer).publicIpAddresses.join("\n")
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedValuesFilter
                                getValuePage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.OciNetworkingNetworkLoadBalancerPublicIpAddresses)}
                                placeholder={localization.columns.ipAddresses.public()}/>
                    }
                }}
                id={Contract.EntityModelProperty.OciNetworkingNetworkLoadBalancerPublicIpAddresses}
                key={Contract.EntityModelProperty.OciNetworkingNetworkLoadBalancerPublicIpAddresses}
                render={
                    optionalTableCell<Contract.OciNetworkingNetworkLoadBalancerModel>(
                        item =>
                            _.isEmpty((item.entity as Contract.OciNetworkingNetworkLoadBalancer).publicIpAddresses)
                                ? undefined
                                : <InlineItems
                                    items={(item.entity as Contract.OciNetworkingNetworkLoadBalancer).publicIpAddresses}
                                    variant="itemPlusItemCount"/>)}
                title={localization.columns.ipAddresses.public()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.OciNetworkingNetworkLoadBalancerModel) => ({
                            [localization.columns.ipAddresses.private()]: (item.entity as Contract.OciNetworkingNetworkLoadBalancer).privateIpAddresses.join("\n")
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedValuesFilter
                                getValuePage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.OciNetworkingNetworkLoadBalancerPrivateIpAddresses)}
                                placeholder={localization.columns.ipAddresses.private()}/>
                    }
                }}
                id={Contract.EntityModelProperty.OciNetworkingNetworkLoadBalancerPrivateIpAddresses}
                key={Contract.EntityModelProperty.OciNetworkingNetworkLoadBalancerPrivateIpAddresses}
                render={
                    optionalTableCell<Contract.OciNetworkingNetworkLoadBalancerModel>(
                        item =>
                            _.isEmpty((item.entity as Contract.OciNetworkingNetworkLoadBalancer).privateIpAddresses)
                                ? undefined
                                : <InlineItems
                                    items={(item.entity as Contract.OciNetworkingNetworkLoadBalancer).privateIpAddresses}
                                    variant="itemPlusItemCount"/>)}
                title={localization.columns.ipAddresses.private()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.OciNetworkingNetworkLoadBalancerModel>(
                        Contract.TypeNames.OciNetworkingVcn,
                        item => [item.vcnIdReference],
                        entityTypeNameTranslator(Contract.TypeNames.OciNetworkingVcn))}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.OciNetworkingNetworkLoadBalancerVcn)}
                                placeholder={entityTypeNameTranslator(Contract.TypeNames.OciNetworkingVcn)}/>
                    }
                }}
                id={Contract.EntityModelProperty.OciNetworkingNetworkLoadBalancerVcn}
                key={Contract.EntityModelProperty.OciNetworkingNetworkLoadBalancerVcn}
                render={
                    optionalTableCell<Contract.OciNetworkingNetworkLoadBalancerModel>(
                        item =>
                            _.isNil(item.vcnIdReference)
                                ? undefined
                                : <EntitiesCell
                                    entityIdsOrModels={item.vcnIdReference}
                                    entityTypeName={Contract.TypeNames.OciNetworkingVcn}/>)}
                title={entityTypeNameTranslator(Contract.TypeNames.OciNetworkingVcn)}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.OciNetworkingNetworkLoadBalancerModel>(
                        Contract.TypeNames.OciNetworkingNetworkSecurityGroup,
                        item => item.securityGroupIdReferences,
                        entityTypeNameTranslator(Contract.TypeNames.OciNetworkingNetworkSecurityGroup))}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.OciNetworkingNetworkLoadBalancerSecurityGroups)}
                                placeholder={
                                    entityTypeNameTranslator(
                                        Contract.TypeNames.OciNetworkingNetworkSecurityGroup,
                                        { count: 0 })}/>
                    }
                }}
                id={Contract.EntityModelProperty.OciNetworkingNetworkLoadBalancerSecurityGroups}
                key={Contract.EntityModelProperty.OciNetworkingNetworkLoadBalancerSecurityGroups}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.OciNetworkingNetworkLoadBalancerModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.securityGroupIdReferences}
                            entityTypeName={Contract.TypeNames.OciNetworkingNetworkSecurityGroup}/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={
                    entityTypeNameTranslator(
                        Contract.TypeNames.OciNetworkingNetworkSecurityGroup,
                        { count: 0 })}/>,
            commonResourceDefinition.columns.regionColumn,
            commonResourceDefinition.columns.regionLocationColumn,
            commonResourceDefinition.columns.tagsColumn,
            commonResourceDefinition.columns.attributesColumn,
            commonResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageOciNetworkingNetworkLoadBalancerRequest(
                new Contract.EntityControllerGetEntityModelPageOciNetworkingNetworkLoadBalancerRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciNetworkingNetworkLoadBalancerExternal]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciNetworkingNetworkLoadBalancerPrivateIpAddresses]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciNetworkingNetworkLoadBalancerPublicIpAddresses]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciNetworkingNetworkLoadBalancerSecurityGroups]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciNetworkingNetworkLoadBalancerStatus]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciNetworkingNetworkLoadBalancerVcn])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags]))));
}