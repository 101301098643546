import { InlineItems, Link, SuccessIcon, useLocalization, useOrderedWizardContext } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { CustomerConsoleAppUrlHelper, scopeSystemEntityModelStore, useTheme } from "../../../../../../../../../../../../../../../common";
import { useAddOrEditContext } from "../AddOrEdit";

export function FinishItem() {
    const { updatedOrganizationModel, updatedOrganizationTenantNames } = useAddOrEditContext();
    const { useNextEffect } = useOrderedWizardContext();

    useNextEffect(
        async () => {
            await scopeSystemEntityModelStore.notify(updatedOrganizationModel);
            return undefined;
        },
        []);

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCloudProviderTenantOrganizationItems.oci.addOrEdit.finishItem",
            () => ({
                organizationTenantNames: {
                    namePluralizer: [
                        "compartment",
                        "{{count | NumberFormatter.humanize}} compartments"
                    ],
                    title: "Detected {{organizationTenantNames}}"
                },
                subtitle: {
                    documentationLink: "learn more",
                    text: "We're synchronizing your data now, and you'll be able to see it soon. {{documentationLink}}"
                },
                title: "Tenancy {{organizationName}} configured successfully"
            }));

    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            justifyContent="center"
            spacing={2}
            sx={{ height: "100%" }}>
            <SuccessIcon
                sx={{
                    color: theme.palette.success.main,
                    fontSize: "60px"
                }}/>
            <Typography
                align="center"
                sx={{ fontWeight: 400 }}
                variant="h2">
                {localization.title({ organizationName: updatedOrganizationModel!.configuration.name })}
            </Typography>
            <Typography
                align="center"
                variant="h4">
                {localization.organizationTenantNames.title({
                    organizationTenantNames:
                        <InlineItems
                            items={_.orderBy(updatedOrganizationTenantNames!)}
                            namePluralizer={localization.organizationTenantNames.namePluralizer}
                            variant="itemCountAndType"/>
                })}
            </Typography>
            <Typography
                align="center"
                variant="h5">
                {localization.subtitle.text({
                    documentationLink:
                        <Link
                            urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsArchitectureAndDataHandlingHowOftenIsDataSyncedRelativeUrl()}
                            variant="external">
                            {localization.subtitle.documentationLink()}
                        </Link>
                })}
            </Typography>
        </Stack>);
}