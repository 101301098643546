import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, EmptyMessageText, NumberFormatter, Optional, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { DataAnalysisResourceDataClassifierSamples } from "../..";
import { Contract, dataAnalysisResourceScanStore, DataCategory, dataClassifierModelStore, DataSensitivity, ItemTable, TypeHelper, useDataAnalysisErrorTranslator } from "../../../../../../../../../common";
import { useDataAnalysisResourceSegmentTranslator } from "../hooks";

type DataClassifiersProps = {
    dataAnalysisResourceModel: Contract.IDataAnalysisResourceModel;
};

export function DataClassifiers({ dataAnalysisResourceModel }: DataClassifiersProps) {
    const dataAnalysisResourceScan = dataAnalysisResourceScanStore.useGet(dataAnalysisResourceModel.id);
    const isDynamoDbTable = dataAnalysisResourceModel.typeName === Contract.TypeNames.AwsDynamoDbTableModel;
    const dynamoFirstValueDataAnalysisResourceScanDataSegmentData =
        useMemo(
            () =>
                isDynamoDbTable
                    ? _(dataAnalysisResourceScan?.classificationExistsDataSegmentNameToDataMap)
                        .values()
                        .first()
                    : undefined,
            [dataAnalysisResourceScan]);

    const dataClassifierModels = dataClassifierModelStore.useGetAll();
    const dataClassifierModelMap =
        useMemo(
            () =>
                _.keyBy(
                    dataClassifierModels,
                    dataClassifierModel => dataClassifierModel.id),
            [dataClassifierModels]);

    const items =
        useMemo(
            () =>
                _(dataAnalysisResourceModel.dataClassifierIdToDataSegmentCountMap).
                    keys().
                    map(
                        dataClassifierId => ({
                            category: dataClassifierModelMap[dataClassifierId].category,
                            dataSegmentCount: dataAnalysisResourceModel.dataClassifierIdToDataSegmentCountMap?.[dataClassifierId],
                            id: dataClassifierModelMap[dataClassifierId].id,
                            name: dataClassifierModelMap[dataClassifierId].name,
                            sensitivity: dataClassifierModelMap[dataClassifierId].sensitivity
                        } as DataClassifierTableItem)).
                    value(),
            [dataAnalysisResourceModel, dataClassifierModelMap]);

    const dataAnalysisErrorTranslator = useDataAnalysisErrorTranslator();
    const dataAnalysisResourceSegmentTranslator = useDataAnalysisResourceSegmentTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.dataAnalysisResourceOverview.dataClassifiers",
            () => ({
                columns: {
                    dataCategory: "Data Category",
                    dataClassifierName: "Data Type",
                    dataSamples: "Samples",
                    dataSegmentCount: "Sensitive Items",
                    dataSensitivity: "Data Sensitivity"
                },
                empty: "No sensitive data found",
                title: "Data Types"
            }));

    const dataSegmentsTitle = dataAnalysisResourceSegmentTranslator(dataAnalysisResourceModel);

    return (
        <ItemTable
            columnIdToDefaultSortDirectionMap={{
                [TableColumnId.DataSegmentCount]: "desc",
                [TableColumnId.Name]: "asc",
                [TableColumnId.Sensitivity]: "desc"
            }}
            columnIdToGetItemValueMap={{
                [TableColumnId.Category]: (item: DataClassifierTableItem) => item.category,
                [TableColumnId.DataSegmentCount]: item => item.dataSegmentCount,
                [TableColumnId.Name]: (item: DataClassifierTableItem) => item.name,
                [TableColumnId.Sensitivity]: {
                    getFilterValue: (item: DataClassifierTableItem) => item.sensitivity,
                    getSortValue: (item: DataClassifierTableItem) => TypeHelper.getEnumValue(Contract.TypeNames.DataSensitivity, item.sensitivity)
                }
            }}
            defaultSortColumnIdOrIds={[
                TableColumnId.Sensitivity,
                TableColumnId.DataSegmentCount,
                TableColumnId.Name
            ]}
            emptyMessageOptions={{
                emptyMessageText:
                    new EmptyMessageText(
                        _.isNil(dataAnalysisResourceModel.dataAnalysisError)
                            ? localization.empty()
                            : dataAnalysisErrorTranslator(dataAnalysisResourceModel.dataAnalysisError))
            }}
            getItemId={(item: DataClassifierTableItem) => item.id}
            items={items}>
            {() => _([
                <DataTableColumn
                    cellMaxWidth="medium"
                    id={TableColumnId.Name}
                    itemProperty={(item: DataClassifierTableItem) => item.name}
                    key={TableColumnId.Name}
                    title={localization.columns.dataClassifierName()}/>,
                <DataTableColumn
                    id={TableColumnId.Category}
                    key={TableColumnId.Category}
                    render={({ item }: DataTableColumnRenderProps<DataClassifierTableItem>) => <DataCategory category={item.category}/>}
                    title={localization.columns.dataCategory()}/>,
                <DataTableColumn
                    id={TableColumnId.Sensitivity}
                    key={TableColumnId.Sensitivity}
                    render={({ item }: DataTableColumnRenderProps<DataClassifierTableItem>) => <DataSensitivity sensitivity={item.sensitivity}/>}
                    sortOptions={{ type: DataTableSortType.Numeric }}
                    title={localization.columns.dataSensitivity()}/>]).
                concatIf(
                    isDynamoDbTable && !_.isNil(dynamoFirstValueDataAnalysisResourceScanDataSegmentData?.dataClassifierIdToSamplesMap),
                    () =>
                        <DataTableColumn
                            id={TableColumnId.DataSamples}
                            key={TableColumnId.DataSamples}
                            render={
                                ({ item }: DataTableColumnRenderProps<DataClassifierTableItem>) =>
                                    <DataAnalysisResourceDataClassifierSamples
                                        dataClassifierIdToSamplesMap={{ [item.id]: dynamoFirstValueDataAnalysisResourceScanDataSegmentData!.dataClassifierIdToSamplesMap?.[item.id] }}
                                        dataClassifierModelMap={dataClassifierModelMap}
                                        showDataTypeColumn={false}/>}
                            sortOptions={{ type: DataTableSortType.Numeric }}
                            title={localization.columns.dataSamples()}/>).
                concatIf(
                    !isDynamoDbTable,
                    () =>
                        <DataTableColumn
                            id={TableColumnId.DataSegmentCount}
                            itemProperty={(item: DataClassifierTableItem) => NumberFormatter.humanize(item.dataSegmentCount!)}
                            key={TableColumnId.DataSegmentCount}
                            sortOptions={{ type: DataTableSortType.Numeric }}
                            title={localization.columns.dataSegmentCount({ dataSegmentsTitle })}/>).
                value()}
        </ItemTable>);
}

type DataClassifierTableItem = {
    category: Contract.DataCategory;
    dataSegmentCount: Optional<number>;
    id: string;
    name: string;
    sensitivity: Contract.DataSensitivity;
};

enum TableColumnId {
    Category = "category",
    DataSamples = "dataSamples",
    DataSegmentCount = "dataSegmentCount",
    Name = "name",
    Sensitivity = "sensitivity"
}