import { Store } from "@infrastructure";
import _ from "lodash";
import { useMemo } from "react";
import { Contract, RiskController, RiskPolicyTypeMetadataHelper } from "..";

export class RiskPolicyModelStore extends Store<Contract.RiskPolicyModel, never, never> {
    public useGetCustom =
        () =>
            this.useGetType(
                Contract.RiskPolicyCategory.Custom,
                async () => {
                    const { riskPolicyModels } = await RiskController.getRiskPolicyModels(new Contract.RiskControllerGetCustomRiskPolicyModelsRequest());
                    return riskPolicyModels;
                },
                riskPolicyModel => RiskPolicyTypeMetadataHelper.get(riskPolicyModel.riskPolicyConfiguration.typeName).category === Contract.RiskPolicyCategory.Custom);

    public useGetLicensedCustom =
        () => {
            const customerRiskPolicyModels = this.useGetCustom();
            return useMemo(
                () =>
                    _.filter(
                        customerRiskPolicyModels,
                        customerRiskPolicyModel => RiskPolicyTypeMetadataHelper.isLicensed(customerRiskPolicyModel.riskPolicyConfiguration.typeName)),
                [customerRiskPolicyModels]);
        };
}

export const riskPolicyModelStore =
    new RiskPolicyModelStore(
        riskPolicyModel => riskPolicyModel.riskPolicyConfiguration.id,
        {
            get:
                async ids => {
                    const { riskPolicyModels } = await RiskController.getRiskPolicyModels(new Contract.RiskControllerGetCloudRiskPolicyModelsRequest(ids));
                    return riskPolicyModels;
                },
            getAll:
                async () => {
                    const { riskPolicyModels } = await RiskController.getRiskPolicyModels(new Contract.RiskControllerGetCloudRiskPolicyModelsRequest(undefined));
                    return riskPolicyModels;
                }
        });