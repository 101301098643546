import { DialogMenuItem, TimeFormatter, useExecuteOperation, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useAwsCommonEc2ResourceInfoElements, useAwsCommonNetworkingInfoItemElements, useAwsDefaultResourceInfoItemElements, useAwsEc2InstanceMetadataServiceInfoItems } from ".";
import { useCommonVirtualMachineDiskResourceInfoItemElements, useCommonVirtualMachineResourceInfoItemElements, useCustomEntityPropertyInfoItemElements } from "..";
import { Base64Helper, Contract, EntityController, InfoItem, VerticalTopItemsInfoItem } from "../../../../../../../../../../common";
import { AwsEc2InstanceUserDataTextViewer, GenerateAwsResourceNonexcessivePolicyDialog, useAwsResourceProductCodeTypeTranslator } from "../../../../../../../../../../tenants";
import { useGetVirtualMachinePriorityScanRequestDialogMenuItem } from "../../../../../../../WorkloadAnalysis";
import { useAwsEc2InstanceHypervisorTranslator, useAwsEc2InstanceStatusTranslator } from "../../../../../../hooks";
import { EntitiesInfoItem, Info } from "../../../../components";
import { GenerateEntityNonexcessivePermitterIcon } from "../../../../icons";
import { NetworkingInfoCard } from "../../components";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../useDefinition";

export function useAwsEc2InstanceDefinition(resourceModel: Contract.AwsResourceModel, options?: DefinitionOptions) {
    const commonEc2ResourceInfoItemElements = useAwsCommonEc2ResourceInfoElements(resourceModel);
    const commonVirtualMachineDiskResourceInfoItemElements = useCommonVirtualMachineDiskResourceInfoItemElements(resourceModel);
    const commonVirtualMachineResourceInfoItemElements = useCommonVirtualMachineResourceInfoItemElements(resourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useAwsDefaultResourceInfoItemElements(resourceModel);
    const instanceModel = resourceModel as Contract.AwsEc2InstanceModel;
    const instance = instanceModel.entity as Contract.AwsEc2Instance;
    const [{ distributionIds, entityExcessivePermissionCustomEvaluationData }] =
        useExecuteOperation(
            [useAwsEc2InstanceDefinition, instanceModel.id],
            async () => {
                const { distributionIds } = await EntityController.getAwsDistributionIds(new Contract.EntityControllerGetAwsDistributionIdsRequest(instanceModel.id));

                const entityExcessivePermissionCustomEvaluationData =
                    await EntityController.getEntityExcessivePermissionCustomEvaluationData(
                        new Contract.EntityControllerGetAwsEntityExcessivePermissionCustomEvaluationDataRequest(instanceModel.id));

                return {
                    distributionIds,
                    entityExcessivePermissionCustomEvaluationData
                };
            });

    const nonRootVolumeIds =
        _.filter(
            instance.volumeIds,
            volumeId => volumeId != instance.rootVolumeId);

    const getVirtualMachinePriorityScanRequestDialogMenuItem = useGetVirtualMachinePriorityScanRequestDialogMenuItem(instanceModel);
    const instanceMetadataServiceInfoItems = useAwsEc2InstanceMetadataServiceInfoItems(instance.metadataService);
    const commonNetworkCardInfoItems =
        useAwsCommonNetworkingInfoItemElements({
            elasticIpIds: instanceModel.elasticIpIds,
            loadBalancerIdReferences: instanceModel.loadBalancerIdReferences,
            networkInterfaceIds: instance.networkInterfaceIds,
            privateIpAddresses: instance.privateIpAddresses,
            publicIpAddresses: instance.publicIpAddresses,
            resourceModel
        });

    const ec2InstanceStatusTranslator = useAwsEc2InstanceStatusTranslator();
    const ec2InstanceTypeHypervisorTranslator = useAwsEc2InstanceHypervisorTranslator();
    const resourceProductCodeTypeTranslator = useAwsResourceProductCodeTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsEc2InstanceDefinition",
            () => ({
                info: {
                    items: {
                        architecture: "Architecture",
                        autoScalingGroup: "Auto Scaling Group",
                        availabilityZone: "Availability Zone",
                        bareMetal: {
                            false: "No",
                            title: "Bare Metal",
                            true: "Yes"
                        },
                        cloudFrontDistributions: "CloudFront Distributions",
                        cluster: "EKS Cluster",
                        cpuCoresCount: "CPU Cores",
                        dnsZoneIds: "DNS Zones",
                        enclaveEnabled: {
                            false: "Disabled",
                            title: "Nitro Enclave",
                            true: "Enabled",
                            unsupported: "Not supported"
                        },
                        hypervisor: "Hypervisor",
                        image: "Image",
                        keyName: "Key Pair",
                        launchConfiguration: "Launch Configuration",
                        launchTemplate: "Launch Template",
                        launchTime: "Launch Time",
                        metadataServiceVersionV1UsageTime: "IMDSv1 Last Usage Time",
                        nonRootVolume: "Other Volumes",
                        operatingSystemDescription: "Platform",
                        privateDnsNames: "Private DNS Name",
                        productCodeId: "Product Code Id",
                        productCodeType: "Product Code Type",
                        publicIpAddressDnsNames: "Public DNS Name",
                        rawType: "Instance Type",
                        roleReference: "Role",
                        rootVolume: {
                            instanceStore: "instance store",
                            title: "Root Volume"
                        },
                        status: "State",
                        stopTime: "Stop Time",
                        userData: {
                            open: "View",
                            title: "User Data"
                        },
                        vpc: "VPC"
                    }
                },
                tabs: {
                    userData: "User Data"
                },
                topbar: {
                    menu: {
                        generate: "Generate Least Privilege Policy"
                    }
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Overview,
                <AwsEc2InstanceUserDataTextViewer
                    userData={
                        _.isNil(instance.userData) ||
                        !Base64Helper.isValid(instance.userData)
                            ? instance.userData
                            : atob(instance.userData)}/>,
                localization.tabs.userData(),
                "userData")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    ...commonEc2ResourceInfoItemElements,
                    <InfoItem
                        key="availabilityZone"
                        title={localization.info.items.availabilityZone()}
                        value={instance.availabilityZone.name}/>,
                    <InfoItem
                        key="rawType"
                        location="all"
                        title={localization.info.items.rawType()}
                        value={instance.typeData.rawType}/>,
                    <InfoItem
                        key="bareMetal"
                        title={localization.info.items.bareMetal.title()}
                        value={
                            instance.typeData.bareMetal
                                ? localization.info.items.bareMetal.true()
                                : localization.info.items.bareMetal.false()
                        }/>,
                    <InfoItem
                        key="hypervisor"
                        title={localization.info.items.hypervisor()}
                        value={
                            _.isNil(instance.typeData.hypervisor)
                                ? undefined
                                : ec2InstanceTypeHypervisorTranslator(instance.typeData.hypervisor)}/>,
                    <InfoItem
                        emptyValue={localization.info.items.enclaveEnabled.unsupported()}
                        key="enclaveEnabled"
                        location="all"
                        title={localization.info.items.enclaveEnabled.title()}
                        value={
                            _.isNil(instance.enclaveEnabled)
                                ? undefined
                                : instance.enclaveEnabled
                                    ? localization.info.items.enclaveEnabled.true()
                                    : localization.info.items.enclaveEnabled.false()}/>,
                    <EntitiesInfoItem
                        emptyValue={instance.imageRawId}
                        entityIdsOrModels={instanceModel.imageId}
                        entityTypeName={Contract.TypeNames.AwsEc2Image}
                        key="imageId"
                        title={localization.info.items.image()}/>,
                    <InfoItem
                        key="operatingSystemDescription"
                        location="all"
                        title={localization.info.items.operatingSystemDescription()}
                        value={instance.operatingSystemDescription}/>,
                    ...instanceMetadataServiceInfoItems,
                    <InfoItem
                        key="metadataServiceVersion"
                        title={localization.info.items.metadataServiceVersionV1UsageTime()}
                        value={
                            _.isEmpty(instance.metadataServiceVersionV1UsageTime)
                                ? undefined
                                : TimeFormatter.profileFormatDateTime(instance.metadataServiceVersionV1UsageTime)}/>,
                    _.isNil(instance.rootVolumeRawId)
                        ? <InfoItem
                            key="rootVolume"
                            title={localization.info.items.rootVolume.title()}
                            value={localization.info.items.rootVolume.instanceStore()}/>
                        : <EntitiesInfoItem
                            emptyValue={instance.rootVolumeRawId}
                            entityIdsOrModels={instance.rootVolumeId}
                            entityTypeName={Contract.TypeNames.AwsEc2Volume}
                            key="rootVolume"
                            title={localization.info.items.rootVolume.title()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={nonRootVolumeIds}
                        entityTypeName={Contract.TypeNames.AwsEc2Volume}
                        key="nonRootVolumeIds"
                        title={localization.info.items.nonRootVolume()}/>,
                    <InfoItem
                        key="launchTime"
                        location="all"
                        title={localization.info.items.launchTime()}
                        value={TimeFormatter.profileFormatDateTime(instance.launchTime)}/>,
                    <InfoItem
                        key="status"
                        location="all"
                        title={localization.info.items.status()}
                        value={ec2InstanceStatusTranslator(instance.status)}/>,
                    <InfoItem
                        key="stopTime"
                        location="all"
                        title={localization.info.items.stopTime()}
                        value={
                            _.isNil(instanceModel.stopTime)
                                ? undefined
                                : TimeFormatter.profileFormatDateTime(instanceModel.stopTime)}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={instanceModel.dnsZoneIds}
                        entityTypeName={Contract.TypeNames.Entity}
                        key="dnsZoneIds"
                        title={localization.info.items.dnsZoneIds()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={instance.roleReference?.idReference}
                        entityTypeName={Contract.TypeNames.AwsIamRole}
                        key="roleReference"
                        location="all"
                        title={localization.info.items.roleReference()}/>,
                    <InfoItem
                        key="keyName"
                        title={localization.info.items.keyName()}
                        value={instance.keyName}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={instanceModel.autoScalingGroupId}
                        entityTypeName={Contract.TypeNames.AwsAutoScalingAutoScalingGroup}
                        key="autoScalingGroupId"
                        title={localization.info.items.autoScalingGroup()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={instance?.launchTemplateRevisionReference?.launchTemplateIdReference}
                        entityTypeName={Contract.TypeNames.AwsEc2LaunchTemplate}
                        key="launchTemplate"
                        title={localization.info.items.launchTemplate()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={instanceModel.launchConfigurationId}
                        entityTypeName={Contract.TypeNames.AwsAutoScalingLaunchConfiguration}
                        key="launchConfiguration"
                        title={localization.info.items.launchConfiguration()}/>,
                    _.isNil(instanceModel.clusterId)
                        ? undefined
                        : <EntitiesInfoItem
                            entityIdsOrModels={instanceModel.clusterId}
                            entityTypeName={Contract.TypeNames.AwsEksCluster}
                            key="cluster"
                            title={localization.info.items.cluster()}/>,
                    commonVirtualMachineDiskResourceInfoItemElements.operatingSystemDisplayNameInfoElement,
                    commonVirtualMachineDiskResourceInfoItemElements.operatingSystemTypeInfoElement,
                    <InfoItem
                        key="architecture"
                        title={localization.info.items.architecture()}
                        value={instance.architecture}/>,
                    <InfoItem
                        key="cpuCoresCount"
                        title={localization.info.items.cpuCoresCount()}
                        value={instance.cpuCoresCount}/>,
                    commonVirtualMachineDiskResourceInfoItemElements.vulnerabilitiesInfoElement,
                    commonVirtualMachineDiskResourceInfoItemElements.workloadAnalysisStatusInfoElement,
                    commonVirtualMachineDiskResourceInfoItemElements.scanTimeInfoElement,
                    commonVirtualMachineResourceInfoItemElements.scanSourceInfoElement,
                    <EntitiesInfoItem
                        entityIdsOrModels={distributionIds}
                        entityTypeName={Contract.TypeNames.AwsCloudFrontDistribution}
                        key="cloudFrontDistributions"
                        title={localization.info.items.cloudFrontDistributions()}/>,
                    <InfoItem
                        key="productCodeType"
                        title={localization.info.items.productCodeType()}
                        value={
                            _.isNil(instance.productCode)
                                ? undefined
                                : resourceProductCodeTypeTranslator(instance.productCode.type)}/>,
                    <InfoItem
                        key="productCodeId"
                        title={localization.info.items.productCodeId()}
                        value={
                            _.isNil(instance.productCode)
                                ? undefined
                                : instance.productCode.id}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={instance.vpcIds}
                        entityTypeName={Contract.TypeNames.AwsEc2Vpc}
                        key="vpc"
                        location="miniGlance"
                        title={localization.info.items.vpc()}/>
                ]}
                options={options?.infoOptions}>
                <NetworkingInfoCard>
                    {commonNetworkCardInfoItems.inboundAccessType}
                    {commonNetworkCardInfoItems.inboundExternalAccessScope}
                    {commonNetworkCardInfoItems.getPublicIpAddresses()}
                    {commonNetworkCardInfoItems.getPrivateIpAddresses()}
                    <VerticalTopItemsInfoItem
                        items={instance.publicIpAddressDnsNames}
                        key="publicIpAddressDnsNames"
                        title={localization.info.items.publicIpAddressDnsNames()}/>
                    <VerticalTopItemsInfoItem
                        items={instance.privateDnsNames}
                        key="privateDnsNames"
                        title={localization.info.items.privateDnsNames()}/>
                    {commonNetworkCardInfoItems.getVpcs()}
                    {commonNetworkCardInfoItems.getSubnets()}
                    {commonNetworkCardInfoItems.getNetworkInterfaces()}
                    {commonNetworkCardInfoItems.getSecurityGroups()}
                    {commonNetworkCardInfoItems.getElasticIps()}
                    {commonNetworkCardInfoItems.getLoadBalancers()}
                </NetworkingInfoCard>
            </Info>,
        topbarMenuItems: _.filter([
            entityExcessivePermissionCustomEvaluationData.nonexcessivePermitterCustomGenerationEnabled
                ? new DialogMenuItem(
                    onClose =>
                        <GenerateAwsResourceNonexcessivePolicyDialog
                            entityExcessivePermissionCustomEvaluationData={entityExcessivePermissionCustomEvaluationData}
                            entityModel={instanceModel}
                            onClose={onClose}/>,
                    localization.topbar.menu.generate(),
                    { icon: <GenerateEntityNonexcessivePermitterIcon/> })
                : undefined,
            getVirtualMachinePriorityScanRequestDialogMenuItem()
        ]) as DialogMenuItem[]
    });
}