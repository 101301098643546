import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { EntityExternalConsoleLink } from "../../components";
import { useGetAzureStorageStorageAccountRiskContext } from "../contexts";

export function useAzureStorageStorageAccountDefaultEncryptionRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AzureStorageStorageAccountDefaultEncryptionRisk;
    const storageAccountModel = entityModelStore.useGet(risk.entityId) as Contract.AzureStorageStorageAccountModel;

    const getAzureStorageStorageAccountRiskContext = useGetAzureStorageStorageAccountRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.compliance.useAzureStorageStorageAccountDefaultEncryptionRiskDefinition",
            () => ({
                description: "{{storageAccount}} is not configured with encryption using CMK",
                sections: {
                    resolution: {
                        step1: "Select the **Encryption** tab",
                        step2: "For the **Encryption type**, select **Customer-managed keys**",
                        step3: "Select the key vault and encryption key",
                        step4: "Click **Save**"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            storageAccount:
                <Entity
                    entityIdOrModel={storageAccountModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={risk.entityId}
                key={risk.entityId}
                page={Contract.AzureConsoleEntityPage.Encryption}/>,
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4()
        ],
        riskModel,
        () => {
            const storageAccountRiskContext = getAzureStorageStorageAccountRiskContext(storageAccountModel);
            return [
                storageAccountRiskContext.generalInformation,
                storageAccountRiskContext.sensitive,
                storageAccountRiskContext.inboundExternalAccessScope,
                storageAccountRiskContext.childResources,
                storageAccountRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}