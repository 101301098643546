import { defined } from "@infrastructure";
import React, { Fragment, ReactNode } from "react";

export type DataTableActionProps = {
    children: ReactNode;
};

export function DataTableAction(props: DataTableActionProps) {
    defined(props);
    return <Fragment/>;
}