import { CircularProgress, Grid2, Stack, TextField, Typography } from "@mui/material";
import _ from "lodash";
import React, { useState } from "react";
import { TimeSpanHelper, useExecuteOperation, useLocalization } from "@infrastructure";
import { ConfigurationController, Contract, useTheme } from "../../../../../../../../common";

export function EndOfLife() {
    const theme = useTheme();
    return (
        <Grid2
            size={{ xs: 8 }}
            sx={{
                height: "100%",
                maxWidth: theme.spacing(125),
                overflowY: "auto",
                padding: theme.spacing(3, 4)
            }}>
            <Stack spacing={2}>
                <OperatingSystemEndOfLifeTimeFrame/>
            </Stack>
        </Grid2>);
}
function OperatingSystemEndOfLifeTimeFrame() {
    const localization =
        useLocalization(
            "views.customer.configuration.workloadAnalysis.endOfLife.operatingSystemEndOfLifeTimeFrame",
            () => ({
                title: "Operating System End-of-Life Alert Threshold"
            }));

    const [{ operatingSystemEndOfLifeTimeFrame: initialEndOfLifeTimeFrame }] =
        useExecuteOperation(
            OperatingSystemEndOfLifeTimeFrame,
            ConfigurationController.getWorkloadAnalysisOperatingSystemEndOfLifeTimeFrame);

    const [operatingSystemEndOfLifeTimeFrame, setOperatingSystemEndOfLifeTimeFrame] = useState(TimeSpanHelper.getDays(initialEndOfLifeTimeFrame));
    const [loading, setLoading] = useState(false);

    const theme = useTheme();
    return (
        <Stack spacing={1.5}>
            <Stack
                direction="row"
                spacing={1}>
                <Typography variant="h4">
                    {localization.title()}
                </Typography>
                {loading &&
                    <CircularProgress
                        size={theme.spacing(2)}
                        variant="indeterminate"/>}
            </Stack>
            <TextField
                label="Days"
                size="small"
                slotProps={{
                    htmlInput: {
                        max: 100,
                        min: 1
                    }
                }}
                sx={{
                    marginLeft: theme.spacing(1),
                    width: theme.spacing(16)
                }}
                type="number"
                value={operatingSystemEndOfLifeTimeFrame}
                variant="outlined"
                onChange={
                    async event => {
                        const operatingSystemEndOfLifeDays = _.parseInt(event.target.value);
                        if (!_.isNaN(operatingSystemEndOfLifeDays) &&
                            operatingSystemEndOfLifeDays > 0 &&
                            operatingSystemEndOfLifeDays <= 180) {
                            setLoading(true);
                            await ConfigurationController.updateWorkloadAnalysisOperatingSystemEndOfLifeTimeFrame(new Contract.ConfigurationControllerUpdateWorkloadAnalysisOperatingSystemEndOfLifeTimeFrameRequest(TimeSpanHelper.fromDays(operatingSystemEndOfLifeDays)));
                            setOperatingSystemEndOfLifeTimeFrame(operatingSystemEndOfLifeDays);
                            setLoading(false);
                        }}}/>
        </Stack>);
}