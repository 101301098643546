import React, { Fragment } from "react";
import { CommentIcon, RisksIcon } from "../../../../../../../../../../common";
import { CodeIcon } from "../../../../../../../../../../tenants";
import { RiskDefinitionSectionCategory } from "../../../../../../hooks/useDefinition/utilities";
import { RemediationIcon } from "./icons";

type ViewIconProps = {
    view: RiskDefinitionSectionCategory;
};

export function CategoryIcon({ view }: ViewIconProps) {
    switch (view) {
        case RiskDefinitionSectionCategory.Activity:
            return <CommentIcon/>;
        case RiskDefinitionSectionCategory.CodeResource:
            return <CodeIcon/>;
        case RiskDefinitionSectionCategory.Overview:
            return <RisksIcon/>;
        case RiskDefinitionSectionCategory.Resolution:
            return <RemediationIcon/>;
        default:
            return <Fragment/>;
    }
}