import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function SplunkIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                d="M1 5.88889C1 3.18883 3.18883 1 5.88889 1H18.1111C20.8112 1 23 3.18883 23 5.88889V18.1111C23 20.8112 20.8112 23 18.1111 23H5.88889C3.18883 23 1 20.8112 1 18.1111V5.88889Z"
                fill="#65A637"/>
            <path
                d="M17.2942 13.5591L5.88889 18.8283V15.8685L13.901 12.4336V12.2835L5.88889 8.8486V5.88889L17.2942 11.158V13.5591Z"
                fill="white"/>
        </SvgIcon>);
}