﻿import { useLocalization } from "@infrastructure";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../..";
import { useGcpCommonServiceAccountOriginatorScopeResourceInfoElements, useGcpDefaultResourceInfoItemElements, useGcpDefaultScopeResourceInfoItemElements } from "..";
import { Contract, InfoItem } from "../../../../../../../../../../../common";
import { EntitiesInfoItem, Info } from "../../../../../components";
import { GcpScopeResourceRoleBindings } from "../../../components";
import { useCustomEntityPropertyInfoItemElements } from "../../useCustomEntityPropertyInfoItemElements";
import { FirewallRuleInfoCard } from "./components";

export function useGcpAppEngineApplicationDefinition(scopeResourceModel: Contract.GcpScopeResourceModel, options?: DefinitionOptions) {
    const applicationModel = scopeResourceModel as Contract.GcpAppEngineApplicationModel;
    const application = applicationModel.entity as Contract.GcpAppEngineApplication;
    const commonServiceAccountOriginatorScopeResourceInfoElements = useGcpCommonServiceAccountOriginatorScopeResourceInfoElements(scopeResourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(scopeResourceModel);
    const defaultResourceInfoItemElements = useGcpDefaultResourceInfoItemElements(scopeResourceModel);
    const defaultScopeResourceInfoItemElements = useGcpDefaultScopeResourceInfoItemElements(scopeResourceModel);

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpAppEngineApplicationDefinition",
            () => ({
                info: {
                    items: {
                        authenticationDomain: "Auth Domain",
                        defaultBucketId: "Default Bucket",
                        dnsName: "DNS Name",
                        serviceIds: "App Engine Services"
                    }
                },
                tabs: {
                    resourceRoleBindings: "Resource Role Bindings"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <GcpScopeResourceRoleBindings
                    csvExportFilePrefix={localization.tabs.resourceRoleBindings()}
                    scopeResourceModel={applicationModel}/>,
                localization.tabs.resourceRoleBindings(),
                "resourceRoleBindings")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    ...defaultScopeResourceInfoItemElements,
                    commonServiceAccountOriginatorScopeResourceInfoElements.serviceAccount,
                    <EntitiesInfoItem
                        entityIdsOrModels={applicationModel.serviceIds}
                        entityTypeName={Contract.TypeNames.GcpAppEngineApplicationService}
                        key="serviceIds"
                        title={localization.info.items.serviceIds()}/>,
                    <InfoItem
                        key="authenticationDomain"
                        title={localization.info.items.authenticationDomain()}
                        value={application.authenticationDomain}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={applicationModel?.defaultBucketId}
                        entityTypeName={Contract.TypeNames.GcpStorageBucket}
                        key="defaultBucketId"
                        title={localization.info.items.defaultBucketId()}/>,
                    <InfoItem
                        key="dnsName"
                        title={localization.info.items.dnsName()}
                        value={application.dnsName}/>
                ]}
                options={options?.infoOptions}>
                <FirewallRuleInfoCard firewallRules={application.firewallRules}/>
            </Info>
    });
}