﻿import { Link, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../..";
import { useGetGcpContainerClusterRiskContext } from "..";
import { Contract, Entity, entityModelStore, InlineEntities, SeveritySquare, useSeverityTranslator } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { EntityExternalConsoleLink } from "../../components";

export function useGcpContainerClusterWorkloadIdentityDisabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.GcpContainerClusterWorkloadIdentityDisabledRisk;
    const clusterModel = entityModelStore.useGet(risk.entityId) as Contract.GcpContainerClusterModel;
    const cluster = clusterModel.entity as Contract.GcpContainerCluster;
    const workloadIdentityDisabledRiskModel = riskModel as Contract.GcpContainerClusterWorkloadIdentityDisabledRiskModel;

    const getGcpContainerClusterRiskContext = useGetGcpContainerClusterRiskContext();

    const severityTranslator = useSeverityTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.useGcpContainerClusterWorkloadIdentityDisabledRiskDefinition",
            () => ({
                contextItems: {
                    nodePoolDatas: "The cluster has {{nodePools}} with metadata server disabled",
                    sensitiveNodePools: [
                        "Out of the list of node pools with metadata server disabled, {{nodePools}} is sensitive",
                        "Out of the list of node pools with metadata server disabled, {{nodePools}} are sensitive"
                    ],
                    severePermissionActionServiceAccountNodePools: [
                        "Out of the list of node pools with metadata server disabled, {{nodePools}} is attached to {{serviceAccounts}} with {{severitySquare}}**{{permissionActionSeverity}}** severity permissions",
                        "Out of the list of node pools with metadata server disabled, {{nodePools}} are attached to {{serviceAccounts}} with {{severitySquare}}**{{permissionActionSeverity}}** severity permissions"
                    ],
                    workloadIdentityDisabled: "The cluster has Workload Identity disabled"
                },
                description: "{{cluster}} has node pools in which the Metadata Server is disabled",
                sections: {
                    resolution: {
                        step1: "In the **Details** tab, under **Security**, click on the pencil icon next to **Workload Identity**",
                        step2: "Check the **Enable Workload Identity** checkbox and click **Save Changes**",
                        step3: "Once the cluster has updated, go to the **Nodes** tab",
                        step4: "Click on a Node pool within the cluster",
                        step5: "Click on the **Edit** button",
                        step6: "Under **Security**, select **Enable GKE Metadata Server**",
                        step7: "Click **Save**",
                        step8: "Repeat these steps for any additional node pools in the cluster",
                        step9: "Note: When the GKE Metadata Server and Workload Identity settings are enabled, unless the Pod is running on the host network, Pods cannot use the Compute Engine default service account. \nYou may also need to modify workloads so that they can use Workload Identity, as described here: {{clusterWorkloadIdentityDocumentationLink}}"
                    }
                }
            }));

    const severePermissionActionSeverityServiceAccountNodePoolDatas =
        _.filter(
            risk.nodePoolDatas,
            nodePoolData => !_.isNil(nodePoolData.severePermissionActionSeverityServiceAccountId));
    const severePermissionActionSeverityServiceAccountIds =
        _(severePermissionActionSeverityServiceAccountNodePoolDatas).
            map(nodePoolData => nodePoolData.severePermissionActionSeverityServiceAccountId!).
            uniq().
            value();
    const sensitiveNodePoolIds =
        _(risk.nodePoolDatas).
            filter(nodePoolData => nodePoolData.sensitive).
            map(nodePoolData => nodePoolData.id).
            value();

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            cluster:
                <Entity
                    entityIdOrModel={risk.entityId}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={risk.entityId}
                key={`${risk.entityId}-${Contract.GcpConsolePage.Resource}`}
                page={Contract.GcpConsolePage.Resource}/>,
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4(),
            localization.sections.resolution.step5(),
            localization.sections.resolution.step6(),
            localization.sections.resolution.step7(),
            localization.sections.resolution.step8(),
            localization.sections.resolution.step9({
                clusterWorkloadIdentityDocumentationLink:
                    <Link
                        urlOrGetUrl={workloadIdentityDisabledRiskModel.clusterWorkloadIdentityDocumentationUrl}
                        variant="external">
                    </Link>
            })
        ],
        riskModel,
        () => {
            const containerClusterRiskContext = getGcpContainerClusterRiskContext(clusterModel);
            return [
                containerClusterRiskContext.generalInformation,
                containerClusterRiskContext.sensitive,
                containerClusterRiskContext.vpc,
                cluster.workloadIdentity
                    ? undefined
                    : new RiskDefinitionContextItem(localization.contextItems.workloadIdentityDisabled()),
                _.isEmpty(risk.nodePoolDatas)
                    ? undefined
                    : new RiskDefinitionContextItem(
                        localization.contextItems.nodePoolDatas({
                            nodePools:
                                <InlineEntities
                                    entityIdsOrModels={
                                        _.map(
                                            risk.nodePoolDatas,
                                            nodePoolData => nodePoolData.id)}
                                    entityTypeName={Contract.TypeNames.GcpContainerClusterNodePool}
                                    variant="itemCountAndType"/>
                        })),
                _.isEmpty(severePermissionActionSeverityServiceAccountNodePoolDatas)
                    ? undefined
                    : new RiskDefinitionContextItem(
                        localization.contextItems.severePermissionActionServiceAccountNodePools(
                            _.size(severePermissionActionSeverityServiceAccountNodePoolDatas),
                            {
                                nodePools:
                                    <InlineEntities
                                        entityIdsOrModels={
                                            _.map(
                                                severePermissionActionSeverityServiceAccountNodePoolDatas,
                                                severePermissionActionSeverityServiceAccountNodePoolData => severePermissionActionSeverityServiceAccountNodePoolData.id)}
                                        entityTypeName={Contract.TypeNames.GcpContainerClusterNodePool}
                                        variant="itemCountAndType"/>,
                                permissionActionSeverity: severityTranslator(risk.serviceAccountPermissionActionMaxSeverity!, "text"),
                                serviceAccounts:
                                    <InlineEntities
                                        entityIdsOrModels={severePermissionActionSeverityServiceAccountIds}
                                        entityTypeName={Contract.TypeNames.IGcpIamServiceAccount}
                                        variant="itemCountAndType"/>,
                                severitySquare: <SeveritySquare severity={risk.serviceAccountPermissionActionMaxSeverity!}/>
                            })),
                _.isEmpty(sensitiveNodePoolIds)
                    ? undefined
                    : new RiskDefinitionContextItem(
                        localization.contextItems.sensitiveNodePools(
                            _.size(sensitiveNodePoolIds),
                            {
                                nodePools:
                                    <InlineEntities
                                        entityIdsOrModels={sensitiveNodePoolIds}
                                        entityTypeName={Contract.TypeNames.GcpContainerClusterNodePool}
                                        variant="itemCountAndType"/>
                            })),
                containerClusterRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        });
}