import { DataTable, DataTableActions, DataTableSort, EmptyMessageText, Optional, useLocalization } from "@infrastructure";
import { Dictionary } from "lodash";
import React, { useRef } from "react";
import { Contract, StorageHelper, useLayoutOptions } from "../../../../../../common";
import { useCommonVirtualMachineImageDefinition, useFetchWorkloadResourceModelPage } from "../../hooks";
import { WorkloadResourceModelRequestFiltersHelper } from "../../utilities";

export function VirtualMachineImages() {
    const dataTableActionsRef = useRef<DataTableActions>();
    const filterMapRef = useRef<Dictionary<any>>();
    const commonVirtualMachineImageDefinition = useCommonVirtualMachineImageDefinition();
    const localization =
        useLocalization(
            "views.customer.workloadAnalysis.virtualMachineImages",
            () => ({
                empty: {
                    withFilter: "No Matching Virtual Machine Images",
                    withoutFilter: "No Virtual Machine Images"
                },
                title: "Virtual Machine Images"
            }));

    useLayoutOptions({ view: { title: localization.title() } });

    const sortRef = useRef<Optional<DataTableSort>>();

    const fetchWorkloadResourceModelPage = useFetchWorkloadResourceModelPage(
        dataTableActionsRef,
        WorkloadResourceModelRequestFiltersHelper.getVirtualMachineImageFilters,
        Contract.WorkloadControllerGetWorkloadResourceModelPageVirtualMachineImageRequest
    );

    return <DataTable
        actionsRef={dataTableActionsRef}
        columnOptions={{
            orderOptions: {
                enabled: true,
                persistenceStorageItem: StorageHelper.customerWorkloadAnalysisVirtualMachinesImagesTableColumnOrder
            },
            resizable: true,
            selectorOptions: {
                enabled: true,
                persistenceStorageItem: StorageHelper.customerWorkloadAnalysisVirtualMachinesImagesTableColumnSelector
            },
            stickyColumnId: Contract.WorkloadControllerRequestProperty.WorkloadResourceId
        }}
        emptyMessageOptions={{
            emptyMessageText:
                new EmptyMessageText(
                    localization.empty.withoutFilter(),
                    localization.empty.withFilter())
        }}
        fetchItems={fetchWorkloadResourceModelPage}
        filtersOptions={{
            onChanged: filterMap => filterMapRef.current = filterMap,
            persist: {
                visibilityStorageItem: StorageHelper.customerWorkloadAnalysisVirtualMachinesImagesTableColumnTableFiltersSelector
            }
        }}
        getItemId={(workloadResourceModel: Contract.WorkloadResourceModel) => workloadResourceModel.id}
        virtualizationEnabled={true}
        onSortChanged={sort => sortRef.current = sort}>
        {commonVirtualMachineImageDefinition.virtualMachineImageName}
        {commonVirtualMachineImageDefinition.virtualMachineImageId}
        {commonVirtualMachineImageDefinition.tenantId}
        {commonVirtualMachineImageDefinition.creationTime}
        {commonVirtualMachineImageDefinition.management}
        {commonVirtualMachineImageDefinition.access}
        {commonVirtualMachineImageDefinition.virtualMachines}
        {commonVirtualMachineImageDefinition.operatingSystemDisplayName}
        {commonVirtualMachineImageDefinition.operatingSystemType}
        {commonVirtualMachineImageDefinition.packageDisplayName}
        {commonVirtualMachineImageDefinition.vulnerabilities}
        {commonVirtualMachineImageDefinition.risksSeverity}
        {commonVirtualMachineImageDefinition.workloadAnalysisStatus}
        {commonVirtualMachineImageDefinition.scanTime}
        {commonVirtualMachineImageDefinition.attributes}
        {commonVirtualMachineImageDefinition.regionId}
        {commonVirtualMachineImageDefinition.resourceTags}
    </DataTable>;
}