﻿import { Tooltip, useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import React from "react";
import { useTheme } from "../../../../..";

type PermissionActionCountProps = {
    count: number;
    excessiveCount: number;
    selected: boolean;
};

export function ExcessivePermissionActionCount({ count, excessiveCount, selected }: PermissionActionCountProps) {
    const localization =
        useLocalization(
            "common.access.entitiesPermissionActions.excessivePermissionActionCount",
            () => ({
                info: "{{excessiveCount | NumberFormatter.humanize}} excessive {{count | NumberFormatter.humanize}} granted",
                title: "{{excessivePercent | NumberFormatter.percentage}} excessive"
            }));
    const theme = useTheme();
    return (
        <Tooltip
            titleOrGetTitle={
                localization.info({
                    count,
                    excessiveCount
                })}>
            <Typography
                noWrap={true}
                sx={{
                    color: theme.palette.text.primary,
                    fontWeight:
                        selected
                            ? "bold"
                            : undefined
                }}>
                {localization.title({ excessivePercent: excessiveCount / count })}
            </Typography>
        </Tooltip>);
}