import { DataTableColumn, DataTableColumnRenderProps, EnumValuesFilter, useLocalization } from "@infrastructure";
import { Dictionary } from "lodash";
import React from "react";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, Entity, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { useGcpArtifactRegistryTypeTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";
import { useGcpCommonEntityDefinition } from "./useGcpCommonEntityDefinition";

export function useGcpArtifactPackageRepositoryDefinition(definitionData: DefinitionData) {
    const commonEntityDefinition = useGcpCommonEntityDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const artifactRegistryTypeTranslator = useGcpArtifactRegistryTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.gcp.useGcpArtifactPackageRepositoryDefinition",
            () => ({
                columns: {
                    location: "Location",
                    registry: "Registry",
                    registryType: "Format"
                }
            }));

    return new EntityTableDefinition(
        [
            commonEntityDefinition.columns.tenantColumn,
            commonEntityDefinition.columns.creationTimeColumn,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.GcpArtifactPackageRepositoryModel>(
                        Contract.TypeNames.GcpArtifactRegistry,
                        item => [item.registrySearchableReference.id],
                        localization.columns.registry())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.GcpArtifactPackageRepositoryRegistry)}
                                placeholder={localization.columns.registry()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpArtifactPackageRepositoryRegistry}
                key={Contract.EntityModelProperty.GcpArtifactPackageRepositoryRegistry}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.GcpArtifactPackageRepositoryModel>) =>
                        <Entity
                            entityIdOrModel={item.registrySearchableReference.id}
                            variant="iconText"/>}
                title={localization.columns.registry()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.GcpArtifactPackageRepositoryModel) => ({
                            [localization.columns.registryType()]: artifactRegistryTypeTranslator(item.registryType)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.GcpArtifactRegistryType}
                                enumTypeTranslator={artifactRegistryTypeTranslator}
                                placeholder={localization.columns.registryType()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpArtifactPackageRepositoryRegistryType}
                itemProperty={(item: Contract.GcpArtifactPackageRepositoryModel) => artifactRegistryTypeTranslator(item.registryType)}
                key={Contract.EntityModelProperty.GcpArtifactPackageRepositoryRegistryType}
                title={localization.columns.registryType()}/>,
            commonEntityDefinition.columns.regionColumn,
            commonEntityDefinition.columns.regionLocationColumn,
            commonEntityDefinition.columns.attributesColumn,
            commonEntityDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonEntityDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonEntityDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageGcpArtifactPackageRepositoryRequest(
                new Contract.EntityControllerGetEntityModelPageGcpArtifactPackageRepositoryRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpArtifactPackageRepositoryRegistry]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpArtifactPackageRepositoryRegistryType])),
                limit,
                skip,
                requestSort));
}