import { NavigationViewItem, useChangeEffect } from "@infrastructure";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import { Contract, useObjectTypeCategoryTranslator } from "../../../../../../..";
import { ObjectSelector, ObjectSelectorItem } from "../..";
import { ObjectTypeMetadataModelHelper } from "../../../../../../../utilities";
import { useUdmObjectTypeNameTranslator } from "../../../../../hooks";
import { ObjectTypeCategoryIcon } from "../../../icons";
import { UdmObject } from "../../UdmObject";

type PopupProps = {
    onSelectedObjectTypeNameChanged: (typeName: string) => void;
    relatedObjectTypeName?: string;
    selectedObjectTypeName?: string;
};

export function Popup({ onSelectedObjectTypeNameChanged, relatedObjectTypeName, selectedObjectTypeName: initialSelectedObjectTypeName }: PopupProps) {
    const objectTypeCategoryTranslator = useObjectTypeCategoryTranslator();
    const udmObjectTypeNameTranslator = useUdmObjectTypeNameTranslator();

    const objectCategoryToTypeMetadataModelsMap =
        useMemo(
            () => {
                const objectTypeMetadataModels =
                    _.isEmpty(relatedObjectTypeName)
                        ? ObjectTypeMetadataModelHelper.getAll()
                        : _(ObjectTypeMetadataModelHelper.get(relatedObjectTypeName!).udmProperties).
                            filter(property => !_.isNil(property.relation)).
                            map(property => ObjectTypeMetadataModelHelper.get(property.relation!.objectTypeName)).
                            value();
                return _.groupBy(
                    objectTypeMetadataModels,
                    objectTypeMetadataModel => objectTypeMetadataModel.category);
            },
            [relatedObjectTypeName]);

    const objectSelectorItems =
        useMemo(
            () =>
                _(objectCategoryToTypeMetadataModelsMap).
                    keys().
                    orderBy([
                        objectTypeCategory => objectTypeCategory === Contract.ObjectTypeCategory.Risk,
                        objectTypeCategory => objectTypeCategoryTranslator(objectTypeCategory as Contract.ObjectTypeCategory)
                    ]).
                    map<ObjectSelectorItem>(
                        objectTypeCategory =>
                            new ObjectSelectorItem(
                                objectTypeCategory,
                                objectTypeCategoryTranslator(objectTypeCategory as Contract.ObjectTypeCategory),
                                objectTypeCategoryTranslator(objectTypeCategory as Contract.ObjectTypeCategory),
                                {
                                    icon:
                                        <ObjectTypeCategoryIcon category={objectTypeCategory as Contract.ObjectTypeCategory}/>,
                                    items:
                                        _(objectCategoryToTypeMetadataModelsMap[objectTypeCategory]).
                                            map(
                                                objectCategoryTypeMetadataModel =>
                                                    new NavigationViewItem(
                                                        objectCategoryTypeMetadataModel.typeName,
                                                        udmObjectTypeNameTranslator(objectCategoryTypeMetadataModel.typeName),
                                                        <UdmObject objectTypeName={objectCategoryTypeMetadataModel.typeName}/>)).
                                            orderBy(item => item.searchText).
                                            value()
                                })).
                    value(),
            [objectCategoryToTypeMetadataModelsMap]);

    const [selectedObjectTypeName, setSelectedObjectTypeName] = useState(initialSelectedObjectTypeName);

    useChangeEffect(
        () => {
            if (!_.isNil(selectedObjectTypeName)) {
                onSelectedObjectTypeNameChanged(selectedObjectTypeName);
            }
        },
        [selectedObjectTypeName]);

    return (
        <ObjectSelector
            items={objectSelectorItems}
            selectedItemId={selectedObjectTypeName}
            onSelectedItemIdChanged={itemId => setSelectedObjectTypeName(itemId)}/>);
}