﻿import { Action1 } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React, { useEffect, useMemo } from "react";
import { Contract } from "../../../../../controllers";
import { useAccessContext } from "../../../Access";
import { AccessGraphHelper, AccessGraphPermissionEdgeData, EntitiesPermissionActionsEntity } from "../../../utilities";
import { Entities } from "./Entities";

type DestinationEntitiesProps = {
    entityIdToSearchableReferenceMap: Dictionary<Contract.EntitySearchableReference>;
    excessivePermissionsEnabled: boolean;
    onSelectedEntityChanged: Action1<string>;
    permissionEdgeIdToDataMap: Dictionary<AccessGraphPermissionEdgeData>;
    resourceServiceModelMap: Dictionary<Contract.EntityModel>;
    selectedDestinationEntityId?: string;
    serviceId: string;
    sourceEntityId: string;
};

export function DestinationEntities({ entityIdToSearchableReferenceMap, excessivePermissionsEnabled, onSelectedEntityChanged, permissionEdgeIdToDataMap, resourceServiceModelMap, selectedDestinationEntityId, serviceId, sourceEntityId }: DestinationEntitiesProps) {
    const { definition } = useAccessContext();

    const entities =
        useMemo(
            () => {
                const destinationEntityIdToEntityMap: Dictionary<EntitiesPermissionActionsEntity> = {};
                for (const permissionEdgeData of _.values(permissionEdgeIdToDataMap)) {
                    if (!permissionEdgeData.sourceEntityIds.has(sourceEntityId) ||
                        permissionEdgeData.destinationEntitiesServiceId !== serviceId) {
                        continue;
                    }

                    for (const destinationEntityId of permissionEdgeData.destinationEntityIds) {
                        let entity = destinationEntityIdToEntityMap[destinationEntityId];
                        if (_.isNil(entity)) {
                            entity = new EntitiesPermissionActionsEntity(destinationEntityId, 0, 0);
                            destinationEntityIdToEntityMap[destinationEntityId] = entity;
                        }

                        entity.excessivePermissionActionCount += permissionEdgeData.excessivePermissionActionCount;
                        entity.nonexcessivePermissionActionCount += permissionEdgeData.nonexcessivePermissionActionCount;
                    }
                }

                return AccessGraphHelper.orderEntities(
                    _.values(destinationEntityIdToEntityMap),
                    entityIdToSearchableReferenceMap,
                    resourceServiceModelMap);
            },
            []);

    useEffect(
        () => {
            onSelectedEntityChanged(entities[0].entityId);
        },
        []);

    return (
        <Entities
            entities={entities}
            entityTypeName={definition.entitiesPermissionActions.entityTypeNames.destinationEntityTypeName}
            excessivePermissionsEnabled={excessivePermissionsEnabled}
            selectedEntityId={selectedDestinationEntityId}
            onSelectedEntityChanged={entity => onSelectedEntityChanged(entity.entityId)}/>);
}