import { Shadows, Tooltip, useLocalization } from "@infrastructure";
import { Box, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, UserHelper, useTheme } from "../../../../../../../../../../common";
import { GoogleIcon, MicrosoftIcon } from "../../../../../../../../../../common/components/SignIn/icons";
import { AuthenticationItem, AuthenticationType, useAuthenticationContext } from "../../../../Authentication";
import { useItems } from "../../../../hooks";
import { SamlIcon } from "./icons";

type SelectIdentityProviderProps = {
    onItemClick: (item: AuthenticationItem) => void;
};

export function SelectIdentityProvider({ onItemClick }: SelectIdentityProviderProps) {
    const { aadIdentityProviderEnabled, consoleAppTypeToSamlIdentityProviderMap, gciIdentityProviderEnabled } = useAuthenticationContext();
    const { notConfiguredAuthenticationItems } =
        useItems(
            aadIdentityProviderEnabled,
            consoleAppTypeToSamlIdentityProviderMap,
            gciIdentityProviderEnabled);

    const localization =
        useLocalization(
            "views.customer.configuration.authentication.addOrEdit.selectIdentityProvider",
            () => ({
                azure: "Microsoft",
                google: "Google",
                saml: "SAML",
                tooltip: {
                    configured: "This provider is already enabled"
                }
            }));

    const authenticationItemOptions =
        useMemo(
            (): AuthenticationItemOption[] =>
                [
                    {
                        icon: <MicrosoftIcon/>,
                        items:
                            _.filter(
                                notConfiguredAuthenticationItems,
                                ({ fieldKey }) => fieldKey === "aadIdentityProviderEnabled"),
                        text: localization.azure()
                    },
                    {
                        icon: <GoogleIcon/>,
                        items:
                            _.filter(
                                notConfiguredAuthenticationItems,
                                ({ fieldKey }) => fieldKey === "gciIdentityProviderEnabled"),
                        text: localization.google()
                    },
                    {
                        icon: <SamlIcon/>,
                        items:
                            _.filter(
                                notConfiguredAuthenticationItems,
                                ({ applications, authenticationType, fieldKey }) => {
                                    if (authenticationType === AuthenticationType.Saml && !_.includes(UserHelper.enabledConsoleAppTypes, Contract.ConsoleAppType.User)) {
                                        return !_.includes(applications, Contract.ConsoleAppType.User);
                                    }
                                    return fieldKey === "consoleAppTypeToSamlIdentityProviderMap";
                                }),
                        text: localization.saml()
                    }
                ],
            [notConfiguredAuthenticationItems]);

    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            direction="row"
            justifyContent="center"
            sx={{
                paddingBottom: theme.spacing(5),
                paddingTop: theme.spacing(7)
            }}>
            {_.map(
                authenticationItemOptions,
                authenticationItemOption => {
                    const disabled = !authenticationItemOption.items.length;
                    return (
                        <Tooltip
                            key={authenticationItemOption.text}
                            titleOrGetTitle={
                                disabled
                                    ? <Typography noWrap={true}>
                                        {localization.tooltip.configured()}
                                    </Typography>
                                    : undefined}>
                            <Stack
                                spacing={1.5}
                                sx={{
                                    "&:hover": {
                                        boxShadow: theme.shadows[Shadows.Card]
                                    },
                                    alignItems: "center",
                                    backgroundColor: theme.palette.background.alternate,
                                    border: theme.border.primary,
                                    borderRadius: theme.spacing(0.75),
                                    cursor: "pointer",
                                    height: theme.spacing(18),
                                    justifyContent: "center",
                                    margin: theme.spacing(0, 2),
                                    width: theme.spacing(22),
                                    ...(disabled && {
                                        "&:hover": {
                                            boxShadow: "none"
                                        },
                                        cursor: "default",
                                        filter: "grayscale(1) brightness(1.0)",
                                        opacity: "0.6"
                                    })
                                }}
                                onClick={
                                    () => {
                                        if (disabled) {
                                            return;
                                        }

                                        onItemClick(authenticationItemOption.items[0]);
                                    }}>
                                <Box
                                    sx={{
                                        color: theme.palette.text.primary,
                                        fontSize: "30px"
                                    }}>
                                    {authenticationItemOption.icon}
                                </Box>
                                <Typography sx={{ fontSize: "14px" }}>
                                    {authenticationItemOption.text}
                                </Typography>
                            </Stack>
                        </Tooltip>);
                })}
        </Stack>);
}

type AuthenticationItemOption = {
    icon: JSX.Element;
    items: AuthenticationItem[];
    text: string;
};