﻿import { TimeFormatter, TimeHelper, Tooltip, useLocalization } from "@infrastructure";
import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { AadDirectoryApplicationObjectExpirationStatusIcon } from ".";
import { Contract, useTheme } from "../../../common";
import { useAadDirectoryApplicationObjectExpirationStatusTranslator } from "../hooks";

type ExpirationProps = {
    expirationDate: string;
    expirationStatus: Contract.AadDirectoryApplicationObjectExpirationStatus;
    variant: "date" | "status" | "statusAndIcon";
};

export function AadDirectoryApplicationExpirationDateCell({ expirationDate, expirationStatus, variant = "status" }: ExpirationProps) {
    const directoryApplicationObjectExpirationStatusTranslator = useAadDirectoryApplicationObjectExpirationStatusTranslator();
    const localization =
        useLocalization(
            "tenants.aad.aadDirectoryApplicationExpirationDateCell",
            () => ({
                expirationDay: "Expires in {{timeToExpiration | TimeSpanFormatter.humanizeDays}}"
            }));

    const theme = useTheme();
    return variant === "date"
        ? expirationStatus === Contract.AadDirectoryApplicationObjectExpirationStatus.Valid
            ? <Typography noWrap={true}>
                {TimeFormatter.shortDate(expirationDate)}
            </Typography>
            : <Stack
                alignItems="center"
                direction="row"
                spacing={1}
                sx={{ overflow: "hidden" }}>
                <Typography
                    noWrap={true}
                    sx={{
                        color:
                            expirationStatus === Contract.AadDirectoryApplicationObjectExpirationStatus.ExpiringSoon
                                ? theme.palette.warning.light
                                : theme.palette.error.main,
                        fontWeight: 700
                    }}>
                    {TimeFormatter.shortDate(expirationDate)}
                </Typography>
                <Tooltip titleOrGetTitle={directoryApplicationObjectExpirationStatusTranslator(expirationStatus)}>
                    <Box>
                        <AadDirectoryApplicationObjectExpirationStatusIcon expirationStatus={expirationStatus}/>
                    </Box>
                </Tooltip>
            </Stack>
        : <Stack
            alignItems="center"
            direction="row"
            spacing={1}
            sx={{ overflow: "hidden" }}>
            {variant === "statusAndIcon"
                ? expirationStatus === Contract.AadDirectoryApplicationObjectExpirationStatus.ExpiringSoon
                    ? <Tooltip titleOrGetTitle={localization.expirationDay({ timeToExpiration: TimeHelper.getInterval(expirationDate) })}>
                        <Box>
                            <AadDirectoryApplicationObjectExpirationStatusIcon expirationStatus={expirationStatus}/>
                        </Box>
                    </Tooltip>
                    : <AadDirectoryApplicationObjectExpirationStatusIcon expirationStatus={expirationStatus}/>
                : undefined}
            <Typography noWrap={true}>
                {directoryApplicationObjectExpirationStatusTranslator(expirationStatus)}
            </Typography>
        </Stack>;
}