import { Sx } from "@infrastructure";
import { Box, TableCell, TableRow, Typography, useTheme } from "@mui/material";
import _ from "lodash";
import React, { FunctionComponentElement, memo, useCallback, useEffect, useState } from "react";
import { getRouteResult, RouteType, setUrlRoute } from "../../../../hooks";
import { browserHashHistory, MouseHelper } from "../../../../utilities";
import { ContextMenu } from "../../../ContextMenu";
import { DataTableRowOptions, useDataTableActionsContext } from "../../DataTable";
import { DataTableColumnProps } from "../DataTableColumn";
import { DataTableSelectionCell } from "../DataTableSelectionCell";
import { ElementClass } from "./DataTableRow.element";

const DataTableRowMemo = memo(DataTableRow);
export { DataTableRowMemo as DataTableRow };

export type DataTableRowProps = {
    cellMaxWidth: "large" | "medium" | "none" | "small";
    columns: FunctionComponentElement<DataTableColumnProps>[];
    highlightedItemExists: boolean;
    highlightItemHashRouteTemplate?: string;
    item: any;
    itemId: string;
    itemSelectionEnabled: boolean;
    options?: DataTableRowOptions;
    selectedVisibleColumnIds: string[];
    selectionEnabled: boolean;
    stickyColumnId?: string;
};

function DataTableRow({ cellMaxWidth, columns, highlightedItemExists, highlightItemHashRouteTemplate, item, itemId, itemSelectionEnabled, options, selectedVisibleColumnIds, selectionEnabled, stickyColumnId }: DataTableRowProps) {
    const { actions } = useDataTableActionsContext();
    const [highlightItem, setHighlightItem] = useState(false);
    const highlightItemHashRoute =
        useCallback(
            () => {
                if (!_.isNil(highlightItemHashRouteTemplate)) {
                    const { itemId: routeItemId } =
                        getRouteResult(
                            RouteType.Hash,
                            highlightItemHashRouteTemplate);
                    setHighlightItem(routeItemId === itemId);
                }
            },
            [highlightItemHashRouteTemplate]);
    useEffect(
        () => {
            if (!_.isNil(highlightItemHashRouteTemplate)) {
                highlightItemHashRoute();
                return browserHashHistory.listen(_ => highlightItemHashRoute());
            }
        },
        [highlightItemHashRoute]);

    const theme = useTheme();
    return (
        <TableRow
            className={ElementClass.dataTableRow}
            id={itemId}
            sx={
                Sx.combine(
                    {
                        backgroundColor:
                            highlightItem
                                ? theme.palette.action.selected
                                : theme.palette.background.paper,
                        "body:not(.col-drag, .col-resize) &:hover": {
                            backgroundColor:
                                highlightItem
                                    ? theme.palette.action.selected
                                    : theme.palette.action.hover
                        }
                    },
                    options?.getSx?.(item))}>
            {(itemSelectionEnabled || highlightedItemExists) &&
                <TableCell
                    className={ElementClass.dataTableRowCell}
                    sx={{
                        background: "inherit",
                        padding: 0,
                        paddingLeft: theme.spacing(0.75),
                        paddingRight: theme.spacing(0.75),
                        position: "relative",
                        ...(itemSelectionEnabled && {
                            cursor: "default",
                            left: 0,
                            paddingLeft: theme.spacing(2),
                            paddingRight: 0,
                            position: "sticky",
                            zIndex: 1
                        })
                    }}>
                    {highlightedItemExists && (
                        <Box
                            sx={{
                                backgroundColor: options?.getHighlightColor?.(item),
                                height: "100%",
                                left: 0,
                                position: "absolute",
                                top: 0,
                                width: theme.spacing(0.5)
                            }}/>)}
                    {itemSelectionEnabled && (
                        <DataTableSelectionCell
                            disabled={!selectionEnabled}
                            itemId={actions.getItemId(item)}/>)}
                </TableCell>}
            {_(columns).
                filter(column => !!column.props.selectorOptions?.disabled || _.includes(selectedVisibleColumnIds, column.props.id)).
                map(column => {
                    const columnCellMaxWidth = column.props.cellMaxWidth ?? cellMaxWidth;
                    const cellClasses =
                        columnCellMaxWidth === "none"
                            ? undefined
                            : tableCellClasses[columnCellMaxWidth];
                    return (
                        <TableCell
                            className={ElementClass.dataTableRowCell}
                            key={column.props.id}
                            sx={
                                Sx.combine(
                                    {
                                        "&:first-of-type": {
                                            paddingLeft:
                                                !itemSelectionEnabled
                                                    ? theme.spacing(3)
                                                    : undefined
                                        },
                                        background: "inherit",
                                        "body:not(.col-drag, .col-resize) &": {
                                            cursor:
                                                column.props.disableAction ||
                                                    (_.isNil(options?.getUrl) &&
                                                        !itemSelectionEnabled)
                                                    ? undefined
                                                    : "pointer"
                                        },
                                        display:
                                            column.props.selectorOptions?.disabled ||
                                                _.includes(
                                                    selectedVisibleColumnIds,
                                                    column.props.id)
                                                ? undefined
                                                : "none",
                                        overflow: "hidden",
                                        ...(stickyColumnId && stickyColumnId === column.props.id && {
                                            left:
                                                itemSelectionEnabled
                                                    ? 58
                                                    : 0,
                                            position: "sticky",
                                            zIndex: 1
                                        })
                                    },
                                    column.props.cellSx,
                                    cellClasses)}
                            onClick={
                                event => {
                                    if (options?.onBeforeClick?.(event, item) === true) {
                                        return;
                                    }

                                    if (column.props.disableAction ||
                                        MouseHelper.isAlterActionClick(event)) {
                                        return;
                                    }

                                    if (!_.isNil(options?.getUrl)) {
                                        const url = options?.getUrl(item);
                                        if (!_.isNil(url)) {
                                            setUrlRoute(url);
                                        }
                                    } else if (itemSelectionEnabled && selectionEnabled) {
                                        actions.setSelectedItemIds(
                                            _.includes(actions.getSelectedItemIds(), actions.getItemId(item))
                                                ? _.without(actions.getSelectedItemIds(), actions.getItemId(item))
                                                : _.concat(actions.getSelectedItemIds(), actions.getItemId(item)));
                                    }
                                }}>
                            <ContextMenu
                                getMenuItems={column.props.getContextMenuItems}
                                item={item}>
                                {!_.isNil(column.props.render)
                                    ? <column.props.render item={item}/>
                                    : <Typography noWrap={true}>{column.props.itemProperty?.(item)}</Typography>}
                            </ContextMenu>
                        </TableCell>);
                }).
                value()}
            <TableCell
                sx={{
                    background: "inherit",
                    "body:not(.col-drag, .col-resize) &": {
                        cursor:
                            _.isNil(options?.getUrl) &&
                                !itemSelectionEnabled
                                ? undefined
                                : "pointer"
                    },
                    flex: 1,
                    padding: 0
                }}
                onClick={
                    event => {
                        if (options?.onBeforeClick?.(event, item) === true) {
                            return;
                        }

                        if (!_.isNil(options?.getUrl)) {
                            const url = options!.getUrl(item);
                            if (!_.isNil(url)) {
                                setUrlRoute(url);
                            }
                        } else if (itemSelectionEnabled && selectionEnabled) {
                            actions.setSelectedItemIds(
                                _.includes(actions.getSelectedItemIds(), actions.getItemId(item))
                                    ? _.without(actions.getSelectedItemIds(), actions.getItemId(item))
                                    : _.concat(actions.getSelectedItemIds(), actions.getItemId(item)));
                        }
                    }}/>
        </TableRow>);
}

const tableCellClasses = {
    large: {
        maxWidth: "350px"
    },
    medium: {
        maxWidth: "200px"
    },
    small: {
        maxWidth: "150px"
    }
} as const;