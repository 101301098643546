import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function ColoredAadIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                d="M2.31996 13.3333L12.8666 20.1866L23.6133 13.3066L24.96 14.8799L12.8666 22.6666L0.959961 14.8799L2.31996 13.3333Z"
                fill="#50E6FF"/>
            <path
                d="M12.8666 0.82666V18.76L3.10658 12.4533L12.8666 0.82666Z"
                fill="#50E6FF"/>
            <path
                d="M12.8666 0.82666V18.76L22.8265 12.4667L12.8666 0.82666Z"
                fill="url(#paint0_linear_6172_265)"/>
            <path
                d="M12.8665 10.0932L22.8265 12.4667L12.8666 18.76L12.8665 10.0932Z"
                fill="#53B1E0"/>
            <path
                d="M12.8666 18.76L3.10658 12.4533L12.8665 10.0932L12.8666 18.76Z"
                fill="#9CEBFF"/>
            <path
                d="M12.8666 22.6666L24.96 14.8799L23.6133 13.3066L12.8666 20.1866V22.6666Z"
                fill="url(#paint1_linear_6172_265)"/>
            <defs>
                <linearGradient
                    gradientUnits="userSpaceOnUse"
                    id="paint0_linear_6172_265"
                    x1="14.8716"
                    x2="10.3358"
                    y1="20.6531"
                    y2="3.65365">
                    <stop stopColor="#1988D9"/>
                    <stop
                        offset="0.9"
                        stopColor="#54AEF0"/>
                </linearGradient>
                <linearGradient
                    gradientUnits="userSpaceOnUse"
                    id="paint1_linear_6172_265"
                    x1="15.9734"
                    x2="20.2534"
                    y1="13.7066"
                    y2="21.0666">
                    <stop
                        offset="0.1"
                        stopColor="#54AEF0"/>
                    <stop
                        offset="0.29"
                        stopColor="#4FABEE"/>
                    <stop
                        offset="0.51"
                        stopColor="#41A2E9"/>
                    <stop
                        offset="0.74"
                        stopColor="#2A93E0"/>
                    <stop
                        offset="0.88"
                        stopColor="#1988D9"/>
                </linearGradient>
            </defs>
        </SvgIcon>);
}