import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { DataTableColumnRenderProps, IconText, map, TimeFormatter, useLocalization } from "@infrastructure";
import { useApproverUserPermissionRequestsContext } from "../../../../../../..";
import { Contract, PermissionManagementHelper, PermissionRequestCloseReasonIcon, useTheme } from "../../../../../../../../../../../common";
import { usePermissionRequestActivationFailureDataTranslator } from "../../../../../../../../../hooks";
import { useTableContext } from "../../../../../Table";

export function StatusCell({ item }: DataTableColumnRenderProps<Contract.PermissionRequestModel>) {
    const { entityModelMap } = useTableContext();
    const { userIds } = useApproverUserPermissionRequestsContext();

    const approverUserType =
        item.permissionRequest.closeReason === Contract.PermissionRequestCloseReason.Rejected &&
        _.includes(userIds, item.rejectorUserIdReference) ||
        item.permissionRequest.closeReason !== Contract.PermissionRequestCloseReason.Rejected &&
        !_(item.levelToApproverUserIdReferenceMap).
            values().
            intersection(userIds).
            isEmpty()
            ? "self"
            : "other";

    const permissionRequestActivationFailureDataTranslator = usePermissionRequestActivationFailureDataTranslator();
    const localization =
        useLocalization(
            "views.user.approverUserPermissionRequests.table.hooks.useDefinition.hooks.useHistoryDefinition.statusCell",
            () => ({
                status: {
                    closed: {
                        activationFailed: {
                            other: "Approved by someone else (activation failed)",
                            self: "Approved by me (activation failed)"
                        },
                        cancelled: "Request cancelled",
                        expired: {
                            other: "Approved by someone else (expired)",
                            self: "Approved by me (expired)"
                        },
                        rejected: {
                            other: "Denied by someone else",
                            self: "Denied by me"
                        },
                        requestExpired: "Request timed-out",
                        revoked: {
                            other: "Approved by someone else (revoked)",
                            self: "Approved by me (revoked)"
                        }
                    }
                }
            }));
    const theme = useTheme();
    return (
        <IconText
            icon={<PermissionRequestCloseReasonIcon closeReason={item.permissionRequest.closeReason!}/>}
            iconSx={{
                color: PermissionManagementHelper.getPermissionRequestCloseReasonColor(item.permissionRequest.closeReason!),
                fontSize: "18px"
            }}
            text={
                <Stack>
                    <Typography noWrap={true}>
                        {map(
                            item.permissionRequest.closeReason,
                            {
                                [Contract.PermissionRequestCloseReason.ActivationFailed]: () => localization.status.closed.activationFailed[approverUserType](),
                                [Contract.PermissionRequestCloseReason.Cancelled]: () => localization.status.closed.cancelled(),
                                [Contract.PermissionRequestCloseReason.Expired]: () => localization.status.closed.expired[approverUserType](),
                                [Contract.PermissionRequestCloseReason.Rejected]: () => localization.status.closed.rejected[approverUserType](),
                                [Contract.PermissionRequestCloseReason.RequestExpired]: () => localization.status.closed.requestExpired(),
                                [Contract.PermissionRequestCloseReason.Revoked]: () => localization.status.closed.revoked[approverUserType]()
                            })}
                    </Typography>
                    <Typography
                        noWrap={true}
                        sx={{
                            color: theme.palette.text.secondary,
                            fontSize: "10px"
                        }}>
                        {TimeFormatter.longDateTime(
                            item.permissionRequest.closeReason === Contract.PermissionRequestCloseReason.Expired
                                ? item.permissionRequest.expirationTime!
                                : item.permissionRequest.statusUpdateTime)}
                    </Typography>
                </Stack>}
            titleOrGetTitle={
                (item.permissionRequest.closeReason === Contract.PermissionRequestCloseReason.ActivationFailed ||
                    item.permissionRequest.closeReason === Contract.PermissionRequestCloseReason.Expired ||
                    item.permissionRequest.closeReason === Contract.PermissionRequestCloseReason.Revoked) &&
                !_.isNil(item.activationFailureData)
                    ? <Typography sx={{ whiteSpace: "pre-wrap" }}>
                        {permissionRequestActivationFailureDataTranslator(
                            item.activationFailureData,
                            entityModelMap)}
                    </Typography>
                    : undefined}/>);
}