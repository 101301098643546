import { Message, useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import React from "react";
import { useTheme } from "../../../../../common";

export type DataSensitivityCellProps = {
    dataSensitivityLevel: string;
};

export function DataAnalysisSensitivityCell({ dataSensitivityLevel }: DataSensitivityCellProps) {
    const localization =
        useLocalization(
            "views.customer.dataAnalysis.dataAnalysisSensitivityCell",
            () => ({
                info: {
                    title: "Data analysis sensitivity"
                }
            }));

    const theme = useTheme();
    return (
        <Stack
            direction="row"
            spacing={1}
            sx={{ paddingRight: theme.px(1) }}>
            <Typography noWrap={true}>
                {dataSensitivityLevel}
            </Typography>
            <Message
                level="info"
                title={
                    <Typography sx={{ whiteSpace: "pre-wrap" }}>
                        {localization.info.title()}
                    </Typography>}
                variant="minimal"/>
        </Stack>);
}