﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, InfoItem } from "../../../../../../../../../../common";
import { EntitiesInfoItem } from "../../../../components";

export function useOciCommonEncryptedResourceInfoElements(entityModel: Contract.OciEntityModel) {
    const encryptedResource = _.as<Contract.IOciEncryptedResource>(entityModel.entity);
    const encryptedResourceModel = _.as<Contract.IOciEncryptedResourceModel>(entityModel);

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.oci.useOciCommonEncryptedResourceInfoElements",
            () => ({
                encryptionExists: {
                    disabled: "Not enabled",
                    enabled: "Enabled",
                    title: "Encryption"
                },
                encryptionVaultKeyIdReference: {
                    empty: "Oracle managed",
                    title: "Encryption Key"
                }
            }));

    return {
        encryptionExists:
            <InfoItem
                key="encryptionExists"
                title={localization.encryptionExists.title()}
                value={
                    (encryptedResource.encryptionExists
                        ? localization.encryptionExists.enabled()
                        : localization.encryptionExists.disabled())}/>,
        encryptionVaultKeyIdReference:
            <EntitiesInfoItem
                emptyValue={localization.encryptionVaultKeyIdReference.empty()}
                entityIdsOrModels={encryptedResourceModel.encryptionVaultKeyIdReference}
                entityTypeName={Contract.TypeNames.OciKmsVaultKey}
                key="encryptionVaultKeyIdReference"
                location="all"
                title={localization.encryptionVaultKeyIdReference.title()}/>
    };
}