import { useMemo } from "react";
import { useOpDefaultKubernetesNamespaceResourceDefinition, useOpDefaultKubernetesResourceDefinition, useOpKubernetesClusterRoleBindingDefinition, useOpKubernetesClusterRoleDefinition, useOpKubernetesCronJobDefinition, useOpKubernetesDaemonSetDefinition, useOpKubernetesDeploymentDefinition, useOpKubernetesGroupDefinition, useOpKubernetesIngressDefinition, useOpKubernetesJobDefinition, useOpKubernetesNamespaceDefinition, useOpKubernetesNamespaceRoleBindingDefinition, useOpKubernetesNamespaceRoleDefinition, useOpKubernetesReplicaSetDefinition, useOpKubernetesServiceAccountDefinition, useOpKubernetesServiceDefinition, useOpKubernetesStatefulSetDefinition, useOpKubernetesUserDefinition } from ".";
import { DefinitionOptions } from "../../..";
import { Contract, TypeHelper } from "../../../../../../../../../../common";

export function useOpKubernetesResourceDefinition(entityModel: Contract.EntityModel, options?: DefinitionOptions) {
    const useDefinition =
        useMemo(
            () => {
                switch (entityModel.entity.typeName) {
                    case Contract.TypeNames.OpKubernetesClusterRole:
                        return useOpKubernetesClusterRoleDefinition;
                    case Contract.TypeNames.OpKubernetesClusterRoleBinding:
                        return useOpKubernetesClusterRoleBindingDefinition;
                    case Contract.TypeNames.OpKubernetesCronJob:
                        return useOpKubernetesCronJobDefinition;
                    case Contract.TypeNames.OpKubernetesDaemonSet:
                        return useOpKubernetesDaemonSetDefinition;
                    case Contract.TypeNames.OpKubernetesDeployment:
                        return useOpKubernetesDeploymentDefinition;
                    case Contract.TypeNames.OpKubernetesGroup:
                        return useOpKubernetesGroupDefinition;
                    case Contract.TypeNames.OpKubernetesIngress:
                        return useOpKubernetesIngressDefinition;
                    case Contract.TypeNames.OpKubernetesJob:
                        return useOpKubernetesJobDefinition;
                    case Contract.TypeNames.OpKubernetesNamespace:
                        return useOpKubernetesNamespaceDefinition;
                    case Contract.TypeNames.OpKubernetesNamespaceRole:
                        return useOpKubernetesNamespaceRoleDefinition;
                    case Contract.TypeNames.OpKubernetesNamespaceRoleBinding:
                        return useOpKubernetesNamespaceRoleBindingDefinition;
                    case Contract.TypeNames.OpKubernetesReplicaSet:
                        return useOpKubernetesReplicaSetDefinition;
                    case Contract.TypeNames.OpKubernetesService:
                        return useOpKubernetesServiceDefinition;
                    case Contract.TypeNames.OpKubernetesServiceAccount:
                        return useOpKubernetesServiceAccountDefinition;
                    case Contract.TypeNames.OpKubernetesStatefulSet:
                        return useOpKubernetesStatefulSetDefinition;
                    case Contract.TypeNames.OpKubernetesUser:
                        return useOpKubernetesUserDefinition;
                    default:
                        return TypeHelper.extendOrImplement(entityModel.entity.typeName, Contract.TypeNames.OpKubernetesNamespaceResourceBase)
                            ? useOpDefaultKubernetesNamespaceResourceDefinition
                            : useOpDefaultKubernetesResourceDefinition;
                }
            },
            []);

    return useDefinition(entityModel as Contract.OpResourceModel, options);
}