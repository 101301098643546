import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function AwsIamPrincipalPolicyIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                clipRule="evenodd"
                d="M16 3.72727L9.5 1L3 3.72727V7.81818L3.00427 8.07578C3.12655 11.7617 5.85804 15.1604 9.5 16C10.0564 15.8721 10.591 15.6845 11.0978 15.4455C10.4139 14.6562 10 13.6264 10 12.5C10 12.2862 10.0149 12.0759 10.0437 11.87C8.94932 12.0251 7.79349 11.7026 6.95056 10.9068C5.54222 9.57727 5.54222 7.4159 6.95056 6.08636C8.35889 4.75681 10.6483 4.75681 12.0567 6.08636C12.6878 6.68215 13.036 7.44352 13.1018 8.22145C13.5419 8.07772 14.0119 8 14.5 8C15.0214 8 15.5221 8.08868 15.9878 8.25178C15.9959 8.10772 16 7.96314 16 7.81818V3.72727ZM11.5795 9.07637C11.6362 8.89356 11.6667 8.70014 11.6667 8.50001C11.6667 7.37033 10.6966 6.45454 9.5 6.45454C8.30339 6.45454 7.33334 7.37033 7.33334 8.50001C7.33334 9.62968 8.30339 10.5454 9.5 10.5454C9.89836 10.5454 10.2716 10.4439 10.5923 10.2669C10.8511 9.81503 11.186 9.41237 11.5795 9.07637ZM14.5 9C12.567 9 11 10.567 11 12.5C11 14.433 12.567 16 14.5 16C16.433 16 18 14.433 18 12.5C18 10.567 16.433 9 14.5 9ZM14.5 17.125C18.0912 17.125 21 18.5794 21 20.375V22H8V20.375C8 18.5794 10.9088 17.125 14.5 17.125Z"
                fillRule="evenodd"/>
        </SvgIcon>);
}