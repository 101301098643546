import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { useGetAwsKinesisStreamRiskContext } from "../contexts";

export function useAwsKinesisStreamEncryptionDisabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsKinesisStreamEncryptionDisabledRisk;
    const streamModel = entityModelStore.useGet(risk.entityId) as Contract.AwsKinesisStreamModel;

    const getAwsKinesisStreamRiskContext = useGetAwsKinesisStreamRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsKinesisStreamEncryptionDisabledRiskDefinition",
            () => ({
                description: "{{stream}} is not configured with encryption using KMS",
                sections: {
                    resolution: {
                        step1: "Click the **Configuration** tab",
                        step2: "Under the **Encryption** section, click **Edit**",
                        step3: "Select **Enable server-side encryption**",
                        step4: "Choose between **Use AWS managed CMK** and **Use customer-managed CMK**",
                        step5: "Before saving the changes, make sure the relevant identities have the right permissions to the KMS key used to encrypt the Kinesis data stream",
                        step6: "Click **Save changes**"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            stream:
                <Entity
                    entityIdOrModel={streamModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.Kinesis,
                AwsConsoleUrlBuilder.getKinesisStreamUrl(streamModel.entity as Contract.AwsKinesisStream)),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4(),
            localization.sections.resolution.step5(),
            localization.sections.resolution.step6()
        ],
        riskModel,
        () => {
            const streamRiskContext = getAwsKinesisStreamRiskContext(streamModel);
            return [
                streamRiskContext.generalInformation,
                streamRiskContext.sensitive,
                streamRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}