import { DataTableColumn, DataTableRowOptions, DataTableSortType, EmptyMessageText, optionalTableCell, TextValuesFilter, TimeFormatter, TimeRangeFilter, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, entityModelStore, ItemTable, TimeRangeHelper } from "../../../common";

type GcpIamServiceAccountKeysTableProps = {
    csvExportFilePrefixes: string[];
    rowOptions?: DataTableRowOptions;
    serviceAccountKeyIds: string[];
    sortColumnId?: GcpIamServiceAccountKeysTableColumnId;
};

export function GcpIamServiceAccountKeysTable({ csvExportFilePrefixes, rowOptions, serviceAccountKeyIds, sortColumnId }: GcpIamServiceAccountKeysTableProps) {
    const keyModels = entityModelStore.useGet(serviceAccountKeyIds) as Contract.GcpIamServiceAccountKeyModel[];
    const localization =
        useLocalization(
            "tenants.gcp.gcpIamServiceAccountKeysTable",
            () => ({
                columns: {
                    creationTime: "Creation Time",
                    expirationTime: {
                        empty: "Never",
                        title: "Expiration Time"
                    },
                    name: "ID",
                    status: {
                        disabled: "Disabled",
                        enabled: "Enabled",
                        expired: "Expired",
                        title: "Status"
                    },
                    usageTime: "Usage Time"
                },
                empty: "No Keys"
            }));

    function getStatusTranslation(key: Contract.GcpIamServiceAccountKey) {
        return key.disabled
            ? localization.columns.status.disabled()
            : key.expired
                ? localization.columns.status.expired()
                : localization.columns.status.enabled();
    }

    return (
        <ItemTable
            columnIdToGetItemValueMap={{
                [GcpIamServiceAccountKeysTableColumnId.CreationTime]:
                    item =>
                        _.isNil(item.creationTime)
                            ? undefined
                            : new Date(item.creationTime),
                [GcpIamServiceAccountKeysTableColumnId.Status]: item => getStatusTranslation((item.entity as Contract.GcpIamServiceAccountKey)),
                [GcpIamServiceAccountKeysTableColumnId.ExpirationTime]:
                    item =>
                        _.isNil((item.entity as Contract.GcpIamServiceAccountKey).expirationTime)
                            ? undefined
                            : new Date((item.entity as Contract.GcpIamServiceAccountKey).expirationTime!),
                [GcpIamServiceAccountKeysTableColumnId.Name]: item => (item.entity as Contract.GcpIamServiceAccountKey).name,
                [GcpIamServiceAccountKeysTableColumnId.UsageTime]:
                    item =>
                        _.isNil((item.entityProfile as Contract.GcpIamServiceAccountKeyProfile)?.usageTime)
                            ? undefined
                            : new Date((item.entityProfile as Contract.GcpIamServiceAccountKeyProfile).usageTime!)
            }}
            csvExportFilePrefixes={csvExportFilePrefixes}
            defaultSortColumnIdOrIds={
                !_.isNil(sortColumnId)
                    ? sortColumnId
                    : GcpIamServiceAccountKeysTableColumnId.Name}
            emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty()) }}
            getCsvItem={
                item => ({
                    /* eslint-disable sort-keys-fix/sort-keys-fix */
                    "ID": (item.entity as Contract.GcpIamServiceAccountKey).name,
                    "Status": getStatusTranslation(item.entity as Contract.GcpIamServiceAccountKey),
                    "Creation Time": TimeFormatter.shortDate(item.creationTime),
                    "Expiration Time":
                        _.isNil((item.entity as Contract.GcpIamServiceAccountKey).expirationTime)
                            ? localization.columns.expirationTime.empty()
                            : TimeFormatter.shortDate((item.entity as Contract.GcpIamServiceAccountKey).expirationTime),
                    "Usage Time":
                        _.isNil((item.entityProfile as Contract.GcpIamServiceAccountKeyProfile)?.usageTime)
                            ? "-"
                            : TimeFormatter.shortDate((item.entityProfile as Contract.GcpIamServiceAccountKeyProfile).usageTime)
                    /* eslint-enable sort-keys-fix/sort-keys-fix */
                })}
            getItemId={item => item.id}
            items={keyModels}
            rowOptions={rowOptions}>
            {columnIdToItemValuesMap => [
                <DataTableColumn
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <TextValuesFilter
                                    placeholder={localization.columns.name()}
                                    values={columnIdToItemValuesMap[GcpIamServiceAccountKeysTableColumnId.Name]}/>
                        }
                    }}
                    id={GcpIamServiceAccountKeysTableColumnId.Name}
                    itemProperty={(item: Contract.GcpIamServiceAccountKeyModel) => (item.entity as Contract.GcpIamServiceAccountKey).name}
                    key={GcpIamServiceAccountKeysTableColumnId.Name}
                    title={localization.columns.name()}/>,
                <DataTableColumn
                    id={GcpIamServiceAccountKeysTableColumnId.Status}
                    itemProperty={(item: Contract.GcpIamServiceAccountKeyModel) => getStatusTranslation(item.entity as Contract.GcpIamServiceAccountKey)}
                    key={GcpIamServiceAccountKeysTableColumnId.Status}
                    title={localization.columns.status.title()}/>,
                <DataTableColumn
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <TimeRangeFilter
                                    emptyValue={
                                        _.includes(
                                            columnIdToItemValuesMap[GcpIamServiceAccountKeysTableColumnId.CreationTime],
                                            undefined)}
                                    placeholder={localization.columns.creationTime()}
                                    timeRange={TimeRangeHelper.getTimesFilterRange(columnIdToItemValuesMap[GcpIamServiceAccountKeysTableColumnId.CreationTime])}/>
                        }
                    }}
                    id={GcpIamServiceAccountKeysTableColumnId.CreationTime}
                    itemProperty={(item: Contract.GcpIamServiceAccountKeyModel) => TimeFormatter.monthDayAndYear(item.creationTime)}
                    key={GcpIamServiceAccountKeysTableColumnId.CreationTime}
                    sortOptions={{ type: DataTableSortType.Date }}
                    title={localization.columns.creationTime()}/>,
                <DataTableColumn
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <TimeRangeFilter
                                    emptyValue={
                                        _.includes(
                                            columnIdToItemValuesMap[GcpIamServiceAccountKeysTableColumnId.ExpirationTime],
                                            undefined)}
                                    placeholder={localization.columns.expirationTime.title()}
                                    timeRange={TimeRangeHelper.getTimesFilterRange(columnIdToItemValuesMap[GcpIamServiceAccountKeysTableColumnId.ExpirationTime])}/>
                        }
                    }}
                    id={GcpIamServiceAccountKeysTableColumnId.ExpirationTime}
                    itemProperty={
                        (item: Contract.GcpIamServiceAccountKeyModel) =>
                            _.isNil((item.entity as Contract.GcpIamServiceAccountKey).expirationTime)
                                ? localization.columns.expirationTime.empty()
                                : TimeFormatter.monthDayAndYear((item.entity as Contract.GcpIamServiceAccountKey).expirationTime)}
                    key={GcpIamServiceAccountKeysTableColumnId.ExpirationTime}
                    sortOptions={{ type: DataTableSortType.Date }}
                    title={localization.columns.expirationTime.title()}/>,
                <DataTableColumn
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <TimeRangeFilter
                                    placeholder={localization.columns.usageTime()}
                                    timeRange={TimeRangeHelper.getTimesFilterRange(columnIdToItemValuesMap[GcpIamServiceAccountKeysTableColumnId.UsageTime])}/>
                        }
                    }}
                    id={GcpIamServiceAccountKeysTableColumnId.UsageTime}
                    key={GcpIamServiceAccountKeysTableColumnId.UsageTime}
                    render={
                        optionalTableCell<Contract.GcpIamServiceAccountKeyModel>(
                            item =>
                                _.isNil((item.entityProfile as Contract.GcpIamServiceAccountKeyProfile)?.usageTime)
                                    ? undefined
                                    : TimeFormatter.monthDayAndYear((item.entityProfile as Contract.GcpIamServiceAccountKeyProfile).usageTime))}
                    sortOptions={{ type: DataTableSortType.Date }}
                    title={localization.columns.usageTime()}/>
            ]}
        </ItemTable>);
}

export enum GcpIamServiceAccountKeysTableColumnId {
    CreationTime = "creationTime",
    ExpirationTime = "expirationTime",
    Name = "name",
    Status = "Status",
    UsageTime = "usageTime"
}