import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../../..";
import { useCommonEntityNetworkInfoItemElements, useCustomEntityPropertyInfoItemElements } from "../..";
import { useAzureCommonEncryptedResourceInfoElements, useAzureCommonManagedIdentityOriginatorResourceInfoElements, useAzureDefaultResourceInfoItemElements } from "..";
import { Contract, InfoItem, RegionModelHelper, useEntityTypeNameTranslator } from "../../../../../../../../../../../common";
import { EntitiesInfoItem, Info } from "../../../../../components";
import { AzureFirewallInfoCard, AzureResourceRoleAssignmentResources, NetworkingInfoCard } from "../../../components";
import { ResourcesInfoCard } from "./components";

export function useAzureStorageStorageAccountDefinition(resourceGroupResourceModel: Contract.AzureResourceGroupResourceModel, options?: DefinitionOptions) {
    const commonEncryptedResourceInfoElements = useAzureCommonEncryptedResourceInfoElements(resourceGroupResourceModel);
    const commonEntityNetworkInfoItemElements = useCommonEntityNetworkInfoItemElements(resourceGroupResourceModel);
    const commonManagedIdentityOriginatorResourceInfoElements = useAzureCommonManagedIdentityOriginatorResourceInfoElements(resourceGroupResourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceGroupResourceModel);
    const defaultResourceInfoItemElements = useAzureDefaultResourceInfoItemElements(resourceGroupResourceModel);
    const storageAccount = resourceGroupResourceModel.entity as Contract.AzureStorageStorageAccount;
    const storageAccountModel = resourceGroupResourceModel as Contract.AzureStorageStorageAccountModel;

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.azure.useAzureStorageStorageAccountDefinition",
            () => ({
                info: {
                    cards: {
                        firewallRules: {
                            title: "Firewall Rules"
                        }
                    },
                    items: {
                        allowAnyAzureTrustedServicesNetworkAccess: {
                            false: "Disabled",
                            title: "Allow Trusted Azure Services Network Access",
                            true: "Enabled"
                        },
                        blobContainerPublicAccess: {
                            false: "Disabled",
                            title: "Blob Public Access",
                            true: "Enable"
                        },
                        blobContainerSoftDeleteEnabled: {
                            false: "Disabled",
                            title: "Blob Container Soft Delete",
                            true: "Enabled"
                        },
                        blobContainerSoftDeleteRetentionTimeFrame: {
                            text: "{{blobContainerSoftDeleteRetentionTimeFrame | TimeSpanFormatter.humanizeDays}}",
                            title: "Blob Container Soft Delete Retention Period"
                        },
                        blobSoftDeleteEnabled: {
                            false: "Disabled",
                            title: "Blob Soft Delete",
                            true: "Enabled"
                        },
                        blobSoftDeleteRetentionTimeFrame: {
                            text: "{{blobSoftDeleteRetentionTimeFrame | TimeSpanFormatter.humanizeDays}}",
                            title: "Blob Soft Delete Retention Period"
                        },
                        encryptionKeySource: {
                            [Contract.TypeNames.AzureStorageStorageAccountEncryptionKeySource]: {
                                [Contract.AzureStorageStorageAccountEncryptionKeySource.KeyVault]: "Customer-managed keys",
                                [Contract.AzureStorageStorageAccountEncryptionKeySource.StorageAccount]: "Microsoft-managed keys"
                            }
                        },
                        extendedRegion: {
                            text: "Primary: {{region}}, Secondary: {{secondaryRegion}}",
                            title: "Region"
                        },
                        httpsOnly: {
                            false: "Disabled",
                            title: "Secure Transfer Required",
                            true: "Enabled"
                        },
                        infrastructureEncryption: {
                            false: "Disabled",
                            title: "Infrastructure Encryption",
                            true: "Enabled"
                        },
                        publicNetworkAccess: {
                            false: "Selected Networks",
                            title: "Network Access",
                            true: "Public"
                        },
                        sharedKeyAccess: {
                            false: "Disabled",
                            title: "Shared Key Access",
                            true: "Enabled"
                        },
                        staticWebsiteEnabled: {
                            false: "Disabled",
                            title: "Static Website",
                            true: "Enabled"
                        },
                        status: {
                            title: "Status",
                            withoutSecondaryStatus: "Primary: {{status}}",
                            withSecondaryStatus: "Primary: {{status}}, Secondary: {{secondaryStatus}}"
                        },
                        tlsMinVersion: "Minimum TLS version",
                        type: "Kind"
                    }
                },
                tabs: {
                    resourceRoleAssignments: "Resource Role Assignments"
                }
            }));

    const regionModel = RegionModelHelper.get(storageAccount.regionId!);
    const secondaryRegionModel = RegionModelHelper.tryGet(storageAccount.secondaryRegionId);

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <AzureResourceRoleAssignmentResources
                    csvExportFilePrefix={localization.tabs.resourceRoleAssignments()}
                    scopeResourceModel={resourceGroupResourceModel}/>,
                localization.tabs.resourceRoleAssignments(),
                "resourceRoleAssignments")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    ...commonManagedIdentityOriginatorResourceInfoElements,
                    ...commonEncryptedResourceInfoElements,
                    <InfoItem
                        key="extendedRegion"
                        location="all"
                        title={localization.info.items.extendedRegion.title()}
                        value={
                            _.isNil(secondaryRegionModel)
                                ? undefined
                                : localization.info.items.extendedRegion.text({
                                    region: regionModel.name,
                                    secondaryRegion: secondaryRegionModel.name
                                })}/>,
                    <InfoItem
                        key="type"
                        title={localization.info.items.type()}
                        value={storageAccount.type}/>,
                    <InfoItem
                        key="status"
                        title={localization.info.items.status.title()}
                        value={
                            _.isNil(storageAccount.secondaryStatus)
                                ? localization.info.items.status.withoutSecondaryStatus({ status: storageAccount.status })
                                : localization.info.items.status.withSecondaryStatus({
                                    secondaryStatus: storageAccount.secondaryStatus,
                                    status: storageAccount.status
                                })}/>,
                    <InfoItem
                        key="httpsOnly"
                        title={localization.info.items.httpsOnly.title()}
                        value={
                            storageAccount.httpsOnly
                                ? localization.info.items.httpsOnly.true()
                                : localization.info.items.httpsOnly.false()}/>,
                    <InfoItem
                        key="tlsMinVersion"
                        title={localization.info.items.tlsMinVersion()}
                        value={storageAccount.tlsMinVersion}/>,
                    <InfoItem
                        key="sharedKeyAccess"
                        location="all"
                        title={localization.info.items.sharedKeyAccess.title()}
                        value={
                            storageAccount.sharedKeyAccess
                                ? localization.info.items.sharedKeyAccess.true()
                                : localization.info.items.sharedKeyAccess.false()}/>,
                    <InfoItem
                        key="blobContainerSoftDeleteEnabled"
                        title={localization.info.items.blobContainerSoftDeleteEnabled.title()}
                        value={
                            _.isNil(storageAccount.blobContainerSoftDeleteEnabled)
                                ? undefined
                                : storageAccount.blobContainerSoftDeleteEnabled
                                    ? localization.info.items.blobContainerSoftDeleteEnabled.true()
                                    : localization.info.items.blobContainerSoftDeleteEnabled.false()}/>,
                    <InfoItem
                        key="blobContainerSoftDeleteRetentionTimeFrame"
                        title={localization.info.items.blobContainerSoftDeleteRetentionTimeFrame.title()}
                        value={
                            storageAccount.blobContainerSoftDeleteEnabled
                                ? localization.info.items.blobContainerSoftDeleteRetentionTimeFrame.text({ blobContainerSoftDeleteRetentionTimeFrame: storageAccount.blobContainerSoftDeleteRetentionTimeFrame })
                                : undefined}/>,
                    <InfoItem
                        key="blobSoftDeleteEnabled"
                        title={localization.info.items.blobSoftDeleteEnabled.title()}
                        value={
                            _.isNil(storageAccount.blobSoftDeleteEnabled)
                                ? undefined
                                : storageAccount.blobSoftDeleteEnabled
                                    ? localization.info.items.blobSoftDeleteEnabled.true()
                                    : localization.info.items.blobSoftDeleteEnabled.false()}/>,
                    <InfoItem
                        key="blobSoftDeleteRetentionTimeFrame"
                        title={localization.info.items.blobSoftDeleteRetentionTimeFrame.title()}
                        value={
                            storageAccount.blobSoftDeleteEnabled
                                ? localization.info.items.blobSoftDeleteRetentionTimeFrame.text({ blobSoftDeleteRetentionTimeFrame: storageAccount.blobSoftDeleteRetentionTimeFrame })
                                : undefined}/>,
                    <InfoItem
                        key="blobContainerPublicAccess"
                        title={localization.info.items.blobContainerPublicAccess.title()}
                        value={
                            storageAccount.blobContainerPublicAccess
                                ? localization.info.items.blobContainerPublicAccess.true()
                                : localization.info.items.blobContainerPublicAccess.false()}/>,
                    <InfoItem
                        key="publicNetworkAccess"
                        title={localization.info.items.publicNetworkAccess.title()}
                        value={
                            storageAccount.publicNetworkAccess
                                ? localization.info.items.publicNetworkAccess.true()
                                : localization.info.items.publicNetworkAccess.false()}/>,
                    <InfoItem
                        key="allowAnyAzureTrustedServicesNetworkAccess"
                        title={localization.info.items.allowAnyAzureTrustedServicesNetworkAccess.title()}
                        value={
                            _.isNil(storageAccount.publicNetworkAccess)
                                ? undefined
                                : storageAccount.allowAnyAzureTrustedServicesNetworkAccess
                                    ? localization.info.items.allowAnyAzureTrustedServicesNetworkAccess.true()
                                    : localization.info.items.allowAnyAzureTrustedServicesNetworkAccess.false()}/>,
                    <InfoItem
                        key="encryptionKeySource"
                        title={localization.info.items.encryptionKeySource[Contract.TypeNames.AzureStorageStorageAccountEncryptionKeySource][storageAccount.encryptionKeySource]()}
                        value={storageAccount.encryptionKeySource}/>,
                    <InfoItem
                        key="infrastructureEncryption"
                        title={localization.info.items.infrastructureEncryption.title()}
                        value={
                            storageAccount.infrastructureEncryption
                                ? localization.info.items.infrastructureEncryption.true()
                                : localization.info.items.infrastructureEncryption.false()}/>,
                    <InfoItem
                        key="staticWebsiteEnabled"
                        title={localization.info.items.staticWebsiteEnabled.title()}
                        value={
                            _.isNil(storageAccount.staticWebsiteEnabled)
                                ? undefined
                                : storageAccount.staticWebsiteEnabled
                                    ? localization.info.items.staticWebsiteEnabled.true()
                                    : localization.info.items.staticWebsiteEnabled.false()}/>
                ]}
                options={options?.infoOptions}>
                <NetworkingInfoCard>
                    {commonEntityNetworkInfoItemElements.inboundAccessType}
                    {commonEntityNetworkInfoItemElements.inboundExternalAccessScope}
                    <EntitiesInfoItem
                        entityIdsOrModels={storageAccountModel.privateEndpointsIdReferences}
                        entityTypeName={Contract.TypeNames.AzureNetworkPrivateEndpoint}
                        key={Contract.TypeNames.AzureNetworkPrivateEndpoint}
                        title={entityTypeNameTranslator(
                            Contract.TypeNames.AzureNetworkPrivateEndpoint,
                            { count: 0 })}/>
                </NetworkingInfoCard>
                {!storageAccount.publicNetworkAccess &&
                    <AzureFirewallInfoCard
                        networkResourcePathToIdReferencesMap={storageAccountModel.networkResourcePathToIdReferencesMap}
                        subnets={storageAccount.firewallRuleSubnets}
                        virtualNetworkPathToFirewallSubnetRulesMap={storageAccount.virtualNetworkPathToFirewallSubnetRulesMap}/>}
                <ResourcesInfoCard storageAccountModel={storageAccountModel}/>
            </Info>
    });
}