﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../..";
import { Contract, Entity, entityModelStore, InlineEntities } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { EntityExternalConsoleLink } from "../../components";
import { useGetAzureContainerRegistryRegistryRiskContext } from "../contexts/useGetAzureContainerRegistryRegistryRiskContext";

export function useAzureContainerRegistryRegistryAccessKeyEnabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AzureContainerRegistryRegistryAccessKeyEnabledRisk;
    const registryModel = entityModelStore.useGet(risk.entityId) as Contract.AzureContainerRegistryRegistryModel;

    const getAzureContainerRegistryRegistryRiskContext = useGetAzureContainerRegistryRegistryRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.compliance.useAzureContainerRegistryRegistryAccessKeyEnabledRiskDefinition",
            () => ({
                contextItems: {
                    listCredentialsActionIdentities: {
                        empty: "No identities have the Microsoft.ContainerRegistry/registries/listCredentials/action permission",
                        notEmpty: "{{listCredentialsActionIdentities}} have the Microsoft.ContainerRegistry/registries/listCredentials/action permission"
                    }
                },
                description: "{{registry}} is allowing management access through admin user credentials",
                sections: {
                    resolution: {
                        step1: "Disable the **Admin user** option"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            registry:
                <Entity
                    entityIdOrModel={registryModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={risk.entityId}
                key={risk.entityId}
                page={Contract.AzureConsoleEntityPage.AccessKeys}/>,
            localization.sections.resolution.step1()],
        riskModel,
        () => {
            const registryRiskContext = getAzureContainerRegistryRegistryRiskContext(registryModel);
            return [
                registryRiskContext.generalInformation,
                registryRiskContext.sensitive,
                registryRiskContext.repositories,
                registryRiskContext.sensitiveRepositories,
                registryRiskContext.restricted,
                new RiskDefinitionContextItem(
                    _.isEmpty(risk.listCredentialsPermissionActionIdentityIds)
                        ? localization.contextItems.listCredentialsActionIdentities.empty()
                        : localization.contextItems.listCredentialsActionIdentities.notEmpty({
                            listCredentialsActionIdentities:
                                <InlineEntities
                                    entityIdsOrModels={risk.listCredentialsPermissionActionIdentityIds}
                                    entityTypeName={Contract.TypeNames.AadDirectoryIdentity}
                                    variant="itemCountAndType"/>
                        })),
                registryRiskContext.inboundExternalAccessScope,
                registryRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}