import { Dropdown, StringHelper, useLocalization } from "@infrastructure";
import { List, ListItem, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { Fragment, useMemo } from "react";
import { Contract, useTheme } from "../../../../../../../../../../../common";
import { AwsActionHelper } from "../../../../../../../../../../../tenants";

export type S3BucketAccessControlListSectionProps = {
    accessControlList: Contract.AwsS3AccessControlList;
};

export function S3BucketAccessControlListSection({ accessControlList }: S3BucketAccessControlListSectionProps) {
    const publicAccessPermissionActions =
        useMemo(
            () =>
                _(accessControlList.publicAccessPermissionActions).
                    map(
                        publicAccessPermissionAction => ({
                            publicAccessPermissionAction,
                            publicAccessPermissionActionName: AwsActionHelper.getName(publicAccessPermissionAction)
                        })).
                    orderBy(({ publicAccessPermissionActionName }) => StringHelper.getSortValue(publicAccessPermissionActionName)).
                    map(
                        ({ publicAccessPermissionAction, publicAccessPermissionActionName }) =>
                            <Typography
                                key={publicAccessPermissionAction}
                                noWrap={true}>
                                {publicAccessPermissionActionName}
                            </Typography>).
                    value(),
            [accessControlList]);

    const theme = useTheme();
    const publicAccessPermissionActionMaxCount = 3;
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.s3BucketAccessControlListSection",
            () => ({
                fields: {
                    logDeliveryGroupAccess: {
                        false: "Not enabled",
                        title: "**S3 Log Delivery Group**",
                        true: "Enabled"
                    },
                    otherTenantAccessCanonicalIds: {
                        disabled: "Not enabled",
                        enabled: [
                            "Enabled for 1 account",
                            "Enabled for {{count | NumberFormatter.humanize}} accounts"
                        ],
                        title: "**Access for other AWS accounts**"
                    },
                    publicAccessPermissionActions: {
                        disabled: "Not enabled",
                        title: "**Public Access**"
                    },
                    tenantAccess: {
                        false: "Not enabled",
                        title: "**Access for bucket owner**",
                        true: "Enabled"
                    }
                },
                limitPermissionsAdditional: "+{{additionalCount | NumberFormatter.humanize}}"
            }));
    return (
        <Stack>
            <Typography>
                {localization.fields.tenantAccess.title()}
            </Typography>
            <Typography>
                {accessControlList.tenantAccess
                    ? localization.fields.tenantAccess.true()
                    : localization.fields.tenantAccess.false()}
            </Typography>
            <Typography sx={{ marginTop: theme.spacing(3) }}>
                {localization.fields.otherTenantAccessCanonicalIds.title()}
            </Typography>
            <Typography>
                {_.isEmpty(accessControlList.otherTenantAccessCanonicalIds)
                    ? localization.fields.otherTenantAccessCanonicalIds.disabled()
                    : localization.fields.otherTenantAccessCanonicalIds.enabled(accessControlList.otherTenantAccessCanonicalIds.length)}
            </Typography>
            <Typography sx={{ marginTop: theme.spacing(3) }}>
                {localization.fields.publicAccessPermissionActions.title()}
            </Typography>
            {publicAccessPermissionActions.length === 0
                ? localization.fields.publicAccessPermissionActions.disabled()
                : <Fragment>
                    <Typography
                        sx={{
                            color: theme.palette.error.main,
                            fontWeight: 600
                        }}>
                        {_(publicAccessPermissionActions).
                            take(publicAccessPermissionActionMaxCount).
                            value()}
                    </Typography>
                    {publicAccessPermissionActions.length > publicAccessPermissionActionMaxCount && (
                        <Dropdown
                            popoverElement={
                                <List>
                                    {_(publicAccessPermissionActions).
                                        drop(publicAccessPermissionActionMaxCount).
                                        map(
                                            (permission, permissionIndex) =>
                                                <ListItem
                                                    key={permissionIndex}
                                                    sx={{
                                                        color: theme.palette.error.main,
                                                        fontWeight: 600
                                                    }}>
                                                    {permission}
                                                </ListItem>).
                                        value()}
                                </List>}>
                            <Typography
                                sx={{
                                    color: theme.palette.error.main,
                                    fontWeight: 600,
                                    textDecoration: "underline"
                                }}>
                                {localization.limitPermissionsAdditional({ additionalCount: publicAccessPermissionActions.length - publicAccessPermissionActionMaxCount })}
                            </Typography>
                        </Dropdown>)}
                </Fragment>}
            <Typography sx={{ marginTop: theme.spacing(3) }}>
                {localization.fields.logDeliveryGroupAccess.title()}
            </Typography>
            <Typography>
                {accessControlList.logDeliveryGroupAccess
                    ? localization.fields.logDeliveryGroupAccess.true()
                    : localization.fields.logDeliveryGroupAccess.false()}
            </Typography>
        </Stack>);
}