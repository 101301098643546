﻿import { ItemSelector, TextViewer, useLocalization } from "@infrastructure";
import { Box, Stack, Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { useState } from "react";
import { AwsLambdaFunctionConfigurationRevision } from "../../../../../../../../../../../../../../common/controllers/types.generated";
import { AwsLambdaFunctionConfigurationHelper } from "../../../../../../../../../../../../../../tenants";

export type RevisionPoliciesSectionProps = {
    revisionMap: Dictionary<AwsLambdaFunctionConfigurationRevision>;
};

export function RevisionPolicySection({ revisionMap }: RevisionPoliciesSectionProps) {
    const revisionIds =
        _(revisionMap).
            keys().
            orderBy([
                revisionId => revisionId === AwsLambdaFunctionConfigurationHelper.latest,
                revisionId =>
                    revisionId !== AwsLambdaFunctionConfigurationHelper.latest &&
                        Number(revisionId)
            ],
            [
                "desc",
                "desc"
            ]).
            value();
    const [selectedRevision, setSelectedRevision] = useState(revisionIds[0]);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsLambdaFunctionConfigurationPublicAccessExistsRiskDefinition.revisionPolicySection",
            () => ({
                latest: "Latest",
                policyDocument: "Policy"
            }));

    return (
        <Stack
            spacing={4}
            sx={{ height: "100%" }}>
            <ItemSelector
                dense={true}
                fullWidth={true}
                items={revisionIds}
                selectedItem={selectedRevision}
                onSelectedItemChanged={publicPolicyRevisionName => setSelectedRevision(publicPolicyRevisionName)}>
                {publicPolicyRevisionName =>
                    publicPolicyRevisionName === AwsLambdaFunctionConfigurationHelper.latest
                        ? localization.latest()
                        : publicPolicyRevisionName}
            </ItemSelector>
            <Stack
                spacing={1}
                sx={{ flex: 1 }}>
                <Typography variant="h5">
                    {localization.policyDocument()}
                </Typography>
                <Box sx={{ flex: 1 }}>
                    <TextViewer
                        format="json"
                        height="100%"
                        text={revisionMap[selectedRevision].policyDocument!.raw}/>
                </Box>
            </Stack>
        </Stack>);
}