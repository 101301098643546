import { useLocalization } from "@infrastructure";
import React, { useCallback } from "react";
import { RiskPolicyConfigurationDefinitionData } from "..";
import { Contract, CustomerConsoleAppUrlHelper, RiskPoliciesType } from "../../../../../../../common";
import { TimeFrame, ViewOnlyTimeFrame } from "../components";

export function useAadDirectoryApplicationSecretNotRotatedRiskPolicyConfigurationDefinition(riskPoliciesType: RiskPoliciesType): RiskPolicyConfigurationDefinitionData {
    const localization =
        useLocalization(
            "views.customer.riskPolicies.hooks.useConfigurationDefinition.hooks.useAadDirectoryApplicationSecretNotRotatedRiskPolicyConfigurationDefinition",
            () => ({
                description: "By default, Tenable Cloud Security uses a 365 days time frame to analyze this policy before opening findings.",
                title: "Rotation Period"
            }));

    const getTimeFrame =
        useCallback(
            (riskPolicyConfiguration: Contract.RiskPolicyConfiguration) =>
                (riskPolicyConfiguration as Contract.AadDirectoryApplicationSecretNotRotatedRiskPolicyConfiguration).secretRotationTimeFrame,
            []);

    return {
        generalAdditionalItems: [{
            configurationElement:
                <TimeFrame
                    description={localization.description()}
                    documentationUrl={CustomerConsoleAppUrlHelper.getDocsViewAndManagePoliciesRelativeUrl()}
                    getTimeFrame={getTimeFrame}
                    key={`${Contract.TypeNames.AadDirectoryApplicationSecretNotRotatedRiskPolicyConfiguration}-secretRotationTimeFrame`}
                    riskPoliciesType={riskPoliciesType}
                    setTimeFrame={
                        (riskPolicyConfiguration, secretRotationTimeFrame) => {
                            (riskPolicyConfiguration as Contract.AadDirectoryApplicationSecretNotRotatedRiskPolicyConfiguration).secretRotationTimeFrame = secretRotationTimeFrame;
                            return undefined;
                        }}
                    title={localization.title()}/>,
            viewItem: {
                getValue:
                    (_, item, scopeId) =>
                        <ViewOnlyTimeFrame
                            getTimeFrame={getTimeFrame}
                            item={item}
                            riskPoliciesType={riskPoliciesType}
                            scopeId={scopeId}/>,
                title: localization.title()
            }
        }]
    };
}