import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function SqsQueueIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                clipRule="evenodd"
                d="M22 22V13H9V22H22ZM22.6809 23H8.31915C8.23451 23 8.15333 22.959 8.09348 22.886C8.03362 22.8129 8 22.7139 8 22.6106V12.3894C8 12.2861 8.03362 12.1871 8.09348 12.114C8.15333 12.041 8.23451 12 8.31915 12H22.6809C22.7655 12 22.8467 12.041 22.9065 12.114C22.9664 12.1871 23 12.2861 23 12.3894V22.6106C23 22.7139 22.9664 22.8129 22.9065 22.886C22.8467 22.959 22.7655 23 22.6809 23Z"
                fillRule="evenodd"/>
            <path d="M14.026 14.2138H13.3877V20.783H14.026V14.2138Z"/>
            <path d="M15.8194 14.2138H15.1812V20.783H15.8194V14.2138Z"/>
            <path d="M17.6158 14.2138H16.9775V20.783H17.6158V14.2138Z"/>
            <path d="M10.7127 19.0529C10.6665 19.0531 10.6207 19.0433 10.5787 19.0242C10.5233 18.9986 10.4764 18.9577 10.4436 18.9063C10.4108 18.8549 10.3934 18.7952 10.3936 18.7343V16.2621C10.3935 16.2015 10.4107 16.1421 10.4432 16.091C10.4757 16.0398 10.5221 15.9989 10.5771 15.9732C10.632 15.9474 10.6932 15.9379 10.7534 15.9456C10.8136 15.9533 10.8703 15.978 10.917 16.0168L12.4042 17.2529C12.4403 17.2828 12.4694 17.3203 12.4894 17.3627C12.5094 17.4051 12.5197 17.4513 12.5197 17.4982C12.5197 17.545 12.5094 17.5913 12.4894 17.6337C12.4694 17.6761 12.4403 17.7136 12.4042 17.7435L10.917 18.9796C10.8595 19.0271 10.7873 19.053 10.7127 19.0529ZM11.0319 16.9438V18.0525L11.6989 17.4982L11.0319 16.9438Z"/>
            <path d="M20.2875 19.0529C20.2129 19.053 20.1406 19.0271 20.0832 18.9796L18.596 17.7435C18.5598 17.7136 18.5308 17.6761 18.5108 17.6337C18.4908 17.5913 18.4805 17.545 18.4805 17.4982C18.4805 17.4513 18.4908 17.4051 18.5108 17.3627C18.5308 17.3203 18.5598 17.2828 18.596 17.2529L20.0832 16.0168C20.1299 15.978 20.1866 15.9533 20.2468 15.9456C20.307 15.9379 20.3682 15.9474 20.4231 15.9732C20.4781 15.9989 20.5245 16.0398 20.557 16.091C20.5895 16.1421 20.6067 16.2015 20.6066 16.2621V18.7343C20.6068 18.7952 20.5894 18.8549 20.5566 18.9063C20.5238 18.9577 20.4769 18.9986 20.4215 19.0242C20.3794 19.0433 20.3337 19.0531 20.2875 19.0529ZM19.3013 17.4982L19.9683 18.0525V16.9438L19.3013 17.4982Z"/>
            <path d="M6.5 0L13 2.72728V6.81819C13 7.56146 12.8924 10.2947 12.6919 10.9996L9.74629 10.9995C10.4063 9.72712 10.1783 6.14524 9.05667 5.08637C7.64833 3.75682 5.35889 3.75682 3.95055 5.08637C2.54222 6.41591 2.54222 8.57728 3.95055 9.90679C4.78212 10.6918 5.9182 11.0163 6.99943 10.876L7.00033 14.8673C6.83568 14.9169 6.66885 14.9612 6.5 15C2.85803 14.1604 0.126553 10.7618 0.00427604 7.07579L0 6.81819V2.72728L6.5 0ZM6.5 5.45455C7.69662 5.45455 8.66667 6.37033 8.66667 7.5C8.66667 8.62968 7.69662 9.54549 6.5 9.54549C5.30338 9.54549 4.33333 8.62968 4.33333 7.5C4.33333 6.37033 5.30338 5.45455 6.5 5.45455Z"/>
        </SvgIcon>);
}