﻿import { map } from "@infrastructure";
import { Contract } from "../controllers";

export class AwsPartitionHelper {
    public static getPartitionType(partitionSystemName: string): Contract.AwsPartitionType {
        return map(
            partitionSystemName,
            {
                ["aws"]: () => Contract.AwsPartitionType.Public,
                ["aws-cn"]: () => Contract.AwsPartitionType.China,
                ["aws-iso"]: () => Contract.AwsPartitionType.UsIsoA,
                ["aws-iso-b"]: () => Contract.AwsPartitionType.UsIsoB,
                ["aws-iso-e"]: () => Contract.AwsPartitionType.EuIsoE,
                ["aws-iso-f"]: () => Contract.AwsPartitionType.UsIsoF,
                ["aws-us-gov"]: () => Contract.AwsPartitionType.UsGov
            });
    }
}