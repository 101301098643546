import { makeContextProvider } from "@infrastructure";
import { List, ListItem } from "@mui/material";
import { Stack } from "@mui/system";
import _ from "lodash";
import React, { useEffect, useMemo } from "react";
import { ResourceTagPattern, ResourceTagPatternItem } from "./ResourceTagPattern";

export class ResourceTagPatternSelectorContext {
    constructor(public resourceTagPatternItems: ResourceTagPatternItem[]) {
    }
}

export const [, useSetResourceTagPatternSelectorContext, useResourceTagPatternSelectorContextProvider] = makeContextProvider<ResourceTagPatternSelectorContext>();

export type ResourceTagPatternsSelectorProps = {
    initialItems?: ResourceTagPatternItem[];
    onItemsChanged: (resourceTagPatterns: ResourceTagPatternItem[]) => void;
    onValidChanged: (valid: boolean) => void;
    readOnly?: boolean;
    valueDefaultWildCard?: boolean;
};

export function ResourceTagPatternsSelector({ initialItems, onItemsChanged, onValidChanged, readOnly = false, valueDefaultWildCard = false }: ResourceTagPatternsSelectorProps) {
    const [context, , ContextProvider] = useResourceTagPatternSelectorContextProvider(() => new ResourceTagPatternSelectorContext(initialItems ?? []));

    useEffect(
        () => {
            onItemsChanged(context.resourceTagPatternItems);
            onValidChanged(
                !_.isEmpty(context.resourceTagPatternItems) &&
                    _.every(
                        context.resourceTagPatternItems,
                        item => item.valid()));
        },
        [context.resourceTagPatternItems]);

    const resourceTagPatternItems =
        useMemo(
            () =>
                context.resourceTagPatternItems.length < 50 && !readOnly
                    ? _.concat(
                        context.resourceTagPatternItems,
                        new ResourceTagPatternItem(
                            false,
                            "",
                            valueDefaultWildCard
                                ? "*"
                                : "",
                            undefined,
                            undefined,
                            true))
                    : context.resourceTagPatternItems,
            [context.resourceTagPatternItems]);

    return (
        <ContextProvider>
            <List disablePadding={true}>
                <Stack spacing={2}>
                    {_.map(
                        resourceTagPatternItems,
                        resourceTagPatternItem =>
                            <ListItem
                                disablePadding={true}
                                key={resourceTagPatternItem.id}>
                                <ResourceTagPattern
                                    disabled={false}
                                    item={resourceTagPatternItem}
                                    readOnly={readOnly}/>
                            </ListItem>)}
                </Stack>
            </List>
        </ContextProvider>);
}