import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { usePermissionEligibilityAuditEventCommonDetailItems } from ".";
import { Contract, Entity, InlineEntities, useEntityTypeNameTranslator } from "../../../../../../../../../../../common";
import { AuditEventDefinition, AuditEventDefinitionContext, AuditEventDefinitionDetailItem } from "../../../useDefinition";

export function useGroupMembershipEligibilityAuditEventDefinition(context: AuditEventDefinitionContext) {
    const permissionEligibilityAuditEventCommonDetailItems = usePermissionEligibilityAuditEventCommonDetailItems(context);
    const localization =
        useLocalization(
            "views.customer.auditEvents.profile.hooks.useDefinition.hooks.usePermissionEligibilityAuditEventDefinition.hooks.useGroupMembershipEligibilityAuditEventDefinition",
            () => ({
                groupIdReferences: [
                    "Group",
                    "Groups"
                ]
            }));

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const permissionEligibilityAuditEventModel = context.auditEventModel as Contract.PermissionEligibilityAuditEventModel;
    const auditEventModelGroupMembershipEligibilityData = permissionEligibilityAuditEventModel.permissionEligibilityData as Contract.PermissionEligibilityAuditEventModelGroupMembershipEligibilityData;

    return new AuditEventDefinition(
        _.filter([
            permissionEligibilityAuditEventCommonDetailItems.name,
            permissionEligibilityAuditEventCommonDetailItems.principalTenantId,
            permissionEligibilityAuditEventCommonDetailItems.granteePrincipalIdReferences,
            auditEventModelGroupMembershipEligibilityData.groupIdReferences.length === 1
                ? new AuditEventDefinitionDetailItem(
                    localization.groupIdReferences(auditEventModelGroupMembershipEligibilityData.groupIdReferences.length),
                    context.entityModelMap[auditEventModelGroupMembershipEligibilityData.groupIdReferences[0]].entity.displayName,
                    <Entity
                        entityIdOrModel={auditEventModelGroupMembershipEligibilityData.groupIdReferences[0]}
                        variant="iconText"/>)
                : new AuditEventDefinitionDetailItem(
                    localization.groupIdReferences(auditEventModelGroupMembershipEligibilityData.groupIdReferences.length),
                    entityTypeNameTranslator(
                        Contract.TypeNames.IDirectoryGroup,
                        {
                            count: auditEventModelGroupMembershipEligibilityData.groupIdReferences.length,
                            includeCount: true
                        }),
                    <InlineEntities
                        entityIdsOrModels={auditEventModelGroupMembershipEligibilityData.groupIdReferences}
                        entityTypeName={Contract.TypeNames.Entity}
                        variant="itemPlusItemCount"/>
                ),
            permissionEligibilityAuditEventCommonDetailItems.expirationTimeFrame,
            permissionEligibilityAuditEventCommonDetailItems.approval,
            ...permissionEligibilityAuditEventCommonDetailItems.approvalLevels,
            permissionEligibilityAuditEventCommonDetailItems.reasonRequired,
            permissionEligibilityAuditEventCommonDetailItems.updateAuditEventChanges
        ]));
}