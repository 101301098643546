import { ItemSelector, PasswordText, useLocalization } from "@infrastructure";
import { Box, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import { Contract, KeyValueTable, KeyValueTableItem, SecretExclusionActionCell, useTheme } from "../../../../../../../../../../../../../../common";
import { AwsLambdaFunctionConfigurationHelper } from "../../../../../../../../../../../../../../tenants";

export type RevisionSectionProps = {
    functionConfigurationModel: Contract.AwsLambdaFunctionConfigurationModel;
    risk: Contract.AwsLambdaFunctionConfigurationEnvironmentVariableSecretExistsRisk;
};

export function RevisionSection({ functionConfigurationModel, risk }: RevisionSectionProps) {
    const functionConfiguration = functionConfigurationModel.entity as Contract.AwsLambdaFunctionConfiguration;

    const orderedRevisionIds =
        useMemo(
            () =>
                _(functionConfiguration.revisionMap).
                    keys().
                    orderBy(
                        [
                            revisionId => revisionId === AwsLambdaFunctionConfigurationHelper.latest,
                            revisionId => revisionId !== AwsLambdaFunctionConfigurationHelper.latest && Number(revisionId)
                        ],
                        [
                            "desc",
                            "desc"
                        ]).
                    value(),
            [functionConfiguration.revisionMap]);

    const [selectedRevisionId, setSelectedRevisionId] =
        useState(
            () =>
                _.find(
                    orderedRevisionIds,
                    revisionId => _.has(risk.revisionIdToSecretEnvironmentVariableNamesMap, revisionId)) ?? AwsLambdaFunctionConfigurationHelper.latest);

    const theme = useTheme();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsLambdaFunctionConfigurationEnvironmentVariableSecretExistsRiskDefinition.revisionSection",
            () => ({
                fields: {
                    environmentVariables: {
                        empty: "No environment variables",
                        title: "Environment Variables",
                        valueDisplayName: "Value"
                    }
                },
                latest: "Latest"
            }));
    return (
        <Stack
            spacing={4}
            sx={{ height: "100%" }}>
            <ItemSelector
                dense={true}
                fullWidth={true}
                items={orderedRevisionIds}
                selectedItem={selectedRevisionId}
                sorted={false}
                onSelectedItemChanged={setSelectedRevisionId}>
                {lambdaFunctionRevisionId =>
                    lambdaFunctionRevisionId === AwsLambdaFunctionConfigurationHelper.latest
                        ? localization.latest()
                        : lambdaFunctionRevisionId}
            </ItemSelector>
            <Stack
                spacing={1}
                sx={{
                    flex: 1,
                    overflow: "hidden"
                }}>
                <Typography variant="h5">
                    {_.isEmpty(functionConfiguration.revisionMap[selectedRevisionId].environmentVariableNameToValueMap)
                        ? localization.fields.environmentVariables.empty()
                        : localization.fields.environmentVariables.title()}
                </Typography>
                <Box
                    sx={{
                        flex: 1,
                        overflow: "hidden"
                    }}>
                    <KeyValueTable
                        items={
                            _.map(
                                functionConfiguration.revisionMap[selectedRevisionId].environmentVariableNameToValueMap,
                                (environmentVariableValue, environmentVariableName) =>
                                    new KeyValueTableItem(
                                        environmentVariableName,
                                        environmentVariableValue))}
                        key={selectedRevisionId}
                        renderActions={
                            item =>
                                <SecretExclusionActionCell
                                    displayNameToValueSecretDataMap={{
                                        [localization.fields.environmentVariables.valueDisplayName()]: {
                                            secret: _.includes(risk.revisionIdToSecretEnvironmentVariableNamesMap[selectedRevisionId], item.key),
                                            value: item.value
                                        }
                                    }}
                                    risk={risk}
                                    secretKey={item.key}/>
                        }
                        renderValue={
                            item =>
                                _.includes(risk.revisionIdToSecretEnvironmentVariableNamesMap[selectedRevisionId], item.key)
                                    ? <PasswordText password={item.value!}/>
                                    : item.value}
                        rowOptions={{
                            getHighlightColor:
                                item =>
                                    _.includes(risk.revisionIdToSecretEnvironmentVariableNamesMap[selectedRevisionId], item.key)
                                        ? theme.palette.severity(risk.severity)
                                        : undefined,
                            getSx:
                                item =>
                                    _.includes(risk.revisionIdToSecretEnvironmentVariableNamesMap[selectedRevisionId], item.key)
                                        ? { backgroundColor: theme.palette.opacity(theme.palette.severity(risk.severity), 0.1) }
                                        : undefined
                        }}/>
                </Box>
            </Stack>
        </Stack>);
}