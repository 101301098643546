import { TimeFormatter } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import { MomentInput } from "moment";
import React from "react";

type TimeCellProps = {
    short?: boolean;
    time: MomentInput;
};

export function TimeCell({ short = true, time }: TimeCellProps) {
    return (
        <Stack
            direction="row"
            spacing={1}>
            <Typography noWrap={true}>
                {short
                    ? TimeFormatter.mediumDateShortTime(time)
                    : TimeFormatter.mediumDateTime(time)}
            </Typography>
        </Stack>);
}