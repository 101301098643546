import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function usePingIdentityDirectoryUserStatusTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.usePingIdentityDirectoryUserStatusTranslator",
            () => ({
                [Contract.TypeNames.PingIdentityDirectoryUserStatus]: {
                    [Contract.PingIdentityDirectoryUserStatus.Disabled]: "Disabled",
                    [Contract.PingIdentityDirectoryUserStatus.Enabled]: "Enabled",
                    [Contract.PingIdentityDirectoryUserStatus.Locked]: "Locked"
                }
            }));
    return (status: Contract.PingIdentityDirectoryUserStatus) =>
        localization[Contract.TypeNames.PingIdentityDirectoryUserStatus][status]();
}