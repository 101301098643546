import { Link, Tooltip, useFormatRelativeDays, useLocalization } from "@infrastructure";
import { Error as ErrorIcon } from "@mui/icons-material";
import { Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract, CustomerConsoleAppUrlHelper, DashboardController, InlineEntities, useTheme } from "../../../../../../../../..";
import { SeverityItem } from "../../../components";

type VulnerabilityProps = {
    vulnerability: Contract.WorkloadAnalysisVulnerabilityModel;
};

export function Vulnerability({ vulnerability }: VulnerabilityProps) {
    const formatRelativeDays = useFormatRelativeDays();

    const localization =
        useLocalization(
            "common.dashboard.widget.hooks.useDefinition.hooks.useGetWorkloadAnalysisCriticalVulnerabilitiesDefinition.vulnerability",
            () => ({
                exploitable: "Known Exploit",
                firstScanTime: "First seen {{firstScanTimeRelativeDays}}",
                workloadResources: {
                    pluralizer: [
                        "1 workload",
                        "{{count}} workloads"
                    ],
                    title: "Found on {{workloadResources}}"
                }
            }));

    const theme = useTheme();
    return (
        <SeverityItem
            className="vulnerability"
            info={
                vulnerability.exploitable &&
                <Tooltip titleOrGetTitle={localization.exploitable()}>
                    <ErrorIcon
                        sx={{
                            alignSelf: "flex-start",
                            color: theme.palette.error.main,
                            fontSize: "20px",
                            margin: 1
                        }}/>
                </Tooltip>}
            severity={Contract.Severity.Critical}
            subTitles={
                _.isNil(vulnerability.firstScanTime)
                    ? [
                        <Typography
                            key="workloadResources"
                            noWrap={true}>
                            {localization.workloadResources.title({
                                workloadResources:
                                    <InlineEntities
                                        deferredEntities={{
                                            count: vulnerability.workloadResourceCount,
                                            firstItem: undefined,
                                            getItems:
                                                async () => {
                                                    const { workloadResourceIds } =
                                                        await DashboardController.getSummaryVulnerabilityModelWorkloadResourceIds(
                                                            new Contract.DashboardControllerGetSummaryVulnerabilityWorkloadResourceIdsRequest(vulnerability.rawId));
                                                    return workloadResourceIds;
                                                }
                                        }}
                                        entityTypeName={Contract.TypeNames.Entity}
                                        entityVariant="iconTextTypeTenant"
                                        namePluralizer={localization.workloadResources.pluralizer}
                                        variant="itemCountAndType"/>
                            })}
                        </Typography>
                    ]
                    : [
                        <Typography
                            key="workloadResources"
                            noWrap={true}>
                            {localization.workloadResources.title({
                                workloadResources:
                                    <InlineEntities
                                        deferredEntities={{
                                            count: vulnerability.workloadResourceCount,
                                            firstItem: undefined,
                                            getItems:
                                                async () => {
                                                    const { workloadResourceIds } =
                                                        await DashboardController.getSummaryVulnerabilityModelWorkloadResourceIds(
                                                            new Contract.DashboardControllerGetSummaryVulnerabilityWorkloadResourceIdsRequest(vulnerability.rawId));
                                                    return workloadResourceIds;
                                                }
                                        }}
                                        entityTypeName={Contract.TypeNames.Entity}
                                        entityVariant="iconTextTypeTenant"
                                        namePluralizer={localization.workloadResources.pluralizer}
                                        variant="itemCountAndType"/>
                            })}
                        </Typography>,
                        <Typography
                            key="firstScanTime"
                            noWrap={true}>
                            {localization.firstScanTime({ firstScanTimeRelativeDays: formatRelativeDays(vulnerability.firstScanTime) })}
                        </Typography>
                    ]}
            title={
                <Link
                    urlOrGetUrl={
                        CustomerConsoleAppUrlHelper.getWorkloadAnalysisVulnerabilitiesRelativeUrl(
                            Contract.Severity.Critical,
                            vulnerability.rawId)}>
                    <Typography
                        noWrap={true}
                        sx={{
                            fontSize: "1.1rem",
                            fontWeight: 600,
                            lineHeight: 1.2
                        }}>
                        {vulnerability.rawId}
                    </Typography>
                </Link>}/>);
}