import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, EnumValuesFilter, PagedValuesFilter, useLocalization } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { useAzureCommonEncryptedResourceDefinition, useAzureCommonResourceGroupResourceDefinition } from ".";
import { EntityTableDefinition } from "../..";
import { Contract, CustomerConsoleAppUrlHelper, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { useAzureContainerServiceManagedClusterAuthenticationAndAuthorizationModeTranslator, useAzureContainerServiceManagedClusterStatusTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";
import { useCommonKubernetesClusterDefinition } from "../useCommonKubernetesClusterDefinition";

export function useAzureContainerServiceManagedClusterDefinition(definitionData: DefinitionData) {
    const commonEncryptedResourceDefinition = useAzureCommonEncryptedResourceDefinition(definitionData);
    const commonKubernetesClusterDefinition = useCommonKubernetesClusterDefinition(definitionData);
    const commonResourceGroupResourceDefinition = useAzureCommonResourceGroupResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const containerServiceManagedClusterAuthenticationAndAuthorizationModeTranslator = useAzureContainerServiceManagedClusterAuthenticationAndAuthorizationModeTranslator();
    const containerServiceManagedClusterStatusTranslator = useAzureContainerServiceManagedClusterStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.azure.useAzureContainerServiceManagedClusterDefinition",
            () => ({
                columns: {
                    authenticationAndAuthorizationMode: "Authentication and Authorization",
                    dnsName: "DNS Name",
                    networkVirtualNetworkId: "Virtual Network",
                    status: "Status",
                    version: "Kubernetes Version"
                }
            }));

    return new EntityTableDefinition(
        _.filter([
            commonResourceGroupResourceDefinition.columns.tenantColumn,
            commonResourceGroupResourceDefinition.columns.creationTimeColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AzureContainerServiceManagedClusterModel) => ({
                            [localization.columns.status()]: containerServiceManagedClusterStatusTranslator((item.entity as Contract.AzureContainerServiceManagedCluster).status)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.AzureContainerServiceManagedClusterStatus}
                                enumTypeTranslator={containerServiceManagedClusterStatusTranslator}
                                placeholder={localization.columns.status()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AzureContainerServiceManagedClusterStatus}
                itemProperty={(item: Contract.AzureContainerServiceManagedClusterModel) => containerServiceManagedClusterStatusTranslator((item.entity as Contract.AzureContainerServiceManagedCluster).status)}
                key={Contract.EntityModelProperty.AzureContainerServiceManagedClusterStatus}
                title={localization.columns.status()}/>,
            commonResourceGroupResourceDefinition.columns.creatorIdentityCsvColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AzureContainerServiceManagedClusterModel) => ({
                            [localization.columns.version()]: (item.entity as Contract.AzureContainerServiceManagedCluster).version
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedValuesFilter
                                getValuePage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                        Contract.TypeNames.AzureContainerServiceManagedCluster,
                                        Contract.EntityModelProperty.KubernetesClusterVersion)}
                                placeholder={localization.columns.version()}/>
                    }
                }}
                id={Contract.EntityModelProperty.KubernetesClusterVersion}
                itemProperty={(item: Contract.AzureContainerServiceManagedClusterModel) => (item.entity as Contract.AzureContainerServiceManagedCluster).version}
                key={Contract.EntityModelProperty.KubernetesClusterVersion}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.version()}/>,
            commonKubernetesClusterDefinition.columns.createApiConnectivityStatusColumn(CustomerConsoleAppUrlHelper.getDocsOnboardAzureAksClusterRelativeUrl()),
            commonKubernetesClusterDefinition.columns.resourceUpdateTimeColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AzureContainerServiceManagedClusterModel) => ({
                            [localization.columns.authenticationAndAuthorizationMode()]: containerServiceManagedClusterAuthenticationAndAuthorizationModeTranslator(item.authenticationAndAuthorizationMode)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.AzureContainerServiceManagedClusterAuthenticationAndAuthorizationMode}
                                enumTypeTranslator={containerServiceManagedClusterAuthenticationAndAuthorizationModeTranslator}
                                placeholder={localization.columns.authenticationAndAuthorizationMode()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AzureContainerServiceManagedClusterAuthenticationAndAuthorizationMode}
                itemProperty={(item: Contract.AzureContainerServiceManagedClusterModel) => containerServiceManagedClusterAuthenticationAndAuthorizationModeTranslator(item.authenticationAndAuthorizationMode)}
                key={Contract.EntityModelProperty.AzureContainerServiceManagedClusterAuthenticationAndAuthorizationMode}
                title={localization.columns.authenticationAndAuthorizationMode()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AzureContainerServiceManagedClusterModel) => ({
                            [localization.columns.dnsName()]: (item.entity as Contract.AzureContainerServiceManagedCluster).dnsName
                        })
                }}
                id="dnsName"
                key="dnsName"/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.AzureContainerServiceManagedClusterModel>(
                        Contract.TypeNames.AzureNetworkVirtualNetwork,
                        item => [(item.entity as Contract.AzureContainerServiceManagedCluster).network.virtualNetworkId],
                        localization.columns.networkVirtualNetworkId())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AzureContainerServiceManagedClusterVirtualNetwork)}
                                placeholder={localization.columns.networkVirtualNetworkId()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AzureContainerServiceManagedClusterVirtualNetwork}
                key={Contract.EntityModelProperty.AzureContainerServiceManagedClusterVirtualNetwork}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AzureContainerServiceManagedClusterModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={(item.entity as Contract.AzureContainerServiceManagedCluster).network.virtualNetworkId}
                            entityTypeName={Contract.TypeNames.AzureNetworkVirtualNetwork}/>}
                title={localization.columns.networkVirtualNetworkId()}/>,
            commonEncryptedResourceDefinition.columns.encryptionExistsColumn,
            commonEncryptedResourceDefinition.columns.vaultKeyColumn,
            commonResourceGroupResourceDefinition.columns.regionColumn,
            commonResourceGroupResourceDefinition.columns.regionLocationColumn,
            commonResourceGroupResourceDefinition.columns.resourceGroupColumn,
            commonResourceGroupResourceDefinition.columns.tagsColumn,
            commonResourceGroupResourceDefinition.columns.attributesColumn,
            commonResourceGroupResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonResourceGroupResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonResourceGroupResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ]),
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAzureContainerServiceManagedClusterRequest(
                new Contract.EntityControllerGetEntityModelPageAzureContainerServiceManagedClusterRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureResourceGroupResourceResourceGroup]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.KubernetesClusterApiConnectivityStatus]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureContainerServiceManagedClusterAuthenticationAndAuthorizationMode]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.KubernetesClusterResourceUpdateTime]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureContainerServiceManagedClusterStatus]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.KubernetesClusterVersion]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureContainerServiceManagedClusterVirtualNetwork])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags])),
                new Contract.EntityControllerGetEntityModelPageAzureResourceRequestEncrypted(
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureEncryptedResourceEncryptionExists]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureEncryptedResourceVaultKeys]))));
}