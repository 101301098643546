import { Optional, useActions, useLocalization } from "@infrastructure";
import { Stack } from "@mui/system";
import _ from "lodash";
import React, { Ref, useEffect, useMemo, useState } from "react";
import { automaticCustomEntityAttributeDefinitionTemplateModelStore, ConfigurationController, Contract, PagedResourceTagKeySelector, TextInput, useScopeNavigationViewContext } from "../../../../../../../../../../common";
import { useCustomEntityAttributesContext } from "../../../CustomEntityAttributeDefinitions";
import { AddCustomEntityAttributeActions, ConfigurationValidation } from "../AddOrEdit";

type AutomaticCustomEntityAttributeResourceTagKeyDefinitionTemplateProps = {
    actionsRef: Ref<AddCustomEntityAttributeActions>;
    disabled: boolean;
    onValidChange: (validation: ConfigurationValidation) => void;
    readOnly: boolean;
};

export function AutomaticCustomEntityAttributeResourceTagKeyDefinitionTemplate({ actionsRef, disabled, onValidChange, readOnly }: AutomaticCustomEntityAttributeResourceTagKeyDefinitionTemplateProps) {
    const { scopeNodeModel } = useScopeNavigationViewContext();
    const configuration = useCustomEntityAttributesContext().item?.configuration as Contract.AutomaticCustomEntityAttributeDefinitionTemplateConfiguration | undefined;
    const [name, setName] =
        useState(
            !_.isNil(configuration) &&
            configuration.type == Contract.AutomaticCustomEntityAttributeDefinitionTemplateConfigurationType.ResourceTagKey
                ? configuration.name ?? ""
                : "");
    const [resourceTagKey, setResourceTagKey] =
        useState<Optional<string>>(
            () =>
                !_.isNil(configuration) &&
                configuration.type == Contract.AutomaticCustomEntityAttributeDefinitionTemplateConfigurationType.ResourceTagKey
                    ? _.head(configuration.resourceTagPatterns)!.keyPattern
                    : undefined);

    useActions<AddCustomEntityAttributeActions>(
        actionsRef,
        {
            reset() {
                setName("");
                setResourceTagKey(undefined);
            },
            async upsertCustomEntityAttribute(colorIndex, scopeId) {
                const { automaticCustomEntityAttributeDefinitionTemplateModel } =
                    await ConfigurationController.upsertAutomaticCustomEntityAttributeDefinitionTemplate(
                        new Contract.ConfigurationControllerUpsertAutomaticCustomEntityAttributeResourceTagKeyDefinitionTemplateRequest(
                            colorIndex,
                            configuration?.id,
                            scopeId,
                            _.isEmpty(name)
                                ? undefined
                                : name,
                            resourceTagKey!));

                await automaticCustomEntityAttributeDefinitionTemplateModelStore.notify(automaticCustomEntityAttributeDefinitionTemplateModel);
            }
        });
    const localization =
        useLocalization(
            "views.customer.configuration.entities.customEntityAttributeDefinitions.addOrEdit.automaticCustomEntityAttributeResourceTagKeyDefinitionTemplate",
            () => ({
                name: "Name (optional)",
                resourceTagKey: "Select a Tag Key"
            }));

    useEffect(
        () =>
            onValidChange(
                {
                    changed:
                        !_.isNil(configuration) && (
                            name != (configuration.name ?? "") ||
                            resourceTagKey != _.head(configuration!.resourceTagPatterns)?.keyPattern),
                    valid: !_.isNil(resourceTagKey)
                }),
        [name, resourceTagKey]);

    const selectedResourceTagKeys =
        useMemo(
            () =>
                _.isNil(resourceTagKey)
                    ? []
                    : [resourceTagKey],
            [resourceTagKey]);

    return (
        <Stack spacing={2}>
            <TextInput
                disabled={disabled}
                placeholder={
                    readOnly && _.isEmpty(name)
                        ? undefined
                        : localization.name()}
                readOnly={readOnly}
                text={name}
                onChange={setName}/>
            <PagedResourceTagKeySelector
                disabled={disabled}
                multiSelect={false}
                placeholder={localization.resourceTagKey()}
                readOnly={readOnly}
                scopeId={scopeNodeModel.configuration.id}
                selectedResourceTagKeys={selectedResourceTagKeys}
                onSelectedResourceTagKeyChanged={selectedTagKeys => setResourceTagKey(_.head(selectedTagKeys))}/>
        </Stack>);
}