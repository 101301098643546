import { ApiError, map, Message, useLocalization, useOrderedWizardContext } from "@infrastructure";
import { Box, Stack, Tab, Tabs } from "@mui/material";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import { Contract, TenantController, useTheme } from "../../../../../../../../../../../../common";
import { useGcpTenantManagerErrorTranslator } from "../../../../../../../../../../../../tenants";
import { useAddOrEditContext, useSetAddOrEditContext } from "../../index";
import { CloudShell, Manual } from "./components";

export function SinkPubSubSubscriptionItem() {
    const { gciTenantModel, organizationSinkPubSubSubscriptionEnabled, parentFolderId, permissionTypes, rawShortNameId, sinkPubSubSubscriptionEnabled, sinkPubSubSubscriptionRawId, tenantModel } = useAddOrEditContext();
    const setAddOrEditContext = useSetAddOrEditContext();
    const { useNextEffect } = useOrderedWizardContext();

    const tenantManagerErrorTranslator = useGcpTenantManagerErrorTranslator();
    const localization =
        useLocalization(
            "views.customer.scopes.hooks.useDefinition.hooks.useGcpDefinition.addOrEdit.sinkPubSubSubscriptionItem",
            () => ({
                actions: {
                    next: {
                        error: {
                            general: {
                                add: "Failed to add project",
                                edit: "Failed to update project"
                            }
                        }
                    }
                },
                onboardingType: {
                    [OnboardingType.CloudShell]: "gcloud CLI (Cloud Shell)",
                    [OnboardingType.Manual]: "Manual (Google Cloud Console)"
                },
                organizationSinkPubSubSubscriptionEnabled: "Audit logs were already configured at the organization level. Click **Finish** to complete the wizard.",
                sinkPubSubSubscriptionDisabled: "You chose not to enable audit logs. If this was a mistake, go back to step 3 (Permissions) and enable it. Otherwise, click **Finish** to complete the wizard."
            }));

    const onboardingTypes =
        useMemo(
            () =>
                _<OnboardingType>([]).
                    concatIf(
                        _.isNil(tenantModel),
                        OnboardingType.CloudShell).
                    concat(OnboardingType.Manual).
                    value(),
            []);

    const [selectedOnboardingType, setSelectedOnboardingType] = useState(() => _.first(onboardingTypes)!);
    useNextEffect(
        async () => {
            try {
                const { tenantModel: updatedTenantModel } =
                    _.isNil(tenantModel)
                        ? await TenantController.insertGcpTenant(
                            new Contract.TenantControllerInsertGcpTenantRequest(
                                gciTenantModel!.configuration.id,
                                parentFolderId,
                                permissionTypes!,
                                rawShortNameId!,
                                sinkPubSubSubscriptionEnabled
                                    ? sinkPubSubSubscriptionRawId
                                    : undefined))
                        : await TenantController.updateGcpTenant(
                            new Contract.TenantControllerUpdateGcpTenantRequest(
                                tenantModel.configuration.id,
                                permissionTypes!,
                                sinkPubSubSubscriptionEnabled
                                    ? sinkPubSubSubscriptionRawId
                                    : undefined));

                setAddOrEditContext(
                    addContext => ({
                        ...addContext,
                        updatedTenantModel
                    }));
            } catch (error) {
                return error instanceof ApiError && error.statusCode === 400
                    ? tenantManagerErrorTranslator(error.error as Contract.GcpTenantManagerError)
                    : localization.actions.next.error.general[
                        _.isNil(tenantModel)
                            ? "add"
                            : "edit"]();
            }
        },
        [selectedOnboardingType, sinkPubSubSubscriptionEnabled, sinkPubSubSubscriptionRawId]);

    const theme = useTheme();
    if (organizationSinkPubSubSubscriptionEnabled) {
        return (
            <Message
                level="success"
                title={localization.organizationSinkPubSubSubscriptionEnabled()}/>);
    }

    if (!sinkPubSubSubscriptionEnabled) {
        return (
            <Message
                level="success"
                title={localization.sinkPubSubSubscriptionDisabled()}/>);
    }

    return (
        <Stack
            spacing={2}
            sx={{ height: "100%" }}>
            <Tabs
                indicatorColor="primary"
                value={selectedOnboardingType}
                variant="standard"
                onChange={(_, value) => setSelectedOnboardingType(value)}>
                {_.map(
                    onboardingTypes,
                    onboardingType =>
                        <Tab
                            key={onboardingType}
                            label={localization.onboardingType[onboardingType]()}
                            sx={{ padding: theme.spacing(1, 2) }}
                            value={onboardingType}/>)}
            </Tabs>
            <Stack
                key={selectedOnboardingType}
                sx={{ overflow: "hidden auto" }}>
                <Box sx={{ maxWidth: theme.spacing(80) }}>
                    {map(
                        selectedOnboardingType,
                        {
                            [OnboardingType.CloudShell]: () => <CloudShell/>,
                            [OnboardingType.Manual]: () => <Manual/>
                        })}
                </Box>
            </Stack>
        </Stack>);
}

enum OnboardingType {
    CloudShell = "cloudShell",
    Manual = "manual"
}