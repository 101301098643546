﻿import { FormControl, Stack } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { CheckboxField, Optional, PasswordTextField, Steps, useLocalization, useOrderedWizardContext } from "@infrastructure";
import { Contract, useTheme } from "../../../../../../../../../../../../../../../common";
import { FolderEnabled } from "../../../../../../../../../components";
import { useAddOrEditContext, useSetAddOrEditContext } from "../AddOrEditGitLab";

export function ConfigureOrganizationItem() {
    const setAddOrEditContext = useSetAddOrEditContext();
    const { organizationModel, updatedAccessToken, updatedFolderEnabled, updatedSyncEnabled } = useAddOrEditContext();
    const organizationConfiguration = organizationModel?.configuration as Optional<Contract.GitLabOrganizationConfiguration>;

    const [accessToken, setAccessToken] =
        useState<Optional<string>>(
            updatedAccessToken ??
            (_.isNil(organizationConfiguration)
                ? ""
                : undefined));
    const [folderEnabled, setFolderEnabled] = useState(updatedFolderEnabled ?? organizationConfiguration?.folderEnabled ?? true);
    const [syncEnabled, setSyncEnabled] = useState(updatedSyncEnabled ?? organizationConfiguration?.syncEnabled ?? true);

    const { executing, setValid } = useOrderedWizardContext();

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCodeOrganizationItems.gitLab.addOrEditGitLab.configureOrganizationItem",
            () => ({
                actions: {
                    info: {
                        error: {
                            general: "Failed to access group"
                        }
                    }
                },
                fields: {
                    accessToken: "Root Group access token",
                    folderEnabled: {
                        info: "Mirror your group's structure as folders within Tenable, preserving its hierarchy and keeping the view synchronized.",
                        title: "Automatically update folder structure"
                    },
                    syncEnabled: "Sync Cloud to Code"
                },
                steps: {
                    step1: "Log into GitLab, and click on **Groups**.",
                    step2: "Click on the root, parent group that you want to connect.",
                    step3: "On the left-hand menu, click on **Settings -> Access Tokens**.",
                    step4: "Click **Add new token**",
                    step5: {
                        subSteps: {
                            step1: "*italic*Token name**",
                            step2: "*italic*Expiration date**: After this date, you will need to renew the token and update the configuration in Tenable.",
                            step3: "*italic*Select a role**: Select **Developer**",
                            step4: "*italic*Select scopes**: Select **api**"
                        },
                        title: "Enter the following details:"
                    },
                    step6: "Click **Create group access token** and then copy the token."
                }
            }));

    useEffect(
        () => {
            setAddOrEditContext(
                context => ({
                    ...context,
                    updatedAccessToken: accessToken,
                    updatedFolderEnabled: folderEnabled,
                    updatedSyncEnabled: syncEnabled
                }));
            setValid(!_.isNil(organizationConfiguration) || !!accessToken);
        },
        [accessToken, folderEnabled, syncEnabled]);

    const theme = useTheme();
    return (
        <Stack
            spacing={4}
            sx={{
                paddingBottom: theme.spacing(3)
            }}>
            <Steps variant="letters">
                {localization.steps.step1()}
                {localization.steps.step2()}
                {localization.steps.step3()}
                {localization.steps.step4()}
                <Stack>
                    {localization.steps.step5.title()}
                    <Steps variant="plainNumbers">
                        {localization.steps.step5.subSteps.step1()}
                        {localization.steps.step5.subSteps.step2()}
                        {localization.steps.step5.subSteps.step3()}
                        {localization.steps.step5.subSteps.step4()}
                    </Steps>
                </Stack>
                {localization.steps.step6()}
            </Steps>
            <FormControl>
                <PasswordTextField
                    disabled={executing}
                    label={localization.fields.accessToken()}
                    password={accessToken}
                    variant="outlined"
                    onPasswordChanged={clientSecret => setAccessToken(clientSecret)}/>
            </FormControl>
            <CheckboxField
                checked={syncEnabled}
                disabled={executing}
                onChange={() => setSyncEnabled(!syncEnabled)}>
                {localization.fields.syncEnabled()}
            </CheckboxField>
            <FolderEnabled
                folderEnabled={folderEnabled}
                info={localization.fields.folderEnabled.info()}
                setFolderEnabled={setFolderEnabled}
                title={localization.fields.folderEnabled.title()}/>
        </Stack>);
}