﻿import { SxProps, Typography } from "@mui/material";
import { TOptions } from "i18next";
import React from "react";
import { Contract } from "../../../../..";
import { InlineEntitySelection } from "../..";
import { InlineEntityTypeNameSelection } from "../../InlineEntityTypeNameSelection";

type ViewProps = {
    riskPolicyConfiguration?: Contract.AwsSsoPrincipalCreationRiskPolicyConfiguration;
    sx?: SxProps;
    templateTranslator: (options?: TOptions) => string;
};

export function View({ riskPolicyConfiguration, sx, templateTranslator }: ViewProps) {
    return (
        <Typography
            component="span"
            sx={sx}>
            {templateTranslator({
                principals:
                    <InlineEntitySelection
                        entityTypeName={Contract.TypeNames.AwsSsoPrincipal}
                        selection={{
                            allEntities: riskPolicyConfiguration?.allResources,
                            entityBuiltInAttributeTypeNames: riskPolicyConfiguration?.resourceBuiltInAttributeTypeNames,
                            entityCustomAttributeDefinitionIds: riskPolicyConfiguration?.resourceCustomAttributeDefinitionIds, entityIds: riskPolicyConfiguration?.resourceIds,
                            entityNamePattern: riskPolicyConfiguration?.resourceNamePattern
                        }}
                        sx={sx}/>,
                principalTypeNames:
                    <InlineEntityTypeNameSelection
                        selection={{ typeNames: riskPolicyConfiguration?.principalTypeNames }}
                        sx={sx}/>
            })}
        </Typography>);
}