import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract } from "../../../../../../..";
import { Entity } from "../../../../../..";

export function useAwsUpsertPermissionSetInlinePolicyChangeDefinition(change: Contract.AwsChange, entityLinkDisabled?: boolean) {
    const upsertPermissionSetInlinePolicyChange = change as Contract.AwsUpsertPermissionSetInlinePolicyChange;
    const localization =
        useLocalization(
            "common.change.hooks.useDefinition.hooks.useAwsDefinition.hooks.useAwsUpsertPermissionSetInlinePolicyChangeDefinition",
            () => ({
                create: {
                    false: "Edit {{permissionSet}} custom policy",
                    true: "Create a new custom policy on {{permissionSet}}"
                }
            }));
    return {
        title:
            localization.create[
                upsertPermissionSetInlinePolicyChange.create
                    ? "true"
                    : "false"]({
                permissionSet:
                    <Entity
                        entityIdOrModel={upsertPermissionSetInlinePolicyChange.resourceId}
                        glanceOptions={{ disabled: true }}
                        linkOptions={{ disabled: entityLinkDisabled }}
                        variant="typeText"/>
            })
    };
}