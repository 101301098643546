import { useMemo } from "react";
import { useOciResourceDefinition } from ".";
import { DefinitionOptions } from "../../..";
import { Contract } from "../../../../../../../../../../common";

export function useOciEntityDefinition(entityModel: Contract.EntityModel, options?: DefinitionOptions) {
    const useDefinition =
        useMemo(
            () => {
                switch (entityModel.entity.typeName) {
                    default:
                        return useOciResourceDefinition;
                }
            },
            []);

    return useDefinition(entityModel as Contract.OciEntityModel, options);
}