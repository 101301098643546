﻿import { map, UnexpectedError, useLocalization } from "@infrastructure";
import _, { Dictionary } from "lodash";
import { useMemo } from "react";
import { Contract, CustomerConsoleAppUrlHelper, TenantHelper, TenantTypeStatusItem, useTenantTypeTranslator } from "..";
import { useAwsTenantModelStatusTranslator, useAzureTenantModelStatusTranslator, useGcpTenantModelStatusTranslator, useOciTenantModelStatusTranslator, useOpTenantModelStatusTranslator } from "../../tenants";
import { useCloudProviderTenantTranslator } from "./useCloudProviderTenantTranslator";

export function useTenantTypeToTenantTypeStatusItem(tenantTypeToCloudProviderTenantModelsMap: Dictionary<Contract.TenantModel[]>, interactive = true) {
    const awsTenantModelStatusTranslator = useAwsTenantModelStatusTranslator();
    const azureTenantModelStatusTranslator = useAzureTenantModelStatusTranslator();
    const cloudProviderTenantTranslator = useCloudProviderTenantTranslator();
    const gcpTenantModelStatusTranslator = useGcpTenantModelStatusTranslator();
    const ociTenantModelStatusTranslator = useOciTenantModelStatusTranslator();
    const opTenantModelStatusTranslator = useOpTenantModelStatusTranslator();
    const tenantTypeTranslator = useTenantTypeTranslator();
    const localization =
        useLocalization(
            "common.hooks.useTenantTypeToTenantTypeStatusItem",
            () => ({
                [Contract.TypeNames.TenantType]: {
                    [Contract.TenantType.Aws]: [
                        "{{count | NumberFormatter.humanize}} Account | **{{status}}**",
                        "{{count | NumberFormatter.humanize}} Accounts | **{{status}}**"
                    ],
                    [Contract.TenantType.Azure]: [
                        "{{count | NumberFormatter.humanize}} Subscription | **{{status}}**",
                        "{{count | NumberFormatter.humanize}} Subscriptions | **{{status}}**"
                    ],
                    [Contract.TenantType.Gcp]: [
                        "{{count | NumberFormatter.humanize}} Project | **{{status}}**",
                        "{{count | NumberFormatter.humanize}} Projects | **{{status}}**"
                    ],
                    [Contract.TenantType.Oci]: [
                        "{{count | NumberFormatter.humanize}} Compartment | **{{status}}**",
                        "{{count | NumberFormatter.humanize}} Compartments | **{{status}}**"
                    ],
                    [Contract.TenantType.Op]: [
                        "{{count | NumberFormatter.humanize}} Tenant | **{{status}}**",
                        "{{count | NumberFormatter.humanize}} Tenants | **{{status}}**"
                    ]
                }
            }));

    return useMemo(
        () =>
            _(_.filter(
                TenantHelper.CloudProviderTenantTypes,
                tenantType => !_.isNil(tenantTypeToCloudProviderTenantModelsMap[tenantType]))).
                keyBy().
                mapValues(tenantType => {
                    const tenantModels = tenantTypeToCloudProviderTenantModelsMap[tenantType];
                    const tenantCount = _.size(tenantModels);

                    let tenantModelStatusSeverityToStatusToCount;
                    let validTenantStatusToCount;
                    switch (tenantType) {
                        case Contract.TenantType.Aws:
                            [tenantModelStatusSeverityToStatusToCount, validTenantStatusToCount] =
                                getTenantModelsStatus(
                                    tenantModels,
                                    (tenantModel: Contract.TenantModel) => (tenantModel as Contract.AwsTenantModel).status);
                            break;
                        case Contract.TenantType.Azure:
                            [tenantModelStatusSeverityToStatusToCount, validTenantStatusToCount] =
                                getTenantModelsStatus(
                                    tenantModels,
                                    (tenantModel: Contract.TenantModel) => (tenantModel as Contract.AzureTenantModel).status);
                            break;
                        case Contract.TenantType.Gcp:
                            [tenantModelStatusSeverityToStatusToCount, validTenantStatusToCount] =
                                getTenantModelsStatus(
                                    tenantModels,
                                    (tenantModel: Contract.TenantModel) => (tenantModel as Contract.GcpTenantModel).status);
                            break;
                        case Contract.TenantType.Oci:
                            [tenantModelStatusSeverityToStatusToCount, validTenantStatusToCount] =
                                getTenantModelsStatus(
                                    tenantModels,
                                    (tenantModel: Contract.TenantModel) => (tenantModel as Contract.OciTenantModel).status);
                            break;
                        case Contract.TenantType.Op:
                            tenantModelStatusSeverityToStatusToCount = {};
                            validTenantStatusToCount =
                                getValidTenantStatusToCount(
                                    tenantModels,
                                    (tenantModel: Contract.TenantModel) => (tenantModel as Contract.OpTenantModel).status);
                            break;
                        default:
                            throw new UnexpectedError("tenantType", tenantType);
                    }

                    return new TenantTypeStatusItem(
                        (status, tenantModelCount) =>
                            localization[Contract.TypeNames.TenantType][tenantType](
                                tenantModelCount,
                                {
                                    status:
                                        map(
                                            tenantType,
                                            {
                                                [Contract.TenantType.Aws]: () => awsTenantModelStatusTranslator(status as Contract.AwsTenantModelStatus),
                                                [Contract.TenantType.Azure]: () => azureTenantModelStatusTranslator(status as Contract.AzureTenantModelStatus),
                                                [Contract.TenantType.Gcp]: () => gcpTenantModelStatusTranslator(status as Contract.GcpTenantModelStatus),
                                                [Contract.TenantType.Oci]: () => ociTenantModelStatusTranslator(status as Contract.OciTenantModelStatus),
                                                [Contract.TenantType.Op]: () => opTenantModelStatusTranslator(status as Contract.OpTenantModelStatus)
                                            })
                                }),
                        tenantCount,
                        tenantModelStatusSeverityToStatusToCount as any,
                        cloudProviderTenantTranslator(tenantCount, tenantType),
                        tenantType,
                        tenantTypeTranslator(tenantType),
                        validTenantStatusToCount as any,
                        tenantType == Contract.TenantType.Op,
                        undefined,
                        interactive,
                        undefined,
                        CustomerConsoleAppUrlHelper.getScopesRelativeUrl(tenantType));
                }).
                value(),
        [tenantTypeToCloudProviderTenantModelsMap]);
}

function getTenantModelsStatus<TResult>(
    tenantModels: Contract.TenantModel[],
    getStatus: (tenantModel: Contract.TenantModel) => TResult) {
    return [
        _(tenantModels).
            filter(tenantModel => !_.isNil(tenantModel.statusSeverity)).
            groupBy(tenantModel => tenantModel.statusSeverity).
            mapValues(
                tenantModels =>
                    _(tenantModels).
                        groupBy(tenantModel => getStatus(tenantModel)).
                        mapValues(tenantModels => tenantModels.length).
                        value()).
            value(),
        getValidTenantStatusToCount(tenantModels, getStatus)];
}

function getValidTenantStatusToCount<TResult>(
    tenantModels: Contract.TenantModel[],
    getStatus: (tenantModel: Contract.TenantModel) => TResult) {
    return _(tenantModels).
        filter(tenantModel => _.isNil(tenantModel.statusSeverity)).
        groupBy(tenantModel => getStatus(tenantModel)).
        mapValues(tenantModels => tenantModels.length).
        value();
}