import { Action0, ignoreStoreDeletedDocumentError, Link, Sx } from "@infrastructure";
import { Box, Stack, SxProps, Typography } from "@mui/material";
import React from "react";
import { Contract, CustomerConsoleAppUrlHelper, OrganizationIcon, ScopeHelper, ScopeNameTranslatorOptions, scopeNodeModelStore, useScopeNameTranslator, useTheme } from "../..";
import { Icon } from "./icons";

const ScopeIgnoredStoreDeletedDocument = ignoreStoreDeletedDocumentError(Scope);
export { ScopeIgnoredStoreDeletedDocument as Scope };

type ScopeProps = {
    colored?: boolean;
    iconSx?: SxProps;
    onClick?: Action0;
    scopeId: string;
    scopeNameSx?: SxProps;
    scopeNameTranslatorOptions?: ScopeNameTranslatorOptions;
    sx?: SxProps;
    variant?: ScopeVariant;
};

export type ScopeVariant = "iconText" | "link" | "text" | "treeNode";

function Scope({ colored = true, iconSx, onClick, scopeId, scopeNameSx, scopeNameTranslatorOptions, sx, variant = "iconText" }: ScopeProps) {
    const scopeNodeModel = scopeNodeModelStore.useGet(scopeId);
    const scopeNameTranslator = useScopeNameTranslator();
    const theme = useTheme();
    switch (variant) {
        case "iconText":
        case "treeNode":
        case "link":
            return (
                <Link
                    urlOrGetUrl={
                        variant === "link"
                            ? CustomerConsoleAppUrlHelper.getScopeHashUrl(scopeId)
                            : undefined}
                    onClick={onClick}>
                    <Stack
                        alignItems="center"
                        direction="row"
                        sx={
                            Sx.combine({
                                color: "unset",
                                fontSize: "unset"
                            },
                            sx)}>
                        <Box
                            sx={{
                                fontSize:
                                    variant === "treeNode" &&
                                        ScopeHelper.isTenant(scopeNodeModel)
                                        ? "16px"
                                        : "18px",
                                paddingRight: "5px"
                            }}>
                            {scopeNodeModel.type === Contract.ScopeType.Customer
                                ? <OrganizationIcon sx={iconSx}/>
                                : <Icon
                                    colored={colored}
                                    scopeNodeModel={scopeNodeModel}
                                    sx={{
                                        color: theme.palette.text.secondary,
                                        ...iconSx
                                    }}/>}
                        </Box>
                        <Box
                            sx={{
                                flex: 1,
                                overflow: "hidden",
                                ...scopeNameSx
                            }}>
                            <Typography noWrap={true}>
                                {scopeNameTranslator(scopeId, scopeNameTranslatorOptions)}
                            </Typography>
                        </Box>
                    </Stack>
                </Link>);
        case "text":
            return (
                <Typography
                    component="span"
                    noWrap={true}
                    sx={
                        Sx.combine(
                            {
                                color: "unset",
                                fontSize: "unset"
                            },
                            scopeNameSx,
                            sx)}
                    onClick={onClick}>
                    {scopeNameTranslator(scopeId, scopeNameTranslatorOptions)}
                </Typography>);
    }
}