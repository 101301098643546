import { Step, TextViewer, useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { RiskDefinitionSection } from "../../../../../../utilities";
import { PolicySection } from "../../components";
import { useGetAwsSnsTopicRiskContext } from "../contexts";

export function useAwsSnsTopicHttpsOnlyDisabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsSnsTopicHttpsOnlyDisabledRisk;
    const topicModel = entityModelStore.useGet(risk.entityId) as Contract.AwsSnsTopicModel;
    const topic = topicModel.entity as Contract.AwsSnsTopic;

    const getAwsSnsTopicRiskContext = useGetAwsSnsTopicRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsSnsTopicHttpsOnlyDisabledRiskDefinition",
            () => ({
                description: "{{topic}} is not enforcing transport encryption over HTTPS",
                sections: {
                    policyDocument: "Policy",
                    resolution: {
                        step1: "Before enforcing transport encryption, verify that applications use only HTTPS to access the topic",
                        step2: "Click **Edit**",
                        step3: "Expand the **Access policy** section",
                        step4: "Add the deny statement to the topic policy",
                        step5: "Click **Save changes** to confirm"
                    }
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            topic:
                <Entity
                    entityIdOrModel={topicModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.Sns,
                AwsConsoleUrlBuilder.getSnsTopicUrl(topic)),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3(),
            new Step(
                localization.sections.resolution.step4(),
                {
                    contentElement:
                        <TextViewer
                            format="json"
                            text={(riskModel as Contract.AwsSnsTopicHttpsOnlyDisabledRiskModel).resolutionPolicyDocumentRawStatement}/>
                }),
            localization.sections.resolution.step5()
        ],
        riskModel,
        () => {
            const topicRiskContext = getAwsSnsTopicRiskContext(topicModel);
            return [
                topicRiskContext.generalInformation,
                topicRiskContext.accessLevel,
                topicRiskContext.encryption,
                topicRiskContext.subscriptions,
                topicRiskContext.sensitive,
                topicRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        },
        {
            sections: [
                new RiskDefinitionSection(
                    <PolicySection policyDocument={topic.policyDocument}/>,
                    localization.sections.policyDocument(),
                    {
                        expandable: true
                    })
            ]
        });
}