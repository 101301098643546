﻿import { ApiError, Link, Steps, useLocalization, useOrderedWizardContext } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { useAddOrEditContext, useSetAddOrEditContext } from "..";
import { ConfigurationController, ConsoleApiUrlHelper, Contract, scopeSystemEntityModelStore } from "../../../../../../../../../../../../../../../common";
import { useGitHubOrganizationManagerErrorTranslator } from "../hooks";

export function InstallAppItem() {
    const setAddOrEditContext = useSetAddOrEditContext();
    const { applicationName, defaultServerConfiguration, oAuthAccessToken, organizationModel, updatedServerId } = useAddOrEditContext();

    const gitHubServerModels = scopeSystemEntityModelStore.useGetCodeServer(Contract.CodeTenantType.GitHub);
    const appPageUrl =
        useMemo(
            () =>
                _.isNil(updatedServerId)
                    ? ConsoleApiUrlHelper.getGitHubAppInstallationUrl(applicationName)
                    : ConsoleApiUrlHelper.getGitHubServerAppInstallationUrl(
                        applicationName,
                        _.find(
                            gitHubServerModels,
                            gitHubServerModel => gitHubServerModel.configuration.id == updatedServerId)!.state!.endpoint!),
            [applicationName, defaultServerConfiguration, updatedServerId]);

    const gitHubOrganizationModels = scopeSystemEntityModelStore.useGetCodeOrganization(Contract.CodeTenantType.GitHub);
    const existingOrganizationNames =
        useMemo(
            () =>
                _.map(
                    gitHubOrganizationModels,
                    gitHubOrganizationModel => gitHubOrganizationModel.configuration.name),
            [gitHubOrganizationModels]);

    const { useNextEffect } = useOrderedWizardContext();

    const gitHubOrganizationManagerErrorTranslator = useGitHubOrganizationManagerErrorTranslator();
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCodeOrganizationItems.gitHub.addOrEditGitHub.installAppItem",
            () => ({
                actions: {
                    info: {
                        error: {
                            general: "Failed to access GitHub user",
                            noOrganizations: "No GitHub organization installed the app"
                        }
                    }
                },
                steps: {
                    step1: {
                        link: "Tenable Cloud Security App",
                        title: "Go to the {{gitHubAppLink}} page"
                    },
                    step2: "Click **Install/Configure**",
                    step3: "On the Install page, click on your organization name",
                    step4: "If you want to grant Tenable Cloud Security access to all your repositories, select **All repositories**. Otherwise, select **Only select repositories**, and then select at least one repository",
                    step5: "Click **Install**. If necessary, enter your password, and then confirm that the app was installed"
                }
            }));

    useNextEffect(
        async () => {
            try {
                if (!_.isNil(organizationModel)) {
                    return;
                }

                const { organizationNames } =
                    await ConfigurationController.getGitHubOrganizationOnboardingOrganizationsInfo(
                        new Contract.ConfigurationControllerGetGitHubOrganizationOnboardingOrganizationsInfoRequest(
                            oAuthAccessToken!,
                            updatedServerId));
                const newOrganizationNames =
                    _.filter(
                        organizationNames,
                        organizationName =>
                            _.every(
                                existingOrganizationNames,
                                existingOrganizationName => organizationName.toLowerCase() != existingOrganizationName.toLowerCase()));

                if (_.isEmpty(newOrganizationNames)) {
                    return localization.actions.info.error.noOrganizations();
                }

                setAddOrEditContext(
                    context => ({
                        ...context,
                        organizationNames: newOrganizationNames
                    }));
            } catch (error) {
                return error instanceof ApiError && error.statusCode === 400
                    ? gitHubOrganizationManagerErrorTranslator(error.error as Contract.GitHubOrganizationManagerError)
                    : localization.actions.info.error.general();
            }
        },
        []);

    return (
        <Steps variant="letters">
            {localization.steps.step1.title({
                gitHubAppLink:
                    <Link
                        urlOrGetUrl={appPageUrl}
                        variant="external">
                        {localization.steps.step1.link()}
                    </Link>
            })}
            {localization.steps.step2()}
            {localization.steps.step3()}
            {localization.steps.step4()}
        </Steps>);
}