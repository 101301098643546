﻿import { useLocalization } from "@infrastructure";

export function useGcpIamRoleBindingExpiredTranslator() {
    const localization =
        useLocalization(
            "tenants.gcp.hooks.useGcpIamRoleBindingExpiredTranslator",
            () => ({
                false: "Active",
                true: "Expired"
            }));
    return (expired: boolean) =>
        expired
            ? localization.true()
            : localization.false();
}