﻿import { Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { useCallback, useRef, useState } from "react";
import { CsvExportButton, DataTable, DataTableAction, DataTableColumn, DataTableColumnRenderProps, DataTableFetchItemsResult, DataTableSort, DeferredFilter, EmptyMessageText, EnumValuesFilter, InlineItems, NoneIcon, Optional, optionalTableCell, PagedValuesFilter, TimeHelper, useGetExportFileName, useLocalization, useOperation, ValueFilter } from "@infrastructure";
import { Contract, EntitiesCell, EntityTypeFilter, ExportReportHelper, InlineVulnerability, ItemSelectionHelper, PagedEntityFilter, ScopeTenantFilter, SeverityFilter, StorageHelper, Tenant, useLayoutOptions, WorkloadController } from "../../../../../../common";
import { ContainerImageUsageFilter } from "../../../Entities/components/Table/hooks/useDefinition/components";
import { useOperatingSystemTypeTranslator } from "../../../Entities/hooks";
import { useGetWorkloadResourceScanPackageEntityFilterIdPage, useGetWorkloadResourceScanPackageValueFilterItemPage } from "../../hooks";
import { InlineItemsCell } from "../InlineItemsCell";

type PackagesProps = {
    entityId?: string;
    vulnerabilityRawId?: string;
};

export function Packages({ entityId, vulnerabilityRawId }: PackagesProps) {
    const getExportFileName = useGetExportFileName(Contract.ReportContentType.Csv);
    const operatingSystemTypeTranslator = useOperatingSystemTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.workloadAnalysis.packages",
            () => ({
                columns: {
                    containerImageUsage: "Container Image Usage",
                    fixableVulnerability: "With Fix Only",
                    fixedBy: "Fixed By",
                    operatingSystemType: "Operating System Type",
                    packageDisplayName: "Name",
                    packageFilePaths: "Paths",
                    packageType: {
                        title: "Type",
                        [Contract.TypeNames.WorkloadResourceScanPackageType]: {
                            [Contract.WorkloadResourceScanPackageType.LanguagePackage]: "Library",
                            [Contract.WorkloadResourceScanPackageType.OperatingSystem]: "Operating System",
                            [Contract.WorkloadResourceScanPackageType.OperatingSystemPackage]: "Package"
                        }
                    },
                    tenantIds: "Resource Accounts",
                    vulnerabilityRawIds: {
                        empty: "No Matching Vulnerabilities",
                        filterPlaceholder: {
                            rawId: "Vulnerability",
                            severity: "Vulnerability Severity"
                        },
                        title: "Vulnerabilities"
                    },
                    workloadResourceIds: "Resources",
                    workloadResourceTypeName: "Scanned Resources Type"
                },
                empty: {
                    withFilter: "No Matching Software",
                    withoutFilter: "No Software"
                },
                title: "Software"
            }));
    useLayoutOptions({
        view:
            _.isNil(entityId) &&
            _.isNil(vulnerabilityRawId)
                ? { title: localization.title() }
                : undefined
    });

    const getFilters =
        (filterMap: Dictionary<any>) =>
            new Contract.WorkloadControllerPackageModelsFilters(
                ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.TenantId]),
                _.isNil(entityId)
                    ? ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.WorkloadResourceId])
                    : new Contract.PagedItemSelection<string>(
                        false,
                        [entityId],
                        Contract.PagedItemSelectionType.Include) as Optional<Contract.PagedItemSelection<string>>,
                ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.WorkloadResourceTypeName]),
                ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.PackageDisplayName]),
                ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.PackageFilePath]),
                filterMap[Contract.WorkloadControllerRequestProperty.FixableVulnerability] as boolean,
                ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.OperatingSystemType]),
                ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.PackageType]),
                filterMap[Contract.WorkloadControllerRequestProperty.ContainerImageWorkloadExists] as boolean,
                _.isNil(vulnerabilityRawId)
                    ? ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.VulnerabilityRawId])
                    : new Contract.PagedItemSelection<string>(
                        false,
                        [vulnerabilityRawId],
                        Contract.PagedItemSelectionType.Include) as Optional<Contract.PagedItemSelection<string>>,
                ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.WorkloadControllerRequestProperty.VulnerabilitySeverity]));

    async function getPackageModelPageRequest(filterMap: Dictionary<any>, limit: number, skip: number) {
        const nextPageAggregationCursor =
            skip === 0
                ? undefined
                : fetchPackageModelNextPageAggregationCursorRef.current;

        return new Contract.WorkloadControllerGetPackageModelPageRequest(
            getFilters(filterMap),
            !!vulnerabilityRawId,
            limit,
            nextPageAggregationCursor);
    }

    const fetchPackageModelNextPageAggregationCursorRef = useRef<Contract.ElasticsearchIndexAggregationCursor>();
    const fetchPackageModels =
        async (filterMap: Dictionary<any>, _sort: Optional<DataTableSort>, skip: number, limit: number) => {

            const packageModelPageRequest =
                await getPackageModelPageRequest(
                    filterMap,
                    limit,
                    skip);

            const getPackageModelCount =
                async () => {
                    const { packageModelCount } = await WorkloadController.getPackageModelCount(packageModelPageRequest);
                    return { count: packageModelCount };
                };

            const { packageModelPage } = await WorkloadController.getPackageModelPage(packageModelPageRequest);

            return new DataTableFetchItemsResult(
                getPackageModelCount,
                packageModelPage.items,
                _.isNil(packageModelPage.itemNextPageSearchCursor),
                {
                    onAppendData: () => fetchPackageModelNextPageAggregationCursorRef.current = packageModelPage.itemNextPageSearchCursor
                });
        };

    const filterMapRef = useRef<Dictionary<any>>();
    const [packageCount, setPackageCount] = useState<number>();
    const getWorkloadResourceScanPackageEntityFilterIdPage =
        useGetWorkloadResourceScanPackageEntityFilterIdPage(
            Contract.WorkloadControllerRequestProperty.WorkloadResourceId,
            undefined,
            undefined,
            vulnerabilityRawId);

    const getPackageType =
        (packageModel: Contract.WorkloadAnalysisPackageModel) =>
            _.size(packageModel.types) > 1
                ? Contract.WorkloadResourceScanPackageType.OperatingSystem
                : _(packageModel.types).
                    first()!;

    const fetchPropertyItems =
        async (item: Contract.WorkloadAnalysisPackageModel, property: Contract.WorkloadControllerRequestProperty) => {
            const { items } =
                await WorkloadController.getPackageModelPropertyItems(
                    new Contract.WorkloadControllerPackageModelPropertyItemsRequest(
                        getFilters(filterMapRef.current ?? {}),
                        item.identifier,
                        property)) as Contract.WorkloadControllerPackageModelPropertyItemsResponse<any>;
            return items;
        };

    const [filtersPromise] =
        useOperation(
            Packages,
            useCallback(
                async () => {
                    const { filters } = await WorkloadController.getWorkloadResourceScanPackageFilters(new Contract.WorkloadControllerGetWorkloadPackageScanFiltersRequest(vulnerabilityRawId));
                    return filters;
                },
                [vulnerabilityRawId]));

    const vulnerabilitiesPagedValuesFilter =
        useGetWorkloadResourceScanPackageValueFilterItemPage(
            Contract.WorkloadControllerRequestProperty.VulnerabilityRawId,
            !_.isNil(entityId)
                ? [entityId!]
                : undefined);

    return (
        <DataTable
            columnOptions={{
                orderOptions: {
                    enabled: true,
                    persistenceStorageItem:
                        vulnerabilityRawId
                            ? StorageHelper.customerWorkloadAnalysisPackagesProfileTableColumnOrder
                            : StorageHelper.customerWorkloadAnalysisPackagesTableColumnOrder
                },
                resizable: true,
                selectorOptions: {
                    enabled: true,
                    persistenceStorageItem:
                        vulnerabilityRawId
                            ? StorageHelper.customerWorkloadAnalysisPackagesProfileTableColumnSelector
                            : StorageHelper.customerWorkloadAnalysisPackagesTableColumnSelector
                },
                stickyColumnId: Contract.WorkloadControllerRequestProperty.PackageDisplayName
            }}
            emptyMessageOptions={{
                emptyMessageText:
                    new EmptyMessageText(
                        localization.empty.withoutFilter(),
                        localization.empty.withFilter())
            }}
            fetchItems={fetchPackageModels}
            filtersOptions={{
                onChanged: filterMap => filterMapRef.current = filterMap,
                persist: {
                    visibilityStorageItem:
                        vulnerabilityRawId
                            ? StorageHelper.customerWorkloadAnalysisPackagesProfileTableFilters
                            : StorageHelper.customerWorkloadAnalysisPackagesTableFilters
                },
                ...(vulnerabilityRawId && {
                    sx: {
                        paddingLeft: 0,
                        paddingTop: 0
                    }
                })
            }}
            getItemId={packageModel => packageModel.id}
            sortOptions={{ enabled: false }}
            virtualizationEnabled={true}
            onItemCountChanged={packageCount => setPackageCount(packageCount)}>
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        default: true,
                        element:
                            <PagedValuesFilter
                                emptyValueOptions={{ enabled: false }}
                                getValuePage={
                                    useGetWorkloadResourceScanPackageValueFilterItemPage(
                                        Contract.WorkloadControllerRequestProperty.PackageDisplayName,
                                        !_.isNil(entityId)
                                            ? [entityId!]
                                            : undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        vulnerabilityRawId)}
                                placeholder={localization.columns.packageDisplayName()}/>
                    }
                }}
                id={Contract.WorkloadControllerRequestProperty.PackageDisplayName}
                itemProperty={(packageModel: Contract.WorkloadAnalysisPackageModel) => packageModel.displayName}
                key={Contract.WorkloadControllerRequestProperty.PackageDisplayName}
                selectorOptions={{ disabled: true }}
                title={localization.columns.packageDisplayName()}/>
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        default: !!vulnerabilityRawId,
                        element:
                            <PagedValuesFilter
                                getValuePage={
                                    useGetWorkloadResourceScanPackageValueFilterItemPage(
                                        Contract.WorkloadControllerRequestProperty.PackageFilePath,
                                        !_.isNil(entityId)
                                            ? [entityId!]
                                            : undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        vulnerabilityRawId)}
                                placeholder={localization.columns.packageFilePaths()}/>
                    }
                }}
                id={Contract.WorkloadControllerRequestProperty.PackageFilePath}
                key={Contract.WorkloadControllerRequestProperty.PackageFilePath}
                render={
                    ({ item: packageModel }: DataTableColumnRenderProps<Contract.WorkloadAnalysisPackageModel>) =>
                        _.isEmpty(packageModel.topFilePaths)
                            ? <NoneIcon/>
                            : <InlineItemsCell
                                emptyMessageText={
                                    new EmptyMessageText(
                                        localization.empty.withoutFilter(),
                                        localization.empty.withFilter())}
                                getPopoverItems={
                                    async () =>
                                        await fetchPropertyItems(
                                            packageModel,
                                            Contract.WorkloadControllerRequestProperty.PackageFilePath)}
                                itemCount={packageModel.filePathCount}
                                items={packageModel.topFilePaths}
                                renderItem={
                                    path =>
                                        <Typography noWrap={true}>
                                            {path}
                                        </Typography>}/>}
                title={localization.columns.packageFilePaths()}/>
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        default: !!vulnerabilityRawId,
                        element:
                            <EnumValuesFilter
                                enumType={Contract.WorkloadResourceScanPackageType}
                                enumTypeTranslator={(packageType: Contract.WorkloadResourceScanPackageType) => localization.columns.packageType[Contract.TypeNames.WorkloadResourceScanPackageType][packageType]()}
                                placeholder={localization.columns.packageType.title()}/>
                    }
                }}
                id={Contract.WorkloadControllerRequestProperty.PackageType}
                itemProperty={(packageModel: Contract.WorkloadAnalysisPackageModel) => localization.columns.packageType[Contract.TypeNames.WorkloadResourceScanPackageType][getPackageType(packageModel)]()}
                key={Contract.WorkloadControllerRequestProperty.PackageType}
                title={localization.columns.packageType.title()}/>
            {_.isNil(entityId) && (
                <DataTableColumn
                    filterOptions={{
                        itemOrItems: [
                            {
                                element: <ContainerImageUsageFilter/>,
                                id: Contract.WorkloadControllerRequestProperty.ContainerImageWorkloadExists,
                                title: localization.columns.containerImageUsage()
                            },
                            {
                                default: true,
                                element:
                                    <PagedEntityFilter
                                        getEntityIdPage={getWorkloadResourceScanPackageEntityFilterIdPage}
                                        placeholder={localization.columns.workloadResourceIds()}/>,
                                id: Contract.WorkloadControllerRequestProperty.WorkloadResourceId,
                                title: localization.columns.workloadResourceIds()
                            },
                            {
                                element:
                                    <DeferredFilter
                                        promiseOrGetPromise={filtersPromise}
                                        title={localization.columns.workloadResourceTypeName()}>
                                        {filters =>
                                            <EntityTypeFilter
                                                emptyValue={filters.workloadResourceTypeNameItems.emptyValue}
                                                entityTypeNames={filters.workloadResourceTypeNameItems.items}
                                                groupItemTitle={false}
                                                placeholder={localization.columns.workloadResourceTypeName()}/>}
                                    </DeferredFilter>,
                                id: Contract.WorkloadControllerRequestProperty.WorkloadResourceTypeName,
                                title: localization.columns.workloadResourceTypeName()
                            }
                        ]
                    }}
                    id={Contract.WorkloadControllerRequestProperty.WorkloadResourceId}
                    key={Contract.WorkloadControllerRequestProperty.WorkloadResourceId}
                    render={
                        ({ item: packageModel }: DataTableColumnRenderProps<Contract.WorkloadAnalysisPackageModel>) =>
                            <EntitiesCell
                                deferredEntities={{
                                    count: packageModel.workloadResourceCount,
                                    firstItem: _.first(packageModel.workloadResourceTopIds),
                                    getItems:
                                        async () =>
                                            await fetchPropertyItems(
                                                packageModel,
                                                Contract.WorkloadControllerRequestProperty.WorkloadResourceId)
                                }}
                                entityTypeName={Contract.TypeNames.Entity}
                                entityVariant="iconTextTypeTenant"/>}
                    title={localization.columns.workloadResourceIds()}/>)}
            {_.isNil(entityId) &&
                <DataTableColumn
                    filterOptions={{
                        itemOrItems: {
                            default: true,
                            element:
                                <DeferredFilter
                                    promiseOrGetPromise={filtersPromise}
                                    title={localization.columns.tenantIds()}>
                                    {filters =>
                                        <ScopeTenantFilter
                                            placeholder={localization.columns.tenantIds()}
                                            tenantIds={filters.tenantIdItems.items}/>}
                                </DeferredFilter>
                        }
                    }}
                    id={Contract.WorkloadControllerRequestProperty.TenantId}
                    key={Contract.WorkloadControllerRequestProperty.TenantId}
                    render={
                        ({ item: packageModel }: DataTableColumnRenderProps<Contract.WorkloadAnalysisPackageModel>) =>
                            <InlineItemsCell
                                getPopoverItems={
                                    async () =>
                                        await fetchPropertyItems(
                                            packageModel,
                                            Contract.WorkloadControllerRequestProperty.TenantId)}
                                itemCount={packageModel.tenantCount}
                                items={packageModel.tenantTopIds}
                                renderItem={
                                    tenantId =>
                                        <Tenant
                                            tenantId={tenantId}
                                            variant="iconText"/>}/>}
                    title={localization.columns.tenantIds()}/>}
            <DataTableColumn
                filterOptions={{
                    itemOrItems:
                        _.isNil(vulnerabilityRawId)
                            ? [
                                {
                                    element:
                                        <PagedValuesFilter
                                            getValuePage={vulnerabilitiesPagedValuesFilter}
                                            placeholder={localization.columns.vulnerabilityRawIds.filterPlaceholder.rawId()}/>,
                                    id: Contract.WorkloadControllerRequestProperty.VulnerabilityRawId,
                                    title: localization.columns.vulnerabilityRawIds.filterPlaceholder.rawId()
                                },
                                {
                                    element:
                                        <SeverityFilter
                                            placeholder={localization.columns.vulnerabilityRawIds.filterPlaceholder.severity()}/>,
                                    id: Contract.WorkloadControllerRequestProperty.VulnerabilitySeverity,
                                    title: localization.columns.vulnerabilityRawIds.filterPlaceholder.severity()
                                }
                            ]
                            : undefined
                }}
                id={Contract.WorkloadControllerRequestProperty.VulnerabilityRawId}
                key={Contract.WorkloadControllerRequestProperty.VulnerabilityRawId}
                render={optionalTableCell(
                    (packageModel: Contract.WorkloadAnalysisPackageModel) =>
                        packageModel.vulnerabilityCount > 0 &&
                        !_.isEmpty(packageModel.topVulnerabilities) &&
                        <InlineItemsCell
                            emptyMessageText={new EmptyMessageText(localization.columns.vulnerabilityRawIds.empty())}
                            getItemDisplayName={(vulnerability: Contract.VulnerabilityData) => vulnerability.rawId}
                            getPopoverItems={
                                async () =>
                                    await fetchPropertyItems(
                                        packageModel,
                                        Contract.WorkloadControllerRequestProperty.VulnerabilityRawId)}
                            itemCount={packageModel.vulnerabilityCount}
                            items={packageModel.topVulnerabilities}
                            renderItem={
                                (vulnerability: Contract.VulnerabilityData) =>
                                    <InlineVulnerability rawId={vulnerability.rawId}/>}/>)}
                selectorOptions={{ default: !vulnerabilityRawId }}
                title={localization.columns.vulnerabilityRawIds.title()}/>
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.OperatingSystemType}
                                enumTypeTranslator={operatingSystemTypeTranslator}
                                placeholder={localization.columns.operatingSystemType()}/>
                    }
                }}
                id={Contract.WorkloadControllerRequestProperty.OperatingSystemType}
                itemProperty={(packageModel: Contract.WorkloadAnalysisPackageModel) => operatingSystemTypeTranslator(packageModel.operatingSystemType)}
                key={Contract.WorkloadControllerRequestProperty.OperatingSystemType}
                selectorOptions={{ default: false }}
                title={localization.columns.operatingSystemType()}/>
            {!!vulnerabilityRawId &&
                <DataTableColumn
                    id={Contract.WorkloadControllerRequestProperty.ResolutionVersions}
                    key={Contract.WorkloadControllerRequestProperty.ResolutionVersions}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.WorkloadAnalysisPackageModel>) =>
                            item.vulnerabilityResolutionVersions?.length
                                ? <InlineItems
                                    items={item.vulnerabilityResolutionVersions}
                                    variant="itemPlusItemCount"/>
                                : <NoneIcon/>}
                    title={localization.columns.fixedBy()}/>}
            {!!vulnerabilityRawId &&
                <DataTableColumn
                    filterOptions={{
                        itemOrItems: {
                            default: true,
                            element:
                                <ValueFilter
                                    items={[
                                        {
                                            title: localization.columns.fixableVulnerability(),
                                            value: true
                                        }
                                    ]}
                                    placeholder={localization.columns.fixableVulnerability()}/>
                        }
                    }}
                    id={Contract.WorkloadControllerRequestProperty.FixableVulnerability}
                    key={Contract.WorkloadControllerRequestProperty.FixableVulnerability}
                    title={localization.columns.fixableVulnerability()}/>}
            <DataTableAction>
                <CsvExportButton
                    fileNameOptions={{
                        filtered: !_.isEmpty(filterMapRef.current),
                        prefix: localization.title()
                    }}
                    itemCount={packageCount}
                    showLimitMessage={false}
                    onClick={
                        async fileNameOptions => {
                            await ExportReportHelper.downloadRemote(
                                new Contract.ReportControllerWorkloadAnalysisPackagesReportRequestDefinition(
                                    getExportFileName(fileNameOptions),
                                    TimeHelper.timeZoneId(),
                                    Contract.TypeNames.ReportControllerWorkloadAnalysisPackagesReportRequestDefinition,
                                    _.isNil(filterMapRef.current),
                                    getFilters(filterMapRef.current ?? {}),
                                    !!vulnerabilityRawId));
                        }}/>
            </DataTableAction>
        </DataTable>);
}