import _, { Dictionary } from "lodash";
import React, { useMemo } from "react";
import { Contract } from "../../../../../../../../..";
import { Items } from "./Items";

export function Analyzing() {
    const complianceSectionDatas =
        useMemo(
            () => {
                const emptyComplianceSectionsData =
                    new Contract.ComplianceSectionData(
                        true,
                        true,
                        new Contract.ComplianceDataStats(
                            0,
                            0,
                            1,
                            {}),
                        {},
                        "",
                        false,
                        Contract.BuiltInComplianceSectionType.AwsWellArchitected,
                        [],
                        "",
                        [
                            new Contract.ComplianceSectionData(
                                true,
                                true,
                                new Contract.ComplianceDataStats(
                                    0,
                                    0,
                                    1,
                                    {}),
                                {},
                                "",
                                false,
                                Contract.BuiltInComplianceSectionType.AwsWellArchitected,
                                [
                                    new Contract.ComplianceRiskPolicyData(
                                        true,
                                        true,
                                        new Contract.ComplianceDataStats(
                                            0,
                                            0,
                                            1,
                                            {}),
                                        {},
                                        Contract.TypeNames.ComplianceRiskPolicyData,
                                        Contract.RiskPolicyTypeMetadataAnalysisGroupType.AllEntities,
                                        0,
                                        Contract.TypeNames.AwsInboundExternalVpcRiskPolicyConfiguration,
                                        Contract.TypeNames.AwsInboundExternalVpcRiskPolicyConfiguration)
                                ],
                                "",
                                [])
                        ]);
                const createSectionData =
                    (sectionType: Contract.BuiltInComplianceSectionType,
                        securedCount: number,
                        unsecuredSeverityToCountMap: Dictionary<number>) => {
                        const count =
                            securedCount +
                            _(unsecuredSeverityToCountMap).
                                values().
                                sum();
                        return new Contract.ComplianceSectionData(
                            true,
                            true,
                            new Contract.ComplianceDataStats(
                                count,
                                securedCount,
                                count === 0
                                    ? 1
                                    : securedCount / count,
                                unsecuredSeverityToCountMap),
                            {},
                            "",
                            false,
                            sectionType,
                            [],
                            "",
                            [emptyComplianceSectionsData]);
                    };

                return [
                    createSectionData(
                        Contract.BuiltInComplianceSectionType.AwsWellArchitected,
                        30,
                        {
                            "Critical": 20,
                            "High": 50,
                            "Low": 11,
                            "Medium": 10
                        }),
                    createSectionData(
                        Contract.BuiltInComplianceSectionType.CisAzure_2_0,
                        4,
                        {
                            "Critical": 2,
                            "High": 0,
                            "Low": 14,
                            "Medium": 12
                        }),
                    createSectionData(
                        Contract.BuiltInComplianceSectionType.CsaCcm_4_0,
                        31,
                        {
                            "Critical": 3,
                            "High": 11,
                            "Low": 11,
                            "Medium": 11
                        }),
                    createSectionData(
                        Contract.BuiltInComplianceSectionType.Gdpr,
                        0,
                        {
                            "Critical": 2,
                            "High": 30,
                            "Low": 14,
                            "Medium": 20
                        }),
                    createSectionData(
                        Contract.BuiltInComplianceSectionType.Nist_800_53_5,
                        23,
                        {
                            "Critical": 2,
                            "High": 3,
                            "Low": 14,
                            "Medium": 2
                        }),
                    createSectionData(
                        Contract.BuiltInComplianceSectionType.Nist_800_171_2,
                        23,
                        {
                            "Critical": 2,
                            "High": 3,
                            "Low": 14,
                            "Medium": 2
                        }),
                    createSectionData(
                        Contract.BuiltInComplianceSectionType.PciDss_4_0,
                        55,
                        {
                            "Critical": 4,
                            "High": 30,
                            "Low": 14,
                            "Medium": 20
                        }),
                    createSectionData(
                        Contract.BuiltInComplianceSectionType.Tenable,
                        9,
                        {
                            "Critical": 2,
                            "High": 49,
                            "Low": 30,
                            "Medium": 6
                        })
                ];
            },
            []);
    return (
        <Items
            complianceSectionDatas={complianceSectionDatas}
            timeFrame={Contract.TimeFrame.Short}/>);
}