import { map } from "@infrastructure";
import { useMemo } from "react";
import { Contract } from "../../../../../../../../../../common";
import { AuditEventDefinitionContext } from "../../useDefinition";
import { useAwsSsoPermissionSetAssignmentEligibilityAuditEventDefinition, useAzureRoleAssignmentEligibilityAuditEventDefinition, useGcpRoleBindingEligibilityAuditEventDefinition, useGroupMembershipEligibilityAuditEventDefinition, useOneLoginDirectoryRoleAssignmentEligibilityAuditEventDefinition } from "./hooks";

export function usePermissionEligibilityAuditEventDefinition(context: AuditEventDefinitionContext) {
    const useDefinition =
        useMemo(
            () =>
                map(
                    (context.auditEventModel as Contract.PermissionEligibilityAuditEventModel).auditEvent.permissionEligibilityData.typeName,
                    {
                        [Contract.TypeNames.PermissionEligibilityAuditEventAwsSsoPermissionSetAssignmentEligibilityData]: () => useAwsSsoPermissionSetAssignmentEligibilityAuditEventDefinition,
                        [Contract.TypeNames.PermissionEligibilityAuditEventAzureRoleAssignmentEligibilityData]: () => useAzureRoleAssignmentEligibilityAuditEventDefinition,
                        [Contract.TypeNames.PermissionEligibilityAuditEventGcpRoleBindingEligibilityData]: () => useGcpRoleBindingEligibilityAuditEventDefinition,
                        [Contract.TypeNames.PermissionEligibilityAuditEventGroupMembershipEligibilityData]: () => useGroupMembershipEligibilityAuditEventDefinition,
                        [Contract.TypeNames.PermissionEligibilityAuditEventOneLoginDirectoryRoleAssignmentEligibilityData]: () => useOneLoginDirectoryRoleAssignmentEligibilityAuditEventDefinition
                    }),
            []);

    return useDefinition(context);
}