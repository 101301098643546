import { Dictionary } from "lodash";
import React from "react";
import { DataTableColumn, DataTableSortType, EnumValuesFilter, NumberFormatter, PagedValuesFilter, useLocalization } from "@infrastructure";
import { useAwsCommonAccessResourceDefinition, useAwsCommonCloudWatchLoggingResourceDefinition, useAwsCommonEncryptedResourceDefinition, useAwsCommonNetworkedResourceDefinition, useAwsCommonResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, ItemSelectionHelper, TimeRangeHelper } from "../../../../../../../../../../common";
import { useAwsEsDomainEngineTypeTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";

export function useAwsEsDomainDefinition(definitionData: DefinitionData) {
    const commonAccessResourceDefinition = useAwsCommonAccessResourceDefinition(definitionData);
    const commonCloudWatchLoggingResourceDefinition = useAwsCommonCloudWatchLoggingResourceDefinition(definitionData);
    const commonEncryptedResourceDefinition = useAwsCommonEncryptedResourceDefinition(definitionData);
    const commonNetworkedResourceDefinition = useAwsCommonNetworkedResourceDefinition(definitionData);
    const commonResourceDefinition = useAwsCommonResourceDefinition(definitionData);

    const esDomainEngineTypeTranslator = useAwsEsDomainEngineTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.aws.useAwsEsDomainDefinition",
            () => ({
                columns: {
                    encryption: "Encryption At Rest",
                    engineType: "Engine",
                    engineVersion: "Version",
                    storageSize: "Size"
                }
            }));

    return new EntityTableDefinition(
        [
            commonResourceDefinition.columns.tenantColumn,
            commonResourceDefinition.columns.creationTimeColumn,
            commonAccessResourceDefinition.columns.accessLevelColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsEsDomainModel) => ({
                            [localization.columns.engineType()]: esDomainEngineTypeTranslator((item.entity as Contract.AwsEsDomain).engineType)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.AwsEsDomainEngineType}
                                enumTypeTranslator={esDomainEngineTypeTranslator}
                                placeholder={localization.columns.engineType()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AwsEsDomainEngineType}
                itemProperty={(item: Contract.AwsEsDomainModel) => esDomainEngineTypeTranslator((item.entity as Contract.AwsEsDomain).engineType)}
                key={Contract.EntityModelProperty.AwsEsDomainEngineType}
                title={localization.columns.engineType()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsEsDomainModel) => ({
                            [localization.columns.engineVersion()]: (item.entity as Contract.AwsEsDomain).engineVersion
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedValuesFilter
                                getValuePage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                        Contract.TypeNames.AwsEsDomain,
                                        Contract.EntityModelProperty.AwsEsDomainEngineVersion)}
                                placeholder={localization.columns.engineVersion()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AwsEsDomainEngineVersion}
                itemProperty={(item: Contract.AwsEsDomainModel) => (item.entity as Contract.AwsEsDomain).engineVersion}
                key={Contract.EntityModelProperty.AwsEsDomainEngineVersion}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.engineVersion()}/>,
            commonResourceDefinition.columns.creatorIdentityCsvColumn,
            commonResourceDefinition.columns.creatorOriginatorEntityCsvColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsEsDomainModel) => ({
                            [localization.columns.storageSize()]: NumberFormatter.storage((item.entity as Contract.AwsEsDomain).storageSize)
                        })
                }}
                id={Contract.EntityModelProperty.AwsEsDomainStorageSize}
                itemProperty={(item: Contract.AwsResourceModel) => NumberFormatter.storage((item.entity as Contract.AwsEsDomain).storageSize)}
                key={Contract.EntityModelProperty.AwsEsDomainStorageSize}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.storageSize()}/>,
            commonNetworkedResourceDefinition.columns.getVpcsColumn(),
            commonNetworkedResourceDefinition.columns.securityGroupsColumn,
            commonEncryptedResourceDefinition.columns.encryptionExistsColumn,
            commonEncryptedResourceDefinition.columns.kmsEncryptionKeyColumn,
            commonCloudWatchLoggingResourceDefinition.columns.cloudWatchLoggingEnabledColumn,
            commonCloudWatchLoggingResourceDefinition.columns.cloudWatchLogTypesColumn,
            commonResourceDefinition.columns.regionColumn,
            commonResourceDefinition.columns.regionLocationColumn,
            commonResourceDefinition.columns.tagsColumn,
            commonResourceDefinition.columns.attributesColumn,
            commonResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAwsEsDomainRequest(
                new Contract.EntityControllerGetEntityModelPageAwsEsDomainRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEsDomainEngineType]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEsDomainEngineVersion])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags])),
                new Contract.EntityControllerGetEntityModelPageAwsResourceRequestAccess(ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsResourceAccessLevel])),
                new Contract.EntityControllerGetEntityModelPageAwsResourceRequestCloudWatchLogging(
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsCloudWatchResourceCloudWatchLoggingEnabled]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsCloudWatchResourceCloudWatchLogTypes])),
                new Contract.EntityControllerGetEntityModelPageAwsResourceRequestEncrypted(
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEncryptedResourceEncryptionExists]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEncryptedResourceKmsEncryptionKeys])),
                new Contract.EntityControllerGetEntityModelPageAwsResourceRequestNetworked(
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsNetworkedResourceSecurityGroups]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsNetworkedResourceVpcs]))));
}