import { Link, useLocalization } from "@infrastructure";
import React from "react";
import { useGetAzureContainerServiceManagedClusterRiskContext } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { EntityExternalConsoleLink } from "../../components";

export function useAzureContainerServiceManagedClusterIdentityAuthenticationDisabledRiskDefinition(riskModel: Contract.RiskModel) {
    const clusterIdentityAuthenticationDisabledRiskModel = riskModel as Contract.AzureContainerServiceManagedClusterIdentityAuthenticationDisabledRiskModel;
    const clusterModel = entityModelStore.useGet(clusterIdentityAuthenticationDisabledRiskModel.risk.entityId) as Contract.AzureContainerServiceManagedClusterModel;

    const getAzureContainerServiceManagedClusterRiskContext = useGetAzureContainerServiceManagedClusterRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.compliance.useAzureContainerServiceManagedClusterIdentityAuthenticationDisabledRiskDefinition",
            () => ({
                description: "{{cluster}} Microsoft Entra ID authentication is not enabled",
                sections: {
                    resolution: {
                        step1: "Before changing the configuration, make sure to update existing identities accessing the cluster",
                        step2: {
                            link: "AKS-managed Microsoft Entra ID integration",
                            text: "Under **Authentication and authorization**, select an option with **Microsoft Entra ID authentication**, recommended with Azure RBAC. For more information refer to the Azure documentation on {{documentationLink}}"
                        },
                        step3: "Click **Apply** to confirm"
                    }
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            cluster:
                <Entity
                    entityIdOrModel={clusterModel}
                    entityTypeNameTranslatorOptions={{ includeServiceName: true }}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={clusterIdentityAuthenticationDisabledRiskModel.risk.entityId}
                key={clusterIdentityAuthenticationDisabledRiskModel.risk.entityId}
                page={Contract.AzureConsoleEntityPage.ClusterConfiguration}/>,
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2.text({
                documentationLink:
                    <Link
                        urlOrGetUrl={clusterIdentityAuthenticationDisabledRiskModel.manageAadDocumentationUrl}
                        variant="external">
                        {localization.sections.resolution.step2.link()}
                    </Link>
            }),
            localization.sections.resolution.step3()
        ],
        clusterIdentityAuthenticationDisabledRiskModel,
        () => {
            const clusterRiskContext = getAzureContainerServiceManagedClusterRiskContext(clusterModel);
            return [
                clusterRiskContext.generalInformation,
                clusterRiskContext.sensitive,
                clusterRiskContext.agentPoolIdsAndComputeResources,
                clusterRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.risk.id)
            ];
        });
}