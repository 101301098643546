﻿import { useMemo } from "react";
import { Contract } from "../../../../../../../../../../../../../../../../../../../../common";
import { useDefaultDefinition } from "../useDefaultDefinition";
import { useAwsEcsTaskDefinitionEnvironmentVariableSecretExistsRiskDefinition, useAwsSecretsManagerSecretRotationDisabledRiskDefinition } from "./hooks";

export function useSecretDefinition(riskPolicyConfigurationTypeName: string) {
    const useDefinition =
        useMemo(
            () => {
                switch (riskPolicyConfigurationTypeName) {
                    case Contract.TypeNames.AwsEcsTaskDefinitionEnvironmentVariableSecretExistsRiskPolicyConfiguration:
                        return useAwsEcsTaskDefinitionEnvironmentVariableSecretExistsRiskDefinition;
                    case Contract.TypeNames.AwsSecretsManagerSecretRotationDisabledRiskPolicyConfiguration:
                        return useAwsSecretsManagerSecretRotationDisabledRiskDefinition;
                    default:
                        return useDefaultDefinition;
                }
            },
            []);

    return useDefinition(riskPolicyConfigurationTypeName);
}