import { Dialog, makeContextProvider } from "@infrastructure";
import React from "react";
import { Contract } from "../../../../../../../common";
import { AddOrEdit, Table } from "./components";

class CustomEntityAttributesContext {
    constructor(
        public addOrEditOpen: boolean,
        public addOrEditReadonly: boolean,
        public item?: CustomEntityAttributeItem) {
    }
}

export const [useCustomEntityAttributesContext, useSetCustomEntityAttributesContext, useCustomEntityAttributesContextProvider] = makeContextProvider<CustomEntityAttributesContext>();

export function CustomEntityAttributeDefinitions() {
    const [context, setContext, ContextProvider] = useCustomEntityAttributesContextProvider(() => new CustomEntityAttributesContext(false, false));

    return (
        <ContextProvider>
            {context.addOrEditOpen &&
                <Dialog
                    variant="editor"
                    onClose={
                        () =>
                            setContext(
                                context => ({
                                    ...context,
                                    addOrEditOpen: false,
                                    item: undefined
                                })
                            )}>
                    <AddOrEdit/>
                </Dialog>}
            <Table/>
        </ContextProvider>);
}

export class CustomEntityAttributeItem {
    constructor(
        public configuration: Contract.AutomaticCustomEntityAttributeDefinitionTemplateConfiguration | Contract.ManualCustomEntityAttributeDefinitionConfiguration,
        public displayName: string,
        public type: CustomEntityAttributeItemType) {
    }
}

export enum CustomEntityAttributeItemType {
    Automatic = "Automatic",
    Manual = "Manual"
}