import { InfoBox, InlineItems, Link, NotValidIcon, Steps, SuccessIcon, useLocalization, useOrderedWizardContext } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { CustomerConsoleAppUrlHelper, scopeSystemEntityModelStore, tenantModelStore, useTheme } from "../../../../../../../../../../../../../../../common";
import { useAddOrEditContext } from "../AddOrEdit";

export function FinishItem() {
    const { aadTenantModel, name, organizationMemberTenantNames, servicePrincipalAssignedRoleDefinitionNames, servicePrincipalMissingAssignedRoleDefinitionNames } = useAddOrEditContext();
    const { useNextEffect } = useOrderedWizardContext();

    useNextEffect(
        async () => {
            await tenantModelStore.notify(aadTenantModel!.configuration.id);
            await scopeSystemEntityModelStore.notify();
            return undefined;
        },
        []);

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCloudProviderTenantOrganizationItems.azure.addOrEdit.finishItem",
            () => ({
                organizationMemberTenantNames: {
                    namePluralizer: [
                        "subscription",
                        "{{count | NumberFormatter.humanize}} subscriptions"
                    ],
                    title: "Detected {{organizationMemberTenantNames}}"
                },
                servicePrincipalAssignedRoleDefinitionNames: "Identified the following permissions:",
                servicePrincipalMissingAssignedRoleDefinitionNames: {
                    info: "The following role assignments are missing on the root management group:",
                    subtitle: {
                        all: "Please note that monitoring permissions were not assigned to the root management group, other subscriptions will not be added until access is granted.",
                        some: "Some of the permissions you chose are still missing. You can add them after completing onboarding."
                    }
                },
                subtitle: {
                    documentationLink: "learn more",
                    text: "We're synchronizing your data now, and you'll be able to see it soon. {{documentationLink}}"
                },
                title: "Organization {{name}} configured successfully"
            }));

    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            justifyContent="center"
            spacing={2}
            sx={{ height: "100%" }}>
            <SuccessIcon
                sx={{
                    color: theme.palette.success.main,
                    fontSize: "60px"
                }}/>
            <Typography
                align="center"
                sx={{ fontWeight: 400 }}
                variant="h2">
                {localization.title({ name })}
            </Typography>
            <Typography
                align="center"
                variant="h4">
                {localization.organizationMemberTenantNames.title({
                    organizationMemberTenantNames:
                        <InlineItems
                            items={_.orderBy(organizationMemberTenantNames)}
                            namePluralizer={localization.organizationMemberTenantNames.namePluralizer}
                            variant="itemCountAndType"/>
                })}
            </Typography>
            <Typography
                align="center"
                variant="h5">
                {localization.subtitle.text({
                    documentationLink:
                        <Link
                            urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsArchitectureAndDataHandlingHowOftenIsDataSyncedRelativeUrl()}
                            variant="external">
                            {localization.subtitle.documentationLink()}
                        </Link>
                })}
            </Typography>
            {!_.isEmpty(servicePrincipalMissingAssignedRoleDefinitionNames) &&
                <Typography
                    align="center"
                    sx={{ fontWeight: 600 }}>
                    {_.isEmpty(servicePrincipalAssignedRoleDefinitionNames)
                        ? localization.servicePrincipalMissingAssignedRoleDefinitionNames.subtitle.all()
                        : localization.servicePrincipalMissingAssignedRoleDefinitionNames.subtitle.some()}
                </Typography>}
            {!_.isEmpty(servicePrincipalAssignedRoleDefinitionNames) &&
                <InfoBox
                    iconElement={
                        <SuccessIcon
                            sx={{
                                color: theme.palette.success.main,
                                fontSize: "18px"
                            }}/>}
                    title={localization.servicePrincipalAssignedRoleDefinitionNames()}>
                    <Steps>
                        {servicePrincipalAssignedRoleDefinitionNames}
                    </Steps>
                </InfoBox>}
            {!_.isEmpty(servicePrincipalMissingAssignedRoleDefinitionNames) &&
                <InfoBox
                    iconElement={
                        <NotValidIcon
                            sx={{
                                color: theme.palette.warning.main,
                                fontSize: "18px"
                            }}/>}
                    title={localization.servicePrincipalMissingAssignedRoleDefinitionNames.info()}>
                    <Steps>
                        {servicePrincipalMissingAssignedRoleDefinitionNames}
                    </Steps>
                </InfoBox>}
        </Stack>);
}