import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, EnumValuesFilter, NumberFormatter, optionalTableCell, PagedValuesFilter, useLocalization } from "@infrastructure";
import { Dictionary } from "lodash";
import React from "react";
import { useGcpCommonEncryptedResourceDefinition, useGcpCommonScopeResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { useGcpComputeImageStatusTranslator, useGcpComputeImageTypeTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";

export function useGcpComputeImageDefinition(definitionData: DefinitionData) {
    const commonEncryptedResourceDefinition = useGcpCommonEncryptedResourceDefinition(definitionData);
    const commonScopeResourceDefinition = useGcpCommonScopeResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const computeImageStatusTranslator = useGcpComputeImageStatusTranslator();
    const computeImageTypeTranslator = useGcpComputeImageTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.gcp.useGcpComputeImageDefinition",
            () => ({
                columns: {
                    familyTag: "Family",
                    instanceIdReferences: "VM Instances",
                    size: "Disk Size",
                    sourceIdReference: "Source",
                    status: "Status",
                    type: "Type"
                }
            }));

    return new EntityTableDefinition(
        [
            commonScopeResourceDefinition.columns.tenantColumn,
            commonScopeResourceDefinition.columns.creationTimeColumn,
            commonScopeResourceDefinition.columns.creatorIdentityCsvColumn,
            commonScopeResourceDefinition.columns.creatorOriginatorEntityCsvColumn,
            commonScopeResourceDefinition.columns.accessLevelColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.GcpComputeImageModel) => ({
                            [localization.columns.status()]: computeImageStatusTranslator((item.entity as Contract.GcpComputeImage).status)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.GcpComputeImageStatus}
                                enumTypeTranslator={computeImageStatusTranslator}
                                placeholder={localization.columns.status()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpComputeImageStatus}
                itemProperty={(item: Contract.EntityModel) => computeImageStatusTranslator((item.entity as Contract.GcpComputeImage).status)}
                key={Contract.EntityModelProperty.GcpComputeImageStatus}
                title={localization.columns.status()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.GcpComputeImageModel) => ({
                            [localization.columns.type()]: computeImageTypeTranslator((item.entity as Contract.GcpComputeImage).type)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.GcpComputeImageType}
                                enumTypeTranslator={computeImageTypeTranslator}
                                placeholder={localization.columns.type()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpComputeImageType}
                itemProperty={(item: Contract.EntityModel) => computeImageTypeTranslator((item.entity as Contract.GcpComputeImage).type)}
                key={Contract.EntityModelProperty.GcpComputeImageType}
                title={localization.columns.type()}/>,
            <DataTableColumn
                exportOptions={entitiesExportOptions<Contract.GcpComputeImageModel>(
                    Contract.TypeNames.GcpEntity,
                    item => [item.sourceIdReference],
                    localization.columns.sourceIdReference())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.GcpComputeImageSource)}
                                placeholder={localization.columns.sourceIdReference()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpComputeImageSource}
                key={Contract.EntityModelProperty.GcpComputeImageSource}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.GcpComputeImageModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.sourceIdReference}
                            entityTypeName={Contract.TypeNames.GcpEntity}
                            entityVariant="iconText"/>}
                title={localization.columns.sourceIdReference()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.GcpComputeImageModel) => ({
                            [localization.columns.size()]: NumberFormatter.storage((item.entity as Contract.GcpComputeImage).size)
                        })
                }}
                id={Contract.EntityModelProperty.GcpComputeImageSize}
                itemProperty={(item: Contract.GcpComputeImageModel) => NumberFormatter.storage((item.entity as Contract.GcpComputeImage).size)}
                key={Contract.EntityModelProperty.GcpComputeImageSize}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.size()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.GcpComputeImageModel>(
                        Contract.TypeNames.GcpComputeInstance,
                        item => item.instanceIdReferences,
                        localization.columns.instanceIdReferences())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.GcpComputeImageInstances)}
                                placeholder={localization.columns.instanceIdReferences()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpComputeImageInstances}
                key={Contract.EntityModelProperty.GcpComputeImageInstances}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.GcpComputeImageModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.instanceIdReferences}
                            entityTypeName={Contract.TypeNames.GcpComputeInstance}
                            entityVariant="iconText"/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.instanceIdReferences()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.GcpComputeImageModel) => ({
                            [localization.columns.familyTag()]: (item.entity as Contract.GcpComputeImage).familyTag ?? ""
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedValuesFilter
                                getValuePage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.GcpComputeImageFamilyTag)}
                                placeholder={localization.columns.familyTag()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpComputeImageFamilyTag}
                key={Contract.EntityModelProperty.GcpComputeImageFamilyTag}
                render={optionalTableCell<Contract.GcpComputeImageModel>(item => (item.entity as Contract.GcpComputeImage).familyTag)}
                title={localization.columns.familyTag()}/>,
            commonEncryptedResourceDefinition.columns.encryptionExistsColumn,
            commonEncryptedResourceDefinition.columns.encryptionKeyColumn,
            commonScopeResourceDefinition.columns.regionColumn,
            commonScopeResourceDefinition.columns.regionLocationColumn,
            commonScopeResourceDefinition.columns.tagsColumn,
            commonScopeResourceDefinition.columns.attributesColumn,
            commonScopeResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonScopeResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonScopeResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageGcpComputeImageRequest(
                new Contract.EntityControllerGetEntityModelPageGcpComputeImageRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.GcpResourceUpdateTime]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpScopeResourceAccessLevel]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpScopeResourceRawId]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpComputeImageFamilyTag]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpComputeImageInstances]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpComputeImageSource]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpComputeImageStatus]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpComputeImageType])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags])),
                new Contract.EntityControllerGetEntityModelPageGcpScopeResourceRequestEncrypted(
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpEncryptedResourceEncryptionExists]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpEncryptedResourceKmsEncryptionKeys]))));
}