import { GuidParser, InfoIcon, Link, Optional, PasswordTextField, Tooltip, useInputValidation, useLocalization } from "@infrastructure";
import { FormControl, FormHelperText, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useTheme } from "../../../../../../../../../../../../../../../common";
import { useAadTenantsContext } from "../../../AadTenants";

type TenantApplicationDataProps = {
    data: TenantApplicationData;
    onDataChanged: (data: TenantApplicationData) => void;
    onValidChanged: (valid: boolean) => void;
};

type TenantApplicationData = {
    applicationRawId?: string;
    applicationSecret?: string;
    tenantRawId?: string;
};

export function TenantApplicationData({ data, onDataChanged, onValidChanged }: TenantApplicationDataProps) {
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useIdentityProviderTenantItems.aadTenants.addOrEdit.tenantApplicationData",
            () => ({
                fields: {
                    applicationId: {
                        error: {
                            format: "Application ID must be a valid GUID",
                            required: "Application ID cannot be empty"
                        },
                        title: "Application (client) ID",
                        tooltip: "In Azure portal: **App registration > Overview**"
                    },
                    applicationSecret: {
                        error: "Application secret cannot be empty",
                        title: "Secret Value",
                        tooltip: "When the secret expires, create a new one, then edit and update the value in Tenable"
                    },
                    tenantId: {
                        error: {
                            format: "Tenant ID must be a valid GUID",
                            required: "Tenant ID cannot be empty"
                        },
                        title: "Tenant Directory ID",
                        tooltip: "In Azure portal: **Settings > Directories + subscriptions**"
                    }
                },
                info: {
                    docsLink: "Learn more",
                    title: "Create a dedicated app registration in Microsoft Entra ID (using a secret for authentication) and assign it the necessary permissions. {{docsLink}}."
                }
            }));

    const [applicationRawId, setApplicationRawId] = useState(data.applicationRawId);
    const [applicationRawIdValidationController, applicationRawIdValidationMessage] =
        useInputValidation(
            () => {
                const validationApplicationRawId = _.trim(applicationRawId);
                if (_.isEmpty(validationApplicationRawId)) {
                    return localization.fields.applicationId.error.required();
                }

                if (!GuidParser.validate(validationApplicationRawId)) {
                    return localization.fields.applicationId.error.format();
                }

                return undefined;
            },
            [applicationRawId]);

    const [applicationSecret, setApplicationSecret] = useState<Optional<string>>("");
    const [applicationSecretValidationController, applicationSecretValidationMessage] =
        useInputValidation(
            () => {
                const validationApplicationSecret = _.trim(applicationSecret);
                if (_.isEmpty(validationApplicationSecret)) {
                    return localization.fields.applicationSecret.error();
                }

                return undefined;
            },
            [applicationSecret]);

    const [tenantRawId, setTenantRawId] = useState(data.tenantRawId);
    const [tenantIdValidationController, tenantIdValidationMessage] =
        useInputValidation(
            () => {
                const validationTenantRawId = _.trim(tenantRawId);
                if (_.isEmpty(validationTenantRawId)) {
                    return localization.fields.tenantId.error.required();
                }

                if (!GuidParser.validate(validationTenantRawId)) {
                    return localization.fields.tenantId.error.format();
                }

                return undefined;
            },
            [tenantRawId]);

    useEffect(
        () => {
            onDataChanged({
                applicationRawId,
                applicationSecret,
                tenantRawId
            });
            onValidChanged(
                applicationRawIdValidationController.isValid() &&
                applicationSecretValidationController.isValid() &&
                tenantIdValidationController.isValid());
        },
        [applicationRawId, applicationSecret, tenantRawId]);

    const theme = useTheme();
    const aadTenantsContext = useAadTenantsContext();
    return (
        <Stack
            spacing={3}
            sx={{ maxWidth: theme.spacing(60) }}>
            <FormControl
                fullWidth={true}
                variant="standard">
                <TextField
                    disabled={!_.isBoolean(aadTenantsContext.addOrEditOpen)}
                    label={localization.fields.tenantId.title()}
                    slotProps={{
                        input: {
                            endAdornment:
                                <InputAdornment position="end">
                                    <Tooltip titleOrGetTitle={localization.fields.tenantId.tooltip()}>
                                        <InfoIcon
                                            sx={{
                                                color: theme.palette.text.secondary,
                                                fontSize: "18px"
                                            }}/>
                                    </Tooltip>
                                </InputAdornment>
                        }
                    }}
                    value={tenantRawId}
                    variant="outlined"
                    onChange={event => setTenantRawId(event.target.value)}/>
                {!_.isNil(tenantIdValidationController) &&
                    <FormHelperText error={true}>{tenantIdValidationMessage}</FormHelperText>}
            </FormControl>
            <Typography>
                {localization.info.title({
                    docsLink:
                        <Link
                            urlOrGetUrl="https://docs.ermetic.com/docs/add-an-azure-active-directory-tenant#add-an-azure-china-tenant"
                            variant="external">
                            {localization.info.docsLink()}
                        </Link>
                })}
            </Typography>
            <FormControl
                fullWidth={true}
                variant="standard">
                <TextField
                    label={localization.fields.applicationId.title()}
                    slotProps={{
                        input: {
                            endAdornment:
                                <InputAdornment position="end">
                                    <Tooltip titleOrGetTitle={localization.fields.applicationId.tooltip()}>
                                        <InfoIcon
                                            sx={{
                                                color: theme.palette.text.secondary,
                                                fontSize: "18px"
                                            }}/>
                                    </Tooltip>
                                </InputAdornment>
                        }
                    }}
                    value={applicationRawId}
                    variant="outlined"
                    onChange={event => setApplicationRawId(event.target.value)}/>
                {!_.isNil(applicationRawIdValidationController) &&
                    <FormHelperText error={true}>{applicationRawIdValidationMessage}</FormHelperText>}
            </FormControl>
            <FormControl
                fullWidth={true}
                variant="standard">
                <PasswordTextField
                    label={localization.fields.applicationSecret.title()}
                    password={applicationSecret}
                    slotProps={{
                        input: {
                            endAdornment:
                            <InputAdornment position="end">
                                <Tooltip titleOrGetTitle={localization.fields.applicationSecret.tooltip()}>
                                    <InfoIcon
                                        sx={{
                                            color: theme.palette.text.secondary,
                                            fontSize: "18px"
                                        }}/>
                                </Tooltip>
                            </InputAdornment>
                        }
                    }}
                    variant="outlined"
                    onPasswordChanged={applicationSecret => setApplicationSecret(applicationSecret)}/>
                {!_.isNil(applicationSecretValidationController) &&
                    <FormHelperText error={true}>{applicationSecretValidationMessage}</FormHelperText>}
            </FormControl>
        </Stack>);
}