import { InlineItems, Link, Step, Steps, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { useCommonSectionsAndDescriptionDefinition, useGetAwsLambdaFunctionConfigurationRiskContext } from "../../../..";
import { Contract, CustomerConsoleAppUrlHelper, Entity, entityModelStore, InlineEntities, InlinePermissionActions, RiskTypeMetadataModelHelper, useEntityTypeNameTranslator, useProjectScopeSelected } from "../../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, AwsLambdaFunctionConfigurationHelper, useAwsApiGatewayApiRouteMethodTranslator, useAwsConsoleSignInStepTranslator, useAwsLambdaFunctionConfigurationAccessTypeTranslator } from "../../../../../../../../../../../../../tenants";
import { RiskDefinitionContextItem, RiskDefinitionSection } from "../../../../../../../utilities";
import { AliasPolicySection, RevisionPolicySection } from "./components";
import { ApiGatewaySection } from "./components/ApiGatewaySection";

export function useAwsLambdaFunctionConfigurationPublicAccessExistsRiskDefinition(riskModel: Contract.RiskModel) {
    const functionConfigurationPublicAccessExistsRiskModel = riskModel as Contract.AwsLambdaFunctionConfigurationPublicAccessExistsRiskModel;
    const risk = functionConfigurationPublicAccessExistsRiskModel.risk as Contract.AwsLambdaFunctionConfigurationPublicAccessExistsRisk;
    const functionConfigurationModel = entityModelStore.useGet(risk.entityId) as Contract.AwsLambdaFunctionConfigurationModel;
    const functionConfiguration = functionConfigurationModel.entity as Contract.AwsLambdaFunctionConfiguration;
    const getAwsLambdaFunctionConfigurationRiskContext = useGetAwsLambdaFunctionConfigurationRiskContext();
    const projectScopeSelected = useProjectScopeSelected();

    const policyDocumentPublicAccessAliasMap =
        useMemo(
            () =>
                _(functionConfiguration.aliasNameToPolicyDocumentPublicAccessPermissionActionsMap).
                    keys().
                    filter(aliasName => !_.isNil(functionConfiguration.aliasMap[aliasName]?.policyDocument)).
                    keyBy().
                    mapValues(aliasName => functionConfiguration.aliasMap[aliasName]).
                    value(),
            [riskModel]);

    const policyDocumentPublicAccessRevisionMap =
        useMemo(
            () =>
                _(functionConfiguration.revisionIdToPolicyDocumentPublicAccessPermissionActionsMap).
                    keys().
                    filter(revisionId => !_.isNil(functionConfiguration.revisionMap[revisionId]?.policyDocument)).
                    keyBy().
                    mapValues(revisionId => functionConfiguration.revisionMap[revisionId]).
                    value(),
            [riskModel]);

    const apiGatewayApiRouteMethodTranslator = useAwsApiGatewayApiRouteMethodTranslator();
    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const lambdaFunctionConfigurationAccessTypeTranslator = useAwsLambdaFunctionConfigurationAccessTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsLambdaFunctionConfigurationPublicAccessExistsRiskDefinition",
            () => ({
                contextItems: {
                    apiPublicInvocation: "The {{translatedFunctionConfigurationTypeName}} allows anonymous invocation through {{apiGateways}}",
                    excludedRoutes: [
                        "{{excludedRoutes}} is being excluded. Manage exclusions by editing the {{policyConfigurationLink}}",
                        "{{excludedRoutes}} are being excluded. Manage exclusions by editing the {{policyConfigurationLink}}"
                    ],
                    highSeverityRolePermissionActions: [
                        "1 high severity permission",
                        "{{count | NumberFormatter.humanize}} high severity permissions"
                    ],
                    highSeverityRolePermissionActionsExists: "The {{translatedFunctionConfigurationTypeName}} has {{highSeverityPermissionActions}}",
                    riskPolicyConfigurationLink: "policy configuration",
                    routes: [
                        "1 API Gateway path",
                        "{{count | NumberFormatter.humanize}} API Gateway paths"
                    ]
                },
                description: "{{functionConfiguration}} is publicly accessible via {{publicAccessTypes}}",
                publicAccessTypes: [
                    "1 method",
                    "{{count | NumberFormatter.humanize}} methods"
                ],
                sections: {
                    aliases: "Aliases",
                    apis: "API Gateway",
                    resolution: {
                        aliasStep: "For each alias containing public statements click on the **Aliases** tab and click on the specific alias",
                        apiGatewayApiStep: {
                            step1: {
                                link: "AWS documentation",
                                text: "An API Gateway is considered public when any of its methods don't require authorization (for example: API keys, IAM permissions, or custom authorization).\nTo disable public access, modify the API Gateway path to require authorization. See {{apiGateWayAuthorizationDocumentationUrl}} for more information on how to control and manage access to your API gateway"
                            },
                            title: "Disable public access via {{apiGateways}}"
                        },
                        clickPermissionStep: "Click on **Permissions** inside the **Configuration** tab",
                        editPolicyStatementStep: "Under **Resource-based policy statements** for each public statement click on **Edit** and change it to allow access by specific identities",
                        introStep: "Disabling public access might break identities using the lambda. Before changing the policy, verify that identities using the lambda can access it without public access",
                        revisionStep: "For each version containing public statements click on the **Versions** tab   and click on the specific version",
                        title: "Disable public access via {{publicAccessTypes}}"
                    },
                    revisions: "Revisions"
                }
            }));

    const translatedFunctionConfigurationTypeName =
        entityTypeNameTranslator(
            functionConfiguration.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });

    const excludedRoutePathToMethodsDescriptions =
        _(risk.publicAccessTriggerApiIdToExcludedRoutesMap).
            values().
            flatten().
            filter(excludedRoute => !_.isNil(excludedRoute.method)).
            groupBy(excludedRoute => excludedRoute.path).
            map((routes, path) => {
                const methods = _.map(routes, route => apiGatewayApiRouteMethodTranslator(route.method!));
                return `${path} \(${_.join(methods, ",")}\)`;
            }).
            value();


    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            functionConfiguration:
                <Entity
                    entityIdOrModel={functionConfigurationModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>,
            publicAccessTypes:
                <InlineItems
                    items={
                        _(risk.publicAccessTypes).
                            map(lambdaFunctionConfigurationAccessTypeTranslator).
                            sort().
                            value()}
                    namePluralizer={localization.publicAccessTypes}
                    variant="itemOrItemCountAndType"/>
        }),
        () =>
            _<Step>([]).
                concatIf(
                    _.includes(
                        risk.publicAccessTypes,
                        Contract.AwsLambdaFunctionConfigurationModelAccessType.FunctionUrl) ||
                    _.includes(
                        risk.publicAccessTypes,
                        Contract.AwsLambdaFunctionConfigurationModelAccessType.PolicyDocument),
                    new Step(
                        localization.sections.resolution.title({
                            publicAccessTypes:
                                <InlineItems
                                    items={
                                        _(risk.publicAccessTypes).
                                            without(Contract.AwsLambdaFunctionConfigurationModelAccessType.ApiGatewayApi).
                                            map(lambdaFunctionConfigurationAccessTypeTranslator).
                                            sort().
                                            value()}
                                    namePluralizer={localization.publicAccessTypes}
                                    variant="itemOrItemCountAndType"/>
                        }),
                        {
                            contentElement:
                                <Steps variant="plainNumbers">
                                    {
                                        _<string>([]).
                                            concat(
                                                consoleSignInStepTranslator(
                                                    Contract.AwsConsoleView.Lambda,
                                                    AwsConsoleUrlBuilder.getLambdaFunctionConfigurationUrl(functionConfiguration))).
                                            concat(localization.sections.resolution.introStep()).
                                            concatIf(
                                                !_.isEmpty(risk.publicAccessTriggerApiIdToNotExcludedRouteIdentifiersMap) ||
                                                functionConfiguration.revisionMap[AwsLambdaFunctionConfigurationHelper.latest]?.accessLevel === Contract.AwsResourceAccessLevel.Public ||
                                                !_.isNil(functionConfiguration.url),
                                                localization.sections.resolution.clickPermissionStep(),
                                                localization.sections.resolution.editPolicyStatementStep()).
                                            concatIf(
                                                _.some(
                                                    functionConfiguration.revisionIdToPolicyDocumentPublicAccessPermissionActionsMap,
                                                    (_, revisionId) => revisionId !== AwsLambdaFunctionConfigurationHelper.latest),
                                                localization.sections.resolution.revisionStep(),
                                                localization.sections.resolution.clickPermissionStep(),
                                                localization.sections.resolution.editPolicyStatementStep()).
                                            concatIf(
                                                !_.isEmpty(functionConfiguration.aliasNameToPolicyDocumentPublicAccessPermissionActionsMap),
                                                localization.sections.resolution.aliasStep(),
                                                localization.sections.resolution.clickPermissionStep(),
                                                localization.sections.resolution.editPolicyStatementStep()).
                                            value()
                                    }
                                </Steps>
                        }
                    )).
                concatIf(
                    _.includes(
                        risk.publicAccessTypes,
                        Contract.AwsLambdaFunctionConfigurationModelAccessType.ApiGatewayApi),
                    new Step(
                        localization.sections.resolution.apiGatewayApiStep.title({
                            apiGateways:
                                <InlineEntities
                                    entityIdsOrModels={_.keys(risk.publicAccessTriggerApiIdToNotExcludedRouteIdentifiersMap)}
                                    entityTypeName={Contract.TypeNames.AwsApiGatewayApi}
                                    variant="itemCountAndType"/>
                        }),
                        {
                            contentElement:
                                localization.sections.resolution.apiGatewayApiStep.step1.text({
                                    apiGateWayAuthorizationDocumentationUrl:
                                        <Link
                                            urlOrGetUrl={functionConfigurationPublicAccessExistsRiskModel.apiGateWayAuthorizationDocumentationUrl}
                                            variant="external">
                                            {localization.sections.resolution.apiGatewayApiStep.step1.link()}
                                        </Link>
                                })
                        })).
                value(),
        riskModel,
        () => {
            const lambdaFunctionConfigurationContextItems = getAwsLambdaFunctionConfigurationRiskContext(functionConfigurationModel);
            return [
                lambdaFunctionConfigurationContextItems.generalInformation,
                lambdaFunctionConfigurationContextItems.sensitive,
                lambdaFunctionConfigurationContextItems.aliasFunctionUrl,
                lambdaFunctionConfigurationContextItems.aliasResourcePolicyPermission,
                !_.isEmpty(risk.publicAccessTriggerApiIdToNotExcludedRouteIdentifiersMap)
                    ? new RiskDefinitionContextItem(
                        localization.contextItems.apiPublicInvocation({
                            apiGateways:
                                <InlineEntities
                                    entityIdsOrModels={_.keys(risk.publicAccessTriggerApiIdToNotExcludedRouteIdentifiersMap)}
                                    entityTypeName={Contract.TypeNames.AwsApiGatewayApi}
                                    variant="itemCountAndType"/>,
                            translatedFunctionConfigurationTypeName
                        }))
                    : undefined,
                !_.isEmpty(risk.publicAccessTriggerApiIdToExcludedRoutesMap)
                    ? new RiskDefinitionContextItem(
                        localization.contextItems.excludedRoutes(
                            excludedRoutePathToMethodsDescriptions.length,
                            {
                                excludedRoutes:
                                    <InlineItems
                                        items={excludedRoutePathToMethodsDescriptions}
                                        namePluralizer={localization.contextItems.routes}
                                        variant="itemCountAndType"/>,
                                policyConfigurationLink:
                                    <Link
                                        urlOrGetUrl={
                                            projectScopeSelected
                                                ? undefined
                                                : CustomerConsoleAppUrlHelper.getCloudRiskPoliciesProfileHashUrl(
                                                    RiskTypeMetadataModelHelper.get(risk.typeName).policyConfigurationTypeName,
                                                    risk.tenantId)}>
                                        {localization.contextItems.riskPolicyConfigurationLink()}
                                    </Link>
                            }))
                    : undefined,
                lambdaFunctionConfigurationContextItems.functionUrl,
                lambdaFunctionConfigurationContextItems.publicAccessPermissionActions,
                lambdaFunctionConfigurationContextItems.revisionsPrincipalPermission,
                !_.isEmpty(risk.highSeverityRolePermissionActions)
                    ? new RiskDefinitionContextItem(
                        localization.contextItems.highSeverityRolePermissionActionsExists({
                            highSeverityPermissionActions:
                                <InlinePermissionActions
                                    namePluralizer={localization.contextItems.highSeverityRolePermissionActions}
                                    permissionActions={risk.highSeverityRolePermissionActions}
                                    variant="itemCountAndType"/>,
                            translatedFunctionConfigurationTypeName
                        }))
                    : undefined,
                lambdaFunctionConfigurationContextItems.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        },
        {
            sections:
                _<RiskDefinitionSection>([]).
                    concatIf(
                        !_.isEmpty(risk.publicAccessTriggerApiIdToNotExcludedRouteIdentifiersMap),
                        () =>
                            new RiskDefinitionSection(
                                <ApiGatewaySection
                                    publicAccessTriggerApiIdToNotExcludedRouteIdentifiersMap={risk.publicAccessTriggerApiIdToNotExcludedRouteIdentifiersMap}
                                    riskSeverity={risk.severity}/>,
                                localization.sections.apis(),
                                { expandable: true })).
                    concatIf(
                        !_.isEmpty(policyDocumentPublicAccessAliasMap),
                        () =>
                            new RiskDefinitionSection(
                                <AliasPolicySection aliasMap={policyDocumentPublicAccessAliasMap}/>,
                                localization.sections.aliases(),
                                { expandable: true })).
                    concatIf(
                        !_.isEmpty(policyDocumentPublicAccessRevisionMap),
                        () =>
                            new RiskDefinitionSection(
                                <RevisionPolicySection revisionMap={policyDocumentPublicAccessRevisionMap}/>,
                                localization.sections.revisions(),
                                { expandable: true })).
                    value()
        });
}