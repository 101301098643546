import { DataTable, DataTableColumn, DataTableColumnRenderProps, EmptyMessageText, Optional, useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import React from "react";
import { AwsEc2NetworkRulePortRangeCell, AwsEc2NetworkRuleTypeCell } from ".";
import { Contract, NetworkScopeFormatter, useTheme } from "../../../common";

type AwsEc2NetworkAclRulesTableProps = {
    getHighlightColor?: (rule: Contract.AwsEc2NetworkAclRule, opacity?: number) => Optional<string>;
    inbound: boolean;
    rules: Contract.AwsEc2NetworkAclRule[];
};

export function AwsEc2NetworkAclRulesTable({ getHighlightColor, inbound, rules }: AwsEc2NetworkAclRulesTableProps) {

    const localization =
        useLocalization(
            "tenants.aws.awsEc2NetworkAclRulesTable",
            () => ({
                columns: {
                    action: {
                        title: "Allow/Deny",
                        [Contract.TypeNames.AwsEc2NetworkAclRuleAction]: {
                            [Contract.AwsEc2NetworkAclRuleAction.Allow]: "Allow",
                            [Contract.AwsEc2NetworkAclRuleAction.Deny]: "Deny"
                        }
                    },
                    index: "Rule Number",
                    portRange: "Port Range",
                    protocol: "Protocol",
                    subnet: {
                        title: {
                            inbound: "Source",
                            outbound: "Destination"
                        }
                    },
                    type: "Type"
                },
                empty: "No Rules"
            }));

    const theme = useTheme();
    return (
        <DataTable
            emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty()) }}
            fetchItems={() => rules}
            getItemId={(rule: Contract.AwsEc2NetworkAclRule) => rule.index.toString()}
            rowOptions={{
                getHighlightColor: (item: Contract.AwsEc2NetworkAclRule) => getHighlightColor?.(item),
                getSx: (item: Contract.AwsEc2NetworkAclRule) => ({ backgroundColor: getHighlightColor?.(item, 0.1) })
            }}
            sortOptions={{ enabled: false }}
            variant="card">
            <DataTableColumn
                id={AwsEc2NetworkAclRulesTableColumnId.Index}
                itemProperty={
                    (rule: Contract.AwsEc2NetworkAclRule) =>
                        rule.index > 32766
                            ? "*"
                            : rule.index}
                title={localization.columns.index()}/>
            <DataTableColumn
                id={AwsEc2NetworkAclRulesTableColumnId.Type}
                render={AwsEc2NetworkRuleTypeCell}
                title={localization.columns.type()}/>
            <DataTableColumn
                id={AwsEc2NetworkAclRulesTableColumnId.Protocol}
                itemProperty={(rule: Contract.AwsEc2NetworkAclRule) => NetworkScopeFormatter.protocolRange(rule.protocolRange, Contract.TenantType.Aws)}
                title={localization.columns.protocol()}/>
            <DataTableColumn
                id={AwsEc2NetworkAclRulesTableColumnId.PortRange}
                render={AwsEc2NetworkRulePortRangeCell}
                title={localization.columns.portRange()}/>
            <DataTableColumn
                id={AwsEc2NetworkAclRulesTableColumnId.Subnet}
                itemProperty={(rule: Contract.AwsEc2NetworkAclRule) => rule.subnet}
                title={
                    inbound
                        ? localization.columns.subnet.title.inbound()
                        : localization.columns.subnet.title.outbound()}/>
            <DataTableColumn
                id={AwsEc2NetworkAclRulesTableColumnId.Action}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AwsEc2NetworkAclRule>) =>
                        <Typography
                            noWrap={true}
                            sx={{
                                color:
                                    item.action === Contract.AwsEc2NetworkAclRuleAction.Allow
                                        ? theme.palette.success.main
                                        : theme.palette.error.main
                            }}>
                            {localization.columns.action[Contract.TypeNames.AwsEc2NetworkAclRuleAction][item.action]()}
                        </Typography>}
                title={localization.columns.action.title()}/>
        </DataTable>);
}

enum AwsEc2NetworkAclRulesTableColumnId {
    Action = "action",
    Index = "index",
    PortRange = "portRange",
    Protocol = "protocol",
    Subnet = "subnet",
    Type = "type"
}