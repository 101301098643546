import { map, TimeFormatter, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, entityModelStore, InfoItem, TenantHelper, tenantModelStore, useTenantNameTranslator } from "../../../../../../../../../../common";
import { EntitiesInfoItem } from "../../../../components";
import { useCommonEntityInfoItemElements } from "../useCommonEntityInfoItemElements";

export function useGcpCommonEntityInfoItemElements(entityModel: Contract.GcpEntityModel) {
    const commonEntityInfoItemElements = useCommonEntityInfoItemElements(entityModel);
    const entity = entityModel.entity as Contract.GcpEntity;
    const tenantModel =
        tenantModelStore.useGet(
            TenantHelper.isCommonTenant(entity.tenantId)
                ? undefined
                : entity.tenantId);
    const tenantEntityModel = entityModelStore.useGet(entityModel.tenantId);

    const tenantNameTranslator = useTenantNameTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpCommonEntityInfoItemElements",
            () => ({
                creationTime: "Creation Time",
                gciTenantId: "Workspace",
                syncTime: "Sync Time",
                tenantId: "Project"
            }));

    const gciTenantId =
        map(
            tenantModel?.configuration.type,
            {
                [Contract.TenantType.Gci]: () => tenantModel!.configuration.id,
                [Contract.TenantType.Gcp]: () => (tenantModel!.configuration as Contract.GcpTenantConfiguration).gciTenantId
            },
            () => undefined);
    return {
        ...commonEntityInfoItemElements,
        creationTimeInfoItemElement:
            <InfoItem
                key="creationTime"
                location="all"
                title={localization.creationTime()}
                value={
                    _.isNil(entityModel.creationTime)
                        ? undefined
                        : TimeFormatter.profileFormatDateTime(entityModel.creationTime)}/>,
        gciTenantInfoItemElement:
            <InfoItem
                key="gciTenantId"
                title={localization.gciTenantId()}
                value={
                    _.isNil(gciTenantId)
                        ? undefined
                        : tenantNameTranslator(gciTenantId)}/>,
        syncTimeInfoItemElement:
            <InfoItem
                key="syncTime"
                title={localization.syncTime()}
                value={TimeFormatter.profileFormatDateTime(entity.syncTime)}/>,
        tenantInfoItemElement:
            tenantModel?.configuration.type === Contract.TenantType.Gci
                ? undefined
                : tenantEntityModel.unknown
                    ? <InfoItem
                        key="tenant"
                        title={localization.tenantId()}
                        value={tenantNameTranslator(entity.tenantId, { includeRawId: true })}/>
                    : <EntitiesInfoItem
                        entityIdsOrModels={tenantEntityModel}
                        entityTypeName={tenantEntityModel.entity.typeName}
                        key="tenant"
                        title={localization.tenantId()}/>
    };
}