﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, entityModelStore, InlineEntities, useEntityTypeNameTranslator } from "../../../../../../../../../../../../../../common";
import { useAwsResourceAccessLevelTranslator } from "../../../../../../../../../../../../../../tenants";
import { RiskDefinitionContextItem } from "../../../../../../../../utilities";

export function useGetAwsRdsSnapshotEncryptionDisabledRiskSourceResourceRiskContext() {
    return useMemo(
        () => useAwsRdsSnapshotEncryptionDisabledRiskSourceResourceRiskContext,
        []);
}

function useAwsRdsSnapshotEncryptionDisabledRiskSourceResourceRiskContext(riskModel: Contract.AwsRdsSnapshotEncryptionDisabledRiskModel) {
    const snapshotModels = entityModelStore.useGet(riskModel.risk.aggregatedEntityIds);
    const sourceResourceModel = entityModelStore.useGet(riskModel.risk.entityId);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const resourceAccessLevelTranslator = useAwsResourceAccessLevelTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsRdsSnapshotEncryptionDisabledRiskDefinition.hooks.useGetAwsRdsSnapshotEncryptionDisabledRiskSourceResourceRiskContext",
            () => ({
                networkAccess: {
                    all: "All the {{translatedSnapshotTypeName}} have **{{accessLevel}}** access",
                    some: "{{accessLevelSnapshots}} have **{{accessLevel}}** access"
                },
                snapshots: {
                    automatic: "The {{translatedSourceResourceTypeName}} has automatic backup enabled generating {{snapshotIds}} without KMS encryption",
                    awsBackup: "The {{translatedSourceResourceTypeName}} has aws backup enabled generating {{snapshotIds}} without KMS encryption",
                    empty: "The {{translatedSourceResourceTypeName}} has no RDS snapshots taken from this database",
                    manual: "The {{translatedSourceResourceTypeName}} has {{snapshotIds}} manually created without KMS encryption"
                }
            }));
    const translatedSourceResourceTypeName =
        entityTypeNameTranslator(
            sourceResourceModel.entity.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });

    const [accessLevelSnapshotModels, snapshotTypeToSnapshotModelsMap] =
        useMemo(
            () => {
                const accessLevelSnapshotModels =
                    _.filter(
                        snapshotModels,
                        snapshotModel => (snapshotModel as Contract.AwsRdsSnapshotModel).accessLevel === riskModel.risk.accessLevel);
                const snapshotTypeToSnapshotModelsMap =
                    _.groupBy(
                        snapshotModels,
                        snapshotModel => (snapshotModel.entity as Contract.AwsRdsSnapshot).type);
                return [accessLevelSnapshotModels, snapshotTypeToSnapshotModelsMap];
            },
            [riskModel]);

    return {
        automatic:
            _.isEmpty(snapshotTypeToSnapshotModelsMap[Contract.AwsResourceSnapshotType.Automatic])
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.snapshots.automatic({
                        snapshotIds:
                            <InlineEntities
                                entityIdsOrModels={snapshotTypeToSnapshotModelsMap[Contract.AwsResourceSnapshotType.Automatic]}
                                entityTypeName={Contract.TypeNames.AwsRdsSnapshot}
                                variant="itemCountAndType"/>,
                        translatedSourceResourceTypeName
                    })),
        awsBackup:
            _.isEmpty(snapshotTypeToSnapshotModelsMap[Contract.AwsResourceSnapshotType.AwsBackup])
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.snapshots.awsBackup({
                        snapshotIds:
                            <InlineEntities
                                entityIdsOrModels={snapshotTypeToSnapshotModelsMap[Contract.AwsResourceSnapshotType.AwsBackup]}
                                entityTypeName={Contract.TypeNames.AwsRdsSnapshot}
                                variant="itemCountAndType"/>,
                        translatedSourceResourceTypeName
                    })),
        internal:
            _.isEmpty(riskModel.risk.aggregatedEntityIds) ||
            _.isNil(riskModel.risk.accessLevel) ||
            riskModel.risk.accessLevel === Contract.AwsResourceAccessLevel.Internal
                ? undefined
                : new RiskDefinitionContextItem(
                    (accessLevelSnapshotModels.length === riskModel.risk.aggregatedEntityIds.length)
                        ? localization.networkAccess.all({
                            accessLevel: resourceAccessLevelTranslator(riskModel.risk.accessLevel),
                            translatedSnapshotTypeName:
                                entityTypeNameTranslator(
                                    accessLevelSnapshotModels[0].entity.typeName,
                                    {
                                        count: 0,
                                        includeServiceName: false,
                                        variant: "text"
                                    })
                        })
                        : localization.networkAccess.some({
                            accessLevel: resourceAccessLevelTranslator(riskModel.risk.accessLevel),
                            accessLevelSnapshots:
                                <InlineEntities
                                    entityIdsOrModels={accessLevelSnapshotModels}
                                    entityTypeName={Contract.TypeNames.AwsRdsSnapshot}
                                    variant="itemCountAndType"/>,
                            translatedSourceResourceTypeName
                        })),
        manual:
            _.isEmpty(snapshotTypeToSnapshotModelsMap[Contract.AwsResourceSnapshotType.Manual])
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.snapshots.manual({
                        snapshotIds:
                            <InlineEntities
                                entityIdsOrModels={snapshotTypeToSnapshotModelsMap[Contract.AwsResourceSnapshotType.Manual]}
                                entityTypeName={Contract.TypeNames.AwsRdsSnapshot}
                                variant="itemCountAndType"/>,
                        translatedSourceResourceTypeName
                    })),
        snapshotIdsEmpty:
            _.isEmpty(riskModel.risk.aggregatedEntityIds)
                ? new RiskDefinitionContextItem(
                    localization.snapshots.empty({ translatedSourceResourceTypeName }))
                : undefined
    };
}