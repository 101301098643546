import { EmptyMessage, useLocalization } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React, { Fragment } from "react";
import { Contract } from "../../../../../../../../controllers";
import { RiskCombination } from "./components";

type RiskCombinationsProps = {
    riskCombinationToDataMap: Dictionary<Contract.RiskCombinationData>;
};

export function RiskCombinations({ riskCombinationToDataMap }: RiskCombinationsProps) {
    const localization =
        useLocalization(
            "common.dashboard.widget.hooks.useDefinition.riskCombinations",
            () => ({
                empty: "No Toxic Combinations"
            }));

    return (
        _.isEmpty(riskCombinationToDataMap)
            ? <EmptyMessage
                message={localization.empty()}
                verticalCenter={true}/>
            : <Fragment>
                {_(riskCombinationToDataMap).
                    toPairs().
                    orderBy(
                        [
                            ([, riskCombinationData]) =>
                                _(riskCombinationData.orderedRiskPolicyCategories).
                                    uniq().
                                    size(),
                            ([, riskCombinationData]) =>
                                _(riskCombinationData.entityTypeNameToCountMap).
                                    values().
                                    sum()
                        ],
                        [
                            "desc",
                            "desc"
                        ]).
                    map(
                        ([riskCombination, riskCombinationData], index) =>
                            <RiskCombination
                                entityTypeNameToCountMap={riskCombinationData.entityTypeNameToCountMap}
                                key={`${riskCombination}-${index}`}
                                orderedRiskPolicyCategories={riskCombinationData.orderedRiskPolicyCategories}
                                riskCombination={riskCombination as Contract.RiskCombination}/>).
                    value()}
            </Fragment>);
}