import { Optional } from "@infrastructure";
import _ from "lodash";
import { Contract } from "..";

export class IntRangeHelper {
    public static format(intRange: Contract.IntRange): string {
        return intRange.start === intRange.end
            ? intRange.start.toString()
            : `${intRange.start}-${intRange.end}`;
    }

    public static tryParse(intRangeString: string): Optional<Contract.IntRange> {
        if (!IntRangeHelper.validate(intRangeString)) {
            return undefined;
        }

        const [intRangeStart, intRangeEnd] =
            _.map(
                intRangeString.
                    trim().
                    split("-"),
                _ => parseInt(_, 10));

        return new Contract.IntRange(
            _.isNil(intRangeEnd)
                ? intRangeStart
                : intRangeEnd,
            intRangeStart);
    }

    public static validate(intRangeString: string) {
        return /^(\d+-\d+|\d+)$/.test(intRangeString);
    }

}