import { DataTableColumn, DataTableColumnRenderProps, EmptyMessageText, StringHelper, TextValuesFilter, useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, EntitiesCell, EntityFilter, entityModelStore, InlinePermissionActions, ItemTable, useEntityTypeNameTranslator } from "../../../../../../../../../../../../../../common";
import { GcpRoleBindingHelper } from "../../../../../../../../../../../../../../tenants";

export type RoleBindingTableProps = {
    csvExportFilePrefixes: string[];
    risk: Contract.GcpPrincipalServiceAccountWideScopeAdministratorRoleRisk;
};

export function RoleBindingTable({ csvExportFilePrefixes, risk }: RoleBindingTableProps) {
    const roleBindingModels = entityModelStore.useGet(risk.roleBindingIds) as Contract.GcpIamRoleBindingModel[];
    const roleModels = entityModelStore.useGet(_.flatMap(risk.resourceManagerResourceIdToRoleIdsMap)) as Contract.GcpIamRoleModel[];
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.useGcpPrincipalServiceAccountWideScopeAdministratorRoleRiskDefinition.roleBindingTable",
            () => ({
                columns: {
                    permissionActions: "Permissions",
                    roleBindingId: "Role Binding",
                    usedPermissionActions: {
                        empty: "No permission was used in the last 90 days",
                        title: "Permissions Used In Last 90 Days",
                        unknown: "Learning"
                    }
                },
                empty: "No Bindings",
                permissionActions: [
                    "1 permission",
                    "{{count | NumberFormatter.humanize}} permissions"
                ]
            }));

    const [items, roleBindingModelMap] =
        useMemo(
            () => {
                const roleModelMap =
                    _.keyBy(
                        roleModels,
                        roleModel => roleModel.id);
                const items =
                    _(roleBindingModels).
                        map(
                            roleBindingModel => {
                                const roleBinding = roleBindingModel.entity as Contract.GcpIamRoleBinding;
                                return ({
                                    permissionActions: (roleModelMap[roleBinding.roleId].entity as Contract.GcpIamRole).actions,
                                    roleBinding,
                                    usedPermissionActions: roleBindingModel.permissionUsage?.usedPermissionActions
                                });
                            }).
                        as<RoleBindingTableItem>().
                        value();

                const roleBindingModelMap =
                    _.keyBy(
                        roleBindingModels,
                        roleBindingModel => roleBindingModel.id);

                return [items, roleBindingModelMap];
            },
            [roleBindingModels, roleModels]);

    return (
        <ItemTable
            columnIdToGetItemValueMap={{
                [RoleBindingTableColumnId.PermissionActions]: {
                    getFilterValue: item => item.permissionActions,
                    getSortValue: item => item.permissionActions.length
                },
                [RoleBindingTableColumnId.RoleBindingId]: {
                    getFilterValue: item => item.roleBinding.id,
                    getSortValue: item => StringHelper.normalize(item.roleBinding.displayName)
                },
                [RoleBindingTableColumnId.UsedPermissionActions]: {
                    getFilterValue: item => item.usedPermissionActions,
                    getSortValue: item => item.usedPermissionActions?.length ?? -1
                }
            }}
            csvExportFilePrefixes={csvExportFilePrefixes}
            defaultSortColumnIdOrIds={RoleBindingTableColumnId.RoleBindingId}
            emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty()) }}
            getCsvItem={
                item => ({
                    /* eslint-disable sort-keys-fix/sort-keys-fix */
                    "Role Binding": GcpRoleBindingHelper.getDisplayName(
                        entityTypeNameTranslator,
                        roleBindingModelMap[item.roleBinding.id]),
                    "Permissions": localization.permissionActions(item.permissionActions.length),
                    "Permissions Used In Last 90 Days":
                        _.isNil(item.usedPermissionActions)
                            ? localization.columns.usedPermissionActions.unknown()
                            : _.isEmpty(item.usedPermissionActions)
                                ? localization.columns.usedPermissionActions.empty()
                                : localization.permissionActions(item.usedPermissionActions.length)
                    /* eslint-enable sort-keys-fix/sort-keys-fix */
                })}
            getItemId={item => item.roleBinding.id}
            items={items}>
            {columnIdToItemValuesMap => [
                <DataTableColumn
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <EntityFilter
                                    entityIdsOrSearchableReferences={columnIdToItemValuesMap[RoleBindingTableColumnId.RoleBindingId]}
                                    placeholder={localization.columns.roleBindingId()}/>
                        }
                    }}
                    id={RoleBindingTableColumnId.RoleBindingId}
                    key={RoleBindingTableColumnId.RoleBindingId}
                    render={
                        ({ item }: DataTableColumnRenderProps<RoleBindingTableItem>) =>
                            <EntitiesCell
                                entityIdsOrModels={item.roleBinding.id}
                                entityTypeName={Contract.TypeNames.GcpIamRoleBinding}
                                entityVariant="iconTextTypeTenant"/>}
                    title={localization.columns.roleBindingId()}/>,
                <DataTableColumn
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <TextValuesFilter
                                    placeholder={localization.columns.permissionActions()}
                                    values={
                                        _(columnIdToItemValuesMap[RoleBindingTableColumnId.PermissionActions]).
                                            flatMap().
                                            uniq().
                                            value()}/>
                        }
                    }}
                    id={RoleBindingTableColumnId.PermissionActions}
                    key={RoleBindingTableColumnId.PermissionActions}
                    render={
                        ({ item }: DataTableColumnRenderProps<RoleBindingTableItem>) =>
                            <InlinePermissionActions
                                permissionActions={item.permissionActions}
                                variant="itemOrItemCountAndType"/>}
                    title={localization.columns.permissionActions()}/>,
                <DataTableColumn
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <TextValuesFilter
                                    placeholder={localization.columns.usedPermissionActions.title()}
                                    values={
                                        _(columnIdToItemValuesMap[RoleBindingTableColumnId.UsedPermissionActions]).
                                            flatMap().
                                            uniq().
                                            value()}/>
                        }
                    }}
                    id={RoleBindingTableColumnId.UsedPermissionActions}
                    key={RoleBindingTableColumnId.UsedPermissionActions}
                    render={
                        ({ item }: DataTableColumnRenderProps<RoleBindingTableItem>) =>
                            _.isNil(item.usedPermissionActions)
                                ? <Typography>
                                    {localization.columns.usedPermissionActions.unknown()}
                                </Typography>
                                : _.isEmpty(item.usedPermissionActions)
                                    ? <Typography>
                                        {localization.columns.usedPermissionActions.empty()}
                                    </Typography>
                                    : <InlinePermissionActions
                                        permissionActions={item.usedPermissionActions!}
                                        variant="itemOrItemCountAndType"/>}
                    title={localization.columns.usedPermissionActions.title()}/>
            ]}
        </ItemTable>);
}

enum RoleBindingTableColumnId {
    PermissionActions = "permissionActions",
    RoleBindingId = "roleBindingId",
    UsedPermissionActions = "usedPermissionActions"
}

type RoleBindingTableItem = {
    permissionActions: string[];
    roleBinding: Contract.GcpIamRoleBinding;
    usedPermissionActions?: string[];
};