import React from "react";
import { Step, Steps, useLocalization } from "@infrastructure";
import { Contract, Entity } from "../../../../../../../../../../../../../common";
import { Vulnerabilities } from "../../../../../../../../../../WorkloadAnalysis";
import { RiskDefinition, RiskDefinitionSection } from "../../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../../useCommonSectionsAndDescriptionDefinition";
import { VulnerabilitiesInlineItems } from "../../../components";
import { getWorkloadResourceVulnerabilityRiskMaxVulnerabilitySeverity, getWorkloadResourceVulnerabilityRiskSeverityValues, useGetWorkloadResourceRiskVulnerabilityRawIds } from "../../../utilities/utilities";
import { useGetVirtualMachineImageWorkloadAnalysisRiskContext } from "../../contexts";
import { useGetPackageVulnerabilityResolutionSteps } from "./useGetPackageVulnerabilityResolutionSteps";
import { useGetVirtualMachineImageResolutionSteps } from "./useGetVirtualMachineImageResolutionSteps";

export function useVirtualMachineImageVulnerabilityRiskDefinition(riskModel: Contract.RiskModel): RiskDefinition {
    const virtualMachineImageVulnerabilityRisk = riskModel.risk as Contract.VirtualMachineImageVulnerabilityRisk;
    const getVirtualMachineImageResolutionSteps = useGetVirtualMachineImageResolutionSteps();

    const getVirtualMachineImageWorkloadAnalysisRiskContext = useGetVirtualMachineImageWorkloadAnalysisRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.workloadAnalysis.hooks.useWorkloadAnalysisDefinition.hooks.useVirtualMachineImageVulnerabilityRiskDefinition",
            () => ({
                description:  "{{riskedEntity}} has {{vulnerabilities}}",
                resolution: {
                    vulnerabilities: "Create an AMI instance in which vulnerabilities have been upgraded, as per the details below:"
                },
                sections: {
                    vulnerabilities: "Vulnerabilities"
                }
            }));
    const maxVulnerabilitySeverity = getWorkloadResourceVulnerabilityRiskMaxVulnerabilitySeverity(virtualMachineImageVulnerabilityRisk);
    const vulnerabilitySeverityToRawIdsMap = useGetWorkloadResourceRiskVulnerabilityRawIds(virtualMachineImageVulnerabilityRisk);

    const descriptionProps =
        {
            riskedEntity: <Entity
                entityIdOrModel={virtualMachineImageVulnerabilityRisk.entityId}
                entityTypeNameTranslatorOptions={{ variant: "title" }}
                variant="typeText"/>,
            vulnerabilities: <VulnerabilitiesInlineItems
                severity={maxVulnerabilitySeverity}
                severityVulnerabilityRawIds={vulnerabilitySeverityToRawIdsMap[maxVulnerabilitySeverity]}/>
        };

    const getPackageVulnerabilityResolutionSteps = useGetPackageVulnerabilityResolutionSteps(virtualMachineImageVulnerabilityRisk.windowsUpdateDocumentationUrl);

    return useCommonSectionsAndDescriptionDefinition(
        localization.description(descriptionProps),
        () =>
            getVirtualMachineImageResolutionSteps(
                new Step(
                    localization.resolution.vulnerabilities(),
                    {
                        contentElement:
                            <Steps variant={"bullets"}>
                                {...getPackageVulnerabilityResolutionSteps(
                                    virtualMachineImageVulnerabilityRisk.filePathToVulnerabilityRawIdsMap,
                                    virtualMachineImageVulnerabilityRisk.filePathToVulnerabilityRawIdsMap,
                                    virtualMachineImageVulnerabilityRisk.vulnerabilityPackageNameToResolutionVersionsMap)}
                            </Steps>
                    })),
        riskModel,
        () => {
            const virtualMachineImageWorkloadAnalysisRiskContext = getVirtualMachineImageWorkloadAnalysisRiskContext(virtualMachineImageVulnerabilityRisk);

            return [
                virtualMachineImageWorkloadAnalysisRiskContext.generalInformation,
                virtualMachineImageWorkloadAnalysisRiskContext.virtualMachineUsage,
                ...virtualMachineImageWorkloadAnalysisRiskContext.severityVulnerabilities,
                virtualMachineImageWorkloadAnalysisRiskContext.sensitive,
                virtualMachineImageWorkloadAnalysisRiskContext.getOpenRiskedEntityRisksContextItem(virtualMachineImageVulnerabilityRisk.id)
            ];
        },
        {
            sections:
                [
                    new RiskDefinitionSection(
                        <Vulnerabilities
                            entityIds={[virtualMachineImageVulnerabilityRisk.entityId]}
                            severityFilterValues={getWorkloadResourceVulnerabilityRiskSeverityValues(virtualMachineImageVulnerabilityRisk.severeVulnerabilityMinSeverity)}
                            variant="risk"/>,
                        localization.sections.vulnerabilities(),
                        {
                            expandable: true
                        })
                ]
        });
}