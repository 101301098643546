import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function ColoredOpIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                clipRule="evenodd"
                d="M5 13C2.79086 13 1 14.7909 1 17C1 19.2091 2.79086 21 5 21H19C21.2091 21 23 19.2091 23 17C23 14.7909 21.2091 13 19 13H5ZM5 16C4.44772 16 4 16.4477 4 17C4 17.5523 4.44772 18 5 18H12C12.5523 18 13 17.5523 13 17C13 16.4477 12.5523 16 12 16H5ZM16.0004 15C16.5527 15 17.0004 15.4477 17.0004 16V18C17.0004 18.5523 16.5527 19 16.0004 19C15.4481 19 15.0004 18.5523 15.0004 18V16C15.0004 15.4477 15.4481 15 16.0004 15ZM20.0004 16C20.0004 15.4477 19.5527 15 19.0004 15C18.4481 15 18.0004 15.4477 18.0004 16V18C18.0004 18.5523 18.4481 19 19.0004 19C19.5527 19 20.0004 18.5523 20.0004 18V16Z"
                fill="url(#paint0_linear_57769_1472)"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M5 4C2.79086 4 1 5.79086 1 8C1 10.2091 2.79086 12 5 12H19C21.2091 12 23 10.2091 23 8C23 5.79086 21.2091 4 19 4H5ZM5 7C4.44772 7 4 7.44772 4 8C4 8.55228 4.44772 9 5 9H12C12.5523 9 13 8.55228 13 8C13 7.44772 12.5523 7 12 7H5ZM16.0004 5.99997C16.5527 5.99997 17.0004 6.44769 17.0004 6.99997V8.99997C17.0004 9.55226 16.5527 9.99997 16.0004 9.99997C15.4481 9.99997 15.0004 9.55226 15.0004 8.99997V6.99997C15.0004 6.44769 15.4481 5.99997 16.0004 5.99997ZM20.0004 6.99997C20.0004 6.44769 19.5527 5.99997 19.0004 5.99997C18.4481 5.99997 18.0004 6.44769 18.0004 6.99997V8.99997C18.0004 9.55226 18.4481 9.99997 19.0004 9.99997C19.5527 9.99997 20.0004 9.55226 20.0004 8.99997V6.99997Z"
                fill="url(#paint1_linear_57769_1472)"
                fillRule="evenodd"/>
            <defs>
                <linearGradient
                    gradientUnits="userSpaceOnUse"
                    id="paint0_linear_57769_1472"
                    x1="25.5792"
                    x2="1.26337"
                    y1="22.7368"
                    y2="3.78944">
                    <stop stopColor="#35B5EC"/>
                    <stop
                        offset="1"
                        stopColor="#0889C0"/>
                </linearGradient>
                <linearGradient
                    gradientUnits="userSpaceOnUse"
                    id="paint1_linear_57769_1472"
                    x1="25.5792"
                    x2="1.26337"
                    y1="22.7368"
                    y2="3.78944">
                    <stop stopColor="#35B5EC"/>
                    <stop
                        offset="1"
                        stopColor="#0889C0"/>
                </linearGradient>
            </defs>
        </SvgIcon>);
}