import { Divider, Stack, Typography, useTheme } from "@mui/material";
import _ from "lodash";
import React, { Fragment, ReactElement, useState } from "react";
import { Dropdown, DropdownIcon, IconText, makeContextProvider, NavigationViewTree, NavigationViewTreeProps, Shadows, Tooltip, useApplicationContext, useLocalization } from "@infrastructure";
import { Scope } from "../..";
import { DocumentationButton } from "../DocumentationButton";
import { PageUnavailableImage } from "./images";

export type LayoutOptions = {
    contained?: boolean;
    scopeDisabled?: boolean;
    scopeNavigationOptions?: Omit<NavigationViewTreeProps, "variant" | "onNavigate">;
    view?: LayoutView;
};

type LayoutView = {
    documentationPath?: string;
    title: string;
};

export class LayoutContext {
    constructor(public options: LayoutOptions) { }
}

export const [useLayoutContext, useSetLayoutContext, useLayoutContextProvider] = makeContextProvider<LayoutContext>();

type LayoutProps = {
    children: ReactElement;
    contained?: boolean;
    sidebar?: ReactElement;
    topbar?: ReactElement;
};

export function Layout({ children, contained = true, sidebar, topbar }: LayoutProps) {
    const { permittedContent } = useApplicationContext();
    const [{ options }, , LayoutContextProvider] = useLayoutContextProvider(() => new LayoutContext({}));
    const [showDocumentationButton, setShowDocumentationButton] = useState(false);
    const [scopeNavigationDropdownOpen, setScopeNavigationDropdownOpen] = useState(false);
    const localization =
        useLocalization(
            "common.layout",
            () => ({
                dropdownDisabledTooltipText: "This option is unavailable. You may be filtering Console data by the entire organization, or you may not have any relevant accounts to select."
            }));
    const theme = useTheme();

    const scopeNavigationOptionsExists = !_.isNil(options.scopeNavigationOptions?.items);
    const scopeNavigationDropdownDisabled = scopeNavigationOptionsExists && (options.scopeNavigationOptions!.itemsCount <= 1 || options.scopeDisabled);
    function getViewTitleElement() {
        return (
            <Typography
                sx={{
                    fontWeight: 500,
                    padding: theme.spacing(1.5, 0, 1.5, 2)
                }}
                variant="h4">
                {options.view?.title}
            </Typography>);
    }
    return (
        <LayoutContextProvider>
            {topbar}
            <Stack
                direction="row"
                sx={{
                    height: "100%",
                    overflow: "hidden"
                }}>
                {sidebar}
                <Stack
                    sx={{
                        background: theme.palette.background.paper,
                        borderLeft: theme.border.pageFrame,
                        borderTop: theme.border.pageFrame,
                        borderTopLeftRadius:
                            !_.isNil(topbar)
                                ? theme.spacing(1)
                                : undefined,
                        boxShadow: theme.shadows[Shadows.Card],
                        height: "100%",
                        overflow: "hidden",
                        position: "relative",
                        width: "100%",
                        zIndex: 1,
                        ...(!(options.contained ?? contained) && {
                            background: "unset",
                            border: "unset",
                            boxShadow: "unset",
                            padding: 0
                        })
                    }}>
                    <Stack
                        alignItems="center"
                        direction="row"
                        spacing={1}
                        sx={{ borderBottom: theme.border.pageFrame }}
                        onMouseEnter={() => setShowDocumentationButton(true)}
                        onMouseLeave={() => setShowDocumentationButton(false)}>
                        {!_.isNil(options.view?.title) &&
                            (_.isNil(options.view?.documentationPath) || !showDocumentationButton
                                ? getViewTitleElement()
                                : <IconText
                                    direction="rtl"
                                    icon={<DocumentationButton path={options.view!.documentationPath}/>}
                                    spacing={0.5}
                                    text={getViewTitleElement()}/>)}
                        {scopeNavigationOptionsExists &&
                            <Fragment>
                                <Divider
                                    flexItem={true}
                                    orientation="vertical"
                                    sx={{
                                        alignSelf: "center",
                                        height: theme.spacing(2)
                                    }}
                                    variant="middle"/>
                                <Dropdown
                                    disabled={scopeNavigationDropdownDisabled}
                                    open={scopeNavigationDropdownOpen}
                                    popoverElement={
                                        <NavigationViewTree
                                            {...options.scopeNavigationOptions!}
                                            variant="tree"
                                            onNavigate={() => setScopeNavigationDropdownOpen(false)}/>}
                                    popoverElementContainerSx={{
                                        maxHeight: "80vh",
                                        minWidth: theme.spacing(30),
                                        padding: 0
                                    }}
                                    onClose={() => setScopeNavigationDropdownOpen(false)}
                                    onOpen={() => setScopeNavigationDropdownOpen(true)}>
                                    <Tooltip
                                        disabled={!scopeNavigationDropdownDisabled || options.scopeDisabled}
                                        titleOrGetTitle={localization.dropdownDisabledTooltipText()}>
                                        <Stack
                                            alignItems="center"
                                            direction="row"
                                            spacing={0.5}>
                                            <Typography>
                                                <Scope
                                                    iconSx={{ fontSize: "18px" }}
                                                    scopeId={options.scopeNavigationOptions!.selectedItemId}
                                                    variant="iconText"/>
                                            </Typography>
                                            <DropdownIcon sx={{ fontSize: "16px" }}/>
                                        </Stack>
                                    </Tooltip>
                                </Dropdown>
                            </Fragment>}
                    </Stack>
                    <Stack
                        sx={{
                            flex: 1,
                            overflow: "hidden"
                        }}>
                        {permittedContent
                            ? children
                            : <ContentNotPermitted/>}
                    </Stack>
                </Stack>
            </Stack>
        </LayoutContextProvider>);
}

function ContentNotPermitted() {
    const localization =
        useLocalization(
            "common.layout.contentNotPermitted",
            () => ({
                text: "The selected account does not support this page, or has no data. Please try a different account.",
                title: "Page is unavailable"
            }));
    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            justifyContent="center"
            spacing={0.5}
            sx={{
                height: "100%",
                padding: theme.spacing(6)
            }}>
            <PageUnavailableImage/>
            <Typography variant="h1">
                {localization.title()}
            </Typography>
            <Typography
                sx={{
                    fontSize: "16px",
                    maxWidth: "440px",
                    textAlign: "center"
                }}>
                {localization.text()}
            </Typography>
        </Stack>);
}