import { DataTable, DataTableColumn, DataTableColumnRenderProps, EmptyMessage, NoneIcon, useLocalization, useLocalizeList } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React, { Fragment, useMemo } from "react";
import { Contract, EntitiesCell, entityModelStore, InfoCard } from "../../../../../../../../../common";
import { AzureFirewallRulesSubnetTable } from "../../../../../../../../../tenants";
import { useAzureNetworkServiceEndpointStatusTranslator } from "../../../../../hooks";

type AzureFirewallInfoCardProps = {
    networkResourcePathToIdReferencesMap?: Dictionary<string>;
    rules?: Contract.AzureResourceFirewallIpAddressRangeRule[];
    subnets?: string[];
    virtualNetworkPathToFirewallSubnetRulesMap?: Dictionary<Contract.AzureResourceFirewallVirtualNetworkSubnetRule[]>;
};

export function AzureFirewallInfoCard({ networkResourcePathToIdReferencesMap, rules, subnets, virtualNetworkPathToFirewallSubnetRulesMap }: AzureFirewallInfoCardProps) {
    const [endpointStatusExists, items] =
        useMemo(
            () => {
                const endpointStatusExists =
                    _(virtualNetworkPathToFirewallSubnetRulesMap).
                        values().
                        flatMap().
                        some(virtualNetworkSubnetRule => !_.isNil(virtualNetworkSubnetRule.state));

                const items =
                    _.flatMap(
                        virtualNetworkPathToFirewallSubnetRulesMap,
                        (virtualNetworkSubnetRules, virtualNetworkPath) =>
                            _.flatMap(
                                virtualNetworkSubnetRules,
                                virtualNetworkSubnetRule =>
                                    new VirtualNetworkSubnetTableItem(
                                        networkResourcePathToIdReferencesMap!,
                                        virtualNetworkSubnetRule,
                                        virtualNetworkPath)));

                return [endpointStatusExists, items];
            },
            [virtualNetworkPathToFirewallSubnetRulesMap]);

    const networkServiceEndpointStatusTranslator = useAzureNetworkServiceEndpointStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.azureFirewallInfoCard",
            () => ({
                empty: "No Network Rules",
                rules: {
                    columns: {
                        endIpAddress: "End IP",
                        name: "Name",
                        startIpAddress: "Start IP"
                    }
                },
                title: "Network Rules",
                virtualNetworkSubnets: {
                    columns: {
                        endpointStatus: "Endpoint Status",
                        subnets: "Address Range",
                        virtualNetwork: "Virtual Network",
                        virtualNetworkSubnet: "Subnet"
                    }
                }
            }));

    return (
        <InfoCard title={localization.title()}>
            {_.isEmpty(networkResourcePathToIdReferencesMap) &&
                _.isEmpty(rules) &&
                _.isEmpty(subnets) &&
                _.isEmpty(virtualNetworkPathToFirewallSubnetRulesMap) &&
                <EmptyMessage
                    message={localization.empty()}
                    verticalCenter={true}/>}
            {!_.isEmpty(networkResourcePathToIdReferencesMap) && !_.isEmpty(virtualNetworkPathToFirewallSubnetRulesMap) &&
                <DataTable
                    fetchItems={() => items}
                    getItemId={(item: VirtualNetworkSubnetTableItem) => item.id}
                    sortOptions={{ enabled: false }}
                    variant="card">
                    <DataTableColumn
                        id={VirtualNetworkSubnetTableColumnId.VirtualNetwork}
                        render={
                            ({ item }: DataTableColumnRenderProps<VirtualNetworkSubnetTableItem>) =>
                                <EntitiesCell
                                    entityIdsOrModels={item.virtualNetworkId}
                                    entityTypeName={Contract.TypeNames.AzureNetworkVirtualNetwork}
                                    entityVariant="iconTextTypeTenant"/>}
                        title={localization.virtualNetworkSubnets.columns.virtualNetwork()}/>
                    <DataTableColumn
                        id={VirtualNetworkSubnetTableColumnId.VirtualNetworkSubnet}
                        render={
                            ({ item }: DataTableColumnRenderProps<VirtualNetworkSubnetTableItem>) =>
                                <EntitiesCell
                                    entityIdsOrModels={item.id}
                                    entityTypeName={Contract.TypeNames.AzureNetworkVirtualNetworkSubnet}
                                    entityVariant="iconTextTypeTenant"/>}
                        title={localization.virtualNetworkSubnets.columns.virtualNetworkSubnet()}/>
                    <DataTableColumn
                        id={VirtualNetworkSubnetTableColumnId.Subnets}
                        render={SubnetsCell}
                        title={localization.virtualNetworkSubnets.columns.subnets()}/>
                    {endpointStatusExists &&
                        <DataTableColumn
                            id={VirtualNetworkSubnetTableColumnId.EndpointStatus}
                            itemProperty={(item: VirtualNetworkSubnetTableItem) => networkServiceEndpointStatusTranslator(item.subnetRule.state!)}
                            title={localization.virtualNetworkSubnets.columns.endpointStatus()}/>}
                </DataTable>}
            {!_.isEmpty(rules) &&
                <DataTable
                    fetchItems={() => rules!}
                    getItemId={(item: Contract.AzureResourceGroupResourceModel) => item.id}
                    sortOptions={{ enabled: false }}
                    variant="card">
                    <DataTableColumn
                        id={RulesTableColumnId.Name}
                        itemProperty={(rule: Contract.AzureResourceFirewallIpAddressRangeRule) => rule.name}
                        title={localization.rules.columns.name()}/>
                    <DataTableColumn
                        id={RulesTableColumnId.StartIpAddress}
                        itemProperty={(rule: Contract.AzureResourceFirewallIpAddressRangeRule) => rule.startIpAddress}
                        title={localization.rules.columns.startIpAddress()}/>
                    <DataTableColumn
                        id={RulesTableColumnId.EndIpAddress}
                        itemProperty={(rule: Contract.AzureResourceFirewallIpAddressRangeRule) => rule.endIpAddress}
                        title={localization.rules.columns.endIpAddress()}/>
                </DataTable>}
            {!_.isEmpty(subnets) &&
                <AzureFirewallRulesSubnetTable subnets={subnets!}/>}
        </InfoCard>);
}

function SubnetsCell({ item }: DataTableColumnRenderProps<VirtualNetworkSubnetTableItem>) {
    const localizeList = useLocalizeList();
    const subnetModel = entityModelStore.useGet(item.id) as Contract.AzureNetworkVirtualNetworkSubnetModel;
    return (
        <Fragment>
            {subnetModel.unknown
                ? <NoneIcon sx={{ fontSize: "18px" }}/>
                : localizeList((subnetModel.entity as Contract.AzureNetworkVirtualNetworkSubnet).subnets)}
        </Fragment>);
}

enum RulesTableColumnId {
    EndIpAddress = "endIpAddress",
    Name = "name",
    StartIpAddress = "startIpAddress"
}

class VirtualNetworkSubnetTableItem {
    public id: string;
    public virtualNetworkId: string;

    constructor(
        networkResourcePathToIdReferencesMap: Dictionary<string>,
        public subnetRule: Contract.AzureResourceFirewallVirtualNetworkSubnetRule,
        virtualNetworkPath: string) {
        this.id = networkResourcePathToIdReferencesMap[subnetRule.path];
        this.virtualNetworkId = networkResourcePathToIdReferencesMap[virtualNetworkPath];
    }
}

enum VirtualNetworkSubnetTableColumnId {
    EndpointStatus = "endpointStatus",
    Subnets = "addressRange",
    VirtualNetwork = "virtualNetwork",
    VirtualNetworkSubnet = "virtualNetworkSubnet"
}