import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../../common";
import { useCommonSectionsAndDescriptionDefinition } from "../../../../useCommonSectionsAndDescriptionDefinition";
import { useGetGcpTenantEntityRiskContext } from "../../contexts";

export function useGcpTenantEntityCreationRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.GcpTenantEntityCreationRisk;
    const tenantEntityModel = entityModelStore.useGet(risk.entityId) as Contract.GcpTenantEntityModel;

    const getGcpTenantEntityRiskContext = useGetGcpTenantEntityRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.management.useGcpTenantEntityCreationRiskDefinition",
            () => ({
                description: "{{creatorIdentity}} created a new {{tenant}}",
                sections: {
                    tenantEntityDetails: "Project Details"
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            creatorIdentity:
                <Entity
                    entityIdOrModel={(tenantEntityModel.entityProfile as Contract.GcpTenantEntityProfile)!.creation!.eventIdentityReference!}
                    variant="text"/>,
            tenant:
                <Entity
                    entityIdOrModel={tenantEntityModel}
                    variant="typeText"/>
        }),
        () => [],
        riskModel,
        () => {
            const tenantEntityRiskContext = getGcpTenantEntityRiskContext(tenantEntityModel);
            return [
                tenantEntityRiskContext.generalInformation,
                tenantEntityRiskContext.sensitive,
                tenantEntityRiskContext.path,
                tenantEntityRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        });
}