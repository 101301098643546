import { useLocalization, useOrderedWizardContext, WizardFinishItem } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useAddOrEditContext } from "..";
import { tenantModelStore } from "../../../../../../../../../../../../../../../common";

export function FinishItem() {
    const { useNextEffect } = useOrderedWizardContext();
    const { tenantConfiguration, updatedTenantModel } = useAddOrEditContext();

    useNextEffect(
        async () => {
            await tenantModelStore.notify(updatedTenantModel!);
            return undefined;
        },
        []);

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useIdentityProviderTenantItems.gciTenants.addOrEdit.finishItem",
            () => ({
                title: {
                    add: "Google Workspace was added successfully!",
                    edit: "Google Workspace was changed successfully!"
                }
            }));

    return (
        <WizardFinishItem
            title={
                _.isNil(tenantConfiguration)
                    ? localization.title.add()
                    : localization.title.edit()}/>);
}