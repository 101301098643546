import { datadogRum, RumEvent, RumEventDomainContext, RumFetchResourceEventDomainContext } from "@datadog/browser-rum";
import _ from "lodash";
import { v4 as uuid } from "uuid";
import { UserHelper } from "./userHelper";

type ObservabilityHelperDatadogRumApplication = {
    clientToken: string;
    id: string;
    recordingEnabled: boolean;
    siteDomainName: string;
};

export class ObservabilityHelper {
    public static initialize(assemblyFileVersion: string, consoleAppType: string, deploymentName: string, environmentType: string, datadogRumApplication?: ObservabilityHelperDatadogRumApplication) {
        if (_.isNil(datadogRumApplication)) {
            return;
        }

        datadogRum.init({
            applicationId: datadogRumApplication.id,
            beforeSend:
                (event: RumEvent, context: RumEventDomainContext) => {
                    if (event.type === "resource" &&
                        event.resource.type === "fetch") {
                        event.context = {
                            ...event.context,
                            RequestId: (context as RumFetchResourceEventDomainContext).response?.headers.get("request-id")
                        };
                    }
                },
            clientToken: datadogRumApplication.clientToken,
            defaultPrivacyLevel: "mask",
            env: environmentType.toLowerCase(),
            service: consoleAppType.toLowerCase(),
            site: datadogRumApplication.siteDomainName,
            telemetrySampleRate: 0,
            trackFrustrations: true,
            trackLongTasks: true,
            trackResources: true,
            trackUserInteractions: true,
            version: assemblyFileVersion
        });

        datadogRum.setGlobalContextProperty("deploymentName", deploymentName);

        datadogRum.setGlobalContextProperty(
            "serviceSessionId",
            uuid());

        if (datadogRumApplication.recordingEnabled) {
            datadogRum.startSessionReplayRecording();
        }
    }

    public static setData() {
        datadogRum.setUser({
            customerId: UserHelper.customer?.id,
            customerName: UserHelper.customer?.name,
            email: UserHelper.mail,
            name: UserHelper.name
        });
    }
}