﻿import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useAwsEksFargateProfileStatusTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useAwsEksFargateProfileStatusTranslator",
            () => ({
                [Contract.TypeNames.AwsEksFargateProfileStatus]: {
                    [Contract.AwsEksFargateProfileStatus.Active]: "Active",
                    [Contract.AwsEksFargateProfileStatus.CreateFailed]: "Create Failed",
                    [Contract.AwsEksFargateProfileStatus.Creating]: "Creating",
                    [Contract.AwsEksFargateProfileStatus.DeleteFailed]: "Delete Failed",
                    [Contract.AwsEksFargateProfileStatus.Deleting]: "Deleting"
                }
            }));
    return (status: Contract.AwsEksFargateProfileStatus) =>
        localization[Contract.TypeNames.AwsEksFargateProfileStatus][status]();
}