﻿import { Loading, Optional, useLocalization } from "@infrastructure";
import { Box } from "@mui/material";
import React from "react";
import { useGetAwsInboundExternalResourceRiskContext, useGetAwsRedshiftClusterRiskContext } from "..";
import { Contract, CustomerConsoleAppUrlHelper, Entity, entityModelStore, Network, tenantModelStore, useTheme } from "../../../../../../../../../../../../common";
import { ToolbarToggleFilterId } from "../../../../../../../../../../../../common/components/Network/components";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { RiskDefinitionSection } from "../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";

export function useAwsInboundExternalRedshiftClusterRiskDefinition(riskModel: Contract.RiskModel) {
    const inboundExternalRedshiftClusterRiskModel = riskModel as Contract.AwsInboundExternalRedshiftClusterRiskModel;
    const clusterModel = entityModelStore.useGet(inboundExternalRedshiftClusterRiskModel.risk.entityId) as Contract.AwsRedshiftClusterModel;
    const cluster = clusterModel.entity as Contract.AwsRedshiftCluster;
    const tenantConfiguration = tenantModelStore.useGet(riskModel.tenantId).configuration as Contract.AwsTenantConfiguration;
    const inboundAccessType = (clusterModel.entityNetwork as Optional<Contract.NetworkAccessResourceStateNetwork>)?.inboundAccessType;

    const getAwsInboundExternalResourceRiskContext = useGetAwsInboundExternalResourceRiskContext();
    const getAwsRedshiftClusterRiskContext = useGetAwsRedshiftClusterRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsInboundExternalRedshiftClusterRiskDefinition",
            () => ({
                description: {
                    externalDirect: "{{cluster}} is directly accessible from a wide range of public IP addresses",
                    externalIndirect: "{{cluster}} is indirectly accessible via a load balancer, from a wide range of public IP addresses"
                },
                sections: {
                    networkGraph: "Network Exposure",
                    resolution: {
                        directly: {
                            step1: "In the top right, click **Actions** and choose **Modify publicly accessible setting**",
                            step2: "Choose **Disable** and click **Save Changes**"
                        },
                        indirectly: {
                            step1: "For each exposing security group - click on **Edit inbound rules**",
                            step2: "Delete or restrict the source of the exposing rules based on the required access"
                        }
                    }
                }
            }));

    const theme = useTheme();
    return useCommonSectionsAndDescriptionDefinition(
        (inboundAccessType === Contract.NetworkInboundAccessType.ExternalDirect
            ? localization.description.externalDirect
            : localization.description.externalIndirect)({
            cluster:
                <Entity
                    entityIdOrModel={clusterModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () =>
            inboundAccessType === Contract.NetworkInboundAccessType.ExternalDirect
                ? [
                    consoleSignInStepTranslator(
                        Contract.AwsConsoleView.Redshift,
                        AwsConsoleUrlBuilder.getRedshiftClusterUrl(cluster)),
                    localization.sections.resolution.directly.step1(),
                    localization.sections.resolution.directly.step2()
                ]
                : [
                    consoleSignInStepTranslator(
                        Contract.AwsConsoleView.SecurityGroups,
                        AwsConsoleUrlBuilder.getEc2SecurityGroupsUrl(
                            cluster,
                            tenantConfiguration.partitionType)),
                    localization.sections.resolution.indirectly.step1(),
                    localization.sections.resolution.indirectly.step2()
                ],
        inboundExternalRedshiftClusterRiskModel,
        () => {
            const clusterRiskContext = getAwsRedshiftClusterRiskContext(clusterModel);
            const inboundExternalResourceRiskContext =
                getAwsInboundExternalResourceRiskContext(
                    clusterModel,
                    inboundExternalRedshiftClusterRiskModel);
            return [
                clusterRiskContext.generalInformation,
                clusterRiskContext.sensitive,
                clusterRiskContext.encryption,
                clusterRiskContext.storageSize,
                inboundExternalResourceRiskContext.inboundAccess,
                clusterRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.risk.id)];
        },
        {
            sections:
                !cluster.systemDeleted
                    ? [
                        new RiskDefinitionSection(
                            <Box sx={{ minHeight: theme.spacing(20) }}>
                                <Loading>
                                    <Network
                                        baseUrl={CustomerConsoleAppUrlHelper.getRiskProfileRelativeUrl(inboundExternalRedshiftClusterRiskModel.risk.id)}
                                        entityId={inboundExternalRedshiftClusterRiskModel.risk.entityId}
                                        initialFilterMap={{ [ToolbarToggleFilterId.WideRangeSourceSubnet]: true }}
                                        tenantType={Contract.TenantType.Aws}
                                        variant="risk"/>
                                </Loading>
                            </Box>,
                            localization.sections.networkGraph(),
                            {
                                profilePageOnly: true
                            })
                    ]
                    : undefined
        });
}