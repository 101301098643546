import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { useAzureCognitiveServicesAccountTypeTranslator } from "../../../../../../../../../Entities/hooks";
import { EntityExternalConsoleLink } from "../../components";
import { useGetAzureCognitiveServicesAccountRiskContext } from "../contexts";

export function useAzureCognitiveServicesAccountInboundExternalWideRangeRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AzureCognitiveServicesAccountInboundExternalWideRangeRisk;
    const accountModel = entityModelStore.useGet(risk.entityId) as Contract.AzureCognitiveServicesAccountModel;
    const account = accountModel.entity as Contract.AzureCognitiveServicesAccount;

    const getAzureCognitiveServicesAccountRiskContext = useGetAzureCognitiveServicesAccountRiskContext();

    const cognitiveServicesAccountTypeTranslator = useAzureCognitiveServicesAccountTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.compliance.useAzureCognitiveServicesAccountInboundExternalWideRangeRiskDefinition",
            () => ({
                description: "{{type}} {{account}} allows inbound network access from a wide range of public IP addresses",
                sections: {
                    resolution: {
                        step1: "In the **Firewalls and virtual networks** section, set **Allow access from** to **Selected Networks and Private Endpoints** or **Disabled**",
                        step2: "If **Networks and Private Endpoints** is selected, add firewall rules only to specific IP addresses and virtual networks that require access to the resource",
                        step3: "Click **Save**"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            account:
                <Entity
                    entityIdOrModel={accountModel}
                    variant="text"/>,
            type: cognitiveServicesAccountTypeTranslator(account.type)
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={risk.entityId}
                key={risk.entityId}
                page={Contract.AzureConsoleEntityPage.AccessControl}/>,
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3()
        ],
        riskModel,
        () => {
            const accountRiskContext = getAzureCognitiveServicesAccountRiskContext(accountModel);
            return [
                accountRiskContext.generalInformation,
                accountRiskContext.sensitive,
                accountRiskContext.type,
                accountRiskContext.inboundExternalAccessScope,
                accountRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}