﻿import { InlineItems, useLocalization } from "@infrastructure";
import React from "react";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../../../useCommonSectionsAndDescriptionDefinition";
import { EntityExternalConsoleLink } from "../../../../components";
import { useGetGcpComputeLoadBalancerRiskContext } from "../../../contexts";

export function useGcpComputeLoadBalancerInsecureSslPolicyRiskLoadBalancerDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.GcpComputeLoadBalancerInsecureSslPolicyRisk;
    const loadBalancerModel = entityModelStore.useGet(risk.entityId) as Contract.GcpComputeLoadBalancerModel;

    const getGcpComputeLoadBalancerRiskContext = useGetGcpComputeLoadBalancerRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.useGcpComputeLoadBalancerInsecureSslPolicyRiskDefinition.hooks.useGcpComputeLoadBalancerInsecureSslPolicyRiskLoadBalancerDefinition",
            () => ({
                contextItems: {
                    defaultSslPolicy: {
                        title: "The load balancer is configured with the default SSL policy that allows minimum TLS version 1.0 and {{weakCipherFeatures}}",
                        weakCipherFeatures: [
                            "1 weak cipher feature",
                            "{{count | NumberFormatter.humanize}} weak cipher features"
                        ]
                    }
                },
                description: "{{loadBalancer}} is using the default SSL policy that allows insecure communication protocols such as TLS 1.0 and 1.1",
                sections: {
                    resolution: {
                        step1: "Before enforcing minimum TLS version 1.2 and removing weak cipher features, verify that your client applications support it",
                        step2: "Expand the Frontend configurations and select a secured SSL policy or create a new one",
                        step3: "Click on **Done** and **Update**"
                    }
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            loadBalancer:
                <Entity
                    entityIdOrModel={loadBalancerModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={risk.entityId}
                key={risk.entityId}
                page={Contract.GcpConsolePage.Edit}/>,
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3()
        ],
        riskModel,
        () => {
            const loadBalancerContextItems = getGcpComputeLoadBalancerRiskContext(loadBalancerModel);
            return [
                loadBalancerContextItems.generalInformation,
                loadBalancerContextItems.sensitive,
                new RiskDefinitionContextItem(
                    localization.contextItems.defaultSslPolicy.title({
                        weakCipherFeatures:
                            <InlineItems
                                items={risk.weakCipherFeatures}
                                namePluralizer={localization.contextItems.defaultSslPolicy.weakCipherFeatures}
                                variant="itemCountAndType"/>
                    })),
                loadBalancerContextItems.type,
                loadBalancerContextItems.loadBalancingScheme,
                loadBalancerContextItems.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        });
}