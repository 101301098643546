﻿import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function UnitedArabEmiratesIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <g clipPath="url(#clip0_60203_5281)">
                <rect
                    fill="white"
                    height="16"
                    rx="2"
                    width="24"
                    y="4"/>
                <path
                    d="M24 4.0011H0V19.9649H24V4.0011Z"
                    fill="#00732F"/>
                <path
                    d="M24 9.32237H0V19.9649H24V9.32237Z"
                    fill="white"/>
                <path
                    d="M24 14.6436H0V19.9649H24V14.6436Z"
                    fill="black"/>
                <path
                    d="M6 4.0011H0V19.9649H6V4.0011Z"
                    fill="#FF0000"/>
            </g>
            <defs>
                <clipPath id="clip0_60203_5281">
                    <rect
                        fill="white"
                        height="16"
                        rx="2"
                        width="24"
                        y="4"/>
                </clipPath>
            </defs>
        </SvgIcon>);
}