import { NumberFormatter, useLocalization } from "@infrastructure";
import React from "react";
import { useGcpCommonEncryptedResourceInfoElements, useGcpDefaultResourceInfoItemElements, useGcpDefaultScopeResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../..";
import { useCustomEntityPropertyInfoItemElements } from "..";
import { Contract, InfoItem } from "../../../../../../../../../../common";
import { useGcpArtifactRegistryTypeTranslator } from "../../../../../../hooks";
import { EntitiesInfoCard, Info } from "../../../../components";
import { GcpScopeResourceRoleBindings } from "../../components";

export function useGcpArtifactRegistryDefinition(scopeResourceModel: Contract.GcpScopeResourceModel, options?: DefinitionOptions) {
    const commonEncryptedResourceInfoElements = useGcpCommonEncryptedResourceInfoElements(scopeResourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(scopeResourceModel);
    const defaultResourceInfoItemElements = useGcpDefaultResourceInfoItemElements(scopeResourceModel);
    const defaultScopeResourceInfoItemElements = useGcpDefaultScopeResourceInfoItemElements(scopeResourceModel);
    const registryModel = scopeResourceModel as Contract.GcpArtifactRegistryModel;
    const registry = registryModel.entity as Contract.GcpArtifactRegistry;
    const repositoryTypeName =
        registry.type === Contract.GcpArtifactRegistryType.ContainerImage
            ? Contract.TypeNames.GcpArtifactContainerImageRepository
            : Contract.TypeNames.GcpArtifactPackageRepository;

    const artifactRegistryTypeTranslator = useGcpArtifactRegistryTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpArtifactRegistryDefinition",
            () => ({
                info: {
                    items: {
                        artifactCount: "Artifact count",
                        artifactFindings: "Vulnerabilities",
                        description: "Description",
                        repositories: {
                            [Contract.TypeNames.GcpArtifactContainerImageRepository]: "Container Image Repositories",
                            [Contract.TypeNames.GcpArtifactPackageRepository]: "Package Repositories"
                        },
                        storageSize: "Size",
                        type: "Format"
                    }
                },
                tabs: {
                    resourceRoleBindings: "Resource Role Bindings"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <GcpScopeResourceRoleBindings
                    csvExportFilePrefix={localization.tabs.resourceRoleBindings()}
                    scopeResourceModel={registryModel}/>,
                localization.tabs.resourceRoleBindings(),
                "resourceRoleBindings")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    ...defaultScopeResourceInfoItemElements,
                    commonEncryptedResourceInfoElements.encryptionExists,
                    commonEncryptedResourceInfoElements.kmsEncryptionKeys,
                    <InfoItem
                        key="type"
                        title={localization.info.items.type()}
                        value={artifactRegistryTypeTranslator(registry.type)}/>,
                    <InfoItem
                        key="description"
                        title={localization.info.items.description()}
                        value={registry.description}/>,
                    <InfoItem
                        key="artifactCount"
                        location="all"
                        title={localization.info.items.artifactCount()}
                        value={registry.artifactCount}/>,
                    <InfoItem
                        key="storageSize"
                        title={localization.info.items.storageSize()}
                        value={NumberFormatter.storage(registry.storageSize)}/>
                ]}
                options={options?.infoOptions}>
                <EntitiesInfoCard
                    entityIdsOrModels={registryModel.repositoryIds}
                    entityTypeName={repositoryTypeName}
                    title={localization.info.items.repositories[repositoryTypeName]()}/>
            </Info>
    });
}