﻿import { DeleteIcon, useChangeEffect, useLocalization } from "@infrastructure";
import { Box, FormHelperText, IconButton, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import { InlineScopes, TextInput } from "../../../../../../../../../common";
import { CodeExclusionItem, useSetCodeExclusionsContext } from "./CodeExclusions";

const MaxMessageLength = 256;
const MaxPatternLength = 256;

type CodeExclusionProps = {
    item: CodeExclusionItem;
    parentScopeIds?: string[];
    readOnly: boolean;
};

export function CodeExclusion({ item, parentScopeIds, readOnly }: CodeExclusionProps) {
    const setContext = useSetCodeExclusionsContext();
    const initialValue =
        useMemo(
            () => item,
            []);
    const [message, setMessage] = useState(item.codeExclusion.data.message ?? "");
    const [fileRelativePathPattern, setFileRelativePathPattern] = useState(item.codeExclusion.fileRelativePathPattern ?? "");

    useChangeEffect(
        () => {
            setContext(
                context => {
                    const newCodeExclusion = _.cloneDeep(item.codeExclusion);
                    newCodeExclusion.data.message = message;
                    newCodeExclusion.fileRelativePathPattern = fileRelativePathPattern;

                    const updatedItem =
                        new CodeExclusionItem(
                            newCodeExclusion,
                            !_.isEqual(initialValue.codeExclusion.fileRelativePathPattern, newCodeExclusion.fileRelativePathPattern),
                            true,
                            false,
                            item.id,
                            false,
                            false);
                    const updatedItems =
                        item.placeholder
                            ? _.concat(
                                context.codeExclusionItems,
                                updatedItem)
                            : _.map(
                                context.codeExclusionItems,
                                codeExclusionItem =>
                                    codeExclusionItem.id == item.id
                                        ? updatedItem
                                        : codeExclusionItem);
                    const validatedUpdatedItems =
                        _.map(
                            updatedItems,
                            updatedItem =>
                                updatedItem.inherited
                                    ? updatedItem
                                    : new CodeExclusionItem(
                                        updatedItem.codeExclusion,
                                        updatedItem.changed,
                                        true,
                                        _.filter(
                                            updatedItems,
                                            item => item.codeExclusion.fileRelativePathPattern === updatedItem.codeExclusion.fileRelativePathPattern).length > 1,
                                        updatedItem.id,
                                        updatedItem.inherited,
                                        updatedItem.placeholder));
                    return {
                        codeExclusionItems: validatedUpdatedItems
                    };
                });
        },
        [message, fileRelativePathPattern],
        500);

    const onDelete =
        () => {
            setContext(
                context => {
                    const updatedItems =
                        _.filter(
                            context.codeExclusionItems,
                            codeExclusionItem => codeExclusionItem.id != item.id);
                    const validatedUpdatedItems =
                        _.map(
                            updatedItems,
                            updatedItem =>
                                updatedItem.inherited
                                    ? updatedItem
                                    : new CodeExclusionItem(
                                        updatedItem.codeExclusion,
                                        updatedItem.changed,
                                        true,
                                        _.filter(
                                            updatedItems,
                                            item => item.codeExclusion.fileRelativePathPattern === updatedItem.codeExclusion.fileRelativePathPattern).length > 1,
                                        updatedItem.id,
                                        updatedItem.inherited,
                                        updatedItem.placeholder));
                    return {
                        codeExclusionItems: validatedUpdatedItems
                    };
                });
        };

    const localization =
        useLocalization(
            "views.customer.riskPolicies.configuration.codeExclusion.codeExclusion",
            () => ({
                duplicate: "Duplicate value is not allowed",
                inherited: "Inherited from",
                message: "Comment",
                pattern: "File path pattern"
            }));

    return (
        <Stack sx={{ width: "100%" }}>
            <Stack
                direction="row"
                spacing={1}
                sx={{
                    alignItems: "center",
                    height: "100%"
                }}>
                <TextInput
                    disabled={item.inherited || readOnly}
                    formSx={{ width: "35%" }}
                    placeholder={localization.pattern()}
                    readOnly={item.inherited || readOnly}
                    text={fileRelativePathPattern}
                    onChange={fileRelativePathPattern => setFileRelativePathPattern(fileRelativePathPattern.substring(0, MaxPatternLength))}/>
                <TextInput
                    disabled={item.inherited || readOnly}
                    formSx={{ width: "35%" }}
                    placeholder={localization.message()}
                    readOnly={item.inherited || readOnly}
                    text={message}
                    onChange={message => setMessage(message.substring(0, MaxMessageLength))}/>
                {item.inherited && (
                    <Stack>
                        <Typography>
                            {localization.inherited()}
                        </Typography>
                        <Box>
                            <InlineScopes
                                scopeIds={parentScopeIds!}
                                scopeVariant="iconText"
                                variant="itemCountAndType"/>
                        </Box>
                    </Stack>)}
                {(item.inherited || readOnly || item.placeholder)
                    ? undefined
                    : <Stack
                        alignItems="center"
                        direction="row"
                        spacing={1}>
                        <IconButton
                            size="small"
                            onClick={onDelete}>
                            <DeleteIcon/>
                        </IconButton>
                    </Stack>}
            </Stack>
            <Stack direction="row">
                {(item.duplicate && item.isValid())
                    ? <Stack direction="row">
                        <FormHelperText error={true}>{localization.duplicate()}</FormHelperText>
                    </Stack>
                    : undefined}
            </Stack>
        </Stack>);
}