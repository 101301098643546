﻿import { Box, Stack } from "@mui/material";
import _ from "lodash";
import React, { useRef } from "react";
import { Contract } from "../../../../../../../../../../../../../../../../common";
import { Item } from "./components";

type HttpHeadersProps = {
    items: HttpHeadersItem[];
    onItemsChanged: (items: HttpHeadersItem[]) => void;
    onValidChanged: (valid: boolean) => void;
};

export function HttpHeaders({ items, onItemsChanged, onValidChanged }: HttpHeadersProps) {
    const validationsRef =
        useRef(
            _.map(
                items,
                () => true));
    return (
        <Stack spacing={2}>
            {_(items).
                concatIf(
                    items.length < 20,
                    new HttpHeadersItem(
                        new Contract.WebhookManagerHttpHeader(
                            false,
                            undefined,
                            "",
                            undefined))).
                map(
                    (item, itemIndex) =>
                        <Box key={item.id}>
                            {itemIndex < items.length || items.length === 20
                                ? <Item
                                    item={item}
                                    onDelete={
                                        () => {
                                            onItemsChanged(_.without(items, item));
                                            onValidChanged(
                                                _(validationsRef.current).
                                                    drop(1).
                                                    every());
                                        }}
                                    onItemChanged={
                                        newItem => {
                                            const newItems = _.cloneDeep(items);
                                            newItems.splice(itemIndex, 1, newItem);
                                            onItemsChanged(newItems);
                                        }}
                                    onValidChanged={
                                        valid => {
                                            validationsRef.current[itemIndex] = valid;
                                            onValidChanged(_.every(validationsRef.current));
                                        }}/>
                                : <Item
                                    item={item}
                                    onItemChanged={
                                        newItem => {
                                            const newItems = _.cloneDeep(items);
                                            newItems.splice(itemIndex, 1, newItem);
                                            onItemsChanged(newItems);
                                        }}/>}
                        </Box>).
                value()}
        </Stack>);
}

export class HttpHeadersItem {
    private static _idCounter = 0;
    public id: number;

    constructor(
        public httpHeader: Contract.WebhookManagerHttpHeader,
        id?: number) {
        this.id = id ?? HttpHeadersItem._idCounter++;
    }
}