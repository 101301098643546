﻿import { EnumValuesFilter } from "@infrastructure";
import React from "react";
import { Contract } from "../../../../../../../../../../../../common";
import { useAwsCloudTrailTrailEventSelectorTypeTranslator } from "../../../../../../../../../../../../tenants";

type AwsCloudTrailTrailEventSelectorTypeFilterProps = {
    placeholder: string;
};

export function AwsCloudTrailTrailEventSelectorTypeFilter({ placeholder }: AwsCloudTrailTrailEventSelectorTypeFilterProps) {
    const cloudTrailTrailEventSelectorTypeTranslator = useAwsCloudTrailTrailEventSelectorTypeTranslator();
    return (
        <EnumValuesFilter
            emptyValueOptions={{
                enabled: true,
                title: cloudTrailTrailEventSelectorTypeTranslator(undefined)
            }}
            enumType={Contract.AwsCloudTrailTrailEventSelectorType}
            enumTypeTranslator={cloudTrailTrailEventSelectorTypeTranslator}
            placeholder={placeholder}
            sorted={false}/>);
}