import { NoneIcon } from "@infrastructure";
import { Stack, SxProps } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract } from "../controllers";
import { useTheme } from "../themes";
import { TypeHelper } from "../utilities";
import { DataSensitivity } from "./DataSensitivity";

export type DataSensitivitiesProps = {
    countLoading?: boolean;
    getSensitivityActive?: (sensitivity: Contract.DataSensitivity) => boolean | undefined;
    onClick?: (category: Contract.DataSensitivity) => void;
    sensitivities: Contract.DataSensitivity[];
    sensitivityToCountMap?: { [key in Contract.DataSensitivity]?: number };
    sx?: SxProps;
};

export function DataSensitivities({ countLoading, getSensitivityActive, onClick, sensitivities, sensitivityToCountMap, sx }: DataSensitivitiesProps) {
    const orderedSensitivities =
        useMemo(
            () =>
                _.orderBy(
                    sensitivities,
                    dataSensitivity => TypeHelper.getEnumValue(Contract.TypeNames.DataSensitivity, dataSensitivity),
                    "desc"),
            [sensitivities]);

    const theme = useTheme();
    return _.isEmpty(sensitivities)
        ? <NoneIcon sx={{ color: theme.border.primary }}/>
        : <Stack
            direction="row"
            flexWrap="wrap"
            gap={1}
            sx={sx}>
            {_.map(
                orderedSensitivities,
                sensitivity =>
                    <DataSensitivity
                        active={getSensitivityActive?.(sensitivity)}
                        count={sensitivityToCountMap?.[sensitivity]}
                        countLoading={countLoading}
                        key={sensitivity}
                        sensitivity={sensitivity}
                        onClick={onClick}/>)}
        </Stack>;
}

export const dataSensitivities = _.keys(Contract.DataSensitivity) as Contract.DataSensitivity[];