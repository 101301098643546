import { PopperPlacementType, TextField } from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import _ from "lodash";
import moment from "moment";
import React from "react";

type DateTimeFieldProps = {
    disabled?: boolean;
    disableOpenPicker?: boolean;
    minDateTime?: moment.Moment;
    onChange?: (dateTime?: string) => void;
    popperPlacement?: PopperPlacementType;
    time?: string;
    title: string;
};

export function DateTimeField({ disabled, disableOpenPicker = true, minDateTime, onChange, popperPlacement, time, title }: DateTimeFieldProps) {
    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <DateTimePicker
                disabled={disabled}
                disableOpenPicker={disableOpenPicker}
                minDateTime={minDateTime}
                slotProps={{
                    popper: { placement: popperPlacement },
                    textField:
                        params => (
                            <TextField
                                {...params}
                                fullWidth={true}
                                label={title}
                                variant="outlined"/>)
                }}
                value={
                    _.isNil(time)
                        ? null
                        : moment(time)}
                onChange={timeMoment => onChange?.(timeMoment?.toISOString())}/>
        </LocalizationProvider>);
}