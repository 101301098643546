import { GuidParser, Message, useInputValidation, useLocalization } from "@infrastructure";
import { FormHelperText, Stack, TextField, Typography } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { RadioGroup, useTheme } from "../../../../../../../../../../../../../../../common";

type ConsentApplicationProps = {
    onTenantRawIdChanged: (tenantRawId?: string) => void;
    onValidChanged: (valid: boolean) => void;
    tenantRawId?: string;
};

export function ConsentApplication({ onTenantRawIdChanged, onValidChanged, tenantRawId }: ConsentApplicationProps) {
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useIdentityProviderTenantItems.aadTenants.addOrEdit.consentApplication",
            () => ({
                subtitle: "Granting admin consent requires you to sign in as a user that is authorized to consent on behalf of the organization. Once the consent is approved, you’ll be redirected back to Tenable Cloud Security to finish the set up.",
                tenant: {
                    default: "I want to add the Microsoft Entra ID tenant that my user belongs to",
                    notDefault: {
                        field: {
                            error: {
                                format: "Tenant ID must be a valid GUID",
                                required: "Tenant ID cannot be empty"
                            },
                            title: "Tenant Directory ID"
                        },
                        helpText: "Your user must have the Global Administrator role in this Microsoft Entra ID tenant",
                        title: "I want to add another Microsoft Entra ID tenant"
                    }
                },
                title: "Grant Admin Consent"
            }));

    const [type, setType] = useState(ConsentItemType.Default);

    const [tenantRawIdValidationController, tenantRawIdValidationMessage] =
        useInputValidation(
            () => {
                const validationTenantRawId = _.trim(tenantRawId);
                if (_.isEmpty(validationTenantRawId)) {
                    return localization.tenant.notDefault.field.error.required();
                }

                if (!GuidParser.validate(validationTenantRawId)) {
                    return localization.tenant.notDefault.field.error.format();
                }

                return undefined;
            },
            [tenantRawId]);

    useEffect(
        () => {
            onValidChanged(
                type == ConsentItemType.Default ||
                tenantRawIdValidationController.isValid());
        },
        [type, tenantRawId]);

    const theme = useTheme();
    return (
        <Stack spacing={1}>
            <RadioGroup
                description={
                    <Stack
                        direction="row"
                        spacing={theme.spacing(1)}>
                        <Typography>{localization.title()}</Typography>
                        <Message
                            level="info"
                            title={localization.subtitle()}
                            variant="minimal"/>
                    </Stack>
                }
                items={[
                    {
                        label: localization.tenant.default(),
                        value: ConsentItemType.Default
                    },
                    {
                        children:
                            <Stack>
                                <TextField
                                    label={localization.tenant.notDefault.field.title()}
                                    sx={{
                                        maxWidth: theme.spacing(60),
                                        minWidth: theme.spacing(20)
                                    }}
                                    value={tenantRawId}
                                    variant="outlined"
                                    onChange={event => onTenantRawIdChanged(event.target.value)}/>
                                {!_.isNil(tenantRawIdValidationMessage) &&
                                    <FormHelperText error={true}>{tenantRawIdValidationMessage}</FormHelperText>}
                            </Stack>,
                        label:
                            <Stack
                                direction="row"
                                spacing={theme.spacing(1)}>
                                <Typography>{localization.tenant.notDefault.title()}</Typography>
                                <Message
                                    level="info"
                                    title={localization.tenant.notDefault.helpText()}
                                    variant="minimal"/>
                            </Stack>,
                        value: ConsentItemType.NotDefault
                    }
                ]}
                selectedValue={type}
                tooltip={localization.subtitle()}
                onChange={
                    type => {
                        onTenantRawIdChanged(undefined);
                        setType(type);
                    }}/>
        </Stack>);
}

enum ConsentItemType {
    Default,
    NotDefault
}