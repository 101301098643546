﻿import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, DeferredFilter, EnumValuesFilter, Link, optionalTableCell, TimeFormatter, TimeRangeFilter, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, CustomerConsoleAppUrlHelper, TimeRangeHelper } from "../../../../../../../../../common";
import { useSystemKubernetesClusterApiConnectivityStatusTranslator } from "../../../../../../../../../tenants";
import { KubernetesClusterApiConnectivityStatus } from "../../../../KubernetesClusterApiConnectivityStatus";
import { DefinitionData } from "../../../Table";

export function useCommonKubernetesClusterDefinition(definitionData: DefinitionData) {
    const kubernetesClusterModelFiltersPromise = definitionData.entityModelFiltersPromise as Promise<Contract.IKubernetesClusterModelFilters<Contract.IKubernetesClusterModelFiltersSection>>;
    const systemKubernetesClusterApiConnectivityStatusTranslator = useSystemKubernetesClusterApiConnectivityStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.useCommonKubernetesClusterDefinition",
            () => ({
                columns: {
                    apiConnectivityStatus: {
                        info: {
                            link: "documentation",
                            title: "Indicates whether Tenable Cloud Security can connect to the Kubernetes API. If the network is disconnected, or there are missing permissions, follow the onboarding instructions in the {{link}}"
                        },
                        title: "Resource Sync Status"
                    },
                    resourceUpdateTime: "Resource Sync Time"
                }
            }));

    return {
        columns: {
            createApiConnectivityStatusColumn:
                (documentationKubernetesOnboardClusterUrl: string) =>
                    <DataTableColumn
                        exportOptions={{
                            getItem:
                                (item: Contract.IKubernetesClusterModel) => ({
                                    [localization.columns.apiConnectivityStatus.title()]:
                                        _.isNil(item.apiConnectivityStatus)
                                            ? ""
                                            : systemKubernetesClusterApiConnectivityStatusTranslator(item.apiConnectivityStatus)
                                })
                        }}
                        filterOptions={{
                            itemOrItems: {
                                element:
                                    <EnumValuesFilter
                                        enumType={Contract.SystemKubernetesClusterApiConnectivityStatus}
                                        enumTypeTranslator={(status: Contract.SystemKubernetesClusterApiConnectivityStatus) => systemKubernetesClusterApiConnectivityStatusTranslator(status)}
                                        placeholder={localization.columns.apiConnectivityStatus.title()}/>
                            }
                        }}
                        id={Contract.EntityModelProperty.KubernetesClusterApiConnectivityStatus}
                        key={Contract.EntityModelProperty.KubernetesClusterApiConnectivityStatus}
                        message={
                            localization.columns.apiConnectivityStatus.info.title({
                                link:
                                    <Link
                                        urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsKubernetesRelativeUrl()}
                                        variant="external">
                                        {localization.columns.apiConnectivityStatus.info.link()}
                                    </Link>
                            })}
                        messageLevel="info"
                        render={
                            ({ item }: DataTableColumnRenderProps<Contract.IKubernetesClusterModel>) =>
                                <KubernetesClusterApiConnectivityStatus
                                    apiConnectivityStatus={item.apiConnectivityStatus}
                                    connector={item.helm.connector}
                                    documentationKubernetesOnboardClusterUrl={documentationKubernetesOnboardClusterUrl}/>}
                        title={localization.columns.apiConnectivityStatus.title()}/>,
            resourceUpdateTimeColumn:
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.IKubernetesClusterModel) => ({
                                [localization.columns.resourceUpdateTime()]:
                                    _.isNil(item.resourceUpdateTime)
                                        ? undefined
                                        : TimeFormatter.iso8601String(item.resourceUpdateTime)
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <DeferredFilter
                                    promiseOrGetPromise={kubernetesClusterModelFiltersPromise}
                                    title={localization.columns.resourceUpdateTime()}>
                                    {filters =>
                                        <TimeRangeFilter
                                            emptyValue={filters.kubernetesCluster.resourceUpdateTimeRange.emptyValue}
                                            placeholder={localization.columns.resourceUpdateTime()}
                                            timeRange={TimeRangeHelper.getTimeRangeFilterRange(filters.kubernetesCluster.resourceUpdateTimeRange.timeRange)}/>}
                                </DeferredFilter>
                        }
                    }}
                    id={Contract.EntityModelProperty.KubernetesClusterResourceUpdateTime}
                    key={Contract.EntityModelProperty.KubernetesClusterResourceUpdateTime}
                    render={
                        optionalTableCell<Contract.IKubernetesClusterModel>(
                            item =>
                                _.isNil(item.resourceUpdateTime)
                                    ? undefined
                                    : TimeFormatter.humanizePastDuration(item.resourceUpdateTime))}
                    sortOptions={{ type: DataTableSortType.Date }}
                    title={localization.columns.resourceUpdateTime()}/>
        }
    };
}