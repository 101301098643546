import { Dictionary } from "lodash";
import React, { ReactNode, useLayoutEffect } from "react";

type MailProps = {
    backgroundColor: string;
    children: ReactNode;
    title: string;
};

export function Mail({ backgroundColor, children, title }: MailProps) {
    useLayoutEffect(
        () => {
            (document.body as any).bgcolor = backgroundColor;
            document.head.innerHTML = `
                <title>${title}</title>
                <meta
                    content="text/html; charset=utf-8"
                    http-equiv="Content-Type" />
                <meta
                    content="width=device-width, initial-scale=1"
                    name="viewport" />
                <meta
                    content="IE=edge"
                    http-equiv="X-UA-Compatible" />
                <style type="text/css">
                    body {
                        background-color: ${backgroundColor};
                        margin: 0;
                        padding: 0;
                        width: 100%;
                    }

                    img {
                        display: block;
                        margin: 0;
                    }

                    table {
                        border-collapse: collapse;
                    }
                </style>`;
        },
        []);
    return (
        <MailTable
            bgcolor={backgroundColor}
            border="0"
            cellPadding="0"
            cellSpacing="0"
            width="100%">
            {children}
        </MailTable>);
}

export function MailHr(props: Dictionary<any>) {
    return <hr {...props}/>;
}

export function MailImg(props: Dictionary<any>) {
    return <img {...props}/>;
}

export function MailTable(props: Dictionary<any>) {
    return <table {...props}/>;
}

export function MailTd(props: Dictionary<any>) {
    return <td {...props}/>;
}