import { MouseHelper, useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import React, { ReactNode } from "react";
import { Contract } from "../..";
import { CustomerConsoleAppUrlHelper, UserHelper } from "../../utilities";

type SupportFrameProps = {
    children?: ReactNode;
};

export function SupportFrame({ children }: SupportFrameProps) {
    const localization =
        useLocalization(
            "common.supportFrame",
            () => ({
                title: "Viewing as {{mail}}"
            }));

    return (
        <Stack
            sx={{
                height: "100%",
                width: "100%"
            }}>
            {UserHelper.support &&
                <Stack
                    sx={{
                        alignItems: "center",
                        backgroundColor: "#f62a36",
                        height: frameHeight,
                        justifyContent: "center",
                        width: "100%"
                    }}
                    onClick={
                        event => {
                            if (MouseHelper.isAlterActionClick(event) &&
                                UserHelper.hasAnyCustomerPermissions(Contract.IdentityPermission.SecurityAdministrationWrite)) {
                                window.location.pathname = CustomerConsoleAppUrlHelper.getAdministrationRelativeUrl();
                            }
                        }}>
                    <Typography
                        sx={{
                            color: "white",
                            fontSize: "14px"
                        }}>
                        {localization.title({ mail: UserHelper.mail })}
                    </Typography>
                </Stack>}
            <Stack
                sx={{
                    height:
                        UserHelper.support
                            ? `calc(100% - ${frameHeight})`
                            : "100%",
                    position: "relative"
                }}>
                {children}
            </Stack>
        </Stack>);
}

const frameHeight = "24px";