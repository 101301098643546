import { Action1, ItemSelector, TimeFormatter, useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";

type DailyProps = {
    hour: number;
    onChange: Action1<number>;
};

export function Daily({ hour, onChange }: DailyProps) {
    const localization =
        useLocalization(
            "common.report.daily",
            () => ({
                at: "At",
                utc: "UTC"
            }));
    return (
        <Stack
            alignItems="center"
            direction="row"
            spacing={1}>
            <Typography>
                {localization.at()}
            </Typography>
            <ItemSelector
                fieldVariant="underline"
                items={_.range(0, 24)}
                selectedItem={hour}
                sorted={false}
                onSelectedItemChanged={hour => onChange(hour)}>
                {hour =>
                    <Typography
                        noWrap={true}
                        sx={{ width: "100%" }}>
                        {TimeFormatter.hour(hour)}
                    </Typography>}
            </ItemSelector>
            <Typography>
                {localization.utc()}
            </Typography>
        </Stack>);
}