import { TimeFormatter, useExecuteOperation, useLocalization } from "@infrastructure";
import { Divider } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { Fragment } from "react";
import { auditEventModelStore, AuditEventsIcon, Contract, entityModelStore, InfoCard, InfoItem, ProfileLayout, ProfileTopbar, RiskController, Scope, TypeHelper, useTheme } from "../../../../../../common";
import { useAuditEventTypeNameTranslator } from "../../hooks";
import { AuditEventDefinitionContext, useDefinition } from "./hooks";

export type ProfileProps = {
    eventId: string;
};

export function Profile({ eventId }: ProfileProps) {
    const auditEventModel = auditEventModelStore.useGet(eventId);

    const [definitionContext] =
        useExecuteOperation(
            [Profile, eventId],
            async () => {
                const riskIds =
                    _<string>([]).
                        concatIf(
                            TypeHelper.extendOrImplement(auditEventModel.auditEvent.typeName, Contract.TypeNames.RiskAuditEvent),
                            () => (auditEventModel.auditEvent as Contract.RiskAuditEvent).riskId).
                        concatIf(
                            TypeHelper.extendOrImplement(auditEventModel.auditEvent.typeName, Contract.TypeNames.RisksAuditEvent) && (auditEventModel.auditEvent as Contract.RisksAuditEvent).riskIds.length === 1,
                            () => (auditEventModel.auditEvent as Contract.RisksAuditEvent)?.riskIds).
                        value();

                let riskModelMap: Dictionary<Contract.RiskModel> = {};
                if (!_.isEmpty(riskIds)) {
                    const { riskModels } = await RiskController.getRiskModels(new Contract.RiskControllerGetRiskModelsRequest(riskIds));
                    riskModelMap =
                        _.keyBy(
                            riskModels,
                            riskModel => riskModel.risk.id);
                }

                const entityIds =
                    _(riskModelMap).
                        flatMap(riskModel => riskModel.risk.riskedEntityIds).
                        concat(auditEventModel.entityIds).
                        uniq().
                        value();

                let entityModelMap: Dictionary<Contract.EntityModel> = {};
                if (!_.isEmpty(entityIds)) {
                    const entityModels = await entityModelStore.get(entityIds);
                    entityModelMap =
                        _.keyBy(
                            entityModels,
                            entityModel => entityModel.entity.id);
                }

                return new AuditEventDefinitionContext(
                    auditEventModel,
                    entityModelMap,
                    riskModelMap);
            });

    const definition = useDefinition(definitionContext);
    const auditEventTypeNameTranslator = useAuditEventTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.auditEvents.profile",
            () => ({
                details: "Details",
                errorMessage: {
                    failure: "Failure",
                    success: "Success",
                    title: "Result"
                },
                identityReference: "User",
                overview: "Overview",
                scopeId: "Scope",
                time: "Time",
                title: "Audit Event",
                typeName: "Action"
            }));

    const theme = useTheme();
    return (
        <ProfileLayout
            topBarElement={
                <ProfileTopbar
                    icon={<AuditEventsIcon/>}
                    title={localization.title()}/>}>
            <InfoCard containerSx={{ padding: theme.spacing(2, 3, 0, 3) }}>
                <InfoCard columns={true}>
                    <InfoItem
                        title={localization.time()}
                        value={TimeFormatter.profileFormatDateTime(auditEventModel.auditEvent.systemCreationTime)}/>
                    <InfoItem
                        title={localization.typeName()}
                        value={auditEventTypeNameTranslator(auditEventModel.auditEvent.typeName)}/>
                    <InfoItem
                        title={localization.scopeId()}
                        value={
                            <Scope
                                scopeId={auditEventModel.auditEvent.scopeId}
                                scopeNameTranslatorOptions={{ path: true }}
                                sx={{ width: "100%" }}
                                variant="iconText"/>}/>
                    <InfoItem
                        title={localization.identityReference()}
                        value={auditEventModel.auditEvent.identityReference.displayName}/>
                    <InfoItem
                        title={localization.errorMessage.title()}
                        value={
                            _.isNil(auditEventModel.auditEvent.errorMessage)
                                ? localization.errorMessage.success()
                                : localization.errorMessage.failure()}/>
                </InfoCard>
                {!_.isEmpty(definition.detailItems) && <Divider/>}
                {_.isEmpty(definition.detailItems)
                    ? undefined
                    : <InfoCard
                        columns={true}
                        title={localization.details()}>
                        {_.map(
                            definition.detailItems,
                            (detailItem, detailItemIndex) =>
                                <Fragment key={detailItemIndex}>
                                    {_.isNil(detailItem.valueElement)
                                        ? <InfoItem
                                            title={detailItem.title}
                                            value={detailItem.valueText}/>
                                        : <InfoItem
                                            title={detailItem.title}
                                            value={detailItem.valueElement}/>}
                                </Fragment>)}
                    </InfoCard>}
            </InfoCard>
        </ProfileLayout>);
}