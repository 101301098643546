import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, Entity, entityModelStore, useTheme } from "../../../../../../../../../../../../common";
import { OciNetworkingNetworkSecurityGroupRulesTable, useOciConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { RiskDefinitionSection } from "../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { useGetOciResourceRiskContext } from "../contexts/useGetOciResourceRiskContext";


export function useOciNetworkingNetworkSecurityGroupInboundRuleSubnetAnyExistsRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.OciNetworkingNetworkSecurityGroupInboundRuleSubnetAnyExistsRisk;
    const networkSecurityGroupModel = entityModelStore.useGet(risk.entityId) as Contract.OciNetworkingNetworkSecurityGroupModel;
    const networkSecurityGroup = networkSecurityGroupModel.entity as Contract.OciNetworkingNetworkSecurityGroup;

    const getOciResourceRiskContext = useGetOciResourceRiskContext();

    const consoleSignInStepTranslator = useOciConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.oci.hooks.compliance.useOciNetworkingNetworkSecurityGroupInboundRuleSubnetAnyExistsRiskDefinition",
            () => ({
                description: "{{networkSecurityGroup}} allows traffic from any IP address",
                sections: {
                    inboundRules: "Inbound Rules",
                    resolution: {
                        step1: "For each relevant ingress rule, edit the **Source CIDR** to allow only the necessary IP addresses, or delete the rule if access is not needed",
                        step2: "Click **Save changes**"
                    }
                }
            }));

    const theme = useTheme();
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            networkSecurityGroup:
                <Entity
                    entityIdOrModel={networkSecurityGroupModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () =>
            [
                consoleSignInStepTranslator(
                    Contract.OciConsoleView.Networking,
                    networkSecurityGroupModel.consoleUrl!),
                localization.sections.resolution.step1(),
                localization.sections.resolution.step2()
            ],
        riskModel,
        () => {
            const ociResourceRiskContext = getOciResourceRiskContext(networkSecurityGroupModel);
            return [
                ociResourceRiskContext.generalInformation,
                ociResourceRiskContext.sensitive,
                ociResourceRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        },
        {
            sections: [
                new RiskDefinitionSection(
                    <OciNetworkingNetworkSecurityGroupRulesTable
                        getHighlightColor={
                            (rule, opacity) =>
                                _.some(
                                    risk.unrestrictedInboundRules,
                                    unrestrictedInboundRule =>
                                        _.isEqual(
                                            unrestrictedInboundRule,
                                            rule))
                                    ? opacity
                                        ? theme.palette.opacity(theme.palette.severity(risk.severity), opacity)
                                        : theme.palette.severity(risk.severity)
                                    : undefined
                        }
                        inbound={true}
                        networkSecurityGroupOcidToEntityIdReferenceMap={networkSecurityGroupModel.networkSecurityGroupOcidToEntityIdReferenceMap}
                        rules={networkSecurityGroup.inboundRules}/>,
                    localization.sections.inboundRules(),
                    {
                        expandable: true
                    })
            ]
        });
}