import { Link, useLocalization } from "@infrastructure";
import { Box, Typography, useTheme } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { AuthenticationHelper, ConsoleAppUrlHelper, Contract, UserHelper } from "../../../../../../../../../common";
import { AuthenticationItem } from "../../../Authentication";

type OpenIdConnectProps = {
    selectedItem: AuthenticationItem;
};

export function OpenIdConnect({ selectedItem }: OpenIdConnectProps) {
    const localization =
        useLocalization(
            "views.customer.configuration.authentication.addOrEdit.openIdConnect",
            () => ({
                link: [
                    "link",
                    "links"
                ],
                microsoft: {
                    click: "click here",
                    [Contract.ConsoleAppType.Customer]: "Cloud Security Portal",
                    [Contract.ConsoleAppType.User]: "JIT Portal"
                },
                types: {
                    [Contract.TenantType.Azure]: {
                        text: "Permissions are required to enable Tenable Cloud Security login via Microsoft. Grant access using the relevant {{link}}",
                        title: "Enable Login via Microsoft"
                    },
                    [Contract.TenantType.Gcp]: {
                        text: "Click **Add** to enable login to Tenable Cloud Security via Google.",
                        title: "Enable Login via Google"
                    }
                }
            }));

    const identityProviderType = selectedItem.identityProviderType as Contract.TenantType.Azure | Contract.TenantType.Gcp;

    const consoleAppTypes =
        useMemo(
            () =>
                _<Contract.ConsoleAppType.Customer | Contract.ConsoleAppType.User>([]).
                    concatIf(
                        identityProviderType === Contract.TenantType.Azure,
                        Contract.ConsoleAppType.Customer).
                    concatIf(
                        identityProviderType === Contract.TenantType.Azure && _.includes(UserHelper.enabledConsoleAppTypes, Contract.ConsoleAppType.User),
                        Contract.ConsoleAppType.User).
                    value(),
            []);

    const theme = useTheme();
    return (
        <Box>
            <Typography variant="h4">
                {localization.types[identityProviderType].title()}
            </Typography>
            <Typography
                component="div"
                sx={{ marginTop: theme.spacing(1) }}>
                {localization.types[identityProviderType].text({ link: localization.link(_.size(consoleAppTypes)) })}
            </Typography>
            <Box>
                {_.map(
                    consoleAppTypes,
                    consoleAppType =>
                        <Typography key={consoleAppType}>
                            <Link
                                urlOrGetUrl={`https://login.microsoftonline.com/common/adminconsent?client_id=${AuthenticationHelper.getAuthenticationClientId(consoleAppType)}&redirect_uri=${ConsoleAppUrlHelper.url}`}
                                variant="external">
                                {localization.microsoft[consoleAppType]()}
                            </Link>
                        </Typography>)}
            </Box>
        </Box>);
}