﻿import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useAwsEc2VpcEndpointTypeTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useAwsEc2VpcEndpointTypeTranslator",
            () => ({
                [Contract.TypeNames.AwsEc2VpcEndpointType]: {
                    [Contract.AwsEc2VpcEndpointType.Gateway]: "Gateway",
                    [Contract.AwsEc2VpcEndpointType.GatewayLoadBalancer]: "Gateway Load Balancer",
                    [Contract.AwsEc2VpcEndpointType.Interface]: "Interface",
                    [Contract.AwsEc2VpcEndpointType.Resource]: "Resource",
                    [Contract.AwsEc2VpcEndpointType.ServiceNetwork]: "Service Network"
                }
            }));
    return (type: Contract.AwsEc2VpcEndpointType) =>
        localization[Contract.TypeNames.AwsEc2VpcEndpointType][type]();
}