import { Link, Step, Steps, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { EntityExternalConsoleLink } from "../../components";
import { useAzureEntityDefinition } from "../../components/EntityExternalConsoleLink/hooks/useDefinition/hooks";
import { useGetAzureSqlServerRiskContext } from "../contexts";


export function useAzureSqlServerAadOnlyAuthenticationDisabledRiskDefinition(riskModel: Contract.RiskModel) {
    const serverAadOnlyAuthenticationDisabledRiskModel = riskModel as Contract.AzureSqlServerAadOnlyAuthenticationDisabledRiskModel;
    const risk = riskModel.risk as Contract.AzureSqlServerAadOnlyAuthenticationDisabledRisk;
    const serverModel = entityModelStore.useGet(risk.entityId) as Contract.AzureSqlServerModel;
    const server = serverModel.entity as Contract.AzureSqlServer;

    const getAzureSqlServerRiskContext = useGetAzureSqlServerRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.compliance.useAzureSqlServerAadOnlyAuthenticationDisabledRiskDefinition",
            () => ({
                description: "{{server}} authentication method is not Microsoft Entra ID-only",
                sections: {
                    resolution: {
                        step1: {
                            step1: "Click **Add**, and choose **Add role assignment**",
                            step2: "Search for and select the **SQL Security Manager** role, and then click **Next**",
                            step3: "In the Members tab, make sure that **Assign access to** is set to **User, group, or service principal**",
                            step4: "Next to Members, click **Select members**",
                            step5: "In the right-hand pane, choose the member you want to use to change the authentication mode of the SQL server, and click **Select**",
                            step6: "Click **Review + assign** to confirm",
                            title: "Assign a role to enable Microsoft Entra ID-only authentication"
                        },
                        step2: {
                            link: "Tutorial: Set up Microsoft Entra authentication for SQL Server",
                            text: "Before disabling SQL authentication, verify that doing so will not break any existing applications. For more information, see {{documentationLink}}"
                        },
                        step3: {
                            step1: {
                                link: "Microsoft Entra ID page",
                                text: "Sign in to the Azure portal with the user who you assigned the **SQL Security Manager** role in the previous step, and then open the {{activeDirectoryAdminLink}} for the SQL server"
                            },
                            step2: "Click **Set admin**, and then choose a user to be the admin",
                            step3: "Mark the **Support only Microsoft Entra authentication for this server** checkbox",
                            step4: "The **Enable Microsoft Entra-only authentication** popup will display. Click **Yes** to enable the feature",
                            step5: "Click **Save** on the top right to confirm the changes",
                            title: "Enable Microsoft Entra ID-only authentication for the SQL server"
                        }
                    }
                }
            }));

    const { url: activeDirectoryAdminLink } = useAzureEntityDefinition(serverModel, Contract.AzureConsoleEntityPage.ActiveDirectoryAdmin);
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            server:
                <Entity
                    entityIdOrModel={serverModel}
                    variant="typeText"/>
        }),
        () => [
            new Step(
                localization.sections.resolution.step1.title(),
                {
                    contentElement:
                        <Steps variant="plainNumbers">
                            <EntityExternalConsoleLink
                                entityId={risk.entityId}
                                page={Contract.AzureConsoleEntityPage.RoleAssignments}/>
                            {localization.sections.resolution.step1.step1()}
                            {localization.sections.resolution.step1.step2()}
                            {localization.sections.resolution.step1.step3()}
                            {localization.sections.resolution.step1.step4()}
                            {localization.sections.resolution.step1.step5()}
                            {localization.sections.resolution.step1.step6()}
                        </Steps>
                }),
            localization.sections.resolution.step2.text({
                documentationLink:
                    <Link
                        urlOrGetUrl={serverAadOnlyAuthenticationDisabledRiskModel.aadAuthenticationDocumentationUrl}
                        variant="external">
                        {localization.sections.resolution.step2.link()}
                    </Link>
            }),
            new Step(
                localization.sections.resolution.step3.title(),
                {
                    contentElement:
                        <Steps variant="plainNumbers">
                            {_.filter([
                                localization.sections.resolution.step3.step1.text(
                                    {
                                        activeDirectoryAdminLink:
                                            <Link
                                                urlOrGetUrl={activeDirectoryAdminLink}
                                                variant="external">
                                                {localization.sections.resolution.step3.step1.link()}
                                            </Link>
                                    }
                                ),
                                _.isNil(server.aadAuthenticationAdministratorPrincipalIdReference)
                                    ? localization.sections.resolution.step3.step2()
                                    : undefined,
                                localization.sections.resolution.step3.step3(),
                                localization.sections.resolution.step3.step4(),
                                localization.sections.resolution.step3.step5()
                            ])}

                        </Steps>
                })
        ],
        riskModel,
        () => {
            const serverRiskContext = getAzureSqlServerRiskContext(serverModel);
            return [
                serverRiskContext.generalInformation,
                serverRiskContext.authenticationType,
                serverRiskContext.status,
                serverRiskContext.storageSize,
                serverRiskContext.inboundExternalAccessScope,
                serverRiskContext.sensitive,
                serverRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        });
}