﻿import { map } from "@infrastructure";
import { Contract } from "../../../../../../../../../../../../../../../common";
import { GcpConsoleUrlBuilder } from "../../../../../../../../../../../../../../../tenants";
import { EntityExternalConsoleLinkDefinition } from "../useDefinition";

export function useGcpStorageBucketDefinition(entityModel: Contract.EntityModel, page: Contract.GcpConsolePage) {
    const bucket = entityModel.entity as Contract.GcpStorageBucket;

    const getUrl =
        map(
            page,
            {
                [Contract.GcpConsolePage.Permissions]: () => GcpConsoleUrlBuilder.GetStorageBucketPermissionsUrl,
                [Contract.GcpConsolePage.Resource]: () => GcpConsoleUrlBuilder.GetStorageBucketDetailsUrl
            });

    return new EntityExternalConsoleLinkDefinition(getUrl(bucket.name));
}