import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { useAwsCommonAccessResourceDefinition, useAwsCommonEc2ResourceDefinition } from ".";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, FeatureHelper, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions, useTheme } from "../../../../../../../../../../common";
import { useAwsResourceProductCodeTypeTranslator } from "../../../../../../../../../../tenants";
import { DefinitionData } from "../../../../Table";
import { EntityTableDefinition } from "../../useDefinition";
import { useCommonVirtualMachineDiskResourceDefinition } from "../useCommonVirtualMachineDiskResourceDefinition";

export function useAwsEc2ImageDefinition(definitionData: DefinitionData) {
    const commonAccessResourceDefinition = useAwsCommonAccessResourceDefinition(definitionData);
    const commonEc2ResourceDefinition = useAwsCommonEc2ResourceDefinition(definitionData);
    const commonVirtualMachineDiskResourceDefinition = useCommonVirtualMachineDiskResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const resourceProductCodeTypeTranslator = useAwsResourceProductCodeTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.aws.useAwsEc2ImageDefinition",
            () => ({
                columns: {
                    awsManaged: {
                        false: "Customer managed",
                        title: "Management",
                        true: "AWS managed"
                    },
                    description: "Description",
                    instances: "Instances",
                    productCodeId: "Product Code Id",
                    productCodeType: "Product Code Type"
                }
            }));

    function getAwsManagedTranslation(imageModel: Contract.EntityModel) {
        return (imageModel.entity as Contract.AwsEc2Image).awsManaged
            ? localization.columns.awsManaged.true()
            : localization.columns.awsManaged.false();
    }

    const theme = useTheme();
    return new EntityTableDefinition(
        _.filter(
            [
                commonEc2ResourceDefinition.columns.rawIdColumn,
                commonEc2ResourceDefinition.columns.tenantColumn,
                commonEc2ResourceDefinition.columns.creationTimeColumn,
                commonEc2ResourceDefinition.columns.creatorIdentityCsvColumn,
                commonEc2ResourceDefinition.columns.creatorOriginatorEntityCsvColumn,
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsEc2ImageModel) => ({
                                [localization.columns.awsManaged.title()]: getAwsManagedTranslation(item)
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <ValuesFilter
                                    placeholder={localization.columns.awsManaged.title()}
                                    sorted={false}>
                                    <ValuesFilterItem
                                        title={localization.columns.awsManaged.true()}
                                        value={true}/>
                                    <ValuesFilterItem
                                        title={localization.columns.awsManaged.false()}
                                        value={false}/>
                                </ValuesFilter>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsEc2ImageAwsManaged}
                    itemProperty={getAwsManagedTranslation}
                    key={Contract.EntityModelProperty.AwsEc2ImageAwsManaged}
                    title={localization.columns.awsManaged.title()}/>,
                commonAccessResourceDefinition.columns.accessLevelColumn,
                <DataTableColumn
                    cellSx={{ maxWidth: theme.spacing(25) }}
                    exportOptions={
                        entitiesExportOptions<Contract.AwsEc2ImageModel>(
                            Contract.TypeNames.AwsEc2Instance,
                            item => item.instanceIds,
                            localization.columns.instances())}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedEntityFilter
                                    getEntityIdPage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.AwsEc2ImageInstances)}
                                    placeholder={localization.columns.instances()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsEc2ImageInstances}
                    key={Contract.EntityModelProperty.AwsEc2ImageInstances}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.AwsEc2ImageModel>) =>
                            <EntitiesCell
                                entityIdsOrModels={item.instanceIds}
                                entityTypeName={Contract.TypeNames.AwsEc2Instance}
                                entityVariant="iconText"/>}
                    sortOptions={{ type: DataTableSortType.Numeric }}
                    title={localization.columns.instances()}/>,
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsEc2ImageModel) => ({
                                [localization.columns.productCodeType()]:
                                    _.isNil((item.entity as Contract.AwsEc2Image).productCode)
                                        ? ""
                                        : resourceProductCodeTypeTranslator((item.entity as Contract.AwsEc2Image).productCode!.type)
                            })
                    }}
                    id="productCodeType"
                    key="productCodeType"/>,
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsEc2ImageModel) => ({
                                [localization.columns.productCodeId()]:
                                    _.isNil((item.entity as Contract.AwsEc2Image).productCode)
                                        ? ""
                                        : (item.entity as Contract.AwsEc2Image).productCode!.id
                            })
                    }}
                    id="productCodeId"
                    key="productCodeId"/>,
                ...(FeatureHelper.enabled(Contract.FeatureName.AwsEc2MachineImageAnalysisEnabled)
                    ? [
                        commonVirtualMachineDiskResourceDefinition.columns.operatingSystemColumn,
                        commonVirtualMachineDiskResourceDefinition.columns.operatingSystemTypeColumn,
                        commonVirtualMachineDiskResourceDefinition.columns.vulnerabilitiesColumn,
                        commonVirtualMachineDiskResourceDefinition.columns.workloadAnalysisStatusColumn,
                        commonVirtualMachineDiskResourceDefinition.columns.scanTimeColumn
                    ]
                    : []),
                commonEc2ResourceDefinition.columns.regionColumn,
                commonEc2ResourceDefinition.columns.regionLocationColumn,
                commonEc2ResourceDefinition.columns.tagsColumn,
                commonEc2ResourceDefinition.columns.attributesColumn,
                commonEc2ResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
                commonEc2ResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
                commonEc2ResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
            ]),
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAwsEc2ImageRequest(
                new Contract.EntityControllerGetEntityModelPageAwsEc2ImageRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEc2ResourceRawId]),
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEc2ImageAwsManaged]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEc2ImageInstances])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags])),
                new Contract.EntityControllerGetEntityModelPageAwsResourceRequestAccess(ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsResourceAccessLevel])),
                new Contract.EntityControllerGetEntityModelPageEntityRequestVirtualMachineDiskResource(
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.VirtualMachineOperatingSystemDisplayName]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.VirtualMachineOperatingSystemType]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.VirtualMachineScanTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.VirtualMachineVulnerabilities]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.VirtualMachineVulnerabilitySeverities]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.VirtualMachineScanStatus]))));
}