import { useLocalization } from "@infrastructure";
import React from "react";
import { EntitySnapshotDefinition } from "../..";
import { Contract } from "../../../../../../../../../../../../../common";
import { GroupTable, PolicyTable } from "../../../components";

export function useAwsIamUserDefinition(entitySnapshot: Contract.EntitySnapshot) {
    const userSnapshot = entitySnapshot as Contract.AwsIamUserSnapshot;
    const localization =
        useLocalization(
            "views.customer.entities.profile.snapshots.snapshot.hooks.useDefinition.hooks.useAwsIamUserDefinition",
            () => ({
                description: "The following contains the complete set of Identity-Based Policies that were attached to this user and all the Groups that this user was a member of at the specified time, after the specified changes were made."
            }));
    return new EntitySnapshotDefinition(
        localization.description(),
        [
            <PolicyTable
                entitySnapshot={entitySnapshot}
                key="policyTable"
                permissionBoundaryPolicySnapshotId={userSnapshot.permissionBoundaryPolicySnapshotId}
                policySnapshotIds={userSnapshot.policySnapshotIds}/>,
            <GroupTable
                entitySnapshot={entitySnapshot}
                groupIds={userSnapshot.groupIds}
                key="groupTable"/>
        ]);
}