import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, Entity, entityModelStore, InlineEntities } from "../../../../../../../../../../../../../common";
import { useCommonSectionsAndDescriptionDefinition } from "../../../../useCommonSectionsAndDescriptionDefinition";
import { EntityExternalConsoleLink } from "../../../components";
import { useGetGcpComputeInstancesRiskContext, useGetGcpComputeInstanceTemplateRiskContext, useGetGcpTenantEntityRiskContext } from "../../contexts";

export function useGcpComputeInstanceBlockProjectWideSshKeysNotEnabledRiskInstanceTemplateDefinition(riskModel: Contract.GcpComputeInstanceBlockProjectWideSshKeysNotEnabledRiskModel) {
    const instanceTemplateModel = entityModelStore.useGet(riskModel.risk.entityId) as Contract.GcpComputeInstanceTemplateModel;
    const tenantEntityModel = entityModelStore.useGet(instanceTemplateModel.entity.tenantId) as Contract.GcpTenantEntityModel;

    const getGcpComputeInstancesRiskContext = useGetGcpComputeInstancesRiskContext();
    const getGcpComputeInstanceTemplateRiskContext = useGetGcpComputeInstanceTemplateRiskContext();
    const getGcpTenantEntityRiskContext = useGetGcpTenantEntityRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.useGcpComputeInstanceBlockProjectWideSshKeysNotEnabledRiskDefinition.useGcpComputeInstanceBlockProjectWideSshKeysNotEnabledRiskInstanceTemplateDefinition",
            () => ({
                description: {
                    withInstances: "{{instanceTemplate}} launched {{instances}} configured without blocking project wide SSH keys",
                    withoutInstances: "{{instanceTemplate}} is configured without blocking project wide SSH keys"
                },
                sections: {
                    resolution: {
                        step1: "Click the **CREATE SIMILAR** button",
                        step2: "Check **Block project-wide SSH keys** under **Security**",
                        step3: "Click on **Create**",
                        step4: "For each instance group and cluster using the instance template, update the configuration to use the newly created instance template",
                        step5: "Delete the old unsecured instance template. Note that you cannot delete an instance template if any managed instance group still references it"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        (_.isEmpty(riskModel.risk.aggregatedEntityIds)
            ? localization.description.withoutInstances
            : localization.description.withInstances)({
            instances:
                <InlineEntities
                    entityIdsOrModels={riskModel.risk.aggregatedEntityIds}
                    entityTypeName={Contract.TypeNames.GcpComputeInstance}
                    variant="itemCountAndType"/>,
            instanceTemplate:
                <Entity
                    entityIdOrModel={riskModel.risk.entityId}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={riskModel.risk.entityId}
                key={riskModel.risk.entityId}
                page={Contract.GcpConsolePage.Resource}/>,
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4(),
            localization.sections.resolution.step5()
        ],
        riskModel,
        () => {
            const instancesContextItems = getGcpComputeInstancesRiskContext(riskModel.risk.aggregatedEntityIds);
            const instanceTemplateContextItems = getGcpComputeInstanceTemplateRiskContext(instanceTemplateModel);
            const tenantEntityContextItems = getGcpTenantEntityRiskContext(tenantEntityModel);
            return [
                instanceTemplateContextItems.generalInformation,
                instanceTemplateContextItems.sensitive,
                instanceTemplateContextItems.instanceGroups,
                instanceTemplateContextItems.vpcs,
                tenantEntityContextItems.sshKeys,
                instancesContextItems.sensitive,
                instanceTemplateContextItems.getOpenRiskedEntityRisksContextItem(riskModel.risk.id)
            ];
        });
}