﻿import { Loading, Optional, useLocalization } from "@infrastructure";
import { Box } from "@mui/material";
import React from "react";
import { useGetAwsInboundExternalResourceRiskContext, useGetAwsRdsDatabaseInstanceRiskContext } from "..";
import { Contract, CustomerConsoleAppUrlHelper, Entity, entityModelStore, Network, tenantModelStore, useTheme } from "../../../../../../../../../../../../common";
import { ToolbarToggleFilterId } from "../../../../../../../../../../../../common/components/Network/components";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { RiskDefinitionSection } from "../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";

export function useAwsInboundExternalRdsDatabaseInstanceRiskDefinition(riskModel: Contract.RiskModel) {
    const inboundExternalRdsDatabaseInstanceRiskModel = riskModel as Contract.AwsInboundExternalRdsDatabaseInstanceRiskModel;
    const databaseInstanceModel = entityModelStore.useGet(inboundExternalRdsDatabaseInstanceRiskModel.risk.entityId) as Contract.AwsRdsDatabaseInstanceModel;
    const databaseInstance = databaseInstanceModel.entity as Contract.AwsRdsDatabaseInstance;
    const tenantConfiguration = tenantModelStore.useGet(riskModel.tenantId).configuration as Contract.AwsTenantConfiguration;
    const inboundAccessType = (databaseInstanceModel.entityNetwork as Optional<Contract.NetworkAccessResourceStateNetwork>)?.inboundAccessType;

    const getAwsInboundExternalResourceRiskContext = useGetAwsInboundExternalResourceRiskContext();
    const getAwsRdsDatabaseInstanceRiskContext = useGetAwsRdsDatabaseInstanceRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsInboundExternalRdsDatabaseInstanceRiskDefinition",
            () => ({
                description: {
                    externalDirect: "{{databaseInstance}} is directly accessible from a wide range of public IP addresses",
                    externalIndirect: "{{databaseInstance}} is indirectly accessible via a load balancer, from a wide range of public IP addresses"
                },
                sections: {
                    networkGraph: "Network Exposure",
                    resolution: {
                        directly: {
                            step1: "In the top right corner, click **Modify**",
                            step2: "Scroll down to the **Connectivity** section and expand **Additional configuration**",
                            step3: "Choose **Not publicly accessible** and click **Continue**",
                            step4: "Review the changes and click **Modify DB instance**"
                        },
                        indirectly: {
                            step1: "For each exposing security group - click on **Edit inbound rules**",
                            step2: "Delete or restrict the source of the exposing rules based on the required access"
                        }
                    }
                }
            }));

    const theme = useTheme();
    return useCommonSectionsAndDescriptionDefinition(
        ((databaseInstanceModel.entityNetwork as Optional<Contract.NetworkAccessResourceStateNetwork>)?.inboundAccessType === Contract.NetworkInboundAccessType.ExternalDirect
            ? localization.description.externalDirect
            : localization.description.externalIndirect)({
            databaseInstance:
                <Entity
                    entityIdOrModel={databaseInstanceModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () =>
            inboundAccessType === Contract.NetworkInboundAccessType.ExternalDirect
                ? [
                    consoleSignInStepTranslator(
                        Contract.AwsConsoleView.Rds,
                        AwsConsoleUrlBuilder.getRdsDatabaseInstanceUrl(databaseInstance)),
                    localization.sections.resolution.directly.step1(),
                    localization.sections.resolution.directly.step2(),
                    localization.sections.resolution.directly.step3(),
                    localization.sections.resolution.directly.step4()
                ]
                : [
                    consoleSignInStepTranslator(
                        Contract.AwsConsoleView.SecurityGroups,
                        AwsConsoleUrlBuilder.getEc2SecurityGroupsUrl(
                            databaseInstance,
                            tenantConfiguration.partitionType)),
                    localization.sections.resolution.indirectly.step1(),
                    localization.sections.resolution.indirectly.step2()
                ],
        inboundExternalRdsDatabaseInstanceRiskModel,
        () => {
            const databaseInstanceRiskContext = getAwsRdsDatabaseInstanceRiskContext(databaseInstanceModel);
            const inboundExternalResourceRiskContext =
                getAwsInboundExternalResourceRiskContext(
                    databaseInstanceModel,
                    inboundExternalRdsDatabaseInstanceRiskModel);
            return [
                databaseInstanceRiskContext.generalInformation,
                databaseInstanceRiskContext.sensitive,
                databaseInstanceRiskContext.engine,
                databaseInstanceRiskContext.storageSize,
                inboundExternalResourceRiskContext.inboundAccess,
                databaseInstanceRiskContext.encryption,
                databaseInstanceRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        },
        {
            sections:
                !databaseInstance.systemDeleted
                    ? [
                        new RiskDefinitionSection(
                            <Box sx={{ minHeight: theme.spacing(20) }}>
                                <Loading>
                                    <Network
                                        baseUrl={CustomerConsoleAppUrlHelper.getRiskProfileRelativeUrl(inboundExternalRdsDatabaseInstanceRiskModel.risk.id)}
                                        entityId={inboundExternalRdsDatabaseInstanceRiskModel.risk.entityId}
                                        initialFilterMap={{ [ToolbarToggleFilterId.WideRangeSourceSubnet]: true }}
                                        tenantType={Contract.TenantType.Aws}
                                        variant="risk"/>
                                </Loading>
                            </Box>,
                            localization.sections.networkGraph(),
                            {
                                profilePageOnly: true
                            })
                    ]
                    : undefined
        });
}