import { useLocalization, useLocalizeList } from "@infrastructure";
import _ from "lodash";
import { AuditEventDefinition, AuditEventDefinitionContext, AuditEventDefinitionDetailItem } from "..";
import { Contract } from "../../../../../../../../../common";
import { useCodeTenantTypeTranslator } from "../../../../../../../../../tenants";

export function useCodeTenantAuditEventDefinition(context: AuditEventDefinitionContext) {
    const tenantTypeTranslator = useCodeTenantTypeTranslator();
    const localizeList = useLocalizeList();
    const localization =
        useLocalization(
            "views.customer.auditEvents.profile.hooks.useDefinition.hooks.useCodeTenantAuditEventDefinition",
            () => ({
                branchName: "Branch name",
                propertyChanges: "Updated properties",
                tenantName: "Name",
                tenantType: "Provider"
            }));

    const tenantAuditEvent = context.auditEventModel.auditEvent as Contract.CodeTenantAuditEvent;
    return new AuditEventDefinition(
        _<AuditEventDefinitionDetailItem>([]).
            concat(
                new AuditEventDefinitionDetailItem(
                    localization.tenantName(),
                    tenantAuditEvent.tenantName)).
            concatIf(
                tenantAuditEvent.codeTenantType !== Contract.CodeTenantType.General,
                () =>
                    new AuditEventDefinitionDetailItem(
                        localization.tenantType(),
                        tenantTypeTranslator(tenantAuditEvent.codeTenantType))).
            concatIf(
                tenantAuditEvent.typeName === Contract.TypeNames.GitTenantCreationAuditEvent ||
                tenantAuditEvent.typeName === Contract.TypeNames.GitTenantUpdateAuditEvent,
                () =>
                    new AuditEventDefinitionDetailItem(
                        localization.branchName(),
                        (tenantAuditEvent as Contract.GitTenantCreationAuditEvent | Contract.GitTenantUpdateAuditEvent).branchName)
            ).
            concatIf(
                tenantAuditEvent.typeName === Contract.TypeNames.GitTenantUpdateAuditEvent,
                () => {
                    const properties =
                        _.filter([
                            (tenantAuditEvent as Contract.GitTenantUpdateAuditEvent).branchChanged
                                ? localization.branchName()
                                : undefined]);
                    return (
                        _<AuditEventDefinitionDetailItem>([]).
                            concatIf(
                                !_.isEmpty(properties),
                                new AuditEventDefinitionDetailItem(
                                    localization.propertyChanges(),
                                    localizeList(properties)!)).
                            value());
                }).
            value());
}