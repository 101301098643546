import { IconText } from "@infrastructure";
import { Typography } from "@mui/material";
import React from "react";
import { Contract, RiskPolicyCategoryIcon, useRiskPolicyCategoryTranslator, useTheme } from "..";

type RiskPolicyCategoryProps = {
    riskPolicyCategory: Contract.RiskPolicyCategory;
    variant: "risk" | "riskPolicy";
};

export function RiskPolicyCategory({ riskPolicyCategory, variant }: RiskPolicyCategoryProps) {
    const riskPolicyCategoryTranslator = useRiskPolicyCategoryTranslator();
    const theme = useTheme();
    return (
        <IconText
            icon={
                <RiskPolicyCategoryIcon
                    riskPolicyCategory={riskPolicyCategory}/>
            }
            iconSx={{
                color: theme.palette.text.primary,
                fontSize: "16px",
                fontWeight:
                    variant === "riskPolicy"
                        ? "bold"
                        : undefined
            }}
            text={
                <Typography noWrap={true}>
                    {riskPolicyCategoryTranslator(riskPolicyCategory)}
                </Typography>}/>);
}