import { Tooltip } from "@infrastructure";
import { Box, Stack, SvgIconProps, SxProps, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract } from "../controllers";
import { useTenantTypeTranslator } from "../hooks";
import { TenantIcon } from "./TenantIcon";

type TenantsIconProps =
    SvgIconProps & {
        colored?: boolean;
        containerSx?: SxProps;
        variant?: "icon" | "iconText";
    } & ({
        tenantTypes?: Contract.TenantType[];
        tenantTypeToTitleMap?: never;
    } | {
        tenantTypes?: never;
        tenantTypeToTitleMap?: Record<Contract.TenantType, string>[];
    });

export function TenantsIcon({ colored = true, containerSx, tenantTypes, tenantTypeToTitleMap, variant = "icon", ...props }: TenantsIconProps) {
    const tenantTypeTranslator = useTenantTypeTranslator();
    const types =
        tenantTypes ??
        _.flatMap(
            tenantTypeToTitleMap,
            tenantTypeToTitle => _.keys(tenantTypeToTitle)) as Contract.TenantType[];
    return (
        <Stack
            alignItems="center"
            direction="row"
            gap={1}
            sx={containerSx}>
            {_(types).
                orderBy([
                    (item: string) => item === Contract.TenantType.Code,
                    _.identity
                ]).
                map(
                    type =>
                        <Tooltip
                            key={type}
                            titleOrGetTitle={
                                (_.find(
                                    tenantTypeToTitleMap,
                                    record => record?.[type]) as Record<Contract.TenantType, string> | undefined)?.[type]}>
                            <Stack
                                alignItems="center"
                                direction="row"
                                spacing={1}>
                                <TenantIcon
                                    colored={colored}
                                    key={type}
                                    tenantType={type}
                                    {...props}/>
                                {variant === "iconText" &&
                                    <Box
                                        sx={{
                                            lineHeight: 1,
                                            overflow: "hidden"
                                        }}>
                                        <Typography noWrap={true}>
                                            {tenantTypeTranslator(type)}
                                        </Typography>
                                    </Box>}
                            </Stack>
                        </Tooltip>).
                value()}
        </Stack>);
}