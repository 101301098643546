import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract } from "../../../../../../../../../../common";
import { EntitiesInfoItem } from "../../../../components";

export function useAzureCommonManagedIdentityOriginatorResourceInfoElements(resourceGroupResourceModel: Contract.AzureResourceGroupResourceModel) {
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.azure.useAzureCommonManagedIdentityOriginatorResourceInfoElements",
            () => ({
                managedIdentity: "Managed Identity"
            }));

    return [
        <EntitiesInfoItem
            entityIdsOrModels={
                _.filter(
                    _.concat(
                        _.as<Contract.IAzureManagedIdentityOriginatorResource>(resourceGroupResourceModel.entity).systemManagedIdentityServicePrincipalId,
                        _.as<Contract.IAzureManagedIdentityOriginatorResourceModel>(resourceGroupResourceModel).userManagedIdentityServicePrincipalIds)) as string[]}
            entityTypeName={Contract.TypeNames.AadDirectoryManagedIdentityServicePrincipal}
            key="managedIdentity"
            title={localization.managedIdentity()}/>
    ];
}