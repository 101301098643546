﻿import { useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import React from "react";
import { Contract, EntityController } from "../../../../../../../controllers";
import { useEntityTypeNameTranslator } from "../../../../../../../hooks";
import { ElasticsearchItemPageHelper } from "../../../../../../../utilities";
import { PagedEntityMultiSelect } from "../../../../../../PagedEntityMultiSelect";
import { useCustomRiskPolicyContext } from "../../../../../CustomRiskPolicy";
import { EntityMultiSelectProperty, useMultiSelectorContext, useSetMultiSelectorContext } from "../../../EntityMultiSelect";

type AwsIamUserGroupIdsProps = {
    disabled?: boolean;
    entityTypeName: string;
    includeServiceName: boolean;
};

export function AwsIamUserGroupIds({ disabled, entityTypeName, includeServiceName }: AwsIamUserGroupIdsProps) {
    const { scopeId } = useCustomRiskPolicyContext();
    const { propertyIdToConditionMap } = useMultiSelectorContext();
    const condition = propertyIdToConditionMap[EntityMultiSelectProperty.AwsIamUserGroupIds] as Contract.AwsIamUserGroupIdMatchCondition;
    const setMultiSelectorContext = useSetMultiSelectorContext();
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "common.customRiskPolicy.entityMultiSelect.condition.awsIamUserGroupIds",
            () => ({
                title: "*capitalize*{{translatedEntityTypeName}}** in these groups"
            }));

    return (
        <Stack
            alignItems="center"
            direction="row"
            spacing={1}>
            <Typography noWrap={true}>
                {localization.title({
                    translatedEntityTypeName:
                        entityTypeNameTranslator(
                            entityTypeName,
                            {
                                count: 0,
                                includeServiceName,
                                variant: "text"
                            })
                })}
            </Typography>
            <PagedEntityMultiSelect
                disabled={disabled}
                fieldOptions={{ dense: true }}
                getEntityModelPage={
                    ElasticsearchItemPageHelper.makePagedEntityMultiSelect(
                        async (itemNextPageSearchCursor, searchText) => {
                            const { entityModelPage } =
                                await EntityController.searchEntityModels(
                                    new Contract.EntityControllerSearchEntityModelsTypeRequest(
                                        false,
                                        15,
                                        itemNextPageSearchCursor,
                                        scopeId,
                                        searchText,
                                        true,
                                        Contract.TypeNames.AwsIamGroup));
                            return entityModelPage;
                        })}
                placeholder={
                    entityTypeNameTranslator(
                        Contract.TypeNames.AwsIamGroup,
                        {
                            count: 0,
                            includeServiceName: false
                        })}
                selectedEntityIds={condition.groupIds}
                onSelectedEntityIdsChanged={
                    groupIds =>
                        setMultiSelectorContext(
                            context => ({
                                propertyIdToConditionMap: {
                                    ...context.propertyIdToConditionMap,
                                    [EntityMultiSelectProperty.AwsIamUserGroupIds]: new Contract.AwsIamUserGroupIdMatchCondition(groupIds)
                                }
                            }))}/>
        </Stack>);
}