import { Optional, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, RiskController } from "../../../../../../../controllers";
import { SummaryItem, SummaryItemItem } from "../../../../../components";
import { useRiskResolutionAutomationContext } from "../../../../../utilities";
import { AwsRiskResolutionAutomationContext } from "../useAwsDefinition";

export function AwsSummaryItem() {
    const {
        awsAccessKey,
        changeModels,
        isChangeResolvable,
        storedAwsAccessKey,
        updatedChangeMap
    } = useRiskResolutionAutomationContext() as AwsRiskResolutionAutomationContext;

    const localization =
        useLocalization(
            "common.riskResolutionAutomation.hooks.useDefinition.hooks.useAwsDefinition.awsSummaryItem",
            () => ({
                confirm: "Are you sure you want to continue and apply these changes in your AWS environment?",
                updateStatus: "Update models"
            }));

    const items =
        useMemo(
            () =>
                _(changeModels).
                    filter(changeModel => isChangeResolvable(changeModel)).
                    map(
                        changeModel =>
                            new AwsSummaryItemItem(
                                awsAccessKey,
                                storedAwsAccessKey,
                                updatedChangeMap[changeModel.change.id] ?? changeModel.change,
                                changeModel.changeDatas) as SummaryItemItem).
                    value(),
            []);

    return (
        <SummaryItem
            confirm={localization.confirm()}
            items={items}/>);
}

export class AwsSummaryItemItem extends SummaryItemItem {
    constructor(
        private awsAccessKey: Optional<Contract.AwsIamAccessKey>,
        private storedAwsAccessKey: boolean,
        change: Contract.Change,
        changeDatas: Contract.ChangeModelChangeData[]) {
        super(
            change,
            changeDatas);
    }

    protected async executeCore(): Promise<boolean> {
        this.resolveRiskAutomationResponse =
            await RiskController.resolveRiskAutomation(
                new Contract.RiskControllerResolveAwsRiskAutomationRequest(
                    this.change,
                    this.riskId,
                    this.awsAccessKey,
                    this.storedAwsAccessKey));
        return this.resolveRiskAutomationResponse.success;
    }
}