﻿import React from "react";
import { DataTableColumn, useLocalization } from "@infrastructure";
import { Contract, ElasticsearchItemPageHelper, PagedEntityFilter } from "../../../../../../../../../common";
import { DefinitionData } from "../../../Table";
import { useCommonKubernetesIamResourceDefinition } from "./useCommonKubernetesIamResourceDefinition";

export function useCommonKubernetesPrincipalDefinition(definitionData: DefinitionData) {
    const commonKubernetesIamResourceDefinition = useCommonKubernetesIamResourceDefinition(definitionData);
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.useCommonKubernetesPrincipalDefinition",
            () => ({
                columns: {
                    roleIdReferences: "Roles"
                }
            }));

    return {
        columns: {
            ...commonKubernetesIamResourceDefinition.columns,
            roleColumn:
                <DataTableColumn
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedEntityFilter
                                    getEntityIdPage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.KubernetesPrincipalRoles)}
                                    placeholder={localization.columns.roleIdReferences()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.KubernetesPrincipalRoles}
                    key={Contract.EntityModelProperty.KubernetesPrincipalRoles}
                    title={localization.columns.roleIdReferences()}/>
        }
    };
}