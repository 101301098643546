import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function CrowdStrikeIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <g clipPath="url(#clip0_58343_2156)">
                <path
                    d="M0 12C0 5.37258 5.37258 0 12 0V0C18.6274 0 24 5.37258 24 12V12C24 18.6274 18.6274 24 12 24V24C5.37258 24 0 18.6274 0 12V12Z"
                    fill="#FE1409"/>
                <path
                    d="M6.49496 6.465L6.55496 6.495L6.74996 6.72L6.88496 6.885L6.91496 6.945L6.97496 6.975L7.09496 7.125L7.51496 7.545L7.63496 7.65L7.76996 7.77L7.93496 7.905L8.12996 8.07L8.39996 8.265L8.56496 8.385L8.92496 8.625L9.11996 8.745L9.64496 9.045L9.88496 9.165L10.26 9.345L10.635 9.51L10.995 9.66L11.49 9.855L12.33 10.14L12.87 10.305L13.605 10.515L14.28 10.725L14.835 10.905L15.525 11.16L16.035 11.385L16.41 11.58L16.695 11.76L16.89 11.91L17.07 12.09L17.205 12.3L17.265 12.48V12.765L17.175 13.08L17.055 13.38L16.995 13.605V13.74L17.055 13.875L17.19 13.965L17.55 14.085L18 14.22L18.435 14.37L18.735 14.49L19.005 14.64L19.2 14.79L19.29 14.895L19.365 15.045L19.395 15.225L19.56 15.405L19.755 15.66L19.875 15.855L19.965 16.05L20.025 16.245V16.485L19.995 16.635L19.965 16.665L19.89 16.59L19.71 16.395L19.44 16.125L19.275 15.99L18.975 15.765L18.675 15.57L18.435 15.435L18.195 15.315L17.82 15.165L17.475 15.06L17.1 14.985L16.83 14.955L16.575 14.94H15.885L15.345 14.955H15.015L14.55 14.925L14.205 14.865L13.89 14.775L13.65 14.67L13.47 14.565L13.2 14.37L13.035 14.235L12.87 14.085L12.75 13.98L12.525 13.74L12.315 13.455L12.045 13.05L11.82 12.69L11.655 12.42L11.43 12.045L11.205 11.61L11.055 11.34L10.86 11.04L10.71 10.845L10.545 10.65L10.44 10.53L10.11 10.2L9.98996 10.095L9.76496 9.9L9.46496 9.66L8.74496 9.12L8.54996 8.97L8.30996 8.79L8.06996 8.595L7.91996 8.475L7.69496 8.28L7.57496 8.16L7.52996 8.13V8.1L7.48496 8.085L7.37996 7.965L7.22996 7.8L7.03496 7.545L6.86996 7.29L6.74996 7.08L6.58496 6.72L6.49496 6.51V6.465Z"
                    fill="#FEFEFD"/>
                <path
                    d="M11.895 14.175L11.985 14.205L12.225 14.37L12.39 14.475L12.54 14.58L12.795 14.73L13.035 14.85L13.305 14.97L13.665 15.09L13.935 15.15L14.25 15.195H14.685L15.12 15.15L15.825 15.06L16.23 15.03H16.935L17.31 15.09L17.64 15.18L17.925 15.285L18.3 15.465L18.555 15.6L18.93 15.84L19.125 15.975L19.215 16.05V16.065H18.51L18.195 16.11L17.955 16.17L17.64 16.29L17.4 16.41L16.965 16.605L16.65 16.695L16.47 16.725H16.095L16.155 16.83L16.305 16.98L16.515 17.1L16.755 17.175L16.92 17.205L17.43 17.25L17.655 17.295L17.79 17.355L17.865 17.415L17.925 17.52V17.7L17.88 17.94L17.805 18.165H17.775L17.73 17.91L17.655 17.73L17.535 17.595L17.4 17.535L17.34 17.52H16.965L16.755 17.58L16.62 17.655L16.485 17.79L16.44 17.91V18.075L16.485 18.195L16.62 18.345L16.77 18.45L16.89 18.525L16.875 18.555H16.695L16.485 18.525L16.35 18.465L16.215 18.36L16.065 18.21L15.93 18L15.795 17.745L15.765 17.685L15.675 17.7L15.525 17.76L15.405 17.79H15.135L14.91 17.73L14.655 17.61L14.445 17.475L14.415 17.43L14.445 17.415L14.655 17.475L14.79 17.505H14.865L14.58 17.325L14.325 17.145L14.07 16.935L13.89 16.77L13.65 16.53L13.515 16.365L13.365 16.185L13.35 16.14L13.425 16.17L13.68 16.35L13.86 16.455L14.16 16.575L14.28 16.605L14.145 16.44L13.995 16.275L13.89 16.155L13.71 15.96L13.545 15.78L12.66 14.895L12.54 14.79L12.435 14.685L12.315 14.58L12.075 14.355L11.955 14.25L11.895 14.175Z"
                    fill="#FEFEFD"/>
                <path
                    d="M3.95996 6L4.03496 6.03L4.31996 6.315L4.42496 6.435L4.85996 6.87L4.97996 6.975L4.99496 7.02L5.05496 7.05L5.29496 7.275L5.59496 7.545L5.75996 7.68L5.95496 7.845L6.16496 8.01L6.38996 8.19L6.74996 8.46L7.00496 8.64L7.67996 9.09L7.93496 9.255L8.11496 9.375L8.27996 9.48L8.47496 9.615L8.71496 9.765L8.87996 9.885L9.07496 10.02L9.22496 10.14L9.46496 10.32L9.62996 10.455L9.85496 10.665L9.89996 10.695V10.725H9.92996L10.035 10.845L10.305 11.115L10.575 11.475L10.71 11.7L10.86 11.97L10.95 12.195L10.965 12.315L10.905 12.255L10.74 12.045L10.59 11.88L10.485 11.76L10.245 11.52L10.08 11.385L9.89996 11.235L9.73496 11.115L9.52496 10.965L9.28496 10.815L8.86496 10.56L8.48996 10.35L8.12996 10.14L7.73996 9.9L7.34996 9.645L7.13996 9.51L6.62996 9.15L6.38996 8.97L6.19496 8.82L5.98496 8.64L5.81996 8.505L5.63996 8.34L5.51996 8.235L5.39996 8.115L5.36996 8.1V8.07L5.30996 8.04L5.18996 7.92L5.08496 7.8L4.91996 7.62L4.78496 7.455L4.60496 7.23L4.45496 7.02L4.27496 6.735L4.12496 6.45L3.95996 6.03V6Z"
                    fill="#FEFEFD"/>
                <path
                    d="M4.57496 8.235L4.63496 8.265L4.75496 8.37L4.93496 8.565L5.15996 8.775L5.30996 8.91L5.47496 9.045L5.51996 9.075V9.105L5.57996 9.135L5.74496 9.27L5.92496 9.405L6.20996 9.615L6.47996 9.81L6.73496 9.975L6.91496 10.095L7.10996 10.215L7.46996 10.425L7.67996 10.545L7.82996 10.635L8.21996 10.845L8.59496 11.055L8.92496 11.235L9.28496 11.46L9.73496 11.76L9.92996 11.91L10.17 12.105L10.365 12.285L10.485 12.39L10.68 12.6L10.815 12.78L10.965 13.005L11.1 13.245L11.085 13.305L10.95 13.17L10.845 13.05L10.68 12.885L10.515 12.75L10.38 12.63L10.125 12.45L9.86996 12.285L9.61496 12.135L9.28496 11.955L8.92496 11.79L8.71496 11.685L8.41496 11.55L8.12996 11.4L7.73996 11.19L7.37996 10.98L7.07996 10.8L6.73496 10.575L6.55496 10.455L6.28496 10.26L6.04496 10.08L5.87996 9.945L5.65496 9.75L5.41496 9.525L5.26496 9.375L5.15996 9.255L4.97996 9.03L4.76996 8.715L4.63496 8.43L4.57496 8.295V8.235Z"
                    fill="#FEFEFD"/>
                <path
                    d="M7.60496 13.08L7.67996 13.11L7.93496 13.305L8.38496 13.59L8.71496 13.77L9.07496 13.935L9.53996 14.085L9.83996 14.145L9.95996 14.16H10.23L10.335 14.175L10.29 14.085L10.14 13.935L9.97496 13.815L9.73496 13.665L9.53996 13.56V13.53L9.79496 13.59L10.23 13.74L10.59 13.905L10.815 14.025L10.98 14.115L11.19 14.235L11.37 14.355L11.535 14.475L11.715 14.595L11.955 14.79L12.105 14.91L12.315 15.09L12.51 15.27L12.63 15.375L12.72 15.45V15.48L12.78 15.51L12.96 15.69L12.9 15.705L12.495 15.54L12.195 15.42L11.91 15.315L11.49 15.18L10.575 14.94L10.05 14.775L9.62996 14.61L9.38996 14.49L9.07496 14.325L8.90996 14.235L8.54996 13.995L8.29496 13.8L8.08496 13.62L7.72496 13.26L7.60496 13.11V13.08Z"
                    fill="#FEFEFD"/>
                <path
                    d="M18.645 14.88H18.75L18.99 14.955L19.11 15.03L19.17 15.105L19.2 15.195V15.285H19.14L19.02 15.165L18.765 14.985L18.645 14.895V14.88Z"
                    fill="#FE1409"/>
            </g>
            <defs>
                <clipPath id="clip0_58343_2156">
                    <rect
                        fill="white"
                        height="24"
                        width="24"/>
                </clipPath>
            </defs>
        </SvgIcon>);
}