import { TimeFormatter, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useAwsCommonAccessResourceInfoElements, useAwsCommonEncryptedResourceInfoElements, useAwsDefaultResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../..";
import { useCustomEntityPropertyInfoItemElements } from "..";
import { Contract, InfoItem } from "../../../../../../../../../../common";
import { EntitiesInfoItem, Info } from "../../../../components";
import { AwsIamPolicyDocument } from "../../components";

export function useAwsSecretsManagerSecretDefinition(resourceModel: Contract.AwsResourceModel, options?: DefinitionOptions) {
    const commonAccessResourceInfoElements = useAwsCommonAccessResourceInfoElements(resourceModel);
    const commonEncryptedResourceInfoElements = useAwsCommonEncryptedResourceInfoElements(resourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useAwsDefaultResourceInfoItemElements(resourceModel);
    const secret = resourceModel.entity as Contract.AwsSecretsManagerSecret;
    const secretModel = resourceModel as Contract.AwsSecretsManagerSecretModel;

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsSecretsManagerSecretDefinition",
            () => ({
                info: {
                    items: {
                        rotation: {
                            disabled: "Disabled",
                            enabled: "Enabled",
                            function: "Rotation Lambda Function",
                            timeFrame: {
                                text: "Every {{rotationTimeFrame | TimeSpanFormatter.humanizeDays}}",
                                title: "Rotation Period"
                            },
                            title: "Rotation"
                        },
                        rotationTime: {
                            empty: "Never",
                            title: "Rotation Time"
                        },
                        updateTIme: "Update Time",
                        usageTime: {
                            empty: "Never",
                            title: "Usage Time"
                        }
                    }
                },
                tabs: {
                    policyDocument: "Policy"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <AwsIamPolicyDocument policyDocument={secret.policyDocument}/>,
                localization.tabs.policyDocument(),
                "policyDocument")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    ...commonAccessResourceInfoElements,
                    ...commonEncryptedResourceInfoElements,
                    <InfoItem
                        key="rotationEnabled"
                        title={localization.info.items.rotation.title()}
                        value={
                            secret.rotationEnabled
                                ? localization.info.items.rotation.enabled()
                                : localization.info.items.rotation.disabled()}/>,
                    <EntitiesInfoItem
                        emptyValue={secret.rotationFunctionConfigurationArn}
                        entityIdsOrModels={secretModel.rotationFunctionConfigurationId}
                        entityTypeName={Contract.TypeNames.AwsLambdaFunctionConfiguration}
                        key="rotationFunctionConfigurationId"
                        title={localization.info.items.rotation.function()}/>,
                    <InfoItem
                        key="rotationTimeFrame"
                        title={localization.info.items.rotation.timeFrame.title()}
                        value={
                            _.isNil(secret.rotationEnabled)
                                ? undefined
                                : localization.info.items.rotation.timeFrame.text({ rotationTimeFrame: secret.rotationTimeFrame })}/>,
                    <InfoItem
                        key="rotationTime"
                        location="all"
                        title={localization.info.items.rotationTime.title()}
                        value={
                            _.isNil(secret.rotationTime)
                                ? localization.info.items.rotationTime.empty()
                                : TimeFormatter.profileFormatDateTime(secret.rotationTime)}/>,
                    <InfoItem
                        key="usageTime"
                        location="all"
                        title={localization.info.items.usageTime.title()}
                        value={
                            _.isNil(secret.usageTime)
                                ? localization.info.items.usageTime.empty()
                                : TimeFormatter.profileFormatDateTime(secret.usageTime)}/>,
                    <InfoItem
                        key="updateTIme"
                        title={localization.info.items.updateTIme()}
                        value={TimeFormatter.profileFormatDateTime(secret.updateTime)}/>
                ]}
                options={options?.infoOptions}>
            </Info>
    });
}