import { UnexpectedError, useLocalization } from "@infrastructure";
import _ from "lodash";
import { Contract } from "../../../controllers";
import { useEntityTypeNameTranslator, useRiskPolicyTitleTranslator, useTenantTypeTranslator } from "../../../hooks";
import { ObjectTypeMetadataModelHelper, RiskTypeMetadataModelHelper } from "../../../utilities";

export function useUdmObjectTypeNameTranslator() {
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const riskPolicyTitleTranslator = useRiskPolicyTitleTranslator();
    const tenantTypeTranslator = useTenantTypeTranslator();
    const localization =
        useLocalization(
            "common.udmObjectTable.hooks.useUdmObjectTypeNameTranslator",
            () => ({
                objectTypeNameTenant: "{{translatedTenantType}} {{translatedObjectTypeName}}",
                risk: "Finding"
            }));

    return (objectTypeName: string) => {
        const objectTypeMetadataModel = ObjectTypeMetadataModelHelper.get(objectTypeName);
        switch (objectTypeMetadataModel.udmType) {
            case Contract.UdmObjectType.Entity:
                return _.size(objectTypeMetadataModel.tenantTypes) === 1
                    ? localization.objectTypeNameTenant({
                        translatedObjectTypeName: entityTypeNameTranslator(objectTypeName, { includeServiceName: true }),
                        translatedTenantType: tenantTypeTranslator(objectTypeMetadataModel.tenantTypes[0])
                    })
                    : entityTypeNameTranslator(objectTypeName, { includeServiceName: true });
            case Contract.UdmObjectType.Risk: {
                const riskTypeMetadataModel = RiskTypeMetadataModelHelper.get(objectTypeName);
                return _.isNil(riskTypeMetadataModel)
                    ? localization.risk()
                    : riskPolicyTitleTranslator(riskTypeMetadataModel.policyConfigurationTypeName);
            }
            default:
                throw new UnexpectedError("objectTypeMetadataModel.udmType", objectTypeMetadataModel.udmType);
        }
    };
}