import { useLocalization } from "@infrastructure";
import _ from "lodash";
import { useCallback } from "react";
import { Contract } from "../..";
import { TypeHelper } from "../../utilities";
import { useAzureResourcesGenericResourceTypeNameLocalization, useGcpAssetGenericResourceTypeNameLocalization, useOciResourceSearchGenericResourceTypeNameLocalization } from "./hooks";

export function useEntityTypeNameTranslator() {
    const localization =
        useLocalization(
            "common.hooks.useEntityTypeNameTranslator",
            () => ({
                countAndTypeName: "{{entityCount | NumberFormatter.humanize}} {{translatedTypeName}}",
                typeNames: {
                    [Contract.TypeNames.AadDirectoryApplication]: {
                        text: {
                            withoutServiceName: [
                                "app registration",
                                "app registrations"
                            ],
                            withServiceName: [
                                "Microsoft Entra ID app registration",
                                "Microsoft Entra ID app registrations"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "App Registration",
                                "App Registrations"
                            ],
                            withServiceName: [
                                "Microsoft Entra ID App Registration",
                                "Microsoft Entra ID App Registrations"
                            ]
                        }
                    },
                    [Contract.TypeNames.AadDirectoryApplicationCertificate]: {
                        text: {
                            withoutServiceName: [
                                "app registration certificate",
                                "app registrations certificates"
                            ],
                            withServiceName: [
                                "Microsoft Entra ID app registration certificate",
                                "Microsoft Entra ID app registrations certificates"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "App Registration Certificate",
                                "App Registration Certificates"
                            ],
                            withServiceName: [
                                "Microsoft Entra ID App Registration Certificate",
                                "Microsoft Entra ID App Registration Certificates"
                            ]
                        }
                    },
                    [Contract.TypeNames.AadDirectoryApplicationObject]: {
                        text: {
                            withoutServiceName: [
                                "app registration object",
                                "secrets & certificates"
                            ],
                            withServiceName: [
                                "Microsoft Entra ID app registration object",
                                "Microsoft Entra ID secrets & certificates"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "App Registration Object",
                                "Secrets & Certificates"
                            ],
                            withServiceName: [
                                "Microsoft Entra ID App Registration Object",
                                "Microsoft Entra ID Secrets & Certificates"
                            ]
                        }
                    },
                    [Contract.TypeNames.AadDirectoryApplicationSecret]: {
                        text: {
                            withoutServiceName: [
                                "app registration secret",
                                "app registrations secrets"
                            ],
                            withServiceName: [
                                "Microsoft Entra ID app registration secret",
                                "Microsoft Entra ID app registrations secrets"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "App Registration Secret",
                                "App Registration Secrets"
                            ],
                            withServiceName: [
                                "Microsoft Entra ID App Registration Secret",
                                "Microsoft Entra ID App Registration Secrets"
                            ]
                        }
                    },
                    [Contract.TypeNames.AadDirectoryApplicationServicePrincipal]: {
                        text: {
                            withoutServiceName: [
                                "application",
                                "applications"
                            ],
                            withServiceName: [
                                "Microsoft Entra ID application",
                                "Microsoft Entra ID applications"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Application",
                                "Applications"
                            ],
                            withServiceName: [
                                "Microsoft Entra ID Application",
                                "Microsoft Entra ID Applications"
                            ]
                        }
                    },
                    [Contract.TypeNames.AadDirectoryDirectoryRoleAssignment]: {
                        text: {
                            withoutServiceName: [
                                "administrative role assignment",
                                "administrative role assignments"
                            ],
                            withServiceName: [
                                "Microsoft Entra ID administrative role assignment",
                                "Microsoft Entra ID administrative role assignments"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Administrative Role Assignment",
                                "Administrative Role Assignments"
                            ],
                            withServiceName: [
                                "Microsoft Entra ID Administrative Role Assignment",
                                "Microsoft Entra ID Administrative Role Assignments"
                            ]
                        }
                    },
                    [Contract.TypeNames.AadDirectoryDirectoryRoleAssignmentResource]: {
                        text: {
                            withoutServiceName: [
                                "administrative role assignment",
                                "administrative role assignments"
                            ],
                            withServiceName: [
                                "Microsoft Entra ID administrative role assignment",
                                "Microsoft Entra ID administrative role assignments"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Administrative Role Assignment",
                                "Administrative Role Assignments"
                            ],
                            withServiceName: [
                                "Microsoft Entra ID Administrative Role Assignment",
                                "Microsoft Entra ID Administrative Role Assignments"
                            ]
                        }
                    },
                    [Contract.TypeNames.AadDirectoryDirectoryRoleDefinition]: {
                        text: {
                            withoutServiceName: [
                                "role",
                                "roles"
                            ],
                            withServiceName: [
                                "Microsoft Entra ID role",
                                "Microsoft Entra ID roles"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Role",
                                "Roles"
                            ],
                            withServiceName: [
                                "Microsoft Entra ID Role",
                                "Microsoft Entra ID Roles"
                            ]
                        }
                    },
                    [Contract.TypeNames.AadDirectoryDirectoryRoleEligibilitySchedule]: {
                        text: {
                            withoutServiceName: [
                                "role eligibility",
                                "role eligibilities"
                            ],
                            withServiceName: [
                                "Microsoft Entra ID role eligibility",
                                "Microsoft Entra ID role eligibilities"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Role Eligibility",
                                "Role Eligibilities"
                            ],
                            withServiceName: [
                                "Microsoft Entra ID Role Eligibility",
                                "Microsoft Entra ID Role Eligibilities"
                            ]
                        }
                    },
                    [Contract.TypeNames.AadDirectoryDirectoryRoleManagementPolicyAssignment]: {
                        text: {
                            withoutServiceName: [
                                "PIM role settings",
                                "PIM role settings"
                            ],
                            withServiceName: [
                                "PIM role settings",
                                "PIM role settings"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "PIM Role Settings",
                                "PIM Role Settings"
                            ],
                            withServiceName: [
                                "PIM Role Settings",
                                "PIM Role Settings"
                            ]
                        }
                    },
                    [Contract.TypeNames.AadDirectoryGroup]: {
                        text: {
                            withoutServiceName: [
                                "group",
                                "groups"
                            ],
                            withServiceName: [
                                "Microsoft Entra ID group",
                                "Microsoft Entra ID groups"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Group",
                                "Groups"
                            ],
                            withServiceName: [
                                "Microsoft Entra ID Group",
                                "Microsoft Entra ID Groups"
                            ]
                        }
                    },
                    [Contract.TypeNames.AadDirectoryIdentity]: {
                        text: {
                            withoutServiceName: [
                                "identity",
                                "identities"
                            ],
                            withServiceName: [
                                "Microsoft Entra ID identity",
                                "Microsoft Entra ID identities"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Identity",
                                "Identities"
                            ],
                            withServiceName: [
                                "Microsoft Entra ID Identity",
                                "Microsoft Entra ID Identities"
                            ]
                        }
                    },
                    [Contract.TypeNames.AadDirectoryManagedIdentityServicePrincipal]: {
                        text: {
                            withServiceName: [
                                "managed identity",
                                "managed identities"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Managed Identity",
                                "Managed Identities"
                            ]
                        }
                    },
                    [Contract.TypeNames.AadDirectoryPrincipal]: {
                        text: {
                            withoutServiceName: [
                                "principal",
                                "principals"
                            ],
                            withServiceName: [
                                "Microsoft Entra ID principal",
                                "Microsoft Entra ID principals"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Principal",
                                "Principals"
                            ],
                            withServiceName: [
                                "Microsoft Entra ID Principal",
                                "Microsoft Entra ID Principals"
                            ]
                        }
                    },
                    [Contract.TypeNames.AadDirectoryServicePrincipal]: {
                        text: {
                            withoutServiceName: [
                                "application",
                                "applications"
                            ],
                            withServiceName: [
                                "Microsoft Entra ID application",
                                "Microsoft Entra ID applications"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Application",
                                "Applications"
                            ],
                            withServiceName: [
                                "Microsoft Entra ID Application",
                                "Microsoft Entra ID Applications"
                            ]
                        }
                    },
                    [Contract.TypeNames.AadDirectoryUser]: {
                        text: {
                            withoutServiceName: [
                                "user",
                                "users"
                            ],
                            withServiceName: [
                                "Microsoft Entra ID user",
                                "Microsoft Entra ID users"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "User",
                                "Users"
                            ],
                            withServiceName: [
                                "Microsoft Entra ID User",
                                "Microsoft Entra ID Users"
                            ]
                        }
                    },
                    [Contract.TypeNames.AadTenantEntity]: {
                        text: {
                            withoutServiceName: [
                                "tenant",
                                "tenants"
                            ],
                            withServiceName: [
                                "Microsoft Entra ID tenant",
                                "Microsoft Entra ID tenants"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Tenant",
                                "Tenants"
                            ],
                            withServiceName: [
                                "Microsoft Entra ID Tenant",
                                "Microsoft Entra ID Tenants"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsAcmCertificate]: {
                        text: {
                            withoutServiceName: [
                                "certificate",
                                "certificates"
                            ],
                            withServiceName: [
                                "ACM certificate",
                                "ACM certificates"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Certificate",
                                "Certificates"
                            ],
                            withServiceName: [
                                "ACM Certificate",
                                "ACM Certificates"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsAossCollection]: {
                        text: {
                            withServiceName: [
                                "OpenSearch collection",
                                "OpenSearch collections"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "OpenSearch Collection",
                                "OpenSearch Collections"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsApiGatewayApi]: {
                        text: {
                            withServiceName: [
                                "API gateway API",
                                "API gateway APIs"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "API Gateway API",
                                "API Gateway APIs"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsApiGatewayV1Api]: {
                        text: {
                            withServiceName: [
                                "API gateway API (v1)",
                                "API gateway APIs (v1)"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "API Gateway API (v1)",
                                "API Gateway APIs (v1)"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsApiGatewayV2Api]: {
                        text: {
                            withServiceName: [
                                "API gateway API (v2)",
                                "API gateway APIs (v2)"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "API Gateway API (v2)",
                                "API Gateway APIs (v2)"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsAppConfigApplication]: {
                        text: {
                            withoutServiceName: [
                                "application",
                                "applications"
                            ],
                            withServiceName: [
                                "AppConfig application",
                                "AppConfig applications"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Application",
                                "Applications"
                            ],
                            withServiceName: [
                                "AppConfig Application",
                                "AppConfig Applications"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsAppSyncGraphQlApi]: {
                        text: {
                            withoutServiceName: [
                                "graph ql api",
                                "graph ql apis"
                            ],
                            withServiceName: [
                                "AppSync graph ql api",
                                "AppSync graph ql apis"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Graph Ql Api",
                                "Graph Ql Apis"
                            ],
                            withServiceName: [
                                "AppSync Graph Ql Api",
                                "AppSync Graph Ql Apis"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsAthenaWorkgroup]: {
                        text: {
                            withoutServiceName: [
                                "workgroup",
                                "workgroups"
                            ],
                            withServiceName: [
                                "Athena workgroup",
                                "Athena workgroups"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Workgroup",
                                "Workgroups"
                            ],
                            withServiceName: [
                                "Athena Workgroup",
                                "Athena Workgroups"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsAutoScalingAutoScalingGroup]: {
                        text: {
                            withServiceName: [
                                "Auto Scaling group",
                                "Auto Scaling groups"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Auto Scaling Group",
                                "Auto Scaling Groups"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsAutoScalingLaunchConfiguration]: {
                        text: {
                            withServiceName: [
                                "launch configuration",
                                "launch configurations"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Launch Configuration",
                                "Launch Configurations"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsBatchComputeEnvironment]: {
                        text: {
                            withServiceName: [
                                "Batch compute environment",
                                "Batch compute environments"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Batch Compute Environment",
                                "Batch Compute Environments"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsBatchJobDefinition]: {
                        text: {
                            withServiceName: [
                                "Batch job",
                                "Batch jobs"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Batch Job",
                                "Batch Jobs"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsBedrockAgent]: {
                        text: {
                            withoutServiceName: [
                                "agent",
                                "agents"
                            ],
                            withServiceName: [
                                "Bedrock agent",
                                "Bedrock agents"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Agent",
                                "Agents"
                            ],
                            withServiceName: [
                                "Bedrock Agent",
                                "Bedrock Agents"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsBedrockCustomModel]: {
                        text: {
                            withoutServiceName: [
                                "custom model",
                                "custom models"
                            ],
                            withServiceName: [
                                "Bedrock custom model",
                                "Bedrock custom models"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Custom Model",
                                "Custom Models"
                            ],
                            withServiceName: [
                                "Bedrock Custom Model",
                                "Bedrock Custom Models"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsCloudFormationStack]: {
                        text: {
                            withoutServiceName: [
                                "stack",
                                "stacks"
                            ],
                            withServiceName: [
                                "CloudFormation stack",
                                "CloudFormation stacks"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Stack",
                                "Stacks"
                            ],
                            withServiceName: [
                                "CloudFormation Stack",
                                "CloudFormation Stacks"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsCloudFrontDistribution]: {
                        text: {
                            withoutServiceName: [
                                "distribution",
                                "distributions"
                            ],
                            withServiceName: [
                                "CloudFront distribution",
                                "CloudFront distributions"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Distribution",
                                "Distributions"
                            ],
                            withServiceName: [
                                "CloudFront Distribution",
                                "CloudFront Distributions"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsCloudTrailTrail]: {
                        text: {
                            withoutServiceName: [
                                "trail",
                                "trails"
                            ],
                            withServiceName: [
                                "CloudTrail trail",
                                "CloudTrail trails"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Trail",
                                "Trails"
                            ],
                            withServiceName: [
                                "CloudTrail Trail",
                                "CloudTrail Trails"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsCloudWatchAlarm]: {
                        text: {
                            withServiceName: [
                                "CloudWatch alarm",
                                "CloudWatch alarms"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "CloudWatch Alarm",
                                "CloudWatch Alarms"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsCognitoIdpUserPool]: {
                        text: {
                            withoutServiceName: [
                                "user pool",
                                "user pools"
                            ],
                            withServiceName: [
                                "Cognito user pool",
                                "Cognito user pools"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "User Pool",
                                "User Pools"
                            ],
                            withServiceName: [
                                "Cognito User Pool",
                                "Cognito User Pools"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsConfigConfigurationRecorder]: {
                        text: {
                            withServiceName: [
                                "Config configuration recorder",
                                "Config configuration recorders"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Config Configuration Recorder",
                                "Config Configuration Recorders"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsContainerImage]: {
                        text: {
                            withoutServiceName: [
                                "container image",
                                "container images"
                            ],
                            withServiceName: [
                                "container Image",
                                "container Images"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Container Image",
                                "Container Images"
                            ],
                            withServiceName: [
                                "Container Image",
                                "Container Images"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsDocDbElasticCluster]: {
                        text: {
                            withServiceName: [
                                "DocumentDB elastic cluster",
                                "DocumentDB elastic clusters"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "DocumentDB Elastic Cluster",
                                "DocumentDB Elastic Clusters"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsDocDbElasticClusterSnapshot]: {
                        text: {
                            withServiceName: [
                                "DocumentDB elastic cluster snapshot",
                                "DocumentDB elastic cluster snapshots"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "DocumentDB Elastic Cluster Snapshot",
                                "DocumentDB Elastic Cluster Snapshots"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsDynamoDbTable]: {
                        text: {
                            withoutServiceName: [
                                "table",
                                "tables"
                            ],
                            withServiceName: [
                                "DynamoDB table",
                                "DynamoDB tables"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Table",
                                "Tables"
                            ],
                            withServiceName: [
                                "DynamoDB Table",
                                "DynamoDB Tables"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsEc2CoipPool]: {
                        text: {
                            withServiceName: [
                                "EC2 coip pool",
                                "EC2 coip pools"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "EC2 Coip Pool",
                                "EC2 Coip Pools"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsEc2DatabricksInstanceGroup]: {
                        text: {
                            withoutServiceName: [
                                "databricks cluster",
                                "databricks clusters"
                            ],
                            withServiceName: [
                                "Databricks cluster",
                                "Databricks clusters"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Databricks Cluster",
                                "Databricks Clusters"
                            ],
                            withServiceName: [
                                "Databricks Cluster",
                                "Databricks Clusters"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsEc2ElasticIp]: {
                        text: {
                            withoutServiceName: [
                                "elastic ip",
                                "elastic ips"
                            ],
                            withServiceName: [
                                "EC2 elastic ip",
                                "EC2 elastic ips"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Elastic IP",
                                "Elastic IPs"
                            ],
                            withServiceName: [
                                "EC2 Elastic IP",
                                "EC2 Elastic IPs"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsEc2FlowLog]: {
                        text: {
                            withServiceName: [
                                "EC2 flow log",
                                "EC2 flow logs"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "EC2 Flow Log",
                                "EC2 Flow Logs"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsEc2Image]: {
                        text: {
                            withServiceName: [
                                "EC2 image",
                                "EC2 images"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "EC2 Image",
                                "EC2 Images"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsEc2Instance]: {
                        text: {
                            withServiceName: [
                                "EC2 instance",
                                "EC2 instances"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "EC2 Instance",
                                "EC2 Instances"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsEc2InternetGateway]: {
                        text: {
                            withServiceName: [
                                "internet gateway",
                                "internet gateways"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Internet Gateway",
                                "Internet Gateways"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsEc2IpamPool]: {
                        text: {
                            withServiceName: [
                                "EC2 ipam pool",
                                "EC2 ipam pools"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "EC2 Ipam Pool",
                                "EC2 Ipam Pools"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsEc2LaunchTemplate]: {
                        text: {
                            withServiceName: [
                                "launch template",
                                "launch templates"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Launch Template",
                                "Launch Templates"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsEc2LocalGatewayRouteTable]: {
                        text: {
                            withServiceName: [
                                "EC2 local gateway route table",
                                "EC2 local gateway route tables"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "EC2 Local Gateway Route Table",
                                "EC2 Local Gateway Route Tables"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsEc2NatGateway]: {
                        text: {
                            withServiceName: [
                                "NAT gateway",
                                "NAT gateways"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "NAT Gateway",
                                "NAT Gateways"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsEc2NetworkAcl]: {
                        text: {
                            withServiceName: [
                                "network ACL",
                                "network ACLs"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Network ACL",
                                "Network ACLs"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsEc2NetworkInterface]: {
                        text: {
                            withServiceName: [
                                "network interface",
                                "network interfaces"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Network Interface",
                                "Network Interfaces"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsEc2RouteTable]: {
                        text: {
                            withServiceName: [
                                "route table",
                                "route tables"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Route Table",
                                "Route Tables"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsEc2SecurityGroup]: {
                        text: {
                            withoutServiceName: [
                                "security group",
                                "security groups"
                            ],
                            withServiceName: [
                                "security group",
                                "security groups"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Security Group",
                                "Security Groups"
                            ],
                            withServiceName: [
                                "Security Group",
                                "Security Groups"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsEc2Snapshot]: {
                        text: {
                            withServiceName: [
                                "EBS snapshot",
                                "EBS snapshots"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "EBS Snapshot",
                                "EBS Snapshots"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsEc2SpotInstanceGroup]: {
                        text: {
                            withoutServiceName: [
                                "spot elasticsearch",
                                "spot elasticsearch"
                            ],
                            withServiceName: [
                                "Spot Elasticsearch",
                                "Spot Elasticsearch"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Spot Elasticsearch",
                                "Spot Elasticsearch"
                            ],
                            withServiceName: [
                                "Spot Elasticsearch",
                                "Spot Elasticsearch"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsEc2Subnet]: {
                        text: {
                            withServiceName: [
                                "subnet",
                                "subnets"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Subnet",
                                "Subnets"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsEc2Volume]: {
                        text: {
                            withServiceName: [
                                "EBS volume",
                                "EBS volumes"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "EBS Volume",
                                "EBS Volumes"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsEc2Vpc]: {
                        text: {
                            withServiceName: [
                                "VPC",
                                "VPCs"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "VPC",
                                "VPCs"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsEc2VpcBlockPublicAccessPolicy]: {
                        text: {
                            withServiceName: [
                                "VPC Block public access settings",
                                "VPC Block public access settings"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "VPC Block Public Access Settings",
                                "VPC Block Public Access Settings"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsEc2VpcEndpoint]: {
                        text: {
                            withoutServiceName: [
                                "VPC endpoint",
                                "VPC endpoints"
                            ],
                            withServiceName: [
                                "VPC endpoint",
                                "VPC endpoints"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "VPC Endpoint",
                                "VPC Endpoints"
                            ],
                            withServiceName: [
                                "VPC Endpoint",
                                "VPC Endpoints"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsEc2VpcPeeringConnection]: {
                        text: {
                            withoutServiceName: [
                                "VPC peering connection",
                                "VPC peering connections"
                            ],
                            withServiceName: [
                                "VPC peering connection",
                                "VPC peering connections"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "VPC Peering Connection",
                                "VPC Peering Connections"
                            ],
                            withServiceName: [
                                "VPC Peering Connection",
                                "VPC Peering Connections"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsEcrRepository]: {
                        text: {
                            withoutServiceName: [
                                "repository",
                                "repositories"
                            ],
                            withServiceName: [
                                "ECR repository",
                                "ECR repositories"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Repository",
                                "Repositories"
                            ],
                            withServiceName: [
                                "ECR Repository",
                                "ECR Repositories"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsEcsCluster]: {
                        text: {
                            withServiceName: [
                                "ECS cluster",
                                "ECS clusters"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "ECS Cluster",
                                "ECS Clusters"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsEcsService]: {
                        text: {
                            withServiceName: [
                                "ECS service",
                                "ECS services"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "ECS Service",
                                "ECS Services"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsEcsTask]: {
                        text: {
                            withServiceName: [
                                "ECS task",
                                "ECS tasks"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "ECS Task",
                                "ECS Tasks"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsEcsTaskDefinition]: {
                        text: {
                            withServiceName: [
                                "ECS task definition",
                                "ECS task definitions"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "ECS Task Definition",
                                "ECS Task Definitions"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsEksCluster]: {
                        text: {
                            withServiceName: [
                                "EKS cluster",
                                "EKS clusters"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "EKS Cluster",
                                "EKS Clusters"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsEksClusterNodeGroup]: {
                        text: {
                            withoutServiceName: [
                                "cluster node group",
                                "cluster node groups"
                            ],
                            withServiceName: [
                                "EKS node group",
                                "EKS node groups"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Cluster Node Group",
                                "Cluster Node Groups"
                            ],
                            withServiceName: [
                                "EKS Node Group",
                                "EKS Node Groups"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsEksFargateProfile]: {
                        text: {
                            withServiceName: [
                                "EKS fargate profile",
                                "EKS fargate profiles"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "EKS Fargate Profile",
                                "EKS Fargate Profiles"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsElastiCacheReplicationGroup]: {
                        text: {
                            withoutServiceName: [
                                "Redis OSS Cluster cache",
                                "Redis OSS Cluster caches"
                            ],
                            withServiceName: [
                                "ElastiCache Redis OSS Cluster cache",
                                "ElastiCache Redis OSS Cluster caches"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Redis OSS Cluster Cache",
                                "Redis OSS Cluster Caches"
                            ],
                            withServiceName: [
                                "ElastiCache Redis OSS Cluster Cache",
                                "ElastiCache Redis OSS Cluster Caches"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsElastiCacheCache]: {
                        text: {
                            withoutServiceName: [
                                "Redis OSS cache",
                                "Redis OSS caches"
                            ],
                            withServiceName: [
                                "ElastiCache Redis OSS cache",
                                "ElastiCache Redis OSS caches"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Redis OSS Cache",
                                "Redis OSS Caches"
                            ],
                            withServiceName: [
                                "ElastiCache Redis OSS Cache",
                                "ElastiCache Redis OSS Caches"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsElastiCacheServerlessCache]: {
                        text: {
                            withoutServiceName: [
                                "Redis OSS Serverless cache",
                                "Redis OSS Serverless caches"
                            ],
                            withServiceName: [
                                "ElastiCache Redis OSS Serverless cache",
                                "ElastiCache Redis OSS Serverless caches"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Redis OSS Serverless Cache",
                                "Redis OSS Serverless Caches"
                            ],
                            withServiceName: [
                                "ElastiCache Redis OSS Serverless Cache",
                                "ElastiCache Redis OSS Serverless Caches"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsElasticBeanstalkApplication]: {
                        text: {
                            withServiceName: [
                                "Elastic Beanstalk application",
                                "Elastic Beanstalk applications"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Elastic Beanstalk Environment Application",
                                "Elastic Beanstalk Environment Applications"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsElasticBeanstalkEnvironment]: {
                        text: {
                            withServiceName: [
                                "Elastic Beanstalk environment",
                                "Elastic Beanstalk environments"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Elastic Beanstalk Environment",
                                "Elastic Beanstalk Environments"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsElasticFileSystemFileSystem]: {
                        text: {
                            withServiceName: [
                                "EFS file system",
                                "EFS file systems"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "EFS File System",
                                "EFS File Systems"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsElbApplicationLoadBalancer]: {
                        text: {
                            withServiceName: [
                                "application load balancer",
                                "application load balancers"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Application Load Balancer",
                                "Application Load Balancers"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsElbApplicationLoadBalancerListener]: {
                        text: {
                            withServiceName: [
                                "application load balancer listener",
                                "application load balancer listeners"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Application Load Balancer Listener",
                                "Application Load Balancer Listeners"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsElbClassicLoadBalancer]: {
                        text: {
                            withServiceName: [
                                "classic load balancer",
                                "classic load balancers"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Classic Load Balancer",
                                "Classic Load Balancers"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsElbClassicLoadBalancerListener]: {
                        text: {
                            withServiceName: [
                                "classic load balancer listener",
                                "classic load balancer listeners"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Classic Load Balancer Listener",
                                "Classic Load Balancer Listeners"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsElbLoadBalancer]: {
                        text: {
                            withServiceName: [
                                "load balancer",
                                "load balancers"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Load Balancer",
                                "Load Balancers"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsElbLoadBalancerListener]: {
                        text: {
                            withServiceName: [
                                "load balancer listener",
                                "load balancer listeners"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Load Balancer Listener",
                                "Load Balancer Listeners"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsElbNetworkLoadBalancer]: {
                        text: {
                            withServiceName: [
                                "network load balancer",
                                "network load balancers"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Network Load Balancer",
                                "Network Load Balancers"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsElbNetworkLoadBalancerListener]: {
                        text: {
                            withServiceName: [
                                "network load balancer listener",
                                "network load balancer listeners"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Network Load Balancer Listener",
                                "Network Load Balancer Listeners"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsElbTargetGroup]: {
                        text: {
                            withoutServiceName: [
                                "target group",
                                "target groups"
                            ],
                            withServiceName: [
                                "target group",
                                "target groups"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Target Group",
                                "Target Groups"
                            ],
                            withServiceName: [
                                "Target Group",
                                "Target Groups"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsEmrCluster]: {
                        text: {
                            withServiceName: [
                                "EMR cluster",
                                "EMR clusters"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "EMR Cluster",
                                "EMR Clusters"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsEmrSecurityConfiguration]: {
                        text: {
                            withoutServiceName: [
                                "security configuration",
                                "security configurations"
                            ],
                            withServiceName: [
                                "EMR security configuration",
                                "EMR security configurations"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Security Configuration",
                                "Security Configurations"
                            ],
                            withServiceName: [
                                "EMR Security Configuration",
                                "EMR Security Configurations"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsEsDomain]: {
                        text: {
                            withoutServiceName: [
                                "domain",
                                "domains"
                            ],
                            withServiceName: [
                                "OpenSearch domain",
                                "OpenSearch domains"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Domain",
                                "Domains"
                            ],
                            withServiceName: [
                                "OpenSearch Domain",
                                "OpenSearch Domains"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsEventsRuleOnCustomEventBus]: {
                        text: {
                            withoutServiceName: [
                                "rule",
                                "rules"
                            ],
                            withServiceName: [
                                "Events rule",
                                "Events rules"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Rule",
                                "Rules"
                            ],
                            withServiceName: [
                                "Events Rule",
                                "Events Rules"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsEventsRuleOnDefaultEventBus]: {
                        text: {
                            withoutServiceName: [
                                "rule",
                                "rules"
                            ],
                            withServiceName: [
                                "Events rule",
                                "Events rules"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Rule",
                                "Rules"
                            ],
                            withServiceName: [
                                "Events Rule",
                                "Events Rules"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsFirehoseDeliveryStream]: {
                        text: {
                            withoutServiceName: [
                                "stream",
                                "streams"
                            ],
                            withServiceName: [
                                "Data Firehose stream",
                                "Data Firehose streams"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Stream",
                                "Streams"
                            ],
                            withServiceName: [
                                "Data Firehose Stream",
                                "Data Firehose Streams"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsIamGroup]: {
                        text: {
                            withoutServiceName: [
                                "group",
                                "groups"
                            ],
                            withServiceName: [
                                "IAM group",
                                "IAM groups"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Group",
                                "Groups"
                            ],
                            withServiceName: [
                                "IAM Group",
                                "IAM Groups"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsIamIdentity]: {
                        text: {
                            withoutServiceName: [
                                "identity",
                                "identities"
                            ],
                            withServiceName: [
                                "IAM identity",
                                "IAM identities"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Identity",
                                "Identities"
                            ],
                            withServiceName: [
                                "IAM Identity",
                                "IAM Identities"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsIamInlinePolicy]: {
                        text: {
                            withoutServiceName: [
                                "inline policy",
                                "inline policies"
                            ],
                            withServiceName: [
                                "IAM inline policy",
                                "IAM inline policies"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Inline Policy",
                                "Inline Policies"
                            ],
                            withServiceName: [
                                "IAM Inline Policy",
                                "IAM Inline Policies"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsIamInstanceProfile]: {
                        text: {
                            withServiceName: [
                                "IAM instance profile",
                                "IAM instance profiles"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "IAM Instance Profile",
                                "IAM Instance Profiles"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsIamManagedPolicy]: {
                        text: {
                            withoutServiceName: [
                                "managed policy",
                                "managed policies"
                            ],
                            withServiceName: [
                                "IAM managed policy",
                                "IAM managed policies"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Managed Policy",
                                "Managed Policies"
                            ],
                            withServiceName: [
                                "IAM Managed Policy",
                                "IAM Managed Policies"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsIamOidcIdentityProvider]: {
                        text: {
                            withServiceName: [
                                "IAM OIDC identity provider",
                                "IAM OIDC identity providers"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "IAM OIDC Identity Provider",
                                "IAM OIDC Identity Providers"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsIamPolicy]: {
                        text: {
                            withoutServiceName: [
                                "policy",
                                "policies"
                            ],
                            withServiceName: [
                                "IAM policy",
                                "IAM policies"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Policy",
                                "Policies"
                            ],
                            withServiceName: [
                                "IAM Policy",
                                "IAM Policies"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsIamPrincipal]: {
                        text: {
                            withoutServiceName: [
                                "principal",
                                "principals"
                            ],
                            withServiceName: [
                                "IAM principal",
                                "IAM principals"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Principal",
                                "Principals"
                            ],
                            withServiceName: [
                                "IAM Principal",
                                "IAM Principals"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsIamPrincipalPolicy]: {
                        text: {
                            withoutServiceName: [
                                "policy",
                                "policies"
                            ],
                            withServiceName: [
                                "IAM policy",
                                "IAM policies"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Policy",
                                "Policies"
                            ],
                            withServiceName: [
                                "IAM Policy",
                                "IAM Policies"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsIamRole]: {
                        text: {
                            withoutServiceName: [
                                "role",
                                "roles"
                            ],
                            withServiceName: [
                                "IAM role",
                                "IAM roles"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Role",
                                "Roles"
                            ],
                            withServiceName: [
                                "IAM Role",
                                "IAM Roles"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsIamRoleGroup]: {
                        text: {
                            withoutServiceName: [
                                "organization role",
                                "organization roles"
                            ],
                            withServiceName: [
                                "IAM organization role",
                                "IAM organization roles"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Organization Role",
                                "Organization Roles"
                            ],
                            withServiceName: [
                                "IAM Organization role",
                                "IAM Organization roles"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsIamRootUser]: {
                        text: {
                            withServiceName: [
                                "root user",
                                "root users"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Root User",
                                "Root Users"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsIamSamlIdentityProvider]: {
                        text: {
                            withServiceName: [
                                "IAM SAML identity provider",
                                "IAM SAML identity providers"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "IAM SAML Identity Provider",
                                "IAM SAML Identity Providers"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsIamServerCertificate]: {
                        text: {
                            withServiceName: [
                                "IAM server certificate",
                                "IAM server certificates"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "IAM Server Certificate",
                                "IAM Server Certificates"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsIamUser]: {
                        text: {
                            withoutServiceName: [
                                "user",
                                "users"
                            ],
                            withServiceName: [
                                "IAM user",
                                "IAM users"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "User",
                                "Users"
                            ],
                            withServiceName: [
                                "IAM User",
                                "IAM Users"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsIamVirtualMfaDevice]: {
                        text: {
                            withServiceName: [
                                "IAM virtual MFA device",
                                "IAM virtual MFA devices"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "IAM Virtual MFA Device",
                                "IAM Virtual MFA Devices"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsIotDomainConfiguration]: {
                        text: {
                            withoutServiceName: [
                                "domain configuration",
                                "domain configurations"
                            ],
                            withServiceName: [
                                "IOT domain configuration",
                                "IOT domain configurations"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Domain Configuration",
                                "Domain Configurations"
                            ],
                            withServiceName: [
                                "IOT Domain Configuration",
                                "IOT Domain Configurations"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsKafkaCluster]: {
                        text: {
                            withoutServiceName: [
                                "cluster",
                                "clusters"
                            ],
                            withServiceName: [
                                "Kafka cluster",
                                "Kafka clusters"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Cluster",
                                "Clusters"
                            ],
                            withServiceName: [
                                "Kafka Cluster",
                                "Kafka Clusters"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsKinesisStream]: {
                        text: {
                            withoutServiceName: [
                                "data stream",
                                "data streams"
                            ],
                            withServiceName: [
                                "Kinesis data stream",
                                "Kinesis data streams"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Data Stream",
                                "Data Streams"
                            ],
                            withServiceName: [
                                "Kinesis Data Stream",
                                "Kinesis Data Streams"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsKmsAlias]: {
                        text: {
                            withServiceName: [
                                "KMS alias",
                                "KMS aliases"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "KMS Alias",
                                "KMS Aliases"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsKmsKey]: {
                        text: {
                            withoutServiceName: [
                                "key",
                                "keys"
                            ],
                            withServiceName: [
                                "KMS key",
                                "KMS keys"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Key",
                                "Keys"
                            ],
                            withServiceName: [
                                "KMS Key",
                                "KMS Keys"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsKubernetesClusterRole]: {
                        text: {
                            withoutServiceName: [
                                "cluster role",
                                "cluster roles"
                            ],
                            withServiceName: [
                                "K8s cluster role",
                                "K8s cluster roles"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Cluster Role",
                                "Cluster Roles"
                            ],
                            withServiceName: [
                                "K8s Cluster Role",
                                "K8s Cluster Roles"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsKubernetesClusterRoleBinding]: {
                        text: {
                            withoutServiceName: [
                                "cluster role binding",
                                "cluster role bindings"
                            ],
                            withServiceName: [
                                "K8s cluster role binding",
                                "K8s cluster role bindings"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Cluster Role Binding",
                                "Cluster Role Bindings"
                            ],
                            withServiceName: [
                                "K8s Cluster Role Binding",
                                "K8s Cluster Role Bindings"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsKubernetesConfigMap]: {
                        text: {
                            withoutServiceName: [
                                "config map",
                                "config maps"
                            ],
                            withServiceName: [
                                "K8s config map",
                                "K8s config maps"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Config Map",
                                "Config Maps"
                            ],
                            withServiceName: [
                                "K8s Config Map",
                                "K8s Config Maps"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsKubernetesCronJob]: {
                        text: {
                            withoutServiceName: [
                                "cron job",
                                "cron jobs"
                            ],
                            withServiceName: [
                                "K8s cron job",
                                "K8s cron jobs"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Cron Job",
                                "Cron Jobs"
                            ],
                            withServiceName: [
                                "K8s Cron Job",
                                "K8s Cron Jobs"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsKubernetesDaemonSet]: {
                        text: {
                            withoutServiceName: [
                                "daemon set",
                                "daemon sets"
                            ],
                            withServiceName: [
                                "K8s daemon set",
                                "K8s daemon sets"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Daemon Set",
                                "Daemon Sets"
                            ],
                            withServiceName: [
                                "K8s Daemon Set",
                                "K8s Daemon Sets"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsKubernetesDeployment]: {
                        text: {
                            withoutServiceName: [
                                "deployment",
                                "deployments"
                            ],
                            withServiceName: [
                                "K8s deployment",
                                "K8s deployments"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Deployment",
                                "Deployments"
                            ],
                            withServiceName: [
                                "K8s Deployment",
                                "K8s Deployments"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsKubernetesGroup]: {
                        text: {
                            withoutServiceName: [
                                "group",
                                "groups"
                            ],
                            withServiceName: [
                                "K8s group",
                                "K8s groups"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Group",
                                "Groups"
                            ],
                            withServiceName: [
                                "K8s Group",
                                "K8s Groups"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsKubernetesIngress]: {
                        text: {
                            withoutServiceName: [
                                "ingress",
                                "ingresses"
                            ],
                            withServiceName: [
                                "K8s ingress",
                                "K8s ingresses"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Ingress",
                                "Ingresses"
                            ],
                            withServiceName: [
                                "K8s Ingress",
                                "K8s Ingresses"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsKubernetesIngressClass]: {
                        text: {
                            withoutServiceName: [
                                "ingress class",
                                "ingress classes"
                            ],
                            withServiceName: [
                                "K8s ingress class",
                                "K8s ingress classes"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Ingress class",
                                "Ingress classes"
                            ],
                            withServiceName: [
                                "K8s Ingress class",
                                "K8s Ingress classes"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsKubernetesJob]: {
                        text: {
                            withoutServiceName: [
                                "job",
                                "jobs"
                            ],
                            withServiceName: [
                                "K8s job",
                                "K8s jobs"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Job",
                                "Jobs"
                            ],
                            withServiceName: [
                                "K8s Job",
                                "K8s Jobs"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsKubernetesNamespace]: {
                        text: {
                            withoutServiceName: [
                                "namespace",
                                "namespaces"
                            ],
                            withServiceName: [
                                "K8s namespace",
                                "K8s namespaces"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Namespace",
                                "Namespaces"
                            ],
                            withServiceName: [
                                "K8s Namespace",
                                "K8s Namespaces"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsKubernetesNamespaceRole]: {
                        text: {
                            withoutServiceName: [
                                "role",
                                "roles"
                            ],
                            withServiceName: [
                                "K8s role",
                                "K8s roles"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Role",
                                "Roles"
                            ],
                            withServiceName: [
                                "K8s Role",
                                "K8s Roles"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsKubernetesNamespaceRoleBinding]: {
                        text: {
                            withoutServiceName: [
                                "role binding",
                                "role bindings"
                            ],
                            withServiceName: [
                                "K8s role binding",
                                "K8s role bindings"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Role Binding",
                                "Role Bindings"
                            ],
                            withServiceName: [
                                "K8s Role Binding",
                                "K8s Role Bindings"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsKubernetesNetworkPolicy]: {
                        text: {
                            withoutServiceName: [
                                "network policy",
                                "network policies"
                            ],
                            withServiceName: [
                                "K8s network policy",
                                "K8s network policies"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Network Policy",
                                "Network Policies"
                            ],
                            withServiceName: [
                                "K8s Network Policy",
                                "K8s Network Policies"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsKubernetesNode]: {
                        text: {
                            withoutServiceName: [
                                "node",
                                "nodes"
                            ],
                            withServiceName: [
                                "K8s node",
                                "K8s nodes"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Node",
                                "Nodes"
                            ],
                            withServiceName: [
                                "K8s Node",
                                "K8s Nodes"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsKubernetesPersistentVolume]: {
                        text: {
                            withoutServiceName: [
                                "persistent volume",
                                "persistent volumes"
                            ],
                            withServiceName: [
                                "K8s persistent volume",
                                "K8s persistent volumes"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Persistent Volume",
                                "Persistent Volumes"
                            ],
                            withServiceName: [
                                "K8s Persistent Volume",
                                "K8s Persistent Volumes"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsKubernetesPod]: {
                        text: {
                            withoutServiceName: [
                                "pod",
                                "pods"
                            ],
                            withServiceName: [
                                "K8s pod",
                                "K8s pod"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Pod",
                                "Pods"
                            ],
                            withServiceName: [
                                "K8s Pod",
                                "K8s Pod"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsKubernetesPodSecurityPolicy]: {
                        text: {
                            withoutServiceName: [
                                "pod security policy",
                                "pod security policies"
                            ],
                            withServiceName: [
                                "K8s pod security policy",
                                "K8s pod security policies"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Pod Security Policy",
                                "Pod Security Policies"
                            ],
                            withServiceName: [
                                "K8s Pod Security Policy",
                                "K8s Pod Security Policies"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsKubernetesPodTemplate]: {
                        text: {
                            withoutServiceName: [
                                "pod template",
                                "pod templates"
                            ],
                            withServiceName: [
                                "K8s pod template",
                                "K8s pod templates"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Pod Template",
                                "Pod Templates"
                            ],
                            withServiceName: [
                                "K8s Pod Template",
                                "K8s Pod Templates"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsKubernetesReplicaSet]: {
                        text: {
                            withoutServiceName: [
                                "replica set",
                                "replica sets"
                            ],
                            withServiceName: [
                                "K8s replica set",
                                "K8s replica sets"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Replica Set",
                                "Replica Sets"
                            ],
                            withServiceName: [
                                "K8s Replica Set",
                                "K8s Replica Sets"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsKubernetesResource]: {
                        text: {
                            withoutServiceName: [
                                "resource",
                                "resources"
                            ],
                            withServiceName: [
                                "K8s resource",
                                "K8s resources"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Resource",
                                "Resources"
                            ],
                            withServiceName: [
                                "K8s Resource",
                                "K8s Resources"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsKubernetesService]: {
                        text: {
                            withoutServiceName: [
                                "service",
                                "services"
                            ],
                            withServiceName: [
                                "K8s service",
                                "K8s services"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Service",
                                "Services"
                            ],
                            withServiceName: [
                                "K8s Service",
                                "K8s Services"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsKubernetesServiceAccount]: {
                        text: {
                            withoutServiceName: [
                                "service account",
                                "service accounts"
                            ],
                            withServiceName: [
                                "K8s service account",
                                "K8s service accounts"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Service Account",
                                "Service Accounts"
                            ],
                            withServiceName: [
                                "K8s Service Account",
                                "K8s Service Accounts"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsKubernetesStatefulSet]: {
                        text: {
                            withoutServiceName: [
                                "stateful set",
                                "stateful sets"
                            ],
                            withServiceName: [
                                "K8s stateful set",
                                "K8s stateful sets"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Stateful Set",
                                "Stateful Sets"
                            ],
                            withServiceName: [
                                "K8s Stateful Set",
                                "K8s Stateful Sets"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsKubernetesUser]: {
                        text: {
                            withoutServiceName: [
                                "user",
                                "users"
                            ],
                            withServiceName: [
                                "K8s user",
                                "K8s users"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "User",
                                "Users"
                            ],
                            withServiceName: [
                                "K8s User",
                                "K8s Users"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsKubernetesWorkloadResourceBase]: {
                        text: {
                            withoutServiceName: [
                                "workload",
                                "workloads"
                            ],
                            withServiceName: [
                                "K8s workload",
                                "K8s workloads"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Workload",
                                "Workloads"
                            ],
                            withServiceName: [
                                "K8s Workload",
                                "K8s Workloads"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsLambdaFunctionConfiguration]: {
                        text: {
                            withoutServiceName: [
                                "function",
                                "functions"
                            ],
                            withServiceName: [
                                "Lambda function",
                                "Lambda functions"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Function",
                                "Functions"
                            ],
                            withServiceName: [
                                "Lambda Function",
                                "Lambda Functions"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsLogsLogGroup]: {
                        text: {
                            withServiceName: [
                                "CloudWatch Logs log group",
                                "CloudWatch Logs log groups"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "CloudWatch Logs Log Group",
                                "CloudWatch Logs Log Groups"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsOpenShiftBuildConfig]: {
                        text: {
                            withoutServiceName: [
                                "build config",
                                "build configs"
                            ],
                            withServiceName: [
                                "OpenShift build config",
                                "OpenShift build configs"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Build Config",
                                "Build Configs"
                            ],
                            withServiceName: [
                                "OpenShift Build Config",
                                "OpenShift Build Configs"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsOpenShiftCatalogSource]: {
                        text: {
                            withoutServiceName: [
                                "catalog source",
                                "catalog sources"
                            ],
                            withServiceName: [
                                "OpenShift catalog source",
                                "OpenShift catalog sources"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Catalog Source",
                                "Catalog Sources"
                            ],
                            withServiceName: [
                                "OpenShift Catalog Source",
                                "OpenShift Catalog Sources"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsOpenShiftDeploymentConfig]: {
                        text: {
                            withoutServiceName: [
                                "deployment config",
                                "deployment configs"
                            ],
                            withServiceName: [
                                "OpenShift deployment config",
                                "OpenShift deployment configs"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Deployment Config",
                                "Deployment Configs"
                            ],
                            withServiceName: [
                                "OpenShift Deployment Config",
                                "OpenShift Deployment Configs"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsOpenShiftImageStream]: {
                        text: {
                            withoutServiceName: [
                                "image stream",
                                "image streams"
                            ],
                            withServiceName: [
                                "OpenShift image stream",
                                "OpenShift image streams"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Image Stream",
                                "Image Streams"
                            ],
                            withServiceName: [
                                "OpenShift Image Stream",
                                "OpenShift Image Streams"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsOpenShiftProject]: {
                        text: {
                            withoutServiceName: [
                                "project",
                                "projects"
                            ],
                            withServiceName: [
                                "OpenShift project",
                                "OpenShift projects"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Project",
                                "Projects"
                            ],
                            withServiceName: [
                                "OpenShift Project",
                                "OpenShift Projects"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsOpenShiftRoute]: {
                        text: {
                            withoutServiceName: [
                                "route",
                                "routes"
                            ],
                            withServiceName: [
                                "OpenShift route",
                                "OpenShift routes"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Route",
                                "Routes"
                            ],
                            withServiceName: [
                                "OpenShift Route",
                                "OpenShift Routes"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsOpenShiftSecurityContextConstraint]: {
                        text: {
                            withoutServiceName: [
                                "security context constraint",
                                "security context constraints"
                            ],
                            withServiceName: [
                                "OpenShift security context constraint",
                                "OpenShift security context constraints"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Security Context Constraint",
                                "Security Context Constraints"
                            ],
                            withServiceName: [
                                "OpenShift Security Context Constraint",
                                "OpenShift Security Context Constraints"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsOpenShiftTemplate]: {
                        text: {
                            withoutServiceName: [
                                "template",
                                "templates"
                            ],
                            withServiceName: [
                                "OpenShift template",
                                "OpenShift templates"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Template",
                                "Templates"
                            ],
                            withServiceName: [
                                "OpenShift Template",
                                "OpenShift Templates"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsOrganizationsAccount]: {
                        text: {
                            withServiceName: [
                                "Organization account",
                                "Organization accounts"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Organization Account",
                                "Organization Accounts"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsOrganizationsOrganization]: {
                        text: {
                            withServiceName: [
                                "Organizations organization",
                                "Organizations organizations"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Organizations Organization",
                                "Organizations Organizations"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsOrganizationsOrganizationalUnit]: {
                        text: {
                            withServiceName: [
                                "Organizations organizational unit",
                                "Organizations organizational units"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Organizations Organizational Unit",
                                "Organizations Organizational Units"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsOrganizationsRoot]: {
                        text: {
                            withServiceName: [
                                "Organizations root",
                                "Organizations roots"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Organizations Root",
                                "Organizations Roots"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsOrganizationsPolicy]: {
                        text: {
                            withServiceName: [
                                "Organization policy",
                                "Organization policies"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Organization Policy",
                                "Organization Policies"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsPartialTenantEntity]: {
                        text: {
                            withServiceName: [
                                "account (external)",
                                "accounts (external)"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Account (external)",
                                "Accounts (external)"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsRdsAuroraGlobalCluster]: {
                        text: {
                            withServiceName: [
                                "RDS aurora global cluster",
                                "RDS aurora global clusters"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "RDS Aurora Global Cluster",
                                "RDS Aurora Global Clusters"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsRdsCluster]: {
                        text: {
                            withServiceName: [
                                "RDS cluster",
                                "RDS clusters"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "RDS Cluster",
                                "RDS Clusters"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsRdsClusterSnapshot]: {
                        text: {
                            withServiceName: [
                                "RDS cluster snapshot",
                                "RDS cluster snapshots"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "RDS Cluster Snapshot",
                                "RDS Cluster Snapshots"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsRdsDatabaseInstance]: {
                        text: {
                            withServiceName: [
                                "RDS instance",
                                "RDS instances"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "RDS Instance",
                                "RDS Instances"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsRdsDatabaseInstanceSnapshot]: {
                        text: {
                            withServiceName: [
                                "RDS instance snapshot",
                                "RDS instance snapshots"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "RDS Instance Snapshot",
                                "RDS Instance Snapshots"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsRdsDatabaseSecurityGroup]: {
                        text: {
                            withServiceName: [
                                "RDS database security group",
                                "RDS database security groups"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "RDS Database Security Group",
                                "RDS Database Security Groups"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsRdsDocDbCluster]: {
                        text: {
                            withServiceName: [
                                "DocumentDB cluster",
                                "DocumentDB clusters"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "DocumentDB Cluster",
                                "DocumentDB Clusters"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsRdsDocDbClusterSnapshot]: {
                        text: {
                            withServiceName: [
                                "DocumentDB cluster snapshot",
                                "DocumentDB cluster snapshots"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "DocumentDB Cluster Snapshot",
                                "DocumentDB Cluster Snapshots"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsRdsDocDbDatabaseInstance]: {
                        text: {
                            withServiceName: [
                                "DocumentDB instance",
                                "DocumentDB instances"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "DocumentDB Instance",
                                "DocumentDB Instances"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsRdsDocDbGlobalCluster]: {
                        text: {
                            withServiceName: [
                                "DocumentDB global cluster",
                                "DocumentDB global clusters"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "DocumentDB Global Cluster",
                                "DocumentDB Global Clusters"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsRdsNeptuneCluster]: {
                        text: {
                            withServiceName: [
                                "Neptune cluster",
                                "Neptune clusters"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Neptune Cluster",
                                "Neptune Clusters"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsRdsNeptuneClusterSnapshot]: {
                        text: {
                            withServiceName: [
                                "Neptune cluster snapshot",
                                "Neptune cluster snapshots"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Neptune Cluster Snapshot",
                                "Neptune Cluster Snapshots"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsRdsNeptuneDatabaseInstance]: {
                        text: {
                            withServiceName: [
                                "Neptune instance",
                                "Neptune instances"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Neptune Instance",
                                "Neptune Instances"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsRdsNeptuneGlobalCluster]: {
                        text: {
                            withServiceName: [
                                "Neptune global cluster",
                                "Neptune global clusters"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Neptune Global Cluster",
                                "Neptune Global Clusters"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsRdsResource]: {
                        text: {
                            withServiceName: [
                                "RDS resource",
                                "RDS resources"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "RDS Resource",
                                "RDS Resources"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsRdsSnapshot]: {
                        text: {
                            withServiceName: [
                                "RDS snapshot",
                                "RDS snapshots"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "RDS Snapshot",
                                "RDS Snapshots"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsRedshiftCluster]: {
                        text: {
                            withServiceName: [
                                "Redshift cluster",
                                "Redshift clusters"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Redshift Cluster",
                                "Redshift Clusters"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsRedshiftClusterSecurityGroup]: {
                        text: {
                            withServiceName: [
                                "Redshift cluster security group",
                                "Redshift cluster security groups"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Redshift Cluster Security Group",
                                "Redshift Cluster Security Groups"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsRedshiftNamespace]: {
                        text: {
                            withServiceName: [
                                "Redshift namespace",
                                "Redshift namespaces"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Redshift Namespace",
                                "Redshift Namespaces"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsResource]: {
                        text: {
                            withServiceName: [
                                "resource",
                                "resources"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Resource",
                                "Resources"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsRoute53HostedZone]: {
                        text: {
                            withoutServiceName: [
                                "hosted zone",
                                "hosted zones"
                            ],
                            withServiceName: [
                                "Route53 hosted zone",
                                "Route53 hosted zones"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Hosted Zone",
                                "Hosted Zones"
                            ],
                            withServiceName: [
                                "Route53 Hosted Zone",
                                "Route53 Hosted Zones"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsS3Bucket]: {
                        text: {
                            withoutServiceName: [
                                "bucket",
                                "buckets"
                            ],
                            withServiceName: [
                                "S3 bucket",
                                "S3 buckets"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Bucket",
                                "Buckets"
                            ],
                            withServiceName: [
                                "S3 Bucket",
                                "S3 Buckets"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsS3BucketAccessPoint]: {
                        text: {
                            withServiceName: [
                                "S3 bucket access point",
                                "S3 bucket access points"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "S3 Bucket Access Point",
                                "S3 Bucket Access Points"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsSageMakerNotebookInstance]: {
                        text: {
                            withServiceName: [
                                "SageMaker notebook instance",
                                "SageMaker notebook instances"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "SageMaker Notebook Instance",
                                "SageMaker Notebook Instances"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsSecretsManagerSecret]: {
                        text: {
                            withoutServiceName: [
                                "secret",
                                "secrets"
                            ],
                            withServiceName: [
                                "Secrets Manager secret",
                                "Secrets Manager secrets"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Secret",
                                "Secrets"
                            ],
                            withServiceName: [
                                "Secrets Manager Secret",
                                "Secrets Manager Secrets"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsService]: {
                        text: {
                            withServiceName: [
                                "service",
                                "services"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Service",
                                "Services"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsServiceDiscoveryService]: {
                        text: {
                            withoutServiceName: [
                                "service",
                                "services"
                            ],
                            withServiceName: [
                                "Cloud Map service",
                                "Cloud Map services"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Service",
                                "Services"
                            ],
                            withServiceName: [
                                "Cloud Map Service",
                                "Cloud Map Services"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsSesIdentity]: {
                        text: {
                            withServiceName: [
                                "SES identity",
                                "SES identities"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "SES Identity",
                                "SES Identities"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsSnsTopic]: {
                        text: {
                            withoutServiceName: [
                                "topic",
                                "topics"
                            ],
                            withServiceName: [
                                "SNS topic",
                                "SNS topics"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Topic",
                                "Topics"
                            ],
                            withServiceName: [
                                "SNS Topic",
                                "SNS Topics"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsSqsQueue]: {
                        text: {
                            withoutServiceName: [
                                "queue",
                                "queues"
                            ],
                            withServiceName: [
                                "SQS queue",
                                "SQS queues"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Queue",
                                "Queues"
                            ],
                            withServiceName: [
                                "SQS Queue",
                                "SQS Queues"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsSsmParameter]: {
                        text: {
                            withoutServiceName: [
                                "parameter",
                                "parameters"
                            ],
                            withServiceName: [
                                "Systems Manager parameter",
                                "Systems Manager parameters"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Parameter",
                                "Parameters"
                            ],
                            withServiceName: [
                                "Systems Manager Parameter",
                                "Systems Manager Parameters"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsSsmServiceSetting]: {
                        text: {
                            withoutServiceName: [
                                "service setting",
                                "service settings"
                            ],
                            withServiceName: [
                                "SSM service setting",
                                "SSM service settings"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Service Setting",
                                "Service Settings"
                            ],
                            withServiceName: [
                                "SSM Service Setting",
                                "SSM Service Settings"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsSsoGroup]: {
                        text: {
                            withServiceName: [
                                "SSO group",
                                "SSO groups"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "SSO Group",
                                "SSO Groups"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsSsoInstance]: {
                        text: {
                            withServiceName: [
                                "SSO instance",
                                "SSO instances"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "SSO Instance",
                                "SSO Instances"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsSsoPermissionSet]: {
                        text: {
                            withoutServiceName: [
                                "permission set",
                                "permission sets"
                            ],
                            withServiceName: [
                                "SSO permission set",
                                "SSO permission sets"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Permission Set",
                                "Permission Sets"
                            ],
                            withServiceName: [
                                "SSO Permission Set",
                                "SSO Permission Sets"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsSsoPrincipal]: {
                        text: {
                            withServiceName: [
                                "IAM Identity Center principal",
                                "IAM Identity Center principals"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "IAM Identity Center Principal",
                                "IAM Identity Center Principals"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsSsoUser]: {
                        text: {
                            withServiceName: [
                                "SSO user",
                                "SSO users"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "SSO User",
                                "SSO Users"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsStepsFunctionStateMachine]: {
                        text: {
                            withoutServiceName: [
                                "state machine",
                                "state machines"
                            ],
                            withServiceName: [
                                "Step Functions state machine",
                                "Step Functions state machines"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "State Machine",
                                "State Machines"
                            ],
                            withServiceName: [
                                "Step Functions State Machine",
                                "Step Functions State Machines"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsStsFederatedUser]: {
                        text: {
                            withoutServiceName: [
                                "federated user",
                                "federated users"
                            ],
                            withServiceName: [
                                "STS federated user",
                                "STS federated users"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Federated User",
                                "Federated Users"
                            ],
                            withServiceName: [
                                "STS Federated User",
                                "STS Federated Users"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsUnmanagedKubernetesCluster]: {
                        text: {
                            withoutServiceName: [
                                "Self-Managed cluster",
                                "Self-Managed clusters"
                            ],
                            withServiceName: [
                                "Self-Managed K8s cluster",
                                "Self-Managed K8s clusters"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Self-Managed Cluster",
                                "Self-Managed Clusters"
                            ],
                            withServiceName: [
                                "Self-Managed K8s Cluster",
                                "Self-Managed K8s Clusters"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsTenantEntity]: {
                        text: {
                            withServiceName: [
                                "account",
                                "accounts"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Account",
                                "Accounts"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsTimestreamDatabase]: {
                        text: {
                            withServiceName: [
                                "timestream database",
                                "timestream databases"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Timestream Database",
                                "Timestream Databases"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsTimestreamDatabaseTable]: {
                        text: {
                            withServiceName: [
                                "timestream table",
                                "timestream tables"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Timestream Table",
                                "Timestream Tables"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsWafGlobalWebAcl]: {
                        text: {
                            withoutServiceName: [
                                "web ACL",
                                "web ACLs"
                            ],
                            withServiceName: [
                                "WAF web acl",
                                "WAF web acls"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Web ACL",
                                "Web ACLs"
                            ],
                            withServiceName: [
                                "WAF Web ACL",
                                "WAF Web ACLs"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsWafRegionalWebAcl]: {
                        text: {
                            withoutServiceName: [
                                "web ACL",
                                "web ACLs"
                            ],
                            withServiceName: [
                                "WAF web acl",
                                "WAF web acls"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Web ACL",
                                "Web ACLs"
                            ],
                            withServiceName: [
                                "WAF Web ACL",
                                "WAF Web ACLs"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsWafWebAcl]: {
                        text: {
                            withoutServiceName: [
                                "web ACL",
                                "web ACLs"
                            ],
                            withServiceName: [
                                "WAF web ACL",
                                "WAF web ACLs"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Web ACL",
                                "Web ACLs"
                            ],
                            withServiceName: [
                                "WAF Web ACL",
                                "WAF Web ACLs"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureAuthorizationDenyAssignment]: {
                        text: {
                            withServiceName: [
                                "deny assignment",
                                "deny assignments"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Deny Assignment",
                                "Deny Assignments"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureAuthorizationLock]: {
                        text: {
                            withServiceName: [
                                "lock",
                                "locks"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Lock",
                                "Locks"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureAuthorizationRoleAssignment]: {
                        text: {
                            withoutServiceName: [
                                "role assignment",
                                "role assignments"
                            ],
                            withServiceName: [
                                "role assignment",
                                "role assignments"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Role Assignment",
                                "Role Assignments"
                            ],
                            withServiceName: [
                                "Role Assignment",
                                "Role Assignments"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureAuthorizationRoleAssignmentClassicAdministrator]: {
                        text: {
                            withoutServiceName: [
                                "classic administrator",
                                "classic administrators"
                            ],
                            withServiceName: [
                                "classic administrator",
                                "classic administrators"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Classic Administrator",
                                "Classic Administrators"
                            ],
                            withServiceName: [
                                "Classic Administrator",
                                "Classic Administrators"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureAuthorizationRoleAssignmentResource]: {
                        text: {
                            withoutServiceName: [
                                "role assignment",
                                "role assignments"
                            ],
                            withServiceName: [
                                "role assignment",
                                "role assignments"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Role Assignment",
                                "Role Assignments"
                            ],
                            withServiceName: [
                                "Role Assignment",
                                "Role Assignments"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureAuthorizationRoleAssignmentSchedule]: {
                        text: {
                            withServiceName: [
                                "PIM role assignment",
                                "PIM role assignments"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "PIM Role Assignment",
                                "PIM Role Assignments"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureAuthorizationRoleDefinition]: {
                        text: {
                            withoutServiceName: [
                                "role",
                                "roles"
                            ],
                            withServiceName: [
                                "role",
                                "roles"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Role",
                                "Roles"
                            ],
                            withServiceName: [
                                "Role",
                                "Roles"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureAuthorizationRoleEligibilitySchedule]: {
                        text: {
                            withoutServiceName: [
                                "role eligibility",
                                "role eligibilities"
                            ],
                            withServiceName: [
                                "PIM role eligibility",
                                "PIM role eligibilities"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Role Eligibility",
                                "Role Eligibilities"
                            ],
                            withServiceName: [
                                "PIM Role Eligibility",
                                "PIM Role Eligibilities"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureAuthorizationRoleManagementPolicyAssignment]: {
                        text: {
                            withoutServiceName: [
                                "PIM role settings",
                                "PIM role settings"
                            ],
                            withServiceName: [
                                "PIM role settings",
                                "PIM role settings"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "PIM Role Settings",
                                "PIM Role Settings"
                            ],
                            withServiceName: [
                                "PIM Role Settings",
                                "PIM Role Settings"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureBotServiceBotService]: {
                        text: {
                            withServiceName: [
                                "Bot service",
                                "Bot services"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Bot Service",
                                "Bot Services"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureCognitiveServicesAccount]: {
                        text: {
                            withServiceName: [
                                "AI service",
                                "AI services"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "AI Service",
                                "AI Services"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureComputeDisk]: {
                        text: {
                            withServiceName: [
                                "managed disk",
                                "managed disks"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Managed Disk",
                                "Managed Disks"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureComputeDiskEncryptionSet]: {
                        text: {
                            withServiceName: [
                                "disk encryption set",
                                "disk encryption sets"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Disk Encryption Set",
                                "Disk Encryption Sets"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureComputeDiskResource]: {
                        text: {
                            withServiceName: [
                                "disk resource",
                                "disk resources"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Disk Resource",
                                "Disk Resources"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureComputeGallery]: {
                        text: {
                            withServiceName: [
                                "shared gallery",
                                "shared galleries"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Shared Gallery",
                                "Shared Galleries"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureComputeGalleryImage]: {
                        text: {
                            withServiceName: [
                                "gallery image",
                                "gallery images"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Gallery Image",
                                "Gallery Images"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureComputeManagedImage]: {
                        text: {
                            withServiceName: [
                                "managed Image",
                                "managed Images"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Managed Image",
                                "Managed Images"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureComputeDiskSnapshot]: {
                        text: {
                            withServiceName: [
                                "snapshot",
                                "snapshots"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Snapshot",
                                "Snapshots"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureComputeVirtualMachine]: {
                        text: {
                            withServiceName: [
                                "virtual machine",
                                "virtual machines"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Virtual Machine",
                                "Virtual Machines"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureComputeVirtualMachineScaleSet]: {
                        text: {
                            withServiceName: [
                                "virtual machine scale set",
                                "virtual machine scale sets"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Virtual Machine Scale Set",
                                "Virtual Machine Scale Sets"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureComputeVirtualMachineScaleSetVirtualMachine]: {
                        text: {
                            withServiceName: [
                                "virtual machine scale set instance",
                                "virtual machine scale set instances"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Virtual Machine Scale Set Instance",
                                "Virtual Machine Scale Set Instances"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureComputeVirtualMachineScaleSetVirtualMachineNetworkInterface]: {
                        text: {
                            withServiceName: [
                                "virtual machine scale set instance network interface",
                                "virtual machine scale set instance network interfaces"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Virtual Machine Scale Set Instance Network Interface",
                                "Virtual Machine Scale Set Instance Network Interfaces"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureComputeVirtualMachineScaleSetVirtualMachineNetworkInterfaceIpConfigurationPublicIpAddress]: {
                        text: {
                            withServiceName: [
                                "virtual machine scale set instance network interface ip configuration public ip address",
                                "virtual machine scale set instance network interface ip configuration public ip addresses"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Virtual Machine Scale Set Instance Network Interface IP Configuration Public IP Address",
                                "Virtual Machine Scale Set Instance Network Interface IP Configuration Public IP Addresses"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureContainerImage]: {
                        text: {
                            withoutServiceName: [
                                "container image",
                                "container images"
                            ],
                            withServiceName: [
                                "container Image",
                                "container Images"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Container Image",
                                "Container Images"
                            ],
                            withServiceName: [
                                "Container Image",
                                "Container Images"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureContainerRegistryRegistry]: {
                        text: {
                            withServiceName: [
                                "container registry",
                                "container registries"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Container Registry",
                                "Container Registries"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureContainerRegistryRepository]: {
                        text: {
                            withServiceName: [
                                "container repository",
                                "container repositories"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Container Repository",
                                "Container Repositories"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureContainerResource]: {
                        text: {
                            withServiceName: [
                                "resource",
                                "resources"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Resource",
                                "Resources"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureContainerServiceManagedCluster]: {
                        text: {
                            withServiceName: [
                                "AKS cluster",
                                "AKS clusters"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "AKS Cluster",
                                "AKS Clusters"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureContainerServiceManagedClusterAgentPool]: {
                        text: {
                            withoutServiceName: [
                                "cluster node pool",
                                "cluster node pools"
                            ],
                            withServiceName: [
                                "AKS cluster node pool",
                                "AKS cluster node pools"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Cluster Node Pool",
                                "Cluster Node Pools"
                            ],
                            withServiceName: [
                                "AKS Cluster Node Pool",
                                "AKS Cluster Node Pools"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureDocumentDbDatabaseAccount]: {
                        text: {
                            withServiceName: [
                                "CosmosDB account",
                                "CosmosDB accounts"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "CosmosDB Account",
                                "CosmosDB Accounts"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureDocumentDbDatabaseAccountCassandraKeyspace]: {
                        text: {
                            withServiceName: [
                                "CosmosDB Cassandra keyspace",
                                "CosmosDB Cassandra keyspaces"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "CosmosDB Cassandra Keyspace",
                                "CosmosDB Cassandra Keyspaces"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureDocumentDbDatabaseAccountGremlinDatabase]: {
                        text: {
                            withServiceName: [
                                "CosmosDB Gremlin database",
                                "CosmosDB Gremlin databases"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "CosmosDB Gremlin Database",
                                "CosmosDB Gremlin Databases"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureDocumentDbDatabaseAccountMongoDbDatabase]: {
                        text: {
                            withServiceName: [
                                "CosmosDB MongoDB database",
                                "CosmosDB MongoDB databases"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "CosmosDB MongoDB Database",
                                "CosmosDB MongoDB Databases"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureDocumentDbDatabaseAccountResource]: {
                        text: {
                            withoutServiceName: [
                                "resource",
                                "resources"
                            ],
                            withServiceName: [
                                "CosmosDB resource",
                                "CosmosDB resources"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Resource",
                                "Resources"
                            ],
                            withServiceName: [
                                "CosmosDB Resource",
                                "CosmosDB Resources"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureDocumentDbDatabaseAccountSqlDatabase]: {
                        text: {
                            withServiceName: [
                                "CosmosDB SQL database",
                                "CosmosDB SQL databases"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "CosmosDB SQL Database",
                                "CosmosDB SQL Databases"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureDocumentDbDatabaseAccountTable]: {
                        text: {
                            withServiceName: [
                                "CosmosDB table",
                                "CosmosDB tables"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "CosmosDB Table",
                                "CosmosDB Tables"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureEventHubNamespace]: {
                        text: {
                            withServiceName: [
                                "event hubs namespace",
                                "event hubs namespaces"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Event Hubs Namespace",
                                "Event Hubs Namespaces"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureEventHubNamespaceEventHub]: {
                        text: {
                            withServiceName: [
                                "event hub",
                                "event hubs"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Event Hub",
                                "Event Hubs"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureInsightsDiagnosticSettings]: {
                        text: {
                            withServiceName: [
                                "diagnostic setting",
                                "diagnostic settings"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Diagnostic Setting",
                                "Diagnostic Settings"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureKeyVaultVault]: {
                        text: {
                            withServiceName: [
                                "key vault",
                                "key vaults"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Key Vault",
                                "Key Vaults"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureKeyVaultVaultCertificate]: {
                        text: {
                            withoutServiceName: [
                                "certificate",
                                "certificates"
                            ],
                            withServiceName: [
                                "key vault certificate",
                                "key vault certificates"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Certificate",
                                "Certificates"
                            ],
                            withServiceName: [
                                "Key Vault Certificate",
                                "Key Vault Certificates"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureKeyVaultVaultKey]: {
                        text: {
                            withoutServiceName: [
                                "key",
                                "keys"
                            ],
                            withServiceName: [
                                "key vault key",
                                "key vault keys"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Key",
                                "Keys"
                            ],
                            withServiceName: [
                                "Key Vault Key",
                                "Key Vault Keys"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureKeyVaultVaultObject]: {
                        text: {
                            withServiceName: [
                                "key vault object",
                                "key vault objects"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Key Vault Object",
                                "Key Vault Objects"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureKeyVaultVaultSecret]: {
                        text: {
                            withoutServiceName: [
                                "secret",
                                "secrets"
                            ],
                            withServiceName: [
                                "key vault secret",
                                "key vault secrets"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Secret",
                                "Secrets"
                            ],
                            withServiceName: [
                                "Key Vault Secret",
                                "Key Vault Secrets"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureKubernetesClusterRole]: {
                        text: {
                            withoutServiceName: [
                                "cluster role",
                                "cluster roles"
                            ],
                            withServiceName: [
                                "K8s cluster role",
                                "K8s cluster roles"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Cluster Role",
                                "Cluster Roles"
                            ],
                            withServiceName: [
                                "K8s Cluster Role",
                                "K8s Cluster Roles"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureKubernetesClusterRoleBinding]: {
                        text: {
                            withoutServiceName: [
                                "cluster role binding",
                                "cluster role bindings"
                            ],
                            withServiceName: [
                                "K8s cluster role binding",
                                "K8s cluster role bindings"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Cluster Role Binding",
                                "Cluster Role Bindings"
                            ],
                            withServiceName: [
                                "K8s Cluster Role Binding",
                                "K8s Cluster Role Bindings"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureKubernetesConfigMap]: {
                        text: {
                            withoutServiceName: [
                                "config map",
                                "config maps"
                            ],
                            withServiceName: [
                                "K8s config map",
                                "K8s config maps"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Config Map",
                                "Config Maps"
                            ],
                            withServiceName: [
                                "K8s Config Map",
                                "K8s Config Maps"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureKubernetesCronJob]: {
                        text: {
                            withoutServiceName: [
                                "cron job",
                                "cron jobs"
                            ],
                            withServiceName: [
                                "K8s cron job",
                                "K8s cron jobs"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Cron Job",
                                "Cron Jobs"
                            ],
                            withServiceName: [
                                "K8s Cron Job",
                                "K8s Cron Jobs"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureKubernetesDaemonSet]: {
                        text: {
                            withoutServiceName: [
                                "daemon set",
                                "daemon sets"
                            ],
                            withServiceName: [
                                "K8s daemon set",
                                "K8s daemon sets"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Daemon Set",
                                "Daemon Sets"
                            ],
                            withServiceName: [
                                "K8s Daemon Set",
                                "K8s Daemon Sets"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureKubernetesDeployment]: {
                        text: {
                            withoutServiceName: [
                                "deployment",
                                "deployments"
                            ],
                            withServiceName: [
                                "K8s deployment",
                                "K8s deployments"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Deployment",
                                "Deployments"
                            ],
                            withServiceName: [
                                "K8s Deployment",
                                "K8s Deployments"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureKubernetesGroup]: {
                        text: {
                            withoutServiceName: [
                                "group",
                                "groups"
                            ],
                            withServiceName: [
                                "K8s group",
                                "K8s groups"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Group",
                                "Groups"
                            ],
                            withServiceName: [
                                "K8s Group",
                                "K8s Groups"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureKubernetesIngress]: {
                        text: {
                            withoutServiceName: [
                                "ingress",
                                "ingresses"
                            ],
                            withServiceName: [
                                "K8s ingress",
                                "K8s ingresses"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Ingress",
                                "Ingresses"
                            ],
                            withServiceName: [
                                "K8s Ingress",
                                "K8s Ingresses"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureKubernetesIngressClass]: {
                        text: {
                            withoutServiceName: [
                                "ingress class",
                                "ingress classes"
                            ],
                            withServiceName: [
                                "K8s ingress class",
                                "K8s ingress classes"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Ingress class",
                                "Ingress classes"
                            ],
                            withServiceName: [
                                "K8s Ingress class",
                                "K8s Ingress classes"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureKubernetesJob]: {
                        text: {
                            withoutServiceName: [
                                "job",
                                "jobs"
                            ],
                            withServiceName: [
                                "K8s job",
                                "K8s jobs"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Job",
                                "Jobs"
                            ],
                            withServiceName: [
                                "K8s Job",
                                "K8s Jobs"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureKubernetesNamespace]: {
                        text: {
                            withoutServiceName: [
                                "namespace",
                                "namespaces"
                            ],
                            withServiceName: [
                                "K8s namespace",
                                "K8s namespaces"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Namespace",
                                "Namespaces"
                            ],
                            withServiceName: [
                                "K8s Namespace",
                                "K8s Namespaces"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureKubernetesNamespaceRole]: {
                        text: {
                            withoutServiceName: [
                                "role",
                                "roles"
                            ],
                            withServiceName: [
                                "K8s role",
                                "K8s roles"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Role",
                                "Roles"
                            ],
                            withServiceName: [
                                "K8s Role",
                                "K8s Roles"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureKubernetesNamespaceRoleBinding]: {
                        text: {
                            withoutServiceName: [
                                "role binding",
                                "role bindings"
                            ],
                            withServiceName: [
                                "K8s role binding",
                                "K8s role bindings"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Role Binding",
                                "Role Bindings"
                            ],
                            withServiceName: [
                                "K8s Role Binding",
                                "K8s Role Bindings"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureKubernetesNetworkPolicy]: {
                        text: {
                            withoutServiceName: [
                                "network policy",
                                "network policies"
                            ],
                            withServiceName: [
                                "K8s network policy",
                                "K8s network policies"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Network Policy",
                                "Network Policies"
                            ],
                            withServiceName: [
                                "K8s Network Policy",
                                "K8s Network Policies"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureKubernetesNode]: {
                        text: {
                            withoutServiceName: [
                                "node",
                                "nodes"
                            ],
                            withServiceName: [
                                "K8s node",
                                "K8s nodes"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Node",
                                "Nodes"
                            ],
                            withServiceName: [
                                "K8s Node",
                                "K8s Nodes"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureKubernetesPersistentVolume]: {
                        text: {
                            withoutServiceName: [
                                "persistent volume",
                                "persistent volumes"
                            ],
                            withServiceName: [
                                "K8s persistent volume",
                                "K8s persistent volumes"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Persistent Volume",
                                "Persistent Volumes"
                            ],
                            withServiceName: [
                                "K8s Persistent Volume",
                                "K8s Persistent Volumes"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureKubernetesPod]: {
                        text: {
                            withoutServiceName: [
                                "pod",
                                "pods"
                            ],
                            withServiceName: [
                                "K8s pod",
                                "K8s pod"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Pod",
                                "Pods"
                            ],
                            withServiceName: [
                                "K8s Pod",
                                "K8s Pod"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureKubernetesPodSecurityPolicy]: {
                        text: {
                            withoutServiceName: [
                                "pod security policy",
                                "pod security policies"
                            ],
                            withServiceName: [
                                "K8s pod security policy",
                                "K8s pod security policies"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Pod Security Policy",
                                "Pod Security Policies"
                            ],
                            withServiceName: [
                                "K8s Pod Security Policy",
                                "K8s Pod Security Policies"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureKubernetesPodTemplate]: {
                        text: {
                            withoutServiceName: [
                                "pod template",
                                "pod templates"
                            ],
                            withServiceName: [
                                "K8s pod template",
                                "K8s pod templates"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Pod Template",
                                "Pod Templates"
                            ],
                            withServiceName: [
                                "K8s Pod Template",
                                "K8s Pod Templates"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureKubernetesReplicaSet]: {
                        text: {
                            withoutServiceName: [
                                "replica set",
                                "replica sets"
                            ],
                            withServiceName: [
                                "K8s replica set",
                                "K8s replica sets"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Replica Set",
                                "Replica Sets"
                            ],
                            withServiceName: [
                                "K8s Replica Set",
                                "K8s Replica Sets"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureKubernetesResource]: {
                        text: {
                            withoutServiceName: [
                                "resource",
                                "resources"
                            ],
                            withServiceName: [
                                "K8s resource",
                                "K8s resources"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Resource",
                                "Resources"
                            ],
                            withServiceName: [
                                "K8s Resource",
                                "K8s Resources"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureKubernetesService]: {
                        text: {
                            withoutServiceName: [
                                "service",
                                "services"
                            ],
                            withServiceName: [
                                "K8s service",
                                "K8s services"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Service",
                                "Services"
                            ],
                            withServiceName: [
                                "K8s Service",
                                "K8s Services"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureKubernetesServiceAccount]: {
                        text: {
                            withoutServiceName: [
                                "service account",
                                "service accounts"
                            ],
                            withServiceName: [
                                "K8s service account",
                                "K8s service accounts"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Service Account",
                                "Service Accounts"
                            ],
                            withServiceName: [
                                "K8s Service Account",
                                "K8s Service Accounts"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureKubernetesStatefulSet]: {
                        text: {
                            withoutServiceName: [
                                "stateful set",
                                "stateful sets"
                            ],
                            withServiceName: [
                                "K8s stateful set",
                                "K8s stateful sets"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Stateful Set",
                                "Stateful Sets"
                            ],
                            withServiceName: [
                                "K8s Stateful Set",
                                "K8s Stateful Sets"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureKubernetesUser]: {
                        text: {
                            withoutServiceName: [
                                "user",
                                "users"
                            ],
                            withServiceName: [
                                "K8s user",
                                "K8s users"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "User",
                                "Users"
                            ],
                            withServiceName: [
                                "K8s User",
                                "K8s Users"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureKubernetesWorkloadResourceBase]: {
                        text: {
                            withoutServiceName: [
                                "workload",
                                "workloads"
                            ],
                            withServiceName: [
                                "K8s workload",
                                "K8s workloads"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Workload",
                                "Workloads"
                            ],
                            withServiceName: [
                                "K8s Workload",
                                "K8s Workloads"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureLogicWorkflow]: {
                        text: {
                            withServiceName: [
                                "logic app",
                                "logic apps"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Logic App",
                                "Logic Apps"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureMachineLearningWorkspace]: {
                        text: {
                            withoutServiceName: [
                                "workspace",
                                "workspaces"
                            ],
                            withServiceName: [
                                "Machine Learning workspace",
                                "Machine Learning workspaces"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Workspace",
                                "Workspaces"
                            ],
                            withServiceName: [
                                "Machine Learning Workspace",
                                "Machine Learning Workspaces"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureManagedIdentityManagedIdentity]: {
                        text: {
                            withServiceName: [
                                "managed identity",
                                "managed identities"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Managed Identity",
                                "Managed Identities"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureManagedIdentitySystemManagedIdentity]: {
                        text: {
                            withServiceName: [
                                "system-assigned managed identity",
                                "system-assigned managed identities"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "System-assigned Managed Identity",
                                "System-assigned Managed Identities"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureManagedIdentityUserManagedIdentity]: {
                        text: {
                            withServiceName: [
                                "user-assigned managed identity",
                                "user-assigned managed identities"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "User-assigned Managed Identity",
                                "User-assigned Managed Identities"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureManagementManagementGroup]: {
                        text: {
                            withoutServiceName: [
                                "management group",
                                "management groups"
                            ],
                            withServiceName: [
                                "Azure management group",
                                "Azure management groups"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Management Group",
                                "Management Groups"
                            ],
                            withServiceName: [
                                "Azure Management Group",
                                "Azure Management Groups"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureMySqlFlexibleServer]: {
                        text: {
                            withServiceName: [
                                "MySQL database server",
                                "MySQL database servers"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "MySQL Database Flexible Server",
                                "MySQL Database Flexible Servers"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureMySqlServer]: {
                        text: {
                            withServiceName: [
                                "MySQL database server",
                                "MySQL database servers"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "MySQL Database Server",
                                "MySQL Database Servers"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureMySqlSingleServer]: {
                        text: {
                            withServiceName: [
                                "MySQL database server",
                                "MySQL database servers"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "MySQL Database Single Server",
                                "MySQL Database Single Servers"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureNetworkApplicationGateway]: {
                        text: {
                            withServiceName: [
                                "application gateway",
                                "application gateways"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Application Gateway",
                                "Application Gateways"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureNetworkApplicationSecurityGroup]: {
                        text: {
                            withServiceName: [
                                "application security group",
                                "application security groups"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Application Security Group",
                                "Application Security Groups"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureNetworkDnsZone]: {
                        text: {
                            withServiceName: [
                                "DNS zone",
                                "DNS zones"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "DNS Zone",
                                "DNS Zones"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureNetworkDnsZoneRecordSetA]: {
                        text: {
                            withoutServiceName: [
                                "A record set",
                                "A record sets"
                            ],
                            withServiceName: [
                                "DNS zone A record set",
                                "DNS zone A record sets"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "A Record Set",
                                "A Record Sets"
                            ],
                            withServiceName: [
                                "DNS Zone A Record Set",
                                "DNS Zone A Record Sets"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureNetworkDnsZoneRecordSetAaaa]: {
                        text: {
                            withoutServiceName: [
                                "AAAA record set",
                                "AAAA record sets"
                            ],
                            withServiceName: [
                                "DNS zone AAAA record set",
                                "DNS zone AAAA record sets"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "AAAA Record Set",
                                "AAAA Record Sets"
                            ],
                            withServiceName: [
                                "DNS Zone AAAA Record Set",
                                "DNS Zone AAAA Record Sets"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureNetworkDnsZoneRecordSetCaa]: {
                        text: {
                            withoutServiceName: [
                                "CAA record set",
                                "CAA record sets"
                            ],
                            withServiceName: [
                                "DNS zone CAA record set",
                                "DNS zone CAA record sets"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "CAA Record Set",
                                "CAA Record Sets"
                            ],
                            withServiceName: [
                                "DNS Zone CAA Record Set",
                                "DNS Zone CAA Record Sets"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureNetworkDnsZoneRecordSetCname]: {
                        text: {
                            withoutServiceName: [
                                "CNAME record set",
                                "CNAME record sets"
                            ],
                            withServiceName: [
                                "DNS zone CNAME record set",
                                "DNS zone CNAME record sets"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "CNAME Record Set",
                                "CNAME Record Sets"
                            ],
                            withServiceName: [
                                "DNS Zone CNAME Record Set",
                                "DNS Zone CNAME Record Sets"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureNetworkDnsZoneRecordSetMx]: {
                        text: {
                            withoutServiceName: [
                                "MX record set",
                                "MX record sets"
                            ],
                            withServiceName: [
                                "DNS zone MX record set",
                                "DNS zone MX record sets"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "MX Record Set",
                                "MX Record Sets"
                            ],
                            withServiceName: [
                                "DNS Zone MX Record Set",
                                "DNS Zone MX Record Sets"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureNetworkDnsZoneRecordSetNs]: {
                        text: {
                            withoutServiceName: [
                                "NS record set",
                                "NS record sets"
                            ],
                            withServiceName: [
                                "DNS zone NS record set",
                                "DNS zone NS record sets"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "NS Record Set",
                                "NS Record Sets"
                            ],
                            withServiceName: [
                                "DNS Zone NS Record Set",
                                "DNS Zone NS Record Sets"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureNetworkDnsZoneRecordSetPtr]: {
                        text: {
                            withoutServiceName: [
                                "PTR record set",
                                "PTR record sets"
                            ],
                            withServiceName: [
                                "DNS zone PTR record set",
                                "DNS zone PTR record sets"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "PTR Record Set",
                                "PTR Record Sets"
                            ],
                            withServiceName: [
                                "DNS Zone PTR Record Set",
                                "DNS Zone PTR Record Sets"
                            ]
                        }
                    },

                    [Contract.TypeNames.AzureNetworkDnsZoneRecordSetSoa]: {
                        text: {
                            withoutServiceName: [
                                "SOA record set",
                                "SOA record sets"
                            ],
                            withServiceName: [
                                "DNS zone SOA record set",
                                "DNS zone SOA record sets"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "SOA Record Set",
                                "SOA Record Sets"
                            ],
                            withServiceName: [
                                "DNS Zone SOA Record Set",
                                "DNS Zone SOA Record Sets"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureNetworkDnsZoneRecordSetSrv]: {
                        text: {
                            withoutServiceName: [
                                "SRV record set",
                                "SRV record sets"
                            ],
                            withServiceName: [
                                "DNS zone SRV record set",
                                "DNS zone SRV record sets"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "SRV Record Set",
                                "SRV Record Sets"
                            ],
                            withServiceName: [
                                "DNS Zone SRV Record Set",
                                "DNS Zone SRV Record Sets"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureNetworkDnsZoneRecordSetTxt]: {
                        text: {
                            withoutServiceName: [
                                "TXT record set",
                                "TXT record sets"
                            ],
                            withServiceName: [
                                "DNS zone TXT record set",
                                "DNS zone TXT record sets"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "TXT Record Set",
                                "TXT Record Sets"
                            ],
                            withServiceName: [
                                "DNS Zone TXT Record Set",
                                "DNS Zone TXT Record Sets"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureNetworkLoadBalancer]: {
                        text: {
                            withServiceName: [
                                "load balancer",
                                "load balancers"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Load Balancer",
                                "Load Balancers"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureNetworkNetworkInterface]: {
                        text: {
                            withServiceName: [
                                "network interface",
                                "network interfaces"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Network Interface",
                                "Network Interfaces"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureNetworkNetworkSecurityGroup]: {
                        text: {
                            withServiceName: [
                                "network security group",
                                "network security groups"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Network Security Group",
                                "Network Security Groups"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureNetworkPrivateDnsZone]: {
                        text: {
                            withServiceName: [
                                "private DNS zone",
                                "private DNS zones"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Private DNS Zone",
                                "Private DNS Zones"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureNetworkPrivateDnsZoneRecordSetA]: {
                        text: {
                            withoutServiceName: [
                                "A record set",
                                "A record sets"
                            ],
                            withServiceName: [
                                "private DNS zone A record set",
                                "private DNS zone A record sets"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "A Record Set",
                                "A Record Sets"
                            ],
                            withServiceName: [
                                "Private DNS Zone A Record Set",
                                "Private DNS Zone A Record Sets"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureNetworkPrivateDnsZoneRecordSetAaaa]: {
                        text: {
                            withoutServiceName: [
                                "AAAA record set",
                                "AAAA record sets"
                            ],
                            withServiceName: [
                                "private DNS zone AAAA record set",
                                "private DNS zone AAAA record sets"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "AAAA Record Set",
                                "AAAA Record Sets"
                            ],
                            withServiceName: [
                                "Private DNS Zone AAAA Record Set",
                                "Private DNS Zone AAAA Record Sets"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureNetworkPrivateDnsZoneRecordSetCname]: {
                        text: {
                            withoutServiceName: [
                                "CNAME record set",
                                "CNAME record sets"
                            ],
                            withServiceName: [
                                "private DNS zone CNAME record set",
                                "private DNS zone CNAME record sets"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "CNAME Record Set",
                                "CNAME Record Sets"
                            ],
                            withServiceName: [
                                "Private DNS Zone CNAME Record Set",
                                "Private DNS Zone CNAME Record Sets"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureNetworkPrivateDnsZoneRecordSetMx]: {
                        text: {
                            withoutServiceName: [
                                "MX record set",
                                "MX record sets"
                            ],
                            withServiceName: [
                                "private DNS zone MX record set",
                                "private DNS zone MX record sets"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "MX Record Set",
                                "MX Record Sets"
                            ],
                            withServiceName: [
                                "Private DNS Zone MX Record Set",
                                "Private DNS Zone MX Record Sets"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureNetworkPrivateDnsZoneRecordSetPtr]: {
                        text: {
                            withoutServiceName: [
                                "PTR record set",
                                "PTR record sets"
                            ],
                            withServiceName: [
                                "private DNS zone PTR record set",
                                "private DNS zone PTR record sets"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "PTR Record Set",
                                "PTR Record Sets"
                            ],
                            withServiceName: [
                                "Private DNS Zone PTR Record Set",
                                "Private DNS Zone PTR Record Sets"
                            ]
                        }
                    },

                    [Contract.TypeNames.AzureNetworkPrivateDnsZoneRecordSetSoa]: {
                        text: {
                            withoutServiceName: [
                                "SOA record set",
                                "SOA record sets"
                            ],
                            withServiceName: [
                                "private DNS zone SOA record set",
                                "private DNS zone SOA record sets"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "SOA Record Set",
                                "SOA Record Sets"
                            ],
                            withServiceName: [
                                "Private DNS Zone SOA Record Set",
                                "Private DNS Zone SOA Record Sets"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureNetworkPrivateDnsZoneRecordSetSrv]: {
                        text: {
                            withoutServiceName: [
                                "SRV record set",
                                "SRV record sets"
                            ],
                            withServiceName: [
                                "private DNS zone SRV record set",
                                "private DNS zone SRV record sets"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "SRV Record Set",
                                "SRV Record Sets"
                            ],
                            withServiceName: [
                                "Private DNS Zone SRV Record Set",
                                "Private DNS Zone SRV Record Sets"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureNetworkPrivateDnsZoneRecordSetTxt]: {
                        text: {
                            withoutServiceName: [
                                "TXT record set",
                                "TXT record sets"
                            ],
                            withServiceName: [
                                "private DNS zone TXT record set",
                                "private DNS zone TXT record sets"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "TXT Record Set",
                                "TXT Record Sets"
                            ],
                            withServiceName: [
                                "Private DNS Zone TXT Record Set",
                                "Private DNS Zone TXT Record Sets"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureNetworkPrivateEndpoint]: {
                        text: {
                            withServiceName: [
                                "private endpoint",
                                "private endpoints"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Private Endpoint",
                                "Private Endpoints"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureNetworkPublicIpAddress]: {
                        text: {
                            withServiceName: [
                                "public ip address",
                                "public ip addresses"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Public IP Address",
                                "Public IP Addresses"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureNetworkPublicIpPrefix]: {
                        text: {
                            withServiceName: [
                                "public ip prefix",
                                "public ip prefixes"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Public IP Prefix",
                                "Public IP Prefixes"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureNetworkRouteTable]: {
                        text: {
                            withServiceName: [
                                "route table",
                                "route tables"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Route Table",
                                "Route Tables"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureNetworkVirtualNetwork]: {
                        text: {
                            withServiceName: [
                                "virtual network",
                                "virtual networks"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Virtual Network",
                                "Virtual Networks"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureNetworkVirtualNetworkSubnet]: {
                        text: {
                            withServiceName: [
                                "subnet",
                                "subnets"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Subnet",
                                "Subnets"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureOpenShiftBuildConfig]: {
                        text: {
                            withoutServiceName: [
                                "build config",
                                "build configs"
                            ],
                            withServiceName: [
                                "OpenShift build config",
                                "OpenShift build configs"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Build Config",
                                "Build Configs"
                            ],
                            withServiceName: [
                                "OpenShift Build Config",
                                "OpenShift Build Configs"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureOpenShiftCatalogSource]: {
                        text: {
                            withoutServiceName: [
                                "catalog source",
                                "catalog sources"
                            ],
                            withServiceName: [
                                "OpenShift catalog source",
                                "OpenShift catalog sources"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Catalog Source",
                                "Catalog Sources"
                            ],
                            withServiceName: [
                                "OpenShift Catalog Source",
                                "OpenShift Catalog Sources"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureOpenShiftDeploymentConfig]: {
                        text: {
                            withoutServiceName: [
                                "deployment config",
                                "deployment configs"
                            ],
                            withServiceName: [
                                "OpenShift deployment config",
                                "OpenShift deployment configs"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Deployment Config",
                                "Deployment Configs"
                            ],
                            withServiceName: [
                                "OpenShift Deployment Config",
                                "OpenShift Deployment Configs"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureOpenShiftImageStream]: {
                        text: {
                            withoutServiceName: [
                                "image stream",
                                "image streams"
                            ],
                            withServiceName: [
                                "OpenShift image stream",
                                "OpenShift image streams"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Image Stream",
                                "Image Streams"
                            ],
                            withServiceName: [
                                "OpenShift Image Stream",
                                "OpenShift Image Streams"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureOpenShiftProject]: {
                        text: {
                            withoutServiceName: [
                                "project",
                                "projects"
                            ],
                            withServiceName: [
                                "OpenShift project",
                                "OpenShift projects"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Project",
                                "Projects"
                            ],
                            withServiceName: [
                                "OpenShift Project",
                                "OpenShift Projects"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureOpenShiftRoute]: {
                        text: {
                            withoutServiceName: [
                                "route",
                                "routes"
                            ],
                            withServiceName: [
                                "OpenShift route",
                                "OpenShift routes"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Route",
                                "Routes"
                            ],
                            withServiceName: [
                                "OpenShift Route",
                                "OpenShift Routes"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureOpenShiftSecurityContextConstraint]: {
                        text: {
                            withoutServiceName: [
                                "security context constraint",
                                "security context constraints"
                            ],
                            withServiceName: [
                                "OpenShift security context constraint",
                                "OpenShift security context constraints"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Security Context Constraint",
                                "Security Context Constraints"
                            ],
                            withServiceName: [
                                "OpenShift Security Context Constraint",
                                "OpenShift Security Context Constraints"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureOpenShiftTemplate]: {
                        text: {
                            withoutServiceName: [
                                "template",
                                "templates"
                            ],
                            withServiceName: [
                                "OpenShift template",
                                "OpenShift templates"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Template",
                                "Templates"
                            ],
                            withServiceName: [
                                "OpenShift Template",
                                "OpenShift Templates"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzurePostgreSqlFlexibleServer]: {
                        text: {
                            withServiceName: [
                                "PostgreSQL database server",
                                "PostgreSQL database servers"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "PostgreSQL Database Flexible Server",
                                "PostgreSQL Database Flexible Servers"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzurePostgreSqlServer]: {
                        text: {
                            withServiceName: [
                                "PostgreSQL database server",
                                "PostgreSQL database servers"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "PostgreSQL Database Server",
                                "PostgreSQL Database Servers"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzurePostgreSqlSingleServer]: {
                        text: {
                            withServiceName: [
                                "PostgreSQL database server",
                                "PostgreSQL database servers"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "PostgreSQL Database Single Server",
                                "PostgreSQL Database Single Servers"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureResource]: {
                        text: {
                            withServiceName: [
                                "resource",
                                "resources"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Resource",
                                "Resources"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureResourceGroupResource]: {
                        text: {
                            withServiceName: [
                                "resource",
                                "resources"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Resource",
                                "Resources"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureResourcesResourceGroup]: {
                        text: {
                            withServiceName: [
                                "resource group",
                                "resource groups"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Resource Group",
                                "Resource Groups"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureRoot]: {
                        text: {
                            withServiceName: [
                                "Azure root",
                                "Azure root"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Azure Root",
                                "Azure Root"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureScopeResource]: {
                        text: {
                            withServiceName: [
                                "resource",
                                "resources"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Resource",
                                "Resources"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureService]: {
                        text: {
                            withServiceName: [
                                "service",
                                "services"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Service",
                                "Services"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureSqlServer]: {
                        text: {
                            withServiceName: [
                                "SQL server",
                                "SQL servers"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "SQL Server",
                                "SQL Servers"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureSqlServerDatabase]: {
                        text: {
                            withServiceName: [
                                "SQL database",
                                "SQL databases"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "SQL Database",
                                "SQL Databases"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureStorageStorageAccount]: {
                        text: {
                            withServiceName: [
                                "storage account",
                                "storage accounts"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Storage Account",
                                "Storage Accounts"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureStorageStorageAccountBlobContainer]: {
                        text: {
                            withoutServiceName: [
                                "blob container",
                                "blob containers"
                            ],
                            withServiceName: [
                                "Storage Account blob container",
                                "Storage Account blob containers"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Blob Container",
                                "Blob Containers"
                            ],
                            withServiceName: [
                                "Storage Account Blob Container",
                                "Storage Account Blob Containers"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureStorageStorageAccountFileShare]: {
                        text: {
                            withoutServiceName: [
                                "file share",
                                "file shares"
                            ],
                            withServiceName: [
                                "Storage Account file share",
                                "Storage Account file shares"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "File Share",
                                "File Shares"
                            ],
                            withServiceName: [
                                "Storage Account File Share",
                                "Storage Account File Shares"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureStorageStorageAccountQueue]: {
                        text: {
                            withoutServiceName: [
                                "queue",
                                "queues"
                            ],
                            withServiceName: [
                                "Storage Account queue",
                                "Storage Account queues"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Queue",
                                "Queues"
                            ],
                            withServiceName: [
                                "Storage Account Queue",
                                "Storage Account Queues"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureStorageStorageAccountTable]: {
                        text: {
                            withoutServiceName: [
                                "table",
                                "tables"
                            ],
                            withServiceName: [
                                "Storage Account table",
                                "Storage Account tables"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Table",
                                "Tables"
                            ],
                            withServiceName: [
                                "Storage Account Table",
                                "Storage Account Tables"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureTenantEntity]: {
                        text: {
                            withServiceName: [
                                "subscription",
                                "subscriptions"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Subscription",
                                "Subscriptions"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureUnmanagedKubernetesCluster]: {
                        text: {
                            withoutServiceName: [
                                "Self-Managed cluster",
                                "Self-Managed clusters"
                            ],
                            withServiceName: [
                                "Self-Managed K8s cluster",
                                "Self-Managed K8s clusters"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Self-Managed Cluster",
                                "Self-Managed Clusters"
                            ],
                            withServiceName: [
                                "Self-Managed K8s Cluster",
                                "Self-Managed K8s Clusters"
                            ]
                        }
                    },
                    [Contract.TypeNames.AzureWebApplication]: {
                        text: {
                            withServiceName: [
                                "app service",
                                "app services"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "App Service",
                                "App Services"
                            ]
                        }
                    },
                    [Contract.TypeNames.Entity]: {
                        text: {
                            withServiceName: [
                                "resource",
                                "resources"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Resource",
                                "Resources"
                            ]
                        }
                    },
                    [Contract.TypeNames.CiContainerImage]: {
                        text: {
                            withoutServiceName: [
                                "container image",
                                "container images"
                            ],
                            withServiceName: [
                                "container Image",
                                "container Images"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Container Image",
                                "Container Images"
                            ],
                            withServiceName: [
                                "Container Image",
                                "Container Images"
                            ]
                        }
                    },
                    [Contract.TypeNames.CiContainerImageRepository]: {
                        text: {
                            withServiceName: [
                                "container repository",
                                "container repositories"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Container Repository",
                                "Container Repositories"
                            ]
                        }
                    },
                    [Contract.TypeNames.CiTenantEntity]: {
                        text: {
                            withServiceName: [
                                "container registry",
                                "container registries"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Container Registry",
                                "Container Registries"
                            ]
                        }
                    },
                    [Contract.TypeNames.GciDirectoryDirectoryRole]: {
                        text: {
                            withoutServiceName: [
                                "admin role",
                                "admin roles"
                            ],
                            withServiceName: [
                                "Google workspace admin role",
                                "Google workspace admin roles"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Admin Role",
                                "Admin Roles"
                            ],
                            withServiceName: [
                                "Google Workspace Admin Role",
                                "Google Workspace Admin Roles"
                            ]
                        }
                    },
                    [Contract.TypeNames.GciDirectoryGroup]: {
                        text: {
                            withoutServiceName: [
                                "group",
                                "groups"
                            ],
                            withServiceName: [
                                "Google workspace group",
                                "Google workspace groups"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Group",
                                "Groups"
                            ],
                            withServiceName: [
                                "Google Workspace Group",
                                "Google Workspace Groups"
                            ]
                        }
                    },
                    [Contract.TypeNames.GciDirectoryOrganizationalUnit]: {
                        text: {
                            withoutServiceName: [
                                "organizational unit",
                                "organizational units"
                            ],
                            withServiceName: [
                                "Google workspace organizational unit",
                                "Google workspace organizational units"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Organizational Unit",
                                "Organizational Units"
                            ],
                            withServiceName: [
                                "Google Workspace Organizational Unit",
                                "Google Workspace Organizational Units"
                            ]
                        }
                    },
                    [Contract.TypeNames.GciDirectoryPartialGroup]: {
                        text: {
                            withoutServiceName: [
                                "group (external)",
                                "groups (external)"
                            ],
                            withServiceName: [
                                "Google workspace group (external)",
                                "Google workspace groups (external)"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Group (external)",
                                "Groups (external)"
                            ],
                            withServiceName: [
                                "Google Workspace Group (external)",
                                "Google Workspace Groups (external)"
                            ]
                        }
                    },
                    [Contract.TypeNames.GciDirectoryPartialUser]: {
                        text: {
                            withoutServiceName: [
                                "user (external)",
                                "users (external)"
                            ],
                            withServiceName: [
                                "Google workspace user (external)",
                                "Google workspace users (external)"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "User (external)",
                                "Users (external)"
                            ],
                            withServiceName: [
                                "Google Workspace User (external)",
                                "Google Workspace Users (external)"
                            ]
                        }
                    },
                    [Contract.TypeNames.GciDirectoryPrincipal]: {
                        text: {
                            withoutServiceName: [
                                "principal",
                                "principals"
                            ],
                            withServiceName: [
                                "Google workspace principal",
                                "Google workspace principals"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Principal",
                                "Principals"
                            ],
                            withServiceName: [
                                "Google Workspace Principal",
                                "Google Workspace Principals"
                            ]
                        }
                    },
                    [Contract.TypeNames.GciDirectorySpecialGroup]: {
                        text: {
                            withServiceName: [
                                "Google workspace special group",
                                "Google workspace special groups"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Google Workspace Special Group",
                                "Google Workspace Special Groups"
                            ]
                        }
                    },
                    [Contract.TypeNames.GciDirectoryUser]: {
                        text: {
                            withoutServiceName: [
                                "user",
                                "users"
                            ],
                            withServiceName: [
                                "Google workspace user",
                                "Google workspace users"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "User",
                                "Users"
                            ],
                            withServiceName: [
                                "Google Workspace User",
                                "Google Workspace Users"
                            ]
                        }
                    },
                    [Contract.TypeNames.GciPartialTenantEntity]: {
                        text: {
                            withServiceName: [
                                "Google workspace",
                                "Google workspaces"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Google Workspace",
                                "Google Workspaces"
                            ]
                        }
                    },
                    [Contract.TypeNames.GciTenantEntity]: {
                        text: {
                            withoutServiceName: [
                                "account",
                                "accounts"
                            ],
                            withServiceName: [
                                "Google workspace account",
                                "Google workspace accounts"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Account",
                                "Accounts"
                            ],
                            withServiceName: [
                                "Google Workspace Account",
                                "Google Workspace Accounts"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpApiKeysKey]: {
                        text: {
                            withoutServiceName: [
                                "API key",
                                "API keys"
                            ],
                            withServiceName: [
                                "API key",
                                "API keys"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "API Key",
                                "API Keys"
                            ],
                            withServiceName: [
                                "API Key",
                                "API Keys"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpAppEngineApplication]: {
                        text: {
                            withoutServiceName: [
                                "application",
                                "applications"
                            ],
                            withServiceName: [
                                "App Engine application",
                                "App Engine applications"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Application",
                                "Applications"
                            ],
                            withServiceName: [
                                "App Engine Application",
                                "App Engine Applications"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpAppEngineApplicationService]: {
                        text: {
                            withoutServiceName: [
                                "service",
                                "services"
                            ],
                            withServiceName: [
                                "App Engine service",
                                "App Engine services"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Service",
                                "Services"
                            ],
                            withServiceName: [
                                "App Engine Service",
                                "App Engine Services"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpAppEngineApplicationServiceRevision]: {
                        text: {
                            withoutServiceName: [
                                "version",
                                "versions"
                            ],
                            withServiceName: [
                                "App Engine version",
                                "App Engine versions"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Version",
                                "Versions"
                            ],
                            withServiceName: [
                                "App Engine Version",
                                "App Engine Versions"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpArtifactContainerImageRepository]: {
                        text: {
                            withoutServiceName: [
                                "container image repository",
                                "container image repositories"
                            ],
                            withServiceName: [
                                "Artifact container image repository",
                                "Artifact container image repositories"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Container Image Repository",
                                "Container Image Repositories"
                            ],
                            withServiceName: [
                                "Artifact Container Image Repository",
                                "Artifact Container Image Repositories"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpArtifactPackageRepository]: {
                        text: {
                            withoutServiceName: [
                                "package repository",
                                "package repositories"
                            ],
                            withServiceName: [
                                "Artifact package repository",
                                "Artifact package repositories"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Package Repository",
                                "Package Repositories"
                            ],
                            withServiceName: [
                                "Artifact Package Repository",
                                "Artifact Package Repositories"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpArtifactRegistry]: {
                        text: {
                            withoutServiceName: [
                                "registry",
                                "registries"
                            ],
                            withServiceName: [
                                "Artifact registry",
                                "Artifact registries"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Registry",
                                "Registries"
                            ],
                            withServiceName: [
                                "Artifact Registry",
                                "Artifact Registries"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpBigQueryDataset]: {
                        text: {
                            withoutServiceName: [
                                "dataset",
                                "datasets"
                            ],
                            withServiceName: [
                                "BigQuery dataset",
                                "BigQuery datasets"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Dataset",
                                "Datasets"
                            ],
                            withServiceName: [
                                "BigQuery Dataset",
                                "BigQuery Datasets"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpBigQueryDatasetTable]: {
                        text: {
                            withServiceName: [
                                "BigQuery dataset table",
                                "BigQuery dataset tables"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "BigQuery Dataset Table",
                                "BigQuery Dataset Tables"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpCloudBuildBuildTrigger]: {
                        text: {
                            withoutServiceName: [
                                "trigger",
                                "triggers"
                            ],
                            withServiceName: [
                                "Cloud build trigger",
                                "Cloud build triggers"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Trigger",
                                "Triggers"
                            ],
                            withServiceName: [
                                "Cloud Build Trigger",
                                "Cloud Build Triggers"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpSpannerInstance]: {
                        text: {
                            withServiceName: [
                                "Spanner instance",
                                "Spanner instances"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Spanner Instance",
                                "Spanner Instances"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpSpannerInstanceDatabase]: {
                        text: {
                            withoutServiceName: [
                                "instance database",
                                "instance databases"
                            ],
                            withServiceName: [
                                "Spanner instance database",
                                "Spanner instance databases"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Spanner Instance Database",
                                "Spanner Instance Databases"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpCloudRunService]: {
                        text: {
                            withoutServiceName: [
                                "service",
                                "services"
                            ],
                            withServiceName: [
                                "Cloud Run service",
                                "Cloud Run services"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Service",
                                "Services"
                            ],
                            withServiceName: [
                                "Cloud Run Service",
                                "Cloud Run Services"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpCloudRunServiceRevision]: {
                        text: {
                            withoutServiceName: [
                                "service revision",
                                "service revisions"
                            ],
                            withServiceName: [
                                "Cloud Run service revision",
                                "Cloud Run service revisions"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Service Revision",
                                "Service Revisions"
                            ],
                            withServiceName: [
                                "Cloud Run Service Revision",
                                "Cloud Run Service Revisions"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputeAutoscaler]: {
                        text: {
                            withoutServiceName: [
                                "autoscaler",
                                "autoscalers"
                            ],
                            withServiceName: [
                                "autoscaler",
                                "autoscalers"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Autoscaler",
                                "Autoscalers"
                            ],
                            withServiceName: [
                                "Autoscaler",
                                "Autoscalers"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputeBackendBucket]: {
                        text: {
                            withServiceName: [
                                "load balancer backend bucket",
                                "load balancer backend buckets"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Load Balancer Backend Bucket",
                                "Load Balancer Backend Buckets"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputeBackendService]: {
                        text: {
                            withServiceName: [
                                "load balancer backend service",
                                "load balancer backend services"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Load Balancer Backend Service",
                                "Load Balancer Backend Services"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputeDisk]: {
                        text: {
                            withoutServiceName: [
                                "disk",
                                "disks"
                            ],
                            withServiceName: [
                                "compute disk",
                                "compute disks"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Disk",
                                "Disks"
                            ],
                            withServiceName: [
                                "Compute Disk",
                                "Compute Disks"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputeFirewallPolicy]: {
                        text: {
                            withServiceName: [
                                "firewall policy",
                                "firewall policies"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Firewall Policy",
                                "Firewall Policies"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputeFirewallRule]: {
                        text: {
                            withServiceName: [
                                "firewall rule",
                                "firewall rules"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Firewall Rule",
                                "Firewall Rules"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputeForwardingRule]: {
                        text: {
                            withServiceName: [
                                "load balancer forwarding rule",
                                "load balancer forwarding rules"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Load Balancer Forwarding Rule",
                                "Load Balancer Forwarding Rules"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputeImage]: {
                        text: {
                            withoutServiceName: [
                                "image",
                                "images"
                            ],
                            withServiceName: [
                                "compute image",
                                "compute images"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Image",
                                "Images"
                            ],
                            withServiceName: [
                                "Compute Image",
                                "Compute Images"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputeInstance]: {
                        text: {
                            withServiceName: [
                                "VM instance",
                                "VM instances"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "VM Instance",
                                "VM Instances"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputeInstanceGroup]: {
                        text: {
                            withServiceName: [
                                "instance group",
                                "instance groups"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Instance Group",
                                "Instance Groups"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputeInstanceGroupManager]: {
                        text: {
                            withServiceName: [
                                "instance group manager",
                                "instance groups manager"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Instance Group Manager",
                                "Instance Group Managers"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputeInstanceTemplate]: {
                        text: {
                            withServiceName: [
                                "instance template",
                                "instance templates"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Instance Template",
                                "Instance Templates"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputeLoadBalancer]: {
                        text: {
                            withServiceName: [
                                "load balancer",
                                "load balancers"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Load Balancer",
                                "Load Balancers"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputeNetworkEndpointGroup]: {
                        text: {
                            withServiceName: [
                                "network endpoint group",
                                "network endpoint groups"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Network Endpoint Group",
                                "Network Endpoint Groups"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputeProject]: {
                        text: {
                            withServiceName: [
                                "project",
                                "projects"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Project",
                                "Projects"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputeResource]: {
                        text: {
                            withServiceName: [
                                "resource",
                                "resources"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Resource",
                                "Resources"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputeRoute]: {
                        text: {
                            withServiceName: [
                                "route",
                                "routes"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Route",
                                "Routes"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputeSnapshot]: {
                        text: {
                            withoutServiceName: [
                                "snapshot",
                                "snapshots"
                            ],
                            withServiceName: [
                                "compute snapshot",
                                "compute snapshots"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Snapshot",
                                "Snapshots"
                            ],
                            withServiceName: [
                                "Compute Snapshot",
                                "Compute Snapshots"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputeSslCertificate]: {
                        text: {
                            withServiceName: [
                                "SSL certificate",
                                "SSL certificates"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "SSL Certificate",
                                "SSL Certificates"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputeSslPolicy]: {
                        text: {
                            withServiceName: [
                                "SSL policy",
                                "SSL policies"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "SSL Policy",
                                "SSL Policies"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputeSubnet]: {
                        text: {
                            withServiceName: [
                                "subnet",
                                "subnets"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Subnet",
                                "Subnets"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputeTargetHttpProxy]: {
                        text: {
                            withServiceName: [
                                "load balancer target HTTP proxy",
                                "load balancer target HTTP proxies"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Load Balancer Target HTTP Proxy",
                                "Load Balancer Target HTTP Proxies"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputeTargetHttpsProxy]: {
                        text: {
                            withServiceName: [
                                "load balancer target HTTPS proxy",
                                "load balancer target HTTPS proxies"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Load Balancer Target HTTPS Proxy",
                                "Load Balancer Target HTTPS Proxies"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputeTargetPool]: {
                        text: {
                            withServiceName: [
                                "load balancer target pool",
                                "load balancer target pools"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Load Balancer Target Pool",
                                "Load Balancer Target Pools"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputeTargetProxy]: {
                        text: {
                            withServiceName: [
                                "load balancer target proxy",
                                "load balancer target proxies"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Load Balancer Target Proxy",
                                "Load Balancer Target Proxies"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputeTargetSslProxy]: {
                        text: {
                            withServiceName: [
                                "load balancer target SSL proxy",
                                "load balancer target SSL proxies"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Load Balancer Target SSL Proxy",
                                "Load Balancer Target SSL Proxies"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputeTargetTcpProxy]: {
                        text: {
                            withServiceName: [
                                "load balancer target TCP proxy",
                                "load balancer target TCP proxies"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Load Balancer Target TCP Proxy",
                                "Load Balancer Target TCP Proxies"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputeUrlMap]: {
                        text: {
                            withServiceName: [
                                "load balancer url map",
                                "load balancer url maps"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Load Balancer URL Map",
                                "Load Balancer URL Maps"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputeVpc]: {
                        text: {
                            withServiceName: [
                                "VPC network",
                                "VPC networks"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "VPC Network",
                                "VPC Networks"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputeVpcPeeringConnection]: {
                        text: {
                            withoutServiceName: [
                                "VPC peering connection",
                                "VPC peering connections"
                            ],
                            withServiceName: [
                                "VPC peering connection",
                                "VPC peering connections"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "VPC Peering Connection",
                                "VPC Peering Connections"
                            ],
                            withServiceName: [
                                "VPC Peering Connection",
                                "VPC Peering Connections"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpContainerCluster]: {
                        text: {
                            withServiceName: [
                                "GKE cluster",
                                "GKE clusters"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "GKE Cluster",
                                "GKE Clusters"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpContainerClusterNodePool]: {
                        text: {
                            withoutServiceName: [
                                "cluster node pool",
                                "cluster node pools"
                            ],
                            withServiceName: [
                                "GKE node pool",
                                "GKE node pools"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Cluster Node Pool",
                                "Cluster Node Pools"
                            ],
                            withServiceName: [
                                "GKE Node Pool",
                                "GKE Node Pools"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpContainerImage]: {
                        text: {
                            withoutServiceName: [
                                "container image",
                                "container images"
                            ],
                            withServiceName: [
                                "container Image",
                                "container Images"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Container Image",
                                "Container Images"
                            ],
                            withServiceName: [
                                "Container Image",
                                "Container Images"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpDnsManagedZone]: {
                        text: {
                            withoutServiceName: [
                                "managed zone",
                                "managed zones"
                            ],
                            withServiceName: [
                                "DNS managed zone",
                                "DNS managed zones"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Managed Zone",
                                "Managed Zones"
                            ],
                            withServiceName: [
                                "DNS Managed Zone",
                                "DNS Managed Zones"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpDnsManagedZoneResourceRecordSet]: {
                        text: {
                            withoutServiceName: [
                                "managed zone resource record set",
                                "managed zone resource record sets"
                            ],
                            withServiceName: [
                                "DNS managed zone resource record set",
                                "DNS managed zone resource record sets"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Managed Zone Resource Record Set",
                                "Managed Zone Resource Record Sets"
                            ],
                            withServiceName: [
                                "DNS Managed Zone Resource Record Set",
                                "DNS Managed Zone Resource Record Sets"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpEntity]: {
                        text: {
                            withServiceName: [
                                "resource",
                                "resources"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Resource",
                                "Resources"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpEventarcTrigger]: {
                        text: {
                            withServiceName: [
                                "eventarc trigger",
                                "eventarc triggers"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Eventarc Trigger",
                                "Eventarc Triggers"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpFunctionsFunction]: {
                        text: {
                            withServiceName: [
                                "Cloud function",
                                "Cloud functions"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Cloud Function",
                                "Cloud Functions"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpIamGcpManagedPartialServiceAccount]: {
                        text: {
                            withServiceName: [
                                "service account (google)",
                                "service accounts (google)"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Service Account (Google)",
                                "Service Accounts (Google)"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpIamPartialServiceAccount]: {
                        text: {
                            withServiceName: [
                                "service account (external)",
                                "service accounts (external)"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Service Account (external)",
                                "Service Accounts (external)"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpIamRole]: {
                        text: {
                            withoutServiceName: [
                                "role",
                                "roles"
                            ],
                            withServiceName: [
                                "role",
                                "roles"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Role",
                                "Roles"
                            ],
                            withServiceName: [
                                "Role",
                                "Roles"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpIamRoleBinding]: {
                        text: {
                            withServiceName: [
                                "role binding",
                                "role bindings"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Role Binding",
                                "Role Bindings"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpIamServiceAccount]: {
                        text: {
                            withoutServiceName: [
                                "service account",
                                "service accounts"
                            ],
                            withServiceName: [
                                "service account",
                                "service accounts"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Service Account",
                                "Service Accounts"
                            ],
                            withServiceName: [
                                "Service Account",
                                "Service Accounts"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpIamServiceAccountKey]: {
                        text: {
                            withServiceName: [
                                "service account key",
                                "service account keys"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Service Account Key",
                                "Service Account Keys"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpIamWorkforcePool]: {
                        text: {
                            withServiceName: [
                                "workforce identity pool",
                                "workforce identity pools"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Workforce Identity Pool",
                                "Workforce Identity Pools"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpIamWorkforcePoolProvider]: {
                        text: {
                            withServiceName: [
                                "workforce identity pool provider",
                                "workforce identity pool providers"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Workforce Identity Pool Provider",
                                "Workforce Identity Pool Providers"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpIamWorkloadIdentityPool]: {
                        text: {
                            withServiceName: [
                                "workload identity pool",
                                "workload identity pools"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Workload Identity Pool",
                                "Workload Identity Pools"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpIamWorkloadIdentityPoolProvider]: {
                        text: {
                            withServiceName: [
                                "workload identity pool provider",
                                "workload identity pool providers"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Workload Identity Pool Provider",
                                "Workload Identity Pool Providers"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpKmsKeyRing]: {
                        text: {
                            withoutServiceName: [
                                "key ring",
                                "key rings"
                            ],
                            withServiceName: [
                                "Cloud KMS key ring",
                                "Cloud KMS key rings"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Key Ring",
                                "Key Rings"
                            ],
                            withServiceName: [
                                "Cloud KMS Key Ring",
                                "Cloud KMS Key Rings"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpKmsKeyRingKey]: {
                        text: {
                            withoutServiceName: [
                                "key",
                                "keys"
                            ],
                            withServiceName: [
                                "Cloud KMS key",
                                "Cloud KMS keys"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Key",
                                "Keys"
                            ],
                            withServiceName: [
                                "Cloud KMS Key",
                                "Cloud KMS Keys"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpKubernetesClusterRole]: {
                        text: {
                            withoutServiceName: [
                                "cluster role",
                                "cluster roles"
                            ],
                            withServiceName: [
                                "K8s cluster role",
                                "K8s cluster roles"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Cluster Role",
                                "Cluster Roles"
                            ],
                            withServiceName: [
                                "K8s Cluster Role",
                                "K8s Cluster Roles"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpKubernetesClusterRoleBinding]: {
                        text: {
                            withoutServiceName: [
                                "cluster role binding",
                                "cluster role bindings"
                            ],
                            withServiceName: [
                                "K8s cluster role binding",
                                "K8s cluster role bindings"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Cluster Role Binding",
                                "Cluster Role Bindings"
                            ],
                            withServiceName: [
                                "K8s Cluster Role Binding",
                                "K8s Cluster Role Bindings"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpKubernetesConfigMap]: {
                        text: {
                            withoutServiceName: [
                                "config map",
                                "config maps"
                            ],
                            withServiceName: [
                                "K8s config map",
                                "K8s config maps"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Config Map",
                                "Config Maps"
                            ],
                            withServiceName: [
                                "K8s Config Map",
                                "K8s Config Maps"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpKubernetesCronJob]: {
                        text: {
                            withoutServiceName: [
                                "cron job",
                                "cron jobs"
                            ],
                            withServiceName: [
                                "K8s cron job",
                                "K8s cron jobs"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Cron Job",
                                "Cron Jobs"
                            ],
                            withServiceName: [
                                "K8s Cron Job",
                                "K8s Cron Jobs"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpKubernetesDaemonSet]: {
                        text: {
                            withoutServiceName: [
                                "daemon set",
                                "daemon sets"
                            ],
                            withServiceName: [
                                "K8s daemon set",
                                "K8s daemon sets"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Daemon Set",
                                "Daemon Sets"
                            ],
                            withServiceName: [
                                "K8s Daemon Set",
                                "K8s Daemon Sets"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpKubernetesDeployment]: {
                        text: {
                            withoutServiceName: [
                                "deployment",
                                "deployments"
                            ],
                            withServiceName: [
                                "K8s deployment",
                                "K8s deployments"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Deployment",
                                "Deployments"
                            ],
                            withServiceName: [
                                "K8s Deployment",
                                "K8s Deployments"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpKubernetesGroup]: {
                        text: {
                            withoutServiceName: [
                                "group",
                                "groups"
                            ],
                            withServiceName: [
                                "K8s group",
                                "K8s groups"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Group",
                                "Groups"
                            ],
                            withServiceName: [
                                "K8s Group",
                                "K8s Groups"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpKubernetesIngress]: {
                        text: {
                            withoutServiceName: [
                                "ingress",
                                "ingresses"
                            ],
                            withServiceName: [
                                "K8s ingress",
                                "K8s ingresses"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Ingress",
                                "Ingresses"
                            ],
                            withServiceName: [
                                "K8s Ingress",
                                "K8s Ingresses"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpKubernetesIngressClass]: {
                        text: {
                            withoutServiceName: [
                                "ingress class",
                                "ingress classes"
                            ],
                            withServiceName: [
                                "K8s ingress class",
                                "K8s ingress classes"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Ingress class",
                                "Ingress classes"
                            ],
                            withServiceName: [
                                "K8s Ingress class",
                                "K8s Ingress classes"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpKubernetesJob]: {
                        text: {
                            withoutServiceName: [
                                "job",
                                "jobs"
                            ],
                            withServiceName: [
                                "K8s job",
                                "K8s jobs"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Job",
                                "Jobs"
                            ],
                            withServiceName: [
                                "K8s Job",
                                "K8s Jobs"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpKubernetesNamespace]: {
                        text: {
                            withoutServiceName: [
                                "namespace",
                                "namespaces"
                            ],
                            withServiceName: [
                                "K8s namespace",
                                "K8s namespaces"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Namespace",
                                "Namespaces"
                            ],
                            withServiceName: [
                                "K8s Namespace",
                                "K8s Namespaces"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpKubernetesNamespaceRole]: {
                        text: {
                            withoutServiceName: [
                                "role",
                                "roles"
                            ],
                            withServiceName: [
                                "K8s role",
                                "K8s roles"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Role",
                                "Roles"
                            ],
                            withServiceName: [
                                "K8s Role",
                                "K8s Roles"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpKubernetesNamespaceRoleBinding]: {
                        text: {
                            withoutServiceName: [
                                "role binding",
                                "role bindings"
                            ],
                            withServiceName: [
                                "K8s role binding",
                                "K8s role bindings"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Role Binding",
                                "Role Bindings"
                            ],
                            withServiceName: [
                                "K8s Role Binding",
                                "K8s Role Bindings"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpKubernetesNetworkPolicy]: {
                        text: {
                            withoutServiceName: [
                                "network policy",
                                "network policies"
                            ],
                            withServiceName: [
                                "K8s network policy",
                                "K8s network policies"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Network Policy",
                                "Network Policies"
                            ],
                            withServiceName: [
                                "K8s Network Policy",
                                "K8s Network Policies"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpKubernetesNode]: {
                        text: {
                            withoutServiceName: [
                                "node",
                                "nodes"
                            ],
                            withServiceName: [
                                "K8s node",
                                "K8s nodes"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Node",
                                "Nodes"
                            ],
                            withServiceName: [
                                "K8s Node",
                                "K8s Nodes"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpKubernetesPersistentVolume]: {
                        text: {
                            withoutServiceName: [
                                "persistent volume",
                                "persistent volumes"
                            ],
                            withServiceName: [
                                "K8s persistent volume",
                                "K8s persistent volumes"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Persistent Volume",
                                "Persistent Volumes"
                            ],
                            withServiceName: [
                                "K8s Persistent Volume",
                                "K8s Persistent Volumes"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpKubernetesPod]: {
                        text: {
                            withoutServiceName: [
                                "pod",
                                "pods"
                            ],
                            withServiceName: [
                                "K8s pod",
                                "K8s pod"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Pod",
                                "Pods"
                            ],
                            withServiceName: [
                                "K8s Pod",
                                "K8s Pod"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpKubernetesPodSecurityPolicy]: {
                        text: {
                            withoutServiceName: [
                                "pod security policy",
                                "pod security policies"
                            ],
                            withServiceName: [
                                "K8s pod security policy",
                                "K8s pod security policies"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Pod Security Policy",
                                "Pod Security Policies"
                            ],
                            withServiceName: [
                                "K8s Pod Security Policy",
                                "K8s Pod Security Policies"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpKubernetesPodTemplate]: {
                        text: {
                            withoutServiceName: [
                                "pod template",
                                "pod templates"
                            ],
                            withServiceName: [
                                "K8s pod template",
                                "K8s pod templates"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Pod Template",
                                "Pod Templates"
                            ],
                            withServiceName: [
                                "K8s Pod Template",
                                "K8s Pod Templates"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpKubernetesReplicaSet]: {
                        text: {
                            withoutServiceName: [
                                "replica set",
                                "replica sets"
                            ],
                            withServiceName: [
                                "K8s replica set",
                                "K8s replica sets"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Replica Set",
                                "Replica Sets"
                            ],
                            withServiceName: [
                                "K8s Replica Set",
                                "K8s Replica Sets"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpKubernetesResource]: {
                        text: {
                            withoutServiceName: [
                                "resource",
                                "resources"
                            ],
                            withServiceName: [
                                "K8s resource",
                                "K8s resources"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Resource",
                                "Resources"
                            ],
                            withServiceName: [
                                "K8s Resource",
                                "K8s Resources"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpKubernetesService]: {
                        text: {
                            withoutServiceName: [
                                "service",
                                "services"
                            ],
                            withServiceName: [
                                "K8s service",
                                "K8s services"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Service",
                                "Services"
                            ],
                            withServiceName: [
                                "K8s Service",
                                "K8s Services"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpKubernetesServiceAccount]: {
                        text: {
                            withoutServiceName: [
                                "service account",
                                "service accounts"
                            ],
                            withServiceName: [
                                "K8s service account",
                                "K8s service accounts"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Service Account",
                                "Service Accounts"
                            ],
                            withServiceName: [
                                "K8s Service Account",
                                "K8s Service Accounts"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpKubernetesStatefulSet]: {
                        text: {
                            withoutServiceName: [
                                "stateful set",
                                "stateful sets"
                            ],
                            withServiceName: [
                                "K8s stateful set",
                                "K8s stateful sets"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Stateful Set",
                                "Stateful Sets"
                            ],
                            withServiceName: [
                                "K8s Stateful Set",
                                "K8s Stateful Sets"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpKubernetesUser]: {
                        text: {
                            withoutServiceName: [
                                "user",
                                "users"
                            ],
                            withServiceName: [
                                "K8s user",
                                "K8s users"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "User",
                                "Users"
                            ],
                            withServiceName: [
                                "K8s User",
                                "K8s Users"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpKubernetesWorkloadResourceBase]: {
                        text: {
                            withoutServiceName: [
                                "workload",
                                "workloads"
                            ],
                            withServiceName: [
                                "K8s workload",
                                "K8s workloads"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Workload",
                                "Workloads"
                            ],
                            withServiceName: [
                                "K8s Workload",
                                "K8s Workloads"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpLoggingLogBucket]: {
                        text: {
                            withoutServiceName: [
                                "log bucket",
                                "log buckets"
                            ],
                            withServiceName: [
                                "Log Bucket",
                                "Log Buckets"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Bucket",
                                "Buckets"
                            ],
                            withServiceName: [
                                "Log Bucket",
                                "Log Buckets"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpLoggingLogSink]: {
                        text: {
                            withoutServiceName: [
                                "log sink",
                                "log sinks"
                            ],
                            withServiceName: [
                                "Log Sink",
                                "Log Sinks"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Sink",
                                "Sinks"
                            ],
                            withServiceName: [
                                "Log Sink",
                                "Log Sinks"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpNotebooksInstance]: {
                        text: {
                            withoutServiceName: [
                                "instance",
                                "instances"
                            ],
                            withServiceName: [
                                "Vertex AI workbench instance",
                                "Vertex AI workbench instances"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Instance",
                                "Instances"
                            ],
                            withServiceName: [
                                "Vertex AI Workbench Instance",
                                "Vertex AI Workbench Instances"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpOpenShiftBuildConfig]: {
                        text: {
                            withoutServiceName: [
                                "build config",
                                "build configs"
                            ],
                            withServiceName: [
                                "OpenShift build config",
                                "OpenShift build configs"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Build Config",
                                "Build Configs"
                            ],
                            withServiceName: [
                                "OpenShift Build Config",
                                "OpenShift Build Configs"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpOpenShiftCatalogSource]: {
                        text: {
                            withoutServiceName: [
                                "catalog source",
                                "catalog sources"
                            ],
                            withServiceName: [
                                "OpenShift catalog source",
                                "OpenShift catalog sources"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Catalog Source",
                                "Catalog Sources"
                            ],
                            withServiceName: [
                                "OpenShift Catalog Source",
                                "OpenShift Catalog Sources"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpOpenShiftDeploymentConfig]: {
                        text: {
                            withoutServiceName: [
                                "deployment config",
                                "deployment configs"
                            ],
                            withServiceName: [
                                "OpenShift deployment config",
                                "OpenShift deployment configs"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Deployment Config",
                                "Deployment Configs"
                            ],
                            withServiceName: [
                                "OpenShift Deployment Config",
                                "OpenShift Deployment Configs"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpOpenShiftImageStream]: {
                        text: {
                            withoutServiceName: [
                                "image stream",
                                "image streams"
                            ],
                            withServiceName: [
                                "OpenShift image stream",
                                "OpenShift image streams"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Image Stream",
                                "Image Streams"
                            ],
                            withServiceName: [
                                "OpenShift Image Stream",
                                "OpenShift Image Streams"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpOpenShiftProject]: {
                        text: {
                            withoutServiceName: [
                                "project",
                                "projects"
                            ],
                            withServiceName: [
                                "OpenShift project",
                                "OpenShift projects"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Project",
                                "Projects"
                            ],
                            withServiceName: [
                                "OpenShift Project",
                                "OpenShift Projects"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpOpenShiftRoute]: {
                        text: {
                            withoutServiceName: [
                                "route",
                                "routes"
                            ],
                            withServiceName: [
                                "OpenShift route",
                                "OpenShift routes"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Route",
                                "Routes"
                            ],
                            withServiceName: [
                                "OpenShift Route",
                                "OpenShift Routes"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpOpenShiftSecurityContextConstraint]: {
                        text: {
                            withoutServiceName: [
                                "security context constraint",
                                "security context constraints"
                            ],
                            withServiceName: [
                                "OpenShift security context constraint",
                                "OpenShift security context constraints"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Security Context Constraint",
                                "Security Context Constraints"
                            ],
                            withServiceName: [
                                "OpenShift Security Context Constraint",
                                "OpenShift Security Context Constraints"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpOpenShiftTemplate]: {
                        text: {
                            withoutServiceName: [
                                "template",
                                "templates"
                            ],
                            withServiceName: [
                                "OpenShift template",
                                "OpenShift templates"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Template",
                                "Templates"
                            ],
                            withServiceName: [
                                "OpenShift Template",
                                "OpenShift Templates"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpPartialSpecialGroup]: {
                        text: {
                            withServiceName: [
                                "GCP special group",
                                "GCP special groups"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "GCP Special Group",
                                "GCP Special Groups"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpPubSubSubscription]: {
                        text: {
                            withoutServiceName: [
                                "subscription",
                                "subscriptions"
                            ],
                            withServiceName: [
                                "Pub/Sub subscription",
                                "Pub/Sub subscriptions"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Subscription",
                                "Subscriptions"
                            ],
                            withServiceName: [
                                "Pub/Sub Subscription",
                                "Pub/Sub Subscriptions"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpPubSubTopic]: {
                        text: {
                            withoutServiceName: [
                                "topic",
                                "topics"
                            ],
                            withServiceName: [
                                "Pub/Sub topic",
                                "Pub/Sub topics"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Topic",
                                "Topics"
                            ],
                            withServiceName: [
                                "Pub/Sub Topic",
                                "Pub/Sub Topics"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpResource]: {
                        text: {
                            withServiceName: [
                                "resource",
                                "resources"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Resource",
                                "Resources"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpResourceManagerFolder]: {
                        text: {
                            withServiceName: [
                                "folder",
                                "folders"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Folder",
                                "Folders"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpResourceManagerOrganization]: {
                        text: {
                            withServiceName: [
                                "organization",
                                "organizations"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Organization",
                                "Organizations"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpResourceManagerResource]: {
                        text: {
                            withServiceName: [
                                "resource manager resource",
                                "resource manager resources"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Resource Manager Resource",
                                "Resource Manager Resources"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpScopeResource]: {
                        text: {
                            withServiceName: [
                                "resource",
                                "resources"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Resource",
                                "Resources"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpSecretManagerSecret]: {
                        text: {
                            withoutServiceName: [
                                "secret",
                                "secrets"
                            ],
                            withServiceName: [
                                "Secret Manager secret",
                                "Secret Manager secrets"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Secret",
                                "Secrets"
                            ],
                            withServiceName: [
                                "Secret Manager Secret",
                                "Secret Manager Secrets"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpSecretManagerSecretVersion]: {
                        text: {
                            withoutServiceName: [
                                "secret version",
                                "secret versions"
                            ],
                            withServiceName: [
                                "Secret Manager secret version",
                                "Secret Manager secret versions"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Secret Version",
                                "Secret Versions"
                            ],
                            withServiceName: [
                                "Secret Manager Secret Version",
                                "Secret Manager Secret Versions"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpService]: {
                        text: {
                            withServiceName: [
                                "service",
                                "services"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Service",
                                "Services"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpSpecialGroup]: {
                        text: {
                            withServiceName: [
                                "special group",
                                "special groups"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "GCP Special Group",
                                "GCP Special Groups"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpSqlInstance]: {
                        text: {
                            withServiceName: [
                                "SQL instance",
                                "SQL instances"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "SQL Instance",
                                "SQL Instances"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpSqlInstanceDatabase]: {
                        text: {
                            withServiceName: [
                                "SQL instance database",
                                "SQL instance databases"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "SQL Instance Database",
                                "SQL Instance Databases"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpSqlInstanceUser]: {
                        text: {
                            withServiceName: [
                                "SQL instance user",
                                "SQL instance users"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "SQL Instance User",
                                "SQL Instance Users"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpStorageBucket]: {
                        text: {
                            withoutServiceName: [
                                "bucket",
                                "buckets"
                            ],
                            withServiceName: [
                                "storage bucket",
                                "storage buckets"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Bucket",
                                "Buckets"
                            ],
                            withServiceName: [
                                "Storage Bucket",
                                "Storage Buckets"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpTenantEntity]: {
                        text: {
                            withServiceName: [
                                "project",
                                "projects"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Project",
                                "Projects"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpUnmanagedKubernetesCluster]: {
                        text: {
                            withoutServiceName: [
                                "Self-Managed cluster",
                                "Self-Managed clusters"
                            ],
                            withServiceName: [
                                "Self-Managed K8s cluster",
                                "Self-Managed K8s clusters"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Self-Managed Cluster",
                                "Self-Managed Clusters"
                            ],
                            withServiceName: [
                                "Self-Managed K8s Cluster",
                                "Self-Managed K8s Clusters"
                            ]
                        }
                    },
                    [Contract.TypeNames.IAwsDocDbCluster]: {
                        text: {
                            withServiceName: [
                                "DocumentDB cluster",
                                "DocumentDB clusters"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "DocumentDB Cluster",
                                "DocumentDB Clusters"
                            ]
                        }
                    },
                    [Contract.TypeNames.IAwsDocDbClusterSnapshot]: {
                        text: {
                            withServiceName: [
                                "DocumentDB cluster snapshot",
                                "DocumentDB cluster snapshots"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "DocumentDB Cluster Snapshot",
                                "DocumentDB Cluster Snapshots"
                            ]
                        }
                    },
                    [Contract.TypeNames.IAwsEncryptedResource]: {
                        text: {
                            withServiceName: [
                                "encrypted resource",
                                "encrypted resources"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Encrypted Resource",
                                "Encrypted Resources"
                            ]
                        }
                    },
                    [Contract.TypeNames.IAwsNetworkedResource]: {
                        text: {
                            withServiceName: [
                                "network resource",
                                "network resources"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Network Resource",
                                "Network Resources"
                            ]
                        }
                    },
                    [Contract.TypeNames.IAwsNetworkEvaluationNetworkedResource]: {
                        text: {
                            withServiceName: [
                                "resource",
                                "resources"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Resource",
                                "Resources"
                            ]
                        }
                    },
                    [Contract.TypeNames.IAwsOriginatorWorkloadResource]: {
                        text: {
                            withServiceName: [
                                "workload",
                                "workloads"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Workload",
                                "Workloads"
                            ]
                        }
                    },
                    [Contract.TypeNames.IAwsRdsDocDbResource]: {
                        text: {
                            withServiceName: [
                                "DocumentDB resource",
                                "DocumentDB resources"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "DocumentDB Resource",
                                "DocumentDB Resources"
                            ]
                        }
                    },
                    [Contract.TypeNames.IAwsRdsNeptuneResource]: {
                        text: {
                            withServiceName: [
                                "Neptune resource",
                                "Neptune resources"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Neptune Resource",
                                "Neptune Resources"
                            ]
                        }
                    },
                    [Contract.TypeNames.IAwsTagResource]: {
                        text: {
                            withServiceName: [
                                "resource",
                                "resources"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Resource",
                                "Resources"
                            ]
                        }
                    },
                    [Contract.TypeNames.IAzureManagedIdentityOriginatorResource]: {
                        text: {
                            withServiceName: [
                                "resource",
                                "resources"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Resource",
                                "Resources"
                            ]
                        }
                    },
                    [Contract.TypeNames.IAzureNetworkEvaluationResource]: {
                        text: {
                            withServiceName: [
                                "resource",
                                "resources"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Resource",
                                "Resources"
                            ]
                        }
                    },
                    [Contract.TypeNames.IAzureTagResource]: {
                        text: {
                            withServiceName: [
                                "resource",
                                "resources"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Resource",
                                "Resources"
                            ]
                        }
                    },
                    [Contract.TypeNames.IContainerImage]: {
                        text: {
                            withServiceName: [
                                "container image",
                                "container images"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Container Image",
                                "Container Images"
                            ]
                        }
                    },
                    [Contract.TypeNames.IContainerImageRepository]: {
                        text: {
                            withServiceName: [
                                "container image repository",
                                "container image repositories"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Container Image Repository",
                                "Container Image Repositories"
                            ]
                        }
                    },
                    [Contract.TypeNames.IContainerImageResource]: {
                        text: {
                            withServiceName: [
                                "container workload",
                                "container workloads"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Container Workload",
                                "Container Workloads"
                            ]
                        }
                    },
                    [Contract.TypeNames.IDataAnalysisResource]: {
                        text: {
                            withServiceName: [
                                "data resource",
                                "data resources"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Data Resource",
                                "Data Resources"
                            ]
                        }
                    },
                    [Contract.TypeNames.IDirectoryGroup]: {
                        text: {
                            withServiceName: [
                                "group",
                                "groups"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Group",
                                "Groups"
                            ]
                        }
                    },
                    [Contract.TypeNames.IGciDirectoryPrincipal]: {
                        text: {
                            withServiceName: [
                                "principal",
                                "principals"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Principal",
                                "Principals"
                            ]
                        }
                    },
                    [Contract.TypeNames.IGciGroup]: {
                        text: {
                            withServiceName: [
                                "group",
                                "groups"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Group",
                                "Groups"
                            ]
                        }
                    },
                    [Contract.TypeNames.IGciIdentity]: {
                        text: {
                            withServiceName: [
                                "identity",
                                "identities"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Google Identity",
                                "Google Identities"
                            ]
                        }
                    },
                    [Contract.TypeNames.IGciPartialPrincipal]: {
                        text: {
                            withServiceName: [
                                "external principal",
                                "external principals"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "External Principal",
                                "External Principals"
                            ]
                        }
                    },
                    [Contract.TypeNames.IGciPrincipal]: {
                        text: {
                            withServiceName: [
                                "principal",
                                "principals"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Principal",
                                "Principals"
                            ]
                        }
                    },
                    [Contract.TypeNames.IGciTenantEntity]: {
                        text: {
                            withServiceName: [
                                "Google workspace",
                                "Google workspaces"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Google Workspace",
                                "Google Workspaces"
                            ]
                        }
                    },
                    [Contract.TypeNames.IGciUser]: {
                        text: {
                            withServiceName: [
                                "user",
                                "users"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "User",
                                "Users"
                            ]
                        }
                    },
                    [Contract.TypeNames.IGcpArtifactRepository]: {
                        text: {
                            withServiceName: [
                                "repository",
                                "repositories"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Repository",
                                "Repositories"
                            ]
                        }
                    },
                    [Contract.TypeNames.IGcpIamServiceAccount]: {
                        text: {
                            withServiceName: [
                                "service account",
                                "service accounts"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Service Account",
                                "Service Accounts"
                            ]
                        }
                    },
                    [Contract.TypeNames.IGcpNetworkEvaluationEntity]: {
                        text: {
                            withServiceName: [
                                "resource",
                                "resources"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Resource",
                                "Resources"
                            ]
                        }
                    },
                    [Contract.TypeNames.IGcpTagResource]: {
                        text: {
                            withServiceName: [
                                "resource",
                                "resources"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Resource",
                                "Resources"
                            ]
                        }
                    },
                    [Contract.TypeNames.IGcpServiceAccountOriginatorScopeResource]: {
                        text: {
                            withServiceName: [
                                "resource",
                                "resources"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Resource",
                                "Resources"
                            ]
                        }
                    },
                    [Contract.TypeNames.IKubernetesCluster]: {
                        text: {
                            withServiceName: [
                                "cluster",
                                "clusters"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Cluster",
                                "Clusters"
                            ]
                        }
                    },
                    [Contract.TypeNames.IKubernetesClusterRole]: {
                        text: {
                            withServiceName: [
                                "cluster role",
                                "cluster roles"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Cluster Role",
                                "Cluster Roles"
                            ]
                        }
                    },
                    [Contract.TypeNames.IKubernetesClusterRoleBinding]: {
                        text: {
                            withServiceName: [
                                "cluster role binding",
                                "cluster role bindings"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Cluster Role Binding",
                                "Cluster Role Bindings"
                            ]
                        }
                    },
                    [Contract.TypeNames.IKubernetesConfigMap]: {
                        text: {
                            withServiceName: [
                                "config map",
                                "config maps"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Config Map",
                                "Config Maps"
                            ]
                        }
                    },
                    [Contract.TypeNames.IKubernetesCronJob]: {
                        text: {
                            withServiceName: [
                                "cron job",
                                "cron jobs"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Cron Job",
                                "Cron Jobs"
                            ]
                        }
                    },
                    [Contract.TypeNames.IKubernetesDaemonSet]: {
                        text: {
                            withServiceName: [
                                "daemon set",
                                "daemon sets"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Daemon Set",
                                "Daemon Sets"
                            ]
                        }
                    },
                    [Contract.TypeNames.IKubernetesDeployment]: {
                        text: {
                            withServiceName: [
                                "deployment",
                                "deployments"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Deployment",
                                "Deployments"
                            ]
                        }
                    },
                    [Contract.TypeNames.IKubernetesGroup]: {
                        text: {
                            withServiceName: [
                                "group",
                                "groups"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Group",
                                "Groups"
                            ]
                        }
                    },
                    [Contract.TypeNames.IKubernetesIngress]: {
                        text: {
                            withServiceName: [
                                "ingress",
                                "ingresses"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Ingress",
                                "Ingresses"
                            ]
                        }
                    },
                    [Contract.TypeNames.IKubernetesIngressClass]: {
                        text: {
                            withServiceName: [
                                "ingress class",
                                "ingress classes"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Ingress class",
                                "Ingress classes"
                            ]
                        }
                    },
                    [Contract.TypeNames.IKubernetesJob]: {
                        text: {
                            withServiceName: [
                                "job",
                                "jobs"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Job",
                                "Jobs"
                            ]
                        }
                    },
                    [Contract.TypeNames.IKubernetesNamespace]: {
                        text: {
                            withoutServiceName: [
                                "namespace",
                                "namespaces"
                            ],
                            withServiceName: [
                                "K8s namespace",
                                "K8s namespaces"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Namespace",
                                "Namespaces"
                            ],
                            withServiceName: [
                                "K8s Namespace",
                                "K8s Namespaces"
                            ]
                        }
                    },
                    [Contract.TypeNames.IKubernetesNamespaceRole]: {
                        text: {
                            withServiceName: [
                                "role",
                                "roles"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Role",
                                "Roles"
                            ]
                        }
                    },
                    [Contract.TypeNames.IKubernetesNamespaceRoleBinding]: {
                        text: {
                            withServiceName: [
                                "role binding",
                                "role bindings"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Role Binding",
                                "Role Bindings"
                            ]
                        }
                    },
                    [Contract.TypeNames.IKubernetesNetworkPolicy]: {
                        text: {
                            withServiceName: [
                                "network policy",
                                "network policies"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Network Policy",
                                "Network Policies"
                            ]
                        }
                    },
                    [Contract.TypeNames.IKubernetesNode]: {
                        text: {
                            withoutServiceName: [
                                "node",
                                "nodes"
                            ],
                            withServiceName: [
                                "K8s node",
                                "K8s nodes"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Node",
                                "Nodes"
                            ],
                            withServiceName: [
                                "K8s Node",
                                "K8s Nodes"
                            ]
                        }
                    },
                    [Contract.TypeNames.IKubernetesPersistentVolume]: {
                        text: {
                            withServiceName: [
                                "persistent volume",
                                "persistent volumes"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Persistent Volume",
                                "Persistent Volumes"
                            ]
                        }
                    },
                    [Contract.TypeNames.IKubernetesPod]: {
                        text: {
                            withServiceName: [
                                "pod",
                                "pods"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Pod",
                                "Pods"
                            ]
                        }
                    },
                    [Contract.TypeNames.IKubernetesPodSecurityPolicy]: {
                        text: {
                            withServiceName: [
                                "pod security policy",
                                "pod security policies"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Pod Security Policy",
                                "Pod Security Policies"
                            ]
                        }
                    },
                    [Contract.TypeNames.IKubernetesPodTemplate]: {
                        text: {
                            withServiceName: [
                                "pod template",
                                "pod templates"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Pod Template",
                                "Pod Templates"
                            ]
                        }
                    },
                    [Contract.TypeNames.IKubernetesPrincipal]: {
                        text: {
                            withServiceName: [
                                "subject",
                                "subjects"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Subject",
                                "Subjects"
                            ]
                        }
                    },
                    [Contract.TypeNames.IKubernetesReplicaSet]: {
                        text: {
                            withServiceName: [
                                "replica set",
                                "replica sets"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Replica Set",
                                "Replica Sets"
                            ]
                        }
                    },
                    [Contract.TypeNames.IKubernetesResource]: {
                        text: {
                            withServiceName: [
                                "resource",
                                "resources"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Resource",
                                "Resources"
                            ]
                        }
                    },
                    [Contract.TypeNames.IKubernetesRole]: {
                        text: {
                            withServiceName: [
                                "role",
                                "roles"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Role",
                                "Roles"
                            ]
                        }
                    },
                    [Contract.TypeNames.IKubernetesRoleBinding]: {
                        text: {
                            withServiceName: [
                                "role binding",
                                "role bindings"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Role Binding",
                                "Role Bindings"
                            ]
                        }
                    },
                    [Contract.TypeNames.IKubernetesService]: {
                        text: {
                            withServiceName: [
                                "service",
                                "services"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Service",
                                "Services"
                            ]
                        }
                    },
                    [Contract.TypeNames.IKubernetesStatefulSet]: {
                        text: {
                            withoutServiceName: [
                                "stateful set",
                                "stateful sets"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Stateful Set",
                                "Stateful Sets"
                            ]
                        }
                    },
                    [Contract.TypeNames.IKubernetesUser]: {
                        text: {
                            withServiceName: [
                                "user",
                                "users"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "User",
                                "Users"
                            ]
                        }
                    },
                    [Contract.TypeNames.IKubernetesServiceAccount]: {
                        text: {
                            withoutServiceName: [
                                "service account",
                                "service accounts"
                            ],
                            withServiceName: [
                                "K8s service account",
                                "K8s service accounts"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Service Account",
                                "Service Accounts"
                            ],
                            withServiceName: [
                                "K8s Service Account",
                                "K8s Service Accounts"
                            ]
                        }
                    },
                    [Contract.TypeNames.IKubernetesWorkloadResource]: {
                        text: {
                            withServiceName: [
                                "workload",
                                "workloads"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Workload",
                                "Workloads"
                            ]
                        }
                    },
                    [Contract.TypeNames.INetworkedResource]: {
                        text: {
                            withServiceName: [
                                "network resource",
                                "network resources"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Network Resource",
                                "Network Resources"
                            ]
                        }
                    },
                    [Contract.TypeNames.IOpenShiftBuildConfig]: {
                        text: {
                            withServiceName: [
                                "build config",
                                "build configs"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Build Config",
                                "Build Configs"
                            ]
                        }
                    },
                    [Contract.TypeNames.IOpenShiftCatalogSource]: {
                        text: {
                            withServiceName: [
                                "catalog source",
                                "catalog sources"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Catalog Source",
                                "Catalog Sources"
                            ]
                        }
                    },
                    [Contract.TypeNames.IOpenShiftDeploymentConfig]: {
                        text: {
                            withServiceName: [
                                "deployment config",
                                "deployment configs"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Deployment Config",
                                "Deployment Configs"
                            ]
                        }
                    },
                    [Contract.TypeNames.IOpenShiftImageStream]: {
                        text: {
                            withServiceName: [
                                "image stream",
                                "image streams"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Image Stream",
                                "Image Streams"
                            ]
                        }
                    },
                    [Contract.TypeNames.IOpenShiftProject]: {
                        text: {
                            withServiceName: [
                                "project",
                                "projects"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Project",
                                "Projects"
                            ]
                        }
                    },
                    [Contract.TypeNames.IOpenShiftRoute]: {
                        text: {
                            withServiceName: [
                                "route",
                                "routes"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Route",
                                "Routes"
                            ]
                        }
                    },
                    [Contract.TypeNames.IOpenShiftSecurityContextConstraint]: {
                        text: {
                            withServiceName: [
                                "security context constraint",
                                "security context constraints"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Security Context Constraint",
                                "Security Context Constraints"
                            ]
                        }
                    },
                    [Contract.TypeNames.IOpenShiftTemplate]: {
                        text: {
                            withServiceName: [
                                "template",
                                "templates"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Template",
                                "Templates"
                            ]
                        }
                    },
                    [Contract.TypeNames.IPermissionManagementPrincipal]: {
                        text: {
                            withServiceName: [
                                "principal",
                                "principals"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Principal",
                                "Principals"
                            ]
                        }
                    },
                    [Contract.TypeNames.IPermissionManagementUser]: {
                        text: {
                            withServiceName: [
                                "user",
                                "users"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "User",
                                "Users"
                            ]
                        }
                    },
                    [Contract.TypeNames.IResource]: {
                        text: {
                            withServiceName: [
                                "resource",
                                "resources"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Resource",
                                "Resources"
                            ]
                        }
                    },
                    [Contract.TypeNames.IService]: {
                        text: {
                            withServiceName: [
                                "service",
                                "services"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Service",
                                "Services"
                            ]
                        }
                    },
                    [Contract.TypeNames.IUnmanagedKubernetesCluster]: {
                        text: {
                            withServiceName: [
                                "Self-Managed K8s cluster",
                                "Self-Managed K8s clusters"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Self-Managed K8s Cluster",
                                "Self-Managed K8s Clusters"
                            ]
                        }
                    },
                    [Contract.TypeNames.IVirtualMachine]: {
                        text: {
                            withServiceName: [
                                "virtual machine",
                                "virtual machines"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Virtual machine",
                                "Virtual machines"
                            ]
                        }
                    },
                    [Contract.TypeNames.OciAutoscalingAutoscalingConfiguration]: {
                        text: {
                            withServiceName: [
                                "autoscaling configuration",
                                "autoscaling configurations"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Autoscaling Configuration",
                                "Autoscaling Configurations"
                            ]
                        }
                    },
                    [Contract.TypeNames.OciBlockStorageBlockVolume]: {
                        text: {
                            withServiceName: [
                                "block volume",
                                "block volumes"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Block Volume",
                                "Block Volumes"
                            ]
                        }
                    },
                    [Contract.TypeNames.OciBlockStorageBootVolume]: {
                        text: {
                            withServiceName: [
                                "boot volume",
                                "boot volumes"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Boot Volume",
                                "Boot Volumes"
                            ]
                        }
                    },
                    [Contract.TypeNames.OciComputeImage]: {
                        text: {
                            withoutServiceName: [
                                "image",
                                "images"
                            ],
                            withServiceName: [
                                "compute image",
                                "compute images"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Image",
                                "Images"
                            ],
                            withServiceName: [
                                "Compute Image",
                                "Compute Images"
                            ]
                        }
                    },
                    [Contract.TypeNames.OciComputeInstance]: {
                        text: {
                            withServiceName: [
                                "compute instance",
                                "compute instances"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Compute Instance",
                                "Compute Instances"
                            ]
                        }
                    },
                    [Contract.TypeNames.OciComputeInstanceConfiguration]: {
                        text: {
                            withServiceName: [
                                "instance configuration",
                                "instance configurations"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Instance Configuration",
                                "Instance Configurations"
                            ]
                        }
                    },
                    [Contract.TypeNames.OciComputeInstancePool]: {
                        text: {
                            withServiceName: [
                                "instance pool",
                                "instance pools"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Instance Pool",
                                "Instance Pools"
                            ]
                        }
                    },
                    [Contract.TypeNames.OciContainerEngineCluster]: {
                        text: {
                            withServiceName: [
                                "OKE cluster",
                                "OKE clusters"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "OKE Cluster",
                                "OKE Clusters"
                            ]
                        }
                    },
                    [Contract.TypeNames.OciContainerEngineClusterNodePool]: {
                        text: {
                            withServiceName: [
                                "cluster node pool",
                                "cluster node pools"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Cluster Node Pool",
                                "Cluster Node Pools"
                            ]
                        }
                    },
                    [Contract.TypeNames.OciFssFileSystem]: {
                        text: {
                            withServiceName: [
                                "file system",
                                "file systems"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "File System",
                                "File Systems"
                            ]
                        }
                    },
                    [Contract.TypeNames.OciFssMountTarget]: {
                        text: {
                            withServiceName: [
                                "mount target",
                                "mount targets"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Mount Target",
                                "Mount Targets"
                            ]
                        }
                    },
                    [Contract.TypeNames.OciFssMountTargetExport]: {
                        text: {
                            withServiceName: [
                                "mount target export",
                                "mount target exports"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Mount Target Export",
                                "Mount Target Exports"
                            ]
                        }
                    },
                    [Contract.TypeNames.OciIamDomain]: {
                        text: {
                            withoutServiceName: [
                                "domain",
                                "domains"
                            ],
                            withServiceName: [
                                "domain",
                                "domains"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Domain",
                                "Domains"
                            ],
                            withServiceName: [
                                "Domain",
                                "Domains"
                            ]
                        }
                    },
                    [Contract.TypeNames.OciIamDynamicResourceGroup]: {
                        text: {
                            withoutServiceName: [
                                "dynamic group",
                                "dynamic groups"
                            ],
                            withServiceName: [
                                "dynamic group",
                                "dynamic groups"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Dynamic Group",
                                "Dynamic Groups"
                            ],
                            withServiceName: [
                                "Dynamic Group",
                                "Dynamic Groups"
                            ]
                        }
                    },
                    [Contract.TypeNames.OciIamGroup]: {
                        text: {
                            withoutServiceName: [
                                "group",
                                "groups"
                            ],
                            withServiceName: [
                                "group",
                                "groups"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Group",
                                "Groups"
                            ],
                            withServiceName: [
                                "Group",
                                "Groups"
                            ]
                        }
                    },
                    [Contract.TypeNames.OciIamPolicy]: {
                        text: {
                            withoutServiceName: [
                                "policy",
                                "policies"
                            ],
                            withServiceName: [
                                "policy",
                                "policies"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Policy",
                                "Policies"
                            ],
                            withServiceName: [
                                "Policy",
                                "Policies"
                            ]
                        }
                    },
                    [Contract.TypeNames.OciIamPrincipal]: {
                        text: {
                            withoutServiceName: [
                                "principal",
                                "principals"
                            ],
                            withServiceName: [
                                "IAM principal",
                                "IAM principals"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Principal",
                                "Principals"
                            ],
                            withServiceName: [
                                "IAM Principal",
                                "IAM Principals"
                            ]
                        }
                    },
                    [Contract.TypeNames.OciIamUser]: {
                        text: {
                            withoutServiceName: [
                                "user",
                                "users"
                            ],
                            withServiceName: [
                                "user",
                                "users"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "User",
                                "Users"
                            ],
                            withServiceName: [
                                "User",
                                "Users"
                            ]
                        }
                    },
                    [Contract.TypeNames.OciIamUserApiKey]: {
                        text: {
                            withServiceName: [
                                "user API key",
                                "user API keys"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "User API key",
                                "User API keys"
                            ]
                        }
                    },
                    [Contract.TypeNames.OciIamUserAuthToken]: {
                        text: {
                            withServiceName: [
                                "user auth token",
                                "user auth tokens"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "User Auth Token",
                                "User Auth Tokens"
                            ]
                        }
                    },
                    [Contract.TypeNames.OciIamUserDatabasePassword]: {
                        text: {
                            withServiceName: [
                                "user database password",
                                "user database passwords"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "User Database Password",
                                "User Database Passwords"
                            ]
                        }
                    },
                    [Contract.TypeNames.OciIamUserSecretKey]: {
                        text: {
                            withServiceName: [
                                "user customer secret key",
                                "user customer secret keys"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "User Customer Secret Key",
                                "User Customer Secret Keys"
                            ]
                        }
                    },
                    [Contract.TypeNames.OciKmsVault]: {
                        text: {
                            withServiceName: [
                                "vault",
                                "vaults"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Vault",
                                "Vaults"
                            ]
                        }
                    },
                    [Contract.TypeNames.OciKmsVaultKey]: {
                        text: {
                            withServiceName: [
                                "vault master encryption key",
                                "vault master encryption keys"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Vault Master Encryption Key",
                                "Vault Master Encryption Keys"
                            ]
                        }
                    },
                    [Contract.TypeNames.OciKmsVaultSecret]: {
                        text: {
                            withServiceName: [
                                "vault secret",
                                "vault secrets"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Vault Secret",
                                "Vault Secrets"
                            ]
                        }
                    },
                    [Contract.TypeNames.OciLoadBalancingLoadBalancer]: {
                        text: {
                            withServiceName: [
                                "load balancer",
                                "load balancers"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Load Balancer",
                                "Load Balancers"
                            ]
                        }
                    },
                    [Contract.TypeNames.OciLoggingLogGroup]: {
                        text: {
                            withServiceName: [
                                "log group",
                                "log groups"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Log Group",
                                "Log Groups"
                            ]
                        }
                    },
                    [Contract.TypeNames.OciNetworkingInternetGateway]: {
                        text: {
                            withServiceName: [
                                "internet gateway",
                                "internet gateways"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Internet Gateway",
                                "Internet Gateways"
                            ]
                        }
                    },
                    [Contract.TypeNames.OciNetworkingLocalPeeringGateway]: {
                        text: {
                            withServiceName: [
                                "local peering gateway",
                                "local peering gateways"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Local Peering Gateway",
                                "Local Peering Gateways"
                            ]
                        }
                    },
                    [Contract.TypeNames.OciNetworkingNatGateway]: {
                        text: {
                            withServiceName: [
                                "NAT gateway",
                                "NAT gateways"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "NAT Gateway",
                                "NAT Gateways"
                            ]
                        }
                    },
                    [Contract.TypeNames.OciNetworkingNetworkLoadBalancer]: {
                        text: {
                            withServiceName: [
                                "network load balancer",
                                "network load balancers"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Network Load Balancer",
                                "Network Load Balancers"
                            ]
                        }
                    },
                    [Contract.TypeNames.OciNetworkingNetworkSecurityGroup]: {
                        text: {
                            withServiceName: [
                                "network security group",
                                "network security groups"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Network Security Group",
                                "Network Security Groups"
                            ]
                        }
                    },
                    [Contract.TypeNames.OciNetworkingRouteTable]: {
                        text: {
                            withServiceName: [
                                "route table",
                                "route tables"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Route Table",
                                "Route Tables"
                            ]
                        }
                    },
                    [Contract.TypeNames.OciNetworkingSecurityList]: {
                        text: {
                            withServiceName: [
                                "security list",
                                "security lists"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Security List",
                                "Security Lists"
                            ]
                        }
                    },
                    [Contract.TypeNames.OciNetworkingServiceGateway]: {
                        text: {
                            withServiceName: [
                                "service gateway",
                                "service gateways"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Service Gateway",
                                "Service Gateways"
                            ]
                        }
                    },
                    [Contract.TypeNames.OciNetworkingSubnet]: {
                        text: {
                            withServiceName: [
                                "subnet",
                                "subnets"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Subnet",
                                "Subnets"
                            ]
                        }
                    },
                    [Contract.TypeNames.OciNetworkingVcn]: {
                        text: {
                            withServiceName: [
                                "virtual cloud network",
                                "Virtual cloud networks"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Virtual Cloud Network",
                                "Virtual Cloud Networks"
                            ]
                        }
                    },
                    [Contract.TypeNames.OciNetworkingVirtualNetworkInterfaceCard]: {
                        text: {
                            withServiceName: [
                                "virtual network interface card",
                                "virtual network interface cards"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Virtual Network Interface Card",
                                "Virtual Network Interface Cards"
                            ]
                        }
                    },
                    [Contract.TypeNames.OciObjectStorageBucket]: {
                        text: {
                            withoutServiceName: [
                                "bucket",
                                "buckets"
                            ],
                            withServiceName: [
                                "Object Storage bucket",
                                "Object Storage buckets"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Bucket",
                                "Buckets"
                            ],
                            withServiceName: [
                                "Object Storage Bucket",
                                "Object Storage Buckets"
                            ]
                        }
                    },
                    [Contract.TypeNames.OciResource]: {
                        text: {
                            withServiceName: [
                                "resource",
                                "resources"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Resource",
                                "Resources"
                            ]
                        }
                    },
                    [Contract.TypeNames.OciTenantEntity]: {
                        text: {
                            withServiceName: [
                                "compartment",
                                "compartments"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Compartment",
                                "Compartments"
                            ]
                        }
                    },
                    [Contract.TypeNames.OktaDirectoryGroup]: {
                        text: {
                            withoutServiceName: [
                                "group",
                                "groups"
                            ],
                            withServiceName: [
                                "Okta group",
                                "Okta groups"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Group",
                                "Groups"
                            ],
                            withServiceName: [
                                "Okta Group",
                                "Okta Groups"
                            ]
                        }
                    },
                    [Contract.TypeNames.OktaDirectoryUser]: {
                        text: {
                            withoutServiceName: [
                                "user",
                                "users"
                            ],
                            withServiceName: [
                                "Okta user",
                                "Okta users"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "User",
                                "Users"
                            ],
                            withServiceName: [
                                "Okta User",
                                "Okta Users"
                            ]
                        }
                    },
                    [Contract.TypeNames.OneLoginDirectoryGroup]: {
                        text: {
                            withoutServiceName: [
                                "group",
                                "groups"
                            ],
                            withServiceName: [
                                "OneLogin group",
                                "OneLogin groups"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Group",
                                "Groups"
                            ],
                            withServiceName: [
                                "OneLogin Group",
                                "OneLogin Groups"
                            ]
                        }
                    },
                    [Contract.TypeNames.OneLoginDirectoryRole]: {
                        text: {
                            withoutServiceName: [
                                "role",
                                "roles"
                            ],
                            withServiceName: [
                                "OneLogin role",
                                "OneLogin roles"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Role",
                                "Roles"
                            ],
                            withServiceName: [
                                "OneLogin Role",
                                "OneLogin Roles"
                            ]
                        }
                    },
                    [Contract.TypeNames.OneLoginDirectoryUser]: {
                        text: {
                            withoutServiceName: [
                                "user",
                                "users"
                            ],
                            withServiceName: [
                                "OneLogin user",
                                "OneLogin users"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "User",
                                "Users"
                            ],
                            withServiceName: [
                                "OneLogin User",
                                "OneLogin Users"
                            ]
                        }
                    },
                    [Contract.TypeNames.OpContainerImage]: {
                        text: {
                            withoutServiceName: [
                                "container image",
                                "container images"
                            ],
                            withServiceName: [
                                "container Image",
                                "container Images"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Container Image",
                                "Container Images"
                            ],
                            withServiceName: [
                                "Container Image",
                                "Container Images"
                            ]
                        }
                    },
                    [Contract.TypeNames.OpEntity]: {
                        text: {
                            withServiceName: [
                                "resource",
                                "resources"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Resource",
                                "Resources"
                            ]
                        }
                    },
                    [Contract.TypeNames.OpKubernetesClusterRole]: {
                        text: {
                            withoutServiceName: [
                                "cluster role",
                                "cluster roles"
                            ],
                            withServiceName: [
                                "K8s cluster role",
                                "K8s cluster roles"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Cluster Role",
                                "Cluster Roles"
                            ],
                            withServiceName: [
                                "K8s Cluster Role",
                                "K8s Cluster Roles"
                            ]
                        }
                    },
                    [Contract.TypeNames.OpKubernetesClusterRoleBinding]: {
                        text: {
                            withoutServiceName: [
                                "cluster role binding",
                                "cluster role bindings"
                            ],
                            withServiceName: [
                                "K8s cluster role binding",
                                "K8s cluster role bindings"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Cluster Role Binding",
                                "Cluster Role Bindings"
                            ],
                            withServiceName: [
                                "K8s Cluster Role Binding",
                                "K8s Cluster Role Bindings"
                            ]
                        }
                    },
                    [Contract.TypeNames.OpKubernetesConfigMap]: {
                        text: {
                            withoutServiceName: [
                                "config map",
                                "config maps"
                            ],
                            withServiceName: [
                                "K8s config map",
                                "K8s config maps"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Config Map",
                                "Config Maps"
                            ],
                            withServiceName: [
                                "K8s Config Map",
                                "K8s Config Maps"
                            ]
                        }
                    },
                    [Contract.TypeNames.OpKubernetesCronJob]: {
                        text: {
                            withoutServiceName: [
                                "cron job",
                                "cron jobs"
                            ],
                            withServiceName: [
                                "K8s cron job",
                                "K8s cron jobs"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Cron Job",
                                "Cron Jobs"
                            ],
                            withServiceName: [
                                "K8s Cron Job",
                                "K8s Cron Jobs"
                            ]
                        }
                    },
                    [Contract.TypeNames.OpKubernetesDaemonSet]: {
                        text: {
                            withoutServiceName: [
                                "daemon set",
                                "daemon sets"
                            ],
                            withServiceName: [
                                "K8s daemon set",
                                "K8s daemon sets"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Daemon Set",
                                "Daemon Sets"
                            ],
                            withServiceName: [
                                "K8s Daemon Set",
                                "K8s Daemon Sets"
                            ]
                        }
                    },
                    [Contract.TypeNames.OpKubernetesDeployment]: {
                        text: {
                            withoutServiceName: [
                                "deployment",
                                "deployments"
                            ],
                            withServiceName: [
                                "K8s deployment",
                                "K8s deployments"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Deployment",
                                "Deployments"
                            ],
                            withServiceName: [
                                "K8s Deployment",
                                "K8s Deployments"
                            ]
                        }
                    },
                    [Contract.TypeNames.OpKubernetesGroup]: {
                        text: {
                            withoutServiceName: [
                                "group",
                                "groups"
                            ],
                            withServiceName: [
                                "K8s group",
                                "K8s groups"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Group",
                                "Groups"
                            ],
                            withServiceName: [
                                "K8s Group",
                                "K8s Groups"
                            ]
                        }
                    },
                    [Contract.TypeNames.OpKubernetesIngress]: {
                        text: {
                            withoutServiceName: [
                                "ingress",
                                "ingresses"
                            ],
                            withServiceName: [
                                "K8s ingress",
                                "K8s ingresses"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Ingress",
                                "Ingresses"
                            ],
                            withServiceName: [
                                "K8s Ingress",
                                "K8s Ingresses"
                            ]
                        }
                    },
                    [Contract.TypeNames.OpKubernetesIngressClass]: {
                        text: {
                            withoutServiceName: [
                                "ingress class",
                                "ingress classes"
                            ],
                            withServiceName: [
                                "K8s ingress class",
                                "K8s ingress classes"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Ingress class",
                                "Ingress classes"
                            ],
                            withServiceName: [
                                "K8s Ingress class",
                                "K8s Ingress classes"
                            ]
                        }
                    },
                    [Contract.TypeNames.OpKubernetesJob]: {
                        text: {
                            withoutServiceName: [
                                "job",
                                "jobs"
                            ],
                            withServiceName: [
                                "K8s job",
                                "K8s jobs"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Job",
                                "Jobs"
                            ],
                            withServiceName: [
                                "K8s Job",
                                "K8s Jobs"
                            ]
                        }
                    },
                    [Contract.TypeNames.OpKubernetesNamespace]: {
                        text: {
                            withoutServiceName: [
                                "namespace",
                                "namespaces"
                            ],
                            withServiceName: [
                                "K8s namespace",
                                "K8s namespaces"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Namespace",
                                "Namespaces"
                            ],
                            withServiceName: [
                                "K8s Namespace",
                                "K8s Namespaces"
                            ]
                        }
                    },
                    [Contract.TypeNames.OpKubernetesNamespaceRole]: {
                        text: {
                            withoutServiceName: [
                                "role",
                                "roles"
                            ],
                            withServiceName: [
                                "K8s role",
                                "K8s roles"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Role",
                                "Roles"
                            ],
                            withServiceName: [
                                "K8s Role",
                                "K8s Roles"
                            ]
                        }
                    },
                    [Contract.TypeNames.OpKubernetesNamespaceRoleBinding]: {
                        text: {
                            withoutServiceName: [
                                "role binding",
                                "role bindings"
                            ],
                            withServiceName: [
                                "K8s role binding",
                                "K8s role bindings"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Role Binding",
                                "Role Bindings"
                            ],
                            withServiceName: [
                                "K8s Role Binding",
                                "K8s Role Bindings"
                            ]
                        }
                    },
                    [Contract.TypeNames.OpKubernetesNetworkPolicy]: {
                        text: {
                            withoutServiceName: [
                                "network policy",
                                "network policies"
                            ],
                            withServiceName: [
                                "K8s network policy",
                                "K8s network policies"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Network Policy",
                                "Network Policies"
                            ],
                            withServiceName: [
                                "K8s Network Policy",
                                "K8s Network Policies"
                            ]
                        }
                    },
                    [Contract.TypeNames.OpKubernetesNode]: {
                        text: {
                            withoutServiceName: [
                                "node",
                                "nodes"
                            ],
                            withServiceName: [
                                "K8s node",
                                "K8s nodes"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Node",
                                "Nodes"
                            ],
                            withServiceName: [
                                "K8s Node",
                                "K8s Nodes"
                            ]
                        }
                    },
                    [Contract.TypeNames.OpKubernetesPersistentVolume]: {
                        text: {
                            withoutServiceName: [
                                "persistent volume",
                                "persistent volumes"
                            ],
                            withServiceName: [
                                "K8s persistent volume",
                                "K8s persistent volumes"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Persistent Volume",
                                "Persistent Volumes"
                            ],
                            withServiceName: [
                                "K8s Persistent Volume",
                                "K8s Persistent Volumes"
                            ]
                        }
                    },
                    [Contract.TypeNames.OpKubernetesPod]: {
                        text: {
                            withoutServiceName: [
                                "pod",
                                "pods"
                            ],
                            withServiceName: [
                                "K8s pod",
                                "K8s pod"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Pod",
                                "Pods"
                            ],
                            withServiceName: [
                                "K8s Pod",
                                "K8s Pod"
                            ]
                        }
                    },
                    [Contract.TypeNames.OpKubernetesPodSecurityPolicy]: {
                        text: {
                            withoutServiceName: [
                                "pod security policy",
                                "pod security policies"
                            ],
                            withServiceName: [
                                "K8s pod security policy",
                                "K8s pod security policies"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Pod Security Policy",
                                "Pod Security Policies"
                            ],
                            withServiceName: [
                                "K8s Pod Security Policy",
                                "K8s Pod Security Policies"
                            ]
                        }
                    },
                    [Contract.TypeNames.OpKubernetesPodTemplate]: {
                        text: {
                            withoutServiceName: [
                                "pod template",
                                "pod templates"
                            ],
                            withServiceName: [
                                "K8s pod template",
                                "K8s pod templates"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Pod Template",
                                "Pod Templates"
                            ],
                            withServiceName: [
                                "K8s Pod Template",
                                "K8s Pod Templates"
                            ]
                        }
                    },
                    [Contract.TypeNames.OpKubernetesReplicaSet]: {
                        text: {
                            withoutServiceName: [
                                "replica set",
                                "replica sets"
                            ],
                            withServiceName: [
                                "K8s replica set",
                                "K8s replica sets"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Replica Set",
                                "Replica Sets"
                            ],
                            withServiceName: [
                                "K8s Replica Set",
                                "K8s Replica Sets"
                            ]
                        }
                    },
                    [Contract.TypeNames.OpKubernetesResource]: {
                        text: {
                            withoutServiceName: [
                                "resource",
                                "resources"
                            ],
                            withServiceName: [
                                "K8s resource",
                                "K8s resources"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Resource",
                                "Resources"
                            ],
                            withServiceName: [
                                "K8s Resource",
                                "K8s Resources"
                            ]
                        }
                    },
                    [Contract.TypeNames.OpKubernetesService]: {
                        text: {
                            withoutServiceName: [
                                "service",
                                "services"
                            ],
                            withServiceName: [
                                "K8s service",
                                "K8s services"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Service",
                                "Services"
                            ],
                            withServiceName: [
                                "K8s Service",
                                "K8s Services"
                            ]
                        }
                    },
                    [Contract.TypeNames.OpKubernetesServiceAccount]: {
                        text: {
                            withoutServiceName: [
                                "service account",
                                "service accounts"
                            ],
                            withServiceName: [
                                "K8s service account",
                                "K8s service accounts"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Service Account",
                                "Service Accounts"
                            ],
                            withServiceName: [
                                "K8s Service Account",
                                "K8s Service Accounts"
                            ]
                        }
                    },
                    [Contract.TypeNames.OpKubernetesStatefulSet]: {
                        text: {
                            withoutServiceName: [
                                "stateful set",
                                "stateful sets"
                            ],
                            withServiceName: [
                                "K8s stateful set",
                                "K8s stateful sets"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Stateful Set",
                                "Stateful Sets"
                            ],
                            withServiceName: [
                                "K8s Stateful Set",
                                "K8s Stateful Sets"
                            ]
                        }
                    },
                    [Contract.TypeNames.OpKubernetesUser]: {
                        text: {
                            withoutServiceName: [
                                "user",
                                "users"
                            ],
                            withServiceName: [
                                "K8s user",
                                "K8s users"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "User",
                                "Users"
                            ],
                            withServiceName: [
                                "K8s User",
                                "K8s Users"
                            ]
                        }
                    },
                    [Contract.TypeNames.OpKubernetesWorkloadResourceBase]: {
                        text: {
                            withoutServiceName: [
                                "workload",
                                "workloads"
                            ],
                            withServiceName: [
                                "K8s workload",
                                "K8s workloads"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Workload",
                                "Workloads"
                            ],
                            withServiceName: [
                                "K8s Workload",
                                "K8s Workloads"
                            ]
                        }
                    },
                    [Contract.TypeNames.OpOpenShiftBuildConfig]: {
                        text: {
                            withoutServiceName: [
                                "build config",
                                "build configs"
                            ],
                            withServiceName: [
                                "OpenShift build config",
                                "OpenShift build configs"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Build Config",
                                "Build Configs"
                            ],
                            withServiceName: [
                                "OpenShift Build Config",
                                "OpenShift Build Configs"
                            ]
                        }
                    },
                    [Contract.TypeNames.OpOpenShiftCatalogSource]: {
                        text: {
                            withoutServiceName: [
                                "catalog source",
                                "catalog sources"
                            ],
                            withServiceName: [
                                "OpenShift catalog source",
                                "OpenShift catalog sources"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Catalog Source",
                                "Catalog Sources"
                            ],
                            withServiceName: [
                                "OpenShift Catalog Source",
                                "OpenShift Catalog Sources"
                            ]
                        }
                    },
                    [Contract.TypeNames.OpOpenShiftDeploymentConfig]: {
                        text: {
                            withoutServiceName: [
                                "deployment config",
                                "deployment configs"
                            ],
                            withServiceName: [
                                "OpenShift deployment config",
                                "OpenShift deployment configs"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Deployment Config",
                                "Deployment Configs"
                            ],
                            withServiceName: [
                                "OpenShift Deployment Config",
                                "OpenShift Deployment Configs"
                            ]
                        }
                    },
                    [Contract.TypeNames.OpOpenShiftImageStream]: {
                        text: {
                            withoutServiceName: [
                                "image stream",
                                "image streams"
                            ],
                            withServiceName: [
                                "OpenShift image stream",
                                "OpenShift image streams"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Image Stream",
                                "Image Streams"
                            ],
                            withServiceName: [
                                "OpenShift Image Stream",
                                "OpenShift Image Streams"
                            ]
                        }
                    },
                    [Contract.TypeNames.OpOpenShiftProject]: {
                        text: {
                            withoutServiceName: [
                                "project",
                                "projects"
                            ],
                            withServiceName: [
                                "OpenShift project",
                                "OpenShift projects"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Project",
                                "Projects"
                            ],
                            withServiceName: [
                                "OpenShift Project",
                                "OpenShift Projects"
                            ]
                        }
                    },
                    [Contract.TypeNames.OpOpenShiftRoute]: {
                        text: {
                            withoutServiceName: [
                                "route",
                                "routes"
                            ],
                            withServiceName: [
                                "OpenShift route",
                                "OpenShift routes"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Route",
                                "Routes"
                            ],
                            withServiceName: [
                                "OpenShift Route",
                                "OpenShift Routes"
                            ]
                        }
                    },
                    [Contract.TypeNames.OpOpenShiftSecurityContextConstraint]: {
                        text: {
                            withoutServiceName: [
                                "security context constraint",
                                "security context constraints"
                            ],
                            withServiceName: [
                                "OpenShift security context constraint",
                                "OpenShift security context constraints"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Security Context Constraint",
                                "Security Context Constraints"
                            ],
                            withServiceName: [
                                "OpenShift Security Context Constraint",
                                "OpenShift Security Context Constraints"
                            ]
                        }
                    },
                    [Contract.TypeNames.OpOpenShiftTemplate]: {
                        text: {
                            withoutServiceName: [
                                "template",
                                "templates"
                            ],
                            withServiceName: [
                                "OpenShift template",
                                "OpenShift templates"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Template",
                                "Templates"
                            ],
                            withServiceName: [
                                "OpenShift Template",
                                "OpenShift Templates"
                            ]
                        }
                    },
                    [Contract.TypeNames.OpVirtualMachine]: {
                        text: {
                            withoutServiceName: [
                                "virtual machine",
                                "virtual machines"
                            ],
                            withServiceName: [
                                "Virtual Machine",
                                "Virtual Machines"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Virtual Machine",
                                "Virtual Machines"
                            ],
                            withServiceName: [
                                "Virtual Machine",
                                "Virtual Machines"
                            ]
                        }
                    },
                    [Contract.TypeNames.OpUnmanagedKubernetesCluster]: {
                        text: {
                            withoutServiceName: [
                                "Self-Managed cluster",
                                "Self-Managed clusters"
                            ],
                            withServiceName: [
                                "Self-Managed K8s cluster",
                                "Self-Managed K8s clusters"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Self-Managed Cluster",
                                "Self-Managed Clusters"
                            ],
                            withServiceName: [
                                "Self-Managed K8s Cluster",
                                "Self-Managed K8s Clusters"
                            ]
                        }
                    },
                    [Contract.TypeNames.PartialAnonymousIdentity]: {
                        text: {
                            withServiceName: [
                                "anonymous user",
                                "anonymous users"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Anonymous User",
                                "Anonymous Users"
                            ]
                        }
                    },
                    [Contract.TypeNames.PartialPrincipal]: {
                        text: {
                            withServiceName: [
                                "unknown principal",
                                "unknown principals"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Unknown Principal",
                                "Unknown Principals"
                            ]
                        }
                    },
                    [Contract.TypeNames.PartialUser]: {
                        text: {
                            withServiceName: [
                                "user",
                                "users"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "User",
                                "Users"
                            ]
                        }
                    },
                    [Contract.TypeNames.PingIdentityDirectoryGroup]: {
                        text: {
                            withoutServiceName: [
                                "group",
                                "groups"
                            ],
                            withServiceName: [
                                "Ping Identity group",
                                "Ping Identity groups"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Group",
                                "Groups"
                            ],
                            withServiceName: [
                                "Ping Identity Group",
                                "Ping Identity Groups"
                            ]
                        }
                    },
                    [Contract.TypeNames.PingIdentityDirectoryPrincipal]: {
                        text: {
                            withoutServiceName: [
                                "principal",
                                "principals"
                            ],
                            withServiceName: [
                                "Ping Identity principal",
                                "Ping Identity principals"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "Principal",
                                "Principals"
                            ],
                            withServiceName: [
                                "Ping Identity Principal",
                                "Ping Identity Principals"
                            ]
                        }
                    },
                    [Contract.TypeNames.PingIdentityDirectoryUser]: {
                        text: {
                            withoutServiceName: [
                                "user",
                                "users"
                            ],
                            withServiceName: [
                                "Ping Identity user",
                                "Ping Identity users"
                            ]
                        },
                        title: {
                            withoutServiceName: [
                                "User",
                                "Users"
                            ],
                            withServiceName: [
                                "Ping Identity User",
                                "Ping Identity Users"
                            ]
                        }
                    },
                    [Contract.TypeNames.UnknownEntity]: {
                        text: {
                            withServiceName: [
                                "resource",
                                "resources"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Resource",
                                "Resources"
                            ]
                        }
                    }
                }
            }));

    const azureResourcesGenericResourceTypeNameLocalization = useAzureResourcesGenericResourceTypeNameLocalization();
    const gcpResourcesGenericResourceTypeNameLocalization = useGcpAssetGenericResourceTypeNameLocalization();
    const ociResourceSearchGenericResourceTypeNameLocalization = useOciResourceSearchGenericResourceTypeNameLocalization();
    return useCallback(
        (typeName: string, options?: EntityTypeNameTranslatorOptions): string => {
            options = {
                count: 1,
                includeCount: false,
                includeServiceName: true,
                variant: "title",
                ...options
            };

            let translatedTypeName: string;

            if (_.isNil(options?.translate)) {
                let variantLocalization;
                if (
                    typeName !== Contract.TypeNames.AzureResourcesGenericResource &&
                    TypeHelper.extendOrImplement(typeName, Contract.TypeNames.AzureResourcesGenericResource)) {
                    variantLocalization = (azureResourcesGenericResourceTypeNameLocalization.typeNames as any)[typeName][options.variant!];
                } else if (
                    typeName !== Contract.TypeNames.GcpAssetGenericResource &&
                    TypeHelper.extendOrImplement(typeName, Contract.TypeNames.GcpAssetGenericResource)) {
                    variantLocalization = (gcpResourcesGenericResourceTypeNameLocalization.typeNames as any)[typeName][options.variant!];
                } else if (
                    typeName !== Contract.TypeNames.OciResourceSearchGenericResource &&
                    TypeHelper.extendOrImplement(typeName, Contract.TypeNames.OciResourceSearchGenericResource)) {
                    variantLocalization = (ociResourceSearchGenericResourceTypeNameLocalization.typeNames as any)[typeName][options.variant!];
                } else {
                    variantLocalization = (localization.typeNames as any)[typeName][options.variant!];
                }

                translatedTypeName =
                    options.includeServiceName || _.isNil(variantLocalization.withoutServiceName)
                        ? variantLocalization.translate("withServiceName", { count: options.count })
                        : variantLocalization.translate("withoutServiceName", { count: options.count });
            } else {
                translatedTypeName = options.translate(options.count!);
            }

            return options.includeCount
                ? localization.countAndTypeName({
                    entityCount: options.count,
                    translatedTypeName
                })
                : translatedTypeName;
        },
        [azureResourcesGenericResourceTypeNameLocalization, gcpResourcesGenericResourceTypeNameLocalization, localization, ociResourceSearchGenericResourceTypeNameLocalization]);
}

export type EntityTypeNameTranslatorOptions = {
    count?: number;
    includeCount?: boolean;
    includeServiceName?: boolean;
    translate?: (count: number) => string;
    variant?: "text" | "title";
};