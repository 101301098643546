import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, EmptyMessageText, map, optionalTableCell, StringHelper, TimeFormatter, TimeHelper, TimeRangeFilter, useFormatRelativeTime, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, CustomerConsoleAppUrlHelper, EntitiesCell, Entity, EntityFilter, entityModelStore, EntityTypeMetadataModelHelper, ItemTable, SeverityFilter, tenantModelStore, TimeRangeHelper, useEntityTypeNameTranslator, useTenantNameTranslator, useTenantTypeTranslator } from "../../../../../../../common";
import { VulnerabilitiesChartCell } from "../../../../WorkloadAnalysis";
import { useGetWorkloadResourceVulnerabilitiesCsvItem } from "../../../../WorkloadAnalysis/hooks";

type ContainerImagesProps = {
    entityModel: Contract.EntityModel;
};

export function ContainerImages({ entityModel }: ContainerImagesProps) {
    const containerImageModels = _.as<Contract.IContainerImageModel[]>(entityModelStore.useGet(_.as<Contract.IContainerImageRepository>(entityModel.entity).containerImageIds));
    const relatedEntityModels =
        entityModelStore.useGet(
            _.flatMap(
                containerImageModels,
                containerImageModel =>
                    _([] as string[]).
                        concat(containerImageModel.kubernetesWorkloadResourceIds).
                        concat(containerImageModel.virtualMachineIds).
                        concat(containerImageModel.workloadClusterResourceIds).
                        value()));

    const relatedEntityModelMap =
        _.keyBy(
            relatedEntityModels,
            relatedEntityModel => relatedEntityModel.id);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const tenantNameTranslator = useTenantNameTranslator();
    const tenantTypeTranslator = useTenantTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.containerImages",
            () => ({
                columns: {
                    creationTime: "Date Added",
                    kubernetesWorkloadResourceIds: "K8s Workloads",
                    repositoryIds: "Repositories",
                    scanTime: "Scan Time",
                    virtualMachineIds: "Virtual Machines",
                    vulnerabilities: "Vulnerabilities",
                    vulnerabilitySeverities: "Vulnerability Severity",
                    workloadClusterResourceIds: "Clusters"
                },
                empty: {
                    withFilter: "No Matching {{translatedEntityTypeName}}",
                    withoutFilter: "No {{translatedEntityTypeName}}"
                }
            }));

    const tenantModels = tenantModelStore.useGetAll();
    const [entityTypeName, tenantModelMap, translatedEntityTypeName] =
        useMemo(
            () => {
                const entityTypeName =
                    map(
                        entityModel.entity.typeName,
                        {
                            [Contract.TypeNames.AwsEcrRepository]: () => Contract.TypeNames.AwsContainerImage,
                            [Contract.TypeNames.AzureContainerRegistryRepository]: () => Contract.TypeNames.AzureContainerImage,
                            [Contract.TypeNames.CiContainerImageRepository]: () => Contract.TypeNames.CiContainerImage,
                            [Contract.TypeNames.GcpArtifactContainerImageRepository]: () => Contract.TypeNames.GcpContainerImage
                        });

                const tenantModelMap =
                    _.keyBy(
                        tenantModels,
                        tenantModel => tenantModel.configuration.id);

                const translatedEntityTypeName =
                    entityTypeNameTranslator(
                        entityTypeName,
                        {
                            count: 0,
                            includeServiceName: true
                        });

                return [entityTypeName, tenantModelMap, translatedEntityTypeName];
            },
            [entityModel, tenantModels]);

    const formatRelativeTime = useFormatRelativeTime();
    const getWorkloadAnalysisVulnerabilitiesCsvItem = useGetWorkloadResourceVulnerabilitiesCsvItem();
    return (
        <ItemTable
            columnIdToDefaultSortDirectionMap={{
                [ContainerImageTableColumnId.CreationTime]: "desc"
            }}
            columnIdToGetItemValueMap={{
                [ContainerImageTableColumnId.CreationTime]: {
                    getFilterValue: item => _.as<Contract.IContainerImage>(item.entity).data.creationTime,
                    getSortValue: item => TimeHelper.getSortable(_.as<Contract.IContainerImage>(item.entity).data.creationTime)
                },
                [ContainerImageTableColumnId.ContainerImageId]: {
                    getFilterValue: item => item.id,
                    getSortValue: item => item.entity.displayName
                },
                [ContainerImageTableColumnId.KubernetesWorkloadResourceIds]: {
                    getFilterValue: item => item.kubernetesWorkloadResourceIds,
                    getSortValue:
                        item =>
                            StringHelper.getCombineSortValue(
                                _.size(item.kubernetesWorkloadResourceIds),
                                ..._.map(
                                    item.kubernetesWorkloadResourceIds,
                                    kubernetesWorkloadResourceId => relatedEntityModelMap[kubernetesWorkloadResourceId].entity.displayName))
                },
                [ContainerImageTableColumnId.ScanTime]: {
                    getFilterValue: item => _.as<Contract.IContainerImage>(item.entity).data.scanTime,
                    getSortValue: item => TimeHelper.getSortable(_.as<Contract.IContainerImage>(item.entity).data.scanTime)
                },
                [ContainerImageTableColumnId.VirtualMachineIds]: {
                    getFilterValue: item => item.virtualMachineIds,
                    getSortValue:
                        item =>
                            StringHelper.getCombineSortValue(
                                _.size(item.virtualMachineIds),
                                ..._.map(
                                    item.virtualMachineIds,
                                    virtualMachineId => relatedEntityModelMap[virtualMachineId].entity.displayName))
                },
                [ContainerImageTableColumnId.Vulnerabilities]: {
                    getFilterValue:
                        item =>
                            _.map(
                                _.as<Contract.IContainerImage>(item.entity).data.vulnerabilities,
                                vulnerability => vulnerability.rawId),
                    getSortValue: item => item.vulnerabilitySeveritiesSortValue
                },
                [ContainerImageTableColumnId.VulnerabilitySeverities]: {
                    getFilterValue:
                        item =>
                            _(_.as<Contract.IContainerImage>(item.entity).data.vulnerabilities).
                                map(vulnerability => vulnerability.severity).
                                uniq().
                                value(),
                    getSortValue: _ => {
                    }
                },
                [ContainerImageTableColumnId.WorkloadClusterResourceIds]: {
                    getFilterValue: item => item.workloadClusterResourceIds,
                    getSortValue:
                        item =>
                            StringHelper.getCombineSortValue(
                                _.size(item.workloadClusterResourceIds),
                                ..._.map(
                                    item.workloadClusterResourceIds,
                                    workloadClusterResourceId => relatedEntityModelMap[workloadClusterResourceId].entity.displayName))
                }
            }}
            csvExportFilePrefixes={[
                _.replace(translatedEntityTypeName, /\s/g, "")
            ]}
            defaultSortColumnIdOrIds={[ContainerImageTableColumnId.CreationTime]}
            emptyMessageOptions={{
                emptyMessageText:
                    new EmptyMessageText(
                        localization.empty.withoutFilter({ translatedEntityTypeName }),
                        localization.empty.withFilter({ translatedEntityTypeName }))
            }}
            getCsvItem={
                item => ({
                    /* eslint-disable sort-keys-fix/sort-keys-fix */
                    Cloud: tenantTypeTranslator(EntityTypeMetadataModelHelper.get(entityTypeName).tenantType),
                    "Account ID": tenantModelMap[item.tenantId]?.configuration.displayReference ?? "",
                    "Account Name": tenantNameTranslator(item.tenantId),
                    Type: entityTypeNameTranslator(item.entity.typeName),
                    Id: item.entity.displayReference,
                    Name: item.entity.displayName,
                    "Date Added":
                        _.isNil(_.as<Contract.IContainerImage>(item.entity).data.creationTime)
                            ? ""
                            : TimeFormatter.workloadResourceDateTime(_.as<Contract.IContainerImage>(item.entity).data.creationTime),
                    Clusters:
                        _(item.workloadClusterResourceIds).
                            map(workloadClusterResourceId => relatedEntityModelMap[workloadClusterResourceId].entity.displayReference).
                            join("\n"),
                    "Virtual Machines":
                        _(item.virtualMachineIds).
                            map(virtualMachineId => relatedEntityModelMap[virtualMachineId].entity.displayReference).
                            join("\n"),
                    "K8s Workloads":
                        _(item.kubernetesWorkloadResourceIds).
                            map(kubernetesWorkloadResourceId => relatedEntityModelMap[kubernetesWorkloadResourceId].entity.displayReference).
                            join("\n"),
                    Vulnerabilities: getWorkloadAnalysisVulnerabilitiesCsvItem(_.as<Contract.IContainerImage>(item.entity).data.vulnerabilities),
                    "Scan Time":
                        _.isNil(_.as<Contract.IContainerImage>(item.entity).data.scanTime)
                            ? ""
                            : TimeFormatter.workloadResourceDateTime(_.as<Contract.IContainerImage>(item.entity).data.scanTime),
                    URL: CustomerConsoleAppUrlHelper.getEntityProfileUrl(_.as<Contract.EntityModel>(item)),
                    "Console URL": _.as<Contract.EntityModel>(item).consoleUrl
                    /* eslint-enable sort-keys-fix/sort-keys-fix */
                })}
            getItemId={(item: Contract.IContainerImageModel) => item.id}
            items={containerImageModels}>
            {columnIdToItemValuesMap => [
                <DataTableColumn
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <EntityFilter
                                    entityIdsOrSearchableReferences={columnIdToItemValuesMap[ContainerImageTableColumnId.ContainerImageId]}
                                    placeholder={translatedEntityTypeName}/>
                        }
                    }}
                    id={ContainerImageTableColumnId.ContainerImageId}
                    key={ContainerImageTableColumnId.ContainerImageId}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.EntityModel>) =>
                            <Entity
                                entityIdOrModel={item}
                                variant="link">
                                {_.first(_.as<Contract.IContainerImage>(item.entity).data.tags) ?? _.as<Contract.IContainerImage>(item.entity).data.digest}
                            </Entity>}
                    selectorOptions={{ disabled: true }}
                    title={translatedEntityTypeName}/>,
                <DataTableColumn
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <TimeRangeFilter
                                    placeholder={localization.columns.creationTime()}
                                    timeRange={TimeRangeHelper.getTimesFilterRange(columnIdToItemValuesMap[ContainerImageTableColumnId.CreationTime])}/>
                        }
                    }}
                    id={ContainerImageTableColumnId.CreationTime}
                    key={ContainerImageTableColumnId.CreationTime}
                    render={
                        optionalTableCell<Contract.IContainerImageModel>(
                            item =>
                                _.isNil(_.as<Contract.IContainerImage>(item.entity).data.creationTime)
                                    ? undefined
                                    : formatRelativeTime(_.as<Contract.IContainerImage>(item.entity).data.creationTime))}
                    sortOptions={{ type: DataTableSortType.Date }}
                    title={localization.columns.creationTime()}/>,
                <DataTableColumn
                    cellMinWidth={180}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <ValuesFilter placeholder={localization.columns.vulnerabilities()}>
                                    {_.map(
                                        columnIdToItemValuesMap[ContainerImageTableColumnId.Vulnerabilities],
                                        vulnerability =>
                                            <ValuesFilterItem
                                                key={vulnerability}
                                                value={vulnerability}/>)}
                                </ValuesFilter>
                        }
                    }}
                    id={ContainerImageTableColumnId.Vulnerabilities}
                    key={ContainerImageTableColumnId.Vulnerabilities}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.IContainerImageModel>) =>
                            <VulnerabilitiesChartCell
                                vulnerabilities={_.as<Contract.IContainerImage>(item.entity).data.vulnerabilities}
                                workloadResourceEntityId={item.id}/>}
                    sortOptions={{ type: DataTableSortType.Numeric }}
                    title={localization.columns.vulnerabilities()}/>,
                <DataTableColumn
                    filterOptions={{
                        itemOrItems: {
                            element: <SeverityFilter placeholder={localization.columns.vulnerabilitySeverities()}/>
                        }
                    }}
                    id={ContainerImageTableColumnId.VulnerabilitySeverities}
                    key={ContainerImageTableColumnId.VulnerabilitySeverities}
                    sortOptions={{ type: DataTableSortType.Numeric }}
                    title={localization.columns.vulnerabilitySeverities()}/>,
                <DataTableColumn
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <EntityFilter
                                    emptyValue={true}
                                    entityIdsOrSearchableReferences={columnIdToItemValuesMap[ContainerImageTableColumnId.WorkloadClusterResourceIds]}
                                    placeholder={localization.columns.workloadClusterResourceIds()}/>
                        }
                    }}
                    id={ContainerImageTableColumnId.WorkloadClusterResourceIds}
                    key={ContainerImageTableColumnId.WorkloadClusterResourceIds}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.IContainerImageModel>) =>
                            <EntitiesCell
                                entityIdsOrModels={item.workloadClusterResourceIds}
                                entityTypeName={Contract.TypeNames.Entity}
                                entityVariant="iconTextTypeTenant"/>}
                    title={localization.columns.workloadClusterResourceIds()}/>,
                <DataTableColumn
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <EntityFilter
                                    emptyValue={true}
                                    entityIdsOrSearchableReferences={columnIdToItemValuesMap[ContainerImageTableColumnId.VirtualMachineIds]}
                                    placeholder={localization.columns.virtualMachineIds()}/>
                        }
                    }}
                    id={ContainerImageTableColumnId.VirtualMachineIds}
                    key={ContainerImageTableColumnId.VirtualMachineIds}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.IContainerImageModel>) =>
                            <EntitiesCell
                                entityIdsOrModels={item.virtualMachineIds}
                                entityTypeName={Contract.TypeNames.IVirtualMachine}
                                entityVariant="iconTextTypeTenant"/>}
                    title={localization.columns.virtualMachineIds()}/>,
                <DataTableColumn
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <EntityFilter
                                    emptyValue={true}
                                    entityIdsOrSearchableReferences={columnIdToItemValuesMap[ContainerImageTableColumnId.KubernetesWorkloadResourceIds]}
                                    placeholder={localization.columns.kubernetesWorkloadResourceIds()}/>
                        }
                    }}
                    id={ContainerImageTableColumnId.KubernetesWorkloadResourceIds}
                    key={ContainerImageTableColumnId.KubernetesWorkloadResourceIds}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.IContainerImageModel>) =>
                            <EntitiesCell
                                entityIdsOrModels={item.kubernetesWorkloadResourceIds}
                                entityTypeName={Contract.TypeNames.IKubernetesWorkloadResource}
                                entityVariant="iconTextTypeTenant"/>}
                    title={localization.columns.kubernetesWorkloadResourceIds()}/>,
                <DataTableColumn
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <TimeRangeFilter
                                    placeholder={localization.columns.scanTime()}
                                    timeRange={TimeRangeHelper.getTimesFilterRange(columnIdToItemValuesMap[ContainerImageTableColumnId.ScanTime])}/>
                        }
                    }}
                    id={ContainerImageTableColumnId.ScanTime}
                    key={ContainerImageTableColumnId.ScanTime}
                    render={
                        optionalTableCell<Contract.IContainerImageModel>(
                            item =>
                                _.isNil(_.as<Contract.IContainerImage>(item.entity).data.scanTime)
                                    ? undefined
                                    : formatRelativeTime(_.as<Contract.IContainerImage>(item.entity).data.scanTime))}
                    sortOptions={{ type: DataTableSortType.Date }}
                    title={localization.columns.scanTime()}/>
            ]}
        </ItemTable>);
}

enum ContainerImageTableColumnId {
    ContainerImageId = "containerImageId",
    CreationTime = "creationTime",
    KubernetesWorkloadResourceIds = "kubernetesWorkloadResourceIds",
    ScanTime = "scanTime",
    VirtualMachineIds = "virtualMachineIds",
    Vulnerabilities = "vulnerabilities",
    VulnerabilitySeverities = "vulnerabilitySeverities",
    WorkloadClusterResourceIds = "workloadClusterResourceIds"
}