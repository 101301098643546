import { DataTable, DataTableColumn, DataTableColumnRenderProps, EmptyMessageText, useLocalization } from "@infrastructure";
import React from "react";
import { Contract, Entity, entityModelStore, InfoCard } from "../../../../../../../../../../../../common";
import { useAzureEventHubNamespaceEventHubStatusTranslator } from "../../../../../../../../hooks/useAzureEventHubNamespaceEventHubStatusTranslator";

type EventHubsInfoCardProps = {
    eventHubsIds: string[];
};

export function EventHubsInfoCard({ eventHubsIds }: EventHubsInfoCardProps) {
    const eventHubModels = entityModelStore.useGet(eventHubsIds);

    const eventHubNamespaceEventHubStatusTranslator = useAzureEventHubNamespaceEventHubStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.azure.useAzureEventHubNamespaceDefinition.eventHubsInfoCard",
            () => ({
                columns: {
                    eventHub: "Event Hub",
                    status: "Status"
                },
                empty: "No Event Hubs",
                title: "Event Hubs"
            }));

    return (
        <InfoCard title={localization.title()}>
            <DataTable
                emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty()) }}
                fetchItems={() => eventHubModels}
                getItemId={(item: Contract.AzureResourceGroupResourceModel) => item.id}
                sortOptions={{ enabled: false }}
                variant="card">
                <DataTableColumn
                    id={EventHubsInfoCardTableColumnId.EventHub}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.AzureResourceGroupResourceModel>) =>
                            <Entity
                                entityIdOrModel={item}
                                variant="iconTextTenant"/>}
                    title={localization.columns.eventHub()}/>
                <DataTableColumn
                    id={EventHubsInfoCardTableColumnId.Status}
                    itemProperty={(item: Contract.AzureEventHubNamespaceEventHubModel) => (eventHubNamespaceEventHubStatusTranslator((item.entity as Contract.AzureEventHubNamespaceEventHub).status))}
                    title={localization.columns.status()}/>
            </DataTable>
        </InfoCard>);
}

enum EventHubsInfoCardTableColumnId {
    EventHub = "eventHub",
    Status = "status"
}