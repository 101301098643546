import { CircularProgress, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useState } from "react";
import { ActionMenuItem, DataTableColumnRenderProps, DeleteIcon, Link, Menu, MenuItem, Message, useDataTableActionsContext, useLocalization } from "@infrastructure";
import { Contract, CustomerConsoleAppUrlHelper, Entity, KubernetesController, ScopeHelper, TypeHelper, UserHelper, useScopeNavigationViewContext, useTheme } from "../../../../../../../../common";
import { useSetSystemKubernetesClustersContext } from "../../SystemKubernetesClusters";
import { SystemKubernetesClusterHelmStatus, SystemKubernetesClusterHelper } from "../../utilities";
import { ConnectIcon } from "./icons";

export function ActionsCell({ item: systemKubernetesClusterModel }: DataTableColumnRenderProps<Contract.SystemKubernetesClusterModel>) {
    const { actions } = useDataTableActionsContext();
    const { scopeNodeModel } = useScopeNavigationViewContext();
    const setSystemKubernetesClustersContext = useSetSystemKubernetesClustersContext();

    const [deleteClusterExecuting, setDeleteClusterExecuting] = useState(false);
    const [deleteClusterError, setDeleteClusterError] = useState(false);

    async function deleteCluster() {
        setDeleteClusterExecuting(true);
        setDeleteClusterError(false);

        try {
            await KubernetesController.deleteSystemKubernetesCluster(
                new Contract.KubernetesControllerDeleteSystemKubernetesClusterRequest(
                    systemKubernetesClusterModel.id));

            await actions.reload();
        } catch {
            setDeleteClusterError(true);
        }

        setDeleteClusterExecuting(false);
    }

    const localization =
        useLocalization(
            "views.customer.kubernetes.systemKubernetesClusters.actionsCell",
            () => ({
                actions: {
                    addOrEdit: {
                        title: {
                            [SystemKubernetesClusterHelmStatus.Installed]: "Upgrade Helm",
                            [SystemKubernetesClusterHelmStatus.NotInstalled]: "Install Helm",
                            [SystemKubernetesClusterHelmStatus.Outdated]: "Upgrade Helm"
                        }
                    },
                    delete: {
                        confirm: {
                            description: {
                                connectedText: "It is highly recommended to uninstall a connected cluster using the Helm uninstall command. {{documentationLink}}.",
                                text: "Deleting this cluster will delete any synced resources and findings related to this cluster after the next time data is synchronized."
                            },
                            links: {
                                documentation: "Learn more"
                            },
                            message: "Are you sure you want to delete the cluster {{name}}?"
                        },
                        error: "Failed to delete cluster",
                        title: "Delete"
                    }
                }
            }));

    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            direction="row"
            justifyContent="flex-end"
            spacing={1.5}>
            {deleteClusterExecuting && (
                <CircularProgress
                    size={theme.spacing(2)}
                    variant="indeterminate"/>)}
            {deleteClusterError && (
                <Message
                    level="error"
                    title={localization.actions.delete.error()}
                    variant="minimal"/>)}
            <Menu
                itemsOrGetItems={
                    _<MenuItem>([]).
                        concatIf(
                            !_.isNil(systemKubernetesClusterModel.clusterTypeName),
                            () =>
                                new ActionMenuItem(
                                    () =>
                                        setSystemKubernetesClustersContext(
                                            context => ({
                                                ...context,
                                                addOrEditOpen: systemKubernetesClusterModel
                                            })),
                                    localization.actions.addOrEdit.title[SystemKubernetesClusterHelper.getHelmStatus(systemKubernetesClusterModel)](),
                                    {
                                        disabled:
                                            !UserHelper.hasScopePermissions(
                                                scopeNodeModel.configuration.id,
                                                Contract.IdentityPermission.SecurityAdministrationRead) ||
                                            deleteClusterExecuting ||
                                            ScopeHelper.isProjectScope(scopeNodeModel),
                                        icon: <ConnectIcon/>
                                    })).
                        concatIf(
                            _.isNil(systemKubernetesClusterModel.clusterTypeName) ||
                            TypeHelper.extendOrImplement(systemKubernetesClusterModel.clusterTypeName, Contract.TypeNames.IUnmanagedKubernetesCluster),
                            () =>
                                new ActionMenuItem(
                                    () => deleteCluster(),
                                    localization.actions.delete.title(),
                                    {
                                        confirmOptions: {
                                            descriptionElement:
                                                <Stack spacing={2}>
                                                    <Typography
                                                        color="textSecondary"
                                                        variant="h3">
                                                        {localization.actions.delete.confirm.description.text()}
                                                    </Typography>
                                                    {systemKubernetesClusterModel.apiConnectivityStatus === Contract.SystemKubernetesClusterApiConnectivityStatus.Success &&
                                                        <Message
                                                            level="info"
                                                            title={
                                                                <Typography>
                                                                    {localization.actions.delete.confirm.description.connectedText({
                                                                        documentationLink:
                                                                            <Link
                                                                                urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsOnboardClusterViaTenableConnectorDeleteClusterRelativeUrl()}
                                                                                variant="external">
                                                                                {localization.actions.delete.confirm.links.documentation()}
                                                                            </Link>
                                                                    })}
                                                                </Typography>}/>}
                                                </Stack>,
                                            message:
                                                localization.actions.delete.confirm.message({
                                                    name:
                                                        <Entity
                                                            entityIdOrModel={systemKubernetesClusterModel.clusterReference.idReference}
                                                            linkOptions={{ disabled: true }}
                                                            variant="text"/>
                                                })
                                        },
                                        disabled:
                                            !UserHelper.hasScopePermissions(
                                                scopeNodeModel.configuration.id,
                                                Contract.IdentityPermission.SecurityAdministrationRead) ||
                                            deleteClusterExecuting,
                                        icon: <DeleteIcon/>
                                    })).
                        value()}/>
        </Stack>);
}