import { DataTableSortDirection, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, GroupedTableColumn, GroupedTableColumnRenderProps, InlineCodeTenants, LicensingHelper } from "../../../../../../../../../../../../../common";
import { RisksItemsFilterId } from "../../../../../../../../Filters";
import { GroupedItem, GroupedTableColumnId } from "../../../../../Grouped";
import { GroupedDefinition } from "../../../useDefinition";
import { useCommonGroupedRiskPolicyTypeColumns } from "../../useCommonGroupedRiskPolicyTypeColumns";

export function useRiskPolicyDefinition() {
    const commonColumns = useCommonGroupedRiskPolicyTypeColumns();
    const localization =
        useLocalization(
            "views.customer.risks.items.grouped.hooks.useDefinition.hooks.useCodeDefinition.hooks.useRiskPolicyTypeDefinition",
            () => ({
                repositories: "Repositories"
            }));

    return useMemo(
        (): GroupedDefinition => ({
            columns:
                _<GroupedTableColumn>([]).
                    concat(
                        commonColumns.riskPolicyCategory,
                        commonColumns.riskPolicyConfigurationTypeName,
                        commonColumns.riskCount,
                        {
                            element: ({ item }: GroupedTableColumnRenderProps<GroupedItem>) =>
                                <InlineCodeTenants tenantIds={(item.riskGroup as Contract.RiskControllerGetRiskGroupsResponseRiskPolicyTypeGroup).tenantIds}/>,
                            filterExternalId: RisksItemsFilterId[Contract.RiskFilterId.TenantId],
                            id: GroupedTableColumnId.Repositories,
                            minWidth: 150,
                            title: localization.repositories(),
                            width: 2
                        }).
                    concatIf(
                        LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.Cspm),
                        commonColumns.compliances).
                    concat(commonColumns.severity).
                    value(),
            defaultSort: {
                columnId: commonColumns.riskCount.id,
                direction: DataTableSortDirection.Descending
            },
            groupRequestType: Contract.RiskControllerGetRiskGroupsRequestType.RiskPolicyType,
            riskGroupToId: riskGroup => (riskGroup as Contract.RiskControllerGetRiskGroupsResponseRiskPolicyTypeGroup).riskPolicyConfigurationTypeName
        }),
        [commonColumns]);
}