﻿import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, EnumValuesFilter, PagedValuesFilter, useLocalization } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TenantEntityParentPathCell, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { useAzureTenantEntityStatusTranslator } from "../../../../../../../../../../tenants";
import { DefinitionData, ExportData } from "../../../../Table";
import { EntityTableDefinition } from "../../useDefinition";
import { useAzureCommonResourceDefinition } from "./useAzureCommonResourceDefinition";

export function useAzureTenantEntityDefinition(definitionData: DefinitionData) {
    const commonResourceDefinition = useAzureCommonResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();
    const azureTenantEntityStatusTranslator = useAzureTenantEntityStatusTranslator();

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.azure.useAzureTenantEntityDefinition",
            () => ({
                columns: {
                    parentEntityPath: "Path",
                    rawShortId: "ID",
                    resourceGroupIds: "Resource Groups",
                    status: "Status"
                }
            }));

    return new EntityTableDefinition(
        _.filter([
            commonResourceDefinition.columns.tenantColumn,
            commonResourceDefinition.columns.creationTimeColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AzureTenantEntityModel) => ({
                            [localization.columns.rawShortId()]: (item.entity as Contract.AzureTenantEntity).rawShortId
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedValuesFilter
                                getValuePage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AzureTenantEntityRawShortId)}
                                placeholder={localization.columns.rawShortId()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AzureTenantEntityRawShortId}
                itemProperty={(item: Contract.AzureTenantEntityModel) => (item.entity as Contract.AzureTenantEntity).rawShortId}
                key={Contract.EntityModelProperty.AzureTenantEntityRawShortId}
                title={localization.columns.rawShortId()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AzureTenantEntityModel) => ({
                            [localization.columns.status()]: azureTenantEntityStatusTranslator((item.entity as Contract.AzureTenantEntity).status)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.AzureTenantEntityStatus}
                                enumTypeTranslator={azureTenantEntityStatusTranslator}
                                placeholder={localization.columns.status()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AzureTenantEntityStatus}
                itemProperty={(item: Contract.AzureTenantEntityModel) => azureTenantEntityStatusTranslator((item.entity as Contract.AzureTenantEntity).status)}
                key={Contract.EntityModelProperty.AzureTenantEntityStatus}
                title={localization.columns.status()}/>,
            <DataTableColumn
                exportOptions={{
                    getData: (item: Contract.AzureTenantEntityModel) => [(item.entity as Contract.AzureTenantEntity).parentEntityId],
                    getItem:
                        (item: Contract.AzureTenantEntityModel, exportData: ExportData) => ({
                            [localization.columns.parentEntityPath()]:
                                _.isNil((item.entity as Contract.AzureTenantEntity).parentEntityId) ||
                                _.isNil(exportData.relatedEntityModelMap[(item.entity as Contract.AzureTenantEntity).parentEntityId]?.consoleUrl)
                                    ? item.parentEntityPath
                                    : `${item.parentEntityPath} (${exportData.relatedEntityModelMap[(item.entity as Contract.AzureTenantEntity).parentEntityId].consoleUrl})`
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedValuesFilter
                                getValuePage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AzureTenantEntityParentPath)}
                                placeholder={localization.columns.parentEntityPath()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AzureTenantEntityParentPath}
                key={Contract.EntityModelProperty.AzureTenantEntityParentPath}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AzureTenantEntityModel>) =>
                        <TenantEntityParentPathCell
                            parentEntityId={(item.entity as Contract.AzureTenantEntity).parentEntityId}
                            parentEntityPath={item.parentEntityPath}/>}
                title={localization.columns.parentEntityPath()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.AzureTenantEntityModel>(
                        Contract.TypeNames.AzureResourcesResourceGroup,
                        item => item.resourceGroupIds,
                        localization.columns.resourceGroupIds())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AzureTenantEntityResourceGroups)}
                                placeholder={localization.columns.resourceGroupIds()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AzureTenantEntityResourceGroups}
                key={Contract.EntityModelProperty.AzureTenantEntityResourceGroups}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AzureTenantEntityModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.resourceGroupIds}
                            entityTypeName={Contract.TypeNames.AzureResourcesResourceGroup}
                            entityVariant="iconText"/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.resourceGroupIds()}/>,
            commonResourceDefinition.columns.creatorIdentityCsvColumn,
            commonResourceDefinition.columns.regionColumn,
            commonResourceDefinition.columns.regionLocationColumn,
            commonResourceDefinition.columns.tagsColumn,
            commonResourceDefinition.columns.attributesColumn,
            commonResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ]),
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAzureTenantEntityRequest(
                new Contract.EntityControllerGetEntityModelPageAzureTenantEntityRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureTenantEntityParentPath]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureTenantEntityRawShortId]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureTenantEntityResourceGroups]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureTenantEntityStatus])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags]))));
}