import { NumberFormatter, Resizer, Shadows } from "@infrastructure";
import { Box, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useState } from "react";
import { CartesianGrid, Line, LineChart, Tooltip, XAxis, YAxis } from "recharts";
import { useTheme } from "../themes";
import { LineMetadata, LinesDataItem } from "../utilities";

type ChartProps = {
    activeLineKey?: string;
    interactive?: boolean;
    lineChartMargin?: LineChartMarginOptions;
    lineMetadatas: LineMetadata[];
    orderedLinesDataItems: LinesDataItem[];
};

export type LineChartMarginOptions = {
    left?: number;
    right?: number;
};

export function Chart({ activeLineKey, interactive = true, lineChartMargin, lineMetadatas, orderedLinesDataItems }: ChartProps) {
    const [chartRect, setChartRect] = useState<DOMRect>();
    const theme = useTheme();
    return (
        <Stack
            className="chart"
            sx={{
                height: "100%",
                position: "relative",
                width: "100%"
            }}>
            {interactive && <Resizer onSize={setChartRect}/>}
            <LineChart
                data={orderedLinesDataItems}
                height={
                    interactive
                        ? (chartRect?.height ?? 0) - 16
                        : 300}
                margin={{
                    left: lineChartMargin?.left ?? -30,
                    right: lineChartMargin?.right ?? 20,
                    top: 5
                }}
                width={
                    interactive
                        ? chartRect?.width ?? 0
                        : 750}>
                <CartesianGrid
                    horizontalCoordinatesGenerator={({ yAxis }) => _.range(0, yAxis?.height, 20)}
                    stroke={theme.palette.borders.pageFrame}
                    vertical={false}/>
                <XAxis
                    axisLine={{ stroke: theme.palette.borders.primary }}
                    dataKey={item => item.xAxisValue}
                    interval="preserveStartEnd"
                    minTickGap={20}
                    tick={{ fill: theme.palette.text.secondary }}
                    tickLine={false}/>
                <YAxis
                    axisLine={{ stroke: theme.palette.borders.primary }}
                    tick={{ fill: theme.palette.text.secondary }}
                    tickFormatter={value => NumberFormatter.unit(value)}
                    tickLine={false}/>
                <Tooltip
                    contentStyle={{
                        background: theme.palette.background.alternate,
                        border: "none",
                        borderRadius: 0,
                        color: theme.palette.text.secondary,
                        stroke: "none"
                    }}
                    formatter={
                        (lineYAxisValue, _name, _entry, lineIndex, _payload) =>
                            (<Stack
                                alignItems="center"
                                direction="row"
                                spacing={1}
                                sx={{ color: theme.palette.text.primary }}>
                                <Box
                                    sx={{
                                        backgroundColor: lineMetadatas[lineIndex].color,
                                        height: "12px",
                                        width: "3px"
                                    }}/>
                                <Typography sx={{ fontWeight: 600 }}>
                                    {lineYAxisValue}
                                </Typography>
                                <Typography>
                                    {lineMetadatas[lineIndex].displayName}
                                </Typography>
                            </Stack>) as any}
                    labelStyle={{
                        color: theme.palette.text.secondary,
                        fontSize: "10px"
                    }}
                    wrapperStyle={{
                        border: theme.border.alternate,
                        borderRadius: theme.spacing(0.75),
                        boxShadow: theme.shadows[Shadows.Tooltip],
                        overflow: "hidden",
                        stroke: "none"
                    }}/>
                {_.map(
                    lineMetadatas,
                    lineMetadata =>
                        <Line
                            dataKey={item => item.keyToYAxisValueMap[lineMetadata.key]}
                            dot={false}
                            isAnimationActive={false}
                            key={lineMetadata.key}
                            opacity={
                                !_.isNil(activeLineKey) &&
                                activeLineKey !== lineMetadata.key
                                    ? 0.5
                                    : 1}
                            stroke={lineMetadata.color}
                            strokeWidth={
                                activeLineKey === lineMetadata.key
                                    ? 6
                                    : !_.isNil(activeLineKey)
                                        ? 1
                                        : 2}
                            type="monotone"/>)}
            </LineChart>
        </Stack>);
}