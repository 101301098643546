import { map, useLocalization } from "@infrastructure";
import _ from "lodash";
import { useGetPermissionAssignmentEligibilityUpdateAuditEventCommonChanges } from ".";
import { Contract } from "../../../..";

export function useGetAwsSsoPermissionSetAssignmentEligibilityUpdateAuditEventChanges() {
    const getPermissionAssignmentEligibilityUpdateAuditEventCommonChanges = useGetPermissionAssignmentEligibilityUpdateAuditEventCommonChanges();

    const localization =
        useLocalization(
            "common.hooks.usePermissionEligibilityUpdateAuditEventChangesTranslator.hooks.useGetPermissionAssignmentEligibilityUpdateAuditEventChanges.useGetAwsSsoPermissionSetAssignmentEligibilityUpdateAuditEventChanges",
            () => ({
                awsManagedPolicies: "Permissions",
                inlinePolicyDocument: "Inline policy",
                permissionSet: "Permissions",
                type: "Permissions type"
            }));
    return (permissionEligibilityUpdateAuditEventPermissionEligibilityChanges: Contract.PermissionEligibilityUpdateAuditEventAwsSsoPermissionSetAssignmentEligibilityChanges) => {
        const { permissionsChange } = permissionEligibilityUpdateAuditEventPermissionEligibilityChanges;
        const permissionAssignmentEligibilityUpdateAuditEventCommonChanges = getPermissionAssignmentEligibilityUpdateAuditEventCommonChanges(permissionEligibilityUpdateAuditEventPermissionEligibilityChanges);
        return _.filter([
            permissionAssignmentEligibilityUpdateAuditEventCommonChanges.name,
            permissionAssignmentEligibilityUpdateAuditEventCommonChanges.principalTenantId,
            permissionAssignmentEligibilityUpdateAuditEventCommonChanges.granteePrincipals,
            ...(_.isNil(permissionsChange)
                ? []
                : map(
                    permissionsChange!.typeName,
                    {
                        [Contract.TypeNames.PermissionEligibilityUpdateAuditEventAwsSsoPermissionSetAssignmentEligibilityChangesPermissionsTypeChange]: () => [localization.type()],
                        [Contract.TypeNames.PermissionEligibilityUpdateAuditEventAwsSsoPermissionSetAssignmentEligibilityChangesPermissionSetPermissionsChange]: () => [localization.permissionSet()],
                        [Contract.TypeNames.PermissionEligibilityUpdateAuditEventAwsSsoPermissionSetAssignmentEligibilityChangesPolicyPermissionsChange]: () => {
                            const policyPermissionsChange = permissionsChange as Contract.PermissionEligibilityUpdateAuditEventAwsSsoPermissionSetAssignmentEligibilityChangesPolicyPermissionsChange;
                            return _.filter([
                                policyPermissionsChange.awsManagedPoliciesChanged
                                    ? localization.awsManagedPolicies()
                                    : undefined!,
                                policyPermissionsChange.inlinePolicyDocumentChanged
                                    ? localization.inlinePolicyDocument()
                                    : undefined!
                            ]);
                        }
                    })),
            permissionAssignmentEligibilityUpdateAuditEventCommonChanges.permissionRequestScopes,
            permissionAssignmentEligibilityUpdateAuditEventCommonChanges.expirationTimeFrame,
            permissionAssignmentEligibilityUpdateAuditEventCommonChanges.approval,
            permissionAssignmentEligibilityUpdateAuditEventCommonChanges.reasonRequired
        ]);
    };
}