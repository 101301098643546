﻿import { SvgIconProps } from "@mui/material";
import React, { Fragment } from "react";
import { Contract } from "../../../../common";
import { AzurePipelineIcon, DockerIcon, GitHubWorkflowIcon, GitLabPipelineIcon, JenkinsPipelineIcon, TerraformCloudRunTaskIcon } from "./icons";

type CodePipelineIconProps =
    SvgIconProps & {
        pipelineType: Contract.CodePipelineType;
    };

export function CodePipelineIcon({ pipelineType, ...props }: CodePipelineIconProps) {
    switch (pipelineType) {
        case Contract.CodePipelineType.AzurePipeline:
            return <AzurePipelineIcon {...props}/>;
        case Contract.CodePipelineType.General:
            return <DockerIcon {...props}/>;
        case Contract.CodePipelineType.GitHubWorkflow:
            return <GitHubWorkflowIcon {...props}/>;
        case Contract.CodePipelineType.GitLabPipeline:
            return <GitLabPipelineIcon {...props}/>;
        case Contract.CodePipelineType.JenkinsPipeline:
            return <JenkinsPipelineIcon {...props}/>;
        case Contract.CodePipelineType.TerraformCloudRunTask:
            return <TerraformCloudRunTaskIcon {...props}/>;
        default:
            return <Fragment/>;
    }
}