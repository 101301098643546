import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";
import { useTheme } from "../../../themes";

export function OneLoginFolderIcon(props: SvgIconProps) {
    const theme = useTheme();
    return (
        <SvgIcon {...props}>
            <path
                d="M8.62775 0.690138C8.24787 0.251813 7.6964 0 7.11637 0H3.11852C1.95333 0 1.01059 1.1 1.01059 2.44444L1.00752 5.82532C1.00254 5.88288 1 5.94114 1 6V16C1 17.1046 1.89543 18 3 18H5.552C5.51762 17.6714 5.5 17.3377 5.5 17C5.5 11.7533 9.75329 7.5 15 7.5C18.3589 7.5 21.3107 9.24324 23 11.8744V4.88889C23 3.54444 22.0467 2.44444 20.8815 2.44444H11.0614C10.4814 2.44444 9.92991 2.19263 9.55003 1.75431L8.62775 0.690138Z"
                fill="#BAC5D3"/>
            <path
                d="M14.0407 10.0248C12.9256 10.1793 11.7976 10.643 10.8454 11.3342C10.365 11.682 9.64873 12.4076 9.30131 12.9013C8.78233 13.6311 8.34056 14.6272 8.16042 15.4816C7.60713 18.0447 8.50783 20.6851 10.5108 22.3681C12.3637 23.9222 14.8943 24.4031 17.2232 23.6518C17.7251 23.4886 18.6215 23.0335 19.089 22.6986C19.5865 22.3466 20.3114 21.6296 20.6545 21.153C21.3836 20.1441 21.8297 19.0236 21.967 17.8429C22.0356 17.2633 21.9927 16.0783 21.8812 15.5546C21.3579 13.1288 19.6294 11.1668 17.2919 10.3511C16.5284 10.0849 16.1295 10.0205 15.1645 10.0033C14.6927 9.99471 14.1866 10.0033 14.0407 10.0248ZM15.9751 14.7732C16.0352 14.7989 16.0437 15.0479 16.0437 17.1903C16.0437 18.9935 16.0309 19.5946 15.9923 19.6332C15.9279 19.6976 14.5554 19.7062 14.4568 19.6418C14.401 19.6075 14.3924 19.3713 14.3796 18.0103L14.3667 16.4218H13.8434C13.4917 16.4218 13.2987 16.4046 13.2644 16.3703C13.2301 16.3359 13.2129 16.1041 13.2129 15.6104C13.2129 15.0136 13.2258 14.8934 13.2859 14.8247C13.3502 14.7517 13.4488 14.7474 14.6326 14.7474C15.3318 14.7474 15.9365 14.7603 15.9751 14.7732Z"
                fill={theme.palette.text.primary}/>
        </SvgIcon>);
}