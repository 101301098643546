import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { useGetAwsRdsDatabaseInstanceRiskContext } from "../contexts";

export function useAwsRdsDatabaseInstanceMinorVersionUpgradeDisabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsRdsDatabaseInstanceMinorVersionUpgradeDisabledRisk;
    const databaseInstanceModel = entityModelStore.useGet(risk.entityId) as Contract.AwsRdsDatabaseInstanceModel;
    const databaseInstance = databaseInstanceModel.entity as Contract.AwsRdsDatabaseInstance;

    const getAwsRdsDatabaseInstanceRiskContext = useGetAwsRdsDatabaseInstanceRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsRdsDatabaseInstanceMinorVersionUpgradeDisabledRiskDefinition",
            () => ({
                description: "{{databaseInstance}} 'auto minor version upgrade' flag is not enabled",
                sections: {
                    resolution: {
                        step1: "In the top right corner, click **Modify**",
                        step2: "Scroll down to the **Maintenance** section and select the **Enable auto minor version upgrade** checkbox",
                        step3: "Configure the **DB instance maintenance window**",
                        step4: "Click **Continue**",
                        step5: "**Select Apply Immediately** to apply the changes immediately, or **Apply during the next scheduled maintenance window**. Note that the upgrade causes downtime",
                        step6: "Click **Modify DB instance**"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            databaseInstance:
                <Entity
                    entityIdOrModel={databaseInstanceModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.Rds,
                AwsConsoleUrlBuilder.getRdsDatabaseInstanceUrl(databaseInstance)),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4(),
            localization.sections.resolution.step5(),
            localization.sections.resolution.step6()
        ],
        riskModel,
        () => {
            const databaseInstanceRiskContext = getAwsRdsDatabaseInstanceRiskContext(databaseInstanceModel);
            return [
                databaseInstanceRiskContext.generalInformation,
                databaseInstanceRiskContext.engine,
                databaseInstanceRiskContext.sensitive,
                databaseInstanceRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        });
}