﻿import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useAzureWebApplicationAuthenticationTypeTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useAzureWebApplicationAuthenticationTypeTranslator",
            () => ({
                [Contract.TypeNames.AzureWebApplicationAuthenticationType]: {
                    [Contract.AzureWebApplicationAuthenticationType.Disabled]: "Disabled",
                    [Contract.AzureWebApplicationAuthenticationType.Optional]: "Optional",
                    [Contract.AzureWebApplicationAuthenticationType.Required]: "Required"
                }
            }));
    return (authenticationType: Contract.AzureWebApplicationAuthenticationType) =>
        localization[Contract.TypeNames.AzureWebApplicationAuthenticationType][authenticationType]();
}