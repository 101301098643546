import { useMemo } from "react";
import { Contract } from "../../../../../../../../../../../../../../../../../common";
import { ConditionType } from "../../../utilities";

export function useEntityNamePatternConditionDefinition(condition: Contract.ProjectConfigurationRuleCondition) {
    const entityNamePatternCondition = condition as Contract.ProjectConfigurationRuleAzureResourceGroupNamePatternEntityCondition;
    return useMemo(
        () => ({
            conditionType: ConditionType.EntityNamePattern,
            not: entityNamePatternCondition.not,
            value: entityNamePatternCondition.resourceGroupNamePattern
        }),
        [entityNamePatternCondition]);
}