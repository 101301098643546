﻿import { SxProps, Typography } from "@mui/material";
import { TOptions } from "i18next";
import React from "react";
import { Contract } from "../../../../..";
import { InlineEntitySelection } from "../..";

type ViewProps = {
    riskPolicyConfiguration?: Contract.AwsSsoPermissionSetAssignmentRiskPolicyConfiguration;
    sx?: SxProps;
    templateTranslator: (options?: TOptions) => string;
};

export function View({ riskPolicyConfiguration, sx, templateTranslator }: ViewProps) {
    return (
        <Typography
            component="span"
            sx={sx}>
            {templateTranslator({
                permissionSets:
                    <InlineEntitySelection
                        entityTypeName={Contract.TypeNames.AwsSsoPermissionSet}
                        selection={{
                            allEntities: riskPolicyConfiguration?.allResources,
                            entityBuiltInAttributeTypeNames: riskPolicyConfiguration?.resourceBuiltInAttributeTypeNames,
                            entityCustomAttributeDefinitionIds: riskPolicyConfiguration?.resourceCustomAttributeDefinitionIds, entityIds: riskPolicyConfiguration?.resourceIds,
                            entityNamePattern: riskPolicyConfiguration?.resourceNamePattern,
                            entityTags: riskPolicyConfiguration?.resourceTags,
                            entityTenantIds: riskPolicyConfiguration?.permissionSetTenantIds
                        }}
                        sx={sx}/>
            })}
        </Typography>);
}