import { FormLayout, Message, Optional, StringHelper, useInputValidation, useLocalization } from "@infrastructure";
import { Box, Button, CircularProgress, FormControl, FormHelperText, Stack, TextField } from "@mui/material";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import { Contract, riskPolicyModelStore, TenantController, tenantModelStore, useTheme } from "../../../../../../../../../common";
import { AddOrEditComponentProps } from "../../../useAddOrEditDefinition";

export function AddOrEdit({ onClose, parentFolderId, scopeNodeModel }: AddOrEditComponentProps) {
    const tenantModel = tenantModelStore.useGet(scopeNodeModel?.configuration.id) as Optional<Contract.AwsTenantModel>;
    const tenantModels = tenantModelStore.useGetAll();

    const [name, setName] = useState(tenantModel?.configuration.name ?? "");

    const localization =
        useLocalization(
            "views.customer.scopes.hooks.useDefinition.hooks.useOpDefinition.addOrEdit",
            () => ({
                actions: {
                    add: {
                        error: "Failed to add account",
                        title: "Add"
                    },
                    edit: {
                        error: "Failed to update account",
                        title: "Update"
                    }
                },
                fields: {
                    name: {
                        error: {
                            exists: "Account name already exists",
                            length: "Account name length cannot be more than 1000 characters",
                            required: "Account name cannot be empty"
                        },
                        title: "Account name"
                    }
                },
                title: {
                    add: "Add a new account",
                    edit: "Edit account"
                }
            }));

    const normalizedTenantModels =
        useMemo(
            () =>
                _(tenantModels).
                    filter(tenantModel => tenantModel.tenantType == Contract.TenantType.Op).
                    map(tenantModel => StringHelper.normalize(tenantModel.configuration.name)).
                    value(),
            [tenantModels]);

    const [nameValidationController, nameValidationMessage] =
        useInputValidation(
            () => {
                const normalizedName = StringHelper.normalize(name);
                if (_.isEmpty(normalizedName)) {
                    return localization.fields.name.error.required();
                }
                if (_.includes(normalizedTenantModels, normalizedName)) {
                    return localization.fields.name.error.exists();
                }
                if (normalizedName!.length > 1000) {
                    return localization.fields.name.error.length();
                }

                return undefined;
            },
            [name]);

    const valid =
        useMemo(
            () => nameValidationController.isValid(),
            [name]);

    const [saveExecuting, setSaveExecuting] = useState(false);
    const [saveError, setSaveError] = useState(false);

    async function save() {
        setSaveExecuting(true);
        setSaveError(false);

        try {
            const { tenantModel: updatedTenantModel } =
                _.isNil(tenantModel)
                    ? await TenantController.insertOpTenant(
                        new Contract.TenantControllerInsertOpTenantRequest(
                            name,
                            parentFolderId))
                    : await TenantController.updateOpTenant(
                        new Contract.TenantControllerUpdateOpTenantRequest(
                            tenantModel.configuration.id,
                            name,
                            parentFolderId));

            await tenantModelStore.notify(updatedTenantModel);
            await riskPolicyModelStore.notify();

            onClose();
        } catch {
            setSaveError(true);
        }

        setSaveExecuting(false);
    }

    const theme = useTheme();
    return (
        <FormLayout
            footerOptions={{
                contentElement:
                    <Stack
                        alignItems="center"
                        direction="row"
                        justifyContent="flex-end"
                        spacing={1}>
                        <Box sx={{ flex: 1 }}>
                            {saveError && (
                                <Message
                                    level="error"
                                    title={
                                        _.isNil(tenantModel)
                                            ? localization.actions.add.error()
                                            : localization.actions.edit.error()}/>)}
                        </Box>
                        {saveExecuting && (
                            <CircularProgress
                                size={theme.spacing(2)}
                                variant="indeterminate"/>)}
                        <Button
                            disabled={!valid || saveExecuting}
                            onClick={() => save()}>
                            {_.isNil(tenantModel)
                                ? localization.actions.add.title()
                                : localization.actions.edit.title()}
                        </Button>
                    </Stack>
            }}
            titleOptions={{
                text:
                    _.isNil(tenantModel)
                        ? localization.title.add()
                        : localization.title.edit()
            }}>
            <FormControl
                fullWidth={true}
                variant="standard">
                <TextField
                    autoFocus={true}
                    disabled={saveExecuting}
                    label={localization.fields.name.title()}
                    value={name}
                    variant="outlined"
                    onChange={event => setName(event.target.value)}/>
                {!_.isNil(nameValidationMessage) && (
                    <FormHelperText error={true}>{nameValidationMessage}</FormHelperText>)}
            </FormControl>
        </FormLayout>);
}