import { useLocalization } from "@infrastructure";
import React from "react";
import { useGetAzureTenantEntityRiskContext } from "..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { EntityExternalConsoleLink } from "../../components";

export function useAzureInsightsDiagnosticSettingsTenantLogCategoryAdministrativeNotExistsRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AzureInsightsDiagnosticSettingsTenantLogCategoryAdministrativeNotExistsRisk;
    const tenantEntityModel = entityModelStore.useGet(risk.entityId) as Contract.AzureTenantEntityModel;

    const getAzureTenantEntityRiskContext = useGetAzureTenantEntityRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.compliance.useAzureInsightsDiagnosticSettingsTenantLogCategoryAdministrativeNotExistsRiskDefinition",
            () => ({
                description: "{{tenantEntity}} is missing activity log diagnostic settings configured for administrative activities",
                sections: {
                    resolution: {
                        step1: "Click the **Export Activity Logs** button on the top menu",
                        step2: "Click the **Add diagnostic settings** button",
                        step3: "Under **Category details** section, check the **Administrative** category",
                        step4: "Under **Destination details** section, check the target destination",
                        step5: "Click the **Save** button on top to confirm"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            tenantEntity:
                <Entity
                    entityIdOrModel={risk.entityId}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={risk.entityId}
                key={risk.entityId}
                page={Contract.AzureConsoleEntityPage.ActivityLog}/>,
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4(),
            localization.sections.resolution.step5()],
        riskModel,
        () => {
            const tenantEntityRiskContext = getAzureTenantEntityRiskContext(tenantEntityModel);
            return [
                tenantEntityRiskContext.generalInformation,
                tenantEntityRiskContext.sensitive,
                tenantEntityRiskContext.status,
                tenantEntityRiskContext.path,
                tenantEntityRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)];
        });
}