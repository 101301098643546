import { useChangeEffect, useLocalization } from "@infrastructure";
import { FormControl, FormHelperText, Stack, TextField } from "@mui/material";
import _ from "lodash";
import React, { useState } from "react";
import { Contract } from "../../../../../../../../../../../../../common";
import { EntityTypeNamePropertyPatternItem } from "../../../EntityTypeNamePropertyPattern";

type ResourceTagPatternProps = {
    duplicate: boolean;
    onItemChanged: (propertyPatternItem: EntityTypeNamePropertyPatternItem) => void;
    propertyPatternItem: EntityTypeNamePropertyPatternItem;
    readOnly: boolean;
};

export function ResourceTagPattern({ duplicate, onItemChanged, propertyPatternItem, readOnly }: ResourceTagPatternProps) {
    const [propertyPatternKeyValue, setPropertyPatternKeyValue] = useState(() => (propertyPatternItem.propertyPattern as Contract.RiskPolicyConfigurationEntityExclusionResourceTagPattern).resourceTagPattern.keyPattern);
    const [propertyPatternValueValue, setPropertyPatternValueValue] = useState(() => (propertyPatternItem.propertyPattern as Contract.RiskPolicyConfigurationEntityExclusionResourceTagPattern).resourceTagPattern.valuePattern);

    const localization =
        useLocalization(
            "views.customer.riskPolicies.configuration.entityExclusion.entityTypeNamePropertyPattern.entityPropertyPattern.resourceTagPattern",
            () => ({
                duplicate: "Duplicate value is not allowed",
                key: "Key Pattern",
                value: "Value Pattern"
            }));

    useChangeEffect(
        () => {
            const newPropertyPatternItem = _.cloneDeep(propertyPatternItem.propertyPattern)!;
            (newPropertyPatternItem as Contract.RiskPolicyConfigurationEntityExclusionResourceTagPattern).resourceTagPattern.keyPattern = propertyPatternKeyValue.trim();
            (newPropertyPatternItem as Contract.RiskPolicyConfigurationEntityExclusionResourceTagPattern).resourceTagPattern.valuePattern = propertyPatternValueValue?.trim();

            onItemChanged(
                new EntityTypeNamePropertyPatternItem(
                    newPropertyPatternItem,
                    true,
                    propertyPatternItem.id));
        },
        [propertyPatternKeyValue, propertyPatternValueValue]);

    return (
        <Stack
            direction="row"
            flexDirection="row"
            justifyContent="space-evenly"
            spacing={1}>
            <FormControl
                variant="standard">
                <TextField
                    disabled={readOnly}
                    label={localization.key()}
                    value={propertyPatternKeyValue}
                    variant="outlined"
                    onChange={event => setPropertyPatternKeyValue(event.target.value)}/>
                {!readOnly &&
                    propertyPatternItem.isValid() &&
                    duplicate &&
                    <FormHelperText error={true}>{localization.duplicate()}</FormHelperText>}
            </FormControl>
            <FormControl
                variant="standard">
                <TextField
                    disabled={readOnly}
                    label={localization.value()}
                    value={propertyPatternValueValue}
                    variant="outlined"
                    onChange={event => setPropertyPatternValueValue(event.target.value)}/>
                {!readOnly &&
                    propertyPatternItem.isValid() &&
                    duplicate &&
                    <FormHelperText error={true}>{localization.duplicate()}</FormHelperText>}
            </FormControl>
        </Stack>);
}