import { useLocalization, useLocalizeList } from "@infrastructure";
import _ from "lodash";
import { AuditEventDefinition, AuditEventDefinitionContext, AuditEventDefinitionDetailItem } from "..";
import { Contract } from "../../../../../../../../../common";

export function useFolderAuditEventDefinition(context: AuditEventDefinitionContext) {
    const localizeList = useLocalizeList();
    const localization =
        useLocalization(
            "views.customer.auditEvents.profile.hooks.useDefinition.hooks.useFolderAuditEventDefinition",
            () => ({
                folderName: "Folder name",
                folderParentPath: "Parent Folder",
                propertyChanges: "Updated properties"
            }));

    const folderAuditEvent = context.auditEventModel.auditEvent as Contract.FolderAuditEvent;
    return new AuditEventDefinition(
        _<AuditEventDefinitionDetailItem>([]).
            concat(
                new AuditEventDefinitionDetailItem(
                    localization.folderName(),
                    folderAuditEvent.folderName),
                new AuditEventDefinitionDetailItem(
                    localization.folderParentPath(),
                    folderAuditEvent.folderParentPath)).
            concatIf(
                folderAuditEvent.typeName === Contract.TypeNames.FolderUpdateAuditEvent,
                () => {
                    const properties =
                        _<string>([]).
                            concatIf(
                                (folderAuditEvent as Contract.FolderUpdateAuditEvent).folderNameChanged,
                                localization.folderName()).
                            concatIf(
                                (folderAuditEvent as Contract.FolderUpdateAuditEvent).folderParentPathChanged,
                                localization.folderParentPath()).
                            value();
                    return (
                        _<AuditEventDefinitionDetailItem>([]).
                            concatIf(
                                !_.isEmpty(properties),
                                new AuditEventDefinitionDetailItem(
                                    localization.propertyChanges(),
                                    localizeList(properties)!)).
                            value());
                }).
            value());
}