import { Resizer, useLocalization } from "@infrastructure";
import { Box, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useRef, useState } from "react";
import { getSidebarNavigationItemClassName, useTheme } from "../../../../../../common";
import { CustomerView } from "../../../../hooks";
import { WelcomeImage } from "./images";

type WelcomeProps = {
    variant: "codeTenants" | "newCustomer";
};

export function Welcome({ variant }: WelcomeProps) {
    const localization =
        useLocalization(
            "views.customer.dashboard.welcome",
            () => ({
                subtitle: {
                    code: "Click IaC in the left-hand menu to view scans and findings for your onboarded code repositories and CI/CD pipelines",
                    resource: "To start, connect your cloud environment"
                },
                title: {
                    code: "Your user only has access to IaC-related features",
                    resource: "You are new here"
                }
            }));

    const textRef = useRef<HTMLSpanElement>(null);
    const [arrowRect, setArrowRect] = useState<DOMRect>();
    const [flip, setFlip] = useState(false);

    function setArrowPosition() {
        const tenantsNavigationItemElement =
            document.getElementsByClassName(
                getSidebarNavigationItemClassName(
                    variant === "newCustomer"
                        ? CustomerView.Scopes
                        : CustomerView.Code))[0];
        if (_.isNil(tenantsNavigationItemElement)) {
            return;
        }

        const tenantsNavigationItemBoundingClientRect = tenantsNavigationItemElement.getBoundingClientRect();
        const textBoundingClientRect = textRef.current!.getBoundingClientRect();
        const left = tenantsNavigationItemBoundingClientRect.width;
        const width = Math.max(textBoundingClientRect.left - left - 36, 0);
        if (tenantsNavigationItemBoundingClientRect.top > textBoundingClientRect.top) {
            setArrowRect(
                new DOMRect(
                    20,
                    textBoundingClientRect.top - 56,
                    width,
                    tenantsNavigationItemBoundingClientRect.top - textBoundingClientRect.top + 20));
            setFlip(true);
        } else {
            setArrowRect(
                new DOMRect(
                    20,
                    tenantsNavigationItemBoundingClientRect.top - 36,
                    width,
                    Math.max(textBoundingClientRect.top - tenantsNavigationItemBoundingClientRect.top - 14, 10)));
            setFlip(false);
        }
    }

    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            justifyContent="center"
            sx={{ height: "100%" }}>
            <Resizer onSize={setArrowPosition}/>
            <Stack
                alignItems="center"
                justifyContent="center"
                spacing={3}>
                <WelcomeImage/>
                <Stack
                    alignItems="center"
                    justifyContent="center"
                    spacing={0.5}>
                    <Box>
                        {!_.isNil(arrowRect) && (
                            <svg
                                fill="none"
                                height={arrowRect.height}
                                style={{
                                    left: arrowRect.x,
                                    position: "absolute",
                                    top: arrowRect.y
                                }}
                                viewBox={`0 0 ${arrowRect.width} ${arrowRect.height}`}
                                width={arrowRect.width}>
                                <g
                                    transform={
                                        flip
                                            ? `translate(0 ${arrowRect.height}) scale(1 -1)`
                                            : undefined}>
                                    <path
                                        d={`M0,4Q${arrowRect.width / 2 * 0.4},0,${arrowRect.width / 2},${arrowRect.height / 2}T${arrowRect.width},${arrowRect.height}`}
                                        stroke={theme.palette.grey[500]}
                                        strokeDasharray={4}/>
                                    <path
                                        d="M4,0 L0,4 4,8"
                                        stroke={theme.palette.grey[500]}/>
                                </g>
                            </svg>)}
                        <Typography
                            sx={{ fontWeight: 700 }}
                            variant="h1">
                            {variant === "newCustomer"
                                ? localization.title.resource()
                                : localization.title.code()}
                        </Typography>
                    </Box>
                    <Typography
                        ref={textRef}
                        sx={{
                            fontWeight: 400,
                            maxWidth: theme.spacing(70)
                        }}
                        textAlign="center"
                        variant="h3">
                        {variant === "newCustomer"
                            ? localization.subtitle.resource()
                            : localization.subtitle.code()}
                    </Typography>
                </Stack>
            </Stack>
        </Stack>);
}