﻿import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useGcpSqlInstancePostgreSqlLogLevelTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useGcpSqlInstancePostgreSqlLogLevelTranslator",
            () => ({
                [Contract.TypeNames.GcpSqlInstancePostgreSqlLogLevel]: {
                    [Contract.GcpSqlInstancePostgreSqlLogLevel.Debug1]: "Debug1",
                    [Contract.GcpSqlInstancePostgreSqlLogLevel.Debug2]: "Debug2",
                    [Contract.GcpSqlInstancePostgreSqlLogLevel.Debug3]: "Debug3",
                    [Contract.GcpSqlInstancePostgreSqlLogLevel.Debug4]: "Debug4",
                    [Contract.GcpSqlInstancePostgreSqlLogLevel.Debug5]: "Debug5",
                    [Contract.GcpSqlInstancePostgreSqlLogLevel.Error]: "Error",
                    [Contract.GcpSqlInstancePostgreSqlLogLevel.Fatal]: "Fatal",
                    [Contract.GcpSqlInstancePostgreSqlLogLevel.Info]: "Info",
                    [Contract.GcpSqlInstancePostgreSqlLogLevel.Log]: "Log",
                    [Contract.GcpSqlInstancePostgreSqlLogLevel.Notice]: "Notice",
                    [Contract.GcpSqlInstancePostgreSqlLogLevel.Panic]: "Panic",
                    [Contract.GcpSqlInstancePostgreSqlLogLevel.Warning]: "Warning"
                }
            }));
    return (logLevel: Contract.GcpSqlInstancePostgreSqlLogLevel) =>
        localization[Contract.TypeNames.GcpSqlInstancePostgreSqlLogLevel][logLevel]();
}