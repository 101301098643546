import { NumberFormatter, Optional, UnexpectedError } from "@infrastructure";
import _, { Dictionary } from "lodash";
import { Contract, RiskController, RiskPolicyTypeMetadataHelper } from "..";
import { ConfigurationControllerUpsertCustomComplianceRequestRiskPolicy, ConfigurationControllerUpsertCustomComplianceRequestSection } from "../controllers/types.generated";

export class ComplianceHelper {
    private static builtInComplianceTypeToAnalysisGroupTypeToRiskPolicyConfigurationTypeNamesMap: Dictionary<Dictionary<string[]>>;
    private static builtInComplianceTypeToTenantTypesMap: Dictionary<Contract.TenantType[]>;

    public static async initialize() {
        const { builtInComplianceTypeToAnalysisGroupTypeToRiskPolicyConfigurationTypeNamesMap } = await RiskController.getBuiltInComplianceTypeToAnalysisGroupTypeToRiskPolicyConfigurationTypeNamesMap();

        ComplianceHelper.builtInComplianceTypeToAnalysisGroupTypeToRiskPolicyConfigurationTypeNamesMap = builtInComplianceTypeToAnalysisGroupTypeToRiskPolicyConfigurationTypeNamesMap;
        ComplianceHelper.builtInComplianceTypeToTenantTypesMap =
            _.mapValues(
                ComplianceHelper.builtInComplianceTypeToAnalysisGroupTypeToRiskPolicyConfigurationTypeNamesMap,
                analysisGroupTypeToRiskPolicyConfigurationTypeNamesMap =>
                    _(analysisGroupTypeToRiskPolicyConfigurationTypeNamesMap).
                        flatMap((riskPolicyConfigurationTypeNames, analysisGroupType) =>
                            riskPolicyConfigurationTypeNames.
                                map(
                                    riskPolicyConfigurationTypeName =>
                                        RiskPolicyTypeMetadataHelper.getTenantTypes(
                                            riskPolicyConfigurationTypeName,
                                            analysisGroupType))).
                        flatMap().
                        uniq().
                        value());
    }

    public static getBuiltInComplianceTypeAnalysisGroupTypeToRiskPolicyConfigurationTypeNamesMap(complianceType: string): Dictionary<string[]> {
        return ComplianceHelper.builtInComplianceTypeToAnalysisGroupTypeToRiskPolicyConfigurationTypeNamesMap[complianceType];
    }

    public static getBuiltInComplianceTypeTenantTypes(complianceType: string): Contract.TenantType[] {
        return ComplianceHelper.builtInComplianceTypeToTenantTypesMap[complianceType];
    }

    public static getCustomComplianceId(item: CustomCompliance): string {
        return (item.id ?? item.clientId)!;
    }

    public static getDataPercentages(data: Contract.ComplianceData): [number, Dictionary<number>] {
        const severities = _.values(Contract.Severity);

        if (!data.hasResults) {
            return [0, {}];
        }
        const percentages =
            NumberFormatter.distributionPercentages(
                _(severities).
                    map(
                        severity =>
                            data.stats!.count == 0
                                ? 0
                                : (data.stats!.unsecuredSeverityToCountMap[severity] ?? 0) / data.stats!.count).
                    concat(data.stats!.securedPercentage).
                    value());
        const securedPercentage = _.last(percentages)!;
        const unsecuredSeverityToPercentageMap =
            _(severities).
                map((severity, severityIndex) => ({ severity, severityIndex })).
                keyBy(({ severity }) => severity).
                mapValues(({ severityIndex }) => percentages[severityIndex]).
                value();

        return [securedPercentage, unsecuredSeverityToPercentageMap];
    }

    public static getDataSecuredPercentageDelta(data: Contract.ComplianceData, timeFrame?: Contract.TimeFrame) {
        let securedPercentageDelta: Optional<number>;
        if (!_.isNil(timeFrame) &&
            !_.isNil(data.stats) &&
            !_.isNil(data.timeFrameToStatsMap?.[timeFrame])) {
            const securedPercentage = _.round(data.stats.securedPercentage, 2);
            const timeFrameOldestSecuredPercentage = _.round(data!.timeFrameToStatsMap![timeFrame]!.securedPercentage, 2);
            securedPercentageDelta = securedPercentage - timeFrameOldestSecuredPercentage;
            securedPercentageDelta =
                securedPercentageDelta > -0.01 && securedPercentageDelta < 0.01
                    ? 0
                    : securedPercentageDelta;
        }

        return securedPercentageDelta;
    }

    public static getSectionIds(sectionData: Contract.ComplianceSectionData): string[] {
        return _.concat(
            sectionData.identifier,
            _.flatMap(
                sectionData.sectionDatas,
                sectionData => ComplianceHelper.getSectionIds(sectionData)));
    }

    public static getSideViewItemData(itemId: string) {
        const itemIdParts = _.split(itemId, "/");
        if (_.size(itemIdParts) < 2) {
            throw new UnexpectedError("complianceHelper.getSideViewData", itemId);
        }

        return {
            identifier: itemIdParts[0],
            scopeId: itemIdParts[1]
        };
    }

    public static getSideViewItemId(complianceId: string, scopeId: string) {
        return `${complianceId}/${scopeId}`;
    }

    public static async getRiskPolicyIdentifierToExcludedEntityIdsMap(
        dataOrDatas: Contract.ComplianceRiskPolicyData | Contract.ComplianceRiskPolicyData[],
        scopeId: string): Promise<Dictionary<string[]>> {
        const builtInRiskPolicyConfigurationTypeNames: string[] = [];
        const complianceRiskPolicyIdentifierToExcludedEntityIdsMap: Dictionary<string[]> = {};
        const customRiskPolicyIds: string[] = [];
        _.each(
            _.concat(dataOrDatas),
            data => {
                if (!data.hasResults) {
                    complianceRiskPolicyIdentifierToExcludedEntityIdsMap[data.riskPolicyIdentifier] = [];
                } else if (data.riskPolicyIdentifier in Contract.TypeNames) {
                    builtInRiskPolicyConfigurationTypeNames.push(data.riskPolicyIdentifier);
                } else {
                    customRiskPolicyIds.push(data.riskPolicyIdentifier);
                }
            });

        if (!_.isEmpty(builtInRiskPolicyConfigurationTypeNames) ||
            !_.isEmpty(customRiskPolicyIds)) {
            const { builtInRiskPolicyConfigurationTypeNameToExcludedEntityIdsMap, customRiskPolicyIdToExcludedEntityIdsMap } =
                await RiskController.getRiskPolicyExcludedEntityIds(
                    new Contract.RiskControllerGetRiskPolicyExcludedEntityIdsRequest(
                        builtInRiskPolicyConfigurationTypeNames,
                        customRiskPolicyIds,
                        scopeId));
            _.merge(
                complianceRiskPolicyIdentifierToExcludedEntityIdsMap,
                builtInRiskPolicyConfigurationTypeNameToExcludedEntityIdsMap,
                customRiskPolicyIdToExcludedEntityIdsMap);
        }

        return complianceRiskPolicyIdentifierToExcludedEntityIdsMap;
    }
}

export class CustomCompliance extends ConfigurationControllerUpsertCustomComplianceRequestSection {
    constructor(
        description: Optional<string>,
        id: Optional<string>,
        name: string,
        riskPolicies: ConfigurationControllerUpsertCustomComplianceRequestRiskPolicy[],
        public sections: CustomCompliance[],
        public clientId?: Optional<string>) {
        super(description, id, name, riskPolicies, sections);
    }
}