import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition, useGetGcpComputeInstancesRiskContext, useGetGcpComputeInstanceTemplateRiskContext } from "../../../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../../../common";
import { EntityExternalConsoleLink } from "../../../../components";

export function useGcpComputeInstanceShieldedNotEnabledRiskInstanceTemplateDefinition(riskModel: Contract.GcpComputeInstanceShieldedNotEnabledRiskModel) {
    const risk = riskModel.risk as Contract.GcpComputeInstanceShieldedNotEnabledRisk;
    const instanceTemplateModel = entityModelStore.useGet(risk.entityId) as Contract.GcpComputeInstanceTemplateModel;

    const getGcpComputeInstanceTemplateRiskContext = useGetGcpComputeInstanceTemplateRiskContext();
    const getGcpComputeInstancesRiskContext = useGetGcpComputeInstancesRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.useGcpComputeInstanceShieldedNotEnabledRiskDefinition.hooks.useGcpComputeInstanceShieldedNotEnabledRiskInstanceTemplateDefinition",
            () => ({
                description: "{{instanceTemplate}} Shielded VM is not enabled",
                sections: {
                    resolution: {
                        step1: "Click **CREATE SIMILAR**",
                        step2: "Click on **Advanced options** and then **Security**",
                        step3: "In the **Shielded VM** section, select **Turn on vTPM** and **Turn on Integrity Monitoring**",
                        step4: "Click **CREATE**",
                        step5: "For each instance group and cluster using the instance template, update the configuration to use the newly created instance template",
                        step6: "Delete the old unsecured instance template. Note that you cannot delete an instance template if any managed instance group still references it"
                    }
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            instanceTemplate:
                <Entity
                    entityIdOrModel={instanceTemplateModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={riskModel.risk.entityId}
                key={riskModel.risk.entityId}
                page={Contract.GcpConsolePage.Resource}/>,
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4(),
            localization.sections.resolution.step5(),
            localization.sections.resolution.step6()
        ],
        riskModel,
        () => {
            const instanceTemplateContextItems = getGcpComputeInstanceTemplateRiskContext(instanceTemplateModel);
            const instancesContextItems = getGcpComputeInstancesRiskContext(risk.aggregatedEntityIds);
            return [
                instanceTemplateContextItems.generalInformation,
                instanceTemplateContextItems.sensitive,
                instancesContextItems.sensitive,
                instancesContextItems.instances,
                instanceTemplateContextItems.instanceGroups,
                instanceTemplateContextItems.vpcs,
                instanceTemplateContextItems.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}