import { InlineItems, ItemsPopoverActions } from "@infrastructure";
import { Stack } from "@mui/material";
import _ from "lodash";
import React, { useRef } from "react";
import { Contract, Permitter } from "../../../../../../../common";
import { AwsResourcePermitterType, AzureResourcePermitterType } from "../../../../../../../tenants";

type InlinePermitterEntitiesProps = {
    awsResourcePermitterTypes: Contract.AwsResourcePermitterType[];
    azureResourcePermitterTypes: Contract.AzurePermitterType[];
    permitterEntityIds: string[];
};

export function InlinePermitterEntities({ awsResourcePermitterTypes, azureResourcePermitterTypes, permitterEntityIds }: InlinePermitterEntitiesProps) {
    const itemsPopoverActionsRef = useRef<ItemsPopoverActions>();
    return (
        <InlineItems
            items={
                _<string | AwsResourcePermitterType | AzureResourcePermitterType>([]).
                    concat(permitterEntityIds).
                    concat(
                        _.map(
                            awsResourcePermitterTypes,
                            awsResourcePermitterType => new AwsResourcePermitterType(awsResourcePermitterType))).
                    concat(
                        _.map(
                            azureResourcePermitterTypes,
                            azureResourcePermitterType => new AzureResourcePermitterType(azureResourcePermitterType))).
                    value()}
            itemsPopoverActionsRef={itemsPopoverActionsRef}
            variant="itemPlusItemCount">
            {(item: string | AwsResourcePermitterType | AzureResourcePermitterType) =>
                <Stack sx={{ width: "100%" }}>
                    <Permitter
                        permitterEntityIdOrPermitterType={item}
                        variant="iconTextTypeTenant"
                        onClick={itemsPopoverActionsRef.current?.close}/>
                </Stack>}
        </InlineItems>);
}