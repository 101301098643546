﻿import { Link, useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition, useGetAwsApiGatewayV1ApiRiskContext } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { AwsApiGatewayV1ApiRoutesTable, AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { RiskDefinitionSection } from "../../../../../../utilities";

export function useAwsApiGatewayApiWebAclNotExistsRiskDefinition(riskModel: Contract.RiskModel) {
    const apiGatewayApiWebAclNotExistsRiskModel = riskModel as Contract.AwsApiGatewayApiWebAclNotExistsRiskModel;
    const apiModel = entityModelStore.useGet(apiGatewayApiWebAclNotExistsRiskModel.risk.entityId) as Contract.AwsApiGatewayV1ApiModel;
    const api = apiModel.entity as Contract.AwsApiGatewayV1Api;

    const getAwsApiGatewayV1ApiRiskContext = useGetAwsApiGatewayV1ApiRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsApiGatewayApiWebAclNotExistsRiskDefinition",
            () => ({
                description: "{{api}} is not associated with WAF web ACL",
                sections: {
                    integration: "Resources",
                    resolution: {
                        step1: "For **{{api}}**, click on the stage which you want to associate with a WAF Web ACL",
                        step2: "In the **Stage details** section click **Edit**",
                        step3: "In the **Additional Settings** section, choose the **Web application firewall (AWS WAF)** that you want to associate with this stage",
                        step4: {
                            link: "Creating web ACL",
                            text: "If the Web ACL you need doesn't exist yet, create a new one. For more information, see {{creatingWebAclUrl}}"
                        },
                        step5: "Before saving changes, ensure that all users who need to use the API Gateway API can still do so",
                        step6: "Click **Save**"

                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            api:
                <Entity
                    entityIdOrModel={apiModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.ApiGateway,
                AwsConsoleUrlBuilder.getApiGatewayV1ApiStagesUrl(api)),
            localization.sections.resolution.step1({
                api:
                    <Entity
                        entityIdOrModel={apiModel}
                        glanceOptions={{ disabled: true }}
                        linkOptions={{ disabled: true }}
                        variant="text"/>
            }),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4.text({
                creatingWebAclUrl:
                    <Link
                        urlOrGetUrl={apiGatewayApiWebAclNotExistsRiskModel.creatingWebAclUrl}
                        variant="external">
                        {localization.sections.resolution.step4.link()}
                    </Link>
            }),
            localization.sections.resolution.step5(),
            localization.sections.resolution.step6()
        ],
        apiGatewayApiWebAclNotExistsRiskModel,
        () => {
            const apiRiskContext = getAwsApiGatewayV1ApiRiskContext(apiModel);
            return [
                apiRiskContext.generalInformation,
                apiRiskContext.accessLevel,
                apiRiskContext.integratedResource,
                apiRiskContext.sensitiveIntegratedResource,
                apiRiskContext.sensitive,
                apiRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        },
        {
            sections: [
                new RiskDefinitionSection(
                    <AwsApiGatewayV1ApiRoutesTable apiModel={apiModel}/>,
                    localization.sections.integration(),
                    {
                        expandable: true
                    })
            ]
        });

}