import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function AzureFolderIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                d="M8.62775 0.690138C8.24787 0.251813 7.6964 0 7.11637 0H3.11852C1.95333 0 1.01059 1.1 1.01059 2.44444L1.00752 5.82532C1.00254 5.88288 1 5.94114 1 6V16C1 17.1046 1.89543 18 3 18H6.8305L9.60714 9.40623C9.74889 8.75663 10.0985 8.31059 10.3144 8.08197C10.4144 7.97608 10.5111 7.89031 10.5569 7.85074C11.0608 7.36691 11.8123 7 12.6667 7H17.3333C18.2442 7 19.0381 7.41702 19.5398 7.94815L19.542 7.95049C19.6738 8.09002 19.9685 8.40206 20.2162 8.9266L20.285 9.07224L22.8032 16.8662C22.9293 16.6041 23 16.3103 23 16V4.88889C23 3.54444 22.0467 2.44444 20.8815 2.44444H11.0614C10.4814 2.44444 9.92991 2.19263 9.55003 1.75431L8.62775 0.690138Z"
                fill="#BAC5D3"/>
            <path
                d="M10.6027 7.81117C10.6027 7.81117 10.5985 7.81499 10.5874 7.82457C10.5967 7.81628 10.6027 7.81117 10.6027 7.81117Z"
                fill="#BAC5D3"/>
            <path
                d="M12.6672 9.50001H16.81L12.5094 23.0228C12.4652 23.1617 12.3811 23.2825 12.2688 23.368C12.1566 23.4536 12.0219 23.4996 11.8837 23.4996H8.65956C8.55486 23.4996 8.45166 23.4732 8.35849 23.4225C8.26533 23.3718 8.18488 23.2982 8.12381 23.208C8.06274 23.1177 8.02279 23.0133 8.00728 22.9034C7.99177 22.7935 8.00113 22.6813 8.0346 22.576L12.0413 9.97684C12.0855 9.83783 12.1697 9.71702 12.282 9.63145C12.3943 9.54588 12.529 9.50001 12.6672 9.50001Z"
                fill="url(#paint0_linear_61567_6868)"/>
            <path
                d="M18.6875 18.5703H12.1181C12.057 18.5702 11.9973 18.5897 11.9468 18.6261C11.8963 18.6626 11.8573 18.7143 11.8349 18.7746C11.8125 18.8349 11.8077 18.901 11.8212 18.9642C11.8347 19.0274 11.8658 19.0848 11.9104 19.129L16.1318 23.3105C16.2547 23.4321 16.4165 23.4998 16.5846 23.4997H20.3045L18.6875 18.5703Z"
                fill="#0078D4"/>
            <path
                d="M12.6672 9.50001C12.5275 9.49944 12.3915 9.54652 12.2787 9.63398C12.1658 9.72144 12.0822 9.8449 12.0402 9.98634L8.03983 22.5647C8.00411 22.6704 7.99289 22.7837 8.00714 22.8949C8.02138 23.0061 8.06065 23.1121 8.12165 23.2038C8.18264 23.2956 8.26355 23.3704 8.35754 23.4219C8.45153 23.4734 8.55583 23.5001 8.66161 23.4998H11.9689C12.0921 23.4765 12.2072 23.4188 12.3025 23.3328C12.3979 23.2468 12.47 23.1354 12.5115 23.0102L13.3092 20.5151L16.1588 23.3357C16.2782 23.4405 16.428 23.4985 16.5829 23.4998H20.289L18.6636 18.5704L13.9253 18.5716L16.8253 9.50022L12.6672 9.50001Z"
                fill="url(#paint1_linear_61567_6868)"/>
            <path
                d="M17.9585 9.97638C17.9143 9.83759 17.8303 9.71698 17.7182 9.63156C17.6061 9.54614 17.4715 9.5002 17.3335 9.50022H12.7165C12.8545 9.50022 12.989 9.54617 13.1011 9.63159C13.2132 9.71701 13.2973 9.8376 13.3414 9.97638L17.3483 22.576C17.3818 22.6814 17.3912 22.7936 17.3757 22.9035C17.3602 23.0135 17.3203 23.1179 17.2592 23.2082C17.1981 23.2985 17.1177 23.372 17.0245 23.4228C16.9313 23.4735 16.8281 23.5 16.7233 23.5H21.3405C21.4453 23.5 21.5485 23.4735 21.6416 23.4227C21.7348 23.372 21.8152 23.2984 21.8763 23.2081C21.9373 23.1178 21.9773 23.0134 21.9927 22.9035C22.0082 22.7936 21.9988 22.6813 21.9654 22.576L17.9585 9.97638Z"
                fill="url(#paint2_linear_61567_6868)"/>
            <defs>
                <linearGradient
                    gradientUnits="userSpaceOnUse"
                    id="paint0_linear_61567_6868"
                    x1="17.8161"
                    x2="14.5826"
                    y1="10.5376"
                    y2="24.8412">
                    <stop stopColor="#114A8B"/>
                    <stop
                        offset="1"
                        stopColor="#0669BC"/>
                </linearGradient>
                <linearGradient
                    gradientUnits="userSpaceOnUse"
                    id="paint1_linear_61567_6868"
                    x1="16.5681"
                    x2="15.4517"
                    y1="16.8238"
                    y2="17.229">
                    <stop stopOpacity="0.3"/>
                    <stop
                        offset="0.071"
                        stopOpacity="0.2"/>
                    <stop
                        offset="0.321"
                        stopOpacity="0.1"/>
                    <stop
                        offset="0.623"
                        stopOpacity="0.05"/>
                    <stop
                        offset="1"
                        stopOpacity="0"/>
                </linearGradient>
                <linearGradient
                    gradientUnits="userSpaceOnUse"
                    id="paint2_linear_61567_6868"
                    x1="14.9736"
                    x2="20.211"
                    y1="10.1444"
                    y2="23.2923">
                    <stop stopColor="#3CCBF4"/>
                    <stop
                        offset="1"
                        stopColor="#2892DF"/>
                </linearGradient>
            </defs>
        </SvgIcon>);
}