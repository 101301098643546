﻿import { DataTable, DataTableActions, DataTableColumn, DataTableColumnRenderProps, DataTableFetchItemsResult, DataTableSort, DeferredFilter, EmptyMessageText, EnumValuesFilter, InlineItems, Optional, optionalTableCell, TimeFormatter, TimeRangeFilter, useGetOperation, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React, { useRef } from "react";
import { Contract, CustomerConsoleAppUrlHelper, Entity, entityModelStore, ItemSelectionHelper, KubernetesController, PagedEntityFilter, PagedEntityFilterEntityIdPage, ScopeTenantFilter, StorageHelper, tenantModelStore, TimeRangeHelper, useEntityTypeNameTranslator, useRiskPolicyTranslator } from "../../../../../../common";
import { useKubernetesAdmissionControllerResourceOperationTranslator, useKubernetesAdmissionControllerRiskPolicyResultTranslator } from "../../hooks";
import { ProfileCategory, RiskPolicyDataChart, TenantCell } from "./components";

export function KubernetesClusterAdmissionControllerEvents() {
    const dataTableActionsRef = useRef<DataTableActions>();

    const getEventFiltersResponsePromise =
        useGetOperation<Contract.KubernetesControllerGetKubernetesClusterAdmissionControllerEventFiltersResponse>(
            KubernetesClusterAdmissionControllerEvents,
            () => KubernetesController.getKubernetesClusterAdmissionControllerEventFilters(new Contract.KubernetesControllerGetKubernetesClusterAdmissionControllerEventFiltersRequest()));

    const fetchKubernetesClusterAdmissionControllerEvents =
        async (filterMap: Dictionary<any>, sort: Optional<DataTableSort>, skip: number, limit: number) => {
            const { admissionControllerEventModelPage } =
                await KubernetesController.getKubernetesClusterAdmissionControllerEventModelPage(
                    new Contract.KubernetesControllerGetKubernetesClusterAdmissionControllerEventModelPageRequest(
                        new Contract.KubernetesControllerGetKubernetesClusterAdmissionControllerEventModelPageRequestFilters(
                            ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.KubernetesControllerClusterAdmissionControllerEventProperty.ClusterReference]),
                            ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.KubernetesControllerClusterAdmissionControllerEventProperty.IdentityReference]),
                            ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.KubernetesControllerClusterAdmissionControllerEventProperty.OwnerResourceReference]),
                            ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.KubernetesControllerClusterAdmissionControllerEventProperty.ResourceReference]),
                            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.KubernetesControllerClusterAdmissionControllerEventProperty.ResourceOperation]),
                            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.KubernetesControllerClusterAdmissionControllerEventProperty.ResourceTypeName]),
                            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.KubernetesControllerClusterAdmissionControllerEventProperty.RiskPolicyConfigurationTypeName]),
                            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.KubernetesControllerClusterAdmissionControllerEventProperty.RiskPolicyResult]),
                            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.KubernetesControllerClusterAdmissionControllerEventProperty.TenantId]),
                            TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(dataTableActionsRef.current?.getFiltersTime(), filterMap[Contract.KubernetesControllerClusterAdmissionControllerEventProperty.Time]),
                            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.KubernetesControllerClusterAdmissionControllerEventProperty.ViolationRiskPolicyConfigurationTypeName])),
                        limit,
                        skip));
            return new DataTableFetchItemsResult(
                { count: admissionControllerEventModelPage.count! },
                admissionControllerEventModelPage.items,
                !admissionControllerEventModelPage.hasMore);
        };

    async function getExportData(_exportData: any, items: Contract.KubernetesClusterAdmissionControllerEventModel[]): Promise<ExportData> {
        const entityModels =
            await entityModelStore.get(
                _(items).
                    flatMap(
                        item => [
                            item.clusterReference,
                            item.identityReference,
                            item.ownerResourceReference,
                            item.resourceReference
                        ]).
                    filter().
                    as<string>().
                    value());

        const tenantModels =
            await tenantModelStore.get(
                _.map(
                    items,
                    item => item.tenantId));

        return {
            entityModelMap:
                _.keyBy(
                    entityModels,
                    entityModel => entityModel.id),
            tenantModelMap:
                _.keyBy(
                    tenantModels,
                    tenantModel => tenantModel.configuration.id)
        };
    }

    const makeEntityEventPropertyPage =
        (eventProperty: Contract.KubernetesControllerClusterAdmissionControllerEventProperty) =>
            async (searchText: Optional<string>, skip: number, limit: number, data?: any) => {
                const { kubernetesAdmissionControllerEventFilterItemPage } =
                    await KubernetesController.getKubernetesControllerGetKubernetesClusterAdmissionControllerEventFilterItemPage(
                        new Contract.KubernetesControllerGetKubernetesClusterAdmissionControllerEventFilterItemPageRequest(
                            limit,
                            eventProperty,
                            searchText,
                            skip)) as Contract.KubernetesControllerGetKubernetesClusterAdmissionControllerEventFilterItemPageResponse<string>;

                return new PagedEntityFilterEntityIdPage(
                    kubernetesAdmissionControllerEventFilterItemPage.count ?? data!.count,
                    kubernetesAdmissionControllerEventFilterItemPage.emptyValue,
                    kubernetesAdmissionControllerEventFilterItemPage.items,
                    () => ({
                        count: kubernetesAdmissionControllerEventFilterItemPage.count ?? data!.count
                    }));
            };

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const kubernetesAdmissionControllerResourceOperationTranslator = useKubernetesAdmissionControllerResourceOperationTranslator();
    const kubernetesAdmissionControllerRiskPolicyResultTranslator = useKubernetesAdmissionControllerRiskPolicyResultTranslator();
    const riskPolicyTranslator = useRiskPolicyTranslator();
    const localization =
        useLocalization(
            "views.customer.kubernetes.kubernetesClusterAdmissionControllerEvents",
            () => ({
                columns: {
                    clusterReference: "Cluster",
                    identityReference: "Identity",
                    ownerResourceReference: "Owner Resource",
                    resourceOperation: "Action",
                    resourceReference: "Resource",
                    resourceTypeName: "Resource Type",
                    riskPolicyDatas: "Policies Evaluated",
                    riskPolicyResult: "Result",
                    tenantId: "Account",
                    time: "Time",
                    violationRiskPolicyDatas: "Policy Violations"
                },
                empty: {
                    withFilter: "No Matching Events",
                    withoutFilter: "No Events"
                }
            }));

    return (
        <DataTable
            actionsRef={dataTableActionsRef}
            columnOptions={{
                orderOptions: {
                    enabled: true,
                    persistenceStorageItem: StorageHelper.customerKubernetesClusterAdmissionControllerEventsColumnOrder
                },
                resizable: true,
                selectorOptions: {
                    enabled: true,
                    persistenceStorageItem: StorageHelper.customerKubernetesClusterAdmissionControllerEventsColumnSelector
                }
            }}
            emptyMessageOptions={{
                emptyMessageText:
                    new EmptyMessageText(
                        localization.empty.withoutFilter(),
                        localization.empty.withFilter())
            }}
            exportOptions={{
                fileNamePrefix: "KubernetesClusterAdmissionControllerEvents",
                getData: getExportData
            }}
            fetchItems={fetchKubernetesClusterAdmissionControllerEvents}
            filtersOptions={{
                persist: {
                    visibilityStorageItem: StorageHelper.customerKubernetesClusterAdmissionControllerEventsFilters
                }
            }}
            getItemId={item => item.id}
            rowOptions={{
                getUrl:
                    (item: Contract.KubernetesClusterAdmissionControllerEventModel) =>
                        CustomerConsoleAppUrlHelper.getKubernetesClusterAdmissionControllerEventProfileHashUrl(
                            item.event.id,
                            StorageHelper.customerKubernetesClusterAdmissionControllerEventsProfileSelectedTab.getValue() as ProfileCategory)
            }}
            sortOptions={{ enabled: false }}>
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.KubernetesClusterAdmissionControllerEventModel) => ({
                            [localization.columns.time()]: TimeFormatter.shortDateTime(item.event.time)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <DeferredFilter
                                promiseOrGetPromise={getEventFiltersResponsePromise}
                                title={localization.columns.time()}>
                                {getEventFiltersResponse =>
                                    <TimeRangeFilter
                                        emptyValue={getEventFiltersResponse.eventFilters.timeRange.emptyValue}
                                        placeholder={localization.columns.time()}
                                        timeRange={TimeRangeHelper.getTimeRangeFilterRange(getEventFiltersResponse.eventFilters.timeRange.timeRange)}/>}
                            </DeferredFilter>
                    }
                }}
                id={Contract.KubernetesControllerClusterAdmissionControllerEventProperty.Time}
                itemProperty={(item: Contract.KubernetesClusterAdmissionControllerEventModel) => TimeFormatter.mediumDateTime(item.event.time)}
                title={localization.columns.time()}/>
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.KubernetesClusterAdmissionControllerEventModel) => ({
                            [localization.columns.resourceOperation()]: kubernetesAdmissionControllerResourceOperationTranslator((item.event as Contract.KubernetesClusterAdmissionControllerEvent).resourceOperation)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        default: true,
                        element:
                            <EnumValuesFilter
                                enumType={Contract.KubernetesAdmissionControllerResourceOperation}
                                enumTypeTranslator={kubernetesAdmissionControllerResourceOperationTranslator}
                                placeholder={localization.columns.resourceOperation()}/>
                    }
                }}
                id={Contract.KubernetesControllerClusterAdmissionControllerEventProperty.ResourceOperation}
                itemProperty={
                    (item: Contract.KubernetesClusterAdmissionControllerEventModel) =>
                        kubernetesAdmissionControllerResourceOperationTranslator((item.event as Contract.KubernetesClusterAdmissionControllerEvent).resourceOperation)}
                title={localization.columns.resourceOperation()}/>
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.KubernetesClusterAdmissionControllerEventModel) => ({
                            [localization.columns.resourceTypeName()]: entityTypeNameTranslator((item.event as Contract.KubernetesClusterAdmissionControllerEvent).resourceTypeName)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <DeferredFilter
                                promiseOrGetPromise={getEventFiltersResponsePromise}
                                title={localization.columns.resourceTypeName()}>
                                {getEventFiltersResponse =>
                                    <ValuesFilter placeholder={localization.columns.resourceTypeName()}>
                                        {_.map(
                                            getEventFiltersResponse.eventFilters.resourceTypeNameItems.items,
                                            resourceTypeName =>
                                                <ValuesFilterItem
                                                    key={resourceTypeName}
                                                    title={
                                                        entityTypeNameTranslator(
                                                            resourceTypeName,
                                                            {
                                                                includeServiceName: false
                                                            })}
                                                    value={resourceTypeName}/>)}
                                    </ValuesFilter>}
                            </DeferredFilter>
                    }
                }}
                id={Contract.KubernetesControllerClusterAdmissionControllerEventProperty.ResourceTypeName}
                itemProperty={
                    (item: Contract.KubernetesClusterAdmissionControllerEventModel) =>
                        entityTypeNameTranslator(
                            (item.event as Contract.KubernetesClusterAdmissionControllerEvent).resourceTypeName,
                            {
                                includeServiceName: false
                            })}
                title={localization.columns.resourceTypeName()}/>
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.KubernetesClusterAdmissionControllerEventModel, exportData: ExportData) => ({
                            [localization.columns.resourceReference()]: exportData.entityModelMap[item.resourceReference].entity.displayReference
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        default: true,
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={makeEntityEventPropertyPage(Contract.KubernetesControllerClusterAdmissionControllerEventProperty.ResourceReference)}
                                placeholder={localization.columns.resourceReference()}/>
                    }
                }}
                id={Contract.KubernetesControllerClusterAdmissionControllerEventProperty.ResourceReference}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.KubernetesClusterAdmissionControllerEventModel>) =>
                        <Entity
                            entityIdOrModel={item.resourceReference}
                            variant={"iconText"}/>}
                title={localization.columns.resourceReference()}/>
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.KubernetesClusterAdmissionControllerEventModel, exportData: ExportData) => ({
                            [localization.columns.clusterReference()]: exportData.entityModelMap[item.clusterReference].entity.displayReference
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        default: true,
                        element:
                            <PagedEntityFilter
                                emptyValueOptions={{ enabled: false }}
                                getEntityIdPage={makeEntityEventPropertyPage(Contract.KubernetesControllerClusterAdmissionControllerEventProperty.ClusterReference)}
                                placeholder={localization.columns.clusterReference()}/>
                    }
                }}
                id={Contract.KubernetesControllerClusterAdmissionControllerEventProperty.ClusterReference}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.KubernetesClusterAdmissionControllerEventModel>) =>
                        <Entity
                            entityIdOrModel={item.clusterReference}
                            variant="iconText"/>}
                title={localization.columns.clusterReference()}/>
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.KubernetesClusterAdmissionControllerEventModel, exportData: ExportData) => ({
                            [localization.columns.tenantId()]: exportData.tenantModelMap[item.tenantId].configuration.name
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        default: true,
                        element:
                            <DeferredFilter
                                promiseOrGetPromise={getEventFiltersResponsePromise}
                                title={localization.columns.tenantId()}>
                                {getEventFiltersResponse =>
                                    <ScopeTenantFilter
                                        placeholder={localization.columns.tenantId()}
                                        tenantIds={getEventFiltersResponse.eventFilters.tenantIdItems.items}/>}
                            </DeferredFilter>
                    }
                }}
                id={Contract.KubernetesControllerClusterAdmissionControllerEventProperty.TenantId}
                render={TenantCell}
                title={localization.columns.tenantId()}/>
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.KubernetesClusterAdmissionControllerEventModel) => ({
                            [localization.columns.riskPolicyResult()]: kubernetesAdmissionControllerRiskPolicyResultTranslator((item.event as Contract.KubernetesClusterAdmissionControllerEvent).riskPolicyResult)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        default: true,
                        element:
                            <EnumValuesFilter
                                enumType={Contract.KubernetesAdmissionControllerRiskPolicyResult}
                                enumTypeTranslator={kubernetesAdmissionControllerRiskPolicyResultTranslator}
                                placeholder={localization.columns.riskPolicyResult()}/>
                    }
                }}
                id={Contract.KubernetesControllerClusterAdmissionControllerEventProperty.RiskPolicyResult}
                itemProperty={
                    (item: Contract.KubernetesClusterAdmissionControllerEventModel) =>
                        kubernetesAdmissionControllerRiskPolicyResultTranslator((item.event as Contract.KubernetesClusterAdmissionControllerEvent).riskPolicyResult)}
                title={localization.columns.riskPolicyResult()}/>
            <DataTableColumn
                cellMinWidth={136}
                filterOptions={{
                    itemOrItems: {
                        default: true,
                        element:
                            <DeferredFilter
                                promiseOrGetPromise={getEventFiltersResponsePromise}
                                title={localization.columns.violationRiskPolicyDatas()}>
                                {getEventFiltersResponse =>
                                    <ValuesFilter
                                        emptyValueOptions={{ enabled: true }}
                                        placeholder={localization.columns.violationRiskPolicyDatas()}>
                                        {_.map(
                                            getEventFiltersResponse.eventFilters.violationRiskPolicyConfigurationTypeNames.items,
                                            violationRiskPolicyConfigurationTypeName =>
                                                <ValuesFilterItem
                                                    key={violationRiskPolicyConfigurationTypeName}
                                                    title={riskPolicyTranslator(violationRiskPolicyConfigurationTypeName).title}
                                                    value={violationRiskPolicyConfigurationTypeName}/>)}
                                    </ValuesFilter>}
                            </DeferredFilter>
                    }
                }}
                id={Contract.KubernetesControllerClusterAdmissionControllerEventProperty.ViolationRiskPolicyConfigurationTypeName}
                render={
                    optionalTableCell<Contract.KubernetesClusterAdmissionControllerEventModel>(
                        item =>
                            _.isEmpty(item.violationRiskPolicyDatas)
                                ? undefined
                                : <RiskPolicyDataChart riskPolicyDatas={item.violationRiskPolicyDatas}/>)}
                title={localization.columns.violationRiskPolicyDatas()}/>
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        default: true,
                        element:
                            <DeferredFilter
                                promiseOrGetPromise={getEventFiltersResponsePromise}
                                title={localization.columns.riskPolicyDatas()}>
                                {getEventFiltersResponse =>
                                    <ValuesFilter placeholder={localization.columns.riskPolicyDatas()}>
                                        {_.map(
                                            getEventFiltersResponse.eventFilters.riskPolicyConfigurationTypeNames.items,
                                            riskPolicyConfigurationTypeName =>
                                                <ValuesFilterItem
                                                    key={riskPolicyConfigurationTypeName}
                                                    title={riskPolicyTranslator(riskPolicyConfigurationTypeName).title}
                                                    value={riskPolicyConfigurationTypeName}/>)}
                                    </ValuesFilter>}
                            </DeferredFilter>
                    }
                }}
                id={Contract.KubernetesControllerClusterAdmissionControllerEventProperty.RiskPolicyConfigurationTypeName}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.KubernetesClusterAdmissionControllerEventModel>) =>
                        <InlineItems
                            items={
                                _((item.event as Contract.KubernetesClusterAdmissionControllerEvent).riskPolicyDatas).
                                    map((riskPolicyData: Contract.KubernetesClusterAdmissionControllerEventRiskPolicyData) => riskPolicyTranslator(riskPolicyData.configurationTypeName).title).
                                    orderBy().
                                    value()}
                            variant="itemPlusItemCount"/>}
                title={localization.columns.riskPolicyDatas()}/>
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.KubernetesClusterAdmissionControllerEventModel, exportData: ExportData) => ({
                            [localization.columns.ownerResourceReference()]:
                                _.isNil(item.ownerResourceReference)
                                    ? "-"
                                    : exportData.entityModelMap[item.ownerResourceReference].entity.displayReference
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={makeEntityEventPropertyPage(Contract.KubernetesControllerClusterAdmissionControllerEventProperty.OwnerResourceReference)}
                                placeholder={localization.columns.ownerResourceReference()}/>
                    }
                }}
                id={Contract.KubernetesControllerClusterAdmissionControllerEventProperty.OwnerResourceReference}
                render={
                    optionalTableCell<Contract.KubernetesClusterAdmissionControllerEventModel>(
                        item =>
                            _.isNil(item.ownerResourceReference)
                                ? undefined
                                : <Entity
                                    entityIdOrModel={item.ownerResourceReference}
                                    variant="iconText"/>)}
                title={localization.columns.ownerResourceReference()}/>
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.KubernetesClusterAdmissionControllerEventModel, exportData: ExportData) => ({
                            [localization.columns.identityReference()]:
                                _.isNil(item.identityReference)
                                    ? "-"
                                    : exportData.entityModelMap[item.identityReference].entity.displayReference
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={makeEntityEventPropertyPage(Contract.KubernetesControllerClusterAdmissionControllerEventProperty.IdentityReference)}
                                placeholder={localization.columns.identityReference()}/>
                    }
                }}
                id={Contract.KubernetesControllerClusterAdmissionControllerEventProperty.IdentityReference}
                render={
                    optionalTableCell<Contract.KubernetesClusterAdmissionControllerEventModel>(
                        item =>
                            _.isNil(item.identityReference)
                                ? undefined
                                : <Entity
                                    entityIdOrModel={item.identityReference}
                                    variant="iconText"/>)}
                title={localization.columns.identityReference()}/>
        </DataTable>);
}

type ExportData = {
    entityModelMap: Dictionary<Contract.EntityModel>;
    tenantModelMap: Dictionary<Contract.TenantModel>;
};