import { Action0, AnalyticsEventActionType, makeContextProvider, Menu, Message, useLocalization, useTrackAnalytics } from "@infrastructure";
import { Stack } from "@mui/material";
import _ from "lodash";
import React, { Dispatch, Fragment, SetStateAction, useMemo, useState } from "react";
import { StarAction } from "..";
import { Contract, RiskType } from "../../../../../../common";
import { RiskHelper } from "../../utilities";
import { useDefinition } from "./hooks";

export type ActionsProps = {
    reloadRiskModel: (status?: Contract.RiskStatus) => Promise<void>;
    riskModel: Contract.RiskModel;
    riskType: RiskType;
    triggerAuditEventsChange?: Action0;
    triggerChange?: Action0;
    variant?: "profile" | "table";
};

export class ActionsContext {
    constructor(
        public setDialogType: Dispatch<SetStateAction<ActionDialogType | undefined>>,
        public onCloseDialog: (riskModel: Contract.RiskModel, status: Contract.RiskStatus, canceled?: boolean) => Promise<void>,
        public reloadRiskModel: (status?: Contract.RiskStatus) => Promise<void>,
        public triggerAuditEventsChange?: Action0) {
    }
}

export const [useActionsContext, , useActionsContextProvider] = makeContextProvider<ActionsContext>();

export function Actions({ reloadRiskModel, riskModel, riskType, triggerAuditEventsChange, triggerChange, variant = "profile" }: ActionsProps) {
    const [dialogType, setDialogType] = useState<ActionDialogType>();

    const trackAnalytics = useTrackAnalytics();
    async function onCloseDialog(riskModel: Contract.RiskModel, status: Contract.RiskStatus, canceled?: boolean) {
        setDialogType(undefined);
        if (canceled) {
            return;
        }

        triggerChange?.();

        trackAnalytics(
            AnalyticsEventActionType.RiskStatusUpdate,
            {
                ...RiskHelper.getRiskAnalyticsPropertyNameToValueMap(riskModel),
                "Risk New Status": status
            });

        await reloadRiskModel(riskModel.risk.status);
    }

    const [, , ContextProvider] =
        useActionsContextProvider(
            () =>
                new ActionsContext(
                    setDialogType,
                    onCloseDialog,
                    reloadRiskModel,
                    triggerAuditEventsChange));

    return (
        <ContextProvider>
            <Core
                dialogType={dialogType}
                riskModel={riskModel}
                riskType={riskType}
                triggerChange={triggerChange}
                variant={variant}/>
        </ContextProvider>);
}

type CoreProps =
    Pick<ActionsProps, "riskModel" | "riskType" | "triggerChange">
    & {
        dialogType?: ActionDialogType;
        variant: "profile" | "table";
    };

function Core({ dialogType, riskModel, riskType, triggerChange, variant }: CoreProps) {
    const [updateError, setUpdateError] = useState(false);
    const definition = useDefinition(riskType, riskModel);
    const { reloadRiskModel } = useActionsContext();
    const riskModels =
        useMemo(
            () => [riskModel],
            [riskModel]);

    const localization =
        useLocalization(
            "views.customer.risks.actions.core",
            () => ({
                error: "Failed to update starred"
            }));
    return (
        <Fragment>
            {!_.isNil(dialogType) && definition.getItemDialog(dialogType)}
            <Stack
                alignItems="center"
                direction="row"
                spacing={1.5}>
                {variant === "table" &&
                    <Stack
                        direction="row"
                        spacing={1}>
                        {updateError &&
                            <Message
                                level="error"
                                sx={{ fontSize: "18px" }}
                                title={localization.error()}
                                variant="minimal"/>}
                        {definition.additionalElements}
                        <StarAction
                            riskModels={riskModels}
                            onError={() => setUpdateError(true)}
                            onStart={() => setUpdateError(false)}
                            onToggle={
                                async () => {
                                    triggerChange?.();
                                    await reloadRiskModel();
                                }}/>
                    </Stack>}
                <Menu itemsOrGetItems={definition.menuItems}/>
            </Stack>
        </Fragment>);
}

export enum ActionDialogType {
    Close = "close",
    Ignore = "ignore",
    InsertRiskNote = "insertRiskNote",
    Jira = "jira",
    Reopen = "reopen",
    SendRisksMail = "sendRisksMail",
    SendRisksMailPerOwner = "SendRisksMailPerOwner",
    SendRiskWebhook = "sendRiskWebhook",
    ServiceNow = "serviceNow"
}