import { CheckboxField, StringHelper, useExecuteOperation, useLocalization } from "@infrastructure";
import { Box, Stack } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { AuditEventController, Contract, LicensingHelper, RadioGroup, TypeHelper, useTheme } from "../../../../../../../../../../../../common";
import { useAddOrEditContext, useSetAddOrEditContext } from "../../AddOrEdit";
import { CategoryItem } from "./components";

type AuditEventTypesItem = {
    setValidation: (valid: boolean) => void;
};

export function AuditEventTypesItem({ setValidation }: AuditEventTypesItem) {
    const [{ typeNames }] =
        useExecuteOperation(
            AuditEventTypesItem,
            () => AuditEventController.getAuditEventTypeNames());

    const { automationRuleAuditEventTypeNames, automationRuleIncludeSystemIdentity } = useAddOrEditContext();
    const setAddOrEditContext = useSetAddOrEditContext();

    const localization =
        useLocalization(
            "views.customer.configuration.automations.auditEventAutomations.addOrEdit.auditEventTypesItem",
            () => ({
                includeSystemIdentity: "Include system generated actions",
                selection: {
                    all: "All (existing and future actions)",
                    some: "Select specific actions"
                }
            }));

    const [all, setAll] = useState(_.isNil(automationRuleAuditEventTypeNames));
    const [includeSystemIdentity, setIncludeSystemIdentity] = useState(automationRuleIncludeSystemIdentity ?? false);
    const categoryToTypeNamesMap =
        useMemo(
            () =>
                _.groupBy(
                    typeNames,
                    typeName => {
                        if (TypeHelper.extendOrImplement(typeName, Contract.TypeNames.PermissionEligibilityAuditEvent)) {
                            return AuditEventCategory.PermissionEligibilities;
                        }
                        if (TypeHelper.extendOrImplement(typeName, Contract.TypeNames.PermissionRequestAuditEvent)) {
                            return AuditEventCategory.PermissionRequests;
                        }
                        return AuditEventCategory.General;
                    }),
            []);
    const [categoryToSelectedTypeNamesMap, setCategoryToSelectedTypeNamesMap] =
        useState(
            _.mapValues(
                categoryToTypeNamesMap,
                typeNames =>
                    _.filter(
                        typeNames,
                        typeName => _.includes(automationRuleAuditEventTypeNames, typeName))));
    const [selectedTypeNames, setSelectedTypeNames] = useState(automationRuleAuditEventTypeNames);

    useEffect(
        () =>
            setValidation(
                _.isNil(selectedTypeNames) ||
                !_.isEmpty(selectedTypeNames)),
        [selectedTypeNames]);

    useEffect(
        () => {
            setAddOrEditContext(
                addOrEditContext => ({
                    ...addOrEditContext,
                    automationRuleAuditEventTypeNames: selectedTypeNames,
                    automationRuleIncludeSystemIdentity: includeSystemIdentity
                }));
        },
        [includeSystemIdentity, selectedTypeNames]);

    const theme = useTheme();
    return (
        <Stack sx={{ overflow: "hidden" }}>
            <Stack>
                <Box>
                    <RadioGroup
                        items={[
                            {
                                label: localization.selection.all(),
                                value: true
                            },
                            {
                                label: localization.selection.some(),
                                value: false
                            }
                        ]}
                        selectedValue={all}
                        sx={{ marginBottom: 0 }}
                        onChange={
                            all => {
                                all = StringHelper.isTrue(all);
                                setAll(all);
                                if (all) {
                                    setSelectedTypeNames(undefined);
                                } else {
                                    setSelectedTypeNames(_.flatMap(categoryToSelectedTypeNamesMap));
                                }
                            }}/>
                </Box>
                {!all &&
                    <Stack
                        spacing={1}
                        sx={{ maxWidth: "50%" }}>
                        {_([AuditEventCategory.General]).
                            concatIf(
                                LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.PermissionManagement),
                                AuditEventCategory.PermissionRequests,
                                AuditEventCategory.PermissionEligibilities).
                            map(
                                category =>
                                    <CategoryItem
                                        category={category}
                                        disabled={all}
                                        key={category}
                                        selectedTypeNames={categoryToSelectedTypeNamesMap[category]}
                                        typeNames={categoryToTypeNamesMap[category]}
                                        onSelectedTypeNamesChanged={
                                            (typeNames: string[]) => {
                                                const updatedCategoryToSelectedTypeNamesMap = {
                                                    ...categoryToSelectedTypeNamesMap,
                                                    [category]: typeNames
                                                };
                                                setCategoryToSelectedTypeNamesMap(updatedCategoryToSelectedTypeNamesMap);
                                                setSelectedTypeNames(_.flatMap(updatedCategoryToSelectedTypeNamesMap));
                                            }}/>).
                            value()}
                    </Stack>}
            </Stack>
            <Stack
                alignItems="center"
                direction="row"
                sx={{ padding: theme.spacing(1, 0) }}>
                <CheckboxField
                    checked={includeSystemIdentity}
                    onChange={() => setIncludeSystemIdentity(!includeSystemIdentity)}>
                    {localization.includeSystemIdentity()}
                </CheckboxField>
            </Stack>
        </Stack>);
}

export enum AuditEventCategory {
    General = "general",
    PermissionEligibilities = "permissionEligibilities",
    PermissionRequests = "permissionRequests"
}