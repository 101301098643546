import { Link, map, Message, useChangeEffect, useLocalization, useOrderedWizardContext } from "@infrastructure";
import { Stack } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Contract, CustomerConsoleAppUrlHelper, TenantController, useTheme } from "../../../../../../../../../../../../common";
import { useAddOrEditContext, useSetAddOrEditContext } from "../../index";
import { TrailSelector, TrailSelectorActions } from "./components";

export function TrailItem() {
    const {
        parentFolderId,
        tenantModel,
        tenantName,
        tenantPermissionTypes,
        tenantRegionSystemNames,
        tenantRoleArn,
        tenantTrailBucket,
        tenantTrails,
        tenantValidTrails
    } = useAddOrEditContext();
    const { setLoaded, setValid, useNextEffect } = useOrderedWizardContext();
    const setAddOrEditContext = useSetAddOrEditContext();

    useEffect(
        () => {
            setLoaded();
        },
        []);

    const tenantValidTrailsMap =
        useMemo(
            () =>
                _.keyBy(
                    tenantValidTrails,
                    tenantValidTrail => tenantValidTrail.id),
            [tenantValidTrails]);

    const [initialSelectedLambdaFunctionConfigurationInvokeTrailId, initialSelectedManagementTrailId, initialSelectedS3ObjectTrailId] =
        useMemo(
            () => {
                const managementTrail =
                    _.find(
                        tenantTrails,
                        tenantTrail =>
                            _.includes(tenantTrail.eventCategories, Contract.AwsTenantEventCategory.ManagementRead) &&
                            _.includes(tenantTrail.eventCategories, Contract.AwsTenantEventCategory.ManagementWrite));

                if (_.isNil(managementTrail) ||
                    _.isNil(tenantValidTrailsMap[managementTrail.id]) ||
                    tenantValidTrailsMap[managementTrail.id].eventCategoryToSelectorTypeMap[Contract.AwsCloudTrailTrailEventCategory.Management] !== Contract.AwsCloudTrailTrailEventSelectorType.All) {
                    return [undefined, undefined, undefined];
                } else {
                    const getDataEventCategoryTrail =
                        (eventCategory: Contract.AwsCloudTrailTrailEventCategory) => {
                            if (tenantValidTrailsMap[managementTrail!.id].eventCategoryToSelectorTypeMap[eventCategory] === Contract.AwsCloudTrailTrailEventSelectorType.All) {
                                return managementTrail!.id;
                            } else {
                                const eventCategoryTrails =
                                    _.filter(
                                        tenantTrails,
                                        tenantTrail =>
                                            !_.isNil(tenantValidTrailsMap[tenantTrail.id]) &&
                                            tenantValidTrailsMap[tenantTrail.id].eventCategoryToSelectorTypeMap[eventCategory] === Contract.AwsCloudTrailTrailEventSelectorType.All);
                                return _.size(eventCategoryTrails) === 1
                                    ? eventCategoryTrails[0].id
                                    : undefined;
                            }
                        };

                    const initialSelectedLambdaFunctionConfigurationInvokeTrailId = getDataEventCategoryTrail(Contract.AwsCloudTrailTrailEventCategory.LambdaFunctionConfigurationInvoke);
                    const initialSelectedS3ObjectTrailId = getDataEventCategoryTrail(Contract.AwsCloudTrailTrailEventCategory.S3Object);

                    return [initialSelectedLambdaFunctionConfigurationInvokeTrailId, managementTrail.id, initialSelectedS3ObjectTrailId];
                }
            },
            []);

    const [selectedLambdaFunctionConfigurationInvokeTrailId, setSelectedLambdaFunctionConfigurationInvokeTrailId] = useState<string | false | undefined>(initialSelectedLambdaFunctionConfigurationInvokeTrailId);
    const [selectedManagementTrailId, setSelectedManagementTrailId] =
        useState<string | false | undefined>(
            () => {
                if (_.isNil(initialSelectedManagementTrailId)) {
                    return _.isNil(tenantTrails)
                        ? undefined
                        : false;
                } else {
                    return initialSelectedManagementTrailId;
                }
            });
    const [selectedS3ObjectTrailId, setSelectedS3ObjectTrailId] = useState<string | false | undefined>(initialSelectedS3ObjectTrailId);

    const [selectedLambdaFunctionConfigurationInvokeTrail, selectedManagementTrail, selectedS3ObjectTrail] =
        useMemo(
            () => {
                function getSelectedTrail(trailId: string | false | undefined) {
                    return !_.isNil(trailId) && trailId !== false
                        ? tenantValidTrailsMap[trailId]
                        : undefined;
                }

                const selectedLambdaFunctionConfigurationInvokeTrail = getSelectedTrail(selectedLambdaFunctionConfigurationInvokeTrailId);
                const selectedManagementTrail = getSelectedTrail(selectedManagementTrailId);
                const selectedS3ObjectTrail = getSelectedTrail(selectedS3ObjectTrailId);

                return [selectedLambdaFunctionConfigurationInvokeTrail, selectedManagementTrail, selectedS3ObjectTrail];
            },
            [selectedLambdaFunctionConfigurationInvokeTrailId, selectedManagementTrailId, selectedS3ObjectTrailId]);

    const prevSelectedLambdaFunctionConfigurationInvokeTrailIdRef = useRef(selectedLambdaFunctionConfigurationInvokeTrailId);
    const [validateAwsTenantLambdaFunctionConfigurationInvokeTrailBucketResponse, setValidateAwsTenantLambdaFunctionConfigurationInvokeTrailBucketResponse] = useState<Contract.TenantControllerValidateAwsTenantTrailBucketResponse>();
    useChangeEffect(
        () => {
            if (selectedLambdaFunctionConfigurationInvokeTrailId !== selectedManagementTrailId &&
                selectedLambdaFunctionConfigurationInvokeTrailId !== selectedS3ObjectTrailId &&
                selectedS3ObjectTrailId !== selectedManagementTrailId) {
                if (selectedLambdaFunctionConfigurationInvokeTrail?.eventCategoryToSelectorTypeMap[Contract.AwsCloudTrailTrailEventCategory.S3Object] === Contract.AwsCloudTrailTrailEventSelectorType.All) {
                    setSelectedS3ObjectTrailId(selectedLambdaFunctionConfigurationInvokeTrailId);
                    setValidateAwsTenantS3ObjectTrailBucketResponse(validateAwsTenantLambdaFunctionConfigurationInvokeTrailBucketResponse);
                } else if (selectedS3ObjectTrail?.id === prevSelectedLambdaFunctionConfigurationInvokeTrailIdRef.current) {
                    setSelectedS3ObjectTrailId(undefined);
                    setValidateAwsTenantS3ObjectTrailBucketResponse(undefined);
                }
            }

            prevSelectedLambdaFunctionConfigurationInvokeTrailIdRef.current = selectedLambdaFunctionConfigurationInvokeTrailId;
        },
        [selectedLambdaFunctionConfigurationInvokeTrail, selectedLambdaFunctionConfigurationInvokeTrailId, validateAwsTenantLambdaFunctionConfigurationInvokeTrailBucketResponse]);

    const prevSelectedManagementTrailIdRef = useRef(selectedManagementTrailId);
    const [validateAwsTenantManagementTrailBucketResponse, setValidateAwsTenantManagementTrailBucketResponse] = useState<Contract.TenantControllerValidateAwsTenantTrailBucketResponse>();
    useChangeEffect(
        () => {
            if (selectedManagementTrailId === false) {
                setSelectedLambdaFunctionConfigurationInvokeTrailId(undefined);
                setSelectedS3ObjectTrailId(undefined);
                setValidateAwsTenantLambdaFunctionConfigurationInvokeTrailBucketResponse(undefined);
                setValidateAwsTenantS3ObjectTrailBucketResponse(undefined);
            } else {
                if (selectedManagementTrail?.eventCategoryToSelectorTypeMap[Contract.AwsCloudTrailTrailEventCategory.LambdaFunctionConfigurationInvoke] === Contract.AwsCloudTrailTrailEventSelectorType.All) {
                    setSelectedLambdaFunctionConfigurationInvokeTrailId(selectedManagementTrailId);
                    setValidateAwsTenantLambdaFunctionConfigurationInvokeTrailBucketResponse(validateAwsTenantManagementTrailBucketResponse);
                } else if (selectedLambdaFunctionConfigurationInvokeTrail?.id === prevSelectedManagementTrailIdRef.current) {
                    setSelectedLambdaFunctionConfigurationInvokeTrailId(undefined);
                    setValidateAwsTenantLambdaFunctionConfigurationInvokeTrailBucketResponse(undefined);
                }

                if (selectedManagementTrail?.eventCategoryToSelectorTypeMap[Contract.AwsCloudTrailTrailEventCategory.S3Object] === Contract.AwsCloudTrailTrailEventSelectorType.All) {
                    setSelectedS3ObjectTrailId(selectedManagementTrailId);
                    setValidateAwsTenantS3ObjectTrailBucketResponse(validateAwsTenantManagementTrailBucketResponse);
                } else if (selectedS3ObjectTrail?.id === prevSelectedManagementTrailIdRef.current) {
                    setSelectedS3ObjectTrailId(undefined);
                    setValidateAwsTenantS3ObjectTrailBucketResponse(undefined);
                }
            }

            prevSelectedManagementTrailIdRef.current = selectedManagementTrailId;
        },
        [selectedManagementTrail, selectedManagementTrailId, validateAwsTenantManagementTrailBucketResponse]);

    const prevSelectedS3ObjectTrailIdRef = useRef(selectedS3ObjectTrailId);
    const [validateAwsTenantS3ObjectTrailBucketResponse, setValidateAwsTenantS3ObjectTrailBucketResponse] = useState<Contract.TenantControllerValidateAwsTenantTrailBucketResponse>();
    useChangeEffect(
        () => {
            if (selectedS3ObjectTrailId !== selectedManagementTrailId &&
                selectedS3ObjectTrailId !== selectedLambdaFunctionConfigurationInvokeTrailId &&
                selectedLambdaFunctionConfigurationInvokeTrailId !== selectedManagementTrailId) {
                if (selectedS3ObjectTrail?.eventCategoryToSelectorTypeMap[Contract.AwsCloudTrailTrailEventCategory.LambdaFunctionConfigurationInvoke] === Contract.AwsCloudTrailTrailEventSelectorType.All) {
                    setSelectedLambdaFunctionConfigurationInvokeTrailId(selectedS3ObjectTrailId);
                    setValidateAwsTenantLambdaFunctionConfigurationInvokeTrailBucketResponse(validateAwsTenantS3ObjectTrailBucketResponse);
                } else if (selectedLambdaFunctionConfigurationInvokeTrail?.id === prevSelectedS3ObjectTrailIdRef.current) {
                    setSelectedLambdaFunctionConfigurationInvokeTrailId(undefined);
                    setValidateAwsTenantLambdaFunctionConfigurationInvokeTrailBucketResponse(undefined);
                }
            }

            prevSelectedS3ObjectTrailIdRef.current = selectedS3ObjectTrailId;
        },
        [selectedS3ObjectTrail, selectedS3ObjectTrailId, validateAwsTenantS3ObjectTrailBucketResponse]);

    const [trailAccessDenied, validErrorValidateAwsTenantTrailBucketResponses] =
        useMemo(
            () => {
                if (validateAwsTenantManagementTrailBucketResponse?.result === Contract.AwsTenantManagerValidateTenantTrailBucketResult.TrailAccessDenied) {
                    return [true, undefined];
                }

                const validErrorResults = [
                    Contract.AwsTenantManagerValidateTenantTrailBucketResult.AccessDenied,
                    Contract.AwsTenantManagerValidateTenantTrailBucketResult.TenantDisconnected,
                    Contract.AwsTenantManagerValidateTenantTrailBucketResult.TenantNotExist
                ];

                const validErrorValidateAwsTenantTrailBucketResponses =
                    _<Contract.TenantControllerValidateAwsTenantTrailBucketResponse>([]).
                        concatIf(
                            _.includes(validErrorResults, validateAwsTenantLambdaFunctionConfigurationInvokeTrailBucketResponse?.result),
                            validateAwsTenantLambdaFunctionConfigurationInvokeTrailBucketResponse!).
                        concatIf(
                            _.includes(validErrorResults, validateAwsTenantManagementTrailBucketResponse?.result),
                            validateAwsTenantManagementTrailBucketResponse!).
                        concatIf(
                            _.includes(validErrorResults, validateAwsTenantS3ObjectTrailBucketResponse?.result),
                            validateAwsTenantS3ObjectTrailBucketResponse!).
                        uniqBy(({ trailBucketName }) => trailBucketName).
                        value();

                setAddOrEditContext(
                    addOrEditContext => ({
                        ...addOrEditContext,
                        validErrorValidateAwsTenantTrailBucketResponses
                    }));

                return [false, validErrorValidateAwsTenantTrailBucketResponses];
            },
            [validateAwsTenantLambdaFunctionConfigurationInvokeTrailBucketResponse, validateAwsTenantManagementTrailBucketResponse, validateAwsTenantS3ObjectTrailBucketResponse]);

    const duplicateEventCategoriesSelectedTrails =
        useMemo(
            () =>
                _<Contract.AwsCloudTrailTrail | undefined>([selectedLambdaFunctionConfigurationInvokeTrail, selectedManagementTrail, selectedS3ObjectTrail]).
                    uniq().
                    filter().
                    as<Contract.AwsCloudTrailTrail>().
                    flatMap(
                        selectedTrail =>
                            _(selectedTrail.eventCategoryToSelectorTypeMap).
                                keys().
                                map(
                                    eventCategory => ({
                                        eventCategory,
                                        trail: selectedTrail
                                    })).
                                value()).
                    groupBy(selectedTrailData => selectedTrailData.eventCategory).
                    pickBy(selectedTrailDatas => selectedTrailDatas.length > 1).
                    values().
                    flatMap().
                    as<{
                    eventCategory: Contract.AwsCloudTrailTrailEventCategory;
                    trail: Contract.AwsCloudTrailTrail;
                }>().
                    map(selectedTrailData => selectedTrailData.trail).
                    uniq().
                    value(),
            [selectedLambdaFunctionConfigurationInvokeTrailId, selectedManagementTrailId, selectedS3ObjectTrailId]);

    const [eventCategoryToBucketValidationExecutingMap, setEventCategoryToBucketValidationExecutingMap] = useState<Dictionary<boolean>>({});
    const bucketValidationExecuting =
        _.some(
            eventCategoryToBucketValidationExecutingMap,
            (bucketValidationExecuting, _) => bucketValidationExecuting);

    useEffect(
        () => {
            const valid =
                !bucketValidationExecuting &&
                !trailAccessDenied &&
                _.isEmpty(duplicateEventCategoriesSelectedTrails);

            setValid(valid);

            if (valid) {
                const tenantTrails: Contract.AwsTenantConfigurationTrail[] = [];

                const addTrail = (
                    trail: Contract.AwsCloudTrailTrail | undefined,
                    trailEventCategory: Contract.AwsCloudTrailTrailEventCategory) => {
                    if (_.isNil(trail)) {
                        return;
                    }

                    const tenantTrail =
                        _.find(
                            tenantTrails,
                            tenantTrail => tenantTrail.id === trail.id);
                    const eventCategories =
                        map(
                            trailEventCategory,
                            {
                                [Contract.AwsCloudTrailTrailEventCategory.LambdaFunctionConfigurationInvoke]:
                                    () => [Contract.AwsTenantEventCategory.LambdaFunctionConfigurationInvokeWrite],
                                [Contract.AwsCloudTrailTrailEventCategory.Management]:
                                    () => [Contract.AwsTenantEventCategory.ManagementRead, Contract.AwsTenantEventCategory.ManagementWrite],
                                [Contract.AwsCloudTrailTrailEventCategory.S3Object]:
                                    () => [Contract.AwsTenantEventCategory.S3ObjectRead, Contract.AwsTenantEventCategory.S3ObjectWrite]
                            });
                    if (_.isNil(tenantTrail)) {
                        tenantTrails.push(
                            new Contract.AwsTenantConfigurationTrail(
                                trail.arn!,
                                eventCategories,
                                trail.id));
                    } else {
                        tenantTrail.eventCategories.concat(eventCategories);
                    }
                };

                addTrail(
                    selectedLambdaFunctionConfigurationInvokeTrail,
                    Contract.AwsCloudTrailTrailEventCategory.LambdaFunctionConfigurationInvoke);
                addTrail(
                    selectedManagementTrail,
                    Contract.AwsCloudTrailTrailEventCategory.Management);
                addTrail(
                    selectedS3ObjectTrail,
                    Contract.AwsCloudTrailTrailEventCategory.S3Object);

                setAddOrEditContext(
                    addOrEditContext => ({
                        ...addOrEditContext,
                        tenantTrails
                    }));
            }
        },
        [
            bucketValidationExecuting,
            duplicateEventCategoriesSelectedTrails,
            selectedLambdaFunctionConfigurationInvokeTrailId,
            selectedManagementTrailId,
            selectedS3ObjectTrailId,
            validateAwsTenantLambdaFunctionConfigurationInvokeTrailBucketResponse,
            validateAwsTenantManagementTrailBucketResponse,
            validateAwsTenantS3ObjectTrailBucketResponse
        ]);

    const localization =
        useLocalization(
            "views.customer.scopes.hooks.useDefinition.hooks.useAwsDefinition.addOrEdit.trailItem",
            () => ({
                actions: {
                    save: {
                        error: {
                            add: "Failed to add account",
                            edit: "Failed to save account"
                        }
                    }
                },
                error: {
                    bucketAccessError: "Tenable can't read CloudTrail events. One or more of the selected S3 buckets isn't accessible due to missing permissions or encryption issues.",
                    duplicateEventCategories: "The following trails are syncing the same event types: {{duplicateEventCategoriesSelectedTrailDisplayNames}}",
                    noTrail: {
                        docsLink: "see documentation",
                        title: "It is not recommended to proceed without selecting CloudTrail. Some functionality may be limited. To read more about CloudTrail access, {{docsLink}}"
                    },
                    trailAccessDenied: "Missing permissions to CloudTrail service"
                },
                trails: {
                    lambdaFunctionConfigurationInvoke: {
                        subtitle: "Select one of the following trails that contain Lambda data events as an additional trail to be used by Tenable Cloud Security.",
                        title: "Lambda Data events"
                    },
                    management: {
                        subtitle: "Please select one of the following valid trails for Tenable Cloud Security to read activity logs from. Valid trails are enabled, multi-region and have management read/write events enabled.",
                        title: "Management events"
                    },
                    s3Object: {
                        subtitle: "Select one of the following trails that contain S3 data events as an additional trail to be used by Tenable Cloud Security.",
                        title: "S3 Data events"
                    }
                }
            }));

    useNextEffect(
        async () => {
            try {
                const tenantTrailArns =
                    _<string | undefined>([]).
                        concat(
                            selectedLambdaFunctionConfigurationInvokeTrail?.arn,
                            selectedManagementTrail?.arn,
                            selectedS3ObjectTrail?.arn).
                        filter().
                        as<string>().
                        uniq().
                        value();
                let updatedTenantModel: Contract.AwsTenantModel;
                if (!_.isNil(tenantModel)) {
                    const { tenantModel } =
                        await TenantController.updateAwsTenant(
                            new Contract.TenantControllerUpdateAwsTenantRequest(
                                tenantName!,
                                tenantPermissionTypes,
                                tenantRegionSystemNames,
                                tenantRoleArn!,
                                tenantTrailArns,
                                tenantTrailBucket));
                    updatedTenantModel = tenantModel;
                } else {
                    const { tenantModel } =
                        await TenantController.insertAwsTenant(
                            new Contract.TenantControllerInsertAwsTenantRequest(
                                tenantName!,
                                parentFolderId,
                                tenantPermissionTypes,
                                tenantRegionSystemNames,
                                tenantRoleArn!,
                                tenantTrailArns,
                                tenantTrailBucket));
                    updatedTenantModel = tenantModel;
                }

                setAddOrEditContext(
                    addOrEditContext => ({
                        ...addOrEditContext,
                        updatedTenantModel
                    }));
            } catch {
                return _.isNil(tenantModel)
                    ? localization.actions.save.error.add()
                    : localization.actions.save.error.edit();
            }
        },
        [selectedLambdaFunctionConfigurationInvokeTrail, selectedManagementTrail, selectedS3ObjectTrail]);

    const lambdaFunctionConfigurationInvokeTrailSelectorActionsRef = useRef<TrailSelectorActions>(null);
    const managementTrailSelectorActionsRef = useRef<TrailSelectorActions>(null);
    const s3ObjectTrailSelectorActionsRef = useRef<TrailSelectorActions>(null);

    const theme = useTheme();
    return (
        <Stack
            spacing={4}
            sx={{ maxWidth: theme.spacing(75) }}>
            <Stack
                spacing={4}
                sx={{ padding: theme.spacing(2) }}>
                <TrailSelector
                    actionsRef={managementTrailSelectorActionsRef}
                    eventCategory={Contract.AwsCloudTrailTrailEventCategory.Management}
                    selectedTrailId={selectedManagementTrailId}
                    setEventCategoryToBucketValidationExecutingMap={setEventCategoryToBucketValidationExecutingMap}
                    showNoTrailOption={true}
                    subtitle={localization.trails.management.subtitle()}
                    tenantValidTrails={tenantValidTrails}
                    title={localization.trails.management.title()}
                    onBucketValidationChanged={setValidateAwsTenantManagementTrailBucketResponse}
                    onSelectedTrailIdChanged={setSelectedManagementTrailId}/>
                <TrailSelector
                    actionsRef={s3ObjectTrailSelectorActionsRef}
                    disabled={
                        _.isNil(selectedManagementTrailId) ||
                        selectedManagementTrailId === false ||
                        _.isNil(validateAwsTenantManagementTrailBucketResponse) ||
                        validateAwsTenantManagementTrailBucketResponse?.result === Contract.AwsTenantManagerValidateTenantTrailBucketResult.TenantNotExist ||
                        selectedS3ObjectTrailId === selectedManagementTrailId}
                    eventCategory={Contract.AwsCloudTrailTrailEventCategory.S3Object}
                    selectedTrailId={selectedS3ObjectTrailId}
                    setEventCategoryToBucketValidationExecutingMap={setEventCategoryToBucketValidationExecutingMap}
                    subtitle={localization.trails.s3Object.subtitle()}
                    tenantValidTrails={tenantValidTrails}
                    title={localization.trails.s3Object.title()}
                    onBucketValidationChanged={setValidateAwsTenantS3ObjectTrailBucketResponse}
                    onSelectedTrailIdChanged={setSelectedS3ObjectTrailId}/>
                <TrailSelector
                    actionsRef={lambdaFunctionConfigurationInvokeTrailSelectorActionsRef}
                    disabled={
                        _.isNil(selectedManagementTrailId) ||
                        selectedManagementTrailId === false ||
                        _.isNil(validateAwsTenantManagementTrailBucketResponse) ||
                        validateAwsTenantManagementTrailBucketResponse?.result === Contract.AwsTenantManagerValidateTenantTrailBucketResult.TenantNotExist ||
                        selectedLambdaFunctionConfigurationInvokeTrailId === selectedManagementTrailId}
                    eventCategory={Contract.AwsCloudTrailTrailEventCategory.LambdaFunctionConfigurationInvoke}
                    selectedTrailId={selectedLambdaFunctionConfigurationInvokeTrailId}
                    setEventCategoryToBucketValidationExecutingMap={setEventCategoryToBucketValidationExecutingMap}
                    subtitle={localization.trails.lambdaFunctionConfigurationInvoke.subtitle()}
                    tenantValidTrails={tenantValidTrails}
                    title={localization.trails.lambdaFunctionConfigurationInvoke.title()}
                    onBucketValidationChanged={setValidateAwsTenantLambdaFunctionConfigurationInvokeTrailBucketResponse}
                    onSelectedTrailIdChanged={setSelectedLambdaFunctionConfigurationInvokeTrailId}/>
            </Stack>
            {!bucketValidationExecuting &&
                selectedManagementTrailId === false && (
                <Message
                    level="infoWarning"
                    title={localization.error.noTrail.title({
                        docsLink:
                                <Link
                                    urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsOnboardAwsCloudtrailLogsRelativeUrl()}
                                    variant="external">
                                    {localization.error.noTrail.docsLink()}
                                </Link>
                    })}
                    variant="standard"/>)}
            {!bucketValidationExecuting &&
                trailAccessDenied && (
                <Message
                    level="infoWarning"
                    title={localization.error.trailAccessDenied()}
                    variant="standard"/>)}
            {!bucketValidationExecuting &&
                !trailAccessDenied &&
                !_.isEmpty(duplicateEventCategoriesSelectedTrails) && (
                <Message
                    level="infoWarning"
                    title={
                        localization.error.duplicateEventCategories({
                            duplicateEventCategoriesSelectedTrailDisplayNames:
                                    _(duplicateEventCategoriesSelectedTrails).
                                        map(trail => trail.displayName).
                                        join(", ")
                        })}
                    variant="standard"/>)}
            {!bucketValidationExecuting &&
                !_.isEmpty(validErrorValidateAwsTenantTrailBucketResponses) && (
                <Message
                    level="info"
                    title={localization.error.bucketAccessError()}
                    variant="standard"/>)}
        </Stack>);
}