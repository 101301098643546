import { Link, useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { EntityExternalConsoleLink } from "../../components";
import { useGetGcpBigQueryDatasetRiskContext } from "../contexts";

export function useGcpBigQueryDatasetEncryptionDisabledRiskDefinition(riskModel: Contract.RiskModel) {
    const datasetEncryptionDisabledRiskModel = riskModel as Contract.GcpBigQueryDatasetEncryptionDisabledRiskModel;
    const risk = datasetEncryptionDisabledRiskModel.risk;
    const datasetModel = entityModelStore.useGet(risk.entityId) as Contract.GcpBigQueryDatasetModel;

    const getGcpBigQueryDatasetRiskContext = useGetGcpBigQueryDatasetRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.useGcpBigQueryDatasetEncryptionDisabledRiskDefinition",
            () => ({
                description: "{{dataset}} is not configured with encryption using CMEK",
                sections: {
                    resolution: {
                        step1: {
                            link: "Customer-managed Cloud KMS keys for BigQuery",
                            text: "Before you begin, make sure your encryption key is in a location that matches the location of your BigQuery dataset and BigQuery service account has sufficient permission to encrypt and decrypt using that key. See {{managedEncryptionLink}} for more information."
                        },
                        step2: "Setting a default Customer-managed encryption key (CMEK) for a data set ensures any tables created in future will use the specified CMEK if none other is provided. The default key does not apply to existing tables.",
                        step3: "Run the following command: bq update --default_kms_key projects/KMS_PROJECT_ID/locations/LOCATION/keyRings/KEY_RING/cryptoKeys/KEY --dataset DATASET_ID"
                    }
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            dataset:
                <Entity
                    entityIdOrModel={datasetModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={risk.entityId}
                key={risk.entityId}
                page={Contract.GcpConsolePage.Resource}/>,
            localization.sections.resolution.step1.text({
                managedEncryptionLink:
                    <Link
                        urlOrGetUrl={datasetEncryptionDisabledRiskModel.managedEncryptionDocumentationUrl}
                        variant="external">
                        {localization.sections.resolution.step1.link()}
                    </Link>
            }),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3()
        ],
        riskModel,
        () => {
            const datasetRiskContext = getGcpBigQueryDatasetRiskContext(datasetModel);
            return [
                datasetRiskContext.generalInformation,
                datasetRiskContext.sensitive,
                datasetRiskContext.accessLevel,
                datasetRiskContext.tables,
                datasetRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}