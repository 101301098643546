import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function CustomEntityAttributeIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                clipRule="evenodd"
                d="M19.8824 15.5481L14.1846 5.67938C14.0658 5.47356 13.8776 5.31679 13.6536 5.23717L8.75707 3.4961L6.29927 4.91511L5.3588 10.0262C5.31579 10.2599 5.35744 10.5013 5.47627 10.7072L11.174 20.5759C11.7263 21.5325 12.9495 21.8602 13.906 21.308L19.1503 18.2802C20.1069 17.7279 20.4346 16.5047 19.8824 15.5481ZM3.39182 9.66426C3.2628 10.3655 3.38773 11.0897 3.74422 11.7072L9.44194 21.5759C10.5465 23.4891 12.9929 24.1446 14.906 23.04L20.1503 20.0122C22.0635 18.9077 22.719 16.4613 21.6144 14.5481L15.9167 4.67938C15.5602 4.06193 14.9954 3.59162 14.3237 3.35275L9.42712 1.61168C8.87499 1.41536 8.26455 1.47105 7.75707 1.76405L5.29927 3.18306C4.79179 3.47606 4.43833 3.97687 4.33229 4.55318L3.39182 9.66426Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M10.5038 8.68518C10.0255 8.96132 9.86167 9.57291 10.1378 10.0512C10.414 10.5295 11.0255 10.6934 11.5038 10.4172C11.9821 10.1411 12.146 9.52949 11.8699 9.0512C11.5937 8.57291 10.9821 8.40903 10.5038 8.68518ZM8.40576 11.0512C7.57733 9.61632 8.06896 7.78155 9.50384 6.95312C10.9387 6.1247 12.7735 6.61632 13.6019 8.0512C14.4303 9.48608 13.9387 11.3209 12.5038 12.1493C11.069 12.9777 9.23419 12.4861 8.40576 11.0512Z"
                fillRule="evenodd"/>
        </SvgIcon>);
}