import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function CommonTenantTypeIcon(props: SvgIconProps) {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <path
                clipRule="evenodd"
                d="M5.25 6C5.11193 6 5 6.11193 5 6.25V19C5 19.5523 5.44772 20 6 20H17C17.5523 20 18 19.5523 18 19V6.375C18 6.16789 17.8321 6 17.625 6H17.25C16.6977 6 16.25 5.55228 16.25 5C16.25 4.44772 16.6977 4 17.25 4H17.625C18.9367 4 20 5.06332 20 6.375V19C20 20.6569 18.6569 22 17 22H6C4.34315 22 3 20.6569 3 19V6.25C3 5.00736 4.00736 4 5.25 4H5.5C6.05228 4 6.5 4.44772 6.5 5C6.5 5.55228 6.05228 6 5.5 6H5.25Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M7.5 4C7.5 2.89543 8.39543 2 9.5 2H13.5C14.6046 2 15.5 2.89543 15.5 4V6C15.5 7.10457 14.6046 8 13.5 8H9.5C8.39543 8 7.5 7.10457 7.5 6V4ZM13.5 4H9.5V6H13.5V4Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M7 11C7 10.4477 7.36631 10 7.81818 10H15.1818C15.6337 10 16 10.4477 16 11C16 11.5523 15.6337 12 15.1818 12H7.81818C7.36631 12 7 11.5523 7 11Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M7 15C7 14.4477 7.36631 14 7.81818 14H15.1818C15.6337 14 16 14.4477 16 15C16 15.5523 15.6337 16 15.1818 16H7.81818C7.36631 16 7 15.5523 7 15Z"
                fillRule="evenodd"/>
        </SvgIcon>);
}