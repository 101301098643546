import { Optional, useLocalization } from "@infrastructure";
import { SxProps, Typography } from "@mui/material";
import { TOptions } from "i18next";
import _ from "lodash";
import React from "react";
import { Contract, Entity, entityModelStore } from "../../../../..";
import { InlineEntitySelection } from "../..";

type ViewProps = {
    riskPolicyConfiguration?: Contract.AwsRoleTemplateMismatchRiskPolicyConfiguration;
    sx?: SxProps;
    templateTranslator: (options?: TOptions) => string;
};

export function View({ riskPolicyConfiguration, sx, templateTranslator }: ViewProps) {
    const templateRoleModel = entityModelStore.useGet(riskPolicyConfiguration?.templateRoleId) as Optional<Contract.AwsIamRoleModel>;

    const localization =
        useLocalization(
            "common.customRiskPolicy.awsRoleTemplateMismatchRiskPolicy.view",
            () => ({
                roleDeleted: "{{role}} (role deleted-finding closed)",
                roleMissing: "a reference role. Contact an admin for more details",
                templateRolePlaceholder: "this reference role"
            }));
    return (
        <Typography
            component="span"
            sx={sx}>
            {templateTranslator({
                roles:
                    <InlineEntitySelection
                        entityTypeName={Contract.TypeNames.AwsIamRole}
                        selection={{
                            entityBuiltInAttributeTypeNames: riskPolicyConfiguration?.roleBuiltInAttributeTypeNames,
                            entityCustomAttributeDefinitionIds: riskPolicyConfiguration?.roleCustomAttributeDefinitionIds,
                            entityIds: riskPolicyConfiguration?.roleIds,
                            entityNamePattern: riskPolicyConfiguration?.roleNamePattern,
                            entityTags: riskPolicyConfiguration?.roleTags
                        }}/>,
                templateRole:
                    _.isNil(riskPolicyConfiguration)
                        ? <Typography
                            component="span"
                            sx={{
                                fontWeight: "bold",
                                textDecoration: "underline"
                            }}>
                            {localization.templateRolePlaceholder()}
                        </Typography>
                        : templateRoleModel!.unknown || templateRoleModel!.systemDeleted
                            ? <Typography
                                component="span"
                                sx={{
                                    fontWeight: "bold",
                                    textDecoration: "underline"
                                }}>
                                {templateRoleModel!.systemDeleted
                                    ? localization.roleDeleted({
                                        role:
                                            <Entity
                                                entityIdOrModel={templateRoleModel!}
                                                variant="text"/>
                                    })
                                    : localization.roleMissing()}
                            </Typography>
                            : <Entity
                                entityIdOrModel={templateRoleModel!}
                                variant="text"/>
            })}
        </Typography>);
}