import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useOciKmsVaultKeyVersionSourceTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useOciKmsVaultKeyVersionSourceTranslator",
            () => ({
                [Contract.TypeNames.OciKmsVaultKeyVersionSource]: {
                    [Contract.OciKmsVaultKeyVersionSource.External]: "External",
                    [Contract.OciKmsVaultKeyVersionSource.Internal]: "Internal"
                }
            }));
    return (source: Contract.OciKmsVaultKeyVersionSource) =>
        localization[Contract.TypeNames.OciKmsVaultKeyVersionSource][source]();
}