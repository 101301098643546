import { SxProps } from "@mui/material";
import React from "react";
import { Contract } from "../../../controllers";
import { KubernetesIcon, NvdIcon, TenableIcon } from "../../../icons";
import { AlmaIcon, AmazonIcon, ArchIcon, AwsIcon, AzureIcon, BitnamiIcon, CblMarinerIcon, CentOsIcon, DebianIcon, FedoraIcon, GcpIcon, GitHubSecurityAdvisoriesIcon, GitLabAdvisoryDatabaseIcon, GoVulnerabilityDatabaseIcon, NodejsSecurityWorkgroupIcon, OracleIcon, OsvIcon, PhotonIcon, PhpSecurityAdvisoriesIcon, RedHatIcon, RockyIcon, RubySecIcon, SuseIcon, UbuntuIcon, WindowsIcon } from "./icons";

type VulnerabilitySourceTypeIconProps = {
    sx?: SxProps;
    type: Contract.VulnerabilitySourceType;
};

export function VulnerabilitySourceTypeIcon({ sx, type }: VulnerabilitySourceTypeIconProps) {
    switch (type) {
        case Contract.VulnerabilitySourceType.Alma:
            return <AlmaIcon sx={sx}/>;
        case Contract.VulnerabilitySourceType.Alpine:
            return <AlmaIcon sx={sx}/>;
        case Contract.VulnerabilitySourceType.Amazon:
            return <AmazonIcon sx={sx}/>;
        case Contract.VulnerabilitySourceType.Arch:
            return <ArchIcon sx={sx}/>;
        case Contract.VulnerabilitySourceType.Aws:
            return <AwsIcon sx={sx}/>;
        case Contract.VulnerabilitySourceType.Azure:
            return <AzureIcon sx={sx}/>;
        case Contract.VulnerabilitySourceType.Bitnami:
            return <BitnamiIcon sx={sx}/>;
        case Contract.VulnerabilitySourceType.CblMariner:
            return <CblMarinerIcon sx={sx}/>;
        case Contract.VulnerabilitySourceType.CentOs:
            return <CentOsIcon sx={sx}/>;
        case Contract.VulnerabilitySourceType.Debian:
            return <DebianIcon sx={sx}/>;
        case Contract.VulnerabilitySourceType.Fedora:
            return <FedoraIcon sx={sx}/>;
        case Contract.VulnerabilitySourceType.Gcp:
            return <GcpIcon sx={sx}/>;
        case Contract.VulnerabilitySourceType.GitHubSecurityAdvisories:
            return <GitHubSecurityAdvisoriesIcon sx={sx}/>;
        case Contract.VulnerabilitySourceType.GitLabAdvisoryDatabase:
            return <GitLabAdvisoryDatabaseIcon sx={sx}/>;
        case Contract.VulnerabilitySourceType.GoVulnerabilityDatabase:
            return <GoVulnerabilityDatabaseIcon sx={sx}/>;
        case Contract.VulnerabilitySourceType.Kubernetes:
            return <KubernetesIcon sx={sx}/>;
        case Contract.VulnerabilitySourceType.NodejsSecurityWorkgroup:
            return <NodejsSecurityWorkgroupIcon sx={sx}/>;
        case Contract.VulnerabilitySourceType.Nvd:
            return <NvdIcon sx={sx}/>;
        case Contract.VulnerabilitySourceType.Oracle:
            return <OracleIcon sx={sx}/>;
        case Contract.VulnerabilitySourceType.Osv:
            return <OsvIcon sx={sx}/>;
        case Contract.VulnerabilitySourceType.Photon:
            return <PhotonIcon sx={sx}/>;
        case Contract.VulnerabilitySourceType.PhpSecurityAdvisories:
            return <PhpSecurityAdvisoriesIcon sx={sx}/>;
        case Contract.VulnerabilitySourceType.RedHat:
            return <RedHatIcon sx={sx}/>;
        case Contract.VulnerabilitySourceType.Rocky:
            return <RockyIcon sx={sx}/>;
        case Contract.VulnerabilitySourceType.RubySec:
            return <RubySecIcon sx={sx}/>;
        case Contract.VulnerabilitySourceType.Suse:
            return <SuseIcon sx={sx}/>;
        case Contract.VulnerabilitySourceType.Tenable:
            return <TenableIcon sx={sx}/>;
        case Contract.VulnerabilitySourceType.Ubuntu:
            return <UbuntuIcon sx={sx}/>;
        case Contract.VulnerabilitySourceType.Windows:
            return <WindowsIcon sx={sx}/>;
    }
}