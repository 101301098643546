import { formatFilterQueryParameters, setUrlRoute, ValuesFilterSelection } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React, { useMemo } from "react";
import { RisksItemsFilterId } from "../../views/Customer/components";
import { ProfileCategory } from "../../views/Customer/components/Entities/components/Profile/hooks";
import { Contract } from "../controllers";
import { CustomerConsoleAppUrlHelper, TypeHelper } from "../utilities";
import { RiskCountsChart } from "./RiskCountsChart";

export type RiskChartCellProps = {
    itemId: string;
    riskSeverityToIdsMap: Dictionary<string[]>;
};

export function RiskChartCell({ itemId, riskSeverityToIdsMap }: RiskChartCellProps) {
    const riskSeverities =
        useMemo(
            () =>
                _(Contract.Severity).
                    values().
                    filter(severity => severity != Contract.Severity.Information).
                    orderBy(
                        riskSeverity => TypeHelper.getEnumValue(Contract.TypeNames.Severity, riskSeverity),
                        "desc").
                    value(),
            []);

    const itemMap =
        useMemo(
            () =>
                _(riskSeverities)
                    .reduce(
                        (acc: Dictionary<number>, severity) => {
                            const size = _.size(riskSeverityToIdsMap[severity]);
                            if (size > 0) {
                                acc[severity] = size;
                            }
                            return acc;
                        }, {}),
            [riskSeverityToIdsMap]);

    return (
        <RiskCountsChart
            itemMap={itemMap}
            variant="dynamic"
            onClick={
                severity => {
                    const queryParameters =
                        formatFilterQueryParameters(
                            "filterMap",
                            {
                                [RisksItemsFilterId.RiskSeverity]:
                                    new ValuesFilterSelection(
                                        false,
                                        [severity])
                            });
                    setUrlRoute(`${(CustomerConsoleAppUrlHelper.getEntityProfileHashUrl(itemId, { category: ProfileCategory.Risks }))}?${queryParameters}`);
                }}/>);
}