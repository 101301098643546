import { ApiError, CheckboxField, GuidParser, InfoIcon, ItemSelector, Link, PasswordTextField, StringHelper, Tooltip, useChangeEffect, useExecuteOperation, useInputValidation, useLocalization, useOrderedWizardContext } from "@infrastructure";
import { Box, FormControl, FormHelperText, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useAddOrEditContext, useSetAddOrEditContext } from "..";
import { ConfigurationController, Contract, CustomerConsoleAppUrlHelper, FeatureHelper, RadioField, ScopeHelper, scopeSystemEntityModelStore, UrlHelper, useScopeNavigationViewContext, useTheme } from "../../../../../../../../../../../../../../../common";

export function EventCollectorInfoItem() {
    const { scopeNodeModel } = useScopeNavigationViewContext();
    const eventCollectorModels =
        ScopeHelper.getParentScopeSystemEntityModelsUnion(
            [scopeNodeModel.configuration.id],
            scopeSystemEntityModelStore.useGetSplunk());
    const setAddOrEditContext = useSetAddOrEditContext();
    const { eventCollectorEndpointConnectorId, eventCollectorModel, eventCollectorName, eventCollectorServerCertificateValidationEnabled, eventCollectorToken, eventCollectorUrl } = useAddOrEditContext();

    const [endpointConnectorAvailableTunnelSessionClientIds] =
        useExecuteOperation(
            EventCollectorInfoItem,
            async () => {
                const { endpointConnectorTunnelSessionClientIds } = await ConfigurationController.getEndpointConnectorTunnelSessionClientIds();
                const { endpointConnectorIdToIntegrationNameMap } = await ConfigurationController.getEndpointConnectorIdToIntegrationNameMap();
                return _.difference(
                    endpointConnectorTunnelSessionClientIds,
                    _.keys(endpointConnectorIdToIntegrationNameMap));
            });
    const endpointConnectorModels = scopeSystemEntityModelStore.useGetEndpointConnector();
    const endpointConnectorConfigurations =
        useMemo(
            () =>
                _(endpointConnectorModels).
                    filter(
                        endpointConnectorModel =>
                            endpointConnectorModel.configuration.id == eventCollectorEndpointConnectorId ||
                            endpointConnectorAvailableTunnelSessionClientIds.includes(endpointConnectorModel.configuration.id)).
                    map(endpointConnectorModel => (endpointConnectorModel.configuration as Contract.EndpointConnectorConfiguration)).
                    value(),
            [endpointConnectorModels]);
    const updatedEndpointConnectorConfiguration =
        useMemo(
            () =>
                _.find(
                    endpointConnectorConfigurations,
                    endpointConnectorConfiguration => endpointConnectorConfiguration.id === eventCollectorEndpointConnectorId),
            [endpointConnectorConfigurations, eventCollectorEndpointConnectorId]);

    const [endpointConnectorEnabled, setEndpointConnectorEnabled] = useState(!_.isNil(eventCollectorEndpointConnectorId));
    const [name, setName] = useState(eventCollectorName);
    const [selectedEndpointConnectorConfiguration, setSelectedEndpointConnectorConfiguration] = useState(updatedEndpointConnectorConfiguration ?? _.first(endpointConnectorConfigurations));
    const [serverCertificateValidationEnabled, setServerCertificateValidationEnabled] = useState(eventCollectorServerCertificateValidationEnabled);
    const [token, setToken] = useState(eventCollectorToken);
    const [url, setUrl] = useState(eventCollectorUrl);

    const { executing, setLoaded, setValid, useNextEffect } = useOrderedWizardContext();
    useEffect(
        () => {
            setLoaded();
        },
        []);

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useObservabilityItems.splunk.addOrEdit.eventCollectorInfoItem",
            () => ({
                actions: {
                    save: {
                        error: {
                            add: "Failed to add",
                            edit: "Failed to save",
                            [Contract.TypeNames.ConfigurationControllerUpsertSplunkEventCollectorError]: {
                                [Contract.ConfigurationControllerUpsertSplunkEventCollectorError.ConnectionRefused]: "Failed to connect to event collector",
                                [Contract.ConfigurationControllerUpsertSplunkEventCollectorError.EndpointNotExist]: "Endpoint Connector integration is not connected",
                                [Contract.ConfigurationControllerUpsertSplunkEventCollectorError.InvalidCertificate]: "Invalid certificate",
                                [Contract.ConfigurationControllerUpsertSplunkEventCollectorError.InvalidToken]: "Invalid token",
                                [Contract.ConfigurationControllerUpsertSplunkEventCollectorError.InvalidUrl]: "Invalid URL",
                                [Contract.ConfigurationControllerUpsertSplunkEventCollectorError.ServiceUnavailable]: "Service unavailable",
                                [Contract.ConfigurationControllerUpsertSplunkEventCollectorError.Timeout]: "Timeout"
                            }
                        }
                    }
                },
                fields: {
                    endpointConnectorUsed: {
                        description: {
                            link: "click here",
                            title: "Select how Tenable connects to your server. If your server is network-isolated, use a Tenable Endpoint Connector to establish connection. If the connector option is disabled, {{addConnectorWizardLink}} to add one."
                        },
                        no: "Event Collector URL",
                        yes: "Endpoint Connector (network-isolated)"
                    },
                    name: {
                        error: {
                            exists: "Name already exists",
                            required: "Name cannot be empty"
                        },
                        title: "Name"
                    },
                    serverCertificateValidationEnabled: "Accept any server SSL certificates",
                    token: {
                        error: {
                            format: "Token must be a valid GUID",
                            required: "Token cannot be empty"
                        },
                        title: "Token"
                    },
                    url: {
                        error: {
                            exists: "Event Collector URL already exists",
                            invalidFormat: "Invalid URL",
                            required: "Event Collector URL cannot be empty"
                        },
                        title: "Event Collector URL",
                        tooltip: "e.g. https://splunkcloud.com:8088"
                    }
                }
            }));

    const [existingEventCollectorModels, existingEventCollectorHostNames] =
        useMemo(
            () => {
                const existingEventCollectorModels =
                    _.filter(
                        eventCollectorModels,
                        otherEventCollectorModel =>
                            otherEventCollectorModel.configuration.id !== eventCollectorModel?.configuration.id &&
                            otherEventCollectorModel.configuration.id == scopeNodeModel.configuration.id);
                const existingEventCollectorHostNames =
                    _(existingEventCollectorModels).
                        filter(existingEventCollectorModel => !_.isNil((existingEventCollectorModel.configuration as Contract.SplunkEventCollectorConfiguration).url)).
                        map(existingEventCollectorModel => StringHelper.getSortValue(UrlHelper.getUrlData((existingEventCollectorModel.configuration as Contract.SplunkEventCollectorConfiguration).url!)!.hostname)).
                        value();
                return [
                    existingEventCollectorModels,
                    existingEventCollectorHostNames
                ];
            },
            [eventCollectorModels]);

    const [nameValidationController, nameValidationMessage] =
        useInputValidation(
            () => {
                const validationName = name.trim().
                    toLowerCase();
                if (_.isEmpty(validationName)) {
                    return localization.fields.name.error.required();
                }
                if (_.some(
                    existingEventCollectorModels,
                    existingEventCollectorModel => StringHelper.compare((existingEventCollectorModel.configuration as Contract.SplunkEventCollectorConfiguration).name, validationName))) {
                    return localization.fields.name.error.exists();
                }

                return undefined;
            },
            [name]);

    const [tokenValidationController, tokenValidationMessage] =
        useInputValidation(
            () => {
                if (!_.isNil(eventCollectorModel) &&
                    _.isNil(token)) {
                    return undefined;
                }

                const validationToken = token!.trim();
                if (_.isEmpty(validationToken)) {
                    return localization.fields.token.error.required();
                }
                if (!GuidParser.validate(validationToken)) {
                    return localization.fields.token.error.format();
                }

                return undefined;
            },
            [token]);

    const [urlValidationController, urlValidationMessage] =
        useInputValidation(
            () => {
                const validationUrl = url?.trim();
                if (_.isEmpty(validationUrl)) {
                    return localization.fields.url.error.required();
                }

                const urlData = UrlHelper.getUrlData(validationUrl!);
                if (_.isNil(urlData)) {
                    return localization.fields.url.error.invalidFormat();
                }

                if (_.includes(existingEventCollectorHostNames, urlData.hostname.toLowerCase())) {
                    return localization.fields.url.error.exists();
                }

                return undefined;
            },
            [url]);

    const [urlHttpsProtocol, setUrlHttpsProtocol] = useState(() => !_.isNil(url) && UrlHelper.getUrlData(url!)?.protocolType === "https");
    useChangeEffect(
        () => setUrlHttpsProtocol(
            !_.isEmpty(url?.trim()) &&
            UrlHelper.getUrlData(url!)?.protocolType === "https"),
        [url]);

    useChangeEffect(
        () => {
            if (!endpointConnectorEnabled && !urlHttpsProtocol) {
                setServerCertificateValidationEnabled(true);

            }
        },
        [endpointConnectorEnabled, urlHttpsProtocol]);

    useNextEffect(
        async () => {
            try {
                const { scopeSystemEntityModel } =
                    await ConfigurationController.upsertSplunkEventCollector(
                        new Contract.ConfigurationControllerUpsertSplunkEventCollectorRequest(
                            endpointConnectorEnabled
                                ? selectedEndpointConnectorConfiguration?.id
                                : undefined,
                            eventCollectorModel?.configuration.id,
                            name,
                            scopeNodeModel.configuration.id,
                            urlHttpsProtocol && serverCertificateValidationEnabled,
                            token,
                            endpointConnectorEnabled
                                ? undefined
                                : url));

                await scopeSystemEntityModelStore.notify(scopeSystemEntityModel);
            } catch (error) {
                return error instanceof ApiError && error.statusCode === 400
                    ? localization.actions.save.error[Contract.TypeNames.ConfigurationControllerUpsertSplunkEventCollectorError][error.error as Contract.ConfigurationControllerUpsertSplunkEventCollectorError]()
                    : _.isNil(eventCollectorModel)
                        ? localization.actions.save.error.add()
                        : localization.actions.save.error.edit();
            }
        },
        [endpointConnectorEnabled, name, selectedEndpointConnectorConfiguration, serverCertificateValidationEnabled, token, url, urlHttpsProtocol]);

    useEffect(
        () => {
            setAddOrEditContext(
                addOrEditContext => ({
                    ...addOrEditContext,
                    eventCollectorEndpointConnectorId:
                        endpointConnectorEnabled
                            ? selectedEndpointConnectorConfiguration?.id
                            : undefined,
                    eventCollectorName: name.trim(),
                    eventCollectorServerCertificateValidationEnabled: serverCertificateValidationEnabled,
                    eventCollectorToken: token?.trim(),
                    eventCollectorUrl:
                        endpointConnectorEnabled
                            ? undefined
                            : url?.trim()
                }));

            setValid(
                ((endpointConnectorEnabled && !!selectedEndpointConnectorConfiguration) ||
                    (!endpointConnectorEnabled && urlValidationController.isValid())) &&
                nameValidationController.isValid() &&
                tokenValidationController.isValid());
        },
        [endpointConnectorEnabled, name, selectedEndpointConnectorConfiguration, serverCertificateValidationEnabled, token, url]);

    const theme = useTheme();
    return (
        <Stack
            spacing={4}
            sx={{ maxWidth: theme.spacing(60) }}>
            <Box sx={{ marginBottom: theme.spacing(5) }}>
                <FormControl
                    fullWidth={true}
                    variant="standard">
                    <TextField
                        disabled={executing}
                        label={localization.fields.name.title()}
                        value={name}
                        variant="outlined"
                        onChange={event => setName(event.target.value)}/>
                    {!_.isNil(nameValidationMessage) && (
                        <FormHelperText error={true}>{nameValidationMessage}</FormHelperText>)}
                </FormControl>
            </Box>
            {FeatureHelper.enabled(Contract.FeatureName.SplunkEventCollectorEndpointConnectorEnabled)
                ? <Stack
                    spacing={2}
                    sx={{
                        border: theme.border.primary,
                        borderRadius: theme.spacing(0.75),
                        padding: theme.spacing(2)
                    }}>
                    <Typography>
                        {localization.fields.endpointConnectorUsed.description.title({
                            addConnectorWizardLink:
                                <Link
                                    urlOrGetUrl={CustomerConsoleAppUrlHelper.getConfigurationIntegrationsEndpointConnectorRelativeUrl(true)}
                                    variant="external">
                                    {localization.fields.endpointConnectorUsed.description.link()}
                                </Link>
                        })}
                    </Typography>
                    <RadioField
                        checked={!endpointConnectorEnabled}
                        title={localization.fields.endpointConnectorUsed.no()}
                        onSelected={() => setEndpointConnectorEnabled(false)}>
                        <FormControl
                            fullWidth={true}
                            variant="standard">
                            <TextField
                                disabled={executing || !_.isNil(eventCollectorModel)}
                                label={localization.fields.url.title()}
                                slotProps={{
                                    input: {
                                        endAdornment:
                                        <InputAdornment position="end">
                                            <Tooltip titleOrGetTitle={localization.fields.url.tooltip()}>
                                                <InfoIcon
                                                    sx={{
                                                        color: theme.palette.text.secondary,
                                                        fontSize: "24px"
                                                    }}/>
                                            </Tooltip>
                                        </InputAdornment>
                                    }
                                }}
                                value={url}
                                variant="outlined"
                                onChange={event => setUrl(event.target.value)}/>
                            {!_.isNil(urlValidationMessage) && (
                                <FormHelperText error={true}>{urlValidationMessage}</FormHelperText>)}
                        </FormControl>
                    </RadioField>
                    <RadioField
                        checked={endpointConnectorEnabled}
                        indent={false}
                        title={localization.fields.endpointConnectorUsed.yes()}
                        onSelected={() => setEndpointConnectorEnabled(true)}>
                        <ItemSelector
                            disabled={executing || !_.size(endpointConnectorConfigurations)}
                            fullWidth={true}
                            getItemText={(endpointConnectorConfiguration: Contract.EndpointConnectorConfiguration) => endpointConnectorConfiguration.name}
                            items={endpointConnectorConfigurations}
                            selectedItem={selectedEndpointConnectorConfiguration}
                            sorted={true}
                            onSelectedItemChanged={setSelectedEndpointConnectorConfiguration}>
                            {(endpointConnectorConfiguration: Contract.EndpointConnectorConfiguration) => endpointConnectorConfiguration.name}
                        </ItemSelector>
                    </RadioField>
                </Stack>
                : <Stack spacing={1}>
                    <FormControl
                        fullWidth={true}
                        variant="standard">
                        <TextField
                            disabled={executing || !_.isNil(eventCollectorModel)}
                            label={localization.fields.url.title()}
                            slotProps={{
                                input: {
                                    endAdornment:
                                    <InputAdornment position="end">
                                        <Tooltip titleOrGetTitle={localization.fields.url.tooltip()}>
                                            <InfoIcon
                                                sx={{
                                                    color: theme.palette.text.secondary,
                                                    fontSize: "24px"
                                                }}/>
                                        </Tooltip>
                                    </InputAdornment>
                                }
                            }}
                            value={url}
                            variant="outlined"
                            onChange={event => setUrl(event.target.value)}/>
                        {!_.isNil(urlValidationMessage) && (
                            <FormHelperText error={true}>{urlValidationMessage}</FormHelperText>)}
                    </FormControl>
                </Stack>}
            <FormControl
                fullWidth={true}
                variant="standard">
                <PasswordTextField
                    disabled={executing}
                    label={localization.fields.token.title()}
                    password={token}
                    variant="outlined"
                    onPasswordChanged={token => setToken(token!)}/>
                {!_.isNil(tokenValidationMessage) && (
                    <FormHelperText error={true}>{tokenValidationMessage}</FormHelperText>)}
            </FormControl>
            <CheckboxField
                checked={!serverCertificateValidationEnabled}
                disabled={executing || (!urlHttpsProtocol && !endpointConnectorEnabled)}
                onChange={() => setServerCertificateValidationEnabled(!serverCertificateValidationEnabled)}>
                {localization.fields.serverCertificateValidationEnabled()}
            </CheckboxField>
        </Stack>);
}