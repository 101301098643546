﻿import { TimeSpanFormatter, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../../..";
import { Contract, InfoCard, InfoItem } from "../../../../../../../../../../../common";
import { Info } from "../../../../../components";
import { AzureResourceRoleAssignmentResources } from "../../../components";
import { useCustomEntityPropertyInfoItemElements } from "../../useCustomEntityPropertyInfoItemElements";
import { useAzureDefaultKeyVaultVaultObjectInfoItemElements } from "../useAzureDefaultKeyVaultVaultObjectInfoItemElements";
import { useAzureDefaultResourceInfoItemElements } from "../useAzureDefaultResourceInfoItemElements";
import { Versions } from "./components";

export function useAzureKeyVaultVaultKeyDefinition(resourceGroupResourceModel: Contract.AzureResourceGroupResourceModel, options?: DefinitionOptions) {
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceGroupResourceModel);
    const keyModel = resourceGroupResourceModel as Contract.AzureKeyVaultVaultKeyModel;
    const defaultKeyVaultVaultObjectInfoItemElements = useAzureDefaultKeyVaultVaultObjectInfoItemElements(keyModel);
    const defaultResourceInfoItemElements = useAzureDefaultResourceInfoItemElements(resourceGroupResourceModel);
    const key = resourceGroupResourceModel.entity as Contract.AzureKeyVaultVaultKey;

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.azure.useAzureKeyVaultVaultKeyDefinition",
            () => ({
                info: {
                    items: {
                        rotationPolicy: {
                            empty: "Not Configured",
                            keyExpirationTime: "Expiry Time",
                            rotationEnabled: {
                                false: "Disabled",
                                title: "Auto Rotation",
                                true: "Enabled"
                            },
                            rotationTimeFrame: "Rotation Time",
                            title: "Rotation policy",
                            type: {
                                text: "Rotation Option",
                                [Contract.TypeNames.AzureKeyVaultVaultKeyRotationPolicyType]: {
                                    [Contract.AzureKeyVaultVaultKeyRotationPolicyType.AfterCreation]: "Automatically renew at a given time after creation",
                                    [Contract.AzureKeyVaultVaultKeyRotationPolicyType.BeforeExpiration]: "Automatically renew at a given time before expiry"
                                }
                            }
                        }
                    }
                },
                tabs: {
                    resourceRoleAssignments: "Resource Role Assignments",
                    versions: "Versions ({{versionCount | NumberFormatter.humanize}})"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Overview,
                <Versions keyModel={keyModel}/>,
                localization.tabs.versions({ versionCount: _.size(key.versionNameToVersionMap) }),
                "versions"),
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <AzureResourceRoleAssignmentResources
                    csvExportFilePrefix={localization.tabs.resourceRoleAssignments()}
                    scopeResourceModel={resourceGroupResourceModel}/>,
                localization.tabs.resourceRoleAssignments(),
                "resourceRoleAssignments")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoCard
                        key="profile"
                        location="profile">
                        <InfoCard columns={true} key="defaultKeyVaultVaultObjectInfoItemElements">
                            {...defaultKeyVaultVaultObjectInfoItemElements}
                        </InfoCard>
                        <InfoCard columns={true} key="rotationPolicy" title={localization.info.items.rotationPolicy.title()}>
                            <InfoItem
                                key="rotationEnabled"
                                title={localization.info.items.rotationPolicy.rotationEnabled.title()}
                                value={
                                    key.rotationPolicy?.rotationEnabled
                                        ? localization.info.items.rotationPolicy.rotationEnabled.true()
                                        : localization.info.items.rotationPolicy.rotationEnabled.false()}/>
                            <InfoItem
                                key="rotationEnabled"
                                title={localization.info.items.rotationPolicy.keyExpirationTime()}
                                value={
                                    _.isNil(key.rotationPolicy?.keyExpirationTime)
                                        ? undefined
                                        : TimeSpanFormatter.dayHour(key.rotationPolicy!.keyExpirationTime)}/>
                            <InfoItem
                                key="rotationTimeFrame"
                                title={localization.info.items.rotationPolicy.rotationTimeFrame()}
                                value={
                                    _.isNil(key.rotationPolicy?.rotationTimeFrame)
                                        ? undefined
                                        : TimeSpanFormatter.dayHour(key.rotationPolicy!.rotationTimeFrame)}/>
                            <InfoItem
                                key="type"
                                title={localization.info.items.rotationPolicy.type.text()}
                                value={
                                    _.isNil(key.rotationPolicy?.type)
                                        ? undefined
                                        : localization.info.items.rotationPolicy.type[Contract.TypeNames.AzureKeyVaultVaultKeyRotationPolicyType][key.rotationPolicy!.type]()}/>
                        </InfoCard>
                    </InfoCard>,
                    <InfoCard
                        itemContainerSx={{ padding: "0px 0px 16px 0px" }}
                        key="profile"
                        location="miniGlance">
                        {...defaultKeyVaultVaultObjectInfoItemElements}
                        <InfoItem
                            key="rotationEnabled"
                            title={localization.info.items.rotationPolicy.rotationEnabled.title()}
                            value={
                                key.rotationPolicy?.rotationEnabled
                                    ? localization.info.items.rotationPolicy.rotationEnabled.true()
                                    : localization.info.items.rotationPolicy.rotationEnabled.false()}/>
                        <InfoItem
                            key="rotationEnabled"
                            title={localization.info.items.rotationPolicy.keyExpirationTime()}
                            value={
                                _.isNil(key.rotationPolicy?.keyExpirationTime)
                                    ? undefined
                                    : TimeSpanFormatter.dayHour(key.rotationPolicy!.keyExpirationTime)}/>
                        <InfoItem
                            key="rotationTimeFrame"
                            title={localization.info.items.rotationPolicy.rotationTimeFrame()}
                            value={
                                _.isNil(key.rotationPolicy?.rotationTimeFrame)
                                    ? undefined
                                    : TimeSpanFormatter.dayHour(key.rotationPolicy!.rotationTimeFrame)}/>
                        <InfoItem
                            key="type"
                            title={localization.info.items.rotationPolicy.type.text()}
                            value={
                                _.isNil(key.rotationPolicy?.type)
                                    ? undefined
                                    : localization.info.items.rotationPolicy.type[Contract.TypeNames.AzureKeyVaultVaultKeyRotationPolicyType][key.rotationPolicy!.type]()}/>
                    </InfoCard>
                ]}
                options={options?.infoOptions}>
            </Info>
    });
}