import { IconText, NumberFormatter, StringHelper, TimeFormatter, useLocalization } from "@infrastructure";
import { Divider, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { Fragment, memo } from "react";
import { InfoCard, InfoItem, MaliciousFilesIcon, tiFileModelStore, useTheme, VerticalTopItemsInfoItem } from "..";

const MaliciousFileMemo = memo(MaliciousFile);
export { MaliciousFileMemo as MaliciousFile };

type MaliciousFileProps = {
    contentSha256String: string;
    variant?: "full" | "minimal";
};

function MaliciousFile({ contentSha256String, variant = "minimal" }: MaliciousFileProps) {
    const localization =
        useLocalization(
            "common.maliciousFile",
            () => ({
                contentMd5String: "MD5",
                contentSha1String: "SHA-1",
                contentSha256String: "SHA-256",
                fileSize: "File size",
                firstSeenInTheWildTime: "First Seen In The Wild",
                generic: {
                    false: "No",
                    title: "Generic",
                    true: "Yes"
                },
                knownDistributors: {
                    pluralizer: [
                        "1 distributor",
                        "{{count | NumberFormatter.humanize}} distributors"
                    ],
                    title: "Known Distributors"
                },
                maliciousFile: "Malicious File",
                signed: {
                    false: "No",
                    title: "Signed",
                    true: "Yes"
                },
                tags: "Tags"
            }));
    const tiFileModel = tiFileModelStore.useGet(contentSha256String);
    const tiFileVirusTotalData = tiFileModel.tiFile.virusTotalData!;

    const theme = useTheme();
    return (
        <InfoCard
            columns={variant === "full"}
            containerSx={{
                flex: 1,
                maxHeight:
                    variant === "minimal"
                        ? 500
                        : undefined,
                padding:
                    variant === "full"
                        ? theme.spacing(2, 3, 0, 3)
                        : theme.spacing(2, 0, 0, 2)
            }}
            itemContainerSx={{ maxWidth: "fit-content" }}>
            {variant === "minimal" &&
                <Fragment>
                    <Stack
                        alignItems="center"
                        direction="row"
                        sx={{ paddingBottom: theme.spacing(2) }}>
                        <IconText
                            icon={
                                <MaliciousFilesIcon
                                    sx={{
                                        color: theme.palette.text.primary,
                                        fontSize: "24px"
                                    }}/>}
                            text={
                                <Typography
                                    noWrap={true}
                                    variant="h3">
                                    {localization.maliciousFile()}
                                </Typography>}/>
                    </Stack>
                    <Divider flexItem={true}/>
                </Fragment>}
            <InfoItem
                title={localization.contentSha256String()}
                value={tiFileModel.tiFile.scan.contentSha256String}/>
            <InfoItem
                title={localization.contentSha1String()}
                value={tiFileModel.tiFile.scan.contentSha1String}/>
            <InfoItem
                title={localization.contentMd5String()}
                value={tiFileModel.tiFile.scan.contentMd5String}/>
            <InfoItem
                title={localization.fileSize()}
                value={NumberFormatter.storage(tiFileModel.tiFile.scan.size)}/>
            <InfoItem
                title={localization.generic.title()}
                value={
                    tiFileModel.tiFile.analysis?.generic
                        ? localization.generic.true()
                        : localization.generic.false()}/>
            <InfoItem
                title={localization.signed.title()}
                value={
                    tiFileVirusTotalData.signed
                        ? localization.signed.true()
                        : localization.signed.false()}/>
            <InfoItem
                title={localization.firstSeenInTheWildTime()}
                value={
                    TimeFormatter.longDate(
                        tiFileVirusTotalData.firstSeenInTheWildTime ??
                        tiFileVirusTotalData.firstSubmissionTime)}/>
            {variant === "full" &&
                <VerticalTopItemsInfoItem
                    items={_.orderBy(tiFileVirusTotalData.knownDistributors?.distributors)}
                    title={localization.knownDistributors.title()}/>}
            {variant === "full" &&
                <VerticalTopItemsInfoItem
                    items={
                        _.orderBy(
                            tiFileVirusTotalData.tags,
                            tiFileVirusTotalTag => StringHelper.getSortValue(tiFileVirusTotalTag))}
                    title={localization.tags()}/>}
        </InfoCard>);
}