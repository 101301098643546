import { useExecuteOperation, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, Entity, entityModelStore, InfoItem, useEntityTypeNameTranslator } from "../../../../../../../../../../common";
import { EntitiesInfoItem } from "../../../../components";

export function useAzureCommonEncryptedResourceInfoElements(resourceGroupResourceModel: Contract.AzureResourceGroupResourceModel) {
    const [servicePrincipalModel] =
        useExecuteOperation(
            [useAzureCommonEncryptedResourceInfoElements, resourceGroupResourceModel.id],
            async () =>
                !_.isNil(_.as<Contract.IAzureEncryptedResource>(resourceGroupResourceModel.entity).vaultKeyInfo?.servicePrincipalId)
                    ? await entityModelStore.get(_.as<Contract.IAzureEncryptedResource>(resourceGroupResourceModel.entity).vaultKeyInfo!.servicePrincipalId!)
                    : undefined);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.azure.useAzureCommonEncryptedResourceInfoElements",
            () => ({
                encryption: {
                    disabled: "Not enabled",
                    enabled: "Enabled",
                    servicePrincipal: "Encryption {{servicePrincipalTranslatedTypeName}}",
                    title: "Encryption",
                    vaultKeyIdReferences: "Encryption Key"
                }
            }));

    return [
        <InfoItem
            key="encryptionExists"
            title={localization.encryption.title()}
            value={
                (_.as<Contract.IAzureEncryptedResource>(resourceGroupResourceModel.entity).encryptionExists
                    ? localization.encryption.enabled()
                    : localization.encryption.disabled())}/>,
        !_.isNil(_.as<Contract.IAzureEncryptedResource>(resourceGroupResourceModel.entity).vaultKeyInfo?.servicePrincipalId)
            ? <InfoItem
                key="encryptionServicePrincipal"
                title={localization.encryption.servicePrincipal({ servicePrincipalTranslatedTypeName: entityTypeNameTranslator(servicePrincipalModel!.entity.typeName) })}
                value={
                    <Entity
                        entityIdOrModel={_.as<Contract.IAzureEncryptedResource>(resourceGroupResourceModel.entity).vaultKeyInfo!.servicePrincipalId!}
                        variant="iconText"/>}/>
            : undefined,
        <EntitiesInfoItem
            entityIdsOrModels={_.as<Contract.IAzureEncryptedResourceModel>(resourceGroupResourceModel).vaultKeyIdReferences}
            entityTypeName={Contract.TypeNames.AzureKeyVaultVaultKey}
            key="encryptionVaultKeyIdReferences"
            title={localization.encryption.vaultKeyIdReferences()}/>
    ];
}