import { DataTable, DataTableActions, DataTableColumn, EmptyMessageText, ItemSelector, Loading, useChangeEffect, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useRef, useState } from "react";
import { Contract, InfoCard, InfoItem, useTheme, VerticalTopItemsInfoItem } from "../../../../../../../../../../../../common";
import { VulnerabilitiesCell } from "../../../../../../../VulnerabilitiesCell";
import { EntitiesInfoItem } from "../../../../../../components";

type ContainerDefinitionsProps = {
    taskDefinitionModel: Contract.AwsEcsTaskDefinitionModel;
};

export function ContainerDefinitions({ taskDefinitionModel }: ContainerDefinitionsProps) {
    const taskDefinition = taskDefinitionModel.entity as Contract.AwsEcsTaskDefinition;
    const latestRevision = taskDefinition.revisionMap[taskDefinition.latestRevisionId!];
    const containerDefinitions =
        _.orderBy(
            latestRevision.containerDefinitions,
            containerDefinition => containerDefinition.name,
            "asc");
    const [selectedContainerDefinition, setSelectedContainerDefinition] = useState(containerDefinitions[0]);

    return (
        <InfoCard>
            <ItemSelector
                dense={true}
                fullWidth={true}
                items={containerDefinitions}
                selectedItem={selectedContainerDefinition}
                sorted={false}
                onSelectedItemChanged={setSelectedContainerDefinition}>
                {containerDefinition => containerDefinition.name}
            </ItemSelector>
            <Loading>
                <Core
                    containerImageRawUrlToDataMap={taskDefinitionModel.containerImageRawUrlToDataMap}
                    selectedContainerDefinition={selectedContainerDefinition}/>
            </Loading>
        </InfoCard>);
}

type CoreProps = {
    containerImageRawUrlToDataMap: _.Dictionary<Contract.ContainerImageResourceStateContainerImageData>;
    selectedContainerDefinition: Contract.AwsEcsTaskDefinitionRevisionContainerDefinition;
};

function Core({ containerImageRawUrlToDataMap, selectedContainerDefinition }: CoreProps) {
    const dataTableActionsRef = useRef<DataTableActions>();
    useChangeEffect(
        () => {
            if (!_.isNil(dataTableActionsRef.current)) {
                dataTableActionsRef.current.reset();
            }
        },
        [selectedContainerDefinition]);

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsEcsTaskDefinitionDefinition.containerDefinitions.core",
            () => ({
                fields: {
                    containerImage: "Image",
                    containerImageVulnerabilities: "Vulnerabilities",
                    environmentVariables: {
                        columns: {
                            name: "Key",
                            value: "Value"
                        },
                        empty: "No environment variables",
                        title: "Environment Variables"
                    },
                    portMappings: {
                        portMapping: "{{networkProtocol}} : {{hostPort}} : {{containerPort}}",
                        title: "Port Mappings (Host:Container)"
                    },
                    repositoryId: "Image Repository"
                }
            }));

    const theme = useTheme();
    return (
        <InfoCard>
            <InfoCard columns={true}>
                <EntitiesInfoItem
                    entityIdsOrModels={containerImageRawUrlToDataMap[selectedContainerDefinition.imageRawUrl].containerImageIdReference}
                    entityTypeName={Contract.TypeNames.IContainerImage}
                    title={localization.fields.containerImage()}/>
                <InfoItem
                    key="containerImageVulnerabilities"
                    title={localization.fields.containerImageVulnerabilities()}
                    value={
                        _.isNil(containerImageRawUrlToDataMap[selectedContainerDefinition.imageRawUrl])
                            ? undefined
                            : <VulnerabilitiesCell
                                containerImageScanStatus={containerImageRawUrlToDataMap[selectedContainerDefinition.imageRawUrl].scanStatus}
                                variant="initialAndCount"
                                vulnerabilities={containerImageRawUrlToDataMap[selectedContainerDefinition.imageRawUrl].vulnerabilities}/>}/>
                <VerticalTopItemsInfoItem
                    items={
                        _.map(
                            selectedContainerDefinition.portMappings,
                            portMapping =>
                                localization.fields.portMappings.portMapping({
                                    containerPort: portMapping.containerPort,
                                    hostPort: portMapping.hostPort,
                                    networkProtocol: portMapping.protocolType.toUpperCase()
                                }))}
                    key="portMapping"
                    title={localization.fields.portMappings.title()}/>
            </InfoCard>
            <InfoCard title={localization.fields.environmentVariables.title()}>
                <DataTable
                    actionsRef={dataTableActionsRef}
                    emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.fields.environmentVariables.empty()) }}
                    fetchItems={
                        () =>
                            _.map(
                                selectedContainerDefinition.environmentVariableNameToValueMap,
                                (value, name) =>
                                    new ContainerDefinitionsInfoCardTableItem(
                                        name,
                                        value))}
                    getItemId={(item: ContainerDefinitionsInfoCardTableItem) => item.name}
                    sortOptions={{ enabled: false }}
                    sx={{ marginTop: theme.spacing(1) }}
                    variant="card">
                    <DataTableColumn
                        cellSx={{ width: theme.spacing(44) }}
                        id={ContainerDefinitionsInfoCardTableColumnId.Name}
                        itemProperty={(item: ContainerDefinitionsInfoCardTableItem) => item.name}
                        title={localization.fields.environmentVariables.columns.name()}/>
                    <DataTableColumn
                        id={ContainerDefinitionsInfoCardTableColumnId.Value}
                        itemProperty={(item: ContainerDefinitionsInfoCardTableItem) => item.value}
                        title={localization.fields.environmentVariables.columns.value()}/>
                </DataTable>
            </InfoCard>
        </InfoCard>);
}

enum ContainerDefinitionsInfoCardTableColumnId {
    Name = "name",
    Value = "value"
}

class ContainerDefinitionsInfoCardTableItem {
    constructor(
        public name: string,
        public value: string) {
    }
}