import { DataTableColumn, optionalTableCell, useLocalization } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { useAwsCommonResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";

export function useAwsAutoScalingLaunchConfigurationDefinition(definitionData: DefinitionData) {
    const commonResourceDefinition = useAwsCommonResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.aws.useAwsAutoScalingLaunchConfigurationDefinition",
            () => ({
                columns: {
                    autoScalingGroupIds: "Auto Scaling Groups",
                    vpcIds: "VPC"
                }
            }));

    return new EntityTableDefinition(
        _.filter([
            commonResourceDefinition.columns.tenantColumn,
            commonResourceDefinition.columns.creationTimeColumn,
            commonResourceDefinition.columns.creatorIdentityCsvColumn,
            commonResourceDefinition.columns.creatorOriginatorEntityCsvColumn,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.AwsAutoScalingLaunchConfigurationModel>(
                        Contract.TypeNames.AwsAutoScalingAutoScalingGroup,
                        item => item.autoScalingGroupIds,
                        localization.columns.autoScalingGroupIds())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AwsAutoScalingLaunchConfigurationAutoScalingGroups)}
                                placeholder={localization.columns.autoScalingGroupIds()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AwsAutoScalingLaunchConfigurationAutoScalingGroups}
                key={Contract.EntityModelProperty.AwsAutoScalingLaunchConfigurationAutoScalingGroups}
                render={
                    optionalTableCell<Contract.AwsAutoScalingLaunchConfigurationModel>(
                        item =>
                            _.isEmpty(item.autoScalingGroupIds)
                                ? undefined
                                : <EntitiesCell
                                    entityIdsOrModels={item.autoScalingGroupIds}
                                    entityTypeName={Contract.TypeNames.AwsAutoScalingAutoScalingGroup}
                                    entityVariant="iconText"/>)}
                title={localization.columns.autoScalingGroupIds()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.AwsAutoScalingLaunchConfigurationModel>(
                        Contract.TypeNames.AwsEc2Vpc,
                        item => item.vpcIds,
                        localization.columns.vpcIds())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AwsAutoScalingLaunchConfigurationVpcs)}
                                placeholder={localization.columns.vpcIds()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AwsAutoScalingLaunchConfigurationVpcs}
                key={Contract.EntityModelProperty.AwsAutoScalingLaunchConfigurationVpcs}
                render={
                    optionalTableCell<Contract.AwsAutoScalingLaunchConfigurationModel>(
                        item =>
                            _.isEmpty(item.vpcIds)
                                ? undefined
                                : <EntitiesCell
                                    entityIdsOrModels={item.vpcIds}
                                    entityTypeName={Contract.TypeNames.AwsEc2Vpc}
                                    entityVariant="iconText"/>)}
                title={localization.columns.vpcIds()}/>,
            commonResourceDefinition.columns.regionColumn,
            commonResourceDefinition.columns.regionLocationColumn,
            commonResourceDefinition.columns.tagsColumn,
            commonResourceDefinition.columns.attributesColumn,
            commonResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ]),
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAwsAutoScalingLaunchConfigurationRequest(
                new Contract.EntityControllerGetEntityModelPageAwsAutoScalingLaunchConfigurationRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsAutoScalingLaunchConfigurationAutoScalingGroups]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsAutoScalingLaunchConfigurationVpcs])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags]))));
}