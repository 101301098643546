import { FormLayout, map, Message, useLocalization } from "@infrastructure";
import { Check as SelectedIcon } from "@mui/icons-material";
import { Box, Button, CircularProgress, Stack, Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { useMemo, useRef, useState } from "react";
import { Attribute, Contract, RadioGroup, RadioGroupItem, useScopeNavigationViewContext, useTheme } from "../../../../../../../../../common";
import { useCustomEntityAttributesContext, useSetCustomEntityAttributesContext } from "../../CustomEntityAttributeDefinitions";
import { AutomaticCustomEntityAttributeResourceTagDefinitionTemplate, AutomaticCustomEntityAttributeResourceTagKeyDefinitionTemplate, AutomaticCustomEntityAttributeResourceTagPatternsDefinitionTemplate, ManualCustomEntityAttributeDefinition } from "./components";

export type AddCustomEntityAttributeActions = {
    reset: () => void;
    upsertCustomEntityAttribute: (colorIndex: number, scopeId: string) => Promise<void>;
};

export type ConfigurationValidation = {
    changed: boolean;
    valid: boolean;
};

const getCustomEntityAttributeDefinitionType =
    (customEntityAttributeDefinition: Contract.AutomaticCustomEntityAttributeDefinitionTemplateConfiguration | Contract.ManualCustomEntityAttributeDefinitionConfiguration) =>
        customEntityAttributeDefinition.typeName !== Contract.TypeNames.AutomaticCustomEntityAttributeDefinitionTemplateConfiguration
            ? CustomEntityAttributeDefinitionType.ManualCustomEntityAttributeDefinition
            : map(
                (customEntityAttributeDefinition as Contract.AutomaticCustomEntityAttributeDefinitionTemplateConfiguration).type,
                {
                    [Contract.AutomaticCustomEntityAttributeDefinitionTemplateConfigurationType.ResourceTag]:
                        () => CustomEntityAttributeDefinitionType.AutomaticCustomEntityAttributeResourceTagDefinitionTemplate,
                    [Contract.AutomaticCustomEntityAttributeDefinitionTemplateConfigurationType.ResourceTagKey]:
                        () => CustomEntityAttributeDefinitionType.AutomaticCustomEntityAttributeResourceTagKeyDefinitionTemplate,
                    [Contract.AutomaticCustomEntityAttributeDefinitionTemplateConfigurationType.ResourceTagPattern]:
                        () => CustomEntityAttributeDefinitionType.AutomaticCustomEntityAttributeResourceTagPatternDefinitionTemplate
                });

export function AddOrEdit() {
    const { scopeNodeModel } = useScopeNavigationViewContext();
    const { addOrEditReadonly, item } = useCustomEntityAttributesContext();
    const setCustomEntityAttributesContext = useSetCustomEntityAttributesContext();
    const typeToCustomEntityAttributeActionsMapRef = useRef<Dictionary<AddCustomEntityAttributeActions>>({});
    const [colorIndex, setColorIndex] = useState(item?.configuration.colorIndex ?? 0);
    const [saveError, setSaveError] = useState(false);
    const [saveExecuting, setSaveExecuting] = useState(false);

    const [initialType] =
        useState(
            () =>
                _.isNil(item)
                    ? CustomEntityAttributeDefinitionType.ManualCustomEntityAttributeDefinition
                    : getCustomEntityAttributeDefinitionType(item.configuration!));
    const [type, setType] = useState(initialType);
    const [typeToValidMap, setTypeToValidMap] = useState<Dictionary<ConfigurationValidation>>({});

    const dialogValidation =
        _.isNil(item) || initialType != type
            ? typeToValidMap[type]?.valid
            : typeToValidMap[type]?.valid && (typeToValidMap[type].changed || item.configuration.colorIndex != colorIndex);

    const localization =
        useLocalization(
            "views.customer.configuration.entities.customEntityAttributeDefinitions.addOrEdit",
            () => ({
                actions: {
                    add: {
                        error: "Failed to create label",
                        title: "Create"
                    },
                    close: {
                        title: "Close"
                    },
                    edit: {
                        error: "Failed to edit label",
                        title: "Save"
                    }
                },
                fields: {
                    colorIndex: "Select a color",
                    name: {
                        error: {
                            required: "Name cannot be empty"
                        },
                        title: {
                            nonOptional: "Name",
                            optional: "Name (optional)"
                        }
                    },
                    type: {
                        automaticCustomEntityAttributeResourceTagDefinitionTemplate: "Automatic label based on exact tag key and value",
                        automaticCustomEntityAttributeResourceTagKeyDefinitionTemplate: "Automatic label based on exact tag key",
                        automaticCustomEntityAttributeResourceTagPatternDefinitionTemplate: "Automatic label based on tag key and value patterns",
                        manualCustomEntityAttributeDefinition: "Manual label"
                    }
                },
                title: {
                    add: "Create a New Label",
                    edit: "Edit Label",
                    view: "View Label"
                }
            }));

    async function upsertCustomEntityAttribute() {
        setSaveError(false);
        setSaveExecuting(true);

        try {
            await typeToCustomEntityAttributeActionsMapRef.current[type].upsertCustomEntityAttribute(
                colorIndex,
                scopeNodeModel.configuration.id);
            _.forEach(
                typeToCustomEntityAttributeActionsMapRef.current,
                actions => actions.reset());
            setColorIndex(0);
            setType(CustomEntityAttributeDefinitionType.ManualCustomEntityAttributeDefinition);
            setSaveExecuting(false);
            setCustomEntityAttributesContext(
                context =>
                    ({
                        ...context,
                        addOrEditOpen: false
                    }));
        } catch {
            setSaveExecuting(false);
            setSaveError(true);
        }
    }

    const validateType = (configurationValidation: ConfigurationValidation) => {
        setTypeToValidMap(
            validMap =>
                ({
                    ...validMap,
                    [type]: configurationValidation
                }));
    };

    const items =
        useMemo(
            (): RadioGroupItem<CustomEntityAttributeDefinitionType>[] => [
                {
                    children:
                        <ManualCustomEntityAttributeDefinition
                            actionsRef={actions => typeToCustomEntityAttributeActionsMapRef.current[CustomEntityAttributeDefinitionType.ManualCustomEntityAttributeDefinition] = actions!}
                            disabled={
                                saveExecuting ||
                                !_.isNil(item) &&
                                type !== CustomEntityAttributeDefinitionType.ManualCustomEntityAttributeDefinition}
                            readOnly={addOrEditReadonly}
                            onValidChange={validateType}/>,
                    disabled:
                        !_.isNil(item) &&
                        type !== CustomEntityAttributeDefinitionType.ManualCustomEntityAttributeDefinition,
                    label: localization.fields.type.manualCustomEntityAttributeDefinition(),
                    value: CustomEntityAttributeDefinitionType.ManualCustomEntityAttributeDefinition
                },
                {
                    children:
                        <AutomaticCustomEntityAttributeResourceTagKeyDefinitionTemplate
                            actionsRef={actions => typeToCustomEntityAttributeActionsMapRef.current[CustomEntityAttributeDefinitionType.AutomaticCustomEntityAttributeResourceTagKeyDefinitionTemplate] = actions!}
                            disabled={
                                saveExecuting ||
                                !_.isNil(item) &&
                                type === CustomEntityAttributeDefinitionType.ManualCustomEntityAttributeDefinition ||
                                addOrEditReadonly &&
                                type !== CustomEntityAttributeDefinitionType.AutomaticCustomEntityAttributeResourceTagKeyDefinitionTemplate}
                            readOnly={addOrEditReadonly}
                            onValidChange={validateType}/>,
                    disabled:
                        !_.isNil(item) &&
                        type === CustomEntityAttributeDefinitionType.ManualCustomEntityAttributeDefinition ||
                        addOrEditReadonly &&
                        type !== CustomEntityAttributeDefinitionType.AutomaticCustomEntityAttributeResourceTagKeyDefinitionTemplate,
                    label: localization.fields.type.automaticCustomEntityAttributeResourceTagKeyDefinitionTemplate(),
                    value: CustomEntityAttributeDefinitionType.AutomaticCustomEntityAttributeResourceTagKeyDefinitionTemplate
                },
                {
                    children:
                        <AutomaticCustomEntityAttributeResourceTagDefinitionTemplate
                            actionsRef={actions => typeToCustomEntityAttributeActionsMapRef.current[CustomEntityAttributeDefinitionType.AutomaticCustomEntityAttributeResourceTagDefinitionTemplate] = actions!}
                            disabled={
                                saveExecuting ||
                                !_.isNil(item) &&
                                type === CustomEntityAttributeDefinitionType.ManualCustomEntityAttributeDefinition ||
                                addOrEditReadonly &&
                                type !== CustomEntityAttributeDefinitionType.AutomaticCustomEntityAttributeResourceTagDefinitionTemplate}
                            readOnly={addOrEditReadonly}
                            onValidChange={validateType}/>,
                    disabled:
                        !_.isNil(item) &&
                        type === CustomEntityAttributeDefinitionType.ManualCustomEntityAttributeDefinition ||
                        addOrEditReadonly &&
                        type !== CustomEntityAttributeDefinitionType.AutomaticCustomEntityAttributeResourceTagDefinitionTemplate,
                    label: localization.fields.type.automaticCustomEntityAttributeResourceTagDefinitionTemplate(),
                    value: CustomEntityAttributeDefinitionType.AutomaticCustomEntityAttributeResourceTagDefinitionTemplate
                },
                {
                    children:
                        <AutomaticCustomEntityAttributeResourceTagPatternsDefinitionTemplate
                            actionsRef={actions => typeToCustomEntityAttributeActionsMapRef.current[CustomEntityAttributeDefinitionType.AutomaticCustomEntityAttributeResourceTagPatternDefinitionTemplate] = actions!}
                            disabled={
                                saveExecuting ||
                                !_.isNil(item) &&
                                type === CustomEntityAttributeDefinitionType.ManualCustomEntityAttributeDefinition ||
                                addOrEditReadonly &&
                                type !== CustomEntityAttributeDefinitionType.AutomaticCustomEntityAttributeResourceTagPatternDefinitionTemplate}
                            readOnly={addOrEditReadonly}
                            onValidChange={validateType}/>,
                    disabled:
                        !_.isNil(item) &&
                        type === CustomEntityAttributeDefinitionType.ManualCustomEntityAttributeDefinition ||
                        addOrEditReadonly &&
                        type !== CustomEntityAttributeDefinitionType.AutomaticCustomEntityAttributeResourceTagPatternDefinitionTemplate,
                    label: localization.fields.type.automaticCustomEntityAttributeResourceTagPatternDefinitionTemplate(),
                    value: CustomEntityAttributeDefinitionType.AutomaticCustomEntityAttributeResourceTagPatternDefinitionTemplate
                }
            ],
            [type]);

    const theme = useTheme();
    return (
        <FormLayout
            disableContentPadding={true}
            footerOptions={{
                border: true,
                contentElement:
                    <Stack
                        alignItems="center"
                        direction="row"
                        justifyContent="flex-end"
                        spacing={1}>
                        {saveError && (
                            <Message
                                level="error"
                                title={
                                    _.isNil(item)
                                        ? localization.actions.add.error()
                                        : localization.actions.edit.error()
                                }/>)}
                        {saveExecuting && (
                            <CircularProgress
                                size={theme.spacing(2)}
                                variant="indeterminate"/>)}
                        {!addOrEditReadonly && (
                            <Button
                                disabled={!dialogValidation || saveExecuting}
                                onClick={upsertCustomEntityAttribute}>
                                {_.isNil(item)
                                    ? localization.actions.add.title()
                                    : localization.actions.edit.title()}
                            </Button>)}
                        {addOrEditReadonly && (
                            <Button
                                disabled={saveExecuting}
                                variant="outlined"
                                onClick={
                                    () =>
                                        setCustomEntityAttributesContext(
                                            context => ({
                                                ...context,
                                                addOrEditOpen: false
                                            })
                                        )}>
                                {localization.actions.close.title()}
                            </Button>)}
                    </Stack>
            }}
            titleOptions={{
                text:
                    _.isNil(item)
                        ? localization.title.add()
                        : addOrEditReadonly
                            ? localization.title.view()
                            : localization.title.edit()
            }}>
            <Stack
                spacing={2}
                sx={{
                    height: "100%",
                    padding: theme.spacing(3)
                }}>
                <Stack spacing={2}>
                    <Stack
                        spacing={1}
                        sx={{
                            height: "100%",
                            paddingBottom: theme.spacing(1)
                        }}>
                        <Typography sx={{ fontSize: "13px" }}>
                            {localization.fields.colorIndex()}
                        </Typography>
                        <Stack
                            direction="row"
                            spacing={1}>
                            {_.map(
                                theme.palette.entityAttribute.custom,
                                (customEntityAttributeColor, customEntityAttributeColorIndex) =>
                                    <Box
                                        key={customEntityAttributeColorIndex}
                                        onClick={() => !addOrEditReadonly && !saveExecuting && setColorIndex(customEntityAttributeColorIndex)}>
                                        <Attribute
                                            backgroundColor={theme.palette.opacity(customEntityAttributeColor, 0.2)}
                                            borderColor={customEntityAttributeColor}
                                            item={
                                                colorIndex === customEntityAttributeColorIndex &&
                                                <Stack
                                                    sx={{
                                                        alignItems: "center",
                                                        height: "100%",
                                                        justifyContent: "center",
                                                        width: "100%"
                                                    }}>
                                                    <SelectedIcon/>
                                                </Stack>}
                                            sx={{
                                                color: theme.palette.text.secondary,
                                                cursor: "pointer",
                                                fontSize: "24px",
                                                height: theme.spacing(3.75),
                                                width: theme.spacing(6.25)
                                            }}/>
                                    </Box>)}
                        </Stack>
                    </Stack>
                    <RadioGroup
                        disabled={saveExecuting}
                        items={items}
                        selectedValue={type}
                        onChange={type => setType(type)}/>
                </Stack>
            </Stack>
        </FormLayout>);
}

enum CustomEntityAttributeDefinitionType {
    ManualCustomEntityAttributeDefinition,
    AutomaticCustomEntityAttributeResourceTagKeyDefinitionTemplate,
    AutomaticCustomEntityAttributeResourceTagDefinitionTemplate,
    AutomaticCustomEntityAttributeResourceTagPatternDefinitionTemplate
}