import { TimeFormatter, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../..";
import { Contract, InfoItem } from "../../../../../../../../../../../common";
import { EntitiesInfoCard, Info } from "../../../../../components";
import { useCustomEntityPropertyInfoItemElements } from "../../useCustomEntityPropertyInfoItemElements";
import { useOciCommonIamPrincipalInfoItemElements } from "../useOciCommonIamPrincipalInfoItemElements";
import { useOciDefaultResourceInfoItemElements } from "../useOciDefaultResourceInfoItemElements";
import { Credentials } from "./components";

export function useOciIamUserDefinition(resourceModel: Contract.OciResourceModel, options?: DefinitionOptions) {
    const userModel = resourceModel as Contract.OciIamUserModel;
    const user = userModel.entity as Contract.OciIamUser;
    const commonIamPrincipalInfoItemElements = useOciCommonIamPrincipalInfoItemElements(userModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useOciDefaultResourceInfoItemElements(resourceModel);

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.oci.useOciIamUserDefinition",
            () => ({
                info: {
                    cards: {
                        groupIds: "Groups"
                    },
                    items: {
                        mail: "Mail",
                        mfaEnrolled: {
                            false: "Not enrolled",
                            title: "MFA",
                            true: "Enrolled"
                        },
                        signInTime: {
                            empty: "Not seen",
                            title: "Sign-in Time"
                        },
                        status: "Status",
                        userName: "Username"
                    }
                },
                tabs: {
                    credentials: "Credentials"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <Credentials userModel={userModel}/>,
                localization.tabs.credentials(),
                "credentials")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    commonIamPrincipalInfoItemElements.domainIdInfoItemElement,
                    commonIamPrincipalInfoItemElements.descriptionInfoItemElement,
                    <InfoItem
                        key="userName"
                        location="all"
                        title={localization.info.items.userName()}
                        value={user.userName}/>,
                    <InfoItem
                        key="mail"
                        location="all"
                        title={localization.info.items.mail()}
                        value={user.mail}/>,
                    commonIamPrincipalInfoItemElements.enabledInfoItemElement,
                    <InfoItem
                        emptyValue={localization.info.items.signInTime.empty()}
                        key="signInTime"
                        title={localization.info.items.signInTime.title()}
                        value={
                            _.isNil(user.signInTime)
                                ? undefined
                                : TimeFormatter.profileFormatDateTime(user.signInTime)}/>,
                    <InfoItem
                        key="mfaEnrolled"
                        location="all"
                        title={localization.info.items.mfaEnrolled.title()}
                        value={
                            user.mfaEnrolled
                                ? localization.info.items.mfaEnrolled.true()
                                : localization.info.items.mfaEnrolled.false()}/>
                ]}
                options={options?.infoOptions}>
                <EntitiesInfoCard
                    entityIdsOrModels={userModel.groupIds}
                    entityTypeName={Contract.TypeNames.OciIamGroup}
                    title={localization.info.cards.groupIds()}/>
            </Info>,
        sensitive: false
    });
}