import { Contract } from "../../../../../controllers";
import { useTenantNameTranslator } from "../../../../../hooks";
import { EntityDefinition } from "../useDefinition";

export function useGcpTenantEntityDefinition(entityModel: Contract.EntityModel) {
    const tenantNameTranslator = useTenantNameTranslator();
    return new EntityDefinition(
        entityModel,
        {
            textDisplayName: tenantNameTranslator(entityModel.id, { includeRawId: true })
        });
}