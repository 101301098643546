import { Function0 } from "lodash";
import { Cache, CacheKey } from "./cache";

const operationKeyToResultCache = new Cache<Promise<any> | any | undefined>();

export function clearOperation(key: CacheKey) {
    operationKeyToResultCache.remove(key);
}

export function clearOperations() {
    operationKeyToResultCache.removeAll();
}

export function executeOperation<TResult>(
    key: CacheKey,
    operation: Function0<Promise<TResult>>):
    TResult {
    if (!operationKeyToResultCache.has(key)) {
        const operationPromise =
            operation().
                then(
                    result => {
                        if (Object.is(operationKeyToResultCache.get(key), operationPromise)) {
                            operationKeyToResultCache.set(key, result);
                        }
                    },
                    error => {
                        if (Object.is(operationKeyToResultCache.get(key), operationPromise)) {
                            operationKeyToResultCache.set(key, new Error(`key=${key}\nerror=${error}`));
                        }
                    });
        operationKeyToResultCache.set(key, operationPromise);
    }

    const operationResult = operationKeyToResultCache.get(key);
    if (operationResult instanceof Promise ||
        operationResult instanceof Error) {
        throw operationResult;
    }

    return operationResult;
}