import { Optional, Step, Steps, useLocalization } from "@infrastructure";
import { Box, Typography } from "@mui/material";
import _ from "lodash";
import React, { ReactNode, useMemo } from "react";
import { Contract, entityModelStore, EntityPropertyHelper, RiskPolicyTypeMetadataHelper, RiskTypeMetadataModelHelper, useRiskPolicyTranslator } from "../../../../../../../../../common";
import { RiskView } from "../../../../../utilities";
import { RiskDefinition, RiskDefinitionContextItem, RiskDefinitionSection, RiskDefinitionSectionCategory, RiskDefinitionSectionGroup } from "../../../utilities";
import { ContextItemSteps, Resolve } from "../components";
import { useResourceOwnerRiskDefinitionSection } from "./useResourceOwnerRiskDefinitionSection";

export type CommonSectionAndDetailsDefinitionOptions = {
    contextSectionElement?: ReactNode;
    sections?: (RiskDefinitionSection | RiskDefinitionSectionGroup)[];
};

export function useCommonSectionsAndDescriptionDefinition(
    description: string,
    getResolutionSectionSteps: () => (React.ReactNode | Step | string | undefined)[],
    riskModel: Contract.RiskModel,
    getContextItems?: () => Optional<RiskDefinitionContextItem>[],
    options?: CommonSectionAndDetailsDefinitionOptions): RiskDefinition {
    const { risk } = riskModel;
    const resourceOwnerRiskDefinitionSection = useResourceOwnerRiskDefinitionSection(riskModel);
    const riskedEntityModels = entityModelStore.useGet(risk.riskedEntityIds);
    const unknownRiskedEntityModelExists =
        useMemo(
            () =>
                _.every(
                    riskedEntityModels,
                    riskedEntityModel => riskedEntityModel.unknown),
            [riskedEntityModels]);
    const contextSectionElement =
        _.isNil(getContextItems) || unknownRiskedEntityModelExists
            ? options?.contextSectionElement
            : <ContextItemSteps
                getContextItems={getContextItems}
                risk={risk}/>;
    const resolutionSectionSteps =
        useMemo(
            () =>
                risk.status === Contract.RiskStatus.Closed ||
                unknownRiskedEntityModelExists
                    ? []
                    : _.filter(getResolutionSectionSteps()),
            [getResolutionSectionSteps, risk, unknownRiskedEntityModelExists]);
    const riskPolicyConfigurationTypeName = RiskTypeMetadataModelHelper.get(risk.typeName).policyConfigurationTypeName;

    const riskPolicyTranslator = useRiskPolicyTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.useCommonSectionsAndDescriptionDefinition",
            () => ({
                sections: {
                    context: "Context",
                    policyDescription: "Policy",
                    resolution: "Remediation steps"
                }
            }));

    return useMemo(
        () => ({
            description,
            sections: _.filter([
                new RiskDefinitionSection(
                    <Typography sx={{ whiteSpace: "pre-wrap" }}>
                        {riskPolicyTranslator(
                            RiskPolicyTypeMetadataHelper.get(riskPolicyConfigurationTypeName).category === Contract.RiskPolicyCategory.Custom
                                ? risk.policyId
                                : riskPolicyConfigurationTypeName).description}
                    </Typography>,
                    localization.sections.policyDescription(),
                    {
                        resolvedRiskVisible: true
                    }),
                _.isNil(contextSectionElement)
                    ? undefined
                    : new RiskDefinitionSection(
                        contextSectionElement,
                        localization.sections.context()),
                ...(EntityPropertyHelper.showRiskResourceOwner(riskModel)
                    ? [resourceOwnerRiskDefinitionSection]
                    : []),
                ...(options?.sections ?? []),
                ...(_.isEmpty(resolutionSectionSteps) &&
                _.isEmpty(risk.resolutionChanges)
                    ? []
                    : [new RiskDefinitionSection(
                        <Steps variant="plainNumbers">
                            {resolutionSectionSteps}
                        </Steps>,
                        localization.sections.resolution(),
                        {
                            actionsElement:
                                _.isEmpty(risk.resolutionChanges)
                                    ? undefined
                                    : <Box
                                        alignItems="center"
                                        justifyContent="flex-end"
                                        sx={{
                                            display: "flex",
                                            width: "100%"
                                        }}>
                                        <Resolve/>
                                    </Box>,
                            categoryView: {
                                category: RiskDefinitionSectionCategory.Resolution,
                                view: RiskView.ResolutionConsole
                            }
                        })])
            ]) as (RiskDefinitionSection | RiskDefinitionSectionGroup)[]
        }),
        [riskModel, getResolutionSectionSteps, description, resourceOwnerRiskDefinitionSection, resolutionSectionSteps, unknownRiskedEntityModelExists]);
}