﻿import { map } from "@infrastructure";
import _ from "lodash";
import { EntityTypeNameTranslatorOptions } from "../../../common";
import * as Contract from "../../../common/controllers/contract";

export class GcpRoleBindingHelper {

    public static getDisplayName(
        entityTypeNameTranslator: (typeName: string, options?: EntityTypeNameTranslatorOptions) => string,
        roleBindingModel: Contract.GcpIamRoleBindingModel) {
        return roleBindingModel.unknown || _.isNil(roleBindingModel.scopeResourceTypeName )
            ? `${roleBindingModel.entity.displayName}`
            : `${roleBindingModel.entity.displayName} (${entityTypeNameTranslator(roleBindingModel.scopeResourceTypeName)})`;
    }

    public static getPrincipalTypeSortValue(type: Contract.GciPrincipalType) {
        return map(
            type,
            {
                [Contract.GciPrincipalType.AllUsers]: () => 0,
                [Contract.GciPrincipalType.AllAuthenticatedUsers]: () => 1,
                [Contract.GciPrincipalType.ProjectOwners]: () => 2,
                [Contract.GciPrincipalType.ProjectEditors]: () => 3,
                [Contract.GciPrincipalType.ProjectViewers]: () => 4,
                [Contract.GciPrincipalType.Group]: () => 5,
                [Contract.GciPrincipalType.Domain]: () => 6,
                [Contract.GciPrincipalType.User]: () => 7,
                [Contract.GciPrincipalType.ServiceAccount]: () => 8,
                [Contract.GciPrincipalType.GkeServiceAccount]: () => 9,
                [Contract.GciPrincipalType.WorkforcePoolPrincipal]: () => 10,
                [Contract.GciPrincipalType.WorkforcePoolPrincipalSet]: () => 11,
                [Contract.GciPrincipalType.WorkloadIdentityPoolPrincipal]: () => 12,
                [Contract.GciPrincipalType.WorkloadIdentityPoolPrincipalSet]: () => 13
            });
    }
}