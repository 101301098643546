import { DialogMenuItem, TimeFormatter, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../..";
import { useGcpCommonScopeResourceInfoItemElements, useGcpDefaultResourceInfoItemElements } from "..";
import { Contract, InfoItem, usePrincipalModelAccess } from "../../../../../../../../../../../common";
import { GenerateGcpIdentityNonexcessiveRoleDialog, useGcpIamServiceAccountDisabledTranslator } from "../../../../../../../../../../../tenants";
import { EntitiesInfoCard, GciIdentityActivityInfoItem, Info, PrincipalSecurityStatsStat } from "../../../../../components";
import { GenerateEntityNonexcessivePermitterIcon } from "../../../../../icons";
import { GciDirectoryPrincipalGcpRoleBindings, GcpIamServiceAccountOriginatorResourcesInfoItem, GcpScopeResourceRoleBindings } from "../../../components";
import { useCustomEntityPropertyInfoItemElements } from "../../useCustomEntityPropertyInfoItemElements";
import { UserManagedKeys } from "./components";

export function useGcpIamServiceAccountDefinition(scopeResourceModel: Contract.GcpScopeResourceModel, options?: DefinitionOptions) {
    const commonScopeResourceInfoItemElements = useGcpCommonScopeResourceInfoItemElements(scopeResourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(scopeResourceModel);
    const defaultResourceInfoItemElements = useGcpDefaultResourceInfoItemElements(scopeResourceModel);
    const serviceAccount = scopeResourceModel.entity as Contract.GcpIamServiceAccount;
    const serviceAccountModel = scopeResourceModel as Contract.GcpIamServiceAccountModel;
    const serviceAccountModelAccess = usePrincipalModelAccess<Contract.ServiceIdentityModelAccess>(serviceAccountModel.id);
    const serviceAccountProfile = scopeResourceModel.entityProfile as Contract.GcpIamServiceAccountProfile;

    const iamServiceAccountDisabledTranslator = useGcpIamServiceAccountDisabledTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpIamServiceAccountDefinition",
            () => ({
                info: {
                    cards: {
                        groupIds: "Group Memberships"
                    },
                    items: {
                        disabled: "Status",
                        mail: "Mail",
                        signInTime: {
                            empty: "Not seen",
                            title: "Sign-in Time"
                        }
                    }
                },
                tabs: {
                    principalRoleBindings: "Bindings",
                    resourceRoleBindings: "Resource Role Bindings",
                    userManagedKeys: "User Managed Keys"
                },
                topbar: {
                    menu: {
                        generate: "Generate Custom Role"
                    }
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Overview,
                <UserManagedKeys serviceAccount={serviceAccount}/>,
                localization.tabs.userManagedKeys(),
                "userManagedKeys"),
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <GciDirectoryPrincipalGcpRoleBindings
                    csvExportFilePrefix={localization.tabs.principalRoleBindings()}
                    gcpRoleBindingIds={serviceAccountModel.identityRoleBindingIds}
                    principalId={serviceAccount.id}/>,
                localization.tabs.principalRoleBindings(),
                "principalRoleBindings"),
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <GcpScopeResourceRoleBindings
                    csvExportFilePrefix={localization.tabs.resourceRoleBindings()}
                    scopeResourceModel={serviceAccountModel}/>,
                localization.tabs.resourceRoleBindings(),
                "resourceRoleBindings")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    commonScopeResourceInfoItemElements.accessLevelInfoItemElement,
                    <InfoItem
                        key="mail"
                        location="all"
                        title={localization.info.items.mail()}
                        value={serviceAccount.mail}/>,
                    <InfoItem
                        key="disabled"
                        location="all"
                        title={localization.info.items.disabled()}
                        value={iamServiceAccountDisabledTranslator(serviceAccount.disabled)}/>,
                    <InfoItem
                        emptyValue={localization.info.items.signInTime.empty()}
                        key="signInTime"
                        title={localization.info.items.signInTime.title()}
                        value={
                            _.isNil(serviceAccountProfile?.signInTime)
                                ? undefined
                                : TimeFormatter.profileFormatDateTime(serviceAccountProfile.signInTime)}/>,
                    <GciIdentityActivityInfoItem
                        identityModel={serviceAccountModel}
                        key="activityTime"/>,
                    <GcpIamServiceAccountOriginatorResourcesInfoItem
                        key="originatorResourceIds"
                        location="all"
                        originatorResourceIds={serviceAccountModelAccess.originatorEntityIds}/>
                ]}
                options={options?.infoOptions}>
                <EntitiesInfoCard
                    entityIdsOrModels={serviceAccountModel.groupIds}
                    entityTypeName={Contract.TypeNames.GciDirectoryGroup}
                    title={localization.info.cards.groupIds()}/>
            </Info>,
        sensitive: false,
        statElements:
            _.filter([
                _.isNil(serviceAccountModelAccess?.permissions)
                    ? undefined
                    : <PrincipalSecurityStatsStat
                        analyzingReason={serviceAccountModel.analyzing?.reason}
                        analyzingTenants={
                            _.isNil(serviceAccountModel.analyzing?.tenantIds)
                                ? undefined
                                : {
                                    ids: serviceAccountModel.analyzing!.tenantIds,
                                    typeName: Contract.TypeNames.GcpTenantEntity
                                }}
                        securityStats={serviceAccountModelAccess.permissions!.excessivePermissionActionStats}
                        typeName={Contract.TypeNames.GcpIamServiceAccount}/>
            ]),
        topbarMenuItems:
            _.isNil(serviceAccountModel.systemDeletionTime)
                ? [
                    new DialogMenuItem(
                        onClose =>
                            <GenerateGcpIdentityNonexcessiveRoleDialog
                                identityModel={serviceAccountModel}
                                onClose={onClose}/>,
                        localization.topbar.menu.generate(),
                        { icon: <GenerateEntityNonexcessivePermitterIcon/> })
                ]
                : undefined
    });
}