import { NoneIcon } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract } from "../../../../../../../../../../../common";
import { useVulnerabilityReferenceAuthorityTranslator } from "../../hooks";
import { VulnerabilityReferenceAuthorityIcon } from "./components";

type VulnerabilityReferenceAuthorityProps = {
    authority?: Contract.VulnerabilityReferenceAuthority;
};

export function VulnerabilityReferenceAuthority({ authority }: VulnerabilityReferenceAuthorityProps) {
    const vulnerabilityReferenceAuthorityTranslator = useVulnerabilityReferenceAuthorityTranslator();
    return (
        _.isNil(authority)
            ? <NoneIcon/>
            : <Stack
                alignItems="center"
                direction="row"
                spacing={1.5}
                sx={{ width: "100%" }}>
                <VulnerabilityReferenceAuthorityIcon
                    authority={authority}
                    sx={{ fontSize: "18px" }}/>
                <Typography noWrap={true}>
                    {vulnerabilityReferenceAuthorityTranslator(authority)}
                </Typography>
            </Stack>);
}