import { NoneIcon, Shadows, Tooltip } from "@infrastructure";
import { Stack, SxProps } from "@mui/material";
import _ from "lodash";
import React, { ReactNode } from "react";
import { useTheme } from "../themes";

export interface CountsChartProps<TItem> {
    children: (item: TItem) => ReactNode;
    getColor: (item: TItem) => string;
    getDisabled?: (item: TItem) => boolean;
    getTooltipTitle: (item: TItem) => string | undefined;
    items: TItem[];
    itemSx?: SxProps;
    onClick?: (item: TItem) => void;
    sx?: SxProps;
}

export function ItemsChart<TItem>({ children, getColor, getDisabled, getTooltipTitle, items, itemSx, onClick, sx }: CountsChartProps<TItem>) {
    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            flexDirection="row"
            sx={sx}>
            {_.map(
                items,
                (item, index) => (
                    <Tooltip
                        disabled={!_.isNil(getDisabled) && getDisabled(item)}
                        key={index}
                        titleOrGetTitle={getTooltipTitle(item)}>
                        <Stack
                            alignItems="center"
                            justifyContent="center"
                            sx={{
                                "&:hover": {
                                    ...(!_.isNil(getDisabled) && getDisabled(item)
                                        ? {}
                                        : {
                                            borderWidth: theme.spacing(0.25),
                                            boxShadow: theme.shadows[Shadows.Tooltip]
                                        })
                                },
                                border: theme.border.primary,
                                borderColor:
                                    !_.isNil(getDisabled) && getDisabled(item)
                                        ? theme.border.primary
                                        : getColor(item),
                                borderRadius: theme.spacing(0.75),
                                borderWidth: theme.px(1),
                                color: theme.palette.text.primary,
                                cursor:
                                    !_.isNil(getDisabled) && getDisabled(item)
                                        ? "default"
                                        : "pointer",
                                margin: theme.spacing(0.5),
                                maxHeight: theme.spacing(2.75),
                                minHeight: theme.spacing(2.75),
                                minWidth: theme.spacing(4),
                                ...itemSx
                            }}
                            onClick={
                                event => {
                                    event.preventDefault();
                                    if (_.isNil(getDisabled) || !getDisabled(item)) {
                                        onClick?.(item);
                                    }
                                }}>
                            {children(item) ?? <NoneIcon sx={{ color: theme.border.primary }}/>}
                        </Stack>
                    </Tooltip>))}
        </Stack>);
}