import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../../../../../../common";

export function useVulnerabilityReferenceAuthorityTranslator() {
    const localization =
        useLocalization(
            "views.customer.workloadAnalysis.vulnerabilities.profile.sources.hooks.useVulnerabilityReferenceAuthorityTranslator",
            () => ({
                [Contract.TypeNames.VulnerabilityReferenceAuthority]: {
                    [Contract.VulnerabilityReferenceAuthority.Nvd]: "NVD",
                    [Contract.VulnerabilityReferenceAuthority.TenableResearch]: "Tenable Research"
                }
            }));

    return (type: Contract.VulnerabilityReferenceAuthority) => localization[Contract.TypeNames.VulnerabilityReferenceAuthority][type]();
}