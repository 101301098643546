﻿import _ from "lodash";
import { LineMetadata, useTheme } from "..";

export function useGetOpenBehaviorRiskPolicyMetadatas() {
    const theme = useTheme();
    return (openBehaviorRiskPolicyConfigurationTranslatedTypeNames: string[]) =>
        _.map(
            openBehaviorRiskPolicyConfigurationTranslatedTypeNames,
            (openBehaviorRiskPolicyConfigurationTranslatedTypeName, openBehaviorRiskPolicyConfigurationTranslatedTypeNameIndex) =>
                new LineMetadata(
                    theme.palette.risk.behavior[openBehaviorRiskPolicyConfigurationTranslatedTypeNameIndex],
                    openBehaviorRiskPolicyConfigurationTranslatedTypeName,
                    openBehaviorRiskPolicyConfigurationTranslatedTypeName));
}