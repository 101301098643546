import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../../../common";
import { RiskDefinitionSection } from "../../../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../../../useCommonSectionsAndDescriptionDefinition";
import { ResourcesExternalConsoleLink } from "../../../../components";
import { useGetGcpArtifactRegistryRiskContext, useGetGcpScopeResourcePublicAccessExistsRiskContext } from "../../../contexts";
import { ScopeResourceRoleBindingTableSection } from "../components";

export function useGcpArtifactRegistryPublicAccessExistsRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.GcpArtifactRegistryPublicAccessExistsRisk;
    const registryModel = entityModelStore.useGet(risk.entityId) as Contract.GcpArtifactRegistryModel;

    const getGcpArtifactRegistryRiskContext = useGetGcpArtifactRegistryRiskContext();
    const getGcpScopeResourcePublicAccessExistsRiskContext = useGetGcpScopeResourcePublicAccessExistsRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.publicAccess.hooks.useGcpArtifactRegistryPublicAccessExistsRiskDefinition",
            () => ({
                description: "{{registry}} is publicly accessible via IAM roles",
                sections: {
                    resolution: {
                        step1: "In the repositories list, check repository **{{registry}}** and go the **Permissions** tab on the right pane",
                        step2: "Before changing the permissions, verify that identities can access the repository without public access",
                        step3: "Delete the role bindings to allUsers / allAuthenticatedUsers"
                    },
                    roleBindingIds: "Role Bindings"
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            registry:
                <Entity
                    entityIdOrModel={registryModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            <ResourcesExternalConsoleLink
                key={risk.tenantId}
                tenantId={risk.tenantId}
                typeName={registryModel.entity.typeName}/>,
            localization.sections.resolution.step1({
                registry:
                    <Entity
                        entityIdOrModel={registryModel}
                        variant="text"/>
            }),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3()
        ],
        riskModel,
        () => {
            const registryRiskContext = getGcpArtifactRegistryRiskContext(registryModel);
            const scopeResourcePublicAccessExistsRiskContext = getGcpScopeResourcePublicAccessExistsRiskContext(risk);
            return [
                registryRiskContext.generalInformation,
                registryRiskContext.sensitive,
                registryRiskContext.repositories,
                scopeResourcePublicAccessExistsRiskContext.permissionActions,
                registryRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        },
        {
            sections: [
                new RiskDefinitionSection(
                    <ScopeResourceRoleBindingTableSection
                        csvExportFilePrefixes={[localization.sections.roleBindingIds()]}
                        risk={risk}/>,
                    localization.sections.roleBindingIds())
            ]
        });
}