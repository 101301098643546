import { useLocalization } from "@infrastructure";
import { Contract } from "../../..";

export function useReportTypeTranslator() {
    const localization =
        useLocalization(
            "common.report.hooks.useReportTypeTranslator",
            () => ({
                [Contract.TypeNames.ReportType]: {
                    [Contract.ReportType.AwsIamPolicies]: "AWS Policy Assignments",
                    [Contract.ReportType.AwsIamUserAccessKeys]: "AWS Access Keys",
                    [Contract.ReportType.AwsSsoUserTenantAssignments]: "AWS Identity Center Assignments",
                    [Contract.ReportType.AzureAuthorizationRoleAssignments]: "Azure Role Assignments",
                    [Contract.ReportType.CodeRisks]: "IaC Findings",
                    [Contract.ReportType.Compliance]: "Compliance Requirements Summary",
                    [Contract.ReportType.ComplianceScopes]: "Compliance Accounts Summary",
                    [Contract.ReportType.Dashboard]: "Management",
                    [Contract.ReportType.Events]: "Activity Log",
                    [Contract.ReportType.GcpIamRoleBindings]: "GCP IAM Role Bindings",
                    [Contract.ReportType.GcpIamServiceAccountUserManagedKeys]: "GCP User Managed Keys",
                    [Contract.ReportType.Inventory]: "Inventory",
                    [Contract.ReportType.RiskPolicy]: "Misconfigurations",
                    [Contract.ReportType.Risks]: "Findings",
                    [Contract.ReportType.Vulnerabilities]: "Vulnerabilities"
                }
            }));

    return (reportType: Contract.ReportType) =>
        localization[Contract.TypeNames.ReportType].translate(reportType);
}