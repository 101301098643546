import { TimeFormatter, useLocalization } from "@infrastructure";
import React from "react";
import { Contract, entityModelStore, InfoItem, useTenantNameTranslator } from "../../../../../../../../../../common";
import { EntitiesInfoItem } from "../../../../components";
import { useCommonEntityInfoItemElements } from "../useCommonEntityInfoItemElements";

export function useCiCommonEntityInfoItemElements(entityModel: Contract.CiEntityModel) {
    const commonEntityInfoItemElements = useCommonEntityInfoItemElements(entityModel);
    const entity = entityModel.entity as Contract.CiEntity;
    const tenantEntityModel = entityModelStore.useGet(entityModel.tenantId);

    const tenantNameTranslator = useTenantNameTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.ci.useCiCommonEntityInfoItemElements",
            () => ({
                syncTime: "Sync Time",
                tenantId: "Registry"
            }));

    return {
        ...commonEntityInfoItemElements,
        syncTimeInfoElement:
            <InfoItem
                key="syncTime"
                title={localization.syncTime()}
                value={TimeFormatter.profileFormatDateTime(entity.syncTime)}/>,
        tenantInfoElement:
            tenantEntityModel.unknown
                ? <InfoItem
                    key="tenantId"
                    title={localization.tenantId()}
                    value={tenantNameTranslator(entity.tenantId)}/>
                : <EntitiesInfoItem
                    entityIdsOrModels={tenantEntityModel}
                    entityTypeName={tenantEntityModel.entity.typeName}
                    key="tenantId"
                    title={localization.tenantId()}/>
    };
}