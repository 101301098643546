import { useTheme } from "@mui/material";

export function useCommonStyle() {
    const theme = useTheme();

    return {
        ".slider": {
            borderRadius: theme.spacing(0.75)
        }
    };
}