import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";
import { useTheme } from "../../../themes";

export function VmwareIcon(props: SvgIconProps) {
    const theme = useTheme();
    return (
        <SvgIcon {...props}>
            <path
                d="M2.51545 7.71002C2.21395 7.02002 1.46845 6.70502 0.772452 7.02902C0.0749519 7.35302 -0.181548 8.16302 0.133452 8.85602L3.05695 15.51C3.51595 16.554 4.00195 17.1 4.91095 17.1C5.88145 17.1 6.30595 16.5045 6.76495 15.51L9.34045 9.63602C9.36686 9.56068 9.41691 9.49589 9.48313 9.45129C9.54935 9.40669 9.62821 9.38468 9.70795 9.38852C9.92695 9.38852 10.113 9.57302 10.113 9.81902V15.504C10.113 16.3815 10.575 17.1 11.469 17.1C12.3615 17.1 12.843 16.38 12.843 15.5055V10.8525C12.843 9.95402 13.4579 9.37202 14.2949 9.37202C15.1319 9.37202 15.6884 9.97502 15.6884 10.8525V15.5055C15.6884 16.3815 16.1535 17.1 17.046 17.1C17.9385 17.1 18.4229 16.38 18.4229 15.5055V10.8525C18.4229 9.95402 19.035 9.37202 19.872 9.37202C20.7074 9.37202 21.267 9.97502 21.267 10.8525V15.5055C21.267 16.3815 21.7319 17.1 22.6244 17.1C23.5154 17.1 23.9999 16.38 23.9999 15.5055V10.2105C23.9999 8.26352 22.506 6.90002 20.7075 6.90002C18.912 6.90002 17.7869 8.20202 17.7869 8.20202C17.1869 7.38902 16.3634 6.90152 14.9699 6.90152C13.4969 6.90152 12.21 8.20202 12.21 8.20202C11.61 7.38902 10.593 6.90152 9.74995 6.90152C8.44495 6.90152 7.40995 7.50152 6.77695 9.01652L4.91095 13.623L2.51545 7.71002Z"
                fill={theme.palette.text.primary}/>
        </SvgIcon>);
}