import { EmptyMessageText, useExecuteOperation, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, EntityController, InfoCard } from "../../../../../../../../../../../../common";
import { EntitiesInfoItem } from "../../../../../../components";

type ResourcesInfoCardProps = {
    storageAccountModel: Contract.AzureStorageStorageAccountModel;
};

export function ResourcesInfoCard({ storageAccountModel }: ResourcesInfoCardProps) {
    const [{ blobContainerIds, fileShareIds, queueIds, tableIds }] =
        useExecuteOperation(
            [ResourcesInfoCard, storageAccountModel.id],
            async () => await EntityController.getAzureStorageAccountChildResourceDatas(new Contract.EntityControllerGetAzureStorageAccountChildResourceDatasRequest(storageAccountModel.id)));

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.azure.useAzureStorageStorageAccountDefinition.resourcesInfoCard",
            () => ({
                emptyMessage: "No resources",
                title: "Resources"
            }));
    return (
        <InfoCard
            emptyMessage={new EmptyMessageText(localization.emptyMessage())}
            title={localization.title()}>
            {_.isEmpty(blobContainerIds) &&
            _.isEmpty(fileShareIds) &&
            _.isEmpty(tableIds) &&
            _.isEmpty(queueIds)
                ? undefined
                : <InfoCard columns={true}>
                    <EntitiesInfoItem
                        entityIdsOrModels={blobContainerIds}
                        entityTypeName={Contract.TypeNames.AzureStorageStorageAccountBlobContainer}/>
                    <EntitiesInfoItem
                        entityIdsOrModels={fileShareIds}
                        entityTypeName={Contract.TypeNames.AzureStorageStorageAccountFileShare}/>
                    <EntitiesInfoItem
                        entityIdsOrModels={tableIds}
                        entityTypeName={Contract.TypeNames.AzureStorageStorageAccountTable}/>
                    <EntitiesInfoItem
                        entityIdsOrModels={queueIds}
                        entityTypeName={Contract.TypeNames.AzureStorageStorageAccountQueue}/>
                </InfoCard>}
        </InfoCard>);
}