import { Stack, SxProps, Typography } from "@mui/material";
import _ from "lodash";
import React, { Fragment, ReactNode, useMemo } from "react";
import { Link, Optional, useLocalization } from "@infrastructure";
import { Contract, getScopeConfigurationValueType, getScopeConfigurationValueTypeValue, RadioGroup, RadioGroupItem, RiskPoliciesType, ScopeConfigurationValueType, ScopeLabel, UserHelper } from "../../../../../common";
import { useGetInheritedOverridableValue, useRiskPoliciesItemConfiguration } from "../hooks";
import { useConfigurationContext } from "./Configuration";

export type EnabledRadioGroupBaseProps = {
    children?: ReactNode;
    description: string;
    disabled?: boolean;
    documentationUrl?: string;
    getEnabled: (riskPolicyConfiguration: Contract.RiskPolicyConfiguration) => Optional<boolean>;
    riskPoliciesType: RiskPoliciesType;
    setEnabled: (riskPolicyConfiguration: Contract.RiskPolicyConfiguration, enabled: Optional<boolean>) => void;
    sx?: SxProps;
    title: string;
    tooltip?: string;
};

export function EnabledRadioGroupBase({ children, description, disabled, documentationUrl, getEnabled, riskPoliciesType, setEnabled, sx, title, ...props }: EnabledRadioGroupBaseProps) {
    const { item, riskPolicyConfiguration } = useConfigurationContext();
    const { generalInheritToggleEnabled } = useRiskPoliciesItemConfiguration(riskPoliciesType, item, riskPolicyConfiguration.scopeId);

    const { inheritDisabled, inherited, inheritedScopeId, inheritedValue, value: enabled } =
        useGetInheritedOverridableValue(
            getEnabled,
            riskPoliciesType,
            riskPolicyConfiguration,
            item);

    const localization =
        useLocalization(
            "views.customer.riskPolicies.enabledRadioGroupBase",
            () => ({
                description: {
                    documentationLink: "Learn more"
                },
                options: {
                    false: "Disabled",
                    inherit: "Inherit",
                    true: "Enabled"
                }
            }));

    const items =
        useMemo(
            () =>
                _<RadioGroupItem<ScopeConfigurationValueType>>([]).
                    concatIf(
                        !generalInheritToggleEnabled && !inheritDisabled,
                        {
                            label:
                                <ScopeLabel
                                    inheritedScopeId={
                                        !_.isNil(inheritedValue)
                                            ? inheritedScopeId
                                            : undefined}
                                    text={localization.options.inherit()}
                                    translatedInheritedValue={
                                        inheritedValue
                                            ? localization.options.true()
                                            : localization.options.false()}/>,
                            value: ScopeConfigurationValueType.Inherit
                        }).
                    concat(
                        {
                            label: <ScopeLabel text={localization.options.true()}/>,
                            value: ScopeConfigurationValueType.Enabled
                        },
                        {
                            label: <ScopeLabel text={localization.options.false()}/>,
                            value: ScopeConfigurationValueType.Disabled
                        }).
                    value(),
            [generalInheritToggleEnabled, inherited, inheritDisabled, inheritedValue, inheritedScopeId]);

    const userSecurityAdministrationRead =
        UserHelper.hasScopePermissions(
            riskPolicyConfiguration.scopeId,
            Contract.IdentityPermission.SecurityAdministrationRead);

    return (
        <Stack>
            <RadioGroup
                description={
                    <Fragment>
                        <Typography>
                            {description}
                        </Typography>
                        <Typography>
                            <Link
                                urlOrGetUrl={documentationUrl}
                                variant="external">
                                {localization.description.documentationLink()}
                            </Link>
                        </Typography>
                    </Fragment>}
                disabled={
                    disabled ||
                    generalInheritToggleEnabled && inherited ||
                    !userSecurityAdministrationRead}
                items={items}
                selectedValue={
                    generalInheritToggleEnabled && inherited
                        ? getScopeConfigurationValueType(inheritedValue)
                        : getScopeConfigurationValueType(enabled)}
                sx={sx}
                title={title}
                onChange={
                    (value: ScopeConfigurationValueType) =>
                        setEnabled(
                            riskPolicyConfiguration,
                            getScopeConfigurationValueTypeValue(value))}
                {...props}/>
            {children}
        </Stack>);
}