import { AddIcon, DataTableAction, DataTableActions, DataTableColumn, DataTableColumnRenderProps, EmptyMessageText, Link, makeContextProvider, useChangeEffect, useExecuteOperation, useLocalization } from "@infrastructure";
import { Button, Stack, Typography } from "@mui/material";
import _, { Dictionary, Function0 } from "lodash";
import React, { useRef } from "react";
import { ConfigurationController, Contract, CustomerConsoleAppUrlHelper, ItemTable, useTheme } from "../../../../../../common";
import { ActionsCell, AddOrEdit } from "./components";
import { useItems } from "./hooks";
import { IdentityProviderIcon } from "./icons";

export type AuthenticationItem = {
    applications: AuthenticationItemApplication[];
    authenticationType: AuthenticationType;
    configured: boolean;
    fieldKey: keyof Contract.ConfigurationControllerGetAuthenticationConfigurationResponse;
    identityProviderType: IdentityProviderType;
};

export type AuthenticationItemApplication = Contract.ConsoleAppType.Customer | Contract.ConsoleAppType.User;

export enum AuthenticationType {
    Oidc = "oidc",
    Saml = "saml"
}

class AuthenticationContext {
    constructor(
        public aadIdentityProviderEnabled: boolean,
        public addOrEditOpen: boolean | AuthenticationItem,
        public connectSupportError: boolean,
        public consoleAppTypeToSamlIdentityProviderMap: Dictionary<Contract.ApplicationCustomerConfigurationAuthenticationSamlIdentityProvider>,
        public executeGetAuthenticationConfiguration: Function0<Promise<void>>,
        public gciIdentityProviderEnabled: boolean) {
    }
}

export type IdentityProviderType = Contract.ApplicationCustomerConfigurationAuthenticationSamlIdentityProviderType | Contract.TenantType.Azure | Contract.TenantType.Gcp;

export const [useAuthenticationContext, useSetAuthenticationContext, useAuthenticationProvider] = makeContextProvider<AuthenticationContext>();

export function Authentication() {
    const [{ aadIdentityProviderEnabled, consoleAppTypeToSamlIdentityProviderMap, gciIdentityProviderEnabled }, executeGetAuthenticationConfiguration] =
        useExecuteOperation(
            Authentication,
            ConfigurationController.getAuthenticationConfiguration);

    const [{ addOrEditOpen }, setContext, ContextProvider] =
        useAuthenticationProvider(
            () =>
                new AuthenticationContext(
                    aadIdentityProviderEnabled,
                    false,
                    false,
                    consoleAppTypeToSamlIdentityProviderMap,
                    executeGetAuthenticationConfiguration,
                    gciIdentityProviderEnabled),
            [aadIdentityProviderEnabled, consoleAppTypeToSamlIdentityProviderMap, gciIdentityProviderEnabled]);

    const { configuredAuthenticationItems } =
        useItems(
            aadIdentityProviderEnabled,
            consoleAppTypeToSamlIdentityProviderMap,
            gciIdentityProviderEnabled);

    const localization =
        useLocalization(
            "views.customer.configuration.authentication",
            () => ({
                actions: {
                    addIdentity: "Add Identity Provider"
                },
                columns: {
                    application: {
                        options: {
                            [Contract.ConsoleAppType.Customer]: "Cloud Security",
                            [Contract.ConsoleAppType.User]: "JIT"
                        },
                        title: "Application"
                    },
                    identityProvider: {
                        options: {
                            [Contract.TenantType.Azure]: "Microsoft",
                            [Contract.TenantType.Gcp]: "Google",
                            [Contract.ApplicationCustomerConfigurationAuthenticationSamlIdentityProviderType.Aad]: "Microsoft",
                            [Contract.ApplicationCustomerConfigurationAuthenticationSamlIdentityProviderType.AwsSso]: "AWS IAM Identity Center",
                            [Contract.ApplicationCustomerConfigurationAuthenticationSamlIdentityProviderType.Gci]: "Google",
                            [Contract.ApplicationCustomerConfigurationAuthenticationSamlIdentityProviderType.Duo]: "Duo",
                            [Contract.ApplicationCustomerConfigurationAuthenticationSamlIdentityProviderType.Okta]: "Okta",
                            [Contract.ApplicationCustomerConfigurationAuthenticationSamlIdentityProviderType.OneLogin]: "OneLogin",
                            [Contract.ApplicationCustomerConfigurationAuthenticationSamlIdentityProviderType.Other]: "Unknown",
                            [Contract.ApplicationCustomerConfigurationAuthenticationSamlIdentityProviderType.PingIdentity]: "Ping Identity"
                        },
                        title: "Identity Provider"
                    },
                    type: {
                        options: {
                            [AuthenticationType.Oidc]: "OpenID Connect",
                            [AuthenticationType.Saml]: "SAML"
                        },
                        title: "Type"
                    }
                },
                empty: "No SSO configured",
                subtitle: {
                    description: "Add your identity provider to allow users/groups to log in to the Cloud Security Console and JIT Portal via SSO. {{link}}",
                    link: "Learn more"
                }
            }));

    const itemTableActionsRef = useRef<DataTableActions>();
    useChangeEffect(
        () => {
            itemTableActionsRef.current?.reload();
        },
        [aadIdentityProviderEnabled, consoleAppTypeToSamlIdentityProviderMap, gciIdentityProviderEnabled]);

    const theme = useTheme();
    return (
        <ContextProvider>
            <Typography sx={{ padding: theme.spacing(2, 3) }}>
                {localization.subtitle.description({
                    link:
                        <Link
                            urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsEnableConsoleLoginViaSsoRelativeUrl()}
                            variant="external">
                            {localization.subtitle.link()}
                        </Link>
                })}
            </Typography>
            {addOrEditOpen && <AddOrEdit/>}
            <ItemTable
                actionsRef={itemTableActionsRef}
                columnIdToDefaultSortDirectionMap={{
                    [TableColumnId.Type]: "desc"
                }}
                columnIdToGetItemValueMap={{
                    [TableColumnId.Application]: item => item.applications,
                    [TableColumnId.Type]: item => item.authenticationType,
                    [TableColumnId.IdentityProvider]: item => item.identityProviderType
                }}
                dataTableActions={[
                    <DataTableAction key="DataTableAction">
                        <Button
                            disabled={_.size(configuredAuthenticationItems) === 4}
                            size="small"
                            startIcon={<AddIcon/>}
                            onClick={
                                () =>
                                    setContext(
                                        context => ({
                                            ...context,
                                            addOrEditOpen: true
                                        }))}>
                            {localization.actions.addIdentity()}
                        </Button>
                    </DataTableAction>
                ]}
                defaultSortColumnIdOrIds={[TableColumnId.Type]}
                emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty()) }}
                getItemId={item => item.identityProviderType}
                items={configuredAuthenticationItems}
                showEmptyTable={true}
                variant="view">
                {() => [
                    <DataTableColumn
                        id={TableColumnId.IdentityProvider}
                        key={TableColumnId.IdentityProvider}
                        render={
                            ({ item }: DataTableColumnRenderProps<AuthenticationItem>) =>
                                <Stack
                                    alignItems="center"
                                    direction="row"
                                    spacing={0.75}>
                                    <IdentityProviderIcon
                                        sx={{
                                            height: "20px",
                                            width: "20px"
                                        }}
                                        type={item.identityProviderType}/>
                                    <Typography noWrap={true}>
                                        {localization.columns.identityProvider.options[item.identityProviderType]()}
                                    </Typography>
                                </Stack>}
                        title={localization.columns.identityProvider.title()}/>,
                    <DataTableColumn
                        id={TableColumnId.Type}
                        itemProperty={({ authenticationType }: AuthenticationItem) => localization.columns.type.options[authenticationType]()}
                        key={TableColumnId.Type}
                        title={localization.columns.type.title()}/>,
                    <DataTableColumn
                        id={TableColumnId.Application}
                        itemProperty={
                            ({ applications }: AuthenticationItem) =>
                                _(applications).
                                    map(application => localization.columns.application.options[application]()).
                                    join(", ")}
                        key={TableColumnId.Application}
                        title={localization.columns.application.title()}/>,
                    <DataTableColumn
                        id={TableColumnId.Actions}
                        key={TableColumnId.Actions}
                        render={
                            ({ item }: DataTableColumnRenderProps<AuthenticationItem>) =>
                                <ActionsCell
                                    identityProviderText={localization.columns.identityProvider.options[item.identityProviderType]()}
                                    item={item}/>}
                        sortOptions={{ enabled: false }}/>
                ]}
            </ItemTable>
        </ContextProvider>);
}

enum TableColumnId {
    Actions = "actions",
    Application = "application",
    IdentityProvider = "identityProvider",
    Type = "type"
}