import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function LogicmonitorIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                d="M7.82383 10.2905V18.8824L3 21.5713V7.84502L17.1123 0V5.12595L7.82383 10.2905ZM17.1123 5.11588V13.691L7.82383 18.8657V24L21.9362 16.1281V2.42702L17.1123 5.11588ZM14.1902 12.2946C13.7017 12.2946 13.4181 11.8951 13.4181 11.1969C13.4181 10.4986 13.7 10.0941 14.1902 10.0941C14.6803 10.0941 14.9622 10.497 14.9622 11.1969C14.9622 11.8968 14.6735 12.2946 14.1902 12.2946ZM10.7611 13.9159C10.2727 13.9159 9.98902 13.5165 9.98902 12.8182C9.98902 12.12 10.271 11.7155 10.7611 11.7155C11.2512 11.7155 11.5332 12.1183 11.5332 12.8182C11.5332 13.5181 11.2445 13.9159 10.7544 13.9159H10.7611Z"
                fill="#0B2040"/>
        </SvgIcon>);
}