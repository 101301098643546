import { ArrowDownIcon, ArrowUpIcon, NoneIcon, NumberFormatter, useLocalization } from "@infrastructure";
import { Grid2, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract, TimeRangeHelper } from "../../../../../../../../../../../../../common";

type ScoreProps = {
    currentPercentagePassed: number;
    percentagePassed: number;
    timeFrame: Contract.TimeFrame;
};

export function Score({ currentPercentagePassed, percentagePassed, timeFrame }: ScoreProps) {
    const localization =
        useLocalization(
            "views.customer.compliance.profile.item.summary.scoreSummary.score",
            () => ({
                timeFrame: "Last {{ time }} days"
            }));
    const percentageDelta = _.round(currentPercentagePassed, 2) - percentagePassed;
    return (
        <Grid2
            alignItems="center"
            columnGap={1}
            columns={8}
            container={true}
            wrap="nowrap">
            <Grid2 size={4}>
                <Typography noWrap={true}>
                    {localization.timeFrame({ time: TimeRangeHelper.getTimeFrameValue(timeFrame) })}
                </Typography>
            </Grid2>
            <Grid2 size={1.5}>
                <Typography noWrap={true}>
                    {NumberFormatter.percentage(percentagePassed)}
                </Typography>
            </Grid2>
            <Grid2 size={0.5}>
                {percentageDelta < 0
                    ? <ArrowDownIcon color="error"/>
                    : percentageDelta === 0
                        ? <NoneIcon/>
                        : <ArrowUpIcon color="success"/>}
            </Grid2>
            <Grid2 size={2}>
                <Typography
                    noWrap={true}
                    sx={{ textAlign: "end" }}>
                    {NumberFormatter.signedPercentage(percentageDelta)}
                </Typography>
            </Grid2>
        </Grid2>);
}