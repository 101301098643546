import React, { Dispatch, SetStateAction, useMemo } from "react";
import { Contract, Report as CommonReport, ReportDeliveryType, ReportOptions, ScopeHelper, scopeNodeModelStore, TenantHelper, useSelectedScopeId } from "../../../../../../common";
import { ReportData } from "../View";
import { useItems } from "./hooks";

type ReportProps = {
    reportData: ReportData;
    setReportData: Dispatch<SetStateAction<ReportData>>;
    tenantType: Contract.TenantType;
};

export function Report({ reportData, setReportData, tenantType }: ReportProps) {
    const { selectedScopeId } = useSelectedScopeId();

    const reportTenantTypes =
        useMemo(
            () => TenantHelper.ReportsTenantTypes,
            []);
    const activeScopeNodeMap = scopeNodeModelStore.useGetActiveScopeNodeMap([tenantType]);

    const tenantTypes =
        useMemo(
            () => activeScopeNodeMap[ScopeHelper.customerId].getTenantTypes(reportTenantTypes),
            [activeScopeNodeMap]);

    const items = useItems(reportData, tenantTypes);
    const reportOptions =
        useMemo(
            (): ReportOptions =>
                ({
                    reportType: Contract.ReportType.Inventory,
                    scheduleReportType: ReportDeliveryType.Schedule,
                    selectedScopeId
                }),
            [selectedScopeId]);

    return (
        <CommonReport
            items={items}
            options={reportOptions}
            rootScopeId={ScopeHelper.customerId}
            scopeSelectorDisabled={true}
            onGenerate={() => setReportData({ open: false })}/>);
}