﻿import { Link, Step, Steps, useLocalization } from "@infrastructure";
import React from "react";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { useGetAwsRoute53HostedZoneRiskContext } from "../contexts";

export function useAwsRoute53HostedZoneSigningDisabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsRoute53HostedZoneSigningDisabledRisk;
    const hostedZoneModel = entityModelStore.useGet(risk.entityId) as Contract.AwsRoute53HostedZoneModel;
    const hostedZone = hostedZoneModel.entity as Contract.AwsRoute53HostedZone;

    const getAwsRoute53HostedZoneRiskContext = useGetAwsRoute53HostedZoneRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsRoute53HostedZoneSigningDisabledRiskDefinition",
            () => ({
                description: "{{hostedZone}} DNSSEC is not enabled",
                sections: {
                    resolution: {
                        step1: {
                            step1: "Select the **DNSSEC signing** tab and then click **Enable DNSSEC signing**",
                            step2: {
                                link: "AWS documentation",
                                text: "Create the key-signing key (KSK) by filling in the relevant fields. For more information, see {{enableSigningLink}}"
                            },
                            step3: "Click **Create KSK and enable signing**",
                            step4: "Establish a chain of trust for the selected Route 53 hosted zone to complete your DNSSEC signing setup. To get started, click **View information to create DS record** within the same tab",
                            step5: "In the **Establish a chain of trust** section, choose either **Route 53 registrar** or **Another domain registrar**, depending on where your domain is registered. Copy the provided information",
                            title: "Enable DSNSSEC signing for the hosted zone:"
                        },
                        step2: {
                            step1: "In the left-hand menu, click **Registered domains**",
                            step2: "Select the relevant domain, and then, next to **DNSSEC status**, choose **Manage keys**",
                            step3: "In the **Manage DNSSEC keys** dialog box, choose the appropriate **Key type** and **Algorithm** for the Route 53 registrar from the dropdown menu (according to the information from step 2.E)",
                            step4: "Copy the **Public key** for the Route 53 registrar. In the **Manage DNSSEC keys** dialog box, paste the value into the **Public key** box",
                            step5: "Click **Add**",
                            title: "Update the registered domains"
                        }
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            hostedZone:
                <Entity
                    entityIdOrModel={hostedZoneModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.Route53,
                AwsConsoleUrlBuilder.getRoute53HostedZoneUrl(hostedZone)),
            new Step(
                localization.sections.resolution.step1.title(),
                {
                    contentElement:
                        <Steps variant="letters">
                            {localization.sections.resolution.step1.step1()}
                            {localization.sections.resolution.step1.step2.text({
                                enableSigningLink:
                                    <Link
                                        urlOrGetUrl={(riskModel as Contract.AwsRoute53HostedZoneSigningDisabledRiskModel).secretManagerEnvironmentVariableDocumentationUrl}
                                        variant="external">
                                        {localization.sections.resolution.step1.step2.link()}
                                    </Link>
                            })}
                            {localization.sections.resolution.step1.step3()}
                            {localization.sections.resolution.step1.step4()}
                            {localization.sections.resolution.step1.step5()}
                        </Steps>
                }),
            new Step(
                localization.sections.resolution.step2.title(),
                {
                    contentElement:
                        <Steps variant="letters">
                            {localization.sections.resolution.step2.step1()}
                            {localization.sections.resolution.step2.step2()}
                            {localization.sections.resolution.step2.step3()}
                            {localization.sections.resolution.step2.step4()}
                            {localization.sections.resolution.step2.step5()}
                        </Steps>
                })
        ],
        riskModel,
        () => {
            const hostedZoneRiskContext = getAwsRoute53HostedZoneRiskContext(hostedZoneModel);
            return [
                hostedZoneRiskContext.generalInformation,
                hostedZoneRiskContext.publicNetworkAccess,
                hostedZoneRiskContext.sensitive,
                hostedZoneRiskContext.records,
                hostedZoneRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        });
}