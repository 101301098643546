import _, { Dictionary } from "lodash";
import { useAnalyticsContext } from "../components";
import { AnalyticsEventActionType, AnalyticsHelper, AnalyticsOptionsEvent } from "../utilities";

export function useTrackAnalytics() {
    const context = useAnalyticsContext();
    return (analyticsActionTypeOrEvent: AnalyticsEventActionType | AnalyticsOptionsEvent, propertyNameToValueMap?: Dictionary<boolean | number | string>) => {
        if (_.isObject(analyticsActionTypeOrEvent)) {
            AnalyticsHelper.track(
                analyticsActionTypeOrEvent.actionType,
                context,
                {
                    ...analyticsActionTypeOrEvent.propertyNameToValueMap,
                    ...propertyNameToValueMap
                });
        } else {
            AnalyticsHelper.track(
                analyticsActionTypeOrEvent,
                context,
                propertyNameToValueMap);
        }
    };
}