import { EmptyMessage, Shadows, useLocalization } from "@infrastructure";
import { Avatar, Divider, Grid2, Stack, Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React from "react";
import { SectionHeader } from ".";
import { Contract, RiskPolicyCategoryIcon, useRiskCombinationTranslator, useTheme } from "../../../../../../../common";
import { ContentPages, ContentPagesItem, ContentPagesProps } from "../../ContentPages";

type RiskCombinationsSectionProps = Omit<ContentPagesProps, "children"> & {
    riskCombinationToDataMap: Dictionary<Contract.RiskCombinationData>;
};

export function RiskCombinationsPages({ riskCombinationToDataMap, ...props }: RiskCombinationsSectionProps) {
    const riskCombinationTranslator = useRiskCombinationTranslator();
    const localization =
        useLocalization(
            "views.system.exportReport.dashboardReport.riskCombinationsPages",
            () => ({
                empty: "No Toxic Combinations",
                subtitle: "View resources with multiple risks that, when combined, are both hard-to-spot and pose a greater threat to your organization. The icons on the left represent all categories that contribute to the risk.",
                title: "Toxic Combinations"
            }));

    const theme = useTheme();
    return (
        <ContentPages {...props}>
            <ContentPagesItem>
                <Stack
                    spacing={2}
                    sx={{
                        paddingBottom: theme.spacing(2),
                        width: "100%"
                    }}>
                    <SectionHeader
                        subtitle={localization.subtitle()}
                        title={localization.title()}
                        variant="sub"/>
                </Stack>
            </ContentPagesItem>
            {_.isEmpty(riskCombinationToDataMap)
                ? <ContentPagesItem>
                    <Stack
                        sx={{
                            border: theme.border.primary,
                            borderRadius: theme.spacing(0.75),
                            width: "100%"
                        }}>
                        <EmptyMessage message={localization.empty()}/>
                    </Stack>
                </ContentPagesItem>
                : _(riskCombinationToDataMap).
                    toPairs().
                    orderBy(
                        [
                            ([, riskCombinationData]) =>
                                _(riskCombinationData.orderedRiskPolicyCategories).
                                    uniq().
                                    size(),
                            ([, riskCombinationData]) =>
                                _(riskCombinationData.entityTypeNameToCountMap).
                                    values().
                                    sum()
                        ],
                        [
                            "desc",
                            "desc"
                        ]).
                    map(([riskCombination, riskCombinationData], riskCombinationAndEntityTypeNameToCountIndex) =>
                        <ContentPagesItem key={riskCombination}>
                            {({ pageFirstItem, pageLastItem }) =>
                                <Stack
                                    style={{
                                        border: theme.border.primary,
                                        borderRadius:
                                            pageFirstItem || riskCombinationAndEntityTypeNameToCountIndex === 0
                                                ? theme.spacing(0.75, 0.75, 0, 0)
                                                : pageLastItem
                                                    ? theme.spacing(0, 0, 0.75, 0.75)
                                                    : 0,
                                        borderTop:
                                            pageFirstItem || riskCombinationAndEntityTypeNameToCountIndex === 0
                                                ? theme.border.primary
                                                : "none",
                                        padding: theme.spacing(1)
                                    }}>
                                    <Stack
                                        alignItems="center"
                                        direction="row">
                                        <RiskPolicyCategories orderedRiskPolicyCategories={riskCombinationData.orderedRiskPolicyCategories}/>
                                        <Divider
                                            flexItem={true}
                                            orientation="vertical"
                                            style={{ margin: theme.spacing(0, 2, 0, 1) }}/>
                                        <Typography>
                                            {riskCombinationTranslator(
                                                riskCombination as Contract.RiskCombination,
                                                _(riskCombinationData.entityTypeNameToCountMap).
                                                    values().
                                                    sum())}
                                        </Typography>
                                    </Stack>
                                </Stack>}
                        </ContentPagesItem>).
                    value()}
        </ContentPages>);
}

type RiskPolicyCategoriesProps = {
    orderedRiskPolicyCategories: Contract.RiskPolicyCategory[];
};

function RiskPolicyCategories({ orderedRiskPolicyCategories }: RiskPolicyCategoriesProps) {
    const theme = useTheme();
    return (
        <Grid2
            container={true}
            spacing={1}>
            {_.map(
                orderedRiskPolicyCategories,
                (riskPolicyCategory, riskPolicyCategoryIndex) =>
                    <Grid2 key={riskPolicyCategoryIndex}>
                        <Avatar
                            sx={{
                                background: theme.palette.background.paper,
                                boxShadow: theme.shadows[Shadows.Avatar],
                                height: "30px",
                                WebkitFilter: "opacity(1)",
                                width: "30px"
                            }}>
                            <RiskPolicyCategoryIcon
                                riskPolicyCategory={riskPolicyCategory}
                                style={{
                                    color: theme.palette.text.primary,
                                    fontSize: "18px"
                                }}/>
                        </Avatar>
                    </Grid2>)}
        </Grid2>);
}