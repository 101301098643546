﻿import { DataTableColumn, EnumValuesFilter, useLocalization } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { EntityTableDefinition } from "../..";
import { Contract, ItemSelectionHelper, TimeRangeHelper } from "../../../../../../../../../../common";
import { useAzureKeyVaultVaultKeyTypeTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";
import { useAzureCommonKeyVaultVaultObjectDefinition } from "./useAzureCommonKeyVaultVaultObjectDefinition";

export function useAzureKeyVaultVaultKeyDefinition(definitionData: DefinitionData) {
    const commonKeyVaultVaultObjectDefinition = useAzureCommonKeyVaultVaultObjectDefinition(definitionData);

    const keyVaultVaultKeyTypeTranslator = useAzureKeyVaultVaultKeyTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.azure.useAzureKeyVaultVaultKeyDefinition",
            () => ({
                columns: {
                    type: "Key Type"
                }
            }));

    return new EntityTableDefinition(
        _.filter([
            commonKeyVaultVaultObjectDefinition.columns.tenantColumn,
            commonKeyVaultVaultObjectDefinition.columns.vaultObjectAzureManagedColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AzureKeyVaultVaultKeyModel) => ({
                            [localization.columns.type()]: keyVaultVaultKeyTypeTranslator((item.entity as Contract.AzureKeyVaultVaultKey).type)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.AzureKeyVaultVaultKeyType}
                                enumTypeTranslator={keyVaultVaultKeyTypeTranslator}
                                placeholder={localization.columns.type()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AzureKeyVaultVaultKeyType}
                itemProperty={(item: Contract.AzureKeyVaultVaultKeyModel) => keyVaultVaultKeyTypeTranslator((item.entity as Contract.AzureKeyVaultVaultKey).type)}
                key={Contract.EntityModelProperty.AzureKeyVaultVaultKeyType}
                title={localization.columns.type()}/>,
            commonKeyVaultVaultObjectDefinition.columns.vaultObjectEnabledColumn,
            commonKeyVaultVaultObjectDefinition.columns.vaultObjectExpirationColumn,
            commonKeyVaultVaultObjectDefinition.columns.creationTimeColumn,
            commonKeyVaultVaultObjectDefinition.columns.vaultObjectVaultColumn,
            commonKeyVaultVaultObjectDefinition.columns.creatorIdentityCsvColumn,
            commonKeyVaultVaultObjectDefinition.columns.regionColumn,
            commonKeyVaultVaultObjectDefinition.columns.regionLocationColumn,
            commonKeyVaultVaultObjectDefinition.columns.resourceGroupColumn,
            commonKeyVaultVaultObjectDefinition.columns.tagsColumn,
            commonKeyVaultVaultObjectDefinition.columns.attributesColumn,
            commonKeyVaultVaultObjectDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonKeyVaultVaultObjectDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonKeyVaultVaultObjectDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ]),
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAzureKeyVaultVaultKeyRequest(
                new Contract.EntityControllerGetEntityModelPageAzureKeyVaultVaultKeyRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureResourceGroupResourceResourceGroup]),
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureKeyVaultVaultObjectAzureManaged]),
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureKeyVaultVaultObjectEnabled]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.AzureKeyVaultVaultObjectExpirationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureKeyVaultVaultObjectVault]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureKeyVaultVaultKeyType])
                ),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags]))));
}