import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useVulnerabilityReportTypeTranslator() {
    const localization =
        useLocalization(
            "views.customer.reports.hooks.useVulnerabilityReportTypeTranslator",
            () => ({
                [Contract.TypeNames.VulnerabilityReportType]: {
                    [Contract.VulnerabilityReportType.ContainerImages]: "Container Images",
                    [Contract.VulnerabilityReportType.VirtualMachines]: "Virtual Machines"
                }
            }));

    return (reportType: Contract.VulnerabilityReportType) =>
        localization[Contract.TypeNames.VulnerabilityReportType][reportType]();
}