﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, entityModelStore, InlineEntities } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetEntitiesRiskContext } from "../../../useGetEntitiesRiskContext";

export function useGetOciComputeInstancesRiskContext() {
    return useMemo(
        () => useOciComputeInstancesRiskContext,
        []);
}

function useOciComputeInstancesRiskContext(instanceIdsOrModels: string[] | Contract.OciComputeInstanceModel[]) {
    const instanceModels =
        entityModelStore.useGet(
            _.isString(instanceIdsOrModels[0])
                ? instanceIdsOrModels as string[]
                : undefined) ?? instanceIdsOrModels as Contract.EntityModel[];
    const instancesRiskContext =
        useGetEntitiesRiskContext()(
            instanceModels,
            Contract.TypeNames.OciComputeInstance);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.oci.hooks.contexts.useGetOciComputeInstancesRiskContext",
            () => ({
                runningInstances: {
                    empty: "All compute instances are not running",
                    some: [
                        "{{instances}} has state of **running**",
                        "{{instances}} have state of **running**"
                    ]
                }
            }));

    const runningInstanceModels =
        useMemo(
            () =>
                _.filter(
                    instanceModels,
                    instanceModel => (instanceModel.entity as Contract.OciComputeInstance).status === Contract.OciComputeInstanceStatus.Running),
            [instanceModels]);
    return {
        ...instancesRiskContext,
        runningInstances:
            _.isEmpty(instanceModels)
                ? undefined
                : _.isEmpty(runningInstanceModels)
                    ? new RiskDefinitionContextItem(localization.runningInstances.empty())
                    : new RiskDefinitionContextItem(
                        localization.runningInstances.some(
                            runningInstanceModels.length,
                            {
                                instances:
                                    <InlineEntities
                                        entityIdsOrModels={runningInstanceModels}
                                        entityTypeName={Contract.TypeNames.OciComputeInstance}
                                        variant="itemCountAndType"/>
                            }))
    };
}