import _ from "lodash";
import { useMemo } from "react";
import { useEntityRiskContext, useResourceGeneralInformationStep } from "../../..";
import { Contract } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";

export function useGetOciResourceRiskContext() {
    return useMemo(
        () => useOciResourceRiskContext,
        []);
}

function useOciResourceRiskContext(resourceModel: Contract.OciResourceModel) {
    const entityRiskContext = useEntityRiskContext(resourceModel);
    const resourceGeneralInformationStep = useResourceGeneralInformationStep(resourceModel);

    return {
        ...entityRiskContext,
        generalInformation:
            _.isNil(resourceGeneralInformationStep)
                ? undefined
                : new RiskDefinitionContextItem(resourceGeneralInformationStep)
    };
}