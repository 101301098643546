import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, Entity, InlineEntities, useEntityTypeNameTranslator } from "../../../../../../../../../../../common";
import { AuditEventDefinition, AuditEventDefinitionContext, AuditEventDefinitionDetailItem } from "../../../useDefinition";
import { usePermissionAssignmentEligibilityAuditEventCommonDetailItems } from "./usePermissionAssignmentEligibilityAuditEventCommonDetailItems";

export function useAzureRoleAssignmentEligibilityAuditEventDefinition(context: AuditEventDefinitionContext) {
    const permissionAssignmentEligibilityAuditEventCommonDetailItems = usePermissionAssignmentEligibilityAuditEventCommonDetailItems(context);
    const localization =
        useLocalization(
            "views.customer.auditEvents.profile.hooks.useDefinition.hooks.usePermissionEligibilityAuditEventDefinition.hooks.useAzureRoleAssignmentEligibilityAuditEventDefinition",
            () => ({
                roleDefinitionIdReferences: "Permissions"
            }));

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const permissionEligibilityAuditEventModel = context.auditEventModel as Contract.PermissionEligibilityAuditEventModel;
    const auditEventModelAzureRoleAssignmentEligibilityData = permissionEligibilityAuditEventModel.permissionEligibilityData as Contract.PermissionEligibilityAuditEventModelAzureRoleAssignmentEligibilityData;

    return new AuditEventDefinition(
        _.filter([
            permissionAssignmentEligibilityAuditEventCommonDetailItems.name,
            permissionAssignmentEligibilityAuditEventCommonDetailItems.principalTenantId,
            permissionAssignmentEligibilityAuditEventCommonDetailItems.granteePrincipalIdReferences,
            auditEventModelAzureRoleAssignmentEligibilityData.roleDefinitionIdReferences.length === 1
                ? new AuditEventDefinitionDetailItem(
                    localization.roleDefinitionIdReferences(),
                    context.entityModelMap[auditEventModelAzureRoleAssignmentEligibilityData.roleDefinitionIdReferences[0]].entity.displayName,
                    <Entity
                        entityIdOrModel={auditEventModelAzureRoleAssignmentEligibilityData.roleDefinitionIdReferences[0]}
                        variant="iconText"/>)
                : new AuditEventDefinitionDetailItem(
                    localization.roleDefinitionIdReferences(),
                    entityTypeNameTranslator(
                        Contract.TypeNames.AzureAuthorizationRoleDefinition,
                        {
                            count: auditEventModelAzureRoleAssignmentEligibilityData.roleDefinitionIdReferences.length,
                            includeCount: true
                        }),
                    <InlineEntities
                        entityIdsOrModels={auditEventModelAzureRoleAssignmentEligibilityData.roleDefinitionIdReferences}
                        entityTypeName={Contract.TypeNames.AzureAuthorizationRoleDefinition}
                        variant="itemPlusItemCount"/>
                ),
            permissionAssignmentEligibilityAuditEventCommonDetailItems.permissionRequestScopePaths,
            permissionAssignmentEligibilityAuditEventCommonDetailItems.expirationTimeFrame,
            permissionAssignmentEligibilityAuditEventCommonDetailItems.approval,
            ...permissionAssignmentEligibilityAuditEventCommonDetailItems.approvalLevels,
            permissionAssignmentEligibilityAuditEventCommonDetailItems.reasonRequired,
            permissionAssignmentEligibilityAuditEventCommonDetailItems.updateAuditEventChanges
        ]));
}